import React from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import styled from "styled-components";
import { AppErrorText, AppText, FlexDiv, Loading } from "src/Components/UI";
import { PhoenixIcon } from "src/Components/UI/Phoenix";
import { reorder, trash } from "src/images/NewDesign";
import { theme } from "src/utils/theme";
import { LabelT } from "src/types";

type RankSelectedRepsTableProps = {
  data?: any[];
  onDelete: (id: string) => void;
  onDragEnd: (result: any) => void;
  isLoading?: boolean;
  isError?: any;
  selectedLabels?: LabelT[];
};

export const RankSelectedRepsTable = ({
  data = [],
  onDelete,
  onDragEnd,
  isLoading,
  isError,
  selectedLabels = [],
}: RankSelectedRepsTableProps) => {
  if (isLoading) {
    return <Loading />;
  }

  if (isError) {
    return <AppErrorText>Error loading reps</AppErrorText>;
  }

  const getUserLabels = (userId: string) => {
    const userLabels = selectedLabels.filter((label) => label.reps?.some((rep) => rep.user_id === userId));

    if (userLabels.length === 0) {
      return (
        <AppText fontSize={12} fontWeight={400} lineHeight={18}>
          No labels assigned
        </AppText>
      );
    }

    return userLabels.map((label) => (
      <LabelPill key={label.id} color={label.color}>
        {label.name}
      </LabelPill>
    ));
  };

  const userHasLabel = (userId: string) => {
    return selectedLabels.some((label) => label.reps?.some((rep) => rep.user_id === userId));
  };

  return (
    <div>
      <Header>
        <HeaderItem width="15%">Priority</HeaderItem>
        <HeaderItem>Rep Name</HeaderItem>
        <HeaderItem>Label(s)</HeaderItem>
        <HeaderItem center>Delete</HeaderItem>
      </Header>

      <div>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {data.map((item, index: number) => (
                  <Draggable key={`rep_${item.id}-${index}`} draggableId={item.id} index={index}>
                    {(provided) => {
                      const hasLabel = userHasLabel(item.id);

                      return (
                        <Row ref={provided.innerRef} {...provided.draggableProps}>
                          <Cell gap={8} width="15%">
                            <PhoenixIcon
                              svg={reorder}
                              size={16}
                              color={theme.icon.brand.default}
                              hoverColor={theme.icon.brand.default}
                              alt="drag me"
                              style={{ cursor: "grab" }}
                              {...provided.dragHandleProps}
                            />

                            <AppText fontSize={12} fontWeight={400} lineHeight={18}>
                              {index + 1}
                            </AppText>
                          </Cell>

                          <Cell>
                            <AppText fontSize={12} fontWeight={400} lineHeight={18}>
                              {item.full_name}
                            </AppText>
                          </Cell>

                          <Cell>
                            <FlexDiv wrap="wrap" gap={4}>
                              {getUserLabels(item.id)}
                            </FlexDiv>
                          </Cell>

                          <Cell center>
                            {!hasLabel && (
                              <PhoenixIcon
                                svg={trash}
                                size={12}
                                color={theme.PILL_DARK_RED}
                                pointer
                                onClick={() => onDelete(item.id)}
                              />
                            )}
                          </Cell>
                        </Row>
                      );
                    }}
                  </Draggable>
                ))}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </div>
  );
};

export default RankSelectedRepsTable;

const Header = styled.div`
  display: flex;
  width: 100%;
  height: 40px;
  background-color: ${theme.fill.brand.secondary};
`;

const HeaderItem = styled.div<{ width?: string; center?: boolean }>`
  display: flex;
  justify-content: ${(props) => (props.center ? "center" : "flex-start")};
  align-items: center;
  padding: 8px 16px;

  width: ${(props) => props.width ?? "100%"};
  flex-grow: 1;

  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
`;

export const Row = styled.div`
  display: flex;
  width: 100%;
  min-height: 40px;
  padding: 2px 0;

  :nth-child(odd) {
    background-color: ${theme.surface.neutral.primary};
  }
  :nth-child(even) {
    background-color: ${theme.surface.brand.secondary};
  }
`;

export const Cell = styled.div<{ center?: boolean; width?: string; bold?: boolean; gap?: number }>`
  align-items: center;
  display: flex;
  flex-grow: 1;
  font-size: 12px;
  font-weight: ${(props) => (props.bold ? "600" : "400")};
  gap: ${(props) => props.gap ?? 0}px;
  justify-content: ${(props) => (props.center ? "center" : "flex-start")};
  padding: 0px 16px;
  width: ${(props) => props.width ?? "100%"};
`;

const LabelPill = styled.span<{ color: string }>`
  background-color: ${(props) => props.color};
  border-radius: 4px;
  color: ${theme.WHITE_COLOR};
  font-size: 8px;
  padding: 6px 8px;
`;
