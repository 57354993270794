import React, { forwardRef, useImperativeHandle } from "react";
import { gql, useQuery } from "@apollo/client";
import * as Yup from "yup";
import { AppText, FlexDiv, Loading } from "src/Components/UI";
import { PhoenixAppButton } from "src/Components/UI/Phoenix";
import { theme } from "src/utils/theme";
import CustomNotificationCard from "./CustomNotificationCard";
import { Formik, FormikProps } from "formik";
import { CustomNotification } from "./types";

const FETCH_CUSTOM_NOTIFICATIONS = gql`
  query fetchCustomNotifications {
    fetchCustomNotifications {
      id
      from_value
      in_values
      internal_object_field
      operand
      recipient_type
      to_value
    }
  }
`;

const FETCH_NOTIFICATION_OPTIONS = gql`
  query fetchCustomNotificationOptions {
    fetchCustomNotificationOptions
  }
`;

const validationSchema = Yup.object().shape({
  notifications: Yup.array().of(
    Yup.object().shape({
      recipient_type: Yup.string().required("Recipient is required"),
      internal_object_field: Yup.string().required("Field is required"),
      operand: Yup.string().required("Change type is required"),
      from_value: Yup.string()
        .nullable()
        .when("operand", {
          is: "From",
          then: Yup.string().required("Value is required").nullable(),
        }),
      to_value: Yup.string()
        .nullable()
        .when("operand", {
          is: "To",
          then: Yup.string().required("Value is required").nullable(),
        }),
      in_values: Yup.array().when("operand", {
        is: "In",
        then: Yup.array().min(1, "At least one value is required"),
      }),
    }),
  ),
});

export type CustomNotificationsTabRef = {
  submitForm: () => Promise<{ values: CustomNotification[]; isValid: boolean }>;
};

type CustomNotificationsTabProps = {
  setHasChanges: (hasChanges: boolean) => void;
};

type CustomNotificationsFormValues = {
  notifications: CustomNotification[];
};

const CustomNotificationsTab = forwardRef<CustomNotificationsTabRef, CustomNotificationsTabProps>(
  ({ setHasChanges }, ref) => {
    const formikRef = React.useRef<FormikProps<CustomNotificationsFormValues>>(null);
    const { data: notificationOptionsData, loading: notificationOptionsLoading } = useQuery(
      FETCH_NOTIFICATION_OPTIONS,
      { fetchPolicy: "cache-and-network" },
    );
    const { data: customNotificationsData, loading: customNotificationsLoading } = useQuery(
      FETCH_CUSTOM_NOTIFICATIONS,
      { fetchPolicy: "cache-and-network" },
    );

    const initialValues = {
      notifications: customNotificationsData?.fetchCustomNotifications ?? [],
    };

    const options = {
      recipients: notificationOptionsData?.fetchCustomNotificationOptions.recepicients,
      internal_objects: notificationOptionsData?.fetchCustomNotificationOptions.internal_objects.Lead?.map(
        (item: any) => ({
          label: item.field_label,
          value: item.field_id,
          isCustom: item.custom,
        }),
      ),
      operands: notificationOptionsData?.fetchCustomNotificationOptions.operands,
    };

    const sanitizeNotifications = (values: CustomNotificationsFormValues) => {
      const initialNotifications = initialValues.notifications;
      const currentNotifications = values.notifications;

      const deletedNotifications = initialNotifications
        .filter((initial: any) => initial.id && !currentNotifications.find((current: any) => current.id === initial.id))
        .map((notification: any) => {
          const { __typename, ...rest } = notification;
          return { ...rest, should_delete: true, is_custom: false };
        });

      const processedNotifications = currentNotifications.map((notification) => {
        const { __typename, ...rest } = notification;
        const customField = options.internal_objects?.find((field: any) => field.value === rest.internal_object_field);

        return {
          ...rest,
          is_custom: customField?.isCustom || false,
        };
      });

      return [...processedNotifications, ...deletedNotifications];
    };

    useImperativeHandle(ref, () => ({
      submitForm: async () => {
        if (formikRef.current) {
          await formikRef.current.submitForm();
          return {
            isValid: formikRef.current.isValid,
            values: sanitizeNotifications(formikRef.current.values),
          };
        }
        return { values: [], isValid: false };
      },
    }));

    if (customNotificationsLoading || notificationOptionsLoading) {
      return <Loading />;
    }

    return (
      <Formik<CustomNotificationsFormValues>
        innerRef={formikRef}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          return Promise.resolve(values.notifications);
        }}
        validateOnChange={true}
        validateOnBlur={true}
      >
        {({ values, setFieldValue, errors }) => (
          <FlexDiv direction="column" gap={40} width="100%" align="flex-start">
            <FlexDiv direction="column" gap={8}>
              <AppText fontSize={18} fontWeight={500}>
                Custom Notifications
              </AppText>
              <AppText fontSize={12} fontWeight={500}>
                Add multiple notifications for updates to the{" "}
                <AppText style={{ display: "inline" }} color={theme.text.neutral.primary}>
                  Lead Object
                </AppText>{" "}
                field.
              </AppText>
            </FlexDiv>

            {values.notifications?.length > 0 && (
              <FlexDiv direction="column" gap={16} width="100%" style={{ maxWidth: "400px" }}>
                {values.notifications.map((notification: CustomNotification, index: number) => (
                  <CustomNotificationCard
                    key={notification.id ?? index}
                    index={index + 1}
                    notification={notification}
                    onChange={(updatedNotif) => {
                      setFieldValue(`notifications.${index}`, updatedNotif);
                      setHasChanges(true);
                    }}
                    onDelete={() => {
                      const updatedNotifications = values.notifications.filter((_, i) => i !== index);
                      setFieldValue("notifications", updatedNotifications);
                      setHasChanges(true);
                    }}
                    options={options}
                    error={errors.notifications?.[index]}
                  />
                ))}
              </FlexDiv>
            )}

            <FlexDiv direction="column">
              <PhoenixAppButton
                buttonType="primary"
                variant="brand-outline"
                onClick={() => {
                  const newNotification: CustomNotification = {
                    from_value: "",
                    in_values: [],
                    internal_object_field: "",
                    operand: "",
                    recipient_type: "",
                    to_value: "",
                  };
                  setFieldValue("notifications", [...values.notifications, newNotification]);
                  setHasChanges(true);
                }}
              >
                Add Notification
              </PhoenixAppButton>
            </FlexDiv>
          </FlexDiv>
        )}
      </Formik>
    );
  },
);

export default CustomNotificationsTab;
