import gql from "graphql-tag";
import { useQuery } from "@apollo/client";
import { appToast } from "src/utils/toast";
import { LabelT } from "src/types";

const FETCH_LABELS = gql`
  query fetchLabels($input: fetchLabelsInput!, $includeReps: Boolean!) {
    fetchLabels(input: $input) {
      id
      name
      description
      color
      created_at
      updated_at
      deleted_at
      organization_id
      created_by {
        id
        full_name
      }
      last_updated_by {
        id
        full_name
      }
      reps @include(if: $includeReps) {
        user_id
        user {
          id
          full_name
        }
      }
    }
  }
`;

const useFetchLabels = (params?: { includeReps?: boolean }) => {
  const { includeReps = false } = params ?? {};

  const { data: labelsData, loading, error } = useQuery<{ fetchLabels: LabelT[] }>(FETCH_LABELS, {
    fetchPolicy: "cache-and-network",
    variables: {
      input: { labels_id: [] },
      includeReps,
    },
    onCompleted: () => {},
    onError: () => {
      appToast("Something went wrong.");
    },
  });

  return {
    data: labelsData?.fetchLabels ?? [],
    loading,
    error,
  };
};

export default useFetchLabels;
