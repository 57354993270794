import styled from "styled-components";

import React, { useState, useEffect } from "react";

import { AppText } from "../../../UI";

import { theme } from "../../../../utils/theme";
import { PhoenixInput } from "../../../UI/Phoenix";
import { useQuery, gql } from "@apollo/client";

import { AppErrorText } from "../../../UI";

import { debounce } from "lodash";
import { useMutation } from "@apollo/client";
import { appToast, errorToast } from "../../../../utils/toast";

import { SkeletonBlock } from "../../../UI/SkeletonBlock";
import { useDebounce } from "../../../../utils/hooks";
import { SETTINGS_DEBOUNCE_INTERVAL } from "../../../../utils/variables";
import "./InboundRoutingRulesTable.css";

const FETCH_ORG_INBOUND_CALL_RULE_SETTINGS = gql`
  query fetchOrgInboundCallRoutingRuleSetting {
    fetchOrgInboundCallRoutingRuleSetting {
      id
      dial_duration
      max_cycle
    }
  }
`;

const UPDATE_ORG_INBOUND_CALL_SETTING = gql`
  mutation updateOrgInboundCallRoutingRuleSetting($data: InboundCallRoutingRuleSettingInput!) {
    updateOrgInboundCallRoutingRuleSetting(data: $data) {
      id
    }
  }
`;

const InboundRoutingRulesSettings = () => {
  const [dataOrgInboundRoutingRuleSettings, setDataOrgInboundRoutingRuleSettings] = useState<any>({});
  const [stateMaxCycle, setStateMaxCycle] = useState<number>(dataOrgInboundRoutingRuleSettings?.max_cycle);
  const [stateDialDuration, setStateDialDuration] = useState<number>(dataOrgInboundRoutingRuleSettings?.dial_duration);

  const {
    data: rawDataOrgInboundRoutingRuleSettings,
    error: errorDataOrgInboundRoutingRuleSettings,
    loading: loadingDataOrgInboundRoutingRuleSettings,
    refetch: refetchOrgInboundCallRoutingRuleSettings,
  } = useQuery(FETCH_ORG_INBOUND_CALL_RULE_SETTINGS, {
    fetchPolicy: "cache-and-network",
    onError({ message, name }) {
      console.log(`Error in ${name}: `, message);
      appToast("Error fetching live transfer settings");
    },
    onCompleted() {
      setDataOrgInboundRoutingRuleSettings({
        ...rawDataOrgInboundRoutingRuleSettings?.fetchOrgInboundCallRoutingRuleSetting,
        max_cycle: rawDataOrgInboundRoutingRuleSettings?.fetchOrgInboundCallRoutingRuleSetting?.max_cycle,
        dial_duration: rawDataOrgInboundRoutingRuleSettings?.fetchOrgInboundCallRoutingRuleSetting?.dial_duration,
      });

      setStateMaxCycle(rawDataOrgInboundRoutingRuleSettings?.fetchOrgInboundCallRoutingRuleSetting?.max_cycle);
      setStateDialDuration(rawDataOrgInboundRoutingRuleSettings?.fetchOrgInboundCallRoutingRuleSetting?.dial_duration);
    },
  });

  const [updateOrgInboundCallRoutingRuleSetting] = useMutation(UPDATE_ORG_INBOUND_CALL_SETTING, {
    onError({ message, name }) {
      appToast("Error updating future booking settings");
      console.log(`Error in ${name}: `, message);
    },
    onCompleted() {
      appToast("Settings updated successfully");
      refetchOrgInboundCallRoutingRuleSettings();
    },
  });

  useEffect(() => {
    const initalLoad = Object.keys(dataOrgInboundRoutingRuleSettings).length === 0;

    const stateIsSameAsRawData = () => {
      return (
        JSON.stringify(dataOrgInboundRoutingRuleSettings) ===
        JSON.stringify({
          ...rawDataOrgInboundRoutingRuleSettings?.fetchOrgInboundCallRoutingRuleSetting,
          max_cycle: rawDataOrgInboundRoutingRuleSettings?.fetchOrgInboundCallRoutingRuleSetting?.max_cycle,
          dial_duration: rawDataOrgInboundRoutingRuleSettings?.fetchOrgInboundCallRoutingRuleSetting?.dial_duration,
        })
      );
    };

    if (initalLoad) return;

    if (stateIsSameAsRawData()) return;

    const debounced = debounce(() => {
      updateOrgInboundCallRoutingRuleSetting({
        variables: {
          data: {
            dial_duration: dataOrgInboundRoutingRuleSettings?.dial_duration,
            max_cycle: dataOrgInboundRoutingRuleSettings?.max_cycle,
          },
        },
      });
    }, SETTINGS_DEBOUNCE_INTERVAL);
    debounced();
    return debounced.cancel;
  }, [dataOrgInboundRoutingRuleSettings]);

  useDebounce(
    () => {
      if (!stateDialDuration) return;

      if (stateDialDuration < 15) {
        errorToast("Dial Duration must be greater than 15 seconds. ");
        setStateDialDuration(dataOrgInboundRoutingRuleSettings?.dial_duration || 15);
        return;
      }

      setDataOrgInboundRoutingRuleSettings({
        ...dataOrgInboundRoutingRuleSettings,
        dial_duration: typeof stateDialDuration === "string" ? parseInt(stateDialDuration) : stateDialDuration,
      });
    },
    [stateDialDuration],
    SETTINGS_DEBOUNCE_INTERVAL,
  );

  useDebounce(
    () => {
      if (!stateMaxCycle) return;

      if (stateMaxCycle < 1) {
        errorToast("Max Cycle must be greater than 0. ");
        setStateMaxCycle(dataOrgInboundRoutingRuleSettings?.max_cycle || 1);
        return;
      }

      if (stateMaxCycle > 5) {
        errorToast("Max Cycle must be less than 5. ");
        setStateMaxCycle(dataOrgInboundRoutingRuleSettings?.max_cycle || 1);
        return;
      }

      setDataOrgInboundRoutingRuleSettings({
        ...dataOrgInboundRoutingRuleSettings,
        max_cycle: typeof stateMaxCycle === "string" ? parseInt(stateMaxCycle) : stateMaxCycle,
      });
    },
    [stateMaxCycle],
    SETTINGS_DEBOUNCE_INTERVAL,
  );

  const initalLoad = Object.keys(dataOrgInboundRoutingRuleSettings).length === 0;

  const loading = initalLoad && loadingDataOrgInboundRoutingRuleSettings;

  const error = errorDataOrgInboundRoutingRuleSettings;

  if (error)
    return (
      <MinHeightDiv>
        <AppErrorText>
          Something went wrong. Please refresh the page or contact support if the problem persists.
        </AppErrorText>
      </MinHeightDiv>
    );

  if (loading)
    return (
      <>
        <SkeletonBlock height={281} width={"100%"} borderRadius={4} />
        <SkeletonBlock height={724} width={"100%"} borderRadius={4} />
        <SkeletonBlock height={240} width={"100%"} borderRadius={4} />
      </>
    );

  return (
    <>
      <Container>
        <ContainerHeader>
          <ContainerHeaderLeft>
            <ContainerTitle>Live Transfers</ContainerTitle>
          </ContainerHeaderLeft>
          <ContainerHeaderRight />
        </ContainerHeader>
        <ContainerBody>
          <ContainerBoldText>
            When a call is being transferred, how long should Sellfire dial an AE before moving onto the next AE?
          </ContainerBoldText>
          <AppText fontSize={12} fontWeight={500} color={theme.NEUTRAL300}>
            Time must be 15 seconds minimum
          </AppText>
          <SpacerDiv />
          <AlignedRow>
            <PhoenixInput
              width={47}
              height={40}
              value={stateDialDuration}
              onChange={(e: any) => {
                setStateDialDuration(e.target.value);
              }}
              variant={dataOrgInboundRoutingRuleSettings?.dial_duration < 15 ? "error" : "primary"}
            />
            <AppText fontSize={12} fontWeight={500}>
              Seconds
            </AppText>
          </AlignedRow>
          <SpacerDiv />
          <ContainerBoldText>
            When a call is being transferred, how many times should Sellfire cycle through all eligible AEs before
            alerting the SDR that no one is available?
          </ContainerBoldText>
          <AppText fontSize={12} fontWeight={500} color={theme.NEUTRAL300}>
            Maximum number of cycles is 5
          </AppText>
          <SpacerDiv />
          <AlignedRow>
            <PhoenixInput
              value={stateMaxCycle}
              width={47}
              height={40}
              onChange={(e: any) => {
                setStateMaxCycle(e.target.value);
              }}
              variant={
                dataOrgInboundRoutingRuleSettings?.max_cycle > 5 || dataOrgInboundRoutingRuleSettings?.max_cycle < 1
                  ? "error"
                  : "primary"
              }
            />
            <AppText fontSize={12} fontWeight={500}>
              Cycles
            </AppText>
          </AlignedRow>
        </ContainerBody>
      </Container>
    </>
  );
};

const ContainerTitle = styled(AppText)`
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 16px;
`;

const AlignedRow = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const SpacerDiv = styled.div<{ height?: number | string }>`
  height: ${(props) =>
    props.height ? (typeof props.height === "number" ? `${props.height}px` : props.height) : "16px"};
  min-height: ${(props) =>
    props.height ? (typeof props.height === "number" ? `${props.height}px` : props.height) : "16px"};
`;

const ContainerBoldText = styled(AppText)`
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 16px;
`;

const Container = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${theme.WHITE_COLOR};
  padding: 24px;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  border: 1px solid ${theme.NEUTRAL300};
  margin-bottom: 16px;
`;

const ContainerHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
`;

const ContainerHeaderLeft = styled.div`
  display: flex;
  align-items: center;
`;
const ContainerHeaderRight = styled.div`
  display: flex;
  align-items: center;
`;
const ContainerBody = styled.div``;

const MinHeightDiv = styled.div`
  min-width: 100%;
  min-height: 70vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export default InboundRoutingRulesSettings;
