import * as React from "react";
import { useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import { loggedInUser } from "../../apollo/cache";
import { theme } from "../../utils/theme";
import { AppErrorText, AppIconButton, AppText, FlexDiv, ReactDatesWrapper, SkeletonBlock } from "../UI";

import { NewAppButton } from "../UI/NewAppButton";

import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import * as Sentry from "@sentry/react";
import { FieldArray, Formik, FormikProps } from "formik";
import moment from "moment";
import * as Yup from "yup";
import "../../utils/react-big-calendar.css";
import { appToast } from "../../utils/toast";

import { timezone_list_items } from "../../static/";

import { useFlags } from "launchdarkly-react-client-sdk";
import { SingleDatePicker } from "react-dates";
import { FiTrash2 } from "react-icons/fi";
import { google_meet_logo, phone_white, zoom_logo } from "../../images/NewDesign";
import { SelectDispo } from "../Smart";
import { PhoenixAppButton, PhoenixMultiSelect, PhoenixTextArea } from "../UI/Phoenix";
import { PanelModal } from "../modal";

import { AlternateContactType, MeetingType, OptionItem } from "src/types";
import { isValidEmail } from "src/utils/format";
import { PhoenixInputField } from "../Field/Phoenix";
interface AvailableTimeItem {
  user_id: string;
  user_name: string;
  start_time: string;
  event_count: number;
}

interface DisappearingDivProps {
  dataEvent: any;
  user_id?: string;
  selectedDataID?: any;
  selectedDataTime?: any;
  selectedDataLeadID?: string;
  setCurrentTask?: (currentTask: string) => void;
  id?: string;
  time?: string;
  phone_number?: string;
  title?: string;
  type?: string;
  lead_id: string;
  lead_primary_email?: string;
  lead_full_name?: string;
  fromNonCallActivity?: boolean;
  closeModal: () => void;
  dispositionType?: string;
  intent_id?: string;
  disableNoContectForDemo?: boolean;
  selectedDisposition?: string;
  isThisACallback: boolean;
}

const FETCH_LEAD_EMAILS = gql`
  query fetchLead($id: String!, $always_return_primary: Boolean) {
    fetchLead(id: $id, always_return_primary: $always_return_primary) {
      id
      full_name
      primary_email
      alternate_contacts {
        id
        title
        channel
        type
        value
        mapping_order
        contact_label
        country_code
      }
    }
  }
`;

const FETCH_ASSOCIATED_LEADS_EMAILS = gql`
  query FetchAssociateContact($primary_lead_id: String!) {
    fetchAssociateContact(primary_lead_id: $primary_lead_id) {
      id
      full_name
      primary_email
      alternate_contacts {
        id
        title
        channel
        type
        value
        mapping_order
        contact_label
        country_code
      }
    }
  }
`;

interface IFetchAvailableTimes {
  fetchAvailableTimesV3: AvailableTimeItem[];
}
const AVAILABLE_SPECIFIC_TIMES = gql`
  query fetchAvailableTimes($day: DateTime, $timezone: String, $action: String, $lead_id: String) {
    fetchAvailableTimesV3(day: $day, timezone: $timezone, action: $action, lead_id: $lead_id)
  }
`;

interface CheckIntegrationStatusExpectedResponse {
  checkIntegrationStatus: {
    [key: string]: boolean;
  };
}

const CHECK_INTEGRATION_STATUS = gql`
  query checkIntegrationStatus {
    checkIntegrationStatus
  }
`;

const CHECK_USER_ZOOM_STATUS = gql`
  query CheckUserZoomStatus($user_id: String!) {
    checkUserZoomStatus(user_id: $user_id)
  }
`;

const CHECK_USER_GOOGLE_STATUS = gql`
  query CheckUserGoogleStatus($user_id: String!) {
    checkUserGoogleStatus(user_id: $user_id)
  }
`;

const EDIT_CALENDAR_EVENT = gql`
  mutation editCalendarEvent(
    $id: String!
    $event_start_time: DateTime!
    $event_end_time: DateTime
    $event_title: String!
    $event_location: String
    $event_description: String!
    $joinMe_id: String
    $guest_list: [String]
    $disposition_id: String
    $meeting_type: MeetingType
    $event_timezone: String
  ) {
    editCalendarEvent(
      id: $id
      event_start_time: $event_start_time
      event_end_time: $event_end_time
      event_title: $event_title
      event_location: $event_location
      event_description: $event_description
      joinMe_id: $joinMe_id
      guest_list: $guest_list
      disposition_id: $disposition_id
      meeting_type: $meeting_type
      event_timezone: $event_timezone
    ) {
      id
      action_label
      start_time
      end_time
      what
      where
      description
      timezone
      join_me_id
      join_me_item {
        schedule_item_id
        schedule_item {
          booking_id
          join_me_item {
            id
            viewer_url
          }
        }
      }
      timeoff_request {
        result
        schedule_item_id
      }
      guests {
        id
        email
        result
      }
      google_space_sellfire_id
      google_space_info {
        google_space_sellfire_id
        meeting_code
        meeting_url
      }
      zoom_meeting_sellfire_id
      zoom_meeting_info {
        zoom_meeting_sellfire_id
        meeting_url
        meeting_id
      }
      lead_intent {
        id
        type
        label
        associated_action
        lead {
          id
          full_name
          business_name
          primary_email
          primary_phone_number
        }
        user {
          id
          full_name
          team_name
          role
        }
      }
    }
  }
`;

interface MyFormikProps {
  id: string;
  lead_id: string;
  event_start_time: string;
  event_end_time: string;
  guest_list: string[];
  event_title: string;
  event_description: string;
  joinMe_id: string;
  event_location: string;
  timezone: string;
  selected_person_spoke_to: string;
  selected_disposition_id: string;
  meeting_type: MeetingType;
}

const formatTimesToStringFormat = (start_time: string | number, format: string = "YYYY-MM-DDTHH:mm") => {
  // check for UNIX timestamp in milliseconds
  if (typeof start_time === "number") {
    return moment.unix(start_time / 1000).format(format);
  }

  return moment(start_time).format(format);
};

const EditEventDetailsV2: React.FC<DisappearingDivProps> = ({
  selectedDataID,
  selectedDataTime,
  selectedDataLeadID,
  lead_primary_email,
  lead_id,
  fromNonCallActivity,
  closeModal,
  disableNoContectForDemo = false,
  selectedDisposition,
  dataEvent,
  isThisACallback,
  ...props
}) => {
  // the event_start_time is formated in UNIX time we need to convert it to a string the same as the other times
  const event_start_time = formatTimesToStringFormat(dataEvent?.scheduleItem?.start_time);

  const { zoomIntegration, googleMeetIntegration } = useFlags();
  const { data: dataStatus } = useQuery<CheckIntegrationStatusExpectedResponse>(CHECK_INTEGRATION_STATUS);
  const zoomIsEnabledOnOrg = dataStatus?.checkIntegrationStatus["Zoom"] ?? false;
  const googleMeetIsEnabledOnOrg = dataStatus?.checkIntegrationStatus["Google"] ?? false;

  const { data: userZoomStatus, loading: loadingUserZoomStatus, error: errorUserZoomStatus } = useQuery(
    CHECK_USER_ZOOM_STATUS,
    {
      fetchPolicy: "cache-and-network",
      variables: { user_id: dataEvent?.scheduleItem?.lead_intent?.user?.id || "" },
      onError({ message, name }) {
        console.log(`Error in ${name}: `, message);
      },
      onCompleted({ checkUserZoomStatus }) {},
    },
  );

  const { data: userGoogleStatus, loading: loadingCheckUserGoogleStatus, error: errorCheckUserGoogleStatus } = useQuery(
    CHECK_USER_GOOGLE_STATUS,
    {
      fetchPolicy: "cache-and-network",
      variables: { user_id: dataEvent?.scheduleItem?.lead_intent?.user?.id || "" },
      onError({ message, name }) {
        console.log(`Error in ${name}: `, message);
      },
      onCompleted({ checkUserGoogleStatus }) {},
    },
  );

  const selectedEventRepHasZoomEnabled = useMemo(() => {
    if (userZoomStatus && userZoomStatus.checkUserZoomStatus) {
      return userZoomStatus.checkUserZoomStatus.zoom_status === "active";
    }
    return false;
  }, [userZoomStatus]);

  const selectedEventRepHasGoogleEnabled = useMemo(() => {
    if (userGoogleStatus && userGoogleStatus.checkUserGoogleStatus) {
      return userGoogleStatus.checkUserGoogleStatus.google_status === "active";
    }
    return false;
  }, [userGoogleStatus]);

  const formikRef = useRef() as any;
  const [dateValue, setDateValue] = useState(!!event_start_time ? moment(event_start_time) : moment()?.format(""));

  const [focused, setFocused] = useState(false);

  // step 0: select disposition (person spoke to -> disposition)

  // step 1: edit event details (title, date, time, location, description, guests)

  const [step, setStep] = useState(0);

  const editEventSchema = Yup.object().shape({
    id: Yup.string(),
    event_start_time: Yup.string()
      .required("Start time is required")
      .test("Start time is after the present", "Start time must be in the future.", (value) =>
        moment(value).isAfter(moment()),
      ),
    event_end_time: Yup.string().required(),
    event_title: Yup.string(),
    event_location: Yup.string().nullable(),
    timezone: Yup.string(),
    guest_list: Yup.array()
      .notRequired()
      .nullable()
      .of(Yup.string().required("Email address cannot be empty").email("Please enter a valid email address").trim()),
    event_description: Yup.string().nullable(),
    joinMe_id: Yup.string().nullable(),
    lead_id: Yup.string().required(),
    meeting_type: Yup.string().required(),
  });

  const meetingTypeOptions = [
    {
      label: "Phone",
      value: "PHONE",
      customIcon: {
        type: "svg" as "svg" | "img",
        src: phone_white,
        inputIconColor: theme.PRIMARY500,
      },
    },
    ...(googleMeetIntegration && googleMeetIsEnabledOnOrg && selectedEventRepHasGoogleEnabled
      ? [
          {
            label: "Google Meet",
            value: "GOOGLE_MEET",
            customIcon: {
              type: "img" as "svg" | "img",
              src: google_meet_logo,
            },
          },
        ]
      : []),
    ...(zoomIntegration && zoomIsEnabledOnOrg && selectedEventRepHasZoomEnabled
      ? [
          {
            label: "Zoom",
            value: "ZOOM",
            customIcon: {
              type: "img" as "svg" | "img",
              src: zoom_logo,
            },
          },
        ]
      : []),
  ];

  const { data, loading: loadingTimes, error: errorTimes } = useQuery<IFetchAvailableTimes>(AVAILABLE_SPECIFIC_TIMES, {
    variables: {
      day: dateValue,
      timezone: formikRef?.current?.values?.timezone || dataEvent.scheduleItem.timezone || "America/New_York",
      action: dataEvent?.scheduleItem?.action_label || undefined,
      lead_id: lead_id,
      // callback: isThisACallback,
      // reschedule_own_event: true,
      // user_id:
      //   loggedInUser().role === "ADMIN" || loggedInUser().role == "SM"
      //     ? dataEvent?.scheduleItem?.lead_intent?.user?.id
      //     : loggedInUser().id,
      // callback: ["Schedule Callback", "Scheduled Callback", "Schedule Call Back"].includes(
      //   dataEvent?.scheduleItem?.action_label || "",
      // ),
    },
    fetchPolicy: "no-cache",
    onError({ message, name }) {
      // Sentry.captureEvent({
      //   message: `${name} GraphQL Error: ${message}`,
      // });
      console.log(`Error in ${name}: `, message);
    },
  });

  // used in step 1 (edit calendar event)

  const [editCalendarEvent, { loading: loadingEdit, error: errorEdit }] = useMutation(EDIT_CALENDAR_EVENT, {
    async onCompleted({ editCalendarEvent }) {
      console.log("editCalendarEvent: ", editCalendarEvent);
      if (!editCalendarEvent) {
        appToast("Error editCalendarEvent. Something went wrong.");
        return;
      }
      appToast("Event updated!");
    },
    onError({ message }) {
      appToast(message);
      Sentry.captureEvent({
        message: `editCalendarEvent GraphQL Error: ${message}`,
      });
      console.log("Error in editCalendarEvent: ", message);
    },
  });

  const SelectDispoForEditEvent = ({
    values,
    setFieldValue,
    loading,
  }: {
    values: MyFormikProps;
    setFieldValue: (field: string, value: any) => void;
    loading?: boolean;
  }) => {
    return (
      <SelectDispoDiv>
        {loading ? (
          <SkeletonBlock height={100} width={"100%"} borderRadius={6} />
        ) : (
          <SelectDispo
            selectedPersonSpokeTo={values?.selected_person_spoke_to}
            selectedDispositionId={values?.selected_disposition_id}
            selectedEventType={isThisACallback ? "Schedule Callback" : "Reschedule Demo"}
            setSelectedPersonSpokeTo={(personSpokeTo: string) => {
              setFieldValue("selected_person_spoke_to", personSpokeTo);
            }}
            setSelectedDispositionId={(dispositionId: string) => {
              setFieldValue("selected_disposition_id", dispositionId);
            }}
            lead_id={values?.lead_id}
            vertical
          />
        )}
      </SelectDispoDiv>
    );
  };
  const EditEventDetailsMain = ({
    values,
    setFieldValue,
    zoomIsEnabledOnUser,
    googleMeetIsEnabledOnUser,
  }: {
    values: MyFormikProps;
    setFieldValue: (field: string, value: any) => void;
    zoomIsEnabledOnUser: boolean;
    googleMeetIsEnabledOnUser: boolean;
  }) => {
    // find all of the possible emails for the lead
    const { data: leadData } = useQuery(FETCH_LEAD_EMAILS, {
      fetchPolicy: "cache-and-network",
      variables: { id: lead_id, always_return_primary: true },
      skip: !lead_id,
    });

    const [fetchAssociatedLeadsEmails, { data: associatedLeadsData }] = useLazyQuery(FETCH_ASSOCIATED_LEADS_EMAILS, {
      fetchPolicy: "cache-and-network",
    });

    useEffect(() => {
      if (leadData?.fetchLead?.id) {
        fetchAssociatedLeadsEmails({ variables: { primary_lead_id: leadData.fetchLead.id } });
      }
    }, [leadData?.fetchLead?.id, fetchAssociatedLeadsEmails]);

    const leadEmails = useMemo(() => {
      const primaryLeadEmails = leadData?.fetchLead?.primary_email;
      const alternateContacts = leadData?.fetchLead?.alternate_contacts || [];

      // associated leads
      const associatedLeads = associatedLeadsData?.fetchAssociateContact || [];

      const associatedLeadsPrimaryEmails = associatedLeads?.map((lead: any) => lead?.primary_email);
      const associatedLeadsAlternateContacts = associatedLeads?.map((lead: any) => lead?.alternate_contacts) || [];

      const filterAlternateContacts = (alternateContacts: AlternateContactType[]) => {
        return (
          alternateContacts
            ?.map((contact: AlternateContactType) => {
              if (contact?.channel === "Email") {
                return contact.value;
              }
              return null;
            })
            ?.filter(Boolean) || []
        );
      };

      const filteredAlternateContacts = filterAlternateContacts(alternateContacts);
      const filteredAssociatedLeadsAlternateContacts = filterAlternateContacts(associatedLeadsAlternateContacts);

      const emails = [
        primaryLeadEmails ?? null,
        ...filteredAlternateContacts,
        ...associatedLeadsPrimaryEmails,
        ...filteredAssociatedLeadsAlternateContacts,
      ].filter(Boolean);

      return emails?.map((email: string) => ({ label: email, value: email }));
    }, [leadData, associatedLeadsData]);

    const findPersonFromEmail = (email: string) => {
      const primaryLead = leadData?.fetchLead;
      const associatedLeads = associatedLeadsData?.fetchAssociateContact;

      // primary email checks
      if (email === primaryLead?.primary_email) return primaryLead?.full_name || "Primary";

      // associated lead primary email checks
      const associatedLeadPrimaryEmail = associatedLeads?.find((lead: any) => lead?.primary_email === email);
      if (associatedLeadPrimaryEmail) return associatedLeadPrimaryEmail?.full_name || "Associated";

      // alternate contact email checks
      if (primaryLead?.alternate_contacts?.find((contact: AlternateContactType) => contact?.value === email))
        return primaryLead?.full_name || "Primary";

      // associated lead alternate contact email checks
      const associatedLeadAlternateEmail = associatedLeads?.find(
        (lead: any) =>
          lead?.alternate_contacts &&
          lead.alternate_contacts.find((contact: AlternateContactType) => contact?.value === email),
      );
      if (associatedLeadAlternateEmail) return associatedLeadAlternateEmail?.full_name || "Associated";

      return "Guest";
    };

    const showMeetingType =
      (googleMeetIntegration && googleMeetIsEnabledOnOrg && selectedEventRepHasGoogleEnabled) ||
      (zoomIntegration && zoomIsEnabledOnOrg && selectedEventRepHasZoomEnabled);

    return (
      <MainEventDetails>
        <FlexDiv>
          <PhoenixInputField
            name="event_title"
            titleText="Event Title"
            requiredStar
            displayNoContextText
            titleTextSpacing={8}
          />
        </FlexDiv>
        {showMeetingType && (
          <PhoenixMultiSelect
            titleText="Meeting Type"
            isClearable={false}
            name="meeting_type"
            isMulti={false}
            options={meetingTypeOptions}
            value={meetingTypeOptions?.find((option: any) => option?.value === values.meeting_type)}
            onChange={(value: any) => {
              setFieldValue("meeting_type", value?.value);
            }}
            marginBottom={false}
          />
        )}
        <FlexDiv gap={8} direction="column">
          <AppText fontSize={12} fontWeight={500}>
            Select a Date<span style={{ color: theme.ATTENTION700 }}>*</span>
          </AppText>
          <ReactDatesWrapper>
            <SingleDatePicker
              id="make-sale-date-picker"
              required
              date={moment(dateValue)}
              onDateChange={(date: any) => {
                setDateValue(date);
              }}
              focused={focused}
              onFocusChange={({ focused }) => setFocused(focused)}
              numberOfMonths={1}
            />
          </ReactDatesWrapper>
        </FlexDiv>
        <PhoenixMultiSelect
          name="timezone"
          titleText="Select Timezone"
          isClearable={false}
          titleFontWeight={500}
          titleTextSpacing={8}
          isMulti={false}
          required
          options={timezone_list_items}
          value={timezone_list_items.filter((item: any) => item.value === values.timezone)}
          onChange={(e: any) => {
            setFieldValue("timezone", e.value);
          }}
          marginBottom={false}
        />

        <FlexDiv gap={8} direction="column">
          <AppText>
            Select Time<span style={{ color: "red" }}>*</span>
          </AppText>
          {loadingTimes ? (
            <SkeletonBlock height={450} width={"100%"} borderRadius={4} />
          ) : errorTimes ? (
            <AppErrorText>Error loading available times!</AppErrorText>
          ) : (
            <ScrollSegment>
              {/* {JSON.stringify(data)} */}
              {data &&
                data?.fetchAvailableTimesV3 &&
                (() => {
                  const availableTimes = data.fetchAvailableTimesV3.filter((item: AvailableTimeItem) =>
                    moment(formatTimesToStringFormat(item.start_time)).isAfter(moment()),
                  );

                  return availableTimes.length > 0 ? (
                    availableTimes?.map((item: AvailableTimeItem, index: any) => (
                      <NewAppButton
                        variant={"secondary"}
                        key={`${item.user_id}${item.start_time}`}
                        selected={values.event_start_time === formatTimesToStringFormat(item.start_time)}
                        onClick={async () => {
                          setFieldValue("event_start_time", formatTimesToStringFormat(item.start_time));
                        }}
                      >
                        {values.timezone
                          ? moment(formatTimesToStringFormat(item.start_time)).tz(values.timezone).format("h:mm A")
                          : moment(formatTimesToStringFormat(item.start_time)).format("h:mm A")}
                        {!!item.event_count && <span style={{ opacity: 0.6 }}>{` (${item.event_count})`}</span>}
                      </NewAppButton>
                    ))
                  ) : (
                    <AppText>No available times found for this date.</AppText>
                  );
                })()}
            </ScrollSegment>
          )}
        </FlexDiv>

        <PhoenixTextArea
          name="event_description"
          title="Event Description"
          value={values.event_description}
          onChange={(e: any) => {
            setFieldValue("event_description", e.target.value);
          }}
        />
        {zoomIntegration || googleMeetIntegration ? (
          <FlexDiv gap={4} direction="column">
            <AppText fontSize={12} fontWeight={500}>
              Guests
            </AppText>
            <PhoenixMultiSelect
              name="guest_list"
              value={values?.guest_list?.map((guest: string) => ({ label: guest, value: guest }))}
              options={leadEmails}
              creatableOptions
              hideSelectedOptions
              marginBottom={false}
              placeholder="Add guests by their email"
              onChange={(value: any) => {
                // check newest value
                if (
                  value?.map((guest: OptionItem) => guest.value)?.filter((email: string) => !isValidEmail(email))
                    .length > 0
                ) {
                  appToast("Please enter a valid email");
                  return;
                }
                // add value to email list
                setFieldValue(
                  "guest_list",
                  value?.map((guest: any) => guest.value),
                );
              }}
            />
            {values?.guest_list?.map((guest_email: string) => (
              <FlexDiv key={guest_email} justify="space-between" padding="2px 8px">
                <FlexDiv direction="column">
                  <AppText fontSize={12} fontWeight={600}>
                    {findPersonFromEmail(guest_email)}
                  </AppText>
                  <AppText fontSize={10} fontWeight={500} color={theme.text.neutral.secondary}>
                    {guest_email}
                  </AppText>
                </FlexDiv>
              </FlexDiv>
            ))}
          </FlexDiv>
        ) : (
          <FlexDiv gap={8} direction="column">
            <AppText fontSize={12} fontWeight={500}>
              Guests<span style={{ color: "red" }}>*</span>
            </AppText>

            <FieldArray name="guest_list">
              {({ remove, push }) => (
                <>
                  {values.guest_list?.map((item: string, index: number) => (
                    <>
                      <GuestRowDiv>
                        <PhoenixInputField
                          name={`guest_list[${index}]`}
                          value={item}
                          onChange={(e: any) => {
                            setFieldValue(`guest_list[${index}]`, e.target.value);
                          }}
                          displayNoContextText
                        />

                        <AppIconButton
                          onClick={async () => {
                            remove(index);
                          }}
                        >
                          <FiTrash2 />
                        </AppIconButton>
                      </GuestRowDiv>
                    </>
                  ))}

                  <NewAppButton size="sm" variant={"secondary"} onClick={() => push("")}>
                    Add Guest
                  </NewAppButton>
                </>
              )}
            </FieldArray>
          </FlexDiv>
        )}
      </MainEventDetails>
    );
  };

  const loading = loadingEdit;

  const error = errorEdit;

  if (error) {
    return <AppErrorText>Error loading data!</AppErrorText>;
  }

  return (
    <Formik
      innerRef={formikRef}
      enableReinitialize
      initialValues={{
        lead_id: lead_id,
        id: dataEvent?.scheduleItem?.id ?? "",
        event_start_time: !!dataEvent.scheduleItem.start_time
          ? formatTimesToStringFormat(dataEvent.scheduleItem.start_time)
          : "",
        event_end_time: !!dataEvent.scheduleItem.end_time
          ? formatTimesToStringFormat(dataEvent.scheduleItem.end_time)
          : "",
        timezone: !!dataEvent?.scheduleItem?.timezone ? dataEvent.scheduleItem.timezone : "",
        guest_list: dataEvent.scheduleItem.guests?.map((item: any) => item.email) || [],
        event_title: dataEvent.scheduleItem.what || "",
        event_description: dataEvent.scheduleItem.description || "",
        joinMe_id: dataEvent.scheduleItem.join_me_id || "",
        event_location: dataEvent.scheduleItem.where ?? "",
        selected_person_spoke_to: "",
        selected_disposition_id: "",
        meeting_type: dataEvent?.scheduleItem?.zoom_meeting_sellfire_id?.length
          ? "ZOOM"
          : dataEvent?.scheduleItem?.google_space_sellfire_id?.length
          ? "GOOGLE_MEET"
          : "PHONE",
      }}
      validationSchema={editEventSchema}
      onSubmit={async (values) => {
        await editCalendarEvent({
          variables: {
            id: values.id,
            event_start_time: moment(values.event_start_time).utc().toISOString(),
            event_end_time: undefined,
            event_title: values.event_title,
            // derived value not editable by user currently.
            // caused issues with BE when changing meeting type
            // event_location: values.event_location,
            event_description: values.event_description,
            joinMe_id: values.joinMe_id,
            guest_list: values.guest_list,
            disposition_id: values.selected_disposition_id,
            meeting_type: values.meeting_type || "PHONE",
            event_timezone: values.timezone,
          },
        });
        closeModal();
      }}
    >
      {({
        submitForm,
        isSubmitting,
        values,
        setFieldValue,
        dirty,
        isValid,
        errors,
        touched,
      }: //
      FormikProps<MyFormikProps>) => {
        console.log("values: ", values);
        console.log("errors: ", errors);
        console.log("dirty: ", dirty);
        console.log("isValid: ", isValid);
        return (
          <PanelModal open={true} onClose={closeModal} width={440}>
            <ModalContent>
              <Header>
                <AppText fontSize={20} fontWeight={600}>
                  Edit Event
                </AppText>
              </Header>
              {loadingEdit ? (
                <SkeletonBlock height={"100vh"} width={"100%"} borderRadius={4} />
              ) : (
                <MainDiv>
                  {step === 0 && (
                    <SelectDispoForEditEvent values={values} setFieldValue={setFieldValue} loading={loading} />
                  )}
                  {step === 1 && (
                    <EditEventDetailsMain
                      values={values}
                      setFieldValue={setFieldValue}
                      zoomIsEnabledOnUser={selectedEventRepHasZoomEnabled}
                      googleMeetIsEnabledOnUser={selectedEventRepHasGoogleEnabled}
                    />
                  )}
                </MainDiv>
              )}

              <ButtonDiv>
                <PhoenixAppButton buttonType="secondary" variant="danger-outline" onClick={closeModal}>
                  Cancel
                </PhoenixAppButton>
                <PhoenixAppButton
                  variant="brand"
                  buttonType="secondary"
                  disabled={
                    (step === 0 && !values.selected_disposition_id) ||
                    (step === 1 && (!dirty || !isValid)) ||
                    isSubmitting ||
                    loadingEdit
                  }
                  onClick={
                    step === 0
                      ? () => {
                          setStep(1);
                        }
                      : submitForm
                  }
                >
                  {step === 0 ? "Next" : "Save"}
                </PhoenixAppButton>
              </ButtonDiv>
            </ModalContent>
          </PanelModal>
        );
      }}
    </Formik>
  );
};

const MainEventDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
`;
const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding-top: 32px;
  padding-bottom: 16px;
  justify-content: center;
  border-bottom: 1px solid ${theme.NEUTRAL200};
`;

const MainDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  padding: 40px;
  overflow-y: auto;
  flex-grow: 1;
`;

const ButtonDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 16px 40px;
  background-color: ${theme.WHITE_COLOR};
  border-top: 1px solid ${theme.NEUTRAL200};
`;

const SelectDispoDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const ScrollSegment = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 8px;
`;

const GuestRowDiv = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  justify-content: space-between;
  align-items: center;
`;

export { EditEventDetailsV2 };
