import React, { useContext, useRef, useState } from "react";
import { theme } from "../../../utils/theme";
import { AppText, FlexDiv, SkeletonBlock } from "../../UI";

import { gql, useMutation, useQuery } from "@apollo/client";
import { useFlags } from "launchdarkly-react-client-sdk";
import { loggedInUser } from "src/apollo/cache";
import { CallContext, OTFVideoMeeting } from "src/context";
import { caret_up, copy, google_meet_logo, mail, message_square, trash, video, zoom_logo } from "src/images/NewDesign";
import { appToast, errorToast, successToast } from "src/utils/toast";
import styled from "styled-components";
import { OTFMeetingType } from "../../../types";
import { useClickOutside } from "../../../utils/hooks";
import { PhoenixAppButton, PhoenixIcon } from "../../UI/Phoenix";

import { ModalContext } from "src/context/ModalContext";
import { getMeetingIdFromZoomUrl } from "src/utils/format";

interface CreateInstantMeetingResponse {
  createInstantMeeting: {
    google_space_sellfire_id: string;
    zoom_meeting_sellfire_id: string;
    meeting_url: string;
    meeting_code: string;
  };
}

const CREATE_OTF_MEETING = gql`
  mutation CreateInstantMeeting($scheduleItemId: String, $meetingType: MeetingType!, $lead_id: String!) {
    createInstantMeeting(schedule_item_id: $scheduleItemId, meeting_type: $meetingType, lead_id: $lead_id)
  }
`;
const SEND_SMS = gql`
  mutation SendSMS(
    $lead_id: String!
    $text: String!
    $phone_number: String!
    $template_id: String
    $step_action_id: String
  ) {
    sendSMS(
      lead_id: $lead_id
      text: $text
      phone_number: $phone_number
      template_id: $template_id
      step_action_id: $step_action_id
    ) {
      channel
      created_at
      isFromLead
      id
      isFromRep
      isFromUser
    }
  }
`;

const SEND_EMAIL_TO_LEAD = gql`
  mutation sendEmailToLead(
    $lead_id: String!
    $subject: String
    $html: String!
    $emails: [String]
    $template_id: String
    $email_thread_id: String
    $step_action_id: String
  ) {
    sendEmailToLead(
      lead_id: $lead_id
      subject: $subject
      html: $html
      emails: $emails
      template_id: $template_id
      email_thread_id: $email_thread_id
      step_action_id: $step_action_id
    ) {
      id
      lead_activity {
        lead_id
      }
    }
  }
`;

const DELETE_ZOOM_MEETING = gql`
  mutation DeleteZoomMeeting($meetingId: String!) {
    deleteZoomMeeting(meeting_id: $meetingId)
  }
`;

interface FetchLeadResponse {
  fetchLead: {
    id: string;
    primary_phone_number: string;
    primary_email: string;
  };
}

const FETCH_LEAD = gql`
  query fetchLead($id: String!) {
    fetchLead(id: $id) {
      id
      primary_phone_number
      primary_email
    }
  }
`;

interface OTFMeetingSelectProps {
  leadId: string;
  disabled?: boolean;
  height?: number;
  width?: number;
  tooltipText?: string;
}

const OTFMeetingMenu = ({
  setShowOptionsMenu,
  handleMeetingTypeChange,
}: {
  setShowOptionsMenu: (showOptionsMenu: boolean) => void;
  handleMeetingTypeChange: (value: OTFMeetingType) => void;
}) => {
  const containerRef = useRef(null);

  useClickOutside(containerRef, () => setShowOptionsMenu(false));

  const { zoomIntegration, googleMeetIntegration } = useFlags();

  const zoomIsEnabledOnUser = loggedInUser().checkUserCalendarStatus?.zoom_status === "active" ?? false;
  const googleMeetIsEnabledOnUser = loggedInUser().checkUserCalendarStatus?.google_status === "active" ?? false;

  return (
    <OptionsDiv ref={containerRef}>
      {zoomIntegration && zoomIsEnabledOnUser && (
        <Option onClick={() => handleMeetingTypeChange("Zoom")}>
          <img src={zoom_logo} alt="Zoom" height="21px" />
          Create a Zoom Meeting
        </Option>
      )}
      {googleMeetIntegration && googleMeetIsEnabledOnUser && (
        <Option onClick={() => handleMeetingTypeChange("Google")}>
          <img src={google_meet_logo} alt="Google" height="21px" />
          Create A Google Meet
        </Option>
      )}
    </OptionsDiv>
  );
};

const OTFMeetingMenuLiveMeeting = ({
  setShowOptionsMenu,
  createdOTFVideoMeetingType,
  existingLeadOTFVideoMeeting,
  leadId,
}: {
  setShowOptionsMenu: (showOptionsMenu: boolean) => void;
  createdOTFVideoMeetingType: OTFMeetingType;
  existingLeadOTFVideoMeeting: OTFVideoMeeting | null | undefined;
  leadId: string;
}) => {
  const [deleteZoomMeeting, { loading: deleteZoomMeetingLoading, error: deleteZoomMeetingError }] = useMutation(
    DELETE_ZOOM_MEETING,
    {
      onCompleted: (data) => {
        appToast("On-the-fly meeting deleted successfully.");
      },
      onError: (error) => {
        errorToast(error.message);
      },
    },
  );
  const {
    setJoinGoogleMeetModalData,
    setShowJoinGoogleMeetModal,
    setJoinZoomModalData,
    setShowJoinZoomModal,
  } = useContext(ModalContext);
  const { goToCall, phoneNumber, isGoogleMeetCall, removeOTFVideoMeeting, isZoomCall } = useContext(CallContext);

  const { data: leadData, loading: leadLoading, error: leadError } = useQuery<FetchLeadResponse>(FETCH_LEAD, {
    variables: {
      id: leadId,
    },
    fetchPolicy: "cache-and-network",
  });

  const [sendSMS, { loading: sendSMSLoading, error: sendSMSError }] = useMutation(SEND_SMS, {
    onCompleted: (data) => {
      successToast("SMS sent successfully.");
    },
    onError: (error) => {
      errorToast(error.message);
    },
  });

  const [sendEmailToLead, { loading: sendEmailToLeadLoading, error: sendEmailToLeadError }] = useMutation(
    SEND_EMAIL_TO_LEAD,
    {
      onCompleted: (data) => {
        successToast("Email sent successfully.");
      },
      onError: (error) => {
        errorToast(error.message);
      },
    },
  );

  const handleJoinZoomMeeting = ({
    url,
    zoom_meeting_sellfire_id,
    meeting_id,
    leadId,
  }: {
    url?: string;
    zoom_meeting_sellfire_id?: string;
    meeting_id: string;
    leadId?: string;
  }) => {
    if (!url) {
      appToast("Unable to find a valid Zoom URL.");
      return;
    }

    setJoinZoomModalData({
      meetingLink: url,
      dialInToMeeting: ({ newPhoneNumber, newParticipantId }: { newPhoneNumber: string; newParticipantId: string }) => {
        appToast("Dialing into Zoom...");
        goToCall({
          lead_id: leadId,
          phoneNumber: newPhoneNumber,
          presetDigitsToSend: [getMeetingIdFromZoomUrl(url) ?? "", "#", newParticipantId, "#"],
          zoom_meeting_sellfire_id,
        });
      },
    });
    setShowJoinZoomModal(true);
  };

  const handleJoinGoogleMeeting = ({
    url,
    sellfire_meeting_id,
    leadId,
  }: {
    url?: string;
    sellfire_meeting_id?: string;
    leadId: string;
  }) => {
    if (!url) {
      appToast("Unable to find a valid Google Meet URL.");
      return;
    }

    setJoinGoogleMeetModalData({
      meetingLink: url,
      dialInToMeeting: ({ newPhoneNumber, newPin }: { newPhoneNumber: string; newPin: string }) => {
        appToast("Dialing into Google Meet...");
        goToCall({
          lead_id: leadId,
          phoneNumber: newPhoneNumber,
          presetDigitsToSend: [newPin, "#"],
          google_space_sellfire_id: sellfire_meeting_id,
        });
      },
    });
    setShowJoinGoogleMeetModal(true);
  };
  const handleJoinMeeting = ({
    leadId,
    existingLeadOTFVideoMeeting,
    createdOTFVideoMeetingType,
  }: {
    leadId: string;
    existingLeadOTFVideoMeeting: OTFVideoMeeting | null | undefined;
    createdOTFVideoMeetingType: OTFMeetingType;
  }) => {
    if (createdOTFVideoMeetingType === "Zoom") {
      handleJoinZoomMeeting({
        url: existingLeadOTFVideoMeeting?.meeting_url,
        zoom_meeting_sellfire_id: existingLeadOTFVideoMeeting?.sellfire_meeting_id,
        leadId,
        meeting_id: existingLeadOTFVideoMeeting?.meeting_id ?? "",
      });
    } else if (createdOTFVideoMeetingType === "Google") {
      handleJoinGoogleMeeting({
        url: existingLeadOTFVideoMeeting?.meeting_url,
        sellfire_meeting_id: existingLeadOTFVideoMeeting?.sellfire_meeting_id,
        leadId,
      });
    }

    setShowOptionsMenu(false);
  };

  const handleSendSMS = (phoneNumber: string | undefined) => {
    if (!phoneNumber) {
      appToast("Please add a primary phone number to this contact in order to send the meeting link via SMS.");
      return;
    }

    appToast("Sending meeting link via SMS...");

    sendSMS({
      variables: {
        lead_id: leadId,
        text: `You have a meeting on the way. Join here: ${existingLeadOTFVideoMeeting?.meeting_url}`,
        phone_number: phoneNumber,
      },
    });

    setShowOptionsMenu(false);
  };

  const handleSendEmail = (email: string | undefined) => {
    // send email
    if (!email) {
      appToast("Please add a primary email to this contact in order to send the meeting link via email.");
      return;
    }

    appToast("Sending meeting link via email...");

    sendEmailToLead({
      variables: {
        lead_id: leadId,
        html: `You have a meeting on the way. Join here: ${existingLeadOTFVideoMeeting?.meeting_url}`,
        emails: [email],
        subject: `${createdOTFVideoMeetingType} Meeting Invite`,
      },
    });
    setShowOptionsMenu(false);
  };

  const handleCopyLink = () => {
    if (!existingLeadOTFVideoMeeting?.meeting_url) {
      appToast("Unable to find a valid meeting link.");
      return;
    }

    navigator.clipboard.writeText(existingLeadOTFVideoMeeting?.meeting_url);

    appToast("Meeting link copied to clipboard.");

    setShowOptionsMenu(false);
  };

  const handleDeleteMeeting = () => {
    if (createdOTFVideoMeetingType === "Zoom") {
      deleteZoomMeeting({
        variables: {
          meetingId: existingLeadOTFVideoMeeting?.meeting_id ?? "",
        },
      });
    }

    setShowOptionsMenu(false);

    removeOTFVideoMeeting(leadId);
  };

  const onVideoConferenceCall = isGoogleMeetCall || isZoomCall;

  const numberToSendMeetingLinkTo =
    // if on google meet call the phone number used is actually NOT where we want to send the text (it is a dial in number)
    onVideoConferenceCall
      ? leadData?.fetchLead?.primary_phone_number
      : // send to phone number currently called (could be alternate etc...)
        phoneNumber ||
        // default to primary phone number
        leadData?.fetchLead?.primary_phone_number;

  const emailToSendMeetingLinkTo = leadData?.fetchLead?.primary_email;

  const loading = sendSMSLoading || sendEmailToLeadLoading;

  return (
    <OptionsDiv>
      <Option
        disabled={onVideoConferenceCall}
        onClick={() => handleJoinMeeting({ leadId, existingLeadOTFVideoMeeting, createdOTFVideoMeetingType })}
        style={{
          borderBottom: `1px solid ${theme.border.neutral.primary}`,
        }}
      >
        <PhoenixIcon svg={video} color={theme.PRIMARY500} size={16} pointer />
        <FlexDiv direction="column">
          <AppText color={theme.text.brand.primary} fontSize={12} fontWeight={600}>
            Join {createdOTFVideoMeetingType} Meeting
          </AppText>
          <AppText
            color={theme.text.neutral.tertiary}
            fontSize={10}
            fontWeight={400}
            style={{ wordBreak: "break-all" }}
          >
            ID: {existingLeadOTFVideoMeeting?.meeting_id ?? "N/A"}
          </AppText>
        </FlexDiv>
      </Option>
      <Option onClick={() => handleSendSMS(numberToSendMeetingLinkTo)} disabled={!numberToSendMeetingLinkTo}>
        <PhoenixIcon svg={message_square} color={theme.PRIMARY500} size={16} pointer />
        <FlexDiv direction="column">
          Send Via SMS
          <AppText
            color={theme.text.neutral.tertiary}
            fontSize={10}
            fontWeight={400}
            style={{ wordBreak: "break-all" }}
          >
            {numberToSendMeetingLinkTo || "N/A"}
          </AppText>
        </FlexDiv>
      </Option>
      <Option onClick={() => handleSendEmail(emailToSendMeetingLinkTo)} disabled={!emailToSendMeetingLinkTo}>
        <PhoenixIcon svg={mail} color={theme.PRIMARY500} size={16} pointer />
        <FlexDiv direction="column">
          Send Via Email
          <AppText
            color={theme.text.neutral.tertiary}
            fontSize={10}
            fontWeight={400}
            style={{ wordBreak: "break-all" }}
          >
            {emailToSendMeetingLinkTo || "N/A"}
          </AppText>
        </FlexDiv>
      </Option>
      <Option
        onClick={handleCopyLink}
        style={{
          borderBottom: `1px solid ${theme.border.neutral.primary}`,
        }}
      >
        <PhoenixIcon svg={copy} color={theme.PRIMARY500} size={16} pointer />
        Copy Meeting Link
      </Option>
      <Option onClick={handleDeleteMeeting} disabled={onVideoConferenceCall}>
        <PhoenixIcon svg={trash} color={theme.DANGER600} size={16} pointer />
        Delete {createdOTFVideoMeetingType} Meeting
      </Option>
    </OptionsDiv>
  );
};

export const OTFCallMeetingButton = ({ leadId, disabled = false }: OTFMeetingSelectProps) => {
  const { existingOTFVideoMeetings, addOTFVideoMeeting, removeOTFVideoMeeting } = useContext(CallContext);

  const existingLeadOTFVideoMeeting = existingOTFVideoMeetings[leadId] ?? null;

  const createdOTFVideoMeetingType = existingLeadOTFVideoMeeting?.meeting_type;

  const [showOptionsMenu, setShowOptionsMenu] = useState(false);

  const [
    createInstantMeeting,
    { data: createInstantMeetingData, loading: createInstantMeetingLoading, error: createInstantMeetingError },
  ] = useMutation<CreateInstantMeetingResponse>(CREATE_OTF_MEETING, {
    onCompleted: (data) => {
      const isZoomMeeting = data?.createInstantMeeting?.zoom_meeting_sellfire_id;

      const isGoogleMeeting = data?.createInstantMeeting?.google_space_sellfire_id;

      if (isZoomMeeting) {
        addOTFVideoMeeting({
          leadId,
          OTFVideoMeeting: {
            sellfire_meeting_id: data.createInstantMeeting.zoom_meeting_sellfire_id,
            meeting_id: getMeetingIdFromZoomUrl(data?.createInstantMeeting?.meeting_url) ?? "N/A",
            meeting_type: "Zoom",
            meeting_url: data.createInstantMeeting.meeting_url,
            created_at: new Date().toISOString(),
          },
        });

        return;
      }

      if (isGoogleMeeting) {
        addOTFVideoMeeting({
          leadId,
          OTFVideoMeeting: {
            sellfire_meeting_id: data.createInstantMeeting.google_space_sellfire_id,
            meeting_id: data?.createInstantMeeting?.meeting_code ?? "N/A",
            meeting_type: "Google",
            meeting_url: data.createInstantMeeting.meeting_url,
            created_at: new Date().toISOString(),
          },
        });

        return;
      }

      appToast("Unable to create video meeting for this lead.");
      return;
    },
    onError: (error) => {
      appToast("Unable to create video meeting for this lead.");
      console.log("error", error);
    },
  });

  const handleMeetingTypeChange = (value: OTFMeetingType) => {
    if (value === "Zoom" || value === "Google") {
      const BEValueForMeetingType = value === "Zoom" ? "ZOOM" : value === "Google" ? "GOOGLE_MEET" : "N/A";
      createInstantMeeting({
        variables: {
          meetingType: BEValueForMeetingType,
          lead_id: leadId,
        },
      });
      setShowOptionsMenu(false);
    }
  };
  const containerRef = useRef(null);

  useClickOutside(containerRef, () => setShowOptionsMenu(false));

  if (createInstantMeetingError) {
    return <AppText>Error creating meeting</AppText>;
  }

  return (
    <FlexDiv
      align="center"
      style={{
        position: "relative",
      }}
      ref={containerRef}
    >
      {createInstantMeetingLoading ? (
        <SkeletonBlock height={40} width={200} borderRadius={8} />
      ) : (
        <PhoenixAppButton
          variant="brand-outline"
          buttonType="secondary"
          onClick={() => {
            setShowOptionsMenu(!showOptionsMenu);
          }}
          style={{
            display: "flex",
            alignItems: "center",
            gap: 8,
            justifyContent: "flex-end",
          }}
          width={200}
          height={40}
          uppercase
          disabled={disabled}
          padding="0px 0px 0px 24px"
        >
          <PhoenixIcon svg={video} color={theme.PRIMARY500} size={12} pointer />
          <AppText
            color={theme.PRIMARY500}
            fontSize={10}
            fontWeight={600}
            style={{
              marginRight: 10,
              whiteSpace: "nowrap",
            }}
            uppercase
          >
            {createdOTFVideoMeetingType === undefined ? "Create Meeting" : "Meeting Created"}
          </AppText>
          <FlexDiv
            width={40}
            height={40}
            style={{
              borderLeft: `1px solid ${theme.PRIMARY500}`,
            }}
            align="center"
            justify="center"
            padding="0px"
          >
            <PhoenixIcon svg={caret_up} color={theme.PRIMARY500} size={12} pointer />
          </FlexDiv>
        </PhoenixAppButton>
      )}
      {showOptionsMenu &&
        (!existingLeadOTFVideoMeeting ? (
          <OTFMeetingMenu setShowOptionsMenu={setShowOptionsMenu} handleMeetingTypeChange={handleMeetingTypeChange} />
        ) : (
          <OTFMeetingMenuLiveMeeting
            leadId={leadId}
            setShowOptionsMenu={setShowOptionsMenu}
            createdOTFVideoMeetingType={createdOTFVideoMeetingType}
            existingLeadOTFVideoMeeting={existingLeadOTFVideoMeeting}
          />
        ))}
    </FlexDiv>
  );
};

interface OptionsDivProps {
  top?: number;
  left?: number;
}
const OptionsDiv = styled.div<OptionsDivProps>`
  position: absolute;
  bottom: ${(props) => (props.top ? `${props.top}px` : "45px")};
  left: ${(props) => (props.left ? `${props.left}px` : "0px")};
  background-color: ${theme.WHITE_COLOR};
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.15);
  border: 1px solid ${theme.NEUTRAL300};
  border-radius: 4px;
  overflow: hidden;
  width: 200px;
  animation: ${theme.popup} 0.2s ease;
  z-index: 20;
`;

interface OptionProps {
  disabled?: boolean;
}

const Option = styled.div<OptionProps>`
  display: flex;
  align-items: center;
  gap: 8px;

  width: 200px;
  min-height: 40px;
  padding: 8px;

  font-size: 12px;
  line-height: 18px;
  cursor: pointer !important;
  color: ${theme.BLACK_COLOR};

  transition: background-color 0.15s ease, color 0.15s ease;

  :hover {
    background-color: ${theme.PRIMARY100};
    color: ${theme.PRIMARY500};
  }

  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  pointer-events: ${(props) => (props.disabled ? "none" : "auto")};
`;
