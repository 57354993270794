import React from "react";
import { FlexDiv } from "src/Components/UI";
import AssignedRepsTable from "./AssignedRepsTable";
import AssignRepsAction from "./AssignRepsAction";

const AssignedReps = ({ id, reps }: { id: string; reps: any }) => {
  return (
    <FlexDiv direction="column" gap={24} style={{ flex: 1 }}>
      <AssignRepsAction id={id} />
      <AssignedRepsTable id={id} reps={reps} />
    </FlexDiv>
  );
};

export default AssignedReps;
