import React from "react";
import { AppErrorText, AppText, FlexDiv, Loading } from "src/Components/UI";
import { theme } from "src/utils/theme";
import styled from "styled-components";
import RecordingCard from "./RecordingCard";
import { gql } from "@apollo/client";
import { useQuery } from "@apollo/client";
import { loggedInUser } from "src/apollo/cache";
import { VoicemailDrop } from "src/types/VoicemailTypes";

interface FetchVoicemailDropsExpectedResponse {
  fetchVoicemailDrops: VoicemailDrop[];
}

const FETCH_SAVED_VOICEMAIL_DROPS = gql`
  query FetchVoicemailDrops($userId: String) {
    fetchVoicemailDrops(user_id: $userId)
  }
`;

const SavedRecordings = () => {
  const { data, loading, error } = useQuery(FETCH_SAVED_VOICEMAIL_DROPS, {
    fetchPolicy: "cache-and-network",
    variables: {
      userId: loggedInUser().id,
    },
  });

  const hasNoRecordings = data?.fetchVoicemailDrops.length === 0;

  if (loading) return <Loading />;

  if (error) return <AppErrorText>Unable to fetch voicemail recordings</AppErrorText>;

  return (
    <FlexDiv direction="column" gap={40}>
      <Header>
        <AppText fontSize={18} fontWeight={500} lineHeight={24}>
          Saved Recordings
        </AppText>
      </Header>
      {hasNoRecordings ? (
        <NoRecordings />
      ) : (
        data?.fetchVoicemailDrops.map((recording: VoicemailDrop) => (
          <RecordingCard key={recording.id} voicemailDrop={recording} />
        ))
      )}
    </FlexDiv>
  );
};

const NoRecordings = () => {
  return (
    <FlexDiv direction="column" gap={8} align="center" width="364px" style={{ margin: "0 auto" }}>
      <AppText fontSize={12} fontWeight={500} lineHeight={18} color={theme.NEUTRAL300} textAlign="center">
        Start recording
      </AppText>
      <AppText
        fontSize={14}
        fontWeight={400}
        lineHeight={20}
        textAlign="center"
        style={{
          width: "387px",
        }}
      >
        You don’t have any saved voicemail recordings. Create your first voicemail in the recording area above.
      </AppText>
    </FlexDiv>
  );
};

const Header = styled.div`
  height: 32px;
`;
export default SavedRecordings;
