import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { theme } from "src/utils/theme";

interface PhoenixDateInputProps {
  className?: string;
  disabled?: boolean;
  onChange?: (value: string, isValid: boolean) => void;
  value: string;
}

interface FragmentState {
  isTouched: boolean;
  isValid: boolean;
  value: string;
}

const PhoenixDateInput: React.FC<PhoenixDateInputProps> = ({ className, disabled, onChange, value }) => {
  const [month, setMonth] = useState<FragmentState>({ value: "", isValid: false, isTouched: false });
  const [day, setDay] = useState<FragmentState>({ value: "", isValid: false, isTouched: false });
  const [year, setYear] = useState<FragmentState>({ value: "", isValid: false, isTouched: false });

  const monthRef = useRef<HTMLInputElement>(null);
  const dayRef = useRef<HTMLInputElement>(null);
  const yearRef = useRef<HTMLInputElement>(null);

  const validateFragment = (value: string, type: "month" | "day" | "year"): boolean => {
    const num = parseInt(value);
    if (isNaN(num)) return false;

    switch (type) {
      case "month":
        return num >= 1 && num <= 12;
      case "day": {
        const maxDays = month.value ? getDaysInMonth(parseInt(month.value), year.value) : 31;
        return num >= 1 && num <= maxDays;
      }
      case "year":
        return num >= 1900 && num <= 2100;
      default:
        return false;
    }
  };

  const getDaysInMonth = (month: number, yearStr: string): number => {
    const year = yearStr ? parseInt(yearStr) : new Date().getFullYear();
    return new Date(year, month, 0).getDate();
  };

  const handleFragmentChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    type: "month" | "day" | "year",
    nextRef: React.RefObject<HTMLInputElement> | undefined,
    setState: React.Dispatch<React.SetStateAction<FragmentState>>,
  ) => {
    const value = e.target.value.replace(/\D/g, "");
    const maxLength = type === "year" ? 4 : 2;

    if (value.length <= maxLength) {
      const isValid = validateFragment(value, type);
      setState({ value, isValid, isTouched: true });

      if (value.length === maxLength && isValid && nextRef) {
        nextRef.current?.focus();
      }
    }
  };

  const handleBlur = (setState: React.Dispatch<React.SetStateAction<FragmentState>>) => {
    setState((prev) => ({ ...prev, isTouched: true }));
  };

  useEffect(() => {
    if (day.value) {
      const isValid = validateFragment(day.value, "day");
      setDay((prev) => ({ ...prev, isValid }));
    }
  }, [month.value, year.value]);

  useEffect(() => {
    if (value) {
      const [monthStr, dayStr, yearStr] = value.split("/");

      if (monthStr) {
        const isValid = validateFragment(monthStr, "month");
        setMonth({ value: monthStr, isValid, isTouched: false });
      }

      if (dayStr) {
        const isValid = validateFragment(dayStr, "day");
        setDay({ value: dayStr, isValid, isTouched: false });
      }

      if (yearStr) {
        const isValid = validateFragment(yearStr, "year");
        setYear({ value: yearStr, isValid, isTouched: false });
      }
    } else {
      setMonth({ value: "", isValid: false, isTouched: false });
      setDay({ value: "", isValid: false, isTouched: false });
      setYear({ value: "", isValid: false, isTouched: false });
    }
  }, [value]);

  useEffect(() => {
    if (month.value && day.value && year.value.length === 4) {
      const dateString = `${month.value}/${day.value}/${year.value}`;
      const isValid = month.isValid && day.isValid && year.isValid;
      onChange?.(dateString, isValid);
    } else {
      onChange?.("", false);
    }
  }, [month, day, year]);

  return (
    <Container className={className} disabled={disabled}>
      <FragmentWrapper>
        <Fragment
          ref={monthRef}
          value={month.value}
          onChange={(e) => handleFragmentChange(e, "month", dayRef, setMonth)}
          onBlur={() => handleBlur(setMonth)}
          placeholder="MM"
          maxLength={2}
          disabled={disabled}
        />
      </FragmentWrapper>
      <Separator>/</Separator>
      <FragmentWrapper>
        <Fragment
          ref={dayRef}
          value={day.value}
          onChange={(e) => handleFragmentChange(e, "day", yearRef, setDay)}
          onBlur={() => handleBlur(setDay)}
          placeholder="DD"
          maxLength={2}
          disabled={disabled}
        />
      </FragmentWrapper>
      <Separator>/</Separator>
      <FragmentWrapper>
        <Fragment
          ref={yearRef}
          value={year.value}
          onChange={(e) => handleFragmentChange(e, "year", undefined, setYear)}
          onBlur={() => handleBlur(setYear)}
          placeholder="YYYY"
          maxLength={4}
          disabled={disabled}
        />
      </FragmentWrapper>
    </Container>
  );
};

const Container = styled.div<{ disabled?: boolean }>`
  display: flex;
  align-items: center;
  padding: 8px 12px;
  background: ${theme.WHITE_COLOR};
  border: 1px solid ${(props) => (props.disabled ? theme.NEUTRAL200 : theme.NEUTRAL300)};
  border-radius: 4px;
  cursor: ${(props) => (props.disabled ? "default" : "text")};
`;

const FragmentWrapper = styled.div<{ isValid?: boolean; showError?: boolean }>`
  position: relative;

  &:after {
    content: "";
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: ${(props) =>
      props.showError ? theme.DANGER500 : props.isValid ? theme.PRIMARY500 : "transparent"};
    transition: background-color 0.2s ease;
  }
`;

const Fragment = styled.input`
  width: ${(props) => (props.placeholder === "YYYY" ? "40px" : "24px")};
  border: none;
  outline: none;
  text-align: center;
  font-size: 14px;
  color: ${theme.text.neutral.primary};
  background: transparent;

  &:disabled {
    color: ${theme.NEUTRAL200};
    cursor: default;
  }

  &::placeholder {
    color: ${theme.NEUTRAL300};
  }
`;

const Separator = styled.span`
  color: ${theme.NEUTRAL300};
  padding: 0 2px;
`;

export default PhoenixDateInput;
