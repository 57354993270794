import { useFlags } from "launchdarkly-react-client-sdk";
import React, { useContext, useMemo, useState } from "react";
import { Modal } from "src/Components/modal";
import styled from "styled-components";
import { theme } from "../../../utils/theme";
import { AppErrorText, AppText, Loading, SkeletonBlock } from "../../UI";
import { FlexDiv } from "../../UI/FlexDiv";
import { PhoenixAppButton } from "../../UI/Phoenix";

import { ScriptTemplateModal } from "src/Components/modal";
import ScriptPreview from "./script-manager/ScriptPreview";

import { ModalContext } from "src/context";
import gql from "graphql-tag";
import { appToast } from "src/utils/toast";
import { useMutation, useQuery } from "@apollo/client";
import { format } from "util";
import { FetchVoiceMailScriptsExpectedResponse } from "src/types/VoicemailTypes";
import { useFirstRender } from "src/utils/hooks";

const FETCH_VOICEMAIL_SCRIPTS = gql`
  query FetchVoicemailScripts($fetchVoiceMailScriptsId: String) {
    fetchVoiceMailScripts(id: $fetchVoiceMailScriptsId)
  }
`;

const DELETE_VOICEMAIL_SCRIPT = gql`
  mutation DeleteVoiceMailScript($deleteVoiceMailScriptId: String!) {
    deleteVoiceMailScript(id: $deleteVoiceMailScriptId) {
      id
    }
  }
`;
// add or edit script

const ADD_OR_EDIT_SCRIPT = gql`
  mutation CreateOrEditVoiceMailScript($title: String!, $script: String!, $script_id: String!) {
    CreateOrEditVoiceMailScript(title: $title, script: $script, script_id: $script_id) {
      id
      title
      script
      return_call_rate
      organization {
        id
      }
      organization_id
      created_at
      updated_at
      deleted_at
      VoicemailDrop {
        id
      }
    }
  }
`;

export const StepScriptManager: React.FC = () => {
  const {
    data: voiceMailScriptsData,
    loading: voiceMailScriptsLoading,
    error: voiceMailScriptsError,
    refetch: voiceMailScriptsRefetch,
  } = useQuery<FetchVoiceMailScriptsExpectedResponse>(FETCH_VOICEMAIL_SCRIPTS, {
    fetchPolicy: "cache-and-network",
    onCompleted: (data) => {
      console.log(data);
    },
    onError: (error) => {
      appToast("Unable to fetch voicemail scripts");
    },
  });

  const [createOrEditScript, { loading: createOrEditScriptLoading, error: createOrEditScriptError }] = useMutation(
    ADD_OR_EDIT_SCRIPT,
    {
      onCompleted: (data) => {
        console.log(data);
      },
      onError: (error) => {
        appToast("Unable to create or edit voicemail script");
      },
      refetchQueries: ["FetchVoicemailScripts"],
    },
  );
  const { voicemailDrops } = useFlags();
  const { setShowScriptModal, setScriptModalData, scriptModalData, showScriptModal } = useContext(ModalContext);
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [showPreviewScriptID, setShowPreviewScriptID] = useState("");
  const [showDeleteScriptModal, setShowDeleteScriptModal] = useState(false);
  const [showDeleteScriptID, setShowDeleteScriptID] = useState("");

  const handleShowPreviewModal = (scriptID: string) => {
    setShowPreviewModal(true);
    setShowPreviewScriptID(scriptID);
  };

  const handleShowDeleteScriptModal = (scriptID: string) => {
    setShowDeleteScriptModal(true);
    setShowDeleteScriptID(scriptID);
  };

  const handleCreateScript = () => {
    setShowScriptModal(true);
    setScriptModalData({
      script_id: undefined,
    });
  };

  const handleEditScript = (scriptID: string) => {
    setShowScriptModal(true);
    setScriptModalData({
      script_id: scriptID,
    });
  };

  const globalLoading = voiceMailScriptsLoading;
  const globalError = voiceMailScriptsError;

  const noSavedScripts = useMemo(() => {
    return voiceMailScriptsData?.fetchVoiceMailScripts?.length === 0;
  }, [voiceMailScriptsData]);

  const FORMATED_SCRIPTS = useMemo(() => {
    return voiceMailScriptsData?.fetchVoiceMailScripts?.map((script) => ({
      ...script,
      createdAt: format(script.created_at, "MM/dd/yyyy"),
    }));
  }, [voiceMailScriptsData]);

  const isFirstRender = useFirstRender();

  if (!voicemailDrops || voicemailDrops === undefined) return null;

  if (globalLoading && isFirstRender) return <SkeletonBlock height="100%" width="100%" borderRadius={8} />;

  if (globalError) return <AppErrorText>Unable to fetch voicemail scripts</AppErrorText>;

  return (
    <Main>
      {showScriptModal ? <ScriptTemplateModal /> : null}
      {showPreviewModal ? (
        <ScriptPreviewModal
          scriptID={showPreviewScriptID}
          show={showPreviewModal}
          onClose={() => setShowPreviewModal(false)}
        />
      ) : null}
      {showDeleteScriptModal ? (
        <DeleteScriptModal
          scriptID={showDeleteScriptID}
          show={showDeleteScriptModal}
          onClose={() => setShowDeleteScriptModal(false)}
        />
      ) : null}
      <Header>
        <AppText fontSize={22} fontWeight={500} lineHeight={28}>
          Script Management
        </AppText>
        <PhoenixAppButton variant="brand" buttonType="secondary" onClick={handleCreateScript}>
          Create A Script
        </PhoenixAppButton>
      </Header>

      <Body>
        {noSavedScripts ? (
          <NoSavedScriptComponent handleCreateScript={handleCreateScript} />
        ) : (
          FORMATED_SCRIPTS?.map((script) => (
            <ScriptPreview
              key={script.id}
              script={script}
              handleShowPreviewModal={handleShowPreviewModal}
              handleEditScript={handleEditScript}
              handleShowDeleteScriptModal={handleShowDeleteScriptModal}
            />
          ))
        )}
      </Body>
    </Main>
  );
};

const NoSavedScriptComponent = ({ handleCreateScript }: { handleCreateScript: () => void }) => {
  return (
    <FlexDiv direction="column" gap={40} align="center" height="100%">
      <FlexDiv direction="column" gap={8} align="center">
        <AppText fontSize={12} fontWeight={500} lineHeight={24} color={theme.text.neutral.secondary}>
          Let's get started.
        </AppText>
        <AppText fontSize={16} fontWeight={400} lineHeight={24} color={theme.text.neutral.primary}>
          Create or upload your first script.
        </AppText>
      </FlexDiv>
      <PhoenixAppButton variant="brand" buttonType="secondary" onClick={handleCreateScript}>
        Create A Script
      </PhoenixAppButton>
    </FlexDiv>
  );
};

const DeleteScriptModal = ({ scriptID, show, onClose }: { scriptID: string; show: boolean; onClose: () => void }) => {
  const [deleteScript, { loading: deleteScriptLoading, error: deleteScriptError }] = useMutation(
    DELETE_VOICEMAIL_SCRIPT,
    {
      onCompleted: (data) => {
        // console.log(data);
        onClose();
        appToast("Script deleted successfully");
      },
      onError: (error) => {
        appToast("Unable to delete voicemail script");
      },
      refetchQueries: ["FetchVoicemailScripts"],
    },
  );

  const handleDeleteScript = () => {
    deleteScript({ variables: { deleteVoiceMailScriptId: scriptID } });
  };

  return (
    <Modal open={show} onClose={onClose}>
      <ModalMain>
        <AppText fontSize={16} fontWeight={600} color={theme.BLACK_COLOR}>
          Delete Script
        </AppText>
        <AppText fontSize={14} fontWeight={400} color={theme.BLACK_COLOR}>
          Are you sure you want to delete this script?
        </AppText>

        <FlexDiv direction="row" gap={8} align="center" justify="space-between" width="100%" padding="0px 40px">
          <PhoenixAppButton variant="brand-outline" buttonType="secondary" onClick={onClose}>
            Cancel
          </PhoenixAppButton>
          <PhoenixAppButton variant="danger-outline" buttonType="secondary" onClick={handleDeleteScript}>
            Delete
          </PhoenixAppButton>
        </FlexDiv>
      </ModalMain>
    </Modal>
  );
};

const Main = styled.div`
  border: 1px solid ${theme.NEUTRAL100};
  background: ${theme.WHITE_COLOR};
  margin: 24px;
  border-radius: 8px;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  height: 90px;
  padding: 24px 40px;

  border-bottom: 1px solid ${theme.border.neutral.secondary};
`;

const Body = styled.div`
  display: flex;

  gap: 24px;
  flex-wrap: wrap;

  padding: 24px;

  background-color: ${theme.surface.brand.secondary};
`;

const ScriptPreviewModal = ({ scriptID, show, onClose }: { scriptID: string; show: boolean; onClose: () => void }) => {
  const {
    data: scriptData,
    loading: scriptLoading,
    error: scriptError,
  } = useQuery<FetchVoiceMailScriptsExpectedResponse>(FETCH_VOICEMAIL_SCRIPTS, {
    fetchPolicy: "network-only",
    variables: {
      fetchVoiceMailScriptsId: scriptID,
    },
    onCompleted: (data) => {
      console.log(data);
    },
    onError: (error) => {
      appToast("Unable to fetch voicemail script");
    },
  });

  const script = useMemo(() => {
    return scriptData?.fetchVoiceMailScripts?.[0];
  }, [scriptData]);

  return (
    <Modal open={show} onClose={onClose}>
      <ModalMain>
        {scriptError ? (
          <AppErrorText>Unable to fetch voicemail script</AppErrorText>
        ) : (
          <>
            <AppText fontSize={16} fontWeight={600} color={theme.BLACK_COLOR}>
              {script?.title}
            </AppText>
            <ScriptPreviewBody>
              <div dangerouslySetInnerHTML={{ __html: script?.script ?? "" }} />
            </ScriptPreviewBody>
          </>
        )}
      </ModalMain>
    </Modal>
  );
};

const ScriptPreviewBody = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
  padding: 24px;
`;

const ModalMain = styled(FlexDiv)`
  width: 480px;
  height: max-content;
  max-height: 80vh;
  overflow: auto;
  margin: 40px 0px;
  gap: 48px;
  flex-direction: column;
  align-items: center;
`;
