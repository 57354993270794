export const fakeLeadNames = [
  "Apex Innovations",
  "Chief Cycling Lab",
  "Underwater Reality Co.",
  "Levi's Scouting Crew",
  "Infinity Innovations",
  "Toots Travels",
  "Voltrons Vacations",
  "Radical Ramen Bar",
  "Cacau Planting Co.",
];
export const fakeLeadFirstNames = ["John", "Jane", "Michael", "Sarah", "David", "Emily", "Chris", "Amanda", "Matt"];
export const fakeLeadLastNames = [
  "Smith",
  "Johnson",
  "Williams",
  "Jones",
  "Brown",
  "Davis",
  "Miller",
  "Wilson",
  "Moore",
];
export const fakeFullNames = [
  "John Smith",
  "Jane Johnson",
  "Michael Williams",
  "Sarah Jones",
  "David Brown",
  "Emily Davis",
  "Chris Miller",
  "Amanda Wilson",
  "Matt Moore",
];
export const fakeLeadEmails = ["example@gmail.com", "example@yahoo.com", "example@outlook.com"];
export const fakeLeadAddresses = ["123 Main St", "456 Elm St", "789 Oak St", "101 Pine St", "202 Maple St"];
export const fakeLeadPhoneNumbers = [
  "(123) 456-7890",
  "(234) 567-8901",
  "(345) 678-9012",
  "(456) 789-0123",
  "(567) 890-1234",
];
export const fakeLeadCities = [
  "New York",
  "Los Angeles",
  "Chicago",
  "Houston",
  "Phoenix",
  "Philadelphia",
  "San Antonio",
  "San Diego",
];
export const fakeLeadZipCodes = ["10001", "90001", "60601", "77001", "85001", "19101", "78201", "92101"];
export const fakeLeadCountries = ["USA", "Canada", "Mexico", "Brazil", "Argentina", "Chile", "Peru", "Colombia"];
export const fakeLeadStates = ["NY", "CA", "IL", "TX", "AZ", "PA", "TX", "CA"];
export const fakeLeadIndustries = [
  "Retail",
  "Manufacturing",
  "Healthcare",
  "Finance",
  "Education",
  "Technology",
  "Hospitality",
  "Construction",
];
export const fakeLeadSubIndustries = [
  "Apparel",
  "Food & Beverage",
  "Automotive",
  "Pharmaceutical",
  "Banking",
  "Software",
  "Hotel",
  "Real Estate",
];
export const fakeLeadSources = ["Web", "Referral", "Cold Call", "Event", "Social Media", "Email", "Other"];
export const fakeLeadMRR = ["100", "200", "300", "400", "500", "600", "700", "800", "900", "1000"];

type FakeLeadDataMap = {
  BUSINESS_NAME: string[];
  FIRST_NAME: string[];
  LAST_NAME: string[];
  FULL_NAME: string[];
  EMAIL: string[];
  ADDRESS: string[];
  PRIMARY_PHONE_NUMBER: string[];
  CITY: string[];
  ZIP_CODE: string[];
  COUNTRY: string[];
  STATE: string[];
  INDUSTRY: string[];
  SUB_INDUSTRY: string[];
  LEAD_SOURCE: string[];
  MRR: string[];
};

export const fakeLeadDataMap: FakeLeadDataMap = {
  BUSINESS_NAME: fakeLeadNames,
  FIRST_NAME: fakeLeadFirstNames,
  LAST_NAME: fakeLeadLastNames,
  FULL_NAME: fakeFullNames,
  EMAIL: fakeLeadEmails,
  ADDRESS: fakeLeadAddresses,
  PRIMARY_PHONE_NUMBER: fakeLeadPhoneNumbers,
  CITY: fakeLeadCities,
  ZIP_CODE: fakeLeadZipCodes,
  COUNTRY: fakeLeadCountries,
  STATE: fakeLeadStates,
  INDUSTRY: fakeLeadIndustries,
  SUB_INDUSTRY: fakeLeadSubIndustries,
  LEAD_SOURCE: fakeLeadSources,
  MRR: fakeLeadMRR,
};

export const generateFakeLeadData = () => {
  const fakeLeadData: Record<string, any> = {};
  for (const key in fakeLeadDataMap) {
    const typedKey = key as keyof FakeLeadDataMap;
    const randomIndex = Math.floor(Math.random() * fakeLeadDataMap[typedKey].length);
    fakeLeadData[typedKey] = fakeLeadDataMap[typedKey][randomIndex];
  }
  return fakeLeadData;
};
