import React, { useEffect, useMemo, useState } from "react";
import * as Sentry from "@sentry/react";
import { withLDProvider, useLDClient, useFlags } from "launchdarkly-react-client-sdk";
import { useQuery } from "@apollo/client";
import "react-toastify/dist/ReactToastify.css";
import { LOGGED_IN_USER } from "../../apollo/query";
import { LAUNCH_DARKLY_KEY } from "../../utils/variables";

interface Props {}

const LDWrapperComponent: React.FC<Props> = ({ children }) => {
  const [user_id, setUserId] = useState<string | undefined>(undefined);
  const { data: loggedInUser } = useQuery(LOGGED_IN_USER);
  const ldClient = useLDClient();
  console.log("ldClient: ", ldClient);
  if (loggedInUser?.loggedInUser?.id && ldClient && user_id !== loggedInUser?.loggedInUser?.id) {
    setUserId(loggedInUser?.loggedInUser?.id);
    console.log("ld identity update ", loggedInUser?.loggedInUser?.email);
    console.log("ldwrapper init", loggedInUser?.loggedInUser);
    ldClient
      ?.identify({
        key: loggedInUser?.loggedInUser?.id,
        anonymous: false,
        email: loggedInUser?.loggedInUser?.email,
        firstName: loggedInUser?.loggedInUser?.first_name,
        lastName: loggedInUser?.loggedInUser?.last_name,
        name: `${loggedInUser?.loggedInUser?.first_name} ${loggedInUser?.loggedInUser?.last_name}`,
        custom: {
          phone_number: loggedInUser?.loggedInUser?.phone_number || "",
          created_at: loggedInUser?.loggedInUser?.created_at || "",
          joinMe_connected: loggedInUser?.loggedInUser?.joinMe_connected || "",
          manager:
            `${loggedInUser?.loggedInUser?.manager?.first_name} ${loggedInUser?.loggedInUser?.manager?.last_name}` ||
            "",
          organization_name: loggedInUser?.loggedInUser?.organization?.name || "",
          organization_id: loggedInUser?.loggedInUser?.organization_id || "",
          panda_doc_access: loggedInUser?.loggedInUser?.panda_doc_access || "",
          role: loggedInUser?.loggedInUser?.role || "",
          status: loggedInUser?.loggedInUser?.status || "",
          team_name: loggedInUser?.loggedInUser?.team_name || "",
          timekit_type: loggedInUser?.loggedInUser?.timekit_type || "",
          timezone: loggedInUser?.loggedInUser?.timezone || "",
        },
      })
      .then((k) => console.log("user updated to LD: ", k));
  }

  return <Sentry.ErrorBoundary>{children}</Sentry.ErrorBoundary>;
};

const LDWrapper = withLDProvider({ clientSideID: LAUNCH_DARKLY_KEY, options: { bootstrap: "localStorage" } })(
  LDWrapperComponent,
);

export { LDWrapper };
