import React from "react";
import { useField } from "formik";
import { NewAppInput, AppInputProps } from "../UI";

interface InputFieldProps extends AppInputProps {
  name: string;
  title?: string;
  placeholder?: string;
  style?: React.CSSProperties;
  fullWidth?: boolean;
  type?: string;
  disabled?: boolean;
  label?: string;
  textAlign?: string;
  onChange?: (e: any) => void;
  onBlur?: (e: any) => void;
  value?: any;
  id?: string;
  primary?: boolean;
  variant?: "primary" | "error" | "success" | "warning" | undefined;
  height?: number;
  tabIndex?: number;
}

const NewInputField = ({ style, variant, ...props }: InputFieldProps) => {
  const [field, meta] = useField(props.name);
  return (
    <NewAppInput
      {...field}
      {...props}
      context={meta.touched && meta.error}
      variant={!!meta?.error ? "error" : variant}
    />
  );
};

export { NewInputField };
