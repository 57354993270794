/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { gql, useMutation, useQuery } from "@apollo/client";
import * as Sentry from "@sentry/react";
import { Formik, FormikProps } from "formik";
import { useFlags } from "launchdarkly-react-client-sdk";
import * as React from "react";
import { useContext, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { FaMinus, FaPlus } from "react-icons/fa";
import { FcExpand } from "react-icons/fc";
import styled from "styled-components";
import * as Yup from "yup";
import { LiveUserStatusContext, LiveUserStatusItem } from "../../context/LiveUserStatusContext";
import { iconNewCaretDown } from "../../images";
import { chevron_down, chevron_up, cycle, lock, trash } from "../../images/NewDesign";
import { optionsRole, timezone_list_items } from "../../static";
import { allStatesAndProvincesSelectOptions, isoCountries } from "../../static/countries";
import { DUMMY_AVAILABILITY } from "../../utils/misc";
import { theme } from "../../utils/theme";
import { appToast, successToast } from "../../utils/toast";
import { NewFormMultiSelectField, NewInputField, NewSelectField, NewTimeField } from "../Field";
import { AppText, FlexDiv, Loading, NewAppCircleCheckBox, NewAppMultiSelect, NewAppSelect } from "../UI";
import { NewAppButton } from "../UI/NewAppButton";
import { PhoenixIcon, PhoenixInput, PhoenixMultiSelect, PhoenixRadio } from "../UI/Phoenix";
import { DeleteField } from "./DeleteField";
import { Modal } from "./Modal";
import { APP_REP_ROLES, APP_ROLES } from "../../utils/variables";
import { GET_COUNTRY_OPTIONS } from "../Segments/SystemView/LeadSearchFIltersModal";
import { leadHistoryOperators } from "../Segments/LeadsFilterV2/shared";
import { LabelSelect } from "../Smart";
import { UserLabelT } from "src/types";

interface AvailabilityItem {
  id?: string;
  day: string;
  start_minute?: number | null;
  end_minute?: number | null;
  start_hour?: number | null;
  end_hour?: number | null;
}

interface custom_field_white_list_array_item {
  customFieldId: string;
  assign: "OnlyAssign" | "DoNotAssign";
  selected: any[];
}

interface DisappearingDivProps {
  visible: boolean;
  close: () => void;
  /**
   * Only pass in the id if it is an edit operation
   */
  id?: string;
  role?: string;
  email?: string;
  first_name?: string;
  last_name?: string;
  phone_number?: string;
  team_name?: string;
  team?: any;
  manager_id?: string;
  team_id?: string;
  close_rate?: number;
  visible_all_leads_sm?: boolean;
  default_availability?: boolean;
  holds_per_prospect_vs_held_demo_per_dial?: number;
  user_custom_availability?: AvailabilityItem[];
  timezone?: string;
  industry_white_list_array?: any;
  sub_industry_white_list_array?: any;
  lead_source_white_list_array?: any[];
  regions_white_list_array?: string[];
  industry_white_list_assign_type: any;
  sub_industry_white_list_assign_type: any;
  lead_source_white_list_assign_type: any;
  regions_white_list_assign_type: any;
  leads_in_queue?: number | string;
  channel?: string;
  custom_field_white_list_array?: any[];
  auto_assign_no_phone_leads?: boolean | null;
  assigned_countries: string[];
  lead_history_filter?: any;
  userLabels?: UserLabelT[];

  tileState?: {
    teams: any[];
    unassignedUsers: any[];
    setTeams: React.Dispatch<React.SetStateAction<any[]>>;
    setUnassignedUsers: React.Dispatch<React.SetStateAction<any[]>>;
  };
}

interface allAvailableCustomFieldsObject {
  id: string;
  key: string;
  type: string;
  options: string[];
}

interface MyFormikProps {
  role: string;
  email: string;
  first_name: string;
  last_name: string;
  phone_number: string;
  team_name: string;
  site_id: string;
  visible_all_leads_sm: boolean;
  manager_id: string;
  close_rate: number;
  default_availability: boolean;
  holds_per_prospect_vs_held_demo_per_dial: number | null;
  user_custom_availability: AvailabilityItem[];
  timezone: string;
  industry_white_list_array: any[];
  sub_industry_white_list_array: any[];
  lead_source_white_list_array: any[];
  regions_white_list_array: any[];
  industry_white_list_assign_type?: "OnlyAssign" | "DoNotAssign";
  sub_industry_white_list_assign_type?: "OnlyAssign" | "DoNotAssign";
  lead_source_white_list_assign_type?: "OnlyAssign" | "DoNotAssign";
  regions_white_list_assign_type?: "OnlyAssign" | "DoNotAssign";
  channel: string;
  custom_field_white_list_array: custom_field_white_list_array_item[];
  auto_assign_no_phone_leads?: boolean | null;
  assigned_countries: string[];
  team_id?: string;
  lead_history_filter?: any;
  labels_id: string[];
}

interface IExampleTime {
  day: string;
  start_hour?: number;
  start_minute?: number;
  end_hour?: number;
  end_minute?: number;
}

const ADD_USERV2 = gql`
  mutation addUser($user: AddUserInput) {
    addUser(user: $user) {
      id
      role
      email
      first_name
      last_name
      full_name
      phone_number
      team_name
      manager_id
      team_id
      close_rate
      default_availability
      holds_per_prospect_vs_held_demo_per_dial
      timezone
      industry_white_list_array
      sub_industry_white_list_array
      regions_white_list_array
      lead_source_white_list_array
      industry_white_list_assign_type
      sub_industry_white_list_assign_type
      lead_source_white_list_assign_type
      regions_white_list_assign_type
      auto_assign_no_phone_leads
      assigned_countries
      lead_history_filter
      team {
        id
        site_id
        name
        name
        average_close_rate
        label
      }
      user_custom_availability {
        id
        day
        start_minute
        start_hour
        end_minute
        end_hour
      }
      channel
      userLabels {
        label_id
        label {
          id
          name
          color
          description
        }
      }
    }
  }
`;

const UPDATE_USERV2 = gql`
  mutation updateUser($user_id: String!, $user_input: AddUserInput!) {
    updateUser(user_id: $user_id, user_input: $user_input) {
      id
      role
      email
      first_name
      last_name
      full_name
      phone_number
      team_name
      manager_id
      close_rate

      default_availability
      holds_per_prospect_vs_held_demo_per_dial
      team_id
      timezone
      industry_white_list_array
      sub_industry_white_list_array
      regions_white_list_array
      lead_source_white_list_array
      industry_white_list_assign_type
      sub_industry_white_list_assign_type
      lead_source_white_list_assign_type
      regions_white_list_assign_type
      auto_assign_no_phone_leads
      assigned_countries
      lead_history_filter
      team {
        id
        site_id
        name
        name
        average_close_rate
        label
      }
      user_custom_availability {
        id
        day
        start_minute
        start_hour
        end_minute
        end_hour
      }
      channel
      userLabels {
        label_id
        label {
          id
          name
          color
          description
        }
      }
    }
  }
`;

const DELETE_USER = gql`
  mutation deleteUser($user_id: String!) {
    deleteUser(user_id: $user_id) {
      id
    }
  }
`;

const FETCH_TEAM_NAMES = gql`
  query fetchTeamNames {
    fetchTeamNames {
      id
      name
    }
  }
`;

const FETCH_ORG = gql`
  query fetchOrganization {
    fetchOrganization {
      id
      Sites {
        id
        name
      }
      organization_hours {
        id
        day
        start_hour
        start_minute
        end_minute
        end_hour
      }
    }
  }
`;

const GET_POSSIBLE_INDUSTRIES_DATA = gql`
  query fetchIndustryOptions {
    fetchIndustryOptions {
      id
      label
      sub_industries
    }
  }
`;

const GET_UNIQUE_INDUSTRIES_OPTIONS = gql`
  query getUniqueIndustries {
    getUniqueIndustries
  }
`;

const GET_UNIQUE_LEAD_SOURCES_OPTIONS = gql`
  query getUniqueLeadSources {
    getUniqueLeadSources
  }
`;

const FETCH_CUSTOM_FIELDS = gql`
  query FetchCustomFields {
    fetchCustomFields {
      id
      key
      type
      allow_reps_to_edit
      visible
      options
    }
  }
`;

const FormikValidationSchema = Yup.object().shape({
  role: Yup.string().required("Please select a role!"),
  email: Yup.string().required("Please enter an email address!").email("Please enter a valid email address!"),
  first_name: Yup.string().required(),
  last_name: Yup.string().required(),
  team_name: Yup.string().when("role", {
    is: "SM",
    then: Yup.string().required("Field is required"),
  }),
  site_id: Yup.string().when("role", {
    is: "SM",
    then: Yup.string().notRequired(),
  }),
  visible_all_leads_sm: Yup.string().when("role", {
    is: "SM",
    then: Yup.string().required("Field is required"),
  }),
  manager_id: Yup.string().notRequired(),
  close_rate: Yup.number().min(0, "Must be non-negative number").max(100, "Must be less than 100"),
  default_availability: Yup.boolean(),
  holds_per_prospect_vs_held_demo_per_dial: Yup.lazy((value) => {
    if (!!value) {
      return Yup.number().min(0, "Must be non-negative number");
    }
    return Yup.mixed();
  }),
  timezone: Yup.string().notRequired(),
  user_custom_availability: Yup.array().of(
    Yup.object().shape({
      day: Yup.string(),
      start_hour: Yup.number().notRequired().nullable(),
      start_minute: Yup.number().integer().notRequired().nullable(),
      end_minute: Yup.number().integer().notRequired().nullable(),
      end_hour: Yup.number()
        .nullable()
        .moreThan(Yup.ref("start_hour"), "End hour must be after start hour")
        .when("start_hour", {
          is: (start_hour) => !!start_hour || start_hour === 0,
          then: Yup.number().required("End time required"),
          otherwise: Yup.number().notRequired().nullable(),
        }),
    }),
  ),
  industry_white_list_array: Yup.array().notRequired().nullable(),
  sub_industry_white_list_array: Yup.array().notRequired().nullable(),
  lead_source_white_list_array: Yup.array().notRequired().nullable(),
  regions_white_lit_array: Yup.array().notRequired().nullable(),

  industry_white_list_assign_type: Yup.string().required("Please select Only Assign or Do Not Assign"),
  sub_industry_white_list_assign_type: Yup.string().required("Please select Only Assign or Do Not Assign"),
  lead_source_white_list_assign_type: Yup.string().required("Please select Only Assign or Do Not Assign"),
  regions_white_list_assign_type: Yup.string().required("Please select Only Assign or Do Not Assign"),

  channel: Yup.string().required("Please select a channel option"),
  custom_field_white_list_array: Yup.array()
    .of(
      Yup.object().shape({
        customFieldId: Yup.string().required("Please select a custom field to filter by"),
        assign: Yup.string().required("Please select whether to assign or not assign leads using this custom field"),
        selected: Yup.array().required("Please select at least one value to filter by"),
      }),
    )
    .test("customFieldIdIsUnique", "a custom field can only be used once", (value) => {
      if (value) {
        const customFieldIds = value?.map((item: any) => item.customFieldId);
        const uniqueCustomFieldIds = new Set(customFieldIds);
        return customFieldIds.length === uniqueCustomFieldIds.size;
      }
      return true;
    }),
  auto_assign_no_phone_leads: Yup.boolean().notRequired().nullable(),
  assigned_countries: Yup.array().of(Yup.string()).notRequired().nullable(),
  lead_history_filter: Yup.object().shape({
    metric: Yup.string().notRequired().nullable(),
    operator: Yup.string().notRequired().nullable(),
    amount: Yup.number().notRequired().nullable(),
    amount_2: Yup.number().notRequired().nullable(),
    // lowerbound_date: Yup.string().notRequired().nullable(),
    // upperbound_date: Yup.string().notRequired().nullable(),
  }),

  labels_id: Yup.array().notRequired().nullable(),
});

const UpdateUserModalV2: React.FC<DisappearingDivProps> = ({ visible, close, tileState, ...userProps }) => {
  const { teams, unassignedUsers, setTeams, setUnassignedUsers } = tileState || {};

  console.log("userProps", userProps);
  const currentUser = !!userProps?.id;
  const newUser = !userProps?.id;

  const optionsSelect: any[] = [{ label: "Loading...", value: "" }];

  const [deleteModal, setDeleteModal] = useState(false);
  const [confirmUpdateStep, setConfirmUpdateStep] = useState(false);
  const [confirmDiscardStep, setConfirmDiscardStep] = useState(false);
  const [expanded, setExpanded] = useState([
    "general-information",
    // to have a better UX we want the times show by default for custom availability
    "label",
    !userProps?.default_availability ? "availability" : "",
  ] as string[]);

  //button checks

  const [regionsButton, setRegionsButton] = useState(!userProps?.regions_white_list_array?.length);
  const [industriesButton, setIndustriesButton] = useState(!userProps?.industry_white_list_array?.length);
  const [leadSourcesButton, setLeadSourcesButton] = useState(!userProps?.lead_source_white_list_array?.length);
  const [customFieldsButton, setCustomFieldsButton] = useState(!userProps?.custom_field_white_list_array?.length);
  const [countriesButton, setCountriesButton] = useState(!userProps?.assigned_countries?.length);
  const [historyFilterButton, setHistoryFilterButton] = useState(!userProps?.lead_history_filter?.metric);

  const checkCustomAvailabilityChanges = (oldAvailability: AvailabilityItem[], newAvailability: AvailabilityItem[]) => {
    const daysOfWeek = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];

    if (!oldAvailability.length || !newAvailability.length) {
      return false;
    }

    for (const day of daysOfWeek) {
      const oldDay = oldAvailability.find((item: AvailabilityItem) => item.day === day);
      const newDay = newAvailability.find((item: AvailabilityItem) => item.day === day);

      if (
        oldDay?.start_minute !== newDay?.start_minute ||
        oldDay?.end_minute !== newDay?.end_minute ||
        oldDay?.start_hour !== newDay?.start_hour ||
        oldDay?.end_hour !== newDay?.end_hour
      ) {
        return true;
      }
    }

    return false;
  };

  interface ExpandButtonProps {
    expandableKey: string;
  }
  const ExpandButton = (props: ExpandButtonProps) => {
    return (
      <div>
        <NewAppButton
          onClick={() =>
            expanded?.includes(props.expandableKey)
              ? setExpanded(expanded.filter((item) => item !== props.expandableKey))
              : setExpanded([...expanded, props.expandableKey])
          }
        >
          {expanded?.includes(props.expandableKey) ? (
            <PhoenixIcon svg={chevron_down} size={24} color={theme.PRIMARY500} pointer />
          ) : (
            <PhoenixIcon svg={chevron_up} size={24} color={theme.PRIMARY500} pointer />
          )}
        </NewAppButton>
      </div>
    );
  };

  interface ExpandableDivProps {
    expandableKey: string;
    showToggle?: boolean;
    children: React.ReactNode;
    blueBackground?: boolean;
    style?: any;
  }

  const ExpandableDiv = ({
    expandableKey,
    showToggle = true,
    blueBackground = false,
    children,
    style,
  }: ExpandableDivProps) => {
    return (
      <div
        style={{
          position: "relative",
          borderBottom: `1px solid ${theme.NEUTRAL200}`,
          paddingBottom: "20px",
          backgroundColor: blueBackground ? "#ECF1FF" : theme.WHITE_COLOR,
          ...style,
        }}
      >
        {showToggle && (
          <div
            style={{
              position: "absolute",
              right: "0px",
              top: "10px",
            }}
          >
            <ExpandButton expandableKey={expandableKey} />
          </div>
        )}

        {children}
      </div>
    );
  };

  const [updateUser, { loading, error }] = useMutation(UPDATE_USERV2, {
    onCompleted({ updateUser }) {
      console.log("updateUser: ", updateUser);
      if (!updateUser) {
        appToast("Error: Something went wrong!");
        return;
      }
      appToast("User Updated!");
      close();
    },
    onError({ message }) {
      appToast(message);
      Sentry.captureEvent({
        message: `updateUser GraphQL Error: ${message}`,
      });
      console.log("Error in updateUser: ", message);
    },
    awaitRefetchQueries: false,
    refetchQueries: ["fetchUnassignedUsers", "fetchTeams"],
  });

  const { data: orgData, loading: orgLoading, error: orgError, refetch: orgRefetch } = useQuery(FETCH_ORG, {
    fetchPolicy: "network-only",
    onError({ message, name }) {
      console.log(`Error in ${name}: `, message);
    },
  });

  const [addUser, { loading: addLoading, error: addError }] = useMutation(ADD_USERV2, {
    onCompleted({ addUser }) {
      console.log("Add admin: ", addUser);
      if (!addUser) {
        appToast("Error: Something went wrong!");
        return;
      }
      successToast("Successfully added user!");

      if (["AE", "SDR"].includes(addUser.role) && unassignedUsers && setUnassignedUsers) {
        const newUnassignedUsers = [...unassignedUsers];
        newUnassignedUsers.push(addUser);
        setUnassignedUsers(newUnassignedUsers);
      }

      close();
    },
    onError({ message }) {
      appToast(message);
      Sentry.captureEvent({
        message: `addUser GraphQL Error: ${message}`,
      });
      console.log("Error in addUser: ", message);
    },
  });

  const { setLiveUserStatus, liveUserStatus } = useContext(LiveUserStatusContext);

  const [deleteUser, { loading: deleteLoading, error: deleteError }] = useMutation(DELETE_USER, {
    onCompleted({ deleteUser }) {
      if (!deleteUser) {
        appToast("Error: Something went wrong!");
        return;
      }
      appToast("User deleted!");

      // remove the user from the list of users in the admin's ACTIVE CALLS panel
      if (liveUserStatus) {
        setLiveUserStatus(
          liveUserStatus.filter((user: LiveUserStatusItem) => {
            return user?.user_id !== userProps?.id;
          }),
        );
      }

      const deletingSM = teams?.find((team: any) => team?.team_leader?.id === deleteUser?.id);

      if (!!deletingSM && setTeams) {
        const newTeams = teams?.filter((team: any) => team?.team_leader?.id !== deleteUser?.id) || [];
        setTeams(newTeams);
        close();
        return;
      }

      const deletedUserTeam = teams?.find((team: any) =>
        team.active_members?.find((member: any) => member.id === deleteUser.id),
      );
      const teamIndex = teams?.findIndex((team: any) => team?.id === deletedUserTeam?.id);

      if (teamIndex !== -1 && teamIndex !== undefined && teams && setTeams) {
        const newTeams = [...teams];
        deletedUserTeam.active_members = deletedUserTeam.active_members.filter(
          (member: any) => member.id !== deleteUser.id,
        );
        teams[teamIndex] = deletedUserTeam;
        setTeams(newTeams);
      } else {
        const unassignedIndex = unassignedUsers?.findIndex((user: any) => user?.id === deleteUser.id);
        if (unassignedIndex !== -1 && unassignedIndex !== undefined && unassignedUsers && setUnassignedUsers) {
          const newUnassignedUsers = [...unassignedUsers];
          newUnassignedUsers.splice(unassignedIndex, 1);
          setUnassignedUsers(newUnassignedUsers);
        }
      }

      close();
    },
    onError({ message }) {
      appToast(message);
      Sentry.captureEvent({
        message: `deleteUser GraphQL Error: ${message}`,
      });
      console.log("Error in deleteUser: ", message);
    },
  });

  const { data, loading: loadingTeam, error: teamError } = useQuery(FETCH_TEAM_NAMES, {
    fetchPolicy: "network-only",
    onError({ message, name }) {
      // Sentry.captureEvent({
      //   message: `${name} GraphQL Error: ${message}`,
      // });
      console.log(`Error in ${name}: `, message);
    },
  });

  const { data: industryUniqueData, loading: industryUniqueLoading, error: industryUniqueError } = useQuery(
    GET_UNIQUE_INDUSTRIES_OPTIONS,
    {
      fetchPolicy: "network-only",
      onError({ message, name }) {
        console.log(`Error in ${name}: `, message);
      },
    },
  );

  const { data: subindustryUniqueData, loading: subindustryUniqueLoading, error: subindustryUniqueError } = useQuery(
    GET_POSSIBLE_INDUSTRIES_DATA,
    {
      fetchPolicy: "network-only",
      onError({ message, name }) {
        console.log(`Error in ${name}: `, message);
      },
    },
  );

  const { data: leadSourceUniqueData, loading: leadSourceUniqueLoading, error: leadSourceUniqueError } = useQuery(
    GET_UNIQUE_LEAD_SOURCES_OPTIONS,
    {
      fetchPolicy: "network-only",
      onError({ message, name }) {
        console.log(`Error in ${name}: `, message);
      },
    },
  );

  const { data: countryOptions, loading: countryLoading, error: countryError } = useQuery(GET_COUNTRY_OPTIONS, {
    fetchPolicy: "cache-and-network",
    onCompleted(data) {
      console.log("countryOptions", JSON.stringify(data));
    },
    onError({ message, name }) {
      console.log(`Error in ${name}: `, message);
    },
  });

  const countryOptionsArray = [
    ...(countryLoading
      ? []
      : countryOptions?.fetchNonIsoCountryOptions
          ?.map((item: string) => item)
          .filter((item: string) => item && item?.trim() !== "")
          .sort((a: string, b: string) => (`${a}`.toLowerCase() > `${b}`.toLowerCase() ? 1 : -1))
          .map((item: string) => ({
            value: item,
            label: item,
          }))),
    ...isoCountries
      .map((item) => ({
        value: item["alpha-2"],
        label: `${item["alpha-2"]} (${item["name"]})`,
      }))
      .sort((a: any, b: any) => (a?.value === "US" ? -1 : b?.value === "US" ? 1 : a?.value < b?.value ? -1 : 1)),
    ,
  ];

  const {
    data: allCustomFieldOptions,
    loading: allCustomFieldOptionsLoading,
    error: allCustomFieldOptionsError,
  } = useQuery(FETCH_CUSTOM_FIELDS, { fetchPolicy: "network-only" });

  if (leadSourceUniqueError) return <p>Error loading Lead Sources</p>;
  if (industryUniqueError) return <p>Error loading Industries</p>;
  if (subindustryUniqueError) return <p>Error loading Sub Industries</p>;
  if (loadingTeam) return <Loading />;

  console.log("lead source options: ", leadSourceUniqueData);
  console.log("industry options: ", industryUniqueData);

  if (teamError) {
    return <p>Error loading admins</p>;
  }

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        role: userProps.role || "",
        first_name: userProps.first_name || "",
        last_name: userProps.last_name || "",
        phone_number: userProps.phone_number || "",
        email: userProps.email || "",
        team_name: userProps.team_name || "",
        site_id: userProps.team?.site_id || "",
        visible_all_leads_sm: userProps.visible_all_leads_sm || false,
        manager_id: userProps.manager_id || "",
        team_id: userProps.team_id || "",
        close_rate: userProps.close_rate || 0,

        timezone: userProps.timezone ?? "",
        default_availability: userProps.default_availability ?? true,
        user_custom_availability: DUMMY_AVAILABILITY?.map((item: AvailabilityItem) => {
          const userCustomAvailability = userProps?.user_custom_availability?.find(
            (userItem: { day: string }) => userItem.day === item.day,
          );

          const orgDefaultAvailability = orgData?.fetchOrganization?.organization_hours?.find(
            (orgItem: { day: string }) => orgItem.day === item.day,
          );

          if (userCustomAvailability) {
            return {
              id: userCustomAvailability?.id ?? item.id,
              day: userCustomAvailability?.day ?? item.day,
              start_minute: userCustomAvailability?.start_minute ?? item.start_minute,
              end_minute: userCustomAvailability?.end_minute ?? item.end_minute,
              start_hour: userCustomAvailability?.start_hour ?? item.start_hour,
              end_hour: userCustomAvailability?.end_hour ?? item.end_hour,
            };
          }

          if (orgDefaultAvailability) {
            return {
              day: orgDefaultAvailability?.day ?? item.day,
              start_minute: orgDefaultAvailability?.start_minute ?? item.start_minute,
              end_minute: orgDefaultAvailability?.end_minute ?? item.end_minute,
              start_hour: orgDefaultAvailability?.start_hour ?? item.start_hour,
              end_hour: orgDefaultAvailability?.end_hour ?? item.end_hour,
            };
          }

          return item;
        }),
        holds_per_prospect_vs_held_demo_per_dial: userProps.holds_per_prospect_vs_held_demo_per_dial || null,
        industry_white_list_assign_type: userProps.industry_white_list_assign_type || "OnlyAssign",
        sub_industry_white_list_assign_type: userProps.sub_industry_white_list_assign_type || "OnlyAssign",
        lead_source_white_list_assign_type: userProps.lead_source_white_list_assign_type || "OnlyAssign",
        regions_white_list_assign_type: userProps.regions_white_list_assign_type || "OnlyAssign",
        industry_white_list_array: !!userProps.industry_white_list_array
          ? userProps.industry_white_list_array?.map((item: string) => ({ label: item, value: item }))
          : [],
        sub_industry_white_list_array: !!userProps.sub_industry_white_list_array
          ? userProps.sub_industry_white_list_array?.map((item: string) => ({ label: item, value: item }))
          : [],
        lead_source_white_list_array: !!userProps.lead_source_white_list_array
          ? userProps.lead_source_white_list_array?.map((item: string) => ({ label: item, value: item }))
          : [],
        channel: userProps.channel ?? "Both",
        regions_white_list_array: !!userProps.regions_white_list_array
          ? userProps.regions_white_list_array?.map((item: string) => {
              // find the region in the region options if they exist
              const region = allStatesAndProvincesSelectOptions?.find((region: any) => region?.value === item);
              if (region) {
                return { label: region?.label, value: region?.value };
              }
              return { label: item, value: item };
            })
          : [],
        custom_field_white_list_array: userProps?.custom_field_white_list_array
          ? userProps.custom_field_white_list_array?.map((item: any) => ({
              assign: item?.assign,
              customFieldId: item?.custom_field?.id,
              selected: item.selected ? item?.selected?.map((item: any) => ({ label: item, value: item })) : [],
            }))
          : [],
        auto_assign_no_phone_leads: userProps?.auto_assign_no_phone_leads ?? null,
        assigned_countries: userProps?.assigned_countries ?? [],
        lead_history_filter: {
          metric: userProps?.lead_history_filter?.operator ? "dial" : undefined,
          operator: userProps?.lead_history_filter?.operator ?? "",
          amount: userProps?.lead_history_filter?.amount ?? 0,
          amount_2: userProps?.lead_history_filter?.amount_2 ?? 0,
        },

        labels_id: userProps?.userLabels?.map((item: UserLabelT) => item?.label?.id) ?? [],
      }}
      validationSchema={FormikValidationSchema}
      onSubmit={async ({
        team_name,
        site_id,
        user_custom_availability,
        industry_white_list_array,
        sub_industry_white_list_array,
        ...values
      }) => {
        console.log("submit values: ", values);
        console.log("submit availability: ", user_custom_availability);
        if (userProps?.id) {
          await updateUser({
            variables: {
              user_id: userProps?.id,
              user_input: {
                role: values.role,
                email: values.email,
                first_name: values.first_name,
                last_name: values.last_name,
                phone_number: values.phone_number,
                manager_id: values.manager_id,
                close_rate: values.close_rate,
                team_id: values.team_id,
                // taking_demos: { set: values.taking_demos },
                // @ts-ignore
                // taking_demos: values.taking_demos === true || values.taking_demos === "true" ? true : false,
                default_availability: values.default_availability,
                holds_per_prospect_vs_held_demo_per_dial: values.holds_per_prospect_vs_held_demo_per_dial,
                timezone: values.timezone,
                team_name,
                site_id,
                availability: user_custom_availability,
                visible_all_leads_sm: values.visible_all_leads_sm,
                channel: values.channel || "Both",
                industry_white_list_array: industry_white_list_array?.map((item: any) => item.value),
                sub_industry_white_list_array: sub_industry_white_list_array?.map((item: any) => item.value),
                lead_source_white_list_array: values.lead_source_white_list_array?.map((item: any) => item.value),
                regions_white_list_array: values.regions_white_list_array?.map((item: any) => item.value),
                custom_field_white_list_array: values.custom_field_white_list_array?.map((item: any) => {
                  return {
                    customFieldId: item.customFieldId,
                    assign: item.assign,
                    selected: !!item?.selected ? item?.selected?.map((item: any) => item.value) : [],
                  };
                }),
                industry_white_list_assign_type: values.industry_white_list_assign_type,
                sub_industry_white_list_assign_type: values.sub_industry_white_list_assign_type,
                lead_source_white_list_assign_type: values.lead_source_white_list_assign_type,
                regions_white_list_assign_type: values.regions_white_list_assign_type,
                auto_assign_no_phone_leads: values.auto_assign_no_phone_leads,
                assigned_countries: values.assigned_countries,
                lead_history_filter: {
                  metric: values.lead_history_filter?.operator ? "dial" : undefined,
                  operator: values.lead_history_filter?.operator ?? "",
                  amount: values.lead_history_filter?.amount ?? 0,
                  amount_2: values.lead_history_filter?.amount_2 ?? 0,
                },
                labels_id: values.labels_id,
              },
            },
          });
        } else {
          await addUser({
            variables: {
              user: {
                ...values,
                // @ts-ignore
                // taking_demos: values.taking_demos === true || values.taking_demos === "true" ? true : false,
                industry_white_list_array: industry_white_list_array?.map((item: any) => item.value),
                sub_industry_white_list_array: sub_industry_white_list_array?.map((item: any) => item.value),
                lead_source_white_list_array: values.lead_source_white_list_array?.map((item: any) => item.value),
                team_name,
                site_id,
                visible_all_leads_sm: values.visible_all_leads_sm,
                availability: user_custom_availability,
                channel: values.channel || "Both",
                regions_white_list_array: values.regions_white_list_array?.map((item: any) => item.value),
                custom_field_white_list_array: values.custom_field_white_list_array?.map((item: any) => {
                  return {
                    customFieldId: item.customFieldId,
                    assign: item.assign,
                    selected: item?.selected ? item?.selected?.map((item: any) => item.value) : [],
                  };
                }),
                industry_white_list_assign_type: values.industry_white_list_assign_type,
                sub_industry_white_list_assign_type: values.sub_industry_white_list_assign_type,
                lead_source_white_list_assign_type: values.lead_source_white_list_assign_type,
                regions_white_list_assign_type: values.regions_white_list_assign_type,
                auto_assign_no_phone_leads: values.auto_assign_no_phone_leads,
                assigned_countries: values.assigned_countries,
                lead_history_filter: {
                  metric: values.lead_history_filter?.operator ? "dial" : undefined,
                  operator: values.lead_history_filter?.operator ?? "",
                  amount: values.lead_history_filter?.amount ?? 0,
                  amount_2: values.lead_history_filter?.amount_2 ?? 0,
                },
                labels_id: values.labels_id,
              },
            },
            update(cache, { data, data: { addUser } }) {
              if (addUser.role === "ADMIN") {
                return;
              }
              if (addUser.role === "SM") {
                cache.modify({
                  fields: {
                    fetchTeams(existingTeams, { readField }) {
                      return [...existingTeams, addUser.team];
                    },
                  },
                });
                return;
              }
              if (addUser.team_id) {
                cache.modify({
                  id: cache.identify(addUser.team),
                  fields: {
                    active_members(existingMembers, { readField }) {
                      return [...existingMembers, addUser];
                    },
                  },
                });
                return;
              }
              // Add a user to unassigned user list
              cache.modify({
                fields: {
                  fetchUnassignedUsers(existingUsers, { readField }) {
                    const newUserRef = cache.writeFragment({
                      data: addUser,
                      fragment: gql`
                        fragment NewUser on User {
                          id
                          role
                          email
                          first_name
                          last_name
                          full_name
                          phone_number
                          team_name
                          manager_id
                          close_rate

                          default_availability
                          holds_per_prospect_vs_held_demo_per_dial
                          auto_assign_no_phone_leads
                          assigned_countries
                          lead_history_filter
                          timezone
                          user_custom_availability {
                            id
                            day
                            start_minute
                            start_hour
                            end_minute
                            end_hour
                          }
                          channel
                          industry_white_list_array
                          sub_industry_white_list_array
                          lead_source_white_list_array
                          regions_white_list_array
                          custom_field_white_list_array
                          industry_white_list_assign_type
                          sub_industry_white_list_assign_type
                          lead_source_white_list_assign_type
                          regions_white_list_assign_type
                          userLabels {
                            label_id
                            label {
                              id
                              name
                              color
                              description
                            }
                          }
                        }
                      `,
                    });
                    return [...existingUsers, newUserRef];
                  },
                },
              });
            },
          });
        }
      }}
    >
      {({
        submitForm,
        values,
        setFieldValue,
        isSubmitting,
        isValid,
        dirty,
        errors,
        touched,
        setFieldTouched,
      }: FormikProps<MyFormikProps>) => {
        console.log("errors: ", errors);
        console.log("values: ", values);

        const userHasEditedValues = () => {
          if (newUser) {
            return false;
          }

          return (
            userProps?.first_name !== values.first_name ||
            userProps?.last_name !== values.last_name ||
            userProps?.email !== values.email ||
            (APP_REP_ROLES.includes(userProps?.role as APP_ROLES) && userProps?.phone_number !== values.phone_number) ||
            userProps?.role !== values.role ||
            (userProps?.close_rate ?? 0) !== (values.close_rate ?? 0) ||
            userProps?.holds_per_prospect_vs_held_demo_per_dial !== values.holds_per_prospect_vs_held_demo_per_dial ||
            userProps?.timezone !== values.timezone ||
            JSON.stringify(userProps?.industry_white_list_array ?? []) !==
              JSON.stringify(values.industry_white_list_array?.map((i: any) => i.value)) ||
            JSON.stringify(userProps?.sub_industry_white_list_array ?? []) !==
              JSON.stringify(values.sub_industry_white_list_array?.map((i: any) => i.value)) ||
            JSON.stringify(userProps?.lead_source_white_list_array ?? []) !==
              JSON.stringify(values.lead_source_white_list_array?.map((i: any) => i.value)) ||
            JSON.stringify(userProps?.regions_white_list_array ?? []) !==
              JSON.stringify(values.regions_white_list_array?.map((i: any) => i.value)) ||
            (userProps?.industry_white_list_assign_type || "OnlyAssign") !== values.industry_white_list_assign_type ||
            (userProps?.sub_industry_white_list_assign_type || "OnlyAssign") !==
              values.sub_industry_white_list_assign_type ||
            (userProps?.lead_source_white_list_assign_type || "OnlyAssign") !==
              values.lead_source_white_list_assign_type ||
            (userProps?.regions_white_list_assign_type || "OnlyAssign") !== values.regions_white_list_assign_type ||
            (userProps?.channel || "Both") !== values.channel ||
            (userProps?.custom_field_white_list_array?.length
              ? userProps?.custom_field_white_list_array?.length
              : 0) !==
              (values.custom_field_white_list_array?.length ? values.custom_field_white_list_array?.length : 0) ||
            // user has switched from default availability to custom availability
            userProps?.default_availability !== values.default_availability ||
            // user has updated the start or end time for any day of their custom availability
            checkCustomAvailabilityChanges(
              userProps?.user_custom_availability ?? [],
              values.user_custom_availability ?? [],
            ) ||
            JSON.stringify(userProps?.assigned_countries ?? []) !== JSON.stringify(values.assigned_countries ?? []) ||
            JSON.stringify(userProps?.lead_history_filter ?? {}) !== JSON.stringify(values.lead_history_filter ?? {})
          );
        };

        const handleConfirmUpdate = () => {
          submitForm();
        };
        const subIndustriesOptions = (vals: any) => {
          return subindustryUniqueData.fetchIndustryOptions
            .slice()
            .filter((item: any) => !!item.label && !!item.sub_industries.length)
            ?.map((ls: any) => ({
              label: ls.label,
              options: ls.sub_industries?.map((si: string) => ({
                label: si,
                value: si,
              })),
            }))
            .filter((item: any) => vals?.map((item: any) => item.value).includes(item.label));
        };

        const removeRegionAssignment = (region: string) => {
          const newRegionWhiteList = values.regions_white_list_array.filter((item: any) => item?.value !== region);
          setFieldValue("regions_white_list_array", newRegionWhiteList);

          if (newRegionWhiteList.length === 0) {
            setRegionsButton(true);
          }
        };

        const removeIndustry = (industry: string) => {
          // remove the industry
          const newIndustryWhiteList = values.industry_white_list_array.filter((item: any) => item?.value !== industry);

          setFieldValue("industry_white_list_array", newIndustryWhiteList);

          // remove the sub industries associated with the industry

          const newSubIndustryWhiteList = values.sub_industry_white_list_array.filter((subIndustry: any) =>
            subindustryUniqueData.fetchIndustryOptions.forEach((item: any) => {
              if (item.sub_industries.includes(subIndustry.value)) {
                return item.label !== industry;
              }
            }),
          );

          setFieldValue("sub_industry_white_list_array", newSubIndustryWhiteList);

          if (newIndustryWhiteList.length === 0 && values.sub_industry_white_list_array.length === 0) {
            setIndustriesButton(true);
          }
        };

        const removeSubIndustry = (subIndustry: string) => {
          const newSubIndustryWhiteList = values.sub_industry_white_list_array.filter(
            (item: any) => item?.value !== subIndustry,
          );
          setFieldValue("sub_industry_white_list_array", newSubIndustryWhiteList);

          if (newSubIndustryWhiteList.length === 0 && values.industry_white_list_array.length === 0) {
            setIndustriesButton(true);
          }
        };

        const removeLeadSource = (leadSource: string) => {
          const newLeadSourceWhiteList = values.lead_source_white_list_array.filter(
            (item: any) => item?.value !== leadSource,
          );
          setFieldValue("lead_source_white_list_array", newLeadSourceWhiteList);

          if (newLeadSourceWhiteList.length === 0) {
            setLeadSourcesButton(true);
          }
        };

        const removeCustomField = (index: number) => {
          const newCustomFieldWhiteList = values.custom_field_white_list_array.filter(
            (item: any, i: number) => i !== index,
          );
          setFieldValue("custom_field_white_list_array", newCustomFieldWhiteList);

          if (values.custom_field_white_list_array.length === 0) {
            setCustomFieldsButton(true);
          }
        };

        const removeCountry = (country: string) => {
          const newCountryWhiteList = values.assigned_countries.filter((item: string) => item !== country);
          setFieldValue("assigned_countries", newCountryWhiteList);

          if (newCountryWhiteList.length === 0) {
            setCountriesButton(true);
          }
        };

        const addCustomField = () => {
          const newCustomFieldWhiteListObject: custom_field_white_list_array_item = {
            customFieldId: "",
            assign: "OnlyAssign",
            selected: [],
          };
          const newCustomFieldWhiteList = [...values.custom_field_white_list_array, newCustomFieldWhiteListObject];
          setFieldValue("custom_field_white_list_array", newCustomFieldWhiteList);
        };

        const ResetFilters = ({ formikValue }: { formikValue: string }) => {
          return (
            <ResetFiltersDiv
              style={{ cursor: "pointer" }}
              onClick={() => {
                setFieldValue(
                  formikValue,
                  formikValue === "lead_history_filter"
                    ? {
                        metric: "",
                        operator: "",
                        amount: 0,
                        amount_2: 0,
                      }
                    : [],
                );
              }}
            >
              <img src={cycle} alt="reset" style={{ marginRight: 5, height: 11 }} />
              <AppText
                fontWeight={500}
                fontSize={10}
                style={{
                  color: theme.PRIMARY500,
                }}
              >
                Reset Filters
              </AppText>
            </ResetFiltersDiv>
          );
        };

        const handleCloseModalLogic = () => {
          if (confirmDiscardStep) {
            close();
          } else if (!userHasEditedValues()) {
            close();
          } else if (confirmUpdateStep) {
            setConfirmUpdateStep(false);
          } else {
            setConfirmDiscardStep(true);
          }
        };

        return (
          <>
            {deleteModal ? (
              <PopupContainerDiv blinds={deleteModal}>
                <DeleteField
                  userDelete
                  loading={deleteLoading}
                  blinds={deleteModal}
                  close={() => setDeleteModal(!deleteModal)}
                  onDeleteFunction={async () => {
                    await deleteUser({ variables: { user_id: userProps.id } });
                  }}
                  fieldName={`${userProps.first_name} ${userProps.last_name}`}
                  fieldType="Rep"
                  deleteUserID={userProps.id}
                  keepOpenOnLoading={true}
                />
              </PopupContainerDiv>
            ) : (
              <PopupContainerDiv blinds={visible}>
                <Modal
                  open={visible}
                  onClose={() => {
                    handleCloseModalLogic();
                  }}
                >
                  {confirmUpdateStep ? (
                    <FinalStepDiv>
                      <div
                        style={{
                          width: "396px",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          gap: "48px",
                        }}
                      >
                        <FinalStepTitle>Are You Sure?</FinalStepTitle>
                        <FinalStepSubTitle>
                          Are you sure you want to update this user? Changes will be applied immediately.
                        </FinalStepSubTitle>
                      </div>

                      <FinalStepButtonDiv>
                        <div style={{ width: "138px" }}>
                          <NewAppButton
                            variant="attention"
                            onClick={() => {
                              setConfirmUpdateStep(false);
                            }}
                          >
                            NO, CANCEL
                          </NewAppButton>
                        </div>
                        <div style={{ width: "138px" }}>
                          <NewAppButton variant="primary" onClick={submitForm} disabled={!isValid}>
                            YES, UPDATE
                          </NewAppButton>
                        </div>
                      </FinalStepButtonDiv>
                    </FinalStepDiv>
                  ) : confirmDiscardStep ? (
                    <FinalStepDiv>
                      <div
                        style={{
                          width: "396px",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          gap: "48px",
                        }}
                      >
                        <FinalStepTitle> Discard Changes?</FinalStepTitle>
                        <FinalStepSubTitle>
                          Are you sure you want to discard changes? User information will revert to previous state.
                        </FinalStepSubTitle>
                      </div>
                      <FinalStepButtonDiv>
                        <div style={{ width: "138px" }}>
                          <NewAppButton
                            variant="secondary"
                            onClick={() => {
                              setConfirmDiscardStep(false);
                            }}
                          >
                            NO, GO BACK
                          </NewAppButton>
                        </div>
                        <div style={{ width: "138px" }}>
                          <NewAppButton
                            variant="primary"
                            onClick={() => {
                              setConfirmDiscardStep(false);
                              close && close();
                            }}
                          >
                            YES, DISCARD
                          </NewAppButton>
                        </div>
                      </FinalStepButtonDiv>
                    </FinalStepDiv>
                  ) : (
                    <>
                      <TitleDiv>
                        <TitleText>{currentUser ? `Edit ` : `Add New `}User</TitleText>
                      </TitleDiv>
                      <ScrollingDiv>
                        <>
                          {userProps.role !== "SM" && (
                            <RoleDiv>
                              <PopupInputLabel>
                                Role
                                <span style={{ color: theme.DANGER600 }}>*</span>
                              </PopupInputLabel>
                              <div
                                style={{
                                  width: "376px",
                                  height: "40px",
                                }}
                              >
                                <UserAppSelect
                                  name="role"
                                  options={
                                    currentUser ? optionsRole.filter((item) => item.value !== "SM") : optionsRole
                                  }
                                  textAlign="left"
                                  allowSelectPlaceholder={false}
                                  placeholder={"Choose Role"}
                                />
                              </div>
                            </RoleDiv>
                          )}
                        </>
                        {!!values.role && (
                          <>
                            <ExpandableDiv expandableKey="general-information">
                              <>
                                <SectionInfoText>General Information</SectionInfoText>
                                {values.role === "SM" && (
                                  <TwoColumnsDiv style={{ marginTop: "32px" }}>
                                    <OneColumnDiv>
                                      <PopupInputLabel>
                                        First Name<span style={{ color: theme.DANGER600 }}>*</span>
                                      </PopupInputLabel>
                                      <UserInput name="first_name" tabIndex={1} />
                                      {expanded && expanded.includes("general-information") && (
                                        <>
                                          <PopupInputLabel>
                                            Team Name<span style={{ color: theme.DANGER600 }}>*</span>
                                          </PopupInputLabel>
                                          <div
                                            style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
                                          >
                                            <UserInput
                                              disabled={currentUser}
                                              name="team_name"
                                              style={{ margin: "0px" }}
                                              tabIndex={currentUser ? -1 : 3}
                                            />
                                            <PhoenixIcon
                                              svg={lock}
                                              variant="brand"
                                              size="small"
                                              style={{ transform: "translateY(-12px)" }}
                                            />
                                          </div>
                                          <PopupInputLabel>Site Name</PopupInputLabel>
                                          <NewAppSelect
                                            noErrorNeeded
                                            value={values.site_id}
                                            options={orgData?.fetchOrganization?.Sites?.map((site: any) => ({
                                              label: site?.name,
                                              value: site?.id,
                                            }))}
                                            onChange={(e: any) => setFieldValue("site_id", e?.value)}
                                            tabIndex={"5"}
                                          />
                                        </>
                                      )}
                                    </OneColumnDiv>
                                    <OneColumnDiv>
                                      <PopupInputLabel>
                                        Last Name<span style={{ color: theme.DANGER600 }}>*</span>
                                      </PopupInputLabel>
                                      <UserInput name="last_name" tabIndex={2} />
                                      {expanded && expanded.includes("general-information") && (
                                        <>
                                          <PopupInputLabel>
                                            Email<span style={{ color: theme.DANGER600 }}>*</span>
                                          </PopupInputLabel>
                                          <UserInput disabled={currentUser} name="email" type="email" tabIndex={currentUser ? -1 : 4} />
                                          <PopupInputLabel>Time Zone</PopupInputLabel>
                                          <UserAppSelect
                                            allowSelectPlaceholder
                                            placeholder="Default"
                                            name="timezone"
                                            options={timezone_list_items}
                                            textAlign="left"
                                            tabIndex={currentUser ? -1 : 6}
                                          />
                                          <PopupInputLabel>Access to All Leads</PopupInputLabel>
                                          <NewAppSelect
                                            allowSelectPlaceholder
                                            noErrorNeeded
                                            value={values?.visible_all_leads_sm}
                                            options={[
                                              { label: "No", value: false },
                                              { label: "Yes", value: true },
                                            ]}
                                            onChange={(e: any) => setFieldValue("visible_all_leads_sm", e?.value)}
                                            tabIndex={"7"}
                                          />
                                        </>
                                      )}
                                    </OneColumnDiv>
                                  </TwoColumnsDiv>
                                )}
                                {(values.role === "AE" || values.role === "SDR") && (
                                  <>
                                    <TwoColumnsDiv>
                                      <OneColumnDiv>
                                        <PopupInputLabel>
                                          First Name<span style={{ color: theme.DANGER600 }}>*</span>
                                        </PopupInputLabel>
                                        <UserInput name="first_name" tabIndex={1} />
                                        {expanded && expanded.includes("general-information") && (
                                          <>
                                            <PopupInputLabel>Phone Number</PopupInputLabel>
                                            <div
                                              style={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                              }}
                                            >
                                              <UserInput
                                                name="phone_number"
                                                disabled
                                                placeholder="Assigned automatically"
                                                tabIndex={-1}
                                              />
                                              <PhoenixIcon
                                                svg={lock}
                                                variant="brand"
                                                size="small"
                                                style={{ transform: "translateY(-12px)" }}
                                              />
                                            </div>
                                            {loadingTeam ? (
                                              <Loading />
                                            ) : (
                                              <>
                                                <PopupInputLabel>Sales Manager Assignment</PopupInputLabel>

                                                <UserAppSelect
                                                  name={newUser ? "manager_id" : "team_id"}
                                                  allowSelectPlaceholder
                                                  options={
                                                    data.fetchTeamNames?.map((t: any) => ({
                                                      label: t.name,
                                                      value: t.id,
                                                    })) ?? []
                                                  }
                                                  placeholder="Select"
                                                  textAlign="left"
                                                  width={376}
                                                  height={40}
                                                  tabIndex={5}
                                                />
                                              </>
                                            )}
                                            {(values.role === "SDR" || currentUser) && (
                                              <>
                                                <PopupInputLabel>Time Zone</PopupInputLabel>
                                                <UserAppSelect
                                                  placeholder="Default"
                                                  allowSelectPlaceholder
                                                  name="timezone"
                                                  options={timezone_list_items}
                                                  textAlign="left"
                                                  tabIndex={7}
                                                />
                                              </>
                                            )}
                                            {values.role === "AE" && newUser && (
                                              <>
                                                <PopupInputLabel>Time Zone</PopupInputLabel>
                                                <UserAppSelect
                                                  placeholder="Default"
                                                  allowSelectPlaceholder
                                                  name="timezone"
                                                  options={timezone_list_items}
                                                  textAlign="left"
                                                  tabIndex={7}
                                                />
                                              </>
                                            )}
                                          </>
                                        )}
                                      </OneColumnDiv>

                                      <OneColumnDiv>
                                        <PopupInputLabel>
                                          Last Name
                                          <span style={{ color: theme.DANGER600 }}>*</span>
                                        </PopupInputLabel>
                                        <UserInput name="last_name" tabIndex={3} />
                                        {expanded && expanded.includes("general-information") && (
                                          <>
                                            <PopupInputLabel>
                                              Email
                                              <span style={{ color: theme.DANGER600 }}>*</span>
                                            </PopupInputLabel>
                                            <UserInput disabled={currentUser} name="email" type="email" tabIndex={currentUser ? -1 : 3} />
                                            {values.role === "AE" && (
                                              <>
                                                <PopupInputLabel>Close Rate-90 (%)</PopupInputLabel>
                                                <HoldsDiv>
                                                  <FaMinus
                                                    size={16}
                                                    color={theme.PRIMARY500}
                                                    onClick={() => {
                                                      const valNum = Number(values.close_rate || 0);
                                                      if (valNum > 0) {
                                                        setFieldValue("close_rate", valNum - 1);
                                                      }
                                                    }}
                                                  />
                                                  <div
                                                    style={{
                                                      justifyContent: "center",
                                                      display: "flex",
                                                      alignItems: "center",
                                                    }}
                                                  >
                                                    <NumberInput
                                                      type="number"
                                                      max={120}
                                                      min={15}
                                                      value={values.close_rate}
                                                      onChange={(e: any) => {
                                                        if (e.target.value <= 100 && e.target.value >= 0) {
                                                          setFieldValue("close_rate", parseInt(e.target.value));
                                                        }
                                                      }}
                                                      tabIndex={6}
                                                    />
                                                  </div>
                                                  <FaPlus
                                                    size={16}
                                                    color={theme.PRIMARY500}
                                                    onClick={() => {
                                                      const valNum = Number(values.close_rate || 0);
                                                      if (valNum < 100) {
                                                        setFieldValue("close_rate", valNum + 1);
                                                      }
                                                    }}
                                                  />
                                                </HoldsDiv>
                                              </>
                                            )}
                                            {values.role === "SDR" && (
                                              <>
                                                <PopupInputLabel
                                                  style={{
                                                    fontSize: "12px",
                                                  }}
                                                >
                                                  Holds Per Prospect-90 vs. Held Demo per Dial (%)
                                                </PopupInputLabel>
                                                {/* {values.holds_per_prospect_vs_held_demo_per_dial} */}
                                                <RelDiv>
                                                  <UserInput
                                                    name="holds_per_prospect_vs_held_demo_per_dial"
                                                    type="number"
                                                    placeholder="Enter value"
                                                    onChange={(e) => {
                                                      const valNum = Number(e?.target?.value || 0);
                                                      if (valNum >= 0 && valNum <= 100) {
                                                        setFieldValue(
                                                          "holds_per_prospect_vs_held_demo_per_dial",
                                                          valNum,
                                                        );
                                                      }
                                                    }}
                                                    tabIndex={6}
                                                  />
                                                  <AbsDivTop
                                                    disabled={
                                                      !!values.holds_per_prospect_vs_held_demo_per_dial &&
                                                      values.holds_per_prospect_vs_held_demo_per_dial >= 100
                                                    }
                                                    onClick={() =>
                                                      !!values.holds_per_prospect_vs_held_demo_per_dial &&
                                                      values.holds_per_prospect_vs_held_demo_per_dial < 100 &&
                                                      setFieldValue(
                                                        "holds_per_prospect_vs_held_demo_per_dial",
                                                        Number(values.holds_per_prospect_vs_held_demo_per_dial) + 1,
                                                      )
                                                    }
                                                  />
                                                  <AbsDivTop
                                                    bottom
                                                    disabled={
                                                      !!values.holds_per_prospect_vs_held_demo_per_dial &&
                                                      values.holds_per_prospect_vs_held_demo_per_dial <= 0
                                                    }
                                                    onClick={() =>
                                                      !!values.holds_per_prospect_vs_held_demo_per_dial &&
                                                      values.holds_per_prospect_vs_held_demo_per_dial > 0 &&
                                                      setFieldValue(
                                                        "holds_per_prospect_vs_held_demo_per_dial",
                                                        Number(values.holds_per_prospect_vs_held_demo_per_dial) - 1,
                                                      )
                                                    }
                                                  />
                                                </RelDiv>
                                              </>
                                            )}
                                          </>
                                        )}
                                      </OneColumnDiv>
                                    </TwoColumnsDiv>
                                  </>
                                )}
                              </>
                            </ExpandableDiv>
                            <ExpandableDiv expandableKey="label">
                              <CenterTimesDiv>
                                <SectionLabelText>Label</SectionLabelText>
                                {expanded?.includes("label") && (
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                    }}
                                  >
                                    <LabelSelect
                                      width={380}
                                      value={values.labels_id}
                                      onChange={(arr) =>
                                        setFieldValue(
                                          "labels_id",
                                          arr.map((o) => o.value),
                                        )
                                      }
                                    />
                                  </div>
                                )}
                              </CenterTimesDiv>
                            </ExpandableDiv>
                            {values.role !== "SM" && (
                              <>
                                <BlueTitle fontWeight={400} fontSize={12}>
                                  Lead Assignment Filters
                                </BlueTitle>
                                {(values.role === "AE" || values.role === "SDR") && (
                                  <ExpandableDiv expandableKey={"availability"} showToggle={false}>
                                    <>
                                      <CenterTimesDiv>
                                        <SectionLabelText>Availability</SectionLabelText>
                                        <div
                                          style={{
                                            display: "flex",
                                            gap: "10px",
                                          }}
                                        >
                                          <NewAppCircleCheckBox
                                            titleTextLocation="right"
                                            title="Default"
                                            checked={values.default_availability}
                                            onClick={() => {
                                              setFieldValue("default_availability", true);
                                              if (expanded?.includes("availability")) {
                                                setExpanded(expanded.filter((e) => e !== "availability"));
                                              }
                                            }}
                                          />

                                          <NewAppCircleCheckBox
                                            titleTextLocation="right"
                                            title="Custom"
                                            checked={!values.default_availability}
                                            onClick={() => {
                                              setFieldValue("default_availability", false);
                                              if (!expanded?.includes("availability")) {
                                                setExpanded([...expanded, "availability"]);
                                              }
                                            }}
                                          />
                                        </div>
                                        {values.default_availability === false && (
                                          <>
                                            <SegmentTable>
                                              <TitleRow>
                                                <AppText></AppText>
                                                <AppText fontWeight={500} fontSize={12}>
                                                  Start time
                                                </AppText>
                                                <AppText fontWeight={500} fontSize={12}>
                                                  End time
                                                </AppText>
                                              </TitleRow>
                                              {DUMMY_AVAILABILITY?.map((item, index) => (
                                                <SegmentRow key={item.day}>
                                                  <DayText fontWeight={500} fontSize={12}>
                                                    {item.day}
                                                  </DayText>
                                                  <TimePickerContainer>
                                                    <NewTimeField
                                                      name={`user_custom_availability[${index}]`}
                                                      timeType="start"
                                                    />
                                                  </TimePickerContainer>
                                                  <TimePickerContainer>
                                                    <NewTimeField
                                                      name={`user_custom_availability[${index}]`}
                                                      timeType="end"
                                                    />
                                                  </TimePickerContainer>
                                                </SegmentRow>
                                              ))}
                                            </SegmentTable>
                                          </>
                                        )}
                                        <SectionLabelText>Leads with phone numbers?</SectionLabelText>
                                        <div
                                          style={{
                                            display: "flex",
                                            flexDirection: "row",
                                          }}
                                        >
                                          <NewAppCircleCheckBox
                                            width="80px"
                                            titleTextLocation="right"
                                            title="Both"
                                            checked={values.auto_assign_no_phone_leads === null}
                                            onClick={() => setFieldValue("auto_assign_no_phone_leads", null)}
                                          />
                                          <NewAppCircleCheckBox
                                            width="180px"
                                            titleTextLocation="right"
                                            title="With phone numbers"
                                            checked={values.auto_assign_no_phone_leads === false}
                                            onClick={() => setFieldValue("auto_assign_no_phone_leads", false)}
                                          />
                                          <NewAppCircleCheckBox
                                            width="180px"
                                            titleTextLocation="right"
                                            title="Without phone numbers"
                                            checked={values.auto_assign_no_phone_leads === true}
                                            onClick={() => setFieldValue("auto_assign_no_phone_leads", true)}
                                          />
                                        </div>
                                        {/* <SectionLabelText>Assigned Countries</SectionLabelText>
                                        <PhoenixMultiSelect
                                          maxHeight={200}
                                          name="assigned_countries"
                                          options={countryOptionsArray}
                                          value={values.assigned_countries.map((item: any) => ({
                                            label: item,
                                            value: item,
                                          }))}
                                          onChange={(e: any) => {
                                            !!e.length
                                              ? setFieldValue(
                                                  "assigned_countries",
                                                  e?.map((item: any) => item.value),
                                                )
                                              : setFieldValue("assigned_countries", []);
                                          }}
                                          isClearable={false}
                                          showCheckbox
                                          menuPortal
                                          width={376}
                                          closeMenuOnSelect={false}
                                          hideSelectedOptions={false}
                                          marginBottom={false}
                                        /> */}
                                        <br />
                                      </CenterTimesDiv>
                                    </>
                                  </ExpandableDiv>
                                )}

                                <ExpandableDiv expandableKey={"channel"} showToggle={false}>
                                  <CenterTimesDiv>
                                    <SectionLabelText>Channel</SectionLabelText>
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                      }}
                                    >
                                      <NewAppCircleCheckBox
                                        width="80px"
                                        titleTextLocation="right"
                                        title="Both"
                                        checked={values.channel === "Both"}
                                        onClick={() => setFieldValue("channel", "Both")}
                                      />

                                      <NewAppCircleCheckBox
                                        width="100px"
                                        titleTextLocation="right"
                                        title="Inbound"
                                        checked={values.channel === "Inbound"}
                                        onClick={() => setFieldValue("channel", "Inbound")}
                                      />
                                      <NewAppCircleCheckBox
                                        width="100px"
                                        titleTextLocation="right"
                                        title="Outbound"
                                        type="radio"
                                        name="channel"
                                        checked={values.channel === "Outbound"}
                                        onClick={() => setFieldValue("channel", "Outbound")}
                                      />
                                    </div>
                                  </CenterTimesDiv>
                                </ExpandableDiv>

                                <ExpandableDiv expandableKey={"countries"} showToggle={!countriesButton}>
                                  <CenterTimesDiv>
                                    <SectionLabelText>Countries </SectionLabelText>

                                    {countriesButton ? (
                                      <OpenSectionButtonWrapper>
                                        <NewAppButton
                                          variant="primary"
                                          onClick={() => {
                                            setExpanded([...expanded, "countries"]);
                                            setCountriesButton(false);
                                          }}
                                        >
                                          Add Country Assignment Filter
                                        </NewAppButton>
                                      </OpenSectionButtonWrapper>
                                    ) : expanded && !expanded.includes("countries") ? (
                                      <>
                                        <ButtonsDiv>
                                          {/*maps all countries to buttons*/}
                                          {values.assigned_countries &&
                                            values.assigned_countries.map((item, index) => (
                                              <div
                                                style={{
                                                  position: "relative",
                                                }}
                                              >
                                                <AssignStatusButton>{item}</AssignStatusButton>
                                                <CloseButton onClick={() => removeCountry(item)}>
                                                  <AiOutlineClose size={12} color={theme.WHITE_COLOR} />
                                                </CloseButton>
                                              </div>
                                            ))}
                                        </ButtonsDiv>
                                      </>
                                    ) : (
                                      <PaddingDivFilters>
                                        <div style={{ width: "376px" }}>
                                          <PhoenixMultiSelect
                                            maxHeight={200}
                                            name="assigned_countries"
                                            options={countryOptionsArray}
                                            value={values.assigned_countries.map((item: any) => ({
                                              label: item,
                                              value: item,
                                            }))}
                                            onChange={(e: any) => {
                                              !!e?.length
                                                ? setFieldValue(
                                                    "assigned_countries",
                                                    e?.map((item: any) => item.value),
                                                  )
                                                : setFieldValue("assigned_countries", []);
                                            }}
                                            isClearable={false}
                                            showCheckbox
                                            menuPortal
                                            width={376}
                                            closeMenuOnSelect={false}
                                            hideSelectedOptions={false}
                                            marginBottom={false}
                                          />
                                        </div>
                                        <ResetFilters formikValue="assigned_countries" />
                                      </PaddingDivFilters>
                                    )}
                                  </CenterTimesDiv>
                                </ExpandableDiv>

                                <ExpandableDiv expandableKey={"region"} showToggle={!regionsButton}>
                                  <CenterTimesDiv>
                                    <SectionLabelText>State/Province </SectionLabelText>

                                    {regionsButton ? (
                                      <OpenSectionButtonWrapper>
                                        <NewAppButton
                                          variant="primary"
                                          onClick={() => {
                                            setExpanded([...expanded, "region"]);
                                            setRegionsButton(false);
                                          }}
                                        >
                                          Add State/Province Assignment Filter
                                        </NewAppButton>
                                      </OpenSectionButtonWrapper>
                                    ) : expanded && !expanded.includes("region") ? (
                                      <>
                                        <ButtonsDiv>
                                          {/*maps all industries and sub industries to buttons*/}
                                          {values.regions_white_list_array &&
                                            values.regions_white_list_array?.map((item, index) => (
                                              <div
                                                style={{
                                                  position: "relative",
                                                }}
                                              >
                                                {values.regions_white_list_assign_type === "OnlyAssign" ? (
                                                  <AssignStatusButton>{item.label}</AssignStatusButton>
                                                ) : (
                                                  <DoNotAssignStatusButton>{item.label}</DoNotAssignStatusButton>
                                                )}
                                                <CloseButton onClick={() => removeRegionAssignment(item.value)}>
                                                  <AiOutlineClose size={12} color={theme.WHITE_COLOR} />
                                                </CloseButton>
                                              </div>
                                            ))}
                                        </ButtonsDiv>
                                      </>
                                    ) : (
                                      <PaddingDivFilters>
                                        <CheckBoxContainerDiv>
                                          <NewAppCircleCheckBox
                                            titleTextLocation="right"
                                            title="Only Assign"
                                            // name="lead_source_filter"
                                            checked={values.regions_white_list_assign_type === "OnlyAssign"}
                                            onClick={() => {
                                              setFieldValue("regions_white_list_assign_type", "OnlyAssign");
                                            }}
                                          />

                                          <NewAppCircleCheckBox
                                            title="Do Not Assign"
                                            titleTextLocation="right"
                                            type="checkbox"
                                            // name="custom_field_filter"
                                            checked={values.regions_white_list_assign_type === "DoNotAssign"}
                                            onClick={() =>
                                              setFieldValue("regions_white_list_assign_type", "DoNotAssign")
                                            }
                                          />
                                        </CheckBoxContainerDiv>
                                        <div style={{ width: "376px" }}>
                                          <NewFormMultiSelectField
                                            isClearable={false}
                                            marginBottom={false}
                                            maxHeight={200}
                                            maxMenuHeight={200}
                                            options={allStatesAndProvincesSelectOptions}
                                            name="regions_white_list_array"
                                            value={values.regions_white_list_array}
                                            onChange={(e: any) => {
                                              setFieldValue("regions_white_list_array", !!e ? e : []);
                                              setFieldTouched("regions_white_list_array", true);
                                              console.log("regions_white_list_array", e);
                                            }}
                                            placeholder="Select regions"
                                            itemBackgroundColor={
                                              values.regions_white_list_assign_type === `OnlyAssign`
                                                ? theme.PRIMARY500
                                                : theme.DANGER600
                                            }
                                          />
                                        </div>
                                        <ResetFilters formikValue="regions_white_list_array" />
                                      </PaddingDivFilters>
                                    )}
                                  </CenterTimesDiv>
                                </ExpandableDiv>

                                <ExpandableDiv expandableKey="industry">
                                  <CenterTimesDiv>
                                    <SectionLabelText>Industry</SectionLabelText>
                                    {industriesButton ? (
                                      <OpenSectionButtonWrapper>
                                        <NewAppButton
                                          variant="primary"
                                          onClick={() => {
                                            setExpanded([...expanded, "industry"]);
                                            setIndustriesButton(false);
                                          }}
                                        >
                                          Add Industry Filter
                                        </NewAppButton>
                                      </OpenSectionButtonWrapper>
                                    ) : expanded && !expanded.includes("industry") ? (
                                      <>
                                        <ButtonsDiv>
                                          {/*maps all industries and sub industries to buttons*/}
                                          {values.industry_white_list_array.length > 0 &&
                                            values.industry_white_list_array?.map((item, index) => (
                                              <div
                                                style={{
                                                  position: "relative",
                                                }}
                                              >
                                                {values.industry_white_list_assign_type === "OnlyAssign" ? (
                                                  <AssignStatusButton>{item.label}</AssignStatusButton>
                                                ) : (
                                                  <DoNotAssignStatusButton>{item.label}</DoNotAssignStatusButton>
                                                )}
                                                <CloseButton onClick={() => removeIndustry(item.value)}>
                                                  <AiOutlineClose size={12} color={theme.WHITE_COLOR} />
                                                </CloseButton>
                                              </div>
                                            ))}
                                          {values.sub_industry_white_list_array.length > 0 &&
                                            values.sub_industry_white_list_array?.map((item, index) => (
                                              <div
                                                style={{
                                                  position: "relative",
                                                }}
                                              >
                                                {values.sub_industry_white_list_assign_type === "OnlyAssign" ? (
                                                  <AssignStatusButton>{item.label}</AssignStatusButton>
                                                ) : (
                                                  <DoNotAssignStatusButton>{item.label}</DoNotAssignStatusButton>
                                                )}
                                                <CloseButton onClick={() => removeSubIndustry(item.value)}>
                                                  <AiOutlineClose size={12} color={theme.WHITE_COLOR} />
                                                </CloseButton>
                                              </div>
                                            ))}
                                        </ButtonsDiv>
                                      </>
                                    ) : (
                                      <>
                                        <PaddingDivFilters>
                                          <AppText
                                            fontWeight={500}
                                            fontSize={12}
                                            style={{
                                              marginBottom: "24px",
                                            }}
                                          >
                                            Primary Industries
                                          </AppText>
                                          <CheckBoxContainerDiv>
                                            <NewAppCircleCheckBox
                                              titleTextLocation="right"
                                              title="Only Assign"
                                              // name="custom_field_filter"
                                              checked={values.industry_white_list_assign_type === "OnlyAssign"}
                                              onClick={() =>
                                                setFieldValue("industry_white_list_assign_type", "OnlyAssign")
                                              }
                                            />

                                            <NewAppCircleCheckBox
                                              titleTextLocation="right"
                                              title="Do Not Assign"
                                              // name="custom_field_filter"
                                              checked={values.industry_white_list_assign_type === "DoNotAssign"}
                                              onClick={() => {
                                                setFieldValue("industry_white_list_assign_type", "DoNotAssign");
                                                setFieldValue("sub_industry_white_list_assign_type", "DoNotAssign");
                                              }}
                                            />
                                          </CheckBoxContainerDiv>

                                          <NewFormMultiSelectField
                                            isClearable={false}
                                            marginBottom={false}
                                            maxHeight={200}
                                            maxMenuHeight={200}
                                            name="industry_white_list_array"
                                            options={
                                              subindustryUniqueLoading
                                                ? optionsSelect
                                                : subindustryUniqueData?.fetchIndustryOptions
                                                ? subindustryUniqueData?.fetchIndustryOptions

                                                    .slice()
                                                    ?.filter((item: any) => !!item.label)
                                                    ?.map((ls: any) => ({
                                                      label: ls?.label || "",
                                                      value: ls?.label || "",
                                                    }))
                                                : []
                                            }
                                            value={values.industry_white_list_array}
                                            onChange={(e: any) => {
                                              setFieldValue("industry_white_list_array", !!e ? e : []);
                                              setFieldTouched("industry_white_list_array", true);
                                              console.log("industry_white_list_array", e);

                                              const currentIndustries = e?.map((e: any) => e.label);

                                              // get all valid industries' sub industries

                                              let allValidSubIndustries: any[] = [];

                                              currentIndustries.forEach((industry: any) => {
                                                const subIndustries =
                                                  subindustryUniqueData.fetchIndustryOptions
                                                    ?.slice()
                                                    ?.filter((item: any) => !!item.label)
                                                    ?.filter((item: any) => item.label === industry)
                                                    ?.map((item: any) => item.sub_industries)
                                                    ?.flat() ?? [];

                                                allValidSubIndustries = [...allValidSubIndustries, ...subIndustries];
                                              });

                                              // remove any sub industries that are not in the list of valid sub industries

                                              const newSubIndustryWhiteList = values.sub_industry_white_list_array
                                                ?.slice()
                                                ?.filter((item: any) => allValidSubIndustries.includes(item?.label));

                                              setFieldValue("sub_industry_white_list_array", newSubIndustryWhiteList);
                                            }}
                                            error={
                                              !!errors.industry_white_list_array &&
                                              (currentUser || touched.industry_white_list_array) &&
                                              errors.industry_white_list_array
                                            }
                                            itemBackgroundColor={
                                              values.industry_white_list_assign_type === `OnlyAssign`
                                                ? theme.PRIMARY500
                                                : theme.DANGER600
                                            }
                                          />
                                          <ResetFilters formikValue="industry_white_list_array" />
                                        </PaddingDivFilters>
                                        <PaddingDivFilters>
                                          <AppText
                                            fontWeight={500}
                                            fontSize={12}
                                            style={{
                                              marginBottom: "24px",
                                            }}
                                          >
                                            Sub-Industry
                                          </AppText>
                                          <CheckBoxContainerDiv>
                                            <NewAppCircleCheckBox
                                              titleTextLocation="right"
                                              title="Only Assign"
                                              disabled={values.industry_white_list_assign_type === "DoNotAssign"}
                                              // name="custom_field_filter"
                                              checked={values.sub_industry_white_list_assign_type === "OnlyAssign"}
                                              onClick={() =>
                                                setFieldValue("sub_industry_white_list_assign_type", "OnlyAssign")
                                              }
                                            />
                                            <NewAppCircleCheckBox
                                              titleTextLocation="right"
                                              title="Do Not Assign"
                                              type="checkbox"
                                              // name="custom_field_filter"
                                              checked={values.sub_industry_white_list_assign_type === "DoNotAssign"}
                                              onClick={() =>
                                                setFieldValue("sub_industry_white_list_assign_type", "DoNotAssign")
                                              }
                                            />
                                          </CheckBoxContainerDiv>

                                          <NewFormMultiSelectField
                                            isClearable={false}
                                            marginBottom={false}
                                            maxHeight={200}
                                            maxMenuHeight={200}
                                            name="sub_industry_white_list_array"
                                            options={
                                              subindustryUniqueLoading
                                                ? optionsSelect
                                                : subIndustriesOptions(values.industry_white_list_array)
                                            }
                                            value={values.sub_industry_white_list_array}
                                            onChange={(e: any) => {
                                              setFieldValue("sub_industry_white_list_array", !!e ? e : []);
                                              setFieldTouched("sub_industry_white_list_array", true);
                                              console.log("sub_industry_white_list_array", e);
                                            }}
                                            error={
                                              !!errors.sub_industry_white_list_array &&
                                              (currentUser || touched.sub_industry_white_list_array) &&
                                              errors.sub_industry_white_list_array
                                            }
                                            itemBackgroundColor={
                                              values.sub_industry_white_list_assign_type === `OnlyAssign`
                                                ? theme.PRIMARY500
                                                : theme.DANGER600
                                            }
                                          />
                                          <ResetFilters formikValue="sub_industry_white_list_array" />
                                        </PaddingDivFilters>
                                      </>
                                    )}
                                  </CenterTimesDiv>
                                </ExpandableDiv>
                                <ExpandableDiv expandableKey="lead_source">
                                  <CenterTimesDiv>
                                    <SectionLabelText>Lead Source</SectionLabelText>

                                    {leadSourcesButton ? (
                                      <OpenSectionButtonWrapper>
                                        <NewAppButton
                                          variant="primary"
                                          onClick={() => {
                                            setLeadSourcesButton(false);
                                            setExpanded([...expanded, "lead_source"]);
                                          }}
                                        >
                                          Add Lead Source Filter
                                        </NewAppButton>
                                      </OpenSectionButtonWrapper>
                                    ) : expanded && !expanded.includes("lead_source") ? (
                                      <>
                                        <ButtonsDiv>
                                          {/*maps all industries and sub industries to buttons*/}
                                          {values.lead_source_white_list_array &&
                                            values.lead_source_white_list_array?.map((item, index) => (
                                              <div
                                                style={{
                                                  position: "relative",
                                                }}
                                              >
                                                {values.lead_source_white_list_assign_type === "OnlyAssign" ? (
                                                  <AssignStatusButton>{item.label}</AssignStatusButton>
                                                ) : (
                                                  <DoNotAssignStatusButton>{item.label}</DoNotAssignStatusButton>
                                                )}
                                                <CloseButton onClick={() => removeLeadSource(item.value)}>
                                                  <AiOutlineClose size={12} color={theme.WHITE_COLOR} />
                                                </CloseButton>
                                              </div>
                                            ))}
                                        </ButtonsDiv>
                                      </>
                                    ) : (
                                      <>
                                        <PaddingDivFilters>
                                          <CheckBoxContainerDiv>
                                            <NewAppCircleCheckBox
                                              titleTextLocation="right"
                                              title="Only Assign"
                                              // name="lead_source_filter"
                                              checked={values.lead_source_white_list_assign_type === "OnlyAssign"}
                                              onClick={() => {
                                                setFieldValue("lead_source_white_list_assign_type", "OnlyAssign");
                                              }}
                                            />
                                            <NewAppCircleCheckBox
                                              titleTextLocation="right"
                                              title="Do Not Assign"
                                              // name="custom_field_filter"
                                              checked={values.lead_source_white_list_assign_type === "DoNotAssign"}
                                              onClick={() => {
                                                setFieldValue("lead_source_white_list_assign_type", "DoNotAssign");
                                              }}
                                            />
                                          </CheckBoxContainerDiv>
                                          <NewFormMultiSelectField
                                            isClearable={false}
                                            marginBottom={false}
                                            maxHeight={200}
                                            maxMenuHeight={200}
                                            name="lead_source_white_list_array"
                                            options={
                                              leadSourceUniqueLoading
                                                ? optionsSelect
                                                : (leadSourceUniqueData?.getUniqueLeadSources &&
                                                    leadSourceUniqueData.getUniqueLeadSources
                                                      ?.slice()
                                                      ?.filter((item: any) => !!item.label)
                                                      ?.map((ls: any) => ({
                                                        label: ls.label,
                                                        value: ls.label,
                                                      }))) ||
                                                  []
                                            }
                                            value={values.lead_source_white_list_array}
                                            onChange={(e: any) => {
                                              setFieldValue("lead_source_white_list_array", !!e ? e : []);
                                              setFieldTouched("lead_source_white_list_array", true);
                                              console.log("lead_source_white_list_array", e);
                                            }}
                                            error={
                                              !!errors.lead_source_white_list_array &&
                                              (currentUser || touched.lead_source_white_list_array) &&
                                              errors.lead_source_white_list_array
                                            }
                                            itemBackgroundColor={
                                              values.lead_source_white_list_assign_type === `OnlyAssign`
                                                ? theme.PRIMARY500
                                                : theme.DANGER600
                                            }
                                          />
                                          <ResetFilters formikValue="lead_source_white_list_array" />
                                        </PaddingDivFilters>
                                      </>
                                    )}
                                  </CenterTimesDiv>
                                </ExpandableDiv>
                                <ExpandableDiv expandableKey={"lead_history_filter"} showToggle={!historyFilterButton}>
                                  <CenterTimesDiv>
                                    <SectionLabelText>Lead History</SectionLabelText>

                                    {historyFilterButton ? (
                                      <OpenSectionButtonWrapper>
                                        <NewAppButton
                                          variant="primary"
                                          onClick={() => {
                                            setExpanded([...expanded, "lead_history_filter"]);
                                            setHistoryFilterButton(false);
                                          }}
                                        >
                                          Add Lead History Filter
                                        </NewAppButton>
                                      </OpenSectionButtonWrapper>
                                    ) : expanded && !expanded.includes("lead_history_filter") ? (
                                      <>
                                        <ButtonsDiv>
                                          <AppText>
                                            Lifetime Dials{" "}
                                            {
                                              leadHistoryOperators.find(
                                                (item) => item.value === values.lead_history_filter?.operator,
                                              )?.label
                                            }{" "}
                                            {values.lead_history_filter?.amount}
                                            {["Between", "NotBetween"].includes(values.lead_history_filter?.operator) &&
                                              " and " + values.lead_history_filter?.amount_2}
                                          </AppText>
                                        </ButtonsDiv>
                                      </>
                                    ) : (
                                      <PaddingDivFilters>
                                        <div style={{ width: "100%" }}>
                                          <FlexDiv gap={8}>
                                            <AppText style={{ lineHeight: "40px", marginRight: "4px" }}>
                                              Lifetime Dials
                                            </AppText>
                                            <PhoenixMultiSelect
                                              isMulti={false}
                                              width={200}
                                              name="lead_history_filter.operator"
                                              options={leadHistoryOperators?.sort((a, b) =>
                                                a.label.toLowerCase().localeCompare(b.label.toLowerCase()),
                                              )}
                                              placeholder="Select Operator"
                                              value={{
                                                value: values.lead_history_filter?.operator,
                                                label: leadHistoryOperators.find(
                                                  (item) => item.value === values.lead_history_filter?.operator,
                                                )?.label,
                                              }}
                                              onChange={(item: any) =>
                                                setFieldValue("lead_history_filter", {
                                                  ...values.lead_history_filter,
                                                  operator: item?.value,
                                                })
                                              }
                                            />
                                            <PhoenixInput
                                              name="lead_history_filter.amount"
                                              inputValueType="number"
                                              value={values.lead_history_filter?.amount}
                                              placeholder="0"
                                              type="number"
                                              showNumberArrows
                                              width={100}
                                              onChange={(e: any) =>
                                                setFieldValue("lead_history_filter", {
                                                  ...values.lead_history_filter,
                                                  amount: parseInt(e.target.value) || 0,
                                                })
                                              }
                                              handleNumberArrowUpClick={() =>
                                                setFieldValue("lead_history_filter", {
                                                  ...values.lead_history_filter,
                                                  amount:
                                                    (parseInt(values.lead_history_filter?.amount?.toString() ?? "0") ||
                                                      0) + 1,
                                                })
                                              }
                                              handleNumberArrowDownClick={() =>
                                                setFieldValue("lead_history_filter", {
                                                  ...values.lead_history_filter,
                                                  amount: Math.max(
                                                    0,
                                                    (parseInt(values.lead_history_filter?.amount?.toString() ?? "0") ||
                                                      0) - 1,
                                                  ),
                                                })
                                              }
                                              displayNoContextText
                                            />

                                            <AppText style={{ lineHeight: "40px" }}>
                                              {values.lead_history_filter?.operator === "Between" ||
                                              values.lead_history_filter?.operator === "NotBetween"
                                                ? "and"
                                                : "times"}
                                            </AppText>

                                            {(values.lead_history_filter?.operator === "Between" ||
                                              values.lead_history_filter?.operator === "NotBetween") && (
                                              <>
                                                <PhoenixInput
                                                  name="lead_history_number"
                                                  inputValueType="number"
                                                  value={values.lead_history_filter?.amount_2}
                                                  type="number"
                                                  placeholder="0"
                                                  width={100}
                                                  showNumberArrows
                                                  onChange={(e: any) =>
                                                    setFieldValue("lead_history_filter", {
                                                      ...values.lead_history_filter,
                                                      amount_2: parseInt(e.target.value) || 0,
                                                    })
                                                  }
                                                  handleNumberArrowUpClick={() =>
                                                    setFieldValue("lead_history_filter", {
                                                      ...values.lead_history_filter,
                                                      amount_2:
                                                        (parseInt(
                                                          values.lead_history_filter?.amount_2?.toString() ?? "0",
                                                        ) || 0) + 1,
                                                    })
                                                  }
                                                  handleNumberArrowDownClick={() =>
                                                    setFieldValue("lead_history_filter", {
                                                      ...values.lead_history_filter,
                                                      amount_2: Math.max(
                                                        0,
                                                        (parseInt(
                                                          values.lead_history_filter?.amount_2?.toString() ?? "0",
                                                        ) || 0) - 1,
                                                      ),
                                                    })
                                                  }
                                                  displayNoContextText
                                                />
                                                <AppText style={{ lineHeight: "40px" }}>times</AppText>
                                              </>
                                            )}
                                          </FlexDiv>
                                        </div>
                                        <ResetFilters formikValue="lead_history_filter" />
                                      </PaddingDivFilters>
                                    )}
                                  </CenterTimesDiv>
                                </ExpandableDiv>
                                <ExpandableDiv
                                  expandableKey="custom-fields"
                                  blueBackground
                                  showToggle={!customFieldsButton}
                                  style={{
                                    padding: "0px 24px",
                                  }}
                                >
                                  <CenterTimesDiv>
                                    <SectionLabelText>
                                      Custom Fields
                                      {expanded &&
                                        expanded.includes("custom-fields") === false &&
                                        values?.custom_field_white_list_array &&
                                        values?.custom_field_white_list_array.length > 0 && (
                                          <span
                                            style={{
                                              marginLeft: "2px",
                                            }}
                                          >
                                            ({values?.custom_field_white_list_array.length})
                                          </span>
                                        )}
                                    </SectionLabelText>
                                    {customFieldsButton ? (
                                      <OpenSectionButtonWrapper>
                                        <NewAppButton
                                          variant="primary"
                                          onClick={() => {
                                            setCustomFieldsButton(false);
                                            addCustomField();
                                            setExpanded([...expanded, "custom-fields"]);
                                          }}
                                        >
                                          Include Custom Fields
                                        </NewAppButton>
                                      </OpenSectionButtonWrapper>
                                    ) : (
                                      expanded &&
                                      expanded.includes("custom-fields") && (
                                        <>
                                          {values.custom_field_white_list_array &&
                                            values.custom_field_white_list_array?.map(
                                              (item: custom_field_white_list_array_item, index) => (
                                                <div
                                                  style={{
                                                    width: "100%",
                                                  }}
                                                >
                                                  <PaddingDivFilters>
                                                    <CustomFieldGrid>
                                                      <Left>
                                                        <AppText fontWeight={500} fontSize={12}>
                                                          Choose Custom Field
                                                        </AppText>
                                                        <NewAppSelect
                                                          // name={`custom_field_white_list_array[${index}]`}
                                                          value={item?.customFieldId}
                                                          options={
                                                            allCustomFieldOptions &&
                                                            allCustomFieldOptions.fetchCustomFields.length > 0
                                                              ? allCustomFieldOptions?.fetchCustomFields

                                                                  // filter unsported types
                                                                  .filter((item: allAvailableCustomFieldsObject) => {
                                                                    return [
                                                                      "MultiDropdown",
                                                                      "Dropdown",
                                                                      "Boolean",
                                                                    ].includes(item.type);
                                                                  })
                                                                  ?.map((item: allAvailableCustomFieldsObject) => ({
                                                                    label: item.key,
                                                                    value: item.id,
                                                                  }))
                                                              : []
                                                          }
                                                          onChange={(e: any) => {
                                                            console.log("e", e);

                                                            setFieldValue(
                                                              `custom_field_white_list_array[${index}].customFieldId`,
                                                              e.value,
                                                            );

                                                            !values?.custom_field_white_list_array[index].assign &&
                                                              setFieldValue(
                                                                `custom_field_white_list_array[${index}].assign`,
                                                                `OnlyAssign`,
                                                              );

                                                            setFieldValue(
                                                              `custom_field_white_list_array[${index}].selected`,
                                                              [],
                                                            );
                                                          }}
                                                        />
                                                        {touched.custom_field_white_list_array &&
                                                          touched.custom_field_white_list_array[index] &&
                                                          touched.custom_field_white_list_array[index].customFieldId &&
                                                          errors &&
                                                          errors.custom_field_white_list_array &&
                                                          errors.custom_field_white_list_array[index] &&
                                                          (errors.custom_field_white_list_array[index] as any)
                                                            .customFieldId && (
                                                            <AppText variant="error" fontSize={12} fontWeight={500}>
                                                              {
                                                                (errors.custom_field_white_list_array[index] as any)
                                                                  .customFieldId
                                                              }
                                                            </AppText>
                                                          )}

                                                        <div style={{ display: "flex" }}>
                                                          <NewAppCircleCheckBox
                                                            noMarginBottom
                                                            titleTextLocation="right"
                                                            title="Only Assign"
                                                            name="custom_field_filter"
                                                            checked={
                                                              values.custom_field_white_list_array[index].assign ===
                                                              `OnlyAssign`
                                                            }
                                                            onClick={() =>
                                                              setFieldValue(
                                                                `custom_field_white_list_array[${index}].assign`,
                                                                `OnlyAssign`,
                                                              )
                                                            }
                                                          />
                                                          <NewAppCircleCheckBox
                                                            noMarginBottom
                                                            titleTextLocation="right"
                                                            title="Do Not Assign"
                                                            type="checkbox"
                                                            name="custom_field_filter"
                                                            checked={
                                                              values.custom_field_white_list_array[index].assign ===
                                                              `DoNotAssign`
                                                            }
                                                            onClick={() =>
                                                              setFieldValue(
                                                                `custom_field_white_list_array[${index}].assign`,
                                                                `DoNotAssign`,
                                                              )
                                                            }
                                                          />
                                                        </div>
                                                        {touched &&
                                                          touched.custom_field_white_list_array &&
                                                          touched.custom_field_white_list_array[index] &&
                                                          touched.custom_field_white_list_array[index].assign &&
                                                          errors &&
                                                          errors.custom_field_white_list_array &&
                                                          errors.custom_field_white_list_array[index] &&
                                                          (errors.custom_field_white_list_array[index] as any)
                                                            .assign && (
                                                            <AppText variant="error" fontSize={12} fontWeight={500}>
                                                              {
                                                                (errors.custom_field_white_list_array[index] as any)
                                                                  .assign
                                                              }
                                                            </AppText>
                                                          )}
                                                        <NewAppMultiSelect
                                                          isClearable={false}
                                                          marginBottom={false}
                                                          maxHeight={200}
                                                          maxMenuHeight={200}
                                                          name={`custom_field_white_list_array[${index}].selected`}
                                                          options={
                                                            // find the type of the custom field based on the customFieldId
                                                            allCustomFieldOptions &&
                                                            allCustomFieldOptions.fetchCustomFields.length > 0 &&
                                                            allCustomFieldOptions.fetchCustomFields.filter(
                                                              (item: allAvailableCustomFieldsObject) =>
                                                                item.id ===
                                                                values.custom_field_white_list_array[index]
                                                                  .customFieldId,
                                                            )[0]?.type === "Boolean"
                                                              ? [
                                                                  {
                                                                    label: "true",
                                                                    value: "true",
                                                                  },
                                                                  {
                                                                    label: "false",
                                                                    value: "false",
                                                                  },
                                                                ]
                                                              : allCustomFieldOptions &&
                                                                allCustomFieldOptions?.fetchCustomFields?.length > 0
                                                              ? allCustomFieldOptions?.fetchCustomFields
                                                                  .filter(
                                                                    (item: allAvailableCustomFieldsObject) =>
                                                                      item.id ===
                                                                      values.custom_field_white_list_array[index]
                                                                        .customFieldId,
                                                                  )[0]
                                                                  ?.options?.map((value: string) => ({
                                                                    label: value,
                                                                    value: value,
                                                                  }))
                                                              : []
                                                          }
                                                          value={item.selected}
                                                          onChange={(e: any) => {
                                                            setFieldValue(
                                                              `custom_field_white_list_array[${index}].selected`,
                                                              !!e ? e : [],
                                                            );
                                                            setFieldTouched(
                                                              `custom_field_white_list_array[${index}].selected`,
                                                              true,
                                                            );
                                                            console.log("custom_field_white_list_array", e);
                                                          }}
                                                          itemBackgroundColor={
                                                            values.custom_field_white_list_array[index].assign ===
                                                            `OnlyAssign`
                                                              ? theme.PRIMARY500
                                                              : theme.DANGER600
                                                          }
                                                        />
                                                        {touched.custom_field_white_list_array &&
                                                          touched.custom_field_white_list_array[index] &&
                                                          touched.custom_field_white_list_array[index].selected &&
                                                          errors &&
                                                          errors.custom_field_white_list_array &&
                                                          errors.custom_field_white_list_array[index] &&
                                                          (errors.custom_field_white_list_array[index] as any)
                                                            .selected && (
                                                            <AppText variant="error" fontSize={12} fontWeight={500}>
                                                              {
                                                                (errors.custom_field_white_list_array[index] as any)
                                                                  .selected
                                                              }
                                                            </AppText>
                                                          )}
                                                        <ResetFilters
                                                          formikValue={`custom_field_white_list_array[${index}].selected`}
                                                        />
                                                      </Left>
                                                      <Right>
                                                        <div
                                                          onClick={() => {
                                                            removeCustomField(index);
                                                          }}
                                                        >
                                                          {/* <img src={trash_blue} alt="trash" height={20} width={20} /> */}
                                                          <PhoenixIcon
                                                            svg={trash}
                                                            variant="brand"
                                                            alt="trash"
                                                            size={20}
                                                          />
                                                        </div>
                                                      </Right>
                                                    </CustomFieldGrid>
                                                  </PaddingDivFilters>
                                                </div>
                                              ),
                                            )}
                                          {`${JSON.stringify(errors?.custom_field_white_list_array)}`.includes(
                                            "a custom field can only be used once",
                                          ) && (
                                            <AppText variant="error" fontSize={12} fontWeight={500}>
                                              a custom field can only be used once
                                            </AppText>
                                          )}
                                          <StyledHr />
                                          <div
                                            style={{
                                              width: "162px",
                                              marginTop: "16px",
                                            }}
                                          >
                                            <NewAppButton
                                              variant="primary"
                                              onClick={() => {
                                                addCustomField();
                                              }}
                                              style={{ marginBottom: "16px" }}
                                              disabled={
                                                // if there are already a new custom field in the list, disable the button
                                                values.custom_field_white_list_array.filter(
                                                  (item: custom_field_white_list_array_item) =>
                                                    item.customFieldId === "",
                                                ).length > 0
                                              }
                                            >
                                              Add Custom Field
                                            </NewAppButton>
                                          </div>
                                        </>
                                      )
                                    )}
                                  </CenterTimesDiv>
                                </ExpandableDiv>
                              </>
                            )}
                          </>
                        )}
                      </ScrollingDiv>
                      <SubmitDiv>
                        <div
                          style={{
                            display: "flex",
                            gap: "24px",
                          }}
                        >
                          {currentUser ? (
                            <div>
                              <SmallButton
                                // small
                                style={{ color: theme.ATTENTION700, marginRight: "12px" }}
                                onClick={() => setDeleteModal(true)}
                              >
                                Delete
                              </SmallButton>
                            </div>
                          ) : (
                            <div style={{ width: "100px" }}>
                              <NewAppButton onClick={() => close && close()} variant="attention">
                                Close
                              </NewAppButton>
                            </div>
                          )}
                        </div>

                        <div style={{ width: "138px" }}>
                          <NewAppButton
                            variant="primary"
                            disabled={isSubmitting || !isValid}
                            type="submit"
                            onClick={
                              currentUser
                                ? () => {
                                    console.log("isSubmitting", isSubmitting);
                                    console.log("isValid", isValid);
                                    setConfirmUpdateStep(true);
                                  }
                                : () => {
                                    console.log("isSubmitting", isSubmitting);
                                    console.log("isValid", isValid);
                                    handleConfirmUpdate();
                                  }
                            }
                          >
                            {currentUser ? `Save Changes` : `Create User`}
                          </NewAppButton>
                        </div>
                      </SubmitDiv>
                    </>
                  )}
                </Modal>
              </PopupContainerDiv>
            )}
          </>
        );
      }}
    </Formik>
  );
};

const RelDiv = styled.div`
  position: relative;
  width: 100%;
`;

const HoldsDiv = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
  gap: 8px;
`;

interface CaretProps {
  disabled: boolean;
  bottom?: boolean;
}

const AbsDivTop = styled.div<CaretProps>`
  display: flex;
  background: url(${iconNewCaretDown}) no-repeat;
  background-position: 5px 13px;
  position: absolute;
  right: 10px;
  top: ${(props) => (props.bottom ? "20px" : "0px")};
  transform: ${(props) => (props.bottom ? "rotate(180deg)" : "rotate(0deg)")};
  width: 20px;
  height: 20px;
  cursor: ${(props) => (props.disabled ? "default" : "pointer")};
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
`;
const ResetFiltersDiv = styled.div`
  display: flex;
  margin-bottom: 12px;
  align-items: center;
  gap: 8px;
  margin-top: 12px;
`;
const BlueTitle = styled(AppText)`
  color: ${theme.PRIMARY500};
  line-height: 18px;
  margin-top: 24px;
  margin-bottom: 24px;
`;

const FinalStepTitle = styled(AppText)`
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 20px;
  margin-top: 40px;
`;

const FinalStepButtonDiv = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  border-top: 1px solid ${theme.NEUTRAL200};
  padding: 16px 40px;
`;

const FinalStepSubTitle = styled(AppText)`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
`;

const SectionLabelText = styled(AppText)`
  font-size: 14px;
  margin-bottom: 24px;
  margin-top: 24px;
  font-weight: 500;
`;

const PaddingDivFilters = styled.div`
  width: 100%;
`;

const DayText = styled(AppText)`
  margin-left: 10px;
`;
const CenterTimesDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  /* margin-top: 64px; */
`;

const SmallButton = styled(NewAppButton)`
  height: 40px;
  width: 90px;
`;

const CheckBoxContainerDiv = styled.div`
  display: flex;
  margin-top: 12px;
  /* margin-bottom: 12px; */
`;

const AssignStatusButton = styled.button`
  padding-right: 30px;
  border-radius: 4px;
  font-weight: 600;
  font-size: 8px;
  line-height: 12px;
  height: 24px;
  background-color: ${theme.PRIMARY500};
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 30px 0px 16px;
  color: ${theme.WHITE_COLOR};
`;

const DoNotAssignStatusButton = styled.button`
  padding-right: 30px;
  border-radius: 4px;
  font-weight: 600;
  font-size: 8px;
  line-height: 12px;
  background-color: ${theme.DANGER600};
  height: 24px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 30px 0px 16px;
  color: ${theme.WHITE_COLOR};
`;

const SectionInfoText = styled(AppText)`
  font-size: 14px;
  width: 100%;
  text-align: left;
  margin: 32px auto;
`;

const NumberInput = styled.input`
  text-align: center;
  width: 64px;
  height: 40px;

  border-radius: 4px;
  border: 1px solid ${theme.NEUTRAL200};
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  :focus {
    /* border-bottom-color: ${theme.PRIMARY500}; */
    outline: none;
    border: 1px solid ${theme.NEUTRAL400};
  }
  ::placeholder {
    opacity: 0.4;
  }
  :hover {
    border: 1px solid ${(props) => (props.disabled ? theme.NEUTRAL200 : theme.NEUTRAL400)};
  }
`;

const OneColumnDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 330px;
`;

const TwoColumnsDiv = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 40px;
`;

const RoleDiv = styled.div`
  height: 100px;
  border-bottom: 1px solid ${theme.NEUTRAL200};
`;

const SubmitDiv = styled.div`
  width: 100%;
  height: 72px;

  overflow: hidden;
  border-radius: 8px;

  padding: 0px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${theme.NEUTRAL100};
  border-top: solid 1px ${theme.NEUTRAL200};
`;

const TitleText = styled(AppText)`
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 20px;
`;

const TitleDiv = styled.div`
  position: relative;
  height: 80px;
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  border-bottom: solid 1px ${theme.NEUTRAL200};
`;

const UserAppSelect = styled(NewSelectField)`
  margin-bottom: 3px;
`;

const TimePickerContainer = styled.div`
  height: 35px;
  width: 101px;
`;

const StyledHr = styled.hr`
  width: 100%;
  border-top: 2px solid ${theme.NEUTRAL200};
`;

const SegmentTable = styled.div`
  display: flex;
  flex-direction: column;
`;

const CloseButton = styled.div`
  position: absolute;
  right: 5px;
  top: 10%;
  cursor: pointer;
  z-index: 5;
`;

const SegmentRow = styled.div`
  display: grid;
  min-height: 40px;
  width: 388px;
  grid-template-rows: 75px;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 10px;
  justify-items: start;
  justify-self: center;
  align-self: flex-start;
  break-inside: avoid;
  break-after: always;
  break-before: always;
`;

const TitleRow = styled(SegmentRow)`
  min-height: 20px;
  grid-template-rows: 25px;
`;

const CustomFieldGrid = styled.div`
  display: grid;
  grid-template-columns: 376px 1fr;
  border-top: 2px solid ${theme.NEUTRAL200};
  padding-top: 22px;
  /* padding-bottom: 22px; */
  margin-bottom: 12px;
`;
const Left = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 230px;
`;
const Right = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
`;

const OpenSectionButtonWrapper = styled.div`
  width: 206px;
  margin-bottom: 12px;
`;

const FinalStepDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 313px;
  width: 476px;
`;

const PopupInputLabel = styled(AppText)`
  margin-bottom: 8px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
`;

const UserInput = styled(NewInputField)`
  width: 290px;
  height: 40px;
`;

const ScrollingDiv = styled.div`
  position: relative;
  /* padding: 40px 66px; */
  padding: 24px;
  padding-bottom: 88px;
  overflow: auto;
  width: 740px;
  min-height: 60vh;
  max-height: 60vh;
  overflow: auto;
  margin-bottom: 32px;
`;

interface ModalOnProps {
  blinds?: boolean;
}

const PopupContainerDiv = styled.div<ModalOnProps>`
  position: fixed;
  display: ${(props) => (props.blinds ? "block" : "none")};
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  max-height: 100%;
  background-attachment: fixed;
  overflow: hidden;
  z-index: 5;
`;

const ButtonsDiv = styled.div`
  display: flex;
  gap: 12px;
  width: 100%;
  max-width: 100%;
  flex-wrap: wrap;
`;

export { UpdateUserModalV2 };
