import carrot from "./carrot.png";
import lock_blue from "./lock_blue.png";
import cycle from "./cycle.png";
import trash_blue from "./trash_blue.png";
import phone_white from "./phone_white.svg";
import phone_primary from "./phone_primary.svg";
import chat from "./chat.svg";
import mail_blue from "./mail_blue.svg";
import associatedLeadHistoryIcon from "./associatedLeadHistoryIcon.svg";
import primaryLeadHistoryIcon from "./primaryLeadHistoryIcon.svg";
import arrowsUpAndDown from "./arrowsUpAndDown.svg";
import arrowsLeftAndRight from "./arrowsLeftAndRight.svg";
import primary_x from "./primary_x.svg";
import merge_white from "./merge_white.svg";
import addNewUser from "./addNewUser.svg";
import merge_primary from "./merge_primary.svg";
import associated_white from "./associated_white.svg";
import download_blue from "./download_blue.svg";
import play_circle_blue from "./play_circle_blue.svg";
import point from "./point.svg";
import link from "./link.svg";
import disassociated_contact_tag_no_text from "./disassociated_contact_tag_no_text.svg";

import user_white from "./user_white.svg";

import plain_phone_white from "./plain_phone_white.svg";
import outgoing_phone_white from "./outgoing_phone_white.svg";
import search_blue from "./search_blue.svg";
import check_blue from "./check_blue.svg";
import xIcon from "./xIcon.svg";
import video from "./video.svg";

// phoenix 👇
import dialer from "./dialer.svg";
import menu_expand from "./menu_expand.svg";
import menu_collapse from "./menu_collapse.svg";
import notification_dot from "./notification_dot.svg";
import bell from "./bell.svg";
import alert_circle from "./alert_circle.svg";
import arrow_down from "./arrow_down.svg";
import arrow_right from "./arrow_right.svg";
import arrow_up_down from "./arrow_up_down.svg";
import arrow_left_right from "./arrow_left_right.svg";
import activity from "./activity.svg";
import arrow_left from "./arrow_left.svg";
import resting from "./resting.svg";
import bar_chart from "./bar_chart.svg";
import bar_chart_down from "./bar_chart_down.svg";
import bar_chart_up from "./bar_chart_up.svg";
import book from "./book.svg";
import calendar from "./calendar.svg";
import caret_double from "./caret_double.svg";
import caret_down from "./caret_down.svg";
import check from "./check.svg";
import check_thick from "./check_thick.svg";
import chevron_down from "./chevron_down.svg";
import chevron_left from "./chevron_left.svg";
import chevron_right from "./chevron_right.svg";
import chevron_up from "./chevron_up.svg";
import clipboard from "./clipboard.svg";
import contact_info_add from "./contact_info_add.svg";
import clock from "./clock.svg";
import clone from "./clone.svg";
import copy from "./copy.svg";
import clone_blue from "./clone_blue.svg";
import dashboard from "./dashboard.svg";
import dollar_sign from "./dollar_sign.svg";
import download from "./download.svg";
import edit from "./edit.svg";
import emoji from "./emoji.svg";
import eye_off from "./eye_off.svg";
import eye from "./eye.svg";
import file_plus from "./file_plus.svg";
import file_text from "./file_text.svg";
import filter from "./filter.svg";
import flag from "./flag.svg";
import folder from "./folder.svg";
import grid from "./grid.svg";
import hashtag from "./hashtag.svg";
import info from "./info.svg";
import list from "./list.svg";
import lock from "./lock.svg";
import log_in from "./log_in.svg";
import log_out from "./log_out.svg";
import mail from "./mail.svg";
import mail_incoming from "./mail-incoming-blue.svg";
import mail_outgoing from "./mail-outgoing-blue.svg";
import map_pin from "./map_pin.svg";
import maximize from "./maximize.svg";
import message_square from "./message_square.svg";
import message_incoming from "./message_incoming.svg";
import message_outgoing from "./message_outgoing.svg";
import minimize from "./minimize.svg";
import minus from "./minus.svg";
import more_vertical from "./more_vertical.svg";
import music from "./music.svg";
import pause from "./pause.svg";
import phone_call from "./phone_call.svg";
import phone_forwarded from "./phone_forwarded.svg";
import phone_incoming from "./phone_incoming.svg";
import phone_outgoing from "./phone_outgoing.svg";
import phone_incoming_blue from "./phone-incoming-blue.svg";
import phone_outgoing_blue from "./phone-outgoing-blue.svg";
import pie_chart from "./pie_chart.svg";
import pin from "./pin.svg";
import play from "./play.svg";
import zoom_logo from "./zoom_logo.svg";
import play_circle from "./play_circle.svg";
import plus_circle from "./plus_circle.svg";
import plus from "./plus.svg";
import record from "./record.svg";
import refresh from "./refresh.svg";
import reorder from "./reorder.svg";
import repeat from "./repeat.svg";
import search from "./search.svg";
import sequences from "./sequences.svg";
import settings from "./settings.svg";
import share from "./share.svg";
import shield from "./shield.svg";
import shuffle from "./shuffle.svg";
import sliders from "./sliders.svg";
import star_filled from "./star_filled.svg";
import star from "./star.svg";
import steps from "./steps.svg";
import sync from "./sync.svg";
import system_view from "./system_view.svg";
import table_view from "./table_view.svg";
import trash from "./trash.svg";
import trending_up from "./trending_up.svg";
import user_add from "./user_add.svg";
import user_remove from "./user_remove.svg";
import users from "./users.svg";
import ai from "./ai.svg";
import rotate from "./rotate.svg";
import volume from "./volume.svg";
import volume_1 from "./volume_1.svg";
import volume_2 from "./volume_2.svg";
import stop_watch from "./stop_watch.svg";
import ellipse_small from "./ellipse_small.svg";
import ellipse_medium from "./ellipse_medium.svg";
import ellipse_large from "./ellipse_large.svg";
import google_meet_logo from "./google_meet_logo.svg";
import caret_up from "./caret_up.svg";
import google_meet_activities_logo from "src/images/NewDesign/google_meet_activities_logo.svg";
import flame from "./flame.svg";
import keypad from "./keypad.svg";
import mic from "./mic.svg";
import mic_off from "./mic_off.svg";
import phone_end from "./phone_end.svg";

import sellfire_logo from "./sellfire_logo.svg";
import wifi_alert from "./wifi-alert.svg";
import call_quality_good from "./call-quality-good.svg";
import call_quality_fair from "./call-quality-fair.svg";
import call_quality_poor from "./call-quality-poor.svg";

import cursor from "./cursor.svg";
import reply from "./reply.svg";

import play_circle_new from "./play_circle_new.svg";
import pause_circle_new from "./pause_circle_new.svg";
import record_outer_ring from "./record_outer_ring.svg";
import voicemail from "./voicemail.svg";
import stop_circle from "./stop_circle.svg";

import stop_outer_ring from "./stop_outer_ring.svg";

export {
  record_outer_ring,
  play_circle_new,
  pause_circle_new,
  stop_circle,
  voicemail,
  google_meet_activities_logo,
  primaryLeadHistoryIcon,
  associatedLeadHistoryIcon,
  carrot,
  lock_blue,
  cycle,
  trash_blue,
  phone_white,
  phone_primary,
  chat,
  mail_blue,
  arrowsUpAndDown,
  arrowsLeftAndRight,
  primary_x,
  merge_white,
  addNewUser,
  merge_primary,
  associated_white,
  disassociated_contact_tag_no_text,
  user_white,
  download_blue,
  play_circle_blue,
  plain_phone_white,
  outgoing_phone_white,
  search_blue,
  check_blue,
  xIcon,
  zoom_logo,
  google_meet_logo,
  // phoenix 👇
  video,
  point,
  link,
  dialer,
  menu_expand,
  menu_collapse,
  notification_dot,
  bell,
  alert_circle,
  arrow_down,
  arrow_right,
  arrow_up_down,
  arrow_left_right,
  activity,
  arrow_left,
  bar_chart,
  bar_chart_down,
  bar_chart_up,
  book,
  calendar,
  caret_double,
  caret_down,
  caret_up,
  check,
  check_thick,
  chevron_down,
  chevron_left,
  chevron_right,
  chevron_up,
  clipboard,
  contact_info_add,
  clock,
  clone,
  copy,
  clone_blue,
  dashboard,
  dollar_sign,
  download,
  edit,
  emoji,
  eye_off,
  eye,
  file_plus,
  file_text,
  filter,
  flag,
  folder,
  grid,
  hashtag,
  info,
  list,
  lock,
  log_in,
  log_out,
  mail,
  map_pin,
  maximize,
  message_square,
  message_incoming,
  message_outgoing,
  minimize,
  minus,
  more_vertical,
  music,
  pause,
  phone_call,
  phone_forwarded,
  phone_incoming,
  phone_outgoing,
  pie_chart,
  pin,
  play,
  play_circle,
  plus_circle,
  plus,
  record,
  refresh,
  reorder,
  repeat,
  search,
  sequences,
  settings,
  share,
  shield,
  shuffle,
  sliders,
  star_filled,
  star,
  steps,
  sync,
  system_view,
  table_view,
  trash,
  trending_up,
  user_add,
  resting,
  user_remove,
  users,
  ai,
  rotate,
  volume,
  volume_1,
  volume_2,
  stop_watch,
  stop_outer_ring,
  ellipse_small,
  ellipse_medium,
  ellipse_large,
  sellfire_logo,
  wifi_alert,
  phone_incoming_blue,
  phone_outgoing_blue,
  mail_incoming,
  mail_outgoing,
  flame,
  call_quality_good,
  call_quality_fair,
  call_quality_poor,
  keypad,
  mic,
  mic_off,
  phone_end,
  cursor,
  reply,
};
