import React, { useState, useRef } from "react";
import styled from "styled-components";
import { useClickOutside } from "src/utils/hooks";
import { theme } from "../../../utils/theme";
import { AppText, FlexDiv } from "../../UI";
import { PhoenixIcon } from "src/Components/UI/Phoenix";
import { users } from "src/images/NewDesign";

interface CallParticipantsMenuProps {
  businessName?: string;
  children?: (onClick: () => void) => React.ReactNode;
  participants?: string[];
  refetchParticipants: () => void;
}

const CallParticipantsMenu: React.FC<CallParticipantsMenuProps> = ({
  businessName,
  children,
  participants = [],
  refetchParticipants,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const triggerRef = useRef<HTMLDivElement>(null);

  useClickOutside(triggerRef, () => setIsOpen(false));

  const handleClick = () => {
    if (!isOpen) {
      refetchParticipants();
    }
    setIsOpen(!isOpen);
  };

  return (
    <MenuWrapper ref={triggerRef}>
      {children ? (
        children(handleClick)
      ) : (
        <TriggerButton onClick={handleClick}>
          <FlexDiv gap={4} align="center">
            <PhoenixIcon
              svg={users}
              size={16}
              color={theme.text.neutral.inverse}
              pointer
              hoverColor={theme.text.neutral.inverse}
            />
            {participants?.length ? (
              <AppText fontSize={14} color={theme.text.neutral.inverse}>
                {participants.length}
              </AppText>
            ) : null}
          </FlexDiv>
        </TriggerButton>
      )}

      {isOpen && (participants?.length > 0 || businessName) && (
        <MenuContainer>
          {participants.map((participant) => (
            <ParticipantRow key={participant}>
              <ParticipantInfo>
                <AppText fontSize={12} color={theme.text.neutral.primary}>
                  {participant}
                </AppText>
              </ParticipantInfo>
            </ParticipantRow>
          ))}
          {businessName ? (
            <FlexDiv direction="column" gap={4} padding="6px 8px">
              <AppText fontSize={8} fontWeight={600} color={theme.text.neutral.tertiary}>
                LEAD
              </AppText>
              <AppText fontSize={12}>{businessName}</AppText>
            </FlexDiv>
          ) : null}
        </MenuContainer>
      )}
    </MenuWrapper>
  );
};

const MenuWrapper = styled.div`
  position: relative;
`;

const TriggerButton = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  transition: background-color 0.2s ease;

  &:hover {
    background: rgba(255, 255, 255, 0.1);
  }
`;

const MenuContainer = styled.div`
  position: absolute;
  bottom: calc(100% + 8px);
  right: 0;
  background: ${theme.surface.neutral.primary};
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  padding: 8px;
  width: 240px;
  z-index: 1000;
`;

const ParticipantRow = styled(FlexDiv)`
  padding: 8px;
  gap: 12px;
  align-items: center;
`;

const ParticipantInfo = styled(FlexDiv)`
  flex-direction: column;
  gap: 2px;
`;

export default CallParticipantsMenu;
