import React, { useMemo, useState } from "react";
import gql from "graphql-tag";
import { AgGridReact } from "ag-grid-react";
import { useMutation } from "@apollo/client";
import { errorToast, successToast } from "src/utils/toast";
import { FlexDiv } from "src/Components/UI";
import { PhoenixAppButton, PhoenixCheckbox } from "src/Components/UI/Phoenix";
import { trash } from "src/images/NewDesign";
import { PhoenixIcon } from "src/Components/UI/Phoenix";
import { getDownIconHTML, getUpIconHTML } from "src/utils/misc";
import "ag-grid-community/styles/ag-grid.css";
import "src/Components/Segments/Onboarding/custom-object/ObjectManager.css";
import { UnassignUsersModal } from "src/Components/modal/UnassignUsersModal";

const BATCH_UNASSIGN_REPS = gql`
  mutation batchUnassignReps($userIds: [String!]!, $labelId: String!) {
    batchDeleteUserLabel(user_ids: $userIds, label_id: $labelId)
  }
`;

const AssignedRepsTable = ({ id, reps = [] }: { id: string; reps: any }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedReps, setSelectedReps] = useState<string[]>([]);

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const clearSelection = () => {
    setSelectedReps([]);
  };

  const [batchUnassignReps] = useMutation(BATCH_UNASSIGN_REPS, {
    onCompleted() {
      successToast("Reps unassigned");
      clearSelection();
    },
    onError({ message }) {
      errorToast(message);
    },
    refetchQueries: ["fetchLabel"],
  });

  const unassignRep = (userID: string) => {
    setSelectedReps([userID]);
    setIsModalOpen(true);
  };

  const unassignReps = () => {
    setIsModalOpen(true);
  };

  const handleUnassignReps = (ids: string[]) => {
    batchUnassignReps({ variables: { userIds: ids, labelId: id } });
  };

  const columnDefs = [
    {
      headerName: "Select",
      field: "id",
      cellStyle: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
      width: 70,
      sortable: true,
      headerComponentFramework: () => (
        <FlexDiv align="center" justify="center">
          <PhoenixCheckbox
            checked={Boolean(selectedReps.length) && selectedReps.length === reps.length}
            onChange={() => {
              if (selectedReps.length === reps.length) {
                clearSelection();
              } else {
                setSelectedReps(reps.map((rep: any) => rep.user.id));
              }
            }}
          />
        </FlexDiv>
      ),
      cellRenderer: (params: any) => {
        const itemID = params.data.id;

        return (
          <FlexDiv align="center" justify="center">
            <PhoenixCheckbox
              checked={selectedReps.includes(itemID)}
              onChange={() => {
                selectedReps.includes(itemID)
                  ? setSelectedReps(selectedReps.filter((val: string) => val !== itemID))
                  : setSelectedReps([...selectedReps, itemID]);
              }}
            />
          </FlexDiv>
        );
      },
    },
    {
      headerName: "Name",
      field: "name",
      flex: 1,
      headerClass: "ag-object-manager-header",
      sortable: true,
    },
    {
      headerName: "Unassign",
      field: "id",
      cellStyle: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
      cellRenderer: (params: any) => {
        const itemID = params.data.id;

        return (
          <PhoenixIcon
            svg={trash}
            variant="danger"
            size={16}
            onClick={() => {
              unassignRep(itemID);
            }}
          />
        );
      },
      headerClass: "ag-object-manager-header ag-object-manager-header-center",
    },
  ];

  const rowData = useMemo(() => {
    return reps.map((rep: any) => ({
      id: rep.user.id,
      name: rep.user.full_name,
    }));
  }, [reps]);

  return (
    <>
      <div className="ag-theme-object-manager" style={{ width: "100%", minHeight: 300, maxWidth: 680 }}>
        <AgGridReact
          animateRows={true}
          columnDefs={columnDefs}
          rowData={rowData}
          icons={{
            sortAscending: getUpIconHTML(),
            sortDescending: getDownIconHTML(),
          }}
        />
      </div>

      <FlexDiv gap={16}>
        <PhoenixAppButton
          variant="danger-outline"
          buttonType="secondary"
          disabled={!selectedReps.length}
          onClick={unassignReps}
        >
          Unassign
        </PhoenixAppButton>

        <PhoenixAppButton
          disabled={!selectedReps.length}
          variant="brand-outline"
          buttonType="secondary"
          onClick={clearSelection}
        >
          Clear Selection
        </PhoenixAppButton>
      </FlexDiv>

      <UnassignUsersModal
        closeModal={closeModal}
        ids={selectedReps}
        isOpen={isModalOpen}
        onUnassign={handleUnassignReps}
      />
    </>
  );
};

export default AssignedRepsTable;
