import styled from "styled-components";
import * as React from "react";
import { NewAppButton, AppErrorText, AppSidebarCard } from "../UI";
import { PhoenixAppButton } from "../UI/Phoenix";

interface EventItemProps {
  title: string;
  refetch: () => void;
  error: string;
  twoButtons?: boolean;
  secondaryButtonText?: string;
  secondaryButtonOnClick?: () => void;
}

const LeadCardError: React.FC<EventItemProps> = ({
  title,
  refetch,
  error,
  twoButtons = false,
  secondaryButtonText,
  secondaryButtonOnClick,
}) => {
  if (twoButtons) {
    return (
      <AppSidebarCard showExpand title={title} width={432}>
        <PaddingDiv>
          <AppErrorText>{error}</AppErrorText>
          <TwoButtonsDiv>
            <PhoenixAppButton
              style={{ width: "100%" }}
              buttonType="secondary"
              variant="brand-outline"
              onClick={refetch}
            >
              Reload
            </PhoenixAppButton>
            <PhoenixAppButton
              style={{ width: "100%" }}
              buttonType="secondary"
              variant="brand-outline"
              onClick={secondaryButtonOnClick}
            >
              {secondaryButtonText ?? "Reset"}
            </PhoenixAppButton>
          </TwoButtonsDiv>
        </PaddingDiv>
      </AppSidebarCard>
    );
  }
  return (
    <AppSidebarCard showExpand title={title}>
      <PaddingDiv>
        <AppErrorText>{error}</AppErrorText>
        <NewAppButton variant={"primary"} onClick={refetch}>
          Reload
        </NewAppButton>
      </PaddingDiv>
    </AppSidebarCard>
  );
};

const TwoButtonsDiv = styled.div`
  display: grid;
  width: 420px;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
  padding: 0px 16px;
`;

const PaddingDiv = styled.div`
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
`;

export { LeadCardError };
