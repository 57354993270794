import { gql, useMutation, useQuery } from "@apollo/client";
import * as Sentry from "@sentry/react";
import moment from "moment";
import React, { Dispatch, FunctionComponent, SetStateAction, createContext, useEffect, useMemo, useState } from "react";
import { appToast } from "../utils/toast";
import { FETCH_NOTIFICATIONS_POLL_INTERVAL } from "./../utils/variables";
export interface NotificationType {
  id: string;
  lead_id: string;
  text_computed?: string;
  details?: string;
  read?: boolean;
  payload?: any;
  send_action_back_to_server?: boolean;
  type: string;
  updated_at?: any;
  created_at?: any;
  relevant_id?: string;
  call_to_action_read?: boolean;
  text_action?: string;
  date_string?: string;
  type_label?: string;
  salesperson?: string;
  voicemail_url?: string;
  voicemail_url_computed?: string;
  can_dial?: boolean;
  phone_number?: string;
  temporaryNotification?: boolean;
}

interface NotificationsContextState {
  // State that holds the notifications data
  notifications: NotificationType[];
  setNotifications: Dispatch<SetStateAction<NotificationType[]>>;
  addNotification: any; //todo: define type
  fetchNotificationsLoading: any;
  fetchNotificationsError: any;
  fetchNotifications: any; //todo: define type
  updateOneNotification: any; //todo: define type
  deleteOneNotification: any; //todo: define type
  handleUpdateOneNotification: any; //todo: define type
  handleDeleteOneNotification: any; //todo: define type
  markNotificationsAsRead: any; //todo: define type
  loading: boolean | undefined;
  error: any;
  sortOrder: string;
  setSortOrder: Dispatch<SetStateAction<string>>;
  sortedNotifications: NotificationType[];
  descendingOnlySortedNotifications: NotificationType[];
  networkQuality: string;
  setNetworkQuality: Dispatch<SetStateAction<string>>;
  latestBreakingDeployTimestamp: string | null;
  setLatestBreakingDeployTimestamp: Dispatch<SetStateAction<string | null>>;
}

export const MARK_NOTIFICATIONS_AS_READ = gql`
  mutation markNotificationsAsRead {
    markNotificationsAsRead
  }
`;

interface fetchNotificationsExpectedResponse {
  fetchNotifications: NotificationType[];
}
export const FETCH_NOTIFICATIONS = gql`
  query fetchNotifications($take: Int, $skip: Int) {
    fetchNotifications(take: $take, skip: $skip) {
      id
      lead_id
      text_computed
      details
      read
      payload
      send_action_back_to_server
      type
      updated_at
      created_at
      relevant_id
      call_to_action_read
      text_action
      date_string
      type_label
      salesperson
      voicemail_url
      voicemail_url_computed
      can_dial
      phone_number
    }
  }
`;

const DELETE_NOTIFICATION = gql`
  mutation deleteOneNotification($id: String!) {
    deleteOneNotification(id: $id) {
      id
      text
      details
      read
      payload
      date_string
      created_at
      call_to_action_read
      text_action
      relevant_id
      send_action_back_to_server
      type
    }
  }
`;

const UPDATE_NOTIFICATION = gql`
  mutation UpdateOneNotification($notificationId: String!, $read: Boolean!) {
    updateOneNotification(notification_id: $notificationId, read: $read) {
      id
      text
      details
      read
      payload
      date_string
      created_at
      call_to_action_read
      text_action
      relevant_id
      send_action_back_to_server
      type
    }
  }
`;

export const NotificationsContext = createContext<NotificationsContextState>({} as NotificationsContextState);

export const NotificationsProvider: FunctionComponent = ({ children }) => {
  // setting the state
  const [notifications, setNotifications] = useState<NotificationType[]>([]); // TODO: define type
  const [sortOrder, setSortOrder] = useState("descending");

  const [latestBreakingDeployTimestamp, setLatestBreakingDeployTimestamp] = useState<string | null>(null);

  // Network Quality
  const [networkQuality, setNetworkQuality] = useState("good");

  const sortIncreasing = (a: any, b: any) => moment(a.created_at).unix() - moment(b.created_at).unix();
  const sortDecreasing = (a: any, b: any) => moment(b.created_at).unix() - moment(a.created_at).unix();

  const reSortedTable = (sortOrder: string) => {
    const sorted_data = notifications ? notifications.slice() : [];
    return sorted_data.sort(sortOrder === "descending" ? sortDecreasing : sortIncreasing);
  };

  const descendingOnlySortedNotifications = reSortedTable("descending");

  const sortedNotifications = notifications && notifications.length > 0 ? reSortedTable(sortOrder) : [];

  useEffect(() => {
    fetchNotificationsRefetch();
  }, []);

  // normal useQuery
  const {
    loading: fetchNotificationsLoading,
    error: fetchNotificationsError,
    data: fetchNotificationsData,
    refetch: fetchNotificationsRefetch,
  } = useQuery<fetchNotificationsExpectedResponse>(FETCH_NOTIFICATIONS, {
    pollInterval: FETCH_NOTIFICATIONS_POLL_INTERVAL,
    notifyOnNetworkStatusChange: false,
    fetchPolicy: "cache-and-network",
    variables: {
      take: 50,
    },

    onCompleted: (data) => {
      console.log("fetchNotificationsData: ", data);

      setNotifications(data.fetchNotifications);
    },

    onError: (error) => {
      Sentry.captureException(error);
      appToast("Error fetching notifications");
    },
  });

  const [deleteOneNotification] = useMutation(DELETE_NOTIFICATION, {
    onCompleted({ deleteOneNotification }) {
      console.log("Deleted notification: ", deleteOneNotification);
      // window.location.reload(false);
      if (!deleteOneNotification) {
        return;
      }
    },
    onError({ message }) {
      console.log("Error in deleteOneNotification: ", message);
      appToast(message);
      Sentry.captureEvent({
        message: `deleteOneNotification GraphQL Error: ${message}`,
      });
    },
  });

  const [
    updateOneNotification,
    { loading: updateOneNotificationLoading, error: updateOneNotificationError },
  ] = useMutation(UPDATE_NOTIFICATION, {
    onCompleted({ updateOneNotification }) {
      console.log("updateOneNotification: ", updateOneNotification);
      //window.location.reload(false);
      if (!updateOneNotification) {
        return;
      }
      console.log(updateOneNotification.id);
    },
    onError({ message }) {
      console.log("Error in updateOneNotification: ", message);
      appToast(message);
      Sentry.captureEvent({
        message: `updateOneNotification GraphQL Error: ${message}`,
      });
    },
  });

  const addNotification = (notification: NotificationType) => {
    setNotifications([notification, ...notifications]);
  };

  const handleUpdateOneNotification = async (item: any) => {
    if (item?.temporaryNotification) {
      setNotifications(
        notifications?.map((notification: any) => {
          if (notification.id === item.id) {
            return {
              ...notification,
              read: true,
            };
          }
          return notification;
        }),
      );
    } else {
      await updateOneNotification({
        variables: {
          notificationId: item?.id,
          read: true,
        },
      }).then(() => {
        // change the read status of the notification
        setNotifications(
          notifications?.map((notification: any) => {
            if (notification.id === item.id) {
              return {
                ...notification,
                read: true,
              };
            }
            return notification;
          }),
        );
      });
    }
  };

  const handleDeleteOneNotification = async (item: any) => {
    if (item?.temporaryNotification) {
      setNotifications(notifications.filter((notification: any) => notification.id !== item.id));
    } else {
      await deleteOneNotification({
        variables: {
          id: item.id,
        },
      }).then(() => {
        setNotifications(notifications.filter((notification: any) => notification.id !== item.id));
      });
    }
  };

  const [markNotificationsAsRead] = useMutation(MARK_NOTIFICATIONS_AS_READ, {
    onCompleted({ markNotificationsAsRead }) {
      if (!markNotificationsAsRead) {
        return;
      }
    },
    onError({ message }) {
      console.log("Error in markNotificationsAsRead: ", message);
    },
  });

  const loading = fetchNotificationsLoading || updateOneNotificationLoading;
  const NotificationError = fetchNotificationsError || updateOneNotificationError;
  const memoizedValue = useMemo(
    () => ({
      notifications,
      sortedNotifications,
      descendingOnlySortedNotifications,
      sortOrder,
      setSortOrder,
      addNotification,
      setNotifications,
      fetchNotificationsLoading,
      fetchNotificationsError,
      fetchNotifications: fetchNotificationsRefetch,
      updateOneNotification,
      deleteOneNotification,
      handleUpdateOneNotification,
      handleDeleteOneNotification,
      loading,
      error: NotificationError,
      markNotificationsAsRead,
      networkQuality,
      setNetworkQuality,
      latestBreakingDeployTimestamp,
      setLatestBreakingDeployTimestamp,
    }),
    [
      notifications,
      sortedNotifications,
      descendingOnlySortedNotifications,
      sortOrder,
      setSortOrder,
      addNotification,
      setNotifications,
      fetchNotificationsLoading,
      fetchNotificationsError,
      fetchNotificationsRefetch,
      updateOneNotification,
      deleteOneNotification,
      handleUpdateOneNotification,
      handleDeleteOneNotification,
      loading,
      NotificationError,
      markNotificationsAsRead,
      networkQuality,
      setNetworkQuality,
      latestBreakingDeployTimestamp,
      setLatestBreakingDeployTimestamp,
    ],
  );

  return <NotificationsContext.Provider value={memoizedValue}>{children}</NotificationsContext.Provider>;
};
