import React, { useContext } from "react";
import styled, { keyframes } from "styled-components";
import { ModalContext } from "../../context";
import { Formik } from "formik";
import * as Yup from "yup";
import { DarkDiv, AppText } from "../UI";
import { FlexDiv } from "../UI/FlexDiv";
import { theme } from "../../utils/theme";
import { PhoenixIcon, PhoenixAppButton, PhoenixTextArea, PhoenixColorPickerGrid } from "../UI/Phoenix";
import { PhoenixInputField } from "../Field/Phoenix";
import { xIcon } from "../../images/NewDesign";
import { gql, useMutation } from "@apollo/client";
import { errorToast, successToast } from "../../utils/toast";
import { ColorField } from "../Segments/Onboarding/widget/CustomizeWidget";

const productSchema = Yup.object().shape({
  name: Yup.string().required(),
  color: Yup.string().nullable(),
  description: Yup.string().nullable(),
  custom_color: Yup.string().nullable(),
});

const CREATE_OR_UPDATE_LABEL = gql`
  mutation CreateOrUpdateLabel($input: CreateOrUpdateLabelInput!) {
    createOrUpdateLabel(input: $input) {
      id
    }
  }
`;

interface AddOrEditLabelModalProps {
  selectedLabel?: any;
}

export const AddOrEditLabelModal: React.FC<AddOrEditLabelModalProps> = ({ selectedLabel }) => {
  const { setShowAddOrEditLabelModal } = useContext(ModalContext);

  const isEdit = Boolean(selectedLabel?.id);

  const [createOrEditLabel, { loading: loadingCreateOrEditLabel }] = useMutation(CREATE_OR_UPDATE_LABEL, {
    onCompleted() {
      successToast(!!selectedLabel?.id ? "Label updated" : "Label created");
      setShowAddOrEditLabelModal(false);
    },
    onError({ message }) {
      errorToast(message);
      console.log("Error in createOrEditLabel: ", message);
    },
    refetchQueries: ["fetchLabels", "fetchLabel"],
  });

  return (
    <>
      <DarkDiv />
      <ModalContainer direction="column">
        <ModalHeader>
          <PhoenixIcon
            svg={xIcon}
            size={24}
            variant="brand"
            pointer
            onClick={() => setShowAddOrEditLabelModal(false)}
          />
          <FlexDiv justify="center" style={{ width: "100%" }}>
            <AppText fontSize={18} fontWeight={500} lineHeight={24}>
              {isEdit ? "Edit" : "Create"} Label
            </AppText>
          </FlexDiv>
        </ModalHeader>

        <Formik
          initialValues={{
            name: selectedLabel?.name,
            description: selectedLabel?.description,
            color: selectedLabel?.color,
            custom_color: selectedLabel?.custom_color ?? selectedLabel?.color,
          }}
          onSubmit={async (values) => {
            await createOrEditLabel({
              variables: {
                input: {
                  id: selectedLabel?.id,
                  name: values.name,
                  description: values.description,
                  color: values?.custom_color ? values.custom_color : values.color,
                },
              },
            });
          }}
          validationSchema={productSchema}
          validateOnMount
        >
          {({ values, submitForm, setFieldValue, isValid, errors }) => {
            return (
              <>
                <ModalBody>
                  <FlexDiv direction="column" gap={24}>
                    <FlexDiv direction="column" gap={8}>
                      <AppText fontSize={12} fontWeight={500} lineHeight={18}>
                        Label Name
                        <span style={{ color: theme.PILL_DARK_RED }}>*</span>
                      </AppText>
                      <PhoenixInputField
                        name="name"
                        value={values.name}
                        onChange={(e) => setFieldValue("name", e.target.value)}
                        placeholder="Give your label a name"
                        displayNoContextText
                      />
                    </FlexDiv>

                    <FlexDiv direction="column" gap={8}>
                      <AppText fontSize={12} fontWeight={500} lineHeight={18}>
                        Label Color
                      </AppText>

                      <PhoenixColorPickerGrid
                        color={values.color}
                        setColor={(v) => {
                          setFieldValue("color", v);
                          setFieldValue("custom_color", v);
                        }}
                        columns={8}
                      />
                    </FlexDiv>

                    <FlexDiv direction="column" gap={8}>
                      <FlexDiv gap={8} align="flex-end">
                        <PhoenixInputField
                          name="custom_color"
                          titleText="Custom Label Color"
                          displayNoContextText
                          width={120}
                        />
                        <ColorField name="custom_color" type="color" width={40} height={40} />
                      </FlexDiv>
                    </FlexDiv>

                    <FlexDiv direction="column" gap={8}>
                      <AppText fontSize={12} fontWeight={500} lineHeight={18}>
                        Label Description
                      </AppText>
                      <PhoenixTextArea
                        name="description"
                        value={values.description}
                        onChange={(e) => setFieldValue("description", e.target.value)}
                        placeholder="Add a description about your label"
                      />
                    </FlexDiv>
                  </FlexDiv>
                </ModalBody>

                <ModalFooter>
                  <PhoenixAppButton
                    buttonType="secondary"
                    variant="danger-outline"
                    uppercase
                    buttonTextFontSize={10}
                    style={{ letterSpacing: "1px" }}
                    onClick={() => {
                      setShowAddOrEditLabelModal(false);
                    }}
                  >
                    Cancel
                  </PhoenixAppButton>
                  <PhoenixAppButton
                    buttonType="secondary"
                    uppercase
                    buttonTextFontSize={10}
                    style={{ letterSpacing: "1px" }}
                    onClick={submitForm}
                    disabled={!isValid && !loadingCreateOrEditLabel}
                  >
                    {isEdit ? "Update" : "Create"}
                  </PhoenixAppButton>
                </ModalFooter>
              </>
            );
          }}
        </Formik>
      </ModalContainer>
    </>
  );
};

const slideIn = keyframes`
  0% {
    width: 0px;
  }
  100% {
    width: 456px;
  }
`;

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  } 
`;

const ModalContainer = styled(FlexDiv)`
  position: fixed;
  z-index: 899;
  top: 0;
  right: 0;

  width: 456px;
  height: 100vh;

  background-color: ${theme.WHITE_COLOR};

  animation: ${slideIn} 0.4s ease forwards;
  & > * {
    animation: ${fadeIn} 0.75s ease forwards;
  }
`;

const ModalHeader = styled.div`
  width: 100%;
  height: 72px;

  padding: 8px 8px 16px 8px;
  border-bottom: 1px solid ${theme.NEUTRAL200};
`;

const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;

  padding: 24px 40px;

  overflow-y: auto;
`;

const ModalFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  min-height: 72px;
  margin-top: auto;
  padding: 16px 40px;

  background-color: ${theme.WHITE_COLOR};
`;
