import gql from "graphql-tag";

export const IS_LOGGED_IN = gql`
  query IsUserLoggedIn {
    isLoggedIn @client
  }
`;

export const SHOW_AUTO_DIALER = gql`
  query showAutoDialer {
    showAutoDialer @client
  }
`;

export const LOGGED_IN_USER = gql`
  query loggedInUser {
    loggedInUser @client
  }
`;

export const MRR_LABEL = gql`
  query getMrrLabel {
    getMrrLabel @client
  }
`;

export const SHOW_LEAD_SOURCE_TO_USER = gql`
  query showLeadSourceToUser {
    showLeadSourceToUser @client
  }
`;

export const SHOW_CALENDAR_OPTIONS = gql`
  query showCalendarOptions {
    showCalendarOptions @client
  }
`;

export const IS_ON_CALL = gql`
  query isOnCall {
    isOnCall @client
  }
`;

export const IS_USER_REP = gql`
  query isUserRep {
    isUserRep @client
  }
`;

export const TZ_OVERRIDE = gql`
  query tzOverride {
    tzOverride @client
  }
`;

export const SHOW_NOCONTACT_BUTTON_SHORTCUT = gql`
  query showNoContactButtonShortcut {
    showNoContactButtonShortcut @client
  }
`;

export const CAN_DIAL_CUSTOMER = gql`
  query canDialCustomer {
    canDialCustomer @client
  }
`;
