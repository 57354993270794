import { gql, useMutation, useQuery } from "@apollo/client";
import * as Sentry from "@sentry/react";
import * as React from "react";
import { useContext } from "react";
import styled from "styled-components";
import { currentCallState, loggedInUser } from "../../../apollo/cache";
import { CallContext, CartContext, LeadCardContext } from "../../../context";
import { theme } from "../../../utils/theme";
import { appToast } from "../../../utils/toast";
import { AppText, Loading, NewAppButton } from "../../UI";

const MAKE_SALE = gql`
  mutation makeSale(
    $lead_id: String!
    $person_spoke_to: CALLRESULT!
    $products: [ProductInputType!]!
    $intent_id: String
    $call: Boolean
    $notes: String
    $contract_duration: String
    $payment_terms: String
    $from_custom_call_queue: Boolean
    $prepayment: Int
    $call_sid: String
    $pandadoc_sale_id: String
    $mrr: Float
  ) {
    makeSale(
      lead_id: $lead_id
      person_spoke_to: $person_spoke_to
      products: $products
      intent_id: $intent_id
      call: $call
      notes: $notes
      contract_duration: $contract_duration
      payment_terms: $payment_terms
      from_custom_call_queue: $from_custom_call_queue
      prepayment: $prepayment
      call_sid: $call_sid
      pandadoc_sale_id: $pandadoc_sale_id
      mrr: $mrr
    ) {
      id
      lead_id
      related_disposition {
        id
        completed_text
        completed_image
        completed_tip
      }
    }
  }
`;

const END_CLOSING_SCRIPT = gql`
  mutation endClosingScript {
    endClosingScript {
      id
    }
  }
`;

interface ClosingScriptProps {
  setCallOptions: (state: string) => void;
  isPandaDocEnabled: boolean;
}

export const mapProducts = (products: any[]) => {
  return products?.map((item: any) => {
    return {
      product_id: item.product_id,
      quantity: parseInt(item.quantity),
      concession_id: item.concession_id && item.concession_id !== "0" ? item.concession_id : undefined,
      price_override: item.use_custom_price ? Number(item.price_override) : undefined,
    };
  });
};

const ClosingScript: React.FC<ClosingScriptProps> = ({ setCallOptions, isPandaDocEnabled }) => {
  const {
    setRecordingButtonDisabled,
    revertCallState,
    callOptionStackPush,
    intentId,
    callSid,
    suggestedActionData,
    nextActionOverride,
    callLeadId,
    isNonCallActivity,
    getCallLeadId,
    setAssociatedAction,
    personSpokeTo,
    callCameFromTransfer,
    persistCallNotes,
  } = useContext(CallContext);

  const {
    lead_id,
    person_spoke_to,
    products,
    sale_notes,
    contract_duration,
    payment_terms,
    prepayment,
    intent_id,
    clearCartContext,
    setCartSaleID,
    pandadoc_sale_id,
    mrr,
  } = useContext(CartContext);

  const { selectedTabDialQueue } = useContext(LeadCardContext);
  const confirmSale = async () => {
    await makeSale({
      variables: {
        lead_id,
        person_spoke_to: person_spoke_to,
        products: mapProducts(products),
        notes: sale_notes,
        contract_duration: contract_duration === "0" ? null : contract_duration,
        prepayment: !!prepayment ? Number(prepayment) : prepayment,
        payment_terms: payment_terms === "0" ? null : payment_terms,
        intent_id: intent_id,
        call: !isNonCallActivity,
        from_custom_call_queue: selectedTabDialQueue === "custom",
        call_sid: callSid,
        pandadoc_sale_id,
        mrr: mrr ? parseFloat(Number.isInteger(mrr) ? mrr + ".0" : mrr.toString()) : undefined,
      },
    });
  };

  const [endClosingScript, { loading, error }] = useMutation(END_CLOSING_SCRIPT);

  const [makeSale, { loading: loadingSale, error: errorSale }] = useMutation(MAKE_SALE, {
    async onCompleted({ makeSale }) {
      console.log("makeSale: ", makeSale);
      endClosingScript();
      if (!makeSale) {
        appToast("Error making sale!");
        return;
      }

      setCartSaleID(makeSale?.id);

      const callLeadId = getCallLeadId();
      const isSameCallLead = callLeadId === makeSale.lead_id;

      // This mutation implicitly logs a disposition
      // Make sure to update dispositionLogged if call is still going on
      // If the call has ended, revert back to dashboard
      if (!currentCallState().onCall && isSameCallLead) {
        revertCallState();
      } else {
        currentCallState({
          ...currentCallState(),
          dispositionLogged: isSameCallLead ? true : currentCallState().dispositionLogged,
        });
      }

      appToast(`${makeSale.related_disposition.completed_text}`, {}, "");
    },
    onError({ message }) {
      appToast(message);
      Sentry.captureEvent({
        message: `makeSale GraphQL Error: ${message}`,
      });
      console.log("Error in makeSale: ", message);
    },
    refetchQueries: ["fetchMySchedule", "fetchCustomQueueLeads", "fetchNextDial", "fetchFilteredLeadsInQueueV2"],
  });

  return (
    <FormContainer>
      <HeaderContainer>
        <AppText fontSize={21}>Recite Closing Script</AppText>
      </HeaderContainer>
      <ClosingScriptText>
        Please read your closing script to the customer for recording purposes. Once you have received confirmation from
        the customer, press Confirm Sale.
      </ClosingScriptText>
      {!isNonCallActivity &&
        currentCallState().onCall &&
        loggedInUser().allow_external_transfer &&
        loggedInUser().organization?.allow_external_transfer && (
          <ButtonsDiv>
            <NewAppButton
              size={"lg"}
              variant={"secondary"}
              onClick={() => {
                persistCallNotes();
                callOptionStackPush("external-transfer");
                setAssociatedAction(undefined);
              }}
              disabled={
                !callLeadId || personSpokeTo === "NoContact" || !currentCallState().onCall || !!callCameFromTransfer
              }
            >
              Transfer To External Number
            </NewAppButton>
          </ButtonsDiv>
        )}
      <SubmitButtonDiv>
        {!loadingSale && currentCallState().dispositionLogged && (
          <ClosingScriptText color={theme.SUCCESS500}>
            You have logged a sale for this call. End the call to return to the dashboard.
          </ClosingScriptText>
        )}
        <NewAppButton
          style={{ alignSelf: "center" }}
          variant={"primary"}
          size={"lg"}
          onClick={() => confirmSale()}
          disabled={loadingSale || currentCallState().dispositionLogged}
        >
          {loadingSale ? <Loading color={theme.WHITE_COLOR} /> : "Confirm Sale"}
        </NewAppButton>
      </SubmitButtonDiv>
    </FormContainer>
  );
};

const ButtonsDiv = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: 24px;
  /* height: 100%; */
`;

const ClosingScriptText = styled(AppText)`
  font-size: 16px;
  text-align: center;
  margin-bottom: 15px;
  margin-top: 15px;
`;

const SubmitButtonDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const FormContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const HeaderContainer = styled.div`
  width: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: auto;
  margin-bottom: 15px;
  margin-top: 40px;
`;

export { ClosingScript };
