import React, { useMemo } from "react";
import { AgGridReact } from "ag-grid-react";
import { useHistory } from "react-router-dom";
import { PhoenixAppButton } from "src/Components/UI/Phoenix";
import { FlexDiv } from "src/Components/UI";
import { getDownIconHTML, getUpIconHTML } from "src/utils/misc";
import "ag-grid-community/styles/ag-grid.css";
import "src/Components/Segments/Onboarding/custom-object/ObjectManager.css";

const AssignedRules = ({ rules = [] }: { id: string; rules: any }) => {
  const history = useHistory();

  const viewRule = (ruleID: string) => {
    history.push(`/system-config/routing-rules/${ruleID}`);
  };

  const columnDefs = [
    {
      headerName: "Routing Rule",
      field: "name",
      flex: 1,
      headerClass: "ag-object-manager-header",
      sortable: true,
    },
    {
      headerName: "",
      field: "id",
      cellStyle: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
      cellRenderer: (params: any) => {
        const itemID = params.data.id;

        return (
          <PhoenixAppButton buttonType="ghost-small" onClick={() => viewRule(itemID)}>
            View Rule
          </PhoenixAppButton>
        );
      },
      headerClass: "ag-object-manager-header ag-object-manager-header-center",
    },
  ];

  const rowData = useMemo(() => {
    return rules.map((rule: any) => ({
      id: rule.rule.id,
      name: rule.rule.name,
    }));
  }, [rules]);

  return (
    <FlexDiv direction="column" gap={24} style={{ flex: 1 }}>
      <div className="ag-theme-object-manager" style={{ width: "100%", minHeight: 300, maxWidth: 680 }}>
        <AgGridReact
          animateRows={true}
          columnDefs={columnDefs}
          rowData={rowData}
          icons={{
            sortAscending: getUpIconHTML(),
            sortDescending: getDownIconHTML(),
          }}
        />
      </div>
    </FlexDiv>
  );
};

export default AssignedRules;
