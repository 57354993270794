import * as React from "react";
import { useMutation, gql } from "@apollo/client";
import styled from "styled-components";
import { MixpanelActions } from "src/services/mixpanel";
import { useModalContext } from "src/utils/hooks";
import { AppText } from "../UI";
import { theme } from "../../utils/theme";

import { appToast, errorToast } from "../../utils/toast";
import { Modal } from "./Modal";
import { PhoenixAppButton } from "../UI/Phoenix";

const UPDATE_SAVED_VIEW = gql`
  mutation createOrUpdateAllLeadsSavedViewFilter($allLeadsSavedViewFilterInput: AllLeadsSavedViewFilterInput!) {
    createOrUpdateAllLeadsSavedViewFilter(allLeadsSavedViewFilterInput: $allLeadsSavedViewFilterInput) {
      id
      is_default
      is_favorite
    }
  }
`;

const SetLeadFilterSavedViewAsDefaultModal: React.FC<{ defaultView: any }> = ({ defaultView }) => {
  const {
    leadFilterSavedViewModalData,
    setLeadFilterSavedViewModalData,
    setShowSetLeadFilterSavedViewAsDefaultModal,
    showSetLeadFilterSavedViewAsDefaultModal,
  } = useModalContext();

  const closeModal = () => {
    setShowSetLeadFilterSavedViewAsDefaultModal(false);
    setLeadFilterSavedViewModalData(null);
  };

  const [updateAllLeadsSavedViewFilter, { loading }] = useMutation(UPDATE_SAVED_VIEW, {
    onCompleted() {
      appToast("Saved view marked as default");
      MixpanelActions.track("Lead filter saved view marked as default");
      closeModal();
    },
    onError({ message }) {
      errorToast(message);
      console.log("Error in updateAllLeadsSavedViewFilter: ", message);
    },
    refetchQueries: ["fetchAllLeadsSavedViewFilters"],
  });

  const onUpdate = () => {
    updateAllLeadsSavedViewFilter({
      variables: {
        allLeadsSavedViewFilterInput: {
          id: leadFilterSavedViewModalData?.id!,
          name: leadFilterSavedViewModalData.name,
          description: leadFilterSavedViewModalData.description,
          is_default: true,
        },
      },
    });
  };

  return (
    <Modal open={showSetLeadFilterSavedViewAsDefaultModal} onClose={closeModal}>
      <ModalContent>
        <ModalHeader>
          <HeaderTitle>Set As Default</HeaderTitle>
        </ModalHeader>
        <ModalBody>
          <AppText textAlign="center">
            Setting this view as default will remove "{defaultView?.name}" as default. Would you like to proceed?
          </AppText>
        </ModalBody>

        <ModalFooter>
          <PhoenixAppButton type="button" onClick={closeModal} buttonType="secondary" variant="danger-outline">
            Cancel
          </PhoenixAppButton>
          <PhoenixAppButton type="submit" onClick={onUpdate} buttonType="secondary" variant="brand" disabled={loading}>
            Save
          </PhoenixAppButton>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

const ModalContent = styled.div`
  height: auto;
  max-height: 70vh;
  width: 456px;
`;

const ModalHeader = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  gap: 4px;
  justify-content: center;
  padding-top: 40px;
  padding-bottom: 12px;
`;

const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px 40px 40px 40px;
  position: relative;
  width: 100%;
  z-index: 1;
`;

const ModalFooter = styled.div`
  align-items: center;
  border-top: solid 1px ${theme.NEUTRAL200};
  display: flex;
  gap: 12px;
  justify-content: space-between;
  padding: 16px 40px;
  width: 100%;
`;

const HeaderTitle = styled(AppText)`
  font-size: 14px;
  font-weight: 600;
  /* margin-bottom: 27px; */
`;

export { SetLeadFilterSavedViewAsDefaultModal };
