import { gql, useMutation, useQuery } from "@apollo/client";
import * as Sentry from "@sentry/react";
import { ColDef } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import { loggedInUser } from "../../../../apollo/cache";
import { ModalContext } from "../../../../context";
import { edit, trash } from "../../../../images/NewDesign";
import { theme } from "../../../../utils/theme";
import { appToast } from "../../../../utils/toast";
import { AppErrorText, AppText, FlexDiv, Loading, SkeletonBlock } from "../../../UI";
import { PhoenixAppButton, PhoenixIcon } from "../../../UI/Phoenix";
import {
  CreateDispositionReasonModal,
  CreateLeadSourceModalV2,
  DeleteFieldV2,
  PipelineCloseDateModalV2,
  PipelineMetricDefaultValueModalV2,
  UpdateIndustryV2,
} from "../../../modal";
import { LeadCustomEmptyPage } from "./LeadCustomFieldEmptyTable";
import { LeadCustomFieldTable } from "./LeadCustomFieldTable";

// import default styling for ag-grid
import "ag-grid-community/dist/styles/ag-grid.css";
import "./ObjectManager.css";
import { getUpIconHTML, getDownIconHTML } from "../../../../utils/misc";
import Switch from "react-switch";
import { DispositionReason } from "src/types";
import { useFlags } from "launchdarkly-react-client-sdk";
import { MixpanelActions } from "src/services/mixpanel";
interface DisappearingDivProps {
  blinds: boolean;
  setBlinds: (blinds: boolean) => void;
  togglePageTitle?: () => void;
}

const FETCH_INDUSTRY_OPTIONS = gql`
  query fetchIndustryOptions {
    fetchIndustryOptions {
      id
      label
      sub_industries
    }
  }
`;

const FETCH_LEAD_SOURCE_OPTIONS = gql`
  query fetchLeadSourceOptions {
    fetchLeadSourceOptions {
      id
      label
    }
  }
`;

const FETCH_PIPELINE_VALUES = gql`
  query fetchOrganization {
    fetchOrganization {
      id
      mrr
      mrr_label
      day_to_close
      mrr_required_on_sale
    }
  }
`;

const FETCH_DISPOSITION_REASONS = gql`
  query fetchDispositionReasons($disposition_group: DispositionGroup!) {
    fetchDispositionReasons(disposition_group: $disposition_group) {
      id
      disposition_group
      reason
      is_other
      created_at
      updated_at
      deleted_at
    }
  }
`;

const DELETE_DISPOSITION_REASON = gql`
  mutation deleteDispositionReason($id: String!) {
    deleteDispositionReason(id: $id) {
      id
    }
  }
`;

const UPDATE_ALLOW_FREE_TEXT_FOR_CALL_RESULT_REASON = gql`
  mutation updateAllowFreeTextForCallResultReason($allow_free_text: Boolean!, $disposition_group: DispositionGroup!) {
    updateAllowFreeTextForCallResultReason(allow_free_text: $allow_free_text, disposition_group: $disposition_group)
  }
`;

const DELETE_SYSTEM_FIELD = gql`
  mutation removeSystemField($systemFieldType: SystemFieldType!, $label: String!) {
    removeSystemField(systemFieldType: $systemFieldType, label: $label)
  }
`;

const LeadObjectManager: React.FC<DisappearingDivProps> = ({ blinds, setBlinds }) => {
  const gridRef = useRef<AgGridReact>(null);
  const {
    leadSourceModalData,
    setLeadSourceModalData,
    industryModalData,
    setIndustryModalData,
    dispositionReasonModalData,
    setDispositionReasonModalData,
    pipelineCloseDateModal,
    setPipelineCloseDateModal,
    pipelineMetricDefaultValueModal,
    setPipelineMetricDefaultValueModal,
  } = useContext(ModalContext);
  const deleteType = useRef("");

  const [rowData, setRowData] = useState<any[]>([]);
  const [colDefs, setColDefs] = useState<ColDef[]>([]);
  const [selectedField, setSelectedField] = useState("");
  const [isCustomField, setIsCustomField] = useState(false);

  const [deleteSystemField, { loading: loadingSystemField, error: errorSystemField }] = useMutation(
    DELETE_SYSTEM_FIELD,
    {
      onCompleted({ deleteSystemField }) {
        appToast("Deleted system field");
        MixpanelActions.track(`${deleteType.current} Deleted`);
      },
      onError({ message }) {
        appToast(message);
        Sentry.captureEvent({
          message: `deleteSystemField GraphQL Error: ${message}`,
        });
        console.log("Error in deleteSystemField: ", message);
      },
      refetchQueries: ["fetchLeadSourceOptions", "fetchIndustryOptions"],
    },
  );

  const [
    deleteDispositionReason,
    { loading: loadingDeleteDispositionReason, error: errorDeleteDispositionReason },
  ] = useMutation(DELETE_DISPOSITION_REASON, {
    onCompleted({ deleteDispositionReason }) {
      appToast("Deleted reason.");
    },
    onError({ message }) {
      appToast(message);
      Sentry.captureEvent({
        message: `deleteDispositionReason GraphQL Error: ${message}`,
      });
      console.log("Error in deleteDispositionReason: ", message);
    },
    refetchQueries: ["fetchDispositionReasons"],
  });

  const {
    data: dataIndustries,
    loading: loadingIndustries,
    error: errorIndustries,
    refetch: refreshIndustry,
  } = useQuery(FETCH_INDUSTRY_OPTIONS, {
    fetchPolicy: "cache-and-network",
    variables: { org_id: loggedInUser().organization_id },
    onError({ message, name }) {
      console.log(`Error in ${name}: `, message);
    },
  });
  const { data: dataLeadSources, loading: loadingLeadSources, error: errorLeadSources } = useQuery(
    FETCH_LEAD_SOURCE_OPTIONS,
    {
      fetchPolicy: "cache-and-network",
      variables: { org_id: loggedInUser().organization_id },
      onError({ message, name }) {
        console.log(`Error in ${name}: `, message);
      },
    },
  );

  const { data: dataPipelineValues, loading: loadingPipelineValues, error: errorPipelineValues } = useQuery(
    FETCH_PIPELINE_VALUES,
    {
      fetchPolicy: "cache-and-network",
      variables: { org_id: loggedInUser().organization_id },
      onError({ message, name }) {},
    },
  );

  const {
    data: NotInterestedDispositionReasons,
    loading: loadingNotInterestedDispositionReasons,
    error: errorNotInterestedDispositionReasons,
  } = useQuery<{
    fetchDispositionReasons: DispositionReason[];
  }>(FETCH_DISPOSITION_REASONS, {
    fetchPolicy: "cache-and-network",
    variables: { disposition_group: "NOT_INTERESTED" },
    onError({ message, name }) {
      console.log(`Error in ${name}: `, message);
    },
  });

  // we don't need to show other disposition as it is not editable and is controlled by the the switch
  const filteredNotInterestedDispositionReasons = useMemo(() => {
    return NotInterestedDispositionReasons?.fetchDispositionReasons?.filter((reason) => !reason.is_other) || [];
  }, [NotInterestedDispositionReasons]);

  const {
    data: NotQualifiedDispositionReasons,
    loading: loadingNotQualifiedDispositionReasons,
    error: errorNotQualifiedDispositionReasons,
  } = useQuery<{
    fetchDispositionReasons: DispositionReason[];
  }>(FETCH_DISPOSITION_REASONS, {
    fetchPolicy: "cache-and-network",
    variables: { disposition_group: "NOT_QUALIFIED" },
    onError({ message, name }) {
      console.log(`Error in ${name}: `, message);
    },
  });

  const filteredNotQualifiedDispositionReasons = useMemo(() => {
    return NotQualifiedDispositionReasons?.fetchDispositionReasons?.filter((reason) => !reason.is_other) || [];
  }, [NotQualifiedDispositionReasons]);

  const allowsOtherNotInterestedReason = useMemo(() => {
    return NotInterestedDispositionReasons?.fetchDispositionReasons?.find((reason) => reason.is_other) ?? false;
  }, [NotInterestedDispositionReasons]);

  const allowsOtherNotQualifiedReason = useMemo(() => {
    return NotQualifiedDispositionReasons?.fetchDispositionReasons?.find((reason) => reason.is_other) ?? false;
  }, [NotQualifiedDispositionReasons]);

  const [
    updateAllowFreeTextForCallResultReason,
    { loading: loadingUpdateAllowFreeTextForCallResultReason, error: errorUpdateAllowFreeTextForCallResultReason },
  ] = useMutation(UPDATE_ALLOW_FREE_TEXT_FOR_CALL_RESULT_REASON, {
    onCompleted({ updateAllowFreeTextForCallResultReason }) {
      appToast("Successfully updated allow free text option");
    },
    onError({ message }) {
      appToast(message);
      Sentry.captureEvent({
        message: `updateAllowFreeTextForCallResultReason GraphQL Error: ${message}`,
      });
      console.log("Error in updateAllowFreeTextForCallResultReason: ", message);
    },
    refetchQueries: ["fetchDispositionReasons"],
  });

  const { logDispositionReason } = useFlags();

  const baseSystemFields = ["Lead Source", "Industry", "Default Revenue Forecast Value", "Default Close Date"];

  const dispositionReasonFields = ["Not Interested Reason", "Not Qualified Reason"];

  const system_fields = logDispositionReason ? [...baseSystemFields, ...dispositionReasonFields] : baseSystemFields;

  const fetchObjectFields = (data: string[]) => {
    return data?.map((field, idx) => {
      return (
        <LeadObjectFieldDiv key={`${field}-idx`} selected={selectedField === field}>
          <AppText
            fontSize={14}
            fontWeight={500}
            lineHeight={20}
            style={{
              width: "100%",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {field}
          </AppText>

          <PhoenixAppButton
            buttonType="ghost-large"
            variant="brand"
            onClick={() => {
              setSelectedField(field);
              setIsCustomField(system_fields.includes(field) ? false : true);
            }}
          >
            MANAGE
          </PhoenixAppButton>
        </LeadObjectFieldDiv>
      );
    });
  };
  const ManageCellRenderer = ({
    id,
    label,
    renderType,
    sub_industries,
  }: {
    id?: string;
    label?: string;
    renderType: "EditOnly" | "DeleteOnly" | "Both";
    sub_industries?: string[];
  }) => {
    return (
      <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "40px", gap: "8px" }}>
        {(renderType === "EditOnly" || renderType === "Both") && (
          <PhoenixIcon
            svg={edit}
            size={16}
            onClick={() => {
              handleModalAction({ id, label: label || "", selectedField, type: "edit", sub_industries });
            }}
          />
        )}
        {(renderType === "DeleteOnly" || renderType === "Both") && (
          <PhoenixIcon
            svg={trash}
            variant="danger"
            size={16}
            onClick={() => {
              handleModalAction({ id, label: label || "", selectedField, type: "delete" });
            }}
          />
        )}
      </div>
    );
  };

  const setObjectRows = ({ selectedField }: { selectedField: string }) => {
    switch (selectedField) {
      case "Lead Source":
        const rowData = dataLeadSources?.fetchLeadSourceOptions || [];
        const alphabeticalRowData = [...rowData].sort((a: any, b: any) => a?.label.localeCompare(b?.label));
        setRowData(alphabeticalRowData);
        setColDefs([
          {
            headerName: selectedField,
            field: "label",
            flex: 1,
            headerClass: "ag-object-manager-header",
            sortable: true,
          },
          {
            headerName: "Manage Field",
            field: "id",
            cellRenderer: (params: any) =>
              ManageCellRenderer({ renderType: "DeleteOnly", id: params.data.id, label: params.data.label }),
            flex: 1,

            headerClass: "ag-object-manager-header ag-object-manager-header-center",
          },
        ]);
        break;
      case "Industry":
        const industryData = dataIndustries?.fetchIndustryOptions || [];
        const alphabeticalIndustryData = [...industryData].sort((a: any, b: any) => a?.label.localeCompare(b?.label));
        setRowData(alphabeticalIndustryData);
        setColDefs([
          {
            headerName: selectedField,
            field: "label",
            flex: 1,
            headerClass: "ag-object-manager-header",
            sortable: true,
          },
          {
            headerName: "Sub Industry",
            field: "sub_industries",
            flex: 4,
            headerClass: "ag-object-manager-header",
            sortable: true,
          },
          {
            headerName: "Manage Field",
            field: "manage",
            cellRenderer: (params: any) =>
              ManageCellRenderer({
                renderType: "Both",
                id: params.data.id,
                label: params.data.label,
                sub_industries: params.data.sub_industries,
              }),
            flex: 1,
            headerClass: "ag-object-manager-header ag-object-manager-header-center",
          },
        ]);
        break;
      case "Default Revenue Forecast Value":
        setRowData([{ label: "MRR + One-Time Fees", mrr: dataPipelineValues?.fetchOrganization?.mrr }]);
        setColDefs([
          {
            headerName: "Selected Field",
            field: "label",
            flex: 3,
            headerClass: "ag-object-manager-header",
            sortable: true,
          },
          {
            headerName: "Value",
            field: "mrr",
            flex: 1,
            headerClass: "ag-object-manager-header",
            sortable: true,
          },
          {
            headerName: "Manage Field",
            field: "manage",
            cellRenderer: () => ManageCellRenderer({ renderType: "EditOnly" }),
            flex: 3,
            headerClass: "ag-object-manager-header ag-object-manager-header-center",
          },
        ]);
        break;
      case "Default Close Date":
        setRowData([
          { label: "Default Close Date", day_to_close: dataPipelineValues?.fetchOrganization?.day_to_close },
        ]);
        setColDefs([
          {
            headerName: "Selected Field",
            field: "label",
            flex: 3,
            headerClass: "ag-object-manager-header",
            sortable: true,
          },
          {
            headerName: "Value",
            field: "day_to_close",
            flex: 1,
            headerClass: "ag-object-manager-header",
            sortable: true,
          },
          {
            headerName: "Manage Field",
            field: "manage",
            cellRenderer: () => ManageCellRenderer({ renderType: "EditOnly" }),
            cellStyle: {
              textAlign: "center",
            },
            headerClass: "ag-object-manager-header ag-object-manager-header-center",
          },
        ]);
        break;
      case "Not Interested Reason":
        setRowData(filteredNotInterestedDispositionReasons);
        setColDefs([
          {
            headerName: "Reason",
            field: "reason",
            flex: 3,
            headerClass: "ag-object-manager-header",
            sortable: true,
          },
          {
            headerName: "Manage Field",
            field: "id",
            cellRenderer: (params: any) =>
              ManageCellRenderer({ renderType: "DeleteOnly", id: params.data.id, label: params.data.reason }),
            flex: 1,
            headerClass: "ag-object-manager-header ag-object-manager-header-center",
          },
        ]);
        break;
      case "Not Qualified Reason":
        setRowData(filteredNotQualifiedDispositionReasons);
        setColDefs([
          {
            headerName: "Reason",
            field: "reason",
            flex: 3,
            headerClass: "ag-object-manager-header",
            sortable: true,
          },

          {
            headerName: "Manage Field",
            field: "id",
            cellRenderer: (params: any) =>
              ManageCellRenderer({ renderType: "DeleteOnly", id: params.data.id, label: params.data.reason }),
            flex: 1,
            headerClass: "ag-object-manager-header ag-object-manager-header-center",
          },
        ]);
        break;
      default:
        break;
    }
  };

  const handleAddModal = ({ selectedField }: { selectedField: string }) => {
    switch (selectedField) {
      case "Lead Source":
        setLeadSourceModalData({
          id: "",
          delete_modal_on: false,
          name_modal_on: true,
          label: "",
        });
        break;
      case "Industry":
        setIndustryModalData({
          id: "",
          delete_modal_on: false,
          name_modal_on: true,
          label: "",
          sub_fields: [],
        });
        break;
      case "Not Interested Reason":
        setDispositionReasonModalData({
          id: "",
          delete_modal_on: false,
          name_modal_on: true,
          label: "",
          disposition_type: "Not Interested",
        });
        break;
      case "Not Qualified Reason":
        setDispositionReasonModalData({
          id: "",
          delete_modal_on: false,
          name_modal_on: true,
          label: "",
          disposition_type: "Not Qualified",
        });
        break;
      default:
        break;
    }
  };

  const handleModalAction = ({
    id,
    label,
    selectedField,
    type,
    sub_industries,
  }: {
    id?: string;
    label: string;
    selectedField: string;
    type: "edit" | "delete";
    sub_industries?: string[];
  }) => {
    const delete_modal_on = type === "delete" ? true : false;
    const name_modal_on = type === "edit" ? true : false;

    if (delete_modal_on) {
      deleteType.current = selectedField;
    }

    switch (selectedField) {
      case "Lead Source":
        if (!id) return;
        setLeadSourceModalData({
          id,
          delete_modal_on,
          name_modal_on,
          label,
        });
        break;
      case "Industry":
        if (!id) return;
        setIndustryModalData({
          id,
          delete_modal_on,
          name_modal_on,
          label,
          sub_fields: sub_industries || [],
        });
        break;
      case "Default Revenue Forecast Value":
        setPipelineMetricDefaultValueModal(true);
        break;
      case "Default Close Date":
        setPipelineCloseDateModal(true);
        break;
      case "Not Interested Reason":
        if (!id) return;
        setDispositionReasonModalData({
          id,
          delete_modal_on,
          name_modal_on,
          label,
          disposition_type: "Not Interested",
        });
        break;
      case "Not Qualified Reason":
        if (!id) return;
        setDispositionReasonModalData({
          id,
          delete_modal_on,
          name_modal_on,
          label,
          disposition_type: "Not Qualified",
        });
        break;
      default:
        break;
    }
  };
  const renderModal = () => {
    if (leadSourceModalData.name_modal_on) {
      return (
        <CreateLeadSourceModalV2
          blinds={leadSourceModalData.name_modal_on}
          close={() => {
            setLeadSourceModalData({
              name_modal_on: false,
              delete_modal_on: false,
              id: "",
              label: "",
            });
          }}
        />
      );
    }
    if (leadSourceModalData.delete_modal_on) {
      return (
        <DeleteFieldV2
          customFields
          blinds={leadSourceModalData.delete_modal_on}
          close={() =>
            setLeadSourceModalData({
              name_modal_on: false,
              delete_modal_on: false,
              id: "",
              label: "",
            })
          }
          id={leadSourceModalData.id}
          onDeleteFunction={async (item_id: string) => {
            await deleteSystemField({
              variables: {
                systemFieldType: "LeadSource",
                label: item_id,
              },
            });
          }}
          loading={loadingSystemField}
          fieldType={"Lead Source"}
          fieldName={leadSourceModalData.label}
        />
      );
    }
    if (industryModalData.name_modal_on) {
      return (
        <UpdateIndustryV2
          blinds={industryModalData.name_modal_on}
          close={() => {
            setIndustryModalData({
              name_modal_on: false,
              delete_modal_on: false,
              id: "",
              label: "",
              sub_fields: [],
            });
          }}
          setDeleteModalData={() =>
            setIndustryModalData({
              ...industryModalData,
              delete_modal_on: true,
              name_modal_on: false,
            })
          }
          {...industryModalData}
        />
      );
    }

    if (industryModalData.delete_modal_on) {
      const id = industryModalData.id || "";
      return (
        <DeleteFieldV2
          customFields
          blinds={industryModalData.delete_modal_on}
          close={() =>
            setIndustryModalData({
              name_modal_on: false,
              delete_modal_on: false,
              id: "",
              label: "",
              sub_fields: [],
            })
          }
          id={id}
          onDeleteFunction={async (item_id: string) => {
            await deleteSystemField({
              variables: {
                systemFieldType: "Industry",
                label: item_id,
              },
            });
          }}
          loading={loadingSystemField}
          fieldType={"Industry"}
          fieldName={industryModalData.label}
        />
      );
    }
    if (pipelineCloseDateModal) {
      return <PipelineCloseDateModalV2 />;
    }

    if (pipelineMetricDefaultValueModal) {
      return <PipelineMetricDefaultValueModalV2 />;
    }

    if (dispositionReasonModalData.name_modal_on) {
      return (
        <CreateDispositionReasonModal
          blinds={dispositionReasonModalData.name_modal_on}
          close={() => {
            setDispositionReasonModalData({
              name_modal_on: false,
              delete_modal_on: false,
              id: "",
              label: "",
              disposition_type: "Not Interested",
            });
          }}
        />
      );
    }

    if (dispositionReasonModalData.delete_modal_on) {
      return (
        <DeleteFieldV2
          blinds={dispositionReasonModalData.delete_modal_on}
          close={() => {
            setDispositionReasonModalData({
              name_modal_on: false,
              delete_modal_on: false,
              id: "",
              label: "",
              disposition_type: "Not Interested",
            });
          }}
          onDeleteFunction={async () => {
            await deleteDispositionReason({
              variables: {
                id: dispositionReasonModalData.id,
              },
            });
          }}
          customFields
          loading={loadingSystemField}
          fieldType={selectedField}
          fieldName={dispositionReasonModalData.label}
        />
      );
    }
  };

  useEffect(() => {
    setSelectedField("Lead Source");
  }, []);

  useEffect(() => {
    setObjectRows({ selectedField });
  }, [
    selectedField,
    dataIndustries,
    dataLeadSources,
    dataPipelineValues,
    NotInterestedDispositionReasons,
    NotQualifiedDispositionReasons,
  ]);

  const loading =
    loadingIndustries ||
    loadingLeadSources ||
    loadingPipelineValues ||
    rowData === undefined ||
    loadingSystemField ||
    loadingNotInterestedDispositionReasons ||
    loadingNotQualifiedDispositionReasons ||
    loadingUpdateAllowFreeTextForCallResultReason ||
    loadingDeleteDispositionReason;

  const error =
    errorIndustries ||
    errorLeadSources ||
    errorPipelineValues ||
    errorSystemField ||
    errorNotInterestedDispositionReasons ||
    errorNotQualifiedDispositionReasons ||
    errorUpdateAllowFreeTextForCallResultReason ||
    errorDeleteDispositionReason;

  const renderTable = () => {
    if (error) {
      return <GlobalErrorState />;
    }
    if (loading) {
      return <GlobalLoadingState />;
    } else if (isCustomField) {
      return <LeadCustomFieldTable />;
    } else {
      if (!rowData?.length) {
        return (
          <LeadCustomEmptyPage
            blinds={blinds}
            defaultField={selectedField}
            setBlinds={setBlinds}
            type={selectedField}
          />
        );
      } else {
        if (selectedField === "Industry" || selectedField === "Lead Source") {
          return (
            <>
              <PhoenixAppButton
                buttonType="secondary"
                onClick={() => {
                  handleAddModal({ selectedField });
                }}
                style={{ margin: "24px 0 0 24px" }}
              >
                Add {selectedField}
              </PhoenixAppButton>
              <div className="ag-theme-object-manager" style={{ minHeight: 500, width: "100%", padding: "24px" }}>
                <AgGridReact
                  ref={gridRef}
                  rowData={rowData}
                  columnDefs={colDefs}
                  icons={{
                    sortAscending: getUpIconHTML(),
                    sortDescending: getDownIconHTML(),
                  }}
                />
              </div>
            </>
          );
        }
        if (selectedField === "Not Interested Reason" || selectedField === "Not Qualified Reason") {
          return (
            <>
              <FlexDiv direction="row" justify="space-between" align="center" width="100%" padding="24px 24px 0px 24px">
                <PhoenixAppButton
                  buttonType="secondary"
                  onClick={() => {
                    handleAddModal({ selectedField });
                  }}
                >
                  Add {selectedField}
                </PhoenixAppButton>
                <FlexDiv gap={8} align="center">
                  <Switch
                    checked={
                      selectedField === "Not Interested Reason"
                        ? !!allowsOtherNotInterestedReason
                        : !!allowsOtherNotQualifiedReason
                    }
                    name="allow-other"
                    onChange={(check) => {
                      const formatedField =
                        selectedField === "Not Interested Reason" ? "NOT_INTERESTED" : "NOT_QUALIFIED";

                      updateAllowFreeTextForCallResultReason({
                        variables: {
                          allow_free_text: check,
                          disposition_group: formatedField,
                        },
                      });
                    }}
                    onColor={theme.PRIMARY500}
                    height={16}
                    width={32}
                    checkedIcon={false}
                    uncheckedIcon={false}
                    handleDiameter={12}
                  />
                  <AppText>Allow reps to enter free text to describe other reasons</AppText>
                </FlexDiv>
              </FlexDiv>
              <div className="ag-theme-object-manager" style={{ minHeight: 500, width: "100%", padding: "24px" }}>
                <AgGridReact
                  ref={gridRef}
                  rowData={rowData}
                  columnDefs={colDefs}
                  icons={{
                    sortAscending: getUpIconHTML(),
                    sortDescending: getDownIconHTML(),
                  }}
                />
              </div>
            </>
          );
        }
        return (
          <div className="ag-theme-object-manager" style={{ minHeight: 500, width: "100%", padding: "24px" }}>
            <AgGridReact
              ref={gridRef}
              rowData={rowData}
              columnDefs={colDefs}
              icons={{
                sortAscending: getUpIconHTML(),
                sortDescending: getDownIconHTML(),
              }}
            />
          </div>
        );
      }
    }
  };
  return (
    <>
      <Container>
        <LeadObjectFieldOptions>
          <LeadObjectFields>
            <LeadObjectFieldTitle>SYSTEM FIELDS</LeadObjectFieldTitle>
            {fetchObjectFields(system_fields)}
          </LeadObjectFields>
          <BreakLine></BreakLine>
          <LeadObjectCustomFields>
            <LeadObjectFieldTitle>CUSTOM FIELDS</LeadObjectFieldTitle>
            {fetchObjectFields(["Custom Fields"])}
          </LeadObjectCustomFields>
        </LeadObjectFieldOptions>
        <LeadObjectFieldData>
          {renderModal()}

          {renderTable()}
        </LeadObjectFieldData>
      </Container>
    </>
  );
};

const GlobalLoadingState = () => {
  const numRows = 10;

  return (
    <FlexDiv
      direction="column"
      style={{
        width: "100%",
        padding: "24px",
        overflow: "hidden",
        height: "max-content",
      }}
      gap={24}
    >
      <SkeletonBlock height={40} width={200} borderRadius={8} />
      <FlexDiv
        direction="column"
        gap={16}
        style={{
          marginTop: 24,
        }}
      >
        {Array.from({ length: numRows })?.map((_, index) => (
          <SkeletonBlock key={index} height={40} width="100%" borderRadius={8} />
        ))}
      </FlexDiv>
    </FlexDiv>
  );
};

const GlobalErrorState = () => {
  return <AppErrorText>Error loading data</AppErrorText>;
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: space-between;
  align-self: stretch;
  border-top: 2px solid ${theme.NEUTRAL200};
  width: calc(100% + 48px); /* 100% width + 24px margin on each side */
  margin: 0 -24px;
`;

const LeadObjectFieldOptions = styled.div`
  display: flex;
  width: 400px;
  height: 100%;
  min-height: 888px;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  border-right: 1px solid ${theme.NEUTRAL200};
  background: ${theme.WHITE_COLOR};
`;

const LeadObjectFieldTitle = styled.div`
  text-align: left;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 1px;
  text-transform: uppercase;
  width: 100%;
  color: ${theme.NEUTRAL300};
  width: 320px;
  align-self: center;
`;

const BreakLine = styled.div`
  width: 100%;
  height: 1px;
  background: ${theme.NEUTRAL200};
  margin: 24px 0px;
`;
const LeadObjectFieldData = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
  align-self: stretch;
`;

const LeadObjectFields = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  align-self: stretch;
  border-radius: 8px;
  margin-top: 24px;
  height: max-content;
  overflow-y: auto;
  gap: 16px;
`;
const LeadObjectCustomFields = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  align-self: stretch;
  border-radius: 8px;
  gap: 16px;
`;

const LeadObjectFieldDiv = styled.div<{ selected: boolean }>`
  border: ${({ selected }) =>
    selected ? `1px solid ${theme.border.brand.primary}` : `1px solid ${theme.border.neutral.secondary}`};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 16px;
  align-items: center;
  align-self: stretch;
  border-radius: 8px;
  width: 320px;
  align-self: center;
  ${({ selected }) => selected && `background: ${theme.fill.brand.secondary}`}
`;

export { LeadObjectManager };
