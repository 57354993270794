import * as Sentry from "@sentry/react";
import React, { MutableRefObject, ReactHTMLElement, useMemo } from "react";
import styled from "styled-components";
import { theme } from "../../utils/theme";
import { AppErrorText, AppText, FlexDiv, SkeletonBlock } from "../UI";

import { useEffect, useRef, useState } from "react";
import { BiStop } from "react-icons/bi";
import { useReactMediaRecorder } from "react-media-recorder";
import Spinner from "react-spinkit";
import {
  pause_circle_new,
  play_circle_new,
  record_outer_ring,
  stop_circle,
  stop_outer_ring,
} from "src/images/NewDesign";
import { useModalContext } from "src/utils/hooks";
import WaveSurfer from "wavesurfer.js";
import { convertBlobToMediaFile } from "../../utils/media";
import { appToast } from "../../utils/toast";
import { PhoenixStyledTooltip } from "../Dumb/PhoenixStyledTooltip";
import { WavefromTimeline } from "../Segments/CallReportSegments/WaveformV2";
import { PhoenixAppButton, PhoenixIcon, PhoenixMultiSelect } from "../UI/Phoenix";
import { PhoenixInput } from "../UI/Phoenix/PhoenixInput";
import SavedRecordings from "./VoicemailDrops/SavedRecordings";
import { gql, useQuery } from "@apollo/client";
import { OptionItem } from "src/types";
import { axios } from "src/services/rest";
import { restAPI } from "src/apollo";
import { BACKEND_URL, TOKEN_PERSIST_KEY } from "src/utils/variables";
import { FetchVoiceMailScriptsExpectedResponse } from "src/types/VoicemailTypes";

const FETCH_VOICEMAIL_SCRIPTS = gql`
  query FetchVoicemailScripts($fetchVoiceMailScriptsId: String) {
    fetchVoiceMailScripts(id: $fetchVoiceMailScriptsId)
  }
`;

const formatSeconds = (seconds: number) => {
  // 00:00 format minutes:seconds

  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;
  return `${minutes.toString().padStart(2, "0")}:${remainingSeconds.toString().padStart(2, "0")}`;
};

const VoicemailDrops: React.FC = () => {
  const {
    data: voiceMailScriptsData,
    loading: voiceMailScriptsLoading,
    error: voiceMailScriptsError,
    refetch: voiceMailScriptsRefetch,
  } = useQuery<FetchVoiceMailScriptsExpectedResponse>(FETCH_VOICEMAIL_SCRIPTS, {
    fetchPolicy: "cache-and-network",
    onCompleted: (data) => {
      console.log(data);
    },
    onError: (error) => {
      appToast("Unable to fetch voicemail scripts");
    },
    notifyOnNetworkStatusChange: true,
  });

  const FORMATED_VOICEMAIL_SCRIPTS = useMemo(
    () =>
      voiceMailScriptsData?.fetchVoiceMailScripts.map((script) => ({
        label: script.title,
        value: script.id,
      })) || [],
    [voiceMailScriptsData],
  );

  const { setShowScriptPreviewModal, setScriptPreviewModalDataId } = useModalContext();

  const [recordingFile, setRecordingFile] = useState<any>(null);

  const [selectedScriptId, setSelectedScriptId] = useState<string | undefined>(undefined);

  const [mediaDuration, setMediaDuration] = useState(0);

  console.log(selectedScriptId, FORMATED_VOICEMAIL_SCRIPTS, "wow");

  const [recordingTitle, setRecordingTitle] = useState("");
  const [recordingDescription, setRecordingDescription] = useState("");

  const handleSaveRecording = async ({
    recordingFile,
    selectedScriptId,
    recordingDescription,
    recordingTitle,
  }: {
    recordingFile: any;
    selectedScriptId: string | undefined;
    recordingDescription: string;
    recordingTitle: string;
  }) => {
    if (!recordingFile || !selectedScriptId || !recordingTitle || !recordingDescription) {
      appToast("Please provide a recording, script, title, and description");
      return;
    }

    try {
      console.log("Recording file type:", recordingFile.type);
      console.log("Recording file size:", recordingFile.size);

      const formData = new FormData();
      formData.append("file", recordingFile);
      formData.append("script_id", selectedScriptId);
      formData.append("title", recordingTitle);
      formData.append("description", recordingDescription);
      formData.append("duration_seconds", Math.round(mediaDuration).toString());
      formData.append("size", recordingFile.size.toString());

      // const response = await restAPI.post(`/uploadVoicemailDrop`, formData);
      const response = await axios.post("https://api.beta.sellfire.com/api/uploadVoicemailDrop", formData, {
        headers: {
          Authorization: `${localStorage.getItem(TOKEN_PERSIST_KEY)}`,
        },
      });

      if (response.status === 200) {
        appToast("Recording saved successfully");
        voiceMailScriptsRefetch();
        setRecordingTitle("");
        setRecordingDescription("");
        setSelectedScriptId(undefined);
        setMediaDuration(0);
        setRecordingFile(null);
        setShowScriptPreviewModal(false);
      } else {
        appToast("Failed to save recording");
      }
    } catch (error: any) {
      console.error("Error saving recording:", error);
      appToast("Error saving recording");
    }
  };

  const globalLoading = voiceMailScriptsLoading;

  const globalError = voiceMailScriptsError;

  if (globalError) {
    return (
      <Main>
        <AppErrorText>Error fetching voicemail scripts</AppErrorText>
      </Main>
    );
  }

  if (globalLoading) {
    return (
      <Main>
        <SkeletonBlock width="100%" height="70vh" borderRadius={8} />
      </Main>
    );
  }

  return (
    <Sentry.ErrorBoundary fallback={"An error has occured at the Voicemail Drops page"}>
      <Main>
        <PhoenixStyledTooltip id="call-library-tooltip" />

        <Header>
          <AppText fontSize={22} fontWeight={500} lineHeight={28}>
            Voicemail Drops
          </AppText>
        </Header>

        <BodyWrap>
          <Body>
            <VoicemailGrid>
              <Left
                direction="column"
                padding={"24px 40px 24px 24px"}
                gap={16}
                style={{ borderRight: `1px solid ${theme.border.neutral.primary}` }}
              >
                <AppText fontSize={18} fontWeight={500} lineHeight={24} style={{ marginBottom: 8 }}>
                  Record Outbound Voicemail
                </AppText>
                <PhoenixInput
                  titleText="Name"
                  name="voicemail-name"
                  value={recordingTitle}
                  placeholder="Name this recording"
                  onChange={(e: any) => setRecordingTitle(e.target.value)}
                  displayNoContextText
                />
                <PhoenixInput
                  titleText="Description"
                  name="voicemail-description"
                  placeholder="What is it used for?"
                  value={recordingDescription}
                  displayNoContextText
                  onChange={(e: any) => setRecordingDescription(e.target.value)}
                />
                <FlexDiv gap={16} style={{ width: "100%" }} justify="space-between">
                  <PhoenixMultiSelect
                    width={211}
                    voicemail-selected-script-id=""
                    name="voicemail-selected-script-id"
                    placeholder="Select a Script"
                    options={FORMATED_VOICEMAIL_SCRIPTS}
                    value={FORMATED_VOICEMAIL_SCRIPTS.find((script) => script.value === selectedScriptId)}
                    isMulti={false}
                    onChange={(option: OptionItem) => {
                      if (option?.value) {
                        setShowScriptPreviewModal(true);
                        setScriptPreviewModalDataId(option?.value as string);
                      }

                      setSelectedScriptId(option?.value as string);
                    }}
                    marginBottom={false}
                  />
                  <PhoenixAppButton
                    variant="brand"
                    buttonType="secondary"
                    onClick={() =>
                      handleSaveRecording({
                        recordingFile,
                        selectedScriptId,
                        recordingDescription,
                        recordingTitle,
                      })
                    }
                  >
                    Save Recording
                  </PhoenixAppButton>
                </FlexDiv>
              </Left>
              <Right>
                <RightSection
                  recordingFile={recordingFile}
                  setRecordingFile={setRecordingFile}
                  setMediaDuration={setMediaDuration}
                  mediaDuration={mediaDuration}
                />
              </Right>
            </VoicemailGrid>
            <SavedRecordings />
          </Body>
        </BodyWrap>
      </Main>
    </Sentry.ErrorBoundary>
  );
};

const Left = styled(FlexDiv)``;

const Right = styled(FlexDiv)``;

const VoicemailGrid = styled.div`
  display: grid;
  grid-template-columns: 420px 1fr;
  max-width: 1200px;
  background-color: ${theme.WHITE_COLOR};
  border-radius: 8px;
  border: 1px solid ${theme.border.neutral.primary};
`;

const Main = styled.div`
  margin: 24px;

  min-width: fit-content;
  height: 92vh;

  overflow: hidden;
  border: 16px;
  border-radius: 8px;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 24px 40px;

  background-color: ${theme.WHITE_COLOR};
`;

const BodyWrap = styled.div`
  display: flex;
  width: 100%;
  height: 80vh;
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  width: 100%;
  min-width: fit-content;
  height: 100%;
  padding: 24px;

  overflow-y: auto;

  background-color: ${theme.surface.brand.secondary};
`;

const RightSection: React.FC<{
  recordingFile: any;
  setRecordingFile: (recordingFile: any) => void;
  setMediaDuration: (mediaDuration: number) => void;
  mediaDuration: number;
}> = ({ recordingFile, setRecordingFile, setMediaDuration, mediaDuration }) => {
  const waveformRef = useRef<HTMLDivElement>(null);
  const wavesurfer = useRef<WaveSurfer | null>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isRecording, setIsRecording] = useState(false);

  const { status, startRecording, stopRecording, mediaBlobUrl, clearBlobUrl, previewStream } = useReactMediaRecorder({
    video: false,
    blobPropertyBag: { type: "audio/wav" },
  });

  useEffect(() => {
    const tickerContainerHeight = 22;

    if (waveformRef.current && recordingFile) {
      wavesurfer.current = WaveSurfer.create({
        container: waveformRef.current,
        waveColor: theme.text.brand.primary,
        progressColor: theme.text.brand.secondary,
        // hide the default cursor
        cursorColor: "transparent",
        barWidth: 2,
        barRadius: 1,
        responsive: true,
        height: 160 - tickerContainerHeight,
        normalize: true,
        partialRender: true,
      });
      // Create a custom cursor element
      const cursor = document.createElement("div");
      cursor.style.position = "absolute";
      cursor.style.top = `calc(50%)`;
      cursor.style.transform = "translateY(calc(-50% - 18px))";
      cursor.style.width = "1px";
      cursor.style.height = "72px";
      cursor.style.backgroundColor = theme.text.danger.primary;
      cursor.style.zIndex = "1";
      waveformRef.current.appendChild(cursor);

      // Create a circle element at the top of the cursor
      const circle = document.createElement("div");
      circle.style.position = "absolute";
      circle.style.top = "-4px";
      circle.style.left = "-4px";
      circle.style.width = "8px";
      circle.style.height = "8px";
      circle.style.borderRadius = "50%";
      circle.style.backgroundColor = theme.text.danger.primary;
      cursor.appendChild(circle);

      // Update the cursor position based on the current time
      const updateCursorPosition = () => {
        const progress = (wavesurfer.current?.getCurrentTime() || 0) / (wavesurfer.current?.getDuration() || 0);
        cursor.style.left = `${progress * 100}%`;
      };

      wavesurfer.current?.on("audioprocess", updateCursorPosition);
      wavesurfer.current?.on("seek", updateCursorPosition);

      wavesurfer.current?.loadBlob(recordingFile);

      wavesurfer.current?.on("ready", () => {
        setMediaDuration(wavesurfer.current?.getDuration() || 0);
      });

      wavesurfer.current?.on("play", () => {
        setIsPlaying(true);
      });

      wavesurfer.current.on("pause", () => {
        setIsPlaying(false);
      });
    }

    return () => {
      if (wavesurfer.current) {
        wavesurfer.current.destroy();
        wavesurfer.current = null;
      }
      setIsPlaying(false);
    };
  }, [recordingFile]);

  useEffect(() => {
    const convert = async () => {
      const file = await convertBlobToMediaFile(mediaBlobUrl as string);
      setRecordingFile(file);
    };
    convert();
  }, [mediaBlobUrl]);

  const handleRecord = () => {
    setIsRecording(true);
    startRecording();
  };

  const handleStop = () => {
    setIsRecording(false);

    stopRecording();
  };

  const handleStopAudioPlayback = () => {
    wavesurfer.current?.pause();
    wavesurfer.current?.seekTo(0);
  };

  const [currentRecordingDuration, setCurrentRecordingDuration] = useState(0);

  const increaseRecordingDuration = () => {
    setCurrentRecordingDuration((prev) => prev + 1);
  };

  useEffect(() => {
    if (isRecording) {
      const interval = setInterval(increaseRecordingDuration, 1000);
      return () => clearInterval(interval);
    } else {
      setCurrentRecordingDuration(0);
    }
  }, [isRecording]);

  const clearRecording = () => {
    setRecordingFile(null);
    clearBlobUrl();
  };

  const recordingMade = !!mediaBlobUrl && !!recordingFile;

  const handlePlay = () => {
    wavesurfer.current?.play();
  };

  const handlePause = () => {
    wavesurfer.current?.pause();
  };

  return (
    <FlexDiv direction="column" width="100%" height="100%">
      {!recordingMade ? (
        <WaveformContainer recordingMade={false}>
          <RecordingVisualizer isRecording={isRecording} />
        </WaveformContainer>
      ) : (
        <>
          <WaveformContainer recordingMade>
            <div
              style={{
                width: "100%",
              }}
              ref={waveformRef}
            />

            <WavefromTimeline duration={mediaDuration} />
          </WaveformContainer>
        </>
      )}
      <RecordingControls
        isRecording={isRecording}
        handleRecord={handleRecord}
        handleStopRecording={handleStop}
        handleStopAudioPlayback={handleStopAudioPlayback}
        clearRecording={clearRecording}
        recordingMade={recordingMade}
        handlePlay={handlePlay}
        handlePause={handlePause}
        currentRecordingDuration={currentRecordingDuration}
        isPlaying={isPlaying}
      />
    </FlexDiv>
  );
};

const RecordingControls = ({
  isRecording,
  handleRecord,
  handleStopAudioPlayback,
  handleStopRecording,
  clearRecording,
  recordingMade,

  handlePlay,
  handlePause,
  currentRecordingDuration,
  isPlaying,
}: {
  isRecording: boolean;
  handleRecord: () => void;
  handleStopAudioPlayback: () => void;
  handleStopRecording: () => void;
  clearRecording: () => void;
  recordingMade: boolean;

  handlePlay: () => void;
  handlePause: () => void;
  currentRecordingDuration: number;
  isPlaying: boolean;
}) => {
  return (
    <FlexDiv
      justify="space-between"
      style={{ padding: 24, borderTop: `1px solid ${theme.border.neutral.primary}` }}
      align="center"
    >
      {recordingMade ? (
        <RecordModuleDiv>
          <img
            src={isPlaying ? pause_circle_new : play_circle_new}
            onClick={isPlaying ? handlePause : handlePlay}
            style={{ width: "40px", height: "40px", padding: 0 }}
          />
          <PhoenixIcon
            svg={stop_outer_ring}
            onClick={handleStopAudioPlayback}
            variant="neutral"
            size={40}
            pointer={isPlaying}
          />
        </RecordModuleDiv>
      ) : (
        <RecordModuleDiv>
          {isRecording ? (
            <PhoenixIcon svg={stop_outer_ring} onClick={handleStopRecording} variant="neutral" size={40} pointer />
          ) : (
            <PhoenixIcon svg={record_outer_ring} onClick={handleRecord} variant="neutral" size={40} pointer />
          )}

          <FlexDiv direction="column">
            <AppText fontSize={14} fontWeight={500} lineHeight={20}>
              {isRecording ? "Stop Recording" : "Start Recording"}
            </AppText>
            <AppText fontSize={10} fontWeight={400} lineHeight={16}>
              {formatSeconds(currentRecordingDuration)}
            </AppText>
          </FlexDiv>
        </RecordModuleDiv>
      )}
      <PhoenixAppButton
        variant="danger-outline"
        buttonType="secondary"
        disabled={!recordingMade}
        onClick={clearRecording}
      >
        Clear
      </PhoenixAppButton>
    </FlexDiv>
  );
};

const RecordingVisualizer = ({ isRecording }: { isRecording: boolean }) => {
  if (isRecording) {
    return (
      <FlexDiv justify="center" align="center" style={{ height: "100%" }}>
        <Spinner name="line-scale-party" color={theme.text.brand.primary} />
      </FlexDiv>
    );
  }

  return null;
};

const WaveformContainer = styled.div<{ recordingMade: boolean }>`
  width: 100%;
  height: 100%;
  position: relative;
  ${({ recordingMade }) => recordingMade && `background-color: ${theme.fill.brand.secondary};`}
`;

const RecordModuleDiv = styled.div`
  display: flex;
  gap: 16px;
  padding: 8px 16px 8px 8px;
  background-color: ${theme.NEUTRAL100};
  border: 1px solid ${theme.NEUTRAL200};
  align-items: center;
  justify-content: center;
  border-radius: 360px;
  height: 64px;
`;

export { VoicemailDrops };
