import React, { useState, forwardRef, useImperativeHandle } from "react";
import styled from "styled-components";
import { AppText, FlexDiv, Loading } from "src/Components/UI";
import { PhoenixMultiSelect } from "src/Components/UI/Phoenix";
import { gql, useQuery } from "@apollo/client";
import { OptionItem } from "src/types";

const FETCH_HOT_LEAD_CONFIG = gql`
  query fetchHotLeadConfig {
    fetchUser {
      id
      email_opened_notifications_frequency
      email_link_clicked_notification_frequency
    }
  }
`;

const FREQUENCY_OPTIONS = [
  {
    label: "First Occurence",
    value: "FirstOccurrence",
  },
  {
    label: "First Occurence Daily",
    value: "FirstOccurenceDaily",
  },
  {
    label: "Every Occurence",
    value: "EveryOccurrence",
  },
  {
    label: "No Occurence",
    value: "NoOccurrence",
  },
];

export type HotLeadsTabRef = {
  getValues: () => {
    email_opened_notifications_frequency: string;
    email_link_clicked_notification_frequency: string;
  };
};

type HotLeadsTabProps = {
  setHasChanges: (hasChanges: boolean) => void;
};

const HotLeadsTab = forwardRef<HotLeadsTabRef, HotLeadsTabProps>(({ setHasChanges }, ref) => {
  const [hotLeadConfig, setHotLeadConfig] = useState<{
    email_opened_notifications_frequency: string;
    email_link_clicked_notification_frequency: string;
  }>({
    email_opened_notifications_frequency: "",
    email_link_clicked_notification_frequency: "",
  });

  const { loading: hotLeadConfigLoading } = useQuery(FETCH_HOT_LEAD_CONFIG, {
    fetchPolicy: "cache-and-network",
    onCompleted: (data) => {
      setHotLeadConfig({
        email_opened_notifications_frequency: data.fetchUser.email_opened_notifications_frequency,
        email_link_clicked_notification_frequency: data.fetchUser.email_link_clicked_notification_frequency,
      });
    },
  });

  useImperativeHandle(ref, () => ({
    getValues: () => {
      return hotLeadConfig;
    },
  }));

  if (hotLeadConfigLoading) {
    return <Loading />;
  }

  return (
    <FlexDiv direction="column" gap={40}>
      <FlexDiv direction="column" gap={8}>
        <AppText fontSize={18} fontWeight={500}>
          Hot Lead Configuration
        </AppText>
        <AppText fontSize={12} fontWeight={500}>
          Configure the frequency of rep notifications of a lead’s inbound activity.
        </AppText>
      </FlexDiv>

      <SelectWrapper direction="column" gap={24}>
        <PhoenixMultiSelect
          isClearable={false}
          isMulti={false}
          marginBottom={false}
          name="email-viewed"
          options={FREQUENCY_OPTIONS}
          placeholder="Select Frequency"
          titleFontWeight={500}
          titleText="Email Viewed"
          onChange={(option: OptionItem) => {
            setHotLeadConfig({
              ...hotLeadConfig,
              email_opened_notifications_frequency: option.value as string,
            });
            setHasChanges(true);
          }}
          value={FREQUENCY_OPTIONS.find(
            (option) => option.value === hotLeadConfig.email_opened_notifications_frequency,
          )}
        />

        <PhoenixMultiSelect
          isClearable={false}
          isMulti={false}
          marginBottom={false}
          name="link-clicked"
          options={FREQUENCY_OPTIONS}
          placeholder="Select Frequency"
          titleFontWeight={500}
          titleText="Link Clicked"
          onChange={(option: OptionItem) => {
            setHotLeadConfig({
              ...hotLeadConfig,
              email_link_clicked_notification_frequency: option.value as string,
            });
            setHasChanges(true);
          }}
          value={FREQUENCY_OPTIONS.find(
            (option) => option.value === hotLeadConfig.email_link_clicked_notification_frequency,
          )}
        />
      </SelectWrapper>
    </FlexDiv>
  );
});

HotLeadsTab.displayName = "HotLeadsTab";

export default HotLeadsTab;

const SelectWrapper = styled(FlexDiv)`
  flex-direction: column;
  gap: 24px;
  max-width: 376px;
`;
