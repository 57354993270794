import moment from "moment";
import React, { useEffect, useRef } from "react";
import ReactPlayer from "react-player";
import { FlexDiv } from "src/Components/UI";
import { theme } from "src/utils/theme";
import { appToast } from "src/utils/toast";
interface VideoPlayerProps {
  currentWaveformTime: number;
  waveformPlaying: boolean;
  playbackSpeed: number;
  rendered_video_url?: string;
  videoVolume: number;
}

const timeIsOverOneSecondOff = (videoTime: number | undefined, waveformTime: number | undefined) => {
  const videoTimeRounded = Math.round(videoTime || 1);
  const waveformTimeRounded = Math.round(waveformTime || 1);

  return Math.abs(videoTimeRounded - waveformTimeRounded) > 1;
};

const VideoPlayer: React.FC<VideoPlayerProps> = ({
  currentWaveformTime,
  waveformPlaying,
  playbackSpeed = 1,
  rendered_video_url,
  videoVolume,
}) => {
  // the time difference between the audio and video from the start of the conference to the start of the video
  const difference = 0;

  const playerRef = useRef<ReactPlayer>(null);
  const videoIsPlaying = useRef(false);

  useEffect(() => {
    const player = playerRef.current;

    if (!player) return;

    if (videoIsPlaying.current && timeIsOverOneSecondOff(player.getCurrentTime(), currentWaveformTime + difference)) {
      // only try to seek to the video if the video is currently playing and the start time is at least to the difference
      player.seekTo(currentWaveformTime + difference, "seconds");
    }
  }, [currentWaveformTime, difference]);

  useEffect(() => {
    // video is curerntly playing
    const player = playerRef?.current;
    if (!player) return;

    if (waveformPlaying) {
      player.getInternalPlayer()?.play();
    } else {
      player.getInternalPlayer()?.pause();
    }
  }, [waveformPlaying]);

  if (!rendered_video_url) return null;

  return (
    <FlexDiv
      direction="column"
      gap={16}
      width="100%"
      height="100%"
      style={{ padding: "16px", backgroundColor: theme.fill.brand.secondary }}
    >
      <ReactPlayer
        ref={playerRef}
        url={rendered_video_url}
        controls={false}
        style={{
          visibility: currentWaveformTime + difference >= 0 ? "visible" : "hidden",
        }}
        muted={false}
        volume={videoVolume}
        // playing={waveformPlaying}
        playbackRate={playbackSpeed}
        width="100%"
        height="100%"
        onPlay={() => {
          videoIsPlaying.current = true;
        }}
        onPause={() => {
          videoIsPlaying.current = false;
        }}
        onEnded={() => {
          videoIsPlaying.current = false;
        }}
        onError={(e) => {
          appToast("unable to load call recording");
          console.log("error: ", e);
        }}
        config={{
          file: {
            attributes: {
              onContextMenu: (e: Event) => e.preventDefault(),
            },
          },
        }}
      />
    </FlexDiv>
  );
};

export default VideoPlayer;
