import React, { useMemo } from "react";
import { AppText, FlexDiv } from "src/Components/UI";
import { OptionItem } from "src/types";
import { theme } from "src/utils/theme";

interface IPreviewField {
  leadCardFields: OptionItem[];
  index: number;
  fakeLeadData: any;
}

export const PreviewField: React.FC<IPreviewField> = ({ fakeLeadData, leadCardFields, index }) => {
  const field = useMemo(() => leadCardFields[index], [leadCardFields, index]);
  return (
    <FlexDiv
      direction="column"
      padding="4px 0px"
      width={index === leadCardFields.length - 1 && leadCardFields.length % 2 !== 0 ? "100%" : "50%"}
      style={{ borderBottom: `1px solid ${theme.border.neutral.secondary}` }}
      key={`prev-${field.value}`}
    >
      <AppText fontSize={10} lineHeight={14} color={theme.text.neutral.secondary}>
        {field.label || "Select field..."}
      </AppText>
      <AppText fontSize={12} lineHeight={18}>
        {field.label && fakeLeadData[field.value] ? fakeLeadData[field.value] : "‎"}
      </AppText>
    </FlexDiv>
  );
};
