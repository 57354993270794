import { gql, useMutation, useQuery } from "@apollo/client";
import * as Sentry from "@sentry/react";
import * as React from "react";
import { useContext, useState } from "react";
import styled from "styled-components";
import { CallContext, ModalContext } from "../../../context/";
import { appToast } from "../../../utils/toast";
import { AppErrorText, AppText, SkeletonBlock, StatCard } from "../../UI";
import { NewAppButton } from "../../UI/NewAppButton";

import { user_white } from "../../../images/NewDesign";
import { PhoenixAppButton } from "../../UI/Phoenix";
import { AlternateContactDialAs } from "./AlternateContactDialAs";
import { useFlags } from "launchdarkly-react-client-sdk";

import { PhoenixStyledTooltip } from "../../Dumb/PhoenixStyledTooltip";

const FETCH_ALL_LEADS = gql`
  query FetchLeadAllAssociatedGroup($fetchLeadAllAssociatedGroupId: String!) {
    fetchLeadAllAssociatedGroup(id: $fetchLeadAllAssociatedGroupId) {
      id
      associate_parent_id
      first_name
      last_name
      business_name
      primary_phone_number
      primary_phone_number_type
      primary_phone_number_title
      local_primary_phone_number
      primary_phone_number_country_code
      last_lead_activity {
        id
      }
      associate_parent_id
      alternate_contacts {
        id
        title
        channel
        type
        value
      }
      conference_number
      last_lead_activity {
        id
      }
    }
  }
`;

interface ExpectedLeadResponse {
  id?: string;
  first_name?: string;
  last_name?: string;
  full_name?: string;
  primary_phone_number?: string;
  local_primary_phone_number?: string;
  primary_phone_number_country_code?: string;
  primary_phone_number_title?: string;
  primary_phone_number_type?: string;
  conference_number?: string;
  primary_email?: string;
  primary_email_title?: string;
  primary_email_type?: string;
  business_name?: string;
  rep_id?: string;
  channel?: string;
  status?: string;
  title?: string;
  content?: string;
  country?: string;
  current_close_date?: string;
  computed_mrr?: string;
  address?: string;
  zip?: string;
  current_lead_type?: string;
  visible_custom_fields?: VisibleCustomField[];
  alternate_contacts?: AlternateContact[];
  call_notes?: CallNote[];
  favorited?: boolean;
  next_intent_scheduled_or_unscheduled?: NextIntentScheduledOrUnscheduled;
  last_lead_activity?: {
    id?: string;
  };
}

interface VisibleCustomField {
  id?: string;
  key?: string;
  value?: string;
  visible?: boolean;
  boo_value?: boolean;
  num_value?: number;
  date_value?: string;
  type?: string;
  lead_id?: string;
  options?: string[];
  computed_value?: string;
  list_value?: string[];
}

interface AlternateContact {
  id?: string;
  title?: string;
  channel?: string;
  type?: string;
  value?: string;
}

interface CallNote {
  id?: string;
  notes?: string;
  created_at?: string;
  html?: string;
}

interface NextIntentScheduledOrUnscheduled {
  id?: string;
}

const EXIT_DIALER = gql`
  mutation exitDialer($leadActivityId: String!) {
    exitDialer(lead_activity_id: $leadActivityId)
  }
`;

interface AlternateContactsProps {
  lead_id: string;
  intent_id?: string;
  transferDemoOptions: string;
  transferDemoSubOptions: string;
  setTransferDemoSubOptions: (transferDemoSubOptions: string) => void;
  /**
   * True if UI is this component is not called from the dialer
   */
  fromNonCallActivity?: boolean;
}

const AlternateContactComponent = ({
  transferDemoOptions,
  lead_id,
  intent_id,
  transferDemoSubOptions,
  setTransferDemoSubOptions,
  fromNonCallActivity,
}: AlternateContactsProps) => {
  const {
    callLeadId,
    goToCall,
    dialAsNumber,
    intentId,
    hangup,
    persistCallNotes,

    requireNotes,
    performedNoteAction,
    deviceResetCheck,
    resetCallOptionStack,
  } = useContext(CallContext);

  const [primaryLead, setPrimaryLead] = useState<ExpectedLeadResponse | null>(null);
  const [associatedLeads, setAssociatedLeads] = useState<ExpectedLeadResponse[]>([]);
  const [previouslyDialedLead, setPreviouslyDialedLead] = useState<ExpectedLeadResponse | null>(null);

  const { data: allLeadsData, loading: loadingAllLeads, error: errorAllLeads, refetch: refetchAllLeads } = useQuery(
    FETCH_ALL_LEADS,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "no-cache",
      variables: {
        fetchLeadAllAssociatedGroupId: lead_id,
      },
      onCompleted: (data) => {
        setPrimaryLead(
          data.fetchLeadAllAssociatedGroup.find((lead: any) => {
            return lead.associate_parent_id === null;
          }),
        );
        setAssociatedLeads(
          data.fetchLeadAllAssociatedGroup.filter((lead: any) => {
            return lead.associate_parent_id !== null;
          }),
        );

        setPreviouslyDialedLead(
          data.fetchLeadAllAssociatedGroup.find((lead: any) => {
            return lead.id === callLeadId;
          }),
        );
      },
    },
  );

  const [selectedAlternateContactNumber, setSelectedAlternateContactNumber] = useState("");
  const [selectedLeadId, setSelectedLeadId] = useState("");

  const { revertCallState, phoneNumber, suggestedActionData, nextActionOverride } = useContext(CallContext);

  const [exitDialer, { error: errorExitDialer }] = useMutation(EXIT_DIALER, {
    onError({ message }) {
      console.log("Error in exitDialer", message);
    },
    refetchQueries: ["fetchFilteredLeadsInQueueV2"],
  });

  const loading = loadingAllLeads;

  const error = errorAllLeads || errorExitDialer;

  interface AlternateContactButtonProps {
    lead: ExpectedLeadResponse;
    prevously_dialed_number: string;
    contactNumber: string;
    contactTitle: string;
    selectedAlternateContactNumber: string;
    setSelectedAlternateContactNumber: (contactNumber: string) => void;
    setSelectedLeadId: (leadId: string) => void;
  }

  const ContactButton = ({
    lead,
    prevously_dialed_number,
    contactNumber,
    contactTitle,
    selectedAlternateContactNumber,
    setSelectedAlternateContactNumber,
    setSelectedLeadId,
  }: AlternateContactButtonProps) => {
    return (
      <PhoenixAppButton
        buttonType="secondary"
        variant={selectedAlternateContactNumber === contactNumber ? "brand" : "brand-outline"}
        selected={selectedAlternateContactNumber === contactNumber}
        height={96}
        width={400}
        onClick={() => {
          if (contactNumber) setSelectedAlternateContactNumber(contactNumber);
          if (lead.id) setSelectedLeadId(lead.id);
        }}
        noTransition
      >
        <ContactButtonContainer>
          <ContactInfoContainer>
            <div>{contactTitle}</div>
            <div>{contactNumber}</div>
          </ContactInfoContainer>
          {contactNumber === prevously_dialed_number && <JustDialedLabel>Just Dialed</JustDialedLabel>}
        </ContactButtonContainer>
      </PhoenixAppButton>
    );
  };

  if (error) {
    Sentry.captureException(error);
    console.log("Error in AlternateContactComponent", error);
    return (
      <div>
        <AppErrorText>An error occurred while fetching lead data</AppErrorText>
      </div>
    );
  }

  if (loading) {
    return (
      <div style={{ margin: "20px auto" }}>
        <SkeletonBlock height={96} width={300} borderRadius={4} />
      </div>
    );
  }

  return (
    <>
      {transferDemoOptions !== "" && (
        <>
          <HeaderContainer>
            <AppText fontSize={16}>Alternate Contact</AppText>
            <AppText fontSize={14} style={{ margin: "27px auto 16px auto", width: "66%", textAlign: "center" }}>
              Would you like to try and contact another number from this lead?
            </AppText>
            <RefreshDiv>
              <PhoenixAppButton
                buttonType="secondary"
                variant="brand-outline"
                onClick={() => {
                  refetchAllLeads();
                  appToast("Refreshed Lead Info");
                }}
              >
                Refresh Lead Info
              </PhoenixAppButton>
            </RefreshDiv>
          </HeaderContainer>
          <ButtonsDiv>
            <div style={{ display: "flex", flexDirection: "row", gap: 6, marginBottom: 16 }}>
              <StatCard variant="primary" icon={user_white} text="PRIMARY" />
              <AppText fontSize={16}>
                {primaryLead?.full_name ||
                  primaryLead?.first_name ||
                  primaryLead?.last_name ||
                  primaryLead?.business_name ||
                  "Primary Contact"}
              </AppText>
            </div>

            {/* primary lead logic */}
            {!!primaryLead?.primary_phone_number && (
              <ContactButton
                lead={primaryLead}
                prevously_dialed_number={phoneNumber ?? ""}
                contactNumber={primaryLead?.primary_phone_number}
                contactTitle="Primary Phone"
                selectedAlternateContactNumber={selectedAlternateContactNumber}
                setSelectedLeadId={setSelectedLeadId}
                setSelectedAlternateContactNumber={setSelectedAlternateContactNumber}
              />
            )}
            {/* primary lead's alternate contacts logic */}
            {!!primaryLead?.alternate_contacts &&
              primaryLead.alternate_contacts
                .slice()
                .filter((item: any) => item.channel === "Phone" && !!item.value)
                ?.map((item: any, index: number) => (
                  <ContactButton
                    key={item?.id}
                    lead={primaryLead}
                    prevously_dialed_number={phoneNumber ?? ""}
                    contactNumber={item.value}
                    contactTitle={`${item.title} (${item.type})`}
                    selectedAlternateContactNumber={selectedAlternateContactNumber}
                    setSelectedLeadId={setSelectedLeadId}
                    setSelectedAlternateContactNumber={setSelectedAlternateContactNumber}
                  />
                ))}
            {/*primary lead's conference number*/}
            {!!primaryLead?.conference_number && (
              <ContactButton
                lead={primaryLead}
                prevously_dialed_number={phoneNumber ?? ""}
                contactNumber={primaryLead?.conference_number}
                contactTitle="Screen Sharing Phone Number"
                selectedAlternateContactNumber={selectedAlternateContactNumber}
                setSelectedLeadId={setSelectedLeadId}
                setSelectedAlternateContactNumber={setSelectedAlternateContactNumber}
              />
            )}
          </ButtonsDiv>
        </>
      )}

      {/*associate contacts logic*/}

      {!!associatedLeads?.length && (
        <ButtonsDiv>
          {associatedLeads.slice()?.map((lead: ExpectedLeadResponse, index: number) => (
            // start of associate contact logic
            <>
              <div style={{ display: "flex", flexDirection: "row", gap: 6, marginBottom: 16, marginTop: 16 }}>
                <StatCard variant="secondary" icon={user_white} text="ASSOCIATE" />
                <AppText fontSize={16}>
                  {lead.full_name || lead.first_name || lead.last_name || lead.business_name || "Associate Contact"}
                </AppText>
              </div>
              {/* associate contact's primary number */}
              {lead?.primary_phone_number && (
                <ContactButton
                  key={lead?.id}
                  lead={lead}
                  prevously_dialed_number={phoneNumber ?? ""}
                  contactNumber={lead?.primary_phone_number}
                  contactTitle="Primary Phone"
                  selectedAlternateContactNumber={selectedAlternateContactNumber}
                  setSelectedLeadId={setSelectedLeadId}
                  setSelectedAlternateContactNumber={setSelectedAlternateContactNumber}
                />
              )}
              {/* associate contact's alternate contacts */}
              {!!lead?.alternate_contacts &&
                lead.alternate_contacts
                  .slice()
                  .filter((item: any) => item.channel === "Phone" && !!item.value)
                  ?.map((item: any, index: number) => (
                    <ContactButton
                      key={item?.id}
                      lead={lead}
                      prevously_dialed_number={phoneNumber ?? ""}
                      contactNumber={item.value}
                      contactTitle={`${item.title} (${item.type})`}
                      selectedAlternateContactNumber={selectedAlternateContactNumber}
                      setSelectedLeadId={setSelectedLeadId}
                      setSelectedAlternateContactNumber={setSelectedAlternateContactNumber}
                    />
                  ))}
              {!!lead?.conference_number && (
                <ContactButton
                  lead={lead}
                  prevously_dialed_number={phoneNumber ?? ""}
                  contactNumber={lead?.conference_number}
                  contactTitle="Screen Sharing Phone Number"
                  selectedAlternateContactNumber={selectedAlternateContactNumber}
                  setSelectedLeadId={setSelectedLeadId}
                  setSelectedAlternateContactNumber={setSelectedAlternateContactNumber}
                />
              )}
            </>
          ))}
        </ButtonsDiv>
      )}
      <AlternateContactDialAs leadId={lead_id} leadPhoneNumber={selectedAlternateContactNumber} />
      {transferDemoOptions !== "" && (
        <ConfirmDiv>
          {requireNotes && !performedNoteAction && <PhoenixStyledTooltip id="missing-required-notes" />}

          <NewAppButton
            variant={"primary"}
            size={"lg"}
            disabled={!selectedAlternateContactNumber || deviceResetCheck}
            onClick={() => {
              hangup();
              goToCall({
                lead_id: selectedLeadId,
                phoneNumber: selectedAlternateContactNumber,
                intentId,
                dialAsNumber,
              });
              persistCallNotes();
            }}
          >
            Dial Alternate Contact
          </NewAppButton>
          <NewAppButton
            variant={"secondary"}
            size={"lg"}
            onClick={() => {
              hangup();
              resetCallOptionStack();
              revertCallState();

              // ensures lead is considered for sequence entry on exit
              if (previouslyDialedLead?.last_lead_activity?.id) {
                exitDialer({
                  variables: {
                    leadActivityId: previouslyDialedLead?.last_lead_activity?.id,
                  },
                });
              }
            }}
            disabled={requireNotes && !performedNoteAction}
            data-for={requireNotes && !performedNoteAction ? "missing-required-notes" : ""}
            data-tip={"Please add a note before proceeding."}
          >
            Exit Dialer
          </NewAppButton>
        </ConfirmDiv>
      )}
    </>
  );
};

const ButtonsDiv = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const ConfirmDiv = styled.div`
  width: 100%;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  margin-top: 16px;
`;

const HeaderContainer = styled.div`
  width: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: auto;
  margin-bottom: 15px;
  margin-top: 40px;
`;

const RefreshDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 16px;
  margin-bottom: 48px;
`;

const ContactButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  justify-content: space-between;
`;

const ContactInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 6px;
`;

const JustDialedLabel = styled.div`
  align-self: center;
  display: inline-flex;
  justify-content: flex-end;
  flex-grow: 1;
`;
export { AlternateContactComponent };
