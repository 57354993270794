import React, { useContext, useEffect, useMemo, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import styled from "styled-components";
import { theme } from "src/utils/theme";
import { chevron_left, edit } from "src/images/NewDesign";
import { AppText, SkeletonBlock } from "src/Components/UI";
import { FlexDiv } from "src/Components/UI/FlexDiv";
import { PhoenixIcon } from "src/Components/UI/Phoenix";
import { formatDate } from "src/utils/date";
import { ModalContext } from "src/context";
import useFetchLabel from "src/query-hooks/useFetchLabel";
import AssignedRules from "../Segments/LabelDetails/AssignedRules";
import AssignedReps from "../Segments/LabelDetails/AssignedReps";
import { AddOrEditLabelModal } from "../modal/AddOrEditLabelModal";

interface ParamTypes {
  tab_type_id: string;
}

export const LabelDetails = () => {
  const history = useHistory();
  const location = useLocation();
  const { tab_type_id } = useParams<ParamTypes>();
  const [selectedTab, setSelectedTab] = useState("assigned-reps");
  const { showAddOrEditLabelModal, setShowAddOrEditLabelModal } = useContext(ModalContext);

  const { data, loading, error } = useFetchLabel(tab_type_id);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);

    const tab = queryParams.get("tab");
    if (tab === "assigned-reps" || tab === "assigned-rules") {
      setSelectedTab(tab);
    }
  }, [location.search]);

  const handleEditLabel = () => {
    setShowAddOrEditLabelModal(true);
  };

  const renderTabs = useMemo(() => {
    if (selectedTab === "assigned-rules") {
      return <AssignedRules id={tab_type_id} rules={data?.rules} />;
    }

    return <AssignedReps id={tab_type_id} reps={data?.reps} />;
  }, [selectedTab, tab_type_id, data?.reps, data?.rules]);

  if (loading) {
    return (
      <Main>
        <Body>
          <LoadingComponent />
        </Body>
      </Main>
    );
  }

  return (
    <Main>
      {showAddOrEditLabelModal && <AddOrEditLabelModal selectedLabel={data} />}

      <TopOptions>
        <FlexDiv
          gap={4}
          align="center"
          style={{ cursor: "pointer", marginBottom: "8px" }}
          onClick={() => history.replace("/system-config/manage-labels")}
        >
          <PhoenixIcon svg={chevron_left} size={16} pointer />
          <AppText
            fontSize={10}
            fontWeight={600}
            lineHeight={16}
            uppercase
            letterSpacing={1}
            color={theme.buttontext.brand_outline.default}
          >
            Back
          </AppText>
        </FlexDiv>

        <FlexDiv justify="space-between" style={{ marginBottom: 24 }}>
          <FlexDiv direction="column">
            <FlexDiv align="center" gap={4}>
              <AppText fontSize={22} fontWeight={500}>
                {data?.name}
              </AppText>

              <PhoenixIcon svg={edit} size={16} pointer onClick={handleEditLabel} />
            </FlexDiv>

            <AppText fontSize={12} color={theme.text.neutral.secondary}>
              {data?.description}
            </AppText>
          </FlexDiv>

          <FlexDiv direction="column" gap={2} justify="flex-end">
            <AppText fontSize={12} color={theme.text.neutral.secondary}>
              Created By: {formatDate(data?.created_at)} by {data?.created_by?.full_name}
            </AppText>

            <AppText fontSize={12} color={theme.text.neutral.secondary}>
              Last Updated: {formatDate(data?.updated_at)} by {data?.last_updated_by?.full_name}
            </AppText>
          </FlexDiv>
        </FlexDiv>

        <Tabs>
          <Tab
            selected={selectedTab === "assigned-reps"}
            onClick={() => {
              history.push(`/system-config/manage-labels/${tab_type_id}?tab=assigned-reps`);
            }}
          >
            Assigned Reps
          </Tab>
          <Tab
            selected={selectedTab === "assigned-rules"}
            onClick={() => {
              history.push(`/system-config/manage-labels/${tab_type_id}?tab=assigned-rules`);
            }}
          >
            Assigned Rules
          </Tab>
        </Tabs>
      </TopOptions>

      <Body>{renderTabs}</Body>
    </Main>
  );
};

export default LabelDetails;

const LoadingComponent = () => {
  return (
    <FlexDiv align="center" direction="column" gap={8}>
      {[...Array(6)].map((_, i) => (
        <SkeletonBlock
          key={i}
          opacity={1 - i * 0.16}
          delayNumber={i + 1}
          height={73}
          width="calc(100% - 32px)"
          borderRadius={8}
        />
      ))}
    </FlexDiv>
  );
};

const Main = styled.div`
  border: 1px solid ${theme.NEUTRAL100};
  background: ${theme.WHITE_COLOR};
  margin: 24px;
  margin-left: 24px;
  border-radius: 8px;
  overflow: hidden;
  transition: margin-left 0.22s ease-in-out;
  min-width: 1200px;
`;

const TopOptions = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px 40px 0px;
`;

const Tabs = styled.div`
  display: flex;
  align-items: flex-start;
  align-self: stretch;
`;
interface SelectedProps {
  selected?: boolean;
}

const Tab = styled(AppText)<SelectedProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  font-weight: 600;
  padding-left: 12px;
  padding-right: 12px;
  cursor: pointer;
  border-bottom: solid 2px ${(props) => (props.selected ? "#3E65FF" : "transparent")};
  padding-bottom: 6px;
  margin-top: 2px;
  color: ${(props) => (props.selected ? theme.BLACK_COLOR : theme.NEUTRAL300)};
  text-transform: uppercase;
`;

const Body = styled.div`
  background-color: ${theme.WHITE_COLOR};
  border-top: 1px solid ${theme.NEUTRAL200};
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: 0px;
  height: 80vh;
  overflow-y: auto;
  padding: 40px;
  width: 100%;
`;
