import styled from "styled-components";

import Switch from "react-switch";
import * as React from "react";
import { useState, useMemo, useEffect, useContext } from "react";
import { AppText, AppErrorText, Loading, AppMultiSelect } from "../../UI";
import { theme } from "../../../utils/theme";
import { iconDateSeekerNew, iconCalendarNew } from "../../../images/";
import "../../../utils/react-big-calendar.css";
import moment from "moment";
import { FormSelectField } from "../../Field";
import { useFormikContext } from "formik";
import { AiOutlineLeft } from "react-icons/ai";
import { gql, useQuery } from "@apollo/client";
import { timezone_list_items } from "../../../static/";
import { NewAppButton } from "../../UI/NewAppButton";

import { LOGGED_IN_USER } from "../../../apollo/query";

import { ReactDatesWrapper } from "../../UI/ReactDatesWrapper";
import { SingleDatePicker } from "react-dates";

import { CallContext } from "../../../context";

import { useFlags } from "launchdarkly-react-client-sdk";
import { SkeletonBlock } from "../../UI/SkeletonBlock";
import * as Sentry from "@sentry/react";

interface AvailableTimeItem {
  user_id: string;
  user_name: string;
  start_time: string;
  event_count: number;
  rep_ids: string[];
  rep_id: string;
}

interface DisappearingDivProps {
  user_id?: string;
  lead_id?: string;
  values: any;
  setFieldValue: any;
  errors?: any;
  selectedDataTime: any;
  dateValue: any;
  setDateValue: any;
  yearMonth: any;
  setYearMonth: any;
  specificOptionStep: any;
  setSpecificOptionStep: any;
  toggleCalendar: any;
  closeCalendar: any;
  isInCallRoute?: boolean;
  callOptions?: string;
  disableSelectTime?: boolean;
  disableGeneralTime?: boolean;
  events?: any;
  setAvailableRepsForBooking?: (reps: any) => void;
  setOpsiqSuggestedRep: (rep: any) => void;
}

const AVAILABLE_SPECIFIC_TIMES_V2 = gql`
  query fetchAvailableTimes($day: DateTime, $timezone: String, $action: String, $leadId: String) {
    fetchAvailableTimesV3(day: $day, timezone: $timezone, action: $action, lead_id: $leadId)
  }
`;

const MONTHLY_SCHEDULE = gql`
  query fetchScheduleItemsMonth($yearmonth: String) {
    fetchScheduleItemsMonth(yearmonth: $yearmonth)
  }
`;

const generalTimesDropdownOptions = new Array(24)
  .fill(moment("7:00 AM", "h:mm A"))
  ?.map((t, i) => moment(t).add(i * 30, "minutes"))
  ?.map((item) => ({ label: item.format("h:mm A"), value: `${item.toDate()}` }));

interface GeneralTimesComponentProps {
  isInCallRoute?: boolean;
  setFieldValue?: any;
  errors?: any;
}

const TimeZoneSelect: React.FC<GeneralTimesComponentProps> = ({ isInCallRoute }) => {
  if (isInCallRoute) {
    return (
      <div>
        <TitleLabel>
          Select Timezone<span style={{ color: theme.ATTENTION700 }}>*</span>
        </TitleLabel>
        <FieldSelect2
          name="timezone"
          placeholder="Select Time Zone"
          allowSelectPlaceholder
          options={timezone_list_items}
        />
      </div>
    );
  }
  return <></>;
};

const GeneralTimeDropdowns: React.FC<GeneralTimesComponentProps> = ({ isInCallRoute, setFieldValue }) => {
  const { values, errors, touched, handleChange, setFieldTouched } = useFormikContext();
  console.log("errors: ", errors);
  console.log("touched: ", touched);
  console.log("values: ", values);
  return (
    <>
      <TimeZoneSelect isInCallRoute={isInCallRoute} />
      <TitleLabel>
        Anytime After<span style={{ color: theme.ATTENTION700 }}>*</span>
      </TitleLabel>
      <FieldSelect
        name="anytime_after"
        options={[{ label: "Select after time", value: "" }, ...generalTimesDropdownOptions]}
      />
      <TitleLabel>
        Anytime Before<span style={{ color: theme.ATTENTION700 }}>*</span>
      </TitleLabel>
      <FieldSelect
        name="anytime_before"
        options={[
          { label: "Select before time", value: "" },
          ...generalTimesDropdownOptions?.map((i) => ({
            label: moment(i.label, "h:mm A").add(30, "minutes").format("h:mm A"),
            value: `${moment(i.value).add(30, "minutes")}`,
          })),
        ]}
        onChange={(e) => {
          handleChange(e);
          setFieldTouched("anytime_before", true, true);
        }}
      />
    </>
  );
};

const ScheduleTimeSelectComponentV3: React.FC<DisappearingDivProps> = ({
  user_id,
  lead_id,
  values,
  errors,
  setFieldValue,
  selectedDataTime,
  dateValue,
  setDateValue,
  yearMonth,
  setYearMonth,
  specificOptionStep,
  setSpecificOptionStep,
  toggleCalendar,
  isInCallRoute,
  callOptions,
  disableGeneralTime = true,
  closeCalendar,
  events,
  setAvailableRepsForBooking,
  setOpsiqSuggestedRep,
  ...props
}) => {
  const [generalOption, setGeneralOption] = useState("");
  const [finalDay, setFinalDay] = useState("");
  const [generalOptionStep, setGeneralOptionStep] = useState(0);
  const [optionsSpecificTime, setOptionsSpecificTime] = useState(new Array());

  const { callLeadId } = useContext(CallContext);

  const [showCustomTime, setShowCustomTime] = useState(false);
  const [focused, setFocused] = useState(false);
  const { data, loading: loadingTimes, error: errorTimes, refetch: refetchTimes } = useQuery(
    AVAILABLE_SPECIFIC_TIMES_V2,
    {
      variables: {
        day: dateValue,
        timezone: values.timezone,
        action: callOptions,
        callback: ["Schedule Callback", "Scheduled Callback", "Schedule Call Back"].includes(callOptions || ""),
        leadId: callLeadId || lead_id,
      },
      fetchPolicy: "no-cache",
      onError({ message, name }) {
        // Sentry.captureEvent({
        //   message: `${name} GraphQL Error: ${message}`,
        // });
        console.log(`Error in ${name}: `, message);
      },
    },
  );

  const { data: dataUser, loading: loadingUser, error: dataError } = useQuery(LOGGED_IN_USER);

  const { data: dataCal, loading: loadingCal, error: errorCal, refetch: refetchCal } = useQuery(MONTHLY_SCHEDULE, {
    variables: { yearmonth: yearMonth },
    fetchPolicy: "no-cache",
    onError({ message, name }) {
      // Sentry.captureEvent({
      //   message: `${name} GraphQL Error: ${message}`,
      // });
      console.log(`Error in ${name}: `, message);
    },
  });

  const { data: dataLastMonth, loading: loadingLastMonth, error: errorLastMonth, refetch: refetchLastMonth } = useQuery(
    MONTHLY_SCHEDULE,
    {
      variables: {
        yearmonth: moment(!!yearMonth ? yearMonth : moment().format("YYYY-MM"), "YYYY-MM")
          .subtract(1, "month")
          .format("YYYY-MM"),
      },
      fetchPolicy: "no-cache",
      onError({ message, name }) {
        // Sentry.captureEvent({
        //   message: `${name} GraphQL Error: ${message}`,
        // });
        console.log(`Error in ${name}: `, message);
      },
    },
  );

  const { data: dataNextMonth, loading: loadingNextMonth, error: errorNextMonth, refetch: refetchNextMonth } = useQuery(
    MONTHLY_SCHEDULE,
    {
      variables: {
        yearmonth: moment(!!yearMonth ? yearMonth : moment().format("YYYY-MM"), "YYYY-MM")
          .add(1, "month")
          .format("YYYY-MM"),
      },
      fetchPolicy: "no-cache",
      onError({ message, name }) {
        // Sentry.captureEvent({
        //   message: `${name} GraphQL Error: ${message}`,
        // });
        console.log(`Error in ${name}: `, message);
      },
    },
  );

  const [selectedHour, setSelectedHour] = useState(0);
  const [selectedMinute, setSelectedMinute] = useState(0);

  useEffect(() => {
    // the custom time options need to be updated when the date changes
    setOptionsSpecificTime(
      new Array(96)
        .fill(null)
        ?.map((_, i) =>
          moment(dateValue)
            .startOf("day")
            .add(i * 15, "minutes")
            .format("h:mm A"),
        )
        ?.map((item: any) => ({ label: item, value: item })),
    );
  }, [dateValue]);

  useEffect(() => {
    if (!!values.specific_time) {
      setFieldValue(
        "specific_time",
        moment(dateValue)
          .tz(values.timezone, true)
          .set({
            hour: selectedHour,
            minute: selectedMinute,
          })
          .format(),
      );
    }
  }, [dateValue, values.timezone]);

  useEffect(() => {
    if (values.time === "General Time" && generalOption === "day") {
      const pos = dummyMonthsWithDays
        .slice()
        ?.map((item: any) => item.month)
        .indexOf(`${moment(dateValue, "YYYY-MM-DD").format("MMM YYYY")}`);
      setGeneralOptionStep(pos >= 0 ? pos : 0);
    }
    if (values.time === "General Time" && generalOption === "week") {
      const pos = dummyMonthsWithWeeks
        .slice()
        ?.map((item: any) => item.month)
        .indexOf(`${moment(dateValue, "YYYY-MM-DD").format("MMM YYYY")}`);
      setGeneralOptionStep(pos >= 0 ? pos : 0);
    }
    if (values.time === "General Time" && generalOption === "month") {
      const pos = dummyYears
        .slice()
        ?.map((item: any) => item.year)
        .indexOf(`${moment(dateValue, "YYYY-MM-DD").format("YYYY")}`);
      setGeneralOptionStep(pos >= 0 ? pos : 0);
    }
  }, [dateValue]);

  const optionsTime = [
    {
      label: "Specific Time",
      value: "Specific Time",
    },
    {
      label: "General Time",
      value: "General Time",
    },
  ];

  // GENERAL TIMES: Helper function that generates the months in a given year. Input being number of years from today.
  function CreateMonthsInYearText(num: number) {
    const currentYearMonths = new Array(12)
      .fill(null)
      ?.map((_, i) => moment().startOf("year").add(num, "years").add(i, "months"))
      .filter((item) => item.isAfter(moment().subtract(1, "months")))
      ?.map((item) => `${item.format("MMM")} ${moment().year() + num}`);

    return currentYearMonths;
  }

  // GENERAL TIMES: Helper data. A list of months left in this years plus months in the next two years.
  const monthsInNext3Years = [...CreateMonthsInYearText(0), ...CreateMonthsInYearText(1), ...CreateMonthsInYearText(2)];

  // Making months with days as options

  // Function that returns the weekdays in a given month, with an input of number of months from today
  function CreateMonthsWithDays(num: number) {
    const currentMonthDates = new Array(moment().add(num, "months").daysInMonth())
      .fill(null)
      ?.map((_, i) => moment().startOf("month").add(num, "months").add(i, "days"))
      .filter(
        (item) => item.isAfter(moment().subtract(1, "days")) && item.isoWeekday() !== 6 && item.isoWeekday() !== 7,
      );
    return currentMonthDates;
  }

  // Loops over the helper array of months in the next 3 years. For each month the weekdays are found using the above function.
  // Returns weekdays for each month in the next 3 years.
  const dummyMonthsWithDays = monthsInNext3Years?.map((item, index) => ({
    month: item,
    days: CreateMonthsWithDays(index),
  }));

  // GENERAL TIMES: Create months in next 3 years with weeks as options

  // Creates a range of weeks for each month, where the input is the number of months starting at the current month
  function CreateMonthWithWeek(num: number) {
    const currentMonthWeeks = new Array(5)
      .fill(null)
      ?.map((_, i) => moment().add(num, "months").startOf("month").weekday(1).add(i, "weeks"))
      .filter((item) => item.isAfter(moment().subtract(1, "weeks")))
      .filter((item) =>
        item.isBefore(
          moment()
            .startOf("day")
            .add(num + 1, "months")
            .startOf("month"),
        ),
      );

    const dummyWeeks = new Array(currentMonthWeeks.length).fill(null)?.map((_, index) => ({
      label: `${currentMonthWeeks[index].calendar(null, {
        sameDay: "MMM DD",
        nextDay: "MMM DD",
        nextWeek: "MMM DD",
        lastDay: "MMM DD",
        lastWeek: "MMM DD",
        sameElse: "MMM DD",
      })} - ${currentMonthWeeks[index].add(6, "days").calendar(null, {
        sameDay: "MMM DD",
        nextDay: "MMM DD",
        nextWeek: "MMM DD",
        lastDay: "MMM DD",
        lastWeek: "MMM DD",
        sameElse: "MMM DD",
      })}`,
      value: moment(currentMonthWeeks[index]).format(),
    }));

    return dummyWeeks;
  }

  // Maps over the helper list of months in the next three years to generate the weeks in the next three years, using the above function
  const dummyMonthsWithWeeks = monthsInNext3Years?.map((item, index) => ({
    month: item,
    weeks: CreateMonthWithWeek(index),
  }));

  // GENERAL TIMES: Dummy years with months as options

  // This function creates an array of the months in a year (also checks that it is not in the past)
  function CreateMonthsOfYear(num: number) {
    return new Array(12)
      .fill(null)
      ?.map((_, i) => moment().startOf("year").add(num, "years").add(i, "months"))
      .filter((item) => item.isAfter(moment().subtract(1, "months")))
      ?.map((item) => ({ label: item.format("MMMM"), value: item.format() }));
  }

  // Runs above function for this year and the coming two years
  const dummyYears = [
    { year: `${moment().year()}`, months: CreateMonthsOfYear(0) },
    { year: `${moment().year() + 1}`, months: CreateMonthsOfYear(1) },
    { year: `${moment().year() + 2}`, months: CreateMonthsOfYear(2) },
  ];

  return (
    <FieldsWrapper>
      <HeaderContainer>
        <AppText fontSize={16}>{callOptions}</AppText>
      </HeaderContainer>
      {!disableGeneralTime && (
        <>
          <TitleLabel>
            {isInCallRoute && callOptions === "schedule demo"
              ? "Schedule Demo"
              : isInCallRoute && callOptions === "schedule call back"
              ? "Schedule Call Back"
              : "Select Time"}
            <span style={{ color: theme.ATTENTION700 }}>*</span>
          </TitleLabel>
          <FieldSelect id="option_selector" name="time" options={optionsTime} disabled={props.disableSelectTime} />
        </>
      )}
      {dataCal && dataCal.fetchScheduleItemsMonth && values.time === "Specific Time" ? (
        <div id="time-select-container">
          {loadingCal ? (
            <Loading />
          ) : (
            <>
              <TitleLabel>
                Select a Date<span style={{ color: theme.ATTENTION700 }}>*</span>
              </TitleLabel>
              <ReactDatesWrapper>
                <SingleDatePicker
                  id="make-sale-date-picker"
                  required
                  date={moment(dateValue)}
                  onDateChange={(date) => {
                    // Before Hotfix
                    // setDateValue(date);

                    // SEL-10151 (Hotfix)
                    const newDateValue = moment(date).format("YYYY-MM-DD");

                    if (moment(newDateValue)?.isValid()) {
                      setDateValue(date);
                    } else {
                      console.log("newDateValue is not valid: ", newDateValue);
                      Sentry.captureEvent({
                        message: "calendar date is not valid",
                        extra: {
                          component: "ScheduleTimeSelectV3",
                          newDateValue: newDateValue,
                          dateValue: dateValue,
                          values: values,
                          dataCal: dataCal,
                          loadingCal: loadingCal,
                          focused: focused,
                          events: events,
                          generalOption: generalOption,
                          generalOptionStep: generalOptionStep,
                          specificOptionStep: specificOptionStep,
                          yearMonth: yearMonth,
                        },
                      });
                    }
                  }}
                  focused={focused}
                  onFocusChange={({ focused }) => setFocused(focused)}
                  numberOfMonths={1}
                  isDayHighlighted={(date) =>
                    events.filter((item: any) => {
                      return moment(date).day() === moment(item.start).day();
                    })
                  }
                  showDefaultInputIcon
                  inputIconPosition={"after"}
                  customInputIcon={() => <img src={iconCalendarNew} alt="Calendar icon" />}
                />
              </ReactDatesWrapper>
            </>
          )}

          <TimeZoneSelect isInCallRoute={isInCallRoute} />
          <>
            <div>
              <TitleLabel>
                Select Time<span style={{ color: theme.ATTENTION700 }}>*</span>
              </TitleLabel>
            </div>
            {loadingTimes ? (
              <SkeletonBlock width="100%" height={250} borderRadius={4} />
            ) : errorTimes ? (
              <AppErrorText>{errorTimes.message}</AppErrorText>
            ) : data?.fetchAvailableTimesV3?.length === 0 ||
              // the last item is before the current time
              moment(data?.fetchAvailableTimesV3[data?.fetchAvailableTimesV3?.length - 1]?.start_time).isBefore(
                moment(),
              ) ? (
              <ScrollSegment>
                <AppText fontSize={12} style={{ textAlign: "center" }}>
                  No available times
                </AppText>
              </ScrollSegment>
            ) : (
              <ScrollSegment>
                {data?.fetchAvailableTimesV3
                  ?.slice()
                  ?.filter((item: any) => {
                    if (moment(item.start_time).isBefore(moment())) {
                      return false;
                    } else {
                      return true;
                    }
                  })
                  ?.map(
                    (item: AvailableTimeItem, index: any) =>
                      (
                        <NewAppButton
                          variant={"secondary"}
                          key={`${item.user_id}${item.start_time}`}
                          selected={!!values.specific_time && values.specific_time === item.start_time}
                          onClick={async () => {
                            await setFieldValue(
                              "specific_time",
                              values.specific_time === item.start_time ? "" : item.start_time,
                            );
                            console.log("Selected time is:", moment(item.start_time).toDate());
                            if (item.rep_ids) setAvailableRepsForBooking && setAvailableRepsForBooking(item.rep_ids);
                            if (item.user_id) setOpsiqSuggestedRep && setOpsiqSuggestedRep(item.user_id);
                          }}
                        >
                          {values.timezone
                            ? moment(item.start_time).tz(values.timezone).format("h:mm A")
                            : moment(item.start_time).format("h:mm A")}
                          {!!item.event_count && <span style={{ opacity: 0.6 }}>{` (${item.event_count})`}</span>}
                        </NewAppButton>
                      ) || (
                        <AppText fontSize={12} style={{ textAlign: "center" }}>
                          Something went wrong fetching available times
                        </AppText>
                      ),
                  )}
              </ScrollSegment>
            )}
          </>
        </div>
      ) : (
        values.time === "Specific Time" && <Loading />
      )}
      {values.time === "General Time" && generalOption === "" && (
        <div>
          <HorizontalDiv>
            <TitleLabel>
              General Time<span style={{ color: theme.ATTENTION700 }}>*</span>
            </TitleLabel>
          </HorizontalDiv>
          <SelectionButton
            style={{ marginBottom: "12px" }}
            button="day"
            id="day-button"
            option={generalOption}
            onClick={() => setGeneralOption("day")}
          >
            Day
          </SelectionButton>
          <SelectionButton
            style={{ marginBottom: "12px" }}
            button="week"
            id="week-button"
            option={generalOption}
            onClick={() => setGeneralOption("week")}
          >
            Week
          </SelectionButton>
          <SelectionButton
            button="month"
            id="month-button"
            option={generalOption}
            onClick={() => setGeneralOption("month")}
          >
            Month
          </SelectionButton>
        </div>
      )}
      {values.time === "General Time" && generalOption === "day" && (
        <div>
          <HorizontalDiv style={{ marginBottom: "16px" }}>
            <BackArrow>
              <AiOutlineLeft
                style={{
                  margin: "2px",
                }}
                size={20}
                color={theme.WHITE_COLOR}
                onClick={() => {
                  setGeneralOption("");
                  setFieldValue("specific_time", "");
                  setFieldValue("day", "");
                  setFieldValue("anytime_before", "");
                  setFieldValue("anytime_after", "");
                  setFinalDay("");
                  setGeneralOptionStep(0);
                }}
              />
            </BackArrow>
            {finalDay === "" ? (
              <VerticalAlignDiv>
                {generalOptionStep <= 0 ? (
                  <EmptyDiv small />
                ) : (
                  <InvisibleButton onClick={() => setGeneralOptionStep(generalOptionStep - 1)}>
                    <img src={iconDateSeekerNew} alt="Move to previous date" style={{ transform: "rotate(180deg)" }} />
                  </InvisibleButton>
                )}
                <TimeSeekingText>{dummyMonthsWithDays[generalOptionStep].month}</TimeSeekingText>
                {generalOptionStep >= dummyMonthsWithDays.length - 1 ? (
                  <EmptyDiv small />
                ) : (
                  <InvisibleButton onClick={() => setGeneralOptionStep(generalOptionStep + 1)}>
                    <img
                      src={iconDateSeekerNew}
                      // style={{ cursor: "pointer" }}
                      alt="Move to future date"
                    />
                  </InvisibleButton>
                )}
              </VerticalAlignDiv>
            ) : (
              <VerticalAlignDiv>
                <TimeSeekingText>{finalDay}</TimeSeekingText>
              </VerticalAlignDiv>
            )}
            <VerticalAlignDiv>
              {/*        <InvisibleButton marginLeft onClick={toggleCalendar}>
                <img src={iconCalendarNew} alt="Calendar icon" />
              </InvisibleButton> */}
            </VerticalAlignDiv>
          </HorizontalDiv>
          {finalDay === "" ? (
            <ScrollSegment>
              {dummyMonthsWithDays[generalOptionStep].days?.map((item, index) => (
                <SelectionButton
                  key={index}
                  button={moment(item).format()}
                  option={finalDay}
                  onClick={() => {
                    setFinalDay(
                      item.calendar(null, {
                        sameDay: "MM/DD/YYYY ([Today])",
                        nextDay: "MM/DD/YYYY ([Tomorrow])",
                        nextWeek: "MM/DD/YYYY",
                        sameElse: "MM/DD/YYYY",
                      }),
                    );
                    setFieldValue("day", values.day === moment(item).format() ? "" : moment(item).format());
                  }}
                >
                  {item.calendar(null, {
                    sameDay: "MM/DD/YYYY ([Today])",
                    nextDay: "MM/DD/YYYY ([Tomorrow])",
                    nextWeek: "MM/DD/YYYY",
                    sameElse: "MM/DD/YYYY",
                  })}
                </SelectionButton>
              ))}
            </ScrollSegment>
          ) : (
            <GeneralTimeDropdowns isInCallRoute={isInCallRoute} setFieldValue={setFieldValue} errors={errors} />
          )}
        </div>
      )}
      {values.time === "General Time" && generalOption === "week" && (
        <div>
          <HorizontalDiv style={{ marginBottom: "16px" }}>
            <BackArrow>
              <AiOutlineLeft
                style={{
                  margin: "2px",
                }}
                size={20}
                color={theme.WHITE_COLOR}
                onClick={() => {
                  setGeneralOption("");
                  setFieldValue("specific_time", "");
                  setFieldValue("week_start", "");
                  setFieldValue("week_end", "");
                  setFieldValue("anytime_before", "");
                  setFieldValue("anytime_after", "");
                  setFinalDay("");
                  setGeneralOptionStep(0);
                }}
              />
            </BackArrow>

            {finalDay === "" ? (
              <VerticalAlignDiv>
                {generalOptionStep <= 0 ? (
                  <EmptyDiv small />
                ) : (
                  <InvisibleButton onClick={() => setGeneralOptionStep(generalOptionStep - 1)}>
                    <img src={iconDateSeekerNew} alt="Move to previous date" style={{ transform: "rotate(180deg)" }} />
                  </InvisibleButton>
                )}
                <TimeSeekingText>{dummyMonthsWithWeeks[generalOptionStep].month}</TimeSeekingText>
                {generalOptionStep >= dummyMonthsWithWeeks.length - 1 ? (
                  <EmptyDiv small />
                ) : (
                  <InvisibleButton onClick={() => setGeneralOptionStep(generalOptionStep + 1)}>
                    <img src={iconDateSeekerNew} style={{ cursor: "pointer" }} alt="Move to future date" />
                  </InvisibleButton>
                )}
              </VerticalAlignDiv>
            ) : (
              <VerticalAlignDiv style={{ minWidth: "120px" }}>
                <TimeSeekingText>{finalDay}</TimeSeekingText>
              </VerticalAlignDiv>
            )}

            <VerticalAlignDiv>
              <InvisibleButton marginLeft onClick={toggleCalendar}>
                <img src={iconCalendarNew} alt="Calendar icon" />
              </InvisibleButton>
            </VerticalAlignDiv>
          </HorizontalDiv>
          {finalDay === "" ? (
            <ScrollSegment>
              {dummyMonthsWithWeeks[generalOptionStep].weeks?.map((item, index) => (
                <SelectionButton
                  key={index}
                  button={item.label}
                  option={finalDay}
                  onClick={() => {
                    setFinalDay(item.label);
                    setFieldValue(
                      "week_start",
                      values.week_start === moment(item.value).subtract(6, "days").format()
                        ? ""
                        : moment(item.value).subtract(6, "days").format(),
                    );
                    setFieldValue("week_end", values.week_end === item.value ? "" : item.value);
                  }}
                >
                  {item.label}
                </SelectionButton>
              ))}
            </ScrollSegment>
          ) : (
            <GeneralTimeDropdowns isInCallRoute={isInCallRoute} />
          )}
        </div>
      )}
      {values.time === "General Time" && generalOption === "month" && (
        <div>
          <HorizontalDiv style={{ marginBottom: "16px" }}>
            <BackArrow>
              <AiOutlineLeft
                style={{
                  margin: "2px",
                }}
                size={20}
                color={theme.WHITE_COLOR}
                onClick={() => {
                  setGeneralOption("");
                  setFieldValue("specific_time", "");
                  setFieldValue("month", "");
                  setFieldValue("anytime_before", "");
                  setFieldValue("anytime_after", "");
                  setFinalDay("");
                  setGeneralOptionStep(0);
                }}
              />
            </BackArrow>
            {finalDay === "" ? (
              <VerticalAlignDiv>
                {generalOptionStep <= 0 ? (
                  <EmptyDiv small />
                ) : (
                  <InvisibleButton onClick={() => setGeneralOptionStep(generalOptionStep - 1)}>
                    <img src={iconDateSeekerNew} alt="Move to previous date" style={{ transform: "rotate(180deg)" }} />
                  </InvisibleButton>
                )}
                <TimeSeekingText>{dummyYears[generalOptionStep].year}</TimeSeekingText>
                {generalOptionStep >= dummyYears.length - 1 ? (
                  <EmptyDiv small />
                ) : (
                  <InvisibleButton onClick={() => setGeneralOptionStep(generalOptionStep + 1)}>
                    <img
                      src={iconDateSeekerNew}
                      // style={{ cursor: "pointer" }}
                      alt="Move to future date"
                    />
                  </InvisibleButton>
                )}
              </VerticalAlignDiv>
            ) : (
              <VerticalAlignDiv>
                <TimeSeekingText style={{ minWidth: "120px" }}>{finalDay}</TimeSeekingText>
              </VerticalAlignDiv>
            )}
            <VerticalAlignDiv>
              <InvisibleButton marginLeft onClick={toggleCalendar}>
                <img src={iconCalendarNew} alt="Calendar icon" />
              </InvisibleButton>
            </VerticalAlignDiv>
          </HorizontalDiv>
          {finalDay === "" ? (
            <ScrollSegment>
              {dummyYears[generalOptionStep].months?.map((item) => (
                <SelectionButton
                  key={item.label}
                  button={item.label}
                  option={finalDay}
                  onClick={() => {
                    setFinalDay(`${item.label} ${moment().add(generalOptionStep, "years").format("YYYY")}`);
                    setFieldValue("month", item.value);
                  }}
                >
                  {item.label}
                </SelectionButton>
              ))}
            </ScrollSegment>
          ) : (
            <GeneralTimeDropdowns isInCallRoute={isInCallRoute} />
          )}
        </div>
      )}
    </FieldsWrapper>
  );
};

interface InvisButtonProps {
  marginLeft?: boolean;
}

const InvisibleButton = styled(NewAppButton)<InvisButtonProps>`
  height: 24px;
  width: 24px;
  border: none;
  margin-left: ${(props) => (props.marginLeft ? "16px" : "0px")};
  :hover {
    border: none;
  }
`;

interface IEmptyDiv {
  small?: boolean;
  none?: boolean;
}

const EmptyDiv = styled.div<IEmptyDiv>`
  height: 24px;
  width: ${(props) => (props.none ? "0px" : props.small ? "14px" : "24px")};
`;

const ScrollSegment = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 12px;
  /* max-height: 250px; */
  overflow-x: visible;
  /* overflow-y: auto; */
`;

const BackArrow = styled.div`
  position: relative;
  border-radius: 50%;
  height: 24px;
  width: 24px;
  margin-right: 12px;
  background: ${theme.BLACK_COLOR};
  color: ${theme.WHITE_COLOR};
  cursor: pointer;
`;

const VerticalAlignDiv = styled.div`
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin-bottom: 10px; */
`;

const TimeSeekingText = styled(AppText)`
  display: inline-block;
  font-size: 14px;
  line-height: 21px;
  margin: 0px 10px;
  min-width: 100px;
  max-height: 21px;
  text-align: left;
  text-overflow: ellipsis;
`;

const HorizontalDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: start;
`;

const FieldsWrapper = styled.div`
  padding: 14px 0px;
`;

interface TransferDemoThirdButtonProps {
  button?: string;
  option?: string;
  currentTime?: boolean;
}

const SelectionButton = styled(NewAppButton)<TransferDemoThirdButtonProps>`
  width: 100%;
  height: 40px;
  border-radius: 4px;
  border: solid 1px
    ${(props) =>
      props.currentTime ? theme.NEUTRAL400 : props.button === props.option ? theme.NEUTRAL400 : theme.NEUTRAL200};
  background-color: ${(props) =>
    props.currentTime ? theme.NEUTRAL100 : props.button === props.option ? theme.NEUTRAL100 : theme.WHITE_COLOR};
  color: ${theme.BLACK_COLOR};
  font-weight: 600;
  font-size: 10px;
  margin-bottom: 0px;
  :focus {
    outline: none;
  }
`;

const TitleLabel = styled(AppText)`
  margin-bottom: 11px;
  margin-top: 11px;
`;

const FieldSelect = styled(FormSelectField)``;

const FieldSelect2 = styled(FormSelectField)`
  /* height: 25px; */
  font-size: 12px;
  /* border-radius: 4.4px; */
  text-align: left;
  border: 1px solid ${theme.NEUTRAL200};
  :focus {
    border: 1px solid ${theme.BLACK_COLOR};
  }
`;

const HeaderContainer = styled.div`
  width: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: auto;
  margin-bottom: 15px;
  margin-top: 40px;
`;

export { ScheduleTimeSelectComponentV3 };
