import React, { useContext } from "react";
import { AppText, FlexDiv } from "src/Components/UI";
import { PhoenixIcon } from "src/Components/UI/Phoenix";
import { LeadCardContext } from "src/context";
import { googleLogo } from "src/images";
import { flame, more_vertical, sequences, star } from "src/images/NewDesign";
import { generateFakeLeadData } from "src/utils/fake-data";
import { theme } from "src/utils/theme";
import styled from "styled-components";
import { PreviewField } from "./PreviewField";

const fakeLeadData = generateFakeLeadData();

export const LeadCardPreview: React.FC = () => {
  const { leadCardFields } = useContext(LeadCardContext);
  return (
    <FlexDiv direction="column" gap={16}>
      <AppText
        fontSize={10}
        fontWeight={600}
        letterSpacing={1}
        color={theme.text.brand.primary}
        uppercase
        style={{ alignSelf: "flex-start" }}
      >
        Preview
      </AppText>
      <LeadCardPreviewContainer>
        <LeadCardPreviewHeader>
          <FlexDiv align="center" gap={8}>
            <PhoenixIcon svg={star} size={16} color={theme.icon.brand.default} hoverColor={theme.icon.brand.default} />
            <AppText fontSize={16} fontWeight={500} lineHeight={22}>
              {fakeLeadData.BUSINESS_NAME}
            </AppText>
          </FlexDiv>

          <FlexDiv align="center" gap={8}>
            <img loading="lazy" src={googleLogo} style={{ width: "22px", height: "22px" }} />
            <Tag backgroundColor={theme.fill.warning.secondary}>
              <PhoenixIcon
                svg={sequences}
                size={16}
                color={theme.icon.warning.default}
                hoverColor={theme.icon.warning.default}
              />
            </Tag>
            <Tag backgroundColor={theme.fill.dataviz4.inverse}>
              <PhoenixIcon
                svg={flame}
                size={16}
                color={theme.icon.neutral.inverse}
                hoverColor={theme.icon.neutral.inverse}
              />
            </Tag>
            <Tag backgroundColor={theme.WHITE_COLOR}>
              <PhoenixIcon
                svg={more_vertical}
                size={16}
                color={theme.icon.neutral.default}
                hoverColor={theme.icon.neutral.default}
              />
            </Tag>
          </FlexDiv>
        </LeadCardPreviewHeader>

        <FlexDiv wrap="wrap" padding="0px 16px 8px 16px">
          {leadCardFields.map((_, i) => (
            <PreviewField fakeLeadData={fakeLeadData} leadCardFields={leadCardFields} index={i} />
          ))}
        </FlexDiv>

        <FlexDiv direction="column" align="center" gap={8} padding="0px 16px 16px 16px" style={{ opacity: 0.25 }}>
          <Block width={392} height={40} borderRadius={8} filled />
          <FlexDiv justify="center" gap={8}>
            <Block width={192} height={40} borderRadius={8} />
            <Block width={192} height={40} borderRadius={8} />
          </FlexDiv>
        </FlexDiv>

        <div style={{ height: "100%", backgroundColor: theme.fill.brand.secondary, opacity: 0.35 }}>
          <FlexDiv>
            <PreviewTab selected />
            <PreviewTab />
            <PreviewTab />
            <PreviewTab />
            <PreviewTab />
          </FlexDiv>

          <FlexDiv direction="column" padding="8px 16px 16px 16px">
            <FlexDiv
              width="100%"
              height={32}
              padding="2px"
              style={{
                marginBottom: "16px",
                backgroundColor: theme.fill.neutral.primary,
                border: `1px solid ${theme.border.neutral.secondary}`,
                borderRadius: "6px",
              }}
            >
              <Block width={129} height={28} borderRadius={4} filled />
            </FlexDiv>

            <Block width={85} height={16} borderRadius={8} filled color={theme.fill.brand.disabled} />

            {new Array(3).fill(0).map((_, i) => (
              <FlexDiv
                key={`prev-${i}`}
                gap={16}
                padding="8px 0px"
                align="center"
                style={{ borderBottom: `1px solid ${theme.border.brand.tertiary}` }}
              >
                <Block width={304} height={16} borderRadius={8} filled color={theme.fill.brand.disabled} />
                <FlexDiv gap={8} align="center">
                  <Block width={32} height={32} borderRadius={4} />
                  <Block width={32} height={32} borderRadius={4} />
                </FlexDiv>
              </FlexDiv>
            ))}

            <Block
              width={40}
              height={40}
              borderRadius={4}
              filled
              style={{ marginTop: "16px", alignSelf: "flex-end" }}
            />
          </FlexDiv>
        </div>
      </LeadCardPreviewContainer>
    </FlexDiv>
  );
};

const LeadCardPreviewContainer = styled.div`
  width: 424px;
  height: 85%;
  max-height: 704px;

  overflow: hidden;
  border: 1px solid ${theme.border.neutral.primary};
  border-radius: 8px;
  background-color: ${theme.WHITE_COLOR};
`;

const LeadCardPreviewHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  padding: 8px 16px;

  border-bottom: 1px solid ${theme.border.neutral.secondary};
`;

const Tag = styled.div<{ backgroundColor: string }>`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 24px;
  height: 24px;

  border-radius: 4px;
  background-color: ${({ backgroundColor }) => backgroundColor};
`;

const PreviewTab = styled.div<{ selected?: boolean }>`
  width: 100%;
  height: 32px;

  border-bottom: ${({ selected }) => (selected ? "2px" : "1px")} solid
    ${({ selected }) => (selected ? theme.border.brand.primary : theme.border.neutral.primary)};
`;

interface IBlock {
  width: number;
  height: number;
  borderRadius: number;
  filled?: boolean;
  color?: string;
}

const Block = styled.div<IBlock>`
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
  border-radius: ${({ borderRadius }) => borderRadius}px;

  border: 1px solid ${({ color }) => (color ? color : theme.buttonborder.brand_outline.default)};
  background-color: ${({ filled, color }) => (filled ? (color ? color : theme.buttonfill.brand.default) : "unset")};
`;
