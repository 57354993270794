import React, { useMemo, useState } from "react";
import { AppErrorText, AppText, FlexDiv, Loading, SkeletonBlock } from "src/Components/UI";
import { PhoenixIcon } from "src/Components/UI/Phoenix";
import { useDraggable, useModalContext } from "src/utils/hooks";
import styled from "styled-components";
import { file_text, minus, plus, xIcon } from "../../../images/NewDesign";
import { theme } from "../../../utils/theme";
import { FetchVoiceMailScriptsExpectedResponse } from "src/types/VoicemailTypes";
import { gql, useQuery } from "@apollo/client";
import { appToast } from "src/utils/toast";

const FETCH_VOICEMAIL_SCRIPTS = gql`
  query FetchVoicemailScripts($fetchVoiceMailScriptsId: String) {
    fetchVoiceMailScripts(id: $fetchVoiceMailScriptsId)
  }
`;

export const ScriptPreviewModal = ({}) => {
  const {
    data: voiceMailScriptsData,
    loading: voiceMailScriptsLoading,
    error: voiceMailScriptsError,
    refetch: voiceMailScriptsRefetch,
  } = useQuery<FetchVoiceMailScriptsExpectedResponse>(FETCH_VOICEMAIL_SCRIPTS, {
    fetchPolicy: "cache-and-network",
    onCompleted: (data) => {
      console.log(data);
    },
    onError: (error) => {
      appToast("Unable to fetch voicemail script");
    },
  });
  const { draggableRef, handleDragStart, dragState } = useDraggable();
  const {
    showScriptPreviewModal,
    setShowScriptPreviewModal,
    scriptPreviewModalDataId,
    setScriptPreviewModalDataId,
  } = useModalContext();

  const [isMaximized, setIsMaximized] = useState<boolean>(true);

  // fetch script data

  const scriptData = useMemo(() => {
    return voiceMailScriptsData?.fetchVoiceMailScripts?.[0];
  }, [scriptPreviewModalDataId]);

  const loading = false;

  const error = false;

  const draggable = true;

  const handleClose = () => {
    setShowScriptPreviewModal(false);
  };

  if (error) {
    return <AppErrorText>Error fetching script data</AppErrorText>;
  }

  if (loading) {
    return <SkeletonBlock width="100%" height="200px" borderRadius={8} />;
  }

  return (
    <>
      <WindowContainer ref={draggableRef}>
        <Header onMouseDown={handleDragStart} dragState={dragState}>
          <FlexDiv gap={8} align="center">
            <PhoenixIcon svg={file_text} size={16} variant="white" hoverColor="white" />
            <AppText fontSize={14} fontWeight={600} lineHeight={20} color={theme.WHITE_COLOR}>
              {scriptData?.title}
            </AppText>
          </FlexDiv>
          <FlexDiv gap={8} align="center">
            <PhoenixIcon
              svg={isMaximized ? minus : plus}
              size={16}
              variant="white"
              hoverColor="white"
              pointer
              data-ignore-drag
              onClick={() => setIsMaximized(!isMaximized)}
            />
            <PhoenixIcon
              svg={xIcon}
              size={16}
              variant="white"
              hoverColor="white"
              pointer
              data-ignore-drag
              onClick={handleClose}
            />
          </FlexDiv>
        </Header>

        <Body expand={isMaximized} onMouseDown={handleDragStart}>
          {loading ? <Loading /> : <div dangerouslySetInnerHTML={{ __html: scriptData?.script ?? "" }} />}
        </Body>
      </WindowContainer>
      <AbsoluteDragHandle onMouseDown={(e: React.MouseEvent) => draggable && handleDragStart(e)} />
    </>
  );
};

const AbsoluteDragHandle = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const WindowContainer = styled.div`
  position: fixed;
  top: 270px;
  left: 140px;
  z-index: 1000;

  max-height: 80vh;
  width: 340px;

  height: fit-content;

  overflow: hidden;

  background-color: ${theme.WHITE_COLOR};
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);

  border: 1px solid ${theme.NEUTRAL300};
  border-radius: 8px;
`;

interface HeaderProps {
  dragState?: boolean;
}

const Header = styled.div<HeaderProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 8px 16px;

  background-color: ${theme.PRIMARY900};

  cursor: ${(props) => (!!props.dragState ? "grabbing" : props.dragState === false ? "grab" : "default")};
`;

interface BodyProps {
  expand?: boolean;
}

const Body = styled.div<BodyProps>`
  overflow: auto;
  max-width: 100%;
  padding: ${(props) => (props.expand ? "16px" : "0px")};
  height: ${(props) => (props.expand ? "max-content" : "0px")};

  background-color: ${theme.WHITE_COLOR};
`;
