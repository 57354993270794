import React, { useRef, useState } from "react";
import styled from "styled-components";
import { theme } from "../../../../utils/theme";
import { FlexDiv } from "../../../UI/FlexDiv";
import { AppText } from "../../../UI";
import { PhoenixAppButton, PhoenixIcon } from "src/Components/UI/Phoenix";
import { edit, file_text, more_vertical, trash } from "src/images/NewDesign";
import { useClickOutside } from "src/utils/hooks";
import { displaySanitizedHTML } from "src/utils/misc";
// import { Script } from "../StepScriptManager";

interface ScriptPreviewProps {
  script: any;
  handleShowPreviewModal: (scriptID: string) => void;
  handleEditScript: (scriptID: string) => void;
  handleShowDeleteScriptModal: (scriptID: string) => void;
}

const ScriptPreview: React.FC<ScriptPreviewProps> = ({
  script,
  handleShowPreviewModal,
  handleEditScript,
  handleShowDeleteScriptModal,
}) => {
  const [showOptions, setShowOptions] = useState(false);

  const moreVerticalRef = useRef<HTMLDivElement>(null);

  const handleToggleOptions = () => {
    setShowOptions(!showOptions);
  };

  useClickOutside(moreVerticalRef, () => setShowOptions(false));

  return (
    <div style={{ position: "relative" }}>
      <Card>
        <Header>
          <HeaderTitle>
            <PhoenixIcon
              svg={file_text}
              color={theme.icon.brand.default}
              hoverColor={theme.icon.brand.default}
              size={16}
            />
            <AppText fontSize={14} fontWeight={500} color={theme.BLACK_COLOR}>
              {script.title}
            </AppText>
          </HeaderTitle>
          <PhoenixIcon
            svg={more_vertical}
            color={theme.icon.brand.default}
            hoverColor={theme.icon.brand.default}
            size={16}
            onClick={handleToggleOptions}
            pointer
          />
        </Header>
        {showOptions && (
          <EditScriptMenu ref={moreVerticalRef}>
            <EditScriptMenuItem onClick={() => handleEditScript(script.id)}>
              <PhoenixIcon
                svg={edit}
                color={theme.icon.brand.default}
                hoverColor={theme.icon.brand.default}
                size={16}
              />
              <AppText fontSize={12} fontWeight={400} color={theme.BLACK_COLOR} lineHeight={18} cursor="pointer">
                Edit
              </AppText>
            </EditScriptMenuItem>
            <EditScriptMenuItem onClick={() => handleShowDeleteScriptModal(script.id)}>
              <PhoenixIcon
                svg={trash}
                color={theme.icon.danger.default}
                hoverColor={theme.icon.danger.default}
                size={16}
                pointer
              />
              <AppText fontSize={12} fontWeight={400} color={theme.BLACK_COLOR} lineHeight={18} cursor="pointer">
                Delete
              </AppText>
            </EditScriptMenuItem>
          </EditScriptMenu>
        )}
        <Body>
          <FlexDiv direction="column" justify="space-between" gap={16}>
            <AppText fontSize={12} fontWeight={500} color={theme.NEUTRAL300}>
              Created on {script.created_at}
            </AppText>
            <AppText fontSize={12} fontWeight={500} color={theme.PRIMARY600}>
              {script.description}
            </AppText>
            <Content dangerouslySetInnerHTML={{ __html: script.script }} />
          </FlexDiv>
          <PhoenixAppButton
            variant="brand-outline"
            buttonType="secondary"
            height={40}
            style={{
              minHeight: 40,
            }}
            onClick={() => {
              handleShowPreviewModal(script.id);
            }}
          >
            Preview
          </PhoenixAppButton>
        </Body>
      </Card>
    </div>
  );
};

const Card = styled(FlexDiv)`
  width: 459px;
  height: 300px;
  flex-direction: column;
  background-color: ${theme.WHITE_COLOR};
  border-radius: 8px;
  border: 1px solid ${theme.NEUTRAL300};
  position: relative;
`;

const Header = styled(FlexDiv)`
  width: 100%;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  background-color: ${theme.PRIMARY100};
  border-radius: 8px 8px 0 0;
`;

const HeaderTitle = styled(FlexDiv)`
  gap: 8px;
  align-items: center;
`;

const Body = styled(FlexDiv)`
  width: 100%;
  height: 100%;
  padding: 16px;
  flex-direction: column;
  gap: 16px;
  justify-content: space-between;
`;

const Content = styled(FlexDiv)`
  position: relative;
  height: 72px;
  overflow: hidden;
  width: 427px;

  p {
    white-space: pre-wrap;
    overflow: hidden;
    word-break: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    font-size: 14px;
    font-weight: 400;
    color: ${theme.BLACK_COLOR};
    line-height: 20px;
  }
`;

const EditScriptMenu = styled(FlexDiv)`
  position: absolute;
  top: 30px;
  right: 30px;
  flex-direction: column;
  background-color: ${theme.WHITE_COLOR};
  border-radius: 4px;
  border: 1px solid ${theme.border.neutral.primary};
`;

const EditScriptMenuItem = styled(FlexDiv)`
  width: 136px;
  padding: 8px;
  gap: 8px;
  align-items: center;
  cursor: pointer;
  // first child
  &:first-child {
    border-radius: 4px 4px 0 0;
    border-bottom: 1px solid ${theme.border.neutral.primary};
  }
  // last child
  &:last-child {
    border-radius: 0 0 4px 4px;
  }
`;

export default ScriptPreview;
