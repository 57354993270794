import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import * as Sentry from "@sentry/react";
import { Formik, FormikProps } from "formik";
import * as React from "react";
import { useState } from "react";
import styled from "styled-components";
import * as Yup from "yup";
import { OptionItem } from "../../types";
import { theme } from "../../utils/theme";
import { appToast } from "../../utils/toast";
import { MAX_SMS_CHARACTERS } from "../../utils/variables";
import { CheckboxField } from "../Field";
import { PhoenixInputField } from "../Field/Phoenix/PhoenixInputField";
import { AppErrorText, AppText, Loading, NewAppSelect, TemplateEditor } from "../UI";
import { PhoenixAppButton, PhoenixMultiSelect } from "../UI/Phoenix";
import { handleEmptyToken } from "./EditEmailModalV2";
import { Modal } from "./Modal";

interface DisappearingDivProps {
  visible: boolean;
  close: () => void;
  id?: string;
  name?: string;
  event?: string;
  when?: string;
  increment?: number;
  increment_multiplier?: number;
  method_email?: boolean;
  method_sms?: boolean;
  subject?: string;
  body?: string;
  template_id?: string;
}

const followupSchema = Yup.object().shape({
  name: Yup.string().required("Field is required"),
  event: Yup.string().required("Field is required"),
  when: Yup.string().required("Field is required"),
  increment_multiplier: Yup.number().required("Field is required").integer(),
  increment: Yup.number().required("Field is required").integer(),
  method_email: Yup.boolean().required("Field is required"),
  method_sms: Yup.boolean().required("Field is required"),
});

interface MyFormikProps {
  name: string;
  event: string;
  when: string;
  increment_multiplier?: number;
  increment?: number;
  method_email: boolean;
  method_sms: boolean;
  subject: string;
  body: string;
}

export const UPDATE_ONE_AUTOMATED_CAMPAIGN = gql`
  mutation UpdateOneAutomatedCampaign(
    $automatedCampaignId: String!
    $name: String
    $event: String
    $methodEmail: Boolean
    $methodSms: Boolean
    $when: WHEN
    $incrementMultiplier: Int
    $increment: Int
    $body: String
    $subject: String
  ) {
    updateOneAutomatedCampaign(
      automated_campaign_id: $automatedCampaignId
      name: $name
      event: $event
      method_email: $methodEmail
      method_sms: $methodSms
      when: $when
      increment_multiplier: $incrementMultiplier
      increment: $increment
      body: $body
      subject: $subject
    ) {
      id
      body
      subject
    }
  }
`;

interface ICreateAutomatedCampaign {
  addAutomatedCampaign?: {
    id?: string;
    name?: string;
    event?: string;
    method_email?: boolean;
    method_sms?: boolean;
    when?: string;
    increment?: number;
    increment_multiplier?: number;
  };
}
const CREATE_AUTOMATED_CAMPAIGN = gql`
  mutation addAutomatedCampaign(
    $name: String!
    $event: String!
    $method_email: Boolean!
    $method_sms: Boolean!
    $when: WHEN!
    $increment: Int!
    $increment_multiplier: Int!
  ) {
    addAutomatedCampaign(
      name: $name
      event: $event
      method_email: $method_email
      method_sms: $method_sms
      when: $when
      increment: $increment
      increment_multiplier: $increment_multiplier
    ) {
      id
      name
      event
      method_email
      method_sms
      when
      increment
      increment_multiplier
    }
  }
`;

const CREATE_OR_UPDATE_AUTOMATED_CAMPAIGN = gql`
  mutation addOrUpdateAutomatedCampaign(
    $id: String
    $name: String!
    $event: String!
    $method_email: Boolean!
    $method_sms: Boolean!
    $when: WHEN!
    $increment: Int!
    $increment_multiplier: Int!
    $template_id: String!
    $body: String!
    $subject: String!
  ) {
    addOrUpdateAutomatedCampaign(
      name: $name
      event: $event
      method_email: $method_email
      method_sms: $method_sms
      when: $when
      increment: $increment
      increment_multiplier: $increment_multiplier
      template_id: $template_id
      body: $body
      subject: $subject
      id: $id
    ) {
      id
      name
      event
      method_email
      method_sms
      when
      increment
      increment_multiplier
      template_id
      body
      subject
    }
  }
`;

interface ITriggeringEvent {
  type?: string;
  value?: string;
  label?: string;
}
interface ITriggeringEvents {
  triggeringEvents?: ITriggeringEvent[];
}
const GET_TRIGGER_EVENTS = gql`
  query triggeringEvents {
    triggeringEvents {
      type
      value
      label
    }
  }
`;

interface ITemplate {
  id?: string;
  name?: string;
  subject?: string;
  content?: string;
  type?: string;
}

interface IFetchTemplates {
  fetchTemplates?: ITemplate[];
}
const FETCH_TEMPLATES = gql`
  query fetchTemplates($TemplateFilter: TemplateFilter!) {
    fetchTemplates(TemplateFilter: $TemplateFilter) {
      id
      name
      subject
      content
      type
    }
  }
`;

interface IEmailTemplatePreview {
  fetchEmailTemplatePreview?: {
    body?: string;
    from?: string;
    subject?: string;
    to?: string;
    template_id?: string;
  };
}

const FETCH_EMAIL_TEMPLATE_PREVIEW = gql`
  query FetchEmailTemplatePreview($leadId: String!, $templateId: String!) {
    fetchEmailTemplatePreview(lead_id: $leadId, template_id: $templateId) {
      body
      from
      subject
      to
      template_id
    }
  }
`;
const FETCH_SMS_TEMPLATE_PREVIEW = gql`
  query FetchSMSTemplatePreview($leadId: String!, $templateId: String!) {
    fetchSMSTemplatePreview(lead_id: $leadId, template_id: $templateId) {
      text
      template_id
    }
  }
`;

const optionsLoading = [{ label: "Loading...", value: undefined }];

const optionsIncrement: OptionItem[] = [
  {
    label: "Minutes",
    value: 1,
  },
  {
    label: "Hours",
    value: 60,
  },
  {
    label: "Days",
    value: 1440,
  },
];

const optionsWhen = [
  {
    label: "Choose an option",
    value: undefined,
  },
  {
    label: "Before",
    value: "Before",
  },
  {
    label: "After",
    value: "After",
  },
];

const MINUTE_TIME_OPTIONS: OptionItem[] = [
  {
    label: "0 Minutes",
    value: 0,
  },
  {
    label: "5 Minutes",
    value: 5,
  },
  {
    label: "10 Minutes",
    value: 10,
  },
  {
    label: "15 Minutes",
    value: 15,
  },
  {
    label: "20 Minutes",
    value: 20,
  },
  {
    label: "25 Minutes",
    value: 25,
  },
  {
    label: "30 Minutes",
    value: 30,
  },
  {
    label: "35 Minutes",
    value: 35,
  },
  {
    label: "40 Minutes",
    value: 40,
  },
  {
    label: "45 Minutes",
    value: 45,
  },
  {
    label: "50 Minutes",
    value: 50,
  },
  {
    label: "55 Minutes",
    value: 55,
  },
  {
    label: "60 Minutes",
    value: 60,
  },
];

const CreateCampaign: React.FC<DisappearingDivProps> = ({ visible, close, id, ...props }) => {
  const [page, setPage] = useState(1);
  const [methodEmail, setMethodEmail] = useState(props?.method_email || false);
  const [methodSMS, setMethodSMS] = useState(props?.method_sms || false);
  const [selectedTemplateID, setSelectedTemplateID] = useState(props?.template_id || "");
  const [initialContent, setInitialContent] = useState(props?.body);

  const formikRef = React.useRef<FormikProps<MyFormikProps>>(null);

  const [createAutomatedCampaign, { loading, error }] = useMutation<ICreateAutomatedCampaign>(
    CREATE_AUTOMATED_CAMPAIGN,
    {
      onCompleted({ addAutomatedCampaign }) {
        console.log("createAutomatedCampaign: ", createAutomatedCampaign);
        if (!addAutomatedCampaign) {
          appToast("Something went wrong!");
          return;
        }
        setPage(2);
      },
      onError({ message }) {
        console.log("Error in createAutomatedCampaign: ", message);
        appToast(message);
        Sentry.captureEvent({
          message: `createAutomatedCampaign GraphQL Error: ${message}`,
        });
      },
    },
  );

  const [addOrUpdateAutomatedCampaign, { loading: loadingAutoCampaign, error: errorAutoCampaign }] = useMutation(
    CREATE_OR_UPDATE_AUTOMATED_CAMPAIGN,
    {
      onCompleted({ addOrUpdateAutomatedCampaign }) {
        console.log("addOrUpdateAutomatedCampaign: ", addOrUpdateAutomatedCampaign);
        if (!addOrUpdateAutomatedCampaign) {
          appToast("Error: Something went wrong!");
          return;
        }
        page === 1 ? setPage(2) : close();
        page === 2 && appToast("Automated Campaign Saved!");
      },
      onError({ message }) {
        console.log(message);
        appToast(message);
        Sentry.captureEvent({
          message: `addOrUpdateAutomatedCampaign GraphQL Error: ${message}`,
        });
      },
    },
  );

  const { data, loading: triggerLoading } = useQuery<ITriggeringEvents>(GET_TRIGGER_EVENTS, {
    fetchPolicy: "network-only",
    onError({ message, name }) {
      console.log(`Error in ${name}: `, message);
    },
  });

  interface TemplateFilter {
    template_id?: string;
    template_name?: string;
    team_ids?: string[];
    user_ids?: string[];
    roles?: string[];
    type?: "SMS" | "EMAIL";
  }

  const { data: templates, loading: templatesLoading, error: templatesError } = useQuery<IFetchTemplates>(
    FETCH_TEMPLATES,
    {
      variables: {
        TemplateFilter: {
          type: methodEmail ? "EMAIL" : "SMS",
        },
      } as TemplateFilter,
      onCompleted(fetchTemplatesData: IFetchTemplates) {
        console.log("fetchTemplates: ", fetchTemplatesData);
      },
      fetchPolicy: "network-only",
      onError({ message, name }) {
        appToast(message);
        console.log(`Error in ${name}: `, message);
      },
    },
  );

  const [
    fetchEmailTemplatePreview,
    { data: templateEmailPreview, loading: templateEmailPreviewLoading },
  ] = useLazyQuery<IEmailTemplatePreview>(FETCH_EMAIL_TEMPLATE_PREVIEW, {
    variables: {
      leadId: "",
      templateId: selectedTemplateID,
    },
    onCompleted({ fetchEmailTemplatePreview }) {
      console.log("fetchEmailTemplatePreview: ", fetchEmailTemplatePreview);

      // set the subject
      formikRef.current?.setFieldValue("subject", fetchEmailTemplatePreview?.subject);

      // override the editor content
      if (!fetchEmailTemplatePreview?.body) {
        appToast("Unable to fetch template preview. No Email Body found.");
        return;
      }

      const formattedInitialContent = handleEmptyToken(fetchEmailTemplatePreview?.body);
      formikRef.current?.setFieldValue("body", formattedInitialContent.innerHTML);
      setInitialContent(formattedInitialContent.innerHTML);
    },
    onError({ message }) {
      console.log(message);
      appToast(message);
    },
    fetchPolicy: "network-only",
  });

  const [fetchSMSTemplatePreview, { data: templateSMSPreview, loading: templateSMSPreviewLoading }] = useLazyQuery(
    FETCH_SMS_TEMPLATE_PREVIEW,
    {
      onCompleted({ fetchSMSTemplatePreview }) {
        if (!fetchSMSTemplatePreview?.text) {
          return;
        }

        // reset the subject
        formikRef.current?.setFieldValue("subject", "");

        // override the editor content
        formikRef.current?.setFieldValue("body", fetchSMSTemplatePreview.text);
        setInitialContent(fetchSMSTemplatePreview.text);
      },
      onError({ message }) {
        console.log(message);
        appToast(message);
      },
      fetchPolicy: "network-only",
    },
  );

  const optionsEvent = triggerLoading
    ? optionsLoading
    : data?.triggeringEvents?.map((t: ITriggeringEvent) => ({ label: `${t.type}: ${t.label}`, value: t.value })) || [];

  const globalError = error || errorAutoCampaign || templatesError;

  const globalLoading = loading || loadingAutoCampaign || templatesLoading;

  return (
    <Sentry.ErrorBoundary fallback={"An error has occured on the Follow Up page of Admin Onboarding"}>
      <Modal open={visible} onClose={close} closeButtonSize={16}>
        <Formik
          innerRef={formikRef}
          initialValues={{
            name: props?.name || "",
            event: props?.event || "",
            when: props?.when || "",
            increment_multiplier: !!props?.increment_multiplier
              ? typeof props.increment_multiplier === "string"
                ? parseInt(props.increment_multiplier)
                : props.increment_multiplier
              : 1, // minutes is the lowest value
            increment:
              props?.increment || props?.increment === 0
                ? typeof props.increment === "string"
                  ? parseInt(props.increment)
                  : props.increment
                : 0, // lowest value is right at demo AKA 0
            method_email: methodEmail,
            method_sms: methodSMS,
            subject: props?.subject || "",
            body: props?.body || "",
          }}
          validationSchema={followupSchema}
          onSubmit={async ({ increment, ...values }) => {
            id
              ? await addOrUpdateAutomatedCampaign({
                  variables: {
                    ...values,
                    id: id,
                    template_id: selectedTemplateID,
                    increment: Number(increment),
                  },
                })
              : await addOrUpdateAutomatedCampaign({
                  variables: {
                    ...values,
                    template_id: selectedTemplateID,
                    increment: Number(increment),
                  },
                });

            return true;
          }}
        >
          {({ values, submitForm, setFieldValue, isSubmitting, isValid }: FormikProps<MyFormikProps>) => {
            const campaignIsNotForBothSMSAndEmail = methodSMS !== methodEmail;

            if (globalError) {
              return <AppErrorText>Error creating campaign</AppErrorText>;
            }

            return page === 1 ? (
              <>
                <ScrollingDiv>
                  <CenterDiv>
                    <PopupTitle>{id ? "Edit " : "Add New "}Automated Campaign</PopupTitle>
                  </CenterDiv>
                  <PopupInputLabel>
                    Campaign Name<span style={{ color: "red" }}>*</span>
                  </PopupInputLabel>
                  <FormInput name="name" />
                  <PopupInputLabel>
                    Trigger Event<span style={{ color: "red" }}>*</span>
                  </PopupInputLabel>
                  <FormSelect
                    placeholder="Choose an option"
                    options={optionsEvent}
                    name="event"
                    value={{
                      label: values.event,
                      value: values.event,
                    }}
                    onChange={(e: OptionItem) => {
                      setFieldValue("event", e?.value);
                    }}
                    isMulti={false}
                    isClearable
                  />
                  <PopupInputLabel>
                    When<span style={{ color: "red" }}>*</span>
                  </PopupInputLabel>
                  <FormSelect
                    name="when"
                    options={optionsWhen}
                    value={{
                      label: values.when,
                      value: values.when,
                    }}
                    onChange={(e: any) => {
                      setFieldValue("when", e?.value);
                    }}
                    isMulti={false}
                    isClearable
                  />
                  <PopupInputLabel>
                    Time Delineation <span style={{ color: "red" }}>*</span>
                  </PopupInputLabel>
                  <FormSelect
                    name="increment_multiplier"
                    placeholder="Minutes/Hours/Days"
                    isMulti={false}
                    isClearable
                    options={optionsIncrement}
                    value={{
                      label: optionsIncrement?.find((option: any) => option?.value === values?.increment_multiplier)
                        ?.label,
                      value: values.increment_multiplier,
                    }}
                    onChange={(e: OptionItem) => {
                      const newVal = typeof e?.value === "string" ? parseInt(e?.value) : e?.value;

                      setFieldValue("increment_multiplier", newVal);
                      setFieldValue("increment", 0);
                    }}
                  />
                  <PopupInputLabel>
                    Value<span style={{ color: "red" }}>*</span>
                  </PopupInputLabel>
                  {values.increment_multiplier !== 1 ? (
                    <FormInput type="number" name="increment" placeholder="Enter value" />
                  ) : (
                    <PhoenixMultiSelect
                      name="increment"
                      isClearable
                      isMulti={false}
                      options={MINUTE_TIME_OPTIONS}
                      value={{
                        label: MINUTE_TIME_OPTIONS?.find((option: any) => option?.value === values?.increment)?.label,
                        value: values.increment,
                      }}
                      onChange={(e: OptionItem) => {
                        setFieldValue("increment", e?.value);
                      }}
                    />
                  )}

                  <PopupInputLabel>
                    Delivery Options<span style={{ color: "red" }}>*</span>
                  </PopupInputLabel>
                  <FlexDiv>
                    <CheckboxField name="method_email" title="Email" onClick={() => setMethodEmail(!methodEmail)} />
                    <CheckboxField name="method_sms" title="SMS" onClick={() => setMethodSMS(!methodSMS)} />
                  </FlexDiv>
                  <WhitespaceDiv />
                </ScrollingDiv>
                <ButtonContainer>
                  <PhoenixAppButton variant="danger-outline" buttonType="primary" onClick={close}>
                    Cancel
                  </PhoenixAppButton>
                  {isSubmitting ? (
                    <Loading />
                  ) : (
                    <PhoenixAppButton
                      variant="brand"
                      buttonType="primary"
                      type="submit"
                      onClick={() => {
                        setPage(2);
                      }}
                      disabled={(!methodEmail && !methodSMS) || !isValid}
                    >
                      Continue
                    </PhoenixAppButton>
                  )}
                </ButtonContainer>
              </>
            ) : (
              <>
                <ScrollingDiv>
                  <CenterDiv>
                    <PopupTitle>
                      {id ? "Update" : "Create"} Automated Campaign {props.name && `for "${props.name}"`}
                    </PopupTitle>
                  </CenterDiv>

                  {
                    // show if either sms or email is checked but not both - and LD flags are enabled (we can't support email template options on SMS campaigns)
                    campaignIsNotForBothSMSAndEmail && (
                      <NewAppSelect
                        title="Templates"
                        options={
                          templates?.fetchTemplates
                            ?.map((template: ITemplate) => ({
                              value: template?.id,
                              label: template?.name ?? "N/A",
                            }))
                            ?.concat({
                              value: "",
                              label: "Select a template",
                            }) ?? []
                        }
                        onChange={(e) => {
                          const selectedTemplate = templates?.fetchTemplates?.find(
                            (template: any) => template?.id === e?.value,
                          );

                          const userSelectedTheBlankOption = e?.value === "";

                          if (userSelectedTheBlankOption) {
                            // formik
                            setFieldValue("subject", "");
                            setFieldValue("body", "");

                            // editor
                            setInitialContent("");

                            setSelectedTemplateID("");
                          } else if (selectedTemplate && page === 2) {
                            if (!selectedTemplate?.id) {
                              appToast("Error: template not found");
                              return;
                            }

                            setSelectedTemplateID(selectedTemplate?.id);

                            // we get the template preview then set the field body value
                            const fetchVars = {
                              variables: {
                                leadId: "",
                                templateId: selectedTemplate?.id,
                              },
                            };

                            methodEmail ? fetchEmailTemplatePreview(fetchVars) : fetchSMSTemplatePreview(fetchVars);
                          }
                        }}
                        value={selectedTemplateID}
                      />
                    )
                  }

                  {methodEmail && (
                    <PhoenixInputField
                      name="subject"
                      value={values.subject}
                      placeholder="Enter"
                      titleText="Subject Line"
                    />
                  )}

                  <TemplateEditor
                    titleText="Automated Campaign Copy"
                    requiredStar
                    initialContent={initialContent}
                    emojiPickerTop={-310}
                    emojiPickerRight={methodEmail && !methodSMS ? 20 : -370}
                    width={methodSMS ? 400 : 550}
                    maxKeyCount={methodSMS ? MAX_SMS_CHARACTERS : 9999}
                    displayNoContextText
                    noMargin
                    smsEditor={methodSMS}
                    deliver
                    onContentChange={(content) => setFieldValue("body", content)}
                  />
                </ScrollingDiv>

                <ButtonContainer>
                  <PhoenixAppButton
                    variant="danger-outline"
                    buttonType="primary"
                    onClick={() => {
                      setPage(1);
                      setFieldValue("method_email", false);
                      setFieldValue("method_sms", false);
                      setMethodEmail(false);
                      setMethodSMS(false);
                    }}
                  >
                    Go Back
                  </PhoenixAppButton>

                  <PhoenixAppButton
                    variant="brand"
                    buttonType="primary"
                    type="submit"
                    onClick={submitForm}
                    disabled={isSubmitting || globalLoading || !isValid}
                  >
                    {id ? "Update" : "Create"} Campaign
                  </PhoenixAppButton>
                </ButtonContainer>
              </>
            );
          }}
        </Formik>
      </Modal>
    </Sentry.ErrorBoundary>
  );
};

const FlexDiv = styled.div`
  display: grid;
  grid-template-columns: 150px 150px;
`;

const CenterDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const FormInput = styled(PhoenixInputField)`
  margin: 0;
  text-align: left;
  border-radius: 2.9px;
  border: solid 1px ${theme.BLACK_COLOR};
  ::placeholder {
    text-align: left;
  }
`;

const FormSelect = styled(PhoenixMultiSelect)`
  border-radius: 2.9px;
  border: solid 1px ${theme.BLACK_COLOR};
`;

const WhitespaceDiv = styled.div`
  height: 50px;
`;

const PopupInputLabel = styled(AppText)`
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 13px;
  margin-left: 5px;
`;

const PopupTitle = styled(AppText)`
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 27px;
`;

const ScrollingDiv = styled.div`
  position: relative;
  padding: 40px 66px;
  overflow: auto;
  width: 100%;
  min-width: 500px;
  max-height: calc(100vh - 104px);
  overflow: auto;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 16px;
  padding: 16px;
  border-top: 1px solid ${theme.NEUTRAL200};
`;

export { CreateCampaign };
