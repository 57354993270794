import React, { useEffect, useContext, useState } from "react";
import styled from "styled-components";
import { useDraggable } from "src/utils/hooks";
import { theme } from "src/utils/theme";
import { CallContext } from "../../context";
import { appToast } from "../../utils/toast";

const DialerV2 = () => {
  const { draggableRef, handleDragStart } = useDraggable();
  const { sendDigit } = useContext(CallContext);
  const [dialedNumbers, setDialedNumbers] = useState("");

  const buttons = [
    { number: "1", letters: "\u00A0\u00A0\u00A0\u00A0" },
    { number: "2", letters: "ABC" },
    { number: "3", letters: "DEF" },
    { number: "4", letters: "GHI" },
    { number: "5", letters: "JKL" },
    { number: "6", letters: "MNO" },
    { number: "7", letters: "PQRS" },
    { number: "8", letters: "TUV" },
    { number: "9", letters: "WXYZ" },
    { number: "*", letters: "", backgroundColor: "#C8E5FF", color: theme.PRIMARY500 },
    { number: "0", letters: "+" },
    { number: "#", letters: "", backgroundColor: "#C8E5FF", color: theme.PRIMARY500 },
  ];

  const addToDialedNumbers = (number: string, e?: React.MouseEvent) => {
    e?.stopPropagation();
    sendDigit(number);
    if (dialedNumbers.length >= 21) {
      setDialedNumbers(dialedNumbers.substring(1).concat(number));
    } else {
      setDialedNumbers(dialedNumbers.concat(number));
    }
  };

  const handlePaste = (e: ClipboardEvent) => {
    const { clipboardData } = e;
    const pastedText = clipboardData ? clipboardData.getData("text").replace(/\s+/g, "") : "";
    const numberValid = new RegExp("^[0-9]+$");
    if (!numberValid.test(pastedText)) {
      appToast(`${pastedText} is not a valid number`);
    } else {
      addToDialedNumbers(pastedText);
    }
  };

  useEffect(() => {
    document.addEventListener("paste", handlePaste);
    return () => {
      document.removeEventListener("paste", handlePaste);
    };
  }, []);

  return (
    <DialerContainer ref={draggableRef} onMouseDown={handleDragStart}>
      <NumberDisplay>{dialedNumbers}</NumberDisplay>

      <DialerGrid>
        {buttons.map((button, index) => (
          <DialButton
            key={index}
            backgroundColor={button.backgroundColor}
            onMouseDown={(e) => {
              e.stopPropagation();
              addToDialedNumbers(button.number, e);
            }}
          >
            <Number color={button.color}>{button.number}</Number>
            {button.letters && <Letters>{button.letters}</Letters>}
          </DialButton>
        ))}
      </DialerGrid>

      <PasteTip>extension/meeting IDs are pasteable</PasteTip>
    </DialerContainer>
  );
};

const DialerContainer = styled.div`
  align-items: center;
  background: ${theme.PRIMARY500};
  border-radius: 8px;
  bottom: 24px;
  cursor: default;
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: 390px;
  padding: 20px 16px;
  position: fixed;
  right: 64px;
  width: 318px;
  z-index: 101;
`;

const NumberDisplay = styled.div`
  font-size: 20px;
  color: ${theme.WHITE_COLOR};
  min-height: 24px;
  text-align: center;
`;

const DialerGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 8px;
  width: fit-content;
`;

const DialButton = styled.button<{ backgroundColor?: string }>`
  align-items: center;
  background: ${(props) => props.backgroundColor || "white"};
  border-radius: 8px;
  border: none;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 64px;
  justify-content: center;
  padding: 12px;
  width: 90px;

  &:hover {
    opacity: 0.8;
  }

  &:active {
    transform: translateY(1px);
  }
`;

const Number = styled.span`
  color: ${(props) => props.color || "#000"};
  font-size: 28px;
  font-weight: 500;
`;

const Letters = styled.span`
  font-size: 12px;
  color: ${theme.NEUTRAL300};
  margin-top: 2px;
`;

const PasteTip = styled.div`
  color: ${theme.NEUTRAL100};
  font-size: 12px;
  text-align: center;
`;

export default DialerV2;
