import React, { useCallback, useMemo } from "react";
import styled from "styled-components";
import { AppText, FlexDiv } from "src/Components/UI";
import { PhoenixIcon, PhoenixInput, PhoenixMultiSelect } from "src/Components/UI/Phoenix";
import { trash } from "src/images/NewDesign";
import { theme } from "src/utils/theme";
import { CustomNotification } from "./types";

type CustomNotificationCardProps = {
  error?: any;
  index: number;
  notification: CustomNotification;
  onChange: (config: CustomNotification) => void;
  onDelete: () => void;
  options: any;
};

const CustomNotificationCard = ({
  error,
  index,
  notification,
  onChange,
  onDelete,
  options,
}: CustomNotificationCardProps) => {
  const handleChange = useCallback(
    (field: string, value: any) => {
      const updatedNotification = {
        ...notification,
        [field]: value,
        ...(field === "operand" ? { in_values: [], from_value: "", to_value: "" } : {}),
      };
      onChange(updatedNotification);
    },
    [notification, onChange],
  );

  const renderValueInput = useMemo(() => {
    if (notification.operand.toLowerCase() === "to") {
      return (
        <PhoenixInput onChange={(e: any) => handleChange("to_value", e.target.value)} value={notification.to_value} />
      );
    }

    if (notification.operand.toLowerCase() === "from") {
      return (
        <PhoenixInput
          onChange={(e: any) => handleChange("from_value", e.target.value)}
          value={notification.from_value}
        />
      );
    }

    if (notification.operand.toLowerCase() === "in") {
      const inValueOptions = notification.in_values?.map((item: any) => ({ label: item, value: item }));
      return (
        <PhoenixMultiSelect
          marginBottom={false}
          name="brand"
          creatableOptions
          onCreateOption={(input: any) => {
            handleChange("in_values", [...notification.in_values, input]);
          }}
          onChange={(item: any) => {
            handleChange(
              "in_values",
              item.map((item: any) => item.value),
            );
          }}
          options={inValueOptions}
          value={inValueOptions}
        />
      );
    }
  }, [notification, handleChange]);

  return (
    <Wrapper hasError={!!error}>
      <FlexDiv justify="space-between" align="center" gap={24}>
        <AppText fontWeight={500} fontSize={10} color={theme.text.brand.primary}>
          NOTIFICATION {index}
        </AppText>

        <PhoenixIcon svg={trash} size={16} variant="danger" onClick={onDelete} />
      </FlexDiv>

      <FlexDiv direction="column" gap={24} width="100%">
        <FlexDiv align="center" gap={8}>
          <SelectLabel>Notify</SelectLabel>

          <FlexDiv direction="column" width="100%">
            <PhoenixMultiSelect
              isClearable={false}
              isMulti={false}
              marginBottom={false}
              name="brand"
              onChange={(item: any) => handleChange("recipient_type", item.value)}
              options={options.recipients}
              value={options.recipients.find((item: any) => item.value === notification.recipient_type)}
            />
          </FlexDiv>
        </FlexDiv>

        <FlexDiv align="center" gap={8}>
          <SelectLabel>When</SelectLabel>

          <FlexDiv direction="column" width="100%">
            <PhoenixMultiSelect
              isClearable={false}
              isMulti={false}
              marginBottom={false}
              name="brand"
              onChange={(item: any) => handleChange("internal_object_field", item.value)}
              options={options.internal_objects}
              value={options.internal_objects.find((item: any) => item.value === notification.internal_object_field)}
            />
          </FlexDiv>
        </FlexDiv>

        <FlexDiv align="center" gap={8}>
          <SelectLabel>Is Changed</SelectLabel>

          <FlexDiv direction="column" width="100%">
            <PhoenixMultiSelect
              isClearable={false}
              isMulti={false}
              marginBottom={false}
              name="brand"
              onChange={(item: any) => handleChange("operand", item.value)}
              options={options.operands}
              value={options.operands.find((item: any) => item.value === notification.operand)}
            />
          </FlexDiv>
        </FlexDiv>

        <FlexDiv align="center" gap={8}>
          <SelectLabel />
          <FlexDiv direction="column" width="100%">
            {renderValueInput}
          </FlexDiv>
        </FlexDiv>
      </FlexDiv>

      {error && (
        <FlexDiv width="100%">
          <AppText fontSize={12} color={theme.text.danger.primary}>
            {typeof error === "string" ? error : "Please fill in all required fields"}
          </AppText>
        </FlexDiv>
      )}
    </Wrapper>
  );
};

export default CustomNotificationCard;

const Wrapper = styled(FlexDiv)<{ hasError?: boolean }>`
  background-color: ${theme.text.neutral.inverse};
  border-radius: 8px;
  border: 1px solid ${(props) => (props.hasError ? theme.text.danger.primary : theme.text.neutral.disabled)};
  flex-direction: column;
  gap: 24px;
  padding: 16px;
  width: 100%;
`;

const SelectLabel = styled(AppText)`
  font-size: 12px;
  font-weight: 500;
  text-align: right;
  width: 95px;
`;
