import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import gql from "graphql-tag";
import { FlexDiv } from "src/Components/UI";
import { PhoenixAppButton, PhoenixMultiSelect } from "src/Components/UI/Phoenix";
import useFetchReps from "src/query-hooks/useFetchReps";
import { OptionItem } from "src/types";
import { errorToast, successToast } from "src/utils/toast";

const BATCH_ASSIGN_REPS = gql`
  mutation batchAssignReps($userIds: [String!]!, $labelId: String!) {
    batchCreateUserLabel(user_ids: $userIds, label_id: $labelId)
  }
`;

const AssignRepsAction = ({ id }: { id: string }) => {
  const [selectedReps, setSelectedReps] = useState<OptionItem[]>([]);

  const { data, loading } = useFetchReps();

  const options = loading
    ? [{ label: "Loading...", disabled: true }]
    : data.map((rep) => ({
        label: rep.full_name,
        value: rep.id,
      })) ?? [];

  const [batchAssignReps] = useMutation(BATCH_ASSIGN_REPS, {
    onCompleted() {
      successToast("Reps assigned");
      setSelectedReps([]);
    },
    onError({ message }) {
      errorToast(message);
    },
    refetchQueries: ["fetchLabel"],
  });

  const assignReps = () => {
    batchAssignReps({ variables: { userIds: selectedReps.map((rep) => rep.value), labelId: id } });
  };

  return (
    <FlexDiv direction="column">
      <FlexDiv direction="row" align="flex-end" gap={8}>
        <PhoenixMultiSelect
          hideErrorMessage
          isClearable
          isMulti
          isOptionDisabled={(option) => option.disabled}
          marginBottom={false}
          name="reps"
          onChange={setSelectedReps}
          options={options}
          titleText="Assign to Rep"
          value={selectedReps}
          width={350}
        />

        <PhoenixAppButton variant="brand" buttonType="secondary" disabled={!selectedReps.length} onClick={assignReps}>
          Assign
        </PhoenixAppButton>
      </FlexDiv>
    </FlexDiv>
  );
};

export default AssignRepsAction;
