import React, { useMemo, useState, useContext } from "react";
import { PhoenixAppButton, PhoenixMultiSelect } from "src/Components/UI/Phoenix";
import { voicemail } from "src/images/NewDesign";
import { theme } from "src/utils/theme";
import styled from "styled-components";
import { FlexDiv } from "../../UI/FlexDiv";
import { gql, useQuery } from "@apollo/client";
import { loggedInUser } from "src/apollo/cache";
import { appToast } from "src/utils/toast";
import { AppErrorText, SkeletonBlock } from "src/Components/UI";
import { CallContext } from "src/context";
import { VoicemailDrop } from "src/types/VoicemailTypes";
import { PhoenixStyledTooltip } from "src/Components/Dumb/PhoenixStyledTooltip";
import { OptionItem } from "src/types";

interface FetchVoicemailDropsExpectedResponse {
  fetchVoicemailDrops: VoicemailDrop[];
}

const FETCH_SAVED_VOICEMAIL_DROPS = gql`
  query FetchVoicemailDrops($userId: String) {
    fetchVoicemailDrops(user_id: $userId)
  }
`;
export const VoicemailDropsModule = () => {
  const { hangup, selectVoicemailDrop, selectedVoicemailDropId } = useContext(CallContext);
  const { data, loading, error } = useQuery<FetchVoicemailDropsExpectedResponse>(FETCH_SAVED_VOICEMAIL_DROPS, {
    fetchPolicy: "cache-and-network",
    variables: {
      userId: loggedInUser().id,
    },
    onCompleted: (data) => {},
    onError: (error) => {
      appToast("Unable to fetch voicemail drops");
    },
  });

  const handleLeaveVoicemailDrop = () => {
    hangup(true);
  };

  const FORMATED_VOCIEMAIL_DROP_OPTIONS = useMemo(
    () =>
      data?.fetchVoicemailDrops.map((voicemailDrop: VoicemailDrop) => ({
        label: voicemailDrop.title,
        value: voicemailDrop.id,
        customIcon: {
          type: "svg" as "svg" | "img",
          src: voicemail,
          inputIconColor: theme.PRIMARY500,
        },
      })) || [],
    [data?.fetchVoicemailDrops],
  );

  const globalError = error;
  const globalLoading = loading;

  if (globalError) {
    return (
      <MainDiv>
        <AppErrorText>Unable to fetch voicemail drops</AppErrorText>
      </MainDiv>
    );
  }

  if (globalLoading) {
    return <SkeletonBlock width={240} height={40} borderRadius={8} />;
  }

  return (
    <MainDiv>
      <PhoenixStyledTooltip id="voicemail-drop-tooltip"></PhoenixStyledTooltip>
      <PhoenixMultiSelect
        titleTextSpacing={8}
        options={FORMATED_VOCIEMAIL_DROP_OPTIONS}
        placeholder="Select Voicemail Recording"
        onChange={(e: { label: string; value: string }) => selectVoicemailDrop(e?.value)}
        value={
          FORMATED_VOCIEMAIL_DROP_OPTIONS.find(
            (voicemailDrop: OptionItem) => voicemailDrop.value === selectedVoicemailDropId,
          )?.value
        }
        titleText="Voicemail Drops"
        isMulti={false}
        name="voicemail-drops"
        marginBottom={false}
        width={240}
      />

      <PhoenixAppButton
        variant="brand"
        buttonType="secondary"
        onClick={handleLeaveVoicemailDrop}
        disabled={!selectedVoicemailDropId}
        data-tip="Selecting this will end the call, log a no-answer left voicemail disposition and play the selected voicemail drop."
        data-for="voicemail-drop-tooltip"
      >
        Leave Message
      </PhoenixAppButton>
    </MainDiv>
  );
};

const MainDiv = styled(FlexDiv)`
  width: 432px;
  height: max-content;
  padding: 16px;
  gap: 8px;
  justify-content: space-between;
  background-color: ${theme.fill.brand.secondary};
  border-radius: 8px;
  align-items: flex-end;
  border: 1px solid ${theme.border.neutral.primary};
`;
