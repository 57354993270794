import React, { useContext } from "react";
import styled from "styled-components";
import { CustomFieldType } from "../../../../__generated__/graphql";
import { ModalContext } from "../../../../context";
import { theme } from "../../../../utils/theme";
import { AppText } from "../../../UI";
import { PhoenixAppButton } from "../../../UI/Phoenix";

interface DisappearingDivProps {
  blinds: boolean;
  type: string;
  setBlinds: (blinds: boolean) => void;
  setModalField?: (field: any) => void;
  close?: () => void;
  defaultField: any;
}
const LeadCustomEmptyPage: React.FC<DisappearingDivProps> = ({ type, setModalField, setBlinds, defaultField }) => {
  const {
    setLeadSourceModalData,
    setIndustryModalData,
    setCustomObjectModalData,
    setDispositionReasonModalData,
  } = useContext(ModalContext);

  const formatSingleTypeToPlural = (type: string) => {
    return type.endsWith("y") ? `${type.slice(0, -1).toLowerCase()}ies` : `${type.toLowerCase()}s`;
  };

  const handleAddModal = ({ selectedField }: { selectedField: string }) => {
    switch (selectedField) {
      case "Lead Source":
        setLeadSourceModalData({
          id: "",
          delete_modal_on: false,
          name_modal_on: true,
          label: "",
        });
        break;
      case "Industry":
        setIndustryModalData({
          id: "",
          delete_modal_on: false,
          name_modal_on: true,
          label: "",
          sub_fields: [],
        });
        break;
      case "Custom Object":
        setCustomObjectModalData({
          id: "",
          delete_modal_on: false,
          name_modal_on: true,
          label: "",
        });
        break;
      case "Not Interested Reason":
        setDispositionReasonModalData({
          id: "",
          delete_modal_on: false,
          name_modal_on: true,
          label: "",
          disposition_type: "Not Interested",
        });
        break;
      case "Not Qualified Reason":
        setDispositionReasonModalData({
          id: "",
          delete_modal_on: false,
          name_modal_on: true,
          label: "",
          disposition_type: "Not Qualified",
        });
        break;
      default:
        break;
    }
  };

  return (
    <EmptyCustomField>
      <EmptyPageTextContainer>
        <AppText fontSize={12} lineHeight={18} color={theme.NEUTRAL300} style={{ textAlign: "center" }}>
          Let’s get started.
        </AppText>
        <AppText fontSize={16} lineHeight={22} style={{ textAlign: "center" }}>
          There are no {formatSingleTypeToPlural(type)}. Create one now.
        </AppText>
        <PhoenixAppButton
          buttonType="secondary"
          onClick={() => {
            handleAddModal({ selectedField: type });
            if (setModalField) {
              setModalField(defaultField);
            }
            setBlinds(true);
          }}
          style={{ alignSelf: "center", marginTop: "32px" }}
        >
          ADD {type.toUpperCase()}
        </PhoenixAppButton>
      </EmptyPageTextContainer>
    </EmptyCustomField>
  );
};

const EmptyCustomField = styled.div`
  background: ${theme.PRIMARY50};
  width: 100%;
  height: 100%;
`;

const EmptyPageTextContainer = styled.div`
  width: 387px;
  align-self: center;
  display: flex;
  flex-direction: column;
  margin: auto;
  margin-top: 200px;
  margin-bottom: 200px;
  align-items: center;
`;

export { LeadCustomEmptyPage };
