/* eslint-disable */
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** Date custom scalar type */
  Date: { input: any; output: any; }
  DateTime: { input: any; output: any; }
  Json: { input: any; output: any; }
  /** The `Upload` scalar type represents a file upload. */
  Upload: { input: any; output: any; }
};

/** Activity filter metric options */
export enum ActivityFilterMetric {
  CallFollowup = 'call_followup',
  ColdCall = 'cold_call',
  DecisionCallHeld = 'decision_call_held',
  DecisionCallSetNotHeld = 'decision_call_set_not_held',
  DemoHeld = 'demo_held',
  DemoSetNotHeld = 'demo_set_not_held',
  Dial = 'dial',
  EmailReceived = 'email_received',
  EmailSent = 'email_sent',
  GeneralCallbackHeld = 'general_callback_held',
  GeneralCallbackSetNotHeld = 'general_callback_set_not_held',
  ScheduleCallbackHeld = 'schedule_callback_held',
  ScheduleCallbackSetNotHeld = 'schedule_callback_set_not_held',
  SmsReceived = 'sms_received',
  SmsSent = 'sms_sent'
}

/** Activity filter operator options */
export enum ActivityFilterOperator {
  Between = 'Between',
  EqualTo = 'EqualTo',
  GreaterThan = 'GreaterThan',
  GreaterThanOrEqualTo = 'GreaterThanOrEqualTo',
  LessThan = 'LessThan',
  LessThanOrEqualTo = 'LessThanOrEqualTo',
  NotBetween = 'NotBetween',
  NotEqualTo = 'NotEqualTo'
}

export type ActivityMonitorMultiplier = {
  __typename?: 'ActivityMonitorMultiplier';
  action: Am_Type;
  communication_type: Communication_Type;
  id: Scalars['String']['output'];
  label?: Maybe<Scalars['String']['output']>;
  organization: Organization;
  organization_id: Scalars['String']['output'];
  weight: Scalars['Int']['output'];
};

export type ActivityMonitorMultiplierCreateManyOrganizationInput = {
  action: Am_Type;
  communication_type: Communication_Type;
  id?: InputMaybe<Scalars['String']['input']>;
  weight: Scalars['Int']['input'];
};

export type ActivityMonitorMultiplierCreateManyOrganizationInputEnvelope = {
  data?: InputMaybe<Array<ActivityMonitorMultiplierCreateManyOrganizationInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ActivityMonitorMultiplierCreateNestedManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<ActivityMonitorMultiplierWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ActivityMonitorMultiplierCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<ActivityMonitorMultiplierCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<ActivityMonitorMultiplierCreateManyOrganizationInputEnvelope>;
};

export type ActivityMonitorMultiplierCreateOrConnectWithoutOrganizationInput = {
  create: ActivityMonitorMultiplierCreateWithoutOrganizationInput;
  where: ActivityMonitorMultiplierWhereUniqueInput;
};

export type ActivityMonitorMultiplierCreateWithoutOrganizationInput = {
  action: Am_Type;
  communication_type: Communication_Type;
  id?: InputMaybe<Scalars['String']['input']>;
  weight: Scalars['Int']['input'];
};

export type ActivityMonitorMultiplierListRelationFilter = {
  every?: InputMaybe<ActivityMonitorMultiplierWhereInput>;
  none?: InputMaybe<ActivityMonitorMultiplierWhereInput>;
  some?: InputMaybe<ActivityMonitorMultiplierWhereInput>;
};

export type ActivityMonitorMultiplierOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
  count?: InputMaybe<SortOrder>;
};

export type ActivityMonitorMultiplierScalarWhereInput = {
  action?: InputMaybe<EnumAm_TypeFilter>;
  AND?: InputMaybe<Array<ActivityMonitorMultiplierScalarWhereInput>>;
  communication_type?: InputMaybe<EnumCommunication_TypeFilter>;
  id?: InputMaybe<StringFilter>;
  NOT?: InputMaybe<Array<ActivityMonitorMultiplierScalarWhereInput>>;
  OR?: InputMaybe<Array<ActivityMonitorMultiplierScalarWhereInput>>;
  organization_id?: InputMaybe<StringFilter>;
  weight?: InputMaybe<IntFilter>;
};

export type ActivityMonitorMultiplierUpdateManyMutationInput = {
  action?: InputMaybe<EnumAm_TypeFieldUpdateOperationsInput>;
  communication_type?: InputMaybe<EnumCommunication_TypeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  weight?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type ActivityMonitorMultiplierUpdateManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<ActivityMonitorMultiplierWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ActivityMonitorMultiplierCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<ActivityMonitorMultiplierCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<ActivityMonitorMultiplierCreateManyOrganizationInputEnvelope>;
  delete?: InputMaybe<Array<ActivityMonitorMultiplierWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ActivityMonitorMultiplierScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ActivityMonitorMultiplierWhereUniqueInput>>;
  set?: InputMaybe<Array<ActivityMonitorMultiplierWhereUniqueInput>>;
  update?: InputMaybe<Array<ActivityMonitorMultiplierUpdateWithWhereUniqueWithoutOrganizationInput>>;
  updateMany?: InputMaybe<Array<ActivityMonitorMultiplierUpdateManyWithWhereWithoutOrganizationInput>>;
  upsert?: InputMaybe<Array<ActivityMonitorMultiplierUpsertWithWhereUniqueWithoutOrganizationInput>>;
};

export type ActivityMonitorMultiplierUpdateManyWithWhereWithoutOrganizationInput = {
  data: ActivityMonitorMultiplierUpdateManyMutationInput;
  where: ActivityMonitorMultiplierScalarWhereInput;
};

export type ActivityMonitorMultiplierUpdateWithoutOrganizationInput = {
  action?: InputMaybe<EnumAm_TypeFieldUpdateOperationsInput>;
  communication_type?: InputMaybe<EnumCommunication_TypeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  weight?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type ActivityMonitorMultiplierUpdateWithWhereUniqueWithoutOrganizationInput = {
  data: ActivityMonitorMultiplierUpdateWithoutOrganizationInput;
  where: ActivityMonitorMultiplierWhereUniqueInput;
};

export type ActivityMonitorMultiplierUpsertWithWhereUniqueWithoutOrganizationInput = {
  create: ActivityMonitorMultiplierCreateWithoutOrganizationInput;
  update: ActivityMonitorMultiplierUpdateWithoutOrganizationInput;
  where: ActivityMonitorMultiplierWhereUniqueInput;
};

export type ActivityMonitorMultiplierWhereInput = {
  action?: InputMaybe<EnumAm_TypeFilter>;
  AND?: InputMaybe<Array<ActivityMonitorMultiplierWhereInput>>;
  communication_type?: InputMaybe<EnumCommunication_TypeFilter>;
  id?: InputMaybe<StringFilter>;
  NOT?: InputMaybe<Array<ActivityMonitorMultiplierWhereInput>>;
  OR?: InputMaybe<Array<ActivityMonitorMultiplierWhereInput>>;
  organization?: InputMaybe<OrganizationWhereInput>;
  organization_id?: InputMaybe<StringFilter>;
  weight?: InputMaybe<IntFilter>;
};

export type ActivityMonitorMultiplierWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']['input']>;
};

export type AddAssociateLeadInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  address_2?: InputMaybe<Scalars['String']['input']>;
  business_name?: InputMaybe<Scalars['String']['input']>;
  channel?: InputMaybe<Channel>;
  city?: InputMaybe<Scalars['String']['input']>;
  conference_number?: InputMaybe<Scalars['String']['input']>;
  content?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  country_code?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  industry?: InputMaybe<Scalars['String']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  lead_source?: InputMaybe<Scalars['String']['input']>;
  make_primary?: Scalars['Boolean']['input'];
  phone_number: Scalars['String']['input'];
  state?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  sub_industry?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type AddUserInput = {
  availability?: InputMaybe<Array<InputMaybe<AvailabilityInput>>>;
  channel?: InputMaybe<Channelfilter>;
  close_rate?: InputMaybe<Scalars['Float']['input']>;
  custom_field_white_list_array?: InputMaybe<Array<InputMaybe<UserCustomFieldFilterInput>>>;
  default_availability?: InputMaybe<Scalars['Boolean']['input']>;
  email: Scalars['String']['input'];
  first_name?: InputMaybe<Scalars['String']['input']>;
  holds_per_prospect_vs_held_demo_per_dial?: InputMaybe<Scalars['Float']['input']>;
  industry_filter?: InputMaybe<Scalars['Boolean']['input']>;
  industry_white_list_array?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  industry_white_list_assign_type?: InputMaybe<AssignType>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  lead_source_filter?: InputMaybe<Scalars['Boolean']['input']>;
  lead_source_white_list_array?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  lead_source_white_list_assign_type?: InputMaybe<AssignType>;
  manager_id?: InputMaybe<Scalars['String']['input']>;
  phone_number?: InputMaybe<Scalars['String']['input']>;
  regions_white_list_array?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  regions_white_list_assign_type?: InputMaybe<AssignType>;
  role?: InputMaybe<Role>;
  site_id?: InputMaybe<Scalars['String']['input']>;
  sub_industry_white_list_array?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sub_industry_white_list_assign_type?: InputMaybe<AssignType>;
  taking_demos?: InputMaybe<Scalars['Boolean']['input']>;
  /** only applicable to sales managers */
  team_name?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  visible_all_leads_sm?: InputMaybe<Scalars['Boolean']['input']>;
};

export type AlgoFactor = {
  __typename?: 'AlgoFactor';
  current_data?: Maybe<Scalars['Int']['output']>;
  HP_label: Scalars['String']['output'];
  HP_type: Hingepoint;
  id: Scalars['String']['output'];
  is_custom_field: Scalars['Boolean']['output'];
  label: Scalars['String']['output'];
  ltv_multiplier?: Maybe<Scalars['Float']['output']>;
  min_required_data?: Maybe<Scalars['Int']['output']>;
  organization: Organization;
  organization_id: Scalars['String']['output'];
  rate?: Maybe<Scalars['Float']['output']>;
  variable_name: Scalars['String']['output'];
};

export type AlgoFactorCreateManyOrganizationInput = {
  current_data?: InputMaybe<Scalars['Int']['input']>;
  HP_label: Scalars['String']['input'];
  HP_type: Hingepoint;
  id?: InputMaybe<Scalars['String']['input']>;
  is_custom_field?: InputMaybe<Scalars['Boolean']['input']>;
  label: Scalars['String']['input'];
  ltv_multiplier?: InputMaybe<Scalars['Float']['input']>;
  min_required_data?: InputMaybe<Scalars['Int']['input']>;
  rate?: InputMaybe<Scalars['Float']['input']>;
  variable_name: Scalars['String']['input'];
};

export type AlgoFactorCreateManyOrganizationInputEnvelope = {
  data?: InputMaybe<Array<AlgoFactorCreateManyOrganizationInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type AlgoFactorCreateNestedManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<AlgoFactorWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<AlgoFactorCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<AlgoFactorCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<AlgoFactorCreateManyOrganizationInputEnvelope>;
};

export type AlgoFactorCreateOrConnectWithoutOrganizationInput = {
  create: AlgoFactorCreateWithoutOrganizationInput;
  where: AlgoFactorWhereUniqueInput;
};

export type AlgoFactorCreateWithoutOrganizationInput = {
  current_data?: InputMaybe<Scalars['Int']['input']>;
  HP_label: Scalars['String']['input'];
  HP_type: Hingepoint;
  id?: InputMaybe<Scalars['String']['input']>;
  is_custom_field?: InputMaybe<Scalars['Boolean']['input']>;
  label: Scalars['String']['input'];
  ltv_multiplier?: InputMaybe<Scalars['Float']['input']>;
  min_required_data?: InputMaybe<Scalars['Int']['input']>;
  rate?: InputMaybe<Scalars['Float']['input']>;
  variable_name: Scalars['String']['input'];
};

export type AlgoFactorListRelationFilter = {
  every?: InputMaybe<AlgoFactorWhereInput>;
  none?: InputMaybe<AlgoFactorWhereInput>;
  some?: InputMaybe<AlgoFactorWhereInput>;
};

export type AlgoFactorOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
  count?: InputMaybe<SortOrder>;
};

export type AlgoFactorScalarWhereInput = {
  AND?: InputMaybe<Array<AlgoFactorScalarWhereInput>>;
  current_data?: InputMaybe<IntNullableFilter>;
  HP_label?: InputMaybe<StringFilter>;
  HP_type?: InputMaybe<EnumHingepointFilter>;
  id?: InputMaybe<StringFilter>;
  is_custom_field?: InputMaybe<BoolFilter>;
  label?: InputMaybe<StringFilter>;
  ltv_multiplier?: InputMaybe<FloatNullableFilter>;
  min_required_data?: InputMaybe<IntNullableFilter>;
  NOT?: InputMaybe<Array<AlgoFactorScalarWhereInput>>;
  OR?: InputMaybe<Array<AlgoFactorScalarWhereInput>>;
  organization_id?: InputMaybe<StringFilter>;
  rate?: InputMaybe<FloatNullableFilter>;
  variable_name?: InputMaybe<StringFilter>;
};

export type AlgoFactorUpdateManyMutationInput = {
  current_data?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  HP_label?: InputMaybe<StringFieldUpdateOperationsInput>;
  HP_type?: InputMaybe<EnumHingepointFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  is_custom_field?: InputMaybe<BoolFieldUpdateOperationsInput>;
  label?: InputMaybe<StringFieldUpdateOperationsInput>;
  ltv_multiplier?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  min_required_data?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  variable_name?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type AlgoFactorUpdateManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<AlgoFactorWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<AlgoFactorCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<AlgoFactorCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<AlgoFactorCreateManyOrganizationInputEnvelope>;
  delete?: InputMaybe<Array<AlgoFactorWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<AlgoFactorScalarWhereInput>>;
  disconnect?: InputMaybe<Array<AlgoFactorWhereUniqueInput>>;
  set?: InputMaybe<Array<AlgoFactorWhereUniqueInput>>;
  update?: InputMaybe<Array<AlgoFactorUpdateWithWhereUniqueWithoutOrganizationInput>>;
  updateMany?: InputMaybe<Array<AlgoFactorUpdateManyWithWhereWithoutOrganizationInput>>;
  upsert?: InputMaybe<Array<AlgoFactorUpsertWithWhereUniqueWithoutOrganizationInput>>;
};

export type AlgoFactorUpdateManyWithWhereWithoutOrganizationInput = {
  data: AlgoFactorUpdateManyMutationInput;
  where: AlgoFactorScalarWhereInput;
};

export type AlgoFactorUpdateWithoutOrganizationInput = {
  current_data?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  HP_label?: InputMaybe<StringFieldUpdateOperationsInput>;
  HP_type?: InputMaybe<EnumHingepointFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  is_custom_field?: InputMaybe<BoolFieldUpdateOperationsInput>;
  label?: InputMaybe<StringFieldUpdateOperationsInput>;
  ltv_multiplier?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  min_required_data?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  variable_name?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type AlgoFactorUpdateWithWhereUniqueWithoutOrganizationInput = {
  data: AlgoFactorUpdateWithoutOrganizationInput;
  where: AlgoFactorWhereUniqueInput;
};

export type AlgoFactorUpsertWithWhereUniqueWithoutOrganizationInput = {
  create: AlgoFactorCreateWithoutOrganizationInput;
  update: AlgoFactorUpdateWithoutOrganizationInput;
  where: AlgoFactorWhereUniqueInput;
};

export type AlgoFactorWhereInput = {
  AND?: InputMaybe<Array<AlgoFactorWhereInput>>;
  current_data?: InputMaybe<IntNullableFilter>;
  HP_label?: InputMaybe<StringFilter>;
  HP_type?: InputMaybe<EnumHingepointFilter>;
  id?: InputMaybe<StringFilter>;
  is_custom_field?: InputMaybe<BoolFilter>;
  label?: InputMaybe<StringFilter>;
  ltv_multiplier?: InputMaybe<FloatNullableFilter>;
  min_required_data?: InputMaybe<IntNullableFilter>;
  NOT?: InputMaybe<Array<AlgoFactorWhereInput>>;
  OR?: InputMaybe<Array<AlgoFactorWhereInput>>;
  organization?: InputMaybe<OrganizationWhereInput>;
  organization_id?: InputMaybe<StringFilter>;
  rate?: InputMaybe<FloatNullableFilter>;
  variable_name?: InputMaybe<StringFilter>;
};

export type AlgoFactorWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']['input']>;
};

export type AllLeadBatchResult = {
  __typename?: 'AllLeadBatchResult';
  error_count?: Maybe<Scalars['Int']['output']>;
  errors?: Maybe<Array<Maybe<LeadBatchOperationError>>>;
  success_count?: Maybe<Scalars['Int']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

export enum Am_Type {
  EmailLinkClick = 'Email_Link_Click',
  EmailOpen = 'Email_Open',
  EmailReply = 'Email_Reply',
  SmsReply = 'SMS_REPLY'
}

export enum AssignmentOrigin {
  AssociatedContactSync = 'AssociatedContactSync',
  BulkClaimAllLeads = 'BulkClaimAllLeads',
  BulkUnassign = 'BulkUnassign',
  Claimed = 'Claimed',
  EnablementAssigned = 'EnablementAssigned',
  InboundConcierge = 'InboundConcierge',
  LeadRoutingRule = 'LeadRoutingRule',
  LiveTransfer = 'LiveTransfer',
  MovedToResting = 'MovedToResting',
  MovedToRetired = 'MovedToRetired',
  OpsiqAssigned = 'OpsiqAssigned',
  OpsiqClearFromQueue = 'OpsiqClearFromQueue',
  OpsiqUnassigned = 'OpsiqUnassigned',
  Other = 'Other',
  OwnedOnCsvImport = 'OwnedOnCSVImport',
  OwnedOnInboundCall = 'OwnedOnInboundCall',
  Reassigned = 'Reassigned',
  ReassignedAllLeads = 'ReassignedAllLeads',
  ReassignedLeadDetail = 'ReassignedLeadDetail',
  Released = 'Released',
  RulesOfEngagementAdjustment = 'RulesOfEngagementAdjustment',
  SalesforceAction = 'SalesforceAction',
  SdrNonLiveTransfer = 'SDRNonLiveTransfer',
  SelfSourced = 'SelfSourced',
  SequenceEndAction = 'SequenceEndAction',
  SingleClaim = 'SingleClaim',
  UnassignOnUserDelete = 'UnassignOnUserDelete'
}

export enum AssignType {
  DoNotAssign = 'DoNotAssign',
  OnlyAssign = 'OnlyAssign'
}

export type AssociationHistory = {
  __typename?: 'AssociationHistory';
  action: AssociationHistoryAction;
  associate_lead: Lead;
  associate_lead_id: Scalars['String']['output'];
  created_at: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  primary_lead?: Maybe<Lead>;
  primary_lead_id?: Maybe<Scalars['String']['output']>;
  rep?: Maybe<User>;
  rep_id?: Maybe<Scalars['String']['output']>;
  sibling_lead_ids: Array<Scalars['String']['output']>;
  sibling_leads: Array<Lead>;
  updated_at: Scalars['DateTime']['output'];
  user: User;
  user_id: Scalars['String']['output'];
};


export type AssociationHistorySibling_LeadsArgs = {
  after?: InputMaybe<LeadWhereUniqueInput>;
  before?: InputMaybe<LeadWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export enum AssociationHistoryAction {
  AssociateContactCreated = 'AssociateContactCreated',
  LeadAssociated = 'LeadAssociated',
  LeadDisassociated = 'LeadDisassociated',
  MakePrimary = 'MakePrimary'
}

export type AssociationHistoryCreateManyAssociate_LeadInput = {
  action: AssociationHistoryAction;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  organization_id?: InputMaybe<Scalars['String']['input']>;
  primary_lead_id?: InputMaybe<Scalars['String']['input']>;
  rep_id?: InputMaybe<Scalars['String']['input']>;
  sibling_lead_ids?: InputMaybe<AssociationHistoryCreateManysibling_Lead_IdsInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user_id: Scalars['String']['input'];
};

export type AssociationHistoryCreateManyAssociate_LeadInputEnvelope = {
  data?: InputMaybe<Array<AssociationHistoryCreateManyAssociate_LeadInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type AssociationHistoryCreateManyOrganizationInput = {
  action: AssociationHistoryAction;
  associate_lead_id: Scalars['String']['input'];
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  primary_lead_id?: InputMaybe<Scalars['String']['input']>;
  rep_id?: InputMaybe<Scalars['String']['input']>;
  sibling_lead_ids?: InputMaybe<AssociationHistoryCreateManysibling_Lead_IdsInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user_id: Scalars['String']['input'];
};

export type AssociationHistoryCreateManyOrganizationInputEnvelope = {
  data?: InputMaybe<Array<AssociationHistoryCreateManyOrganizationInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type AssociationHistoryCreateManyPrimary_LeadInput = {
  action: AssociationHistoryAction;
  associate_lead_id: Scalars['String']['input'];
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  organization_id?: InputMaybe<Scalars['String']['input']>;
  rep_id?: InputMaybe<Scalars['String']['input']>;
  sibling_lead_ids?: InputMaybe<AssociationHistoryCreateManysibling_Lead_IdsInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user_id: Scalars['String']['input'];
};

export type AssociationHistoryCreateManyPrimary_LeadInputEnvelope = {
  data?: InputMaybe<Array<AssociationHistoryCreateManyPrimary_LeadInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type AssociationHistoryCreateManyRepInput = {
  action: AssociationHistoryAction;
  associate_lead_id: Scalars['String']['input'];
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  organization_id?: InputMaybe<Scalars['String']['input']>;
  primary_lead_id?: InputMaybe<Scalars['String']['input']>;
  sibling_lead_ids?: InputMaybe<AssociationHistoryCreateManysibling_Lead_IdsInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user_id: Scalars['String']['input'];
};

export type AssociationHistoryCreateManyRepInputEnvelope = {
  data?: InputMaybe<Array<AssociationHistoryCreateManyRepInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type AssociationHistoryCreateManysibling_Lead_IdsInput = {
  set?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type AssociationHistoryCreateManyUserInput = {
  action: AssociationHistoryAction;
  associate_lead_id: Scalars['String']['input'];
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  organization_id?: InputMaybe<Scalars['String']['input']>;
  primary_lead_id?: InputMaybe<Scalars['String']['input']>;
  rep_id?: InputMaybe<Scalars['String']['input']>;
  sibling_lead_ids?: InputMaybe<AssociationHistoryCreateManysibling_Lead_IdsInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type AssociationHistoryCreateManyUserInputEnvelope = {
  data?: InputMaybe<Array<AssociationHistoryCreateManyUserInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type AssociationHistoryCreateNestedManyWithoutAssociate_LeadInput = {
  connect?: InputMaybe<Array<AssociationHistoryWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<AssociationHistoryCreateOrConnectWithoutAssociate_LeadInput>>;
  create?: InputMaybe<Array<AssociationHistoryCreateWithoutAssociate_LeadInput>>;
  createMany?: InputMaybe<AssociationHistoryCreateManyAssociate_LeadInputEnvelope>;
};

export type AssociationHistoryCreateNestedManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<AssociationHistoryWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<AssociationHistoryCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<AssociationHistoryCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<AssociationHistoryCreateManyOrganizationInputEnvelope>;
};

export type AssociationHistoryCreateNestedManyWithoutPrimary_LeadInput = {
  connect?: InputMaybe<Array<AssociationHistoryWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<AssociationHistoryCreateOrConnectWithoutPrimary_LeadInput>>;
  create?: InputMaybe<Array<AssociationHistoryCreateWithoutPrimary_LeadInput>>;
  createMany?: InputMaybe<AssociationHistoryCreateManyPrimary_LeadInputEnvelope>;
};

export type AssociationHistoryCreateNestedManyWithoutRepInput = {
  connect?: InputMaybe<Array<AssociationHistoryWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<AssociationHistoryCreateOrConnectWithoutRepInput>>;
  create?: InputMaybe<Array<AssociationHistoryCreateWithoutRepInput>>;
  createMany?: InputMaybe<AssociationHistoryCreateManyRepInputEnvelope>;
};

export type AssociationHistoryCreateNestedManyWithoutSibling_LeadsInput = {
  connect?: InputMaybe<Array<AssociationHistoryWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<AssociationHistoryCreateOrConnectWithoutSibling_LeadsInput>>;
  create?: InputMaybe<Array<AssociationHistoryCreateWithoutSibling_LeadsInput>>;
};

export type AssociationHistoryCreateNestedManyWithoutUserInput = {
  connect?: InputMaybe<Array<AssociationHistoryWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<AssociationHistoryCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<AssociationHistoryCreateWithoutUserInput>>;
  createMany?: InputMaybe<AssociationHistoryCreateManyUserInputEnvelope>;
};

export type AssociationHistoryCreateOrConnectWithoutAssociate_LeadInput = {
  create: AssociationHistoryCreateWithoutAssociate_LeadInput;
  where: AssociationHistoryWhereUniqueInput;
};

export type AssociationHistoryCreateOrConnectWithoutOrganizationInput = {
  create: AssociationHistoryCreateWithoutOrganizationInput;
  where: AssociationHistoryWhereUniqueInput;
};

export type AssociationHistoryCreateOrConnectWithoutPrimary_LeadInput = {
  create: AssociationHistoryCreateWithoutPrimary_LeadInput;
  where: AssociationHistoryWhereUniqueInput;
};

export type AssociationHistoryCreateOrConnectWithoutRepInput = {
  create: AssociationHistoryCreateWithoutRepInput;
  where: AssociationHistoryWhereUniqueInput;
};

export type AssociationHistoryCreateOrConnectWithoutSibling_LeadsInput = {
  create: AssociationHistoryCreateWithoutSibling_LeadsInput;
  where: AssociationHistoryWhereUniqueInput;
};

export type AssociationHistoryCreateOrConnectWithoutUserInput = {
  create: AssociationHistoryCreateWithoutUserInput;
  where: AssociationHistoryWhereUniqueInput;
};

export type AssociationHistoryCreatesibling_Lead_IdsInput = {
  set?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type AssociationHistoryCreateWithoutAssociate_LeadInput = {
  action: AssociationHistoryAction;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  Organization?: InputMaybe<OrganizationCreateNestedOneWithoutAssociationHistoriesInput>;
  primary_lead?: InputMaybe<LeadCreateNestedOneWithoutPrimary_Lead_HistoriesInput>;
  rep?: InputMaybe<UserCreateNestedOneWithoutAssociate_Owner_Rep_HistoryInput>;
  sibling_lead_ids?: InputMaybe<AssociationHistoryCreatesibling_Lead_IdsInput>;
  sibling_leads?: InputMaybe<LeadCreateNestedManyWithoutSibling_Lead_HistoriesInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user: UserCreateNestedOneWithoutAssociate_User_HistoryInput;
};

export type AssociationHistoryCreateWithoutOrganizationInput = {
  action: AssociationHistoryAction;
  associate_lead: LeadCreateNestedOneWithoutAssociate_Lead_HistoriesInput;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  primary_lead?: InputMaybe<LeadCreateNestedOneWithoutPrimary_Lead_HistoriesInput>;
  rep?: InputMaybe<UserCreateNestedOneWithoutAssociate_Owner_Rep_HistoryInput>;
  sibling_lead_ids?: InputMaybe<AssociationHistoryCreatesibling_Lead_IdsInput>;
  sibling_leads?: InputMaybe<LeadCreateNestedManyWithoutSibling_Lead_HistoriesInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user: UserCreateNestedOneWithoutAssociate_User_HistoryInput;
};

export type AssociationHistoryCreateWithoutPrimary_LeadInput = {
  action: AssociationHistoryAction;
  associate_lead: LeadCreateNestedOneWithoutAssociate_Lead_HistoriesInput;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  Organization?: InputMaybe<OrganizationCreateNestedOneWithoutAssociationHistoriesInput>;
  rep?: InputMaybe<UserCreateNestedOneWithoutAssociate_Owner_Rep_HistoryInput>;
  sibling_lead_ids?: InputMaybe<AssociationHistoryCreatesibling_Lead_IdsInput>;
  sibling_leads?: InputMaybe<LeadCreateNestedManyWithoutSibling_Lead_HistoriesInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user: UserCreateNestedOneWithoutAssociate_User_HistoryInput;
};

export type AssociationHistoryCreateWithoutRepInput = {
  action: AssociationHistoryAction;
  associate_lead: LeadCreateNestedOneWithoutAssociate_Lead_HistoriesInput;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  Organization?: InputMaybe<OrganizationCreateNestedOneWithoutAssociationHistoriesInput>;
  primary_lead?: InputMaybe<LeadCreateNestedOneWithoutPrimary_Lead_HistoriesInput>;
  sibling_lead_ids?: InputMaybe<AssociationHistoryCreatesibling_Lead_IdsInput>;
  sibling_leads?: InputMaybe<LeadCreateNestedManyWithoutSibling_Lead_HistoriesInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user: UserCreateNestedOneWithoutAssociate_User_HistoryInput;
};

export type AssociationHistoryCreateWithoutSibling_LeadsInput = {
  action: AssociationHistoryAction;
  associate_lead: LeadCreateNestedOneWithoutAssociate_Lead_HistoriesInput;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  Organization?: InputMaybe<OrganizationCreateNestedOneWithoutAssociationHistoriesInput>;
  primary_lead?: InputMaybe<LeadCreateNestedOneWithoutPrimary_Lead_HistoriesInput>;
  rep?: InputMaybe<UserCreateNestedOneWithoutAssociate_Owner_Rep_HistoryInput>;
  sibling_lead_ids?: InputMaybe<AssociationHistoryCreatesibling_Lead_IdsInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user: UserCreateNestedOneWithoutAssociate_User_HistoryInput;
};

export type AssociationHistoryCreateWithoutUserInput = {
  action: AssociationHistoryAction;
  associate_lead: LeadCreateNestedOneWithoutAssociate_Lead_HistoriesInput;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  Organization?: InputMaybe<OrganizationCreateNestedOneWithoutAssociationHistoriesInput>;
  primary_lead?: InputMaybe<LeadCreateNestedOneWithoutPrimary_Lead_HistoriesInput>;
  rep?: InputMaybe<UserCreateNestedOneWithoutAssociate_Owner_Rep_HistoryInput>;
  sibling_lead_ids?: InputMaybe<AssociationHistoryCreatesibling_Lead_IdsInput>;
  sibling_leads?: InputMaybe<LeadCreateNestedManyWithoutSibling_Lead_HistoriesInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type AssociationHistoryListRelationFilter = {
  every?: InputMaybe<AssociationHistoryWhereInput>;
  none?: InputMaybe<AssociationHistoryWhereInput>;
  some?: InputMaybe<AssociationHistoryWhereInput>;
};

export type AssociationHistoryOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
  count?: InputMaybe<SortOrder>;
};

export type AssociationHistoryScalarWhereInput = {
  action?: InputMaybe<EnumAssociationHistoryActionFilter>;
  AND?: InputMaybe<Array<AssociationHistoryScalarWhereInput>>;
  associate_lead_id?: InputMaybe<StringFilter>;
  created_at?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  NOT?: InputMaybe<Array<AssociationHistoryScalarWhereInput>>;
  OR?: InputMaybe<Array<AssociationHistoryScalarWhereInput>>;
  organization_id?: InputMaybe<StringNullableFilter>;
  primary_lead_id?: InputMaybe<StringNullableFilter>;
  rep_id?: InputMaybe<StringNullableFilter>;
  sibling_lead_ids?: InputMaybe<StringNullableListFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
  user_id?: InputMaybe<StringFilter>;
};

export type AssociationHistoryUpdateManyMutationInput = {
  action?: InputMaybe<EnumAssociationHistoryActionFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  sibling_lead_ids?: InputMaybe<AssociationHistoryUpdatesibling_Lead_IdsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type AssociationHistoryUpdateManyWithoutAssociate_LeadInput = {
  connect?: InputMaybe<Array<AssociationHistoryWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<AssociationHistoryCreateOrConnectWithoutAssociate_LeadInput>>;
  create?: InputMaybe<Array<AssociationHistoryCreateWithoutAssociate_LeadInput>>;
  createMany?: InputMaybe<AssociationHistoryCreateManyAssociate_LeadInputEnvelope>;
  delete?: InputMaybe<Array<AssociationHistoryWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<AssociationHistoryScalarWhereInput>>;
  disconnect?: InputMaybe<Array<AssociationHistoryWhereUniqueInput>>;
  set?: InputMaybe<Array<AssociationHistoryWhereUniqueInput>>;
  update?: InputMaybe<Array<AssociationHistoryUpdateWithWhereUniqueWithoutAssociate_LeadInput>>;
  updateMany?: InputMaybe<Array<AssociationHistoryUpdateManyWithWhereWithoutAssociate_LeadInput>>;
  upsert?: InputMaybe<Array<AssociationHistoryUpsertWithWhereUniqueWithoutAssociate_LeadInput>>;
};

export type AssociationHistoryUpdateManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<AssociationHistoryWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<AssociationHistoryCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<AssociationHistoryCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<AssociationHistoryCreateManyOrganizationInputEnvelope>;
  delete?: InputMaybe<Array<AssociationHistoryWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<AssociationHistoryScalarWhereInput>>;
  disconnect?: InputMaybe<Array<AssociationHistoryWhereUniqueInput>>;
  set?: InputMaybe<Array<AssociationHistoryWhereUniqueInput>>;
  update?: InputMaybe<Array<AssociationHistoryUpdateWithWhereUniqueWithoutOrganizationInput>>;
  updateMany?: InputMaybe<Array<AssociationHistoryUpdateManyWithWhereWithoutOrganizationInput>>;
  upsert?: InputMaybe<Array<AssociationHistoryUpsertWithWhereUniqueWithoutOrganizationInput>>;
};

export type AssociationHistoryUpdateManyWithoutPrimary_LeadInput = {
  connect?: InputMaybe<Array<AssociationHistoryWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<AssociationHistoryCreateOrConnectWithoutPrimary_LeadInput>>;
  create?: InputMaybe<Array<AssociationHistoryCreateWithoutPrimary_LeadInput>>;
  createMany?: InputMaybe<AssociationHistoryCreateManyPrimary_LeadInputEnvelope>;
  delete?: InputMaybe<Array<AssociationHistoryWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<AssociationHistoryScalarWhereInput>>;
  disconnect?: InputMaybe<Array<AssociationHistoryWhereUniqueInput>>;
  set?: InputMaybe<Array<AssociationHistoryWhereUniqueInput>>;
  update?: InputMaybe<Array<AssociationHistoryUpdateWithWhereUniqueWithoutPrimary_LeadInput>>;
  updateMany?: InputMaybe<Array<AssociationHistoryUpdateManyWithWhereWithoutPrimary_LeadInput>>;
  upsert?: InputMaybe<Array<AssociationHistoryUpsertWithWhereUniqueWithoutPrimary_LeadInput>>;
};

export type AssociationHistoryUpdateManyWithoutRepInput = {
  connect?: InputMaybe<Array<AssociationHistoryWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<AssociationHistoryCreateOrConnectWithoutRepInput>>;
  create?: InputMaybe<Array<AssociationHistoryCreateWithoutRepInput>>;
  createMany?: InputMaybe<AssociationHistoryCreateManyRepInputEnvelope>;
  delete?: InputMaybe<Array<AssociationHistoryWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<AssociationHistoryScalarWhereInput>>;
  disconnect?: InputMaybe<Array<AssociationHistoryWhereUniqueInput>>;
  set?: InputMaybe<Array<AssociationHistoryWhereUniqueInput>>;
  update?: InputMaybe<Array<AssociationHistoryUpdateWithWhereUniqueWithoutRepInput>>;
  updateMany?: InputMaybe<Array<AssociationHistoryUpdateManyWithWhereWithoutRepInput>>;
  upsert?: InputMaybe<Array<AssociationHistoryUpsertWithWhereUniqueWithoutRepInput>>;
};

export type AssociationHistoryUpdateManyWithoutSibling_LeadsInput = {
  connect?: InputMaybe<Array<AssociationHistoryWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<AssociationHistoryCreateOrConnectWithoutSibling_LeadsInput>>;
  create?: InputMaybe<Array<AssociationHistoryCreateWithoutSibling_LeadsInput>>;
  delete?: InputMaybe<Array<AssociationHistoryWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<AssociationHistoryScalarWhereInput>>;
  disconnect?: InputMaybe<Array<AssociationHistoryWhereUniqueInput>>;
  set?: InputMaybe<Array<AssociationHistoryWhereUniqueInput>>;
  update?: InputMaybe<Array<AssociationHistoryUpdateWithWhereUniqueWithoutSibling_LeadsInput>>;
  updateMany?: InputMaybe<Array<AssociationHistoryUpdateManyWithWhereWithoutSibling_LeadsInput>>;
  upsert?: InputMaybe<Array<AssociationHistoryUpsertWithWhereUniqueWithoutSibling_LeadsInput>>;
};

export type AssociationHistoryUpdateManyWithoutUserInput = {
  connect?: InputMaybe<Array<AssociationHistoryWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<AssociationHistoryCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<AssociationHistoryCreateWithoutUserInput>>;
  createMany?: InputMaybe<AssociationHistoryCreateManyUserInputEnvelope>;
  delete?: InputMaybe<Array<AssociationHistoryWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<AssociationHistoryScalarWhereInput>>;
  disconnect?: InputMaybe<Array<AssociationHistoryWhereUniqueInput>>;
  set?: InputMaybe<Array<AssociationHistoryWhereUniqueInput>>;
  update?: InputMaybe<Array<AssociationHistoryUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: InputMaybe<Array<AssociationHistoryUpdateManyWithWhereWithoutUserInput>>;
  upsert?: InputMaybe<Array<AssociationHistoryUpsertWithWhereUniqueWithoutUserInput>>;
};

export type AssociationHistoryUpdateManyWithWhereWithoutAssociate_LeadInput = {
  data: AssociationHistoryUpdateManyMutationInput;
  where: AssociationHistoryScalarWhereInput;
};

export type AssociationHistoryUpdateManyWithWhereWithoutOrganizationInput = {
  data: AssociationHistoryUpdateManyMutationInput;
  where: AssociationHistoryScalarWhereInput;
};

export type AssociationHistoryUpdateManyWithWhereWithoutPrimary_LeadInput = {
  data: AssociationHistoryUpdateManyMutationInput;
  where: AssociationHistoryScalarWhereInput;
};

export type AssociationHistoryUpdateManyWithWhereWithoutRepInput = {
  data: AssociationHistoryUpdateManyMutationInput;
  where: AssociationHistoryScalarWhereInput;
};

export type AssociationHistoryUpdateManyWithWhereWithoutSibling_LeadsInput = {
  data: AssociationHistoryUpdateManyMutationInput;
  where: AssociationHistoryScalarWhereInput;
};

export type AssociationHistoryUpdateManyWithWhereWithoutUserInput = {
  data: AssociationHistoryUpdateManyMutationInput;
  where: AssociationHistoryScalarWhereInput;
};

export type AssociationHistoryUpdatesibling_Lead_IdsInput = {
  push?: InputMaybe<Scalars['String']['input']>;
  set?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type AssociationHistoryUpdateWithoutAssociate_LeadInput = {
  action?: InputMaybe<EnumAssociationHistoryActionFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  Organization?: InputMaybe<OrganizationUpdateOneWithoutAssociationHistoriesInput>;
  primary_lead?: InputMaybe<LeadUpdateOneWithoutPrimary_Lead_HistoriesInput>;
  rep?: InputMaybe<UserUpdateOneWithoutAssociate_Owner_Rep_HistoryInput>;
  sibling_lead_ids?: InputMaybe<AssociationHistoryUpdatesibling_Lead_IdsInput>;
  sibling_leads?: InputMaybe<LeadUpdateManyWithoutSibling_Lead_HistoriesInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutAssociate_User_HistoryInput>;
};

export type AssociationHistoryUpdateWithoutOrganizationInput = {
  action?: InputMaybe<EnumAssociationHistoryActionFieldUpdateOperationsInput>;
  associate_lead?: InputMaybe<LeadUpdateOneRequiredWithoutAssociate_Lead_HistoriesInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  primary_lead?: InputMaybe<LeadUpdateOneWithoutPrimary_Lead_HistoriesInput>;
  rep?: InputMaybe<UserUpdateOneWithoutAssociate_Owner_Rep_HistoryInput>;
  sibling_lead_ids?: InputMaybe<AssociationHistoryUpdatesibling_Lead_IdsInput>;
  sibling_leads?: InputMaybe<LeadUpdateManyWithoutSibling_Lead_HistoriesInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutAssociate_User_HistoryInput>;
};

export type AssociationHistoryUpdateWithoutPrimary_LeadInput = {
  action?: InputMaybe<EnumAssociationHistoryActionFieldUpdateOperationsInput>;
  associate_lead?: InputMaybe<LeadUpdateOneRequiredWithoutAssociate_Lead_HistoriesInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  Organization?: InputMaybe<OrganizationUpdateOneWithoutAssociationHistoriesInput>;
  rep?: InputMaybe<UserUpdateOneWithoutAssociate_Owner_Rep_HistoryInput>;
  sibling_lead_ids?: InputMaybe<AssociationHistoryUpdatesibling_Lead_IdsInput>;
  sibling_leads?: InputMaybe<LeadUpdateManyWithoutSibling_Lead_HistoriesInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutAssociate_User_HistoryInput>;
};

export type AssociationHistoryUpdateWithoutRepInput = {
  action?: InputMaybe<EnumAssociationHistoryActionFieldUpdateOperationsInput>;
  associate_lead?: InputMaybe<LeadUpdateOneRequiredWithoutAssociate_Lead_HistoriesInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  Organization?: InputMaybe<OrganizationUpdateOneWithoutAssociationHistoriesInput>;
  primary_lead?: InputMaybe<LeadUpdateOneWithoutPrimary_Lead_HistoriesInput>;
  sibling_lead_ids?: InputMaybe<AssociationHistoryUpdatesibling_Lead_IdsInput>;
  sibling_leads?: InputMaybe<LeadUpdateManyWithoutSibling_Lead_HistoriesInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutAssociate_User_HistoryInput>;
};

export type AssociationHistoryUpdateWithoutSibling_LeadsInput = {
  action?: InputMaybe<EnumAssociationHistoryActionFieldUpdateOperationsInput>;
  associate_lead?: InputMaybe<LeadUpdateOneRequiredWithoutAssociate_Lead_HistoriesInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  Organization?: InputMaybe<OrganizationUpdateOneWithoutAssociationHistoriesInput>;
  primary_lead?: InputMaybe<LeadUpdateOneWithoutPrimary_Lead_HistoriesInput>;
  rep?: InputMaybe<UserUpdateOneWithoutAssociate_Owner_Rep_HistoryInput>;
  sibling_lead_ids?: InputMaybe<AssociationHistoryUpdatesibling_Lead_IdsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutAssociate_User_HistoryInput>;
};

export type AssociationHistoryUpdateWithoutUserInput = {
  action?: InputMaybe<EnumAssociationHistoryActionFieldUpdateOperationsInput>;
  associate_lead?: InputMaybe<LeadUpdateOneRequiredWithoutAssociate_Lead_HistoriesInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  Organization?: InputMaybe<OrganizationUpdateOneWithoutAssociationHistoriesInput>;
  primary_lead?: InputMaybe<LeadUpdateOneWithoutPrimary_Lead_HistoriesInput>;
  rep?: InputMaybe<UserUpdateOneWithoutAssociate_Owner_Rep_HistoryInput>;
  sibling_lead_ids?: InputMaybe<AssociationHistoryUpdatesibling_Lead_IdsInput>;
  sibling_leads?: InputMaybe<LeadUpdateManyWithoutSibling_Lead_HistoriesInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type AssociationHistoryUpdateWithWhereUniqueWithoutAssociate_LeadInput = {
  data: AssociationHistoryUpdateWithoutAssociate_LeadInput;
  where: AssociationHistoryWhereUniqueInput;
};

export type AssociationHistoryUpdateWithWhereUniqueWithoutOrganizationInput = {
  data: AssociationHistoryUpdateWithoutOrganizationInput;
  where: AssociationHistoryWhereUniqueInput;
};

export type AssociationHistoryUpdateWithWhereUniqueWithoutPrimary_LeadInput = {
  data: AssociationHistoryUpdateWithoutPrimary_LeadInput;
  where: AssociationHistoryWhereUniqueInput;
};

export type AssociationHistoryUpdateWithWhereUniqueWithoutRepInput = {
  data: AssociationHistoryUpdateWithoutRepInput;
  where: AssociationHistoryWhereUniqueInput;
};

export type AssociationHistoryUpdateWithWhereUniqueWithoutSibling_LeadsInput = {
  data: AssociationHistoryUpdateWithoutSibling_LeadsInput;
  where: AssociationHistoryWhereUniqueInput;
};

export type AssociationHistoryUpdateWithWhereUniqueWithoutUserInput = {
  data: AssociationHistoryUpdateWithoutUserInput;
  where: AssociationHistoryWhereUniqueInput;
};

export type AssociationHistoryUpsertWithWhereUniqueWithoutAssociate_LeadInput = {
  create: AssociationHistoryCreateWithoutAssociate_LeadInput;
  update: AssociationHistoryUpdateWithoutAssociate_LeadInput;
  where: AssociationHistoryWhereUniqueInput;
};

export type AssociationHistoryUpsertWithWhereUniqueWithoutOrganizationInput = {
  create: AssociationHistoryCreateWithoutOrganizationInput;
  update: AssociationHistoryUpdateWithoutOrganizationInput;
  where: AssociationHistoryWhereUniqueInput;
};

export type AssociationHistoryUpsertWithWhereUniqueWithoutPrimary_LeadInput = {
  create: AssociationHistoryCreateWithoutPrimary_LeadInput;
  update: AssociationHistoryUpdateWithoutPrimary_LeadInput;
  where: AssociationHistoryWhereUniqueInput;
};

export type AssociationHistoryUpsertWithWhereUniqueWithoutRepInput = {
  create: AssociationHistoryCreateWithoutRepInput;
  update: AssociationHistoryUpdateWithoutRepInput;
  where: AssociationHistoryWhereUniqueInput;
};

export type AssociationHistoryUpsertWithWhereUniqueWithoutSibling_LeadsInput = {
  create: AssociationHistoryCreateWithoutSibling_LeadsInput;
  update: AssociationHistoryUpdateWithoutSibling_LeadsInput;
  where: AssociationHistoryWhereUniqueInput;
};

export type AssociationHistoryUpsertWithWhereUniqueWithoutUserInput = {
  create: AssociationHistoryCreateWithoutUserInput;
  update: AssociationHistoryUpdateWithoutUserInput;
  where: AssociationHistoryWhereUniqueInput;
};

export type AssociationHistoryWhereInput = {
  action?: InputMaybe<EnumAssociationHistoryActionFilter>;
  AND?: InputMaybe<Array<AssociationHistoryWhereInput>>;
  associate_lead?: InputMaybe<LeadWhereInput>;
  associate_lead_id?: InputMaybe<StringFilter>;
  created_at?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  NOT?: InputMaybe<Array<AssociationHistoryWhereInput>>;
  OR?: InputMaybe<Array<AssociationHistoryWhereInput>>;
  Organization?: InputMaybe<OrganizationWhereInput>;
  organization_id?: InputMaybe<StringNullableFilter>;
  primary_lead?: InputMaybe<LeadWhereInput>;
  primary_lead_id?: InputMaybe<StringNullableFilter>;
  rep?: InputMaybe<UserWhereInput>;
  rep_id?: InputMaybe<StringNullableFilter>;
  sibling_lead_ids?: InputMaybe<StringNullableListFilter>;
  sibling_leads?: InputMaybe<LeadListRelationFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserWhereInput>;
  user_id?: InputMaybe<StringFilter>;
};

export type AssociationHistoryWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']['input']>;
};

export type AudioUpload = {
  __typename?: 'AudioUpload';
  filename?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type AutomatedCampaign = {
  __typename?: 'AutomatedCampaign';
  active?: Maybe<Scalars['Boolean']['output']>;
  body?: Maybe<Scalars['String']['output']>;
  event: Scalars['String']['output'];
  id: Scalars['String']['output'];
  increment?: Maybe<Scalars['Int']['output']>;
  increment_multiplier?: Maybe<Scalars['Int']['output']>;
  lead_activities: Array<LeadActivity>;
  method_email?: Maybe<Scalars['Boolean']['output']>;
  method_sms?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  organization?: Maybe<Organization>;
  organization_id?: Maybe<Scalars['String']['output']>;
  schedule_items: Array<ScheduleItem>;
  subject?: Maybe<Scalars['String']['output']>;
  template_id?: Maybe<Scalars['String']['output']>;
  when?: Maybe<When>;
};


export type AutomatedCampaignLead_ActivitiesArgs = {
  after?: InputMaybe<LeadActivityWhereUniqueInput>;
  before?: InputMaybe<LeadActivityWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type AutomatedCampaignSchedule_ItemsArgs = {
  after?: InputMaybe<ScheduleItemWhereUniqueInput>;
  before?: InputMaybe<ScheduleItemWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type AutomatedCampaignCreateManyOrganizationInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  body?: InputMaybe<Scalars['String']['input']>;
  deleted_at?: InputMaybe<Scalars['DateTime']['input']>;
  event: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  increment?: InputMaybe<Scalars['Int']['input']>;
  increment_multiplier?: InputMaybe<Scalars['Int']['input']>;
  method_email?: InputMaybe<Scalars['Boolean']['input']>;
  method_sms?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  subject?: InputMaybe<Scalars['String']['input']>;
  template_id?: InputMaybe<Scalars['String']['input']>;
  when?: InputMaybe<When>;
};

export type AutomatedCampaignCreateManyOrganizationInputEnvelope = {
  data?: InputMaybe<Array<AutomatedCampaignCreateManyOrganizationInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type AutomatedCampaignCreateManyTemplateInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  body?: InputMaybe<Scalars['String']['input']>;
  deleted_at?: InputMaybe<Scalars['DateTime']['input']>;
  event: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  increment?: InputMaybe<Scalars['Int']['input']>;
  increment_multiplier?: InputMaybe<Scalars['Int']['input']>;
  method_email?: InputMaybe<Scalars['Boolean']['input']>;
  method_sms?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organization_id?: InputMaybe<Scalars['String']['input']>;
  subject?: InputMaybe<Scalars['String']['input']>;
  when?: InputMaybe<When>;
};

export type AutomatedCampaignCreateManyTemplateInputEnvelope = {
  data?: InputMaybe<Array<AutomatedCampaignCreateManyTemplateInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type AutomatedCampaignCreateNestedManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<AutomatedCampaignWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<AutomatedCampaignCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<AutomatedCampaignCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<AutomatedCampaignCreateManyOrganizationInputEnvelope>;
};

export type AutomatedCampaignCreateNestedManyWithoutSchedule_ItemsInput = {
  connect?: InputMaybe<Array<AutomatedCampaignWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<AutomatedCampaignCreateOrConnectWithoutSchedule_ItemsInput>>;
  create?: InputMaybe<Array<AutomatedCampaignCreateWithoutSchedule_ItemsInput>>;
};

export type AutomatedCampaignCreateNestedManyWithoutTemplateInput = {
  connect?: InputMaybe<Array<AutomatedCampaignWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<AutomatedCampaignCreateOrConnectWithoutTemplateInput>>;
  create?: InputMaybe<Array<AutomatedCampaignCreateWithoutTemplateInput>>;
  createMany?: InputMaybe<AutomatedCampaignCreateManyTemplateInputEnvelope>;
};

export type AutomatedCampaignCreateNestedOneWithoutLead_ActivitiesInput = {
  connect?: InputMaybe<AutomatedCampaignWhereUniqueInput>;
  connectOrCreate?: InputMaybe<AutomatedCampaignCreateOrConnectWithoutLead_ActivitiesInput>;
  create?: InputMaybe<AutomatedCampaignCreateWithoutLead_ActivitiesInput>;
};

export type AutomatedCampaignCreateOrConnectWithoutLead_ActivitiesInput = {
  create: AutomatedCampaignCreateWithoutLead_ActivitiesInput;
  where: AutomatedCampaignWhereUniqueInput;
};

export type AutomatedCampaignCreateOrConnectWithoutOrganizationInput = {
  create: AutomatedCampaignCreateWithoutOrganizationInput;
  where: AutomatedCampaignWhereUniqueInput;
};

export type AutomatedCampaignCreateOrConnectWithoutSchedule_ItemsInput = {
  create: AutomatedCampaignCreateWithoutSchedule_ItemsInput;
  where: AutomatedCampaignWhereUniqueInput;
};

export type AutomatedCampaignCreateOrConnectWithoutTemplateInput = {
  create: AutomatedCampaignCreateWithoutTemplateInput;
  where: AutomatedCampaignWhereUniqueInput;
};

export type AutomatedCampaignCreateWithoutLead_ActivitiesInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  body?: InputMaybe<Scalars['String']['input']>;
  deleted_at?: InputMaybe<Scalars['DateTime']['input']>;
  event: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  increment?: InputMaybe<Scalars['Int']['input']>;
  increment_multiplier?: InputMaybe<Scalars['Int']['input']>;
  method_email?: InputMaybe<Scalars['Boolean']['input']>;
  method_sms?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organization?: InputMaybe<OrganizationCreateNestedOneWithoutAutomated_CampaignsInput>;
  schedule_items?: InputMaybe<ScheduleItemCreateNestedManyWithoutAutomated_CampaignsInput>;
  subject?: InputMaybe<Scalars['String']['input']>;
  template?: InputMaybe<TemplateCreateNestedOneWithoutAutomatedCampaignInput>;
  when?: InputMaybe<When>;
};

export type AutomatedCampaignCreateWithoutOrganizationInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  body?: InputMaybe<Scalars['String']['input']>;
  deleted_at?: InputMaybe<Scalars['DateTime']['input']>;
  event: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  increment?: InputMaybe<Scalars['Int']['input']>;
  increment_multiplier?: InputMaybe<Scalars['Int']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutAutomated_CampaignInput>;
  method_email?: InputMaybe<Scalars['Boolean']['input']>;
  method_sms?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  schedule_items?: InputMaybe<ScheduleItemCreateNestedManyWithoutAutomated_CampaignsInput>;
  subject?: InputMaybe<Scalars['String']['input']>;
  template?: InputMaybe<TemplateCreateNestedOneWithoutAutomatedCampaignInput>;
  when?: InputMaybe<When>;
};

export type AutomatedCampaignCreateWithoutSchedule_ItemsInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  body?: InputMaybe<Scalars['String']['input']>;
  deleted_at?: InputMaybe<Scalars['DateTime']['input']>;
  event: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  increment?: InputMaybe<Scalars['Int']['input']>;
  increment_multiplier?: InputMaybe<Scalars['Int']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutAutomated_CampaignInput>;
  method_email?: InputMaybe<Scalars['Boolean']['input']>;
  method_sms?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organization?: InputMaybe<OrganizationCreateNestedOneWithoutAutomated_CampaignsInput>;
  subject?: InputMaybe<Scalars['String']['input']>;
  template?: InputMaybe<TemplateCreateNestedOneWithoutAutomatedCampaignInput>;
  when?: InputMaybe<When>;
};

export type AutomatedCampaignCreateWithoutTemplateInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  body?: InputMaybe<Scalars['String']['input']>;
  deleted_at?: InputMaybe<Scalars['DateTime']['input']>;
  event: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  increment?: InputMaybe<Scalars['Int']['input']>;
  increment_multiplier?: InputMaybe<Scalars['Int']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutAutomated_CampaignInput>;
  method_email?: InputMaybe<Scalars['Boolean']['input']>;
  method_sms?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organization?: InputMaybe<OrganizationCreateNestedOneWithoutAutomated_CampaignsInput>;
  schedule_items?: InputMaybe<ScheduleItemCreateNestedManyWithoutAutomated_CampaignsInput>;
  subject?: InputMaybe<Scalars['String']['input']>;
  when?: InputMaybe<When>;
};

export type AutomatedCampaignListRelationFilter = {
  every?: InputMaybe<AutomatedCampaignWhereInput>;
  none?: InputMaybe<AutomatedCampaignWhereInput>;
  some?: InputMaybe<AutomatedCampaignWhereInput>;
};

export type AutomatedCampaignLog = {
  __typename?: 'AutomatedCampaignLog';
  automated_campaign_id: Scalars['String']['output'];
  dml_created_by?: Maybe<Scalars['String']['output']>;
  dml_timestamp: Scalars['DateTime']['output'];
  dml_type: Dml_Type;
  new_row_data?: Maybe<Scalars['Json']['output']>;
  old_row_data?: Maybe<Scalars['Json']['output']>;
};

export type AutomatedCampaignOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
  count?: InputMaybe<SortOrder>;
};

export type AutomatedCampaignOrderByWithRelationInput = {
  active?: InputMaybe<SortOrder>;
  body?: InputMaybe<SortOrder>;
  deleted_at?: InputMaybe<SortOrder>;
  event?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  increment?: InputMaybe<SortOrder>;
  increment_multiplier?: InputMaybe<SortOrder>;
  lead_activities?: InputMaybe<LeadActivityOrderByRelationAggregateInput>;
  method_email?: InputMaybe<SortOrder>;
  method_sms?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  organization?: InputMaybe<OrganizationOrderByWithRelationInput>;
  organization_id?: InputMaybe<SortOrder>;
  schedule_items?: InputMaybe<ScheduleItemOrderByRelationAggregateInput>;
  subject?: InputMaybe<SortOrder>;
  template?: InputMaybe<TemplateOrderByWithRelationInput>;
  template_id?: InputMaybe<SortOrder>;
  when?: InputMaybe<SortOrder>;
};

export type AutomatedCampaignScalarWhereInput = {
  active?: InputMaybe<BoolNullableFilter>;
  AND?: InputMaybe<Array<AutomatedCampaignScalarWhereInput>>;
  body?: InputMaybe<StringNullableFilter>;
  deleted_at?: InputMaybe<DateTimeNullableFilter>;
  event?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  increment?: InputMaybe<IntNullableFilter>;
  increment_multiplier?: InputMaybe<IntNullableFilter>;
  method_email?: InputMaybe<BoolNullableFilter>;
  method_sms?: InputMaybe<BoolNullableFilter>;
  name?: InputMaybe<StringNullableFilter>;
  NOT?: InputMaybe<Array<AutomatedCampaignScalarWhereInput>>;
  OR?: InputMaybe<Array<AutomatedCampaignScalarWhereInput>>;
  organization_id?: InputMaybe<StringNullableFilter>;
  subject?: InputMaybe<StringNullableFilter>;
  template_id?: InputMaybe<StringNullableFilter>;
  when?: InputMaybe<EnumWhenNullableFilter>;
};

export type AutomatedCampaignUpdateManyMutationInput = {
  active?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  body?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  deleted_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  event?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  increment?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  increment_multiplier?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  method_email?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  method_sms?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  subject?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  when?: InputMaybe<NullableEnumWhenFieldUpdateOperationsInput>;
};

export type AutomatedCampaignUpdateManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<AutomatedCampaignWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<AutomatedCampaignCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<AutomatedCampaignCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<AutomatedCampaignCreateManyOrganizationInputEnvelope>;
  delete?: InputMaybe<Array<AutomatedCampaignWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<AutomatedCampaignScalarWhereInput>>;
  disconnect?: InputMaybe<Array<AutomatedCampaignWhereUniqueInput>>;
  set?: InputMaybe<Array<AutomatedCampaignWhereUniqueInput>>;
  update?: InputMaybe<Array<AutomatedCampaignUpdateWithWhereUniqueWithoutOrganizationInput>>;
  updateMany?: InputMaybe<Array<AutomatedCampaignUpdateManyWithWhereWithoutOrganizationInput>>;
  upsert?: InputMaybe<Array<AutomatedCampaignUpsertWithWhereUniqueWithoutOrganizationInput>>;
};

export type AutomatedCampaignUpdateManyWithoutSchedule_ItemsInput = {
  connect?: InputMaybe<Array<AutomatedCampaignWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<AutomatedCampaignCreateOrConnectWithoutSchedule_ItemsInput>>;
  create?: InputMaybe<Array<AutomatedCampaignCreateWithoutSchedule_ItemsInput>>;
  delete?: InputMaybe<Array<AutomatedCampaignWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<AutomatedCampaignScalarWhereInput>>;
  disconnect?: InputMaybe<Array<AutomatedCampaignWhereUniqueInput>>;
  set?: InputMaybe<Array<AutomatedCampaignWhereUniqueInput>>;
  update?: InputMaybe<Array<AutomatedCampaignUpdateWithWhereUniqueWithoutSchedule_ItemsInput>>;
  updateMany?: InputMaybe<Array<AutomatedCampaignUpdateManyWithWhereWithoutSchedule_ItemsInput>>;
  upsert?: InputMaybe<Array<AutomatedCampaignUpsertWithWhereUniqueWithoutSchedule_ItemsInput>>;
};

export type AutomatedCampaignUpdateManyWithoutTemplateInput = {
  connect?: InputMaybe<Array<AutomatedCampaignWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<AutomatedCampaignCreateOrConnectWithoutTemplateInput>>;
  create?: InputMaybe<Array<AutomatedCampaignCreateWithoutTemplateInput>>;
  createMany?: InputMaybe<AutomatedCampaignCreateManyTemplateInputEnvelope>;
  delete?: InputMaybe<Array<AutomatedCampaignWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<AutomatedCampaignScalarWhereInput>>;
  disconnect?: InputMaybe<Array<AutomatedCampaignWhereUniqueInput>>;
  set?: InputMaybe<Array<AutomatedCampaignWhereUniqueInput>>;
  update?: InputMaybe<Array<AutomatedCampaignUpdateWithWhereUniqueWithoutTemplateInput>>;
  updateMany?: InputMaybe<Array<AutomatedCampaignUpdateManyWithWhereWithoutTemplateInput>>;
  upsert?: InputMaybe<Array<AutomatedCampaignUpsertWithWhereUniqueWithoutTemplateInput>>;
};

export type AutomatedCampaignUpdateManyWithWhereWithoutOrganizationInput = {
  data: AutomatedCampaignUpdateManyMutationInput;
  where: AutomatedCampaignScalarWhereInput;
};

export type AutomatedCampaignUpdateManyWithWhereWithoutSchedule_ItemsInput = {
  data: AutomatedCampaignUpdateManyMutationInput;
  where: AutomatedCampaignScalarWhereInput;
};

export type AutomatedCampaignUpdateManyWithWhereWithoutTemplateInput = {
  data: AutomatedCampaignUpdateManyMutationInput;
  where: AutomatedCampaignScalarWhereInput;
};

export type AutomatedCampaignUpdateOneWithoutLead_ActivitiesInput = {
  connect?: InputMaybe<AutomatedCampaignWhereUniqueInput>;
  connectOrCreate?: InputMaybe<AutomatedCampaignCreateOrConnectWithoutLead_ActivitiesInput>;
  create?: InputMaybe<AutomatedCampaignCreateWithoutLead_ActivitiesInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<AutomatedCampaignUpdateWithoutLead_ActivitiesInput>;
  upsert?: InputMaybe<AutomatedCampaignUpsertWithoutLead_ActivitiesInput>;
};

export type AutomatedCampaignUpdateWithoutLead_ActivitiesInput = {
  active?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  body?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  deleted_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  event?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  increment?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  increment_multiplier?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  method_email?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  method_sms?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneWithoutAutomated_CampaignsInput>;
  schedule_items?: InputMaybe<ScheduleItemUpdateManyWithoutAutomated_CampaignsInput>;
  subject?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  template?: InputMaybe<TemplateUpdateOneWithoutAutomatedCampaignInput>;
  when?: InputMaybe<NullableEnumWhenFieldUpdateOperationsInput>;
};

export type AutomatedCampaignUpdateWithoutOrganizationInput = {
  active?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  body?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  deleted_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  event?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  increment?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  increment_multiplier?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutAutomated_CampaignInput>;
  method_email?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  method_sms?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  schedule_items?: InputMaybe<ScheduleItemUpdateManyWithoutAutomated_CampaignsInput>;
  subject?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  template?: InputMaybe<TemplateUpdateOneWithoutAutomatedCampaignInput>;
  when?: InputMaybe<NullableEnumWhenFieldUpdateOperationsInput>;
};

export type AutomatedCampaignUpdateWithoutSchedule_ItemsInput = {
  active?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  body?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  deleted_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  event?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  increment?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  increment_multiplier?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutAutomated_CampaignInput>;
  method_email?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  method_sms?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneWithoutAutomated_CampaignsInput>;
  subject?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  template?: InputMaybe<TemplateUpdateOneWithoutAutomatedCampaignInput>;
  when?: InputMaybe<NullableEnumWhenFieldUpdateOperationsInput>;
};

export type AutomatedCampaignUpdateWithoutTemplateInput = {
  active?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  body?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  deleted_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  event?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  increment?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  increment_multiplier?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutAutomated_CampaignInput>;
  method_email?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  method_sms?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneWithoutAutomated_CampaignsInput>;
  schedule_items?: InputMaybe<ScheduleItemUpdateManyWithoutAutomated_CampaignsInput>;
  subject?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  when?: InputMaybe<NullableEnumWhenFieldUpdateOperationsInput>;
};

export type AutomatedCampaignUpdateWithWhereUniqueWithoutOrganizationInput = {
  data: AutomatedCampaignUpdateWithoutOrganizationInput;
  where: AutomatedCampaignWhereUniqueInput;
};

export type AutomatedCampaignUpdateWithWhereUniqueWithoutSchedule_ItemsInput = {
  data: AutomatedCampaignUpdateWithoutSchedule_ItemsInput;
  where: AutomatedCampaignWhereUniqueInput;
};

export type AutomatedCampaignUpdateWithWhereUniqueWithoutTemplateInput = {
  data: AutomatedCampaignUpdateWithoutTemplateInput;
  where: AutomatedCampaignWhereUniqueInput;
};

export type AutomatedCampaignUpsertWithoutLead_ActivitiesInput = {
  create: AutomatedCampaignCreateWithoutLead_ActivitiesInput;
  update: AutomatedCampaignUpdateWithoutLead_ActivitiesInput;
};

export type AutomatedCampaignUpsertWithWhereUniqueWithoutOrganizationInput = {
  create: AutomatedCampaignCreateWithoutOrganizationInput;
  update: AutomatedCampaignUpdateWithoutOrganizationInput;
  where: AutomatedCampaignWhereUniqueInput;
};

export type AutomatedCampaignUpsertWithWhereUniqueWithoutSchedule_ItemsInput = {
  create: AutomatedCampaignCreateWithoutSchedule_ItemsInput;
  update: AutomatedCampaignUpdateWithoutSchedule_ItemsInput;
  where: AutomatedCampaignWhereUniqueInput;
};

export type AutomatedCampaignUpsertWithWhereUniqueWithoutTemplateInput = {
  create: AutomatedCampaignCreateWithoutTemplateInput;
  update: AutomatedCampaignUpdateWithoutTemplateInput;
  where: AutomatedCampaignWhereUniqueInput;
};

export type AutomatedCampaignWhereInput = {
  active?: InputMaybe<BoolNullableFilter>;
  AND?: InputMaybe<Array<AutomatedCampaignWhereInput>>;
  body?: InputMaybe<StringNullableFilter>;
  deleted_at?: InputMaybe<DateTimeNullableFilter>;
  event?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  increment?: InputMaybe<IntNullableFilter>;
  increment_multiplier?: InputMaybe<IntNullableFilter>;
  lead_activities?: InputMaybe<LeadActivityListRelationFilter>;
  method_email?: InputMaybe<BoolNullableFilter>;
  method_sms?: InputMaybe<BoolNullableFilter>;
  name?: InputMaybe<StringNullableFilter>;
  NOT?: InputMaybe<Array<AutomatedCampaignWhereInput>>;
  OR?: InputMaybe<Array<AutomatedCampaignWhereInput>>;
  organization?: InputMaybe<OrganizationWhereInput>;
  organization_id?: InputMaybe<StringNullableFilter>;
  schedule_items?: InputMaybe<ScheduleItemListRelationFilter>;
  subject?: InputMaybe<StringNullableFilter>;
  template?: InputMaybe<TemplateWhereInput>;
  template_id?: InputMaybe<StringNullableFilter>;
  when?: InputMaybe<EnumWhenNullableFilter>;
};

export type AutomatedCampaignWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']['input']>;
};

export enum AutoSelectionType {
  Schedule = 'Schedule',
  Transfer = 'Transfer'
}

export type AutoTransferSelection = {
  __typename?: 'AutoTransferSelection';
  conference?: Maybe<Conference>;
  conference_id?: Maybe<Scalars['String']['output']>;
  created_at: Scalars['DateTime']['output'];
  from_user?: Maybe<User>;
  from_user_id?: Maybe<Scalars['String']['output']>;
  global_rule_available_aes?: Maybe<Scalars['Json']['output']>;
  id: Scalars['String']['output'];
  matched_rule?: Maybe<Rule>;
  matched_rule_available_aes?: Maybe<Scalars['Json']['output']>;
  rule_id?: Maybe<Scalars['String']['output']>;
  to_user?: Maybe<User>;
  to_user_id?: Maybe<Scalars['String']['output']>;
  transfer_attempt?: Maybe<TransferAttempt>;
  transfer_attempt_id?: Maybe<Scalars['String']['output']>;
  type: AutoSelectionType;
  updated_at: Scalars['DateTime']['output'];
};

export type AutoTransferSelectionCreateManyConferenceInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  cycle_attempt_number?: InputMaybe<Scalars['Int']['input']>;
  from_user_id?: InputMaybe<Scalars['String']['input']>;
  global_rule_available_aes?: InputMaybe<Scalars['Json']['input']>;
  global_rule_raw?: InputMaybe<Scalars['Json']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  matched_rule_available_aes?: InputMaybe<Scalars['Json']['input']>;
  matched_rule_raw?: InputMaybe<Scalars['Json']['input']>;
  organization_id?: InputMaybe<Scalars['String']['input']>;
  rule_id?: InputMaybe<Scalars['String']['input']>;
  to_user_id?: InputMaybe<Scalars['String']['input']>;
  transfer_attempt_id?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<AutoSelectionType>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type AutoTransferSelectionCreateManyConferenceInputEnvelope = {
  data?: InputMaybe<Array<AutoTransferSelectionCreateManyConferenceInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type AutoTransferSelectionCreateManyFrom_UserInput = {
  conference_id?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  cycle_attempt_number?: InputMaybe<Scalars['Int']['input']>;
  global_rule_available_aes?: InputMaybe<Scalars['Json']['input']>;
  global_rule_raw?: InputMaybe<Scalars['Json']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  matched_rule_available_aes?: InputMaybe<Scalars['Json']['input']>;
  matched_rule_raw?: InputMaybe<Scalars['Json']['input']>;
  organization_id?: InputMaybe<Scalars['String']['input']>;
  rule_id?: InputMaybe<Scalars['String']['input']>;
  to_user_id?: InputMaybe<Scalars['String']['input']>;
  transfer_attempt_id?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<AutoSelectionType>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type AutoTransferSelectionCreateManyFrom_UserInputEnvelope = {
  data?: InputMaybe<Array<AutoTransferSelectionCreateManyFrom_UserInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type AutoTransferSelectionCreateManyMatched_RuleInput = {
  conference_id?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  cycle_attempt_number?: InputMaybe<Scalars['Int']['input']>;
  from_user_id?: InputMaybe<Scalars['String']['input']>;
  global_rule_available_aes?: InputMaybe<Scalars['Json']['input']>;
  global_rule_raw?: InputMaybe<Scalars['Json']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  matched_rule_available_aes?: InputMaybe<Scalars['Json']['input']>;
  matched_rule_raw?: InputMaybe<Scalars['Json']['input']>;
  organization_id?: InputMaybe<Scalars['String']['input']>;
  to_user_id?: InputMaybe<Scalars['String']['input']>;
  transfer_attempt_id?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<AutoSelectionType>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type AutoTransferSelectionCreateManyMatched_RuleInputEnvelope = {
  data?: InputMaybe<Array<AutoTransferSelectionCreateManyMatched_RuleInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type AutoTransferSelectionCreateManyOrganizationInput = {
  conference_id?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  cycle_attempt_number?: InputMaybe<Scalars['Int']['input']>;
  from_user_id?: InputMaybe<Scalars['String']['input']>;
  global_rule_available_aes?: InputMaybe<Scalars['Json']['input']>;
  global_rule_raw?: InputMaybe<Scalars['Json']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  matched_rule_available_aes?: InputMaybe<Scalars['Json']['input']>;
  matched_rule_raw?: InputMaybe<Scalars['Json']['input']>;
  rule_id?: InputMaybe<Scalars['String']['input']>;
  to_user_id?: InputMaybe<Scalars['String']['input']>;
  transfer_attempt_id?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<AutoSelectionType>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type AutoTransferSelectionCreateManyOrganizationInputEnvelope = {
  data?: InputMaybe<Array<AutoTransferSelectionCreateManyOrganizationInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type AutoTransferSelectionCreateManyTo_UserInput = {
  conference_id?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  cycle_attempt_number?: InputMaybe<Scalars['Int']['input']>;
  from_user_id?: InputMaybe<Scalars['String']['input']>;
  global_rule_available_aes?: InputMaybe<Scalars['Json']['input']>;
  global_rule_raw?: InputMaybe<Scalars['Json']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  matched_rule_available_aes?: InputMaybe<Scalars['Json']['input']>;
  matched_rule_raw?: InputMaybe<Scalars['Json']['input']>;
  organization_id?: InputMaybe<Scalars['String']['input']>;
  rule_id?: InputMaybe<Scalars['String']['input']>;
  transfer_attempt_id?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<AutoSelectionType>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type AutoTransferSelectionCreateManyTo_UserInputEnvelope = {
  data?: InputMaybe<Array<AutoTransferSelectionCreateManyTo_UserInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type AutoTransferSelectionCreateNestedManyWithoutConferenceInput = {
  connect?: InputMaybe<Array<AutoTransferSelectionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<AutoTransferSelectionCreateOrConnectWithoutConferenceInput>>;
  create?: InputMaybe<Array<AutoTransferSelectionCreateWithoutConferenceInput>>;
  createMany?: InputMaybe<AutoTransferSelectionCreateManyConferenceInputEnvelope>;
};

export type AutoTransferSelectionCreateNestedManyWithoutFrom_UserInput = {
  connect?: InputMaybe<Array<AutoTransferSelectionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<AutoTransferSelectionCreateOrConnectWithoutFrom_UserInput>>;
  create?: InputMaybe<Array<AutoTransferSelectionCreateWithoutFrom_UserInput>>;
  createMany?: InputMaybe<AutoTransferSelectionCreateManyFrom_UserInputEnvelope>;
};

export type AutoTransferSelectionCreateNestedManyWithoutMatched_RuleInput = {
  connect?: InputMaybe<Array<AutoTransferSelectionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<AutoTransferSelectionCreateOrConnectWithoutMatched_RuleInput>>;
  create?: InputMaybe<Array<AutoTransferSelectionCreateWithoutMatched_RuleInput>>;
  createMany?: InputMaybe<AutoTransferSelectionCreateManyMatched_RuleInputEnvelope>;
};

export type AutoTransferSelectionCreateNestedManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<AutoTransferSelectionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<AutoTransferSelectionCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<AutoTransferSelectionCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<AutoTransferSelectionCreateManyOrganizationInputEnvelope>;
};

export type AutoTransferSelectionCreateNestedManyWithoutTo_UserInput = {
  connect?: InputMaybe<Array<AutoTransferSelectionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<AutoTransferSelectionCreateOrConnectWithoutTo_UserInput>>;
  create?: InputMaybe<Array<AutoTransferSelectionCreateWithoutTo_UserInput>>;
  createMany?: InputMaybe<AutoTransferSelectionCreateManyTo_UserInputEnvelope>;
};

export type AutoTransferSelectionCreateNestedOneWithoutTransfer_AttemptInput = {
  connect?: InputMaybe<AutoTransferSelectionWhereUniqueInput>;
  connectOrCreate?: InputMaybe<AutoTransferSelectionCreateOrConnectWithoutTransfer_AttemptInput>;
  create?: InputMaybe<AutoTransferSelectionCreateWithoutTransfer_AttemptInput>;
};

export type AutoTransferSelectionCreateOrConnectWithoutConferenceInput = {
  create: AutoTransferSelectionCreateWithoutConferenceInput;
  where: AutoTransferSelectionWhereUniqueInput;
};

export type AutoTransferSelectionCreateOrConnectWithoutFrom_UserInput = {
  create: AutoTransferSelectionCreateWithoutFrom_UserInput;
  where: AutoTransferSelectionWhereUniqueInput;
};

export type AutoTransferSelectionCreateOrConnectWithoutMatched_RuleInput = {
  create: AutoTransferSelectionCreateWithoutMatched_RuleInput;
  where: AutoTransferSelectionWhereUniqueInput;
};

export type AutoTransferSelectionCreateOrConnectWithoutOrganizationInput = {
  create: AutoTransferSelectionCreateWithoutOrganizationInput;
  where: AutoTransferSelectionWhereUniqueInput;
};

export type AutoTransferSelectionCreateOrConnectWithoutTo_UserInput = {
  create: AutoTransferSelectionCreateWithoutTo_UserInput;
  where: AutoTransferSelectionWhereUniqueInput;
};

export type AutoTransferSelectionCreateOrConnectWithoutTransfer_AttemptInput = {
  create: AutoTransferSelectionCreateWithoutTransfer_AttemptInput;
  where: AutoTransferSelectionWhereUniqueInput;
};

export type AutoTransferSelectionCreateWithoutConferenceInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  cycle_attempt_number?: InputMaybe<Scalars['Int']['input']>;
  from_user?: InputMaybe<UserCreateNestedOneWithoutAuto_Transfer_Selection_Receive_AttemptsInput>;
  global_rule_available_aes?: InputMaybe<Scalars['Json']['input']>;
  global_rule_raw?: InputMaybe<Scalars['Json']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  matched_rule?: InputMaybe<RuleCreateNestedOneWithoutAuto_Transfer_SelectionInput>;
  matched_rule_available_aes?: InputMaybe<Scalars['Json']['input']>;
  matched_rule_raw?: InputMaybe<Scalars['Json']['input']>;
  Organization?: InputMaybe<OrganizationCreateNestedOneWithoutAutoTransferSelectionsInput>;
  to_user?: InputMaybe<UserCreateNestedOneWithoutAuto_Transfer_Selection_Send_AttemptsInput>;
  transfer_attempt?: InputMaybe<TransferAttemptCreateNestedOneWithoutAuto_Transfer_SelectionInput>;
  type?: InputMaybe<AutoSelectionType>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type AutoTransferSelectionCreateWithoutFrom_UserInput = {
  conference?: InputMaybe<ConferenceCreateNestedOneWithoutAuto_Transfer_SelectionsInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  cycle_attempt_number?: InputMaybe<Scalars['Int']['input']>;
  global_rule_available_aes?: InputMaybe<Scalars['Json']['input']>;
  global_rule_raw?: InputMaybe<Scalars['Json']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  matched_rule?: InputMaybe<RuleCreateNestedOneWithoutAuto_Transfer_SelectionInput>;
  matched_rule_available_aes?: InputMaybe<Scalars['Json']['input']>;
  matched_rule_raw?: InputMaybe<Scalars['Json']['input']>;
  Organization?: InputMaybe<OrganizationCreateNestedOneWithoutAutoTransferSelectionsInput>;
  to_user?: InputMaybe<UserCreateNestedOneWithoutAuto_Transfer_Selection_Send_AttemptsInput>;
  transfer_attempt?: InputMaybe<TransferAttemptCreateNestedOneWithoutAuto_Transfer_SelectionInput>;
  type?: InputMaybe<AutoSelectionType>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type AutoTransferSelectionCreateWithoutMatched_RuleInput = {
  conference?: InputMaybe<ConferenceCreateNestedOneWithoutAuto_Transfer_SelectionsInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  cycle_attempt_number?: InputMaybe<Scalars['Int']['input']>;
  from_user?: InputMaybe<UserCreateNestedOneWithoutAuto_Transfer_Selection_Receive_AttemptsInput>;
  global_rule_available_aes?: InputMaybe<Scalars['Json']['input']>;
  global_rule_raw?: InputMaybe<Scalars['Json']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  matched_rule_available_aes?: InputMaybe<Scalars['Json']['input']>;
  matched_rule_raw?: InputMaybe<Scalars['Json']['input']>;
  Organization?: InputMaybe<OrganizationCreateNestedOneWithoutAutoTransferSelectionsInput>;
  to_user?: InputMaybe<UserCreateNestedOneWithoutAuto_Transfer_Selection_Send_AttemptsInput>;
  transfer_attempt?: InputMaybe<TransferAttemptCreateNestedOneWithoutAuto_Transfer_SelectionInput>;
  type?: InputMaybe<AutoSelectionType>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type AutoTransferSelectionCreateWithoutOrganizationInput = {
  conference?: InputMaybe<ConferenceCreateNestedOneWithoutAuto_Transfer_SelectionsInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  cycle_attempt_number?: InputMaybe<Scalars['Int']['input']>;
  from_user?: InputMaybe<UserCreateNestedOneWithoutAuto_Transfer_Selection_Receive_AttemptsInput>;
  global_rule_available_aes?: InputMaybe<Scalars['Json']['input']>;
  global_rule_raw?: InputMaybe<Scalars['Json']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  matched_rule?: InputMaybe<RuleCreateNestedOneWithoutAuto_Transfer_SelectionInput>;
  matched_rule_available_aes?: InputMaybe<Scalars['Json']['input']>;
  matched_rule_raw?: InputMaybe<Scalars['Json']['input']>;
  to_user?: InputMaybe<UserCreateNestedOneWithoutAuto_Transfer_Selection_Send_AttemptsInput>;
  transfer_attempt?: InputMaybe<TransferAttemptCreateNestedOneWithoutAuto_Transfer_SelectionInput>;
  type?: InputMaybe<AutoSelectionType>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type AutoTransferSelectionCreateWithoutTo_UserInput = {
  conference?: InputMaybe<ConferenceCreateNestedOneWithoutAuto_Transfer_SelectionsInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  cycle_attempt_number?: InputMaybe<Scalars['Int']['input']>;
  from_user?: InputMaybe<UserCreateNestedOneWithoutAuto_Transfer_Selection_Receive_AttemptsInput>;
  global_rule_available_aes?: InputMaybe<Scalars['Json']['input']>;
  global_rule_raw?: InputMaybe<Scalars['Json']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  matched_rule?: InputMaybe<RuleCreateNestedOneWithoutAuto_Transfer_SelectionInput>;
  matched_rule_available_aes?: InputMaybe<Scalars['Json']['input']>;
  matched_rule_raw?: InputMaybe<Scalars['Json']['input']>;
  Organization?: InputMaybe<OrganizationCreateNestedOneWithoutAutoTransferSelectionsInput>;
  transfer_attempt?: InputMaybe<TransferAttemptCreateNestedOneWithoutAuto_Transfer_SelectionInput>;
  type?: InputMaybe<AutoSelectionType>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type AutoTransferSelectionCreateWithoutTransfer_AttemptInput = {
  conference?: InputMaybe<ConferenceCreateNestedOneWithoutAuto_Transfer_SelectionsInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  cycle_attempt_number?: InputMaybe<Scalars['Int']['input']>;
  from_user?: InputMaybe<UserCreateNestedOneWithoutAuto_Transfer_Selection_Receive_AttemptsInput>;
  global_rule_available_aes?: InputMaybe<Scalars['Json']['input']>;
  global_rule_raw?: InputMaybe<Scalars['Json']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  matched_rule?: InputMaybe<RuleCreateNestedOneWithoutAuto_Transfer_SelectionInput>;
  matched_rule_available_aes?: InputMaybe<Scalars['Json']['input']>;
  matched_rule_raw?: InputMaybe<Scalars['Json']['input']>;
  Organization?: InputMaybe<OrganizationCreateNestedOneWithoutAutoTransferSelectionsInput>;
  to_user?: InputMaybe<UserCreateNestedOneWithoutAuto_Transfer_Selection_Send_AttemptsInput>;
  type?: InputMaybe<AutoSelectionType>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type AutoTransferSelectionListRelationFilter = {
  every?: InputMaybe<AutoTransferSelectionWhereInput>;
  none?: InputMaybe<AutoTransferSelectionWhereInput>;
  some?: InputMaybe<AutoTransferSelectionWhereInput>;
};

export type AutoTransferSelectionOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
  count?: InputMaybe<SortOrder>;
};

export type AutoTransferSelectionScalarWhereInput = {
  AND?: InputMaybe<Array<AutoTransferSelectionScalarWhereInput>>;
  conference_id?: InputMaybe<StringNullableFilter>;
  created_at?: InputMaybe<DateTimeFilter>;
  cycle_attempt_number?: InputMaybe<IntNullableFilter>;
  from_user_id?: InputMaybe<StringNullableFilter>;
  global_rule_available_aes?: InputMaybe<JsonNullableFilter>;
  global_rule_raw?: InputMaybe<JsonNullableFilter>;
  id?: InputMaybe<StringFilter>;
  matched_rule_available_aes?: InputMaybe<JsonNullableFilter>;
  matched_rule_raw?: InputMaybe<JsonNullableFilter>;
  NOT?: InputMaybe<Array<AutoTransferSelectionScalarWhereInput>>;
  OR?: InputMaybe<Array<AutoTransferSelectionScalarWhereInput>>;
  organization_id?: InputMaybe<StringNullableFilter>;
  rule_id?: InputMaybe<StringNullableFilter>;
  to_user_id?: InputMaybe<StringNullableFilter>;
  transfer_attempt_id?: InputMaybe<StringNullableFilter>;
  type?: InputMaybe<EnumAutoSelectionTypeFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
};

export type AutoTransferSelectionUpdateManyMutationInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  cycle_attempt_number?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  global_rule_available_aes?: InputMaybe<Scalars['Json']['input']>;
  global_rule_raw?: InputMaybe<Scalars['Json']['input']>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  matched_rule_available_aes?: InputMaybe<Scalars['Json']['input']>;
  matched_rule_raw?: InputMaybe<Scalars['Json']['input']>;
  type?: InputMaybe<EnumAutoSelectionTypeFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type AutoTransferSelectionUpdateManyWithoutConferenceInput = {
  connect?: InputMaybe<Array<AutoTransferSelectionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<AutoTransferSelectionCreateOrConnectWithoutConferenceInput>>;
  create?: InputMaybe<Array<AutoTransferSelectionCreateWithoutConferenceInput>>;
  createMany?: InputMaybe<AutoTransferSelectionCreateManyConferenceInputEnvelope>;
  delete?: InputMaybe<Array<AutoTransferSelectionWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<AutoTransferSelectionScalarWhereInput>>;
  disconnect?: InputMaybe<Array<AutoTransferSelectionWhereUniqueInput>>;
  set?: InputMaybe<Array<AutoTransferSelectionWhereUniqueInput>>;
  update?: InputMaybe<Array<AutoTransferSelectionUpdateWithWhereUniqueWithoutConferenceInput>>;
  updateMany?: InputMaybe<Array<AutoTransferSelectionUpdateManyWithWhereWithoutConferenceInput>>;
  upsert?: InputMaybe<Array<AutoTransferSelectionUpsertWithWhereUniqueWithoutConferenceInput>>;
};

export type AutoTransferSelectionUpdateManyWithoutFrom_UserInput = {
  connect?: InputMaybe<Array<AutoTransferSelectionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<AutoTransferSelectionCreateOrConnectWithoutFrom_UserInput>>;
  create?: InputMaybe<Array<AutoTransferSelectionCreateWithoutFrom_UserInput>>;
  createMany?: InputMaybe<AutoTransferSelectionCreateManyFrom_UserInputEnvelope>;
  delete?: InputMaybe<Array<AutoTransferSelectionWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<AutoTransferSelectionScalarWhereInput>>;
  disconnect?: InputMaybe<Array<AutoTransferSelectionWhereUniqueInput>>;
  set?: InputMaybe<Array<AutoTransferSelectionWhereUniqueInput>>;
  update?: InputMaybe<Array<AutoTransferSelectionUpdateWithWhereUniqueWithoutFrom_UserInput>>;
  updateMany?: InputMaybe<Array<AutoTransferSelectionUpdateManyWithWhereWithoutFrom_UserInput>>;
  upsert?: InputMaybe<Array<AutoTransferSelectionUpsertWithWhereUniqueWithoutFrom_UserInput>>;
};

export type AutoTransferSelectionUpdateManyWithoutMatched_RuleInput = {
  connect?: InputMaybe<Array<AutoTransferSelectionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<AutoTransferSelectionCreateOrConnectWithoutMatched_RuleInput>>;
  create?: InputMaybe<Array<AutoTransferSelectionCreateWithoutMatched_RuleInput>>;
  createMany?: InputMaybe<AutoTransferSelectionCreateManyMatched_RuleInputEnvelope>;
  delete?: InputMaybe<Array<AutoTransferSelectionWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<AutoTransferSelectionScalarWhereInput>>;
  disconnect?: InputMaybe<Array<AutoTransferSelectionWhereUniqueInput>>;
  set?: InputMaybe<Array<AutoTransferSelectionWhereUniqueInput>>;
  update?: InputMaybe<Array<AutoTransferSelectionUpdateWithWhereUniqueWithoutMatched_RuleInput>>;
  updateMany?: InputMaybe<Array<AutoTransferSelectionUpdateManyWithWhereWithoutMatched_RuleInput>>;
  upsert?: InputMaybe<Array<AutoTransferSelectionUpsertWithWhereUniqueWithoutMatched_RuleInput>>;
};

export type AutoTransferSelectionUpdateManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<AutoTransferSelectionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<AutoTransferSelectionCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<AutoTransferSelectionCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<AutoTransferSelectionCreateManyOrganizationInputEnvelope>;
  delete?: InputMaybe<Array<AutoTransferSelectionWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<AutoTransferSelectionScalarWhereInput>>;
  disconnect?: InputMaybe<Array<AutoTransferSelectionWhereUniqueInput>>;
  set?: InputMaybe<Array<AutoTransferSelectionWhereUniqueInput>>;
  update?: InputMaybe<Array<AutoTransferSelectionUpdateWithWhereUniqueWithoutOrganizationInput>>;
  updateMany?: InputMaybe<Array<AutoTransferSelectionUpdateManyWithWhereWithoutOrganizationInput>>;
  upsert?: InputMaybe<Array<AutoTransferSelectionUpsertWithWhereUniqueWithoutOrganizationInput>>;
};

export type AutoTransferSelectionUpdateManyWithoutTo_UserInput = {
  connect?: InputMaybe<Array<AutoTransferSelectionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<AutoTransferSelectionCreateOrConnectWithoutTo_UserInput>>;
  create?: InputMaybe<Array<AutoTransferSelectionCreateWithoutTo_UserInput>>;
  createMany?: InputMaybe<AutoTransferSelectionCreateManyTo_UserInputEnvelope>;
  delete?: InputMaybe<Array<AutoTransferSelectionWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<AutoTransferSelectionScalarWhereInput>>;
  disconnect?: InputMaybe<Array<AutoTransferSelectionWhereUniqueInput>>;
  set?: InputMaybe<Array<AutoTransferSelectionWhereUniqueInput>>;
  update?: InputMaybe<Array<AutoTransferSelectionUpdateWithWhereUniqueWithoutTo_UserInput>>;
  updateMany?: InputMaybe<Array<AutoTransferSelectionUpdateManyWithWhereWithoutTo_UserInput>>;
  upsert?: InputMaybe<Array<AutoTransferSelectionUpsertWithWhereUniqueWithoutTo_UserInput>>;
};

export type AutoTransferSelectionUpdateManyWithWhereWithoutConferenceInput = {
  data: AutoTransferSelectionUpdateManyMutationInput;
  where: AutoTransferSelectionScalarWhereInput;
};

export type AutoTransferSelectionUpdateManyWithWhereWithoutFrom_UserInput = {
  data: AutoTransferSelectionUpdateManyMutationInput;
  where: AutoTransferSelectionScalarWhereInput;
};

export type AutoTransferSelectionUpdateManyWithWhereWithoutMatched_RuleInput = {
  data: AutoTransferSelectionUpdateManyMutationInput;
  where: AutoTransferSelectionScalarWhereInput;
};

export type AutoTransferSelectionUpdateManyWithWhereWithoutOrganizationInput = {
  data: AutoTransferSelectionUpdateManyMutationInput;
  where: AutoTransferSelectionScalarWhereInput;
};

export type AutoTransferSelectionUpdateManyWithWhereWithoutTo_UserInput = {
  data: AutoTransferSelectionUpdateManyMutationInput;
  where: AutoTransferSelectionScalarWhereInput;
};

export type AutoTransferSelectionUpdateOneWithoutTransfer_AttemptInput = {
  connect?: InputMaybe<AutoTransferSelectionWhereUniqueInput>;
  connectOrCreate?: InputMaybe<AutoTransferSelectionCreateOrConnectWithoutTransfer_AttemptInput>;
  create?: InputMaybe<AutoTransferSelectionCreateWithoutTransfer_AttemptInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<AutoTransferSelectionUpdateWithoutTransfer_AttemptInput>;
  upsert?: InputMaybe<AutoTransferSelectionUpsertWithoutTransfer_AttemptInput>;
};

export type AutoTransferSelectionUpdateWithoutConferenceInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  cycle_attempt_number?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  from_user?: InputMaybe<UserUpdateOneWithoutAuto_Transfer_Selection_Receive_AttemptsInput>;
  global_rule_available_aes?: InputMaybe<Scalars['Json']['input']>;
  global_rule_raw?: InputMaybe<Scalars['Json']['input']>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  matched_rule?: InputMaybe<RuleUpdateOneWithoutAuto_Transfer_SelectionInput>;
  matched_rule_available_aes?: InputMaybe<Scalars['Json']['input']>;
  matched_rule_raw?: InputMaybe<Scalars['Json']['input']>;
  Organization?: InputMaybe<OrganizationUpdateOneWithoutAutoTransferSelectionsInput>;
  to_user?: InputMaybe<UserUpdateOneWithoutAuto_Transfer_Selection_Send_AttemptsInput>;
  transfer_attempt?: InputMaybe<TransferAttemptUpdateOneWithoutAuto_Transfer_SelectionInput>;
  type?: InputMaybe<EnumAutoSelectionTypeFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type AutoTransferSelectionUpdateWithoutFrom_UserInput = {
  conference?: InputMaybe<ConferenceUpdateOneWithoutAuto_Transfer_SelectionsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  cycle_attempt_number?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  global_rule_available_aes?: InputMaybe<Scalars['Json']['input']>;
  global_rule_raw?: InputMaybe<Scalars['Json']['input']>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  matched_rule?: InputMaybe<RuleUpdateOneWithoutAuto_Transfer_SelectionInput>;
  matched_rule_available_aes?: InputMaybe<Scalars['Json']['input']>;
  matched_rule_raw?: InputMaybe<Scalars['Json']['input']>;
  Organization?: InputMaybe<OrganizationUpdateOneWithoutAutoTransferSelectionsInput>;
  to_user?: InputMaybe<UserUpdateOneWithoutAuto_Transfer_Selection_Send_AttemptsInput>;
  transfer_attempt?: InputMaybe<TransferAttemptUpdateOneWithoutAuto_Transfer_SelectionInput>;
  type?: InputMaybe<EnumAutoSelectionTypeFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type AutoTransferSelectionUpdateWithoutMatched_RuleInput = {
  conference?: InputMaybe<ConferenceUpdateOneWithoutAuto_Transfer_SelectionsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  cycle_attempt_number?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  from_user?: InputMaybe<UserUpdateOneWithoutAuto_Transfer_Selection_Receive_AttemptsInput>;
  global_rule_available_aes?: InputMaybe<Scalars['Json']['input']>;
  global_rule_raw?: InputMaybe<Scalars['Json']['input']>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  matched_rule_available_aes?: InputMaybe<Scalars['Json']['input']>;
  matched_rule_raw?: InputMaybe<Scalars['Json']['input']>;
  Organization?: InputMaybe<OrganizationUpdateOneWithoutAutoTransferSelectionsInput>;
  to_user?: InputMaybe<UserUpdateOneWithoutAuto_Transfer_Selection_Send_AttemptsInput>;
  transfer_attempt?: InputMaybe<TransferAttemptUpdateOneWithoutAuto_Transfer_SelectionInput>;
  type?: InputMaybe<EnumAutoSelectionTypeFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type AutoTransferSelectionUpdateWithoutOrganizationInput = {
  conference?: InputMaybe<ConferenceUpdateOneWithoutAuto_Transfer_SelectionsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  cycle_attempt_number?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  from_user?: InputMaybe<UserUpdateOneWithoutAuto_Transfer_Selection_Receive_AttemptsInput>;
  global_rule_available_aes?: InputMaybe<Scalars['Json']['input']>;
  global_rule_raw?: InputMaybe<Scalars['Json']['input']>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  matched_rule?: InputMaybe<RuleUpdateOneWithoutAuto_Transfer_SelectionInput>;
  matched_rule_available_aes?: InputMaybe<Scalars['Json']['input']>;
  matched_rule_raw?: InputMaybe<Scalars['Json']['input']>;
  to_user?: InputMaybe<UserUpdateOneWithoutAuto_Transfer_Selection_Send_AttemptsInput>;
  transfer_attempt?: InputMaybe<TransferAttemptUpdateOneWithoutAuto_Transfer_SelectionInput>;
  type?: InputMaybe<EnumAutoSelectionTypeFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type AutoTransferSelectionUpdateWithoutTo_UserInput = {
  conference?: InputMaybe<ConferenceUpdateOneWithoutAuto_Transfer_SelectionsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  cycle_attempt_number?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  from_user?: InputMaybe<UserUpdateOneWithoutAuto_Transfer_Selection_Receive_AttemptsInput>;
  global_rule_available_aes?: InputMaybe<Scalars['Json']['input']>;
  global_rule_raw?: InputMaybe<Scalars['Json']['input']>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  matched_rule?: InputMaybe<RuleUpdateOneWithoutAuto_Transfer_SelectionInput>;
  matched_rule_available_aes?: InputMaybe<Scalars['Json']['input']>;
  matched_rule_raw?: InputMaybe<Scalars['Json']['input']>;
  Organization?: InputMaybe<OrganizationUpdateOneWithoutAutoTransferSelectionsInput>;
  transfer_attempt?: InputMaybe<TransferAttemptUpdateOneWithoutAuto_Transfer_SelectionInput>;
  type?: InputMaybe<EnumAutoSelectionTypeFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type AutoTransferSelectionUpdateWithoutTransfer_AttemptInput = {
  conference?: InputMaybe<ConferenceUpdateOneWithoutAuto_Transfer_SelectionsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  cycle_attempt_number?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  from_user?: InputMaybe<UserUpdateOneWithoutAuto_Transfer_Selection_Receive_AttemptsInput>;
  global_rule_available_aes?: InputMaybe<Scalars['Json']['input']>;
  global_rule_raw?: InputMaybe<Scalars['Json']['input']>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  matched_rule?: InputMaybe<RuleUpdateOneWithoutAuto_Transfer_SelectionInput>;
  matched_rule_available_aes?: InputMaybe<Scalars['Json']['input']>;
  matched_rule_raw?: InputMaybe<Scalars['Json']['input']>;
  Organization?: InputMaybe<OrganizationUpdateOneWithoutAutoTransferSelectionsInput>;
  to_user?: InputMaybe<UserUpdateOneWithoutAuto_Transfer_Selection_Send_AttemptsInput>;
  type?: InputMaybe<EnumAutoSelectionTypeFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type AutoTransferSelectionUpdateWithWhereUniqueWithoutConferenceInput = {
  data: AutoTransferSelectionUpdateWithoutConferenceInput;
  where: AutoTransferSelectionWhereUniqueInput;
};

export type AutoTransferSelectionUpdateWithWhereUniqueWithoutFrom_UserInput = {
  data: AutoTransferSelectionUpdateWithoutFrom_UserInput;
  where: AutoTransferSelectionWhereUniqueInput;
};

export type AutoTransferSelectionUpdateWithWhereUniqueWithoutMatched_RuleInput = {
  data: AutoTransferSelectionUpdateWithoutMatched_RuleInput;
  where: AutoTransferSelectionWhereUniqueInput;
};

export type AutoTransferSelectionUpdateWithWhereUniqueWithoutOrganizationInput = {
  data: AutoTransferSelectionUpdateWithoutOrganizationInput;
  where: AutoTransferSelectionWhereUniqueInput;
};

export type AutoTransferSelectionUpdateWithWhereUniqueWithoutTo_UserInput = {
  data: AutoTransferSelectionUpdateWithoutTo_UserInput;
  where: AutoTransferSelectionWhereUniqueInput;
};

export type AutoTransferSelectionUpsertWithoutTransfer_AttemptInput = {
  create: AutoTransferSelectionCreateWithoutTransfer_AttemptInput;
  update: AutoTransferSelectionUpdateWithoutTransfer_AttemptInput;
};

export type AutoTransferSelectionUpsertWithWhereUniqueWithoutConferenceInput = {
  create: AutoTransferSelectionCreateWithoutConferenceInput;
  update: AutoTransferSelectionUpdateWithoutConferenceInput;
  where: AutoTransferSelectionWhereUniqueInput;
};

export type AutoTransferSelectionUpsertWithWhereUniqueWithoutFrom_UserInput = {
  create: AutoTransferSelectionCreateWithoutFrom_UserInput;
  update: AutoTransferSelectionUpdateWithoutFrom_UserInput;
  where: AutoTransferSelectionWhereUniqueInput;
};

export type AutoTransferSelectionUpsertWithWhereUniqueWithoutMatched_RuleInput = {
  create: AutoTransferSelectionCreateWithoutMatched_RuleInput;
  update: AutoTransferSelectionUpdateWithoutMatched_RuleInput;
  where: AutoTransferSelectionWhereUniqueInput;
};

export type AutoTransferSelectionUpsertWithWhereUniqueWithoutOrganizationInput = {
  create: AutoTransferSelectionCreateWithoutOrganizationInput;
  update: AutoTransferSelectionUpdateWithoutOrganizationInput;
  where: AutoTransferSelectionWhereUniqueInput;
};

export type AutoTransferSelectionUpsertWithWhereUniqueWithoutTo_UserInput = {
  create: AutoTransferSelectionCreateWithoutTo_UserInput;
  update: AutoTransferSelectionUpdateWithoutTo_UserInput;
  where: AutoTransferSelectionWhereUniqueInput;
};

export type AutoTransferSelectionWhereInput = {
  AND?: InputMaybe<Array<AutoTransferSelectionWhereInput>>;
  conference?: InputMaybe<ConferenceWhereInput>;
  conference_id?: InputMaybe<StringNullableFilter>;
  created_at?: InputMaybe<DateTimeFilter>;
  cycle_attempt_number?: InputMaybe<IntNullableFilter>;
  from_user?: InputMaybe<UserWhereInput>;
  from_user_id?: InputMaybe<StringNullableFilter>;
  global_rule_available_aes?: InputMaybe<JsonNullableFilter>;
  global_rule_raw?: InputMaybe<JsonNullableFilter>;
  id?: InputMaybe<StringFilter>;
  matched_rule?: InputMaybe<RuleWhereInput>;
  matched_rule_available_aes?: InputMaybe<JsonNullableFilter>;
  matched_rule_raw?: InputMaybe<JsonNullableFilter>;
  NOT?: InputMaybe<Array<AutoTransferSelectionWhereInput>>;
  OR?: InputMaybe<Array<AutoTransferSelectionWhereInput>>;
  Organization?: InputMaybe<OrganizationWhereInput>;
  organization_id?: InputMaybe<StringNullableFilter>;
  rule_id?: InputMaybe<StringNullableFilter>;
  to_user?: InputMaybe<UserWhereInput>;
  to_user_id?: InputMaybe<StringNullableFilter>;
  transfer_attempt?: InputMaybe<TransferAttemptWhereInput>;
  transfer_attempt_id?: InputMaybe<StringNullableFilter>;
  type?: InputMaybe<EnumAutoSelectionTypeFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
};

export type AutoTransferSelectionWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  transfer_attempt_id?: InputMaybe<Scalars['String']['input']>;
};

export type Availability = {
  __typename?: 'Availability';
  created_at: Scalars['DateTime']['output'];
  day: Weekday;
  end_hour?: Maybe<Scalars['Int']['output']>;
  end_minute?: Maybe<Scalars['Int']['output']>;
  /** computed representation of end_time in string format */
  end_time?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  start_hour?: Maybe<Scalars['Int']['output']>;
  start_minute?: Maybe<Scalars['Int']['output']>;
  /** computed representation of start_time in string format */
  start_time?: Maybe<Scalars['String']['output']>;
  updated_at: Scalars['DateTime']['output'];
  user?: Maybe<User>;
  user_id?: Maybe<Scalars['String']['output']>;
};

export type AvailabilityCreateManyOrganizationInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  day: Weekday;
  end_hour?: InputMaybe<Scalars['Int']['input']>;
  end_minute?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  start_hour?: InputMaybe<Scalars['Int']['input']>;
  start_minute?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
};

export type AvailabilityCreateManyOrganizationInputEnvelope = {
  data?: InputMaybe<Array<AvailabilityCreateManyOrganizationInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type AvailabilityCreateManyUserInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  day: Weekday;
  end_hour?: InputMaybe<Scalars['Int']['input']>;
  end_minute?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  organization_id?: InputMaybe<Scalars['String']['input']>;
  start_hour?: InputMaybe<Scalars['Int']['input']>;
  start_minute?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type AvailabilityCreateManyUserInputEnvelope = {
  data?: InputMaybe<Array<AvailabilityCreateManyUserInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type AvailabilityCreateNestedManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<AvailabilityWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<AvailabilityCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<AvailabilityCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<AvailabilityCreateManyOrganizationInputEnvelope>;
};

export type AvailabilityCreateNestedManyWithoutUserInput = {
  connect?: InputMaybe<Array<AvailabilityWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<AvailabilityCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<AvailabilityCreateWithoutUserInput>>;
  createMany?: InputMaybe<AvailabilityCreateManyUserInputEnvelope>;
};

export type AvailabilityCreateOrConnectWithoutOrganizationInput = {
  create: AvailabilityCreateWithoutOrganizationInput;
  where: AvailabilityWhereUniqueInput;
};

export type AvailabilityCreateOrConnectWithoutUserInput = {
  create: AvailabilityCreateWithoutUserInput;
  where: AvailabilityWhereUniqueInput;
};

export type AvailabilityCreateWithoutOrganizationInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  day: Weekday;
  end_hour?: InputMaybe<Scalars['Int']['input']>;
  end_minute?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  start_hour?: InputMaybe<Scalars['Int']['input']>;
  start_minute?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user?: InputMaybe<UserCreateNestedOneWithoutUser_Custom_AvailabilityInput>;
};

export type AvailabilityCreateWithoutUserInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  day: Weekday;
  end_hour?: InputMaybe<Scalars['Int']['input']>;
  end_minute?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  Organization?: InputMaybe<OrganizationCreateNestedOneWithoutAvailablitiesInput>;
  start_hour?: InputMaybe<Scalars['Int']['input']>;
  start_minute?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

/** Used to input availability into a mutation/query */
export type AvailabilityInput = {
  day: Weekday;
  end_hour?: InputMaybe<Scalars['Int']['input']>;
  end_minute?: InputMaybe<Scalars['Int']['input']>;
  /** Id of the availability day. Not required. */
  id?: InputMaybe<Scalars['String']['input']>;
  start_hour?: InputMaybe<Scalars['Int']['input']>;
  start_minute?: InputMaybe<Scalars['Int']['input']>;
};

export type AvailabilityListRelationFilter = {
  every?: InputMaybe<AvailabilityWhereInput>;
  none?: InputMaybe<AvailabilityWhereInput>;
  some?: InputMaybe<AvailabilityWhereInput>;
};

export type AvailabilityOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
  count?: InputMaybe<SortOrder>;
};

export type AvailabilityScalarWhereInput = {
  AND?: InputMaybe<Array<AvailabilityScalarWhereInput>>;
  created_at?: InputMaybe<DateTimeFilter>;
  day?: InputMaybe<EnumWeekdayFilter>;
  end_hour?: InputMaybe<IntNullableFilter>;
  end_minute?: InputMaybe<IntNullableFilter>;
  id?: InputMaybe<StringFilter>;
  NOT?: InputMaybe<Array<AvailabilityScalarWhereInput>>;
  OR?: InputMaybe<Array<AvailabilityScalarWhereInput>>;
  organization_id?: InputMaybe<StringNullableFilter>;
  start_hour?: InputMaybe<IntNullableFilter>;
  start_minute?: InputMaybe<IntNullableFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
  user_id?: InputMaybe<StringNullableFilter>;
};

export type AvailabilityUpdateManyMutationInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  day?: InputMaybe<EnumWeekdayFieldUpdateOperationsInput>;
  end_hour?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  end_minute?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  start_hour?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  start_minute?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type AvailabilityUpdateManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<AvailabilityWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<AvailabilityCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<AvailabilityCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<AvailabilityCreateManyOrganizationInputEnvelope>;
  delete?: InputMaybe<Array<AvailabilityWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<AvailabilityScalarWhereInput>>;
  disconnect?: InputMaybe<Array<AvailabilityWhereUniqueInput>>;
  set?: InputMaybe<Array<AvailabilityWhereUniqueInput>>;
  update?: InputMaybe<Array<AvailabilityUpdateWithWhereUniqueWithoutOrganizationInput>>;
  updateMany?: InputMaybe<Array<AvailabilityUpdateManyWithWhereWithoutOrganizationInput>>;
  upsert?: InputMaybe<Array<AvailabilityUpsertWithWhereUniqueWithoutOrganizationInput>>;
};

export type AvailabilityUpdateManyWithoutUserInput = {
  connect?: InputMaybe<Array<AvailabilityWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<AvailabilityCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<AvailabilityCreateWithoutUserInput>>;
  createMany?: InputMaybe<AvailabilityCreateManyUserInputEnvelope>;
  delete?: InputMaybe<Array<AvailabilityWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<AvailabilityScalarWhereInput>>;
  disconnect?: InputMaybe<Array<AvailabilityWhereUniqueInput>>;
  set?: InputMaybe<Array<AvailabilityWhereUniqueInput>>;
  update?: InputMaybe<Array<AvailabilityUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: InputMaybe<Array<AvailabilityUpdateManyWithWhereWithoutUserInput>>;
  upsert?: InputMaybe<Array<AvailabilityUpsertWithWhereUniqueWithoutUserInput>>;
};

export type AvailabilityUpdateManyWithWhereWithoutOrganizationInput = {
  data: AvailabilityUpdateManyMutationInput;
  where: AvailabilityScalarWhereInput;
};

export type AvailabilityUpdateManyWithWhereWithoutUserInput = {
  data: AvailabilityUpdateManyMutationInput;
  where: AvailabilityScalarWhereInput;
};

export type AvailabilityUpdateWithoutOrganizationInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  day?: InputMaybe<EnumWeekdayFieldUpdateOperationsInput>;
  end_hour?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  end_minute?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  start_hour?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  start_minute?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneWithoutUser_Custom_AvailabilityInput>;
};

export type AvailabilityUpdateWithoutUserInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  day?: InputMaybe<EnumWeekdayFieldUpdateOperationsInput>;
  end_hour?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  end_minute?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  Organization?: InputMaybe<OrganizationUpdateOneWithoutAvailablitiesInput>;
  start_hour?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  start_minute?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type AvailabilityUpdateWithWhereUniqueWithoutOrganizationInput = {
  data: AvailabilityUpdateWithoutOrganizationInput;
  where: AvailabilityWhereUniqueInput;
};

export type AvailabilityUpdateWithWhereUniqueWithoutUserInput = {
  data: AvailabilityUpdateWithoutUserInput;
  where: AvailabilityWhereUniqueInput;
};

export type AvailabilityUpsertWithWhereUniqueWithoutOrganizationInput = {
  create: AvailabilityCreateWithoutOrganizationInput;
  update: AvailabilityUpdateWithoutOrganizationInput;
  where: AvailabilityWhereUniqueInput;
};

export type AvailabilityUpsertWithWhereUniqueWithoutUserInput = {
  create: AvailabilityCreateWithoutUserInput;
  update: AvailabilityUpdateWithoutUserInput;
  where: AvailabilityWhereUniqueInput;
};

export type AvailabilityUser_IdDayCompoundUniqueInput = {
  day: Weekday;
  user_id: Scalars['String']['input'];
};

export type AvailabilityWhereInput = {
  AND?: InputMaybe<Array<AvailabilityWhereInput>>;
  created_at?: InputMaybe<DateTimeFilter>;
  day?: InputMaybe<EnumWeekdayFilter>;
  end_hour?: InputMaybe<IntNullableFilter>;
  end_minute?: InputMaybe<IntNullableFilter>;
  id?: InputMaybe<StringFilter>;
  NOT?: InputMaybe<Array<AvailabilityWhereInput>>;
  OR?: InputMaybe<Array<AvailabilityWhereInput>>;
  Organization?: InputMaybe<OrganizationWhereInput>;
  organization_id?: InputMaybe<StringNullableFilter>;
  start_hour?: InputMaybe<IntNullableFilter>;
  start_minute?: InputMaybe<IntNullableFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserWhereInput>;
  user_id?: InputMaybe<StringNullableFilter>;
};

export type AvailabilityWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  user_id_day?: InputMaybe<AvailabilityUser_IdDayCompoundUniqueInput>;
};

export type AverageOrderValue = {
  __typename?: 'AverageOrderValue';
  computed_value: Scalars['Float']['output'];
  default_value: Scalars['Float']['output'];
  id: Scalars['String']['output'];
  organization: Organization;
  organization_id: Scalars['String']['output'];
  use_default: Scalars['Boolean']['output'];
};

export type AverageOrderValueCreateManyOrganizationInput = {
  computed_value: Scalars['Float']['input'];
  default_value: Scalars['Float']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  use_default?: InputMaybe<Scalars['Boolean']['input']>;
};

export type AverageOrderValueCreateManyOrganizationInputEnvelope = {
  data?: InputMaybe<Array<AverageOrderValueCreateManyOrganizationInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type AverageOrderValueCreateNestedManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<AverageOrderValueWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<AverageOrderValueCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<AverageOrderValueCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<AverageOrderValueCreateManyOrganizationInputEnvelope>;
};

export type AverageOrderValueCreateOrConnectWithoutOrganizationInput = {
  create: AverageOrderValueCreateWithoutOrganizationInput;
  where: AverageOrderValueWhereUniqueInput;
};

export type AverageOrderValueCreateWithoutOrganizationInput = {
  computed_value: Scalars['Float']['input'];
  default_value: Scalars['Float']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  use_default?: InputMaybe<Scalars['Boolean']['input']>;
};

export type AverageOrderValueListRelationFilter = {
  every?: InputMaybe<AverageOrderValueWhereInput>;
  none?: InputMaybe<AverageOrderValueWhereInput>;
  some?: InputMaybe<AverageOrderValueWhereInput>;
};

export type AverageOrderValueOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
  count?: InputMaybe<SortOrder>;
};

export type AverageOrderValueScalarWhereInput = {
  AND?: InputMaybe<Array<AverageOrderValueScalarWhereInput>>;
  computed_value?: InputMaybe<FloatFilter>;
  default_value?: InputMaybe<FloatFilter>;
  id?: InputMaybe<StringFilter>;
  NOT?: InputMaybe<Array<AverageOrderValueScalarWhereInput>>;
  OR?: InputMaybe<Array<AverageOrderValueScalarWhereInput>>;
  organization_id?: InputMaybe<StringFilter>;
  use_default?: InputMaybe<BoolFilter>;
};

export type AverageOrderValueUpdateManyMutationInput = {
  computed_value?: InputMaybe<FloatFieldUpdateOperationsInput>;
  default_value?: InputMaybe<FloatFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  use_default?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type AverageOrderValueUpdateManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<AverageOrderValueWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<AverageOrderValueCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<AverageOrderValueCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<AverageOrderValueCreateManyOrganizationInputEnvelope>;
  delete?: InputMaybe<Array<AverageOrderValueWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<AverageOrderValueScalarWhereInput>>;
  disconnect?: InputMaybe<Array<AverageOrderValueWhereUniqueInput>>;
  set?: InputMaybe<Array<AverageOrderValueWhereUniqueInput>>;
  update?: InputMaybe<Array<AverageOrderValueUpdateWithWhereUniqueWithoutOrganizationInput>>;
  updateMany?: InputMaybe<Array<AverageOrderValueUpdateManyWithWhereWithoutOrganizationInput>>;
  upsert?: InputMaybe<Array<AverageOrderValueUpsertWithWhereUniqueWithoutOrganizationInput>>;
};

export type AverageOrderValueUpdateManyWithWhereWithoutOrganizationInput = {
  data: AverageOrderValueUpdateManyMutationInput;
  where: AverageOrderValueScalarWhereInput;
};

export type AverageOrderValueUpdateWithoutOrganizationInput = {
  computed_value?: InputMaybe<FloatFieldUpdateOperationsInput>;
  default_value?: InputMaybe<FloatFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  use_default?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type AverageOrderValueUpdateWithWhereUniqueWithoutOrganizationInput = {
  data: AverageOrderValueUpdateWithoutOrganizationInput;
  where: AverageOrderValueWhereUniqueInput;
};

export type AverageOrderValueUpsertWithWhereUniqueWithoutOrganizationInput = {
  create: AverageOrderValueCreateWithoutOrganizationInput;
  update: AverageOrderValueUpdateWithoutOrganizationInput;
  where: AverageOrderValueWhereUniqueInput;
};

export type AverageOrderValueWhereInput = {
  AND?: InputMaybe<Array<AverageOrderValueWhereInput>>;
  computed_value?: InputMaybe<FloatFilter>;
  default_value?: InputMaybe<FloatFilter>;
  id?: InputMaybe<StringFilter>;
  NOT?: InputMaybe<Array<AverageOrderValueWhereInput>>;
  OR?: InputMaybe<Array<AverageOrderValueWhereInput>>;
  organization?: InputMaybe<OrganizationWhereInput>;
  organization_id?: InputMaybe<StringFilter>;
  use_default?: InputMaybe<BoolFilter>;
};

export type AverageOrderValueWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']['input']>;
};

export enum BackUpRule {
  FetchAllAe = 'FetchAllAE',
  FetchSelectedAe = 'FetchSelectedAE',
  ScheduleMeeting = 'ScheduleMeeting'
}

export enum Best_Metric_Record_Type {
  Daily = 'Daily',
  Monthly = 'Monthly',
  Weekly = 'Weekly'
}

export type BestMetricRecord = {
  __typename?: 'BestMetricRecord';
  id: Scalars['String']['output'];
  metric: Metric;
  organization: Organization;
  organization_id: Scalars['String']['output'];
  record_type: Best_Metric_Record_Type;
  team_id?: Maybe<Scalars['String']['output']>;
  user_id?: Maybe<Scalars['String']['output']>;
  value: Scalars['Float']['output'];
};

export type BestMetricRecordCreateInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  metric: Metric;
  organization: OrganizationCreateNestedOneWithoutBest_MetricsInput;
  record_type: Best_Metric_Record_Type;
  team_id?: InputMaybe<Scalars['String']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
  value: Scalars['Float']['input'];
};

export type BestMetricRecordCreateManyOrganizationInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  metric: Metric;
  record_type: Best_Metric_Record_Type;
  team_id?: InputMaybe<Scalars['String']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
  value: Scalars['Float']['input'];
};

export type BestMetricRecordCreateManyOrganizationInputEnvelope = {
  data?: InputMaybe<Array<BestMetricRecordCreateManyOrganizationInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type BestMetricRecordCreateNestedManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<BestMetricRecordWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<BestMetricRecordCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<BestMetricRecordCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<BestMetricRecordCreateManyOrganizationInputEnvelope>;
};

export type BestMetricRecordCreateOrConnectWithoutOrganizationInput = {
  create: BestMetricRecordCreateWithoutOrganizationInput;
  where: BestMetricRecordWhereUniqueInput;
};

export type BestMetricRecordCreateWithoutOrganizationInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  metric: Metric;
  record_type: Best_Metric_Record_Type;
  team_id?: InputMaybe<Scalars['String']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
  value: Scalars['Float']['input'];
};

export type BestMetricRecordListRelationFilter = {
  every?: InputMaybe<BestMetricRecordWhereInput>;
  none?: InputMaybe<BestMetricRecordWhereInput>;
  some?: InputMaybe<BestMetricRecordWhereInput>;
};

export type BestMetricRecordOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
  count?: InputMaybe<SortOrder>;
};

export type BestMetricRecordOrderByWithRelationInput = {
  id?: InputMaybe<SortOrder>;
  metric?: InputMaybe<SortOrder>;
  organization?: InputMaybe<OrganizationOrderByWithRelationInput>;
  organization_id?: InputMaybe<SortOrder>;
  record_type?: InputMaybe<SortOrder>;
  team_id?: InputMaybe<SortOrder>;
  user_id?: InputMaybe<SortOrder>;
  value?: InputMaybe<SortOrder>;
};

export type BestMetricRecordScalarWhereInput = {
  AND?: InputMaybe<Array<BestMetricRecordScalarWhereInput>>;
  id?: InputMaybe<StringFilter>;
  metric?: InputMaybe<EnumMetricFilter>;
  NOT?: InputMaybe<Array<BestMetricRecordScalarWhereInput>>;
  OR?: InputMaybe<Array<BestMetricRecordScalarWhereInput>>;
  organization_id?: InputMaybe<StringFilter>;
  record_type?: InputMaybe<EnumBest_Metric_Record_TypeFilter>;
  team_id?: InputMaybe<StringNullableFilter>;
  user_id?: InputMaybe<StringNullableFilter>;
  value?: InputMaybe<FloatFilter>;
};

export type BestMetricRecordUpdateInput = {
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  metric?: InputMaybe<EnumMetricFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutBest_MetricsInput>;
  record_type?: InputMaybe<EnumBest_Metric_Record_TypeFieldUpdateOperationsInput>;
  team_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  user_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  value?: InputMaybe<FloatFieldUpdateOperationsInput>;
};

export type BestMetricRecordUpdateManyMutationInput = {
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  metric?: InputMaybe<EnumMetricFieldUpdateOperationsInput>;
  record_type?: InputMaybe<EnumBest_Metric_Record_TypeFieldUpdateOperationsInput>;
  team_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  user_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  value?: InputMaybe<FloatFieldUpdateOperationsInput>;
};

export type BestMetricRecordUpdateManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<BestMetricRecordWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<BestMetricRecordCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<BestMetricRecordCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<BestMetricRecordCreateManyOrganizationInputEnvelope>;
  delete?: InputMaybe<Array<BestMetricRecordWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<BestMetricRecordScalarWhereInput>>;
  disconnect?: InputMaybe<Array<BestMetricRecordWhereUniqueInput>>;
  set?: InputMaybe<Array<BestMetricRecordWhereUniqueInput>>;
  update?: InputMaybe<Array<BestMetricRecordUpdateWithWhereUniqueWithoutOrganizationInput>>;
  updateMany?: InputMaybe<Array<BestMetricRecordUpdateManyWithWhereWithoutOrganizationInput>>;
  upsert?: InputMaybe<Array<BestMetricRecordUpsertWithWhereUniqueWithoutOrganizationInput>>;
};

export type BestMetricRecordUpdateManyWithWhereWithoutOrganizationInput = {
  data: BestMetricRecordUpdateManyMutationInput;
  where: BestMetricRecordScalarWhereInput;
};

export type BestMetricRecordUpdateWithoutOrganizationInput = {
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  metric?: InputMaybe<EnumMetricFieldUpdateOperationsInput>;
  record_type?: InputMaybe<EnumBest_Metric_Record_TypeFieldUpdateOperationsInput>;
  team_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  user_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  value?: InputMaybe<FloatFieldUpdateOperationsInput>;
};

export type BestMetricRecordUpdateWithWhereUniqueWithoutOrganizationInput = {
  data: BestMetricRecordUpdateWithoutOrganizationInput;
  where: BestMetricRecordWhereUniqueInput;
};

export type BestMetricRecordUpsertWithWhereUniqueWithoutOrganizationInput = {
  create: BestMetricRecordCreateWithoutOrganizationInput;
  update: BestMetricRecordUpdateWithoutOrganizationInput;
  where: BestMetricRecordWhereUniqueInput;
};

export type BestMetricRecordWhereInput = {
  AND?: InputMaybe<Array<BestMetricRecordWhereInput>>;
  id?: InputMaybe<StringFilter>;
  metric?: InputMaybe<EnumMetricFilter>;
  NOT?: InputMaybe<Array<BestMetricRecordWhereInput>>;
  OR?: InputMaybe<Array<BestMetricRecordWhereInput>>;
  organization?: InputMaybe<OrganizationWhereInput>;
  organization_id?: InputMaybe<StringFilter>;
  record_type?: InputMaybe<EnumBest_Metric_Record_TypeFilter>;
  team_id?: InputMaybe<StringNullableFilter>;
  user_id?: InputMaybe<StringNullableFilter>;
  value?: InputMaybe<FloatFilter>;
};

export type BestMetricRecordWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']['input']>;
};

export enum BgMusicOption {
  Custom = 'Custom',
  Default = 'Default',
  None = 'None'
}

export type BoolFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars['Boolean']['input']>;
};

export type BoolFilter = {
  equals?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<NestedBoolFilter>;
};

export type BoolNullableFilter = {
  equals?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<NestedBoolNullableFilter>;
};

export enum Cadence {
  Days = 'days',
  Months = 'months',
  Weeks = 'weeks'
}

/** Data callback */
export type Callback = {
  __typename?: 'Callback';
  end: Scalars['DateTime']['output'];
  intent: LeadIntent;
  start: Scalars['DateTime']['output'];
};

export enum CallbackType {
  General = 'General',
  None = 'None',
  Scheduled = 'Scheduled'
}

/** CallDetailStatSummary with lead activities list */
export type CallDetailStatSummary = {
  __typename?: 'CallDetailStatSummary';
  total_closed?: Maybe<Scalars['Int']['output']>;
  total_held?: Maybe<Scalars['Int']['output']>;
  total_set?: Maybe<Scalars['Int']['output']>;
};

export type CallMeNow = {
  __typename?: 'callMeNow';
  created_at: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  lead: Lead;
  lead_id: Scalars['String']['output'];
  organization: Organization;
  organization_id: Scalars['String']['output'];
  status: CallMeNowStatus;
  /** Returns the successful attempt */
  successful_attempt?: Maybe<CallMeNowAttempt>;
  updated_at: Scalars['DateTime']['output'];
};

export type CallMeNowAttempt = {
  __typename?: 'callMeNowAttempt';
  attempt_number: Scalars['Int']['output'];
  call_me_now_id: Scalars['String']['output'];
  callMeNow: CallMeNow;
  created_at: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  lead: Lead;
  lead_id: Scalars['String']['output'];
  organization: Organization;
  organization_id: Scalars['String']['output'];
  respond_by?: Maybe<Scalars['DateTime']['output']>;
  status: CallMeNowStatus;
  updated_at: Scalars['DateTime']['output'];
  user?: Maybe<User>;
  user_id?: Maybe<Scalars['String']['output']>;
};

export type CallMeNowAttemptCreateManyCallMeNowInput = {
  attempt_number?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  lead_id: Scalars['String']['input'];
  organization_id: Scalars['String']['input'];
  respond_by?: InputMaybe<Scalars['DateTime']['input']>;
  status?: InputMaybe<CallMeNowStatus>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
};

export type CallMeNowAttemptCreateManyCallMeNowInputEnvelope = {
  data?: InputMaybe<Array<CallMeNowAttemptCreateManyCallMeNowInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CallMeNowAttemptCreateManyLeadInput = {
  attempt_number?: InputMaybe<Scalars['Int']['input']>;
  call_me_now_id: Scalars['String']['input'];
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  organization_id: Scalars['String']['input'];
  respond_by?: InputMaybe<Scalars['DateTime']['input']>;
  status?: InputMaybe<CallMeNowStatus>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
};

export type CallMeNowAttemptCreateManyLeadInputEnvelope = {
  data?: InputMaybe<Array<CallMeNowAttemptCreateManyLeadInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CallMeNowAttemptCreateManyOrganizationInput = {
  attempt_number?: InputMaybe<Scalars['Int']['input']>;
  call_me_now_id: Scalars['String']['input'];
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  lead_id: Scalars['String']['input'];
  respond_by?: InputMaybe<Scalars['DateTime']['input']>;
  status?: InputMaybe<CallMeNowStatus>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
};

export type CallMeNowAttemptCreateManyOrganizationInputEnvelope = {
  data?: InputMaybe<Array<CallMeNowAttemptCreateManyOrganizationInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CallMeNowAttemptCreateManyUserInput = {
  attempt_number?: InputMaybe<Scalars['Int']['input']>;
  call_me_now_id: Scalars['String']['input'];
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  lead_id: Scalars['String']['input'];
  organization_id: Scalars['String']['input'];
  respond_by?: InputMaybe<Scalars['DateTime']['input']>;
  status?: InputMaybe<CallMeNowStatus>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type CallMeNowAttemptCreateManyUserInputEnvelope = {
  data?: InputMaybe<Array<CallMeNowAttemptCreateManyUserInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CallMeNowAttemptCreateNestedManyWithoutCallMeNowInput = {
  connect?: InputMaybe<Array<CallMeNowAttemptWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CallMeNowAttemptCreateOrConnectWithoutCallMeNowInput>>;
  create?: InputMaybe<Array<CallMeNowAttemptCreateWithoutCallMeNowInput>>;
  createMany?: InputMaybe<CallMeNowAttemptCreateManyCallMeNowInputEnvelope>;
};

export type CallMeNowAttemptCreateNestedManyWithoutLeadInput = {
  connect?: InputMaybe<Array<CallMeNowAttemptWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CallMeNowAttemptCreateOrConnectWithoutLeadInput>>;
  create?: InputMaybe<Array<CallMeNowAttemptCreateWithoutLeadInput>>;
  createMany?: InputMaybe<CallMeNowAttemptCreateManyLeadInputEnvelope>;
};

export type CallMeNowAttemptCreateNestedManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<CallMeNowAttemptWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CallMeNowAttemptCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<CallMeNowAttemptCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<CallMeNowAttemptCreateManyOrganizationInputEnvelope>;
};

export type CallMeNowAttemptCreateNestedManyWithoutUserInput = {
  connect?: InputMaybe<Array<CallMeNowAttemptWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CallMeNowAttemptCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<CallMeNowAttemptCreateWithoutUserInput>>;
  createMany?: InputMaybe<CallMeNowAttemptCreateManyUserInputEnvelope>;
};

export type CallMeNowAttemptCreateOrConnectWithoutCallMeNowInput = {
  create: CallMeNowAttemptCreateWithoutCallMeNowInput;
  where: CallMeNowAttemptWhereUniqueInput;
};

export type CallMeNowAttemptCreateOrConnectWithoutLeadInput = {
  create: CallMeNowAttemptCreateWithoutLeadInput;
  where: CallMeNowAttemptWhereUniqueInput;
};

export type CallMeNowAttemptCreateOrConnectWithoutOrganizationInput = {
  create: CallMeNowAttemptCreateWithoutOrganizationInput;
  where: CallMeNowAttemptWhereUniqueInput;
};

export type CallMeNowAttemptCreateOrConnectWithoutUserInput = {
  create: CallMeNowAttemptCreateWithoutUserInput;
  where: CallMeNowAttemptWhereUniqueInput;
};

export type CallMeNowAttemptCreateWithoutCallMeNowInput = {
  attempt_number?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  lead: LeadCreateNestedOneWithoutCallMeNowAttemptsInput;
  organization: OrganizationCreateNestedOneWithoutCallMeNowAttemptsInput;
  respond_by?: InputMaybe<Scalars['DateTime']['input']>;
  status?: InputMaybe<CallMeNowStatus>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user?: InputMaybe<UserCreateNestedOneWithoutCallMeNowAttemptsInput>;
};

export type CallMeNowAttemptCreateWithoutLeadInput = {
  attempt_number?: InputMaybe<Scalars['Int']['input']>;
  callMeNow: CallMeNowCreateNestedOneWithoutCallMeNowAttemptsInput;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  organization: OrganizationCreateNestedOneWithoutCallMeNowAttemptsInput;
  respond_by?: InputMaybe<Scalars['DateTime']['input']>;
  status?: InputMaybe<CallMeNowStatus>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user?: InputMaybe<UserCreateNestedOneWithoutCallMeNowAttemptsInput>;
};

export type CallMeNowAttemptCreateWithoutOrganizationInput = {
  attempt_number?: InputMaybe<Scalars['Int']['input']>;
  callMeNow: CallMeNowCreateNestedOneWithoutCallMeNowAttemptsInput;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  lead: LeadCreateNestedOneWithoutCallMeNowAttemptsInput;
  respond_by?: InputMaybe<Scalars['DateTime']['input']>;
  status?: InputMaybe<CallMeNowStatus>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user?: InputMaybe<UserCreateNestedOneWithoutCallMeNowAttemptsInput>;
};

export type CallMeNowAttemptCreateWithoutUserInput = {
  attempt_number?: InputMaybe<Scalars['Int']['input']>;
  callMeNow: CallMeNowCreateNestedOneWithoutCallMeNowAttemptsInput;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  lead: LeadCreateNestedOneWithoutCallMeNowAttemptsInput;
  organization: OrganizationCreateNestedOneWithoutCallMeNowAttemptsInput;
  respond_by?: InputMaybe<Scalars['DateTime']['input']>;
  status?: InputMaybe<CallMeNowStatus>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type CallMeNowAttemptListRelationFilter = {
  every?: InputMaybe<CallMeNowAttemptWhereInput>;
  none?: InputMaybe<CallMeNowAttemptWhereInput>;
  some?: InputMaybe<CallMeNowAttemptWhereInput>;
};

export type CallMeNowAttemptOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
  count?: InputMaybe<SortOrder>;
};

export type CallMeNowAttemptScalarWhereInput = {
  AND?: InputMaybe<Array<CallMeNowAttemptScalarWhereInput>>;
  attempt_number?: InputMaybe<IntFilter>;
  call_me_now_id?: InputMaybe<StringFilter>;
  created_at?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  lead_id?: InputMaybe<StringFilter>;
  NOT?: InputMaybe<Array<CallMeNowAttemptScalarWhereInput>>;
  OR?: InputMaybe<Array<CallMeNowAttemptScalarWhereInput>>;
  organization_id?: InputMaybe<StringFilter>;
  respond_by?: InputMaybe<DateTimeNullableFilter>;
  status?: InputMaybe<EnumCallMeNowStatusFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
  user_id?: InputMaybe<StringNullableFilter>;
};

export type CallMeNowAttemptUpdateManyMutationInput = {
  attempt_number?: InputMaybe<IntFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  respond_by?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumCallMeNowStatusFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type CallMeNowAttemptUpdateManyWithoutCallMeNowInput = {
  connect?: InputMaybe<Array<CallMeNowAttemptWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CallMeNowAttemptCreateOrConnectWithoutCallMeNowInput>>;
  create?: InputMaybe<Array<CallMeNowAttemptCreateWithoutCallMeNowInput>>;
  createMany?: InputMaybe<CallMeNowAttemptCreateManyCallMeNowInputEnvelope>;
  delete?: InputMaybe<Array<CallMeNowAttemptWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CallMeNowAttemptScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CallMeNowAttemptWhereUniqueInput>>;
  set?: InputMaybe<Array<CallMeNowAttemptWhereUniqueInput>>;
  update?: InputMaybe<Array<CallMeNowAttemptUpdateWithWhereUniqueWithoutCallMeNowInput>>;
  updateMany?: InputMaybe<Array<CallMeNowAttemptUpdateManyWithWhereWithoutCallMeNowInput>>;
  upsert?: InputMaybe<Array<CallMeNowAttemptUpsertWithWhereUniqueWithoutCallMeNowInput>>;
};

export type CallMeNowAttemptUpdateManyWithoutLeadInput = {
  connect?: InputMaybe<Array<CallMeNowAttemptWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CallMeNowAttemptCreateOrConnectWithoutLeadInput>>;
  create?: InputMaybe<Array<CallMeNowAttemptCreateWithoutLeadInput>>;
  createMany?: InputMaybe<CallMeNowAttemptCreateManyLeadInputEnvelope>;
  delete?: InputMaybe<Array<CallMeNowAttemptWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CallMeNowAttemptScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CallMeNowAttemptWhereUniqueInput>>;
  set?: InputMaybe<Array<CallMeNowAttemptWhereUniqueInput>>;
  update?: InputMaybe<Array<CallMeNowAttemptUpdateWithWhereUniqueWithoutLeadInput>>;
  updateMany?: InputMaybe<Array<CallMeNowAttemptUpdateManyWithWhereWithoutLeadInput>>;
  upsert?: InputMaybe<Array<CallMeNowAttemptUpsertWithWhereUniqueWithoutLeadInput>>;
};

export type CallMeNowAttemptUpdateManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<CallMeNowAttemptWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CallMeNowAttemptCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<CallMeNowAttemptCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<CallMeNowAttemptCreateManyOrganizationInputEnvelope>;
  delete?: InputMaybe<Array<CallMeNowAttemptWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CallMeNowAttemptScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CallMeNowAttemptWhereUniqueInput>>;
  set?: InputMaybe<Array<CallMeNowAttemptWhereUniqueInput>>;
  update?: InputMaybe<Array<CallMeNowAttemptUpdateWithWhereUniqueWithoutOrganizationInput>>;
  updateMany?: InputMaybe<Array<CallMeNowAttemptUpdateManyWithWhereWithoutOrganizationInput>>;
  upsert?: InputMaybe<Array<CallMeNowAttemptUpsertWithWhereUniqueWithoutOrganizationInput>>;
};

export type CallMeNowAttemptUpdateManyWithoutUserInput = {
  connect?: InputMaybe<Array<CallMeNowAttemptWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CallMeNowAttemptCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<CallMeNowAttemptCreateWithoutUserInput>>;
  createMany?: InputMaybe<CallMeNowAttemptCreateManyUserInputEnvelope>;
  delete?: InputMaybe<Array<CallMeNowAttemptWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CallMeNowAttemptScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CallMeNowAttemptWhereUniqueInput>>;
  set?: InputMaybe<Array<CallMeNowAttemptWhereUniqueInput>>;
  update?: InputMaybe<Array<CallMeNowAttemptUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: InputMaybe<Array<CallMeNowAttemptUpdateManyWithWhereWithoutUserInput>>;
  upsert?: InputMaybe<Array<CallMeNowAttemptUpsertWithWhereUniqueWithoutUserInput>>;
};

export type CallMeNowAttemptUpdateManyWithWhereWithoutCallMeNowInput = {
  data: CallMeNowAttemptUpdateManyMutationInput;
  where: CallMeNowAttemptScalarWhereInput;
};

export type CallMeNowAttemptUpdateManyWithWhereWithoutLeadInput = {
  data: CallMeNowAttemptUpdateManyMutationInput;
  where: CallMeNowAttemptScalarWhereInput;
};

export type CallMeNowAttemptUpdateManyWithWhereWithoutOrganizationInput = {
  data: CallMeNowAttemptUpdateManyMutationInput;
  where: CallMeNowAttemptScalarWhereInput;
};

export type CallMeNowAttemptUpdateManyWithWhereWithoutUserInput = {
  data: CallMeNowAttemptUpdateManyMutationInput;
  where: CallMeNowAttemptScalarWhereInput;
};

export type CallMeNowAttemptUpdateWithoutCallMeNowInput = {
  attempt_number?: InputMaybe<IntFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lead?: InputMaybe<LeadUpdateOneRequiredWithoutCallMeNowAttemptsInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutCallMeNowAttemptsInput>;
  respond_by?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumCallMeNowStatusFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneWithoutCallMeNowAttemptsInput>;
};

export type CallMeNowAttemptUpdateWithoutLeadInput = {
  attempt_number?: InputMaybe<IntFieldUpdateOperationsInput>;
  callMeNow?: InputMaybe<CallMeNowUpdateOneRequiredWithoutCallMeNowAttemptsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutCallMeNowAttemptsInput>;
  respond_by?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumCallMeNowStatusFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneWithoutCallMeNowAttemptsInput>;
};

export type CallMeNowAttemptUpdateWithoutOrganizationInput = {
  attempt_number?: InputMaybe<IntFieldUpdateOperationsInput>;
  callMeNow?: InputMaybe<CallMeNowUpdateOneRequiredWithoutCallMeNowAttemptsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lead?: InputMaybe<LeadUpdateOneRequiredWithoutCallMeNowAttemptsInput>;
  respond_by?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumCallMeNowStatusFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneWithoutCallMeNowAttemptsInput>;
};

export type CallMeNowAttemptUpdateWithoutUserInput = {
  attempt_number?: InputMaybe<IntFieldUpdateOperationsInput>;
  callMeNow?: InputMaybe<CallMeNowUpdateOneRequiredWithoutCallMeNowAttemptsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lead?: InputMaybe<LeadUpdateOneRequiredWithoutCallMeNowAttemptsInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutCallMeNowAttemptsInput>;
  respond_by?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumCallMeNowStatusFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type CallMeNowAttemptUpdateWithWhereUniqueWithoutCallMeNowInput = {
  data: CallMeNowAttemptUpdateWithoutCallMeNowInput;
  where: CallMeNowAttemptWhereUniqueInput;
};

export type CallMeNowAttemptUpdateWithWhereUniqueWithoutLeadInput = {
  data: CallMeNowAttemptUpdateWithoutLeadInput;
  where: CallMeNowAttemptWhereUniqueInput;
};

export type CallMeNowAttemptUpdateWithWhereUniqueWithoutOrganizationInput = {
  data: CallMeNowAttemptUpdateWithoutOrganizationInput;
  where: CallMeNowAttemptWhereUniqueInput;
};

export type CallMeNowAttemptUpdateWithWhereUniqueWithoutUserInput = {
  data: CallMeNowAttemptUpdateWithoutUserInput;
  where: CallMeNowAttemptWhereUniqueInput;
};

export type CallMeNowAttemptUpsertWithWhereUniqueWithoutCallMeNowInput = {
  create: CallMeNowAttemptCreateWithoutCallMeNowInput;
  update: CallMeNowAttemptUpdateWithoutCallMeNowInput;
  where: CallMeNowAttemptWhereUniqueInput;
};

export type CallMeNowAttemptUpsertWithWhereUniqueWithoutLeadInput = {
  create: CallMeNowAttemptCreateWithoutLeadInput;
  update: CallMeNowAttemptUpdateWithoutLeadInput;
  where: CallMeNowAttemptWhereUniqueInput;
};

export type CallMeNowAttemptUpsertWithWhereUniqueWithoutOrganizationInput = {
  create: CallMeNowAttemptCreateWithoutOrganizationInput;
  update: CallMeNowAttemptUpdateWithoutOrganizationInput;
  where: CallMeNowAttemptWhereUniqueInput;
};

export type CallMeNowAttemptUpsertWithWhereUniqueWithoutUserInput = {
  create: CallMeNowAttemptCreateWithoutUserInput;
  update: CallMeNowAttemptUpdateWithoutUserInput;
  where: CallMeNowAttemptWhereUniqueInput;
};

export type CallMeNowAttemptWhereInput = {
  AND?: InputMaybe<Array<CallMeNowAttemptWhereInput>>;
  attempt_number?: InputMaybe<IntFilter>;
  call_me_now_id?: InputMaybe<StringFilter>;
  callMeNow?: InputMaybe<CallMeNowWhereInput>;
  created_at?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  lead?: InputMaybe<LeadWhereInput>;
  lead_id?: InputMaybe<StringFilter>;
  NOT?: InputMaybe<Array<CallMeNowAttemptWhereInput>>;
  OR?: InputMaybe<Array<CallMeNowAttemptWhereInput>>;
  organization?: InputMaybe<OrganizationWhereInput>;
  organization_id?: InputMaybe<StringFilter>;
  respond_by?: InputMaybe<DateTimeNullableFilter>;
  status?: InputMaybe<EnumCallMeNowStatusFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserWhereInput>;
  user_id?: InputMaybe<StringNullableFilter>;
};

export type CallMeNowAttemptWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']['input']>;
};

export type CallMeNowCreateManyLeadInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  inboundConciergeEventId: Scalars['String']['input'];
  organization_id: Scalars['String']['input'];
  status?: InputMaybe<CallMeNowStatus>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type CallMeNowCreateManyLeadInputEnvelope = {
  data?: InputMaybe<Array<CallMeNowCreateManyLeadInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CallMeNowCreateManyOrganizationInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  inboundConciergeEventId: Scalars['String']['input'];
  lead_id: Scalars['String']['input'];
  status?: InputMaybe<CallMeNowStatus>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type CallMeNowCreateManyOrganizationInputEnvelope = {
  data?: InputMaybe<Array<CallMeNowCreateManyOrganizationInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CallMeNowCreateNestedManyWithoutLeadInput = {
  connect?: InputMaybe<Array<CallMeNowWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CallMeNowCreateOrConnectWithoutLeadInput>>;
  create?: InputMaybe<Array<CallMeNowCreateWithoutLeadInput>>;
  createMany?: InputMaybe<CallMeNowCreateManyLeadInputEnvelope>;
};

export type CallMeNowCreateNestedManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<CallMeNowWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CallMeNowCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<CallMeNowCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<CallMeNowCreateManyOrganizationInputEnvelope>;
};

export type CallMeNowCreateNestedOneWithoutCallMeNowAttemptsInput = {
  connect?: InputMaybe<CallMeNowWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CallMeNowCreateOrConnectWithoutCallMeNowAttemptsInput>;
  create?: InputMaybe<CallMeNowCreateWithoutCallMeNowAttemptsInput>;
};

export type CallMeNowCreateNestedOneWithoutInboundConciergeEventInput = {
  connect?: InputMaybe<CallMeNowWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CallMeNowCreateOrConnectWithoutInboundConciergeEventInput>;
  create?: InputMaybe<CallMeNowCreateWithoutInboundConciergeEventInput>;
};

export type CallMeNowCreateOrConnectWithoutCallMeNowAttemptsInput = {
  create: CallMeNowCreateWithoutCallMeNowAttemptsInput;
  where: CallMeNowWhereUniqueInput;
};

export type CallMeNowCreateOrConnectWithoutInboundConciergeEventInput = {
  create: CallMeNowCreateWithoutInboundConciergeEventInput;
  where: CallMeNowWhereUniqueInput;
};

export type CallMeNowCreateOrConnectWithoutLeadInput = {
  create: CallMeNowCreateWithoutLeadInput;
  where: CallMeNowWhereUniqueInput;
};

export type CallMeNowCreateOrConnectWithoutOrganizationInput = {
  create: CallMeNowCreateWithoutOrganizationInput;
  where: CallMeNowWhereUniqueInput;
};

export type CallMeNowCreateWithoutCallMeNowAttemptsInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  inboundConciergeEvent: InboundConciergeEventCreateNestedOneWithoutCallMeNowInput;
  lead: LeadCreateNestedOneWithoutCallMeNowsInput;
  organization: OrganizationCreateNestedOneWithoutCallMeNowsInput;
  status?: InputMaybe<CallMeNowStatus>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type CallMeNowCreateWithoutInboundConciergeEventInput = {
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptCreateNestedManyWithoutCallMeNowInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  lead: LeadCreateNestedOneWithoutCallMeNowsInput;
  organization: OrganizationCreateNestedOneWithoutCallMeNowsInput;
  status?: InputMaybe<CallMeNowStatus>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type CallMeNowCreateWithoutLeadInput = {
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptCreateNestedManyWithoutCallMeNowInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  inboundConciergeEvent: InboundConciergeEventCreateNestedOneWithoutCallMeNowInput;
  organization: OrganizationCreateNestedOneWithoutCallMeNowsInput;
  status?: InputMaybe<CallMeNowStatus>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type CallMeNowCreateWithoutOrganizationInput = {
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptCreateNestedManyWithoutCallMeNowInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  inboundConciergeEvent: InboundConciergeEventCreateNestedOneWithoutCallMeNowInput;
  lead: LeadCreateNestedOneWithoutCallMeNowsInput;
  status?: InputMaybe<CallMeNowStatus>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type CallMeNowListRelationFilter = {
  every?: InputMaybe<CallMeNowWhereInput>;
  none?: InputMaybe<CallMeNowWhereInput>;
  some?: InputMaybe<CallMeNowWhereInput>;
};

export type CallMeNowOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
  count?: InputMaybe<SortOrder>;
};

export type CallMeNowOrderByWithRelationInput = {
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptOrderByRelationAggregateInput>;
  created_at?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  inboundConciergeEvent?: InputMaybe<InboundConciergeEventOrderByWithRelationInput>;
  inboundConciergeEventId?: InputMaybe<SortOrder>;
  lead?: InputMaybe<LeadOrderByWithRelationInput>;
  lead_id?: InputMaybe<SortOrder>;
  organization?: InputMaybe<OrganizationOrderByWithRelationInput>;
  organization_id?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
};

export type CallMeNowScalarWhereInput = {
  AND?: InputMaybe<Array<CallMeNowScalarWhereInput>>;
  created_at?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  inboundConciergeEventId?: InputMaybe<StringFilter>;
  lead_id?: InputMaybe<StringFilter>;
  NOT?: InputMaybe<Array<CallMeNowScalarWhereInput>>;
  OR?: InputMaybe<Array<CallMeNowScalarWhereInput>>;
  organization_id?: InputMaybe<StringFilter>;
  status?: InputMaybe<EnumCallMeNowStatusFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
};

export enum CallMeNowStatus {
  Cancelled = 'Cancelled',
  Complete = 'Complete',
  Connecting = 'Connecting',
  Declined = 'Declined',
  Failure = 'Failure',
  New = 'New'
}

export type CallMeNowUpdateManyMutationInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumCallMeNowStatusFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type CallMeNowUpdateManyWithoutLeadInput = {
  connect?: InputMaybe<Array<CallMeNowWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CallMeNowCreateOrConnectWithoutLeadInput>>;
  create?: InputMaybe<Array<CallMeNowCreateWithoutLeadInput>>;
  createMany?: InputMaybe<CallMeNowCreateManyLeadInputEnvelope>;
  delete?: InputMaybe<Array<CallMeNowWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CallMeNowScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CallMeNowWhereUniqueInput>>;
  set?: InputMaybe<Array<CallMeNowWhereUniqueInput>>;
  update?: InputMaybe<Array<CallMeNowUpdateWithWhereUniqueWithoutLeadInput>>;
  updateMany?: InputMaybe<Array<CallMeNowUpdateManyWithWhereWithoutLeadInput>>;
  upsert?: InputMaybe<Array<CallMeNowUpsertWithWhereUniqueWithoutLeadInput>>;
};

export type CallMeNowUpdateManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<CallMeNowWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CallMeNowCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<CallMeNowCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<CallMeNowCreateManyOrganizationInputEnvelope>;
  delete?: InputMaybe<Array<CallMeNowWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CallMeNowScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CallMeNowWhereUniqueInput>>;
  set?: InputMaybe<Array<CallMeNowWhereUniqueInput>>;
  update?: InputMaybe<Array<CallMeNowUpdateWithWhereUniqueWithoutOrganizationInput>>;
  updateMany?: InputMaybe<Array<CallMeNowUpdateManyWithWhereWithoutOrganizationInput>>;
  upsert?: InputMaybe<Array<CallMeNowUpsertWithWhereUniqueWithoutOrganizationInput>>;
};

export type CallMeNowUpdateManyWithWhereWithoutLeadInput = {
  data: CallMeNowUpdateManyMutationInput;
  where: CallMeNowScalarWhereInput;
};

export type CallMeNowUpdateManyWithWhereWithoutOrganizationInput = {
  data: CallMeNowUpdateManyMutationInput;
  where: CallMeNowScalarWhereInput;
};

export type CallMeNowUpdateOneRequiredWithoutCallMeNowAttemptsInput = {
  connect?: InputMaybe<CallMeNowWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CallMeNowCreateOrConnectWithoutCallMeNowAttemptsInput>;
  create?: InputMaybe<CallMeNowCreateWithoutCallMeNowAttemptsInput>;
  update?: InputMaybe<CallMeNowUpdateWithoutCallMeNowAttemptsInput>;
  upsert?: InputMaybe<CallMeNowUpsertWithoutCallMeNowAttemptsInput>;
};

export type CallMeNowUpdateOneWithoutInboundConciergeEventInput = {
  connect?: InputMaybe<CallMeNowWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CallMeNowCreateOrConnectWithoutInboundConciergeEventInput>;
  create?: InputMaybe<CallMeNowCreateWithoutInboundConciergeEventInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<CallMeNowUpdateWithoutInboundConciergeEventInput>;
  upsert?: InputMaybe<CallMeNowUpsertWithoutInboundConciergeEventInput>;
};

export type CallMeNowUpdateWithoutCallMeNowAttemptsInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inboundConciergeEvent?: InputMaybe<InboundConciergeEventUpdateOneRequiredWithoutCallMeNowInput>;
  lead?: InputMaybe<LeadUpdateOneRequiredWithoutCallMeNowsInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutCallMeNowsInput>;
  status?: InputMaybe<EnumCallMeNowStatusFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type CallMeNowUpdateWithoutInboundConciergeEventInput = {
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptUpdateManyWithoutCallMeNowInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lead?: InputMaybe<LeadUpdateOneRequiredWithoutCallMeNowsInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutCallMeNowsInput>;
  status?: InputMaybe<EnumCallMeNowStatusFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type CallMeNowUpdateWithoutLeadInput = {
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptUpdateManyWithoutCallMeNowInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inboundConciergeEvent?: InputMaybe<InboundConciergeEventUpdateOneRequiredWithoutCallMeNowInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutCallMeNowsInput>;
  status?: InputMaybe<EnumCallMeNowStatusFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type CallMeNowUpdateWithoutOrganizationInput = {
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptUpdateManyWithoutCallMeNowInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inboundConciergeEvent?: InputMaybe<InboundConciergeEventUpdateOneRequiredWithoutCallMeNowInput>;
  lead?: InputMaybe<LeadUpdateOneRequiredWithoutCallMeNowsInput>;
  status?: InputMaybe<EnumCallMeNowStatusFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type CallMeNowUpdateWithWhereUniqueWithoutLeadInput = {
  data: CallMeNowUpdateWithoutLeadInput;
  where: CallMeNowWhereUniqueInput;
};

export type CallMeNowUpdateWithWhereUniqueWithoutOrganizationInput = {
  data: CallMeNowUpdateWithoutOrganizationInput;
  where: CallMeNowWhereUniqueInput;
};

export type CallMeNowUpsertWithoutCallMeNowAttemptsInput = {
  create: CallMeNowCreateWithoutCallMeNowAttemptsInput;
  update: CallMeNowUpdateWithoutCallMeNowAttemptsInput;
};

export type CallMeNowUpsertWithoutInboundConciergeEventInput = {
  create: CallMeNowCreateWithoutInboundConciergeEventInput;
  update: CallMeNowUpdateWithoutInboundConciergeEventInput;
};

export type CallMeNowUpsertWithWhereUniqueWithoutLeadInput = {
  create: CallMeNowCreateWithoutLeadInput;
  update: CallMeNowUpdateWithoutLeadInput;
  where: CallMeNowWhereUniqueInput;
};

export type CallMeNowUpsertWithWhereUniqueWithoutOrganizationInput = {
  create: CallMeNowCreateWithoutOrganizationInput;
  update: CallMeNowUpdateWithoutOrganizationInput;
  where: CallMeNowWhereUniqueInput;
};

export type CallMeNowWhereInput = {
  AND?: InputMaybe<Array<CallMeNowWhereInput>>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptListRelationFilter>;
  created_at?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  inboundConciergeEvent?: InputMaybe<InboundConciergeEventWhereInput>;
  inboundConciergeEventId?: InputMaybe<StringFilter>;
  lead?: InputMaybe<LeadWhereInput>;
  lead_id?: InputMaybe<StringFilter>;
  NOT?: InputMaybe<Array<CallMeNowWhereInput>>;
  OR?: InputMaybe<Array<CallMeNowWhereInput>>;
  organization?: InputMaybe<OrganizationWhereInput>;
  organization_id?: InputMaybe<StringFilter>;
  status?: InputMaybe<EnumCallMeNowStatusFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
};

export type CallMeNowWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  inboundConciergeEventId?: InputMaybe<Scalars['String']['input']>;
};

export type CallMultiplier = {
  __typename?: 'CallMultiplier';
  default_rate: Scalars['Float']['output'];
  disposition_type?: Maybe<Dispositiontype>;
  id: Scalars['String']['output'];
  is_exception: Scalars['Boolean']['output'];
  organization: Organization;
  organization_id: Scalars['String']['output'];
  person?: Maybe<Callresult>;
  phase: Phase;
  round: Scalars['Int']['output'];
};

export type CallMultiplierCreateManyOrganizationInput = {
  default_rate: Scalars['Float']['input'];
  disposition_type?: InputMaybe<Dispositiontype>;
  id?: InputMaybe<Scalars['String']['input']>;
  is_exception?: InputMaybe<Scalars['Boolean']['input']>;
  person?: InputMaybe<Callresult>;
  phase: Phase;
  round?: InputMaybe<Scalars['Int']['input']>;
};

export type CallMultiplierCreateManyOrganizationInputEnvelope = {
  data?: InputMaybe<Array<CallMultiplierCreateManyOrganizationInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CallMultiplierCreateNestedManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<CallMultiplierWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CallMultiplierCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<CallMultiplierCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<CallMultiplierCreateManyOrganizationInputEnvelope>;
};

export type CallMultiplierCreateOrConnectWithoutOrganizationInput = {
  create: CallMultiplierCreateWithoutOrganizationInput;
  where: CallMultiplierWhereUniqueInput;
};

export type CallMultiplierCreateWithoutOrganizationInput = {
  default_rate: Scalars['Float']['input'];
  disposition_type?: InputMaybe<Dispositiontype>;
  id?: InputMaybe<Scalars['String']['input']>;
  is_exception?: InputMaybe<Scalars['Boolean']['input']>;
  person?: InputMaybe<Callresult>;
  phase: Phase;
  round?: InputMaybe<Scalars['Int']['input']>;
};

export type CallMultiplierListRelationFilter = {
  every?: InputMaybe<CallMultiplierWhereInput>;
  none?: InputMaybe<CallMultiplierWhereInput>;
  some?: InputMaybe<CallMultiplierWhereInput>;
};

export type CallMultiplierOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
  count?: InputMaybe<SortOrder>;
};

export type CallMultiplierScalarWhereInput = {
  AND?: InputMaybe<Array<CallMultiplierScalarWhereInput>>;
  default_rate?: InputMaybe<FloatFilter>;
  disposition_type?: InputMaybe<EnumDispositiontypeNullableFilter>;
  id?: InputMaybe<StringFilter>;
  is_exception?: InputMaybe<BoolFilter>;
  NOT?: InputMaybe<Array<CallMultiplierScalarWhereInput>>;
  OR?: InputMaybe<Array<CallMultiplierScalarWhereInput>>;
  organization_id?: InputMaybe<StringFilter>;
  person?: InputMaybe<EnumCallresultNullableFilter>;
  phase?: InputMaybe<EnumPhaseFilter>;
  round?: InputMaybe<IntFilter>;
};

export type CallMultiplierUpdateManyMutationInput = {
  default_rate?: InputMaybe<FloatFieldUpdateOperationsInput>;
  disposition_type?: InputMaybe<NullableEnumDispositiontypeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  is_exception?: InputMaybe<BoolFieldUpdateOperationsInput>;
  person?: InputMaybe<NullableEnumCallresultFieldUpdateOperationsInput>;
  phase?: InputMaybe<EnumPhaseFieldUpdateOperationsInput>;
  round?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type CallMultiplierUpdateManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<CallMultiplierWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CallMultiplierCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<CallMultiplierCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<CallMultiplierCreateManyOrganizationInputEnvelope>;
  delete?: InputMaybe<Array<CallMultiplierWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CallMultiplierScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CallMultiplierWhereUniqueInput>>;
  set?: InputMaybe<Array<CallMultiplierWhereUniqueInput>>;
  update?: InputMaybe<Array<CallMultiplierUpdateWithWhereUniqueWithoutOrganizationInput>>;
  updateMany?: InputMaybe<Array<CallMultiplierUpdateManyWithWhereWithoutOrganizationInput>>;
  upsert?: InputMaybe<Array<CallMultiplierUpsertWithWhereUniqueWithoutOrganizationInput>>;
};

export type CallMultiplierUpdateManyWithWhereWithoutOrganizationInput = {
  data: CallMultiplierUpdateManyMutationInput;
  where: CallMultiplierScalarWhereInput;
};

export type CallMultiplierUpdateWithoutOrganizationInput = {
  default_rate?: InputMaybe<FloatFieldUpdateOperationsInput>;
  disposition_type?: InputMaybe<NullableEnumDispositiontypeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  is_exception?: InputMaybe<BoolFieldUpdateOperationsInput>;
  person?: InputMaybe<NullableEnumCallresultFieldUpdateOperationsInput>;
  phase?: InputMaybe<EnumPhaseFieldUpdateOperationsInput>;
  round?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type CallMultiplierUpdateWithWhereUniqueWithoutOrganizationInput = {
  data: CallMultiplierUpdateWithoutOrganizationInput;
  where: CallMultiplierWhereUniqueInput;
};

export type CallMultiplierUpsertWithWhereUniqueWithoutOrganizationInput = {
  create: CallMultiplierCreateWithoutOrganizationInput;
  update: CallMultiplierUpdateWithoutOrganizationInput;
  where: CallMultiplierWhereUniqueInput;
};

export type CallMultiplierWhereInput = {
  AND?: InputMaybe<Array<CallMultiplierWhereInput>>;
  default_rate?: InputMaybe<FloatFilter>;
  disposition_type?: InputMaybe<EnumDispositiontypeNullableFilter>;
  id?: InputMaybe<StringFilter>;
  is_exception?: InputMaybe<BoolFilter>;
  NOT?: InputMaybe<Array<CallMultiplierWhereInput>>;
  OR?: InputMaybe<Array<CallMultiplierWhereInput>>;
  organization?: InputMaybe<OrganizationWhereInput>;
  organization_id?: InputMaybe<StringFilter>;
  person?: InputMaybe<EnumCallresultNullableFilter>;
  phase?: InputMaybe<EnumPhaseFilter>;
  round?: InputMaybe<IntFilter>;
};

export type CallMultiplierWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']['input']>;
};

/** Summarized Dashboard Input */
export type CallReportLeadArgument = {
  desc?: InputMaybe<Scalars['Boolean']['input']>;
  lead_id: Scalars['String']['input'];
  order_by?: InputMaybe<CallReportListSortOption>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};

/** available option for sorting Call Report List in coaching view */
export enum CallReportListSortOption {
  AssociatedAction = 'AssociatedAction',
  BusinessName = 'BusinessName',
  CallDuration = 'CallDuration',
  CallResult = 'CallResult',
  CallTime = 'CallTime',
  Close = 'Close',
  ClosedByUser = 'ClosedByUser',
  CurrentPhase = 'CurrentPhase',
  DaysSetOut = 'DaysSetOut',
  DialsInCurrentSalesCycle = 'DialsInCurrentSalesCycle',
  EventTime = 'EventTime',
  Held = 'Held',
  Industry = 'Industry',
  LeadId = 'LeadID',
  LeadName = 'LeadName',
  LeadSource = 'LeadSource',
  RecurringRevenue = 'RecurringRevenue',
  Release = 'Release',
  RepName = 'RepName',
  Revenue = 'Revenue',
  RevenueValue = 'RevenueValue',
  Set = 'Set',
  SetByUser = 'SetByUser',
  Skip = 'Skip',
  SubIndustry = 'SubIndustry',
  TotalRecurringRevenue = 'TotalRecurringRevenue',
  TotalRevenue = 'TotalRevenue',
  TotalRevenueValue = 'TotalRevenueValue'
}

export enum Callresult {
  Dm = 'DM',
  DMviaNdm = 'DMviaNDM',
  Ndm = 'NDM',
  NoContact = 'NoContact'
}

/** Filter inputs for call result filter */
export type CallResultFilter = {
  call_result_option?: InputMaybe<Dispositiontype>;
  call_result_type?: InputMaybe<CallResultOptions>;
};

/** Types of call results that can be filtered on */
export enum CallResultOptions {
  Anytime = 'Anytime',
  LastCallResult = 'LastCallResult'
}

export type CallSharingCreateManyCoachInput = {
  conference_id?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  organization_id?: InputMaybe<Scalars['String']['input']>;
  receiver_user_id?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type CallSharingCreateManyCoachInputEnvelope = {
  data?: InputMaybe<Array<CallSharingCreateManyCoachInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CallSharingCreateManyConferenceInput = {
  coach_id?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  organization_id?: InputMaybe<Scalars['String']['input']>;
  receiver_user_id?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type CallSharingCreateManyConferenceInputEnvelope = {
  data?: InputMaybe<Array<CallSharingCreateManyConferenceInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CallSharingCreateManyOrganizationInput = {
  coach_id?: InputMaybe<Scalars['String']['input']>;
  conference_id?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  receiver_user_id?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type CallSharingCreateManyOrganizationInputEnvelope = {
  data?: InputMaybe<Array<CallSharingCreateManyOrganizationInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CallSharingCreateManyReceiver_UserInput = {
  coach_id?: InputMaybe<Scalars['String']['input']>;
  conference_id?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  organization_id?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type CallSharingCreateManyReceiver_UserInputEnvelope = {
  data?: InputMaybe<Array<CallSharingCreateManyReceiver_UserInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CallSharingCreateNestedManyWithoutCoachInput = {
  connect?: InputMaybe<Array<CallSharingWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CallSharingCreateOrConnectWithoutCoachInput>>;
  create?: InputMaybe<Array<CallSharingCreateWithoutCoachInput>>;
  createMany?: InputMaybe<CallSharingCreateManyCoachInputEnvelope>;
};

export type CallSharingCreateNestedManyWithoutConferenceInput = {
  connect?: InputMaybe<Array<CallSharingWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CallSharingCreateOrConnectWithoutConferenceInput>>;
  create?: InputMaybe<Array<CallSharingCreateWithoutConferenceInput>>;
  createMany?: InputMaybe<CallSharingCreateManyConferenceInputEnvelope>;
};

export type CallSharingCreateNestedManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<CallSharingWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CallSharingCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<CallSharingCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<CallSharingCreateManyOrganizationInputEnvelope>;
};

export type CallSharingCreateNestedManyWithoutReceiver_UserInput = {
  connect?: InputMaybe<Array<CallSharingWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CallSharingCreateOrConnectWithoutReceiver_UserInput>>;
  create?: InputMaybe<Array<CallSharingCreateWithoutReceiver_UserInput>>;
  createMany?: InputMaybe<CallSharingCreateManyReceiver_UserInputEnvelope>;
};

export type CallSharingCreateOrConnectWithoutCoachInput = {
  create: CallSharingCreateWithoutCoachInput;
  where: CallSharingWhereUniqueInput;
};

export type CallSharingCreateOrConnectWithoutConferenceInput = {
  create: CallSharingCreateWithoutConferenceInput;
  where: CallSharingWhereUniqueInput;
};

export type CallSharingCreateOrConnectWithoutOrganizationInput = {
  create: CallSharingCreateWithoutOrganizationInput;
  where: CallSharingWhereUniqueInput;
};

export type CallSharingCreateOrConnectWithoutReceiver_UserInput = {
  create: CallSharingCreateWithoutReceiver_UserInput;
  where: CallSharingWhereUniqueInput;
};

export type CallSharingCreateWithoutCoachInput = {
  conference?: InputMaybe<ConferenceCreateNestedOneWithoutCallSharingInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  Organization?: InputMaybe<OrganizationCreateNestedOneWithoutCallSharingsInput>;
  receiver_user?: InputMaybe<UserCreateNestedOneWithoutCallSharingAsUserInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type CallSharingCreateWithoutConferenceInput = {
  coach?: InputMaybe<UserCreateNestedOneWithoutCallSharingAsCoachInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  Organization?: InputMaybe<OrganizationCreateNestedOneWithoutCallSharingsInput>;
  receiver_user?: InputMaybe<UserCreateNestedOneWithoutCallSharingAsUserInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type CallSharingCreateWithoutOrganizationInput = {
  coach?: InputMaybe<UserCreateNestedOneWithoutCallSharingAsCoachInput>;
  conference?: InputMaybe<ConferenceCreateNestedOneWithoutCallSharingInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  receiver_user?: InputMaybe<UserCreateNestedOneWithoutCallSharingAsUserInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type CallSharingCreateWithoutReceiver_UserInput = {
  coach?: InputMaybe<UserCreateNestedOneWithoutCallSharingAsCoachInput>;
  conference?: InputMaybe<ConferenceCreateNestedOneWithoutCallSharingInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  Organization?: InputMaybe<OrganizationCreateNestedOneWithoutCallSharingsInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type CallSharingListRelationFilter = {
  every?: InputMaybe<CallSharingWhereInput>;
  none?: InputMaybe<CallSharingWhereInput>;
  some?: InputMaybe<CallSharingWhereInput>;
};

export type CallSharingOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
  count?: InputMaybe<SortOrder>;
};

export type CallSharingScalarWhereInput = {
  AND?: InputMaybe<Array<CallSharingScalarWhereInput>>;
  coach_id?: InputMaybe<StringNullableFilter>;
  conference_id?: InputMaybe<StringNullableFilter>;
  created_at?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  NOT?: InputMaybe<Array<CallSharingScalarWhereInput>>;
  OR?: InputMaybe<Array<CallSharingScalarWhereInput>>;
  organization_id?: InputMaybe<StringNullableFilter>;
  receiver_user_id?: InputMaybe<StringNullableFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
};

export type CallSharingUpdateManyMutationInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type CallSharingUpdateManyWithoutCoachInput = {
  connect?: InputMaybe<Array<CallSharingWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CallSharingCreateOrConnectWithoutCoachInput>>;
  create?: InputMaybe<Array<CallSharingCreateWithoutCoachInput>>;
  createMany?: InputMaybe<CallSharingCreateManyCoachInputEnvelope>;
  delete?: InputMaybe<Array<CallSharingWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CallSharingScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CallSharingWhereUniqueInput>>;
  set?: InputMaybe<Array<CallSharingWhereUniqueInput>>;
  update?: InputMaybe<Array<CallSharingUpdateWithWhereUniqueWithoutCoachInput>>;
  updateMany?: InputMaybe<Array<CallSharingUpdateManyWithWhereWithoutCoachInput>>;
  upsert?: InputMaybe<Array<CallSharingUpsertWithWhereUniqueWithoutCoachInput>>;
};

export type CallSharingUpdateManyWithoutConferenceInput = {
  connect?: InputMaybe<Array<CallSharingWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CallSharingCreateOrConnectWithoutConferenceInput>>;
  create?: InputMaybe<Array<CallSharingCreateWithoutConferenceInput>>;
  createMany?: InputMaybe<CallSharingCreateManyConferenceInputEnvelope>;
  delete?: InputMaybe<Array<CallSharingWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CallSharingScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CallSharingWhereUniqueInput>>;
  set?: InputMaybe<Array<CallSharingWhereUniqueInput>>;
  update?: InputMaybe<Array<CallSharingUpdateWithWhereUniqueWithoutConferenceInput>>;
  updateMany?: InputMaybe<Array<CallSharingUpdateManyWithWhereWithoutConferenceInput>>;
  upsert?: InputMaybe<Array<CallSharingUpsertWithWhereUniqueWithoutConferenceInput>>;
};

export type CallSharingUpdateManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<CallSharingWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CallSharingCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<CallSharingCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<CallSharingCreateManyOrganizationInputEnvelope>;
  delete?: InputMaybe<Array<CallSharingWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CallSharingScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CallSharingWhereUniqueInput>>;
  set?: InputMaybe<Array<CallSharingWhereUniqueInput>>;
  update?: InputMaybe<Array<CallSharingUpdateWithWhereUniqueWithoutOrganizationInput>>;
  updateMany?: InputMaybe<Array<CallSharingUpdateManyWithWhereWithoutOrganizationInput>>;
  upsert?: InputMaybe<Array<CallSharingUpsertWithWhereUniqueWithoutOrganizationInput>>;
};

export type CallSharingUpdateManyWithoutReceiver_UserInput = {
  connect?: InputMaybe<Array<CallSharingWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CallSharingCreateOrConnectWithoutReceiver_UserInput>>;
  create?: InputMaybe<Array<CallSharingCreateWithoutReceiver_UserInput>>;
  createMany?: InputMaybe<CallSharingCreateManyReceiver_UserInputEnvelope>;
  delete?: InputMaybe<Array<CallSharingWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CallSharingScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CallSharingWhereUniqueInput>>;
  set?: InputMaybe<Array<CallSharingWhereUniqueInput>>;
  update?: InputMaybe<Array<CallSharingUpdateWithWhereUniqueWithoutReceiver_UserInput>>;
  updateMany?: InputMaybe<Array<CallSharingUpdateManyWithWhereWithoutReceiver_UserInput>>;
  upsert?: InputMaybe<Array<CallSharingUpsertWithWhereUniqueWithoutReceiver_UserInput>>;
};

export type CallSharingUpdateManyWithWhereWithoutCoachInput = {
  data: CallSharingUpdateManyMutationInput;
  where: CallSharingScalarWhereInput;
};

export type CallSharingUpdateManyWithWhereWithoutConferenceInput = {
  data: CallSharingUpdateManyMutationInput;
  where: CallSharingScalarWhereInput;
};

export type CallSharingUpdateManyWithWhereWithoutOrganizationInput = {
  data: CallSharingUpdateManyMutationInput;
  where: CallSharingScalarWhereInput;
};

export type CallSharingUpdateManyWithWhereWithoutReceiver_UserInput = {
  data: CallSharingUpdateManyMutationInput;
  where: CallSharingScalarWhereInput;
};

export type CallSharingUpdateWithoutCoachInput = {
  conference?: InputMaybe<ConferenceUpdateOneWithoutCallSharingInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  Organization?: InputMaybe<OrganizationUpdateOneWithoutCallSharingsInput>;
  receiver_user?: InputMaybe<UserUpdateOneWithoutCallSharingAsUserInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type CallSharingUpdateWithoutConferenceInput = {
  coach?: InputMaybe<UserUpdateOneWithoutCallSharingAsCoachInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  Organization?: InputMaybe<OrganizationUpdateOneWithoutCallSharingsInput>;
  receiver_user?: InputMaybe<UserUpdateOneWithoutCallSharingAsUserInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type CallSharingUpdateWithoutOrganizationInput = {
  coach?: InputMaybe<UserUpdateOneWithoutCallSharingAsCoachInput>;
  conference?: InputMaybe<ConferenceUpdateOneWithoutCallSharingInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  receiver_user?: InputMaybe<UserUpdateOneWithoutCallSharingAsUserInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type CallSharingUpdateWithoutReceiver_UserInput = {
  coach?: InputMaybe<UserUpdateOneWithoutCallSharingAsCoachInput>;
  conference?: InputMaybe<ConferenceUpdateOneWithoutCallSharingInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  Organization?: InputMaybe<OrganizationUpdateOneWithoutCallSharingsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type CallSharingUpdateWithWhereUniqueWithoutCoachInput = {
  data: CallSharingUpdateWithoutCoachInput;
  where: CallSharingWhereUniqueInput;
};

export type CallSharingUpdateWithWhereUniqueWithoutConferenceInput = {
  data: CallSharingUpdateWithoutConferenceInput;
  where: CallSharingWhereUniqueInput;
};

export type CallSharingUpdateWithWhereUniqueWithoutOrganizationInput = {
  data: CallSharingUpdateWithoutOrganizationInput;
  where: CallSharingWhereUniqueInput;
};

export type CallSharingUpdateWithWhereUniqueWithoutReceiver_UserInput = {
  data: CallSharingUpdateWithoutReceiver_UserInput;
  where: CallSharingWhereUniqueInput;
};

export type CallSharingUpsertWithWhereUniqueWithoutCoachInput = {
  create: CallSharingCreateWithoutCoachInput;
  update: CallSharingUpdateWithoutCoachInput;
  where: CallSharingWhereUniqueInput;
};

export type CallSharingUpsertWithWhereUniqueWithoutConferenceInput = {
  create: CallSharingCreateWithoutConferenceInput;
  update: CallSharingUpdateWithoutConferenceInput;
  where: CallSharingWhereUniqueInput;
};

export type CallSharingUpsertWithWhereUniqueWithoutOrganizationInput = {
  create: CallSharingCreateWithoutOrganizationInput;
  update: CallSharingUpdateWithoutOrganizationInput;
  where: CallSharingWhereUniqueInput;
};

export type CallSharingUpsertWithWhereUniqueWithoutReceiver_UserInput = {
  create: CallSharingCreateWithoutReceiver_UserInput;
  update: CallSharingUpdateWithoutReceiver_UserInput;
  where: CallSharingWhereUniqueInput;
};

export type CallSharingWhereInput = {
  AND?: InputMaybe<Array<CallSharingWhereInput>>;
  coach?: InputMaybe<UserWhereInput>;
  coach_id?: InputMaybe<StringNullableFilter>;
  conference?: InputMaybe<ConferenceWhereInput>;
  conference_id?: InputMaybe<StringNullableFilter>;
  created_at?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  NOT?: InputMaybe<Array<CallSharingWhereInput>>;
  OR?: InputMaybe<Array<CallSharingWhereInput>>;
  Organization?: InputMaybe<OrganizationWhereInput>;
  organization_id?: InputMaybe<StringNullableFilter>;
  receiver_user?: InputMaybe<UserWhereInput>;
  receiver_user_id?: InputMaybe<StringNullableFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
};

export type CallSharingWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']['input']>;
};

export enum Channel {
  Inbound = 'Inbound',
  Outbound = 'Outbound'
}

export enum Channelfilter {
  Both = 'Both',
  Inbound = 'Inbound',
  Outbound = 'Outbound'
}

/** Filter inputs for lead options */
export type ColumnOptionArgument = {
  visible_activity_fields: Array<Scalars['String']['input']>;
  visible_coaching_fields: Array<Scalars['String']['input']>;
  visible_event_fields: Array<Scalars['String']['input']>;
  visible_pipeline_fields: Array<Scalars['String']['input']>;
  visible_reporting_fields: Array<Scalars['String']['input']>;
  visible_transfer_fields: Array<Scalars['String']['input']>;
  visible_transfer_quality_fields: Array<Scalars['String']['input']>;
};

export type Commission = {
  __typename?: 'Commission';
  amount: Scalars['Float']['output'];
  created_at: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  sale?: Maybe<Sale>;
  sale_id?: Maybe<Scalars['String']['output']>;
  updated_at: Scalars['DateTime']['output'];
  user?: Maybe<User>;
  user_id?: Maybe<Scalars['String']['output']>;
};

export type CommissionCreateManySaleInput = {
  amount: Scalars['Float']['input'];
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
};

export type CommissionCreateManySaleInputEnvelope = {
  data?: InputMaybe<Array<CommissionCreateManySaleInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CommissionCreateManyUserInput = {
  amount: Scalars['Float']['input'];
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  sale_id?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type CommissionCreateManyUserInputEnvelope = {
  data?: InputMaybe<Array<CommissionCreateManyUserInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CommissionCreateNestedManyWithoutSaleInput = {
  connect?: InputMaybe<Array<CommissionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CommissionCreateOrConnectWithoutSaleInput>>;
  create?: InputMaybe<Array<CommissionCreateWithoutSaleInput>>;
  createMany?: InputMaybe<CommissionCreateManySaleInputEnvelope>;
};

export type CommissionCreateNestedManyWithoutUserInput = {
  connect?: InputMaybe<Array<CommissionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CommissionCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<CommissionCreateWithoutUserInput>>;
  createMany?: InputMaybe<CommissionCreateManyUserInputEnvelope>;
};

export type CommissionCreateOrConnectWithoutSaleInput = {
  create: CommissionCreateWithoutSaleInput;
  where: CommissionWhereUniqueInput;
};

export type CommissionCreateOrConnectWithoutUserInput = {
  create: CommissionCreateWithoutUserInput;
  where: CommissionWhereUniqueInput;
};

export type CommissionCreateWithoutSaleInput = {
  amount: Scalars['Float']['input'];
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user?: InputMaybe<UserCreateNestedOneWithoutCommissionsInput>;
};

export type CommissionCreateWithoutUserInput = {
  amount: Scalars['Float']['input'];
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  sale?: InputMaybe<SaleCreateNestedOneWithoutCommissionsInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type CommissionListRelationFilter = {
  every?: InputMaybe<CommissionWhereInput>;
  none?: InputMaybe<CommissionWhereInput>;
  some?: InputMaybe<CommissionWhereInput>;
};

export type CommissionOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
  count?: InputMaybe<SortOrder>;
};

export type CommissionRate = {
  __typename?: 'CommissionRate';
  amount: Scalars['Float']['output'];
  created_at: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  product?: Maybe<Product>;
  product_id?: Maybe<Scalars['String']['output']>;
  role: Role;
  updated_at: Scalars['DateTime']['output'];
};

export type CommissionRateCreateManyProductInput = {
  amount: Scalars['Float']['input'];
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  role: Role;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type CommissionRateCreateManyProductInputEnvelope = {
  data?: InputMaybe<Array<CommissionRateCreateManyProductInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CommissionRateCreateNestedManyWithoutProductInput = {
  connect?: InputMaybe<Array<CommissionRateWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CommissionRateCreateOrConnectWithoutProductInput>>;
  create?: InputMaybe<Array<CommissionRateCreateWithoutProductInput>>;
  createMany?: InputMaybe<CommissionRateCreateManyProductInputEnvelope>;
};

export type CommissionRateCreateOrConnectWithoutProductInput = {
  create: CommissionRateCreateWithoutProductInput;
  where: CommissionRateWhereUniqueInput;
};

export type CommissionRateCreateWithoutProductInput = {
  amount: Scalars['Float']['input'];
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  role: Role;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type CommissionRateListRelationFilter = {
  every?: InputMaybe<CommissionRateWhereInput>;
  none?: InputMaybe<CommissionRateWhereInput>;
  some?: InputMaybe<CommissionRateWhereInput>;
};

export type CommissionRateOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
  count?: InputMaybe<SortOrder>;
};

export type CommissionRateScalarWhereInput = {
  amount?: InputMaybe<FloatFilter>;
  AND?: InputMaybe<Array<CommissionRateScalarWhereInput>>;
  created_at?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  NOT?: InputMaybe<Array<CommissionRateScalarWhereInput>>;
  OR?: InputMaybe<Array<CommissionRateScalarWhereInput>>;
  product_id?: InputMaybe<StringNullableFilter>;
  role?: InputMaybe<EnumRoleFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
};

export type CommissionRateUpdateManyMutationInput = {
  amount?: InputMaybe<FloatFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumRoleFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type CommissionRateUpdateManyWithoutProductInput = {
  connect?: InputMaybe<Array<CommissionRateWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CommissionRateCreateOrConnectWithoutProductInput>>;
  create?: InputMaybe<Array<CommissionRateCreateWithoutProductInput>>;
  createMany?: InputMaybe<CommissionRateCreateManyProductInputEnvelope>;
  delete?: InputMaybe<Array<CommissionRateWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CommissionRateScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CommissionRateWhereUniqueInput>>;
  set?: InputMaybe<Array<CommissionRateWhereUniqueInput>>;
  update?: InputMaybe<Array<CommissionRateUpdateWithWhereUniqueWithoutProductInput>>;
  updateMany?: InputMaybe<Array<CommissionRateUpdateManyWithWhereWithoutProductInput>>;
  upsert?: InputMaybe<Array<CommissionRateUpsertWithWhereUniqueWithoutProductInput>>;
};

export type CommissionRateUpdateManyWithWhereWithoutProductInput = {
  data: CommissionRateUpdateManyMutationInput;
  where: CommissionRateScalarWhereInput;
};

export type CommissionRateUpdateWithoutProductInput = {
  amount?: InputMaybe<FloatFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumRoleFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type CommissionRateUpdateWithWhereUniqueWithoutProductInput = {
  data: CommissionRateUpdateWithoutProductInput;
  where: CommissionRateWhereUniqueInput;
};

export type CommissionRateUpsertWithWhereUniqueWithoutProductInput = {
  create: CommissionRateCreateWithoutProductInput;
  update: CommissionRateUpdateWithoutProductInput;
  where: CommissionRateWhereUniqueInput;
};

export type CommissionRateWhereInput = {
  amount?: InputMaybe<FloatFilter>;
  AND?: InputMaybe<Array<CommissionRateWhereInput>>;
  created_at?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  NOT?: InputMaybe<Array<CommissionRateWhereInput>>;
  OR?: InputMaybe<Array<CommissionRateWhereInput>>;
  product?: InputMaybe<ProductWhereInput>;
  product_id?: InputMaybe<StringNullableFilter>;
  role?: InputMaybe<EnumRoleFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
};

export type CommissionRateWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']['input']>;
};

export type CommissionScalarWhereInput = {
  amount?: InputMaybe<FloatFilter>;
  AND?: InputMaybe<Array<CommissionScalarWhereInput>>;
  created_at?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  NOT?: InputMaybe<Array<CommissionScalarWhereInput>>;
  OR?: InputMaybe<Array<CommissionScalarWhereInput>>;
  sale_id?: InputMaybe<StringNullableFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
  user_id?: InputMaybe<StringNullableFilter>;
};

export type CommissionSplitInputType = {
  /** User's percent of the commission. Values reside from 0 to 100, inclusive */
  percent: Scalars['Float']['input'];
  /** id of a concession */
  user_id: Scalars['String']['input'];
};

export type CommissionUpdateManyMutationInput = {
  amount?: InputMaybe<FloatFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type CommissionUpdateManyWithoutSaleInput = {
  connect?: InputMaybe<Array<CommissionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CommissionCreateOrConnectWithoutSaleInput>>;
  create?: InputMaybe<Array<CommissionCreateWithoutSaleInput>>;
  createMany?: InputMaybe<CommissionCreateManySaleInputEnvelope>;
  delete?: InputMaybe<Array<CommissionWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CommissionScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CommissionWhereUniqueInput>>;
  set?: InputMaybe<Array<CommissionWhereUniqueInput>>;
  update?: InputMaybe<Array<CommissionUpdateWithWhereUniqueWithoutSaleInput>>;
  updateMany?: InputMaybe<Array<CommissionUpdateManyWithWhereWithoutSaleInput>>;
  upsert?: InputMaybe<Array<CommissionUpsertWithWhereUniqueWithoutSaleInput>>;
};

export type CommissionUpdateManyWithoutUserInput = {
  connect?: InputMaybe<Array<CommissionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CommissionCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<CommissionCreateWithoutUserInput>>;
  createMany?: InputMaybe<CommissionCreateManyUserInputEnvelope>;
  delete?: InputMaybe<Array<CommissionWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CommissionScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CommissionWhereUniqueInput>>;
  set?: InputMaybe<Array<CommissionWhereUniqueInput>>;
  update?: InputMaybe<Array<CommissionUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: InputMaybe<Array<CommissionUpdateManyWithWhereWithoutUserInput>>;
  upsert?: InputMaybe<Array<CommissionUpsertWithWhereUniqueWithoutUserInput>>;
};

export type CommissionUpdateManyWithWhereWithoutSaleInput = {
  data: CommissionUpdateManyMutationInput;
  where: CommissionScalarWhereInput;
};

export type CommissionUpdateManyWithWhereWithoutUserInput = {
  data: CommissionUpdateManyMutationInput;
  where: CommissionScalarWhereInput;
};

export type CommissionUpdateWithoutSaleInput = {
  amount?: InputMaybe<FloatFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneWithoutCommissionsInput>;
};

export type CommissionUpdateWithoutUserInput = {
  amount?: InputMaybe<FloatFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  sale?: InputMaybe<SaleUpdateOneWithoutCommissionsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type CommissionUpdateWithWhereUniqueWithoutSaleInput = {
  data: CommissionUpdateWithoutSaleInput;
  where: CommissionWhereUniqueInput;
};

export type CommissionUpdateWithWhereUniqueWithoutUserInput = {
  data: CommissionUpdateWithoutUserInput;
  where: CommissionWhereUniqueInput;
};

export type CommissionUpsertWithWhereUniqueWithoutSaleInput = {
  create: CommissionCreateWithoutSaleInput;
  update: CommissionUpdateWithoutSaleInput;
  where: CommissionWhereUniqueInput;
};

export type CommissionUpsertWithWhereUniqueWithoutUserInput = {
  create: CommissionCreateWithoutUserInput;
  update: CommissionUpdateWithoutUserInput;
  where: CommissionWhereUniqueInput;
};

export type CommissionWhereInput = {
  amount?: InputMaybe<FloatFilter>;
  AND?: InputMaybe<Array<CommissionWhereInput>>;
  created_at?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  NOT?: InputMaybe<Array<CommissionWhereInput>>;
  OR?: InputMaybe<Array<CommissionWhereInput>>;
  sale?: InputMaybe<SaleWhereInput>;
  sale_id?: InputMaybe<StringNullableFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserWhereInput>;
  user_id?: InputMaybe<StringNullableFilter>;
};

export type CommissionWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']['input']>;
};

export enum Communication_Type {
  Email = 'Email',
  Fax = 'Fax',
  Internal = 'Internal',
  Na = 'NA',
  NonCall = 'NonCall',
  Other = 'Other',
  Phone = 'Phone',
  Sms = 'SMS'
}

export enum Computationtype {
  Addition = 'Addition',
  Division = 'Division'
}

export type Concession = {
  __typename?: 'Concession';
  amount?: Maybe<Scalars['Float']['output']>;
  applicable_products: Array<Product>;
  created_at: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  label: Scalars['String']['output'];
  sale_items: Array<SaleItem>;
  type: Concessiontype;
  updated_at: Scalars['DateTime']['output'];
};


export type ConcessionApplicable_ProductsArgs = {
  after?: InputMaybe<ProductWhereUniqueInput>;
  before?: InputMaybe<ProductWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type ConcessionSale_ItemsArgs = {
  after?: InputMaybe<SaleItemWhereUniqueInput>;
  before?: InputMaybe<SaleItemWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type ConcessionCreateManyOrganizationInput = {
  amount?: InputMaybe<Scalars['Float']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  label: Scalars['String']['input'];
  type: Concessiontype;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type ConcessionCreateManyOrganizationInputEnvelope = {
  data?: InputMaybe<Array<ConcessionCreateManyOrganizationInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ConcessionCreateNestedManyWithoutApplicable_ProductsInput = {
  connect?: InputMaybe<Array<ConcessionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ConcessionCreateOrConnectWithoutApplicable_ProductsInput>>;
  create?: InputMaybe<Array<ConcessionCreateWithoutApplicable_ProductsInput>>;
};

export type ConcessionCreateNestedManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<ConcessionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ConcessionCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<ConcessionCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<ConcessionCreateManyOrganizationInputEnvelope>;
};

export type ConcessionCreateNestedManyWithoutSale_ItemsInput = {
  connect?: InputMaybe<Array<ConcessionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ConcessionCreateOrConnectWithoutSale_ItemsInput>>;
  create?: InputMaybe<Array<ConcessionCreateWithoutSale_ItemsInput>>;
};

export type ConcessionCreateOrConnectWithoutApplicable_ProductsInput = {
  create: ConcessionCreateWithoutApplicable_ProductsInput;
  where: ConcessionWhereUniqueInput;
};

export type ConcessionCreateOrConnectWithoutOrganizationInput = {
  create: ConcessionCreateWithoutOrganizationInput;
  where: ConcessionWhereUniqueInput;
};

export type ConcessionCreateOrConnectWithoutSale_ItemsInput = {
  create: ConcessionCreateWithoutSale_ItemsInput;
  where: ConcessionWhereUniqueInput;
};

export type ConcessionCreateWithoutApplicable_ProductsInput = {
  amount?: InputMaybe<Scalars['Float']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  label: Scalars['String']['input'];
  organization?: InputMaybe<OrganizationCreateNestedOneWithoutConcessionInput>;
  sale_items?: InputMaybe<SaleItemCreateNestedManyWithoutConcessionInput>;
  type: Concessiontype;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type ConcessionCreateWithoutOrganizationInput = {
  amount?: InputMaybe<Scalars['Float']['input']>;
  applicable_products?: InputMaybe<ProductCreateNestedManyWithoutAvailable_ConcessionsInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  label: Scalars['String']['input'];
  sale_items?: InputMaybe<SaleItemCreateNestedManyWithoutConcessionInput>;
  type: Concessiontype;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type ConcessionCreateWithoutSale_ItemsInput = {
  amount?: InputMaybe<Scalars['Float']['input']>;
  applicable_products?: InputMaybe<ProductCreateNestedManyWithoutAvailable_ConcessionsInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  label: Scalars['String']['input'];
  organization?: InputMaybe<OrganizationCreateNestedOneWithoutConcessionInput>;
  type: Concessiontype;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type ConcessionInput = {
  amount?: InputMaybe<Scalars['Float']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
};

export type ConcessionInputType = {
  /** id of a concession */
  concession_id: Scalars['String']['input'];
};

export type ConcessionListRelationFilter = {
  every?: InputMaybe<ConcessionWhereInput>;
  none?: InputMaybe<ConcessionWhereInput>;
  some?: InputMaybe<ConcessionWhereInput>;
};

export type ConcessionLog = {
  __typename?: 'ConcessionLog';
  concession_id: Scalars['String']['output'];
  dml_created_by?: Maybe<Scalars['String']['output']>;
  dml_timestamp: Scalars['DateTime']['output'];
  dml_type: Dml_Type;
  new_row_data?: Maybe<Scalars['Json']['output']>;
  old_row_data?: Maybe<Scalars['Json']['output']>;
};

export type ConcessionOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
  count?: InputMaybe<SortOrder>;
};

export type ConcessionOrderByWithRelationInput = {
  amount?: InputMaybe<SortOrder>;
  applicable_products?: InputMaybe<ProductOrderByRelationAggregateInput>;
  created_at?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  label?: InputMaybe<SortOrder>;
  organization?: InputMaybe<OrganizationOrderByWithRelationInput>;
  organization_id?: InputMaybe<SortOrder>;
  sale_items?: InputMaybe<SaleItemOrderByRelationAggregateInput>;
  type?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
};

export type ConcessionScalarWhereInput = {
  amount?: InputMaybe<FloatNullableFilter>;
  AND?: InputMaybe<Array<ConcessionScalarWhereInput>>;
  created_at?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  label?: InputMaybe<StringFilter>;
  NOT?: InputMaybe<Array<ConcessionScalarWhereInput>>;
  OR?: InputMaybe<Array<ConcessionScalarWhereInput>>;
  organization_id?: InputMaybe<StringNullableFilter>;
  type?: InputMaybe<EnumConcessiontypeFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
};

export enum Concessiontype {
  DiscountAmount = 'DiscountAmount',
  DiscountPercent = 'DiscountPercent',
  Other = 'Other'
}

export type ConcessionUpdateManyMutationInput = {
  amount?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  label?: InputMaybe<StringFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumConcessiontypeFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ConcessionUpdateManyWithoutApplicable_ProductsInput = {
  connect?: InputMaybe<Array<ConcessionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ConcessionCreateOrConnectWithoutApplicable_ProductsInput>>;
  create?: InputMaybe<Array<ConcessionCreateWithoutApplicable_ProductsInput>>;
  delete?: InputMaybe<Array<ConcessionWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ConcessionScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ConcessionWhereUniqueInput>>;
  set?: InputMaybe<Array<ConcessionWhereUniqueInput>>;
  update?: InputMaybe<Array<ConcessionUpdateWithWhereUniqueWithoutApplicable_ProductsInput>>;
  updateMany?: InputMaybe<Array<ConcessionUpdateManyWithWhereWithoutApplicable_ProductsInput>>;
  upsert?: InputMaybe<Array<ConcessionUpsertWithWhereUniqueWithoutApplicable_ProductsInput>>;
};

export type ConcessionUpdateManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<ConcessionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ConcessionCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<ConcessionCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<ConcessionCreateManyOrganizationInputEnvelope>;
  delete?: InputMaybe<Array<ConcessionWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ConcessionScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ConcessionWhereUniqueInput>>;
  set?: InputMaybe<Array<ConcessionWhereUniqueInput>>;
  update?: InputMaybe<Array<ConcessionUpdateWithWhereUniqueWithoutOrganizationInput>>;
  updateMany?: InputMaybe<Array<ConcessionUpdateManyWithWhereWithoutOrganizationInput>>;
  upsert?: InputMaybe<Array<ConcessionUpsertWithWhereUniqueWithoutOrganizationInput>>;
};

export type ConcessionUpdateManyWithoutSale_ItemsInput = {
  connect?: InputMaybe<Array<ConcessionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ConcessionCreateOrConnectWithoutSale_ItemsInput>>;
  create?: InputMaybe<Array<ConcessionCreateWithoutSale_ItemsInput>>;
  delete?: InputMaybe<Array<ConcessionWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ConcessionScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ConcessionWhereUniqueInput>>;
  set?: InputMaybe<Array<ConcessionWhereUniqueInput>>;
  update?: InputMaybe<Array<ConcessionUpdateWithWhereUniqueWithoutSale_ItemsInput>>;
  updateMany?: InputMaybe<Array<ConcessionUpdateManyWithWhereWithoutSale_ItemsInput>>;
  upsert?: InputMaybe<Array<ConcessionUpsertWithWhereUniqueWithoutSale_ItemsInput>>;
};

export type ConcessionUpdateManyWithWhereWithoutApplicable_ProductsInput = {
  data: ConcessionUpdateManyMutationInput;
  where: ConcessionScalarWhereInput;
};

export type ConcessionUpdateManyWithWhereWithoutOrganizationInput = {
  data: ConcessionUpdateManyMutationInput;
  where: ConcessionScalarWhereInput;
};

export type ConcessionUpdateManyWithWhereWithoutSale_ItemsInput = {
  data: ConcessionUpdateManyMutationInput;
  where: ConcessionScalarWhereInput;
};

export type ConcessionUpdateWithoutApplicable_ProductsInput = {
  amount?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  label?: InputMaybe<StringFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneWithoutConcessionInput>;
  sale_items?: InputMaybe<SaleItemUpdateManyWithoutConcessionInput>;
  type?: InputMaybe<EnumConcessiontypeFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ConcessionUpdateWithoutOrganizationInput = {
  amount?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  applicable_products?: InputMaybe<ProductUpdateManyWithoutAvailable_ConcessionsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  label?: InputMaybe<StringFieldUpdateOperationsInput>;
  sale_items?: InputMaybe<SaleItemUpdateManyWithoutConcessionInput>;
  type?: InputMaybe<EnumConcessiontypeFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ConcessionUpdateWithoutSale_ItemsInput = {
  amount?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  applicable_products?: InputMaybe<ProductUpdateManyWithoutAvailable_ConcessionsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  label?: InputMaybe<StringFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneWithoutConcessionInput>;
  type?: InputMaybe<EnumConcessiontypeFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ConcessionUpdateWithWhereUniqueWithoutApplicable_ProductsInput = {
  data: ConcessionUpdateWithoutApplicable_ProductsInput;
  where: ConcessionWhereUniqueInput;
};

export type ConcessionUpdateWithWhereUniqueWithoutOrganizationInput = {
  data: ConcessionUpdateWithoutOrganizationInput;
  where: ConcessionWhereUniqueInput;
};

export type ConcessionUpdateWithWhereUniqueWithoutSale_ItemsInput = {
  data: ConcessionUpdateWithoutSale_ItemsInput;
  where: ConcessionWhereUniqueInput;
};

export type ConcessionUpsertWithWhereUniqueWithoutApplicable_ProductsInput = {
  create: ConcessionCreateWithoutApplicable_ProductsInput;
  update: ConcessionUpdateWithoutApplicable_ProductsInput;
  where: ConcessionWhereUniqueInput;
};

export type ConcessionUpsertWithWhereUniqueWithoutOrganizationInput = {
  create: ConcessionCreateWithoutOrganizationInput;
  update: ConcessionUpdateWithoutOrganizationInput;
  where: ConcessionWhereUniqueInput;
};

export type ConcessionUpsertWithWhereUniqueWithoutSale_ItemsInput = {
  create: ConcessionCreateWithoutSale_ItemsInput;
  update: ConcessionUpdateWithoutSale_ItemsInput;
  where: ConcessionWhereUniqueInput;
};

export type ConcessionWhereInput = {
  amount?: InputMaybe<FloatNullableFilter>;
  AND?: InputMaybe<Array<ConcessionWhereInput>>;
  applicable_products?: InputMaybe<ProductListRelationFilter>;
  created_at?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  label?: InputMaybe<StringFilter>;
  NOT?: InputMaybe<Array<ConcessionWhereInput>>;
  OR?: InputMaybe<Array<ConcessionWhereInput>>;
  organization?: InputMaybe<OrganizationWhereInput>;
  organization_id?: InputMaybe<StringNullableFilter>;
  sale_items?: InputMaybe<SaleItemListRelationFilter>;
  type?: InputMaybe<EnumConcessiontypeFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
};

export type ConcessionWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']['input']>;
};

export type Condition = {
  __typename?: 'Condition';
  cadence: Cadence;
  created_at: Scalars['DateTime']['output'];
  field: Scalars['String']['output'];
  field_label?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  object: Scalars['String']['output'];
  operator: Operator;
  rule: Rule;
  rule_id: Scalars['String']['output'];
  type: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type ConditionCreateManyRuleInput = {
  cadence?: InputMaybe<Cadence>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  field: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  object?: InputMaybe<Scalars['String']['input']>;
  operator: Operator;
  type: Scalars['String']['input'];
  value?: InputMaybe<Scalars['String']['input']>;
};

export type ConditionCreateManyRuleInputEnvelope = {
  data?: InputMaybe<Array<ConditionCreateManyRuleInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ConditionCreateNestedManyWithoutRuleInput = {
  connect?: InputMaybe<Array<ConditionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ConditionCreateOrConnectWithoutRuleInput>>;
  create?: InputMaybe<Array<ConditionCreateWithoutRuleInput>>;
  createMany?: InputMaybe<ConditionCreateManyRuleInputEnvelope>;
};

export type ConditionCreateOrConnectWithoutRuleInput = {
  create: ConditionCreateWithoutRuleInput;
  where: ConditionWhereUniqueInput;
};

export type ConditionCreateWithoutRuleInput = {
  cadence?: InputMaybe<Cadence>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  field: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  object?: InputMaybe<Scalars['String']['input']>;
  operator: Operator;
  type: Scalars['String']['input'];
  value?: InputMaybe<Scalars['String']['input']>;
};

export type ConditionListRelationFilter = {
  every?: InputMaybe<ConditionWhereInput>;
  none?: InputMaybe<ConditionWhereInput>;
  some?: InputMaybe<ConditionWhereInput>;
};

export type ConditionOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
  count?: InputMaybe<SortOrder>;
};

export type ConditionScalarWhereInput = {
  AND?: InputMaybe<Array<ConditionScalarWhereInput>>;
  cadence?: InputMaybe<EnumCadenceFilter>;
  created_at?: InputMaybe<DateTimeFilter>;
  field?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  NOT?: InputMaybe<Array<ConditionScalarWhereInput>>;
  object?: InputMaybe<StringFilter>;
  operator?: InputMaybe<EnumOperatorFilter>;
  OR?: InputMaybe<Array<ConditionScalarWhereInput>>;
  rule_id?: InputMaybe<StringFilter>;
  type?: InputMaybe<StringFilter>;
  value?: InputMaybe<StringFilter>;
};

export enum ConditionType {
  Customfield = 'customfield',
  Industry = 'industry',
  LeadSource = 'leadSource',
  Region = 'region',
  SubIndustry = 'subIndustry'
}

export enum ConditionType {
  Boolean = 'Boolean',
  Date = 'Date',
  Dropdown = 'Dropdown',
  MultiDropdown = 'MultiDropdown',
  MultiText = 'MultiText',
  Number = 'Number',
  Percentage = 'Percentage',
  Rate = 'Rate',
  Reference = 'Reference',
  Text = 'Text'
}

export type ConditionUpdateManyMutationInput = {
  cadence?: InputMaybe<EnumCadenceFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  field?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  object?: InputMaybe<StringFieldUpdateOperationsInput>;
  operator?: InputMaybe<EnumOperatorFieldUpdateOperationsInput>;
  type?: InputMaybe<StringFieldUpdateOperationsInput>;
  value?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type ConditionUpdateManyWithoutRuleInput = {
  connect?: InputMaybe<Array<ConditionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ConditionCreateOrConnectWithoutRuleInput>>;
  create?: InputMaybe<Array<ConditionCreateWithoutRuleInput>>;
  createMany?: InputMaybe<ConditionCreateManyRuleInputEnvelope>;
  delete?: InputMaybe<Array<ConditionWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ConditionScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ConditionWhereUniqueInput>>;
  set?: InputMaybe<Array<ConditionWhereUniqueInput>>;
  update?: InputMaybe<Array<ConditionUpdateWithWhereUniqueWithoutRuleInput>>;
  updateMany?: InputMaybe<Array<ConditionUpdateManyWithWhereWithoutRuleInput>>;
  upsert?: InputMaybe<Array<ConditionUpsertWithWhereUniqueWithoutRuleInput>>;
};

export type ConditionUpdateManyWithWhereWithoutRuleInput = {
  data: ConditionUpdateManyMutationInput;
  where: ConditionScalarWhereInput;
};

export type ConditionUpdateWithoutRuleInput = {
  cadence?: InputMaybe<EnumCadenceFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  field?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  object?: InputMaybe<StringFieldUpdateOperationsInput>;
  operator?: InputMaybe<EnumOperatorFieldUpdateOperationsInput>;
  type?: InputMaybe<StringFieldUpdateOperationsInput>;
  value?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type ConditionUpdateWithWhereUniqueWithoutRuleInput = {
  data: ConditionUpdateWithoutRuleInput;
  where: ConditionWhereUniqueInput;
};

export type ConditionUpsertWithWhereUniqueWithoutRuleInput = {
  create: ConditionCreateWithoutRuleInput;
  update: ConditionUpdateWithoutRuleInput;
  where: ConditionWhereUniqueInput;
};

export type ConditionWhereInput = {
  AND?: InputMaybe<Array<ConditionWhereInput>>;
  cadence?: InputMaybe<EnumCadenceFilter>;
  created_at?: InputMaybe<DateTimeFilter>;
  field?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  NOT?: InputMaybe<Array<ConditionWhereInput>>;
  object?: InputMaybe<StringFilter>;
  operator?: InputMaybe<EnumOperatorFilter>;
  OR?: InputMaybe<Array<ConditionWhereInput>>;
  rule?: InputMaybe<RuleWhereInput>;
  rule_id?: InputMaybe<StringFilter>;
  type?: InputMaybe<StringFilter>;
  value?: InputMaybe<StringFilter>;
};

export type ConditionWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']['input']>;
};

export type Conference = {
  __typename?: 'Conference';
  auto_transfer_selections: Array<AutoTransferSelection>;
  call_ended?: Maybe<Scalars['Boolean']['output']>;
  call_result?: Maybe<Disposition>;
  call_sid_to_coach?: Maybe<Scalars['String']['output']>;
  call_sid_to_lead?: Maybe<Scalars['String']['output']>;
  channel?: Maybe<Channel>;
  closing_script_end?: Maybe<Scalars['Int']['output']>;
  closing_script_start?: Maybe<Scalars['Int']['output']>;
  coaching_notes: Array<NoteItem>;
  /** The coaching notes, but sorted */
  coaching_notes_sorted?: Maybe<Array<Maybe<NoteItem>>>;
  conference_code?: Maybe<Scalars['String']['output']>;
  conference_sid?: Maybe<Scalars['String']['output']>;
  created_at: Scalars['DateTime']['output'];
  duration?: Maybe<Scalars['Int']['output']>;
  end_time?: Maybe<Scalars['Date']['output']>;
  folders: Array<Folder>;
  id: Scalars['String']['output'];
  /** True if the call is currently ongoing. */
  is_call_live?: Maybe<Scalars['Boolean']['output']>;
  is_initial_call: Scalars['Boolean']['output'];
  /** The latest activity attached to the conference. Useful when there are several activities on a conference. */
  latest_activity?: Maybe<LeadActivity>;
  /** The latest disposition logged on a call. This is needed because there could be more than 1 disposition logged on a call. */
  latest_call_result?: Maybe<Disposition>;
  /** The lead associated with the call. */
  lead?: Maybe<Lead>;
  lead_activities: Array<LeadActivity>;
  multi_joined: Scalars['Boolean']['output'];
  noncall?: Maybe<Scalars['Boolean']['output']>;
  notification_id?: Maybe<Scalars['String']['output']>;
  recording_sid?: Maybe<Scalars['String']['output']>;
  recording_url?: Maybe<Scalars['String']['output']>;
  /** The users associated with the call. */
  salesperson?: Maybe<Scalars['String']['output']>;
  source_type?: Maybe<Scalars['String']['output']>;
  start_time?: Maybe<Scalars['DateTime']['output']>;
  suggested_dial?: Maybe<Scalars['Boolean']['output']>;
  transcription_url?: Maybe<Scalars['String']['output']>;
  transfer_attempts: Array<TransferAttempt>;
  updated_at: Scalars['DateTime']['output'];
  /** The users associated with the call. */
  users?: Maybe<Array<Maybe<User>>>;
};


export type ConferenceAuto_Transfer_SelectionsArgs = {
  after?: InputMaybe<AutoTransferSelectionWhereUniqueInput>;
  before?: InputMaybe<AutoTransferSelectionWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type ConferenceCoaching_NotesArgs = {
  after?: InputMaybe<NoteItemWhereUniqueInput>;
  before?: InputMaybe<NoteItemWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type ConferenceFoldersArgs = {
  after?: InputMaybe<FolderWhereUniqueInput>;
  before?: InputMaybe<FolderWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type ConferenceLead_ActivitiesArgs = {
  after?: InputMaybe<LeadActivityWhereUniqueInput>;
  before?: InputMaybe<LeadActivityWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type ConferenceTransfer_AttemptsArgs = {
  after?: InputMaybe<TransferAttemptWhereUniqueInput>;
  before?: InputMaybe<TransferAttemptWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type ConferenceCreateManyCall_ResultInput = {
  call_ended?: InputMaybe<Scalars['Boolean']['input']>;
  call_sid_to_coach?: InputMaybe<Scalars['String']['input']>;
  call_sid_to_lead?: InputMaybe<Scalars['String']['input']>;
  channel?: InputMaybe<Channel>;
  closing_script_end?: InputMaybe<Scalars['Int']['input']>;
  closing_script_start?: InputMaybe<Scalars['Int']['input']>;
  coached_at?: InputMaybe<Scalars['DateTime']['input']>;
  conference_code?: InputMaybe<Scalars['String']['input']>;
  conference_sid?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  duration?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  is_initial_call?: InputMaybe<Scalars['Boolean']['input']>;
  multi_joined?: InputMaybe<Scalars['Boolean']['input']>;
  noncall?: InputMaybe<Scalars['Boolean']['input']>;
  notification_id?: InputMaybe<Scalars['String']['input']>;
  organization_id?: InputMaybe<Scalars['String']['input']>;
  recording_sid?: InputMaybe<Scalars['String']['input']>;
  recording_track?: InputMaybe<Scalars['String']['input']>;
  recording_url?: InputMaybe<Scalars['String']['input']>;
  start_time?: InputMaybe<Scalars['DateTime']['input']>;
  suggested_dial?: InputMaybe<Scalars['Boolean']['input']>;
  transcription_url?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type ConferenceCreateManyCall_ResultInputEnvelope = {
  data?: InputMaybe<Array<ConferenceCreateManyCall_ResultInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ConferenceCreateManyOrganizationInput = {
  call_ended?: InputMaybe<Scalars['Boolean']['input']>;
  call_sid_to_coach?: InputMaybe<Scalars['String']['input']>;
  call_sid_to_lead?: InputMaybe<Scalars['String']['input']>;
  channel?: InputMaybe<Channel>;
  closing_script_end?: InputMaybe<Scalars['Int']['input']>;
  closing_script_start?: InputMaybe<Scalars['Int']['input']>;
  coached_at?: InputMaybe<Scalars['DateTime']['input']>;
  conference_code?: InputMaybe<Scalars['String']['input']>;
  conference_sid?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  dispositionId?: InputMaybe<Scalars['String']['input']>;
  duration?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  is_initial_call?: InputMaybe<Scalars['Boolean']['input']>;
  multi_joined?: InputMaybe<Scalars['Boolean']['input']>;
  noncall?: InputMaybe<Scalars['Boolean']['input']>;
  notification_id?: InputMaybe<Scalars['String']['input']>;
  recording_sid?: InputMaybe<Scalars['String']['input']>;
  recording_track?: InputMaybe<Scalars['String']['input']>;
  recording_url?: InputMaybe<Scalars['String']['input']>;
  start_time?: InputMaybe<Scalars['DateTime']['input']>;
  suggested_dial?: InputMaybe<Scalars['Boolean']['input']>;
  transcription_url?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type ConferenceCreateManyOrganizationInputEnvelope = {
  data?: InputMaybe<Array<ConferenceCreateManyOrganizationInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ConferenceCreateNestedManyWithoutCall_ResultInput = {
  connect?: InputMaybe<Array<ConferenceWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ConferenceCreateOrConnectWithoutCall_ResultInput>>;
  create?: InputMaybe<Array<ConferenceCreateWithoutCall_ResultInput>>;
  createMany?: InputMaybe<ConferenceCreateManyCall_ResultInputEnvelope>;
};

export type ConferenceCreateNestedManyWithoutFoldersInput = {
  connect?: InputMaybe<Array<ConferenceWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ConferenceCreateOrConnectWithoutFoldersInput>>;
  create?: InputMaybe<Array<ConferenceCreateWithoutFoldersInput>>;
};

export type ConferenceCreateNestedManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<ConferenceWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ConferenceCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<ConferenceCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<ConferenceCreateManyOrganizationInputEnvelope>;
};

export type ConferenceCreateNestedOneWithoutAuto_Transfer_SelectionsInput = {
  connect?: InputMaybe<ConferenceWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ConferenceCreateOrConnectWithoutAuto_Transfer_SelectionsInput>;
  create?: InputMaybe<ConferenceCreateWithoutAuto_Transfer_SelectionsInput>;
};

export type ConferenceCreateNestedOneWithoutCallSharingInput = {
  connect?: InputMaybe<ConferenceWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ConferenceCreateOrConnectWithoutCallSharingInput>;
  create?: InputMaybe<ConferenceCreateWithoutCallSharingInput>;
};

export type ConferenceCreateNestedOneWithoutCoaching_NotesInput = {
  connect?: InputMaybe<ConferenceWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ConferenceCreateOrConnectWithoutCoaching_NotesInput>;
  create?: InputMaybe<ConferenceCreateWithoutCoaching_NotesInput>;
};

export type ConferenceCreateNestedOneWithoutLead_ActivitiesInput = {
  connect?: InputMaybe<ConferenceWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ConferenceCreateOrConnectWithoutLead_ActivitiesInput>;
  create?: InputMaybe<ConferenceCreateWithoutLead_ActivitiesInput>;
};

export type ConferenceCreateNestedOneWithoutLiveUserStatusInput = {
  connect?: InputMaybe<ConferenceWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ConferenceCreateOrConnectWithoutLiveUserStatusInput>;
  create?: InputMaybe<ConferenceCreateWithoutLiveUserStatusInput>;
};

export type ConferenceCreateNestedOneWithoutNotificationsInput = {
  connect?: InputMaybe<ConferenceWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ConferenceCreateOrConnectWithoutNotificationsInput>;
  create?: InputMaybe<ConferenceCreateWithoutNotificationsInput>;
};

export type ConferenceCreateNestedOneWithoutTransfer_AttemptsInput = {
  connect?: InputMaybe<ConferenceWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ConferenceCreateOrConnectWithoutTransfer_AttemptsInput>;
  create?: InputMaybe<ConferenceCreateWithoutTransfer_AttemptsInput>;
};

export type ConferenceCreateNestedOneWithoutTwilioFeedbackInput = {
  connect?: InputMaybe<ConferenceWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ConferenceCreateOrConnectWithoutTwilioFeedbackInput>;
  create?: InputMaybe<ConferenceCreateWithoutTwilioFeedbackInput>;
};

export type ConferenceCreateOrConnectWithoutAuto_Transfer_SelectionsInput = {
  create: ConferenceCreateWithoutAuto_Transfer_SelectionsInput;
  where: ConferenceWhereUniqueInput;
};

export type ConferenceCreateOrConnectWithoutCall_ResultInput = {
  create: ConferenceCreateWithoutCall_ResultInput;
  where: ConferenceWhereUniqueInput;
};

export type ConferenceCreateOrConnectWithoutCallSharingInput = {
  create: ConferenceCreateWithoutCallSharingInput;
  where: ConferenceWhereUniqueInput;
};

export type ConferenceCreateOrConnectWithoutCoaching_NotesInput = {
  create: ConferenceCreateWithoutCoaching_NotesInput;
  where: ConferenceWhereUniqueInput;
};

export type ConferenceCreateOrConnectWithoutFoldersInput = {
  create: ConferenceCreateWithoutFoldersInput;
  where: ConferenceWhereUniqueInput;
};

export type ConferenceCreateOrConnectWithoutLead_ActivitiesInput = {
  create: ConferenceCreateWithoutLead_ActivitiesInput;
  where: ConferenceWhereUniqueInput;
};

export type ConferenceCreateOrConnectWithoutLiveUserStatusInput = {
  create: ConferenceCreateWithoutLiveUserStatusInput;
  where: ConferenceWhereUniqueInput;
};

export type ConferenceCreateOrConnectWithoutNotificationsInput = {
  create: ConferenceCreateWithoutNotificationsInput;
  where: ConferenceWhereUniqueInput;
};

export type ConferenceCreateOrConnectWithoutOrganizationInput = {
  create: ConferenceCreateWithoutOrganizationInput;
  where: ConferenceWhereUniqueInput;
};

export type ConferenceCreateOrConnectWithoutTransfer_AttemptsInput = {
  create: ConferenceCreateWithoutTransfer_AttemptsInput;
  where: ConferenceWhereUniqueInput;
};

export type ConferenceCreateOrConnectWithoutTwilioFeedbackInput = {
  create: ConferenceCreateWithoutTwilioFeedbackInput;
  where: ConferenceWhereUniqueInput;
};

export type ConferenceCreateWithoutAuto_Transfer_SelectionsInput = {
  call_ended?: InputMaybe<Scalars['Boolean']['input']>;
  call_result?: InputMaybe<DispositionCreateNestedOneWithoutConferencesInput>;
  call_sid_to_coach?: InputMaybe<Scalars['String']['input']>;
  call_sid_to_lead?: InputMaybe<Scalars['String']['input']>;
  CallSharing?: InputMaybe<CallSharingCreateNestedManyWithoutConferenceInput>;
  channel?: InputMaybe<Channel>;
  closing_script_end?: InputMaybe<Scalars['Int']['input']>;
  closing_script_start?: InputMaybe<Scalars['Int']['input']>;
  coached_at?: InputMaybe<Scalars['DateTime']['input']>;
  coaching_notes?: InputMaybe<NoteItemCreateNestedManyWithoutConferenceInput>;
  conference_code?: InputMaybe<Scalars['String']['input']>;
  conference_sid?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  duration?: InputMaybe<Scalars['Int']['input']>;
  folders?: InputMaybe<FolderCreateNestedManyWithoutConferencesInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  is_initial_call?: InputMaybe<Scalars['Boolean']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutConferenceInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusCreateNestedManyWithoutConferenceInput>;
  multi_joined?: InputMaybe<Scalars['Boolean']['input']>;
  noncall?: InputMaybe<Scalars['Boolean']['input']>;
  notification_id?: InputMaybe<Scalars['String']['input']>;
  notifications?: InputMaybe<NotificationCreateNestedManyWithoutConferenceInput>;
  Organization?: InputMaybe<OrganizationCreateNestedOneWithoutConferencesInput>;
  recording_sid?: InputMaybe<Scalars['String']['input']>;
  recording_track?: InputMaybe<Scalars['String']['input']>;
  recording_url?: InputMaybe<Scalars['String']['input']>;
  start_time?: InputMaybe<Scalars['DateTime']['input']>;
  suggested_dial?: InputMaybe<Scalars['Boolean']['input']>;
  transcription_url?: InputMaybe<Scalars['String']['input']>;
  transfer_attempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutConferenceInput>;
  TwilioFeedback?: InputMaybe<TwilioFeedbackCreateNestedOneWithoutConferenceInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type ConferenceCreateWithoutCall_ResultInput = {
  auto_transfer_selections?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutConferenceInput>;
  call_ended?: InputMaybe<Scalars['Boolean']['input']>;
  call_sid_to_coach?: InputMaybe<Scalars['String']['input']>;
  call_sid_to_lead?: InputMaybe<Scalars['String']['input']>;
  CallSharing?: InputMaybe<CallSharingCreateNestedManyWithoutConferenceInput>;
  channel?: InputMaybe<Channel>;
  closing_script_end?: InputMaybe<Scalars['Int']['input']>;
  closing_script_start?: InputMaybe<Scalars['Int']['input']>;
  coached_at?: InputMaybe<Scalars['DateTime']['input']>;
  coaching_notes?: InputMaybe<NoteItemCreateNestedManyWithoutConferenceInput>;
  conference_code?: InputMaybe<Scalars['String']['input']>;
  conference_sid?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  duration?: InputMaybe<Scalars['Int']['input']>;
  folders?: InputMaybe<FolderCreateNestedManyWithoutConferencesInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  is_initial_call?: InputMaybe<Scalars['Boolean']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutConferenceInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusCreateNestedManyWithoutConferenceInput>;
  multi_joined?: InputMaybe<Scalars['Boolean']['input']>;
  noncall?: InputMaybe<Scalars['Boolean']['input']>;
  notification_id?: InputMaybe<Scalars['String']['input']>;
  notifications?: InputMaybe<NotificationCreateNestedManyWithoutConferenceInput>;
  Organization?: InputMaybe<OrganizationCreateNestedOneWithoutConferencesInput>;
  recording_sid?: InputMaybe<Scalars['String']['input']>;
  recording_track?: InputMaybe<Scalars['String']['input']>;
  recording_url?: InputMaybe<Scalars['String']['input']>;
  start_time?: InputMaybe<Scalars['DateTime']['input']>;
  suggested_dial?: InputMaybe<Scalars['Boolean']['input']>;
  transcription_url?: InputMaybe<Scalars['String']['input']>;
  transfer_attempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutConferenceInput>;
  TwilioFeedback?: InputMaybe<TwilioFeedbackCreateNestedOneWithoutConferenceInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type ConferenceCreateWithoutCallSharingInput = {
  auto_transfer_selections?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutConferenceInput>;
  call_ended?: InputMaybe<Scalars['Boolean']['input']>;
  call_result?: InputMaybe<DispositionCreateNestedOneWithoutConferencesInput>;
  call_sid_to_coach?: InputMaybe<Scalars['String']['input']>;
  call_sid_to_lead?: InputMaybe<Scalars['String']['input']>;
  channel?: InputMaybe<Channel>;
  closing_script_end?: InputMaybe<Scalars['Int']['input']>;
  closing_script_start?: InputMaybe<Scalars['Int']['input']>;
  coached_at?: InputMaybe<Scalars['DateTime']['input']>;
  coaching_notes?: InputMaybe<NoteItemCreateNestedManyWithoutConferenceInput>;
  conference_code?: InputMaybe<Scalars['String']['input']>;
  conference_sid?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  duration?: InputMaybe<Scalars['Int']['input']>;
  folders?: InputMaybe<FolderCreateNestedManyWithoutConferencesInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  is_initial_call?: InputMaybe<Scalars['Boolean']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutConferenceInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusCreateNestedManyWithoutConferenceInput>;
  multi_joined?: InputMaybe<Scalars['Boolean']['input']>;
  noncall?: InputMaybe<Scalars['Boolean']['input']>;
  notification_id?: InputMaybe<Scalars['String']['input']>;
  notifications?: InputMaybe<NotificationCreateNestedManyWithoutConferenceInput>;
  Organization?: InputMaybe<OrganizationCreateNestedOneWithoutConferencesInput>;
  recording_sid?: InputMaybe<Scalars['String']['input']>;
  recording_track?: InputMaybe<Scalars['String']['input']>;
  recording_url?: InputMaybe<Scalars['String']['input']>;
  start_time?: InputMaybe<Scalars['DateTime']['input']>;
  suggested_dial?: InputMaybe<Scalars['Boolean']['input']>;
  transcription_url?: InputMaybe<Scalars['String']['input']>;
  transfer_attempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutConferenceInput>;
  TwilioFeedback?: InputMaybe<TwilioFeedbackCreateNestedOneWithoutConferenceInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type ConferenceCreateWithoutCoaching_NotesInput = {
  auto_transfer_selections?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutConferenceInput>;
  call_ended?: InputMaybe<Scalars['Boolean']['input']>;
  call_result?: InputMaybe<DispositionCreateNestedOneWithoutConferencesInput>;
  call_sid_to_coach?: InputMaybe<Scalars['String']['input']>;
  call_sid_to_lead?: InputMaybe<Scalars['String']['input']>;
  CallSharing?: InputMaybe<CallSharingCreateNestedManyWithoutConferenceInput>;
  channel?: InputMaybe<Channel>;
  closing_script_end?: InputMaybe<Scalars['Int']['input']>;
  closing_script_start?: InputMaybe<Scalars['Int']['input']>;
  coached_at?: InputMaybe<Scalars['DateTime']['input']>;
  conference_code?: InputMaybe<Scalars['String']['input']>;
  conference_sid?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  duration?: InputMaybe<Scalars['Int']['input']>;
  folders?: InputMaybe<FolderCreateNestedManyWithoutConferencesInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  is_initial_call?: InputMaybe<Scalars['Boolean']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutConferenceInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusCreateNestedManyWithoutConferenceInput>;
  multi_joined?: InputMaybe<Scalars['Boolean']['input']>;
  noncall?: InputMaybe<Scalars['Boolean']['input']>;
  notification_id?: InputMaybe<Scalars['String']['input']>;
  notifications?: InputMaybe<NotificationCreateNestedManyWithoutConferenceInput>;
  Organization?: InputMaybe<OrganizationCreateNestedOneWithoutConferencesInput>;
  recording_sid?: InputMaybe<Scalars['String']['input']>;
  recording_track?: InputMaybe<Scalars['String']['input']>;
  recording_url?: InputMaybe<Scalars['String']['input']>;
  start_time?: InputMaybe<Scalars['DateTime']['input']>;
  suggested_dial?: InputMaybe<Scalars['Boolean']['input']>;
  transcription_url?: InputMaybe<Scalars['String']['input']>;
  transfer_attempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutConferenceInput>;
  TwilioFeedback?: InputMaybe<TwilioFeedbackCreateNestedOneWithoutConferenceInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type ConferenceCreateWithoutFoldersInput = {
  auto_transfer_selections?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutConferenceInput>;
  call_ended?: InputMaybe<Scalars['Boolean']['input']>;
  call_result?: InputMaybe<DispositionCreateNestedOneWithoutConferencesInput>;
  call_sid_to_coach?: InputMaybe<Scalars['String']['input']>;
  call_sid_to_lead?: InputMaybe<Scalars['String']['input']>;
  CallSharing?: InputMaybe<CallSharingCreateNestedManyWithoutConferenceInput>;
  channel?: InputMaybe<Channel>;
  closing_script_end?: InputMaybe<Scalars['Int']['input']>;
  closing_script_start?: InputMaybe<Scalars['Int']['input']>;
  coached_at?: InputMaybe<Scalars['DateTime']['input']>;
  coaching_notes?: InputMaybe<NoteItemCreateNestedManyWithoutConferenceInput>;
  conference_code?: InputMaybe<Scalars['String']['input']>;
  conference_sid?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  duration?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  is_initial_call?: InputMaybe<Scalars['Boolean']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutConferenceInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusCreateNestedManyWithoutConferenceInput>;
  multi_joined?: InputMaybe<Scalars['Boolean']['input']>;
  noncall?: InputMaybe<Scalars['Boolean']['input']>;
  notification_id?: InputMaybe<Scalars['String']['input']>;
  notifications?: InputMaybe<NotificationCreateNestedManyWithoutConferenceInput>;
  Organization?: InputMaybe<OrganizationCreateNestedOneWithoutConferencesInput>;
  recording_sid?: InputMaybe<Scalars['String']['input']>;
  recording_track?: InputMaybe<Scalars['String']['input']>;
  recording_url?: InputMaybe<Scalars['String']['input']>;
  start_time?: InputMaybe<Scalars['DateTime']['input']>;
  suggested_dial?: InputMaybe<Scalars['Boolean']['input']>;
  transcription_url?: InputMaybe<Scalars['String']['input']>;
  transfer_attempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutConferenceInput>;
  TwilioFeedback?: InputMaybe<TwilioFeedbackCreateNestedOneWithoutConferenceInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type ConferenceCreateWithoutLead_ActivitiesInput = {
  auto_transfer_selections?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutConferenceInput>;
  call_ended?: InputMaybe<Scalars['Boolean']['input']>;
  call_result?: InputMaybe<DispositionCreateNestedOneWithoutConferencesInput>;
  call_sid_to_coach?: InputMaybe<Scalars['String']['input']>;
  call_sid_to_lead?: InputMaybe<Scalars['String']['input']>;
  CallSharing?: InputMaybe<CallSharingCreateNestedManyWithoutConferenceInput>;
  channel?: InputMaybe<Channel>;
  closing_script_end?: InputMaybe<Scalars['Int']['input']>;
  closing_script_start?: InputMaybe<Scalars['Int']['input']>;
  coached_at?: InputMaybe<Scalars['DateTime']['input']>;
  coaching_notes?: InputMaybe<NoteItemCreateNestedManyWithoutConferenceInput>;
  conference_code?: InputMaybe<Scalars['String']['input']>;
  conference_sid?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  duration?: InputMaybe<Scalars['Int']['input']>;
  folders?: InputMaybe<FolderCreateNestedManyWithoutConferencesInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  is_initial_call?: InputMaybe<Scalars['Boolean']['input']>;
  liveUserStatus?: InputMaybe<LiveUserStatusCreateNestedManyWithoutConferenceInput>;
  multi_joined?: InputMaybe<Scalars['Boolean']['input']>;
  noncall?: InputMaybe<Scalars['Boolean']['input']>;
  notification_id?: InputMaybe<Scalars['String']['input']>;
  notifications?: InputMaybe<NotificationCreateNestedManyWithoutConferenceInput>;
  Organization?: InputMaybe<OrganizationCreateNestedOneWithoutConferencesInput>;
  recording_sid?: InputMaybe<Scalars['String']['input']>;
  recording_track?: InputMaybe<Scalars['String']['input']>;
  recording_url?: InputMaybe<Scalars['String']['input']>;
  start_time?: InputMaybe<Scalars['DateTime']['input']>;
  suggested_dial?: InputMaybe<Scalars['Boolean']['input']>;
  transcription_url?: InputMaybe<Scalars['String']['input']>;
  transfer_attempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutConferenceInput>;
  TwilioFeedback?: InputMaybe<TwilioFeedbackCreateNestedOneWithoutConferenceInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type ConferenceCreateWithoutLiveUserStatusInput = {
  auto_transfer_selections?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutConferenceInput>;
  call_ended?: InputMaybe<Scalars['Boolean']['input']>;
  call_result?: InputMaybe<DispositionCreateNestedOneWithoutConferencesInput>;
  call_sid_to_coach?: InputMaybe<Scalars['String']['input']>;
  call_sid_to_lead?: InputMaybe<Scalars['String']['input']>;
  CallSharing?: InputMaybe<CallSharingCreateNestedManyWithoutConferenceInput>;
  channel?: InputMaybe<Channel>;
  closing_script_end?: InputMaybe<Scalars['Int']['input']>;
  closing_script_start?: InputMaybe<Scalars['Int']['input']>;
  coached_at?: InputMaybe<Scalars['DateTime']['input']>;
  coaching_notes?: InputMaybe<NoteItemCreateNestedManyWithoutConferenceInput>;
  conference_code?: InputMaybe<Scalars['String']['input']>;
  conference_sid?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  duration?: InputMaybe<Scalars['Int']['input']>;
  folders?: InputMaybe<FolderCreateNestedManyWithoutConferencesInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  is_initial_call?: InputMaybe<Scalars['Boolean']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutConferenceInput>;
  multi_joined?: InputMaybe<Scalars['Boolean']['input']>;
  noncall?: InputMaybe<Scalars['Boolean']['input']>;
  notification_id?: InputMaybe<Scalars['String']['input']>;
  notifications?: InputMaybe<NotificationCreateNestedManyWithoutConferenceInput>;
  Organization?: InputMaybe<OrganizationCreateNestedOneWithoutConferencesInput>;
  recording_sid?: InputMaybe<Scalars['String']['input']>;
  recording_track?: InputMaybe<Scalars['String']['input']>;
  recording_url?: InputMaybe<Scalars['String']['input']>;
  start_time?: InputMaybe<Scalars['DateTime']['input']>;
  suggested_dial?: InputMaybe<Scalars['Boolean']['input']>;
  transcription_url?: InputMaybe<Scalars['String']['input']>;
  transfer_attempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutConferenceInput>;
  TwilioFeedback?: InputMaybe<TwilioFeedbackCreateNestedOneWithoutConferenceInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type ConferenceCreateWithoutNotificationsInput = {
  auto_transfer_selections?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutConferenceInput>;
  call_ended?: InputMaybe<Scalars['Boolean']['input']>;
  call_result?: InputMaybe<DispositionCreateNestedOneWithoutConferencesInput>;
  call_sid_to_coach?: InputMaybe<Scalars['String']['input']>;
  call_sid_to_lead?: InputMaybe<Scalars['String']['input']>;
  CallSharing?: InputMaybe<CallSharingCreateNestedManyWithoutConferenceInput>;
  channel?: InputMaybe<Channel>;
  closing_script_end?: InputMaybe<Scalars['Int']['input']>;
  closing_script_start?: InputMaybe<Scalars['Int']['input']>;
  coached_at?: InputMaybe<Scalars['DateTime']['input']>;
  coaching_notes?: InputMaybe<NoteItemCreateNestedManyWithoutConferenceInput>;
  conference_code?: InputMaybe<Scalars['String']['input']>;
  conference_sid?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  duration?: InputMaybe<Scalars['Int']['input']>;
  folders?: InputMaybe<FolderCreateNestedManyWithoutConferencesInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  is_initial_call?: InputMaybe<Scalars['Boolean']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutConferenceInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusCreateNestedManyWithoutConferenceInput>;
  multi_joined?: InputMaybe<Scalars['Boolean']['input']>;
  noncall?: InputMaybe<Scalars['Boolean']['input']>;
  notification_id?: InputMaybe<Scalars['String']['input']>;
  Organization?: InputMaybe<OrganizationCreateNestedOneWithoutConferencesInput>;
  recording_sid?: InputMaybe<Scalars['String']['input']>;
  recording_track?: InputMaybe<Scalars['String']['input']>;
  recording_url?: InputMaybe<Scalars['String']['input']>;
  start_time?: InputMaybe<Scalars['DateTime']['input']>;
  suggested_dial?: InputMaybe<Scalars['Boolean']['input']>;
  transcription_url?: InputMaybe<Scalars['String']['input']>;
  transfer_attempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutConferenceInput>;
  TwilioFeedback?: InputMaybe<TwilioFeedbackCreateNestedOneWithoutConferenceInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type ConferenceCreateWithoutOrganizationInput = {
  auto_transfer_selections?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutConferenceInput>;
  call_ended?: InputMaybe<Scalars['Boolean']['input']>;
  call_result?: InputMaybe<DispositionCreateNestedOneWithoutConferencesInput>;
  call_sid_to_coach?: InputMaybe<Scalars['String']['input']>;
  call_sid_to_lead?: InputMaybe<Scalars['String']['input']>;
  CallSharing?: InputMaybe<CallSharingCreateNestedManyWithoutConferenceInput>;
  channel?: InputMaybe<Channel>;
  closing_script_end?: InputMaybe<Scalars['Int']['input']>;
  closing_script_start?: InputMaybe<Scalars['Int']['input']>;
  coached_at?: InputMaybe<Scalars['DateTime']['input']>;
  coaching_notes?: InputMaybe<NoteItemCreateNestedManyWithoutConferenceInput>;
  conference_code?: InputMaybe<Scalars['String']['input']>;
  conference_sid?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  duration?: InputMaybe<Scalars['Int']['input']>;
  folders?: InputMaybe<FolderCreateNestedManyWithoutConferencesInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  is_initial_call?: InputMaybe<Scalars['Boolean']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutConferenceInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusCreateNestedManyWithoutConferenceInput>;
  multi_joined?: InputMaybe<Scalars['Boolean']['input']>;
  noncall?: InputMaybe<Scalars['Boolean']['input']>;
  notification_id?: InputMaybe<Scalars['String']['input']>;
  notifications?: InputMaybe<NotificationCreateNestedManyWithoutConferenceInput>;
  recording_sid?: InputMaybe<Scalars['String']['input']>;
  recording_track?: InputMaybe<Scalars['String']['input']>;
  recording_url?: InputMaybe<Scalars['String']['input']>;
  start_time?: InputMaybe<Scalars['DateTime']['input']>;
  suggested_dial?: InputMaybe<Scalars['Boolean']['input']>;
  transcription_url?: InputMaybe<Scalars['String']['input']>;
  transfer_attempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutConferenceInput>;
  TwilioFeedback?: InputMaybe<TwilioFeedbackCreateNestedOneWithoutConferenceInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type ConferenceCreateWithoutTransfer_AttemptsInput = {
  auto_transfer_selections?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutConferenceInput>;
  call_ended?: InputMaybe<Scalars['Boolean']['input']>;
  call_result?: InputMaybe<DispositionCreateNestedOneWithoutConferencesInput>;
  call_sid_to_coach?: InputMaybe<Scalars['String']['input']>;
  call_sid_to_lead?: InputMaybe<Scalars['String']['input']>;
  CallSharing?: InputMaybe<CallSharingCreateNestedManyWithoutConferenceInput>;
  channel?: InputMaybe<Channel>;
  closing_script_end?: InputMaybe<Scalars['Int']['input']>;
  closing_script_start?: InputMaybe<Scalars['Int']['input']>;
  coached_at?: InputMaybe<Scalars['DateTime']['input']>;
  coaching_notes?: InputMaybe<NoteItemCreateNestedManyWithoutConferenceInput>;
  conference_code?: InputMaybe<Scalars['String']['input']>;
  conference_sid?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  duration?: InputMaybe<Scalars['Int']['input']>;
  folders?: InputMaybe<FolderCreateNestedManyWithoutConferencesInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  is_initial_call?: InputMaybe<Scalars['Boolean']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutConferenceInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusCreateNestedManyWithoutConferenceInput>;
  multi_joined?: InputMaybe<Scalars['Boolean']['input']>;
  noncall?: InputMaybe<Scalars['Boolean']['input']>;
  notification_id?: InputMaybe<Scalars['String']['input']>;
  notifications?: InputMaybe<NotificationCreateNestedManyWithoutConferenceInput>;
  Organization?: InputMaybe<OrganizationCreateNestedOneWithoutConferencesInput>;
  recording_sid?: InputMaybe<Scalars['String']['input']>;
  recording_track?: InputMaybe<Scalars['String']['input']>;
  recording_url?: InputMaybe<Scalars['String']['input']>;
  start_time?: InputMaybe<Scalars['DateTime']['input']>;
  suggested_dial?: InputMaybe<Scalars['Boolean']['input']>;
  transcription_url?: InputMaybe<Scalars['String']['input']>;
  TwilioFeedback?: InputMaybe<TwilioFeedbackCreateNestedOneWithoutConferenceInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type ConferenceCreateWithoutTwilioFeedbackInput = {
  auto_transfer_selections?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutConferenceInput>;
  call_ended?: InputMaybe<Scalars['Boolean']['input']>;
  call_result?: InputMaybe<DispositionCreateNestedOneWithoutConferencesInput>;
  call_sid_to_coach?: InputMaybe<Scalars['String']['input']>;
  call_sid_to_lead?: InputMaybe<Scalars['String']['input']>;
  CallSharing?: InputMaybe<CallSharingCreateNestedManyWithoutConferenceInput>;
  channel?: InputMaybe<Channel>;
  closing_script_end?: InputMaybe<Scalars['Int']['input']>;
  closing_script_start?: InputMaybe<Scalars['Int']['input']>;
  coached_at?: InputMaybe<Scalars['DateTime']['input']>;
  coaching_notes?: InputMaybe<NoteItemCreateNestedManyWithoutConferenceInput>;
  conference_code?: InputMaybe<Scalars['String']['input']>;
  conference_sid?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  duration?: InputMaybe<Scalars['Int']['input']>;
  folders?: InputMaybe<FolderCreateNestedManyWithoutConferencesInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  is_initial_call?: InputMaybe<Scalars['Boolean']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutConferenceInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusCreateNestedManyWithoutConferenceInput>;
  multi_joined?: InputMaybe<Scalars['Boolean']['input']>;
  noncall?: InputMaybe<Scalars['Boolean']['input']>;
  notification_id?: InputMaybe<Scalars['String']['input']>;
  notifications?: InputMaybe<NotificationCreateNestedManyWithoutConferenceInput>;
  Organization?: InputMaybe<OrganizationCreateNestedOneWithoutConferencesInput>;
  recording_sid?: InputMaybe<Scalars['String']['input']>;
  recording_track?: InputMaybe<Scalars['String']['input']>;
  recording_url?: InputMaybe<Scalars['String']['input']>;
  start_time?: InputMaybe<Scalars['DateTime']['input']>;
  suggested_dial?: InputMaybe<Scalars['Boolean']['input']>;
  transcription_url?: InputMaybe<Scalars['String']['input']>;
  transfer_attempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutConferenceInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

/** Conference Join Info */
export type ConferenceJoinInfoObject = {
  __typename?: 'ConferenceJoinInfoObject';
  conference_code: Scalars['String']['output'];
  dial_number: Scalars['String']['output'];
};

export type ConferenceListRelationFilter = {
  every?: InputMaybe<ConferenceWhereInput>;
  none?: InputMaybe<ConferenceWhereInput>;
  some?: InputMaybe<ConferenceWhereInput>;
};

export type ConferenceOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
  count?: InputMaybe<SortOrder>;
};

export type ConferenceOrderByWithRelationInput = {
  auto_transfer_selections?: InputMaybe<AutoTransferSelectionOrderByRelationAggregateInput>;
  call_ended?: InputMaybe<SortOrder>;
  call_result?: InputMaybe<DispositionOrderByWithRelationInput>;
  call_sid_to_coach?: InputMaybe<SortOrder>;
  call_sid_to_lead?: InputMaybe<SortOrder>;
  CallSharing?: InputMaybe<CallSharingOrderByRelationAggregateInput>;
  channel?: InputMaybe<SortOrder>;
  closing_script_end?: InputMaybe<SortOrder>;
  closing_script_start?: InputMaybe<SortOrder>;
  coached_at?: InputMaybe<SortOrder>;
  coaching_notes?: InputMaybe<NoteItemOrderByRelationAggregateInput>;
  conference_code?: InputMaybe<SortOrder>;
  conference_sid?: InputMaybe<SortOrder>;
  created_at?: InputMaybe<SortOrder>;
  dispositionId?: InputMaybe<SortOrder>;
  duration?: InputMaybe<SortOrder>;
  folders?: InputMaybe<FolderOrderByRelationAggregateInput>;
  id?: InputMaybe<SortOrder>;
  is_initial_call?: InputMaybe<SortOrder>;
  lead_activities?: InputMaybe<LeadActivityOrderByRelationAggregateInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusOrderByRelationAggregateInput>;
  multi_joined?: InputMaybe<SortOrder>;
  noncall?: InputMaybe<SortOrder>;
  notification_id?: InputMaybe<SortOrder>;
  notifications?: InputMaybe<NotificationOrderByRelationAggregateInput>;
  Organization?: InputMaybe<OrganizationOrderByWithRelationInput>;
  organization_id?: InputMaybe<SortOrder>;
  recording_sid?: InputMaybe<SortOrder>;
  recording_track?: InputMaybe<SortOrder>;
  recording_url?: InputMaybe<SortOrder>;
  start_time?: InputMaybe<SortOrder>;
  suggested_dial?: InputMaybe<SortOrder>;
  transcription_url?: InputMaybe<SortOrder>;
  transfer_attempts?: InputMaybe<TransferAttemptOrderByRelationAggregateInput>;
  TwilioFeedback?: InputMaybe<TwilioFeedbackOrderByWithRelationInput>;
  updated_at?: InputMaybe<SortOrder>;
};

export enum ConferenceRegion {
  Au1 = 'au1',
  Br1 = 'br1',
  De1 = 'de1',
  Ie1 = 'ie1',
  Jp1 = 'jp1',
  Sg1 = 'sg1',
  Us1 = 'us1'
}

export type ConferenceScalarWhereInput = {
  AND?: InputMaybe<Array<ConferenceScalarWhereInput>>;
  call_ended?: InputMaybe<BoolNullableFilter>;
  call_sid_to_coach?: InputMaybe<StringNullableFilter>;
  call_sid_to_lead?: InputMaybe<StringNullableFilter>;
  channel?: InputMaybe<EnumChannelNullableFilter>;
  closing_script_end?: InputMaybe<IntNullableFilter>;
  closing_script_start?: InputMaybe<IntNullableFilter>;
  coached_at?: InputMaybe<DateTimeNullableFilter>;
  conference_code?: InputMaybe<StringNullableFilter>;
  conference_sid?: InputMaybe<StringNullableFilter>;
  created_at?: InputMaybe<DateTimeFilter>;
  dispositionId?: InputMaybe<StringNullableFilter>;
  duration?: InputMaybe<IntNullableFilter>;
  id?: InputMaybe<StringFilter>;
  is_initial_call?: InputMaybe<BoolFilter>;
  multi_joined?: InputMaybe<BoolFilter>;
  noncall?: InputMaybe<BoolNullableFilter>;
  NOT?: InputMaybe<Array<ConferenceScalarWhereInput>>;
  notification_id?: InputMaybe<StringNullableFilter>;
  OR?: InputMaybe<Array<ConferenceScalarWhereInput>>;
  organization_id?: InputMaybe<StringNullableFilter>;
  recording_sid?: InputMaybe<StringNullableFilter>;
  recording_track?: InputMaybe<StringNullableFilter>;
  recording_url?: InputMaybe<StringNullableFilter>;
  start_time?: InputMaybe<DateTimeNullableFilter>;
  suggested_dial?: InputMaybe<BoolNullableFilter>;
  transcription_url?: InputMaybe<StringNullableFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
};

export type ConferenceUpdateManyMutationInput = {
  call_ended?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  call_sid_to_coach?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  call_sid_to_lead?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  channel?: InputMaybe<NullableEnumChannelFieldUpdateOperationsInput>;
  closing_script_end?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  closing_script_start?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  coached_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  conference_code?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  conference_sid?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  duration?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  is_initial_call?: InputMaybe<BoolFieldUpdateOperationsInput>;
  multi_joined?: InputMaybe<BoolFieldUpdateOperationsInput>;
  noncall?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  notification_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  recording_sid?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  recording_track?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  recording_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  start_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  suggested_dial?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  transcription_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ConferenceUpdateManyWithoutCall_ResultInput = {
  connect?: InputMaybe<Array<ConferenceWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ConferenceCreateOrConnectWithoutCall_ResultInput>>;
  create?: InputMaybe<Array<ConferenceCreateWithoutCall_ResultInput>>;
  createMany?: InputMaybe<ConferenceCreateManyCall_ResultInputEnvelope>;
  delete?: InputMaybe<Array<ConferenceWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ConferenceScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ConferenceWhereUniqueInput>>;
  set?: InputMaybe<Array<ConferenceWhereUniqueInput>>;
  update?: InputMaybe<Array<ConferenceUpdateWithWhereUniqueWithoutCall_ResultInput>>;
  updateMany?: InputMaybe<Array<ConferenceUpdateManyWithWhereWithoutCall_ResultInput>>;
  upsert?: InputMaybe<Array<ConferenceUpsertWithWhereUniqueWithoutCall_ResultInput>>;
};

export type ConferenceUpdateManyWithoutFoldersInput = {
  connect?: InputMaybe<Array<ConferenceWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ConferenceCreateOrConnectWithoutFoldersInput>>;
  create?: InputMaybe<Array<ConferenceCreateWithoutFoldersInput>>;
  delete?: InputMaybe<Array<ConferenceWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ConferenceScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ConferenceWhereUniqueInput>>;
  set?: InputMaybe<Array<ConferenceWhereUniqueInput>>;
  update?: InputMaybe<Array<ConferenceUpdateWithWhereUniqueWithoutFoldersInput>>;
  updateMany?: InputMaybe<Array<ConferenceUpdateManyWithWhereWithoutFoldersInput>>;
  upsert?: InputMaybe<Array<ConferenceUpsertWithWhereUniqueWithoutFoldersInput>>;
};

export type ConferenceUpdateManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<ConferenceWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ConferenceCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<ConferenceCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<ConferenceCreateManyOrganizationInputEnvelope>;
  delete?: InputMaybe<Array<ConferenceWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ConferenceScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ConferenceWhereUniqueInput>>;
  set?: InputMaybe<Array<ConferenceWhereUniqueInput>>;
  update?: InputMaybe<Array<ConferenceUpdateWithWhereUniqueWithoutOrganizationInput>>;
  updateMany?: InputMaybe<Array<ConferenceUpdateManyWithWhereWithoutOrganizationInput>>;
  upsert?: InputMaybe<Array<ConferenceUpsertWithWhereUniqueWithoutOrganizationInput>>;
};

export type ConferenceUpdateManyWithWhereWithoutCall_ResultInput = {
  data: ConferenceUpdateManyMutationInput;
  where: ConferenceScalarWhereInput;
};

export type ConferenceUpdateManyWithWhereWithoutFoldersInput = {
  data: ConferenceUpdateManyMutationInput;
  where: ConferenceScalarWhereInput;
};

export type ConferenceUpdateManyWithWhereWithoutOrganizationInput = {
  data: ConferenceUpdateManyMutationInput;
  where: ConferenceScalarWhereInput;
};

export type ConferenceUpdateOneRequiredWithoutTransfer_AttemptsInput = {
  connect?: InputMaybe<ConferenceWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ConferenceCreateOrConnectWithoutTransfer_AttemptsInput>;
  create?: InputMaybe<ConferenceCreateWithoutTransfer_AttemptsInput>;
  update?: InputMaybe<ConferenceUpdateWithoutTransfer_AttemptsInput>;
  upsert?: InputMaybe<ConferenceUpsertWithoutTransfer_AttemptsInput>;
};

export type ConferenceUpdateOneRequiredWithoutTwilioFeedbackInput = {
  connect?: InputMaybe<ConferenceWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ConferenceCreateOrConnectWithoutTwilioFeedbackInput>;
  create?: InputMaybe<ConferenceCreateWithoutTwilioFeedbackInput>;
  update?: InputMaybe<ConferenceUpdateWithoutTwilioFeedbackInput>;
  upsert?: InputMaybe<ConferenceUpsertWithoutTwilioFeedbackInput>;
};

export type ConferenceUpdateOneWithoutAuto_Transfer_SelectionsInput = {
  connect?: InputMaybe<ConferenceWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ConferenceCreateOrConnectWithoutAuto_Transfer_SelectionsInput>;
  create?: InputMaybe<ConferenceCreateWithoutAuto_Transfer_SelectionsInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<ConferenceUpdateWithoutAuto_Transfer_SelectionsInput>;
  upsert?: InputMaybe<ConferenceUpsertWithoutAuto_Transfer_SelectionsInput>;
};

export type ConferenceUpdateOneWithoutCallSharingInput = {
  connect?: InputMaybe<ConferenceWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ConferenceCreateOrConnectWithoutCallSharingInput>;
  create?: InputMaybe<ConferenceCreateWithoutCallSharingInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<ConferenceUpdateWithoutCallSharingInput>;
  upsert?: InputMaybe<ConferenceUpsertWithoutCallSharingInput>;
};

export type ConferenceUpdateOneWithoutCoaching_NotesInput = {
  connect?: InputMaybe<ConferenceWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ConferenceCreateOrConnectWithoutCoaching_NotesInput>;
  create?: InputMaybe<ConferenceCreateWithoutCoaching_NotesInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<ConferenceUpdateWithoutCoaching_NotesInput>;
  upsert?: InputMaybe<ConferenceUpsertWithoutCoaching_NotesInput>;
};

export type ConferenceUpdateOneWithoutLead_ActivitiesInput = {
  connect?: InputMaybe<ConferenceWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ConferenceCreateOrConnectWithoutLead_ActivitiesInput>;
  create?: InputMaybe<ConferenceCreateWithoutLead_ActivitiesInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<ConferenceUpdateWithoutLead_ActivitiesInput>;
  upsert?: InputMaybe<ConferenceUpsertWithoutLead_ActivitiesInput>;
};

export type ConferenceUpdateOneWithoutLiveUserStatusInput = {
  connect?: InputMaybe<ConferenceWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ConferenceCreateOrConnectWithoutLiveUserStatusInput>;
  create?: InputMaybe<ConferenceCreateWithoutLiveUserStatusInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<ConferenceUpdateWithoutLiveUserStatusInput>;
  upsert?: InputMaybe<ConferenceUpsertWithoutLiveUserStatusInput>;
};

export type ConferenceUpdateOneWithoutNotificationsInput = {
  connect?: InputMaybe<ConferenceWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ConferenceCreateOrConnectWithoutNotificationsInput>;
  create?: InputMaybe<ConferenceCreateWithoutNotificationsInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<ConferenceUpdateWithoutNotificationsInput>;
  upsert?: InputMaybe<ConferenceUpsertWithoutNotificationsInput>;
};

export type ConferenceUpdateWithoutAuto_Transfer_SelectionsInput = {
  call_ended?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  call_result?: InputMaybe<DispositionUpdateOneWithoutConferencesInput>;
  call_sid_to_coach?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  call_sid_to_lead?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  CallSharing?: InputMaybe<CallSharingUpdateManyWithoutConferenceInput>;
  channel?: InputMaybe<NullableEnumChannelFieldUpdateOperationsInput>;
  closing_script_end?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  closing_script_start?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  coached_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  coaching_notes?: InputMaybe<NoteItemUpdateManyWithoutConferenceInput>;
  conference_code?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  conference_sid?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  duration?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  folders?: InputMaybe<FolderUpdateManyWithoutConferencesInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  is_initial_call?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutConferenceInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusUpdateManyWithoutConferenceInput>;
  multi_joined?: InputMaybe<BoolFieldUpdateOperationsInput>;
  noncall?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  notification_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  notifications?: InputMaybe<NotificationUpdateManyWithoutConferenceInput>;
  Organization?: InputMaybe<OrganizationUpdateOneWithoutConferencesInput>;
  recording_sid?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  recording_track?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  recording_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  start_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  suggested_dial?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  transcription_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transfer_attempts?: InputMaybe<TransferAttemptUpdateManyWithoutConferenceInput>;
  TwilioFeedback?: InputMaybe<TwilioFeedbackUpdateOneWithoutConferenceInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ConferenceUpdateWithoutCall_ResultInput = {
  auto_transfer_selections?: InputMaybe<AutoTransferSelectionUpdateManyWithoutConferenceInput>;
  call_ended?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  call_sid_to_coach?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  call_sid_to_lead?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  CallSharing?: InputMaybe<CallSharingUpdateManyWithoutConferenceInput>;
  channel?: InputMaybe<NullableEnumChannelFieldUpdateOperationsInput>;
  closing_script_end?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  closing_script_start?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  coached_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  coaching_notes?: InputMaybe<NoteItemUpdateManyWithoutConferenceInput>;
  conference_code?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  conference_sid?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  duration?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  folders?: InputMaybe<FolderUpdateManyWithoutConferencesInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  is_initial_call?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutConferenceInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusUpdateManyWithoutConferenceInput>;
  multi_joined?: InputMaybe<BoolFieldUpdateOperationsInput>;
  noncall?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  notification_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  notifications?: InputMaybe<NotificationUpdateManyWithoutConferenceInput>;
  Organization?: InputMaybe<OrganizationUpdateOneWithoutConferencesInput>;
  recording_sid?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  recording_track?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  recording_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  start_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  suggested_dial?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  transcription_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transfer_attempts?: InputMaybe<TransferAttemptUpdateManyWithoutConferenceInput>;
  TwilioFeedback?: InputMaybe<TwilioFeedbackUpdateOneWithoutConferenceInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ConferenceUpdateWithoutCallSharingInput = {
  auto_transfer_selections?: InputMaybe<AutoTransferSelectionUpdateManyWithoutConferenceInput>;
  call_ended?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  call_result?: InputMaybe<DispositionUpdateOneWithoutConferencesInput>;
  call_sid_to_coach?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  call_sid_to_lead?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  channel?: InputMaybe<NullableEnumChannelFieldUpdateOperationsInput>;
  closing_script_end?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  closing_script_start?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  coached_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  coaching_notes?: InputMaybe<NoteItemUpdateManyWithoutConferenceInput>;
  conference_code?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  conference_sid?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  duration?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  folders?: InputMaybe<FolderUpdateManyWithoutConferencesInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  is_initial_call?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutConferenceInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusUpdateManyWithoutConferenceInput>;
  multi_joined?: InputMaybe<BoolFieldUpdateOperationsInput>;
  noncall?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  notification_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  notifications?: InputMaybe<NotificationUpdateManyWithoutConferenceInput>;
  Organization?: InputMaybe<OrganizationUpdateOneWithoutConferencesInput>;
  recording_sid?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  recording_track?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  recording_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  start_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  suggested_dial?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  transcription_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transfer_attempts?: InputMaybe<TransferAttemptUpdateManyWithoutConferenceInput>;
  TwilioFeedback?: InputMaybe<TwilioFeedbackUpdateOneWithoutConferenceInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ConferenceUpdateWithoutCoaching_NotesInput = {
  auto_transfer_selections?: InputMaybe<AutoTransferSelectionUpdateManyWithoutConferenceInput>;
  call_ended?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  call_result?: InputMaybe<DispositionUpdateOneWithoutConferencesInput>;
  call_sid_to_coach?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  call_sid_to_lead?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  CallSharing?: InputMaybe<CallSharingUpdateManyWithoutConferenceInput>;
  channel?: InputMaybe<NullableEnumChannelFieldUpdateOperationsInput>;
  closing_script_end?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  closing_script_start?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  coached_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  conference_code?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  conference_sid?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  duration?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  folders?: InputMaybe<FolderUpdateManyWithoutConferencesInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  is_initial_call?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutConferenceInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusUpdateManyWithoutConferenceInput>;
  multi_joined?: InputMaybe<BoolFieldUpdateOperationsInput>;
  noncall?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  notification_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  notifications?: InputMaybe<NotificationUpdateManyWithoutConferenceInput>;
  Organization?: InputMaybe<OrganizationUpdateOneWithoutConferencesInput>;
  recording_sid?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  recording_track?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  recording_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  start_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  suggested_dial?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  transcription_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transfer_attempts?: InputMaybe<TransferAttemptUpdateManyWithoutConferenceInput>;
  TwilioFeedback?: InputMaybe<TwilioFeedbackUpdateOneWithoutConferenceInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ConferenceUpdateWithoutFoldersInput = {
  auto_transfer_selections?: InputMaybe<AutoTransferSelectionUpdateManyWithoutConferenceInput>;
  call_ended?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  call_result?: InputMaybe<DispositionUpdateOneWithoutConferencesInput>;
  call_sid_to_coach?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  call_sid_to_lead?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  CallSharing?: InputMaybe<CallSharingUpdateManyWithoutConferenceInput>;
  channel?: InputMaybe<NullableEnumChannelFieldUpdateOperationsInput>;
  closing_script_end?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  closing_script_start?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  coached_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  coaching_notes?: InputMaybe<NoteItemUpdateManyWithoutConferenceInput>;
  conference_code?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  conference_sid?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  duration?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  is_initial_call?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutConferenceInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusUpdateManyWithoutConferenceInput>;
  multi_joined?: InputMaybe<BoolFieldUpdateOperationsInput>;
  noncall?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  notification_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  notifications?: InputMaybe<NotificationUpdateManyWithoutConferenceInput>;
  Organization?: InputMaybe<OrganizationUpdateOneWithoutConferencesInput>;
  recording_sid?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  recording_track?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  recording_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  start_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  suggested_dial?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  transcription_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transfer_attempts?: InputMaybe<TransferAttemptUpdateManyWithoutConferenceInput>;
  TwilioFeedback?: InputMaybe<TwilioFeedbackUpdateOneWithoutConferenceInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ConferenceUpdateWithoutLead_ActivitiesInput = {
  auto_transfer_selections?: InputMaybe<AutoTransferSelectionUpdateManyWithoutConferenceInput>;
  call_ended?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  call_result?: InputMaybe<DispositionUpdateOneWithoutConferencesInput>;
  call_sid_to_coach?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  call_sid_to_lead?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  CallSharing?: InputMaybe<CallSharingUpdateManyWithoutConferenceInput>;
  channel?: InputMaybe<NullableEnumChannelFieldUpdateOperationsInput>;
  closing_script_end?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  closing_script_start?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  coached_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  coaching_notes?: InputMaybe<NoteItemUpdateManyWithoutConferenceInput>;
  conference_code?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  conference_sid?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  duration?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  folders?: InputMaybe<FolderUpdateManyWithoutConferencesInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  is_initial_call?: InputMaybe<BoolFieldUpdateOperationsInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusUpdateManyWithoutConferenceInput>;
  multi_joined?: InputMaybe<BoolFieldUpdateOperationsInput>;
  noncall?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  notification_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  notifications?: InputMaybe<NotificationUpdateManyWithoutConferenceInput>;
  Organization?: InputMaybe<OrganizationUpdateOneWithoutConferencesInput>;
  recording_sid?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  recording_track?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  recording_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  start_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  suggested_dial?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  transcription_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transfer_attempts?: InputMaybe<TransferAttemptUpdateManyWithoutConferenceInput>;
  TwilioFeedback?: InputMaybe<TwilioFeedbackUpdateOneWithoutConferenceInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ConferenceUpdateWithoutLiveUserStatusInput = {
  auto_transfer_selections?: InputMaybe<AutoTransferSelectionUpdateManyWithoutConferenceInput>;
  call_ended?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  call_result?: InputMaybe<DispositionUpdateOneWithoutConferencesInput>;
  call_sid_to_coach?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  call_sid_to_lead?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  CallSharing?: InputMaybe<CallSharingUpdateManyWithoutConferenceInput>;
  channel?: InputMaybe<NullableEnumChannelFieldUpdateOperationsInput>;
  closing_script_end?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  closing_script_start?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  coached_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  coaching_notes?: InputMaybe<NoteItemUpdateManyWithoutConferenceInput>;
  conference_code?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  conference_sid?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  duration?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  folders?: InputMaybe<FolderUpdateManyWithoutConferencesInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  is_initial_call?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutConferenceInput>;
  multi_joined?: InputMaybe<BoolFieldUpdateOperationsInput>;
  noncall?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  notification_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  notifications?: InputMaybe<NotificationUpdateManyWithoutConferenceInput>;
  Organization?: InputMaybe<OrganizationUpdateOneWithoutConferencesInput>;
  recording_sid?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  recording_track?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  recording_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  start_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  suggested_dial?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  transcription_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transfer_attempts?: InputMaybe<TransferAttemptUpdateManyWithoutConferenceInput>;
  TwilioFeedback?: InputMaybe<TwilioFeedbackUpdateOneWithoutConferenceInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ConferenceUpdateWithoutNotificationsInput = {
  auto_transfer_selections?: InputMaybe<AutoTransferSelectionUpdateManyWithoutConferenceInput>;
  call_ended?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  call_result?: InputMaybe<DispositionUpdateOneWithoutConferencesInput>;
  call_sid_to_coach?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  call_sid_to_lead?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  CallSharing?: InputMaybe<CallSharingUpdateManyWithoutConferenceInput>;
  channel?: InputMaybe<NullableEnumChannelFieldUpdateOperationsInput>;
  closing_script_end?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  closing_script_start?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  coached_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  coaching_notes?: InputMaybe<NoteItemUpdateManyWithoutConferenceInput>;
  conference_code?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  conference_sid?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  duration?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  folders?: InputMaybe<FolderUpdateManyWithoutConferencesInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  is_initial_call?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutConferenceInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusUpdateManyWithoutConferenceInput>;
  multi_joined?: InputMaybe<BoolFieldUpdateOperationsInput>;
  noncall?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  notification_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  Organization?: InputMaybe<OrganizationUpdateOneWithoutConferencesInput>;
  recording_sid?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  recording_track?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  recording_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  start_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  suggested_dial?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  transcription_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transfer_attempts?: InputMaybe<TransferAttemptUpdateManyWithoutConferenceInput>;
  TwilioFeedback?: InputMaybe<TwilioFeedbackUpdateOneWithoutConferenceInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ConferenceUpdateWithoutOrganizationInput = {
  auto_transfer_selections?: InputMaybe<AutoTransferSelectionUpdateManyWithoutConferenceInput>;
  call_ended?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  call_result?: InputMaybe<DispositionUpdateOneWithoutConferencesInput>;
  call_sid_to_coach?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  call_sid_to_lead?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  CallSharing?: InputMaybe<CallSharingUpdateManyWithoutConferenceInput>;
  channel?: InputMaybe<NullableEnumChannelFieldUpdateOperationsInput>;
  closing_script_end?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  closing_script_start?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  coached_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  coaching_notes?: InputMaybe<NoteItemUpdateManyWithoutConferenceInput>;
  conference_code?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  conference_sid?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  duration?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  folders?: InputMaybe<FolderUpdateManyWithoutConferencesInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  is_initial_call?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutConferenceInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusUpdateManyWithoutConferenceInput>;
  multi_joined?: InputMaybe<BoolFieldUpdateOperationsInput>;
  noncall?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  notification_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  notifications?: InputMaybe<NotificationUpdateManyWithoutConferenceInput>;
  recording_sid?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  recording_track?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  recording_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  start_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  suggested_dial?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  transcription_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transfer_attempts?: InputMaybe<TransferAttemptUpdateManyWithoutConferenceInput>;
  TwilioFeedback?: InputMaybe<TwilioFeedbackUpdateOneWithoutConferenceInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ConferenceUpdateWithoutTransfer_AttemptsInput = {
  auto_transfer_selections?: InputMaybe<AutoTransferSelectionUpdateManyWithoutConferenceInput>;
  call_ended?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  call_result?: InputMaybe<DispositionUpdateOneWithoutConferencesInput>;
  call_sid_to_coach?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  call_sid_to_lead?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  CallSharing?: InputMaybe<CallSharingUpdateManyWithoutConferenceInput>;
  channel?: InputMaybe<NullableEnumChannelFieldUpdateOperationsInput>;
  closing_script_end?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  closing_script_start?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  coached_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  coaching_notes?: InputMaybe<NoteItemUpdateManyWithoutConferenceInput>;
  conference_code?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  conference_sid?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  duration?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  folders?: InputMaybe<FolderUpdateManyWithoutConferencesInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  is_initial_call?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutConferenceInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusUpdateManyWithoutConferenceInput>;
  multi_joined?: InputMaybe<BoolFieldUpdateOperationsInput>;
  noncall?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  notification_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  notifications?: InputMaybe<NotificationUpdateManyWithoutConferenceInput>;
  Organization?: InputMaybe<OrganizationUpdateOneWithoutConferencesInput>;
  recording_sid?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  recording_track?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  recording_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  start_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  suggested_dial?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  transcription_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  TwilioFeedback?: InputMaybe<TwilioFeedbackUpdateOneWithoutConferenceInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ConferenceUpdateWithoutTwilioFeedbackInput = {
  auto_transfer_selections?: InputMaybe<AutoTransferSelectionUpdateManyWithoutConferenceInput>;
  call_ended?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  call_result?: InputMaybe<DispositionUpdateOneWithoutConferencesInput>;
  call_sid_to_coach?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  call_sid_to_lead?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  CallSharing?: InputMaybe<CallSharingUpdateManyWithoutConferenceInput>;
  channel?: InputMaybe<NullableEnumChannelFieldUpdateOperationsInput>;
  closing_script_end?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  closing_script_start?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  coached_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  coaching_notes?: InputMaybe<NoteItemUpdateManyWithoutConferenceInput>;
  conference_code?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  conference_sid?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  duration?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  folders?: InputMaybe<FolderUpdateManyWithoutConferencesInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  is_initial_call?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutConferenceInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusUpdateManyWithoutConferenceInput>;
  multi_joined?: InputMaybe<BoolFieldUpdateOperationsInput>;
  noncall?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  notification_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  notifications?: InputMaybe<NotificationUpdateManyWithoutConferenceInput>;
  Organization?: InputMaybe<OrganizationUpdateOneWithoutConferencesInput>;
  recording_sid?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  recording_track?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  recording_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  start_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  suggested_dial?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  transcription_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transfer_attempts?: InputMaybe<TransferAttemptUpdateManyWithoutConferenceInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ConferenceUpdateWithWhereUniqueWithoutCall_ResultInput = {
  data: ConferenceUpdateWithoutCall_ResultInput;
  where: ConferenceWhereUniqueInput;
};

export type ConferenceUpdateWithWhereUniqueWithoutFoldersInput = {
  data: ConferenceUpdateWithoutFoldersInput;
  where: ConferenceWhereUniqueInput;
};

export type ConferenceUpdateWithWhereUniqueWithoutOrganizationInput = {
  data: ConferenceUpdateWithoutOrganizationInput;
  where: ConferenceWhereUniqueInput;
};

export type ConferenceUpsertWithoutAuto_Transfer_SelectionsInput = {
  create: ConferenceCreateWithoutAuto_Transfer_SelectionsInput;
  update: ConferenceUpdateWithoutAuto_Transfer_SelectionsInput;
};

export type ConferenceUpsertWithoutCallSharingInput = {
  create: ConferenceCreateWithoutCallSharingInput;
  update: ConferenceUpdateWithoutCallSharingInput;
};

export type ConferenceUpsertWithoutCoaching_NotesInput = {
  create: ConferenceCreateWithoutCoaching_NotesInput;
  update: ConferenceUpdateWithoutCoaching_NotesInput;
};

export type ConferenceUpsertWithoutLead_ActivitiesInput = {
  create: ConferenceCreateWithoutLead_ActivitiesInput;
  update: ConferenceUpdateWithoutLead_ActivitiesInput;
};

export type ConferenceUpsertWithoutLiveUserStatusInput = {
  create: ConferenceCreateWithoutLiveUserStatusInput;
  update: ConferenceUpdateWithoutLiveUserStatusInput;
};

export type ConferenceUpsertWithoutNotificationsInput = {
  create: ConferenceCreateWithoutNotificationsInput;
  update: ConferenceUpdateWithoutNotificationsInput;
};

export type ConferenceUpsertWithoutTransfer_AttemptsInput = {
  create: ConferenceCreateWithoutTransfer_AttemptsInput;
  update: ConferenceUpdateWithoutTransfer_AttemptsInput;
};

export type ConferenceUpsertWithoutTwilioFeedbackInput = {
  create: ConferenceCreateWithoutTwilioFeedbackInput;
  update: ConferenceUpdateWithoutTwilioFeedbackInput;
};

export type ConferenceUpsertWithWhereUniqueWithoutCall_ResultInput = {
  create: ConferenceCreateWithoutCall_ResultInput;
  update: ConferenceUpdateWithoutCall_ResultInput;
  where: ConferenceWhereUniqueInput;
};

export type ConferenceUpsertWithWhereUniqueWithoutFoldersInput = {
  create: ConferenceCreateWithoutFoldersInput;
  update: ConferenceUpdateWithoutFoldersInput;
  where: ConferenceWhereUniqueInput;
};

export type ConferenceUpsertWithWhereUniqueWithoutOrganizationInput = {
  create: ConferenceCreateWithoutOrganizationInput;
  update: ConferenceUpdateWithoutOrganizationInput;
  where: ConferenceWhereUniqueInput;
};

export type ConferenceWhereInput = {
  AND?: InputMaybe<Array<ConferenceWhereInput>>;
  auto_transfer_selections?: InputMaybe<AutoTransferSelectionListRelationFilter>;
  call_ended?: InputMaybe<BoolNullableFilter>;
  call_result?: InputMaybe<DispositionWhereInput>;
  call_sid_to_coach?: InputMaybe<StringNullableFilter>;
  call_sid_to_lead?: InputMaybe<StringNullableFilter>;
  CallSharing?: InputMaybe<CallSharingListRelationFilter>;
  channel?: InputMaybe<EnumChannelNullableFilter>;
  closing_script_end?: InputMaybe<IntNullableFilter>;
  closing_script_start?: InputMaybe<IntNullableFilter>;
  coached_at?: InputMaybe<DateTimeNullableFilter>;
  coaching_notes?: InputMaybe<NoteItemListRelationFilter>;
  conference_code?: InputMaybe<StringNullableFilter>;
  conference_sid?: InputMaybe<StringNullableFilter>;
  created_at?: InputMaybe<DateTimeFilter>;
  dispositionId?: InputMaybe<StringNullableFilter>;
  duration?: InputMaybe<IntNullableFilter>;
  folders?: InputMaybe<FolderListRelationFilter>;
  id?: InputMaybe<StringFilter>;
  is_initial_call?: InputMaybe<BoolFilter>;
  lead_activities?: InputMaybe<LeadActivityListRelationFilter>;
  liveUserStatus?: InputMaybe<LiveUserStatusListRelationFilter>;
  multi_joined?: InputMaybe<BoolFilter>;
  noncall?: InputMaybe<BoolNullableFilter>;
  NOT?: InputMaybe<Array<ConferenceWhereInput>>;
  notification_id?: InputMaybe<StringNullableFilter>;
  notifications?: InputMaybe<NotificationListRelationFilter>;
  OR?: InputMaybe<Array<ConferenceWhereInput>>;
  Organization?: InputMaybe<OrganizationWhereInput>;
  organization_id?: InputMaybe<StringNullableFilter>;
  recording_sid?: InputMaybe<StringNullableFilter>;
  recording_track?: InputMaybe<StringNullableFilter>;
  recording_url?: InputMaybe<StringNullableFilter>;
  start_time?: InputMaybe<DateTimeNullableFilter>;
  suggested_dial?: InputMaybe<BoolNullableFilter>;
  transcription_url?: InputMaybe<StringNullableFilter>;
  transfer_attempts?: InputMaybe<TransferAttemptListRelationFilter>;
  TwilioFeedback?: InputMaybe<TwilioFeedbackWhereInput>;
  updated_at?: InputMaybe<DateTimeFilter>;
};

export type ConferenceWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']['input']>;
};

export enum Consentlaw {
  AllParty = 'AllParty',
  OneParty = 'OneParty'
}

export type Contact = {
  __typename?: 'Contact';
  channel?: Maybe<ContactType>;
  /** Returns the contact label based on mapping order */
  contact_label?: Maybe<Scalars['String']['output']>;
  contact_requests: Array<ContactRequest>;
  country_code?: Maybe<Scalars['String']['output']>;
  created_at: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  lead?: Maybe<Lead>;
  lead_id?: Maybe<Scalars['String']['output']>;
  /** Returns the primary phone number without country code */
  local_number?: Maybe<Scalars['String']['output']>;
  mapping_order?: Maybe<Scalars['Int']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  updated_at: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};


export type ContactContact_RequestsArgs = {
  after?: InputMaybe<ContactRequestWhereUniqueInput>;
  before?: InputMaybe<ContactRequestWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type ContactContact_Mapping_Order_Lead_Id_ChannelCompoundUniqueInput = {
  channel: ContactType;
  lead_id: Scalars['String']['input'];
  mapping_order: Scalars['Int']['input'];
};

export type ContactCreateManyLeadInput = {
  channel?: InputMaybe<ContactType>;
  country_code?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  mapping_order?: InputMaybe<Scalars['Int']['input']>;
  organization_id?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type ContactCreateManyLeadInputEnvelope = {
  data?: InputMaybe<Array<ContactCreateManyLeadInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ContactCreateManyOrganizationInput = {
  channel?: InputMaybe<ContactType>;
  country_code?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  lead_id?: InputMaybe<Scalars['String']['input']>;
  mapping_order?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type ContactCreateManyOrganizationInputEnvelope = {
  data?: InputMaybe<Array<ContactCreateManyOrganizationInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ContactCreateNestedManyWithoutLeadInput = {
  connect?: InputMaybe<Array<ContactWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ContactCreateOrConnectWithoutLeadInput>>;
  create?: InputMaybe<Array<ContactCreateWithoutLeadInput>>;
  createMany?: InputMaybe<ContactCreateManyLeadInputEnvelope>;
};

export type ContactCreateNestedManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<ContactWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ContactCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<ContactCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<ContactCreateManyOrganizationInputEnvelope>;
};

export type ContactCreateNestedOneWithoutContact_RequestsInput = {
  connect?: InputMaybe<ContactWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ContactCreateOrConnectWithoutContact_RequestsInput>;
  create?: InputMaybe<ContactCreateWithoutContact_RequestsInput>;
};

export type ContactCreateOrConnectWithoutContact_RequestsInput = {
  create: ContactCreateWithoutContact_RequestsInput;
  where: ContactWhereUniqueInput;
};

export type ContactCreateOrConnectWithoutLeadInput = {
  create: ContactCreateWithoutLeadInput;
  where: ContactWhereUniqueInput;
};

export type ContactCreateOrConnectWithoutOrganizationInput = {
  create: ContactCreateWithoutOrganizationInput;
  where: ContactWhereUniqueInput;
};

export type ContactCreateWithoutContact_RequestsInput = {
  channel?: InputMaybe<ContactType>;
  country_code?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  lead?: InputMaybe<LeadCreateNestedOneWithoutAlternate_ContactsInput>;
  mapping_order?: InputMaybe<Scalars['Int']['input']>;
  Organization?: InputMaybe<OrganizationCreateNestedOneWithoutContactsInput>;
  title?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type ContactCreateWithoutLeadInput = {
  channel?: InputMaybe<ContactType>;
  contact_requests?: InputMaybe<ContactRequestCreateNestedManyWithoutContactInput>;
  country_code?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  mapping_order?: InputMaybe<Scalars['Int']['input']>;
  Organization?: InputMaybe<OrganizationCreateNestedOneWithoutContactsInput>;
  title?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type ContactCreateWithoutOrganizationInput = {
  channel?: InputMaybe<ContactType>;
  contact_requests?: InputMaybe<ContactRequestCreateNestedManyWithoutContactInput>;
  country_code?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  lead?: InputMaybe<LeadCreateNestedOneWithoutAlternate_ContactsInput>;
  mapping_order?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type ContactListRelationFilter = {
  every?: InputMaybe<ContactWhereInput>;
  none?: InputMaybe<ContactWhereInput>;
  some?: InputMaybe<ContactWhereInput>;
};

export type ContactOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
  count?: InputMaybe<SortOrder>;
};

export type ContactOrderByWithRelationInput = {
  channel?: InputMaybe<SortOrder>;
  contact_requests?: InputMaybe<ContactRequestOrderByRelationAggregateInput>;
  country_code?: InputMaybe<SortOrder>;
  created_at?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  lead?: InputMaybe<LeadOrderByWithRelationInput>;
  lead_id?: InputMaybe<SortOrder>;
  mapping_order?: InputMaybe<SortOrder>;
  Organization?: InputMaybe<OrganizationOrderByWithRelationInput>;
  organization_id?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
  value?: InputMaybe<SortOrder>;
};

export type ContactRequest = {
  __typename?: 'ContactRequest';
  channel?: Maybe<ContactType>;
  contact?: Maybe<Contact>;
  contact_id?: Maybe<Scalars['String']['output']>;
  country_code?: Maybe<Scalars['String']['output']>;
  created_at: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  lead?: Maybe<Lead>;
  lead_id?: Maybe<Scalars['String']['output']>;
  operation: Operation;
  title?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  updated_at: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type ContactRequestCreateManyContactInput = {
  channel?: InputMaybe<ContactType>;
  country_code?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  lead_id?: InputMaybe<Scalars['String']['input']>;
  operation: Operation;
  organization_id?: InputMaybe<Scalars['String']['input']>;
  primary_email?: InputMaybe<Scalars['Boolean']['input']>;
  primary_phone_number?: InputMaybe<Scalars['Boolean']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type ContactRequestCreateManyContactInputEnvelope = {
  data?: InputMaybe<Array<ContactRequestCreateManyContactInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ContactRequestCreateManyLeadInput = {
  channel?: InputMaybe<ContactType>;
  contact_id?: InputMaybe<Scalars['String']['input']>;
  country_code?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  operation: Operation;
  organization_id?: InputMaybe<Scalars['String']['input']>;
  primary_email?: InputMaybe<Scalars['Boolean']['input']>;
  primary_phone_number?: InputMaybe<Scalars['Boolean']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type ContactRequestCreateManyLeadInputEnvelope = {
  data?: InputMaybe<Array<ContactRequestCreateManyLeadInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ContactRequestCreateManyOrganizationInput = {
  channel?: InputMaybe<ContactType>;
  contact_id?: InputMaybe<Scalars['String']['input']>;
  country_code?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  lead_id?: InputMaybe<Scalars['String']['input']>;
  operation: Operation;
  primary_email?: InputMaybe<Scalars['Boolean']['input']>;
  primary_phone_number?: InputMaybe<Scalars['Boolean']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type ContactRequestCreateManyOrganizationInputEnvelope = {
  data?: InputMaybe<Array<ContactRequestCreateManyOrganizationInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ContactRequestCreateManyRequesting_UserInput = {
  channel?: InputMaybe<ContactType>;
  contact_id?: InputMaybe<Scalars['String']['input']>;
  country_code?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  lead_id?: InputMaybe<Scalars['String']['input']>;
  operation: Operation;
  organization_id?: InputMaybe<Scalars['String']['input']>;
  primary_email?: InputMaybe<Scalars['Boolean']['input']>;
  primary_phone_number?: InputMaybe<Scalars['Boolean']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type ContactRequestCreateManyRequesting_UserInputEnvelope = {
  data?: InputMaybe<Array<ContactRequestCreateManyRequesting_UserInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ContactRequestCreateNestedManyWithoutContactInput = {
  connect?: InputMaybe<Array<ContactRequestWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ContactRequestCreateOrConnectWithoutContactInput>>;
  create?: InputMaybe<Array<ContactRequestCreateWithoutContactInput>>;
  createMany?: InputMaybe<ContactRequestCreateManyContactInputEnvelope>;
};

export type ContactRequestCreateNestedManyWithoutLeadInput = {
  connect?: InputMaybe<Array<ContactRequestWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ContactRequestCreateOrConnectWithoutLeadInput>>;
  create?: InputMaybe<Array<ContactRequestCreateWithoutLeadInput>>;
  createMany?: InputMaybe<ContactRequestCreateManyLeadInputEnvelope>;
};

export type ContactRequestCreateNestedManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<ContactRequestWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ContactRequestCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<ContactRequestCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<ContactRequestCreateManyOrganizationInputEnvelope>;
};

export type ContactRequestCreateNestedManyWithoutRequesting_UserInput = {
  connect?: InputMaybe<Array<ContactRequestWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ContactRequestCreateOrConnectWithoutRequesting_UserInput>>;
  create?: InputMaybe<Array<ContactRequestCreateWithoutRequesting_UserInput>>;
  createMany?: InputMaybe<ContactRequestCreateManyRequesting_UserInputEnvelope>;
};

export type ContactRequestCreateOrConnectWithoutContactInput = {
  create: ContactRequestCreateWithoutContactInput;
  where: ContactRequestWhereUniqueInput;
};

export type ContactRequestCreateOrConnectWithoutLeadInput = {
  create: ContactRequestCreateWithoutLeadInput;
  where: ContactRequestWhereUniqueInput;
};

export type ContactRequestCreateOrConnectWithoutOrganizationInput = {
  create: ContactRequestCreateWithoutOrganizationInput;
  where: ContactRequestWhereUniqueInput;
};

export type ContactRequestCreateOrConnectWithoutRequesting_UserInput = {
  create: ContactRequestCreateWithoutRequesting_UserInput;
  where: ContactRequestWhereUniqueInput;
};

export type ContactRequestCreateWithoutContactInput = {
  channel?: InputMaybe<ContactType>;
  country_code?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  lead?: InputMaybe<LeadCreateNestedOneWithoutContact_RequestsInput>;
  operation: Operation;
  Organization?: InputMaybe<OrganizationCreateNestedOneWithoutContactRequestsInput>;
  primary_email?: InputMaybe<Scalars['Boolean']['input']>;
  primary_phone_number?: InputMaybe<Scalars['Boolean']['input']>;
  requesting_user?: InputMaybe<UserCreateNestedOneWithoutContact_RequestsInput>;
  title?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type ContactRequestCreateWithoutLeadInput = {
  channel?: InputMaybe<ContactType>;
  contact?: InputMaybe<ContactCreateNestedOneWithoutContact_RequestsInput>;
  country_code?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  operation: Operation;
  Organization?: InputMaybe<OrganizationCreateNestedOneWithoutContactRequestsInput>;
  primary_email?: InputMaybe<Scalars['Boolean']['input']>;
  primary_phone_number?: InputMaybe<Scalars['Boolean']['input']>;
  requesting_user?: InputMaybe<UserCreateNestedOneWithoutContact_RequestsInput>;
  title?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type ContactRequestCreateWithoutOrganizationInput = {
  channel?: InputMaybe<ContactType>;
  contact?: InputMaybe<ContactCreateNestedOneWithoutContact_RequestsInput>;
  country_code?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  lead?: InputMaybe<LeadCreateNestedOneWithoutContact_RequestsInput>;
  operation: Operation;
  primary_email?: InputMaybe<Scalars['Boolean']['input']>;
  primary_phone_number?: InputMaybe<Scalars['Boolean']['input']>;
  requesting_user?: InputMaybe<UserCreateNestedOneWithoutContact_RequestsInput>;
  title?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type ContactRequestCreateWithoutRequesting_UserInput = {
  channel?: InputMaybe<ContactType>;
  contact?: InputMaybe<ContactCreateNestedOneWithoutContact_RequestsInput>;
  country_code?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  lead?: InputMaybe<LeadCreateNestedOneWithoutContact_RequestsInput>;
  operation: Operation;
  Organization?: InputMaybe<OrganizationCreateNestedOneWithoutContactRequestsInput>;
  primary_email?: InputMaybe<Scalars['Boolean']['input']>;
  primary_phone_number?: InputMaybe<Scalars['Boolean']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type ContactRequestListRelationFilter = {
  every?: InputMaybe<ContactRequestWhereInput>;
  none?: InputMaybe<ContactRequestWhereInput>;
  some?: InputMaybe<ContactRequestWhereInput>;
};

export type ContactRequestOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
  count?: InputMaybe<SortOrder>;
};

export type ContactRequestScalarWhereInput = {
  AND?: InputMaybe<Array<ContactRequestScalarWhereInput>>;
  channel?: InputMaybe<EnumContactTypeNullableFilter>;
  contact_id?: InputMaybe<StringNullableFilter>;
  country_code?: InputMaybe<StringNullableFilter>;
  created_at?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  lead_id?: InputMaybe<StringNullableFilter>;
  NOT?: InputMaybe<Array<ContactRequestScalarWhereInput>>;
  operation?: InputMaybe<EnumOperationFilter>;
  OR?: InputMaybe<Array<ContactRequestScalarWhereInput>>;
  organization_id?: InputMaybe<StringNullableFilter>;
  primary_email?: InputMaybe<BoolFilter>;
  primary_phone_number?: InputMaybe<BoolFilter>;
  title?: InputMaybe<StringNullableFilter>;
  type?: InputMaybe<StringNullableFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
  user_id?: InputMaybe<StringNullableFilter>;
  value?: InputMaybe<StringNullableFilter>;
};

export type ContactRequestUpdateManyMutationInput = {
  channel?: InputMaybe<NullableEnumContactTypeFieldUpdateOperationsInput>;
  country_code?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  operation?: InputMaybe<EnumOperationFieldUpdateOperationsInput>;
  primary_email?: InputMaybe<BoolFieldUpdateOperationsInput>;
  primary_phone_number?: InputMaybe<BoolFieldUpdateOperationsInput>;
  title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  type?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  value?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type ContactRequestUpdateManyWithoutContactInput = {
  connect?: InputMaybe<Array<ContactRequestWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ContactRequestCreateOrConnectWithoutContactInput>>;
  create?: InputMaybe<Array<ContactRequestCreateWithoutContactInput>>;
  createMany?: InputMaybe<ContactRequestCreateManyContactInputEnvelope>;
  delete?: InputMaybe<Array<ContactRequestWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ContactRequestScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ContactRequestWhereUniqueInput>>;
  set?: InputMaybe<Array<ContactRequestWhereUniqueInput>>;
  update?: InputMaybe<Array<ContactRequestUpdateWithWhereUniqueWithoutContactInput>>;
  updateMany?: InputMaybe<Array<ContactRequestUpdateManyWithWhereWithoutContactInput>>;
  upsert?: InputMaybe<Array<ContactRequestUpsertWithWhereUniqueWithoutContactInput>>;
};

export type ContactRequestUpdateManyWithoutLeadInput = {
  connect?: InputMaybe<Array<ContactRequestWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ContactRequestCreateOrConnectWithoutLeadInput>>;
  create?: InputMaybe<Array<ContactRequestCreateWithoutLeadInput>>;
  createMany?: InputMaybe<ContactRequestCreateManyLeadInputEnvelope>;
  delete?: InputMaybe<Array<ContactRequestWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ContactRequestScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ContactRequestWhereUniqueInput>>;
  set?: InputMaybe<Array<ContactRequestWhereUniqueInput>>;
  update?: InputMaybe<Array<ContactRequestUpdateWithWhereUniqueWithoutLeadInput>>;
  updateMany?: InputMaybe<Array<ContactRequestUpdateManyWithWhereWithoutLeadInput>>;
  upsert?: InputMaybe<Array<ContactRequestUpsertWithWhereUniqueWithoutLeadInput>>;
};

export type ContactRequestUpdateManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<ContactRequestWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ContactRequestCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<ContactRequestCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<ContactRequestCreateManyOrganizationInputEnvelope>;
  delete?: InputMaybe<Array<ContactRequestWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ContactRequestScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ContactRequestWhereUniqueInput>>;
  set?: InputMaybe<Array<ContactRequestWhereUniqueInput>>;
  update?: InputMaybe<Array<ContactRequestUpdateWithWhereUniqueWithoutOrganizationInput>>;
  updateMany?: InputMaybe<Array<ContactRequestUpdateManyWithWhereWithoutOrganizationInput>>;
  upsert?: InputMaybe<Array<ContactRequestUpsertWithWhereUniqueWithoutOrganizationInput>>;
};

export type ContactRequestUpdateManyWithoutRequesting_UserInput = {
  connect?: InputMaybe<Array<ContactRequestWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ContactRequestCreateOrConnectWithoutRequesting_UserInput>>;
  create?: InputMaybe<Array<ContactRequestCreateWithoutRequesting_UserInput>>;
  createMany?: InputMaybe<ContactRequestCreateManyRequesting_UserInputEnvelope>;
  delete?: InputMaybe<Array<ContactRequestWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ContactRequestScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ContactRequestWhereUniqueInput>>;
  set?: InputMaybe<Array<ContactRequestWhereUniqueInput>>;
  update?: InputMaybe<Array<ContactRequestUpdateWithWhereUniqueWithoutRequesting_UserInput>>;
  updateMany?: InputMaybe<Array<ContactRequestUpdateManyWithWhereWithoutRequesting_UserInput>>;
  upsert?: InputMaybe<Array<ContactRequestUpsertWithWhereUniqueWithoutRequesting_UserInput>>;
};

export type ContactRequestUpdateManyWithWhereWithoutContactInput = {
  data: ContactRequestUpdateManyMutationInput;
  where: ContactRequestScalarWhereInput;
};

export type ContactRequestUpdateManyWithWhereWithoutLeadInput = {
  data: ContactRequestUpdateManyMutationInput;
  where: ContactRequestScalarWhereInput;
};

export type ContactRequestUpdateManyWithWhereWithoutOrganizationInput = {
  data: ContactRequestUpdateManyMutationInput;
  where: ContactRequestScalarWhereInput;
};

export type ContactRequestUpdateManyWithWhereWithoutRequesting_UserInput = {
  data: ContactRequestUpdateManyMutationInput;
  where: ContactRequestScalarWhereInput;
};

export type ContactRequestUpdateWithoutContactInput = {
  channel?: InputMaybe<NullableEnumContactTypeFieldUpdateOperationsInput>;
  country_code?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lead?: InputMaybe<LeadUpdateOneWithoutContact_RequestsInput>;
  operation?: InputMaybe<EnumOperationFieldUpdateOperationsInput>;
  Organization?: InputMaybe<OrganizationUpdateOneWithoutContactRequestsInput>;
  primary_email?: InputMaybe<BoolFieldUpdateOperationsInput>;
  primary_phone_number?: InputMaybe<BoolFieldUpdateOperationsInput>;
  requesting_user?: InputMaybe<UserUpdateOneWithoutContact_RequestsInput>;
  title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  type?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  value?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type ContactRequestUpdateWithoutLeadInput = {
  channel?: InputMaybe<NullableEnumContactTypeFieldUpdateOperationsInput>;
  contact?: InputMaybe<ContactUpdateOneWithoutContact_RequestsInput>;
  country_code?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  operation?: InputMaybe<EnumOperationFieldUpdateOperationsInput>;
  Organization?: InputMaybe<OrganizationUpdateOneWithoutContactRequestsInput>;
  primary_email?: InputMaybe<BoolFieldUpdateOperationsInput>;
  primary_phone_number?: InputMaybe<BoolFieldUpdateOperationsInput>;
  requesting_user?: InputMaybe<UserUpdateOneWithoutContact_RequestsInput>;
  title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  type?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  value?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type ContactRequestUpdateWithoutOrganizationInput = {
  channel?: InputMaybe<NullableEnumContactTypeFieldUpdateOperationsInput>;
  contact?: InputMaybe<ContactUpdateOneWithoutContact_RequestsInput>;
  country_code?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lead?: InputMaybe<LeadUpdateOneWithoutContact_RequestsInput>;
  operation?: InputMaybe<EnumOperationFieldUpdateOperationsInput>;
  primary_email?: InputMaybe<BoolFieldUpdateOperationsInput>;
  primary_phone_number?: InputMaybe<BoolFieldUpdateOperationsInput>;
  requesting_user?: InputMaybe<UserUpdateOneWithoutContact_RequestsInput>;
  title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  type?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  value?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type ContactRequestUpdateWithoutRequesting_UserInput = {
  channel?: InputMaybe<NullableEnumContactTypeFieldUpdateOperationsInput>;
  contact?: InputMaybe<ContactUpdateOneWithoutContact_RequestsInput>;
  country_code?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lead?: InputMaybe<LeadUpdateOneWithoutContact_RequestsInput>;
  operation?: InputMaybe<EnumOperationFieldUpdateOperationsInput>;
  Organization?: InputMaybe<OrganizationUpdateOneWithoutContactRequestsInput>;
  primary_email?: InputMaybe<BoolFieldUpdateOperationsInput>;
  primary_phone_number?: InputMaybe<BoolFieldUpdateOperationsInput>;
  title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  type?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  value?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type ContactRequestUpdateWithWhereUniqueWithoutContactInput = {
  data: ContactRequestUpdateWithoutContactInput;
  where: ContactRequestWhereUniqueInput;
};

export type ContactRequestUpdateWithWhereUniqueWithoutLeadInput = {
  data: ContactRequestUpdateWithoutLeadInput;
  where: ContactRequestWhereUniqueInput;
};

export type ContactRequestUpdateWithWhereUniqueWithoutOrganizationInput = {
  data: ContactRequestUpdateWithoutOrganizationInput;
  where: ContactRequestWhereUniqueInput;
};

export type ContactRequestUpdateWithWhereUniqueWithoutRequesting_UserInput = {
  data: ContactRequestUpdateWithoutRequesting_UserInput;
  where: ContactRequestWhereUniqueInput;
};

export type ContactRequestUpsertWithWhereUniqueWithoutContactInput = {
  create: ContactRequestCreateWithoutContactInput;
  update: ContactRequestUpdateWithoutContactInput;
  where: ContactRequestWhereUniqueInput;
};

export type ContactRequestUpsertWithWhereUniqueWithoutLeadInput = {
  create: ContactRequestCreateWithoutLeadInput;
  update: ContactRequestUpdateWithoutLeadInput;
  where: ContactRequestWhereUniqueInput;
};

export type ContactRequestUpsertWithWhereUniqueWithoutOrganizationInput = {
  create: ContactRequestCreateWithoutOrganizationInput;
  update: ContactRequestUpdateWithoutOrganizationInput;
  where: ContactRequestWhereUniqueInput;
};

export type ContactRequestUpsertWithWhereUniqueWithoutRequesting_UserInput = {
  create: ContactRequestCreateWithoutRequesting_UserInput;
  update: ContactRequestUpdateWithoutRequesting_UserInput;
  where: ContactRequestWhereUniqueInput;
};

export type ContactRequestWhereInput = {
  AND?: InputMaybe<Array<ContactRequestWhereInput>>;
  channel?: InputMaybe<EnumContactTypeNullableFilter>;
  contact?: InputMaybe<ContactWhereInput>;
  contact_id?: InputMaybe<StringNullableFilter>;
  country_code?: InputMaybe<StringNullableFilter>;
  created_at?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  lead?: InputMaybe<LeadWhereInput>;
  lead_id?: InputMaybe<StringNullableFilter>;
  NOT?: InputMaybe<Array<ContactRequestWhereInput>>;
  operation?: InputMaybe<EnumOperationFilter>;
  OR?: InputMaybe<Array<ContactRequestWhereInput>>;
  Organization?: InputMaybe<OrganizationWhereInput>;
  organization_id?: InputMaybe<StringNullableFilter>;
  primary_email?: InputMaybe<BoolFilter>;
  primary_phone_number?: InputMaybe<BoolFilter>;
  requesting_user?: InputMaybe<UserWhereInput>;
  title?: InputMaybe<StringNullableFilter>;
  type?: InputMaybe<StringNullableFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
  user_id?: InputMaybe<StringNullableFilter>;
  value?: InputMaybe<StringNullableFilter>;
};

export type ContactRequestWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']['input']>;
};

export type ContactScalarWhereInput = {
  AND?: InputMaybe<Array<ContactScalarWhereInput>>;
  channel?: InputMaybe<EnumContactTypeNullableFilter>;
  country_code?: InputMaybe<StringNullableFilter>;
  created_at?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  lead_id?: InputMaybe<StringNullableFilter>;
  mapping_order?: InputMaybe<IntNullableFilter>;
  NOT?: InputMaybe<Array<ContactScalarWhereInput>>;
  OR?: InputMaybe<Array<ContactScalarWhereInput>>;
  organization_id?: InputMaybe<StringNullableFilter>;
  title?: InputMaybe<StringNullableFilter>;
  type?: InputMaybe<StringNullableFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
  value?: InputMaybe<StringNullableFilter>;
};

export enum ContactType {
  Email = 'Email',
  Phone = 'Phone',
  Social = 'Social'
}

export type ContactUpdateManyMutationInput = {
  channel?: InputMaybe<NullableEnumContactTypeFieldUpdateOperationsInput>;
  country_code?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  mapping_order?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  type?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  value?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type ContactUpdateManyWithoutLeadInput = {
  connect?: InputMaybe<Array<ContactWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ContactCreateOrConnectWithoutLeadInput>>;
  create?: InputMaybe<Array<ContactCreateWithoutLeadInput>>;
  createMany?: InputMaybe<ContactCreateManyLeadInputEnvelope>;
  delete?: InputMaybe<Array<ContactWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ContactScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ContactWhereUniqueInput>>;
  set?: InputMaybe<Array<ContactWhereUniqueInput>>;
  update?: InputMaybe<Array<ContactUpdateWithWhereUniqueWithoutLeadInput>>;
  updateMany?: InputMaybe<Array<ContactUpdateManyWithWhereWithoutLeadInput>>;
  upsert?: InputMaybe<Array<ContactUpsertWithWhereUniqueWithoutLeadInput>>;
};

export type ContactUpdateManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<ContactWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ContactCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<ContactCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<ContactCreateManyOrganizationInputEnvelope>;
  delete?: InputMaybe<Array<ContactWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ContactScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ContactWhereUniqueInput>>;
  set?: InputMaybe<Array<ContactWhereUniqueInput>>;
  update?: InputMaybe<Array<ContactUpdateWithWhereUniqueWithoutOrganizationInput>>;
  updateMany?: InputMaybe<Array<ContactUpdateManyWithWhereWithoutOrganizationInput>>;
  upsert?: InputMaybe<Array<ContactUpsertWithWhereUniqueWithoutOrganizationInput>>;
};

export type ContactUpdateManyWithWhereWithoutLeadInput = {
  data: ContactUpdateManyMutationInput;
  where: ContactScalarWhereInput;
};

export type ContactUpdateManyWithWhereWithoutOrganizationInput = {
  data: ContactUpdateManyMutationInput;
  where: ContactScalarWhereInput;
};

export type ContactUpdateOneWithoutContact_RequestsInput = {
  connect?: InputMaybe<ContactWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ContactCreateOrConnectWithoutContact_RequestsInput>;
  create?: InputMaybe<ContactCreateWithoutContact_RequestsInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<ContactUpdateWithoutContact_RequestsInput>;
  upsert?: InputMaybe<ContactUpsertWithoutContact_RequestsInput>;
};

export type ContactUpdateWithoutContact_RequestsInput = {
  channel?: InputMaybe<NullableEnumContactTypeFieldUpdateOperationsInput>;
  country_code?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lead?: InputMaybe<LeadUpdateOneWithoutAlternate_ContactsInput>;
  mapping_order?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  Organization?: InputMaybe<OrganizationUpdateOneWithoutContactsInput>;
  title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  type?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  value?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type ContactUpdateWithoutLeadInput = {
  channel?: InputMaybe<NullableEnumContactTypeFieldUpdateOperationsInput>;
  contact_requests?: InputMaybe<ContactRequestUpdateManyWithoutContactInput>;
  country_code?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  mapping_order?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  Organization?: InputMaybe<OrganizationUpdateOneWithoutContactsInput>;
  title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  type?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  value?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type ContactUpdateWithoutOrganizationInput = {
  channel?: InputMaybe<NullableEnumContactTypeFieldUpdateOperationsInput>;
  contact_requests?: InputMaybe<ContactRequestUpdateManyWithoutContactInput>;
  country_code?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lead?: InputMaybe<LeadUpdateOneWithoutAlternate_ContactsInput>;
  mapping_order?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  type?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  value?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type ContactUpdateWithWhereUniqueWithoutLeadInput = {
  data: ContactUpdateWithoutLeadInput;
  where: ContactWhereUniqueInput;
};

export type ContactUpdateWithWhereUniqueWithoutOrganizationInput = {
  data: ContactUpdateWithoutOrganizationInput;
  where: ContactWhereUniqueInput;
};

export type ContactUpsertWithoutContact_RequestsInput = {
  create: ContactCreateWithoutContact_RequestsInput;
  update: ContactUpdateWithoutContact_RequestsInput;
};

export type ContactUpsertWithWhereUniqueWithoutLeadInput = {
  create: ContactCreateWithoutLeadInput;
  update: ContactUpdateWithoutLeadInput;
  where: ContactWhereUniqueInput;
};

export type ContactUpsertWithWhereUniqueWithoutOrganizationInput = {
  create: ContactCreateWithoutOrganizationInput;
  update: ContactUpdateWithoutOrganizationInput;
  where: ContactWhereUniqueInput;
};

export type ContactWhereInput = {
  AND?: InputMaybe<Array<ContactWhereInput>>;
  channel?: InputMaybe<EnumContactTypeNullableFilter>;
  contact_requests?: InputMaybe<ContactRequestListRelationFilter>;
  country_code?: InputMaybe<StringNullableFilter>;
  created_at?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  lead?: InputMaybe<LeadWhereInput>;
  lead_id?: InputMaybe<StringNullableFilter>;
  mapping_order?: InputMaybe<IntNullableFilter>;
  NOT?: InputMaybe<Array<ContactWhereInput>>;
  OR?: InputMaybe<Array<ContactWhereInput>>;
  Organization?: InputMaybe<OrganizationWhereInput>;
  organization_id?: InputMaybe<StringNullableFilter>;
  title?: InputMaybe<StringNullableFilter>;
  type?: InputMaybe<StringNullableFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
  value?: InputMaybe<StringNullableFilter>;
};

export type ContactWhereUniqueInput = {
  contact_mapping_order_lead_id_channel?: InputMaybe<ContactContact_Mapping_Order_Lead_Id_ChannelCompoundUniqueInput>;
  id?: InputMaybe<Scalars['String']['input']>;
};

export type CsvUpload = {
  __typename?: 'CSVUpload';
  error_message?: Maybe<Scalars['String']['output']>;
  num_failed?: Maybe<Scalars['Int']['output']>;
  num_success?: Maybe<Scalars['Int']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type CustomField = {
  __typename?: 'CustomField';
  allow_reps_to_edit: Scalars['Boolean']['output'];
  boo_value?: Maybe<Scalars['Boolean']['output']>;
  computed_value?: Maybe<Scalars['String']['output']>;
  created_at: Scalars['DateTime']['output'];
  date_value?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  key: Scalars['String']['output'];
  lead?: Maybe<Lead>;
  lead_id?: Maybe<Scalars['String']['output']>;
  list_value: Array<Scalars['String']['output']>;
  num_value?: Maybe<Scalars['Float']['output']>;
  options: Array<Scalars['String']['output']>;
  ordinal: Scalars['Int']['output'];
  organization?: Maybe<Organization>;
  organization_id?: Maybe<Scalars['String']['output']>;
  required_for_sale?: Maybe<Scalars['Boolean']['output']>;
  required_required_for_sale?: Maybe<Scalars['Boolean']['output']>;
  type: CustomFieldType;
  updated_at: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
  visible: Scalars['Boolean']['output'];
};

export type CustomFieldCreatelist_ValueInput = {
  set?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type CustomFieldCreateManyLeadInput = {
  allow_reps_to_edit?: InputMaybe<Scalars['Boolean']['input']>;
  boo_value?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  date_value?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  key: Scalars['String']['input'];
  list_value?: InputMaybe<CustomFieldCreateManylist_ValueInput>;
  num_value?: InputMaybe<Scalars['Float']['input']>;
  options?: InputMaybe<CustomFieldCreateManyoptionsInput>;
  ordinal?: InputMaybe<Scalars['Int']['input']>;
  organization_id?: InputMaybe<Scalars['String']['input']>;
  required_for_sale?: InputMaybe<Scalars['Boolean']['input']>;
  required_required_for_sale?: InputMaybe<Scalars['Boolean']['input']>;
  type?: InputMaybe<CustomFieldType>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
  visible?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CustomFieldCreateManyLeadInputEnvelope = {
  data?: InputMaybe<Array<CustomFieldCreateManyLeadInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CustomFieldCreateManylist_ValueInput = {
  set?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type CustomFieldCreateManyoptionsInput = {
  set?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type CustomFieldCreateManyOrganizationInput = {
  allow_reps_to_edit?: InputMaybe<Scalars['Boolean']['input']>;
  boo_value?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  date_value?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  key: Scalars['String']['input'];
  lead_id?: InputMaybe<Scalars['String']['input']>;
  list_value?: InputMaybe<CustomFieldCreateManylist_ValueInput>;
  num_value?: InputMaybe<Scalars['Float']['input']>;
  options?: InputMaybe<CustomFieldCreateManyoptionsInput>;
  ordinal?: InputMaybe<Scalars['Int']['input']>;
  required_for_sale?: InputMaybe<Scalars['Boolean']['input']>;
  required_required_for_sale?: InputMaybe<Scalars['Boolean']['input']>;
  type?: InputMaybe<CustomFieldType>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
  visible?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CustomFieldCreateManyOrganizationInputEnvelope = {
  data?: InputMaybe<Array<CustomFieldCreateManyOrganizationInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CustomFieldCreateNestedManyWithoutLeadInput = {
  connect?: InputMaybe<Array<CustomFieldWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CustomFieldCreateOrConnectWithoutLeadInput>>;
  create?: InputMaybe<Array<CustomFieldCreateWithoutLeadInput>>;
  createMany?: InputMaybe<CustomFieldCreateManyLeadInputEnvelope>;
};

export type CustomFieldCreateNestedManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<CustomFieldWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CustomFieldCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<CustomFieldCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<CustomFieldCreateManyOrganizationInputEnvelope>;
};

export type CustomFieldCreateNestedOneWithoutSaleConfigSectionInput = {
  connect?: InputMaybe<CustomFieldWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CustomFieldCreateOrConnectWithoutSaleConfigSectionInput>;
  create?: InputMaybe<CustomFieldCreateWithoutSaleConfigSectionInput>;
};

export type CustomFieldCreateNestedOneWithoutUserCustomFieldFilterInput = {
  connect?: InputMaybe<CustomFieldWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CustomFieldCreateOrConnectWithoutUserCustomFieldFilterInput>;
  create?: InputMaybe<CustomFieldCreateWithoutUserCustomFieldFilterInput>;
};

export type CustomFieldCreateoptionsInput = {
  set?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type CustomFieldCreateOrConnectWithoutLeadInput = {
  create: CustomFieldCreateWithoutLeadInput;
  where: CustomFieldWhereUniqueInput;
};

export type CustomFieldCreateOrConnectWithoutOrganizationInput = {
  create: CustomFieldCreateWithoutOrganizationInput;
  where: CustomFieldWhereUniqueInput;
};

export type CustomFieldCreateOrConnectWithoutSaleConfigSectionInput = {
  create: CustomFieldCreateWithoutSaleConfigSectionInput;
  where: CustomFieldWhereUniqueInput;
};

export type CustomFieldCreateOrConnectWithoutUserCustomFieldFilterInput = {
  create: CustomFieldCreateWithoutUserCustomFieldFilterInput;
  where: CustomFieldWhereUniqueInput;
};

export type CustomFieldCreateWithoutLeadInput = {
  allow_reps_to_edit?: InputMaybe<Scalars['Boolean']['input']>;
  boo_value?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  date_value?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  key: Scalars['String']['input'];
  list_value?: InputMaybe<CustomFieldCreatelist_ValueInput>;
  num_value?: InputMaybe<Scalars['Float']['input']>;
  options?: InputMaybe<CustomFieldCreateoptionsInput>;
  ordinal?: InputMaybe<Scalars['Int']['input']>;
  organization?: InputMaybe<OrganizationCreateNestedOneWithoutCustom_FieldsInput>;
  required_for_sale?: InputMaybe<Scalars['Boolean']['input']>;
  required_required_for_sale?: InputMaybe<Scalars['Boolean']['input']>;
  SaleConfigSection?: InputMaybe<SaleConfigSectionCreateNestedManyWithoutCustom_FieldInput>;
  type?: InputMaybe<CustomFieldType>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  userCustomFieldFilter?: InputMaybe<UserCustomFieldFilterCreateNestedManyWithoutCustom_FieldInput>;
  value?: InputMaybe<Scalars['String']['input']>;
  visible?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CustomFieldCreateWithoutOrganizationInput = {
  allow_reps_to_edit?: InputMaybe<Scalars['Boolean']['input']>;
  boo_value?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  date_value?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  key: Scalars['String']['input'];
  lead?: InputMaybe<LeadCreateNestedOneWithoutCustom_FieldsInput>;
  list_value?: InputMaybe<CustomFieldCreatelist_ValueInput>;
  num_value?: InputMaybe<Scalars['Float']['input']>;
  options?: InputMaybe<CustomFieldCreateoptionsInput>;
  ordinal?: InputMaybe<Scalars['Int']['input']>;
  required_for_sale?: InputMaybe<Scalars['Boolean']['input']>;
  required_required_for_sale?: InputMaybe<Scalars['Boolean']['input']>;
  SaleConfigSection?: InputMaybe<SaleConfigSectionCreateNestedManyWithoutCustom_FieldInput>;
  type?: InputMaybe<CustomFieldType>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  userCustomFieldFilter?: InputMaybe<UserCustomFieldFilterCreateNestedManyWithoutCustom_FieldInput>;
  value?: InputMaybe<Scalars['String']['input']>;
  visible?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CustomFieldCreateWithoutSaleConfigSectionInput = {
  allow_reps_to_edit?: InputMaybe<Scalars['Boolean']['input']>;
  boo_value?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  date_value?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  key: Scalars['String']['input'];
  lead?: InputMaybe<LeadCreateNestedOneWithoutCustom_FieldsInput>;
  list_value?: InputMaybe<CustomFieldCreatelist_ValueInput>;
  num_value?: InputMaybe<Scalars['Float']['input']>;
  options?: InputMaybe<CustomFieldCreateoptionsInput>;
  ordinal?: InputMaybe<Scalars['Int']['input']>;
  organization?: InputMaybe<OrganizationCreateNestedOneWithoutCustom_FieldsInput>;
  required_for_sale?: InputMaybe<Scalars['Boolean']['input']>;
  required_required_for_sale?: InputMaybe<Scalars['Boolean']['input']>;
  type?: InputMaybe<CustomFieldType>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  userCustomFieldFilter?: InputMaybe<UserCustomFieldFilterCreateNestedManyWithoutCustom_FieldInput>;
  value?: InputMaybe<Scalars['String']['input']>;
  visible?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CustomFieldCreateWithoutUserCustomFieldFilterInput = {
  allow_reps_to_edit?: InputMaybe<Scalars['Boolean']['input']>;
  boo_value?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  date_value?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  key: Scalars['String']['input'];
  lead?: InputMaybe<LeadCreateNestedOneWithoutCustom_FieldsInput>;
  list_value?: InputMaybe<CustomFieldCreatelist_ValueInput>;
  num_value?: InputMaybe<Scalars['Float']['input']>;
  options?: InputMaybe<CustomFieldCreateoptionsInput>;
  ordinal?: InputMaybe<Scalars['Int']['input']>;
  organization?: InputMaybe<OrganizationCreateNestedOneWithoutCustom_FieldsInput>;
  required_for_sale?: InputMaybe<Scalars['Boolean']['input']>;
  required_required_for_sale?: InputMaybe<Scalars['Boolean']['input']>;
  SaleConfigSection?: InputMaybe<SaleConfigSectionCreateNestedManyWithoutCustom_FieldInput>;
  type?: InputMaybe<CustomFieldType>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
  visible?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CustomFieldFkDataInput = {
  custom_object_field_id: Scalars['String']['input'];
  custom_object_id: Scalars['String']['input'];
  lead_system_field_key: Scalars['String']['input'];
};

export type CustomFieldInput = {
  boo_value?: InputMaybe<Scalars['Boolean']['input']>;
  date_value?: InputMaybe<Scalars['String']['input']>;
  /** id of the custom field */
  id: Scalars['String']['input'];
  list_value?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  num_value?: InputMaybe<Scalars['Float']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type CustomFieldListRelationFilter = {
  every?: InputMaybe<CustomFieldWhereInput>;
  none?: InputMaybe<CustomFieldWhereInput>;
  some?: InputMaybe<CustomFieldWhereInput>;
};

export type CustomFieldOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
  count?: InputMaybe<SortOrder>;
};

export type CustomFieldScalarWhereInput = {
  allow_reps_to_edit?: InputMaybe<BoolFilter>;
  AND?: InputMaybe<Array<CustomFieldScalarWhereInput>>;
  boo_value?: InputMaybe<BoolNullableFilter>;
  created_at?: InputMaybe<DateTimeFilter>;
  date_value?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  key?: InputMaybe<StringFilter>;
  lead_id?: InputMaybe<StringNullableFilter>;
  list_value?: InputMaybe<StringNullableListFilter>;
  NOT?: InputMaybe<Array<CustomFieldScalarWhereInput>>;
  num_value?: InputMaybe<FloatNullableFilter>;
  options?: InputMaybe<StringNullableListFilter>;
  OR?: InputMaybe<Array<CustomFieldScalarWhereInput>>;
  ordinal?: InputMaybe<IntFilter>;
  organization_id?: InputMaybe<StringNullableFilter>;
  required_for_sale?: InputMaybe<BoolNullableFilter>;
  required_required_for_sale?: InputMaybe<BoolNullableFilter>;
  type?: InputMaybe<EnumCustomFieldTypeFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
  value?: InputMaybe<StringNullableFilter>;
  visible?: InputMaybe<BoolFilter>;
};

export enum CustomFieldType {
  Boolean = 'Boolean',
  Date = 'Date',
  DateTime = 'DateTime',
  Dropdown = 'Dropdown',
  MultiDropdown = 'MultiDropdown',
  MultiText = 'MultiText',
  Number = 'Number',
  Percentage = 'Percentage',
  Rate = 'Rate',
  Reference = 'Reference',
  Text = 'Text'
}

export type CustomFieldUpdatelist_ValueInput = {
  push?: InputMaybe<Scalars['String']['input']>;
  set?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type CustomFieldUpdateManyMutationInput = {
  allow_reps_to_edit?: InputMaybe<BoolFieldUpdateOperationsInput>;
  boo_value?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  date_value?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  key?: InputMaybe<StringFieldUpdateOperationsInput>;
  list_value?: InputMaybe<CustomFieldUpdatelist_ValueInput>;
  num_value?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  options?: InputMaybe<CustomFieldUpdateoptionsInput>;
  ordinal?: InputMaybe<IntFieldUpdateOperationsInput>;
  required_for_sale?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  required_required_for_sale?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumCustomFieldTypeFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  value?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  visible?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type CustomFieldUpdateManyWithoutLeadInput = {
  connect?: InputMaybe<Array<CustomFieldWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CustomFieldCreateOrConnectWithoutLeadInput>>;
  create?: InputMaybe<Array<CustomFieldCreateWithoutLeadInput>>;
  createMany?: InputMaybe<CustomFieldCreateManyLeadInputEnvelope>;
  delete?: InputMaybe<Array<CustomFieldWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CustomFieldScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CustomFieldWhereUniqueInput>>;
  set?: InputMaybe<Array<CustomFieldWhereUniqueInput>>;
  update?: InputMaybe<Array<CustomFieldUpdateWithWhereUniqueWithoutLeadInput>>;
  updateMany?: InputMaybe<Array<CustomFieldUpdateManyWithWhereWithoutLeadInput>>;
  upsert?: InputMaybe<Array<CustomFieldUpsertWithWhereUniqueWithoutLeadInput>>;
};

export type CustomFieldUpdateManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<CustomFieldWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CustomFieldCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<CustomFieldCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<CustomFieldCreateManyOrganizationInputEnvelope>;
  delete?: InputMaybe<Array<CustomFieldWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CustomFieldScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CustomFieldWhereUniqueInput>>;
  set?: InputMaybe<Array<CustomFieldWhereUniqueInput>>;
  update?: InputMaybe<Array<CustomFieldUpdateWithWhereUniqueWithoutOrganizationInput>>;
  updateMany?: InputMaybe<Array<CustomFieldUpdateManyWithWhereWithoutOrganizationInput>>;
  upsert?: InputMaybe<Array<CustomFieldUpsertWithWhereUniqueWithoutOrganizationInput>>;
};

export type CustomFieldUpdateManyWithWhereWithoutLeadInput = {
  data: CustomFieldUpdateManyMutationInput;
  where: CustomFieldScalarWhereInput;
};

export type CustomFieldUpdateManyWithWhereWithoutOrganizationInput = {
  data: CustomFieldUpdateManyMutationInput;
  where: CustomFieldScalarWhereInput;
};

export type CustomFieldUpdateOneRequiredWithoutUserCustomFieldFilterInput = {
  connect?: InputMaybe<CustomFieldWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CustomFieldCreateOrConnectWithoutUserCustomFieldFilterInput>;
  create?: InputMaybe<CustomFieldCreateWithoutUserCustomFieldFilterInput>;
  update?: InputMaybe<CustomFieldUpdateWithoutUserCustomFieldFilterInput>;
  upsert?: InputMaybe<CustomFieldUpsertWithoutUserCustomFieldFilterInput>;
};

export type CustomFieldUpdateOneWithoutSaleConfigSectionInput = {
  connect?: InputMaybe<CustomFieldWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CustomFieldCreateOrConnectWithoutSaleConfigSectionInput>;
  create?: InputMaybe<CustomFieldCreateWithoutSaleConfigSectionInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<CustomFieldUpdateWithoutSaleConfigSectionInput>;
  upsert?: InputMaybe<CustomFieldUpsertWithoutSaleConfigSectionInput>;
};

export type CustomFieldUpdateoptionsInput = {
  push?: InputMaybe<Scalars['String']['input']>;
  set?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type CustomFieldUpdateWithoutLeadInput = {
  allow_reps_to_edit?: InputMaybe<BoolFieldUpdateOperationsInput>;
  boo_value?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  date_value?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  key?: InputMaybe<StringFieldUpdateOperationsInput>;
  list_value?: InputMaybe<CustomFieldUpdatelist_ValueInput>;
  num_value?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  options?: InputMaybe<CustomFieldUpdateoptionsInput>;
  ordinal?: InputMaybe<IntFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneWithoutCustom_FieldsInput>;
  required_for_sale?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  required_required_for_sale?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  SaleConfigSection?: InputMaybe<SaleConfigSectionUpdateManyWithoutCustom_FieldInput>;
  type?: InputMaybe<EnumCustomFieldTypeFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  userCustomFieldFilter?: InputMaybe<UserCustomFieldFilterUpdateManyWithoutCustom_FieldInput>;
  value?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  visible?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type CustomFieldUpdateWithoutOrganizationInput = {
  allow_reps_to_edit?: InputMaybe<BoolFieldUpdateOperationsInput>;
  boo_value?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  date_value?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  key?: InputMaybe<StringFieldUpdateOperationsInput>;
  lead?: InputMaybe<LeadUpdateOneWithoutCustom_FieldsInput>;
  list_value?: InputMaybe<CustomFieldUpdatelist_ValueInput>;
  num_value?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  options?: InputMaybe<CustomFieldUpdateoptionsInput>;
  ordinal?: InputMaybe<IntFieldUpdateOperationsInput>;
  required_for_sale?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  required_required_for_sale?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  SaleConfigSection?: InputMaybe<SaleConfigSectionUpdateManyWithoutCustom_FieldInput>;
  type?: InputMaybe<EnumCustomFieldTypeFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  userCustomFieldFilter?: InputMaybe<UserCustomFieldFilterUpdateManyWithoutCustom_FieldInput>;
  value?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  visible?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type CustomFieldUpdateWithoutSaleConfigSectionInput = {
  allow_reps_to_edit?: InputMaybe<BoolFieldUpdateOperationsInput>;
  boo_value?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  date_value?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  key?: InputMaybe<StringFieldUpdateOperationsInput>;
  lead?: InputMaybe<LeadUpdateOneWithoutCustom_FieldsInput>;
  list_value?: InputMaybe<CustomFieldUpdatelist_ValueInput>;
  num_value?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  options?: InputMaybe<CustomFieldUpdateoptionsInput>;
  ordinal?: InputMaybe<IntFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneWithoutCustom_FieldsInput>;
  required_for_sale?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  required_required_for_sale?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumCustomFieldTypeFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  userCustomFieldFilter?: InputMaybe<UserCustomFieldFilterUpdateManyWithoutCustom_FieldInput>;
  value?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  visible?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type CustomFieldUpdateWithoutUserCustomFieldFilterInput = {
  allow_reps_to_edit?: InputMaybe<BoolFieldUpdateOperationsInput>;
  boo_value?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  date_value?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  key?: InputMaybe<StringFieldUpdateOperationsInput>;
  lead?: InputMaybe<LeadUpdateOneWithoutCustom_FieldsInput>;
  list_value?: InputMaybe<CustomFieldUpdatelist_ValueInput>;
  num_value?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  options?: InputMaybe<CustomFieldUpdateoptionsInput>;
  ordinal?: InputMaybe<IntFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneWithoutCustom_FieldsInput>;
  required_for_sale?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  required_required_for_sale?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  SaleConfigSection?: InputMaybe<SaleConfigSectionUpdateManyWithoutCustom_FieldInput>;
  type?: InputMaybe<EnumCustomFieldTypeFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  value?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  visible?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type CustomFieldUpdateWithWhereUniqueWithoutLeadInput = {
  data: CustomFieldUpdateWithoutLeadInput;
  where: CustomFieldWhereUniqueInput;
};

export type CustomFieldUpdateWithWhereUniqueWithoutOrganizationInput = {
  data: CustomFieldUpdateWithoutOrganizationInput;
  where: CustomFieldWhereUniqueInput;
};

export type CustomFieldUpsertWithoutSaleConfigSectionInput = {
  create: CustomFieldCreateWithoutSaleConfigSectionInput;
  update: CustomFieldUpdateWithoutSaleConfigSectionInput;
};

export type CustomFieldUpsertWithoutUserCustomFieldFilterInput = {
  create: CustomFieldCreateWithoutUserCustomFieldFilterInput;
  update: CustomFieldUpdateWithoutUserCustomFieldFilterInput;
};

export type CustomFieldUpsertWithWhereUniqueWithoutLeadInput = {
  create: CustomFieldCreateWithoutLeadInput;
  update: CustomFieldUpdateWithoutLeadInput;
  where: CustomFieldWhereUniqueInput;
};

export type CustomFieldUpsertWithWhereUniqueWithoutOrganizationInput = {
  create: CustomFieldCreateWithoutOrganizationInput;
  update: CustomFieldUpdateWithoutOrganizationInput;
  where: CustomFieldWhereUniqueInput;
};

export type CustomFieldWhereInput = {
  allow_reps_to_edit?: InputMaybe<BoolFilter>;
  AND?: InputMaybe<Array<CustomFieldWhereInput>>;
  boo_value?: InputMaybe<BoolNullableFilter>;
  created_at?: InputMaybe<DateTimeFilter>;
  date_value?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  key?: InputMaybe<StringFilter>;
  lead?: InputMaybe<LeadWhereInput>;
  lead_id?: InputMaybe<StringNullableFilter>;
  list_value?: InputMaybe<StringNullableListFilter>;
  NOT?: InputMaybe<Array<CustomFieldWhereInput>>;
  num_value?: InputMaybe<FloatNullableFilter>;
  options?: InputMaybe<StringNullableListFilter>;
  OR?: InputMaybe<Array<CustomFieldWhereInput>>;
  ordinal?: InputMaybe<IntFilter>;
  organization?: InputMaybe<OrganizationWhereInput>;
  organization_id?: InputMaybe<StringNullableFilter>;
  required_for_sale?: InputMaybe<BoolNullableFilter>;
  required_required_for_sale?: InputMaybe<BoolNullableFilter>;
  SaleConfigSection?: InputMaybe<SaleConfigSectionListRelationFilter>;
  type?: InputMaybe<EnumCustomFieldTypeFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
  userCustomFieldFilter?: InputMaybe<UserCustomFieldFilterListRelationFilter>;
  value?: InputMaybe<StringNullableFilter>;
  visible?: InputMaybe<BoolFilter>;
};

export type CustomFieldWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']['input']>;
};

export type CustomObject = {
  __typename?: 'CustomObject';
  created_at: Scalars['DateTime']['output'];
  CustomObjectField: Array<CustomObjectField>;
  CustomObjectFieldForeignKey?: Maybe<CustomObjectFieldForeignKey>;
  CustomObjectRecord: Array<CustomObjectRecord>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  mappable_lead_keys?: Maybe<Array<Maybe<Scalars['Json']['output']>>>;
  name: Scalars['String']['output'];
  organization: Organization;
  organization_id: Scalars['String']['output'];
  updated_at: Scalars['DateTime']['output'];
};


export type CustomObjectCustomObjectFieldArgs = {
  after?: InputMaybe<CustomObjectFieldWhereUniqueInput>;
  before?: InputMaybe<CustomObjectFieldWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CustomObjectFieldOrderByWithRelationInput>>;
  where?: InputMaybe<CustomObjectFieldWhereInput>;
};


export type CustomObjectCustomObjectRecordArgs = {
  after?: InputMaybe<CustomObjectRecordWhereUniqueInput>;
  before?: InputMaybe<CustomObjectRecordWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type CustomObjectCreateManyOrganizationInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type CustomObjectCreateManyOrganizationInputEnvelope = {
  data?: InputMaybe<Array<CustomObjectCreateManyOrganizationInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CustomObjectCreateNestedManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<CustomObjectWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CustomObjectCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<CustomObjectCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<CustomObjectCreateManyOrganizationInputEnvelope>;
};

export type CustomObjectCreateNestedOneWithoutCustomObjectFieldForeignKeyInput = {
  connect?: InputMaybe<CustomObjectWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CustomObjectCreateOrConnectWithoutCustomObjectFieldForeignKeyInput>;
  create?: InputMaybe<CustomObjectCreateWithoutCustomObjectFieldForeignKeyInput>;
};

export type CustomObjectCreateNestedOneWithoutCustomObjectFieldInput = {
  connect?: InputMaybe<CustomObjectWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CustomObjectCreateOrConnectWithoutCustomObjectFieldInput>;
  create?: InputMaybe<CustomObjectCreateWithoutCustomObjectFieldInput>;
};

export type CustomObjectCreateNestedOneWithoutCustomObjectRecordInput = {
  connect?: InputMaybe<CustomObjectWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CustomObjectCreateOrConnectWithoutCustomObjectRecordInput>;
  create?: InputMaybe<CustomObjectCreateWithoutCustomObjectRecordInput>;
};

export type CustomObjectCreateNestedOneWithoutCustomObjectRowInput = {
  connect?: InputMaybe<CustomObjectWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CustomObjectCreateOrConnectWithoutCustomObjectRowInput>;
  create?: InputMaybe<CustomObjectCreateWithoutCustomObjectRowInput>;
};

export type CustomObjectCreateNestedOneWithoutLeadCustomObjectRowAssociationInput = {
  connect?: InputMaybe<CustomObjectWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CustomObjectCreateOrConnectWithoutLeadCustomObjectRowAssociationInput>;
  create?: InputMaybe<CustomObjectCreateWithoutLeadCustomObjectRowAssociationInput>;
};

export type CustomObjectCreateOrConnectWithoutCustomObjectFieldForeignKeyInput = {
  create: CustomObjectCreateWithoutCustomObjectFieldForeignKeyInput;
  where: CustomObjectWhereUniqueInput;
};

export type CustomObjectCreateOrConnectWithoutCustomObjectFieldInput = {
  create: CustomObjectCreateWithoutCustomObjectFieldInput;
  where: CustomObjectWhereUniqueInput;
};

export type CustomObjectCreateOrConnectWithoutCustomObjectRecordInput = {
  create: CustomObjectCreateWithoutCustomObjectRecordInput;
  where: CustomObjectWhereUniqueInput;
};

export type CustomObjectCreateOrConnectWithoutCustomObjectRowInput = {
  create: CustomObjectCreateWithoutCustomObjectRowInput;
  where: CustomObjectWhereUniqueInput;
};

export type CustomObjectCreateOrConnectWithoutLeadCustomObjectRowAssociationInput = {
  create: CustomObjectCreateWithoutLeadCustomObjectRowAssociationInput;
  where: CustomObjectWhereUniqueInput;
};

export type CustomObjectCreateOrConnectWithoutOrganizationInput = {
  create: CustomObjectCreateWithoutOrganizationInput;
  where: CustomObjectWhereUniqueInput;
};

export type CustomObjectCreateWithoutCustomObjectFieldForeignKeyInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  CustomObjectField?: InputMaybe<CustomObjectFieldCreateNestedManyWithoutCustom_ObjectInput>;
  CustomObjectRecord?: InputMaybe<CustomObjectRecordCreateNestedManyWithoutObjectInput>;
  CustomObjectRow?: InputMaybe<CustomObjectRowCreateNestedManyWithoutCustom_ObjectInput>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  LeadCustomObjectRowAssociation?: InputMaybe<LeadCustomObjectRowAssociationCreateNestedManyWithoutCustom_ObjectInput>;
  name: Scalars['String']['input'];
  organization: OrganizationCreateNestedOneWithoutCustomObjectInput;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type CustomObjectCreateWithoutCustomObjectFieldInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  CustomObjectFieldForeignKey?: InputMaybe<CustomObjectFieldForeignKeyCreateNestedOneWithoutCustom_ObjectInput>;
  CustomObjectRecord?: InputMaybe<CustomObjectRecordCreateNestedManyWithoutObjectInput>;
  CustomObjectRow?: InputMaybe<CustomObjectRowCreateNestedManyWithoutCustom_ObjectInput>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  LeadCustomObjectRowAssociation?: InputMaybe<LeadCustomObjectRowAssociationCreateNestedManyWithoutCustom_ObjectInput>;
  name: Scalars['String']['input'];
  organization: OrganizationCreateNestedOneWithoutCustomObjectInput;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type CustomObjectCreateWithoutCustomObjectRecordInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  CustomObjectField?: InputMaybe<CustomObjectFieldCreateNestedManyWithoutCustom_ObjectInput>;
  CustomObjectFieldForeignKey?: InputMaybe<CustomObjectFieldForeignKeyCreateNestedOneWithoutCustom_ObjectInput>;
  CustomObjectRow?: InputMaybe<CustomObjectRowCreateNestedManyWithoutCustom_ObjectInput>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  LeadCustomObjectRowAssociation?: InputMaybe<LeadCustomObjectRowAssociationCreateNestedManyWithoutCustom_ObjectInput>;
  name: Scalars['String']['input'];
  organization: OrganizationCreateNestedOneWithoutCustomObjectInput;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type CustomObjectCreateWithoutCustomObjectRowInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  CustomObjectField?: InputMaybe<CustomObjectFieldCreateNestedManyWithoutCustom_ObjectInput>;
  CustomObjectFieldForeignKey?: InputMaybe<CustomObjectFieldForeignKeyCreateNestedOneWithoutCustom_ObjectInput>;
  CustomObjectRecord?: InputMaybe<CustomObjectRecordCreateNestedManyWithoutObjectInput>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  LeadCustomObjectRowAssociation?: InputMaybe<LeadCustomObjectRowAssociationCreateNestedManyWithoutCustom_ObjectInput>;
  name: Scalars['String']['input'];
  organization: OrganizationCreateNestedOneWithoutCustomObjectInput;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type CustomObjectCreateWithoutLeadCustomObjectRowAssociationInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  CustomObjectField?: InputMaybe<CustomObjectFieldCreateNestedManyWithoutCustom_ObjectInput>;
  CustomObjectFieldForeignKey?: InputMaybe<CustomObjectFieldForeignKeyCreateNestedOneWithoutCustom_ObjectInput>;
  CustomObjectRecord?: InputMaybe<CustomObjectRecordCreateNestedManyWithoutObjectInput>;
  CustomObjectRow?: InputMaybe<CustomObjectRowCreateNestedManyWithoutCustom_ObjectInput>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  organization: OrganizationCreateNestedOneWithoutCustomObjectInput;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type CustomObjectCreateWithoutOrganizationInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  CustomObjectField?: InputMaybe<CustomObjectFieldCreateNestedManyWithoutCustom_ObjectInput>;
  CustomObjectFieldForeignKey?: InputMaybe<CustomObjectFieldForeignKeyCreateNestedOneWithoutCustom_ObjectInput>;
  CustomObjectRecord?: InputMaybe<CustomObjectRecordCreateNestedManyWithoutObjectInput>;
  CustomObjectRow?: InputMaybe<CustomObjectRowCreateNestedManyWithoutCustom_ObjectInput>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  LeadCustomObjectRowAssociation?: InputMaybe<LeadCustomObjectRowAssociationCreateNestedManyWithoutCustom_ObjectInput>;
  name: Scalars['String']['input'];
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type CustomObjectDataInput = {
  allow_reps_to_edit: Scalars['Boolean']['input'];
  custom_object_id: Scalars['String']['input'];
  lead_system_field_key: Scalars['String']['input'];
  list_option: Array<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  type: CustomFieldType;
  visible: Scalars['Boolean']['input'];
};

export type CustomObjectField = {
  __typename?: 'CustomObjectField';
  allow_reps_to_edit: Scalars['Boolean']['output'];
  created_at: Scalars['DateTime']['output'];
  custom_object: CustomObject;
  custom_object_id: Scalars['String']['output'];
  CustomObjectFieldForeignKey?: Maybe<CustomObjectFieldForeignKey>;
  CustomObjectRecord: Array<CustomObjectRecord>;
  id: Scalars['String']['output'];
  is_name_field: Scalars['Boolean']['output'];
  is_primary_key: Scalars['Boolean']['output'];
  list_option: Array<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  organization: Organization;
  organization_id: Scalars['String']['output'];
  type: CustomFieldType;
  updated_at: Scalars['DateTime']['output'];
  visible: Scalars['Boolean']['output'];
};


export type CustomObjectFieldCustomObjectRecordArgs = {
  after?: InputMaybe<CustomObjectRecordWhereUniqueInput>;
  before?: InputMaybe<CustomObjectRecordWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type CustomObjectFieldCreatelist_OptionInput = {
  set?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type CustomObjectFieldCreateManyCustom_ObjectInput = {
  allow_reps_to_edit?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  is_name_field?: InputMaybe<Scalars['Boolean']['input']>;
  is_primary_key?: InputMaybe<Scalars['Boolean']['input']>;
  list_option?: InputMaybe<CustomObjectFieldCreateManylist_OptionInput>;
  name: Scalars['String']['input'];
  organization_id: Scalars['String']['input'];
  type?: InputMaybe<CustomFieldType>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  visible?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CustomObjectFieldCreateManyCustom_ObjectInputEnvelope = {
  data?: InputMaybe<Array<CustomObjectFieldCreateManyCustom_ObjectInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CustomObjectFieldCreateManylist_OptionInput = {
  set?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type CustomObjectFieldCreateManyOrganizationInput = {
  allow_reps_to_edit?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  custom_object_id: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  is_name_field?: InputMaybe<Scalars['Boolean']['input']>;
  is_primary_key?: InputMaybe<Scalars['Boolean']['input']>;
  list_option?: InputMaybe<CustomObjectFieldCreateManylist_OptionInput>;
  name: Scalars['String']['input'];
  type?: InputMaybe<CustomFieldType>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  visible?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CustomObjectFieldCreateManyOrganizationInputEnvelope = {
  data?: InputMaybe<Array<CustomObjectFieldCreateManyOrganizationInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CustomObjectFieldCreateNestedManyWithoutCustom_ObjectInput = {
  connect?: InputMaybe<Array<CustomObjectFieldWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CustomObjectFieldCreateOrConnectWithoutCustom_ObjectInput>>;
  create?: InputMaybe<Array<CustomObjectFieldCreateWithoutCustom_ObjectInput>>;
  createMany?: InputMaybe<CustomObjectFieldCreateManyCustom_ObjectInputEnvelope>;
};

export type CustomObjectFieldCreateNestedManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<CustomObjectFieldWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CustomObjectFieldCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<CustomObjectFieldCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<CustomObjectFieldCreateManyOrganizationInputEnvelope>;
};

export type CustomObjectFieldCreateNestedOneWithoutCustomObjectFieldForeignKeyInput = {
  connect?: InputMaybe<CustomObjectFieldWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CustomObjectFieldCreateOrConnectWithoutCustomObjectFieldForeignKeyInput>;
  create?: InputMaybe<CustomObjectFieldCreateWithoutCustomObjectFieldForeignKeyInput>;
};

export type CustomObjectFieldCreateNestedOneWithoutCustomObjectRecordInput = {
  connect?: InputMaybe<CustomObjectFieldWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CustomObjectFieldCreateOrConnectWithoutCustomObjectRecordInput>;
  create?: InputMaybe<CustomObjectFieldCreateWithoutCustomObjectRecordInput>;
};

export type CustomObjectFieldCreateOrConnectWithoutCustom_ObjectInput = {
  create: CustomObjectFieldCreateWithoutCustom_ObjectInput;
  where: CustomObjectFieldWhereUniqueInput;
};

export type CustomObjectFieldCreateOrConnectWithoutCustomObjectFieldForeignKeyInput = {
  create: CustomObjectFieldCreateWithoutCustomObjectFieldForeignKeyInput;
  where: CustomObjectFieldWhereUniqueInput;
};

export type CustomObjectFieldCreateOrConnectWithoutCustomObjectRecordInput = {
  create: CustomObjectFieldCreateWithoutCustomObjectRecordInput;
  where: CustomObjectFieldWhereUniqueInput;
};

export type CustomObjectFieldCreateOrConnectWithoutOrganizationInput = {
  create: CustomObjectFieldCreateWithoutOrganizationInput;
  where: CustomObjectFieldWhereUniqueInput;
};

export type CustomObjectFieldCreateWithoutCustom_ObjectInput = {
  allow_reps_to_edit?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  CustomObjectFieldForeignKey?: InputMaybe<CustomObjectFieldForeignKeyCreateNestedOneWithoutCustom_Object_FieldInput>;
  CustomObjectRecord?: InputMaybe<CustomObjectRecordCreateNestedManyWithoutObject_FieldInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  is_name_field?: InputMaybe<Scalars['Boolean']['input']>;
  is_primary_key?: InputMaybe<Scalars['Boolean']['input']>;
  list_option?: InputMaybe<CustomObjectFieldCreatelist_OptionInput>;
  name: Scalars['String']['input'];
  organization: OrganizationCreateNestedOneWithoutCustomObjectFieldInput;
  type?: InputMaybe<CustomFieldType>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  visible?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CustomObjectFieldCreateWithoutCustomObjectFieldForeignKeyInput = {
  allow_reps_to_edit?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  custom_object: CustomObjectCreateNestedOneWithoutCustomObjectFieldInput;
  CustomObjectRecord?: InputMaybe<CustomObjectRecordCreateNestedManyWithoutObject_FieldInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  is_name_field?: InputMaybe<Scalars['Boolean']['input']>;
  is_primary_key?: InputMaybe<Scalars['Boolean']['input']>;
  list_option?: InputMaybe<CustomObjectFieldCreatelist_OptionInput>;
  name: Scalars['String']['input'];
  organization: OrganizationCreateNestedOneWithoutCustomObjectFieldInput;
  type?: InputMaybe<CustomFieldType>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  visible?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CustomObjectFieldCreateWithoutCustomObjectRecordInput = {
  allow_reps_to_edit?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  custom_object: CustomObjectCreateNestedOneWithoutCustomObjectFieldInput;
  CustomObjectFieldForeignKey?: InputMaybe<CustomObjectFieldForeignKeyCreateNestedOneWithoutCustom_Object_FieldInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  is_name_field?: InputMaybe<Scalars['Boolean']['input']>;
  is_primary_key?: InputMaybe<Scalars['Boolean']['input']>;
  list_option?: InputMaybe<CustomObjectFieldCreatelist_OptionInput>;
  name: Scalars['String']['input'];
  organization: OrganizationCreateNestedOneWithoutCustomObjectFieldInput;
  type?: InputMaybe<CustomFieldType>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  visible?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CustomObjectFieldCreateWithoutOrganizationInput = {
  allow_reps_to_edit?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  custom_object: CustomObjectCreateNestedOneWithoutCustomObjectFieldInput;
  CustomObjectFieldForeignKey?: InputMaybe<CustomObjectFieldForeignKeyCreateNestedOneWithoutCustom_Object_FieldInput>;
  CustomObjectRecord?: InputMaybe<CustomObjectRecordCreateNestedManyWithoutObject_FieldInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  is_name_field?: InputMaybe<Scalars['Boolean']['input']>;
  is_primary_key?: InputMaybe<Scalars['Boolean']['input']>;
  list_option?: InputMaybe<CustomObjectFieldCreatelist_OptionInput>;
  name: Scalars['String']['input'];
  type?: InputMaybe<CustomFieldType>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  visible?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CustomObjectFieldCustom_Object_Field_UniqueCompoundUniqueInput = {
  custom_object_id: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type CustomObjectFieldForeignKey = {
  __typename?: 'CustomObjectFieldForeignKey';
  created_at: Scalars['DateTime']['output'];
  custom_object: CustomObject;
  custom_object_field: CustomObjectField;
  custom_object_field_id: Scalars['String']['output'];
  custom_object_id: Scalars['String']['output'];
  id: Scalars['String']['output'];
  lead_system_field_key: Scalars['String']['output'];
  mappable_lead_keys?: Maybe<Array<Maybe<Scalars['Json']['output']>>>;
  organization: Organization;
  organization_id: Scalars['String']['output'];
  updated_at: Scalars['DateTime']['output'];
};

export type CustomObjectFieldForeignKeyCreateManyOrganizationInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  custom_object_field_id: Scalars['String']['input'];
  custom_object_id: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  lead_system_field_key: Scalars['String']['input'];
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type CustomObjectFieldForeignKeyCreateManyOrganizationInputEnvelope = {
  data?: InputMaybe<Array<CustomObjectFieldForeignKeyCreateManyOrganizationInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CustomObjectFieldForeignKeyCreateNestedManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<CustomObjectFieldForeignKeyWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CustomObjectFieldForeignKeyCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<CustomObjectFieldForeignKeyCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<CustomObjectFieldForeignKeyCreateManyOrganizationInputEnvelope>;
};

export type CustomObjectFieldForeignKeyCreateNestedOneWithoutCustom_Object_FieldInput = {
  connect?: InputMaybe<CustomObjectFieldForeignKeyWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CustomObjectFieldForeignKeyCreateOrConnectWithoutCustom_Object_FieldInput>;
  create?: InputMaybe<CustomObjectFieldForeignKeyCreateWithoutCustom_Object_FieldInput>;
};

export type CustomObjectFieldForeignKeyCreateNestedOneWithoutCustom_ObjectInput = {
  connect?: InputMaybe<CustomObjectFieldForeignKeyWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CustomObjectFieldForeignKeyCreateOrConnectWithoutCustom_ObjectInput>;
  create?: InputMaybe<CustomObjectFieldForeignKeyCreateWithoutCustom_ObjectInput>;
};

export type CustomObjectFieldForeignKeyCreateOrConnectWithoutCustom_Object_FieldInput = {
  create: CustomObjectFieldForeignKeyCreateWithoutCustom_Object_FieldInput;
  where: CustomObjectFieldForeignKeyWhereUniqueInput;
};

export type CustomObjectFieldForeignKeyCreateOrConnectWithoutCustom_ObjectInput = {
  create: CustomObjectFieldForeignKeyCreateWithoutCustom_ObjectInput;
  where: CustomObjectFieldForeignKeyWhereUniqueInput;
};

export type CustomObjectFieldForeignKeyCreateOrConnectWithoutOrganizationInput = {
  create: CustomObjectFieldForeignKeyCreateWithoutOrganizationInput;
  where: CustomObjectFieldForeignKeyWhereUniqueInput;
};

export type CustomObjectFieldForeignKeyCreateWithoutCustom_Object_FieldInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  custom_object: CustomObjectCreateNestedOneWithoutCustomObjectFieldForeignKeyInput;
  id?: InputMaybe<Scalars['String']['input']>;
  lead_system_field_key: Scalars['String']['input'];
  organization: OrganizationCreateNestedOneWithoutCustomObjectFieldForeignKeyInput;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type CustomObjectFieldForeignKeyCreateWithoutCustom_ObjectInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  custom_object_field: CustomObjectFieldCreateNestedOneWithoutCustomObjectFieldForeignKeyInput;
  id?: InputMaybe<Scalars['String']['input']>;
  lead_system_field_key: Scalars['String']['input'];
  organization: OrganizationCreateNestedOneWithoutCustomObjectFieldForeignKeyInput;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type CustomObjectFieldForeignKeyCreateWithoutOrganizationInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  custom_object: CustomObjectCreateNestedOneWithoutCustomObjectFieldForeignKeyInput;
  custom_object_field: CustomObjectFieldCreateNestedOneWithoutCustomObjectFieldForeignKeyInput;
  id?: InputMaybe<Scalars['String']['input']>;
  lead_system_field_key: Scalars['String']['input'];
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type CustomObjectFieldForeignKeyCustom_Object_Field_Foreign_Key_UniqueCompoundUniqueInput = {
  custom_object_field_id: Scalars['String']['input'];
  custom_object_id: Scalars['String']['input'];
};

export type CustomObjectFieldForeignKeyListRelationFilter = {
  every?: InputMaybe<CustomObjectFieldForeignKeyWhereInput>;
  none?: InputMaybe<CustomObjectFieldForeignKeyWhereInput>;
  some?: InputMaybe<CustomObjectFieldForeignKeyWhereInput>;
};

export type CustomObjectFieldForeignKeyOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
  count?: InputMaybe<SortOrder>;
};

export type CustomObjectFieldForeignKeyOrderByWithRelationInput = {
  created_at?: InputMaybe<SortOrder>;
  custom_object?: InputMaybe<CustomObjectOrderByWithRelationInput>;
  custom_object_field?: InputMaybe<CustomObjectFieldOrderByWithRelationInput>;
  custom_object_field_id?: InputMaybe<SortOrder>;
  custom_object_id?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  lead_system_field_key?: InputMaybe<SortOrder>;
  organization?: InputMaybe<OrganizationOrderByWithRelationInput>;
  organization_id?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
};

export type CustomObjectFieldForeignKeyScalarWhereInput = {
  AND?: InputMaybe<Array<CustomObjectFieldForeignKeyScalarWhereInput>>;
  created_at?: InputMaybe<DateTimeFilter>;
  custom_object_field_id?: InputMaybe<StringFilter>;
  custom_object_id?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  lead_system_field_key?: InputMaybe<StringFilter>;
  NOT?: InputMaybe<Array<CustomObjectFieldForeignKeyScalarWhereInput>>;
  OR?: InputMaybe<Array<CustomObjectFieldForeignKeyScalarWhereInput>>;
  organization_id?: InputMaybe<StringFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
};

export type CustomObjectFieldForeignKeyUpdateManyMutationInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lead_system_field_key?: InputMaybe<StringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type CustomObjectFieldForeignKeyUpdateManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<CustomObjectFieldForeignKeyWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CustomObjectFieldForeignKeyCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<CustomObjectFieldForeignKeyCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<CustomObjectFieldForeignKeyCreateManyOrganizationInputEnvelope>;
  delete?: InputMaybe<Array<CustomObjectFieldForeignKeyWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CustomObjectFieldForeignKeyScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CustomObjectFieldForeignKeyWhereUniqueInput>>;
  set?: InputMaybe<Array<CustomObjectFieldForeignKeyWhereUniqueInput>>;
  update?: InputMaybe<Array<CustomObjectFieldForeignKeyUpdateWithWhereUniqueWithoutOrganizationInput>>;
  updateMany?: InputMaybe<Array<CustomObjectFieldForeignKeyUpdateManyWithWhereWithoutOrganizationInput>>;
  upsert?: InputMaybe<Array<CustomObjectFieldForeignKeyUpsertWithWhereUniqueWithoutOrganizationInput>>;
};

export type CustomObjectFieldForeignKeyUpdateManyWithWhereWithoutOrganizationInput = {
  data: CustomObjectFieldForeignKeyUpdateManyMutationInput;
  where: CustomObjectFieldForeignKeyScalarWhereInput;
};

export type CustomObjectFieldForeignKeyUpdateOneWithoutCustom_Object_FieldInput = {
  connect?: InputMaybe<CustomObjectFieldForeignKeyWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CustomObjectFieldForeignKeyCreateOrConnectWithoutCustom_Object_FieldInput>;
  create?: InputMaybe<CustomObjectFieldForeignKeyCreateWithoutCustom_Object_FieldInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<CustomObjectFieldForeignKeyUpdateWithoutCustom_Object_FieldInput>;
  upsert?: InputMaybe<CustomObjectFieldForeignKeyUpsertWithoutCustom_Object_FieldInput>;
};

export type CustomObjectFieldForeignKeyUpdateOneWithoutCustom_ObjectInput = {
  connect?: InputMaybe<CustomObjectFieldForeignKeyWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CustomObjectFieldForeignKeyCreateOrConnectWithoutCustom_ObjectInput>;
  create?: InputMaybe<CustomObjectFieldForeignKeyCreateWithoutCustom_ObjectInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<CustomObjectFieldForeignKeyUpdateWithoutCustom_ObjectInput>;
  upsert?: InputMaybe<CustomObjectFieldForeignKeyUpsertWithoutCustom_ObjectInput>;
};

export type CustomObjectFieldForeignKeyUpdateWithoutCustom_Object_FieldInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  custom_object?: InputMaybe<CustomObjectUpdateOneRequiredWithoutCustomObjectFieldForeignKeyInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lead_system_field_key?: InputMaybe<StringFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutCustomObjectFieldForeignKeyInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type CustomObjectFieldForeignKeyUpdateWithoutCustom_ObjectInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  custom_object_field?: InputMaybe<CustomObjectFieldUpdateOneRequiredWithoutCustomObjectFieldForeignKeyInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lead_system_field_key?: InputMaybe<StringFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutCustomObjectFieldForeignKeyInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type CustomObjectFieldForeignKeyUpdateWithoutOrganizationInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  custom_object?: InputMaybe<CustomObjectUpdateOneRequiredWithoutCustomObjectFieldForeignKeyInput>;
  custom_object_field?: InputMaybe<CustomObjectFieldUpdateOneRequiredWithoutCustomObjectFieldForeignKeyInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lead_system_field_key?: InputMaybe<StringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type CustomObjectFieldForeignKeyUpdateWithWhereUniqueWithoutOrganizationInput = {
  data: CustomObjectFieldForeignKeyUpdateWithoutOrganizationInput;
  where: CustomObjectFieldForeignKeyWhereUniqueInput;
};

export type CustomObjectFieldForeignKeyUpsertWithoutCustom_Object_FieldInput = {
  create: CustomObjectFieldForeignKeyCreateWithoutCustom_Object_FieldInput;
  update: CustomObjectFieldForeignKeyUpdateWithoutCustom_Object_FieldInput;
};

export type CustomObjectFieldForeignKeyUpsertWithoutCustom_ObjectInput = {
  create: CustomObjectFieldForeignKeyCreateWithoutCustom_ObjectInput;
  update: CustomObjectFieldForeignKeyUpdateWithoutCustom_ObjectInput;
};

export type CustomObjectFieldForeignKeyUpsertWithWhereUniqueWithoutOrganizationInput = {
  create: CustomObjectFieldForeignKeyCreateWithoutOrganizationInput;
  update: CustomObjectFieldForeignKeyUpdateWithoutOrganizationInput;
  where: CustomObjectFieldForeignKeyWhereUniqueInput;
};

export type CustomObjectFieldForeignKeyWhereInput = {
  AND?: InputMaybe<Array<CustomObjectFieldForeignKeyWhereInput>>;
  created_at?: InputMaybe<DateTimeFilter>;
  custom_object?: InputMaybe<CustomObjectWhereInput>;
  custom_object_field?: InputMaybe<CustomObjectFieldWhereInput>;
  custom_object_field_id?: InputMaybe<StringFilter>;
  custom_object_id?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  lead_system_field_key?: InputMaybe<StringFilter>;
  NOT?: InputMaybe<Array<CustomObjectFieldForeignKeyWhereInput>>;
  OR?: InputMaybe<Array<CustomObjectFieldForeignKeyWhereInput>>;
  organization?: InputMaybe<OrganizationWhereInput>;
  organization_id?: InputMaybe<StringFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
};

export type CustomObjectFieldForeignKeyWhereUniqueInput = {
  custom_object_field_foreign_key_unique?: InputMaybe<CustomObjectFieldForeignKeyCustom_Object_Field_Foreign_Key_UniqueCompoundUniqueInput>;
  custom_object_field_id?: InputMaybe<Scalars['String']['input']>;
  custom_object_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
};

export type CustomObjectFieldListRelationFilter = {
  every?: InputMaybe<CustomObjectFieldWhereInput>;
  none?: InputMaybe<CustomObjectFieldWhereInput>;
  some?: InputMaybe<CustomObjectFieldWhereInput>;
};

export type CustomObjectFieldOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
  count?: InputMaybe<SortOrder>;
};

export type CustomObjectFieldOrderByWithRelationInput = {
  allow_reps_to_edit?: InputMaybe<SortOrder>;
  created_at?: InputMaybe<SortOrder>;
  custom_object?: InputMaybe<CustomObjectOrderByWithRelationInput>;
  custom_object_id?: InputMaybe<SortOrder>;
  CustomObjectFieldForeignKey?: InputMaybe<CustomObjectFieldForeignKeyOrderByWithRelationInput>;
  CustomObjectRecord?: InputMaybe<CustomObjectRecordOrderByRelationAggregateInput>;
  id?: InputMaybe<SortOrder>;
  is_name_field?: InputMaybe<SortOrder>;
  is_primary_key?: InputMaybe<SortOrder>;
  list_option?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  organization?: InputMaybe<OrganizationOrderByWithRelationInput>;
  organization_id?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
  visible?: InputMaybe<SortOrder>;
};

export type CustomObjectFieldScalarWhereInput = {
  allow_reps_to_edit?: InputMaybe<BoolFilter>;
  AND?: InputMaybe<Array<CustomObjectFieldScalarWhereInput>>;
  created_at?: InputMaybe<DateTimeFilter>;
  custom_object_id?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  is_name_field?: InputMaybe<BoolFilter>;
  is_primary_key?: InputMaybe<BoolFilter>;
  list_option?: InputMaybe<StringNullableListFilter>;
  name?: InputMaybe<StringFilter>;
  NOT?: InputMaybe<Array<CustomObjectFieldScalarWhereInput>>;
  OR?: InputMaybe<Array<CustomObjectFieldScalarWhereInput>>;
  organization_id?: InputMaybe<StringFilter>;
  type?: InputMaybe<EnumCustomFieldTypeFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
  visible?: InputMaybe<BoolFilter>;
};

export type CustomObjectFieldUpdatelist_OptionInput = {
  push?: InputMaybe<Scalars['String']['input']>;
  set?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type CustomObjectFieldUpdateManyMutationInput = {
  allow_reps_to_edit?: InputMaybe<BoolFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  is_name_field?: InputMaybe<BoolFieldUpdateOperationsInput>;
  is_primary_key?: InputMaybe<BoolFieldUpdateOperationsInput>;
  list_option?: InputMaybe<CustomObjectFieldUpdatelist_OptionInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumCustomFieldTypeFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  visible?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type CustomObjectFieldUpdateManyWithoutCustom_ObjectInput = {
  connect?: InputMaybe<Array<CustomObjectFieldWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CustomObjectFieldCreateOrConnectWithoutCustom_ObjectInput>>;
  create?: InputMaybe<Array<CustomObjectFieldCreateWithoutCustom_ObjectInput>>;
  createMany?: InputMaybe<CustomObjectFieldCreateManyCustom_ObjectInputEnvelope>;
  delete?: InputMaybe<Array<CustomObjectFieldWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CustomObjectFieldScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CustomObjectFieldWhereUniqueInput>>;
  set?: InputMaybe<Array<CustomObjectFieldWhereUniqueInput>>;
  update?: InputMaybe<Array<CustomObjectFieldUpdateWithWhereUniqueWithoutCustom_ObjectInput>>;
  updateMany?: InputMaybe<Array<CustomObjectFieldUpdateManyWithWhereWithoutCustom_ObjectInput>>;
  upsert?: InputMaybe<Array<CustomObjectFieldUpsertWithWhereUniqueWithoutCustom_ObjectInput>>;
};

export type CustomObjectFieldUpdateManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<CustomObjectFieldWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CustomObjectFieldCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<CustomObjectFieldCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<CustomObjectFieldCreateManyOrganizationInputEnvelope>;
  delete?: InputMaybe<Array<CustomObjectFieldWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CustomObjectFieldScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CustomObjectFieldWhereUniqueInput>>;
  set?: InputMaybe<Array<CustomObjectFieldWhereUniqueInput>>;
  update?: InputMaybe<Array<CustomObjectFieldUpdateWithWhereUniqueWithoutOrganizationInput>>;
  updateMany?: InputMaybe<Array<CustomObjectFieldUpdateManyWithWhereWithoutOrganizationInput>>;
  upsert?: InputMaybe<Array<CustomObjectFieldUpsertWithWhereUniqueWithoutOrganizationInput>>;
};

export type CustomObjectFieldUpdateManyWithWhereWithoutCustom_ObjectInput = {
  data: CustomObjectFieldUpdateManyMutationInput;
  where: CustomObjectFieldScalarWhereInput;
};

export type CustomObjectFieldUpdateManyWithWhereWithoutOrganizationInput = {
  data: CustomObjectFieldUpdateManyMutationInput;
  where: CustomObjectFieldScalarWhereInput;
};

export type CustomObjectFieldUpdateOneRequiredWithoutCustomObjectFieldForeignKeyInput = {
  connect?: InputMaybe<CustomObjectFieldWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CustomObjectFieldCreateOrConnectWithoutCustomObjectFieldForeignKeyInput>;
  create?: InputMaybe<CustomObjectFieldCreateWithoutCustomObjectFieldForeignKeyInput>;
  update?: InputMaybe<CustomObjectFieldUpdateWithoutCustomObjectFieldForeignKeyInput>;
  upsert?: InputMaybe<CustomObjectFieldUpsertWithoutCustomObjectFieldForeignKeyInput>;
};

export type CustomObjectFieldUpdateOneRequiredWithoutCustomObjectRecordInput = {
  connect?: InputMaybe<CustomObjectFieldWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CustomObjectFieldCreateOrConnectWithoutCustomObjectRecordInput>;
  create?: InputMaybe<CustomObjectFieldCreateWithoutCustomObjectRecordInput>;
  update?: InputMaybe<CustomObjectFieldUpdateWithoutCustomObjectRecordInput>;
  upsert?: InputMaybe<CustomObjectFieldUpsertWithoutCustomObjectRecordInput>;
};

export type CustomObjectFieldUpdateWithoutCustom_ObjectInput = {
  allow_reps_to_edit?: InputMaybe<BoolFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  CustomObjectFieldForeignKey?: InputMaybe<CustomObjectFieldForeignKeyUpdateOneWithoutCustom_Object_FieldInput>;
  CustomObjectRecord?: InputMaybe<CustomObjectRecordUpdateManyWithoutObject_FieldInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  is_name_field?: InputMaybe<BoolFieldUpdateOperationsInput>;
  is_primary_key?: InputMaybe<BoolFieldUpdateOperationsInput>;
  list_option?: InputMaybe<CustomObjectFieldUpdatelist_OptionInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutCustomObjectFieldInput>;
  type?: InputMaybe<EnumCustomFieldTypeFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  visible?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type CustomObjectFieldUpdateWithoutCustomObjectFieldForeignKeyInput = {
  allow_reps_to_edit?: InputMaybe<BoolFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  custom_object?: InputMaybe<CustomObjectUpdateOneRequiredWithoutCustomObjectFieldInput>;
  CustomObjectRecord?: InputMaybe<CustomObjectRecordUpdateManyWithoutObject_FieldInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  is_name_field?: InputMaybe<BoolFieldUpdateOperationsInput>;
  is_primary_key?: InputMaybe<BoolFieldUpdateOperationsInput>;
  list_option?: InputMaybe<CustomObjectFieldUpdatelist_OptionInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutCustomObjectFieldInput>;
  type?: InputMaybe<EnumCustomFieldTypeFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  visible?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type CustomObjectFieldUpdateWithoutCustomObjectRecordInput = {
  allow_reps_to_edit?: InputMaybe<BoolFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  custom_object?: InputMaybe<CustomObjectUpdateOneRequiredWithoutCustomObjectFieldInput>;
  CustomObjectFieldForeignKey?: InputMaybe<CustomObjectFieldForeignKeyUpdateOneWithoutCustom_Object_FieldInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  is_name_field?: InputMaybe<BoolFieldUpdateOperationsInput>;
  is_primary_key?: InputMaybe<BoolFieldUpdateOperationsInput>;
  list_option?: InputMaybe<CustomObjectFieldUpdatelist_OptionInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutCustomObjectFieldInput>;
  type?: InputMaybe<EnumCustomFieldTypeFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  visible?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type CustomObjectFieldUpdateWithoutOrganizationInput = {
  allow_reps_to_edit?: InputMaybe<BoolFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  custom_object?: InputMaybe<CustomObjectUpdateOneRequiredWithoutCustomObjectFieldInput>;
  CustomObjectFieldForeignKey?: InputMaybe<CustomObjectFieldForeignKeyUpdateOneWithoutCustom_Object_FieldInput>;
  CustomObjectRecord?: InputMaybe<CustomObjectRecordUpdateManyWithoutObject_FieldInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  is_name_field?: InputMaybe<BoolFieldUpdateOperationsInput>;
  is_primary_key?: InputMaybe<BoolFieldUpdateOperationsInput>;
  list_option?: InputMaybe<CustomObjectFieldUpdatelist_OptionInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumCustomFieldTypeFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  visible?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type CustomObjectFieldUpdateWithWhereUniqueWithoutCustom_ObjectInput = {
  data: CustomObjectFieldUpdateWithoutCustom_ObjectInput;
  where: CustomObjectFieldWhereUniqueInput;
};

export type CustomObjectFieldUpdateWithWhereUniqueWithoutOrganizationInput = {
  data: CustomObjectFieldUpdateWithoutOrganizationInput;
  where: CustomObjectFieldWhereUniqueInput;
};

export type CustomObjectFieldUpsertWithoutCustomObjectFieldForeignKeyInput = {
  create: CustomObjectFieldCreateWithoutCustomObjectFieldForeignKeyInput;
  update: CustomObjectFieldUpdateWithoutCustomObjectFieldForeignKeyInput;
};

export type CustomObjectFieldUpsertWithoutCustomObjectRecordInput = {
  create: CustomObjectFieldCreateWithoutCustomObjectRecordInput;
  update: CustomObjectFieldUpdateWithoutCustomObjectRecordInput;
};

export type CustomObjectFieldUpsertWithWhereUniqueWithoutCustom_ObjectInput = {
  create: CustomObjectFieldCreateWithoutCustom_ObjectInput;
  update: CustomObjectFieldUpdateWithoutCustom_ObjectInput;
  where: CustomObjectFieldWhereUniqueInput;
};

export type CustomObjectFieldUpsertWithWhereUniqueWithoutOrganizationInput = {
  create: CustomObjectFieldCreateWithoutOrganizationInput;
  update: CustomObjectFieldUpdateWithoutOrganizationInput;
  where: CustomObjectFieldWhereUniqueInput;
};

export type CustomObjectFieldWhereInput = {
  allow_reps_to_edit?: InputMaybe<BoolFilter>;
  AND?: InputMaybe<Array<CustomObjectFieldWhereInput>>;
  created_at?: InputMaybe<DateTimeFilter>;
  custom_object?: InputMaybe<CustomObjectWhereInput>;
  custom_object_id?: InputMaybe<StringFilter>;
  CustomObjectFieldForeignKey?: InputMaybe<CustomObjectFieldForeignKeyWhereInput>;
  CustomObjectRecord?: InputMaybe<CustomObjectRecordListRelationFilter>;
  id?: InputMaybe<StringFilter>;
  is_name_field?: InputMaybe<BoolFilter>;
  is_primary_key?: InputMaybe<BoolFilter>;
  list_option?: InputMaybe<StringNullableListFilter>;
  name?: InputMaybe<StringFilter>;
  NOT?: InputMaybe<Array<CustomObjectFieldWhereInput>>;
  OR?: InputMaybe<Array<CustomObjectFieldWhereInput>>;
  organization?: InputMaybe<OrganizationWhereInput>;
  organization_id?: InputMaybe<StringFilter>;
  type?: InputMaybe<EnumCustomFieldTypeFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
  visible?: InputMaybe<BoolFilter>;
};

export type CustomObjectFieldWhereUniqueInput = {
  custom_object_field_unique?: InputMaybe<CustomObjectFieldCustom_Object_Field_UniqueCompoundUniqueInput>;
  id?: InputMaybe<Scalars['String']['input']>;
};

export type CustomObjectListRelationFilter = {
  every?: InputMaybe<CustomObjectWhereInput>;
  none?: InputMaybe<CustomObjectWhereInput>;
  some?: InputMaybe<CustomObjectWhereInput>;
};

export type CustomObjectNameOrganization_IdCompoundUniqueInput = {
  name: Scalars['String']['input'];
  organization_id: Scalars['String']['input'];
};

export type CustomObjectOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
  count?: InputMaybe<SortOrder>;
};

export type CustomObjectOrderByWithRelationInput = {
  created_at?: InputMaybe<SortOrder>;
  CustomObjectField?: InputMaybe<CustomObjectFieldOrderByRelationAggregateInput>;
  CustomObjectFieldForeignKey?: InputMaybe<CustomObjectFieldForeignKeyOrderByWithRelationInput>;
  CustomObjectRecord?: InputMaybe<CustomObjectRecordOrderByRelationAggregateInput>;
  CustomObjectRow?: InputMaybe<CustomObjectRowOrderByRelationAggregateInput>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  LeadCustomObjectRowAssociation?: InputMaybe<LeadCustomObjectRowAssociationOrderByRelationAggregateInput>;
  name?: InputMaybe<SortOrder>;
  organization?: InputMaybe<OrganizationOrderByWithRelationInput>;
  organization_id?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
};

export type CustomObjectRecord = {
  __typename?: 'CustomObjectRecord';
  boo_value?: Maybe<Scalars['Boolean']['output']>;
  created_at: Scalars['DateTime']['output'];
  date_value?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['String']['output'];
  list_value: Array<Scalars['String']['output']>;
  num_value?: Maybe<Scalars['Float']['output']>;
  object: CustomObject;
  object_field: CustomObjectField;
  object_field_id: Scalars['String']['output'];
  object_id: Scalars['String']['output'];
  object_row: CustomObjectRow;
  object_row_id: Scalars['String']['output'];
  organization: Organization;
  organization_id: Scalars['String']['output'];
  string_value?: Maybe<Scalars['String']['output']>;
  updated_at: Scalars['DateTime']['output'];
};

export type CustomObjectRecordCreatelist_ValueInput = {
  set?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type CustomObjectRecordCreateManylist_ValueInput = {
  set?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type CustomObjectRecordCreateManyObject_FieldInput = {
  boo_value?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  date_value?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  list_value?: InputMaybe<CustomObjectRecordCreateManylist_ValueInput>;
  num_value?: InputMaybe<Scalars['Float']['input']>;
  object_id: Scalars['String']['input'];
  object_row_id: Scalars['String']['input'];
  organization_id: Scalars['String']['input'];
  string_value?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type CustomObjectRecordCreateManyObject_FieldInputEnvelope = {
  data?: InputMaybe<Array<CustomObjectRecordCreateManyObject_FieldInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CustomObjectRecordCreateManyObject_RowInput = {
  boo_value?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  date_value?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  list_value?: InputMaybe<CustomObjectRecordCreateManylist_ValueInput>;
  num_value?: InputMaybe<Scalars['Float']['input']>;
  object_field_id: Scalars['String']['input'];
  object_id: Scalars['String']['input'];
  organization_id: Scalars['String']['input'];
  string_value?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type CustomObjectRecordCreateManyObject_RowInputEnvelope = {
  data?: InputMaybe<Array<CustomObjectRecordCreateManyObject_RowInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CustomObjectRecordCreateManyObjectInput = {
  boo_value?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  date_value?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  list_value?: InputMaybe<CustomObjectRecordCreateManylist_ValueInput>;
  num_value?: InputMaybe<Scalars['Float']['input']>;
  object_field_id: Scalars['String']['input'];
  object_row_id: Scalars['String']['input'];
  organization_id: Scalars['String']['input'];
  string_value?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type CustomObjectRecordCreateManyObjectInputEnvelope = {
  data?: InputMaybe<Array<CustomObjectRecordCreateManyObjectInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CustomObjectRecordCreateManyOrganizationInput = {
  boo_value?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  date_value?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  list_value?: InputMaybe<CustomObjectRecordCreateManylist_ValueInput>;
  num_value?: InputMaybe<Scalars['Float']['input']>;
  object_field_id: Scalars['String']['input'];
  object_id: Scalars['String']['input'];
  object_row_id: Scalars['String']['input'];
  string_value?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type CustomObjectRecordCreateManyOrganizationInputEnvelope = {
  data?: InputMaybe<Array<CustomObjectRecordCreateManyOrganizationInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CustomObjectRecordCreateNestedManyWithoutObject_FieldInput = {
  connect?: InputMaybe<Array<CustomObjectRecordWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CustomObjectRecordCreateOrConnectWithoutObject_FieldInput>>;
  create?: InputMaybe<Array<CustomObjectRecordCreateWithoutObject_FieldInput>>;
  createMany?: InputMaybe<CustomObjectRecordCreateManyObject_FieldInputEnvelope>;
};

export type CustomObjectRecordCreateNestedManyWithoutObject_RowInput = {
  connect?: InputMaybe<Array<CustomObjectRecordWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CustomObjectRecordCreateOrConnectWithoutObject_RowInput>>;
  create?: InputMaybe<Array<CustomObjectRecordCreateWithoutObject_RowInput>>;
  createMany?: InputMaybe<CustomObjectRecordCreateManyObject_RowInputEnvelope>;
};

export type CustomObjectRecordCreateNestedManyWithoutObjectInput = {
  connect?: InputMaybe<Array<CustomObjectRecordWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CustomObjectRecordCreateOrConnectWithoutObjectInput>>;
  create?: InputMaybe<Array<CustomObjectRecordCreateWithoutObjectInput>>;
  createMany?: InputMaybe<CustomObjectRecordCreateManyObjectInputEnvelope>;
};

export type CustomObjectRecordCreateNestedManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<CustomObjectRecordWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CustomObjectRecordCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<CustomObjectRecordCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<CustomObjectRecordCreateManyOrganizationInputEnvelope>;
};

export type CustomObjectRecordCreateOrConnectWithoutObject_FieldInput = {
  create: CustomObjectRecordCreateWithoutObject_FieldInput;
  where: CustomObjectRecordWhereUniqueInput;
};

export type CustomObjectRecordCreateOrConnectWithoutObject_RowInput = {
  create: CustomObjectRecordCreateWithoutObject_RowInput;
  where: CustomObjectRecordWhereUniqueInput;
};

export type CustomObjectRecordCreateOrConnectWithoutObjectInput = {
  create: CustomObjectRecordCreateWithoutObjectInput;
  where: CustomObjectRecordWhereUniqueInput;
};

export type CustomObjectRecordCreateOrConnectWithoutOrganizationInput = {
  create: CustomObjectRecordCreateWithoutOrganizationInput;
  where: CustomObjectRecordWhereUniqueInput;
};

export type CustomObjectRecordCreateWithoutObject_FieldInput = {
  boo_value?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  date_value?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  list_value?: InputMaybe<CustomObjectRecordCreatelist_ValueInput>;
  num_value?: InputMaybe<Scalars['Float']['input']>;
  object: CustomObjectCreateNestedOneWithoutCustomObjectRecordInput;
  object_row: CustomObjectRowCreateNestedOneWithoutRecordInput;
  organization: OrganizationCreateNestedOneWithoutCustomObjectRecordInput;
  string_value?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type CustomObjectRecordCreateWithoutObject_RowInput = {
  boo_value?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  date_value?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  list_value?: InputMaybe<CustomObjectRecordCreatelist_ValueInput>;
  num_value?: InputMaybe<Scalars['Float']['input']>;
  object: CustomObjectCreateNestedOneWithoutCustomObjectRecordInput;
  object_field: CustomObjectFieldCreateNestedOneWithoutCustomObjectRecordInput;
  organization: OrganizationCreateNestedOneWithoutCustomObjectRecordInput;
  string_value?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type CustomObjectRecordCreateWithoutObjectInput = {
  boo_value?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  date_value?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  list_value?: InputMaybe<CustomObjectRecordCreatelist_ValueInput>;
  num_value?: InputMaybe<Scalars['Float']['input']>;
  object_field: CustomObjectFieldCreateNestedOneWithoutCustomObjectRecordInput;
  object_row: CustomObjectRowCreateNestedOneWithoutRecordInput;
  organization: OrganizationCreateNestedOneWithoutCustomObjectRecordInput;
  string_value?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type CustomObjectRecordCreateWithoutOrganizationInput = {
  boo_value?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  date_value?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  list_value?: InputMaybe<CustomObjectRecordCreatelist_ValueInput>;
  num_value?: InputMaybe<Scalars['Float']['input']>;
  object: CustomObjectCreateNestedOneWithoutCustomObjectRecordInput;
  object_field: CustomObjectFieldCreateNestedOneWithoutCustomObjectRecordInput;
  object_row: CustomObjectRowCreateNestedOneWithoutRecordInput;
  string_value?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type CustomObjectRecordListRelationFilter = {
  every?: InputMaybe<CustomObjectRecordWhereInput>;
  none?: InputMaybe<CustomObjectRecordWhereInput>;
  some?: InputMaybe<CustomObjectRecordWhereInput>;
};

export type CustomObjectRecordOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
  count?: InputMaybe<SortOrder>;
};

export type CustomObjectRecordScalarWhereInput = {
  AND?: InputMaybe<Array<CustomObjectRecordScalarWhereInput>>;
  boo_value?: InputMaybe<BoolNullableFilter>;
  created_at?: InputMaybe<DateTimeFilter>;
  date_value?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<StringFilter>;
  list_value?: InputMaybe<StringNullableListFilter>;
  NOT?: InputMaybe<Array<CustomObjectRecordScalarWhereInput>>;
  num_value?: InputMaybe<FloatNullableFilter>;
  object_field_id?: InputMaybe<StringFilter>;
  object_id?: InputMaybe<StringFilter>;
  object_row_id?: InputMaybe<StringFilter>;
  OR?: InputMaybe<Array<CustomObjectRecordScalarWhereInput>>;
  organization_id?: InputMaybe<StringFilter>;
  string_value?: InputMaybe<StringNullableFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
};

export type CustomObjectRecordUpdatelist_ValueInput = {
  push?: InputMaybe<Scalars['String']['input']>;
  set?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type CustomObjectRecordUpdateManyMutationInput = {
  boo_value?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  date_value?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  list_value?: InputMaybe<CustomObjectRecordUpdatelist_ValueInput>;
  num_value?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  string_value?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type CustomObjectRecordUpdateManyWithoutObject_FieldInput = {
  connect?: InputMaybe<Array<CustomObjectRecordWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CustomObjectRecordCreateOrConnectWithoutObject_FieldInput>>;
  create?: InputMaybe<Array<CustomObjectRecordCreateWithoutObject_FieldInput>>;
  createMany?: InputMaybe<CustomObjectRecordCreateManyObject_FieldInputEnvelope>;
  delete?: InputMaybe<Array<CustomObjectRecordWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CustomObjectRecordScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CustomObjectRecordWhereUniqueInput>>;
  set?: InputMaybe<Array<CustomObjectRecordWhereUniqueInput>>;
  update?: InputMaybe<Array<CustomObjectRecordUpdateWithWhereUniqueWithoutObject_FieldInput>>;
  updateMany?: InputMaybe<Array<CustomObjectRecordUpdateManyWithWhereWithoutObject_FieldInput>>;
  upsert?: InputMaybe<Array<CustomObjectRecordUpsertWithWhereUniqueWithoutObject_FieldInput>>;
};

export type CustomObjectRecordUpdateManyWithoutObject_RowInput = {
  connect?: InputMaybe<Array<CustomObjectRecordWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CustomObjectRecordCreateOrConnectWithoutObject_RowInput>>;
  create?: InputMaybe<Array<CustomObjectRecordCreateWithoutObject_RowInput>>;
  createMany?: InputMaybe<CustomObjectRecordCreateManyObject_RowInputEnvelope>;
  delete?: InputMaybe<Array<CustomObjectRecordWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CustomObjectRecordScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CustomObjectRecordWhereUniqueInput>>;
  set?: InputMaybe<Array<CustomObjectRecordWhereUniqueInput>>;
  update?: InputMaybe<Array<CustomObjectRecordUpdateWithWhereUniqueWithoutObject_RowInput>>;
  updateMany?: InputMaybe<Array<CustomObjectRecordUpdateManyWithWhereWithoutObject_RowInput>>;
  upsert?: InputMaybe<Array<CustomObjectRecordUpsertWithWhereUniqueWithoutObject_RowInput>>;
};

export type CustomObjectRecordUpdateManyWithoutObjectInput = {
  connect?: InputMaybe<Array<CustomObjectRecordWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CustomObjectRecordCreateOrConnectWithoutObjectInput>>;
  create?: InputMaybe<Array<CustomObjectRecordCreateWithoutObjectInput>>;
  createMany?: InputMaybe<CustomObjectRecordCreateManyObjectInputEnvelope>;
  delete?: InputMaybe<Array<CustomObjectRecordWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CustomObjectRecordScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CustomObjectRecordWhereUniqueInput>>;
  set?: InputMaybe<Array<CustomObjectRecordWhereUniqueInput>>;
  update?: InputMaybe<Array<CustomObjectRecordUpdateWithWhereUniqueWithoutObjectInput>>;
  updateMany?: InputMaybe<Array<CustomObjectRecordUpdateManyWithWhereWithoutObjectInput>>;
  upsert?: InputMaybe<Array<CustomObjectRecordUpsertWithWhereUniqueWithoutObjectInput>>;
};

export type CustomObjectRecordUpdateManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<CustomObjectRecordWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CustomObjectRecordCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<CustomObjectRecordCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<CustomObjectRecordCreateManyOrganizationInputEnvelope>;
  delete?: InputMaybe<Array<CustomObjectRecordWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CustomObjectRecordScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CustomObjectRecordWhereUniqueInput>>;
  set?: InputMaybe<Array<CustomObjectRecordWhereUniqueInput>>;
  update?: InputMaybe<Array<CustomObjectRecordUpdateWithWhereUniqueWithoutOrganizationInput>>;
  updateMany?: InputMaybe<Array<CustomObjectRecordUpdateManyWithWhereWithoutOrganizationInput>>;
  upsert?: InputMaybe<Array<CustomObjectRecordUpsertWithWhereUniqueWithoutOrganizationInput>>;
};

export type CustomObjectRecordUpdateManyWithWhereWithoutObject_FieldInput = {
  data: CustomObjectRecordUpdateManyMutationInput;
  where: CustomObjectRecordScalarWhereInput;
};

export type CustomObjectRecordUpdateManyWithWhereWithoutObject_RowInput = {
  data: CustomObjectRecordUpdateManyMutationInput;
  where: CustomObjectRecordScalarWhereInput;
};

export type CustomObjectRecordUpdateManyWithWhereWithoutObjectInput = {
  data: CustomObjectRecordUpdateManyMutationInput;
  where: CustomObjectRecordScalarWhereInput;
};

export type CustomObjectRecordUpdateManyWithWhereWithoutOrganizationInput = {
  data: CustomObjectRecordUpdateManyMutationInput;
  where: CustomObjectRecordScalarWhereInput;
};

export type CustomObjectRecordUpdateWithoutObject_FieldInput = {
  boo_value?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  date_value?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  list_value?: InputMaybe<CustomObjectRecordUpdatelist_ValueInput>;
  num_value?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  object?: InputMaybe<CustomObjectUpdateOneRequiredWithoutCustomObjectRecordInput>;
  object_row?: InputMaybe<CustomObjectRowUpdateOneRequiredWithoutRecordInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutCustomObjectRecordInput>;
  string_value?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type CustomObjectRecordUpdateWithoutObject_RowInput = {
  boo_value?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  date_value?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  list_value?: InputMaybe<CustomObjectRecordUpdatelist_ValueInput>;
  num_value?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  object?: InputMaybe<CustomObjectUpdateOneRequiredWithoutCustomObjectRecordInput>;
  object_field?: InputMaybe<CustomObjectFieldUpdateOneRequiredWithoutCustomObjectRecordInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutCustomObjectRecordInput>;
  string_value?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type CustomObjectRecordUpdateWithoutObjectInput = {
  boo_value?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  date_value?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  list_value?: InputMaybe<CustomObjectRecordUpdatelist_ValueInput>;
  num_value?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  object_field?: InputMaybe<CustomObjectFieldUpdateOneRequiredWithoutCustomObjectRecordInput>;
  object_row?: InputMaybe<CustomObjectRowUpdateOneRequiredWithoutRecordInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutCustomObjectRecordInput>;
  string_value?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type CustomObjectRecordUpdateWithoutOrganizationInput = {
  boo_value?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  date_value?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  list_value?: InputMaybe<CustomObjectRecordUpdatelist_ValueInput>;
  num_value?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  object?: InputMaybe<CustomObjectUpdateOneRequiredWithoutCustomObjectRecordInput>;
  object_field?: InputMaybe<CustomObjectFieldUpdateOneRequiredWithoutCustomObjectRecordInput>;
  object_row?: InputMaybe<CustomObjectRowUpdateOneRequiredWithoutRecordInput>;
  string_value?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type CustomObjectRecordUpdateWithWhereUniqueWithoutObject_FieldInput = {
  data: CustomObjectRecordUpdateWithoutObject_FieldInput;
  where: CustomObjectRecordWhereUniqueInput;
};

export type CustomObjectRecordUpdateWithWhereUniqueWithoutObject_RowInput = {
  data: CustomObjectRecordUpdateWithoutObject_RowInput;
  where: CustomObjectRecordWhereUniqueInput;
};

export type CustomObjectRecordUpdateWithWhereUniqueWithoutObjectInput = {
  data: CustomObjectRecordUpdateWithoutObjectInput;
  where: CustomObjectRecordWhereUniqueInput;
};

export type CustomObjectRecordUpdateWithWhereUniqueWithoutOrganizationInput = {
  data: CustomObjectRecordUpdateWithoutOrganizationInput;
  where: CustomObjectRecordWhereUniqueInput;
};

export type CustomObjectRecordUpsertWithWhereUniqueWithoutObject_FieldInput = {
  create: CustomObjectRecordCreateWithoutObject_FieldInput;
  update: CustomObjectRecordUpdateWithoutObject_FieldInput;
  where: CustomObjectRecordWhereUniqueInput;
};

export type CustomObjectRecordUpsertWithWhereUniqueWithoutObject_RowInput = {
  create: CustomObjectRecordCreateWithoutObject_RowInput;
  update: CustomObjectRecordUpdateWithoutObject_RowInput;
  where: CustomObjectRecordWhereUniqueInput;
};

export type CustomObjectRecordUpsertWithWhereUniqueWithoutObjectInput = {
  create: CustomObjectRecordCreateWithoutObjectInput;
  update: CustomObjectRecordUpdateWithoutObjectInput;
  where: CustomObjectRecordWhereUniqueInput;
};

export type CustomObjectRecordUpsertWithWhereUniqueWithoutOrganizationInput = {
  create: CustomObjectRecordCreateWithoutOrganizationInput;
  update: CustomObjectRecordUpdateWithoutOrganizationInput;
  where: CustomObjectRecordWhereUniqueInput;
};

export type CustomObjectRecordWhereInput = {
  AND?: InputMaybe<Array<CustomObjectRecordWhereInput>>;
  boo_value?: InputMaybe<BoolNullableFilter>;
  created_at?: InputMaybe<DateTimeFilter>;
  date_value?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<StringFilter>;
  list_value?: InputMaybe<StringNullableListFilter>;
  NOT?: InputMaybe<Array<CustomObjectRecordWhereInput>>;
  num_value?: InputMaybe<FloatNullableFilter>;
  object?: InputMaybe<CustomObjectWhereInput>;
  object_field?: InputMaybe<CustomObjectFieldWhereInput>;
  object_field_id?: InputMaybe<StringFilter>;
  object_id?: InputMaybe<StringFilter>;
  object_row?: InputMaybe<CustomObjectRowWhereInput>;
  object_row_id?: InputMaybe<StringFilter>;
  OR?: InputMaybe<Array<CustomObjectRecordWhereInput>>;
  organization?: InputMaybe<OrganizationWhereInput>;
  organization_id?: InputMaybe<StringFilter>;
  string_value?: InputMaybe<StringNullableFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
};

export type CustomObjectRecordWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']['input']>;
};

export type CustomObjectRow = {
  __typename?: 'CustomObjectRow';
  created_at: Scalars['DateTime']['output'];
  custom_object: CustomObject;
  custom_object_id: Scalars['String']['output'];
  id: Scalars['String']['output'];
  organization: Organization;
  organization_id: Scalars['String']['output'];
  record: Array<CustomObjectRecord>;
  updated_at: Scalars['DateTime']['output'];
};


export type CustomObjectRowRecordArgs = {
  after?: InputMaybe<CustomObjectRecordWhereUniqueInput>;
  before?: InputMaybe<CustomObjectRecordWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type CustomObjectRowCreateManyCustom_ObjectInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  organization_id: Scalars['String']['input'];
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type CustomObjectRowCreateManyCustom_ObjectInputEnvelope = {
  data?: InputMaybe<Array<CustomObjectRowCreateManyCustom_ObjectInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CustomObjectRowCreateManyOrganizationInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  custom_object_id: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type CustomObjectRowCreateManyOrganizationInputEnvelope = {
  data?: InputMaybe<Array<CustomObjectRowCreateManyOrganizationInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CustomObjectRowCreateNestedManyWithoutCustom_ObjectInput = {
  connect?: InputMaybe<Array<CustomObjectRowWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CustomObjectRowCreateOrConnectWithoutCustom_ObjectInput>>;
  create?: InputMaybe<Array<CustomObjectRowCreateWithoutCustom_ObjectInput>>;
  createMany?: InputMaybe<CustomObjectRowCreateManyCustom_ObjectInputEnvelope>;
};

export type CustomObjectRowCreateNestedManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<CustomObjectRowWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CustomObjectRowCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<CustomObjectRowCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<CustomObjectRowCreateManyOrganizationInputEnvelope>;
};

export type CustomObjectRowCreateNestedOneWithoutLead_Custom_Row_InfoInput = {
  connect?: InputMaybe<CustomObjectRowWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CustomObjectRowCreateOrConnectWithoutLead_Custom_Row_InfoInput>;
  create?: InputMaybe<CustomObjectRowCreateWithoutLead_Custom_Row_InfoInput>;
};

export type CustomObjectRowCreateNestedOneWithoutRecordInput = {
  connect?: InputMaybe<CustomObjectRowWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CustomObjectRowCreateOrConnectWithoutRecordInput>;
  create?: InputMaybe<CustomObjectRowCreateWithoutRecordInput>;
};

export type CustomObjectRowCreateOrConnectWithoutCustom_ObjectInput = {
  create: CustomObjectRowCreateWithoutCustom_ObjectInput;
  where: CustomObjectRowWhereUniqueInput;
};

export type CustomObjectRowCreateOrConnectWithoutLead_Custom_Row_InfoInput = {
  create: CustomObjectRowCreateWithoutLead_Custom_Row_InfoInput;
  where: CustomObjectRowWhereUniqueInput;
};

export type CustomObjectRowCreateOrConnectWithoutOrganizationInput = {
  create: CustomObjectRowCreateWithoutOrganizationInput;
  where: CustomObjectRowWhereUniqueInput;
};

export type CustomObjectRowCreateOrConnectWithoutRecordInput = {
  create: CustomObjectRowCreateWithoutRecordInput;
  where: CustomObjectRowWhereUniqueInput;
};

export type CustomObjectRowCreateWithoutCustom_ObjectInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  lead_custom_row_info?: InputMaybe<LeadCustomObjectRowAssociationCreateNestedManyWithoutRowInput>;
  organization: OrganizationCreateNestedOneWithoutCustomObjectRowInput;
  record?: InputMaybe<CustomObjectRecordCreateNestedManyWithoutObject_RowInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type CustomObjectRowCreateWithoutLead_Custom_Row_InfoInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  custom_object: CustomObjectCreateNestedOneWithoutCustomObjectRowInput;
  id?: InputMaybe<Scalars['String']['input']>;
  organization: OrganizationCreateNestedOneWithoutCustomObjectRowInput;
  record?: InputMaybe<CustomObjectRecordCreateNestedManyWithoutObject_RowInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type CustomObjectRowCreateWithoutOrganizationInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  custom_object: CustomObjectCreateNestedOneWithoutCustomObjectRowInput;
  id?: InputMaybe<Scalars['String']['input']>;
  lead_custom_row_info?: InputMaybe<LeadCustomObjectRowAssociationCreateNestedManyWithoutRowInput>;
  record?: InputMaybe<CustomObjectRecordCreateNestedManyWithoutObject_RowInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type CustomObjectRowCreateWithoutRecordInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  custom_object: CustomObjectCreateNestedOneWithoutCustomObjectRowInput;
  id?: InputMaybe<Scalars['String']['input']>;
  lead_custom_row_info?: InputMaybe<LeadCustomObjectRowAssociationCreateNestedManyWithoutRowInput>;
  organization: OrganizationCreateNestedOneWithoutCustomObjectRowInput;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type CustomObjectRowListRelationFilter = {
  every?: InputMaybe<CustomObjectRowWhereInput>;
  none?: InputMaybe<CustomObjectRowWhereInput>;
  some?: InputMaybe<CustomObjectRowWhereInput>;
};

export type CustomObjectRowOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
  count?: InputMaybe<SortOrder>;
};

export type CustomObjectRowScalarWhereInput = {
  AND?: InputMaybe<Array<CustomObjectRowScalarWhereInput>>;
  created_at?: InputMaybe<DateTimeFilter>;
  custom_object_id?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  NOT?: InputMaybe<Array<CustomObjectRowScalarWhereInput>>;
  OR?: InputMaybe<Array<CustomObjectRowScalarWhereInput>>;
  organization_id?: InputMaybe<StringFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
};

export type CustomObjectRowUpdateManyMutationInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type CustomObjectRowUpdateManyWithoutCustom_ObjectInput = {
  connect?: InputMaybe<Array<CustomObjectRowWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CustomObjectRowCreateOrConnectWithoutCustom_ObjectInput>>;
  create?: InputMaybe<Array<CustomObjectRowCreateWithoutCustom_ObjectInput>>;
  createMany?: InputMaybe<CustomObjectRowCreateManyCustom_ObjectInputEnvelope>;
  delete?: InputMaybe<Array<CustomObjectRowWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CustomObjectRowScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CustomObjectRowWhereUniqueInput>>;
  set?: InputMaybe<Array<CustomObjectRowWhereUniqueInput>>;
  update?: InputMaybe<Array<CustomObjectRowUpdateWithWhereUniqueWithoutCustom_ObjectInput>>;
  updateMany?: InputMaybe<Array<CustomObjectRowUpdateManyWithWhereWithoutCustom_ObjectInput>>;
  upsert?: InputMaybe<Array<CustomObjectRowUpsertWithWhereUniqueWithoutCustom_ObjectInput>>;
};

export type CustomObjectRowUpdateManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<CustomObjectRowWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CustomObjectRowCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<CustomObjectRowCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<CustomObjectRowCreateManyOrganizationInputEnvelope>;
  delete?: InputMaybe<Array<CustomObjectRowWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CustomObjectRowScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CustomObjectRowWhereUniqueInput>>;
  set?: InputMaybe<Array<CustomObjectRowWhereUniqueInput>>;
  update?: InputMaybe<Array<CustomObjectRowUpdateWithWhereUniqueWithoutOrganizationInput>>;
  updateMany?: InputMaybe<Array<CustomObjectRowUpdateManyWithWhereWithoutOrganizationInput>>;
  upsert?: InputMaybe<Array<CustomObjectRowUpsertWithWhereUniqueWithoutOrganizationInput>>;
};

export type CustomObjectRowUpdateManyWithWhereWithoutCustom_ObjectInput = {
  data: CustomObjectRowUpdateManyMutationInput;
  where: CustomObjectRowScalarWhereInput;
};

export type CustomObjectRowUpdateManyWithWhereWithoutOrganizationInput = {
  data: CustomObjectRowUpdateManyMutationInput;
  where: CustomObjectRowScalarWhereInput;
};

export type CustomObjectRowUpdateOneRequiredWithoutLead_Custom_Row_InfoInput = {
  connect?: InputMaybe<CustomObjectRowWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CustomObjectRowCreateOrConnectWithoutLead_Custom_Row_InfoInput>;
  create?: InputMaybe<CustomObjectRowCreateWithoutLead_Custom_Row_InfoInput>;
  update?: InputMaybe<CustomObjectRowUpdateWithoutLead_Custom_Row_InfoInput>;
  upsert?: InputMaybe<CustomObjectRowUpsertWithoutLead_Custom_Row_InfoInput>;
};

export type CustomObjectRowUpdateOneRequiredWithoutRecordInput = {
  connect?: InputMaybe<CustomObjectRowWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CustomObjectRowCreateOrConnectWithoutRecordInput>;
  create?: InputMaybe<CustomObjectRowCreateWithoutRecordInput>;
  update?: InputMaybe<CustomObjectRowUpdateWithoutRecordInput>;
  upsert?: InputMaybe<CustomObjectRowUpsertWithoutRecordInput>;
};

export type CustomObjectRowUpdateWithoutCustom_ObjectInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lead_custom_row_info?: InputMaybe<LeadCustomObjectRowAssociationUpdateManyWithoutRowInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutCustomObjectRowInput>;
  record?: InputMaybe<CustomObjectRecordUpdateManyWithoutObject_RowInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type CustomObjectRowUpdateWithoutLead_Custom_Row_InfoInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  custom_object?: InputMaybe<CustomObjectUpdateOneRequiredWithoutCustomObjectRowInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutCustomObjectRowInput>;
  record?: InputMaybe<CustomObjectRecordUpdateManyWithoutObject_RowInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type CustomObjectRowUpdateWithoutOrganizationInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  custom_object?: InputMaybe<CustomObjectUpdateOneRequiredWithoutCustomObjectRowInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lead_custom_row_info?: InputMaybe<LeadCustomObjectRowAssociationUpdateManyWithoutRowInput>;
  record?: InputMaybe<CustomObjectRecordUpdateManyWithoutObject_RowInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type CustomObjectRowUpdateWithoutRecordInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  custom_object?: InputMaybe<CustomObjectUpdateOneRequiredWithoutCustomObjectRowInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lead_custom_row_info?: InputMaybe<LeadCustomObjectRowAssociationUpdateManyWithoutRowInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutCustomObjectRowInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type CustomObjectRowUpdateWithWhereUniqueWithoutCustom_ObjectInput = {
  data: CustomObjectRowUpdateWithoutCustom_ObjectInput;
  where: CustomObjectRowWhereUniqueInput;
};

export type CustomObjectRowUpdateWithWhereUniqueWithoutOrganizationInput = {
  data: CustomObjectRowUpdateWithoutOrganizationInput;
  where: CustomObjectRowWhereUniqueInput;
};

export type CustomObjectRowUpsertWithoutLead_Custom_Row_InfoInput = {
  create: CustomObjectRowCreateWithoutLead_Custom_Row_InfoInput;
  update: CustomObjectRowUpdateWithoutLead_Custom_Row_InfoInput;
};

export type CustomObjectRowUpsertWithoutRecordInput = {
  create: CustomObjectRowCreateWithoutRecordInput;
  update: CustomObjectRowUpdateWithoutRecordInput;
};

export type CustomObjectRowUpsertWithWhereUniqueWithoutCustom_ObjectInput = {
  create: CustomObjectRowCreateWithoutCustom_ObjectInput;
  update: CustomObjectRowUpdateWithoutCustom_ObjectInput;
  where: CustomObjectRowWhereUniqueInput;
};

export type CustomObjectRowUpsertWithWhereUniqueWithoutOrganizationInput = {
  create: CustomObjectRowCreateWithoutOrganizationInput;
  update: CustomObjectRowUpdateWithoutOrganizationInput;
  where: CustomObjectRowWhereUniqueInput;
};

export type CustomObjectRowWhereInput = {
  AND?: InputMaybe<Array<CustomObjectRowWhereInput>>;
  created_at?: InputMaybe<DateTimeFilter>;
  custom_object?: InputMaybe<CustomObjectWhereInput>;
  custom_object_id?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  lead_custom_row_info?: InputMaybe<LeadCustomObjectRowAssociationListRelationFilter>;
  NOT?: InputMaybe<Array<CustomObjectRowWhereInput>>;
  OR?: InputMaybe<Array<CustomObjectRowWhereInput>>;
  organization?: InputMaybe<OrganizationWhereInput>;
  organization_id?: InputMaybe<StringFilter>;
  record?: InputMaybe<CustomObjectRecordListRelationFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
};

export type CustomObjectRowWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']['input']>;
};

export type CustomObjectScalarWhereInput = {
  AND?: InputMaybe<Array<CustomObjectScalarWhereInput>>;
  created_at?: InputMaybe<DateTimeFilter>;
  description?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  NOT?: InputMaybe<Array<CustomObjectScalarWhereInput>>;
  OR?: InputMaybe<Array<CustomObjectScalarWhereInput>>;
  organization_id?: InputMaybe<StringFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
};

export type CustomObjectSummary = {
  __typename?: 'CustomObjectSummary';
  association_count?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type CustomObjectUpdateManyMutationInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type CustomObjectUpdateManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<CustomObjectWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CustomObjectCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<CustomObjectCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<CustomObjectCreateManyOrganizationInputEnvelope>;
  delete?: InputMaybe<Array<CustomObjectWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CustomObjectScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CustomObjectWhereUniqueInput>>;
  set?: InputMaybe<Array<CustomObjectWhereUniqueInput>>;
  update?: InputMaybe<Array<CustomObjectUpdateWithWhereUniqueWithoutOrganizationInput>>;
  updateMany?: InputMaybe<Array<CustomObjectUpdateManyWithWhereWithoutOrganizationInput>>;
  upsert?: InputMaybe<Array<CustomObjectUpsertWithWhereUniqueWithoutOrganizationInput>>;
};

export type CustomObjectUpdateManyWithWhereWithoutOrganizationInput = {
  data: CustomObjectUpdateManyMutationInput;
  where: CustomObjectScalarWhereInput;
};

export type CustomObjectUpdateOneRequiredWithoutCustomObjectFieldForeignKeyInput = {
  connect?: InputMaybe<CustomObjectWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CustomObjectCreateOrConnectWithoutCustomObjectFieldForeignKeyInput>;
  create?: InputMaybe<CustomObjectCreateWithoutCustomObjectFieldForeignKeyInput>;
  update?: InputMaybe<CustomObjectUpdateWithoutCustomObjectFieldForeignKeyInput>;
  upsert?: InputMaybe<CustomObjectUpsertWithoutCustomObjectFieldForeignKeyInput>;
};

export type CustomObjectUpdateOneRequiredWithoutCustomObjectFieldInput = {
  connect?: InputMaybe<CustomObjectWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CustomObjectCreateOrConnectWithoutCustomObjectFieldInput>;
  create?: InputMaybe<CustomObjectCreateWithoutCustomObjectFieldInput>;
  update?: InputMaybe<CustomObjectUpdateWithoutCustomObjectFieldInput>;
  upsert?: InputMaybe<CustomObjectUpsertWithoutCustomObjectFieldInput>;
};

export type CustomObjectUpdateOneRequiredWithoutCustomObjectRecordInput = {
  connect?: InputMaybe<CustomObjectWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CustomObjectCreateOrConnectWithoutCustomObjectRecordInput>;
  create?: InputMaybe<CustomObjectCreateWithoutCustomObjectRecordInput>;
  update?: InputMaybe<CustomObjectUpdateWithoutCustomObjectRecordInput>;
  upsert?: InputMaybe<CustomObjectUpsertWithoutCustomObjectRecordInput>;
};

export type CustomObjectUpdateOneRequiredWithoutCustomObjectRowInput = {
  connect?: InputMaybe<CustomObjectWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CustomObjectCreateOrConnectWithoutCustomObjectRowInput>;
  create?: InputMaybe<CustomObjectCreateWithoutCustomObjectRowInput>;
  update?: InputMaybe<CustomObjectUpdateWithoutCustomObjectRowInput>;
  upsert?: InputMaybe<CustomObjectUpsertWithoutCustomObjectRowInput>;
};

export type CustomObjectUpdateOneRequiredWithoutLeadCustomObjectRowAssociationInput = {
  connect?: InputMaybe<CustomObjectWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CustomObjectCreateOrConnectWithoutLeadCustomObjectRowAssociationInput>;
  create?: InputMaybe<CustomObjectCreateWithoutLeadCustomObjectRowAssociationInput>;
  update?: InputMaybe<CustomObjectUpdateWithoutLeadCustomObjectRowAssociationInput>;
  upsert?: InputMaybe<CustomObjectUpsertWithoutLeadCustomObjectRowAssociationInput>;
};

export type CustomObjectUpdateWithoutCustomObjectFieldForeignKeyInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  CustomObjectField?: InputMaybe<CustomObjectFieldUpdateManyWithoutCustom_ObjectInput>;
  CustomObjectRecord?: InputMaybe<CustomObjectRecordUpdateManyWithoutObjectInput>;
  CustomObjectRow?: InputMaybe<CustomObjectRowUpdateManyWithoutCustom_ObjectInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  LeadCustomObjectRowAssociation?: InputMaybe<LeadCustomObjectRowAssociationUpdateManyWithoutCustom_ObjectInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutCustomObjectInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type CustomObjectUpdateWithoutCustomObjectFieldInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  CustomObjectFieldForeignKey?: InputMaybe<CustomObjectFieldForeignKeyUpdateOneWithoutCustom_ObjectInput>;
  CustomObjectRecord?: InputMaybe<CustomObjectRecordUpdateManyWithoutObjectInput>;
  CustomObjectRow?: InputMaybe<CustomObjectRowUpdateManyWithoutCustom_ObjectInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  LeadCustomObjectRowAssociation?: InputMaybe<LeadCustomObjectRowAssociationUpdateManyWithoutCustom_ObjectInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutCustomObjectInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type CustomObjectUpdateWithoutCustomObjectRecordInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  CustomObjectField?: InputMaybe<CustomObjectFieldUpdateManyWithoutCustom_ObjectInput>;
  CustomObjectFieldForeignKey?: InputMaybe<CustomObjectFieldForeignKeyUpdateOneWithoutCustom_ObjectInput>;
  CustomObjectRow?: InputMaybe<CustomObjectRowUpdateManyWithoutCustom_ObjectInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  LeadCustomObjectRowAssociation?: InputMaybe<LeadCustomObjectRowAssociationUpdateManyWithoutCustom_ObjectInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutCustomObjectInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type CustomObjectUpdateWithoutCustomObjectRowInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  CustomObjectField?: InputMaybe<CustomObjectFieldUpdateManyWithoutCustom_ObjectInput>;
  CustomObjectFieldForeignKey?: InputMaybe<CustomObjectFieldForeignKeyUpdateOneWithoutCustom_ObjectInput>;
  CustomObjectRecord?: InputMaybe<CustomObjectRecordUpdateManyWithoutObjectInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  LeadCustomObjectRowAssociation?: InputMaybe<LeadCustomObjectRowAssociationUpdateManyWithoutCustom_ObjectInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutCustomObjectInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type CustomObjectUpdateWithoutLeadCustomObjectRowAssociationInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  CustomObjectField?: InputMaybe<CustomObjectFieldUpdateManyWithoutCustom_ObjectInput>;
  CustomObjectFieldForeignKey?: InputMaybe<CustomObjectFieldForeignKeyUpdateOneWithoutCustom_ObjectInput>;
  CustomObjectRecord?: InputMaybe<CustomObjectRecordUpdateManyWithoutObjectInput>;
  CustomObjectRow?: InputMaybe<CustomObjectRowUpdateManyWithoutCustom_ObjectInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutCustomObjectInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type CustomObjectUpdateWithoutOrganizationInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  CustomObjectField?: InputMaybe<CustomObjectFieldUpdateManyWithoutCustom_ObjectInput>;
  CustomObjectFieldForeignKey?: InputMaybe<CustomObjectFieldForeignKeyUpdateOneWithoutCustom_ObjectInput>;
  CustomObjectRecord?: InputMaybe<CustomObjectRecordUpdateManyWithoutObjectInput>;
  CustomObjectRow?: InputMaybe<CustomObjectRowUpdateManyWithoutCustom_ObjectInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  LeadCustomObjectRowAssociation?: InputMaybe<LeadCustomObjectRowAssociationUpdateManyWithoutCustom_ObjectInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type CustomObjectUpdateWithWhereUniqueWithoutOrganizationInput = {
  data: CustomObjectUpdateWithoutOrganizationInput;
  where: CustomObjectWhereUniqueInput;
};

export type CustomObjectUpsertWithoutCustomObjectFieldForeignKeyInput = {
  create: CustomObjectCreateWithoutCustomObjectFieldForeignKeyInput;
  update: CustomObjectUpdateWithoutCustomObjectFieldForeignKeyInput;
};

export type CustomObjectUpsertWithoutCustomObjectFieldInput = {
  create: CustomObjectCreateWithoutCustomObjectFieldInput;
  update: CustomObjectUpdateWithoutCustomObjectFieldInput;
};

export type CustomObjectUpsertWithoutCustomObjectRecordInput = {
  create: CustomObjectCreateWithoutCustomObjectRecordInput;
  update: CustomObjectUpdateWithoutCustomObjectRecordInput;
};

export type CustomObjectUpsertWithoutCustomObjectRowInput = {
  create: CustomObjectCreateWithoutCustomObjectRowInput;
  update: CustomObjectUpdateWithoutCustomObjectRowInput;
};

export type CustomObjectUpsertWithoutLeadCustomObjectRowAssociationInput = {
  create: CustomObjectCreateWithoutLeadCustomObjectRowAssociationInput;
  update: CustomObjectUpdateWithoutLeadCustomObjectRowAssociationInput;
};

export type CustomObjectUpsertWithWhereUniqueWithoutOrganizationInput = {
  create: CustomObjectCreateWithoutOrganizationInput;
  update: CustomObjectUpdateWithoutOrganizationInput;
  where: CustomObjectWhereUniqueInput;
};

export type CustomObjectWhereInput = {
  AND?: InputMaybe<Array<CustomObjectWhereInput>>;
  created_at?: InputMaybe<DateTimeFilter>;
  CustomObjectField?: InputMaybe<CustomObjectFieldListRelationFilter>;
  CustomObjectFieldForeignKey?: InputMaybe<CustomObjectFieldForeignKeyWhereInput>;
  CustomObjectRecord?: InputMaybe<CustomObjectRecordListRelationFilter>;
  CustomObjectRow?: InputMaybe<CustomObjectRowListRelationFilter>;
  description?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  LeadCustomObjectRowAssociation?: InputMaybe<LeadCustomObjectRowAssociationListRelationFilter>;
  name?: InputMaybe<StringFilter>;
  NOT?: InputMaybe<Array<CustomObjectWhereInput>>;
  OR?: InputMaybe<Array<CustomObjectWhereInput>>;
  organization?: InputMaybe<OrganizationWhereInput>;
  organization_id?: InputMaybe<StringFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
};

export type CustomObjectWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  name_organization_id?: InputMaybe<CustomObjectNameOrganization_IdCompoundUniqueInput>;
};

export type CustomQueueItem = {
  __typename?: 'CustomQueueItem';
  created_at: Scalars['DateTime']['output'];
  custom_ordinal?: Maybe<Scalars['Int']['output']>;
  lead: Lead;
  lead_id: Scalars['String']['output'];
  updated_at: Scalars['DateTime']['output'];
  user: User;
  user_id: Scalars['String']['output'];
};

export type CustomQueueItemCreateManyLeadInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  custom_ordinal?: InputMaybe<Scalars['Int']['input']>;
  organization_id?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user_id: Scalars['String']['input'];
};

export type CustomQueueItemCreateManyLeadInputEnvelope = {
  data?: InputMaybe<Array<CustomQueueItemCreateManyLeadInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CustomQueueItemCreateManyOrganizationInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  custom_ordinal?: InputMaybe<Scalars['Int']['input']>;
  lead_id: Scalars['String']['input'];
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user_id: Scalars['String']['input'];
};

export type CustomQueueItemCreateManyOrganizationInputEnvelope = {
  data?: InputMaybe<Array<CustomQueueItemCreateManyOrganizationInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CustomQueueItemCreateManyUserInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  custom_ordinal?: InputMaybe<Scalars['Int']['input']>;
  lead_id: Scalars['String']['input'];
  organization_id?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type CustomQueueItemCreateManyUserInputEnvelope = {
  data?: InputMaybe<Array<CustomQueueItemCreateManyUserInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CustomQueueItemCreateNestedManyWithoutLeadInput = {
  connect?: InputMaybe<Array<CustomQueueItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CustomQueueItemCreateOrConnectWithoutLeadInput>>;
  create?: InputMaybe<Array<CustomQueueItemCreateWithoutLeadInput>>;
  createMany?: InputMaybe<CustomQueueItemCreateManyLeadInputEnvelope>;
};

export type CustomQueueItemCreateNestedManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<CustomQueueItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CustomQueueItemCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<CustomQueueItemCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<CustomQueueItemCreateManyOrganizationInputEnvelope>;
};

export type CustomQueueItemCreateNestedManyWithoutUserInput = {
  connect?: InputMaybe<Array<CustomQueueItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CustomQueueItemCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<CustomQueueItemCreateWithoutUserInput>>;
  createMany?: InputMaybe<CustomQueueItemCreateManyUserInputEnvelope>;
};

export type CustomQueueItemCreateOrConnectWithoutLeadInput = {
  create: CustomQueueItemCreateWithoutLeadInput;
  where: CustomQueueItemWhereUniqueInput;
};

export type CustomQueueItemCreateOrConnectWithoutOrganizationInput = {
  create: CustomQueueItemCreateWithoutOrganizationInput;
  where: CustomQueueItemWhereUniqueInput;
};

export type CustomQueueItemCreateOrConnectWithoutUserInput = {
  create: CustomQueueItemCreateWithoutUserInput;
  where: CustomQueueItemWhereUniqueInput;
};

export type CustomQueueItemCreateWithoutLeadInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  custom_ordinal?: InputMaybe<Scalars['Int']['input']>;
  Organization?: InputMaybe<OrganizationCreateNestedOneWithoutCustomQueueItemsInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user: UserCreateNestedOneWithoutCustom_Queue_ItemsInput;
};

export type CustomQueueItemCreateWithoutOrganizationInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  custom_ordinal?: InputMaybe<Scalars['Int']['input']>;
  lead: LeadCreateNestedOneWithoutCustom_Queue_ItemsInput;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user: UserCreateNestedOneWithoutCustom_Queue_ItemsInput;
};

export type CustomQueueItemCreateWithoutUserInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  custom_ordinal?: InputMaybe<Scalars['Int']['input']>;
  lead: LeadCreateNestedOneWithoutCustom_Queue_ItemsInput;
  Organization?: InputMaybe<OrganizationCreateNestedOneWithoutCustomQueueItemsInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type CustomQueueItemListRelationFilter = {
  every?: InputMaybe<CustomQueueItemWhereInput>;
  none?: InputMaybe<CustomQueueItemWhereInput>;
  some?: InputMaybe<CustomQueueItemWhereInput>;
};

export type CustomQueueItemOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
  count?: InputMaybe<SortOrder>;
};

export type CustomQueueItemScalarWhereInput = {
  AND?: InputMaybe<Array<CustomQueueItemScalarWhereInput>>;
  created_at?: InputMaybe<DateTimeFilter>;
  custom_ordinal?: InputMaybe<IntNullableFilter>;
  lead_id?: InputMaybe<StringFilter>;
  NOT?: InputMaybe<Array<CustomQueueItemScalarWhereInput>>;
  OR?: InputMaybe<Array<CustomQueueItemScalarWhereInput>>;
  organization_id?: InputMaybe<StringNullableFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
  user_id?: InputMaybe<StringFilter>;
};

export type CustomQueueItemUpdateManyMutationInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  custom_ordinal?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type CustomQueueItemUpdateManyWithoutLeadInput = {
  connect?: InputMaybe<Array<CustomQueueItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CustomQueueItemCreateOrConnectWithoutLeadInput>>;
  create?: InputMaybe<Array<CustomQueueItemCreateWithoutLeadInput>>;
  createMany?: InputMaybe<CustomQueueItemCreateManyLeadInputEnvelope>;
  delete?: InputMaybe<Array<CustomQueueItemWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CustomQueueItemScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CustomQueueItemWhereUniqueInput>>;
  set?: InputMaybe<Array<CustomQueueItemWhereUniqueInput>>;
  update?: InputMaybe<Array<CustomQueueItemUpdateWithWhereUniqueWithoutLeadInput>>;
  updateMany?: InputMaybe<Array<CustomQueueItemUpdateManyWithWhereWithoutLeadInput>>;
  upsert?: InputMaybe<Array<CustomQueueItemUpsertWithWhereUniqueWithoutLeadInput>>;
};

export type CustomQueueItemUpdateManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<CustomQueueItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CustomQueueItemCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<CustomQueueItemCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<CustomQueueItemCreateManyOrganizationInputEnvelope>;
  delete?: InputMaybe<Array<CustomQueueItemWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CustomQueueItemScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CustomQueueItemWhereUniqueInput>>;
  set?: InputMaybe<Array<CustomQueueItemWhereUniqueInput>>;
  update?: InputMaybe<Array<CustomQueueItemUpdateWithWhereUniqueWithoutOrganizationInput>>;
  updateMany?: InputMaybe<Array<CustomQueueItemUpdateManyWithWhereWithoutOrganizationInput>>;
  upsert?: InputMaybe<Array<CustomQueueItemUpsertWithWhereUniqueWithoutOrganizationInput>>;
};

export type CustomQueueItemUpdateManyWithoutUserInput = {
  connect?: InputMaybe<Array<CustomQueueItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CustomQueueItemCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<CustomQueueItemCreateWithoutUserInput>>;
  createMany?: InputMaybe<CustomQueueItemCreateManyUserInputEnvelope>;
  delete?: InputMaybe<Array<CustomQueueItemWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CustomQueueItemScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CustomQueueItemWhereUniqueInput>>;
  set?: InputMaybe<Array<CustomQueueItemWhereUniqueInput>>;
  update?: InputMaybe<Array<CustomQueueItemUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: InputMaybe<Array<CustomQueueItemUpdateManyWithWhereWithoutUserInput>>;
  upsert?: InputMaybe<Array<CustomQueueItemUpsertWithWhereUniqueWithoutUserInput>>;
};

export type CustomQueueItemUpdateManyWithWhereWithoutLeadInput = {
  data: CustomQueueItemUpdateManyMutationInput;
  where: CustomQueueItemScalarWhereInput;
};

export type CustomQueueItemUpdateManyWithWhereWithoutOrganizationInput = {
  data: CustomQueueItemUpdateManyMutationInput;
  where: CustomQueueItemScalarWhereInput;
};

export type CustomQueueItemUpdateManyWithWhereWithoutUserInput = {
  data: CustomQueueItemUpdateManyMutationInput;
  where: CustomQueueItemScalarWhereInput;
};

export type CustomQueueItemUpdateWithoutLeadInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  custom_ordinal?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  Organization?: InputMaybe<OrganizationUpdateOneWithoutCustomQueueItemsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutCustom_Queue_ItemsInput>;
};

export type CustomQueueItemUpdateWithoutOrganizationInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  custom_ordinal?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  lead?: InputMaybe<LeadUpdateOneRequiredWithoutCustom_Queue_ItemsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutCustom_Queue_ItemsInput>;
};

export type CustomQueueItemUpdateWithoutUserInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  custom_ordinal?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  lead?: InputMaybe<LeadUpdateOneRequiredWithoutCustom_Queue_ItemsInput>;
  Organization?: InputMaybe<OrganizationUpdateOneWithoutCustomQueueItemsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type CustomQueueItemUpdateWithWhereUniqueWithoutLeadInput = {
  data: CustomQueueItemUpdateWithoutLeadInput;
  where: CustomQueueItemWhereUniqueInput;
};

export type CustomQueueItemUpdateWithWhereUniqueWithoutOrganizationInput = {
  data: CustomQueueItemUpdateWithoutOrganizationInput;
  where: CustomQueueItemWhereUniqueInput;
};

export type CustomQueueItemUpdateWithWhereUniqueWithoutUserInput = {
  data: CustomQueueItemUpdateWithoutUserInput;
  where: CustomQueueItemWhereUniqueInput;
};

export type CustomQueueItemUpsertWithWhereUniqueWithoutLeadInput = {
  create: CustomQueueItemCreateWithoutLeadInput;
  update: CustomQueueItemUpdateWithoutLeadInput;
  where: CustomQueueItemWhereUniqueInput;
};

export type CustomQueueItemUpsertWithWhereUniqueWithoutOrganizationInput = {
  create: CustomQueueItemCreateWithoutOrganizationInput;
  update: CustomQueueItemUpdateWithoutOrganizationInput;
  where: CustomQueueItemWhereUniqueInput;
};

export type CustomQueueItemUpsertWithWhereUniqueWithoutUserInput = {
  create: CustomQueueItemCreateWithoutUserInput;
  update: CustomQueueItemUpdateWithoutUserInput;
  where: CustomQueueItemWhereUniqueInput;
};

export type CustomQueueItemUser_IdLead_IdCompoundUniqueInput = {
  lead_id: Scalars['String']['input'];
  user_id: Scalars['String']['input'];
};

export type CustomQueueItemWhereInput = {
  AND?: InputMaybe<Array<CustomQueueItemWhereInput>>;
  created_at?: InputMaybe<DateTimeFilter>;
  custom_ordinal?: InputMaybe<IntNullableFilter>;
  lead?: InputMaybe<LeadWhereInput>;
  lead_id?: InputMaybe<StringFilter>;
  NOT?: InputMaybe<Array<CustomQueueItemWhereInput>>;
  OR?: InputMaybe<Array<CustomQueueItemWhereInput>>;
  Organization?: InputMaybe<OrganizationWhereInput>;
  organization_id?: InputMaybe<StringNullableFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserWhereInput>;
  user_id?: InputMaybe<StringFilter>;
};

export type CustomQueueItemWhereUniqueInput = {
  user_id_lead_id?: InputMaybe<CustomQueueItemUser_IdLead_IdCompoundUniqueInput>;
};

export type CustomTaskAckInput = {
  action_completed: Scalars['Boolean']['input'];
  delay_days?: InputMaybe<Scalars['Int']['input']>;
  delay_hours?: InputMaybe<Scalars['Int']['input']>;
  delay_minutes?: InputMaybe<Scalars['Int']['input']>;
  delay_until_scheduled_event?: InputMaybe<Scalars['Boolean']['input']>;
  lead_id?: InputMaybe<Scalars['String']['input']>;
  lead_intent_id?: InputMaybe<Scalars['String']['input']>;
  nextstep: Scalars['Boolean']['input'];
  reason?: InputMaybe<Scalars['String']['input']>;
  remove: Scalars['Boolean']['input'];
  snooze: Scalars['Boolean']['input'];
  step_action_id: Scalars['String']['input'];
};

/** CustomTransferRuleInput for create / update */
export type CustomTransferRuleInput = {
  backup_rule: BackUpRule;
  date_range?: InputMaybe<DateOption>;
  distribution_method?: InputMaybe<Distribution_Method>;
  metric?: InputMaybe<Metric>;
  rank_sort_type?: InputMaybe<RankSortType>;
  rep_ids: Array<Scalars['String']['input']>;
  rule_type: TransferRuleType;
};

export enum Dashboard_Group_Option {
  None = 'None',
  Site = 'Site',
  Team = 'Team'
}

/** Summarized Dashboard Input */
export type DashboardArgument = {
  dashboard_type: DashboardType;
  date_filter: DashboardDateOption;
  group_option?: Dashboard_Group_Option;
  group_reference?: InputMaybe<Scalars['String']['input']>;
  lead_filter: DashboardLeadFilterOption;
  rep_filter: DashboardRepFilterOption;
};

export type DashboardColumnOption = {
  __typename?: 'DashboardColumnOption';
  all_activity_fields?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  all_coaching_fields?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  all_event_fields?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  all_pipeline_fields?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  all_reporting_fields?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  all_transfer_fields?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  all_transfer_quality_fields?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  created_at: Scalars['DateTime']['output'];
  DashboardSavedView: DashboardSavedView;
  saved_view_id: Scalars['String']['output'];
  updated_at: Scalars['DateTime']['output'];
  visible_activity_fields: Array<Scalars['String']['output']>;
  visible_coaching_fields: Array<Scalars['String']['output']>;
  visible_event_fields: Array<Scalars['String']['output']>;
  visible_pipeline_fields: Array<Scalars['String']['output']>;
  visible_reporting_fields: Array<Scalars['String']['output']>;
  visible_transfer_fields: Array<Scalars['String']['output']>;
  visible_transfer_quality_fields: Array<Scalars['String']['output']>;
};

export type DashboardColumnOptionCreateManyOrganizationInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  saved_view_id: Scalars['String']['input'];
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  visible_activity_fields?: InputMaybe<DashboardColumnOptionCreateManyvisible_Activity_FieldsInput>;
  visible_coaching_fields?: InputMaybe<DashboardColumnOptionCreateManyvisible_Coaching_FieldsInput>;
  visible_event_fields?: InputMaybe<DashboardColumnOptionCreateManyvisible_Event_FieldsInput>;
  visible_pipeline_fields?: InputMaybe<DashboardColumnOptionCreateManyvisible_Pipeline_FieldsInput>;
  visible_reporting_fields?: InputMaybe<DashboardColumnOptionCreateManyvisible_Reporting_FieldsInput>;
  visible_transfer_fields?: InputMaybe<DashboardColumnOptionCreateManyvisible_Transfer_FieldsInput>;
  visible_transfer_quality_fields?: InputMaybe<DashboardColumnOptionCreateManyvisible_Transfer_Quality_FieldsInput>;
};

export type DashboardColumnOptionCreateManyOrganizationInputEnvelope = {
  data?: InputMaybe<Array<DashboardColumnOptionCreateManyOrganizationInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type DashboardColumnOptionCreateManyvisible_Activity_FieldsInput = {
  set?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type DashboardColumnOptionCreateManyvisible_Coaching_FieldsInput = {
  set?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type DashboardColumnOptionCreateManyvisible_Event_FieldsInput = {
  set?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type DashboardColumnOptionCreateManyvisible_Pipeline_FieldsInput = {
  set?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type DashboardColumnOptionCreateManyvisible_Reporting_FieldsInput = {
  set?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type DashboardColumnOptionCreateManyvisible_Transfer_FieldsInput = {
  set?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type DashboardColumnOptionCreateManyvisible_Transfer_Quality_FieldsInput = {
  set?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type DashboardColumnOptionCreateNestedManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<DashboardColumnOptionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<DashboardColumnOptionCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<DashboardColumnOptionCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<DashboardColumnOptionCreateManyOrganizationInputEnvelope>;
};

export type DashboardColumnOptionCreateNestedOneWithoutDashboardSavedViewInput = {
  connect?: InputMaybe<DashboardColumnOptionWhereUniqueInput>;
  connectOrCreate?: InputMaybe<DashboardColumnOptionCreateOrConnectWithoutDashboardSavedViewInput>;
  create?: InputMaybe<DashboardColumnOptionCreateWithoutDashboardSavedViewInput>;
};

export type DashboardColumnOptionCreateOrConnectWithoutDashboardSavedViewInput = {
  create: DashboardColumnOptionCreateWithoutDashboardSavedViewInput;
  where: DashboardColumnOptionWhereUniqueInput;
};

export type DashboardColumnOptionCreateOrConnectWithoutOrganizationInput = {
  create: DashboardColumnOptionCreateWithoutOrganizationInput;
  where: DashboardColumnOptionWhereUniqueInput;
};

export type DashboardColumnOptionCreatevisible_Activity_FieldsInput = {
  set?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type DashboardColumnOptionCreatevisible_Coaching_FieldsInput = {
  set?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type DashboardColumnOptionCreatevisible_Event_FieldsInput = {
  set?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type DashboardColumnOptionCreatevisible_Pipeline_FieldsInput = {
  set?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type DashboardColumnOptionCreatevisible_Reporting_FieldsInput = {
  set?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type DashboardColumnOptionCreatevisible_Transfer_FieldsInput = {
  set?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type DashboardColumnOptionCreatevisible_Transfer_Quality_FieldsInput = {
  set?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type DashboardColumnOptionCreateWithoutDashboardSavedViewInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  Organization?: InputMaybe<OrganizationCreateNestedOneWithoutDashboardColumnOptionsInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  visible_activity_fields?: InputMaybe<DashboardColumnOptionCreatevisible_Activity_FieldsInput>;
  visible_coaching_fields?: InputMaybe<DashboardColumnOptionCreatevisible_Coaching_FieldsInput>;
  visible_event_fields?: InputMaybe<DashboardColumnOptionCreatevisible_Event_FieldsInput>;
  visible_pipeline_fields?: InputMaybe<DashboardColumnOptionCreatevisible_Pipeline_FieldsInput>;
  visible_reporting_fields?: InputMaybe<DashboardColumnOptionCreatevisible_Reporting_FieldsInput>;
  visible_transfer_fields?: InputMaybe<DashboardColumnOptionCreatevisible_Transfer_FieldsInput>;
  visible_transfer_quality_fields?: InputMaybe<DashboardColumnOptionCreatevisible_Transfer_Quality_FieldsInput>;
};

export type DashboardColumnOptionCreateWithoutOrganizationInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  DashboardSavedView: DashboardSavedViewCreateNestedOneWithoutColumn_OptionsInput;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  visible_activity_fields?: InputMaybe<DashboardColumnOptionCreatevisible_Activity_FieldsInput>;
  visible_coaching_fields?: InputMaybe<DashboardColumnOptionCreatevisible_Coaching_FieldsInput>;
  visible_event_fields?: InputMaybe<DashboardColumnOptionCreatevisible_Event_FieldsInput>;
  visible_pipeline_fields?: InputMaybe<DashboardColumnOptionCreatevisible_Pipeline_FieldsInput>;
  visible_reporting_fields?: InputMaybe<DashboardColumnOptionCreatevisible_Reporting_FieldsInput>;
  visible_transfer_fields?: InputMaybe<DashboardColumnOptionCreatevisible_Transfer_FieldsInput>;
  visible_transfer_quality_fields?: InputMaybe<DashboardColumnOptionCreatevisible_Transfer_Quality_FieldsInput>;
};

export type DashboardColumnOptionListRelationFilter = {
  every?: InputMaybe<DashboardColumnOptionWhereInput>;
  none?: InputMaybe<DashboardColumnOptionWhereInput>;
  some?: InputMaybe<DashboardColumnOptionWhereInput>;
};

export type DashboardColumnOptionOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
  count?: InputMaybe<SortOrder>;
};

export type DashboardColumnOptionScalarWhereInput = {
  AND?: InputMaybe<Array<DashboardColumnOptionScalarWhereInput>>;
  created_at?: InputMaybe<DateTimeFilter>;
  NOT?: InputMaybe<Array<DashboardColumnOptionScalarWhereInput>>;
  OR?: InputMaybe<Array<DashboardColumnOptionScalarWhereInput>>;
  organization_id?: InputMaybe<StringNullableFilter>;
  saved_view_id?: InputMaybe<StringFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
  visible_activity_fields?: InputMaybe<StringNullableListFilter>;
  visible_coaching_fields?: InputMaybe<StringNullableListFilter>;
  visible_event_fields?: InputMaybe<StringNullableListFilter>;
  visible_pipeline_fields?: InputMaybe<StringNullableListFilter>;
  visible_reporting_fields?: InputMaybe<StringNullableListFilter>;
  visible_transfer_fields?: InputMaybe<StringNullableListFilter>;
  visible_transfer_quality_fields?: InputMaybe<StringNullableListFilter>;
};

export type DashboardColumnOptionUpdateManyMutationInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  visible_activity_fields?: InputMaybe<DashboardColumnOptionUpdatevisible_Activity_FieldsInput>;
  visible_coaching_fields?: InputMaybe<DashboardColumnOptionUpdatevisible_Coaching_FieldsInput>;
  visible_event_fields?: InputMaybe<DashboardColumnOptionUpdatevisible_Event_FieldsInput>;
  visible_pipeline_fields?: InputMaybe<DashboardColumnOptionUpdatevisible_Pipeline_FieldsInput>;
  visible_reporting_fields?: InputMaybe<DashboardColumnOptionUpdatevisible_Reporting_FieldsInput>;
  visible_transfer_fields?: InputMaybe<DashboardColumnOptionUpdatevisible_Transfer_FieldsInput>;
  visible_transfer_quality_fields?: InputMaybe<DashboardColumnOptionUpdatevisible_Transfer_Quality_FieldsInput>;
};

export type DashboardColumnOptionUpdateManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<DashboardColumnOptionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<DashboardColumnOptionCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<DashboardColumnOptionCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<DashboardColumnOptionCreateManyOrganizationInputEnvelope>;
  delete?: InputMaybe<Array<DashboardColumnOptionWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<DashboardColumnOptionScalarWhereInput>>;
  disconnect?: InputMaybe<Array<DashboardColumnOptionWhereUniqueInput>>;
  set?: InputMaybe<Array<DashboardColumnOptionWhereUniqueInput>>;
  update?: InputMaybe<Array<DashboardColumnOptionUpdateWithWhereUniqueWithoutOrganizationInput>>;
  updateMany?: InputMaybe<Array<DashboardColumnOptionUpdateManyWithWhereWithoutOrganizationInput>>;
  upsert?: InputMaybe<Array<DashboardColumnOptionUpsertWithWhereUniqueWithoutOrganizationInput>>;
};

export type DashboardColumnOptionUpdateManyWithWhereWithoutOrganizationInput = {
  data: DashboardColumnOptionUpdateManyMutationInput;
  where: DashboardColumnOptionScalarWhereInput;
};

export type DashboardColumnOptionUpdateOneWithoutDashboardSavedViewInput = {
  connect?: InputMaybe<DashboardColumnOptionWhereUniqueInput>;
  connectOrCreate?: InputMaybe<DashboardColumnOptionCreateOrConnectWithoutDashboardSavedViewInput>;
  create?: InputMaybe<DashboardColumnOptionCreateWithoutDashboardSavedViewInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<DashboardColumnOptionUpdateWithoutDashboardSavedViewInput>;
  upsert?: InputMaybe<DashboardColumnOptionUpsertWithoutDashboardSavedViewInput>;
};

export type DashboardColumnOptionUpdatevisible_Activity_FieldsInput = {
  push?: InputMaybe<Scalars['String']['input']>;
  set?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type DashboardColumnOptionUpdatevisible_Coaching_FieldsInput = {
  push?: InputMaybe<Scalars['String']['input']>;
  set?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type DashboardColumnOptionUpdatevisible_Event_FieldsInput = {
  push?: InputMaybe<Scalars['String']['input']>;
  set?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type DashboardColumnOptionUpdatevisible_Pipeline_FieldsInput = {
  push?: InputMaybe<Scalars['String']['input']>;
  set?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type DashboardColumnOptionUpdatevisible_Reporting_FieldsInput = {
  push?: InputMaybe<Scalars['String']['input']>;
  set?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type DashboardColumnOptionUpdatevisible_Transfer_FieldsInput = {
  push?: InputMaybe<Scalars['String']['input']>;
  set?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type DashboardColumnOptionUpdatevisible_Transfer_Quality_FieldsInput = {
  push?: InputMaybe<Scalars['String']['input']>;
  set?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type DashboardColumnOptionUpdateWithoutDashboardSavedViewInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  Organization?: InputMaybe<OrganizationUpdateOneWithoutDashboardColumnOptionsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  visible_activity_fields?: InputMaybe<DashboardColumnOptionUpdatevisible_Activity_FieldsInput>;
  visible_coaching_fields?: InputMaybe<DashboardColumnOptionUpdatevisible_Coaching_FieldsInput>;
  visible_event_fields?: InputMaybe<DashboardColumnOptionUpdatevisible_Event_FieldsInput>;
  visible_pipeline_fields?: InputMaybe<DashboardColumnOptionUpdatevisible_Pipeline_FieldsInput>;
  visible_reporting_fields?: InputMaybe<DashboardColumnOptionUpdatevisible_Reporting_FieldsInput>;
  visible_transfer_fields?: InputMaybe<DashboardColumnOptionUpdatevisible_Transfer_FieldsInput>;
  visible_transfer_quality_fields?: InputMaybe<DashboardColumnOptionUpdatevisible_Transfer_Quality_FieldsInput>;
};

export type DashboardColumnOptionUpdateWithoutOrganizationInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewUpdateOneRequiredWithoutColumn_OptionsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  visible_activity_fields?: InputMaybe<DashboardColumnOptionUpdatevisible_Activity_FieldsInput>;
  visible_coaching_fields?: InputMaybe<DashboardColumnOptionUpdatevisible_Coaching_FieldsInput>;
  visible_event_fields?: InputMaybe<DashboardColumnOptionUpdatevisible_Event_FieldsInput>;
  visible_pipeline_fields?: InputMaybe<DashboardColumnOptionUpdatevisible_Pipeline_FieldsInput>;
  visible_reporting_fields?: InputMaybe<DashboardColumnOptionUpdatevisible_Reporting_FieldsInput>;
  visible_transfer_fields?: InputMaybe<DashboardColumnOptionUpdatevisible_Transfer_FieldsInput>;
  visible_transfer_quality_fields?: InputMaybe<DashboardColumnOptionUpdatevisible_Transfer_Quality_FieldsInput>;
};

export type DashboardColumnOptionUpdateWithWhereUniqueWithoutOrganizationInput = {
  data: DashboardColumnOptionUpdateWithoutOrganizationInput;
  where: DashboardColumnOptionWhereUniqueInput;
};

export type DashboardColumnOptionUpsertWithoutDashboardSavedViewInput = {
  create: DashboardColumnOptionCreateWithoutDashboardSavedViewInput;
  update: DashboardColumnOptionUpdateWithoutDashboardSavedViewInput;
};

export type DashboardColumnOptionUpsertWithWhereUniqueWithoutOrganizationInput = {
  create: DashboardColumnOptionCreateWithoutOrganizationInput;
  update: DashboardColumnOptionUpdateWithoutOrganizationInput;
  where: DashboardColumnOptionWhereUniqueInput;
};

export type DashboardColumnOptionWhereInput = {
  AND?: InputMaybe<Array<DashboardColumnOptionWhereInput>>;
  created_at?: InputMaybe<DateTimeFilter>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewWhereInput>;
  NOT?: InputMaybe<Array<DashboardColumnOptionWhereInput>>;
  OR?: InputMaybe<Array<DashboardColumnOptionWhereInput>>;
  Organization?: InputMaybe<OrganizationWhereInput>;
  organization_id?: InputMaybe<StringNullableFilter>;
  saved_view_id?: InputMaybe<StringFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
  visible_activity_fields?: InputMaybe<StringNullableListFilter>;
  visible_coaching_fields?: InputMaybe<StringNullableListFilter>;
  visible_event_fields?: InputMaybe<StringNullableListFilter>;
  visible_pipeline_fields?: InputMaybe<StringNullableListFilter>;
  visible_reporting_fields?: InputMaybe<StringNullableListFilter>;
  visible_transfer_fields?: InputMaybe<StringNullableListFilter>;
  visible_transfer_quality_fields?: InputMaybe<StringNullableListFilter>;
};

export type DashboardColumnOptionWhereUniqueInput = {
  saved_view_id?: InputMaybe<Scalars['String']['input']>;
};

/** Filter inputs for lead options */
export type DashboardDateOption = {
  date_range?: InputMaybe<DateOption>;
  lowerbound_date?: InputMaybe<Scalars['Date']['input']>;
  upperbound_date?: InputMaybe<Scalars['Date']['input']>;
};

/** Summarized Dashboard Input */
export type DashboardDetailArgument = {
  computed_id: Scalars['String']['input'];
  date_filter: DashboardDateOption;
  desc?: InputMaybe<Scalars['Boolean']['input']>;
  lead_filter: DashboardLeadFilterOption;
  measure_by?: InputMaybe<MeasureByFilter>;
  metric: Metric;
  order_by?: InputMaybe<CallReportListSortOption>;
  position: Position;
  rep_filter: DashboardRepFilterOption;
  role?: InputMaybe<Role>;
  /** used to filter by site id on position user */
  site_id?: InputMaybe<Scalars['String']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  /** used to filter by team id on position user */
  team_id?: InputMaybe<Scalars['String']['input']>;
};

/** Filter inputs for lead options */
export type DashboardFilter = {
  Channels?: InputMaybe<Array<Channel>>;
  custom_fields?: InputMaybe<Array<InputMaybe<LeadCustomFieldFilter>>>;
  LeadCreationSources?: InputMaybe<Array<Leadcreationsource>>;
  LeadSources?: InputMaybe<Array<Scalars['String']['input']>>;
  NextScheduledEventDays?: InputMaybe<Scalars['Int']['input']>;
  NextScheduledEventTypes?: InputMaybe<Array<InputMaybe<ScheduleItemType>>>;
  primary_industries?: InputMaybe<Array<Scalars['String']['input']>>;
  sequences?: InputMaybe<Array<InputMaybe<SequencesDashFilter>>>;
  sub_industries?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type DashboardLeadFilter = {
  __typename?: 'DashboardLeadFilter';
  Channels: Array<Channel>;
  created_at: Scalars['DateTime']['output'];
  custom_fields: Array<Scalars['Json']['output']>;
  DashboardSavedView: DashboardSavedView;
  LeadCreationSources: Array<Leadcreationsource>;
  LeadSources: Array<Scalars['String']['output']>;
  NextScheduledEventDays?: Maybe<Scalars['Int']['output']>;
  NextScheduledEventTypes: Array<ScheduleItemType>;
  primary_industries: Array<Scalars['String']['output']>;
  saved_view_id: Scalars['String']['output'];
  sequences: Array<Scalars['Json']['output']>;
  sub_industries: Array<Scalars['String']['output']>;
  updated_at: Scalars['DateTime']['output'];
};

export type DashboardLeadFilterCreateChannelsInput = {
  set?: InputMaybe<Array<Channel>>;
};

export type DashboardLeadFilterCreatecustom_FieldsInput = {
  set?: InputMaybe<Array<Scalars['Json']['input']>>;
};

export type DashboardLeadFilterCreateLeadCreationSourcesInput = {
  set?: InputMaybe<Array<Leadcreationsource>>;
};

export type DashboardLeadFilterCreateLeadSourcesInput = {
  set?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type DashboardLeadFilterCreateManyChannelsInput = {
  set?: InputMaybe<Array<Channel>>;
};

export type DashboardLeadFilterCreateManycustom_FieldsInput = {
  set?: InputMaybe<Array<Scalars['Json']['input']>>;
};

export type DashboardLeadFilterCreateManyLeadCreationSourcesInput = {
  set?: InputMaybe<Array<Leadcreationsource>>;
};

export type DashboardLeadFilterCreateManyLeadSourcesInput = {
  set?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type DashboardLeadFilterCreateManyNextScheduledEventTypesInput = {
  set?: InputMaybe<Array<ScheduleItemType>>;
};

export type DashboardLeadFilterCreateManyOrganizationInput = {
  Channels?: InputMaybe<DashboardLeadFilterCreateManyChannelsInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  custom_fields?: InputMaybe<DashboardLeadFilterCreateManycustom_FieldsInput>;
  LeadCreationSources?: InputMaybe<DashboardLeadFilterCreateManyLeadCreationSourcesInput>;
  LeadSources?: InputMaybe<DashboardLeadFilterCreateManyLeadSourcesInput>;
  NextScheduledEventDays?: InputMaybe<Scalars['Int']['input']>;
  NextScheduledEventTypes?: InputMaybe<DashboardLeadFilterCreateManyNextScheduledEventTypesInput>;
  primary_industries?: InputMaybe<DashboardLeadFilterCreateManyprimary_IndustriesInput>;
  saved_view_id: Scalars['String']['input'];
  sequences?: InputMaybe<DashboardLeadFilterCreateManysequencesInput>;
  sub_industries?: InputMaybe<DashboardLeadFilterCreateManysub_IndustriesInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type DashboardLeadFilterCreateManyOrganizationInputEnvelope = {
  data?: InputMaybe<Array<DashboardLeadFilterCreateManyOrganizationInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type DashboardLeadFilterCreateManyprimary_IndustriesInput = {
  set?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type DashboardLeadFilterCreateManysequencesInput = {
  set?: InputMaybe<Array<Scalars['Json']['input']>>;
};

export type DashboardLeadFilterCreateManysub_IndustriesInput = {
  set?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type DashboardLeadFilterCreateNestedManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<DashboardLeadFilterWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<DashboardLeadFilterCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<DashboardLeadFilterCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<DashboardLeadFilterCreateManyOrganizationInputEnvelope>;
};

export type DashboardLeadFilterCreateNestedOneWithoutDashboardSavedViewInput = {
  connect?: InputMaybe<DashboardLeadFilterWhereUniqueInput>;
  connectOrCreate?: InputMaybe<DashboardLeadFilterCreateOrConnectWithoutDashboardSavedViewInput>;
  create?: InputMaybe<DashboardLeadFilterCreateWithoutDashboardSavedViewInput>;
};

export type DashboardLeadFilterCreateNextScheduledEventTypesInput = {
  set?: InputMaybe<Array<ScheduleItemType>>;
};

export type DashboardLeadFilterCreateOrConnectWithoutDashboardSavedViewInput = {
  create: DashboardLeadFilterCreateWithoutDashboardSavedViewInput;
  where: DashboardLeadFilterWhereUniqueInput;
};

export type DashboardLeadFilterCreateOrConnectWithoutOrganizationInput = {
  create: DashboardLeadFilterCreateWithoutOrganizationInput;
  where: DashboardLeadFilterWhereUniqueInput;
};

export type DashboardLeadFilterCreateprimary_IndustriesInput = {
  set?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type DashboardLeadFilterCreatesequencesInput = {
  set?: InputMaybe<Array<Scalars['Json']['input']>>;
};

export type DashboardLeadFilterCreatesub_IndustriesInput = {
  set?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type DashboardLeadFilterCreateWithoutDashboardSavedViewInput = {
  Channels?: InputMaybe<DashboardLeadFilterCreateChannelsInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  custom_fields?: InputMaybe<DashboardLeadFilterCreatecustom_FieldsInput>;
  LeadCreationSources?: InputMaybe<DashboardLeadFilterCreateLeadCreationSourcesInput>;
  LeadSources?: InputMaybe<DashboardLeadFilterCreateLeadSourcesInput>;
  NextScheduledEventDays?: InputMaybe<Scalars['Int']['input']>;
  NextScheduledEventTypes?: InputMaybe<DashboardLeadFilterCreateNextScheduledEventTypesInput>;
  Organization?: InputMaybe<OrganizationCreateNestedOneWithoutDashboardLeadFiltersInput>;
  primary_industries?: InputMaybe<DashboardLeadFilterCreateprimary_IndustriesInput>;
  sequences?: InputMaybe<DashboardLeadFilterCreatesequencesInput>;
  sub_industries?: InputMaybe<DashboardLeadFilterCreatesub_IndustriesInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type DashboardLeadFilterCreateWithoutOrganizationInput = {
  Channels?: InputMaybe<DashboardLeadFilterCreateChannelsInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  custom_fields?: InputMaybe<DashboardLeadFilterCreatecustom_FieldsInput>;
  DashboardSavedView: DashboardSavedViewCreateNestedOneWithoutLead_FilterInput;
  LeadCreationSources?: InputMaybe<DashboardLeadFilterCreateLeadCreationSourcesInput>;
  LeadSources?: InputMaybe<DashboardLeadFilterCreateLeadSourcesInput>;
  NextScheduledEventDays?: InputMaybe<Scalars['Int']['input']>;
  NextScheduledEventTypes?: InputMaybe<DashboardLeadFilterCreateNextScheduledEventTypesInput>;
  primary_industries?: InputMaybe<DashboardLeadFilterCreateprimary_IndustriesInput>;
  sequences?: InputMaybe<DashboardLeadFilterCreatesequencesInput>;
  sub_industries?: InputMaybe<DashboardLeadFilterCreatesub_IndustriesInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type DashboardLeadFilterListRelationFilter = {
  every?: InputMaybe<DashboardLeadFilterWhereInput>;
  none?: InputMaybe<DashboardLeadFilterWhereInput>;
  some?: InputMaybe<DashboardLeadFilterWhereInput>;
};

/** Filter inputs for lead options */
export type DashboardLeadFilterOption = {
  Channels: Array<Channel>;
  custom_fields?: InputMaybe<Array<InputMaybe<LeadCustomFieldFilter>>>;
  LeadCreationSources: Array<Leadcreationsource>;
  LeadSources: Array<Scalars['String']['input']>;
  NextScheduledEventDays?: InputMaybe<Scalars['Int']['input']>;
  NextScheduledEventTypes?: InputMaybe<Array<InputMaybe<ScheduleItemType>>>;
  primary_industries: Array<Scalars['String']['input']>;
  sequences?: InputMaybe<Array<InputMaybe<SequencesDashFilter>>>;
  sub_industries: Array<Scalars['String']['input']>;
};

export type DashboardLeadFilterOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
  count?: InputMaybe<SortOrder>;
};

export type DashboardLeadFilterScalarWhereInput = {
  AND?: InputMaybe<Array<DashboardLeadFilterScalarWhereInput>>;
  Channels?: InputMaybe<EnumChannelNullableListFilter>;
  created_at?: InputMaybe<DateTimeFilter>;
  custom_fields?: InputMaybe<JsonNullableListFilter>;
  LeadCreationSources?: InputMaybe<EnumLeadcreationsourceNullableListFilter>;
  LeadSources?: InputMaybe<StringNullableListFilter>;
  NextScheduledEventDays?: InputMaybe<IntNullableFilter>;
  NextScheduledEventTypes?: InputMaybe<EnumScheduleItemTypeNullableListFilter>;
  NOT?: InputMaybe<Array<DashboardLeadFilterScalarWhereInput>>;
  OR?: InputMaybe<Array<DashboardLeadFilterScalarWhereInput>>;
  organization_id?: InputMaybe<StringNullableFilter>;
  primary_industries?: InputMaybe<StringNullableListFilter>;
  saved_view_id?: InputMaybe<StringFilter>;
  sequences?: InputMaybe<JsonNullableListFilter>;
  sub_industries?: InputMaybe<StringNullableListFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
};

export type DashboardLeadFilterUpdateChannelsInput = {
  push?: InputMaybe<Channel>;
  set?: InputMaybe<Array<Channel>>;
};

export type DashboardLeadFilterUpdatecustom_FieldsInput = {
  push?: InputMaybe<Scalars['Json']['input']>;
  set?: InputMaybe<Array<Scalars['Json']['input']>>;
};

export type DashboardLeadFilterUpdateLeadCreationSourcesInput = {
  push?: InputMaybe<Leadcreationsource>;
  set?: InputMaybe<Array<Leadcreationsource>>;
};

export type DashboardLeadFilterUpdateLeadSourcesInput = {
  push?: InputMaybe<Scalars['String']['input']>;
  set?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type DashboardLeadFilterUpdateManyMutationInput = {
  Channels?: InputMaybe<DashboardLeadFilterUpdateChannelsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  custom_fields?: InputMaybe<DashboardLeadFilterUpdatecustom_FieldsInput>;
  LeadCreationSources?: InputMaybe<DashboardLeadFilterUpdateLeadCreationSourcesInput>;
  LeadSources?: InputMaybe<DashboardLeadFilterUpdateLeadSourcesInput>;
  NextScheduledEventDays?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  NextScheduledEventTypes?: InputMaybe<DashboardLeadFilterUpdateNextScheduledEventTypesInput>;
  primary_industries?: InputMaybe<DashboardLeadFilterUpdateprimary_IndustriesInput>;
  sequences?: InputMaybe<DashboardLeadFilterUpdatesequencesInput>;
  sub_industries?: InputMaybe<DashboardLeadFilterUpdatesub_IndustriesInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type DashboardLeadFilterUpdateManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<DashboardLeadFilterWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<DashboardLeadFilterCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<DashboardLeadFilterCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<DashboardLeadFilterCreateManyOrganizationInputEnvelope>;
  delete?: InputMaybe<Array<DashboardLeadFilterWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<DashboardLeadFilterScalarWhereInput>>;
  disconnect?: InputMaybe<Array<DashboardLeadFilterWhereUniqueInput>>;
  set?: InputMaybe<Array<DashboardLeadFilterWhereUniqueInput>>;
  update?: InputMaybe<Array<DashboardLeadFilterUpdateWithWhereUniqueWithoutOrganizationInput>>;
  updateMany?: InputMaybe<Array<DashboardLeadFilterUpdateManyWithWhereWithoutOrganizationInput>>;
  upsert?: InputMaybe<Array<DashboardLeadFilterUpsertWithWhereUniqueWithoutOrganizationInput>>;
};

export type DashboardLeadFilterUpdateManyWithWhereWithoutOrganizationInput = {
  data: DashboardLeadFilterUpdateManyMutationInput;
  where: DashboardLeadFilterScalarWhereInput;
};

export type DashboardLeadFilterUpdateNextScheduledEventTypesInput = {
  push?: InputMaybe<ScheduleItemType>;
  set?: InputMaybe<Array<ScheduleItemType>>;
};

export type DashboardLeadFilterUpdateOneWithoutDashboardSavedViewInput = {
  connect?: InputMaybe<DashboardLeadFilterWhereUniqueInput>;
  connectOrCreate?: InputMaybe<DashboardLeadFilterCreateOrConnectWithoutDashboardSavedViewInput>;
  create?: InputMaybe<DashboardLeadFilterCreateWithoutDashboardSavedViewInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<DashboardLeadFilterUpdateWithoutDashboardSavedViewInput>;
  upsert?: InputMaybe<DashboardLeadFilterUpsertWithoutDashboardSavedViewInput>;
};

export type DashboardLeadFilterUpdateprimary_IndustriesInput = {
  push?: InputMaybe<Scalars['String']['input']>;
  set?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type DashboardLeadFilterUpdatesequencesInput = {
  push?: InputMaybe<Scalars['Json']['input']>;
  set?: InputMaybe<Array<Scalars['Json']['input']>>;
};

export type DashboardLeadFilterUpdatesub_IndustriesInput = {
  push?: InputMaybe<Scalars['String']['input']>;
  set?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type DashboardLeadFilterUpdateWithoutDashboardSavedViewInput = {
  Channels?: InputMaybe<DashboardLeadFilterUpdateChannelsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  custom_fields?: InputMaybe<DashboardLeadFilterUpdatecustom_FieldsInput>;
  LeadCreationSources?: InputMaybe<DashboardLeadFilterUpdateLeadCreationSourcesInput>;
  LeadSources?: InputMaybe<DashboardLeadFilterUpdateLeadSourcesInput>;
  NextScheduledEventDays?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  NextScheduledEventTypes?: InputMaybe<DashboardLeadFilterUpdateNextScheduledEventTypesInput>;
  Organization?: InputMaybe<OrganizationUpdateOneWithoutDashboardLeadFiltersInput>;
  primary_industries?: InputMaybe<DashboardLeadFilterUpdateprimary_IndustriesInput>;
  sequences?: InputMaybe<DashboardLeadFilterUpdatesequencesInput>;
  sub_industries?: InputMaybe<DashboardLeadFilterUpdatesub_IndustriesInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type DashboardLeadFilterUpdateWithoutOrganizationInput = {
  Channels?: InputMaybe<DashboardLeadFilterUpdateChannelsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  custom_fields?: InputMaybe<DashboardLeadFilterUpdatecustom_FieldsInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewUpdateOneRequiredWithoutLead_FilterInput>;
  LeadCreationSources?: InputMaybe<DashboardLeadFilterUpdateLeadCreationSourcesInput>;
  LeadSources?: InputMaybe<DashboardLeadFilterUpdateLeadSourcesInput>;
  NextScheduledEventDays?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  NextScheduledEventTypes?: InputMaybe<DashboardLeadFilterUpdateNextScheduledEventTypesInput>;
  primary_industries?: InputMaybe<DashboardLeadFilterUpdateprimary_IndustriesInput>;
  sequences?: InputMaybe<DashboardLeadFilterUpdatesequencesInput>;
  sub_industries?: InputMaybe<DashboardLeadFilterUpdatesub_IndustriesInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type DashboardLeadFilterUpdateWithWhereUniqueWithoutOrganizationInput = {
  data: DashboardLeadFilterUpdateWithoutOrganizationInput;
  where: DashboardLeadFilterWhereUniqueInput;
};

export type DashboardLeadFilterUpsertWithoutDashboardSavedViewInput = {
  create: DashboardLeadFilterCreateWithoutDashboardSavedViewInput;
  update: DashboardLeadFilterUpdateWithoutDashboardSavedViewInput;
};

export type DashboardLeadFilterUpsertWithWhereUniqueWithoutOrganizationInput = {
  create: DashboardLeadFilterCreateWithoutOrganizationInput;
  update: DashboardLeadFilterUpdateWithoutOrganizationInput;
  where: DashboardLeadFilterWhereUniqueInput;
};

export type DashboardLeadFilterWhereInput = {
  AND?: InputMaybe<Array<DashboardLeadFilterWhereInput>>;
  Channels?: InputMaybe<EnumChannelNullableListFilter>;
  created_at?: InputMaybe<DateTimeFilter>;
  custom_fields?: InputMaybe<JsonNullableListFilter>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewWhereInput>;
  LeadCreationSources?: InputMaybe<EnumLeadcreationsourceNullableListFilter>;
  LeadSources?: InputMaybe<StringNullableListFilter>;
  NextScheduledEventDays?: InputMaybe<IntNullableFilter>;
  NextScheduledEventTypes?: InputMaybe<EnumScheduleItemTypeNullableListFilter>;
  NOT?: InputMaybe<Array<DashboardLeadFilterWhereInput>>;
  OR?: InputMaybe<Array<DashboardLeadFilterWhereInput>>;
  Organization?: InputMaybe<OrganizationWhereInput>;
  organization_id?: InputMaybe<StringNullableFilter>;
  primary_industries?: InputMaybe<StringNullableListFilter>;
  saved_view_id?: InputMaybe<StringFilter>;
  sequences?: InputMaybe<JsonNullableListFilter>;
  sub_industries?: InputMaybe<StringNullableListFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
};

export type DashboardLeadFilterWhereUniqueInput = {
  saved_view_id?: InputMaybe<Scalars['String']['input']>;
};

export type DashboardRepFilter = {
  __typename?: 'DashboardRepFilter';
  created_at: Scalars['DateTime']['output'];
  DashboardSavedView: DashboardSavedView;
  roles: Array<Role>;
  saved_view_id: Scalars['String']['output'];
  site_ids: Array<Scalars['String']['output']>;
  team_ids: Array<Scalars['String']['output']>;
  updated_at: Scalars['DateTime']['output'];
  user_ids: Array<Scalars['String']['output']>;
};

export type DashboardRepFilterCreateManyOrganizationInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  roles?: InputMaybe<DashboardRepFilterCreateManyrolesInput>;
  saved_view_id: Scalars['String']['input'];
  site_ids?: InputMaybe<DashboardRepFilterCreateManysite_IdsInput>;
  team_ids?: InputMaybe<DashboardRepFilterCreateManyteam_IdsInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user_ids?: InputMaybe<DashboardRepFilterCreateManyuser_IdsInput>;
};

export type DashboardRepFilterCreateManyOrganizationInputEnvelope = {
  data?: InputMaybe<Array<DashboardRepFilterCreateManyOrganizationInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type DashboardRepFilterCreateManyrolesInput = {
  set?: InputMaybe<Array<Role>>;
};

export type DashboardRepFilterCreateManysite_IdsInput = {
  set?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type DashboardRepFilterCreateManyteam_IdsInput = {
  set?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type DashboardRepFilterCreateManyuser_IdsInput = {
  set?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type DashboardRepFilterCreateNestedManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<DashboardRepFilterWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<DashboardRepFilterCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<DashboardRepFilterCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<DashboardRepFilterCreateManyOrganizationInputEnvelope>;
};

export type DashboardRepFilterCreateNestedOneWithoutDashboardSavedViewInput = {
  connect?: InputMaybe<DashboardRepFilterWhereUniqueInput>;
  connectOrCreate?: InputMaybe<DashboardRepFilterCreateOrConnectWithoutDashboardSavedViewInput>;
  create?: InputMaybe<DashboardRepFilterCreateWithoutDashboardSavedViewInput>;
};

export type DashboardRepFilterCreateOrConnectWithoutDashboardSavedViewInput = {
  create: DashboardRepFilterCreateWithoutDashboardSavedViewInput;
  where: DashboardRepFilterWhereUniqueInput;
};

export type DashboardRepFilterCreateOrConnectWithoutOrganizationInput = {
  create: DashboardRepFilterCreateWithoutOrganizationInput;
  where: DashboardRepFilterWhereUniqueInput;
};

export type DashboardRepFilterCreaterolesInput = {
  set?: InputMaybe<Array<Role>>;
};

export type DashboardRepFilterCreatesite_IdsInput = {
  set?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type DashboardRepFilterCreateteam_IdsInput = {
  set?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type DashboardRepFilterCreateuser_IdsInput = {
  set?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type DashboardRepFilterCreateWithoutDashboardSavedViewInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  Organization?: InputMaybe<OrganizationCreateNestedOneWithoutDashboardRepFiltersInput>;
  roles?: InputMaybe<DashboardRepFilterCreaterolesInput>;
  site_ids?: InputMaybe<DashboardRepFilterCreatesite_IdsInput>;
  team_ids?: InputMaybe<DashboardRepFilterCreateteam_IdsInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user_ids?: InputMaybe<DashboardRepFilterCreateuser_IdsInput>;
};

export type DashboardRepFilterCreateWithoutOrganizationInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  DashboardSavedView: DashboardSavedViewCreateNestedOneWithoutRep_FilterInput;
  roles?: InputMaybe<DashboardRepFilterCreaterolesInput>;
  site_ids?: InputMaybe<DashboardRepFilterCreatesite_IdsInput>;
  team_ids?: InputMaybe<DashboardRepFilterCreateteam_IdsInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user_ids?: InputMaybe<DashboardRepFilterCreateuser_IdsInput>;
};

export type DashboardRepFilterListRelationFilter = {
  every?: InputMaybe<DashboardRepFilterWhereInput>;
  none?: InputMaybe<DashboardRepFilterWhereInput>;
  some?: InputMaybe<DashboardRepFilterWhereInput>;
};

/** Filter inputs for rep options  */
export type DashboardRepFilterOption = {
  roles: Array<Role>;
  site_ids: Array<Scalars['String']['input']>;
  team_ids: Array<Scalars['String']['input']>;
  user_ids: Array<Scalars['String']['input']>;
};

export type DashboardRepFilterOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
  count?: InputMaybe<SortOrder>;
};

export type DashboardRepFilterScalarWhereInput = {
  AND?: InputMaybe<Array<DashboardRepFilterScalarWhereInput>>;
  created_at?: InputMaybe<DateTimeFilter>;
  NOT?: InputMaybe<Array<DashboardRepFilterScalarWhereInput>>;
  OR?: InputMaybe<Array<DashboardRepFilterScalarWhereInput>>;
  organization_id?: InputMaybe<StringNullableFilter>;
  roles?: InputMaybe<EnumRoleNullableListFilter>;
  saved_view_id?: InputMaybe<StringFilter>;
  site_ids?: InputMaybe<StringNullableListFilter>;
  team_ids?: InputMaybe<StringNullableListFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
  user_ids?: InputMaybe<StringNullableListFilter>;
};

export type DashboardRepFilterUpdateManyMutationInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  roles?: InputMaybe<DashboardRepFilterUpdaterolesInput>;
  site_ids?: InputMaybe<DashboardRepFilterUpdatesite_IdsInput>;
  team_ids?: InputMaybe<DashboardRepFilterUpdateteam_IdsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user_ids?: InputMaybe<DashboardRepFilterUpdateuser_IdsInput>;
};

export type DashboardRepFilterUpdateManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<DashboardRepFilterWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<DashboardRepFilterCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<DashboardRepFilterCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<DashboardRepFilterCreateManyOrganizationInputEnvelope>;
  delete?: InputMaybe<Array<DashboardRepFilterWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<DashboardRepFilterScalarWhereInput>>;
  disconnect?: InputMaybe<Array<DashboardRepFilterWhereUniqueInput>>;
  set?: InputMaybe<Array<DashboardRepFilterWhereUniqueInput>>;
  update?: InputMaybe<Array<DashboardRepFilterUpdateWithWhereUniqueWithoutOrganizationInput>>;
  updateMany?: InputMaybe<Array<DashboardRepFilterUpdateManyWithWhereWithoutOrganizationInput>>;
  upsert?: InputMaybe<Array<DashboardRepFilterUpsertWithWhereUniqueWithoutOrganizationInput>>;
};

export type DashboardRepFilterUpdateManyWithWhereWithoutOrganizationInput = {
  data: DashboardRepFilterUpdateManyMutationInput;
  where: DashboardRepFilterScalarWhereInput;
};

export type DashboardRepFilterUpdateOneWithoutDashboardSavedViewInput = {
  connect?: InputMaybe<DashboardRepFilterWhereUniqueInput>;
  connectOrCreate?: InputMaybe<DashboardRepFilterCreateOrConnectWithoutDashboardSavedViewInput>;
  create?: InputMaybe<DashboardRepFilterCreateWithoutDashboardSavedViewInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<DashboardRepFilterUpdateWithoutDashboardSavedViewInput>;
  upsert?: InputMaybe<DashboardRepFilterUpsertWithoutDashboardSavedViewInput>;
};

export type DashboardRepFilterUpdaterolesInput = {
  push?: InputMaybe<Role>;
  set?: InputMaybe<Array<Role>>;
};

export type DashboardRepFilterUpdatesite_IdsInput = {
  push?: InputMaybe<Scalars['String']['input']>;
  set?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type DashboardRepFilterUpdateteam_IdsInput = {
  push?: InputMaybe<Scalars['String']['input']>;
  set?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type DashboardRepFilterUpdateuser_IdsInput = {
  push?: InputMaybe<Scalars['String']['input']>;
  set?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type DashboardRepFilterUpdateWithoutDashboardSavedViewInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  Organization?: InputMaybe<OrganizationUpdateOneWithoutDashboardRepFiltersInput>;
  roles?: InputMaybe<DashboardRepFilterUpdaterolesInput>;
  site_ids?: InputMaybe<DashboardRepFilterUpdatesite_IdsInput>;
  team_ids?: InputMaybe<DashboardRepFilterUpdateteam_IdsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user_ids?: InputMaybe<DashboardRepFilterUpdateuser_IdsInput>;
};

export type DashboardRepFilterUpdateWithoutOrganizationInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewUpdateOneRequiredWithoutRep_FilterInput>;
  roles?: InputMaybe<DashboardRepFilterUpdaterolesInput>;
  site_ids?: InputMaybe<DashboardRepFilterUpdatesite_IdsInput>;
  team_ids?: InputMaybe<DashboardRepFilterUpdateteam_IdsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user_ids?: InputMaybe<DashboardRepFilterUpdateuser_IdsInput>;
};

export type DashboardRepFilterUpdateWithWhereUniqueWithoutOrganizationInput = {
  data: DashboardRepFilterUpdateWithoutOrganizationInput;
  where: DashboardRepFilterWhereUniqueInput;
};

export type DashboardRepFilterUpsertWithoutDashboardSavedViewInput = {
  create: DashboardRepFilterCreateWithoutDashboardSavedViewInput;
  update: DashboardRepFilterUpdateWithoutDashboardSavedViewInput;
};

export type DashboardRepFilterUpsertWithWhereUniqueWithoutOrganizationInput = {
  create: DashboardRepFilterCreateWithoutOrganizationInput;
  update: DashboardRepFilterUpdateWithoutOrganizationInput;
  where: DashboardRepFilterWhereUniqueInput;
};

export type DashboardRepFilterWhereInput = {
  AND?: InputMaybe<Array<DashboardRepFilterWhereInput>>;
  created_at?: InputMaybe<DateTimeFilter>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewWhereInput>;
  NOT?: InputMaybe<Array<DashboardRepFilterWhereInput>>;
  OR?: InputMaybe<Array<DashboardRepFilterWhereInput>>;
  Organization?: InputMaybe<OrganizationWhereInput>;
  organization_id?: InputMaybe<StringNullableFilter>;
  roles?: InputMaybe<EnumRoleNullableListFilter>;
  saved_view_id?: InputMaybe<StringFilter>;
  site_ids?: InputMaybe<StringNullableListFilter>;
  team_ids?: InputMaybe<StringNullableListFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
  user_ids?: InputMaybe<StringNullableListFilter>;
};

export type DashboardRepFilterWhereUniqueInput = {
  saved_view_id?: InputMaybe<Scalars['String']['input']>;
};

export type DashboardSavedView = {
  __typename?: 'DashboardSavedView';
  column_options?: Maybe<DashboardColumnOption>;
  created_at: Scalars['DateTime']['output'];
  date_range: DateOption;
  group_option: Dashboard_Group_Option;
  id: Scalars['String']['output'];
  is_default_view: Scalars['Boolean']['output'];
  lead_filter?: Maybe<DashboardLeadFilter>;
  lowerbound_date?: Maybe<Scalars['DateTime']['output']>;
  measure_by?: Maybe<MeasureByFilter>;
  name: Scalars['String']['output'];
  rep_filter?: Maybe<DashboardRepFilter>;
  shareable: Scalars['Boolean']['output'];
  updated_at: Scalars['DateTime']['output'];
  upperbound_date?: Maybe<Scalars['DateTime']['output']>;
  user?: Maybe<User>;
  user_id?: Maybe<Scalars['String']['output']>;
};

/** Filter inputs for dashboard 2.0 */
export type DashBoardSavedViewArgument = {
  column_options: ColumnOptionArgument;
  date_filter: DashboardDateOption;
  group_option?: Dashboard_Group_Option;
  group_reference?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  lead_filter: DashboardLeadFilterOption;
  measure_by?: InputMaybe<MeasureByFilter>;
  organization_id?: InputMaybe<Scalars['String']['input']>;
  pipeline_metric_type?: InputMaybe<PipeLineMetricType>;
  rep_filter: DashboardRepFilterOption;
  shareable?: InputMaybe<Scalars['Boolean']['input']>;
};

export type DashboardSavedViewCreateManyOrganizationInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  date_range?: InputMaybe<DateOption>;
  group_option?: InputMaybe<Dashboard_Group_Option>;
  id?: InputMaybe<Scalars['String']['input']>;
  is_default_view?: InputMaybe<Scalars['Boolean']['input']>;
  lowerbound_date?: InputMaybe<Scalars['DateTime']['input']>;
  measure_by?: InputMaybe<MeasureByFilter>;
  name: Scalars['String']['input'];
  shareable?: InputMaybe<Scalars['Boolean']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  upperbound_date?: InputMaybe<Scalars['DateTime']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
};

export type DashboardSavedViewCreateManyOrganizationInputEnvelope = {
  data?: InputMaybe<Array<DashboardSavedViewCreateManyOrganizationInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type DashboardSavedViewCreateManyUserInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  date_range?: InputMaybe<DateOption>;
  group_option?: InputMaybe<Dashboard_Group_Option>;
  id?: InputMaybe<Scalars['String']['input']>;
  is_default_view?: InputMaybe<Scalars['Boolean']['input']>;
  lowerbound_date?: InputMaybe<Scalars['DateTime']['input']>;
  measure_by?: InputMaybe<MeasureByFilter>;
  name: Scalars['String']['input'];
  organization_id?: InputMaybe<Scalars['String']['input']>;
  shareable?: InputMaybe<Scalars['Boolean']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  upperbound_date?: InputMaybe<Scalars['DateTime']['input']>;
};

export type DashboardSavedViewCreateManyUserInputEnvelope = {
  data?: InputMaybe<Array<DashboardSavedViewCreateManyUserInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type DashboardSavedViewCreateNestedManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<DashboardSavedViewWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<DashboardSavedViewCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<DashboardSavedViewCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<DashboardSavedViewCreateManyOrganizationInputEnvelope>;
};

export type DashboardSavedViewCreateNestedManyWithoutUserInput = {
  connect?: InputMaybe<Array<DashboardSavedViewWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<DashboardSavedViewCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<DashboardSavedViewCreateWithoutUserInput>>;
  createMany?: InputMaybe<DashboardSavedViewCreateManyUserInputEnvelope>;
};

export type DashboardSavedViewCreateNestedOneWithoutColumn_OptionsInput = {
  connect?: InputMaybe<DashboardSavedViewWhereUniqueInput>;
  connectOrCreate?: InputMaybe<DashboardSavedViewCreateOrConnectWithoutColumn_OptionsInput>;
  create?: InputMaybe<DashboardSavedViewCreateWithoutColumn_OptionsInput>;
};

export type DashboardSavedViewCreateNestedOneWithoutLead_FilterInput = {
  connect?: InputMaybe<DashboardSavedViewWhereUniqueInput>;
  connectOrCreate?: InputMaybe<DashboardSavedViewCreateOrConnectWithoutLead_FilterInput>;
  create?: InputMaybe<DashboardSavedViewCreateWithoutLead_FilterInput>;
};

export type DashboardSavedViewCreateNestedOneWithoutRep_FilterInput = {
  connect?: InputMaybe<DashboardSavedViewWhereUniqueInput>;
  connectOrCreate?: InputMaybe<DashboardSavedViewCreateOrConnectWithoutRep_FilterInput>;
  create?: InputMaybe<DashboardSavedViewCreateWithoutRep_FilterInput>;
};

export type DashboardSavedViewCreateOrConnectWithoutColumn_OptionsInput = {
  create: DashboardSavedViewCreateWithoutColumn_OptionsInput;
  where: DashboardSavedViewWhereUniqueInput;
};

export type DashboardSavedViewCreateOrConnectWithoutLead_FilterInput = {
  create: DashboardSavedViewCreateWithoutLead_FilterInput;
  where: DashboardSavedViewWhereUniqueInput;
};

export type DashboardSavedViewCreateOrConnectWithoutOrganizationInput = {
  create: DashboardSavedViewCreateWithoutOrganizationInput;
  where: DashboardSavedViewWhereUniqueInput;
};

export type DashboardSavedViewCreateOrConnectWithoutRep_FilterInput = {
  create: DashboardSavedViewCreateWithoutRep_FilterInput;
  where: DashboardSavedViewWhereUniqueInput;
};

export type DashboardSavedViewCreateOrConnectWithoutUserInput = {
  create: DashboardSavedViewCreateWithoutUserInput;
  where: DashboardSavedViewWhereUniqueInput;
};

export type DashboardSavedViewCreateWithoutColumn_OptionsInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  date_range?: InputMaybe<DateOption>;
  group_option?: InputMaybe<Dashboard_Group_Option>;
  id?: InputMaybe<Scalars['String']['input']>;
  is_default_view?: InputMaybe<Scalars['Boolean']['input']>;
  lead_filter?: InputMaybe<DashboardLeadFilterCreateNestedOneWithoutDashboardSavedViewInput>;
  lowerbound_date?: InputMaybe<Scalars['DateTime']['input']>;
  measure_by?: InputMaybe<MeasureByFilter>;
  name: Scalars['String']['input'];
  Organization?: InputMaybe<OrganizationCreateNestedOneWithoutDashboardSavedViewInput>;
  rep_filter?: InputMaybe<DashboardRepFilterCreateNestedOneWithoutDashboardSavedViewInput>;
  shareable?: InputMaybe<Scalars['Boolean']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  upperbound_date?: InputMaybe<Scalars['DateTime']['input']>;
  user?: InputMaybe<UserCreateNestedOneWithoutDashboardSavedViewInput>;
};

export type DashboardSavedViewCreateWithoutLead_FilterInput = {
  column_options?: InputMaybe<DashboardColumnOptionCreateNestedOneWithoutDashboardSavedViewInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  date_range?: InputMaybe<DateOption>;
  group_option?: InputMaybe<Dashboard_Group_Option>;
  id?: InputMaybe<Scalars['String']['input']>;
  is_default_view?: InputMaybe<Scalars['Boolean']['input']>;
  lowerbound_date?: InputMaybe<Scalars['DateTime']['input']>;
  measure_by?: InputMaybe<MeasureByFilter>;
  name: Scalars['String']['input'];
  Organization?: InputMaybe<OrganizationCreateNestedOneWithoutDashboardSavedViewInput>;
  rep_filter?: InputMaybe<DashboardRepFilterCreateNestedOneWithoutDashboardSavedViewInput>;
  shareable?: InputMaybe<Scalars['Boolean']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  upperbound_date?: InputMaybe<Scalars['DateTime']['input']>;
  user?: InputMaybe<UserCreateNestedOneWithoutDashboardSavedViewInput>;
};

export type DashboardSavedViewCreateWithoutOrganizationInput = {
  column_options?: InputMaybe<DashboardColumnOptionCreateNestedOneWithoutDashboardSavedViewInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  date_range?: InputMaybe<DateOption>;
  group_option?: InputMaybe<Dashboard_Group_Option>;
  id?: InputMaybe<Scalars['String']['input']>;
  is_default_view?: InputMaybe<Scalars['Boolean']['input']>;
  lead_filter?: InputMaybe<DashboardLeadFilterCreateNestedOneWithoutDashboardSavedViewInput>;
  lowerbound_date?: InputMaybe<Scalars['DateTime']['input']>;
  measure_by?: InputMaybe<MeasureByFilter>;
  name: Scalars['String']['input'];
  rep_filter?: InputMaybe<DashboardRepFilterCreateNestedOneWithoutDashboardSavedViewInput>;
  shareable?: InputMaybe<Scalars['Boolean']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  upperbound_date?: InputMaybe<Scalars['DateTime']['input']>;
  user?: InputMaybe<UserCreateNestedOneWithoutDashboardSavedViewInput>;
};

export type DashboardSavedViewCreateWithoutRep_FilterInput = {
  column_options?: InputMaybe<DashboardColumnOptionCreateNestedOneWithoutDashboardSavedViewInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  date_range?: InputMaybe<DateOption>;
  group_option?: InputMaybe<Dashboard_Group_Option>;
  id?: InputMaybe<Scalars['String']['input']>;
  is_default_view?: InputMaybe<Scalars['Boolean']['input']>;
  lead_filter?: InputMaybe<DashboardLeadFilterCreateNestedOneWithoutDashboardSavedViewInput>;
  lowerbound_date?: InputMaybe<Scalars['DateTime']['input']>;
  measure_by?: InputMaybe<MeasureByFilter>;
  name: Scalars['String']['input'];
  Organization?: InputMaybe<OrganizationCreateNestedOneWithoutDashboardSavedViewInput>;
  shareable?: InputMaybe<Scalars['Boolean']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  upperbound_date?: InputMaybe<Scalars['DateTime']['input']>;
  user?: InputMaybe<UserCreateNestedOneWithoutDashboardSavedViewInput>;
};

export type DashboardSavedViewCreateWithoutUserInput = {
  column_options?: InputMaybe<DashboardColumnOptionCreateNestedOneWithoutDashboardSavedViewInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  date_range?: InputMaybe<DateOption>;
  group_option?: InputMaybe<Dashboard_Group_Option>;
  id?: InputMaybe<Scalars['String']['input']>;
  is_default_view?: InputMaybe<Scalars['Boolean']['input']>;
  lead_filter?: InputMaybe<DashboardLeadFilterCreateNestedOneWithoutDashboardSavedViewInput>;
  lowerbound_date?: InputMaybe<Scalars['DateTime']['input']>;
  measure_by?: InputMaybe<MeasureByFilter>;
  name: Scalars['String']['input'];
  Organization?: InputMaybe<OrganizationCreateNestedOneWithoutDashboardSavedViewInput>;
  rep_filter?: InputMaybe<DashboardRepFilterCreateNestedOneWithoutDashboardSavedViewInput>;
  shareable?: InputMaybe<Scalars['Boolean']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  upperbound_date?: InputMaybe<Scalars['DateTime']['input']>;
};

export type DashboardSavedViewListRelationFilter = {
  every?: InputMaybe<DashboardSavedViewWhereInput>;
  none?: InputMaybe<DashboardSavedViewWhereInput>;
  some?: InputMaybe<DashboardSavedViewWhereInput>;
};

export type DashboardSavedViewOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
  count?: InputMaybe<SortOrder>;
};

export type DashboardSavedViewScalarWhereInput = {
  AND?: InputMaybe<Array<DashboardSavedViewScalarWhereInput>>;
  created_at?: InputMaybe<DateTimeFilter>;
  date_range?: InputMaybe<EnumDateOptionFilter>;
  group_option?: InputMaybe<EnumDashboard_Group_OptionFilter>;
  id?: InputMaybe<StringFilter>;
  is_default_view?: InputMaybe<BoolFilter>;
  lowerbound_date?: InputMaybe<DateTimeNullableFilter>;
  measure_by?: InputMaybe<EnumMeasureByFilterNullableFilter>;
  name?: InputMaybe<StringFilter>;
  NOT?: InputMaybe<Array<DashboardSavedViewScalarWhereInput>>;
  OR?: InputMaybe<Array<DashboardSavedViewScalarWhereInput>>;
  organization_id?: InputMaybe<StringNullableFilter>;
  shareable?: InputMaybe<BoolFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
  upperbound_date?: InputMaybe<DateTimeNullableFilter>;
  user_id?: InputMaybe<StringNullableFilter>;
};

export type DashboardSavedViewUpdateManyMutationInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  date_range?: InputMaybe<EnumDateOptionFieldUpdateOperationsInput>;
  group_option?: InputMaybe<EnumDashboard_Group_OptionFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  is_default_view?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lowerbound_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  measure_by?: InputMaybe<NullableEnumMeasureByFilterFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  shareable?: InputMaybe<BoolFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  upperbound_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type DashboardSavedViewUpdateManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<DashboardSavedViewWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<DashboardSavedViewCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<DashboardSavedViewCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<DashboardSavedViewCreateManyOrganizationInputEnvelope>;
  delete?: InputMaybe<Array<DashboardSavedViewWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<DashboardSavedViewScalarWhereInput>>;
  disconnect?: InputMaybe<Array<DashboardSavedViewWhereUniqueInput>>;
  set?: InputMaybe<Array<DashboardSavedViewWhereUniqueInput>>;
  update?: InputMaybe<Array<DashboardSavedViewUpdateWithWhereUniqueWithoutOrganizationInput>>;
  updateMany?: InputMaybe<Array<DashboardSavedViewUpdateManyWithWhereWithoutOrganizationInput>>;
  upsert?: InputMaybe<Array<DashboardSavedViewUpsertWithWhereUniqueWithoutOrganizationInput>>;
};

export type DashboardSavedViewUpdateManyWithoutUserInput = {
  connect?: InputMaybe<Array<DashboardSavedViewWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<DashboardSavedViewCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<DashboardSavedViewCreateWithoutUserInput>>;
  createMany?: InputMaybe<DashboardSavedViewCreateManyUserInputEnvelope>;
  delete?: InputMaybe<Array<DashboardSavedViewWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<DashboardSavedViewScalarWhereInput>>;
  disconnect?: InputMaybe<Array<DashboardSavedViewWhereUniqueInput>>;
  set?: InputMaybe<Array<DashboardSavedViewWhereUniqueInput>>;
  update?: InputMaybe<Array<DashboardSavedViewUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: InputMaybe<Array<DashboardSavedViewUpdateManyWithWhereWithoutUserInput>>;
  upsert?: InputMaybe<Array<DashboardSavedViewUpsertWithWhereUniqueWithoutUserInput>>;
};

export type DashboardSavedViewUpdateManyWithWhereWithoutOrganizationInput = {
  data: DashboardSavedViewUpdateManyMutationInput;
  where: DashboardSavedViewScalarWhereInput;
};

export type DashboardSavedViewUpdateManyWithWhereWithoutUserInput = {
  data: DashboardSavedViewUpdateManyMutationInput;
  where: DashboardSavedViewScalarWhereInput;
};

export type DashboardSavedViewUpdateOneRequiredWithoutColumn_OptionsInput = {
  connect?: InputMaybe<DashboardSavedViewWhereUniqueInput>;
  connectOrCreate?: InputMaybe<DashboardSavedViewCreateOrConnectWithoutColumn_OptionsInput>;
  create?: InputMaybe<DashboardSavedViewCreateWithoutColumn_OptionsInput>;
  update?: InputMaybe<DashboardSavedViewUpdateWithoutColumn_OptionsInput>;
  upsert?: InputMaybe<DashboardSavedViewUpsertWithoutColumn_OptionsInput>;
};

export type DashboardSavedViewUpdateOneRequiredWithoutLead_FilterInput = {
  connect?: InputMaybe<DashboardSavedViewWhereUniqueInput>;
  connectOrCreate?: InputMaybe<DashboardSavedViewCreateOrConnectWithoutLead_FilterInput>;
  create?: InputMaybe<DashboardSavedViewCreateWithoutLead_FilterInput>;
  update?: InputMaybe<DashboardSavedViewUpdateWithoutLead_FilterInput>;
  upsert?: InputMaybe<DashboardSavedViewUpsertWithoutLead_FilterInput>;
};

export type DashboardSavedViewUpdateOneRequiredWithoutRep_FilterInput = {
  connect?: InputMaybe<DashboardSavedViewWhereUniqueInput>;
  connectOrCreate?: InputMaybe<DashboardSavedViewCreateOrConnectWithoutRep_FilterInput>;
  create?: InputMaybe<DashboardSavedViewCreateWithoutRep_FilterInput>;
  update?: InputMaybe<DashboardSavedViewUpdateWithoutRep_FilterInput>;
  upsert?: InputMaybe<DashboardSavedViewUpsertWithoutRep_FilterInput>;
};

export type DashboardSavedViewUpdateWithoutColumn_OptionsInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  date_range?: InputMaybe<EnumDateOptionFieldUpdateOperationsInput>;
  group_option?: InputMaybe<EnumDashboard_Group_OptionFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  is_default_view?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lead_filter?: InputMaybe<DashboardLeadFilterUpdateOneWithoutDashboardSavedViewInput>;
  lowerbound_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  measure_by?: InputMaybe<NullableEnumMeasureByFilterFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  Organization?: InputMaybe<OrganizationUpdateOneWithoutDashboardSavedViewInput>;
  rep_filter?: InputMaybe<DashboardRepFilterUpdateOneWithoutDashboardSavedViewInput>;
  shareable?: InputMaybe<BoolFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  upperbound_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneWithoutDashboardSavedViewInput>;
};

export type DashboardSavedViewUpdateWithoutLead_FilterInput = {
  column_options?: InputMaybe<DashboardColumnOptionUpdateOneWithoutDashboardSavedViewInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  date_range?: InputMaybe<EnumDateOptionFieldUpdateOperationsInput>;
  group_option?: InputMaybe<EnumDashboard_Group_OptionFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  is_default_view?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lowerbound_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  measure_by?: InputMaybe<NullableEnumMeasureByFilterFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  Organization?: InputMaybe<OrganizationUpdateOneWithoutDashboardSavedViewInput>;
  rep_filter?: InputMaybe<DashboardRepFilterUpdateOneWithoutDashboardSavedViewInput>;
  shareable?: InputMaybe<BoolFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  upperbound_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneWithoutDashboardSavedViewInput>;
};

export type DashboardSavedViewUpdateWithoutOrganizationInput = {
  column_options?: InputMaybe<DashboardColumnOptionUpdateOneWithoutDashboardSavedViewInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  date_range?: InputMaybe<EnumDateOptionFieldUpdateOperationsInput>;
  group_option?: InputMaybe<EnumDashboard_Group_OptionFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  is_default_view?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lead_filter?: InputMaybe<DashboardLeadFilterUpdateOneWithoutDashboardSavedViewInput>;
  lowerbound_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  measure_by?: InputMaybe<NullableEnumMeasureByFilterFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  rep_filter?: InputMaybe<DashboardRepFilterUpdateOneWithoutDashboardSavedViewInput>;
  shareable?: InputMaybe<BoolFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  upperbound_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneWithoutDashboardSavedViewInput>;
};

export type DashboardSavedViewUpdateWithoutRep_FilterInput = {
  column_options?: InputMaybe<DashboardColumnOptionUpdateOneWithoutDashboardSavedViewInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  date_range?: InputMaybe<EnumDateOptionFieldUpdateOperationsInput>;
  group_option?: InputMaybe<EnumDashboard_Group_OptionFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  is_default_view?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lead_filter?: InputMaybe<DashboardLeadFilterUpdateOneWithoutDashboardSavedViewInput>;
  lowerbound_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  measure_by?: InputMaybe<NullableEnumMeasureByFilterFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  Organization?: InputMaybe<OrganizationUpdateOneWithoutDashboardSavedViewInput>;
  shareable?: InputMaybe<BoolFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  upperbound_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneWithoutDashboardSavedViewInput>;
};

export type DashboardSavedViewUpdateWithoutUserInput = {
  column_options?: InputMaybe<DashboardColumnOptionUpdateOneWithoutDashboardSavedViewInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  date_range?: InputMaybe<EnumDateOptionFieldUpdateOperationsInput>;
  group_option?: InputMaybe<EnumDashboard_Group_OptionFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  is_default_view?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lead_filter?: InputMaybe<DashboardLeadFilterUpdateOneWithoutDashboardSavedViewInput>;
  lowerbound_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  measure_by?: InputMaybe<NullableEnumMeasureByFilterFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  Organization?: InputMaybe<OrganizationUpdateOneWithoutDashboardSavedViewInput>;
  rep_filter?: InputMaybe<DashboardRepFilterUpdateOneWithoutDashboardSavedViewInput>;
  shareable?: InputMaybe<BoolFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  upperbound_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type DashboardSavedViewUpdateWithWhereUniqueWithoutOrganizationInput = {
  data: DashboardSavedViewUpdateWithoutOrganizationInput;
  where: DashboardSavedViewWhereUniqueInput;
};

export type DashboardSavedViewUpdateWithWhereUniqueWithoutUserInput = {
  data: DashboardSavedViewUpdateWithoutUserInput;
  where: DashboardSavedViewWhereUniqueInput;
};

export type DashboardSavedViewUpsertWithoutColumn_OptionsInput = {
  create: DashboardSavedViewCreateWithoutColumn_OptionsInput;
  update: DashboardSavedViewUpdateWithoutColumn_OptionsInput;
};

export type DashboardSavedViewUpsertWithoutLead_FilterInput = {
  create: DashboardSavedViewCreateWithoutLead_FilterInput;
  update: DashboardSavedViewUpdateWithoutLead_FilterInput;
};

export type DashboardSavedViewUpsertWithoutRep_FilterInput = {
  create: DashboardSavedViewCreateWithoutRep_FilterInput;
  update: DashboardSavedViewUpdateWithoutRep_FilterInput;
};

export type DashboardSavedViewUpsertWithWhereUniqueWithoutOrganizationInput = {
  create: DashboardSavedViewCreateWithoutOrganizationInput;
  update: DashboardSavedViewUpdateWithoutOrganizationInput;
  where: DashboardSavedViewWhereUniqueInput;
};

export type DashboardSavedViewUpsertWithWhereUniqueWithoutUserInput = {
  create: DashboardSavedViewCreateWithoutUserInput;
  update: DashboardSavedViewUpdateWithoutUserInput;
  where: DashboardSavedViewWhereUniqueInput;
};

export type DashboardSavedViewWhereInput = {
  AND?: InputMaybe<Array<DashboardSavedViewWhereInput>>;
  column_options?: InputMaybe<DashboardColumnOptionWhereInput>;
  created_at?: InputMaybe<DateTimeFilter>;
  date_range?: InputMaybe<EnumDateOptionFilter>;
  group_option?: InputMaybe<EnumDashboard_Group_OptionFilter>;
  id?: InputMaybe<StringFilter>;
  is_default_view?: InputMaybe<BoolFilter>;
  lead_filter?: InputMaybe<DashboardLeadFilterWhereInput>;
  lowerbound_date?: InputMaybe<DateTimeNullableFilter>;
  measure_by?: InputMaybe<EnumMeasureByFilterNullableFilter>;
  name?: InputMaybe<StringFilter>;
  NOT?: InputMaybe<Array<DashboardSavedViewWhereInput>>;
  OR?: InputMaybe<Array<DashboardSavedViewWhereInput>>;
  Organization?: InputMaybe<OrganizationWhereInput>;
  organization_id?: InputMaybe<StringNullableFilter>;
  rep_filter?: InputMaybe<DashboardRepFilterWhereInput>;
  shareable?: InputMaybe<BoolFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
  upperbound_date?: InputMaybe<DateTimeNullableFilter>;
  user?: InputMaybe<UserWhereInput>;
  user_id?: InputMaybe<StringNullableFilter>;
};

export type DashboardSavedViewWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']['input']>;
};

/** The Main Dashboard Tab type */
export enum DashboardType {
  Activity = 'Activity',
  Coaching = 'Coaching',
  Event = 'Event',
  Pipeline = 'Pipeline',
  Reporting = 'Reporting',
  Transfer = 'Transfer',
  TransferQuality = 'TransferQuality'
}

export enum DateOption {
  CustomRange = 'CustomRange',
  LastMonth = 'LastMonth',
  LastThreeMonths = 'LastThreeMonths',
  LastWeek = 'LastWeek',
  LastYear = 'LastYear',
  MonthToDate = 'MonthToDate',
  ThisWeek = 'ThisWeek',
  Today = 'Today',
  YearToDate = 'YearToDate',
  Yesterday = 'Yesterday'
}

export type DateTimeFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars['DateTime']['input']>;
};

export type DateTimeFilter = {
  equals?: InputMaybe<Scalars['DateTime']['input']>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  not?: InputMaybe<NestedDateTimeFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']['input']>>;
};

export type DateTimeNullableFilter = {
  equals?: InputMaybe<Scalars['DateTime']['input']>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  not?: InputMaybe<NestedDateTimeNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']['input']>>;
};

export type DialerActionLog = {
  __typename?: 'DialerActionLog';
  dialer_action_id: Scalars['String']['output'];
  dml_created_by?: Maybe<Scalars['String']['output']>;
  dml_timestamp: Scalars['DateTime']['output'];
  dml_type: Dml_Type;
  new_row_data?: Maybe<Scalars['Json']['output']>;
  old_row_data?: Maybe<Scalars['Json']['output']>;
};

export type DialerActions = {
  __typename?: 'DialerActions';
  description?: Maybe<Scalars['String']['output']>;
  event_type: Scalars['String']['output'];
  id: Scalars['String']['output'];
  label: Scalars['String']['output'];
  phase: Scalars['String']['output'];
  show_on_dialer: Scalars['Boolean']['output'];
};

/** Available option for sorting Call Report List in coaching view */
export enum DialSortOption {
  HighestPdv = 'HighestPDV',
  LastSinceDialed = 'LastSinceDialed',
  LowestPdv = 'LowestPDV',
  MostRecentDial = 'MostRecentDial',
  MostRecentlyAdded = 'MostRecentlyAdded'
}

export type Disposition = {
  __typename?: 'Disposition';
  active: Scalars['Boolean']['output'];
  associated_action?: Maybe<Scalars['String']['output']>;
  belongs_to_action: Scalars['Boolean']['output'];
  /** @deprecated Deprecated in favor of lead.close */
  closed?: Maybe<Scalars['Boolean']['output']>;
  completed_image?: Maybe<Scalars['String']['output']>;
  completed_text?: Maybe<Scalars['String']['output']>;
  completed_tip?: Maybe<Scalars['String']['output']>;
  computed_call_history_theme?: Maybe<DispositionComputedTheme>;
  conferences: Array<Conference>;
  created_at: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  duplicate_disposition?: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated Deprecated in favor of lead.held */
  held?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['String']['output'];
  label?: Maybe<Scalars['String']['output']>;
  lead_activities: Array<LeadActivity>;
  next_implied_action: Hingepoint;
  next_phase?: Maybe<Phase>;
  next_phase_label?: Maybe<Scalars['String']['output']>;
  order?: Maybe<Scalars['Int']['output']>;
  organization?: Maybe<Organization>;
  organization_id?: Maybe<Scalars['String']['output']>;
  person_spoke_to?: Maybe<Callresult>;
  phase?: Maybe<Phase>;
  phase_label?: Maybe<Scalars['String']['output']>;
  resting_day?: Maybe<Scalars['Float']['output']>;
  /** @deprecated Deprecated in favor of lead.set */
  set?: Maybe<Scalars['Boolean']['output']>;
  type: Dispositiontype;
  updated_at: Scalars['DateTime']['output'];
};


export type DispositionConferencesArgs = {
  after?: InputMaybe<ConferenceWhereUniqueInput>;
  before?: InputMaybe<ConferenceWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type DispositionLead_ActivitiesArgs = {
  after?: InputMaybe<LeadActivityWhereUniqueInput>;
  before?: InputMaybe<LeadActivityWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type DispositionChange = {
  __typename?: 'DispositionChange';
  change_type: DispositionChangeType;
  created_at: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  lead_activity_id: Scalars['String']['output'];
  new_disposition_id: Scalars['String']['output'];
  new_rep_id: Scalars['String']['output'];
  old_disposition_id: Scalars['String']['output'];
  old_rep_id: Scalars['String']['output'];
  updated_at: Scalars['DateTime']['output'];
  updated_user_id: Scalars['String']['output'];
};

export type DispositionChangeResponse = {
  __typename?: 'DispositionChangeResponse';
  disposition_id?: Maybe<Scalars['String']['output']>;
  label?: Maybe<Scalars['String']['output']>;
};

export enum DispositionChangeType {
  RemoveDisposition = 'RemoveDisposition',
  Type = 'Type',
  User = 'User'
}

/** computed field that defines styles for the updated lead history card */
export type DispositionComputedTheme = {
  __typename?: 'dispositionComputedTheme';
  call_result_theme?: Maybe<DispositionThemeType>;
  completed_with_theme?: Maybe<DispositionThemeType>;
};

export type DispositionCreateManyOrganizationInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  associated_action?: InputMaybe<Scalars['String']['input']>;
  belongs_to_action?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  duplicate_disposition?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  next_implied_action?: InputMaybe<Hingepoint>;
  next_phase?: InputMaybe<Phase>;
  order?: InputMaybe<Scalars['Int']['input']>;
  person_spoke_to?: InputMaybe<Callresult>;
  phase?: InputMaybe<Phase>;
  type?: InputMaybe<Dispositiontype>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  visible?: InputMaybe<Scalars['Boolean']['input']>;
};

export type DispositionCreateManyOrganizationInputEnvelope = {
  data?: InputMaybe<Array<DispositionCreateManyOrganizationInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type DispositionCreateNestedManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<DispositionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<DispositionCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<DispositionCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<DispositionCreateManyOrganizationInputEnvelope>;
};

export type DispositionCreateNestedOneWithoutConferencesInput = {
  connect?: InputMaybe<DispositionWhereUniqueInput>;
  connectOrCreate?: InputMaybe<DispositionCreateOrConnectWithoutConferencesInput>;
  create?: InputMaybe<DispositionCreateWithoutConferencesInput>;
};

export type DispositionCreateNestedOneWithoutLead_ActivitiesInput = {
  connect?: InputMaybe<DispositionWhereUniqueInput>;
  connectOrCreate?: InputMaybe<DispositionCreateOrConnectWithoutLead_ActivitiesInput>;
  create?: InputMaybe<DispositionCreateWithoutLead_ActivitiesInput>;
};

export type DispositionCreateOrConnectWithoutConferencesInput = {
  create: DispositionCreateWithoutConferencesInput;
  where: DispositionWhereUniqueInput;
};

export type DispositionCreateOrConnectWithoutLead_ActivitiesInput = {
  create: DispositionCreateWithoutLead_ActivitiesInput;
  where: DispositionWhereUniqueInput;
};

export type DispositionCreateOrConnectWithoutOrganizationInput = {
  create: DispositionCreateWithoutOrganizationInput;
  where: DispositionWhereUniqueInput;
};

export type DispositionCreateWithoutConferencesInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  associated_action?: InputMaybe<Scalars['String']['input']>;
  belongs_to_action?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  duplicate_disposition?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutRelated_DispositionInput>;
  next_implied_action?: InputMaybe<Hingepoint>;
  next_phase?: InputMaybe<Phase>;
  order?: InputMaybe<Scalars['Int']['input']>;
  organization?: InputMaybe<OrganizationCreateNestedOneWithoutDispositionsInput>;
  person_spoke_to?: InputMaybe<Callresult>;
  phase?: InputMaybe<Phase>;
  type?: InputMaybe<Dispositiontype>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  visible?: InputMaybe<Scalars['Boolean']['input']>;
};

export type DispositionCreateWithoutLead_ActivitiesInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  associated_action?: InputMaybe<Scalars['String']['input']>;
  belongs_to_action?: InputMaybe<Scalars['Boolean']['input']>;
  conferences?: InputMaybe<ConferenceCreateNestedManyWithoutCall_ResultInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  duplicate_disposition?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  next_implied_action?: InputMaybe<Hingepoint>;
  next_phase?: InputMaybe<Phase>;
  order?: InputMaybe<Scalars['Int']['input']>;
  organization?: InputMaybe<OrganizationCreateNestedOneWithoutDispositionsInput>;
  person_spoke_to?: InputMaybe<Callresult>;
  phase?: InputMaybe<Phase>;
  type?: InputMaybe<Dispositiontype>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  visible?: InputMaybe<Scalars['Boolean']['input']>;
};

export type DispositionCreateWithoutOrganizationInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  associated_action?: InputMaybe<Scalars['String']['input']>;
  belongs_to_action?: InputMaybe<Scalars['Boolean']['input']>;
  conferences?: InputMaybe<ConferenceCreateNestedManyWithoutCall_ResultInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  duplicate_disposition?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutRelated_DispositionInput>;
  next_implied_action?: InputMaybe<Hingepoint>;
  next_phase?: InputMaybe<Phase>;
  order?: InputMaybe<Scalars['Int']['input']>;
  person_spoke_to?: InputMaybe<Callresult>;
  phase?: InputMaybe<Phase>;
  type?: InputMaybe<Dispositiontype>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  visible?: InputMaybe<Scalars['Boolean']['input']>;
};

export type DispositionListRelationFilter = {
  every?: InputMaybe<DispositionWhereInput>;
  none?: InputMaybe<DispositionWhereInput>;
  some?: InputMaybe<DispositionWhereInput>;
};

export type DispositionLog = {
  __typename?: 'DispositionLog';
  disposition_id: Scalars['String']['output'];
  dml_created_by?: Maybe<Scalars['String']['output']>;
  dml_timestamp: Scalars['DateTime']['output'];
  dml_type: Dml_Type;
  new_row_data?: Maybe<Scalars['Json']['output']>;
  old_row_data?: Maybe<Scalars['Json']['output']>;
};

export type DispositionMultiplier = {
  __typename?: 'DispositionMultiplier';
  action: Scalars['String']['output'];
  actual_actions_count: Scalars['Int']['output'];
  callback_type: CallbackType;
  computed_rate: Scalars['Float']['output'];
  current_data_points: Scalars['Int']['output'];
  default_rate: Scalars['Float']['output'];
  disposition_type: Dispositiontype;
  hinge_point: HingePointMultiplier;
  HP_type: Hingepoint;
  min_number_data_points: Scalars['Int']['output'];
  organization: Organization;
  organization_id: Scalars['String']['output'];
  possible_actions_count: Scalars['Int']['output'];
  rate?: Maybe<Scalars['Float']['output']>;
  use_default: Scalars['Boolean']['output'];
};

export type DispositionMultiplierCreateManyHinge_PointInput = {
  action?: InputMaybe<Scalars['String']['input']>;
  actual_actions_count?: InputMaybe<Scalars['Int']['input']>;
  callback_type?: InputMaybe<CallbackType>;
  computed_rate: Scalars['Float']['input'];
  current_data_points?: InputMaybe<Scalars['Int']['input']>;
  default_rate: Scalars['Float']['input'];
  disposition_type: Dispositiontype;
  min_number_data_points?: InputMaybe<Scalars['Int']['input']>;
  possible_actions_count?: InputMaybe<Scalars['Int']['input']>;
  use_default?: InputMaybe<Scalars['Boolean']['input']>;
};

export type DispositionMultiplierCreateManyHinge_PointInputEnvelope = {
  data?: InputMaybe<Array<DispositionMultiplierCreateManyHinge_PointInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type DispositionMultiplierCreateManyOrganizationInput = {
  action?: InputMaybe<Scalars['String']['input']>;
  actual_actions_count?: InputMaybe<Scalars['Int']['input']>;
  callback_type?: InputMaybe<CallbackType>;
  computed_rate: Scalars['Float']['input'];
  current_data_points?: InputMaybe<Scalars['Int']['input']>;
  default_rate: Scalars['Float']['input'];
  disposition_type: Dispositiontype;
  HP_type: Hingepoint;
  min_number_data_points?: InputMaybe<Scalars['Int']['input']>;
  possible_actions_count?: InputMaybe<Scalars['Int']['input']>;
  use_default?: InputMaybe<Scalars['Boolean']['input']>;
};

export type DispositionMultiplierCreateManyOrganizationInputEnvelope = {
  data?: InputMaybe<Array<DispositionMultiplierCreateManyOrganizationInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type DispositionMultiplierCreateNestedManyWithoutHinge_PointInput = {
  connect?: InputMaybe<Array<DispositionMultiplierWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<DispositionMultiplierCreateOrConnectWithoutHinge_PointInput>>;
  create?: InputMaybe<Array<DispositionMultiplierCreateWithoutHinge_PointInput>>;
  createMany?: InputMaybe<DispositionMultiplierCreateManyHinge_PointInputEnvelope>;
};

export type DispositionMultiplierCreateNestedManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<DispositionMultiplierWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<DispositionMultiplierCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<DispositionMultiplierCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<DispositionMultiplierCreateManyOrganizationInputEnvelope>;
};

export type DispositionMultiplierCreateOrConnectWithoutHinge_PointInput = {
  create: DispositionMultiplierCreateWithoutHinge_PointInput;
  where: DispositionMultiplierWhereUniqueInput;
};

export type DispositionMultiplierCreateOrConnectWithoutOrganizationInput = {
  create: DispositionMultiplierCreateWithoutOrganizationInput;
  where: DispositionMultiplierWhereUniqueInput;
};

export type DispositionMultiplierCreateWithoutHinge_PointInput = {
  action?: InputMaybe<Scalars['String']['input']>;
  actual_actions_count?: InputMaybe<Scalars['Int']['input']>;
  callback_type?: InputMaybe<CallbackType>;
  computed_rate: Scalars['Float']['input'];
  current_data_points?: InputMaybe<Scalars['Int']['input']>;
  default_rate: Scalars['Float']['input'];
  disposition_type: Dispositiontype;
  min_number_data_points?: InputMaybe<Scalars['Int']['input']>;
  organization: OrganizationCreateNestedOneWithoutDispositionMultiplierInput;
  possible_actions_count?: InputMaybe<Scalars['Int']['input']>;
  use_default?: InputMaybe<Scalars['Boolean']['input']>;
};

export type DispositionMultiplierCreateWithoutOrganizationInput = {
  action?: InputMaybe<Scalars['String']['input']>;
  actual_actions_count?: InputMaybe<Scalars['Int']['input']>;
  callback_type?: InputMaybe<CallbackType>;
  computed_rate: Scalars['Float']['input'];
  current_data_points?: InputMaybe<Scalars['Int']['input']>;
  default_rate: Scalars['Float']['input'];
  disposition_type: Dispositiontype;
  hinge_point: HingePointMultiplierCreateNestedOneWithoutDispositionMultiplierInput;
  min_number_data_points?: InputMaybe<Scalars['Int']['input']>;
  possible_actions_count?: InputMaybe<Scalars['Int']['input']>;
  use_default?: InputMaybe<Scalars['Boolean']['input']>;
};

export type DispositionMultiplierDisposition_IdCompoundUniqueInput = {
  action: Scalars['String']['input'];
  callback_type: CallbackType;
  disposition_type: Dispositiontype;
  HP_type: Hingepoint;
  organization_id: Scalars['String']['input'];
};

export type DispositionMultiplierListRelationFilter = {
  every?: InputMaybe<DispositionMultiplierWhereInput>;
  none?: InputMaybe<DispositionMultiplierWhereInput>;
  some?: InputMaybe<DispositionMultiplierWhereInput>;
};

export type DispositionMultiplierOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
  count?: InputMaybe<SortOrder>;
};

export type DispositionMultiplierScalarWhereInput = {
  action?: InputMaybe<StringFilter>;
  actual_actions_count?: InputMaybe<IntFilter>;
  AND?: InputMaybe<Array<DispositionMultiplierScalarWhereInput>>;
  callback_type?: InputMaybe<EnumCallbackTypeFilter>;
  computed_rate?: InputMaybe<FloatFilter>;
  current_data_points?: InputMaybe<IntFilter>;
  default_rate?: InputMaybe<FloatFilter>;
  disposition_type?: InputMaybe<EnumDispositiontypeFilter>;
  HP_type?: InputMaybe<EnumHingepointFilter>;
  min_number_data_points?: InputMaybe<IntFilter>;
  NOT?: InputMaybe<Array<DispositionMultiplierScalarWhereInput>>;
  OR?: InputMaybe<Array<DispositionMultiplierScalarWhereInput>>;
  organization_id?: InputMaybe<StringFilter>;
  possible_actions_count?: InputMaybe<IntFilter>;
  use_default?: InputMaybe<BoolFilter>;
};

export type DispositionMultiplierUpdateManyMutationInput = {
  action?: InputMaybe<StringFieldUpdateOperationsInput>;
  actual_actions_count?: InputMaybe<IntFieldUpdateOperationsInput>;
  callback_type?: InputMaybe<EnumCallbackTypeFieldUpdateOperationsInput>;
  computed_rate?: InputMaybe<FloatFieldUpdateOperationsInput>;
  current_data_points?: InputMaybe<IntFieldUpdateOperationsInput>;
  default_rate?: InputMaybe<FloatFieldUpdateOperationsInput>;
  disposition_type?: InputMaybe<EnumDispositiontypeFieldUpdateOperationsInput>;
  min_number_data_points?: InputMaybe<IntFieldUpdateOperationsInput>;
  possible_actions_count?: InputMaybe<IntFieldUpdateOperationsInput>;
  use_default?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type DispositionMultiplierUpdateManyWithoutHinge_PointInput = {
  connect?: InputMaybe<Array<DispositionMultiplierWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<DispositionMultiplierCreateOrConnectWithoutHinge_PointInput>>;
  create?: InputMaybe<Array<DispositionMultiplierCreateWithoutHinge_PointInput>>;
  createMany?: InputMaybe<DispositionMultiplierCreateManyHinge_PointInputEnvelope>;
  delete?: InputMaybe<Array<DispositionMultiplierWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<DispositionMultiplierScalarWhereInput>>;
  disconnect?: InputMaybe<Array<DispositionMultiplierWhereUniqueInput>>;
  set?: InputMaybe<Array<DispositionMultiplierWhereUniqueInput>>;
  update?: InputMaybe<Array<DispositionMultiplierUpdateWithWhereUniqueWithoutHinge_PointInput>>;
  updateMany?: InputMaybe<Array<DispositionMultiplierUpdateManyWithWhereWithoutHinge_PointInput>>;
  upsert?: InputMaybe<Array<DispositionMultiplierUpsertWithWhereUniqueWithoutHinge_PointInput>>;
};

export type DispositionMultiplierUpdateManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<DispositionMultiplierWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<DispositionMultiplierCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<DispositionMultiplierCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<DispositionMultiplierCreateManyOrganizationInputEnvelope>;
  delete?: InputMaybe<Array<DispositionMultiplierWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<DispositionMultiplierScalarWhereInput>>;
  disconnect?: InputMaybe<Array<DispositionMultiplierWhereUniqueInput>>;
  set?: InputMaybe<Array<DispositionMultiplierWhereUniqueInput>>;
  update?: InputMaybe<Array<DispositionMultiplierUpdateWithWhereUniqueWithoutOrganizationInput>>;
  updateMany?: InputMaybe<Array<DispositionMultiplierUpdateManyWithWhereWithoutOrganizationInput>>;
  upsert?: InputMaybe<Array<DispositionMultiplierUpsertWithWhereUniqueWithoutOrganizationInput>>;
};

export type DispositionMultiplierUpdateManyWithWhereWithoutHinge_PointInput = {
  data: DispositionMultiplierUpdateManyMutationInput;
  where: DispositionMultiplierScalarWhereInput;
};

export type DispositionMultiplierUpdateManyWithWhereWithoutOrganizationInput = {
  data: DispositionMultiplierUpdateManyMutationInput;
  where: DispositionMultiplierScalarWhereInput;
};

export type DispositionMultiplierUpdateWithoutHinge_PointInput = {
  action?: InputMaybe<StringFieldUpdateOperationsInput>;
  actual_actions_count?: InputMaybe<IntFieldUpdateOperationsInput>;
  callback_type?: InputMaybe<EnumCallbackTypeFieldUpdateOperationsInput>;
  computed_rate?: InputMaybe<FloatFieldUpdateOperationsInput>;
  current_data_points?: InputMaybe<IntFieldUpdateOperationsInput>;
  default_rate?: InputMaybe<FloatFieldUpdateOperationsInput>;
  disposition_type?: InputMaybe<EnumDispositiontypeFieldUpdateOperationsInput>;
  min_number_data_points?: InputMaybe<IntFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutDispositionMultiplierInput>;
  possible_actions_count?: InputMaybe<IntFieldUpdateOperationsInput>;
  use_default?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type DispositionMultiplierUpdateWithoutOrganizationInput = {
  action?: InputMaybe<StringFieldUpdateOperationsInput>;
  actual_actions_count?: InputMaybe<IntFieldUpdateOperationsInput>;
  callback_type?: InputMaybe<EnumCallbackTypeFieldUpdateOperationsInput>;
  computed_rate?: InputMaybe<FloatFieldUpdateOperationsInput>;
  current_data_points?: InputMaybe<IntFieldUpdateOperationsInput>;
  default_rate?: InputMaybe<FloatFieldUpdateOperationsInput>;
  disposition_type?: InputMaybe<EnumDispositiontypeFieldUpdateOperationsInput>;
  hinge_point?: InputMaybe<HingePointMultiplierUpdateOneRequiredWithoutDispositionMultiplierInput>;
  min_number_data_points?: InputMaybe<IntFieldUpdateOperationsInput>;
  possible_actions_count?: InputMaybe<IntFieldUpdateOperationsInput>;
  use_default?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type DispositionMultiplierUpdateWithWhereUniqueWithoutHinge_PointInput = {
  data: DispositionMultiplierUpdateWithoutHinge_PointInput;
  where: DispositionMultiplierWhereUniqueInput;
};

export type DispositionMultiplierUpdateWithWhereUniqueWithoutOrganizationInput = {
  data: DispositionMultiplierUpdateWithoutOrganizationInput;
  where: DispositionMultiplierWhereUniqueInput;
};

export type DispositionMultiplierUpsertWithWhereUniqueWithoutHinge_PointInput = {
  create: DispositionMultiplierCreateWithoutHinge_PointInput;
  update: DispositionMultiplierUpdateWithoutHinge_PointInput;
  where: DispositionMultiplierWhereUniqueInput;
};

export type DispositionMultiplierUpsertWithWhereUniqueWithoutOrganizationInput = {
  create: DispositionMultiplierCreateWithoutOrganizationInput;
  update: DispositionMultiplierUpdateWithoutOrganizationInput;
  where: DispositionMultiplierWhereUniqueInput;
};

export type DispositionMultiplierWhereInput = {
  action?: InputMaybe<StringFilter>;
  actual_actions_count?: InputMaybe<IntFilter>;
  AND?: InputMaybe<Array<DispositionMultiplierWhereInput>>;
  callback_type?: InputMaybe<EnumCallbackTypeFilter>;
  computed_rate?: InputMaybe<FloatFilter>;
  current_data_points?: InputMaybe<IntFilter>;
  default_rate?: InputMaybe<FloatFilter>;
  disposition_type?: InputMaybe<EnumDispositiontypeFilter>;
  hinge_point?: InputMaybe<HingePointMultiplierWhereInput>;
  HP_type?: InputMaybe<EnumHingepointFilter>;
  min_number_data_points?: InputMaybe<IntFilter>;
  NOT?: InputMaybe<Array<DispositionMultiplierWhereInput>>;
  OR?: InputMaybe<Array<DispositionMultiplierWhereInput>>;
  organization?: InputMaybe<OrganizationWhereInput>;
  organization_id?: InputMaybe<StringFilter>;
  possible_actions_count?: InputMaybe<IntFilter>;
  use_default?: InputMaybe<BoolFilter>;
};

export type DispositionMultiplierWhereUniqueInput = {
  disposition_id?: InputMaybe<DispositionMultiplierDisposition_IdCompoundUniqueInput>;
};

export type DispositionOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
  count?: InputMaybe<SortOrder>;
};

export type DispositionOrderByWithRelationInput = {
  active?: InputMaybe<SortOrder>;
  associated_action?: InputMaybe<SortOrder>;
  belongs_to_action?: InputMaybe<SortOrder>;
  conferences?: InputMaybe<ConferenceOrderByRelationAggregateInput>;
  created_at?: InputMaybe<SortOrder>;
  duplicate_disposition?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  lead_activities?: InputMaybe<LeadActivityOrderByRelationAggregateInput>;
  next_implied_action?: InputMaybe<SortOrder>;
  next_phase?: InputMaybe<SortOrder>;
  order?: InputMaybe<SortOrder>;
  organization?: InputMaybe<OrganizationOrderByWithRelationInput>;
  organization_id?: InputMaybe<SortOrder>;
  person_spoke_to?: InputMaybe<SortOrder>;
  phase?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
  visible?: InputMaybe<SortOrder>;
};

export type DispositionScalarWhereInput = {
  active?: InputMaybe<BoolFilter>;
  AND?: InputMaybe<Array<DispositionScalarWhereInput>>;
  associated_action?: InputMaybe<StringNullableFilter>;
  belongs_to_action?: InputMaybe<BoolFilter>;
  created_at?: InputMaybe<DateTimeFilter>;
  duplicate_disposition?: InputMaybe<BoolNullableFilter>;
  id?: InputMaybe<StringFilter>;
  next_implied_action?: InputMaybe<EnumHingepointFilter>;
  next_phase?: InputMaybe<EnumPhaseNullableFilter>;
  NOT?: InputMaybe<Array<DispositionScalarWhereInput>>;
  OR?: InputMaybe<Array<DispositionScalarWhereInput>>;
  order?: InputMaybe<IntNullableFilter>;
  organization_id?: InputMaybe<StringNullableFilter>;
  person_spoke_to?: InputMaybe<EnumCallresultNullableFilter>;
  phase?: InputMaybe<EnumPhaseNullableFilter>;
  type?: InputMaybe<EnumDispositiontypeFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
  visible?: InputMaybe<BoolFilter>;
};

/** theme object used to define styles for the lead history card */
export type DispositionThemeType = {
  __typename?: 'dispositionThemeType';
  bg_color?: Maybe<Scalars['String']['output']>;
  font_color?: Maybe<Scalars['String']['output']>;
  label?: Maybe<Scalars['String']['output']>;
};

export enum Dispositiontype {
  CurrentCustomer = 'CurrentCustomer',
  DeadLine = 'DeadLine',
  DemoCancelled = 'DemoCancelled',
  DemoCancelledNotInterested = 'DemoCancelledNotInterested',
  DemoCancelledStillInterested = 'DemoCancelledStillInterested',
  DemoConfirmed = 'DemoConfirmed',
  DemoRescheduledWithDm = 'DemoRescheduledWithDM',
  DemoRescheduledWithNdm = 'DemoRescheduledWithNDM',
  Disconnected = 'Disconnected',
  Dnc = 'DNC',
  DuplicateLead = 'DuplicateLead',
  HeldDemoNotInterested = 'HeldDemoNotInterested',
  HeldDemoScheduledDecisionCall = 'HeldDemoScheduledDecisionCall',
  HeldDemoStillInterested = 'HeldDemoStillInterested',
  HeldDemoWithDmStillInterested = 'HeldDemoWithDMStillInterested',
  HeldDemoWithNdmNotInterested = 'HeldDemoWithNDMNotInterested',
  HeldDemoWithNdmStillInterested = 'HeldDemoWithNDMStillInterested',
  LeftMessageWithNdm = 'LeftMessageWithNDM',
  LeftVoicemail = 'LeftVoicemail',
  MissedScheduledDemo = 'MissedScheduledDemo',
  MissedScheduledFollowUpCall = 'MissedScheduledFollowUpCall',
  NdmHeldDemoNotInterested = 'NDMHeldDemoNotInterested',
  NdmHeldDemoScheduledDecisionCall = 'NDMHeldDemoScheduledDecisionCall',
  NdmHeldDemoStillInterested = 'NDMHeldDemoStillInterested',
  NdmInterested = 'NDMInterested',
  NdmNotInterested = 'NDMNotInterested',
  NeverDialed = 'NeverDialed',
  NoMessageLeft = 'NoMessageLeft',
  NotInterested = 'NotInterested',
  NotQualified = 'NotQualified',
  PartialDemo = 'PartialDemo',
  PracticeManagementSystemNotIntegrated = 'PracticeManagementSystemNotIntegrated',
  PropsectDropped = 'PropsectDropped',
  ProspectDropped = 'ProspectDropped',
  ProspectInterested = 'ProspectInterested',
  ProspectNotInterested = 'ProspectNotInterested',
  ProspectReached = 'ProspectReached',
  ProspectStillInterested = 'ProspectStillInterested',
  Retired = 'Retired',
  Sale = 'Sale',
  ScheduleAdditionalDemoWithDm = 'ScheduleAdditionalDemoWithDM',
  ScheduleAdditionalDemoWithNdm = 'ScheduleAdditionalDemoWithNDM',
  ScheduledDecisionCall = 'ScheduledDecisionCall',
  ScheduleDemoWithDm = 'ScheduleDemoWithDM',
  ScheduleDemoWithNdm = 'ScheduleDemoWithNDM',
  ScheduleFuDemoWithDm = 'ScheduleFUDemoWithDM',
  ScheduleFuDemoWithNdm = 'ScheduleFUDemoWithNDM',
  SetDemoWithDm = 'SetDemoWithDM',
  SetDemoWithNdm = 'SetDemoWithNDM',
  StillInterested = 'StillInterested',
  TrackingNumber = 'TrackingNumber',
  TransferredAnotherSdRsSet = 'TransferredAnotherSDRsSet',
  TransferredMyOwnSet = 'TransferredMyOwnSet',
  TransferredOtf = 'TransferredOTF',
  UpcomingScheduledDemoConfirmed = 'UpcomingScheduledDemoConfirmed',
  WrongNum = 'WrongNum'
}

export type DispositionUpdateManyMutationInput = {
  active?: InputMaybe<BoolFieldUpdateOperationsInput>;
  associated_action?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  belongs_to_action?: InputMaybe<BoolFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  duplicate_disposition?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  next_implied_action?: InputMaybe<EnumHingepointFieldUpdateOperationsInput>;
  next_phase?: InputMaybe<NullableEnumPhaseFieldUpdateOperationsInput>;
  order?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  person_spoke_to?: InputMaybe<NullableEnumCallresultFieldUpdateOperationsInput>;
  phase?: InputMaybe<NullableEnumPhaseFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumDispositiontypeFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  visible?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type DispositionUpdateManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<DispositionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<DispositionCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<DispositionCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<DispositionCreateManyOrganizationInputEnvelope>;
  delete?: InputMaybe<Array<DispositionWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<DispositionScalarWhereInput>>;
  disconnect?: InputMaybe<Array<DispositionWhereUniqueInput>>;
  set?: InputMaybe<Array<DispositionWhereUniqueInput>>;
  update?: InputMaybe<Array<DispositionUpdateWithWhereUniqueWithoutOrganizationInput>>;
  updateMany?: InputMaybe<Array<DispositionUpdateManyWithWhereWithoutOrganizationInput>>;
  upsert?: InputMaybe<Array<DispositionUpsertWithWhereUniqueWithoutOrganizationInput>>;
};

export type DispositionUpdateManyWithWhereWithoutOrganizationInput = {
  data: DispositionUpdateManyMutationInput;
  where: DispositionScalarWhereInput;
};

export type DispositionUpdateOneWithoutConferencesInput = {
  connect?: InputMaybe<DispositionWhereUniqueInput>;
  connectOrCreate?: InputMaybe<DispositionCreateOrConnectWithoutConferencesInput>;
  create?: InputMaybe<DispositionCreateWithoutConferencesInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<DispositionUpdateWithoutConferencesInput>;
  upsert?: InputMaybe<DispositionUpsertWithoutConferencesInput>;
};

export type DispositionUpdateOneWithoutLead_ActivitiesInput = {
  connect?: InputMaybe<DispositionWhereUniqueInput>;
  connectOrCreate?: InputMaybe<DispositionCreateOrConnectWithoutLead_ActivitiesInput>;
  create?: InputMaybe<DispositionCreateWithoutLead_ActivitiesInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<DispositionUpdateWithoutLead_ActivitiesInput>;
  upsert?: InputMaybe<DispositionUpsertWithoutLead_ActivitiesInput>;
};

export type DispositionUpdateWithoutConferencesInput = {
  active?: InputMaybe<BoolFieldUpdateOperationsInput>;
  associated_action?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  belongs_to_action?: InputMaybe<BoolFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  duplicate_disposition?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutRelated_DispositionInput>;
  next_implied_action?: InputMaybe<EnumHingepointFieldUpdateOperationsInput>;
  next_phase?: InputMaybe<NullableEnumPhaseFieldUpdateOperationsInput>;
  order?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneWithoutDispositionsInput>;
  person_spoke_to?: InputMaybe<NullableEnumCallresultFieldUpdateOperationsInput>;
  phase?: InputMaybe<NullableEnumPhaseFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumDispositiontypeFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  visible?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type DispositionUpdateWithoutLead_ActivitiesInput = {
  active?: InputMaybe<BoolFieldUpdateOperationsInput>;
  associated_action?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  belongs_to_action?: InputMaybe<BoolFieldUpdateOperationsInput>;
  conferences?: InputMaybe<ConferenceUpdateManyWithoutCall_ResultInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  duplicate_disposition?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  next_implied_action?: InputMaybe<EnumHingepointFieldUpdateOperationsInput>;
  next_phase?: InputMaybe<NullableEnumPhaseFieldUpdateOperationsInput>;
  order?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneWithoutDispositionsInput>;
  person_spoke_to?: InputMaybe<NullableEnumCallresultFieldUpdateOperationsInput>;
  phase?: InputMaybe<NullableEnumPhaseFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumDispositiontypeFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  visible?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type DispositionUpdateWithoutOrganizationInput = {
  active?: InputMaybe<BoolFieldUpdateOperationsInput>;
  associated_action?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  belongs_to_action?: InputMaybe<BoolFieldUpdateOperationsInput>;
  conferences?: InputMaybe<ConferenceUpdateManyWithoutCall_ResultInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  duplicate_disposition?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutRelated_DispositionInput>;
  next_implied_action?: InputMaybe<EnumHingepointFieldUpdateOperationsInput>;
  next_phase?: InputMaybe<NullableEnumPhaseFieldUpdateOperationsInput>;
  order?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  person_spoke_to?: InputMaybe<NullableEnumCallresultFieldUpdateOperationsInput>;
  phase?: InputMaybe<NullableEnumPhaseFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumDispositiontypeFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  visible?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type DispositionUpdateWithWhereUniqueWithoutOrganizationInput = {
  data: DispositionUpdateWithoutOrganizationInput;
  where: DispositionWhereUniqueInput;
};

export type DispositionUpsertWithoutConferencesInput = {
  create: DispositionCreateWithoutConferencesInput;
  update: DispositionUpdateWithoutConferencesInput;
};

export type DispositionUpsertWithoutLead_ActivitiesInput = {
  create: DispositionCreateWithoutLead_ActivitiesInput;
  update: DispositionUpdateWithoutLead_ActivitiesInput;
};

export type DispositionUpsertWithWhereUniqueWithoutOrganizationInput = {
  create: DispositionCreateWithoutOrganizationInput;
  update: DispositionUpdateWithoutOrganizationInput;
  where: DispositionWhereUniqueInput;
};

export type DispositionWhereInput = {
  active?: InputMaybe<BoolFilter>;
  AND?: InputMaybe<Array<DispositionWhereInput>>;
  associated_action?: InputMaybe<StringNullableFilter>;
  belongs_to_action?: InputMaybe<BoolFilter>;
  conferences?: InputMaybe<ConferenceListRelationFilter>;
  created_at?: InputMaybe<DateTimeFilter>;
  duplicate_disposition?: InputMaybe<BoolNullableFilter>;
  id?: InputMaybe<StringFilter>;
  lead_activities?: InputMaybe<LeadActivityListRelationFilter>;
  next_implied_action?: InputMaybe<EnumHingepointFilter>;
  next_phase?: InputMaybe<EnumPhaseNullableFilter>;
  NOT?: InputMaybe<Array<DispositionWhereInput>>;
  OR?: InputMaybe<Array<DispositionWhereInput>>;
  order?: InputMaybe<IntNullableFilter>;
  organization?: InputMaybe<OrganizationWhereInput>;
  organization_id?: InputMaybe<StringNullableFilter>;
  person_spoke_to?: InputMaybe<EnumCallresultNullableFilter>;
  phase?: InputMaybe<EnumPhaseNullableFilter>;
  type?: InputMaybe<EnumDispositiontypeFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
  visible?: InputMaybe<BoolFilter>;
};

export type DispositionWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']['input']>;
};

export enum Distribution_Method {
  Ranking = 'Ranking',
  RoundRobin = 'RoundRobin'
}

export enum Dml_Type {
  Delete = 'DELETE',
  Insert = 'INSERT',
  Update = 'UPDATE'
}

export type EmailItem = {
  __typename?: 'EmailItem';
  bcc_emails: Array<Scalars['String']['output']>;
  body?: Maybe<Scalars['String']['output']>;
  cc_emails: Array<Scalars['String']['output']>;
  channel?: Maybe<Channel>;
  clicked_at?: Maybe<Scalars['DateTime']['output']>;
  email_thread?: Maybe<EmailThread>;
  email_thread_id?: Maybe<Scalars['String']['output']>;
  from_email?: Maybe<Scalars['String']['output']>;
  html?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  lead_activity?: Maybe<LeadActivity>;
  lead_activity_id?: Maybe<Scalars['String']['output']>;
  opened_at?: Maybe<Scalars['DateTime']['output']>;
  reply_to_email?: Maybe<Scalars['String']['output']>;
  sender_label?: Maybe<Scalars['String']['output']>;
  sent_at: Scalars['DateTime']['output'];
  source_type?: Maybe<Scalars['String']['output']>;
  subject?: Maybe<Scalars['String']['output']>;
  to_email: Array<Scalars['String']['output']>;
  unsubscribed_at?: Maybe<Scalars['DateTime']['output']>;
  updated_at: Scalars['DateTime']['output'];
};

export type EmailItemCreatebcc_EmailsInput = {
  set?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type EmailItemCreatecc_EmailsInput = {
  set?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type EmailItemCreateManybcc_EmailsInput = {
  set?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type EmailItemCreateManycc_EmailsInput = {
  set?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type EmailItemCreateManyEmail_ThreadInput = {
  bcc_emails?: InputMaybe<EmailItemCreateManybcc_EmailsInput>;
  body?: InputMaybe<Scalars['String']['input']>;
  bounced_at?: InputMaybe<Scalars['DateTime']['input']>;
  cc_emails?: InputMaybe<EmailItemCreateManycc_EmailsInput>;
  channel?: InputMaybe<Channel>;
  clicked_at?: InputMaybe<Scalars['DateTime']['input']>;
  from_email?: InputMaybe<Scalars['String']['input']>;
  gmail_message_id?: InputMaybe<Scalars['String']['input']>;
  html?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  lead_activity_id?: InputMaybe<Scalars['String']['input']>;
  opened_at?: InputMaybe<Scalars['DateTime']['input']>;
  organization_id?: InputMaybe<Scalars['String']['input']>;
  replied_at?: InputMaybe<Scalars['DateTime']['input']>;
  reply_to_email?: InputMaybe<Scalars['String']['input']>;
  sent_at?: InputMaybe<Scalars['DateTime']['input']>;
  sequence_id?: InputMaybe<Scalars['String']['input']>;
  step_action_id?: InputMaybe<Scalars['String']['input']>;
  subject?: InputMaybe<Scalars['String']['input']>;
  template_version_id?: InputMaybe<Scalars['String']['input']>;
  to_email?: InputMaybe<EmailItemCreateManyto_EmailInput>;
  unsubscribed_at?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type EmailItemCreateManyEmail_ThreadInputEnvelope = {
  data?: InputMaybe<Array<EmailItemCreateManyEmail_ThreadInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type EmailItemCreateManyOrganizationInput = {
  bcc_emails?: InputMaybe<EmailItemCreateManybcc_EmailsInput>;
  body?: InputMaybe<Scalars['String']['input']>;
  bounced_at?: InputMaybe<Scalars['DateTime']['input']>;
  cc_emails?: InputMaybe<EmailItemCreateManycc_EmailsInput>;
  channel?: InputMaybe<Channel>;
  clicked_at?: InputMaybe<Scalars['DateTime']['input']>;
  email_thread_id?: InputMaybe<Scalars['String']['input']>;
  from_email?: InputMaybe<Scalars['String']['input']>;
  gmail_message_id?: InputMaybe<Scalars['String']['input']>;
  html?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  lead_activity_id?: InputMaybe<Scalars['String']['input']>;
  opened_at?: InputMaybe<Scalars['DateTime']['input']>;
  replied_at?: InputMaybe<Scalars['DateTime']['input']>;
  reply_to_email?: InputMaybe<Scalars['String']['input']>;
  sent_at?: InputMaybe<Scalars['DateTime']['input']>;
  sequence_id?: InputMaybe<Scalars['String']['input']>;
  step_action_id?: InputMaybe<Scalars['String']['input']>;
  subject?: InputMaybe<Scalars['String']['input']>;
  template_version_id?: InputMaybe<Scalars['String']['input']>;
  to_email?: InputMaybe<EmailItemCreateManyto_EmailInput>;
  unsubscribed_at?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type EmailItemCreateManyOrganizationInputEnvelope = {
  data?: InputMaybe<Array<EmailItemCreateManyOrganizationInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type EmailItemCreateManySequenceInput = {
  bcc_emails?: InputMaybe<EmailItemCreateManybcc_EmailsInput>;
  body?: InputMaybe<Scalars['String']['input']>;
  bounced_at?: InputMaybe<Scalars['DateTime']['input']>;
  cc_emails?: InputMaybe<EmailItemCreateManycc_EmailsInput>;
  channel?: InputMaybe<Channel>;
  clicked_at?: InputMaybe<Scalars['DateTime']['input']>;
  email_thread_id?: InputMaybe<Scalars['String']['input']>;
  from_email?: InputMaybe<Scalars['String']['input']>;
  gmail_message_id?: InputMaybe<Scalars['String']['input']>;
  html?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  lead_activity_id?: InputMaybe<Scalars['String']['input']>;
  opened_at?: InputMaybe<Scalars['DateTime']['input']>;
  organization_id?: InputMaybe<Scalars['String']['input']>;
  replied_at?: InputMaybe<Scalars['DateTime']['input']>;
  reply_to_email?: InputMaybe<Scalars['String']['input']>;
  sent_at?: InputMaybe<Scalars['DateTime']['input']>;
  step_action_id?: InputMaybe<Scalars['String']['input']>;
  subject?: InputMaybe<Scalars['String']['input']>;
  template_version_id?: InputMaybe<Scalars['String']['input']>;
  to_email?: InputMaybe<EmailItemCreateManyto_EmailInput>;
  unsubscribed_at?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type EmailItemCreateManySequenceInputEnvelope = {
  data?: InputMaybe<Array<EmailItemCreateManySequenceInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type EmailItemCreateManyStep_ActionInput = {
  bcc_emails?: InputMaybe<EmailItemCreateManybcc_EmailsInput>;
  body?: InputMaybe<Scalars['String']['input']>;
  bounced_at?: InputMaybe<Scalars['DateTime']['input']>;
  cc_emails?: InputMaybe<EmailItemCreateManycc_EmailsInput>;
  channel?: InputMaybe<Channel>;
  clicked_at?: InputMaybe<Scalars['DateTime']['input']>;
  email_thread_id?: InputMaybe<Scalars['String']['input']>;
  from_email?: InputMaybe<Scalars['String']['input']>;
  gmail_message_id?: InputMaybe<Scalars['String']['input']>;
  html?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  lead_activity_id?: InputMaybe<Scalars['String']['input']>;
  opened_at?: InputMaybe<Scalars['DateTime']['input']>;
  organization_id?: InputMaybe<Scalars['String']['input']>;
  replied_at?: InputMaybe<Scalars['DateTime']['input']>;
  reply_to_email?: InputMaybe<Scalars['String']['input']>;
  sent_at?: InputMaybe<Scalars['DateTime']['input']>;
  sequence_id?: InputMaybe<Scalars['String']['input']>;
  subject?: InputMaybe<Scalars['String']['input']>;
  template_version_id?: InputMaybe<Scalars['String']['input']>;
  to_email?: InputMaybe<EmailItemCreateManyto_EmailInput>;
  unsubscribed_at?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type EmailItemCreateManyStep_ActionInputEnvelope = {
  data?: InputMaybe<Array<EmailItemCreateManyStep_ActionInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type EmailItemCreateManyTemplate_VersionInput = {
  bcc_emails?: InputMaybe<EmailItemCreateManybcc_EmailsInput>;
  body?: InputMaybe<Scalars['String']['input']>;
  bounced_at?: InputMaybe<Scalars['DateTime']['input']>;
  cc_emails?: InputMaybe<EmailItemCreateManycc_EmailsInput>;
  channel?: InputMaybe<Channel>;
  clicked_at?: InputMaybe<Scalars['DateTime']['input']>;
  email_thread_id?: InputMaybe<Scalars['String']['input']>;
  from_email?: InputMaybe<Scalars['String']['input']>;
  gmail_message_id?: InputMaybe<Scalars['String']['input']>;
  html?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  lead_activity_id?: InputMaybe<Scalars['String']['input']>;
  opened_at?: InputMaybe<Scalars['DateTime']['input']>;
  organization_id?: InputMaybe<Scalars['String']['input']>;
  replied_at?: InputMaybe<Scalars['DateTime']['input']>;
  reply_to_email?: InputMaybe<Scalars['String']['input']>;
  sent_at?: InputMaybe<Scalars['DateTime']['input']>;
  sequence_id?: InputMaybe<Scalars['String']['input']>;
  step_action_id?: InputMaybe<Scalars['String']['input']>;
  subject?: InputMaybe<Scalars['String']['input']>;
  to_email?: InputMaybe<EmailItemCreateManyto_EmailInput>;
  unsubscribed_at?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type EmailItemCreateManyTemplate_VersionInputEnvelope = {
  data?: InputMaybe<Array<EmailItemCreateManyTemplate_VersionInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type EmailItemCreateManyto_EmailInput = {
  set?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type EmailItemCreateNestedManyWithoutEmail_ThreadInput = {
  connect?: InputMaybe<Array<EmailItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<EmailItemCreateOrConnectWithoutEmail_ThreadInput>>;
  create?: InputMaybe<Array<EmailItemCreateWithoutEmail_ThreadInput>>;
  createMany?: InputMaybe<EmailItemCreateManyEmail_ThreadInputEnvelope>;
};

export type EmailItemCreateNestedManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<EmailItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<EmailItemCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<EmailItemCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<EmailItemCreateManyOrganizationInputEnvelope>;
};

export type EmailItemCreateNestedManyWithoutSequenceInput = {
  connect?: InputMaybe<Array<EmailItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<EmailItemCreateOrConnectWithoutSequenceInput>>;
  create?: InputMaybe<Array<EmailItemCreateWithoutSequenceInput>>;
  createMany?: InputMaybe<EmailItemCreateManySequenceInputEnvelope>;
};

export type EmailItemCreateNestedManyWithoutStep_ActionInput = {
  connect?: InputMaybe<Array<EmailItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<EmailItemCreateOrConnectWithoutStep_ActionInput>>;
  create?: InputMaybe<Array<EmailItemCreateWithoutStep_ActionInput>>;
  createMany?: InputMaybe<EmailItemCreateManyStep_ActionInputEnvelope>;
};

export type EmailItemCreateNestedManyWithoutTemplate_VersionInput = {
  connect?: InputMaybe<Array<EmailItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<EmailItemCreateOrConnectWithoutTemplate_VersionInput>>;
  create?: InputMaybe<Array<EmailItemCreateWithoutTemplate_VersionInput>>;
  createMany?: InputMaybe<EmailItemCreateManyTemplate_VersionInputEnvelope>;
};

export type EmailItemCreateNestedOneWithoutLead_ActivityInput = {
  connect?: InputMaybe<EmailItemWhereUniqueInput>;
  connectOrCreate?: InputMaybe<EmailItemCreateOrConnectWithoutLead_ActivityInput>;
  create?: InputMaybe<EmailItemCreateWithoutLead_ActivityInput>;
};

export type EmailItemCreateOrConnectWithoutEmail_ThreadInput = {
  create: EmailItemCreateWithoutEmail_ThreadInput;
  where: EmailItemWhereUniqueInput;
};

export type EmailItemCreateOrConnectWithoutLead_ActivityInput = {
  create: EmailItemCreateWithoutLead_ActivityInput;
  where: EmailItemWhereUniqueInput;
};

export type EmailItemCreateOrConnectWithoutOrganizationInput = {
  create: EmailItemCreateWithoutOrganizationInput;
  where: EmailItemWhereUniqueInput;
};

export type EmailItemCreateOrConnectWithoutSequenceInput = {
  create: EmailItemCreateWithoutSequenceInput;
  where: EmailItemWhereUniqueInput;
};

export type EmailItemCreateOrConnectWithoutStep_ActionInput = {
  create: EmailItemCreateWithoutStep_ActionInput;
  where: EmailItemWhereUniqueInput;
};

export type EmailItemCreateOrConnectWithoutTemplate_VersionInput = {
  create: EmailItemCreateWithoutTemplate_VersionInput;
  where: EmailItemWhereUniqueInput;
};

export type EmailItemCreateto_EmailInput = {
  set?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type EmailItemCreateWithoutEmail_ThreadInput = {
  bcc_emails?: InputMaybe<EmailItemCreatebcc_EmailsInput>;
  body?: InputMaybe<Scalars['String']['input']>;
  bounced_at?: InputMaybe<Scalars['DateTime']['input']>;
  cc_emails?: InputMaybe<EmailItemCreatecc_EmailsInput>;
  channel?: InputMaybe<Channel>;
  clicked_at?: InputMaybe<Scalars['DateTime']['input']>;
  from_email?: InputMaybe<Scalars['String']['input']>;
  gmail_message_id?: InputMaybe<Scalars['String']['input']>;
  html?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  lead_activity?: InputMaybe<LeadActivityCreateNestedOneWithoutEmail_ItemInput>;
  opened_at?: InputMaybe<Scalars['DateTime']['input']>;
  Organization?: InputMaybe<OrganizationCreateNestedOneWithoutEmailItemsInput>;
  replied_at?: InputMaybe<Scalars['DateTime']['input']>;
  reply_to_email?: InputMaybe<Scalars['String']['input']>;
  sent_at?: InputMaybe<Scalars['DateTime']['input']>;
  sequence?: InputMaybe<SequenceCreateNestedOneWithoutEmailItemInput>;
  step_action?: InputMaybe<StepActionCreateNestedOneWithoutEmailItemInput>;
  subject?: InputMaybe<Scalars['String']['input']>;
  template_version?: InputMaybe<TemplateVersionCreateNestedOneWithoutEmailItemInput>;
  to_email?: InputMaybe<EmailItemCreateto_EmailInput>;
  unsubscribed_at?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type EmailItemCreateWithoutLead_ActivityInput = {
  bcc_emails?: InputMaybe<EmailItemCreatebcc_EmailsInput>;
  body?: InputMaybe<Scalars['String']['input']>;
  bounced_at?: InputMaybe<Scalars['DateTime']['input']>;
  cc_emails?: InputMaybe<EmailItemCreatecc_EmailsInput>;
  channel?: InputMaybe<Channel>;
  clicked_at?: InputMaybe<Scalars['DateTime']['input']>;
  email_thread?: InputMaybe<EmailThreadCreateNestedOneWithoutEmail_ItemsInput>;
  from_email?: InputMaybe<Scalars['String']['input']>;
  gmail_message_id?: InputMaybe<Scalars['String']['input']>;
  html?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  opened_at?: InputMaybe<Scalars['DateTime']['input']>;
  Organization?: InputMaybe<OrganizationCreateNestedOneWithoutEmailItemsInput>;
  replied_at?: InputMaybe<Scalars['DateTime']['input']>;
  reply_to_email?: InputMaybe<Scalars['String']['input']>;
  sent_at?: InputMaybe<Scalars['DateTime']['input']>;
  sequence?: InputMaybe<SequenceCreateNestedOneWithoutEmailItemInput>;
  step_action?: InputMaybe<StepActionCreateNestedOneWithoutEmailItemInput>;
  subject?: InputMaybe<Scalars['String']['input']>;
  template_version?: InputMaybe<TemplateVersionCreateNestedOneWithoutEmailItemInput>;
  to_email?: InputMaybe<EmailItemCreateto_EmailInput>;
  unsubscribed_at?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type EmailItemCreateWithoutOrganizationInput = {
  bcc_emails?: InputMaybe<EmailItemCreatebcc_EmailsInput>;
  body?: InputMaybe<Scalars['String']['input']>;
  bounced_at?: InputMaybe<Scalars['DateTime']['input']>;
  cc_emails?: InputMaybe<EmailItemCreatecc_EmailsInput>;
  channel?: InputMaybe<Channel>;
  clicked_at?: InputMaybe<Scalars['DateTime']['input']>;
  email_thread?: InputMaybe<EmailThreadCreateNestedOneWithoutEmail_ItemsInput>;
  from_email?: InputMaybe<Scalars['String']['input']>;
  gmail_message_id?: InputMaybe<Scalars['String']['input']>;
  html?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  lead_activity?: InputMaybe<LeadActivityCreateNestedOneWithoutEmail_ItemInput>;
  opened_at?: InputMaybe<Scalars['DateTime']['input']>;
  replied_at?: InputMaybe<Scalars['DateTime']['input']>;
  reply_to_email?: InputMaybe<Scalars['String']['input']>;
  sent_at?: InputMaybe<Scalars['DateTime']['input']>;
  sequence?: InputMaybe<SequenceCreateNestedOneWithoutEmailItemInput>;
  step_action?: InputMaybe<StepActionCreateNestedOneWithoutEmailItemInput>;
  subject?: InputMaybe<Scalars['String']['input']>;
  template_version?: InputMaybe<TemplateVersionCreateNestedOneWithoutEmailItemInput>;
  to_email?: InputMaybe<EmailItemCreateto_EmailInput>;
  unsubscribed_at?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type EmailItemCreateWithoutSequenceInput = {
  bcc_emails?: InputMaybe<EmailItemCreatebcc_EmailsInput>;
  body?: InputMaybe<Scalars['String']['input']>;
  bounced_at?: InputMaybe<Scalars['DateTime']['input']>;
  cc_emails?: InputMaybe<EmailItemCreatecc_EmailsInput>;
  channel?: InputMaybe<Channel>;
  clicked_at?: InputMaybe<Scalars['DateTime']['input']>;
  email_thread?: InputMaybe<EmailThreadCreateNestedOneWithoutEmail_ItemsInput>;
  from_email?: InputMaybe<Scalars['String']['input']>;
  gmail_message_id?: InputMaybe<Scalars['String']['input']>;
  html?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  lead_activity?: InputMaybe<LeadActivityCreateNestedOneWithoutEmail_ItemInput>;
  opened_at?: InputMaybe<Scalars['DateTime']['input']>;
  Organization?: InputMaybe<OrganizationCreateNestedOneWithoutEmailItemsInput>;
  replied_at?: InputMaybe<Scalars['DateTime']['input']>;
  reply_to_email?: InputMaybe<Scalars['String']['input']>;
  sent_at?: InputMaybe<Scalars['DateTime']['input']>;
  step_action?: InputMaybe<StepActionCreateNestedOneWithoutEmailItemInput>;
  subject?: InputMaybe<Scalars['String']['input']>;
  template_version?: InputMaybe<TemplateVersionCreateNestedOneWithoutEmailItemInput>;
  to_email?: InputMaybe<EmailItemCreateto_EmailInput>;
  unsubscribed_at?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type EmailItemCreateWithoutStep_ActionInput = {
  bcc_emails?: InputMaybe<EmailItemCreatebcc_EmailsInput>;
  body?: InputMaybe<Scalars['String']['input']>;
  bounced_at?: InputMaybe<Scalars['DateTime']['input']>;
  cc_emails?: InputMaybe<EmailItemCreatecc_EmailsInput>;
  channel?: InputMaybe<Channel>;
  clicked_at?: InputMaybe<Scalars['DateTime']['input']>;
  email_thread?: InputMaybe<EmailThreadCreateNestedOneWithoutEmail_ItemsInput>;
  from_email?: InputMaybe<Scalars['String']['input']>;
  gmail_message_id?: InputMaybe<Scalars['String']['input']>;
  html?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  lead_activity?: InputMaybe<LeadActivityCreateNestedOneWithoutEmail_ItemInput>;
  opened_at?: InputMaybe<Scalars['DateTime']['input']>;
  Organization?: InputMaybe<OrganizationCreateNestedOneWithoutEmailItemsInput>;
  replied_at?: InputMaybe<Scalars['DateTime']['input']>;
  reply_to_email?: InputMaybe<Scalars['String']['input']>;
  sent_at?: InputMaybe<Scalars['DateTime']['input']>;
  sequence?: InputMaybe<SequenceCreateNestedOneWithoutEmailItemInput>;
  subject?: InputMaybe<Scalars['String']['input']>;
  template_version?: InputMaybe<TemplateVersionCreateNestedOneWithoutEmailItemInput>;
  to_email?: InputMaybe<EmailItemCreateto_EmailInput>;
  unsubscribed_at?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type EmailItemCreateWithoutTemplate_VersionInput = {
  bcc_emails?: InputMaybe<EmailItemCreatebcc_EmailsInput>;
  body?: InputMaybe<Scalars['String']['input']>;
  bounced_at?: InputMaybe<Scalars['DateTime']['input']>;
  cc_emails?: InputMaybe<EmailItemCreatecc_EmailsInput>;
  channel?: InputMaybe<Channel>;
  clicked_at?: InputMaybe<Scalars['DateTime']['input']>;
  email_thread?: InputMaybe<EmailThreadCreateNestedOneWithoutEmail_ItemsInput>;
  from_email?: InputMaybe<Scalars['String']['input']>;
  gmail_message_id?: InputMaybe<Scalars['String']['input']>;
  html?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  lead_activity?: InputMaybe<LeadActivityCreateNestedOneWithoutEmail_ItemInput>;
  opened_at?: InputMaybe<Scalars['DateTime']['input']>;
  Organization?: InputMaybe<OrganizationCreateNestedOneWithoutEmailItemsInput>;
  replied_at?: InputMaybe<Scalars['DateTime']['input']>;
  reply_to_email?: InputMaybe<Scalars['String']['input']>;
  sent_at?: InputMaybe<Scalars['DateTime']['input']>;
  sequence?: InputMaybe<SequenceCreateNestedOneWithoutEmailItemInput>;
  step_action?: InputMaybe<StepActionCreateNestedOneWithoutEmailItemInput>;
  subject?: InputMaybe<Scalars['String']['input']>;
  to_email?: InputMaybe<EmailItemCreateto_EmailInput>;
  unsubscribed_at?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type EmailItemListRelationFilter = {
  every?: InputMaybe<EmailItemWhereInput>;
  none?: InputMaybe<EmailItemWhereInput>;
  some?: InputMaybe<EmailItemWhereInput>;
};

export type EmailItemOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
  count?: InputMaybe<SortOrder>;
};

export type EmailItemOrderByWithRelationInput = {
  bcc_emails?: InputMaybe<SortOrder>;
  body?: InputMaybe<SortOrder>;
  bounced_at?: InputMaybe<SortOrder>;
  cc_emails?: InputMaybe<SortOrder>;
  channel?: InputMaybe<SortOrder>;
  clicked_at?: InputMaybe<SortOrder>;
  email_thread?: InputMaybe<EmailThreadOrderByWithRelationInput>;
  email_thread_id?: InputMaybe<SortOrder>;
  from_email?: InputMaybe<SortOrder>;
  gmail_message_id?: InputMaybe<SortOrder>;
  html?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  lead_activity?: InputMaybe<LeadActivityOrderByWithRelationInput>;
  lead_activity_id?: InputMaybe<SortOrder>;
  opened_at?: InputMaybe<SortOrder>;
  Organization?: InputMaybe<OrganizationOrderByWithRelationInput>;
  organization_id?: InputMaybe<SortOrder>;
  replied_at?: InputMaybe<SortOrder>;
  reply_to_email?: InputMaybe<SortOrder>;
  sent_at?: InputMaybe<SortOrder>;
  sequence?: InputMaybe<SequenceOrderByWithRelationInput>;
  sequence_id?: InputMaybe<SortOrder>;
  step_action?: InputMaybe<StepActionOrderByWithRelationInput>;
  step_action_id?: InputMaybe<SortOrder>;
  subject?: InputMaybe<SortOrder>;
  template_version?: InputMaybe<TemplateVersionOrderByWithRelationInput>;
  template_version_id?: InputMaybe<SortOrder>;
  to_email?: InputMaybe<SortOrder>;
  unsubscribed_at?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
};

export type EmailItemScalarWhereInput = {
  AND?: InputMaybe<Array<EmailItemScalarWhereInput>>;
  bcc_emails?: InputMaybe<StringNullableListFilter>;
  body?: InputMaybe<StringNullableFilter>;
  bounced_at?: InputMaybe<DateTimeNullableFilter>;
  cc_emails?: InputMaybe<StringNullableListFilter>;
  channel?: InputMaybe<EnumChannelNullableFilter>;
  clicked_at?: InputMaybe<DateTimeNullableFilter>;
  email_thread_id?: InputMaybe<StringNullableFilter>;
  from_email?: InputMaybe<StringNullableFilter>;
  gmail_message_id?: InputMaybe<StringNullableFilter>;
  html?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  lead_activity_id?: InputMaybe<StringNullableFilter>;
  NOT?: InputMaybe<Array<EmailItemScalarWhereInput>>;
  opened_at?: InputMaybe<DateTimeNullableFilter>;
  OR?: InputMaybe<Array<EmailItemScalarWhereInput>>;
  organization_id?: InputMaybe<StringNullableFilter>;
  replied_at?: InputMaybe<DateTimeNullableFilter>;
  reply_to_email?: InputMaybe<StringNullableFilter>;
  sent_at?: InputMaybe<DateTimeFilter>;
  sequence_id?: InputMaybe<StringNullableFilter>;
  step_action_id?: InputMaybe<StringNullableFilter>;
  subject?: InputMaybe<StringNullableFilter>;
  template_version_id?: InputMaybe<StringNullableFilter>;
  to_email?: InputMaybe<StringNullableListFilter>;
  unsubscribed_at?: InputMaybe<DateTimeNullableFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
};

export type EmailItemUpdatebcc_EmailsInput = {
  push?: InputMaybe<Scalars['String']['input']>;
  set?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type EmailItemUpdatecc_EmailsInput = {
  push?: InputMaybe<Scalars['String']['input']>;
  set?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type EmailItemUpdateManyMutationInput = {
  bcc_emails?: InputMaybe<EmailItemUpdatebcc_EmailsInput>;
  body?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  bounced_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  cc_emails?: InputMaybe<EmailItemUpdatecc_EmailsInput>;
  channel?: InputMaybe<NullableEnumChannelFieldUpdateOperationsInput>;
  clicked_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  from_email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  gmail_message_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  html?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  opened_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  replied_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  reply_to_email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sent_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  subject?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  to_email?: InputMaybe<EmailItemUpdateto_EmailInput>;
  unsubscribed_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type EmailItemUpdateManyWithoutEmail_ThreadInput = {
  connect?: InputMaybe<Array<EmailItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<EmailItemCreateOrConnectWithoutEmail_ThreadInput>>;
  create?: InputMaybe<Array<EmailItemCreateWithoutEmail_ThreadInput>>;
  createMany?: InputMaybe<EmailItemCreateManyEmail_ThreadInputEnvelope>;
  delete?: InputMaybe<Array<EmailItemWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<EmailItemScalarWhereInput>>;
  disconnect?: InputMaybe<Array<EmailItemWhereUniqueInput>>;
  set?: InputMaybe<Array<EmailItemWhereUniqueInput>>;
  update?: InputMaybe<Array<EmailItemUpdateWithWhereUniqueWithoutEmail_ThreadInput>>;
  updateMany?: InputMaybe<Array<EmailItemUpdateManyWithWhereWithoutEmail_ThreadInput>>;
  upsert?: InputMaybe<Array<EmailItemUpsertWithWhereUniqueWithoutEmail_ThreadInput>>;
};

export type EmailItemUpdateManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<EmailItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<EmailItemCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<EmailItemCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<EmailItemCreateManyOrganizationInputEnvelope>;
  delete?: InputMaybe<Array<EmailItemWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<EmailItemScalarWhereInput>>;
  disconnect?: InputMaybe<Array<EmailItemWhereUniqueInput>>;
  set?: InputMaybe<Array<EmailItemWhereUniqueInput>>;
  update?: InputMaybe<Array<EmailItemUpdateWithWhereUniqueWithoutOrganizationInput>>;
  updateMany?: InputMaybe<Array<EmailItemUpdateManyWithWhereWithoutOrganizationInput>>;
  upsert?: InputMaybe<Array<EmailItemUpsertWithWhereUniqueWithoutOrganizationInput>>;
};

export type EmailItemUpdateManyWithoutSequenceInput = {
  connect?: InputMaybe<Array<EmailItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<EmailItemCreateOrConnectWithoutSequenceInput>>;
  create?: InputMaybe<Array<EmailItemCreateWithoutSequenceInput>>;
  createMany?: InputMaybe<EmailItemCreateManySequenceInputEnvelope>;
  delete?: InputMaybe<Array<EmailItemWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<EmailItemScalarWhereInput>>;
  disconnect?: InputMaybe<Array<EmailItemWhereUniqueInput>>;
  set?: InputMaybe<Array<EmailItemWhereUniqueInput>>;
  update?: InputMaybe<Array<EmailItemUpdateWithWhereUniqueWithoutSequenceInput>>;
  updateMany?: InputMaybe<Array<EmailItemUpdateManyWithWhereWithoutSequenceInput>>;
  upsert?: InputMaybe<Array<EmailItemUpsertWithWhereUniqueWithoutSequenceInput>>;
};

export type EmailItemUpdateManyWithoutStep_ActionInput = {
  connect?: InputMaybe<Array<EmailItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<EmailItemCreateOrConnectWithoutStep_ActionInput>>;
  create?: InputMaybe<Array<EmailItemCreateWithoutStep_ActionInput>>;
  createMany?: InputMaybe<EmailItemCreateManyStep_ActionInputEnvelope>;
  delete?: InputMaybe<Array<EmailItemWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<EmailItemScalarWhereInput>>;
  disconnect?: InputMaybe<Array<EmailItemWhereUniqueInput>>;
  set?: InputMaybe<Array<EmailItemWhereUniqueInput>>;
  update?: InputMaybe<Array<EmailItemUpdateWithWhereUniqueWithoutStep_ActionInput>>;
  updateMany?: InputMaybe<Array<EmailItemUpdateManyWithWhereWithoutStep_ActionInput>>;
  upsert?: InputMaybe<Array<EmailItemUpsertWithWhereUniqueWithoutStep_ActionInput>>;
};

export type EmailItemUpdateManyWithoutTemplate_VersionInput = {
  connect?: InputMaybe<Array<EmailItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<EmailItemCreateOrConnectWithoutTemplate_VersionInput>>;
  create?: InputMaybe<Array<EmailItemCreateWithoutTemplate_VersionInput>>;
  createMany?: InputMaybe<EmailItemCreateManyTemplate_VersionInputEnvelope>;
  delete?: InputMaybe<Array<EmailItemWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<EmailItemScalarWhereInput>>;
  disconnect?: InputMaybe<Array<EmailItemWhereUniqueInput>>;
  set?: InputMaybe<Array<EmailItemWhereUniqueInput>>;
  update?: InputMaybe<Array<EmailItemUpdateWithWhereUniqueWithoutTemplate_VersionInput>>;
  updateMany?: InputMaybe<Array<EmailItemUpdateManyWithWhereWithoutTemplate_VersionInput>>;
  upsert?: InputMaybe<Array<EmailItemUpsertWithWhereUniqueWithoutTemplate_VersionInput>>;
};

export type EmailItemUpdateManyWithWhereWithoutEmail_ThreadInput = {
  data: EmailItemUpdateManyMutationInput;
  where: EmailItemScalarWhereInput;
};

export type EmailItemUpdateManyWithWhereWithoutOrganizationInput = {
  data: EmailItemUpdateManyMutationInput;
  where: EmailItemScalarWhereInput;
};

export type EmailItemUpdateManyWithWhereWithoutSequenceInput = {
  data: EmailItemUpdateManyMutationInput;
  where: EmailItemScalarWhereInput;
};

export type EmailItemUpdateManyWithWhereWithoutStep_ActionInput = {
  data: EmailItemUpdateManyMutationInput;
  where: EmailItemScalarWhereInput;
};

export type EmailItemUpdateManyWithWhereWithoutTemplate_VersionInput = {
  data: EmailItemUpdateManyMutationInput;
  where: EmailItemScalarWhereInput;
};

export type EmailItemUpdateOneWithoutLead_ActivityInput = {
  connect?: InputMaybe<EmailItemWhereUniqueInput>;
  connectOrCreate?: InputMaybe<EmailItemCreateOrConnectWithoutLead_ActivityInput>;
  create?: InputMaybe<EmailItemCreateWithoutLead_ActivityInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<EmailItemUpdateWithoutLead_ActivityInput>;
  upsert?: InputMaybe<EmailItemUpsertWithoutLead_ActivityInput>;
};

export type EmailItemUpdateto_EmailInput = {
  push?: InputMaybe<Scalars['String']['input']>;
  set?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type EmailItemUpdateWithoutEmail_ThreadInput = {
  bcc_emails?: InputMaybe<EmailItemUpdatebcc_EmailsInput>;
  body?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  bounced_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  cc_emails?: InputMaybe<EmailItemUpdatecc_EmailsInput>;
  channel?: InputMaybe<NullableEnumChannelFieldUpdateOperationsInput>;
  clicked_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  from_email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  gmail_message_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  html?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lead_activity?: InputMaybe<LeadActivityUpdateOneWithoutEmail_ItemInput>;
  opened_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  Organization?: InputMaybe<OrganizationUpdateOneWithoutEmailItemsInput>;
  replied_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  reply_to_email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sent_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  sequence?: InputMaybe<SequenceUpdateOneWithoutEmailItemInput>;
  step_action?: InputMaybe<StepActionUpdateOneWithoutEmailItemInput>;
  subject?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  template_version?: InputMaybe<TemplateVersionUpdateOneWithoutEmailItemInput>;
  to_email?: InputMaybe<EmailItemUpdateto_EmailInput>;
  unsubscribed_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type EmailItemUpdateWithoutLead_ActivityInput = {
  bcc_emails?: InputMaybe<EmailItemUpdatebcc_EmailsInput>;
  body?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  bounced_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  cc_emails?: InputMaybe<EmailItemUpdatecc_EmailsInput>;
  channel?: InputMaybe<NullableEnumChannelFieldUpdateOperationsInput>;
  clicked_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  email_thread?: InputMaybe<EmailThreadUpdateOneWithoutEmail_ItemsInput>;
  from_email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  gmail_message_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  html?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  opened_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  Organization?: InputMaybe<OrganizationUpdateOneWithoutEmailItemsInput>;
  replied_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  reply_to_email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sent_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  sequence?: InputMaybe<SequenceUpdateOneWithoutEmailItemInput>;
  step_action?: InputMaybe<StepActionUpdateOneWithoutEmailItemInput>;
  subject?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  template_version?: InputMaybe<TemplateVersionUpdateOneWithoutEmailItemInput>;
  to_email?: InputMaybe<EmailItemUpdateto_EmailInput>;
  unsubscribed_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type EmailItemUpdateWithoutOrganizationInput = {
  bcc_emails?: InputMaybe<EmailItemUpdatebcc_EmailsInput>;
  body?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  bounced_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  cc_emails?: InputMaybe<EmailItemUpdatecc_EmailsInput>;
  channel?: InputMaybe<NullableEnumChannelFieldUpdateOperationsInput>;
  clicked_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  email_thread?: InputMaybe<EmailThreadUpdateOneWithoutEmail_ItemsInput>;
  from_email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  gmail_message_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  html?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lead_activity?: InputMaybe<LeadActivityUpdateOneWithoutEmail_ItemInput>;
  opened_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  replied_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  reply_to_email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sent_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  sequence?: InputMaybe<SequenceUpdateOneWithoutEmailItemInput>;
  step_action?: InputMaybe<StepActionUpdateOneWithoutEmailItemInput>;
  subject?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  template_version?: InputMaybe<TemplateVersionUpdateOneWithoutEmailItemInput>;
  to_email?: InputMaybe<EmailItemUpdateto_EmailInput>;
  unsubscribed_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type EmailItemUpdateWithoutSequenceInput = {
  bcc_emails?: InputMaybe<EmailItemUpdatebcc_EmailsInput>;
  body?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  bounced_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  cc_emails?: InputMaybe<EmailItemUpdatecc_EmailsInput>;
  channel?: InputMaybe<NullableEnumChannelFieldUpdateOperationsInput>;
  clicked_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  email_thread?: InputMaybe<EmailThreadUpdateOneWithoutEmail_ItemsInput>;
  from_email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  gmail_message_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  html?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lead_activity?: InputMaybe<LeadActivityUpdateOneWithoutEmail_ItemInput>;
  opened_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  Organization?: InputMaybe<OrganizationUpdateOneWithoutEmailItemsInput>;
  replied_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  reply_to_email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sent_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  step_action?: InputMaybe<StepActionUpdateOneWithoutEmailItemInput>;
  subject?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  template_version?: InputMaybe<TemplateVersionUpdateOneWithoutEmailItemInput>;
  to_email?: InputMaybe<EmailItemUpdateto_EmailInput>;
  unsubscribed_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type EmailItemUpdateWithoutStep_ActionInput = {
  bcc_emails?: InputMaybe<EmailItemUpdatebcc_EmailsInput>;
  body?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  bounced_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  cc_emails?: InputMaybe<EmailItemUpdatecc_EmailsInput>;
  channel?: InputMaybe<NullableEnumChannelFieldUpdateOperationsInput>;
  clicked_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  email_thread?: InputMaybe<EmailThreadUpdateOneWithoutEmail_ItemsInput>;
  from_email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  gmail_message_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  html?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lead_activity?: InputMaybe<LeadActivityUpdateOneWithoutEmail_ItemInput>;
  opened_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  Organization?: InputMaybe<OrganizationUpdateOneWithoutEmailItemsInput>;
  replied_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  reply_to_email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sent_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  sequence?: InputMaybe<SequenceUpdateOneWithoutEmailItemInput>;
  subject?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  template_version?: InputMaybe<TemplateVersionUpdateOneWithoutEmailItemInput>;
  to_email?: InputMaybe<EmailItemUpdateto_EmailInput>;
  unsubscribed_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type EmailItemUpdateWithoutTemplate_VersionInput = {
  bcc_emails?: InputMaybe<EmailItemUpdatebcc_EmailsInput>;
  body?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  bounced_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  cc_emails?: InputMaybe<EmailItemUpdatecc_EmailsInput>;
  channel?: InputMaybe<NullableEnumChannelFieldUpdateOperationsInput>;
  clicked_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  email_thread?: InputMaybe<EmailThreadUpdateOneWithoutEmail_ItemsInput>;
  from_email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  gmail_message_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  html?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lead_activity?: InputMaybe<LeadActivityUpdateOneWithoutEmail_ItemInput>;
  opened_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  Organization?: InputMaybe<OrganizationUpdateOneWithoutEmailItemsInput>;
  replied_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  reply_to_email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sent_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  sequence?: InputMaybe<SequenceUpdateOneWithoutEmailItemInput>;
  step_action?: InputMaybe<StepActionUpdateOneWithoutEmailItemInput>;
  subject?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  to_email?: InputMaybe<EmailItemUpdateto_EmailInput>;
  unsubscribed_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type EmailItemUpdateWithWhereUniqueWithoutEmail_ThreadInput = {
  data: EmailItemUpdateWithoutEmail_ThreadInput;
  where: EmailItemWhereUniqueInput;
};

export type EmailItemUpdateWithWhereUniqueWithoutOrganizationInput = {
  data: EmailItemUpdateWithoutOrganizationInput;
  where: EmailItemWhereUniqueInput;
};

export type EmailItemUpdateWithWhereUniqueWithoutSequenceInput = {
  data: EmailItemUpdateWithoutSequenceInput;
  where: EmailItemWhereUniqueInput;
};

export type EmailItemUpdateWithWhereUniqueWithoutStep_ActionInput = {
  data: EmailItemUpdateWithoutStep_ActionInput;
  where: EmailItemWhereUniqueInput;
};

export type EmailItemUpdateWithWhereUniqueWithoutTemplate_VersionInput = {
  data: EmailItemUpdateWithoutTemplate_VersionInput;
  where: EmailItemWhereUniqueInput;
};

export type EmailItemUpsertWithoutLead_ActivityInput = {
  create: EmailItemCreateWithoutLead_ActivityInput;
  update: EmailItemUpdateWithoutLead_ActivityInput;
};

export type EmailItemUpsertWithWhereUniqueWithoutEmail_ThreadInput = {
  create: EmailItemCreateWithoutEmail_ThreadInput;
  update: EmailItemUpdateWithoutEmail_ThreadInput;
  where: EmailItemWhereUniqueInput;
};

export type EmailItemUpsertWithWhereUniqueWithoutOrganizationInput = {
  create: EmailItemCreateWithoutOrganizationInput;
  update: EmailItemUpdateWithoutOrganizationInput;
  where: EmailItemWhereUniqueInput;
};

export type EmailItemUpsertWithWhereUniqueWithoutSequenceInput = {
  create: EmailItemCreateWithoutSequenceInput;
  update: EmailItemUpdateWithoutSequenceInput;
  where: EmailItemWhereUniqueInput;
};

export type EmailItemUpsertWithWhereUniqueWithoutStep_ActionInput = {
  create: EmailItemCreateWithoutStep_ActionInput;
  update: EmailItemUpdateWithoutStep_ActionInput;
  where: EmailItemWhereUniqueInput;
};

export type EmailItemUpsertWithWhereUniqueWithoutTemplate_VersionInput = {
  create: EmailItemCreateWithoutTemplate_VersionInput;
  update: EmailItemUpdateWithoutTemplate_VersionInput;
  where: EmailItemWhereUniqueInput;
};

export type EmailItemWhereInput = {
  AND?: InputMaybe<Array<EmailItemWhereInput>>;
  bcc_emails?: InputMaybe<StringNullableListFilter>;
  body?: InputMaybe<StringNullableFilter>;
  bounced_at?: InputMaybe<DateTimeNullableFilter>;
  cc_emails?: InputMaybe<StringNullableListFilter>;
  channel?: InputMaybe<EnumChannelNullableFilter>;
  clicked_at?: InputMaybe<DateTimeNullableFilter>;
  email_thread?: InputMaybe<EmailThreadWhereInput>;
  email_thread_id?: InputMaybe<StringNullableFilter>;
  from_email?: InputMaybe<StringNullableFilter>;
  gmail_message_id?: InputMaybe<StringNullableFilter>;
  html?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  lead_activity?: InputMaybe<LeadActivityWhereInput>;
  lead_activity_id?: InputMaybe<StringNullableFilter>;
  NOT?: InputMaybe<Array<EmailItemWhereInput>>;
  opened_at?: InputMaybe<DateTimeNullableFilter>;
  OR?: InputMaybe<Array<EmailItemWhereInput>>;
  Organization?: InputMaybe<OrganizationWhereInput>;
  organization_id?: InputMaybe<StringNullableFilter>;
  replied_at?: InputMaybe<DateTimeNullableFilter>;
  reply_to_email?: InputMaybe<StringNullableFilter>;
  sent_at?: InputMaybe<DateTimeFilter>;
  sequence?: InputMaybe<SequenceWhereInput>;
  sequence_id?: InputMaybe<StringNullableFilter>;
  step_action?: InputMaybe<StepActionWhereInput>;
  step_action_id?: InputMaybe<StringNullableFilter>;
  subject?: InputMaybe<StringNullableFilter>;
  template_version?: InputMaybe<TemplateVersionWhereInput>;
  template_version_id?: InputMaybe<StringNullableFilter>;
  to_email?: InputMaybe<StringNullableListFilter>;
  unsubscribed_at?: InputMaybe<DateTimeNullableFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
};

export type EmailItemWhereUniqueInput = {
  gmail_message_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  lead_activity_id?: InputMaybe<Scalars['String']['input']>;
};

/** Email preview for a given template */
export type EmailPreview = {
  __typename?: 'EmailPreview';
  body: Scalars['String']['output'];
  from: Scalars['String']['output'];
  subject: Scalars['String']['output'];
  template_id: Scalars['String']['output'];
  to: Scalars['String']['output'];
};

export type EmailSyncRecord = {
  __typename?: 'EmailSyncRecord';
  created_at: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  lead?: Maybe<Lead>;
  lead_id?: Maybe<Scalars['String']['output']>;
  organization: Organization;
  organization_id: Scalars['String']['output'];
  user?: Maybe<User>;
  user_id?: Maybe<Scalars['String']['output']>;
};

export type EmailSyncRecordCreateManyLeadInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  organization_id: Scalars['String']['input'];
  user_id?: InputMaybe<Scalars['String']['input']>;
};

export type EmailSyncRecordCreateManyLeadInputEnvelope = {
  data?: InputMaybe<Array<EmailSyncRecordCreateManyLeadInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type EmailSyncRecordCreateManyOrganizationInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  lead_id?: InputMaybe<Scalars['String']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
};

export type EmailSyncRecordCreateManyOrganizationInputEnvelope = {
  data?: InputMaybe<Array<EmailSyncRecordCreateManyOrganizationInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type EmailSyncRecordCreateManyUserInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  lead_id?: InputMaybe<Scalars['String']['input']>;
  organization_id: Scalars['String']['input'];
};

export type EmailSyncRecordCreateManyUserInputEnvelope = {
  data?: InputMaybe<Array<EmailSyncRecordCreateManyUserInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type EmailSyncRecordCreateNestedManyWithoutLeadInput = {
  connect?: InputMaybe<Array<EmailSyncRecordWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<EmailSyncRecordCreateOrConnectWithoutLeadInput>>;
  create?: InputMaybe<Array<EmailSyncRecordCreateWithoutLeadInput>>;
  createMany?: InputMaybe<EmailSyncRecordCreateManyLeadInputEnvelope>;
};

export type EmailSyncRecordCreateNestedManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<EmailSyncRecordWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<EmailSyncRecordCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<EmailSyncRecordCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<EmailSyncRecordCreateManyOrganizationInputEnvelope>;
};

export type EmailSyncRecordCreateNestedManyWithoutUserInput = {
  connect?: InputMaybe<Array<EmailSyncRecordWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<EmailSyncRecordCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<EmailSyncRecordCreateWithoutUserInput>>;
  createMany?: InputMaybe<EmailSyncRecordCreateManyUserInputEnvelope>;
};

export type EmailSyncRecordCreateOrConnectWithoutLeadInput = {
  create: EmailSyncRecordCreateWithoutLeadInput;
  where: EmailSyncRecordWhereUniqueInput;
};

export type EmailSyncRecordCreateOrConnectWithoutOrganizationInput = {
  create: EmailSyncRecordCreateWithoutOrganizationInput;
  where: EmailSyncRecordWhereUniqueInput;
};

export type EmailSyncRecordCreateOrConnectWithoutUserInput = {
  create: EmailSyncRecordCreateWithoutUserInput;
  where: EmailSyncRecordWhereUniqueInput;
};

export type EmailSyncRecordCreateWithoutLeadInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  organization: OrganizationCreateNestedOneWithoutEmailSyncRecordInput;
  user?: InputMaybe<UserCreateNestedOneWithoutEmailSyncRecordInput>;
};

export type EmailSyncRecordCreateWithoutOrganizationInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  lead?: InputMaybe<LeadCreateNestedOneWithoutEmailSyncRecordInput>;
  user?: InputMaybe<UserCreateNestedOneWithoutEmailSyncRecordInput>;
};

export type EmailSyncRecordCreateWithoutUserInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  lead?: InputMaybe<LeadCreateNestedOneWithoutEmailSyncRecordInput>;
  organization: OrganizationCreateNestedOneWithoutEmailSyncRecordInput;
};

export type EmailSyncRecordListRelationFilter = {
  every?: InputMaybe<EmailSyncRecordWhereInput>;
  none?: InputMaybe<EmailSyncRecordWhereInput>;
  some?: InputMaybe<EmailSyncRecordWhereInput>;
};

export type EmailSyncRecordOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
  count?: InputMaybe<SortOrder>;
};

export type EmailSyncRecordScalarWhereInput = {
  AND?: InputMaybe<Array<EmailSyncRecordScalarWhereInput>>;
  created_at?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  lead_id?: InputMaybe<StringNullableFilter>;
  NOT?: InputMaybe<Array<EmailSyncRecordScalarWhereInput>>;
  OR?: InputMaybe<Array<EmailSyncRecordScalarWhereInput>>;
  organization_id?: InputMaybe<StringFilter>;
  user_id?: InputMaybe<StringNullableFilter>;
};

export type EmailSyncRecordUpdateManyMutationInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type EmailSyncRecordUpdateManyWithoutLeadInput = {
  connect?: InputMaybe<Array<EmailSyncRecordWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<EmailSyncRecordCreateOrConnectWithoutLeadInput>>;
  create?: InputMaybe<Array<EmailSyncRecordCreateWithoutLeadInput>>;
  createMany?: InputMaybe<EmailSyncRecordCreateManyLeadInputEnvelope>;
  delete?: InputMaybe<Array<EmailSyncRecordWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<EmailSyncRecordScalarWhereInput>>;
  disconnect?: InputMaybe<Array<EmailSyncRecordWhereUniqueInput>>;
  set?: InputMaybe<Array<EmailSyncRecordWhereUniqueInput>>;
  update?: InputMaybe<Array<EmailSyncRecordUpdateWithWhereUniqueWithoutLeadInput>>;
  updateMany?: InputMaybe<Array<EmailSyncRecordUpdateManyWithWhereWithoutLeadInput>>;
  upsert?: InputMaybe<Array<EmailSyncRecordUpsertWithWhereUniqueWithoutLeadInput>>;
};

export type EmailSyncRecordUpdateManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<EmailSyncRecordWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<EmailSyncRecordCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<EmailSyncRecordCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<EmailSyncRecordCreateManyOrganizationInputEnvelope>;
  delete?: InputMaybe<Array<EmailSyncRecordWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<EmailSyncRecordScalarWhereInput>>;
  disconnect?: InputMaybe<Array<EmailSyncRecordWhereUniqueInput>>;
  set?: InputMaybe<Array<EmailSyncRecordWhereUniqueInput>>;
  update?: InputMaybe<Array<EmailSyncRecordUpdateWithWhereUniqueWithoutOrganizationInput>>;
  updateMany?: InputMaybe<Array<EmailSyncRecordUpdateManyWithWhereWithoutOrganizationInput>>;
  upsert?: InputMaybe<Array<EmailSyncRecordUpsertWithWhereUniqueWithoutOrganizationInput>>;
};

export type EmailSyncRecordUpdateManyWithoutUserInput = {
  connect?: InputMaybe<Array<EmailSyncRecordWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<EmailSyncRecordCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<EmailSyncRecordCreateWithoutUserInput>>;
  createMany?: InputMaybe<EmailSyncRecordCreateManyUserInputEnvelope>;
  delete?: InputMaybe<Array<EmailSyncRecordWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<EmailSyncRecordScalarWhereInput>>;
  disconnect?: InputMaybe<Array<EmailSyncRecordWhereUniqueInput>>;
  set?: InputMaybe<Array<EmailSyncRecordWhereUniqueInput>>;
  update?: InputMaybe<Array<EmailSyncRecordUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: InputMaybe<Array<EmailSyncRecordUpdateManyWithWhereWithoutUserInput>>;
  upsert?: InputMaybe<Array<EmailSyncRecordUpsertWithWhereUniqueWithoutUserInput>>;
};

export type EmailSyncRecordUpdateManyWithWhereWithoutLeadInput = {
  data: EmailSyncRecordUpdateManyMutationInput;
  where: EmailSyncRecordScalarWhereInput;
};

export type EmailSyncRecordUpdateManyWithWhereWithoutOrganizationInput = {
  data: EmailSyncRecordUpdateManyMutationInput;
  where: EmailSyncRecordScalarWhereInput;
};

export type EmailSyncRecordUpdateManyWithWhereWithoutUserInput = {
  data: EmailSyncRecordUpdateManyMutationInput;
  where: EmailSyncRecordScalarWhereInput;
};

export type EmailSyncRecordUpdateWithoutLeadInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutEmailSyncRecordInput>;
  user?: InputMaybe<UserUpdateOneWithoutEmailSyncRecordInput>;
};

export type EmailSyncRecordUpdateWithoutOrganizationInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lead?: InputMaybe<LeadUpdateOneWithoutEmailSyncRecordInput>;
  user?: InputMaybe<UserUpdateOneWithoutEmailSyncRecordInput>;
};

export type EmailSyncRecordUpdateWithoutUserInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lead?: InputMaybe<LeadUpdateOneWithoutEmailSyncRecordInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutEmailSyncRecordInput>;
};

export type EmailSyncRecordUpdateWithWhereUniqueWithoutLeadInput = {
  data: EmailSyncRecordUpdateWithoutLeadInput;
  where: EmailSyncRecordWhereUniqueInput;
};

export type EmailSyncRecordUpdateWithWhereUniqueWithoutOrganizationInput = {
  data: EmailSyncRecordUpdateWithoutOrganizationInput;
  where: EmailSyncRecordWhereUniqueInput;
};

export type EmailSyncRecordUpdateWithWhereUniqueWithoutUserInput = {
  data: EmailSyncRecordUpdateWithoutUserInput;
  where: EmailSyncRecordWhereUniqueInput;
};

export type EmailSyncRecordUpsertWithWhereUniqueWithoutLeadInput = {
  create: EmailSyncRecordCreateWithoutLeadInput;
  update: EmailSyncRecordUpdateWithoutLeadInput;
  where: EmailSyncRecordWhereUniqueInput;
};

export type EmailSyncRecordUpsertWithWhereUniqueWithoutOrganizationInput = {
  create: EmailSyncRecordCreateWithoutOrganizationInput;
  update: EmailSyncRecordUpdateWithoutOrganizationInput;
  where: EmailSyncRecordWhereUniqueInput;
};

export type EmailSyncRecordUpsertWithWhereUniqueWithoutUserInput = {
  create: EmailSyncRecordCreateWithoutUserInput;
  update: EmailSyncRecordUpdateWithoutUserInput;
  where: EmailSyncRecordWhereUniqueInput;
};

export type EmailSyncRecordWhereInput = {
  AND?: InputMaybe<Array<EmailSyncRecordWhereInput>>;
  created_at?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  lead?: InputMaybe<LeadWhereInput>;
  lead_id?: InputMaybe<StringNullableFilter>;
  NOT?: InputMaybe<Array<EmailSyncRecordWhereInput>>;
  OR?: InputMaybe<Array<EmailSyncRecordWhereInput>>;
  organization?: InputMaybe<OrganizationWhereInput>;
  organization_id?: InputMaybe<StringFilter>;
  user?: InputMaybe<UserWhereInput>;
  user_id?: InputMaybe<StringNullableFilter>;
};

export type EmailSyncRecordWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']['input']>;
};

export type EmailThread = {
  __typename?: 'EmailThread';
  email_items: Array<EmailItem>;
  id: Scalars['String']['output'];
};


export type EmailThreadEmail_ItemsArgs = {
  after?: InputMaybe<EmailItemWhereUniqueInput>;
  before?: InputMaybe<EmailItemWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type EmailThreadCreateManyLeadInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  organization_id?: InputMaybe<Scalars['String']['input']>;
  origin?: InputMaybe<Emailthreadorigin>;
};

export type EmailThreadCreateManyLeadInputEnvelope = {
  data?: InputMaybe<Array<EmailThreadCreateManyLeadInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type EmailThreadCreateManyOrganizationInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  lead_id?: InputMaybe<Scalars['String']['input']>;
  origin?: InputMaybe<Emailthreadorigin>;
};

export type EmailThreadCreateManyOrganizationInputEnvelope = {
  data?: InputMaybe<Array<EmailThreadCreateManyOrganizationInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type EmailThreadCreateNestedManyWithoutLeadInput = {
  connect?: InputMaybe<Array<EmailThreadWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<EmailThreadCreateOrConnectWithoutLeadInput>>;
  create?: InputMaybe<Array<EmailThreadCreateWithoutLeadInput>>;
  createMany?: InputMaybe<EmailThreadCreateManyLeadInputEnvelope>;
};

export type EmailThreadCreateNestedManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<EmailThreadWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<EmailThreadCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<EmailThreadCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<EmailThreadCreateManyOrganizationInputEnvelope>;
};

export type EmailThreadCreateNestedOneWithoutEmail_ItemsInput = {
  connect?: InputMaybe<EmailThreadWhereUniqueInput>;
  connectOrCreate?: InputMaybe<EmailThreadCreateOrConnectWithoutEmail_ItemsInput>;
  create?: InputMaybe<EmailThreadCreateWithoutEmail_ItemsInput>;
};

export type EmailThreadCreateOrConnectWithoutEmail_ItemsInput = {
  create: EmailThreadCreateWithoutEmail_ItemsInput;
  where: EmailThreadWhereUniqueInput;
};

export type EmailThreadCreateOrConnectWithoutLeadInput = {
  create: EmailThreadCreateWithoutLeadInput;
  where: EmailThreadWhereUniqueInput;
};

export type EmailThreadCreateOrConnectWithoutOrganizationInput = {
  create: EmailThreadCreateWithoutOrganizationInput;
  where: EmailThreadWhereUniqueInput;
};

export type EmailThreadCreateWithoutEmail_ItemsInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  lead?: InputMaybe<LeadCreateNestedOneWithoutEmailThreadInput>;
  Organization?: InputMaybe<OrganizationCreateNestedOneWithoutEmailThreadsInput>;
  origin?: InputMaybe<Emailthreadorigin>;
};

export type EmailThreadCreateWithoutLeadInput = {
  email_items?: InputMaybe<EmailItemCreateNestedManyWithoutEmail_ThreadInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  Organization?: InputMaybe<OrganizationCreateNestedOneWithoutEmailThreadsInput>;
  origin?: InputMaybe<Emailthreadorigin>;
};

export type EmailThreadCreateWithoutOrganizationInput = {
  email_items?: InputMaybe<EmailItemCreateNestedManyWithoutEmail_ThreadInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  lead?: InputMaybe<LeadCreateNestedOneWithoutEmailThreadInput>;
  origin?: InputMaybe<Emailthreadorigin>;
};

export type EmailThreadListRelationFilter = {
  every?: InputMaybe<EmailThreadWhereInput>;
  none?: InputMaybe<EmailThreadWhereInput>;
  some?: InputMaybe<EmailThreadWhereInput>;
};

export type EmailThreadOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
  count?: InputMaybe<SortOrder>;
};

export type EmailThreadOrderByWithRelationInput = {
  email_items?: InputMaybe<EmailItemOrderByRelationAggregateInput>;
  id?: InputMaybe<SortOrder>;
  lead?: InputMaybe<LeadOrderByWithRelationInput>;
  lead_id?: InputMaybe<SortOrder>;
  Organization?: InputMaybe<OrganizationOrderByWithRelationInput>;
  organization_id?: InputMaybe<SortOrder>;
  origin?: InputMaybe<SortOrder>;
};

export enum Emailthreadorigin {
  AutomatedCampaign = 'AutomatedCampaign',
  Extension = 'Extension',
  Frontend = 'Frontend',
  SequenceAutomatedEmail = 'SequenceAutomatedEmail'
}

export type EmailThreadScalarWhereInput = {
  AND?: InputMaybe<Array<EmailThreadScalarWhereInput>>;
  id?: InputMaybe<StringFilter>;
  lead_id?: InputMaybe<StringNullableFilter>;
  NOT?: InputMaybe<Array<EmailThreadScalarWhereInput>>;
  OR?: InputMaybe<Array<EmailThreadScalarWhereInput>>;
  organization_id?: InputMaybe<StringNullableFilter>;
  origin?: InputMaybe<EnumEmailthreadoriginFilter>;
};

export type EmailThreadUpdateManyMutationInput = {
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  origin?: InputMaybe<EnumEmailthreadoriginFieldUpdateOperationsInput>;
};

export type EmailThreadUpdateManyWithoutLeadInput = {
  connect?: InputMaybe<Array<EmailThreadWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<EmailThreadCreateOrConnectWithoutLeadInput>>;
  create?: InputMaybe<Array<EmailThreadCreateWithoutLeadInput>>;
  createMany?: InputMaybe<EmailThreadCreateManyLeadInputEnvelope>;
  delete?: InputMaybe<Array<EmailThreadWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<EmailThreadScalarWhereInput>>;
  disconnect?: InputMaybe<Array<EmailThreadWhereUniqueInput>>;
  set?: InputMaybe<Array<EmailThreadWhereUniqueInput>>;
  update?: InputMaybe<Array<EmailThreadUpdateWithWhereUniqueWithoutLeadInput>>;
  updateMany?: InputMaybe<Array<EmailThreadUpdateManyWithWhereWithoutLeadInput>>;
  upsert?: InputMaybe<Array<EmailThreadUpsertWithWhereUniqueWithoutLeadInput>>;
};

export type EmailThreadUpdateManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<EmailThreadWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<EmailThreadCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<EmailThreadCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<EmailThreadCreateManyOrganizationInputEnvelope>;
  delete?: InputMaybe<Array<EmailThreadWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<EmailThreadScalarWhereInput>>;
  disconnect?: InputMaybe<Array<EmailThreadWhereUniqueInput>>;
  set?: InputMaybe<Array<EmailThreadWhereUniqueInput>>;
  update?: InputMaybe<Array<EmailThreadUpdateWithWhereUniqueWithoutOrganizationInput>>;
  updateMany?: InputMaybe<Array<EmailThreadUpdateManyWithWhereWithoutOrganizationInput>>;
  upsert?: InputMaybe<Array<EmailThreadUpsertWithWhereUniqueWithoutOrganizationInput>>;
};

export type EmailThreadUpdateManyWithWhereWithoutLeadInput = {
  data: EmailThreadUpdateManyMutationInput;
  where: EmailThreadScalarWhereInput;
};

export type EmailThreadUpdateManyWithWhereWithoutOrganizationInput = {
  data: EmailThreadUpdateManyMutationInput;
  where: EmailThreadScalarWhereInput;
};

export type EmailThreadUpdateOneWithoutEmail_ItemsInput = {
  connect?: InputMaybe<EmailThreadWhereUniqueInput>;
  connectOrCreate?: InputMaybe<EmailThreadCreateOrConnectWithoutEmail_ItemsInput>;
  create?: InputMaybe<EmailThreadCreateWithoutEmail_ItemsInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<EmailThreadUpdateWithoutEmail_ItemsInput>;
  upsert?: InputMaybe<EmailThreadUpsertWithoutEmail_ItemsInput>;
};

export type EmailThreadUpdateWithoutEmail_ItemsInput = {
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lead?: InputMaybe<LeadUpdateOneWithoutEmailThreadInput>;
  Organization?: InputMaybe<OrganizationUpdateOneWithoutEmailThreadsInput>;
  origin?: InputMaybe<EnumEmailthreadoriginFieldUpdateOperationsInput>;
};

export type EmailThreadUpdateWithoutLeadInput = {
  email_items?: InputMaybe<EmailItemUpdateManyWithoutEmail_ThreadInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  Organization?: InputMaybe<OrganizationUpdateOneWithoutEmailThreadsInput>;
  origin?: InputMaybe<EnumEmailthreadoriginFieldUpdateOperationsInput>;
};

export type EmailThreadUpdateWithoutOrganizationInput = {
  email_items?: InputMaybe<EmailItemUpdateManyWithoutEmail_ThreadInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lead?: InputMaybe<LeadUpdateOneWithoutEmailThreadInput>;
  origin?: InputMaybe<EnumEmailthreadoriginFieldUpdateOperationsInput>;
};

export type EmailThreadUpdateWithWhereUniqueWithoutLeadInput = {
  data: EmailThreadUpdateWithoutLeadInput;
  where: EmailThreadWhereUniqueInput;
};

export type EmailThreadUpdateWithWhereUniqueWithoutOrganizationInput = {
  data: EmailThreadUpdateWithoutOrganizationInput;
  where: EmailThreadWhereUniqueInput;
};

export type EmailThreadUpsertWithoutEmail_ItemsInput = {
  create: EmailThreadCreateWithoutEmail_ItemsInput;
  update: EmailThreadUpdateWithoutEmail_ItemsInput;
};

export type EmailThreadUpsertWithWhereUniqueWithoutLeadInput = {
  create: EmailThreadCreateWithoutLeadInput;
  update: EmailThreadUpdateWithoutLeadInput;
  where: EmailThreadWhereUniqueInput;
};

export type EmailThreadUpsertWithWhereUniqueWithoutOrganizationInput = {
  create: EmailThreadCreateWithoutOrganizationInput;
  update: EmailThreadUpdateWithoutOrganizationInput;
  where: EmailThreadWhereUniqueInput;
};

export type EmailThreadWhereInput = {
  AND?: InputMaybe<Array<EmailThreadWhereInput>>;
  email_items?: InputMaybe<EmailItemListRelationFilter>;
  id?: InputMaybe<StringFilter>;
  lead?: InputMaybe<LeadWhereInput>;
  lead_id?: InputMaybe<StringNullableFilter>;
  NOT?: InputMaybe<Array<EmailThreadWhereInput>>;
  OR?: InputMaybe<Array<EmailThreadWhereInput>>;
  Organization?: InputMaybe<OrganizationWhereInput>;
  organization_id?: InputMaybe<StringNullableFilter>;
  origin?: InputMaybe<EnumEmailthreadoriginFilter>;
};

export type EmailThreadWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']['input']>;
};

export enum EntityType {
  Lead = 'Lead',
  User = 'User'
}

export type EnumAm_TypeFieldUpdateOperationsInput = {
  set?: InputMaybe<Am_Type>;
};

export type EnumAm_TypeFilter = {
  equals?: InputMaybe<Am_Type>;
  in?: InputMaybe<Array<Am_Type>>;
  not?: InputMaybe<NestedEnumAm_TypeFilter>;
  notIn?: InputMaybe<Array<Am_Type>>;
};

export type EnumAssignmentOriginFieldUpdateOperationsInput = {
  set?: InputMaybe<AssignmentOrigin>;
};

export type EnumAssignmentOriginFilter = {
  equals?: InputMaybe<AssignmentOrigin>;
  in?: InputMaybe<Array<AssignmentOrigin>>;
  not?: InputMaybe<NestedEnumAssignmentOriginFilter>;
  notIn?: InputMaybe<Array<AssignmentOrigin>>;
};

export type EnumAssignTypeFieldUpdateOperationsInput = {
  set?: InputMaybe<AssignType>;
};

export type EnumAssignTypeFilter = {
  equals?: InputMaybe<AssignType>;
  in?: InputMaybe<Array<AssignType>>;
  not?: InputMaybe<NestedEnumAssignTypeFilter>;
  notIn?: InputMaybe<Array<AssignType>>;
};

export type EnumAssociationHistoryActionFieldUpdateOperationsInput = {
  set?: InputMaybe<AssociationHistoryAction>;
};

export type EnumAssociationHistoryActionFilter = {
  equals?: InputMaybe<AssociationHistoryAction>;
  in?: InputMaybe<Array<AssociationHistoryAction>>;
  not?: InputMaybe<NestedEnumAssociationHistoryActionFilter>;
  notIn?: InputMaybe<Array<AssociationHistoryAction>>;
};

export type EnumAutoSelectionTypeFieldUpdateOperationsInput = {
  set?: InputMaybe<AutoSelectionType>;
};

export type EnumAutoSelectionTypeFilter = {
  equals?: InputMaybe<AutoSelectionType>;
  in?: InputMaybe<Array<AutoSelectionType>>;
  not?: InputMaybe<NestedEnumAutoSelectionTypeFilter>;
  notIn?: InputMaybe<Array<AutoSelectionType>>;
};

export type EnumBackUpRuleFieldUpdateOperationsInput = {
  set?: InputMaybe<BackUpRule>;
};

export type EnumBackUpRuleFilter = {
  equals?: InputMaybe<BackUpRule>;
  in?: InputMaybe<Array<BackUpRule>>;
  not?: InputMaybe<NestedEnumBackUpRuleFilter>;
  notIn?: InputMaybe<Array<BackUpRule>>;
};

export type EnumBest_Metric_Record_TypeFieldUpdateOperationsInput = {
  set?: InputMaybe<Best_Metric_Record_Type>;
};

export type EnumBest_Metric_Record_TypeFilter = {
  equals?: InputMaybe<Best_Metric_Record_Type>;
  in?: InputMaybe<Array<Best_Metric_Record_Type>>;
  not?: InputMaybe<NestedEnumBest_Metric_Record_TypeFilter>;
  notIn?: InputMaybe<Array<Best_Metric_Record_Type>>;
};

export type EnumBgMusicOptionFieldUpdateOperationsInput = {
  set?: InputMaybe<BgMusicOption>;
};

export type EnumBgMusicOptionFilter = {
  equals?: InputMaybe<BgMusicOption>;
  in?: InputMaybe<Array<BgMusicOption>>;
  not?: InputMaybe<NestedEnumBgMusicOptionFilter>;
  notIn?: InputMaybe<Array<BgMusicOption>>;
};

export type EnumCadenceFieldUpdateOperationsInput = {
  set?: InputMaybe<Cadence>;
};

export type EnumCadenceFilter = {
  equals?: InputMaybe<Cadence>;
  in?: InputMaybe<Array<Cadence>>;
  not?: InputMaybe<NestedEnumCadenceFilter>;
  notIn?: InputMaybe<Array<Cadence>>;
};

export type EnumCallbackTypeFieldUpdateOperationsInput = {
  set?: InputMaybe<CallbackType>;
};

export type EnumCallbackTypeFilter = {
  equals?: InputMaybe<CallbackType>;
  in?: InputMaybe<Array<CallbackType>>;
  not?: InputMaybe<NestedEnumCallbackTypeFilter>;
  notIn?: InputMaybe<Array<CallbackType>>;
};

export type EnumCallbackTypeNullableFilter = {
  equals?: InputMaybe<CallbackType>;
  in?: InputMaybe<Array<CallbackType>>;
  not?: InputMaybe<NestedEnumCallbackTypeNullableFilter>;
  notIn?: InputMaybe<Array<CallbackType>>;
};

export type EnumCallMeNowStatusFieldUpdateOperationsInput = {
  set?: InputMaybe<CallMeNowStatus>;
};

export type EnumCallMeNowStatusFilter = {
  equals?: InputMaybe<CallMeNowStatus>;
  in?: InputMaybe<Array<CallMeNowStatus>>;
  not?: InputMaybe<NestedEnumCallMeNowStatusFilter>;
  notIn?: InputMaybe<Array<CallMeNowStatus>>;
};

export type EnumCallresultNullableFilter = {
  equals?: InputMaybe<Callresult>;
  in?: InputMaybe<Array<Callresult>>;
  not?: InputMaybe<NestedEnumCallresultNullableFilter>;
  notIn?: InputMaybe<Array<Callresult>>;
};

export type EnumCallresultNullableListFilter = {
  equals?: InputMaybe<Array<Callresult>>;
  has?: InputMaybe<Callresult>;
  hasEvery?: InputMaybe<Array<Callresult>>;
  hasSome?: InputMaybe<Array<Callresult>>;
  isEmpty?: InputMaybe<Scalars['Boolean']['input']>;
};

export type EnumChannelfilterNullableFilter = {
  equals?: InputMaybe<Channelfilter>;
  in?: InputMaybe<Array<Channelfilter>>;
  not?: InputMaybe<NestedEnumChannelfilterNullableFilter>;
  notIn?: InputMaybe<Array<Channelfilter>>;
};

export type EnumChannelNullableFilter = {
  equals?: InputMaybe<Channel>;
  in?: InputMaybe<Array<Channel>>;
  not?: InputMaybe<NestedEnumChannelNullableFilter>;
  notIn?: InputMaybe<Array<Channel>>;
};

export type EnumChannelNullableListFilter = {
  equals?: InputMaybe<Array<Channel>>;
  has?: InputMaybe<Channel>;
  hasEvery?: InputMaybe<Array<Channel>>;
  hasSome?: InputMaybe<Array<Channel>>;
  isEmpty?: InputMaybe<Scalars['Boolean']['input']>;
};

export type EnumCommunication_TypeFieldUpdateOperationsInput = {
  set?: InputMaybe<Communication_Type>;
};

export type EnumCommunication_TypeFilter = {
  equals?: InputMaybe<Communication_Type>;
  in?: InputMaybe<Array<Communication_Type>>;
  not?: InputMaybe<NestedEnumCommunication_TypeFilter>;
  notIn?: InputMaybe<Array<Communication_Type>>;
};

export type EnumCommunication_TypeNullableFilter = {
  equals?: InputMaybe<Communication_Type>;
  in?: InputMaybe<Array<Communication_Type>>;
  not?: InputMaybe<NestedEnumCommunication_TypeNullableFilter>;
  notIn?: InputMaybe<Array<Communication_Type>>;
};

export type EnumConcessiontypeFieldUpdateOperationsInput = {
  set?: InputMaybe<Concessiontype>;
};

export type EnumConcessiontypeFilter = {
  equals?: InputMaybe<Concessiontype>;
  in?: InputMaybe<Array<Concessiontype>>;
  not?: InputMaybe<NestedEnumConcessiontypeFilter>;
  notIn?: InputMaybe<Array<Concessiontype>>;
};

export type EnumconditionTypeFieldUpdateOperationsInput = {
  set?: InputMaybe<ConditionType>;
};

export type EnumconditionTypeFilter = {
  equals?: InputMaybe<ConditionType>;
  in?: InputMaybe<Array<ConditionType>>;
  not?: InputMaybe<NestedEnumconditionTypeFilter>;
  notIn?: InputMaybe<Array<ConditionType>>;
};

export type EnumConferenceRegionFieldUpdateOperationsInput = {
  set?: InputMaybe<ConferenceRegion>;
};

export type EnumConferenceRegionFilter = {
  equals?: InputMaybe<ConferenceRegion>;
  in?: InputMaybe<Array<ConferenceRegion>>;
  not?: InputMaybe<NestedEnumConferenceRegionFilter>;
  notIn?: InputMaybe<Array<ConferenceRegion>>;
};

export type EnumContactTypeNullableFilter = {
  equals?: InputMaybe<ContactType>;
  in?: InputMaybe<Array<ContactType>>;
  not?: InputMaybe<NestedEnumContactTypeNullableFilter>;
  notIn?: InputMaybe<Array<ContactType>>;
};

export type EnumCustomFieldTypeFieldUpdateOperationsInput = {
  set?: InputMaybe<CustomFieldType>;
};

export type EnumCustomFieldTypeFilter = {
  equals?: InputMaybe<CustomFieldType>;
  in?: InputMaybe<Array<CustomFieldType>>;
  not?: InputMaybe<NestedEnumCustomFieldTypeFilter>;
  notIn?: InputMaybe<Array<CustomFieldType>>;
};

export type EnumCustomFieldTypeNullableFilter = {
  equals?: InputMaybe<CustomFieldType>;
  in?: InputMaybe<Array<CustomFieldType>>;
  not?: InputMaybe<NestedEnumCustomFieldTypeNullableFilter>;
  notIn?: InputMaybe<Array<CustomFieldType>>;
};

export type EnumDashboard_Group_OptionFieldUpdateOperationsInput = {
  set?: InputMaybe<Dashboard_Group_Option>;
};

export type EnumDashboard_Group_OptionFilter = {
  equals?: InputMaybe<Dashboard_Group_Option>;
  in?: InputMaybe<Array<Dashboard_Group_Option>>;
  not?: InputMaybe<NestedEnumDashboard_Group_OptionFilter>;
  notIn?: InputMaybe<Array<Dashboard_Group_Option>>;
};

export type EnumDateOptionFieldUpdateOperationsInput = {
  set?: InputMaybe<DateOption>;
};

export type EnumDateOptionFilter = {
  equals?: InputMaybe<DateOption>;
  in?: InputMaybe<Array<DateOption>>;
  not?: InputMaybe<NestedEnumDateOptionFilter>;
  notIn?: InputMaybe<Array<DateOption>>;
};

export type EnumDateOptionNullableFilter = {
  equals?: InputMaybe<DateOption>;
  in?: InputMaybe<Array<DateOption>>;
  not?: InputMaybe<NestedEnumDateOptionNullableFilter>;
  notIn?: InputMaybe<Array<DateOption>>;
};

export type EnumDispositiontypeFieldUpdateOperationsInput = {
  set?: InputMaybe<Dispositiontype>;
};

export type EnumDispositiontypeFilter = {
  equals?: InputMaybe<Dispositiontype>;
  in?: InputMaybe<Array<Dispositiontype>>;
  not?: InputMaybe<NestedEnumDispositiontypeFilter>;
  notIn?: InputMaybe<Array<Dispositiontype>>;
};

export type EnumDispositiontypeNullableFilter = {
  equals?: InputMaybe<Dispositiontype>;
  in?: InputMaybe<Array<Dispositiontype>>;
  not?: InputMaybe<NestedEnumDispositiontypeNullableFilter>;
  notIn?: InputMaybe<Array<Dispositiontype>>;
};

export type EnumDispositiontypeNullableListFilter = {
  equals?: InputMaybe<Array<Dispositiontype>>;
  has?: InputMaybe<Dispositiontype>;
  hasEvery?: InputMaybe<Array<Dispositiontype>>;
  hasSome?: InputMaybe<Array<Dispositiontype>>;
  isEmpty?: InputMaybe<Scalars['Boolean']['input']>;
};

export type EnumDistribution_MethodFieldUpdateOperationsInput = {
  set?: InputMaybe<Distribution_Method>;
};

export type EnumDistribution_MethodFilter = {
  equals?: InputMaybe<Distribution_Method>;
  in?: InputMaybe<Array<Distribution_Method>>;
  not?: InputMaybe<NestedEnumDistribution_MethodFilter>;
  notIn?: InputMaybe<Array<Distribution_Method>>;
};

export type EnumEmailthreadoriginFieldUpdateOperationsInput = {
  set?: InputMaybe<Emailthreadorigin>;
};

export type EnumEmailthreadoriginFilter = {
  equals?: InputMaybe<Emailthreadorigin>;
  in?: InputMaybe<Array<Emailthreadorigin>>;
  not?: InputMaybe<NestedEnumEmailthreadoriginFilter>;
  notIn?: InputMaybe<Array<Emailthreadorigin>>;
};

export type EnumEntityTypeFieldUpdateOperationsInput = {
  set?: InputMaybe<EntityType>;
};

export type EnumEntityTypeFilter = {
  equals?: InputMaybe<EntityType>;
  in?: InputMaybe<Array<EntityType>>;
  not?: InputMaybe<NestedEnumEntityTypeFilter>;
  notIn?: InputMaybe<Array<EntityType>>;
};

export type EnumFee_TypeFieldUpdateOperationsInput = {
  set?: InputMaybe<Fee_Type>;
};

export type EnumFee_TypeFilter = {
  equals?: InputMaybe<Fee_Type>;
  in?: InputMaybe<Array<Fee_Type>>;
  not?: InputMaybe<NestedEnumFee_TypeFilter>;
  notIn?: InputMaybe<Array<Fee_Type>>;
};

export type EnumGoalsettingNullableFilter = {
  equals?: InputMaybe<Goalsetting>;
  in?: InputMaybe<Array<Goalsetting>>;
  not?: InputMaybe<NestedEnumGoalsettingNullableFilter>;
  notIn?: InputMaybe<Array<Goalsetting>>;
};

export type EnumHingepointFieldUpdateOperationsInput = {
  set?: InputMaybe<Hingepoint>;
};

export type EnumHingepointFilter = {
  equals?: InputMaybe<Hingepoint>;
  in?: InputMaybe<Array<Hingepoint>>;
  not?: InputMaybe<NestedEnumHingepointFilter>;
  notIn?: InputMaybe<Array<Hingepoint>>;
};

export type EnumHingepointNullableFilter = {
  equals?: InputMaybe<Hingepoint>;
  in?: InputMaybe<Array<Hingepoint>>;
  not?: InputMaybe<NestedEnumHingepointNullableFilter>;
  notIn?: InputMaybe<Array<Hingepoint>>;
};

export type EnumInboundConciergeEventTypeFieldUpdateOperationsInput = {
  set?: InputMaybe<InboundConciergeEventType>;
};

export type EnumInboundConciergeEventTypeFilter = {
  equals?: InputMaybe<InboundConciergeEventType>;
  in?: InputMaybe<Array<InboundConciergeEventType>>;
  not?: InputMaybe<NestedEnumInboundConciergeEventTypeFilter>;
  notIn?: InputMaybe<Array<InboundConciergeEventType>>;
};

export type EnumIntegration_TypeFieldUpdateOperationsInput = {
  set?: InputMaybe<Integration_Type>;
};

export type EnumIntegration_TypeFilter = {
  equals?: InputMaybe<Integration_Type>;
  in?: InputMaybe<Array<Integration_Type>>;
  not?: InputMaybe<NestedEnumIntegration_TypeFilter>;
  notIn?: InputMaybe<Array<Integration_Type>>;
};

export type EnumIntegrationErrorEntityTypeFieldUpdateOperationsInput = {
  set?: InputMaybe<IntegrationErrorEntityType>;
};

export type EnumIntegrationErrorEntityTypeFilter = {
  equals?: InputMaybe<IntegrationErrorEntityType>;
  in?: InputMaybe<Array<IntegrationErrorEntityType>>;
  not?: InputMaybe<NestedEnumIntegrationErrorEntityTypeFilter>;
  notIn?: InputMaybe<Array<IntegrationErrorEntityType>>;
};

export type EnumIntegrationOperationTypeFieldUpdateOperationsInput = {
  set?: InputMaybe<IntegrationOperationType>;
};

export type EnumIntegrationOperationTypeFilter = {
  equals?: InputMaybe<IntegrationOperationType>;
  in?: InputMaybe<Array<IntegrationOperationType>>;
  not?: InputMaybe<NestedEnumIntegrationOperationTypeFilter>;
  notIn?: InputMaybe<Array<IntegrationOperationType>>;
};

export type EnumIntentTypeNullableFilter = {
  equals?: InputMaybe<IntentType>;
  in?: InputMaybe<Array<IntentType>>;
  not?: InputMaybe<NestedEnumIntentTypeNullableFilter>;
  notIn?: InputMaybe<Array<IntentType>>;
};

export type EnumInvitationResultFieldUpdateOperationsInput = {
  set?: InputMaybe<InvitationResult>;
};

export type EnumInvitationResultFilter = {
  equals?: InputMaybe<InvitationResult>;
  in?: InputMaybe<Array<InvitationResult>>;
  not?: InputMaybe<NestedEnumInvitationResultFilter>;
  notIn?: InputMaybe<Array<InvitationResult>>;
};

export type EnumJitterBufferSizeFieldUpdateOperationsInput = {
  set?: InputMaybe<JitterBufferSize>;
};

export type EnumJitterBufferSizeFilter = {
  equals?: InputMaybe<JitterBufferSize>;
  in?: InputMaybe<Array<JitterBufferSize>>;
  not?: InputMaybe<NestedEnumJitterBufferSizeFilter>;
  notIn?: InputMaybe<Array<JitterBufferSize>>;
};

export type EnumLeadcreationsourceNullableFilter = {
  equals?: InputMaybe<Leadcreationsource>;
  in?: InputMaybe<Array<Leadcreationsource>>;
  not?: InputMaybe<NestedEnumLeadcreationsourceNullableFilter>;
  notIn?: InputMaybe<Array<Leadcreationsource>>;
};

export type EnumLeadcreationsourceNullableListFilter = {
  equals?: InputMaybe<Array<Leadcreationsource>>;
  has?: InputMaybe<Leadcreationsource>;
  hasEvery?: InputMaybe<Array<Leadcreationsource>>;
  hasSome?: InputMaybe<Array<Leadcreationsource>>;
  isEmpty?: InputMaybe<Scalars['Boolean']['input']>;
};

export type EnumLeadCreationStatusFieldUpdateOperationsInput = {
  set?: InputMaybe<LeadCreationStatus>;
};

export type EnumLeadCreationStatusFilter = {
  equals?: InputMaybe<LeadCreationStatus>;
  in?: InputMaybe<Array<LeadCreationStatus>>;
  not?: InputMaybe<NestedEnumLeadCreationStatusFilter>;
  notIn?: InputMaybe<Array<LeadCreationStatus>>;
};

export type EnumLeadCustomObjectAssociationSourceFieldUpdateOperationsInput = {
  set?: InputMaybe<LeadCustomObjectAssociationSource>;
};

export type EnumLeadCustomObjectAssociationSourceFilter = {
  equals?: InputMaybe<LeadCustomObjectAssociationSource>;
  in?: InputMaybe<Array<LeadCustomObjectAssociationSource>>;
  not?: InputMaybe<NestedEnumLeadCustomObjectAssociationSourceFilter>;
  notIn?: InputMaybe<Array<LeadCustomObjectAssociationSource>>;
};

export type EnumLeadImportTypeFieldUpdateOperationsInput = {
  set?: InputMaybe<LeadImportType>;
};

export type EnumLeadImportTypeFilter = {
  equals?: InputMaybe<LeadImportType>;
  in?: InputMaybe<Array<LeadImportType>>;
  not?: InputMaybe<NestedEnumLeadImportTypeFilter>;
  notIn?: InputMaybe<Array<LeadImportType>>;
};

export type EnumLeadUploadStatusFieldUpdateOperationsInput = {
  set?: InputMaybe<LeadUploadStatus>;
};

export type EnumLeadUploadStatusFilter = {
  equals?: InputMaybe<LeadUploadStatus>;
  in?: InputMaybe<Array<LeadUploadStatus>>;
  not?: InputMaybe<NestedEnumLeadUploadStatusFilter>;
  notIn?: InputMaybe<Array<LeadUploadStatus>>;
};

export type EnumLfmTypeFieldUpdateOperationsInput = {
  set?: InputMaybe<LfmType>;
};

export type EnumLfmTypeFilter = {
  equals?: InputMaybe<LfmType>;
  in?: InputMaybe<Array<LfmType>>;
  not?: InputMaybe<NestedEnumLfmTypeFilter>;
  notIn?: InputMaybe<Array<LfmType>>;
};

export type EnumLosFieldUpdateOperationsInput = {
  set?: InputMaybe<Los>;
};

export type EnumLosFilter = {
  equals?: InputMaybe<Los>;
  in?: InputMaybe<Array<Los>>;
  not?: InputMaybe<NestedEnumLosFilter>;
  notIn?: InputMaybe<Array<Los>>;
};

export type EnumLosNullableFilter = {
  equals?: InputMaybe<Los>;
  in?: InputMaybe<Array<Los>>;
  not?: InputMaybe<NestedEnumLosNullableFilter>;
  notIn?: InputMaybe<Array<Los>>;
};

export type EnumLosNullableListFilter = {
  equals?: InputMaybe<Array<Los>>;
  has?: InputMaybe<Los>;
  hasEvery?: InputMaybe<Array<Los>>;
  hasSome?: InputMaybe<Array<Los>>;
  isEmpty?: InputMaybe<Scalars['Boolean']['input']>;
};

export type EnumMapping_TypeFieldUpdateOperationsInput = {
  set?: InputMaybe<Mapping_Type>;
};

export type EnumMapping_TypeFilter = {
  equals?: InputMaybe<Mapping_Type>;
  in?: InputMaybe<Array<Mapping_Type>>;
  not?: InputMaybe<NestedEnumMapping_TypeFilter>;
  notIn?: InputMaybe<Array<Mapping_Type>>;
};

export type EnumMeasureByFilterNullableFilter = {
  equals?: InputMaybe<MeasureByFilter>;
  in?: InputMaybe<Array<MeasureByFilter>>;
  not?: InputMaybe<NestedEnumMeasureByFilterNullableFilter>;
  notIn?: InputMaybe<Array<MeasureByFilter>>;
};

export type EnumMetricFieldUpdateOperationsInput = {
  set?: InputMaybe<Metric>;
};

export type EnumMetricFilter = {
  equals?: InputMaybe<Metric>;
  in?: InputMaybe<Array<Metric>>;
  not?: InputMaybe<NestedEnumMetricFilter>;
  notIn?: InputMaybe<Array<Metric>>;
};

export type EnumMetricNullableFilter = {
  equals?: InputMaybe<Metric>;
  in?: InputMaybe<Array<Metric>>;
  not?: InputMaybe<NestedEnumMetricNullableFilter>;
  notIn?: InputMaybe<Array<Metric>>;
};

export type EnumMetrictypeNullableFilter = {
  equals?: InputMaybe<Metrictype>;
  in?: InputMaybe<Array<Metrictype>>;
  not?: InputMaybe<NestedEnumMetrictypeNullableFilter>;
  notIn?: InputMaybe<Array<Metrictype>>;
};

export type EnumMonthNullableFilter = {
  equals?: InputMaybe<Month>;
  in?: InputMaybe<Array<Month>>;
  not?: InputMaybe<NestedEnumMonthNullableFilter>;
  notIn?: InputMaybe<Array<Month>>;
};

export type EnumNotificationTypeFieldUpdateOperationsInput = {
  set?: InputMaybe<NotificationType>;
};

export type EnumNotificationTypeFilter = {
  equals?: InputMaybe<NotificationType>;
  in?: InputMaybe<Array<NotificationType>>;
  not?: InputMaybe<NestedEnumNotificationTypeFilter>;
  notIn?: InputMaybe<Array<NotificationType>>;
};

export type EnumOperationFieldUpdateOperationsInput = {
  set?: InputMaybe<Operation>;
};

export type EnumOperationFilter = {
  equals?: InputMaybe<Operation>;
  in?: InputMaybe<Array<Operation>>;
  not?: InputMaybe<NestedEnumOperationFilter>;
  notIn?: InputMaybe<Array<Operation>>;
};

export type EnumOperatorFieldUpdateOperationsInput = {
  set?: InputMaybe<Operator>;
};

export type EnumOperatorFilter = {
  equals?: InputMaybe<Operator>;
  in?: InputMaybe<Array<Operator>>;
  not?: InputMaybe<NestedEnumOperatorFilter>;
  notIn?: InputMaybe<Array<Operator>>;
};

export type EnumOptInStatusFieldUpdateOperationsInput = {
  set?: InputMaybe<OptInStatus>;
};

export type EnumOptInStatusFilter = {
  equals?: InputMaybe<OptInStatus>;
  in?: InputMaybe<Array<OptInStatus>>;
  not?: InputMaybe<NestedEnumOptInStatusFilter>;
  notIn?: InputMaybe<Array<OptInStatus>>;
};

export type EnumPhaseFieldUpdateOperationsInput = {
  set?: InputMaybe<Phase>;
};

export type EnumPhaseFilter = {
  equals?: InputMaybe<Phase>;
  in?: InputMaybe<Array<Phase>>;
  not?: InputMaybe<NestedEnumPhaseFilter>;
  notIn?: InputMaybe<Array<Phase>>;
};

export type EnumPhaseNullableFilter = {
  equals?: InputMaybe<Phase>;
  in?: InputMaybe<Array<Phase>>;
  not?: InputMaybe<NestedEnumPhaseNullableFilter>;
  notIn?: InputMaybe<Array<Phase>>;
};

export type EnumPhaseNullableListFilter = {
  equals?: InputMaybe<Array<Phase>>;
  has?: InputMaybe<Phase>;
  hasEvery?: InputMaybe<Array<Phase>>;
  hasSome?: InputMaybe<Array<Phase>>;
  isEmpty?: InputMaybe<Scalars['Boolean']['input']>;
};

export type EnumPipelineColumnFieldUpdateOperationsInput = {
  set?: InputMaybe<PipelineColumn>;
};

export type EnumPipelineColumnFilter = {
  equals?: InputMaybe<PipelineColumn>;
  in?: InputMaybe<Array<PipelineColumn>>;
  not?: InputMaybe<NestedEnumPipelineColumnFilter>;
  notIn?: InputMaybe<Array<PipelineColumn>>;
};

export type EnumPositionNullableFilter = {
  equals?: InputMaybe<Position>;
  in?: InputMaybe<Array<Position>>;
  not?: InputMaybe<NestedEnumPositionNullableFilter>;
  notIn?: InputMaybe<Array<Position>>;
};

export type EnumpostActionFieldUpdateOperationsInput = {
  set?: InputMaybe<PostAction>;
};

export type EnumpostActionFilter = {
  equals?: InputMaybe<PostAction>;
  in?: InputMaybe<Array<PostAction>>;
  not?: InputMaybe<NestedEnumpostActionFilter>;
  notIn?: InputMaybe<Array<PostAction>>;
};

export type EnumRankSortTypeFieldUpdateOperationsInput = {
  set?: InputMaybe<RankSortType>;
};

export type EnumRankSortTypeFilter = {
  equals?: InputMaybe<RankSortType>;
  in?: InputMaybe<Array<RankSortType>>;
  not?: InputMaybe<NestedEnumRankSortTypeFilter>;
  notIn?: InputMaybe<Array<RankSortType>>;
};

export type EnumRecordingStatusFieldUpdateOperationsInput = {
  set?: InputMaybe<RecordingStatus>;
};

export type EnumRecordingStatusFilter = {
  equals?: InputMaybe<RecordingStatus>;
  in?: InputMaybe<Array<RecordingStatus>>;
  not?: InputMaybe<NestedEnumRecordingStatusFilter>;
  notIn?: InputMaybe<Array<RecordingStatus>>;
};

export type EnumRep_Selection_MethodFieldUpdateOperationsInput = {
  set?: InputMaybe<Rep_Selection_Method>;
};

export type EnumRep_Selection_MethodFilter = {
  equals?: InputMaybe<Rep_Selection_Method>;
  in?: InputMaybe<Array<Rep_Selection_Method>>;
  not?: InputMaybe<NestedEnumRep_Selection_MethodFilter>;
  notIn?: InputMaybe<Array<Rep_Selection_Method>>;
};

export type EnumRepPriorityRuleFieldUpdateOperationsInput = {
  set?: InputMaybe<RepPriorityRule>;
};

export type EnumRepPriorityRuleFilter = {
  equals?: InputMaybe<RepPriorityRule>;
  in?: InputMaybe<Array<RepPriorityRule>>;
  not?: InputMaybe<NestedEnumRepPriorityRuleFilter>;
  notIn?: InputMaybe<Array<RepPriorityRule>>;
};

export type EnumRestingtypeNullableFilter = {
  equals?: InputMaybe<Restingtype>;
  in?: InputMaybe<Array<Restingtype>>;
  not?: InputMaybe<NestedEnumRestingtypeNullableFilter>;
  notIn?: InputMaybe<Array<Restingtype>>;
};

export type EnumResultTypeFieldUpdateOperationsInput = {
  set?: InputMaybe<ResultType>;
};

export type EnumResultTypeFilter = {
  equals?: InputMaybe<ResultType>;
  in?: InputMaybe<Array<ResultType>>;
  not?: InputMaybe<NestedEnumResultTypeFilter>;
  notIn?: InputMaybe<Array<ResultType>>;
};

export type EnumRoleFieldUpdateOperationsInput = {
  set?: InputMaybe<Role>;
};

export type EnumRoleFilter = {
  equals?: InputMaybe<Role>;
  in?: InputMaybe<Array<Role>>;
  not?: InputMaybe<NestedEnumRoleFilter>;
  notIn?: InputMaybe<Array<Role>>;
};

export type EnumRoleNullableFilter = {
  equals?: InputMaybe<Role>;
  in?: InputMaybe<Array<Role>>;
  not?: InputMaybe<NestedEnumRoleNullableFilter>;
  notIn?: InputMaybe<Array<Role>>;
};

export type EnumRoleNullableListFilter = {
  equals?: InputMaybe<Array<Role>>;
  has?: InputMaybe<Role>;
  hasEvery?: InputMaybe<Array<Role>>;
  hasSome?: InputMaybe<Array<Role>>;
  isEmpty?: InputMaybe<Scalars['Boolean']['input']>;
};

export type EnumRuleTypeFieldUpdateOperationsInput = {
  set?: InputMaybe<RuleType>;
};

export type EnumRuleTypeFilter = {
  equals?: InputMaybe<RuleType>;
  in?: InputMaybe<Array<RuleType>>;
  not?: InputMaybe<NestedEnumRuleTypeFilter>;
  notIn?: InputMaybe<Array<RuleType>>;
};

export type EnumSaleSectionTypeFieldUpdateOperationsInput = {
  set?: InputMaybe<SaleSectionType>;
};

export type EnumSaleSectionTypeFilter = {
  equals?: InputMaybe<SaleSectionType>;
  in?: InputMaybe<Array<SaleSectionType>>;
  not?: InputMaybe<NestedEnumSaleSectionTypeFilter>;
  notIn?: InputMaybe<Array<SaleSectionType>>;
};

export type EnumScheduleItemTypeFieldUpdateOperationsInput = {
  set?: InputMaybe<ScheduleItemType>;
};

export type EnumScheduleItemTypeFilter = {
  equals?: InputMaybe<ScheduleItemType>;
  in?: InputMaybe<Array<ScheduleItemType>>;
  not?: InputMaybe<NestedEnumScheduleItemTypeFilter>;
  notIn?: InputMaybe<Array<ScheduleItemType>>;
};

export type EnumScheduleItemTypeNullableFilter = {
  equals?: InputMaybe<ScheduleItemType>;
  in?: InputMaybe<Array<ScheduleItemType>>;
  not?: InputMaybe<NestedEnumScheduleItemTypeNullableFilter>;
  notIn?: InputMaybe<Array<ScheduleItemType>>;
};

export type EnumScheduleItemTypeNullableListFilter = {
  equals?: InputMaybe<Array<ScheduleItemType>>;
  has?: InputMaybe<ScheduleItemType>;
  hasEvery?: InputMaybe<Array<ScheduleItemType>>;
  hasSome?: InputMaybe<Array<ScheduleItemType>>;
  isEmpty?: InputMaybe<Scalars['Boolean']['input']>;
};

export type EnumSequenceEntryExitTypeFieldUpdateOperationsInput = {
  set?: InputMaybe<SequenceEntryExitType>;
};

export type EnumSequenceEntryExitTypeFilter = {
  equals?: InputMaybe<SequenceEntryExitType>;
  in?: InputMaybe<Array<SequenceEntryExitType>>;
  not?: InputMaybe<NestedEnumSequenceEntryExitTypeFilter>;
  notIn?: InputMaybe<Array<SequenceEntryExitType>>;
};

export type EnumSequenceExitTypeNullableFilter = {
  equals?: InputMaybe<SequenceExitType>;
  in?: InputMaybe<Array<SequenceExitType>>;
  not?: InputMaybe<NestedEnumSequenceExitTypeNullableFilter>;
  notIn?: InputMaybe<Array<SequenceExitType>>;
};

export type EnumSequenceStepScheduledEventBehaviorFieldUpdateOperationsInput = {
  set?: InputMaybe<SequenceStepScheduledEventBehavior>;
};

export type EnumSequenceStepScheduledEventBehaviorFilter = {
  equals?: InputMaybe<SequenceStepScheduledEventBehavior>;
  in?: InputMaybe<Array<SequenceStepScheduledEventBehavior>>;
  not?: InputMaybe<NestedEnumSequenceStepScheduledEventBehaviorFilter>;
  notIn?: InputMaybe<Array<SequenceStepScheduledEventBehavior>>;
};

export type EnumSequenceTaskNullableFilter = {
  equals?: InputMaybe<SequenceTask>;
  in?: InputMaybe<Array<SequenceTask>>;
  not?: InputMaybe<NestedEnumSequenceTaskNullableFilter>;
  notIn?: InputMaybe<Array<SequenceTask>>;
};

export type EnumStatItemAggregationTypeFieldUpdateOperationsInput = {
  set?: InputMaybe<StatItemAggregationType>;
};

export type EnumStatItemAggregationTypeFilter = {
  equals?: InputMaybe<StatItemAggregationType>;
  in?: InputMaybe<Array<StatItemAggregationType>>;
  not?: InputMaybe<NestedEnumStatItemAggregationTypeFilter>;
  notIn?: InputMaybe<Array<StatItemAggregationType>>;
};

export type EnumStatusFieldUpdateOperationsInput = {
  set?: InputMaybe<Status>;
};

export type EnumStatusFilter = {
  equals?: InputMaybe<Status>;
  in?: InputMaybe<Array<Status>>;
  not?: InputMaybe<NestedEnumStatusFilter>;
  notIn?: InputMaybe<Array<Status>>;
};

export type EnumSubscriptioneventtypeFieldUpdateOperationsInput = {
  set?: InputMaybe<Subscriptioneventtype>;
};

export type EnumSubscriptioneventtypeFilter = {
  equals?: InputMaybe<Subscriptioneventtype>;
  in?: InputMaybe<Array<Subscriptioneventtype>>;
  not?: InputMaybe<NestedEnumSubscriptioneventtypeFilter>;
  notIn?: InputMaybe<Array<Subscriptioneventtype>>;
};

export type EnumSync_RuleFieldUpdateOperationsInput = {
  set?: InputMaybe<Sync_Rule>;
};

export type EnumSync_RuleFilter = {
  equals?: InputMaybe<Sync_Rule>;
  in?: InputMaybe<Array<Sync_Rule>>;
  not?: InputMaybe<NestedEnumSync_RuleFilter>;
  notIn?: InputMaybe<Array<Sync_Rule>>;
};

export type EnumSystem_EventNullableListFilter = {
  equals?: InputMaybe<Array<System_Event>>;
  has?: InputMaybe<System_Event>;
  hasEvery?: InputMaybe<Array<System_Event>>;
  hasSome?: InputMaybe<Array<System_Event>>;
  isEmpty?: InputMaybe<Scalars['Boolean']['input']>;
};

export type EnumSystemFieldTypesNullableFilter = {
  equals?: InputMaybe<SystemFieldTypes>;
  in?: InputMaybe<Array<SystemFieldTypes>>;
  not?: InputMaybe<NestedEnumSystemFieldTypesNullableFilter>;
  notIn?: InputMaybe<Array<SystemFieldTypes>>;
};

export type EnumTemplateTypeFieldUpdateOperationsInput = {
  set?: InputMaybe<TemplateType>;
};

export type EnumTemplateTypeFilter = {
  equals?: InputMaybe<TemplateType>;
  in?: InputMaybe<Array<TemplateType>>;
  not?: InputMaybe<NestedEnumTemplateTypeFilter>;
  notIn?: InputMaybe<Array<TemplateType>>;
};

export type EnumTimekittypeFieldUpdateOperationsInput = {
  set?: InputMaybe<Timekittype>;
};

export type EnumTimekittypeFilter = {
  equals?: InputMaybe<Timekittype>;
  in?: InputMaybe<Array<Timekittype>>;
  not?: InputMaybe<NestedEnumTimekittypeFilter>;
  notIn?: InputMaybe<Array<Timekittype>>;
};

export type EnumTimeoffrequestresultNullableFilter = {
  equals?: InputMaybe<Timeoffrequestresult>;
  in?: InputMaybe<Array<Timeoffrequestresult>>;
  not?: InputMaybe<NestedEnumTimeoffrequestresultNullableFilter>;
  notIn?: InputMaybe<Array<Timeoffrequestresult>>;
};

export type EnumTransferActionResultFieldUpdateOperationsInput = {
  set?: InputMaybe<TransferActionResult>;
};

export type EnumTransferActionResultFilter = {
  equals?: InputMaybe<TransferActionResult>;
  in?: InputMaybe<Array<TransferActionResult>>;
  not?: InputMaybe<NestedEnumTransferActionResultFilter>;
  notIn?: InputMaybe<Array<TransferActionResult>>;
};

export type EnumTransferAttemptStatusFieldUpdateOperationsInput = {
  set?: InputMaybe<TransferAttemptStatus>;
};

export type EnumTransferAttemptStatusFilter = {
  equals?: InputMaybe<TransferAttemptStatus>;
  in?: InputMaybe<Array<TransferAttemptStatus>>;
  not?: InputMaybe<NestedEnumTransferAttemptStatusFilter>;
  notIn?: InputMaybe<Array<TransferAttemptStatus>>;
};

export type EnumTransferRuleTypeFieldUpdateOperationsInput = {
  set?: InputMaybe<TransferRuleType>;
};

export type EnumTransferRuleTypeFilter = {
  equals?: InputMaybe<TransferRuleType>;
  in?: InputMaybe<Array<TransferRuleType>>;
  not?: InputMaybe<NestedEnumTransferRuleTypeFilter>;
  notIn?: InputMaybe<Array<TransferRuleType>>;
};

export type EnumTransferTypeFieldUpdateOperationsInput = {
  set?: InputMaybe<TransferType>;
};

export type EnumTransferTypeFilter = {
  equals?: InputMaybe<TransferType>;
  in?: InputMaybe<Array<TransferType>>;
  not?: InputMaybe<NestedEnumTransferTypeFilter>;
  notIn?: InputMaybe<Array<TransferType>>;
};

export type EnumWeekdayFieldUpdateOperationsInput = {
  set?: InputMaybe<Weekday>;
};

export type EnumWeekdayFilter = {
  equals?: InputMaybe<Weekday>;
  in?: InputMaybe<Array<Weekday>>;
  not?: InputMaybe<NestedEnumWeekdayFilter>;
  notIn?: InputMaybe<Array<Weekday>>;
};

export type EnumWhenNullableFilter = {
  equals?: InputMaybe<When>;
  in?: InputMaybe<Array<When>>;
  not?: InputMaybe<NestedEnumWhenNullableFilter>;
  notIn?: InputMaybe<Array<When>>;
};

/** Summarized Dashboard Input */
export type EventDetailArgument = {
  computed_id: Scalars['String']['input'];
  date_filter: DashboardDateOption;
  desc?: InputMaybe<Scalars['Boolean']['input']>;
  lead_filter: DashboardLeadFilterOption;
  metric: Metric;
  order_by?: InputMaybe<EventReportListSortOption>;
  position: Position;
  rep_filter: DashboardRepFilterOption;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};

/** Events */
export type EventObject = {
  __typename?: 'EventObject';
  end?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['String']['output'];
  label: Scalars['String']['output'];
  lead_value: Scalars['Float']['output'];
  start?: Maybe<Scalars['DateTime']['output']>;
  type: IntentType;
};

/** available option for sorting Event Report List in event view */
export enum EventReportListSortOption {
  BusinessName = 'BusinessName',
  CallDuration = 'CallDuration',
  CallTime = 'CallTime',
  Close = 'Close',
  ClosedByUser = 'ClosedByUser',
  DaysSetOut = 'DaysSetOut',
  EventTime = 'EventTime',
  EventType = 'EventType',
  Held = 'Held',
  Industry = 'Industry',
  LeadId = 'LeadID',
  LeadName = 'LeadName',
  LeadSource = 'LeadSource',
  RepName = 'RepName',
  ScheduleItemCallResult = 'ScheduleItemCallResult',
  Set = 'Set',
  SetByUser = 'SetByUser',
  SubIndustry = 'SubIndustry'
}

/** CallDetailStatSummary with lead activities list */
export type ExportCallReportType = {
  __typename?: 'ExportCallReportType';
  url: Scalars['String']['output'];
};

/** export dashboard stats */
export type ExportDashboardDataType = {
  __typename?: 'ExportDashboardDataType';
  url: Scalars['String']['output'];
};

/** Export URL Type */
export type ExportUrlType = {
  __typename?: 'ExportURLType';
  url: Scalars['String']['output'];
};

export type ExternalFieldDataType = {
  __typename?: 'ExternalFieldDataType';
  custom: Scalars['Boolean']['output'];
  field_id: Scalars['String']['output'];
  field_label: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

/** Default Federal Holiday */
export type FederalHoliday = {
  __typename?: 'FederalHoliday';
  date: Scalars['Date']['output'];
  dateString: Scalars['String']['output'];
  name: Scalars['String']['output'];
  observe: Scalars['Boolean']['output'];
};

/** Default Federal Holiday */
export type FederalHolidayInput = {
  date: Scalars['DateTime']['input'];
  dateString: Scalars['String']['input'];
  name: Scalars['String']['input'];
  observe: Scalars['Boolean']['input'];
};

export enum Fee_Type {
  OneTime = 'ONE_TIME',
  Recurring = 'RECURRING'
}

/** twilio default category reason */
export enum FeedBackCategoryReason {
  AudioLatency = 'audio_latency',
  DigitsNotCaptured = 'digits_not_captured',
  DroppedCall = 'dropped_call',
  ImperfectAudio = 'imperfect_audio',
  IncorrectCallerId = 'incorrect_caller_id',
  OneWayAudio = 'one_way_audio',
  PostDialDelay = 'post_dial_delay',
  UnsolicitedCall = 'unsolicited_call'
}

/** Fee type for product */
export enum FeeType {
  OneTime = 'ONE_TIME',
  Recurring = 'RECURRING'
}

export type FloatFieldUpdateOperationsInput = {
  decrement?: InputMaybe<Scalars['Float']['input']>;
  divide?: InputMaybe<Scalars['Float']['input']>;
  increment?: InputMaybe<Scalars['Float']['input']>;
  multiply?: InputMaybe<Scalars['Float']['input']>;
  set?: InputMaybe<Scalars['Float']['input']>;
};

export type FloatFilter = {
  equals?: InputMaybe<Scalars['Float']['input']>;
  gt?: InputMaybe<Scalars['Float']['input']>;
  gte?: InputMaybe<Scalars['Float']['input']>;
  in?: InputMaybe<Array<Scalars['Float']['input']>>;
  lt?: InputMaybe<Scalars['Float']['input']>;
  lte?: InputMaybe<Scalars['Float']['input']>;
  not?: InputMaybe<NestedFloatFilter>;
  notIn?: InputMaybe<Array<Scalars['Float']['input']>>;
};

export type FloatNullableFilter = {
  equals?: InputMaybe<Scalars['Float']['input']>;
  gt?: InputMaybe<Scalars['Float']['input']>;
  gte?: InputMaybe<Scalars['Float']['input']>;
  in?: InputMaybe<Array<Scalars['Float']['input']>>;
  lt?: InputMaybe<Scalars['Float']['input']>;
  lte?: InputMaybe<Scalars['Float']['input']>;
  not?: InputMaybe<NestedFloatNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['Float']['input']>>;
};

export type Folder = {
  __typename?: 'Folder';
  conferences: Array<Conference>;
  created_at: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  owner?: Maybe<User>;
  owner_id?: Maybe<Scalars['String']['output']>;
  shared_user?: Maybe<Array<Maybe<User>>>;
  size?: Maybe<Scalars['Int']['output']>;
  updated_at: Scalars['DateTime']['output'];
};


export type FolderConferencesArgs = {
  after?: InputMaybe<ConferenceWhereUniqueInput>;
  before?: InputMaybe<ConferenceWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type FolderCreateManyOrganizationInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  owner_id?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type FolderCreateManyOrganizationInputEnvelope = {
  data?: InputMaybe<Array<FolderCreateManyOrganizationInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type FolderCreateManyOwnerInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  organization_id?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type FolderCreateManyOwnerInputEnvelope = {
  data?: InputMaybe<Array<FolderCreateManyOwnerInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type FolderCreateNestedManyWithoutConferencesInput = {
  connect?: InputMaybe<Array<FolderWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<FolderCreateOrConnectWithoutConferencesInput>>;
  create?: InputMaybe<Array<FolderCreateWithoutConferencesInput>>;
};

export type FolderCreateNestedManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<FolderWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<FolderCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<FolderCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<FolderCreateManyOrganizationInputEnvelope>;
};

export type FolderCreateNestedManyWithoutOwnerInput = {
  connect?: InputMaybe<Array<FolderWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<FolderCreateOrConnectWithoutOwnerInput>>;
  create?: InputMaybe<Array<FolderCreateWithoutOwnerInput>>;
  createMany?: InputMaybe<FolderCreateManyOwnerInputEnvelope>;
};

export type FolderCreateNestedOneWithoutUser_PermissionsInput = {
  connect?: InputMaybe<FolderWhereUniqueInput>;
  connectOrCreate?: InputMaybe<FolderCreateOrConnectWithoutUser_PermissionsInput>;
  create?: InputMaybe<FolderCreateWithoutUser_PermissionsInput>;
};

export type FolderCreateOrConnectWithoutConferencesInput = {
  create: FolderCreateWithoutConferencesInput;
  where: FolderWhereUniqueInput;
};

export type FolderCreateOrConnectWithoutOrganizationInput = {
  create: FolderCreateWithoutOrganizationInput;
  where: FolderWhereUniqueInput;
};

export type FolderCreateOrConnectWithoutOwnerInput = {
  create: FolderCreateWithoutOwnerInput;
  where: FolderWhereUniqueInput;
};

export type FolderCreateOrConnectWithoutUser_PermissionsInput = {
  create: FolderCreateWithoutUser_PermissionsInput;
  where: FolderWhereUniqueInput;
};

export type FolderCreateWithoutConferencesInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  Organization?: InputMaybe<OrganizationCreateNestedOneWithoutFoldersInput>;
  owner?: InputMaybe<UserCreateNestedOneWithoutFoldersInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user_permissions?: InputMaybe<FolderPermissionCreateNestedManyWithoutFolderInput>;
};

export type FolderCreateWithoutOrganizationInput = {
  conferences?: InputMaybe<ConferenceCreateNestedManyWithoutFoldersInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  owner?: InputMaybe<UserCreateNestedOneWithoutFoldersInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user_permissions?: InputMaybe<FolderPermissionCreateNestedManyWithoutFolderInput>;
};

export type FolderCreateWithoutOwnerInput = {
  conferences?: InputMaybe<ConferenceCreateNestedManyWithoutFoldersInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  Organization?: InputMaybe<OrganizationCreateNestedOneWithoutFoldersInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user_permissions?: InputMaybe<FolderPermissionCreateNestedManyWithoutFolderInput>;
};

export type FolderCreateWithoutUser_PermissionsInput = {
  conferences?: InputMaybe<ConferenceCreateNestedManyWithoutFoldersInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  Organization?: InputMaybe<OrganizationCreateNestedOneWithoutFoldersInput>;
  owner?: InputMaybe<UserCreateNestedOneWithoutFoldersInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type FolderListRelationFilter = {
  every?: InputMaybe<FolderWhereInput>;
  none?: InputMaybe<FolderWhereInput>;
  some?: InputMaybe<FolderWhereInput>;
};

export type FolderOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
  count?: InputMaybe<SortOrder>;
};

export type FolderPermissionCreateManyFolderInput = {
  organization_id?: InputMaybe<Scalars['String']['input']>;
  read_perimission?: InputMaybe<Scalars['Boolean']['input']>;
  user_id: Scalars['String']['input'];
};

export type FolderPermissionCreateManyFolderInputEnvelope = {
  data?: InputMaybe<Array<FolderPermissionCreateManyFolderInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type FolderPermissionCreateManyOrganizationInput = {
  folder_id: Scalars['String']['input'];
  read_perimission?: InputMaybe<Scalars['Boolean']['input']>;
  user_id: Scalars['String']['input'];
};

export type FolderPermissionCreateManyOrganizationInputEnvelope = {
  data?: InputMaybe<Array<FolderPermissionCreateManyOrganizationInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type FolderPermissionCreateManyUserInput = {
  folder_id: Scalars['String']['input'];
  organization_id?: InputMaybe<Scalars['String']['input']>;
  read_perimission?: InputMaybe<Scalars['Boolean']['input']>;
};

export type FolderPermissionCreateManyUserInputEnvelope = {
  data?: InputMaybe<Array<FolderPermissionCreateManyUserInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type FolderPermissionCreateNestedManyWithoutFolderInput = {
  connect?: InputMaybe<Array<FolderPermissionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<FolderPermissionCreateOrConnectWithoutFolderInput>>;
  create?: InputMaybe<Array<FolderPermissionCreateWithoutFolderInput>>;
  createMany?: InputMaybe<FolderPermissionCreateManyFolderInputEnvelope>;
};

export type FolderPermissionCreateNestedManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<FolderPermissionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<FolderPermissionCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<FolderPermissionCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<FolderPermissionCreateManyOrganizationInputEnvelope>;
};

export type FolderPermissionCreateNestedManyWithoutUserInput = {
  connect?: InputMaybe<Array<FolderPermissionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<FolderPermissionCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<FolderPermissionCreateWithoutUserInput>>;
  createMany?: InputMaybe<FolderPermissionCreateManyUserInputEnvelope>;
};

export type FolderPermissionCreateOrConnectWithoutFolderInput = {
  create: FolderPermissionCreateWithoutFolderInput;
  where: FolderPermissionWhereUniqueInput;
};

export type FolderPermissionCreateOrConnectWithoutOrganizationInput = {
  create: FolderPermissionCreateWithoutOrganizationInput;
  where: FolderPermissionWhereUniqueInput;
};

export type FolderPermissionCreateOrConnectWithoutUserInput = {
  create: FolderPermissionCreateWithoutUserInput;
  where: FolderPermissionWhereUniqueInput;
};

export type FolderPermissionCreateWithoutFolderInput = {
  Organization?: InputMaybe<OrganizationCreateNestedOneWithoutFolderPermissionsInput>;
  read_perimission?: InputMaybe<Scalars['Boolean']['input']>;
  user: UserCreateNestedOneWithoutFolder_PermissionsInput;
};

export type FolderPermissionCreateWithoutOrganizationInput = {
  folder: FolderCreateNestedOneWithoutUser_PermissionsInput;
  read_perimission?: InputMaybe<Scalars['Boolean']['input']>;
  user: UserCreateNestedOneWithoutFolder_PermissionsInput;
};

export type FolderPermissionCreateWithoutUserInput = {
  folder: FolderCreateNestedOneWithoutUser_PermissionsInput;
  Organization?: InputMaybe<OrganizationCreateNestedOneWithoutFolderPermissionsInput>;
  read_perimission?: InputMaybe<Scalars['Boolean']['input']>;
};

export type FolderPermissionFolder_IdUser_IdCompoundUniqueInput = {
  folder_id: Scalars['String']['input'];
  user_id: Scalars['String']['input'];
};

export type FolderPermissionListRelationFilter = {
  every?: InputMaybe<FolderPermissionWhereInput>;
  none?: InputMaybe<FolderPermissionWhereInput>;
  some?: InputMaybe<FolderPermissionWhereInput>;
};

export type FolderPermissionOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
  count?: InputMaybe<SortOrder>;
};

export type FolderPermissionScalarWhereInput = {
  AND?: InputMaybe<Array<FolderPermissionScalarWhereInput>>;
  folder_id?: InputMaybe<StringFilter>;
  NOT?: InputMaybe<Array<FolderPermissionScalarWhereInput>>;
  OR?: InputMaybe<Array<FolderPermissionScalarWhereInput>>;
  organization_id?: InputMaybe<StringNullableFilter>;
  read_perimission?: InputMaybe<BoolFilter>;
  user_id?: InputMaybe<StringFilter>;
};

export type FolderPermissionUpdateManyMutationInput = {
  read_perimission?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type FolderPermissionUpdateManyWithoutFolderInput = {
  connect?: InputMaybe<Array<FolderPermissionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<FolderPermissionCreateOrConnectWithoutFolderInput>>;
  create?: InputMaybe<Array<FolderPermissionCreateWithoutFolderInput>>;
  createMany?: InputMaybe<FolderPermissionCreateManyFolderInputEnvelope>;
  delete?: InputMaybe<Array<FolderPermissionWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<FolderPermissionScalarWhereInput>>;
  disconnect?: InputMaybe<Array<FolderPermissionWhereUniqueInput>>;
  set?: InputMaybe<Array<FolderPermissionWhereUniqueInput>>;
  update?: InputMaybe<Array<FolderPermissionUpdateWithWhereUniqueWithoutFolderInput>>;
  updateMany?: InputMaybe<Array<FolderPermissionUpdateManyWithWhereWithoutFolderInput>>;
  upsert?: InputMaybe<Array<FolderPermissionUpsertWithWhereUniqueWithoutFolderInput>>;
};

export type FolderPermissionUpdateManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<FolderPermissionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<FolderPermissionCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<FolderPermissionCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<FolderPermissionCreateManyOrganizationInputEnvelope>;
  delete?: InputMaybe<Array<FolderPermissionWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<FolderPermissionScalarWhereInput>>;
  disconnect?: InputMaybe<Array<FolderPermissionWhereUniqueInput>>;
  set?: InputMaybe<Array<FolderPermissionWhereUniqueInput>>;
  update?: InputMaybe<Array<FolderPermissionUpdateWithWhereUniqueWithoutOrganizationInput>>;
  updateMany?: InputMaybe<Array<FolderPermissionUpdateManyWithWhereWithoutOrganizationInput>>;
  upsert?: InputMaybe<Array<FolderPermissionUpsertWithWhereUniqueWithoutOrganizationInput>>;
};

export type FolderPermissionUpdateManyWithoutUserInput = {
  connect?: InputMaybe<Array<FolderPermissionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<FolderPermissionCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<FolderPermissionCreateWithoutUserInput>>;
  createMany?: InputMaybe<FolderPermissionCreateManyUserInputEnvelope>;
  delete?: InputMaybe<Array<FolderPermissionWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<FolderPermissionScalarWhereInput>>;
  disconnect?: InputMaybe<Array<FolderPermissionWhereUniqueInput>>;
  set?: InputMaybe<Array<FolderPermissionWhereUniqueInput>>;
  update?: InputMaybe<Array<FolderPermissionUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: InputMaybe<Array<FolderPermissionUpdateManyWithWhereWithoutUserInput>>;
  upsert?: InputMaybe<Array<FolderPermissionUpsertWithWhereUniqueWithoutUserInput>>;
};

export type FolderPermissionUpdateManyWithWhereWithoutFolderInput = {
  data: FolderPermissionUpdateManyMutationInput;
  where: FolderPermissionScalarWhereInput;
};

export type FolderPermissionUpdateManyWithWhereWithoutOrganizationInput = {
  data: FolderPermissionUpdateManyMutationInput;
  where: FolderPermissionScalarWhereInput;
};

export type FolderPermissionUpdateManyWithWhereWithoutUserInput = {
  data: FolderPermissionUpdateManyMutationInput;
  where: FolderPermissionScalarWhereInput;
};

export type FolderPermissionUpdateWithoutFolderInput = {
  Organization?: InputMaybe<OrganizationUpdateOneWithoutFolderPermissionsInput>;
  read_perimission?: InputMaybe<BoolFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutFolder_PermissionsInput>;
};

export type FolderPermissionUpdateWithoutOrganizationInput = {
  folder?: InputMaybe<FolderUpdateOneRequiredWithoutUser_PermissionsInput>;
  read_perimission?: InputMaybe<BoolFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutFolder_PermissionsInput>;
};

export type FolderPermissionUpdateWithoutUserInput = {
  folder?: InputMaybe<FolderUpdateOneRequiredWithoutUser_PermissionsInput>;
  Organization?: InputMaybe<OrganizationUpdateOneWithoutFolderPermissionsInput>;
  read_perimission?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type FolderPermissionUpdateWithWhereUniqueWithoutFolderInput = {
  data: FolderPermissionUpdateWithoutFolderInput;
  where: FolderPermissionWhereUniqueInput;
};

export type FolderPermissionUpdateWithWhereUniqueWithoutOrganizationInput = {
  data: FolderPermissionUpdateWithoutOrganizationInput;
  where: FolderPermissionWhereUniqueInput;
};

export type FolderPermissionUpdateWithWhereUniqueWithoutUserInput = {
  data: FolderPermissionUpdateWithoutUserInput;
  where: FolderPermissionWhereUniqueInput;
};

export type FolderPermissionUpsertWithWhereUniqueWithoutFolderInput = {
  create: FolderPermissionCreateWithoutFolderInput;
  update: FolderPermissionUpdateWithoutFolderInput;
  where: FolderPermissionWhereUniqueInput;
};

export type FolderPermissionUpsertWithWhereUniqueWithoutOrganizationInput = {
  create: FolderPermissionCreateWithoutOrganizationInput;
  update: FolderPermissionUpdateWithoutOrganizationInput;
  where: FolderPermissionWhereUniqueInput;
};

export type FolderPermissionUpsertWithWhereUniqueWithoutUserInput = {
  create: FolderPermissionCreateWithoutUserInput;
  update: FolderPermissionUpdateWithoutUserInput;
  where: FolderPermissionWhereUniqueInput;
};

export type FolderPermissionWhereInput = {
  AND?: InputMaybe<Array<FolderPermissionWhereInput>>;
  folder?: InputMaybe<FolderWhereInput>;
  folder_id?: InputMaybe<StringFilter>;
  NOT?: InputMaybe<Array<FolderPermissionWhereInput>>;
  OR?: InputMaybe<Array<FolderPermissionWhereInput>>;
  Organization?: InputMaybe<OrganizationWhereInput>;
  organization_id?: InputMaybe<StringNullableFilter>;
  read_perimission?: InputMaybe<BoolFilter>;
  user?: InputMaybe<UserWhereInput>;
  user_id?: InputMaybe<StringFilter>;
};

export type FolderPermissionWhereUniqueInput = {
  folder_id_user_id?: InputMaybe<FolderPermissionFolder_IdUser_IdCompoundUniqueInput>;
};

export type FolderScalarWhereInput = {
  AND?: InputMaybe<Array<FolderScalarWhereInput>>;
  created_at?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  NOT?: InputMaybe<Array<FolderScalarWhereInput>>;
  OR?: InputMaybe<Array<FolderScalarWhereInput>>;
  organization_id?: InputMaybe<StringNullableFilter>;
  owner_id?: InputMaybe<StringNullableFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
};

export type FolderUpdateManyMutationInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type FolderUpdateManyWithoutConferencesInput = {
  connect?: InputMaybe<Array<FolderWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<FolderCreateOrConnectWithoutConferencesInput>>;
  create?: InputMaybe<Array<FolderCreateWithoutConferencesInput>>;
  delete?: InputMaybe<Array<FolderWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<FolderScalarWhereInput>>;
  disconnect?: InputMaybe<Array<FolderWhereUniqueInput>>;
  set?: InputMaybe<Array<FolderWhereUniqueInput>>;
  update?: InputMaybe<Array<FolderUpdateWithWhereUniqueWithoutConferencesInput>>;
  updateMany?: InputMaybe<Array<FolderUpdateManyWithWhereWithoutConferencesInput>>;
  upsert?: InputMaybe<Array<FolderUpsertWithWhereUniqueWithoutConferencesInput>>;
};

export type FolderUpdateManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<FolderWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<FolderCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<FolderCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<FolderCreateManyOrganizationInputEnvelope>;
  delete?: InputMaybe<Array<FolderWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<FolderScalarWhereInput>>;
  disconnect?: InputMaybe<Array<FolderWhereUniqueInput>>;
  set?: InputMaybe<Array<FolderWhereUniqueInput>>;
  update?: InputMaybe<Array<FolderUpdateWithWhereUniqueWithoutOrganizationInput>>;
  updateMany?: InputMaybe<Array<FolderUpdateManyWithWhereWithoutOrganizationInput>>;
  upsert?: InputMaybe<Array<FolderUpsertWithWhereUniqueWithoutOrganizationInput>>;
};

export type FolderUpdateManyWithoutOwnerInput = {
  connect?: InputMaybe<Array<FolderWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<FolderCreateOrConnectWithoutOwnerInput>>;
  create?: InputMaybe<Array<FolderCreateWithoutOwnerInput>>;
  createMany?: InputMaybe<FolderCreateManyOwnerInputEnvelope>;
  delete?: InputMaybe<Array<FolderWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<FolderScalarWhereInput>>;
  disconnect?: InputMaybe<Array<FolderWhereUniqueInput>>;
  set?: InputMaybe<Array<FolderWhereUniqueInput>>;
  update?: InputMaybe<Array<FolderUpdateWithWhereUniqueWithoutOwnerInput>>;
  updateMany?: InputMaybe<Array<FolderUpdateManyWithWhereWithoutOwnerInput>>;
  upsert?: InputMaybe<Array<FolderUpsertWithWhereUniqueWithoutOwnerInput>>;
};

export type FolderUpdateManyWithWhereWithoutConferencesInput = {
  data: FolderUpdateManyMutationInput;
  where: FolderScalarWhereInput;
};

export type FolderUpdateManyWithWhereWithoutOrganizationInput = {
  data: FolderUpdateManyMutationInput;
  where: FolderScalarWhereInput;
};

export type FolderUpdateManyWithWhereWithoutOwnerInput = {
  data: FolderUpdateManyMutationInput;
  where: FolderScalarWhereInput;
};

export type FolderUpdateOneRequiredWithoutUser_PermissionsInput = {
  connect?: InputMaybe<FolderWhereUniqueInput>;
  connectOrCreate?: InputMaybe<FolderCreateOrConnectWithoutUser_PermissionsInput>;
  create?: InputMaybe<FolderCreateWithoutUser_PermissionsInput>;
  update?: InputMaybe<FolderUpdateWithoutUser_PermissionsInput>;
  upsert?: InputMaybe<FolderUpsertWithoutUser_PermissionsInput>;
};

export type FolderUpdateWithoutConferencesInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  Organization?: InputMaybe<OrganizationUpdateOneWithoutFoldersInput>;
  owner?: InputMaybe<UserUpdateOneWithoutFoldersInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user_permissions?: InputMaybe<FolderPermissionUpdateManyWithoutFolderInput>;
};

export type FolderUpdateWithoutOrganizationInput = {
  conferences?: InputMaybe<ConferenceUpdateManyWithoutFoldersInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  owner?: InputMaybe<UserUpdateOneWithoutFoldersInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user_permissions?: InputMaybe<FolderPermissionUpdateManyWithoutFolderInput>;
};

export type FolderUpdateWithoutOwnerInput = {
  conferences?: InputMaybe<ConferenceUpdateManyWithoutFoldersInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  Organization?: InputMaybe<OrganizationUpdateOneWithoutFoldersInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user_permissions?: InputMaybe<FolderPermissionUpdateManyWithoutFolderInput>;
};

export type FolderUpdateWithoutUser_PermissionsInput = {
  conferences?: InputMaybe<ConferenceUpdateManyWithoutFoldersInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  Organization?: InputMaybe<OrganizationUpdateOneWithoutFoldersInput>;
  owner?: InputMaybe<UserUpdateOneWithoutFoldersInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type FolderUpdateWithWhereUniqueWithoutConferencesInput = {
  data: FolderUpdateWithoutConferencesInput;
  where: FolderWhereUniqueInput;
};

export type FolderUpdateWithWhereUniqueWithoutOrganizationInput = {
  data: FolderUpdateWithoutOrganizationInput;
  where: FolderWhereUniqueInput;
};

export type FolderUpdateWithWhereUniqueWithoutOwnerInput = {
  data: FolderUpdateWithoutOwnerInput;
  where: FolderWhereUniqueInput;
};

export type FolderUpsertWithoutUser_PermissionsInput = {
  create: FolderCreateWithoutUser_PermissionsInput;
  update: FolderUpdateWithoutUser_PermissionsInput;
};

export type FolderUpsertWithWhereUniqueWithoutConferencesInput = {
  create: FolderCreateWithoutConferencesInput;
  update: FolderUpdateWithoutConferencesInput;
  where: FolderWhereUniqueInput;
};

export type FolderUpsertWithWhereUniqueWithoutOrganizationInput = {
  create: FolderCreateWithoutOrganizationInput;
  update: FolderUpdateWithoutOrganizationInput;
  where: FolderWhereUniqueInput;
};

export type FolderUpsertWithWhereUniqueWithoutOwnerInput = {
  create: FolderCreateWithoutOwnerInput;
  update: FolderUpdateWithoutOwnerInput;
  where: FolderWhereUniqueInput;
};

export type FolderWhereInput = {
  AND?: InputMaybe<Array<FolderWhereInput>>;
  conferences?: InputMaybe<ConferenceListRelationFilter>;
  created_at?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  NOT?: InputMaybe<Array<FolderWhereInput>>;
  OR?: InputMaybe<Array<FolderWhereInput>>;
  Organization?: InputMaybe<OrganizationWhereInput>;
  organization_id?: InputMaybe<StringNullableFilter>;
  owner?: InputMaybe<UserWhereInput>;
  owner_id?: InputMaybe<StringNullableFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
  user_permissions?: InputMaybe<FolderPermissionListRelationFilter>;
};

export type FolderWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']['input']>;
};

export type FutureBookingSetting = {
  __typename?: 'FutureBookingSetting';
  created_at: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  organization: Organization;
  organization_id: Scalars['String']['output'];
  sdr_demo_to_sdr: Scalars['Boolean']['output'];
  transfer_type: TransferType;
  updated_at: Scalars['DateTime']['output'];
};

export type FutureBookingSettingCreateNestedOneWithoutOrganizationInput = {
  connect?: InputMaybe<FutureBookingSettingWhereUniqueInput>;
  connectOrCreate?: InputMaybe<FutureBookingSettingCreateOrConnectWithoutOrganizationInput>;
  create?: InputMaybe<FutureBookingSettingCreateWithoutOrganizationInput>;
};

export type FutureBookingSettingCreateOrConnectWithoutOrganizationInput = {
  create: FutureBookingSettingCreateWithoutOrganizationInput;
  where: FutureBookingSettingWhereUniqueInput;
};

export type FutureBookingSettingCreateWithoutOrganizationInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  sdr_demo_to_sdr?: InputMaybe<Scalars['Boolean']['input']>;
  transfer_type?: InputMaybe<TransferType>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

/** FutureBookingSetting Input for create / update */
export type FutureBookingSettingInput = {
  sdr_demo_to_sdr?: InputMaybe<Scalars['Boolean']['input']>;
  transfer_type?: InputMaybe<TransferType>;
};

export type FutureBookingSettingOrderByWithRelationInput = {
  created_at?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  organization?: InputMaybe<OrganizationOrderByWithRelationInput>;
  organization_id?: InputMaybe<SortOrder>;
  sdr_demo_to_sdr?: InputMaybe<SortOrder>;
  transfer_type?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
};

export type FutureBookingSettingUpdateOneWithoutOrganizationInput = {
  connect?: InputMaybe<FutureBookingSettingWhereUniqueInput>;
  connectOrCreate?: InputMaybe<FutureBookingSettingCreateOrConnectWithoutOrganizationInput>;
  create?: InputMaybe<FutureBookingSettingCreateWithoutOrganizationInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<FutureBookingSettingUpdateWithoutOrganizationInput>;
  upsert?: InputMaybe<FutureBookingSettingUpsertWithoutOrganizationInput>;
};

export type FutureBookingSettingUpdateWithoutOrganizationInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  sdr_demo_to_sdr?: InputMaybe<BoolFieldUpdateOperationsInput>;
  transfer_type?: InputMaybe<EnumTransferTypeFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type FutureBookingSettingUpsertWithoutOrganizationInput = {
  create: FutureBookingSettingCreateWithoutOrganizationInput;
  update: FutureBookingSettingUpdateWithoutOrganizationInput;
};

export type FutureBookingSettingWhereInput = {
  AND?: InputMaybe<Array<FutureBookingSettingWhereInput>>;
  created_at?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  NOT?: InputMaybe<Array<FutureBookingSettingWhereInput>>;
  OR?: InputMaybe<Array<FutureBookingSettingWhereInput>>;
  organization?: InputMaybe<OrganizationWhereInput>;
  organization_id?: InputMaybe<StringFilter>;
  sdr_demo_to_sdr?: InputMaybe<BoolFilter>;
  transfer_type?: InputMaybe<EnumTransferTypeFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
};

export type FutureBookingSettingWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  organization_id?: InputMaybe<Scalars['String']['input']>;
};

export type GlobalRepRouting = {
  __typename?: 'GlobalRepRouting';
  close_rate?: Maybe<Scalars['Float']['output']>;
  global_rule: GlobalTransferRule;
  global_rule_id: Scalars['String']['output'];
  rank: Scalars['Int']['output'];
  rep: User;
  rep_id: Scalars['String']['output'];
};

export type GlobalRepRoutingCreateManyGlobal_RuleInput = {
  rank?: InputMaybe<Scalars['Int']['input']>;
  rep_id: Scalars['String']['input'];
};

export type GlobalRepRoutingCreateManyGlobal_RuleInputEnvelope = {
  data?: InputMaybe<Array<GlobalRepRoutingCreateManyGlobal_RuleInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type GlobalRepRoutingCreateManyRepInput = {
  global_rule_id: Scalars['String']['input'];
  rank?: InputMaybe<Scalars['Int']['input']>;
};

export type GlobalRepRoutingCreateManyRepInputEnvelope = {
  data?: InputMaybe<Array<GlobalRepRoutingCreateManyRepInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type GlobalRepRoutingCreateNestedManyWithoutGlobal_RuleInput = {
  connect?: InputMaybe<Array<GlobalRepRoutingWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<GlobalRepRoutingCreateOrConnectWithoutGlobal_RuleInput>>;
  create?: InputMaybe<Array<GlobalRepRoutingCreateWithoutGlobal_RuleInput>>;
  createMany?: InputMaybe<GlobalRepRoutingCreateManyGlobal_RuleInputEnvelope>;
};

export type GlobalRepRoutingCreateNestedManyWithoutRepInput = {
  connect?: InputMaybe<Array<GlobalRepRoutingWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<GlobalRepRoutingCreateOrConnectWithoutRepInput>>;
  create?: InputMaybe<Array<GlobalRepRoutingCreateWithoutRepInput>>;
  createMany?: InputMaybe<GlobalRepRoutingCreateManyRepInputEnvelope>;
};

export type GlobalRepRoutingCreateOrConnectWithoutGlobal_RuleInput = {
  create: GlobalRepRoutingCreateWithoutGlobal_RuleInput;
  where: GlobalRepRoutingWhereUniqueInput;
};

export type GlobalRepRoutingCreateOrConnectWithoutRepInput = {
  create: GlobalRepRoutingCreateWithoutRepInput;
  where: GlobalRepRoutingWhereUniqueInput;
};

export type GlobalRepRoutingCreateWithoutGlobal_RuleInput = {
  rank?: InputMaybe<Scalars['Int']['input']>;
  rep: UserCreateNestedOneWithoutGlobalRoutingInput;
};

export type GlobalRepRoutingCreateWithoutRepInput = {
  global_rule: GlobalTransferRuleCreateNestedOneWithoutGlobalRepRoutingInput;
  rank?: InputMaybe<Scalars['Int']['input']>;
};

export type GlobalRepRoutingListRelationFilter = {
  every?: InputMaybe<GlobalRepRoutingWhereInput>;
  none?: InputMaybe<GlobalRepRoutingWhereInput>;
  some?: InputMaybe<GlobalRepRoutingWhereInput>;
};

export type GlobalRepRoutingOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
  count?: InputMaybe<SortOrder>;
};

export type GlobalRepRoutingRep_IdGlobal_Rule_IdCompoundUniqueInput = {
  global_rule_id: Scalars['String']['input'];
  rep_id: Scalars['String']['input'];
};

export type GlobalRepRoutingScalarWhereInput = {
  AND?: InputMaybe<Array<GlobalRepRoutingScalarWhereInput>>;
  global_rule_id?: InputMaybe<StringFilter>;
  NOT?: InputMaybe<Array<GlobalRepRoutingScalarWhereInput>>;
  OR?: InputMaybe<Array<GlobalRepRoutingScalarWhereInput>>;
  rank?: InputMaybe<IntFilter>;
  rep_id?: InputMaybe<StringFilter>;
};

export type GlobalRepRoutingUpdateManyMutationInput = {
  rank?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type GlobalRepRoutingUpdateManyWithoutGlobal_RuleInput = {
  connect?: InputMaybe<Array<GlobalRepRoutingWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<GlobalRepRoutingCreateOrConnectWithoutGlobal_RuleInput>>;
  create?: InputMaybe<Array<GlobalRepRoutingCreateWithoutGlobal_RuleInput>>;
  createMany?: InputMaybe<GlobalRepRoutingCreateManyGlobal_RuleInputEnvelope>;
  delete?: InputMaybe<Array<GlobalRepRoutingWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<GlobalRepRoutingScalarWhereInput>>;
  disconnect?: InputMaybe<Array<GlobalRepRoutingWhereUniqueInput>>;
  set?: InputMaybe<Array<GlobalRepRoutingWhereUniqueInput>>;
  update?: InputMaybe<Array<GlobalRepRoutingUpdateWithWhereUniqueWithoutGlobal_RuleInput>>;
  updateMany?: InputMaybe<Array<GlobalRepRoutingUpdateManyWithWhereWithoutGlobal_RuleInput>>;
  upsert?: InputMaybe<Array<GlobalRepRoutingUpsertWithWhereUniqueWithoutGlobal_RuleInput>>;
};

export type GlobalRepRoutingUpdateManyWithoutRepInput = {
  connect?: InputMaybe<Array<GlobalRepRoutingWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<GlobalRepRoutingCreateOrConnectWithoutRepInput>>;
  create?: InputMaybe<Array<GlobalRepRoutingCreateWithoutRepInput>>;
  createMany?: InputMaybe<GlobalRepRoutingCreateManyRepInputEnvelope>;
  delete?: InputMaybe<Array<GlobalRepRoutingWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<GlobalRepRoutingScalarWhereInput>>;
  disconnect?: InputMaybe<Array<GlobalRepRoutingWhereUniqueInput>>;
  set?: InputMaybe<Array<GlobalRepRoutingWhereUniqueInput>>;
  update?: InputMaybe<Array<GlobalRepRoutingUpdateWithWhereUniqueWithoutRepInput>>;
  updateMany?: InputMaybe<Array<GlobalRepRoutingUpdateManyWithWhereWithoutRepInput>>;
  upsert?: InputMaybe<Array<GlobalRepRoutingUpsertWithWhereUniqueWithoutRepInput>>;
};

export type GlobalRepRoutingUpdateManyWithWhereWithoutGlobal_RuleInput = {
  data: GlobalRepRoutingUpdateManyMutationInput;
  where: GlobalRepRoutingScalarWhereInput;
};

export type GlobalRepRoutingUpdateManyWithWhereWithoutRepInput = {
  data: GlobalRepRoutingUpdateManyMutationInput;
  where: GlobalRepRoutingScalarWhereInput;
};

export type GlobalRepRoutingUpdateWithoutGlobal_RuleInput = {
  rank?: InputMaybe<IntFieldUpdateOperationsInput>;
  rep?: InputMaybe<UserUpdateOneRequiredWithoutGlobalRoutingInput>;
};

export type GlobalRepRoutingUpdateWithoutRepInput = {
  global_rule?: InputMaybe<GlobalTransferRuleUpdateOneRequiredWithoutGlobalRepRoutingInput>;
  rank?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type GlobalRepRoutingUpdateWithWhereUniqueWithoutGlobal_RuleInput = {
  data: GlobalRepRoutingUpdateWithoutGlobal_RuleInput;
  where: GlobalRepRoutingWhereUniqueInput;
};

export type GlobalRepRoutingUpdateWithWhereUniqueWithoutRepInput = {
  data: GlobalRepRoutingUpdateWithoutRepInput;
  where: GlobalRepRoutingWhereUniqueInput;
};

export type GlobalRepRoutingUpsertWithWhereUniqueWithoutGlobal_RuleInput = {
  create: GlobalRepRoutingCreateWithoutGlobal_RuleInput;
  update: GlobalRepRoutingUpdateWithoutGlobal_RuleInput;
  where: GlobalRepRoutingWhereUniqueInput;
};

export type GlobalRepRoutingUpsertWithWhereUniqueWithoutRepInput = {
  create: GlobalRepRoutingCreateWithoutRepInput;
  update: GlobalRepRoutingUpdateWithoutRepInput;
  where: GlobalRepRoutingWhereUniqueInput;
};

export type GlobalRepRoutingWhereInput = {
  AND?: InputMaybe<Array<GlobalRepRoutingWhereInput>>;
  global_rule?: InputMaybe<GlobalTransferRuleWhereInput>;
  global_rule_id?: InputMaybe<StringFilter>;
  NOT?: InputMaybe<Array<GlobalRepRoutingWhereInput>>;
  OR?: InputMaybe<Array<GlobalRepRoutingWhereInput>>;
  rank?: InputMaybe<IntFilter>;
  rep?: InputMaybe<UserWhereInput>;
  rep_id?: InputMaybe<StringFilter>;
};

export type GlobalRepRoutingWhereUniqueInput = {
  rep_id_global_rule_id?: InputMaybe<GlobalRepRoutingRep_IdGlobal_Rule_IdCompoundUniqueInput>;
};

export type GlobalTransferRule = {
  __typename?: 'GlobalTransferRule';
  backup_rule: BackUpRule;
  created_at: Scalars['DateTime']['output'];
  date_range: DateOption;
  distribution_method: Distribution_Method;
  GlobalRepRouting: Array<GlobalRepRouting>;
  id: Scalars['String']['output'];
  metric: Metric;
  organization: Organization;
  organization_id: Scalars['String']['output'];
  rank_sort_type: RankSortType;
  rule_type: TransferRuleType;
  transfer_type: TransferType;
  updated_at: Scalars['DateTime']['output'];
  user_effective_transfer_type?: Maybe<TransferType>;
};


export type GlobalTransferRuleGlobalRepRoutingArgs = {
  after?: InputMaybe<GlobalRepRoutingWhereUniqueInput>;
  before?: InputMaybe<GlobalRepRoutingWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type GlobalTransferRuleCreateManyOrganizationInput = {
  backup_rule?: InputMaybe<BackUpRule>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  date_range?: InputMaybe<DateOption>;
  distribution_method?: InputMaybe<Distribution_Method>;
  id?: InputMaybe<Scalars['String']['input']>;
  metric?: InputMaybe<Metric>;
  rank_sort_type: RankSortType;
  rule_type?: InputMaybe<TransferRuleType>;
  transfer_type?: InputMaybe<TransferType>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type GlobalTransferRuleCreateManyOrganizationInputEnvelope = {
  data?: InputMaybe<Array<GlobalTransferRuleCreateManyOrganizationInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type GlobalTransferRuleCreateNestedManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<GlobalTransferRuleWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<GlobalTransferRuleCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<GlobalTransferRuleCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<GlobalTransferRuleCreateManyOrganizationInputEnvelope>;
};

export type GlobalTransferRuleCreateNestedOneWithoutGlobalRepRoutingInput = {
  connect?: InputMaybe<GlobalTransferRuleWhereUniqueInput>;
  connectOrCreate?: InputMaybe<GlobalTransferRuleCreateOrConnectWithoutGlobalRepRoutingInput>;
  create?: InputMaybe<GlobalTransferRuleCreateWithoutGlobalRepRoutingInput>;
};

export type GlobalTransferRuleCreateOrConnectWithoutGlobalRepRoutingInput = {
  create: GlobalTransferRuleCreateWithoutGlobalRepRoutingInput;
  where: GlobalTransferRuleWhereUniqueInput;
};

export type GlobalTransferRuleCreateOrConnectWithoutOrganizationInput = {
  create: GlobalTransferRuleCreateWithoutOrganizationInput;
  where: GlobalTransferRuleWhereUniqueInput;
};

export type GlobalTransferRuleCreateWithoutGlobalRepRoutingInput = {
  backup_rule?: InputMaybe<BackUpRule>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  date_range?: InputMaybe<DateOption>;
  distribution_method?: InputMaybe<Distribution_Method>;
  id?: InputMaybe<Scalars['String']['input']>;
  metric?: InputMaybe<Metric>;
  organization: OrganizationCreateNestedOneWithoutGlobalTransferRuleInput;
  rank_sort_type: RankSortType;
  rule_type?: InputMaybe<TransferRuleType>;
  transfer_type?: InputMaybe<TransferType>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type GlobalTransferRuleCreateWithoutOrganizationInput = {
  backup_rule?: InputMaybe<BackUpRule>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  date_range?: InputMaybe<DateOption>;
  distribution_method?: InputMaybe<Distribution_Method>;
  GlobalRepRouting?: InputMaybe<GlobalRepRoutingCreateNestedManyWithoutGlobal_RuleInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  metric?: InputMaybe<Metric>;
  rank_sort_type: RankSortType;
  rule_type?: InputMaybe<TransferRuleType>;
  transfer_type?: InputMaybe<TransferType>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

/** GlobalTransferRuleInput for create / update */
export type GlobalTransferRuleInput = {
  backup_rule: BackUpRule;
  date_range?: InputMaybe<DateOption>;
  distribution_method?: InputMaybe<Distribution_Method>;
  metric?: InputMaybe<Metric>;
  rank_sort_type?: InputMaybe<RankSortType>;
  rep_ids: Array<Scalars['String']['input']>;
  transfer_type: TransferType;
};

export type GlobalTransferRuleListRelationFilter = {
  every?: InputMaybe<GlobalTransferRuleWhereInput>;
  none?: InputMaybe<GlobalTransferRuleWhereInput>;
  some?: InputMaybe<GlobalTransferRuleWhereInput>;
};

export type GlobalTransferRuleOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
  count?: InputMaybe<SortOrder>;
};

export type GlobalTransferRuleRule_TypeOrganization_IdCompoundUniqueInput = {
  organization_id: Scalars['String']['input'];
  rule_type: TransferRuleType;
};

export type GlobalTransferRuleScalarWhereInput = {
  AND?: InputMaybe<Array<GlobalTransferRuleScalarWhereInput>>;
  backup_rule?: InputMaybe<EnumBackUpRuleFilter>;
  created_at?: InputMaybe<DateTimeFilter>;
  date_range?: InputMaybe<EnumDateOptionFilter>;
  distribution_method?: InputMaybe<EnumDistribution_MethodFilter>;
  id?: InputMaybe<StringFilter>;
  metric?: InputMaybe<EnumMetricFilter>;
  NOT?: InputMaybe<Array<GlobalTransferRuleScalarWhereInput>>;
  OR?: InputMaybe<Array<GlobalTransferRuleScalarWhereInput>>;
  organization_id?: InputMaybe<StringFilter>;
  rank_sort_type?: InputMaybe<EnumRankSortTypeFilter>;
  rule_type?: InputMaybe<EnumTransferRuleTypeFilter>;
  transfer_type?: InputMaybe<EnumTransferTypeFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
};

export type GlobalTransferRuleUpdateManyMutationInput = {
  backup_rule?: InputMaybe<EnumBackUpRuleFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  date_range?: InputMaybe<EnumDateOptionFieldUpdateOperationsInput>;
  distribution_method?: InputMaybe<EnumDistribution_MethodFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  metric?: InputMaybe<EnumMetricFieldUpdateOperationsInput>;
  rank_sort_type?: InputMaybe<EnumRankSortTypeFieldUpdateOperationsInput>;
  rule_type?: InputMaybe<EnumTransferRuleTypeFieldUpdateOperationsInput>;
  transfer_type?: InputMaybe<EnumTransferTypeFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type GlobalTransferRuleUpdateManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<GlobalTransferRuleWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<GlobalTransferRuleCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<GlobalTransferRuleCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<GlobalTransferRuleCreateManyOrganizationInputEnvelope>;
  delete?: InputMaybe<Array<GlobalTransferRuleWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<GlobalTransferRuleScalarWhereInput>>;
  disconnect?: InputMaybe<Array<GlobalTransferRuleWhereUniqueInput>>;
  set?: InputMaybe<Array<GlobalTransferRuleWhereUniqueInput>>;
  update?: InputMaybe<Array<GlobalTransferRuleUpdateWithWhereUniqueWithoutOrganizationInput>>;
  updateMany?: InputMaybe<Array<GlobalTransferRuleUpdateManyWithWhereWithoutOrganizationInput>>;
  upsert?: InputMaybe<Array<GlobalTransferRuleUpsertWithWhereUniqueWithoutOrganizationInput>>;
};

export type GlobalTransferRuleUpdateManyWithWhereWithoutOrganizationInput = {
  data: GlobalTransferRuleUpdateManyMutationInput;
  where: GlobalTransferRuleScalarWhereInput;
};

export type GlobalTransferRuleUpdateOneRequiredWithoutGlobalRepRoutingInput = {
  connect?: InputMaybe<GlobalTransferRuleWhereUniqueInput>;
  connectOrCreate?: InputMaybe<GlobalTransferRuleCreateOrConnectWithoutGlobalRepRoutingInput>;
  create?: InputMaybe<GlobalTransferRuleCreateWithoutGlobalRepRoutingInput>;
  update?: InputMaybe<GlobalTransferRuleUpdateWithoutGlobalRepRoutingInput>;
  upsert?: InputMaybe<GlobalTransferRuleUpsertWithoutGlobalRepRoutingInput>;
};

export type GlobalTransferRuleUpdateWithoutGlobalRepRoutingInput = {
  backup_rule?: InputMaybe<EnumBackUpRuleFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  date_range?: InputMaybe<EnumDateOptionFieldUpdateOperationsInput>;
  distribution_method?: InputMaybe<EnumDistribution_MethodFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  metric?: InputMaybe<EnumMetricFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutGlobalTransferRuleInput>;
  rank_sort_type?: InputMaybe<EnumRankSortTypeFieldUpdateOperationsInput>;
  rule_type?: InputMaybe<EnumTransferRuleTypeFieldUpdateOperationsInput>;
  transfer_type?: InputMaybe<EnumTransferTypeFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type GlobalTransferRuleUpdateWithoutOrganizationInput = {
  backup_rule?: InputMaybe<EnumBackUpRuleFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  date_range?: InputMaybe<EnumDateOptionFieldUpdateOperationsInput>;
  distribution_method?: InputMaybe<EnumDistribution_MethodFieldUpdateOperationsInput>;
  GlobalRepRouting?: InputMaybe<GlobalRepRoutingUpdateManyWithoutGlobal_RuleInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  metric?: InputMaybe<EnumMetricFieldUpdateOperationsInput>;
  rank_sort_type?: InputMaybe<EnumRankSortTypeFieldUpdateOperationsInput>;
  rule_type?: InputMaybe<EnumTransferRuleTypeFieldUpdateOperationsInput>;
  transfer_type?: InputMaybe<EnumTransferTypeFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type GlobalTransferRuleUpdateWithWhereUniqueWithoutOrganizationInput = {
  data: GlobalTransferRuleUpdateWithoutOrganizationInput;
  where: GlobalTransferRuleWhereUniqueInput;
};

export type GlobalTransferRuleUpsertWithoutGlobalRepRoutingInput = {
  create: GlobalTransferRuleCreateWithoutGlobalRepRoutingInput;
  update: GlobalTransferRuleUpdateWithoutGlobalRepRoutingInput;
};

export type GlobalTransferRuleUpsertWithWhereUniqueWithoutOrganizationInput = {
  create: GlobalTransferRuleCreateWithoutOrganizationInput;
  update: GlobalTransferRuleUpdateWithoutOrganizationInput;
  where: GlobalTransferRuleWhereUniqueInput;
};

export type GlobalTransferRuleWhereInput = {
  AND?: InputMaybe<Array<GlobalTransferRuleWhereInput>>;
  backup_rule?: InputMaybe<EnumBackUpRuleFilter>;
  created_at?: InputMaybe<DateTimeFilter>;
  date_range?: InputMaybe<EnumDateOptionFilter>;
  distribution_method?: InputMaybe<EnumDistribution_MethodFilter>;
  GlobalRepRouting?: InputMaybe<GlobalRepRoutingListRelationFilter>;
  id?: InputMaybe<StringFilter>;
  metric?: InputMaybe<EnumMetricFilter>;
  NOT?: InputMaybe<Array<GlobalTransferRuleWhereInput>>;
  OR?: InputMaybe<Array<GlobalTransferRuleWhereInput>>;
  organization?: InputMaybe<OrganizationWhereInput>;
  organization_id?: InputMaybe<StringFilter>;
  rank_sort_type?: InputMaybe<EnumRankSortTypeFilter>;
  rule_type?: InputMaybe<EnumTransferRuleTypeFilter>;
  transfer_type?: InputMaybe<EnumTransferTypeFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
};

export type GlobalTransferRuleWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  rule_type_organization_id?: InputMaybe<GlobalTransferRuleRule_TypeOrganization_IdCompoundUniqueInput>;
};

export enum Goalsetting {
  Revenue = 'Revenue',
  RevenueValue = 'RevenueValue'
}

export enum Hingepoint {
  CycleEnd = 'CycleEnd',
  Dm = 'DM',
  DmHold = 'DMHold',
  HoldDemo = 'HoldDemo',
  Ndm = 'NDM',
  NdmHold = 'NDMHold',
  PreviousDispositionValue = 'PreviousDispositionValue',
  Sale = 'Sale',
  Set = 'Set',
  Unchanged = 'Unchanged',
  WorkingNum = 'WorkingNum'
}

export type HingePointMultiplier = {
  __typename?: 'HingePointMultiplier';
  actual_actions_count: Scalars['Int']['output'];
  computed_rate: Scalars['Float']['output'];
  current_data_points: Scalars['Int']['output'];
  default_rate: Scalars['Float']['output'];
  DispositionMultiplier: Array<DispositionMultiplier>;
  HP_type: Hingepoint;
  is_algo_related: Scalars['Boolean']['output'];
  LeadFieldMultiplier: Array<LeadFieldMultiplier>;
  min_number_data_points: Scalars['Int']['output'];
  organization: Organization;
  organization_id: Scalars['String']['output'];
  possible_actions_count: Scalars['Int']['output'];
  sort_order?: Maybe<Scalars['Int']['output']>;
  use_default: Scalars['Boolean']['output'];
};


export type HingePointMultiplierDispositionMultiplierArgs = {
  after?: InputMaybe<DispositionMultiplierWhereUniqueInput>;
  before?: InputMaybe<DispositionMultiplierWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type HingePointMultiplierLeadFieldMultiplierArgs = {
  after?: InputMaybe<LeadFieldMultiplierWhereUniqueInput>;
  before?: InputMaybe<LeadFieldMultiplierWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type HingePointMultiplierCreateManyOrganizationInput = {
  actual_actions_count?: InputMaybe<Scalars['Int']['input']>;
  computed_rate: Scalars['Float']['input'];
  current_data_points?: InputMaybe<Scalars['Int']['input']>;
  default_rate: Scalars['Float']['input'];
  HP_type: Hingepoint;
  is_algo_related?: InputMaybe<Scalars['Boolean']['input']>;
  min_number_data_points?: InputMaybe<Scalars['Int']['input']>;
  possible_actions_count?: InputMaybe<Scalars['Int']['input']>;
  sort_order?: InputMaybe<Scalars['Int']['input']>;
  use_default?: InputMaybe<Scalars['Boolean']['input']>;
};

export type HingePointMultiplierCreateManyOrganizationInputEnvelope = {
  data?: InputMaybe<Array<HingePointMultiplierCreateManyOrganizationInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type HingePointMultiplierCreateNestedManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<HingePointMultiplierWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<HingePointMultiplierCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<HingePointMultiplierCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<HingePointMultiplierCreateManyOrganizationInputEnvelope>;
};

export type HingePointMultiplierCreateNestedOneWithoutDispositionMultiplierInput = {
  connect?: InputMaybe<HingePointMultiplierWhereUniqueInput>;
  connectOrCreate?: InputMaybe<HingePointMultiplierCreateOrConnectWithoutDispositionMultiplierInput>;
  create?: InputMaybe<HingePointMultiplierCreateWithoutDispositionMultiplierInput>;
};

export type HingePointMultiplierCreateNestedOneWithoutLeadFieldMultiplierCustomInput = {
  connect?: InputMaybe<HingePointMultiplierWhereUniqueInput>;
  connectOrCreate?: InputMaybe<HingePointMultiplierCreateOrConnectWithoutLeadFieldMultiplierCustomInput>;
  create?: InputMaybe<HingePointMultiplierCreateWithoutLeadFieldMultiplierCustomInput>;
};

export type HingePointMultiplierCreateNestedOneWithoutLeadFieldMultiplierInput = {
  connect?: InputMaybe<HingePointMultiplierWhereUniqueInput>;
  connectOrCreate?: InputMaybe<HingePointMultiplierCreateOrConnectWithoutLeadFieldMultiplierInput>;
  create?: InputMaybe<HingePointMultiplierCreateWithoutLeadFieldMultiplierInput>;
};

export type HingePointMultiplierCreateOrConnectWithoutDispositionMultiplierInput = {
  create: HingePointMultiplierCreateWithoutDispositionMultiplierInput;
  where: HingePointMultiplierWhereUniqueInput;
};

export type HingePointMultiplierCreateOrConnectWithoutLeadFieldMultiplierCustomInput = {
  create: HingePointMultiplierCreateWithoutLeadFieldMultiplierCustomInput;
  where: HingePointMultiplierWhereUniqueInput;
};

export type HingePointMultiplierCreateOrConnectWithoutLeadFieldMultiplierInput = {
  create: HingePointMultiplierCreateWithoutLeadFieldMultiplierInput;
  where: HingePointMultiplierWhereUniqueInput;
};

export type HingePointMultiplierCreateOrConnectWithoutOrganizationInput = {
  create: HingePointMultiplierCreateWithoutOrganizationInput;
  where: HingePointMultiplierWhereUniqueInput;
};

export type HingePointMultiplierCreateWithoutDispositionMultiplierInput = {
  actual_actions_count?: InputMaybe<Scalars['Int']['input']>;
  computed_rate: Scalars['Float']['input'];
  current_data_points?: InputMaybe<Scalars['Int']['input']>;
  default_rate: Scalars['Float']['input'];
  HP_type: Hingepoint;
  is_algo_related?: InputMaybe<Scalars['Boolean']['input']>;
  LeadFieldMultiplier?: InputMaybe<LeadFieldMultiplierCreateNestedManyWithoutHinge_PointInput>;
  LeadFieldMultiplierCustom?: InputMaybe<LeadFieldMultiplierCustomCreateNestedManyWithoutHinge_PointInput>;
  min_number_data_points?: InputMaybe<Scalars['Int']['input']>;
  organization: OrganizationCreateNestedOneWithoutHingePointMultiplierInput;
  possible_actions_count?: InputMaybe<Scalars['Int']['input']>;
  sort_order?: InputMaybe<Scalars['Int']['input']>;
  use_default?: InputMaybe<Scalars['Boolean']['input']>;
};

export type HingePointMultiplierCreateWithoutLeadFieldMultiplierCustomInput = {
  actual_actions_count?: InputMaybe<Scalars['Int']['input']>;
  computed_rate: Scalars['Float']['input'];
  current_data_points?: InputMaybe<Scalars['Int']['input']>;
  default_rate: Scalars['Float']['input'];
  DispositionMultiplier?: InputMaybe<DispositionMultiplierCreateNestedManyWithoutHinge_PointInput>;
  HP_type: Hingepoint;
  is_algo_related?: InputMaybe<Scalars['Boolean']['input']>;
  LeadFieldMultiplier?: InputMaybe<LeadFieldMultiplierCreateNestedManyWithoutHinge_PointInput>;
  min_number_data_points?: InputMaybe<Scalars['Int']['input']>;
  organization: OrganizationCreateNestedOneWithoutHingePointMultiplierInput;
  possible_actions_count?: InputMaybe<Scalars['Int']['input']>;
  sort_order?: InputMaybe<Scalars['Int']['input']>;
  use_default?: InputMaybe<Scalars['Boolean']['input']>;
};

export type HingePointMultiplierCreateWithoutLeadFieldMultiplierInput = {
  actual_actions_count?: InputMaybe<Scalars['Int']['input']>;
  computed_rate: Scalars['Float']['input'];
  current_data_points?: InputMaybe<Scalars['Int']['input']>;
  default_rate: Scalars['Float']['input'];
  DispositionMultiplier?: InputMaybe<DispositionMultiplierCreateNestedManyWithoutHinge_PointInput>;
  HP_type: Hingepoint;
  is_algo_related?: InputMaybe<Scalars['Boolean']['input']>;
  LeadFieldMultiplierCustom?: InputMaybe<LeadFieldMultiplierCustomCreateNestedManyWithoutHinge_PointInput>;
  min_number_data_points?: InputMaybe<Scalars['Int']['input']>;
  organization: OrganizationCreateNestedOneWithoutHingePointMultiplierInput;
  possible_actions_count?: InputMaybe<Scalars['Int']['input']>;
  sort_order?: InputMaybe<Scalars['Int']['input']>;
  use_default?: InputMaybe<Scalars['Boolean']['input']>;
};

export type HingePointMultiplierCreateWithoutOrganizationInput = {
  actual_actions_count?: InputMaybe<Scalars['Int']['input']>;
  computed_rate: Scalars['Float']['input'];
  current_data_points?: InputMaybe<Scalars['Int']['input']>;
  default_rate: Scalars['Float']['input'];
  DispositionMultiplier?: InputMaybe<DispositionMultiplierCreateNestedManyWithoutHinge_PointInput>;
  HP_type: Hingepoint;
  is_algo_related?: InputMaybe<Scalars['Boolean']['input']>;
  LeadFieldMultiplier?: InputMaybe<LeadFieldMultiplierCreateNestedManyWithoutHinge_PointInput>;
  LeadFieldMultiplierCustom?: InputMaybe<LeadFieldMultiplierCustomCreateNestedManyWithoutHinge_PointInput>;
  min_number_data_points?: InputMaybe<Scalars['Int']['input']>;
  possible_actions_count?: InputMaybe<Scalars['Int']['input']>;
  sort_order?: InputMaybe<Scalars['Int']['input']>;
  use_default?: InputMaybe<Scalars['Boolean']['input']>;
};

export type HingePointMultiplierHinge_Point_IdCompoundUniqueInput = {
  HP_type: Hingepoint;
  organization_id: Scalars['String']['input'];
};

export type HingePointMultiplierListRelationFilter = {
  every?: InputMaybe<HingePointMultiplierWhereInput>;
  none?: InputMaybe<HingePointMultiplierWhereInput>;
  some?: InputMaybe<HingePointMultiplierWhereInput>;
};

export type HingePointMultiplierOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
  count?: InputMaybe<SortOrder>;
};

export type HingePointMultiplierScalarWhereInput = {
  actual_actions_count?: InputMaybe<IntFilter>;
  AND?: InputMaybe<Array<HingePointMultiplierScalarWhereInput>>;
  computed_rate?: InputMaybe<FloatFilter>;
  current_data_points?: InputMaybe<IntFilter>;
  default_rate?: InputMaybe<FloatFilter>;
  HP_type?: InputMaybe<EnumHingepointFilter>;
  is_algo_related?: InputMaybe<BoolFilter>;
  min_number_data_points?: InputMaybe<IntFilter>;
  NOT?: InputMaybe<Array<HingePointMultiplierScalarWhereInput>>;
  OR?: InputMaybe<Array<HingePointMultiplierScalarWhereInput>>;
  organization_id?: InputMaybe<StringFilter>;
  possible_actions_count?: InputMaybe<IntFilter>;
  sort_order?: InputMaybe<IntNullableFilter>;
  use_default?: InputMaybe<BoolFilter>;
};

export type HingePointMultiplierUpdateManyMutationInput = {
  actual_actions_count?: InputMaybe<IntFieldUpdateOperationsInput>;
  computed_rate?: InputMaybe<FloatFieldUpdateOperationsInput>;
  current_data_points?: InputMaybe<IntFieldUpdateOperationsInput>;
  default_rate?: InputMaybe<FloatFieldUpdateOperationsInput>;
  HP_type?: InputMaybe<EnumHingepointFieldUpdateOperationsInput>;
  is_algo_related?: InputMaybe<BoolFieldUpdateOperationsInput>;
  min_number_data_points?: InputMaybe<IntFieldUpdateOperationsInput>;
  possible_actions_count?: InputMaybe<IntFieldUpdateOperationsInput>;
  sort_order?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  use_default?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type HingePointMultiplierUpdateManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<HingePointMultiplierWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<HingePointMultiplierCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<HingePointMultiplierCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<HingePointMultiplierCreateManyOrganizationInputEnvelope>;
  delete?: InputMaybe<Array<HingePointMultiplierWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<HingePointMultiplierScalarWhereInput>>;
  disconnect?: InputMaybe<Array<HingePointMultiplierWhereUniqueInput>>;
  set?: InputMaybe<Array<HingePointMultiplierWhereUniqueInput>>;
  update?: InputMaybe<Array<HingePointMultiplierUpdateWithWhereUniqueWithoutOrganizationInput>>;
  updateMany?: InputMaybe<Array<HingePointMultiplierUpdateManyWithWhereWithoutOrganizationInput>>;
  upsert?: InputMaybe<Array<HingePointMultiplierUpsertWithWhereUniqueWithoutOrganizationInput>>;
};

export type HingePointMultiplierUpdateManyWithWhereWithoutOrganizationInput = {
  data: HingePointMultiplierUpdateManyMutationInput;
  where: HingePointMultiplierScalarWhereInput;
};

export type HingePointMultiplierUpdateOneRequiredWithoutDispositionMultiplierInput = {
  connect?: InputMaybe<HingePointMultiplierWhereUniqueInput>;
  connectOrCreate?: InputMaybe<HingePointMultiplierCreateOrConnectWithoutDispositionMultiplierInput>;
  create?: InputMaybe<HingePointMultiplierCreateWithoutDispositionMultiplierInput>;
  update?: InputMaybe<HingePointMultiplierUpdateWithoutDispositionMultiplierInput>;
  upsert?: InputMaybe<HingePointMultiplierUpsertWithoutDispositionMultiplierInput>;
};

export type HingePointMultiplierUpdateOneRequiredWithoutLeadFieldMultiplierCustomInput = {
  connect?: InputMaybe<HingePointMultiplierWhereUniqueInput>;
  connectOrCreate?: InputMaybe<HingePointMultiplierCreateOrConnectWithoutLeadFieldMultiplierCustomInput>;
  create?: InputMaybe<HingePointMultiplierCreateWithoutLeadFieldMultiplierCustomInput>;
  update?: InputMaybe<HingePointMultiplierUpdateWithoutLeadFieldMultiplierCustomInput>;
  upsert?: InputMaybe<HingePointMultiplierUpsertWithoutLeadFieldMultiplierCustomInput>;
};

export type HingePointMultiplierUpdateOneRequiredWithoutLeadFieldMultiplierInput = {
  connect?: InputMaybe<HingePointMultiplierWhereUniqueInput>;
  connectOrCreate?: InputMaybe<HingePointMultiplierCreateOrConnectWithoutLeadFieldMultiplierInput>;
  create?: InputMaybe<HingePointMultiplierCreateWithoutLeadFieldMultiplierInput>;
  update?: InputMaybe<HingePointMultiplierUpdateWithoutLeadFieldMultiplierInput>;
  upsert?: InputMaybe<HingePointMultiplierUpsertWithoutLeadFieldMultiplierInput>;
};

export type HingePointMultiplierUpdateWithoutDispositionMultiplierInput = {
  actual_actions_count?: InputMaybe<IntFieldUpdateOperationsInput>;
  computed_rate?: InputMaybe<FloatFieldUpdateOperationsInput>;
  current_data_points?: InputMaybe<IntFieldUpdateOperationsInput>;
  default_rate?: InputMaybe<FloatFieldUpdateOperationsInput>;
  HP_type?: InputMaybe<EnumHingepointFieldUpdateOperationsInput>;
  is_algo_related?: InputMaybe<BoolFieldUpdateOperationsInput>;
  LeadFieldMultiplier?: InputMaybe<LeadFieldMultiplierUpdateManyWithoutHinge_PointInput>;
  LeadFieldMultiplierCustom?: InputMaybe<LeadFieldMultiplierCustomUpdateManyWithoutHinge_PointInput>;
  min_number_data_points?: InputMaybe<IntFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutHingePointMultiplierInput>;
  possible_actions_count?: InputMaybe<IntFieldUpdateOperationsInput>;
  sort_order?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  use_default?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type HingePointMultiplierUpdateWithoutLeadFieldMultiplierCustomInput = {
  actual_actions_count?: InputMaybe<IntFieldUpdateOperationsInput>;
  computed_rate?: InputMaybe<FloatFieldUpdateOperationsInput>;
  current_data_points?: InputMaybe<IntFieldUpdateOperationsInput>;
  default_rate?: InputMaybe<FloatFieldUpdateOperationsInput>;
  DispositionMultiplier?: InputMaybe<DispositionMultiplierUpdateManyWithoutHinge_PointInput>;
  HP_type?: InputMaybe<EnumHingepointFieldUpdateOperationsInput>;
  is_algo_related?: InputMaybe<BoolFieldUpdateOperationsInput>;
  LeadFieldMultiplier?: InputMaybe<LeadFieldMultiplierUpdateManyWithoutHinge_PointInput>;
  min_number_data_points?: InputMaybe<IntFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutHingePointMultiplierInput>;
  possible_actions_count?: InputMaybe<IntFieldUpdateOperationsInput>;
  sort_order?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  use_default?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type HingePointMultiplierUpdateWithoutLeadFieldMultiplierInput = {
  actual_actions_count?: InputMaybe<IntFieldUpdateOperationsInput>;
  computed_rate?: InputMaybe<FloatFieldUpdateOperationsInput>;
  current_data_points?: InputMaybe<IntFieldUpdateOperationsInput>;
  default_rate?: InputMaybe<FloatFieldUpdateOperationsInput>;
  DispositionMultiplier?: InputMaybe<DispositionMultiplierUpdateManyWithoutHinge_PointInput>;
  HP_type?: InputMaybe<EnumHingepointFieldUpdateOperationsInput>;
  is_algo_related?: InputMaybe<BoolFieldUpdateOperationsInput>;
  LeadFieldMultiplierCustom?: InputMaybe<LeadFieldMultiplierCustomUpdateManyWithoutHinge_PointInput>;
  min_number_data_points?: InputMaybe<IntFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutHingePointMultiplierInput>;
  possible_actions_count?: InputMaybe<IntFieldUpdateOperationsInput>;
  sort_order?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  use_default?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type HingePointMultiplierUpdateWithoutOrganizationInput = {
  actual_actions_count?: InputMaybe<IntFieldUpdateOperationsInput>;
  computed_rate?: InputMaybe<FloatFieldUpdateOperationsInput>;
  current_data_points?: InputMaybe<IntFieldUpdateOperationsInput>;
  default_rate?: InputMaybe<FloatFieldUpdateOperationsInput>;
  DispositionMultiplier?: InputMaybe<DispositionMultiplierUpdateManyWithoutHinge_PointInput>;
  HP_type?: InputMaybe<EnumHingepointFieldUpdateOperationsInput>;
  is_algo_related?: InputMaybe<BoolFieldUpdateOperationsInput>;
  LeadFieldMultiplier?: InputMaybe<LeadFieldMultiplierUpdateManyWithoutHinge_PointInput>;
  LeadFieldMultiplierCustom?: InputMaybe<LeadFieldMultiplierCustomUpdateManyWithoutHinge_PointInput>;
  min_number_data_points?: InputMaybe<IntFieldUpdateOperationsInput>;
  possible_actions_count?: InputMaybe<IntFieldUpdateOperationsInput>;
  sort_order?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  use_default?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type HingePointMultiplierUpdateWithWhereUniqueWithoutOrganizationInput = {
  data: HingePointMultiplierUpdateWithoutOrganizationInput;
  where: HingePointMultiplierWhereUniqueInput;
};

export type HingePointMultiplierUpsertWithoutDispositionMultiplierInput = {
  create: HingePointMultiplierCreateWithoutDispositionMultiplierInput;
  update: HingePointMultiplierUpdateWithoutDispositionMultiplierInput;
};

export type HingePointMultiplierUpsertWithoutLeadFieldMultiplierCustomInput = {
  create: HingePointMultiplierCreateWithoutLeadFieldMultiplierCustomInput;
  update: HingePointMultiplierUpdateWithoutLeadFieldMultiplierCustomInput;
};

export type HingePointMultiplierUpsertWithoutLeadFieldMultiplierInput = {
  create: HingePointMultiplierCreateWithoutLeadFieldMultiplierInput;
  update: HingePointMultiplierUpdateWithoutLeadFieldMultiplierInput;
};

export type HingePointMultiplierUpsertWithWhereUniqueWithoutOrganizationInput = {
  create: HingePointMultiplierCreateWithoutOrganizationInput;
  update: HingePointMultiplierUpdateWithoutOrganizationInput;
  where: HingePointMultiplierWhereUniqueInput;
};

export type HingePointMultiplierWhereInput = {
  actual_actions_count?: InputMaybe<IntFilter>;
  AND?: InputMaybe<Array<HingePointMultiplierWhereInput>>;
  computed_rate?: InputMaybe<FloatFilter>;
  current_data_points?: InputMaybe<IntFilter>;
  default_rate?: InputMaybe<FloatFilter>;
  DispositionMultiplier?: InputMaybe<DispositionMultiplierListRelationFilter>;
  HP_type?: InputMaybe<EnumHingepointFilter>;
  is_algo_related?: InputMaybe<BoolFilter>;
  LeadFieldMultiplier?: InputMaybe<LeadFieldMultiplierListRelationFilter>;
  LeadFieldMultiplierCustom?: InputMaybe<LeadFieldMultiplierCustomListRelationFilter>;
  min_number_data_points?: InputMaybe<IntFilter>;
  NOT?: InputMaybe<Array<HingePointMultiplierWhereInput>>;
  OR?: InputMaybe<Array<HingePointMultiplierWhereInput>>;
  organization?: InputMaybe<OrganizationWhereInput>;
  organization_id?: InputMaybe<StringFilter>;
  possible_actions_count?: InputMaybe<IntFilter>;
  sort_order?: InputMaybe<IntNullableFilter>;
  use_default?: InputMaybe<BoolFilter>;
};

export type HingePointMultiplierWhereUniqueInput = {
  hinge_point_id?: InputMaybe<HingePointMultiplierHinge_Point_IdCompoundUniqueInput>;
};

export enum HistoryEventTypeOption {
  All = 'All',
  Call = 'Call',
  CallAndNonCall = 'CallAndNonCall',
  Email = 'Email',
  NonCall = 'NonCall',
  Note = 'Note',
  Other = 'Other',
  Sms = 'SMS'
}

export type Holiday = {
  __typename?: 'Holiday';
  created_at: Scalars['DateTime']['output'];
  date: Scalars['DateTime']['output'];
  /** Date in string form */
  date_string?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  label: Scalars['String']['output'];
  organization_holiday: Array<OrganizationHoliday>;
  updated_at: Scalars['DateTime']['output'];
  year: Scalars['String']['output'];
};


export type HolidayOrganization_HolidayArgs = {
  after?: InputMaybe<OrganizationHolidayWhereUniqueInput>;
  before?: InputMaybe<OrganizationHolidayWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type HolidayCreateNestedOneWithoutOrganization_HolidayInput = {
  connect?: InputMaybe<HolidayWhereUniqueInput>;
  connectOrCreate?: InputMaybe<HolidayCreateOrConnectWithoutOrganization_HolidayInput>;
  create?: InputMaybe<HolidayCreateWithoutOrganization_HolidayInput>;
};

export type HolidayCreateOrConnectWithoutOrganization_HolidayInput = {
  create: HolidayCreateWithoutOrganization_HolidayInput;
  where: HolidayWhereUniqueInput;
};

export type HolidayCreateWithoutOrganization_HolidayInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  date: Scalars['DateTime']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  label: Scalars['String']['input'];
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  year: Scalars['String']['input'];
};

export type HolidayUpdateOneRequiredWithoutOrganization_HolidayInput = {
  connect?: InputMaybe<HolidayWhereUniqueInput>;
  connectOrCreate?: InputMaybe<HolidayCreateOrConnectWithoutOrganization_HolidayInput>;
  create?: InputMaybe<HolidayCreateWithoutOrganization_HolidayInput>;
  update?: InputMaybe<HolidayUpdateWithoutOrganization_HolidayInput>;
  upsert?: InputMaybe<HolidayUpsertWithoutOrganization_HolidayInput>;
};

export type HolidayUpdateWithoutOrganization_HolidayInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  date?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  label?: InputMaybe<StringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  year?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type HolidayUpsertWithoutOrganization_HolidayInput = {
  create: HolidayCreateWithoutOrganization_HolidayInput;
  update: HolidayUpdateWithoutOrganization_HolidayInput;
};

export type HolidayWhereInput = {
  AND?: InputMaybe<Array<HolidayWhereInput>>;
  created_at?: InputMaybe<DateTimeFilter>;
  date?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  label?: InputMaybe<StringFilter>;
  NOT?: InputMaybe<Array<HolidayWhereInput>>;
  OR?: InputMaybe<Array<HolidayWhereInput>>;
  organization_holiday?: InputMaybe<OrganizationHolidayListRelationFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
  year?: InputMaybe<StringFilter>;
};

export type HolidayWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']['input']>;
};

export type HubSpotCredential = {
  __typename?: 'HubSpotCredential';
  created_at: Scalars['DateTime']['output'];
  hubspot_portal_id: Scalars['String']['output'];
  id: Scalars['String']['output'];
  organization: Organization;
  organization_id: Scalars['String']['output'];
  updated_at: Scalars['DateTime']['output'];
  use_white_list?: Maybe<Scalars['Boolean']['output']>;
};

export type HubSpotCredentialCreateNestedOneWithoutOrganizationInput = {
  connect?: InputMaybe<HubSpotCredentialWhereUniqueInput>;
  connectOrCreate?: InputMaybe<HubSpotCredentialCreateOrConnectWithoutOrganizationInput>;
  create?: InputMaybe<HubSpotCredentialCreateWithoutOrganizationInput>;
};

export type HubSpotCredentialCreateOrConnectWithoutOrganizationInput = {
  create: HubSpotCredentialCreateWithoutOrganizationInput;
  where: HubSpotCredentialWhereUniqueInput;
};

export type HubSpotCredentialCreateWithoutOrganizationInput = {
  access_token: Scalars['String']['input'];
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  hubspot_portal_id: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  inbound_activity_sync?: InputMaybe<Scalars['Boolean']['input']>;
  last_activity_backfill?: InputMaybe<Scalars['DateTime']['input']>;
  lead_create_inbound?: InputMaybe<Scalars['Boolean']['input']>;
  lead_create_outbound?: InputMaybe<Scalars['Boolean']['input']>;
  lead_update_inbound?: InputMaybe<Scalars['Boolean']['input']>;
  lead_update_outbound?: InputMaybe<Scalars['Boolean']['input']>;
  outbound_activity_sync?: InputMaybe<Scalars['Boolean']['input']>;
  refresh_token: Scalars['String']['input'];
  sale_create_outbound?: InputMaybe<Scalars['Boolean']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  use_white_list?: InputMaybe<Scalars['Boolean']['input']>;
};

export type HubSpotCredentialOrderByWithRelationInput = {
  access_token?: InputMaybe<SortOrder>;
  created_at?: InputMaybe<SortOrder>;
  hubspot_portal_id?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  inbound_activity_sync?: InputMaybe<SortOrder>;
  last_activity_backfill?: InputMaybe<SortOrder>;
  lead_create_inbound?: InputMaybe<SortOrder>;
  lead_create_outbound?: InputMaybe<SortOrder>;
  lead_update_inbound?: InputMaybe<SortOrder>;
  lead_update_outbound?: InputMaybe<SortOrder>;
  organization?: InputMaybe<OrganizationOrderByWithRelationInput>;
  organization_id?: InputMaybe<SortOrder>;
  outbound_activity_sync?: InputMaybe<SortOrder>;
  refresh_token?: InputMaybe<SortOrder>;
  sale_create_outbound?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
  use_white_list?: InputMaybe<SortOrder>;
};

export type HubSpotCredentialUpdateOneWithoutOrganizationInput = {
  connect?: InputMaybe<HubSpotCredentialWhereUniqueInput>;
  connectOrCreate?: InputMaybe<HubSpotCredentialCreateOrConnectWithoutOrganizationInput>;
  create?: InputMaybe<HubSpotCredentialCreateWithoutOrganizationInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<HubSpotCredentialUpdateWithoutOrganizationInput>;
  upsert?: InputMaybe<HubSpotCredentialUpsertWithoutOrganizationInput>;
};

export type HubSpotCredentialUpdateWithoutOrganizationInput = {
  access_token?: InputMaybe<StringFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  hubspot_portal_id?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inbound_activity_sync?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  last_activity_backfill?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lead_create_inbound?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  lead_create_outbound?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  lead_update_inbound?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  lead_update_outbound?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  outbound_activity_sync?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  refresh_token?: InputMaybe<StringFieldUpdateOperationsInput>;
  sale_create_outbound?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  use_white_list?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
};

export type HubSpotCredentialUpsertWithoutOrganizationInput = {
  create: HubSpotCredentialCreateWithoutOrganizationInput;
  update: HubSpotCredentialUpdateWithoutOrganizationInput;
};

export type HubSpotCredentialWhereInput = {
  access_token?: InputMaybe<StringFilter>;
  AND?: InputMaybe<Array<HubSpotCredentialWhereInput>>;
  created_at?: InputMaybe<DateTimeFilter>;
  hubspot_portal_id?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  inbound_activity_sync?: InputMaybe<BoolNullableFilter>;
  last_activity_backfill?: InputMaybe<DateTimeNullableFilter>;
  lead_create_inbound?: InputMaybe<BoolNullableFilter>;
  lead_create_outbound?: InputMaybe<BoolNullableFilter>;
  lead_update_inbound?: InputMaybe<BoolNullableFilter>;
  lead_update_outbound?: InputMaybe<BoolNullableFilter>;
  NOT?: InputMaybe<Array<HubSpotCredentialWhereInput>>;
  OR?: InputMaybe<Array<HubSpotCredentialWhereInput>>;
  organization?: InputMaybe<OrganizationWhereInput>;
  organization_id?: InputMaybe<StringFilter>;
  outbound_activity_sync?: InputMaybe<BoolNullableFilter>;
  refresh_token?: InputMaybe<StringFilter>;
  sale_create_outbound?: InputMaybe<BoolNullableFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
  use_white_list?: InputMaybe<BoolNullableFilter>;
};

export type HubSpotCredentialWhereUniqueInput = {
  hubspot_portal_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  organization_id?: InputMaybe<Scalars['String']['input']>;
};

export type HubSpotWhitelistCreateManyOrganizationInput = {
  contact_id: Scalars['String']['input'];
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type HubSpotWhitelistCreateManyOrganizationInputEnvelope = {
  data?: InputMaybe<Array<HubSpotWhitelistCreateManyOrganizationInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type HubSpotWhitelistCreateNestedManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<HubSpotWhitelistWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<HubSpotWhitelistCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<HubSpotWhitelistCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<HubSpotWhitelistCreateManyOrganizationInputEnvelope>;
};

export type HubSpotWhitelistCreateOrConnectWithoutOrganizationInput = {
  create: HubSpotWhitelistCreateWithoutOrganizationInput;
  where: HubSpotWhitelistWhereUniqueInput;
};

export type HubSpotWhitelistCreateWithoutOrganizationInput = {
  contact_id: Scalars['String']['input'];
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type HubSpotWhitelistListRelationFilter = {
  every?: InputMaybe<HubSpotWhitelistWhereInput>;
  none?: InputMaybe<HubSpotWhitelistWhereInput>;
  some?: InputMaybe<HubSpotWhitelistWhereInput>;
};

export type HubSpotWhitelistOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
  count?: InputMaybe<SortOrder>;
};

export type HubSpotWhitelistScalarWhereInput = {
  AND?: InputMaybe<Array<HubSpotWhitelistScalarWhereInput>>;
  contact_id?: InputMaybe<StringFilter>;
  created_at?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  NOT?: InputMaybe<Array<HubSpotWhitelistScalarWhereInput>>;
  OR?: InputMaybe<Array<HubSpotWhitelistScalarWhereInput>>;
  organization_id?: InputMaybe<StringNullableFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
};

export type HubSpotWhitelistUpdateManyMutationInput = {
  contact_id?: InputMaybe<StringFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type HubSpotWhitelistUpdateManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<HubSpotWhitelistWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<HubSpotWhitelistCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<HubSpotWhitelistCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<HubSpotWhitelistCreateManyOrganizationInputEnvelope>;
  delete?: InputMaybe<Array<HubSpotWhitelistWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<HubSpotWhitelistScalarWhereInput>>;
  disconnect?: InputMaybe<Array<HubSpotWhitelistWhereUniqueInput>>;
  set?: InputMaybe<Array<HubSpotWhitelistWhereUniqueInput>>;
  update?: InputMaybe<Array<HubSpotWhitelistUpdateWithWhereUniqueWithoutOrganizationInput>>;
  updateMany?: InputMaybe<Array<HubSpotWhitelistUpdateManyWithWhereWithoutOrganizationInput>>;
  upsert?: InputMaybe<Array<HubSpotWhitelistUpsertWithWhereUniqueWithoutOrganizationInput>>;
};

export type HubSpotWhitelistUpdateManyWithWhereWithoutOrganizationInput = {
  data: HubSpotWhitelistUpdateManyMutationInput;
  where: HubSpotWhitelistScalarWhereInput;
};

export type HubSpotWhitelistUpdateWithoutOrganizationInput = {
  contact_id?: InputMaybe<StringFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type HubSpotWhitelistUpdateWithWhereUniqueWithoutOrganizationInput = {
  data: HubSpotWhitelistUpdateWithoutOrganizationInput;
  where: HubSpotWhitelistWhereUniqueInput;
};

export type HubSpotWhitelistUpsertWithWhereUniqueWithoutOrganizationInput = {
  create: HubSpotWhitelistCreateWithoutOrganizationInput;
  update: HubSpotWhitelistUpdateWithoutOrganizationInput;
  where: HubSpotWhitelistWhereUniqueInput;
};

export type HubSpotWhitelistWhereInput = {
  AND?: InputMaybe<Array<HubSpotWhitelistWhereInput>>;
  contact_id?: InputMaybe<StringFilter>;
  created_at?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  NOT?: InputMaybe<Array<HubSpotWhitelistWhereInput>>;
  OR?: InputMaybe<Array<HubSpotWhitelistWhereInput>>;
  organization?: InputMaybe<OrganizationWhereInput>;
  organization_id?: InputMaybe<StringNullableFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
};

export type HubSpotWhitelistWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']['input']>;
};

export type ImageUpload = {
  __typename?: 'ImageUpload';
  filename?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type InboundConciergeEvent = {
  __typename?: 'InboundConciergeEvent';
  created_at: Scalars['DateTime']['output'];
  /** If true do not show widget */
  disableWidget?: Maybe<Scalars['Boolean']['output']>;
  existingLead: Scalars['Boolean']['output'];
  form_data: Scalars['Json']['output'];
  id: Scalars['String']['output'];
  lead?: Maybe<Lead>;
  lead_activity_id?: Maybe<Scalars['String']['output']>;
  lead_id?: Maybe<Scalars['String']['output']>;
  leadActivity?: Maybe<LeadActivity>;
  organization: Organization;
  organization_id: Scalars['String']['output'];
  ownedRepOnCall: Scalars['Boolean']['output'];
  type: InboundConciergeEventType;
  updated_at: Scalars['DateTime']['output'];
};

export type InboundConciergeEventCreateManyLeadActivityInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  existingLead?: InputMaybe<Scalars['Boolean']['input']>;
  first_dial_time?: InputMaybe<Scalars['DateTime']['input']>;
  form_data: Scalars['Json']['input'];
  has_been_routed?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  lead_assignment_history_id?: InputMaybe<Scalars['String']['input']>;
  lead_id?: InputMaybe<Scalars['String']['input']>;
  organization_id: Scalars['String']['input'];
  ownedRepOnCall?: InputMaybe<Scalars['Boolean']['input']>;
  routing_history_id?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<InboundConciergeEventType>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type InboundConciergeEventCreateManyLeadActivityInputEnvelope = {
  data?: InputMaybe<Array<InboundConciergeEventCreateManyLeadActivityInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type InboundConciergeEventCreateManyLeadInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  existingLead?: InputMaybe<Scalars['Boolean']['input']>;
  first_dial_time?: InputMaybe<Scalars['DateTime']['input']>;
  form_data: Scalars['Json']['input'];
  has_been_routed?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  lead_activity_id?: InputMaybe<Scalars['String']['input']>;
  lead_assignment_history_id?: InputMaybe<Scalars['String']['input']>;
  organization_id: Scalars['String']['input'];
  ownedRepOnCall?: InputMaybe<Scalars['Boolean']['input']>;
  routing_history_id?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<InboundConciergeEventType>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type InboundConciergeEventCreateManyLeadInputEnvelope = {
  data?: InputMaybe<Array<InboundConciergeEventCreateManyLeadInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type InboundConciergeEventCreateManyOrganizationInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  existingLead?: InputMaybe<Scalars['Boolean']['input']>;
  first_dial_time?: InputMaybe<Scalars['DateTime']['input']>;
  form_data: Scalars['Json']['input'];
  has_been_routed?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  lead_activity_id?: InputMaybe<Scalars['String']['input']>;
  lead_assignment_history_id?: InputMaybe<Scalars['String']['input']>;
  lead_id?: InputMaybe<Scalars['String']['input']>;
  ownedRepOnCall?: InputMaybe<Scalars['Boolean']['input']>;
  routing_history_id?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<InboundConciergeEventType>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type InboundConciergeEventCreateManyOrganizationInputEnvelope = {
  data?: InputMaybe<Array<InboundConciergeEventCreateManyOrganizationInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type InboundConciergeEventCreateNestedManyWithoutLeadActivityInput = {
  connect?: InputMaybe<Array<InboundConciergeEventWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<InboundConciergeEventCreateOrConnectWithoutLeadActivityInput>>;
  create?: InputMaybe<Array<InboundConciergeEventCreateWithoutLeadActivityInput>>;
  createMany?: InputMaybe<InboundConciergeEventCreateManyLeadActivityInputEnvelope>;
};

export type InboundConciergeEventCreateNestedManyWithoutLeadInput = {
  connect?: InputMaybe<Array<InboundConciergeEventWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<InboundConciergeEventCreateOrConnectWithoutLeadInput>>;
  create?: InputMaybe<Array<InboundConciergeEventCreateWithoutLeadInput>>;
  createMany?: InputMaybe<InboundConciergeEventCreateManyLeadInputEnvelope>;
};

export type InboundConciergeEventCreateNestedManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<InboundConciergeEventWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<InboundConciergeEventCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<InboundConciergeEventCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<InboundConciergeEventCreateManyOrganizationInputEnvelope>;
};

export type InboundConciergeEventCreateNestedOneWithoutCallMeNowInput = {
  connect?: InputMaybe<InboundConciergeEventWhereUniqueInput>;
  connectOrCreate?: InputMaybe<InboundConciergeEventCreateOrConnectWithoutCallMeNowInput>;
  create?: InputMaybe<InboundConciergeEventCreateWithoutCallMeNowInput>;
};

export type InboundConciergeEventCreateNestedOneWithoutLead_Assignment_HistoryInput = {
  connect?: InputMaybe<InboundConciergeEventWhereUniqueInput>;
  connectOrCreate?: InputMaybe<InboundConciergeEventCreateOrConnectWithoutLead_Assignment_HistoryInput>;
  create?: InputMaybe<InboundConciergeEventCreateWithoutLead_Assignment_HistoryInput>;
};

export type InboundConciergeEventCreateNestedOneWithoutRouting_HistoryInput = {
  connect?: InputMaybe<InboundConciergeEventWhereUniqueInput>;
  connectOrCreate?: InputMaybe<InboundConciergeEventCreateOrConnectWithoutRouting_HistoryInput>;
  create?: InputMaybe<InboundConciergeEventCreateWithoutRouting_HistoryInput>;
};

export type InboundConciergeEventCreateOrConnectWithoutCallMeNowInput = {
  create: InboundConciergeEventCreateWithoutCallMeNowInput;
  where: InboundConciergeEventWhereUniqueInput;
};

export type InboundConciergeEventCreateOrConnectWithoutLead_Assignment_HistoryInput = {
  create: InboundConciergeEventCreateWithoutLead_Assignment_HistoryInput;
  where: InboundConciergeEventWhereUniqueInput;
};

export type InboundConciergeEventCreateOrConnectWithoutLeadActivityInput = {
  create: InboundConciergeEventCreateWithoutLeadActivityInput;
  where: InboundConciergeEventWhereUniqueInput;
};

export type InboundConciergeEventCreateOrConnectWithoutLeadInput = {
  create: InboundConciergeEventCreateWithoutLeadInput;
  where: InboundConciergeEventWhereUniqueInput;
};

export type InboundConciergeEventCreateOrConnectWithoutOrganizationInput = {
  create: InboundConciergeEventCreateWithoutOrganizationInput;
  where: InboundConciergeEventWhereUniqueInput;
};

export type InboundConciergeEventCreateOrConnectWithoutRouting_HistoryInput = {
  create: InboundConciergeEventCreateWithoutRouting_HistoryInput;
  where: InboundConciergeEventWhereUniqueInput;
};

export type InboundConciergeEventCreateWithoutCallMeNowInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  existingLead?: InputMaybe<Scalars['Boolean']['input']>;
  first_dial_time?: InputMaybe<Scalars['DateTime']['input']>;
  form_data: Scalars['Json']['input'];
  has_been_routed?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  lead?: InputMaybe<LeadCreateNestedOneWithoutInboundConciergeEventsInput>;
  lead_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedOneWithoutInboundConciergeEventInput>;
  leadActivity?: InputMaybe<LeadActivityCreateNestedOneWithoutInboundConciergeEventsInput>;
  organization: OrganizationCreateNestedOneWithoutInboundConciergeEventsInput;
  ownedRepOnCall?: InputMaybe<Scalars['Boolean']['input']>;
  routing_history?: InputMaybe<RoutingHistoryCreateNestedOneWithoutInboundConciergeEventInput>;
  type?: InputMaybe<InboundConciergeEventType>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type InboundConciergeEventCreateWithoutLead_Assignment_HistoryInput = {
  callMeNow?: InputMaybe<CallMeNowCreateNestedOneWithoutInboundConciergeEventInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  existingLead?: InputMaybe<Scalars['Boolean']['input']>;
  first_dial_time?: InputMaybe<Scalars['DateTime']['input']>;
  form_data: Scalars['Json']['input'];
  has_been_routed?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  lead?: InputMaybe<LeadCreateNestedOneWithoutInboundConciergeEventsInput>;
  leadActivity?: InputMaybe<LeadActivityCreateNestedOneWithoutInboundConciergeEventsInput>;
  organization: OrganizationCreateNestedOneWithoutInboundConciergeEventsInput;
  ownedRepOnCall?: InputMaybe<Scalars['Boolean']['input']>;
  routing_history?: InputMaybe<RoutingHistoryCreateNestedOneWithoutInboundConciergeEventInput>;
  type?: InputMaybe<InboundConciergeEventType>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type InboundConciergeEventCreateWithoutLeadActivityInput = {
  callMeNow?: InputMaybe<CallMeNowCreateNestedOneWithoutInboundConciergeEventInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  existingLead?: InputMaybe<Scalars['Boolean']['input']>;
  first_dial_time?: InputMaybe<Scalars['DateTime']['input']>;
  form_data: Scalars['Json']['input'];
  has_been_routed?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  lead?: InputMaybe<LeadCreateNestedOneWithoutInboundConciergeEventsInput>;
  lead_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedOneWithoutInboundConciergeEventInput>;
  organization: OrganizationCreateNestedOneWithoutInboundConciergeEventsInput;
  ownedRepOnCall?: InputMaybe<Scalars['Boolean']['input']>;
  routing_history?: InputMaybe<RoutingHistoryCreateNestedOneWithoutInboundConciergeEventInput>;
  type?: InputMaybe<InboundConciergeEventType>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type InboundConciergeEventCreateWithoutLeadInput = {
  callMeNow?: InputMaybe<CallMeNowCreateNestedOneWithoutInboundConciergeEventInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  existingLead?: InputMaybe<Scalars['Boolean']['input']>;
  first_dial_time?: InputMaybe<Scalars['DateTime']['input']>;
  form_data: Scalars['Json']['input'];
  has_been_routed?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  lead_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedOneWithoutInboundConciergeEventInput>;
  leadActivity?: InputMaybe<LeadActivityCreateNestedOneWithoutInboundConciergeEventsInput>;
  organization: OrganizationCreateNestedOneWithoutInboundConciergeEventsInput;
  ownedRepOnCall?: InputMaybe<Scalars['Boolean']['input']>;
  routing_history?: InputMaybe<RoutingHistoryCreateNestedOneWithoutInboundConciergeEventInput>;
  type?: InputMaybe<InboundConciergeEventType>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type InboundConciergeEventCreateWithoutOrganizationInput = {
  callMeNow?: InputMaybe<CallMeNowCreateNestedOneWithoutInboundConciergeEventInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  existingLead?: InputMaybe<Scalars['Boolean']['input']>;
  first_dial_time?: InputMaybe<Scalars['DateTime']['input']>;
  form_data: Scalars['Json']['input'];
  has_been_routed?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  lead?: InputMaybe<LeadCreateNestedOneWithoutInboundConciergeEventsInput>;
  lead_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedOneWithoutInboundConciergeEventInput>;
  leadActivity?: InputMaybe<LeadActivityCreateNestedOneWithoutInboundConciergeEventsInput>;
  ownedRepOnCall?: InputMaybe<Scalars['Boolean']['input']>;
  routing_history?: InputMaybe<RoutingHistoryCreateNestedOneWithoutInboundConciergeEventInput>;
  type?: InputMaybe<InboundConciergeEventType>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type InboundConciergeEventCreateWithoutRouting_HistoryInput = {
  callMeNow?: InputMaybe<CallMeNowCreateNestedOneWithoutInboundConciergeEventInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  existingLead?: InputMaybe<Scalars['Boolean']['input']>;
  first_dial_time?: InputMaybe<Scalars['DateTime']['input']>;
  form_data: Scalars['Json']['input'];
  has_been_routed?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  lead?: InputMaybe<LeadCreateNestedOneWithoutInboundConciergeEventsInput>;
  lead_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedOneWithoutInboundConciergeEventInput>;
  leadActivity?: InputMaybe<LeadActivityCreateNestedOneWithoutInboundConciergeEventsInput>;
  organization: OrganizationCreateNestedOneWithoutInboundConciergeEventsInput;
  ownedRepOnCall?: InputMaybe<Scalars['Boolean']['input']>;
  type?: InputMaybe<InboundConciergeEventType>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type InboundConciergeEventListRelationFilter = {
  every?: InputMaybe<InboundConciergeEventWhereInput>;
  none?: InputMaybe<InboundConciergeEventWhereInput>;
  some?: InputMaybe<InboundConciergeEventWhereInput>;
};

export type InboundConciergeEventOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
  count?: InputMaybe<SortOrder>;
};

export type InboundConciergeEventOrderByWithRelationInput = {
  callMeNow?: InputMaybe<CallMeNowOrderByWithRelationInput>;
  created_at?: InputMaybe<SortOrder>;
  existingLead?: InputMaybe<SortOrder>;
  first_dial_time?: InputMaybe<SortOrder>;
  form_data?: InputMaybe<SortOrder>;
  has_been_routed?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  lead?: InputMaybe<LeadOrderByWithRelationInput>;
  lead_activity_id?: InputMaybe<SortOrder>;
  lead_assignment_history?: InputMaybe<LeadAssignmentHistoryOrderByWithRelationInput>;
  lead_assignment_history_id?: InputMaybe<SortOrder>;
  lead_id?: InputMaybe<SortOrder>;
  leadActivity?: InputMaybe<LeadActivityOrderByWithRelationInput>;
  organization?: InputMaybe<OrganizationOrderByWithRelationInput>;
  organization_id?: InputMaybe<SortOrder>;
  ownedRepOnCall?: InputMaybe<SortOrder>;
  routing_history?: InputMaybe<RoutingHistoryOrderByWithRelationInput>;
  routing_history_id?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
};

export type InboundConciergeEventScalarWhereInput = {
  AND?: InputMaybe<Array<InboundConciergeEventScalarWhereInput>>;
  created_at?: InputMaybe<DateTimeFilter>;
  existingLead?: InputMaybe<BoolFilter>;
  first_dial_time?: InputMaybe<DateTimeNullableFilter>;
  has_been_routed?: InputMaybe<BoolFilter>;
  id?: InputMaybe<StringFilter>;
  lead_activity_id?: InputMaybe<StringNullableFilter>;
  lead_assignment_history_id?: InputMaybe<StringNullableFilter>;
  lead_id?: InputMaybe<StringNullableFilter>;
  NOT?: InputMaybe<Array<InboundConciergeEventScalarWhereInput>>;
  OR?: InputMaybe<Array<InboundConciergeEventScalarWhereInput>>;
  organization_id?: InputMaybe<StringFilter>;
  ownedRepOnCall?: InputMaybe<BoolFilter>;
  routing_history_id?: InputMaybe<StringNullableFilter>;
  type?: InputMaybe<EnumInboundConciergeEventTypeFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
};

export enum InboundConciergeEventType {
  Call = 'Call',
  Error = 'Error',
  Schedule = 'Schedule',
  ScheduleAfterCall = 'ScheduleAfterCall',
  Unknown = 'Unknown'
}

export type InboundConciergeEventUpdateManyMutationInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  existingLead?: InputMaybe<BoolFieldUpdateOperationsInput>;
  first_dial_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  form_data?: InputMaybe<Scalars['Json']['input']>;
  has_been_routed?: InputMaybe<BoolFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  ownedRepOnCall?: InputMaybe<BoolFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumInboundConciergeEventTypeFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type InboundConciergeEventUpdateManyWithoutLeadActivityInput = {
  connect?: InputMaybe<Array<InboundConciergeEventWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<InboundConciergeEventCreateOrConnectWithoutLeadActivityInput>>;
  create?: InputMaybe<Array<InboundConciergeEventCreateWithoutLeadActivityInput>>;
  createMany?: InputMaybe<InboundConciergeEventCreateManyLeadActivityInputEnvelope>;
  delete?: InputMaybe<Array<InboundConciergeEventWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<InboundConciergeEventScalarWhereInput>>;
  disconnect?: InputMaybe<Array<InboundConciergeEventWhereUniqueInput>>;
  set?: InputMaybe<Array<InboundConciergeEventWhereUniqueInput>>;
  update?: InputMaybe<Array<InboundConciergeEventUpdateWithWhereUniqueWithoutLeadActivityInput>>;
  updateMany?: InputMaybe<Array<InboundConciergeEventUpdateManyWithWhereWithoutLeadActivityInput>>;
  upsert?: InputMaybe<Array<InboundConciergeEventUpsertWithWhereUniqueWithoutLeadActivityInput>>;
};

export type InboundConciergeEventUpdateManyWithoutLeadInput = {
  connect?: InputMaybe<Array<InboundConciergeEventWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<InboundConciergeEventCreateOrConnectWithoutLeadInput>>;
  create?: InputMaybe<Array<InboundConciergeEventCreateWithoutLeadInput>>;
  createMany?: InputMaybe<InboundConciergeEventCreateManyLeadInputEnvelope>;
  delete?: InputMaybe<Array<InboundConciergeEventWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<InboundConciergeEventScalarWhereInput>>;
  disconnect?: InputMaybe<Array<InboundConciergeEventWhereUniqueInput>>;
  set?: InputMaybe<Array<InboundConciergeEventWhereUniqueInput>>;
  update?: InputMaybe<Array<InboundConciergeEventUpdateWithWhereUniqueWithoutLeadInput>>;
  updateMany?: InputMaybe<Array<InboundConciergeEventUpdateManyWithWhereWithoutLeadInput>>;
  upsert?: InputMaybe<Array<InboundConciergeEventUpsertWithWhereUniqueWithoutLeadInput>>;
};

export type InboundConciergeEventUpdateManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<InboundConciergeEventWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<InboundConciergeEventCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<InboundConciergeEventCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<InboundConciergeEventCreateManyOrganizationInputEnvelope>;
  delete?: InputMaybe<Array<InboundConciergeEventWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<InboundConciergeEventScalarWhereInput>>;
  disconnect?: InputMaybe<Array<InboundConciergeEventWhereUniqueInput>>;
  set?: InputMaybe<Array<InboundConciergeEventWhereUniqueInput>>;
  update?: InputMaybe<Array<InboundConciergeEventUpdateWithWhereUniqueWithoutOrganizationInput>>;
  updateMany?: InputMaybe<Array<InboundConciergeEventUpdateManyWithWhereWithoutOrganizationInput>>;
  upsert?: InputMaybe<Array<InboundConciergeEventUpsertWithWhereUniqueWithoutOrganizationInput>>;
};

export type InboundConciergeEventUpdateManyWithWhereWithoutLeadActivityInput = {
  data: InboundConciergeEventUpdateManyMutationInput;
  where: InboundConciergeEventScalarWhereInput;
};

export type InboundConciergeEventUpdateManyWithWhereWithoutLeadInput = {
  data: InboundConciergeEventUpdateManyMutationInput;
  where: InboundConciergeEventScalarWhereInput;
};

export type InboundConciergeEventUpdateManyWithWhereWithoutOrganizationInput = {
  data: InboundConciergeEventUpdateManyMutationInput;
  where: InboundConciergeEventScalarWhereInput;
};

export type InboundConciergeEventUpdateOneRequiredWithoutCallMeNowInput = {
  connect?: InputMaybe<InboundConciergeEventWhereUniqueInput>;
  connectOrCreate?: InputMaybe<InboundConciergeEventCreateOrConnectWithoutCallMeNowInput>;
  create?: InputMaybe<InboundConciergeEventCreateWithoutCallMeNowInput>;
  update?: InputMaybe<InboundConciergeEventUpdateWithoutCallMeNowInput>;
  upsert?: InputMaybe<InboundConciergeEventUpsertWithoutCallMeNowInput>;
};

export type InboundConciergeEventUpdateOneWithoutLead_Assignment_HistoryInput = {
  connect?: InputMaybe<InboundConciergeEventWhereUniqueInput>;
  connectOrCreate?: InputMaybe<InboundConciergeEventCreateOrConnectWithoutLead_Assignment_HistoryInput>;
  create?: InputMaybe<InboundConciergeEventCreateWithoutLead_Assignment_HistoryInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<InboundConciergeEventUpdateWithoutLead_Assignment_HistoryInput>;
  upsert?: InputMaybe<InboundConciergeEventUpsertWithoutLead_Assignment_HistoryInput>;
};

export type InboundConciergeEventUpdateOneWithoutRouting_HistoryInput = {
  connect?: InputMaybe<InboundConciergeEventWhereUniqueInput>;
  connectOrCreate?: InputMaybe<InboundConciergeEventCreateOrConnectWithoutRouting_HistoryInput>;
  create?: InputMaybe<InboundConciergeEventCreateWithoutRouting_HistoryInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<InboundConciergeEventUpdateWithoutRouting_HistoryInput>;
  upsert?: InputMaybe<InboundConciergeEventUpsertWithoutRouting_HistoryInput>;
};

export type InboundConciergeEventUpdateWithoutCallMeNowInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  existingLead?: InputMaybe<BoolFieldUpdateOperationsInput>;
  first_dial_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  form_data?: InputMaybe<Scalars['Json']['input']>;
  has_been_routed?: InputMaybe<BoolFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lead?: InputMaybe<LeadUpdateOneWithoutInboundConciergeEventsInput>;
  lead_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateOneWithoutInboundConciergeEventInput>;
  leadActivity?: InputMaybe<LeadActivityUpdateOneWithoutInboundConciergeEventsInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutInboundConciergeEventsInput>;
  ownedRepOnCall?: InputMaybe<BoolFieldUpdateOperationsInput>;
  routing_history?: InputMaybe<RoutingHistoryUpdateOneWithoutInboundConciergeEventInput>;
  type?: InputMaybe<EnumInboundConciergeEventTypeFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type InboundConciergeEventUpdateWithoutLead_Assignment_HistoryInput = {
  callMeNow?: InputMaybe<CallMeNowUpdateOneWithoutInboundConciergeEventInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  existingLead?: InputMaybe<BoolFieldUpdateOperationsInput>;
  first_dial_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  form_data?: InputMaybe<Scalars['Json']['input']>;
  has_been_routed?: InputMaybe<BoolFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lead?: InputMaybe<LeadUpdateOneWithoutInboundConciergeEventsInput>;
  leadActivity?: InputMaybe<LeadActivityUpdateOneWithoutInboundConciergeEventsInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutInboundConciergeEventsInput>;
  ownedRepOnCall?: InputMaybe<BoolFieldUpdateOperationsInput>;
  routing_history?: InputMaybe<RoutingHistoryUpdateOneWithoutInboundConciergeEventInput>;
  type?: InputMaybe<EnumInboundConciergeEventTypeFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type InboundConciergeEventUpdateWithoutLeadActivityInput = {
  callMeNow?: InputMaybe<CallMeNowUpdateOneWithoutInboundConciergeEventInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  existingLead?: InputMaybe<BoolFieldUpdateOperationsInput>;
  first_dial_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  form_data?: InputMaybe<Scalars['Json']['input']>;
  has_been_routed?: InputMaybe<BoolFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lead?: InputMaybe<LeadUpdateOneWithoutInboundConciergeEventsInput>;
  lead_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateOneWithoutInboundConciergeEventInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutInboundConciergeEventsInput>;
  ownedRepOnCall?: InputMaybe<BoolFieldUpdateOperationsInput>;
  routing_history?: InputMaybe<RoutingHistoryUpdateOneWithoutInboundConciergeEventInput>;
  type?: InputMaybe<EnumInboundConciergeEventTypeFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type InboundConciergeEventUpdateWithoutLeadInput = {
  callMeNow?: InputMaybe<CallMeNowUpdateOneWithoutInboundConciergeEventInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  existingLead?: InputMaybe<BoolFieldUpdateOperationsInput>;
  first_dial_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  form_data?: InputMaybe<Scalars['Json']['input']>;
  has_been_routed?: InputMaybe<BoolFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lead_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateOneWithoutInboundConciergeEventInput>;
  leadActivity?: InputMaybe<LeadActivityUpdateOneWithoutInboundConciergeEventsInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutInboundConciergeEventsInput>;
  ownedRepOnCall?: InputMaybe<BoolFieldUpdateOperationsInput>;
  routing_history?: InputMaybe<RoutingHistoryUpdateOneWithoutInboundConciergeEventInput>;
  type?: InputMaybe<EnumInboundConciergeEventTypeFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type InboundConciergeEventUpdateWithoutOrganizationInput = {
  callMeNow?: InputMaybe<CallMeNowUpdateOneWithoutInboundConciergeEventInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  existingLead?: InputMaybe<BoolFieldUpdateOperationsInput>;
  first_dial_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  form_data?: InputMaybe<Scalars['Json']['input']>;
  has_been_routed?: InputMaybe<BoolFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lead?: InputMaybe<LeadUpdateOneWithoutInboundConciergeEventsInput>;
  lead_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateOneWithoutInboundConciergeEventInput>;
  leadActivity?: InputMaybe<LeadActivityUpdateOneWithoutInboundConciergeEventsInput>;
  ownedRepOnCall?: InputMaybe<BoolFieldUpdateOperationsInput>;
  routing_history?: InputMaybe<RoutingHistoryUpdateOneWithoutInboundConciergeEventInput>;
  type?: InputMaybe<EnumInboundConciergeEventTypeFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type InboundConciergeEventUpdateWithoutRouting_HistoryInput = {
  callMeNow?: InputMaybe<CallMeNowUpdateOneWithoutInboundConciergeEventInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  existingLead?: InputMaybe<BoolFieldUpdateOperationsInput>;
  first_dial_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  form_data?: InputMaybe<Scalars['Json']['input']>;
  has_been_routed?: InputMaybe<BoolFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lead?: InputMaybe<LeadUpdateOneWithoutInboundConciergeEventsInput>;
  lead_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateOneWithoutInboundConciergeEventInput>;
  leadActivity?: InputMaybe<LeadActivityUpdateOneWithoutInboundConciergeEventsInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutInboundConciergeEventsInput>;
  ownedRepOnCall?: InputMaybe<BoolFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumInboundConciergeEventTypeFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type InboundConciergeEventUpdateWithWhereUniqueWithoutLeadActivityInput = {
  data: InboundConciergeEventUpdateWithoutLeadActivityInput;
  where: InboundConciergeEventWhereUniqueInput;
};

export type InboundConciergeEventUpdateWithWhereUniqueWithoutLeadInput = {
  data: InboundConciergeEventUpdateWithoutLeadInput;
  where: InboundConciergeEventWhereUniqueInput;
};

export type InboundConciergeEventUpdateWithWhereUniqueWithoutOrganizationInput = {
  data: InboundConciergeEventUpdateWithoutOrganizationInput;
  where: InboundConciergeEventWhereUniqueInput;
};

export type InboundConciergeEventUpsertWithoutCallMeNowInput = {
  create: InboundConciergeEventCreateWithoutCallMeNowInput;
  update: InboundConciergeEventUpdateWithoutCallMeNowInput;
};

export type InboundConciergeEventUpsertWithoutLead_Assignment_HistoryInput = {
  create: InboundConciergeEventCreateWithoutLead_Assignment_HistoryInput;
  update: InboundConciergeEventUpdateWithoutLead_Assignment_HistoryInput;
};

export type InboundConciergeEventUpsertWithoutRouting_HistoryInput = {
  create: InboundConciergeEventCreateWithoutRouting_HistoryInput;
  update: InboundConciergeEventUpdateWithoutRouting_HistoryInput;
};

export type InboundConciergeEventUpsertWithWhereUniqueWithoutLeadActivityInput = {
  create: InboundConciergeEventCreateWithoutLeadActivityInput;
  update: InboundConciergeEventUpdateWithoutLeadActivityInput;
  where: InboundConciergeEventWhereUniqueInput;
};

export type InboundConciergeEventUpsertWithWhereUniqueWithoutLeadInput = {
  create: InboundConciergeEventCreateWithoutLeadInput;
  update: InboundConciergeEventUpdateWithoutLeadInput;
  where: InboundConciergeEventWhereUniqueInput;
};

export type InboundConciergeEventUpsertWithWhereUniqueWithoutOrganizationInput = {
  create: InboundConciergeEventCreateWithoutOrganizationInput;
  update: InboundConciergeEventUpdateWithoutOrganizationInput;
  where: InboundConciergeEventWhereUniqueInput;
};

export type InboundConciergeEventWhereInput = {
  AND?: InputMaybe<Array<InboundConciergeEventWhereInput>>;
  callMeNow?: InputMaybe<CallMeNowWhereInput>;
  created_at?: InputMaybe<DateTimeFilter>;
  existingLead?: InputMaybe<BoolFilter>;
  first_dial_time?: InputMaybe<DateTimeNullableFilter>;
  has_been_routed?: InputMaybe<BoolFilter>;
  id?: InputMaybe<StringFilter>;
  lead?: InputMaybe<LeadWhereInput>;
  lead_activity_id?: InputMaybe<StringNullableFilter>;
  lead_assignment_history?: InputMaybe<LeadAssignmentHistoryWhereInput>;
  lead_assignment_history_id?: InputMaybe<StringNullableFilter>;
  lead_id?: InputMaybe<StringNullableFilter>;
  leadActivity?: InputMaybe<LeadActivityWhereInput>;
  NOT?: InputMaybe<Array<InboundConciergeEventWhereInput>>;
  OR?: InputMaybe<Array<InboundConciergeEventWhereInput>>;
  organization?: InputMaybe<OrganizationWhereInput>;
  organization_id?: InputMaybe<StringFilter>;
  ownedRepOnCall?: InputMaybe<BoolFilter>;
  routing_history?: InputMaybe<RoutingHistoryWhereInput>;
  routing_history_id?: InputMaybe<StringNullableFilter>;
  type?: InputMaybe<EnumInboundConciergeEventTypeFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
};

export type InboundConciergeEventWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  lead_assignment_history_id?: InputMaybe<Scalars['String']['input']>;
  routing_history_id?: InputMaybe<Scalars['String']['input']>;
};

export type InboundConciergeWidget = {
  __typename?: 'InboundConciergeWidget';
  action: ScheduleItemType;
  /** Return the action options to be used in the widget */
  actionOptions?: Maybe<Scalars['Json']['output']>;
  brand_name?: Maybe<Scalars['String']['output']>;
  button_color?: Maybe<Scalars['String']['output']>;
  button_text_color?: Maybe<Scalars['String']['output']>;
  created_at: Scalars['DateTime']['output'];
  custom_existing_lead_response_time: Scalars['Boolean']['output'];
  existing_lead_response_time?: Maybe<Scalars['Int']['output']>;
  font?: Maybe<Scalars['String']['output']>;
  header_background_color?: Maybe<Scalars['String']['output']>;
  header_text_color?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  organization: Organization;
  organization_id: Scalars['String']['output'];
  overflow_reps_on_existing_lead: Scalars['Boolean']['output'];
  representative_response_time?: Maybe<Scalars['Int']['output']>;
  session_token?: Maybe<Scalars['String']['output']>;
  show_leads_custom_time_options?: Maybe<Scalars['Boolean']['output']>;
  updated_at: Scalars['DateTime']['output'];
};

export type InboundConciergeWidgetCreateManyOrganizationInput = {
  action?: InputMaybe<ScheduleItemType>;
  brand_name?: InputMaybe<Scalars['String']['input']>;
  button_color?: InputMaybe<Scalars['String']['input']>;
  button_text_color?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  custom_existing_lead_response_time?: InputMaybe<Scalars['Boolean']['input']>;
  deleted_at?: InputMaybe<Scalars['DateTime']['input']>;
  existing_lead_response_time?: InputMaybe<Scalars['Int']['input']>;
  font?: InputMaybe<Scalars['String']['input']>;
  header_background_color?: InputMaybe<Scalars['String']['input']>;
  header_text_color?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  overflow_reps_on_existing_lead?: InputMaybe<Scalars['Boolean']['input']>;
  representative_response_time?: InputMaybe<Scalars['Int']['input']>;
  session_token?: InputMaybe<Scalars['String']['input']>;
  show_leads_custom_time_options?: InputMaybe<Scalars['Boolean']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type InboundConciergeWidgetCreateManyOrganizationInputEnvelope = {
  data?: InputMaybe<Array<InboundConciergeWidgetCreateManyOrganizationInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type InboundConciergeWidgetCreateNestedManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<InboundConciergeWidgetWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<InboundConciergeWidgetCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<InboundConciergeWidgetCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<InboundConciergeWidgetCreateManyOrganizationInputEnvelope>;
};

export type InboundConciergeWidgetCreateOrConnectWithoutOrganizationInput = {
  create: InboundConciergeWidgetCreateWithoutOrganizationInput;
  where: InboundConciergeWidgetWhereUniqueInput;
};

export type InboundConciergeWidgetCreateWithoutOrganizationInput = {
  action?: InputMaybe<ScheduleItemType>;
  brand_name?: InputMaybe<Scalars['String']['input']>;
  button_color?: InputMaybe<Scalars['String']['input']>;
  button_text_color?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  custom_existing_lead_response_time?: InputMaybe<Scalars['Boolean']['input']>;
  deleted_at?: InputMaybe<Scalars['DateTime']['input']>;
  existing_lead_response_time?: InputMaybe<Scalars['Int']['input']>;
  font?: InputMaybe<Scalars['String']['input']>;
  header_background_color?: InputMaybe<Scalars['String']['input']>;
  header_text_color?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  overflow_reps_on_existing_lead?: InputMaybe<Scalars['Boolean']['input']>;
  representative_response_time?: InputMaybe<Scalars['Int']['input']>;
  session_token?: InputMaybe<Scalars['String']['input']>;
  show_leads_custom_time_options?: InputMaybe<Scalars['Boolean']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type InboundConciergeWidgetListRelationFilter = {
  every?: InputMaybe<InboundConciergeWidgetWhereInput>;
  none?: InputMaybe<InboundConciergeWidgetWhereInput>;
  some?: InputMaybe<InboundConciergeWidgetWhereInput>;
};

export type InboundConciergeWidgetOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
  count?: InputMaybe<SortOrder>;
};

export type InboundConciergeWidgetScalarWhereInput = {
  action?: InputMaybe<EnumScheduleItemTypeFilter>;
  AND?: InputMaybe<Array<InboundConciergeWidgetScalarWhereInput>>;
  brand_name?: InputMaybe<StringNullableFilter>;
  button_color?: InputMaybe<StringNullableFilter>;
  button_text_color?: InputMaybe<StringNullableFilter>;
  created_at?: InputMaybe<DateTimeFilter>;
  custom_existing_lead_response_time?: InputMaybe<BoolFilter>;
  deleted_at?: InputMaybe<DateTimeNullableFilter>;
  existing_lead_response_time?: InputMaybe<IntNullableFilter>;
  font?: InputMaybe<StringNullableFilter>;
  header_background_color?: InputMaybe<StringNullableFilter>;
  header_text_color?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  NOT?: InputMaybe<Array<InboundConciergeWidgetScalarWhereInput>>;
  OR?: InputMaybe<Array<InboundConciergeWidgetScalarWhereInput>>;
  organization_id?: InputMaybe<StringFilter>;
  overflow_reps_on_existing_lead?: InputMaybe<BoolFilter>;
  representative_response_time?: InputMaybe<IntNullableFilter>;
  session_token?: InputMaybe<StringNullableFilter>;
  show_leads_custom_time_options?: InputMaybe<BoolNullableFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
};

export type InboundConciergeWidgetUpdateManyMutationInput = {
  action?: InputMaybe<EnumScheduleItemTypeFieldUpdateOperationsInput>;
  brand_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  button_color?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  button_text_color?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  custom_existing_lead_response_time?: InputMaybe<BoolFieldUpdateOperationsInput>;
  deleted_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  existing_lead_response_time?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  font?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  header_background_color?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  header_text_color?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  overflow_reps_on_existing_lead?: InputMaybe<BoolFieldUpdateOperationsInput>;
  representative_response_time?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  session_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  show_leads_custom_time_options?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type InboundConciergeWidgetUpdateManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<InboundConciergeWidgetWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<InboundConciergeWidgetCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<InboundConciergeWidgetCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<InboundConciergeWidgetCreateManyOrganizationInputEnvelope>;
  delete?: InputMaybe<Array<InboundConciergeWidgetWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<InboundConciergeWidgetScalarWhereInput>>;
  disconnect?: InputMaybe<Array<InboundConciergeWidgetWhereUniqueInput>>;
  set?: InputMaybe<Array<InboundConciergeWidgetWhereUniqueInput>>;
  update?: InputMaybe<Array<InboundConciergeWidgetUpdateWithWhereUniqueWithoutOrganizationInput>>;
  updateMany?: InputMaybe<Array<InboundConciergeWidgetUpdateManyWithWhereWithoutOrganizationInput>>;
  upsert?: InputMaybe<Array<InboundConciergeWidgetUpsertWithWhereUniqueWithoutOrganizationInput>>;
};

export type InboundConciergeWidgetUpdateManyWithWhereWithoutOrganizationInput = {
  data: InboundConciergeWidgetUpdateManyMutationInput;
  where: InboundConciergeWidgetScalarWhereInput;
};

export type InboundConciergeWidgetUpdateWithoutOrganizationInput = {
  action?: InputMaybe<EnumScheduleItemTypeFieldUpdateOperationsInput>;
  brand_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  button_color?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  button_text_color?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  custom_existing_lead_response_time?: InputMaybe<BoolFieldUpdateOperationsInput>;
  deleted_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  existing_lead_response_time?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  font?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  header_background_color?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  header_text_color?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  overflow_reps_on_existing_lead?: InputMaybe<BoolFieldUpdateOperationsInput>;
  representative_response_time?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  session_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  show_leads_custom_time_options?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type InboundConciergeWidgetUpdateWithWhereUniqueWithoutOrganizationInput = {
  data: InboundConciergeWidgetUpdateWithoutOrganizationInput;
  where: InboundConciergeWidgetWhereUniqueInput;
};

export type InboundConciergeWidgetUpsertWithWhereUniqueWithoutOrganizationInput = {
  create: InboundConciergeWidgetCreateWithoutOrganizationInput;
  update: InboundConciergeWidgetUpdateWithoutOrganizationInput;
  where: InboundConciergeWidgetWhereUniqueInput;
};

export type InboundConciergeWidgetWhereInput = {
  action?: InputMaybe<EnumScheduleItemTypeFilter>;
  AND?: InputMaybe<Array<InboundConciergeWidgetWhereInput>>;
  brand_name?: InputMaybe<StringNullableFilter>;
  button_color?: InputMaybe<StringNullableFilter>;
  button_text_color?: InputMaybe<StringNullableFilter>;
  created_at?: InputMaybe<DateTimeFilter>;
  custom_existing_lead_response_time?: InputMaybe<BoolFilter>;
  deleted_at?: InputMaybe<DateTimeNullableFilter>;
  existing_lead_response_time?: InputMaybe<IntNullableFilter>;
  font?: InputMaybe<StringNullableFilter>;
  header_background_color?: InputMaybe<StringNullableFilter>;
  header_text_color?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  NOT?: InputMaybe<Array<InboundConciergeWidgetWhereInput>>;
  OR?: InputMaybe<Array<InboundConciergeWidgetWhereInput>>;
  organization?: InputMaybe<OrganizationWhereInput>;
  organization_id?: InputMaybe<StringFilter>;
  overflow_reps_on_existing_lead?: InputMaybe<BoolFilter>;
  representative_response_time?: InputMaybe<IntNullableFilter>;
  session_token?: InputMaybe<StringNullableFilter>;
  show_leads_custom_time_options?: InputMaybe<BoolNullableFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
};

export type InboundConciergeWidgetWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']['input']>;
};

/** User Batch Edit Input */
export type IndustryBatchEditInput = {
  assign?: InputMaybe<AssignType>;
  industry_white_list_array?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type IndustryOption = {
  __typename?: 'IndustryOption';
  created_at: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  label: Scalars['String']['output'];
  organization: Organization;
  organization_id: Scalars['String']['output'];
  sub_industries: Array<Scalars['String']['output']>;
  updated_at: Scalars['DateTime']['output'];
};

export type IndustryOptionCreateManyOrganizationInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  label: Scalars['String']['input'];
  sub_industries?: InputMaybe<IndustryOptionCreateManysub_IndustriesInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type IndustryOptionCreateManyOrganizationInputEnvelope = {
  data?: InputMaybe<Array<IndustryOptionCreateManyOrganizationInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type IndustryOptionCreateManysub_IndustriesInput = {
  set?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type IndustryOptionCreateNestedManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<IndustryOptionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<IndustryOptionCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<IndustryOptionCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<IndustryOptionCreateManyOrganizationInputEnvelope>;
};

export type IndustryOptionCreateOrConnectWithoutOrganizationInput = {
  create: IndustryOptionCreateWithoutOrganizationInput;
  where: IndustryOptionWhereUniqueInput;
};

export type IndustryOptionCreatesub_IndustriesInput = {
  set?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type IndustryOptionCreateWithoutOrganizationInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  label: Scalars['String']['input'];
  sub_industries?: InputMaybe<IndustryOptionCreatesub_IndustriesInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type IndustryOptionListRelationFilter = {
  every?: InputMaybe<IndustryOptionWhereInput>;
  none?: InputMaybe<IndustryOptionWhereInput>;
  some?: InputMaybe<IndustryOptionWhereInput>;
};

export type IndustryOptionOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
  count?: InputMaybe<SortOrder>;
};

export type IndustryOptionScalarWhereInput = {
  AND?: InputMaybe<Array<IndustryOptionScalarWhereInput>>;
  created_at?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  label?: InputMaybe<StringFilter>;
  NOT?: InputMaybe<Array<IndustryOptionScalarWhereInput>>;
  OR?: InputMaybe<Array<IndustryOptionScalarWhereInput>>;
  organization_id?: InputMaybe<StringFilter>;
  sub_industries?: InputMaybe<StringNullableListFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
};

export type IndustryOptionUpdateManyMutationInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  label?: InputMaybe<StringFieldUpdateOperationsInput>;
  sub_industries?: InputMaybe<IndustryOptionUpdatesub_IndustriesInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type IndustryOptionUpdateManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<IndustryOptionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<IndustryOptionCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<IndustryOptionCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<IndustryOptionCreateManyOrganizationInputEnvelope>;
  delete?: InputMaybe<Array<IndustryOptionWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<IndustryOptionScalarWhereInput>>;
  disconnect?: InputMaybe<Array<IndustryOptionWhereUniqueInput>>;
  set?: InputMaybe<Array<IndustryOptionWhereUniqueInput>>;
  update?: InputMaybe<Array<IndustryOptionUpdateWithWhereUniqueWithoutOrganizationInput>>;
  updateMany?: InputMaybe<Array<IndustryOptionUpdateManyWithWhereWithoutOrganizationInput>>;
  upsert?: InputMaybe<Array<IndustryOptionUpsertWithWhereUniqueWithoutOrganizationInput>>;
};

export type IndustryOptionUpdateManyWithWhereWithoutOrganizationInput = {
  data: IndustryOptionUpdateManyMutationInput;
  where: IndustryOptionScalarWhereInput;
};

export type IndustryOptionUpdatesub_IndustriesInput = {
  push?: InputMaybe<Scalars['String']['input']>;
  set?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type IndustryOptionUpdateWithoutOrganizationInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  label?: InputMaybe<StringFieldUpdateOperationsInput>;
  sub_industries?: InputMaybe<IndustryOptionUpdatesub_IndustriesInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type IndustryOptionUpdateWithWhereUniqueWithoutOrganizationInput = {
  data: IndustryOptionUpdateWithoutOrganizationInput;
  where: IndustryOptionWhereUniqueInput;
};

export type IndustryOptionUpsertWithWhereUniqueWithoutOrganizationInput = {
  create: IndustryOptionCreateWithoutOrganizationInput;
  update: IndustryOptionUpdateWithoutOrganizationInput;
  where: IndustryOptionWhereUniqueInput;
};

export type IndustryOptionWhereInput = {
  AND?: InputMaybe<Array<IndustryOptionWhereInput>>;
  created_at?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  label?: InputMaybe<StringFilter>;
  NOT?: InputMaybe<Array<IndustryOptionWhereInput>>;
  OR?: InputMaybe<Array<IndustryOptionWhereInput>>;
  organization?: InputMaybe<OrganizationWhereInput>;
  organization_id?: InputMaybe<StringFilter>;
  sub_industries?: InputMaybe<StringNullableListFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
};

export type IndustryOptionWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']['input']>;
};

export enum Integration_Type {
  HubSpot = 'HubSpot',
  PandaDoc = 'PandaDoc',
  SalesForce = 'SalesForce'
}

export enum IntegrationErrorEntityType {
  CustomObject = 'CustomObject',
  HubSpotContact = 'HubSpotContact',
  Lead = 'Lead',
  LeadActivity = 'LeadActivity',
  Sale = 'Sale',
  ScheduleItem = 'ScheduleItem',
  Unknown = 'Unknown'
}

export type IntegrationErrorLog = {
  __typename?: 'IntegrationErrorLog';
  created_at: Scalars['DateTime']['output'];
  error_message: Scalars['String']['output'];
  id: Scalars['String']['output'];
  integration_operation_log_id?: Maybe<Scalars['String']['output']>;
  integration_type: Integration_Type;
  IntegrationOperationLog?: Maybe<IntegrationOperationLog>;
  organization: Organization;
  result_type: ResultType;
};

export type IntegrationErrorLogCreateManyIntegrationOperationLogInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  error_message: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  info?: InputMaybe<Scalars['Json']['input']>;
  integration_type: Integration_Type;
  organization_id: Scalars['String']['input'];
  result_type?: InputMaybe<ResultType>;
};

export type IntegrationErrorLogCreateManyIntegrationOperationLogInputEnvelope = {
  data?: InputMaybe<Array<IntegrationErrorLogCreateManyIntegrationOperationLogInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type IntegrationErrorLogCreateManyOrganizationInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  error_message: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  info?: InputMaybe<Scalars['Json']['input']>;
  integration_operation_log_id?: InputMaybe<Scalars['String']['input']>;
  integration_type: Integration_Type;
  result_type?: InputMaybe<ResultType>;
};

export type IntegrationErrorLogCreateManyOrganizationInputEnvelope = {
  data?: InputMaybe<Array<IntegrationErrorLogCreateManyOrganizationInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type IntegrationErrorLogCreateNestedManyWithoutIntegrationOperationLogInput = {
  connect?: InputMaybe<Array<IntegrationErrorLogWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<IntegrationErrorLogCreateOrConnectWithoutIntegrationOperationLogInput>>;
  create?: InputMaybe<Array<IntegrationErrorLogCreateWithoutIntegrationOperationLogInput>>;
  createMany?: InputMaybe<IntegrationErrorLogCreateManyIntegrationOperationLogInputEnvelope>;
};

export type IntegrationErrorLogCreateNestedManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<IntegrationErrorLogWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<IntegrationErrorLogCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<IntegrationErrorLogCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<IntegrationErrorLogCreateManyOrganizationInputEnvelope>;
};

export type IntegrationErrorLogCreateOrConnectWithoutIntegrationOperationLogInput = {
  create: IntegrationErrorLogCreateWithoutIntegrationOperationLogInput;
  where: IntegrationErrorLogWhereUniqueInput;
};

export type IntegrationErrorLogCreateOrConnectWithoutOrganizationInput = {
  create: IntegrationErrorLogCreateWithoutOrganizationInput;
  where: IntegrationErrorLogWhereUniqueInput;
};

export type IntegrationErrorLogCreateWithoutIntegrationOperationLogInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  error_message: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  info?: InputMaybe<Scalars['Json']['input']>;
  integration_type: Integration_Type;
  organization: OrganizationCreateNestedOneWithoutIntegration_Error_LogsInput;
  result_type?: InputMaybe<ResultType>;
};

export type IntegrationErrorLogCreateWithoutOrganizationInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  error_message: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  info?: InputMaybe<Scalars['Json']['input']>;
  integration_type: Integration_Type;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogCreateNestedOneWithoutMessagesInput>;
  result_type?: InputMaybe<ResultType>;
};

export type IntegrationErrorLogListRelationFilter = {
  every?: InputMaybe<IntegrationErrorLogWhereInput>;
  none?: InputMaybe<IntegrationErrorLogWhereInput>;
  some?: InputMaybe<IntegrationErrorLogWhereInput>;
};

export type IntegrationErrorLogOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
  count?: InputMaybe<SortOrder>;
};

export type IntegrationErrorLogScalarWhereInput = {
  AND?: InputMaybe<Array<IntegrationErrorLogScalarWhereInput>>;
  created_at?: InputMaybe<DateTimeFilter>;
  error_message?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  info?: InputMaybe<JsonNullableFilter>;
  integration_operation_log_id?: InputMaybe<StringNullableFilter>;
  integration_type?: InputMaybe<EnumIntegration_TypeFilter>;
  NOT?: InputMaybe<Array<IntegrationErrorLogScalarWhereInput>>;
  OR?: InputMaybe<Array<IntegrationErrorLogScalarWhereInput>>;
  organization_id?: InputMaybe<StringFilter>;
  result_type?: InputMaybe<EnumResultTypeFilter>;
};

export type IntegrationErrorLogUpdateManyMutationInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  error_message?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  info?: InputMaybe<Scalars['Json']['input']>;
  integration_type?: InputMaybe<EnumIntegration_TypeFieldUpdateOperationsInput>;
  result_type?: InputMaybe<EnumResultTypeFieldUpdateOperationsInput>;
};

export type IntegrationErrorLogUpdateManyWithoutIntegrationOperationLogInput = {
  connect?: InputMaybe<Array<IntegrationErrorLogWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<IntegrationErrorLogCreateOrConnectWithoutIntegrationOperationLogInput>>;
  create?: InputMaybe<Array<IntegrationErrorLogCreateWithoutIntegrationOperationLogInput>>;
  createMany?: InputMaybe<IntegrationErrorLogCreateManyIntegrationOperationLogInputEnvelope>;
  delete?: InputMaybe<Array<IntegrationErrorLogWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<IntegrationErrorLogScalarWhereInput>>;
  disconnect?: InputMaybe<Array<IntegrationErrorLogWhereUniqueInput>>;
  set?: InputMaybe<Array<IntegrationErrorLogWhereUniqueInput>>;
  update?: InputMaybe<Array<IntegrationErrorLogUpdateWithWhereUniqueWithoutIntegrationOperationLogInput>>;
  updateMany?: InputMaybe<Array<IntegrationErrorLogUpdateManyWithWhereWithoutIntegrationOperationLogInput>>;
  upsert?: InputMaybe<Array<IntegrationErrorLogUpsertWithWhereUniqueWithoutIntegrationOperationLogInput>>;
};

export type IntegrationErrorLogUpdateManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<IntegrationErrorLogWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<IntegrationErrorLogCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<IntegrationErrorLogCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<IntegrationErrorLogCreateManyOrganizationInputEnvelope>;
  delete?: InputMaybe<Array<IntegrationErrorLogWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<IntegrationErrorLogScalarWhereInput>>;
  disconnect?: InputMaybe<Array<IntegrationErrorLogWhereUniqueInput>>;
  set?: InputMaybe<Array<IntegrationErrorLogWhereUniqueInput>>;
  update?: InputMaybe<Array<IntegrationErrorLogUpdateWithWhereUniqueWithoutOrganizationInput>>;
  updateMany?: InputMaybe<Array<IntegrationErrorLogUpdateManyWithWhereWithoutOrganizationInput>>;
  upsert?: InputMaybe<Array<IntegrationErrorLogUpsertWithWhereUniqueWithoutOrganizationInput>>;
};

export type IntegrationErrorLogUpdateManyWithWhereWithoutIntegrationOperationLogInput = {
  data: IntegrationErrorLogUpdateManyMutationInput;
  where: IntegrationErrorLogScalarWhereInput;
};

export type IntegrationErrorLogUpdateManyWithWhereWithoutOrganizationInput = {
  data: IntegrationErrorLogUpdateManyMutationInput;
  where: IntegrationErrorLogScalarWhereInput;
};

export type IntegrationErrorLogUpdateWithoutIntegrationOperationLogInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  error_message?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  info?: InputMaybe<Scalars['Json']['input']>;
  integration_type?: InputMaybe<EnumIntegration_TypeFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutIntegration_Error_LogsInput>;
  result_type?: InputMaybe<EnumResultTypeFieldUpdateOperationsInput>;
};

export type IntegrationErrorLogUpdateWithoutOrganizationInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  error_message?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  info?: InputMaybe<Scalars['Json']['input']>;
  integration_type?: InputMaybe<EnumIntegration_TypeFieldUpdateOperationsInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogUpdateOneWithoutMessagesInput>;
  result_type?: InputMaybe<EnumResultTypeFieldUpdateOperationsInput>;
};

export type IntegrationErrorLogUpdateWithWhereUniqueWithoutIntegrationOperationLogInput = {
  data: IntegrationErrorLogUpdateWithoutIntegrationOperationLogInput;
  where: IntegrationErrorLogWhereUniqueInput;
};

export type IntegrationErrorLogUpdateWithWhereUniqueWithoutOrganizationInput = {
  data: IntegrationErrorLogUpdateWithoutOrganizationInput;
  where: IntegrationErrorLogWhereUniqueInput;
};

export type IntegrationErrorLogUpsertWithWhereUniqueWithoutIntegrationOperationLogInput = {
  create: IntegrationErrorLogCreateWithoutIntegrationOperationLogInput;
  update: IntegrationErrorLogUpdateWithoutIntegrationOperationLogInput;
  where: IntegrationErrorLogWhereUniqueInput;
};

export type IntegrationErrorLogUpsertWithWhereUniqueWithoutOrganizationInput = {
  create: IntegrationErrorLogCreateWithoutOrganizationInput;
  update: IntegrationErrorLogUpdateWithoutOrganizationInput;
  where: IntegrationErrorLogWhereUniqueInput;
};

export type IntegrationErrorLogWhereInput = {
  AND?: InputMaybe<Array<IntegrationErrorLogWhereInput>>;
  created_at?: InputMaybe<DateTimeFilter>;
  error_message?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  info?: InputMaybe<JsonNullableFilter>;
  integration_operation_log_id?: InputMaybe<StringNullableFilter>;
  integration_type?: InputMaybe<EnumIntegration_TypeFilter>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogWhereInput>;
  NOT?: InputMaybe<Array<IntegrationErrorLogWhereInput>>;
  OR?: InputMaybe<Array<IntegrationErrorLogWhereInput>>;
  organization?: InputMaybe<OrganizationWhereInput>;
  organization_id?: InputMaybe<StringFilter>;
  result_type?: InputMaybe<EnumResultTypeFilter>;
};

export type IntegrationErrorLogWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']['input']>;
};

/** argument for integration error logs */
export type IntegrationLogArgument = {
  entity_id?: InputMaybe<Scalars['String']['input']>;
  integration_type: Integration_Type;
  lowerbound_date?: InputMaybe<Scalars['Date']['input']>;
  operation_type?: InputMaybe<IntegrationOperationType>;
  result_type?: InputMaybe<ResultType>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  upperbound_date?: InputMaybe<Scalars['Date']['input']>;
};

export type IntegrationMapping = {
  __typename?: 'IntegrationMapping';
  created_at: Scalars['DateTime']['output'];
  external_field: Scalars['String']['output'];
  external_field_label: Scalars['String']['output'];
  external_object: Scalars['String']['output'];
  external_template_id?: Maybe<Scalars['String']['output']>;
  field_type: Scalars['String']['output'];
  id: Scalars['String']['output'];
  inspect?: Maybe<Scalars['Boolean']['output']>;
  integration_type: Integration_Type;
  is_custom_object: Scalars['Boolean']['output'];
  mapping_type: Mapping_Type;
  opsiq_field: Scalars['String']['output'];
  opsiq_field_label: Scalars['String']['output'];
  opsiq_field_type: Scalars['String']['output'];
  opsiq_object: Scalars['String']['output'];
  organization: Organization;
  organization_id: Scalars['String']['output'];
  /** Returns if the mapping is valid or not. */
  status?: Maybe<Scalars['Boolean']['output']>;
  sync_rule: Sync_Rule;
  trigger_events: Array<System_Event>;
  updated_at: Scalars['DateTime']['output'];
};

export type IntegrationMappingCreateManyOrganizationInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  external_field: Scalars['String']['input'];
  external_field_label: Scalars['String']['input'];
  external_object: Scalars['String']['input'];
  external_template_id?: InputMaybe<Scalars['String']['input']>;
  field_type?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  integration_type: Integration_Type;
  is_custom_object?: InputMaybe<Scalars['Boolean']['input']>;
  mapping_type: Mapping_Type;
  opsiq_field: Scalars['String']['input'];
  opsiq_field_label: Scalars['String']['input'];
  opsiq_field_type?: InputMaybe<Scalars['String']['input']>;
  opsiq_object: Scalars['String']['input'];
  sync_rule: Sync_Rule;
  trigger_events?: InputMaybe<IntegrationMappingCreateManytrigger_EventsInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type IntegrationMappingCreateManyOrganizationInputEnvelope = {
  data?: InputMaybe<Array<IntegrationMappingCreateManyOrganizationInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type IntegrationMappingCreateManytrigger_EventsInput = {
  set?: InputMaybe<Array<System_Event>>;
};

export type IntegrationMappingCreateNestedManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<IntegrationMappingWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<IntegrationMappingCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<IntegrationMappingCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<IntegrationMappingCreateManyOrganizationInputEnvelope>;
};

export type IntegrationMappingCreateOrConnectWithoutOrganizationInput = {
  create: IntegrationMappingCreateWithoutOrganizationInput;
  where: IntegrationMappingWhereUniqueInput;
};

export type IntegrationMappingCreatetrigger_EventsInput = {
  set?: InputMaybe<Array<System_Event>>;
};

export type IntegrationMappingCreateWithoutOrganizationInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  external_field: Scalars['String']['input'];
  external_field_label: Scalars['String']['input'];
  external_object: Scalars['String']['input'];
  external_template_id?: InputMaybe<Scalars['String']['input']>;
  field_type?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  integration_type: Integration_Type;
  is_custom_object?: InputMaybe<Scalars['Boolean']['input']>;
  mapping_type: Mapping_Type;
  opsiq_field: Scalars['String']['input'];
  opsiq_field_label: Scalars['String']['input'];
  opsiq_field_type?: InputMaybe<Scalars['String']['input']>;
  opsiq_object: Scalars['String']['input'];
  sync_rule: Sync_Rule;
  trigger_events?: InputMaybe<IntegrationMappingCreatetrigger_EventsInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type IntegrationMappingCustomObject = {
  __typename?: 'IntegrationMappingCustomObject';
  active: Scalars['Boolean']['output'];
  associated_object: Scalars['String']['output'];
  created_at: Scalars['DateTime']['output'];
  external_object: Scalars['String']['output'];
  id: Scalars['String']['output'];
  integration_type: Integration_Type;
  organization: Organization;
  organization_id: Scalars['String']['output'];
  reference_field: Scalars['String']['output'];
  reference_object: Scalars['String']['output'];
  updated_at: Scalars['DateTime']['output'];
};

export type IntegrationMappingCustomObjectCreateManyOrganizationInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  associated_object: Scalars['String']['input'];
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  external_object: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  integration_type?: InputMaybe<Integration_Type>;
  reference_field: Scalars['String']['input'];
  reference_object: Scalars['String']['input'];
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type IntegrationMappingCustomObjectCreateManyOrganizationInputEnvelope = {
  data?: InputMaybe<Array<IntegrationMappingCustomObjectCreateManyOrganizationInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type IntegrationMappingCustomObjectCreateNestedManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<IntegrationMappingCustomObjectWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<IntegrationMappingCustomObjectCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<IntegrationMappingCustomObjectCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<IntegrationMappingCustomObjectCreateManyOrganizationInputEnvelope>;
};

export type IntegrationMappingCustomObjectCreateOrConnectWithoutOrganizationInput = {
  create: IntegrationMappingCustomObjectCreateWithoutOrganizationInput;
  where: IntegrationMappingCustomObjectWhereUniqueInput;
};

export type IntegrationMappingCustomObjectCreateWithoutOrganizationInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  associated_object: Scalars['String']['input'];
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  external_object: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  integration_type?: InputMaybe<Integration_Type>;
  reference_field: Scalars['String']['input'];
  reference_object: Scalars['String']['input'];
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type IntegrationMappingCustomObjectListRelationFilter = {
  every?: InputMaybe<IntegrationMappingCustomObjectWhereInput>;
  none?: InputMaybe<IntegrationMappingCustomObjectWhereInput>;
  some?: InputMaybe<IntegrationMappingCustomObjectWhereInput>;
};

export type IntegrationMappingCustomObjectOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
  count?: InputMaybe<SortOrder>;
};

export type IntegrationMappingCustomObjectOrganization_IdExternal_ObjectReference_ObjectReference_FieldCompoundUniqueInput = {
  external_object: Scalars['String']['input'];
  organization_id: Scalars['String']['input'];
  reference_field: Scalars['String']['input'];
  reference_object: Scalars['String']['input'];
};

export type IntegrationMappingCustomObjectScalarWhereInput = {
  active?: InputMaybe<BoolFilter>;
  AND?: InputMaybe<Array<IntegrationMappingCustomObjectScalarWhereInput>>;
  associated_object?: InputMaybe<StringFilter>;
  created_at?: InputMaybe<DateTimeFilter>;
  external_object?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  integration_type?: InputMaybe<EnumIntegration_TypeFilter>;
  NOT?: InputMaybe<Array<IntegrationMappingCustomObjectScalarWhereInput>>;
  OR?: InputMaybe<Array<IntegrationMappingCustomObjectScalarWhereInput>>;
  organization_id?: InputMaybe<StringFilter>;
  reference_field?: InputMaybe<StringFilter>;
  reference_object?: InputMaybe<StringFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
};

export type IntegrationMappingCustomObjectUpdateManyMutationInput = {
  active?: InputMaybe<BoolFieldUpdateOperationsInput>;
  associated_object?: InputMaybe<StringFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  external_object?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  integration_type?: InputMaybe<EnumIntegration_TypeFieldUpdateOperationsInput>;
  reference_field?: InputMaybe<StringFieldUpdateOperationsInput>;
  reference_object?: InputMaybe<StringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type IntegrationMappingCustomObjectUpdateManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<IntegrationMappingCustomObjectWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<IntegrationMappingCustomObjectCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<IntegrationMappingCustomObjectCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<IntegrationMappingCustomObjectCreateManyOrganizationInputEnvelope>;
  delete?: InputMaybe<Array<IntegrationMappingCustomObjectWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<IntegrationMappingCustomObjectScalarWhereInput>>;
  disconnect?: InputMaybe<Array<IntegrationMappingCustomObjectWhereUniqueInput>>;
  set?: InputMaybe<Array<IntegrationMappingCustomObjectWhereUniqueInput>>;
  update?: InputMaybe<Array<IntegrationMappingCustomObjectUpdateWithWhereUniqueWithoutOrganizationInput>>;
  updateMany?: InputMaybe<Array<IntegrationMappingCustomObjectUpdateManyWithWhereWithoutOrganizationInput>>;
  upsert?: InputMaybe<Array<IntegrationMappingCustomObjectUpsertWithWhereUniqueWithoutOrganizationInput>>;
};

export type IntegrationMappingCustomObjectUpdateManyWithWhereWithoutOrganizationInput = {
  data: IntegrationMappingCustomObjectUpdateManyMutationInput;
  where: IntegrationMappingCustomObjectScalarWhereInput;
};

export type IntegrationMappingCustomObjectUpdateWithoutOrganizationInput = {
  active?: InputMaybe<BoolFieldUpdateOperationsInput>;
  associated_object?: InputMaybe<StringFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  external_object?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  integration_type?: InputMaybe<EnumIntegration_TypeFieldUpdateOperationsInput>;
  reference_field?: InputMaybe<StringFieldUpdateOperationsInput>;
  reference_object?: InputMaybe<StringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type IntegrationMappingCustomObjectUpdateWithWhereUniqueWithoutOrganizationInput = {
  data: IntegrationMappingCustomObjectUpdateWithoutOrganizationInput;
  where: IntegrationMappingCustomObjectWhereUniqueInput;
};

export type IntegrationMappingCustomObjectUpsertWithWhereUniqueWithoutOrganizationInput = {
  create: IntegrationMappingCustomObjectCreateWithoutOrganizationInput;
  update: IntegrationMappingCustomObjectUpdateWithoutOrganizationInput;
  where: IntegrationMappingCustomObjectWhereUniqueInput;
};

export type IntegrationMappingCustomObjectWhereInput = {
  active?: InputMaybe<BoolFilter>;
  AND?: InputMaybe<Array<IntegrationMappingCustomObjectWhereInput>>;
  associated_object?: InputMaybe<StringFilter>;
  created_at?: InputMaybe<DateTimeFilter>;
  external_object?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  integration_type?: InputMaybe<EnumIntegration_TypeFilter>;
  NOT?: InputMaybe<Array<IntegrationMappingCustomObjectWhereInput>>;
  OR?: InputMaybe<Array<IntegrationMappingCustomObjectWhereInput>>;
  organization?: InputMaybe<OrganizationWhereInput>;
  organization_id?: InputMaybe<StringFilter>;
  reference_field?: InputMaybe<StringFilter>;
  reference_object?: InputMaybe<StringFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
};

export type IntegrationMappingCustomObjectWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  organization_id_external_object_reference_object_reference_field?: InputMaybe<IntegrationMappingCustomObjectOrganization_IdExternal_ObjectReference_ObjectReference_FieldCompoundUniqueInput>;
};

/** Field Option for OPSIQ Fields */
export type IntegrationMappingFetchOption = {
  __typename?: 'IntegrationMappingFetchOption';
  integration_mapping_id: Scalars['String']['output'];
  options?: Maybe<Array<Maybe<OpsIqExternalMappingOption>>>;
};

/** Input object from third-party integration mapping */
export type IntegrationMappingInput = {
  events: Array<System_Event>;
  external_field: Scalars['String']['input'];
  external_field_label: Scalars['String']['input'];
  external_field_type?: InputMaybe<Scalars['String']['input']>;
  external_object: Scalars['String']['input'];
  external_template_id?: InputMaybe<Scalars['String']['input']>;
  mapping_type: Mapping_Type;
  opsiq_field: Scalars['String']['input'];
  opsiq_field_label: Scalars['String']['input'];
  opsiq_field_type?: InputMaybe<Scalars['String']['input']>;
  opsiq_object: Scalars['String']['input'];
  sync_rule: Sync_Rule;
};

export type IntegrationMappingListRelationFilter = {
  every?: InputMaybe<IntegrationMappingWhereInput>;
  none?: InputMaybe<IntegrationMappingWhereInput>;
  some?: InputMaybe<IntegrationMappingWhereInput>;
};

export type IntegrationMappingOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
  count?: InputMaybe<SortOrder>;
};

export type IntegrationMappingScalarWhereInput = {
  AND?: InputMaybe<Array<IntegrationMappingScalarWhereInput>>;
  created_at?: InputMaybe<DateTimeFilter>;
  external_field?: InputMaybe<StringFilter>;
  external_field_label?: InputMaybe<StringFilter>;
  external_object?: InputMaybe<StringFilter>;
  external_template_id?: InputMaybe<StringNullableFilter>;
  field_type?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  integration_type?: InputMaybe<EnumIntegration_TypeFilter>;
  is_custom_object?: InputMaybe<BoolFilter>;
  mapping_type?: InputMaybe<EnumMapping_TypeFilter>;
  NOT?: InputMaybe<Array<IntegrationMappingScalarWhereInput>>;
  opsiq_field?: InputMaybe<StringFilter>;
  opsiq_field_label?: InputMaybe<StringFilter>;
  opsiq_field_type?: InputMaybe<StringFilter>;
  opsiq_object?: InputMaybe<StringFilter>;
  OR?: InputMaybe<Array<IntegrationMappingScalarWhereInput>>;
  organization_id?: InputMaybe<StringFilter>;
  sync_rule?: InputMaybe<EnumSync_RuleFilter>;
  trigger_events?: InputMaybe<EnumSystem_EventNullableListFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
};

export type IntegrationMappingUpdateManyMutationInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  external_field?: InputMaybe<StringFieldUpdateOperationsInput>;
  external_field_label?: InputMaybe<StringFieldUpdateOperationsInput>;
  external_object?: InputMaybe<StringFieldUpdateOperationsInput>;
  external_template_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  field_type?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  integration_type?: InputMaybe<EnumIntegration_TypeFieldUpdateOperationsInput>;
  is_custom_object?: InputMaybe<BoolFieldUpdateOperationsInput>;
  mapping_type?: InputMaybe<EnumMapping_TypeFieldUpdateOperationsInput>;
  opsiq_field?: InputMaybe<StringFieldUpdateOperationsInput>;
  opsiq_field_label?: InputMaybe<StringFieldUpdateOperationsInput>;
  opsiq_field_type?: InputMaybe<StringFieldUpdateOperationsInput>;
  opsiq_object?: InputMaybe<StringFieldUpdateOperationsInput>;
  sync_rule?: InputMaybe<EnumSync_RuleFieldUpdateOperationsInput>;
  trigger_events?: InputMaybe<IntegrationMappingUpdatetrigger_EventsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type IntegrationMappingUpdateManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<IntegrationMappingWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<IntegrationMappingCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<IntegrationMappingCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<IntegrationMappingCreateManyOrganizationInputEnvelope>;
  delete?: InputMaybe<Array<IntegrationMappingWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<IntegrationMappingScalarWhereInput>>;
  disconnect?: InputMaybe<Array<IntegrationMappingWhereUniqueInput>>;
  set?: InputMaybe<Array<IntegrationMappingWhereUniqueInput>>;
  update?: InputMaybe<Array<IntegrationMappingUpdateWithWhereUniqueWithoutOrganizationInput>>;
  updateMany?: InputMaybe<Array<IntegrationMappingUpdateManyWithWhereWithoutOrganizationInput>>;
  upsert?: InputMaybe<Array<IntegrationMappingUpsertWithWhereUniqueWithoutOrganizationInput>>;
};

export type IntegrationMappingUpdateManyWithWhereWithoutOrganizationInput = {
  data: IntegrationMappingUpdateManyMutationInput;
  where: IntegrationMappingScalarWhereInput;
};

export type IntegrationMappingUpdatetrigger_EventsInput = {
  push?: InputMaybe<System_Event>;
  set?: InputMaybe<Array<System_Event>>;
};

export type IntegrationMappingUpdateWithoutOrganizationInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  external_field?: InputMaybe<StringFieldUpdateOperationsInput>;
  external_field_label?: InputMaybe<StringFieldUpdateOperationsInput>;
  external_object?: InputMaybe<StringFieldUpdateOperationsInput>;
  external_template_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  field_type?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  integration_type?: InputMaybe<EnumIntegration_TypeFieldUpdateOperationsInput>;
  is_custom_object?: InputMaybe<BoolFieldUpdateOperationsInput>;
  mapping_type?: InputMaybe<EnumMapping_TypeFieldUpdateOperationsInput>;
  opsiq_field?: InputMaybe<StringFieldUpdateOperationsInput>;
  opsiq_field_label?: InputMaybe<StringFieldUpdateOperationsInput>;
  opsiq_field_type?: InputMaybe<StringFieldUpdateOperationsInput>;
  opsiq_object?: InputMaybe<StringFieldUpdateOperationsInput>;
  sync_rule?: InputMaybe<EnumSync_RuleFieldUpdateOperationsInput>;
  trigger_events?: InputMaybe<IntegrationMappingUpdatetrigger_EventsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type IntegrationMappingUpdateWithWhereUniqueWithoutOrganizationInput = {
  data: IntegrationMappingUpdateWithoutOrganizationInput;
  where: IntegrationMappingWhereUniqueInput;
};

export type IntegrationMappingUpsertWithWhereUniqueWithoutOrganizationInput = {
  create: IntegrationMappingCreateWithoutOrganizationInput;
  update: IntegrationMappingUpdateWithoutOrganizationInput;
  where: IntegrationMappingWhereUniqueInput;
};

export type IntegrationMappingWhereInput = {
  AND?: InputMaybe<Array<IntegrationMappingWhereInput>>;
  created_at?: InputMaybe<DateTimeFilter>;
  external_field?: InputMaybe<StringFilter>;
  external_field_label?: InputMaybe<StringFilter>;
  external_object?: InputMaybe<StringFilter>;
  external_template_id?: InputMaybe<StringNullableFilter>;
  field_type?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  integration_type?: InputMaybe<EnumIntegration_TypeFilter>;
  is_custom_object?: InputMaybe<BoolFilter>;
  mapping_type?: InputMaybe<EnumMapping_TypeFilter>;
  NOT?: InputMaybe<Array<IntegrationMappingWhereInput>>;
  opsiq_field?: InputMaybe<StringFilter>;
  opsiq_field_label?: InputMaybe<StringFilter>;
  opsiq_field_type?: InputMaybe<StringFilter>;
  opsiq_object?: InputMaybe<StringFilter>;
  OR?: InputMaybe<Array<IntegrationMappingWhereInput>>;
  organization?: InputMaybe<OrganizationWhereInput>;
  organization_id?: InputMaybe<StringFilter>;
  sync_rule?: InputMaybe<EnumSync_RuleFilter>;
  trigger_events?: InputMaybe<EnumSystem_EventNullableListFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
};

export type IntegrationMappingWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']['input']>;
};

export type IntegrationOperationLog = {
  __typename?: 'IntegrationOperationLog';
  created_at: Scalars['DateTime']['output'];
  entity_id: Scalars['String']['output'];
  entity_type: IntegrationErrorEntityType;
  failed_event_pushed: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
  integration_type: Integration_Type;
  Lead?: Maybe<Lead>;
  lead_id?: Maybe<Scalars['String']['output']>;
  messages: Array<IntegrationErrorLog>;
  operation_type: IntegrationOperationType;
  operation_type_computed?: Maybe<Scalars['String']['output']>;
  organization: Organization;
  organization_id: Scalars['String']['output'];
  result_type: ResultType;
};


export type IntegrationOperationLogMessagesArgs = {
  after?: InputMaybe<IntegrationErrorLogWhereUniqueInput>;
  before?: InputMaybe<IntegrationErrorLogWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type IntegrationOperationLogCreateManyLeadInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  entity_id: Scalars['String']['input'];
  entity_type?: InputMaybe<IntegrationErrorEntityType>;
  failed_event_pushed?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  integration_type: Integration_Type;
  operation_type: IntegrationOperationType;
  organization_id: Scalars['String']['input'];
  payload?: InputMaybe<Scalars['Json']['input']>;
  result_type?: InputMaybe<ResultType>;
};

export type IntegrationOperationLogCreateManyLeadInputEnvelope = {
  data?: InputMaybe<Array<IntegrationOperationLogCreateManyLeadInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type IntegrationOperationLogCreateManyOrganizationInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  entity_id: Scalars['String']['input'];
  entity_type?: InputMaybe<IntegrationErrorEntityType>;
  failed_event_pushed?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  integration_type: Integration_Type;
  lead_id?: InputMaybe<Scalars['String']['input']>;
  operation_type: IntegrationOperationType;
  payload?: InputMaybe<Scalars['Json']['input']>;
  result_type?: InputMaybe<ResultType>;
};

export type IntegrationOperationLogCreateManyOrganizationInputEnvelope = {
  data?: InputMaybe<Array<IntegrationOperationLogCreateManyOrganizationInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type IntegrationOperationLogCreateNestedManyWithoutLeadInput = {
  connect?: InputMaybe<Array<IntegrationOperationLogWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<IntegrationOperationLogCreateOrConnectWithoutLeadInput>>;
  create?: InputMaybe<Array<IntegrationOperationLogCreateWithoutLeadInput>>;
  createMany?: InputMaybe<IntegrationOperationLogCreateManyLeadInputEnvelope>;
};

export type IntegrationOperationLogCreateNestedManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<IntegrationOperationLogWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<IntegrationOperationLogCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<IntegrationOperationLogCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<IntegrationOperationLogCreateManyOrganizationInputEnvelope>;
};

export type IntegrationOperationLogCreateNestedOneWithoutMessagesInput = {
  connect?: InputMaybe<IntegrationOperationLogWhereUniqueInput>;
  connectOrCreate?: InputMaybe<IntegrationOperationLogCreateOrConnectWithoutMessagesInput>;
  create?: InputMaybe<IntegrationOperationLogCreateWithoutMessagesInput>;
};

export type IntegrationOperationLogCreateOrConnectWithoutLeadInput = {
  create: IntegrationOperationLogCreateWithoutLeadInput;
  where: IntegrationOperationLogWhereUniqueInput;
};

export type IntegrationOperationLogCreateOrConnectWithoutMessagesInput = {
  create: IntegrationOperationLogCreateWithoutMessagesInput;
  where: IntegrationOperationLogWhereUniqueInput;
};

export type IntegrationOperationLogCreateOrConnectWithoutOrganizationInput = {
  create: IntegrationOperationLogCreateWithoutOrganizationInput;
  where: IntegrationOperationLogWhereUniqueInput;
};

export type IntegrationOperationLogCreateWithoutLeadInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  entity_id: Scalars['String']['input'];
  entity_type?: InputMaybe<IntegrationErrorEntityType>;
  failed_event_pushed?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  integration_type: Integration_Type;
  messages?: InputMaybe<IntegrationErrorLogCreateNestedManyWithoutIntegrationOperationLogInput>;
  operation_type: IntegrationOperationType;
  organization: OrganizationCreateNestedOneWithoutIntegrationOperationLogInput;
  payload?: InputMaybe<Scalars['Json']['input']>;
  result_type?: InputMaybe<ResultType>;
};

export type IntegrationOperationLogCreateWithoutMessagesInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  entity_id: Scalars['String']['input'];
  entity_type?: InputMaybe<IntegrationErrorEntityType>;
  failed_event_pushed?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  integration_type: Integration_Type;
  Lead?: InputMaybe<LeadCreateNestedOneWithoutIntegrationOperationLogInput>;
  operation_type: IntegrationOperationType;
  organization: OrganizationCreateNestedOneWithoutIntegrationOperationLogInput;
  payload?: InputMaybe<Scalars['Json']['input']>;
  result_type?: InputMaybe<ResultType>;
};

export type IntegrationOperationLogCreateWithoutOrganizationInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  entity_id: Scalars['String']['input'];
  entity_type?: InputMaybe<IntegrationErrorEntityType>;
  failed_event_pushed?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  integration_type: Integration_Type;
  Lead?: InputMaybe<LeadCreateNestedOneWithoutIntegrationOperationLogInput>;
  messages?: InputMaybe<IntegrationErrorLogCreateNestedManyWithoutIntegrationOperationLogInput>;
  operation_type: IntegrationOperationType;
  payload?: InputMaybe<Scalars['Json']['input']>;
  result_type?: InputMaybe<ResultType>;
};

export type IntegrationOperationLogListRelationFilter = {
  every?: InputMaybe<IntegrationOperationLogWhereInput>;
  none?: InputMaybe<IntegrationOperationLogWhereInput>;
  some?: InputMaybe<IntegrationOperationLogWhereInput>;
};

export type IntegrationOperationLogOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
  count?: InputMaybe<SortOrder>;
};

export type IntegrationOperationLogScalarWhereInput = {
  AND?: InputMaybe<Array<IntegrationOperationLogScalarWhereInput>>;
  created_at?: InputMaybe<DateTimeFilter>;
  entity_id?: InputMaybe<StringFilter>;
  entity_type?: InputMaybe<EnumIntegrationErrorEntityTypeFilter>;
  failed_event_pushed?: InputMaybe<BoolFilter>;
  id?: InputMaybe<StringFilter>;
  integration_type?: InputMaybe<EnumIntegration_TypeFilter>;
  lead_id?: InputMaybe<StringNullableFilter>;
  NOT?: InputMaybe<Array<IntegrationOperationLogScalarWhereInput>>;
  operation_type?: InputMaybe<EnumIntegrationOperationTypeFilter>;
  OR?: InputMaybe<Array<IntegrationOperationLogScalarWhereInput>>;
  organization_id?: InputMaybe<StringFilter>;
  payload?: InputMaybe<JsonNullableFilter>;
  result_type?: InputMaybe<EnumResultTypeFilter>;
};

export type IntegrationOperationLogUpdateManyMutationInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  entity_id?: InputMaybe<StringFieldUpdateOperationsInput>;
  entity_type?: InputMaybe<EnumIntegrationErrorEntityTypeFieldUpdateOperationsInput>;
  failed_event_pushed?: InputMaybe<BoolFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  integration_type?: InputMaybe<EnumIntegration_TypeFieldUpdateOperationsInput>;
  operation_type?: InputMaybe<EnumIntegrationOperationTypeFieldUpdateOperationsInput>;
  payload?: InputMaybe<Scalars['Json']['input']>;
  result_type?: InputMaybe<EnumResultTypeFieldUpdateOperationsInput>;
};

export type IntegrationOperationLogUpdateManyWithoutLeadInput = {
  connect?: InputMaybe<Array<IntegrationOperationLogWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<IntegrationOperationLogCreateOrConnectWithoutLeadInput>>;
  create?: InputMaybe<Array<IntegrationOperationLogCreateWithoutLeadInput>>;
  createMany?: InputMaybe<IntegrationOperationLogCreateManyLeadInputEnvelope>;
  delete?: InputMaybe<Array<IntegrationOperationLogWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<IntegrationOperationLogScalarWhereInput>>;
  disconnect?: InputMaybe<Array<IntegrationOperationLogWhereUniqueInput>>;
  set?: InputMaybe<Array<IntegrationOperationLogWhereUniqueInput>>;
  update?: InputMaybe<Array<IntegrationOperationLogUpdateWithWhereUniqueWithoutLeadInput>>;
  updateMany?: InputMaybe<Array<IntegrationOperationLogUpdateManyWithWhereWithoutLeadInput>>;
  upsert?: InputMaybe<Array<IntegrationOperationLogUpsertWithWhereUniqueWithoutLeadInput>>;
};

export type IntegrationOperationLogUpdateManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<IntegrationOperationLogWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<IntegrationOperationLogCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<IntegrationOperationLogCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<IntegrationOperationLogCreateManyOrganizationInputEnvelope>;
  delete?: InputMaybe<Array<IntegrationOperationLogWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<IntegrationOperationLogScalarWhereInput>>;
  disconnect?: InputMaybe<Array<IntegrationOperationLogWhereUniqueInput>>;
  set?: InputMaybe<Array<IntegrationOperationLogWhereUniqueInput>>;
  update?: InputMaybe<Array<IntegrationOperationLogUpdateWithWhereUniqueWithoutOrganizationInput>>;
  updateMany?: InputMaybe<Array<IntegrationOperationLogUpdateManyWithWhereWithoutOrganizationInput>>;
  upsert?: InputMaybe<Array<IntegrationOperationLogUpsertWithWhereUniqueWithoutOrganizationInput>>;
};

export type IntegrationOperationLogUpdateManyWithWhereWithoutLeadInput = {
  data: IntegrationOperationLogUpdateManyMutationInput;
  where: IntegrationOperationLogScalarWhereInput;
};

export type IntegrationOperationLogUpdateManyWithWhereWithoutOrganizationInput = {
  data: IntegrationOperationLogUpdateManyMutationInput;
  where: IntegrationOperationLogScalarWhereInput;
};

export type IntegrationOperationLogUpdateOneWithoutMessagesInput = {
  connect?: InputMaybe<IntegrationOperationLogWhereUniqueInput>;
  connectOrCreate?: InputMaybe<IntegrationOperationLogCreateOrConnectWithoutMessagesInput>;
  create?: InputMaybe<IntegrationOperationLogCreateWithoutMessagesInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<IntegrationOperationLogUpdateWithoutMessagesInput>;
  upsert?: InputMaybe<IntegrationOperationLogUpsertWithoutMessagesInput>;
};

export type IntegrationOperationLogUpdateWithoutLeadInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  entity_id?: InputMaybe<StringFieldUpdateOperationsInput>;
  entity_type?: InputMaybe<EnumIntegrationErrorEntityTypeFieldUpdateOperationsInput>;
  failed_event_pushed?: InputMaybe<BoolFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  integration_type?: InputMaybe<EnumIntegration_TypeFieldUpdateOperationsInput>;
  messages?: InputMaybe<IntegrationErrorLogUpdateManyWithoutIntegrationOperationLogInput>;
  operation_type?: InputMaybe<EnumIntegrationOperationTypeFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutIntegrationOperationLogInput>;
  payload?: InputMaybe<Scalars['Json']['input']>;
  result_type?: InputMaybe<EnumResultTypeFieldUpdateOperationsInput>;
};

export type IntegrationOperationLogUpdateWithoutMessagesInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  entity_id?: InputMaybe<StringFieldUpdateOperationsInput>;
  entity_type?: InputMaybe<EnumIntegrationErrorEntityTypeFieldUpdateOperationsInput>;
  failed_event_pushed?: InputMaybe<BoolFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  integration_type?: InputMaybe<EnumIntegration_TypeFieldUpdateOperationsInput>;
  Lead?: InputMaybe<LeadUpdateOneWithoutIntegrationOperationLogInput>;
  operation_type?: InputMaybe<EnumIntegrationOperationTypeFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutIntegrationOperationLogInput>;
  payload?: InputMaybe<Scalars['Json']['input']>;
  result_type?: InputMaybe<EnumResultTypeFieldUpdateOperationsInput>;
};

export type IntegrationOperationLogUpdateWithoutOrganizationInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  entity_id?: InputMaybe<StringFieldUpdateOperationsInput>;
  entity_type?: InputMaybe<EnumIntegrationErrorEntityTypeFieldUpdateOperationsInput>;
  failed_event_pushed?: InputMaybe<BoolFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  integration_type?: InputMaybe<EnumIntegration_TypeFieldUpdateOperationsInput>;
  Lead?: InputMaybe<LeadUpdateOneWithoutIntegrationOperationLogInput>;
  messages?: InputMaybe<IntegrationErrorLogUpdateManyWithoutIntegrationOperationLogInput>;
  operation_type?: InputMaybe<EnumIntegrationOperationTypeFieldUpdateOperationsInput>;
  payload?: InputMaybe<Scalars['Json']['input']>;
  result_type?: InputMaybe<EnumResultTypeFieldUpdateOperationsInput>;
};

export type IntegrationOperationLogUpdateWithWhereUniqueWithoutLeadInput = {
  data: IntegrationOperationLogUpdateWithoutLeadInput;
  where: IntegrationOperationLogWhereUniqueInput;
};

export type IntegrationOperationLogUpdateWithWhereUniqueWithoutOrganizationInput = {
  data: IntegrationOperationLogUpdateWithoutOrganizationInput;
  where: IntegrationOperationLogWhereUniqueInput;
};

export type IntegrationOperationLogUpsertWithoutMessagesInput = {
  create: IntegrationOperationLogCreateWithoutMessagesInput;
  update: IntegrationOperationLogUpdateWithoutMessagesInput;
};

export type IntegrationOperationLogUpsertWithWhereUniqueWithoutLeadInput = {
  create: IntegrationOperationLogCreateWithoutLeadInput;
  update: IntegrationOperationLogUpdateWithoutLeadInput;
  where: IntegrationOperationLogWhereUniqueInput;
};

export type IntegrationOperationLogUpsertWithWhereUniqueWithoutOrganizationInput = {
  create: IntegrationOperationLogCreateWithoutOrganizationInput;
  update: IntegrationOperationLogUpdateWithoutOrganizationInput;
  where: IntegrationOperationLogWhereUniqueInput;
};

export type IntegrationOperationLogWhereInput = {
  AND?: InputMaybe<Array<IntegrationOperationLogWhereInput>>;
  created_at?: InputMaybe<DateTimeFilter>;
  entity_id?: InputMaybe<StringFilter>;
  entity_type?: InputMaybe<EnumIntegrationErrorEntityTypeFilter>;
  failed_event_pushed?: InputMaybe<BoolFilter>;
  id?: InputMaybe<StringFilter>;
  integration_type?: InputMaybe<EnumIntegration_TypeFilter>;
  Lead?: InputMaybe<LeadWhereInput>;
  lead_id?: InputMaybe<StringNullableFilter>;
  messages?: InputMaybe<IntegrationErrorLogListRelationFilter>;
  NOT?: InputMaybe<Array<IntegrationOperationLogWhereInput>>;
  operation_type?: InputMaybe<EnumIntegrationOperationTypeFilter>;
  OR?: InputMaybe<Array<IntegrationOperationLogWhereInput>>;
  organization?: InputMaybe<OrganizationWhereInput>;
  organization_id?: InputMaybe<StringFilter>;
  payload?: InputMaybe<JsonNullableFilter>;
  result_type?: InputMaybe<EnumResultTypeFilter>;
};

export type IntegrationOperationLogWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']['input']>;
};

export enum IntegrationOperationType {
  HubSpotCustomObjectInbound = 'HubSpotCustomObjectInbound',
  HubSpotCustomObjectOutbound = 'HubSpotCustomObjectOutbound',
  HubSpotLeadAssociationEventOutbound = 'HubSpotLeadAssociationEventOutbound',
  HubSpotLeadCreationInbound = 'HubSpotLeadCreationInbound',
  HubSpotLeadCreationOutbound = 'HubSpotLeadCreationOutbound',
  HubSpotLeadUpdateInbound = 'HubSpotLeadUpdateInbound',
  HubSpotLeadUpdateOutbound = 'HubSpotLeadUpdateOutbound',
  PandaDocTemplateCreation = 'PandaDocTemplateCreation',
  SalesForceCustomObjectInbound = 'SalesForceCustomObjectInbound',
  SalesForceCustomObjectOutbound = 'SalesForceCustomObjectOutbound',
  SalesForceDemoOpportunityOutbound = 'SalesForceDemoOpportunityOutbound',
  SalesForceLeadActivityOutbound = 'SalesForceLeadActivityOutbound',
  SalesForceLeadInbound = 'SalesForceLeadInbound',
  SalesForceLeadOutbound = 'SalesForceLeadOutbound',
  SalesForceSale = 'SalesForceSale'
}

export type IntegrationReadOnlyField = {
  __typename?: 'IntegrationReadOnlyField';
  created_at: Scalars['DateTime']['output'];
  data_type: Scalars['String']['output'];
  display: Scalars['Boolean']['output'];
  field_label: Scalars['String']['output'];
  field_name: Scalars['String']['output'];
  id: Scalars['String']['output'];
  object_id: Scalars['String']['output'];
  updated_at: Scalars['DateTime']['output'];
};

export type IntegrationReadOnlyFieldCreateManyObjectInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  data_type: Scalars['String']['input'];
  display?: InputMaybe<Scalars['Boolean']['input']>;
  field_label: Scalars['String']['input'];
  field_name: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type IntegrationReadOnlyFieldCreateManyObjectInputEnvelope = {
  data?: InputMaybe<Array<IntegrationReadOnlyFieldCreateManyObjectInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type IntegrationReadOnlyFieldCreateNestedManyWithoutObjectInput = {
  connect?: InputMaybe<Array<IntegrationReadOnlyFieldWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<IntegrationReadOnlyFieldCreateOrConnectWithoutObjectInput>>;
  create?: InputMaybe<Array<IntegrationReadOnlyFieldCreateWithoutObjectInput>>;
  createMany?: InputMaybe<IntegrationReadOnlyFieldCreateManyObjectInputEnvelope>;
};

export type IntegrationReadOnlyFieldCreateOrConnectWithoutObjectInput = {
  create: IntegrationReadOnlyFieldCreateWithoutObjectInput;
  where: IntegrationReadOnlyFieldWhereUniqueInput;
};

export type IntegrationReadOnlyFieldCreateWithoutObjectInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  data_type: Scalars['String']['input'];
  display?: InputMaybe<Scalars['Boolean']['input']>;
  field_label: Scalars['String']['input'];
  field_name: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type IntegrationReadOnlyFieldListRelationFilter = {
  every?: InputMaybe<IntegrationReadOnlyFieldWhereInput>;
  none?: InputMaybe<IntegrationReadOnlyFieldWhereInput>;
  some?: InputMaybe<IntegrationReadOnlyFieldWhereInput>;
};

export type IntegrationReadOnlyFieldObject_IdField_NameCompoundUniqueInput = {
  field_name: Scalars['String']['input'];
  object_id: Scalars['String']['input'];
};

export type IntegrationReadOnlyFieldScalarWhereInput = {
  AND?: InputMaybe<Array<IntegrationReadOnlyFieldScalarWhereInput>>;
  created_at?: InputMaybe<DateTimeFilter>;
  data_type?: InputMaybe<StringFilter>;
  display?: InputMaybe<BoolFilter>;
  field_label?: InputMaybe<StringFilter>;
  field_name?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  NOT?: InputMaybe<Array<IntegrationReadOnlyFieldScalarWhereInput>>;
  object_id?: InputMaybe<StringFilter>;
  OR?: InputMaybe<Array<IntegrationReadOnlyFieldScalarWhereInput>>;
  updated_at?: InputMaybe<DateTimeFilter>;
};

export type IntegrationReadOnlyFieldUpdateManyMutationInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  data_type?: InputMaybe<StringFieldUpdateOperationsInput>;
  display?: InputMaybe<BoolFieldUpdateOperationsInput>;
  field_label?: InputMaybe<StringFieldUpdateOperationsInput>;
  field_name?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type IntegrationReadOnlyFieldUpdateManyWithoutObjectInput = {
  connect?: InputMaybe<Array<IntegrationReadOnlyFieldWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<IntegrationReadOnlyFieldCreateOrConnectWithoutObjectInput>>;
  create?: InputMaybe<Array<IntegrationReadOnlyFieldCreateWithoutObjectInput>>;
  createMany?: InputMaybe<IntegrationReadOnlyFieldCreateManyObjectInputEnvelope>;
  delete?: InputMaybe<Array<IntegrationReadOnlyFieldWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<IntegrationReadOnlyFieldScalarWhereInput>>;
  disconnect?: InputMaybe<Array<IntegrationReadOnlyFieldWhereUniqueInput>>;
  set?: InputMaybe<Array<IntegrationReadOnlyFieldWhereUniqueInput>>;
  update?: InputMaybe<Array<IntegrationReadOnlyFieldUpdateWithWhereUniqueWithoutObjectInput>>;
  updateMany?: InputMaybe<Array<IntegrationReadOnlyFieldUpdateManyWithWhereWithoutObjectInput>>;
  upsert?: InputMaybe<Array<IntegrationReadOnlyFieldUpsertWithWhereUniqueWithoutObjectInput>>;
};

export type IntegrationReadOnlyFieldUpdateManyWithWhereWithoutObjectInput = {
  data: IntegrationReadOnlyFieldUpdateManyMutationInput;
  where: IntegrationReadOnlyFieldScalarWhereInput;
};

export type IntegrationReadOnlyFieldUpdateWithoutObjectInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  data_type?: InputMaybe<StringFieldUpdateOperationsInput>;
  display?: InputMaybe<BoolFieldUpdateOperationsInput>;
  field_label?: InputMaybe<StringFieldUpdateOperationsInput>;
  field_name?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type IntegrationReadOnlyFieldUpdateWithWhereUniqueWithoutObjectInput = {
  data: IntegrationReadOnlyFieldUpdateWithoutObjectInput;
  where: IntegrationReadOnlyFieldWhereUniqueInput;
};

export type IntegrationReadOnlyFieldUpsertWithWhereUniqueWithoutObjectInput = {
  create: IntegrationReadOnlyFieldCreateWithoutObjectInput;
  update: IntegrationReadOnlyFieldUpdateWithoutObjectInput;
  where: IntegrationReadOnlyFieldWhereUniqueInput;
};

export type IntegrationReadOnlyFieldWhereInput = {
  AND?: InputMaybe<Array<IntegrationReadOnlyFieldWhereInput>>;
  created_at?: InputMaybe<DateTimeFilter>;
  data_type?: InputMaybe<StringFilter>;
  display?: InputMaybe<BoolFilter>;
  field_label?: InputMaybe<StringFilter>;
  field_name?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  NOT?: InputMaybe<Array<IntegrationReadOnlyFieldWhereInput>>;
  object?: InputMaybe<IntegrationReadOnlyObjectWhereInput>;
  object_id?: InputMaybe<StringFilter>;
  OR?: InputMaybe<Array<IntegrationReadOnlyFieldWhereInput>>;
  updated_at?: InputMaybe<DateTimeFilter>;
};

export type IntegrationReadOnlyFieldWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  object_id_field_name?: InputMaybe<IntegrationReadOnlyFieldObject_IdField_NameCompoundUniqueInput>;
};

export type IntegrationReadOnlyObject = {
  __typename?: 'IntegrationReadOnlyObject';
  active: Scalars['Boolean']['output'];
  created_at: Scalars['DateTime']['output'];
  fields: Array<IntegrationReadOnlyField>;
  id: Scalars['String']['output'];
  integration_fk_field: Scalars['String']['output'];
  integration_type: Integration_Type;
  lead_fk_field: Scalars['String']['output'];
  num_fields?: Maybe<Scalars['Int']['output']>;
  object_name: Scalars['String']['output'];
  organization: Organization;
  organization_id: Scalars['String']['output'];
  updated_at: Scalars['DateTime']['output'];
};


export type IntegrationReadOnlyObjectFieldsArgs = {
  after?: InputMaybe<IntegrationReadOnlyFieldWhereUniqueInput>;
  before?: InputMaybe<IntegrationReadOnlyFieldWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type IntegrationReadOnlyObjectCreateManyOrganizationInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  integration_fk_field?: InputMaybe<Scalars['String']['input']>;
  integration_type?: InputMaybe<Integration_Type>;
  lead_fk_field: Scalars['String']['input'];
  object_name: Scalars['String']['input'];
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type IntegrationReadOnlyObjectCreateManyOrganizationInputEnvelope = {
  data?: InputMaybe<Array<IntegrationReadOnlyObjectCreateManyOrganizationInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type IntegrationReadOnlyObjectCreateNestedManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<IntegrationReadOnlyObjectWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<IntegrationReadOnlyObjectCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<IntegrationReadOnlyObjectCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<IntegrationReadOnlyObjectCreateManyOrganizationInputEnvelope>;
};

export type IntegrationReadOnlyObjectCreateOrConnectWithoutOrganizationInput = {
  create: IntegrationReadOnlyObjectCreateWithoutOrganizationInput;
  where: IntegrationReadOnlyObjectWhereUniqueInput;
};

export type IntegrationReadOnlyObjectCreateWithoutOrganizationInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  fields?: InputMaybe<IntegrationReadOnlyFieldCreateNestedManyWithoutObjectInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  integration_fk_field?: InputMaybe<Scalars['String']['input']>;
  integration_type?: InputMaybe<Integration_Type>;
  lead_fk_field: Scalars['String']['input'];
  object_name: Scalars['String']['input'];
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type IntegrationReadOnlyObjectListRelationFilter = {
  every?: InputMaybe<IntegrationReadOnlyObjectWhereInput>;
  none?: InputMaybe<IntegrationReadOnlyObjectWhereInput>;
  some?: InputMaybe<IntegrationReadOnlyObjectWhereInput>;
};

export type IntegrationReadOnlyObjectObject_NameOrganization_IdIntegration_TypeCompoundUniqueInput = {
  integration_type: Integration_Type;
  object_name: Scalars['String']['input'];
  organization_id: Scalars['String']['input'];
};

export type IntegrationReadOnlyObjectOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
  count?: InputMaybe<SortOrder>;
};

export type IntegrationReadOnlyObjectScalarWhereInput = {
  active?: InputMaybe<BoolFilter>;
  AND?: InputMaybe<Array<IntegrationReadOnlyObjectScalarWhereInput>>;
  created_at?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  integration_fk_field?: InputMaybe<StringFilter>;
  integration_type?: InputMaybe<EnumIntegration_TypeFilter>;
  lead_fk_field?: InputMaybe<StringFilter>;
  NOT?: InputMaybe<Array<IntegrationReadOnlyObjectScalarWhereInput>>;
  object_name?: InputMaybe<StringFilter>;
  OR?: InputMaybe<Array<IntegrationReadOnlyObjectScalarWhereInput>>;
  organization_id?: InputMaybe<StringFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
};

export type IntegrationReadOnlyObjectUpdateManyMutationInput = {
  active?: InputMaybe<BoolFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  integration_fk_field?: InputMaybe<StringFieldUpdateOperationsInput>;
  integration_type?: InputMaybe<EnumIntegration_TypeFieldUpdateOperationsInput>;
  lead_fk_field?: InputMaybe<StringFieldUpdateOperationsInput>;
  object_name?: InputMaybe<StringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type IntegrationReadOnlyObjectUpdateManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<IntegrationReadOnlyObjectWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<IntegrationReadOnlyObjectCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<IntegrationReadOnlyObjectCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<IntegrationReadOnlyObjectCreateManyOrganizationInputEnvelope>;
  delete?: InputMaybe<Array<IntegrationReadOnlyObjectWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<IntegrationReadOnlyObjectScalarWhereInput>>;
  disconnect?: InputMaybe<Array<IntegrationReadOnlyObjectWhereUniqueInput>>;
  set?: InputMaybe<Array<IntegrationReadOnlyObjectWhereUniqueInput>>;
  update?: InputMaybe<Array<IntegrationReadOnlyObjectUpdateWithWhereUniqueWithoutOrganizationInput>>;
  updateMany?: InputMaybe<Array<IntegrationReadOnlyObjectUpdateManyWithWhereWithoutOrganizationInput>>;
  upsert?: InputMaybe<Array<IntegrationReadOnlyObjectUpsertWithWhereUniqueWithoutOrganizationInput>>;
};

export type IntegrationReadOnlyObjectUpdateManyWithWhereWithoutOrganizationInput = {
  data: IntegrationReadOnlyObjectUpdateManyMutationInput;
  where: IntegrationReadOnlyObjectScalarWhereInput;
};

export type IntegrationReadOnlyObjectUpdateWithoutOrganizationInput = {
  active?: InputMaybe<BoolFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  fields?: InputMaybe<IntegrationReadOnlyFieldUpdateManyWithoutObjectInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  integration_fk_field?: InputMaybe<StringFieldUpdateOperationsInput>;
  integration_type?: InputMaybe<EnumIntegration_TypeFieldUpdateOperationsInput>;
  lead_fk_field?: InputMaybe<StringFieldUpdateOperationsInput>;
  object_name?: InputMaybe<StringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type IntegrationReadOnlyObjectUpdateWithWhereUniqueWithoutOrganizationInput = {
  data: IntegrationReadOnlyObjectUpdateWithoutOrganizationInput;
  where: IntegrationReadOnlyObjectWhereUniqueInput;
};

export type IntegrationReadOnlyObjectUpsertWithWhereUniqueWithoutOrganizationInput = {
  create: IntegrationReadOnlyObjectCreateWithoutOrganizationInput;
  update: IntegrationReadOnlyObjectUpdateWithoutOrganizationInput;
  where: IntegrationReadOnlyObjectWhereUniqueInput;
};

export type IntegrationReadOnlyObjectWhereInput = {
  active?: InputMaybe<BoolFilter>;
  AND?: InputMaybe<Array<IntegrationReadOnlyObjectWhereInput>>;
  created_at?: InputMaybe<DateTimeFilter>;
  fields?: InputMaybe<IntegrationReadOnlyFieldListRelationFilter>;
  id?: InputMaybe<StringFilter>;
  integration_fk_field?: InputMaybe<StringFilter>;
  integration_type?: InputMaybe<EnumIntegration_TypeFilter>;
  lead_fk_field?: InputMaybe<StringFilter>;
  NOT?: InputMaybe<Array<IntegrationReadOnlyObjectWhereInput>>;
  object_name?: InputMaybe<StringFilter>;
  OR?: InputMaybe<Array<IntegrationReadOnlyObjectWhereInput>>;
  organization?: InputMaybe<OrganizationWhereInput>;
  organization_id?: InputMaybe<StringFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
};

export type IntegrationReadOnlyObjectWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  object_name_organization_id_integration_type?: InputMaybe<IntegrationReadOnlyObjectObject_NameOrganization_IdIntegration_TypeCompoundUniqueInput>;
};

export enum IntentType {
  AutomaticCallBack = 'AutomaticCallBack',
  CallBack = 'CallBack',
  ColdCall = 'ColdCall',
  Demo = 'Demo',
  DemoHeldCallBack = 'DemoHeldCallBack',
  DemoNotHeldCallBack = 'DemoNotHeldCallBack',
  Other = 'Other',
  ScheduledCallBack = 'ScheduledCallBack',
  SequenceAction = 'SequenceAction'
}

export type IntFieldUpdateOperationsInput = {
  decrement?: InputMaybe<Scalars['Int']['input']>;
  divide?: InputMaybe<Scalars['Int']['input']>;
  increment?: InputMaybe<Scalars['Int']['input']>;
  multiply?: InputMaybe<Scalars['Int']['input']>;
  set?: InputMaybe<Scalars['Int']['input']>;
};

export type IntFilter = {
  equals?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<Scalars['Int']['input']>>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
  not?: InputMaybe<NestedIntFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type IntNullableFilter = {
  equals?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<Scalars['Int']['input']>>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
  not?: InputMaybe<NestedIntNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type IntNullableListFilter = {
  equals?: InputMaybe<Array<Scalars['Int']['input']>>;
  has?: InputMaybe<Scalars['Int']['input']>;
  hasEvery?: InputMaybe<Array<Scalars['Int']['input']>>;
  hasSome?: InputMaybe<Array<Scalars['Int']['input']>>;
  isEmpty?: InputMaybe<Scalars['Boolean']['input']>;
};

export type InvitationMetadata = {
  __typename?: 'InvitationMetadata';
  email: Scalars['String']['output'];
  id: Scalars['String']['output'];
  result: InvitationResult;
};

export type InvitationMetadataCreateManyScheduled_ItemInput = {
  email: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  result?: InputMaybe<InvitationResult>;
};

export type InvitationMetadataCreateManyScheduled_ItemInputEnvelope = {
  data?: InputMaybe<Array<InvitationMetadataCreateManyScheduled_ItemInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type InvitationMetadataCreateNestedManyWithoutScheduled_ItemInput = {
  connect?: InputMaybe<Array<InvitationMetadataWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<InvitationMetadataCreateOrConnectWithoutScheduled_ItemInput>>;
  create?: InputMaybe<Array<InvitationMetadataCreateWithoutScheduled_ItemInput>>;
  createMany?: InputMaybe<InvitationMetadataCreateManyScheduled_ItemInputEnvelope>;
};

export type InvitationMetadataCreateOrConnectWithoutScheduled_ItemInput = {
  create: InvitationMetadataCreateWithoutScheduled_ItemInput;
  where: InvitationMetadataWhereUniqueInput;
};

export type InvitationMetadataCreateWithoutScheduled_ItemInput = {
  email: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  result?: InputMaybe<InvitationResult>;
};

export type InvitationMetadataListRelationFilter = {
  every?: InputMaybe<InvitationMetadataWhereInput>;
  none?: InputMaybe<InvitationMetadataWhereInput>;
  some?: InputMaybe<InvitationMetadataWhereInput>;
};

export type InvitationMetadataOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
  count?: InputMaybe<SortOrder>;
};

export type InvitationMetadataScalarWhereInput = {
  AND?: InputMaybe<Array<InvitationMetadataScalarWhereInput>>;
  email?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  NOT?: InputMaybe<Array<InvitationMetadataScalarWhereInput>>;
  OR?: InputMaybe<Array<InvitationMetadataScalarWhereInput>>;
  result?: InputMaybe<EnumInvitationResultFilter>;
  scheduled_item_id?: InputMaybe<StringFilter>;
};

export type InvitationMetadataUpdateManyMutationInput = {
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  result?: InputMaybe<EnumInvitationResultFieldUpdateOperationsInput>;
};

export type InvitationMetadataUpdateManyWithoutScheduled_ItemInput = {
  connect?: InputMaybe<Array<InvitationMetadataWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<InvitationMetadataCreateOrConnectWithoutScheduled_ItemInput>>;
  create?: InputMaybe<Array<InvitationMetadataCreateWithoutScheduled_ItemInput>>;
  createMany?: InputMaybe<InvitationMetadataCreateManyScheduled_ItemInputEnvelope>;
  delete?: InputMaybe<Array<InvitationMetadataWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<InvitationMetadataScalarWhereInput>>;
  disconnect?: InputMaybe<Array<InvitationMetadataWhereUniqueInput>>;
  set?: InputMaybe<Array<InvitationMetadataWhereUniqueInput>>;
  update?: InputMaybe<Array<InvitationMetadataUpdateWithWhereUniqueWithoutScheduled_ItemInput>>;
  updateMany?: InputMaybe<Array<InvitationMetadataUpdateManyWithWhereWithoutScheduled_ItemInput>>;
  upsert?: InputMaybe<Array<InvitationMetadataUpsertWithWhereUniqueWithoutScheduled_ItemInput>>;
};

export type InvitationMetadataUpdateManyWithWhereWithoutScheduled_ItemInput = {
  data: InvitationMetadataUpdateManyMutationInput;
  where: InvitationMetadataScalarWhereInput;
};

export type InvitationMetadataUpdateWithoutScheduled_ItemInput = {
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  result?: InputMaybe<EnumInvitationResultFieldUpdateOperationsInput>;
};

export type InvitationMetadataUpdateWithWhereUniqueWithoutScheduled_ItemInput = {
  data: InvitationMetadataUpdateWithoutScheduled_ItemInput;
  where: InvitationMetadataWhereUniqueInput;
};

export type InvitationMetadataUpsertWithWhereUniqueWithoutScheduled_ItemInput = {
  create: InvitationMetadataCreateWithoutScheduled_ItemInput;
  update: InvitationMetadataUpdateWithoutScheduled_ItemInput;
  where: InvitationMetadataWhereUniqueInput;
};

export type InvitationMetadataWhereInput = {
  AND?: InputMaybe<Array<InvitationMetadataWhereInput>>;
  email?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  NOT?: InputMaybe<Array<InvitationMetadataWhereInput>>;
  OR?: InputMaybe<Array<InvitationMetadataWhereInput>>;
  result?: InputMaybe<EnumInvitationResultFilter>;
  scheduled_item?: InputMaybe<ScheduleItemWhereInput>;
  scheduled_item_id?: InputMaybe<StringFilter>;
};

export type InvitationMetadataWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']['input']>;
};

export enum InvitationResult {
  Accepted = 'Accepted',
  Rejected = 'Rejected',
  Waiting = 'Waiting'
}

export enum JitterBufferSize {
  Large = 'large',
  Medium = 'medium',
  Off = 'off',
  Small = 'small'
}

export type JoinMeItem = {
  __typename?: 'JoinMeItem';
  created_at: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  meeting_id: Scalars['String']['output'];
  schedule_item?: Maybe<ScheduleItem>;
  schedule_item_id?: Maybe<Scalars['String']['output']>;
  updated_at: Scalars['DateTime']['output'];
  viewer_url: Scalars['String']['output'];
};

export type JoinMeItemCreateNestedOneWithoutSchedule_ItemInput = {
  connect?: InputMaybe<JoinMeItemWhereUniqueInput>;
  connectOrCreate?: InputMaybe<JoinMeItemCreateOrConnectWithoutSchedule_ItemInput>;
  create?: InputMaybe<JoinMeItemCreateWithoutSchedule_ItemInput>;
};

export type JoinMeItemCreateOrConnectWithoutSchedule_ItemInput = {
  create: JoinMeItemCreateWithoutSchedule_ItemInput;
  where: JoinMeItemWhereUniqueInput;
};

export type JoinMeItemCreateWithoutSchedule_ItemInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  meeting_id: Scalars['String']['input'];
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  viewer_url: Scalars['String']['input'];
};

export type JoinMeItemOrderByWithRelationInput = {
  created_at?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  meeting_id?: InputMaybe<SortOrder>;
  schedule_item?: InputMaybe<ScheduleItemOrderByWithRelationInput>;
  schedule_item_id?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
  viewer_url?: InputMaybe<SortOrder>;
};

export type JoinMeItemUpdateOneWithoutSchedule_ItemInput = {
  connect?: InputMaybe<JoinMeItemWhereUniqueInput>;
  connectOrCreate?: InputMaybe<JoinMeItemCreateOrConnectWithoutSchedule_ItemInput>;
  create?: InputMaybe<JoinMeItemCreateWithoutSchedule_ItemInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<JoinMeItemUpdateWithoutSchedule_ItemInput>;
  upsert?: InputMaybe<JoinMeItemUpsertWithoutSchedule_ItemInput>;
};

export type JoinMeItemUpdateWithoutSchedule_ItemInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  meeting_id?: InputMaybe<StringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  viewer_url?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type JoinMeItemUpsertWithoutSchedule_ItemInput = {
  create: JoinMeItemCreateWithoutSchedule_ItemInput;
  update: JoinMeItemUpdateWithoutSchedule_ItemInput;
};

export type JoinMeItemWhereInput = {
  AND?: InputMaybe<Array<JoinMeItemWhereInput>>;
  created_at?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  meeting_id?: InputMaybe<StringFilter>;
  NOT?: InputMaybe<Array<JoinMeItemWhereInput>>;
  OR?: InputMaybe<Array<JoinMeItemWhereInput>>;
  schedule_item?: InputMaybe<ScheduleItemWhereInput>;
  schedule_item_id?: InputMaybe<StringNullableFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
  viewer_url?: InputMaybe<StringFilter>;
};

export type JoinMeItemWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  schedule_item_id?: InputMaybe<Scalars['String']['input']>;
};

export type JsonNullableFilter = {
  array_contains?: InputMaybe<Scalars['Json']['input']>;
  array_ends_with?: InputMaybe<Scalars['Json']['input']>;
  array_starts_with?: InputMaybe<Scalars['Json']['input']>;
  equals?: InputMaybe<Scalars['Json']['input']>;
  gt?: InputMaybe<Scalars['Json']['input']>;
  gte?: InputMaybe<Scalars['Json']['input']>;
  lt?: InputMaybe<Scalars['Json']['input']>;
  lte?: InputMaybe<Scalars['Json']['input']>;
  not?: InputMaybe<Scalars['Json']['input']>;
  path?: InputMaybe<Array<Scalars['String']['input']>>;
  string_contains?: InputMaybe<Scalars['String']['input']>;
  string_ends_with?: InputMaybe<Scalars['String']['input']>;
  string_starts_with?: InputMaybe<Scalars['String']['input']>;
};

export type JsonNullableListFilter = {
  equals?: InputMaybe<Array<Scalars['Json']['input']>>;
  has?: InputMaybe<Scalars['Json']['input']>;
  hasEvery?: InputMaybe<Array<Scalars['Json']['input']>>;
  hasSome?: InputMaybe<Array<Scalars['Json']['input']>>;
  isEmpty?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Lead = {
  __typename?: 'Lead';
  address?: Maybe<Scalars['String']['output']>;
  address_2?: Maybe<Scalars['String']['output']>;
  alternate_contacts: Array<Contact>;
  assignment_locked: Scalars['Boolean']['output'];
  associate_children: Array<Lead>;
  associate_group_lead_activities: Array<LeadActivity>;
  associate_lead_histories: Array<AssociationHistory>;
  associate_parent_id?: Maybe<Scalars['String']['output']>;
  associate_parent_lead?: Maybe<Lead>;
  back_fill_first_demo?: Maybe<Scalars['Date']['output']>;
  business_name?: Maybe<Scalars['String']['output']>;
  /** Returns all call notes for the lead. Call notes are stored on the lead activity. */
  call_notes?: Maybe<Array<Maybe<LeadActivity>>>;
  /** Returns all call notes for the lead. Call notes are stored on the lead activity. This excludes SMS messages */
  call_notes_without_sms?: Maybe<Array<Maybe<LeadActivity>>>;
  can_record_without_permission?: Maybe<Scalars['Boolean']['output']>;
  channel?: Maybe<Channel>;
  city?: Maybe<Scalars['String']['output']>;
  claim_date?: Maybe<Scalars['DateTime']['output']>;
  close?: Maybe<Scalars['Boolean']['output']>;
  /** Close Date */
  close_date?: Maybe<Scalars['DateTime']['output']>;
  computed_mrr?: Maybe<Scalars['Float']['output']>;
  conference_number?: Maybe<Scalars['String']['output']>;
  contact_requests: Array<ContactRequest>;
  content?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  created_at: Scalars['DateTime']['output'];
  current_close_date?: Maybe<Scalars['DateTime']['output']>;
  current_contact_date?: Maybe<Scalars['DateTime']['output']>;
  /** The current disposition in the lead's lifecycle. */
  current_disposition?: Maybe<LeadActivity>;
  current_lead_type?: Maybe<Scalars['String']['output']>;
  current_sale_cycle?: Maybe<SaleCycle>;
  custom_field_json?: Maybe<Scalars['Json']['output']>;
  custom_fields: Array<CustomField>;
  custom_queue_items: Array<CustomQueueItem>;
  day_set_out?: Maybe<Scalars['Int']['output']>;
  email_sub_status: OptInStatus;
  /** Returns a list of emails for the user */
  emails?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  favorited?: Maybe<Scalars['Boolean']['output']>;
  favorited_by_users: Array<User>;
  first_demo_schedule_date?: Maybe<Scalars['DateTime']['output']>;
  first_name?: Maybe<Scalars['String']['output']>;
  full_name?: Maybe<Scalars['String']['output']>;
  full_name_or_business?: Maybe<Scalars['String']['output']>;
  held?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['String']['output'];
  implied_next_action: Hingepoint;
  industry?: Maybe<Scalars['String']['output']>;
  industry_label?: Maybe<Scalars['String']['output']>;
  /** Returns CyclingBack or InActiveQueue or NotFound */
  is_in_active_queue?: Maybe<Scalars['String']['output']>;
  last_call_result?: Maybe<Scalars['String']['output']>;
  last_call_result_rep?: Maybe<User>;
  last_call_result_rep_id?: Maybe<Scalars['String']['output']>;
  last_call_result_time?: Maybe<Scalars['DateTime']['output']>;
  /** The current disposition in the lead's lifecycle. */
  last_disposition_label?: Maybe<Scalars['String']['output']>;
  last_lead_activity?: Maybe<LeadActivity>;
  last_name?: Maybe<Scalars['String']['output']>;
  last_sent_to_resting_date?: Maybe<Scalars['DateTime']['output']>;
  last_skipped_date?: Maybe<Scalars['Date']['output']>;
  last_skipped_reason: Scalars['String']['output'];
  /** return the last date the lead was touched */
  last_touched_date?: Maybe<Scalars['Date']['output']>;
  lead_activities: Array<LeadActivity>;
  lead_assignment_history: Array<LeadAssignmentHistory>;
  lead_assignment_origin: AssignmentOrigin;
  lead_creation_source?: Maybe<Leadcreationsource>;
  lead_import_history?: Maybe<LeadImportHistory>;
  lead_import_history_csv?: Maybe<LeadImportHistory>;
  lead_import_history_id?: Maybe<Scalars['String']['output']>;
  lead_intent: Array<LeadIntent>;
  lead_ownership_status: Los;
  lead_source?: Maybe<Scalars['String']['output']>;
  lead_source_label?: Maybe<Scalars['String']['output']>;
  lead_type?: Maybe<Scalars['String']['output']>;
  lead_value?: Maybe<LeadValue>;
  /** Returns the primary phone number without country code */
  local_primary_phone_number?: Maybe<Scalars['String']['output']>;
  most_recent_disposition?: Maybe<LeadActivity>;
  most_recent_sale_cycle?: Maybe<SaleCycle>;
  mrr?: Maybe<Scalars['Float']['output']>;
  newIncoming: Scalars['Boolean']['output'];
  newIncomingComputed?: Maybe<Scalars['Boolean']['output']>;
  /** Returns the next intent for the user. If there is a scheduled intent in the future, it returns that. If no future scheduled intents, it will return the most recently created non-scheduled intent. If the previous 2 conditions do not pass, return null. */
  next_intent_scheduled_or_unscheduled?: Maybe<LeadIntent>;
  /** Returns the next scheduled lead intent for the user. Returns null of no scheduled events. */
  next_lead_intent?: Maybe<LeadIntent>;
  /** Returns the next scheduled event for the user. Returns null of no scheduled events. */
  next_scheduled_agent?: Maybe<User>;
  /** Returns the next scheduled event for the user. Returns null of no scheduled events. */
  next_scheduled_event?: Maybe<ScheduleItem>;
  /** Returns the next scheduled event for the user. Returns null of no scheduled events. */
  next_scheduled_event_time?: Maybe<Scalars['DateTime']['output']>;
  /** return # of connected call for the lead */
  num_of_dials?: Maybe<Scalars['Int']['output']>;
  opsiq_whitelist?: Maybe<Scalars['Boolean']['output']>;
  organization: Organization;
  organization_id: Scalars['String']['output'];
  phase: Phase;
  phase_label?: Maybe<Scalars['String']['output']>;
  /** Returns a list of phone_numbers for the user */
  phone_numbers?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  pipeline_state_label?: Maybe<Scalars['String']['output']>;
  primary_email?: Maybe<Scalars['String']['output']>;
  primary_email_title?: Maybe<Scalars['String']['output']>;
  primary_email_type?: Maybe<Scalars['String']['output']>;
  primary_lead_histories: Array<AssociationHistory>;
  primary_phone_number?: Maybe<Scalars['String']['output']>;
  primary_phone_number_country_code?: Maybe<Scalars['String']['output']>;
  primary_phone_number_title?: Maybe<Scalars['String']['output']>;
  primary_phone_number_type?: Maybe<Scalars['String']['output']>;
  rep?: Maybe<User>;
  rep_id?: Maybe<Scalars['String']['output']>;
  revenue_value?: Maybe<Scalars['Float']['output']>;
  routing_history: Array<RoutingHistory>;
  Sale: Array<Sale>;
  sale_cycles: Array<SaleCycle>;
  sale_flow_custom_fields?: Maybe<Array<Maybe<CustomField>>>;
  self_sourced?: Maybe<Scalars['Boolean']['output']>;
  sequence?: Maybe<Sequence>;
  sequence_id?: Maybe<Scalars['String']['output']>;
  sequence_step?: Maybe<SequenceStep>;
  sequence_step_id?: Maybe<Scalars['String']['output']>;
  sequences_joined_ids: Array<Scalars['String']['output']>;
  set?: Maybe<Scalars['Boolean']['output']>;
  /** Returns true if the user should show a custom confirm details screen in the make sale flow. */
  show_sale_flow_custom_fields?: Maybe<Scalars['Boolean']['output']>;
  sibling_lead_histories: Array<AssociationHistory>;
  sms_sub_status: OptInStatus;
  source_type?: Maybe<Scalars['String']['output']>;
  stat_items: Array<StatItemAggregation>;
  state?: Maybe<Scalars['String']['output']>;
  StatLeadAggregation?: Maybe<StatLeadAggregation>;
  status?: Maybe<Scalars['String']['output']>;
  sub_industry?: Maybe<Scalars['String']['output']>;
  time_in_pipeline?: Maybe<Scalars['Int']['output']>;
  timezone?: Maybe<Scalars['String']['output']>;
  /** Returns the timezone based on the lead's state. Returns EST if not recognized. */
  timezone_by_state?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  /** return the label of the upcoming activity */
  upcoming_activity_label?: Maybe<Scalars['String']['output']>;
  /** Returns the upcoming events on this lead */
  upcoming_events?: Maybe<Array<Maybe<ScheduleItem>>>;
  updated_at: Scalars['DateTime']['output'];
  visible_custom_fields?: Maybe<Array<Maybe<CustomField>>>;
  zip?: Maybe<Scalars['String']['output']>;
};


export type LeadAlternate_ContactsArgs = {
  after?: InputMaybe<ContactWhereUniqueInput>;
  before?: InputMaybe<ContactWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ContactOrderByWithRelationInput>>;
};


export type LeadAssociate_ChildrenArgs = {
  after?: InputMaybe<LeadWhereUniqueInput>;
  before?: InputMaybe<LeadWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type LeadAssociate_Group_Lead_ActivitiesArgs = {
  after?: InputMaybe<LeadActivityWhereUniqueInput>;
  before?: InputMaybe<LeadActivityWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type LeadAssociate_Lead_HistoriesArgs = {
  after?: InputMaybe<AssociationHistoryWhereUniqueInput>;
  before?: InputMaybe<AssociationHistoryWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type LeadContact_RequestsArgs = {
  after?: InputMaybe<ContactRequestWhereUniqueInput>;
  before?: InputMaybe<ContactRequestWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type LeadCustom_FieldsArgs = {
  after?: InputMaybe<CustomFieldWhereUniqueInput>;
  before?: InputMaybe<CustomFieldWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type LeadCustom_Queue_ItemsArgs = {
  after?: InputMaybe<CustomQueueItemWhereUniqueInput>;
  before?: InputMaybe<CustomQueueItemWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type LeadFavorited_By_UsersArgs = {
  after?: InputMaybe<UserWhereUniqueInput>;
  before?: InputMaybe<UserWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type LeadLead_ActivitiesArgs = {
  after?: InputMaybe<LeadActivityWhereUniqueInput>;
  before?: InputMaybe<LeadActivityWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type LeadLead_Assignment_HistoryArgs = {
  after?: InputMaybe<LeadAssignmentHistoryWhereUniqueInput>;
  before?: InputMaybe<LeadAssignmentHistoryWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type LeadLead_IntentArgs = {
  after?: InputMaybe<LeadIntentWhereUniqueInput>;
  before?: InputMaybe<LeadIntentWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type LeadPrimary_Lead_HistoriesArgs = {
  after?: InputMaybe<AssociationHistoryWhereUniqueInput>;
  before?: InputMaybe<AssociationHistoryWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type LeadRouting_HistoryArgs = {
  after?: InputMaybe<RoutingHistoryWhereUniqueInput>;
  before?: InputMaybe<RoutingHistoryWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type LeadSaleArgs = {
  after?: InputMaybe<SaleWhereUniqueInput>;
  before?: InputMaybe<SaleWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type LeadSale_CyclesArgs = {
  after?: InputMaybe<SaleCycleWhereUniqueInput>;
  before?: InputMaybe<SaleCycleWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type LeadSibling_Lead_HistoriesArgs = {
  after?: InputMaybe<AssociationHistoryWhereUniqueInput>;
  before?: InputMaybe<AssociationHistoryWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type LeadStat_ItemsArgs = {
  after?: InputMaybe<StatItemAggregationWhereUniqueInput>;
  before?: InputMaybe<StatItemAggregationWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type LeadActivity = {
  __typename?: 'LeadActivity';
  associate_child?: Maybe<Lead>;
  associate_child_id?: Maybe<Scalars['String']['output']>;
  associate_group_ids: Array<Scalars['String']['output']>;
  associate_group_leads: Array<Lead>;
  associated_action?: Maybe<Scalars['String']['output']>;
  automated_campaign?: Maybe<AutomatedCampaign>;
  /** true if activity is related to a call */
  call_activity?: Maybe<Scalars['Boolean']['output']>;
  call_completed_with?: Maybe<Callresult>;
  campaign_event?: Maybe<ScheduleItem>;
  channel?: Maybe<Channel>;
  communication_type?: Maybe<Communication_Type>;
  /** the call results with correct formatting for displaying on the front end */
  computed_call_completed_with?: Maybe<Scalars['String']['output']>;
  /** returns call history formatted for display on the frontend */
  computed_call_history_theme: LeadActivityComputedTheme;
  /** Used for the lead history card */
  computed_title?: Maybe<Scalars['String']['output']>;
  /** Cold call, SCB, Decision, RS Demo, FU Demo, Inbounc, NSCB */
  computed_type?: Maybe<Scalars['String']['output']>;
  conference?: Maybe<Conference>;
  conference_id?: Maybe<Scalars['String']['output']>;
  created_at: Scalars['DateTime']['output'];
  curr_lead_val?: Maybe<Scalars['Float']['output']>;
  current_implied_action?: Maybe<Hingepoint>;
  customer_number?: Maybe<Scalars['String']['output']>;
  customer_number_country_code?: Maybe<Scalars['String']['output']>;
  cycle_num?: Maybe<Scalars['Int']['output']>;
  dedupe_migration: Scalars['Boolean']['output'];
  disposition?: Maybe<Scalars['Boolean']['output']>;
  disposition_id?: Maybe<Scalars['String']['output']>;
  email_item?: Maybe<EmailItem>;
  from_action?: Maybe<Scalars['String']['output']>;
  from_callback_type?: Maybe<CallbackType>;
  from_intent_type?: Maybe<IntentType>;
  html: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
  ignore_sequences: Scalars['Boolean']['output'];
  initial_days_set_out?: Maybe<Scalars['Float']['output']>;
  intent_type?: Maybe<Scalars['String']['output']>;
  is_last_activity?: Maybe<Scalars['Boolean']['output']>;
  is_resting_adjustment: Scalars['Boolean']['output'];
  lead: Lead;
  lead_assignment_history_change?: Maybe<LeadAssignmentHistory>;
  lead_assignment_history_id?: Maybe<Scalars['String']['output']>;
  lead_id: Scalars['String']['output'];
  local_number?: Maybe<TwilioNumber>;
  next_action?: Maybe<Scalars['String']['output']>;
  next_callback_type?: Maybe<CallbackType>;
  notes?: Maybe<Scalars['String']['output']>;
  organization?: Maybe<Organization>;
  organization_id?: Maybe<Scalars['String']['output']>;
  previous_lead_val?: Maybe<Scalars['Float']['output']>;
  published?: Maybe<Scalars['Boolean']['output']>;
  related_disposition?: Maybe<Disposition>;
  resting_date_on_recycle?: Maybe<Scalars['DateTime']['output']>;
  resting_type_on_recycle?: Maybe<Restingtype>;
  sale?: Maybe<Sale>;
  sale_cycle?: Maybe<SaleCycle>;
  schedule_item?: Maybe<ScheduleItem>;
  schedule_item_id?: Maybe<Scalars['String']['output']>;
  site?: Maybe<Site>;
  site_id?: Maybe<Scalars['String']['output']>;
  skip_reason?: Maybe<Scalars['String']['output']>;
  SMSItem?: Maybe<SmsItem>;
  source_type?: Maybe<Scalars['String']['output']>;
  stat_item_aggregation?: Maybe<StatItemAggregation>;
  stat_item_aggregation_pipeline?: Maybe<StatItemAggregation>;
  team?: Maybe<Team>;
  team_id?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  twilio_action?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  updated_at: Scalars['DateTime']['output'];
  user?: Maybe<User>;
  user_id?: Maybe<Scalars['String']['output']>;
  user_role?: Maybe<Role>;
};


export type LeadActivityAssociate_Group_LeadsArgs = {
  after?: InputMaybe<LeadWhereUniqueInput>;
  before?: InputMaybe<LeadWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** computed field that defines styles for the updated lead history card */
export type LeadActivityComputedTheme = {
  __typename?: 'leadActivityComputedTheme';
  call_result_theme?: Maybe<LeadActivityThemeType>;
  completed_with_theme?: Maybe<LeadActivityThemeType>;
};

export type LeadActivityCreateassociate_Group_IdsInput = {
  set?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type LeadActivityCreateManyAssociate_ChildInput = {
  associate_group_ids?: InputMaybe<LeadActivityCreateManyassociate_Group_IdsInput>;
  associated_action?: InputMaybe<Scalars['String']['input']>;
  automated_campaign_id?: InputMaybe<Scalars['String']['input']>;
  call_completed_with?: InputMaybe<Callresult>;
  call_result_deleted?: InputMaybe<Scalars['Boolean']['input']>;
  campaign_event_id?: InputMaybe<Scalars['String']['input']>;
  channel?: InputMaybe<Channel>;
  communication_type?: InputMaybe<Communication_Type>;
  conference_id?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  curr_lead_val?: InputMaybe<Scalars['Float']['input']>;
  current_implied_action?: InputMaybe<Hingepoint>;
  customer_number?: InputMaybe<Scalars['String']['input']>;
  customer_number_country_code?: InputMaybe<Scalars['String']['input']>;
  cycle_num?: InputMaybe<Scalars['Int']['input']>;
  dedupe_migration?: InputMaybe<Scalars['Boolean']['input']>;
  disposition_id?: InputMaybe<Scalars['String']['input']>;
  from_action?: InputMaybe<Scalars['String']['input']>;
  from_callback_type?: InputMaybe<CallbackType>;
  from_intent_type?: InputMaybe<IntentType>;
  html?: InputMaybe<Scalars['Boolean']['input']>;
  hubspot_email_engagement_id?: InputMaybe<Scalars['String']['input']>;
  hubspot_id?: InputMaybe<Scalars['String']['input']>;
  hubspot_note_engagement_id?: InputMaybe<Scalars['String']['input']>;
  hubspot_owner_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  ignore_sequences?: InputMaybe<Scalars['Boolean']['input']>;
  initial_days_set_out?: InputMaybe<Scalars['Float']['input']>;
  is_resting_adjustment?: InputMaybe<Scalars['Boolean']['input']>;
  lead_assignment_history_id?: InputMaybe<Scalars['String']['input']>;
  lead_current_sequence_id?: InputMaybe<Scalars['String']['input']>;
  lead_current_step_id?: InputMaybe<Scalars['String']['input']>;
  lead_id: Scalars['String']['input'];
  local_number_id?: InputMaybe<Scalars['String']['input']>;
  next_action?: InputMaybe<Scalars['String']['input']>;
  next_callback_type?: InputMaybe<CallbackType>;
  notes?: InputMaybe<Scalars['String']['input']>;
  organization_id?: InputMaybe<Scalars['String']['input']>;
  previous_lead_val?: InputMaybe<Scalars['Float']['input']>;
  published?: InputMaybe<Scalars['Boolean']['input']>;
  resting_date_on_recycle?: InputMaybe<Scalars['DateTime']['input']>;
  resting_type_on_recycle?: InputMaybe<Restingtype>;
  schedule_item_id?: InputMaybe<Scalars['String']['input']>;
  site_id?: InputMaybe<Scalars['String']['input']>;
  skip_reason?: InputMaybe<Scalars['String']['input']>;
  team_id?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  twilio_action?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
  user_role?: InputMaybe<Role>;
};

export type LeadActivityCreateManyAssociate_ChildInputEnvelope = {
  data?: InputMaybe<Array<LeadActivityCreateManyAssociate_ChildInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type LeadActivityCreateManyassociate_Group_IdsInput = {
  set?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type LeadActivityCreateManyAutomated_CampaignInput = {
  associate_child_id?: InputMaybe<Scalars['String']['input']>;
  associate_group_ids?: InputMaybe<LeadActivityCreateManyassociate_Group_IdsInput>;
  associated_action?: InputMaybe<Scalars['String']['input']>;
  call_completed_with?: InputMaybe<Callresult>;
  call_result_deleted?: InputMaybe<Scalars['Boolean']['input']>;
  campaign_event_id?: InputMaybe<Scalars['String']['input']>;
  channel?: InputMaybe<Channel>;
  communication_type?: InputMaybe<Communication_Type>;
  conference_id?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  curr_lead_val?: InputMaybe<Scalars['Float']['input']>;
  current_implied_action?: InputMaybe<Hingepoint>;
  customer_number?: InputMaybe<Scalars['String']['input']>;
  customer_number_country_code?: InputMaybe<Scalars['String']['input']>;
  cycle_num?: InputMaybe<Scalars['Int']['input']>;
  dedupe_migration?: InputMaybe<Scalars['Boolean']['input']>;
  disposition_id?: InputMaybe<Scalars['String']['input']>;
  from_action?: InputMaybe<Scalars['String']['input']>;
  from_callback_type?: InputMaybe<CallbackType>;
  from_intent_type?: InputMaybe<IntentType>;
  html?: InputMaybe<Scalars['Boolean']['input']>;
  hubspot_email_engagement_id?: InputMaybe<Scalars['String']['input']>;
  hubspot_id?: InputMaybe<Scalars['String']['input']>;
  hubspot_note_engagement_id?: InputMaybe<Scalars['String']['input']>;
  hubspot_owner_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  ignore_sequences?: InputMaybe<Scalars['Boolean']['input']>;
  initial_days_set_out?: InputMaybe<Scalars['Float']['input']>;
  is_resting_adjustment?: InputMaybe<Scalars['Boolean']['input']>;
  lead_assignment_history_id?: InputMaybe<Scalars['String']['input']>;
  lead_current_sequence_id?: InputMaybe<Scalars['String']['input']>;
  lead_current_step_id?: InputMaybe<Scalars['String']['input']>;
  lead_id: Scalars['String']['input'];
  local_number_id?: InputMaybe<Scalars['String']['input']>;
  next_action?: InputMaybe<Scalars['String']['input']>;
  next_callback_type?: InputMaybe<CallbackType>;
  notes?: InputMaybe<Scalars['String']['input']>;
  organization_id?: InputMaybe<Scalars['String']['input']>;
  previous_lead_val?: InputMaybe<Scalars['Float']['input']>;
  published?: InputMaybe<Scalars['Boolean']['input']>;
  resting_date_on_recycle?: InputMaybe<Scalars['DateTime']['input']>;
  resting_type_on_recycle?: InputMaybe<Restingtype>;
  schedule_item_id?: InputMaybe<Scalars['String']['input']>;
  site_id?: InputMaybe<Scalars['String']['input']>;
  skip_reason?: InputMaybe<Scalars['String']['input']>;
  team_id?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  twilio_action?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
  user_role?: InputMaybe<Role>;
};

export type LeadActivityCreateManyAutomated_CampaignInputEnvelope = {
  data?: InputMaybe<Array<LeadActivityCreateManyAutomated_CampaignInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type LeadActivityCreateManyCampaign_EventInput = {
  associate_child_id?: InputMaybe<Scalars['String']['input']>;
  associate_group_ids?: InputMaybe<LeadActivityCreateManyassociate_Group_IdsInput>;
  associated_action?: InputMaybe<Scalars['String']['input']>;
  automated_campaign_id?: InputMaybe<Scalars['String']['input']>;
  call_completed_with?: InputMaybe<Callresult>;
  call_result_deleted?: InputMaybe<Scalars['Boolean']['input']>;
  channel?: InputMaybe<Channel>;
  communication_type?: InputMaybe<Communication_Type>;
  conference_id?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  curr_lead_val?: InputMaybe<Scalars['Float']['input']>;
  current_implied_action?: InputMaybe<Hingepoint>;
  customer_number?: InputMaybe<Scalars['String']['input']>;
  customer_number_country_code?: InputMaybe<Scalars['String']['input']>;
  cycle_num?: InputMaybe<Scalars['Int']['input']>;
  dedupe_migration?: InputMaybe<Scalars['Boolean']['input']>;
  disposition_id?: InputMaybe<Scalars['String']['input']>;
  from_action?: InputMaybe<Scalars['String']['input']>;
  from_callback_type?: InputMaybe<CallbackType>;
  from_intent_type?: InputMaybe<IntentType>;
  html?: InputMaybe<Scalars['Boolean']['input']>;
  hubspot_email_engagement_id?: InputMaybe<Scalars['String']['input']>;
  hubspot_id?: InputMaybe<Scalars['String']['input']>;
  hubspot_note_engagement_id?: InputMaybe<Scalars['String']['input']>;
  hubspot_owner_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  ignore_sequences?: InputMaybe<Scalars['Boolean']['input']>;
  initial_days_set_out?: InputMaybe<Scalars['Float']['input']>;
  is_resting_adjustment?: InputMaybe<Scalars['Boolean']['input']>;
  lead_assignment_history_id?: InputMaybe<Scalars['String']['input']>;
  lead_current_sequence_id?: InputMaybe<Scalars['String']['input']>;
  lead_current_step_id?: InputMaybe<Scalars['String']['input']>;
  lead_id: Scalars['String']['input'];
  local_number_id?: InputMaybe<Scalars['String']['input']>;
  next_action?: InputMaybe<Scalars['String']['input']>;
  next_callback_type?: InputMaybe<CallbackType>;
  notes?: InputMaybe<Scalars['String']['input']>;
  organization_id?: InputMaybe<Scalars['String']['input']>;
  previous_lead_val?: InputMaybe<Scalars['Float']['input']>;
  published?: InputMaybe<Scalars['Boolean']['input']>;
  resting_date_on_recycle?: InputMaybe<Scalars['DateTime']['input']>;
  resting_type_on_recycle?: InputMaybe<Restingtype>;
  schedule_item_id?: InputMaybe<Scalars['String']['input']>;
  site_id?: InputMaybe<Scalars['String']['input']>;
  skip_reason?: InputMaybe<Scalars['String']['input']>;
  team_id?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  twilio_action?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
  user_role?: InputMaybe<Role>;
};

export type LeadActivityCreateManyCampaign_EventInputEnvelope = {
  data?: InputMaybe<Array<LeadActivityCreateManyCampaign_EventInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type LeadActivityCreateManyConferenceInput = {
  associate_child_id?: InputMaybe<Scalars['String']['input']>;
  associate_group_ids?: InputMaybe<LeadActivityCreateManyassociate_Group_IdsInput>;
  associated_action?: InputMaybe<Scalars['String']['input']>;
  automated_campaign_id?: InputMaybe<Scalars['String']['input']>;
  call_completed_with?: InputMaybe<Callresult>;
  call_result_deleted?: InputMaybe<Scalars['Boolean']['input']>;
  campaign_event_id?: InputMaybe<Scalars['String']['input']>;
  channel?: InputMaybe<Channel>;
  communication_type?: InputMaybe<Communication_Type>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  curr_lead_val?: InputMaybe<Scalars['Float']['input']>;
  current_implied_action?: InputMaybe<Hingepoint>;
  customer_number?: InputMaybe<Scalars['String']['input']>;
  customer_number_country_code?: InputMaybe<Scalars['String']['input']>;
  cycle_num?: InputMaybe<Scalars['Int']['input']>;
  dedupe_migration?: InputMaybe<Scalars['Boolean']['input']>;
  disposition_id?: InputMaybe<Scalars['String']['input']>;
  from_action?: InputMaybe<Scalars['String']['input']>;
  from_callback_type?: InputMaybe<CallbackType>;
  from_intent_type?: InputMaybe<IntentType>;
  html?: InputMaybe<Scalars['Boolean']['input']>;
  hubspot_email_engagement_id?: InputMaybe<Scalars['String']['input']>;
  hubspot_id?: InputMaybe<Scalars['String']['input']>;
  hubspot_note_engagement_id?: InputMaybe<Scalars['String']['input']>;
  hubspot_owner_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  ignore_sequences?: InputMaybe<Scalars['Boolean']['input']>;
  initial_days_set_out?: InputMaybe<Scalars['Float']['input']>;
  is_resting_adjustment?: InputMaybe<Scalars['Boolean']['input']>;
  lead_assignment_history_id?: InputMaybe<Scalars['String']['input']>;
  lead_current_sequence_id?: InputMaybe<Scalars['String']['input']>;
  lead_current_step_id?: InputMaybe<Scalars['String']['input']>;
  lead_id: Scalars['String']['input'];
  local_number_id?: InputMaybe<Scalars['String']['input']>;
  next_action?: InputMaybe<Scalars['String']['input']>;
  next_callback_type?: InputMaybe<CallbackType>;
  notes?: InputMaybe<Scalars['String']['input']>;
  organization_id?: InputMaybe<Scalars['String']['input']>;
  previous_lead_val?: InputMaybe<Scalars['Float']['input']>;
  published?: InputMaybe<Scalars['Boolean']['input']>;
  resting_date_on_recycle?: InputMaybe<Scalars['DateTime']['input']>;
  resting_type_on_recycle?: InputMaybe<Restingtype>;
  schedule_item_id?: InputMaybe<Scalars['String']['input']>;
  site_id?: InputMaybe<Scalars['String']['input']>;
  skip_reason?: InputMaybe<Scalars['String']['input']>;
  team_id?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  twilio_action?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
  user_role?: InputMaybe<Role>;
};

export type LeadActivityCreateManyConferenceInputEnvelope = {
  data?: InputMaybe<Array<LeadActivityCreateManyConferenceInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type LeadActivityCreateManyLead_Current_SequenceInput = {
  associate_child_id?: InputMaybe<Scalars['String']['input']>;
  associate_group_ids?: InputMaybe<LeadActivityCreateManyassociate_Group_IdsInput>;
  associated_action?: InputMaybe<Scalars['String']['input']>;
  automated_campaign_id?: InputMaybe<Scalars['String']['input']>;
  call_completed_with?: InputMaybe<Callresult>;
  call_result_deleted?: InputMaybe<Scalars['Boolean']['input']>;
  campaign_event_id?: InputMaybe<Scalars['String']['input']>;
  channel?: InputMaybe<Channel>;
  communication_type?: InputMaybe<Communication_Type>;
  conference_id?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  curr_lead_val?: InputMaybe<Scalars['Float']['input']>;
  current_implied_action?: InputMaybe<Hingepoint>;
  customer_number?: InputMaybe<Scalars['String']['input']>;
  customer_number_country_code?: InputMaybe<Scalars['String']['input']>;
  cycle_num?: InputMaybe<Scalars['Int']['input']>;
  dedupe_migration?: InputMaybe<Scalars['Boolean']['input']>;
  disposition_id?: InputMaybe<Scalars['String']['input']>;
  from_action?: InputMaybe<Scalars['String']['input']>;
  from_callback_type?: InputMaybe<CallbackType>;
  from_intent_type?: InputMaybe<IntentType>;
  html?: InputMaybe<Scalars['Boolean']['input']>;
  hubspot_email_engagement_id?: InputMaybe<Scalars['String']['input']>;
  hubspot_id?: InputMaybe<Scalars['String']['input']>;
  hubspot_note_engagement_id?: InputMaybe<Scalars['String']['input']>;
  hubspot_owner_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  ignore_sequences?: InputMaybe<Scalars['Boolean']['input']>;
  initial_days_set_out?: InputMaybe<Scalars['Float']['input']>;
  is_resting_adjustment?: InputMaybe<Scalars['Boolean']['input']>;
  lead_assignment_history_id?: InputMaybe<Scalars['String']['input']>;
  lead_current_step_id?: InputMaybe<Scalars['String']['input']>;
  lead_id: Scalars['String']['input'];
  local_number_id?: InputMaybe<Scalars['String']['input']>;
  next_action?: InputMaybe<Scalars['String']['input']>;
  next_callback_type?: InputMaybe<CallbackType>;
  notes?: InputMaybe<Scalars['String']['input']>;
  organization_id?: InputMaybe<Scalars['String']['input']>;
  previous_lead_val?: InputMaybe<Scalars['Float']['input']>;
  published?: InputMaybe<Scalars['Boolean']['input']>;
  resting_date_on_recycle?: InputMaybe<Scalars['DateTime']['input']>;
  resting_type_on_recycle?: InputMaybe<Restingtype>;
  schedule_item_id?: InputMaybe<Scalars['String']['input']>;
  site_id?: InputMaybe<Scalars['String']['input']>;
  skip_reason?: InputMaybe<Scalars['String']['input']>;
  team_id?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  twilio_action?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
  user_role?: InputMaybe<Role>;
};

export type LeadActivityCreateManyLead_Current_SequenceInputEnvelope = {
  data?: InputMaybe<Array<LeadActivityCreateManyLead_Current_SequenceInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type LeadActivityCreateManyLead_Current_StepInput = {
  associate_child_id?: InputMaybe<Scalars['String']['input']>;
  associate_group_ids?: InputMaybe<LeadActivityCreateManyassociate_Group_IdsInput>;
  associated_action?: InputMaybe<Scalars['String']['input']>;
  automated_campaign_id?: InputMaybe<Scalars['String']['input']>;
  call_completed_with?: InputMaybe<Callresult>;
  call_result_deleted?: InputMaybe<Scalars['Boolean']['input']>;
  campaign_event_id?: InputMaybe<Scalars['String']['input']>;
  channel?: InputMaybe<Channel>;
  communication_type?: InputMaybe<Communication_Type>;
  conference_id?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  curr_lead_val?: InputMaybe<Scalars['Float']['input']>;
  current_implied_action?: InputMaybe<Hingepoint>;
  customer_number?: InputMaybe<Scalars['String']['input']>;
  customer_number_country_code?: InputMaybe<Scalars['String']['input']>;
  cycle_num?: InputMaybe<Scalars['Int']['input']>;
  dedupe_migration?: InputMaybe<Scalars['Boolean']['input']>;
  disposition_id?: InputMaybe<Scalars['String']['input']>;
  from_action?: InputMaybe<Scalars['String']['input']>;
  from_callback_type?: InputMaybe<CallbackType>;
  from_intent_type?: InputMaybe<IntentType>;
  html?: InputMaybe<Scalars['Boolean']['input']>;
  hubspot_email_engagement_id?: InputMaybe<Scalars['String']['input']>;
  hubspot_id?: InputMaybe<Scalars['String']['input']>;
  hubspot_note_engagement_id?: InputMaybe<Scalars['String']['input']>;
  hubspot_owner_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  ignore_sequences?: InputMaybe<Scalars['Boolean']['input']>;
  initial_days_set_out?: InputMaybe<Scalars['Float']['input']>;
  is_resting_adjustment?: InputMaybe<Scalars['Boolean']['input']>;
  lead_assignment_history_id?: InputMaybe<Scalars['String']['input']>;
  lead_current_sequence_id?: InputMaybe<Scalars['String']['input']>;
  lead_id: Scalars['String']['input'];
  local_number_id?: InputMaybe<Scalars['String']['input']>;
  next_action?: InputMaybe<Scalars['String']['input']>;
  next_callback_type?: InputMaybe<CallbackType>;
  notes?: InputMaybe<Scalars['String']['input']>;
  organization_id?: InputMaybe<Scalars['String']['input']>;
  previous_lead_val?: InputMaybe<Scalars['Float']['input']>;
  published?: InputMaybe<Scalars['Boolean']['input']>;
  resting_date_on_recycle?: InputMaybe<Scalars['DateTime']['input']>;
  resting_type_on_recycle?: InputMaybe<Restingtype>;
  schedule_item_id?: InputMaybe<Scalars['String']['input']>;
  site_id?: InputMaybe<Scalars['String']['input']>;
  skip_reason?: InputMaybe<Scalars['String']['input']>;
  team_id?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  twilio_action?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
  user_role?: InputMaybe<Role>;
};

export type LeadActivityCreateManyLead_Current_StepInputEnvelope = {
  data?: InputMaybe<Array<LeadActivityCreateManyLead_Current_StepInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type LeadActivityCreateManyLeadInput = {
  associate_child_id?: InputMaybe<Scalars['String']['input']>;
  associate_group_ids?: InputMaybe<LeadActivityCreateManyassociate_Group_IdsInput>;
  associated_action?: InputMaybe<Scalars['String']['input']>;
  automated_campaign_id?: InputMaybe<Scalars['String']['input']>;
  call_completed_with?: InputMaybe<Callresult>;
  call_result_deleted?: InputMaybe<Scalars['Boolean']['input']>;
  campaign_event_id?: InputMaybe<Scalars['String']['input']>;
  channel?: InputMaybe<Channel>;
  communication_type?: InputMaybe<Communication_Type>;
  conference_id?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  curr_lead_val?: InputMaybe<Scalars['Float']['input']>;
  current_implied_action?: InputMaybe<Hingepoint>;
  customer_number?: InputMaybe<Scalars['String']['input']>;
  customer_number_country_code?: InputMaybe<Scalars['String']['input']>;
  cycle_num?: InputMaybe<Scalars['Int']['input']>;
  dedupe_migration?: InputMaybe<Scalars['Boolean']['input']>;
  disposition_id?: InputMaybe<Scalars['String']['input']>;
  from_action?: InputMaybe<Scalars['String']['input']>;
  from_callback_type?: InputMaybe<CallbackType>;
  from_intent_type?: InputMaybe<IntentType>;
  html?: InputMaybe<Scalars['Boolean']['input']>;
  hubspot_email_engagement_id?: InputMaybe<Scalars['String']['input']>;
  hubspot_id?: InputMaybe<Scalars['String']['input']>;
  hubspot_note_engagement_id?: InputMaybe<Scalars['String']['input']>;
  hubspot_owner_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  ignore_sequences?: InputMaybe<Scalars['Boolean']['input']>;
  initial_days_set_out?: InputMaybe<Scalars['Float']['input']>;
  is_resting_adjustment?: InputMaybe<Scalars['Boolean']['input']>;
  lead_assignment_history_id?: InputMaybe<Scalars['String']['input']>;
  lead_current_sequence_id?: InputMaybe<Scalars['String']['input']>;
  lead_current_step_id?: InputMaybe<Scalars['String']['input']>;
  local_number_id?: InputMaybe<Scalars['String']['input']>;
  next_action?: InputMaybe<Scalars['String']['input']>;
  next_callback_type?: InputMaybe<CallbackType>;
  notes?: InputMaybe<Scalars['String']['input']>;
  organization_id?: InputMaybe<Scalars['String']['input']>;
  previous_lead_val?: InputMaybe<Scalars['Float']['input']>;
  published?: InputMaybe<Scalars['Boolean']['input']>;
  resting_date_on_recycle?: InputMaybe<Scalars['DateTime']['input']>;
  resting_type_on_recycle?: InputMaybe<Restingtype>;
  schedule_item_id?: InputMaybe<Scalars['String']['input']>;
  site_id?: InputMaybe<Scalars['String']['input']>;
  skip_reason?: InputMaybe<Scalars['String']['input']>;
  team_id?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  twilio_action?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
  user_role?: InputMaybe<Role>;
};

export type LeadActivityCreateManyLeadInputEnvelope = {
  data?: InputMaybe<Array<LeadActivityCreateManyLeadInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type LeadActivityCreateManyLocal_NumberInput = {
  associate_child_id?: InputMaybe<Scalars['String']['input']>;
  associate_group_ids?: InputMaybe<LeadActivityCreateManyassociate_Group_IdsInput>;
  associated_action?: InputMaybe<Scalars['String']['input']>;
  automated_campaign_id?: InputMaybe<Scalars['String']['input']>;
  call_completed_with?: InputMaybe<Callresult>;
  call_result_deleted?: InputMaybe<Scalars['Boolean']['input']>;
  campaign_event_id?: InputMaybe<Scalars['String']['input']>;
  channel?: InputMaybe<Channel>;
  communication_type?: InputMaybe<Communication_Type>;
  conference_id?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  curr_lead_val?: InputMaybe<Scalars['Float']['input']>;
  current_implied_action?: InputMaybe<Hingepoint>;
  customer_number?: InputMaybe<Scalars['String']['input']>;
  customer_number_country_code?: InputMaybe<Scalars['String']['input']>;
  cycle_num?: InputMaybe<Scalars['Int']['input']>;
  dedupe_migration?: InputMaybe<Scalars['Boolean']['input']>;
  disposition_id?: InputMaybe<Scalars['String']['input']>;
  from_action?: InputMaybe<Scalars['String']['input']>;
  from_callback_type?: InputMaybe<CallbackType>;
  from_intent_type?: InputMaybe<IntentType>;
  html?: InputMaybe<Scalars['Boolean']['input']>;
  hubspot_email_engagement_id?: InputMaybe<Scalars['String']['input']>;
  hubspot_id?: InputMaybe<Scalars['String']['input']>;
  hubspot_note_engagement_id?: InputMaybe<Scalars['String']['input']>;
  hubspot_owner_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  ignore_sequences?: InputMaybe<Scalars['Boolean']['input']>;
  initial_days_set_out?: InputMaybe<Scalars['Float']['input']>;
  is_resting_adjustment?: InputMaybe<Scalars['Boolean']['input']>;
  lead_assignment_history_id?: InputMaybe<Scalars['String']['input']>;
  lead_current_sequence_id?: InputMaybe<Scalars['String']['input']>;
  lead_current_step_id?: InputMaybe<Scalars['String']['input']>;
  lead_id: Scalars['String']['input'];
  next_action?: InputMaybe<Scalars['String']['input']>;
  next_callback_type?: InputMaybe<CallbackType>;
  notes?: InputMaybe<Scalars['String']['input']>;
  organization_id?: InputMaybe<Scalars['String']['input']>;
  previous_lead_val?: InputMaybe<Scalars['Float']['input']>;
  published?: InputMaybe<Scalars['Boolean']['input']>;
  resting_date_on_recycle?: InputMaybe<Scalars['DateTime']['input']>;
  resting_type_on_recycle?: InputMaybe<Restingtype>;
  schedule_item_id?: InputMaybe<Scalars['String']['input']>;
  site_id?: InputMaybe<Scalars['String']['input']>;
  skip_reason?: InputMaybe<Scalars['String']['input']>;
  team_id?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  twilio_action?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
  user_role?: InputMaybe<Role>;
};

export type LeadActivityCreateManyLocal_NumberInputEnvelope = {
  data?: InputMaybe<Array<LeadActivityCreateManyLocal_NumberInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type LeadActivityCreateManyOrganizationInput = {
  associate_child_id?: InputMaybe<Scalars['String']['input']>;
  associate_group_ids?: InputMaybe<LeadActivityCreateManyassociate_Group_IdsInput>;
  associated_action?: InputMaybe<Scalars['String']['input']>;
  automated_campaign_id?: InputMaybe<Scalars['String']['input']>;
  call_completed_with?: InputMaybe<Callresult>;
  call_result_deleted?: InputMaybe<Scalars['Boolean']['input']>;
  campaign_event_id?: InputMaybe<Scalars['String']['input']>;
  channel?: InputMaybe<Channel>;
  communication_type?: InputMaybe<Communication_Type>;
  conference_id?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  curr_lead_val?: InputMaybe<Scalars['Float']['input']>;
  current_implied_action?: InputMaybe<Hingepoint>;
  customer_number?: InputMaybe<Scalars['String']['input']>;
  customer_number_country_code?: InputMaybe<Scalars['String']['input']>;
  cycle_num?: InputMaybe<Scalars['Int']['input']>;
  dedupe_migration?: InputMaybe<Scalars['Boolean']['input']>;
  disposition_id?: InputMaybe<Scalars['String']['input']>;
  from_action?: InputMaybe<Scalars['String']['input']>;
  from_callback_type?: InputMaybe<CallbackType>;
  from_intent_type?: InputMaybe<IntentType>;
  html?: InputMaybe<Scalars['Boolean']['input']>;
  hubspot_email_engagement_id?: InputMaybe<Scalars['String']['input']>;
  hubspot_id?: InputMaybe<Scalars['String']['input']>;
  hubspot_note_engagement_id?: InputMaybe<Scalars['String']['input']>;
  hubspot_owner_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  ignore_sequences?: InputMaybe<Scalars['Boolean']['input']>;
  initial_days_set_out?: InputMaybe<Scalars['Float']['input']>;
  is_resting_adjustment?: InputMaybe<Scalars['Boolean']['input']>;
  lead_assignment_history_id?: InputMaybe<Scalars['String']['input']>;
  lead_current_sequence_id?: InputMaybe<Scalars['String']['input']>;
  lead_current_step_id?: InputMaybe<Scalars['String']['input']>;
  lead_id: Scalars['String']['input'];
  local_number_id?: InputMaybe<Scalars['String']['input']>;
  next_action?: InputMaybe<Scalars['String']['input']>;
  next_callback_type?: InputMaybe<CallbackType>;
  notes?: InputMaybe<Scalars['String']['input']>;
  previous_lead_val?: InputMaybe<Scalars['Float']['input']>;
  published?: InputMaybe<Scalars['Boolean']['input']>;
  resting_date_on_recycle?: InputMaybe<Scalars['DateTime']['input']>;
  resting_type_on_recycle?: InputMaybe<Restingtype>;
  schedule_item_id?: InputMaybe<Scalars['String']['input']>;
  site_id?: InputMaybe<Scalars['String']['input']>;
  skip_reason?: InputMaybe<Scalars['String']['input']>;
  team_id?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  twilio_action?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
  user_role?: InputMaybe<Role>;
};

export type LeadActivityCreateManyOrganizationInputEnvelope = {
  data?: InputMaybe<Array<LeadActivityCreateManyOrganizationInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type LeadActivityCreateManyRelated_DispositionInput = {
  associate_child_id?: InputMaybe<Scalars['String']['input']>;
  associate_group_ids?: InputMaybe<LeadActivityCreateManyassociate_Group_IdsInput>;
  associated_action?: InputMaybe<Scalars['String']['input']>;
  automated_campaign_id?: InputMaybe<Scalars['String']['input']>;
  call_completed_with?: InputMaybe<Callresult>;
  call_result_deleted?: InputMaybe<Scalars['Boolean']['input']>;
  campaign_event_id?: InputMaybe<Scalars['String']['input']>;
  channel?: InputMaybe<Channel>;
  communication_type?: InputMaybe<Communication_Type>;
  conference_id?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  curr_lead_val?: InputMaybe<Scalars['Float']['input']>;
  current_implied_action?: InputMaybe<Hingepoint>;
  customer_number?: InputMaybe<Scalars['String']['input']>;
  customer_number_country_code?: InputMaybe<Scalars['String']['input']>;
  cycle_num?: InputMaybe<Scalars['Int']['input']>;
  dedupe_migration?: InputMaybe<Scalars['Boolean']['input']>;
  from_action?: InputMaybe<Scalars['String']['input']>;
  from_callback_type?: InputMaybe<CallbackType>;
  from_intent_type?: InputMaybe<IntentType>;
  html?: InputMaybe<Scalars['Boolean']['input']>;
  hubspot_email_engagement_id?: InputMaybe<Scalars['String']['input']>;
  hubspot_id?: InputMaybe<Scalars['String']['input']>;
  hubspot_note_engagement_id?: InputMaybe<Scalars['String']['input']>;
  hubspot_owner_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  ignore_sequences?: InputMaybe<Scalars['Boolean']['input']>;
  initial_days_set_out?: InputMaybe<Scalars['Float']['input']>;
  is_resting_adjustment?: InputMaybe<Scalars['Boolean']['input']>;
  lead_assignment_history_id?: InputMaybe<Scalars['String']['input']>;
  lead_current_sequence_id?: InputMaybe<Scalars['String']['input']>;
  lead_current_step_id?: InputMaybe<Scalars['String']['input']>;
  lead_id: Scalars['String']['input'];
  local_number_id?: InputMaybe<Scalars['String']['input']>;
  next_action?: InputMaybe<Scalars['String']['input']>;
  next_callback_type?: InputMaybe<CallbackType>;
  notes?: InputMaybe<Scalars['String']['input']>;
  organization_id?: InputMaybe<Scalars['String']['input']>;
  previous_lead_val?: InputMaybe<Scalars['Float']['input']>;
  published?: InputMaybe<Scalars['Boolean']['input']>;
  resting_date_on_recycle?: InputMaybe<Scalars['DateTime']['input']>;
  resting_type_on_recycle?: InputMaybe<Restingtype>;
  schedule_item_id?: InputMaybe<Scalars['String']['input']>;
  site_id?: InputMaybe<Scalars['String']['input']>;
  skip_reason?: InputMaybe<Scalars['String']['input']>;
  team_id?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  twilio_action?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
  user_role?: InputMaybe<Role>;
};

export type LeadActivityCreateManyRelated_DispositionInputEnvelope = {
  data?: InputMaybe<Array<LeadActivityCreateManyRelated_DispositionInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type LeadActivityCreateManySale_CycleInput = {
  associate_child_id?: InputMaybe<Scalars['String']['input']>;
  associate_group_ids?: InputMaybe<LeadActivityCreateManyassociate_Group_IdsInput>;
  associated_action?: InputMaybe<Scalars['String']['input']>;
  automated_campaign_id?: InputMaybe<Scalars['String']['input']>;
  call_completed_with?: InputMaybe<Callresult>;
  call_result_deleted?: InputMaybe<Scalars['Boolean']['input']>;
  campaign_event_id?: InputMaybe<Scalars['String']['input']>;
  channel?: InputMaybe<Channel>;
  communication_type?: InputMaybe<Communication_Type>;
  conference_id?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  curr_lead_val?: InputMaybe<Scalars['Float']['input']>;
  current_implied_action?: InputMaybe<Hingepoint>;
  customer_number?: InputMaybe<Scalars['String']['input']>;
  customer_number_country_code?: InputMaybe<Scalars['String']['input']>;
  dedupe_migration?: InputMaybe<Scalars['Boolean']['input']>;
  disposition_id?: InputMaybe<Scalars['String']['input']>;
  from_action?: InputMaybe<Scalars['String']['input']>;
  from_callback_type?: InputMaybe<CallbackType>;
  from_intent_type?: InputMaybe<IntentType>;
  html?: InputMaybe<Scalars['Boolean']['input']>;
  hubspot_email_engagement_id?: InputMaybe<Scalars['String']['input']>;
  hubspot_id?: InputMaybe<Scalars['String']['input']>;
  hubspot_note_engagement_id?: InputMaybe<Scalars['String']['input']>;
  hubspot_owner_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  ignore_sequences?: InputMaybe<Scalars['Boolean']['input']>;
  initial_days_set_out?: InputMaybe<Scalars['Float']['input']>;
  is_resting_adjustment?: InputMaybe<Scalars['Boolean']['input']>;
  lead_assignment_history_id?: InputMaybe<Scalars['String']['input']>;
  lead_current_sequence_id?: InputMaybe<Scalars['String']['input']>;
  lead_current_step_id?: InputMaybe<Scalars['String']['input']>;
  local_number_id?: InputMaybe<Scalars['String']['input']>;
  next_action?: InputMaybe<Scalars['String']['input']>;
  next_callback_type?: InputMaybe<CallbackType>;
  notes?: InputMaybe<Scalars['String']['input']>;
  organization_id?: InputMaybe<Scalars['String']['input']>;
  previous_lead_val?: InputMaybe<Scalars['Float']['input']>;
  published?: InputMaybe<Scalars['Boolean']['input']>;
  resting_date_on_recycle?: InputMaybe<Scalars['DateTime']['input']>;
  resting_type_on_recycle?: InputMaybe<Restingtype>;
  schedule_item_id?: InputMaybe<Scalars['String']['input']>;
  site_id?: InputMaybe<Scalars['String']['input']>;
  skip_reason?: InputMaybe<Scalars['String']['input']>;
  team_id?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  twilio_action?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
  user_role?: InputMaybe<Role>;
};

export type LeadActivityCreateManySale_CycleInputEnvelope = {
  data?: InputMaybe<Array<LeadActivityCreateManySale_CycleInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type LeadActivityCreateManySiteInput = {
  associate_child_id?: InputMaybe<Scalars['String']['input']>;
  associate_group_ids?: InputMaybe<LeadActivityCreateManyassociate_Group_IdsInput>;
  associated_action?: InputMaybe<Scalars['String']['input']>;
  automated_campaign_id?: InputMaybe<Scalars['String']['input']>;
  call_completed_with?: InputMaybe<Callresult>;
  call_result_deleted?: InputMaybe<Scalars['Boolean']['input']>;
  campaign_event_id?: InputMaybe<Scalars['String']['input']>;
  channel?: InputMaybe<Channel>;
  communication_type?: InputMaybe<Communication_Type>;
  conference_id?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  curr_lead_val?: InputMaybe<Scalars['Float']['input']>;
  current_implied_action?: InputMaybe<Hingepoint>;
  customer_number?: InputMaybe<Scalars['String']['input']>;
  customer_number_country_code?: InputMaybe<Scalars['String']['input']>;
  cycle_num?: InputMaybe<Scalars['Int']['input']>;
  dedupe_migration?: InputMaybe<Scalars['Boolean']['input']>;
  disposition_id?: InputMaybe<Scalars['String']['input']>;
  from_action?: InputMaybe<Scalars['String']['input']>;
  from_callback_type?: InputMaybe<CallbackType>;
  from_intent_type?: InputMaybe<IntentType>;
  html?: InputMaybe<Scalars['Boolean']['input']>;
  hubspot_email_engagement_id?: InputMaybe<Scalars['String']['input']>;
  hubspot_id?: InputMaybe<Scalars['String']['input']>;
  hubspot_note_engagement_id?: InputMaybe<Scalars['String']['input']>;
  hubspot_owner_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  ignore_sequences?: InputMaybe<Scalars['Boolean']['input']>;
  initial_days_set_out?: InputMaybe<Scalars['Float']['input']>;
  is_resting_adjustment?: InputMaybe<Scalars['Boolean']['input']>;
  lead_assignment_history_id?: InputMaybe<Scalars['String']['input']>;
  lead_current_sequence_id?: InputMaybe<Scalars['String']['input']>;
  lead_current_step_id?: InputMaybe<Scalars['String']['input']>;
  lead_id: Scalars['String']['input'];
  local_number_id?: InputMaybe<Scalars['String']['input']>;
  next_action?: InputMaybe<Scalars['String']['input']>;
  next_callback_type?: InputMaybe<CallbackType>;
  notes?: InputMaybe<Scalars['String']['input']>;
  organization_id?: InputMaybe<Scalars['String']['input']>;
  previous_lead_val?: InputMaybe<Scalars['Float']['input']>;
  published?: InputMaybe<Scalars['Boolean']['input']>;
  resting_date_on_recycle?: InputMaybe<Scalars['DateTime']['input']>;
  resting_type_on_recycle?: InputMaybe<Restingtype>;
  schedule_item_id?: InputMaybe<Scalars['String']['input']>;
  skip_reason?: InputMaybe<Scalars['String']['input']>;
  team_id?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  twilio_action?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
  user_role?: InputMaybe<Role>;
};

export type LeadActivityCreateManySiteInputEnvelope = {
  data?: InputMaybe<Array<LeadActivityCreateManySiteInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type LeadActivityCreateManyTeamInput = {
  associate_child_id?: InputMaybe<Scalars['String']['input']>;
  associate_group_ids?: InputMaybe<LeadActivityCreateManyassociate_Group_IdsInput>;
  associated_action?: InputMaybe<Scalars['String']['input']>;
  automated_campaign_id?: InputMaybe<Scalars['String']['input']>;
  call_completed_with?: InputMaybe<Callresult>;
  call_result_deleted?: InputMaybe<Scalars['Boolean']['input']>;
  campaign_event_id?: InputMaybe<Scalars['String']['input']>;
  channel?: InputMaybe<Channel>;
  communication_type?: InputMaybe<Communication_Type>;
  conference_id?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  curr_lead_val?: InputMaybe<Scalars['Float']['input']>;
  current_implied_action?: InputMaybe<Hingepoint>;
  customer_number?: InputMaybe<Scalars['String']['input']>;
  customer_number_country_code?: InputMaybe<Scalars['String']['input']>;
  cycle_num?: InputMaybe<Scalars['Int']['input']>;
  dedupe_migration?: InputMaybe<Scalars['Boolean']['input']>;
  disposition_id?: InputMaybe<Scalars['String']['input']>;
  from_action?: InputMaybe<Scalars['String']['input']>;
  from_callback_type?: InputMaybe<CallbackType>;
  from_intent_type?: InputMaybe<IntentType>;
  html?: InputMaybe<Scalars['Boolean']['input']>;
  hubspot_email_engagement_id?: InputMaybe<Scalars['String']['input']>;
  hubspot_id?: InputMaybe<Scalars['String']['input']>;
  hubspot_note_engagement_id?: InputMaybe<Scalars['String']['input']>;
  hubspot_owner_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  ignore_sequences?: InputMaybe<Scalars['Boolean']['input']>;
  initial_days_set_out?: InputMaybe<Scalars['Float']['input']>;
  is_resting_adjustment?: InputMaybe<Scalars['Boolean']['input']>;
  lead_assignment_history_id?: InputMaybe<Scalars['String']['input']>;
  lead_current_sequence_id?: InputMaybe<Scalars['String']['input']>;
  lead_current_step_id?: InputMaybe<Scalars['String']['input']>;
  lead_id: Scalars['String']['input'];
  local_number_id?: InputMaybe<Scalars['String']['input']>;
  next_action?: InputMaybe<Scalars['String']['input']>;
  next_callback_type?: InputMaybe<CallbackType>;
  notes?: InputMaybe<Scalars['String']['input']>;
  organization_id?: InputMaybe<Scalars['String']['input']>;
  previous_lead_val?: InputMaybe<Scalars['Float']['input']>;
  published?: InputMaybe<Scalars['Boolean']['input']>;
  resting_date_on_recycle?: InputMaybe<Scalars['DateTime']['input']>;
  resting_type_on_recycle?: InputMaybe<Restingtype>;
  schedule_item_id?: InputMaybe<Scalars['String']['input']>;
  site_id?: InputMaybe<Scalars['String']['input']>;
  skip_reason?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  twilio_action?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
  user_role?: InputMaybe<Role>;
};

export type LeadActivityCreateManyTeamInputEnvelope = {
  data?: InputMaybe<Array<LeadActivityCreateManyTeamInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type LeadActivityCreateManyUserInput = {
  associate_child_id?: InputMaybe<Scalars['String']['input']>;
  associate_group_ids?: InputMaybe<LeadActivityCreateManyassociate_Group_IdsInput>;
  associated_action?: InputMaybe<Scalars['String']['input']>;
  automated_campaign_id?: InputMaybe<Scalars['String']['input']>;
  call_completed_with?: InputMaybe<Callresult>;
  call_result_deleted?: InputMaybe<Scalars['Boolean']['input']>;
  campaign_event_id?: InputMaybe<Scalars['String']['input']>;
  channel?: InputMaybe<Channel>;
  communication_type?: InputMaybe<Communication_Type>;
  conference_id?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  curr_lead_val?: InputMaybe<Scalars['Float']['input']>;
  current_implied_action?: InputMaybe<Hingepoint>;
  customer_number?: InputMaybe<Scalars['String']['input']>;
  customer_number_country_code?: InputMaybe<Scalars['String']['input']>;
  cycle_num?: InputMaybe<Scalars['Int']['input']>;
  dedupe_migration?: InputMaybe<Scalars['Boolean']['input']>;
  disposition_id?: InputMaybe<Scalars['String']['input']>;
  from_action?: InputMaybe<Scalars['String']['input']>;
  from_callback_type?: InputMaybe<CallbackType>;
  from_intent_type?: InputMaybe<IntentType>;
  html?: InputMaybe<Scalars['Boolean']['input']>;
  hubspot_email_engagement_id?: InputMaybe<Scalars['String']['input']>;
  hubspot_id?: InputMaybe<Scalars['String']['input']>;
  hubspot_note_engagement_id?: InputMaybe<Scalars['String']['input']>;
  hubspot_owner_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  ignore_sequences?: InputMaybe<Scalars['Boolean']['input']>;
  initial_days_set_out?: InputMaybe<Scalars['Float']['input']>;
  is_resting_adjustment?: InputMaybe<Scalars['Boolean']['input']>;
  lead_assignment_history_id?: InputMaybe<Scalars['String']['input']>;
  lead_current_sequence_id?: InputMaybe<Scalars['String']['input']>;
  lead_current_step_id?: InputMaybe<Scalars['String']['input']>;
  lead_id: Scalars['String']['input'];
  local_number_id?: InputMaybe<Scalars['String']['input']>;
  next_action?: InputMaybe<Scalars['String']['input']>;
  next_callback_type?: InputMaybe<CallbackType>;
  notes?: InputMaybe<Scalars['String']['input']>;
  organization_id?: InputMaybe<Scalars['String']['input']>;
  previous_lead_val?: InputMaybe<Scalars['Float']['input']>;
  published?: InputMaybe<Scalars['Boolean']['input']>;
  resting_date_on_recycle?: InputMaybe<Scalars['DateTime']['input']>;
  resting_type_on_recycle?: InputMaybe<Restingtype>;
  schedule_item_id?: InputMaybe<Scalars['String']['input']>;
  site_id?: InputMaybe<Scalars['String']['input']>;
  skip_reason?: InputMaybe<Scalars['String']['input']>;
  team_id?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  twilio_action?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user_role?: InputMaybe<Role>;
};

export type LeadActivityCreateManyUserInputEnvelope = {
  data?: InputMaybe<Array<LeadActivityCreateManyUserInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type LeadActivityCreateNestedManyWithoutAssociate_ChildInput = {
  connect?: InputMaybe<Array<LeadActivityWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<LeadActivityCreateOrConnectWithoutAssociate_ChildInput>>;
  create?: InputMaybe<Array<LeadActivityCreateWithoutAssociate_ChildInput>>;
  createMany?: InputMaybe<LeadActivityCreateManyAssociate_ChildInputEnvelope>;
};

export type LeadActivityCreateNestedManyWithoutAssociate_Group_LeadsInput = {
  connect?: InputMaybe<Array<LeadActivityWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<LeadActivityCreateOrConnectWithoutAssociate_Group_LeadsInput>>;
  create?: InputMaybe<Array<LeadActivityCreateWithoutAssociate_Group_LeadsInput>>;
};

export type LeadActivityCreateNestedManyWithoutAutomated_CampaignInput = {
  connect?: InputMaybe<Array<LeadActivityWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<LeadActivityCreateOrConnectWithoutAutomated_CampaignInput>>;
  create?: InputMaybe<Array<LeadActivityCreateWithoutAutomated_CampaignInput>>;
  createMany?: InputMaybe<LeadActivityCreateManyAutomated_CampaignInputEnvelope>;
};

export type LeadActivityCreateNestedManyWithoutCampaign_EventInput = {
  connect?: InputMaybe<Array<LeadActivityWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<LeadActivityCreateOrConnectWithoutCampaign_EventInput>>;
  create?: InputMaybe<Array<LeadActivityCreateWithoutCampaign_EventInput>>;
  createMany?: InputMaybe<LeadActivityCreateManyCampaign_EventInputEnvelope>;
};

export type LeadActivityCreateNestedManyWithoutConferenceInput = {
  connect?: InputMaybe<Array<LeadActivityWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<LeadActivityCreateOrConnectWithoutConferenceInput>>;
  create?: InputMaybe<Array<LeadActivityCreateWithoutConferenceInput>>;
  createMany?: InputMaybe<LeadActivityCreateManyConferenceInputEnvelope>;
};

export type LeadActivityCreateNestedManyWithoutLead_Current_SequenceInput = {
  connect?: InputMaybe<Array<LeadActivityWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<LeadActivityCreateOrConnectWithoutLead_Current_SequenceInput>>;
  create?: InputMaybe<Array<LeadActivityCreateWithoutLead_Current_SequenceInput>>;
  createMany?: InputMaybe<LeadActivityCreateManyLead_Current_SequenceInputEnvelope>;
};

export type LeadActivityCreateNestedManyWithoutLead_Current_StepInput = {
  connect?: InputMaybe<Array<LeadActivityWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<LeadActivityCreateOrConnectWithoutLead_Current_StepInput>>;
  create?: InputMaybe<Array<LeadActivityCreateWithoutLead_Current_StepInput>>;
  createMany?: InputMaybe<LeadActivityCreateManyLead_Current_StepInputEnvelope>;
};

export type LeadActivityCreateNestedManyWithoutLeadInput = {
  connect?: InputMaybe<Array<LeadActivityWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<LeadActivityCreateOrConnectWithoutLeadInput>>;
  create?: InputMaybe<Array<LeadActivityCreateWithoutLeadInput>>;
  createMany?: InputMaybe<LeadActivityCreateManyLeadInputEnvelope>;
};

export type LeadActivityCreateNestedManyWithoutLocal_NumberInput = {
  connect?: InputMaybe<Array<LeadActivityWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<LeadActivityCreateOrConnectWithoutLocal_NumberInput>>;
  create?: InputMaybe<Array<LeadActivityCreateWithoutLocal_NumberInput>>;
  createMany?: InputMaybe<LeadActivityCreateManyLocal_NumberInputEnvelope>;
};

export type LeadActivityCreateNestedManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<LeadActivityWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<LeadActivityCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<LeadActivityCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<LeadActivityCreateManyOrganizationInputEnvelope>;
};

export type LeadActivityCreateNestedManyWithoutRelated_DispositionInput = {
  connect?: InputMaybe<Array<LeadActivityWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<LeadActivityCreateOrConnectWithoutRelated_DispositionInput>>;
  create?: InputMaybe<Array<LeadActivityCreateWithoutRelated_DispositionInput>>;
  createMany?: InputMaybe<LeadActivityCreateManyRelated_DispositionInputEnvelope>;
};

export type LeadActivityCreateNestedManyWithoutSale_CycleInput = {
  connect?: InputMaybe<Array<LeadActivityWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<LeadActivityCreateOrConnectWithoutSale_CycleInput>>;
  create?: InputMaybe<Array<LeadActivityCreateWithoutSale_CycleInput>>;
  createMany?: InputMaybe<LeadActivityCreateManySale_CycleInputEnvelope>;
};

export type LeadActivityCreateNestedManyWithoutSiteInput = {
  connect?: InputMaybe<Array<LeadActivityWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<LeadActivityCreateOrConnectWithoutSiteInput>>;
  create?: InputMaybe<Array<LeadActivityCreateWithoutSiteInput>>;
  createMany?: InputMaybe<LeadActivityCreateManySiteInputEnvelope>;
};

export type LeadActivityCreateNestedManyWithoutTeamInput = {
  connect?: InputMaybe<Array<LeadActivityWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<LeadActivityCreateOrConnectWithoutTeamInput>>;
  create?: InputMaybe<Array<LeadActivityCreateWithoutTeamInput>>;
  createMany?: InputMaybe<LeadActivityCreateManyTeamInputEnvelope>;
};

export type LeadActivityCreateNestedManyWithoutUserInput = {
  connect?: InputMaybe<Array<LeadActivityWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<LeadActivityCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<LeadActivityCreateWithoutUserInput>>;
  createMany?: InputMaybe<LeadActivityCreateManyUserInputEnvelope>;
};

export type LeadActivityCreateNestedOneWithoutEmail_ItemInput = {
  connect?: InputMaybe<LeadActivityWhereUniqueInput>;
  connectOrCreate?: InputMaybe<LeadActivityCreateOrConnectWithoutEmail_ItemInput>;
  create?: InputMaybe<LeadActivityCreateWithoutEmail_ItemInput>;
};

export type LeadActivityCreateNestedOneWithoutInboundConciergeEventsInput = {
  connect?: InputMaybe<LeadActivityWhereUniqueInput>;
  connectOrCreate?: InputMaybe<LeadActivityCreateOrConnectWithoutInboundConciergeEventsInput>;
  create?: InputMaybe<LeadActivityCreateWithoutInboundConciergeEventsInput>;
};

export type LeadActivityCreateNestedOneWithoutLead_Assignment_History_ChangeInput = {
  connect?: InputMaybe<LeadActivityWhereUniqueInput>;
  connectOrCreate?: InputMaybe<LeadActivityCreateOrConnectWithoutLead_Assignment_History_ChangeInput>;
  create?: InputMaybe<LeadActivityCreateWithoutLead_Assignment_History_ChangeInput>;
};

export type LeadActivityCreateNestedOneWithoutOriginating_Schedule_ItemInput = {
  connect?: InputMaybe<LeadActivityWhereUniqueInput>;
  connectOrCreate?: InputMaybe<LeadActivityCreateOrConnectWithoutOriginating_Schedule_ItemInput>;
  create?: InputMaybe<LeadActivityCreateWithoutOriginating_Schedule_ItemInput>;
};

export type LeadActivityCreateNestedOneWithoutSaleInput = {
  connect?: InputMaybe<LeadActivityWhereUniqueInput>;
  connectOrCreate?: InputMaybe<LeadActivityCreateOrConnectWithoutSaleInput>;
  create?: InputMaybe<LeadActivityCreateWithoutSaleInput>;
};

export type LeadActivityCreateNestedOneWithoutSchedule_ItemInput = {
  connect?: InputMaybe<LeadActivityWhereUniqueInput>;
  connectOrCreate?: InputMaybe<LeadActivityCreateOrConnectWithoutSchedule_ItemInput>;
  create?: InputMaybe<LeadActivityCreateWithoutSchedule_ItemInput>;
};

export type LeadActivityCreateNestedOneWithoutSequenceEntryExitLeadInput = {
  connect?: InputMaybe<LeadActivityWhereUniqueInput>;
  connectOrCreate?: InputMaybe<LeadActivityCreateOrConnectWithoutSequenceEntryExitLeadInput>;
  create?: InputMaybe<LeadActivityCreateWithoutSequenceEntryExitLeadInput>;
};

export type LeadActivityCreateNestedOneWithoutSmsItemInput = {
  connect?: InputMaybe<LeadActivityWhereUniqueInput>;
  connectOrCreate?: InputMaybe<LeadActivityCreateOrConnectWithoutSmsItemInput>;
  create?: InputMaybe<LeadActivityCreateWithoutSmsItemInput>;
};

export type LeadActivityCreateNestedOneWithoutStat_Item_Aggregation_PipelineInput = {
  connect?: InputMaybe<LeadActivityWhereUniqueInput>;
  connectOrCreate?: InputMaybe<LeadActivityCreateOrConnectWithoutStat_Item_Aggregation_PipelineInput>;
  create?: InputMaybe<LeadActivityCreateWithoutStat_Item_Aggregation_PipelineInput>;
};

export type LeadActivityCreateNestedOneWithoutStat_Item_AggregationInput = {
  connect?: InputMaybe<LeadActivityWhereUniqueInput>;
  connectOrCreate?: InputMaybe<LeadActivityCreateOrConnectWithoutStat_Item_AggregationInput>;
  create?: InputMaybe<LeadActivityCreateWithoutStat_Item_AggregationInput>;
};

export type LeadActivityCreateNestedOneWithoutStepActionLogInput = {
  connect?: InputMaybe<LeadActivityWhereUniqueInput>;
  connectOrCreate?: InputMaybe<LeadActivityCreateOrConnectWithoutStepActionLogInput>;
  create?: InputMaybe<LeadActivityCreateWithoutStepActionLogInput>;
};

export type LeadActivityCreateOrConnectWithoutAssociate_ChildInput = {
  create: LeadActivityCreateWithoutAssociate_ChildInput;
  where: LeadActivityWhereUniqueInput;
};

export type LeadActivityCreateOrConnectWithoutAssociate_Group_LeadsInput = {
  create: LeadActivityCreateWithoutAssociate_Group_LeadsInput;
  where: LeadActivityWhereUniqueInput;
};

export type LeadActivityCreateOrConnectWithoutAutomated_CampaignInput = {
  create: LeadActivityCreateWithoutAutomated_CampaignInput;
  where: LeadActivityWhereUniqueInput;
};

export type LeadActivityCreateOrConnectWithoutCampaign_EventInput = {
  create: LeadActivityCreateWithoutCampaign_EventInput;
  where: LeadActivityWhereUniqueInput;
};

export type LeadActivityCreateOrConnectWithoutConferenceInput = {
  create: LeadActivityCreateWithoutConferenceInput;
  where: LeadActivityWhereUniqueInput;
};

export type LeadActivityCreateOrConnectWithoutEmail_ItemInput = {
  create: LeadActivityCreateWithoutEmail_ItemInput;
  where: LeadActivityWhereUniqueInput;
};

export type LeadActivityCreateOrConnectWithoutInboundConciergeEventsInput = {
  create: LeadActivityCreateWithoutInboundConciergeEventsInput;
  where: LeadActivityWhereUniqueInput;
};

export type LeadActivityCreateOrConnectWithoutLead_Assignment_History_ChangeInput = {
  create: LeadActivityCreateWithoutLead_Assignment_History_ChangeInput;
  where: LeadActivityWhereUniqueInput;
};

export type LeadActivityCreateOrConnectWithoutLead_Current_SequenceInput = {
  create: LeadActivityCreateWithoutLead_Current_SequenceInput;
  where: LeadActivityWhereUniqueInput;
};

export type LeadActivityCreateOrConnectWithoutLead_Current_StepInput = {
  create: LeadActivityCreateWithoutLead_Current_StepInput;
  where: LeadActivityWhereUniqueInput;
};

export type LeadActivityCreateOrConnectWithoutLeadInput = {
  create: LeadActivityCreateWithoutLeadInput;
  where: LeadActivityWhereUniqueInput;
};

export type LeadActivityCreateOrConnectWithoutLocal_NumberInput = {
  create: LeadActivityCreateWithoutLocal_NumberInput;
  where: LeadActivityWhereUniqueInput;
};

export type LeadActivityCreateOrConnectWithoutOrganizationInput = {
  create: LeadActivityCreateWithoutOrganizationInput;
  where: LeadActivityWhereUniqueInput;
};

export type LeadActivityCreateOrConnectWithoutOriginating_Schedule_ItemInput = {
  create: LeadActivityCreateWithoutOriginating_Schedule_ItemInput;
  where: LeadActivityWhereUniqueInput;
};

export type LeadActivityCreateOrConnectWithoutRelated_DispositionInput = {
  create: LeadActivityCreateWithoutRelated_DispositionInput;
  where: LeadActivityWhereUniqueInput;
};

export type LeadActivityCreateOrConnectWithoutSale_CycleInput = {
  create: LeadActivityCreateWithoutSale_CycleInput;
  where: LeadActivityWhereUniqueInput;
};

export type LeadActivityCreateOrConnectWithoutSaleInput = {
  create: LeadActivityCreateWithoutSaleInput;
  where: LeadActivityWhereUniqueInput;
};

export type LeadActivityCreateOrConnectWithoutSchedule_ItemInput = {
  create: LeadActivityCreateWithoutSchedule_ItemInput;
  where: LeadActivityWhereUniqueInput;
};

export type LeadActivityCreateOrConnectWithoutSequenceEntryExitLeadInput = {
  create: LeadActivityCreateWithoutSequenceEntryExitLeadInput;
  where: LeadActivityWhereUniqueInput;
};

export type LeadActivityCreateOrConnectWithoutSiteInput = {
  create: LeadActivityCreateWithoutSiteInput;
  where: LeadActivityWhereUniqueInput;
};

export type LeadActivityCreateOrConnectWithoutSmsItemInput = {
  create: LeadActivityCreateWithoutSmsItemInput;
  where: LeadActivityWhereUniqueInput;
};

export type LeadActivityCreateOrConnectWithoutStat_Item_Aggregation_PipelineInput = {
  create: LeadActivityCreateWithoutStat_Item_Aggregation_PipelineInput;
  where: LeadActivityWhereUniqueInput;
};

export type LeadActivityCreateOrConnectWithoutStat_Item_AggregationInput = {
  create: LeadActivityCreateWithoutStat_Item_AggregationInput;
  where: LeadActivityWhereUniqueInput;
};

export type LeadActivityCreateOrConnectWithoutStepActionLogInput = {
  create: LeadActivityCreateWithoutStepActionLogInput;
  where: LeadActivityWhereUniqueInput;
};

export type LeadActivityCreateOrConnectWithoutTeamInput = {
  create: LeadActivityCreateWithoutTeamInput;
  where: LeadActivityWhereUniqueInput;
};

export type LeadActivityCreateOrConnectWithoutUserInput = {
  create: LeadActivityCreateWithoutUserInput;
  where: LeadActivityWhereUniqueInput;
};

export type LeadActivityCreateWithoutAssociate_ChildInput = {
  associate_group_ids?: InputMaybe<LeadActivityCreateassociate_Group_IdsInput>;
  associate_group_leads?: InputMaybe<LeadCreateNestedManyWithoutAssociate_Group_Lead_ActivitiesInput>;
  associated_action?: InputMaybe<Scalars['String']['input']>;
  automated_campaign?: InputMaybe<AutomatedCampaignCreateNestedOneWithoutLead_ActivitiesInput>;
  call_completed_with?: InputMaybe<Callresult>;
  call_result_deleted?: InputMaybe<Scalars['Boolean']['input']>;
  campaign_event?: InputMaybe<ScheduleItemCreateNestedOneWithoutCampaign_ActivitiesInput>;
  channel?: InputMaybe<Channel>;
  communication_type?: InputMaybe<Communication_Type>;
  conference?: InputMaybe<ConferenceCreateNestedOneWithoutLead_ActivitiesInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  curr_lead_val?: InputMaybe<Scalars['Float']['input']>;
  current_implied_action?: InputMaybe<Hingepoint>;
  customer_number?: InputMaybe<Scalars['String']['input']>;
  customer_number_country_code?: InputMaybe<Scalars['String']['input']>;
  dedupe_migration?: InputMaybe<Scalars['Boolean']['input']>;
  email_item?: InputMaybe<EmailItemCreateNestedOneWithoutLead_ActivityInput>;
  from_action?: InputMaybe<Scalars['String']['input']>;
  from_callback_type?: InputMaybe<CallbackType>;
  from_intent_type?: InputMaybe<IntentType>;
  html?: InputMaybe<Scalars['Boolean']['input']>;
  hubspot_email_engagement_id?: InputMaybe<Scalars['String']['input']>;
  hubspot_id?: InputMaybe<Scalars['String']['input']>;
  hubspot_note_engagement_id?: InputMaybe<Scalars['String']['input']>;
  hubspot_owner_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  ignore_sequences?: InputMaybe<Scalars['Boolean']['input']>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventCreateNestedManyWithoutLeadActivityInput>;
  initial_days_set_out?: InputMaybe<Scalars['Float']['input']>;
  is_resting_adjustment?: InputMaybe<Scalars['Boolean']['input']>;
  lead: LeadCreateNestedOneWithoutLead_ActivitiesInput;
  lead_assignment_history_change?: InputMaybe<LeadAssignmentHistoryCreateNestedOneWithoutLead_ActivityInput>;
  lead_current_sequence?: InputMaybe<SequenceCreateNestedOneWithoutLeadActivitiesInput>;
  lead_current_step?: InputMaybe<SequenceStepCreateNestedOneWithoutLeadActivityInput>;
  local_number?: InputMaybe<TwilioNumberCreateNestedOneWithoutLead_ActivitiesInput>;
  next_action?: InputMaybe<Scalars['String']['input']>;
  next_callback_type?: InputMaybe<CallbackType>;
  notes?: InputMaybe<Scalars['String']['input']>;
  organization?: InputMaybe<OrganizationCreateNestedOneWithoutLead_ActivitiesInput>;
  originating_schedule_item?: InputMaybe<ScheduleItemCreateNestedOneWithoutResulting_ActivityInput>;
  previous_lead_val?: InputMaybe<Scalars['Float']['input']>;
  published?: InputMaybe<Scalars['Boolean']['input']>;
  related_disposition?: InputMaybe<DispositionCreateNestedOneWithoutLead_ActivitiesInput>;
  resting_date_on_recycle?: InputMaybe<Scalars['DateTime']['input']>;
  resting_type_on_recycle?: InputMaybe<Restingtype>;
  sale?: InputMaybe<SaleCreateNestedOneWithoutLead_ActivityInput>;
  sale_cycle?: InputMaybe<SaleCycleCreateNestedOneWithoutLead_ActivitiesInput>;
  schedule_item?: InputMaybe<ScheduleItemCreateNestedOneWithoutLead_ActivityInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutLead_ActivityInput>;
  site?: InputMaybe<SiteCreateNestedOneWithoutLead_ActivitiesInput>;
  skip_reason?: InputMaybe<Scalars['String']['input']>;
  SMSItem?: InputMaybe<SmsItemCreateNestedOneWithoutLead_ActivityInput>;
  stat_item_aggregation?: InputMaybe<StatItemAggregationCreateNestedOneWithoutLead_ActivityInput>;
  stat_item_aggregation_pipeline?: InputMaybe<StatItemAggregationCreateNestedOneWithoutLead_Activity_PipelineInput>;
  StepActionLog?: InputMaybe<StepActionLogCreateNestedManyWithoutLeadActivityInput>;
  team?: InputMaybe<TeamCreateNestedOneWithoutLead_ActivitiesInput>;
  title?: InputMaybe<Scalars['String']['input']>;
  twilio_action?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user?: InputMaybe<UserCreateNestedOneWithoutLead_ActivitiesInput>;
  user_role?: InputMaybe<Role>;
};

export type LeadActivityCreateWithoutAssociate_Group_LeadsInput = {
  associate_child?: InputMaybe<LeadCreateNestedOneWithoutChild_Lead_ActivitesInput>;
  associate_group_ids?: InputMaybe<LeadActivityCreateassociate_Group_IdsInput>;
  associated_action?: InputMaybe<Scalars['String']['input']>;
  automated_campaign?: InputMaybe<AutomatedCampaignCreateNestedOneWithoutLead_ActivitiesInput>;
  call_completed_with?: InputMaybe<Callresult>;
  call_result_deleted?: InputMaybe<Scalars['Boolean']['input']>;
  campaign_event?: InputMaybe<ScheduleItemCreateNestedOneWithoutCampaign_ActivitiesInput>;
  channel?: InputMaybe<Channel>;
  communication_type?: InputMaybe<Communication_Type>;
  conference?: InputMaybe<ConferenceCreateNestedOneWithoutLead_ActivitiesInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  curr_lead_val?: InputMaybe<Scalars['Float']['input']>;
  current_implied_action?: InputMaybe<Hingepoint>;
  customer_number?: InputMaybe<Scalars['String']['input']>;
  customer_number_country_code?: InputMaybe<Scalars['String']['input']>;
  dedupe_migration?: InputMaybe<Scalars['Boolean']['input']>;
  email_item?: InputMaybe<EmailItemCreateNestedOneWithoutLead_ActivityInput>;
  from_action?: InputMaybe<Scalars['String']['input']>;
  from_callback_type?: InputMaybe<CallbackType>;
  from_intent_type?: InputMaybe<IntentType>;
  html?: InputMaybe<Scalars['Boolean']['input']>;
  hubspot_email_engagement_id?: InputMaybe<Scalars['String']['input']>;
  hubspot_id?: InputMaybe<Scalars['String']['input']>;
  hubspot_note_engagement_id?: InputMaybe<Scalars['String']['input']>;
  hubspot_owner_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  ignore_sequences?: InputMaybe<Scalars['Boolean']['input']>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventCreateNestedManyWithoutLeadActivityInput>;
  initial_days_set_out?: InputMaybe<Scalars['Float']['input']>;
  is_resting_adjustment?: InputMaybe<Scalars['Boolean']['input']>;
  lead: LeadCreateNestedOneWithoutLead_ActivitiesInput;
  lead_assignment_history_change?: InputMaybe<LeadAssignmentHistoryCreateNestedOneWithoutLead_ActivityInput>;
  lead_current_sequence?: InputMaybe<SequenceCreateNestedOneWithoutLeadActivitiesInput>;
  lead_current_step?: InputMaybe<SequenceStepCreateNestedOneWithoutLeadActivityInput>;
  local_number?: InputMaybe<TwilioNumberCreateNestedOneWithoutLead_ActivitiesInput>;
  next_action?: InputMaybe<Scalars['String']['input']>;
  next_callback_type?: InputMaybe<CallbackType>;
  notes?: InputMaybe<Scalars['String']['input']>;
  organization?: InputMaybe<OrganizationCreateNestedOneWithoutLead_ActivitiesInput>;
  originating_schedule_item?: InputMaybe<ScheduleItemCreateNestedOneWithoutResulting_ActivityInput>;
  previous_lead_val?: InputMaybe<Scalars['Float']['input']>;
  published?: InputMaybe<Scalars['Boolean']['input']>;
  related_disposition?: InputMaybe<DispositionCreateNestedOneWithoutLead_ActivitiesInput>;
  resting_date_on_recycle?: InputMaybe<Scalars['DateTime']['input']>;
  resting_type_on_recycle?: InputMaybe<Restingtype>;
  sale?: InputMaybe<SaleCreateNestedOneWithoutLead_ActivityInput>;
  sale_cycle?: InputMaybe<SaleCycleCreateNestedOneWithoutLead_ActivitiesInput>;
  schedule_item?: InputMaybe<ScheduleItemCreateNestedOneWithoutLead_ActivityInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutLead_ActivityInput>;
  site?: InputMaybe<SiteCreateNestedOneWithoutLead_ActivitiesInput>;
  skip_reason?: InputMaybe<Scalars['String']['input']>;
  SMSItem?: InputMaybe<SmsItemCreateNestedOneWithoutLead_ActivityInput>;
  stat_item_aggregation?: InputMaybe<StatItemAggregationCreateNestedOneWithoutLead_ActivityInput>;
  stat_item_aggregation_pipeline?: InputMaybe<StatItemAggregationCreateNestedOneWithoutLead_Activity_PipelineInput>;
  StepActionLog?: InputMaybe<StepActionLogCreateNestedManyWithoutLeadActivityInput>;
  team?: InputMaybe<TeamCreateNestedOneWithoutLead_ActivitiesInput>;
  title?: InputMaybe<Scalars['String']['input']>;
  twilio_action?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user?: InputMaybe<UserCreateNestedOneWithoutLead_ActivitiesInput>;
  user_role?: InputMaybe<Role>;
};

export type LeadActivityCreateWithoutAutomated_CampaignInput = {
  associate_child?: InputMaybe<LeadCreateNestedOneWithoutChild_Lead_ActivitesInput>;
  associate_group_ids?: InputMaybe<LeadActivityCreateassociate_Group_IdsInput>;
  associate_group_leads?: InputMaybe<LeadCreateNestedManyWithoutAssociate_Group_Lead_ActivitiesInput>;
  associated_action?: InputMaybe<Scalars['String']['input']>;
  call_completed_with?: InputMaybe<Callresult>;
  call_result_deleted?: InputMaybe<Scalars['Boolean']['input']>;
  campaign_event?: InputMaybe<ScheduleItemCreateNestedOneWithoutCampaign_ActivitiesInput>;
  channel?: InputMaybe<Channel>;
  communication_type?: InputMaybe<Communication_Type>;
  conference?: InputMaybe<ConferenceCreateNestedOneWithoutLead_ActivitiesInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  curr_lead_val?: InputMaybe<Scalars['Float']['input']>;
  current_implied_action?: InputMaybe<Hingepoint>;
  customer_number?: InputMaybe<Scalars['String']['input']>;
  customer_number_country_code?: InputMaybe<Scalars['String']['input']>;
  dedupe_migration?: InputMaybe<Scalars['Boolean']['input']>;
  email_item?: InputMaybe<EmailItemCreateNestedOneWithoutLead_ActivityInput>;
  from_action?: InputMaybe<Scalars['String']['input']>;
  from_callback_type?: InputMaybe<CallbackType>;
  from_intent_type?: InputMaybe<IntentType>;
  html?: InputMaybe<Scalars['Boolean']['input']>;
  hubspot_email_engagement_id?: InputMaybe<Scalars['String']['input']>;
  hubspot_id?: InputMaybe<Scalars['String']['input']>;
  hubspot_note_engagement_id?: InputMaybe<Scalars['String']['input']>;
  hubspot_owner_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  ignore_sequences?: InputMaybe<Scalars['Boolean']['input']>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventCreateNestedManyWithoutLeadActivityInput>;
  initial_days_set_out?: InputMaybe<Scalars['Float']['input']>;
  is_resting_adjustment?: InputMaybe<Scalars['Boolean']['input']>;
  lead: LeadCreateNestedOneWithoutLead_ActivitiesInput;
  lead_assignment_history_change?: InputMaybe<LeadAssignmentHistoryCreateNestedOneWithoutLead_ActivityInput>;
  lead_current_sequence?: InputMaybe<SequenceCreateNestedOneWithoutLeadActivitiesInput>;
  lead_current_step?: InputMaybe<SequenceStepCreateNestedOneWithoutLeadActivityInput>;
  local_number?: InputMaybe<TwilioNumberCreateNestedOneWithoutLead_ActivitiesInput>;
  next_action?: InputMaybe<Scalars['String']['input']>;
  next_callback_type?: InputMaybe<CallbackType>;
  notes?: InputMaybe<Scalars['String']['input']>;
  organization?: InputMaybe<OrganizationCreateNestedOneWithoutLead_ActivitiesInput>;
  originating_schedule_item?: InputMaybe<ScheduleItemCreateNestedOneWithoutResulting_ActivityInput>;
  previous_lead_val?: InputMaybe<Scalars['Float']['input']>;
  published?: InputMaybe<Scalars['Boolean']['input']>;
  related_disposition?: InputMaybe<DispositionCreateNestedOneWithoutLead_ActivitiesInput>;
  resting_date_on_recycle?: InputMaybe<Scalars['DateTime']['input']>;
  resting_type_on_recycle?: InputMaybe<Restingtype>;
  sale?: InputMaybe<SaleCreateNestedOneWithoutLead_ActivityInput>;
  sale_cycle?: InputMaybe<SaleCycleCreateNestedOneWithoutLead_ActivitiesInput>;
  schedule_item?: InputMaybe<ScheduleItemCreateNestedOneWithoutLead_ActivityInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutLead_ActivityInput>;
  site?: InputMaybe<SiteCreateNestedOneWithoutLead_ActivitiesInput>;
  skip_reason?: InputMaybe<Scalars['String']['input']>;
  SMSItem?: InputMaybe<SmsItemCreateNestedOneWithoutLead_ActivityInput>;
  stat_item_aggregation?: InputMaybe<StatItemAggregationCreateNestedOneWithoutLead_ActivityInput>;
  stat_item_aggregation_pipeline?: InputMaybe<StatItemAggregationCreateNestedOneWithoutLead_Activity_PipelineInput>;
  StepActionLog?: InputMaybe<StepActionLogCreateNestedManyWithoutLeadActivityInput>;
  team?: InputMaybe<TeamCreateNestedOneWithoutLead_ActivitiesInput>;
  title?: InputMaybe<Scalars['String']['input']>;
  twilio_action?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user?: InputMaybe<UserCreateNestedOneWithoutLead_ActivitiesInput>;
  user_role?: InputMaybe<Role>;
};

export type LeadActivityCreateWithoutCampaign_EventInput = {
  associate_child?: InputMaybe<LeadCreateNestedOneWithoutChild_Lead_ActivitesInput>;
  associate_group_ids?: InputMaybe<LeadActivityCreateassociate_Group_IdsInput>;
  associate_group_leads?: InputMaybe<LeadCreateNestedManyWithoutAssociate_Group_Lead_ActivitiesInput>;
  associated_action?: InputMaybe<Scalars['String']['input']>;
  automated_campaign?: InputMaybe<AutomatedCampaignCreateNestedOneWithoutLead_ActivitiesInput>;
  call_completed_with?: InputMaybe<Callresult>;
  call_result_deleted?: InputMaybe<Scalars['Boolean']['input']>;
  channel?: InputMaybe<Channel>;
  communication_type?: InputMaybe<Communication_Type>;
  conference?: InputMaybe<ConferenceCreateNestedOneWithoutLead_ActivitiesInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  curr_lead_val?: InputMaybe<Scalars['Float']['input']>;
  current_implied_action?: InputMaybe<Hingepoint>;
  customer_number?: InputMaybe<Scalars['String']['input']>;
  customer_number_country_code?: InputMaybe<Scalars['String']['input']>;
  dedupe_migration?: InputMaybe<Scalars['Boolean']['input']>;
  email_item?: InputMaybe<EmailItemCreateNestedOneWithoutLead_ActivityInput>;
  from_action?: InputMaybe<Scalars['String']['input']>;
  from_callback_type?: InputMaybe<CallbackType>;
  from_intent_type?: InputMaybe<IntentType>;
  html?: InputMaybe<Scalars['Boolean']['input']>;
  hubspot_email_engagement_id?: InputMaybe<Scalars['String']['input']>;
  hubspot_id?: InputMaybe<Scalars['String']['input']>;
  hubspot_note_engagement_id?: InputMaybe<Scalars['String']['input']>;
  hubspot_owner_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  ignore_sequences?: InputMaybe<Scalars['Boolean']['input']>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventCreateNestedManyWithoutLeadActivityInput>;
  initial_days_set_out?: InputMaybe<Scalars['Float']['input']>;
  is_resting_adjustment?: InputMaybe<Scalars['Boolean']['input']>;
  lead: LeadCreateNestedOneWithoutLead_ActivitiesInput;
  lead_assignment_history_change?: InputMaybe<LeadAssignmentHistoryCreateNestedOneWithoutLead_ActivityInput>;
  lead_current_sequence?: InputMaybe<SequenceCreateNestedOneWithoutLeadActivitiesInput>;
  lead_current_step?: InputMaybe<SequenceStepCreateNestedOneWithoutLeadActivityInput>;
  local_number?: InputMaybe<TwilioNumberCreateNestedOneWithoutLead_ActivitiesInput>;
  next_action?: InputMaybe<Scalars['String']['input']>;
  next_callback_type?: InputMaybe<CallbackType>;
  notes?: InputMaybe<Scalars['String']['input']>;
  organization?: InputMaybe<OrganizationCreateNestedOneWithoutLead_ActivitiesInput>;
  originating_schedule_item?: InputMaybe<ScheduleItemCreateNestedOneWithoutResulting_ActivityInput>;
  previous_lead_val?: InputMaybe<Scalars['Float']['input']>;
  published?: InputMaybe<Scalars['Boolean']['input']>;
  related_disposition?: InputMaybe<DispositionCreateNestedOneWithoutLead_ActivitiesInput>;
  resting_date_on_recycle?: InputMaybe<Scalars['DateTime']['input']>;
  resting_type_on_recycle?: InputMaybe<Restingtype>;
  sale?: InputMaybe<SaleCreateNestedOneWithoutLead_ActivityInput>;
  sale_cycle?: InputMaybe<SaleCycleCreateNestedOneWithoutLead_ActivitiesInput>;
  schedule_item?: InputMaybe<ScheduleItemCreateNestedOneWithoutLead_ActivityInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutLead_ActivityInput>;
  site?: InputMaybe<SiteCreateNestedOneWithoutLead_ActivitiesInput>;
  skip_reason?: InputMaybe<Scalars['String']['input']>;
  SMSItem?: InputMaybe<SmsItemCreateNestedOneWithoutLead_ActivityInput>;
  stat_item_aggregation?: InputMaybe<StatItemAggregationCreateNestedOneWithoutLead_ActivityInput>;
  stat_item_aggregation_pipeline?: InputMaybe<StatItemAggregationCreateNestedOneWithoutLead_Activity_PipelineInput>;
  StepActionLog?: InputMaybe<StepActionLogCreateNestedManyWithoutLeadActivityInput>;
  team?: InputMaybe<TeamCreateNestedOneWithoutLead_ActivitiesInput>;
  title?: InputMaybe<Scalars['String']['input']>;
  twilio_action?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user?: InputMaybe<UserCreateNestedOneWithoutLead_ActivitiesInput>;
  user_role?: InputMaybe<Role>;
};

export type LeadActivityCreateWithoutConferenceInput = {
  associate_child?: InputMaybe<LeadCreateNestedOneWithoutChild_Lead_ActivitesInput>;
  associate_group_ids?: InputMaybe<LeadActivityCreateassociate_Group_IdsInput>;
  associate_group_leads?: InputMaybe<LeadCreateNestedManyWithoutAssociate_Group_Lead_ActivitiesInput>;
  associated_action?: InputMaybe<Scalars['String']['input']>;
  automated_campaign?: InputMaybe<AutomatedCampaignCreateNestedOneWithoutLead_ActivitiesInput>;
  call_completed_with?: InputMaybe<Callresult>;
  call_result_deleted?: InputMaybe<Scalars['Boolean']['input']>;
  campaign_event?: InputMaybe<ScheduleItemCreateNestedOneWithoutCampaign_ActivitiesInput>;
  channel?: InputMaybe<Channel>;
  communication_type?: InputMaybe<Communication_Type>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  curr_lead_val?: InputMaybe<Scalars['Float']['input']>;
  current_implied_action?: InputMaybe<Hingepoint>;
  customer_number?: InputMaybe<Scalars['String']['input']>;
  customer_number_country_code?: InputMaybe<Scalars['String']['input']>;
  dedupe_migration?: InputMaybe<Scalars['Boolean']['input']>;
  email_item?: InputMaybe<EmailItemCreateNestedOneWithoutLead_ActivityInput>;
  from_action?: InputMaybe<Scalars['String']['input']>;
  from_callback_type?: InputMaybe<CallbackType>;
  from_intent_type?: InputMaybe<IntentType>;
  html?: InputMaybe<Scalars['Boolean']['input']>;
  hubspot_email_engagement_id?: InputMaybe<Scalars['String']['input']>;
  hubspot_id?: InputMaybe<Scalars['String']['input']>;
  hubspot_note_engagement_id?: InputMaybe<Scalars['String']['input']>;
  hubspot_owner_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  ignore_sequences?: InputMaybe<Scalars['Boolean']['input']>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventCreateNestedManyWithoutLeadActivityInput>;
  initial_days_set_out?: InputMaybe<Scalars['Float']['input']>;
  is_resting_adjustment?: InputMaybe<Scalars['Boolean']['input']>;
  lead: LeadCreateNestedOneWithoutLead_ActivitiesInput;
  lead_assignment_history_change?: InputMaybe<LeadAssignmentHistoryCreateNestedOneWithoutLead_ActivityInput>;
  lead_current_sequence?: InputMaybe<SequenceCreateNestedOneWithoutLeadActivitiesInput>;
  lead_current_step?: InputMaybe<SequenceStepCreateNestedOneWithoutLeadActivityInput>;
  local_number?: InputMaybe<TwilioNumberCreateNestedOneWithoutLead_ActivitiesInput>;
  next_action?: InputMaybe<Scalars['String']['input']>;
  next_callback_type?: InputMaybe<CallbackType>;
  notes?: InputMaybe<Scalars['String']['input']>;
  organization?: InputMaybe<OrganizationCreateNestedOneWithoutLead_ActivitiesInput>;
  originating_schedule_item?: InputMaybe<ScheduleItemCreateNestedOneWithoutResulting_ActivityInput>;
  previous_lead_val?: InputMaybe<Scalars['Float']['input']>;
  published?: InputMaybe<Scalars['Boolean']['input']>;
  related_disposition?: InputMaybe<DispositionCreateNestedOneWithoutLead_ActivitiesInput>;
  resting_date_on_recycle?: InputMaybe<Scalars['DateTime']['input']>;
  resting_type_on_recycle?: InputMaybe<Restingtype>;
  sale?: InputMaybe<SaleCreateNestedOneWithoutLead_ActivityInput>;
  sale_cycle?: InputMaybe<SaleCycleCreateNestedOneWithoutLead_ActivitiesInput>;
  schedule_item?: InputMaybe<ScheduleItemCreateNestedOneWithoutLead_ActivityInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutLead_ActivityInput>;
  site?: InputMaybe<SiteCreateNestedOneWithoutLead_ActivitiesInput>;
  skip_reason?: InputMaybe<Scalars['String']['input']>;
  SMSItem?: InputMaybe<SmsItemCreateNestedOneWithoutLead_ActivityInput>;
  stat_item_aggregation?: InputMaybe<StatItemAggregationCreateNestedOneWithoutLead_ActivityInput>;
  stat_item_aggregation_pipeline?: InputMaybe<StatItemAggregationCreateNestedOneWithoutLead_Activity_PipelineInput>;
  StepActionLog?: InputMaybe<StepActionLogCreateNestedManyWithoutLeadActivityInput>;
  team?: InputMaybe<TeamCreateNestedOneWithoutLead_ActivitiesInput>;
  title?: InputMaybe<Scalars['String']['input']>;
  twilio_action?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user?: InputMaybe<UserCreateNestedOneWithoutLead_ActivitiesInput>;
  user_role?: InputMaybe<Role>;
};

export type LeadActivityCreateWithoutEmail_ItemInput = {
  associate_child?: InputMaybe<LeadCreateNestedOneWithoutChild_Lead_ActivitesInput>;
  associate_group_ids?: InputMaybe<LeadActivityCreateassociate_Group_IdsInput>;
  associate_group_leads?: InputMaybe<LeadCreateNestedManyWithoutAssociate_Group_Lead_ActivitiesInput>;
  associated_action?: InputMaybe<Scalars['String']['input']>;
  automated_campaign?: InputMaybe<AutomatedCampaignCreateNestedOneWithoutLead_ActivitiesInput>;
  call_completed_with?: InputMaybe<Callresult>;
  call_result_deleted?: InputMaybe<Scalars['Boolean']['input']>;
  campaign_event?: InputMaybe<ScheduleItemCreateNestedOneWithoutCampaign_ActivitiesInput>;
  channel?: InputMaybe<Channel>;
  communication_type?: InputMaybe<Communication_Type>;
  conference?: InputMaybe<ConferenceCreateNestedOneWithoutLead_ActivitiesInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  curr_lead_val?: InputMaybe<Scalars['Float']['input']>;
  current_implied_action?: InputMaybe<Hingepoint>;
  customer_number?: InputMaybe<Scalars['String']['input']>;
  customer_number_country_code?: InputMaybe<Scalars['String']['input']>;
  dedupe_migration?: InputMaybe<Scalars['Boolean']['input']>;
  from_action?: InputMaybe<Scalars['String']['input']>;
  from_callback_type?: InputMaybe<CallbackType>;
  from_intent_type?: InputMaybe<IntentType>;
  html?: InputMaybe<Scalars['Boolean']['input']>;
  hubspot_email_engagement_id?: InputMaybe<Scalars['String']['input']>;
  hubspot_id?: InputMaybe<Scalars['String']['input']>;
  hubspot_note_engagement_id?: InputMaybe<Scalars['String']['input']>;
  hubspot_owner_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  ignore_sequences?: InputMaybe<Scalars['Boolean']['input']>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventCreateNestedManyWithoutLeadActivityInput>;
  initial_days_set_out?: InputMaybe<Scalars['Float']['input']>;
  is_resting_adjustment?: InputMaybe<Scalars['Boolean']['input']>;
  lead: LeadCreateNestedOneWithoutLead_ActivitiesInput;
  lead_assignment_history_change?: InputMaybe<LeadAssignmentHistoryCreateNestedOneWithoutLead_ActivityInput>;
  lead_current_sequence?: InputMaybe<SequenceCreateNestedOneWithoutLeadActivitiesInput>;
  lead_current_step?: InputMaybe<SequenceStepCreateNestedOneWithoutLeadActivityInput>;
  local_number?: InputMaybe<TwilioNumberCreateNestedOneWithoutLead_ActivitiesInput>;
  next_action?: InputMaybe<Scalars['String']['input']>;
  next_callback_type?: InputMaybe<CallbackType>;
  notes?: InputMaybe<Scalars['String']['input']>;
  organization?: InputMaybe<OrganizationCreateNestedOneWithoutLead_ActivitiesInput>;
  originating_schedule_item?: InputMaybe<ScheduleItemCreateNestedOneWithoutResulting_ActivityInput>;
  previous_lead_val?: InputMaybe<Scalars['Float']['input']>;
  published?: InputMaybe<Scalars['Boolean']['input']>;
  related_disposition?: InputMaybe<DispositionCreateNestedOneWithoutLead_ActivitiesInput>;
  resting_date_on_recycle?: InputMaybe<Scalars['DateTime']['input']>;
  resting_type_on_recycle?: InputMaybe<Restingtype>;
  sale?: InputMaybe<SaleCreateNestedOneWithoutLead_ActivityInput>;
  sale_cycle?: InputMaybe<SaleCycleCreateNestedOneWithoutLead_ActivitiesInput>;
  schedule_item?: InputMaybe<ScheduleItemCreateNestedOneWithoutLead_ActivityInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutLead_ActivityInput>;
  site?: InputMaybe<SiteCreateNestedOneWithoutLead_ActivitiesInput>;
  skip_reason?: InputMaybe<Scalars['String']['input']>;
  SMSItem?: InputMaybe<SmsItemCreateNestedOneWithoutLead_ActivityInput>;
  stat_item_aggregation?: InputMaybe<StatItemAggregationCreateNestedOneWithoutLead_ActivityInput>;
  stat_item_aggregation_pipeline?: InputMaybe<StatItemAggregationCreateNestedOneWithoutLead_Activity_PipelineInput>;
  StepActionLog?: InputMaybe<StepActionLogCreateNestedManyWithoutLeadActivityInput>;
  team?: InputMaybe<TeamCreateNestedOneWithoutLead_ActivitiesInput>;
  title?: InputMaybe<Scalars['String']['input']>;
  twilio_action?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user?: InputMaybe<UserCreateNestedOneWithoutLead_ActivitiesInput>;
  user_role?: InputMaybe<Role>;
};

export type LeadActivityCreateWithoutInboundConciergeEventsInput = {
  associate_child?: InputMaybe<LeadCreateNestedOneWithoutChild_Lead_ActivitesInput>;
  associate_group_ids?: InputMaybe<LeadActivityCreateassociate_Group_IdsInput>;
  associate_group_leads?: InputMaybe<LeadCreateNestedManyWithoutAssociate_Group_Lead_ActivitiesInput>;
  associated_action?: InputMaybe<Scalars['String']['input']>;
  automated_campaign?: InputMaybe<AutomatedCampaignCreateNestedOneWithoutLead_ActivitiesInput>;
  call_completed_with?: InputMaybe<Callresult>;
  call_result_deleted?: InputMaybe<Scalars['Boolean']['input']>;
  campaign_event?: InputMaybe<ScheduleItemCreateNestedOneWithoutCampaign_ActivitiesInput>;
  channel?: InputMaybe<Channel>;
  communication_type?: InputMaybe<Communication_Type>;
  conference?: InputMaybe<ConferenceCreateNestedOneWithoutLead_ActivitiesInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  curr_lead_val?: InputMaybe<Scalars['Float']['input']>;
  current_implied_action?: InputMaybe<Hingepoint>;
  customer_number?: InputMaybe<Scalars['String']['input']>;
  customer_number_country_code?: InputMaybe<Scalars['String']['input']>;
  dedupe_migration?: InputMaybe<Scalars['Boolean']['input']>;
  email_item?: InputMaybe<EmailItemCreateNestedOneWithoutLead_ActivityInput>;
  from_action?: InputMaybe<Scalars['String']['input']>;
  from_callback_type?: InputMaybe<CallbackType>;
  from_intent_type?: InputMaybe<IntentType>;
  html?: InputMaybe<Scalars['Boolean']['input']>;
  hubspot_email_engagement_id?: InputMaybe<Scalars['String']['input']>;
  hubspot_id?: InputMaybe<Scalars['String']['input']>;
  hubspot_note_engagement_id?: InputMaybe<Scalars['String']['input']>;
  hubspot_owner_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  ignore_sequences?: InputMaybe<Scalars['Boolean']['input']>;
  initial_days_set_out?: InputMaybe<Scalars['Float']['input']>;
  is_resting_adjustment?: InputMaybe<Scalars['Boolean']['input']>;
  lead: LeadCreateNestedOneWithoutLead_ActivitiesInput;
  lead_assignment_history_change?: InputMaybe<LeadAssignmentHistoryCreateNestedOneWithoutLead_ActivityInput>;
  lead_current_sequence?: InputMaybe<SequenceCreateNestedOneWithoutLeadActivitiesInput>;
  lead_current_step?: InputMaybe<SequenceStepCreateNestedOneWithoutLeadActivityInput>;
  local_number?: InputMaybe<TwilioNumberCreateNestedOneWithoutLead_ActivitiesInput>;
  next_action?: InputMaybe<Scalars['String']['input']>;
  next_callback_type?: InputMaybe<CallbackType>;
  notes?: InputMaybe<Scalars['String']['input']>;
  organization?: InputMaybe<OrganizationCreateNestedOneWithoutLead_ActivitiesInput>;
  originating_schedule_item?: InputMaybe<ScheduleItemCreateNestedOneWithoutResulting_ActivityInput>;
  previous_lead_val?: InputMaybe<Scalars['Float']['input']>;
  published?: InputMaybe<Scalars['Boolean']['input']>;
  related_disposition?: InputMaybe<DispositionCreateNestedOneWithoutLead_ActivitiesInput>;
  resting_date_on_recycle?: InputMaybe<Scalars['DateTime']['input']>;
  resting_type_on_recycle?: InputMaybe<Restingtype>;
  sale?: InputMaybe<SaleCreateNestedOneWithoutLead_ActivityInput>;
  sale_cycle?: InputMaybe<SaleCycleCreateNestedOneWithoutLead_ActivitiesInput>;
  schedule_item?: InputMaybe<ScheduleItemCreateNestedOneWithoutLead_ActivityInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutLead_ActivityInput>;
  site?: InputMaybe<SiteCreateNestedOneWithoutLead_ActivitiesInput>;
  skip_reason?: InputMaybe<Scalars['String']['input']>;
  SMSItem?: InputMaybe<SmsItemCreateNestedOneWithoutLead_ActivityInput>;
  stat_item_aggregation?: InputMaybe<StatItemAggregationCreateNestedOneWithoutLead_ActivityInput>;
  stat_item_aggregation_pipeline?: InputMaybe<StatItemAggregationCreateNestedOneWithoutLead_Activity_PipelineInput>;
  StepActionLog?: InputMaybe<StepActionLogCreateNestedManyWithoutLeadActivityInput>;
  team?: InputMaybe<TeamCreateNestedOneWithoutLead_ActivitiesInput>;
  title?: InputMaybe<Scalars['String']['input']>;
  twilio_action?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user?: InputMaybe<UserCreateNestedOneWithoutLead_ActivitiesInput>;
  user_role?: InputMaybe<Role>;
};

export type LeadActivityCreateWithoutLead_Assignment_History_ChangeInput = {
  associate_child?: InputMaybe<LeadCreateNestedOneWithoutChild_Lead_ActivitesInput>;
  associate_group_ids?: InputMaybe<LeadActivityCreateassociate_Group_IdsInput>;
  associate_group_leads?: InputMaybe<LeadCreateNestedManyWithoutAssociate_Group_Lead_ActivitiesInput>;
  associated_action?: InputMaybe<Scalars['String']['input']>;
  automated_campaign?: InputMaybe<AutomatedCampaignCreateNestedOneWithoutLead_ActivitiesInput>;
  call_completed_with?: InputMaybe<Callresult>;
  call_result_deleted?: InputMaybe<Scalars['Boolean']['input']>;
  campaign_event?: InputMaybe<ScheduleItemCreateNestedOneWithoutCampaign_ActivitiesInput>;
  channel?: InputMaybe<Channel>;
  communication_type?: InputMaybe<Communication_Type>;
  conference?: InputMaybe<ConferenceCreateNestedOneWithoutLead_ActivitiesInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  curr_lead_val?: InputMaybe<Scalars['Float']['input']>;
  current_implied_action?: InputMaybe<Hingepoint>;
  customer_number?: InputMaybe<Scalars['String']['input']>;
  customer_number_country_code?: InputMaybe<Scalars['String']['input']>;
  dedupe_migration?: InputMaybe<Scalars['Boolean']['input']>;
  email_item?: InputMaybe<EmailItemCreateNestedOneWithoutLead_ActivityInput>;
  from_action?: InputMaybe<Scalars['String']['input']>;
  from_callback_type?: InputMaybe<CallbackType>;
  from_intent_type?: InputMaybe<IntentType>;
  html?: InputMaybe<Scalars['Boolean']['input']>;
  hubspot_email_engagement_id?: InputMaybe<Scalars['String']['input']>;
  hubspot_id?: InputMaybe<Scalars['String']['input']>;
  hubspot_note_engagement_id?: InputMaybe<Scalars['String']['input']>;
  hubspot_owner_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  ignore_sequences?: InputMaybe<Scalars['Boolean']['input']>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventCreateNestedManyWithoutLeadActivityInput>;
  initial_days_set_out?: InputMaybe<Scalars['Float']['input']>;
  is_resting_adjustment?: InputMaybe<Scalars['Boolean']['input']>;
  lead: LeadCreateNestedOneWithoutLead_ActivitiesInput;
  lead_current_sequence?: InputMaybe<SequenceCreateNestedOneWithoutLeadActivitiesInput>;
  lead_current_step?: InputMaybe<SequenceStepCreateNestedOneWithoutLeadActivityInput>;
  local_number?: InputMaybe<TwilioNumberCreateNestedOneWithoutLead_ActivitiesInput>;
  next_action?: InputMaybe<Scalars['String']['input']>;
  next_callback_type?: InputMaybe<CallbackType>;
  notes?: InputMaybe<Scalars['String']['input']>;
  organization?: InputMaybe<OrganizationCreateNestedOneWithoutLead_ActivitiesInput>;
  originating_schedule_item?: InputMaybe<ScheduleItemCreateNestedOneWithoutResulting_ActivityInput>;
  previous_lead_val?: InputMaybe<Scalars['Float']['input']>;
  published?: InputMaybe<Scalars['Boolean']['input']>;
  related_disposition?: InputMaybe<DispositionCreateNestedOneWithoutLead_ActivitiesInput>;
  resting_date_on_recycle?: InputMaybe<Scalars['DateTime']['input']>;
  resting_type_on_recycle?: InputMaybe<Restingtype>;
  sale?: InputMaybe<SaleCreateNestedOneWithoutLead_ActivityInput>;
  sale_cycle?: InputMaybe<SaleCycleCreateNestedOneWithoutLead_ActivitiesInput>;
  schedule_item?: InputMaybe<ScheduleItemCreateNestedOneWithoutLead_ActivityInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutLead_ActivityInput>;
  site?: InputMaybe<SiteCreateNestedOneWithoutLead_ActivitiesInput>;
  skip_reason?: InputMaybe<Scalars['String']['input']>;
  SMSItem?: InputMaybe<SmsItemCreateNestedOneWithoutLead_ActivityInput>;
  stat_item_aggregation?: InputMaybe<StatItemAggregationCreateNestedOneWithoutLead_ActivityInput>;
  stat_item_aggregation_pipeline?: InputMaybe<StatItemAggregationCreateNestedOneWithoutLead_Activity_PipelineInput>;
  StepActionLog?: InputMaybe<StepActionLogCreateNestedManyWithoutLeadActivityInput>;
  team?: InputMaybe<TeamCreateNestedOneWithoutLead_ActivitiesInput>;
  title?: InputMaybe<Scalars['String']['input']>;
  twilio_action?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user?: InputMaybe<UserCreateNestedOneWithoutLead_ActivitiesInput>;
  user_role?: InputMaybe<Role>;
};

export type LeadActivityCreateWithoutLead_Current_SequenceInput = {
  associate_child?: InputMaybe<LeadCreateNestedOneWithoutChild_Lead_ActivitesInput>;
  associate_group_ids?: InputMaybe<LeadActivityCreateassociate_Group_IdsInput>;
  associate_group_leads?: InputMaybe<LeadCreateNestedManyWithoutAssociate_Group_Lead_ActivitiesInput>;
  associated_action?: InputMaybe<Scalars['String']['input']>;
  automated_campaign?: InputMaybe<AutomatedCampaignCreateNestedOneWithoutLead_ActivitiesInput>;
  call_completed_with?: InputMaybe<Callresult>;
  call_result_deleted?: InputMaybe<Scalars['Boolean']['input']>;
  campaign_event?: InputMaybe<ScheduleItemCreateNestedOneWithoutCampaign_ActivitiesInput>;
  channel?: InputMaybe<Channel>;
  communication_type?: InputMaybe<Communication_Type>;
  conference?: InputMaybe<ConferenceCreateNestedOneWithoutLead_ActivitiesInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  curr_lead_val?: InputMaybe<Scalars['Float']['input']>;
  current_implied_action?: InputMaybe<Hingepoint>;
  customer_number?: InputMaybe<Scalars['String']['input']>;
  customer_number_country_code?: InputMaybe<Scalars['String']['input']>;
  dedupe_migration?: InputMaybe<Scalars['Boolean']['input']>;
  email_item?: InputMaybe<EmailItemCreateNestedOneWithoutLead_ActivityInput>;
  from_action?: InputMaybe<Scalars['String']['input']>;
  from_callback_type?: InputMaybe<CallbackType>;
  from_intent_type?: InputMaybe<IntentType>;
  html?: InputMaybe<Scalars['Boolean']['input']>;
  hubspot_email_engagement_id?: InputMaybe<Scalars['String']['input']>;
  hubspot_id?: InputMaybe<Scalars['String']['input']>;
  hubspot_note_engagement_id?: InputMaybe<Scalars['String']['input']>;
  hubspot_owner_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  ignore_sequences?: InputMaybe<Scalars['Boolean']['input']>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventCreateNestedManyWithoutLeadActivityInput>;
  initial_days_set_out?: InputMaybe<Scalars['Float']['input']>;
  is_resting_adjustment?: InputMaybe<Scalars['Boolean']['input']>;
  lead: LeadCreateNestedOneWithoutLead_ActivitiesInput;
  lead_assignment_history_change?: InputMaybe<LeadAssignmentHistoryCreateNestedOneWithoutLead_ActivityInput>;
  lead_current_step?: InputMaybe<SequenceStepCreateNestedOneWithoutLeadActivityInput>;
  local_number?: InputMaybe<TwilioNumberCreateNestedOneWithoutLead_ActivitiesInput>;
  next_action?: InputMaybe<Scalars['String']['input']>;
  next_callback_type?: InputMaybe<CallbackType>;
  notes?: InputMaybe<Scalars['String']['input']>;
  organization?: InputMaybe<OrganizationCreateNestedOneWithoutLead_ActivitiesInput>;
  originating_schedule_item?: InputMaybe<ScheduleItemCreateNestedOneWithoutResulting_ActivityInput>;
  previous_lead_val?: InputMaybe<Scalars['Float']['input']>;
  published?: InputMaybe<Scalars['Boolean']['input']>;
  related_disposition?: InputMaybe<DispositionCreateNestedOneWithoutLead_ActivitiesInput>;
  resting_date_on_recycle?: InputMaybe<Scalars['DateTime']['input']>;
  resting_type_on_recycle?: InputMaybe<Restingtype>;
  sale?: InputMaybe<SaleCreateNestedOneWithoutLead_ActivityInput>;
  sale_cycle?: InputMaybe<SaleCycleCreateNestedOneWithoutLead_ActivitiesInput>;
  schedule_item?: InputMaybe<ScheduleItemCreateNestedOneWithoutLead_ActivityInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutLead_ActivityInput>;
  site?: InputMaybe<SiteCreateNestedOneWithoutLead_ActivitiesInput>;
  skip_reason?: InputMaybe<Scalars['String']['input']>;
  SMSItem?: InputMaybe<SmsItemCreateNestedOneWithoutLead_ActivityInput>;
  stat_item_aggregation?: InputMaybe<StatItemAggregationCreateNestedOneWithoutLead_ActivityInput>;
  stat_item_aggregation_pipeline?: InputMaybe<StatItemAggregationCreateNestedOneWithoutLead_Activity_PipelineInput>;
  StepActionLog?: InputMaybe<StepActionLogCreateNestedManyWithoutLeadActivityInput>;
  team?: InputMaybe<TeamCreateNestedOneWithoutLead_ActivitiesInput>;
  title?: InputMaybe<Scalars['String']['input']>;
  twilio_action?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user?: InputMaybe<UserCreateNestedOneWithoutLead_ActivitiesInput>;
  user_role?: InputMaybe<Role>;
};

export type LeadActivityCreateWithoutLead_Current_StepInput = {
  associate_child?: InputMaybe<LeadCreateNestedOneWithoutChild_Lead_ActivitesInput>;
  associate_group_ids?: InputMaybe<LeadActivityCreateassociate_Group_IdsInput>;
  associate_group_leads?: InputMaybe<LeadCreateNestedManyWithoutAssociate_Group_Lead_ActivitiesInput>;
  associated_action?: InputMaybe<Scalars['String']['input']>;
  automated_campaign?: InputMaybe<AutomatedCampaignCreateNestedOneWithoutLead_ActivitiesInput>;
  call_completed_with?: InputMaybe<Callresult>;
  call_result_deleted?: InputMaybe<Scalars['Boolean']['input']>;
  campaign_event?: InputMaybe<ScheduleItemCreateNestedOneWithoutCampaign_ActivitiesInput>;
  channel?: InputMaybe<Channel>;
  communication_type?: InputMaybe<Communication_Type>;
  conference?: InputMaybe<ConferenceCreateNestedOneWithoutLead_ActivitiesInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  curr_lead_val?: InputMaybe<Scalars['Float']['input']>;
  current_implied_action?: InputMaybe<Hingepoint>;
  customer_number?: InputMaybe<Scalars['String']['input']>;
  customer_number_country_code?: InputMaybe<Scalars['String']['input']>;
  dedupe_migration?: InputMaybe<Scalars['Boolean']['input']>;
  email_item?: InputMaybe<EmailItemCreateNestedOneWithoutLead_ActivityInput>;
  from_action?: InputMaybe<Scalars['String']['input']>;
  from_callback_type?: InputMaybe<CallbackType>;
  from_intent_type?: InputMaybe<IntentType>;
  html?: InputMaybe<Scalars['Boolean']['input']>;
  hubspot_email_engagement_id?: InputMaybe<Scalars['String']['input']>;
  hubspot_id?: InputMaybe<Scalars['String']['input']>;
  hubspot_note_engagement_id?: InputMaybe<Scalars['String']['input']>;
  hubspot_owner_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  ignore_sequences?: InputMaybe<Scalars['Boolean']['input']>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventCreateNestedManyWithoutLeadActivityInput>;
  initial_days_set_out?: InputMaybe<Scalars['Float']['input']>;
  is_resting_adjustment?: InputMaybe<Scalars['Boolean']['input']>;
  lead: LeadCreateNestedOneWithoutLead_ActivitiesInput;
  lead_assignment_history_change?: InputMaybe<LeadAssignmentHistoryCreateNestedOneWithoutLead_ActivityInput>;
  lead_current_sequence?: InputMaybe<SequenceCreateNestedOneWithoutLeadActivitiesInput>;
  local_number?: InputMaybe<TwilioNumberCreateNestedOneWithoutLead_ActivitiesInput>;
  next_action?: InputMaybe<Scalars['String']['input']>;
  next_callback_type?: InputMaybe<CallbackType>;
  notes?: InputMaybe<Scalars['String']['input']>;
  organization?: InputMaybe<OrganizationCreateNestedOneWithoutLead_ActivitiesInput>;
  originating_schedule_item?: InputMaybe<ScheduleItemCreateNestedOneWithoutResulting_ActivityInput>;
  previous_lead_val?: InputMaybe<Scalars['Float']['input']>;
  published?: InputMaybe<Scalars['Boolean']['input']>;
  related_disposition?: InputMaybe<DispositionCreateNestedOneWithoutLead_ActivitiesInput>;
  resting_date_on_recycle?: InputMaybe<Scalars['DateTime']['input']>;
  resting_type_on_recycle?: InputMaybe<Restingtype>;
  sale?: InputMaybe<SaleCreateNestedOneWithoutLead_ActivityInput>;
  sale_cycle?: InputMaybe<SaleCycleCreateNestedOneWithoutLead_ActivitiesInput>;
  schedule_item?: InputMaybe<ScheduleItemCreateNestedOneWithoutLead_ActivityInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutLead_ActivityInput>;
  site?: InputMaybe<SiteCreateNestedOneWithoutLead_ActivitiesInput>;
  skip_reason?: InputMaybe<Scalars['String']['input']>;
  SMSItem?: InputMaybe<SmsItemCreateNestedOneWithoutLead_ActivityInput>;
  stat_item_aggregation?: InputMaybe<StatItemAggregationCreateNestedOneWithoutLead_ActivityInput>;
  stat_item_aggregation_pipeline?: InputMaybe<StatItemAggregationCreateNestedOneWithoutLead_Activity_PipelineInput>;
  StepActionLog?: InputMaybe<StepActionLogCreateNestedManyWithoutLeadActivityInput>;
  team?: InputMaybe<TeamCreateNestedOneWithoutLead_ActivitiesInput>;
  title?: InputMaybe<Scalars['String']['input']>;
  twilio_action?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user?: InputMaybe<UserCreateNestedOneWithoutLead_ActivitiesInput>;
  user_role?: InputMaybe<Role>;
};

export type LeadActivityCreateWithoutLeadInput = {
  associate_child?: InputMaybe<LeadCreateNestedOneWithoutChild_Lead_ActivitesInput>;
  associate_group_ids?: InputMaybe<LeadActivityCreateassociate_Group_IdsInput>;
  associate_group_leads?: InputMaybe<LeadCreateNestedManyWithoutAssociate_Group_Lead_ActivitiesInput>;
  associated_action?: InputMaybe<Scalars['String']['input']>;
  automated_campaign?: InputMaybe<AutomatedCampaignCreateNestedOneWithoutLead_ActivitiesInput>;
  call_completed_with?: InputMaybe<Callresult>;
  call_result_deleted?: InputMaybe<Scalars['Boolean']['input']>;
  campaign_event?: InputMaybe<ScheduleItemCreateNestedOneWithoutCampaign_ActivitiesInput>;
  channel?: InputMaybe<Channel>;
  communication_type?: InputMaybe<Communication_Type>;
  conference?: InputMaybe<ConferenceCreateNestedOneWithoutLead_ActivitiesInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  curr_lead_val?: InputMaybe<Scalars['Float']['input']>;
  current_implied_action?: InputMaybe<Hingepoint>;
  customer_number?: InputMaybe<Scalars['String']['input']>;
  customer_number_country_code?: InputMaybe<Scalars['String']['input']>;
  dedupe_migration?: InputMaybe<Scalars['Boolean']['input']>;
  email_item?: InputMaybe<EmailItemCreateNestedOneWithoutLead_ActivityInput>;
  from_action?: InputMaybe<Scalars['String']['input']>;
  from_callback_type?: InputMaybe<CallbackType>;
  from_intent_type?: InputMaybe<IntentType>;
  html?: InputMaybe<Scalars['Boolean']['input']>;
  hubspot_email_engagement_id?: InputMaybe<Scalars['String']['input']>;
  hubspot_id?: InputMaybe<Scalars['String']['input']>;
  hubspot_note_engagement_id?: InputMaybe<Scalars['String']['input']>;
  hubspot_owner_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  ignore_sequences?: InputMaybe<Scalars['Boolean']['input']>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventCreateNestedManyWithoutLeadActivityInput>;
  initial_days_set_out?: InputMaybe<Scalars['Float']['input']>;
  is_resting_adjustment?: InputMaybe<Scalars['Boolean']['input']>;
  lead_assignment_history_change?: InputMaybe<LeadAssignmentHistoryCreateNestedOneWithoutLead_ActivityInput>;
  lead_current_sequence?: InputMaybe<SequenceCreateNestedOneWithoutLeadActivitiesInput>;
  lead_current_step?: InputMaybe<SequenceStepCreateNestedOneWithoutLeadActivityInput>;
  local_number?: InputMaybe<TwilioNumberCreateNestedOneWithoutLead_ActivitiesInput>;
  next_action?: InputMaybe<Scalars['String']['input']>;
  next_callback_type?: InputMaybe<CallbackType>;
  notes?: InputMaybe<Scalars['String']['input']>;
  organization?: InputMaybe<OrganizationCreateNestedOneWithoutLead_ActivitiesInput>;
  originating_schedule_item?: InputMaybe<ScheduleItemCreateNestedOneWithoutResulting_ActivityInput>;
  previous_lead_val?: InputMaybe<Scalars['Float']['input']>;
  published?: InputMaybe<Scalars['Boolean']['input']>;
  related_disposition?: InputMaybe<DispositionCreateNestedOneWithoutLead_ActivitiesInput>;
  resting_date_on_recycle?: InputMaybe<Scalars['DateTime']['input']>;
  resting_type_on_recycle?: InputMaybe<Restingtype>;
  sale?: InputMaybe<SaleCreateNestedOneWithoutLead_ActivityInput>;
  sale_cycle?: InputMaybe<SaleCycleCreateNestedOneWithoutLead_ActivitiesInput>;
  schedule_item?: InputMaybe<ScheduleItemCreateNestedOneWithoutLead_ActivityInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutLead_ActivityInput>;
  site?: InputMaybe<SiteCreateNestedOneWithoutLead_ActivitiesInput>;
  skip_reason?: InputMaybe<Scalars['String']['input']>;
  SMSItem?: InputMaybe<SmsItemCreateNestedOneWithoutLead_ActivityInput>;
  stat_item_aggregation?: InputMaybe<StatItemAggregationCreateNestedOneWithoutLead_ActivityInput>;
  stat_item_aggregation_pipeline?: InputMaybe<StatItemAggregationCreateNestedOneWithoutLead_Activity_PipelineInput>;
  StepActionLog?: InputMaybe<StepActionLogCreateNestedManyWithoutLeadActivityInput>;
  team?: InputMaybe<TeamCreateNestedOneWithoutLead_ActivitiesInput>;
  title?: InputMaybe<Scalars['String']['input']>;
  twilio_action?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user?: InputMaybe<UserCreateNestedOneWithoutLead_ActivitiesInput>;
  user_role?: InputMaybe<Role>;
};

export type LeadActivityCreateWithoutLocal_NumberInput = {
  associate_child?: InputMaybe<LeadCreateNestedOneWithoutChild_Lead_ActivitesInput>;
  associate_group_ids?: InputMaybe<LeadActivityCreateassociate_Group_IdsInput>;
  associate_group_leads?: InputMaybe<LeadCreateNestedManyWithoutAssociate_Group_Lead_ActivitiesInput>;
  associated_action?: InputMaybe<Scalars['String']['input']>;
  automated_campaign?: InputMaybe<AutomatedCampaignCreateNestedOneWithoutLead_ActivitiesInput>;
  call_completed_with?: InputMaybe<Callresult>;
  call_result_deleted?: InputMaybe<Scalars['Boolean']['input']>;
  campaign_event?: InputMaybe<ScheduleItemCreateNestedOneWithoutCampaign_ActivitiesInput>;
  channel?: InputMaybe<Channel>;
  communication_type?: InputMaybe<Communication_Type>;
  conference?: InputMaybe<ConferenceCreateNestedOneWithoutLead_ActivitiesInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  curr_lead_val?: InputMaybe<Scalars['Float']['input']>;
  current_implied_action?: InputMaybe<Hingepoint>;
  customer_number?: InputMaybe<Scalars['String']['input']>;
  customer_number_country_code?: InputMaybe<Scalars['String']['input']>;
  dedupe_migration?: InputMaybe<Scalars['Boolean']['input']>;
  email_item?: InputMaybe<EmailItemCreateNestedOneWithoutLead_ActivityInput>;
  from_action?: InputMaybe<Scalars['String']['input']>;
  from_callback_type?: InputMaybe<CallbackType>;
  from_intent_type?: InputMaybe<IntentType>;
  html?: InputMaybe<Scalars['Boolean']['input']>;
  hubspot_email_engagement_id?: InputMaybe<Scalars['String']['input']>;
  hubspot_id?: InputMaybe<Scalars['String']['input']>;
  hubspot_note_engagement_id?: InputMaybe<Scalars['String']['input']>;
  hubspot_owner_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  ignore_sequences?: InputMaybe<Scalars['Boolean']['input']>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventCreateNestedManyWithoutLeadActivityInput>;
  initial_days_set_out?: InputMaybe<Scalars['Float']['input']>;
  is_resting_adjustment?: InputMaybe<Scalars['Boolean']['input']>;
  lead: LeadCreateNestedOneWithoutLead_ActivitiesInput;
  lead_assignment_history_change?: InputMaybe<LeadAssignmentHistoryCreateNestedOneWithoutLead_ActivityInput>;
  lead_current_sequence?: InputMaybe<SequenceCreateNestedOneWithoutLeadActivitiesInput>;
  lead_current_step?: InputMaybe<SequenceStepCreateNestedOneWithoutLeadActivityInput>;
  next_action?: InputMaybe<Scalars['String']['input']>;
  next_callback_type?: InputMaybe<CallbackType>;
  notes?: InputMaybe<Scalars['String']['input']>;
  organization?: InputMaybe<OrganizationCreateNestedOneWithoutLead_ActivitiesInput>;
  originating_schedule_item?: InputMaybe<ScheduleItemCreateNestedOneWithoutResulting_ActivityInput>;
  previous_lead_val?: InputMaybe<Scalars['Float']['input']>;
  published?: InputMaybe<Scalars['Boolean']['input']>;
  related_disposition?: InputMaybe<DispositionCreateNestedOneWithoutLead_ActivitiesInput>;
  resting_date_on_recycle?: InputMaybe<Scalars['DateTime']['input']>;
  resting_type_on_recycle?: InputMaybe<Restingtype>;
  sale?: InputMaybe<SaleCreateNestedOneWithoutLead_ActivityInput>;
  sale_cycle?: InputMaybe<SaleCycleCreateNestedOneWithoutLead_ActivitiesInput>;
  schedule_item?: InputMaybe<ScheduleItemCreateNestedOneWithoutLead_ActivityInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutLead_ActivityInput>;
  site?: InputMaybe<SiteCreateNestedOneWithoutLead_ActivitiesInput>;
  skip_reason?: InputMaybe<Scalars['String']['input']>;
  SMSItem?: InputMaybe<SmsItemCreateNestedOneWithoutLead_ActivityInput>;
  stat_item_aggregation?: InputMaybe<StatItemAggregationCreateNestedOneWithoutLead_ActivityInput>;
  stat_item_aggregation_pipeline?: InputMaybe<StatItemAggregationCreateNestedOneWithoutLead_Activity_PipelineInput>;
  StepActionLog?: InputMaybe<StepActionLogCreateNestedManyWithoutLeadActivityInput>;
  team?: InputMaybe<TeamCreateNestedOneWithoutLead_ActivitiesInput>;
  title?: InputMaybe<Scalars['String']['input']>;
  twilio_action?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user?: InputMaybe<UserCreateNestedOneWithoutLead_ActivitiesInput>;
  user_role?: InputMaybe<Role>;
};

export type LeadActivityCreateWithoutOrganizationInput = {
  associate_child?: InputMaybe<LeadCreateNestedOneWithoutChild_Lead_ActivitesInput>;
  associate_group_ids?: InputMaybe<LeadActivityCreateassociate_Group_IdsInput>;
  associate_group_leads?: InputMaybe<LeadCreateNestedManyWithoutAssociate_Group_Lead_ActivitiesInput>;
  associated_action?: InputMaybe<Scalars['String']['input']>;
  automated_campaign?: InputMaybe<AutomatedCampaignCreateNestedOneWithoutLead_ActivitiesInput>;
  call_completed_with?: InputMaybe<Callresult>;
  call_result_deleted?: InputMaybe<Scalars['Boolean']['input']>;
  campaign_event?: InputMaybe<ScheduleItemCreateNestedOneWithoutCampaign_ActivitiesInput>;
  channel?: InputMaybe<Channel>;
  communication_type?: InputMaybe<Communication_Type>;
  conference?: InputMaybe<ConferenceCreateNestedOneWithoutLead_ActivitiesInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  curr_lead_val?: InputMaybe<Scalars['Float']['input']>;
  current_implied_action?: InputMaybe<Hingepoint>;
  customer_number?: InputMaybe<Scalars['String']['input']>;
  customer_number_country_code?: InputMaybe<Scalars['String']['input']>;
  dedupe_migration?: InputMaybe<Scalars['Boolean']['input']>;
  email_item?: InputMaybe<EmailItemCreateNestedOneWithoutLead_ActivityInput>;
  from_action?: InputMaybe<Scalars['String']['input']>;
  from_callback_type?: InputMaybe<CallbackType>;
  from_intent_type?: InputMaybe<IntentType>;
  html?: InputMaybe<Scalars['Boolean']['input']>;
  hubspot_email_engagement_id?: InputMaybe<Scalars['String']['input']>;
  hubspot_id?: InputMaybe<Scalars['String']['input']>;
  hubspot_note_engagement_id?: InputMaybe<Scalars['String']['input']>;
  hubspot_owner_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  ignore_sequences?: InputMaybe<Scalars['Boolean']['input']>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventCreateNestedManyWithoutLeadActivityInput>;
  initial_days_set_out?: InputMaybe<Scalars['Float']['input']>;
  is_resting_adjustment?: InputMaybe<Scalars['Boolean']['input']>;
  lead: LeadCreateNestedOneWithoutLead_ActivitiesInput;
  lead_assignment_history_change?: InputMaybe<LeadAssignmentHistoryCreateNestedOneWithoutLead_ActivityInput>;
  lead_current_sequence?: InputMaybe<SequenceCreateNestedOneWithoutLeadActivitiesInput>;
  lead_current_step?: InputMaybe<SequenceStepCreateNestedOneWithoutLeadActivityInput>;
  local_number?: InputMaybe<TwilioNumberCreateNestedOneWithoutLead_ActivitiesInput>;
  next_action?: InputMaybe<Scalars['String']['input']>;
  next_callback_type?: InputMaybe<CallbackType>;
  notes?: InputMaybe<Scalars['String']['input']>;
  originating_schedule_item?: InputMaybe<ScheduleItemCreateNestedOneWithoutResulting_ActivityInput>;
  previous_lead_val?: InputMaybe<Scalars['Float']['input']>;
  published?: InputMaybe<Scalars['Boolean']['input']>;
  related_disposition?: InputMaybe<DispositionCreateNestedOneWithoutLead_ActivitiesInput>;
  resting_date_on_recycle?: InputMaybe<Scalars['DateTime']['input']>;
  resting_type_on_recycle?: InputMaybe<Restingtype>;
  sale?: InputMaybe<SaleCreateNestedOneWithoutLead_ActivityInput>;
  sale_cycle?: InputMaybe<SaleCycleCreateNestedOneWithoutLead_ActivitiesInput>;
  schedule_item?: InputMaybe<ScheduleItemCreateNestedOneWithoutLead_ActivityInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutLead_ActivityInput>;
  site?: InputMaybe<SiteCreateNestedOneWithoutLead_ActivitiesInput>;
  skip_reason?: InputMaybe<Scalars['String']['input']>;
  SMSItem?: InputMaybe<SmsItemCreateNestedOneWithoutLead_ActivityInput>;
  stat_item_aggregation?: InputMaybe<StatItemAggregationCreateNestedOneWithoutLead_ActivityInput>;
  stat_item_aggregation_pipeline?: InputMaybe<StatItemAggregationCreateNestedOneWithoutLead_Activity_PipelineInput>;
  StepActionLog?: InputMaybe<StepActionLogCreateNestedManyWithoutLeadActivityInput>;
  team?: InputMaybe<TeamCreateNestedOneWithoutLead_ActivitiesInput>;
  title?: InputMaybe<Scalars['String']['input']>;
  twilio_action?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user?: InputMaybe<UserCreateNestedOneWithoutLead_ActivitiesInput>;
  user_role?: InputMaybe<Role>;
};

export type LeadActivityCreateWithoutOriginating_Schedule_ItemInput = {
  associate_child?: InputMaybe<LeadCreateNestedOneWithoutChild_Lead_ActivitesInput>;
  associate_group_ids?: InputMaybe<LeadActivityCreateassociate_Group_IdsInput>;
  associate_group_leads?: InputMaybe<LeadCreateNestedManyWithoutAssociate_Group_Lead_ActivitiesInput>;
  associated_action?: InputMaybe<Scalars['String']['input']>;
  automated_campaign?: InputMaybe<AutomatedCampaignCreateNestedOneWithoutLead_ActivitiesInput>;
  call_completed_with?: InputMaybe<Callresult>;
  call_result_deleted?: InputMaybe<Scalars['Boolean']['input']>;
  campaign_event?: InputMaybe<ScheduleItemCreateNestedOneWithoutCampaign_ActivitiesInput>;
  channel?: InputMaybe<Channel>;
  communication_type?: InputMaybe<Communication_Type>;
  conference?: InputMaybe<ConferenceCreateNestedOneWithoutLead_ActivitiesInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  curr_lead_val?: InputMaybe<Scalars['Float']['input']>;
  current_implied_action?: InputMaybe<Hingepoint>;
  customer_number?: InputMaybe<Scalars['String']['input']>;
  customer_number_country_code?: InputMaybe<Scalars['String']['input']>;
  dedupe_migration?: InputMaybe<Scalars['Boolean']['input']>;
  email_item?: InputMaybe<EmailItemCreateNestedOneWithoutLead_ActivityInput>;
  from_action?: InputMaybe<Scalars['String']['input']>;
  from_callback_type?: InputMaybe<CallbackType>;
  from_intent_type?: InputMaybe<IntentType>;
  html?: InputMaybe<Scalars['Boolean']['input']>;
  hubspot_email_engagement_id?: InputMaybe<Scalars['String']['input']>;
  hubspot_id?: InputMaybe<Scalars['String']['input']>;
  hubspot_note_engagement_id?: InputMaybe<Scalars['String']['input']>;
  hubspot_owner_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  ignore_sequences?: InputMaybe<Scalars['Boolean']['input']>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventCreateNestedManyWithoutLeadActivityInput>;
  initial_days_set_out?: InputMaybe<Scalars['Float']['input']>;
  is_resting_adjustment?: InputMaybe<Scalars['Boolean']['input']>;
  lead: LeadCreateNestedOneWithoutLead_ActivitiesInput;
  lead_assignment_history_change?: InputMaybe<LeadAssignmentHistoryCreateNestedOneWithoutLead_ActivityInput>;
  lead_current_sequence?: InputMaybe<SequenceCreateNestedOneWithoutLeadActivitiesInput>;
  lead_current_step?: InputMaybe<SequenceStepCreateNestedOneWithoutLeadActivityInput>;
  local_number?: InputMaybe<TwilioNumberCreateNestedOneWithoutLead_ActivitiesInput>;
  next_action?: InputMaybe<Scalars['String']['input']>;
  next_callback_type?: InputMaybe<CallbackType>;
  notes?: InputMaybe<Scalars['String']['input']>;
  organization?: InputMaybe<OrganizationCreateNestedOneWithoutLead_ActivitiesInput>;
  previous_lead_val?: InputMaybe<Scalars['Float']['input']>;
  published?: InputMaybe<Scalars['Boolean']['input']>;
  related_disposition?: InputMaybe<DispositionCreateNestedOneWithoutLead_ActivitiesInput>;
  resting_date_on_recycle?: InputMaybe<Scalars['DateTime']['input']>;
  resting_type_on_recycle?: InputMaybe<Restingtype>;
  sale?: InputMaybe<SaleCreateNestedOneWithoutLead_ActivityInput>;
  sale_cycle?: InputMaybe<SaleCycleCreateNestedOneWithoutLead_ActivitiesInput>;
  schedule_item?: InputMaybe<ScheduleItemCreateNestedOneWithoutLead_ActivityInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutLead_ActivityInput>;
  site?: InputMaybe<SiteCreateNestedOneWithoutLead_ActivitiesInput>;
  skip_reason?: InputMaybe<Scalars['String']['input']>;
  SMSItem?: InputMaybe<SmsItemCreateNestedOneWithoutLead_ActivityInput>;
  stat_item_aggregation?: InputMaybe<StatItemAggregationCreateNestedOneWithoutLead_ActivityInput>;
  stat_item_aggregation_pipeline?: InputMaybe<StatItemAggregationCreateNestedOneWithoutLead_Activity_PipelineInput>;
  StepActionLog?: InputMaybe<StepActionLogCreateNestedManyWithoutLeadActivityInput>;
  team?: InputMaybe<TeamCreateNestedOneWithoutLead_ActivitiesInput>;
  title?: InputMaybe<Scalars['String']['input']>;
  twilio_action?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user?: InputMaybe<UserCreateNestedOneWithoutLead_ActivitiesInput>;
  user_role?: InputMaybe<Role>;
};

export type LeadActivityCreateWithoutRelated_DispositionInput = {
  associate_child?: InputMaybe<LeadCreateNestedOneWithoutChild_Lead_ActivitesInput>;
  associate_group_ids?: InputMaybe<LeadActivityCreateassociate_Group_IdsInput>;
  associate_group_leads?: InputMaybe<LeadCreateNestedManyWithoutAssociate_Group_Lead_ActivitiesInput>;
  associated_action?: InputMaybe<Scalars['String']['input']>;
  automated_campaign?: InputMaybe<AutomatedCampaignCreateNestedOneWithoutLead_ActivitiesInput>;
  call_completed_with?: InputMaybe<Callresult>;
  call_result_deleted?: InputMaybe<Scalars['Boolean']['input']>;
  campaign_event?: InputMaybe<ScheduleItemCreateNestedOneWithoutCampaign_ActivitiesInput>;
  channel?: InputMaybe<Channel>;
  communication_type?: InputMaybe<Communication_Type>;
  conference?: InputMaybe<ConferenceCreateNestedOneWithoutLead_ActivitiesInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  curr_lead_val?: InputMaybe<Scalars['Float']['input']>;
  current_implied_action?: InputMaybe<Hingepoint>;
  customer_number?: InputMaybe<Scalars['String']['input']>;
  customer_number_country_code?: InputMaybe<Scalars['String']['input']>;
  dedupe_migration?: InputMaybe<Scalars['Boolean']['input']>;
  email_item?: InputMaybe<EmailItemCreateNestedOneWithoutLead_ActivityInput>;
  from_action?: InputMaybe<Scalars['String']['input']>;
  from_callback_type?: InputMaybe<CallbackType>;
  from_intent_type?: InputMaybe<IntentType>;
  html?: InputMaybe<Scalars['Boolean']['input']>;
  hubspot_email_engagement_id?: InputMaybe<Scalars['String']['input']>;
  hubspot_id?: InputMaybe<Scalars['String']['input']>;
  hubspot_note_engagement_id?: InputMaybe<Scalars['String']['input']>;
  hubspot_owner_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  ignore_sequences?: InputMaybe<Scalars['Boolean']['input']>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventCreateNestedManyWithoutLeadActivityInput>;
  initial_days_set_out?: InputMaybe<Scalars['Float']['input']>;
  is_resting_adjustment?: InputMaybe<Scalars['Boolean']['input']>;
  lead: LeadCreateNestedOneWithoutLead_ActivitiesInput;
  lead_assignment_history_change?: InputMaybe<LeadAssignmentHistoryCreateNestedOneWithoutLead_ActivityInput>;
  lead_current_sequence?: InputMaybe<SequenceCreateNestedOneWithoutLeadActivitiesInput>;
  lead_current_step?: InputMaybe<SequenceStepCreateNestedOneWithoutLeadActivityInput>;
  local_number?: InputMaybe<TwilioNumberCreateNestedOneWithoutLead_ActivitiesInput>;
  next_action?: InputMaybe<Scalars['String']['input']>;
  next_callback_type?: InputMaybe<CallbackType>;
  notes?: InputMaybe<Scalars['String']['input']>;
  organization?: InputMaybe<OrganizationCreateNestedOneWithoutLead_ActivitiesInput>;
  originating_schedule_item?: InputMaybe<ScheduleItemCreateNestedOneWithoutResulting_ActivityInput>;
  previous_lead_val?: InputMaybe<Scalars['Float']['input']>;
  published?: InputMaybe<Scalars['Boolean']['input']>;
  resting_date_on_recycle?: InputMaybe<Scalars['DateTime']['input']>;
  resting_type_on_recycle?: InputMaybe<Restingtype>;
  sale?: InputMaybe<SaleCreateNestedOneWithoutLead_ActivityInput>;
  sale_cycle?: InputMaybe<SaleCycleCreateNestedOneWithoutLead_ActivitiesInput>;
  schedule_item?: InputMaybe<ScheduleItemCreateNestedOneWithoutLead_ActivityInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutLead_ActivityInput>;
  site?: InputMaybe<SiteCreateNestedOneWithoutLead_ActivitiesInput>;
  skip_reason?: InputMaybe<Scalars['String']['input']>;
  SMSItem?: InputMaybe<SmsItemCreateNestedOneWithoutLead_ActivityInput>;
  stat_item_aggregation?: InputMaybe<StatItemAggregationCreateNestedOneWithoutLead_ActivityInput>;
  stat_item_aggregation_pipeline?: InputMaybe<StatItemAggregationCreateNestedOneWithoutLead_Activity_PipelineInput>;
  StepActionLog?: InputMaybe<StepActionLogCreateNestedManyWithoutLeadActivityInput>;
  team?: InputMaybe<TeamCreateNestedOneWithoutLead_ActivitiesInput>;
  title?: InputMaybe<Scalars['String']['input']>;
  twilio_action?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user?: InputMaybe<UserCreateNestedOneWithoutLead_ActivitiesInput>;
  user_role?: InputMaybe<Role>;
};

export type LeadActivityCreateWithoutSale_CycleInput = {
  associate_child?: InputMaybe<LeadCreateNestedOneWithoutChild_Lead_ActivitesInput>;
  associate_group_ids?: InputMaybe<LeadActivityCreateassociate_Group_IdsInput>;
  associate_group_leads?: InputMaybe<LeadCreateNestedManyWithoutAssociate_Group_Lead_ActivitiesInput>;
  associated_action?: InputMaybe<Scalars['String']['input']>;
  automated_campaign?: InputMaybe<AutomatedCampaignCreateNestedOneWithoutLead_ActivitiesInput>;
  call_completed_with?: InputMaybe<Callresult>;
  call_result_deleted?: InputMaybe<Scalars['Boolean']['input']>;
  campaign_event?: InputMaybe<ScheduleItemCreateNestedOneWithoutCampaign_ActivitiesInput>;
  channel?: InputMaybe<Channel>;
  communication_type?: InputMaybe<Communication_Type>;
  conference?: InputMaybe<ConferenceCreateNestedOneWithoutLead_ActivitiesInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  curr_lead_val?: InputMaybe<Scalars['Float']['input']>;
  current_implied_action?: InputMaybe<Hingepoint>;
  customer_number?: InputMaybe<Scalars['String']['input']>;
  customer_number_country_code?: InputMaybe<Scalars['String']['input']>;
  dedupe_migration?: InputMaybe<Scalars['Boolean']['input']>;
  email_item?: InputMaybe<EmailItemCreateNestedOneWithoutLead_ActivityInput>;
  from_action?: InputMaybe<Scalars['String']['input']>;
  from_callback_type?: InputMaybe<CallbackType>;
  from_intent_type?: InputMaybe<IntentType>;
  html?: InputMaybe<Scalars['Boolean']['input']>;
  hubspot_email_engagement_id?: InputMaybe<Scalars['String']['input']>;
  hubspot_id?: InputMaybe<Scalars['String']['input']>;
  hubspot_note_engagement_id?: InputMaybe<Scalars['String']['input']>;
  hubspot_owner_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  ignore_sequences?: InputMaybe<Scalars['Boolean']['input']>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventCreateNestedManyWithoutLeadActivityInput>;
  initial_days_set_out?: InputMaybe<Scalars['Float']['input']>;
  is_resting_adjustment?: InputMaybe<Scalars['Boolean']['input']>;
  lead: LeadCreateNestedOneWithoutLead_ActivitiesInput;
  lead_assignment_history_change?: InputMaybe<LeadAssignmentHistoryCreateNestedOneWithoutLead_ActivityInput>;
  lead_current_sequence?: InputMaybe<SequenceCreateNestedOneWithoutLeadActivitiesInput>;
  lead_current_step?: InputMaybe<SequenceStepCreateNestedOneWithoutLeadActivityInput>;
  local_number?: InputMaybe<TwilioNumberCreateNestedOneWithoutLead_ActivitiesInput>;
  next_action?: InputMaybe<Scalars['String']['input']>;
  next_callback_type?: InputMaybe<CallbackType>;
  notes?: InputMaybe<Scalars['String']['input']>;
  organization?: InputMaybe<OrganizationCreateNestedOneWithoutLead_ActivitiesInput>;
  originating_schedule_item?: InputMaybe<ScheduleItemCreateNestedOneWithoutResulting_ActivityInput>;
  previous_lead_val?: InputMaybe<Scalars['Float']['input']>;
  published?: InputMaybe<Scalars['Boolean']['input']>;
  related_disposition?: InputMaybe<DispositionCreateNestedOneWithoutLead_ActivitiesInput>;
  resting_date_on_recycle?: InputMaybe<Scalars['DateTime']['input']>;
  resting_type_on_recycle?: InputMaybe<Restingtype>;
  sale?: InputMaybe<SaleCreateNestedOneWithoutLead_ActivityInput>;
  schedule_item?: InputMaybe<ScheduleItemCreateNestedOneWithoutLead_ActivityInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutLead_ActivityInput>;
  site?: InputMaybe<SiteCreateNestedOneWithoutLead_ActivitiesInput>;
  skip_reason?: InputMaybe<Scalars['String']['input']>;
  SMSItem?: InputMaybe<SmsItemCreateNestedOneWithoutLead_ActivityInput>;
  stat_item_aggregation?: InputMaybe<StatItemAggregationCreateNestedOneWithoutLead_ActivityInput>;
  stat_item_aggregation_pipeline?: InputMaybe<StatItemAggregationCreateNestedOneWithoutLead_Activity_PipelineInput>;
  StepActionLog?: InputMaybe<StepActionLogCreateNestedManyWithoutLeadActivityInput>;
  team?: InputMaybe<TeamCreateNestedOneWithoutLead_ActivitiesInput>;
  title?: InputMaybe<Scalars['String']['input']>;
  twilio_action?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user?: InputMaybe<UserCreateNestedOneWithoutLead_ActivitiesInput>;
  user_role?: InputMaybe<Role>;
};

export type LeadActivityCreateWithoutSaleInput = {
  associate_child?: InputMaybe<LeadCreateNestedOneWithoutChild_Lead_ActivitesInput>;
  associate_group_ids?: InputMaybe<LeadActivityCreateassociate_Group_IdsInput>;
  associate_group_leads?: InputMaybe<LeadCreateNestedManyWithoutAssociate_Group_Lead_ActivitiesInput>;
  associated_action?: InputMaybe<Scalars['String']['input']>;
  automated_campaign?: InputMaybe<AutomatedCampaignCreateNestedOneWithoutLead_ActivitiesInput>;
  call_completed_with?: InputMaybe<Callresult>;
  call_result_deleted?: InputMaybe<Scalars['Boolean']['input']>;
  campaign_event?: InputMaybe<ScheduleItemCreateNestedOneWithoutCampaign_ActivitiesInput>;
  channel?: InputMaybe<Channel>;
  communication_type?: InputMaybe<Communication_Type>;
  conference?: InputMaybe<ConferenceCreateNestedOneWithoutLead_ActivitiesInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  curr_lead_val?: InputMaybe<Scalars['Float']['input']>;
  current_implied_action?: InputMaybe<Hingepoint>;
  customer_number?: InputMaybe<Scalars['String']['input']>;
  customer_number_country_code?: InputMaybe<Scalars['String']['input']>;
  dedupe_migration?: InputMaybe<Scalars['Boolean']['input']>;
  email_item?: InputMaybe<EmailItemCreateNestedOneWithoutLead_ActivityInput>;
  from_action?: InputMaybe<Scalars['String']['input']>;
  from_callback_type?: InputMaybe<CallbackType>;
  from_intent_type?: InputMaybe<IntentType>;
  html?: InputMaybe<Scalars['Boolean']['input']>;
  hubspot_email_engagement_id?: InputMaybe<Scalars['String']['input']>;
  hubspot_id?: InputMaybe<Scalars['String']['input']>;
  hubspot_note_engagement_id?: InputMaybe<Scalars['String']['input']>;
  hubspot_owner_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  ignore_sequences?: InputMaybe<Scalars['Boolean']['input']>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventCreateNestedManyWithoutLeadActivityInput>;
  initial_days_set_out?: InputMaybe<Scalars['Float']['input']>;
  is_resting_adjustment?: InputMaybe<Scalars['Boolean']['input']>;
  lead: LeadCreateNestedOneWithoutLead_ActivitiesInput;
  lead_assignment_history_change?: InputMaybe<LeadAssignmentHistoryCreateNestedOneWithoutLead_ActivityInput>;
  lead_current_sequence?: InputMaybe<SequenceCreateNestedOneWithoutLeadActivitiesInput>;
  lead_current_step?: InputMaybe<SequenceStepCreateNestedOneWithoutLeadActivityInput>;
  local_number?: InputMaybe<TwilioNumberCreateNestedOneWithoutLead_ActivitiesInput>;
  next_action?: InputMaybe<Scalars['String']['input']>;
  next_callback_type?: InputMaybe<CallbackType>;
  notes?: InputMaybe<Scalars['String']['input']>;
  organization?: InputMaybe<OrganizationCreateNestedOneWithoutLead_ActivitiesInput>;
  originating_schedule_item?: InputMaybe<ScheduleItemCreateNestedOneWithoutResulting_ActivityInput>;
  previous_lead_val?: InputMaybe<Scalars['Float']['input']>;
  published?: InputMaybe<Scalars['Boolean']['input']>;
  related_disposition?: InputMaybe<DispositionCreateNestedOneWithoutLead_ActivitiesInput>;
  resting_date_on_recycle?: InputMaybe<Scalars['DateTime']['input']>;
  resting_type_on_recycle?: InputMaybe<Restingtype>;
  sale_cycle?: InputMaybe<SaleCycleCreateNestedOneWithoutLead_ActivitiesInput>;
  schedule_item?: InputMaybe<ScheduleItemCreateNestedOneWithoutLead_ActivityInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutLead_ActivityInput>;
  site?: InputMaybe<SiteCreateNestedOneWithoutLead_ActivitiesInput>;
  skip_reason?: InputMaybe<Scalars['String']['input']>;
  SMSItem?: InputMaybe<SmsItemCreateNestedOneWithoutLead_ActivityInput>;
  stat_item_aggregation?: InputMaybe<StatItemAggregationCreateNestedOneWithoutLead_ActivityInput>;
  stat_item_aggregation_pipeline?: InputMaybe<StatItemAggregationCreateNestedOneWithoutLead_Activity_PipelineInput>;
  StepActionLog?: InputMaybe<StepActionLogCreateNestedManyWithoutLeadActivityInput>;
  team?: InputMaybe<TeamCreateNestedOneWithoutLead_ActivitiesInput>;
  title?: InputMaybe<Scalars['String']['input']>;
  twilio_action?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user?: InputMaybe<UserCreateNestedOneWithoutLead_ActivitiesInput>;
  user_role?: InputMaybe<Role>;
};

export type LeadActivityCreateWithoutSchedule_ItemInput = {
  associate_child?: InputMaybe<LeadCreateNestedOneWithoutChild_Lead_ActivitesInput>;
  associate_group_ids?: InputMaybe<LeadActivityCreateassociate_Group_IdsInput>;
  associate_group_leads?: InputMaybe<LeadCreateNestedManyWithoutAssociate_Group_Lead_ActivitiesInput>;
  associated_action?: InputMaybe<Scalars['String']['input']>;
  automated_campaign?: InputMaybe<AutomatedCampaignCreateNestedOneWithoutLead_ActivitiesInput>;
  call_completed_with?: InputMaybe<Callresult>;
  call_result_deleted?: InputMaybe<Scalars['Boolean']['input']>;
  campaign_event?: InputMaybe<ScheduleItemCreateNestedOneWithoutCampaign_ActivitiesInput>;
  channel?: InputMaybe<Channel>;
  communication_type?: InputMaybe<Communication_Type>;
  conference?: InputMaybe<ConferenceCreateNestedOneWithoutLead_ActivitiesInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  curr_lead_val?: InputMaybe<Scalars['Float']['input']>;
  current_implied_action?: InputMaybe<Hingepoint>;
  customer_number?: InputMaybe<Scalars['String']['input']>;
  customer_number_country_code?: InputMaybe<Scalars['String']['input']>;
  dedupe_migration?: InputMaybe<Scalars['Boolean']['input']>;
  email_item?: InputMaybe<EmailItemCreateNestedOneWithoutLead_ActivityInput>;
  from_action?: InputMaybe<Scalars['String']['input']>;
  from_callback_type?: InputMaybe<CallbackType>;
  from_intent_type?: InputMaybe<IntentType>;
  html?: InputMaybe<Scalars['Boolean']['input']>;
  hubspot_email_engagement_id?: InputMaybe<Scalars['String']['input']>;
  hubspot_id?: InputMaybe<Scalars['String']['input']>;
  hubspot_note_engagement_id?: InputMaybe<Scalars['String']['input']>;
  hubspot_owner_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  ignore_sequences?: InputMaybe<Scalars['Boolean']['input']>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventCreateNestedManyWithoutLeadActivityInput>;
  initial_days_set_out?: InputMaybe<Scalars['Float']['input']>;
  is_resting_adjustment?: InputMaybe<Scalars['Boolean']['input']>;
  lead: LeadCreateNestedOneWithoutLead_ActivitiesInput;
  lead_assignment_history_change?: InputMaybe<LeadAssignmentHistoryCreateNestedOneWithoutLead_ActivityInput>;
  lead_current_sequence?: InputMaybe<SequenceCreateNestedOneWithoutLeadActivitiesInput>;
  lead_current_step?: InputMaybe<SequenceStepCreateNestedOneWithoutLeadActivityInput>;
  local_number?: InputMaybe<TwilioNumberCreateNestedOneWithoutLead_ActivitiesInput>;
  next_action?: InputMaybe<Scalars['String']['input']>;
  next_callback_type?: InputMaybe<CallbackType>;
  notes?: InputMaybe<Scalars['String']['input']>;
  organization?: InputMaybe<OrganizationCreateNestedOneWithoutLead_ActivitiesInput>;
  originating_schedule_item?: InputMaybe<ScheduleItemCreateNestedOneWithoutResulting_ActivityInput>;
  previous_lead_val?: InputMaybe<Scalars['Float']['input']>;
  published?: InputMaybe<Scalars['Boolean']['input']>;
  related_disposition?: InputMaybe<DispositionCreateNestedOneWithoutLead_ActivitiesInput>;
  resting_date_on_recycle?: InputMaybe<Scalars['DateTime']['input']>;
  resting_type_on_recycle?: InputMaybe<Restingtype>;
  sale?: InputMaybe<SaleCreateNestedOneWithoutLead_ActivityInput>;
  sale_cycle?: InputMaybe<SaleCycleCreateNestedOneWithoutLead_ActivitiesInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutLead_ActivityInput>;
  site?: InputMaybe<SiteCreateNestedOneWithoutLead_ActivitiesInput>;
  skip_reason?: InputMaybe<Scalars['String']['input']>;
  SMSItem?: InputMaybe<SmsItemCreateNestedOneWithoutLead_ActivityInput>;
  stat_item_aggregation?: InputMaybe<StatItemAggregationCreateNestedOneWithoutLead_ActivityInput>;
  stat_item_aggregation_pipeline?: InputMaybe<StatItemAggregationCreateNestedOneWithoutLead_Activity_PipelineInput>;
  StepActionLog?: InputMaybe<StepActionLogCreateNestedManyWithoutLeadActivityInput>;
  team?: InputMaybe<TeamCreateNestedOneWithoutLead_ActivitiesInput>;
  title?: InputMaybe<Scalars['String']['input']>;
  twilio_action?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user?: InputMaybe<UserCreateNestedOneWithoutLead_ActivitiesInput>;
  user_role?: InputMaybe<Role>;
};

export type LeadActivityCreateWithoutSequenceEntryExitLeadInput = {
  associate_child?: InputMaybe<LeadCreateNestedOneWithoutChild_Lead_ActivitesInput>;
  associate_group_ids?: InputMaybe<LeadActivityCreateassociate_Group_IdsInput>;
  associate_group_leads?: InputMaybe<LeadCreateNestedManyWithoutAssociate_Group_Lead_ActivitiesInput>;
  associated_action?: InputMaybe<Scalars['String']['input']>;
  automated_campaign?: InputMaybe<AutomatedCampaignCreateNestedOneWithoutLead_ActivitiesInput>;
  call_completed_with?: InputMaybe<Callresult>;
  call_result_deleted?: InputMaybe<Scalars['Boolean']['input']>;
  campaign_event?: InputMaybe<ScheduleItemCreateNestedOneWithoutCampaign_ActivitiesInput>;
  channel?: InputMaybe<Channel>;
  communication_type?: InputMaybe<Communication_Type>;
  conference?: InputMaybe<ConferenceCreateNestedOneWithoutLead_ActivitiesInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  curr_lead_val?: InputMaybe<Scalars['Float']['input']>;
  current_implied_action?: InputMaybe<Hingepoint>;
  customer_number?: InputMaybe<Scalars['String']['input']>;
  customer_number_country_code?: InputMaybe<Scalars['String']['input']>;
  dedupe_migration?: InputMaybe<Scalars['Boolean']['input']>;
  email_item?: InputMaybe<EmailItemCreateNestedOneWithoutLead_ActivityInput>;
  from_action?: InputMaybe<Scalars['String']['input']>;
  from_callback_type?: InputMaybe<CallbackType>;
  from_intent_type?: InputMaybe<IntentType>;
  html?: InputMaybe<Scalars['Boolean']['input']>;
  hubspot_email_engagement_id?: InputMaybe<Scalars['String']['input']>;
  hubspot_id?: InputMaybe<Scalars['String']['input']>;
  hubspot_note_engagement_id?: InputMaybe<Scalars['String']['input']>;
  hubspot_owner_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  ignore_sequences?: InputMaybe<Scalars['Boolean']['input']>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventCreateNestedManyWithoutLeadActivityInput>;
  initial_days_set_out?: InputMaybe<Scalars['Float']['input']>;
  is_resting_adjustment?: InputMaybe<Scalars['Boolean']['input']>;
  lead: LeadCreateNestedOneWithoutLead_ActivitiesInput;
  lead_assignment_history_change?: InputMaybe<LeadAssignmentHistoryCreateNestedOneWithoutLead_ActivityInput>;
  lead_current_sequence?: InputMaybe<SequenceCreateNestedOneWithoutLeadActivitiesInput>;
  lead_current_step?: InputMaybe<SequenceStepCreateNestedOneWithoutLeadActivityInput>;
  local_number?: InputMaybe<TwilioNumberCreateNestedOneWithoutLead_ActivitiesInput>;
  next_action?: InputMaybe<Scalars['String']['input']>;
  next_callback_type?: InputMaybe<CallbackType>;
  notes?: InputMaybe<Scalars['String']['input']>;
  organization?: InputMaybe<OrganizationCreateNestedOneWithoutLead_ActivitiesInput>;
  originating_schedule_item?: InputMaybe<ScheduleItemCreateNestedOneWithoutResulting_ActivityInput>;
  previous_lead_val?: InputMaybe<Scalars['Float']['input']>;
  published?: InputMaybe<Scalars['Boolean']['input']>;
  related_disposition?: InputMaybe<DispositionCreateNestedOneWithoutLead_ActivitiesInput>;
  resting_date_on_recycle?: InputMaybe<Scalars['DateTime']['input']>;
  resting_type_on_recycle?: InputMaybe<Restingtype>;
  sale?: InputMaybe<SaleCreateNestedOneWithoutLead_ActivityInput>;
  sale_cycle?: InputMaybe<SaleCycleCreateNestedOneWithoutLead_ActivitiesInput>;
  schedule_item?: InputMaybe<ScheduleItemCreateNestedOneWithoutLead_ActivityInput>;
  site?: InputMaybe<SiteCreateNestedOneWithoutLead_ActivitiesInput>;
  skip_reason?: InputMaybe<Scalars['String']['input']>;
  SMSItem?: InputMaybe<SmsItemCreateNestedOneWithoutLead_ActivityInput>;
  stat_item_aggregation?: InputMaybe<StatItemAggregationCreateNestedOneWithoutLead_ActivityInput>;
  stat_item_aggregation_pipeline?: InputMaybe<StatItemAggregationCreateNestedOneWithoutLead_Activity_PipelineInput>;
  StepActionLog?: InputMaybe<StepActionLogCreateNestedManyWithoutLeadActivityInput>;
  team?: InputMaybe<TeamCreateNestedOneWithoutLead_ActivitiesInput>;
  title?: InputMaybe<Scalars['String']['input']>;
  twilio_action?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user?: InputMaybe<UserCreateNestedOneWithoutLead_ActivitiesInput>;
  user_role?: InputMaybe<Role>;
};

export type LeadActivityCreateWithoutSiteInput = {
  associate_child?: InputMaybe<LeadCreateNestedOneWithoutChild_Lead_ActivitesInput>;
  associate_group_ids?: InputMaybe<LeadActivityCreateassociate_Group_IdsInput>;
  associate_group_leads?: InputMaybe<LeadCreateNestedManyWithoutAssociate_Group_Lead_ActivitiesInput>;
  associated_action?: InputMaybe<Scalars['String']['input']>;
  automated_campaign?: InputMaybe<AutomatedCampaignCreateNestedOneWithoutLead_ActivitiesInput>;
  call_completed_with?: InputMaybe<Callresult>;
  call_result_deleted?: InputMaybe<Scalars['Boolean']['input']>;
  campaign_event?: InputMaybe<ScheduleItemCreateNestedOneWithoutCampaign_ActivitiesInput>;
  channel?: InputMaybe<Channel>;
  communication_type?: InputMaybe<Communication_Type>;
  conference?: InputMaybe<ConferenceCreateNestedOneWithoutLead_ActivitiesInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  curr_lead_val?: InputMaybe<Scalars['Float']['input']>;
  current_implied_action?: InputMaybe<Hingepoint>;
  customer_number?: InputMaybe<Scalars['String']['input']>;
  customer_number_country_code?: InputMaybe<Scalars['String']['input']>;
  dedupe_migration?: InputMaybe<Scalars['Boolean']['input']>;
  email_item?: InputMaybe<EmailItemCreateNestedOneWithoutLead_ActivityInput>;
  from_action?: InputMaybe<Scalars['String']['input']>;
  from_callback_type?: InputMaybe<CallbackType>;
  from_intent_type?: InputMaybe<IntentType>;
  html?: InputMaybe<Scalars['Boolean']['input']>;
  hubspot_email_engagement_id?: InputMaybe<Scalars['String']['input']>;
  hubspot_id?: InputMaybe<Scalars['String']['input']>;
  hubspot_note_engagement_id?: InputMaybe<Scalars['String']['input']>;
  hubspot_owner_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  ignore_sequences?: InputMaybe<Scalars['Boolean']['input']>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventCreateNestedManyWithoutLeadActivityInput>;
  initial_days_set_out?: InputMaybe<Scalars['Float']['input']>;
  is_resting_adjustment?: InputMaybe<Scalars['Boolean']['input']>;
  lead: LeadCreateNestedOneWithoutLead_ActivitiesInput;
  lead_assignment_history_change?: InputMaybe<LeadAssignmentHistoryCreateNestedOneWithoutLead_ActivityInput>;
  lead_current_sequence?: InputMaybe<SequenceCreateNestedOneWithoutLeadActivitiesInput>;
  lead_current_step?: InputMaybe<SequenceStepCreateNestedOneWithoutLeadActivityInput>;
  local_number?: InputMaybe<TwilioNumberCreateNestedOneWithoutLead_ActivitiesInput>;
  next_action?: InputMaybe<Scalars['String']['input']>;
  next_callback_type?: InputMaybe<CallbackType>;
  notes?: InputMaybe<Scalars['String']['input']>;
  organization?: InputMaybe<OrganizationCreateNestedOneWithoutLead_ActivitiesInput>;
  originating_schedule_item?: InputMaybe<ScheduleItemCreateNestedOneWithoutResulting_ActivityInput>;
  previous_lead_val?: InputMaybe<Scalars['Float']['input']>;
  published?: InputMaybe<Scalars['Boolean']['input']>;
  related_disposition?: InputMaybe<DispositionCreateNestedOneWithoutLead_ActivitiesInput>;
  resting_date_on_recycle?: InputMaybe<Scalars['DateTime']['input']>;
  resting_type_on_recycle?: InputMaybe<Restingtype>;
  sale?: InputMaybe<SaleCreateNestedOneWithoutLead_ActivityInput>;
  sale_cycle?: InputMaybe<SaleCycleCreateNestedOneWithoutLead_ActivitiesInput>;
  schedule_item?: InputMaybe<ScheduleItemCreateNestedOneWithoutLead_ActivityInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutLead_ActivityInput>;
  skip_reason?: InputMaybe<Scalars['String']['input']>;
  SMSItem?: InputMaybe<SmsItemCreateNestedOneWithoutLead_ActivityInput>;
  stat_item_aggregation?: InputMaybe<StatItemAggregationCreateNestedOneWithoutLead_ActivityInput>;
  stat_item_aggregation_pipeline?: InputMaybe<StatItemAggregationCreateNestedOneWithoutLead_Activity_PipelineInput>;
  StepActionLog?: InputMaybe<StepActionLogCreateNestedManyWithoutLeadActivityInput>;
  team?: InputMaybe<TeamCreateNestedOneWithoutLead_ActivitiesInput>;
  title?: InputMaybe<Scalars['String']['input']>;
  twilio_action?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user?: InputMaybe<UserCreateNestedOneWithoutLead_ActivitiesInput>;
  user_role?: InputMaybe<Role>;
};

export type LeadActivityCreateWithoutSmsItemInput = {
  associate_child?: InputMaybe<LeadCreateNestedOneWithoutChild_Lead_ActivitesInput>;
  associate_group_ids?: InputMaybe<LeadActivityCreateassociate_Group_IdsInput>;
  associate_group_leads?: InputMaybe<LeadCreateNestedManyWithoutAssociate_Group_Lead_ActivitiesInput>;
  associated_action?: InputMaybe<Scalars['String']['input']>;
  automated_campaign?: InputMaybe<AutomatedCampaignCreateNestedOneWithoutLead_ActivitiesInput>;
  call_completed_with?: InputMaybe<Callresult>;
  call_result_deleted?: InputMaybe<Scalars['Boolean']['input']>;
  campaign_event?: InputMaybe<ScheduleItemCreateNestedOneWithoutCampaign_ActivitiesInput>;
  channel?: InputMaybe<Channel>;
  communication_type?: InputMaybe<Communication_Type>;
  conference?: InputMaybe<ConferenceCreateNestedOneWithoutLead_ActivitiesInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  curr_lead_val?: InputMaybe<Scalars['Float']['input']>;
  current_implied_action?: InputMaybe<Hingepoint>;
  customer_number?: InputMaybe<Scalars['String']['input']>;
  customer_number_country_code?: InputMaybe<Scalars['String']['input']>;
  dedupe_migration?: InputMaybe<Scalars['Boolean']['input']>;
  email_item?: InputMaybe<EmailItemCreateNestedOneWithoutLead_ActivityInput>;
  from_action?: InputMaybe<Scalars['String']['input']>;
  from_callback_type?: InputMaybe<CallbackType>;
  from_intent_type?: InputMaybe<IntentType>;
  html?: InputMaybe<Scalars['Boolean']['input']>;
  hubspot_email_engagement_id?: InputMaybe<Scalars['String']['input']>;
  hubspot_id?: InputMaybe<Scalars['String']['input']>;
  hubspot_note_engagement_id?: InputMaybe<Scalars['String']['input']>;
  hubspot_owner_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  ignore_sequences?: InputMaybe<Scalars['Boolean']['input']>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventCreateNestedManyWithoutLeadActivityInput>;
  initial_days_set_out?: InputMaybe<Scalars['Float']['input']>;
  is_resting_adjustment?: InputMaybe<Scalars['Boolean']['input']>;
  lead: LeadCreateNestedOneWithoutLead_ActivitiesInput;
  lead_assignment_history_change?: InputMaybe<LeadAssignmentHistoryCreateNestedOneWithoutLead_ActivityInput>;
  lead_current_sequence?: InputMaybe<SequenceCreateNestedOneWithoutLeadActivitiesInput>;
  lead_current_step?: InputMaybe<SequenceStepCreateNestedOneWithoutLeadActivityInput>;
  local_number?: InputMaybe<TwilioNumberCreateNestedOneWithoutLead_ActivitiesInput>;
  next_action?: InputMaybe<Scalars['String']['input']>;
  next_callback_type?: InputMaybe<CallbackType>;
  notes?: InputMaybe<Scalars['String']['input']>;
  organization?: InputMaybe<OrganizationCreateNestedOneWithoutLead_ActivitiesInput>;
  originating_schedule_item?: InputMaybe<ScheduleItemCreateNestedOneWithoutResulting_ActivityInput>;
  previous_lead_val?: InputMaybe<Scalars['Float']['input']>;
  published?: InputMaybe<Scalars['Boolean']['input']>;
  related_disposition?: InputMaybe<DispositionCreateNestedOneWithoutLead_ActivitiesInput>;
  resting_date_on_recycle?: InputMaybe<Scalars['DateTime']['input']>;
  resting_type_on_recycle?: InputMaybe<Restingtype>;
  sale?: InputMaybe<SaleCreateNestedOneWithoutLead_ActivityInput>;
  sale_cycle?: InputMaybe<SaleCycleCreateNestedOneWithoutLead_ActivitiesInput>;
  schedule_item?: InputMaybe<ScheduleItemCreateNestedOneWithoutLead_ActivityInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutLead_ActivityInput>;
  site?: InputMaybe<SiteCreateNestedOneWithoutLead_ActivitiesInput>;
  skip_reason?: InputMaybe<Scalars['String']['input']>;
  stat_item_aggregation?: InputMaybe<StatItemAggregationCreateNestedOneWithoutLead_ActivityInput>;
  stat_item_aggregation_pipeline?: InputMaybe<StatItemAggregationCreateNestedOneWithoutLead_Activity_PipelineInput>;
  StepActionLog?: InputMaybe<StepActionLogCreateNestedManyWithoutLeadActivityInput>;
  team?: InputMaybe<TeamCreateNestedOneWithoutLead_ActivitiesInput>;
  title?: InputMaybe<Scalars['String']['input']>;
  twilio_action?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user?: InputMaybe<UserCreateNestedOneWithoutLead_ActivitiesInput>;
  user_role?: InputMaybe<Role>;
};

export type LeadActivityCreateWithoutStat_Item_Aggregation_PipelineInput = {
  associate_child?: InputMaybe<LeadCreateNestedOneWithoutChild_Lead_ActivitesInput>;
  associate_group_ids?: InputMaybe<LeadActivityCreateassociate_Group_IdsInput>;
  associate_group_leads?: InputMaybe<LeadCreateNestedManyWithoutAssociate_Group_Lead_ActivitiesInput>;
  associated_action?: InputMaybe<Scalars['String']['input']>;
  automated_campaign?: InputMaybe<AutomatedCampaignCreateNestedOneWithoutLead_ActivitiesInput>;
  call_completed_with?: InputMaybe<Callresult>;
  call_result_deleted?: InputMaybe<Scalars['Boolean']['input']>;
  campaign_event?: InputMaybe<ScheduleItemCreateNestedOneWithoutCampaign_ActivitiesInput>;
  channel?: InputMaybe<Channel>;
  communication_type?: InputMaybe<Communication_Type>;
  conference?: InputMaybe<ConferenceCreateNestedOneWithoutLead_ActivitiesInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  curr_lead_val?: InputMaybe<Scalars['Float']['input']>;
  current_implied_action?: InputMaybe<Hingepoint>;
  customer_number?: InputMaybe<Scalars['String']['input']>;
  customer_number_country_code?: InputMaybe<Scalars['String']['input']>;
  dedupe_migration?: InputMaybe<Scalars['Boolean']['input']>;
  email_item?: InputMaybe<EmailItemCreateNestedOneWithoutLead_ActivityInput>;
  from_action?: InputMaybe<Scalars['String']['input']>;
  from_callback_type?: InputMaybe<CallbackType>;
  from_intent_type?: InputMaybe<IntentType>;
  html?: InputMaybe<Scalars['Boolean']['input']>;
  hubspot_email_engagement_id?: InputMaybe<Scalars['String']['input']>;
  hubspot_id?: InputMaybe<Scalars['String']['input']>;
  hubspot_note_engagement_id?: InputMaybe<Scalars['String']['input']>;
  hubspot_owner_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  ignore_sequences?: InputMaybe<Scalars['Boolean']['input']>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventCreateNestedManyWithoutLeadActivityInput>;
  initial_days_set_out?: InputMaybe<Scalars['Float']['input']>;
  is_resting_adjustment?: InputMaybe<Scalars['Boolean']['input']>;
  lead: LeadCreateNestedOneWithoutLead_ActivitiesInput;
  lead_assignment_history_change?: InputMaybe<LeadAssignmentHistoryCreateNestedOneWithoutLead_ActivityInput>;
  lead_current_sequence?: InputMaybe<SequenceCreateNestedOneWithoutLeadActivitiesInput>;
  lead_current_step?: InputMaybe<SequenceStepCreateNestedOneWithoutLeadActivityInput>;
  local_number?: InputMaybe<TwilioNumberCreateNestedOneWithoutLead_ActivitiesInput>;
  next_action?: InputMaybe<Scalars['String']['input']>;
  next_callback_type?: InputMaybe<CallbackType>;
  notes?: InputMaybe<Scalars['String']['input']>;
  organization?: InputMaybe<OrganizationCreateNestedOneWithoutLead_ActivitiesInput>;
  originating_schedule_item?: InputMaybe<ScheduleItemCreateNestedOneWithoutResulting_ActivityInput>;
  previous_lead_val?: InputMaybe<Scalars['Float']['input']>;
  published?: InputMaybe<Scalars['Boolean']['input']>;
  related_disposition?: InputMaybe<DispositionCreateNestedOneWithoutLead_ActivitiesInput>;
  resting_date_on_recycle?: InputMaybe<Scalars['DateTime']['input']>;
  resting_type_on_recycle?: InputMaybe<Restingtype>;
  sale?: InputMaybe<SaleCreateNestedOneWithoutLead_ActivityInput>;
  sale_cycle?: InputMaybe<SaleCycleCreateNestedOneWithoutLead_ActivitiesInput>;
  schedule_item?: InputMaybe<ScheduleItemCreateNestedOneWithoutLead_ActivityInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutLead_ActivityInput>;
  site?: InputMaybe<SiteCreateNestedOneWithoutLead_ActivitiesInput>;
  skip_reason?: InputMaybe<Scalars['String']['input']>;
  SMSItem?: InputMaybe<SmsItemCreateNestedOneWithoutLead_ActivityInput>;
  stat_item_aggregation?: InputMaybe<StatItemAggregationCreateNestedOneWithoutLead_ActivityInput>;
  StepActionLog?: InputMaybe<StepActionLogCreateNestedManyWithoutLeadActivityInput>;
  team?: InputMaybe<TeamCreateNestedOneWithoutLead_ActivitiesInput>;
  title?: InputMaybe<Scalars['String']['input']>;
  twilio_action?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user?: InputMaybe<UserCreateNestedOneWithoutLead_ActivitiesInput>;
  user_role?: InputMaybe<Role>;
};

export type LeadActivityCreateWithoutStat_Item_AggregationInput = {
  associate_child?: InputMaybe<LeadCreateNestedOneWithoutChild_Lead_ActivitesInput>;
  associate_group_ids?: InputMaybe<LeadActivityCreateassociate_Group_IdsInput>;
  associate_group_leads?: InputMaybe<LeadCreateNestedManyWithoutAssociate_Group_Lead_ActivitiesInput>;
  associated_action?: InputMaybe<Scalars['String']['input']>;
  automated_campaign?: InputMaybe<AutomatedCampaignCreateNestedOneWithoutLead_ActivitiesInput>;
  call_completed_with?: InputMaybe<Callresult>;
  call_result_deleted?: InputMaybe<Scalars['Boolean']['input']>;
  campaign_event?: InputMaybe<ScheduleItemCreateNestedOneWithoutCampaign_ActivitiesInput>;
  channel?: InputMaybe<Channel>;
  communication_type?: InputMaybe<Communication_Type>;
  conference?: InputMaybe<ConferenceCreateNestedOneWithoutLead_ActivitiesInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  curr_lead_val?: InputMaybe<Scalars['Float']['input']>;
  current_implied_action?: InputMaybe<Hingepoint>;
  customer_number?: InputMaybe<Scalars['String']['input']>;
  customer_number_country_code?: InputMaybe<Scalars['String']['input']>;
  dedupe_migration?: InputMaybe<Scalars['Boolean']['input']>;
  email_item?: InputMaybe<EmailItemCreateNestedOneWithoutLead_ActivityInput>;
  from_action?: InputMaybe<Scalars['String']['input']>;
  from_callback_type?: InputMaybe<CallbackType>;
  from_intent_type?: InputMaybe<IntentType>;
  html?: InputMaybe<Scalars['Boolean']['input']>;
  hubspot_email_engagement_id?: InputMaybe<Scalars['String']['input']>;
  hubspot_id?: InputMaybe<Scalars['String']['input']>;
  hubspot_note_engagement_id?: InputMaybe<Scalars['String']['input']>;
  hubspot_owner_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  ignore_sequences?: InputMaybe<Scalars['Boolean']['input']>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventCreateNestedManyWithoutLeadActivityInput>;
  initial_days_set_out?: InputMaybe<Scalars['Float']['input']>;
  is_resting_adjustment?: InputMaybe<Scalars['Boolean']['input']>;
  lead: LeadCreateNestedOneWithoutLead_ActivitiesInput;
  lead_assignment_history_change?: InputMaybe<LeadAssignmentHistoryCreateNestedOneWithoutLead_ActivityInput>;
  lead_current_sequence?: InputMaybe<SequenceCreateNestedOneWithoutLeadActivitiesInput>;
  lead_current_step?: InputMaybe<SequenceStepCreateNestedOneWithoutLeadActivityInput>;
  local_number?: InputMaybe<TwilioNumberCreateNestedOneWithoutLead_ActivitiesInput>;
  next_action?: InputMaybe<Scalars['String']['input']>;
  next_callback_type?: InputMaybe<CallbackType>;
  notes?: InputMaybe<Scalars['String']['input']>;
  organization?: InputMaybe<OrganizationCreateNestedOneWithoutLead_ActivitiesInput>;
  originating_schedule_item?: InputMaybe<ScheduleItemCreateNestedOneWithoutResulting_ActivityInput>;
  previous_lead_val?: InputMaybe<Scalars['Float']['input']>;
  published?: InputMaybe<Scalars['Boolean']['input']>;
  related_disposition?: InputMaybe<DispositionCreateNestedOneWithoutLead_ActivitiesInput>;
  resting_date_on_recycle?: InputMaybe<Scalars['DateTime']['input']>;
  resting_type_on_recycle?: InputMaybe<Restingtype>;
  sale?: InputMaybe<SaleCreateNestedOneWithoutLead_ActivityInput>;
  sale_cycle?: InputMaybe<SaleCycleCreateNestedOneWithoutLead_ActivitiesInput>;
  schedule_item?: InputMaybe<ScheduleItemCreateNestedOneWithoutLead_ActivityInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutLead_ActivityInput>;
  site?: InputMaybe<SiteCreateNestedOneWithoutLead_ActivitiesInput>;
  skip_reason?: InputMaybe<Scalars['String']['input']>;
  SMSItem?: InputMaybe<SmsItemCreateNestedOneWithoutLead_ActivityInput>;
  stat_item_aggregation_pipeline?: InputMaybe<StatItemAggregationCreateNestedOneWithoutLead_Activity_PipelineInput>;
  StepActionLog?: InputMaybe<StepActionLogCreateNestedManyWithoutLeadActivityInput>;
  team?: InputMaybe<TeamCreateNestedOneWithoutLead_ActivitiesInput>;
  title?: InputMaybe<Scalars['String']['input']>;
  twilio_action?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user?: InputMaybe<UserCreateNestedOneWithoutLead_ActivitiesInput>;
  user_role?: InputMaybe<Role>;
};

export type LeadActivityCreateWithoutStepActionLogInput = {
  associate_child?: InputMaybe<LeadCreateNestedOneWithoutChild_Lead_ActivitesInput>;
  associate_group_ids?: InputMaybe<LeadActivityCreateassociate_Group_IdsInput>;
  associate_group_leads?: InputMaybe<LeadCreateNestedManyWithoutAssociate_Group_Lead_ActivitiesInput>;
  associated_action?: InputMaybe<Scalars['String']['input']>;
  automated_campaign?: InputMaybe<AutomatedCampaignCreateNestedOneWithoutLead_ActivitiesInput>;
  call_completed_with?: InputMaybe<Callresult>;
  call_result_deleted?: InputMaybe<Scalars['Boolean']['input']>;
  campaign_event?: InputMaybe<ScheduleItemCreateNestedOneWithoutCampaign_ActivitiesInput>;
  channel?: InputMaybe<Channel>;
  communication_type?: InputMaybe<Communication_Type>;
  conference?: InputMaybe<ConferenceCreateNestedOneWithoutLead_ActivitiesInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  curr_lead_val?: InputMaybe<Scalars['Float']['input']>;
  current_implied_action?: InputMaybe<Hingepoint>;
  customer_number?: InputMaybe<Scalars['String']['input']>;
  customer_number_country_code?: InputMaybe<Scalars['String']['input']>;
  dedupe_migration?: InputMaybe<Scalars['Boolean']['input']>;
  email_item?: InputMaybe<EmailItemCreateNestedOneWithoutLead_ActivityInput>;
  from_action?: InputMaybe<Scalars['String']['input']>;
  from_callback_type?: InputMaybe<CallbackType>;
  from_intent_type?: InputMaybe<IntentType>;
  html?: InputMaybe<Scalars['Boolean']['input']>;
  hubspot_email_engagement_id?: InputMaybe<Scalars['String']['input']>;
  hubspot_id?: InputMaybe<Scalars['String']['input']>;
  hubspot_note_engagement_id?: InputMaybe<Scalars['String']['input']>;
  hubspot_owner_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  ignore_sequences?: InputMaybe<Scalars['Boolean']['input']>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventCreateNestedManyWithoutLeadActivityInput>;
  initial_days_set_out?: InputMaybe<Scalars['Float']['input']>;
  is_resting_adjustment?: InputMaybe<Scalars['Boolean']['input']>;
  lead: LeadCreateNestedOneWithoutLead_ActivitiesInput;
  lead_assignment_history_change?: InputMaybe<LeadAssignmentHistoryCreateNestedOneWithoutLead_ActivityInput>;
  lead_current_sequence?: InputMaybe<SequenceCreateNestedOneWithoutLeadActivitiesInput>;
  lead_current_step?: InputMaybe<SequenceStepCreateNestedOneWithoutLeadActivityInput>;
  local_number?: InputMaybe<TwilioNumberCreateNestedOneWithoutLead_ActivitiesInput>;
  next_action?: InputMaybe<Scalars['String']['input']>;
  next_callback_type?: InputMaybe<CallbackType>;
  notes?: InputMaybe<Scalars['String']['input']>;
  organization?: InputMaybe<OrganizationCreateNestedOneWithoutLead_ActivitiesInput>;
  originating_schedule_item?: InputMaybe<ScheduleItemCreateNestedOneWithoutResulting_ActivityInput>;
  previous_lead_val?: InputMaybe<Scalars['Float']['input']>;
  published?: InputMaybe<Scalars['Boolean']['input']>;
  related_disposition?: InputMaybe<DispositionCreateNestedOneWithoutLead_ActivitiesInput>;
  resting_date_on_recycle?: InputMaybe<Scalars['DateTime']['input']>;
  resting_type_on_recycle?: InputMaybe<Restingtype>;
  sale?: InputMaybe<SaleCreateNestedOneWithoutLead_ActivityInput>;
  sale_cycle?: InputMaybe<SaleCycleCreateNestedOneWithoutLead_ActivitiesInput>;
  schedule_item?: InputMaybe<ScheduleItemCreateNestedOneWithoutLead_ActivityInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutLead_ActivityInput>;
  site?: InputMaybe<SiteCreateNestedOneWithoutLead_ActivitiesInput>;
  skip_reason?: InputMaybe<Scalars['String']['input']>;
  SMSItem?: InputMaybe<SmsItemCreateNestedOneWithoutLead_ActivityInput>;
  stat_item_aggregation?: InputMaybe<StatItemAggregationCreateNestedOneWithoutLead_ActivityInput>;
  stat_item_aggregation_pipeline?: InputMaybe<StatItemAggregationCreateNestedOneWithoutLead_Activity_PipelineInput>;
  team?: InputMaybe<TeamCreateNestedOneWithoutLead_ActivitiesInput>;
  title?: InputMaybe<Scalars['String']['input']>;
  twilio_action?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user?: InputMaybe<UserCreateNestedOneWithoutLead_ActivitiesInput>;
  user_role?: InputMaybe<Role>;
};

export type LeadActivityCreateWithoutTeamInput = {
  associate_child?: InputMaybe<LeadCreateNestedOneWithoutChild_Lead_ActivitesInput>;
  associate_group_ids?: InputMaybe<LeadActivityCreateassociate_Group_IdsInput>;
  associate_group_leads?: InputMaybe<LeadCreateNestedManyWithoutAssociate_Group_Lead_ActivitiesInput>;
  associated_action?: InputMaybe<Scalars['String']['input']>;
  automated_campaign?: InputMaybe<AutomatedCampaignCreateNestedOneWithoutLead_ActivitiesInput>;
  call_completed_with?: InputMaybe<Callresult>;
  call_result_deleted?: InputMaybe<Scalars['Boolean']['input']>;
  campaign_event?: InputMaybe<ScheduleItemCreateNestedOneWithoutCampaign_ActivitiesInput>;
  channel?: InputMaybe<Channel>;
  communication_type?: InputMaybe<Communication_Type>;
  conference?: InputMaybe<ConferenceCreateNestedOneWithoutLead_ActivitiesInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  curr_lead_val?: InputMaybe<Scalars['Float']['input']>;
  current_implied_action?: InputMaybe<Hingepoint>;
  customer_number?: InputMaybe<Scalars['String']['input']>;
  customer_number_country_code?: InputMaybe<Scalars['String']['input']>;
  dedupe_migration?: InputMaybe<Scalars['Boolean']['input']>;
  email_item?: InputMaybe<EmailItemCreateNestedOneWithoutLead_ActivityInput>;
  from_action?: InputMaybe<Scalars['String']['input']>;
  from_callback_type?: InputMaybe<CallbackType>;
  from_intent_type?: InputMaybe<IntentType>;
  html?: InputMaybe<Scalars['Boolean']['input']>;
  hubspot_email_engagement_id?: InputMaybe<Scalars['String']['input']>;
  hubspot_id?: InputMaybe<Scalars['String']['input']>;
  hubspot_note_engagement_id?: InputMaybe<Scalars['String']['input']>;
  hubspot_owner_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  ignore_sequences?: InputMaybe<Scalars['Boolean']['input']>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventCreateNestedManyWithoutLeadActivityInput>;
  initial_days_set_out?: InputMaybe<Scalars['Float']['input']>;
  is_resting_adjustment?: InputMaybe<Scalars['Boolean']['input']>;
  lead: LeadCreateNestedOneWithoutLead_ActivitiesInput;
  lead_assignment_history_change?: InputMaybe<LeadAssignmentHistoryCreateNestedOneWithoutLead_ActivityInput>;
  lead_current_sequence?: InputMaybe<SequenceCreateNestedOneWithoutLeadActivitiesInput>;
  lead_current_step?: InputMaybe<SequenceStepCreateNestedOneWithoutLeadActivityInput>;
  local_number?: InputMaybe<TwilioNumberCreateNestedOneWithoutLead_ActivitiesInput>;
  next_action?: InputMaybe<Scalars['String']['input']>;
  next_callback_type?: InputMaybe<CallbackType>;
  notes?: InputMaybe<Scalars['String']['input']>;
  organization?: InputMaybe<OrganizationCreateNestedOneWithoutLead_ActivitiesInput>;
  originating_schedule_item?: InputMaybe<ScheduleItemCreateNestedOneWithoutResulting_ActivityInput>;
  previous_lead_val?: InputMaybe<Scalars['Float']['input']>;
  published?: InputMaybe<Scalars['Boolean']['input']>;
  related_disposition?: InputMaybe<DispositionCreateNestedOneWithoutLead_ActivitiesInput>;
  resting_date_on_recycle?: InputMaybe<Scalars['DateTime']['input']>;
  resting_type_on_recycle?: InputMaybe<Restingtype>;
  sale?: InputMaybe<SaleCreateNestedOneWithoutLead_ActivityInput>;
  sale_cycle?: InputMaybe<SaleCycleCreateNestedOneWithoutLead_ActivitiesInput>;
  schedule_item?: InputMaybe<ScheduleItemCreateNestedOneWithoutLead_ActivityInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutLead_ActivityInput>;
  site?: InputMaybe<SiteCreateNestedOneWithoutLead_ActivitiesInput>;
  skip_reason?: InputMaybe<Scalars['String']['input']>;
  SMSItem?: InputMaybe<SmsItemCreateNestedOneWithoutLead_ActivityInput>;
  stat_item_aggregation?: InputMaybe<StatItemAggregationCreateNestedOneWithoutLead_ActivityInput>;
  stat_item_aggregation_pipeline?: InputMaybe<StatItemAggregationCreateNestedOneWithoutLead_Activity_PipelineInput>;
  StepActionLog?: InputMaybe<StepActionLogCreateNestedManyWithoutLeadActivityInput>;
  title?: InputMaybe<Scalars['String']['input']>;
  twilio_action?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user?: InputMaybe<UserCreateNestedOneWithoutLead_ActivitiesInput>;
  user_role?: InputMaybe<Role>;
};

export type LeadActivityCreateWithoutUserInput = {
  associate_child?: InputMaybe<LeadCreateNestedOneWithoutChild_Lead_ActivitesInput>;
  associate_group_ids?: InputMaybe<LeadActivityCreateassociate_Group_IdsInput>;
  associate_group_leads?: InputMaybe<LeadCreateNestedManyWithoutAssociate_Group_Lead_ActivitiesInput>;
  associated_action?: InputMaybe<Scalars['String']['input']>;
  automated_campaign?: InputMaybe<AutomatedCampaignCreateNestedOneWithoutLead_ActivitiesInput>;
  call_completed_with?: InputMaybe<Callresult>;
  call_result_deleted?: InputMaybe<Scalars['Boolean']['input']>;
  campaign_event?: InputMaybe<ScheduleItemCreateNestedOneWithoutCampaign_ActivitiesInput>;
  channel?: InputMaybe<Channel>;
  communication_type?: InputMaybe<Communication_Type>;
  conference?: InputMaybe<ConferenceCreateNestedOneWithoutLead_ActivitiesInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  curr_lead_val?: InputMaybe<Scalars['Float']['input']>;
  current_implied_action?: InputMaybe<Hingepoint>;
  customer_number?: InputMaybe<Scalars['String']['input']>;
  customer_number_country_code?: InputMaybe<Scalars['String']['input']>;
  dedupe_migration?: InputMaybe<Scalars['Boolean']['input']>;
  email_item?: InputMaybe<EmailItemCreateNestedOneWithoutLead_ActivityInput>;
  from_action?: InputMaybe<Scalars['String']['input']>;
  from_callback_type?: InputMaybe<CallbackType>;
  from_intent_type?: InputMaybe<IntentType>;
  html?: InputMaybe<Scalars['Boolean']['input']>;
  hubspot_email_engagement_id?: InputMaybe<Scalars['String']['input']>;
  hubspot_id?: InputMaybe<Scalars['String']['input']>;
  hubspot_note_engagement_id?: InputMaybe<Scalars['String']['input']>;
  hubspot_owner_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  ignore_sequences?: InputMaybe<Scalars['Boolean']['input']>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventCreateNestedManyWithoutLeadActivityInput>;
  initial_days_set_out?: InputMaybe<Scalars['Float']['input']>;
  is_resting_adjustment?: InputMaybe<Scalars['Boolean']['input']>;
  lead: LeadCreateNestedOneWithoutLead_ActivitiesInput;
  lead_assignment_history_change?: InputMaybe<LeadAssignmentHistoryCreateNestedOneWithoutLead_ActivityInput>;
  lead_current_sequence?: InputMaybe<SequenceCreateNestedOneWithoutLeadActivitiesInput>;
  lead_current_step?: InputMaybe<SequenceStepCreateNestedOneWithoutLeadActivityInput>;
  local_number?: InputMaybe<TwilioNumberCreateNestedOneWithoutLead_ActivitiesInput>;
  next_action?: InputMaybe<Scalars['String']['input']>;
  next_callback_type?: InputMaybe<CallbackType>;
  notes?: InputMaybe<Scalars['String']['input']>;
  organization?: InputMaybe<OrganizationCreateNestedOneWithoutLead_ActivitiesInput>;
  originating_schedule_item?: InputMaybe<ScheduleItemCreateNestedOneWithoutResulting_ActivityInput>;
  previous_lead_val?: InputMaybe<Scalars['Float']['input']>;
  published?: InputMaybe<Scalars['Boolean']['input']>;
  related_disposition?: InputMaybe<DispositionCreateNestedOneWithoutLead_ActivitiesInput>;
  resting_date_on_recycle?: InputMaybe<Scalars['DateTime']['input']>;
  resting_type_on_recycle?: InputMaybe<Restingtype>;
  sale?: InputMaybe<SaleCreateNestedOneWithoutLead_ActivityInput>;
  sale_cycle?: InputMaybe<SaleCycleCreateNestedOneWithoutLead_ActivitiesInput>;
  schedule_item?: InputMaybe<ScheduleItemCreateNestedOneWithoutLead_ActivityInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutLead_ActivityInput>;
  site?: InputMaybe<SiteCreateNestedOneWithoutLead_ActivitiesInput>;
  skip_reason?: InputMaybe<Scalars['String']['input']>;
  SMSItem?: InputMaybe<SmsItemCreateNestedOneWithoutLead_ActivityInput>;
  stat_item_aggregation?: InputMaybe<StatItemAggregationCreateNestedOneWithoutLead_ActivityInput>;
  stat_item_aggregation_pipeline?: InputMaybe<StatItemAggregationCreateNestedOneWithoutLead_Activity_PipelineInput>;
  StepActionLog?: InputMaybe<StepActionLogCreateNestedManyWithoutLeadActivityInput>;
  team?: InputMaybe<TeamCreateNestedOneWithoutLead_ActivitiesInput>;
  title?: InputMaybe<Scalars['String']['input']>;
  twilio_action?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user_role?: InputMaybe<Role>;
};

export type LeadActivityListRelationFilter = {
  every?: InputMaybe<LeadActivityWhereInput>;
  none?: InputMaybe<LeadActivityWhereInput>;
  some?: InputMaybe<LeadActivityWhereInput>;
};

export type LeadActivityOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
  count?: InputMaybe<SortOrder>;
};

export type LeadActivityOrderByWithRelationInput = {
  associate_child?: InputMaybe<LeadOrderByWithRelationInput>;
  associate_child_id?: InputMaybe<SortOrder>;
  associate_group_ids?: InputMaybe<SortOrder>;
  associate_group_leads?: InputMaybe<LeadOrderByRelationAggregateInput>;
  associated_action?: InputMaybe<SortOrder>;
  automated_campaign?: InputMaybe<AutomatedCampaignOrderByWithRelationInput>;
  automated_campaign_id?: InputMaybe<SortOrder>;
  call_completed_with?: InputMaybe<SortOrder>;
  call_result_deleted?: InputMaybe<SortOrder>;
  campaign_event?: InputMaybe<ScheduleItemOrderByWithRelationInput>;
  campaign_event_id?: InputMaybe<SortOrder>;
  channel?: InputMaybe<SortOrder>;
  communication_type?: InputMaybe<SortOrder>;
  conference?: InputMaybe<ConferenceOrderByWithRelationInput>;
  conference_id?: InputMaybe<SortOrder>;
  created_at?: InputMaybe<SortOrder>;
  curr_lead_val?: InputMaybe<SortOrder>;
  current_implied_action?: InputMaybe<SortOrder>;
  customer_number?: InputMaybe<SortOrder>;
  customer_number_country_code?: InputMaybe<SortOrder>;
  cycle_num?: InputMaybe<SortOrder>;
  dedupe_migration?: InputMaybe<SortOrder>;
  disposition_id?: InputMaybe<SortOrder>;
  email_item?: InputMaybe<EmailItemOrderByWithRelationInput>;
  from_action?: InputMaybe<SortOrder>;
  from_callback_type?: InputMaybe<SortOrder>;
  from_intent_type?: InputMaybe<SortOrder>;
  html?: InputMaybe<SortOrder>;
  hubspot_email_engagement_id?: InputMaybe<SortOrder>;
  hubspot_id?: InputMaybe<SortOrder>;
  hubspot_note_engagement_id?: InputMaybe<SortOrder>;
  hubspot_owner_id?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  ignore_sequences?: InputMaybe<SortOrder>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventOrderByRelationAggregateInput>;
  initial_days_set_out?: InputMaybe<SortOrder>;
  is_resting_adjustment?: InputMaybe<SortOrder>;
  lead?: InputMaybe<LeadOrderByWithRelationInput>;
  lead_assignment_history_change?: InputMaybe<LeadAssignmentHistoryOrderByWithRelationInput>;
  lead_assignment_history_id?: InputMaybe<SortOrder>;
  lead_current_sequence?: InputMaybe<SequenceOrderByWithRelationInput>;
  lead_current_sequence_id?: InputMaybe<SortOrder>;
  lead_current_step?: InputMaybe<SequenceStepOrderByWithRelationInput>;
  lead_current_step_id?: InputMaybe<SortOrder>;
  lead_id?: InputMaybe<SortOrder>;
  local_number?: InputMaybe<TwilioNumberOrderByWithRelationInput>;
  local_number_id?: InputMaybe<SortOrder>;
  next_action?: InputMaybe<SortOrder>;
  next_callback_type?: InputMaybe<SortOrder>;
  notes?: InputMaybe<SortOrder>;
  organization?: InputMaybe<OrganizationOrderByWithRelationInput>;
  organization_id?: InputMaybe<SortOrder>;
  originating_schedule_item?: InputMaybe<ScheduleItemOrderByWithRelationInput>;
  previous_lead_val?: InputMaybe<SortOrder>;
  published?: InputMaybe<SortOrder>;
  related_disposition?: InputMaybe<DispositionOrderByWithRelationInput>;
  resting_date_on_recycle?: InputMaybe<SortOrder>;
  resting_type_on_recycle?: InputMaybe<SortOrder>;
  sale?: InputMaybe<SaleOrderByWithRelationInput>;
  sale_cycle?: InputMaybe<SaleCycleOrderByWithRelationInput>;
  schedule_item?: InputMaybe<ScheduleItemOrderByWithRelationInput>;
  schedule_item_id?: InputMaybe<SortOrder>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadOrderByRelationAggregateInput>;
  site?: InputMaybe<SiteOrderByWithRelationInput>;
  site_id?: InputMaybe<SortOrder>;
  skip_reason?: InputMaybe<SortOrder>;
  SMSItem?: InputMaybe<SmsItemOrderByWithRelationInput>;
  stat_item_aggregation?: InputMaybe<StatItemAggregationOrderByWithRelationInput>;
  stat_item_aggregation_pipeline?: InputMaybe<StatItemAggregationOrderByWithRelationInput>;
  StepActionLog?: InputMaybe<StepActionLogOrderByRelationAggregateInput>;
  team?: InputMaybe<TeamOrderByWithRelationInput>;
  team_id?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  twilio_action?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserOrderByWithRelationInput>;
  user_id?: InputMaybe<SortOrder>;
  user_role?: InputMaybe<SortOrder>;
};

export type LeadActivityScalarWhereInput = {
  AND?: InputMaybe<Array<LeadActivityScalarWhereInput>>;
  associate_child_id?: InputMaybe<StringNullableFilter>;
  associate_group_ids?: InputMaybe<StringNullableListFilter>;
  associated_action?: InputMaybe<StringNullableFilter>;
  automated_campaign_id?: InputMaybe<StringNullableFilter>;
  call_completed_with?: InputMaybe<EnumCallresultNullableFilter>;
  call_result_deleted?: InputMaybe<BoolFilter>;
  campaign_event_id?: InputMaybe<StringNullableFilter>;
  channel?: InputMaybe<EnumChannelNullableFilter>;
  communication_type?: InputMaybe<EnumCommunication_TypeNullableFilter>;
  conference_id?: InputMaybe<StringNullableFilter>;
  created_at?: InputMaybe<DateTimeFilter>;
  curr_lead_val?: InputMaybe<FloatNullableFilter>;
  current_implied_action?: InputMaybe<EnumHingepointNullableFilter>;
  customer_number?: InputMaybe<StringNullableFilter>;
  customer_number_country_code?: InputMaybe<StringNullableFilter>;
  cycle_num?: InputMaybe<IntNullableFilter>;
  dedupe_migration?: InputMaybe<BoolFilter>;
  disposition_id?: InputMaybe<StringNullableFilter>;
  from_action?: InputMaybe<StringNullableFilter>;
  from_callback_type?: InputMaybe<EnumCallbackTypeNullableFilter>;
  from_intent_type?: InputMaybe<EnumIntentTypeNullableFilter>;
  html?: InputMaybe<BoolFilter>;
  hubspot_email_engagement_id?: InputMaybe<StringNullableFilter>;
  hubspot_id?: InputMaybe<StringNullableFilter>;
  hubspot_note_engagement_id?: InputMaybe<StringNullableFilter>;
  hubspot_owner_id?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  ignore_sequences?: InputMaybe<BoolFilter>;
  initial_days_set_out?: InputMaybe<FloatNullableFilter>;
  is_resting_adjustment?: InputMaybe<BoolFilter>;
  lead_assignment_history_id?: InputMaybe<StringNullableFilter>;
  lead_current_sequence_id?: InputMaybe<StringNullableFilter>;
  lead_current_step_id?: InputMaybe<StringNullableFilter>;
  lead_id?: InputMaybe<StringFilter>;
  local_number_id?: InputMaybe<StringNullableFilter>;
  next_action?: InputMaybe<StringNullableFilter>;
  next_callback_type?: InputMaybe<EnumCallbackTypeNullableFilter>;
  NOT?: InputMaybe<Array<LeadActivityScalarWhereInput>>;
  notes?: InputMaybe<StringNullableFilter>;
  OR?: InputMaybe<Array<LeadActivityScalarWhereInput>>;
  organization_id?: InputMaybe<StringNullableFilter>;
  previous_lead_val?: InputMaybe<FloatNullableFilter>;
  published?: InputMaybe<BoolNullableFilter>;
  resting_date_on_recycle?: InputMaybe<DateTimeNullableFilter>;
  resting_type_on_recycle?: InputMaybe<EnumRestingtypeNullableFilter>;
  schedule_item_id?: InputMaybe<StringNullableFilter>;
  site_id?: InputMaybe<StringNullableFilter>;
  skip_reason?: InputMaybe<StringNullableFilter>;
  team_id?: InputMaybe<StringNullableFilter>;
  title?: InputMaybe<StringNullableFilter>;
  twilio_action?: InputMaybe<StringNullableFilter>;
  type?: InputMaybe<StringNullableFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
  user_id?: InputMaybe<StringNullableFilter>;
  user_role?: InputMaybe<EnumRoleNullableFilter>;
};

/** theme object used to define styles for the lead history card */
export type LeadActivityThemeType = {
  __typename?: 'leadActivityThemeType';
  bg_color?: Maybe<Scalars['String']['output']>;
  font_color?: Maybe<Scalars['String']['output']>;
  label?: Maybe<Scalars['String']['output']>;
};

export type LeadActivityUpdateassociate_Group_IdsInput = {
  push?: InputMaybe<Scalars['String']['input']>;
  set?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type LeadActivityUpdateManyMutationInput = {
  associate_group_ids?: InputMaybe<LeadActivityUpdateassociate_Group_IdsInput>;
  associated_action?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  call_completed_with?: InputMaybe<NullableEnumCallresultFieldUpdateOperationsInput>;
  call_result_deleted?: InputMaybe<BoolFieldUpdateOperationsInput>;
  channel?: InputMaybe<NullableEnumChannelFieldUpdateOperationsInput>;
  communication_type?: InputMaybe<NullableEnumCommunication_TypeFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  curr_lead_val?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  current_implied_action?: InputMaybe<NullableEnumHingepointFieldUpdateOperationsInput>;
  customer_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  customer_number_country_code?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  dedupe_migration?: InputMaybe<BoolFieldUpdateOperationsInput>;
  from_action?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  from_callback_type?: InputMaybe<NullableEnumCallbackTypeFieldUpdateOperationsInput>;
  from_intent_type?: InputMaybe<NullableEnumIntentTypeFieldUpdateOperationsInput>;
  html?: InputMaybe<BoolFieldUpdateOperationsInput>;
  hubspot_email_engagement_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  hubspot_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  hubspot_note_engagement_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  hubspot_owner_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  ignore_sequences?: InputMaybe<BoolFieldUpdateOperationsInput>;
  initial_days_set_out?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  is_resting_adjustment?: InputMaybe<BoolFieldUpdateOperationsInput>;
  next_action?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  next_callback_type?: InputMaybe<NullableEnumCallbackTypeFieldUpdateOperationsInput>;
  notes?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  previous_lead_val?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  published?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  resting_date_on_recycle?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  resting_type_on_recycle?: InputMaybe<NullableEnumRestingtypeFieldUpdateOperationsInput>;
  skip_reason?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  twilio_action?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  type?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user_role?: InputMaybe<NullableEnumRoleFieldUpdateOperationsInput>;
};

export type LeadActivityUpdateManyWithoutAssociate_ChildInput = {
  connect?: InputMaybe<Array<LeadActivityWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<LeadActivityCreateOrConnectWithoutAssociate_ChildInput>>;
  create?: InputMaybe<Array<LeadActivityCreateWithoutAssociate_ChildInput>>;
  createMany?: InputMaybe<LeadActivityCreateManyAssociate_ChildInputEnvelope>;
  delete?: InputMaybe<Array<LeadActivityWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<LeadActivityScalarWhereInput>>;
  disconnect?: InputMaybe<Array<LeadActivityWhereUniqueInput>>;
  set?: InputMaybe<Array<LeadActivityWhereUniqueInput>>;
  update?: InputMaybe<Array<LeadActivityUpdateWithWhereUniqueWithoutAssociate_ChildInput>>;
  updateMany?: InputMaybe<Array<LeadActivityUpdateManyWithWhereWithoutAssociate_ChildInput>>;
  upsert?: InputMaybe<Array<LeadActivityUpsertWithWhereUniqueWithoutAssociate_ChildInput>>;
};

export type LeadActivityUpdateManyWithoutAssociate_Group_LeadsInput = {
  connect?: InputMaybe<Array<LeadActivityWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<LeadActivityCreateOrConnectWithoutAssociate_Group_LeadsInput>>;
  create?: InputMaybe<Array<LeadActivityCreateWithoutAssociate_Group_LeadsInput>>;
  delete?: InputMaybe<Array<LeadActivityWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<LeadActivityScalarWhereInput>>;
  disconnect?: InputMaybe<Array<LeadActivityWhereUniqueInput>>;
  set?: InputMaybe<Array<LeadActivityWhereUniqueInput>>;
  update?: InputMaybe<Array<LeadActivityUpdateWithWhereUniqueWithoutAssociate_Group_LeadsInput>>;
  updateMany?: InputMaybe<Array<LeadActivityUpdateManyWithWhereWithoutAssociate_Group_LeadsInput>>;
  upsert?: InputMaybe<Array<LeadActivityUpsertWithWhereUniqueWithoutAssociate_Group_LeadsInput>>;
};

export type LeadActivityUpdateManyWithoutAutomated_CampaignInput = {
  connect?: InputMaybe<Array<LeadActivityWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<LeadActivityCreateOrConnectWithoutAutomated_CampaignInput>>;
  create?: InputMaybe<Array<LeadActivityCreateWithoutAutomated_CampaignInput>>;
  createMany?: InputMaybe<LeadActivityCreateManyAutomated_CampaignInputEnvelope>;
  delete?: InputMaybe<Array<LeadActivityWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<LeadActivityScalarWhereInput>>;
  disconnect?: InputMaybe<Array<LeadActivityWhereUniqueInput>>;
  set?: InputMaybe<Array<LeadActivityWhereUniqueInput>>;
  update?: InputMaybe<Array<LeadActivityUpdateWithWhereUniqueWithoutAutomated_CampaignInput>>;
  updateMany?: InputMaybe<Array<LeadActivityUpdateManyWithWhereWithoutAutomated_CampaignInput>>;
  upsert?: InputMaybe<Array<LeadActivityUpsertWithWhereUniqueWithoutAutomated_CampaignInput>>;
};

export type LeadActivityUpdateManyWithoutCampaign_EventInput = {
  connect?: InputMaybe<Array<LeadActivityWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<LeadActivityCreateOrConnectWithoutCampaign_EventInput>>;
  create?: InputMaybe<Array<LeadActivityCreateWithoutCampaign_EventInput>>;
  createMany?: InputMaybe<LeadActivityCreateManyCampaign_EventInputEnvelope>;
  delete?: InputMaybe<Array<LeadActivityWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<LeadActivityScalarWhereInput>>;
  disconnect?: InputMaybe<Array<LeadActivityWhereUniqueInput>>;
  set?: InputMaybe<Array<LeadActivityWhereUniqueInput>>;
  update?: InputMaybe<Array<LeadActivityUpdateWithWhereUniqueWithoutCampaign_EventInput>>;
  updateMany?: InputMaybe<Array<LeadActivityUpdateManyWithWhereWithoutCampaign_EventInput>>;
  upsert?: InputMaybe<Array<LeadActivityUpsertWithWhereUniqueWithoutCampaign_EventInput>>;
};

export type LeadActivityUpdateManyWithoutConferenceInput = {
  connect?: InputMaybe<Array<LeadActivityWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<LeadActivityCreateOrConnectWithoutConferenceInput>>;
  create?: InputMaybe<Array<LeadActivityCreateWithoutConferenceInput>>;
  createMany?: InputMaybe<LeadActivityCreateManyConferenceInputEnvelope>;
  delete?: InputMaybe<Array<LeadActivityWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<LeadActivityScalarWhereInput>>;
  disconnect?: InputMaybe<Array<LeadActivityWhereUniqueInput>>;
  set?: InputMaybe<Array<LeadActivityWhereUniqueInput>>;
  update?: InputMaybe<Array<LeadActivityUpdateWithWhereUniqueWithoutConferenceInput>>;
  updateMany?: InputMaybe<Array<LeadActivityUpdateManyWithWhereWithoutConferenceInput>>;
  upsert?: InputMaybe<Array<LeadActivityUpsertWithWhereUniqueWithoutConferenceInput>>;
};

export type LeadActivityUpdateManyWithoutLead_Current_SequenceInput = {
  connect?: InputMaybe<Array<LeadActivityWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<LeadActivityCreateOrConnectWithoutLead_Current_SequenceInput>>;
  create?: InputMaybe<Array<LeadActivityCreateWithoutLead_Current_SequenceInput>>;
  createMany?: InputMaybe<LeadActivityCreateManyLead_Current_SequenceInputEnvelope>;
  delete?: InputMaybe<Array<LeadActivityWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<LeadActivityScalarWhereInput>>;
  disconnect?: InputMaybe<Array<LeadActivityWhereUniqueInput>>;
  set?: InputMaybe<Array<LeadActivityWhereUniqueInput>>;
  update?: InputMaybe<Array<LeadActivityUpdateWithWhereUniqueWithoutLead_Current_SequenceInput>>;
  updateMany?: InputMaybe<Array<LeadActivityUpdateManyWithWhereWithoutLead_Current_SequenceInput>>;
  upsert?: InputMaybe<Array<LeadActivityUpsertWithWhereUniqueWithoutLead_Current_SequenceInput>>;
};

export type LeadActivityUpdateManyWithoutLead_Current_StepInput = {
  connect?: InputMaybe<Array<LeadActivityWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<LeadActivityCreateOrConnectWithoutLead_Current_StepInput>>;
  create?: InputMaybe<Array<LeadActivityCreateWithoutLead_Current_StepInput>>;
  createMany?: InputMaybe<LeadActivityCreateManyLead_Current_StepInputEnvelope>;
  delete?: InputMaybe<Array<LeadActivityWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<LeadActivityScalarWhereInput>>;
  disconnect?: InputMaybe<Array<LeadActivityWhereUniqueInput>>;
  set?: InputMaybe<Array<LeadActivityWhereUniqueInput>>;
  update?: InputMaybe<Array<LeadActivityUpdateWithWhereUniqueWithoutLead_Current_StepInput>>;
  updateMany?: InputMaybe<Array<LeadActivityUpdateManyWithWhereWithoutLead_Current_StepInput>>;
  upsert?: InputMaybe<Array<LeadActivityUpsertWithWhereUniqueWithoutLead_Current_StepInput>>;
};

export type LeadActivityUpdateManyWithoutLeadInput = {
  connect?: InputMaybe<Array<LeadActivityWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<LeadActivityCreateOrConnectWithoutLeadInput>>;
  create?: InputMaybe<Array<LeadActivityCreateWithoutLeadInput>>;
  createMany?: InputMaybe<LeadActivityCreateManyLeadInputEnvelope>;
  delete?: InputMaybe<Array<LeadActivityWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<LeadActivityScalarWhereInput>>;
  disconnect?: InputMaybe<Array<LeadActivityWhereUniqueInput>>;
  set?: InputMaybe<Array<LeadActivityWhereUniqueInput>>;
  update?: InputMaybe<Array<LeadActivityUpdateWithWhereUniqueWithoutLeadInput>>;
  updateMany?: InputMaybe<Array<LeadActivityUpdateManyWithWhereWithoutLeadInput>>;
  upsert?: InputMaybe<Array<LeadActivityUpsertWithWhereUniqueWithoutLeadInput>>;
};

export type LeadActivityUpdateManyWithoutLocal_NumberInput = {
  connect?: InputMaybe<Array<LeadActivityWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<LeadActivityCreateOrConnectWithoutLocal_NumberInput>>;
  create?: InputMaybe<Array<LeadActivityCreateWithoutLocal_NumberInput>>;
  createMany?: InputMaybe<LeadActivityCreateManyLocal_NumberInputEnvelope>;
  delete?: InputMaybe<Array<LeadActivityWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<LeadActivityScalarWhereInput>>;
  disconnect?: InputMaybe<Array<LeadActivityWhereUniqueInput>>;
  set?: InputMaybe<Array<LeadActivityWhereUniqueInput>>;
  update?: InputMaybe<Array<LeadActivityUpdateWithWhereUniqueWithoutLocal_NumberInput>>;
  updateMany?: InputMaybe<Array<LeadActivityUpdateManyWithWhereWithoutLocal_NumberInput>>;
  upsert?: InputMaybe<Array<LeadActivityUpsertWithWhereUniqueWithoutLocal_NumberInput>>;
};

export type LeadActivityUpdateManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<LeadActivityWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<LeadActivityCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<LeadActivityCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<LeadActivityCreateManyOrganizationInputEnvelope>;
  delete?: InputMaybe<Array<LeadActivityWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<LeadActivityScalarWhereInput>>;
  disconnect?: InputMaybe<Array<LeadActivityWhereUniqueInput>>;
  set?: InputMaybe<Array<LeadActivityWhereUniqueInput>>;
  update?: InputMaybe<Array<LeadActivityUpdateWithWhereUniqueWithoutOrganizationInput>>;
  updateMany?: InputMaybe<Array<LeadActivityUpdateManyWithWhereWithoutOrganizationInput>>;
  upsert?: InputMaybe<Array<LeadActivityUpsertWithWhereUniqueWithoutOrganizationInput>>;
};

export type LeadActivityUpdateManyWithoutRelated_DispositionInput = {
  connect?: InputMaybe<Array<LeadActivityWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<LeadActivityCreateOrConnectWithoutRelated_DispositionInput>>;
  create?: InputMaybe<Array<LeadActivityCreateWithoutRelated_DispositionInput>>;
  createMany?: InputMaybe<LeadActivityCreateManyRelated_DispositionInputEnvelope>;
  delete?: InputMaybe<Array<LeadActivityWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<LeadActivityScalarWhereInput>>;
  disconnect?: InputMaybe<Array<LeadActivityWhereUniqueInput>>;
  set?: InputMaybe<Array<LeadActivityWhereUniqueInput>>;
  update?: InputMaybe<Array<LeadActivityUpdateWithWhereUniqueWithoutRelated_DispositionInput>>;
  updateMany?: InputMaybe<Array<LeadActivityUpdateManyWithWhereWithoutRelated_DispositionInput>>;
  upsert?: InputMaybe<Array<LeadActivityUpsertWithWhereUniqueWithoutRelated_DispositionInput>>;
};

export type LeadActivityUpdateManyWithoutSale_CycleInput = {
  connect?: InputMaybe<Array<LeadActivityWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<LeadActivityCreateOrConnectWithoutSale_CycleInput>>;
  create?: InputMaybe<Array<LeadActivityCreateWithoutSale_CycleInput>>;
  createMany?: InputMaybe<LeadActivityCreateManySale_CycleInputEnvelope>;
  delete?: InputMaybe<Array<LeadActivityWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<LeadActivityScalarWhereInput>>;
  disconnect?: InputMaybe<Array<LeadActivityWhereUniqueInput>>;
  set?: InputMaybe<Array<LeadActivityWhereUniqueInput>>;
  update?: InputMaybe<Array<LeadActivityUpdateWithWhereUniqueWithoutSale_CycleInput>>;
  updateMany?: InputMaybe<Array<LeadActivityUpdateManyWithWhereWithoutSale_CycleInput>>;
  upsert?: InputMaybe<Array<LeadActivityUpsertWithWhereUniqueWithoutSale_CycleInput>>;
};

export type LeadActivityUpdateManyWithoutSiteInput = {
  connect?: InputMaybe<Array<LeadActivityWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<LeadActivityCreateOrConnectWithoutSiteInput>>;
  create?: InputMaybe<Array<LeadActivityCreateWithoutSiteInput>>;
  createMany?: InputMaybe<LeadActivityCreateManySiteInputEnvelope>;
  delete?: InputMaybe<Array<LeadActivityWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<LeadActivityScalarWhereInput>>;
  disconnect?: InputMaybe<Array<LeadActivityWhereUniqueInput>>;
  set?: InputMaybe<Array<LeadActivityWhereUniqueInput>>;
  update?: InputMaybe<Array<LeadActivityUpdateWithWhereUniqueWithoutSiteInput>>;
  updateMany?: InputMaybe<Array<LeadActivityUpdateManyWithWhereWithoutSiteInput>>;
  upsert?: InputMaybe<Array<LeadActivityUpsertWithWhereUniqueWithoutSiteInput>>;
};

export type LeadActivityUpdateManyWithoutTeamInput = {
  connect?: InputMaybe<Array<LeadActivityWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<LeadActivityCreateOrConnectWithoutTeamInput>>;
  create?: InputMaybe<Array<LeadActivityCreateWithoutTeamInput>>;
  createMany?: InputMaybe<LeadActivityCreateManyTeamInputEnvelope>;
  delete?: InputMaybe<Array<LeadActivityWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<LeadActivityScalarWhereInput>>;
  disconnect?: InputMaybe<Array<LeadActivityWhereUniqueInput>>;
  set?: InputMaybe<Array<LeadActivityWhereUniqueInput>>;
  update?: InputMaybe<Array<LeadActivityUpdateWithWhereUniqueWithoutTeamInput>>;
  updateMany?: InputMaybe<Array<LeadActivityUpdateManyWithWhereWithoutTeamInput>>;
  upsert?: InputMaybe<Array<LeadActivityUpsertWithWhereUniqueWithoutTeamInput>>;
};

export type LeadActivityUpdateManyWithoutUserInput = {
  connect?: InputMaybe<Array<LeadActivityWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<LeadActivityCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<LeadActivityCreateWithoutUserInput>>;
  createMany?: InputMaybe<LeadActivityCreateManyUserInputEnvelope>;
  delete?: InputMaybe<Array<LeadActivityWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<LeadActivityScalarWhereInput>>;
  disconnect?: InputMaybe<Array<LeadActivityWhereUniqueInput>>;
  set?: InputMaybe<Array<LeadActivityWhereUniqueInput>>;
  update?: InputMaybe<Array<LeadActivityUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: InputMaybe<Array<LeadActivityUpdateManyWithWhereWithoutUserInput>>;
  upsert?: InputMaybe<Array<LeadActivityUpsertWithWhereUniqueWithoutUserInput>>;
};

export type LeadActivityUpdateManyWithWhereWithoutAssociate_ChildInput = {
  data: LeadActivityUpdateManyMutationInput;
  where: LeadActivityScalarWhereInput;
};

export type LeadActivityUpdateManyWithWhereWithoutAssociate_Group_LeadsInput = {
  data: LeadActivityUpdateManyMutationInput;
  where: LeadActivityScalarWhereInput;
};

export type LeadActivityUpdateManyWithWhereWithoutAutomated_CampaignInput = {
  data: LeadActivityUpdateManyMutationInput;
  where: LeadActivityScalarWhereInput;
};

export type LeadActivityUpdateManyWithWhereWithoutCampaign_EventInput = {
  data: LeadActivityUpdateManyMutationInput;
  where: LeadActivityScalarWhereInput;
};

export type LeadActivityUpdateManyWithWhereWithoutConferenceInput = {
  data: LeadActivityUpdateManyMutationInput;
  where: LeadActivityScalarWhereInput;
};

export type LeadActivityUpdateManyWithWhereWithoutLead_Current_SequenceInput = {
  data: LeadActivityUpdateManyMutationInput;
  where: LeadActivityScalarWhereInput;
};

export type LeadActivityUpdateManyWithWhereWithoutLead_Current_StepInput = {
  data: LeadActivityUpdateManyMutationInput;
  where: LeadActivityScalarWhereInput;
};

export type LeadActivityUpdateManyWithWhereWithoutLeadInput = {
  data: LeadActivityUpdateManyMutationInput;
  where: LeadActivityScalarWhereInput;
};

export type LeadActivityUpdateManyWithWhereWithoutLocal_NumberInput = {
  data: LeadActivityUpdateManyMutationInput;
  where: LeadActivityScalarWhereInput;
};

export type LeadActivityUpdateManyWithWhereWithoutOrganizationInput = {
  data: LeadActivityUpdateManyMutationInput;
  where: LeadActivityScalarWhereInput;
};

export type LeadActivityUpdateManyWithWhereWithoutRelated_DispositionInput = {
  data: LeadActivityUpdateManyMutationInput;
  where: LeadActivityScalarWhereInput;
};

export type LeadActivityUpdateManyWithWhereWithoutSale_CycleInput = {
  data: LeadActivityUpdateManyMutationInput;
  where: LeadActivityScalarWhereInput;
};

export type LeadActivityUpdateManyWithWhereWithoutSiteInput = {
  data: LeadActivityUpdateManyMutationInput;
  where: LeadActivityScalarWhereInput;
};

export type LeadActivityUpdateManyWithWhereWithoutTeamInput = {
  data: LeadActivityUpdateManyMutationInput;
  where: LeadActivityScalarWhereInput;
};

export type LeadActivityUpdateManyWithWhereWithoutUserInput = {
  data: LeadActivityUpdateManyMutationInput;
  where: LeadActivityScalarWhereInput;
};

export type LeadActivityUpdateOneWithoutEmail_ItemInput = {
  connect?: InputMaybe<LeadActivityWhereUniqueInput>;
  connectOrCreate?: InputMaybe<LeadActivityCreateOrConnectWithoutEmail_ItemInput>;
  create?: InputMaybe<LeadActivityCreateWithoutEmail_ItemInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<LeadActivityUpdateWithoutEmail_ItemInput>;
  upsert?: InputMaybe<LeadActivityUpsertWithoutEmail_ItemInput>;
};

export type LeadActivityUpdateOneWithoutInboundConciergeEventsInput = {
  connect?: InputMaybe<LeadActivityWhereUniqueInput>;
  connectOrCreate?: InputMaybe<LeadActivityCreateOrConnectWithoutInboundConciergeEventsInput>;
  create?: InputMaybe<LeadActivityCreateWithoutInboundConciergeEventsInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<LeadActivityUpdateWithoutInboundConciergeEventsInput>;
  upsert?: InputMaybe<LeadActivityUpsertWithoutInboundConciergeEventsInput>;
};

export type LeadActivityUpdateOneWithoutLead_Assignment_History_ChangeInput = {
  connect?: InputMaybe<LeadActivityWhereUniqueInput>;
  connectOrCreate?: InputMaybe<LeadActivityCreateOrConnectWithoutLead_Assignment_History_ChangeInput>;
  create?: InputMaybe<LeadActivityCreateWithoutLead_Assignment_History_ChangeInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<LeadActivityUpdateWithoutLead_Assignment_History_ChangeInput>;
  upsert?: InputMaybe<LeadActivityUpsertWithoutLead_Assignment_History_ChangeInput>;
};

export type LeadActivityUpdateOneWithoutOriginating_Schedule_ItemInput = {
  connect?: InputMaybe<LeadActivityWhereUniqueInput>;
  connectOrCreate?: InputMaybe<LeadActivityCreateOrConnectWithoutOriginating_Schedule_ItemInput>;
  create?: InputMaybe<LeadActivityCreateWithoutOriginating_Schedule_ItemInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<LeadActivityUpdateWithoutOriginating_Schedule_ItemInput>;
  upsert?: InputMaybe<LeadActivityUpsertWithoutOriginating_Schedule_ItemInput>;
};

export type LeadActivityUpdateOneWithoutSaleInput = {
  connect?: InputMaybe<LeadActivityWhereUniqueInput>;
  connectOrCreate?: InputMaybe<LeadActivityCreateOrConnectWithoutSaleInput>;
  create?: InputMaybe<LeadActivityCreateWithoutSaleInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<LeadActivityUpdateWithoutSaleInput>;
  upsert?: InputMaybe<LeadActivityUpsertWithoutSaleInput>;
};

export type LeadActivityUpdateOneWithoutSchedule_ItemInput = {
  connect?: InputMaybe<LeadActivityWhereUniqueInput>;
  connectOrCreate?: InputMaybe<LeadActivityCreateOrConnectWithoutSchedule_ItemInput>;
  create?: InputMaybe<LeadActivityCreateWithoutSchedule_ItemInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<LeadActivityUpdateWithoutSchedule_ItemInput>;
  upsert?: InputMaybe<LeadActivityUpsertWithoutSchedule_ItemInput>;
};

export type LeadActivityUpdateOneWithoutSequenceEntryExitLeadInput = {
  connect?: InputMaybe<LeadActivityWhereUniqueInput>;
  connectOrCreate?: InputMaybe<LeadActivityCreateOrConnectWithoutSequenceEntryExitLeadInput>;
  create?: InputMaybe<LeadActivityCreateWithoutSequenceEntryExitLeadInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<LeadActivityUpdateWithoutSequenceEntryExitLeadInput>;
  upsert?: InputMaybe<LeadActivityUpsertWithoutSequenceEntryExitLeadInput>;
};

export type LeadActivityUpdateOneWithoutSmsItemInput = {
  connect?: InputMaybe<LeadActivityWhereUniqueInput>;
  connectOrCreate?: InputMaybe<LeadActivityCreateOrConnectWithoutSmsItemInput>;
  create?: InputMaybe<LeadActivityCreateWithoutSmsItemInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<LeadActivityUpdateWithoutSmsItemInput>;
  upsert?: InputMaybe<LeadActivityUpsertWithoutSmsItemInput>;
};

export type LeadActivityUpdateOneWithoutStat_Item_Aggregation_PipelineInput = {
  connect?: InputMaybe<LeadActivityWhereUniqueInput>;
  connectOrCreate?: InputMaybe<LeadActivityCreateOrConnectWithoutStat_Item_Aggregation_PipelineInput>;
  create?: InputMaybe<LeadActivityCreateWithoutStat_Item_Aggregation_PipelineInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<LeadActivityUpdateWithoutStat_Item_Aggregation_PipelineInput>;
  upsert?: InputMaybe<LeadActivityUpsertWithoutStat_Item_Aggregation_PipelineInput>;
};

export type LeadActivityUpdateOneWithoutStat_Item_AggregationInput = {
  connect?: InputMaybe<LeadActivityWhereUniqueInput>;
  connectOrCreate?: InputMaybe<LeadActivityCreateOrConnectWithoutStat_Item_AggregationInput>;
  create?: InputMaybe<LeadActivityCreateWithoutStat_Item_AggregationInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<LeadActivityUpdateWithoutStat_Item_AggregationInput>;
  upsert?: InputMaybe<LeadActivityUpsertWithoutStat_Item_AggregationInput>;
};

export type LeadActivityUpdateOneWithoutStepActionLogInput = {
  connect?: InputMaybe<LeadActivityWhereUniqueInput>;
  connectOrCreate?: InputMaybe<LeadActivityCreateOrConnectWithoutStepActionLogInput>;
  create?: InputMaybe<LeadActivityCreateWithoutStepActionLogInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<LeadActivityUpdateWithoutStepActionLogInput>;
  upsert?: InputMaybe<LeadActivityUpsertWithoutStepActionLogInput>;
};

export type LeadActivityUpdateWithoutAssociate_ChildInput = {
  associate_group_ids?: InputMaybe<LeadActivityUpdateassociate_Group_IdsInput>;
  associate_group_leads?: InputMaybe<LeadUpdateManyWithoutAssociate_Group_Lead_ActivitiesInput>;
  associated_action?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  automated_campaign?: InputMaybe<AutomatedCampaignUpdateOneWithoutLead_ActivitiesInput>;
  call_completed_with?: InputMaybe<NullableEnumCallresultFieldUpdateOperationsInput>;
  call_result_deleted?: InputMaybe<BoolFieldUpdateOperationsInput>;
  campaign_event?: InputMaybe<ScheduleItemUpdateOneWithoutCampaign_ActivitiesInput>;
  channel?: InputMaybe<NullableEnumChannelFieldUpdateOperationsInput>;
  communication_type?: InputMaybe<NullableEnumCommunication_TypeFieldUpdateOperationsInput>;
  conference?: InputMaybe<ConferenceUpdateOneWithoutLead_ActivitiesInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  curr_lead_val?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  current_implied_action?: InputMaybe<NullableEnumHingepointFieldUpdateOperationsInput>;
  customer_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  customer_number_country_code?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  dedupe_migration?: InputMaybe<BoolFieldUpdateOperationsInput>;
  email_item?: InputMaybe<EmailItemUpdateOneWithoutLead_ActivityInput>;
  from_action?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  from_callback_type?: InputMaybe<NullableEnumCallbackTypeFieldUpdateOperationsInput>;
  from_intent_type?: InputMaybe<NullableEnumIntentTypeFieldUpdateOperationsInput>;
  html?: InputMaybe<BoolFieldUpdateOperationsInput>;
  hubspot_email_engagement_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  hubspot_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  hubspot_note_engagement_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  hubspot_owner_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  ignore_sequences?: InputMaybe<BoolFieldUpdateOperationsInput>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventUpdateManyWithoutLeadActivityInput>;
  initial_days_set_out?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  is_resting_adjustment?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lead?: InputMaybe<LeadUpdateOneRequiredWithoutLead_ActivitiesInput>;
  lead_assignment_history_change?: InputMaybe<LeadAssignmentHistoryUpdateOneWithoutLead_ActivityInput>;
  lead_current_sequence?: InputMaybe<SequenceUpdateOneWithoutLeadActivitiesInput>;
  lead_current_step?: InputMaybe<SequenceStepUpdateOneWithoutLeadActivityInput>;
  local_number?: InputMaybe<TwilioNumberUpdateOneWithoutLead_ActivitiesInput>;
  next_action?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  next_callback_type?: InputMaybe<NullableEnumCallbackTypeFieldUpdateOperationsInput>;
  notes?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneWithoutLead_ActivitiesInput>;
  originating_schedule_item?: InputMaybe<ScheduleItemUpdateOneWithoutResulting_ActivityInput>;
  previous_lead_val?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  published?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  related_disposition?: InputMaybe<DispositionUpdateOneWithoutLead_ActivitiesInput>;
  resting_date_on_recycle?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  resting_type_on_recycle?: InputMaybe<NullableEnumRestingtypeFieldUpdateOperationsInput>;
  sale?: InputMaybe<SaleUpdateOneWithoutLead_ActivityInput>;
  sale_cycle?: InputMaybe<SaleCycleUpdateOneWithoutLead_ActivitiesInput>;
  schedule_item?: InputMaybe<ScheduleItemUpdateOneWithoutLead_ActivityInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutLead_ActivityInput>;
  site?: InputMaybe<SiteUpdateOneWithoutLead_ActivitiesInput>;
  skip_reason?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SMSItem?: InputMaybe<SmsItemUpdateOneWithoutLead_ActivityInput>;
  stat_item_aggregation?: InputMaybe<StatItemAggregationUpdateOneWithoutLead_ActivityInput>;
  stat_item_aggregation_pipeline?: InputMaybe<StatItemAggregationUpdateOneWithoutLead_Activity_PipelineInput>;
  StepActionLog?: InputMaybe<StepActionLogUpdateManyWithoutLeadActivityInput>;
  team?: InputMaybe<TeamUpdateOneWithoutLead_ActivitiesInput>;
  title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  twilio_action?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  type?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneWithoutLead_ActivitiesInput>;
  user_role?: InputMaybe<NullableEnumRoleFieldUpdateOperationsInput>;
};

export type LeadActivityUpdateWithoutAssociate_Group_LeadsInput = {
  associate_child?: InputMaybe<LeadUpdateOneWithoutChild_Lead_ActivitesInput>;
  associate_group_ids?: InputMaybe<LeadActivityUpdateassociate_Group_IdsInput>;
  associated_action?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  automated_campaign?: InputMaybe<AutomatedCampaignUpdateOneWithoutLead_ActivitiesInput>;
  call_completed_with?: InputMaybe<NullableEnumCallresultFieldUpdateOperationsInput>;
  call_result_deleted?: InputMaybe<BoolFieldUpdateOperationsInput>;
  campaign_event?: InputMaybe<ScheduleItemUpdateOneWithoutCampaign_ActivitiesInput>;
  channel?: InputMaybe<NullableEnumChannelFieldUpdateOperationsInput>;
  communication_type?: InputMaybe<NullableEnumCommunication_TypeFieldUpdateOperationsInput>;
  conference?: InputMaybe<ConferenceUpdateOneWithoutLead_ActivitiesInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  curr_lead_val?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  current_implied_action?: InputMaybe<NullableEnumHingepointFieldUpdateOperationsInput>;
  customer_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  customer_number_country_code?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  dedupe_migration?: InputMaybe<BoolFieldUpdateOperationsInput>;
  email_item?: InputMaybe<EmailItemUpdateOneWithoutLead_ActivityInput>;
  from_action?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  from_callback_type?: InputMaybe<NullableEnumCallbackTypeFieldUpdateOperationsInput>;
  from_intent_type?: InputMaybe<NullableEnumIntentTypeFieldUpdateOperationsInput>;
  html?: InputMaybe<BoolFieldUpdateOperationsInput>;
  hubspot_email_engagement_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  hubspot_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  hubspot_note_engagement_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  hubspot_owner_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  ignore_sequences?: InputMaybe<BoolFieldUpdateOperationsInput>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventUpdateManyWithoutLeadActivityInput>;
  initial_days_set_out?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  is_resting_adjustment?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lead?: InputMaybe<LeadUpdateOneRequiredWithoutLead_ActivitiesInput>;
  lead_assignment_history_change?: InputMaybe<LeadAssignmentHistoryUpdateOneWithoutLead_ActivityInput>;
  lead_current_sequence?: InputMaybe<SequenceUpdateOneWithoutLeadActivitiesInput>;
  lead_current_step?: InputMaybe<SequenceStepUpdateOneWithoutLeadActivityInput>;
  local_number?: InputMaybe<TwilioNumberUpdateOneWithoutLead_ActivitiesInput>;
  next_action?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  next_callback_type?: InputMaybe<NullableEnumCallbackTypeFieldUpdateOperationsInput>;
  notes?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneWithoutLead_ActivitiesInput>;
  originating_schedule_item?: InputMaybe<ScheduleItemUpdateOneWithoutResulting_ActivityInput>;
  previous_lead_val?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  published?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  related_disposition?: InputMaybe<DispositionUpdateOneWithoutLead_ActivitiesInput>;
  resting_date_on_recycle?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  resting_type_on_recycle?: InputMaybe<NullableEnumRestingtypeFieldUpdateOperationsInput>;
  sale?: InputMaybe<SaleUpdateOneWithoutLead_ActivityInput>;
  sale_cycle?: InputMaybe<SaleCycleUpdateOneWithoutLead_ActivitiesInput>;
  schedule_item?: InputMaybe<ScheduleItemUpdateOneWithoutLead_ActivityInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutLead_ActivityInput>;
  site?: InputMaybe<SiteUpdateOneWithoutLead_ActivitiesInput>;
  skip_reason?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SMSItem?: InputMaybe<SmsItemUpdateOneWithoutLead_ActivityInput>;
  stat_item_aggregation?: InputMaybe<StatItemAggregationUpdateOneWithoutLead_ActivityInput>;
  stat_item_aggregation_pipeline?: InputMaybe<StatItemAggregationUpdateOneWithoutLead_Activity_PipelineInput>;
  StepActionLog?: InputMaybe<StepActionLogUpdateManyWithoutLeadActivityInput>;
  team?: InputMaybe<TeamUpdateOneWithoutLead_ActivitiesInput>;
  title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  twilio_action?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  type?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneWithoutLead_ActivitiesInput>;
  user_role?: InputMaybe<NullableEnumRoleFieldUpdateOperationsInput>;
};

export type LeadActivityUpdateWithoutAutomated_CampaignInput = {
  associate_child?: InputMaybe<LeadUpdateOneWithoutChild_Lead_ActivitesInput>;
  associate_group_ids?: InputMaybe<LeadActivityUpdateassociate_Group_IdsInput>;
  associate_group_leads?: InputMaybe<LeadUpdateManyWithoutAssociate_Group_Lead_ActivitiesInput>;
  associated_action?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  call_completed_with?: InputMaybe<NullableEnumCallresultFieldUpdateOperationsInput>;
  call_result_deleted?: InputMaybe<BoolFieldUpdateOperationsInput>;
  campaign_event?: InputMaybe<ScheduleItemUpdateOneWithoutCampaign_ActivitiesInput>;
  channel?: InputMaybe<NullableEnumChannelFieldUpdateOperationsInput>;
  communication_type?: InputMaybe<NullableEnumCommunication_TypeFieldUpdateOperationsInput>;
  conference?: InputMaybe<ConferenceUpdateOneWithoutLead_ActivitiesInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  curr_lead_val?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  current_implied_action?: InputMaybe<NullableEnumHingepointFieldUpdateOperationsInput>;
  customer_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  customer_number_country_code?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  dedupe_migration?: InputMaybe<BoolFieldUpdateOperationsInput>;
  email_item?: InputMaybe<EmailItemUpdateOneWithoutLead_ActivityInput>;
  from_action?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  from_callback_type?: InputMaybe<NullableEnumCallbackTypeFieldUpdateOperationsInput>;
  from_intent_type?: InputMaybe<NullableEnumIntentTypeFieldUpdateOperationsInput>;
  html?: InputMaybe<BoolFieldUpdateOperationsInput>;
  hubspot_email_engagement_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  hubspot_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  hubspot_note_engagement_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  hubspot_owner_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  ignore_sequences?: InputMaybe<BoolFieldUpdateOperationsInput>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventUpdateManyWithoutLeadActivityInput>;
  initial_days_set_out?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  is_resting_adjustment?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lead?: InputMaybe<LeadUpdateOneRequiredWithoutLead_ActivitiesInput>;
  lead_assignment_history_change?: InputMaybe<LeadAssignmentHistoryUpdateOneWithoutLead_ActivityInput>;
  lead_current_sequence?: InputMaybe<SequenceUpdateOneWithoutLeadActivitiesInput>;
  lead_current_step?: InputMaybe<SequenceStepUpdateOneWithoutLeadActivityInput>;
  local_number?: InputMaybe<TwilioNumberUpdateOneWithoutLead_ActivitiesInput>;
  next_action?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  next_callback_type?: InputMaybe<NullableEnumCallbackTypeFieldUpdateOperationsInput>;
  notes?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneWithoutLead_ActivitiesInput>;
  originating_schedule_item?: InputMaybe<ScheduleItemUpdateOneWithoutResulting_ActivityInput>;
  previous_lead_val?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  published?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  related_disposition?: InputMaybe<DispositionUpdateOneWithoutLead_ActivitiesInput>;
  resting_date_on_recycle?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  resting_type_on_recycle?: InputMaybe<NullableEnumRestingtypeFieldUpdateOperationsInput>;
  sale?: InputMaybe<SaleUpdateOneWithoutLead_ActivityInput>;
  sale_cycle?: InputMaybe<SaleCycleUpdateOneWithoutLead_ActivitiesInput>;
  schedule_item?: InputMaybe<ScheduleItemUpdateOneWithoutLead_ActivityInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutLead_ActivityInput>;
  site?: InputMaybe<SiteUpdateOneWithoutLead_ActivitiesInput>;
  skip_reason?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SMSItem?: InputMaybe<SmsItemUpdateOneWithoutLead_ActivityInput>;
  stat_item_aggregation?: InputMaybe<StatItemAggregationUpdateOneWithoutLead_ActivityInput>;
  stat_item_aggregation_pipeline?: InputMaybe<StatItemAggregationUpdateOneWithoutLead_Activity_PipelineInput>;
  StepActionLog?: InputMaybe<StepActionLogUpdateManyWithoutLeadActivityInput>;
  team?: InputMaybe<TeamUpdateOneWithoutLead_ActivitiesInput>;
  title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  twilio_action?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  type?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneWithoutLead_ActivitiesInput>;
  user_role?: InputMaybe<NullableEnumRoleFieldUpdateOperationsInput>;
};

export type LeadActivityUpdateWithoutCampaign_EventInput = {
  associate_child?: InputMaybe<LeadUpdateOneWithoutChild_Lead_ActivitesInput>;
  associate_group_ids?: InputMaybe<LeadActivityUpdateassociate_Group_IdsInput>;
  associate_group_leads?: InputMaybe<LeadUpdateManyWithoutAssociate_Group_Lead_ActivitiesInput>;
  associated_action?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  automated_campaign?: InputMaybe<AutomatedCampaignUpdateOneWithoutLead_ActivitiesInput>;
  call_completed_with?: InputMaybe<NullableEnumCallresultFieldUpdateOperationsInput>;
  call_result_deleted?: InputMaybe<BoolFieldUpdateOperationsInput>;
  channel?: InputMaybe<NullableEnumChannelFieldUpdateOperationsInput>;
  communication_type?: InputMaybe<NullableEnumCommunication_TypeFieldUpdateOperationsInput>;
  conference?: InputMaybe<ConferenceUpdateOneWithoutLead_ActivitiesInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  curr_lead_val?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  current_implied_action?: InputMaybe<NullableEnumHingepointFieldUpdateOperationsInput>;
  customer_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  customer_number_country_code?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  dedupe_migration?: InputMaybe<BoolFieldUpdateOperationsInput>;
  email_item?: InputMaybe<EmailItemUpdateOneWithoutLead_ActivityInput>;
  from_action?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  from_callback_type?: InputMaybe<NullableEnumCallbackTypeFieldUpdateOperationsInput>;
  from_intent_type?: InputMaybe<NullableEnumIntentTypeFieldUpdateOperationsInput>;
  html?: InputMaybe<BoolFieldUpdateOperationsInput>;
  hubspot_email_engagement_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  hubspot_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  hubspot_note_engagement_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  hubspot_owner_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  ignore_sequences?: InputMaybe<BoolFieldUpdateOperationsInput>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventUpdateManyWithoutLeadActivityInput>;
  initial_days_set_out?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  is_resting_adjustment?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lead?: InputMaybe<LeadUpdateOneRequiredWithoutLead_ActivitiesInput>;
  lead_assignment_history_change?: InputMaybe<LeadAssignmentHistoryUpdateOneWithoutLead_ActivityInput>;
  lead_current_sequence?: InputMaybe<SequenceUpdateOneWithoutLeadActivitiesInput>;
  lead_current_step?: InputMaybe<SequenceStepUpdateOneWithoutLeadActivityInput>;
  local_number?: InputMaybe<TwilioNumberUpdateOneWithoutLead_ActivitiesInput>;
  next_action?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  next_callback_type?: InputMaybe<NullableEnumCallbackTypeFieldUpdateOperationsInput>;
  notes?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneWithoutLead_ActivitiesInput>;
  originating_schedule_item?: InputMaybe<ScheduleItemUpdateOneWithoutResulting_ActivityInput>;
  previous_lead_val?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  published?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  related_disposition?: InputMaybe<DispositionUpdateOneWithoutLead_ActivitiesInput>;
  resting_date_on_recycle?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  resting_type_on_recycle?: InputMaybe<NullableEnumRestingtypeFieldUpdateOperationsInput>;
  sale?: InputMaybe<SaleUpdateOneWithoutLead_ActivityInput>;
  sale_cycle?: InputMaybe<SaleCycleUpdateOneWithoutLead_ActivitiesInput>;
  schedule_item?: InputMaybe<ScheduleItemUpdateOneWithoutLead_ActivityInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutLead_ActivityInput>;
  site?: InputMaybe<SiteUpdateOneWithoutLead_ActivitiesInput>;
  skip_reason?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SMSItem?: InputMaybe<SmsItemUpdateOneWithoutLead_ActivityInput>;
  stat_item_aggregation?: InputMaybe<StatItemAggregationUpdateOneWithoutLead_ActivityInput>;
  stat_item_aggregation_pipeline?: InputMaybe<StatItemAggregationUpdateOneWithoutLead_Activity_PipelineInput>;
  StepActionLog?: InputMaybe<StepActionLogUpdateManyWithoutLeadActivityInput>;
  team?: InputMaybe<TeamUpdateOneWithoutLead_ActivitiesInput>;
  title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  twilio_action?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  type?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneWithoutLead_ActivitiesInput>;
  user_role?: InputMaybe<NullableEnumRoleFieldUpdateOperationsInput>;
};

export type LeadActivityUpdateWithoutConferenceInput = {
  associate_child?: InputMaybe<LeadUpdateOneWithoutChild_Lead_ActivitesInput>;
  associate_group_ids?: InputMaybe<LeadActivityUpdateassociate_Group_IdsInput>;
  associate_group_leads?: InputMaybe<LeadUpdateManyWithoutAssociate_Group_Lead_ActivitiesInput>;
  associated_action?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  automated_campaign?: InputMaybe<AutomatedCampaignUpdateOneWithoutLead_ActivitiesInput>;
  call_completed_with?: InputMaybe<NullableEnumCallresultFieldUpdateOperationsInput>;
  call_result_deleted?: InputMaybe<BoolFieldUpdateOperationsInput>;
  campaign_event?: InputMaybe<ScheduleItemUpdateOneWithoutCampaign_ActivitiesInput>;
  channel?: InputMaybe<NullableEnumChannelFieldUpdateOperationsInput>;
  communication_type?: InputMaybe<NullableEnumCommunication_TypeFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  curr_lead_val?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  current_implied_action?: InputMaybe<NullableEnumHingepointFieldUpdateOperationsInput>;
  customer_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  customer_number_country_code?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  dedupe_migration?: InputMaybe<BoolFieldUpdateOperationsInput>;
  email_item?: InputMaybe<EmailItemUpdateOneWithoutLead_ActivityInput>;
  from_action?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  from_callback_type?: InputMaybe<NullableEnumCallbackTypeFieldUpdateOperationsInput>;
  from_intent_type?: InputMaybe<NullableEnumIntentTypeFieldUpdateOperationsInput>;
  html?: InputMaybe<BoolFieldUpdateOperationsInput>;
  hubspot_email_engagement_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  hubspot_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  hubspot_note_engagement_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  hubspot_owner_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  ignore_sequences?: InputMaybe<BoolFieldUpdateOperationsInput>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventUpdateManyWithoutLeadActivityInput>;
  initial_days_set_out?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  is_resting_adjustment?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lead?: InputMaybe<LeadUpdateOneRequiredWithoutLead_ActivitiesInput>;
  lead_assignment_history_change?: InputMaybe<LeadAssignmentHistoryUpdateOneWithoutLead_ActivityInput>;
  lead_current_sequence?: InputMaybe<SequenceUpdateOneWithoutLeadActivitiesInput>;
  lead_current_step?: InputMaybe<SequenceStepUpdateOneWithoutLeadActivityInput>;
  local_number?: InputMaybe<TwilioNumberUpdateOneWithoutLead_ActivitiesInput>;
  next_action?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  next_callback_type?: InputMaybe<NullableEnumCallbackTypeFieldUpdateOperationsInput>;
  notes?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneWithoutLead_ActivitiesInput>;
  originating_schedule_item?: InputMaybe<ScheduleItemUpdateOneWithoutResulting_ActivityInput>;
  previous_lead_val?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  published?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  related_disposition?: InputMaybe<DispositionUpdateOneWithoutLead_ActivitiesInput>;
  resting_date_on_recycle?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  resting_type_on_recycle?: InputMaybe<NullableEnumRestingtypeFieldUpdateOperationsInput>;
  sale?: InputMaybe<SaleUpdateOneWithoutLead_ActivityInput>;
  sale_cycle?: InputMaybe<SaleCycleUpdateOneWithoutLead_ActivitiesInput>;
  schedule_item?: InputMaybe<ScheduleItemUpdateOneWithoutLead_ActivityInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutLead_ActivityInput>;
  site?: InputMaybe<SiteUpdateOneWithoutLead_ActivitiesInput>;
  skip_reason?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SMSItem?: InputMaybe<SmsItemUpdateOneWithoutLead_ActivityInput>;
  stat_item_aggregation?: InputMaybe<StatItemAggregationUpdateOneWithoutLead_ActivityInput>;
  stat_item_aggregation_pipeline?: InputMaybe<StatItemAggregationUpdateOneWithoutLead_Activity_PipelineInput>;
  StepActionLog?: InputMaybe<StepActionLogUpdateManyWithoutLeadActivityInput>;
  team?: InputMaybe<TeamUpdateOneWithoutLead_ActivitiesInput>;
  title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  twilio_action?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  type?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneWithoutLead_ActivitiesInput>;
  user_role?: InputMaybe<NullableEnumRoleFieldUpdateOperationsInput>;
};

export type LeadActivityUpdateWithoutEmail_ItemInput = {
  associate_child?: InputMaybe<LeadUpdateOneWithoutChild_Lead_ActivitesInput>;
  associate_group_ids?: InputMaybe<LeadActivityUpdateassociate_Group_IdsInput>;
  associate_group_leads?: InputMaybe<LeadUpdateManyWithoutAssociate_Group_Lead_ActivitiesInput>;
  associated_action?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  automated_campaign?: InputMaybe<AutomatedCampaignUpdateOneWithoutLead_ActivitiesInput>;
  call_completed_with?: InputMaybe<NullableEnumCallresultFieldUpdateOperationsInput>;
  call_result_deleted?: InputMaybe<BoolFieldUpdateOperationsInput>;
  campaign_event?: InputMaybe<ScheduleItemUpdateOneWithoutCampaign_ActivitiesInput>;
  channel?: InputMaybe<NullableEnumChannelFieldUpdateOperationsInput>;
  communication_type?: InputMaybe<NullableEnumCommunication_TypeFieldUpdateOperationsInput>;
  conference?: InputMaybe<ConferenceUpdateOneWithoutLead_ActivitiesInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  curr_lead_val?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  current_implied_action?: InputMaybe<NullableEnumHingepointFieldUpdateOperationsInput>;
  customer_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  customer_number_country_code?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  dedupe_migration?: InputMaybe<BoolFieldUpdateOperationsInput>;
  from_action?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  from_callback_type?: InputMaybe<NullableEnumCallbackTypeFieldUpdateOperationsInput>;
  from_intent_type?: InputMaybe<NullableEnumIntentTypeFieldUpdateOperationsInput>;
  html?: InputMaybe<BoolFieldUpdateOperationsInput>;
  hubspot_email_engagement_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  hubspot_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  hubspot_note_engagement_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  hubspot_owner_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  ignore_sequences?: InputMaybe<BoolFieldUpdateOperationsInput>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventUpdateManyWithoutLeadActivityInput>;
  initial_days_set_out?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  is_resting_adjustment?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lead?: InputMaybe<LeadUpdateOneRequiredWithoutLead_ActivitiesInput>;
  lead_assignment_history_change?: InputMaybe<LeadAssignmentHistoryUpdateOneWithoutLead_ActivityInput>;
  lead_current_sequence?: InputMaybe<SequenceUpdateOneWithoutLeadActivitiesInput>;
  lead_current_step?: InputMaybe<SequenceStepUpdateOneWithoutLeadActivityInput>;
  local_number?: InputMaybe<TwilioNumberUpdateOneWithoutLead_ActivitiesInput>;
  next_action?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  next_callback_type?: InputMaybe<NullableEnumCallbackTypeFieldUpdateOperationsInput>;
  notes?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneWithoutLead_ActivitiesInput>;
  originating_schedule_item?: InputMaybe<ScheduleItemUpdateOneWithoutResulting_ActivityInput>;
  previous_lead_val?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  published?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  related_disposition?: InputMaybe<DispositionUpdateOneWithoutLead_ActivitiesInput>;
  resting_date_on_recycle?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  resting_type_on_recycle?: InputMaybe<NullableEnumRestingtypeFieldUpdateOperationsInput>;
  sale?: InputMaybe<SaleUpdateOneWithoutLead_ActivityInput>;
  sale_cycle?: InputMaybe<SaleCycleUpdateOneWithoutLead_ActivitiesInput>;
  schedule_item?: InputMaybe<ScheduleItemUpdateOneWithoutLead_ActivityInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutLead_ActivityInput>;
  site?: InputMaybe<SiteUpdateOneWithoutLead_ActivitiesInput>;
  skip_reason?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SMSItem?: InputMaybe<SmsItemUpdateOneWithoutLead_ActivityInput>;
  stat_item_aggregation?: InputMaybe<StatItemAggregationUpdateOneWithoutLead_ActivityInput>;
  stat_item_aggregation_pipeline?: InputMaybe<StatItemAggregationUpdateOneWithoutLead_Activity_PipelineInput>;
  StepActionLog?: InputMaybe<StepActionLogUpdateManyWithoutLeadActivityInput>;
  team?: InputMaybe<TeamUpdateOneWithoutLead_ActivitiesInput>;
  title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  twilio_action?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  type?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneWithoutLead_ActivitiesInput>;
  user_role?: InputMaybe<NullableEnumRoleFieldUpdateOperationsInput>;
};

export type LeadActivityUpdateWithoutInboundConciergeEventsInput = {
  associate_child?: InputMaybe<LeadUpdateOneWithoutChild_Lead_ActivitesInput>;
  associate_group_ids?: InputMaybe<LeadActivityUpdateassociate_Group_IdsInput>;
  associate_group_leads?: InputMaybe<LeadUpdateManyWithoutAssociate_Group_Lead_ActivitiesInput>;
  associated_action?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  automated_campaign?: InputMaybe<AutomatedCampaignUpdateOneWithoutLead_ActivitiesInput>;
  call_completed_with?: InputMaybe<NullableEnumCallresultFieldUpdateOperationsInput>;
  call_result_deleted?: InputMaybe<BoolFieldUpdateOperationsInput>;
  campaign_event?: InputMaybe<ScheduleItemUpdateOneWithoutCampaign_ActivitiesInput>;
  channel?: InputMaybe<NullableEnumChannelFieldUpdateOperationsInput>;
  communication_type?: InputMaybe<NullableEnumCommunication_TypeFieldUpdateOperationsInput>;
  conference?: InputMaybe<ConferenceUpdateOneWithoutLead_ActivitiesInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  curr_lead_val?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  current_implied_action?: InputMaybe<NullableEnumHingepointFieldUpdateOperationsInput>;
  customer_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  customer_number_country_code?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  dedupe_migration?: InputMaybe<BoolFieldUpdateOperationsInput>;
  email_item?: InputMaybe<EmailItemUpdateOneWithoutLead_ActivityInput>;
  from_action?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  from_callback_type?: InputMaybe<NullableEnumCallbackTypeFieldUpdateOperationsInput>;
  from_intent_type?: InputMaybe<NullableEnumIntentTypeFieldUpdateOperationsInput>;
  html?: InputMaybe<BoolFieldUpdateOperationsInput>;
  hubspot_email_engagement_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  hubspot_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  hubspot_note_engagement_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  hubspot_owner_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  ignore_sequences?: InputMaybe<BoolFieldUpdateOperationsInput>;
  initial_days_set_out?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  is_resting_adjustment?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lead?: InputMaybe<LeadUpdateOneRequiredWithoutLead_ActivitiesInput>;
  lead_assignment_history_change?: InputMaybe<LeadAssignmentHistoryUpdateOneWithoutLead_ActivityInput>;
  lead_current_sequence?: InputMaybe<SequenceUpdateOneWithoutLeadActivitiesInput>;
  lead_current_step?: InputMaybe<SequenceStepUpdateOneWithoutLeadActivityInput>;
  local_number?: InputMaybe<TwilioNumberUpdateOneWithoutLead_ActivitiesInput>;
  next_action?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  next_callback_type?: InputMaybe<NullableEnumCallbackTypeFieldUpdateOperationsInput>;
  notes?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneWithoutLead_ActivitiesInput>;
  originating_schedule_item?: InputMaybe<ScheduleItemUpdateOneWithoutResulting_ActivityInput>;
  previous_lead_val?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  published?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  related_disposition?: InputMaybe<DispositionUpdateOneWithoutLead_ActivitiesInput>;
  resting_date_on_recycle?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  resting_type_on_recycle?: InputMaybe<NullableEnumRestingtypeFieldUpdateOperationsInput>;
  sale?: InputMaybe<SaleUpdateOneWithoutLead_ActivityInput>;
  sale_cycle?: InputMaybe<SaleCycleUpdateOneWithoutLead_ActivitiesInput>;
  schedule_item?: InputMaybe<ScheduleItemUpdateOneWithoutLead_ActivityInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutLead_ActivityInput>;
  site?: InputMaybe<SiteUpdateOneWithoutLead_ActivitiesInput>;
  skip_reason?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SMSItem?: InputMaybe<SmsItemUpdateOneWithoutLead_ActivityInput>;
  stat_item_aggregation?: InputMaybe<StatItemAggregationUpdateOneWithoutLead_ActivityInput>;
  stat_item_aggregation_pipeline?: InputMaybe<StatItemAggregationUpdateOneWithoutLead_Activity_PipelineInput>;
  StepActionLog?: InputMaybe<StepActionLogUpdateManyWithoutLeadActivityInput>;
  team?: InputMaybe<TeamUpdateOneWithoutLead_ActivitiesInput>;
  title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  twilio_action?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  type?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneWithoutLead_ActivitiesInput>;
  user_role?: InputMaybe<NullableEnumRoleFieldUpdateOperationsInput>;
};

export type LeadActivityUpdateWithoutLead_Assignment_History_ChangeInput = {
  associate_child?: InputMaybe<LeadUpdateOneWithoutChild_Lead_ActivitesInput>;
  associate_group_ids?: InputMaybe<LeadActivityUpdateassociate_Group_IdsInput>;
  associate_group_leads?: InputMaybe<LeadUpdateManyWithoutAssociate_Group_Lead_ActivitiesInput>;
  associated_action?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  automated_campaign?: InputMaybe<AutomatedCampaignUpdateOneWithoutLead_ActivitiesInput>;
  call_completed_with?: InputMaybe<NullableEnumCallresultFieldUpdateOperationsInput>;
  call_result_deleted?: InputMaybe<BoolFieldUpdateOperationsInput>;
  campaign_event?: InputMaybe<ScheduleItemUpdateOneWithoutCampaign_ActivitiesInput>;
  channel?: InputMaybe<NullableEnumChannelFieldUpdateOperationsInput>;
  communication_type?: InputMaybe<NullableEnumCommunication_TypeFieldUpdateOperationsInput>;
  conference?: InputMaybe<ConferenceUpdateOneWithoutLead_ActivitiesInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  curr_lead_val?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  current_implied_action?: InputMaybe<NullableEnumHingepointFieldUpdateOperationsInput>;
  customer_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  customer_number_country_code?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  dedupe_migration?: InputMaybe<BoolFieldUpdateOperationsInput>;
  email_item?: InputMaybe<EmailItemUpdateOneWithoutLead_ActivityInput>;
  from_action?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  from_callback_type?: InputMaybe<NullableEnumCallbackTypeFieldUpdateOperationsInput>;
  from_intent_type?: InputMaybe<NullableEnumIntentTypeFieldUpdateOperationsInput>;
  html?: InputMaybe<BoolFieldUpdateOperationsInput>;
  hubspot_email_engagement_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  hubspot_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  hubspot_note_engagement_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  hubspot_owner_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  ignore_sequences?: InputMaybe<BoolFieldUpdateOperationsInput>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventUpdateManyWithoutLeadActivityInput>;
  initial_days_set_out?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  is_resting_adjustment?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lead?: InputMaybe<LeadUpdateOneRequiredWithoutLead_ActivitiesInput>;
  lead_current_sequence?: InputMaybe<SequenceUpdateOneWithoutLeadActivitiesInput>;
  lead_current_step?: InputMaybe<SequenceStepUpdateOneWithoutLeadActivityInput>;
  local_number?: InputMaybe<TwilioNumberUpdateOneWithoutLead_ActivitiesInput>;
  next_action?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  next_callback_type?: InputMaybe<NullableEnumCallbackTypeFieldUpdateOperationsInput>;
  notes?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneWithoutLead_ActivitiesInput>;
  originating_schedule_item?: InputMaybe<ScheduleItemUpdateOneWithoutResulting_ActivityInput>;
  previous_lead_val?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  published?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  related_disposition?: InputMaybe<DispositionUpdateOneWithoutLead_ActivitiesInput>;
  resting_date_on_recycle?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  resting_type_on_recycle?: InputMaybe<NullableEnumRestingtypeFieldUpdateOperationsInput>;
  sale?: InputMaybe<SaleUpdateOneWithoutLead_ActivityInput>;
  sale_cycle?: InputMaybe<SaleCycleUpdateOneWithoutLead_ActivitiesInput>;
  schedule_item?: InputMaybe<ScheduleItemUpdateOneWithoutLead_ActivityInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutLead_ActivityInput>;
  site?: InputMaybe<SiteUpdateOneWithoutLead_ActivitiesInput>;
  skip_reason?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SMSItem?: InputMaybe<SmsItemUpdateOneWithoutLead_ActivityInput>;
  stat_item_aggregation?: InputMaybe<StatItemAggregationUpdateOneWithoutLead_ActivityInput>;
  stat_item_aggregation_pipeline?: InputMaybe<StatItemAggregationUpdateOneWithoutLead_Activity_PipelineInput>;
  StepActionLog?: InputMaybe<StepActionLogUpdateManyWithoutLeadActivityInput>;
  team?: InputMaybe<TeamUpdateOneWithoutLead_ActivitiesInput>;
  title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  twilio_action?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  type?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneWithoutLead_ActivitiesInput>;
  user_role?: InputMaybe<NullableEnumRoleFieldUpdateOperationsInput>;
};

export type LeadActivityUpdateWithoutLead_Current_SequenceInput = {
  associate_child?: InputMaybe<LeadUpdateOneWithoutChild_Lead_ActivitesInput>;
  associate_group_ids?: InputMaybe<LeadActivityUpdateassociate_Group_IdsInput>;
  associate_group_leads?: InputMaybe<LeadUpdateManyWithoutAssociate_Group_Lead_ActivitiesInput>;
  associated_action?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  automated_campaign?: InputMaybe<AutomatedCampaignUpdateOneWithoutLead_ActivitiesInput>;
  call_completed_with?: InputMaybe<NullableEnumCallresultFieldUpdateOperationsInput>;
  call_result_deleted?: InputMaybe<BoolFieldUpdateOperationsInput>;
  campaign_event?: InputMaybe<ScheduleItemUpdateOneWithoutCampaign_ActivitiesInput>;
  channel?: InputMaybe<NullableEnumChannelFieldUpdateOperationsInput>;
  communication_type?: InputMaybe<NullableEnumCommunication_TypeFieldUpdateOperationsInput>;
  conference?: InputMaybe<ConferenceUpdateOneWithoutLead_ActivitiesInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  curr_lead_val?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  current_implied_action?: InputMaybe<NullableEnumHingepointFieldUpdateOperationsInput>;
  customer_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  customer_number_country_code?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  dedupe_migration?: InputMaybe<BoolFieldUpdateOperationsInput>;
  email_item?: InputMaybe<EmailItemUpdateOneWithoutLead_ActivityInput>;
  from_action?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  from_callback_type?: InputMaybe<NullableEnumCallbackTypeFieldUpdateOperationsInput>;
  from_intent_type?: InputMaybe<NullableEnumIntentTypeFieldUpdateOperationsInput>;
  html?: InputMaybe<BoolFieldUpdateOperationsInput>;
  hubspot_email_engagement_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  hubspot_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  hubspot_note_engagement_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  hubspot_owner_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  ignore_sequences?: InputMaybe<BoolFieldUpdateOperationsInput>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventUpdateManyWithoutLeadActivityInput>;
  initial_days_set_out?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  is_resting_adjustment?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lead?: InputMaybe<LeadUpdateOneRequiredWithoutLead_ActivitiesInput>;
  lead_assignment_history_change?: InputMaybe<LeadAssignmentHistoryUpdateOneWithoutLead_ActivityInput>;
  lead_current_step?: InputMaybe<SequenceStepUpdateOneWithoutLeadActivityInput>;
  local_number?: InputMaybe<TwilioNumberUpdateOneWithoutLead_ActivitiesInput>;
  next_action?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  next_callback_type?: InputMaybe<NullableEnumCallbackTypeFieldUpdateOperationsInput>;
  notes?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneWithoutLead_ActivitiesInput>;
  originating_schedule_item?: InputMaybe<ScheduleItemUpdateOneWithoutResulting_ActivityInput>;
  previous_lead_val?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  published?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  related_disposition?: InputMaybe<DispositionUpdateOneWithoutLead_ActivitiesInput>;
  resting_date_on_recycle?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  resting_type_on_recycle?: InputMaybe<NullableEnumRestingtypeFieldUpdateOperationsInput>;
  sale?: InputMaybe<SaleUpdateOneWithoutLead_ActivityInput>;
  sale_cycle?: InputMaybe<SaleCycleUpdateOneWithoutLead_ActivitiesInput>;
  schedule_item?: InputMaybe<ScheduleItemUpdateOneWithoutLead_ActivityInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutLead_ActivityInput>;
  site?: InputMaybe<SiteUpdateOneWithoutLead_ActivitiesInput>;
  skip_reason?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SMSItem?: InputMaybe<SmsItemUpdateOneWithoutLead_ActivityInput>;
  stat_item_aggregation?: InputMaybe<StatItemAggregationUpdateOneWithoutLead_ActivityInput>;
  stat_item_aggregation_pipeline?: InputMaybe<StatItemAggregationUpdateOneWithoutLead_Activity_PipelineInput>;
  StepActionLog?: InputMaybe<StepActionLogUpdateManyWithoutLeadActivityInput>;
  team?: InputMaybe<TeamUpdateOneWithoutLead_ActivitiesInput>;
  title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  twilio_action?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  type?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneWithoutLead_ActivitiesInput>;
  user_role?: InputMaybe<NullableEnumRoleFieldUpdateOperationsInput>;
};

export type LeadActivityUpdateWithoutLead_Current_StepInput = {
  associate_child?: InputMaybe<LeadUpdateOneWithoutChild_Lead_ActivitesInput>;
  associate_group_ids?: InputMaybe<LeadActivityUpdateassociate_Group_IdsInput>;
  associate_group_leads?: InputMaybe<LeadUpdateManyWithoutAssociate_Group_Lead_ActivitiesInput>;
  associated_action?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  automated_campaign?: InputMaybe<AutomatedCampaignUpdateOneWithoutLead_ActivitiesInput>;
  call_completed_with?: InputMaybe<NullableEnumCallresultFieldUpdateOperationsInput>;
  call_result_deleted?: InputMaybe<BoolFieldUpdateOperationsInput>;
  campaign_event?: InputMaybe<ScheduleItemUpdateOneWithoutCampaign_ActivitiesInput>;
  channel?: InputMaybe<NullableEnumChannelFieldUpdateOperationsInput>;
  communication_type?: InputMaybe<NullableEnumCommunication_TypeFieldUpdateOperationsInput>;
  conference?: InputMaybe<ConferenceUpdateOneWithoutLead_ActivitiesInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  curr_lead_val?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  current_implied_action?: InputMaybe<NullableEnumHingepointFieldUpdateOperationsInput>;
  customer_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  customer_number_country_code?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  dedupe_migration?: InputMaybe<BoolFieldUpdateOperationsInput>;
  email_item?: InputMaybe<EmailItemUpdateOneWithoutLead_ActivityInput>;
  from_action?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  from_callback_type?: InputMaybe<NullableEnumCallbackTypeFieldUpdateOperationsInput>;
  from_intent_type?: InputMaybe<NullableEnumIntentTypeFieldUpdateOperationsInput>;
  html?: InputMaybe<BoolFieldUpdateOperationsInput>;
  hubspot_email_engagement_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  hubspot_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  hubspot_note_engagement_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  hubspot_owner_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  ignore_sequences?: InputMaybe<BoolFieldUpdateOperationsInput>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventUpdateManyWithoutLeadActivityInput>;
  initial_days_set_out?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  is_resting_adjustment?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lead?: InputMaybe<LeadUpdateOneRequiredWithoutLead_ActivitiesInput>;
  lead_assignment_history_change?: InputMaybe<LeadAssignmentHistoryUpdateOneWithoutLead_ActivityInput>;
  lead_current_sequence?: InputMaybe<SequenceUpdateOneWithoutLeadActivitiesInput>;
  local_number?: InputMaybe<TwilioNumberUpdateOneWithoutLead_ActivitiesInput>;
  next_action?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  next_callback_type?: InputMaybe<NullableEnumCallbackTypeFieldUpdateOperationsInput>;
  notes?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneWithoutLead_ActivitiesInput>;
  originating_schedule_item?: InputMaybe<ScheduleItemUpdateOneWithoutResulting_ActivityInput>;
  previous_lead_val?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  published?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  related_disposition?: InputMaybe<DispositionUpdateOneWithoutLead_ActivitiesInput>;
  resting_date_on_recycle?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  resting_type_on_recycle?: InputMaybe<NullableEnumRestingtypeFieldUpdateOperationsInput>;
  sale?: InputMaybe<SaleUpdateOneWithoutLead_ActivityInput>;
  sale_cycle?: InputMaybe<SaleCycleUpdateOneWithoutLead_ActivitiesInput>;
  schedule_item?: InputMaybe<ScheduleItemUpdateOneWithoutLead_ActivityInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutLead_ActivityInput>;
  site?: InputMaybe<SiteUpdateOneWithoutLead_ActivitiesInput>;
  skip_reason?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SMSItem?: InputMaybe<SmsItemUpdateOneWithoutLead_ActivityInput>;
  stat_item_aggregation?: InputMaybe<StatItemAggregationUpdateOneWithoutLead_ActivityInput>;
  stat_item_aggregation_pipeline?: InputMaybe<StatItemAggregationUpdateOneWithoutLead_Activity_PipelineInput>;
  StepActionLog?: InputMaybe<StepActionLogUpdateManyWithoutLeadActivityInput>;
  team?: InputMaybe<TeamUpdateOneWithoutLead_ActivitiesInput>;
  title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  twilio_action?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  type?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneWithoutLead_ActivitiesInput>;
  user_role?: InputMaybe<NullableEnumRoleFieldUpdateOperationsInput>;
};

export type LeadActivityUpdateWithoutLeadInput = {
  associate_child?: InputMaybe<LeadUpdateOneWithoutChild_Lead_ActivitesInput>;
  associate_group_ids?: InputMaybe<LeadActivityUpdateassociate_Group_IdsInput>;
  associate_group_leads?: InputMaybe<LeadUpdateManyWithoutAssociate_Group_Lead_ActivitiesInput>;
  associated_action?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  automated_campaign?: InputMaybe<AutomatedCampaignUpdateOneWithoutLead_ActivitiesInput>;
  call_completed_with?: InputMaybe<NullableEnumCallresultFieldUpdateOperationsInput>;
  call_result_deleted?: InputMaybe<BoolFieldUpdateOperationsInput>;
  campaign_event?: InputMaybe<ScheduleItemUpdateOneWithoutCampaign_ActivitiesInput>;
  channel?: InputMaybe<NullableEnumChannelFieldUpdateOperationsInput>;
  communication_type?: InputMaybe<NullableEnumCommunication_TypeFieldUpdateOperationsInput>;
  conference?: InputMaybe<ConferenceUpdateOneWithoutLead_ActivitiesInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  curr_lead_val?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  current_implied_action?: InputMaybe<NullableEnumHingepointFieldUpdateOperationsInput>;
  customer_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  customer_number_country_code?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  dedupe_migration?: InputMaybe<BoolFieldUpdateOperationsInput>;
  email_item?: InputMaybe<EmailItemUpdateOneWithoutLead_ActivityInput>;
  from_action?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  from_callback_type?: InputMaybe<NullableEnumCallbackTypeFieldUpdateOperationsInput>;
  from_intent_type?: InputMaybe<NullableEnumIntentTypeFieldUpdateOperationsInput>;
  html?: InputMaybe<BoolFieldUpdateOperationsInput>;
  hubspot_email_engagement_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  hubspot_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  hubspot_note_engagement_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  hubspot_owner_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  ignore_sequences?: InputMaybe<BoolFieldUpdateOperationsInput>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventUpdateManyWithoutLeadActivityInput>;
  initial_days_set_out?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  is_resting_adjustment?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lead_assignment_history_change?: InputMaybe<LeadAssignmentHistoryUpdateOneWithoutLead_ActivityInput>;
  lead_current_sequence?: InputMaybe<SequenceUpdateOneWithoutLeadActivitiesInput>;
  lead_current_step?: InputMaybe<SequenceStepUpdateOneWithoutLeadActivityInput>;
  local_number?: InputMaybe<TwilioNumberUpdateOneWithoutLead_ActivitiesInput>;
  next_action?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  next_callback_type?: InputMaybe<NullableEnumCallbackTypeFieldUpdateOperationsInput>;
  notes?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneWithoutLead_ActivitiesInput>;
  originating_schedule_item?: InputMaybe<ScheduleItemUpdateOneWithoutResulting_ActivityInput>;
  previous_lead_val?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  published?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  related_disposition?: InputMaybe<DispositionUpdateOneWithoutLead_ActivitiesInput>;
  resting_date_on_recycle?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  resting_type_on_recycle?: InputMaybe<NullableEnumRestingtypeFieldUpdateOperationsInput>;
  sale?: InputMaybe<SaleUpdateOneWithoutLead_ActivityInput>;
  sale_cycle?: InputMaybe<SaleCycleUpdateOneWithoutLead_ActivitiesInput>;
  schedule_item?: InputMaybe<ScheduleItemUpdateOneWithoutLead_ActivityInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutLead_ActivityInput>;
  site?: InputMaybe<SiteUpdateOneWithoutLead_ActivitiesInput>;
  skip_reason?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SMSItem?: InputMaybe<SmsItemUpdateOneWithoutLead_ActivityInput>;
  stat_item_aggregation?: InputMaybe<StatItemAggregationUpdateOneWithoutLead_ActivityInput>;
  stat_item_aggregation_pipeline?: InputMaybe<StatItemAggregationUpdateOneWithoutLead_Activity_PipelineInput>;
  StepActionLog?: InputMaybe<StepActionLogUpdateManyWithoutLeadActivityInput>;
  team?: InputMaybe<TeamUpdateOneWithoutLead_ActivitiesInput>;
  title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  twilio_action?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  type?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneWithoutLead_ActivitiesInput>;
  user_role?: InputMaybe<NullableEnumRoleFieldUpdateOperationsInput>;
};

export type LeadActivityUpdateWithoutLocal_NumberInput = {
  associate_child?: InputMaybe<LeadUpdateOneWithoutChild_Lead_ActivitesInput>;
  associate_group_ids?: InputMaybe<LeadActivityUpdateassociate_Group_IdsInput>;
  associate_group_leads?: InputMaybe<LeadUpdateManyWithoutAssociate_Group_Lead_ActivitiesInput>;
  associated_action?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  automated_campaign?: InputMaybe<AutomatedCampaignUpdateOneWithoutLead_ActivitiesInput>;
  call_completed_with?: InputMaybe<NullableEnumCallresultFieldUpdateOperationsInput>;
  call_result_deleted?: InputMaybe<BoolFieldUpdateOperationsInput>;
  campaign_event?: InputMaybe<ScheduleItemUpdateOneWithoutCampaign_ActivitiesInput>;
  channel?: InputMaybe<NullableEnumChannelFieldUpdateOperationsInput>;
  communication_type?: InputMaybe<NullableEnumCommunication_TypeFieldUpdateOperationsInput>;
  conference?: InputMaybe<ConferenceUpdateOneWithoutLead_ActivitiesInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  curr_lead_val?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  current_implied_action?: InputMaybe<NullableEnumHingepointFieldUpdateOperationsInput>;
  customer_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  customer_number_country_code?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  dedupe_migration?: InputMaybe<BoolFieldUpdateOperationsInput>;
  email_item?: InputMaybe<EmailItemUpdateOneWithoutLead_ActivityInput>;
  from_action?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  from_callback_type?: InputMaybe<NullableEnumCallbackTypeFieldUpdateOperationsInput>;
  from_intent_type?: InputMaybe<NullableEnumIntentTypeFieldUpdateOperationsInput>;
  html?: InputMaybe<BoolFieldUpdateOperationsInput>;
  hubspot_email_engagement_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  hubspot_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  hubspot_note_engagement_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  hubspot_owner_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  ignore_sequences?: InputMaybe<BoolFieldUpdateOperationsInput>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventUpdateManyWithoutLeadActivityInput>;
  initial_days_set_out?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  is_resting_adjustment?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lead?: InputMaybe<LeadUpdateOneRequiredWithoutLead_ActivitiesInput>;
  lead_assignment_history_change?: InputMaybe<LeadAssignmentHistoryUpdateOneWithoutLead_ActivityInput>;
  lead_current_sequence?: InputMaybe<SequenceUpdateOneWithoutLeadActivitiesInput>;
  lead_current_step?: InputMaybe<SequenceStepUpdateOneWithoutLeadActivityInput>;
  next_action?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  next_callback_type?: InputMaybe<NullableEnumCallbackTypeFieldUpdateOperationsInput>;
  notes?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneWithoutLead_ActivitiesInput>;
  originating_schedule_item?: InputMaybe<ScheduleItemUpdateOneWithoutResulting_ActivityInput>;
  previous_lead_val?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  published?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  related_disposition?: InputMaybe<DispositionUpdateOneWithoutLead_ActivitiesInput>;
  resting_date_on_recycle?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  resting_type_on_recycle?: InputMaybe<NullableEnumRestingtypeFieldUpdateOperationsInput>;
  sale?: InputMaybe<SaleUpdateOneWithoutLead_ActivityInput>;
  sale_cycle?: InputMaybe<SaleCycleUpdateOneWithoutLead_ActivitiesInput>;
  schedule_item?: InputMaybe<ScheduleItemUpdateOneWithoutLead_ActivityInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutLead_ActivityInput>;
  site?: InputMaybe<SiteUpdateOneWithoutLead_ActivitiesInput>;
  skip_reason?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SMSItem?: InputMaybe<SmsItemUpdateOneWithoutLead_ActivityInput>;
  stat_item_aggregation?: InputMaybe<StatItemAggregationUpdateOneWithoutLead_ActivityInput>;
  stat_item_aggregation_pipeline?: InputMaybe<StatItemAggregationUpdateOneWithoutLead_Activity_PipelineInput>;
  StepActionLog?: InputMaybe<StepActionLogUpdateManyWithoutLeadActivityInput>;
  team?: InputMaybe<TeamUpdateOneWithoutLead_ActivitiesInput>;
  title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  twilio_action?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  type?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneWithoutLead_ActivitiesInput>;
  user_role?: InputMaybe<NullableEnumRoleFieldUpdateOperationsInput>;
};

export type LeadActivityUpdateWithoutOrganizationInput = {
  associate_child?: InputMaybe<LeadUpdateOneWithoutChild_Lead_ActivitesInput>;
  associate_group_ids?: InputMaybe<LeadActivityUpdateassociate_Group_IdsInput>;
  associate_group_leads?: InputMaybe<LeadUpdateManyWithoutAssociate_Group_Lead_ActivitiesInput>;
  associated_action?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  automated_campaign?: InputMaybe<AutomatedCampaignUpdateOneWithoutLead_ActivitiesInput>;
  call_completed_with?: InputMaybe<NullableEnumCallresultFieldUpdateOperationsInput>;
  call_result_deleted?: InputMaybe<BoolFieldUpdateOperationsInput>;
  campaign_event?: InputMaybe<ScheduleItemUpdateOneWithoutCampaign_ActivitiesInput>;
  channel?: InputMaybe<NullableEnumChannelFieldUpdateOperationsInput>;
  communication_type?: InputMaybe<NullableEnumCommunication_TypeFieldUpdateOperationsInput>;
  conference?: InputMaybe<ConferenceUpdateOneWithoutLead_ActivitiesInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  curr_lead_val?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  current_implied_action?: InputMaybe<NullableEnumHingepointFieldUpdateOperationsInput>;
  customer_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  customer_number_country_code?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  dedupe_migration?: InputMaybe<BoolFieldUpdateOperationsInput>;
  email_item?: InputMaybe<EmailItemUpdateOneWithoutLead_ActivityInput>;
  from_action?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  from_callback_type?: InputMaybe<NullableEnumCallbackTypeFieldUpdateOperationsInput>;
  from_intent_type?: InputMaybe<NullableEnumIntentTypeFieldUpdateOperationsInput>;
  html?: InputMaybe<BoolFieldUpdateOperationsInput>;
  hubspot_email_engagement_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  hubspot_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  hubspot_note_engagement_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  hubspot_owner_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  ignore_sequences?: InputMaybe<BoolFieldUpdateOperationsInput>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventUpdateManyWithoutLeadActivityInput>;
  initial_days_set_out?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  is_resting_adjustment?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lead?: InputMaybe<LeadUpdateOneRequiredWithoutLead_ActivitiesInput>;
  lead_assignment_history_change?: InputMaybe<LeadAssignmentHistoryUpdateOneWithoutLead_ActivityInput>;
  lead_current_sequence?: InputMaybe<SequenceUpdateOneWithoutLeadActivitiesInput>;
  lead_current_step?: InputMaybe<SequenceStepUpdateOneWithoutLeadActivityInput>;
  local_number?: InputMaybe<TwilioNumberUpdateOneWithoutLead_ActivitiesInput>;
  next_action?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  next_callback_type?: InputMaybe<NullableEnumCallbackTypeFieldUpdateOperationsInput>;
  notes?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  originating_schedule_item?: InputMaybe<ScheduleItemUpdateOneWithoutResulting_ActivityInput>;
  previous_lead_val?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  published?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  related_disposition?: InputMaybe<DispositionUpdateOneWithoutLead_ActivitiesInput>;
  resting_date_on_recycle?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  resting_type_on_recycle?: InputMaybe<NullableEnumRestingtypeFieldUpdateOperationsInput>;
  sale?: InputMaybe<SaleUpdateOneWithoutLead_ActivityInput>;
  sale_cycle?: InputMaybe<SaleCycleUpdateOneWithoutLead_ActivitiesInput>;
  schedule_item?: InputMaybe<ScheduleItemUpdateOneWithoutLead_ActivityInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutLead_ActivityInput>;
  site?: InputMaybe<SiteUpdateOneWithoutLead_ActivitiesInput>;
  skip_reason?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SMSItem?: InputMaybe<SmsItemUpdateOneWithoutLead_ActivityInput>;
  stat_item_aggregation?: InputMaybe<StatItemAggregationUpdateOneWithoutLead_ActivityInput>;
  stat_item_aggregation_pipeline?: InputMaybe<StatItemAggregationUpdateOneWithoutLead_Activity_PipelineInput>;
  StepActionLog?: InputMaybe<StepActionLogUpdateManyWithoutLeadActivityInput>;
  team?: InputMaybe<TeamUpdateOneWithoutLead_ActivitiesInput>;
  title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  twilio_action?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  type?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneWithoutLead_ActivitiesInput>;
  user_role?: InputMaybe<NullableEnumRoleFieldUpdateOperationsInput>;
};

export type LeadActivityUpdateWithoutOriginating_Schedule_ItemInput = {
  associate_child?: InputMaybe<LeadUpdateOneWithoutChild_Lead_ActivitesInput>;
  associate_group_ids?: InputMaybe<LeadActivityUpdateassociate_Group_IdsInput>;
  associate_group_leads?: InputMaybe<LeadUpdateManyWithoutAssociate_Group_Lead_ActivitiesInput>;
  associated_action?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  automated_campaign?: InputMaybe<AutomatedCampaignUpdateOneWithoutLead_ActivitiesInput>;
  call_completed_with?: InputMaybe<NullableEnumCallresultFieldUpdateOperationsInput>;
  call_result_deleted?: InputMaybe<BoolFieldUpdateOperationsInput>;
  campaign_event?: InputMaybe<ScheduleItemUpdateOneWithoutCampaign_ActivitiesInput>;
  channel?: InputMaybe<NullableEnumChannelFieldUpdateOperationsInput>;
  communication_type?: InputMaybe<NullableEnumCommunication_TypeFieldUpdateOperationsInput>;
  conference?: InputMaybe<ConferenceUpdateOneWithoutLead_ActivitiesInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  curr_lead_val?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  current_implied_action?: InputMaybe<NullableEnumHingepointFieldUpdateOperationsInput>;
  customer_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  customer_number_country_code?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  dedupe_migration?: InputMaybe<BoolFieldUpdateOperationsInput>;
  email_item?: InputMaybe<EmailItemUpdateOneWithoutLead_ActivityInput>;
  from_action?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  from_callback_type?: InputMaybe<NullableEnumCallbackTypeFieldUpdateOperationsInput>;
  from_intent_type?: InputMaybe<NullableEnumIntentTypeFieldUpdateOperationsInput>;
  html?: InputMaybe<BoolFieldUpdateOperationsInput>;
  hubspot_email_engagement_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  hubspot_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  hubspot_note_engagement_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  hubspot_owner_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  ignore_sequences?: InputMaybe<BoolFieldUpdateOperationsInput>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventUpdateManyWithoutLeadActivityInput>;
  initial_days_set_out?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  is_resting_adjustment?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lead?: InputMaybe<LeadUpdateOneRequiredWithoutLead_ActivitiesInput>;
  lead_assignment_history_change?: InputMaybe<LeadAssignmentHistoryUpdateOneWithoutLead_ActivityInput>;
  lead_current_sequence?: InputMaybe<SequenceUpdateOneWithoutLeadActivitiesInput>;
  lead_current_step?: InputMaybe<SequenceStepUpdateOneWithoutLeadActivityInput>;
  local_number?: InputMaybe<TwilioNumberUpdateOneWithoutLead_ActivitiesInput>;
  next_action?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  next_callback_type?: InputMaybe<NullableEnumCallbackTypeFieldUpdateOperationsInput>;
  notes?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneWithoutLead_ActivitiesInput>;
  previous_lead_val?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  published?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  related_disposition?: InputMaybe<DispositionUpdateOneWithoutLead_ActivitiesInput>;
  resting_date_on_recycle?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  resting_type_on_recycle?: InputMaybe<NullableEnumRestingtypeFieldUpdateOperationsInput>;
  sale?: InputMaybe<SaleUpdateOneWithoutLead_ActivityInput>;
  sale_cycle?: InputMaybe<SaleCycleUpdateOneWithoutLead_ActivitiesInput>;
  schedule_item?: InputMaybe<ScheduleItemUpdateOneWithoutLead_ActivityInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutLead_ActivityInput>;
  site?: InputMaybe<SiteUpdateOneWithoutLead_ActivitiesInput>;
  skip_reason?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SMSItem?: InputMaybe<SmsItemUpdateOneWithoutLead_ActivityInput>;
  stat_item_aggregation?: InputMaybe<StatItemAggregationUpdateOneWithoutLead_ActivityInput>;
  stat_item_aggregation_pipeline?: InputMaybe<StatItemAggregationUpdateOneWithoutLead_Activity_PipelineInput>;
  StepActionLog?: InputMaybe<StepActionLogUpdateManyWithoutLeadActivityInput>;
  team?: InputMaybe<TeamUpdateOneWithoutLead_ActivitiesInput>;
  title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  twilio_action?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  type?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneWithoutLead_ActivitiesInput>;
  user_role?: InputMaybe<NullableEnumRoleFieldUpdateOperationsInput>;
};

export type LeadActivityUpdateWithoutRelated_DispositionInput = {
  associate_child?: InputMaybe<LeadUpdateOneWithoutChild_Lead_ActivitesInput>;
  associate_group_ids?: InputMaybe<LeadActivityUpdateassociate_Group_IdsInput>;
  associate_group_leads?: InputMaybe<LeadUpdateManyWithoutAssociate_Group_Lead_ActivitiesInput>;
  associated_action?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  automated_campaign?: InputMaybe<AutomatedCampaignUpdateOneWithoutLead_ActivitiesInput>;
  call_completed_with?: InputMaybe<NullableEnumCallresultFieldUpdateOperationsInput>;
  call_result_deleted?: InputMaybe<BoolFieldUpdateOperationsInput>;
  campaign_event?: InputMaybe<ScheduleItemUpdateOneWithoutCampaign_ActivitiesInput>;
  channel?: InputMaybe<NullableEnumChannelFieldUpdateOperationsInput>;
  communication_type?: InputMaybe<NullableEnumCommunication_TypeFieldUpdateOperationsInput>;
  conference?: InputMaybe<ConferenceUpdateOneWithoutLead_ActivitiesInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  curr_lead_val?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  current_implied_action?: InputMaybe<NullableEnumHingepointFieldUpdateOperationsInput>;
  customer_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  customer_number_country_code?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  dedupe_migration?: InputMaybe<BoolFieldUpdateOperationsInput>;
  email_item?: InputMaybe<EmailItemUpdateOneWithoutLead_ActivityInput>;
  from_action?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  from_callback_type?: InputMaybe<NullableEnumCallbackTypeFieldUpdateOperationsInput>;
  from_intent_type?: InputMaybe<NullableEnumIntentTypeFieldUpdateOperationsInput>;
  html?: InputMaybe<BoolFieldUpdateOperationsInput>;
  hubspot_email_engagement_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  hubspot_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  hubspot_note_engagement_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  hubspot_owner_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  ignore_sequences?: InputMaybe<BoolFieldUpdateOperationsInput>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventUpdateManyWithoutLeadActivityInput>;
  initial_days_set_out?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  is_resting_adjustment?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lead?: InputMaybe<LeadUpdateOneRequiredWithoutLead_ActivitiesInput>;
  lead_assignment_history_change?: InputMaybe<LeadAssignmentHistoryUpdateOneWithoutLead_ActivityInput>;
  lead_current_sequence?: InputMaybe<SequenceUpdateOneWithoutLeadActivitiesInput>;
  lead_current_step?: InputMaybe<SequenceStepUpdateOneWithoutLeadActivityInput>;
  local_number?: InputMaybe<TwilioNumberUpdateOneWithoutLead_ActivitiesInput>;
  next_action?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  next_callback_type?: InputMaybe<NullableEnumCallbackTypeFieldUpdateOperationsInput>;
  notes?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneWithoutLead_ActivitiesInput>;
  originating_schedule_item?: InputMaybe<ScheduleItemUpdateOneWithoutResulting_ActivityInput>;
  previous_lead_val?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  published?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  resting_date_on_recycle?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  resting_type_on_recycle?: InputMaybe<NullableEnumRestingtypeFieldUpdateOperationsInput>;
  sale?: InputMaybe<SaleUpdateOneWithoutLead_ActivityInput>;
  sale_cycle?: InputMaybe<SaleCycleUpdateOneWithoutLead_ActivitiesInput>;
  schedule_item?: InputMaybe<ScheduleItemUpdateOneWithoutLead_ActivityInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutLead_ActivityInput>;
  site?: InputMaybe<SiteUpdateOneWithoutLead_ActivitiesInput>;
  skip_reason?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SMSItem?: InputMaybe<SmsItemUpdateOneWithoutLead_ActivityInput>;
  stat_item_aggregation?: InputMaybe<StatItemAggregationUpdateOneWithoutLead_ActivityInput>;
  stat_item_aggregation_pipeline?: InputMaybe<StatItemAggregationUpdateOneWithoutLead_Activity_PipelineInput>;
  StepActionLog?: InputMaybe<StepActionLogUpdateManyWithoutLeadActivityInput>;
  team?: InputMaybe<TeamUpdateOneWithoutLead_ActivitiesInput>;
  title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  twilio_action?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  type?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneWithoutLead_ActivitiesInput>;
  user_role?: InputMaybe<NullableEnumRoleFieldUpdateOperationsInput>;
};

export type LeadActivityUpdateWithoutSale_CycleInput = {
  associate_child?: InputMaybe<LeadUpdateOneWithoutChild_Lead_ActivitesInput>;
  associate_group_ids?: InputMaybe<LeadActivityUpdateassociate_Group_IdsInput>;
  associate_group_leads?: InputMaybe<LeadUpdateManyWithoutAssociate_Group_Lead_ActivitiesInput>;
  associated_action?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  automated_campaign?: InputMaybe<AutomatedCampaignUpdateOneWithoutLead_ActivitiesInput>;
  call_completed_with?: InputMaybe<NullableEnumCallresultFieldUpdateOperationsInput>;
  call_result_deleted?: InputMaybe<BoolFieldUpdateOperationsInput>;
  campaign_event?: InputMaybe<ScheduleItemUpdateOneWithoutCampaign_ActivitiesInput>;
  channel?: InputMaybe<NullableEnumChannelFieldUpdateOperationsInput>;
  communication_type?: InputMaybe<NullableEnumCommunication_TypeFieldUpdateOperationsInput>;
  conference?: InputMaybe<ConferenceUpdateOneWithoutLead_ActivitiesInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  curr_lead_val?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  current_implied_action?: InputMaybe<NullableEnumHingepointFieldUpdateOperationsInput>;
  customer_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  customer_number_country_code?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  dedupe_migration?: InputMaybe<BoolFieldUpdateOperationsInput>;
  email_item?: InputMaybe<EmailItemUpdateOneWithoutLead_ActivityInput>;
  from_action?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  from_callback_type?: InputMaybe<NullableEnumCallbackTypeFieldUpdateOperationsInput>;
  from_intent_type?: InputMaybe<NullableEnumIntentTypeFieldUpdateOperationsInput>;
  html?: InputMaybe<BoolFieldUpdateOperationsInput>;
  hubspot_email_engagement_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  hubspot_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  hubspot_note_engagement_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  hubspot_owner_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  ignore_sequences?: InputMaybe<BoolFieldUpdateOperationsInput>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventUpdateManyWithoutLeadActivityInput>;
  initial_days_set_out?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  is_resting_adjustment?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lead?: InputMaybe<LeadUpdateOneRequiredWithoutLead_ActivitiesInput>;
  lead_assignment_history_change?: InputMaybe<LeadAssignmentHistoryUpdateOneWithoutLead_ActivityInput>;
  lead_current_sequence?: InputMaybe<SequenceUpdateOneWithoutLeadActivitiesInput>;
  lead_current_step?: InputMaybe<SequenceStepUpdateOneWithoutLeadActivityInput>;
  local_number?: InputMaybe<TwilioNumberUpdateOneWithoutLead_ActivitiesInput>;
  next_action?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  next_callback_type?: InputMaybe<NullableEnumCallbackTypeFieldUpdateOperationsInput>;
  notes?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneWithoutLead_ActivitiesInput>;
  originating_schedule_item?: InputMaybe<ScheduleItemUpdateOneWithoutResulting_ActivityInput>;
  previous_lead_val?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  published?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  related_disposition?: InputMaybe<DispositionUpdateOneWithoutLead_ActivitiesInput>;
  resting_date_on_recycle?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  resting_type_on_recycle?: InputMaybe<NullableEnumRestingtypeFieldUpdateOperationsInput>;
  sale?: InputMaybe<SaleUpdateOneWithoutLead_ActivityInput>;
  schedule_item?: InputMaybe<ScheduleItemUpdateOneWithoutLead_ActivityInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutLead_ActivityInput>;
  site?: InputMaybe<SiteUpdateOneWithoutLead_ActivitiesInput>;
  skip_reason?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SMSItem?: InputMaybe<SmsItemUpdateOneWithoutLead_ActivityInput>;
  stat_item_aggregation?: InputMaybe<StatItemAggregationUpdateOneWithoutLead_ActivityInput>;
  stat_item_aggregation_pipeline?: InputMaybe<StatItemAggregationUpdateOneWithoutLead_Activity_PipelineInput>;
  StepActionLog?: InputMaybe<StepActionLogUpdateManyWithoutLeadActivityInput>;
  team?: InputMaybe<TeamUpdateOneWithoutLead_ActivitiesInput>;
  title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  twilio_action?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  type?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneWithoutLead_ActivitiesInput>;
  user_role?: InputMaybe<NullableEnumRoleFieldUpdateOperationsInput>;
};

export type LeadActivityUpdateWithoutSaleInput = {
  associate_child?: InputMaybe<LeadUpdateOneWithoutChild_Lead_ActivitesInput>;
  associate_group_ids?: InputMaybe<LeadActivityUpdateassociate_Group_IdsInput>;
  associate_group_leads?: InputMaybe<LeadUpdateManyWithoutAssociate_Group_Lead_ActivitiesInput>;
  associated_action?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  automated_campaign?: InputMaybe<AutomatedCampaignUpdateOneWithoutLead_ActivitiesInput>;
  call_completed_with?: InputMaybe<NullableEnumCallresultFieldUpdateOperationsInput>;
  call_result_deleted?: InputMaybe<BoolFieldUpdateOperationsInput>;
  campaign_event?: InputMaybe<ScheduleItemUpdateOneWithoutCampaign_ActivitiesInput>;
  channel?: InputMaybe<NullableEnumChannelFieldUpdateOperationsInput>;
  communication_type?: InputMaybe<NullableEnumCommunication_TypeFieldUpdateOperationsInput>;
  conference?: InputMaybe<ConferenceUpdateOneWithoutLead_ActivitiesInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  curr_lead_val?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  current_implied_action?: InputMaybe<NullableEnumHingepointFieldUpdateOperationsInput>;
  customer_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  customer_number_country_code?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  dedupe_migration?: InputMaybe<BoolFieldUpdateOperationsInput>;
  email_item?: InputMaybe<EmailItemUpdateOneWithoutLead_ActivityInput>;
  from_action?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  from_callback_type?: InputMaybe<NullableEnumCallbackTypeFieldUpdateOperationsInput>;
  from_intent_type?: InputMaybe<NullableEnumIntentTypeFieldUpdateOperationsInput>;
  html?: InputMaybe<BoolFieldUpdateOperationsInput>;
  hubspot_email_engagement_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  hubspot_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  hubspot_note_engagement_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  hubspot_owner_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  ignore_sequences?: InputMaybe<BoolFieldUpdateOperationsInput>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventUpdateManyWithoutLeadActivityInput>;
  initial_days_set_out?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  is_resting_adjustment?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lead?: InputMaybe<LeadUpdateOneRequiredWithoutLead_ActivitiesInput>;
  lead_assignment_history_change?: InputMaybe<LeadAssignmentHistoryUpdateOneWithoutLead_ActivityInput>;
  lead_current_sequence?: InputMaybe<SequenceUpdateOneWithoutLeadActivitiesInput>;
  lead_current_step?: InputMaybe<SequenceStepUpdateOneWithoutLeadActivityInput>;
  local_number?: InputMaybe<TwilioNumberUpdateOneWithoutLead_ActivitiesInput>;
  next_action?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  next_callback_type?: InputMaybe<NullableEnumCallbackTypeFieldUpdateOperationsInput>;
  notes?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneWithoutLead_ActivitiesInput>;
  originating_schedule_item?: InputMaybe<ScheduleItemUpdateOneWithoutResulting_ActivityInput>;
  previous_lead_val?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  published?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  related_disposition?: InputMaybe<DispositionUpdateOneWithoutLead_ActivitiesInput>;
  resting_date_on_recycle?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  resting_type_on_recycle?: InputMaybe<NullableEnumRestingtypeFieldUpdateOperationsInput>;
  sale_cycle?: InputMaybe<SaleCycleUpdateOneWithoutLead_ActivitiesInput>;
  schedule_item?: InputMaybe<ScheduleItemUpdateOneWithoutLead_ActivityInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutLead_ActivityInput>;
  site?: InputMaybe<SiteUpdateOneWithoutLead_ActivitiesInput>;
  skip_reason?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SMSItem?: InputMaybe<SmsItemUpdateOneWithoutLead_ActivityInput>;
  stat_item_aggregation?: InputMaybe<StatItemAggregationUpdateOneWithoutLead_ActivityInput>;
  stat_item_aggregation_pipeline?: InputMaybe<StatItemAggregationUpdateOneWithoutLead_Activity_PipelineInput>;
  StepActionLog?: InputMaybe<StepActionLogUpdateManyWithoutLeadActivityInput>;
  team?: InputMaybe<TeamUpdateOneWithoutLead_ActivitiesInput>;
  title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  twilio_action?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  type?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneWithoutLead_ActivitiesInput>;
  user_role?: InputMaybe<NullableEnumRoleFieldUpdateOperationsInput>;
};

export type LeadActivityUpdateWithoutSchedule_ItemInput = {
  associate_child?: InputMaybe<LeadUpdateOneWithoutChild_Lead_ActivitesInput>;
  associate_group_ids?: InputMaybe<LeadActivityUpdateassociate_Group_IdsInput>;
  associate_group_leads?: InputMaybe<LeadUpdateManyWithoutAssociate_Group_Lead_ActivitiesInput>;
  associated_action?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  automated_campaign?: InputMaybe<AutomatedCampaignUpdateOneWithoutLead_ActivitiesInput>;
  call_completed_with?: InputMaybe<NullableEnumCallresultFieldUpdateOperationsInput>;
  call_result_deleted?: InputMaybe<BoolFieldUpdateOperationsInput>;
  campaign_event?: InputMaybe<ScheduleItemUpdateOneWithoutCampaign_ActivitiesInput>;
  channel?: InputMaybe<NullableEnumChannelFieldUpdateOperationsInput>;
  communication_type?: InputMaybe<NullableEnumCommunication_TypeFieldUpdateOperationsInput>;
  conference?: InputMaybe<ConferenceUpdateOneWithoutLead_ActivitiesInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  curr_lead_val?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  current_implied_action?: InputMaybe<NullableEnumHingepointFieldUpdateOperationsInput>;
  customer_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  customer_number_country_code?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  dedupe_migration?: InputMaybe<BoolFieldUpdateOperationsInput>;
  email_item?: InputMaybe<EmailItemUpdateOneWithoutLead_ActivityInput>;
  from_action?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  from_callback_type?: InputMaybe<NullableEnumCallbackTypeFieldUpdateOperationsInput>;
  from_intent_type?: InputMaybe<NullableEnumIntentTypeFieldUpdateOperationsInput>;
  html?: InputMaybe<BoolFieldUpdateOperationsInput>;
  hubspot_email_engagement_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  hubspot_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  hubspot_note_engagement_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  hubspot_owner_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  ignore_sequences?: InputMaybe<BoolFieldUpdateOperationsInput>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventUpdateManyWithoutLeadActivityInput>;
  initial_days_set_out?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  is_resting_adjustment?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lead?: InputMaybe<LeadUpdateOneRequiredWithoutLead_ActivitiesInput>;
  lead_assignment_history_change?: InputMaybe<LeadAssignmentHistoryUpdateOneWithoutLead_ActivityInput>;
  lead_current_sequence?: InputMaybe<SequenceUpdateOneWithoutLeadActivitiesInput>;
  lead_current_step?: InputMaybe<SequenceStepUpdateOneWithoutLeadActivityInput>;
  local_number?: InputMaybe<TwilioNumberUpdateOneWithoutLead_ActivitiesInput>;
  next_action?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  next_callback_type?: InputMaybe<NullableEnumCallbackTypeFieldUpdateOperationsInput>;
  notes?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneWithoutLead_ActivitiesInput>;
  originating_schedule_item?: InputMaybe<ScheduleItemUpdateOneWithoutResulting_ActivityInput>;
  previous_lead_val?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  published?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  related_disposition?: InputMaybe<DispositionUpdateOneWithoutLead_ActivitiesInput>;
  resting_date_on_recycle?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  resting_type_on_recycle?: InputMaybe<NullableEnumRestingtypeFieldUpdateOperationsInput>;
  sale?: InputMaybe<SaleUpdateOneWithoutLead_ActivityInput>;
  sale_cycle?: InputMaybe<SaleCycleUpdateOneWithoutLead_ActivitiesInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutLead_ActivityInput>;
  site?: InputMaybe<SiteUpdateOneWithoutLead_ActivitiesInput>;
  skip_reason?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SMSItem?: InputMaybe<SmsItemUpdateOneWithoutLead_ActivityInput>;
  stat_item_aggregation?: InputMaybe<StatItemAggregationUpdateOneWithoutLead_ActivityInput>;
  stat_item_aggregation_pipeline?: InputMaybe<StatItemAggregationUpdateOneWithoutLead_Activity_PipelineInput>;
  StepActionLog?: InputMaybe<StepActionLogUpdateManyWithoutLeadActivityInput>;
  team?: InputMaybe<TeamUpdateOneWithoutLead_ActivitiesInput>;
  title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  twilio_action?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  type?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneWithoutLead_ActivitiesInput>;
  user_role?: InputMaybe<NullableEnumRoleFieldUpdateOperationsInput>;
};

export type LeadActivityUpdateWithoutSequenceEntryExitLeadInput = {
  associate_child?: InputMaybe<LeadUpdateOneWithoutChild_Lead_ActivitesInput>;
  associate_group_ids?: InputMaybe<LeadActivityUpdateassociate_Group_IdsInput>;
  associate_group_leads?: InputMaybe<LeadUpdateManyWithoutAssociate_Group_Lead_ActivitiesInput>;
  associated_action?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  automated_campaign?: InputMaybe<AutomatedCampaignUpdateOneWithoutLead_ActivitiesInput>;
  call_completed_with?: InputMaybe<NullableEnumCallresultFieldUpdateOperationsInput>;
  call_result_deleted?: InputMaybe<BoolFieldUpdateOperationsInput>;
  campaign_event?: InputMaybe<ScheduleItemUpdateOneWithoutCampaign_ActivitiesInput>;
  channel?: InputMaybe<NullableEnumChannelFieldUpdateOperationsInput>;
  communication_type?: InputMaybe<NullableEnumCommunication_TypeFieldUpdateOperationsInput>;
  conference?: InputMaybe<ConferenceUpdateOneWithoutLead_ActivitiesInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  curr_lead_val?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  current_implied_action?: InputMaybe<NullableEnumHingepointFieldUpdateOperationsInput>;
  customer_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  customer_number_country_code?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  dedupe_migration?: InputMaybe<BoolFieldUpdateOperationsInput>;
  email_item?: InputMaybe<EmailItemUpdateOneWithoutLead_ActivityInput>;
  from_action?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  from_callback_type?: InputMaybe<NullableEnumCallbackTypeFieldUpdateOperationsInput>;
  from_intent_type?: InputMaybe<NullableEnumIntentTypeFieldUpdateOperationsInput>;
  html?: InputMaybe<BoolFieldUpdateOperationsInput>;
  hubspot_email_engagement_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  hubspot_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  hubspot_note_engagement_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  hubspot_owner_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  ignore_sequences?: InputMaybe<BoolFieldUpdateOperationsInput>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventUpdateManyWithoutLeadActivityInput>;
  initial_days_set_out?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  is_resting_adjustment?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lead?: InputMaybe<LeadUpdateOneRequiredWithoutLead_ActivitiesInput>;
  lead_assignment_history_change?: InputMaybe<LeadAssignmentHistoryUpdateOneWithoutLead_ActivityInput>;
  lead_current_sequence?: InputMaybe<SequenceUpdateOneWithoutLeadActivitiesInput>;
  lead_current_step?: InputMaybe<SequenceStepUpdateOneWithoutLeadActivityInput>;
  local_number?: InputMaybe<TwilioNumberUpdateOneWithoutLead_ActivitiesInput>;
  next_action?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  next_callback_type?: InputMaybe<NullableEnumCallbackTypeFieldUpdateOperationsInput>;
  notes?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneWithoutLead_ActivitiesInput>;
  originating_schedule_item?: InputMaybe<ScheduleItemUpdateOneWithoutResulting_ActivityInput>;
  previous_lead_val?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  published?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  related_disposition?: InputMaybe<DispositionUpdateOneWithoutLead_ActivitiesInput>;
  resting_date_on_recycle?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  resting_type_on_recycle?: InputMaybe<NullableEnumRestingtypeFieldUpdateOperationsInput>;
  sale?: InputMaybe<SaleUpdateOneWithoutLead_ActivityInput>;
  sale_cycle?: InputMaybe<SaleCycleUpdateOneWithoutLead_ActivitiesInput>;
  schedule_item?: InputMaybe<ScheduleItemUpdateOneWithoutLead_ActivityInput>;
  site?: InputMaybe<SiteUpdateOneWithoutLead_ActivitiesInput>;
  skip_reason?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SMSItem?: InputMaybe<SmsItemUpdateOneWithoutLead_ActivityInput>;
  stat_item_aggregation?: InputMaybe<StatItemAggregationUpdateOneWithoutLead_ActivityInput>;
  stat_item_aggregation_pipeline?: InputMaybe<StatItemAggregationUpdateOneWithoutLead_Activity_PipelineInput>;
  StepActionLog?: InputMaybe<StepActionLogUpdateManyWithoutLeadActivityInput>;
  team?: InputMaybe<TeamUpdateOneWithoutLead_ActivitiesInput>;
  title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  twilio_action?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  type?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneWithoutLead_ActivitiesInput>;
  user_role?: InputMaybe<NullableEnumRoleFieldUpdateOperationsInput>;
};

export type LeadActivityUpdateWithoutSiteInput = {
  associate_child?: InputMaybe<LeadUpdateOneWithoutChild_Lead_ActivitesInput>;
  associate_group_ids?: InputMaybe<LeadActivityUpdateassociate_Group_IdsInput>;
  associate_group_leads?: InputMaybe<LeadUpdateManyWithoutAssociate_Group_Lead_ActivitiesInput>;
  associated_action?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  automated_campaign?: InputMaybe<AutomatedCampaignUpdateOneWithoutLead_ActivitiesInput>;
  call_completed_with?: InputMaybe<NullableEnumCallresultFieldUpdateOperationsInput>;
  call_result_deleted?: InputMaybe<BoolFieldUpdateOperationsInput>;
  campaign_event?: InputMaybe<ScheduleItemUpdateOneWithoutCampaign_ActivitiesInput>;
  channel?: InputMaybe<NullableEnumChannelFieldUpdateOperationsInput>;
  communication_type?: InputMaybe<NullableEnumCommunication_TypeFieldUpdateOperationsInput>;
  conference?: InputMaybe<ConferenceUpdateOneWithoutLead_ActivitiesInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  curr_lead_val?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  current_implied_action?: InputMaybe<NullableEnumHingepointFieldUpdateOperationsInput>;
  customer_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  customer_number_country_code?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  dedupe_migration?: InputMaybe<BoolFieldUpdateOperationsInput>;
  email_item?: InputMaybe<EmailItemUpdateOneWithoutLead_ActivityInput>;
  from_action?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  from_callback_type?: InputMaybe<NullableEnumCallbackTypeFieldUpdateOperationsInput>;
  from_intent_type?: InputMaybe<NullableEnumIntentTypeFieldUpdateOperationsInput>;
  html?: InputMaybe<BoolFieldUpdateOperationsInput>;
  hubspot_email_engagement_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  hubspot_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  hubspot_note_engagement_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  hubspot_owner_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  ignore_sequences?: InputMaybe<BoolFieldUpdateOperationsInput>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventUpdateManyWithoutLeadActivityInput>;
  initial_days_set_out?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  is_resting_adjustment?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lead?: InputMaybe<LeadUpdateOneRequiredWithoutLead_ActivitiesInput>;
  lead_assignment_history_change?: InputMaybe<LeadAssignmentHistoryUpdateOneWithoutLead_ActivityInput>;
  lead_current_sequence?: InputMaybe<SequenceUpdateOneWithoutLeadActivitiesInput>;
  lead_current_step?: InputMaybe<SequenceStepUpdateOneWithoutLeadActivityInput>;
  local_number?: InputMaybe<TwilioNumberUpdateOneWithoutLead_ActivitiesInput>;
  next_action?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  next_callback_type?: InputMaybe<NullableEnumCallbackTypeFieldUpdateOperationsInput>;
  notes?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneWithoutLead_ActivitiesInput>;
  originating_schedule_item?: InputMaybe<ScheduleItemUpdateOneWithoutResulting_ActivityInput>;
  previous_lead_val?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  published?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  related_disposition?: InputMaybe<DispositionUpdateOneWithoutLead_ActivitiesInput>;
  resting_date_on_recycle?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  resting_type_on_recycle?: InputMaybe<NullableEnumRestingtypeFieldUpdateOperationsInput>;
  sale?: InputMaybe<SaleUpdateOneWithoutLead_ActivityInput>;
  sale_cycle?: InputMaybe<SaleCycleUpdateOneWithoutLead_ActivitiesInput>;
  schedule_item?: InputMaybe<ScheduleItemUpdateOneWithoutLead_ActivityInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutLead_ActivityInput>;
  skip_reason?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SMSItem?: InputMaybe<SmsItemUpdateOneWithoutLead_ActivityInput>;
  stat_item_aggregation?: InputMaybe<StatItemAggregationUpdateOneWithoutLead_ActivityInput>;
  stat_item_aggregation_pipeline?: InputMaybe<StatItemAggregationUpdateOneWithoutLead_Activity_PipelineInput>;
  StepActionLog?: InputMaybe<StepActionLogUpdateManyWithoutLeadActivityInput>;
  team?: InputMaybe<TeamUpdateOneWithoutLead_ActivitiesInput>;
  title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  twilio_action?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  type?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneWithoutLead_ActivitiesInput>;
  user_role?: InputMaybe<NullableEnumRoleFieldUpdateOperationsInput>;
};

export type LeadActivityUpdateWithoutSmsItemInput = {
  associate_child?: InputMaybe<LeadUpdateOneWithoutChild_Lead_ActivitesInput>;
  associate_group_ids?: InputMaybe<LeadActivityUpdateassociate_Group_IdsInput>;
  associate_group_leads?: InputMaybe<LeadUpdateManyWithoutAssociate_Group_Lead_ActivitiesInput>;
  associated_action?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  automated_campaign?: InputMaybe<AutomatedCampaignUpdateOneWithoutLead_ActivitiesInput>;
  call_completed_with?: InputMaybe<NullableEnumCallresultFieldUpdateOperationsInput>;
  call_result_deleted?: InputMaybe<BoolFieldUpdateOperationsInput>;
  campaign_event?: InputMaybe<ScheduleItemUpdateOneWithoutCampaign_ActivitiesInput>;
  channel?: InputMaybe<NullableEnumChannelFieldUpdateOperationsInput>;
  communication_type?: InputMaybe<NullableEnumCommunication_TypeFieldUpdateOperationsInput>;
  conference?: InputMaybe<ConferenceUpdateOneWithoutLead_ActivitiesInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  curr_lead_val?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  current_implied_action?: InputMaybe<NullableEnumHingepointFieldUpdateOperationsInput>;
  customer_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  customer_number_country_code?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  dedupe_migration?: InputMaybe<BoolFieldUpdateOperationsInput>;
  email_item?: InputMaybe<EmailItemUpdateOneWithoutLead_ActivityInput>;
  from_action?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  from_callback_type?: InputMaybe<NullableEnumCallbackTypeFieldUpdateOperationsInput>;
  from_intent_type?: InputMaybe<NullableEnumIntentTypeFieldUpdateOperationsInput>;
  html?: InputMaybe<BoolFieldUpdateOperationsInput>;
  hubspot_email_engagement_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  hubspot_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  hubspot_note_engagement_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  hubspot_owner_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  ignore_sequences?: InputMaybe<BoolFieldUpdateOperationsInput>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventUpdateManyWithoutLeadActivityInput>;
  initial_days_set_out?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  is_resting_adjustment?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lead?: InputMaybe<LeadUpdateOneRequiredWithoutLead_ActivitiesInput>;
  lead_assignment_history_change?: InputMaybe<LeadAssignmentHistoryUpdateOneWithoutLead_ActivityInput>;
  lead_current_sequence?: InputMaybe<SequenceUpdateOneWithoutLeadActivitiesInput>;
  lead_current_step?: InputMaybe<SequenceStepUpdateOneWithoutLeadActivityInput>;
  local_number?: InputMaybe<TwilioNumberUpdateOneWithoutLead_ActivitiesInput>;
  next_action?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  next_callback_type?: InputMaybe<NullableEnumCallbackTypeFieldUpdateOperationsInput>;
  notes?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneWithoutLead_ActivitiesInput>;
  originating_schedule_item?: InputMaybe<ScheduleItemUpdateOneWithoutResulting_ActivityInput>;
  previous_lead_val?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  published?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  related_disposition?: InputMaybe<DispositionUpdateOneWithoutLead_ActivitiesInput>;
  resting_date_on_recycle?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  resting_type_on_recycle?: InputMaybe<NullableEnumRestingtypeFieldUpdateOperationsInput>;
  sale?: InputMaybe<SaleUpdateOneWithoutLead_ActivityInput>;
  sale_cycle?: InputMaybe<SaleCycleUpdateOneWithoutLead_ActivitiesInput>;
  schedule_item?: InputMaybe<ScheduleItemUpdateOneWithoutLead_ActivityInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutLead_ActivityInput>;
  site?: InputMaybe<SiteUpdateOneWithoutLead_ActivitiesInput>;
  skip_reason?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  stat_item_aggregation?: InputMaybe<StatItemAggregationUpdateOneWithoutLead_ActivityInput>;
  stat_item_aggregation_pipeline?: InputMaybe<StatItemAggregationUpdateOneWithoutLead_Activity_PipelineInput>;
  StepActionLog?: InputMaybe<StepActionLogUpdateManyWithoutLeadActivityInput>;
  team?: InputMaybe<TeamUpdateOneWithoutLead_ActivitiesInput>;
  title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  twilio_action?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  type?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneWithoutLead_ActivitiesInput>;
  user_role?: InputMaybe<NullableEnumRoleFieldUpdateOperationsInput>;
};

export type LeadActivityUpdateWithoutStat_Item_Aggregation_PipelineInput = {
  associate_child?: InputMaybe<LeadUpdateOneWithoutChild_Lead_ActivitesInput>;
  associate_group_ids?: InputMaybe<LeadActivityUpdateassociate_Group_IdsInput>;
  associate_group_leads?: InputMaybe<LeadUpdateManyWithoutAssociate_Group_Lead_ActivitiesInput>;
  associated_action?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  automated_campaign?: InputMaybe<AutomatedCampaignUpdateOneWithoutLead_ActivitiesInput>;
  call_completed_with?: InputMaybe<NullableEnumCallresultFieldUpdateOperationsInput>;
  call_result_deleted?: InputMaybe<BoolFieldUpdateOperationsInput>;
  campaign_event?: InputMaybe<ScheduleItemUpdateOneWithoutCampaign_ActivitiesInput>;
  channel?: InputMaybe<NullableEnumChannelFieldUpdateOperationsInput>;
  communication_type?: InputMaybe<NullableEnumCommunication_TypeFieldUpdateOperationsInput>;
  conference?: InputMaybe<ConferenceUpdateOneWithoutLead_ActivitiesInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  curr_lead_val?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  current_implied_action?: InputMaybe<NullableEnumHingepointFieldUpdateOperationsInput>;
  customer_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  customer_number_country_code?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  dedupe_migration?: InputMaybe<BoolFieldUpdateOperationsInput>;
  email_item?: InputMaybe<EmailItemUpdateOneWithoutLead_ActivityInput>;
  from_action?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  from_callback_type?: InputMaybe<NullableEnumCallbackTypeFieldUpdateOperationsInput>;
  from_intent_type?: InputMaybe<NullableEnumIntentTypeFieldUpdateOperationsInput>;
  html?: InputMaybe<BoolFieldUpdateOperationsInput>;
  hubspot_email_engagement_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  hubspot_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  hubspot_note_engagement_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  hubspot_owner_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  ignore_sequences?: InputMaybe<BoolFieldUpdateOperationsInput>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventUpdateManyWithoutLeadActivityInput>;
  initial_days_set_out?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  is_resting_adjustment?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lead?: InputMaybe<LeadUpdateOneRequiredWithoutLead_ActivitiesInput>;
  lead_assignment_history_change?: InputMaybe<LeadAssignmentHistoryUpdateOneWithoutLead_ActivityInput>;
  lead_current_sequence?: InputMaybe<SequenceUpdateOneWithoutLeadActivitiesInput>;
  lead_current_step?: InputMaybe<SequenceStepUpdateOneWithoutLeadActivityInput>;
  local_number?: InputMaybe<TwilioNumberUpdateOneWithoutLead_ActivitiesInput>;
  next_action?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  next_callback_type?: InputMaybe<NullableEnumCallbackTypeFieldUpdateOperationsInput>;
  notes?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneWithoutLead_ActivitiesInput>;
  originating_schedule_item?: InputMaybe<ScheduleItemUpdateOneWithoutResulting_ActivityInput>;
  previous_lead_val?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  published?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  related_disposition?: InputMaybe<DispositionUpdateOneWithoutLead_ActivitiesInput>;
  resting_date_on_recycle?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  resting_type_on_recycle?: InputMaybe<NullableEnumRestingtypeFieldUpdateOperationsInput>;
  sale?: InputMaybe<SaleUpdateOneWithoutLead_ActivityInput>;
  sale_cycle?: InputMaybe<SaleCycleUpdateOneWithoutLead_ActivitiesInput>;
  schedule_item?: InputMaybe<ScheduleItemUpdateOneWithoutLead_ActivityInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutLead_ActivityInput>;
  site?: InputMaybe<SiteUpdateOneWithoutLead_ActivitiesInput>;
  skip_reason?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SMSItem?: InputMaybe<SmsItemUpdateOneWithoutLead_ActivityInput>;
  stat_item_aggregation?: InputMaybe<StatItemAggregationUpdateOneWithoutLead_ActivityInput>;
  StepActionLog?: InputMaybe<StepActionLogUpdateManyWithoutLeadActivityInput>;
  team?: InputMaybe<TeamUpdateOneWithoutLead_ActivitiesInput>;
  title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  twilio_action?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  type?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneWithoutLead_ActivitiesInput>;
  user_role?: InputMaybe<NullableEnumRoleFieldUpdateOperationsInput>;
};

export type LeadActivityUpdateWithoutStat_Item_AggregationInput = {
  associate_child?: InputMaybe<LeadUpdateOneWithoutChild_Lead_ActivitesInput>;
  associate_group_ids?: InputMaybe<LeadActivityUpdateassociate_Group_IdsInput>;
  associate_group_leads?: InputMaybe<LeadUpdateManyWithoutAssociate_Group_Lead_ActivitiesInput>;
  associated_action?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  automated_campaign?: InputMaybe<AutomatedCampaignUpdateOneWithoutLead_ActivitiesInput>;
  call_completed_with?: InputMaybe<NullableEnumCallresultFieldUpdateOperationsInput>;
  call_result_deleted?: InputMaybe<BoolFieldUpdateOperationsInput>;
  campaign_event?: InputMaybe<ScheduleItemUpdateOneWithoutCampaign_ActivitiesInput>;
  channel?: InputMaybe<NullableEnumChannelFieldUpdateOperationsInput>;
  communication_type?: InputMaybe<NullableEnumCommunication_TypeFieldUpdateOperationsInput>;
  conference?: InputMaybe<ConferenceUpdateOneWithoutLead_ActivitiesInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  curr_lead_val?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  current_implied_action?: InputMaybe<NullableEnumHingepointFieldUpdateOperationsInput>;
  customer_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  customer_number_country_code?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  dedupe_migration?: InputMaybe<BoolFieldUpdateOperationsInput>;
  email_item?: InputMaybe<EmailItemUpdateOneWithoutLead_ActivityInput>;
  from_action?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  from_callback_type?: InputMaybe<NullableEnumCallbackTypeFieldUpdateOperationsInput>;
  from_intent_type?: InputMaybe<NullableEnumIntentTypeFieldUpdateOperationsInput>;
  html?: InputMaybe<BoolFieldUpdateOperationsInput>;
  hubspot_email_engagement_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  hubspot_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  hubspot_note_engagement_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  hubspot_owner_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  ignore_sequences?: InputMaybe<BoolFieldUpdateOperationsInput>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventUpdateManyWithoutLeadActivityInput>;
  initial_days_set_out?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  is_resting_adjustment?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lead?: InputMaybe<LeadUpdateOneRequiredWithoutLead_ActivitiesInput>;
  lead_assignment_history_change?: InputMaybe<LeadAssignmentHistoryUpdateOneWithoutLead_ActivityInput>;
  lead_current_sequence?: InputMaybe<SequenceUpdateOneWithoutLeadActivitiesInput>;
  lead_current_step?: InputMaybe<SequenceStepUpdateOneWithoutLeadActivityInput>;
  local_number?: InputMaybe<TwilioNumberUpdateOneWithoutLead_ActivitiesInput>;
  next_action?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  next_callback_type?: InputMaybe<NullableEnumCallbackTypeFieldUpdateOperationsInput>;
  notes?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneWithoutLead_ActivitiesInput>;
  originating_schedule_item?: InputMaybe<ScheduleItemUpdateOneWithoutResulting_ActivityInput>;
  previous_lead_val?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  published?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  related_disposition?: InputMaybe<DispositionUpdateOneWithoutLead_ActivitiesInput>;
  resting_date_on_recycle?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  resting_type_on_recycle?: InputMaybe<NullableEnumRestingtypeFieldUpdateOperationsInput>;
  sale?: InputMaybe<SaleUpdateOneWithoutLead_ActivityInput>;
  sale_cycle?: InputMaybe<SaleCycleUpdateOneWithoutLead_ActivitiesInput>;
  schedule_item?: InputMaybe<ScheduleItemUpdateOneWithoutLead_ActivityInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutLead_ActivityInput>;
  site?: InputMaybe<SiteUpdateOneWithoutLead_ActivitiesInput>;
  skip_reason?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SMSItem?: InputMaybe<SmsItemUpdateOneWithoutLead_ActivityInput>;
  stat_item_aggregation_pipeline?: InputMaybe<StatItemAggregationUpdateOneWithoutLead_Activity_PipelineInput>;
  StepActionLog?: InputMaybe<StepActionLogUpdateManyWithoutLeadActivityInput>;
  team?: InputMaybe<TeamUpdateOneWithoutLead_ActivitiesInput>;
  title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  twilio_action?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  type?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneWithoutLead_ActivitiesInput>;
  user_role?: InputMaybe<NullableEnumRoleFieldUpdateOperationsInput>;
};

export type LeadActivityUpdateWithoutStepActionLogInput = {
  associate_child?: InputMaybe<LeadUpdateOneWithoutChild_Lead_ActivitesInput>;
  associate_group_ids?: InputMaybe<LeadActivityUpdateassociate_Group_IdsInput>;
  associate_group_leads?: InputMaybe<LeadUpdateManyWithoutAssociate_Group_Lead_ActivitiesInput>;
  associated_action?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  automated_campaign?: InputMaybe<AutomatedCampaignUpdateOneWithoutLead_ActivitiesInput>;
  call_completed_with?: InputMaybe<NullableEnumCallresultFieldUpdateOperationsInput>;
  call_result_deleted?: InputMaybe<BoolFieldUpdateOperationsInput>;
  campaign_event?: InputMaybe<ScheduleItemUpdateOneWithoutCampaign_ActivitiesInput>;
  channel?: InputMaybe<NullableEnumChannelFieldUpdateOperationsInput>;
  communication_type?: InputMaybe<NullableEnumCommunication_TypeFieldUpdateOperationsInput>;
  conference?: InputMaybe<ConferenceUpdateOneWithoutLead_ActivitiesInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  curr_lead_val?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  current_implied_action?: InputMaybe<NullableEnumHingepointFieldUpdateOperationsInput>;
  customer_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  customer_number_country_code?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  dedupe_migration?: InputMaybe<BoolFieldUpdateOperationsInput>;
  email_item?: InputMaybe<EmailItemUpdateOneWithoutLead_ActivityInput>;
  from_action?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  from_callback_type?: InputMaybe<NullableEnumCallbackTypeFieldUpdateOperationsInput>;
  from_intent_type?: InputMaybe<NullableEnumIntentTypeFieldUpdateOperationsInput>;
  html?: InputMaybe<BoolFieldUpdateOperationsInput>;
  hubspot_email_engagement_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  hubspot_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  hubspot_note_engagement_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  hubspot_owner_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  ignore_sequences?: InputMaybe<BoolFieldUpdateOperationsInput>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventUpdateManyWithoutLeadActivityInput>;
  initial_days_set_out?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  is_resting_adjustment?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lead?: InputMaybe<LeadUpdateOneRequiredWithoutLead_ActivitiesInput>;
  lead_assignment_history_change?: InputMaybe<LeadAssignmentHistoryUpdateOneWithoutLead_ActivityInput>;
  lead_current_sequence?: InputMaybe<SequenceUpdateOneWithoutLeadActivitiesInput>;
  lead_current_step?: InputMaybe<SequenceStepUpdateOneWithoutLeadActivityInput>;
  local_number?: InputMaybe<TwilioNumberUpdateOneWithoutLead_ActivitiesInput>;
  next_action?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  next_callback_type?: InputMaybe<NullableEnumCallbackTypeFieldUpdateOperationsInput>;
  notes?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneWithoutLead_ActivitiesInput>;
  originating_schedule_item?: InputMaybe<ScheduleItemUpdateOneWithoutResulting_ActivityInput>;
  previous_lead_val?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  published?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  related_disposition?: InputMaybe<DispositionUpdateOneWithoutLead_ActivitiesInput>;
  resting_date_on_recycle?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  resting_type_on_recycle?: InputMaybe<NullableEnumRestingtypeFieldUpdateOperationsInput>;
  sale?: InputMaybe<SaleUpdateOneWithoutLead_ActivityInput>;
  sale_cycle?: InputMaybe<SaleCycleUpdateOneWithoutLead_ActivitiesInput>;
  schedule_item?: InputMaybe<ScheduleItemUpdateOneWithoutLead_ActivityInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutLead_ActivityInput>;
  site?: InputMaybe<SiteUpdateOneWithoutLead_ActivitiesInput>;
  skip_reason?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SMSItem?: InputMaybe<SmsItemUpdateOneWithoutLead_ActivityInput>;
  stat_item_aggregation?: InputMaybe<StatItemAggregationUpdateOneWithoutLead_ActivityInput>;
  stat_item_aggregation_pipeline?: InputMaybe<StatItemAggregationUpdateOneWithoutLead_Activity_PipelineInput>;
  team?: InputMaybe<TeamUpdateOneWithoutLead_ActivitiesInput>;
  title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  twilio_action?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  type?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneWithoutLead_ActivitiesInput>;
  user_role?: InputMaybe<NullableEnumRoleFieldUpdateOperationsInput>;
};

export type LeadActivityUpdateWithoutTeamInput = {
  associate_child?: InputMaybe<LeadUpdateOneWithoutChild_Lead_ActivitesInput>;
  associate_group_ids?: InputMaybe<LeadActivityUpdateassociate_Group_IdsInput>;
  associate_group_leads?: InputMaybe<LeadUpdateManyWithoutAssociate_Group_Lead_ActivitiesInput>;
  associated_action?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  automated_campaign?: InputMaybe<AutomatedCampaignUpdateOneWithoutLead_ActivitiesInput>;
  call_completed_with?: InputMaybe<NullableEnumCallresultFieldUpdateOperationsInput>;
  call_result_deleted?: InputMaybe<BoolFieldUpdateOperationsInput>;
  campaign_event?: InputMaybe<ScheduleItemUpdateOneWithoutCampaign_ActivitiesInput>;
  channel?: InputMaybe<NullableEnumChannelFieldUpdateOperationsInput>;
  communication_type?: InputMaybe<NullableEnumCommunication_TypeFieldUpdateOperationsInput>;
  conference?: InputMaybe<ConferenceUpdateOneWithoutLead_ActivitiesInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  curr_lead_val?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  current_implied_action?: InputMaybe<NullableEnumHingepointFieldUpdateOperationsInput>;
  customer_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  customer_number_country_code?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  dedupe_migration?: InputMaybe<BoolFieldUpdateOperationsInput>;
  email_item?: InputMaybe<EmailItemUpdateOneWithoutLead_ActivityInput>;
  from_action?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  from_callback_type?: InputMaybe<NullableEnumCallbackTypeFieldUpdateOperationsInput>;
  from_intent_type?: InputMaybe<NullableEnumIntentTypeFieldUpdateOperationsInput>;
  html?: InputMaybe<BoolFieldUpdateOperationsInput>;
  hubspot_email_engagement_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  hubspot_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  hubspot_note_engagement_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  hubspot_owner_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  ignore_sequences?: InputMaybe<BoolFieldUpdateOperationsInput>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventUpdateManyWithoutLeadActivityInput>;
  initial_days_set_out?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  is_resting_adjustment?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lead?: InputMaybe<LeadUpdateOneRequiredWithoutLead_ActivitiesInput>;
  lead_assignment_history_change?: InputMaybe<LeadAssignmentHistoryUpdateOneWithoutLead_ActivityInput>;
  lead_current_sequence?: InputMaybe<SequenceUpdateOneWithoutLeadActivitiesInput>;
  lead_current_step?: InputMaybe<SequenceStepUpdateOneWithoutLeadActivityInput>;
  local_number?: InputMaybe<TwilioNumberUpdateOneWithoutLead_ActivitiesInput>;
  next_action?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  next_callback_type?: InputMaybe<NullableEnumCallbackTypeFieldUpdateOperationsInput>;
  notes?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneWithoutLead_ActivitiesInput>;
  originating_schedule_item?: InputMaybe<ScheduleItemUpdateOneWithoutResulting_ActivityInput>;
  previous_lead_val?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  published?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  related_disposition?: InputMaybe<DispositionUpdateOneWithoutLead_ActivitiesInput>;
  resting_date_on_recycle?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  resting_type_on_recycle?: InputMaybe<NullableEnumRestingtypeFieldUpdateOperationsInput>;
  sale?: InputMaybe<SaleUpdateOneWithoutLead_ActivityInput>;
  sale_cycle?: InputMaybe<SaleCycleUpdateOneWithoutLead_ActivitiesInput>;
  schedule_item?: InputMaybe<ScheduleItemUpdateOneWithoutLead_ActivityInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutLead_ActivityInput>;
  site?: InputMaybe<SiteUpdateOneWithoutLead_ActivitiesInput>;
  skip_reason?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SMSItem?: InputMaybe<SmsItemUpdateOneWithoutLead_ActivityInput>;
  stat_item_aggregation?: InputMaybe<StatItemAggregationUpdateOneWithoutLead_ActivityInput>;
  stat_item_aggregation_pipeline?: InputMaybe<StatItemAggregationUpdateOneWithoutLead_Activity_PipelineInput>;
  StepActionLog?: InputMaybe<StepActionLogUpdateManyWithoutLeadActivityInput>;
  title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  twilio_action?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  type?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneWithoutLead_ActivitiesInput>;
  user_role?: InputMaybe<NullableEnumRoleFieldUpdateOperationsInput>;
};

export type LeadActivityUpdateWithoutUserInput = {
  associate_child?: InputMaybe<LeadUpdateOneWithoutChild_Lead_ActivitesInput>;
  associate_group_ids?: InputMaybe<LeadActivityUpdateassociate_Group_IdsInput>;
  associate_group_leads?: InputMaybe<LeadUpdateManyWithoutAssociate_Group_Lead_ActivitiesInput>;
  associated_action?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  automated_campaign?: InputMaybe<AutomatedCampaignUpdateOneWithoutLead_ActivitiesInput>;
  call_completed_with?: InputMaybe<NullableEnumCallresultFieldUpdateOperationsInput>;
  call_result_deleted?: InputMaybe<BoolFieldUpdateOperationsInput>;
  campaign_event?: InputMaybe<ScheduleItemUpdateOneWithoutCampaign_ActivitiesInput>;
  channel?: InputMaybe<NullableEnumChannelFieldUpdateOperationsInput>;
  communication_type?: InputMaybe<NullableEnumCommunication_TypeFieldUpdateOperationsInput>;
  conference?: InputMaybe<ConferenceUpdateOneWithoutLead_ActivitiesInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  curr_lead_val?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  current_implied_action?: InputMaybe<NullableEnumHingepointFieldUpdateOperationsInput>;
  customer_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  customer_number_country_code?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  dedupe_migration?: InputMaybe<BoolFieldUpdateOperationsInput>;
  email_item?: InputMaybe<EmailItemUpdateOneWithoutLead_ActivityInput>;
  from_action?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  from_callback_type?: InputMaybe<NullableEnumCallbackTypeFieldUpdateOperationsInput>;
  from_intent_type?: InputMaybe<NullableEnumIntentTypeFieldUpdateOperationsInput>;
  html?: InputMaybe<BoolFieldUpdateOperationsInput>;
  hubspot_email_engagement_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  hubspot_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  hubspot_note_engagement_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  hubspot_owner_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  ignore_sequences?: InputMaybe<BoolFieldUpdateOperationsInput>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventUpdateManyWithoutLeadActivityInput>;
  initial_days_set_out?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  is_resting_adjustment?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lead?: InputMaybe<LeadUpdateOneRequiredWithoutLead_ActivitiesInput>;
  lead_assignment_history_change?: InputMaybe<LeadAssignmentHistoryUpdateOneWithoutLead_ActivityInput>;
  lead_current_sequence?: InputMaybe<SequenceUpdateOneWithoutLeadActivitiesInput>;
  lead_current_step?: InputMaybe<SequenceStepUpdateOneWithoutLeadActivityInput>;
  local_number?: InputMaybe<TwilioNumberUpdateOneWithoutLead_ActivitiesInput>;
  next_action?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  next_callback_type?: InputMaybe<NullableEnumCallbackTypeFieldUpdateOperationsInput>;
  notes?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneWithoutLead_ActivitiesInput>;
  originating_schedule_item?: InputMaybe<ScheduleItemUpdateOneWithoutResulting_ActivityInput>;
  previous_lead_val?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  published?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  related_disposition?: InputMaybe<DispositionUpdateOneWithoutLead_ActivitiesInput>;
  resting_date_on_recycle?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  resting_type_on_recycle?: InputMaybe<NullableEnumRestingtypeFieldUpdateOperationsInput>;
  sale?: InputMaybe<SaleUpdateOneWithoutLead_ActivityInput>;
  sale_cycle?: InputMaybe<SaleCycleUpdateOneWithoutLead_ActivitiesInput>;
  schedule_item?: InputMaybe<ScheduleItemUpdateOneWithoutLead_ActivityInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutLead_ActivityInput>;
  site?: InputMaybe<SiteUpdateOneWithoutLead_ActivitiesInput>;
  skip_reason?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SMSItem?: InputMaybe<SmsItemUpdateOneWithoutLead_ActivityInput>;
  stat_item_aggregation?: InputMaybe<StatItemAggregationUpdateOneWithoutLead_ActivityInput>;
  stat_item_aggregation_pipeline?: InputMaybe<StatItemAggregationUpdateOneWithoutLead_Activity_PipelineInput>;
  StepActionLog?: InputMaybe<StepActionLogUpdateManyWithoutLeadActivityInput>;
  team?: InputMaybe<TeamUpdateOneWithoutLead_ActivitiesInput>;
  title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  twilio_action?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  type?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user_role?: InputMaybe<NullableEnumRoleFieldUpdateOperationsInput>;
};

export type LeadActivityUpdateWithWhereUniqueWithoutAssociate_ChildInput = {
  data: LeadActivityUpdateWithoutAssociate_ChildInput;
  where: LeadActivityWhereUniqueInput;
};

export type LeadActivityUpdateWithWhereUniqueWithoutAssociate_Group_LeadsInput = {
  data: LeadActivityUpdateWithoutAssociate_Group_LeadsInput;
  where: LeadActivityWhereUniqueInput;
};

export type LeadActivityUpdateWithWhereUniqueWithoutAutomated_CampaignInput = {
  data: LeadActivityUpdateWithoutAutomated_CampaignInput;
  where: LeadActivityWhereUniqueInput;
};

export type LeadActivityUpdateWithWhereUniqueWithoutCampaign_EventInput = {
  data: LeadActivityUpdateWithoutCampaign_EventInput;
  where: LeadActivityWhereUniqueInput;
};

export type LeadActivityUpdateWithWhereUniqueWithoutConferenceInput = {
  data: LeadActivityUpdateWithoutConferenceInput;
  where: LeadActivityWhereUniqueInput;
};

export type LeadActivityUpdateWithWhereUniqueWithoutLead_Current_SequenceInput = {
  data: LeadActivityUpdateWithoutLead_Current_SequenceInput;
  where: LeadActivityWhereUniqueInput;
};

export type LeadActivityUpdateWithWhereUniqueWithoutLead_Current_StepInput = {
  data: LeadActivityUpdateWithoutLead_Current_StepInput;
  where: LeadActivityWhereUniqueInput;
};

export type LeadActivityUpdateWithWhereUniqueWithoutLeadInput = {
  data: LeadActivityUpdateWithoutLeadInput;
  where: LeadActivityWhereUniqueInput;
};

export type LeadActivityUpdateWithWhereUniqueWithoutLocal_NumberInput = {
  data: LeadActivityUpdateWithoutLocal_NumberInput;
  where: LeadActivityWhereUniqueInput;
};

export type LeadActivityUpdateWithWhereUniqueWithoutOrganizationInput = {
  data: LeadActivityUpdateWithoutOrganizationInput;
  where: LeadActivityWhereUniqueInput;
};

export type LeadActivityUpdateWithWhereUniqueWithoutRelated_DispositionInput = {
  data: LeadActivityUpdateWithoutRelated_DispositionInput;
  where: LeadActivityWhereUniqueInput;
};

export type LeadActivityUpdateWithWhereUniqueWithoutSale_CycleInput = {
  data: LeadActivityUpdateWithoutSale_CycleInput;
  where: LeadActivityWhereUniqueInput;
};

export type LeadActivityUpdateWithWhereUniqueWithoutSiteInput = {
  data: LeadActivityUpdateWithoutSiteInput;
  where: LeadActivityWhereUniqueInput;
};

export type LeadActivityUpdateWithWhereUniqueWithoutTeamInput = {
  data: LeadActivityUpdateWithoutTeamInput;
  where: LeadActivityWhereUniqueInput;
};

export type LeadActivityUpdateWithWhereUniqueWithoutUserInput = {
  data: LeadActivityUpdateWithoutUserInput;
  where: LeadActivityWhereUniqueInput;
};

export type LeadActivityUpsertWithoutEmail_ItemInput = {
  create: LeadActivityCreateWithoutEmail_ItemInput;
  update: LeadActivityUpdateWithoutEmail_ItemInput;
};

export type LeadActivityUpsertWithoutInboundConciergeEventsInput = {
  create: LeadActivityCreateWithoutInboundConciergeEventsInput;
  update: LeadActivityUpdateWithoutInboundConciergeEventsInput;
};

export type LeadActivityUpsertWithoutLead_Assignment_History_ChangeInput = {
  create: LeadActivityCreateWithoutLead_Assignment_History_ChangeInput;
  update: LeadActivityUpdateWithoutLead_Assignment_History_ChangeInput;
};

export type LeadActivityUpsertWithoutOriginating_Schedule_ItemInput = {
  create: LeadActivityCreateWithoutOriginating_Schedule_ItemInput;
  update: LeadActivityUpdateWithoutOriginating_Schedule_ItemInput;
};

export type LeadActivityUpsertWithoutSaleInput = {
  create: LeadActivityCreateWithoutSaleInput;
  update: LeadActivityUpdateWithoutSaleInput;
};

export type LeadActivityUpsertWithoutSchedule_ItemInput = {
  create: LeadActivityCreateWithoutSchedule_ItemInput;
  update: LeadActivityUpdateWithoutSchedule_ItemInput;
};

export type LeadActivityUpsertWithoutSequenceEntryExitLeadInput = {
  create: LeadActivityCreateWithoutSequenceEntryExitLeadInput;
  update: LeadActivityUpdateWithoutSequenceEntryExitLeadInput;
};

export type LeadActivityUpsertWithoutSmsItemInput = {
  create: LeadActivityCreateWithoutSmsItemInput;
  update: LeadActivityUpdateWithoutSmsItemInput;
};

export type LeadActivityUpsertWithoutStat_Item_Aggregation_PipelineInput = {
  create: LeadActivityCreateWithoutStat_Item_Aggregation_PipelineInput;
  update: LeadActivityUpdateWithoutStat_Item_Aggregation_PipelineInput;
};

export type LeadActivityUpsertWithoutStat_Item_AggregationInput = {
  create: LeadActivityCreateWithoutStat_Item_AggregationInput;
  update: LeadActivityUpdateWithoutStat_Item_AggregationInput;
};

export type LeadActivityUpsertWithoutStepActionLogInput = {
  create: LeadActivityCreateWithoutStepActionLogInput;
  update: LeadActivityUpdateWithoutStepActionLogInput;
};

export type LeadActivityUpsertWithWhereUniqueWithoutAssociate_ChildInput = {
  create: LeadActivityCreateWithoutAssociate_ChildInput;
  update: LeadActivityUpdateWithoutAssociate_ChildInput;
  where: LeadActivityWhereUniqueInput;
};

export type LeadActivityUpsertWithWhereUniqueWithoutAssociate_Group_LeadsInput = {
  create: LeadActivityCreateWithoutAssociate_Group_LeadsInput;
  update: LeadActivityUpdateWithoutAssociate_Group_LeadsInput;
  where: LeadActivityWhereUniqueInput;
};

export type LeadActivityUpsertWithWhereUniqueWithoutAutomated_CampaignInput = {
  create: LeadActivityCreateWithoutAutomated_CampaignInput;
  update: LeadActivityUpdateWithoutAutomated_CampaignInput;
  where: LeadActivityWhereUniqueInput;
};

export type LeadActivityUpsertWithWhereUniqueWithoutCampaign_EventInput = {
  create: LeadActivityCreateWithoutCampaign_EventInput;
  update: LeadActivityUpdateWithoutCampaign_EventInput;
  where: LeadActivityWhereUniqueInput;
};

export type LeadActivityUpsertWithWhereUniqueWithoutConferenceInput = {
  create: LeadActivityCreateWithoutConferenceInput;
  update: LeadActivityUpdateWithoutConferenceInput;
  where: LeadActivityWhereUniqueInput;
};

export type LeadActivityUpsertWithWhereUniqueWithoutLead_Current_SequenceInput = {
  create: LeadActivityCreateWithoutLead_Current_SequenceInput;
  update: LeadActivityUpdateWithoutLead_Current_SequenceInput;
  where: LeadActivityWhereUniqueInput;
};

export type LeadActivityUpsertWithWhereUniqueWithoutLead_Current_StepInput = {
  create: LeadActivityCreateWithoutLead_Current_StepInput;
  update: LeadActivityUpdateWithoutLead_Current_StepInput;
  where: LeadActivityWhereUniqueInput;
};

export type LeadActivityUpsertWithWhereUniqueWithoutLeadInput = {
  create: LeadActivityCreateWithoutLeadInput;
  update: LeadActivityUpdateWithoutLeadInput;
  where: LeadActivityWhereUniqueInput;
};

export type LeadActivityUpsertWithWhereUniqueWithoutLocal_NumberInput = {
  create: LeadActivityCreateWithoutLocal_NumberInput;
  update: LeadActivityUpdateWithoutLocal_NumberInput;
  where: LeadActivityWhereUniqueInput;
};

export type LeadActivityUpsertWithWhereUniqueWithoutOrganizationInput = {
  create: LeadActivityCreateWithoutOrganizationInput;
  update: LeadActivityUpdateWithoutOrganizationInput;
  where: LeadActivityWhereUniqueInput;
};

export type LeadActivityUpsertWithWhereUniqueWithoutRelated_DispositionInput = {
  create: LeadActivityCreateWithoutRelated_DispositionInput;
  update: LeadActivityUpdateWithoutRelated_DispositionInput;
  where: LeadActivityWhereUniqueInput;
};

export type LeadActivityUpsertWithWhereUniqueWithoutSale_CycleInput = {
  create: LeadActivityCreateWithoutSale_CycleInput;
  update: LeadActivityUpdateWithoutSale_CycleInput;
  where: LeadActivityWhereUniqueInput;
};

export type LeadActivityUpsertWithWhereUniqueWithoutSiteInput = {
  create: LeadActivityCreateWithoutSiteInput;
  update: LeadActivityUpdateWithoutSiteInput;
  where: LeadActivityWhereUniqueInput;
};

export type LeadActivityUpsertWithWhereUniqueWithoutTeamInput = {
  create: LeadActivityCreateWithoutTeamInput;
  update: LeadActivityUpdateWithoutTeamInput;
  where: LeadActivityWhereUniqueInput;
};

export type LeadActivityUpsertWithWhereUniqueWithoutUserInput = {
  create: LeadActivityCreateWithoutUserInput;
  update: LeadActivityUpdateWithoutUserInput;
  where: LeadActivityWhereUniqueInput;
};

export type LeadActivityWhereInput = {
  AND?: InputMaybe<Array<LeadActivityWhereInput>>;
  associate_child?: InputMaybe<LeadWhereInput>;
  associate_child_id?: InputMaybe<StringNullableFilter>;
  associate_group_ids?: InputMaybe<StringNullableListFilter>;
  associate_group_leads?: InputMaybe<LeadListRelationFilter>;
  associated_action?: InputMaybe<StringNullableFilter>;
  automated_campaign?: InputMaybe<AutomatedCampaignWhereInput>;
  automated_campaign_id?: InputMaybe<StringNullableFilter>;
  call_completed_with?: InputMaybe<EnumCallresultNullableFilter>;
  call_result_deleted?: InputMaybe<BoolFilter>;
  campaign_event?: InputMaybe<ScheduleItemWhereInput>;
  campaign_event_id?: InputMaybe<StringNullableFilter>;
  channel?: InputMaybe<EnumChannelNullableFilter>;
  communication_type?: InputMaybe<EnumCommunication_TypeNullableFilter>;
  conference?: InputMaybe<ConferenceWhereInput>;
  conference_id?: InputMaybe<StringNullableFilter>;
  created_at?: InputMaybe<DateTimeFilter>;
  curr_lead_val?: InputMaybe<FloatNullableFilter>;
  current_implied_action?: InputMaybe<EnumHingepointNullableFilter>;
  customer_number?: InputMaybe<StringNullableFilter>;
  customer_number_country_code?: InputMaybe<StringNullableFilter>;
  cycle_num?: InputMaybe<IntNullableFilter>;
  dedupe_migration?: InputMaybe<BoolFilter>;
  disposition_id?: InputMaybe<StringNullableFilter>;
  email_item?: InputMaybe<EmailItemWhereInput>;
  from_action?: InputMaybe<StringNullableFilter>;
  from_callback_type?: InputMaybe<EnumCallbackTypeNullableFilter>;
  from_intent_type?: InputMaybe<EnumIntentTypeNullableFilter>;
  html?: InputMaybe<BoolFilter>;
  hubspot_email_engagement_id?: InputMaybe<StringNullableFilter>;
  hubspot_id?: InputMaybe<StringNullableFilter>;
  hubspot_note_engagement_id?: InputMaybe<StringNullableFilter>;
  hubspot_owner_id?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  ignore_sequences?: InputMaybe<BoolFilter>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventListRelationFilter>;
  initial_days_set_out?: InputMaybe<FloatNullableFilter>;
  is_resting_adjustment?: InputMaybe<BoolFilter>;
  lead?: InputMaybe<LeadWhereInput>;
  lead_assignment_history_change?: InputMaybe<LeadAssignmentHistoryWhereInput>;
  lead_assignment_history_id?: InputMaybe<StringNullableFilter>;
  lead_current_sequence?: InputMaybe<SequenceWhereInput>;
  lead_current_sequence_id?: InputMaybe<StringNullableFilter>;
  lead_current_step?: InputMaybe<SequenceStepWhereInput>;
  lead_current_step_id?: InputMaybe<StringNullableFilter>;
  lead_id?: InputMaybe<StringFilter>;
  local_number?: InputMaybe<TwilioNumberWhereInput>;
  local_number_id?: InputMaybe<StringNullableFilter>;
  next_action?: InputMaybe<StringNullableFilter>;
  next_callback_type?: InputMaybe<EnumCallbackTypeNullableFilter>;
  NOT?: InputMaybe<Array<LeadActivityWhereInput>>;
  notes?: InputMaybe<StringNullableFilter>;
  OR?: InputMaybe<Array<LeadActivityWhereInput>>;
  organization?: InputMaybe<OrganizationWhereInput>;
  organization_id?: InputMaybe<StringNullableFilter>;
  originating_schedule_item?: InputMaybe<ScheduleItemWhereInput>;
  previous_lead_val?: InputMaybe<FloatNullableFilter>;
  published?: InputMaybe<BoolNullableFilter>;
  related_disposition?: InputMaybe<DispositionWhereInput>;
  resting_date_on_recycle?: InputMaybe<DateTimeNullableFilter>;
  resting_type_on_recycle?: InputMaybe<EnumRestingtypeNullableFilter>;
  sale?: InputMaybe<SaleWhereInput>;
  sale_cycle?: InputMaybe<SaleCycleWhereInput>;
  schedule_item?: InputMaybe<ScheduleItemWhereInput>;
  schedule_item_id?: InputMaybe<StringNullableFilter>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadListRelationFilter>;
  site?: InputMaybe<SiteWhereInput>;
  site_id?: InputMaybe<StringNullableFilter>;
  skip_reason?: InputMaybe<StringNullableFilter>;
  SMSItem?: InputMaybe<SmsItemWhereInput>;
  stat_item_aggregation?: InputMaybe<StatItemAggregationWhereInput>;
  stat_item_aggregation_pipeline?: InputMaybe<StatItemAggregationWhereInput>;
  StepActionLog?: InputMaybe<StepActionLogListRelationFilter>;
  team?: InputMaybe<TeamWhereInput>;
  team_id?: InputMaybe<StringNullableFilter>;
  title?: InputMaybe<StringNullableFilter>;
  twilio_action?: InputMaybe<StringNullableFilter>;
  type?: InputMaybe<StringNullableFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserWhereInput>;
  user_id?: InputMaybe<StringNullableFilter>;
  user_role?: InputMaybe<EnumRoleNullableFilter>;
};

export type LeadActivityWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  lead_assignment_history_id?: InputMaybe<Scalars['String']['input']>;
  schedule_item_id?: InputMaybe<Scalars['String']['input']>;
};

/** Lead and Owned Rep */
export type LeadAndOwnedRep = {
  __typename?: 'LeadAndOwnedRep';
  lead?: Maybe<Lead>;
  rep?: Maybe<User>;
};

export type LeadAssignmentHistory = {
  __typename?: 'LeadAssignmentHistory';
  created_at: Scalars['DateTime']['output'];
  custom_assignment_origin?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  lead?: Maybe<Lead>;
  lead_activity?: Maybe<LeadActivity>;
  lead_activity_id?: Maybe<Scalars['String']['output']>;
  lead_assignment_origin: AssignmentOrigin;
  lead_assignment_origin_label?: Maybe<Scalars['String']['output']>;
  lead_id?: Maybe<Scalars['String']['output']>;
  old_user?: Maybe<User>;
  old_user_id?: Maybe<Scalars['String']['output']>;
  post_ownership_status?: Maybe<Los>;
  pre_ownership_status?: Maybe<Los>;
  receiver_user?: Maybe<User>;
  receiver_user_id?: Maybe<Scalars['String']['output']>;
  sender_user?: Maybe<User>;
  sender_user_id?: Maybe<Scalars['String']['output']>;
  sequence_not_interested?: Maybe<Sequence>;
  sequence_not_interested_id?: Maybe<Scalars['String']['output']>;
  updated_at: Scalars['DateTime']['output'];
};

export type LeadAssignmentHistoryCreateManyLeadInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  custom_assignment_origin?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  lead_activity_id?: InputMaybe<Scalars['String']['input']>;
  lead_assignment_origin?: InputMaybe<AssignmentOrigin>;
  old_user_id?: InputMaybe<Scalars['String']['input']>;
  post_ownership_status?: InputMaybe<Los>;
  pre_ownership_status?: InputMaybe<Los>;
  receiver_user_id?: InputMaybe<Scalars['String']['input']>;
  sender_user_id?: InputMaybe<Scalars['String']['input']>;
  sequence_not_interested_id?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type LeadAssignmentHistoryCreateManyLeadInputEnvelope = {
  data?: InputMaybe<Array<LeadAssignmentHistoryCreateManyLeadInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type LeadAssignmentHistoryCreateManyOld_UserInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  custom_assignment_origin?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  lead_activity_id?: InputMaybe<Scalars['String']['input']>;
  lead_assignment_origin?: InputMaybe<AssignmentOrigin>;
  lead_id?: InputMaybe<Scalars['String']['input']>;
  post_ownership_status?: InputMaybe<Los>;
  pre_ownership_status?: InputMaybe<Los>;
  receiver_user_id?: InputMaybe<Scalars['String']['input']>;
  sender_user_id?: InputMaybe<Scalars['String']['input']>;
  sequence_not_interested_id?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type LeadAssignmentHistoryCreateManyOld_UserInputEnvelope = {
  data?: InputMaybe<Array<LeadAssignmentHistoryCreateManyOld_UserInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type LeadAssignmentHistoryCreateManyReceiver_UserInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  custom_assignment_origin?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  lead_activity_id?: InputMaybe<Scalars['String']['input']>;
  lead_assignment_origin?: InputMaybe<AssignmentOrigin>;
  lead_id?: InputMaybe<Scalars['String']['input']>;
  old_user_id?: InputMaybe<Scalars['String']['input']>;
  post_ownership_status?: InputMaybe<Los>;
  pre_ownership_status?: InputMaybe<Los>;
  sender_user_id?: InputMaybe<Scalars['String']['input']>;
  sequence_not_interested_id?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type LeadAssignmentHistoryCreateManyReceiver_UserInputEnvelope = {
  data?: InputMaybe<Array<LeadAssignmentHistoryCreateManyReceiver_UserInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type LeadAssignmentHistoryCreateManySender_UserInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  custom_assignment_origin?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  lead_activity_id?: InputMaybe<Scalars['String']['input']>;
  lead_assignment_origin?: InputMaybe<AssignmentOrigin>;
  lead_id?: InputMaybe<Scalars['String']['input']>;
  old_user_id?: InputMaybe<Scalars['String']['input']>;
  post_ownership_status?: InputMaybe<Los>;
  pre_ownership_status?: InputMaybe<Los>;
  receiver_user_id?: InputMaybe<Scalars['String']['input']>;
  sequence_not_interested_id?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type LeadAssignmentHistoryCreateManySender_UserInputEnvelope = {
  data?: InputMaybe<Array<LeadAssignmentHistoryCreateManySender_UserInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type LeadAssignmentHistoryCreateManySequence_Not_InterestedInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  custom_assignment_origin?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  lead_activity_id?: InputMaybe<Scalars['String']['input']>;
  lead_assignment_origin?: InputMaybe<AssignmentOrigin>;
  lead_id?: InputMaybe<Scalars['String']['input']>;
  old_user_id?: InputMaybe<Scalars['String']['input']>;
  post_ownership_status?: InputMaybe<Los>;
  pre_ownership_status?: InputMaybe<Los>;
  receiver_user_id?: InputMaybe<Scalars['String']['input']>;
  sender_user_id?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type LeadAssignmentHistoryCreateManySequence_Not_InterestedInputEnvelope = {
  data?: InputMaybe<Array<LeadAssignmentHistoryCreateManySequence_Not_InterestedInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type LeadAssignmentHistoryCreateNestedManyWithoutLeadInput = {
  connect?: InputMaybe<Array<LeadAssignmentHistoryWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<LeadAssignmentHistoryCreateOrConnectWithoutLeadInput>>;
  create?: InputMaybe<Array<LeadAssignmentHistoryCreateWithoutLeadInput>>;
  createMany?: InputMaybe<LeadAssignmentHistoryCreateManyLeadInputEnvelope>;
};

export type LeadAssignmentHistoryCreateNestedManyWithoutOld_UserInput = {
  connect?: InputMaybe<Array<LeadAssignmentHistoryWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<LeadAssignmentHistoryCreateOrConnectWithoutOld_UserInput>>;
  create?: InputMaybe<Array<LeadAssignmentHistoryCreateWithoutOld_UserInput>>;
  createMany?: InputMaybe<LeadAssignmentHistoryCreateManyOld_UserInputEnvelope>;
};

export type LeadAssignmentHistoryCreateNestedManyWithoutReceiver_UserInput = {
  connect?: InputMaybe<Array<LeadAssignmentHistoryWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<LeadAssignmentHistoryCreateOrConnectWithoutReceiver_UserInput>>;
  create?: InputMaybe<Array<LeadAssignmentHistoryCreateWithoutReceiver_UserInput>>;
  createMany?: InputMaybe<LeadAssignmentHistoryCreateManyReceiver_UserInputEnvelope>;
};

export type LeadAssignmentHistoryCreateNestedManyWithoutSender_UserInput = {
  connect?: InputMaybe<Array<LeadAssignmentHistoryWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<LeadAssignmentHistoryCreateOrConnectWithoutSender_UserInput>>;
  create?: InputMaybe<Array<LeadAssignmentHistoryCreateWithoutSender_UserInput>>;
  createMany?: InputMaybe<LeadAssignmentHistoryCreateManySender_UserInputEnvelope>;
};

export type LeadAssignmentHistoryCreateNestedManyWithoutSequence_Not_InterestedInput = {
  connect?: InputMaybe<Array<LeadAssignmentHistoryWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<LeadAssignmentHistoryCreateOrConnectWithoutSequence_Not_InterestedInput>>;
  create?: InputMaybe<Array<LeadAssignmentHistoryCreateWithoutSequence_Not_InterestedInput>>;
  createMany?: InputMaybe<LeadAssignmentHistoryCreateManySequence_Not_InterestedInputEnvelope>;
};

export type LeadAssignmentHistoryCreateNestedOneWithoutInboundConciergeEventInput = {
  connect?: InputMaybe<LeadAssignmentHistoryWhereUniqueInput>;
  connectOrCreate?: InputMaybe<LeadAssignmentHistoryCreateOrConnectWithoutInboundConciergeEventInput>;
  create?: InputMaybe<LeadAssignmentHistoryCreateWithoutInboundConciergeEventInput>;
};

export type LeadAssignmentHistoryCreateNestedOneWithoutLead_ActivityInput = {
  connect?: InputMaybe<LeadAssignmentHistoryWhereUniqueInput>;
  connectOrCreate?: InputMaybe<LeadAssignmentHistoryCreateOrConnectWithoutLead_ActivityInput>;
  create?: InputMaybe<LeadAssignmentHistoryCreateWithoutLead_ActivityInput>;
};

export type LeadAssignmentHistoryCreateOrConnectWithoutInboundConciergeEventInput = {
  create: LeadAssignmentHistoryCreateWithoutInboundConciergeEventInput;
  where: LeadAssignmentHistoryWhereUniqueInput;
};

export type LeadAssignmentHistoryCreateOrConnectWithoutLead_ActivityInput = {
  create: LeadAssignmentHistoryCreateWithoutLead_ActivityInput;
  where: LeadAssignmentHistoryWhereUniqueInput;
};

export type LeadAssignmentHistoryCreateOrConnectWithoutLeadInput = {
  create: LeadAssignmentHistoryCreateWithoutLeadInput;
  where: LeadAssignmentHistoryWhereUniqueInput;
};

export type LeadAssignmentHistoryCreateOrConnectWithoutOld_UserInput = {
  create: LeadAssignmentHistoryCreateWithoutOld_UserInput;
  where: LeadAssignmentHistoryWhereUniqueInput;
};

export type LeadAssignmentHistoryCreateOrConnectWithoutReceiver_UserInput = {
  create: LeadAssignmentHistoryCreateWithoutReceiver_UserInput;
  where: LeadAssignmentHistoryWhereUniqueInput;
};

export type LeadAssignmentHistoryCreateOrConnectWithoutSender_UserInput = {
  create: LeadAssignmentHistoryCreateWithoutSender_UserInput;
  where: LeadAssignmentHistoryWhereUniqueInput;
};

export type LeadAssignmentHistoryCreateOrConnectWithoutSequence_Not_InterestedInput = {
  create: LeadAssignmentHistoryCreateWithoutSequence_Not_InterestedInput;
  where: LeadAssignmentHistoryWhereUniqueInput;
};

export type LeadAssignmentHistoryCreateWithoutInboundConciergeEventInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  custom_assignment_origin?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  lead?: InputMaybe<LeadCreateNestedOneWithoutLead_Assignment_HistoryInput>;
  lead_activity?: InputMaybe<LeadActivityCreateNestedOneWithoutLead_Assignment_History_ChangeInput>;
  lead_activity_id?: InputMaybe<Scalars['String']['input']>;
  lead_assignment_origin?: InputMaybe<AssignmentOrigin>;
  old_user?: InputMaybe<UserCreateNestedOneWithoutFrom_Assignment_HistoryInput>;
  post_ownership_status?: InputMaybe<Los>;
  pre_ownership_status?: InputMaybe<Los>;
  receiver_user?: InputMaybe<UserCreateNestedOneWithoutReceiver_Assignment_HistoryInput>;
  sender_user?: InputMaybe<UserCreateNestedOneWithoutSender_Assignment_HistoryInput>;
  sequence_not_interested?: InputMaybe<SequenceCreateNestedOneWithoutLeadAssignmentHistoryInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type LeadAssignmentHistoryCreateWithoutLead_ActivityInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  custom_assignment_origin?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  inboundConciergeEvent?: InputMaybe<InboundConciergeEventCreateNestedOneWithoutLead_Assignment_HistoryInput>;
  lead?: InputMaybe<LeadCreateNestedOneWithoutLead_Assignment_HistoryInput>;
  lead_activity_id?: InputMaybe<Scalars['String']['input']>;
  lead_assignment_origin?: InputMaybe<AssignmentOrigin>;
  old_user?: InputMaybe<UserCreateNestedOneWithoutFrom_Assignment_HistoryInput>;
  post_ownership_status?: InputMaybe<Los>;
  pre_ownership_status?: InputMaybe<Los>;
  receiver_user?: InputMaybe<UserCreateNestedOneWithoutReceiver_Assignment_HistoryInput>;
  sender_user?: InputMaybe<UserCreateNestedOneWithoutSender_Assignment_HistoryInput>;
  sequence_not_interested?: InputMaybe<SequenceCreateNestedOneWithoutLeadAssignmentHistoryInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type LeadAssignmentHistoryCreateWithoutLeadInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  custom_assignment_origin?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  inboundConciergeEvent?: InputMaybe<InboundConciergeEventCreateNestedOneWithoutLead_Assignment_HistoryInput>;
  lead_activity?: InputMaybe<LeadActivityCreateNestedOneWithoutLead_Assignment_History_ChangeInput>;
  lead_activity_id?: InputMaybe<Scalars['String']['input']>;
  lead_assignment_origin?: InputMaybe<AssignmentOrigin>;
  old_user?: InputMaybe<UserCreateNestedOneWithoutFrom_Assignment_HistoryInput>;
  post_ownership_status?: InputMaybe<Los>;
  pre_ownership_status?: InputMaybe<Los>;
  receiver_user?: InputMaybe<UserCreateNestedOneWithoutReceiver_Assignment_HistoryInput>;
  sender_user?: InputMaybe<UserCreateNestedOneWithoutSender_Assignment_HistoryInput>;
  sequence_not_interested?: InputMaybe<SequenceCreateNestedOneWithoutLeadAssignmentHistoryInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type LeadAssignmentHistoryCreateWithoutOld_UserInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  custom_assignment_origin?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  inboundConciergeEvent?: InputMaybe<InboundConciergeEventCreateNestedOneWithoutLead_Assignment_HistoryInput>;
  lead?: InputMaybe<LeadCreateNestedOneWithoutLead_Assignment_HistoryInput>;
  lead_activity?: InputMaybe<LeadActivityCreateNestedOneWithoutLead_Assignment_History_ChangeInput>;
  lead_activity_id?: InputMaybe<Scalars['String']['input']>;
  lead_assignment_origin?: InputMaybe<AssignmentOrigin>;
  post_ownership_status?: InputMaybe<Los>;
  pre_ownership_status?: InputMaybe<Los>;
  receiver_user?: InputMaybe<UserCreateNestedOneWithoutReceiver_Assignment_HistoryInput>;
  sender_user?: InputMaybe<UserCreateNestedOneWithoutSender_Assignment_HistoryInput>;
  sequence_not_interested?: InputMaybe<SequenceCreateNestedOneWithoutLeadAssignmentHistoryInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type LeadAssignmentHistoryCreateWithoutReceiver_UserInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  custom_assignment_origin?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  inboundConciergeEvent?: InputMaybe<InboundConciergeEventCreateNestedOneWithoutLead_Assignment_HistoryInput>;
  lead?: InputMaybe<LeadCreateNestedOneWithoutLead_Assignment_HistoryInput>;
  lead_activity?: InputMaybe<LeadActivityCreateNestedOneWithoutLead_Assignment_History_ChangeInput>;
  lead_activity_id?: InputMaybe<Scalars['String']['input']>;
  lead_assignment_origin?: InputMaybe<AssignmentOrigin>;
  old_user?: InputMaybe<UserCreateNestedOneWithoutFrom_Assignment_HistoryInput>;
  post_ownership_status?: InputMaybe<Los>;
  pre_ownership_status?: InputMaybe<Los>;
  sender_user?: InputMaybe<UserCreateNestedOneWithoutSender_Assignment_HistoryInput>;
  sequence_not_interested?: InputMaybe<SequenceCreateNestedOneWithoutLeadAssignmentHistoryInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type LeadAssignmentHistoryCreateWithoutSender_UserInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  custom_assignment_origin?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  inboundConciergeEvent?: InputMaybe<InboundConciergeEventCreateNestedOneWithoutLead_Assignment_HistoryInput>;
  lead?: InputMaybe<LeadCreateNestedOneWithoutLead_Assignment_HistoryInput>;
  lead_activity?: InputMaybe<LeadActivityCreateNestedOneWithoutLead_Assignment_History_ChangeInput>;
  lead_activity_id?: InputMaybe<Scalars['String']['input']>;
  lead_assignment_origin?: InputMaybe<AssignmentOrigin>;
  old_user?: InputMaybe<UserCreateNestedOneWithoutFrom_Assignment_HistoryInput>;
  post_ownership_status?: InputMaybe<Los>;
  pre_ownership_status?: InputMaybe<Los>;
  receiver_user?: InputMaybe<UserCreateNestedOneWithoutReceiver_Assignment_HistoryInput>;
  sequence_not_interested?: InputMaybe<SequenceCreateNestedOneWithoutLeadAssignmentHistoryInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type LeadAssignmentHistoryCreateWithoutSequence_Not_InterestedInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  custom_assignment_origin?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  inboundConciergeEvent?: InputMaybe<InboundConciergeEventCreateNestedOneWithoutLead_Assignment_HistoryInput>;
  lead?: InputMaybe<LeadCreateNestedOneWithoutLead_Assignment_HistoryInput>;
  lead_activity?: InputMaybe<LeadActivityCreateNestedOneWithoutLead_Assignment_History_ChangeInput>;
  lead_activity_id?: InputMaybe<Scalars['String']['input']>;
  lead_assignment_origin?: InputMaybe<AssignmentOrigin>;
  old_user?: InputMaybe<UserCreateNestedOneWithoutFrom_Assignment_HistoryInput>;
  post_ownership_status?: InputMaybe<Los>;
  pre_ownership_status?: InputMaybe<Los>;
  receiver_user?: InputMaybe<UserCreateNestedOneWithoutReceiver_Assignment_HistoryInput>;
  sender_user?: InputMaybe<UserCreateNestedOneWithoutSender_Assignment_HistoryInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type LeadAssignmentHistoryListRelationFilter = {
  every?: InputMaybe<LeadAssignmentHistoryWhereInput>;
  none?: InputMaybe<LeadAssignmentHistoryWhereInput>;
  some?: InputMaybe<LeadAssignmentHistoryWhereInput>;
};

export type LeadAssignmentHistoryOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
  count?: InputMaybe<SortOrder>;
};

export type LeadAssignmentHistoryOrderByWithRelationInput = {
  created_at?: InputMaybe<SortOrder>;
  custom_assignment_origin?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  inboundConciergeEvent?: InputMaybe<InboundConciergeEventOrderByWithRelationInput>;
  lead?: InputMaybe<LeadOrderByWithRelationInput>;
  lead_activity?: InputMaybe<LeadActivityOrderByWithRelationInput>;
  lead_activity_id?: InputMaybe<SortOrder>;
  lead_assignment_origin?: InputMaybe<SortOrder>;
  lead_id?: InputMaybe<SortOrder>;
  old_user?: InputMaybe<UserOrderByWithRelationInput>;
  old_user_id?: InputMaybe<SortOrder>;
  post_ownership_status?: InputMaybe<SortOrder>;
  pre_ownership_status?: InputMaybe<SortOrder>;
  receiver_user?: InputMaybe<UserOrderByWithRelationInput>;
  receiver_user_id?: InputMaybe<SortOrder>;
  sender_user?: InputMaybe<UserOrderByWithRelationInput>;
  sender_user_id?: InputMaybe<SortOrder>;
  sequence_not_interested?: InputMaybe<SequenceOrderByWithRelationInput>;
  sequence_not_interested_id?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
};

export type LeadAssignmentHistoryScalarWhereInput = {
  AND?: InputMaybe<Array<LeadAssignmentHistoryScalarWhereInput>>;
  created_at?: InputMaybe<DateTimeFilter>;
  custom_assignment_origin?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  lead_activity_id?: InputMaybe<StringNullableFilter>;
  lead_assignment_origin?: InputMaybe<EnumAssignmentOriginFilter>;
  lead_id?: InputMaybe<StringNullableFilter>;
  NOT?: InputMaybe<Array<LeadAssignmentHistoryScalarWhereInput>>;
  old_user_id?: InputMaybe<StringNullableFilter>;
  OR?: InputMaybe<Array<LeadAssignmentHistoryScalarWhereInput>>;
  post_ownership_status?: InputMaybe<EnumLosNullableFilter>;
  pre_ownership_status?: InputMaybe<EnumLosNullableFilter>;
  receiver_user_id?: InputMaybe<StringNullableFilter>;
  sender_user_id?: InputMaybe<StringNullableFilter>;
  sequence_not_interested_id?: InputMaybe<StringNullableFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
};

export type LeadAssignmentHistoryUpdateManyMutationInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  custom_assignment_origin?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lead_activity_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  lead_assignment_origin?: InputMaybe<EnumAssignmentOriginFieldUpdateOperationsInput>;
  post_ownership_status?: InputMaybe<NullableEnumLosFieldUpdateOperationsInput>;
  pre_ownership_status?: InputMaybe<NullableEnumLosFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type LeadAssignmentHistoryUpdateManyWithoutLeadInput = {
  connect?: InputMaybe<Array<LeadAssignmentHistoryWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<LeadAssignmentHistoryCreateOrConnectWithoutLeadInput>>;
  create?: InputMaybe<Array<LeadAssignmentHistoryCreateWithoutLeadInput>>;
  createMany?: InputMaybe<LeadAssignmentHistoryCreateManyLeadInputEnvelope>;
  delete?: InputMaybe<Array<LeadAssignmentHistoryWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<LeadAssignmentHistoryScalarWhereInput>>;
  disconnect?: InputMaybe<Array<LeadAssignmentHistoryWhereUniqueInput>>;
  set?: InputMaybe<Array<LeadAssignmentHistoryWhereUniqueInput>>;
  update?: InputMaybe<Array<LeadAssignmentHistoryUpdateWithWhereUniqueWithoutLeadInput>>;
  updateMany?: InputMaybe<Array<LeadAssignmentHistoryUpdateManyWithWhereWithoutLeadInput>>;
  upsert?: InputMaybe<Array<LeadAssignmentHistoryUpsertWithWhereUniqueWithoutLeadInput>>;
};

export type LeadAssignmentHistoryUpdateManyWithoutOld_UserInput = {
  connect?: InputMaybe<Array<LeadAssignmentHistoryWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<LeadAssignmentHistoryCreateOrConnectWithoutOld_UserInput>>;
  create?: InputMaybe<Array<LeadAssignmentHistoryCreateWithoutOld_UserInput>>;
  createMany?: InputMaybe<LeadAssignmentHistoryCreateManyOld_UserInputEnvelope>;
  delete?: InputMaybe<Array<LeadAssignmentHistoryWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<LeadAssignmentHistoryScalarWhereInput>>;
  disconnect?: InputMaybe<Array<LeadAssignmentHistoryWhereUniqueInput>>;
  set?: InputMaybe<Array<LeadAssignmentHistoryWhereUniqueInput>>;
  update?: InputMaybe<Array<LeadAssignmentHistoryUpdateWithWhereUniqueWithoutOld_UserInput>>;
  updateMany?: InputMaybe<Array<LeadAssignmentHistoryUpdateManyWithWhereWithoutOld_UserInput>>;
  upsert?: InputMaybe<Array<LeadAssignmentHistoryUpsertWithWhereUniqueWithoutOld_UserInput>>;
};

export type LeadAssignmentHistoryUpdateManyWithoutReceiver_UserInput = {
  connect?: InputMaybe<Array<LeadAssignmentHistoryWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<LeadAssignmentHistoryCreateOrConnectWithoutReceiver_UserInput>>;
  create?: InputMaybe<Array<LeadAssignmentHistoryCreateWithoutReceiver_UserInput>>;
  createMany?: InputMaybe<LeadAssignmentHistoryCreateManyReceiver_UserInputEnvelope>;
  delete?: InputMaybe<Array<LeadAssignmentHistoryWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<LeadAssignmentHistoryScalarWhereInput>>;
  disconnect?: InputMaybe<Array<LeadAssignmentHistoryWhereUniqueInput>>;
  set?: InputMaybe<Array<LeadAssignmentHistoryWhereUniqueInput>>;
  update?: InputMaybe<Array<LeadAssignmentHistoryUpdateWithWhereUniqueWithoutReceiver_UserInput>>;
  updateMany?: InputMaybe<Array<LeadAssignmentHistoryUpdateManyWithWhereWithoutReceiver_UserInput>>;
  upsert?: InputMaybe<Array<LeadAssignmentHistoryUpsertWithWhereUniqueWithoutReceiver_UserInput>>;
};

export type LeadAssignmentHistoryUpdateManyWithoutSender_UserInput = {
  connect?: InputMaybe<Array<LeadAssignmentHistoryWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<LeadAssignmentHistoryCreateOrConnectWithoutSender_UserInput>>;
  create?: InputMaybe<Array<LeadAssignmentHistoryCreateWithoutSender_UserInput>>;
  createMany?: InputMaybe<LeadAssignmentHistoryCreateManySender_UserInputEnvelope>;
  delete?: InputMaybe<Array<LeadAssignmentHistoryWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<LeadAssignmentHistoryScalarWhereInput>>;
  disconnect?: InputMaybe<Array<LeadAssignmentHistoryWhereUniqueInput>>;
  set?: InputMaybe<Array<LeadAssignmentHistoryWhereUniqueInput>>;
  update?: InputMaybe<Array<LeadAssignmentHistoryUpdateWithWhereUniqueWithoutSender_UserInput>>;
  updateMany?: InputMaybe<Array<LeadAssignmentHistoryUpdateManyWithWhereWithoutSender_UserInput>>;
  upsert?: InputMaybe<Array<LeadAssignmentHistoryUpsertWithWhereUniqueWithoutSender_UserInput>>;
};

export type LeadAssignmentHistoryUpdateManyWithoutSequence_Not_InterestedInput = {
  connect?: InputMaybe<Array<LeadAssignmentHistoryWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<LeadAssignmentHistoryCreateOrConnectWithoutSequence_Not_InterestedInput>>;
  create?: InputMaybe<Array<LeadAssignmentHistoryCreateWithoutSequence_Not_InterestedInput>>;
  createMany?: InputMaybe<LeadAssignmentHistoryCreateManySequence_Not_InterestedInputEnvelope>;
  delete?: InputMaybe<Array<LeadAssignmentHistoryWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<LeadAssignmentHistoryScalarWhereInput>>;
  disconnect?: InputMaybe<Array<LeadAssignmentHistoryWhereUniqueInput>>;
  set?: InputMaybe<Array<LeadAssignmentHistoryWhereUniqueInput>>;
  update?: InputMaybe<Array<LeadAssignmentHistoryUpdateWithWhereUniqueWithoutSequence_Not_InterestedInput>>;
  updateMany?: InputMaybe<Array<LeadAssignmentHistoryUpdateManyWithWhereWithoutSequence_Not_InterestedInput>>;
  upsert?: InputMaybe<Array<LeadAssignmentHistoryUpsertWithWhereUniqueWithoutSequence_Not_InterestedInput>>;
};

export type LeadAssignmentHistoryUpdateManyWithWhereWithoutLeadInput = {
  data: LeadAssignmentHistoryUpdateManyMutationInput;
  where: LeadAssignmentHistoryScalarWhereInput;
};

export type LeadAssignmentHistoryUpdateManyWithWhereWithoutOld_UserInput = {
  data: LeadAssignmentHistoryUpdateManyMutationInput;
  where: LeadAssignmentHistoryScalarWhereInput;
};

export type LeadAssignmentHistoryUpdateManyWithWhereWithoutReceiver_UserInput = {
  data: LeadAssignmentHistoryUpdateManyMutationInput;
  where: LeadAssignmentHistoryScalarWhereInput;
};

export type LeadAssignmentHistoryUpdateManyWithWhereWithoutSender_UserInput = {
  data: LeadAssignmentHistoryUpdateManyMutationInput;
  where: LeadAssignmentHistoryScalarWhereInput;
};

export type LeadAssignmentHistoryUpdateManyWithWhereWithoutSequence_Not_InterestedInput = {
  data: LeadAssignmentHistoryUpdateManyMutationInput;
  where: LeadAssignmentHistoryScalarWhereInput;
};

export type LeadAssignmentHistoryUpdateOneWithoutInboundConciergeEventInput = {
  connect?: InputMaybe<LeadAssignmentHistoryWhereUniqueInput>;
  connectOrCreate?: InputMaybe<LeadAssignmentHistoryCreateOrConnectWithoutInboundConciergeEventInput>;
  create?: InputMaybe<LeadAssignmentHistoryCreateWithoutInboundConciergeEventInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<LeadAssignmentHistoryUpdateWithoutInboundConciergeEventInput>;
  upsert?: InputMaybe<LeadAssignmentHistoryUpsertWithoutInboundConciergeEventInput>;
};

export type LeadAssignmentHistoryUpdateOneWithoutLead_ActivityInput = {
  connect?: InputMaybe<LeadAssignmentHistoryWhereUniqueInput>;
  connectOrCreate?: InputMaybe<LeadAssignmentHistoryCreateOrConnectWithoutLead_ActivityInput>;
  create?: InputMaybe<LeadAssignmentHistoryCreateWithoutLead_ActivityInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<LeadAssignmentHistoryUpdateWithoutLead_ActivityInput>;
  upsert?: InputMaybe<LeadAssignmentHistoryUpsertWithoutLead_ActivityInput>;
};

export type LeadAssignmentHistoryUpdateWithoutInboundConciergeEventInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  custom_assignment_origin?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lead?: InputMaybe<LeadUpdateOneWithoutLead_Assignment_HistoryInput>;
  lead_activity?: InputMaybe<LeadActivityUpdateOneWithoutLead_Assignment_History_ChangeInput>;
  lead_activity_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  lead_assignment_origin?: InputMaybe<EnumAssignmentOriginFieldUpdateOperationsInput>;
  old_user?: InputMaybe<UserUpdateOneWithoutFrom_Assignment_HistoryInput>;
  post_ownership_status?: InputMaybe<NullableEnumLosFieldUpdateOperationsInput>;
  pre_ownership_status?: InputMaybe<NullableEnumLosFieldUpdateOperationsInput>;
  receiver_user?: InputMaybe<UserUpdateOneWithoutReceiver_Assignment_HistoryInput>;
  sender_user?: InputMaybe<UserUpdateOneWithoutSender_Assignment_HistoryInput>;
  sequence_not_interested?: InputMaybe<SequenceUpdateOneWithoutLeadAssignmentHistoryInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type LeadAssignmentHistoryUpdateWithoutLead_ActivityInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  custom_assignment_origin?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inboundConciergeEvent?: InputMaybe<InboundConciergeEventUpdateOneWithoutLead_Assignment_HistoryInput>;
  lead?: InputMaybe<LeadUpdateOneWithoutLead_Assignment_HistoryInput>;
  lead_activity_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  lead_assignment_origin?: InputMaybe<EnumAssignmentOriginFieldUpdateOperationsInput>;
  old_user?: InputMaybe<UserUpdateOneWithoutFrom_Assignment_HistoryInput>;
  post_ownership_status?: InputMaybe<NullableEnumLosFieldUpdateOperationsInput>;
  pre_ownership_status?: InputMaybe<NullableEnumLosFieldUpdateOperationsInput>;
  receiver_user?: InputMaybe<UserUpdateOneWithoutReceiver_Assignment_HistoryInput>;
  sender_user?: InputMaybe<UserUpdateOneWithoutSender_Assignment_HistoryInput>;
  sequence_not_interested?: InputMaybe<SequenceUpdateOneWithoutLeadAssignmentHistoryInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type LeadAssignmentHistoryUpdateWithoutLeadInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  custom_assignment_origin?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inboundConciergeEvent?: InputMaybe<InboundConciergeEventUpdateOneWithoutLead_Assignment_HistoryInput>;
  lead_activity?: InputMaybe<LeadActivityUpdateOneWithoutLead_Assignment_History_ChangeInput>;
  lead_activity_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  lead_assignment_origin?: InputMaybe<EnumAssignmentOriginFieldUpdateOperationsInput>;
  old_user?: InputMaybe<UserUpdateOneWithoutFrom_Assignment_HistoryInput>;
  post_ownership_status?: InputMaybe<NullableEnumLosFieldUpdateOperationsInput>;
  pre_ownership_status?: InputMaybe<NullableEnumLosFieldUpdateOperationsInput>;
  receiver_user?: InputMaybe<UserUpdateOneWithoutReceiver_Assignment_HistoryInput>;
  sender_user?: InputMaybe<UserUpdateOneWithoutSender_Assignment_HistoryInput>;
  sequence_not_interested?: InputMaybe<SequenceUpdateOneWithoutLeadAssignmentHistoryInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type LeadAssignmentHistoryUpdateWithoutOld_UserInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  custom_assignment_origin?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inboundConciergeEvent?: InputMaybe<InboundConciergeEventUpdateOneWithoutLead_Assignment_HistoryInput>;
  lead?: InputMaybe<LeadUpdateOneWithoutLead_Assignment_HistoryInput>;
  lead_activity?: InputMaybe<LeadActivityUpdateOneWithoutLead_Assignment_History_ChangeInput>;
  lead_activity_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  lead_assignment_origin?: InputMaybe<EnumAssignmentOriginFieldUpdateOperationsInput>;
  post_ownership_status?: InputMaybe<NullableEnumLosFieldUpdateOperationsInput>;
  pre_ownership_status?: InputMaybe<NullableEnumLosFieldUpdateOperationsInput>;
  receiver_user?: InputMaybe<UserUpdateOneWithoutReceiver_Assignment_HistoryInput>;
  sender_user?: InputMaybe<UserUpdateOneWithoutSender_Assignment_HistoryInput>;
  sequence_not_interested?: InputMaybe<SequenceUpdateOneWithoutLeadAssignmentHistoryInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type LeadAssignmentHistoryUpdateWithoutReceiver_UserInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  custom_assignment_origin?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inboundConciergeEvent?: InputMaybe<InboundConciergeEventUpdateOneWithoutLead_Assignment_HistoryInput>;
  lead?: InputMaybe<LeadUpdateOneWithoutLead_Assignment_HistoryInput>;
  lead_activity?: InputMaybe<LeadActivityUpdateOneWithoutLead_Assignment_History_ChangeInput>;
  lead_activity_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  lead_assignment_origin?: InputMaybe<EnumAssignmentOriginFieldUpdateOperationsInput>;
  old_user?: InputMaybe<UserUpdateOneWithoutFrom_Assignment_HistoryInput>;
  post_ownership_status?: InputMaybe<NullableEnumLosFieldUpdateOperationsInput>;
  pre_ownership_status?: InputMaybe<NullableEnumLosFieldUpdateOperationsInput>;
  sender_user?: InputMaybe<UserUpdateOneWithoutSender_Assignment_HistoryInput>;
  sequence_not_interested?: InputMaybe<SequenceUpdateOneWithoutLeadAssignmentHistoryInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type LeadAssignmentHistoryUpdateWithoutSender_UserInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  custom_assignment_origin?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inboundConciergeEvent?: InputMaybe<InboundConciergeEventUpdateOneWithoutLead_Assignment_HistoryInput>;
  lead?: InputMaybe<LeadUpdateOneWithoutLead_Assignment_HistoryInput>;
  lead_activity?: InputMaybe<LeadActivityUpdateOneWithoutLead_Assignment_History_ChangeInput>;
  lead_activity_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  lead_assignment_origin?: InputMaybe<EnumAssignmentOriginFieldUpdateOperationsInput>;
  old_user?: InputMaybe<UserUpdateOneWithoutFrom_Assignment_HistoryInput>;
  post_ownership_status?: InputMaybe<NullableEnumLosFieldUpdateOperationsInput>;
  pre_ownership_status?: InputMaybe<NullableEnumLosFieldUpdateOperationsInput>;
  receiver_user?: InputMaybe<UserUpdateOneWithoutReceiver_Assignment_HistoryInput>;
  sequence_not_interested?: InputMaybe<SequenceUpdateOneWithoutLeadAssignmentHistoryInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type LeadAssignmentHistoryUpdateWithoutSequence_Not_InterestedInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  custom_assignment_origin?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inboundConciergeEvent?: InputMaybe<InboundConciergeEventUpdateOneWithoutLead_Assignment_HistoryInput>;
  lead?: InputMaybe<LeadUpdateOneWithoutLead_Assignment_HistoryInput>;
  lead_activity?: InputMaybe<LeadActivityUpdateOneWithoutLead_Assignment_History_ChangeInput>;
  lead_activity_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  lead_assignment_origin?: InputMaybe<EnumAssignmentOriginFieldUpdateOperationsInput>;
  old_user?: InputMaybe<UserUpdateOneWithoutFrom_Assignment_HistoryInput>;
  post_ownership_status?: InputMaybe<NullableEnumLosFieldUpdateOperationsInput>;
  pre_ownership_status?: InputMaybe<NullableEnumLosFieldUpdateOperationsInput>;
  receiver_user?: InputMaybe<UserUpdateOneWithoutReceiver_Assignment_HistoryInput>;
  sender_user?: InputMaybe<UserUpdateOneWithoutSender_Assignment_HistoryInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type LeadAssignmentHistoryUpdateWithWhereUniqueWithoutLeadInput = {
  data: LeadAssignmentHistoryUpdateWithoutLeadInput;
  where: LeadAssignmentHistoryWhereUniqueInput;
};

export type LeadAssignmentHistoryUpdateWithWhereUniqueWithoutOld_UserInput = {
  data: LeadAssignmentHistoryUpdateWithoutOld_UserInput;
  where: LeadAssignmentHistoryWhereUniqueInput;
};

export type LeadAssignmentHistoryUpdateWithWhereUniqueWithoutReceiver_UserInput = {
  data: LeadAssignmentHistoryUpdateWithoutReceiver_UserInput;
  where: LeadAssignmentHistoryWhereUniqueInput;
};

export type LeadAssignmentHistoryUpdateWithWhereUniqueWithoutSender_UserInput = {
  data: LeadAssignmentHistoryUpdateWithoutSender_UserInput;
  where: LeadAssignmentHistoryWhereUniqueInput;
};

export type LeadAssignmentHistoryUpdateWithWhereUniqueWithoutSequence_Not_InterestedInput = {
  data: LeadAssignmentHistoryUpdateWithoutSequence_Not_InterestedInput;
  where: LeadAssignmentHistoryWhereUniqueInput;
};

export type LeadAssignmentHistoryUpsertWithoutInboundConciergeEventInput = {
  create: LeadAssignmentHistoryCreateWithoutInboundConciergeEventInput;
  update: LeadAssignmentHistoryUpdateWithoutInboundConciergeEventInput;
};

export type LeadAssignmentHistoryUpsertWithoutLead_ActivityInput = {
  create: LeadAssignmentHistoryCreateWithoutLead_ActivityInput;
  update: LeadAssignmentHistoryUpdateWithoutLead_ActivityInput;
};

export type LeadAssignmentHistoryUpsertWithWhereUniqueWithoutLeadInput = {
  create: LeadAssignmentHistoryCreateWithoutLeadInput;
  update: LeadAssignmentHistoryUpdateWithoutLeadInput;
  where: LeadAssignmentHistoryWhereUniqueInput;
};

export type LeadAssignmentHistoryUpsertWithWhereUniqueWithoutOld_UserInput = {
  create: LeadAssignmentHistoryCreateWithoutOld_UserInput;
  update: LeadAssignmentHistoryUpdateWithoutOld_UserInput;
  where: LeadAssignmentHistoryWhereUniqueInput;
};

export type LeadAssignmentHistoryUpsertWithWhereUniqueWithoutReceiver_UserInput = {
  create: LeadAssignmentHistoryCreateWithoutReceiver_UserInput;
  update: LeadAssignmentHistoryUpdateWithoutReceiver_UserInput;
  where: LeadAssignmentHistoryWhereUniqueInput;
};

export type LeadAssignmentHistoryUpsertWithWhereUniqueWithoutSender_UserInput = {
  create: LeadAssignmentHistoryCreateWithoutSender_UserInput;
  update: LeadAssignmentHistoryUpdateWithoutSender_UserInput;
  where: LeadAssignmentHistoryWhereUniqueInput;
};

export type LeadAssignmentHistoryUpsertWithWhereUniqueWithoutSequence_Not_InterestedInput = {
  create: LeadAssignmentHistoryCreateWithoutSequence_Not_InterestedInput;
  update: LeadAssignmentHistoryUpdateWithoutSequence_Not_InterestedInput;
  where: LeadAssignmentHistoryWhereUniqueInput;
};

export type LeadAssignmentHistoryWhereInput = {
  AND?: InputMaybe<Array<LeadAssignmentHistoryWhereInput>>;
  created_at?: InputMaybe<DateTimeFilter>;
  custom_assignment_origin?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  inboundConciergeEvent?: InputMaybe<InboundConciergeEventWhereInput>;
  lead?: InputMaybe<LeadWhereInput>;
  lead_activity?: InputMaybe<LeadActivityWhereInput>;
  lead_activity_id?: InputMaybe<StringNullableFilter>;
  lead_assignment_origin?: InputMaybe<EnumAssignmentOriginFilter>;
  lead_id?: InputMaybe<StringNullableFilter>;
  NOT?: InputMaybe<Array<LeadAssignmentHistoryWhereInput>>;
  old_user?: InputMaybe<UserWhereInput>;
  old_user_id?: InputMaybe<StringNullableFilter>;
  OR?: InputMaybe<Array<LeadAssignmentHistoryWhereInput>>;
  post_ownership_status?: InputMaybe<EnumLosNullableFilter>;
  pre_ownership_status?: InputMaybe<EnumLosNullableFilter>;
  receiver_user?: InputMaybe<UserWhereInput>;
  receiver_user_id?: InputMaybe<StringNullableFilter>;
  sender_user?: InputMaybe<UserWhereInput>;
  sender_user_id?: InputMaybe<StringNullableFilter>;
  sequence_not_interested?: InputMaybe<SequenceWhereInput>;
  sequence_not_interested_id?: InputMaybe<StringNullableFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
};

export type LeadAssignmentHistoryWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']['input']>;
};

export type LeadBatchOperationError = {
  __typename?: 'LeadBatchOperationError';
  lead_id?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
};

/** ManualTransferRepData */
export type LeadCallHoldStatus = {
  __typename?: 'LeadCallHoldStatus';
  current_hold_status: Scalars['Boolean']['output'];
};

export type LeadCreateManyAssociate_Parent_LeadInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  address_2?: InputMaybe<Scalars['String']['input']>;
  assignment_locked?: InputMaybe<Scalars['Boolean']['input']>;
  associates_search_content?: InputMaybe<Scalars['String']['input']>;
  business_name?: InputMaybe<Scalars['String']['input']>;
  channel?: InputMaybe<Channel>;
  city?: InputMaybe<Scalars['String']['input']>;
  claim_date?: InputMaybe<Scalars['DateTime']['input']>;
  conference_number?: InputMaybe<Scalars['String']['input']>;
  content?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  current_close_date?: InputMaybe<Scalars['DateTime']['input']>;
  current_contact_date?: InputMaybe<Scalars['DateTime']['input']>;
  custom_field_json?: InputMaybe<Scalars['Json']['input']>;
  delay_action_status?: InputMaybe<Scalars['Boolean']['input']>;
  email_sub_status?: InputMaybe<OptInStatus>;
  first_demo_schedule_date?: InputMaybe<Scalars['DateTime']['input']>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  hubspot_company_id?: InputMaybe<Scalars['String']['input']>;
  hubspot_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  implied_next_action?: InputMaybe<Hingepoint>;
  in_algo_queue?: InputMaybe<Scalars['Boolean']['input']>;
  in_custom_sequence?: InputMaybe<Scalars['Int']['input']>;
  industry?: InputMaybe<Scalars['String']['input']>;
  last_call_result?: InputMaybe<Scalars['String']['input']>;
  last_call_result_rep_id?: InputMaybe<Scalars['String']['input']>;
  last_call_result_time?: InputMaybe<Scalars['DateTime']['input']>;
  last_lead_assignment_origin?: InputMaybe<Scalars['DateTime']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  last_sent_to_resting_date?: InputMaybe<Scalars['DateTime']['input']>;
  lead_assignment_origin?: InputMaybe<AssignmentOrigin>;
  lead_creation_source?: InputMaybe<Leadcreationsource>;
  lead_import_history_id?: InputMaybe<Scalars['String']['input']>;
  lead_ownership_status?: InputMaybe<Los>;
  lead_source?: InputMaybe<Scalars['String']['input']>;
  mrr?: InputMaybe<Scalars['Float']['input']>;
  newIncoming?: InputMaybe<Scalars['Boolean']['input']>;
  next_scheduled_event_id?: InputMaybe<Scalars['String']['input']>;
  opsiq_whitelist?: InputMaybe<Scalars['Boolean']['input']>;
  organization_id: Scalars['String']['input'];
  phase?: InputMaybe<Phase>;
  primary_email?: InputMaybe<Scalars['String']['input']>;
  primary_email_title?: InputMaybe<Scalars['String']['input']>;
  primary_email_type?: InputMaybe<Scalars['String']['input']>;
  primary_phone_number?: InputMaybe<Scalars['String']['input']>;
  primary_phone_number_country_code?: InputMaybe<Scalars['String']['input']>;
  primary_phone_number_title?: InputMaybe<Scalars['String']['input']>;
  primary_phone_number_type?: InputMaybe<Scalars['String']['input']>;
  rep_id?: InputMaybe<Scalars['String']['input']>;
  resting_type?: InputMaybe<Restingtype>;
  routing_rule_id?: InputMaybe<Scalars['String']['input']>;
  saleCycleCycle_lead_id?: InputMaybe<Scalars['String']['input']>;
  saleCycleCycle_num?: InputMaybe<Scalars['Int']['input']>;
  search_content?: InputMaybe<Scalars['String']['input']>;
  self_sourced?: InputMaybe<Scalars['Boolean']['input']>;
  sequence_id?: InputMaybe<Scalars['String']['input']>;
  sequence_step_id?: InputMaybe<Scalars['String']['input']>;
  sequences_completed_ids?: InputMaybe<LeadCreateManysequences_Completed_IdsInput>;
  sequences_joined_ids?: InputMaybe<LeadCreateManysequences_Joined_IdsInput>;
  sf_account_id?: InputMaybe<Scalars['String']['input']>;
  sf_contact_id?: InputMaybe<Scalars['String']['input']>;
  sf_lead_id?: InputMaybe<Scalars['String']['input']>;
  sms_sub_status?: InputMaybe<OptInStatus>;
  state?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  sub_industry?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type LeadCreateManyAssociate_Parent_LeadInputEnvelope = {
  data?: InputMaybe<Array<LeadCreateManyAssociate_Parent_LeadInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type LeadCreateManyLast_Call_Result_RepInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  address_2?: InputMaybe<Scalars['String']['input']>;
  assignment_locked?: InputMaybe<Scalars['Boolean']['input']>;
  associate_parent_id?: InputMaybe<Scalars['String']['input']>;
  associates_search_content?: InputMaybe<Scalars['String']['input']>;
  business_name?: InputMaybe<Scalars['String']['input']>;
  channel?: InputMaybe<Channel>;
  city?: InputMaybe<Scalars['String']['input']>;
  claim_date?: InputMaybe<Scalars['DateTime']['input']>;
  conference_number?: InputMaybe<Scalars['String']['input']>;
  content?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  current_close_date?: InputMaybe<Scalars['DateTime']['input']>;
  current_contact_date?: InputMaybe<Scalars['DateTime']['input']>;
  custom_field_json?: InputMaybe<Scalars['Json']['input']>;
  delay_action_status?: InputMaybe<Scalars['Boolean']['input']>;
  email_sub_status?: InputMaybe<OptInStatus>;
  first_demo_schedule_date?: InputMaybe<Scalars['DateTime']['input']>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  hubspot_company_id?: InputMaybe<Scalars['String']['input']>;
  hubspot_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  implied_next_action?: InputMaybe<Hingepoint>;
  in_algo_queue?: InputMaybe<Scalars['Boolean']['input']>;
  in_custom_sequence?: InputMaybe<Scalars['Int']['input']>;
  industry?: InputMaybe<Scalars['String']['input']>;
  last_call_result?: InputMaybe<Scalars['String']['input']>;
  last_call_result_time?: InputMaybe<Scalars['DateTime']['input']>;
  last_lead_assignment_origin?: InputMaybe<Scalars['DateTime']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  last_sent_to_resting_date?: InputMaybe<Scalars['DateTime']['input']>;
  lead_assignment_origin?: InputMaybe<AssignmentOrigin>;
  lead_creation_source?: InputMaybe<Leadcreationsource>;
  lead_import_history_id?: InputMaybe<Scalars['String']['input']>;
  lead_ownership_status?: InputMaybe<Los>;
  lead_source?: InputMaybe<Scalars['String']['input']>;
  mrr?: InputMaybe<Scalars['Float']['input']>;
  newIncoming?: InputMaybe<Scalars['Boolean']['input']>;
  next_scheduled_event_id?: InputMaybe<Scalars['String']['input']>;
  opsiq_whitelist?: InputMaybe<Scalars['Boolean']['input']>;
  organization_id: Scalars['String']['input'];
  phase?: InputMaybe<Phase>;
  primary_email?: InputMaybe<Scalars['String']['input']>;
  primary_email_title?: InputMaybe<Scalars['String']['input']>;
  primary_email_type?: InputMaybe<Scalars['String']['input']>;
  primary_phone_number?: InputMaybe<Scalars['String']['input']>;
  primary_phone_number_country_code?: InputMaybe<Scalars['String']['input']>;
  primary_phone_number_title?: InputMaybe<Scalars['String']['input']>;
  primary_phone_number_type?: InputMaybe<Scalars['String']['input']>;
  rep_id?: InputMaybe<Scalars['String']['input']>;
  resting_type?: InputMaybe<Restingtype>;
  routing_rule_id?: InputMaybe<Scalars['String']['input']>;
  saleCycleCycle_lead_id?: InputMaybe<Scalars['String']['input']>;
  saleCycleCycle_num?: InputMaybe<Scalars['Int']['input']>;
  search_content?: InputMaybe<Scalars['String']['input']>;
  self_sourced?: InputMaybe<Scalars['Boolean']['input']>;
  sequence_id?: InputMaybe<Scalars['String']['input']>;
  sequence_step_id?: InputMaybe<Scalars['String']['input']>;
  sequences_completed_ids?: InputMaybe<LeadCreateManysequences_Completed_IdsInput>;
  sequences_joined_ids?: InputMaybe<LeadCreateManysequences_Joined_IdsInput>;
  sf_account_id?: InputMaybe<Scalars['String']['input']>;
  sf_contact_id?: InputMaybe<Scalars['String']['input']>;
  sf_lead_id?: InputMaybe<Scalars['String']['input']>;
  sms_sub_status?: InputMaybe<OptInStatus>;
  state?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  sub_industry?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type LeadCreateManyLast_Call_Result_RepInputEnvelope = {
  data?: InputMaybe<Array<LeadCreateManyLast_Call_Result_RepInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type LeadCreateManyLead_Import_History_CsvInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  address_2?: InputMaybe<Scalars['String']['input']>;
  assignment_locked?: InputMaybe<Scalars['Boolean']['input']>;
  associate_parent_id?: InputMaybe<Scalars['String']['input']>;
  associates_search_content?: InputMaybe<Scalars['String']['input']>;
  business_name?: InputMaybe<Scalars['String']['input']>;
  channel?: InputMaybe<Channel>;
  city?: InputMaybe<Scalars['String']['input']>;
  claim_date?: InputMaybe<Scalars['DateTime']['input']>;
  conference_number?: InputMaybe<Scalars['String']['input']>;
  content?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  current_close_date?: InputMaybe<Scalars['DateTime']['input']>;
  current_contact_date?: InputMaybe<Scalars['DateTime']['input']>;
  custom_field_json?: InputMaybe<Scalars['Json']['input']>;
  delay_action_status?: InputMaybe<Scalars['Boolean']['input']>;
  email_sub_status?: InputMaybe<OptInStatus>;
  first_demo_schedule_date?: InputMaybe<Scalars['DateTime']['input']>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  hubspot_company_id?: InputMaybe<Scalars['String']['input']>;
  hubspot_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  implied_next_action?: InputMaybe<Hingepoint>;
  in_algo_queue?: InputMaybe<Scalars['Boolean']['input']>;
  in_custom_sequence?: InputMaybe<Scalars['Int']['input']>;
  industry?: InputMaybe<Scalars['String']['input']>;
  last_call_result?: InputMaybe<Scalars['String']['input']>;
  last_call_result_rep_id?: InputMaybe<Scalars['String']['input']>;
  last_call_result_time?: InputMaybe<Scalars['DateTime']['input']>;
  last_lead_assignment_origin?: InputMaybe<Scalars['DateTime']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  last_sent_to_resting_date?: InputMaybe<Scalars['DateTime']['input']>;
  lead_assignment_origin?: InputMaybe<AssignmentOrigin>;
  lead_creation_source?: InputMaybe<Leadcreationsource>;
  lead_ownership_status?: InputMaybe<Los>;
  lead_source?: InputMaybe<Scalars['String']['input']>;
  mrr?: InputMaybe<Scalars['Float']['input']>;
  newIncoming?: InputMaybe<Scalars['Boolean']['input']>;
  next_scheduled_event_id?: InputMaybe<Scalars['String']['input']>;
  opsiq_whitelist?: InputMaybe<Scalars['Boolean']['input']>;
  organization_id: Scalars['String']['input'];
  phase?: InputMaybe<Phase>;
  primary_email?: InputMaybe<Scalars['String']['input']>;
  primary_email_title?: InputMaybe<Scalars['String']['input']>;
  primary_email_type?: InputMaybe<Scalars['String']['input']>;
  primary_phone_number?: InputMaybe<Scalars['String']['input']>;
  primary_phone_number_country_code?: InputMaybe<Scalars['String']['input']>;
  primary_phone_number_title?: InputMaybe<Scalars['String']['input']>;
  primary_phone_number_type?: InputMaybe<Scalars['String']['input']>;
  rep_id?: InputMaybe<Scalars['String']['input']>;
  resting_type?: InputMaybe<Restingtype>;
  routing_rule_id?: InputMaybe<Scalars['String']['input']>;
  saleCycleCycle_lead_id?: InputMaybe<Scalars['String']['input']>;
  saleCycleCycle_num?: InputMaybe<Scalars['Int']['input']>;
  search_content?: InputMaybe<Scalars['String']['input']>;
  self_sourced?: InputMaybe<Scalars['Boolean']['input']>;
  sequence_id?: InputMaybe<Scalars['String']['input']>;
  sequence_step_id?: InputMaybe<Scalars['String']['input']>;
  sequences_completed_ids?: InputMaybe<LeadCreateManysequences_Completed_IdsInput>;
  sequences_joined_ids?: InputMaybe<LeadCreateManysequences_Joined_IdsInput>;
  sf_account_id?: InputMaybe<Scalars['String']['input']>;
  sf_contact_id?: InputMaybe<Scalars['String']['input']>;
  sf_lead_id?: InputMaybe<Scalars['String']['input']>;
  sms_sub_status?: InputMaybe<OptInStatus>;
  state?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  sub_industry?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type LeadCreateManyLead_Import_History_CsvInputEnvelope = {
  data?: InputMaybe<Array<LeadCreateManyLead_Import_History_CsvInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type LeadCreateManyNext_Scheduled_EventInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  address_2?: InputMaybe<Scalars['String']['input']>;
  assignment_locked?: InputMaybe<Scalars['Boolean']['input']>;
  associate_parent_id?: InputMaybe<Scalars['String']['input']>;
  associates_search_content?: InputMaybe<Scalars['String']['input']>;
  business_name?: InputMaybe<Scalars['String']['input']>;
  channel?: InputMaybe<Channel>;
  city?: InputMaybe<Scalars['String']['input']>;
  claim_date?: InputMaybe<Scalars['DateTime']['input']>;
  conference_number?: InputMaybe<Scalars['String']['input']>;
  content?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  current_close_date?: InputMaybe<Scalars['DateTime']['input']>;
  current_contact_date?: InputMaybe<Scalars['DateTime']['input']>;
  custom_field_json?: InputMaybe<Scalars['Json']['input']>;
  delay_action_status?: InputMaybe<Scalars['Boolean']['input']>;
  email_sub_status?: InputMaybe<OptInStatus>;
  first_demo_schedule_date?: InputMaybe<Scalars['DateTime']['input']>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  hubspot_company_id?: InputMaybe<Scalars['String']['input']>;
  hubspot_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  implied_next_action?: InputMaybe<Hingepoint>;
  in_algo_queue?: InputMaybe<Scalars['Boolean']['input']>;
  in_custom_sequence?: InputMaybe<Scalars['Int']['input']>;
  industry?: InputMaybe<Scalars['String']['input']>;
  last_call_result?: InputMaybe<Scalars['String']['input']>;
  last_call_result_rep_id?: InputMaybe<Scalars['String']['input']>;
  last_call_result_time?: InputMaybe<Scalars['DateTime']['input']>;
  last_lead_assignment_origin?: InputMaybe<Scalars['DateTime']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  last_sent_to_resting_date?: InputMaybe<Scalars['DateTime']['input']>;
  lead_assignment_origin?: InputMaybe<AssignmentOrigin>;
  lead_creation_source?: InputMaybe<Leadcreationsource>;
  lead_import_history_id?: InputMaybe<Scalars['String']['input']>;
  lead_ownership_status?: InputMaybe<Los>;
  lead_source?: InputMaybe<Scalars['String']['input']>;
  mrr?: InputMaybe<Scalars['Float']['input']>;
  newIncoming?: InputMaybe<Scalars['Boolean']['input']>;
  opsiq_whitelist?: InputMaybe<Scalars['Boolean']['input']>;
  organization_id: Scalars['String']['input'];
  phase?: InputMaybe<Phase>;
  primary_email?: InputMaybe<Scalars['String']['input']>;
  primary_email_title?: InputMaybe<Scalars['String']['input']>;
  primary_email_type?: InputMaybe<Scalars['String']['input']>;
  primary_phone_number?: InputMaybe<Scalars['String']['input']>;
  primary_phone_number_country_code?: InputMaybe<Scalars['String']['input']>;
  primary_phone_number_title?: InputMaybe<Scalars['String']['input']>;
  primary_phone_number_type?: InputMaybe<Scalars['String']['input']>;
  rep_id?: InputMaybe<Scalars['String']['input']>;
  resting_type?: InputMaybe<Restingtype>;
  routing_rule_id?: InputMaybe<Scalars['String']['input']>;
  saleCycleCycle_lead_id?: InputMaybe<Scalars['String']['input']>;
  saleCycleCycle_num?: InputMaybe<Scalars['Int']['input']>;
  search_content?: InputMaybe<Scalars['String']['input']>;
  self_sourced?: InputMaybe<Scalars['Boolean']['input']>;
  sequence_id?: InputMaybe<Scalars['String']['input']>;
  sequence_step_id?: InputMaybe<Scalars['String']['input']>;
  sequences_completed_ids?: InputMaybe<LeadCreateManysequences_Completed_IdsInput>;
  sequences_joined_ids?: InputMaybe<LeadCreateManysequences_Joined_IdsInput>;
  sf_account_id?: InputMaybe<Scalars['String']['input']>;
  sf_contact_id?: InputMaybe<Scalars['String']['input']>;
  sf_lead_id?: InputMaybe<Scalars['String']['input']>;
  sms_sub_status?: InputMaybe<OptInStatus>;
  state?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  sub_industry?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type LeadCreateManyNext_Scheduled_EventInputEnvelope = {
  data?: InputMaybe<Array<LeadCreateManyNext_Scheduled_EventInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type LeadCreateManyOrganizationInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  address_2?: InputMaybe<Scalars['String']['input']>;
  assignment_locked?: InputMaybe<Scalars['Boolean']['input']>;
  associate_parent_id?: InputMaybe<Scalars['String']['input']>;
  associates_search_content?: InputMaybe<Scalars['String']['input']>;
  business_name?: InputMaybe<Scalars['String']['input']>;
  channel?: InputMaybe<Channel>;
  city?: InputMaybe<Scalars['String']['input']>;
  claim_date?: InputMaybe<Scalars['DateTime']['input']>;
  conference_number?: InputMaybe<Scalars['String']['input']>;
  content?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  current_close_date?: InputMaybe<Scalars['DateTime']['input']>;
  current_contact_date?: InputMaybe<Scalars['DateTime']['input']>;
  custom_field_json?: InputMaybe<Scalars['Json']['input']>;
  delay_action_status?: InputMaybe<Scalars['Boolean']['input']>;
  email_sub_status?: InputMaybe<OptInStatus>;
  first_demo_schedule_date?: InputMaybe<Scalars['DateTime']['input']>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  hubspot_company_id?: InputMaybe<Scalars['String']['input']>;
  hubspot_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  implied_next_action?: InputMaybe<Hingepoint>;
  in_algo_queue?: InputMaybe<Scalars['Boolean']['input']>;
  in_custom_sequence?: InputMaybe<Scalars['Int']['input']>;
  industry?: InputMaybe<Scalars['String']['input']>;
  last_call_result?: InputMaybe<Scalars['String']['input']>;
  last_call_result_rep_id?: InputMaybe<Scalars['String']['input']>;
  last_call_result_time?: InputMaybe<Scalars['DateTime']['input']>;
  last_lead_assignment_origin?: InputMaybe<Scalars['DateTime']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  last_sent_to_resting_date?: InputMaybe<Scalars['DateTime']['input']>;
  lead_assignment_origin?: InputMaybe<AssignmentOrigin>;
  lead_creation_source?: InputMaybe<Leadcreationsource>;
  lead_import_history_id?: InputMaybe<Scalars['String']['input']>;
  lead_ownership_status?: InputMaybe<Los>;
  lead_source?: InputMaybe<Scalars['String']['input']>;
  mrr?: InputMaybe<Scalars['Float']['input']>;
  newIncoming?: InputMaybe<Scalars['Boolean']['input']>;
  next_scheduled_event_id?: InputMaybe<Scalars['String']['input']>;
  opsiq_whitelist?: InputMaybe<Scalars['Boolean']['input']>;
  phase?: InputMaybe<Phase>;
  primary_email?: InputMaybe<Scalars['String']['input']>;
  primary_email_title?: InputMaybe<Scalars['String']['input']>;
  primary_email_type?: InputMaybe<Scalars['String']['input']>;
  primary_phone_number?: InputMaybe<Scalars['String']['input']>;
  primary_phone_number_country_code?: InputMaybe<Scalars['String']['input']>;
  primary_phone_number_title?: InputMaybe<Scalars['String']['input']>;
  primary_phone_number_type?: InputMaybe<Scalars['String']['input']>;
  rep_id?: InputMaybe<Scalars['String']['input']>;
  resting_type?: InputMaybe<Restingtype>;
  routing_rule_id?: InputMaybe<Scalars['String']['input']>;
  saleCycleCycle_lead_id?: InputMaybe<Scalars['String']['input']>;
  saleCycleCycle_num?: InputMaybe<Scalars['Int']['input']>;
  search_content?: InputMaybe<Scalars['String']['input']>;
  self_sourced?: InputMaybe<Scalars['Boolean']['input']>;
  sequence_id?: InputMaybe<Scalars['String']['input']>;
  sequence_step_id?: InputMaybe<Scalars['String']['input']>;
  sequences_completed_ids?: InputMaybe<LeadCreateManysequences_Completed_IdsInput>;
  sequences_joined_ids?: InputMaybe<LeadCreateManysequences_Joined_IdsInput>;
  sf_account_id?: InputMaybe<Scalars['String']['input']>;
  sf_contact_id?: InputMaybe<Scalars['String']['input']>;
  sf_lead_id?: InputMaybe<Scalars['String']['input']>;
  sms_sub_status?: InputMaybe<OptInStatus>;
  state?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  sub_industry?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type LeadCreateManyOrganizationInputEnvelope = {
  data?: InputMaybe<Array<LeadCreateManyOrganizationInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type LeadCreateManyRepInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  address_2?: InputMaybe<Scalars['String']['input']>;
  assignment_locked?: InputMaybe<Scalars['Boolean']['input']>;
  associate_parent_id?: InputMaybe<Scalars['String']['input']>;
  associates_search_content?: InputMaybe<Scalars['String']['input']>;
  business_name?: InputMaybe<Scalars['String']['input']>;
  channel?: InputMaybe<Channel>;
  city?: InputMaybe<Scalars['String']['input']>;
  claim_date?: InputMaybe<Scalars['DateTime']['input']>;
  conference_number?: InputMaybe<Scalars['String']['input']>;
  content?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  current_close_date?: InputMaybe<Scalars['DateTime']['input']>;
  current_contact_date?: InputMaybe<Scalars['DateTime']['input']>;
  custom_field_json?: InputMaybe<Scalars['Json']['input']>;
  delay_action_status?: InputMaybe<Scalars['Boolean']['input']>;
  email_sub_status?: InputMaybe<OptInStatus>;
  first_demo_schedule_date?: InputMaybe<Scalars['DateTime']['input']>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  hubspot_company_id?: InputMaybe<Scalars['String']['input']>;
  hubspot_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  implied_next_action?: InputMaybe<Hingepoint>;
  in_algo_queue?: InputMaybe<Scalars['Boolean']['input']>;
  in_custom_sequence?: InputMaybe<Scalars['Int']['input']>;
  industry?: InputMaybe<Scalars['String']['input']>;
  last_call_result?: InputMaybe<Scalars['String']['input']>;
  last_call_result_rep_id?: InputMaybe<Scalars['String']['input']>;
  last_call_result_time?: InputMaybe<Scalars['DateTime']['input']>;
  last_lead_assignment_origin?: InputMaybe<Scalars['DateTime']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  last_sent_to_resting_date?: InputMaybe<Scalars['DateTime']['input']>;
  lead_assignment_origin?: InputMaybe<AssignmentOrigin>;
  lead_creation_source?: InputMaybe<Leadcreationsource>;
  lead_import_history_id?: InputMaybe<Scalars['String']['input']>;
  lead_ownership_status?: InputMaybe<Los>;
  lead_source?: InputMaybe<Scalars['String']['input']>;
  mrr?: InputMaybe<Scalars['Float']['input']>;
  newIncoming?: InputMaybe<Scalars['Boolean']['input']>;
  next_scheduled_event_id?: InputMaybe<Scalars['String']['input']>;
  opsiq_whitelist?: InputMaybe<Scalars['Boolean']['input']>;
  organization_id: Scalars['String']['input'];
  phase?: InputMaybe<Phase>;
  primary_email?: InputMaybe<Scalars['String']['input']>;
  primary_email_title?: InputMaybe<Scalars['String']['input']>;
  primary_email_type?: InputMaybe<Scalars['String']['input']>;
  primary_phone_number?: InputMaybe<Scalars['String']['input']>;
  primary_phone_number_country_code?: InputMaybe<Scalars['String']['input']>;
  primary_phone_number_title?: InputMaybe<Scalars['String']['input']>;
  primary_phone_number_type?: InputMaybe<Scalars['String']['input']>;
  resting_type?: InputMaybe<Restingtype>;
  routing_rule_id?: InputMaybe<Scalars['String']['input']>;
  saleCycleCycle_lead_id?: InputMaybe<Scalars['String']['input']>;
  saleCycleCycle_num?: InputMaybe<Scalars['Int']['input']>;
  search_content?: InputMaybe<Scalars['String']['input']>;
  self_sourced?: InputMaybe<Scalars['Boolean']['input']>;
  sequence_id?: InputMaybe<Scalars['String']['input']>;
  sequence_step_id?: InputMaybe<Scalars['String']['input']>;
  sequences_completed_ids?: InputMaybe<LeadCreateManysequences_Completed_IdsInput>;
  sequences_joined_ids?: InputMaybe<LeadCreateManysequences_Joined_IdsInput>;
  sf_account_id?: InputMaybe<Scalars['String']['input']>;
  sf_contact_id?: InputMaybe<Scalars['String']['input']>;
  sf_lead_id?: InputMaybe<Scalars['String']['input']>;
  sms_sub_status?: InputMaybe<OptInStatus>;
  state?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  sub_industry?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type LeadCreateManyRepInputEnvelope = {
  data?: InputMaybe<Array<LeadCreateManyRepInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type LeadCreateManyRouting_RuleInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  address_2?: InputMaybe<Scalars['String']['input']>;
  assignment_locked?: InputMaybe<Scalars['Boolean']['input']>;
  associate_parent_id?: InputMaybe<Scalars['String']['input']>;
  associates_search_content?: InputMaybe<Scalars['String']['input']>;
  business_name?: InputMaybe<Scalars['String']['input']>;
  channel?: InputMaybe<Channel>;
  city?: InputMaybe<Scalars['String']['input']>;
  claim_date?: InputMaybe<Scalars['DateTime']['input']>;
  conference_number?: InputMaybe<Scalars['String']['input']>;
  content?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  current_close_date?: InputMaybe<Scalars['DateTime']['input']>;
  current_contact_date?: InputMaybe<Scalars['DateTime']['input']>;
  custom_field_json?: InputMaybe<Scalars['Json']['input']>;
  delay_action_status?: InputMaybe<Scalars['Boolean']['input']>;
  email_sub_status?: InputMaybe<OptInStatus>;
  first_demo_schedule_date?: InputMaybe<Scalars['DateTime']['input']>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  hubspot_company_id?: InputMaybe<Scalars['String']['input']>;
  hubspot_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  implied_next_action?: InputMaybe<Hingepoint>;
  in_algo_queue?: InputMaybe<Scalars['Boolean']['input']>;
  in_custom_sequence?: InputMaybe<Scalars['Int']['input']>;
  industry?: InputMaybe<Scalars['String']['input']>;
  last_call_result?: InputMaybe<Scalars['String']['input']>;
  last_call_result_rep_id?: InputMaybe<Scalars['String']['input']>;
  last_call_result_time?: InputMaybe<Scalars['DateTime']['input']>;
  last_lead_assignment_origin?: InputMaybe<Scalars['DateTime']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  last_sent_to_resting_date?: InputMaybe<Scalars['DateTime']['input']>;
  lead_assignment_origin?: InputMaybe<AssignmentOrigin>;
  lead_creation_source?: InputMaybe<Leadcreationsource>;
  lead_import_history_id?: InputMaybe<Scalars['String']['input']>;
  lead_ownership_status?: InputMaybe<Los>;
  lead_source?: InputMaybe<Scalars['String']['input']>;
  mrr?: InputMaybe<Scalars['Float']['input']>;
  newIncoming?: InputMaybe<Scalars['Boolean']['input']>;
  next_scheduled_event_id?: InputMaybe<Scalars['String']['input']>;
  opsiq_whitelist?: InputMaybe<Scalars['Boolean']['input']>;
  organization_id: Scalars['String']['input'];
  phase?: InputMaybe<Phase>;
  primary_email?: InputMaybe<Scalars['String']['input']>;
  primary_email_title?: InputMaybe<Scalars['String']['input']>;
  primary_email_type?: InputMaybe<Scalars['String']['input']>;
  primary_phone_number?: InputMaybe<Scalars['String']['input']>;
  primary_phone_number_country_code?: InputMaybe<Scalars['String']['input']>;
  primary_phone_number_title?: InputMaybe<Scalars['String']['input']>;
  primary_phone_number_type?: InputMaybe<Scalars['String']['input']>;
  rep_id?: InputMaybe<Scalars['String']['input']>;
  resting_type?: InputMaybe<Restingtype>;
  saleCycleCycle_lead_id?: InputMaybe<Scalars['String']['input']>;
  saleCycleCycle_num?: InputMaybe<Scalars['Int']['input']>;
  search_content?: InputMaybe<Scalars['String']['input']>;
  self_sourced?: InputMaybe<Scalars['Boolean']['input']>;
  sequence_id?: InputMaybe<Scalars['String']['input']>;
  sequence_step_id?: InputMaybe<Scalars['String']['input']>;
  sequences_completed_ids?: InputMaybe<LeadCreateManysequences_Completed_IdsInput>;
  sequences_joined_ids?: InputMaybe<LeadCreateManysequences_Joined_IdsInput>;
  sf_account_id?: InputMaybe<Scalars['String']['input']>;
  sf_contact_id?: InputMaybe<Scalars['String']['input']>;
  sf_lead_id?: InputMaybe<Scalars['String']['input']>;
  sms_sub_status?: InputMaybe<OptInStatus>;
  state?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  sub_industry?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type LeadCreateManyRouting_RuleInputEnvelope = {
  data?: InputMaybe<Array<LeadCreateManyRouting_RuleInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type LeadCreateManySequence_StepInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  address_2?: InputMaybe<Scalars['String']['input']>;
  assignment_locked?: InputMaybe<Scalars['Boolean']['input']>;
  associate_parent_id?: InputMaybe<Scalars['String']['input']>;
  associates_search_content?: InputMaybe<Scalars['String']['input']>;
  business_name?: InputMaybe<Scalars['String']['input']>;
  channel?: InputMaybe<Channel>;
  city?: InputMaybe<Scalars['String']['input']>;
  claim_date?: InputMaybe<Scalars['DateTime']['input']>;
  conference_number?: InputMaybe<Scalars['String']['input']>;
  content?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  current_close_date?: InputMaybe<Scalars['DateTime']['input']>;
  current_contact_date?: InputMaybe<Scalars['DateTime']['input']>;
  custom_field_json?: InputMaybe<Scalars['Json']['input']>;
  delay_action_status?: InputMaybe<Scalars['Boolean']['input']>;
  email_sub_status?: InputMaybe<OptInStatus>;
  first_demo_schedule_date?: InputMaybe<Scalars['DateTime']['input']>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  hubspot_company_id?: InputMaybe<Scalars['String']['input']>;
  hubspot_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  implied_next_action?: InputMaybe<Hingepoint>;
  in_algo_queue?: InputMaybe<Scalars['Boolean']['input']>;
  in_custom_sequence?: InputMaybe<Scalars['Int']['input']>;
  industry?: InputMaybe<Scalars['String']['input']>;
  last_call_result?: InputMaybe<Scalars['String']['input']>;
  last_call_result_rep_id?: InputMaybe<Scalars['String']['input']>;
  last_call_result_time?: InputMaybe<Scalars['DateTime']['input']>;
  last_lead_assignment_origin?: InputMaybe<Scalars['DateTime']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  last_sent_to_resting_date?: InputMaybe<Scalars['DateTime']['input']>;
  lead_assignment_origin?: InputMaybe<AssignmentOrigin>;
  lead_creation_source?: InputMaybe<Leadcreationsource>;
  lead_import_history_id?: InputMaybe<Scalars['String']['input']>;
  lead_ownership_status?: InputMaybe<Los>;
  lead_source?: InputMaybe<Scalars['String']['input']>;
  mrr?: InputMaybe<Scalars['Float']['input']>;
  newIncoming?: InputMaybe<Scalars['Boolean']['input']>;
  next_scheduled_event_id?: InputMaybe<Scalars['String']['input']>;
  opsiq_whitelist?: InputMaybe<Scalars['Boolean']['input']>;
  organization_id: Scalars['String']['input'];
  phase?: InputMaybe<Phase>;
  primary_email?: InputMaybe<Scalars['String']['input']>;
  primary_email_title?: InputMaybe<Scalars['String']['input']>;
  primary_email_type?: InputMaybe<Scalars['String']['input']>;
  primary_phone_number?: InputMaybe<Scalars['String']['input']>;
  primary_phone_number_country_code?: InputMaybe<Scalars['String']['input']>;
  primary_phone_number_title?: InputMaybe<Scalars['String']['input']>;
  primary_phone_number_type?: InputMaybe<Scalars['String']['input']>;
  rep_id?: InputMaybe<Scalars['String']['input']>;
  resting_type?: InputMaybe<Restingtype>;
  routing_rule_id?: InputMaybe<Scalars['String']['input']>;
  saleCycleCycle_lead_id?: InputMaybe<Scalars['String']['input']>;
  saleCycleCycle_num?: InputMaybe<Scalars['Int']['input']>;
  search_content?: InputMaybe<Scalars['String']['input']>;
  self_sourced?: InputMaybe<Scalars['Boolean']['input']>;
  sequence_id?: InputMaybe<Scalars['String']['input']>;
  sequences_completed_ids?: InputMaybe<LeadCreateManysequences_Completed_IdsInput>;
  sequences_joined_ids?: InputMaybe<LeadCreateManysequences_Joined_IdsInput>;
  sf_account_id?: InputMaybe<Scalars['String']['input']>;
  sf_contact_id?: InputMaybe<Scalars['String']['input']>;
  sf_lead_id?: InputMaybe<Scalars['String']['input']>;
  sms_sub_status?: InputMaybe<OptInStatus>;
  state?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  sub_industry?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type LeadCreateManySequence_StepInputEnvelope = {
  data?: InputMaybe<Array<LeadCreateManySequence_StepInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type LeadCreateManySequenceInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  address_2?: InputMaybe<Scalars['String']['input']>;
  assignment_locked?: InputMaybe<Scalars['Boolean']['input']>;
  associate_parent_id?: InputMaybe<Scalars['String']['input']>;
  associates_search_content?: InputMaybe<Scalars['String']['input']>;
  business_name?: InputMaybe<Scalars['String']['input']>;
  channel?: InputMaybe<Channel>;
  city?: InputMaybe<Scalars['String']['input']>;
  claim_date?: InputMaybe<Scalars['DateTime']['input']>;
  conference_number?: InputMaybe<Scalars['String']['input']>;
  content?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  current_close_date?: InputMaybe<Scalars['DateTime']['input']>;
  current_contact_date?: InputMaybe<Scalars['DateTime']['input']>;
  custom_field_json?: InputMaybe<Scalars['Json']['input']>;
  delay_action_status?: InputMaybe<Scalars['Boolean']['input']>;
  email_sub_status?: InputMaybe<OptInStatus>;
  first_demo_schedule_date?: InputMaybe<Scalars['DateTime']['input']>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  hubspot_company_id?: InputMaybe<Scalars['String']['input']>;
  hubspot_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  implied_next_action?: InputMaybe<Hingepoint>;
  in_algo_queue?: InputMaybe<Scalars['Boolean']['input']>;
  in_custom_sequence?: InputMaybe<Scalars['Int']['input']>;
  industry?: InputMaybe<Scalars['String']['input']>;
  last_call_result?: InputMaybe<Scalars['String']['input']>;
  last_call_result_rep_id?: InputMaybe<Scalars['String']['input']>;
  last_call_result_time?: InputMaybe<Scalars['DateTime']['input']>;
  last_lead_assignment_origin?: InputMaybe<Scalars['DateTime']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  last_sent_to_resting_date?: InputMaybe<Scalars['DateTime']['input']>;
  lead_assignment_origin?: InputMaybe<AssignmentOrigin>;
  lead_creation_source?: InputMaybe<Leadcreationsource>;
  lead_import_history_id?: InputMaybe<Scalars['String']['input']>;
  lead_ownership_status?: InputMaybe<Los>;
  lead_source?: InputMaybe<Scalars['String']['input']>;
  mrr?: InputMaybe<Scalars['Float']['input']>;
  newIncoming?: InputMaybe<Scalars['Boolean']['input']>;
  next_scheduled_event_id?: InputMaybe<Scalars['String']['input']>;
  opsiq_whitelist?: InputMaybe<Scalars['Boolean']['input']>;
  organization_id: Scalars['String']['input'];
  phase?: InputMaybe<Phase>;
  primary_email?: InputMaybe<Scalars['String']['input']>;
  primary_email_title?: InputMaybe<Scalars['String']['input']>;
  primary_email_type?: InputMaybe<Scalars['String']['input']>;
  primary_phone_number?: InputMaybe<Scalars['String']['input']>;
  primary_phone_number_country_code?: InputMaybe<Scalars['String']['input']>;
  primary_phone_number_title?: InputMaybe<Scalars['String']['input']>;
  primary_phone_number_type?: InputMaybe<Scalars['String']['input']>;
  rep_id?: InputMaybe<Scalars['String']['input']>;
  resting_type?: InputMaybe<Restingtype>;
  routing_rule_id?: InputMaybe<Scalars['String']['input']>;
  saleCycleCycle_lead_id?: InputMaybe<Scalars['String']['input']>;
  saleCycleCycle_num?: InputMaybe<Scalars['Int']['input']>;
  search_content?: InputMaybe<Scalars['String']['input']>;
  self_sourced?: InputMaybe<Scalars['Boolean']['input']>;
  sequence_step_id?: InputMaybe<Scalars['String']['input']>;
  sequences_completed_ids?: InputMaybe<LeadCreateManysequences_Completed_IdsInput>;
  sequences_joined_ids?: InputMaybe<LeadCreateManysequences_Joined_IdsInput>;
  sf_account_id?: InputMaybe<Scalars['String']['input']>;
  sf_contact_id?: InputMaybe<Scalars['String']['input']>;
  sf_lead_id?: InputMaybe<Scalars['String']['input']>;
  sms_sub_status?: InputMaybe<OptInStatus>;
  state?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  sub_industry?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type LeadCreateManySequenceInputEnvelope = {
  data?: InputMaybe<Array<LeadCreateManySequenceInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type LeadCreateManysequences_Completed_IdsInput = {
  set?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type LeadCreateManysequences_Joined_IdsInput = {
  set?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type LeadCreateNestedManyWithoutAssociate_Group_Lead_ActivitiesInput = {
  connect?: InputMaybe<Array<LeadWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<LeadCreateOrConnectWithoutAssociate_Group_Lead_ActivitiesInput>>;
  create?: InputMaybe<Array<LeadCreateWithoutAssociate_Group_Lead_ActivitiesInput>>;
};

export type LeadCreateNestedManyWithoutAssociate_Parent_LeadInput = {
  connect?: InputMaybe<Array<LeadWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<LeadCreateOrConnectWithoutAssociate_Parent_LeadInput>>;
  create?: InputMaybe<Array<LeadCreateWithoutAssociate_Parent_LeadInput>>;
  createMany?: InputMaybe<LeadCreateManyAssociate_Parent_LeadInputEnvelope>;
};

export type LeadCreateNestedManyWithoutFavorited_By_UsersInput = {
  connect?: InputMaybe<Array<LeadWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<LeadCreateOrConnectWithoutFavorited_By_UsersInput>>;
  create?: InputMaybe<Array<LeadCreateWithoutFavorited_By_UsersInput>>;
};

export type LeadCreateNestedManyWithoutLast_Call_Result_RepInput = {
  connect?: InputMaybe<Array<LeadWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<LeadCreateOrConnectWithoutLast_Call_Result_RepInput>>;
  create?: InputMaybe<Array<LeadCreateWithoutLast_Call_Result_RepInput>>;
  createMany?: InputMaybe<LeadCreateManyLast_Call_Result_RepInputEnvelope>;
};

export type LeadCreateNestedManyWithoutLead_Import_History_CsvInput = {
  connect?: InputMaybe<Array<LeadWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<LeadCreateOrConnectWithoutLead_Import_History_CsvInput>>;
  create?: InputMaybe<Array<LeadCreateWithoutLead_Import_History_CsvInput>>;
  createMany?: InputMaybe<LeadCreateManyLead_Import_History_CsvInputEnvelope>;
};

export type LeadCreateNestedManyWithoutNext_Scheduled_EventInput = {
  connect?: InputMaybe<Array<LeadWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<LeadCreateOrConnectWithoutNext_Scheduled_EventInput>>;
  create?: InputMaybe<Array<LeadCreateWithoutNext_Scheduled_EventInput>>;
  createMany?: InputMaybe<LeadCreateManyNext_Scheduled_EventInputEnvelope>;
};

export type LeadCreateNestedManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<LeadWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<LeadCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<LeadCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<LeadCreateManyOrganizationInputEnvelope>;
};

export type LeadCreateNestedManyWithoutRepInput = {
  connect?: InputMaybe<Array<LeadWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<LeadCreateOrConnectWithoutRepInput>>;
  create?: InputMaybe<Array<LeadCreateWithoutRepInput>>;
  createMany?: InputMaybe<LeadCreateManyRepInputEnvelope>;
};

export type LeadCreateNestedManyWithoutRouting_RuleInput = {
  connect?: InputMaybe<Array<LeadWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<LeadCreateOrConnectWithoutRouting_RuleInput>>;
  create?: InputMaybe<Array<LeadCreateWithoutRouting_RuleInput>>;
  createMany?: InputMaybe<LeadCreateManyRouting_RuleInputEnvelope>;
};

export type LeadCreateNestedManyWithoutSecondary_RepsInput = {
  connect?: InputMaybe<Array<LeadWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<LeadCreateOrConnectWithoutSecondary_RepsInput>>;
  create?: InputMaybe<Array<LeadCreateWithoutSecondary_RepsInput>>;
};

export type LeadCreateNestedManyWithoutSequence_StepInput = {
  connect?: InputMaybe<Array<LeadWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<LeadCreateOrConnectWithoutSequence_StepInput>>;
  create?: InputMaybe<Array<LeadCreateWithoutSequence_StepInput>>;
  createMany?: InputMaybe<LeadCreateManySequence_StepInputEnvelope>;
};

export type LeadCreateNestedManyWithoutSequenceInput = {
  connect?: InputMaybe<Array<LeadWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<LeadCreateOrConnectWithoutSequenceInput>>;
  create?: InputMaybe<Array<LeadCreateWithoutSequenceInput>>;
  createMany?: InputMaybe<LeadCreateManySequenceInputEnvelope>;
};

export type LeadCreateNestedManyWithoutSibling_Lead_HistoriesInput = {
  connect?: InputMaybe<Array<LeadWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<LeadCreateOrConnectWithoutSibling_Lead_HistoriesInput>>;
  create?: InputMaybe<Array<LeadCreateWithoutSibling_Lead_HistoriesInput>>;
};

export type LeadCreateNestedOneWithoutAlternate_ContactsInput = {
  connect?: InputMaybe<LeadWhereUniqueInput>;
  connectOrCreate?: InputMaybe<LeadCreateOrConnectWithoutAlternate_ContactsInput>;
  create?: InputMaybe<LeadCreateWithoutAlternate_ContactsInput>;
};

export type LeadCreateNestedOneWithoutAssociate_ChildrenInput = {
  connect?: InputMaybe<LeadWhereUniqueInput>;
  connectOrCreate?: InputMaybe<LeadCreateOrConnectWithoutAssociate_ChildrenInput>;
  create?: InputMaybe<LeadCreateWithoutAssociate_ChildrenInput>;
};

export type LeadCreateNestedOneWithoutAssociate_Lead_HistoriesInput = {
  connect?: InputMaybe<LeadWhereUniqueInput>;
  connectOrCreate?: InputMaybe<LeadCreateOrConnectWithoutAssociate_Lead_HistoriesInput>;
  create?: InputMaybe<LeadCreateWithoutAssociate_Lead_HistoriesInput>;
};

export type LeadCreateNestedOneWithoutAssociated_Parent_IntentsInput = {
  connect?: InputMaybe<LeadWhereUniqueInput>;
  connectOrCreate?: InputMaybe<LeadCreateOrConnectWithoutAssociated_Parent_IntentsInput>;
  create?: InputMaybe<LeadCreateWithoutAssociated_Parent_IntentsInput>;
};

export type LeadCreateNestedOneWithoutCallMeNowAttemptsInput = {
  connect?: InputMaybe<LeadWhereUniqueInput>;
  connectOrCreate?: InputMaybe<LeadCreateOrConnectWithoutCallMeNowAttemptsInput>;
  create?: InputMaybe<LeadCreateWithoutCallMeNowAttemptsInput>;
};

export type LeadCreateNestedOneWithoutCallMeNowsInput = {
  connect?: InputMaybe<LeadWhereUniqueInput>;
  connectOrCreate?: InputMaybe<LeadCreateOrConnectWithoutCallMeNowsInput>;
  create?: InputMaybe<LeadCreateWithoutCallMeNowsInput>;
};

export type LeadCreateNestedOneWithoutChild_Lead_ActivitesInput = {
  connect?: InputMaybe<LeadWhereUniqueInput>;
  connectOrCreate?: InputMaybe<LeadCreateOrConnectWithoutChild_Lead_ActivitesInput>;
  create?: InputMaybe<LeadCreateWithoutChild_Lead_ActivitesInput>;
};

export type LeadCreateNestedOneWithoutContact_RequestsInput = {
  connect?: InputMaybe<LeadWhereUniqueInput>;
  connectOrCreate?: InputMaybe<LeadCreateOrConnectWithoutContact_RequestsInput>;
  create?: InputMaybe<LeadCreateWithoutContact_RequestsInput>;
};

export type LeadCreateNestedOneWithoutCurrent_Sale_CycleInput = {
  connect?: InputMaybe<LeadWhereUniqueInput>;
  connectOrCreate?: InputMaybe<LeadCreateOrConnectWithoutCurrent_Sale_CycleInput>;
  create?: InputMaybe<LeadCreateWithoutCurrent_Sale_CycleInput>;
};

export type LeadCreateNestedOneWithoutCustom_FieldsInput = {
  connect?: InputMaybe<LeadWhereUniqueInput>;
  connectOrCreate?: InputMaybe<LeadCreateOrConnectWithoutCustom_FieldsInput>;
  create?: InputMaybe<LeadCreateWithoutCustom_FieldsInput>;
};

export type LeadCreateNestedOneWithoutCustom_Queue_ItemsInput = {
  connect?: InputMaybe<LeadWhereUniqueInput>;
  connectOrCreate?: InputMaybe<LeadCreateOrConnectWithoutCustom_Queue_ItemsInput>;
  create?: InputMaybe<LeadCreateWithoutCustom_Queue_ItemsInput>;
};

export type LeadCreateNestedOneWithoutEmailSyncRecordInput = {
  connect?: InputMaybe<LeadWhereUniqueInput>;
  connectOrCreate?: InputMaybe<LeadCreateOrConnectWithoutEmailSyncRecordInput>;
  create?: InputMaybe<LeadCreateWithoutEmailSyncRecordInput>;
};

export type LeadCreateNestedOneWithoutEmailThreadInput = {
  connect?: InputMaybe<LeadWhereUniqueInput>;
  connectOrCreate?: InputMaybe<LeadCreateOrConnectWithoutEmailThreadInput>;
  create?: InputMaybe<LeadCreateWithoutEmailThreadInput>;
};

export type LeadCreateNestedOneWithoutInboundConciergeEventsInput = {
  connect?: InputMaybe<LeadWhereUniqueInput>;
  connectOrCreate?: InputMaybe<LeadCreateOrConnectWithoutInboundConciergeEventsInput>;
  create?: InputMaybe<LeadCreateWithoutInboundConciergeEventsInput>;
};

export type LeadCreateNestedOneWithoutIntegrationOperationLogInput = {
  connect?: InputMaybe<LeadWhereUniqueInput>;
  connectOrCreate?: InputMaybe<LeadCreateOrConnectWithoutIntegrationOperationLogInput>;
  create?: InputMaybe<LeadCreateWithoutIntegrationOperationLogInput>;
};

export type LeadCreateNestedOneWithoutLead_ActivitiesInput = {
  connect?: InputMaybe<LeadWhereUniqueInput>;
  connectOrCreate?: InputMaybe<LeadCreateOrConnectWithoutLead_ActivitiesInput>;
  create?: InputMaybe<LeadCreateWithoutLead_ActivitiesInput>;
};

export type LeadCreateNestedOneWithoutLead_Assignment_HistoryInput = {
  connect?: InputMaybe<LeadWhereUniqueInput>;
  connectOrCreate?: InputMaybe<LeadCreateOrConnectWithoutLead_Assignment_HistoryInput>;
  create?: InputMaybe<LeadCreateWithoutLead_Assignment_HistoryInput>;
};

export type LeadCreateNestedOneWithoutLead_Custom_Row_InfoInput = {
  connect?: InputMaybe<LeadWhereUniqueInput>;
  connectOrCreate?: InputMaybe<LeadCreateOrConnectWithoutLead_Custom_Row_InfoInput>;
  create?: InputMaybe<LeadCreateWithoutLead_Custom_Row_InfoInput>;
};

export type LeadCreateNestedOneWithoutLead_Import_HistoryInput = {
  connect?: InputMaybe<LeadWhereUniqueInput>;
  connectOrCreate?: InputMaybe<LeadCreateOrConnectWithoutLead_Import_HistoryInput>;
  create?: InputMaybe<LeadCreateWithoutLead_Import_HistoryInput>;
};

export type LeadCreateNestedOneWithoutLead_IntentInput = {
  connect?: InputMaybe<LeadWhereUniqueInput>;
  connectOrCreate?: InputMaybe<LeadCreateOrConnectWithoutLead_IntentInput>;
  create?: InputMaybe<LeadCreateWithoutLead_IntentInput>;
};

export type LeadCreateNestedOneWithoutLead_ValueInput = {
  connect?: InputMaybe<LeadWhereUniqueInput>;
  connectOrCreate?: InputMaybe<LeadCreateOrConnectWithoutLead_ValueInput>;
  create?: InputMaybe<LeadCreateWithoutLead_ValueInput>;
};

export type LeadCreateNestedOneWithoutNotificationsInput = {
  connect?: InputMaybe<LeadWhereUniqueInput>;
  connectOrCreate?: InputMaybe<LeadCreateOrConnectWithoutNotificationsInput>;
  create?: InputMaybe<LeadCreateWithoutNotificationsInput>;
};

export type LeadCreateNestedOneWithoutPrimary_Lead_HistoriesInput = {
  connect?: InputMaybe<LeadWhereUniqueInput>;
  connectOrCreate?: InputMaybe<LeadCreateOrConnectWithoutPrimary_Lead_HistoriesInput>;
  create?: InputMaybe<LeadCreateWithoutPrimary_Lead_HistoriesInput>;
};

export type LeadCreateNestedOneWithoutRouting_HistoryInput = {
  connect?: InputMaybe<LeadWhereUniqueInput>;
  connectOrCreate?: InputMaybe<LeadCreateOrConnectWithoutRouting_HistoryInput>;
  create?: InputMaybe<LeadCreateWithoutRouting_HistoryInput>;
};

export type LeadCreateNestedOneWithoutSale_CyclesInput = {
  connect?: InputMaybe<LeadWhereUniqueInput>;
  connectOrCreate?: InputMaybe<LeadCreateOrConnectWithoutSale_CyclesInput>;
  create?: InputMaybe<LeadCreateWithoutSale_CyclesInput>;
};

export type LeadCreateNestedOneWithoutSaleInput = {
  connect?: InputMaybe<LeadWhereUniqueInput>;
  connectOrCreate?: InputMaybe<LeadCreateOrConnectWithoutSaleInput>;
  create?: InputMaybe<LeadCreateWithoutSaleInput>;
};

export type LeadCreateNestedOneWithoutSequenceActionLogInput = {
  connect?: InputMaybe<LeadWhereUniqueInput>;
  connectOrCreate?: InputMaybe<LeadCreateOrConnectWithoutSequenceActionLogInput>;
  create?: InputMaybe<LeadCreateWithoutSequenceActionLogInput>;
};

export type LeadCreateNestedOneWithoutSequenceEntryExitLeadInput = {
  connect?: InputMaybe<LeadWhereUniqueInput>;
  connectOrCreate?: InputMaybe<LeadCreateOrConnectWithoutSequenceEntryExitLeadInput>;
  create?: InputMaybe<LeadCreateWithoutSequenceEntryExitLeadInput>;
};

export type LeadCreateNestedOneWithoutStat_ItemsInput = {
  connect?: InputMaybe<LeadWhereUniqueInput>;
  connectOrCreate?: InputMaybe<LeadCreateOrConnectWithoutStat_ItemsInput>;
  create?: InputMaybe<LeadCreateWithoutStat_ItemsInput>;
};

export type LeadCreateNestedOneWithoutStatLeadAggregationInput = {
  connect?: InputMaybe<LeadWhereUniqueInput>;
  connectOrCreate?: InputMaybe<LeadCreateOrConnectWithoutStatLeadAggregationInput>;
  create?: InputMaybe<LeadCreateWithoutStatLeadAggregationInput>;
};

export type LeadCreateOrConnectWithoutAlternate_ContactsInput = {
  create: LeadCreateWithoutAlternate_ContactsInput;
  where: LeadWhereUniqueInput;
};

export type LeadCreateOrConnectWithoutAssociate_ChildrenInput = {
  create: LeadCreateWithoutAssociate_ChildrenInput;
  where: LeadWhereUniqueInput;
};

export type LeadCreateOrConnectWithoutAssociate_Group_Lead_ActivitiesInput = {
  create: LeadCreateWithoutAssociate_Group_Lead_ActivitiesInput;
  where: LeadWhereUniqueInput;
};

export type LeadCreateOrConnectWithoutAssociate_Lead_HistoriesInput = {
  create: LeadCreateWithoutAssociate_Lead_HistoriesInput;
  where: LeadWhereUniqueInput;
};

export type LeadCreateOrConnectWithoutAssociate_Parent_LeadInput = {
  create: LeadCreateWithoutAssociate_Parent_LeadInput;
  where: LeadWhereUniqueInput;
};

export type LeadCreateOrConnectWithoutAssociated_Parent_IntentsInput = {
  create: LeadCreateWithoutAssociated_Parent_IntentsInput;
  where: LeadWhereUniqueInput;
};

export type LeadCreateOrConnectWithoutCallMeNowAttemptsInput = {
  create: LeadCreateWithoutCallMeNowAttemptsInput;
  where: LeadWhereUniqueInput;
};

export type LeadCreateOrConnectWithoutCallMeNowsInput = {
  create: LeadCreateWithoutCallMeNowsInput;
  where: LeadWhereUniqueInput;
};

export type LeadCreateOrConnectWithoutChild_Lead_ActivitesInput = {
  create: LeadCreateWithoutChild_Lead_ActivitesInput;
  where: LeadWhereUniqueInput;
};

export type LeadCreateOrConnectWithoutContact_RequestsInput = {
  create: LeadCreateWithoutContact_RequestsInput;
  where: LeadWhereUniqueInput;
};

export type LeadCreateOrConnectWithoutCurrent_Sale_CycleInput = {
  create: LeadCreateWithoutCurrent_Sale_CycleInput;
  where: LeadWhereUniqueInput;
};

export type LeadCreateOrConnectWithoutCustom_FieldsInput = {
  create: LeadCreateWithoutCustom_FieldsInput;
  where: LeadWhereUniqueInput;
};

export type LeadCreateOrConnectWithoutCustom_Queue_ItemsInput = {
  create: LeadCreateWithoutCustom_Queue_ItemsInput;
  where: LeadWhereUniqueInput;
};

export type LeadCreateOrConnectWithoutEmailSyncRecordInput = {
  create: LeadCreateWithoutEmailSyncRecordInput;
  where: LeadWhereUniqueInput;
};

export type LeadCreateOrConnectWithoutEmailThreadInput = {
  create: LeadCreateWithoutEmailThreadInput;
  where: LeadWhereUniqueInput;
};

export type LeadCreateOrConnectWithoutFavorited_By_UsersInput = {
  create: LeadCreateWithoutFavorited_By_UsersInput;
  where: LeadWhereUniqueInput;
};

export type LeadCreateOrConnectWithoutInboundConciergeEventsInput = {
  create: LeadCreateWithoutInboundConciergeEventsInput;
  where: LeadWhereUniqueInput;
};

export type LeadCreateOrConnectWithoutIntegrationOperationLogInput = {
  create: LeadCreateWithoutIntegrationOperationLogInput;
  where: LeadWhereUniqueInput;
};

export type LeadCreateOrConnectWithoutLast_Call_Result_RepInput = {
  create: LeadCreateWithoutLast_Call_Result_RepInput;
  where: LeadWhereUniqueInput;
};

export type LeadCreateOrConnectWithoutLead_ActivitiesInput = {
  create: LeadCreateWithoutLead_ActivitiesInput;
  where: LeadWhereUniqueInput;
};

export type LeadCreateOrConnectWithoutLead_Assignment_HistoryInput = {
  create: LeadCreateWithoutLead_Assignment_HistoryInput;
  where: LeadWhereUniqueInput;
};

export type LeadCreateOrConnectWithoutLead_Custom_Row_InfoInput = {
  create: LeadCreateWithoutLead_Custom_Row_InfoInput;
  where: LeadWhereUniqueInput;
};

export type LeadCreateOrConnectWithoutLead_Import_History_CsvInput = {
  create: LeadCreateWithoutLead_Import_History_CsvInput;
  where: LeadWhereUniqueInput;
};

export type LeadCreateOrConnectWithoutLead_Import_HistoryInput = {
  create: LeadCreateWithoutLead_Import_HistoryInput;
  where: LeadWhereUniqueInput;
};

export type LeadCreateOrConnectWithoutLead_IntentInput = {
  create: LeadCreateWithoutLead_IntentInput;
  where: LeadWhereUniqueInput;
};

export type LeadCreateOrConnectWithoutLead_ValueInput = {
  create: LeadCreateWithoutLead_ValueInput;
  where: LeadWhereUniqueInput;
};

export type LeadCreateOrConnectWithoutNext_Scheduled_EventInput = {
  create: LeadCreateWithoutNext_Scheduled_EventInput;
  where: LeadWhereUniqueInput;
};

export type LeadCreateOrConnectWithoutNotificationsInput = {
  create: LeadCreateWithoutNotificationsInput;
  where: LeadWhereUniqueInput;
};

export type LeadCreateOrConnectWithoutOrganizationInput = {
  create: LeadCreateWithoutOrganizationInput;
  where: LeadWhereUniqueInput;
};

export type LeadCreateOrConnectWithoutPrimary_Lead_HistoriesInput = {
  create: LeadCreateWithoutPrimary_Lead_HistoriesInput;
  where: LeadWhereUniqueInput;
};

export type LeadCreateOrConnectWithoutRepInput = {
  create: LeadCreateWithoutRepInput;
  where: LeadWhereUniqueInput;
};

export type LeadCreateOrConnectWithoutRouting_HistoryInput = {
  create: LeadCreateWithoutRouting_HistoryInput;
  where: LeadWhereUniqueInput;
};

export type LeadCreateOrConnectWithoutRouting_RuleInput = {
  create: LeadCreateWithoutRouting_RuleInput;
  where: LeadWhereUniqueInput;
};

export type LeadCreateOrConnectWithoutSale_CyclesInput = {
  create: LeadCreateWithoutSale_CyclesInput;
  where: LeadWhereUniqueInput;
};

export type LeadCreateOrConnectWithoutSaleInput = {
  create: LeadCreateWithoutSaleInput;
  where: LeadWhereUniqueInput;
};

export type LeadCreateOrConnectWithoutSecondary_RepsInput = {
  create: LeadCreateWithoutSecondary_RepsInput;
  where: LeadWhereUniqueInput;
};

export type LeadCreateOrConnectWithoutSequence_StepInput = {
  create: LeadCreateWithoutSequence_StepInput;
  where: LeadWhereUniqueInput;
};

export type LeadCreateOrConnectWithoutSequenceActionLogInput = {
  create: LeadCreateWithoutSequenceActionLogInput;
  where: LeadWhereUniqueInput;
};

export type LeadCreateOrConnectWithoutSequenceEntryExitLeadInput = {
  create: LeadCreateWithoutSequenceEntryExitLeadInput;
  where: LeadWhereUniqueInput;
};

export type LeadCreateOrConnectWithoutSequenceInput = {
  create: LeadCreateWithoutSequenceInput;
  where: LeadWhereUniqueInput;
};

export type LeadCreateOrConnectWithoutSibling_Lead_HistoriesInput = {
  create: LeadCreateWithoutSibling_Lead_HistoriesInput;
  where: LeadWhereUniqueInput;
};

export type LeadCreateOrConnectWithoutStat_ItemsInput = {
  create: LeadCreateWithoutStat_ItemsInput;
  where: LeadWhereUniqueInput;
};

export type LeadCreateOrConnectWithoutStatLeadAggregationInput = {
  create: LeadCreateWithoutStatLeadAggregationInput;
  where: LeadWhereUniqueInput;
};

export type LeadCreatesequences_Completed_IdsInput = {
  set?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type LeadCreatesequences_Joined_IdsInput = {
  set?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type LeadCreateWithoutAlternate_ContactsInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  address_2?: InputMaybe<Scalars['String']['input']>;
  assignment_locked?: InputMaybe<Scalars['Boolean']['input']>;
  associate_children?: InputMaybe<LeadCreateNestedManyWithoutAssociate_Parent_LeadInput>;
  associate_group_lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutAssociate_Group_LeadsInput>;
  associate_lead_histories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutAssociate_LeadInput>;
  associate_parent_lead?: InputMaybe<LeadCreateNestedOneWithoutAssociate_ChildrenInput>;
  associated_parent_intents?: InputMaybe<LeadIntentCreateNestedManyWithoutAssociate_ParentInput>;
  associates_search_content?: InputMaybe<Scalars['String']['input']>;
  business_name?: InputMaybe<Scalars['String']['input']>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptCreateNestedManyWithoutLeadInput>;
  callMeNows?: InputMaybe<CallMeNowCreateNestedManyWithoutLeadInput>;
  channel?: InputMaybe<Channel>;
  child_lead_activites?: InputMaybe<LeadActivityCreateNestedManyWithoutAssociate_ChildInput>;
  city?: InputMaybe<Scalars['String']['input']>;
  claim_date?: InputMaybe<Scalars['DateTime']['input']>;
  conference_number?: InputMaybe<Scalars['String']['input']>;
  contact_requests?: InputMaybe<ContactRequestCreateNestedManyWithoutLeadInput>;
  content?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  current_close_date?: InputMaybe<Scalars['DateTime']['input']>;
  current_contact_date?: InputMaybe<Scalars['DateTime']['input']>;
  current_sale_cycle?: InputMaybe<SaleCycleCreateNestedOneWithoutLead_If_CurrentInput>;
  custom_field_json?: InputMaybe<Scalars['Json']['input']>;
  custom_fields?: InputMaybe<CustomFieldCreateNestedManyWithoutLeadInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemCreateNestedManyWithoutLeadInput>;
  delay_action_status?: InputMaybe<Scalars['Boolean']['input']>;
  email_sub_status?: InputMaybe<OptInStatus>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordCreateNestedManyWithoutLeadInput>;
  EmailThread?: InputMaybe<EmailThreadCreateNestedManyWithoutLeadInput>;
  favorited_by_users?: InputMaybe<UserCreateNestedManyWithoutFavorite_LeadsInput>;
  first_demo_schedule_date?: InputMaybe<Scalars['DateTime']['input']>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  hubspot_company_id?: InputMaybe<Scalars['String']['input']>;
  hubspot_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  implied_next_action?: InputMaybe<Hingepoint>;
  in_algo_queue?: InputMaybe<Scalars['Boolean']['input']>;
  in_custom_sequence?: InputMaybe<Scalars['Int']['input']>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventCreateNestedManyWithoutLeadInput>;
  industry?: InputMaybe<Scalars['String']['input']>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogCreateNestedManyWithoutLeadInput>;
  last_call_result?: InputMaybe<Scalars['String']['input']>;
  last_call_result_rep?: InputMaybe<UserCreateNestedOneWithoutLeads_Last_CalledInput>;
  last_call_result_time?: InputMaybe<Scalars['DateTime']['input']>;
  last_lead_assignment_origin?: InputMaybe<Scalars['DateTime']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  last_sent_to_resting_date?: InputMaybe<Scalars['DateTime']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutLeadInput>;
  lead_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutLeadInput>;
  lead_assignment_origin?: InputMaybe<AssignmentOrigin>;
  lead_creation_source?: InputMaybe<Leadcreationsource>;
  lead_custom_row_info?: InputMaybe<LeadCustomObjectRowAssociationCreateNestedManyWithoutLeadInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryCreateNestedOneWithoutLeadInput>;
  lead_import_history_csv?: InputMaybe<LeadImportHistoryCreateNestedOneWithoutUploaded_LeadsInput>;
  lead_intent?: InputMaybe<LeadIntentCreateNestedManyWithoutLeadInput>;
  lead_ownership_status?: InputMaybe<Los>;
  lead_source?: InputMaybe<Scalars['String']['input']>;
  lead_value?: InputMaybe<LeadValueCreateNestedOneWithoutLeadInput>;
  mrr?: InputMaybe<Scalars['Float']['input']>;
  newIncoming?: InputMaybe<Scalars['Boolean']['input']>;
  next_scheduled_event?: InputMaybe<ScheduleItemCreateNestedOneWithoutLeadInput>;
  notifications?: InputMaybe<NotificationCreateNestedManyWithoutLeadInput>;
  opsiq_whitelist?: InputMaybe<Scalars['Boolean']['input']>;
  organization: OrganizationCreateNestedOneWithoutLeadsInput;
  phase?: InputMaybe<Phase>;
  primary_email?: InputMaybe<Scalars['String']['input']>;
  primary_email_title?: InputMaybe<Scalars['String']['input']>;
  primary_email_type?: InputMaybe<Scalars['String']['input']>;
  primary_lead_histories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutPrimary_LeadInput>;
  primary_phone_number?: InputMaybe<Scalars['String']['input']>;
  primary_phone_number_country_code?: InputMaybe<Scalars['String']['input']>;
  primary_phone_number_title?: InputMaybe<Scalars['String']['input']>;
  primary_phone_number_type?: InputMaybe<Scalars['String']['input']>;
  rep?: InputMaybe<UserCreateNestedOneWithoutOwned_LeadsInput>;
  resting_type?: InputMaybe<Restingtype>;
  routing_history?: InputMaybe<RoutingHistoryCreateNestedManyWithoutLeadInput>;
  routing_rule?: InputMaybe<RuleCreateNestedOneWithoutLeadInput>;
  Sale?: InputMaybe<SaleCreateNestedManyWithoutLeadInput>;
  sale_cycles?: InputMaybe<SaleCycleCreateNestedManyWithoutLeadInput>;
  search_content?: InputMaybe<Scalars['String']['input']>;
  secondary_reps?: InputMaybe<UserCreateNestedManyWithoutIdle_Owned_LeadsInput>;
  self_sourced?: InputMaybe<Scalars['Boolean']['input']>;
  sequence?: InputMaybe<SequenceCreateNestedOneWithoutLeadsInput>;
  sequence_step?: InputMaybe<SequenceStepCreateNestedOneWithoutLeadsInput>;
  SequenceActionLog?: InputMaybe<StepActionLogCreateNestedManyWithoutLeadInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutLeadInput>;
  sequences_completed_ids?: InputMaybe<LeadCreatesequences_Completed_IdsInput>;
  sequences_joined_ids?: InputMaybe<LeadCreatesequences_Joined_IdsInput>;
  sf_account_id?: InputMaybe<Scalars['String']['input']>;
  sf_contact_id?: InputMaybe<Scalars['String']['input']>;
  sf_lead_id?: InputMaybe<Scalars['String']['input']>;
  sibling_lead_histories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutSibling_LeadsInput>;
  sms_sub_status?: InputMaybe<OptInStatus>;
  stat_items?: InputMaybe<StatItemAggregationCreateNestedManyWithoutLeadInput>;
  state?: InputMaybe<Scalars['String']['input']>;
  StatLeadAggregation?: InputMaybe<StatLeadAggregationCreateNestedOneWithoutLeadInput>;
  status?: InputMaybe<Scalars['String']['input']>;
  sub_industry?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type LeadCreateWithoutAssociate_ChildrenInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  address_2?: InputMaybe<Scalars['String']['input']>;
  alternate_contacts?: InputMaybe<ContactCreateNestedManyWithoutLeadInput>;
  assignment_locked?: InputMaybe<Scalars['Boolean']['input']>;
  associate_group_lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutAssociate_Group_LeadsInput>;
  associate_lead_histories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutAssociate_LeadInput>;
  associate_parent_lead?: InputMaybe<LeadCreateNestedOneWithoutAssociate_ChildrenInput>;
  associated_parent_intents?: InputMaybe<LeadIntentCreateNestedManyWithoutAssociate_ParentInput>;
  associates_search_content?: InputMaybe<Scalars['String']['input']>;
  business_name?: InputMaybe<Scalars['String']['input']>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptCreateNestedManyWithoutLeadInput>;
  callMeNows?: InputMaybe<CallMeNowCreateNestedManyWithoutLeadInput>;
  channel?: InputMaybe<Channel>;
  child_lead_activites?: InputMaybe<LeadActivityCreateNestedManyWithoutAssociate_ChildInput>;
  city?: InputMaybe<Scalars['String']['input']>;
  claim_date?: InputMaybe<Scalars['DateTime']['input']>;
  conference_number?: InputMaybe<Scalars['String']['input']>;
  contact_requests?: InputMaybe<ContactRequestCreateNestedManyWithoutLeadInput>;
  content?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  current_close_date?: InputMaybe<Scalars['DateTime']['input']>;
  current_contact_date?: InputMaybe<Scalars['DateTime']['input']>;
  current_sale_cycle?: InputMaybe<SaleCycleCreateNestedOneWithoutLead_If_CurrentInput>;
  custom_field_json?: InputMaybe<Scalars['Json']['input']>;
  custom_fields?: InputMaybe<CustomFieldCreateNestedManyWithoutLeadInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemCreateNestedManyWithoutLeadInput>;
  delay_action_status?: InputMaybe<Scalars['Boolean']['input']>;
  email_sub_status?: InputMaybe<OptInStatus>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordCreateNestedManyWithoutLeadInput>;
  EmailThread?: InputMaybe<EmailThreadCreateNestedManyWithoutLeadInput>;
  favorited_by_users?: InputMaybe<UserCreateNestedManyWithoutFavorite_LeadsInput>;
  first_demo_schedule_date?: InputMaybe<Scalars['DateTime']['input']>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  hubspot_company_id?: InputMaybe<Scalars['String']['input']>;
  hubspot_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  implied_next_action?: InputMaybe<Hingepoint>;
  in_algo_queue?: InputMaybe<Scalars['Boolean']['input']>;
  in_custom_sequence?: InputMaybe<Scalars['Int']['input']>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventCreateNestedManyWithoutLeadInput>;
  industry?: InputMaybe<Scalars['String']['input']>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogCreateNestedManyWithoutLeadInput>;
  last_call_result?: InputMaybe<Scalars['String']['input']>;
  last_call_result_rep?: InputMaybe<UserCreateNestedOneWithoutLeads_Last_CalledInput>;
  last_call_result_time?: InputMaybe<Scalars['DateTime']['input']>;
  last_lead_assignment_origin?: InputMaybe<Scalars['DateTime']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  last_sent_to_resting_date?: InputMaybe<Scalars['DateTime']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutLeadInput>;
  lead_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutLeadInput>;
  lead_assignment_origin?: InputMaybe<AssignmentOrigin>;
  lead_creation_source?: InputMaybe<Leadcreationsource>;
  lead_custom_row_info?: InputMaybe<LeadCustomObjectRowAssociationCreateNestedManyWithoutLeadInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryCreateNestedOneWithoutLeadInput>;
  lead_import_history_csv?: InputMaybe<LeadImportHistoryCreateNestedOneWithoutUploaded_LeadsInput>;
  lead_intent?: InputMaybe<LeadIntentCreateNestedManyWithoutLeadInput>;
  lead_ownership_status?: InputMaybe<Los>;
  lead_source?: InputMaybe<Scalars['String']['input']>;
  lead_value?: InputMaybe<LeadValueCreateNestedOneWithoutLeadInput>;
  mrr?: InputMaybe<Scalars['Float']['input']>;
  newIncoming?: InputMaybe<Scalars['Boolean']['input']>;
  next_scheduled_event?: InputMaybe<ScheduleItemCreateNestedOneWithoutLeadInput>;
  notifications?: InputMaybe<NotificationCreateNestedManyWithoutLeadInput>;
  opsiq_whitelist?: InputMaybe<Scalars['Boolean']['input']>;
  organization: OrganizationCreateNestedOneWithoutLeadsInput;
  phase?: InputMaybe<Phase>;
  primary_email?: InputMaybe<Scalars['String']['input']>;
  primary_email_title?: InputMaybe<Scalars['String']['input']>;
  primary_email_type?: InputMaybe<Scalars['String']['input']>;
  primary_lead_histories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutPrimary_LeadInput>;
  primary_phone_number?: InputMaybe<Scalars['String']['input']>;
  primary_phone_number_country_code?: InputMaybe<Scalars['String']['input']>;
  primary_phone_number_title?: InputMaybe<Scalars['String']['input']>;
  primary_phone_number_type?: InputMaybe<Scalars['String']['input']>;
  rep?: InputMaybe<UserCreateNestedOneWithoutOwned_LeadsInput>;
  resting_type?: InputMaybe<Restingtype>;
  routing_history?: InputMaybe<RoutingHistoryCreateNestedManyWithoutLeadInput>;
  routing_rule?: InputMaybe<RuleCreateNestedOneWithoutLeadInput>;
  Sale?: InputMaybe<SaleCreateNestedManyWithoutLeadInput>;
  sale_cycles?: InputMaybe<SaleCycleCreateNestedManyWithoutLeadInput>;
  search_content?: InputMaybe<Scalars['String']['input']>;
  secondary_reps?: InputMaybe<UserCreateNestedManyWithoutIdle_Owned_LeadsInput>;
  self_sourced?: InputMaybe<Scalars['Boolean']['input']>;
  sequence?: InputMaybe<SequenceCreateNestedOneWithoutLeadsInput>;
  sequence_step?: InputMaybe<SequenceStepCreateNestedOneWithoutLeadsInput>;
  SequenceActionLog?: InputMaybe<StepActionLogCreateNestedManyWithoutLeadInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutLeadInput>;
  sequences_completed_ids?: InputMaybe<LeadCreatesequences_Completed_IdsInput>;
  sequences_joined_ids?: InputMaybe<LeadCreatesequences_Joined_IdsInput>;
  sf_account_id?: InputMaybe<Scalars['String']['input']>;
  sf_contact_id?: InputMaybe<Scalars['String']['input']>;
  sf_lead_id?: InputMaybe<Scalars['String']['input']>;
  sibling_lead_histories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutSibling_LeadsInput>;
  sms_sub_status?: InputMaybe<OptInStatus>;
  stat_items?: InputMaybe<StatItemAggregationCreateNestedManyWithoutLeadInput>;
  state?: InputMaybe<Scalars['String']['input']>;
  StatLeadAggregation?: InputMaybe<StatLeadAggregationCreateNestedOneWithoutLeadInput>;
  status?: InputMaybe<Scalars['String']['input']>;
  sub_industry?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type LeadCreateWithoutAssociate_Group_Lead_ActivitiesInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  address_2?: InputMaybe<Scalars['String']['input']>;
  alternate_contacts?: InputMaybe<ContactCreateNestedManyWithoutLeadInput>;
  assignment_locked?: InputMaybe<Scalars['Boolean']['input']>;
  associate_children?: InputMaybe<LeadCreateNestedManyWithoutAssociate_Parent_LeadInput>;
  associate_lead_histories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutAssociate_LeadInput>;
  associate_parent_lead?: InputMaybe<LeadCreateNestedOneWithoutAssociate_ChildrenInput>;
  associated_parent_intents?: InputMaybe<LeadIntentCreateNestedManyWithoutAssociate_ParentInput>;
  associates_search_content?: InputMaybe<Scalars['String']['input']>;
  business_name?: InputMaybe<Scalars['String']['input']>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptCreateNestedManyWithoutLeadInput>;
  callMeNows?: InputMaybe<CallMeNowCreateNestedManyWithoutLeadInput>;
  channel?: InputMaybe<Channel>;
  child_lead_activites?: InputMaybe<LeadActivityCreateNestedManyWithoutAssociate_ChildInput>;
  city?: InputMaybe<Scalars['String']['input']>;
  claim_date?: InputMaybe<Scalars['DateTime']['input']>;
  conference_number?: InputMaybe<Scalars['String']['input']>;
  contact_requests?: InputMaybe<ContactRequestCreateNestedManyWithoutLeadInput>;
  content?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  current_close_date?: InputMaybe<Scalars['DateTime']['input']>;
  current_contact_date?: InputMaybe<Scalars['DateTime']['input']>;
  current_sale_cycle?: InputMaybe<SaleCycleCreateNestedOneWithoutLead_If_CurrentInput>;
  custom_field_json?: InputMaybe<Scalars['Json']['input']>;
  custom_fields?: InputMaybe<CustomFieldCreateNestedManyWithoutLeadInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemCreateNestedManyWithoutLeadInput>;
  delay_action_status?: InputMaybe<Scalars['Boolean']['input']>;
  email_sub_status?: InputMaybe<OptInStatus>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordCreateNestedManyWithoutLeadInput>;
  EmailThread?: InputMaybe<EmailThreadCreateNestedManyWithoutLeadInput>;
  favorited_by_users?: InputMaybe<UserCreateNestedManyWithoutFavorite_LeadsInput>;
  first_demo_schedule_date?: InputMaybe<Scalars['DateTime']['input']>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  hubspot_company_id?: InputMaybe<Scalars['String']['input']>;
  hubspot_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  implied_next_action?: InputMaybe<Hingepoint>;
  in_algo_queue?: InputMaybe<Scalars['Boolean']['input']>;
  in_custom_sequence?: InputMaybe<Scalars['Int']['input']>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventCreateNestedManyWithoutLeadInput>;
  industry?: InputMaybe<Scalars['String']['input']>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogCreateNestedManyWithoutLeadInput>;
  last_call_result?: InputMaybe<Scalars['String']['input']>;
  last_call_result_rep?: InputMaybe<UserCreateNestedOneWithoutLeads_Last_CalledInput>;
  last_call_result_time?: InputMaybe<Scalars['DateTime']['input']>;
  last_lead_assignment_origin?: InputMaybe<Scalars['DateTime']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  last_sent_to_resting_date?: InputMaybe<Scalars['DateTime']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutLeadInput>;
  lead_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutLeadInput>;
  lead_assignment_origin?: InputMaybe<AssignmentOrigin>;
  lead_creation_source?: InputMaybe<Leadcreationsource>;
  lead_custom_row_info?: InputMaybe<LeadCustomObjectRowAssociationCreateNestedManyWithoutLeadInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryCreateNestedOneWithoutLeadInput>;
  lead_import_history_csv?: InputMaybe<LeadImportHistoryCreateNestedOneWithoutUploaded_LeadsInput>;
  lead_intent?: InputMaybe<LeadIntentCreateNestedManyWithoutLeadInput>;
  lead_ownership_status?: InputMaybe<Los>;
  lead_source?: InputMaybe<Scalars['String']['input']>;
  lead_value?: InputMaybe<LeadValueCreateNestedOneWithoutLeadInput>;
  mrr?: InputMaybe<Scalars['Float']['input']>;
  newIncoming?: InputMaybe<Scalars['Boolean']['input']>;
  next_scheduled_event?: InputMaybe<ScheduleItemCreateNestedOneWithoutLeadInput>;
  notifications?: InputMaybe<NotificationCreateNestedManyWithoutLeadInput>;
  opsiq_whitelist?: InputMaybe<Scalars['Boolean']['input']>;
  organization: OrganizationCreateNestedOneWithoutLeadsInput;
  phase?: InputMaybe<Phase>;
  primary_email?: InputMaybe<Scalars['String']['input']>;
  primary_email_title?: InputMaybe<Scalars['String']['input']>;
  primary_email_type?: InputMaybe<Scalars['String']['input']>;
  primary_lead_histories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutPrimary_LeadInput>;
  primary_phone_number?: InputMaybe<Scalars['String']['input']>;
  primary_phone_number_country_code?: InputMaybe<Scalars['String']['input']>;
  primary_phone_number_title?: InputMaybe<Scalars['String']['input']>;
  primary_phone_number_type?: InputMaybe<Scalars['String']['input']>;
  rep?: InputMaybe<UserCreateNestedOneWithoutOwned_LeadsInput>;
  resting_type?: InputMaybe<Restingtype>;
  routing_history?: InputMaybe<RoutingHistoryCreateNestedManyWithoutLeadInput>;
  routing_rule?: InputMaybe<RuleCreateNestedOneWithoutLeadInput>;
  Sale?: InputMaybe<SaleCreateNestedManyWithoutLeadInput>;
  sale_cycles?: InputMaybe<SaleCycleCreateNestedManyWithoutLeadInput>;
  search_content?: InputMaybe<Scalars['String']['input']>;
  secondary_reps?: InputMaybe<UserCreateNestedManyWithoutIdle_Owned_LeadsInput>;
  self_sourced?: InputMaybe<Scalars['Boolean']['input']>;
  sequence?: InputMaybe<SequenceCreateNestedOneWithoutLeadsInput>;
  sequence_step?: InputMaybe<SequenceStepCreateNestedOneWithoutLeadsInput>;
  SequenceActionLog?: InputMaybe<StepActionLogCreateNestedManyWithoutLeadInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutLeadInput>;
  sequences_completed_ids?: InputMaybe<LeadCreatesequences_Completed_IdsInput>;
  sequences_joined_ids?: InputMaybe<LeadCreatesequences_Joined_IdsInput>;
  sf_account_id?: InputMaybe<Scalars['String']['input']>;
  sf_contact_id?: InputMaybe<Scalars['String']['input']>;
  sf_lead_id?: InputMaybe<Scalars['String']['input']>;
  sibling_lead_histories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutSibling_LeadsInput>;
  sms_sub_status?: InputMaybe<OptInStatus>;
  stat_items?: InputMaybe<StatItemAggregationCreateNestedManyWithoutLeadInput>;
  state?: InputMaybe<Scalars['String']['input']>;
  StatLeadAggregation?: InputMaybe<StatLeadAggregationCreateNestedOneWithoutLeadInput>;
  status?: InputMaybe<Scalars['String']['input']>;
  sub_industry?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type LeadCreateWithoutAssociate_Lead_HistoriesInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  address_2?: InputMaybe<Scalars['String']['input']>;
  alternate_contacts?: InputMaybe<ContactCreateNestedManyWithoutLeadInput>;
  assignment_locked?: InputMaybe<Scalars['Boolean']['input']>;
  associate_children?: InputMaybe<LeadCreateNestedManyWithoutAssociate_Parent_LeadInput>;
  associate_group_lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutAssociate_Group_LeadsInput>;
  associate_parent_lead?: InputMaybe<LeadCreateNestedOneWithoutAssociate_ChildrenInput>;
  associated_parent_intents?: InputMaybe<LeadIntentCreateNestedManyWithoutAssociate_ParentInput>;
  associates_search_content?: InputMaybe<Scalars['String']['input']>;
  business_name?: InputMaybe<Scalars['String']['input']>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptCreateNestedManyWithoutLeadInput>;
  callMeNows?: InputMaybe<CallMeNowCreateNestedManyWithoutLeadInput>;
  channel?: InputMaybe<Channel>;
  child_lead_activites?: InputMaybe<LeadActivityCreateNestedManyWithoutAssociate_ChildInput>;
  city?: InputMaybe<Scalars['String']['input']>;
  claim_date?: InputMaybe<Scalars['DateTime']['input']>;
  conference_number?: InputMaybe<Scalars['String']['input']>;
  contact_requests?: InputMaybe<ContactRequestCreateNestedManyWithoutLeadInput>;
  content?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  current_close_date?: InputMaybe<Scalars['DateTime']['input']>;
  current_contact_date?: InputMaybe<Scalars['DateTime']['input']>;
  current_sale_cycle?: InputMaybe<SaleCycleCreateNestedOneWithoutLead_If_CurrentInput>;
  custom_field_json?: InputMaybe<Scalars['Json']['input']>;
  custom_fields?: InputMaybe<CustomFieldCreateNestedManyWithoutLeadInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemCreateNestedManyWithoutLeadInput>;
  delay_action_status?: InputMaybe<Scalars['Boolean']['input']>;
  email_sub_status?: InputMaybe<OptInStatus>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordCreateNestedManyWithoutLeadInput>;
  EmailThread?: InputMaybe<EmailThreadCreateNestedManyWithoutLeadInput>;
  favorited_by_users?: InputMaybe<UserCreateNestedManyWithoutFavorite_LeadsInput>;
  first_demo_schedule_date?: InputMaybe<Scalars['DateTime']['input']>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  hubspot_company_id?: InputMaybe<Scalars['String']['input']>;
  hubspot_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  implied_next_action?: InputMaybe<Hingepoint>;
  in_algo_queue?: InputMaybe<Scalars['Boolean']['input']>;
  in_custom_sequence?: InputMaybe<Scalars['Int']['input']>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventCreateNestedManyWithoutLeadInput>;
  industry?: InputMaybe<Scalars['String']['input']>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogCreateNestedManyWithoutLeadInput>;
  last_call_result?: InputMaybe<Scalars['String']['input']>;
  last_call_result_rep?: InputMaybe<UserCreateNestedOneWithoutLeads_Last_CalledInput>;
  last_call_result_time?: InputMaybe<Scalars['DateTime']['input']>;
  last_lead_assignment_origin?: InputMaybe<Scalars['DateTime']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  last_sent_to_resting_date?: InputMaybe<Scalars['DateTime']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutLeadInput>;
  lead_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutLeadInput>;
  lead_assignment_origin?: InputMaybe<AssignmentOrigin>;
  lead_creation_source?: InputMaybe<Leadcreationsource>;
  lead_custom_row_info?: InputMaybe<LeadCustomObjectRowAssociationCreateNestedManyWithoutLeadInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryCreateNestedOneWithoutLeadInput>;
  lead_import_history_csv?: InputMaybe<LeadImportHistoryCreateNestedOneWithoutUploaded_LeadsInput>;
  lead_intent?: InputMaybe<LeadIntentCreateNestedManyWithoutLeadInput>;
  lead_ownership_status?: InputMaybe<Los>;
  lead_source?: InputMaybe<Scalars['String']['input']>;
  lead_value?: InputMaybe<LeadValueCreateNestedOneWithoutLeadInput>;
  mrr?: InputMaybe<Scalars['Float']['input']>;
  newIncoming?: InputMaybe<Scalars['Boolean']['input']>;
  next_scheduled_event?: InputMaybe<ScheduleItemCreateNestedOneWithoutLeadInput>;
  notifications?: InputMaybe<NotificationCreateNestedManyWithoutLeadInput>;
  opsiq_whitelist?: InputMaybe<Scalars['Boolean']['input']>;
  organization: OrganizationCreateNestedOneWithoutLeadsInput;
  phase?: InputMaybe<Phase>;
  primary_email?: InputMaybe<Scalars['String']['input']>;
  primary_email_title?: InputMaybe<Scalars['String']['input']>;
  primary_email_type?: InputMaybe<Scalars['String']['input']>;
  primary_lead_histories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutPrimary_LeadInput>;
  primary_phone_number?: InputMaybe<Scalars['String']['input']>;
  primary_phone_number_country_code?: InputMaybe<Scalars['String']['input']>;
  primary_phone_number_title?: InputMaybe<Scalars['String']['input']>;
  primary_phone_number_type?: InputMaybe<Scalars['String']['input']>;
  rep?: InputMaybe<UserCreateNestedOneWithoutOwned_LeadsInput>;
  resting_type?: InputMaybe<Restingtype>;
  routing_history?: InputMaybe<RoutingHistoryCreateNestedManyWithoutLeadInput>;
  routing_rule?: InputMaybe<RuleCreateNestedOneWithoutLeadInput>;
  Sale?: InputMaybe<SaleCreateNestedManyWithoutLeadInput>;
  sale_cycles?: InputMaybe<SaleCycleCreateNestedManyWithoutLeadInput>;
  search_content?: InputMaybe<Scalars['String']['input']>;
  secondary_reps?: InputMaybe<UserCreateNestedManyWithoutIdle_Owned_LeadsInput>;
  self_sourced?: InputMaybe<Scalars['Boolean']['input']>;
  sequence?: InputMaybe<SequenceCreateNestedOneWithoutLeadsInput>;
  sequence_step?: InputMaybe<SequenceStepCreateNestedOneWithoutLeadsInput>;
  SequenceActionLog?: InputMaybe<StepActionLogCreateNestedManyWithoutLeadInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutLeadInput>;
  sequences_completed_ids?: InputMaybe<LeadCreatesequences_Completed_IdsInput>;
  sequences_joined_ids?: InputMaybe<LeadCreatesequences_Joined_IdsInput>;
  sf_account_id?: InputMaybe<Scalars['String']['input']>;
  sf_contact_id?: InputMaybe<Scalars['String']['input']>;
  sf_lead_id?: InputMaybe<Scalars['String']['input']>;
  sibling_lead_histories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutSibling_LeadsInput>;
  sms_sub_status?: InputMaybe<OptInStatus>;
  stat_items?: InputMaybe<StatItemAggregationCreateNestedManyWithoutLeadInput>;
  state?: InputMaybe<Scalars['String']['input']>;
  StatLeadAggregation?: InputMaybe<StatLeadAggregationCreateNestedOneWithoutLeadInput>;
  status?: InputMaybe<Scalars['String']['input']>;
  sub_industry?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type LeadCreateWithoutAssociate_Parent_LeadInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  address_2?: InputMaybe<Scalars['String']['input']>;
  alternate_contacts?: InputMaybe<ContactCreateNestedManyWithoutLeadInput>;
  assignment_locked?: InputMaybe<Scalars['Boolean']['input']>;
  associate_children?: InputMaybe<LeadCreateNestedManyWithoutAssociate_Parent_LeadInput>;
  associate_group_lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutAssociate_Group_LeadsInput>;
  associate_lead_histories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutAssociate_LeadInput>;
  associated_parent_intents?: InputMaybe<LeadIntentCreateNestedManyWithoutAssociate_ParentInput>;
  associates_search_content?: InputMaybe<Scalars['String']['input']>;
  business_name?: InputMaybe<Scalars['String']['input']>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptCreateNestedManyWithoutLeadInput>;
  callMeNows?: InputMaybe<CallMeNowCreateNestedManyWithoutLeadInput>;
  channel?: InputMaybe<Channel>;
  child_lead_activites?: InputMaybe<LeadActivityCreateNestedManyWithoutAssociate_ChildInput>;
  city?: InputMaybe<Scalars['String']['input']>;
  claim_date?: InputMaybe<Scalars['DateTime']['input']>;
  conference_number?: InputMaybe<Scalars['String']['input']>;
  contact_requests?: InputMaybe<ContactRequestCreateNestedManyWithoutLeadInput>;
  content?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  current_close_date?: InputMaybe<Scalars['DateTime']['input']>;
  current_contact_date?: InputMaybe<Scalars['DateTime']['input']>;
  current_sale_cycle?: InputMaybe<SaleCycleCreateNestedOneWithoutLead_If_CurrentInput>;
  custom_field_json?: InputMaybe<Scalars['Json']['input']>;
  custom_fields?: InputMaybe<CustomFieldCreateNestedManyWithoutLeadInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemCreateNestedManyWithoutLeadInput>;
  delay_action_status?: InputMaybe<Scalars['Boolean']['input']>;
  email_sub_status?: InputMaybe<OptInStatus>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordCreateNestedManyWithoutLeadInput>;
  EmailThread?: InputMaybe<EmailThreadCreateNestedManyWithoutLeadInput>;
  favorited_by_users?: InputMaybe<UserCreateNestedManyWithoutFavorite_LeadsInput>;
  first_demo_schedule_date?: InputMaybe<Scalars['DateTime']['input']>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  hubspot_company_id?: InputMaybe<Scalars['String']['input']>;
  hubspot_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  implied_next_action?: InputMaybe<Hingepoint>;
  in_algo_queue?: InputMaybe<Scalars['Boolean']['input']>;
  in_custom_sequence?: InputMaybe<Scalars['Int']['input']>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventCreateNestedManyWithoutLeadInput>;
  industry?: InputMaybe<Scalars['String']['input']>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogCreateNestedManyWithoutLeadInput>;
  last_call_result?: InputMaybe<Scalars['String']['input']>;
  last_call_result_rep?: InputMaybe<UserCreateNestedOneWithoutLeads_Last_CalledInput>;
  last_call_result_time?: InputMaybe<Scalars['DateTime']['input']>;
  last_lead_assignment_origin?: InputMaybe<Scalars['DateTime']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  last_sent_to_resting_date?: InputMaybe<Scalars['DateTime']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutLeadInput>;
  lead_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutLeadInput>;
  lead_assignment_origin?: InputMaybe<AssignmentOrigin>;
  lead_creation_source?: InputMaybe<Leadcreationsource>;
  lead_custom_row_info?: InputMaybe<LeadCustomObjectRowAssociationCreateNestedManyWithoutLeadInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryCreateNestedOneWithoutLeadInput>;
  lead_import_history_csv?: InputMaybe<LeadImportHistoryCreateNestedOneWithoutUploaded_LeadsInput>;
  lead_intent?: InputMaybe<LeadIntentCreateNestedManyWithoutLeadInput>;
  lead_ownership_status?: InputMaybe<Los>;
  lead_source?: InputMaybe<Scalars['String']['input']>;
  lead_value?: InputMaybe<LeadValueCreateNestedOneWithoutLeadInput>;
  mrr?: InputMaybe<Scalars['Float']['input']>;
  newIncoming?: InputMaybe<Scalars['Boolean']['input']>;
  next_scheduled_event?: InputMaybe<ScheduleItemCreateNestedOneWithoutLeadInput>;
  notifications?: InputMaybe<NotificationCreateNestedManyWithoutLeadInput>;
  opsiq_whitelist?: InputMaybe<Scalars['Boolean']['input']>;
  organization: OrganizationCreateNestedOneWithoutLeadsInput;
  phase?: InputMaybe<Phase>;
  primary_email?: InputMaybe<Scalars['String']['input']>;
  primary_email_title?: InputMaybe<Scalars['String']['input']>;
  primary_email_type?: InputMaybe<Scalars['String']['input']>;
  primary_lead_histories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutPrimary_LeadInput>;
  primary_phone_number?: InputMaybe<Scalars['String']['input']>;
  primary_phone_number_country_code?: InputMaybe<Scalars['String']['input']>;
  primary_phone_number_title?: InputMaybe<Scalars['String']['input']>;
  primary_phone_number_type?: InputMaybe<Scalars['String']['input']>;
  rep?: InputMaybe<UserCreateNestedOneWithoutOwned_LeadsInput>;
  resting_type?: InputMaybe<Restingtype>;
  routing_history?: InputMaybe<RoutingHistoryCreateNestedManyWithoutLeadInput>;
  routing_rule?: InputMaybe<RuleCreateNestedOneWithoutLeadInput>;
  Sale?: InputMaybe<SaleCreateNestedManyWithoutLeadInput>;
  sale_cycles?: InputMaybe<SaleCycleCreateNestedManyWithoutLeadInput>;
  search_content?: InputMaybe<Scalars['String']['input']>;
  secondary_reps?: InputMaybe<UserCreateNestedManyWithoutIdle_Owned_LeadsInput>;
  self_sourced?: InputMaybe<Scalars['Boolean']['input']>;
  sequence?: InputMaybe<SequenceCreateNestedOneWithoutLeadsInput>;
  sequence_step?: InputMaybe<SequenceStepCreateNestedOneWithoutLeadsInput>;
  SequenceActionLog?: InputMaybe<StepActionLogCreateNestedManyWithoutLeadInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutLeadInput>;
  sequences_completed_ids?: InputMaybe<LeadCreatesequences_Completed_IdsInput>;
  sequences_joined_ids?: InputMaybe<LeadCreatesequences_Joined_IdsInput>;
  sf_account_id?: InputMaybe<Scalars['String']['input']>;
  sf_contact_id?: InputMaybe<Scalars['String']['input']>;
  sf_lead_id?: InputMaybe<Scalars['String']['input']>;
  sibling_lead_histories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutSibling_LeadsInput>;
  sms_sub_status?: InputMaybe<OptInStatus>;
  stat_items?: InputMaybe<StatItemAggregationCreateNestedManyWithoutLeadInput>;
  state?: InputMaybe<Scalars['String']['input']>;
  StatLeadAggregation?: InputMaybe<StatLeadAggregationCreateNestedOneWithoutLeadInput>;
  status?: InputMaybe<Scalars['String']['input']>;
  sub_industry?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type LeadCreateWithoutAssociated_Parent_IntentsInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  address_2?: InputMaybe<Scalars['String']['input']>;
  alternate_contacts?: InputMaybe<ContactCreateNestedManyWithoutLeadInput>;
  assignment_locked?: InputMaybe<Scalars['Boolean']['input']>;
  associate_children?: InputMaybe<LeadCreateNestedManyWithoutAssociate_Parent_LeadInput>;
  associate_group_lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutAssociate_Group_LeadsInput>;
  associate_lead_histories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutAssociate_LeadInput>;
  associate_parent_lead?: InputMaybe<LeadCreateNestedOneWithoutAssociate_ChildrenInput>;
  associates_search_content?: InputMaybe<Scalars['String']['input']>;
  business_name?: InputMaybe<Scalars['String']['input']>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptCreateNestedManyWithoutLeadInput>;
  callMeNows?: InputMaybe<CallMeNowCreateNestedManyWithoutLeadInput>;
  channel?: InputMaybe<Channel>;
  child_lead_activites?: InputMaybe<LeadActivityCreateNestedManyWithoutAssociate_ChildInput>;
  city?: InputMaybe<Scalars['String']['input']>;
  claim_date?: InputMaybe<Scalars['DateTime']['input']>;
  conference_number?: InputMaybe<Scalars['String']['input']>;
  contact_requests?: InputMaybe<ContactRequestCreateNestedManyWithoutLeadInput>;
  content?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  current_close_date?: InputMaybe<Scalars['DateTime']['input']>;
  current_contact_date?: InputMaybe<Scalars['DateTime']['input']>;
  current_sale_cycle?: InputMaybe<SaleCycleCreateNestedOneWithoutLead_If_CurrentInput>;
  custom_field_json?: InputMaybe<Scalars['Json']['input']>;
  custom_fields?: InputMaybe<CustomFieldCreateNestedManyWithoutLeadInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemCreateNestedManyWithoutLeadInput>;
  delay_action_status?: InputMaybe<Scalars['Boolean']['input']>;
  email_sub_status?: InputMaybe<OptInStatus>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordCreateNestedManyWithoutLeadInput>;
  EmailThread?: InputMaybe<EmailThreadCreateNestedManyWithoutLeadInput>;
  favorited_by_users?: InputMaybe<UserCreateNestedManyWithoutFavorite_LeadsInput>;
  first_demo_schedule_date?: InputMaybe<Scalars['DateTime']['input']>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  hubspot_company_id?: InputMaybe<Scalars['String']['input']>;
  hubspot_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  implied_next_action?: InputMaybe<Hingepoint>;
  in_algo_queue?: InputMaybe<Scalars['Boolean']['input']>;
  in_custom_sequence?: InputMaybe<Scalars['Int']['input']>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventCreateNestedManyWithoutLeadInput>;
  industry?: InputMaybe<Scalars['String']['input']>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogCreateNestedManyWithoutLeadInput>;
  last_call_result?: InputMaybe<Scalars['String']['input']>;
  last_call_result_rep?: InputMaybe<UserCreateNestedOneWithoutLeads_Last_CalledInput>;
  last_call_result_time?: InputMaybe<Scalars['DateTime']['input']>;
  last_lead_assignment_origin?: InputMaybe<Scalars['DateTime']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  last_sent_to_resting_date?: InputMaybe<Scalars['DateTime']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutLeadInput>;
  lead_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutLeadInput>;
  lead_assignment_origin?: InputMaybe<AssignmentOrigin>;
  lead_creation_source?: InputMaybe<Leadcreationsource>;
  lead_custom_row_info?: InputMaybe<LeadCustomObjectRowAssociationCreateNestedManyWithoutLeadInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryCreateNestedOneWithoutLeadInput>;
  lead_import_history_csv?: InputMaybe<LeadImportHistoryCreateNestedOneWithoutUploaded_LeadsInput>;
  lead_intent?: InputMaybe<LeadIntentCreateNestedManyWithoutLeadInput>;
  lead_ownership_status?: InputMaybe<Los>;
  lead_source?: InputMaybe<Scalars['String']['input']>;
  lead_value?: InputMaybe<LeadValueCreateNestedOneWithoutLeadInput>;
  mrr?: InputMaybe<Scalars['Float']['input']>;
  newIncoming?: InputMaybe<Scalars['Boolean']['input']>;
  next_scheduled_event?: InputMaybe<ScheduleItemCreateNestedOneWithoutLeadInput>;
  notifications?: InputMaybe<NotificationCreateNestedManyWithoutLeadInput>;
  opsiq_whitelist?: InputMaybe<Scalars['Boolean']['input']>;
  organization: OrganizationCreateNestedOneWithoutLeadsInput;
  phase?: InputMaybe<Phase>;
  primary_email?: InputMaybe<Scalars['String']['input']>;
  primary_email_title?: InputMaybe<Scalars['String']['input']>;
  primary_email_type?: InputMaybe<Scalars['String']['input']>;
  primary_lead_histories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutPrimary_LeadInput>;
  primary_phone_number?: InputMaybe<Scalars['String']['input']>;
  primary_phone_number_country_code?: InputMaybe<Scalars['String']['input']>;
  primary_phone_number_title?: InputMaybe<Scalars['String']['input']>;
  primary_phone_number_type?: InputMaybe<Scalars['String']['input']>;
  rep?: InputMaybe<UserCreateNestedOneWithoutOwned_LeadsInput>;
  resting_type?: InputMaybe<Restingtype>;
  routing_history?: InputMaybe<RoutingHistoryCreateNestedManyWithoutLeadInput>;
  routing_rule?: InputMaybe<RuleCreateNestedOneWithoutLeadInput>;
  Sale?: InputMaybe<SaleCreateNestedManyWithoutLeadInput>;
  sale_cycles?: InputMaybe<SaleCycleCreateNestedManyWithoutLeadInput>;
  search_content?: InputMaybe<Scalars['String']['input']>;
  secondary_reps?: InputMaybe<UserCreateNestedManyWithoutIdle_Owned_LeadsInput>;
  self_sourced?: InputMaybe<Scalars['Boolean']['input']>;
  sequence?: InputMaybe<SequenceCreateNestedOneWithoutLeadsInput>;
  sequence_step?: InputMaybe<SequenceStepCreateNestedOneWithoutLeadsInput>;
  SequenceActionLog?: InputMaybe<StepActionLogCreateNestedManyWithoutLeadInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutLeadInput>;
  sequences_completed_ids?: InputMaybe<LeadCreatesequences_Completed_IdsInput>;
  sequences_joined_ids?: InputMaybe<LeadCreatesequences_Joined_IdsInput>;
  sf_account_id?: InputMaybe<Scalars['String']['input']>;
  sf_contact_id?: InputMaybe<Scalars['String']['input']>;
  sf_lead_id?: InputMaybe<Scalars['String']['input']>;
  sibling_lead_histories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutSibling_LeadsInput>;
  sms_sub_status?: InputMaybe<OptInStatus>;
  stat_items?: InputMaybe<StatItemAggregationCreateNestedManyWithoutLeadInput>;
  state?: InputMaybe<Scalars['String']['input']>;
  StatLeadAggregation?: InputMaybe<StatLeadAggregationCreateNestedOneWithoutLeadInput>;
  status?: InputMaybe<Scalars['String']['input']>;
  sub_industry?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type LeadCreateWithoutCallMeNowAttemptsInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  address_2?: InputMaybe<Scalars['String']['input']>;
  alternate_contacts?: InputMaybe<ContactCreateNestedManyWithoutLeadInput>;
  assignment_locked?: InputMaybe<Scalars['Boolean']['input']>;
  associate_children?: InputMaybe<LeadCreateNestedManyWithoutAssociate_Parent_LeadInput>;
  associate_group_lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutAssociate_Group_LeadsInput>;
  associate_lead_histories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutAssociate_LeadInput>;
  associate_parent_lead?: InputMaybe<LeadCreateNestedOneWithoutAssociate_ChildrenInput>;
  associated_parent_intents?: InputMaybe<LeadIntentCreateNestedManyWithoutAssociate_ParentInput>;
  associates_search_content?: InputMaybe<Scalars['String']['input']>;
  business_name?: InputMaybe<Scalars['String']['input']>;
  callMeNows?: InputMaybe<CallMeNowCreateNestedManyWithoutLeadInput>;
  channel?: InputMaybe<Channel>;
  child_lead_activites?: InputMaybe<LeadActivityCreateNestedManyWithoutAssociate_ChildInput>;
  city?: InputMaybe<Scalars['String']['input']>;
  claim_date?: InputMaybe<Scalars['DateTime']['input']>;
  conference_number?: InputMaybe<Scalars['String']['input']>;
  contact_requests?: InputMaybe<ContactRequestCreateNestedManyWithoutLeadInput>;
  content?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  current_close_date?: InputMaybe<Scalars['DateTime']['input']>;
  current_contact_date?: InputMaybe<Scalars['DateTime']['input']>;
  current_sale_cycle?: InputMaybe<SaleCycleCreateNestedOneWithoutLead_If_CurrentInput>;
  custom_field_json?: InputMaybe<Scalars['Json']['input']>;
  custom_fields?: InputMaybe<CustomFieldCreateNestedManyWithoutLeadInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemCreateNestedManyWithoutLeadInput>;
  delay_action_status?: InputMaybe<Scalars['Boolean']['input']>;
  email_sub_status?: InputMaybe<OptInStatus>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordCreateNestedManyWithoutLeadInput>;
  EmailThread?: InputMaybe<EmailThreadCreateNestedManyWithoutLeadInput>;
  favorited_by_users?: InputMaybe<UserCreateNestedManyWithoutFavorite_LeadsInput>;
  first_demo_schedule_date?: InputMaybe<Scalars['DateTime']['input']>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  hubspot_company_id?: InputMaybe<Scalars['String']['input']>;
  hubspot_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  implied_next_action?: InputMaybe<Hingepoint>;
  in_algo_queue?: InputMaybe<Scalars['Boolean']['input']>;
  in_custom_sequence?: InputMaybe<Scalars['Int']['input']>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventCreateNestedManyWithoutLeadInput>;
  industry?: InputMaybe<Scalars['String']['input']>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogCreateNestedManyWithoutLeadInput>;
  last_call_result?: InputMaybe<Scalars['String']['input']>;
  last_call_result_rep?: InputMaybe<UserCreateNestedOneWithoutLeads_Last_CalledInput>;
  last_call_result_time?: InputMaybe<Scalars['DateTime']['input']>;
  last_lead_assignment_origin?: InputMaybe<Scalars['DateTime']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  last_sent_to_resting_date?: InputMaybe<Scalars['DateTime']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutLeadInput>;
  lead_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutLeadInput>;
  lead_assignment_origin?: InputMaybe<AssignmentOrigin>;
  lead_creation_source?: InputMaybe<Leadcreationsource>;
  lead_custom_row_info?: InputMaybe<LeadCustomObjectRowAssociationCreateNestedManyWithoutLeadInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryCreateNestedOneWithoutLeadInput>;
  lead_import_history_csv?: InputMaybe<LeadImportHistoryCreateNestedOneWithoutUploaded_LeadsInput>;
  lead_intent?: InputMaybe<LeadIntentCreateNestedManyWithoutLeadInput>;
  lead_ownership_status?: InputMaybe<Los>;
  lead_source?: InputMaybe<Scalars['String']['input']>;
  lead_value?: InputMaybe<LeadValueCreateNestedOneWithoutLeadInput>;
  mrr?: InputMaybe<Scalars['Float']['input']>;
  newIncoming?: InputMaybe<Scalars['Boolean']['input']>;
  next_scheduled_event?: InputMaybe<ScheduleItemCreateNestedOneWithoutLeadInput>;
  notifications?: InputMaybe<NotificationCreateNestedManyWithoutLeadInput>;
  opsiq_whitelist?: InputMaybe<Scalars['Boolean']['input']>;
  organization: OrganizationCreateNestedOneWithoutLeadsInput;
  phase?: InputMaybe<Phase>;
  primary_email?: InputMaybe<Scalars['String']['input']>;
  primary_email_title?: InputMaybe<Scalars['String']['input']>;
  primary_email_type?: InputMaybe<Scalars['String']['input']>;
  primary_lead_histories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutPrimary_LeadInput>;
  primary_phone_number?: InputMaybe<Scalars['String']['input']>;
  primary_phone_number_country_code?: InputMaybe<Scalars['String']['input']>;
  primary_phone_number_title?: InputMaybe<Scalars['String']['input']>;
  primary_phone_number_type?: InputMaybe<Scalars['String']['input']>;
  rep?: InputMaybe<UserCreateNestedOneWithoutOwned_LeadsInput>;
  resting_type?: InputMaybe<Restingtype>;
  routing_history?: InputMaybe<RoutingHistoryCreateNestedManyWithoutLeadInput>;
  routing_rule?: InputMaybe<RuleCreateNestedOneWithoutLeadInput>;
  Sale?: InputMaybe<SaleCreateNestedManyWithoutLeadInput>;
  sale_cycles?: InputMaybe<SaleCycleCreateNestedManyWithoutLeadInput>;
  search_content?: InputMaybe<Scalars['String']['input']>;
  secondary_reps?: InputMaybe<UserCreateNestedManyWithoutIdle_Owned_LeadsInput>;
  self_sourced?: InputMaybe<Scalars['Boolean']['input']>;
  sequence?: InputMaybe<SequenceCreateNestedOneWithoutLeadsInput>;
  sequence_step?: InputMaybe<SequenceStepCreateNestedOneWithoutLeadsInput>;
  SequenceActionLog?: InputMaybe<StepActionLogCreateNestedManyWithoutLeadInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutLeadInput>;
  sequences_completed_ids?: InputMaybe<LeadCreatesequences_Completed_IdsInput>;
  sequences_joined_ids?: InputMaybe<LeadCreatesequences_Joined_IdsInput>;
  sf_account_id?: InputMaybe<Scalars['String']['input']>;
  sf_contact_id?: InputMaybe<Scalars['String']['input']>;
  sf_lead_id?: InputMaybe<Scalars['String']['input']>;
  sibling_lead_histories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutSibling_LeadsInput>;
  sms_sub_status?: InputMaybe<OptInStatus>;
  stat_items?: InputMaybe<StatItemAggregationCreateNestedManyWithoutLeadInput>;
  state?: InputMaybe<Scalars['String']['input']>;
  StatLeadAggregation?: InputMaybe<StatLeadAggregationCreateNestedOneWithoutLeadInput>;
  status?: InputMaybe<Scalars['String']['input']>;
  sub_industry?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type LeadCreateWithoutCallMeNowsInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  address_2?: InputMaybe<Scalars['String']['input']>;
  alternate_contacts?: InputMaybe<ContactCreateNestedManyWithoutLeadInput>;
  assignment_locked?: InputMaybe<Scalars['Boolean']['input']>;
  associate_children?: InputMaybe<LeadCreateNestedManyWithoutAssociate_Parent_LeadInput>;
  associate_group_lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutAssociate_Group_LeadsInput>;
  associate_lead_histories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutAssociate_LeadInput>;
  associate_parent_lead?: InputMaybe<LeadCreateNestedOneWithoutAssociate_ChildrenInput>;
  associated_parent_intents?: InputMaybe<LeadIntentCreateNestedManyWithoutAssociate_ParentInput>;
  associates_search_content?: InputMaybe<Scalars['String']['input']>;
  business_name?: InputMaybe<Scalars['String']['input']>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptCreateNestedManyWithoutLeadInput>;
  channel?: InputMaybe<Channel>;
  child_lead_activites?: InputMaybe<LeadActivityCreateNestedManyWithoutAssociate_ChildInput>;
  city?: InputMaybe<Scalars['String']['input']>;
  claim_date?: InputMaybe<Scalars['DateTime']['input']>;
  conference_number?: InputMaybe<Scalars['String']['input']>;
  contact_requests?: InputMaybe<ContactRequestCreateNestedManyWithoutLeadInput>;
  content?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  current_close_date?: InputMaybe<Scalars['DateTime']['input']>;
  current_contact_date?: InputMaybe<Scalars['DateTime']['input']>;
  current_sale_cycle?: InputMaybe<SaleCycleCreateNestedOneWithoutLead_If_CurrentInput>;
  custom_field_json?: InputMaybe<Scalars['Json']['input']>;
  custom_fields?: InputMaybe<CustomFieldCreateNestedManyWithoutLeadInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemCreateNestedManyWithoutLeadInput>;
  delay_action_status?: InputMaybe<Scalars['Boolean']['input']>;
  email_sub_status?: InputMaybe<OptInStatus>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordCreateNestedManyWithoutLeadInput>;
  EmailThread?: InputMaybe<EmailThreadCreateNestedManyWithoutLeadInput>;
  favorited_by_users?: InputMaybe<UserCreateNestedManyWithoutFavorite_LeadsInput>;
  first_demo_schedule_date?: InputMaybe<Scalars['DateTime']['input']>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  hubspot_company_id?: InputMaybe<Scalars['String']['input']>;
  hubspot_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  implied_next_action?: InputMaybe<Hingepoint>;
  in_algo_queue?: InputMaybe<Scalars['Boolean']['input']>;
  in_custom_sequence?: InputMaybe<Scalars['Int']['input']>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventCreateNestedManyWithoutLeadInput>;
  industry?: InputMaybe<Scalars['String']['input']>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogCreateNestedManyWithoutLeadInput>;
  last_call_result?: InputMaybe<Scalars['String']['input']>;
  last_call_result_rep?: InputMaybe<UserCreateNestedOneWithoutLeads_Last_CalledInput>;
  last_call_result_time?: InputMaybe<Scalars['DateTime']['input']>;
  last_lead_assignment_origin?: InputMaybe<Scalars['DateTime']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  last_sent_to_resting_date?: InputMaybe<Scalars['DateTime']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutLeadInput>;
  lead_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutLeadInput>;
  lead_assignment_origin?: InputMaybe<AssignmentOrigin>;
  lead_creation_source?: InputMaybe<Leadcreationsource>;
  lead_custom_row_info?: InputMaybe<LeadCustomObjectRowAssociationCreateNestedManyWithoutLeadInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryCreateNestedOneWithoutLeadInput>;
  lead_import_history_csv?: InputMaybe<LeadImportHistoryCreateNestedOneWithoutUploaded_LeadsInput>;
  lead_intent?: InputMaybe<LeadIntentCreateNestedManyWithoutLeadInput>;
  lead_ownership_status?: InputMaybe<Los>;
  lead_source?: InputMaybe<Scalars['String']['input']>;
  lead_value?: InputMaybe<LeadValueCreateNestedOneWithoutLeadInput>;
  mrr?: InputMaybe<Scalars['Float']['input']>;
  newIncoming?: InputMaybe<Scalars['Boolean']['input']>;
  next_scheduled_event?: InputMaybe<ScheduleItemCreateNestedOneWithoutLeadInput>;
  notifications?: InputMaybe<NotificationCreateNestedManyWithoutLeadInput>;
  opsiq_whitelist?: InputMaybe<Scalars['Boolean']['input']>;
  organization: OrganizationCreateNestedOneWithoutLeadsInput;
  phase?: InputMaybe<Phase>;
  primary_email?: InputMaybe<Scalars['String']['input']>;
  primary_email_title?: InputMaybe<Scalars['String']['input']>;
  primary_email_type?: InputMaybe<Scalars['String']['input']>;
  primary_lead_histories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutPrimary_LeadInput>;
  primary_phone_number?: InputMaybe<Scalars['String']['input']>;
  primary_phone_number_country_code?: InputMaybe<Scalars['String']['input']>;
  primary_phone_number_title?: InputMaybe<Scalars['String']['input']>;
  primary_phone_number_type?: InputMaybe<Scalars['String']['input']>;
  rep?: InputMaybe<UserCreateNestedOneWithoutOwned_LeadsInput>;
  resting_type?: InputMaybe<Restingtype>;
  routing_history?: InputMaybe<RoutingHistoryCreateNestedManyWithoutLeadInput>;
  routing_rule?: InputMaybe<RuleCreateNestedOneWithoutLeadInput>;
  Sale?: InputMaybe<SaleCreateNestedManyWithoutLeadInput>;
  sale_cycles?: InputMaybe<SaleCycleCreateNestedManyWithoutLeadInput>;
  search_content?: InputMaybe<Scalars['String']['input']>;
  secondary_reps?: InputMaybe<UserCreateNestedManyWithoutIdle_Owned_LeadsInput>;
  self_sourced?: InputMaybe<Scalars['Boolean']['input']>;
  sequence?: InputMaybe<SequenceCreateNestedOneWithoutLeadsInput>;
  sequence_step?: InputMaybe<SequenceStepCreateNestedOneWithoutLeadsInput>;
  SequenceActionLog?: InputMaybe<StepActionLogCreateNestedManyWithoutLeadInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutLeadInput>;
  sequences_completed_ids?: InputMaybe<LeadCreatesequences_Completed_IdsInput>;
  sequences_joined_ids?: InputMaybe<LeadCreatesequences_Joined_IdsInput>;
  sf_account_id?: InputMaybe<Scalars['String']['input']>;
  sf_contact_id?: InputMaybe<Scalars['String']['input']>;
  sf_lead_id?: InputMaybe<Scalars['String']['input']>;
  sibling_lead_histories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutSibling_LeadsInput>;
  sms_sub_status?: InputMaybe<OptInStatus>;
  stat_items?: InputMaybe<StatItemAggregationCreateNestedManyWithoutLeadInput>;
  state?: InputMaybe<Scalars['String']['input']>;
  StatLeadAggregation?: InputMaybe<StatLeadAggregationCreateNestedOneWithoutLeadInput>;
  status?: InputMaybe<Scalars['String']['input']>;
  sub_industry?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type LeadCreateWithoutChild_Lead_ActivitesInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  address_2?: InputMaybe<Scalars['String']['input']>;
  alternate_contacts?: InputMaybe<ContactCreateNestedManyWithoutLeadInput>;
  assignment_locked?: InputMaybe<Scalars['Boolean']['input']>;
  associate_children?: InputMaybe<LeadCreateNestedManyWithoutAssociate_Parent_LeadInput>;
  associate_group_lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutAssociate_Group_LeadsInput>;
  associate_lead_histories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutAssociate_LeadInput>;
  associate_parent_lead?: InputMaybe<LeadCreateNestedOneWithoutAssociate_ChildrenInput>;
  associated_parent_intents?: InputMaybe<LeadIntentCreateNestedManyWithoutAssociate_ParentInput>;
  associates_search_content?: InputMaybe<Scalars['String']['input']>;
  business_name?: InputMaybe<Scalars['String']['input']>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptCreateNestedManyWithoutLeadInput>;
  callMeNows?: InputMaybe<CallMeNowCreateNestedManyWithoutLeadInput>;
  channel?: InputMaybe<Channel>;
  city?: InputMaybe<Scalars['String']['input']>;
  claim_date?: InputMaybe<Scalars['DateTime']['input']>;
  conference_number?: InputMaybe<Scalars['String']['input']>;
  contact_requests?: InputMaybe<ContactRequestCreateNestedManyWithoutLeadInput>;
  content?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  current_close_date?: InputMaybe<Scalars['DateTime']['input']>;
  current_contact_date?: InputMaybe<Scalars['DateTime']['input']>;
  current_sale_cycle?: InputMaybe<SaleCycleCreateNestedOneWithoutLead_If_CurrentInput>;
  custom_field_json?: InputMaybe<Scalars['Json']['input']>;
  custom_fields?: InputMaybe<CustomFieldCreateNestedManyWithoutLeadInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemCreateNestedManyWithoutLeadInput>;
  delay_action_status?: InputMaybe<Scalars['Boolean']['input']>;
  email_sub_status?: InputMaybe<OptInStatus>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordCreateNestedManyWithoutLeadInput>;
  EmailThread?: InputMaybe<EmailThreadCreateNestedManyWithoutLeadInput>;
  favorited_by_users?: InputMaybe<UserCreateNestedManyWithoutFavorite_LeadsInput>;
  first_demo_schedule_date?: InputMaybe<Scalars['DateTime']['input']>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  hubspot_company_id?: InputMaybe<Scalars['String']['input']>;
  hubspot_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  implied_next_action?: InputMaybe<Hingepoint>;
  in_algo_queue?: InputMaybe<Scalars['Boolean']['input']>;
  in_custom_sequence?: InputMaybe<Scalars['Int']['input']>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventCreateNestedManyWithoutLeadInput>;
  industry?: InputMaybe<Scalars['String']['input']>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogCreateNestedManyWithoutLeadInput>;
  last_call_result?: InputMaybe<Scalars['String']['input']>;
  last_call_result_rep?: InputMaybe<UserCreateNestedOneWithoutLeads_Last_CalledInput>;
  last_call_result_time?: InputMaybe<Scalars['DateTime']['input']>;
  last_lead_assignment_origin?: InputMaybe<Scalars['DateTime']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  last_sent_to_resting_date?: InputMaybe<Scalars['DateTime']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutLeadInput>;
  lead_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutLeadInput>;
  lead_assignment_origin?: InputMaybe<AssignmentOrigin>;
  lead_creation_source?: InputMaybe<Leadcreationsource>;
  lead_custom_row_info?: InputMaybe<LeadCustomObjectRowAssociationCreateNestedManyWithoutLeadInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryCreateNestedOneWithoutLeadInput>;
  lead_import_history_csv?: InputMaybe<LeadImportHistoryCreateNestedOneWithoutUploaded_LeadsInput>;
  lead_intent?: InputMaybe<LeadIntentCreateNestedManyWithoutLeadInput>;
  lead_ownership_status?: InputMaybe<Los>;
  lead_source?: InputMaybe<Scalars['String']['input']>;
  lead_value?: InputMaybe<LeadValueCreateNestedOneWithoutLeadInput>;
  mrr?: InputMaybe<Scalars['Float']['input']>;
  newIncoming?: InputMaybe<Scalars['Boolean']['input']>;
  next_scheduled_event?: InputMaybe<ScheduleItemCreateNestedOneWithoutLeadInput>;
  notifications?: InputMaybe<NotificationCreateNestedManyWithoutLeadInput>;
  opsiq_whitelist?: InputMaybe<Scalars['Boolean']['input']>;
  organization: OrganizationCreateNestedOneWithoutLeadsInput;
  phase?: InputMaybe<Phase>;
  primary_email?: InputMaybe<Scalars['String']['input']>;
  primary_email_title?: InputMaybe<Scalars['String']['input']>;
  primary_email_type?: InputMaybe<Scalars['String']['input']>;
  primary_lead_histories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutPrimary_LeadInput>;
  primary_phone_number?: InputMaybe<Scalars['String']['input']>;
  primary_phone_number_country_code?: InputMaybe<Scalars['String']['input']>;
  primary_phone_number_title?: InputMaybe<Scalars['String']['input']>;
  primary_phone_number_type?: InputMaybe<Scalars['String']['input']>;
  rep?: InputMaybe<UserCreateNestedOneWithoutOwned_LeadsInput>;
  resting_type?: InputMaybe<Restingtype>;
  routing_history?: InputMaybe<RoutingHistoryCreateNestedManyWithoutLeadInput>;
  routing_rule?: InputMaybe<RuleCreateNestedOneWithoutLeadInput>;
  Sale?: InputMaybe<SaleCreateNestedManyWithoutLeadInput>;
  sale_cycles?: InputMaybe<SaleCycleCreateNestedManyWithoutLeadInput>;
  search_content?: InputMaybe<Scalars['String']['input']>;
  secondary_reps?: InputMaybe<UserCreateNestedManyWithoutIdle_Owned_LeadsInput>;
  self_sourced?: InputMaybe<Scalars['Boolean']['input']>;
  sequence?: InputMaybe<SequenceCreateNestedOneWithoutLeadsInput>;
  sequence_step?: InputMaybe<SequenceStepCreateNestedOneWithoutLeadsInput>;
  SequenceActionLog?: InputMaybe<StepActionLogCreateNestedManyWithoutLeadInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutLeadInput>;
  sequences_completed_ids?: InputMaybe<LeadCreatesequences_Completed_IdsInput>;
  sequences_joined_ids?: InputMaybe<LeadCreatesequences_Joined_IdsInput>;
  sf_account_id?: InputMaybe<Scalars['String']['input']>;
  sf_contact_id?: InputMaybe<Scalars['String']['input']>;
  sf_lead_id?: InputMaybe<Scalars['String']['input']>;
  sibling_lead_histories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutSibling_LeadsInput>;
  sms_sub_status?: InputMaybe<OptInStatus>;
  stat_items?: InputMaybe<StatItemAggregationCreateNestedManyWithoutLeadInput>;
  state?: InputMaybe<Scalars['String']['input']>;
  StatLeadAggregation?: InputMaybe<StatLeadAggregationCreateNestedOneWithoutLeadInput>;
  status?: InputMaybe<Scalars['String']['input']>;
  sub_industry?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type LeadCreateWithoutContact_RequestsInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  address_2?: InputMaybe<Scalars['String']['input']>;
  alternate_contacts?: InputMaybe<ContactCreateNestedManyWithoutLeadInput>;
  assignment_locked?: InputMaybe<Scalars['Boolean']['input']>;
  associate_children?: InputMaybe<LeadCreateNestedManyWithoutAssociate_Parent_LeadInput>;
  associate_group_lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutAssociate_Group_LeadsInput>;
  associate_lead_histories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutAssociate_LeadInput>;
  associate_parent_lead?: InputMaybe<LeadCreateNestedOneWithoutAssociate_ChildrenInput>;
  associated_parent_intents?: InputMaybe<LeadIntentCreateNestedManyWithoutAssociate_ParentInput>;
  associates_search_content?: InputMaybe<Scalars['String']['input']>;
  business_name?: InputMaybe<Scalars['String']['input']>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptCreateNestedManyWithoutLeadInput>;
  callMeNows?: InputMaybe<CallMeNowCreateNestedManyWithoutLeadInput>;
  channel?: InputMaybe<Channel>;
  child_lead_activites?: InputMaybe<LeadActivityCreateNestedManyWithoutAssociate_ChildInput>;
  city?: InputMaybe<Scalars['String']['input']>;
  claim_date?: InputMaybe<Scalars['DateTime']['input']>;
  conference_number?: InputMaybe<Scalars['String']['input']>;
  content?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  current_close_date?: InputMaybe<Scalars['DateTime']['input']>;
  current_contact_date?: InputMaybe<Scalars['DateTime']['input']>;
  current_sale_cycle?: InputMaybe<SaleCycleCreateNestedOneWithoutLead_If_CurrentInput>;
  custom_field_json?: InputMaybe<Scalars['Json']['input']>;
  custom_fields?: InputMaybe<CustomFieldCreateNestedManyWithoutLeadInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemCreateNestedManyWithoutLeadInput>;
  delay_action_status?: InputMaybe<Scalars['Boolean']['input']>;
  email_sub_status?: InputMaybe<OptInStatus>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordCreateNestedManyWithoutLeadInput>;
  EmailThread?: InputMaybe<EmailThreadCreateNestedManyWithoutLeadInput>;
  favorited_by_users?: InputMaybe<UserCreateNestedManyWithoutFavorite_LeadsInput>;
  first_demo_schedule_date?: InputMaybe<Scalars['DateTime']['input']>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  hubspot_company_id?: InputMaybe<Scalars['String']['input']>;
  hubspot_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  implied_next_action?: InputMaybe<Hingepoint>;
  in_algo_queue?: InputMaybe<Scalars['Boolean']['input']>;
  in_custom_sequence?: InputMaybe<Scalars['Int']['input']>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventCreateNestedManyWithoutLeadInput>;
  industry?: InputMaybe<Scalars['String']['input']>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogCreateNestedManyWithoutLeadInput>;
  last_call_result?: InputMaybe<Scalars['String']['input']>;
  last_call_result_rep?: InputMaybe<UserCreateNestedOneWithoutLeads_Last_CalledInput>;
  last_call_result_time?: InputMaybe<Scalars['DateTime']['input']>;
  last_lead_assignment_origin?: InputMaybe<Scalars['DateTime']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  last_sent_to_resting_date?: InputMaybe<Scalars['DateTime']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutLeadInput>;
  lead_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutLeadInput>;
  lead_assignment_origin?: InputMaybe<AssignmentOrigin>;
  lead_creation_source?: InputMaybe<Leadcreationsource>;
  lead_custom_row_info?: InputMaybe<LeadCustomObjectRowAssociationCreateNestedManyWithoutLeadInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryCreateNestedOneWithoutLeadInput>;
  lead_import_history_csv?: InputMaybe<LeadImportHistoryCreateNestedOneWithoutUploaded_LeadsInput>;
  lead_intent?: InputMaybe<LeadIntentCreateNestedManyWithoutLeadInput>;
  lead_ownership_status?: InputMaybe<Los>;
  lead_source?: InputMaybe<Scalars['String']['input']>;
  lead_value?: InputMaybe<LeadValueCreateNestedOneWithoutLeadInput>;
  mrr?: InputMaybe<Scalars['Float']['input']>;
  newIncoming?: InputMaybe<Scalars['Boolean']['input']>;
  next_scheduled_event?: InputMaybe<ScheduleItemCreateNestedOneWithoutLeadInput>;
  notifications?: InputMaybe<NotificationCreateNestedManyWithoutLeadInput>;
  opsiq_whitelist?: InputMaybe<Scalars['Boolean']['input']>;
  organization: OrganizationCreateNestedOneWithoutLeadsInput;
  phase?: InputMaybe<Phase>;
  primary_email?: InputMaybe<Scalars['String']['input']>;
  primary_email_title?: InputMaybe<Scalars['String']['input']>;
  primary_email_type?: InputMaybe<Scalars['String']['input']>;
  primary_lead_histories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutPrimary_LeadInput>;
  primary_phone_number?: InputMaybe<Scalars['String']['input']>;
  primary_phone_number_country_code?: InputMaybe<Scalars['String']['input']>;
  primary_phone_number_title?: InputMaybe<Scalars['String']['input']>;
  primary_phone_number_type?: InputMaybe<Scalars['String']['input']>;
  rep?: InputMaybe<UserCreateNestedOneWithoutOwned_LeadsInput>;
  resting_type?: InputMaybe<Restingtype>;
  routing_history?: InputMaybe<RoutingHistoryCreateNestedManyWithoutLeadInput>;
  routing_rule?: InputMaybe<RuleCreateNestedOneWithoutLeadInput>;
  Sale?: InputMaybe<SaleCreateNestedManyWithoutLeadInput>;
  sale_cycles?: InputMaybe<SaleCycleCreateNestedManyWithoutLeadInput>;
  search_content?: InputMaybe<Scalars['String']['input']>;
  secondary_reps?: InputMaybe<UserCreateNestedManyWithoutIdle_Owned_LeadsInput>;
  self_sourced?: InputMaybe<Scalars['Boolean']['input']>;
  sequence?: InputMaybe<SequenceCreateNestedOneWithoutLeadsInput>;
  sequence_step?: InputMaybe<SequenceStepCreateNestedOneWithoutLeadsInput>;
  SequenceActionLog?: InputMaybe<StepActionLogCreateNestedManyWithoutLeadInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutLeadInput>;
  sequences_completed_ids?: InputMaybe<LeadCreatesequences_Completed_IdsInput>;
  sequences_joined_ids?: InputMaybe<LeadCreatesequences_Joined_IdsInput>;
  sf_account_id?: InputMaybe<Scalars['String']['input']>;
  sf_contact_id?: InputMaybe<Scalars['String']['input']>;
  sf_lead_id?: InputMaybe<Scalars['String']['input']>;
  sibling_lead_histories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutSibling_LeadsInput>;
  sms_sub_status?: InputMaybe<OptInStatus>;
  stat_items?: InputMaybe<StatItemAggregationCreateNestedManyWithoutLeadInput>;
  state?: InputMaybe<Scalars['String']['input']>;
  StatLeadAggregation?: InputMaybe<StatLeadAggregationCreateNestedOneWithoutLeadInput>;
  status?: InputMaybe<Scalars['String']['input']>;
  sub_industry?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type LeadCreateWithoutCurrent_Sale_CycleInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  address_2?: InputMaybe<Scalars['String']['input']>;
  alternate_contacts?: InputMaybe<ContactCreateNestedManyWithoutLeadInput>;
  assignment_locked?: InputMaybe<Scalars['Boolean']['input']>;
  associate_children?: InputMaybe<LeadCreateNestedManyWithoutAssociate_Parent_LeadInput>;
  associate_group_lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutAssociate_Group_LeadsInput>;
  associate_lead_histories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutAssociate_LeadInput>;
  associate_parent_lead?: InputMaybe<LeadCreateNestedOneWithoutAssociate_ChildrenInput>;
  associated_parent_intents?: InputMaybe<LeadIntentCreateNestedManyWithoutAssociate_ParentInput>;
  associates_search_content?: InputMaybe<Scalars['String']['input']>;
  business_name?: InputMaybe<Scalars['String']['input']>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptCreateNestedManyWithoutLeadInput>;
  callMeNows?: InputMaybe<CallMeNowCreateNestedManyWithoutLeadInput>;
  channel?: InputMaybe<Channel>;
  child_lead_activites?: InputMaybe<LeadActivityCreateNestedManyWithoutAssociate_ChildInput>;
  city?: InputMaybe<Scalars['String']['input']>;
  claim_date?: InputMaybe<Scalars['DateTime']['input']>;
  conference_number?: InputMaybe<Scalars['String']['input']>;
  contact_requests?: InputMaybe<ContactRequestCreateNestedManyWithoutLeadInput>;
  content?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  current_close_date?: InputMaybe<Scalars['DateTime']['input']>;
  current_contact_date?: InputMaybe<Scalars['DateTime']['input']>;
  custom_field_json?: InputMaybe<Scalars['Json']['input']>;
  custom_fields?: InputMaybe<CustomFieldCreateNestedManyWithoutLeadInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemCreateNestedManyWithoutLeadInput>;
  delay_action_status?: InputMaybe<Scalars['Boolean']['input']>;
  email_sub_status?: InputMaybe<OptInStatus>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordCreateNestedManyWithoutLeadInput>;
  EmailThread?: InputMaybe<EmailThreadCreateNestedManyWithoutLeadInput>;
  favorited_by_users?: InputMaybe<UserCreateNestedManyWithoutFavorite_LeadsInput>;
  first_demo_schedule_date?: InputMaybe<Scalars['DateTime']['input']>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  hubspot_company_id?: InputMaybe<Scalars['String']['input']>;
  hubspot_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  implied_next_action?: InputMaybe<Hingepoint>;
  in_algo_queue?: InputMaybe<Scalars['Boolean']['input']>;
  in_custom_sequence?: InputMaybe<Scalars['Int']['input']>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventCreateNestedManyWithoutLeadInput>;
  industry?: InputMaybe<Scalars['String']['input']>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogCreateNestedManyWithoutLeadInput>;
  last_call_result?: InputMaybe<Scalars['String']['input']>;
  last_call_result_rep?: InputMaybe<UserCreateNestedOneWithoutLeads_Last_CalledInput>;
  last_call_result_time?: InputMaybe<Scalars['DateTime']['input']>;
  last_lead_assignment_origin?: InputMaybe<Scalars['DateTime']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  last_sent_to_resting_date?: InputMaybe<Scalars['DateTime']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutLeadInput>;
  lead_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutLeadInput>;
  lead_assignment_origin?: InputMaybe<AssignmentOrigin>;
  lead_creation_source?: InputMaybe<Leadcreationsource>;
  lead_custom_row_info?: InputMaybe<LeadCustomObjectRowAssociationCreateNestedManyWithoutLeadInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryCreateNestedOneWithoutLeadInput>;
  lead_import_history_csv?: InputMaybe<LeadImportHistoryCreateNestedOneWithoutUploaded_LeadsInput>;
  lead_intent?: InputMaybe<LeadIntentCreateNestedManyWithoutLeadInput>;
  lead_ownership_status?: InputMaybe<Los>;
  lead_source?: InputMaybe<Scalars['String']['input']>;
  lead_value?: InputMaybe<LeadValueCreateNestedOneWithoutLeadInput>;
  mrr?: InputMaybe<Scalars['Float']['input']>;
  newIncoming?: InputMaybe<Scalars['Boolean']['input']>;
  next_scheduled_event?: InputMaybe<ScheduleItemCreateNestedOneWithoutLeadInput>;
  notifications?: InputMaybe<NotificationCreateNestedManyWithoutLeadInput>;
  opsiq_whitelist?: InputMaybe<Scalars['Boolean']['input']>;
  organization: OrganizationCreateNestedOneWithoutLeadsInput;
  phase?: InputMaybe<Phase>;
  primary_email?: InputMaybe<Scalars['String']['input']>;
  primary_email_title?: InputMaybe<Scalars['String']['input']>;
  primary_email_type?: InputMaybe<Scalars['String']['input']>;
  primary_lead_histories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutPrimary_LeadInput>;
  primary_phone_number?: InputMaybe<Scalars['String']['input']>;
  primary_phone_number_country_code?: InputMaybe<Scalars['String']['input']>;
  primary_phone_number_title?: InputMaybe<Scalars['String']['input']>;
  primary_phone_number_type?: InputMaybe<Scalars['String']['input']>;
  rep?: InputMaybe<UserCreateNestedOneWithoutOwned_LeadsInput>;
  resting_type?: InputMaybe<Restingtype>;
  routing_history?: InputMaybe<RoutingHistoryCreateNestedManyWithoutLeadInput>;
  routing_rule?: InputMaybe<RuleCreateNestedOneWithoutLeadInput>;
  Sale?: InputMaybe<SaleCreateNestedManyWithoutLeadInput>;
  sale_cycles?: InputMaybe<SaleCycleCreateNestedManyWithoutLeadInput>;
  search_content?: InputMaybe<Scalars['String']['input']>;
  secondary_reps?: InputMaybe<UserCreateNestedManyWithoutIdle_Owned_LeadsInput>;
  self_sourced?: InputMaybe<Scalars['Boolean']['input']>;
  sequence?: InputMaybe<SequenceCreateNestedOneWithoutLeadsInput>;
  sequence_step?: InputMaybe<SequenceStepCreateNestedOneWithoutLeadsInput>;
  SequenceActionLog?: InputMaybe<StepActionLogCreateNestedManyWithoutLeadInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutLeadInput>;
  sequences_completed_ids?: InputMaybe<LeadCreatesequences_Completed_IdsInput>;
  sequences_joined_ids?: InputMaybe<LeadCreatesequences_Joined_IdsInput>;
  sf_account_id?: InputMaybe<Scalars['String']['input']>;
  sf_contact_id?: InputMaybe<Scalars['String']['input']>;
  sf_lead_id?: InputMaybe<Scalars['String']['input']>;
  sibling_lead_histories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutSibling_LeadsInput>;
  sms_sub_status?: InputMaybe<OptInStatus>;
  stat_items?: InputMaybe<StatItemAggregationCreateNestedManyWithoutLeadInput>;
  state?: InputMaybe<Scalars['String']['input']>;
  StatLeadAggregation?: InputMaybe<StatLeadAggregationCreateNestedOneWithoutLeadInput>;
  status?: InputMaybe<Scalars['String']['input']>;
  sub_industry?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type LeadCreateWithoutCustom_FieldsInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  address_2?: InputMaybe<Scalars['String']['input']>;
  alternate_contacts?: InputMaybe<ContactCreateNestedManyWithoutLeadInput>;
  assignment_locked?: InputMaybe<Scalars['Boolean']['input']>;
  associate_children?: InputMaybe<LeadCreateNestedManyWithoutAssociate_Parent_LeadInput>;
  associate_group_lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutAssociate_Group_LeadsInput>;
  associate_lead_histories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutAssociate_LeadInput>;
  associate_parent_lead?: InputMaybe<LeadCreateNestedOneWithoutAssociate_ChildrenInput>;
  associated_parent_intents?: InputMaybe<LeadIntentCreateNestedManyWithoutAssociate_ParentInput>;
  associates_search_content?: InputMaybe<Scalars['String']['input']>;
  business_name?: InputMaybe<Scalars['String']['input']>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptCreateNestedManyWithoutLeadInput>;
  callMeNows?: InputMaybe<CallMeNowCreateNestedManyWithoutLeadInput>;
  channel?: InputMaybe<Channel>;
  child_lead_activites?: InputMaybe<LeadActivityCreateNestedManyWithoutAssociate_ChildInput>;
  city?: InputMaybe<Scalars['String']['input']>;
  claim_date?: InputMaybe<Scalars['DateTime']['input']>;
  conference_number?: InputMaybe<Scalars['String']['input']>;
  contact_requests?: InputMaybe<ContactRequestCreateNestedManyWithoutLeadInput>;
  content?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  current_close_date?: InputMaybe<Scalars['DateTime']['input']>;
  current_contact_date?: InputMaybe<Scalars['DateTime']['input']>;
  current_sale_cycle?: InputMaybe<SaleCycleCreateNestedOneWithoutLead_If_CurrentInput>;
  custom_field_json?: InputMaybe<Scalars['Json']['input']>;
  custom_queue_items?: InputMaybe<CustomQueueItemCreateNestedManyWithoutLeadInput>;
  delay_action_status?: InputMaybe<Scalars['Boolean']['input']>;
  email_sub_status?: InputMaybe<OptInStatus>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordCreateNestedManyWithoutLeadInput>;
  EmailThread?: InputMaybe<EmailThreadCreateNestedManyWithoutLeadInput>;
  favorited_by_users?: InputMaybe<UserCreateNestedManyWithoutFavorite_LeadsInput>;
  first_demo_schedule_date?: InputMaybe<Scalars['DateTime']['input']>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  hubspot_company_id?: InputMaybe<Scalars['String']['input']>;
  hubspot_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  implied_next_action?: InputMaybe<Hingepoint>;
  in_algo_queue?: InputMaybe<Scalars['Boolean']['input']>;
  in_custom_sequence?: InputMaybe<Scalars['Int']['input']>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventCreateNestedManyWithoutLeadInput>;
  industry?: InputMaybe<Scalars['String']['input']>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogCreateNestedManyWithoutLeadInput>;
  last_call_result?: InputMaybe<Scalars['String']['input']>;
  last_call_result_rep?: InputMaybe<UserCreateNestedOneWithoutLeads_Last_CalledInput>;
  last_call_result_time?: InputMaybe<Scalars['DateTime']['input']>;
  last_lead_assignment_origin?: InputMaybe<Scalars['DateTime']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  last_sent_to_resting_date?: InputMaybe<Scalars['DateTime']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutLeadInput>;
  lead_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutLeadInput>;
  lead_assignment_origin?: InputMaybe<AssignmentOrigin>;
  lead_creation_source?: InputMaybe<Leadcreationsource>;
  lead_custom_row_info?: InputMaybe<LeadCustomObjectRowAssociationCreateNestedManyWithoutLeadInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryCreateNestedOneWithoutLeadInput>;
  lead_import_history_csv?: InputMaybe<LeadImportHistoryCreateNestedOneWithoutUploaded_LeadsInput>;
  lead_intent?: InputMaybe<LeadIntentCreateNestedManyWithoutLeadInput>;
  lead_ownership_status?: InputMaybe<Los>;
  lead_source?: InputMaybe<Scalars['String']['input']>;
  lead_value?: InputMaybe<LeadValueCreateNestedOneWithoutLeadInput>;
  mrr?: InputMaybe<Scalars['Float']['input']>;
  newIncoming?: InputMaybe<Scalars['Boolean']['input']>;
  next_scheduled_event?: InputMaybe<ScheduleItemCreateNestedOneWithoutLeadInput>;
  notifications?: InputMaybe<NotificationCreateNestedManyWithoutLeadInput>;
  opsiq_whitelist?: InputMaybe<Scalars['Boolean']['input']>;
  organization: OrganizationCreateNestedOneWithoutLeadsInput;
  phase?: InputMaybe<Phase>;
  primary_email?: InputMaybe<Scalars['String']['input']>;
  primary_email_title?: InputMaybe<Scalars['String']['input']>;
  primary_email_type?: InputMaybe<Scalars['String']['input']>;
  primary_lead_histories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutPrimary_LeadInput>;
  primary_phone_number?: InputMaybe<Scalars['String']['input']>;
  primary_phone_number_country_code?: InputMaybe<Scalars['String']['input']>;
  primary_phone_number_title?: InputMaybe<Scalars['String']['input']>;
  primary_phone_number_type?: InputMaybe<Scalars['String']['input']>;
  rep?: InputMaybe<UserCreateNestedOneWithoutOwned_LeadsInput>;
  resting_type?: InputMaybe<Restingtype>;
  routing_history?: InputMaybe<RoutingHistoryCreateNestedManyWithoutLeadInput>;
  routing_rule?: InputMaybe<RuleCreateNestedOneWithoutLeadInput>;
  Sale?: InputMaybe<SaleCreateNestedManyWithoutLeadInput>;
  sale_cycles?: InputMaybe<SaleCycleCreateNestedManyWithoutLeadInput>;
  search_content?: InputMaybe<Scalars['String']['input']>;
  secondary_reps?: InputMaybe<UserCreateNestedManyWithoutIdle_Owned_LeadsInput>;
  self_sourced?: InputMaybe<Scalars['Boolean']['input']>;
  sequence?: InputMaybe<SequenceCreateNestedOneWithoutLeadsInput>;
  sequence_step?: InputMaybe<SequenceStepCreateNestedOneWithoutLeadsInput>;
  SequenceActionLog?: InputMaybe<StepActionLogCreateNestedManyWithoutLeadInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutLeadInput>;
  sequences_completed_ids?: InputMaybe<LeadCreatesequences_Completed_IdsInput>;
  sequences_joined_ids?: InputMaybe<LeadCreatesequences_Joined_IdsInput>;
  sf_account_id?: InputMaybe<Scalars['String']['input']>;
  sf_contact_id?: InputMaybe<Scalars['String']['input']>;
  sf_lead_id?: InputMaybe<Scalars['String']['input']>;
  sibling_lead_histories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutSibling_LeadsInput>;
  sms_sub_status?: InputMaybe<OptInStatus>;
  stat_items?: InputMaybe<StatItemAggregationCreateNestedManyWithoutLeadInput>;
  state?: InputMaybe<Scalars['String']['input']>;
  StatLeadAggregation?: InputMaybe<StatLeadAggregationCreateNestedOneWithoutLeadInput>;
  status?: InputMaybe<Scalars['String']['input']>;
  sub_industry?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type LeadCreateWithoutCustom_Queue_ItemsInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  address_2?: InputMaybe<Scalars['String']['input']>;
  alternate_contacts?: InputMaybe<ContactCreateNestedManyWithoutLeadInput>;
  assignment_locked?: InputMaybe<Scalars['Boolean']['input']>;
  associate_children?: InputMaybe<LeadCreateNestedManyWithoutAssociate_Parent_LeadInput>;
  associate_group_lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutAssociate_Group_LeadsInput>;
  associate_lead_histories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutAssociate_LeadInput>;
  associate_parent_lead?: InputMaybe<LeadCreateNestedOneWithoutAssociate_ChildrenInput>;
  associated_parent_intents?: InputMaybe<LeadIntentCreateNestedManyWithoutAssociate_ParentInput>;
  associates_search_content?: InputMaybe<Scalars['String']['input']>;
  business_name?: InputMaybe<Scalars['String']['input']>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptCreateNestedManyWithoutLeadInput>;
  callMeNows?: InputMaybe<CallMeNowCreateNestedManyWithoutLeadInput>;
  channel?: InputMaybe<Channel>;
  child_lead_activites?: InputMaybe<LeadActivityCreateNestedManyWithoutAssociate_ChildInput>;
  city?: InputMaybe<Scalars['String']['input']>;
  claim_date?: InputMaybe<Scalars['DateTime']['input']>;
  conference_number?: InputMaybe<Scalars['String']['input']>;
  contact_requests?: InputMaybe<ContactRequestCreateNestedManyWithoutLeadInput>;
  content?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  current_close_date?: InputMaybe<Scalars['DateTime']['input']>;
  current_contact_date?: InputMaybe<Scalars['DateTime']['input']>;
  current_sale_cycle?: InputMaybe<SaleCycleCreateNestedOneWithoutLead_If_CurrentInput>;
  custom_field_json?: InputMaybe<Scalars['Json']['input']>;
  custom_fields?: InputMaybe<CustomFieldCreateNestedManyWithoutLeadInput>;
  delay_action_status?: InputMaybe<Scalars['Boolean']['input']>;
  email_sub_status?: InputMaybe<OptInStatus>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordCreateNestedManyWithoutLeadInput>;
  EmailThread?: InputMaybe<EmailThreadCreateNestedManyWithoutLeadInput>;
  favorited_by_users?: InputMaybe<UserCreateNestedManyWithoutFavorite_LeadsInput>;
  first_demo_schedule_date?: InputMaybe<Scalars['DateTime']['input']>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  hubspot_company_id?: InputMaybe<Scalars['String']['input']>;
  hubspot_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  implied_next_action?: InputMaybe<Hingepoint>;
  in_algo_queue?: InputMaybe<Scalars['Boolean']['input']>;
  in_custom_sequence?: InputMaybe<Scalars['Int']['input']>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventCreateNestedManyWithoutLeadInput>;
  industry?: InputMaybe<Scalars['String']['input']>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogCreateNestedManyWithoutLeadInput>;
  last_call_result?: InputMaybe<Scalars['String']['input']>;
  last_call_result_rep?: InputMaybe<UserCreateNestedOneWithoutLeads_Last_CalledInput>;
  last_call_result_time?: InputMaybe<Scalars['DateTime']['input']>;
  last_lead_assignment_origin?: InputMaybe<Scalars['DateTime']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  last_sent_to_resting_date?: InputMaybe<Scalars['DateTime']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutLeadInput>;
  lead_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutLeadInput>;
  lead_assignment_origin?: InputMaybe<AssignmentOrigin>;
  lead_creation_source?: InputMaybe<Leadcreationsource>;
  lead_custom_row_info?: InputMaybe<LeadCustomObjectRowAssociationCreateNestedManyWithoutLeadInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryCreateNestedOneWithoutLeadInput>;
  lead_import_history_csv?: InputMaybe<LeadImportHistoryCreateNestedOneWithoutUploaded_LeadsInput>;
  lead_intent?: InputMaybe<LeadIntentCreateNestedManyWithoutLeadInput>;
  lead_ownership_status?: InputMaybe<Los>;
  lead_source?: InputMaybe<Scalars['String']['input']>;
  lead_value?: InputMaybe<LeadValueCreateNestedOneWithoutLeadInput>;
  mrr?: InputMaybe<Scalars['Float']['input']>;
  newIncoming?: InputMaybe<Scalars['Boolean']['input']>;
  next_scheduled_event?: InputMaybe<ScheduleItemCreateNestedOneWithoutLeadInput>;
  notifications?: InputMaybe<NotificationCreateNestedManyWithoutLeadInput>;
  opsiq_whitelist?: InputMaybe<Scalars['Boolean']['input']>;
  organization: OrganizationCreateNestedOneWithoutLeadsInput;
  phase?: InputMaybe<Phase>;
  primary_email?: InputMaybe<Scalars['String']['input']>;
  primary_email_title?: InputMaybe<Scalars['String']['input']>;
  primary_email_type?: InputMaybe<Scalars['String']['input']>;
  primary_lead_histories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutPrimary_LeadInput>;
  primary_phone_number?: InputMaybe<Scalars['String']['input']>;
  primary_phone_number_country_code?: InputMaybe<Scalars['String']['input']>;
  primary_phone_number_title?: InputMaybe<Scalars['String']['input']>;
  primary_phone_number_type?: InputMaybe<Scalars['String']['input']>;
  rep?: InputMaybe<UserCreateNestedOneWithoutOwned_LeadsInput>;
  resting_type?: InputMaybe<Restingtype>;
  routing_history?: InputMaybe<RoutingHistoryCreateNestedManyWithoutLeadInput>;
  routing_rule?: InputMaybe<RuleCreateNestedOneWithoutLeadInput>;
  Sale?: InputMaybe<SaleCreateNestedManyWithoutLeadInput>;
  sale_cycles?: InputMaybe<SaleCycleCreateNestedManyWithoutLeadInput>;
  search_content?: InputMaybe<Scalars['String']['input']>;
  secondary_reps?: InputMaybe<UserCreateNestedManyWithoutIdle_Owned_LeadsInput>;
  self_sourced?: InputMaybe<Scalars['Boolean']['input']>;
  sequence?: InputMaybe<SequenceCreateNestedOneWithoutLeadsInput>;
  sequence_step?: InputMaybe<SequenceStepCreateNestedOneWithoutLeadsInput>;
  SequenceActionLog?: InputMaybe<StepActionLogCreateNestedManyWithoutLeadInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutLeadInput>;
  sequences_completed_ids?: InputMaybe<LeadCreatesequences_Completed_IdsInput>;
  sequences_joined_ids?: InputMaybe<LeadCreatesequences_Joined_IdsInput>;
  sf_account_id?: InputMaybe<Scalars['String']['input']>;
  sf_contact_id?: InputMaybe<Scalars['String']['input']>;
  sf_lead_id?: InputMaybe<Scalars['String']['input']>;
  sibling_lead_histories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutSibling_LeadsInput>;
  sms_sub_status?: InputMaybe<OptInStatus>;
  stat_items?: InputMaybe<StatItemAggregationCreateNestedManyWithoutLeadInput>;
  state?: InputMaybe<Scalars['String']['input']>;
  StatLeadAggregation?: InputMaybe<StatLeadAggregationCreateNestedOneWithoutLeadInput>;
  status?: InputMaybe<Scalars['String']['input']>;
  sub_industry?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type LeadCreateWithoutEmailSyncRecordInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  address_2?: InputMaybe<Scalars['String']['input']>;
  alternate_contacts?: InputMaybe<ContactCreateNestedManyWithoutLeadInput>;
  assignment_locked?: InputMaybe<Scalars['Boolean']['input']>;
  associate_children?: InputMaybe<LeadCreateNestedManyWithoutAssociate_Parent_LeadInput>;
  associate_group_lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutAssociate_Group_LeadsInput>;
  associate_lead_histories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutAssociate_LeadInput>;
  associate_parent_lead?: InputMaybe<LeadCreateNestedOneWithoutAssociate_ChildrenInput>;
  associated_parent_intents?: InputMaybe<LeadIntentCreateNestedManyWithoutAssociate_ParentInput>;
  associates_search_content?: InputMaybe<Scalars['String']['input']>;
  business_name?: InputMaybe<Scalars['String']['input']>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptCreateNestedManyWithoutLeadInput>;
  callMeNows?: InputMaybe<CallMeNowCreateNestedManyWithoutLeadInput>;
  channel?: InputMaybe<Channel>;
  child_lead_activites?: InputMaybe<LeadActivityCreateNestedManyWithoutAssociate_ChildInput>;
  city?: InputMaybe<Scalars['String']['input']>;
  claim_date?: InputMaybe<Scalars['DateTime']['input']>;
  conference_number?: InputMaybe<Scalars['String']['input']>;
  contact_requests?: InputMaybe<ContactRequestCreateNestedManyWithoutLeadInput>;
  content?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  current_close_date?: InputMaybe<Scalars['DateTime']['input']>;
  current_contact_date?: InputMaybe<Scalars['DateTime']['input']>;
  current_sale_cycle?: InputMaybe<SaleCycleCreateNestedOneWithoutLead_If_CurrentInput>;
  custom_field_json?: InputMaybe<Scalars['Json']['input']>;
  custom_fields?: InputMaybe<CustomFieldCreateNestedManyWithoutLeadInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemCreateNestedManyWithoutLeadInput>;
  delay_action_status?: InputMaybe<Scalars['Boolean']['input']>;
  email_sub_status?: InputMaybe<OptInStatus>;
  EmailThread?: InputMaybe<EmailThreadCreateNestedManyWithoutLeadInput>;
  favorited_by_users?: InputMaybe<UserCreateNestedManyWithoutFavorite_LeadsInput>;
  first_demo_schedule_date?: InputMaybe<Scalars['DateTime']['input']>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  hubspot_company_id?: InputMaybe<Scalars['String']['input']>;
  hubspot_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  implied_next_action?: InputMaybe<Hingepoint>;
  in_algo_queue?: InputMaybe<Scalars['Boolean']['input']>;
  in_custom_sequence?: InputMaybe<Scalars['Int']['input']>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventCreateNestedManyWithoutLeadInput>;
  industry?: InputMaybe<Scalars['String']['input']>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogCreateNestedManyWithoutLeadInput>;
  last_call_result?: InputMaybe<Scalars['String']['input']>;
  last_call_result_rep?: InputMaybe<UserCreateNestedOneWithoutLeads_Last_CalledInput>;
  last_call_result_time?: InputMaybe<Scalars['DateTime']['input']>;
  last_lead_assignment_origin?: InputMaybe<Scalars['DateTime']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  last_sent_to_resting_date?: InputMaybe<Scalars['DateTime']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutLeadInput>;
  lead_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutLeadInput>;
  lead_assignment_origin?: InputMaybe<AssignmentOrigin>;
  lead_creation_source?: InputMaybe<Leadcreationsource>;
  lead_custom_row_info?: InputMaybe<LeadCustomObjectRowAssociationCreateNestedManyWithoutLeadInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryCreateNestedOneWithoutLeadInput>;
  lead_import_history_csv?: InputMaybe<LeadImportHistoryCreateNestedOneWithoutUploaded_LeadsInput>;
  lead_intent?: InputMaybe<LeadIntentCreateNestedManyWithoutLeadInput>;
  lead_ownership_status?: InputMaybe<Los>;
  lead_source?: InputMaybe<Scalars['String']['input']>;
  lead_value?: InputMaybe<LeadValueCreateNestedOneWithoutLeadInput>;
  mrr?: InputMaybe<Scalars['Float']['input']>;
  newIncoming?: InputMaybe<Scalars['Boolean']['input']>;
  next_scheduled_event?: InputMaybe<ScheduleItemCreateNestedOneWithoutLeadInput>;
  notifications?: InputMaybe<NotificationCreateNestedManyWithoutLeadInput>;
  opsiq_whitelist?: InputMaybe<Scalars['Boolean']['input']>;
  organization: OrganizationCreateNestedOneWithoutLeadsInput;
  phase?: InputMaybe<Phase>;
  primary_email?: InputMaybe<Scalars['String']['input']>;
  primary_email_title?: InputMaybe<Scalars['String']['input']>;
  primary_email_type?: InputMaybe<Scalars['String']['input']>;
  primary_lead_histories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutPrimary_LeadInput>;
  primary_phone_number?: InputMaybe<Scalars['String']['input']>;
  primary_phone_number_country_code?: InputMaybe<Scalars['String']['input']>;
  primary_phone_number_title?: InputMaybe<Scalars['String']['input']>;
  primary_phone_number_type?: InputMaybe<Scalars['String']['input']>;
  rep?: InputMaybe<UserCreateNestedOneWithoutOwned_LeadsInput>;
  resting_type?: InputMaybe<Restingtype>;
  routing_history?: InputMaybe<RoutingHistoryCreateNestedManyWithoutLeadInput>;
  routing_rule?: InputMaybe<RuleCreateNestedOneWithoutLeadInput>;
  Sale?: InputMaybe<SaleCreateNestedManyWithoutLeadInput>;
  sale_cycles?: InputMaybe<SaleCycleCreateNestedManyWithoutLeadInput>;
  search_content?: InputMaybe<Scalars['String']['input']>;
  secondary_reps?: InputMaybe<UserCreateNestedManyWithoutIdle_Owned_LeadsInput>;
  self_sourced?: InputMaybe<Scalars['Boolean']['input']>;
  sequence?: InputMaybe<SequenceCreateNestedOneWithoutLeadsInput>;
  sequence_step?: InputMaybe<SequenceStepCreateNestedOneWithoutLeadsInput>;
  SequenceActionLog?: InputMaybe<StepActionLogCreateNestedManyWithoutLeadInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutLeadInput>;
  sequences_completed_ids?: InputMaybe<LeadCreatesequences_Completed_IdsInput>;
  sequences_joined_ids?: InputMaybe<LeadCreatesequences_Joined_IdsInput>;
  sf_account_id?: InputMaybe<Scalars['String']['input']>;
  sf_contact_id?: InputMaybe<Scalars['String']['input']>;
  sf_lead_id?: InputMaybe<Scalars['String']['input']>;
  sibling_lead_histories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutSibling_LeadsInput>;
  sms_sub_status?: InputMaybe<OptInStatus>;
  stat_items?: InputMaybe<StatItemAggregationCreateNestedManyWithoutLeadInput>;
  state?: InputMaybe<Scalars['String']['input']>;
  StatLeadAggregation?: InputMaybe<StatLeadAggregationCreateNestedOneWithoutLeadInput>;
  status?: InputMaybe<Scalars['String']['input']>;
  sub_industry?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type LeadCreateWithoutEmailThreadInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  address_2?: InputMaybe<Scalars['String']['input']>;
  alternate_contacts?: InputMaybe<ContactCreateNestedManyWithoutLeadInput>;
  assignment_locked?: InputMaybe<Scalars['Boolean']['input']>;
  associate_children?: InputMaybe<LeadCreateNestedManyWithoutAssociate_Parent_LeadInput>;
  associate_group_lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutAssociate_Group_LeadsInput>;
  associate_lead_histories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutAssociate_LeadInput>;
  associate_parent_lead?: InputMaybe<LeadCreateNestedOneWithoutAssociate_ChildrenInput>;
  associated_parent_intents?: InputMaybe<LeadIntentCreateNestedManyWithoutAssociate_ParentInput>;
  associates_search_content?: InputMaybe<Scalars['String']['input']>;
  business_name?: InputMaybe<Scalars['String']['input']>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptCreateNestedManyWithoutLeadInput>;
  callMeNows?: InputMaybe<CallMeNowCreateNestedManyWithoutLeadInput>;
  channel?: InputMaybe<Channel>;
  child_lead_activites?: InputMaybe<LeadActivityCreateNestedManyWithoutAssociate_ChildInput>;
  city?: InputMaybe<Scalars['String']['input']>;
  claim_date?: InputMaybe<Scalars['DateTime']['input']>;
  conference_number?: InputMaybe<Scalars['String']['input']>;
  contact_requests?: InputMaybe<ContactRequestCreateNestedManyWithoutLeadInput>;
  content?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  current_close_date?: InputMaybe<Scalars['DateTime']['input']>;
  current_contact_date?: InputMaybe<Scalars['DateTime']['input']>;
  current_sale_cycle?: InputMaybe<SaleCycleCreateNestedOneWithoutLead_If_CurrentInput>;
  custom_field_json?: InputMaybe<Scalars['Json']['input']>;
  custom_fields?: InputMaybe<CustomFieldCreateNestedManyWithoutLeadInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemCreateNestedManyWithoutLeadInput>;
  delay_action_status?: InputMaybe<Scalars['Boolean']['input']>;
  email_sub_status?: InputMaybe<OptInStatus>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordCreateNestedManyWithoutLeadInput>;
  favorited_by_users?: InputMaybe<UserCreateNestedManyWithoutFavorite_LeadsInput>;
  first_demo_schedule_date?: InputMaybe<Scalars['DateTime']['input']>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  hubspot_company_id?: InputMaybe<Scalars['String']['input']>;
  hubspot_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  implied_next_action?: InputMaybe<Hingepoint>;
  in_algo_queue?: InputMaybe<Scalars['Boolean']['input']>;
  in_custom_sequence?: InputMaybe<Scalars['Int']['input']>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventCreateNestedManyWithoutLeadInput>;
  industry?: InputMaybe<Scalars['String']['input']>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogCreateNestedManyWithoutLeadInput>;
  last_call_result?: InputMaybe<Scalars['String']['input']>;
  last_call_result_rep?: InputMaybe<UserCreateNestedOneWithoutLeads_Last_CalledInput>;
  last_call_result_time?: InputMaybe<Scalars['DateTime']['input']>;
  last_lead_assignment_origin?: InputMaybe<Scalars['DateTime']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  last_sent_to_resting_date?: InputMaybe<Scalars['DateTime']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutLeadInput>;
  lead_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutLeadInput>;
  lead_assignment_origin?: InputMaybe<AssignmentOrigin>;
  lead_creation_source?: InputMaybe<Leadcreationsource>;
  lead_custom_row_info?: InputMaybe<LeadCustomObjectRowAssociationCreateNestedManyWithoutLeadInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryCreateNestedOneWithoutLeadInput>;
  lead_import_history_csv?: InputMaybe<LeadImportHistoryCreateNestedOneWithoutUploaded_LeadsInput>;
  lead_intent?: InputMaybe<LeadIntentCreateNestedManyWithoutLeadInput>;
  lead_ownership_status?: InputMaybe<Los>;
  lead_source?: InputMaybe<Scalars['String']['input']>;
  lead_value?: InputMaybe<LeadValueCreateNestedOneWithoutLeadInput>;
  mrr?: InputMaybe<Scalars['Float']['input']>;
  newIncoming?: InputMaybe<Scalars['Boolean']['input']>;
  next_scheduled_event?: InputMaybe<ScheduleItemCreateNestedOneWithoutLeadInput>;
  notifications?: InputMaybe<NotificationCreateNestedManyWithoutLeadInput>;
  opsiq_whitelist?: InputMaybe<Scalars['Boolean']['input']>;
  organization: OrganizationCreateNestedOneWithoutLeadsInput;
  phase?: InputMaybe<Phase>;
  primary_email?: InputMaybe<Scalars['String']['input']>;
  primary_email_title?: InputMaybe<Scalars['String']['input']>;
  primary_email_type?: InputMaybe<Scalars['String']['input']>;
  primary_lead_histories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutPrimary_LeadInput>;
  primary_phone_number?: InputMaybe<Scalars['String']['input']>;
  primary_phone_number_country_code?: InputMaybe<Scalars['String']['input']>;
  primary_phone_number_title?: InputMaybe<Scalars['String']['input']>;
  primary_phone_number_type?: InputMaybe<Scalars['String']['input']>;
  rep?: InputMaybe<UserCreateNestedOneWithoutOwned_LeadsInput>;
  resting_type?: InputMaybe<Restingtype>;
  routing_history?: InputMaybe<RoutingHistoryCreateNestedManyWithoutLeadInput>;
  routing_rule?: InputMaybe<RuleCreateNestedOneWithoutLeadInput>;
  Sale?: InputMaybe<SaleCreateNestedManyWithoutLeadInput>;
  sale_cycles?: InputMaybe<SaleCycleCreateNestedManyWithoutLeadInput>;
  search_content?: InputMaybe<Scalars['String']['input']>;
  secondary_reps?: InputMaybe<UserCreateNestedManyWithoutIdle_Owned_LeadsInput>;
  self_sourced?: InputMaybe<Scalars['Boolean']['input']>;
  sequence?: InputMaybe<SequenceCreateNestedOneWithoutLeadsInput>;
  sequence_step?: InputMaybe<SequenceStepCreateNestedOneWithoutLeadsInput>;
  SequenceActionLog?: InputMaybe<StepActionLogCreateNestedManyWithoutLeadInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutLeadInput>;
  sequences_completed_ids?: InputMaybe<LeadCreatesequences_Completed_IdsInput>;
  sequences_joined_ids?: InputMaybe<LeadCreatesequences_Joined_IdsInput>;
  sf_account_id?: InputMaybe<Scalars['String']['input']>;
  sf_contact_id?: InputMaybe<Scalars['String']['input']>;
  sf_lead_id?: InputMaybe<Scalars['String']['input']>;
  sibling_lead_histories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutSibling_LeadsInput>;
  sms_sub_status?: InputMaybe<OptInStatus>;
  stat_items?: InputMaybe<StatItemAggregationCreateNestedManyWithoutLeadInput>;
  state?: InputMaybe<Scalars['String']['input']>;
  StatLeadAggregation?: InputMaybe<StatLeadAggregationCreateNestedOneWithoutLeadInput>;
  status?: InputMaybe<Scalars['String']['input']>;
  sub_industry?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type LeadCreateWithoutFavorited_By_UsersInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  address_2?: InputMaybe<Scalars['String']['input']>;
  alternate_contacts?: InputMaybe<ContactCreateNestedManyWithoutLeadInput>;
  assignment_locked?: InputMaybe<Scalars['Boolean']['input']>;
  associate_children?: InputMaybe<LeadCreateNestedManyWithoutAssociate_Parent_LeadInput>;
  associate_group_lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutAssociate_Group_LeadsInput>;
  associate_lead_histories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutAssociate_LeadInput>;
  associate_parent_lead?: InputMaybe<LeadCreateNestedOneWithoutAssociate_ChildrenInput>;
  associated_parent_intents?: InputMaybe<LeadIntentCreateNestedManyWithoutAssociate_ParentInput>;
  associates_search_content?: InputMaybe<Scalars['String']['input']>;
  business_name?: InputMaybe<Scalars['String']['input']>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptCreateNestedManyWithoutLeadInput>;
  callMeNows?: InputMaybe<CallMeNowCreateNestedManyWithoutLeadInput>;
  channel?: InputMaybe<Channel>;
  child_lead_activites?: InputMaybe<LeadActivityCreateNestedManyWithoutAssociate_ChildInput>;
  city?: InputMaybe<Scalars['String']['input']>;
  claim_date?: InputMaybe<Scalars['DateTime']['input']>;
  conference_number?: InputMaybe<Scalars['String']['input']>;
  contact_requests?: InputMaybe<ContactRequestCreateNestedManyWithoutLeadInput>;
  content?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  current_close_date?: InputMaybe<Scalars['DateTime']['input']>;
  current_contact_date?: InputMaybe<Scalars['DateTime']['input']>;
  current_sale_cycle?: InputMaybe<SaleCycleCreateNestedOneWithoutLead_If_CurrentInput>;
  custom_field_json?: InputMaybe<Scalars['Json']['input']>;
  custom_fields?: InputMaybe<CustomFieldCreateNestedManyWithoutLeadInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemCreateNestedManyWithoutLeadInput>;
  delay_action_status?: InputMaybe<Scalars['Boolean']['input']>;
  email_sub_status?: InputMaybe<OptInStatus>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordCreateNestedManyWithoutLeadInput>;
  EmailThread?: InputMaybe<EmailThreadCreateNestedManyWithoutLeadInput>;
  first_demo_schedule_date?: InputMaybe<Scalars['DateTime']['input']>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  hubspot_company_id?: InputMaybe<Scalars['String']['input']>;
  hubspot_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  implied_next_action?: InputMaybe<Hingepoint>;
  in_algo_queue?: InputMaybe<Scalars['Boolean']['input']>;
  in_custom_sequence?: InputMaybe<Scalars['Int']['input']>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventCreateNestedManyWithoutLeadInput>;
  industry?: InputMaybe<Scalars['String']['input']>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogCreateNestedManyWithoutLeadInput>;
  last_call_result?: InputMaybe<Scalars['String']['input']>;
  last_call_result_rep?: InputMaybe<UserCreateNestedOneWithoutLeads_Last_CalledInput>;
  last_call_result_time?: InputMaybe<Scalars['DateTime']['input']>;
  last_lead_assignment_origin?: InputMaybe<Scalars['DateTime']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  last_sent_to_resting_date?: InputMaybe<Scalars['DateTime']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutLeadInput>;
  lead_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutLeadInput>;
  lead_assignment_origin?: InputMaybe<AssignmentOrigin>;
  lead_creation_source?: InputMaybe<Leadcreationsource>;
  lead_custom_row_info?: InputMaybe<LeadCustomObjectRowAssociationCreateNestedManyWithoutLeadInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryCreateNestedOneWithoutLeadInput>;
  lead_import_history_csv?: InputMaybe<LeadImportHistoryCreateNestedOneWithoutUploaded_LeadsInput>;
  lead_intent?: InputMaybe<LeadIntentCreateNestedManyWithoutLeadInput>;
  lead_ownership_status?: InputMaybe<Los>;
  lead_source?: InputMaybe<Scalars['String']['input']>;
  lead_value?: InputMaybe<LeadValueCreateNestedOneWithoutLeadInput>;
  mrr?: InputMaybe<Scalars['Float']['input']>;
  newIncoming?: InputMaybe<Scalars['Boolean']['input']>;
  next_scheduled_event?: InputMaybe<ScheduleItemCreateNestedOneWithoutLeadInput>;
  notifications?: InputMaybe<NotificationCreateNestedManyWithoutLeadInput>;
  opsiq_whitelist?: InputMaybe<Scalars['Boolean']['input']>;
  organization: OrganizationCreateNestedOneWithoutLeadsInput;
  phase?: InputMaybe<Phase>;
  primary_email?: InputMaybe<Scalars['String']['input']>;
  primary_email_title?: InputMaybe<Scalars['String']['input']>;
  primary_email_type?: InputMaybe<Scalars['String']['input']>;
  primary_lead_histories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutPrimary_LeadInput>;
  primary_phone_number?: InputMaybe<Scalars['String']['input']>;
  primary_phone_number_country_code?: InputMaybe<Scalars['String']['input']>;
  primary_phone_number_title?: InputMaybe<Scalars['String']['input']>;
  primary_phone_number_type?: InputMaybe<Scalars['String']['input']>;
  rep?: InputMaybe<UserCreateNestedOneWithoutOwned_LeadsInput>;
  resting_type?: InputMaybe<Restingtype>;
  routing_history?: InputMaybe<RoutingHistoryCreateNestedManyWithoutLeadInput>;
  routing_rule?: InputMaybe<RuleCreateNestedOneWithoutLeadInput>;
  Sale?: InputMaybe<SaleCreateNestedManyWithoutLeadInput>;
  sale_cycles?: InputMaybe<SaleCycleCreateNestedManyWithoutLeadInput>;
  search_content?: InputMaybe<Scalars['String']['input']>;
  secondary_reps?: InputMaybe<UserCreateNestedManyWithoutIdle_Owned_LeadsInput>;
  self_sourced?: InputMaybe<Scalars['Boolean']['input']>;
  sequence?: InputMaybe<SequenceCreateNestedOneWithoutLeadsInput>;
  sequence_step?: InputMaybe<SequenceStepCreateNestedOneWithoutLeadsInput>;
  SequenceActionLog?: InputMaybe<StepActionLogCreateNestedManyWithoutLeadInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutLeadInput>;
  sequences_completed_ids?: InputMaybe<LeadCreatesequences_Completed_IdsInput>;
  sequences_joined_ids?: InputMaybe<LeadCreatesequences_Joined_IdsInput>;
  sf_account_id?: InputMaybe<Scalars['String']['input']>;
  sf_contact_id?: InputMaybe<Scalars['String']['input']>;
  sf_lead_id?: InputMaybe<Scalars['String']['input']>;
  sibling_lead_histories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutSibling_LeadsInput>;
  sms_sub_status?: InputMaybe<OptInStatus>;
  stat_items?: InputMaybe<StatItemAggregationCreateNestedManyWithoutLeadInput>;
  state?: InputMaybe<Scalars['String']['input']>;
  StatLeadAggregation?: InputMaybe<StatLeadAggregationCreateNestedOneWithoutLeadInput>;
  status?: InputMaybe<Scalars['String']['input']>;
  sub_industry?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type LeadCreateWithoutInboundConciergeEventsInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  address_2?: InputMaybe<Scalars['String']['input']>;
  alternate_contacts?: InputMaybe<ContactCreateNestedManyWithoutLeadInput>;
  assignment_locked?: InputMaybe<Scalars['Boolean']['input']>;
  associate_children?: InputMaybe<LeadCreateNestedManyWithoutAssociate_Parent_LeadInput>;
  associate_group_lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutAssociate_Group_LeadsInput>;
  associate_lead_histories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutAssociate_LeadInput>;
  associate_parent_lead?: InputMaybe<LeadCreateNestedOneWithoutAssociate_ChildrenInput>;
  associated_parent_intents?: InputMaybe<LeadIntentCreateNestedManyWithoutAssociate_ParentInput>;
  associates_search_content?: InputMaybe<Scalars['String']['input']>;
  business_name?: InputMaybe<Scalars['String']['input']>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptCreateNestedManyWithoutLeadInput>;
  callMeNows?: InputMaybe<CallMeNowCreateNestedManyWithoutLeadInput>;
  channel?: InputMaybe<Channel>;
  child_lead_activites?: InputMaybe<LeadActivityCreateNestedManyWithoutAssociate_ChildInput>;
  city?: InputMaybe<Scalars['String']['input']>;
  claim_date?: InputMaybe<Scalars['DateTime']['input']>;
  conference_number?: InputMaybe<Scalars['String']['input']>;
  contact_requests?: InputMaybe<ContactRequestCreateNestedManyWithoutLeadInput>;
  content?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  current_close_date?: InputMaybe<Scalars['DateTime']['input']>;
  current_contact_date?: InputMaybe<Scalars['DateTime']['input']>;
  current_sale_cycle?: InputMaybe<SaleCycleCreateNestedOneWithoutLead_If_CurrentInput>;
  custom_field_json?: InputMaybe<Scalars['Json']['input']>;
  custom_fields?: InputMaybe<CustomFieldCreateNestedManyWithoutLeadInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemCreateNestedManyWithoutLeadInput>;
  delay_action_status?: InputMaybe<Scalars['Boolean']['input']>;
  email_sub_status?: InputMaybe<OptInStatus>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordCreateNestedManyWithoutLeadInput>;
  EmailThread?: InputMaybe<EmailThreadCreateNestedManyWithoutLeadInput>;
  favorited_by_users?: InputMaybe<UserCreateNestedManyWithoutFavorite_LeadsInput>;
  first_demo_schedule_date?: InputMaybe<Scalars['DateTime']['input']>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  hubspot_company_id?: InputMaybe<Scalars['String']['input']>;
  hubspot_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  implied_next_action?: InputMaybe<Hingepoint>;
  in_algo_queue?: InputMaybe<Scalars['Boolean']['input']>;
  in_custom_sequence?: InputMaybe<Scalars['Int']['input']>;
  industry?: InputMaybe<Scalars['String']['input']>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogCreateNestedManyWithoutLeadInput>;
  last_call_result?: InputMaybe<Scalars['String']['input']>;
  last_call_result_rep?: InputMaybe<UserCreateNestedOneWithoutLeads_Last_CalledInput>;
  last_call_result_time?: InputMaybe<Scalars['DateTime']['input']>;
  last_lead_assignment_origin?: InputMaybe<Scalars['DateTime']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  last_sent_to_resting_date?: InputMaybe<Scalars['DateTime']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutLeadInput>;
  lead_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutLeadInput>;
  lead_assignment_origin?: InputMaybe<AssignmentOrigin>;
  lead_creation_source?: InputMaybe<Leadcreationsource>;
  lead_custom_row_info?: InputMaybe<LeadCustomObjectRowAssociationCreateNestedManyWithoutLeadInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryCreateNestedOneWithoutLeadInput>;
  lead_import_history_csv?: InputMaybe<LeadImportHistoryCreateNestedOneWithoutUploaded_LeadsInput>;
  lead_intent?: InputMaybe<LeadIntentCreateNestedManyWithoutLeadInput>;
  lead_ownership_status?: InputMaybe<Los>;
  lead_source?: InputMaybe<Scalars['String']['input']>;
  lead_value?: InputMaybe<LeadValueCreateNestedOneWithoutLeadInput>;
  mrr?: InputMaybe<Scalars['Float']['input']>;
  newIncoming?: InputMaybe<Scalars['Boolean']['input']>;
  next_scheduled_event?: InputMaybe<ScheduleItemCreateNestedOneWithoutLeadInput>;
  notifications?: InputMaybe<NotificationCreateNestedManyWithoutLeadInput>;
  opsiq_whitelist?: InputMaybe<Scalars['Boolean']['input']>;
  organization: OrganizationCreateNestedOneWithoutLeadsInput;
  phase?: InputMaybe<Phase>;
  primary_email?: InputMaybe<Scalars['String']['input']>;
  primary_email_title?: InputMaybe<Scalars['String']['input']>;
  primary_email_type?: InputMaybe<Scalars['String']['input']>;
  primary_lead_histories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutPrimary_LeadInput>;
  primary_phone_number?: InputMaybe<Scalars['String']['input']>;
  primary_phone_number_country_code?: InputMaybe<Scalars['String']['input']>;
  primary_phone_number_title?: InputMaybe<Scalars['String']['input']>;
  primary_phone_number_type?: InputMaybe<Scalars['String']['input']>;
  rep?: InputMaybe<UserCreateNestedOneWithoutOwned_LeadsInput>;
  resting_type?: InputMaybe<Restingtype>;
  routing_history?: InputMaybe<RoutingHistoryCreateNestedManyWithoutLeadInput>;
  routing_rule?: InputMaybe<RuleCreateNestedOneWithoutLeadInput>;
  Sale?: InputMaybe<SaleCreateNestedManyWithoutLeadInput>;
  sale_cycles?: InputMaybe<SaleCycleCreateNestedManyWithoutLeadInput>;
  search_content?: InputMaybe<Scalars['String']['input']>;
  secondary_reps?: InputMaybe<UserCreateNestedManyWithoutIdle_Owned_LeadsInput>;
  self_sourced?: InputMaybe<Scalars['Boolean']['input']>;
  sequence?: InputMaybe<SequenceCreateNestedOneWithoutLeadsInput>;
  sequence_step?: InputMaybe<SequenceStepCreateNestedOneWithoutLeadsInput>;
  SequenceActionLog?: InputMaybe<StepActionLogCreateNestedManyWithoutLeadInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutLeadInput>;
  sequences_completed_ids?: InputMaybe<LeadCreatesequences_Completed_IdsInput>;
  sequences_joined_ids?: InputMaybe<LeadCreatesequences_Joined_IdsInput>;
  sf_account_id?: InputMaybe<Scalars['String']['input']>;
  sf_contact_id?: InputMaybe<Scalars['String']['input']>;
  sf_lead_id?: InputMaybe<Scalars['String']['input']>;
  sibling_lead_histories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutSibling_LeadsInput>;
  sms_sub_status?: InputMaybe<OptInStatus>;
  stat_items?: InputMaybe<StatItemAggregationCreateNestedManyWithoutLeadInput>;
  state?: InputMaybe<Scalars['String']['input']>;
  StatLeadAggregation?: InputMaybe<StatLeadAggregationCreateNestedOneWithoutLeadInput>;
  status?: InputMaybe<Scalars['String']['input']>;
  sub_industry?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type LeadCreateWithoutIntegrationOperationLogInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  address_2?: InputMaybe<Scalars['String']['input']>;
  alternate_contacts?: InputMaybe<ContactCreateNestedManyWithoutLeadInput>;
  assignment_locked?: InputMaybe<Scalars['Boolean']['input']>;
  associate_children?: InputMaybe<LeadCreateNestedManyWithoutAssociate_Parent_LeadInput>;
  associate_group_lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutAssociate_Group_LeadsInput>;
  associate_lead_histories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutAssociate_LeadInput>;
  associate_parent_lead?: InputMaybe<LeadCreateNestedOneWithoutAssociate_ChildrenInput>;
  associated_parent_intents?: InputMaybe<LeadIntentCreateNestedManyWithoutAssociate_ParentInput>;
  associates_search_content?: InputMaybe<Scalars['String']['input']>;
  business_name?: InputMaybe<Scalars['String']['input']>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptCreateNestedManyWithoutLeadInput>;
  callMeNows?: InputMaybe<CallMeNowCreateNestedManyWithoutLeadInput>;
  channel?: InputMaybe<Channel>;
  child_lead_activites?: InputMaybe<LeadActivityCreateNestedManyWithoutAssociate_ChildInput>;
  city?: InputMaybe<Scalars['String']['input']>;
  claim_date?: InputMaybe<Scalars['DateTime']['input']>;
  conference_number?: InputMaybe<Scalars['String']['input']>;
  contact_requests?: InputMaybe<ContactRequestCreateNestedManyWithoutLeadInput>;
  content?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  current_close_date?: InputMaybe<Scalars['DateTime']['input']>;
  current_contact_date?: InputMaybe<Scalars['DateTime']['input']>;
  current_sale_cycle?: InputMaybe<SaleCycleCreateNestedOneWithoutLead_If_CurrentInput>;
  custom_field_json?: InputMaybe<Scalars['Json']['input']>;
  custom_fields?: InputMaybe<CustomFieldCreateNestedManyWithoutLeadInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemCreateNestedManyWithoutLeadInput>;
  delay_action_status?: InputMaybe<Scalars['Boolean']['input']>;
  email_sub_status?: InputMaybe<OptInStatus>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordCreateNestedManyWithoutLeadInput>;
  EmailThread?: InputMaybe<EmailThreadCreateNestedManyWithoutLeadInput>;
  favorited_by_users?: InputMaybe<UserCreateNestedManyWithoutFavorite_LeadsInput>;
  first_demo_schedule_date?: InputMaybe<Scalars['DateTime']['input']>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  hubspot_company_id?: InputMaybe<Scalars['String']['input']>;
  hubspot_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  implied_next_action?: InputMaybe<Hingepoint>;
  in_algo_queue?: InputMaybe<Scalars['Boolean']['input']>;
  in_custom_sequence?: InputMaybe<Scalars['Int']['input']>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventCreateNestedManyWithoutLeadInput>;
  industry?: InputMaybe<Scalars['String']['input']>;
  last_call_result?: InputMaybe<Scalars['String']['input']>;
  last_call_result_rep?: InputMaybe<UserCreateNestedOneWithoutLeads_Last_CalledInput>;
  last_call_result_time?: InputMaybe<Scalars['DateTime']['input']>;
  last_lead_assignment_origin?: InputMaybe<Scalars['DateTime']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  last_sent_to_resting_date?: InputMaybe<Scalars['DateTime']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutLeadInput>;
  lead_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutLeadInput>;
  lead_assignment_origin?: InputMaybe<AssignmentOrigin>;
  lead_creation_source?: InputMaybe<Leadcreationsource>;
  lead_custom_row_info?: InputMaybe<LeadCustomObjectRowAssociationCreateNestedManyWithoutLeadInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryCreateNestedOneWithoutLeadInput>;
  lead_import_history_csv?: InputMaybe<LeadImportHistoryCreateNestedOneWithoutUploaded_LeadsInput>;
  lead_intent?: InputMaybe<LeadIntentCreateNestedManyWithoutLeadInput>;
  lead_ownership_status?: InputMaybe<Los>;
  lead_source?: InputMaybe<Scalars['String']['input']>;
  lead_value?: InputMaybe<LeadValueCreateNestedOneWithoutLeadInput>;
  mrr?: InputMaybe<Scalars['Float']['input']>;
  newIncoming?: InputMaybe<Scalars['Boolean']['input']>;
  next_scheduled_event?: InputMaybe<ScheduleItemCreateNestedOneWithoutLeadInput>;
  notifications?: InputMaybe<NotificationCreateNestedManyWithoutLeadInput>;
  opsiq_whitelist?: InputMaybe<Scalars['Boolean']['input']>;
  organization: OrganizationCreateNestedOneWithoutLeadsInput;
  phase?: InputMaybe<Phase>;
  primary_email?: InputMaybe<Scalars['String']['input']>;
  primary_email_title?: InputMaybe<Scalars['String']['input']>;
  primary_email_type?: InputMaybe<Scalars['String']['input']>;
  primary_lead_histories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutPrimary_LeadInput>;
  primary_phone_number?: InputMaybe<Scalars['String']['input']>;
  primary_phone_number_country_code?: InputMaybe<Scalars['String']['input']>;
  primary_phone_number_title?: InputMaybe<Scalars['String']['input']>;
  primary_phone_number_type?: InputMaybe<Scalars['String']['input']>;
  rep?: InputMaybe<UserCreateNestedOneWithoutOwned_LeadsInput>;
  resting_type?: InputMaybe<Restingtype>;
  routing_history?: InputMaybe<RoutingHistoryCreateNestedManyWithoutLeadInput>;
  routing_rule?: InputMaybe<RuleCreateNestedOneWithoutLeadInput>;
  Sale?: InputMaybe<SaleCreateNestedManyWithoutLeadInput>;
  sale_cycles?: InputMaybe<SaleCycleCreateNestedManyWithoutLeadInput>;
  search_content?: InputMaybe<Scalars['String']['input']>;
  secondary_reps?: InputMaybe<UserCreateNestedManyWithoutIdle_Owned_LeadsInput>;
  self_sourced?: InputMaybe<Scalars['Boolean']['input']>;
  sequence?: InputMaybe<SequenceCreateNestedOneWithoutLeadsInput>;
  sequence_step?: InputMaybe<SequenceStepCreateNestedOneWithoutLeadsInput>;
  SequenceActionLog?: InputMaybe<StepActionLogCreateNestedManyWithoutLeadInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutLeadInput>;
  sequences_completed_ids?: InputMaybe<LeadCreatesequences_Completed_IdsInput>;
  sequences_joined_ids?: InputMaybe<LeadCreatesequences_Joined_IdsInput>;
  sf_account_id?: InputMaybe<Scalars['String']['input']>;
  sf_contact_id?: InputMaybe<Scalars['String']['input']>;
  sf_lead_id?: InputMaybe<Scalars['String']['input']>;
  sibling_lead_histories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutSibling_LeadsInput>;
  sms_sub_status?: InputMaybe<OptInStatus>;
  stat_items?: InputMaybe<StatItemAggregationCreateNestedManyWithoutLeadInput>;
  state?: InputMaybe<Scalars['String']['input']>;
  StatLeadAggregation?: InputMaybe<StatLeadAggregationCreateNestedOneWithoutLeadInput>;
  status?: InputMaybe<Scalars['String']['input']>;
  sub_industry?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type LeadCreateWithoutLast_Call_Result_RepInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  address_2?: InputMaybe<Scalars['String']['input']>;
  alternate_contacts?: InputMaybe<ContactCreateNestedManyWithoutLeadInput>;
  assignment_locked?: InputMaybe<Scalars['Boolean']['input']>;
  associate_children?: InputMaybe<LeadCreateNestedManyWithoutAssociate_Parent_LeadInput>;
  associate_group_lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutAssociate_Group_LeadsInput>;
  associate_lead_histories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutAssociate_LeadInput>;
  associate_parent_lead?: InputMaybe<LeadCreateNestedOneWithoutAssociate_ChildrenInput>;
  associated_parent_intents?: InputMaybe<LeadIntentCreateNestedManyWithoutAssociate_ParentInput>;
  associates_search_content?: InputMaybe<Scalars['String']['input']>;
  business_name?: InputMaybe<Scalars['String']['input']>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptCreateNestedManyWithoutLeadInput>;
  callMeNows?: InputMaybe<CallMeNowCreateNestedManyWithoutLeadInput>;
  channel?: InputMaybe<Channel>;
  child_lead_activites?: InputMaybe<LeadActivityCreateNestedManyWithoutAssociate_ChildInput>;
  city?: InputMaybe<Scalars['String']['input']>;
  claim_date?: InputMaybe<Scalars['DateTime']['input']>;
  conference_number?: InputMaybe<Scalars['String']['input']>;
  contact_requests?: InputMaybe<ContactRequestCreateNestedManyWithoutLeadInput>;
  content?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  current_close_date?: InputMaybe<Scalars['DateTime']['input']>;
  current_contact_date?: InputMaybe<Scalars['DateTime']['input']>;
  current_sale_cycle?: InputMaybe<SaleCycleCreateNestedOneWithoutLead_If_CurrentInput>;
  custom_field_json?: InputMaybe<Scalars['Json']['input']>;
  custom_fields?: InputMaybe<CustomFieldCreateNestedManyWithoutLeadInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemCreateNestedManyWithoutLeadInput>;
  delay_action_status?: InputMaybe<Scalars['Boolean']['input']>;
  email_sub_status?: InputMaybe<OptInStatus>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordCreateNestedManyWithoutLeadInput>;
  EmailThread?: InputMaybe<EmailThreadCreateNestedManyWithoutLeadInput>;
  favorited_by_users?: InputMaybe<UserCreateNestedManyWithoutFavorite_LeadsInput>;
  first_demo_schedule_date?: InputMaybe<Scalars['DateTime']['input']>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  hubspot_company_id?: InputMaybe<Scalars['String']['input']>;
  hubspot_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  implied_next_action?: InputMaybe<Hingepoint>;
  in_algo_queue?: InputMaybe<Scalars['Boolean']['input']>;
  in_custom_sequence?: InputMaybe<Scalars['Int']['input']>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventCreateNestedManyWithoutLeadInput>;
  industry?: InputMaybe<Scalars['String']['input']>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogCreateNestedManyWithoutLeadInput>;
  last_call_result?: InputMaybe<Scalars['String']['input']>;
  last_call_result_time?: InputMaybe<Scalars['DateTime']['input']>;
  last_lead_assignment_origin?: InputMaybe<Scalars['DateTime']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  last_sent_to_resting_date?: InputMaybe<Scalars['DateTime']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutLeadInput>;
  lead_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutLeadInput>;
  lead_assignment_origin?: InputMaybe<AssignmentOrigin>;
  lead_creation_source?: InputMaybe<Leadcreationsource>;
  lead_custom_row_info?: InputMaybe<LeadCustomObjectRowAssociationCreateNestedManyWithoutLeadInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryCreateNestedOneWithoutLeadInput>;
  lead_import_history_csv?: InputMaybe<LeadImportHistoryCreateNestedOneWithoutUploaded_LeadsInput>;
  lead_intent?: InputMaybe<LeadIntentCreateNestedManyWithoutLeadInput>;
  lead_ownership_status?: InputMaybe<Los>;
  lead_source?: InputMaybe<Scalars['String']['input']>;
  lead_value?: InputMaybe<LeadValueCreateNestedOneWithoutLeadInput>;
  mrr?: InputMaybe<Scalars['Float']['input']>;
  newIncoming?: InputMaybe<Scalars['Boolean']['input']>;
  next_scheduled_event?: InputMaybe<ScheduleItemCreateNestedOneWithoutLeadInput>;
  notifications?: InputMaybe<NotificationCreateNestedManyWithoutLeadInput>;
  opsiq_whitelist?: InputMaybe<Scalars['Boolean']['input']>;
  organization: OrganizationCreateNestedOneWithoutLeadsInput;
  phase?: InputMaybe<Phase>;
  primary_email?: InputMaybe<Scalars['String']['input']>;
  primary_email_title?: InputMaybe<Scalars['String']['input']>;
  primary_email_type?: InputMaybe<Scalars['String']['input']>;
  primary_lead_histories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutPrimary_LeadInput>;
  primary_phone_number?: InputMaybe<Scalars['String']['input']>;
  primary_phone_number_country_code?: InputMaybe<Scalars['String']['input']>;
  primary_phone_number_title?: InputMaybe<Scalars['String']['input']>;
  primary_phone_number_type?: InputMaybe<Scalars['String']['input']>;
  rep?: InputMaybe<UserCreateNestedOneWithoutOwned_LeadsInput>;
  resting_type?: InputMaybe<Restingtype>;
  routing_history?: InputMaybe<RoutingHistoryCreateNestedManyWithoutLeadInput>;
  routing_rule?: InputMaybe<RuleCreateNestedOneWithoutLeadInput>;
  Sale?: InputMaybe<SaleCreateNestedManyWithoutLeadInput>;
  sale_cycles?: InputMaybe<SaleCycleCreateNestedManyWithoutLeadInput>;
  search_content?: InputMaybe<Scalars['String']['input']>;
  secondary_reps?: InputMaybe<UserCreateNestedManyWithoutIdle_Owned_LeadsInput>;
  self_sourced?: InputMaybe<Scalars['Boolean']['input']>;
  sequence?: InputMaybe<SequenceCreateNestedOneWithoutLeadsInput>;
  sequence_step?: InputMaybe<SequenceStepCreateNestedOneWithoutLeadsInput>;
  SequenceActionLog?: InputMaybe<StepActionLogCreateNestedManyWithoutLeadInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutLeadInput>;
  sequences_completed_ids?: InputMaybe<LeadCreatesequences_Completed_IdsInput>;
  sequences_joined_ids?: InputMaybe<LeadCreatesequences_Joined_IdsInput>;
  sf_account_id?: InputMaybe<Scalars['String']['input']>;
  sf_contact_id?: InputMaybe<Scalars['String']['input']>;
  sf_lead_id?: InputMaybe<Scalars['String']['input']>;
  sibling_lead_histories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutSibling_LeadsInput>;
  sms_sub_status?: InputMaybe<OptInStatus>;
  stat_items?: InputMaybe<StatItemAggregationCreateNestedManyWithoutLeadInput>;
  state?: InputMaybe<Scalars['String']['input']>;
  StatLeadAggregation?: InputMaybe<StatLeadAggregationCreateNestedOneWithoutLeadInput>;
  status?: InputMaybe<Scalars['String']['input']>;
  sub_industry?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type LeadCreateWithoutLead_ActivitiesInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  address_2?: InputMaybe<Scalars['String']['input']>;
  alternate_contacts?: InputMaybe<ContactCreateNestedManyWithoutLeadInput>;
  assignment_locked?: InputMaybe<Scalars['Boolean']['input']>;
  associate_children?: InputMaybe<LeadCreateNestedManyWithoutAssociate_Parent_LeadInput>;
  associate_group_lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutAssociate_Group_LeadsInput>;
  associate_lead_histories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutAssociate_LeadInput>;
  associate_parent_lead?: InputMaybe<LeadCreateNestedOneWithoutAssociate_ChildrenInput>;
  associated_parent_intents?: InputMaybe<LeadIntentCreateNestedManyWithoutAssociate_ParentInput>;
  associates_search_content?: InputMaybe<Scalars['String']['input']>;
  business_name?: InputMaybe<Scalars['String']['input']>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptCreateNestedManyWithoutLeadInput>;
  callMeNows?: InputMaybe<CallMeNowCreateNestedManyWithoutLeadInput>;
  channel?: InputMaybe<Channel>;
  child_lead_activites?: InputMaybe<LeadActivityCreateNestedManyWithoutAssociate_ChildInput>;
  city?: InputMaybe<Scalars['String']['input']>;
  claim_date?: InputMaybe<Scalars['DateTime']['input']>;
  conference_number?: InputMaybe<Scalars['String']['input']>;
  contact_requests?: InputMaybe<ContactRequestCreateNestedManyWithoutLeadInput>;
  content?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  current_close_date?: InputMaybe<Scalars['DateTime']['input']>;
  current_contact_date?: InputMaybe<Scalars['DateTime']['input']>;
  current_sale_cycle?: InputMaybe<SaleCycleCreateNestedOneWithoutLead_If_CurrentInput>;
  custom_field_json?: InputMaybe<Scalars['Json']['input']>;
  custom_fields?: InputMaybe<CustomFieldCreateNestedManyWithoutLeadInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemCreateNestedManyWithoutLeadInput>;
  delay_action_status?: InputMaybe<Scalars['Boolean']['input']>;
  email_sub_status?: InputMaybe<OptInStatus>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordCreateNestedManyWithoutLeadInput>;
  EmailThread?: InputMaybe<EmailThreadCreateNestedManyWithoutLeadInput>;
  favorited_by_users?: InputMaybe<UserCreateNestedManyWithoutFavorite_LeadsInput>;
  first_demo_schedule_date?: InputMaybe<Scalars['DateTime']['input']>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  hubspot_company_id?: InputMaybe<Scalars['String']['input']>;
  hubspot_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  implied_next_action?: InputMaybe<Hingepoint>;
  in_algo_queue?: InputMaybe<Scalars['Boolean']['input']>;
  in_custom_sequence?: InputMaybe<Scalars['Int']['input']>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventCreateNestedManyWithoutLeadInput>;
  industry?: InputMaybe<Scalars['String']['input']>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogCreateNestedManyWithoutLeadInput>;
  last_call_result?: InputMaybe<Scalars['String']['input']>;
  last_call_result_rep?: InputMaybe<UserCreateNestedOneWithoutLeads_Last_CalledInput>;
  last_call_result_time?: InputMaybe<Scalars['DateTime']['input']>;
  last_lead_assignment_origin?: InputMaybe<Scalars['DateTime']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  last_sent_to_resting_date?: InputMaybe<Scalars['DateTime']['input']>;
  lead_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutLeadInput>;
  lead_assignment_origin?: InputMaybe<AssignmentOrigin>;
  lead_creation_source?: InputMaybe<Leadcreationsource>;
  lead_custom_row_info?: InputMaybe<LeadCustomObjectRowAssociationCreateNestedManyWithoutLeadInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryCreateNestedOneWithoutLeadInput>;
  lead_import_history_csv?: InputMaybe<LeadImportHistoryCreateNestedOneWithoutUploaded_LeadsInput>;
  lead_intent?: InputMaybe<LeadIntentCreateNestedManyWithoutLeadInput>;
  lead_ownership_status?: InputMaybe<Los>;
  lead_source?: InputMaybe<Scalars['String']['input']>;
  lead_value?: InputMaybe<LeadValueCreateNestedOneWithoutLeadInput>;
  mrr?: InputMaybe<Scalars['Float']['input']>;
  newIncoming?: InputMaybe<Scalars['Boolean']['input']>;
  next_scheduled_event?: InputMaybe<ScheduleItemCreateNestedOneWithoutLeadInput>;
  notifications?: InputMaybe<NotificationCreateNestedManyWithoutLeadInput>;
  opsiq_whitelist?: InputMaybe<Scalars['Boolean']['input']>;
  organization: OrganizationCreateNestedOneWithoutLeadsInput;
  phase?: InputMaybe<Phase>;
  primary_email?: InputMaybe<Scalars['String']['input']>;
  primary_email_title?: InputMaybe<Scalars['String']['input']>;
  primary_email_type?: InputMaybe<Scalars['String']['input']>;
  primary_lead_histories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutPrimary_LeadInput>;
  primary_phone_number?: InputMaybe<Scalars['String']['input']>;
  primary_phone_number_country_code?: InputMaybe<Scalars['String']['input']>;
  primary_phone_number_title?: InputMaybe<Scalars['String']['input']>;
  primary_phone_number_type?: InputMaybe<Scalars['String']['input']>;
  rep?: InputMaybe<UserCreateNestedOneWithoutOwned_LeadsInput>;
  resting_type?: InputMaybe<Restingtype>;
  routing_history?: InputMaybe<RoutingHistoryCreateNestedManyWithoutLeadInput>;
  routing_rule?: InputMaybe<RuleCreateNestedOneWithoutLeadInput>;
  Sale?: InputMaybe<SaleCreateNestedManyWithoutLeadInput>;
  sale_cycles?: InputMaybe<SaleCycleCreateNestedManyWithoutLeadInput>;
  search_content?: InputMaybe<Scalars['String']['input']>;
  secondary_reps?: InputMaybe<UserCreateNestedManyWithoutIdle_Owned_LeadsInput>;
  self_sourced?: InputMaybe<Scalars['Boolean']['input']>;
  sequence?: InputMaybe<SequenceCreateNestedOneWithoutLeadsInput>;
  sequence_step?: InputMaybe<SequenceStepCreateNestedOneWithoutLeadsInput>;
  SequenceActionLog?: InputMaybe<StepActionLogCreateNestedManyWithoutLeadInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutLeadInput>;
  sequences_completed_ids?: InputMaybe<LeadCreatesequences_Completed_IdsInput>;
  sequences_joined_ids?: InputMaybe<LeadCreatesequences_Joined_IdsInput>;
  sf_account_id?: InputMaybe<Scalars['String']['input']>;
  sf_contact_id?: InputMaybe<Scalars['String']['input']>;
  sf_lead_id?: InputMaybe<Scalars['String']['input']>;
  sibling_lead_histories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutSibling_LeadsInput>;
  sms_sub_status?: InputMaybe<OptInStatus>;
  stat_items?: InputMaybe<StatItemAggregationCreateNestedManyWithoutLeadInput>;
  state?: InputMaybe<Scalars['String']['input']>;
  StatLeadAggregation?: InputMaybe<StatLeadAggregationCreateNestedOneWithoutLeadInput>;
  status?: InputMaybe<Scalars['String']['input']>;
  sub_industry?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type LeadCreateWithoutLead_Assignment_HistoryInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  address_2?: InputMaybe<Scalars['String']['input']>;
  alternate_contacts?: InputMaybe<ContactCreateNestedManyWithoutLeadInput>;
  assignment_locked?: InputMaybe<Scalars['Boolean']['input']>;
  associate_children?: InputMaybe<LeadCreateNestedManyWithoutAssociate_Parent_LeadInput>;
  associate_group_lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutAssociate_Group_LeadsInput>;
  associate_lead_histories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutAssociate_LeadInput>;
  associate_parent_lead?: InputMaybe<LeadCreateNestedOneWithoutAssociate_ChildrenInput>;
  associated_parent_intents?: InputMaybe<LeadIntentCreateNestedManyWithoutAssociate_ParentInput>;
  associates_search_content?: InputMaybe<Scalars['String']['input']>;
  business_name?: InputMaybe<Scalars['String']['input']>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptCreateNestedManyWithoutLeadInput>;
  callMeNows?: InputMaybe<CallMeNowCreateNestedManyWithoutLeadInput>;
  channel?: InputMaybe<Channel>;
  child_lead_activites?: InputMaybe<LeadActivityCreateNestedManyWithoutAssociate_ChildInput>;
  city?: InputMaybe<Scalars['String']['input']>;
  claim_date?: InputMaybe<Scalars['DateTime']['input']>;
  conference_number?: InputMaybe<Scalars['String']['input']>;
  contact_requests?: InputMaybe<ContactRequestCreateNestedManyWithoutLeadInput>;
  content?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  current_close_date?: InputMaybe<Scalars['DateTime']['input']>;
  current_contact_date?: InputMaybe<Scalars['DateTime']['input']>;
  current_sale_cycle?: InputMaybe<SaleCycleCreateNestedOneWithoutLead_If_CurrentInput>;
  custom_field_json?: InputMaybe<Scalars['Json']['input']>;
  custom_fields?: InputMaybe<CustomFieldCreateNestedManyWithoutLeadInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemCreateNestedManyWithoutLeadInput>;
  delay_action_status?: InputMaybe<Scalars['Boolean']['input']>;
  email_sub_status?: InputMaybe<OptInStatus>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordCreateNestedManyWithoutLeadInput>;
  EmailThread?: InputMaybe<EmailThreadCreateNestedManyWithoutLeadInput>;
  favorited_by_users?: InputMaybe<UserCreateNestedManyWithoutFavorite_LeadsInput>;
  first_demo_schedule_date?: InputMaybe<Scalars['DateTime']['input']>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  hubspot_company_id?: InputMaybe<Scalars['String']['input']>;
  hubspot_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  implied_next_action?: InputMaybe<Hingepoint>;
  in_algo_queue?: InputMaybe<Scalars['Boolean']['input']>;
  in_custom_sequence?: InputMaybe<Scalars['Int']['input']>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventCreateNestedManyWithoutLeadInput>;
  industry?: InputMaybe<Scalars['String']['input']>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogCreateNestedManyWithoutLeadInput>;
  last_call_result?: InputMaybe<Scalars['String']['input']>;
  last_call_result_rep?: InputMaybe<UserCreateNestedOneWithoutLeads_Last_CalledInput>;
  last_call_result_time?: InputMaybe<Scalars['DateTime']['input']>;
  last_lead_assignment_origin?: InputMaybe<Scalars['DateTime']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  last_sent_to_resting_date?: InputMaybe<Scalars['DateTime']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutLeadInput>;
  lead_assignment_origin?: InputMaybe<AssignmentOrigin>;
  lead_creation_source?: InputMaybe<Leadcreationsource>;
  lead_custom_row_info?: InputMaybe<LeadCustomObjectRowAssociationCreateNestedManyWithoutLeadInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryCreateNestedOneWithoutLeadInput>;
  lead_import_history_csv?: InputMaybe<LeadImportHistoryCreateNestedOneWithoutUploaded_LeadsInput>;
  lead_intent?: InputMaybe<LeadIntentCreateNestedManyWithoutLeadInput>;
  lead_ownership_status?: InputMaybe<Los>;
  lead_source?: InputMaybe<Scalars['String']['input']>;
  lead_value?: InputMaybe<LeadValueCreateNestedOneWithoutLeadInput>;
  mrr?: InputMaybe<Scalars['Float']['input']>;
  newIncoming?: InputMaybe<Scalars['Boolean']['input']>;
  next_scheduled_event?: InputMaybe<ScheduleItemCreateNestedOneWithoutLeadInput>;
  notifications?: InputMaybe<NotificationCreateNestedManyWithoutLeadInput>;
  opsiq_whitelist?: InputMaybe<Scalars['Boolean']['input']>;
  organization: OrganizationCreateNestedOneWithoutLeadsInput;
  phase?: InputMaybe<Phase>;
  primary_email?: InputMaybe<Scalars['String']['input']>;
  primary_email_title?: InputMaybe<Scalars['String']['input']>;
  primary_email_type?: InputMaybe<Scalars['String']['input']>;
  primary_lead_histories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutPrimary_LeadInput>;
  primary_phone_number?: InputMaybe<Scalars['String']['input']>;
  primary_phone_number_country_code?: InputMaybe<Scalars['String']['input']>;
  primary_phone_number_title?: InputMaybe<Scalars['String']['input']>;
  primary_phone_number_type?: InputMaybe<Scalars['String']['input']>;
  rep?: InputMaybe<UserCreateNestedOneWithoutOwned_LeadsInput>;
  resting_type?: InputMaybe<Restingtype>;
  routing_history?: InputMaybe<RoutingHistoryCreateNestedManyWithoutLeadInput>;
  routing_rule?: InputMaybe<RuleCreateNestedOneWithoutLeadInput>;
  Sale?: InputMaybe<SaleCreateNestedManyWithoutLeadInput>;
  sale_cycles?: InputMaybe<SaleCycleCreateNestedManyWithoutLeadInput>;
  search_content?: InputMaybe<Scalars['String']['input']>;
  secondary_reps?: InputMaybe<UserCreateNestedManyWithoutIdle_Owned_LeadsInput>;
  self_sourced?: InputMaybe<Scalars['Boolean']['input']>;
  sequence?: InputMaybe<SequenceCreateNestedOneWithoutLeadsInput>;
  sequence_step?: InputMaybe<SequenceStepCreateNestedOneWithoutLeadsInput>;
  SequenceActionLog?: InputMaybe<StepActionLogCreateNestedManyWithoutLeadInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutLeadInput>;
  sequences_completed_ids?: InputMaybe<LeadCreatesequences_Completed_IdsInput>;
  sequences_joined_ids?: InputMaybe<LeadCreatesequences_Joined_IdsInput>;
  sf_account_id?: InputMaybe<Scalars['String']['input']>;
  sf_contact_id?: InputMaybe<Scalars['String']['input']>;
  sf_lead_id?: InputMaybe<Scalars['String']['input']>;
  sibling_lead_histories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutSibling_LeadsInput>;
  sms_sub_status?: InputMaybe<OptInStatus>;
  stat_items?: InputMaybe<StatItemAggregationCreateNestedManyWithoutLeadInput>;
  state?: InputMaybe<Scalars['String']['input']>;
  StatLeadAggregation?: InputMaybe<StatLeadAggregationCreateNestedOneWithoutLeadInput>;
  status?: InputMaybe<Scalars['String']['input']>;
  sub_industry?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type LeadCreateWithoutLead_Custom_Row_InfoInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  address_2?: InputMaybe<Scalars['String']['input']>;
  alternate_contacts?: InputMaybe<ContactCreateNestedManyWithoutLeadInput>;
  assignment_locked?: InputMaybe<Scalars['Boolean']['input']>;
  associate_children?: InputMaybe<LeadCreateNestedManyWithoutAssociate_Parent_LeadInput>;
  associate_group_lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutAssociate_Group_LeadsInput>;
  associate_lead_histories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutAssociate_LeadInput>;
  associate_parent_lead?: InputMaybe<LeadCreateNestedOneWithoutAssociate_ChildrenInput>;
  associated_parent_intents?: InputMaybe<LeadIntentCreateNestedManyWithoutAssociate_ParentInput>;
  associates_search_content?: InputMaybe<Scalars['String']['input']>;
  business_name?: InputMaybe<Scalars['String']['input']>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptCreateNestedManyWithoutLeadInput>;
  callMeNows?: InputMaybe<CallMeNowCreateNestedManyWithoutLeadInput>;
  channel?: InputMaybe<Channel>;
  child_lead_activites?: InputMaybe<LeadActivityCreateNestedManyWithoutAssociate_ChildInput>;
  city?: InputMaybe<Scalars['String']['input']>;
  claim_date?: InputMaybe<Scalars['DateTime']['input']>;
  conference_number?: InputMaybe<Scalars['String']['input']>;
  contact_requests?: InputMaybe<ContactRequestCreateNestedManyWithoutLeadInput>;
  content?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  current_close_date?: InputMaybe<Scalars['DateTime']['input']>;
  current_contact_date?: InputMaybe<Scalars['DateTime']['input']>;
  current_sale_cycle?: InputMaybe<SaleCycleCreateNestedOneWithoutLead_If_CurrentInput>;
  custom_field_json?: InputMaybe<Scalars['Json']['input']>;
  custom_fields?: InputMaybe<CustomFieldCreateNestedManyWithoutLeadInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemCreateNestedManyWithoutLeadInput>;
  delay_action_status?: InputMaybe<Scalars['Boolean']['input']>;
  email_sub_status?: InputMaybe<OptInStatus>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordCreateNestedManyWithoutLeadInput>;
  EmailThread?: InputMaybe<EmailThreadCreateNestedManyWithoutLeadInput>;
  favorited_by_users?: InputMaybe<UserCreateNestedManyWithoutFavorite_LeadsInput>;
  first_demo_schedule_date?: InputMaybe<Scalars['DateTime']['input']>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  hubspot_company_id?: InputMaybe<Scalars['String']['input']>;
  hubspot_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  implied_next_action?: InputMaybe<Hingepoint>;
  in_algo_queue?: InputMaybe<Scalars['Boolean']['input']>;
  in_custom_sequence?: InputMaybe<Scalars['Int']['input']>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventCreateNestedManyWithoutLeadInput>;
  industry?: InputMaybe<Scalars['String']['input']>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogCreateNestedManyWithoutLeadInput>;
  last_call_result?: InputMaybe<Scalars['String']['input']>;
  last_call_result_rep?: InputMaybe<UserCreateNestedOneWithoutLeads_Last_CalledInput>;
  last_call_result_time?: InputMaybe<Scalars['DateTime']['input']>;
  last_lead_assignment_origin?: InputMaybe<Scalars['DateTime']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  last_sent_to_resting_date?: InputMaybe<Scalars['DateTime']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutLeadInput>;
  lead_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutLeadInput>;
  lead_assignment_origin?: InputMaybe<AssignmentOrigin>;
  lead_creation_source?: InputMaybe<Leadcreationsource>;
  lead_import_history?: InputMaybe<LeadImportHistoryCreateNestedOneWithoutLeadInput>;
  lead_import_history_csv?: InputMaybe<LeadImportHistoryCreateNestedOneWithoutUploaded_LeadsInput>;
  lead_intent?: InputMaybe<LeadIntentCreateNestedManyWithoutLeadInput>;
  lead_ownership_status?: InputMaybe<Los>;
  lead_source?: InputMaybe<Scalars['String']['input']>;
  lead_value?: InputMaybe<LeadValueCreateNestedOneWithoutLeadInput>;
  mrr?: InputMaybe<Scalars['Float']['input']>;
  newIncoming?: InputMaybe<Scalars['Boolean']['input']>;
  next_scheduled_event?: InputMaybe<ScheduleItemCreateNestedOneWithoutLeadInput>;
  notifications?: InputMaybe<NotificationCreateNestedManyWithoutLeadInput>;
  opsiq_whitelist?: InputMaybe<Scalars['Boolean']['input']>;
  organization: OrganizationCreateNestedOneWithoutLeadsInput;
  phase?: InputMaybe<Phase>;
  primary_email?: InputMaybe<Scalars['String']['input']>;
  primary_email_title?: InputMaybe<Scalars['String']['input']>;
  primary_email_type?: InputMaybe<Scalars['String']['input']>;
  primary_lead_histories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutPrimary_LeadInput>;
  primary_phone_number?: InputMaybe<Scalars['String']['input']>;
  primary_phone_number_country_code?: InputMaybe<Scalars['String']['input']>;
  primary_phone_number_title?: InputMaybe<Scalars['String']['input']>;
  primary_phone_number_type?: InputMaybe<Scalars['String']['input']>;
  rep?: InputMaybe<UserCreateNestedOneWithoutOwned_LeadsInput>;
  resting_type?: InputMaybe<Restingtype>;
  routing_history?: InputMaybe<RoutingHistoryCreateNestedManyWithoutLeadInput>;
  routing_rule?: InputMaybe<RuleCreateNestedOneWithoutLeadInput>;
  Sale?: InputMaybe<SaleCreateNestedManyWithoutLeadInput>;
  sale_cycles?: InputMaybe<SaleCycleCreateNestedManyWithoutLeadInput>;
  search_content?: InputMaybe<Scalars['String']['input']>;
  secondary_reps?: InputMaybe<UserCreateNestedManyWithoutIdle_Owned_LeadsInput>;
  self_sourced?: InputMaybe<Scalars['Boolean']['input']>;
  sequence?: InputMaybe<SequenceCreateNestedOneWithoutLeadsInput>;
  sequence_step?: InputMaybe<SequenceStepCreateNestedOneWithoutLeadsInput>;
  SequenceActionLog?: InputMaybe<StepActionLogCreateNestedManyWithoutLeadInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutLeadInput>;
  sequences_completed_ids?: InputMaybe<LeadCreatesequences_Completed_IdsInput>;
  sequences_joined_ids?: InputMaybe<LeadCreatesequences_Joined_IdsInput>;
  sf_account_id?: InputMaybe<Scalars['String']['input']>;
  sf_contact_id?: InputMaybe<Scalars['String']['input']>;
  sf_lead_id?: InputMaybe<Scalars['String']['input']>;
  sibling_lead_histories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutSibling_LeadsInput>;
  sms_sub_status?: InputMaybe<OptInStatus>;
  stat_items?: InputMaybe<StatItemAggregationCreateNestedManyWithoutLeadInput>;
  state?: InputMaybe<Scalars['String']['input']>;
  StatLeadAggregation?: InputMaybe<StatLeadAggregationCreateNestedOneWithoutLeadInput>;
  status?: InputMaybe<Scalars['String']['input']>;
  sub_industry?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type LeadCreateWithoutLead_Import_History_CsvInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  address_2?: InputMaybe<Scalars['String']['input']>;
  alternate_contacts?: InputMaybe<ContactCreateNestedManyWithoutLeadInput>;
  assignment_locked?: InputMaybe<Scalars['Boolean']['input']>;
  associate_children?: InputMaybe<LeadCreateNestedManyWithoutAssociate_Parent_LeadInput>;
  associate_group_lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutAssociate_Group_LeadsInput>;
  associate_lead_histories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutAssociate_LeadInput>;
  associate_parent_lead?: InputMaybe<LeadCreateNestedOneWithoutAssociate_ChildrenInput>;
  associated_parent_intents?: InputMaybe<LeadIntentCreateNestedManyWithoutAssociate_ParentInput>;
  associates_search_content?: InputMaybe<Scalars['String']['input']>;
  business_name?: InputMaybe<Scalars['String']['input']>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptCreateNestedManyWithoutLeadInput>;
  callMeNows?: InputMaybe<CallMeNowCreateNestedManyWithoutLeadInput>;
  channel?: InputMaybe<Channel>;
  child_lead_activites?: InputMaybe<LeadActivityCreateNestedManyWithoutAssociate_ChildInput>;
  city?: InputMaybe<Scalars['String']['input']>;
  claim_date?: InputMaybe<Scalars['DateTime']['input']>;
  conference_number?: InputMaybe<Scalars['String']['input']>;
  contact_requests?: InputMaybe<ContactRequestCreateNestedManyWithoutLeadInput>;
  content?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  current_close_date?: InputMaybe<Scalars['DateTime']['input']>;
  current_contact_date?: InputMaybe<Scalars['DateTime']['input']>;
  current_sale_cycle?: InputMaybe<SaleCycleCreateNestedOneWithoutLead_If_CurrentInput>;
  custom_field_json?: InputMaybe<Scalars['Json']['input']>;
  custom_fields?: InputMaybe<CustomFieldCreateNestedManyWithoutLeadInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemCreateNestedManyWithoutLeadInput>;
  delay_action_status?: InputMaybe<Scalars['Boolean']['input']>;
  email_sub_status?: InputMaybe<OptInStatus>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordCreateNestedManyWithoutLeadInput>;
  EmailThread?: InputMaybe<EmailThreadCreateNestedManyWithoutLeadInput>;
  favorited_by_users?: InputMaybe<UserCreateNestedManyWithoutFavorite_LeadsInput>;
  first_demo_schedule_date?: InputMaybe<Scalars['DateTime']['input']>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  hubspot_company_id?: InputMaybe<Scalars['String']['input']>;
  hubspot_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  implied_next_action?: InputMaybe<Hingepoint>;
  in_algo_queue?: InputMaybe<Scalars['Boolean']['input']>;
  in_custom_sequence?: InputMaybe<Scalars['Int']['input']>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventCreateNestedManyWithoutLeadInput>;
  industry?: InputMaybe<Scalars['String']['input']>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogCreateNestedManyWithoutLeadInput>;
  last_call_result?: InputMaybe<Scalars['String']['input']>;
  last_call_result_rep?: InputMaybe<UserCreateNestedOneWithoutLeads_Last_CalledInput>;
  last_call_result_time?: InputMaybe<Scalars['DateTime']['input']>;
  last_lead_assignment_origin?: InputMaybe<Scalars['DateTime']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  last_sent_to_resting_date?: InputMaybe<Scalars['DateTime']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutLeadInput>;
  lead_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutLeadInput>;
  lead_assignment_origin?: InputMaybe<AssignmentOrigin>;
  lead_creation_source?: InputMaybe<Leadcreationsource>;
  lead_custom_row_info?: InputMaybe<LeadCustomObjectRowAssociationCreateNestedManyWithoutLeadInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryCreateNestedOneWithoutLeadInput>;
  lead_intent?: InputMaybe<LeadIntentCreateNestedManyWithoutLeadInput>;
  lead_ownership_status?: InputMaybe<Los>;
  lead_source?: InputMaybe<Scalars['String']['input']>;
  lead_value?: InputMaybe<LeadValueCreateNestedOneWithoutLeadInput>;
  mrr?: InputMaybe<Scalars['Float']['input']>;
  newIncoming?: InputMaybe<Scalars['Boolean']['input']>;
  next_scheduled_event?: InputMaybe<ScheduleItemCreateNestedOneWithoutLeadInput>;
  notifications?: InputMaybe<NotificationCreateNestedManyWithoutLeadInput>;
  opsiq_whitelist?: InputMaybe<Scalars['Boolean']['input']>;
  organization: OrganizationCreateNestedOneWithoutLeadsInput;
  phase?: InputMaybe<Phase>;
  primary_email?: InputMaybe<Scalars['String']['input']>;
  primary_email_title?: InputMaybe<Scalars['String']['input']>;
  primary_email_type?: InputMaybe<Scalars['String']['input']>;
  primary_lead_histories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutPrimary_LeadInput>;
  primary_phone_number?: InputMaybe<Scalars['String']['input']>;
  primary_phone_number_country_code?: InputMaybe<Scalars['String']['input']>;
  primary_phone_number_title?: InputMaybe<Scalars['String']['input']>;
  primary_phone_number_type?: InputMaybe<Scalars['String']['input']>;
  rep?: InputMaybe<UserCreateNestedOneWithoutOwned_LeadsInput>;
  resting_type?: InputMaybe<Restingtype>;
  routing_history?: InputMaybe<RoutingHistoryCreateNestedManyWithoutLeadInput>;
  routing_rule?: InputMaybe<RuleCreateNestedOneWithoutLeadInput>;
  Sale?: InputMaybe<SaleCreateNestedManyWithoutLeadInput>;
  sale_cycles?: InputMaybe<SaleCycleCreateNestedManyWithoutLeadInput>;
  search_content?: InputMaybe<Scalars['String']['input']>;
  secondary_reps?: InputMaybe<UserCreateNestedManyWithoutIdle_Owned_LeadsInput>;
  self_sourced?: InputMaybe<Scalars['Boolean']['input']>;
  sequence?: InputMaybe<SequenceCreateNestedOneWithoutLeadsInput>;
  sequence_step?: InputMaybe<SequenceStepCreateNestedOneWithoutLeadsInput>;
  SequenceActionLog?: InputMaybe<StepActionLogCreateNestedManyWithoutLeadInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutLeadInput>;
  sequences_completed_ids?: InputMaybe<LeadCreatesequences_Completed_IdsInput>;
  sequences_joined_ids?: InputMaybe<LeadCreatesequences_Joined_IdsInput>;
  sf_account_id?: InputMaybe<Scalars['String']['input']>;
  sf_contact_id?: InputMaybe<Scalars['String']['input']>;
  sf_lead_id?: InputMaybe<Scalars['String']['input']>;
  sibling_lead_histories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutSibling_LeadsInput>;
  sms_sub_status?: InputMaybe<OptInStatus>;
  stat_items?: InputMaybe<StatItemAggregationCreateNestedManyWithoutLeadInput>;
  state?: InputMaybe<Scalars['String']['input']>;
  StatLeadAggregation?: InputMaybe<StatLeadAggregationCreateNestedOneWithoutLeadInput>;
  status?: InputMaybe<Scalars['String']['input']>;
  sub_industry?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type LeadCreateWithoutLead_Import_HistoryInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  address_2?: InputMaybe<Scalars['String']['input']>;
  alternate_contacts?: InputMaybe<ContactCreateNestedManyWithoutLeadInput>;
  assignment_locked?: InputMaybe<Scalars['Boolean']['input']>;
  associate_children?: InputMaybe<LeadCreateNestedManyWithoutAssociate_Parent_LeadInput>;
  associate_group_lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutAssociate_Group_LeadsInput>;
  associate_lead_histories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutAssociate_LeadInput>;
  associate_parent_lead?: InputMaybe<LeadCreateNestedOneWithoutAssociate_ChildrenInput>;
  associated_parent_intents?: InputMaybe<LeadIntentCreateNestedManyWithoutAssociate_ParentInput>;
  associates_search_content?: InputMaybe<Scalars['String']['input']>;
  business_name?: InputMaybe<Scalars['String']['input']>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptCreateNestedManyWithoutLeadInput>;
  callMeNows?: InputMaybe<CallMeNowCreateNestedManyWithoutLeadInput>;
  channel?: InputMaybe<Channel>;
  child_lead_activites?: InputMaybe<LeadActivityCreateNestedManyWithoutAssociate_ChildInput>;
  city?: InputMaybe<Scalars['String']['input']>;
  claim_date?: InputMaybe<Scalars['DateTime']['input']>;
  conference_number?: InputMaybe<Scalars['String']['input']>;
  contact_requests?: InputMaybe<ContactRequestCreateNestedManyWithoutLeadInput>;
  content?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  current_close_date?: InputMaybe<Scalars['DateTime']['input']>;
  current_contact_date?: InputMaybe<Scalars['DateTime']['input']>;
  current_sale_cycle?: InputMaybe<SaleCycleCreateNestedOneWithoutLead_If_CurrentInput>;
  custom_field_json?: InputMaybe<Scalars['Json']['input']>;
  custom_fields?: InputMaybe<CustomFieldCreateNestedManyWithoutLeadInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemCreateNestedManyWithoutLeadInput>;
  delay_action_status?: InputMaybe<Scalars['Boolean']['input']>;
  email_sub_status?: InputMaybe<OptInStatus>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordCreateNestedManyWithoutLeadInput>;
  EmailThread?: InputMaybe<EmailThreadCreateNestedManyWithoutLeadInput>;
  favorited_by_users?: InputMaybe<UserCreateNestedManyWithoutFavorite_LeadsInput>;
  first_demo_schedule_date?: InputMaybe<Scalars['DateTime']['input']>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  hubspot_company_id?: InputMaybe<Scalars['String']['input']>;
  hubspot_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  implied_next_action?: InputMaybe<Hingepoint>;
  in_algo_queue?: InputMaybe<Scalars['Boolean']['input']>;
  in_custom_sequence?: InputMaybe<Scalars['Int']['input']>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventCreateNestedManyWithoutLeadInput>;
  industry?: InputMaybe<Scalars['String']['input']>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogCreateNestedManyWithoutLeadInput>;
  last_call_result?: InputMaybe<Scalars['String']['input']>;
  last_call_result_rep?: InputMaybe<UserCreateNestedOneWithoutLeads_Last_CalledInput>;
  last_call_result_time?: InputMaybe<Scalars['DateTime']['input']>;
  last_lead_assignment_origin?: InputMaybe<Scalars['DateTime']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  last_sent_to_resting_date?: InputMaybe<Scalars['DateTime']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutLeadInput>;
  lead_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutLeadInput>;
  lead_assignment_origin?: InputMaybe<AssignmentOrigin>;
  lead_creation_source?: InputMaybe<Leadcreationsource>;
  lead_custom_row_info?: InputMaybe<LeadCustomObjectRowAssociationCreateNestedManyWithoutLeadInput>;
  lead_import_history_csv?: InputMaybe<LeadImportHistoryCreateNestedOneWithoutUploaded_LeadsInput>;
  lead_intent?: InputMaybe<LeadIntentCreateNestedManyWithoutLeadInput>;
  lead_ownership_status?: InputMaybe<Los>;
  lead_source?: InputMaybe<Scalars['String']['input']>;
  lead_value?: InputMaybe<LeadValueCreateNestedOneWithoutLeadInput>;
  mrr?: InputMaybe<Scalars['Float']['input']>;
  newIncoming?: InputMaybe<Scalars['Boolean']['input']>;
  next_scheduled_event?: InputMaybe<ScheduleItemCreateNestedOneWithoutLeadInput>;
  notifications?: InputMaybe<NotificationCreateNestedManyWithoutLeadInput>;
  opsiq_whitelist?: InputMaybe<Scalars['Boolean']['input']>;
  organization: OrganizationCreateNestedOneWithoutLeadsInput;
  phase?: InputMaybe<Phase>;
  primary_email?: InputMaybe<Scalars['String']['input']>;
  primary_email_title?: InputMaybe<Scalars['String']['input']>;
  primary_email_type?: InputMaybe<Scalars['String']['input']>;
  primary_lead_histories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutPrimary_LeadInput>;
  primary_phone_number?: InputMaybe<Scalars['String']['input']>;
  primary_phone_number_country_code?: InputMaybe<Scalars['String']['input']>;
  primary_phone_number_title?: InputMaybe<Scalars['String']['input']>;
  primary_phone_number_type?: InputMaybe<Scalars['String']['input']>;
  rep?: InputMaybe<UserCreateNestedOneWithoutOwned_LeadsInput>;
  resting_type?: InputMaybe<Restingtype>;
  routing_history?: InputMaybe<RoutingHistoryCreateNestedManyWithoutLeadInput>;
  routing_rule?: InputMaybe<RuleCreateNestedOneWithoutLeadInput>;
  Sale?: InputMaybe<SaleCreateNestedManyWithoutLeadInput>;
  sale_cycles?: InputMaybe<SaleCycleCreateNestedManyWithoutLeadInput>;
  search_content?: InputMaybe<Scalars['String']['input']>;
  secondary_reps?: InputMaybe<UserCreateNestedManyWithoutIdle_Owned_LeadsInput>;
  self_sourced?: InputMaybe<Scalars['Boolean']['input']>;
  sequence?: InputMaybe<SequenceCreateNestedOneWithoutLeadsInput>;
  sequence_step?: InputMaybe<SequenceStepCreateNestedOneWithoutLeadsInput>;
  SequenceActionLog?: InputMaybe<StepActionLogCreateNestedManyWithoutLeadInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutLeadInput>;
  sequences_completed_ids?: InputMaybe<LeadCreatesequences_Completed_IdsInput>;
  sequences_joined_ids?: InputMaybe<LeadCreatesequences_Joined_IdsInput>;
  sf_account_id?: InputMaybe<Scalars['String']['input']>;
  sf_contact_id?: InputMaybe<Scalars['String']['input']>;
  sf_lead_id?: InputMaybe<Scalars['String']['input']>;
  sibling_lead_histories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutSibling_LeadsInput>;
  sms_sub_status?: InputMaybe<OptInStatus>;
  stat_items?: InputMaybe<StatItemAggregationCreateNestedManyWithoutLeadInput>;
  state?: InputMaybe<Scalars['String']['input']>;
  StatLeadAggregation?: InputMaybe<StatLeadAggregationCreateNestedOneWithoutLeadInput>;
  status?: InputMaybe<Scalars['String']['input']>;
  sub_industry?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type LeadCreateWithoutLead_IntentInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  address_2?: InputMaybe<Scalars['String']['input']>;
  alternate_contacts?: InputMaybe<ContactCreateNestedManyWithoutLeadInput>;
  assignment_locked?: InputMaybe<Scalars['Boolean']['input']>;
  associate_children?: InputMaybe<LeadCreateNestedManyWithoutAssociate_Parent_LeadInput>;
  associate_group_lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutAssociate_Group_LeadsInput>;
  associate_lead_histories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutAssociate_LeadInput>;
  associate_parent_lead?: InputMaybe<LeadCreateNestedOneWithoutAssociate_ChildrenInput>;
  associated_parent_intents?: InputMaybe<LeadIntentCreateNestedManyWithoutAssociate_ParentInput>;
  associates_search_content?: InputMaybe<Scalars['String']['input']>;
  business_name?: InputMaybe<Scalars['String']['input']>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptCreateNestedManyWithoutLeadInput>;
  callMeNows?: InputMaybe<CallMeNowCreateNestedManyWithoutLeadInput>;
  channel?: InputMaybe<Channel>;
  child_lead_activites?: InputMaybe<LeadActivityCreateNestedManyWithoutAssociate_ChildInput>;
  city?: InputMaybe<Scalars['String']['input']>;
  claim_date?: InputMaybe<Scalars['DateTime']['input']>;
  conference_number?: InputMaybe<Scalars['String']['input']>;
  contact_requests?: InputMaybe<ContactRequestCreateNestedManyWithoutLeadInput>;
  content?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  current_close_date?: InputMaybe<Scalars['DateTime']['input']>;
  current_contact_date?: InputMaybe<Scalars['DateTime']['input']>;
  current_sale_cycle?: InputMaybe<SaleCycleCreateNestedOneWithoutLead_If_CurrentInput>;
  custom_field_json?: InputMaybe<Scalars['Json']['input']>;
  custom_fields?: InputMaybe<CustomFieldCreateNestedManyWithoutLeadInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemCreateNestedManyWithoutLeadInput>;
  delay_action_status?: InputMaybe<Scalars['Boolean']['input']>;
  email_sub_status?: InputMaybe<OptInStatus>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordCreateNestedManyWithoutLeadInput>;
  EmailThread?: InputMaybe<EmailThreadCreateNestedManyWithoutLeadInput>;
  favorited_by_users?: InputMaybe<UserCreateNestedManyWithoutFavorite_LeadsInput>;
  first_demo_schedule_date?: InputMaybe<Scalars['DateTime']['input']>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  hubspot_company_id?: InputMaybe<Scalars['String']['input']>;
  hubspot_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  implied_next_action?: InputMaybe<Hingepoint>;
  in_algo_queue?: InputMaybe<Scalars['Boolean']['input']>;
  in_custom_sequence?: InputMaybe<Scalars['Int']['input']>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventCreateNestedManyWithoutLeadInput>;
  industry?: InputMaybe<Scalars['String']['input']>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogCreateNestedManyWithoutLeadInput>;
  last_call_result?: InputMaybe<Scalars['String']['input']>;
  last_call_result_rep?: InputMaybe<UserCreateNestedOneWithoutLeads_Last_CalledInput>;
  last_call_result_time?: InputMaybe<Scalars['DateTime']['input']>;
  last_lead_assignment_origin?: InputMaybe<Scalars['DateTime']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  last_sent_to_resting_date?: InputMaybe<Scalars['DateTime']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutLeadInput>;
  lead_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutLeadInput>;
  lead_assignment_origin?: InputMaybe<AssignmentOrigin>;
  lead_creation_source?: InputMaybe<Leadcreationsource>;
  lead_custom_row_info?: InputMaybe<LeadCustomObjectRowAssociationCreateNestedManyWithoutLeadInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryCreateNestedOneWithoutLeadInput>;
  lead_import_history_csv?: InputMaybe<LeadImportHistoryCreateNestedOneWithoutUploaded_LeadsInput>;
  lead_ownership_status?: InputMaybe<Los>;
  lead_source?: InputMaybe<Scalars['String']['input']>;
  lead_value?: InputMaybe<LeadValueCreateNestedOneWithoutLeadInput>;
  mrr?: InputMaybe<Scalars['Float']['input']>;
  newIncoming?: InputMaybe<Scalars['Boolean']['input']>;
  next_scheduled_event?: InputMaybe<ScheduleItemCreateNestedOneWithoutLeadInput>;
  notifications?: InputMaybe<NotificationCreateNestedManyWithoutLeadInput>;
  opsiq_whitelist?: InputMaybe<Scalars['Boolean']['input']>;
  organization: OrganizationCreateNestedOneWithoutLeadsInput;
  phase?: InputMaybe<Phase>;
  primary_email?: InputMaybe<Scalars['String']['input']>;
  primary_email_title?: InputMaybe<Scalars['String']['input']>;
  primary_email_type?: InputMaybe<Scalars['String']['input']>;
  primary_lead_histories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutPrimary_LeadInput>;
  primary_phone_number?: InputMaybe<Scalars['String']['input']>;
  primary_phone_number_country_code?: InputMaybe<Scalars['String']['input']>;
  primary_phone_number_title?: InputMaybe<Scalars['String']['input']>;
  primary_phone_number_type?: InputMaybe<Scalars['String']['input']>;
  rep?: InputMaybe<UserCreateNestedOneWithoutOwned_LeadsInput>;
  resting_type?: InputMaybe<Restingtype>;
  routing_history?: InputMaybe<RoutingHistoryCreateNestedManyWithoutLeadInput>;
  routing_rule?: InputMaybe<RuleCreateNestedOneWithoutLeadInput>;
  Sale?: InputMaybe<SaleCreateNestedManyWithoutLeadInput>;
  sale_cycles?: InputMaybe<SaleCycleCreateNestedManyWithoutLeadInput>;
  search_content?: InputMaybe<Scalars['String']['input']>;
  secondary_reps?: InputMaybe<UserCreateNestedManyWithoutIdle_Owned_LeadsInput>;
  self_sourced?: InputMaybe<Scalars['Boolean']['input']>;
  sequence?: InputMaybe<SequenceCreateNestedOneWithoutLeadsInput>;
  sequence_step?: InputMaybe<SequenceStepCreateNestedOneWithoutLeadsInput>;
  SequenceActionLog?: InputMaybe<StepActionLogCreateNestedManyWithoutLeadInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutLeadInput>;
  sequences_completed_ids?: InputMaybe<LeadCreatesequences_Completed_IdsInput>;
  sequences_joined_ids?: InputMaybe<LeadCreatesequences_Joined_IdsInput>;
  sf_account_id?: InputMaybe<Scalars['String']['input']>;
  sf_contact_id?: InputMaybe<Scalars['String']['input']>;
  sf_lead_id?: InputMaybe<Scalars['String']['input']>;
  sibling_lead_histories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutSibling_LeadsInput>;
  sms_sub_status?: InputMaybe<OptInStatus>;
  stat_items?: InputMaybe<StatItemAggregationCreateNestedManyWithoutLeadInput>;
  state?: InputMaybe<Scalars['String']['input']>;
  StatLeadAggregation?: InputMaybe<StatLeadAggregationCreateNestedOneWithoutLeadInput>;
  status?: InputMaybe<Scalars['String']['input']>;
  sub_industry?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type LeadCreateWithoutLead_ValueInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  address_2?: InputMaybe<Scalars['String']['input']>;
  alternate_contacts?: InputMaybe<ContactCreateNestedManyWithoutLeadInput>;
  assignment_locked?: InputMaybe<Scalars['Boolean']['input']>;
  associate_children?: InputMaybe<LeadCreateNestedManyWithoutAssociate_Parent_LeadInput>;
  associate_group_lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutAssociate_Group_LeadsInput>;
  associate_lead_histories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutAssociate_LeadInput>;
  associate_parent_lead?: InputMaybe<LeadCreateNestedOneWithoutAssociate_ChildrenInput>;
  associated_parent_intents?: InputMaybe<LeadIntentCreateNestedManyWithoutAssociate_ParentInput>;
  associates_search_content?: InputMaybe<Scalars['String']['input']>;
  business_name?: InputMaybe<Scalars['String']['input']>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptCreateNestedManyWithoutLeadInput>;
  callMeNows?: InputMaybe<CallMeNowCreateNestedManyWithoutLeadInput>;
  channel?: InputMaybe<Channel>;
  child_lead_activites?: InputMaybe<LeadActivityCreateNestedManyWithoutAssociate_ChildInput>;
  city?: InputMaybe<Scalars['String']['input']>;
  claim_date?: InputMaybe<Scalars['DateTime']['input']>;
  conference_number?: InputMaybe<Scalars['String']['input']>;
  contact_requests?: InputMaybe<ContactRequestCreateNestedManyWithoutLeadInput>;
  content?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  current_close_date?: InputMaybe<Scalars['DateTime']['input']>;
  current_contact_date?: InputMaybe<Scalars['DateTime']['input']>;
  current_sale_cycle?: InputMaybe<SaleCycleCreateNestedOneWithoutLead_If_CurrentInput>;
  custom_field_json?: InputMaybe<Scalars['Json']['input']>;
  custom_fields?: InputMaybe<CustomFieldCreateNestedManyWithoutLeadInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemCreateNestedManyWithoutLeadInput>;
  delay_action_status?: InputMaybe<Scalars['Boolean']['input']>;
  email_sub_status?: InputMaybe<OptInStatus>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordCreateNestedManyWithoutLeadInput>;
  EmailThread?: InputMaybe<EmailThreadCreateNestedManyWithoutLeadInput>;
  favorited_by_users?: InputMaybe<UserCreateNestedManyWithoutFavorite_LeadsInput>;
  first_demo_schedule_date?: InputMaybe<Scalars['DateTime']['input']>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  hubspot_company_id?: InputMaybe<Scalars['String']['input']>;
  hubspot_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  implied_next_action?: InputMaybe<Hingepoint>;
  in_algo_queue?: InputMaybe<Scalars['Boolean']['input']>;
  in_custom_sequence?: InputMaybe<Scalars['Int']['input']>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventCreateNestedManyWithoutLeadInput>;
  industry?: InputMaybe<Scalars['String']['input']>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogCreateNestedManyWithoutLeadInput>;
  last_call_result?: InputMaybe<Scalars['String']['input']>;
  last_call_result_rep?: InputMaybe<UserCreateNestedOneWithoutLeads_Last_CalledInput>;
  last_call_result_time?: InputMaybe<Scalars['DateTime']['input']>;
  last_lead_assignment_origin?: InputMaybe<Scalars['DateTime']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  last_sent_to_resting_date?: InputMaybe<Scalars['DateTime']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutLeadInput>;
  lead_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutLeadInput>;
  lead_assignment_origin?: InputMaybe<AssignmentOrigin>;
  lead_creation_source?: InputMaybe<Leadcreationsource>;
  lead_custom_row_info?: InputMaybe<LeadCustomObjectRowAssociationCreateNestedManyWithoutLeadInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryCreateNestedOneWithoutLeadInput>;
  lead_import_history_csv?: InputMaybe<LeadImportHistoryCreateNestedOneWithoutUploaded_LeadsInput>;
  lead_intent?: InputMaybe<LeadIntentCreateNestedManyWithoutLeadInput>;
  lead_ownership_status?: InputMaybe<Los>;
  lead_source?: InputMaybe<Scalars['String']['input']>;
  mrr?: InputMaybe<Scalars['Float']['input']>;
  newIncoming?: InputMaybe<Scalars['Boolean']['input']>;
  next_scheduled_event?: InputMaybe<ScheduleItemCreateNestedOneWithoutLeadInput>;
  notifications?: InputMaybe<NotificationCreateNestedManyWithoutLeadInput>;
  opsiq_whitelist?: InputMaybe<Scalars['Boolean']['input']>;
  organization: OrganizationCreateNestedOneWithoutLeadsInput;
  phase?: InputMaybe<Phase>;
  primary_email?: InputMaybe<Scalars['String']['input']>;
  primary_email_title?: InputMaybe<Scalars['String']['input']>;
  primary_email_type?: InputMaybe<Scalars['String']['input']>;
  primary_lead_histories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutPrimary_LeadInput>;
  primary_phone_number?: InputMaybe<Scalars['String']['input']>;
  primary_phone_number_country_code?: InputMaybe<Scalars['String']['input']>;
  primary_phone_number_title?: InputMaybe<Scalars['String']['input']>;
  primary_phone_number_type?: InputMaybe<Scalars['String']['input']>;
  rep?: InputMaybe<UserCreateNestedOneWithoutOwned_LeadsInput>;
  resting_type?: InputMaybe<Restingtype>;
  routing_history?: InputMaybe<RoutingHistoryCreateNestedManyWithoutLeadInput>;
  routing_rule?: InputMaybe<RuleCreateNestedOneWithoutLeadInput>;
  Sale?: InputMaybe<SaleCreateNestedManyWithoutLeadInput>;
  sale_cycles?: InputMaybe<SaleCycleCreateNestedManyWithoutLeadInput>;
  search_content?: InputMaybe<Scalars['String']['input']>;
  secondary_reps?: InputMaybe<UserCreateNestedManyWithoutIdle_Owned_LeadsInput>;
  self_sourced?: InputMaybe<Scalars['Boolean']['input']>;
  sequence?: InputMaybe<SequenceCreateNestedOneWithoutLeadsInput>;
  sequence_step?: InputMaybe<SequenceStepCreateNestedOneWithoutLeadsInput>;
  SequenceActionLog?: InputMaybe<StepActionLogCreateNestedManyWithoutLeadInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutLeadInput>;
  sequences_completed_ids?: InputMaybe<LeadCreatesequences_Completed_IdsInput>;
  sequences_joined_ids?: InputMaybe<LeadCreatesequences_Joined_IdsInput>;
  sf_account_id?: InputMaybe<Scalars['String']['input']>;
  sf_contact_id?: InputMaybe<Scalars['String']['input']>;
  sf_lead_id?: InputMaybe<Scalars['String']['input']>;
  sibling_lead_histories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutSibling_LeadsInput>;
  sms_sub_status?: InputMaybe<OptInStatus>;
  stat_items?: InputMaybe<StatItemAggregationCreateNestedManyWithoutLeadInput>;
  state?: InputMaybe<Scalars['String']['input']>;
  StatLeadAggregation?: InputMaybe<StatLeadAggregationCreateNestedOneWithoutLeadInput>;
  status?: InputMaybe<Scalars['String']['input']>;
  sub_industry?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type LeadCreateWithoutNext_Scheduled_EventInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  address_2?: InputMaybe<Scalars['String']['input']>;
  alternate_contacts?: InputMaybe<ContactCreateNestedManyWithoutLeadInput>;
  assignment_locked?: InputMaybe<Scalars['Boolean']['input']>;
  associate_children?: InputMaybe<LeadCreateNestedManyWithoutAssociate_Parent_LeadInput>;
  associate_group_lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutAssociate_Group_LeadsInput>;
  associate_lead_histories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutAssociate_LeadInput>;
  associate_parent_lead?: InputMaybe<LeadCreateNestedOneWithoutAssociate_ChildrenInput>;
  associated_parent_intents?: InputMaybe<LeadIntentCreateNestedManyWithoutAssociate_ParentInput>;
  associates_search_content?: InputMaybe<Scalars['String']['input']>;
  business_name?: InputMaybe<Scalars['String']['input']>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptCreateNestedManyWithoutLeadInput>;
  callMeNows?: InputMaybe<CallMeNowCreateNestedManyWithoutLeadInput>;
  channel?: InputMaybe<Channel>;
  child_lead_activites?: InputMaybe<LeadActivityCreateNestedManyWithoutAssociate_ChildInput>;
  city?: InputMaybe<Scalars['String']['input']>;
  claim_date?: InputMaybe<Scalars['DateTime']['input']>;
  conference_number?: InputMaybe<Scalars['String']['input']>;
  contact_requests?: InputMaybe<ContactRequestCreateNestedManyWithoutLeadInput>;
  content?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  current_close_date?: InputMaybe<Scalars['DateTime']['input']>;
  current_contact_date?: InputMaybe<Scalars['DateTime']['input']>;
  current_sale_cycle?: InputMaybe<SaleCycleCreateNestedOneWithoutLead_If_CurrentInput>;
  custom_field_json?: InputMaybe<Scalars['Json']['input']>;
  custom_fields?: InputMaybe<CustomFieldCreateNestedManyWithoutLeadInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemCreateNestedManyWithoutLeadInput>;
  delay_action_status?: InputMaybe<Scalars['Boolean']['input']>;
  email_sub_status?: InputMaybe<OptInStatus>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordCreateNestedManyWithoutLeadInput>;
  EmailThread?: InputMaybe<EmailThreadCreateNestedManyWithoutLeadInput>;
  favorited_by_users?: InputMaybe<UserCreateNestedManyWithoutFavorite_LeadsInput>;
  first_demo_schedule_date?: InputMaybe<Scalars['DateTime']['input']>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  hubspot_company_id?: InputMaybe<Scalars['String']['input']>;
  hubspot_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  implied_next_action?: InputMaybe<Hingepoint>;
  in_algo_queue?: InputMaybe<Scalars['Boolean']['input']>;
  in_custom_sequence?: InputMaybe<Scalars['Int']['input']>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventCreateNestedManyWithoutLeadInput>;
  industry?: InputMaybe<Scalars['String']['input']>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogCreateNestedManyWithoutLeadInput>;
  last_call_result?: InputMaybe<Scalars['String']['input']>;
  last_call_result_rep?: InputMaybe<UserCreateNestedOneWithoutLeads_Last_CalledInput>;
  last_call_result_time?: InputMaybe<Scalars['DateTime']['input']>;
  last_lead_assignment_origin?: InputMaybe<Scalars['DateTime']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  last_sent_to_resting_date?: InputMaybe<Scalars['DateTime']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutLeadInput>;
  lead_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutLeadInput>;
  lead_assignment_origin?: InputMaybe<AssignmentOrigin>;
  lead_creation_source?: InputMaybe<Leadcreationsource>;
  lead_custom_row_info?: InputMaybe<LeadCustomObjectRowAssociationCreateNestedManyWithoutLeadInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryCreateNestedOneWithoutLeadInput>;
  lead_import_history_csv?: InputMaybe<LeadImportHistoryCreateNestedOneWithoutUploaded_LeadsInput>;
  lead_intent?: InputMaybe<LeadIntentCreateNestedManyWithoutLeadInput>;
  lead_ownership_status?: InputMaybe<Los>;
  lead_source?: InputMaybe<Scalars['String']['input']>;
  lead_value?: InputMaybe<LeadValueCreateNestedOneWithoutLeadInput>;
  mrr?: InputMaybe<Scalars['Float']['input']>;
  newIncoming?: InputMaybe<Scalars['Boolean']['input']>;
  notifications?: InputMaybe<NotificationCreateNestedManyWithoutLeadInput>;
  opsiq_whitelist?: InputMaybe<Scalars['Boolean']['input']>;
  organization: OrganizationCreateNestedOneWithoutLeadsInput;
  phase?: InputMaybe<Phase>;
  primary_email?: InputMaybe<Scalars['String']['input']>;
  primary_email_title?: InputMaybe<Scalars['String']['input']>;
  primary_email_type?: InputMaybe<Scalars['String']['input']>;
  primary_lead_histories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutPrimary_LeadInput>;
  primary_phone_number?: InputMaybe<Scalars['String']['input']>;
  primary_phone_number_country_code?: InputMaybe<Scalars['String']['input']>;
  primary_phone_number_title?: InputMaybe<Scalars['String']['input']>;
  primary_phone_number_type?: InputMaybe<Scalars['String']['input']>;
  rep?: InputMaybe<UserCreateNestedOneWithoutOwned_LeadsInput>;
  resting_type?: InputMaybe<Restingtype>;
  routing_history?: InputMaybe<RoutingHistoryCreateNestedManyWithoutLeadInput>;
  routing_rule?: InputMaybe<RuleCreateNestedOneWithoutLeadInput>;
  Sale?: InputMaybe<SaleCreateNestedManyWithoutLeadInput>;
  sale_cycles?: InputMaybe<SaleCycleCreateNestedManyWithoutLeadInput>;
  search_content?: InputMaybe<Scalars['String']['input']>;
  secondary_reps?: InputMaybe<UserCreateNestedManyWithoutIdle_Owned_LeadsInput>;
  self_sourced?: InputMaybe<Scalars['Boolean']['input']>;
  sequence?: InputMaybe<SequenceCreateNestedOneWithoutLeadsInput>;
  sequence_step?: InputMaybe<SequenceStepCreateNestedOneWithoutLeadsInput>;
  SequenceActionLog?: InputMaybe<StepActionLogCreateNestedManyWithoutLeadInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutLeadInput>;
  sequences_completed_ids?: InputMaybe<LeadCreatesequences_Completed_IdsInput>;
  sequences_joined_ids?: InputMaybe<LeadCreatesequences_Joined_IdsInput>;
  sf_account_id?: InputMaybe<Scalars['String']['input']>;
  sf_contact_id?: InputMaybe<Scalars['String']['input']>;
  sf_lead_id?: InputMaybe<Scalars['String']['input']>;
  sibling_lead_histories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutSibling_LeadsInput>;
  sms_sub_status?: InputMaybe<OptInStatus>;
  stat_items?: InputMaybe<StatItemAggregationCreateNestedManyWithoutLeadInput>;
  state?: InputMaybe<Scalars['String']['input']>;
  StatLeadAggregation?: InputMaybe<StatLeadAggregationCreateNestedOneWithoutLeadInput>;
  status?: InputMaybe<Scalars['String']['input']>;
  sub_industry?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type LeadCreateWithoutNotificationsInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  address_2?: InputMaybe<Scalars['String']['input']>;
  alternate_contacts?: InputMaybe<ContactCreateNestedManyWithoutLeadInput>;
  assignment_locked?: InputMaybe<Scalars['Boolean']['input']>;
  associate_children?: InputMaybe<LeadCreateNestedManyWithoutAssociate_Parent_LeadInput>;
  associate_group_lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutAssociate_Group_LeadsInput>;
  associate_lead_histories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutAssociate_LeadInput>;
  associate_parent_lead?: InputMaybe<LeadCreateNestedOneWithoutAssociate_ChildrenInput>;
  associated_parent_intents?: InputMaybe<LeadIntentCreateNestedManyWithoutAssociate_ParentInput>;
  associates_search_content?: InputMaybe<Scalars['String']['input']>;
  business_name?: InputMaybe<Scalars['String']['input']>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptCreateNestedManyWithoutLeadInput>;
  callMeNows?: InputMaybe<CallMeNowCreateNestedManyWithoutLeadInput>;
  channel?: InputMaybe<Channel>;
  child_lead_activites?: InputMaybe<LeadActivityCreateNestedManyWithoutAssociate_ChildInput>;
  city?: InputMaybe<Scalars['String']['input']>;
  claim_date?: InputMaybe<Scalars['DateTime']['input']>;
  conference_number?: InputMaybe<Scalars['String']['input']>;
  contact_requests?: InputMaybe<ContactRequestCreateNestedManyWithoutLeadInput>;
  content?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  current_close_date?: InputMaybe<Scalars['DateTime']['input']>;
  current_contact_date?: InputMaybe<Scalars['DateTime']['input']>;
  current_sale_cycle?: InputMaybe<SaleCycleCreateNestedOneWithoutLead_If_CurrentInput>;
  custom_field_json?: InputMaybe<Scalars['Json']['input']>;
  custom_fields?: InputMaybe<CustomFieldCreateNestedManyWithoutLeadInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemCreateNestedManyWithoutLeadInput>;
  delay_action_status?: InputMaybe<Scalars['Boolean']['input']>;
  email_sub_status?: InputMaybe<OptInStatus>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordCreateNestedManyWithoutLeadInput>;
  EmailThread?: InputMaybe<EmailThreadCreateNestedManyWithoutLeadInput>;
  favorited_by_users?: InputMaybe<UserCreateNestedManyWithoutFavorite_LeadsInput>;
  first_demo_schedule_date?: InputMaybe<Scalars['DateTime']['input']>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  hubspot_company_id?: InputMaybe<Scalars['String']['input']>;
  hubspot_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  implied_next_action?: InputMaybe<Hingepoint>;
  in_algo_queue?: InputMaybe<Scalars['Boolean']['input']>;
  in_custom_sequence?: InputMaybe<Scalars['Int']['input']>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventCreateNestedManyWithoutLeadInput>;
  industry?: InputMaybe<Scalars['String']['input']>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogCreateNestedManyWithoutLeadInput>;
  last_call_result?: InputMaybe<Scalars['String']['input']>;
  last_call_result_rep?: InputMaybe<UserCreateNestedOneWithoutLeads_Last_CalledInput>;
  last_call_result_time?: InputMaybe<Scalars['DateTime']['input']>;
  last_lead_assignment_origin?: InputMaybe<Scalars['DateTime']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  last_sent_to_resting_date?: InputMaybe<Scalars['DateTime']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutLeadInput>;
  lead_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutLeadInput>;
  lead_assignment_origin?: InputMaybe<AssignmentOrigin>;
  lead_creation_source?: InputMaybe<Leadcreationsource>;
  lead_custom_row_info?: InputMaybe<LeadCustomObjectRowAssociationCreateNestedManyWithoutLeadInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryCreateNestedOneWithoutLeadInput>;
  lead_import_history_csv?: InputMaybe<LeadImportHistoryCreateNestedOneWithoutUploaded_LeadsInput>;
  lead_intent?: InputMaybe<LeadIntentCreateNestedManyWithoutLeadInput>;
  lead_ownership_status?: InputMaybe<Los>;
  lead_source?: InputMaybe<Scalars['String']['input']>;
  lead_value?: InputMaybe<LeadValueCreateNestedOneWithoutLeadInput>;
  mrr?: InputMaybe<Scalars['Float']['input']>;
  newIncoming?: InputMaybe<Scalars['Boolean']['input']>;
  next_scheduled_event?: InputMaybe<ScheduleItemCreateNestedOneWithoutLeadInput>;
  opsiq_whitelist?: InputMaybe<Scalars['Boolean']['input']>;
  organization: OrganizationCreateNestedOneWithoutLeadsInput;
  phase?: InputMaybe<Phase>;
  primary_email?: InputMaybe<Scalars['String']['input']>;
  primary_email_title?: InputMaybe<Scalars['String']['input']>;
  primary_email_type?: InputMaybe<Scalars['String']['input']>;
  primary_lead_histories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutPrimary_LeadInput>;
  primary_phone_number?: InputMaybe<Scalars['String']['input']>;
  primary_phone_number_country_code?: InputMaybe<Scalars['String']['input']>;
  primary_phone_number_title?: InputMaybe<Scalars['String']['input']>;
  primary_phone_number_type?: InputMaybe<Scalars['String']['input']>;
  rep?: InputMaybe<UserCreateNestedOneWithoutOwned_LeadsInput>;
  resting_type?: InputMaybe<Restingtype>;
  routing_history?: InputMaybe<RoutingHistoryCreateNestedManyWithoutLeadInput>;
  routing_rule?: InputMaybe<RuleCreateNestedOneWithoutLeadInput>;
  Sale?: InputMaybe<SaleCreateNestedManyWithoutLeadInput>;
  sale_cycles?: InputMaybe<SaleCycleCreateNestedManyWithoutLeadInput>;
  search_content?: InputMaybe<Scalars['String']['input']>;
  secondary_reps?: InputMaybe<UserCreateNestedManyWithoutIdle_Owned_LeadsInput>;
  self_sourced?: InputMaybe<Scalars['Boolean']['input']>;
  sequence?: InputMaybe<SequenceCreateNestedOneWithoutLeadsInput>;
  sequence_step?: InputMaybe<SequenceStepCreateNestedOneWithoutLeadsInput>;
  SequenceActionLog?: InputMaybe<StepActionLogCreateNestedManyWithoutLeadInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutLeadInput>;
  sequences_completed_ids?: InputMaybe<LeadCreatesequences_Completed_IdsInput>;
  sequences_joined_ids?: InputMaybe<LeadCreatesequences_Joined_IdsInput>;
  sf_account_id?: InputMaybe<Scalars['String']['input']>;
  sf_contact_id?: InputMaybe<Scalars['String']['input']>;
  sf_lead_id?: InputMaybe<Scalars['String']['input']>;
  sibling_lead_histories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutSibling_LeadsInput>;
  sms_sub_status?: InputMaybe<OptInStatus>;
  stat_items?: InputMaybe<StatItemAggregationCreateNestedManyWithoutLeadInput>;
  state?: InputMaybe<Scalars['String']['input']>;
  StatLeadAggregation?: InputMaybe<StatLeadAggregationCreateNestedOneWithoutLeadInput>;
  status?: InputMaybe<Scalars['String']['input']>;
  sub_industry?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type LeadCreateWithoutOrganizationInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  address_2?: InputMaybe<Scalars['String']['input']>;
  alternate_contacts?: InputMaybe<ContactCreateNestedManyWithoutLeadInput>;
  assignment_locked?: InputMaybe<Scalars['Boolean']['input']>;
  associate_children?: InputMaybe<LeadCreateNestedManyWithoutAssociate_Parent_LeadInput>;
  associate_group_lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutAssociate_Group_LeadsInput>;
  associate_lead_histories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutAssociate_LeadInput>;
  associate_parent_lead?: InputMaybe<LeadCreateNestedOneWithoutAssociate_ChildrenInput>;
  associated_parent_intents?: InputMaybe<LeadIntentCreateNestedManyWithoutAssociate_ParentInput>;
  associates_search_content?: InputMaybe<Scalars['String']['input']>;
  business_name?: InputMaybe<Scalars['String']['input']>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptCreateNestedManyWithoutLeadInput>;
  callMeNows?: InputMaybe<CallMeNowCreateNestedManyWithoutLeadInput>;
  channel?: InputMaybe<Channel>;
  child_lead_activites?: InputMaybe<LeadActivityCreateNestedManyWithoutAssociate_ChildInput>;
  city?: InputMaybe<Scalars['String']['input']>;
  claim_date?: InputMaybe<Scalars['DateTime']['input']>;
  conference_number?: InputMaybe<Scalars['String']['input']>;
  contact_requests?: InputMaybe<ContactRequestCreateNestedManyWithoutLeadInput>;
  content?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  current_close_date?: InputMaybe<Scalars['DateTime']['input']>;
  current_contact_date?: InputMaybe<Scalars['DateTime']['input']>;
  current_sale_cycle?: InputMaybe<SaleCycleCreateNestedOneWithoutLead_If_CurrentInput>;
  custom_field_json?: InputMaybe<Scalars['Json']['input']>;
  custom_fields?: InputMaybe<CustomFieldCreateNestedManyWithoutLeadInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemCreateNestedManyWithoutLeadInput>;
  delay_action_status?: InputMaybe<Scalars['Boolean']['input']>;
  email_sub_status?: InputMaybe<OptInStatus>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordCreateNestedManyWithoutLeadInput>;
  EmailThread?: InputMaybe<EmailThreadCreateNestedManyWithoutLeadInput>;
  favorited_by_users?: InputMaybe<UserCreateNestedManyWithoutFavorite_LeadsInput>;
  first_demo_schedule_date?: InputMaybe<Scalars['DateTime']['input']>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  hubspot_company_id?: InputMaybe<Scalars['String']['input']>;
  hubspot_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  implied_next_action?: InputMaybe<Hingepoint>;
  in_algo_queue?: InputMaybe<Scalars['Boolean']['input']>;
  in_custom_sequence?: InputMaybe<Scalars['Int']['input']>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventCreateNestedManyWithoutLeadInput>;
  industry?: InputMaybe<Scalars['String']['input']>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogCreateNestedManyWithoutLeadInput>;
  last_call_result?: InputMaybe<Scalars['String']['input']>;
  last_call_result_rep?: InputMaybe<UserCreateNestedOneWithoutLeads_Last_CalledInput>;
  last_call_result_time?: InputMaybe<Scalars['DateTime']['input']>;
  last_lead_assignment_origin?: InputMaybe<Scalars['DateTime']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  last_sent_to_resting_date?: InputMaybe<Scalars['DateTime']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutLeadInput>;
  lead_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutLeadInput>;
  lead_assignment_origin?: InputMaybe<AssignmentOrigin>;
  lead_creation_source?: InputMaybe<Leadcreationsource>;
  lead_custom_row_info?: InputMaybe<LeadCustomObjectRowAssociationCreateNestedManyWithoutLeadInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryCreateNestedOneWithoutLeadInput>;
  lead_import_history_csv?: InputMaybe<LeadImportHistoryCreateNestedOneWithoutUploaded_LeadsInput>;
  lead_intent?: InputMaybe<LeadIntentCreateNestedManyWithoutLeadInput>;
  lead_ownership_status?: InputMaybe<Los>;
  lead_source?: InputMaybe<Scalars['String']['input']>;
  lead_value?: InputMaybe<LeadValueCreateNestedOneWithoutLeadInput>;
  mrr?: InputMaybe<Scalars['Float']['input']>;
  newIncoming?: InputMaybe<Scalars['Boolean']['input']>;
  next_scheduled_event?: InputMaybe<ScheduleItemCreateNestedOneWithoutLeadInput>;
  notifications?: InputMaybe<NotificationCreateNestedManyWithoutLeadInput>;
  opsiq_whitelist?: InputMaybe<Scalars['Boolean']['input']>;
  phase?: InputMaybe<Phase>;
  primary_email?: InputMaybe<Scalars['String']['input']>;
  primary_email_title?: InputMaybe<Scalars['String']['input']>;
  primary_email_type?: InputMaybe<Scalars['String']['input']>;
  primary_lead_histories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutPrimary_LeadInput>;
  primary_phone_number?: InputMaybe<Scalars['String']['input']>;
  primary_phone_number_country_code?: InputMaybe<Scalars['String']['input']>;
  primary_phone_number_title?: InputMaybe<Scalars['String']['input']>;
  primary_phone_number_type?: InputMaybe<Scalars['String']['input']>;
  rep?: InputMaybe<UserCreateNestedOneWithoutOwned_LeadsInput>;
  resting_type?: InputMaybe<Restingtype>;
  routing_history?: InputMaybe<RoutingHistoryCreateNestedManyWithoutLeadInput>;
  routing_rule?: InputMaybe<RuleCreateNestedOneWithoutLeadInput>;
  Sale?: InputMaybe<SaleCreateNestedManyWithoutLeadInput>;
  sale_cycles?: InputMaybe<SaleCycleCreateNestedManyWithoutLeadInput>;
  search_content?: InputMaybe<Scalars['String']['input']>;
  secondary_reps?: InputMaybe<UserCreateNestedManyWithoutIdle_Owned_LeadsInput>;
  self_sourced?: InputMaybe<Scalars['Boolean']['input']>;
  sequence?: InputMaybe<SequenceCreateNestedOneWithoutLeadsInput>;
  sequence_step?: InputMaybe<SequenceStepCreateNestedOneWithoutLeadsInput>;
  SequenceActionLog?: InputMaybe<StepActionLogCreateNestedManyWithoutLeadInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutLeadInput>;
  sequences_completed_ids?: InputMaybe<LeadCreatesequences_Completed_IdsInput>;
  sequences_joined_ids?: InputMaybe<LeadCreatesequences_Joined_IdsInput>;
  sf_account_id?: InputMaybe<Scalars['String']['input']>;
  sf_contact_id?: InputMaybe<Scalars['String']['input']>;
  sf_lead_id?: InputMaybe<Scalars['String']['input']>;
  sibling_lead_histories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutSibling_LeadsInput>;
  sms_sub_status?: InputMaybe<OptInStatus>;
  stat_items?: InputMaybe<StatItemAggregationCreateNestedManyWithoutLeadInput>;
  state?: InputMaybe<Scalars['String']['input']>;
  StatLeadAggregation?: InputMaybe<StatLeadAggregationCreateNestedOneWithoutLeadInput>;
  status?: InputMaybe<Scalars['String']['input']>;
  sub_industry?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type LeadCreateWithoutPrimary_Lead_HistoriesInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  address_2?: InputMaybe<Scalars['String']['input']>;
  alternate_contacts?: InputMaybe<ContactCreateNestedManyWithoutLeadInput>;
  assignment_locked?: InputMaybe<Scalars['Boolean']['input']>;
  associate_children?: InputMaybe<LeadCreateNestedManyWithoutAssociate_Parent_LeadInput>;
  associate_group_lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutAssociate_Group_LeadsInput>;
  associate_lead_histories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutAssociate_LeadInput>;
  associate_parent_lead?: InputMaybe<LeadCreateNestedOneWithoutAssociate_ChildrenInput>;
  associated_parent_intents?: InputMaybe<LeadIntentCreateNestedManyWithoutAssociate_ParentInput>;
  associates_search_content?: InputMaybe<Scalars['String']['input']>;
  business_name?: InputMaybe<Scalars['String']['input']>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptCreateNestedManyWithoutLeadInput>;
  callMeNows?: InputMaybe<CallMeNowCreateNestedManyWithoutLeadInput>;
  channel?: InputMaybe<Channel>;
  child_lead_activites?: InputMaybe<LeadActivityCreateNestedManyWithoutAssociate_ChildInput>;
  city?: InputMaybe<Scalars['String']['input']>;
  claim_date?: InputMaybe<Scalars['DateTime']['input']>;
  conference_number?: InputMaybe<Scalars['String']['input']>;
  contact_requests?: InputMaybe<ContactRequestCreateNestedManyWithoutLeadInput>;
  content?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  current_close_date?: InputMaybe<Scalars['DateTime']['input']>;
  current_contact_date?: InputMaybe<Scalars['DateTime']['input']>;
  current_sale_cycle?: InputMaybe<SaleCycleCreateNestedOneWithoutLead_If_CurrentInput>;
  custom_field_json?: InputMaybe<Scalars['Json']['input']>;
  custom_fields?: InputMaybe<CustomFieldCreateNestedManyWithoutLeadInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemCreateNestedManyWithoutLeadInput>;
  delay_action_status?: InputMaybe<Scalars['Boolean']['input']>;
  email_sub_status?: InputMaybe<OptInStatus>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordCreateNestedManyWithoutLeadInput>;
  EmailThread?: InputMaybe<EmailThreadCreateNestedManyWithoutLeadInput>;
  favorited_by_users?: InputMaybe<UserCreateNestedManyWithoutFavorite_LeadsInput>;
  first_demo_schedule_date?: InputMaybe<Scalars['DateTime']['input']>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  hubspot_company_id?: InputMaybe<Scalars['String']['input']>;
  hubspot_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  implied_next_action?: InputMaybe<Hingepoint>;
  in_algo_queue?: InputMaybe<Scalars['Boolean']['input']>;
  in_custom_sequence?: InputMaybe<Scalars['Int']['input']>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventCreateNestedManyWithoutLeadInput>;
  industry?: InputMaybe<Scalars['String']['input']>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogCreateNestedManyWithoutLeadInput>;
  last_call_result?: InputMaybe<Scalars['String']['input']>;
  last_call_result_rep?: InputMaybe<UserCreateNestedOneWithoutLeads_Last_CalledInput>;
  last_call_result_time?: InputMaybe<Scalars['DateTime']['input']>;
  last_lead_assignment_origin?: InputMaybe<Scalars['DateTime']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  last_sent_to_resting_date?: InputMaybe<Scalars['DateTime']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutLeadInput>;
  lead_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutLeadInput>;
  lead_assignment_origin?: InputMaybe<AssignmentOrigin>;
  lead_creation_source?: InputMaybe<Leadcreationsource>;
  lead_custom_row_info?: InputMaybe<LeadCustomObjectRowAssociationCreateNestedManyWithoutLeadInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryCreateNestedOneWithoutLeadInput>;
  lead_import_history_csv?: InputMaybe<LeadImportHistoryCreateNestedOneWithoutUploaded_LeadsInput>;
  lead_intent?: InputMaybe<LeadIntentCreateNestedManyWithoutLeadInput>;
  lead_ownership_status?: InputMaybe<Los>;
  lead_source?: InputMaybe<Scalars['String']['input']>;
  lead_value?: InputMaybe<LeadValueCreateNestedOneWithoutLeadInput>;
  mrr?: InputMaybe<Scalars['Float']['input']>;
  newIncoming?: InputMaybe<Scalars['Boolean']['input']>;
  next_scheduled_event?: InputMaybe<ScheduleItemCreateNestedOneWithoutLeadInput>;
  notifications?: InputMaybe<NotificationCreateNestedManyWithoutLeadInput>;
  opsiq_whitelist?: InputMaybe<Scalars['Boolean']['input']>;
  organization: OrganizationCreateNestedOneWithoutLeadsInput;
  phase?: InputMaybe<Phase>;
  primary_email?: InputMaybe<Scalars['String']['input']>;
  primary_email_title?: InputMaybe<Scalars['String']['input']>;
  primary_email_type?: InputMaybe<Scalars['String']['input']>;
  primary_phone_number?: InputMaybe<Scalars['String']['input']>;
  primary_phone_number_country_code?: InputMaybe<Scalars['String']['input']>;
  primary_phone_number_title?: InputMaybe<Scalars['String']['input']>;
  primary_phone_number_type?: InputMaybe<Scalars['String']['input']>;
  rep?: InputMaybe<UserCreateNestedOneWithoutOwned_LeadsInput>;
  resting_type?: InputMaybe<Restingtype>;
  routing_history?: InputMaybe<RoutingHistoryCreateNestedManyWithoutLeadInput>;
  routing_rule?: InputMaybe<RuleCreateNestedOneWithoutLeadInput>;
  Sale?: InputMaybe<SaleCreateNestedManyWithoutLeadInput>;
  sale_cycles?: InputMaybe<SaleCycleCreateNestedManyWithoutLeadInput>;
  search_content?: InputMaybe<Scalars['String']['input']>;
  secondary_reps?: InputMaybe<UserCreateNestedManyWithoutIdle_Owned_LeadsInput>;
  self_sourced?: InputMaybe<Scalars['Boolean']['input']>;
  sequence?: InputMaybe<SequenceCreateNestedOneWithoutLeadsInput>;
  sequence_step?: InputMaybe<SequenceStepCreateNestedOneWithoutLeadsInput>;
  SequenceActionLog?: InputMaybe<StepActionLogCreateNestedManyWithoutLeadInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutLeadInput>;
  sequences_completed_ids?: InputMaybe<LeadCreatesequences_Completed_IdsInput>;
  sequences_joined_ids?: InputMaybe<LeadCreatesequences_Joined_IdsInput>;
  sf_account_id?: InputMaybe<Scalars['String']['input']>;
  sf_contact_id?: InputMaybe<Scalars['String']['input']>;
  sf_lead_id?: InputMaybe<Scalars['String']['input']>;
  sibling_lead_histories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutSibling_LeadsInput>;
  sms_sub_status?: InputMaybe<OptInStatus>;
  stat_items?: InputMaybe<StatItemAggregationCreateNestedManyWithoutLeadInput>;
  state?: InputMaybe<Scalars['String']['input']>;
  StatLeadAggregation?: InputMaybe<StatLeadAggregationCreateNestedOneWithoutLeadInput>;
  status?: InputMaybe<Scalars['String']['input']>;
  sub_industry?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type LeadCreateWithoutRepInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  address_2?: InputMaybe<Scalars['String']['input']>;
  alternate_contacts?: InputMaybe<ContactCreateNestedManyWithoutLeadInput>;
  assignment_locked?: InputMaybe<Scalars['Boolean']['input']>;
  associate_children?: InputMaybe<LeadCreateNestedManyWithoutAssociate_Parent_LeadInput>;
  associate_group_lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutAssociate_Group_LeadsInput>;
  associate_lead_histories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutAssociate_LeadInput>;
  associate_parent_lead?: InputMaybe<LeadCreateNestedOneWithoutAssociate_ChildrenInput>;
  associated_parent_intents?: InputMaybe<LeadIntentCreateNestedManyWithoutAssociate_ParentInput>;
  associates_search_content?: InputMaybe<Scalars['String']['input']>;
  business_name?: InputMaybe<Scalars['String']['input']>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptCreateNestedManyWithoutLeadInput>;
  callMeNows?: InputMaybe<CallMeNowCreateNestedManyWithoutLeadInput>;
  channel?: InputMaybe<Channel>;
  child_lead_activites?: InputMaybe<LeadActivityCreateNestedManyWithoutAssociate_ChildInput>;
  city?: InputMaybe<Scalars['String']['input']>;
  claim_date?: InputMaybe<Scalars['DateTime']['input']>;
  conference_number?: InputMaybe<Scalars['String']['input']>;
  contact_requests?: InputMaybe<ContactRequestCreateNestedManyWithoutLeadInput>;
  content?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  current_close_date?: InputMaybe<Scalars['DateTime']['input']>;
  current_contact_date?: InputMaybe<Scalars['DateTime']['input']>;
  current_sale_cycle?: InputMaybe<SaleCycleCreateNestedOneWithoutLead_If_CurrentInput>;
  custom_field_json?: InputMaybe<Scalars['Json']['input']>;
  custom_fields?: InputMaybe<CustomFieldCreateNestedManyWithoutLeadInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemCreateNestedManyWithoutLeadInput>;
  delay_action_status?: InputMaybe<Scalars['Boolean']['input']>;
  email_sub_status?: InputMaybe<OptInStatus>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordCreateNestedManyWithoutLeadInput>;
  EmailThread?: InputMaybe<EmailThreadCreateNestedManyWithoutLeadInput>;
  favorited_by_users?: InputMaybe<UserCreateNestedManyWithoutFavorite_LeadsInput>;
  first_demo_schedule_date?: InputMaybe<Scalars['DateTime']['input']>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  hubspot_company_id?: InputMaybe<Scalars['String']['input']>;
  hubspot_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  implied_next_action?: InputMaybe<Hingepoint>;
  in_algo_queue?: InputMaybe<Scalars['Boolean']['input']>;
  in_custom_sequence?: InputMaybe<Scalars['Int']['input']>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventCreateNestedManyWithoutLeadInput>;
  industry?: InputMaybe<Scalars['String']['input']>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogCreateNestedManyWithoutLeadInput>;
  last_call_result?: InputMaybe<Scalars['String']['input']>;
  last_call_result_rep?: InputMaybe<UserCreateNestedOneWithoutLeads_Last_CalledInput>;
  last_call_result_time?: InputMaybe<Scalars['DateTime']['input']>;
  last_lead_assignment_origin?: InputMaybe<Scalars['DateTime']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  last_sent_to_resting_date?: InputMaybe<Scalars['DateTime']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutLeadInput>;
  lead_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutLeadInput>;
  lead_assignment_origin?: InputMaybe<AssignmentOrigin>;
  lead_creation_source?: InputMaybe<Leadcreationsource>;
  lead_custom_row_info?: InputMaybe<LeadCustomObjectRowAssociationCreateNestedManyWithoutLeadInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryCreateNestedOneWithoutLeadInput>;
  lead_import_history_csv?: InputMaybe<LeadImportHistoryCreateNestedOneWithoutUploaded_LeadsInput>;
  lead_intent?: InputMaybe<LeadIntentCreateNestedManyWithoutLeadInput>;
  lead_ownership_status?: InputMaybe<Los>;
  lead_source?: InputMaybe<Scalars['String']['input']>;
  lead_value?: InputMaybe<LeadValueCreateNestedOneWithoutLeadInput>;
  mrr?: InputMaybe<Scalars['Float']['input']>;
  newIncoming?: InputMaybe<Scalars['Boolean']['input']>;
  next_scheduled_event?: InputMaybe<ScheduleItemCreateNestedOneWithoutLeadInput>;
  notifications?: InputMaybe<NotificationCreateNestedManyWithoutLeadInput>;
  opsiq_whitelist?: InputMaybe<Scalars['Boolean']['input']>;
  organization: OrganizationCreateNestedOneWithoutLeadsInput;
  phase?: InputMaybe<Phase>;
  primary_email?: InputMaybe<Scalars['String']['input']>;
  primary_email_title?: InputMaybe<Scalars['String']['input']>;
  primary_email_type?: InputMaybe<Scalars['String']['input']>;
  primary_lead_histories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutPrimary_LeadInput>;
  primary_phone_number?: InputMaybe<Scalars['String']['input']>;
  primary_phone_number_country_code?: InputMaybe<Scalars['String']['input']>;
  primary_phone_number_title?: InputMaybe<Scalars['String']['input']>;
  primary_phone_number_type?: InputMaybe<Scalars['String']['input']>;
  resting_type?: InputMaybe<Restingtype>;
  routing_history?: InputMaybe<RoutingHistoryCreateNestedManyWithoutLeadInput>;
  routing_rule?: InputMaybe<RuleCreateNestedOneWithoutLeadInput>;
  Sale?: InputMaybe<SaleCreateNestedManyWithoutLeadInput>;
  sale_cycles?: InputMaybe<SaleCycleCreateNestedManyWithoutLeadInput>;
  search_content?: InputMaybe<Scalars['String']['input']>;
  secondary_reps?: InputMaybe<UserCreateNestedManyWithoutIdle_Owned_LeadsInput>;
  self_sourced?: InputMaybe<Scalars['Boolean']['input']>;
  sequence?: InputMaybe<SequenceCreateNestedOneWithoutLeadsInput>;
  sequence_step?: InputMaybe<SequenceStepCreateNestedOneWithoutLeadsInput>;
  SequenceActionLog?: InputMaybe<StepActionLogCreateNestedManyWithoutLeadInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutLeadInput>;
  sequences_completed_ids?: InputMaybe<LeadCreatesequences_Completed_IdsInput>;
  sequences_joined_ids?: InputMaybe<LeadCreatesequences_Joined_IdsInput>;
  sf_account_id?: InputMaybe<Scalars['String']['input']>;
  sf_contact_id?: InputMaybe<Scalars['String']['input']>;
  sf_lead_id?: InputMaybe<Scalars['String']['input']>;
  sibling_lead_histories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutSibling_LeadsInput>;
  sms_sub_status?: InputMaybe<OptInStatus>;
  stat_items?: InputMaybe<StatItemAggregationCreateNestedManyWithoutLeadInput>;
  state?: InputMaybe<Scalars['String']['input']>;
  StatLeadAggregation?: InputMaybe<StatLeadAggregationCreateNestedOneWithoutLeadInput>;
  status?: InputMaybe<Scalars['String']['input']>;
  sub_industry?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type LeadCreateWithoutRouting_HistoryInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  address_2?: InputMaybe<Scalars['String']['input']>;
  alternate_contacts?: InputMaybe<ContactCreateNestedManyWithoutLeadInput>;
  assignment_locked?: InputMaybe<Scalars['Boolean']['input']>;
  associate_children?: InputMaybe<LeadCreateNestedManyWithoutAssociate_Parent_LeadInput>;
  associate_group_lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutAssociate_Group_LeadsInput>;
  associate_lead_histories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutAssociate_LeadInput>;
  associate_parent_lead?: InputMaybe<LeadCreateNestedOneWithoutAssociate_ChildrenInput>;
  associated_parent_intents?: InputMaybe<LeadIntentCreateNestedManyWithoutAssociate_ParentInput>;
  associates_search_content?: InputMaybe<Scalars['String']['input']>;
  business_name?: InputMaybe<Scalars['String']['input']>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptCreateNestedManyWithoutLeadInput>;
  callMeNows?: InputMaybe<CallMeNowCreateNestedManyWithoutLeadInput>;
  channel?: InputMaybe<Channel>;
  child_lead_activites?: InputMaybe<LeadActivityCreateNestedManyWithoutAssociate_ChildInput>;
  city?: InputMaybe<Scalars['String']['input']>;
  claim_date?: InputMaybe<Scalars['DateTime']['input']>;
  conference_number?: InputMaybe<Scalars['String']['input']>;
  contact_requests?: InputMaybe<ContactRequestCreateNestedManyWithoutLeadInput>;
  content?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  current_close_date?: InputMaybe<Scalars['DateTime']['input']>;
  current_contact_date?: InputMaybe<Scalars['DateTime']['input']>;
  current_sale_cycle?: InputMaybe<SaleCycleCreateNestedOneWithoutLead_If_CurrentInput>;
  custom_field_json?: InputMaybe<Scalars['Json']['input']>;
  custom_fields?: InputMaybe<CustomFieldCreateNestedManyWithoutLeadInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemCreateNestedManyWithoutLeadInput>;
  delay_action_status?: InputMaybe<Scalars['Boolean']['input']>;
  email_sub_status?: InputMaybe<OptInStatus>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordCreateNestedManyWithoutLeadInput>;
  EmailThread?: InputMaybe<EmailThreadCreateNestedManyWithoutLeadInput>;
  favorited_by_users?: InputMaybe<UserCreateNestedManyWithoutFavorite_LeadsInput>;
  first_demo_schedule_date?: InputMaybe<Scalars['DateTime']['input']>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  hubspot_company_id?: InputMaybe<Scalars['String']['input']>;
  hubspot_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  implied_next_action?: InputMaybe<Hingepoint>;
  in_algo_queue?: InputMaybe<Scalars['Boolean']['input']>;
  in_custom_sequence?: InputMaybe<Scalars['Int']['input']>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventCreateNestedManyWithoutLeadInput>;
  industry?: InputMaybe<Scalars['String']['input']>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogCreateNestedManyWithoutLeadInput>;
  last_call_result?: InputMaybe<Scalars['String']['input']>;
  last_call_result_rep?: InputMaybe<UserCreateNestedOneWithoutLeads_Last_CalledInput>;
  last_call_result_time?: InputMaybe<Scalars['DateTime']['input']>;
  last_lead_assignment_origin?: InputMaybe<Scalars['DateTime']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  last_sent_to_resting_date?: InputMaybe<Scalars['DateTime']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutLeadInput>;
  lead_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutLeadInput>;
  lead_assignment_origin?: InputMaybe<AssignmentOrigin>;
  lead_creation_source?: InputMaybe<Leadcreationsource>;
  lead_custom_row_info?: InputMaybe<LeadCustomObjectRowAssociationCreateNestedManyWithoutLeadInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryCreateNestedOneWithoutLeadInput>;
  lead_import_history_csv?: InputMaybe<LeadImportHistoryCreateNestedOneWithoutUploaded_LeadsInput>;
  lead_intent?: InputMaybe<LeadIntentCreateNestedManyWithoutLeadInput>;
  lead_ownership_status?: InputMaybe<Los>;
  lead_source?: InputMaybe<Scalars['String']['input']>;
  lead_value?: InputMaybe<LeadValueCreateNestedOneWithoutLeadInput>;
  mrr?: InputMaybe<Scalars['Float']['input']>;
  newIncoming?: InputMaybe<Scalars['Boolean']['input']>;
  next_scheduled_event?: InputMaybe<ScheduleItemCreateNestedOneWithoutLeadInput>;
  notifications?: InputMaybe<NotificationCreateNestedManyWithoutLeadInput>;
  opsiq_whitelist?: InputMaybe<Scalars['Boolean']['input']>;
  organization: OrganizationCreateNestedOneWithoutLeadsInput;
  phase?: InputMaybe<Phase>;
  primary_email?: InputMaybe<Scalars['String']['input']>;
  primary_email_title?: InputMaybe<Scalars['String']['input']>;
  primary_email_type?: InputMaybe<Scalars['String']['input']>;
  primary_lead_histories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutPrimary_LeadInput>;
  primary_phone_number?: InputMaybe<Scalars['String']['input']>;
  primary_phone_number_country_code?: InputMaybe<Scalars['String']['input']>;
  primary_phone_number_title?: InputMaybe<Scalars['String']['input']>;
  primary_phone_number_type?: InputMaybe<Scalars['String']['input']>;
  rep?: InputMaybe<UserCreateNestedOneWithoutOwned_LeadsInput>;
  resting_type?: InputMaybe<Restingtype>;
  routing_rule?: InputMaybe<RuleCreateNestedOneWithoutLeadInput>;
  Sale?: InputMaybe<SaleCreateNestedManyWithoutLeadInput>;
  sale_cycles?: InputMaybe<SaleCycleCreateNestedManyWithoutLeadInput>;
  search_content?: InputMaybe<Scalars['String']['input']>;
  secondary_reps?: InputMaybe<UserCreateNestedManyWithoutIdle_Owned_LeadsInput>;
  self_sourced?: InputMaybe<Scalars['Boolean']['input']>;
  sequence?: InputMaybe<SequenceCreateNestedOneWithoutLeadsInput>;
  sequence_step?: InputMaybe<SequenceStepCreateNestedOneWithoutLeadsInput>;
  SequenceActionLog?: InputMaybe<StepActionLogCreateNestedManyWithoutLeadInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutLeadInput>;
  sequences_completed_ids?: InputMaybe<LeadCreatesequences_Completed_IdsInput>;
  sequences_joined_ids?: InputMaybe<LeadCreatesequences_Joined_IdsInput>;
  sf_account_id?: InputMaybe<Scalars['String']['input']>;
  sf_contact_id?: InputMaybe<Scalars['String']['input']>;
  sf_lead_id?: InputMaybe<Scalars['String']['input']>;
  sibling_lead_histories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutSibling_LeadsInput>;
  sms_sub_status?: InputMaybe<OptInStatus>;
  stat_items?: InputMaybe<StatItemAggregationCreateNestedManyWithoutLeadInput>;
  state?: InputMaybe<Scalars['String']['input']>;
  StatLeadAggregation?: InputMaybe<StatLeadAggregationCreateNestedOneWithoutLeadInput>;
  status?: InputMaybe<Scalars['String']['input']>;
  sub_industry?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type LeadCreateWithoutRouting_RuleInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  address_2?: InputMaybe<Scalars['String']['input']>;
  alternate_contacts?: InputMaybe<ContactCreateNestedManyWithoutLeadInput>;
  assignment_locked?: InputMaybe<Scalars['Boolean']['input']>;
  associate_children?: InputMaybe<LeadCreateNestedManyWithoutAssociate_Parent_LeadInput>;
  associate_group_lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutAssociate_Group_LeadsInput>;
  associate_lead_histories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutAssociate_LeadInput>;
  associate_parent_lead?: InputMaybe<LeadCreateNestedOneWithoutAssociate_ChildrenInput>;
  associated_parent_intents?: InputMaybe<LeadIntentCreateNestedManyWithoutAssociate_ParentInput>;
  associates_search_content?: InputMaybe<Scalars['String']['input']>;
  business_name?: InputMaybe<Scalars['String']['input']>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptCreateNestedManyWithoutLeadInput>;
  callMeNows?: InputMaybe<CallMeNowCreateNestedManyWithoutLeadInput>;
  channel?: InputMaybe<Channel>;
  child_lead_activites?: InputMaybe<LeadActivityCreateNestedManyWithoutAssociate_ChildInput>;
  city?: InputMaybe<Scalars['String']['input']>;
  claim_date?: InputMaybe<Scalars['DateTime']['input']>;
  conference_number?: InputMaybe<Scalars['String']['input']>;
  contact_requests?: InputMaybe<ContactRequestCreateNestedManyWithoutLeadInput>;
  content?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  current_close_date?: InputMaybe<Scalars['DateTime']['input']>;
  current_contact_date?: InputMaybe<Scalars['DateTime']['input']>;
  current_sale_cycle?: InputMaybe<SaleCycleCreateNestedOneWithoutLead_If_CurrentInput>;
  custom_field_json?: InputMaybe<Scalars['Json']['input']>;
  custom_fields?: InputMaybe<CustomFieldCreateNestedManyWithoutLeadInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemCreateNestedManyWithoutLeadInput>;
  delay_action_status?: InputMaybe<Scalars['Boolean']['input']>;
  email_sub_status?: InputMaybe<OptInStatus>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordCreateNestedManyWithoutLeadInput>;
  EmailThread?: InputMaybe<EmailThreadCreateNestedManyWithoutLeadInput>;
  favorited_by_users?: InputMaybe<UserCreateNestedManyWithoutFavorite_LeadsInput>;
  first_demo_schedule_date?: InputMaybe<Scalars['DateTime']['input']>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  hubspot_company_id?: InputMaybe<Scalars['String']['input']>;
  hubspot_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  implied_next_action?: InputMaybe<Hingepoint>;
  in_algo_queue?: InputMaybe<Scalars['Boolean']['input']>;
  in_custom_sequence?: InputMaybe<Scalars['Int']['input']>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventCreateNestedManyWithoutLeadInput>;
  industry?: InputMaybe<Scalars['String']['input']>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogCreateNestedManyWithoutLeadInput>;
  last_call_result?: InputMaybe<Scalars['String']['input']>;
  last_call_result_rep?: InputMaybe<UserCreateNestedOneWithoutLeads_Last_CalledInput>;
  last_call_result_time?: InputMaybe<Scalars['DateTime']['input']>;
  last_lead_assignment_origin?: InputMaybe<Scalars['DateTime']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  last_sent_to_resting_date?: InputMaybe<Scalars['DateTime']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutLeadInput>;
  lead_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutLeadInput>;
  lead_assignment_origin?: InputMaybe<AssignmentOrigin>;
  lead_creation_source?: InputMaybe<Leadcreationsource>;
  lead_custom_row_info?: InputMaybe<LeadCustomObjectRowAssociationCreateNestedManyWithoutLeadInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryCreateNestedOneWithoutLeadInput>;
  lead_import_history_csv?: InputMaybe<LeadImportHistoryCreateNestedOneWithoutUploaded_LeadsInput>;
  lead_intent?: InputMaybe<LeadIntentCreateNestedManyWithoutLeadInput>;
  lead_ownership_status?: InputMaybe<Los>;
  lead_source?: InputMaybe<Scalars['String']['input']>;
  lead_value?: InputMaybe<LeadValueCreateNestedOneWithoutLeadInput>;
  mrr?: InputMaybe<Scalars['Float']['input']>;
  newIncoming?: InputMaybe<Scalars['Boolean']['input']>;
  next_scheduled_event?: InputMaybe<ScheduleItemCreateNestedOneWithoutLeadInput>;
  notifications?: InputMaybe<NotificationCreateNestedManyWithoutLeadInput>;
  opsiq_whitelist?: InputMaybe<Scalars['Boolean']['input']>;
  organization: OrganizationCreateNestedOneWithoutLeadsInput;
  phase?: InputMaybe<Phase>;
  primary_email?: InputMaybe<Scalars['String']['input']>;
  primary_email_title?: InputMaybe<Scalars['String']['input']>;
  primary_email_type?: InputMaybe<Scalars['String']['input']>;
  primary_lead_histories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutPrimary_LeadInput>;
  primary_phone_number?: InputMaybe<Scalars['String']['input']>;
  primary_phone_number_country_code?: InputMaybe<Scalars['String']['input']>;
  primary_phone_number_title?: InputMaybe<Scalars['String']['input']>;
  primary_phone_number_type?: InputMaybe<Scalars['String']['input']>;
  rep?: InputMaybe<UserCreateNestedOneWithoutOwned_LeadsInput>;
  resting_type?: InputMaybe<Restingtype>;
  routing_history?: InputMaybe<RoutingHistoryCreateNestedManyWithoutLeadInput>;
  Sale?: InputMaybe<SaleCreateNestedManyWithoutLeadInput>;
  sale_cycles?: InputMaybe<SaleCycleCreateNestedManyWithoutLeadInput>;
  search_content?: InputMaybe<Scalars['String']['input']>;
  secondary_reps?: InputMaybe<UserCreateNestedManyWithoutIdle_Owned_LeadsInput>;
  self_sourced?: InputMaybe<Scalars['Boolean']['input']>;
  sequence?: InputMaybe<SequenceCreateNestedOneWithoutLeadsInput>;
  sequence_step?: InputMaybe<SequenceStepCreateNestedOneWithoutLeadsInput>;
  SequenceActionLog?: InputMaybe<StepActionLogCreateNestedManyWithoutLeadInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutLeadInput>;
  sequences_completed_ids?: InputMaybe<LeadCreatesequences_Completed_IdsInput>;
  sequences_joined_ids?: InputMaybe<LeadCreatesequences_Joined_IdsInput>;
  sf_account_id?: InputMaybe<Scalars['String']['input']>;
  sf_contact_id?: InputMaybe<Scalars['String']['input']>;
  sf_lead_id?: InputMaybe<Scalars['String']['input']>;
  sibling_lead_histories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutSibling_LeadsInput>;
  sms_sub_status?: InputMaybe<OptInStatus>;
  stat_items?: InputMaybe<StatItemAggregationCreateNestedManyWithoutLeadInput>;
  state?: InputMaybe<Scalars['String']['input']>;
  StatLeadAggregation?: InputMaybe<StatLeadAggregationCreateNestedOneWithoutLeadInput>;
  status?: InputMaybe<Scalars['String']['input']>;
  sub_industry?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type LeadCreateWithoutSale_CyclesInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  address_2?: InputMaybe<Scalars['String']['input']>;
  alternate_contacts?: InputMaybe<ContactCreateNestedManyWithoutLeadInput>;
  assignment_locked?: InputMaybe<Scalars['Boolean']['input']>;
  associate_children?: InputMaybe<LeadCreateNestedManyWithoutAssociate_Parent_LeadInput>;
  associate_group_lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutAssociate_Group_LeadsInput>;
  associate_lead_histories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutAssociate_LeadInput>;
  associate_parent_lead?: InputMaybe<LeadCreateNestedOneWithoutAssociate_ChildrenInput>;
  associated_parent_intents?: InputMaybe<LeadIntentCreateNestedManyWithoutAssociate_ParentInput>;
  associates_search_content?: InputMaybe<Scalars['String']['input']>;
  business_name?: InputMaybe<Scalars['String']['input']>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptCreateNestedManyWithoutLeadInput>;
  callMeNows?: InputMaybe<CallMeNowCreateNestedManyWithoutLeadInput>;
  channel?: InputMaybe<Channel>;
  child_lead_activites?: InputMaybe<LeadActivityCreateNestedManyWithoutAssociate_ChildInput>;
  city?: InputMaybe<Scalars['String']['input']>;
  claim_date?: InputMaybe<Scalars['DateTime']['input']>;
  conference_number?: InputMaybe<Scalars['String']['input']>;
  contact_requests?: InputMaybe<ContactRequestCreateNestedManyWithoutLeadInput>;
  content?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  current_close_date?: InputMaybe<Scalars['DateTime']['input']>;
  current_contact_date?: InputMaybe<Scalars['DateTime']['input']>;
  current_sale_cycle?: InputMaybe<SaleCycleCreateNestedOneWithoutLead_If_CurrentInput>;
  custom_field_json?: InputMaybe<Scalars['Json']['input']>;
  custom_fields?: InputMaybe<CustomFieldCreateNestedManyWithoutLeadInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemCreateNestedManyWithoutLeadInput>;
  delay_action_status?: InputMaybe<Scalars['Boolean']['input']>;
  email_sub_status?: InputMaybe<OptInStatus>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordCreateNestedManyWithoutLeadInput>;
  EmailThread?: InputMaybe<EmailThreadCreateNestedManyWithoutLeadInput>;
  favorited_by_users?: InputMaybe<UserCreateNestedManyWithoutFavorite_LeadsInput>;
  first_demo_schedule_date?: InputMaybe<Scalars['DateTime']['input']>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  hubspot_company_id?: InputMaybe<Scalars['String']['input']>;
  hubspot_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  implied_next_action?: InputMaybe<Hingepoint>;
  in_algo_queue?: InputMaybe<Scalars['Boolean']['input']>;
  in_custom_sequence?: InputMaybe<Scalars['Int']['input']>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventCreateNestedManyWithoutLeadInput>;
  industry?: InputMaybe<Scalars['String']['input']>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogCreateNestedManyWithoutLeadInput>;
  last_call_result?: InputMaybe<Scalars['String']['input']>;
  last_call_result_rep?: InputMaybe<UserCreateNestedOneWithoutLeads_Last_CalledInput>;
  last_call_result_time?: InputMaybe<Scalars['DateTime']['input']>;
  last_lead_assignment_origin?: InputMaybe<Scalars['DateTime']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  last_sent_to_resting_date?: InputMaybe<Scalars['DateTime']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutLeadInput>;
  lead_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutLeadInput>;
  lead_assignment_origin?: InputMaybe<AssignmentOrigin>;
  lead_creation_source?: InputMaybe<Leadcreationsource>;
  lead_custom_row_info?: InputMaybe<LeadCustomObjectRowAssociationCreateNestedManyWithoutLeadInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryCreateNestedOneWithoutLeadInput>;
  lead_import_history_csv?: InputMaybe<LeadImportHistoryCreateNestedOneWithoutUploaded_LeadsInput>;
  lead_intent?: InputMaybe<LeadIntentCreateNestedManyWithoutLeadInput>;
  lead_ownership_status?: InputMaybe<Los>;
  lead_source?: InputMaybe<Scalars['String']['input']>;
  lead_value?: InputMaybe<LeadValueCreateNestedOneWithoutLeadInput>;
  mrr?: InputMaybe<Scalars['Float']['input']>;
  newIncoming?: InputMaybe<Scalars['Boolean']['input']>;
  next_scheduled_event?: InputMaybe<ScheduleItemCreateNestedOneWithoutLeadInput>;
  notifications?: InputMaybe<NotificationCreateNestedManyWithoutLeadInput>;
  opsiq_whitelist?: InputMaybe<Scalars['Boolean']['input']>;
  organization: OrganizationCreateNestedOneWithoutLeadsInput;
  phase?: InputMaybe<Phase>;
  primary_email?: InputMaybe<Scalars['String']['input']>;
  primary_email_title?: InputMaybe<Scalars['String']['input']>;
  primary_email_type?: InputMaybe<Scalars['String']['input']>;
  primary_lead_histories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutPrimary_LeadInput>;
  primary_phone_number?: InputMaybe<Scalars['String']['input']>;
  primary_phone_number_country_code?: InputMaybe<Scalars['String']['input']>;
  primary_phone_number_title?: InputMaybe<Scalars['String']['input']>;
  primary_phone_number_type?: InputMaybe<Scalars['String']['input']>;
  rep?: InputMaybe<UserCreateNestedOneWithoutOwned_LeadsInput>;
  resting_type?: InputMaybe<Restingtype>;
  routing_history?: InputMaybe<RoutingHistoryCreateNestedManyWithoutLeadInput>;
  routing_rule?: InputMaybe<RuleCreateNestedOneWithoutLeadInput>;
  Sale?: InputMaybe<SaleCreateNestedManyWithoutLeadInput>;
  search_content?: InputMaybe<Scalars['String']['input']>;
  secondary_reps?: InputMaybe<UserCreateNestedManyWithoutIdle_Owned_LeadsInput>;
  self_sourced?: InputMaybe<Scalars['Boolean']['input']>;
  sequence?: InputMaybe<SequenceCreateNestedOneWithoutLeadsInput>;
  sequence_step?: InputMaybe<SequenceStepCreateNestedOneWithoutLeadsInput>;
  SequenceActionLog?: InputMaybe<StepActionLogCreateNestedManyWithoutLeadInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutLeadInput>;
  sequences_completed_ids?: InputMaybe<LeadCreatesequences_Completed_IdsInput>;
  sequences_joined_ids?: InputMaybe<LeadCreatesequences_Joined_IdsInput>;
  sf_account_id?: InputMaybe<Scalars['String']['input']>;
  sf_contact_id?: InputMaybe<Scalars['String']['input']>;
  sf_lead_id?: InputMaybe<Scalars['String']['input']>;
  sibling_lead_histories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutSibling_LeadsInput>;
  sms_sub_status?: InputMaybe<OptInStatus>;
  stat_items?: InputMaybe<StatItemAggregationCreateNestedManyWithoutLeadInput>;
  state?: InputMaybe<Scalars['String']['input']>;
  StatLeadAggregation?: InputMaybe<StatLeadAggregationCreateNestedOneWithoutLeadInput>;
  status?: InputMaybe<Scalars['String']['input']>;
  sub_industry?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type LeadCreateWithoutSaleInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  address_2?: InputMaybe<Scalars['String']['input']>;
  alternate_contacts?: InputMaybe<ContactCreateNestedManyWithoutLeadInput>;
  assignment_locked?: InputMaybe<Scalars['Boolean']['input']>;
  associate_children?: InputMaybe<LeadCreateNestedManyWithoutAssociate_Parent_LeadInput>;
  associate_group_lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutAssociate_Group_LeadsInput>;
  associate_lead_histories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutAssociate_LeadInput>;
  associate_parent_lead?: InputMaybe<LeadCreateNestedOneWithoutAssociate_ChildrenInput>;
  associated_parent_intents?: InputMaybe<LeadIntentCreateNestedManyWithoutAssociate_ParentInput>;
  associates_search_content?: InputMaybe<Scalars['String']['input']>;
  business_name?: InputMaybe<Scalars['String']['input']>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptCreateNestedManyWithoutLeadInput>;
  callMeNows?: InputMaybe<CallMeNowCreateNestedManyWithoutLeadInput>;
  channel?: InputMaybe<Channel>;
  child_lead_activites?: InputMaybe<LeadActivityCreateNestedManyWithoutAssociate_ChildInput>;
  city?: InputMaybe<Scalars['String']['input']>;
  claim_date?: InputMaybe<Scalars['DateTime']['input']>;
  conference_number?: InputMaybe<Scalars['String']['input']>;
  contact_requests?: InputMaybe<ContactRequestCreateNestedManyWithoutLeadInput>;
  content?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  current_close_date?: InputMaybe<Scalars['DateTime']['input']>;
  current_contact_date?: InputMaybe<Scalars['DateTime']['input']>;
  current_sale_cycle?: InputMaybe<SaleCycleCreateNestedOneWithoutLead_If_CurrentInput>;
  custom_field_json?: InputMaybe<Scalars['Json']['input']>;
  custom_fields?: InputMaybe<CustomFieldCreateNestedManyWithoutLeadInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemCreateNestedManyWithoutLeadInput>;
  delay_action_status?: InputMaybe<Scalars['Boolean']['input']>;
  email_sub_status?: InputMaybe<OptInStatus>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordCreateNestedManyWithoutLeadInput>;
  EmailThread?: InputMaybe<EmailThreadCreateNestedManyWithoutLeadInput>;
  favorited_by_users?: InputMaybe<UserCreateNestedManyWithoutFavorite_LeadsInput>;
  first_demo_schedule_date?: InputMaybe<Scalars['DateTime']['input']>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  hubspot_company_id?: InputMaybe<Scalars['String']['input']>;
  hubspot_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  implied_next_action?: InputMaybe<Hingepoint>;
  in_algo_queue?: InputMaybe<Scalars['Boolean']['input']>;
  in_custom_sequence?: InputMaybe<Scalars['Int']['input']>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventCreateNestedManyWithoutLeadInput>;
  industry?: InputMaybe<Scalars['String']['input']>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogCreateNestedManyWithoutLeadInput>;
  last_call_result?: InputMaybe<Scalars['String']['input']>;
  last_call_result_rep?: InputMaybe<UserCreateNestedOneWithoutLeads_Last_CalledInput>;
  last_call_result_time?: InputMaybe<Scalars['DateTime']['input']>;
  last_lead_assignment_origin?: InputMaybe<Scalars['DateTime']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  last_sent_to_resting_date?: InputMaybe<Scalars['DateTime']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutLeadInput>;
  lead_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutLeadInput>;
  lead_assignment_origin?: InputMaybe<AssignmentOrigin>;
  lead_creation_source?: InputMaybe<Leadcreationsource>;
  lead_custom_row_info?: InputMaybe<LeadCustomObjectRowAssociationCreateNestedManyWithoutLeadInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryCreateNestedOneWithoutLeadInput>;
  lead_import_history_csv?: InputMaybe<LeadImportHistoryCreateNestedOneWithoutUploaded_LeadsInput>;
  lead_intent?: InputMaybe<LeadIntentCreateNestedManyWithoutLeadInput>;
  lead_ownership_status?: InputMaybe<Los>;
  lead_source?: InputMaybe<Scalars['String']['input']>;
  lead_value?: InputMaybe<LeadValueCreateNestedOneWithoutLeadInput>;
  mrr?: InputMaybe<Scalars['Float']['input']>;
  newIncoming?: InputMaybe<Scalars['Boolean']['input']>;
  next_scheduled_event?: InputMaybe<ScheduleItemCreateNestedOneWithoutLeadInput>;
  notifications?: InputMaybe<NotificationCreateNestedManyWithoutLeadInput>;
  opsiq_whitelist?: InputMaybe<Scalars['Boolean']['input']>;
  organization: OrganizationCreateNestedOneWithoutLeadsInput;
  phase?: InputMaybe<Phase>;
  primary_email?: InputMaybe<Scalars['String']['input']>;
  primary_email_title?: InputMaybe<Scalars['String']['input']>;
  primary_email_type?: InputMaybe<Scalars['String']['input']>;
  primary_lead_histories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutPrimary_LeadInput>;
  primary_phone_number?: InputMaybe<Scalars['String']['input']>;
  primary_phone_number_country_code?: InputMaybe<Scalars['String']['input']>;
  primary_phone_number_title?: InputMaybe<Scalars['String']['input']>;
  primary_phone_number_type?: InputMaybe<Scalars['String']['input']>;
  rep?: InputMaybe<UserCreateNestedOneWithoutOwned_LeadsInput>;
  resting_type?: InputMaybe<Restingtype>;
  routing_history?: InputMaybe<RoutingHistoryCreateNestedManyWithoutLeadInput>;
  routing_rule?: InputMaybe<RuleCreateNestedOneWithoutLeadInput>;
  sale_cycles?: InputMaybe<SaleCycleCreateNestedManyWithoutLeadInput>;
  search_content?: InputMaybe<Scalars['String']['input']>;
  secondary_reps?: InputMaybe<UserCreateNestedManyWithoutIdle_Owned_LeadsInput>;
  self_sourced?: InputMaybe<Scalars['Boolean']['input']>;
  sequence?: InputMaybe<SequenceCreateNestedOneWithoutLeadsInput>;
  sequence_step?: InputMaybe<SequenceStepCreateNestedOneWithoutLeadsInput>;
  SequenceActionLog?: InputMaybe<StepActionLogCreateNestedManyWithoutLeadInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutLeadInput>;
  sequences_completed_ids?: InputMaybe<LeadCreatesequences_Completed_IdsInput>;
  sequences_joined_ids?: InputMaybe<LeadCreatesequences_Joined_IdsInput>;
  sf_account_id?: InputMaybe<Scalars['String']['input']>;
  sf_contact_id?: InputMaybe<Scalars['String']['input']>;
  sf_lead_id?: InputMaybe<Scalars['String']['input']>;
  sibling_lead_histories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutSibling_LeadsInput>;
  sms_sub_status?: InputMaybe<OptInStatus>;
  stat_items?: InputMaybe<StatItemAggregationCreateNestedManyWithoutLeadInput>;
  state?: InputMaybe<Scalars['String']['input']>;
  StatLeadAggregation?: InputMaybe<StatLeadAggregationCreateNestedOneWithoutLeadInput>;
  status?: InputMaybe<Scalars['String']['input']>;
  sub_industry?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type LeadCreateWithoutSecondary_RepsInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  address_2?: InputMaybe<Scalars['String']['input']>;
  alternate_contacts?: InputMaybe<ContactCreateNestedManyWithoutLeadInput>;
  assignment_locked?: InputMaybe<Scalars['Boolean']['input']>;
  associate_children?: InputMaybe<LeadCreateNestedManyWithoutAssociate_Parent_LeadInput>;
  associate_group_lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutAssociate_Group_LeadsInput>;
  associate_lead_histories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutAssociate_LeadInput>;
  associate_parent_lead?: InputMaybe<LeadCreateNestedOneWithoutAssociate_ChildrenInput>;
  associated_parent_intents?: InputMaybe<LeadIntentCreateNestedManyWithoutAssociate_ParentInput>;
  associates_search_content?: InputMaybe<Scalars['String']['input']>;
  business_name?: InputMaybe<Scalars['String']['input']>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptCreateNestedManyWithoutLeadInput>;
  callMeNows?: InputMaybe<CallMeNowCreateNestedManyWithoutLeadInput>;
  channel?: InputMaybe<Channel>;
  child_lead_activites?: InputMaybe<LeadActivityCreateNestedManyWithoutAssociate_ChildInput>;
  city?: InputMaybe<Scalars['String']['input']>;
  claim_date?: InputMaybe<Scalars['DateTime']['input']>;
  conference_number?: InputMaybe<Scalars['String']['input']>;
  contact_requests?: InputMaybe<ContactRequestCreateNestedManyWithoutLeadInput>;
  content?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  current_close_date?: InputMaybe<Scalars['DateTime']['input']>;
  current_contact_date?: InputMaybe<Scalars['DateTime']['input']>;
  current_sale_cycle?: InputMaybe<SaleCycleCreateNestedOneWithoutLead_If_CurrentInput>;
  custom_field_json?: InputMaybe<Scalars['Json']['input']>;
  custom_fields?: InputMaybe<CustomFieldCreateNestedManyWithoutLeadInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemCreateNestedManyWithoutLeadInput>;
  delay_action_status?: InputMaybe<Scalars['Boolean']['input']>;
  email_sub_status?: InputMaybe<OptInStatus>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordCreateNestedManyWithoutLeadInput>;
  EmailThread?: InputMaybe<EmailThreadCreateNestedManyWithoutLeadInput>;
  favorited_by_users?: InputMaybe<UserCreateNestedManyWithoutFavorite_LeadsInput>;
  first_demo_schedule_date?: InputMaybe<Scalars['DateTime']['input']>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  hubspot_company_id?: InputMaybe<Scalars['String']['input']>;
  hubspot_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  implied_next_action?: InputMaybe<Hingepoint>;
  in_algo_queue?: InputMaybe<Scalars['Boolean']['input']>;
  in_custom_sequence?: InputMaybe<Scalars['Int']['input']>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventCreateNestedManyWithoutLeadInput>;
  industry?: InputMaybe<Scalars['String']['input']>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogCreateNestedManyWithoutLeadInput>;
  last_call_result?: InputMaybe<Scalars['String']['input']>;
  last_call_result_rep?: InputMaybe<UserCreateNestedOneWithoutLeads_Last_CalledInput>;
  last_call_result_time?: InputMaybe<Scalars['DateTime']['input']>;
  last_lead_assignment_origin?: InputMaybe<Scalars['DateTime']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  last_sent_to_resting_date?: InputMaybe<Scalars['DateTime']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutLeadInput>;
  lead_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutLeadInput>;
  lead_assignment_origin?: InputMaybe<AssignmentOrigin>;
  lead_creation_source?: InputMaybe<Leadcreationsource>;
  lead_custom_row_info?: InputMaybe<LeadCustomObjectRowAssociationCreateNestedManyWithoutLeadInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryCreateNestedOneWithoutLeadInput>;
  lead_import_history_csv?: InputMaybe<LeadImportHistoryCreateNestedOneWithoutUploaded_LeadsInput>;
  lead_intent?: InputMaybe<LeadIntentCreateNestedManyWithoutLeadInput>;
  lead_ownership_status?: InputMaybe<Los>;
  lead_source?: InputMaybe<Scalars['String']['input']>;
  lead_value?: InputMaybe<LeadValueCreateNestedOneWithoutLeadInput>;
  mrr?: InputMaybe<Scalars['Float']['input']>;
  newIncoming?: InputMaybe<Scalars['Boolean']['input']>;
  next_scheduled_event?: InputMaybe<ScheduleItemCreateNestedOneWithoutLeadInput>;
  notifications?: InputMaybe<NotificationCreateNestedManyWithoutLeadInput>;
  opsiq_whitelist?: InputMaybe<Scalars['Boolean']['input']>;
  organization: OrganizationCreateNestedOneWithoutLeadsInput;
  phase?: InputMaybe<Phase>;
  primary_email?: InputMaybe<Scalars['String']['input']>;
  primary_email_title?: InputMaybe<Scalars['String']['input']>;
  primary_email_type?: InputMaybe<Scalars['String']['input']>;
  primary_lead_histories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutPrimary_LeadInput>;
  primary_phone_number?: InputMaybe<Scalars['String']['input']>;
  primary_phone_number_country_code?: InputMaybe<Scalars['String']['input']>;
  primary_phone_number_title?: InputMaybe<Scalars['String']['input']>;
  primary_phone_number_type?: InputMaybe<Scalars['String']['input']>;
  rep?: InputMaybe<UserCreateNestedOneWithoutOwned_LeadsInput>;
  resting_type?: InputMaybe<Restingtype>;
  routing_history?: InputMaybe<RoutingHistoryCreateNestedManyWithoutLeadInput>;
  routing_rule?: InputMaybe<RuleCreateNestedOneWithoutLeadInput>;
  Sale?: InputMaybe<SaleCreateNestedManyWithoutLeadInput>;
  sale_cycles?: InputMaybe<SaleCycleCreateNestedManyWithoutLeadInput>;
  search_content?: InputMaybe<Scalars['String']['input']>;
  self_sourced?: InputMaybe<Scalars['Boolean']['input']>;
  sequence?: InputMaybe<SequenceCreateNestedOneWithoutLeadsInput>;
  sequence_step?: InputMaybe<SequenceStepCreateNestedOneWithoutLeadsInput>;
  SequenceActionLog?: InputMaybe<StepActionLogCreateNestedManyWithoutLeadInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutLeadInput>;
  sequences_completed_ids?: InputMaybe<LeadCreatesequences_Completed_IdsInput>;
  sequences_joined_ids?: InputMaybe<LeadCreatesequences_Joined_IdsInput>;
  sf_account_id?: InputMaybe<Scalars['String']['input']>;
  sf_contact_id?: InputMaybe<Scalars['String']['input']>;
  sf_lead_id?: InputMaybe<Scalars['String']['input']>;
  sibling_lead_histories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutSibling_LeadsInput>;
  sms_sub_status?: InputMaybe<OptInStatus>;
  stat_items?: InputMaybe<StatItemAggregationCreateNestedManyWithoutLeadInput>;
  state?: InputMaybe<Scalars['String']['input']>;
  StatLeadAggregation?: InputMaybe<StatLeadAggregationCreateNestedOneWithoutLeadInput>;
  status?: InputMaybe<Scalars['String']['input']>;
  sub_industry?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type LeadCreateWithoutSequence_StepInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  address_2?: InputMaybe<Scalars['String']['input']>;
  alternate_contacts?: InputMaybe<ContactCreateNestedManyWithoutLeadInput>;
  assignment_locked?: InputMaybe<Scalars['Boolean']['input']>;
  associate_children?: InputMaybe<LeadCreateNestedManyWithoutAssociate_Parent_LeadInput>;
  associate_group_lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutAssociate_Group_LeadsInput>;
  associate_lead_histories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutAssociate_LeadInput>;
  associate_parent_lead?: InputMaybe<LeadCreateNestedOneWithoutAssociate_ChildrenInput>;
  associated_parent_intents?: InputMaybe<LeadIntentCreateNestedManyWithoutAssociate_ParentInput>;
  associates_search_content?: InputMaybe<Scalars['String']['input']>;
  business_name?: InputMaybe<Scalars['String']['input']>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptCreateNestedManyWithoutLeadInput>;
  callMeNows?: InputMaybe<CallMeNowCreateNestedManyWithoutLeadInput>;
  channel?: InputMaybe<Channel>;
  child_lead_activites?: InputMaybe<LeadActivityCreateNestedManyWithoutAssociate_ChildInput>;
  city?: InputMaybe<Scalars['String']['input']>;
  claim_date?: InputMaybe<Scalars['DateTime']['input']>;
  conference_number?: InputMaybe<Scalars['String']['input']>;
  contact_requests?: InputMaybe<ContactRequestCreateNestedManyWithoutLeadInput>;
  content?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  current_close_date?: InputMaybe<Scalars['DateTime']['input']>;
  current_contact_date?: InputMaybe<Scalars['DateTime']['input']>;
  current_sale_cycle?: InputMaybe<SaleCycleCreateNestedOneWithoutLead_If_CurrentInput>;
  custom_field_json?: InputMaybe<Scalars['Json']['input']>;
  custom_fields?: InputMaybe<CustomFieldCreateNestedManyWithoutLeadInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemCreateNestedManyWithoutLeadInput>;
  delay_action_status?: InputMaybe<Scalars['Boolean']['input']>;
  email_sub_status?: InputMaybe<OptInStatus>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordCreateNestedManyWithoutLeadInput>;
  EmailThread?: InputMaybe<EmailThreadCreateNestedManyWithoutLeadInput>;
  favorited_by_users?: InputMaybe<UserCreateNestedManyWithoutFavorite_LeadsInput>;
  first_demo_schedule_date?: InputMaybe<Scalars['DateTime']['input']>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  hubspot_company_id?: InputMaybe<Scalars['String']['input']>;
  hubspot_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  implied_next_action?: InputMaybe<Hingepoint>;
  in_algo_queue?: InputMaybe<Scalars['Boolean']['input']>;
  in_custom_sequence?: InputMaybe<Scalars['Int']['input']>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventCreateNestedManyWithoutLeadInput>;
  industry?: InputMaybe<Scalars['String']['input']>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogCreateNestedManyWithoutLeadInput>;
  last_call_result?: InputMaybe<Scalars['String']['input']>;
  last_call_result_rep?: InputMaybe<UserCreateNestedOneWithoutLeads_Last_CalledInput>;
  last_call_result_time?: InputMaybe<Scalars['DateTime']['input']>;
  last_lead_assignment_origin?: InputMaybe<Scalars['DateTime']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  last_sent_to_resting_date?: InputMaybe<Scalars['DateTime']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutLeadInput>;
  lead_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutLeadInput>;
  lead_assignment_origin?: InputMaybe<AssignmentOrigin>;
  lead_creation_source?: InputMaybe<Leadcreationsource>;
  lead_custom_row_info?: InputMaybe<LeadCustomObjectRowAssociationCreateNestedManyWithoutLeadInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryCreateNestedOneWithoutLeadInput>;
  lead_import_history_csv?: InputMaybe<LeadImportHistoryCreateNestedOneWithoutUploaded_LeadsInput>;
  lead_intent?: InputMaybe<LeadIntentCreateNestedManyWithoutLeadInput>;
  lead_ownership_status?: InputMaybe<Los>;
  lead_source?: InputMaybe<Scalars['String']['input']>;
  lead_value?: InputMaybe<LeadValueCreateNestedOneWithoutLeadInput>;
  mrr?: InputMaybe<Scalars['Float']['input']>;
  newIncoming?: InputMaybe<Scalars['Boolean']['input']>;
  next_scheduled_event?: InputMaybe<ScheduleItemCreateNestedOneWithoutLeadInput>;
  notifications?: InputMaybe<NotificationCreateNestedManyWithoutLeadInput>;
  opsiq_whitelist?: InputMaybe<Scalars['Boolean']['input']>;
  organization: OrganizationCreateNestedOneWithoutLeadsInput;
  phase?: InputMaybe<Phase>;
  primary_email?: InputMaybe<Scalars['String']['input']>;
  primary_email_title?: InputMaybe<Scalars['String']['input']>;
  primary_email_type?: InputMaybe<Scalars['String']['input']>;
  primary_lead_histories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutPrimary_LeadInput>;
  primary_phone_number?: InputMaybe<Scalars['String']['input']>;
  primary_phone_number_country_code?: InputMaybe<Scalars['String']['input']>;
  primary_phone_number_title?: InputMaybe<Scalars['String']['input']>;
  primary_phone_number_type?: InputMaybe<Scalars['String']['input']>;
  rep?: InputMaybe<UserCreateNestedOneWithoutOwned_LeadsInput>;
  resting_type?: InputMaybe<Restingtype>;
  routing_history?: InputMaybe<RoutingHistoryCreateNestedManyWithoutLeadInput>;
  routing_rule?: InputMaybe<RuleCreateNestedOneWithoutLeadInput>;
  Sale?: InputMaybe<SaleCreateNestedManyWithoutLeadInput>;
  sale_cycles?: InputMaybe<SaleCycleCreateNestedManyWithoutLeadInput>;
  search_content?: InputMaybe<Scalars['String']['input']>;
  secondary_reps?: InputMaybe<UserCreateNestedManyWithoutIdle_Owned_LeadsInput>;
  self_sourced?: InputMaybe<Scalars['Boolean']['input']>;
  sequence?: InputMaybe<SequenceCreateNestedOneWithoutLeadsInput>;
  SequenceActionLog?: InputMaybe<StepActionLogCreateNestedManyWithoutLeadInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutLeadInput>;
  sequences_completed_ids?: InputMaybe<LeadCreatesequences_Completed_IdsInput>;
  sequences_joined_ids?: InputMaybe<LeadCreatesequences_Joined_IdsInput>;
  sf_account_id?: InputMaybe<Scalars['String']['input']>;
  sf_contact_id?: InputMaybe<Scalars['String']['input']>;
  sf_lead_id?: InputMaybe<Scalars['String']['input']>;
  sibling_lead_histories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutSibling_LeadsInput>;
  sms_sub_status?: InputMaybe<OptInStatus>;
  stat_items?: InputMaybe<StatItemAggregationCreateNestedManyWithoutLeadInput>;
  state?: InputMaybe<Scalars['String']['input']>;
  StatLeadAggregation?: InputMaybe<StatLeadAggregationCreateNestedOneWithoutLeadInput>;
  status?: InputMaybe<Scalars['String']['input']>;
  sub_industry?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type LeadCreateWithoutSequenceActionLogInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  address_2?: InputMaybe<Scalars['String']['input']>;
  alternate_contacts?: InputMaybe<ContactCreateNestedManyWithoutLeadInput>;
  assignment_locked?: InputMaybe<Scalars['Boolean']['input']>;
  associate_children?: InputMaybe<LeadCreateNestedManyWithoutAssociate_Parent_LeadInput>;
  associate_group_lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutAssociate_Group_LeadsInput>;
  associate_lead_histories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutAssociate_LeadInput>;
  associate_parent_lead?: InputMaybe<LeadCreateNestedOneWithoutAssociate_ChildrenInput>;
  associated_parent_intents?: InputMaybe<LeadIntentCreateNestedManyWithoutAssociate_ParentInput>;
  associates_search_content?: InputMaybe<Scalars['String']['input']>;
  business_name?: InputMaybe<Scalars['String']['input']>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptCreateNestedManyWithoutLeadInput>;
  callMeNows?: InputMaybe<CallMeNowCreateNestedManyWithoutLeadInput>;
  channel?: InputMaybe<Channel>;
  child_lead_activites?: InputMaybe<LeadActivityCreateNestedManyWithoutAssociate_ChildInput>;
  city?: InputMaybe<Scalars['String']['input']>;
  claim_date?: InputMaybe<Scalars['DateTime']['input']>;
  conference_number?: InputMaybe<Scalars['String']['input']>;
  contact_requests?: InputMaybe<ContactRequestCreateNestedManyWithoutLeadInput>;
  content?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  current_close_date?: InputMaybe<Scalars['DateTime']['input']>;
  current_contact_date?: InputMaybe<Scalars['DateTime']['input']>;
  current_sale_cycle?: InputMaybe<SaleCycleCreateNestedOneWithoutLead_If_CurrentInput>;
  custom_field_json?: InputMaybe<Scalars['Json']['input']>;
  custom_fields?: InputMaybe<CustomFieldCreateNestedManyWithoutLeadInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemCreateNestedManyWithoutLeadInput>;
  delay_action_status?: InputMaybe<Scalars['Boolean']['input']>;
  email_sub_status?: InputMaybe<OptInStatus>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordCreateNestedManyWithoutLeadInput>;
  EmailThread?: InputMaybe<EmailThreadCreateNestedManyWithoutLeadInput>;
  favorited_by_users?: InputMaybe<UserCreateNestedManyWithoutFavorite_LeadsInput>;
  first_demo_schedule_date?: InputMaybe<Scalars['DateTime']['input']>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  hubspot_company_id?: InputMaybe<Scalars['String']['input']>;
  hubspot_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  implied_next_action?: InputMaybe<Hingepoint>;
  in_algo_queue?: InputMaybe<Scalars['Boolean']['input']>;
  in_custom_sequence?: InputMaybe<Scalars['Int']['input']>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventCreateNestedManyWithoutLeadInput>;
  industry?: InputMaybe<Scalars['String']['input']>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogCreateNestedManyWithoutLeadInput>;
  last_call_result?: InputMaybe<Scalars['String']['input']>;
  last_call_result_rep?: InputMaybe<UserCreateNestedOneWithoutLeads_Last_CalledInput>;
  last_call_result_time?: InputMaybe<Scalars['DateTime']['input']>;
  last_lead_assignment_origin?: InputMaybe<Scalars['DateTime']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  last_sent_to_resting_date?: InputMaybe<Scalars['DateTime']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutLeadInput>;
  lead_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutLeadInput>;
  lead_assignment_origin?: InputMaybe<AssignmentOrigin>;
  lead_creation_source?: InputMaybe<Leadcreationsource>;
  lead_custom_row_info?: InputMaybe<LeadCustomObjectRowAssociationCreateNestedManyWithoutLeadInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryCreateNestedOneWithoutLeadInput>;
  lead_import_history_csv?: InputMaybe<LeadImportHistoryCreateNestedOneWithoutUploaded_LeadsInput>;
  lead_intent?: InputMaybe<LeadIntentCreateNestedManyWithoutLeadInput>;
  lead_ownership_status?: InputMaybe<Los>;
  lead_source?: InputMaybe<Scalars['String']['input']>;
  lead_value?: InputMaybe<LeadValueCreateNestedOneWithoutLeadInput>;
  mrr?: InputMaybe<Scalars['Float']['input']>;
  newIncoming?: InputMaybe<Scalars['Boolean']['input']>;
  next_scheduled_event?: InputMaybe<ScheduleItemCreateNestedOneWithoutLeadInput>;
  notifications?: InputMaybe<NotificationCreateNestedManyWithoutLeadInput>;
  opsiq_whitelist?: InputMaybe<Scalars['Boolean']['input']>;
  organization: OrganizationCreateNestedOneWithoutLeadsInput;
  phase?: InputMaybe<Phase>;
  primary_email?: InputMaybe<Scalars['String']['input']>;
  primary_email_title?: InputMaybe<Scalars['String']['input']>;
  primary_email_type?: InputMaybe<Scalars['String']['input']>;
  primary_lead_histories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutPrimary_LeadInput>;
  primary_phone_number?: InputMaybe<Scalars['String']['input']>;
  primary_phone_number_country_code?: InputMaybe<Scalars['String']['input']>;
  primary_phone_number_title?: InputMaybe<Scalars['String']['input']>;
  primary_phone_number_type?: InputMaybe<Scalars['String']['input']>;
  rep?: InputMaybe<UserCreateNestedOneWithoutOwned_LeadsInput>;
  resting_type?: InputMaybe<Restingtype>;
  routing_history?: InputMaybe<RoutingHistoryCreateNestedManyWithoutLeadInput>;
  routing_rule?: InputMaybe<RuleCreateNestedOneWithoutLeadInput>;
  Sale?: InputMaybe<SaleCreateNestedManyWithoutLeadInput>;
  sale_cycles?: InputMaybe<SaleCycleCreateNestedManyWithoutLeadInput>;
  search_content?: InputMaybe<Scalars['String']['input']>;
  secondary_reps?: InputMaybe<UserCreateNestedManyWithoutIdle_Owned_LeadsInput>;
  self_sourced?: InputMaybe<Scalars['Boolean']['input']>;
  sequence?: InputMaybe<SequenceCreateNestedOneWithoutLeadsInput>;
  sequence_step?: InputMaybe<SequenceStepCreateNestedOneWithoutLeadsInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutLeadInput>;
  sequences_completed_ids?: InputMaybe<LeadCreatesequences_Completed_IdsInput>;
  sequences_joined_ids?: InputMaybe<LeadCreatesequences_Joined_IdsInput>;
  sf_account_id?: InputMaybe<Scalars['String']['input']>;
  sf_contact_id?: InputMaybe<Scalars['String']['input']>;
  sf_lead_id?: InputMaybe<Scalars['String']['input']>;
  sibling_lead_histories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutSibling_LeadsInput>;
  sms_sub_status?: InputMaybe<OptInStatus>;
  stat_items?: InputMaybe<StatItemAggregationCreateNestedManyWithoutLeadInput>;
  state?: InputMaybe<Scalars['String']['input']>;
  StatLeadAggregation?: InputMaybe<StatLeadAggregationCreateNestedOneWithoutLeadInput>;
  status?: InputMaybe<Scalars['String']['input']>;
  sub_industry?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type LeadCreateWithoutSequenceEntryExitLeadInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  address_2?: InputMaybe<Scalars['String']['input']>;
  alternate_contacts?: InputMaybe<ContactCreateNestedManyWithoutLeadInput>;
  assignment_locked?: InputMaybe<Scalars['Boolean']['input']>;
  associate_children?: InputMaybe<LeadCreateNestedManyWithoutAssociate_Parent_LeadInput>;
  associate_group_lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutAssociate_Group_LeadsInput>;
  associate_lead_histories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutAssociate_LeadInput>;
  associate_parent_lead?: InputMaybe<LeadCreateNestedOneWithoutAssociate_ChildrenInput>;
  associated_parent_intents?: InputMaybe<LeadIntentCreateNestedManyWithoutAssociate_ParentInput>;
  associates_search_content?: InputMaybe<Scalars['String']['input']>;
  business_name?: InputMaybe<Scalars['String']['input']>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptCreateNestedManyWithoutLeadInput>;
  callMeNows?: InputMaybe<CallMeNowCreateNestedManyWithoutLeadInput>;
  channel?: InputMaybe<Channel>;
  child_lead_activites?: InputMaybe<LeadActivityCreateNestedManyWithoutAssociate_ChildInput>;
  city?: InputMaybe<Scalars['String']['input']>;
  claim_date?: InputMaybe<Scalars['DateTime']['input']>;
  conference_number?: InputMaybe<Scalars['String']['input']>;
  contact_requests?: InputMaybe<ContactRequestCreateNestedManyWithoutLeadInput>;
  content?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  current_close_date?: InputMaybe<Scalars['DateTime']['input']>;
  current_contact_date?: InputMaybe<Scalars['DateTime']['input']>;
  current_sale_cycle?: InputMaybe<SaleCycleCreateNestedOneWithoutLead_If_CurrentInput>;
  custom_field_json?: InputMaybe<Scalars['Json']['input']>;
  custom_fields?: InputMaybe<CustomFieldCreateNestedManyWithoutLeadInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemCreateNestedManyWithoutLeadInput>;
  delay_action_status?: InputMaybe<Scalars['Boolean']['input']>;
  email_sub_status?: InputMaybe<OptInStatus>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordCreateNestedManyWithoutLeadInput>;
  EmailThread?: InputMaybe<EmailThreadCreateNestedManyWithoutLeadInput>;
  favorited_by_users?: InputMaybe<UserCreateNestedManyWithoutFavorite_LeadsInput>;
  first_demo_schedule_date?: InputMaybe<Scalars['DateTime']['input']>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  hubspot_company_id?: InputMaybe<Scalars['String']['input']>;
  hubspot_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  implied_next_action?: InputMaybe<Hingepoint>;
  in_algo_queue?: InputMaybe<Scalars['Boolean']['input']>;
  in_custom_sequence?: InputMaybe<Scalars['Int']['input']>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventCreateNestedManyWithoutLeadInput>;
  industry?: InputMaybe<Scalars['String']['input']>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogCreateNestedManyWithoutLeadInput>;
  last_call_result?: InputMaybe<Scalars['String']['input']>;
  last_call_result_rep?: InputMaybe<UserCreateNestedOneWithoutLeads_Last_CalledInput>;
  last_call_result_time?: InputMaybe<Scalars['DateTime']['input']>;
  last_lead_assignment_origin?: InputMaybe<Scalars['DateTime']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  last_sent_to_resting_date?: InputMaybe<Scalars['DateTime']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutLeadInput>;
  lead_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutLeadInput>;
  lead_assignment_origin?: InputMaybe<AssignmentOrigin>;
  lead_creation_source?: InputMaybe<Leadcreationsource>;
  lead_custom_row_info?: InputMaybe<LeadCustomObjectRowAssociationCreateNestedManyWithoutLeadInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryCreateNestedOneWithoutLeadInput>;
  lead_import_history_csv?: InputMaybe<LeadImportHistoryCreateNestedOneWithoutUploaded_LeadsInput>;
  lead_intent?: InputMaybe<LeadIntentCreateNestedManyWithoutLeadInput>;
  lead_ownership_status?: InputMaybe<Los>;
  lead_source?: InputMaybe<Scalars['String']['input']>;
  lead_value?: InputMaybe<LeadValueCreateNestedOneWithoutLeadInput>;
  mrr?: InputMaybe<Scalars['Float']['input']>;
  newIncoming?: InputMaybe<Scalars['Boolean']['input']>;
  next_scheduled_event?: InputMaybe<ScheduleItemCreateNestedOneWithoutLeadInput>;
  notifications?: InputMaybe<NotificationCreateNestedManyWithoutLeadInput>;
  opsiq_whitelist?: InputMaybe<Scalars['Boolean']['input']>;
  organization: OrganizationCreateNestedOneWithoutLeadsInput;
  phase?: InputMaybe<Phase>;
  primary_email?: InputMaybe<Scalars['String']['input']>;
  primary_email_title?: InputMaybe<Scalars['String']['input']>;
  primary_email_type?: InputMaybe<Scalars['String']['input']>;
  primary_lead_histories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutPrimary_LeadInput>;
  primary_phone_number?: InputMaybe<Scalars['String']['input']>;
  primary_phone_number_country_code?: InputMaybe<Scalars['String']['input']>;
  primary_phone_number_title?: InputMaybe<Scalars['String']['input']>;
  primary_phone_number_type?: InputMaybe<Scalars['String']['input']>;
  rep?: InputMaybe<UserCreateNestedOneWithoutOwned_LeadsInput>;
  resting_type?: InputMaybe<Restingtype>;
  routing_history?: InputMaybe<RoutingHistoryCreateNestedManyWithoutLeadInput>;
  routing_rule?: InputMaybe<RuleCreateNestedOneWithoutLeadInput>;
  Sale?: InputMaybe<SaleCreateNestedManyWithoutLeadInput>;
  sale_cycles?: InputMaybe<SaleCycleCreateNestedManyWithoutLeadInput>;
  search_content?: InputMaybe<Scalars['String']['input']>;
  secondary_reps?: InputMaybe<UserCreateNestedManyWithoutIdle_Owned_LeadsInput>;
  self_sourced?: InputMaybe<Scalars['Boolean']['input']>;
  sequence?: InputMaybe<SequenceCreateNestedOneWithoutLeadsInput>;
  sequence_step?: InputMaybe<SequenceStepCreateNestedOneWithoutLeadsInput>;
  SequenceActionLog?: InputMaybe<StepActionLogCreateNestedManyWithoutLeadInput>;
  sequences_completed_ids?: InputMaybe<LeadCreatesequences_Completed_IdsInput>;
  sequences_joined_ids?: InputMaybe<LeadCreatesequences_Joined_IdsInput>;
  sf_account_id?: InputMaybe<Scalars['String']['input']>;
  sf_contact_id?: InputMaybe<Scalars['String']['input']>;
  sf_lead_id?: InputMaybe<Scalars['String']['input']>;
  sibling_lead_histories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutSibling_LeadsInput>;
  sms_sub_status?: InputMaybe<OptInStatus>;
  stat_items?: InputMaybe<StatItemAggregationCreateNestedManyWithoutLeadInput>;
  state?: InputMaybe<Scalars['String']['input']>;
  StatLeadAggregation?: InputMaybe<StatLeadAggregationCreateNestedOneWithoutLeadInput>;
  status?: InputMaybe<Scalars['String']['input']>;
  sub_industry?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type LeadCreateWithoutSequenceInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  address_2?: InputMaybe<Scalars['String']['input']>;
  alternate_contacts?: InputMaybe<ContactCreateNestedManyWithoutLeadInput>;
  assignment_locked?: InputMaybe<Scalars['Boolean']['input']>;
  associate_children?: InputMaybe<LeadCreateNestedManyWithoutAssociate_Parent_LeadInput>;
  associate_group_lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutAssociate_Group_LeadsInput>;
  associate_lead_histories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutAssociate_LeadInput>;
  associate_parent_lead?: InputMaybe<LeadCreateNestedOneWithoutAssociate_ChildrenInput>;
  associated_parent_intents?: InputMaybe<LeadIntentCreateNestedManyWithoutAssociate_ParentInput>;
  associates_search_content?: InputMaybe<Scalars['String']['input']>;
  business_name?: InputMaybe<Scalars['String']['input']>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptCreateNestedManyWithoutLeadInput>;
  callMeNows?: InputMaybe<CallMeNowCreateNestedManyWithoutLeadInput>;
  channel?: InputMaybe<Channel>;
  child_lead_activites?: InputMaybe<LeadActivityCreateNestedManyWithoutAssociate_ChildInput>;
  city?: InputMaybe<Scalars['String']['input']>;
  claim_date?: InputMaybe<Scalars['DateTime']['input']>;
  conference_number?: InputMaybe<Scalars['String']['input']>;
  contact_requests?: InputMaybe<ContactRequestCreateNestedManyWithoutLeadInput>;
  content?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  current_close_date?: InputMaybe<Scalars['DateTime']['input']>;
  current_contact_date?: InputMaybe<Scalars['DateTime']['input']>;
  current_sale_cycle?: InputMaybe<SaleCycleCreateNestedOneWithoutLead_If_CurrentInput>;
  custom_field_json?: InputMaybe<Scalars['Json']['input']>;
  custom_fields?: InputMaybe<CustomFieldCreateNestedManyWithoutLeadInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemCreateNestedManyWithoutLeadInput>;
  delay_action_status?: InputMaybe<Scalars['Boolean']['input']>;
  email_sub_status?: InputMaybe<OptInStatus>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordCreateNestedManyWithoutLeadInput>;
  EmailThread?: InputMaybe<EmailThreadCreateNestedManyWithoutLeadInput>;
  favorited_by_users?: InputMaybe<UserCreateNestedManyWithoutFavorite_LeadsInput>;
  first_demo_schedule_date?: InputMaybe<Scalars['DateTime']['input']>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  hubspot_company_id?: InputMaybe<Scalars['String']['input']>;
  hubspot_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  implied_next_action?: InputMaybe<Hingepoint>;
  in_algo_queue?: InputMaybe<Scalars['Boolean']['input']>;
  in_custom_sequence?: InputMaybe<Scalars['Int']['input']>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventCreateNestedManyWithoutLeadInput>;
  industry?: InputMaybe<Scalars['String']['input']>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogCreateNestedManyWithoutLeadInput>;
  last_call_result?: InputMaybe<Scalars['String']['input']>;
  last_call_result_rep?: InputMaybe<UserCreateNestedOneWithoutLeads_Last_CalledInput>;
  last_call_result_time?: InputMaybe<Scalars['DateTime']['input']>;
  last_lead_assignment_origin?: InputMaybe<Scalars['DateTime']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  last_sent_to_resting_date?: InputMaybe<Scalars['DateTime']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutLeadInput>;
  lead_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutLeadInput>;
  lead_assignment_origin?: InputMaybe<AssignmentOrigin>;
  lead_creation_source?: InputMaybe<Leadcreationsource>;
  lead_custom_row_info?: InputMaybe<LeadCustomObjectRowAssociationCreateNestedManyWithoutLeadInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryCreateNestedOneWithoutLeadInput>;
  lead_import_history_csv?: InputMaybe<LeadImportHistoryCreateNestedOneWithoutUploaded_LeadsInput>;
  lead_intent?: InputMaybe<LeadIntentCreateNestedManyWithoutLeadInput>;
  lead_ownership_status?: InputMaybe<Los>;
  lead_source?: InputMaybe<Scalars['String']['input']>;
  lead_value?: InputMaybe<LeadValueCreateNestedOneWithoutLeadInput>;
  mrr?: InputMaybe<Scalars['Float']['input']>;
  newIncoming?: InputMaybe<Scalars['Boolean']['input']>;
  next_scheduled_event?: InputMaybe<ScheduleItemCreateNestedOneWithoutLeadInput>;
  notifications?: InputMaybe<NotificationCreateNestedManyWithoutLeadInput>;
  opsiq_whitelist?: InputMaybe<Scalars['Boolean']['input']>;
  organization: OrganizationCreateNestedOneWithoutLeadsInput;
  phase?: InputMaybe<Phase>;
  primary_email?: InputMaybe<Scalars['String']['input']>;
  primary_email_title?: InputMaybe<Scalars['String']['input']>;
  primary_email_type?: InputMaybe<Scalars['String']['input']>;
  primary_lead_histories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutPrimary_LeadInput>;
  primary_phone_number?: InputMaybe<Scalars['String']['input']>;
  primary_phone_number_country_code?: InputMaybe<Scalars['String']['input']>;
  primary_phone_number_title?: InputMaybe<Scalars['String']['input']>;
  primary_phone_number_type?: InputMaybe<Scalars['String']['input']>;
  rep?: InputMaybe<UserCreateNestedOneWithoutOwned_LeadsInput>;
  resting_type?: InputMaybe<Restingtype>;
  routing_history?: InputMaybe<RoutingHistoryCreateNestedManyWithoutLeadInput>;
  routing_rule?: InputMaybe<RuleCreateNestedOneWithoutLeadInput>;
  Sale?: InputMaybe<SaleCreateNestedManyWithoutLeadInput>;
  sale_cycles?: InputMaybe<SaleCycleCreateNestedManyWithoutLeadInput>;
  search_content?: InputMaybe<Scalars['String']['input']>;
  secondary_reps?: InputMaybe<UserCreateNestedManyWithoutIdle_Owned_LeadsInput>;
  self_sourced?: InputMaybe<Scalars['Boolean']['input']>;
  sequence_step?: InputMaybe<SequenceStepCreateNestedOneWithoutLeadsInput>;
  SequenceActionLog?: InputMaybe<StepActionLogCreateNestedManyWithoutLeadInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutLeadInput>;
  sequences_completed_ids?: InputMaybe<LeadCreatesequences_Completed_IdsInput>;
  sequences_joined_ids?: InputMaybe<LeadCreatesequences_Joined_IdsInput>;
  sf_account_id?: InputMaybe<Scalars['String']['input']>;
  sf_contact_id?: InputMaybe<Scalars['String']['input']>;
  sf_lead_id?: InputMaybe<Scalars['String']['input']>;
  sibling_lead_histories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutSibling_LeadsInput>;
  sms_sub_status?: InputMaybe<OptInStatus>;
  stat_items?: InputMaybe<StatItemAggregationCreateNestedManyWithoutLeadInput>;
  state?: InputMaybe<Scalars['String']['input']>;
  StatLeadAggregation?: InputMaybe<StatLeadAggregationCreateNestedOneWithoutLeadInput>;
  status?: InputMaybe<Scalars['String']['input']>;
  sub_industry?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type LeadCreateWithoutSibling_Lead_HistoriesInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  address_2?: InputMaybe<Scalars['String']['input']>;
  alternate_contacts?: InputMaybe<ContactCreateNestedManyWithoutLeadInput>;
  assignment_locked?: InputMaybe<Scalars['Boolean']['input']>;
  associate_children?: InputMaybe<LeadCreateNestedManyWithoutAssociate_Parent_LeadInput>;
  associate_group_lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutAssociate_Group_LeadsInput>;
  associate_lead_histories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutAssociate_LeadInput>;
  associate_parent_lead?: InputMaybe<LeadCreateNestedOneWithoutAssociate_ChildrenInput>;
  associated_parent_intents?: InputMaybe<LeadIntentCreateNestedManyWithoutAssociate_ParentInput>;
  associates_search_content?: InputMaybe<Scalars['String']['input']>;
  business_name?: InputMaybe<Scalars['String']['input']>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptCreateNestedManyWithoutLeadInput>;
  callMeNows?: InputMaybe<CallMeNowCreateNestedManyWithoutLeadInput>;
  channel?: InputMaybe<Channel>;
  child_lead_activites?: InputMaybe<LeadActivityCreateNestedManyWithoutAssociate_ChildInput>;
  city?: InputMaybe<Scalars['String']['input']>;
  claim_date?: InputMaybe<Scalars['DateTime']['input']>;
  conference_number?: InputMaybe<Scalars['String']['input']>;
  contact_requests?: InputMaybe<ContactRequestCreateNestedManyWithoutLeadInput>;
  content?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  current_close_date?: InputMaybe<Scalars['DateTime']['input']>;
  current_contact_date?: InputMaybe<Scalars['DateTime']['input']>;
  current_sale_cycle?: InputMaybe<SaleCycleCreateNestedOneWithoutLead_If_CurrentInput>;
  custom_field_json?: InputMaybe<Scalars['Json']['input']>;
  custom_fields?: InputMaybe<CustomFieldCreateNestedManyWithoutLeadInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemCreateNestedManyWithoutLeadInput>;
  delay_action_status?: InputMaybe<Scalars['Boolean']['input']>;
  email_sub_status?: InputMaybe<OptInStatus>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordCreateNestedManyWithoutLeadInput>;
  EmailThread?: InputMaybe<EmailThreadCreateNestedManyWithoutLeadInput>;
  favorited_by_users?: InputMaybe<UserCreateNestedManyWithoutFavorite_LeadsInput>;
  first_demo_schedule_date?: InputMaybe<Scalars['DateTime']['input']>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  hubspot_company_id?: InputMaybe<Scalars['String']['input']>;
  hubspot_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  implied_next_action?: InputMaybe<Hingepoint>;
  in_algo_queue?: InputMaybe<Scalars['Boolean']['input']>;
  in_custom_sequence?: InputMaybe<Scalars['Int']['input']>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventCreateNestedManyWithoutLeadInput>;
  industry?: InputMaybe<Scalars['String']['input']>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogCreateNestedManyWithoutLeadInput>;
  last_call_result?: InputMaybe<Scalars['String']['input']>;
  last_call_result_rep?: InputMaybe<UserCreateNestedOneWithoutLeads_Last_CalledInput>;
  last_call_result_time?: InputMaybe<Scalars['DateTime']['input']>;
  last_lead_assignment_origin?: InputMaybe<Scalars['DateTime']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  last_sent_to_resting_date?: InputMaybe<Scalars['DateTime']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutLeadInput>;
  lead_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutLeadInput>;
  lead_assignment_origin?: InputMaybe<AssignmentOrigin>;
  lead_creation_source?: InputMaybe<Leadcreationsource>;
  lead_custom_row_info?: InputMaybe<LeadCustomObjectRowAssociationCreateNestedManyWithoutLeadInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryCreateNestedOneWithoutLeadInput>;
  lead_import_history_csv?: InputMaybe<LeadImportHistoryCreateNestedOneWithoutUploaded_LeadsInput>;
  lead_intent?: InputMaybe<LeadIntentCreateNestedManyWithoutLeadInput>;
  lead_ownership_status?: InputMaybe<Los>;
  lead_source?: InputMaybe<Scalars['String']['input']>;
  lead_value?: InputMaybe<LeadValueCreateNestedOneWithoutLeadInput>;
  mrr?: InputMaybe<Scalars['Float']['input']>;
  newIncoming?: InputMaybe<Scalars['Boolean']['input']>;
  next_scheduled_event?: InputMaybe<ScheduleItemCreateNestedOneWithoutLeadInput>;
  notifications?: InputMaybe<NotificationCreateNestedManyWithoutLeadInput>;
  opsiq_whitelist?: InputMaybe<Scalars['Boolean']['input']>;
  organization: OrganizationCreateNestedOneWithoutLeadsInput;
  phase?: InputMaybe<Phase>;
  primary_email?: InputMaybe<Scalars['String']['input']>;
  primary_email_title?: InputMaybe<Scalars['String']['input']>;
  primary_email_type?: InputMaybe<Scalars['String']['input']>;
  primary_lead_histories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutPrimary_LeadInput>;
  primary_phone_number?: InputMaybe<Scalars['String']['input']>;
  primary_phone_number_country_code?: InputMaybe<Scalars['String']['input']>;
  primary_phone_number_title?: InputMaybe<Scalars['String']['input']>;
  primary_phone_number_type?: InputMaybe<Scalars['String']['input']>;
  rep?: InputMaybe<UserCreateNestedOneWithoutOwned_LeadsInput>;
  resting_type?: InputMaybe<Restingtype>;
  routing_history?: InputMaybe<RoutingHistoryCreateNestedManyWithoutLeadInput>;
  routing_rule?: InputMaybe<RuleCreateNestedOneWithoutLeadInput>;
  Sale?: InputMaybe<SaleCreateNestedManyWithoutLeadInput>;
  sale_cycles?: InputMaybe<SaleCycleCreateNestedManyWithoutLeadInput>;
  search_content?: InputMaybe<Scalars['String']['input']>;
  secondary_reps?: InputMaybe<UserCreateNestedManyWithoutIdle_Owned_LeadsInput>;
  self_sourced?: InputMaybe<Scalars['Boolean']['input']>;
  sequence?: InputMaybe<SequenceCreateNestedOneWithoutLeadsInput>;
  sequence_step?: InputMaybe<SequenceStepCreateNestedOneWithoutLeadsInput>;
  SequenceActionLog?: InputMaybe<StepActionLogCreateNestedManyWithoutLeadInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutLeadInput>;
  sequences_completed_ids?: InputMaybe<LeadCreatesequences_Completed_IdsInput>;
  sequences_joined_ids?: InputMaybe<LeadCreatesequences_Joined_IdsInput>;
  sf_account_id?: InputMaybe<Scalars['String']['input']>;
  sf_contact_id?: InputMaybe<Scalars['String']['input']>;
  sf_lead_id?: InputMaybe<Scalars['String']['input']>;
  sms_sub_status?: InputMaybe<OptInStatus>;
  stat_items?: InputMaybe<StatItemAggregationCreateNestedManyWithoutLeadInput>;
  state?: InputMaybe<Scalars['String']['input']>;
  StatLeadAggregation?: InputMaybe<StatLeadAggregationCreateNestedOneWithoutLeadInput>;
  status?: InputMaybe<Scalars['String']['input']>;
  sub_industry?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type LeadCreateWithoutStat_ItemsInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  address_2?: InputMaybe<Scalars['String']['input']>;
  alternate_contacts?: InputMaybe<ContactCreateNestedManyWithoutLeadInput>;
  assignment_locked?: InputMaybe<Scalars['Boolean']['input']>;
  associate_children?: InputMaybe<LeadCreateNestedManyWithoutAssociate_Parent_LeadInput>;
  associate_group_lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutAssociate_Group_LeadsInput>;
  associate_lead_histories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutAssociate_LeadInput>;
  associate_parent_lead?: InputMaybe<LeadCreateNestedOneWithoutAssociate_ChildrenInput>;
  associated_parent_intents?: InputMaybe<LeadIntentCreateNestedManyWithoutAssociate_ParentInput>;
  associates_search_content?: InputMaybe<Scalars['String']['input']>;
  business_name?: InputMaybe<Scalars['String']['input']>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptCreateNestedManyWithoutLeadInput>;
  callMeNows?: InputMaybe<CallMeNowCreateNestedManyWithoutLeadInput>;
  channel?: InputMaybe<Channel>;
  child_lead_activites?: InputMaybe<LeadActivityCreateNestedManyWithoutAssociate_ChildInput>;
  city?: InputMaybe<Scalars['String']['input']>;
  claim_date?: InputMaybe<Scalars['DateTime']['input']>;
  conference_number?: InputMaybe<Scalars['String']['input']>;
  contact_requests?: InputMaybe<ContactRequestCreateNestedManyWithoutLeadInput>;
  content?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  current_close_date?: InputMaybe<Scalars['DateTime']['input']>;
  current_contact_date?: InputMaybe<Scalars['DateTime']['input']>;
  current_sale_cycle?: InputMaybe<SaleCycleCreateNestedOneWithoutLead_If_CurrentInput>;
  custom_field_json?: InputMaybe<Scalars['Json']['input']>;
  custom_fields?: InputMaybe<CustomFieldCreateNestedManyWithoutLeadInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemCreateNestedManyWithoutLeadInput>;
  delay_action_status?: InputMaybe<Scalars['Boolean']['input']>;
  email_sub_status?: InputMaybe<OptInStatus>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordCreateNestedManyWithoutLeadInput>;
  EmailThread?: InputMaybe<EmailThreadCreateNestedManyWithoutLeadInput>;
  favorited_by_users?: InputMaybe<UserCreateNestedManyWithoutFavorite_LeadsInput>;
  first_demo_schedule_date?: InputMaybe<Scalars['DateTime']['input']>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  hubspot_company_id?: InputMaybe<Scalars['String']['input']>;
  hubspot_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  implied_next_action?: InputMaybe<Hingepoint>;
  in_algo_queue?: InputMaybe<Scalars['Boolean']['input']>;
  in_custom_sequence?: InputMaybe<Scalars['Int']['input']>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventCreateNestedManyWithoutLeadInput>;
  industry?: InputMaybe<Scalars['String']['input']>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogCreateNestedManyWithoutLeadInput>;
  last_call_result?: InputMaybe<Scalars['String']['input']>;
  last_call_result_rep?: InputMaybe<UserCreateNestedOneWithoutLeads_Last_CalledInput>;
  last_call_result_time?: InputMaybe<Scalars['DateTime']['input']>;
  last_lead_assignment_origin?: InputMaybe<Scalars['DateTime']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  last_sent_to_resting_date?: InputMaybe<Scalars['DateTime']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutLeadInput>;
  lead_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutLeadInput>;
  lead_assignment_origin?: InputMaybe<AssignmentOrigin>;
  lead_creation_source?: InputMaybe<Leadcreationsource>;
  lead_custom_row_info?: InputMaybe<LeadCustomObjectRowAssociationCreateNestedManyWithoutLeadInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryCreateNestedOneWithoutLeadInput>;
  lead_import_history_csv?: InputMaybe<LeadImportHistoryCreateNestedOneWithoutUploaded_LeadsInput>;
  lead_intent?: InputMaybe<LeadIntentCreateNestedManyWithoutLeadInput>;
  lead_ownership_status?: InputMaybe<Los>;
  lead_source?: InputMaybe<Scalars['String']['input']>;
  lead_value?: InputMaybe<LeadValueCreateNestedOneWithoutLeadInput>;
  mrr?: InputMaybe<Scalars['Float']['input']>;
  newIncoming?: InputMaybe<Scalars['Boolean']['input']>;
  next_scheduled_event?: InputMaybe<ScheduleItemCreateNestedOneWithoutLeadInput>;
  notifications?: InputMaybe<NotificationCreateNestedManyWithoutLeadInput>;
  opsiq_whitelist?: InputMaybe<Scalars['Boolean']['input']>;
  organization: OrganizationCreateNestedOneWithoutLeadsInput;
  phase?: InputMaybe<Phase>;
  primary_email?: InputMaybe<Scalars['String']['input']>;
  primary_email_title?: InputMaybe<Scalars['String']['input']>;
  primary_email_type?: InputMaybe<Scalars['String']['input']>;
  primary_lead_histories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutPrimary_LeadInput>;
  primary_phone_number?: InputMaybe<Scalars['String']['input']>;
  primary_phone_number_country_code?: InputMaybe<Scalars['String']['input']>;
  primary_phone_number_title?: InputMaybe<Scalars['String']['input']>;
  primary_phone_number_type?: InputMaybe<Scalars['String']['input']>;
  rep?: InputMaybe<UserCreateNestedOneWithoutOwned_LeadsInput>;
  resting_type?: InputMaybe<Restingtype>;
  routing_history?: InputMaybe<RoutingHistoryCreateNestedManyWithoutLeadInput>;
  routing_rule?: InputMaybe<RuleCreateNestedOneWithoutLeadInput>;
  Sale?: InputMaybe<SaleCreateNestedManyWithoutLeadInput>;
  sale_cycles?: InputMaybe<SaleCycleCreateNestedManyWithoutLeadInput>;
  search_content?: InputMaybe<Scalars['String']['input']>;
  secondary_reps?: InputMaybe<UserCreateNestedManyWithoutIdle_Owned_LeadsInput>;
  self_sourced?: InputMaybe<Scalars['Boolean']['input']>;
  sequence?: InputMaybe<SequenceCreateNestedOneWithoutLeadsInput>;
  sequence_step?: InputMaybe<SequenceStepCreateNestedOneWithoutLeadsInput>;
  SequenceActionLog?: InputMaybe<StepActionLogCreateNestedManyWithoutLeadInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutLeadInput>;
  sequences_completed_ids?: InputMaybe<LeadCreatesequences_Completed_IdsInput>;
  sequences_joined_ids?: InputMaybe<LeadCreatesequences_Joined_IdsInput>;
  sf_account_id?: InputMaybe<Scalars['String']['input']>;
  sf_contact_id?: InputMaybe<Scalars['String']['input']>;
  sf_lead_id?: InputMaybe<Scalars['String']['input']>;
  sibling_lead_histories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutSibling_LeadsInput>;
  sms_sub_status?: InputMaybe<OptInStatus>;
  state?: InputMaybe<Scalars['String']['input']>;
  StatLeadAggregation?: InputMaybe<StatLeadAggregationCreateNestedOneWithoutLeadInput>;
  status?: InputMaybe<Scalars['String']['input']>;
  sub_industry?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type LeadCreateWithoutStatLeadAggregationInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  address_2?: InputMaybe<Scalars['String']['input']>;
  alternate_contacts?: InputMaybe<ContactCreateNestedManyWithoutLeadInput>;
  assignment_locked?: InputMaybe<Scalars['Boolean']['input']>;
  associate_children?: InputMaybe<LeadCreateNestedManyWithoutAssociate_Parent_LeadInput>;
  associate_group_lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutAssociate_Group_LeadsInput>;
  associate_lead_histories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutAssociate_LeadInput>;
  associate_parent_lead?: InputMaybe<LeadCreateNestedOneWithoutAssociate_ChildrenInput>;
  associated_parent_intents?: InputMaybe<LeadIntentCreateNestedManyWithoutAssociate_ParentInput>;
  associates_search_content?: InputMaybe<Scalars['String']['input']>;
  business_name?: InputMaybe<Scalars['String']['input']>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptCreateNestedManyWithoutLeadInput>;
  callMeNows?: InputMaybe<CallMeNowCreateNestedManyWithoutLeadInput>;
  channel?: InputMaybe<Channel>;
  child_lead_activites?: InputMaybe<LeadActivityCreateNestedManyWithoutAssociate_ChildInput>;
  city?: InputMaybe<Scalars['String']['input']>;
  claim_date?: InputMaybe<Scalars['DateTime']['input']>;
  conference_number?: InputMaybe<Scalars['String']['input']>;
  contact_requests?: InputMaybe<ContactRequestCreateNestedManyWithoutLeadInput>;
  content?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  current_close_date?: InputMaybe<Scalars['DateTime']['input']>;
  current_contact_date?: InputMaybe<Scalars['DateTime']['input']>;
  current_sale_cycle?: InputMaybe<SaleCycleCreateNestedOneWithoutLead_If_CurrentInput>;
  custom_field_json?: InputMaybe<Scalars['Json']['input']>;
  custom_fields?: InputMaybe<CustomFieldCreateNestedManyWithoutLeadInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemCreateNestedManyWithoutLeadInput>;
  delay_action_status?: InputMaybe<Scalars['Boolean']['input']>;
  email_sub_status?: InputMaybe<OptInStatus>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordCreateNestedManyWithoutLeadInput>;
  EmailThread?: InputMaybe<EmailThreadCreateNestedManyWithoutLeadInput>;
  favorited_by_users?: InputMaybe<UserCreateNestedManyWithoutFavorite_LeadsInput>;
  first_demo_schedule_date?: InputMaybe<Scalars['DateTime']['input']>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  hubspot_company_id?: InputMaybe<Scalars['String']['input']>;
  hubspot_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  implied_next_action?: InputMaybe<Hingepoint>;
  in_algo_queue?: InputMaybe<Scalars['Boolean']['input']>;
  in_custom_sequence?: InputMaybe<Scalars['Int']['input']>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventCreateNestedManyWithoutLeadInput>;
  industry?: InputMaybe<Scalars['String']['input']>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogCreateNestedManyWithoutLeadInput>;
  last_call_result?: InputMaybe<Scalars['String']['input']>;
  last_call_result_rep?: InputMaybe<UserCreateNestedOneWithoutLeads_Last_CalledInput>;
  last_call_result_time?: InputMaybe<Scalars['DateTime']['input']>;
  last_lead_assignment_origin?: InputMaybe<Scalars['DateTime']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  last_sent_to_resting_date?: InputMaybe<Scalars['DateTime']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutLeadInput>;
  lead_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutLeadInput>;
  lead_assignment_origin?: InputMaybe<AssignmentOrigin>;
  lead_creation_source?: InputMaybe<Leadcreationsource>;
  lead_custom_row_info?: InputMaybe<LeadCustomObjectRowAssociationCreateNestedManyWithoutLeadInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryCreateNestedOneWithoutLeadInput>;
  lead_import_history_csv?: InputMaybe<LeadImportHistoryCreateNestedOneWithoutUploaded_LeadsInput>;
  lead_intent?: InputMaybe<LeadIntentCreateNestedManyWithoutLeadInput>;
  lead_ownership_status?: InputMaybe<Los>;
  lead_source?: InputMaybe<Scalars['String']['input']>;
  lead_value?: InputMaybe<LeadValueCreateNestedOneWithoutLeadInput>;
  mrr?: InputMaybe<Scalars['Float']['input']>;
  newIncoming?: InputMaybe<Scalars['Boolean']['input']>;
  next_scheduled_event?: InputMaybe<ScheduleItemCreateNestedOneWithoutLeadInput>;
  notifications?: InputMaybe<NotificationCreateNestedManyWithoutLeadInput>;
  opsiq_whitelist?: InputMaybe<Scalars['Boolean']['input']>;
  organization: OrganizationCreateNestedOneWithoutLeadsInput;
  phase?: InputMaybe<Phase>;
  primary_email?: InputMaybe<Scalars['String']['input']>;
  primary_email_title?: InputMaybe<Scalars['String']['input']>;
  primary_email_type?: InputMaybe<Scalars['String']['input']>;
  primary_lead_histories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutPrimary_LeadInput>;
  primary_phone_number?: InputMaybe<Scalars['String']['input']>;
  primary_phone_number_country_code?: InputMaybe<Scalars['String']['input']>;
  primary_phone_number_title?: InputMaybe<Scalars['String']['input']>;
  primary_phone_number_type?: InputMaybe<Scalars['String']['input']>;
  rep?: InputMaybe<UserCreateNestedOneWithoutOwned_LeadsInput>;
  resting_type?: InputMaybe<Restingtype>;
  routing_history?: InputMaybe<RoutingHistoryCreateNestedManyWithoutLeadInput>;
  routing_rule?: InputMaybe<RuleCreateNestedOneWithoutLeadInput>;
  Sale?: InputMaybe<SaleCreateNestedManyWithoutLeadInput>;
  sale_cycles?: InputMaybe<SaleCycleCreateNestedManyWithoutLeadInput>;
  search_content?: InputMaybe<Scalars['String']['input']>;
  secondary_reps?: InputMaybe<UserCreateNestedManyWithoutIdle_Owned_LeadsInput>;
  self_sourced?: InputMaybe<Scalars['Boolean']['input']>;
  sequence?: InputMaybe<SequenceCreateNestedOneWithoutLeadsInput>;
  sequence_step?: InputMaybe<SequenceStepCreateNestedOneWithoutLeadsInput>;
  SequenceActionLog?: InputMaybe<StepActionLogCreateNestedManyWithoutLeadInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutLeadInput>;
  sequences_completed_ids?: InputMaybe<LeadCreatesequences_Completed_IdsInput>;
  sequences_joined_ids?: InputMaybe<LeadCreatesequences_Joined_IdsInput>;
  sf_account_id?: InputMaybe<Scalars['String']['input']>;
  sf_contact_id?: InputMaybe<Scalars['String']['input']>;
  sf_lead_id?: InputMaybe<Scalars['String']['input']>;
  sibling_lead_histories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutSibling_LeadsInput>;
  sms_sub_status?: InputMaybe<OptInStatus>;
  stat_items?: InputMaybe<StatItemAggregationCreateNestedManyWithoutLeadInput>;
  state?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  sub_industry?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export enum Leadcreationsource {
  AsAdmin = 'as_admin',
  Csv = 'CSV',
  Hubspot = 'hubspot',
  Salesforce = 'salesforce',
  SelfSourced = 'self_sourced',
  Widget = 'widget'
}

export enum LeadCreationStatus {
  Both = 'Both',
  Create = 'Create',
  Update = 'Update'
}

/** lead custom field filter */
export type LeadCustomFieldFilter = {
  id: Scalars['String']['input'];
  key: Scalars['String']['input'];
  /** actually CustomFieldType check Schema  */
  type?: InputMaybe<Scalars['String']['input']>;
  value: Array<Scalars['String']['input']>;
};

export enum LeadCustomObjectAssociationSource {
  Hubspot = 'hubspot',
  Opsiq = 'opsiq',
  Salesforce = 'salesforce'
}

export type LeadCustomObjectRowAssociation = {
  __typename?: 'LeadCustomObjectRowAssociation';
  created_at: Scalars['DateTime']['output'];
  custom_object: CustomObject;
  custom_object_id: Scalars['String']['output'];
  id: Scalars['String']['output'];
  lead: Lead;
  lead_id: Scalars['String']['output'];
  row: CustomObjectRow;
  row_id: Scalars['String']['output'];
  updated_at: Scalars['DateTime']['output'];
};

export type LeadCustomObjectRowAssociationCreateManyCustom_ObjectInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  creation_source?: InputMaybe<LeadCustomObjectAssociationSource>;
  id?: InputMaybe<Scalars['String']['input']>;
  lead_id: Scalars['String']['input'];
  organization_id: Scalars['String']['input'];
  row_id: Scalars['String']['input'];
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type LeadCustomObjectRowAssociationCreateManyCustom_ObjectInputEnvelope = {
  data?: InputMaybe<Array<LeadCustomObjectRowAssociationCreateManyCustom_ObjectInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type LeadCustomObjectRowAssociationCreateManyLeadInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  creation_source?: InputMaybe<LeadCustomObjectAssociationSource>;
  custom_object_id: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  organization_id: Scalars['String']['input'];
  row_id: Scalars['String']['input'];
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type LeadCustomObjectRowAssociationCreateManyLeadInputEnvelope = {
  data?: InputMaybe<Array<LeadCustomObjectRowAssociationCreateManyLeadInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type LeadCustomObjectRowAssociationCreateManyOrganizationInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  creation_source?: InputMaybe<LeadCustomObjectAssociationSource>;
  custom_object_id: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  lead_id: Scalars['String']['input'];
  row_id: Scalars['String']['input'];
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type LeadCustomObjectRowAssociationCreateManyOrganizationInputEnvelope = {
  data?: InputMaybe<Array<LeadCustomObjectRowAssociationCreateManyOrganizationInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type LeadCustomObjectRowAssociationCreateManyRowInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  creation_source?: InputMaybe<LeadCustomObjectAssociationSource>;
  custom_object_id: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  lead_id: Scalars['String']['input'];
  organization_id: Scalars['String']['input'];
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type LeadCustomObjectRowAssociationCreateManyRowInputEnvelope = {
  data?: InputMaybe<Array<LeadCustomObjectRowAssociationCreateManyRowInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type LeadCustomObjectRowAssociationCreateNestedManyWithoutCustom_ObjectInput = {
  connect?: InputMaybe<Array<LeadCustomObjectRowAssociationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<LeadCustomObjectRowAssociationCreateOrConnectWithoutCustom_ObjectInput>>;
  create?: InputMaybe<Array<LeadCustomObjectRowAssociationCreateWithoutCustom_ObjectInput>>;
  createMany?: InputMaybe<LeadCustomObjectRowAssociationCreateManyCustom_ObjectInputEnvelope>;
};

export type LeadCustomObjectRowAssociationCreateNestedManyWithoutLeadInput = {
  connect?: InputMaybe<Array<LeadCustomObjectRowAssociationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<LeadCustomObjectRowAssociationCreateOrConnectWithoutLeadInput>>;
  create?: InputMaybe<Array<LeadCustomObjectRowAssociationCreateWithoutLeadInput>>;
  createMany?: InputMaybe<LeadCustomObjectRowAssociationCreateManyLeadInputEnvelope>;
};

export type LeadCustomObjectRowAssociationCreateNestedManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<LeadCustomObjectRowAssociationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<LeadCustomObjectRowAssociationCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<LeadCustomObjectRowAssociationCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<LeadCustomObjectRowAssociationCreateManyOrganizationInputEnvelope>;
};

export type LeadCustomObjectRowAssociationCreateNestedManyWithoutRowInput = {
  connect?: InputMaybe<Array<LeadCustomObjectRowAssociationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<LeadCustomObjectRowAssociationCreateOrConnectWithoutRowInput>>;
  create?: InputMaybe<Array<LeadCustomObjectRowAssociationCreateWithoutRowInput>>;
  createMany?: InputMaybe<LeadCustomObjectRowAssociationCreateManyRowInputEnvelope>;
};

export type LeadCustomObjectRowAssociationCreateOrConnectWithoutCustom_ObjectInput = {
  create: LeadCustomObjectRowAssociationCreateWithoutCustom_ObjectInput;
  where: LeadCustomObjectRowAssociationWhereUniqueInput;
};

export type LeadCustomObjectRowAssociationCreateOrConnectWithoutLeadInput = {
  create: LeadCustomObjectRowAssociationCreateWithoutLeadInput;
  where: LeadCustomObjectRowAssociationWhereUniqueInput;
};

export type LeadCustomObjectRowAssociationCreateOrConnectWithoutOrganizationInput = {
  create: LeadCustomObjectRowAssociationCreateWithoutOrganizationInput;
  where: LeadCustomObjectRowAssociationWhereUniqueInput;
};

export type LeadCustomObjectRowAssociationCreateOrConnectWithoutRowInput = {
  create: LeadCustomObjectRowAssociationCreateWithoutRowInput;
  where: LeadCustomObjectRowAssociationWhereUniqueInput;
};

export type LeadCustomObjectRowAssociationCreateWithoutCustom_ObjectInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  creation_source?: InputMaybe<LeadCustomObjectAssociationSource>;
  id?: InputMaybe<Scalars['String']['input']>;
  lead: LeadCreateNestedOneWithoutLead_Custom_Row_InfoInput;
  organization: OrganizationCreateNestedOneWithoutLeadCustomObjectRowAssociationInput;
  row: CustomObjectRowCreateNestedOneWithoutLead_Custom_Row_InfoInput;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type LeadCustomObjectRowAssociationCreateWithoutLeadInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  creation_source?: InputMaybe<LeadCustomObjectAssociationSource>;
  custom_object: CustomObjectCreateNestedOneWithoutLeadCustomObjectRowAssociationInput;
  id?: InputMaybe<Scalars['String']['input']>;
  organization: OrganizationCreateNestedOneWithoutLeadCustomObjectRowAssociationInput;
  row: CustomObjectRowCreateNestedOneWithoutLead_Custom_Row_InfoInput;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type LeadCustomObjectRowAssociationCreateWithoutOrganizationInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  creation_source?: InputMaybe<LeadCustomObjectAssociationSource>;
  custom_object: CustomObjectCreateNestedOneWithoutLeadCustomObjectRowAssociationInput;
  id?: InputMaybe<Scalars['String']['input']>;
  lead: LeadCreateNestedOneWithoutLead_Custom_Row_InfoInput;
  row: CustomObjectRowCreateNestedOneWithoutLead_Custom_Row_InfoInput;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type LeadCustomObjectRowAssociationCreateWithoutRowInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  creation_source?: InputMaybe<LeadCustomObjectAssociationSource>;
  custom_object: CustomObjectCreateNestedOneWithoutLeadCustomObjectRowAssociationInput;
  id?: InputMaybe<Scalars['String']['input']>;
  lead: LeadCreateNestedOneWithoutLead_Custom_Row_InfoInput;
  organization: OrganizationCreateNestedOneWithoutLeadCustomObjectRowAssociationInput;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type LeadCustomObjectRowAssociationLead_IdRow_IdCompoundUniqueInput = {
  lead_id: Scalars['String']['input'];
  row_id: Scalars['String']['input'];
};

export type LeadCustomObjectRowAssociationListRelationFilter = {
  every?: InputMaybe<LeadCustomObjectRowAssociationWhereInput>;
  none?: InputMaybe<LeadCustomObjectRowAssociationWhereInput>;
  some?: InputMaybe<LeadCustomObjectRowAssociationWhereInput>;
};

export type LeadCustomObjectRowAssociationOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
  count?: InputMaybe<SortOrder>;
};

export type LeadCustomObjectRowAssociationScalarWhereInput = {
  AND?: InputMaybe<Array<LeadCustomObjectRowAssociationScalarWhereInput>>;
  created_at?: InputMaybe<DateTimeFilter>;
  creation_source?: InputMaybe<EnumLeadCustomObjectAssociationSourceFilter>;
  custom_object_id?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  lead_id?: InputMaybe<StringFilter>;
  NOT?: InputMaybe<Array<LeadCustomObjectRowAssociationScalarWhereInput>>;
  OR?: InputMaybe<Array<LeadCustomObjectRowAssociationScalarWhereInput>>;
  organization_id?: InputMaybe<StringFilter>;
  row_id?: InputMaybe<StringFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
};

export type LeadCustomObjectRowAssociationUpdateManyMutationInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  creation_source?: InputMaybe<EnumLeadCustomObjectAssociationSourceFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type LeadCustomObjectRowAssociationUpdateManyWithoutCustom_ObjectInput = {
  connect?: InputMaybe<Array<LeadCustomObjectRowAssociationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<LeadCustomObjectRowAssociationCreateOrConnectWithoutCustom_ObjectInput>>;
  create?: InputMaybe<Array<LeadCustomObjectRowAssociationCreateWithoutCustom_ObjectInput>>;
  createMany?: InputMaybe<LeadCustomObjectRowAssociationCreateManyCustom_ObjectInputEnvelope>;
  delete?: InputMaybe<Array<LeadCustomObjectRowAssociationWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<LeadCustomObjectRowAssociationScalarWhereInput>>;
  disconnect?: InputMaybe<Array<LeadCustomObjectRowAssociationWhereUniqueInput>>;
  set?: InputMaybe<Array<LeadCustomObjectRowAssociationWhereUniqueInput>>;
  update?: InputMaybe<Array<LeadCustomObjectRowAssociationUpdateWithWhereUniqueWithoutCustom_ObjectInput>>;
  updateMany?: InputMaybe<Array<LeadCustomObjectRowAssociationUpdateManyWithWhereWithoutCustom_ObjectInput>>;
  upsert?: InputMaybe<Array<LeadCustomObjectRowAssociationUpsertWithWhereUniqueWithoutCustom_ObjectInput>>;
};

export type LeadCustomObjectRowAssociationUpdateManyWithoutLeadInput = {
  connect?: InputMaybe<Array<LeadCustomObjectRowAssociationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<LeadCustomObjectRowAssociationCreateOrConnectWithoutLeadInput>>;
  create?: InputMaybe<Array<LeadCustomObjectRowAssociationCreateWithoutLeadInput>>;
  createMany?: InputMaybe<LeadCustomObjectRowAssociationCreateManyLeadInputEnvelope>;
  delete?: InputMaybe<Array<LeadCustomObjectRowAssociationWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<LeadCustomObjectRowAssociationScalarWhereInput>>;
  disconnect?: InputMaybe<Array<LeadCustomObjectRowAssociationWhereUniqueInput>>;
  set?: InputMaybe<Array<LeadCustomObjectRowAssociationWhereUniqueInput>>;
  update?: InputMaybe<Array<LeadCustomObjectRowAssociationUpdateWithWhereUniqueWithoutLeadInput>>;
  updateMany?: InputMaybe<Array<LeadCustomObjectRowAssociationUpdateManyWithWhereWithoutLeadInput>>;
  upsert?: InputMaybe<Array<LeadCustomObjectRowAssociationUpsertWithWhereUniqueWithoutLeadInput>>;
};

export type LeadCustomObjectRowAssociationUpdateManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<LeadCustomObjectRowAssociationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<LeadCustomObjectRowAssociationCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<LeadCustomObjectRowAssociationCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<LeadCustomObjectRowAssociationCreateManyOrganizationInputEnvelope>;
  delete?: InputMaybe<Array<LeadCustomObjectRowAssociationWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<LeadCustomObjectRowAssociationScalarWhereInput>>;
  disconnect?: InputMaybe<Array<LeadCustomObjectRowAssociationWhereUniqueInput>>;
  set?: InputMaybe<Array<LeadCustomObjectRowAssociationWhereUniqueInput>>;
  update?: InputMaybe<Array<LeadCustomObjectRowAssociationUpdateWithWhereUniqueWithoutOrganizationInput>>;
  updateMany?: InputMaybe<Array<LeadCustomObjectRowAssociationUpdateManyWithWhereWithoutOrganizationInput>>;
  upsert?: InputMaybe<Array<LeadCustomObjectRowAssociationUpsertWithWhereUniqueWithoutOrganizationInput>>;
};

export type LeadCustomObjectRowAssociationUpdateManyWithoutRowInput = {
  connect?: InputMaybe<Array<LeadCustomObjectRowAssociationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<LeadCustomObjectRowAssociationCreateOrConnectWithoutRowInput>>;
  create?: InputMaybe<Array<LeadCustomObjectRowAssociationCreateWithoutRowInput>>;
  createMany?: InputMaybe<LeadCustomObjectRowAssociationCreateManyRowInputEnvelope>;
  delete?: InputMaybe<Array<LeadCustomObjectRowAssociationWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<LeadCustomObjectRowAssociationScalarWhereInput>>;
  disconnect?: InputMaybe<Array<LeadCustomObjectRowAssociationWhereUniqueInput>>;
  set?: InputMaybe<Array<LeadCustomObjectRowAssociationWhereUniqueInput>>;
  update?: InputMaybe<Array<LeadCustomObjectRowAssociationUpdateWithWhereUniqueWithoutRowInput>>;
  updateMany?: InputMaybe<Array<LeadCustomObjectRowAssociationUpdateManyWithWhereWithoutRowInput>>;
  upsert?: InputMaybe<Array<LeadCustomObjectRowAssociationUpsertWithWhereUniqueWithoutRowInput>>;
};

export type LeadCustomObjectRowAssociationUpdateManyWithWhereWithoutCustom_ObjectInput = {
  data: LeadCustomObjectRowAssociationUpdateManyMutationInput;
  where: LeadCustomObjectRowAssociationScalarWhereInput;
};

export type LeadCustomObjectRowAssociationUpdateManyWithWhereWithoutLeadInput = {
  data: LeadCustomObjectRowAssociationUpdateManyMutationInput;
  where: LeadCustomObjectRowAssociationScalarWhereInput;
};

export type LeadCustomObjectRowAssociationUpdateManyWithWhereWithoutOrganizationInput = {
  data: LeadCustomObjectRowAssociationUpdateManyMutationInput;
  where: LeadCustomObjectRowAssociationScalarWhereInput;
};

export type LeadCustomObjectRowAssociationUpdateManyWithWhereWithoutRowInput = {
  data: LeadCustomObjectRowAssociationUpdateManyMutationInput;
  where: LeadCustomObjectRowAssociationScalarWhereInput;
};

export type LeadCustomObjectRowAssociationUpdateWithoutCustom_ObjectInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  creation_source?: InputMaybe<EnumLeadCustomObjectAssociationSourceFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lead?: InputMaybe<LeadUpdateOneRequiredWithoutLead_Custom_Row_InfoInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutLeadCustomObjectRowAssociationInput>;
  row?: InputMaybe<CustomObjectRowUpdateOneRequiredWithoutLead_Custom_Row_InfoInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type LeadCustomObjectRowAssociationUpdateWithoutLeadInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  creation_source?: InputMaybe<EnumLeadCustomObjectAssociationSourceFieldUpdateOperationsInput>;
  custom_object?: InputMaybe<CustomObjectUpdateOneRequiredWithoutLeadCustomObjectRowAssociationInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutLeadCustomObjectRowAssociationInput>;
  row?: InputMaybe<CustomObjectRowUpdateOneRequiredWithoutLead_Custom_Row_InfoInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type LeadCustomObjectRowAssociationUpdateWithoutOrganizationInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  creation_source?: InputMaybe<EnumLeadCustomObjectAssociationSourceFieldUpdateOperationsInput>;
  custom_object?: InputMaybe<CustomObjectUpdateOneRequiredWithoutLeadCustomObjectRowAssociationInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lead?: InputMaybe<LeadUpdateOneRequiredWithoutLead_Custom_Row_InfoInput>;
  row?: InputMaybe<CustomObjectRowUpdateOneRequiredWithoutLead_Custom_Row_InfoInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type LeadCustomObjectRowAssociationUpdateWithoutRowInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  creation_source?: InputMaybe<EnumLeadCustomObjectAssociationSourceFieldUpdateOperationsInput>;
  custom_object?: InputMaybe<CustomObjectUpdateOneRequiredWithoutLeadCustomObjectRowAssociationInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lead?: InputMaybe<LeadUpdateOneRequiredWithoutLead_Custom_Row_InfoInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutLeadCustomObjectRowAssociationInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type LeadCustomObjectRowAssociationUpdateWithWhereUniqueWithoutCustom_ObjectInput = {
  data: LeadCustomObjectRowAssociationUpdateWithoutCustom_ObjectInput;
  where: LeadCustomObjectRowAssociationWhereUniqueInput;
};

export type LeadCustomObjectRowAssociationUpdateWithWhereUniqueWithoutLeadInput = {
  data: LeadCustomObjectRowAssociationUpdateWithoutLeadInput;
  where: LeadCustomObjectRowAssociationWhereUniqueInput;
};

export type LeadCustomObjectRowAssociationUpdateWithWhereUniqueWithoutOrganizationInput = {
  data: LeadCustomObjectRowAssociationUpdateWithoutOrganizationInput;
  where: LeadCustomObjectRowAssociationWhereUniqueInput;
};

export type LeadCustomObjectRowAssociationUpdateWithWhereUniqueWithoutRowInput = {
  data: LeadCustomObjectRowAssociationUpdateWithoutRowInput;
  where: LeadCustomObjectRowAssociationWhereUniqueInput;
};

export type LeadCustomObjectRowAssociationUpsertWithWhereUniqueWithoutCustom_ObjectInput = {
  create: LeadCustomObjectRowAssociationCreateWithoutCustom_ObjectInput;
  update: LeadCustomObjectRowAssociationUpdateWithoutCustom_ObjectInput;
  where: LeadCustomObjectRowAssociationWhereUniqueInput;
};

export type LeadCustomObjectRowAssociationUpsertWithWhereUniqueWithoutLeadInput = {
  create: LeadCustomObjectRowAssociationCreateWithoutLeadInput;
  update: LeadCustomObjectRowAssociationUpdateWithoutLeadInput;
  where: LeadCustomObjectRowAssociationWhereUniqueInput;
};

export type LeadCustomObjectRowAssociationUpsertWithWhereUniqueWithoutOrganizationInput = {
  create: LeadCustomObjectRowAssociationCreateWithoutOrganizationInput;
  update: LeadCustomObjectRowAssociationUpdateWithoutOrganizationInput;
  where: LeadCustomObjectRowAssociationWhereUniqueInput;
};

export type LeadCustomObjectRowAssociationUpsertWithWhereUniqueWithoutRowInput = {
  create: LeadCustomObjectRowAssociationCreateWithoutRowInput;
  update: LeadCustomObjectRowAssociationUpdateWithoutRowInput;
  where: LeadCustomObjectRowAssociationWhereUniqueInput;
};

export type LeadCustomObjectRowAssociationWhereInput = {
  AND?: InputMaybe<Array<LeadCustomObjectRowAssociationWhereInput>>;
  created_at?: InputMaybe<DateTimeFilter>;
  creation_source?: InputMaybe<EnumLeadCustomObjectAssociationSourceFilter>;
  custom_object?: InputMaybe<CustomObjectWhereInput>;
  custom_object_id?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  lead?: InputMaybe<LeadWhereInput>;
  lead_id?: InputMaybe<StringFilter>;
  NOT?: InputMaybe<Array<LeadCustomObjectRowAssociationWhereInput>>;
  OR?: InputMaybe<Array<LeadCustomObjectRowAssociationWhereInput>>;
  organization?: InputMaybe<OrganizationWhereInput>;
  organization_id?: InputMaybe<StringFilter>;
  row?: InputMaybe<CustomObjectRowWhereInput>;
  row_id?: InputMaybe<StringFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
};

export type LeadCustomObjectRowAssociationWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  lead_id_row_id?: InputMaybe<LeadCustomObjectRowAssociationLead_IdRow_IdCompoundUniqueInput>;
};

export type LeadCustomObjectRowUpsertInput = {
  custom_object_id: Scalars['String']['input'];
  lead_id: Scalars['String']['input'];
  row_id: Scalars['String']['input'];
};

/** Summarized Dashboard Input */
export type LeadDetailArgument = {
  computed_id: Scalars['String']['input'];
  date_filter: DashboardDateOption;
  desc?: InputMaybe<Scalars['Boolean']['input']>;
  lead_filter: DashboardLeadFilterOption;
  measure_by?: InputMaybe<MeasureByFilter>;
  metric: Metric;
  order_by?: InputMaybe<Scalars['String']['input']>;
  position: Position;
  rep_filter: DashboardRepFilterOption;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};

export type LeadFieldMultiplier = {
  __typename?: 'LeadFieldMultiplier';
  actual_actions_count: Scalars['Int']['output'];
  computed_rate: Scalars['Float']['output'];
  current_data_points: Scalars['Int']['output'];
  default_rate: Scalars['Float']['output'];
  field: Scalars['String']['output'];
  hinge_point: HingePointMultiplier;
  HP_type: Hingepoint;
  min_number_data_points: Scalars['Int']['output'];
  organization: Organization;
  organization_id: Scalars['String']['output'];
  possible_actions_count: Scalars['Int']['output'];
  rate?: Maybe<Scalars['Float']['output']>;
  type: LfmType;
  use_default: Scalars['Boolean']['output'];
};

export type LeadFieldMultiplierCreateManyHinge_PointInput = {
  actual_actions_count?: InputMaybe<Scalars['Int']['input']>;
  computed_rate: Scalars['Float']['input'];
  current_data_points?: InputMaybe<Scalars['Int']['input']>;
  default_rate: Scalars['Float']['input'];
  field: Scalars['String']['input'];
  min_number_data_points?: InputMaybe<Scalars['Int']['input']>;
  possible_actions_count?: InputMaybe<Scalars['Int']['input']>;
  type: LfmType;
  use_default?: InputMaybe<Scalars['Boolean']['input']>;
};

export type LeadFieldMultiplierCreateManyHinge_PointInputEnvelope = {
  data?: InputMaybe<Array<LeadFieldMultiplierCreateManyHinge_PointInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type LeadFieldMultiplierCreateManyOrganizationInput = {
  actual_actions_count?: InputMaybe<Scalars['Int']['input']>;
  computed_rate: Scalars['Float']['input'];
  current_data_points?: InputMaybe<Scalars['Int']['input']>;
  default_rate: Scalars['Float']['input'];
  field: Scalars['String']['input'];
  HP_type: Hingepoint;
  min_number_data_points?: InputMaybe<Scalars['Int']['input']>;
  possible_actions_count?: InputMaybe<Scalars['Int']['input']>;
  type: LfmType;
  use_default?: InputMaybe<Scalars['Boolean']['input']>;
};

export type LeadFieldMultiplierCreateManyOrganizationInputEnvelope = {
  data?: InputMaybe<Array<LeadFieldMultiplierCreateManyOrganizationInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type LeadFieldMultiplierCreateNestedManyWithoutHinge_PointInput = {
  connect?: InputMaybe<Array<LeadFieldMultiplierWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<LeadFieldMultiplierCreateOrConnectWithoutHinge_PointInput>>;
  create?: InputMaybe<Array<LeadFieldMultiplierCreateWithoutHinge_PointInput>>;
  createMany?: InputMaybe<LeadFieldMultiplierCreateManyHinge_PointInputEnvelope>;
};

export type LeadFieldMultiplierCreateNestedManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<LeadFieldMultiplierWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<LeadFieldMultiplierCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<LeadFieldMultiplierCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<LeadFieldMultiplierCreateManyOrganizationInputEnvelope>;
};

export type LeadFieldMultiplierCreateOrConnectWithoutHinge_PointInput = {
  create: LeadFieldMultiplierCreateWithoutHinge_PointInput;
  where: LeadFieldMultiplierWhereUniqueInput;
};

export type LeadFieldMultiplierCreateOrConnectWithoutOrganizationInput = {
  create: LeadFieldMultiplierCreateWithoutOrganizationInput;
  where: LeadFieldMultiplierWhereUniqueInput;
};

export type LeadFieldMultiplierCreateWithoutHinge_PointInput = {
  actual_actions_count?: InputMaybe<Scalars['Int']['input']>;
  computed_rate: Scalars['Float']['input'];
  current_data_points?: InputMaybe<Scalars['Int']['input']>;
  default_rate: Scalars['Float']['input'];
  field: Scalars['String']['input'];
  min_number_data_points?: InputMaybe<Scalars['Int']['input']>;
  organization: OrganizationCreateNestedOneWithoutLeadFieldMultiplierInput;
  possible_actions_count?: InputMaybe<Scalars['Int']['input']>;
  type: LfmType;
  use_default?: InputMaybe<Scalars['Boolean']['input']>;
};

export type LeadFieldMultiplierCreateWithoutOrganizationInput = {
  actual_actions_count?: InputMaybe<Scalars['Int']['input']>;
  computed_rate: Scalars['Float']['input'];
  current_data_points?: InputMaybe<Scalars['Int']['input']>;
  default_rate: Scalars['Float']['input'];
  field: Scalars['String']['input'];
  hinge_point: HingePointMultiplierCreateNestedOneWithoutLeadFieldMultiplierInput;
  min_number_data_points?: InputMaybe<Scalars['Int']['input']>;
  possible_actions_count?: InputMaybe<Scalars['Int']['input']>;
  type: LfmType;
  use_default?: InputMaybe<Scalars['Boolean']['input']>;
};

export type LeadFieldMultiplierCustomCreateManyHinge_PointInput = {
  actual_actions_count?: InputMaybe<Scalars['Int']['input']>;
  computed_rate: Scalars['Float']['input'];
  current_data_points?: InputMaybe<Scalars['Int']['input']>;
  default_rate: Scalars['Float']['input'];
  dropdown_value: Scalars['String']['input'];
  field: Scalars['String']['input'];
  min_number_data_points?: InputMaybe<Scalars['Int']['input']>;
  possible_actions_count?: InputMaybe<Scalars['Int']['input']>;
  use_default?: InputMaybe<Scalars['Boolean']['input']>;
};

export type LeadFieldMultiplierCustomCreateManyHinge_PointInputEnvelope = {
  data?: InputMaybe<Array<LeadFieldMultiplierCustomCreateManyHinge_PointInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type LeadFieldMultiplierCustomCreateManyOrganizationInput = {
  actual_actions_count?: InputMaybe<Scalars['Int']['input']>;
  computed_rate: Scalars['Float']['input'];
  current_data_points?: InputMaybe<Scalars['Int']['input']>;
  default_rate: Scalars['Float']['input'];
  dropdown_value: Scalars['String']['input'];
  field: Scalars['String']['input'];
  HP_type: Hingepoint;
  min_number_data_points?: InputMaybe<Scalars['Int']['input']>;
  possible_actions_count?: InputMaybe<Scalars['Int']['input']>;
  use_default?: InputMaybe<Scalars['Boolean']['input']>;
};

export type LeadFieldMultiplierCustomCreateManyOrganizationInputEnvelope = {
  data?: InputMaybe<Array<LeadFieldMultiplierCustomCreateManyOrganizationInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type LeadFieldMultiplierCustomCreateNestedManyWithoutHinge_PointInput = {
  connect?: InputMaybe<Array<LeadFieldMultiplierCustomWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<LeadFieldMultiplierCustomCreateOrConnectWithoutHinge_PointInput>>;
  create?: InputMaybe<Array<LeadFieldMultiplierCustomCreateWithoutHinge_PointInput>>;
  createMany?: InputMaybe<LeadFieldMultiplierCustomCreateManyHinge_PointInputEnvelope>;
};

export type LeadFieldMultiplierCustomCreateNestedManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<LeadFieldMultiplierCustomWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<LeadFieldMultiplierCustomCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<LeadFieldMultiplierCustomCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<LeadFieldMultiplierCustomCreateManyOrganizationInputEnvelope>;
};

export type LeadFieldMultiplierCustomCreateOrConnectWithoutHinge_PointInput = {
  create: LeadFieldMultiplierCustomCreateWithoutHinge_PointInput;
  where: LeadFieldMultiplierCustomWhereUniqueInput;
};

export type LeadFieldMultiplierCustomCreateOrConnectWithoutOrganizationInput = {
  create: LeadFieldMultiplierCustomCreateWithoutOrganizationInput;
  where: LeadFieldMultiplierCustomWhereUniqueInput;
};

export type LeadFieldMultiplierCustomCreateWithoutHinge_PointInput = {
  actual_actions_count?: InputMaybe<Scalars['Int']['input']>;
  computed_rate: Scalars['Float']['input'];
  current_data_points?: InputMaybe<Scalars['Int']['input']>;
  default_rate: Scalars['Float']['input'];
  dropdown_value: Scalars['String']['input'];
  field: Scalars['String']['input'];
  min_number_data_points?: InputMaybe<Scalars['Int']['input']>;
  organization: OrganizationCreateNestedOneWithoutLeadFieldMultiplierCustomInput;
  possible_actions_count?: InputMaybe<Scalars['Int']['input']>;
  use_default?: InputMaybe<Scalars['Boolean']['input']>;
};

export type LeadFieldMultiplierCustomCreateWithoutOrganizationInput = {
  actual_actions_count?: InputMaybe<Scalars['Int']['input']>;
  computed_rate: Scalars['Float']['input'];
  current_data_points?: InputMaybe<Scalars['Int']['input']>;
  default_rate: Scalars['Float']['input'];
  dropdown_value: Scalars['String']['input'];
  field: Scalars['String']['input'];
  hinge_point: HingePointMultiplierCreateNestedOneWithoutLeadFieldMultiplierCustomInput;
  min_number_data_points?: InputMaybe<Scalars['Int']['input']>;
  possible_actions_count?: InputMaybe<Scalars['Int']['input']>;
  use_default?: InputMaybe<Scalars['Boolean']['input']>;
};

export type LeadFieldMultiplierCustomLf_Custom_IdCompoundUniqueInput = {
  dropdown_value: Scalars['String']['input'];
  field: Scalars['String']['input'];
  HP_type: Hingepoint;
  organization_id: Scalars['String']['input'];
};

export type LeadFieldMultiplierCustomListRelationFilter = {
  every?: InputMaybe<LeadFieldMultiplierCustomWhereInput>;
  none?: InputMaybe<LeadFieldMultiplierCustomWhereInput>;
  some?: InputMaybe<LeadFieldMultiplierCustomWhereInput>;
};

export type LeadFieldMultiplierCustomOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
  count?: InputMaybe<SortOrder>;
};

export type LeadFieldMultiplierCustomScalarWhereInput = {
  actual_actions_count?: InputMaybe<IntFilter>;
  AND?: InputMaybe<Array<LeadFieldMultiplierCustomScalarWhereInput>>;
  computed_rate?: InputMaybe<FloatFilter>;
  current_data_points?: InputMaybe<IntFilter>;
  default_rate?: InputMaybe<FloatFilter>;
  dropdown_value?: InputMaybe<StringFilter>;
  field?: InputMaybe<StringFilter>;
  HP_type?: InputMaybe<EnumHingepointFilter>;
  min_number_data_points?: InputMaybe<IntFilter>;
  NOT?: InputMaybe<Array<LeadFieldMultiplierCustomScalarWhereInput>>;
  OR?: InputMaybe<Array<LeadFieldMultiplierCustomScalarWhereInput>>;
  organization_id?: InputMaybe<StringFilter>;
  possible_actions_count?: InputMaybe<IntFilter>;
  use_default?: InputMaybe<BoolFilter>;
};

export type LeadFieldMultiplierCustomUpdateManyMutationInput = {
  actual_actions_count?: InputMaybe<IntFieldUpdateOperationsInput>;
  computed_rate?: InputMaybe<FloatFieldUpdateOperationsInput>;
  current_data_points?: InputMaybe<IntFieldUpdateOperationsInput>;
  default_rate?: InputMaybe<FloatFieldUpdateOperationsInput>;
  dropdown_value?: InputMaybe<StringFieldUpdateOperationsInput>;
  field?: InputMaybe<StringFieldUpdateOperationsInput>;
  min_number_data_points?: InputMaybe<IntFieldUpdateOperationsInput>;
  possible_actions_count?: InputMaybe<IntFieldUpdateOperationsInput>;
  use_default?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type LeadFieldMultiplierCustomUpdateManyWithoutHinge_PointInput = {
  connect?: InputMaybe<Array<LeadFieldMultiplierCustomWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<LeadFieldMultiplierCustomCreateOrConnectWithoutHinge_PointInput>>;
  create?: InputMaybe<Array<LeadFieldMultiplierCustomCreateWithoutHinge_PointInput>>;
  createMany?: InputMaybe<LeadFieldMultiplierCustomCreateManyHinge_PointInputEnvelope>;
  delete?: InputMaybe<Array<LeadFieldMultiplierCustomWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<LeadFieldMultiplierCustomScalarWhereInput>>;
  disconnect?: InputMaybe<Array<LeadFieldMultiplierCustomWhereUniqueInput>>;
  set?: InputMaybe<Array<LeadFieldMultiplierCustomWhereUniqueInput>>;
  update?: InputMaybe<Array<LeadFieldMultiplierCustomUpdateWithWhereUniqueWithoutHinge_PointInput>>;
  updateMany?: InputMaybe<Array<LeadFieldMultiplierCustomUpdateManyWithWhereWithoutHinge_PointInput>>;
  upsert?: InputMaybe<Array<LeadFieldMultiplierCustomUpsertWithWhereUniqueWithoutHinge_PointInput>>;
};

export type LeadFieldMultiplierCustomUpdateManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<LeadFieldMultiplierCustomWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<LeadFieldMultiplierCustomCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<LeadFieldMultiplierCustomCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<LeadFieldMultiplierCustomCreateManyOrganizationInputEnvelope>;
  delete?: InputMaybe<Array<LeadFieldMultiplierCustomWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<LeadFieldMultiplierCustomScalarWhereInput>>;
  disconnect?: InputMaybe<Array<LeadFieldMultiplierCustomWhereUniqueInput>>;
  set?: InputMaybe<Array<LeadFieldMultiplierCustomWhereUniqueInput>>;
  update?: InputMaybe<Array<LeadFieldMultiplierCustomUpdateWithWhereUniqueWithoutOrganizationInput>>;
  updateMany?: InputMaybe<Array<LeadFieldMultiplierCustomUpdateManyWithWhereWithoutOrganizationInput>>;
  upsert?: InputMaybe<Array<LeadFieldMultiplierCustomUpsertWithWhereUniqueWithoutOrganizationInput>>;
};

export type LeadFieldMultiplierCustomUpdateManyWithWhereWithoutHinge_PointInput = {
  data: LeadFieldMultiplierCustomUpdateManyMutationInput;
  where: LeadFieldMultiplierCustomScalarWhereInput;
};

export type LeadFieldMultiplierCustomUpdateManyWithWhereWithoutOrganizationInput = {
  data: LeadFieldMultiplierCustomUpdateManyMutationInput;
  where: LeadFieldMultiplierCustomScalarWhereInput;
};

export type LeadFieldMultiplierCustomUpdateWithoutHinge_PointInput = {
  actual_actions_count?: InputMaybe<IntFieldUpdateOperationsInput>;
  computed_rate?: InputMaybe<FloatFieldUpdateOperationsInput>;
  current_data_points?: InputMaybe<IntFieldUpdateOperationsInput>;
  default_rate?: InputMaybe<FloatFieldUpdateOperationsInput>;
  dropdown_value?: InputMaybe<StringFieldUpdateOperationsInput>;
  field?: InputMaybe<StringFieldUpdateOperationsInput>;
  min_number_data_points?: InputMaybe<IntFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutLeadFieldMultiplierCustomInput>;
  possible_actions_count?: InputMaybe<IntFieldUpdateOperationsInput>;
  use_default?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type LeadFieldMultiplierCustomUpdateWithoutOrganizationInput = {
  actual_actions_count?: InputMaybe<IntFieldUpdateOperationsInput>;
  computed_rate?: InputMaybe<FloatFieldUpdateOperationsInput>;
  current_data_points?: InputMaybe<IntFieldUpdateOperationsInput>;
  default_rate?: InputMaybe<FloatFieldUpdateOperationsInput>;
  dropdown_value?: InputMaybe<StringFieldUpdateOperationsInput>;
  field?: InputMaybe<StringFieldUpdateOperationsInput>;
  hinge_point?: InputMaybe<HingePointMultiplierUpdateOneRequiredWithoutLeadFieldMultiplierCustomInput>;
  min_number_data_points?: InputMaybe<IntFieldUpdateOperationsInput>;
  possible_actions_count?: InputMaybe<IntFieldUpdateOperationsInput>;
  use_default?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type LeadFieldMultiplierCustomUpdateWithWhereUniqueWithoutHinge_PointInput = {
  data: LeadFieldMultiplierCustomUpdateWithoutHinge_PointInput;
  where: LeadFieldMultiplierCustomWhereUniqueInput;
};

export type LeadFieldMultiplierCustomUpdateWithWhereUniqueWithoutOrganizationInput = {
  data: LeadFieldMultiplierCustomUpdateWithoutOrganizationInput;
  where: LeadFieldMultiplierCustomWhereUniqueInput;
};

export type LeadFieldMultiplierCustomUpsertWithWhereUniqueWithoutHinge_PointInput = {
  create: LeadFieldMultiplierCustomCreateWithoutHinge_PointInput;
  update: LeadFieldMultiplierCustomUpdateWithoutHinge_PointInput;
  where: LeadFieldMultiplierCustomWhereUniqueInput;
};

export type LeadFieldMultiplierCustomUpsertWithWhereUniqueWithoutOrganizationInput = {
  create: LeadFieldMultiplierCustomCreateWithoutOrganizationInput;
  update: LeadFieldMultiplierCustomUpdateWithoutOrganizationInput;
  where: LeadFieldMultiplierCustomWhereUniqueInput;
};

export type LeadFieldMultiplierCustomWhereInput = {
  actual_actions_count?: InputMaybe<IntFilter>;
  AND?: InputMaybe<Array<LeadFieldMultiplierCustomWhereInput>>;
  computed_rate?: InputMaybe<FloatFilter>;
  current_data_points?: InputMaybe<IntFilter>;
  default_rate?: InputMaybe<FloatFilter>;
  dropdown_value?: InputMaybe<StringFilter>;
  field?: InputMaybe<StringFilter>;
  hinge_point?: InputMaybe<HingePointMultiplierWhereInput>;
  HP_type?: InputMaybe<EnumHingepointFilter>;
  min_number_data_points?: InputMaybe<IntFilter>;
  NOT?: InputMaybe<Array<LeadFieldMultiplierCustomWhereInput>>;
  OR?: InputMaybe<Array<LeadFieldMultiplierCustomWhereInput>>;
  organization?: InputMaybe<OrganizationWhereInput>;
  organization_id?: InputMaybe<StringFilter>;
  possible_actions_count?: InputMaybe<IntFilter>;
  use_default?: InputMaybe<BoolFilter>;
};

export type LeadFieldMultiplierCustomWhereUniqueInput = {
  lf_custom_id?: InputMaybe<LeadFieldMultiplierCustomLf_Custom_IdCompoundUniqueInput>;
};

export type LeadFieldMultiplierLfm_IdCompoundUniqueInput = {
  field: Scalars['String']['input'];
  HP_type: Hingepoint;
  organization_id: Scalars['String']['input'];
  type: LfmType;
};

export type LeadFieldMultiplierListRelationFilter = {
  every?: InputMaybe<LeadFieldMultiplierWhereInput>;
  none?: InputMaybe<LeadFieldMultiplierWhereInput>;
  some?: InputMaybe<LeadFieldMultiplierWhereInput>;
};

export type LeadFieldMultiplierOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
  count?: InputMaybe<SortOrder>;
};

export type LeadFieldMultiplierScalarWhereInput = {
  actual_actions_count?: InputMaybe<IntFilter>;
  AND?: InputMaybe<Array<LeadFieldMultiplierScalarWhereInput>>;
  computed_rate?: InputMaybe<FloatFilter>;
  current_data_points?: InputMaybe<IntFilter>;
  default_rate?: InputMaybe<FloatFilter>;
  field?: InputMaybe<StringFilter>;
  HP_type?: InputMaybe<EnumHingepointFilter>;
  min_number_data_points?: InputMaybe<IntFilter>;
  NOT?: InputMaybe<Array<LeadFieldMultiplierScalarWhereInput>>;
  OR?: InputMaybe<Array<LeadFieldMultiplierScalarWhereInput>>;
  organization_id?: InputMaybe<StringFilter>;
  possible_actions_count?: InputMaybe<IntFilter>;
  type?: InputMaybe<EnumLfmTypeFilter>;
  use_default?: InputMaybe<BoolFilter>;
};

export type LeadFieldMultiplierUpdateManyMutationInput = {
  actual_actions_count?: InputMaybe<IntFieldUpdateOperationsInput>;
  computed_rate?: InputMaybe<FloatFieldUpdateOperationsInput>;
  current_data_points?: InputMaybe<IntFieldUpdateOperationsInput>;
  default_rate?: InputMaybe<FloatFieldUpdateOperationsInput>;
  field?: InputMaybe<StringFieldUpdateOperationsInput>;
  min_number_data_points?: InputMaybe<IntFieldUpdateOperationsInput>;
  possible_actions_count?: InputMaybe<IntFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumLfmTypeFieldUpdateOperationsInput>;
  use_default?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type LeadFieldMultiplierUpdateManyWithoutHinge_PointInput = {
  connect?: InputMaybe<Array<LeadFieldMultiplierWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<LeadFieldMultiplierCreateOrConnectWithoutHinge_PointInput>>;
  create?: InputMaybe<Array<LeadFieldMultiplierCreateWithoutHinge_PointInput>>;
  createMany?: InputMaybe<LeadFieldMultiplierCreateManyHinge_PointInputEnvelope>;
  delete?: InputMaybe<Array<LeadFieldMultiplierWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<LeadFieldMultiplierScalarWhereInput>>;
  disconnect?: InputMaybe<Array<LeadFieldMultiplierWhereUniqueInput>>;
  set?: InputMaybe<Array<LeadFieldMultiplierWhereUniqueInput>>;
  update?: InputMaybe<Array<LeadFieldMultiplierUpdateWithWhereUniqueWithoutHinge_PointInput>>;
  updateMany?: InputMaybe<Array<LeadFieldMultiplierUpdateManyWithWhereWithoutHinge_PointInput>>;
  upsert?: InputMaybe<Array<LeadFieldMultiplierUpsertWithWhereUniqueWithoutHinge_PointInput>>;
};

export type LeadFieldMultiplierUpdateManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<LeadFieldMultiplierWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<LeadFieldMultiplierCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<LeadFieldMultiplierCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<LeadFieldMultiplierCreateManyOrganizationInputEnvelope>;
  delete?: InputMaybe<Array<LeadFieldMultiplierWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<LeadFieldMultiplierScalarWhereInput>>;
  disconnect?: InputMaybe<Array<LeadFieldMultiplierWhereUniqueInput>>;
  set?: InputMaybe<Array<LeadFieldMultiplierWhereUniqueInput>>;
  update?: InputMaybe<Array<LeadFieldMultiplierUpdateWithWhereUniqueWithoutOrganizationInput>>;
  updateMany?: InputMaybe<Array<LeadFieldMultiplierUpdateManyWithWhereWithoutOrganizationInput>>;
  upsert?: InputMaybe<Array<LeadFieldMultiplierUpsertWithWhereUniqueWithoutOrganizationInput>>;
};

export type LeadFieldMultiplierUpdateManyWithWhereWithoutHinge_PointInput = {
  data: LeadFieldMultiplierUpdateManyMutationInput;
  where: LeadFieldMultiplierScalarWhereInput;
};

export type LeadFieldMultiplierUpdateManyWithWhereWithoutOrganizationInput = {
  data: LeadFieldMultiplierUpdateManyMutationInput;
  where: LeadFieldMultiplierScalarWhereInput;
};

export type LeadFieldMultiplierUpdateWithoutHinge_PointInput = {
  actual_actions_count?: InputMaybe<IntFieldUpdateOperationsInput>;
  computed_rate?: InputMaybe<FloatFieldUpdateOperationsInput>;
  current_data_points?: InputMaybe<IntFieldUpdateOperationsInput>;
  default_rate?: InputMaybe<FloatFieldUpdateOperationsInput>;
  field?: InputMaybe<StringFieldUpdateOperationsInput>;
  min_number_data_points?: InputMaybe<IntFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutLeadFieldMultiplierInput>;
  possible_actions_count?: InputMaybe<IntFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumLfmTypeFieldUpdateOperationsInput>;
  use_default?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type LeadFieldMultiplierUpdateWithoutOrganizationInput = {
  actual_actions_count?: InputMaybe<IntFieldUpdateOperationsInput>;
  computed_rate?: InputMaybe<FloatFieldUpdateOperationsInput>;
  current_data_points?: InputMaybe<IntFieldUpdateOperationsInput>;
  default_rate?: InputMaybe<FloatFieldUpdateOperationsInput>;
  field?: InputMaybe<StringFieldUpdateOperationsInput>;
  hinge_point?: InputMaybe<HingePointMultiplierUpdateOneRequiredWithoutLeadFieldMultiplierInput>;
  min_number_data_points?: InputMaybe<IntFieldUpdateOperationsInput>;
  possible_actions_count?: InputMaybe<IntFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumLfmTypeFieldUpdateOperationsInput>;
  use_default?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type LeadFieldMultiplierUpdateWithWhereUniqueWithoutHinge_PointInput = {
  data: LeadFieldMultiplierUpdateWithoutHinge_PointInput;
  where: LeadFieldMultiplierWhereUniqueInput;
};

export type LeadFieldMultiplierUpdateWithWhereUniqueWithoutOrganizationInput = {
  data: LeadFieldMultiplierUpdateWithoutOrganizationInput;
  where: LeadFieldMultiplierWhereUniqueInput;
};

export type LeadFieldMultiplierUpsertWithWhereUniqueWithoutHinge_PointInput = {
  create: LeadFieldMultiplierCreateWithoutHinge_PointInput;
  update: LeadFieldMultiplierUpdateWithoutHinge_PointInput;
  where: LeadFieldMultiplierWhereUniqueInput;
};

export type LeadFieldMultiplierUpsertWithWhereUniqueWithoutOrganizationInput = {
  create: LeadFieldMultiplierCreateWithoutOrganizationInput;
  update: LeadFieldMultiplierUpdateWithoutOrganizationInput;
  where: LeadFieldMultiplierWhereUniqueInput;
};

export type LeadFieldMultiplierWhereInput = {
  actual_actions_count?: InputMaybe<IntFilter>;
  AND?: InputMaybe<Array<LeadFieldMultiplierWhereInput>>;
  computed_rate?: InputMaybe<FloatFilter>;
  current_data_points?: InputMaybe<IntFilter>;
  default_rate?: InputMaybe<FloatFilter>;
  field?: InputMaybe<StringFilter>;
  hinge_point?: InputMaybe<HingePointMultiplierWhereInput>;
  HP_type?: InputMaybe<EnumHingepointFilter>;
  min_number_data_points?: InputMaybe<IntFilter>;
  NOT?: InputMaybe<Array<LeadFieldMultiplierWhereInput>>;
  OR?: InputMaybe<Array<LeadFieldMultiplierWhereInput>>;
  organization?: InputMaybe<OrganizationWhereInput>;
  organization_id?: InputMaybe<StringFilter>;
  possible_actions_count?: InputMaybe<IntFilter>;
  type?: InputMaybe<EnumLfmTypeFilter>;
  use_default?: InputMaybe<BoolFilter>;
};

export type LeadFieldMultiplierWhereUniqueInput = {
  lfm_id?: InputMaybe<LeadFieldMultiplierLfm_IdCompoundUniqueInput>;
};

/** Filter inputs for lead intents */
export type LeadFilter = {
  call_result_filter?: InputMaybe<CallResultFilter>;
  channels?: InputMaybe<Array<Channel>>;
  cities?: InputMaybe<Array<Scalars['String']['input']>>;
  countries?: InputMaybe<Array<Scalars['String']['input']>>;
  custom_fields?: InputMaybe<Array<InputMaybe<LeadCustomFieldFilter>>>;
  history_filter?: InputMaybe<LeadHistoryFilter>;
  industries?: InputMaybe<Array<Scalars['String']['input']>>;
  lead_assignment_origin?: InputMaybe<Array<AssignmentOrigin>>;
  lead_creation_source?: InputMaybe<Array<Leadcreationsource>>;
  lead_sources?: InputMaybe<Array<Scalars['String']['input']>>;
  max_lead_Val?: InputMaybe<Scalars['Float']['input']>;
  min_lead_val?: InputMaybe<Scalars['Float']['input']>;
  next_scheduled_event_lowerbound_date?: InputMaybe<Scalars['Date']['input']>;
  next_scheduled_event_upperbound_date?: InputMaybe<Scalars['Date']['input']>;
  NextScheduledEventDays?: InputMaybe<Scalars['Int']['input']>;
  NextScheduledEventTypes?: InputMaybe<Array<InputMaybe<ScheduleItemType>>>;
  rep_activity_filter?: InputMaybe<RepActivityFilter>;
  /** Id of the reps */
  reps?: InputMaybe<Array<Scalars['String']['input']>>;
  sequences?: InputMaybe<Array<InputMaybe<SequencesDashFilter>>>;
  states?: InputMaybe<Array<Scalars['String']['input']>>;
  status?: InputMaybe<Array<Los>>;
  sub_industry?: InputMaybe<Array<Scalars['String']['input']>>;
  timezones?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Only used when filtering Intents */
  types?: InputMaybe<Array<IntentType>>;
};

/** System Leads Filter Operator - DoesInclude, DoesNotInclude, EmptyField */
export enum LeadFilterOperator {
  DoesInclude = 'DoesInclude',
  DoesNotInclude = 'DoesNotInclude',
  EmptyField = 'EmptyField'
}

/**
 * Used to calculate statistics of lead pool
 *
 */
export type LeadFunnelItem = {
  __typename?: 'LeadFunnelItem';
  id?: Maybe<Scalars['String']['output']>;
  metric: Scalars['String']['output'];
  metric_label?: Maybe<Scalars['String']['output']>;
  metric_tooltip?: Maybe<Scalars['String']['output']>;
  metric_type?: Maybe<Scalars['String']['output']>;
  organization_id?: Maybe<Scalars['String']['output']>;
  row_id?: Maybe<Scalars['String']['output']>;
  row_label?: Maybe<Scalars['String']['output']>;
  team_id?: Maybe<Scalars['String']['output']>;
  user?: Maybe<User>;
  user_id?: Maybe<Scalars['String']['output']>;
  value: Scalars['Float']['output'];
  /** lead table filter, available options are LeadSource or Team */
  view_filter?: Maybe<Scalars['String']['output']>;
};

/** available lead funnel metrics */
export enum LeadFunnelMetrics {
  CustomerNonSale = 'CustomerNonSale',
  DecisionCallHeld = 'DecisionCallHeld',
  DemoHeld = 'DemoHeld',
  DemoSet = 'DemoSet',
  DemoSetFlaked = 'DemoSetFlaked',
  DmContact = 'DMContact',
  DmContactInterested = 'DMContactInterested',
  DoNotContact = 'DoNotContact',
  FollowUpDemoHeld = 'FollowUpDemoHeld',
  NdmContact = 'NDMContact',
  NdmContactInterested = 'NDMContactInterested',
  NoContactMade = 'NoContactMade',
  Sale = 'Sale',
  Undialed = 'Undialed'
}

/** available lead funnel sort options */
export enum LeadFunnelSortOption {
  CustomerNonSale = 'CustomerNonSale',
  DecisionCallHeld = 'DecisionCallHeld',
  DemoHeld = 'DemoHeld',
  DemoSet = 'DemoSet',
  DemoSetFlaked = 'DemoSetFlaked',
  DmContact = 'DMContact',
  DmContactInterested = 'DMContactInterested',
  DoNotContact = 'DoNotContact',
  FollowUpDemoHeld = 'FollowUpDemoHeld',
  NdmContact = 'NDMContact',
  NdmContactInterested = 'NDMContactInterested',
  NoContactMade = 'NoContactMade',
  Sale = 'Sale',
  Undialed = 'Undialed'
}

/** Filter inputs for lead history filter */
export type LeadHistoryFilter = {
  amount?: InputMaybe<Scalars['Int']['input']>;
  amount_2?: InputMaybe<Scalars['Int']['input']>;
  lowerbound_date?: InputMaybe<Scalars['DateTime']['input']>;
  metric?: InputMaybe<ActivityFilterMetric>;
  operator?: InputMaybe<ActivityFilterOperator>;
  upperbound_date?: InputMaybe<Scalars['DateTime']['input']>;
};

export type LeadHubspot_IdOrganization_IdCompoundUniqueInput = {
  hubspot_id: Scalars['String']['input'];
  organization_id: Scalars['String']['input'];
};

export type LeadImportHistory = {
  __typename?: 'LeadImportHistory';
  created_at: Scalars['DateTime']['output'];
  duplicate_report_url?: Maybe<Scalars['String']['output']>;
  error_report_url?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  is_resolved: Scalars['Boolean']['output'];
  lead?: Maybe<Lead>;
  lead_id?: Maybe<Scalars['String']['output']>;
  leads_added?: Maybe<Scalars['Int']['output']>;
  leads_errored?: Maybe<Scalars['Int']['output']>;
  leads_on_warning?: Maybe<Scalars['Int']['output']>;
  leads_queued?: Maybe<Scalars['Int']['output']>;
  leads_updated?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  organization?: Maybe<Organization>;
  organization_id?: Maybe<Scalars['String']['output']>;
  type: LeadImportType;
  updated_at: Scalars['DateTime']['output'];
  upload_status: LeadUploadStatus;
  uploaded_leads: Array<Lead>;
  user?: Maybe<User>;
  user_id?: Maybe<Scalars['String']['output']>;
};


export type LeadImportHistoryUploaded_LeadsArgs = {
  after?: InputMaybe<LeadWhereUniqueInput>;
  before?: InputMaybe<LeadWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type LeadImportHistoryCreateManyOrganizationInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  duplicate_report_url?: InputMaybe<Scalars['String']['input']>;
  error_report_url?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  is_resolved?: InputMaybe<Scalars['Boolean']['input']>;
  lead_id?: InputMaybe<Scalars['String']['input']>;
  leads_added?: InputMaybe<Scalars['Int']['input']>;
  leads_errored?: InputMaybe<Scalars['Int']['input']>;
  leads_on_warning?: InputMaybe<Scalars['Int']['input']>;
  leads_queued?: InputMaybe<Scalars['Int']['input']>;
  leads_updated?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<LeadImportType>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  upload_status?: InputMaybe<LeadUploadStatus>;
  user_id?: InputMaybe<Scalars['String']['input']>;
};

export type LeadImportHistoryCreateManyOrganizationInputEnvelope = {
  data?: InputMaybe<Array<LeadImportHistoryCreateManyOrganizationInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type LeadImportHistoryCreateManyUserInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  duplicate_report_url?: InputMaybe<Scalars['String']['input']>;
  error_report_url?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  is_resolved?: InputMaybe<Scalars['Boolean']['input']>;
  lead_id?: InputMaybe<Scalars['String']['input']>;
  leads_added?: InputMaybe<Scalars['Int']['input']>;
  leads_errored?: InputMaybe<Scalars['Int']['input']>;
  leads_on_warning?: InputMaybe<Scalars['Int']['input']>;
  leads_queued?: InputMaybe<Scalars['Int']['input']>;
  leads_updated?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organization_id?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<LeadImportType>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  upload_status?: InputMaybe<LeadUploadStatus>;
};

export type LeadImportHistoryCreateManyUserInputEnvelope = {
  data?: InputMaybe<Array<LeadImportHistoryCreateManyUserInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type LeadImportHistoryCreateNestedManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<LeadImportHistoryWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<LeadImportHistoryCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<LeadImportHistoryCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<LeadImportHistoryCreateManyOrganizationInputEnvelope>;
};

export type LeadImportHistoryCreateNestedManyWithoutUserInput = {
  connect?: InputMaybe<Array<LeadImportHistoryWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<LeadImportHistoryCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<LeadImportHistoryCreateWithoutUserInput>>;
  createMany?: InputMaybe<LeadImportHistoryCreateManyUserInputEnvelope>;
};

export type LeadImportHistoryCreateNestedOneWithoutLeadInput = {
  connect?: InputMaybe<LeadImportHistoryWhereUniqueInput>;
  connectOrCreate?: InputMaybe<LeadImportHistoryCreateOrConnectWithoutLeadInput>;
  create?: InputMaybe<LeadImportHistoryCreateWithoutLeadInput>;
};

export type LeadImportHistoryCreateNestedOneWithoutUploaded_LeadsInput = {
  connect?: InputMaybe<LeadImportHistoryWhereUniqueInput>;
  connectOrCreate?: InputMaybe<LeadImportHistoryCreateOrConnectWithoutUploaded_LeadsInput>;
  create?: InputMaybe<LeadImportHistoryCreateWithoutUploaded_LeadsInput>;
};

export type LeadImportHistoryCreateOrConnectWithoutLeadInput = {
  create: LeadImportHistoryCreateWithoutLeadInput;
  where: LeadImportHistoryWhereUniqueInput;
};

export type LeadImportHistoryCreateOrConnectWithoutOrganizationInput = {
  create: LeadImportHistoryCreateWithoutOrganizationInput;
  where: LeadImportHistoryWhereUniqueInput;
};

export type LeadImportHistoryCreateOrConnectWithoutUploaded_LeadsInput = {
  create: LeadImportHistoryCreateWithoutUploaded_LeadsInput;
  where: LeadImportHistoryWhereUniqueInput;
};

export type LeadImportHistoryCreateOrConnectWithoutUserInput = {
  create: LeadImportHistoryCreateWithoutUserInput;
  where: LeadImportHistoryWhereUniqueInput;
};

export type LeadImportHistoryCreateWithoutLeadInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  duplicate_report_url?: InputMaybe<Scalars['String']['input']>;
  error_report_url?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  is_resolved?: InputMaybe<Scalars['Boolean']['input']>;
  leads_added?: InputMaybe<Scalars['Int']['input']>;
  leads_errored?: InputMaybe<Scalars['Int']['input']>;
  leads_on_warning?: InputMaybe<Scalars['Int']['input']>;
  leads_queued?: InputMaybe<Scalars['Int']['input']>;
  leads_updated?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organization?: InputMaybe<OrganizationCreateNestedOneWithoutLead_Import_HistoryInput>;
  type?: InputMaybe<LeadImportType>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  upload_status?: InputMaybe<LeadUploadStatus>;
  uploaded_leads?: InputMaybe<LeadCreateNestedManyWithoutLead_Import_History_CsvInput>;
  user?: InputMaybe<UserCreateNestedOneWithoutLead_Import_HistoryInput>;
};

export type LeadImportHistoryCreateWithoutOrganizationInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  duplicate_report_url?: InputMaybe<Scalars['String']['input']>;
  error_report_url?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  is_resolved?: InputMaybe<Scalars['Boolean']['input']>;
  lead?: InputMaybe<LeadCreateNestedOneWithoutLead_Import_HistoryInput>;
  leads_added?: InputMaybe<Scalars['Int']['input']>;
  leads_errored?: InputMaybe<Scalars['Int']['input']>;
  leads_on_warning?: InputMaybe<Scalars['Int']['input']>;
  leads_queued?: InputMaybe<Scalars['Int']['input']>;
  leads_updated?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<LeadImportType>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  upload_status?: InputMaybe<LeadUploadStatus>;
  uploaded_leads?: InputMaybe<LeadCreateNestedManyWithoutLead_Import_History_CsvInput>;
  user?: InputMaybe<UserCreateNestedOneWithoutLead_Import_HistoryInput>;
};

export type LeadImportHistoryCreateWithoutUploaded_LeadsInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  duplicate_report_url?: InputMaybe<Scalars['String']['input']>;
  error_report_url?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  is_resolved?: InputMaybe<Scalars['Boolean']['input']>;
  lead?: InputMaybe<LeadCreateNestedOneWithoutLead_Import_HistoryInput>;
  leads_added?: InputMaybe<Scalars['Int']['input']>;
  leads_errored?: InputMaybe<Scalars['Int']['input']>;
  leads_on_warning?: InputMaybe<Scalars['Int']['input']>;
  leads_queued?: InputMaybe<Scalars['Int']['input']>;
  leads_updated?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organization?: InputMaybe<OrganizationCreateNestedOneWithoutLead_Import_HistoryInput>;
  type?: InputMaybe<LeadImportType>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  upload_status?: InputMaybe<LeadUploadStatus>;
  user?: InputMaybe<UserCreateNestedOneWithoutLead_Import_HistoryInput>;
};

export type LeadImportHistoryCreateWithoutUserInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  duplicate_report_url?: InputMaybe<Scalars['String']['input']>;
  error_report_url?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  is_resolved?: InputMaybe<Scalars['Boolean']['input']>;
  lead?: InputMaybe<LeadCreateNestedOneWithoutLead_Import_HistoryInput>;
  leads_added?: InputMaybe<Scalars['Int']['input']>;
  leads_errored?: InputMaybe<Scalars['Int']['input']>;
  leads_on_warning?: InputMaybe<Scalars['Int']['input']>;
  leads_queued?: InputMaybe<Scalars['Int']['input']>;
  leads_updated?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organization?: InputMaybe<OrganizationCreateNestedOneWithoutLead_Import_HistoryInput>;
  type?: InputMaybe<LeadImportType>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  upload_status?: InputMaybe<LeadUploadStatus>;
  uploaded_leads?: InputMaybe<LeadCreateNestedManyWithoutLead_Import_History_CsvInput>;
};

export type LeadImportHistoryListRelationFilter = {
  every?: InputMaybe<LeadImportHistoryWhereInput>;
  none?: InputMaybe<LeadImportHistoryWhereInput>;
  some?: InputMaybe<LeadImportHistoryWhereInput>;
};

export type LeadImportHistoryOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
  count?: InputMaybe<SortOrder>;
};

export type LeadImportHistoryOrderByWithRelationInput = {
  created_at?: InputMaybe<SortOrder>;
  duplicate_report_url?: InputMaybe<SortOrder>;
  error_report_url?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  is_resolved?: InputMaybe<SortOrder>;
  lead?: InputMaybe<LeadOrderByWithRelationInput>;
  lead_id?: InputMaybe<SortOrder>;
  leads_added?: InputMaybe<SortOrder>;
  leads_errored?: InputMaybe<SortOrder>;
  leads_on_warning?: InputMaybe<SortOrder>;
  leads_queued?: InputMaybe<SortOrder>;
  leads_updated?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  organization?: InputMaybe<OrganizationOrderByWithRelationInput>;
  organization_id?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
  upload_status?: InputMaybe<SortOrder>;
  uploaded_leads?: InputMaybe<LeadOrderByRelationAggregateInput>;
  user?: InputMaybe<UserOrderByWithRelationInput>;
  user_id?: InputMaybe<SortOrder>;
};

export type LeadImportHistoryScalarWhereInput = {
  AND?: InputMaybe<Array<LeadImportHistoryScalarWhereInput>>;
  created_at?: InputMaybe<DateTimeFilter>;
  duplicate_report_url?: InputMaybe<StringNullableFilter>;
  error_report_url?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  is_resolved?: InputMaybe<BoolFilter>;
  lead_id?: InputMaybe<StringNullableFilter>;
  leads_added?: InputMaybe<IntNullableFilter>;
  leads_errored?: InputMaybe<IntNullableFilter>;
  leads_on_warning?: InputMaybe<IntNullableFilter>;
  leads_queued?: InputMaybe<IntNullableFilter>;
  leads_updated?: InputMaybe<IntNullableFilter>;
  name?: InputMaybe<StringNullableFilter>;
  NOT?: InputMaybe<Array<LeadImportHistoryScalarWhereInput>>;
  OR?: InputMaybe<Array<LeadImportHistoryScalarWhereInput>>;
  organization_id?: InputMaybe<StringNullableFilter>;
  type?: InputMaybe<EnumLeadImportTypeFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
  upload_status?: InputMaybe<EnumLeadUploadStatusFilter>;
  user_id?: InputMaybe<StringNullableFilter>;
};

export type LeadImportHistoryUpdateManyMutationInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  duplicate_report_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  error_report_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  is_resolved?: InputMaybe<BoolFieldUpdateOperationsInput>;
  leads_added?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  leads_errored?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  leads_on_warning?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  leads_queued?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  leads_updated?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumLeadImportTypeFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  upload_status?: InputMaybe<EnumLeadUploadStatusFieldUpdateOperationsInput>;
};

export type LeadImportHistoryUpdateManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<LeadImportHistoryWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<LeadImportHistoryCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<LeadImportHistoryCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<LeadImportHistoryCreateManyOrganizationInputEnvelope>;
  delete?: InputMaybe<Array<LeadImportHistoryWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<LeadImportHistoryScalarWhereInput>>;
  disconnect?: InputMaybe<Array<LeadImportHistoryWhereUniqueInput>>;
  set?: InputMaybe<Array<LeadImportHistoryWhereUniqueInput>>;
  update?: InputMaybe<Array<LeadImportHistoryUpdateWithWhereUniqueWithoutOrganizationInput>>;
  updateMany?: InputMaybe<Array<LeadImportHistoryUpdateManyWithWhereWithoutOrganizationInput>>;
  upsert?: InputMaybe<Array<LeadImportHistoryUpsertWithWhereUniqueWithoutOrganizationInput>>;
};

export type LeadImportHistoryUpdateManyWithoutUserInput = {
  connect?: InputMaybe<Array<LeadImportHistoryWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<LeadImportHistoryCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<LeadImportHistoryCreateWithoutUserInput>>;
  createMany?: InputMaybe<LeadImportHistoryCreateManyUserInputEnvelope>;
  delete?: InputMaybe<Array<LeadImportHistoryWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<LeadImportHistoryScalarWhereInput>>;
  disconnect?: InputMaybe<Array<LeadImportHistoryWhereUniqueInput>>;
  set?: InputMaybe<Array<LeadImportHistoryWhereUniqueInput>>;
  update?: InputMaybe<Array<LeadImportHistoryUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: InputMaybe<Array<LeadImportHistoryUpdateManyWithWhereWithoutUserInput>>;
  upsert?: InputMaybe<Array<LeadImportHistoryUpsertWithWhereUniqueWithoutUserInput>>;
};

export type LeadImportHistoryUpdateManyWithWhereWithoutOrganizationInput = {
  data: LeadImportHistoryUpdateManyMutationInput;
  where: LeadImportHistoryScalarWhereInput;
};

export type LeadImportHistoryUpdateManyWithWhereWithoutUserInput = {
  data: LeadImportHistoryUpdateManyMutationInput;
  where: LeadImportHistoryScalarWhereInput;
};

export type LeadImportHistoryUpdateOneWithoutLeadInput = {
  connect?: InputMaybe<LeadImportHistoryWhereUniqueInput>;
  connectOrCreate?: InputMaybe<LeadImportHistoryCreateOrConnectWithoutLeadInput>;
  create?: InputMaybe<LeadImportHistoryCreateWithoutLeadInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<LeadImportHistoryUpdateWithoutLeadInput>;
  upsert?: InputMaybe<LeadImportHistoryUpsertWithoutLeadInput>;
};

export type LeadImportHistoryUpdateOneWithoutUploaded_LeadsInput = {
  connect?: InputMaybe<LeadImportHistoryWhereUniqueInput>;
  connectOrCreate?: InputMaybe<LeadImportHistoryCreateOrConnectWithoutUploaded_LeadsInput>;
  create?: InputMaybe<LeadImportHistoryCreateWithoutUploaded_LeadsInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<LeadImportHistoryUpdateWithoutUploaded_LeadsInput>;
  upsert?: InputMaybe<LeadImportHistoryUpsertWithoutUploaded_LeadsInput>;
};

export type LeadImportHistoryUpdateWithoutLeadInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  duplicate_report_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  error_report_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  is_resolved?: InputMaybe<BoolFieldUpdateOperationsInput>;
  leads_added?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  leads_errored?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  leads_on_warning?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  leads_queued?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  leads_updated?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneWithoutLead_Import_HistoryInput>;
  type?: InputMaybe<EnumLeadImportTypeFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  upload_status?: InputMaybe<EnumLeadUploadStatusFieldUpdateOperationsInput>;
  uploaded_leads?: InputMaybe<LeadUpdateManyWithoutLead_Import_History_CsvInput>;
  user?: InputMaybe<UserUpdateOneWithoutLead_Import_HistoryInput>;
};

export type LeadImportHistoryUpdateWithoutOrganizationInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  duplicate_report_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  error_report_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  is_resolved?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lead?: InputMaybe<LeadUpdateOneWithoutLead_Import_HistoryInput>;
  leads_added?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  leads_errored?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  leads_on_warning?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  leads_queued?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  leads_updated?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumLeadImportTypeFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  upload_status?: InputMaybe<EnumLeadUploadStatusFieldUpdateOperationsInput>;
  uploaded_leads?: InputMaybe<LeadUpdateManyWithoutLead_Import_History_CsvInput>;
  user?: InputMaybe<UserUpdateOneWithoutLead_Import_HistoryInput>;
};

export type LeadImportHistoryUpdateWithoutUploaded_LeadsInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  duplicate_report_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  error_report_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  is_resolved?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lead?: InputMaybe<LeadUpdateOneWithoutLead_Import_HistoryInput>;
  leads_added?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  leads_errored?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  leads_on_warning?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  leads_queued?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  leads_updated?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneWithoutLead_Import_HistoryInput>;
  type?: InputMaybe<EnumLeadImportTypeFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  upload_status?: InputMaybe<EnumLeadUploadStatusFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneWithoutLead_Import_HistoryInput>;
};

export type LeadImportHistoryUpdateWithoutUserInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  duplicate_report_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  error_report_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  is_resolved?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lead?: InputMaybe<LeadUpdateOneWithoutLead_Import_HistoryInput>;
  leads_added?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  leads_errored?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  leads_on_warning?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  leads_queued?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  leads_updated?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneWithoutLead_Import_HistoryInput>;
  type?: InputMaybe<EnumLeadImportTypeFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  upload_status?: InputMaybe<EnumLeadUploadStatusFieldUpdateOperationsInput>;
  uploaded_leads?: InputMaybe<LeadUpdateManyWithoutLead_Import_History_CsvInput>;
};

export type LeadImportHistoryUpdateWithWhereUniqueWithoutOrganizationInput = {
  data: LeadImportHistoryUpdateWithoutOrganizationInput;
  where: LeadImportHistoryWhereUniqueInput;
};

export type LeadImportHistoryUpdateWithWhereUniqueWithoutUserInput = {
  data: LeadImportHistoryUpdateWithoutUserInput;
  where: LeadImportHistoryWhereUniqueInput;
};

export type LeadImportHistoryUpsertWithoutLeadInput = {
  create: LeadImportHistoryCreateWithoutLeadInput;
  update: LeadImportHistoryUpdateWithoutLeadInput;
};

export type LeadImportHistoryUpsertWithoutUploaded_LeadsInput = {
  create: LeadImportHistoryCreateWithoutUploaded_LeadsInput;
  update: LeadImportHistoryUpdateWithoutUploaded_LeadsInput;
};

export type LeadImportHistoryUpsertWithWhereUniqueWithoutOrganizationInput = {
  create: LeadImportHistoryCreateWithoutOrganizationInput;
  update: LeadImportHistoryUpdateWithoutOrganizationInput;
  where: LeadImportHistoryWhereUniqueInput;
};

export type LeadImportHistoryUpsertWithWhereUniqueWithoutUserInput = {
  create: LeadImportHistoryCreateWithoutUserInput;
  update: LeadImportHistoryUpdateWithoutUserInput;
  where: LeadImportHistoryWhereUniqueInput;
};

export type LeadImportHistoryWhereInput = {
  AND?: InputMaybe<Array<LeadImportHistoryWhereInput>>;
  created_at?: InputMaybe<DateTimeFilter>;
  duplicate_report_url?: InputMaybe<StringNullableFilter>;
  error_report_url?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  is_resolved?: InputMaybe<BoolFilter>;
  lead?: InputMaybe<LeadWhereInput>;
  lead_id?: InputMaybe<StringNullableFilter>;
  leads_added?: InputMaybe<IntNullableFilter>;
  leads_errored?: InputMaybe<IntNullableFilter>;
  leads_on_warning?: InputMaybe<IntNullableFilter>;
  leads_queued?: InputMaybe<IntNullableFilter>;
  leads_updated?: InputMaybe<IntNullableFilter>;
  name?: InputMaybe<StringNullableFilter>;
  NOT?: InputMaybe<Array<LeadImportHistoryWhereInput>>;
  OR?: InputMaybe<Array<LeadImportHistoryWhereInput>>;
  organization?: InputMaybe<OrganizationWhereInput>;
  organization_id?: InputMaybe<StringNullableFilter>;
  type?: InputMaybe<EnumLeadImportTypeFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
  upload_status?: InputMaybe<EnumLeadUploadStatusFilter>;
  uploaded_leads?: InputMaybe<LeadListRelationFilter>;
  user?: InputMaybe<UserWhereInput>;
  user_id?: InputMaybe<StringNullableFilter>;
};

export type LeadImportHistoryWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  lead_id?: InputMaybe<Scalars['String']['input']>;
};

export type LeadImportProgress = {
  __typename?: 'LeadImportProgress';
  denominator: Scalars['Int']['output'];
  filename: Scalars['String']['output'];
  id: Scalars['String']['output'];
  message: Scalars['String']['output'];
  numerator: Scalars['Int']['output'];
  progress: Scalars['Int']['output'];
  userid: Scalars['String']['output'];
  username: Scalars['String']['output'];
};

export enum LeadImportType {
  Csv = 'CSV',
  HubspotCallImport = 'HubspotCallImport',
  HubspotContactImport = 'HubspotContactImport',
  Individual = 'Individual',
  LeadActivityCsv = 'LeadActivityCSV'
}

/** Leads in queue query */
export enum LeadInSequenceQuery {
  Active = 'active',
  All = 'all',
  Completed = 'completed',
  Converted = 'converted',
  NotInterested = 'not_interested',
  SystemExit = 'system_exit'
}

export type LeadIntent = {
  __typename?: 'LeadIntent';
  anytime_after?: Maybe<Scalars['Float']['output']>;
  anytime_before?: Maybe<Scalars['Float']['output']>;
  anytime_day?: Maybe<Scalars['DateTime']['output']>;
  anytime_day_upperbound?: Maybe<Scalars['DateTime']['output']>;
  anytime_tz?: Maybe<Scalars['String']['output']>;
  associate_parent?: Maybe<Lead>;
  associate_parent_id?: Maybe<Scalars['String']['output']>;
  associated_action?: Maybe<Scalars['String']['output']>;
  created_at: Scalars['DateTime']['output'];
  dial_num_in_sales_cycle?: Maybe<Scalars['Int']['output']>;
  /** Type of the event in label format */
  event_type_label?: Maybe<Scalars['String']['output']>;
  current_sequence_step?: Maybe<Scalars['String']['output']>;
  general_time_end_date?: Maybe<Scalars['DateTime']['output']>;
  general_time_start_date?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['String']['output'];
  /** Return true if it's scheduled event. False otherwise */
  is_scheduled_item?: Maybe<Scalars['Boolean']['output']>;
  /** Label of the intent. ${name}: ${type} */
  label?: Maybe<Scalars['String']['output']>;
  lead: Lead;
  lead_id: Scalars['String']['output'];
  organization: Organization;
  organization_id: Scalars['String']['output'];
  prepare?: Maybe<Scalars['Boolean']['output']>;
  prepare_raw?: Maybe<Scalars['Boolean']['output']>;
  /** Returns number of previous dials made in the sales cycle */
  prev_dials_in_sales_cycle?: Maybe<Scalars['Int']['output']>;
  ranking?: Maybe<Scalars['Int']['output']>;
  replacement_lead_intent?: Maybe<LeadIntent>;
  replacement_lead_intent_id?: Maybe<Scalars['String']['output']>;
  replacing_lead_intent?: Maybe<LeadIntent>;
  schedule_item?: Maybe<ScheduleItem>;
  schedule_item_id?: Maybe<Scalars['String']['output']>;
  /**
   * Determines whether the action buttons are seen below the lead card.
   *       They should only appear if the user is preparing for their next scheduled call.
   *       These buttons allow the user to switch back to the scheduled call back if they
   *       clicked on make a cold call first. The use can make a non-scheduled call back as well.
   */
  show_action_buttons?: Maybe<Scalars['Boolean']['output']>;
  show_during_scheduled_event_duration?: Maybe<Scalars['Boolean']['output']>;
  type?: Maybe<IntentType>;
  /** Type of intent in label form */
  type_label?: Maybe<Scalars['String']['output']>;
  updated_at: Scalars['DateTime']['output'];
  user?: Maybe<User>;
  user_id?: Maybe<Scalars['String']['output']>;
};

export type LeadIntentCreateManyAssociate_ParentInput = {
  anytime_after?: InputMaybe<Scalars['Float']['input']>;
  anytime_before?: InputMaybe<Scalars['Float']['input']>;
  anytime_day?: InputMaybe<Scalars['DateTime']['input']>;
  anytime_day_upperbound?: InputMaybe<Scalars['DateTime']['input']>;
  anytime_tz?: InputMaybe<Scalars['String']['input']>;
  associated_action?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  lead_id: Scalars['String']['input'];
  lead_value?: InputMaybe<Scalars['Float']['input']>;
  organization_id: Scalars['String']['input'];
  pipeline_state_int?: InputMaybe<Scalars['Int']['input']>;
  prepare_raw?: InputMaybe<Scalars['Boolean']['input']>;
  ranking?: InputMaybe<Scalars['Int']['input']>;
  replacement_lead_intent_id?: InputMaybe<Scalars['String']['input']>;
  schedule_item_id?: InputMaybe<Scalars['String']['input']>;
  show_during_scheduled_event_duration?: InputMaybe<Scalars['Boolean']['input']>;
  type?: InputMaybe<IntentType>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
};

export type LeadIntentCreateManyAssociate_ParentInputEnvelope = {
  data?: InputMaybe<Array<LeadIntentCreateManyAssociate_ParentInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type LeadIntentCreateManyLeadInput = {
  anytime_after?: InputMaybe<Scalars['Float']['input']>;
  anytime_before?: InputMaybe<Scalars['Float']['input']>;
  anytime_day?: InputMaybe<Scalars['DateTime']['input']>;
  anytime_day_upperbound?: InputMaybe<Scalars['DateTime']['input']>;
  anytime_tz?: InputMaybe<Scalars['String']['input']>;
  associate_parent_id?: InputMaybe<Scalars['String']['input']>;
  associated_action?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  lead_value?: InputMaybe<Scalars['Float']['input']>;
  organization_id: Scalars['String']['input'];
  pipeline_state_int?: InputMaybe<Scalars['Int']['input']>;
  prepare_raw?: InputMaybe<Scalars['Boolean']['input']>;
  ranking?: InputMaybe<Scalars['Int']['input']>;
  replacement_lead_intent_id?: InputMaybe<Scalars['String']['input']>;
  schedule_item_id?: InputMaybe<Scalars['String']['input']>;
  show_during_scheduled_event_duration?: InputMaybe<Scalars['Boolean']['input']>;
  type?: InputMaybe<IntentType>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
};

export type LeadIntentCreateManyLeadInputEnvelope = {
  data?: InputMaybe<Array<LeadIntentCreateManyLeadInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type LeadIntentCreateManyOrganizationInput = {
  anytime_after?: InputMaybe<Scalars['Float']['input']>;
  anytime_before?: InputMaybe<Scalars['Float']['input']>;
  anytime_day?: InputMaybe<Scalars['DateTime']['input']>;
  anytime_day_upperbound?: InputMaybe<Scalars['DateTime']['input']>;
  anytime_tz?: InputMaybe<Scalars['String']['input']>;
  associate_parent_id?: InputMaybe<Scalars['String']['input']>;
  associated_action?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  lead_id: Scalars['String']['input'];
  lead_value?: InputMaybe<Scalars['Float']['input']>;
  pipeline_state_int?: InputMaybe<Scalars['Int']['input']>;
  prepare_raw?: InputMaybe<Scalars['Boolean']['input']>;
  ranking?: InputMaybe<Scalars['Int']['input']>;
  replacement_lead_intent_id?: InputMaybe<Scalars['String']['input']>;
  schedule_item_id?: InputMaybe<Scalars['String']['input']>;
  show_during_scheduled_event_duration?: InputMaybe<Scalars['Boolean']['input']>;
  type?: InputMaybe<IntentType>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
};

export type LeadIntentCreateManyOrganizationInputEnvelope = {
  data?: InputMaybe<Array<LeadIntentCreateManyOrganizationInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type LeadIntentCreateManyUserInput = {
  anytime_after?: InputMaybe<Scalars['Float']['input']>;
  anytime_before?: InputMaybe<Scalars['Float']['input']>;
  anytime_day?: InputMaybe<Scalars['DateTime']['input']>;
  anytime_day_upperbound?: InputMaybe<Scalars['DateTime']['input']>;
  anytime_tz?: InputMaybe<Scalars['String']['input']>;
  associate_parent_id?: InputMaybe<Scalars['String']['input']>;
  associated_action?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  lead_id: Scalars['String']['input'];
  lead_value?: InputMaybe<Scalars['Float']['input']>;
  organization_id: Scalars['String']['input'];
  pipeline_state_int?: InputMaybe<Scalars['Int']['input']>;
  prepare_raw?: InputMaybe<Scalars['Boolean']['input']>;
  ranking?: InputMaybe<Scalars['Int']['input']>;
  replacement_lead_intent_id?: InputMaybe<Scalars['String']['input']>;
  schedule_item_id?: InputMaybe<Scalars['String']['input']>;
  show_during_scheduled_event_duration?: InputMaybe<Scalars['Boolean']['input']>;
  type?: InputMaybe<IntentType>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type LeadIntentCreateManyUserInputEnvelope = {
  data?: InputMaybe<Array<LeadIntentCreateManyUserInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type LeadIntentCreateNestedManyWithoutAssociate_ParentInput = {
  connect?: InputMaybe<Array<LeadIntentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<LeadIntentCreateOrConnectWithoutAssociate_ParentInput>>;
  create?: InputMaybe<Array<LeadIntentCreateWithoutAssociate_ParentInput>>;
  createMany?: InputMaybe<LeadIntentCreateManyAssociate_ParentInputEnvelope>;
};

export type LeadIntentCreateNestedManyWithoutLeadInput = {
  connect?: InputMaybe<Array<LeadIntentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<LeadIntentCreateOrConnectWithoutLeadInput>>;
  create?: InputMaybe<Array<LeadIntentCreateWithoutLeadInput>>;
  createMany?: InputMaybe<LeadIntentCreateManyLeadInputEnvelope>;
};

export type LeadIntentCreateNestedManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<LeadIntentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<LeadIntentCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<LeadIntentCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<LeadIntentCreateManyOrganizationInputEnvelope>;
};

export type LeadIntentCreateNestedManyWithoutUserInput = {
  connect?: InputMaybe<Array<LeadIntentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<LeadIntentCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<LeadIntentCreateWithoutUserInput>>;
  createMany?: InputMaybe<LeadIntentCreateManyUserInputEnvelope>;
};

export type LeadIntentCreateNestedOneWithoutReplacement_Lead_IntentInput = {
  connect?: InputMaybe<LeadIntentWhereUniqueInput>;
  connectOrCreate?: InputMaybe<LeadIntentCreateOrConnectWithoutReplacement_Lead_IntentInput>;
  create?: InputMaybe<LeadIntentCreateWithoutReplacement_Lead_IntentInput>;
};

export type LeadIntentCreateNestedOneWithoutReplacing_Lead_IntentInput = {
  connect?: InputMaybe<LeadIntentWhereUniqueInput>;
  connectOrCreate?: InputMaybe<LeadIntentCreateOrConnectWithoutReplacing_Lead_IntentInput>;
  create?: InputMaybe<LeadIntentCreateWithoutReplacing_Lead_IntentInput>;
};

export type LeadIntentCreateNestedOneWithoutSchedule_ItemInput = {
  connect?: InputMaybe<LeadIntentWhereUniqueInput>;
  connectOrCreate?: InputMaybe<LeadIntentCreateOrConnectWithoutSchedule_ItemInput>;
  create?: InputMaybe<LeadIntentCreateWithoutSchedule_ItemInput>;
};

export type LeadIntentCreateNestedOneWithoutStepActionLogInput = {
  connect?: InputMaybe<LeadIntentWhereUniqueInput>;
  connectOrCreate?: InputMaybe<LeadIntentCreateOrConnectWithoutStepActionLogInput>;
  create?: InputMaybe<LeadIntentCreateWithoutStepActionLogInput>;
};

export type LeadIntentCreateOrConnectWithoutAssociate_ParentInput = {
  create: LeadIntentCreateWithoutAssociate_ParentInput;
  where: LeadIntentWhereUniqueInput;
};

export type LeadIntentCreateOrConnectWithoutLeadInput = {
  create: LeadIntentCreateWithoutLeadInput;
  where: LeadIntentWhereUniqueInput;
};

export type LeadIntentCreateOrConnectWithoutOrganizationInput = {
  create: LeadIntentCreateWithoutOrganizationInput;
  where: LeadIntentWhereUniqueInput;
};

export type LeadIntentCreateOrConnectWithoutReplacement_Lead_IntentInput = {
  create: LeadIntentCreateWithoutReplacement_Lead_IntentInput;
  where: LeadIntentWhereUniqueInput;
};

export type LeadIntentCreateOrConnectWithoutReplacing_Lead_IntentInput = {
  create: LeadIntentCreateWithoutReplacing_Lead_IntentInput;
  where: LeadIntentWhereUniqueInput;
};

export type LeadIntentCreateOrConnectWithoutSchedule_ItemInput = {
  create: LeadIntentCreateWithoutSchedule_ItemInput;
  where: LeadIntentWhereUniqueInput;
};

export type LeadIntentCreateOrConnectWithoutStepActionLogInput = {
  create: LeadIntentCreateWithoutStepActionLogInput;
  where: LeadIntentWhereUniqueInput;
};

export type LeadIntentCreateOrConnectWithoutUserInput = {
  create: LeadIntentCreateWithoutUserInput;
  where: LeadIntentWhereUniqueInput;
};

export type LeadIntentCreateWithoutAssociate_ParentInput = {
  anytime_after?: InputMaybe<Scalars['Float']['input']>;
  anytime_before?: InputMaybe<Scalars['Float']['input']>;
  anytime_day?: InputMaybe<Scalars['DateTime']['input']>;
  anytime_day_upperbound?: InputMaybe<Scalars['DateTime']['input']>;
  anytime_tz?: InputMaybe<Scalars['String']['input']>;
  associated_action?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  lead: LeadCreateNestedOneWithoutLead_IntentInput;
  lead_value?: InputMaybe<Scalars['Float']['input']>;
  organization: OrganizationCreateNestedOneWithoutLead_IntentInput;
  pipeline_state_int?: InputMaybe<Scalars['Int']['input']>;
  prepare_raw?: InputMaybe<Scalars['Boolean']['input']>;
  ranking?: InputMaybe<Scalars['Int']['input']>;
  replacement_lead_intent?: InputMaybe<LeadIntentCreateNestedOneWithoutReplacing_Lead_IntentInput>;
  replacing_lead_intent?: InputMaybe<LeadIntentCreateNestedOneWithoutReplacement_Lead_IntentInput>;
  schedule_item?: InputMaybe<ScheduleItemCreateNestedOneWithoutLead_IntentInput>;
  show_during_scheduled_event_duration?: InputMaybe<Scalars['Boolean']['input']>;
  StepActionLog?: InputMaybe<StepActionLogCreateNestedOneWithoutLeadIntentInput>;
  type?: InputMaybe<IntentType>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user?: InputMaybe<UserCreateNestedOneWithoutLead_IntentsInput>;
};

export type LeadIntentCreateWithoutLeadInput = {
  anytime_after?: InputMaybe<Scalars['Float']['input']>;
  anytime_before?: InputMaybe<Scalars['Float']['input']>;
  anytime_day?: InputMaybe<Scalars['DateTime']['input']>;
  anytime_day_upperbound?: InputMaybe<Scalars['DateTime']['input']>;
  anytime_tz?: InputMaybe<Scalars['String']['input']>;
  associate_parent?: InputMaybe<LeadCreateNestedOneWithoutAssociated_Parent_IntentsInput>;
  associated_action?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  lead_value?: InputMaybe<Scalars['Float']['input']>;
  organization: OrganizationCreateNestedOneWithoutLead_IntentInput;
  pipeline_state_int?: InputMaybe<Scalars['Int']['input']>;
  prepare_raw?: InputMaybe<Scalars['Boolean']['input']>;
  ranking?: InputMaybe<Scalars['Int']['input']>;
  replacement_lead_intent?: InputMaybe<LeadIntentCreateNestedOneWithoutReplacing_Lead_IntentInput>;
  replacing_lead_intent?: InputMaybe<LeadIntentCreateNestedOneWithoutReplacement_Lead_IntentInput>;
  schedule_item?: InputMaybe<ScheduleItemCreateNestedOneWithoutLead_IntentInput>;
  show_during_scheduled_event_duration?: InputMaybe<Scalars['Boolean']['input']>;
  StepActionLog?: InputMaybe<StepActionLogCreateNestedOneWithoutLeadIntentInput>;
  type?: InputMaybe<IntentType>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user?: InputMaybe<UserCreateNestedOneWithoutLead_IntentsInput>;
};

export type LeadIntentCreateWithoutOrganizationInput = {
  anytime_after?: InputMaybe<Scalars['Float']['input']>;
  anytime_before?: InputMaybe<Scalars['Float']['input']>;
  anytime_day?: InputMaybe<Scalars['DateTime']['input']>;
  anytime_day_upperbound?: InputMaybe<Scalars['DateTime']['input']>;
  anytime_tz?: InputMaybe<Scalars['String']['input']>;
  associate_parent?: InputMaybe<LeadCreateNestedOneWithoutAssociated_Parent_IntentsInput>;
  associated_action?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  lead: LeadCreateNestedOneWithoutLead_IntentInput;
  lead_value?: InputMaybe<Scalars['Float']['input']>;
  pipeline_state_int?: InputMaybe<Scalars['Int']['input']>;
  prepare_raw?: InputMaybe<Scalars['Boolean']['input']>;
  ranking?: InputMaybe<Scalars['Int']['input']>;
  replacement_lead_intent?: InputMaybe<LeadIntentCreateNestedOneWithoutReplacing_Lead_IntentInput>;
  replacing_lead_intent?: InputMaybe<LeadIntentCreateNestedOneWithoutReplacement_Lead_IntentInput>;
  schedule_item?: InputMaybe<ScheduleItemCreateNestedOneWithoutLead_IntentInput>;
  show_during_scheduled_event_duration?: InputMaybe<Scalars['Boolean']['input']>;
  StepActionLog?: InputMaybe<StepActionLogCreateNestedOneWithoutLeadIntentInput>;
  type?: InputMaybe<IntentType>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user?: InputMaybe<UserCreateNestedOneWithoutLead_IntentsInput>;
};

export type LeadIntentCreateWithoutReplacement_Lead_IntentInput = {
  anytime_after?: InputMaybe<Scalars['Float']['input']>;
  anytime_before?: InputMaybe<Scalars['Float']['input']>;
  anytime_day?: InputMaybe<Scalars['DateTime']['input']>;
  anytime_day_upperbound?: InputMaybe<Scalars['DateTime']['input']>;
  anytime_tz?: InputMaybe<Scalars['String']['input']>;
  associate_parent?: InputMaybe<LeadCreateNestedOneWithoutAssociated_Parent_IntentsInput>;
  associated_action?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  lead: LeadCreateNestedOneWithoutLead_IntentInput;
  lead_value?: InputMaybe<Scalars['Float']['input']>;
  organization: OrganizationCreateNestedOneWithoutLead_IntentInput;
  pipeline_state_int?: InputMaybe<Scalars['Int']['input']>;
  prepare_raw?: InputMaybe<Scalars['Boolean']['input']>;
  ranking?: InputMaybe<Scalars['Int']['input']>;
  replacing_lead_intent?: InputMaybe<LeadIntentCreateNestedOneWithoutReplacement_Lead_IntentInput>;
  schedule_item?: InputMaybe<ScheduleItemCreateNestedOneWithoutLead_IntentInput>;
  show_during_scheduled_event_duration?: InputMaybe<Scalars['Boolean']['input']>;
  StepActionLog?: InputMaybe<StepActionLogCreateNestedOneWithoutLeadIntentInput>;
  type?: InputMaybe<IntentType>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user?: InputMaybe<UserCreateNestedOneWithoutLead_IntentsInput>;
};

export type LeadIntentCreateWithoutReplacing_Lead_IntentInput = {
  anytime_after?: InputMaybe<Scalars['Float']['input']>;
  anytime_before?: InputMaybe<Scalars['Float']['input']>;
  anytime_day?: InputMaybe<Scalars['DateTime']['input']>;
  anytime_day_upperbound?: InputMaybe<Scalars['DateTime']['input']>;
  anytime_tz?: InputMaybe<Scalars['String']['input']>;
  associate_parent?: InputMaybe<LeadCreateNestedOneWithoutAssociated_Parent_IntentsInput>;
  associated_action?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  lead: LeadCreateNestedOneWithoutLead_IntentInput;
  lead_value?: InputMaybe<Scalars['Float']['input']>;
  organization: OrganizationCreateNestedOneWithoutLead_IntentInput;
  pipeline_state_int?: InputMaybe<Scalars['Int']['input']>;
  prepare_raw?: InputMaybe<Scalars['Boolean']['input']>;
  ranking?: InputMaybe<Scalars['Int']['input']>;
  replacement_lead_intent?: InputMaybe<LeadIntentCreateNestedOneWithoutReplacing_Lead_IntentInput>;
  schedule_item?: InputMaybe<ScheduleItemCreateNestedOneWithoutLead_IntentInput>;
  show_during_scheduled_event_duration?: InputMaybe<Scalars['Boolean']['input']>;
  StepActionLog?: InputMaybe<StepActionLogCreateNestedOneWithoutLeadIntentInput>;
  type?: InputMaybe<IntentType>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user?: InputMaybe<UserCreateNestedOneWithoutLead_IntentsInput>;
};

export type LeadIntentCreateWithoutSchedule_ItemInput = {
  anytime_after?: InputMaybe<Scalars['Float']['input']>;
  anytime_before?: InputMaybe<Scalars['Float']['input']>;
  anytime_day?: InputMaybe<Scalars['DateTime']['input']>;
  anytime_day_upperbound?: InputMaybe<Scalars['DateTime']['input']>;
  anytime_tz?: InputMaybe<Scalars['String']['input']>;
  associate_parent?: InputMaybe<LeadCreateNestedOneWithoutAssociated_Parent_IntentsInput>;
  associated_action?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  lead: LeadCreateNestedOneWithoutLead_IntentInput;
  lead_value?: InputMaybe<Scalars['Float']['input']>;
  organization: OrganizationCreateNestedOneWithoutLead_IntentInput;
  pipeline_state_int?: InputMaybe<Scalars['Int']['input']>;
  prepare_raw?: InputMaybe<Scalars['Boolean']['input']>;
  ranking?: InputMaybe<Scalars['Int']['input']>;
  replacement_lead_intent?: InputMaybe<LeadIntentCreateNestedOneWithoutReplacing_Lead_IntentInput>;
  replacing_lead_intent?: InputMaybe<LeadIntentCreateNestedOneWithoutReplacement_Lead_IntentInput>;
  show_during_scheduled_event_duration?: InputMaybe<Scalars['Boolean']['input']>;
  StepActionLog?: InputMaybe<StepActionLogCreateNestedOneWithoutLeadIntentInput>;
  type?: InputMaybe<IntentType>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user?: InputMaybe<UserCreateNestedOneWithoutLead_IntentsInput>;
};

export type LeadIntentCreateWithoutStepActionLogInput = {
  anytime_after?: InputMaybe<Scalars['Float']['input']>;
  anytime_before?: InputMaybe<Scalars['Float']['input']>;
  anytime_day?: InputMaybe<Scalars['DateTime']['input']>;
  anytime_day_upperbound?: InputMaybe<Scalars['DateTime']['input']>;
  anytime_tz?: InputMaybe<Scalars['String']['input']>;
  associate_parent?: InputMaybe<LeadCreateNestedOneWithoutAssociated_Parent_IntentsInput>;
  associated_action?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  lead: LeadCreateNestedOneWithoutLead_IntentInput;
  lead_value?: InputMaybe<Scalars['Float']['input']>;
  organization: OrganizationCreateNestedOneWithoutLead_IntentInput;
  pipeline_state_int?: InputMaybe<Scalars['Int']['input']>;
  prepare_raw?: InputMaybe<Scalars['Boolean']['input']>;
  ranking?: InputMaybe<Scalars['Int']['input']>;
  replacement_lead_intent?: InputMaybe<LeadIntentCreateNestedOneWithoutReplacing_Lead_IntentInput>;
  replacing_lead_intent?: InputMaybe<LeadIntentCreateNestedOneWithoutReplacement_Lead_IntentInput>;
  schedule_item?: InputMaybe<ScheduleItemCreateNestedOneWithoutLead_IntentInput>;
  show_during_scheduled_event_duration?: InputMaybe<Scalars['Boolean']['input']>;
  type?: InputMaybe<IntentType>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user?: InputMaybe<UserCreateNestedOneWithoutLead_IntentsInput>;
};

export type LeadIntentCreateWithoutUserInput = {
  anytime_after?: InputMaybe<Scalars['Float']['input']>;
  anytime_before?: InputMaybe<Scalars['Float']['input']>;
  anytime_day?: InputMaybe<Scalars['DateTime']['input']>;
  anytime_day_upperbound?: InputMaybe<Scalars['DateTime']['input']>;
  anytime_tz?: InputMaybe<Scalars['String']['input']>;
  associate_parent?: InputMaybe<LeadCreateNestedOneWithoutAssociated_Parent_IntentsInput>;
  associated_action?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  lead: LeadCreateNestedOneWithoutLead_IntentInput;
  lead_value?: InputMaybe<Scalars['Float']['input']>;
  organization: OrganizationCreateNestedOneWithoutLead_IntentInput;
  pipeline_state_int?: InputMaybe<Scalars['Int']['input']>;
  prepare_raw?: InputMaybe<Scalars['Boolean']['input']>;
  ranking?: InputMaybe<Scalars['Int']['input']>;
  replacement_lead_intent?: InputMaybe<LeadIntentCreateNestedOneWithoutReplacing_Lead_IntentInput>;
  replacing_lead_intent?: InputMaybe<LeadIntentCreateNestedOneWithoutReplacement_Lead_IntentInput>;
  schedule_item?: InputMaybe<ScheduleItemCreateNestedOneWithoutLead_IntentInput>;
  show_during_scheduled_event_duration?: InputMaybe<Scalars['Boolean']['input']>;
  StepActionLog?: InputMaybe<StepActionLogCreateNestedOneWithoutLeadIntentInput>;
  type?: InputMaybe<IntentType>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

/** Used for select fields */
export type LeadIntentIndustryDetail = {
  __typename?: 'LeadIntentIndustryDetail';
  industry?: Maybe<Scalars['String']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type LeadIntentListRelationFilter = {
  every?: InputMaybe<LeadIntentWhereInput>;
  none?: InputMaybe<LeadIntentWhereInput>;
  some?: InputMaybe<LeadIntentWhereInput>;
};

export type LeadIntentOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
  count?: InputMaybe<SortOrder>;
};

export type LeadIntentOrderByWithRelationInput = {
  anytime_after?: InputMaybe<SortOrder>;
  anytime_before?: InputMaybe<SortOrder>;
  anytime_day?: InputMaybe<SortOrder>;
  anytime_day_upperbound?: InputMaybe<SortOrder>;
  anytime_tz?: InputMaybe<SortOrder>;
  associate_parent?: InputMaybe<LeadOrderByWithRelationInput>;
  associate_parent_id?: InputMaybe<SortOrder>;
  associated_action?: InputMaybe<SortOrder>;
  created_at?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  lead?: InputMaybe<LeadOrderByWithRelationInput>;
  lead_id?: InputMaybe<SortOrder>;
  lead_value?: InputMaybe<SortOrder>;
  organization?: InputMaybe<OrganizationOrderByWithRelationInput>;
  organization_id?: InputMaybe<SortOrder>;
  pipeline_state_int?: InputMaybe<SortOrder>;
  prepare_raw?: InputMaybe<SortOrder>;
  ranking?: InputMaybe<SortOrder>;
  replacement_lead_intent?: InputMaybe<LeadIntentOrderByWithRelationInput>;
  replacement_lead_intent_id?: InputMaybe<SortOrder>;
  replacing_lead_intent?: InputMaybe<LeadIntentOrderByWithRelationInput>;
  schedule_item?: InputMaybe<ScheduleItemOrderByWithRelationInput>;
  schedule_item_id?: InputMaybe<SortOrder>;
  show_during_scheduled_event_duration?: InputMaybe<SortOrder>;
  StepActionLog?: InputMaybe<StepActionLogOrderByWithRelationInput>;
  type?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserOrderByWithRelationInput>;
  user_id?: InputMaybe<SortOrder>;
};

export type LeadIntentScalarWhereInput = {
  AND?: InputMaybe<Array<LeadIntentScalarWhereInput>>;
  anytime_after?: InputMaybe<FloatNullableFilter>;
  anytime_before?: InputMaybe<FloatNullableFilter>;
  anytime_day?: InputMaybe<DateTimeNullableFilter>;
  anytime_day_upperbound?: InputMaybe<DateTimeNullableFilter>;
  anytime_tz?: InputMaybe<StringNullableFilter>;
  associate_parent_id?: InputMaybe<StringNullableFilter>;
  associated_action?: InputMaybe<StringNullableFilter>;
  created_at?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  lead_id?: InputMaybe<StringFilter>;
  lead_value?: InputMaybe<FloatNullableFilter>;
  NOT?: InputMaybe<Array<LeadIntentScalarWhereInput>>;
  OR?: InputMaybe<Array<LeadIntentScalarWhereInput>>;
  organization_id?: InputMaybe<StringFilter>;
  pipeline_state_int?: InputMaybe<IntFilter>;
  prepare_raw?: InputMaybe<BoolNullableFilter>;
  ranking?: InputMaybe<IntNullableFilter>;
  replacement_lead_intent_id?: InputMaybe<StringNullableFilter>;
  schedule_item_id?: InputMaybe<StringNullableFilter>;
  show_during_scheduled_event_duration?: InputMaybe<BoolNullableFilter>;
  type?: InputMaybe<EnumIntentTypeNullableFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
  user_id?: InputMaybe<StringNullableFilter>;
};

/** Used for select fields */
export type LeadIntentStats = {
  __typename?: 'LeadIntentStats';
  industry_stat_details?: Maybe<Array<Maybe<LeadIntentIndustryDetail>>>;
  organization_name?: Maybe<Scalars['String']['output']>;
  user_email?: Maybe<Scalars['String']['output']>;
};

export type LeadIntentUpdateManyMutationInput = {
  anytime_after?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  anytime_before?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  anytime_day?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  anytime_day_upperbound?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  anytime_tz?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  associated_action?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lead_value?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  pipeline_state_int?: InputMaybe<IntFieldUpdateOperationsInput>;
  prepare_raw?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  ranking?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  show_during_scheduled_event_duration?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  type?: InputMaybe<NullableEnumIntentTypeFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type LeadIntentUpdateManyWithoutAssociate_ParentInput = {
  connect?: InputMaybe<Array<LeadIntentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<LeadIntentCreateOrConnectWithoutAssociate_ParentInput>>;
  create?: InputMaybe<Array<LeadIntentCreateWithoutAssociate_ParentInput>>;
  createMany?: InputMaybe<LeadIntentCreateManyAssociate_ParentInputEnvelope>;
  delete?: InputMaybe<Array<LeadIntentWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<LeadIntentScalarWhereInput>>;
  disconnect?: InputMaybe<Array<LeadIntentWhereUniqueInput>>;
  set?: InputMaybe<Array<LeadIntentWhereUniqueInput>>;
  update?: InputMaybe<Array<LeadIntentUpdateWithWhereUniqueWithoutAssociate_ParentInput>>;
  updateMany?: InputMaybe<Array<LeadIntentUpdateManyWithWhereWithoutAssociate_ParentInput>>;
  upsert?: InputMaybe<Array<LeadIntentUpsertWithWhereUniqueWithoutAssociate_ParentInput>>;
};

export type LeadIntentUpdateManyWithoutLeadInput = {
  connect?: InputMaybe<Array<LeadIntentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<LeadIntentCreateOrConnectWithoutLeadInput>>;
  create?: InputMaybe<Array<LeadIntentCreateWithoutLeadInput>>;
  createMany?: InputMaybe<LeadIntentCreateManyLeadInputEnvelope>;
  delete?: InputMaybe<Array<LeadIntentWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<LeadIntentScalarWhereInput>>;
  disconnect?: InputMaybe<Array<LeadIntentWhereUniqueInput>>;
  set?: InputMaybe<Array<LeadIntentWhereUniqueInput>>;
  update?: InputMaybe<Array<LeadIntentUpdateWithWhereUniqueWithoutLeadInput>>;
  updateMany?: InputMaybe<Array<LeadIntentUpdateManyWithWhereWithoutLeadInput>>;
  upsert?: InputMaybe<Array<LeadIntentUpsertWithWhereUniqueWithoutLeadInput>>;
};

export type LeadIntentUpdateManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<LeadIntentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<LeadIntentCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<LeadIntentCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<LeadIntentCreateManyOrganizationInputEnvelope>;
  delete?: InputMaybe<Array<LeadIntentWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<LeadIntentScalarWhereInput>>;
  disconnect?: InputMaybe<Array<LeadIntentWhereUniqueInput>>;
  set?: InputMaybe<Array<LeadIntentWhereUniqueInput>>;
  update?: InputMaybe<Array<LeadIntentUpdateWithWhereUniqueWithoutOrganizationInput>>;
  updateMany?: InputMaybe<Array<LeadIntentUpdateManyWithWhereWithoutOrganizationInput>>;
  upsert?: InputMaybe<Array<LeadIntentUpsertWithWhereUniqueWithoutOrganizationInput>>;
};

export type LeadIntentUpdateManyWithoutUserInput = {
  connect?: InputMaybe<Array<LeadIntentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<LeadIntentCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<LeadIntentCreateWithoutUserInput>>;
  createMany?: InputMaybe<LeadIntentCreateManyUserInputEnvelope>;
  delete?: InputMaybe<Array<LeadIntentWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<LeadIntentScalarWhereInput>>;
  disconnect?: InputMaybe<Array<LeadIntentWhereUniqueInput>>;
  set?: InputMaybe<Array<LeadIntentWhereUniqueInput>>;
  update?: InputMaybe<Array<LeadIntentUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: InputMaybe<Array<LeadIntentUpdateManyWithWhereWithoutUserInput>>;
  upsert?: InputMaybe<Array<LeadIntentUpsertWithWhereUniqueWithoutUserInput>>;
};

export type LeadIntentUpdateManyWithWhereWithoutAssociate_ParentInput = {
  data: LeadIntentUpdateManyMutationInput;
  where: LeadIntentScalarWhereInput;
};

export type LeadIntentUpdateManyWithWhereWithoutLeadInput = {
  data: LeadIntentUpdateManyMutationInput;
  where: LeadIntentScalarWhereInput;
};

export type LeadIntentUpdateManyWithWhereWithoutOrganizationInput = {
  data: LeadIntentUpdateManyMutationInput;
  where: LeadIntentScalarWhereInput;
};

export type LeadIntentUpdateManyWithWhereWithoutUserInput = {
  data: LeadIntentUpdateManyMutationInput;
  where: LeadIntentScalarWhereInput;
};

export type LeadIntentUpdateOneWithoutReplacement_Lead_IntentInput = {
  connect?: InputMaybe<LeadIntentWhereUniqueInput>;
  connectOrCreate?: InputMaybe<LeadIntentCreateOrConnectWithoutReplacement_Lead_IntentInput>;
  create?: InputMaybe<LeadIntentCreateWithoutReplacement_Lead_IntentInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<LeadIntentUpdateWithoutReplacement_Lead_IntentInput>;
  upsert?: InputMaybe<LeadIntentUpsertWithoutReplacement_Lead_IntentInput>;
};

export type LeadIntentUpdateOneWithoutReplacing_Lead_IntentInput = {
  connect?: InputMaybe<LeadIntentWhereUniqueInput>;
  connectOrCreate?: InputMaybe<LeadIntentCreateOrConnectWithoutReplacing_Lead_IntentInput>;
  create?: InputMaybe<LeadIntentCreateWithoutReplacing_Lead_IntentInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<LeadIntentUpdateWithoutReplacing_Lead_IntentInput>;
  upsert?: InputMaybe<LeadIntentUpsertWithoutReplacing_Lead_IntentInput>;
};

export type LeadIntentUpdateOneWithoutSchedule_ItemInput = {
  connect?: InputMaybe<LeadIntentWhereUniqueInput>;
  connectOrCreate?: InputMaybe<LeadIntentCreateOrConnectWithoutSchedule_ItemInput>;
  create?: InputMaybe<LeadIntentCreateWithoutSchedule_ItemInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<LeadIntentUpdateWithoutSchedule_ItemInput>;
  upsert?: InputMaybe<LeadIntentUpsertWithoutSchedule_ItemInput>;
};

export type LeadIntentUpdateOneWithoutStepActionLogInput = {
  connect?: InputMaybe<LeadIntentWhereUniqueInput>;
  connectOrCreate?: InputMaybe<LeadIntentCreateOrConnectWithoutStepActionLogInput>;
  create?: InputMaybe<LeadIntentCreateWithoutStepActionLogInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<LeadIntentUpdateWithoutStepActionLogInput>;
  upsert?: InputMaybe<LeadIntentUpsertWithoutStepActionLogInput>;
};

export type LeadIntentUpdateWithoutAssociate_ParentInput = {
  anytime_after?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  anytime_before?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  anytime_day?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  anytime_day_upperbound?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  anytime_tz?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  associated_action?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lead?: InputMaybe<LeadUpdateOneRequiredWithoutLead_IntentInput>;
  lead_value?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutLead_IntentInput>;
  pipeline_state_int?: InputMaybe<IntFieldUpdateOperationsInput>;
  prepare_raw?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  ranking?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  replacement_lead_intent?: InputMaybe<LeadIntentUpdateOneWithoutReplacing_Lead_IntentInput>;
  replacing_lead_intent?: InputMaybe<LeadIntentUpdateOneWithoutReplacement_Lead_IntentInput>;
  schedule_item?: InputMaybe<ScheduleItemUpdateOneWithoutLead_IntentInput>;
  show_during_scheduled_event_duration?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  StepActionLog?: InputMaybe<StepActionLogUpdateOneWithoutLeadIntentInput>;
  type?: InputMaybe<NullableEnumIntentTypeFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneWithoutLead_IntentsInput>;
};

export type LeadIntentUpdateWithoutLeadInput = {
  anytime_after?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  anytime_before?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  anytime_day?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  anytime_day_upperbound?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  anytime_tz?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  associate_parent?: InputMaybe<LeadUpdateOneWithoutAssociated_Parent_IntentsInput>;
  associated_action?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lead_value?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutLead_IntentInput>;
  pipeline_state_int?: InputMaybe<IntFieldUpdateOperationsInput>;
  prepare_raw?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  ranking?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  replacement_lead_intent?: InputMaybe<LeadIntentUpdateOneWithoutReplacing_Lead_IntentInput>;
  replacing_lead_intent?: InputMaybe<LeadIntentUpdateOneWithoutReplacement_Lead_IntentInput>;
  schedule_item?: InputMaybe<ScheduleItemUpdateOneWithoutLead_IntentInput>;
  show_during_scheduled_event_duration?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  StepActionLog?: InputMaybe<StepActionLogUpdateOneWithoutLeadIntentInput>;
  type?: InputMaybe<NullableEnumIntentTypeFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneWithoutLead_IntentsInput>;
};

export type LeadIntentUpdateWithoutOrganizationInput = {
  anytime_after?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  anytime_before?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  anytime_day?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  anytime_day_upperbound?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  anytime_tz?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  associate_parent?: InputMaybe<LeadUpdateOneWithoutAssociated_Parent_IntentsInput>;
  associated_action?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lead?: InputMaybe<LeadUpdateOneRequiredWithoutLead_IntentInput>;
  lead_value?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  pipeline_state_int?: InputMaybe<IntFieldUpdateOperationsInput>;
  prepare_raw?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  ranking?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  replacement_lead_intent?: InputMaybe<LeadIntentUpdateOneWithoutReplacing_Lead_IntentInput>;
  replacing_lead_intent?: InputMaybe<LeadIntentUpdateOneWithoutReplacement_Lead_IntentInput>;
  schedule_item?: InputMaybe<ScheduleItemUpdateOneWithoutLead_IntentInput>;
  show_during_scheduled_event_duration?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  StepActionLog?: InputMaybe<StepActionLogUpdateOneWithoutLeadIntentInput>;
  type?: InputMaybe<NullableEnumIntentTypeFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneWithoutLead_IntentsInput>;
};

export type LeadIntentUpdateWithoutReplacement_Lead_IntentInput = {
  anytime_after?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  anytime_before?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  anytime_day?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  anytime_day_upperbound?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  anytime_tz?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  associate_parent?: InputMaybe<LeadUpdateOneWithoutAssociated_Parent_IntentsInput>;
  associated_action?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lead?: InputMaybe<LeadUpdateOneRequiredWithoutLead_IntentInput>;
  lead_value?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutLead_IntentInput>;
  pipeline_state_int?: InputMaybe<IntFieldUpdateOperationsInput>;
  prepare_raw?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  ranking?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  replacing_lead_intent?: InputMaybe<LeadIntentUpdateOneWithoutReplacement_Lead_IntentInput>;
  schedule_item?: InputMaybe<ScheduleItemUpdateOneWithoutLead_IntentInput>;
  show_during_scheduled_event_duration?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  StepActionLog?: InputMaybe<StepActionLogUpdateOneWithoutLeadIntentInput>;
  type?: InputMaybe<NullableEnumIntentTypeFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneWithoutLead_IntentsInput>;
};

export type LeadIntentUpdateWithoutReplacing_Lead_IntentInput = {
  anytime_after?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  anytime_before?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  anytime_day?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  anytime_day_upperbound?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  anytime_tz?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  associate_parent?: InputMaybe<LeadUpdateOneWithoutAssociated_Parent_IntentsInput>;
  associated_action?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lead?: InputMaybe<LeadUpdateOneRequiredWithoutLead_IntentInput>;
  lead_value?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutLead_IntentInput>;
  pipeline_state_int?: InputMaybe<IntFieldUpdateOperationsInput>;
  prepare_raw?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  ranking?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  replacement_lead_intent?: InputMaybe<LeadIntentUpdateOneWithoutReplacing_Lead_IntentInput>;
  schedule_item?: InputMaybe<ScheduleItemUpdateOneWithoutLead_IntentInput>;
  show_during_scheduled_event_duration?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  StepActionLog?: InputMaybe<StepActionLogUpdateOneWithoutLeadIntentInput>;
  type?: InputMaybe<NullableEnumIntentTypeFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneWithoutLead_IntentsInput>;
};

export type LeadIntentUpdateWithoutSchedule_ItemInput = {
  anytime_after?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  anytime_before?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  anytime_day?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  anytime_day_upperbound?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  anytime_tz?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  associate_parent?: InputMaybe<LeadUpdateOneWithoutAssociated_Parent_IntentsInput>;
  associated_action?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lead?: InputMaybe<LeadUpdateOneRequiredWithoutLead_IntentInput>;
  lead_value?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutLead_IntentInput>;
  pipeline_state_int?: InputMaybe<IntFieldUpdateOperationsInput>;
  prepare_raw?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  ranking?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  replacement_lead_intent?: InputMaybe<LeadIntentUpdateOneWithoutReplacing_Lead_IntentInput>;
  replacing_lead_intent?: InputMaybe<LeadIntentUpdateOneWithoutReplacement_Lead_IntentInput>;
  show_during_scheduled_event_duration?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  StepActionLog?: InputMaybe<StepActionLogUpdateOneWithoutLeadIntentInput>;
  type?: InputMaybe<NullableEnumIntentTypeFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneWithoutLead_IntentsInput>;
};

export type LeadIntentUpdateWithoutStepActionLogInput = {
  anytime_after?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  anytime_before?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  anytime_day?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  anytime_day_upperbound?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  anytime_tz?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  associate_parent?: InputMaybe<LeadUpdateOneWithoutAssociated_Parent_IntentsInput>;
  associated_action?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lead?: InputMaybe<LeadUpdateOneRequiredWithoutLead_IntentInput>;
  lead_value?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutLead_IntentInput>;
  pipeline_state_int?: InputMaybe<IntFieldUpdateOperationsInput>;
  prepare_raw?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  ranking?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  replacement_lead_intent?: InputMaybe<LeadIntentUpdateOneWithoutReplacing_Lead_IntentInput>;
  replacing_lead_intent?: InputMaybe<LeadIntentUpdateOneWithoutReplacement_Lead_IntentInput>;
  schedule_item?: InputMaybe<ScheduleItemUpdateOneWithoutLead_IntentInput>;
  show_during_scheduled_event_duration?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  type?: InputMaybe<NullableEnumIntentTypeFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneWithoutLead_IntentsInput>;
};

export type LeadIntentUpdateWithoutUserInput = {
  anytime_after?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  anytime_before?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  anytime_day?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  anytime_day_upperbound?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  anytime_tz?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  associate_parent?: InputMaybe<LeadUpdateOneWithoutAssociated_Parent_IntentsInput>;
  associated_action?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lead?: InputMaybe<LeadUpdateOneRequiredWithoutLead_IntentInput>;
  lead_value?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutLead_IntentInput>;
  pipeline_state_int?: InputMaybe<IntFieldUpdateOperationsInput>;
  prepare_raw?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  ranking?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  replacement_lead_intent?: InputMaybe<LeadIntentUpdateOneWithoutReplacing_Lead_IntentInput>;
  replacing_lead_intent?: InputMaybe<LeadIntentUpdateOneWithoutReplacement_Lead_IntentInput>;
  schedule_item?: InputMaybe<ScheduleItemUpdateOneWithoutLead_IntentInput>;
  show_during_scheduled_event_duration?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  StepActionLog?: InputMaybe<StepActionLogUpdateOneWithoutLeadIntentInput>;
  type?: InputMaybe<NullableEnumIntentTypeFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type LeadIntentUpdateWithWhereUniqueWithoutAssociate_ParentInput = {
  data: LeadIntentUpdateWithoutAssociate_ParentInput;
  where: LeadIntentWhereUniqueInput;
};

export type LeadIntentUpdateWithWhereUniqueWithoutLeadInput = {
  data: LeadIntentUpdateWithoutLeadInput;
  where: LeadIntentWhereUniqueInput;
};

export type LeadIntentUpdateWithWhereUniqueWithoutOrganizationInput = {
  data: LeadIntentUpdateWithoutOrganizationInput;
  where: LeadIntentWhereUniqueInput;
};

export type LeadIntentUpdateWithWhereUniqueWithoutUserInput = {
  data: LeadIntentUpdateWithoutUserInput;
  where: LeadIntentWhereUniqueInput;
};

export type LeadIntentUpsertWithoutReplacement_Lead_IntentInput = {
  create: LeadIntentCreateWithoutReplacement_Lead_IntentInput;
  update: LeadIntentUpdateWithoutReplacement_Lead_IntentInput;
};

export type LeadIntentUpsertWithoutReplacing_Lead_IntentInput = {
  create: LeadIntentCreateWithoutReplacing_Lead_IntentInput;
  update: LeadIntentUpdateWithoutReplacing_Lead_IntentInput;
};

export type LeadIntentUpsertWithoutSchedule_ItemInput = {
  create: LeadIntentCreateWithoutSchedule_ItemInput;
  update: LeadIntentUpdateWithoutSchedule_ItemInput;
};

export type LeadIntentUpsertWithoutStepActionLogInput = {
  create: LeadIntentCreateWithoutStepActionLogInput;
  update: LeadIntentUpdateWithoutStepActionLogInput;
};

export type LeadIntentUpsertWithWhereUniqueWithoutAssociate_ParentInput = {
  create: LeadIntentCreateWithoutAssociate_ParentInput;
  update: LeadIntentUpdateWithoutAssociate_ParentInput;
  where: LeadIntentWhereUniqueInput;
};

export type LeadIntentUpsertWithWhereUniqueWithoutLeadInput = {
  create: LeadIntentCreateWithoutLeadInput;
  update: LeadIntentUpdateWithoutLeadInput;
  where: LeadIntentWhereUniqueInput;
};

export type LeadIntentUpsertWithWhereUniqueWithoutOrganizationInput = {
  create: LeadIntentCreateWithoutOrganizationInput;
  update: LeadIntentUpdateWithoutOrganizationInput;
  where: LeadIntentWhereUniqueInput;
};

export type LeadIntentUpsertWithWhereUniqueWithoutUserInput = {
  create: LeadIntentCreateWithoutUserInput;
  update: LeadIntentUpdateWithoutUserInput;
  where: LeadIntentWhereUniqueInput;
};

export type LeadIntentWhereInput = {
  AND?: InputMaybe<Array<LeadIntentWhereInput>>;
  anytime_after?: InputMaybe<FloatNullableFilter>;
  anytime_before?: InputMaybe<FloatNullableFilter>;
  anytime_day?: InputMaybe<DateTimeNullableFilter>;
  anytime_day_upperbound?: InputMaybe<DateTimeNullableFilter>;
  anytime_tz?: InputMaybe<StringNullableFilter>;
  associate_parent?: InputMaybe<LeadWhereInput>;
  associate_parent_id?: InputMaybe<StringNullableFilter>;
  associated_action?: InputMaybe<StringNullableFilter>;
  created_at?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  lead?: InputMaybe<LeadWhereInput>;
  lead_id?: InputMaybe<StringFilter>;
  lead_value?: InputMaybe<FloatNullableFilter>;
  NOT?: InputMaybe<Array<LeadIntentWhereInput>>;
  OR?: InputMaybe<Array<LeadIntentWhereInput>>;
  organization?: InputMaybe<OrganizationWhereInput>;
  organization_id?: InputMaybe<StringFilter>;
  pipeline_state_int?: InputMaybe<IntFilter>;
  prepare_raw?: InputMaybe<BoolNullableFilter>;
  ranking?: InputMaybe<IntNullableFilter>;
  replacement_lead_intent?: InputMaybe<LeadIntentWhereInput>;
  replacement_lead_intent_id?: InputMaybe<StringNullableFilter>;
  replacing_lead_intent?: InputMaybe<LeadIntentWhereInput>;
  schedule_item?: InputMaybe<ScheduleItemWhereInput>;
  schedule_item_id?: InputMaybe<StringNullableFilter>;
  show_during_scheduled_event_duration?: InputMaybe<BoolNullableFilter>;
  StepActionLog?: InputMaybe<StepActionLogWhereInput>;
  type?: InputMaybe<EnumIntentTypeNullableFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserWhereInput>;
  user_id?: InputMaybe<StringNullableFilter>;
};

export type LeadIntentWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  replacement_lead_intent_id?: InputMaybe<Scalars['String']['input']>;
  schedule_item_id?: InputMaybe<Scalars['String']['input']>;
};

export type LeadListRelationFilter = {
  every?: InputMaybe<LeadWhereInput>;
  none?: InputMaybe<LeadWhereInput>;
  some?: InputMaybe<LeadWhereInput>;
};

/** available option for sorting Call Report List in coaching view */
export enum LeadListSortOption {
  BusinessName = 'BusinessName',
  City = 'City',
  Country = 'Country',
  Dials = 'Dials',
  DialsInCurrentSaleCycle = 'DialsInCurrentSaleCycle',
  FirstName = 'FirstName',
  Industry = 'Industry',
  LastCallResult = 'LastCallResult',
  LastCallResultRep = 'LastCallResultRep',
  LastCallResultTime = 'LastCallResultTime',
  LastLeadAssignmentOriginTime = 'LastLeadAssignmentOriginTime',
  LastName = 'LastName',
  LeadCreationDate = 'LeadCreationDate',
  LeadCreationSource = 'LeadCreationSource',
  LeadSource = 'LeadSource',
  NextScheduledEvent = 'NextScheduledEvent',
  Phase = 'Phase',
  PrimaryEmail = 'PrimaryEmail',
  PrimaryPhone = 'PrimaryPhone',
  Rep = 'Rep',
  State = 'State',
  Status = 'Status',
  SubIndustry = 'SubIndustry',
  ZipCode = 'ZipCode'
}

export type LeadLog = {
  __typename?: 'LeadLog';
  dml_created_by?: Maybe<Scalars['String']['output']>;
  dml_timestamp: Scalars['DateTime']['output'];
  dml_type: Dml_Type;
  lead_id: Scalars['String']['output'];
  new_row_data?: Maybe<Scalars['Json']['output']>;
  old_row_data?: Maybe<Scalars['Json']['output']>;
};

export type LeadOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
  count?: InputMaybe<SortOrder>;
};

export type LeadOrderByWithRelationInput = {
  address?: InputMaybe<SortOrder>;
  address_2?: InputMaybe<SortOrder>;
  alternate_contacts?: InputMaybe<ContactOrderByRelationAggregateInput>;
  assignment_locked?: InputMaybe<SortOrder>;
  associate_children?: InputMaybe<LeadOrderByRelationAggregateInput>;
  associate_group_lead_activities?: InputMaybe<LeadActivityOrderByRelationAggregateInput>;
  associate_lead_histories?: InputMaybe<AssociationHistoryOrderByRelationAggregateInput>;
  associate_parent_id?: InputMaybe<SortOrder>;
  associate_parent_lead?: InputMaybe<LeadOrderByWithRelationInput>;
  associated_parent_intents?: InputMaybe<LeadIntentOrderByRelationAggregateInput>;
  associates_search_content?: InputMaybe<SortOrder>;
  business_name?: InputMaybe<SortOrder>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptOrderByRelationAggregateInput>;
  callMeNows?: InputMaybe<CallMeNowOrderByRelationAggregateInput>;
  channel?: InputMaybe<SortOrder>;
  child_lead_activites?: InputMaybe<LeadActivityOrderByRelationAggregateInput>;
  city?: InputMaybe<SortOrder>;
  claim_date?: InputMaybe<SortOrder>;
  conference_number?: InputMaybe<SortOrder>;
  contact_requests?: InputMaybe<ContactRequestOrderByRelationAggregateInput>;
  content?: InputMaybe<SortOrder>;
  country?: InputMaybe<SortOrder>;
  created_at?: InputMaybe<SortOrder>;
  current_close_date?: InputMaybe<SortOrder>;
  current_contact_date?: InputMaybe<SortOrder>;
  current_sale_cycle?: InputMaybe<SaleCycleOrderByWithRelationInput>;
  custom_field_json?: InputMaybe<SortOrder>;
  custom_fields?: InputMaybe<CustomFieldOrderByRelationAggregateInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemOrderByRelationAggregateInput>;
  delay_action_status?: InputMaybe<SortOrder>;
  email_sub_status?: InputMaybe<SortOrder>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordOrderByRelationAggregateInput>;
  EmailThread?: InputMaybe<EmailThreadOrderByRelationAggregateInput>;
  favorited_by_users?: InputMaybe<UserOrderByRelationAggregateInput>;
  first_demo_schedule_date?: InputMaybe<SortOrder>;
  first_name?: InputMaybe<SortOrder>;
  hubspot_company_id?: InputMaybe<SortOrder>;
  hubspot_id?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  implied_next_action?: InputMaybe<SortOrder>;
  in_algo_queue?: InputMaybe<SortOrder>;
  in_custom_sequence?: InputMaybe<SortOrder>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventOrderByRelationAggregateInput>;
  industry?: InputMaybe<SortOrder>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogOrderByRelationAggregateInput>;
  last_call_result?: InputMaybe<SortOrder>;
  last_call_result_rep?: InputMaybe<UserOrderByWithRelationInput>;
  last_call_result_rep_id?: InputMaybe<SortOrder>;
  last_call_result_time?: InputMaybe<SortOrder>;
  last_lead_assignment_origin?: InputMaybe<SortOrder>;
  last_name?: InputMaybe<SortOrder>;
  last_sent_to_resting_date?: InputMaybe<SortOrder>;
  lead_activities?: InputMaybe<LeadActivityOrderByRelationAggregateInput>;
  lead_assignment_history?: InputMaybe<LeadAssignmentHistoryOrderByRelationAggregateInput>;
  lead_assignment_origin?: InputMaybe<SortOrder>;
  lead_creation_source?: InputMaybe<SortOrder>;
  lead_custom_row_info?: InputMaybe<LeadCustomObjectRowAssociationOrderByRelationAggregateInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryOrderByWithRelationInput>;
  lead_import_history_csv?: InputMaybe<LeadImportHistoryOrderByWithRelationInput>;
  lead_import_history_id?: InputMaybe<SortOrder>;
  lead_intent?: InputMaybe<LeadIntentOrderByRelationAggregateInput>;
  lead_ownership_status?: InputMaybe<SortOrder>;
  lead_source?: InputMaybe<SortOrder>;
  lead_value?: InputMaybe<LeadValueOrderByWithRelationInput>;
  mrr?: InputMaybe<SortOrder>;
  newIncoming?: InputMaybe<SortOrder>;
  next_scheduled_event?: InputMaybe<ScheduleItemOrderByWithRelationInput>;
  next_scheduled_event_id?: InputMaybe<SortOrder>;
  notifications?: InputMaybe<NotificationOrderByRelationAggregateInput>;
  opsiq_whitelist?: InputMaybe<SortOrder>;
  organization?: InputMaybe<OrganizationOrderByWithRelationInput>;
  organization_id?: InputMaybe<SortOrder>;
  phase?: InputMaybe<SortOrder>;
  primary_email?: InputMaybe<SortOrder>;
  primary_email_title?: InputMaybe<SortOrder>;
  primary_email_type?: InputMaybe<SortOrder>;
  primary_lead_histories?: InputMaybe<AssociationHistoryOrderByRelationAggregateInput>;
  primary_phone_number?: InputMaybe<SortOrder>;
  primary_phone_number_country_code?: InputMaybe<SortOrder>;
  primary_phone_number_title?: InputMaybe<SortOrder>;
  primary_phone_number_type?: InputMaybe<SortOrder>;
  rep?: InputMaybe<UserOrderByWithRelationInput>;
  rep_id?: InputMaybe<SortOrder>;
  resting_type?: InputMaybe<SortOrder>;
  routing_history?: InputMaybe<RoutingHistoryOrderByRelationAggregateInput>;
  routing_rule?: InputMaybe<RuleOrderByWithRelationInput>;
  routing_rule_id?: InputMaybe<SortOrder>;
  Sale?: InputMaybe<SaleOrderByRelationAggregateInput>;
  sale_cycles?: InputMaybe<SaleCycleOrderByRelationAggregateInput>;
  saleCycleCycle_lead_id?: InputMaybe<SortOrder>;
  saleCycleCycle_num?: InputMaybe<SortOrder>;
  search_content?: InputMaybe<SortOrder>;
  secondary_reps?: InputMaybe<UserOrderByRelationAggregateInput>;
  self_sourced?: InputMaybe<SortOrder>;
  sequence?: InputMaybe<SequenceOrderByWithRelationInput>;
  sequence_id?: InputMaybe<SortOrder>;
  sequence_step?: InputMaybe<SequenceStepOrderByWithRelationInput>;
  sequence_step_id?: InputMaybe<SortOrder>;
  SequenceActionLog?: InputMaybe<StepActionLogOrderByRelationAggregateInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadOrderByRelationAggregateInput>;
  sequences_completed_ids?: InputMaybe<SortOrder>;
  sequences_joined_ids?: InputMaybe<SortOrder>;
  sf_account_id?: InputMaybe<SortOrder>;
  sf_contact_id?: InputMaybe<SortOrder>;
  sf_lead_id?: InputMaybe<SortOrder>;
  sibling_lead_histories?: InputMaybe<AssociationHistoryOrderByRelationAggregateInput>;
  sms_sub_status?: InputMaybe<SortOrder>;
  stat_items?: InputMaybe<StatItemAggregationOrderByRelationAggregateInput>;
  state?: InputMaybe<SortOrder>;
  StatLeadAggregation?: InputMaybe<StatLeadAggregationOrderByWithRelationInput>;
  status?: InputMaybe<SortOrder>;
  sub_industry?: InputMaybe<SortOrder>;
  timezone?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
  zip?: InputMaybe<SortOrder>;
};

/**
 * Used to calculate statistics of lead pool
 *
 */
export type LeadPoolItem = {
  __typename?: 'LeadPoolItem';
  id?: Maybe<Scalars['String']['output']>;
  metric: Scalars['String']['output'];
  metric_label?: Maybe<Scalars['String']['output']>;
  metric_tooltip?: Maybe<Scalars['String']['output']>;
  metric_type?: Maybe<Scalars['String']['output']>;
  organization_id?: Maybe<Scalars['String']['output']>;
  row_id?: Maybe<Scalars['String']['output']>;
  row_label?: Maybe<Scalars['String']['output']>;
  team_id?: Maybe<Scalars['String']['output']>;
  user?: Maybe<User>;
  user_id?: Maybe<Scalars['String']['output']>;
  value: Scalars['Float']['output'];
  /** lead table filter, available options are LeadSource or Team */
  view_filter?: Maybe<Scalars['String']['output']>;
};

/** available lead pool metrics */
export enum LeadPoolMetrics {
  Assigned = 'Assigned',
  Customer = 'Customer',
  InProgress = 'InProgress',
  NeverDialed = 'NeverDialed',
  Owned = 'Owned',
  Resting = 'Resting',
  Retired = 'Retired',
  UnAssigned = 'UnAssigned'
}

/** available lead funnel sort options */
export enum LeadPoolSortOption {
  CurrentState = 'CurrentState',
  Industry = 'Industry',
  LeadName = 'LeadName',
  LeadValue = 'LeadValue'
}

/** available lead pool filter */
export enum LeadPoolViewFilter {
  Industry = 'Industry',
  LeadCreationSource = 'LeadCreationSource',
  LeadSource = 'LeadSource',
  Team = 'Team'
}

export type LeadSaleCycleCycle_NumSaleCycleCycle_Lead_IdCompoundUniqueInput = {
  saleCycleCycle_lead_id: Scalars['String']['input'];
  saleCycleCycle_num: Scalars['Int']['input'];
};

export type LeadScalarWhereInput = {
  address?: InputMaybe<StringNullableFilter>;
  address_2?: InputMaybe<StringNullableFilter>;
  AND?: InputMaybe<Array<LeadScalarWhereInput>>;
  assignment_locked?: InputMaybe<BoolFilter>;
  associate_parent_id?: InputMaybe<StringNullableFilter>;
  associates_search_content?: InputMaybe<StringNullableFilter>;
  business_name?: InputMaybe<StringNullableFilter>;
  channel?: InputMaybe<EnumChannelNullableFilter>;
  city?: InputMaybe<StringNullableFilter>;
  claim_date?: InputMaybe<DateTimeNullableFilter>;
  conference_number?: InputMaybe<StringNullableFilter>;
  content?: InputMaybe<StringNullableFilter>;
  country?: InputMaybe<StringNullableFilter>;
  created_at?: InputMaybe<DateTimeFilter>;
  current_close_date?: InputMaybe<DateTimeNullableFilter>;
  current_contact_date?: InputMaybe<DateTimeNullableFilter>;
  custom_field_json?: InputMaybe<JsonNullableFilter>;
  delay_action_status?: InputMaybe<BoolFilter>;
  email_sub_status?: InputMaybe<EnumOptInStatusFilter>;
  first_demo_schedule_date?: InputMaybe<DateTimeNullableFilter>;
  first_name?: InputMaybe<StringNullableFilter>;
  hubspot_company_id?: InputMaybe<StringNullableFilter>;
  hubspot_id?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  implied_next_action?: InputMaybe<EnumHingepointFilter>;
  in_algo_queue?: InputMaybe<BoolFilter>;
  in_custom_sequence?: InputMaybe<IntFilter>;
  industry?: InputMaybe<StringNullableFilter>;
  last_call_result?: InputMaybe<StringNullableFilter>;
  last_call_result_rep_id?: InputMaybe<StringNullableFilter>;
  last_call_result_time?: InputMaybe<DateTimeNullableFilter>;
  last_lead_assignment_origin?: InputMaybe<DateTimeNullableFilter>;
  last_name?: InputMaybe<StringNullableFilter>;
  last_sent_to_resting_date?: InputMaybe<DateTimeNullableFilter>;
  lead_assignment_origin?: InputMaybe<EnumAssignmentOriginFilter>;
  lead_creation_source?: InputMaybe<EnumLeadcreationsourceNullableFilter>;
  lead_import_history_id?: InputMaybe<StringNullableFilter>;
  lead_ownership_status?: InputMaybe<EnumLosFilter>;
  lead_source?: InputMaybe<StringNullableFilter>;
  mrr?: InputMaybe<FloatNullableFilter>;
  newIncoming?: InputMaybe<BoolFilter>;
  next_scheduled_event_id?: InputMaybe<StringNullableFilter>;
  NOT?: InputMaybe<Array<LeadScalarWhereInput>>;
  opsiq_whitelist?: InputMaybe<BoolNullableFilter>;
  OR?: InputMaybe<Array<LeadScalarWhereInput>>;
  organization_id?: InputMaybe<StringFilter>;
  phase?: InputMaybe<EnumPhaseFilter>;
  primary_email?: InputMaybe<StringNullableFilter>;
  primary_email_title?: InputMaybe<StringNullableFilter>;
  primary_email_type?: InputMaybe<StringNullableFilter>;
  primary_phone_number?: InputMaybe<StringNullableFilter>;
  primary_phone_number_country_code?: InputMaybe<StringNullableFilter>;
  primary_phone_number_title?: InputMaybe<StringNullableFilter>;
  primary_phone_number_type?: InputMaybe<StringNullableFilter>;
  rep_id?: InputMaybe<StringNullableFilter>;
  resting_type?: InputMaybe<EnumRestingtypeNullableFilter>;
  routing_rule_id?: InputMaybe<StringNullableFilter>;
  saleCycleCycle_lead_id?: InputMaybe<StringNullableFilter>;
  saleCycleCycle_num?: InputMaybe<IntNullableFilter>;
  search_content?: InputMaybe<StringNullableFilter>;
  self_sourced?: InputMaybe<BoolNullableFilter>;
  sequence_id?: InputMaybe<StringNullableFilter>;
  sequence_step_id?: InputMaybe<StringNullableFilter>;
  sequences_completed_ids?: InputMaybe<StringNullableListFilter>;
  sequences_joined_ids?: InputMaybe<StringNullableListFilter>;
  sf_account_id?: InputMaybe<StringNullableFilter>;
  sf_contact_id?: InputMaybe<StringNullableFilter>;
  sf_lead_id?: InputMaybe<StringNullableFilter>;
  sms_sub_status?: InputMaybe<EnumOptInStatusFilter>;
  state?: InputMaybe<StringNullableFilter>;
  status?: InputMaybe<StringNullableFilter>;
  sub_industry?: InputMaybe<StringNullableFilter>;
  timezone?: InputMaybe<StringNullableFilter>;
  title?: InputMaybe<StringNullableFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
  zip?: InputMaybe<StringNullableFilter>;
};

export type LeadSf_Contact_IdOrganization_IdCompoundUniqueInput = {
  organization_id: Scalars['String']['input'];
  sf_contact_id: Scalars['String']['input'];
};

export type LeadSf_Lead_IdOrganization_IdCompoundUniqueInput = {
  organization_id: Scalars['String']['input'];
  sf_lead_id: Scalars['String']['input'];
};

/** User Batch Edit Input */
export type LeadSourceBatchEditInput = {
  assign?: InputMaybe<AssignType>;
  lead_source_white_list_array?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type LeadSourceOption = {
  __typename?: 'LeadSourceOption';
  created_at: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  label: Scalars['String']['output'];
  updated_at: Scalars['DateTime']['output'];
};

export type LeadSourceOptionCreateManyOrganizationInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  label: Scalars['String']['input'];
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type LeadSourceOptionCreateManyOrganizationInputEnvelope = {
  data?: InputMaybe<Array<LeadSourceOptionCreateManyOrganizationInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type LeadSourceOptionCreateNestedManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<LeadSourceOptionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<LeadSourceOptionCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<LeadSourceOptionCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<LeadSourceOptionCreateManyOrganizationInputEnvelope>;
};

export type LeadSourceOptionCreateOrConnectWithoutOrganizationInput = {
  create: LeadSourceOptionCreateWithoutOrganizationInput;
  where: LeadSourceOptionWhereUniqueInput;
};

export type LeadSourceOptionCreateWithoutOrganizationInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  label: Scalars['String']['input'];
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type LeadSourceOptionListRelationFilter = {
  every?: InputMaybe<LeadSourceOptionWhereInput>;
  none?: InputMaybe<LeadSourceOptionWhereInput>;
  some?: InputMaybe<LeadSourceOptionWhereInput>;
};

export type LeadSourceOptionOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
  count?: InputMaybe<SortOrder>;
};

export type LeadSourceOptionScalarWhereInput = {
  AND?: InputMaybe<Array<LeadSourceOptionScalarWhereInput>>;
  created_at?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  label?: InputMaybe<StringFilter>;
  NOT?: InputMaybe<Array<LeadSourceOptionScalarWhereInput>>;
  OR?: InputMaybe<Array<LeadSourceOptionScalarWhereInput>>;
  organization_id?: InputMaybe<StringFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
};

export type LeadSourceOptionUpdateManyMutationInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  label?: InputMaybe<StringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type LeadSourceOptionUpdateManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<LeadSourceOptionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<LeadSourceOptionCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<LeadSourceOptionCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<LeadSourceOptionCreateManyOrganizationInputEnvelope>;
  delete?: InputMaybe<Array<LeadSourceOptionWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<LeadSourceOptionScalarWhereInput>>;
  disconnect?: InputMaybe<Array<LeadSourceOptionWhereUniqueInput>>;
  set?: InputMaybe<Array<LeadSourceOptionWhereUniqueInput>>;
  update?: InputMaybe<Array<LeadSourceOptionUpdateWithWhereUniqueWithoutOrganizationInput>>;
  updateMany?: InputMaybe<Array<LeadSourceOptionUpdateManyWithWhereWithoutOrganizationInput>>;
  upsert?: InputMaybe<Array<LeadSourceOptionUpsertWithWhereUniqueWithoutOrganizationInput>>;
};

export type LeadSourceOptionUpdateManyWithWhereWithoutOrganizationInput = {
  data: LeadSourceOptionUpdateManyMutationInput;
  where: LeadSourceOptionScalarWhereInput;
};

export type LeadSourceOptionUpdateWithoutOrganizationInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  label?: InputMaybe<StringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type LeadSourceOptionUpdateWithWhereUniqueWithoutOrganizationInput = {
  data: LeadSourceOptionUpdateWithoutOrganizationInput;
  where: LeadSourceOptionWhereUniqueInput;
};

export type LeadSourceOptionUpsertWithWhereUniqueWithoutOrganizationInput = {
  create: LeadSourceOptionCreateWithoutOrganizationInput;
  update: LeadSourceOptionUpdateWithoutOrganizationInput;
  where: LeadSourceOptionWhereUniqueInput;
};

export type LeadSourceOptionWhereInput = {
  AND?: InputMaybe<Array<LeadSourceOptionWhereInput>>;
  created_at?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  label?: InputMaybe<StringFilter>;
  NOT?: InputMaybe<Array<LeadSourceOptionWhereInput>>;
  OR?: InputMaybe<Array<LeadSourceOptionWhereInput>>;
  organization?: InputMaybe<OrganizationWhereInput>;
  organization_id?: InputMaybe<StringFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
};

export type LeadSourceOptionWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']['input']>;
};

/** Lead System Operators */
export type LeadSystemOperators = {
  industries_operator?: InputMaybe<LeadFilterOperator>;
  lead_creation_source_operator?: InputMaybe<LeadFilterOperator>;
  lead_sources_operator?: InputMaybe<LeadFilterOperator>;
  reps_operator?: InputMaybe<LeadFilterOperator>;
  states_operator?: InputMaybe<LeadFilterOperator>;
  status_operator?: InputMaybe<LeadFilterOperator>;
  sub_industry_operator?: InputMaybe<LeadFilterOperator>;
  timezones_operator?: InputMaybe<LeadFilterOperator>;
};

export type LeadUpdateManyMutationInput = {
  address?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  address_2?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  assignment_locked?: InputMaybe<BoolFieldUpdateOperationsInput>;
  associates_search_content?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  business_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  channel?: InputMaybe<NullableEnumChannelFieldUpdateOperationsInput>;
  city?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  claim_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  conference_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  content?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  current_close_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  current_contact_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  custom_field_json?: InputMaybe<Scalars['Json']['input']>;
  delay_action_status?: InputMaybe<BoolFieldUpdateOperationsInput>;
  email_sub_status?: InputMaybe<EnumOptInStatusFieldUpdateOperationsInput>;
  first_demo_schedule_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  first_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  hubspot_company_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  hubspot_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  implied_next_action?: InputMaybe<EnumHingepointFieldUpdateOperationsInput>;
  in_algo_queue?: InputMaybe<BoolFieldUpdateOperationsInput>;
  in_custom_sequence?: InputMaybe<IntFieldUpdateOperationsInput>;
  industry?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  last_call_result?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  last_call_result_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  last_lead_assignment_origin?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  last_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  last_sent_to_resting_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lead_assignment_origin?: InputMaybe<EnumAssignmentOriginFieldUpdateOperationsInput>;
  lead_creation_source?: InputMaybe<NullableEnumLeadcreationsourceFieldUpdateOperationsInput>;
  lead_ownership_status?: InputMaybe<EnumLosFieldUpdateOperationsInput>;
  lead_source?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  mrr?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  newIncoming?: InputMaybe<BoolFieldUpdateOperationsInput>;
  opsiq_whitelist?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  phase?: InputMaybe<EnumPhaseFieldUpdateOperationsInput>;
  primary_email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_email_title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_email_type?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_phone_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_phone_number_country_code?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_phone_number_title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_phone_number_type?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  resting_type?: InputMaybe<NullableEnumRestingtypeFieldUpdateOperationsInput>;
  search_content?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  self_sourced?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  sequences_completed_ids?: InputMaybe<LeadUpdatesequences_Completed_IdsInput>;
  sequences_joined_ids?: InputMaybe<LeadUpdatesequences_Joined_IdsInput>;
  sf_account_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sf_contact_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sf_lead_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sms_sub_status?: InputMaybe<EnumOptInStatusFieldUpdateOperationsInput>;
  state?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sub_industry?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timezone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  zip?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type LeadUpdateManyWithoutAssociate_Group_Lead_ActivitiesInput = {
  connect?: InputMaybe<Array<LeadWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<LeadCreateOrConnectWithoutAssociate_Group_Lead_ActivitiesInput>>;
  create?: InputMaybe<Array<LeadCreateWithoutAssociate_Group_Lead_ActivitiesInput>>;
  delete?: InputMaybe<Array<LeadWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<LeadScalarWhereInput>>;
  disconnect?: InputMaybe<Array<LeadWhereUniqueInput>>;
  set?: InputMaybe<Array<LeadWhereUniqueInput>>;
  update?: InputMaybe<Array<LeadUpdateWithWhereUniqueWithoutAssociate_Group_Lead_ActivitiesInput>>;
  updateMany?: InputMaybe<Array<LeadUpdateManyWithWhereWithoutAssociate_Group_Lead_ActivitiesInput>>;
  upsert?: InputMaybe<Array<LeadUpsertWithWhereUniqueWithoutAssociate_Group_Lead_ActivitiesInput>>;
};

export type LeadUpdateManyWithoutAssociate_Parent_LeadInput = {
  connect?: InputMaybe<Array<LeadWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<LeadCreateOrConnectWithoutAssociate_Parent_LeadInput>>;
  create?: InputMaybe<Array<LeadCreateWithoutAssociate_Parent_LeadInput>>;
  createMany?: InputMaybe<LeadCreateManyAssociate_Parent_LeadInputEnvelope>;
  delete?: InputMaybe<Array<LeadWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<LeadScalarWhereInput>>;
  disconnect?: InputMaybe<Array<LeadWhereUniqueInput>>;
  set?: InputMaybe<Array<LeadWhereUniqueInput>>;
  update?: InputMaybe<Array<LeadUpdateWithWhereUniqueWithoutAssociate_Parent_LeadInput>>;
  updateMany?: InputMaybe<Array<LeadUpdateManyWithWhereWithoutAssociate_Parent_LeadInput>>;
  upsert?: InputMaybe<Array<LeadUpsertWithWhereUniqueWithoutAssociate_Parent_LeadInput>>;
};

export type LeadUpdateManyWithoutFavorited_By_UsersInput = {
  connect?: InputMaybe<Array<LeadWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<LeadCreateOrConnectWithoutFavorited_By_UsersInput>>;
  create?: InputMaybe<Array<LeadCreateWithoutFavorited_By_UsersInput>>;
  delete?: InputMaybe<Array<LeadWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<LeadScalarWhereInput>>;
  disconnect?: InputMaybe<Array<LeadWhereUniqueInput>>;
  set?: InputMaybe<Array<LeadWhereUniqueInput>>;
  update?: InputMaybe<Array<LeadUpdateWithWhereUniqueWithoutFavorited_By_UsersInput>>;
  updateMany?: InputMaybe<Array<LeadUpdateManyWithWhereWithoutFavorited_By_UsersInput>>;
  upsert?: InputMaybe<Array<LeadUpsertWithWhereUniqueWithoutFavorited_By_UsersInput>>;
};

export type LeadUpdateManyWithoutLast_Call_Result_RepInput = {
  connect?: InputMaybe<Array<LeadWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<LeadCreateOrConnectWithoutLast_Call_Result_RepInput>>;
  create?: InputMaybe<Array<LeadCreateWithoutLast_Call_Result_RepInput>>;
  createMany?: InputMaybe<LeadCreateManyLast_Call_Result_RepInputEnvelope>;
  delete?: InputMaybe<Array<LeadWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<LeadScalarWhereInput>>;
  disconnect?: InputMaybe<Array<LeadWhereUniqueInput>>;
  set?: InputMaybe<Array<LeadWhereUniqueInput>>;
  update?: InputMaybe<Array<LeadUpdateWithWhereUniqueWithoutLast_Call_Result_RepInput>>;
  updateMany?: InputMaybe<Array<LeadUpdateManyWithWhereWithoutLast_Call_Result_RepInput>>;
  upsert?: InputMaybe<Array<LeadUpsertWithWhereUniqueWithoutLast_Call_Result_RepInput>>;
};

export type LeadUpdateManyWithoutLead_Import_History_CsvInput = {
  connect?: InputMaybe<Array<LeadWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<LeadCreateOrConnectWithoutLead_Import_History_CsvInput>>;
  create?: InputMaybe<Array<LeadCreateWithoutLead_Import_History_CsvInput>>;
  createMany?: InputMaybe<LeadCreateManyLead_Import_History_CsvInputEnvelope>;
  delete?: InputMaybe<Array<LeadWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<LeadScalarWhereInput>>;
  disconnect?: InputMaybe<Array<LeadWhereUniqueInput>>;
  set?: InputMaybe<Array<LeadWhereUniqueInput>>;
  update?: InputMaybe<Array<LeadUpdateWithWhereUniqueWithoutLead_Import_History_CsvInput>>;
  updateMany?: InputMaybe<Array<LeadUpdateManyWithWhereWithoutLead_Import_History_CsvInput>>;
  upsert?: InputMaybe<Array<LeadUpsertWithWhereUniqueWithoutLead_Import_History_CsvInput>>;
};

export type LeadUpdateManyWithoutNext_Scheduled_EventInput = {
  connect?: InputMaybe<Array<LeadWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<LeadCreateOrConnectWithoutNext_Scheduled_EventInput>>;
  create?: InputMaybe<Array<LeadCreateWithoutNext_Scheduled_EventInput>>;
  createMany?: InputMaybe<LeadCreateManyNext_Scheduled_EventInputEnvelope>;
  delete?: InputMaybe<Array<LeadWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<LeadScalarWhereInput>>;
  disconnect?: InputMaybe<Array<LeadWhereUniqueInput>>;
  set?: InputMaybe<Array<LeadWhereUniqueInput>>;
  update?: InputMaybe<Array<LeadUpdateWithWhereUniqueWithoutNext_Scheduled_EventInput>>;
  updateMany?: InputMaybe<Array<LeadUpdateManyWithWhereWithoutNext_Scheduled_EventInput>>;
  upsert?: InputMaybe<Array<LeadUpsertWithWhereUniqueWithoutNext_Scheduled_EventInput>>;
};

export type LeadUpdateManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<LeadWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<LeadCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<LeadCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<LeadCreateManyOrganizationInputEnvelope>;
  delete?: InputMaybe<Array<LeadWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<LeadScalarWhereInput>>;
  disconnect?: InputMaybe<Array<LeadWhereUniqueInput>>;
  set?: InputMaybe<Array<LeadWhereUniqueInput>>;
  update?: InputMaybe<Array<LeadUpdateWithWhereUniqueWithoutOrganizationInput>>;
  updateMany?: InputMaybe<Array<LeadUpdateManyWithWhereWithoutOrganizationInput>>;
  upsert?: InputMaybe<Array<LeadUpsertWithWhereUniqueWithoutOrganizationInput>>;
};

export type LeadUpdateManyWithoutRepInput = {
  connect?: InputMaybe<Array<LeadWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<LeadCreateOrConnectWithoutRepInput>>;
  create?: InputMaybe<Array<LeadCreateWithoutRepInput>>;
  createMany?: InputMaybe<LeadCreateManyRepInputEnvelope>;
  delete?: InputMaybe<Array<LeadWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<LeadScalarWhereInput>>;
  disconnect?: InputMaybe<Array<LeadWhereUniqueInput>>;
  set?: InputMaybe<Array<LeadWhereUniqueInput>>;
  update?: InputMaybe<Array<LeadUpdateWithWhereUniqueWithoutRepInput>>;
  updateMany?: InputMaybe<Array<LeadUpdateManyWithWhereWithoutRepInput>>;
  upsert?: InputMaybe<Array<LeadUpsertWithWhereUniqueWithoutRepInput>>;
};

export type LeadUpdateManyWithoutRouting_RuleInput = {
  connect?: InputMaybe<Array<LeadWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<LeadCreateOrConnectWithoutRouting_RuleInput>>;
  create?: InputMaybe<Array<LeadCreateWithoutRouting_RuleInput>>;
  createMany?: InputMaybe<LeadCreateManyRouting_RuleInputEnvelope>;
  delete?: InputMaybe<Array<LeadWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<LeadScalarWhereInput>>;
  disconnect?: InputMaybe<Array<LeadWhereUniqueInput>>;
  set?: InputMaybe<Array<LeadWhereUniqueInput>>;
  update?: InputMaybe<Array<LeadUpdateWithWhereUniqueWithoutRouting_RuleInput>>;
  updateMany?: InputMaybe<Array<LeadUpdateManyWithWhereWithoutRouting_RuleInput>>;
  upsert?: InputMaybe<Array<LeadUpsertWithWhereUniqueWithoutRouting_RuleInput>>;
};

export type LeadUpdateManyWithoutSecondary_RepsInput = {
  connect?: InputMaybe<Array<LeadWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<LeadCreateOrConnectWithoutSecondary_RepsInput>>;
  create?: InputMaybe<Array<LeadCreateWithoutSecondary_RepsInput>>;
  delete?: InputMaybe<Array<LeadWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<LeadScalarWhereInput>>;
  disconnect?: InputMaybe<Array<LeadWhereUniqueInput>>;
  set?: InputMaybe<Array<LeadWhereUniqueInput>>;
  update?: InputMaybe<Array<LeadUpdateWithWhereUniqueWithoutSecondary_RepsInput>>;
  updateMany?: InputMaybe<Array<LeadUpdateManyWithWhereWithoutSecondary_RepsInput>>;
  upsert?: InputMaybe<Array<LeadUpsertWithWhereUniqueWithoutSecondary_RepsInput>>;
};

export type LeadUpdateManyWithoutSequence_StepInput = {
  connect?: InputMaybe<Array<LeadWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<LeadCreateOrConnectWithoutSequence_StepInput>>;
  create?: InputMaybe<Array<LeadCreateWithoutSequence_StepInput>>;
  createMany?: InputMaybe<LeadCreateManySequence_StepInputEnvelope>;
  delete?: InputMaybe<Array<LeadWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<LeadScalarWhereInput>>;
  disconnect?: InputMaybe<Array<LeadWhereUniqueInput>>;
  set?: InputMaybe<Array<LeadWhereUniqueInput>>;
  update?: InputMaybe<Array<LeadUpdateWithWhereUniqueWithoutSequence_StepInput>>;
  updateMany?: InputMaybe<Array<LeadUpdateManyWithWhereWithoutSequence_StepInput>>;
  upsert?: InputMaybe<Array<LeadUpsertWithWhereUniqueWithoutSequence_StepInput>>;
};

export type LeadUpdateManyWithoutSequenceInput = {
  connect?: InputMaybe<Array<LeadWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<LeadCreateOrConnectWithoutSequenceInput>>;
  create?: InputMaybe<Array<LeadCreateWithoutSequenceInput>>;
  createMany?: InputMaybe<LeadCreateManySequenceInputEnvelope>;
  delete?: InputMaybe<Array<LeadWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<LeadScalarWhereInput>>;
  disconnect?: InputMaybe<Array<LeadWhereUniqueInput>>;
  set?: InputMaybe<Array<LeadWhereUniqueInput>>;
  update?: InputMaybe<Array<LeadUpdateWithWhereUniqueWithoutSequenceInput>>;
  updateMany?: InputMaybe<Array<LeadUpdateManyWithWhereWithoutSequenceInput>>;
  upsert?: InputMaybe<Array<LeadUpsertWithWhereUniqueWithoutSequenceInput>>;
};

export type LeadUpdateManyWithoutSibling_Lead_HistoriesInput = {
  connect?: InputMaybe<Array<LeadWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<LeadCreateOrConnectWithoutSibling_Lead_HistoriesInput>>;
  create?: InputMaybe<Array<LeadCreateWithoutSibling_Lead_HistoriesInput>>;
  delete?: InputMaybe<Array<LeadWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<LeadScalarWhereInput>>;
  disconnect?: InputMaybe<Array<LeadWhereUniqueInput>>;
  set?: InputMaybe<Array<LeadWhereUniqueInput>>;
  update?: InputMaybe<Array<LeadUpdateWithWhereUniqueWithoutSibling_Lead_HistoriesInput>>;
  updateMany?: InputMaybe<Array<LeadUpdateManyWithWhereWithoutSibling_Lead_HistoriesInput>>;
  upsert?: InputMaybe<Array<LeadUpsertWithWhereUniqueWithoutSibling_Lead_HistoriesInput>>;
};

export type LeadUpdateManyWithWhereWithoutAssociate_Group_Lead_ActivitiesInput = {
  data: LeadUpdateManyMutationInput;
  where: LeadScalarWhereInput;
};

export type LeadUpdateManyWithWhereWithoutAssociate_Parent_LeadInput = {
  data: LeadUpdateManyMutationInput;
  where: LeadScalarWhereInput;
};

export type LeadUpdateManyWithWhereWithoutFavorited_By_UsersInput = {
  data: LeadUpdateManyMutationInput;
  where: LeadScalarWhereInput;
};

export type LeadUpdateManyWithWhereWithoutLast_Call_Result_RepInput = {
  data: LeadUpdateManyMutationInput;
  where: LeadScalarWhereInput;
};

export type LeadUpdateManyWithWhereWithoutLead_Import_History_CsvInput = {
  data: LeadUpdateManyMutationInput;
  where: LeadScalarWhereInput;
};

export type LeadUpdateManyWithWhereWithoutNext_Scheduled_EventInput = {
  data: LeadUpdateManyMutationInput;
  where: LeadScalarWhereInput;
};

export type LeadUpdateManyWithWhereWithoutOrganizationInput = {
  data: LeadUpdateManyMutationInput;
  where: LeadScalarWhereInput;
};

export type LeadUpdateManyWithWhereWithoutRepInput = {
  data: LeadUpdateManyMutationInput;
  where: LeadScalarWhereInput;
};

export type LeadUpdateManyWithWhereWithoutRouting_RuleInput = {
  data: LeadUpdateManyMutationInput;
  where: LeadScalarWhereInput;
};

export type LeadUpdateManyWithWhereWithoutSecondary_RepsInput = {
  data: LeadUpdateManyMutationInput;
  where: LeadScalarWhereInput;
};

export type LeadUpdateManyWithWhereWithoutSequence_StepInput = {
  data: LeadUpdateManyMutationInput;
  where: LeadScalarWhereInput;
};

export type LeadUpdateManyWithWhereWithoutSequenceInput = {
  data: LeadUpdateManyMutationInput;
  where: LeadScalarWhereInput;
};

export type LeadUpdateManyWithWhereWithoutSibling_Lead_HistoriesInput = {
  data: LeadUpdateManyMutationInput;
  where: LeadScalarWhereInput;
};

export type LeadUpdateOneRequiredWithoutAssociate_Lead_HistoriesInput = {
  connect?: InputMaybe<LeadWhereUniqueInput>;
  connectOrCreate?: InputMaybe<LeadCreateOrConnectWithoutAssociate_Lead_HistoriesInput>;
  create?: InputMaybe<LeadCreateWithoutAssociate_Lead_HistoriesInput>;
  update?: InputMaybe<LeadUpdateWithoutAssociate_Lead_HistoriesInput>;
  upsert?: InputMaybe<LeadUpsertWithoutAssociate_Lead_HistoriesInput>;
};

export type LeadUpdateOneRequiredWithoutCallMeNowAttemptsInput = {
  connect?: InputMaybe<LeadWhereUniqueInput>;
  connectOrCreate?: InputMaybe<LeadCreateOrConnectWithoutCallMeNowAttemptsInput>;
  create?: InputMaybe<LeadCreateWithoutCallMeNowAttemptsInput>;
  update?: InputMaybe<LeadUpdateWithoutCallMeNowAttemptsInput>;
  upsert?: InputMaybe<LeadUpsertWithoutCallMeNowAttemptsInput>;
};

export type LeadUpdateOneRequiredWithoutCallMeNowsInput = {
  connect?: InputMaybe<LeadWhereUniqueInput>;
  connectOrCreate?: InputMaybe<LeadCreateOrConnectWithoutCallMeNowsInput>;
  create?: InputMaybe<LeadCreateWithoutCallMeNowsInput>;
  update?: InputMaybe<LeadUpdateWithoutCallMeNowsInput>;
  upsert?: InputMaybe<LeadUpsertWithoutCallMeNowsInput>;
};

export type LeadUpdateOneRequiredWithoutCustom_Queue_ItemsInput = {
  connect?: InputMaybe<LeadWhereUniqueInput>;
  connectOrCreate?: InputMaybe<LeadCreateOrConnectWithoutCustom_Queue_ItemsInput>;
  create?: InputMaybe<LeadCreateWithoutCustom_Queue_ItemsInput>;
  update?: InputMaybe<LeadUpdateWithoutCustom_Queue_ItemsInput>;
  upsert?: InputMaybe<LeadUpsertWithoutCustom_Queue_ItemsInput>;
};

export type LeadUpdateOneRequiredWithoutLead_ActivitiesInput = {
  connect?: InputMaybe<LeadWhereUniqueInput>;
  connectOrCreate?: InputMaybe<LeadCreateOrConnectWithoutLead_ActivitiesInput>;
  create?: InputMaybe<LeadCreateWithoutLead_ActivitiesInput>;
  update?: InputMaybe<LeadUpdateWithoutLead_ActivitiesInput>;
  upsert?: InputMaybe<LeadUpsertWithoutLead_ActivitiesInput>;
};

export type LeadUpdateOneRequiredWithoutLead_Custom_Row_InfoInput = {
  connect?: InputMaybe<LeadWhereUniqueInput>;
  connectOrCreate?: InputMaybe<LeadCreateOrConnectWithoutLead_Custom_Row_InfoInput>;
  create?: InputMaybe<LeadCreateWithoutLead_Custom_Row_InfoInput>;
  update?: InputMaybe<LeadUpdateWithoutLead_Custom_Row_InfoInput>;
  upsert?: InputMaybe<LeadUpsertWithoutLead_Custom_Row_InfoInput>;
};

export type LeadUpdateOneRequiredWithoutLead_IntentInput = {
  connect?: InputMaybe<LeadWhereUniqueInput>;
  connectOrCreate?: InputMaybe<LeadCreateOrConnectWithoutLead_IntentInput>;
  create?: InputMaybe<LeadCreateWithoutLead_IntentInput>;
  update?: InputMaybe<LeadUpdateWithoutLead_IntentInput>;
  upsert?: InputMaybe<LeadUpsertWithoutLead_IntentInput>;
};

export type LeadUpdateOneRequiredWithoutRouting_HistoryInput = {
  connect?: InputMaybe<LeadWhereUniqueInput>;
  connectOrCreate?: InputMaybe<LeadCreateOrConnectWithoutRouting_HistoryInput>;
  create?: InputMaybe<LeadCreateWithoutRouting_HistoryInput>;
  update?: InputMaybe<LeadUpdateWithoutRouting_HistoryInput>;
  upsert?: InputMaybe<LeadUpsertWithoutRouting_HistoryInput>;
};

export type LeadUpdateOneRequiredWithoutSale_CyclesInput = {
  connect?: InputMaybe<LeadWhereUniqueInput>;
  connectOrCreate?: InputMaybe<LeadCreateOrConnectWithoutSale_CyclesInput>;
  create?: InputMaybe<LeadCreateWithoutSale_CyclesInput>;
  update?: InputMaybe<LeadUpdateWithoutSale_CyclesInput>;
  upsert?: InputMaybe<LeadUpsertWithoutSale_CyclesInput>;
};

export type LeadUpdateOneRequiredWithoutSequenceActionLogInput = {
  connect?: InputMaybe<LeadWhereUniqueInput>;
  connectOrCreate?: InputMaybe<LeadCreateOrConnectWithoutSequenceActionLogInput>;
  create?: InputMaybe<LeadCreateWithoutSequenceActionLogInput>;
  update?: InputMaybe<LeadUpdateWithoutSequenceActionLogInput>;
  upsert?: InputMaybe<LeadUpsertWithoutSequenceActionLogInput>;
};

export type LeadUpdateOneRequiredWithoutSequenceEntryExitLeadInput = {
  connect?: InputMaybe<LeadWhereUniqueInput>;
  connectOrCreate?: InputMaybe<LeadCreateOrConnectWithoutSequenceEntryExitLeadInput>;
  create?: InputMaybe<LeadCreateWithoutSequenceEntryExitLeadInput>;
  update?: InputMaybe<LeadUpdateWithoutSequenceEntryExitLeadInput>;
  upsert?: InputMaybe<LeadUpsertWithoutSequenceEntryExitLeadInput>;
};

export type LeadUpdateOneWithoutAlternate_ContactsInput = {
  connect?: InputMaybe<LeadWhereUniqueInput>;
  connectOrCreate?: InputMaybe<LeadCreateOrConnectWithoutAlternate_ContactsInput>;
  create?: InputMaybe<LeadCreateWithoutAlternate_ContactsInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<LeadUpdateWithoutAlternate_ContactsInput>;
  upsert?: InputMaybe<LeadUpsertWithoutAlternate_ContactsInput>;
};

export type LeadUpdateOneWithoutAssociate_ChildrenInput = {
  connect?: InputMaybe<LeadWhereUniqueInput>;
  connectOrCreate?: InputMaybe<LeadCreateOrConnectWithoutAssociate_ChildrenInput>;
  create?: InputMaybe<LeadCreateWithoutAssociate_ChildrenInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<LeadUpdateWithoutAssociate_ChildrenInput>;
  upsert?: InputMaybe<LeadUpsertWithoutAssociate_ChildrenInput>;
};

export type LeadUpdateOneWithoutAssociated_Parent_IntentsInput = {
  connect?: InputMaybe<LeadWhereUniqueInput>;
  connectOrCreate?: InputMaybe<LeadCreateOrConnectWithoutAssociated_Parent_IntentsInput>;
  create?: InputMaybe<LeadCreateWithoutAssociated_Parent_IntentsInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<LeadUpdateWithoutAssociated_Parent_IntentsInput>;
  upsert?: InputMaybe<LeadUpsertWithoutAssociated_Parent_IntentsInput>;
};

export type LeadUpdateOneWithoutChild_Lead_ActivitesInput = {
  connect?: InputMaybe<LeadWhereUniqueInput>;
  connectOrCreate?: InputMaybe<LeadCreateOrConnectWithoutChild_Lead_ActivitesInput>;
  create?: InputMaybe<LeadCreateWithoutChild_Lead_ActivitesInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<LeadUpdateWithoutChild_Lead_ActivitesInput>;
  upsert?: InputMaybe<LeadUpsertWithoutChild_Lead_ActivitesInput>;
};

export type LeadUpdateOneWithoutContact_RequestsInput = {
  connect?: InputMaybe<LeadWhereUniqueInput>;
  connectOrCreate?: InputMaybe<LeadCreateOrConnectWithoutContact_RequestsInput>;
  create?: InputMaybe<LeadCreateWithoutContact_RequestsInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<LeadUpdateWithoutContact_RequestsInput>;
  upsert?: InputMaybe<LeadUpsertWithoutContact_RequestsInput>;
};

export type LeadUpdateOneWithoutCurrent_Sale_CycleInput = {
  connect?: InputMaybe<LeadWhereUniqueInput>;
  connectOrCreate?: InputMaybe<LeadCreateOrConnectWithoutCurrent_Sale_CycleInput>;
  create?: InputMaybe<LeadCreateWithoutCurrent_Sale_CycleInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<LeadUpdateWithoutCurrent_Sale_CycleInput>;
  upsert?: InputMaybe<LeadUpsertWithoutCurrent_Sale_CycleInput>;
};

export type LeadUpdateOneWithoutCustom_FieldsInput = {
  connect?: InputMaybe<LeadWhereUniqueInput>;
  connectOrCreate?: InputMaybe<LeadCreateOrConnectWithoutCustom_FieldsInput>;
  create?: InputMaybe<LeadCreateWithoutCustom_FieldsInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<LeadUpdateWithoutCustom_FieldsInput>;
  upsert?: InputMaybe<LeadUpsertWithoutCustom_FieldsInput>;
};

export type LeadUpdateOneWithoutEmailSyncRecordInput = {
  connect?: InputMaybe<LeadWhereUniqueInput>;
  connectOrCreate?: InputMaybe<LeadCreateOrConnectWithoutEmailSyncRecordInput>;
  create?: InputMaybe<LeadCreateWithoutEmailSyncRecordInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<LeadUpdateWithoutEmailSyncRecordInput>;
  upsert?: InputMaybe<LeadUpsertWithoutEmailSyncRecordInput>;
};

export type LeadUpdateOneWithoutEmailThreadInput = {
  connect?: InputMaybe<LeadWhereUniqueInput>;
  connectOrCreate?: InputMaybe<LeadCreateOrConnectWithoutEmailThreadInput>;
  create?: InputMaybe<LeadCreateWithoutEmailThreadInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<LeadUpdateWithoutEmailThreadInput>;
  upsert?: InputMaybe<LeadUpsertWithoutEmailThreadInput>;
};

export type LeadUpdateOneWithoutInboundConciergeEventsInput = {
  connect?: InputMaybe<LeadWhereUniqueInput>;
  connectOrCreate?: InputMaybe<LeadCreateOrConnectWithoutInboundConciergeEventsInput>;
  create?: InputMaybe<LeadCreateWithoutInboundConciergeEventsInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<LeadUpdateWithoutInboundConciergeEventsInput>;
  upsert?: InputMaybe<LeadUpsertWithoutInboundConciergeEventsInput>;
};

export type LeadUpdateOneWithoutIntegrationOperationLogInput = {
  connect?: InputMaybe<LeadWhereUniqueInput>;
  connectOrCreate?: InputMaybe<LeadCreateOrConnectWithoutIntegrationOperationLogInput>;
  create?: InputMaybe<LeadCreateWithoutIntegrationOperationLogInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<LeadUpdateWithoutIntegrationOperationLogInput>;
  upsert?: InputMaybe<LeadUpsertWithoutIntegrationOperationLogInput>;
};

export type LeadUpdateOneWithoutLead_Assignment_HistoryInput = {
  connect?: InputMaybe<LeadWhereUniqueInput>;
  connectOrCreate?: InputMaybe<LeadCreateOrConnectWithoutLead_Assignment_HistoryInput>;
  create?: InputMaybe<LeadCreateWithoutLead_Assignment_HistoryInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<LeadUpdateWithoutLead_Assignment_HistoryInput>;
  upsert?: InputMaybe<LeadUpsertWithoutLead_Assignment_HistoryInput>;
};

export type LeadUpdateOneWithoutLead_Import_HistoryInput = {
  connect?: InputMaybe<LeadWhereUniqueInput>;
  connectOrCreate?: InputMaybe<LeadCreateOrConnectWithoutLead_Import_HistoryInput>;
  create?: InputMaybe<LeadCreateWithoutLead_Import_HistoryInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<LeadUpdateWithoutLead_Import_HistoryInput>;
  upsert?: InputMaybe<LeadUpsertWithoutLead_Import_HistoryInput>;
};

export type LeadUpdateOneWithoutLead_ValueInput = {
  connect?: InputMaybe<LeadWhereUniqueInput>;
  connectOrCreate?: InputMaybe<LeadCreateOrConnectWithoutLead_ValueInput>;
  create?: InputMaybe<LeadCreateWithoutLead_ValueInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<LeadUpdateWithoutLead_ValueInput>;
  upsert?: InputMaybe<LeadUpsertWithoutLead_ValueInput>;
};

export type LeadUpdateOneWithoutNotificationsInput = {
  connect?: InputMaybe<LeadWhereUniqueInput>;
  connectOrCreate?: InputMaybe<LeadCreateOrConnectWithoutNotificationsInput>;
  create?: InputMaybe<LeadCreateWithoutNotificationsInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<LeadUpdateWithoutNotificationsInput>;
  upsert?: InputMaybe<LeadUpsertWithoutNotificationsInput>;
};

export type LeadUpdateOneWithoutPrimary_Lead_HistoriesInput = {
  connect?: InputMaybe<LeadWhereUniqueInput>;
  connectOrCreate?: InputMaybe<LeadCreateOrConnectWithoutPrimary_Lead_HistoriesInput>;
  create?: InputMaybe<LeadCreateWithoutPrimary_Lead_HistoriesInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<LeadUpdateWithoutPrimary_Lead_HistoriesInput>;
  upsert?: InputMaybe<LeadUpsertWithoutPrimary_Lead_HistoriesInput>;
};

export type LeadUpdateOneWithoutSaleInput = {
  connect?: InputMaybe<LeadWhereUniqueInput>;
  connectOrCreate?: InputMaybe<LeadCreateOrConnectWithoutSaleInput>;
  create?: InputMaybe<LeadCreateWithoutSaleInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<LeadUpdateWithoutSaleInput>;
  upsert?: InputMaybe<LeadUpsertWithoutSaleInput>;
};

export type LeadUpdateOneWithoutStat_ItemsInput = {
  connect?: InputMaybe<LeadWhereUniqueInput>;
  connectOrCreate?: InputMaybe<LeadCreateOrConnectWithoutStat_ItemsInput>;
  create?: InputMaybe<LeadCreateWithoutStat_ItemsInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<LeadUpdateWithoutStat_ItemsInput>;
  upsert?: InputMaybe<LeadUpsertWithoutStat_ItemsInput>;
};

export type LeadUpdateOneWithoutStatLeadAggregationInput = {
  connect?: InputMaybe<LeadWhereUniqueInput>;
  connectOrCreate?: InputMaybe<LeadCreateOrConnectWithoutStatLeadAggregationInput>;
  create?: InputMaybe<LeadCreateWithoutStatLeadAggregationInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<LeadUpdateWithoutStatLeadAggregationInput>;
  upsert?: InputMaybe<LeadUpsertWithoutStatLeadAggregationInput>;
};

export type LeadUpdatesequences_Completed_IdsInput = {
  push?: InputMaybe<Scalars['String']['input']>;
  set?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type LeadUpdatesequences_Joined_IdsInput = {
  push?: InputMaybe<Scalars['String']['input']>;
  set?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type LeadUpdateWithoutAlternate_ContactsInput = {
  address?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  address_2?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  assignment_locked?: InputMaybe<BoolFieldUpdateOperationsInput>;
  associate_children?: InputMaybe<LeadUpdateManyWithoutAssociate_Parent_LeadInput>;
  associate_group_lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutAssociate_Group_LeadsInput>;
  associate_lead_histories?: InputMaybe<AssociationHistoryUpdateManyWithoutAssociate_LeadInput>;
  associate_parent_lead?: InputMaybe<LeadUpdateOneWithoutAssociate_ChildrenInput>;
  associated_parent_intents?: InputMaybe<LeadIntentUpdateManyWithoutAssociate_ParentInput>;
  associates_search_content?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  business_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptUpdateManyWithoutLeadInput>;
  callMeNows?: InputMaybe<CallMeNowUpdateManyWithoutLeadInput>;
  channel?: InputMaybe<NullableEnumChannelFieldUpdateOperationsInput>;
  child_lead_activites?: InputMaybe<LeadActivityUpdateManyWithoutAssociate_ChildInput>;
  city?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  claim_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  conference_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  contact_requests?: InputMaybe<ContactRequestUpdateManyWithoutLeadInput>;
  content?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  current_close_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  current_contact_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  current_sale_cycle?: InputMaybe<SaleCycleUpdateOneWithoutLead_If_CurrentInput>;
  custom_field_json?: InputMaybe<Scalars['Json']['input']>;
  custom_fields?: InputMaybe<CustomFieldUpdateManyWithoutLeadInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemUpdateManyWithoutLeadInput>;
  delay_action_status?: InputMaybe<BoolFieldUpdateOperationsInput>;
  email_sub_status?: InputMaybe<EnumOptInStatusFieldUpdateOperationsInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordUpdateManyWithoutLeadInput>;
  EmailThread?: InputMaybe<EmailThreadUpdateManyWithoutLeadInput>;
  favorited_by_users?: InputMaybe<UserUpdateManyWithoutFavorite_LeadsInput>;
  first_demo_schedule_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  first_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  hubspot_company_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  hubspot_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  implied_next_action?: InputMaybe<EnumHingepointFieldUpdateOperationsInput>;
  in_algo_queue?: InputMaybe<BoolFieldUpdateOperationsInput>;
  in_custom_sequence?: InputMaybe<IntFieldUpdateOperationsInput>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventUpdateManyWithoutLeadInput>;
  industry?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogUpdateManyWithoutLeadInput>;
  last_call_result?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  last_call_result_rep?: InputMaybe<UserUpdateOneWithoutLeads_Last_CalledInput>;
  last_call_result_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  last_lead_assignment_origin?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  last_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  last_sent_to_resting_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutLeadInput>;
  lead_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutLeadInput>;
  lead_assignment_origin?: InputMaybe<EnumAssignmentOriginFieldUpdateOperationsInput>;
  lead_creation_source?: InputMaybe<NullableEnumLeadcreationsourceFieldUpdateOperationsInput>;
  lead_custom_row_info?: InputMaybe<LeadCustomObjectRowAssociationUpdateManyWithoutLeadInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryUpdateOneWithoutLeadInput>;
  lead_import_history_csv?: InputMaybe<LeadImportHistoryUpdateOneWithoutUploaded_LeadsInput>;
  lead_intent?: InputMaybe<LeadIntentUpdateManyWithoutLeadInput>;
  lead_ownership_status?: InputMaybe<EnumLosFieldUpdateOperationsInput>;
  lead_source?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  lead_value?: InputMaybe<LeadValueUpdateOneWithoutLeadInput>;
  mrr?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  newIncoming?: InputMaybe<BoolFieldUpdateOperationsInput>;
  next_scheduled_event?: InputMaybe<ScheduleItemUpdateOneWithoutLeadInput>;
  notifications?: InputMaybe<NotificationUpdateManyWithoutLeadInput>;
  opsiq_whitelist?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutLeadsInput>;
  phase?: InputMaybe<EnumPhaseFieldUpdateOperationsInput>;
  primary_email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_email_title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_email_type?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_lead_histories?: InputMaybe<AssociationHistoryUpdateManyWithoutPrimary_LeadInput>;
  primary_phone_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_phone_number_country_code?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_phone_number_title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_phone_number_type?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  rep?: InputMaybe<UserUpdateOneWithoutOwned_LeadsInput>;
  resting_type?: InputMaybe<NullableEnumRestingtypeFieldUpdateOperationsInput>;
  routing_history?: InputMaybe<RoutingHistoryUpdateManyWithoutLeadInput>;
  routing_rule?: InputMaybe<RuleUpdateOneWithoutLeadInput>;
  Sale?: InputMaybe<SaleUpdateManyWithoutLeadInput>;
  sale_cycles?: InputMaybe<SaleCycleUpdateManyWithoutLeadInput>;
  search_content?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  secondary_reps?: InputMaybe<UserUpdateManyWithoutIdle_Owned_LeadsInput>;
  self_sourced?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  sequence?: InputMaybe<SequenceUpdateOneWithoutLeadsInput>;
  sequence_step?: InputMaybe<SequenceStepUpdateOneWithoutLeadsInput>;
  SequenceActionLog?: InputMaybe<StepActionLogUpdateManyWithoutLeadInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutLeadInput>;
  sequences_completed_ids?: InputMaybe<LeadUpdatesequences_Completed_IdsInput>;
  sequences_joined_ids?: InputMaybe<LeadUpdatesequences_Joined_IdsInput>;
  sf_account_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sf_contact_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sf_lead_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sibling_lead_histories?: InputMaybe<AssociationHistoryUpdateManyWithoutSibling_LeadsInput>;
  sms_sub_status?: InputMaybe<EnumOptInStatusFieldUpdateOperationsInput>;
  stat_items?: InputMaybe<StatItemAggregationUpdateManyWithoutLeadInput>;
  state?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  StatLeadAggregation?: InputMaybe<StatLeadAggregationUpdateOneWithoutLeadInput>;
  status?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sub_industry?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timezone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  zip?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type LeadUpdateWithoutAssociate_ChildrenInput = {
  address?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  address_2?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  alternate_contacts?: InputMaybe<ContactUpdateManyWithoutLeadInput>;
  assignment_locked?: InputMaybe<BoolFieldUpdateOperationsInput>;
  associate_group_lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutAssociate_Group_LeadsInput>;
  associate_lead_histories?: InputMaybe<AssociationHistoryUpdateManyWithoutAssociate_LeadInput>;
  associate_parent_lead?: InputMaybe<LeadUpdateOneWithoutAssociate_ChildrenInput>;
  associated_parent_intents?: InputMaybe<LeadIntentUpdateManyWithoutAssociate_ParentInput>;
  associates_search_content?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  business_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptUpdateManyWithoutLeadInput>;
  callMeNows?: InputMaybe<CallMeNowUpdateManyWithoutLeadInput>;
  channel?: InputMaybe<NullableEnumChannelFieldUpdateOperationsInput>;
  child_lead_activites?: InputMaybe<LeadActivityUpdateManyWithoutAssociate_ChildInput>;
  city?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  claim_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  conference_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  contact_requests?: InputMaybe<ContactRequestUpdateManyWithoutLeadInput>;
  content?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  current_close_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  current_contact_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  current_sale_cycle?: InputMaybe<SaleCycleUpdateOneWithoutLead_If_CurrentInput>;
  custom_field_json?: InputMaybe<Scalars['Json']['input']>;
  custom_fields?: InputMaybe<CustomFieldUpdateManyWithoutLeadInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemUpdateManyWithoutLeadInput>;
  delay_action_status?: InputMaybe<BoolFieldUpdateOperationsInput>;
  email_sub_status?: InputMaybe<EnumOptInStatusFieldUpdateOperationsInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordUpdateManyWithoutLeadInput>;
  EmailThread?: InputMaybe<EmailThreadUpdateManyWithoutLeadInput>;
  favorited_by_users?: InputMaybe<UserUpdateManyWithoutFavorite_LeadsInput>;
  first_demo_schedule_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  first_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  hubspot_company_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  hubspot_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  implied_next_action?: InputMaybe<EnumHingepointFieldUpdateOperationsInput>;
  in_algo_queue?: InputMaybe<BoolFieldUpdateOperationsInput>;
  in_custom_sequence?: InputMaybe<IntFieldUpdateOperationsInput>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventUpdateManyWithoutLeadInput>;
  industry?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogUpdateManyWithoutLeadInput>;
  last_call_result?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  last_call_result_rep?: InputMaybe<UserUpdateOneWithoutLeads_Last_CalledInput>;
  last_call_result_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  last_lead_assignment_origin?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  last_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  last_sent_to_resting_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutLeadInput>;
  lead_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutLeadInput>;
  lead_assignment_origin?: InputMaybe<EnumAssignmentOriginFieldUpdateOperationsInput>;
  lead_creation_source?: InputMaybe<NullableEnumLeadcreationsourceFieldUpdateOperationsInput>;
  lead_custom_row_info?: InputMaybe<LeadCustomObjectRowAssociationUpdateManyWithoutLeadInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryUpdateOneWithoutLeadInput>;
  lead_import_history_csv?: InputMaybe<LeadImportHistoryUpdateOneWithoutUploaded_LeadsInput>;
  lead_intent?: InputMaybe<LeadIntentUpdateManyWithoutLeadInput>;
  lead_ownership_status?: InputMaybe<EnumLosFieldUpdateOperationsInput>;
  lead_source?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  lead_value?: InputMaybe<LeadValueUpdateOneWithoutLeadInput>;
  mrr?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  newIncoming?: InputMaybe<BoolFieldUpdateOperationsInput>;
  next_scheduled_event?: InputMaybe<ScheduleItemUpdateOneWithoutLeadInput>;
  notifications?: InputMaybe<NotificationUpdateManyWithoutLeadInput>;
  opsiq_whitelist?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutLeadsInput>;
  phase?: InputMaybe<EnumPhaseFieldUpdateOperationsInput>;
  primary_email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_email_title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_email_type?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_lead_histories?: InputMaybe<AssociationHistoryUpdateManyWithoutPrimary_LeadInput>;
  primary_phone_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_phone_number_country_code?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_phone_number_title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_phone_number_type?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  rep?: InputMaybe<UserUpdateOneWithoutOwned_LeadsInput>;
  resting_type?: InputMaybe<NullableEnumRestingtypeFieldUpdateOperationsInput>;
  routing_history?: InputMaybe<RoutingHistoryUpdateManyWithoutLeadInput>;
  routing_rule?: InputMaybe<RuleUpdateOneWithoutLeadInput>;
  Sale?: InputMaybe<SaleUpdateManyWithoutLeadInput>;
  sale_cycles?: InputMaybe<SaleCycleUpdateManyWithoutLeadInput>;
  search_content?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  secondary_reps?: InputMaybe<UserUpdateManyWithoutIdle_Owned_LeadsInput>;
  self_sourced?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  sequence?: InputMaybe<SequenceUpdateOneWithoutLeadsInput>;
  sequence_step?: InputMaybe<SequenceStepUpdateOneWithoutLeadsInput>;
  SequenceActionLog?: InputMaybe<StepActionLogUpdateManyWithoutLeadInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutLeadInput>;
  sequences_completed_ids?: InputMaybe<LeadUpdatesequences_Completed_IdsInput>;
  sequences_joined_ids?: InputMaybe<LeadUpdatesequences_Joined_IdsInput>;
  sf_account_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sf_contact_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sf_lead_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sibling_lead_histories?: InputMaybe<AssociationHistoryUpdateManyWithoutSibling_LeadsInput>;
  sms_sub_status?: InputMaybe<EnumOptInStatusFieldUpdateOperationsInput>;
  stat_items?: InputMaybe<StatItemAggregationUpdateManyWithoutLeadInput>;
  state?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  StatLeadAggregation?: InputMaybe<StatLeadAggregationUpdateOneWithoutLeadInput>;
  status?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sub_industry?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timezone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  zip?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type LeadUpdateWithoutAssociate_Group_Lead_ActivitiesInput = {
  address?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  address_2?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  alternate_contacts?: InputMaybe<ContactUpdateManyWithoutLeadInput>;
  assignment_locked?: InputMaybe<BoolFieldUpdateOperationsInput>;
  associate_children?: InputMaybe<LeadUpdateManyWithoutAssociate_Parent_LeadInput>;
  associate_lead_histories?: InputMaybe<AssociationHistoryUpdateManyWithoutAssociate_LeadInput>;
  associate_parent_lead?: InputMaybe<LeadUpdateOneWithoutAssociate_ChildrenInput>;
  associated_parent_intents?: InputMaybe<LeadIntentUpdateManyWithoutAssociate_ParentInput>;
  associates_search_content?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  business_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptUpdateManyWithoutLeadInput>;
  callMeNows?: InputMaybe<CallMeNowUpdateManyWithoutLeadInput>;
  channel?: InputMaybe<NullableEnumChannelFieldUpdateOperationsInput>;
  child_lead_activites?: InputMaybe<LeadActivityUpdateManyWithoutAssociate_ChildInput>;
  city?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  claim_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  conference_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  contact_requests?: InputMaybe<ContactRequestUpdateManyWithoutLeadInput>;
  content?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  current_close_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  current_contact_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  current_sale_cycle?: InputMaybe<SaleCycleUpdateOneWithoutLead_If_CurrentInput>;
  custom_field_json?: InputMaybe<Scalars['Json']['input']>;
  custom_fields?: InputMaybe<CustomFieldUpdateManyWithoutLeadInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemUpdateManyWithoutLeadInput>;
  delay_action_status?: InputMaybe<BoolFieldUpdateOperationsInput>;
  email_sub_status?: InputMaybe<EnumOptInStatusFieldUpdateOperationsInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordUpdateManyWithoutLeadInput>;
  EmailThread?: InputMaybe<EmailThreadUpdateManyWithoutLeadInput>;
  favorited_by_users?: InputMaybe<UserUpdateManyWithoutFavorite_LeadsInput>;
  first_demo_schedule_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  first_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  hubspot_company_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  hubspot_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  implied_next_action?: InputMaybe<EnumHingepointFieldUpdateOperationsInput>;
  in_algo_queue?: InputMaybe<BoolFieldUpdateOperationsInput>;
  in_custom_sequence?: InputMaybe<IntFieldUpdateOperationsInput>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventUpdateManyWithoutLeadInput>;
  industry?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogUpdateManyWithoutLeadInput>;
  last_call_result?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  last_call_result_rep?: InputMaybe<UserUpdateOneWithoutLeads_Last_CalledInput>;
  last_call_result_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  last_lead_assignment_origin?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  last_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  last_sent_to_resting_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutLeadInput>;
  lead_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutLeadInput>;
  lead_assignment_origin?: InputMaybe<EnumAssignmentOriginFieldUpdateOperationsInput>;
  lead_creation_source?: InputMaybe<NullableEnumLeadcreationsourceFieldUpdateOperationsInput>;
  lead_custom_row_info?: InputMaybe<LeadCustomObjectRowAssociationUpdateManyWithoutLeadInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryUpdateOneWithoutLeadInput>;
  lead_import_history_csv?: InputMaybe<LeadImportHistoryUpdateOneWithoutUploaded_LeadsInput>;
  lead_intent?: InputMaybe<LeadIntentUpdateManyWithoutLeadInput>;
  lead_ownership_status?: InputMaybe<EnumLosFieldUpdateOperationsInput>;
  lead_source?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  lead_value?: InputMaybe<LeadValueUpdateOneWithoutLeadInput>;
  mrr?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  newIncoming?: InputMaybe<BoolFieldUpdateOperationsInput>;
  next_scheduled_event?: InputMaybe<ScheduleItemUpdateOneWithoutLeadInput>;
  notifications?: InputMaybe<NotificationUpdateManyWithoutLeadInput>;
  opsiq_whitelist?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutLeadsInput>;
  phase?: InputMaybe<EnumPhaseFieldUpdateOperationsInput>;
  primary_email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_email_title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_email_type?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_lead_histories?: InputMaybe<AssociationHistoryUpdateManyWithoutPrimary_LeadInput>;
  primary_phone_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_phone_number_country_code?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_phone_number_title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_phone_number_type?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  rep?: InputMaybe<UserUpdateOneWithoutOwned_LeadsInput>;
  resting_type?: InputMaybe<NullableEnumRestingtypeFieldUpdateOperationsInput>;
  routing_history?: InputMaybe<RoutingHistoryUpdateManyWithoutLeadInput>;
  routing_rule?: InputMaybe<RuleUpdateOneWithoutLeadInput>;
  Sale?: InputMaybe<SaleUpdateManyWithoutLeadInput>;
  sale_cycles?: InputMaybe<SaleCycleUpdateManyWithoutLeadInput>;
  search_content?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  secondary_reps?: InputMaybe<UserUpdateManyWithoutIdle_Owned_LeadsInput>;
  self_sourced?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  sequence?: InputMaybe<SequenceUpdateOneWithoutLeadsInput>;
  sequence_step?: InputMaybe<SequenceStepUpdateOneWithoutLeadsInput>;
  SequenceActionLog?: InputMaybe<StepActionLogUpdateManyWithoutLeadInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutLeadInput>;
  sequences_completed_ids?: InputMaybe<LeadUpdatesequences_Completed_IdsInput>;
  sequences_joined_ids?: InputMaybe<LeadUpdatesequences_Joined_IdsInput>;
  sf_account_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sf_contact_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sf_lead_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sibling_lead_histories?: InputMaybe<AssociationHistoryUpdateManyWithoutSibling_LeadsInput>;
  sms_sub_status?: InputMaybe<EnumOptInStatusFieldUpdateOperationsInput>;
  stat_items?: InputMaybe<StatItemAggregationUpdateManyWithoutLeadInput>;
  state?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  StatLeadAggregation?: InputMaybe<StatLeadAggregationUpdateOneWithoutLeadInput>;
  status?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sub_industry?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timezone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  zip?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type LeadUpdateWithoutAssociate_Lead_HistoriesInput = {
  address?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  address_2?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  alternate_contacts?: InputMaybe<ContactUpdateManyWithoutLeadInput>;
  assignment_locked?: InputMaybe<BoolFieldUpdateOperationsInput>;
  associate_children?: InputMaybe<LeadUpdateManyWithoutAssociate_Parent_LeadInput>;
  associate_group_lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutAssociate_Group_LeadsInput>;
  associate_parent_lead?: InputMaybe<LeadUpdateOneWithoutAssociate_ChildrenInput>;
  associated_parent_intents?: InputMaybe<LeadIntentUpdateManyWithoutAssociate_ParentInput>;
  associates_search_content?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  business_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptUpdateManyWithoutLeadInput>;
  callMeNows?: InputMaybe<CallMeNowUpdateManyWithoutLeadInput>;
  channel?: InputMaybe<NullableEnumChannelFieldUpdateOperationsInput>;
  child_lead_activites?: InputMaybe<LeadActivityUpdateManyWithoutAssociate_ChildInput>;
  city?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  claim_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  conference_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  contact_requests?: InputMaybe<ContactRequestUpdateManyWithoutLeadInput>;
  content?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  current_close_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  current_contact_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  current_sale_cycle?: InputMaybe<SaleCycleUpdateOneWithoutLead_If_CurrentInput>;
  custom_field_json?: InputMaybe<Scalars['Json']['input']>;
  custom_fields?: InputMaybe<CustomFieldUpdateManyWithoutLeadInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemUpdateManyWithoutLeadInput>;
  delay_action_status?: InputMaybe<BoolFieldUpdateOperationsInput>;
  email_sub_status?: InputMaybe<EnumOptInStatusFieldUpdateOperationsInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordUpdateManyWithoutLeadInput>;
  EmailThread?: InputMaybe<EmailThreadUpdateManyWithoutLeadInput>;
  favorited_by_users?: InputMaybe<UserUpdateManyWithoutFavorite_LeadsInput>;
  first_demo_schedule_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  first_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  hubspot_company_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  hubspot_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  implied_next_action?: InputMaybe<EnumHingepointFieldUpdateOperationsInput>;
  in_algo_queue?: InputMaybe<BoolFieldUpdateOperationsInput>;
  in_custom_sequence?: InputMaybe<IntFieldUpdateOperationsInput>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventUpdateManyWithoutLeadInput>;
  industry?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogUpdateManyWithoutLeadInput>;
  last_call_result?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  last_call_result_rep?: InputMaybe<UserUpdateOneWithoutLeads_Last_CalledInput>;
  last_call_result_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  last_lead_assignment_origin?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  last_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  last_sent_to_resting_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutLeadInput>;
  lead_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutLeadInput>;
  lead_assignment_origin?: InputMaybe<EnumAssignmentOriginFieldUpdateOperationsInput>;
  lead_creation_source?: InputMaybe<NullableEnumLeadcreationsourceFieldUpdateOperationsInput>;
  lead_custom_row_info?: InputMaybe<LeadCustomObjectRowAssociationUpdateManyWithoutLeadInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryUpdateOneWithoutLeadInput>;
  lead_import_history_csv?: InputMaybe<LeadImportHistoryUpdateOneWithoutUploaded_LeadsInput>;
  lead_intent?: InputMaybe<LeadIntentUpdateManyWithoutLeadInput>;
  lead_ownership_status?: InputMaybe<EnumLosFieldUpdateOperationsInput>;
  lead_source?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  lead_value?: InputMaybe<LeadValueUpdateOneWithoutLeadInput>;
  mrr?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  newIncoming?: InputMaybe<BoolFieldUpdateOperationsInput>;
  next_scheduled_event?: InputMaybe<ScheduleItemUpdateOneWithoutLeadInput>;
  notifications?: InputMaybe<NotificationUpdateManyWithoutLeadInput>;
  opsiq_whitelist?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutLeadsInput>;
  phase?: InputMaybe<EnumPhaseFieldUpdateOperationsInput>;
  primary_email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_email_title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_email_type?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_lead_histories?: InputMaybe<AssociationHistoryUpdateManyWithoutPrimary_LeadInput>;
  primary_phone_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_phone_number_country_code?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_phone_number_title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_phone_number_type?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  rep?: InputMaybe<UserUpdateOneWithoutOwned_LeadsInput>;
  resting_type?: InputMaybe<NullableEnumRestingtypeFieldUpdateOperationsInput>;
  routing_history?: InputMaybe<RoutingHistoryUpdateManyWithoutLeadInput>;
  routing_rule?: InputMaybe<RuleUpdateOneWithoutLeadInput>;
  Sale?: InputMaybe<SaleUpdateManyWithoutLeadInput>;
  sale_cycles?: InputMaybe<SaleCycleUpdateManyWithoutLeadInput>;
  search_content?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  secondary_reps?: InputMaybe<UserUpdateManyWithoutIdle_Owned_LeadsInput>;
  self_sourced?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  sequence?: InputMaybe<SequenceUpdateOneWithoutLeadsInput>;
  sequence_step?: InputMaybe<SequenceStepUpdateOneWithoutLeadsInput>;
  SequenceActionLog?: InputMaybe<StepActionLogUpdateManyWithoutLeadInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutLeadInput>;
  sequences_completed_ids?: InputMaybe<LeadUpdatesequences_Completed_IdsInput>;
  sequences_joined_ids?: InputMaybe<LeadUpdatesequences_Joined_IdsInput>;
  sf_account_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sf_contact_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sf_lead_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sibling_lead_histories?: InputMaybe<AssociationHistoryUpdateManyWithoutSibling_LeadsInput>;
  sms_sub_status?: InputMaybe<EnumOptInStatusFieldUpdateOperationsInput>;
  stat_items?: InputMaybe<StatItemAggregationUpdateManyWithoutLeadInput>;
  state?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  StatLeadAggregation?: InputMaybe<StatLeadAggregationUpdateOneWithoutLeadInput>;
  status?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sub_industry?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timezone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  zip?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type LeadUpdateWithoutAssociate_Parent_LeadInput = {
  address?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  address_2?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  alternate_contacts?: InputMaybe<ContactUpdateManyWithoutLeadInput>;
  assignment_locked?: InputMaybe<BoolFieldUpdateOperationsInput>;
  associate_children?: InputMaybe<LeadUpdateManyWithoutAssociate_Parent_LeadInput>;
  associate_group_lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutAssociate_Group_LeadsInput>;
  associate_lead_histories?: InputMaybe<AssociationHistoryUpdateManyWithoutAssociate_LeadInput>;
  associated_parent_intents?: InputMaybe<LeadIntentUpdateManyWithoutAssociate_ParentInput>;
  associates_search_content?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  business_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptUpdateManyWithoutLeadInput>;
  callMeNows?: InputMaybe<CallMeNowUpdateManyWithoutLeadInput>;
  channel?: InputMaybe<NullableEnumChannelFieldUpdateOperationsInput>;
  child_lead_activites?: InputMaybe<LeadActivityUpdateManyWithoutAssociate_ChildInput>;
  city?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  claim_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  conference_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  contact_requests?: InputMaybe<ContactRequestUpdateManyWithoutLeadInput>;
  content?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  current_close_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  current_contact_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  current_sale_cycle?: InputMaybe<SaleCycleUpdateOneWithoutLead_If_CurrentInput>;
  custom_field_json?: InputMaybe<Scalars['Json']['input']>;
  custom_fields?: InputMaybe<CustomFieldUpdateManyWithoutLeadInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemUpdateManyWithoutLeadInput>;
  delay_action_status?: InputMaybe<BoolFieldUpdateOperationsInput>;
  email_sub_status?: InputMaybe<EnumOptInStatusFieldUpdateOperationsInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordUpdateManyWithoutLeadInput>;
  EmailThread?: InputMaybe<EmailThreadUpdateManyWithoutLeadInput>;
  favorited_by_users?: InputMaybe<UserUpdateManyWithoutFavorite_LeadsInput>;
  first_demo_schedule_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  first_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  hubspot_company_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  hubspot_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  implied_next_action?: InputMaybe<EnumHingepointFieldUpdateOperationsInput>;
  in_algo_queue?: InputMaybe<BoolFieldUpdateOperationsInput>;
  in_custom_sequence?: InputMaybe<IntFieldUpdateOperationsInput>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventUpdateManyWithoutLeadInput>;
  industry?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogUpdateManyWithoutLeadInput>;
  last_call_result?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  last_call_result_rep?: InputMaybe<UserUpdateOneWithoutLeads_Last_CalledInput>;
  last_call_result_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  last_lead_assignment_origin?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  last_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  last_sent_to_resting_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutLeadInput>;
  lead_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutLeadInput>;
  lead_assignment_origin?: InputMaybe<EnumAssignmentOriginFieldUpdateOperationsInput>;
  lead_creation_source?: InputMaybe<NullableEnumLeadcreationsourceFieldUpdateOperationsInput>;
  lead_custom_row_info?: InputMaybe<LeadCustomObjectRowAssociationUpdateManyWithoutLeadInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryUpdateOneWithoutLeadInput>;
  lead_import_history_csv?: InputMaybe<LeadImportHistoryUpdateOneWithoutUploaded_LeadsInput>;
  lead_intent?: InputMaybe<LeadIntentUpdateManyWithoutLeadInput>;
  lead_ownership_status?: InputMaybe<EnumLosFieldUpdateOperationsInput>;
  lead_source?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  lead_value?: InputMaybe<LeadValueUpdateOneWithoutLeadInput>;
  mrr?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  newIncoming?: InputMaybe<BoolFieldUpdateOperationsInput>;
  next_scheduled_event?: InputMaybe<ScheduleItemUpdateOneWithoutLeadInput>;
  notifications?: InputMaybe<NotificationUpdateManyWithoutLeadInput>;
  opsiq_whitelist?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutLeadsInput>;
  phase?: InputMaybe<EnumPhaseFieldUpdateOperationsInput>;
  primary_email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_email_title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_email_type?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_lead_histories?: InputMaybe<AssociationHistoryUpdateManyWithoutPrimary_LeadInput>;
  primary_phone_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_phone_number_country_code?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_phone_number_title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_phone_number_type?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  rep?: InputMaybe<UserUpdateOneWithoutOwned_LeadsInput>;
  resting_type?: InputMaybe<NullableEnumRestingtypeFieldUpdateOperationsInput>;
  routing_history?: InputMaybe<RoutingHistoryUpdateManyWithoutLeadInput>;
  routing_rule?: InputMaybe<RuleUpdateOneWithoutLeadInput>;
  Sale?: InputMaybe<SaleUpdateManyWithoutLeadInput>;
  sale_cycles?: InputMaybe<SaleCycleUpdateManyWithoutLeadInput>;
  search_content?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  secondary_reps?: InputMaybe<UserUpdateManyWithoutIdle_Owned_LeadsInput>;
  self_sourced?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  sequence?: InputMaybe<SequenceUpdateOneWithoutLeadsInput>;
  sequence_step?: InputMaybe<SequenceStepUpdateOneWithoutLeadsInput>;
  SequenceActionLog?: InputMaybe<StepActionLogUpdateManyWithoutLeadInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutLeadInput>;
  sequences_completed_ids?: InputMaybe<LeadUpdatesequences_Completed_IdsInput>;
  sequences_joined_ids?: InputMaybe<LeadUpdatesequences_Joined_IdsInput>;
  sf_account_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sf_contact_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sf_lead_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sibling_lead_histories?: InputMaybe<AssociationHistoryUpdateManyWithoutSibling_LeadsInput>;
  sms_sub_status?: InputMaybe<EnumOptInStatusFieldUpdateOperationsInput>;
  stat_items?: InputMaybe<StatItemAggregationUpdateManyWithoutLeadInput>;
  state?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  StatLeadAggregation?: InputMaybe<StatLeadAggregationUpdateOneWithoutLeadInput>;
  status?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sub_industry?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timezone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  zip?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type LeadUpdateWithoutAssociated_Parent_IntentsInput = {
  address?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  address_2?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  alternate_contacts?: InputMaybe<ContactUpdateManyWithoutLeadInput>;
  assignment_locked?: InputMaybe<BoolFieldUpdateOperationsInput>;
  associate_children?: InputMaybe<LeadUpdateManyWithoutAssociate_Parent_LeadInput>;
  associate_group_lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutAssociate_Group_LeadsInput>;
  associate_lead_histories?: InputMaybe<AssociationHistoryUpdateManyWithoutAssociate_LeadInput>;
  associate_parent_lead?: InputMaybe<LeadUpdateOneWithoutAssociate_ChildrenInput>;
  associates_search_content?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  business_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptUpdateManyWithoutLeadInput>;
  callMeNows?: InputMaybe<CallMeNowUpdateManyWithoutLeadInput>;
  channel?: InputMaybe<NullableEnumChannelFieldUpdateOperationsInput>;
  child_lead_activites?: InputMaybe<LeadActivityUpdateManyWithoutAssociate_ChildInput>;
  city?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  claim_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  conference_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  contact_requests?: InputMaybe<ContactRequestUpdateManyWithoutLeadInput>;
  content?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  current_close_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  current_contact_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  current_sale_cycle?: InputMaybe<SaleCycleUpdateOneWithoutLead_If_CurrentInput>;
  custom_field_json?: InputMaybe<Scalars['Json']['input']>;
  custom_fields?: InputMaybe<CustomFieldUpdateManyWithoutLeadInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemUpdateManyWithoutLeadInput>;
  delay_action_status?: InputMaybe<BoolFieldUpdateOperationsInput>;
  email_sub_status?: InputMaybe<EnumOptInStatusFieldUpdateOperationsInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordUpdateManyWithoutLeadInput>;
  EmailThread?: InputMaybe<EmailThreadUpdateManyWithoutLeadInput>;
  favorited_by_users?: InputMaybe<UserUpdateManyWithoutFavorite_LeadsInput>;
  first_demo_schedule_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  first_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  hubspot_company_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  hubspot_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  implied_next_action?: InputMaybe<EnumHingepointFieldUpdateOperationsInput>;
  in_algo_queue?: InputMaybe<BoolFieldUpdateOperationsInput>;
  in_custom_sequence?: InputMaybe<IntFieldUpdateOperationsInput>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventUpdateManyWithoutLeadInput>;
  industry?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogUpdateManyWithoutLeadInput>;
  last_call_result?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  last_call_result_rep?: InputMaybe<UserUpdateOneWithoutLeads_Last_CalledInput>;
  last_call_result_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  last_lead_assignment_origin?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  last_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  last_sent_to_resting_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutLeadInput>;
  lead_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutLeadInput>;
  lead_assignment_origin?: InputMaybe<EnumAssignmentOriginFieldUpdateOperationsInput>;
  lead_creation_source?: InputMaybe<NullableEnumLeadcreationsourceFieldUpdateOperationsInput>;
  lead_custom_row_info?: InputMaybe<LeadCustomObjectRowAssociationUpdateManyWithoutLeadInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryUpdateOneWithoutLeadInput>;
  lead_import_history_csv?: InputMaybe<LeadImportHistoryUpdateOneWithoutUploaded_LeadsInput>;
  lead_intent?: InputMaybe<LeadIntentUpdateManyWithoutLeadInput>;
  lead_ownership_status?: InputMaybe<EnumLosFieldUpdateOperationsInput>;
  lead_source?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  lead_value?: InputMaybe<LeadValueUpdateOneWithoutLeadInput>;
  mrr?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  newIncoming?: InputMaybe<BoolFieldUpdateOperationsInput>;
  next_scheduled_event?: InputMaybe<ScheduleItemUpdateOneWithoutLeadInput>;
  notifications?: InputMaybe<NotificationUpdateManyWithoutLeadInput>;
  opsiq_whitelist?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutLeadsInput>;
  phase?: InputMaybe<EnumPhaseFieldUpdateOperationsInput>;
  primary_email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_email_title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_email_type?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_lead_histories?: InputMaybe<AssociationHistoryUpdateManyWithoutPrimary_LeadInput>;
  primary_phone_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_phone_number_country_code?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_phone_number_title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_phone_number_type?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  rep?: InputMaybe<UserUpdateOneWithoutOwned_LeadsInput>;
  resting_type?: InputMaybe<NullableEnumRestingtypeFieldUpdateOperationsInput>;
  routing_history?: InputMaybe<RoutingHistoryUpdateManyWithoutLeadInput>;
  routing_rule?: InputMaybe<RuleUpdateOneWithoutLeadInput>;
  Sale?: InputMaybe<SaleUpdateManyWithoutLeadInput>;
  sale_cycles?: InputMaybe<SaleCycleUpdateManyWithoutLeadInput>;
  search_content?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  secondary_reps?: InputMaybe<UserUpdateManyWithoutIdle_Owned_LeadsInput>;
  self_sourced?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  sequence?: InputMaybe<SequenceUpdateOneWithoutLeadsInput>;
  sequence_step?: InputMaybe<SequenceStepUpdateOneWithoutLeadsInput>;
  SequenceActionLog?: InputMaybe<StepActionLogUpdateManyWithoutLeadInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutLeadInput>;
  sequences_completed_ids?: InputMaybe<LeadUpdatesequences_Completed_IdsInput>;
  sequences_joined_ids?: InputMaybe<LeadUpdatesequences_Joined_IdsInput>;
  sf_account_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sf_contact_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sf_lead_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sibling_lead_histories?: InputMaybe<AssociationHistoryUpdateManyWithoutSibling_LeadsInput>;
  sms_sub_status?: InputMaybe<EnumOptInStatusFieldUpdateOperationsInput>;
  stat_items?: InputMaybe<StatItemAggregationUpdateManyWithoutLeadInput>;
  state?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  StatLeadAggregation?: InputMaybe<StatLeadAggregationUpdateOneWithoutLeadInput>;
  status?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sub_industry?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timezone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  zip?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type LeadUpdateWithoutCallMeNowAttemptsInput = {
  address?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  address_2?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  alternate_contacts?: InputMaybe<ContactUpdateManyWithoutLeadInput>;
  assignment_locked?: InputMaybe<BoolFieldUpdateOperationsInput>;
  associate_children?: InputMaybe<LeadUpdateManyWithoutAssociate_Parent_LeadInput>;
  associate_group_lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutAssociate_Group_LeadsInput>;
  associate_lead_histories?: InputMaybe<AssociationHistoryUpdateManyWithoutAssociate_LeadInput>;
  associate_parent_lead?: InputMaybe<LeadUpdateOneWithoutAssociate_ChildrenInput>;
  associated_parent_intents?: InputMaybe<LeadIntentUpdateManyWithoutAssociate_ParentInput>;
  associates_search_content?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  business_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  callMeNows?: InputMaybe<CallMeNowUpdateManyWithoutLeadInput>;
  channel?: InputMaybe<NullableEnumChannelFieldUpdateOperationsInput>;
  child_lead_activites?: InputMaybe<LeadActivityUpdateManyWithoutAssociate_ChildInput>;
  city?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  claim_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  conference_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  contact_requests?: InputMaybe<ContactRequestUpdateManyWithoutLeadInput>;
  content?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  current_close_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  current_contact_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  current_sale_cycle?: InputMaybe<SaleCycleUpdateOneWithoutLead_If_CurrentInput>;
  custom_field_json?: InputMaybe<Scalars['Json']['input']>;
  custom_fields?: InputMaybe<CustomFieldUpdateManyWithoutLeadInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemUpdateManyWithoutLeadInput>;
  delay_action_status?: InputMaybe<BoolFieldUpdateOperationsInput>;
  email_sub_status?: InputMaybe<EnumOptInStatusFieldUpdateOperationsInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordUpdateManyWithoutLeadInput>;
  EmailThread?: InputMaybe<EmailThreadUpdateManyWithoutLeadInput>;
  favorited_by_users?: InputMaybe<UserUpdateManyWithoutFavorite_LeadsInput>;
  first_demo_schedule_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  first_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  hubspot_company_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  hubspot_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  implied_next_action?: InputMaybe<EnumHingepointFieldUpdateOperationsInput>;
  in_algo_queue?: InputMaybe<BoolFieldUpdateOperationsInput>;
  in_custom_sequence?: InputMaybe<IntFieldUpdateOperationsInput>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventUpdateManyWithoutLeadInput>;
  industry?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogUpdateManyWithoutLeadInput>;
  last_call_result?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  last_call_result_rep?: InputMaybe<UserUpdateOneWithoutLeads_Last_CalledInput>;
  last_call_result_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  last_lead_assignment_origin?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  last_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  last_sent_to_resting_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutLeadInput>;
  lead_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutLeadInput>;
  lead_assignment_origin?: InputMaybe<EnumAssignmentOriginFieldUpdateOperationsInput>;
  lead_creation_source?: InputMaybe<NullableEnumLeadcreationsourceFieldUpdateOperationsInput>;
  lead_custom_row_info?: InputMaybe<LeadCustomObjectRowAssociationUpdateManyWithoutLeadInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryUpdateOneWithoutLeadInput>;
  lead_import_history_csv?: InputMaybe<LeadImportHistoryUpdateOneWithoutUploaded_LeadsInput>;
  lead_intent?: InputMaybe<LeadIntentUpdateManyWithoutLeadInput>;
  lead_ownership_status?: InputMaybe<EnumLosFieldUpdateOperationsInput>;
  lead_source?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  lead_value?: InputMaybe<LeadValueUpdateOneWithoutLeadInput>;
  mrr?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  newIncoming?: InputMaybe<BoolFieldUpdateOperationsInput>;
  next_scheduled_event?: InputMaybe<ScheduleItemUpdateOneWithoutLeadInput>;
  notifications?: InputMaybe<NotificationUpdateManyWithoutLeadInput>;
  opsiq_whitelist?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutLeadsInput>;
  phase?: InputMaybe<EnumPhaseFieldUpdateOperationsInput>;
  primary_email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_email_title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_email_type?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_lead_histories?: InputMaybe<AssociationHistoryUpdateManyWithoutPrimary_LeadInput>;
  primary_phone_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_phone_number_country_code?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_phone_number_title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_phone_number_type?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  rep?: InputMaybe<UserUpdateOneWithoutOwned_LeadsInput>;
  resting_type?: InputMaybe<NullableEnumRestingtypeFieldUpdateOperationsInput>;
  routing_history?: InputMaybe<RoutingHistoryUpdateManyWithoutLeadInput>;
  routing_rule?: InputMaybe<RuleUpdateOneWithoutLeadInput>;
  Sale?: InputMaybe<SaleUpdateManyWithoutLeadInput>;
  sale_cycles?: InputMaybe<SaleCycleUpdateManyWithoutLeadInput>;
  search_content?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  secondary_reps?: InputMaybe<UserUpdateManyWithoutIdle_Owned_LeadsInput>;
  self_sourced?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  sequence?: InputMaybe<SequenceUpdateOneWithoutLeadsInput>;
  sequence_step?: InputMaybe<SequenceStepUpdateOneWithoutLeadsInput>;
  SequenceActionLog?: InputMaybe<StepActionLogUpdateManyWithoutLeadInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutLeadInput>;
  sequences_completed_ids?: InputMaybe<LeadUpdatesequences_Completed_IdsInput>;
  sequences_joined_ids?: InputMaybe<LeadUpdatesequences_Joined_IdsInput>;
  sf_account_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sf_contact_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sf_lead_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sibling_lead_histories?: InputMaybe<AssociationHistoryUpdateManyWithoutSibling_LeadsInput>;
  sms_sub_status?: InputMaybe<EnumOptInStatusFieldUpdateOperationsInput>;
  stat_items?: InputMaybe<StatItemAggregationUpdateManyWithoutLeadInput>;
  state?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  StatLeadAggregation?: InputMaybe<StatLeadAggregationUpdateOneWithoutLeadInput>;
  status?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sub_industry?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timezone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  zip?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type LeadUpdateWithoutCallMeNowsInput = {
  address?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  address_2?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  alternate_contacts?: InputMaybe<ContactUpdateManyWithoutLeadInput>;
  assignment_locked?: InputMaybe<BoolFieldUpdateOperationsInput>;
  associate_children?: InputMaybe<LeadUpdateManyWithoutAssociate_Parent_LeadInput>;
  associate_group_lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutAssociate_Group_LeadsInput>;
  associate_lead_histories?: InputMaybe<AssociationHistoryUpdateManyWithoutAssociate_LeadInput>;
  associate_parent_lead?: InputMaybe<LeadUpdateOneWithoutAssociate_ChildrenInput>;
  associated_parent_intents?: InputMaybe<LeadIntentUpdateManyWithoutAssociate_ParentInput>;
  associates_search_content?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  business_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptUpdateManyWithoutLeadInput>;
  channel?: InputMaybe<NullableEnumChannelFieldUpdateOperationsInput>;
  child_lead_activites?: InputMaybe<LeadActivityUpdateManyWithoutAssociate_ChildInput>;
  city?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  claim_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  conference_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  contact_requests?: InputMaybe<ContactRequestUpdateManyWithoutLeadInput>;
  content?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  current_close_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  current_contact_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  current_sale_cycle?: InputMaybe<SaleCycleUpdateOneWithoutLead_If_CurrentInput>;
  custom_field_json?: InputMaybe<Scalars['Json']['input']>;
  custom_fields?: InputMaybe<CustomFieldUpdateManyWithoutLeadInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemUpdateManyWithoutLeadInput>;
  delay_action_status?: InputMaybe<BoolFieldUpdateOperationsInput>;
  email_sub_status?: InputMaybe<EnumOptInStatusFieldUpdateOperationsInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordUpdateManyWithoutLeadInput>;
  EmailThread?: InputMaybe<EmailThreadUpdateManyWithoutLeadInput>;
  favorited_by_users?: InputMaybe<UserUpdateManyWithoutFavorite_LeadsInput>;
  first_demo_schedule_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  first_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  hubspot_company_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  hubspot_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  implied_next_action?: InputMaybe<EnumHingepointFieldUpdateOperationsInput>;
  in_algo_queue?: InputMaybe<BoolFieldUpdateOperationsInput>;
  in_custom_sequence?: InputMaybe<IntFieldUpdateOperationsInput>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventUpdateManyWithoutLeadInput>;
  industry?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogUpdateManyWithoutLeadInput>;
  last_call_result?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  last_call_result_rep?: InputMaybe<UserUpdateOneWithoutLeads_Last_CalledInput>;
  last_call_result_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  last_lead_assignment_origin?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  last_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  last_sent_to_resting_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutLeadInput>;
  lead_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutLeadInput>;
  lead_assignment_origin?: InputMaybe<EnumAssignmentOriginFieldUpdateOperationsInput>;
  lead_creation_source?: InputMaybe<NullableEnumLeadcreationsourceFieldUpdateOperationsInput>;
  lead_custom_row_info?: InputMaybe<LeadCustomObjectRowAssociationUpdateManyWithoutLeadInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryUpdateOneWithoutLeadInput>;
  lead_import_history_csv?: InputMaybe<LeadImportHistoryUpdateOneWithoutUploaded_LeadsInput>;
  lead_intent?: InputMaybe<LeadIntentUpdateManyWithoutLeadInput>;
  lead_ownership_status?: InputMaybe<EnumLosFieldUpdateOperationsInput>;
  lead_source?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  lead_value?: InputMaybe<LeadValueUpdateOneWithoutLeadInput>;
  mrr?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  newIncoming?: InputMaybe<BoolFieldUpdateOperationsInput>;
  next_scheduled_event?: InputMaybe<ScheduleItemUpdateOneWithoutLeadInput>;
  notifications?: InputMaybe<NotificationUpdateManyWithoutLeadInput>;
  opsiq_whitelist?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutLeadsInput>;
  phase?: InputMaybe<EnumPhaseFieldUpdateOperationsInput>;
  primary_email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_email_title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_email_type?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_lead_histories?: InputMaybe<AssociationHistoryUpdateManyWithoutPrimary_LeadInput>;
  primary_phone_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_phone_number_country_code?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_phone_number_title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_phone_number_type?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  rep?: InputMaybe<UserUpdateOneWithoutOwned_LeadsInput>;
  resting_type?: InputMaybe<NullableEnumRestingtypeFieldUpdateOperationsInput>;
  routing_history?: InputMaybe<RoutingHistoryUpdateManyWithoutLeadInput>;
  routing_rule?: InputMaybe<RuleUpdateOneWithoutLeadInput>;
  Sale?: InputMaybe<SaleUpdateManyWithoutLeadInput>;
  sale_cycles?: InputMaybe<SaleCycleUpdateManyWithoutLeadInput>;
  search_content?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  secondary_reps?: InputMaybe<UserUpdateManyWithoutIdle_Owned_LeadsInput>;
  self_sourced?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  sequence?: InputMaybe<SequenceUpdateOneWithoutLeadsInput>;
  sequence_step?: InputMaybe<SequenceStepUpdateOneWithoutLeadsInput>;
  SequenceActionLog?: InputMaybe<StepActionLogUpdateManyWithoutLeadInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutLeadInput>;
  sequences_completed_ids?: InputMaybe<LeadUpdatesequences_Completed_IdsInput>;
  sequences_joined_ids?: InputMaybe<LeadUpdatesequences_Joined_IdsInput>;
  sf_account_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sf_contact_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sf_lead_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sibling_lead_histories?: InputMaybe<AssociationHistoryUpdateManyWithoutSibling_LeadsInput>;
  sms_sub_status?: InputMaybe<EnumOptInStatusFieldUpdateOperationsInput>;
  stat_items?: InputMaybe<StatItemAggregationUpdateManyWithoutLeadInput>;
  state?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  StatLeadAggregation?: InputMaybe<StatLeadAggregationUpdateOneWithoutLeadInput>;
  status?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sub_industry?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timezone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  zip?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type LeadUpdateWithoutChild_Lead_ActivitesInput = {
  address?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  address_2?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  alternate_contacts?: InputMaybe<ContactUpdateManyWithoutLeadInput>;
  assignment_locked?: InputMaybe<BoolFieldUpdateOperationsInput>;
  associate_children?: InputMaybe<LeadUpdateManyWithoutAssociate_Parent_LeadInput>;
  associate_group_lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutAssociate_Group_LeadsInput>;
  associate_lead_histories?: InputMaybe<AssociationHistoryUpdateManyWithoutAssociate_LeadInput>;
  associate_parent_lead?: InputMaybe<LeadUpdateOneWithoutAssociate_ChildrenInput>;
  associated_parent_intents?: InputMaybe<LeadIntentUpdateManyWithoutAssociate_ParentInput>;
  associates_search_content?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  business_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptUpdateManyWithoutLeadInput>;
  callMeNows?: InputMaybe<CallMeNowUpdateManyWithoutLeadInput>;
  channel?: InputMaybe<NullableEnumChannelFieldUpdateOperationsInput>;
  city?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  claim_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  conference_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  contact_requests?: InputMaybe<ContactRequestUpdateManyWithoutLeadInput>;
  content?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  current_close_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  current_contact_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  current_sale_cycle?: InputMaybe<SaleCycleUpdateOneWithoutLead_If_CurrentInput>;
  custom_field_json?: InputMaybe<Scalars['Json']['input']>;
  custom_fields?: InputMaybe<CustomFieldUpdateManyWithoutLeadInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemUpdateManyWithoutLeadInput>;
  delay_action_status?: InputMaybe<BoolFieldUpdateOperationsInput>;
  email_sub_status?: InputMaybe<EnumOptInStatusFieldUpdateOperationsInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordUpdateManyWithoutLeadInput>;
  EmailThread?: InputMaybe<EmailThreadUpdateManyWithoutLeadInput>;
  favorited_by_users?: InputMaybe<UserUpdateManyWithoutFavorite_LeadsInput>;
  first_demo_schedule_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  first_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  hubspot_company_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  hubspot_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  implied_next_action?: InputMaybe<EnumHingepointFieldUpdateOperationsInput>;
  in_algo_queue?: InputMaybe<BoolFieldUpdateOperationsInput>;
  in_custom_sequence?: InputMaybe<IntFieldUpdateOperationsInput>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventUpdateManyWithoutLeadInput>;
  industry?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogUpdateManyWithoutLeadInput>;
  last_call_result?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  last_call_result_rep?: InputMaybe<UserUpdateOneWithoutLeads_Last_CalledInput>;
  last_call_result_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  last_lead_assignment_origin?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  last_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  last_sent_to_resting_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutLeadInput>;
  lead_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutLeadInput>;
  lead_assignment_origin?: InputMaybe<EnumAssignmentOriginFieldUpdateOperationsInput>;
  lead_creation_source?: InputMaybe<NullableEnumLeadcreationsourceFieldUpdateOperationsInput>;
  lead_custom_row_info?: InputMaybe<LeadCustomObjectRowAssociationUpdateManyWithoutLeadInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryUpdateOneWithoutLeadInput>;
  lead_import_history_csv?: InputMaybe<LeadImportHistoryUpdateOneWithoutUploaded_LeadsInput>;
  lead_intent?: InputMaybe<LeadIntentUpdateManyWithoutLeadInput>;
  lead_ownership_status?: InputMaybe<EnumLosFieldUpdateOperationsInput>;
  lead_source?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  lead_value?: InputMaybe<LeadValueUpdateOneWithoutLeadInput>;
  mrr?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  newIncoming?: InputMaybe<BoolFieldUpdateOperationsInput>;
  next_scheduled_event?: InputMaybe<ScheduleItemUpdateOneWithoutLeadInput>;
  notifications?: InputMaybe<NotificationUpdateManyWithoutLeadInput>;
  opsiq_whitelist?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutLeadsInput>;
  phase?: InputMaybe<EnumPhaseFieldUpdateOperationsInput>;
  primary_email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_email_title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_email_type?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_lead_histories?: InputMaybe<AssociationHistoryUpdateManyWithoutPrimary_LeadInput>;
  primary_phone_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_phone_number_country_code?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_phone_number_title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_phone_number_type?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  rep?: InputMaybe<UserUpdateOneWithoutOwned_LeadsInput>;
  resting_type?: InputMaybe<NullableEnumRestingtypeFieldUpdateOperationsInput>;
  routing_history?: InputMaybe<RoutingHistoryUpdateManyWithoutLeadInput>;
  routing_rule?: InputMaybe<RuleUpdateOneWithoutLeadInput>;
  Sale?: InputMaybe<SaleUpdateManyWithoutLeadInput>;
  sale_cycles?: InputMaybe<SaleCycleUpdateManyWithoutLeadInput>;
  search_content?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  secondary_reps?: InputMaybe<UserUpdateManyWithoutIdle_Owned_LeadsInput>;
  self_sourced?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  sequence?: InputMaybe<SequenceUpdateOneWithoutLeadsInput>;
  sequence_step?: InputMaybe<SequenceStepUpdateOneWithoutLeadsInput>;
  SequenceActionLog?: InputMaybe<StepActionLogUpdateManyWithoutLeadInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutLeadInput>;
  sequences_completed_ids?: InputMaybe<LeadUpdatesequences_Completed_IdsInput>;
  sequences_joined_ids?: InputMaybe<LeadUpdatesequences_Joined_IdsInput>;
  sf_account_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sf_contact_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sf_lead_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sibling_lead_histories?: InputMaybe<AssociationHistoryUpdateManyWithoutSibling_LeadsInput>;
  sms_sub_status?: InputMaybe<EnumOptInStatusFieldUpdateOperationsInput>;
  stat_items?: InputMaybe<StatItemAggregationUpdateManyWithoutLeadInput>;
  state?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  StatLeadAggregation?: InputMaybe<StatLeadAggregationUpdateOneWithoutLeadInput>;
  status?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sub_industry?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timezone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  zip?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type LeadUpdateWithoutContact_RequestsInput = {
  address?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  address_2?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  alternate_contacts?: InputMaybe<ContactUpdateManyWithoutLeadInput>;
  assignment_locked?: InputMaybe<BoolFieldUpdateOperationsInput>;
  associate_children?: InputMaybe<LeadUpdateManyWithoutAssociate_Parent_LeadInput>;
  associate_group_lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutAssociate_Group_LeadsInput>;
  associate_lead_histories?: InputMaybe<AssociationHistoryUpdateManyWithoutAssociate_LeadInput>;
  associate_parent_lead?: InputMaybe<LeadUpdateOneWithoutAssociate_ChildrenInput>;
  associated_parent_intents?: InputMaybe<LeadIntentUpdateManyWithoutAssociate_ParentInput>;
  associates_search_content?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  business_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptUpdateManyWithoutLeadInput>;
  callMeNows?: InputMaybe<CallMeNowUpdateManyWithoutLeadInput>;
  channel?: InputMaybe<NullableEnumChannelFieldUpdateOperationsInput>;
  child_lead_activites?: InputMaybe<LeadActivityUpdateManyWithoutAssociate_ChildInput>;
  city?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  claim_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  conference_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  content?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  current_close_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  current_contact_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  current_sale_cycle?: InputMaybe<SaleCycleUpdateOneWithoutLead_If_CurrentInput>;
  custom_field_json?: InputMaybe<Scalars['Json']['input']>;
  custom_fields?: InputMaybe<CustomFieldUpdateManyWithoutLeadInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemUpdateManyWithoutLeadInput>;
  delay_action_status?: InputMaybe<BoolFieldUpdateOperationsInput>;
  email_sub_status?: InputMaybe<EnumOptInStatusFieldUpdateOperationsInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordUpdateManyWithoutLeadInput>;
  EmailThread?: InputMaybe<EmailThreadUpdateManyWithoutLeadInput>;
  favorited_by_users?: InputMaybe<UserUpdateManyWithoutFavorite_LeadsInput>;
  first_demo_schedule_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  first_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  hubspot_company_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  hubspot_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  implied_next_action?: InputMaybe<EnumHingepointFieldUpdateOperationsInput>;
  in_algo_queue?: InputMaybe<BoolFieldUpdateOperationsInput>;
  in_custom_sequence?: InputMaybe<IntFieldUpdateOperationsInput>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventUpdateManyWithoutLeadInput>;
  industry?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogUpdateManyWithoutLeadInput>;
  last_call_result?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  last_call_result_rep?: InputMaybe<UserUpdateOneWithoutLeads_Last_CalledInput>;
  last_call_result_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  last_lead_assignment_origin?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  last_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  last_sent_to_resting_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutLeadInput>;
  lead_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutLeadInput>;
  lead_assignment_origin?: InputMaybe<EnumAssignmentOriginFieldUpdateOperationsInput>;
  lead_creation_source?: InputMaybe<NullableEnumLeadcreationsourceFieldUpdateOperationsInput>;
  lead_custom_row_info?: InputMaybe<LeadCustomObjectRowAssociationUpdateManyWithoutLeadInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryUpdateOneWithoutLeadInput>;
  lead_import_history_csv?: InputMaybe<LeadImportHistoryUpdateOneWithoutUploaded_LeadsInput>;
  lead_intent?: InputMaybe<LeadIntentUpdateManyWithoutLeadInput>;
  lead_ownership_status?: InputMaybe<EnumLosFieldUpdateOperationsInput>;
  lead_source?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  lead_value?: InputMaybe<LeadValueUpdateOneWithoutLeadInput>;
  mrr?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  newIncoming?: InputMaybe<BoolFieldUpdateOperationsInput>;
  next_scheduled_event?: InputMaybe<ScheduleItemUpdateOneWithoutLeadInput>;
  notifications?: InputMaybe<NotificationUpdateManyWithoutLeadInput>;
  opsiq_whitelist?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutLeadsInput>;
  phase?: InputMaybe<EnumPhaseFieldUpdateOperationsInput>;
  primary_email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_email_title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_email_type?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_lead_histories?: InputMaybe<AssociationHistoryUpdateManyWithoutPrimary_LeadInput>;
  primary_phone_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_phone_number_country_code?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_phone_number_title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_phone_number_type?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  rep?: InputMaybe<UserUpdateOneWithoutOwned_LeadsInput>;
  resting_type?: InputMaybe<NullableEnumRestingtypeFieldUpdateOperationsInput>;
  routing_history?: InputMaybe<RoutingHistoryUpdateManyWithoutLeadInput>;
  routing_rule?: InputMaybe<RuleUpdateOneWithoutLeadInput>;
  Sale?: InputMaybe<SaleUpdateManyWithoutLeadInput>;
  sale_cycles?: InputMaybe<SaleCycleUpdateManyWithoutLeadInput>;
  search_content?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  secondary_reps?: InputMaybe<UserUpdateManyWithoutIdle_Owned_LeadsInput>;
  self_sourced?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  sequence?: InputMaybe<SequenceUpdateOneWithoutLeadsInput>;
  sequence_step?: InputMaybe<SequenceStepUpdateOneWithoutLeadsInput>;
  SequenceActionLog?: InputMaybe<StepActionLogUpdateManyWithoutLeadInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutLeadInput>;
  sequences_completed_ids?: InputMaybe<LeadUpdatesequences_Completed_IdsInput>;
  sequences_joined_ids?: InputMaybe<LeadUpdatesequences_Joined_IdsInput>;
  sf_account_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sf_contact_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sf_lead_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sibling_lead_histories?: InputMaybe<AssociationHistoryUpdateManyWithoutSibling_LeadsInput>;
  sms_sub_status?: InputMaybe<EnumOptInStatusFieldUpdateOperationsInput>;
  stat_items?: InputMaybe<StatItemAggregationUpdateManyWithoutLeadInput>;
  state?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  StatLeadAggregation?: InputMaybe<StatLeadAggregationUpdateOneWithoutLeadInput>;
  status?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sub_industry?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timezone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  zip?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type LeadUpdateWithoutCurrent_Sale_CycleInput = {
  address?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  address_2?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  alternate_contacts?: InputMaybe<ContactUpdateManyWithoutLeadInput>;
  assignment_locked?: InputMaybe<BoolFieldUpdateOperationsInput>;
  associate_children?: InputMaybe<LeadUpdateManyWithoutAssociate_Parent_LeadInput>;
  associate_group_lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutAssociate_Group_LeadsInput>;
  associate_lead_histories?: InputMaybe<AssociationHistoryUpdateManyWithoutAssociate_LeadInput>;
  associate_parent_lead?: InputMaybe<LeadUpdateOneWithoutAssociate_ChildrenInput>;
  associated_parent_intents?: InputMaybe<LeadIntentUpdateManyWithoutAssociate_ParentInput>;
  associates_search_content?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  business_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptUpdateManyWithoutLeadInput>;
  callMeNows?: InputMaybe<CallMeNowUpdateManyWithoutLeadInput>;
  channel?: InputMaybe<NullableEnumChannelFieldUpdateOperationsInput>;
  child_lead_activites?: InputMaybe<LeadActivityUpdateManyWithoutAssociate_ChildInput>;
  city?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  claim_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  conference_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  contact_requests?: InputMaybe<ContactRequestUpdateManyWithoutLeadInput>;
  content?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  current_close_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  current_contact_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  custom_field_json?: InputMaybe<Scalars['Json']['input']>;
  custom_fields?: InputMaybe<CustomFieldUpdateManyWithoutLeadInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemUpdateManyWithoutLeadInput>;
  delay_action_status?: InputMaybe<BoolFieldUpdateOperationsInput>;
  email_sub_status?: InputMaybe<EnumOptInStatusFieldUpdateOperationsInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordUpdateManyWithoutLeadInput>;
  EmailThread?: InputMaybe<EmailThreadUpdateManyWithoutLeadInput>;
  favorited_by_users?: InputMaybe<UserUpdateManyWithoutFavorite_LeadsInput>;
  first_demo_schedule_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  first_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  hubspot_company_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  hubspot_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  implied_next_action?: InputMaybe<EnumHingepointFieldUpdateOperationsInput>;
  in_algo_queue?: InputMaybe<BoolFieldUpdateOperationsInput>;
  in_custom_sequence?: InputMaybe<IntFieldUpdateOperationsInput>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventUpdateManyWithoutLeadInput>;
  industry?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogUpdateManyWithoutLeadInput>;
  last_call_result?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  last_call_result_rep?: InputMaybe<UserUpdateOneWithoutLeads_Last_CalledInput>;
  last_call_result_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  last_lead_assignment_origin?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  last_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  last_sent_to_resting_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutLeadInput>;
  lead_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutLeadInput>;
  lead_assignment_origin?: InputMaybe<EnumAssignmentOriginFieldUpdateOperationsInput>;
  lead_creation_source?: InputMaybe<NullableEnumLeadcreationsourceFieldUpdateOperationsInput>;
  lead_custom_row_info?: InputMaybe<LeadCustomObjectRowAssociationUpdateManyWithoutLeadInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryUpdateOneWithoutLeadInput>;
  lead_import_history_csv?: InputMaybe<LeadImportHistoryUpdateOneWithoutUploaded_LeadsInput>;
  lead_intent?: InputMaybe<LeadIntentUpdateManyWithoutLeadInput>;
  lead_ownership_status?: InputMaybe<EnumLosFieldUpdateOperationsInput>;
  lead_source?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  lead_value?: InputMaybe<LeadValueUpdateOneWithoutLeadInput>;
  mrr?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  newIncoming?: InputMaybe<BoolFieldUpdateOperationsInput>;
  next_scheduled_event?: InputMaybe<ScheduleItemUpdateOneWithoutLeadInput>;
  notifications?: InputMaybe<NotificationUpdateManyWithoutLeadInput>;
  opsiq_whitelist?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutLeadsInput>;
  phase?: InputMaybe<EnumPhaseFieldUpdateOperationsInput>;
  primary_email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_email_title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_email_type?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_lead_histories?: InputMaybe<AssociationHistoryUpdateManyWithoutPrimary_LeadInput>;
  primary_phone_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_phone_number_country_code?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_phone_number_title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_phone_number_type?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  rep?: InputMaybe<UserUpdateOneWithoutOwned_LeadsInput>;
  resting_type?: InputMaybe<NullableEnumRestingtypeFieldUpdateOperationsInput>;
  routing_history?: InputMaybe<RoutingHistoryUpdateManyWithoutLeadInput>;
  routing_rule?: InputMaybe<RuleUpdateOneWithoutLeadInput>;
  Sale?: InputMaybe<SaleUpdateManyWithoutLeadInput>;
  sale_cycles?: InputMaybe<SaleCycleUpdateManyWithoutLeadInput>;
  search_content?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  secondary_reps?: InputMaybe<UserUpdateManyWithoutIdle_Owned_LeadsInput>;
  self_sourced?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  sequence?: InputMaybe<SequenceUpdateOneWithoutLeadsInput>;
  sequence_step?: InputMaybe<SequenceStepUpdateOneWithoutLeadsInput>;
  SequenceActionLog?: InputMaybe<StepActionLogUpdateManyWithoutLeadInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutLeadInput>;
  sequences_completed_ids?: InputMaybe<LeadUpdatesequences_Completed_IdsInput>;
  sequences_joined_ids?: InputMaybe<LeadUpdatesequences_Joined_IdsInput>;
  sf_account_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sf_contact_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sf_lead_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sibling_lead_histories?: InputMaybe<AssociationHistoryUpdateManyWithoutSibling_LeadsInput>;
  sms_sub_status?: InputMaybe<EnumOptInStatusFieldUpdateOperationsInput>;
  stat_items?: InputMaybe<StatItemAggregationUpdateManyWithoutLeadInput>;
  state?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  StatLeadAggregation?: InputMaybe<StatLeadAggregationUpdateOneWithoutLeadInput>;
  status?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sub_industry?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timezone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  zip?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type LeadUpdateWithoutCustom_FieldsInput = {
  address?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  address_2?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  alternate_contacts?: InputMaybe<ContactUpdateManyWithoutLeadInput>;
  assignment_locked?: InputMaybe<BoolFieldUpdateOperationsInput>;
  associate_children?: InputMaybe<LeadUpdateManyWithoutAssociate_Parent_LeadInput>;
  associate_group_lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutAssociate_Group_LeadsInput>;
  associate_lead_histories?: InputMaybe<AssociationHistoryUpdateManyWithoutAssociate_LeadInput>;
  associate_parent_lead?: InputMaybe<LeadUpdateOneWithoutAssociate_ChildrenInput>;
  associated_parent_intents?: InputMaybe<LeadIntentUpdateManyWithoutAssociate_ParentInput>;
  associates_search_content?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  business_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptUpdateManyWithoutLeadInput>;
  callMeNows?: InputMaybe<CallMeNowUpdateManyWithoutLeadInput>;
  channel?: InputMaybe<NullableEnumChannelFieldUpdateOperationsInput>;
  child_lead_activites?: InputMaybe<LeadActivityUpdateManyWithoutAssociate_ChildInput>;
  city?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  claim_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  conference_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  contact_requests?: InputMaybe<ContactRequestUpdateManyWithoutLeadInput>;
  content?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  current_close_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  current_contact_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  current_sale_cycle?: InputMaybe<SaleCycleUpdateOneWithoutLead_If_CurrentInput>;
  custom_field_json?: InputMaybe<Scalars['Json']['input']>;
  custom_queue_items?: InputMaybe<CustomQueueItemUpdateManyWithoutLeadInput>;
  delay_action_status?: InputMaybe<BoolFieldUpdateOperationsInput>;
  email_sub_status?: InputMaybe<EnumOptInStatusFieldUpdateOperationsInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordUpdateManyWithoutLeadInput>;
  EmailThread?: InputMaybe<EmailThreadUpdateManyWithoutLeadInput>;
  favorited_by_users?: InputMaybe<UserUpdateManyWithoutFavorite_LeadsInput>;
  first_demo_schedule_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  first_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  hubspot_company_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  hubspot_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  implied_next_action?: InputMaybe<EnumHingepointFieldUpdateOperationsInput>;
  in_algo_queue?: InputMaybe<BoolFieldUpdateOperationsInput>;
  in_custom_sequence?: InputMaybe<IntFieldUpdateOperationsInput>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventUpdateManyWithoutLeadInput>;
  industry?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogUpdateManyWithoutLeadInput>;
  last_call_result?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  last_call_result_rep?: InputMaybe<UserUpdateOneWithoutLeads_Last_CalledInput>;
  last_call_result_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  last_lead_assignment_origin?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  last_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  last_sent_to_resting_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutLeadInput>;
  lead_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutLeadInput>;
  lead_assignment_origin?: InputMaybe<EnumAssignmentOriginFieldUpdateOperationsInput>;
  lead_creation_source?: InputMaybe<NullableEnumLeadcreationsourceFieldUpdateOperationsInput>;
  lead_custom_row_info?: InputMaybe<LeadCustomObjectRowAssociationUpdateManyWithoutLeadInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryUpdateOneWithoutLeadInput>;
  lead_import_history_csv?: InputMaybe<LeadImportHistoryUpdateOneWithoutUploaded_LeadsInput>;
  lead_intent?: InputMaybe<LeadIntentUpdateManyWithoutLeadInput>;
  lead_ownership_status?: InputMaybe<EnumLosFieldUpdateOperationsInput>;
  lead_source?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  lead_value?: InputMaybe<LeadValueUpdateOneWithoutLeadInput>;
  mrr?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  newIncoming?: InputMaybe<BoolFieldUpdateOperationsInput>;
  next_scheduled_event?: InputMaybe<ScheduleItemUpdateOneWithoutLeadInput>;
  notifications?: InputMaybe<NotificationUpdateManyWithoutLeadInput>;
  opsiq_whitelist?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutLeadsInput>;
  phase?: InputMaybe<EnumPhaseFieldUpdateOperationsInput>;
  primary_email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_email_title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_email_type?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_lead_histories?: InputMaybe<AssociationHistoryUpdateManyWithoutPrimary_LeadInput>;
  primary_phone_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_phone_number_country_code?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_phone_number_title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_phone_number_type?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  rep?: InputMaybe<UserUpdateOneWithoutOwned_LeadsInput>;
  resting_type?: InputMaybe<NullableEnumRestingtypeFieldUpdateOperationsInput>;
  routing_history?: InputMaybe<RoutingHistoryUpdateManyWithoutLeadInput>;
  routing_rule?: InputMaybe<RuleUpdateOneWithoutLeadInput>;
  Sale?: InputMaybe<SaleUpdateManyWithoutLeadInput>;
  sale_cycles?: InputMaybe<SaleCycleUpdateManyWithoutLeadInput>;
  search_content?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  secondary_reps?: InputMaybe<UserUpdateManyWithoutIdle_Owned_LeadsInput>;
  self_sourced?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  sequence?: InputMaybe<SequenceUpdateOneWithoutLeadsInput>;
  sequence_step?: InputMaybe<SequenceStepUpdateOneWithoutLeadsInput>;
  SequenceActionLog?: InputMaybe<StepActionLogUpdateManyWithoutLeadInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutLeadInput>;
  sequences_completed_ids?: InputMaybe<LeadUpdatesequences_Completed_IdsInput>;
  sequences_joined_ids?: InputMaybe<LeadUpdatesequences_Joined_IdsInput>;
  sf_account_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sf_contact_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sf_lead_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sibling_lead_histories?: InputMaybe<AssociationHistoryUpdateManyWithoutSibling_LeadsInput>;
  sms_sub_status?: InputMaybe<EnumOptInStatusFieldUpdateOperationsInput>;
  stat_items?: InputMaybe<StatItemAggregationUpdateManyWithoutLeadInput>;
  state?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  StatLeadAggregation?: InputMaybe<StatLeadAggregationUpdateOneWithoutLeadInput>;
  status?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sub_industry?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timezone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  zip?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type LeadUpdateWithoutCustom_Queue_ItemsInput = {
  address?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  address_2?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  alternate_contacts?: InputMaybe<ContactUpdateManyWithoutLeadInput>;
  assignment_locked?: InputMaybe<BoolFieldUpdateOperationsInput>;
  associate_children?: InputMaybe<LeadUpdateManyWithoutAssociate_Parent_LeadInput>;
  associate_group_lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutAssociate_Group_LeadsInput>;
  associate_lead_histories?: InputMaybe<AssociationHistoryUpdateManyWithoutAssociate_LeadInput>;
  associate_parent_lead?: InputMaybe<LeadUpdateOneWithoutAssociate_ChildrenInput>;
  associated_parent_intents?: InputMaybe<LeadIntentUpdateManyWithoutAssociate_ParentInput>;
  associates_search_content?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  business_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptUpdateManyWithoutLeadInput>;
  callMeNows?: InputMaybe<CallMeNowUpdateManyWithoutLeadInput>;
  channel?: InputMaybe<NullableEnumChannelFieldUpdateOperationsInput>;
  child_lead_activites?: InputMaybe<LeadActivityUpdateManyWithoutAssociate_ChildInput>;
  city?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  claim_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  conference_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  contact_requests?: InputMaybe<ContactRequestUpdateManyWithoutLeadInput>;
  content?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  current_close_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  current_contact_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  current_sale_cycle?: InputMaybe<SaleCycleUpdateOneWithoutLead_If_CurrentInput>;
  custom_field_json?: InputMaybe<Scalars['Json']['input']>;
  custom_fields?: InputMaybe<CustomFieldUpdateManyWithoutLeadInput>;
  delay_action_status?: InputMaybe<BoolFieldUpdateOperationsInput>;
  email_sub_status?: InputMaybe<EnumOptInStatusFieldUpdateOperationsInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordUpdateManyWithoutLeadInput>;
  EmailThread?: InputMaybe<EmailThreadUpdateManyWithoutLeadInput>;
  favorited_by_users?: InputMaybe<UserUpdateManyWithoutFavorite_LeadsInput>;
  first_demo_schedule_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  first_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  hubspot_company_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  hubspot_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  implied_next_action?: InputMaybe<EnumHingepointFieldUpdateOperationsInput>;
  in_algo_queue?: InputMaybe<BoolFieldUpdateOperationsInput>;
  in_custom_sequence?: InputMaybe<IntFieldUpdateOperationsInput>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventUpdateManyWithoutLeadInput>;
  industry?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogUpdateManyWithoutLeadInput>;
  last_call_result?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  last_call_result_rep?: InputMaybe<UserUpdateOneWithoutLeads_Last_CalledInput>;
  last_call_result_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  last_lead_assignment_origin?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  last_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  last_sent_to_resting_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutLeadInput>;
  lead_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutLeadInput>;
  lead_assignment_origin?: InputMaybe<EnumAssignmentOriginFieldUpdateOperationsInput>;
  lead_creation_source?: InputMaybe<NullableEnumLeadcreationsourceFieldUpdateOperationsInput>;
  lead_custom_row_info?: InputMaybe<LeadCustomObjectRowAssociationUpdateManyWithoutLeadInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryUpdateOneWithoutLeadInput>;
  lead_import_history_csv?: InputMaybe<LeadImportHistoryUpdateOneWithoutUploaded_LeadsInput>;
  lead_intent?: InputMaybe<LeadIntentUpdateManyWithoutLeadInput>;
  lead_ownership_status?: InputMaybe<EnumLosFieldUpdateOperationsInput>;
  lead_source?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  lead_value?: InputMaybe<LeadValueUpdateOneWithoutLeadInput>;
  mrr?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  newIncoming?: InputMaybe<BoolFieldUpdateOperationsInput>;
  next_scheduled_event?: InputMaybe<ScheduleItemUpdateOneWithoutLeadInput>;
  notifications?: InputMaybe<NotificationUpdateManyWithoutLeadInput>;
  opsiq_whitelist?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutLeadsInput>;
  phase?: InputMaybe<EnumPhaseFieldUpdateOperationsInput>;
  primary_email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_email_title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_email_type?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_lead_histories?: InputMaybe<AssociationHistoryUpdateManyWithoutPrimary_LeadInput>;
  primary_phone_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_phone_number_country_code?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_phone_number_title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_phone_number_type?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  rep?: InputMaybe<UserUpdateOneWithoutOwned_LeadsInput>;
  resting_type?: InputMaybe<NullableEnumRestingtypeFieldUpdateOperationsInput>;
  routing_history?: InputMaybe<RoutingHistoryUpdateManyWithoutLeadInput>;
  routing_rule?: InputMaybe<RuleUpdateOneWithoutLeadInput>;
  Sale?: InputMaybe<SaleUpdateManyWithoutLeadInput>;
  sale_cycles?: InputMaybe<SaleCycleUpdateManyWithoutLeadInput>;
  search_content?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  secondary_reps?: InputMaybe<UserUpdateManyWithoutIdle_Owned_LeadsInput>;
  self_sourced?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  sequence?: InputMaybe<SequenceUpdateOneWithoutLeadsInput>;
  sequence_step?: InputMaybe<SequenceStepUpdateOneWithoutLeadsInput>;
  SequenceActionLog?: InputMaybe<StepActionLogUpdateManyWithoutLeadInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutLeadInput>;
  sequences_completed_ids?: InputMaybe<LeadUpdatesequences_Completed_IdsInput>;
  sequences_joined_ids?: InputMaybe<LeadUpdatesequences_Joined_IdsInput>;
  sf_account_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sf_contact_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sf_lead_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sibling_lead_histories?: InputMaybe<AssociationHistoryUpdateManyWithoutSibling_LeadsInput>;
  sms_sub_status?: InputMaybe<EnumOptInStatusFieldUpdateOperationsInput>;
  stat_items?: InputMaybe<StatItemAggregationUpdateManyWithoutLeadInput>;
  state?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  StatLeadAggregation?: InputMaybe<StatLeadAggregationUpdateOneWithoutLeadInput>;
  status?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sub_industry?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timezone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  zip?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type LeadUpdateWithoutEmailSyncRecordInput = {
  address?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  address_2?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  alternate_contacts?: InputMaybe<ContactUpdateManyWithoutLeadInput>;
  assignment_locked?: InputMaybe<BoolFieldUpdateOperationsInput>;
  associate_children?: InputMaybe<LeadUpdateManyWithoutAssociate_Parent_LeadInput>;
  associate_group_lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutAssociate_Group_LeadsInput>;
  associate_lead_histories?: InputMaybe<AssociationHistoryUpdateManyWithoutAssociate_LeadInput>;
  associate_parent_lead?: InputMaybe<LeadUpdateOneWithoutAssociate_ChildrenInput>;
  associated_parent_intents?: InputMaybe<LeadIntentUpdateManyWithoutAssociate_ParentInput>;
  associates_search_content?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  business_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptUpdateManyWithoutLeadInput>;
  callMeNows?: InputMaybe<CallMeNowUpdateManyWithoutLeadInput>;
  channel?: InputMaybe<NullableEnumChannelFieldUpdateOperationsInput>;
  child_lead_activites?: InputMaybe<LeadActivityUpdateManyWithoutAssociate_ChildInput>;
  city?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  claim_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  conference_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  contact_requests?: InputMaybe<ContactRequestUpdateManyWithoutLeadInput>;
  content?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  current_close_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  current_contact_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  current_sale_cycle?: InputMaybe<SaleCycleUpdateOneWithoutLead_If_CurrentInput>;
  custom_field_json?: InputMaybe<Scalars['Json']['input']>;
  custom_fields?: InputMaybe<CustomFieldUpdateManyWithoutLeadInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemUpdateManyWithoutLeadInput>;
  delay_action_status?: InputMaybe<BoolFieldUpdateOperationsInput>;
  email_sub_status?: InputMaybe<EnumOptInStatusFieldUpdateOperationsInput>;
  EmailThread?: InputMaybe<EmailThreadUpdateManyWithoutLeadInput>;
  favorited_by_users?: InputMaybe<UserUpdateManyWithoutFavorite_LeadsInput>;
  first_demo_schedule_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  first_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  hubspot_company_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  hubspot_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  implied_next_action?: InputMaybe<EnumHingepointFieldUpdateOperationsInput>;
  in_algo_queue?: InputMaybe<BoolFieldUpdateOperationsInput>;
  in_custom_sequence?: InputMaybe<IntFieldUpdateOperationsInput>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventUpdateManyWithoutLeadInput>;
  industry?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogUpdateManyWithoutLeadInput>;
  last_call_result?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  last_call_result_rep?: InputMaybe<UserUpdateOneWithoutLeads_Last_CalledInput>;
  last_call_result_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  last_lead_assignment_origin?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  last_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  last_sent_to_resting_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutLeadInput>;
  lead_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutLeadInput>;
  lead_assignment_origin?: InputMaybe<EnumAssignmentOriginFieldUpdateOperationsInput>;
  lead_creation_source?: InputMaybe<NullableEnumLeadcreationsourceFieldUpdateOperationsInput>;
  lead_custom_row_info?: InputMaybe<LeadCustomObjectRowAssociationUpdateManyWithoutLeadInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryUpdateOneWithoutLeadInput>;
  lead_import_history_csv?: InputMaybe<LeadImportHistoryUpdateOneWithoutUploaded_LeadsInput>;
  lead_intent?: InputMaybe<LeadIntentUpdateManyWithoutLeadInput>;
  lead_ownership_status?: InputMaybe<EnumLosFieldUpdateOperationsInput>;
  lead_source?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  lead_value?: InputMaybe<LeadValueUpdateOneWithoutLeadInput>;
  mrr?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  newIncoming?: InputMaybe<BoolFieldUpdateOperationsInput>;
  next_scheduled_event?: InputMaybe<ScheduleItemUpdateOneWithoutLeadInput>;
  notifications?: InputMaybe<NotificationUpdateManyWithoutLeadInput>;
  opsiq_whitelist?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutLeadsInput>;
  phase?: InputMaybe<EnumPhaseFieldUpdateOperationsInput>;
  primary_email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_email_title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_email_type?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_lead_histories?: InputMaybe<AssociationHistoryUpdateManyWithoutPrimary_LeadInput>;
  primary_phone_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_phone_number_country_code?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_phone_number_title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_phone_number_type?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  rep?: InputMaybe<UserUpdateOneWithoutOwned_LeadsInput>;
  resting_type?: InputMaybe<NullableEnumRestingtypeFieldUpdateOperationsInput>;
  routing_history?: InputMaybe<RoutingHistoryUpdateManyWithoutLeadInput>;
  routing_rule?: InputMaybe<RuleUpdateOneWithoutLeadInput>;
  Sale?: InputMaybe<SaleUpdateManyWithoutLeadInput>;
  sale_cycles?: InputMaybe<SaleCycleUpdateManyWithoutLeadInput>;
  search_content?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  secondary_reps?: InputMaybe<UserUpdateManyWithoutIdle_Owned_LeadsInput>;
  self_sourced?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  sequence?: InputMaybe<SequenceUpdateOneWithoutLeadsInput>;
  sequence_step?: InputMaybe<SequenceStepUpdateOneWithoutLeadsInput>;
  SequenceActionLog?: InputMaybe<StepActionLogUpdateManyWithoutLeadInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutLeadInput>;
  sequences_completed_ids?: InputMaybe<LeadUpdatesequences_Completed_IdsInput>;
  sequences_joined_ids?: InputMaybe<LeadUpdatesequences_Joined_IdsInput>;
  sf_account_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sf_contact_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sf_lead_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sibling_lead_histories?: InputMaybe<AssociationHistoryUpdateManyWithoutSibling_LeadsInput>;
  sms_sub_status?: InputMaybe<EnumOptInStatusFieldUpdateOperationsInput>;
  stat_items?: InputMaybe<StatItemAggregationUpdateManyWithoutLeadInput>;
  state?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  StatLeadAggregation?: InputMaybe<StatLeadAggregationUpdateOneWithoutLeadInput>;
  status?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sub_industry?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timezone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  zip?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type LeadUpdateWithoutEmailThreadInput = {
  address?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  address_2?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  alternate_contacts?: InputMaybe<ContactUpdateManyWithoutLeadInput>;
  assignment_locked?: InputMaybe<BoolFieldUpdateOperationsInput>;
  associate_children?: InputMaybe<LeadUpdateManyWithoutAssociate_Parent_LeadInput>;
  associate_group_lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutAssociate_Group_LeadsInput>;
  associate_lead_histories?: InputMaybe<AssociationHistoryUpdateManyWithoutAssociate_LeadInput>;
  associate_parent_lead?: InputMaybe<LeadUpdateOneWithoutAssociate_ChildrenInput>;
  associated_parent_intents?: InputMaybe<LeadIntentUpdateManyWithoutAssociate_ParentInput>;
  associates_search_content?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  business_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptUpdateManyWithoutLeadInput>;
  callMeNows?: InputMaybe<CallMeNowUpdateManyWithoutLeadInput>;
  channel?: InputMaybe<NullableEnumChannelFieldUpdateOperationsInput>;
  child_lead_activites?: InputMaybe<LeadActivityUpdateManyWithoutAssociate_ChildInput>;
  city?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  claim_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  conference_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  contact_requests?: InputMaybe<ContactRequestUpdateManyWithoutLeadInput>;
  content?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  current_close_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  current_contact_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  current_sale_cycle?: InputMaybe<SaleCycleUpdateOneWithoutLead_If_CurrentInput>;
  custom_field_json?: InputMaybe<Scalars['Json']['input']>;
  custom_fields?: InputMaybe<CustomFieldUpdateManyWithoutLeadInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemUpdateManyWithoutLeadInput>;
  delay_action_status?: InputMaybe<BoolFieldUpdateOperationsInput>;
  email_sub_status?: InputMaybe<EnumOptInStatusFieldUpdateOperationsInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordUpdateManyWithoutLeadInput>;
  favorited_by_users?: InputMaybe<UserUpdateManyWithoutFavorite_LeadsInput>;
  first_demo_schedule_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  first_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  hubspot_company_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  hubspot_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  implied_next_action?: InputMaybe<EnumHingepointFieldUpdateOperationsInput>;
  in_algo_queue?: InputMaybe<BoolFieldUpdateOperationsInput>;
  in_custom_sequence?: InputMaybe<IntFieldUpdateOperationsInput>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventUpdateManyWithoutLeadInput>;
  industry?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogUpdateManyWithoutLeadInput>;
  last_call_result?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  last_call_result_rep?: InputMaybe<UserUpdateOneWithoutLeads_Last_CalledInput>;
  last_call_result_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  last_lead_assignment_origin?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  last_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  last_sent_to_resting_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutLeadInput>;
  lead_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutLeadInput>;
  lead_assignment_origin?: InputMaybe<EnumAssignmentOriginFieldUpdateOperationsInput>;
  lead_creation_source?: InputMaybe<NullableEnumLeadcreationsourceFieldUpdateOperationsInput>;
  lead_custom_row_info?: InputMaybe<LeadCustomObjectRowAssociationUpdateManyWithoutLeadInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryUpdateOneWithoutLeadInput>;
  lead_import_history_csv?: InputMaybe<LeadImportHistoryUpdateOneWithoutUploaded_LeadsInput>;
  lead_intent?: InputMaybe<LeadIntentUpdateManyWithoutLeadInput>;
  lead_ownership_status?: InputMaybe<EnumLosFieldUpdateOperationsInput>;
  lead_source?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  lead_value?: InputMaybe<LeadValueUpdateOneWithoutLeadInput>;
  mrr?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  newIncoming?: InputMaybe<BoolFieldUpdateOperationsInput>;
  next_scheduled_event?: InputMaybe<ScheduleItemUpdateOneWithoutLeadInput>;
  notifications?: InputMaybe<NotificationUpdateManyWithoutLeadInput>;
  opsiq_whitelist?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutLeadsInput>;
  phase?: InputMaybe<EnumPhaseFieldUpdateOperationsInput>;
  primary_email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_email_title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_email_type?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_lead_histories?: InputMaybe<AssociationHistoryUpdateManyWithoutPrimary_LeadInput>;
  primary_phone_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_phone_number_country_code?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_phone_number_title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_phone_number_type?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  rep?: InputMaybe<UserUpdateOneWithoutOwned_LeadsInput>;
  resting_type?: InputMaybe<NullableEnumRestingtypeFieldUpdateOperationsInput>;
  routing_history?: InputMaybe<RoutingHistoryUpdateManyWithoutLeadInput>;
  routing_rule?: InputMaybe<RuleUpdateOneWithoutLeadInput>;
  Sale?: InputMaybe<SaleUpdateManyWithoutLeadInput>;
  sale_cycles?: InputMaybe<SaleCycleUpdateManyWithoutLeadInput>;
  search_content?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  secondary_reps?: InputMaybe<UserUpdateManyWithoutIdle_Owned_LeadsInput>;
  self_sourced?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  sequence?: InputMaybe<SequenceUpdateOneWithoutLeadsInput>;
  sequence_step?: InputMaybe<SequenceStepUpdateOneWithoutLeadsInput>;
  SequenceActionLog?: InputMaybe<StepActionLogUpdateManyWithoutLeadInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutLeadInput>;
  sequences_completed_ids?: InputMaybe<LeadUpdatesequences_Completed_IdsInput>;
  sequences_joined_ids?: InputMaybe<LeadUpdatesequences_Joined_IdsInput>;
  sf_account_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sf_contact_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sf_lead_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sibling_lead_histories?: InputMaybe<AssociationHistoryUpdateManyWithoutSibling_LeadsInput>;
  sms_sub_status?: InputMaybe<EnumOptInStatusFieldUpdateOperationsInput>;
  stat_items?: InputMaybe<StatItemAggregationUpdateManyWithoutLeadInput>;
  state?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  StatLeadAggregation?: InputMaybe<StatLeadAggregationUpdateOneWithoutLeadInput>;
  status?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sub_industry?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timezone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  zip?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type LeadUpdateWithoutFavorited_By_UsersInput = {
  address?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  address_2?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  alternate_contacts?: InputMaybe<ContactUpdateManyWithoutLeadInput>;
  assignment_locked?: InputMaybe<BoolFieldUpdateOperationsInput>;
  associate_children?: InputMaybe<LeadUpdateManyWithoutAssociate_Parent_LeadInput>;
  associate_group_lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutAssociate_Group_LeadsInput>;
  associate_lead_histories?: InputMaybe<AssociationHistoryUpdateManyWithoutAssociate_LeadInput>;
  associate_parent_lead?: InputMaybe<LeadUpdateOneWithoutAssociate_ChildrenInput>;
  associated_parent_intents?: InputMaybe<LeadIntentUpdateManyWithoutAssociate_ParentInput>;
  associates_search_content?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  business_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptUpdateManyWithoutLeadInput>;
  callMeNows?: InputMaybe<CallMeNowUpdateManyWithoutLeadInput>;
  channel?: InputMaybe<NullableEnumChannelFieldUpdateOperationsInput>;
  child_lead_activites?: InputMaybe<LeadActivityUpdateManyWithoutAssociate_ChildInput>;
  city?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  claim_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  conference_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  contact_requests?: InputMaybe<ContactRequestUpdateManyWithoutLeadInput>;
  content?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  current_close_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  current_contact_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  current_sale_cycle?: InputMaybe<SaleCycleUpdateOneWithoutLead_If_CurrentInput>;
  custom_field_json?: InputMaybe<Scalars['Json']['input']>;
  custom_fields?: InputMaybe<CustomFieldUpdateManyWithoutLeadInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemUpdateManyWithoutLeadInput>;
  delay_action_status?: InputMaybe<BoolFieldUpdateOperationsInput>;
  email_sub_status?: InputMaybe<EnumOptInStatusFieldUpdateOperationsInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordUpdateManyWithoutLeadInput>;
  EmailThread?: InputMaybe<EmailThreadUpdateManyWithoutLeadInput>;
  first_demo_schedule_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  first_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  hubspot_company_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  hubspot_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  implied_next_action?: InputMaybe<EnumHingepointFieldUpdateOperationsInput>;
  in_algo_queue?: InputMaybe<BoolFieldUpdateOperationsInput>;
  in_custom_sequence?: InputMaybe<IntFieldUpdateOperationsInput>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventUpdateManyWithoutLeadInput>;
  industry?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogUpdateManyWithoutLeadInput>;
  last_call_result?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  last_call_result_rep?: InputMaybe<UserUpdateOneWithoutLeads_Last_CalledInput>;
  last_call_result_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  last_lead_assignment_origin?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  last_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  last_sent_to_resting_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutLeadInput>;
  lead_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutLeadInput>;
  lead_assignment_origin?: InputMaybe<EnumAssignmentOriginFieldUpdateOperationsInput>;
  lead_creation_source?: InputMaybe<NullableEnumLeadcreationsourceFieldUpdateOperationsInput>;
  lead_custom_row_info?: InputMaybe<LeadCustomObjectRowAssociationUpdateManyWithoutLeadInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryUpdateOneWithoutLeadInput>;
  lead_import_history_csv?: InputMaybe<LeadImportHistoryUpdateOneWithoutUploaded_LeadsInput>;
  lead_intent?: InputMaybe<LeadIntentUpdateManyWithoutLeadInput>;
  lead_ownership_status?: InputMaybe<EnumLosFieldUpdateOperationsInput>;
  lead_source?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  lead_value?: InputMaybe<LeadValueUpdateOneWithoutLeadInput>;
  mrr?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  newIncoming?: InputMaybe<BoolFieldUpdateOperationsInput>;
  next_scheduled_event?: InputMaybe<ScheduleItemUpdateOneWithoutLeadInput>;
  notifications?: InputMaybe<NotificationUpdateManyWithoutLeadInput>;
  opsiq_whitelist?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutLeadsInput>;
  phase?: InputMaybe<EnumPhaseFieldUpdateOperationsInput>;
  primary_email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_email_title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_email_type?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_lead_histories?: InputMaybe<AssociationHistoryUpdateManyWithoutPrimary_LeadInput>;
  primary_phone_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_phone_number_country_code?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_phone_number_title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_phone_number_type?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  rep?: InputMaybe<UserUpdateOneWithoutOwned_LeadsInput>;
  resting_type?: InputMaybe<NullableEnumRestingtypeFieldUpdateOperationsInput>;
  routing_history?: InputMaybe<RoutingHistoryUpdateManyWithoutLeadInput>;
  routing_rule?: InputMaybe<RuleUpdateOneWithoutLeadInput>;
  Sale?: InputMaybe<SaleUpdateManyWithoutLeadInput>;
  sale_cycles?: InputMaybe<SaleCycleUpdateManyWithoutLeadInput>;
  search_content?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  secondary_reps?: InputMaybe<UserUpdateManyWithoutIdle_Owned_LeadsInput>;
  self_sourced?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  sequence?: InputMaybe<SequenceUpdateOneWithoutLeadsInput>;
  sequence_step?: InputMaybe<SequenceStepUpdateOneWithoutLeadsInput>;
  SequenceActionLog?: InputMaybe<StepActionLogUpdateManyWithoutLeadInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutLeadInput>;
  sequences_completed_ids?: InputMaybe<LeadUpdatesequences_Completed_IdsInput>;
  sequences_joined_ids?: InputMaybe<LeadUpdatesequences_Joined_IdsInput>;
  sf_account_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sf_contact_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sf_lead_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sibling_lead_histories?: InputMaybe<AssociationHistoryUpdateManyWithoutSibling_LeadsInput>;
  sms_sub_status?: InputMaybe<EnumOptInStatusFieldUpdateOperationsInput>;
  stat_items?: InputMaybe<StatItemAggregationUpdateManyWithoutLeadInput>;
  state?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  StatLeadAggregation?: InputMaybe<StatLeadAggregationUpdateOneWithoutLeadInput>;
  status?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sub_industry?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timezone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  zip?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type LeadUpdateWithoutInboundConciergeEventsInput = {
  address?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  address_2?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  alternate_contacts?: InputMaybe<ContactUpdateManyWithoutLeadInput>;
  assignment_locked?: InputMaybe<BoolFieldUpdateOperationsInput>;
  associate_children?: InputMaybe<LeadUpdateManyWithoutAssociate_Parent_LeadInput>;
  associate_group_lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutAssociate_Group_LeadsInput>;
  associate_lead_histories?: InputMaybe<AssociationHistoryUpdateManyWithoutAssociate_LeadInput>;
  associate_parent_lead?: InputMaybe<LeadUpdateOneWithoutAssociate_ChildrenInput>;
  associated_parent_intents?: InputMaybe<LeadIntentUpdateManyWithoutAssociate_ParentInput>;
  associates_search_content?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  business_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptUpdateManyWithoutLeadInput>;
  callMeNows?: InputMaybe<CallMeNowUpdateManyWithoutLeadInput>;
  channel?: InputMaybe<NullableEnumChannelFieldUpdateOperationsInput>;
  child_lead_activites?: InputMaybe<LeadActivityUpdateManyWithoutAssociate_ChildInput>;
  city?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  claim_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  conference_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  contact_requests?: InputMaybe<ContactRequestUpdateManyWithoutLeadInput>;
  content?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  current_close_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  current_contact_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  current_sale_cycle?: InputMaybe<SaleCycleUpdateOneWithoutLead_If_CurrentInput>;
  custom_field_json?: InputMaybe<Scalars['Json']['input']>;
  custom_fields?: InputMaybe<CustomFieldUpdateManyWithoutLeadInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemUpdateManyWithoutLeadInput>;
  delay_action_status?: InputMaybe<BoolFieldUpdateOperationsInput>;
  email_sub_status?: InputMaybe<EnumOptInStatusFieldUpdateOperationsInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordUpdateManyWithoutLeadInput>;
  EmailThread?: InputMaybe<EmailThreadUpdateManyWithoutLeadInput>;
  favorited_by_users?: InputMaybe<UserUpdateManyWithoutFavorite_LeadsInput>;
  first_demo_schedule_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  first_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  hubspot_company_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  hubspot_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  implied_next_action?: InputMaybe<EnumHingepointFieldUpdateOperationsInput>;
  in_algo_queue?: InputMaybe<BoolFieldUpdateOperationsInput>;
  in_custom_sequence?: InputMaybe<IntFieldUpdateOperationsInput>;
  industry?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogUpdateManyWithoutLeadInput>;
  last_call_result?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  last_call_result_rep?: InputMaybe<UserUpdateOneWithoutLeads_Last_CalledInput>;
  last_call_result_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  last_lead_assignment_origin?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  last_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  last_sent_to_resting_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutLeadInput>;
  lead_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutLeadInput>;
  lead_assignment_origin?: InputMaybe<EnumAssignmentOriginFieldUpdateOperationsInput>;
  lead_creation_source?: InputMaybe<NullableEnumLeadcreationsourceFieldUpdateOperationsInput>;
  lead_custom_row_info?: InputMaybe<LeadCustomObjectRowAssociationUpdateManyWithoutLeadInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryUpdateOneWithoutLeadInput>;
  lead_import_history_csv?: InputMaybe<LeadImportHistoryUpdateOneWithoutUploaded_LeadsInput>;
  lead_intent?: InputMaybe<LeadIntentUpdateManyWithoutLeadInput>;
  lead_ownership_status?: InputMaybe<EnumLosFieldUpdateOperationsInput>;
  lead_source?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  lead_value?: InputMaybe<LeadValueUpdateOneWithoutLeadInput>;
  mrr?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  newIncoming?: InputMaybe<BoolFieldUpdateOperationsInput>;
  next_scheduled_event?: InputMaybe<ScheduleItemUpdateOneWithoutLeadInput>;
  notifications?: InputMaybe<NotificationUpdateManyWithoutLeadInput>;
  opsiq_whitelist?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutLeadsInput>;
  phase?: InputMaybe<EnumPhaseFieldUpdateOperationsInput>;
  primary_email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_email_title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_email_type?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_lead_histories?: InputMaybe<AssociationHistoryUpdateManyWithoutPrimary_LeadInput>;
  primary_phone_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_phone_number_country_code?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_phone_number_title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_phone_number_type?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  rep?: InputMaybe<UserUpdateOneWithoutOwned_LeadsInput>;
  resting_type?: InputMaybe<NullableEnumRestingtypeFieldUpdateOperationsInput>;
  routing_history?: InputMaybe<RoutingHistoryUpdateManyWithoutLeadInput>;
  routing_rule?: InputMaybe<RuleUpdateOneWithoutLeadInput>;
  Sale?: InputMaybe<SaleUpdateManyWithoutLeadInput>;
  sale_cycles?: InputMaybe<SaleCycleUpdateManyWithoutLeadInput>;
  search_content?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  secondary_reps?: InputMaybe<UserUpdateManyWithoutIdle_Owned_LeadsInput>;
  self_sourced?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  sequence?: InputMaybe<SequenceUpdateOneWithoutLeadsInput>;
  sequence_step?: InputMaybe<SequenceStepUpdateOneWithoutLeadsInput>;
  SequenceActionLog?: InputMaybe<StepActionLogUpdateManyWithoutLeadInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutLeadInput>;
  sequences_completed_ids?: InputMaybe<LeadUpdatesequences_Completed_IdsInput>;
  sequences_joined_ids?: InputMaybe<LeadUpdatesequences_Joined_IdsInput>;
  sf_account_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sf_contact_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sf_lead_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sibling_lead_histories?: InputMaybe<AssociationHistoryUpdateManyWithoutSibling_LeadsInput>;
  sms_sub_status?: InputMaybe<EnumOptInStatusFieldUpdateOperationsInput>;
  stat_items?: InputMaybe<StatItemAggregationUpdateManyWithoutLeadInput>;
  state?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  StatLeadAggregation?: InputMaybe<StatLeadAggregationUpdateOneWithoutLeadInput>;
  status?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sub_industry?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timezone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  zip?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type LeadUpdateWithoutIntegrationOperationLogInput = {
  address?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  address_2?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  alternate_contacts?: InputMaybe<ContactUpdateManyWithoutLeadInput>;
  assignment_locked?: InputMaybe<BoolFieldUpdateOperationsInput>;
  associate_children?: InputMaybe<LeadUpdateManyWithoutAssociate_Parent_LeadInput>;
  associate_group_lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutAssociate_Group_LeadsInput>;
  associate_lead_histories?: InputMaybe<AssociationHistoryUpdateManyWithoutAssociate_LeadInput>;
  associate_parent_lead?: InputMaybe<LeadUpdateOneWithoutAssociate_ChildrenInput>;
  associated_parent_intents?: InputMaybe<LeadIntentUpdateManyWithoutAssociate_ParentInput>;
  associates_search_content?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  business_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptUpdateManyWithoutLeadInput>;
  callMeNows?: InputMaybe<CallMeNowUpdateManyWithoutLeadInput>;
  channel?: InputMaybe<NullableEnumChannelFieldUpdateOperationsInput>;
  child_lead_activites?: InputMaybe<LeadActivityUpdateManyWithoutAssociate_ChildInput>;
  city?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  claim_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  conference_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  contact_requests?: InputMaybe<ContactRequestUpdateManyWithoutLeadInput>;
  content?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  current_close_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  current_contact_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  current_sale_cycle?: InputMaybe<SaleCycleUpdateOneWithoutLead_If_CurrentInput>;
  custom_field_json?: InputMaybe<Scalars['Json']['input']>;
  custom_fields?: InputMaybe<CustomFieldUpdateManyWithoutLeadInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemUpdateManyWithoutLeadInput>;
  delay_action_status?: InputMaybe<BoolFieldUpdateOperationsInput>;
  email_sub_status?: InputMaybe<EnumOptInStatusFieldUpdateOperationsInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordUpdateManyWithoutLeadInput>;
  EmailThread?: InputMaybe<EmailThreadUpdateManyWithoutLeadInput>;
  favorited_by_users?: InputMaybe<UserUpdateManyWithoutFavorite_LeadsInput>;
  first_demo_schedule_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  first_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  hubspot_company_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  hubspot_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  implied_next_action?: InputMaybe<EnumHingepointFieldUpdateOperationsInput>;
  in_algo_queue?: InputMaybe<BoolFieldUpdateOperationsInput>;
  in_custom_sequence?: InputMaybe<IntFieldUpdateOperationsInput>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventUpdateManyWithoutLeadInput>;
  industry?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  last_call_result?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  last_call_result_rep?: InputMaybe<UserUpdateOneWithoutLeads_Last_CalledInput>;
  last_call_result_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  last_lead_assignment_origin?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  last_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  last_sent_to_resting_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutLeadInput>;
  lead_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutLeadInput>;
  lead_assignment_origin?: InputMaybe<EnumAssignmentOriginFieldUpdateOperationsInput>;
  lead_creation_source?: InputMaybe<NullableEnumLeadcreationsourceFieldUpdateOperationsInput>;
  lead_custom_row_info?: InputMaybe<LeadCustomObjectRowAssociationUpdateManyWithoutLeadInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryUpdateOneWithoutLeadInput>;
  lead_import_history_csv?: InputMaybe<LeadImportHistoryUpdateOneWithoutUploaded_LeadsInput>;
  lead_intent?: InputMaybe<LeadIntentUpdateManyWithoutLeadInput>;
  lead_ownership_status?: InputMaybe<EnumLosFieldUpdateOperationsInput>;
  lead_source?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  lead_value?: InputMaybe<LeadValueUpdateOneWithoutLeadInput>;
  mrr?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  newIncoming?: InputMaybe<BoolFieldUpdateOperationsInput>;
  next_scheduled_event?: InputMaybe<ScheduleItemUpdateOneWithoutLeadInput>;
  notifications?: InputMaybe<NotificationUpdateManyWithoutLeadInput>;
  opsiq_whitelist?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutLeadsInput>;
  phase?: InputMaybe<EnumPhaseFieldUpdateOperationsInput>;
  primary_email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_email_title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_email_type?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_lead_histories?: InputMaybe<AssociationHistoryUpdateManyWithoutPrimary_LeadInput>;
  primary_phone_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_phone_number_country_code?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_phone_number_title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_phone_number_type?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  rep?: InputMaybe<UserUpdateOneWithoutOwned_LeadsInput>;
  resting_type?: InputMaybe<NullableEnumRestingtypeFieldUpdateOperationsInput>;
  routing_history?: InputMaybe<RoutingHistoryUpdateManyWithoutLeadInput>;
  routing_rule?: InputMaybe<RuleUpdateOneWithoutLeadInput>;
  Sale?: InputMaybe<SaleUpdateManyWithoutLeadInput>;
  sale_cycles?: InputMaybe<SaleCycleUpdateManyWithoutLeadInput>;
  search_content?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  secondary_reps?: InputMaybe<UserUpdateManyWithoutIdle_Owned_LeadsInput>;
  self_sourced?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  sequence?: InputMaybe<SequenceUpdateOneWithoutLeadsInput>;
  sequence_step?: InputMaybe<SequenceStepUpdateOneWithoutLeadsInput>;
  SequenceActionLog?: InputMaybe<StepActionLogUpdateManyWithoutLeadInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutLeadInput>;
  sequences_completed_ids?: InputMaybe<LeadUpdatesequences_Completed_IdsInput>;
  sequences_joined_ids?: InputMaybe<LeadUpdatesequences_Joined_IdsInput>;
  sf_account_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sf_contact_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sf_lead_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sibling_lead_histories?: InputMaybe<AssociationHistoryUpdateManyWithoutSibling_LeadsInput>;
  sms_sub_status?: InputMaybe<EnumOptInStatusFieldUpdateOperationsInput>;
  stat_items?: InputMaybe<StatItemAggregationUpdateManyWithoutLeadInput>;
  state?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  StatLeadAggregation?: InputMaybe<StatLeadAggregationUpdateOneWithoutLeadInput>;
  status?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sub_industry?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timezone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  zip?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type LeadUpdateWithoutLast_Call_Result_RepInput = {
  address?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  address_2?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  alternate_contacts?: InputMaybe<ContactUpdateManyWithoutLeadInput>;
  assignment_locked?: InputMaybe<BoolFieldUpdateOperationsInput>;
  associate_children?: InputMaybe<LeadUpdateManyWithoutAssociate_Parent_LeadInput>;
  associate_group_lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutAssociate_Group_LeadsInput>;
  associate_lead_histories?: InputMaybe<AssociationHistoryUpdateManyWithoutAssociate_LeadInput>;
  associate_parent_lead?: InputMaybe<LeadUpdateOneWithoutAssociate_ChildrenInput>;
  associated_parent_intents?: InputMaybe<LeadIntentUpdateManyWithoutAssociate_ParentInput>;
  associates_search_content?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  business_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptUpdateManyWithoutLeadInput>;
  callMeNows?: InputMaybe<CallMeNowUpdateManyWithoutLeadInput>;
  channel?: InputMaybe<NullableEnumChannelFieldUpdateOperationsInput>;
  child_lead_activites?: InputMaybe<LeadActivityUpdateManyWithoutAssociate_ChildInput>;
  city?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  claim_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  conference_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  contact_requests?: InputMaybe<ContactRequestUpdateManyWithoutLeadInput>;
  content?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  current_close_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  current_contact_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  current_sale_cycle?: InputMaybe<SaleCycleUpdateOneWithoutLead_If_CurrentInput>;
  custom_field_json?: InputMaybe<Scalars['Json']['input']>;
  custom_fields?: InputMaybe<CustomFieldUpdateManyWithoutLeadInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemUpdateManyWithoutLeadInput>;
  delay_action_status?: InputMaybe<BoolFieldUpdateOperationsInput>;
  email_sub_status?: InputMaybe<EnumOptInStatusFieldUpdateOperationsInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordUpdateManyWithoutLeadInput>;
  EmailThread?: InputMaybe<EmailThreadUpdateManyWithoutLeadInput>;
  favorited_by_users?: InputMaybe<UserUpdateManyWithoutFavorite_LeadsInput>;
  first_demo_schedule_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  first_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  hubspot_company_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  hubspot_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  implied_next_action?: InputMaybe<EnumHingepointFieldUpdateOperationsInput>;
  in_algo_queue?: InputMaybe<BoolFieldUpdateOperationsInput>;
  in_custom_sequence?: InputMaybe<IntFieldUpdateOperationsInput>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventUpdateManyWithoutLeadInput>;
  industry?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogUpdateManyWithoutLeadInput>;
  last_call_result?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  last_call_result_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  last_lead_assignment_origin?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  last_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  last_sent_to_resting_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutLeadInput>;
  lead_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutLeadInput>;
  lead_assignment_origin?: InputMaybe<EnumAssignmentOriginFieldUpdateOperationsInput>;
  lead_creation_source?: InputMaybe<NullableEnumLeadcreationsourceFieldUpdateOperationsInput>;
  lead_custom_row_info?: InputMaybe<LeadCustomObjectRowAssociationUpdateManyWithoutLeadInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryUpdateOneWithoutLeadInput>;
  lead_import_history_csv?: InputMaybe<LeadImportHistoryUpdateOneWithoutUploaded_LeadsInput>;
  lead_intent?: InputMaybe<LeadIntentUpdateManyWithoutLeadInput>;
  lead_ownership_status?: InputMaybe<EnumLosFieldUpdateOperationsInput>;
  lead_source?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  lead_value?: InputMaybe<LeadValueUpdateOneWithoutLeadInput>;
  mrr?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  newIncoming?: InputMaybe<BoolFieldUpdateOperationsInput>;
  next_scheduled_event?: InputMaybe<ScheduleItemUpdateOneWithoutLeadInput>;
  notifications?: InputMaybe<NotificationUpdateManyWithoutLeadInput>;
  opsiq_whitelist?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutLeadsInput>;
  phase?: InputMaybe<EnumPhaseFieldUpdateOperationsInput>;
  primary_email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_email_title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_email_type?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_lead_histories?: InputMaybe<AssociationHistoryUpdateManyWithoutPrimary_LeadInput>;
  primary_phone_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_phone_number_country_code?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_phone_number_title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_phone_number_type?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  rep?: InputMaybe<UserUpdateOneWithoutOwned_LeadsInput>;
  resting_type?: InputMaybe<NullableEnumRestingtypeFieldUpdateOperationsInput>;
  routing_history?: InputMaybe<RoutingHistoryUpdateManyWithoutLeadInput>;
  routing_rule?: InputMaybe<RuleUpdateOneWithoutLeadInput>;
  Sale?: InputMaybe<SaleUpdateManyWithoutLeadInput>;
  sale_cycles?: InputMaybe<SaleCycleUpdateManyWithoutLeadInput>;
  search_content?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  secondary_reps?: InputMaybe<UserUpdateManyWithoutIdle_Owned_LeadsInput>;
  self_sourced?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  sequence?: InputMaybe<SequenceUpdateOneWithoutLeadsInput>;
  sequence_step?: InputMaybe<SequenceStepUpdateOneWithoutLeadsInput>;
  SequenceActionLog?: InputMaybe<StepActionLogUpdateManyWithoutLeadInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutLeadInput>;
  sequences_completed_ids?: InputMaybe<LeadUpdatesequences_Completed_IdsInput>;
  sequences_joined_ids?: InputMaybe<LeadUpdatesequences_Joined_IdsInput>;
  sf_account_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sf_contact_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sf_lead_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sibling_lead_histories?: InputMaybe<AssociationHistoryUpdateManyWithoutSibling_LeadsInput>;
  sms_sub_status?: InputMaybe<EnumOptInStatusFieldUpdateOperationsInput>;
  stat_items?: InputMaybe<StatItemAggregationUpdateManyWithoutLeadInput>;
  state?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  StatLeadAggregation?: InputMaybe<StatLeadAggregationUpdateOneWithoutLeadInput>;
  status?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sub_industry?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timezone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  zip?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type LeadUpdateWithoutLead_ActivitiesInput = {
  address?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  address_2?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  alternate_contacts?: InputMaybe<ContactUpdateManyWithoutLeadInput>;
  assignment_locked?: InputMaybe<BoolFieldUpdateOperationsInput>;
  associate_children?: InputMaybe<LeadUpdateManyWithoutAssociate_Parent_LeadInput>;
  associate_group_lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutAssociate_Group_LeadsInput>;
  associate_lead_histories?: InputMaybe<AssociationHistoryUpdateManyWithoutAssociate_LeadInput>;
  associate_parent_lead?: InputMaybe<LeadUpdateOneWithoutAssociate_ChildrenInput>;
  associated_parent_intents?: InputMaybe<LeadIntentUpdateManyWithoutAssociate_ParentInput>;
  associates_search_content?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  business_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptUpdateManyWithoutLeadInput>;
  callMeNows?: InputMaybe<CallMeNowUpdateManyWithoutLeadInput>;
  channel?: InputMaybe<NullableEnumChannelFieldUpdateOperationsInput>;
  child_lead_activites?: InputMaybe<LeadActivityUpdateManyWithoutAssociate_ChildInput>;
  city?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  claim_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  conference_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  contact_requests?: InputMaybe<ContactRequestUpdateManyWithoutLeadInput>;
  content?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  current_close_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  current_contact_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  current_sale_cycle?: InputMaybe<SaleCycleUpdateOneWithoutLead_If_CurrentInput>;
  custom_field_json?: InputMaybe<Scalars['Json']['input']>;
  custom_fields?: InputMaybe<CustomFieldUpdateManyWithoutLeadInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemUpdateManyWithoutLeadInput>;
  delay_action_status?: InputMaybe<BoolFieldUpdateOperationsInput>;
  email_sub_status?: InputMaybe<EnumOptInStatusFieldUpdateOperationsInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordUpdateManyWithoutLeadInput>;
  EmailThread?: InputMaybe<EmailThreadUpdateManyWithoutLeadInput>;
  favorited_by_users?: InputMaybe<UserUpdateManyWithoutFavorite_LeadsInput>;
  first_demo_schedule_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  first_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  hubspot_company_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  hubspot_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  implied_next_action?: InputMaybe<EnumHingepointFieldUpdateOperationsInput>;
  in_algo_queue?: InputMaybe<BoolFieldUpdateOperationsInput>;
  in_custom_sequence?: InputMaybe<IntFieldUpdateOperationsInput>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventUpdateManyWithoutLeadInput>;
  industry?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogUpdateManyWithoutLeadInput>;
  last_call_result?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  last_call_result_rep?: InputMaybe<UserUpdateOneWithoutLeads_Last_CalledInput>;
  last_call_result_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  last_lead_assignment_origin?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  last_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  last_sent_to_resting_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lead_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutLeadInput>;
  lead_assignment_origin?: InputMaybe<EnumAssignmentOriginFieldUpdateOperationsInput>;
  lead_creation_source?: InputMaybe<NullableEnumLeadcreationsourceFieldUpdateOperationsInput>;
  lead_custom_row_info?: InputMaybe<LeadCustomObjectRowAssociationUpdateManyWithoutLeadInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryUpdateOneWithoutLeadInput>;
  lead_import_history_csv?: InputMaybe<LeadImportHistoryUpdateOneWithoutUploaded_LeadsInput>;
  lead_intent?: InputMaybe<LeadIntentUpdateManyWithoutLeadInput>;
  lead_ownership_status?: InputMaybe<EnumLosFieldUpdateOperationsInput>;
  lead_source?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  lead_value?: InputMaybe<LeadValueUpdateOneWithoutLeadInput>;
  mrr?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  newIncoming?: InputMaybe<BoolFieldUpdateOperationsInput>;
  next_scheduled_event?: InputMaybe<ScheduleItemUpdateOneWithoutLeadInput>;
  notifications?: InputMaybe<NotificationUpdateManyWithoutLeadInput>;
  opsiq_whitelist?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutLeadsInput>;
  phase?: InputMaybe<EnumPhaseFieldUpdateOperationsInput>;
  primary_email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_email_title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_email_type?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_lead_histories?: InputMaybe<AssociationHistoryUpdateManyWithoutPrimary_LeadInput>;
  primary_phone_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_phone_number_country_code?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_phone_number_title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_phone_number_type?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  rep?: InputMaybe<UserUpdateOneWithoutOwned_LeadsInput>;
  resting_type?: InputMaybe<NullableEnumRestingtypeFieldUpdateOperationsInput>;
  routing_history?: InputMaybe<RoutingHistoryUpdateManyWithoutLeadInput>;
  routing_rule?: InputMaybe<RuleUpdateOneWithoutLeadInput>;
  Sale?: InputMaybe<SaleUpdateManyWithoutLeadInput>;
  sale_cycles?: InputMaybe<SaleCycleUpdateManyWithoutLeadInput>;
  search_content?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  secondary_reps?: InputMaybe<UserUpdateManyWithoutIdle_Owned_LeadsInput>;
  self_sourced?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  sequence?: InputMaybe<SequenceUpdateOneWithoutLeadsInput>;
  sequence_step?: InputMaybe<SequenceStepUpdateOneWithoutLeadsInput>;
  SequenceActionLog?: InputMaybe<StepActionLogUpdateManyWithoutLeadInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutLeadInput>;
  sequences_completed_ids?: InputMaybe<LeadUpdatesequences_Completed_IdsInput>;
  sequences_joined_ids?: InputMaybe<LeadUpdatesequences_Joined_IdsInput>;
  sf_account_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sf_contact_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sf_lead_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sibling_lead_histories?: InputMaybe<AssociationHistoryUpdateManyWithoutSibling_LeadsInput>;
  sms_sub_status?: InputMaybe<EnumOptInStatusFieldUpdateOperationsInput>;
  stat_items?: InputMaybe<StatItemAggregationUpdateManyWithoutLeadInput>;
  state?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  StatLeadAggregation?: InputMaybe<StatLeadAggregationUpdateOneWithoutLeadInput>;
  status?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sub_industry?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timezone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  zip?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type LeadUpdateWithoutLead_Assignment_HistoryInput = {
  address?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  address_2?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  alternate_contacts?: InputMaybe<ContactUpdateManyWithoutLeadInput>;
  assignment_locked?: InputMaybe<BoolFieldUpdateOperationsInput>;
  associate_children?: InputMaybe<LeadUpdateManyWithoutAssociate_Parent_LeadInput>;
  associate_group_lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutAssociate_Group_LeadsInput>;
  associate_lead_histories?: InputMaybe<AssociationHistoryUpdateManyWithoutAssociate_LeadInput>;
  associate_parent_lead?: InputMaybe<LeadUpdateOneWithoutAssociate_ChildrenInput>;
  associated_parent_intents?: InputMaybe<LeadIntentUpdateManyWithoutAssociate_ParentInput>;
  associates_search_content?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  business_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptUpdateManyWithoutLeadInput>;
  callMeNows?: InputMaybe<CallMeNowUpdateManyWithoutLeadInput>;
  channel?: InputMaybe<NullableEnumChannelFieldUpdateOperationsInput>;
  child_lead_activites?: InputMaybe<LeadActivityUpdateManyWithoutAssociate_ChildInput>;
  city?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  claim_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  conference_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  contact_requests?: InputMaybe<ContactRequestUpdateManyWithoutLeadInput>;
  content?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  current_close_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  current_contact_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  current_sale_cycle?: InputMaybe<SaleCycleUpdateOneWithoutLead_If_CurrentInput>;
  custom_field_json?: InputMaybe<Scalars['Json']['input']>;
  custom_fields?: InputMaybe<CustomFieldUpdateManyWithoutLeadInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemUpdateManyWithoutLeadInput>;
  delay_action_status?: InputMaybe<BoolFieldUpdateOperationsInput>;
  email_sub_status?: InputMaybe<EnumOptInStatusFieldUpdateOperationsInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordUpdateManyWithoutLeadInput>;
  EmailThread?: InputMaybe<EmailThreadUpdateManyWithoutLeadInput>;
  favorited_by_users?: InputMaybe<UserUpdateManyWithoutFavorite_LeadsInput>;
  first_demo_schedule_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  first_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  hubspot_company_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  hubspot_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  implied_next_action?: InputMaybe<EnumHingepointFieldUpdateOperationsInput>;
  in_algo_queue?: InputMaybe<BoolFieldUpdateOperationsInput>;
  in_custom_sequence?: InputMaybe<IntFieldUpdateOperationsInput>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventUpdateManyWithoutLeadInput>;
  industry?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogUpdateManyWithoutLeadInput>;
  last_call_result?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  last_call_result_rep?: InputMaybe<UserUpdateOneWithoutLeads_Last_CalledInput>;
  last_call_result_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  last_lead_assignment_origin?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  last_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  last_sent_to_resting_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutLeadInput>;
  lead_assignment_origin?: InputMaybe<EnumAssignmentOriginFieldUpdateOperationsInput>;
  lead_creation_source?: InputMaybe<NullableEnumLeadcreationsourceFieldUpdateOperationsInput>;
  lead_custom_row_info?: InputMaybe<LeadCustomObjectRowAssociationUpdateManyWithoutLeadInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryUpdateOneWithoutLeadInput>;
  lead_import_history_csv?: InputMaybe<LeadImportHistoryUpdateOneWithoutUploaded_LeadsInput>;
  lead_intent?: InputMaybe<LeadIntentUpdateManyWithoutLeadInput>;
  lead_ownership_status?: InputMaybe<EnumLosFieldUpdateOperationsInput>;
  lead_source?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  lead_value?: InputMaybe<LeadValueUpdateOneWithoutLeadInput>;
  mrr?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  newIncoming?: InputMaybe<BoolFieldUpdateOperationsInput>;
  next_scheduled_event?: InputMaybe<ScheduleItemUpdateOneWithoutLeadInput>;
  notifications?: InputMaybe<NotificationUpdateManyWithoutLeadInput>;
  opsiq_whitelist?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutLeadsInput>;
  phase?: InputMaybe<EnumPhaseFieldUpdateOperationsInput>;
  primary_email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_email_title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_email_type?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_lead_histories?: InputMaybe<AssociationHistoryUpdateManyWithoutPrimary_LeadInput>;
  primary_phone_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_phone_number_country_code?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_phone_number_title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_phone_number_type?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  rep?: InputMaybe<UserUpdateOneWithoutOwned_LeadsInput>;
  resting_type?: InputMaybe<NullableEnumRestingtypeFieldUpdateOperationsInput>;
  routing_history?: InputMaybe<RoutingHistoryUpdateManyWithoutLeadInput>;
  routing_rule?: InputMaybe<RuleUpdateOneWithoutLeadInput>;
  Sale?: InputMaybe<SaleUpdateManyWithoutLeadInput>;
  sale_cycles?: InputMaybe<SaleCycleUpdateManyWithoutLeadInput>;
  search_content?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  secondary_reps?: InputMaybe<UserUpdateManyWithoutIdle_Owned_LeadsInput>;
  self_sourced?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  sequence?: InputMaybe<SequenceUpdateOneWithoutLeadsInput>;
  sequence_step?: InputMaybe<SequenceStepUpdateOneWithoutLeadsInput>;
  SequenceActionLog?: InputMaybe<StepActionLogUpdateManyWithoutLeadInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutLeadInput>;
  sequences_completed_ids?: InputMaybe<LeadUpdatesequences_Completed_IdsInput>;
  sequences_joined_ids?: InputMaybe<LeadUpdatesequences_Joined_IdsInput>;
  sf_account_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sf_contact_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sf_lead_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sibling_lead_histories?: InputMaybe<AssociationHistoryUpdateManyWithoutSibling_LeadsInput>;
  sms_sub_status?: InputMaybe<EnumOptInStatusFieldUpdateOperationsInput>;
  stat_items?: InputMaybe<StatItemAggregationUpdateManyWithoutLeadInput>;
  state?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  StatLeadAggregation?: InputMaybe<StatLeadAggregationUpdateOneWithoutLeadInput>;
  status?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sub_industry?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timezone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  zip?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type LeadUpdateWithoutLead_Custom_Row_InfoInput = {
  address?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  address_2?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  alternate_contacts?: InputMaybe<ContactUpdateManyWithoutLeadInput>;
  assignment_locked?: InputMaybe<BoolFieldUpdateOperationsInput>;
  associate_children?: InputMaybe<LeadUpdateManyWithoutAssociate_Parent_LeadInput>;
  associate_group_lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutAssociate_Group_LeadsInput>;
  associate_lead_histories?: InputMaybe<AssociationHistoryUpdateManyWithoutAssociate_LeadInput>;
  associate_parent_lead?: InputMaybe<LeadUpdateOneWithoutAssociate_ChildrenInput>;
  associated_parent_intents?: InputMaybe<LeadIntentUpdateManyWithoutAssociate_ParentInput>;
  associates_search_content?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  business_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptUpdateManyWithoutLeadInput>;
  callMeNows?: InputMaybe<CallMeNowUpdateManyWithoutLeadInput>;
  channel?: InputMaybe<NullableEnumChannelFieldUpdateOperationsInput>;
  child_lead_activites?: InputMaybe<LeadActivityUpdateManyWithoutAssociate_ChildInput>;
  city?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  claim_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  conference_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  contact_requests?: InputMaybe<ContactRequestUpdateManyWithoutLeadInput>;
  content?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  current_close_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  current_contact_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  current_sale_cycle?: InputMaybe<SaleCycleUpdateOneWithoutLead_If_CurrentInput>;
  custom_field_json?: InputMaybe<Scalars['Json']['input']>;
  custom_fields?: InputMaybe<CustomFieldUpdateManyWithoutLeadInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemUpdateManyWithoutLeadInput>;
  delay_action_status?: InputMaybe<BoolFieldUpdateOperationsInput>;
  email_sub_status?: InputMaybe<EnumOptInStatusFieldUpdateOperationsInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordUpdateManyWithoutLeadInput>;
  EmailThread?: InputMaybe<EmailThreadUpdateManyWithoutLeadInput>;
  favorited_by_users?: InputMaybe<UserUpdateManyWithoutFavorite_LeadsInput>;
  first_demo_schedule_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  first_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  hubspot_company_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  hubspot_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  implied_next_action?: InputMaybe<EnumHingepointFieldUpdateOperationsInput>;
  in_algo_queue?: InputMaybe<BoolFieldUpdateOperationsInput>;
  in_custom_sequence?: InputMaybe<IntFieldUpdateOperationsInput>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventUpdateManyWithoutLeadInput>;
  industry?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogUpdateManyWithoutLeadInput>;
  last_call_result?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  last_call_result_rep?: InputMaybe<UserUpdateOneWithoutLeads_Last_CalledInput>;
  last_call_result_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  last_lead_assignment_origin?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  last_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  last_sent_to_resting_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutLeadInput>;
  lead_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutLeadInput>;
  lead_assignment_origin?: InputMaybe<EnumAssignmentOriginFieldUpdateOperationsInput>;
  lead_creation_source?: InputMaybe<NullableEnumLeadcreationsourceFieldUpdateOperationsInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryUpdateOneWithoutLeadInput>;
  lead_import_history_csv?: InputMaybe<LeadImportHistoryUpdateOneWithoutUploaded_LeadsInput>;
  lead_intent?: InputMaybe<LeadIntentUpdateManyWithoutLeadInput>;
  lead_ownership_status?: InputMaybe<EnumLosFieldUpdateOperationsInput>;
  lead_source?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  lead_value?: InputMaybe<LeadValueUpdateOneWithoutLeadInput>;
  mrr?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  newIncoming?: InputMaybe<BoolFieldUpdateOperationsInput>;
  next_scheduled_event?: InputMaybe<ScheduleItemUpdateOneWithoutLeadInput>;
  notifications?: InputMaybe<NotificationUpdateManyWithoutLeadInput>;
  opsiq_whitelist?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutLeadsInput>;
  phase?: InputMaybe<EnumPhaseFieldUpdateOperationsInput>;
  primary_email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_email_title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_email_type?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_lead_histories?: InputMaybe<AssociationHistoryUpdateManyWithoutPrimary_LeadInput>;
  primary_phone_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_phone_number_country_code?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_phone_number_title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_phone_number_type?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  rep?: InputMaybe<UserUpdateOneWithoutOwned_LeadsInput>;
  resting_type?: InputMaybe<NullableEnumRestingtypeFieldUpdateOperationsInput>;
  routing_history?: InputMaybe<RoutingHistoryUpdateManyWithoutLeadInput>;
  routing_rule?: InputMaybe<RuleUpdateOneWithoutLeadInput>;
  Sale?: InputMaybe<SaleUpdateManyWithoutLeadInput>;
  sale_cycles?: InputMaybe<SaleCycleUpdateManyWithoutLeadInput>;
  search_content?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  secondary_reps?: InputMaybe<UserUpdateManyWithoutIdle_Owned_LeadsInput>;
  self_sourced?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  sequence?: InputMaybe<SequenceUpdateOneWithoutLeadsInput>;
  sequence_step?: InputMaybe<SequenceStepUpdateOneWithoutLeadsInput>;
  SequenceActionLog?: InputMaybe<StepActionLogUpdateManyWithoutLeadInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutLeadInput>;
  sequences_completed_ids?: InputMaybe<LeadUpdatesequences_Completed_IdsInput>;
  sequences_joined_ids?: InputMaybe<LeadUpdatesequences_Joined_IdsInput>;
  sf_account_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sf_contact_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sf_lead_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sibling_lead_histories?: InputMaybe<AssociationHistoryUpdateManyWithoutSibling_LeadsInput>;
  sms_sub_status?: InputMaybe<EnumOptInStatusFieldUpdateOperationsInput>;
  stat_items?: InputMaybe<StatItemAggregationUpdateManyWithoutLeadInput>;
  state?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  StatLeadAggregation?: InputMaybe<StatLeadAggregationUpdateOneWithoutLeadInput>;
  status?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sub_industry?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timezone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  zip?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type LeadUpdateWithoutLead_Import_History_CsvInput = {
  address?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  address_2?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  alternate_contacts?: InputMaybe<ContactUpdateManyWithoutLeadInput>;
  assignment_locked?: InputMaybe<BoolFieldUpdateOperationsInput>;
  associate_children?: InputMaybe<LeadUpdateManyWithoutAssociate_Parent_LeadInput>;
  associate_group_lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutAssociate_Group_LeadsInput>;
  associate_lead_histories?: InputMaybe<AssociationHistoryUpdateManyWithoutAssociate_LeadInput>;
  associate_parent_lead?: InputMaybe<LeadUpdateOneWithoutAssociate_ChildrenInput>;
  associated_parent_intents?: InputMaybe<LeadIntentUpdateManyWithoutAssociate_ParentInput>;
  associates_search_content?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  business_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptUpdateManyWithoutLeadInput>;
  callMeNows?: InputMaybe<CallMeNowUpdateManyWithoutLeadInput>;
  channel?: InputMaybe<NullableEnumChannelFieldUpdateOperationsInput>;
  child_lead_activites?: InputMaybe<LeadActivityUpdateManyWithoutAssociate_ChildInput>;
  city?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  claim_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  conference_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  contact_requests?: InputMaybe<ContactRequestUpdateManyWithoutLeadInput>;
  content?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  current_close_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  current_contact_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  current_sale_cycle?: InputMaybe<SaleCycleUpdateOneWithoutLead_If_CurrentInput>;
  custom_field_json?: InputMaybe<Scalars['Json']['input']>;
  custom_fields?: InputMaybe<CustomFieldUpdateManyWithoutLeadInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemUpdateManyWithoutLeadInput>;
  delay_action_status?: InputMaybe<BoolFieldUpdateOperationsInput>;
  email_sub_status?: InputMaybe<EnumOptInStatusFieldUpdateOperationsInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordUpdateManyWithoutLeadInput>;
  EmailThread?: InputMaybe<EmailThreadUpdateManyWithoutLeadInput>;
  favorited_by_users?: InputMaybe<UserUpdateManyWithoutFavorite_LeadsInput>;
  first_demo_schedule_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  first_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  hubspot_company_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  hubspot_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  implied_next_action?: InputMaybe<EnumHingepointFieldUpdateOperationsInput>;
  in_algo_queue?: InputMaybe<BoolFieldUpdateOperationsInput>;
  in_custom_sequence?: InputMaybe<IntFieldUpdateOperationsInput>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventUpdateManyWithoutLeadInput>;
  industry?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogUpdateManyWithoutLeadInput>;
  last_call_result?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  last_call_result_rep?: InputMaybe<UserUpdateOneWithoutLeads_Last_CalledInput>;
  last_call_result_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  last_lead_assignment_origin?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  last_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  last_sent_to_resting_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutLeadInput>;
  lead_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutLeadInput>;
  lead_assignment_origin?: InputMaybe<EnumAssignmentOriginFieldUpdateOperationsInput>;
  lead_creation_source?: InputMaybe<NullableEnumLeadcreationsourceFieldUpdateOperationsInput>;
  lead_custom_row_info?: InputMaybe<LeadCustomObjectRowAssociationUpdateManyWithoutLeadInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryUpdateOneWithoutLeadInput>;
  lead_intent?: InputMaybe<LeadIntentUpdateManyWithoutLeadInput>;
  lead_ownership_status?: InputMaybe<EnumLosFieldUpdateOperationsInput>;
  lead_source?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  lead_value?: InputMaybe<LeadValueUpdateOneWithoutLeadInput>;
  mrr?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  newIncoming?: InputMaybe<BoolFieldUpdateOperationsInput>;
  next_scheduled_event?: InputMaybe<ScheduleItemUpdateOneWithoutLeadInput>;
  notifications?: InputMaybe<NotificationUpdateManyWithoutLeadInput>;
  opsiq_whitelist?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutLeadsInput>;
  phase?: InputMaybe<EnumPhaseFieldUpdateOperationsInput>;
  primary_email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_email_title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_email_type?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_lead_histories?: InputMaybe<AssociationHistoryUpdateManyWithoutPrimary_LeadInput>;
  primary_phone_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_phone_number_country_code?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_phone_number_title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_phone_number_type?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  rep?: InputMaybe<UserUpdateOneWithoutOwned_LeadsInput>;
  resting_type?: InputMaybe<NullableEnumRestingtypeFieldUpdateOperationsInput>;
  routing_history?: InputMaybe<RoutingHistoryUpdateManyWithoutLeadInput>;
  routing_rule?: InputMaybe<RuleUpdateOneWithoutLeadInput>;
  Sale?: InputMaybe<SaleUpdateManyWithoutLeadInput>;
  sale_cycles?: InputMaybe<SaleCycleUpdateManyWithoutLeadInput>;
  search_content?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  secondary_reps?: InputMaybe<UserUpdateManyWithoutIdle_Owned_LeadsInput>;
  self_sourced?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  sequence?: InputMaybe<SequenceUpdateOneWithoutLeadsInput>;
  sequence_step?: InputMaybe<SequenceStepUpdateOneWithoutLeadsInput>;
  SequenceActionLog?: InputMaybe<StepActionLogUpdateManyWithoutLeadInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutLeadInput>;
  sequences_completed_ids?: InputMaybe<LeadUpdatesequences_Completed_IdsInput>;
  sequences_joined_ids?: InputMaybe<LeadUpdatesequences_Joined_IdsInput>;
  sf_account_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sf_contact_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sf_lead_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sibling_lead_histories?: InputMaybe<AssociationHistoryUpdateManyWithoutSibling_LeadsInput>;
  sms_sub_status?: InputMaybe<EnumOptInStatusFieldUpdateOperationsInput>;
  stat_items?: InputMaybe<StatItemAggregationUpdateManyWithoutLeadInput>;
  state?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  StatLeadAggregation?: InputMaybe<StatLeadAggregationUpdateOneWithoutLeadInput>;
  status?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sub_industry?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timezone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  zip?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type LeadUpdateWithoutLead_Import_HistoryInput = {
  address?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  address_2?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  alternate_contacts?: InputMaybe<ContactUpdateManyWithoutLeadInput>;
  assignment_locked?: InputMaybe<BoolFieldUpdateOperationsInput>;
  associate_children?: InputMaybe<LeadUpdateManyWithoutAssociate_Parent_LeadInput>;
  associate_group_lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutAssociate_Group_LeadsInput>;
  associate_lead_histories?: InputMaybe<AssociationHistoryUpdateManyWithoutAssociate_LeadInput>;
  associate_parent_lead?: InputMaybe<LeadUpdateOneWithoutAssociate_ChildrenInput>;
  associated_parent_intents?: InputMaybe<LeadIntentUpdateManyWithoutAssociate_ParentInput>;
  associates_search_content?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  business_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptUpdateManyWithoutLeadInput>;
  callMeNows?: InputMaybe<CallMeNowUpdateManyWithoutLeadInput>;
  channel?: InputMaybe<NullableEnumChannelFieldUpdateOperationsInput>;
  child_lead_activites?: InputMaybe<LeadActivityUpdateManyWithoutAssociate_ChildInput>;
  city?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  claim_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  conference_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  contact_requests?: InputMaybe<ContactRequestUpdateManyWithoutLeadInput>;
  content?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  current_close_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  current_contact_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  current_sale_cycle?: InputMaybe<SaleCycleUpdateOneWithoutLead_If_CurrentInput>;
  custom_field_json?: InputMaybe<Scalars['Json']['input']>;
  custom_fields?: InputMaybe<CustomFieldUpdateManyWithoutLeadInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemUpdateManyWithoutLeadInput>;
  delay_action_status?: InputMaybe<BoolFieldUpdateOperationsInput>;
  email_sub_status?: InputMaybe<EnumOptInStatusFieldUpdateOperationsInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordUpdateManyWithoutLeadInput>;
  EmailThread?: InputMaybe<EmailThreadUpdateManyWithoutLeadInput>;
  favorited_by_users?: InputMaybe<UserUpdateManyWithoutFavorite_LeadsInput>;
  first_demo_schedule_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  first_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  hubspot_company_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  hubspot_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  implied_next_action?: InputMaybe<EnumHingepointFieldUpdateOperationsInput>;
  in_algo_queue?: InputMaybe<BoolFieldUpdateOperationsInput>;
  in_custom_sequence?: InputMaybe<IntFieldUpdateOperationsInput>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventUpdateManyWithoutLeadInput>;
  industry?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogUpdateManyWithoutLeadInput>;
  last_call_result?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  last_call_result_rep?: InputMaybe<UserUpdateOneWithoutLeads_Last_CalledInput>;
  last_call_result_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  last_lead_assignment_origin?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  last_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  last_sent_to_resting_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutLeadInput>;
  lead_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutLeadInput>;
  lead_assignment_origin?: InputMaybe<EnumAssignmentOriginFieldUpdateOperationsInput>;
  lead_creation_source?: InputMaybe<NullableEnumLeadcreationsourceFieldUpdateOperationsInput>;
  lead_custom_row_info?: InputMaybe<LeadCustomObjectRowAssociationUpdateManyWithoutLeadInput>;
  lead_import_history_csv?: InputMaybe<LeadImportHistoryUpdateOneWithoutUploaded_LeadsInput>;
  lead_intent?: InputMaybe<LeadIntentUpdateManyWithoutLeadInput>;
  lead_ownership_status?: InputMaybe<EnumLosFieldUpdateOperationsInput>;
  lead_source?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  lead_value?: InputMaybe<LeadValueUpdateOneWithoutLeadInput>;
  mrr?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  newIncoming?: InputMaybe<BoolFieldUpdateOperationsInput>;
  next_scheduled_event?: InputMaybe<ScheduleItemUpdateOneWithoutLeadInput>;
  notifications?: InputMaybe<NotificationUpdateManyWithoutLeadInput>;
  opsiq_whitelist?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutLeadsInput>;
  phase?: InputMaybe<EnumPhaseFieldUpdateOperationsInput>;
  primary_email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_email_title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_email_type?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_lead_histories?: InputMaybe<AssociationHistoryUpdateManyWithoutPrimary_LeadInput>;
  primary_phone_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_phone_number_country_code?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_phone_number_title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_phone_number_type?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  rep?: InputMaybe<UserUpdateOneWithoutOwned_LeadsInput>;
  resting_type?: InputMaybe<NullableEnumRestingtypeFieldUpdateOperationsInput>;
  routing_history?: InputMaybe<RoutingHistoryUpdateManyWithoutLeadInput>;
  routing_rule?: InputMaybe<RuleUpdateOneWithoutLeadInput>;
  Sale?: InputMaybe<SaleUpdateManyWithoutLeadInput>;
  sale_cycles?: InputMaybe<SaleCycleUpdateManyWithoutLeadInput>;
  search_content?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  secondary_reps?: InputMaybe<UserUpdateManyWithoutIdle_Owned_LeadsInput>;
  self_sourced?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  sequence?: InputMaybe<SequenceUpdateOneWithoutLeadsInput>;
  sequence_step?: InputMaybe<SequenceStepUpdateOneWithoutLeadsInput>;
  SequenceActionLog?: InputMaybe<StepActionLogUpdateManyWithoutLeadInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutLeadInput>;
  sequences_completed_ids?: InputMaybe<LeadUpdatesequences_Completed_IdsInput>;
  sequences_joined_ids?: InputMaybe<LeadUpdatesequences_Joined_IdsInput>;
  sf_account_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sf_contact_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sf_lead_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sibling_lead_histories?: InputMaybe<AssociationHistoryUpdateManyWithoutSibling_LeadsInput>;
  sms_sub_status?: InputMaybe<EnumOptInStatusFieldUpdateOperationsInput>;
  stat_items?: InputMaybe<StatItemAggregationUpdateManyWithoutLeadInput>;
  state?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  StatLeadAggregation?: InputMaybe<StatLeadAggregationUpdateOneWithoutLeadInput>;
  status?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sub_industry?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timezone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  zip?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type LeadUpdateWithoutLead_IntentInput = {
  address?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  address_2?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  alternate_contacts?: InputMaybe<ContactUpdateManyWithoutLeadInput>;
  assignment_locked?: InputMaybe<BoolFieldUpdateOperationsInput>;
  associate_children?: InputMaybe<LeadUpdateManyWithoutAssociate_Parent_LeadInput>;
  associate_group_lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutAssociate_Group_LeadsInput>;
  associate_lead_histories?: InputMaybe<AssociationHistoryUpdateManyWithoutAssociate_LeadInput>;
  associate_parent_lead?: InputMaybe<LeadUpdateOneWithoutAssociate_ChildrenInput>;
  associated_parent_intents?: InputMaybe<LeadIntentUpdateManyWithoutAssociate_ParentInput>;
  associates_search_content?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  business_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptUpdateManyWithoutLeadInput>;
  callMeNows?: InputMaybe<CallMeNowUpdateManyWithoutLeadInput>;
  channel?: InputMaybe<NullableEnumChannelFieldUpdateOperationsInput>;
  child_lead_activites?: InputMaybe<LeadActivityUpdateManyWithoutAssociate_ChildInput>;
  city?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  claim_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  conference_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  contact_requests?: InputMaybe<ContactRequestUpdateManyWithoutLeadInput>;
  content?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  current_close_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  current_contact_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  current_sale_cycle?: InputMaybe<SaleCycleUpdateOneWithoutLead_If_CurrentInput>;
  custom_field_json?: InputMaybe<Scalars['Json']['input']>;
  custom_fields?: InputMaybe<CustomFieldUpdateManyWithoutLeadInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemUpdateManyWithoutLeadInput>;
  delay_action_status?: InputMaybe<BoolFieldUpdateOperationsInput>;
  email_sub_status?: InputMaybe<EnumOptInStatusFieldUpdateOperationsInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordUpdateManyWithoutLeadInput>;
  EmailThread?: InputMaybe<EmailThreadUpdateManyWithoutLeadInput>;
  favorited_by_users?: InputMaybe<UserUpdateManyWithoutFavorite_LeadsInput>;
  first_demo_schedule_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  first_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  hubspot_company_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  hubspot_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  implied_next_action?: InputMaybe<EnumHingepointFieldUpdateOperationsInput>;
  in_algo_queue?: InputMaybe<BoolFieldUpdateOperationsInput>;
  in_custom_sequence?: InputMaybe<IntFieldUpdateOperationsInput>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventUpdateManyWithoutLeadInput>;
  industry?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogUpdateManyWithoutLeadInput>;
  last_call_result?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  last_call_result_rep?: InputMaybe<UserUpdateOneWithoutLeads_Last_CalledInput>;
  last_call_result_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  last_lead_assignment_origin?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  last_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  last_sent_to_resting_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutLeadInput>;
  lead_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutLeadInput>;
  lead_assignment_origin?: InputMaybe<EnumAssignmentOriginFieldUpdateOperationsInput>;
  lead_creation_source?: InputMaybe<NullableEnumLeadcreationsourceFieldUpdateOperationsInput>;
  lead_custom_row_info?: InputMaybe<LeadCustomObjectRowAssociationUpdateManyWithoutLeadInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryUpdateOneWithoutLeadInput>;
  lead_import_history_csv?: InputMaybe<LeadImportHistoryUpdateOneWithoutUploaded_LeadsInput>;
  lead_ownership_status?: InputMaybe<EnumLosFieldUpdateOperationsInput>;
  lead_source?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  lead_value?: InputMaybe<LeadValueUpdateOneWithoutLeadInput>;
  mrr?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  newIncoming?: InputMaybe<BoolFieldUpdateOperationsInput>;
  next_scheduled_event?: InputMaybe<ScheduleItemUpdateOneWithoutLeadInput>;
  notifications?: InputMaybe<NotificationUpdateManyWithoutLeadInput>;
  opsiq_whitelist?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutLeadsInput>;
  phase?: InputMaybe<EnumPhaseFieldUpdateOperationsInput>;
  primary_email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_email_title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_email_type?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_lead_histories?: InputMaybe<AssociationHistoryUpdateManyWithoutPrimary_LeadInput>;
  primary_phone_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_phone_number_country_code?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_phone_number_title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_phone_number_type?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  rep?: InputMaybe<UserUpdateOneWithoutOwned_LeadsInput>;
  resting_type?: InputMaybe<NullableEnumRestingtypeFieldUpdateOperationsInput>;
  routing_history?: InputMaybe<RoutingHistoryUpdateManyWithoutLeadInput>;
  routing_rule?: InputMaybe<RuleUpdateOneWithoutLeadInput>;
  Sale?: InputMaybe<SaleUpdateManyWithoutLeadInput>;
  sale_cycles?: InputMaybe<SaleCycleUpdateManyWithoutLeadInput>;
  search_content?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  secondary_reps?: InputMaybe<UserUpdateManyWithoutIdle_Owned_LeadsInput>;
  self_sourced?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  sequence?: InputMaybe<SequenceUpdateOneWithoutLeadsInput>;
  sequence_step?: InputMaybe<SequenceStepUpdateOneWithoutLeadsInput>;
  SequenceActionLog?: InputMaybe<StepActionLogUpdateManyWithoutLeadInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutLeadInput>;
  sequences_completed_ids?: InputMaybe<LeadUpdatesequences_Completed_IdsInput>;
  sequences_joined_ids?: InputMaybe<LeadUpdatesequences_Joined_IdsInput>;
  sf_account_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sf_contact_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sf_lead_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sibling_lead_histories?: InputMaybe<AssociationHistoryUpdateManyWithoutSibling_LeadsInput>;
  sms_sub_status?: InputMaybe<EnumOptInStatusFieldUpdateOperationsInput>;
  stat_items?: InputMaybe<StatItemAggregationUpdateManyWithoutLeadInput>;
  state?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  StatLeadAggregation?: InputMaybe<StatLeadAggregationUpdateOneWithoutLeadInput>;
  status?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sub_industry?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timezone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  zip?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type LeadUpdateWithoutLead_ValueInput = {
  address?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  address_2?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  alternate_contacts?: InputMaybe<ContactUpdateManyWithoutLeadInput>;
  assignment_locked?: InputMaybe<BoolFieldUpdateOperationsInput>;
  associate_children?: InputMaybe<LeadUpdateManyWithoutAssociate_Parent_LeadInput>;
  associate_group_lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutAssociate_Group_LeadsInput>;
  associate_lead_histories?: InputMaybe<AssociationHistoryUpdateManyWithoutAssociate_LeadInput>;
  associate_parent_lead?: InputMaybe<LeadUpdateOneWithoutAssociate_ChildrenInput>;
  associated_parent_intents?: InputMaybe<LeadIntentUpdateManyWithoutAssociate_ParentInput>;
  associates_search_content?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  business_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptUpdateManyWithoutLeadInput>;
  callMeNows?: InputMaybe<CallMeNowUpdateManyWithoutLeadInput>;
  channel?: InputMaybe<NullableEnumChannelFieldUpdateOperationsInput>;
  child_lead_activites?: InputMaybe<LeadActivityUpdateManyWithoutAssociate_ChildInput>;
  city?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  claim_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  conference_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  contact_requests?: InputMaybe<ContactRequestUpdateManyWithoutLeadInput>;
  content?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  current_close_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  current_contact_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  current_sale_cycle?: InputMaybe<SaleCycleUpdateOneWithoutLead_If_CurrentInput>;
  custom_field_json?: InputMaybe<Scalars['Json']['input']>;
  custom_fields?: InputMaybe<CustomFieldUpdateManyWithoutLeadInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemUpdateManyWithoutLeadInput>;
  delay_action_status?: InputMaybe<BoolFieldUpdateOperationsInput>;
  email_sub_status?: InputMaybe<EnumOptInStatusFieldUpdateOperationsInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordUpdateManyWithoutLeadInput>;
  EmailThread?: InputMaybe<EmailThreadUpdateManyWithoutLeadInput>;
  favorited_by_users?: InputMaybe<UserUpdateManyWithoutFavorite_LeadsInput>;
  first_demo_schedule_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  first_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  hubspot_company_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  hubspot_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  implied_next_action?: InputMaybe<EnumHingepointFieldUpdateOperationsInput>;
  in_algo_queue?: InputMaybe<BoolFieldUpdateOperationsInput>;
  in_custom_sequence?: InputMaybe<IntFieldUpdateOperationsInput>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventUpdateManyWithoutLeadInput>;
  industry?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogUpdateManyWithoutLeadInput>;
  last_call_result?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  last_call_result_rep?: InputMaybe<UserUpdateOneWithoutLeads_Last_CalledInput>;
  last_call_result_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  last_lead_assignment_origin?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  last_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  last_sent_to_resting_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutLeadInput>;
  lead_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutLeadInput>;
  lead_assignment_origin?: InputMaybe<EnumAssignmentOriginFieldUpdateOperationsInput>;
  lead_creation_source?: InputMaybe<NullableEnumLeadcreationsourceFieldUpdateOperationsInput>;
  lead_custom_row_info?: InputMaybe<LeadCustomObjectRowAssociationUpdateManyWithoutLeadInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryUpdateOneWithoutLeadInput>;
  lead_import_history_csv?: InputMaybe<LeadImportHistoryUpdateOneWithoutUploaded_LeadsInput>;
  lead_intent?: InputMaybe<LeadIntentUpdateManyWithoutLeadInput>;
  lead_ownership_status?: InputMaybe<EnumLosFieldUpdateOperationsInput>;
  lead_source?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  mrr?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  newIncoming?: InputMaybe<BoolFieldUpdateOperationsInput>;
  next_scheduled_event?: InputMaybe<ScheduleItemUpdateOneWithoutLeadInput>;
  notifications?: InputMaybe<NotificationUpdateManyWithoutLeadInput>;
  opsiq_whitelist?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutLeadsInput>;
  phase?: InputMaybe<EnumPhaseFieldUpdateOperationsInput>;
  primary_email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_email_title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_email_type?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_lead_histories?: InputMaybe<AssociationHistoryUpdateManyWithoutPrimary_LeadInput>;
  primary_phone_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_phone_number_country_code?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_phone_number_title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_phone_number_type?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  rep?: InputMaybe<UserUpdateOneWithoutOwned_LeadsInput>;
  resting_type?: InputMaybe<NullableEnumRestingtypeFieldUpdateOperationsInput>;
  routing_history?: InputMaybe<RoutingHistoryUpdateManyWithoutLeadInput>;
  routing_rule?: InputMaybe<RuleUpdateOneWithoutLeadInput>;
  Sale?: InputMaybe<SaleUpdateManyWithoutLeadInput>;
  sale_cycles?: InputMaybe<SaleCycleUpdateManyWithoutLeadInput>;
  search_content?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  secondary_reps?: InputMaybe<UserUpdateManyWithoutIdle_Owned_LeadsInput>;
  self_sourced?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  sequence?: InputMaybe<SequenceUpdateOneWithoutLeadsInput>;
  sequence_step?: InputMaybe<SequenceStepUpdateOneWithoutLeadsInput>;
  SequenceActionLog?: InputMaybe<StepActionLogUpdateManyWithoutLeadInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutLeadInput>;
  sequences_completed_ids?: InputMaybe<LeadUpdatesequences_Completed_IdsInput>;
  sequences_joined_ids?: InputMaybe<LeadUpdatesequences_Joined_IdsInput>;
  sf_account_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sf_contact_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sf_lead_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sibling_lead_histories?: InputMaybe<AssociationHistoryUpdateManyWithoutSibling_LeadsInput>;
  sms_sub_status?: InputMaybe<EnumOptInStatusFieldUpdateOperationsInput>;
  stat_items?: InputMaybe<StatItemAggregationUpdateManyWithoutLeadInput>;
  state?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  StatLeadAggregation?: InputMaybe<StatLeadAggregationUpdateOneWithoutLeadInput>;
  status?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sub_industry?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timezone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  zip?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type LeadUpdateWithoutNext_Scheduled_EventInput = {
  address?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  address_2?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  alternate_contacts?: InputMaybe<ContactUpdateManyWithoutLeadInput>;
  assignment_locked?: InputMaybe<BoolFieldUpdateOperationsInput>;
  associate_children?: InputMaybe<LeadUpdateManyWithoutAssociate_Parent_LeadInput>;
  associate_group_lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutAssociate_Group_LeadsInput>;
  associate_lead_histories?: InputMaybe<AssociationHistoryUpdateManyWithoutAssociate_LeadInput>;
  associate_parent_lead?: InputMaybe<LeadUpdateOneWithoutAssociate_ChildrenInput>;
  associated_parent_intents?: InputMaybe<LeadIntentUpdateManyWithoutAssociate_ParentInput>;
  associates_search_content?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  business_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptUpdateManyWithoutLeadInput>;
  callMeNows?: InputMaybe<CallMeNowUpdateManyWithoutLeadInput>;
  channel?: InputMaybe<NullableEnumChannelFieldUpdateOperationsInput>;
  child_lead_activites?: InputMaybe<LeadActivityUpdateManyWithoutAssociate_ChildInput>;
  city?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  claim_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  conference_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  contact_requests?: InputMaybe<ContactRequestUpdateManyWithoutLeadInput>;
  content?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  current_close_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  current_contact_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  current_sale_cycle?: InputMaybe<SaleCycleUpdateOneWithoutLead_If_CurrentInput>;
  custom_field_json?: InputMaybe<Scalars['Json']['input']>;
  custom_fields?: InputMaybe<CustomFieldUpdateManyWithoutLeadInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemUpdateManyWithoutLeadInput>;
  delay_action_status?: InputMaybe<BoolFieldUpdateOperationsInput>;
  email_sub_status?: InputMaybe<EnumOptInStatusFieldUpdateOperationsInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordUpdateManyWithoutLeadInput>;
  EmailThread?: InputMaybe<EmailThreadUpdateManyWithoutLeadInput>;
  favorited_by_users?: InputMaybe<UserUpdateManyWithoutFavorite_LeadsInput>;
  first_demo_schedule_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  first_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  hubspot_company_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  hubspot_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  implied_next_action?: InputMaybe<EnumHingepointFieldUpdateOperationsInput>;
  in_algo_queue?: InputMaybe<BoolFieldUpdateOperationsInput>;
  in_custom_sequence?: InputMaybe<IntFieldUpdateOperationsInput>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventUpdateManyWithoutLeadInput>;
  industry?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogUpdateManyWithoutLeadInput>;
  last_call_result?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  last_call_result_rep?: InputMaybe<UserUpdateOneWithoutLeads_Last_CalledInput>;
  last_call_result_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  last_lead_assignment_origin?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  last_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  last_sent_to_resting_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutLeadInput>;
  lead_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutLeadInput>;
  lead_assignment_origin?: InputMaybe<EnumAssignmentOriginFieldUpdateOperationsInput>;
  lead_creation_source?: InputMaybe<NullableEnumLeadcreationsourceFieldUpdateOperationsInput>;
  lead_custom_row_info?: InputMaybe<LeadCustomObjectRowAssociationUpdateManyWithoutLeadInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryUpdateOneWithoutLeadInput>;
  lead_import_history_csv?: InputMaybe<LeadImportHistoryUpdateOneWithoutUploaded_LeadsInput>;
  lead_intent?: InputMaybe<LeadIntentUpdateManyWithoutLeadInput>;
  lead_ownership_status?: InputMaybe<EnumLosFieldUpdateOperationsInput>;
  lead_source?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  lead_value?: InputMaybe<LeadValueUpdateOneWithoutLeadInput>;
  mrr?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  newIncoming?: InputMaybe<BoolFieldUpdateOperationsInput>;
  notifications?: InputMaybe<NotificationUpdateManyWithoutLeadInput>;
  opsiq_whitelist?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutLeadsInput>;
  phase?: InputMaybe<EnumPhaseFieldUpdateOperationsInput>;
  primary_email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_email_title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_email_type?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_lead_histories?: InputMaybe<AssociationHistoryUpdateManyWithoutPrimary_LeadInput>;
  primary_phone_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_phone_number_country_code?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_phone_number_title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_phone_number_type?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  rep?: InputMaybe<UserUpdateOneWithoutOwned_LeadsInput>;
  resting_type?: InputMaybe<NullableEnumRestingtypeFieldUpdateOperationsInput>;
  routing_history?: InputMaybe<RoutingHistoryUpdateManyWithoutLeadInput>;
  routing_rule?: InputMaybe<RuleUpdateOneWithoutLeadInput>;
  Sale?: InputMaybe<SaleUpdateManyWithoutLeadInput>;
  sale_cycles?: InputMaybe<SaleCycleUpdateManyWithoutLeadInput>;
  search_content?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  secondary_reps?: InputMaybe<UserUpdateManyWithoutIdle_Owned_LeadsInput>;
  self_sourced?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  sequence?: InputMaybe<SequenceUpdateOneWithoutLeadsInput>;
  sequence_step?: InputMaybe<SequenceStepUpdateOneWithoutLeadsInput>;
  SequenceActionLog?: InputMaybe<StepActionLogUpdateManyWithoutLeadInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutLeadInput>;
  sequences_completed_ids?: InputMaybe<LeadUpdatesequences_Completed_IdsInput>;
  sequences_joined_ids?: InputMaybe<LeadUpdatesequences_Joined_IdsInput>;
  sf_account_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sf_contact_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sf_lead_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sibling_lead_histories?: InputMaybe<AssociationHistoryUpdateManyWithoutSibling_LeadsInput>;
  sms_sub_status?: InputMaybe<EnumOptInStatusFieldUpdateOperationsInput>;
  stat_items?: InputMaybe<StatItemAggregationUpdateManyWithoutLeadInput>;
  state?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  StatLeadAggregation?: InputMaybe<StatLeadAggregationUpdateOneWithoutLeadInput>;
  status?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sub_industry?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timezone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  zip?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type LeadUpdateWithoutNotificationsInput = {
  address?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  address_2?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  alternate_contacts?: InputMaybe<ContactUpdateManyWithoutLeadInput>;
  assignment_locked?: InputMaybe<BoolFieldUpdateOperationsInput>;
  associate_children?: InputMaybe<LeadUpdateManyWithoutAssociate_Parent_LeadInput>;
  associate_group_lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutAssociate_Group_LeadsInput>;
  associate_lead_histories?: InputMaybe<AssociationHistoryUpdateManyWithoutAssociate_LeadInput>;
  associate_parent_lead?: InputMaybe<LeadUpdateOneWithoutAssociate_ChildrenInput>;
  associated_parent_intents?: InputMaybe<LeadIntentUpdateManyWithoutAssociate_ParentInput>;
  associates_search_content?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  business_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptUpdateManyWithoutLeadInput>;
  callMeNows?: InputMaybe<CallMeNowUpdateManyWithoutLeadInput>;
  channel?: InputMaybe<NullableEnumChannelFieldUpdateOperationsInput>;
  child_lead_activites?: InputMaybe<LeadActivityUpdateManyWithoutAssociate_ChildInput>;
  city?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  claim_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  conference_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  contact_requests?: InputMaybe<ContactRequestUpdateManyWithoutLeadInput>;
  content?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  current_close_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  current_contact_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  current_sale_cycle?: InputMaybe<SaleCycleUpdateOneWithoutLead_If_CurrentInput>;
  custom_field_json?: InputMaybe<Scalars['Json']['input']>;
  custom_fields?: InputMaybe<CustomFieldUpdateManyWithoutLeadInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemUpdateManyWithoutLeadInput>;
  delay_action_status?: InputMaybe<BoolFieldUpdateOperationsInput>;
  email_sub_status?: InputMaybe<EnumOptInStatusFieldUpdateOperationsInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordUpdateManyWithoutLeadInput>;
  EmailThread?: InputMaybe<EmailThreadUpdateManyWithoutLeadInput>;
  favorited_by_users?: InputMaybe<UserUpdateManyWithoutFavorite_LeadsInput>;
  first_demo_schedule_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  first_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  hubspot_company_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  hubspot_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  implied_next_action?: InputMaybe<EnumHingepointFieldUpdateOperationsInput>;
  in_algo_queue?: InputMaybe<BoolFieldUpdateOperationsInput>;
  in_custom_sequence?: InputMaybe<IntFieldUpdateOperationsInput>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventUpdateManyWithoutLeadInput>;
  industry?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogUpdateManyWithoutLeadInput>;
  last_call_result?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  last_call_result_rep?: InputMaybe<UserUpdateOneWithoutLeads_Last_CalledInput>;
  last_call_result_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  last_lead_assignment_origin?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  last_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  last_sent_to_resting_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutLeadInput>;
  lead_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutLeadInput>;
  lead_assignment_origin?: InputMaybe<EnumAssignmentOriginFieldUpdateOperationsInput>;
  lead_creation_source?: InputMaybe<NullableEnumLeadcreationsourceFieldUpdateOperationsInput>;
  lead_custom_row_info?: InputMaybe<LeadCustomObjectRowAssociationUpdateManyWithoutLeadInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryUpdateOneWithoutLeadInput>;
  lead_import_history_csv?: InputMaybe<LeadImportHistoryUpdateOneWithoutUploaded_LeadsInput>;
  lead_intent?: InputMaybe<LeadIntentUpdateManyWithoutLeadInput>;
  lead_ownership_status?: InputMaybe<EnumLosFieldUpdateOperationsInput>;
  lead_source?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  lead_value?: InputMaybe<LeadValueUpdateOneWithoutLeadInput>;
  mrr?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  newIncoming?: InputMaybe<BoolFieldUpdateOperationsInput>;
  next_scheduled_event?: InputMaybe<ScheduleItemUpdateOneWithoutLeadInput>;
  opsiq_whitelist?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutLeadsInput>;
  phase?: InputMaybe<EnumPhaseFieldUpdateOperationsInput>;
  primary_email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_email_title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_email_type?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_lead_histories?: InputMaybe<AssociationHistoryUpdateManyWithoutPrimary_LeadInput>;
  primary_phone_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_phone_number_country_code?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_phone_number_title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_phone_number_type?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  rep?: InputMaybe<UserUpdateOneWithoutOwned_LeadsInput>;
  resting_type?: InputMaybe<NullableEnumRestingtypeFieldUpdateOperationsInput>;
  routing_history?: InputMaybe<RoutingHistoryUpdateManyWithoutLeadInput>;
  routing_rule?: InputMaybe<RuleUpdateOneWithoutLeadInput>;
  Sale?: InputMaybe<SaleUpdateManyWithoutLeadInput>;
  sale_cycles?: InputMaybe<SaleCycleUpdateManyWithoutLeadInput>;
  search_content?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  secondary_reps?: InputMaybe<UserUpdateManyWithoutIdle_Owned_LeadsInput>;
  self_sourced?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  sequence?: InputMaybe<SequenceUpdateOneWithoutLeadsInput>;
  sequence_step?: InputMaybe<SequenceStepUpdateOneWithoutLeadsInput>;
  SequenceActionLog?: InputMaybe<StepActionLogUpdateManyWithoutLeadInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutLeadInput>;
  sequences_completed_ids?: InputMaybe<LeadUpdatesequences_Completed_IdsInput>;
  sequences_joined_ids?: InputMaybe<LeadUpdatesequences_Joined_IdsInput>;
  sf_account_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sf_contact_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sf_lead_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sibling_lead_histories?: InputMaybe<AssociationHistoryUpdateManyWithoutSibling_LeadsInput>;
  sms_sub_status?: InputMaybe<EnumOptInStatusFieldUpdateOperationsInput>;
  stat_items?: InputMaybe<StatItemAggregationUpdateManyWithoutLeadInput>;
  state?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  StatLeadAggregation?: InputMaybe<StatLeadAggregationUpdateOneWithoutLeadInput>;
  status?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sub_industry?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timezone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  zip?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type LeadUpdateWithoutOrganizationInput = {
  address?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  address_2?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  alternate_contacts?: InputMaybe<ContactUpdateManyWithoutLeadInput>;
  assignment_locked?: InputMaybe<BoolFieldUpdateOperationsInput>;
  associate_children?: InputMaybe<LeadUpdateManyWithoutAssociate_Parent_LeadInput>;
  associate_group_lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutAssociate_Group_LeadsInput>;
  associate_lead_histories?: InputMaybe<AssociationHistoryUpdateManyWithoutAssociate_LeadInput>;
  associate_parent_lead?: InputMaybe<LeadUpdateOneWithoutAssociate_ChildrenInput>;
  associated_parent_intents?: InputMaybe<LeadIntentUpdateManyWithoutAssociate_ParentInput>;
  associates_search_content?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  business_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptUpdateManyWithoutLeadInput>;
  callMeNows?: InputMaybe<CallMeNowUpdateManyWithoutLeadInput>;
  channel?: InputMaybe<NullableEnumChannelFieldUpdateOperationsInput>;
  child_lead_activites?: InputMaybe<LeadActivityUpdateManyWithoutAssociate_ChildInput>;
  city?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  claim_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  conference_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  contact_requests?: InputMaybe<ContactRequestUpdateManyWithoutLeadInput>;
  content?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  current_close_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  current_contact_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  current_sale_cycle?: InputMaybe<SaleCycleUpdateOneWithoutLead_If_CurrentInput>;
  custom_field_json?: InputMaybe<Scalars['Json']['input']>;
  custom_fields?: InputMaybe<CustomFieldUpdateManyWithoutLeadInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemUpdateManyWithoutLeadInput>;
  delay_action_status?: InputMaybe<BoolFieldUpdateOperationsInput>;
  email_sub_status?: InputMaybe<EnumOptInStatusFieldUpdateOperationsInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordUpdateManyWithoutLeadInput>;
  EmailThread?: InputMaybe<EmailThreadUpdateManyWithoutLeadInput>;
  favorited_by_users?: InputMaybe<UserUpdateManyWithoutFavorite_LeadsInput>;
  first_demo_schedule_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  first_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  hubspot_company_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  hubspot_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  implied_next_action?: InputMaybe<EnumHingepointFieldUpdateOperationsInput>;
  in_algo_queue?: InputMaybe<BoolFieldUpdateOperationsInput>;
  in_custom_sequence?: InputMaybe<IntFieldUpdateOperationsInput>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventUpdateManyWithoutLeadInput>;
  industry?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogUpdateManyWithoutLeadInput>;
  last_call_result?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  last_call_result_rep?: InputMaybe<UserUpdateOneWithoutLeads_Last_CalledInput>;
  last_call_result_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  last_lead_assignment_origin?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  last_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  last_sent_to_resting_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutLeadInput>;
  lead_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutLeadInput>;
  lead_assignment_origin?: InputMaybe<EnumAssignmentOriginFieldUpdateOperationsInput>;
  lead_creation_source?: InputMaybe<NullableEnumLeadcreationsourceFieldUpdateOperationsInput>;
  lead_custom_row_info?: InputMaybe<LeadCustomObjectRowAssociationUpdateManyWithoutLeadInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryUpdateOneWithoutLeadInput>;
  lead_import_history_csv?: InputMaybe<LeadImportHistoryUpdateOneWithoutUploaded_LeadsInput>;
  lead_intent?: InputMaybe<LeadIntentUpdateManyWithoutLeadInput>;
  lead_ownership_status?: InputMaybe<EnumLosFieldUpdateOperationsInput>;
  lead_source?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  lead_value?: InputMaybe<LeadValueUpdateOneWithoutLeadInput>;
  mrr?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  newIncoming?: InputMaybe<BoolFieldUpdateOperationsInput>;
  next_scheduled_event?: InputMaybe<ScheduleItemUpdateOneWithoutLeadInput>;
  notifications?: InputMaybe<NotificationUpdateManyWithoutLeadInput>;
  opsiq_whitelist?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  phase?: InputMaybe<EnumPhaseFieldUpdateOperationsInput>;
  primary_email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_email_title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_email_type?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_lead_histories?: InputMaybe<AssociationHistoryUpdateManyWithoutPrimary_LeadInput>;
  primary_phone_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_phone_number_country_code?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_phone_number_title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_phone_number_type?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  rep?: InputMaybe<UserUpdateOneWithoutOwned_LeadsInput>;
  resting_type?: InputMaybe<NullableEnumRestingtypeFieldUpdateOperationsInput>;
  routing_history?: InputMaybe<RoutingHistoryUpdateManyWithoutLeadInput>;
  routing_rule?: InputMaybe<RuleUpdateOneWithoutLeadInput>;
  Sale?: InputMaybe<SaleUpdateManyWithoutLeadInput>;
  sale_cycles?: InputMaybe<SaleCycleUpdateManyWithoutLeadInput>;
  search_content?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  secondary_reps?: InputMaybe<UserUpdateManyWithoutIdle_Owned_LeadsInput>;
  self_sourced?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  sequence?: InputMaybe<SequenceUpdateOneWithoutLeadsInput>;
  sequence_step?: InputMaybe<SequenceStepUpdateOneWithoutLeadsInput>;
  SequenceActionLog?: InputMaybe<StepActionLogUpdateManyWithoutLeadInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutLeadInput>;
  sequences_completed_ids?: InputMaybe<LeadUpdatesequences_Completed_IdsInput>;
  sequences_joined_ids?: InputMaybe<LeadUpdatesequences_Joined_IdsInput>;
  sf_account_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sf_contact_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sf_lead_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sibling_lead_histories?: InputMaybe<AssociationHistoryUpdateManyWithoutSibling_LeadsInput>;
  sms_sub_status?: InputMaybe<EnumOptInStatusFieldUpdateOperationsInput>;
  stat_items?: InputMaybe<StatItemAggregationUpdateManyWithoutLeadInput>;
  state?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  StatLeadAggregation?: InputMaybe<StatLeadAggregationUpdateOneWithoutLeadInput>;
  status?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sub_industry?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timezone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  zip?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type LeadUpdateWithoutPrimary_Lead_HistoriesInput = {
  address?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  address_2?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  alternate_contacts?: InputMaybe<ContactUpdateManyWithoutLeadInput>;
  assignment_locked?: InputMaybe<BoolFieldUpdateOperationsInput>;
  associate_children?: InputMaybe<LeadUpdateManyWithoutAssociate_Parent_LeadInput>;
  associate_group_lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutAssociate_Group_LeadsInput>;
  associate_lead_histories?: InputMaybe<AssociationHistoryUpdateManyWithoutAssociate_LeadInput>;
  associate_parent_lead?: InputMaybe<LeadUpdateOneWithoutAssociate_ChildrenInput>;
  associated_parent_intents?: InputMaybe<LeadIntentUpdateManyWithoutAssociate_ParentInput>;
  associates_search_content?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  business_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptUpdateManyWithoutLeadInput>;
  callMeNows?: InputMaybe<CallMeNowUpdateManyWithoutLeadInput>;
  channel?: InputMaybe<NullableEnumChannelFieldUpdateOperationsInput>;
  child_lead_activites?: InputMaybe<LeadActivityUpdateManyWithoutAssociate_ChildInput>;
  city?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  claim_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  conference_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  contact_requests?: InputMaybe<ContactRequestUpdateManyWithoutLeadInput>;
  content?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  current_close_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  current_contact_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  current_sale_cycle?: InputMaybe<SaleCycleUpdateOneWithoutLead_If_CurrentInput>;
  custom_field_json?: InputMaybe<Scalars['Json']['input']>;
  custom_fields?: InputMaybe<CustomFieldUpdateManyWithoutLeadInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemUpdateManyWithoutLeadInput>;
  delay_action_status?: InputMaybe<BoolFieldUpdateOperationsInput>;
  email_sub_status?: InputMaybe<EnumOptInStatusFieldUpdateOperationsInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordUpdateManyWithoutLeadInput>;
  EmailThread?: InputMaybe<EmailThreadUpdateManyWithoutLeadInput>;
  favorited_by_users?: InputMaybe<UserUpdateManyWithoutFavorite_LeadsInput>;
  first_demo_schedule_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  first_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  hubspot_company_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  hubspot_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  implied_next_action?: InputMaybe<EnumHingepointFieldUpdateOperationsInput>;
  in_algo_queue?: InputMaybe<BoolFieldUpdateOperationsInput>;
  in_custom_sequence?: InputMaybe<IntFieldUpdateOperationsInput>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventUpdateManyWithoutLeadInput>;
  industry?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogUpdateManyWithoutLeadInput>;
  last_call_result?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  last_call_result_rep?: InputMaybe<UserUpdateOneWithoutLeads_Last_CalledInput>;
  last_call_result_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  last_lead_assignment_origin?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  last_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  last_sent_to_resting_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutLeadInput>;
  lead_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutLeadInput>;
  lead_assignment_origin?: InputMaybe<EnumAssignmentOriginFieldUpdateOperationsInput>;
  lead_creation_source?: InputMaybe<NullableEnumLeadcreationsourceFieldUpdateOperationsInput>;
  lead_custom_row_info?: InputMaybe<LeadCustomObjectRowAssociationUpdateManyWithoutLeadInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryUpdateOneWithoutLeadInput>;
  lead_import_history_csv?: InputMaybe<LeadImportHistoryUpdateOneWithoutUploaded_LeadsInput>;
  lead_intent?: InputMaybe<LeadIntentUpdateManyWithoutLeadInput>;
  lead_ownership_status?: InputMaybe<EnumLosFieldUpdateOperationsInput>;
  lead_source?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  lead_value?: InputMaybe<LeadValueUpdateOneWithoutLeadInput>;
  mrr?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  newIncoming?: InputMaybe<BoolFieldUpdateOperationsInput>;
  next_scheduled_event?: InputMaybe<ScheduleItemUpdateOneWithoutLeadInput>;
  notifications?: InputMaybe<NotificationUpdateManyWithoutLeadInput>;
  opsiq_whitelist?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutLeadsInput>;
  phase?: InputMaybe<EnumPhaseFieldUpdateOperationsInput>;
  primary_email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_email_title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_email_type?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_phone_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_phone_number_country_code?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_phone_number_title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_phone_number_type?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  rep?: InputMaybe<UserUpdateOneWithoutOwned_LeadsInput>;
  resting_type?: InputMaybe<NullableEnumRestingtypeFieldUpdateOperationsInput>;
  routing_history?: InputMaybe<RoutingHistoryUpdateManyWithoutLeadInput>;
  routing_rule?: InputMaybe<RuleUpdateOneWithoutLeadInput>;
  Sale?: InputMaybe<SaleUpdateManyWithoutLeadInput>;
  sale_cycles?: InputMaybe<SaleCycleUpdateManyWithoutLeadInput>;
  search_content?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  secondary_reps?: InputMaybe<UserUpdateManyWithoutIdle_Owned_LeadsInput>;
  self_sourced?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  sequence?: InputMaybe<SequenceUpdateOneWithoutLeadsInput>;
  sequence_step?: InputMaybe<SequenceStepUpdateOneWithoutLeadsInput>;
  SequenceActionLog?: InputMaybe<StepActionLogUpdateManyWithoutLeadInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutLeadInput>;
  sequences_completed_ids?: InputMaybe<LeadUpdatesequences_Completed_IdsInput>;
  sequences_joined_ids?: InputMaybe<LeadUpdatesequences_Joined_IdsInput>;
  sf_account_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sf_contact_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sf_lead_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sibling_lead_histories?: InputMaybe<AssociationHistoryUpdateManyWithoutSibling_LeadsInput>;
  sms_sub_status?: InputMaybe<EnumOptInStatusFieldUpdateOperationsInput>;
  stat_items?: InputMaybe<StatItemAggregationUpdateManyWithoutLeadInput>;
  state?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  StatLeadAggregation?: InputMaybe<StatLeadAggregationUpdateOneWithoutLeadInput>;
  status?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sub_industry?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timezone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  zip?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type LeadUpdateWithoutRepInput = {
  address?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  address_2?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  alternate_contacts?: InputMaybe<ContactUpdateManyWithoutLeadInput>;
  assignment_locked?: InputMaybe<BoolFieldUpdateOperationsInput>;
  associate_children?: InputMaybe<LeadUpdateManyWithoutAssociate_Parent_LeadInput>;
  associate_group_lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutAssociate_Group_LeadsInput>;
  associate_lead_histories?: InputMaybe<AssociationHistoryUpdateManyWithoutAssociate_LeadInput>;
  associate_parent_lead?: InputMaybe<LeadUpdateOneWithoutAssociate_ChildrenInput>;
  associated_parent_intents?: InputMaybe<LeadIntentUpdateManyWithoutAssociate_ParentInput>;
  associates_search_content?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  business_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptUpdateManyWithoutLeadInput>;
  callMeNows?: InputMaybe<CallMeNowUpdateManyWithoutLeadInput>;
  channel?: InputMaybe<NullableEnumChannelFieldUpdateOperationsInput>;
  child_lead_activites?: InputMaybe<LeadActivityUpdateManyWithoutAssociate_ChildInput>;
  city?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  claim_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  conference_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  contact_requests?: InputMaybe<ContactRequestUpdateManyWithoutLeadInput>;
  content?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  current_close_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  current_contact_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  current_sale_cycle?: InputMaybe<SaleCycleUpdateOneWithoutLead_If_CurrentInput>;
  custom_field_json?: InputMaybe<Scalars['Json']['input']>;
  custom_fields?: InputMaybe<CustomFieldUpdateManyWithoutLeadInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemUpdateManyWithoutLeadInput>;
  delay_action_status?: InputMaybe<BoolFieldUpdateOperationsInput>;
  email_sub_status?: InputMaybe<EnumOptInStatusFieldUpdateOperationsInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordUpdateManyWithoutLeadInput>;
  EmailThread?: InputMaybe<EmailThreadUpdateManyWithoutLeadInput>;
  favorited_by_users?: InputMaybe<UserUpdateManyWithoutFavorite_LeadsInput>;
  first_demo_schedule_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  first_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  hubspot_company_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  hubspot_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  implied_next_action?: InputMaybe<EnumHingepointFieldUpdateOperationsInput>;
  in_algo_queue?: InputMaybe<BoolFieldUpdateOperationsInput>;
  in_custom_sequence?: InputMaybe<IntFieldUpdateOperationsInput>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventUpdateManyWithoutLeadInput>;
  industry?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogUpdateManyWithoutLeadInput>;
  last_call_result?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  last_call_result_rep?: InputMaybe<UserUpdateOneWithoutLeads_Last_CalledInput>;
  last_call_result_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  last_lead_assignment_origin?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  last_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  last_sent_to_resting_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutLeadInput>;
  lead_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutLeadInput>;
  lead_assignment_origin?: InputMaybe<EnumAssignmentOriginFieldUpdateOperationsInput>;
  lead_creation_source?: InputMaybe<NullableEnumLeadcreationsourceFieldUpdateOperationsInput>;
  lead_custom_row_info?: InputMaybe<LeadCustomObjectRowAssociationUpdateManyWithoutLeadInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryUpdateOneWithoutLeadInput>;
  lead_import_history_csv?: InputMaybe<LeadImportHistoryUpdateOneWithoutUploaded_LeadsInput>;
  lead_intent?: InputMaybe<LeadIntentUpdateManyWithoutLeadInput>;
  lead_ownership_status?: InputMaybe<EnumLosFieldUpdateOperationsInput>;
  lead_source?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  lead_value?: InputMaybe<LeadValueUpdateOneWithoutLeadInput>;
  mrr?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  newIncoming?: InputMaybe<BoolFieldUpdateOperationsInput>;
  next_scheduled_event?: InputMaybe<ScheduleItemUpdateOneWithoutLeadInput>;
  notifications?: InputMaybe<NotificationUpdateManyWithoutLeadInput>;
  opsiq_whitelist?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutLeadsInput>;
  phase?: InputMaybe<EnumPhaseFieldUpdateOperationsInput>;
  primary_email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_email_title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_email_type?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_lead_histories?: InputMaybe<AssociationHistoryUpdateManyWithoutPrimary_LeadInput>;
  primary_phone_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_phone_number_country_code?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_phone_number_title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_phone_number_type?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  resting_type?: InputMaybe<NullableEnumRestingtypeFieldUpdateOperationsInput>;
  routing_history?: InputMaybe<RoutingHistoryUpdateManyWithoutLeadInput>;
  routing_rule?: InputMaybe<RuleUpdateOneWithoutLeadInput>;
  Sale?: InputMaybe<SaleUpdateManyWithoutLeadInput>;
  sale_cycles?: InputMaybe<SaleCycleUpdateManyWithoutLeadInput>;
  search_content?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  secondary_reps?: InputMaybe<UserUpdateManyWithoutIdle_Owned_LeadsInput>;
  self_sourced?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  sequence?: InputMaybe<SequenceUpdateOneWithoutLeadsInput>;
  sequence_step?: InputMaybe<SequenceStepUpdateOneWithoutLeadsInput>;
  SequenceActionLog?: InputMaybe<StepActionLogUpdateManyWithoutLeadInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutLeadInput>;
  sequences_completed_ids?: InputMaybe<LeadUpdatesequences_Completed_IdsInput>;
  sequences_joined_ids?: InputMaybe<LeadUpdatesequences_Joined_IdsInput>;
  sf_account_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sf_contact_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sf_lead_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sibling_lead_histories?: InputMaybe<AssociationHistoryUpdateManyWithoutSibling_LeadsInput>;
  sms_sub_status?: InputMaybe<EnumOptInStatusFieldUpdateOperationsInput>;
  stat_items?: InputMaybe<StatItemAggregationUpdateManyWithoutLeadInput>;
  state?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  StatLeadAggregation?: InputMaybe<StatLeadAggregationUpdateOneWithoutLeadInput>;
  status?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sub_industry?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timezone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  zip?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type LeadUpdateWithoutRouting_HistoryInput = {
  address?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  address_2?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  alternate_contacts?: InputMaybe<ContactUpdateManyWithoutLeadInput>;
  assignment_locked?: InputMaybe<BoolFieldUpdateOperationsInput>;
  associate_children?: InputMaybe<LeadUpdateManyWithoutAssociate_Parent_LeadInput>;
  associate_group_lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutAssociate_Group_LeadsInput>;
  associate_lead_histories?: InputMaybe<AssociationHistoryUpdateManyWithoutAssociate_LeadInput>;
  associate_parent_lead?: InputMaybe<LeadUpdateOneWithoutAssociate_ChildrenInput>;
  associated_parent_intents?: InputMaybe<LeadIntentUpdateManyWithoutAssociate_ParentInput>;
  associates_search_content?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  business_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptUpdateManyWithoutLeadInput>;
  callMeNows?: InputMaybe<CallMeNowUpdateManyWithoutLeadInput>;
  channel?: InputMaybe<NullableEnumChannelFieldUpdateOperationsInput>;
  child_lead_activites?: InputMaybe<LeadActivityUpdateManyWithoutAssociate_ChildInput>;
  city?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  claim_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  conference_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  contact_requests?: InputMaybe<ContactRequestUpdateManyWithoutLeadInput>;
  content?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  current_close_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  current_contact_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  current_sale_cycle?: InputMaybe<SaleCycleUpdateOneWithoutLead_If_CurrentInput>;
  custom_field_json?: InputMaybe<Scalars['Json']['input']>;
  custom_fields?: InputMaybe<CustomFieldUpdateManyWithoutLeadInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemUpdateManyWithoutLeadInput>;
  delay_action_status?: InputMaybe<BoolFieldUpdateOperationsInput>;
  email_sub_status?: InputMaybe<EnumOptInStatusFieldUpdateOperationsInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordUpdateManyWithoutLeadInput>;
  EmailThread?: InputMaybe<EmailThreadUpdateManyWithoutLeadInput>;
  favorited_by_users?: InputMaybe<UserUpdateManyWithoutFavorite_LeadsInput>;
  first_demo_schedule_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  first_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  hubspot_company_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  hubspot_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  implied_next_action?: InputMaybe<EnumHingepointFieldUpdateOperationsInput>;
  in_algo_queue?: InputMaybe<BoolFieldUpdateOperationsInput>;
  in_custom_sequence?: InputMaybe<IntFieldUpdateOperationsInput>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventUpdateManyWithoutLeadInput>;
  industry?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogUpdateManyWithoutLeadInput>;
  last_call_result?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  last_call_result_rep?: InputMaybe<UserUpdateOneWithoutLeads_Last_CalledInput>;
  last_call_result_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  last_lead_assignment_origin?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  last_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  last_sent_to_resting_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutLeadInput>;
  lead_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutLeadInput>;
  lead_assignment_origin?: InputMaybe<EnumAssignmentOriginFieldUpdateOperationsInput>;
  lead_creation_source?: InputMaybe<NullableEnumLeadcreationsourceFieldUpdateOperationsInput>;
  lead_custom_row_info?: InputMaybe<LeadCustomObjectRowAssociationUpdateManyWithoutLeadInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryUpdateOneWithoutLeadInput>;
  lead_import_history_csv?: InputMaybe<LeadImportHistoryUpdateOneWithoutUploaded_LeadsInput>;
  lead_intent?: InputMaybe<LeadIntentUpdateManyWithoutLeadInput>;
  lead_ownership_status?: InputMaybe<EnumLosFieldUpdateOperationsInput>;
  lead_source?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  lead_value?: InputMaybe<LeadValueUpdateOneWithoutLeadInput>;
  mrr?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  newIncoming?: InputMaybe<BoolFieldUpdateOperationsInput>;
  next_scheduled_event?: InputMaybe<ScheduleItemUpdateOneWithoutLeadInput>;
  notifications?: InputMaybe<NotificationUpdateManyWithoutLeadInput>;
  opsiq_whitelist?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutLeadsInput>;
  phase?: InputMaybe<EnumPhaseFieldUpdateOperationsInput>;
  primary_email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_email_title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_email_type?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_lead_histories?: InputMaybe<AssociationHistoryUpdateManyWithoutPrimary_LeadInput>;
  primary_phone_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_phone_number_country_code?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_phone_number_title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_phone_number_type?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  rep?: InputMaybe<UserUpdateOneWithoutOwned_LeadsInput>;
  resting_type?: InputMaybe<NullableEnumRestingtypeFieldUpdateOperationsInput>;
  routing_rule?: InputMaybe<RuleUpdateOneWithoutLeadInput>;
  Sale?: InputMaybe<SaleUpdateManyWithoutLeadInput>;
  sale_cycles?: InputMaybe<SaleCycleUpdateManyWithoutLeadInput>;
  search_content?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  secondary_reps?: InputMaybe<UserUpdateManyWithoutIdle_Owned_LeadsInput>;
  self_sourced?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  sequence?: InputMaybe<SequenceUpdateOneWithoutLeadsInput>;
  sequence_step?: InputMaybe<SequenceStepUpdateOneWithoutLeadsInput>;
  SequenceActionLog?: InputMaybe<StepActionLogUpdateManyWithoutLeadInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutLeadInput>;
  sequences_completed_ids?: InputMaybe<LeadUpdatesequences_Completed_IdsInput>;
  sequences_joined_ids?: InputMaybe<LeadUpdatesequences_Joined_IdsInput>;
  sf_account_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sf_contact_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sf_lead_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sibling_lead_histories?: InputMaybe<AssociationHistoryUpdateManyWithoutSibling_LeadsInput>;
  sms_sub_status?: InputMaybe<EnumOptInStatusFieldUpdateOperationsInput>;
  stat_items?: InputMaybe<StatItemAggregationUpdateManyWithoutLeadInput>;
  state?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  StatLeadAggregation?: InputMaybe<StatLeadAggregationUpdateOneWithoutLeadInput>;
  status?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sub_industry?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timezone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  zip?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type LeadUpdateWithoutRouting_RuleInput = {
  address?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  address_2?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  alternate_contacts?: InputMaybe<ContactUpdateManyWithoutLeadInput>;
  assignment_locked?: InputMaybe<BoolFieldUpdateOperationsInput>;
  associate_children?: InputMaybe<LeadUpdateManyWithoutAssociate_Parent_LeadInput>;
  associate_group_lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutAssociate_Group_LeadsInput>;
  associate_lead_histories?: InputMaybe<AssociationHistoryUpdateManyWithoutAssociate_LeadInput>;
  associate_parent_lead?: InputMaybe<LeadUpdateOneWithoutAssociate_ChildrenInput>;
  associated_parent_intents?: InputMaybe<LeadIntentUpdateManyWithoutAssociate_ParentInput>;
  associates_search_content?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  business_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptUpdateManyWithoutLeadInput>;
  callMeNows?: InputMaybe<CallMeNowUpdateManyWithoutLeadInput>;
  channel?: InputMaybe<NullableEnumChannelFieldUpdateOperationsInput>;
  child_lead_activites?: InputMaybe<LeadActivityUpdateManyWithoutAssociate_ChildInput>;
  city?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  claim_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  conference_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  contact_requests?: InputMaybe<ContactRequestUpdateManyWithoutLeadInput>;
  content?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  current_close_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  current_contact_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  current_sale_cycle?: InputMaybe<SaleCycleUpdateOneWithoutLead_If_CurrentInput>;
  custom_field_json?: InputMaybe<Scalars['Json']['input']>;
  custom_fields?: InputMaybe<CustomFieldUpdateManyWithoutLeadInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemUpdateManyWithoutLeadInput>;
  delay_action_status?: InputMaybe<BoolFieldUpdateOperationsInput>;
  email_sub_status?: InputMaybe<EnumOptInStatusFieldUpdateOperationsInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordUpdateManyWithoutLeadInput>;
  EmailThread?: InputMaybe<EmailThreadUpdateManyWithoutLeadInput>;
  favorited_by_users?: InputMaybe<UserUpdateManyWithoutFavorite_LeadsInput>;
  first_demo_schedule_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  first_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  hubspot_company_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  hubspot_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  implied_next_action?: InputMaybe<EnumHingepointFieldUpdateOperationsInput>;
  in_algo_queue?: InputMaybe<BoolFieldUpdateOperationsInput>;
  in_custom_sequence?: InputMaybe<IntFieldUpdateOperationsInput>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventUpdateManyWithoutLeadInput>;
  industry?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogUpdateManyWithoutLeadInput>;
  last_call_result?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  last_call_result_rep?: InputMaybe<UserUpdateOneWithoutLeads_Last_CalledInput>;
  last_call_result_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  last_lead_assignment_origin?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  last_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  last_sent_to_resting_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutLeadInput>;
  lead_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutLeadInput>;
  lead_assignment_origin?: InputMaybe<EnumAssignmentOriginFieldUpdateOperationsInput>;
  lead_creation_source?: InputMaybe<NullableEnumLeadcreationsourceFieldUpdateOperationsInput>;
  lead_custom_row_info?: InputMaybe<LeadCustomObjectRowAssociationUpdateManyWithoutLeadInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryUpdateOneWithoutLeadInput>;
  lead_import_history_csv?: InputMaybe<LeadImportHistoryUpdateOneWithoutUploaded_LeadsInput>;
  lead_intent?: InputMaybe<LeadIntentUpdateManyWithoutLeadInput>;
  lead_ownership_status?: InputMaybe<EnumLosFieldUpdateOperationsInput>;
  lead_source?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  lead_value?: InputMaybe<LeadValueUpdateOneWithoutLeadInput>;
  mrr?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  newIncoming?: InputMaybe<BoolFieldUpdateOperationsInput>;
  next_scheduled_event?: InputMaybe<ScheduleItemUpdateOneWithoutLeadInput>;
  notifications?: InputMaybe<NotificationUpdateManyWithoutLeadInput>;
  opsiq_whitelist?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutLeadsInput>;
  phase?: InputMaybe<EnumPhaseFieldUpdateOperationsInput>;
  primary_email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_email_title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_email_type?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_lead_histories?: InputMaybe<AssociationHistoryUpdateManyWithoutPrimary_LeadInput>;
  primary_phone_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_phone_number_country_code?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_phone_number_title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_phone_number_type?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  rep?: InputMaybe<UserUpdateOneWithoutOwned_LeadsInput>;
  resting_type?: InputMaybe<NullableEnumRestingtypeFieldUpdateOperationsInput>;
  routing_history?: InputMaybe<RoutingHistoryUpdateManyWithoutLeadInput>;
  Sale?: InputMaybe<SaleUpdateManyWithoutLeadInput>;
  sale_cycles?: InputMaybe<SaleCycleUpdateManyWithoutLeadInput>;
  search_content?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  secondary_reps?: InputMaybe<UserUpdateManyWithoutIdle_Owned_LeadsInput>;
  self_sourced?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  sequence?: InputMaybe<SequenceUpdateOneWithoutLeadsInput>;
  sequence_step?: InputMaybe<SequenceStepUpdateOneWithoutLeadsInput>;
  SequenceActionLog?: InputMaybe<StepActionLogUpdateManyWithoutLeadInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutLeadInput>;
  sequences_completed_ids?: InputMaybe<LeadUpdatesequences_Completed_IdsInput>;
  sequences_joined_ids?: InputMaybe<LeadUpdatesequences_Joined_IdsInput>;
  sf_account_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sf_contact_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sf_lead_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sibling_lead_histories?: InputMaybe<AssociationHistoryUpdateManyWithoutSibling_LeadsInput>;
  sms_sub_status?: InputMaybe<EnumOptInStatusFieldUpdateOperationsInput>;
  stat_items?: InputMaybe<StatItemAggregationUpdateManyWithoutLeadInput>;
  state?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  StatLeadAggregation?: InputMaybe<StatLeadAggregationUpdateOneWithoutLeadInput>;
  status?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sub_industry?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timezone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  zip?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type LeadUpdateWithoutSale_CyclesInput = {
  address?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  address_2?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  alternate_contacts?: InputMaybe<ContactUpdateManyWithoutLeadInput>;
  assignment_locked?: InputMaybe<BoolFieldUpdateOperationsInput>;
  associate_children?: InputMaybe<LeadUpdateManyWithoutAssociate_Parent_LeadInput>;
  associate_group_lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutAssociate_Group_LeadsInput>;
  associate_lead_histories?: InputMaybe<AssociationHistoryUpdateManyWithoutAssociate_LeadInput>;
  associate_parent_lead?: InputMaybe<LeadUpdateOneWithoutAssociate_ChildrenInput>;
  associated_parent_intents?: InputMaybe<LeadIntentUpdateManyWithoutAssociate_ParentInput>;
  associates_search_content?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  business_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptUpdateManyWithoutLeadInput>;
  callMeNows?: InputMaybe<CallMeNowUpdateManyWithoutLeadInput>;
  channel?: InputMaybe<NullableEnumChannelFieldUpdateOperationsInput>;
  child_lead_activites?: InputMaybe<LeadActivityUpdateManyWithoutAssociate_ChildInput>;
  city?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  claim_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  conference_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  contact_requests?: InputMaybe<ContactRequestUpdateManyWithoutLeadInput>;
  content?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  current_close_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  current_contact_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  current_sale_cycle?: InputMaybe<SaleCycleUpdateOneWithoutLead_If_CurrentInput>;
  custom_field_json?: InputMaybe<Scalars['Json']['input']>;
  custom_fields?: InputMaybe<CustomFieldUpdateManyWithoutLeadInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemUpdateManyWithoutLeadInput>;
  delay_action_status?: InputMaybe<BoolFieldUpdateOperationsInput>;
  email_sub_status?: InputMaybe<EnumOptInStatusFieldUpdateOperationsInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordUpdateManyWithoutLeadInput>;
  EmailThread?: InputMaybe<EmailThreadUpdateManyWithoutLeadInput>;
  favorited_by_users?: InputMaybe<UserUpdateManyWithoutFavorite_LeadsInput>;
  first_demo_schedule_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  first_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  hubspot_company_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  hubspot_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  implied_next_action?: InputMaybe<EnumHingepointFieldUpdateOperationsInput>;
  in_algo_queue?: InputMaybe<BoolFieldUpdateOperationsInput>;
  in_custom_sequence?: InputMaybe<IntFieldUpdateOperationsInput>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventUpdateManyWithoutLeadInput>;
  industry?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogUpdateManyWithoutLeadInput>;
  last_call_result?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  last_call_result_rep?: InputMaybe<UserUpdateOneWithoutLeads_Last_CalledInput>;
  last_call_result_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  last_lead_assignment_origin?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  last_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  last_sent_to_resting_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutLeadInput>;
  lead_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutLeadInput>;
  lead_assignment_origin?: InputMaybe<EnumAssignmentOriginFieldUpdateOperationsInput>;
  lead_creation_source?: InputMaybe<NullableEnumLeadcreationsourceFieldUpdateOperationsInput>;
  lead_custom_row_info?: InputMaybe<LeadCustomObjectRowAssociationUpdateManyWithoutLeadInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryUpdateOneWithoutLeadInput>;
  lead_import_history_csv?: InputMaybe<LeadImportHistoryUpdateOneWithoutUploaded_LeadsInput>;
  lead_intent?: InputMaybe<LeadIntentUpdateManyWithoutLeadInput>;
  lead_ownership_status?: InputMaybe<EnumLosFieldUpdateOperationsInput>;
  lead_source?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  lead_value?: InputMaybe<LeadValueUpdateOneWithoutLeadInput>;
  mrr?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  newIncoming?: InputMaybe<BoolFieldUpdateOperationsInput>;
  next_scheduled_event?: InputMaybe<ScheduleItemUpdateOneWithoutLeadInput>;
  notifications?: InputMaybe<NotificationUpdateManyWithoutLeadInput>;
  opsiq_whitelist?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutLeadsInput>;
  phase?: InputMaybe<EnumPhaseFieldUpdateOperationsInput>;
  primary_email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_email_title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_email_type?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_lead_histories?: InputMaybe<AssociationHistoryUpdateManyWithoutPrimary_LeadInput>;
  primary_phone_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_phone_number_country_code?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_phone_number_title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_phone_number_type?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  rep?: InputMaybe<UserUpdateOneWithoutOwned_LeadsInput>;
  resting_type?: InputMaybe<NullableEnumRestingtypeFieldUpdateOperationsInput>;
  routing_history?: InputMaybe<RoutingHistoryUpdateManyWithoutLeadInput>;
  routing_rule?: InputMaybe<RuleUpdateOneWithoutLeadInput>;
  Sale?: InputMaybe<SaleUpdateManyWithoutLeadInput>;
  search_content?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  secondary_reps?: InputMaybe<UserUpdateManyWithoutIdle_Owned_LeadsInput>;
  self_sourced?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  sequence?: InputMaybe<SequenceUpdateOneWithoutLeadsInput>;
  sequence_step?: InputMaybe<SequenceStepUpdateOneWithoutLeadsInput>;
  SequenceActionLog?: InputMaybe<StepActionLogUpdateManyWithoutLeadInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutLeadInput>;
  sequences_completed_ids?: InputMaybe<LeadUpdatesequences_Completed_IdsInput>;
  sequences_joined_ids?: InputMaybe<LeadUpdatesequences_Joined_IdsInput>;
  sf_account_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sf_contact_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sf_lead_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sibling_lead_histories?: InputMaybe<AssociationHistoryUpdateManyWithoutSibling_LeadsInput>;
  sms_sub_status?: InputMaybe<EnumOptInStatusFieldUpdateOperationsInput>;
  stat_items?: InputMaybe<StatItemAggregationUpdateManyWithoutLeadInput>;
  state?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  StatLeadAggregation?: InputMaybe<StatLeadAggregationUpdateOneWithoutLeadInput>;
  status?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sub_industry?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timezone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  zip?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type LeadUpdateWithoutSaleInput = {
  address?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  address_2?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  alternate_contacts?: InputMaybe<ContactUpdateManyWithoutLeadInput>;
  assignment_locked?: InputMaybe<BoolFieldUpdateOperationsInput>;
  associate_children?: InputMaybe<LeadUpdateManyWithoutAssociate_Parent_LeadInput>;
  associate_group_lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutAssociate_Group_LeadsInput>;
  associate_lead_histories?: InputMaybe<AssociationHistoryUpdateManyWithoutAssociate_LeadInput>;
  associate_parent_lead?: InputMaybe<LeadUpdateOneWithoutAssociate_ChildrenInput>;
  associated_parent_intents?: InputMaybe<LeadIntentUpdateManyWithoutAssociate_ParentInput>;
  associates_search_content?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  business_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptUpdateManyWithoutLeadInput>;
  callMeNows?: InputMaybe<CallMeNowUpdateManyWithoutLeadInput>;
  channel?: InputMaybe<NullableEnumChannelFieldUpdateOperationsInput>;
  child_lead_activites?: InputMaybe<LeadActivityUpdateManyWithoutAssociate_ChildInput>;
  city?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  claim_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  conference_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  contact_requests?: InputMaybe<ContactRequestUpdateManyWithoutLeadInput>;
  content?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  current_close_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  current_contact_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  current_sale_cycle?: InputMaybe<SaleCycleUpdateOneWithoutLead_If_CurrentInput>;
  custom_field_json?: InputMaybe<Scalars['Json']['input']>;
  custom_fields?: InputMaybe<CustomFieldUpdateManyWithoutLeadInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemUpdateManyWithoutLeadInput>;
  delay_action_status?: InputMaybe<BoolFieldUpdateOperationsInput>;
  email_sub_status?: InputMaybe<EnumOptInStatusFieldUpdateOperationsInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordUpdateManyWithoutLeadInput>;
  EmailThread?: InputMaybe<EmailThreadUpdateManyWithoutLeadInput>;
  favorited_by_users?: InputMaybe<UserUpdateManyWithoutFavorite_LeadsInput>;
  first_demo_schedule_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  first_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  hubspot_company_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  hubspot_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  implied_next_action?: InputMaybe<EnumHingepointFieldUpdateOperationsInput>;
  in_algo_queue?: InputMaybe<BoolFieldUpdateOperationsInput>;
  in_custom_sequence?: InputMaybe<IntFieldUpdateOperationsInput>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventUpdateManyWithoutLeadInput>;
  industry?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogUpdateManyWithoutLeadInput>;
  last_call_result?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  last_call_result_rep?: InputMaybe<UserUpdateOneWithoutLeads_Last_CalledInput>;
  last_call_result_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  last_lead_assignment_origin?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  last_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  last_sent_to_resting_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutLeadInput>;
  lead_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutLeadInput>;
  lead_assignment_origin?: InputMaybe<EnumAssignmentOriginFieldUpdateOperationsInput>;
  lead_creation_source?: InputMaybe<NullableEnumLeadcreationsourceFieldUpdateOperationsInput>;
  lead_custom_row_info?: InputMaybe<LeadCustomObjectRowAssociationUpdateManyWithoutLeadInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryUpdateOneWithoutLeadInput>;
  lead_import_history_csv?: InputMaybe<LeadImportHistoryUpdateOneWithoutUploaded_LeadsInput>;
  lead_intent?: InputMaybe<LeadIntentUpdateManyWithoutLeadInput>;
  lead_ownership_status?: InputMaybe<EnumLosFieldUpdateOperationsInput>;
  lead_source?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  lead_value?: InputMaybe<LeadValueUpdateOneWithoutLeadInput>;
  mrr?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  newIncoming?: InputMaybe<BoolFieldUpdateOperationsInput>;
  next_scheduled_event?: InputMaybe<ScheduleItemUpdateOneWithoutLeadInput>;
  notifications?: InputMaybe<NotificationUpdateManyWithoutLeadInput>;
  opsiq_whitelist?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutLeadsInput>;
  phase?: InputMaybe<EnumPhaseFieldUpdateOperationsInput>;
  primary_email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_email_title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_email_type?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_lead_histories?: InputMaybe<AssociationHistoryUpdateManyWithoutPrimary_LeadInput>;
  primary_phone_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_phone_number_country_code?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_phone_number_title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_phone_number_type?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  rep?: InputMaybe<UserUpdateOneWithoutOwned_LeadsInput>;
  resting_type?: InputMaybe<NullableEnumRestingtypeFieldUpdateOperationsInput>;
  routing_history?: InputMaybe<RoutingHistoryUpdateManyWithoutLeadInput>;
  routing_rule?: InputMaybe<RuleUpdateOneWithoutLeadInput>;
  sale_cycles?: InputMaybe<SaleCycleUpdateManyWithoutLeadInput>;
  search_content?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  secondary_reps?: InputMaybe<UserUpdateManyWithoutIdle_Owned_LeadsInput>;
  self_sourced?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  sequence?: InputMaybe<SequenceUpdateOneWithoutLeadsInput>;
  sequence_step?: InputMaybe<SequenceStepUpdateOneWithoutLeadsInput>;
  SequenceActionLog?: InputMaybe<StepActionLogUpdateManyWithoutLeadInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutLeadInput>;
  sequences_completed_ids?: InputMaybe<LeadUpdatesequences_Completed_IdsInput>;
  sequences_joined_ids?: InputMaybe<LeadUpdatesequences_Joined_IdsInput>;
  sf_account_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sf_contact_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sf_lead_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sibling_lead_histories?: InputMaybe<AssociationHistoryUpdateManyWithoutSibling_LeadsInput>;
  sms_sub_status?: InputMaybe<EnumOptInStatusFieldUpdateOperationsInput>;
  stat_items?: InputMaybe<StatItemAggregationUpdateManyWithoutLeadInput>;
  state?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  StatLeadAggregation?: InputMaybe<StatLeadAggregationUpdateOneWithoutLeadInput>;
  status?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sub_industry?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timezone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  zip?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type LeadUpdateWithoutSecondary_RepsInput = {
  address?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  address_2?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  alternate_contacts?: InputMaybe<ContactUpdateManyWithoutLeadInput>;
  assignment_locked?: InputMaybe<BoolFieldUpdateOperationsInput>;
  associate_children?: InputMaybe<LeadUpdateManyWithoutAssociate_Parent_LeadInput>;
  associate_group_lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutAssociate_Group_LeadsInput>;
  associate_lead_histories?: InputMaybe<AssociationHistoryUpdateManyWithoutAssociate_LeadInput>;
  associate_parent_lead?: InputMaybe<LeadUpdateOneWithoutAssociate_ChildrenInput>;
  associated_parent_intents?: InputMaybe<LeadIntentUpdateManyWithoutAssociate_ParentInput>;
  associates_search_content?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  business_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptUpdateManyWithoutLeadInput>;
  callMeNows?: InputMaybe<CallMeNowUpdateManyWithoutLeadInput>;
  channel?: InputMaybe<NullableEnumChannelFieldUpdateOperationsInput>;
  child_lead_activites?: InputMaybe<LeadActivityUpdateManyWithoutAssociate_ChildInput>;
  city?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  claim_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  conference_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  contact_requests?: InputMaybe<ContactRequestUpdateManyWithoutLeadInput>;
  content?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  current_close_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  current_contact_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  current_sale_cycle?: InputMaybe<SaleCycleUpdateOneWithoutLead_If_CurrentInput>;
  custom_field_json?: InputMaybe<Scalars['Json']['input']>;
  custom_fields?: InputMaybe<CustomFieldUpdateManyWithoutLeadInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemUpdateManyWithoutLeadInput>;
  delay_action_status?: InputMaybe<BoolFieldUpdateOperationsInput>;
  email_sub_status?: InputMaybe<EnumOptInStatusFieldUpdateOperationsInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordUpdateManyWithoutLeadInput>;
  EmailThread?: InputMaybe<EmailThreadUpdateManyWithoutLeadInput>;
  favorited_by_users?: InputMaybe<UserUpdateManyWithoutFavorite_LeadsInput>;
  first_demo_schedule_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  first_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  hubspot_company_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  hubspot_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  implied_next_action?: InputMaybe<EnumHingepointFieldUpdateOperationsInput>;
  in_algo_queue?: InputMaybe<BoolFieldUpdateOperationsInput>;
  in_custom_sequence?: InputMaybe<IntFieldUpdateOperationsInput>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventUpdateManyWithoutLeadInput>;
  industry?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogUpdateManyWithoutLeadInput>;
  last_call_result?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  last_call_result_rep?: InputMaybe<UserUpdateOneWithoutLeads_Last_CalledInput>;
  last_call_result_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  last_lead_assignment_origin?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  last_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  last_sent_to_resting_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutLeadInput>;
  lead_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutLeadInput>;
  lead_assignment_origin?: InputMaybe<EnumAssignmentOriginFieldUpdateOperationsInput>;
  lead_creation_source?: InputMaybe<NullableEnumLeadcreationsourceFieldUpdateOperationsInput>;
  lead_custom_row_info?: InputMaybe<LeadCustomObjectRowAssociationUpdateManyWithoutLeadInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryUpdateOneWithoutLeadInput>;
  lead_import_history_csv?: InputMaybe<LeadImportHistoryUpdateOneWithoutUploaded_LeadsInput>;
  lead_intent?: InputMaybe<LeadIntentUpdateManyWithoutLeadInput>;
  lead_ownership_status?: InputMaybe<EnumLosFieldUpdateOperationsInput>;
  lead_source?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  lead_value?: InputMaybe<LeadValueUpdateOneWithoutLeadInput>;
  mrr?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  newIncoming?: InputMaybe<BoolFieldUpdateOperationsInput>;
  next_scheduled_event?: InputMaybe<ScheduleItemUpdateOneWithoutLeadInput>;
  notifications?: InputMaybe<NotificationUpdateManyWithoutLeadInput>;
  opsiq_whitelist?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutLeadsInput>;
  phase?: InputMaybe<EnumPhaseFieldUpdateOperationsInput>;
  primary_email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_email_title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_email_type?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_lead_histories?: InputMaybe<AssociationHistoryUpdateManyWithoutPrimary_LeadInput>;
  primary_phone_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_phone_number_country_code?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_phone_number_title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_phone_number_type?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  rep?: InputMaybe<UserUpdateOneWithoutOwned_LeadsInput>;
  resting_type?: InputMaybe<NullableEnumRestingtypeFieldUpdateOperationsInput>;
  routing_history?: InputMaybe<RoutingHistoryUpdateManyWithoutLeadInput>;
  routing_rule?: InputMaybe<RuleUpdateOneWithoutLeadInput>;
  Sale?: InputMaybe<SaleUpdateManyWithoutLeadInput>;
  sale_cycles?: InputMaybe<SaleCycleUpdateManyWithoutLeadInput>;
  search_content?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  self_sourced?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  sequence?: InputMaybe<SequenceUpdateOneWithoutLeadsInput>;
  sequence_step?: InputMaybe<SequenceStepUpdateOneWithoutLeadsInput>;
  SequenceActionLog?: InputMaybe<StepActionLogUpdateManyWithoutLeadInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutLeadInput>;
  sequences_completed_ids?: InputMaybe<LeadUpdatesequences_Completed_IdsInput>;
  sequences_joined_ids?: InputMaybe<LeadUpdatesequences_Joined_IdsInput>;
  sf_account_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sf_contact_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sf_lead_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sibling_lead_histories?: InputMaybe<AssociationHistoryUpdateManyWithoutSibling_LeadsInput>;
  sms_sub_status?: InputMaybe<EnumOptInStatusFieldUpdateOperationsInput>;
  stat_items?: InputMaybe<StatItemAggregationUpdateManyWithoutLeadInput>;
  state?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  StatLeadAggregation?: InputMaybe<StatLeadAggregationUpdateOneWithoutLeadInput>;
  status?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sub_industry?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timezone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  zip?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type LeadUpdateWithoutSequence_StepInput = {
  address?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  address_2?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  alternate_contacts?: InputMaybe<ContactUpdateManyWithoutLeadInput>;
  assignment_locked?: InputMaybe<BoolFieldUpdateOperationsInput>;
  associate_children?: InputMaybe<LeadUpdateManyWithoutAssociate_Parent_LeadInput>;
  associate_group_lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutAssociate_Group_LeadsInput>;
  associate_lead_histories?: InputMaybe<AssociationHistoryUpdateManyWithoutAssociate_LeadInput>;
  associate_parent_lead?: InputMaybe<LeadUpdateOneWithoutAssociate_ChildrenInput>;
  associated_parent_intents?: InputMaybe<LeadIntentUpdateManyWithoutAssociate_ParentInput>;
  associates_search_content?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  business_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptUpdateManyWithoutLeadInput>;
  callMeNows?: InputMaybe<CallMeNowUpdateManyWithoutLeadInput>;
  channel?: InputMaybe<NullableEnumChannelFieldUpdateOperationsInput>;
  child_lead_activites?: InputMaybe<LeadActivityUpdateManyWithoutAssociate_ChildInput>;
  city?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  claim_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  conference_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  contact_requests?: InputMaybe<ContactRequestUpdateManyWithoutLeadInput>;
  content?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  current_close_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  current_contact_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  current_sale_cycle?: InputMaybe<SaleCycleUpdateOneWithoutLead_If_CurrentInput>;
  custom_field_json?: InputMaybe<Scalars['Json']['input']>;
  custom_fields?: InputMaybe<CustomFieldUpdateManyWithoutLeadInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemUpdateManyWithoutLeadInput>;
  delay_action_status?: InputMaybe<BoolFieldUpdateOperationsInput>;
  email_sub_status?: InputMaybe<EnumOptInStatusFieldUpdateOperationsInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordUpdateManyWithoutLeadInput>;
  EmailThread?: InputMaybe<EmailThreadUpdateManyWithoutLeadInput>;
  favorited_by_users?: InputMaybe<UserUpdateManyWithoutFavorite_LeadsInput>;
  first_demo_schedule_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  first_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  hubspot_company_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  hubspot_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  implied_next_action?: InputMaybe<EnumHingepointFieldUpdateOperationsInput>;
  in_algo_queue?: InputMaybe<BoolFieldUpdateOperationsInput>;
  in_custom_sequence?: InputMaybe<IntFieldUpdateOperationsInput>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventUpdateManyWithoutLeadInput>;
  industry?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogUpdateManyWithoutLeadInput>;
  last_call_result?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  last_call_result_rep?: InputMaybe<UserUpdateOneWithoutLeads_Last_CalledInput>;
  last_call_result_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  last_lead_assignment_origin?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  last_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  last_sent_to_resting_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutLeadInput>;
  lead_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutLeadInput>;
  lead_assignment_origin?: InputMaybe<EnumAssignmentOriginFieldUpdateOperationsInput>;
  lead_creation_source?: InputMaybe<NullableEnumLeadcreationsourceFieldUpdateOperationsInput>;
  lead_custom_row_info?: InputMaybe<LeadCustomObjectRowAssociationUpdateManyWithoutLeadInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryUpdateOneWithoutLeadInput>;
  lead_import_history_csv?: InputMaybe<LeadImportHistoryUpdateOneWithoutUploaded_LeadsInput>;
  lead_intent?: InputMaybe<LeadIntentUpdateManyWithoutLeadInput>;
  lead_ownership_status?: InputMaybe<EnumLosFieldUpdateOperationsInput>;
  lead_source?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  lead_value?: InputMaybe<LeadValueUpdateOneWithoutLeadInput>;
  mrr?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  newIncoming?: InputMaybe<BoolFieldUpdateOperationsInput>;
  next_scheduled_event?: InputMaybe<ScheduleItemUpdateOneWithoutLeadInput>;
  notifications?: InputMaybe<NotificationUpdateManyWithoutLeadInput>;
  opsiq_whitelist?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutLeadsInput>;
  phase?: InputMaybe<EnumPhaseFieldUpdateOperationsInput>;
  primary_email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_email_title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_email_type?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_lead_histories?: InputMaybe<AssociationHistoryUpdateManyWithoutPrimary_LeadInput>;
  primary_phone_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_phone_number_country_code?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_phone_number_title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_phone_number_type?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  rep?: InputMaybe<UserUpdateOneWithoutOwned_LeadsInput>;
  resting_type?: InputMaybe<NullableEnumRestingtypeFieldUpdateOperationsInput>;
  routing_history?: InputMaybe<RoutingHistoryUpdateManyWithoutLeadInput>;
  routing_rule?: InputMaybe<RuleUpdateOneWithoutLeadInput>;
  Sale?: InputMaybe<SaleUpdateManyWithoutLeadInput>;
  sale_cycles?: InputMaybe<SaleCycleUpdateManyWithoutLeadInput>;
  search_content?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  secondary_reps?: InputMaybe<UserUpdateManyWithoutIdle_Owned_LeadsInput>;
  self_sourced?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  sequence?: InputMaybe<SequenceUpdateOneWithoutLeadsInput>;
  SequenceActionLog?: InputMaybe<StepActionLogUpdateManyWithoutLeadInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutLeadInput>;
  sequences_completed_ids?: InputMaybe<LeadUpdatesequences_Completed_IdsInput>;
  sequences_joined_ids?: InputMaybe<LeadUpdatesequences_Joined_IdsInput>;
  sf_account_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sf_contact_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sf_lead_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sibling_lead_histories?: InputMaybe<AssociationHistoryUpdateManyWithoutSibling_LeadsInput>;
  sms_sub_status?: InputMaybe<EnumOptInStatusFieldUpdateOperationsInput>;
  stat_items?: InputMaybe<StatItemAggregationUpdateManyWithoutLeadInput>;
  state?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  StatLeadAggregation?: InputMaybe<StatLeadAggregationUpdateOneWithoutLeadInput>;
  status?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sub_industry?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timezone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  zip?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type LeadUpdateWithoutSequenceActionLogInput = {
  address?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  address_2?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  alternate_contacts?: InputMaybe<ContactUpdateManyWithoutLeadInput>;
  assignment_locked?: InputMaybe<BoolFieldUpdateOperationsInput>;
  associate_children?: InputMaybe<LeadUpdateManyWithoutAssociate_Parent_LeadInput>;
  associate_group_lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutAssociate_Group_LeadsInput>;
  associate_lead_histories?: InputMaybe<AssociationHistoryUpdateManyWithoutAssociate_LeadInput>;
  associate_parent_lead?: InputMaybe<LeadUpdateOneWithoutAssociate_ChildrenInput>;
  associated_parent_intents?: InputMaybe<LeadIntentUpdateManyWithoutAssociate_ParentInput>;
  associates_search_content?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  business_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptUpdateManyWithoutLeadInput>;
  callMeNows?: InputMaybe<CallMeNowUpdateManyWithoutLeadInput>;
  channel?: InputMaybe<NullableEnumChannelFieldUpdateOperationsInput>;
  child_lead_activites?: InputMaybe<LeadActivityUpdateManyWithoutAssociate_ChildInput>;
  city?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  claim_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  conference_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  contact_requests?: InputMaybe<ContactRequestUpdateManyWithoutLeadInput>;
  content?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  current_close_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  current_contact_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  current_sale_cycle?: InputMaybe<SaleCycleUpdateOneWithoutLead_If_CurrentInput>;
  custom_field_json?: InputMaybe<Scalars['Json']['input']>;
  custom_fields?: InputMaybe<CustomFieldUpdateManyWithoutLeadInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemUpdateManyWithoutLeadInput>;
  delay_action_status?: InputMaybe<BoolFieldUpdateOperationsInput>;
  email_sub_status?: InputMaybe<EnumOptInStatusFieldUpdateOperationsInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordUpdateManyWithoutLeadInput>;
  EmailThread?: InputMaybe<EmailThreadUpdateManyWithoutLeadInput>;
  favorited_by_users?: InputMaybe<UserUpdateManyWithoutFavorite_LeadsInput>;
  first_demo_schedule_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  first_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  hubspot_company_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  hubspot_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  implied_next_action?: InputMaybe<EnumHingepointFieldUpdateOperationsInput>;
  in_algo_queue?: InputMaybe<BoolFieldUpdateOperationsInput>;
  in_custom_sequence?: InputMaybe<IntFieldUpdateOperationsInput>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventUpdateManyWithoutLeadInput>;
  industry?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogUpdateManyWithoutLeadInput>;
  last_call_result?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  last_call_result_rep?: InputMaybe<UserUpdateOneWithoutLeads_Last_CalledInput>;
  last_call_result_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  last_lead_assignment_origin?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  last_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  last_sent_to_resting_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutLeadInput>;
  lead_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutLeadInput>;
  lead_assignment_origin?: InputMaybe<EnumAssignmentOriginFieldUpdateOperationsInput>;
  lead_creation_source?: InputMaybe<NullableEnumLeadcreationsourceFieldUpdateOperationsInput>;
  lead_custom_row_info?: InputMaybe<LeadCustomObjectRowAssociationUpdateManyWithoutLeadInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryUpdateOneWithoutLeadInput>;
  lead_import_history_csv?: InputMaybe<LeadImportHistoryUpdateOneWithoutUploaded_LeadsInput>;
  lead_intent?: InputMaybe<LeadIntentUpdateManyWithoutLeadInput>;
  lead_ownership_status?: InputMaybe<EnumLosFieldUpdateOperationsInput>;
  lead_source?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  lead_value?: InputMaybe<LeadValueUpdateOneWithoutLeadInput>;
  mrr?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  newIncoming?: InputMaybe<BoolFieldUpdateOperationsInput>;
  next_scheduled_event?: InputMaybe<ScheduleItemUpdateOneWithoutLeadInput>;
  notifications?: InputMaybe<NotificationUpdateManyWithoutLeadInput>;
  opsiq_whitelist?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutLeadsInput>;
  phase?: InputMaybe<EnumPhaseFieldUpdateOperationsInput>;
  primary_email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_email_title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_email_type?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_lead_histories?: InputMaybe<AssociationHistoryUpdateManyWithoutPrimary_LeadInput>;
  primary_phone_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_phone_number_country_code?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_phone_number_title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_phone_number_type?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  rep?: InputMaybe<UserUpdateOneWithoutOwned_LeadsInput>;
  resting_type?: InputMaybe<NullableEnumRestingtypeFieldUpdateOperationsInput>;
  routing_history?: InputMaybe<RoutingHistoryUpdateManyWithoutLeadInput>;
  routing_rule?: InputMaybe<RuleUpdateOneWithoutLeadInput>;
  Sale?: InputMaybe<SaleUpdateManyWithoutLeadInput>;
  sale_cycles?: InputMaybe<SaleCycleUpdateManyWithoutLeadInput>;
  search_content?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  secondary_reps?: InputMaybe<UserUpdateManyWithoutIdle_Owned_LeadsInput>;
  self_sourced?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  sequence?: InputMaybe<SequenceUpdateOneWithoutLeadsInput>;
  sequence_step?: InputMaybe<SequenceStepUpdateOneWithoutLeadsInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutLeadInput>;
  sequences_completed_ids?: InputMaybe<LeadUpdatesequences_Completed_IdsInput>;
  sequences_joined_ids?: InputMaybe<LeadUpdatesequences_Joined_IdsInput>;
  sf_account_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sf_contact_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sf_lead_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sibling_lead_histories?: InputMaybe<AssociationHistoryUpdateManyWithoutSibling_LeadsInput>;
  sms_sub_status?: InputMaybe<EnumOptInStatusFieldUpdateOperationsInput>;
  stat_items?: InputMaybe<StatItemAggregationUpdateManyWithoutLeadInput>;
  state?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  StatLeadAggregation?: InputMaybe<StatLeadAggregationUpdateOneWithoutLeadInput>;
  status?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sub_industry?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timezone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  zip?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type LeadUpdateWithoutSequenceEntryExitLeadInput = {
  address?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  address_2?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  alternate_contacts?: InputMaybe<ContactUpdateManyWithoutLeadInput>;
  assignment_locked?: InputMaybe<BoolFieldUpdateOperationsInput>;
  associate_children?: InputMaybe<LeadUpdateManyWithoutAssociate_Parent_LeadInput>;
  associate_group_lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutAssociate_Group_LeadsInput>;
  associate_lead_histories?: InputMaybe<AssociationHistoryUpdateManyWithoutAssociate_LeadInput>;
  associate_parent_lead?: InputMaybe<LeadUpdateOneWithoutAssociate_ChildrenInput>;
  associated_parent_intents?: InputMaybe<LeadIntentUpdateManyWithoutAssociate_ParentInput>;
  associates_search_content?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  business_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptUpdateManyWithoutLeadInput>;
  callMeNows?: InputMaybe<CallMeNowUpdateManyWithoutLeadInput>;
  channel?: InputMaybe<NullableEnumChannelFieldUpdateOperationsInput>;
  child_lead_activites?: InputMaybe<LeadActivityUpdateManyWithoutAssociate_ChildInput>;
  city?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  claim_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  conference_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  contact_requests?: InputMaybe<ContactRequestUpdateManyWithoutLeadInput>;
  content?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  current_close_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  current_contact_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  current_sale_cycle?: InputMaybe<SaleCycleUpdateOneWithoutLead_If_CurrentInput>;
  custom_field_json?: InputMaybe<Scalars['Json']['input']>;
  custom_fields?: InputMaybe<CustomFieldUpdateManyWithoutLeadInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemUpdateManyWithoutLeadInput>;
  delay_action_status?: InputMaybe<BoolFieldUpdateOperationsInput>;
  email_sub_status?: InputMaybe<EnumOptInStatusFieldUpdateOperationsInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordUpdateManyWithoutLeadInput>;
  EmailThread?: InputMaybe<EmailThreadUpdateManyWithoutLeadInput>;
  favorited_by_users?: InputMaybe<UserUpdateManyWithoutFavorite_LeadsInput>;
  first_demo_schedule_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  first_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  hubspot_company_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  hubspot_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  implied_next_action?: InputMaybe<EnumHingepointFieldUpdateOperationsInput>;
  in_algo_queue?: InputMaybe<BoolFieldUpdateOperationsInput>;
  in_custom_sequence?: InputMaybe<IntFieldUpdateOperationsInput>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventUpdateManyWithoutLeadInput>;
  industry?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogUpdateManyWithoutLeadInput>;
  last_call_result?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  last_call_result_rep?: InputMaybe<UserUpdateOneWithoutLeads_Last_CalledInput>;
  last_call_result_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  last_lead_assignment_origin?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  last_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  last_sent_to_resting_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutLeadInput>;
  lead_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutLeadInput>;
  lead_assignment_origin?: InputMaybe<EnumAssignmentOriginFieldUpdateOperationsInput>;
  lead_creation_source?: InputMaybe<NullableEnumLeadcreationsourceFieldUpdateOperationsInput>;
  lead_custom_row_info?: InputMaybe<LeadCustomObjectRowAssociationUpdateManyWithoutLeadInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryUpdateOneWithoutLeadInput>;
  lead_import_history_csv?: InputMaybe<LeadImportHistoryUpdateOneWithoutUploaded_LeadsInput>;
  lead_intent?: InputMaybe<LeadIntentUpdateManyWithoutLeadInput>;
  lead_ownership_status?: InputMaybe<EnumLosFieldUpdateOperationsInput>;
  lead_source?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  lead_value?: InputMaybe<LeadValueUpdateOneWithoutLeadInput>;
  mrr?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  newIncoming?: InputMaybe<BoolFieldUpdateOperationsInput>;
  next_scheduled_event?: InputMaybe<ScheduleItemUpdateOneWithoutLeadInput>;
  notifications?: InputMaybe<NotificationUpdateManyWithoutLeadInput>;
  opsiq_whitelist?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutLeadsInput>;
  phase?: InputMaybe<EnumPhaseFieldUpdateOperationsInput>;
  primary_email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_email_title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_email_type?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_lead_histories?: InputMaybe<AssociationHistoryUpdateManyWithoutPrimary_LeadInput>;
  primary_phone_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_phone_number_country_code?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_phone_number_title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_phone_number_type?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  rep?: InputMaybe<UserUpdateOneWithoutOwned_LeadsInput>;
  resting_type?: InputMaybe<NullableEnumRestingtypeFieldUpdateOperationsInput>;
  routing_history?: InputMaybe<RoutingHistoryUpdateManyWithoutLeadInput>;
  routing_rule?: InputMaybe<RuleUpdateOneWithoutLeadInput>;
  Sale?: InputMaybe<SaleUpdateManyWithoutLeadInput>;
  sale_cycles?: InputMaybe<SaleCycleUpdateManyWithoutLeadInput>;
  search_content?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  secondary_reps?: InputMaybe<UserUpdateManyWithoutIdle_Owned_LeadsInput>;
  self_sourced?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  sequence?: InputMaybe<SequenceUpdateOneWithoutLeadsInput>;
  sequence_step?: InputMaybe<SequenceStepUpdateOneWithoutLeadsInput>;
  SequenceActionLog?: InputMaybe<StepActionLogUpdateManyWithoutLeadInput>;
  sequences_completed_ids?: InputMaybe<LeadUpdatesequences_Completed_IdsInput>;
  sequences_joined_ids?: InputMaybe<LeadUpdatesequences_Joined_IdsInput>;
  sf_account_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sf_contact_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sf_lead_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sibling_lead_histories?: InputMaybe<AssociationHistoryUpdateManyWithoutSibling_LeadsInput>;
  sms_sub_status?: InputMaybe<EnumOptInStatusFieldUpdateOperationsInput>;
  stat_items?: InputMaybe<StatItemAggregationUpdateManyWithoutLeadInput>;
  state?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  StatLeadAggregation?: InputMaybe<StatLeadAggregationUpdateOneWithoutLeadInput>;
  status?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sub_industry?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timezone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  zip?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type LeadUpdateWithoutSequenceInput = {
  address?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  address_2?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  alternate_contacts?: InputMaybe<ContactUpdateManyWithoutLeadInput>;
  assignment_locked?: InputMaybe<BoolFieldUpdateOperationsInput>;
  associate_children?: InputMaybe<LeadUpdateManyWithoutAssociate_Parent_LeadInput>;
  associate_group_lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutAssociate_Group_LeadsInput>;
  associate_lead_histories?: InputMaybe<AssociationHistoryUpdateManyWithoutAssociate_LeadInput>;
  associate_parent_lead?: InputMaybe<LeadUpdateOneWithoutAssociate_ChildrenInput>;
  associated_parent_intents?: InputMaybe<LeadIntentUpdateManyWithoutAssociate_ParentInput>;
  associates_search_content?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  business_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptUpdateManyWithoutLeadInput>;
  callMeNows?: InputMaybe<CallMeNowUpdateManyWithoutLeadInput>;
  channel?: InputMaybe<NullableEnumChannelFieldUpdateOperationsInput>;
  child_lead_activites?: InputMaybe<LeadActivityUpdateManyWithoutAssociate_ChildInput>;
  city?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  claim_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  conference_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  contact_requests?: InputMaybe<ContactRequestUpdateManyWithoutLeadInput>;
  content?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  current_close_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  current_contact_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  current_sale_cycle?: InputMaybe<SaleCycleUpdateOneWithoutLead_If_CurrentInput>;
  custom_field_json?: InputMaybe<Scalars['Json']['input']>;
  custom_fields?: InputMaybe<CustomFieldUpdateManyWithoutLeadInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemUpdateManyWithoutLeadInput>;
  delay_action_status?: InputMaybe<BoolFieldUpdateOperationsInput>;
  email_sub_status?: InputMaybe<EnumOptInStatusFieldUpdateOperationsInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordUpdateManyWithoutLeadInput>;
  EmailThread?: InputMaybe<EmailThreadUpdateManyWithoutLeadInput>;
  favorited_by_users?: InputMaybe<UserUpdateManyWithoutFavorite_LeadsInput>;
  first_demo_schedule_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  first_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  hubspot_company_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  hubspot_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  implied_next_action?: InputMaybe<EnumHingepointFieldUpdateOperationsInput>;
  in_algo_queue?: InputMaybe<BoolFieldUpdateOperationsInput>;
  in_custom_sequence?: InputMaybe<IntFieldUpdateOperationsInput>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventUpdateManyWithoutLeadInput>;
  industry?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogUpdateManyWithoutLeadInput>;
  last_call_result?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  last_call_result_rep?: InputMaybe<UserUpdateOneWithoutLeads_Last_CalledInput>;
  last_call_result_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  last_lead_assignment_origin?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  last_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  last_sent_to_resting_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutLeadInput>;
  lead_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutLeadInput>;
  lead_assignment_origin?: InputMaybe<EnumAssignmentOriginFieldUpdateOperationsInput>;
  lead_creation_source?: InputMaybe<NullableEnumLeadcreationsourceFieldUpdateOperationsInput>;
  lead_custom_row_info?: InputMaybe<LeadCustomObjectRowAssociationUpdateManyWithoutLeadInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryUpdateOneWithoutLeadInput>;
  lead_import_history_csv?: InputMaybe<LeadImportHistoryUpdateOneWithoutUploaded_LeadsInput>;
  lead_intent?: InputMaybe<LeadIntentUpdateManyWithoutLeadInput>;
  lead_ownership_status?: InputMaybe<EnumLosFieldUpdateOperationsInput>;
  lead_source?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  lead_value?: InputMaybe<LeadValueUpdateOneWithoutLeadInput>;
  mrr?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  newIncoming?: InputMaybe<BoolFieldUpdateOperationsInput>;
  next_scheduled_event?: InputMaybe<ScheduleItemUpdateOneWithoutLeadInput>;
  notifications?: InputMaybe<NotificationUpdateManyWithoutLeadInput>;
  opsiq_whitelist?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutLeadsInput>;
  phase?: InputMaybe<EnumPhaseFieldUpdateOperationsInput>;
  primary_email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_email_title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_email_type?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_lead_histories?: InputMaybe<AssociationHistoryUpdateManyWithoutPrimary_LeadInput>;
  primary_phone_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_phone_number_country_code?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_phone_number_title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_phone_number_type?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  rep?: InputMaybe<UserUpdateOneWithoutOwned_LeadsInput>;
  resting_type?: InputMaybe<NullableEnumRestingtypeFieldUpdateOperationsInput>;
  routing_history?: InputMaybe<RoutingHistoryUpdateManyWithoutLeadInput>;
  routing_rule?: InputMaybe<RuleUpdateOneWithoutLeadInput>;
  Sale?: InputMaybe<SaleUpdateManyWithoutLeadInput>;
  sale_cycles?: InputMaybe<SaleCycleUpdateManyWithoutLeadInput>;
  search_content?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  secondary_reps?: InputMaybe<UserUpdateManyWithoutIdle_Owned_LeadsInput>;
  self_sourced?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  sequence_step?: InputMaybe<SequenceStepUpdateOneWithoutLeadsInput>;
  SequenceActionLog?: InputMaybe<StepActionLogUpdateManyWithoutLeadInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutLeadInput>;
  sequences_completed_ids?: InputMaybe<LeadUpdatesequences_Completed_IdsInput>;
  sequences_joined_ids?: InputMaybe<LeadUpdatesequences_Joined_IdsInput>;
  sf_account_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sf_contact_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sf_lead_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sibling_lead_histories?: InputMaybe<AssociationHistoryUpdateManyWithoutSibling_LeadsInput>;
  sms_sub_status?: InputMaybe<EnumOptInStatusFieldUpdateOperationsInput>;
  stat_items?: InputMaybe<StatItemAggregationUpdateManyWithoutLeadInput>;
  state?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  StatLeadAggregation?: InputMaybe<StatLeadAggregationUpdateOneWithoutLeadInput>;
  status?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sub_industry?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timezone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  zip?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type LeadUpdateWithoutSibling_Lead_HistoriesInput = {
  address?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  address_2?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  alternate_contacts?: InputMaybe<ContactUpdateManyWithoutLeadInput>;
  assignment_locked?: InputMaybe<BoolFieldUpdateOperationsInput>;
  associate_children?: InputMaybe<LeadUpdateManyWithoutAssociate_Parent_LeadInput>;
  associate_group_lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutAssociate_Group_LeadsInput>;
  associate_lead_histories?: InputMaybe<AssociationHistoryUpdateManyWithoutAssociate_LeadInput>;
  associate_parent_lead?: InputMaybe<LeadUpdateOneWithoutAssociate_ChildrenInput>;
  associated_parent_intents?: InputMaybe<LeadIntentUpdateManyWithoutAssociate_ParentInput>;
  associates_search_content?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  business_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptUpdateManyWithoutLeadInput>;
  callMeNows?: InputMaybe<CallMeNowUpdateManyWithoutLeadInput>;
  channel?: InputMaybe<NullableEnumChannelFieldUpdateOperationsInput>;
  child_lead_activites?: InputMaybe<LeadActivityUpdateManyWithoutAssociate_ChildInput>;
  city?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  claim_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  conference_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  contact_requests?: InputMaybe<ContactRequestUpdateManyWithoutLeadInput>;
  content?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  current_close_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  current_contact_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  current_sale_cycle?: InputMaybe<SaleCycleUpdateOneWithoutLead_If_CurrentInput>;
  custom_field_json?: InputMaybe<Scalars['Json']['input']>;
  custom_fields?: InputMaybe<CustomFieldUpdateManyWithoutLeadInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemUpdateManyWithoutLeadInput>;
  delay_action_status?: InputMaybe<BoolFieldUpdateOperationsInput>;
  email_sub_status?: InputMaybe<EnumOptInStatusFieldUpdateOperationsInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordUpdateManyWithoutLeadInput>;
  EmailThread?: InputMaybe<EmailThreadUpdateManyWithoutLeadInput>;
  favorited_by_users?: InputMaybe<UserUpdateManyWithoutFavorite_LeadsInput>;
  first_demo_schedule_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  first_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  hubspot_company_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  hubspot_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  implied_next_action?: InputMaybe<EnumHingepointFieldUpdateOperationsInput>;
  in_algo_queue?: InputMaybe<BoolFieldUpdateOperationsInput>;
  in_custom_sequence?: InputMaybe<IntFieldUpdateOperationsInput>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventUpdateManyWithoutLeadInput>;
  industry?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogUpdateManyWithoutLeadInput>;
  last_call_result?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  last_call_result_rep?: InputMaybe<UserUpdateOneWithoutLeads_Last_CalledInput>;
  last_call_result_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  last_lead_assignment_origin?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  last_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  last_sent_to_resting_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutLeadInput>;
  lead_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutLeadInput>;
  lead_assignment_origin?: InputMaybe<EnumAssignmentOriginFieldUpdateOperationsInput>;
  lead_creation_source?: InputMaybe<NullableEnumLeadcreationsourceFieldUpdateOperationsInput>;
  lead_custom_row_info?: InputMaybe<LeadCustomObjectRowAssociationUpdateManyWithoutLeadInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryUpdateOneWithoutLeadInput>;
  lead_import_history_csv?: InputMaybe<LeadImportHistoryUpdateOneWithoutUploaded_LeadsInput>;
  lead_intent?: InputMaybe<LeadIntentUpdateManyWithoutLeadInput>;
  lead_ownership_status?: InputMaybe<EnumLosFieldUpdateOperationsInput>;
  lead_source?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  lead_value?: InputMaybe<LeadValueUpdateOneWithoutLeadInput>;
  mrr?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  newIncoming?: InputMaybe<BoolFieldUpdateOperationsInput>;
  next_scheduled_event?: InputMaybe<ScheduleItemUpdateOneWithoutLeadInput>;
  notifications?: InputMaybe<NotificationUpdateManyWithoutLeadInput>;
  opsiq_whitelist?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutLeadsInput>;
  phase?: InputMaybe<EnumPhaseFieldUpdateOperationsInput>;
  primary_email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_email_title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_email_type?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_lead_histories?: InputMaybe<AssociationHistoryUpdateManyWithoutPrimary_LeadInput>;
  primary_phone_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_phone_number_country_code?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_phone_number_title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_phone_number_type?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  rep?: InputMaybe<UserUpdateOneWithoutOwned_LeadsInput>;
  resting_type?: InputMaybe<NullableEnumRestingtypeFieldUpdateOperationsInput>;
  routing_history?: InputMaybe<RoutingHistoryUpdateManyWithoutLeadInput>;
  routing_rule?: InputMaybe<RuleUpdateOneWithoutLeadInput>;
  Sale?: InputMaybe<SaleUpdateManyWithoutLeadInput>;
  sale_cycles?: InputMaybe<SaleCycleUpdateManyWithoutLeadInput>;
  search_content?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  secondary_reps?: InputMaybe<UserUpdateManyWithoutIdle_Owned_LeadsInput>;
  self_sourced?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  sequence?: InputMaybe<SequenceUpdateOneWithoutLeadsInput>;
  sequence_step?: InputMaybe<SequenceStepUpdateOneWithoutLeadsInput>;
  SequenceActionLog?: InputMaybe<StepActionLogUpdateManyWithoutLeadInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutLeadInput>;
  sequences_completed_ids?: InputMaybe<LeadUpdatesequences_Completed_IdsInput>;
  sequences_joined_ids?: InputMaybe<LeadUpdatesequences_Joined_IdsInput>;
  sf_account_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sf_contact_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sf_lead_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sms_sub_status?: InputMaybe<EnumOptInStatusFieldUpdateOperationsInput>;
  stat_items?: InputMaybe<StatItemAggregationUpdateManyWithoutLeadInput>;
  state?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  StatLeadAggregation?: InputMaybe<StatLeadAggregationUpdateOneWithoutLeadInput>;
  status?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sub_industry?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timezone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  zip?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type LeadUpdateWithoutStat_ItemsInput = {
  address?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  address_2?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  alternate_contacts?: InputMaybe<ContactUpdateManyWithoutLeadInput>;
  assignment_locked?: InputMaybe<BoolFieldUpdateOperationsInput>;
  associate_children?: InputMaybe<LeadUpdateManyWithoutAssociate_Parent_LeadInput>;
  associate_group_lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutAssociate_Group_LeadsInput>;
  associate_lead_histories?: InputMaybe<AssociationHistoryUpdateManyWithoutAssociate_LeadInput>;
  associate_parent_lead?: InputMaybe<LeadUpdateOneWithoutAssociate_ChildrenInput>;
  associated_parent_intents?: InputMaybe<LeadIntentUpdateManyWithoutAssociate_ParentInput>;
  associates_search_content?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  business_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptUpdateManyWithoutLeadInput>;
  callMeNows?: InputMaybe<CallMeNowUpdateManyWithoutLeadInput>;
  channel?: InputMaybe<NullableEnumChannelFieldUpdateOperationsInput>;
  child_lead_activites?: InputMaybe<LeadActivityUpdateManyWithoutAssociate_ChildInput>;
  city?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  claim_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  conference_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  contact_requests?: InputMaybe<ContactRequestUpdateManyWithoutLeadInput>;
  content?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  current_close_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  current_contact_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  current_sale_cycle?: InputMaybe<SaleCycleUpdateOneWithoutLead_If_CurrentInput>;
  custom_field_json?: InputMaybe<Scalars['Json']['input']>;
  custom_fields?: InputMaybe<CustomFieldUpdateManyWithoutLeadInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemUpdateManyWithoutLeadInput>;
  delay_action_status?: InputMaybe<BoolFieldUpdateOperationsInput>;
  email_sub_status?: InputMaybe<EnumOptInStatusFieldUpdateOperationsInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordUpdateManyWithoutLeadInput>;
  EmailThread?: InputMaybe<EmailThreadUpdateManyWithoutLeadInput>;
  favorited_by_users?: InputMaybe<UserUpdateManyWithoutFavorite_LeadsInput>;
  first_demo_schedule_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  first_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  hubspot_company_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  hubspot_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  implied_next_action?: InputMaybe<EnumHingepointFieldUpdateOperationsInput>;
  in_algo_queue?: InputMaybe<BoolFieldUpdateOperationsInput>;
  in_custom_sequence?: InputMaybe<IntFieldUpdateOperationsInput>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventUpdateManyWithoutLeadInput>;
  industry?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogUpdateManyWithoutLeadInput>;
  last_call_result?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  last_call_result_rep?: InputMaybe<UserUpdateOneWithoutLeads_Last_CalledInput>;
  last_call_result_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  last_lead_assignment_origin?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  last_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  last_sent_to_resting_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutLeadInput>;
  lead_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutLeadInput>;
  lead_assignment_origin?: InputMaybe<EnumAssignmentOriginFieldUpdateOperationsInput>;
  lead_creation_source?: InputMaybe<NullableEnumLeadcreationsourceFieldUpdateOperationsInput>;
  lead_custom_row_info?: InputMaybe<LeadCustomObjectRowAssociationUpdateManyWithoutLeadInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryUpdateOneWithoutLeadInput>;
  lead_import_history_csv?: InputMaybe<LeadImportHistoryUpdateOneWithoutUploaded_LeadsInput>;
  lead_intent?: InputMaybe<LeadIntentUpdateManyWithoutLeadInput>;
  lead_ownership_status?: InputMaybe<EnumLosFieldUpdateOperationsInput>;
  lead_source?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  lead_value?: InputMaybe<LeadValueUpdateOneWithoutLeadInput>;
  mrr?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  newIncoming?: InputMaybe<BoolFieldUpdateOperationsInput>;
  next_scheduled_event?: InputMaybe<ScheduleItemUpdateOneWithoutLeadInput>;
  notifications?: InputMaybe<NotificationUpdateManyWithoutLeadInput>;
  opsiq_whitelist?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutLeadsInput>;
  phase?: InputMaybe<EnumPhaseFieldUpdateOperationsInput>;
  primary_email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_email_title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_email_type?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_lead_histories?: InputMaybe<AssociationHistoryUpdateManyWithoutPrimary_LeadInput>;
  primary_phone_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_phone_number_country_code?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_phone_number_title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_phone_number_type?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  rep?: InputMaybe<UserUpdateOneWithoutOwned_LeadsInput>;
  resting_type?: InputMaybe<NullableEnumRestingtypeFieldUpdateOperationsInput>;
  routing_history?: InputMaybe<RoutingHistoryUpdateManyWithoutLeadInput>;
  routing_rule?: InputMaybe<RuleUpdateOneWithoutLeadInput>;
  Sale?: InputMaybe<SaleUpdateManyWithoutLeadInput>;
  sale_cycles?: InputMaybe<SaleCycleUpdateManyWithoutLeadInput>;
  search_content?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  secondary_reps?: InputMaybe<UserUpdateManyWithoutIdle_Owned_LeadsInput>;
  self_sourced?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  sequence?: InputMaybe<SequenceUpdateOneWithoutLeadsInput>;
  sequence_step?: InputMaybe<SequenceStepUpdateOneWithoutLeadsInput>;
  SequenceActionLog?: InputMaybe<StepActionLogUpdateManyWithoutLeadInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutLeadInput>;
  sequences_completed_ids?: InputMaybe<LeadUpdatesequences_Completed_IdsInput>;
  sequences_joined_ids?: InputMaybe<LeadUpdatesequences_Joined_IdsInput>;
  sf_account_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sf_contact_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sf_lead_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sibling_lead_histories?: InputMaybe<AssociationHistoryUpdateManyWithoutSibling_LeadsInput>;
  sms_sub_status?: InputMaybe<EnumOptInStatusFieldUpdateOperationsInput>;
  state?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  StatLeadAggregation?: InputMaybe<StatLeadAggregationUpdateOneWithoutLeadInput>;
  status?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sub_industry?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timezone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  zip?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type LeadUpdateWithoutStatLeadAggregationInput = {
  address?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  address_2?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  alternate_contacts?: InputMaybe<ContactUpdateManyWithoutLeadInput>;
  assignment_locked?: InputMaybe<BoolFieldUpdateOperationsInput>;
  associate_children?: InputMaybe<LeadUpdateManyWithoutAssociate_Parent_LeadInput>;
  associate_group_lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutAssociate_Group_LeadsInput>;
  associate_lead_histories?: InputMaybe<AssociationHistoryUpdateManyWithoutAssociate_LeadInput>;
  associate_parent_lead?: InputMaybe<LeadUpdateOneWithoutAssociate_ChildrenInput>;
  associated_parent_intents?: InputMaybe<LeadIntentUpdateManyWithoutAssociate_ParentInput>;
  associates_search_content?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  business_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptUpdateManyWithoutLeadInput>;
  callMeNows?: InputMaybe<CallMeNowUpdateManyWithoutLeadInput>;
  channel?: InputMaybe<NullableEnumChannelFieldUpdateOperationsInput>;
  child_lead_activites?: InputMaybe<LeadActivityUpdateManyWithoutAssociate_ChildInput>;
  city?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  claim_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  conference_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  contact_requests?: InputMaybe<ContactRequestUpdateManyWithoutLeadInput>;
  content?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  current_close_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  current_contact_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  current_sale_cycle?: InputMaybe<SaleCycleUpdateOneWithoutLead_If_CurrentInput>;
  custom_field_json?: InputMaybe<Scalars['Json']['input']>;
  custom_fields?: InputMaybe<CustomFieldUpdateManyWithoutLeadInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemUpdateManyWithoutLeadInput>;
  delay_action_status?: InputMaybe<BoolFieldUpdateOperationsInput>;
  email_sub_status?: InputMaybe<EnumOptInStatusFieldUpdateOperationsInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordUpdateManyWithoutLeadInput>;
  EmailThread?: InputMaybe<EmailThreadUpdateManyWithoutLeadInput>;
  favorited_by_users?: InputMaybe<UserUpdateManyWithoutFavorite_LeadsInput>;
  first_demo_schedule_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  first_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  hubspot_company_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  hubspot_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  implied_next_action?: InputMaybe<EnumHingepointFieldUpdateOperationsInput>;
  in_algo_queue?: InputMaybe<BoolFieldUpdateOperationsInput>;
  in_custom_sequence?: InputMaybe<IntFieldUpdateOperationsInput>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventUpdateManyWithoutLeadInput>;
  industry?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogUpdateManyWithoutLeadInput>;
  last_call_result?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  last_call_result_rep?: InputMaybe<UserUpdateOneWithoutLeads_Last_CalledInput>;
  last_call_result_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  last_lead_assignment_origin?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  last_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  last_sent_to_resting_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutLeadInput>;
  lead_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutLeadInput>;
  lead_assignment_origin?: InputMaybe<EnumAssignmentOriginFieldUpdateOperationsInput>;
  lead_creation_source?: InputMaybe<NullableEnumLeadcreationsourceFieldUpdateOperationsInput>;
  lead_custom_row_info?: InputMaybe<LeadCustomObjectRowAssociationUpdateManyWithoutLeadInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryUpdateOneWithoutLeadInput>;
  lead_import_history_csv?: InputMaybe<LeadImportHistoryUpdateOneWithoutUploaded_LeadsInput>;
  lead_intent?: InputMaybe<LeadIntentUpdateManyWithoutLeadInput>;
  lead_ownership_status?: InputMaybe<EnumLosFieldUpdateOperationsInput>;
  lead_source?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  lead_value?: InputMaybe<LeadValueUpdateOneWithoutLeadInput>;
  mrr?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  newIncoming?: InputMaybe<BoolFieldUpdateOperationsInput>;
  next_scheduled_event?: InputMaybe<ScheduleItemUpdateOneWithoutLeadInput>;
  notifications?: InputMaybe<NotificationUpdateManyWithoutLeadInput>;
  opsiq_whitelist?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutLeadsInput>;
  phase?: InputMaybe<EnumPhaseFieldUpdateOperationsInput>;
  primary_email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_email_title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_email_type?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_lead_histories?: InputMaybe<AssociationHistoryUpdateManyWithoutPrimary_LeadInput>;
  primary_phone_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_phone_number_country_code?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_phone_number_title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  primary_phone_number_type?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  rep?: InputMaybe<UserUpdateOneWithoutOwned_LeadsInput>;
  resting_type?: InputMaybe<NullableEnumRestingtypeFieldUpdateOperationsInput>;
  routing_history?: InputMaybe<RoutingHistoryUpdateManyWithoutLeadInput>;
  routing_rule?: InputMaybe<RuleUpdateOneWithoutLeadInput>;
  Sale?: InputMaybe<SaleUpdateManyWithoutLeadInput>;
  sale_cycles?: InputMaybe<SaleCycleUpdateManyWithoutLeadInput>;
  search_content?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  secondary_reps?: InputMaybe<UserUpdateManyWithoutIdle_Owned_LeadsInput>;
  self_sourced?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  sequence?: InputMaybe<SequenceUpdateOneWithoutLeadsInput>;
  sequence_step?: InputMaybe<SequenceStepUpdateOneWithoutLeadsInput>;
  SequenceActionLog?: InputMaybe<StepActionLogUpdateManyWithoutLeadInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutLeadInput>;
  sequences_completed_ids?: InputMaybe<LeadUpdatesequences_Completed_IdsInput>;
  sequences_joined_ids?: InputMaybe<LeadUpdatesequences_Joined_IdsInput>;
  sf_account_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sf_contact_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sf_lead_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sibling_lead_histories?: InputMaybe<AssociationHistoryUpdateManyWithoutSibling_LeadsInput>;
  sms_sub_status?: InputMaybe<EnumOptInStatusFieldUpdateOperationsInput>;
  stat_items?: InputMaybe<StatItemAggregationUpdateManyWithoutLeadInput>;
  state?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sub_industry?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timezone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  zip?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type LeadUpdateWithWhereUniqueWithoutAssociate_Group_Lead_ActivitiesInput = {
  data: LeadUpdateWithoutAssociate_Group_Lead_ActivitiesInput;
  where: LeadWhereUniqueInput;
};

export type LeadUpdateWithWhereUniqueWithoutAssociate_Parent_LeadInput = {
  data: LeadUpdateWithoutAssociate_Parent_LeadInput;
  where: LeadWhereUniqueInput;
};

export type LeadUpdateWithWhereUniqueWithoutFavorited_By_UsersInput = {
  data: LeadUpdateWithoutFavorited_By_UsersInput;
  where: LeadWhereUniqueInput;
};

export type LeadUpdateWithWhereUniqueWithoutLast_Call_Result_RepInput = {
  data: LeadUpdateWithoutLast_Call_Result_RepInput;
  where: LeadWhereUniqueInput;
};

export type LeadUpdateWithWhereUniqueWithoutLead_Import_History_CsvInput = {
  data: LeadUpdateWithoutLead_Import_History_CsvInput;
  where: LeadWhereUniqueInput;
};

export type LeadUpdateWithWhereUniqueWithoutNext_Scheduled_EventInput = {
  data: LeadUpdateWithoutNext_Scheduled_EventInput;
  where: LeadWhereUniqueInput;
};

export type LeadUpdateWithWhereUniqueWithoutOrganizationInput = {
  data: LeadUpdateWithoutOrganizationInput;
  where: LeadWhereUniqueInput;
};

export type LeadUpdateWithWhereUniqueWithoutRepInput = {
  data: LeadUpdateWithoutRepInput;
  where: LeadWhereUniqueInput;
};

export type LeadUpdateWithWhereUniqueWithoutRouting_RuleInput = {
  data: LeadUpdateWithoutRouting_RuleInput;
  where: LeadWhereUniqueInput;
};

export type LeadUpdateWithWhereUniqueWithoutSecondary_RepsInput = {
  data: LeadUpdateWithoutSecondary_RepsInput;
  where: LeadWhereUniqueInput;
};

export type LeadUpdateWithWhereUniqueWithoutSequence_StepInput = {
  data: LeadUpdateWithoutSequence_StepInput;
  where: LeadWhereUniqueInput;
};

export type LeadUpdateWithWhereUniqueWithoutSequenceInput = {
  data: LeadUpdateWithoutSequenceInput;
  where: LeadWhereUniqueInput;
};

export type LeadUpdateWithWhereUniqueWithoutSibling_Lead_HistoriesInput = {
  data: LeadUpdateWithoutSibling_Lead_HistoriesInput;
  where: LeadWhereUniqueInput;
};

export enum LeadUploadStatus {
  Cancelled = 'Cancelled',
  Completed = 'Completed',
  Initiated = 'Initiated',
  InProgress = 'InProgress'
}

export type LeadUpsertWithoutAlternate_ContactsInput = {
  create: LeadCreateWithoutAlternate_ContactsInput;
  update: LeadUpdateWithoutAlternate_ContactsInput;
};

export type LeadUpsertWithoutAssociate_ChildrenInput = {
  create: LeadCreateWithoutAssociate_ChildrenInput;
  update: LeadUpdateWithoutAssociate_ChildrenInput;
};

export type LeadUpsertWithoutAssociate_Lead_HistoriesInput = {
  create: LeadCreateWithoutAssociate_Lead_HistoriesInput;
  update: LeadUpdateWithoutAssociate_Lead_HistoriesInput;
};

export type LeadUpsertWithoutAssociated_Parent_IntentsInput = {
  create: LeadCreateWithoutAssociated_Parent_IntentsInput;
  update: LeadUpdateWithoutAssociated_Parent_IntentsInput;
};

export type LeadUpsertWithoutCallMeNowAttemptsInput = {
  create: LeadCreateWithoutCallMeNowAttemptsInput;
  update: LeadUpdateWithoutCallMeNowAttemptsInput;
};

export type LeadUpsertWithoutCallMeNowsInput = {
  create: LeadCreateWithoutCallMeNowsInput;
  update: LeadUpdateWithoutCallMeNowsInput;
};

export type LeadUpsertWithoutChild_Lead_ActivitesInput = {
  create: LeadCreateWithoutChild_Lead_ActivitesInput;
  update: LeadUpdateWithoutChild_Lead_ActivitesInput;
};

export type LeadUpsertWithoutContact_RequestsInput = {
  create: LeadCreateWithoutContact_RequestsInput;
  update: LeadUpdateWithoutContact_RequestsInput;
};

export type LeadUpsertWithoutCurrent_Sale_CycleInput = {
  create: LeadCreateWithoutCurrent_Sale_CycleInput;
  update: LeadUpdateWithoutCurrent_Sale_CycleInput;
};

export type LeadUpsertWithoutCustom_FieldsInput = {
  create: LeadCreateWithoutCustom_FieldsInput;
  update: LeadUpdateWithoutCustom_FieldsInput;
};

export type LeadUpsertWithoutCustom_Queue_ItemsInput = {
  create: LeadCreateWithoutCustom_Queue_ItemsInput;
  update: LeadUpdateWithoutCustom_Queue_ItemsInput;
};

export type LeadUpsertWithoutEmailSyncRecordInput = {
  create: LeadCreateWithoutEmailSyncRecordInput;
  update: LeadUpdateWithoutEmailSyncRecordInput;
};

export type LeadUpsertWithoutEmailThreadInput = {
  create: LeadCreateWithoutEmailThreadInput;
  update: LeadUpdateWithoutEmailThreadInput;
};

export type LeadUpsertWithoutInboundConciergeEventsInput = {
  create: LeadCreateWithoutInboundConciergeEventsInput;
  update: LeadUpdateWithoutInboundConciergeEventsInput;
};

export type LeadUpsertWithoutIntegrationOperationLogInput = {
  create: LeadCreateWithoutIntegrationOperationLogInput;
  update: LeadUpdateWithoutIntegrationOperationLogInput;
};

export type LeadUpsertWithoutLead_ActivitiesInput = {
  create: LeadCreateWithoutLead_ActivitiesInput;
  update: LeadUpdateWithoutLead_ActivitiesInput;
};

export type LeadUpsertWithoutLead_Assignment_HistoryInput = {
  create: LeadCreateWithoutLead_Assignment_HistoryInput;
  update: LeadUpdateWithoutLead_Assignment_HistoryInput;
};

export type LeadUpsertWithoutLead_Custom_Row_InfoInput = {
  create: LeadCreateWithoutLead_Custom_Row_InfoInput;
  update: LeadUpdateWithoutLead_Custom_Row_InfoInput;
};

export type LeadUpsertWithoutLead_Import_HistoryInput = {
  create: LeadCreateWithoutLead_Import_HistoryInput;
  update: LeadUpdateWithoutLead_Import_HistoryInput;
};

export type LeadUpsertWithoutLead_IntentInput = {
  create: LeadCreateWithoutLead_IntentInput;
  update: LeadUpdateWithoutLead_IntentInput;
};

export type LeadUpsertWithoutLead_ValueInput = {
  create: LeadCreateWithoutLead_ValueInput;
  update: LeadUpdateWithoutLead_ValueInput;
};

export type LeadUpsertWithoutNotificationsInput = {
  create: LeadCreateWithoutNotificationsInput;
  update: LeadUpdateWithoutNotificationsInput;
};

export type LeadUpsertWithoutPrimary_Lead_HistoriesInput = {
  create: LeadCreateWithoutPrimary_Lead_HistoriesInput;
  update: LeadUpdateWithoutPrimary_Lead_HistoriesInput;
};

export type LeadUpsertWithoutRouting_HistoryInput = {
  create: LeadCreateWithoutRouting_HistoryInput;
  update: LeadUpdateWithoutRouting_HistoryInput;
};

export type LeadUpsertWithoutSale_CyclesInput = {
  create: LeadCreateWithoutSale_CyclesInput;
  update: LeadUpdateWithoutSale_CyclesInput;
};

export type LeadUpsertWithoutSaleInput = {
  create: LeadCreateWithoutSaleInput;
  update: LeadUpdateWithoutSaleInput;
};

export type LeadUpsertWithoutSequenceActionLogInput = {
  create: LeadCreateWithoutSequenceActionLogInput;
  update: LeadUpdateWithoutSequenceActionLogInput;
};

export type LeadUpsertWithoutSequenceEntryExitLeadInput = {
  create: LeadCreateWithoutSequenceEntryExitLeadInput;
  update: LeadUpdateWithoutSequenceEntryExitLeadInput;
};

export type LeadUpsertWithoutStat_ItemsInput = {
  create: LeadCreateWithoutStat_ItemsInput;
  update: LeadUpdateWithoutStat_ItemsInput;
};

export type LeadUpsertWithoutStatLeadAggregationInput = {
  create: LeadCreateWithoutStatLeadAggregationInput;
  update: LeadUpdateWithoutStatLeadAggregationInput;
};

export type LeadUpsertWithWhereUniqueWithoutAssociate_Group_Lead_ActivitiesInput = {
  create: LeadCreateWithoutAssociate_Group_Lead_ActivitiesInput;
  update: LeadUpdateWithoutAssociate_Group_Lead_ActivitiesInput;
  where: LeadWhereUniqueInput;
};

export type LeadUpsertWithWhereUniqueWithoutAssociate_Parent_LeadInput = {
  create: LeadCreateWithoutAssociate_Parent_LeadInput;
  update: LeadUpdateWithoutAssociate_Parent_LeadInput;
  where: LeadWhereUniqueInput;
};

export type LeadUpsertWithWhereUniqueWithoutFavorited_By_UsersInput = {
  create: LeadCreateWithoutFavorited_By_UsersInput;
  update: LeadUpdateWithoutFavorited_By_UsersInput;
  where: LeadWhereUniqueInput;
};

export type LeadUpsertWithWhereUniqueWithoutLast_Call_Result_RepInput = {
  create: LeadCreateWithoutLast_Call_Result_RepInput;
  update: LeadUpdateWithoutLast_Call_Result_RepInput;
  where: LeadWhereUniqueInput;
};

export type LeadUpsertWithWhereUniqueWithoutLead_Import_History_CsvInput = {
  create: LeadCreateWithoutLead_Import_History_CsvInput;
  update: LeadUpdateWithoutLead_Import_History_CsvInput;
  where: LeadWhereUniqueInput;
};

export type LeadUpsertWithWhereUniqueWithoutNext_Scheduled_EventInput = {
  create: LeadCreateWithoutNext_Scheduled_EventInput;
  update: LeadUpdateWithoutNext_Scheduled_EventInput;
  where: LeadWhereUniqueInput;
};

export type LeadUpsertWithWhereUniqueWithoutOrganizationInput = {
  create: LeadCreateWithoutOrganizationInput;
  update: LeadUpdateWithoutOrganizationInput;
  where: LeadWhereUniqueInput;
};

export type LeadUpsertWithWhereUniqueWithoutRepInput = {
  create: LeadCreateWithoutRepInput;
  update: LeadUpdateWithoutRepInput;
  where: LeadWhereUniqueInput;
};

export type LeadUpsertWithWhereUniqueWithoutRouting_RuleInput = {
  create: LeadCreateWithoutRouting_RuleInput;
  update: LeadUpdateWithoutRouting_RuleInput;
  where: LeadWhereUniqueInput;
};

export type LeadUpsertWithWhereUniqueWithoutSecondary_RepsInput = {
  create: LeadCreateWithoutSecondary_RepsInput;
  update: LeadUpdateWithoutSecondary_RepsInput;
  where: LeadWhereUniqueInput;
};

export type LeadUpsertWithWhereUniqueWithoutSequence_StepInput = {
  create: LeadCreateWithoutSequence_StepInput;
  update: LeadUpdateWithoutSequence_StepInput;
  where: LeadWhereUniqueInput;
};

export type LeadUpsertWithWhereUniqueWithoutSequenceInput = {
  create: LeadCreateWithoutSequenceInput;
  update: LeadUpdateWithoutSequenceInput;
  where: LeadWhereUniqueInput;
};

export type LeadUpsertWithWhereUniqueWithoutSibling_Lead_HistoriesInput = {
  create: LeadCreateWithoutSibling_Lead_HistoriesInput;
  update: LeadUpdateWithoutSibling_Lead_HistoriesInput;
  where: LeadWhereUniqueInput;
};

export type LeadValue = {
  __typename?: 'LeadValue';
  created_at: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  lead?: Maybe<Lead>;
  lead_id?: Maybe<Scalars['String']['output']>;
  organization: Organization;
  organization_id: Scalars['String']['output'];
  updated_at?: Maybe<Scalars['DateTime']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

export type LeadValueCreateManyOrganizationInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  lead_id?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  value?: InputMaybe<Scalars['Float']['input']>;
};

export type LeadValueCreateManyOrganizationInputEnvelope = {
  data?: InputMaybe<Array<LeadValueCreateManyOrganizationInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type LeadValueCreateNestedManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<LeadValueWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<LeadValueCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<LeadValueCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<LeadValueCreateManyOrganizationInputEnvelope>;
};

export type LeadValueCreateNestedOneWithoutLeadInput = {
  connect?: InputMaybe<LeadValueWhereUniqueInput>;
  connectOrCreate?: InputMaybe<LeadValueCreateOrConnectWithoutLeadInput>;
  create?: InputMaybe<LeadValueCreateWithoutLeadInput>;
};

export type LeadValueCreateOrConnectWithoutLeadInput = {
  create: LeadValueCreateWithoutLeadInput;
  where: LeadValueWhereUniqueInput;
};

export type LeadValueCreateOrConnectWithoutOrganizationInput = {
  create: LeadValueCreateWithoutOrganizationInput;
  where: LeadValueWhereUniqueInput;
};

export type LeadValueCreateWithoutLeadInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  organization: OrganizationCreateNestedOneWithoutLeadValueInput;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  value?: InputMaybe<Scalars['Float']['input']>;
};

export type LeadValueCreateWithoutOrganizationInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  lead?: InputMaybe<LeadCreateNestedOneWithoutLead_ValueInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  value?: InputMaybe<Scalars['Float']['input']>;
};

export type LeadValueListRelationFilter = {
  every?: InputMaybe<LeadValueWhereInput>;
  none?: InputMaybe<LeadValueWhereInput>;
  some?: InputMaybe<LeadValueWhereInput>;
};

export type LeadValueOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
  count?: InputMaybe<SortOrder>;
};

export type LeadValueOrderByWithRelationInput = {
  created_at?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  lead?: InputMaybe<LeadOrderByWithRelationInput>;
  lead_id?: InputMaybe<SortOrder>;
  organization?: InputMaybe<OrganizationOrderByWithRelationInput>;
  organization_id?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
  value?: InputMaybe<SortOrder>;
};

export type LeadValueScalarWhereInput = {
  AND?: InputMaybe<Array<LeadValueScalarWhereInput>>;
  created_at?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  lead_id?: InputMaybe<StringNullableFilter>;
  NOT?: InputMaybe<Array<LeadValueScalarWhereInput>>;
  OR?: InputMaybe<Array<LeadValueScalarWhereInput>>;
  organization_id?: InputMaybe<StringFilter>;
  updated_at?: InputMaybe<DateTimeNullableFilter>;
  value?: InputMaybe<FloatNullableFilter>;
};

export type LeadValueUpdateManyMutationInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  value?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
};

export type LeadValueUpdateManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<LeadValueWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<LeadValueCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<LeadValueCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<LeadValueCreateManyOrganizationInputEnvelope>;
  delete?: InputMaybe<Array<LeadValueWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<LeadValueScalarWhereInput>>;
  disconnect?: InputMaybe<Array<LeadValueWhereUniqueInput>>;
  set?: InputMaybe<Array<LeadValueWhereUniqueInput>>;
  update?: InputMaybe<Array<LeadValueUpdateWithWhereUniqueWithoutOrganizationInput>>;
  updateMany?: InputMaybe<Array<LeadValueUpdateManyWithWhereWithoutOrganizationInput>>;
  upsert?: InputMaybe<Array<LeadValueUpsertWithWhereUniqueWithoutOrganizationInput>>;
};

export type LeadValueUpdateManyWithWhereWithoutOrganizationInput = {
  data: LeadValueUpdateManyMutationInput;
  where: LeadValueScalarWhereInput;
};

export type LeadValueUpdateOneWithoutLeadInput = {
  connect?: InputMaybe<LeadValueWhereUniqueInput>;
  connectOrCreate?: InputMaybe<LeadValueCreateOrConnectWithoutLeadInput>;
  create?: InputMaybe<LeadValueCreateWithoutLeadInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<LeadValueUpdateWithoutLeadInput>;
  upsert?: InputMaybe<LeadValueUpsertWithoutLeadInput>;
};

export type LeadValueUpdateWithoutLeadInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutLeadValueInput>;
  updated_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  value?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
};

export type LeadValueUpdateWithoutOrganizationInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lead?: InputMaybe<LeadUpdateOneWithoutLead_ValueInput>;
  updated_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  value?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
};

export type LeadValueUpdateWithWhereUniqueWithoutOrganizationInput = {
  data: LeadValueUpdateWithoutOrganizationInput;
  where: LeadValueWhereUniqueInput;
};

export type LeadValueUpsertWithoutLeadInput = {
  create: LeadValueCreateWithoutLeadInput;
  update: LeadValueUpdateWithoutLeadInput;
};

export type LeadValueUpsertWithWhereUniqueWithoutOrganizationInput = {
  create: LeadValueCreateWithoutOrganizationInput;
  update: LeadValueUpdateWithoutOrganizationInput;
  where: LeadValueWhereUniqueInput;
};

export type LeadValueWhereInput = {
  AND?: InputMaybe<Array<LeadValueWhereInput>>;
  created_at?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  lead?: InputMaybe<LeadWhereInput>;
  lead_id?: InputMaybe<StringNullableFilter>;
  NOT?: InputMaybe<Array<LeadValueWhereInput>>;
  OR?: InputMaybe<Array<LeadValueWhereInput>>;
  organization?: InputMaybe<OrganizationWhereInput>;
  organization_id?: InputMaybe<StringFilter>;
  updated_at?: InputMaybe<DateTimeNullableFilter>;
  value?: InputMaybe<FloatNullableFilter>;
};

export type LeadValueWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  lead_id?: InputMaybe<Scalars['String']['input']>;
};

export type LeadWhereInput = {
  address?: InputMaybe<StringNullableFilter>;
  address_2?: InputMaybe<StringNullableFilter>;
  alternate_contacts?: InputMaybe<ContactListRelationFilter>;
  AND?: InputMaybe<Array<LeadWhereInput>>;
  assignment_locked?: InputMaybe<BoolFilter>;
  associate_children?: InputMaybe<LeadListRelationFilter>;
  associate_group_lead_activities?: InputMaybe<LeadActivityListRelationFilter>;
  associate_lead_histories?: InputMaybe<AssociationHistoryListRelationFilter>;
  associate_parent_id?: InputMaybe<StringNullableFilter>;
  associate_parent_lead?: InputMaybe<LeadWhereInput>;
  associated_parent_intents?: InputMaybe<LeadIntentListRelationFilter>;
  associates_search_content?: InputMaybe<StringNullableFilter>;
  business_name?: InputMaybe<StringNullableFilter>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptListRelationFilter>;
  callMeNows?: InputMaybe<CallMeNowListRelationFilter>;
  channel?: InputMaybe<EnumChannelNullableFilter>;
  child_lead_activites?: InputMaybe<LeadActivityListRelationFilter>;
  city?: InputMaybe<StringNullableFilter>;
  claim_date?: InputMaybe<DateTimeNullableFilter>;
  conference_number?: InputMaybe<StringNullableFilter>;
  contact_requests?: InputMaybe<ContactRequestListRelationFilter>;
  content?: InputMaybe<StringNullableFilter>;
  country?: InputMaybe<StringNullableFilter>;
  created_at?: InputMaybe<DateTimeFilter>;
  current_close_date?: InputMaybe<DateTimeNullableFilter>;
  current_contact_date?: InputMaybe<DateTimeNullableFilter>;
  current_sale_cycle?: InputMaybe<SaleCycleWhereInput>;
  custom_field_json?: InputMaybe<JsonNullableFilter>;
  custom_fields?: InputMaybe<CustomFieldListRelationFilter>;
  custom_queue_items?: InputMaybe<CustomQueueItemListRelationFilter>;
  delay_action_status?: InputMaybe<BoolFilter>;
  email_sub_status?: InputMaybe<EnumOptInStatusFilter>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordListRelationFilter>;
  EmailThread?: InputMaybe<EmailThreadListRelationFilter>;
  favorited_by_users?: InputMaybe<UserListRelationFilter>;
  first_demo_schedule_date?: InputMaybe<DateTimeNullableFilter>;
  first_name?: InputMaybe<StringNullableFilter>;
  hubspot_company_id?: InputMaybe<StringNullableFilter>;
  hubspot_id?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  implied_next_action?: InputMaybe<EnumHingepointFilter>;
  in_algo_queue?: InputMaybe<BoolFilter>;
  in_custom_sequence?: InputMaybe<IntFilter>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventListRelationFilter>;
  industry?: InputMaybe<StringNullableFilter>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogListRelationFilter>;
  last_call_result?: InputMaybe<StringNullableFilter>;
  last_call_result_rep?: InputMaybe<UserWhereInput>;
  last_call_result_rep_id?: InputMaybe<StringNullableFilter>;
  last_call_result_time?: InputMaybe<DateTimeNullableFilter>;
  last_lead_assignment_origin?: InputMaybe<DateTimeNullableFilter>;
  last_name?: InputMaybe<StringNullableFilter>;
  last_sent_to_resting_date?: InputMaybe<DateTimeNullableFilter>;
  lead_activities?: InputMaybe<LeadActivityListRelationFilter>;
  lead_assignment_history?: InputMaybe<LeadAssignmentHistoryListRelationFilter>;
  lead_assignment_origin?: InputMaybe<EnumAssignmentOriginFilter>;
  lead_creation_source?: InputMaybe<EnumLeadcreationsourceNullableFilter>;
  lead_custom_row_info?: InputMaybe<LeadCustomObjectRowAssociationListRelationFilter>;
  lead_import_history?: InputMaybe<LeadImportHistoryWhereInput>;
  lead_import_history_csv?: InputMaybe<LeadImportHistoryWhereInput>;
  lead_import_history_id?: InputMaybe<StringNullableFilter>;
  lead_intent?: InputMaybe<LeadIntentListRelationFilter>;
  lead_ownership_status?: InputMaybe<EnumLosFilter>;
  lead_source?: InputMaybe<StringNullableFilter>;
  lead_value?: InputMaybe<LeadValueWhereInput>;
  mrr?: InputMaybe<FloatNullableFilter>;
  newIncoming?: InputMaybe<BoolFilter>;
  next_scheduled_event?: InputMaybe<ScheduleItemWhereInput>;
  next_scheduled_event_id?: InputMaybe<StringNullableFilter>;
  NOT?: InputMaybe<Array<LeadWhereInput>>;
  notifications?: InputMaybe<NotificationListRelationFilter>;
  opsiq_whitelist?: InputMaybe<BoolNullableFilter>;
  OR?: InputMaybe<Array<LeadWhereInput>>;
  organization?: InputMaybe<OrganizationWhereInput>;
  organization_id?: InputMaybe<StringFilter>;
  phase?: InputMaybe<EnumPhaseFilter>;
  primary_email?: InputMaybe<StringNullableFilter>;
  primary_email_title?: InputMaybe<StringNullableFilter>;
  primary_email_type?: InputMaybe<StringNullableFilter>;
  primary_lead_histories?: InputMaybe<AssociationHistoryListRelationFilter>;
  primary_phone_number?: InputMaybe<StringNullableFilter>;
  primary_phone_number_country_code?: InputMaybe<StringNullableFilter>;
  primary_phone_number_title?: InputMaybe<StringNullableFilter>;
  primary_phone_number_type?: InputMaybe<StringNullableFilter>;
  rep?: InputMaybe<UserWhereInput>;
  rep_id?: InputMaybe<StringNullableFilter>;
  resting_type?: InputMaybe<EnumRestingtypeNullableFilter>;
  routing_history?: InputMaybe<RoutingHistoryListRelationFilter>;
  routing_rule?: InputMaybe<RuleWhereInput>;
  routing_rule_id?: InputMaybe<StringNullableFilter>;
  Sale?: InputMaybe<SaleListRelationFilter>;
  sale_cycles?: InputMaybe<SaleCycleListRelationFilter>;
  saleCycleCycle_lead_id?: InputMaybe<StringNullableFilter>;
  saleCycleCycle_num?: InputMaybe<IntNullableFilter>;
  search_content?: InputMaybe<StringNullableFilter>;
  secondary_reps?: InputMaybe<UserListRelationFilter>;
  self_sourced?: InputMaybe<BoolNullableFilter>;
  sequence?: InputMaybe<SequenceWhereInput>;
  sequence_id?: InputMaybe<StringNullableFilter>;
  sequence_step?: InputMaybe<SequenceStepWhereInput>;
  sequence_step_id?: InputMaybe<StringNullableFilter>;
  SequenceActionLog?: InputMaybe<StepActionLogListRelationFilter>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadListRelationFilter>;
  sequences_completed_ids?: InputMaybe<StringNullableListFilter>;
  sequences_joined_ids?: InputMaybe<StringNullableListFilter>;
  sf_account_id?: InputMaybe<StringNullableFilter>;
  sf_contact_id?: InputMaybe<StringNullableFilter>;
  sf_lead_id?: InputMaybe<StringNullableFilter>;
  sibling_lead_histories?: InputMaybe<AssociationHistoryListRelationFilter>;
  sms_sub_status?: InputMaybe<EnumOptInStatusFilter>;
  stat_items?: InputMaybe<StatItemAggregationListRelationFilter>;
  state?: InputMaybe<StringNullableFilter>;
  StatLeadAggregation?: InputMaybe<StatLeadAggregationWhereInput>;
  status?: InputMaybe<StringNullableFilter>;
  sub_industry?: InputMaybe<StringNullableFilter>;
  timezone?: InputMaybe<StringNullableFilter>;
  title?: InputMaybe<StringNullableFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
  zip?: InputMaybe<StringNullableFilter>;
};

export type LeadWhereUniqueInput = {
  hubspot_id_organization_id?: InputMaybe<LeadHubspot_IdOrganization_IdCompoundUniqueInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  saleCycleCycle_num_saleCycleCycle_lead_id?: InputMaybe<LeadSaleCycleCycle_NumSaleCycleCycle_Lead_IdCompoundUniqueInput>;
  sf_contact_id_organization_id?: InputMaybe<LeadSf_Contact_IdOrganization_IdCompoundUniqueInput>;
  sf_lead_id_organization_id?: InputMaybe<LeadSf_Lead_IdOrganization_IdCompoundUniqueInput>;
};

export enum LfmType {
  Channel = 'Channel',
  CustomField = 'CustomField',
  Industry = 'Industry',
  LeadSource = 'LeadSource'
}

export type LifeTimeValueMultiplier = {
  __typename?: 'LifeTimeValueMultiplier';
  computed_rate: Scalars['Float']['output'];
  default_rate: Scalars['Float']['output'];
  field: Scalars['String']['output'];
  organization: Organization;
  organization_id: Scalars['String']['output'];
  type: LfmType;
  use_default: Scalars['Boolean']['output'];
};

export type LifeTimeValueMultiplierCreateManyOrganizationInput = {
  computed_rate: Scalars['Float']['input'];
  default_rate: Scalars['Float']['input'];
  field: Scalars['String']['input'];
  type: LfmType;
  use_default?: InputMaybe<Scalars['Boolean']['input']>;
};

export type LifeTimeValueMultiplierCreateManyOrganizationInputEnvelope = {
  data?: InputMaybe<Array<LifeTimeValueMultiplierCreateManyOrganizationInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type LifeTimeValueMultiplierCreateNestedManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<LifeTimeValueMultiplierWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<LifeTimeValueMultiplierCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<LifeTimeValueMultiplierCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<LifeTimeValueMultiplierCreateManyOrganizationInputEnvelope>;
};

export type LifeTimeValueMultiplierCreateOrConnectWithoutOrganizationInput = {
  create: LifeTimeValueMultiplierCreateWithoutOrganizationInput;
  where: LifeTimeValueMultiplierWhereUniqueInput;
};

export type LifeTimeValueMultiplierCreateWithoutOrganizationInput = {
  computed_rate: Scalars['Float']['input'];
  default_rate: Scalars['Float']['input'];
  field: Scalars['String']['input'];
  type: LfmType;
  use_default?: InputMaybe<Scalars['Boolean']['input']>;
};

export type LifeTimeValueMultiplierCustomCreateManyOrganizationInput = {
  computed_rate: Scalars['Float']['input'];
  default_rate: Scalars['Float']['input'];
  dropdown_value: Scalars['String']['input'];
  field: Scalars['String']['input'];
  use_default?: InputMaybe<Scalars['Boolean']['input']>;
};

export type LifeTimeValueMultiplierCustomCreateManyOrganizationInputEnvelope = {
  data?: InputMaybe<Array<LifeTimeValueMultiplierCustomCreateManyOrganizationInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type LifeTimeValueMultiplierCustomCreateNestedManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<LifeTimeValueMultiplierCustomWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<LifeTimeValueMultiplierCustomCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<LifeTimeValueMultiplierCustomCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<LifeTimeValueMultiplierCustomCreateManyOrganizationInputEnvelope>;
};

export type LifeTimeValueMultiplierCustomCreateOrConnectWithoutOrganizationInput = {
  create: LifeTimeValueMultiplierCustomCreateWithoutOrganizationInput;
  where: LifeTimeValueMultiplierCustomWhereUniqueInput;
};

export type LifeTimeValueMultiplierCustomCreateWithoutOrganizationInput = {
  computed_rate: Scalars['Float']['input'];
  default_rate: Scalars['Float']['input'];
  dropdown_value: Scalars['String']['input'];
  field: Scalars['String']['input'];
  use_default?: InputMaybe<Scalars['Boolean']['input']>;
};

export type LifeTimeValueMultiplierCustomLft_Custom_IdCompoundUniqueInput = {
  dropdown_value: Scalars['String']['input'];
  field: Scalars['String']['input'];
  organization_id: Scalars['String']['input'];
};

export type LifeTimeValueMultiplierCustomListRelationFilter = {
  every?: InputMaybe<LifeTimeValueMultiplierCustomWhereInput>;
  none?: InputMaybe<LifeTimeValueMultiplierCustomWhereInput>;
  some?: InputMaybe<LifeTimeValueMultiplierCustomWhereInput>;
};

export type LifeTimeValueMultiplierCustomOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
  count?: InputMaybe<SortOrder>;
};

export type LifeTimeValueMultiplierCustomScalarWhereInput = {
  AND?: InputMaybe<Array<LifeTimeValueMultiplierCustomScalarWhereInput>>;
  computed_rate?: InputMaybe<FloatFilter>;
  default_rate?: InputMaybe<FloatFilter>;
  dropdown_value?: InputMaybe<StringFilter>;
  field?: InputMaybe<StringFilter>;
  NOT?: InputMaybe<Array<LifeTimeValueMultiplierCustomScalarWhereInput>>;
  OR?: InputMaybe<Array<LifeTimeValueMultiplierCustomScalarWhereInput>>;
  organization_id?: InputMaybe<StringFilter>;
  use_default?: InputMaybe<BoolFilter>;
};

export type LifeTimeValueMultiplierCustomUpdateManyMutationInput = {
  computed_rate?: InputMaybe<FloatFieldUpdateOperationsInput>;
  default_rate?: InputMaybe<FloatFieldUpdateOperationsInput>;
  dropdown_value?: InputMaybe<StringFieldUpdateOperationsInput>;
  field?: InputMaybe<StringFieldUpdateOperationsInput>;
  use_default?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type LifeTimeValueMultiplierCustomUpdateManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<LifeTimeValueMultiplierCustomWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<LifeTimeValueMultiplierCustomCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<LifeTimeValueMultiplierCustomCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<LifeTimeValueMultiplierCustomCreateManyOrganizationInputEnvelope>;
  delete?: InputMaybe<Array<LifeTimeValueMultiplierCustomWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<LifeTimeValueMultiplierCustomScalarWhereInput>>;
  disconnect?: InputMaybe<Array<LifeTimeValueMultiplierCustomWhereUniqueInput>>;
  set?: InputMaybe<Array<LifeTimeValueMultiplierCustomWhereUniqueInput>>;
  update?: InputMaybe<Array<LifeTimeValueMultiplierCustomUpdateWithWhereUniqueWithoutOrganizationInput>>;
  updateMany?: InputMaybe<Array<LifeTimeValueMultiplierCustomUpdateManyWithWhereWithoutOrganizationInput>>;
  upsert?: InputMaybe<Array<LifeTimeValueMultiplierCustomUpsertWithWhereUniqueWithoutOrganizationInput>>;
};

export type LifeTimeValueMultiplierCustomUpdateManyWithWhereWithoutOrganizationInput = {
  data: LifeTimeValueMultiplierCustomUpdateManyMutationInput;
  where: LifeTimeValueMultiplierCustomScalarWhereInput;
};

export type LifeTimeValueMultiplierCustomUpdateWithoutOrganizationInput = {
  computed_rate?: InputMaybe<FloatFieldUpdateOperationsInput>;
  default_rate?: InputMaybe<FloatFieldUpdateOperationsInput>;
  dropdown_value?: InputMaybe<StringFieldUpdateOperationsInput>;
  field?: InputMaybe<StringFieldUpdateOperationsInput>;
  use_default?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type LifeTimeValueMultiplierCustomUpdateWithWhereUniqueWithoutOrganizationInput = {
  data: LifeTimeValueMultiplierCustomUpdateWithoutOrganizationInput;
  where: LifeTimeValueMultiplierCustomWhereUniqueInput;
};

export type LifeTimeValueMultiplierCustomUpsertWithWhereUniqueWithoutOrganizationInput = {
  create: LifeTimeValueMultiplierCustomCreateWithoutOrganizationInput;
  update: LifeTimeValueMultiplierCustomUpdateWithoutOrganizationInput;
  where: LifeTimeValueMultiplierCustomWhereUniqueInput;
};

export type LifeTimeValueMultiplierCustomWhereInput = {
  AND?: InputMaybe<Array<LifeTimeValueMultiplierCustomWhereInput>>;
  computed_rate?: InputMaybe<FloatFilter>;
  default_rate?: InputMaybe<FloatFilter>;
  dropdown_value?: InputMaybe<StringFilter>;
  field?: InputMaybe<StringFilter>;
  NOT?: InputMaybe<Array<LifeTimeValueMultiplierCustomWhereInput>>;
  OR?: InputMaybe<Array<LifeTimeValueMultiplierCustomWhereInput>>;
  organization?: InputMaybe<OrganizationWhereInput>;
  organization_id?: InputMaybe<StringFilter>;
  use_default?: InputMaybe<BoolFilter>;
};

export type LifeTimeValueMultiplierCustomWhereUniqueInput = {
  lft_custom_id?: InputMaybe<LifeTimeValueMultiplierCustomLft_Custom_IdCompoundUniqueInput>;
};

export type LifeTimeValueMultiplierLft_IdCompoundUniqueInput = {
  field: Scalars['String']['input'];
  organization_id: Scalars['String']['input'];
  type: LfmType;
};

export type LifeTimeValueMultiplierListRelationFilter = {
  every?: InputMaybe<LifeTimeValueMultiplierWhereInput>;
  none?: InputMaybe<LifeTimeValueMultiplierWhereInput>;
  some?: InputMaybe<LifeTimeValueMultiplierWhereInput>;
};

export type LifeTimeValueMultiplierOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
  count?: InputMaybe<SortOrder>;
};

export type LifeTimeValueMultiplierScalarWhereInput = {
  AND?: InputMaybe<Array<LifeTimeValueMultiplierScalarWhereInput>>;
  computed_rate?: InputMaybe<FloatFilter>;
  default_rate?: InputMaybe<FloatFilter>;
  field?: InputMaybe<StringFilter>;
  NOT?: InputMaybe<Array<LifeTimeValueMultiplierScalarWhereInput>>;
  OR?: InputMaybe<Array<LifeTimeValueMultiplierScalarWhereInput>>;
  organization_id?: InputMaybe<StringFilter>;
  type?: InputMaybe<EnumLfmTypeFilter>;
  use_default?: InputMaybe<BoolFilter>;
};

export type LifeTimeValueMultiplierUpdateManyMutationInput = {
  computed_rate?: InputMaybe<FloatFieldUpdateOperationsInput>;
  default_rate?: InputMaybe<FloatFieldUpdateOperationsInput>;
  field?: InputMaybe<StringFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumLfmTypeFieldUpdateOperationsInput>;
  use_default?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type LifeTimeValueMultiplierUpdateManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<LifeTimeValueMultiplierWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<LifeTimeValueMultiplierCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<LifeTimeValueMultiplierCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<LifeTimeValueMultiplierCreateManyOrganizationInputEnvelope>;
  delete?: InputMaybe<Array<LifeTimeValueMultiplierWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<LifeTimeValueMultiplierScalarWhereInput>>;
  disconnect?: InputMaybe<Array<LifeTimeValueMultiplierWhereUniqueInput>>;
  set?: InputMaybe<Array<LifeTimeValueMultiplierWhereUniqueInput>>;
  update?: InputMaybe<Array<LifeTimeValueMultiplierUpdateWithWhereUniqueWithoutOrganizationInput>>;
  updateMany?: InputMaybe<Array<LifeTimeValueMultiplierUpdateManyWithWhereWithoutOrganizationInput>>;
  upsert?: InputMaybe<Array<LifeTimeValueMultiplierUpsertWithWhereUniqueWithoutOrganizationInput>>;
};

export type LifeTimeValueMultiplierUpdateManyWithWhereWithoutOrganizationInput = {
  data: LifeTimeValueMultiplierUpdateManyMutationInput;
  where: LifeTimeValueMultiplierScalarWhereInput;
};

export type LifeTimeValueMultiplierUpdateWithoutOrganizationInput = {
  computed_rate?: InputMaybe<FloatFieldUpdateOperationsInput>;
  default_rate?: InputMaybe<FloatFieldUpdateOperationsInput>;
  field?: InputMaybe<StringFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumLfmTypeFieldUpdateOperationsInput>;
  use_default?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type LifeTimeValueMultiplierUpdateWithWhereUniqueWithoutOrganizationInput = {
  data: LifeTimeValueMultiplierUpdateWithoutOrganizationInput;
  where: LifeTimeValueMultiplierWhereUniqueInput;
};

export type LifeTimeValueMultiplierUpsertWithWhereUniqueWithoutOrganizationInput = {
  create: LifeTimeValueMultiplierCreateWithoutOrganizationInput;
  update: LifeTimeValueMultiplierUpdateWithoutOrganizationInput;
  where: LifeTimeValueMultiplierWhereUniqueInput;
};

export type LifeTimeValueMultiplierWhereInput = {
  AND?: InputMaybe<Array<LifeTimeValueMultiplierWhereInput>>;
  computed_rate?: InputMaybe<FloatFilter>;
  default_rate?: InputMaybe<FloatFilter>;
  field?: InputMaybe<StringFilter>;
  NOT?: InputMaybe<Array<LifeTimeValueMultiplierWhereInput>>;
  OR?: InputMaybe<Array<LifeTimeValueMultiplierWhereInput>>;
  organization?: InputMaybe<OrganizationWhereInput>;
  organization_id?: InputMaybe<StringFilter>;
  type?: InputMaybe<EnumLfmTypeFilter>;
  use_default?: InputMaybe<BoolFilter>;
};

export type LifeTimeValueMultiplierWhereUniqueInput = {
  lft_id?: InputMaybe<LifeTimeValueMultiplierLft_IdCompoundUniqueInput>;
};

export type LiveTransferSetting = {
  __typename?: 'LiveTransferSetting';
  ae_transfer_to_ae: Scalars['Boolean']['output'];
  ae_transfer_to_sdr: Scalars['Boolean']['output'];
  created_at: Scalars['DateTime']['output'];
  dial_duration: Scalars['Int']['output'];
  hold_music_options: BgMusicOption;
  hold_music_url?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  max_cycle: Scalars['Int']['output'];
  organization: Organization;
  organization_id: Scalars['String']['output'];
  sdr_transfer_to_sdr: Scalars['Boolean']['output'];
  transfer_type: TransferType;
  updated_at: Scalars['DateTime']['output'];
};

export type LiveTransferSettingCreateNestedOneWithoutOrganizationInput = {
  connect?: InputMaybe<LiveTransferSettingWhereUniqueInput>;
  connectOrCreate?: InputMaybe<LiveTransferSettingCreateOrConnectWithoutOrganizationInput>;
  create?: InputMaybe<LiveTransferSettingCreateWithoutOrganizationInput>;
};

export type LiveTransferSettingCreateOrConnectWithoutOrganizationInput = {
  create: LiveTransferSettingCreateWithoutOrganizationInput;
  where: LiveTransferSettingWhereUniqueInput;
};

export type LiveTransferSettingCreateWithoutOrganizationInput = {
  ae_transfer_to_ae?: InputMaybe<Scalars['Boolean']['input']>;
  ae_transfer_to_sdr?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  dial_duration?: InputMaybe<Scalars['Int']['input']>;
  hold_music_options?: InputMaybe<BgMusicOption>;
  hold_music_url?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  max_cycle?: InputMaybe<Scalars['Int']['input']>;
  sdr_transfer_to_sdr?: InputMaybe<Scalars['Boolean']['input']>;
  transfer_type?: InputMaybe<TransferType>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

/** LiveTransferSetting Input for create / update */
export type LiveTransferSettingInput = {
  ae_transfer_to_ae?: InputMaybe<Scalars['Boolean']['input']>;
  ae_transfer_to_sdr?: InputMaybe<Scalars['Boolean']['input']>;
  dial_duration?: InputMaybe<Scalars['Int']['input']>;
  hold_music_options?: InputMaybe<BgMusicOption>;
  hold_music_url?: InputMaybe<Scalars['String']['input']>;
  max_cycle?: InputMaybe<Scalars['Int']['input']>;
  sdr_transfer_to_sdr?: InputMaybe<Scalars['Boolean']['input']>;
  transfer_type?: InputMaybe<TransferType>;
};

export type LiveTransferSettingOrderByWithRelationInput = {
  ae_transfer_to_ae?: InputMaybe<SortOrder>;
  ae_transfer_to_sdr?: InputMaybe<SortOrder>;
  created_at?: InputMaybe<SortOrder>;
  dial_duration?: InputMaybe<SortOrder>;
  hold_music_options?: InputMaybe<SortOrder>;
  hold_music_url?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  max_cycle?: InputMaybe<SortOrder>;
  organization?: InputMaybe<OrganizationOrderByWithRelationInput>;
  organization_id?: InputMaybe<SortOrder>;
  sdr_transfer_to_sdr?: InputMaybe<SortOrder>;
  transfer_type?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
};

export type LiveTransferSettingUpdateOneWithoutOrganizationInput = {
  connect?: InputMaybe<LiveTransferSettingWhereUniqueInput>;
  connectOrCreate?: InputMaybe<LiveTransferSettingCreateOrConnectWithoutOrganizationInput>;
  create?: InputMaybe<LiveTransferSettingCreateWithoutOrganizationInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<LiveTransferSettingUpdateWithoutOrganizationInput>;
  upsert?: InputMaybe<LiveTransferSettingUpsertWithoutOrganizationInput>;
};

export type LiveTransferSettingUpdateWithoutOrganizationInput = {
  ae_transfer_to_ae?: InputMaybe<BoolFieldUpdateOperationsInput>;
  ae_transfer_to_sdr?: InputMaybe<BoolFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  dial_duration?: InputMaybe<IntFieldUpdateOperationsInput>;
  hold_music_options?: InputMaybe<EnumBgMusicOptionFieldUpdateOperationsInput>;
  hold_music_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  max_cycle?: InputMaybe<IntFieldUpdateOperationsInput>;
  sdr_transfer_to_sdr?: InputMaybe<BoolFieldUpdateOperationsInput>;
  transfer_type?: InputMaybe<EnumTransferTypeFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type LiveTransferSettingUpsertWithoutOrganizationInput = {
  create: LiveTransferSettingCreateWithoutOrganizationInput;
  update: LiveTransferSettingUpdateWithoutOrganizationInput;
};

export type LiveTransferSettingWhereInput = {
  ae_transfer_to_ae?: InputMaybe<BoolFilter>;
  ae_transfer_to_sdr?: InputMaybe<BoolFilter>;
  AND?: InputMaybe<Array<LiveTransferSettingWhereInput>>;
  created_at?: InputMaybe<DateTimeFilter>;
  dial_duration?: InputMaybe<IntFilter>;
  hold_music_options?: InputMaybe<EnumBgMusicOptionFilter>;
  hold_music_url?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  max_cycle?: InputMaybe<IntFilter>;
  NOT?: InputMaybe<Array<LiveTransferSettingWhereInput>>;
  OR?: InputMaybe<Array<LiveTransferSettingWhereInput>>;
  organization?: InputMaybe<OrganizationWhereInput>;
  organization_id?: InputMaybe<StringFilter>;
  sdr_transfer_to_sdr?: InputMaybe<BoolFilter>;
  transfer_type?: InputMaybe<EnumTransferTypeFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
};

export type LiveTransferSettingWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  organization_id?: InputMaybe<Scalars['String']['input']>;
};

export type LiveUserStatus = {
  __typename?: 'LiveUserStatus';
  activity_type?: Maybe<Scalars['String']['output']>;
  conference?: Maybe<Conference>;
  conference_id?: Maybe<Scalars['String']['output']>;
  last_updated_hours?: Maybe<Scalars['Float']['output']>;
  organization: Organization;
  organization_id: Scalars['String']['output'];
  status: Status;
  updated_at: Scalars['DateTime']['output'];
  user: User;
  user_id: Scalars['String']['output'];
};

export type LiveUserStatusCreateManyConferenceInput = {
  activity_type?: InputMaybe<Scalars['String']['input']>;
  organization_id: Scalars['String']['input'];
  status?: InputMaybe<Status>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user_id: Scalars['String']['input'];
};

export type LiveUserStatusCreateManyConferenceInputEnvelope = {
  data?: InputMaybe<Array<LiveUserStatusCreateManyConferenceInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type LiveUserStatusCreateManyOrganizationInput = {
  activity_type?: InputMaybe<Scalars['String']['input']>;
  conference_id?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Status>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user_id: Scalars['String']['input'];
};

export type LiveUserStatusCreateManyOrganizationInputEnvelope = {
  data?: InputMaybe<Array<LiveUserStatusCreateManyOrganizationInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type LiveUserStatusCreateManyUserInput = {
  activity_type?: InputMaybe<Scalars['String']['input']>;
  conference_id?: InputMaybe<Scalars['String']['input']>;
  organization_id: Scalars['String']['input'];
  status?: InputMaybe<Status>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type LiveUserStatusCreateManyUserInputEnvelope = {
  data?: InputMaybe<Array<LiveUserStatusCreateManyUserInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type LiveUserStatusCreateNestedManyWithoutConferenceInput = {
  connect?: InputMaybe<Array<LiveUserStatusWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<LiveUserStatusCreateOrConnectWithoutConferenceInput>>;
  create?: InputMaybe<Array<LiveUserStatusCreateWithoutConferenceInput>>;
  createMany?: InputMaybe<LiveUserStatusCreateManyConferenceInputEnvelope>;
};

export type LiveUserStatusCreateNestedManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<LiveUserStatusWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<LiveUserStatusCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<LiveUserStatusCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<LiveUserStatusCreateManyOrganizationInputEnvelope>;
};

export type LiveUserStatusCreateNestedManyWithoutUserInput = {
  connect?: InputMaybe<Array<LiveUserStatusWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<LiveUserStatusCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<LiveUserStatusCreateWithoutUserInput>>;
  createMany?: InputMaybe<LiveUserStatusCreateManyUserInputEnvelope>;
};

export type LiveUserStatusCreateOrConnectWithoutConferenceInput = {
  create: LiveUserStatusCreateWithoutConferenceInput;
  where: LiveUserStatusWhereUniqueInput;
};

export type LiveUserStatusCreateOrConnectWithoutOrganizationInput = {
  create: LiveUserStatusCreateWithoutOrganizationInput;
  where: LiveUserStatusWhereUniqueInput;
};

export type LiveUserStatusCreateOrConnectWithoutUserInput = {
  create: LiveUserStatusCreateWithoutUserInput;
  where: LiveUserStatusWhereUniqueInput;
};

export type LiveUserStatusCreateWithoutConferenceInput = {
  activity_type?: InputMaybe<Scalars['String']['input']>;
  organization: OrganizationCreateNestedOneWithoutLiveUserStatusInput;
  status?: InputMaybe<Status>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user: UserCreateNestedOneWithoutLiveUserStatusInput;
};

export type LiveUserStatusCreateWithoutOrganizationInput = {
  activity_type?: InputMaybe<Scalars['String']['input']>;
  conference?: InputMaybe<ConferenceCreateNestedOneWithoutLiveUserStatusInput>;
  status?: InputMaybe<Status>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user: UserCreateNestedOneWithoutLiveUserStatusInput;
};

export type LiveUserStatusCreateWithoutUserInput = {
  activity_type?: InputMaybe<Scalars['String']['input']>;
  conference?: InputMaybe<ConferenceCreateNestedOneWithoutLiveUserStatusInput>;
  organization: OrganizationCreateNestedOneWithoutLiveUserStatusInput;
  status?: InputMaybe<Status>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type LiveUserStatusListRelationFilter = {
  every?: InputMaybe<LiveUserStatusWhereInput>;
  none?: InputMaybe<LiveUserStatusWhereInput>;
  some?: InputMaybe<LiveUserStatusWhereInput>;
};

export type LiveUserStatusOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
  count?: InputMaybe<SortOrder>;
};

export type LiveUserStatusScalarWhereInput = {
  activity_type?: InputMaybe<StringNullableFilter>;
  AND?: InputMaybe<Array<LiveUserStatusScalarWhereInput>>;
  conference_id?: InputMaybe<StringNullableFilter>;
  NOT?: InputMaybe<Array<LiveUserStatusScalarWhereInput>>;
  OR?: InputMaybe<Array<LiveUserStatusScalarWhereInput>>;
  organization_id?: InputMaybe<StringFilter>;
  status?: InputMaybe<EnumStatusFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
  user_id?: InputMaybe<StringFilter>;
};

export type LiveUserStatusUpdateManyMutationInput = {
  activity_type?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type LiveUserStatusUpdateManyWithoutConferenceInput = {
  connect?: InputMaybe<Array<LiveUserStatusWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<LiveUserStatusCreateOrConnectWithoutConferenceInput>>;
  create?: InputMaybe<Array<LiveUserStatusCreateWithoutConferenceInput>>;
  createMany?: InputMaybe<LiveUserStatusCreateManyConferenceInputEnvelope>;
  delete?: InputMaybe<Array<LiveUserStatusWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<LiveUserStatusScalarWhereInput>>;
  disconnect?: InputMaybe<Array<LiveUserStatusWhereUniqueInput>>;
  set?: InputMaybe<Array<LiveUserStatusWhereUniqueInput>>;
  update?: InputMaybe<Array<LiveUserStatusUpdateWithWhereUniqueWithoutConferenceInput>>;
  updateMany?: InputMaybe<Array<LiveUserStatusUpdateManyWithWhereWithoutConferenceInput>>;
  upsert?: InputMaybe<Array<LiveUserStatusUpsertWithWhereUniqueWithoutConferenceInput>>;
};

export type LiveUserStatusUpdateManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<LiveUserStatusWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<LiveUserStatusCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<LiveUserStatusCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<LiveUserStatusCreateManyOrganizationInputEnvelope>;
  delete?: InputMaybe<Array<LiveUserStatusWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<LiveUserStatusScalarWhereInput>>;
  disconnect?: InputMaybe<Array<LiveUserStatusWhereUniqueInput>>;
  set?: InputMaybe<Array<LiveUserStatusWhereUniqueInput>>;
  update?: InputMaybe<Array<LiveUserStatusUpdateWithWhereUniqueWithoutOrganizationInput>>;
  updateMany?: InputMaybe<Array<LiveUserStatusUpdateManyWithWhereWithoutOrganizationInput>>;
  upsert?: InputMaybe<Array<LiveUserStatusUpsertWithWhereUniqueWithoutOrganizationInput>>;
};

export type LiveUserStatusUpdateManyWithoutUserInput = {
  connect?: InputMaybe<Array<LiveUserStatusWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<LiveUserStatusCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<LiveUserStatusCreateWithoutUserInput>>;
  createMany?: InputMaybe<LiveUserStatusCreateManyUserInputEnvelope>;
  delete?: InputMaybe<Array<LiveUserStatusWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<LiveUserStatusScalarWhereInput>>;
  disconnect?: InputMaybe<Array<LiveUserStatusWhereUniqueInput>>;
  set?: InputMaybe<Array<LiveUserStatusWhereUniqueInput>>;
  update?: InputMaybe<Array<LiveUserStatusUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: InputMaybe<Array<LiveUserStatusUpdateManyWithWhereWithoutUserInput>>;
  upsert?: InputMaybe<Array<LiveUserStatusUpsertWithWhereUniqueWithoutUserInput>>;
};

export type LiveUserStatusUpdateManyWithWhereWithoutConferenceInput = {
  data: LiveUserStatusUpdateManyMutationInput;
  where: LiveUserStatusScalarWhereInput;
};

export type LiveUserStatusUpdateManyWithWhereWithoutOrganizationInput = {
  data: LiveUserStatusUpdateManyMutationInput;
  where: LiveUserStatusScalarWhereInput;
};

export type LiveUserStatusUpdateManyWithWhereWithoutUserInput = {
  data: LiveUserStatusUpdateManyMutationInput;
  where: LiveUserStatusScalarWhereInput;
};

export type LiveUserStatusUpdateWithoutConferenceInput = {
  activity_type?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutLiveUserStatusInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutLiveUserStatusInput>;
};

export type LiveUserStatusUpdateWithoutOrganizationInput = {
  activity_type?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  conference?: InputMaybe<ConferenceUpdateOneWithoutLiveUserStatusInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutLiveUserStatusInput>;
};

export type LiveUserStatusUpdateWithoutUserInput = {
  activity_type?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  conference?: InputMaybe<ConferenceUpdateOneWithoutLiveUserStatusInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutLiveUserStatusInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type LiveUserStatusUpdateWithWhereUniqueWithoutConferenceInput = {
  data: LiveUserStatusUpdateWithoutConferenceInput;
  where: LiveUserStatusWhereUniqueInput;
};

export type LiveUserStatusUpdateWithWhereUniqueWithoutOrganizationInput = {
  data: LiveUserStatusUpdateWithoutOrganizationInput;
  where: LiveUserStatusWhereUniqueInput;
};

export type LiveUserStatusUpdateWithWhereUniqueWithoutUserInput = {
  data: LiveUserStatusUpdateWithoutUserInput;
  where: LiveUserStatusWhereUniqueInput;
};

export type LiveUserStatusUpsertWithWhereUniqueWithoutConferenceInput = {
  create: LiveUserStatusCreateWithoutConferenceInput;
  update: LiveUserStatusUpdateWithoutConferenceInput;
  where: LiveUserStatusWhereUniqueInput;
};

export type LiveUserStatusUpsertWithWhereUniqueWithoutOrganizationInput = {
  create: LiveUserStatusCreateWithoutOrganizationInput;
  update: LiveUserStatusUpdateWithoutOrganizationInput;
  where: LiveUserStatusWhereUniqueInput;
};

export type LiveUserStatusUpsertWithWhereUniqueWithoutUserInput = {
  create: LiveUserStatusCreateWithoutUserInput;
  update: LiveUserStatusUpdateWithoutUserInput;
  where: LiveUserStatusWhereUniqueInput;
};

export type LiveUserStatusWhereInput = {
  activity_type?: InputMaybe<StringNullableFilter>;
  AND?: InputMaybe<Array<LiveUserStatusWhereInput>>;
  conference?: InputMaybe<ConferenceWhereInput>;
  conference_id?: InputMaybe<StringNullableFilter>;
  NOT?: InputMaybe<Array<LiveUserStatusWhereInput>>;
  OR?: InputMaybe<Array<LiveUserStatusWhereInput>>;
  organization?: InputMaybe<OrganizationWhereInput>;
  organization_id?: InputMaybe<StringFilter>;
  status?: InputMaybe<EnumStatusFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserWhereInput>;
  user_id?: InputMaybe<StringFilter>;
};

export type LiveUserStatusWhereUniqueInput = {
  user_id?: InputMaybe<Scalars['String']['input']>;
};

export enum Los {
  Assigned = 'Assigned',
  Customer = 'Customer',
  Owned = 'Owned',
  Resting = 'Resting',
  Retired = 'Retired',
  Unassigned = 'Unassigned'
}

/** ManualTransferRepData */
export type ManualTransferRepData = {
  __typename?: 'ManualTransferRepData';
  close_rate?: Maybe<Scalars['Float']['output']>;
  first_name?: Maybe<Scalars['String']['output']>;
  last_name?: Maybe<Scalars['String']['output']>;
  organization_id?: Maybe<Scalars['String']['output']>;
  profile_image?: Maybe<Scalars['String']['output']>;
  role?: Maybe<Scalars['String']['output']>;
  site_id?: Maybe<Scalars['String']['output']>;
  site_name?: Maybe<Scalars['String']['output']>;
  status: Status;
  status_label?: Maybe<Scalars['String']['output']>;
  team_id?: Maybe<Scalars['String']['output']>;
  team_name?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['DateTime']['output']>;
  user_id?: Maybe<Scalars['String']['output']>;
};

export enum Mapping_Type {
  CustomField = 'CustomField',
  Static = 'Static',
  SystemField = 'SystemField'
}

export enum MeasureByFilter {
  SetUserId = 'set_user_id',
  UserId = 'user_id'
}

export enum Metric {
  ActualPercentToPlan = 'ActualPercentToPlan',
  AllHolds = 'AllHolds',
  AllRestingPostDemoSet = 'AllRestingPostDemoSet',
  AllSales = 'AllSales',
  AllSets = 'AllSets',
  AverageTimeBetweenInitialHoldAndScheduledDecision = 'AverageTimeBetweenInitialHoldAndScheduledDecision',
  AvgAeDialedBeforeAnswer = 'AvgAEDialedBeforeAnswer',
  AvgCloseRate = 'AvgCloseRate',
  AvgDialsToSetForEachLead = 'AvgDialsToSetForEachLead',
  AvgHoldPerTransfer = 'AvgHoldPerTransfer',
  AvgLiveTransferCloseRate = 'AvgLiveTransferCloseRate',
  AvgLiveTransferHoldsOverTransfers = 'AvgLiveTransferHoldsOverTransfers',
  AvgLiveTransferSalesOverTransfers = 'AvgLiveTransferSalesOverTransfers',
  AvgRingTimeAeAnswer = 'AvgRingTimeAEAnswer',
  AvgSalePerTransfer = 'AvgSalePerTransfer',
  AvgTimeBetweenDials = 'AvgTimeBetweenDials',
  AvgTimeToFirstDial = 'AvgTimeToFirstDial',
  BadNumbers = 'BadNumbers',
  CallCoachingRequests = 'CallCoachingRequests',
  CcPerHour = 'CCPerHour',
  CcPerRepPerDay = 'CCPerRepPerDay',
  CcPerRepPerHour = 'CCPerRepPerHour',
  CcPerSet = 'CCPerSet',
  CloseRate = 'CloseRate',
  CloseRateLiveTransfer = 'CloseRateLiveTransfer',
  CloseRateSdrDemo = 'CloseRateSDRDemo',
  ClosingOpportunitiesHeld = 'ClosingOpportunitiesHeld',
  ClosingOpportunitiesPipeline = 'ClosingOpportunitiesPipeline',
  ClosingOpportunitiesScheduled = 'ClosingOpportunitiesScheduled',
  ClosingOpportunitiesScheduledRate = 'ClosingOpportunitiesScheduledRate',
  ColdCalls = 'ColdCalls',
  ConnectRate = 'ConnectRate',
  Connects = 'Connects',
  CustomerNonSale = 'CustomerNonSale',
  Days = 'Days',
  DaysSetOut = 'DaysSetOut',
  DaysSinceLastDmContact = 'DaysSinceLastDMContact',
  DaysSinceLastHold = 'DaysSinceLastHold',
  DaysSinceLastSale = 'DaysSinceLastSale',
  DaysSinceLastSet = 'DaysSinceLastSet',
  DecisionCall = 'DecisionCall',
  DecisionCallHeld = 'DecisionCallHeld',
  Demo = 'Demo',
  DemoFlaked = 'DemoFlaked',
  DemoFollowUp = 'DemoFollowUp',
  DemoHeld = 'DemoHeld',
  DemoHeldNoScheduledAction = 'DemoHeldNoScheduledAction',
  DemoRescheduled = 'DemoRescheduled',
  DemoSet = 'DemoSet',
  DemoSetFlaked = 'DemoSetFlaked',
  DervChange = 'DERVChange',
  Dials = 'Dials',
  DialsPerRepPerDay = 'DialsPerRepPerDay',
  DmColdCalls = 'DMColdCalls',
  DmContact = 'DMContact',
  DmContactInterested = 'DMContactInterested',
  DmRate = 'DMRate',
  DmReachedNotScheduled = 'DMReachedNotScheduled',
  DoNotContact = 'DoNotContact',
  FollowUpCloseRate = 'FollowUpCloseRate',
  FollowUpDemoHeld = 'FollowUpDemoHeld',
  FollowUpHolds = 'FollowUpHolds',
  GeneralCallback = 'GeneralCallback',
  HoldPerRepPerDay = 'HoldPerRepPerDay',
  HoldTransferfromSdr = 'HoldTransferfromSDR',
  Hours = 'Hours',
  HoursRepPerDay = 'HoursRepPerDay',
  InboundLeadAssignmentToFirstDial = 'InboundLeadAssignmentToFirstDial',
  InitialHoldRate = 'InitialHoldRate',
  InitialHolds = 'InitialHolds',
  InitialSets = 'InitialSets',
  LtCloseRate = 'LTCloseRate',
  LtHoldRate = 'LTHoldRate',
  MedianDaysFromContactToSet = 'MedianDaysFromContactToSet',
  MedianDaysFromDecisionCallToSale = 'MedianDaysFromDecisionCallToSale',
  MedianDaysFromFirstDialToContact = 'MedianDaysFromFirstDialToContact',
  MedianDaysFromHoldToDecisionCall = 'MedianDaysFromHoldToDecisionCall',
  MedianDaysFromHoldToSale = 'MedianDaysFromHoldToSale',
  MedianDaysFromSetToHold = 'MedianDaysFromSetToHold',
  MedianDaysToClose = 'MedianDaysToClose',
  NdmColdCalls = 'NDMColdCalls',
  NdmContact = 'NDMContact',
  NdmContactInterested = 'NDMContactInterested',
  NdmInterested = 'NDMInterested',
  NdmRate = 'NDMRate',
  NdmSuccessRate = 'NDMSuccessRate',
  NoContactMade = 'NoContactMade',
  NumOfSetRescheduled = 'NumOfSetRescheduled',
  Otf = 'OTF',
  Otfs = 'OTFS',
  PacingPercentToPlan = 'PacingPercentToPlan',
  PercentOfHoldfromSdr = 'PercentOfHoldfromSDR',
  PercentOfSalefromSdr = 'PercentOfSalefromSDR',
  PercentOfSdrTransfersHeld = 'PercentOfSDRTransfersHeld',
  PercentOfSdrTransfersSold = 'PercentOfSDRTransfersSold',
  PercentOfSetsRescheduled = 'PercentOfSetsRescheduled',
  PercentOfTotalRevenuefromSdr = 'PercentOfTotalRevenuefromSDR',
  PercentOfTransferCallAnswered = 'PercentOfTransferCallAnswered',
  PercentOfTransferCallAnsweredDuration = 'PercentOfTransferCallAnsweredDuration',
  Pipeline = 'Pipeline',
  PostDervScore = 'PostDERVScore',
  PreDervScore = 'PreDERVScore',
  Release = 'Release',
  RescheduledHolds = 'RescheduledHolds',
  RescheduledSets = 'RescheduledSets',
  RescheduleHoldRate = 'RescheduleHoldRate',
  RevenuefromSdr = 'RevenuefromSDR',
  RevenuePerSale = 'RevenuePerSale',
  ReviewsReceived = 'ReviewsReceived',
  RvPerDay = 'RVPerDay',
  RvPerSale = 'RVPerSale',
  Sale = 'Sale',
  SalesPerDay = 'SalesPerDay',
  SalesPerRepPerDay = 'SalesPerRepPerDay',
  SaleTransferfromSdr = 'SaleTransferfromSDR',
  ScheduledActivitiesMissed = 'ScheduledActivitiesMissed',
  ScheduledCallBackAfterDmContacted = 'ScheduledCallBackAfterDMContacted',
  ScheduledDecision = 'ScheduledDecision',
  ScheduledDemo = 'ScheduledDemo',
  ScheduledEvent = 'ScheduledEvent',
  ScheduledHoldRate = 'ScheduledHoldRate',
  SdrHold = 'SDRHold',
  SdrLiveTransferHold = 'SDRLiveTransferHold',
  SdrLiveTransferSale = 'SDRLiveTransferSale',
  SdrSale = 'SDRSale',
  SdrTransfer = 'SDRTransfer',
  SelfSourcedLeads = 'SelfSourcedLeads',
  SetRate = 'SetRate',
  Skip = 'Skip',
  TenMinDowntimeWithoutActivity = 'TenMinDowntimeWithoutActivity',
  TenMinDowntimeWithoutDial = 'TenMinDowntimeWithoutDial',
  TotalRecurringRevenue = 'TotalRecurringRevenue',
  TotalRevenue = 'TotalRevenue',
  TotalRevenueValue = 'TotalRevenueValue',
  TransferCallAnswered = 'TransferCallAnswered',
  TransferCallAnsweredDuration = 'TransferCallAnsweredDuration',
  TransferCallIgnored = 'TransferCallIgnored',
  Transfers = 'Transfers',
  TtPerDemo = 'TTPerDemo',
  TtPerHour = 'TTPerHour',
  TtPerRepPerDay = 'TTPerRepPerDay',
  TtPerRepPerHour = 'TTPerRepPerHour',
  Undialed = 'Undialed',
  WinPerHour = 'WinPerHour',
  WinPerRepPerDay = 'WinPerRepPerDay',
  WinPerRepPerHour = 'WinPerRepPerHour'
}

export enum Metrictype {
  Dollar = 'Dollar',
  Number = 'Number',
  Ordinal = 'Ordinal',
  Percentage = 'Percentage'
}

export enum Month {
  April = 'April',
  August = 'August',
  December = 'December',
  February = 'February',
  January = 'January',
  July = 'July',
  June = 'June',
  March = 'March',
  May = 'May',
  November = 'November',
  October = 'October',
  September = 'September'
}

export type Mutation = {
  __typename?: 'Mutation';
  add_end_sequence_step_action?: Maybe<SequenceStep>;
  addAssociateLead?: Maybe<SuccessResponse>;
  addAutomatedCampaign?: Maybe<AutomatedCampaign>;
  /** Add a call to the folder */
  addCallToFolder?: Maybe<Folder>;
  addCoachingNote?: Maybe<NoteItem>;
  /** Adds a contact and creates a notification that goes to the manager and the admin with the ability to veto. */
  addContactRequest?: Maybe<Contact>;
  /** Add a new custom field */
  addCustomField?: Maybe<CustomField>;
  addEmailToErrorReporting?: Maybe<Scalars['Boolean']['output']>;
  addJoinMeToScheduleItem?: Maybe<ScheduleItem>;
  /** Adds a single lead to the organization in the JWT. If null is returned, it indicates a unassigned Lead available and claim is set to false */
  addLead?: Maybe<LeadIntent>;
  /** Add a off call lead note */
  addLeadNote?: Maybe<LeadActivity>;
  /** Used to add a conference routing number */
  addOrgConferenceNumber?: Maybe<Scalars['Boolean']['output']>;
  addOrUpdateAutomatedCampaign?: Maybe<AutomatedCampaign>;
  addOrUpdateCustomRuleSetting?: Maybe<GlobalTransferRule>;
  addOrUpdateFederalHoliday?: Maybe<OrgNoEventDate>;
  /** @deprecated Depreacated dued to transfer 2.0 update */
  addOrUpdateGlobalTransferRule?: Maybe<GlobalTransferRule>;
  addorUpdateIntegrationMapping?: Maybe<IntegrationMapping>;
  /** Adds or updates product list */
  addOrUpdateProducts?: Maybe<Array<Maybe<Product>>>;
  /** Adds or updates product list */
  addOrUpdateProducts_deprecated?: Maybe<Array<Maybe<Product>>>;
  /** Return success response based on the request */
  addProduct?: Maybe<SuccessResponse>;
  addSite?: Maybe<Site>;
  addSystemField?: Maybe<Scalars['Boolean']['output']>;
  /** Updates dispositions based on phase, person_spoke_to, and type. Uses static dispositions file. */
  addTapestryDispositions?: Maybe<Scalars['Boolean']['output']>;
  /** Adds the lead from the rep's custom queue */
  addToCustomQueue?: Maybe<CustomQueueItem>;
  addUser?: Maybe<User>;
  /** Add user to folder */
  addUserToFolder?: Maybe<User>;
  /** Adjust the algo related multipliers, such as hinge, lead field, and disposition */
  adjustAlgoMultipliers?: Maybe<Scalars['String']['output']>;
  /** Moves leads back into unassigned / cold call phase; Runs nightly */
  adjustResting?: Maybe<Scalars['Boolean']['output']>;
  /** Tests the adjustResting script with a single lead */
  adjustRestingTest?: Maybe<Scalars['Json']['output']>;
  /** Tests the adjustResting script with a single lead */
  adjustRestingTestSingle?: Maybe<Scalars['Boolean']['output']>;
  arrangeLeadQueueToTheEnd?: Maybe<Scalars['String']['output']>;
  assignLAToRandomUser?: Maybe<Scalars['String']['output']>;
  /** Assigns */
  assignLeadsAdhoc?: Maybe<Scalars['Int']['output']>;
  /** Assigns the user to the team. If team_id is blank, user is unassigned from a team. */
  assignUserToTeam?: Maybe<User>;
  associateMultipleLeads?: Maybe<SuccessResponse>;
  authorizeJoinMe?: Maybe<User>;
  backfileScheduledIntent?: Maybe<Scalars['Int']['output']>;
  /** A one-time backfill for algo data for an org. */
  backfillAlgoData?: Maybe<Scalars['Boolean']['output']>;
  backFillAllLeadClosedBy?: Maybe<Scalars['Int']['output']>;
  /** return one user's ColdCall LeadIntents per industry */
  backFillAllLeadFirstdemoDate?: Maybe<Scalars['String']['output']>;
  backFillAllLeadSetBy?: Maybe<Scalars['Int']['output']>;
  /** Backfill function for update lead day set out property for all Org */
  backfillAllOrgDaysSetOut?: Maybe<Scalars['Boolean']['output']>;
  backFillAllStatData?: Maybe<Scalars['Int']['output']>;
  backfillBestRecord?: Maybe<Scalars['Boolean']['output']>;
  /** Only used to backfill phone numbers with country code */
  backfillCountryCode?: Maybe<Scalars['Boolean']['output']>;
  /** Returns count of leads */
  backfillCSVHistoryLink?: Maybe<Scalars['Json']['output']>;
  /** Adjust leads back to Customer for Sale */
  backfillCustomer?: Maybe<Scalars['Boolean']['output']>;
  /** Backfill days set out on lead activity */
  backfillDaysSetOut?: Maybe<Scalars['Boolean']['output']>;
  /** Adjust leads back to Retired/Customer for DNC/Sale */
  backfillDNC?: Maybe<Scalars['Boolean']['output']>;
  backFillHubSpotSyncForAllOrg?: Maybe<Array<Maybe<OrgHubSpotSync>>>;
  /** A one-time backfill for algo data for an org. */
  backfillImpliedAction?: Maybe<Scalars['Boolean']['output']>;
  backFillLeadAggretationSiteIds?: Maybe<Scalars['Int']['output']>;
  backfillLeadCreationSource?: Maybe<Scalars['Boolean']['output']>;
  /** Backfill function for new lead field current_contact_date */
  backfillLeadLastContactDate?: Maybe<Scalars['Boolean']['output']>;
  /** Links up notification and lead for call report notifications */
  backfillLeadNotificationLink?: Maybe<Scalars['Boolean']['output']>;
  backfillLeadStatAggregate?: Maybe<Scalars['Boolean']['output']>;
  backfillLeadStatAggregateDirect?: Maybe<Scalars['Boolean']['output']>;
  backfillLeadStatAggregateNoQueue?: Maybe<Scalars['Boolean']['output']>;
  /** one time mutation that will backfill lead value for each lead in the org. */
  backfillLeadValue?: Maybe<Scalars['Boolean']['output']>;
  /** Backfills LV values for a lead. */
  backfillLVLead?: Maybe<Scalars['Boolean']['output']>;
  /** Backfill function for new lead field current_contact_date, and close date based on the day_after_first_contact value */
  backfillOrgLeadLastClosedDate?: Maybe<Scalars['Boolean']['output']>;
  backfillPipelineLeadStats?: Maybe<Scalars['Int']['output']>;
  backFillRescheduleSets?: Maybe<Scalars['Int']['output']>;
  backFillRescheduleSetsOld?: Maybe<Scalars['Int']['output']>;
  /** Only used to backfill an issue with leads going to resting from createScheduledEvent */
  backfillRestingIssue?: Maybe<Scalars['Boolean']['output']>;
  backFillRoleAndSiteIds?: Maybe<Scalars['Int']['output']>;
  backfillSaleCycleBulk?: Maybe<Scalars['Boolean']['output']>;
  backfillSaleStatsCycleBulk?: Maybe<Scalars['Boolean']['output']>;
  backfillSaleStatsCycleList?: Maybe<Scalars['Boolean']['output']>;
  backFillSFDCSyncForAllOrg?: Maybe<Array<Maybe<OrgSalesforceSync>>>;
  backfillSingleSaleCycle?: Maybe<Scalars['Boolean']['output']>;
  /** backfill for sale field options. For one time use */
  backfillSingleSaleFieldOption?: Maybe<Scalars['Boolean']['output']>;
  backfillSIRole?: Maybe<Scalars['Int']['output']>;
  backfillSIRoleTest?: Maybe<Scalars['Int']['output']>;
  backfillTeamIdToLA?: Maybe<Scalars['Int']['output']>;
  backfillTransferStats?: Maybe<Scalars['Boolean']['output']>;
  /** removes deprecated visible pipeline fields from the users table */
  BackfillUserVisibleCallReportPipeLineColumns?: Maybe<Scalars['String']['output']>;
  /** Add a list of leads to a rep's custom queue */
  bulkAddLeadsCustomQueue?: Maybe<Scalars['Int']['output']>;
  /** Perform bulk delete on leads. If no parameters pass in, it will perform organization level delete */
  bulkDeleteLeads?: Maybe<Scalars['String']['output']>;
  /** Update default audio input and output */
  bulkDeleteUsers?: Maybe<Scalars['String']['output']>;
  /** Update default audio input and output */
  bulkEditUsers?: Maybe<Scalars['String']['output']>;
  /** Bulk Star/Favorite Leads */
  bulkStarLeads?: Maybe<Scalars['Int']['output']>;
  /** Bulk reassign leads. Return errors  */
  bulkTransferLead?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** Bulk Unassign Leads */
  bulkUnassignLead?: Maybe<Scalars['Int']['output']>;
  callMeNow?: Maybe<CallMeNow>;
  cancelCallMeNow?: Maybe<CallMeNow>;
  cancelCurrentLeadImport?: Maybe<Scalars['Boolean']['output']>;
  changeDispositionOption?: Maybe<LeadActivity>;
  changeDispositionRep?: Maybe<LeadActivity>;
  checkSelfSourceMapping?: Maybe<Array<Maybe<Lead>>>;
  /**
   * Claim Lead if lead is not owned by other Rep
   * @deprecated Deprecated in favor of addLead
   */
  claimLeadViaPhone?: Maybe<Lead>;
  /**
   * Looks for events that have been missed. Cleans them up and adjusts the lead as neccessary.
   *         This mutation runs for all orgs. It can only be ran by a SUPERADMIN. It is meant to be ran
   *         as a cron job.
   */
  cleanupMissedEvents?: Maybe<Scalars['Int']['output']>;
  /** Clone whole sequence */
  clone_sequence?: Maybe<Sequence>;
  /** Marks onboarding as complete. Also sends out onboarding emails to reps. */
  completeOrganizationOnboarding?: Maybe<Organization>;
  computeLeadValueLead?: Maybe<Lead>;
  /** Algo to run final LV per org */
  computeOrgLeadValue?: Maybe<Scalars['String']['output']>;
  /** Algo to run final LV per org */
  computeOrgLeadValueBackfill?: Maybe<Scalars['String']['output']>;
  configureAlgo?: Maybe<Organization>;
  ConfigureDefaultAlgoData?: Maybe<Array<Maybe<AlgoFactor>>>;
  /** Updates information from global schedule onboarding */
  configureGlobalSchedule?: Maybe<Organization>;
  configureHolidayTimeOff?: Maybe<Array<Maybe<OrganizationHoliday>>>;
  configureProductPricing?: Maybe<Array<Maybe<Product>>>;
  countScheduledItemForLead?: Maybe<Scalars['Int']['output']>;
  createConciergeEvent?: Maybe<InboundConciergeEvent>;
  createConciergeReporting?: Maybe<Scalars['String']['output']>;
  createDashboardSavedView?: Maybe<DashboardSavedView>;
  /** Update the priority of the rules */
  createDefaultSaleConfig?: Maybe<Scalars['Json']['output']>;
  createDocument?: Maybe<Scalars['Json']['output']>;
  createGeneralTimeScheduledEvent?: Maybe<LeadIntent>;
  createIntegrationErrorReportCustomDates?: Maybe<Scalars['String']['output']>;
  /** Create a unattach joinMeItem. Start time must be before end time */
  createJoinMe?: Maybe<JoinMeItem>;
  /** Creates events for a lead */
  createLeadEvents?: Maybe<Scalars['Json']['output']>;
  /** create new lead for algo purposes */
  createManyNewLead?: Maybe<Array<Maybe<Lead>>>;
  /** create new rep for algo purposes */
  createManyNewRep?: Maybe<Array<Maybe<User>>>;
  /** Create A New Folder */
  createNewFolder?: Maybe<Folder>;
  createNoEventDate?: Maybe<OrgNoEventDate>;
  createOneBestMetricRecord: BestMetricRecord;
  createOneCustomObject?: Maybe<CustomObject>;
  createOneCustomObjectField?: Maybe<CustomObjectField>;
  createOneObjectFKMapping?: Maybe<CustomObjectFieldForeignKey>;
  createOneProduct: Product;
  createOneTwilioInfo: TwilioInfo;
  /** Creates a blank organization. Meant to be called off platform. Should only be ran once for each organization. If you need to run it again, you should remove the organization first. */
  createOrganizationShell?: Maybe<Organization>;
  /** Create or update sequenceDashboardView */
  createOrSequenceDashboardView?: Maybe<SequenceDashboardView>;
  /** Update Lead routing rule if rule_id exist. Otherwise, create the transfer rule */
  createOrUpdateCustomTransferRule?: Maybe<Rule>;
  /** Update Lead routing rule if rule_id exist. Otherwise, create the lead routing rule */
  createOrUpdateLeadRoutingRule?: Maybe<Rule>;
  /** create a organization doming on sendgrid */
  createOrUpdateOrganizationDomain?: Maybe<Scalars['Json']['output']>;
  /** Return success response based on the request */
  createOrUpdateSaleConfigPages?: Maybe<SaleConfig>;
  /** Create or update sequence */
  createOrUpdateSequence?: Maybe<Sequence>;
  /** Create or update sequence criteria */
  createOrUpdateSequenceEntryCriteria?: Maybe<SequenceEntryCriteria>;
  /** Create or update sequence step */
  createOrUpdateSequenceStep?: Maybe<SequenceStep>;
  /** Create or update template */
  createOrUpdateTemplate?: Maybe<Template>;
  createRandomActivities?: Maybe<Scalars['String']['output']>;
  createRandomConference?: Maybe<Scalars['String']['output']>;
  /** Create a scheduled event */
  createScheduledEvent?: Maybe<ScheduleItem>;
  /** Create a scheduled event */
  createScheduledEventWidget?: Maybe<WidgetScheduleItem>;
  /** Create New Test Lead, requires 10 digit phone number to generate random new lead */
  createTestLead?: Maybe<Lead>;
  createTwilioFeedback?: Maybe<TwilioFeedback>;
  /** Ack that a task was completed and move to next step */
  customTaskAck?: Maybe<Scalars['Boolean']['output']>;
  /** Seeds the database with data */
  danieltest?: Maybe<Scalars['Json']['output']>;
  /** Dedupe dipositions */
  dedupeDispositions?: Maybe<Scalars['Int']['output']>;
  /** Create or update sequence criteria */
  delete_sequence?: Maybe<Scalars['Boolean']['output']>;
  delete_step?: Maybe<Scalars['Boolean']['output']>;
  deleteAllCustomQueueItems?: Maybe<Scalars['Int']['output']>;
  /** Puts in a request to delete a new contact. Done by SDR or AE. */
  deleteContactRequest?: Maybe<Contact>;
  /** deleteCustomField and userCustomFieldFilters */
  deleteCustomField?: Maybe<CustomField>;
  deleteDashboardSavedView?: Maybe<DashboardSavedView>;
  /** deleting pre existing calendar info to sync w/ google / microsoft calendar */
  deleteExistingResource?: Maybe<User>;
  deleteFederalHoliday?: Maybe<Scalars['Boolean']['output']>;
  deleteLeadCustomObjectRowAssociation?: Maybe<LeadCustomObjectRowAssociation>;
  deleteNoEventDate?: Maybe<OrgNoEventDate>;
  /** Delete the call notes of an old conference */
  deleteNote?: Maybe<LeadActivity>;
  deleteOneAutomatedCampaign?: Maybe<AutomatedCampaign>;
  deleteOneBestMetricRecord?: Maybe<BestMetricRecord>;
  deleteOneCustomObject?: Maybe<CustomObject>;
  deleteOneCustomObjectField?: Maybe<CustomObjectField>;
  deleteOneNoteItem?: Maybe<NoteItem>;
  /** delete notification */
  deleteOneNotification?: Maybe<Notification>;
  deleteOneProduct?: Maybe<Product>;
  /** create a organization doming on sendgrid */
  deleteOrganizationDomain?: Maybe<Scalars['Boolean']['output']>;
  /** Return success response based on the request */
  deleteProduct?: Maybe<SuccessResponse>;
  /** Return success response based on the request */
  deleteProductAdmin?: Maybe<SuccessResponse>;
  deleteROObject?: Maybe<SuccessResponse>;
  /** Deletes a scheduled event */
  deleteScheduledEvent?: Maybe<ScheduleItem>;
  /** Delete sequenceDashboardView */
  deleteSequenceDashboardView?: Maybe<Scalars['Boolean']['output']>;
  deleteSite?: Maybe<Site>;
  /** soft delete template */
  deleteTemplate?: Maybe<Template>;
  /** dirty deletes a user */
  deleteUser?: Maybe<User>;
  disassociateLead?: Maybe<SuccessResponse>;
  disconnectIntegration?: Maybe<Scalars['Boolean']['output']>;
  /** Edits a calendar event */
  editCalendarEvent?: Maybe<ScheduleItem>;
  /** Puts in a request to add a new contact. Done by SDR or AE. */
  editContactRequest?: Maybe<Lead>;
  editCustomFieldsForLead?: Maybe<Array<Maybe<CustomField>>>;
  /** Edit the call notes of an old conference */
  editNote?: Maybe<LeadActivity>;
  /** Return success response based on the request */
  editProduct?: Maybe<SuccessResponse>;
  /** Update default audio input and output */
  editSalesTeamColumnOptions: Array<SalesTeamColumnOptions>;
  /** Edits a scheduled event */
  editScheduledEvent?: Maybe<ScheduleItem>;
  /** Return success response based on the request */
  editTerms?: Maybe<SuccessResponse>;
  editTimeOffRequest?: Maybe<TimeOffRequest>;
  /** Records timestamp of when closing script starts */
  endClosingScript?: Maybe<Conference>;
  /** mostly used for dev purposes. manually  */
  enterSequence?: Maybe<Scalars['Boolean']['output']>;
  /** Trigger to send activities and leads reports for the past 60 days for a given organization */
  executeActivitiesAndLeadsReports?: Maybe<Scalars['Int']['output']>;
  /** Trigger for sending Activities report for the past 60 days for a given organization */
  executeActivitiesReport?: Maybe<Scalars['Int']['output']>;
  executeAssignedColdCallReport?: Maybe<Scalars['Int']['output']>;
  executeDailySurfireRepGoalsReport?: Maybe<Scalars['Json']['output']>;
  /** Triggers a backfill from hubspot for  */
  executeHubSpotActivityBackfill?: Maybe<Scalars['Boolean']['output']>;
  /** Trigger for sending Inbound Concierge report for a given organization */
  executeInboundConciergeReport?: Maybe<Scalars['Int']['output']>;
  executeIntegrationErrorReport?: Maybe<Scalars['String']['output']>;
  executeIntegrationErrorReportCron?: Maybe<Scalars['String']['output']>;
  /** Triggers leads activity past day report to be sent out */
  executeLeadActivityPastDayReportVI?: Maybe<Scalars['Int']['output']>;
  /** Triggers a lead created on the past day report to be sent out */
  executeLeadCreatedPastDayReport?: Maybe<Scalars['Int']['output']>;
  /** endpoint for sending a reps coaching notes */
  executeMyCoachingNotesReport?: Maybe<Scalars['Int']['output']>;
  /** number of assigned leads per rep */
  executeNumberOfAssignedLeadsReport?: Maybe<Scalars['Int']['output']>;
  /** Triggers a report to be sent out, TimeToFirstContact */
  executeOllyOllySalesReport?: Maybe<Scalars['Int']['output']>;
  /** Endpoint for sending all holds report */
  executeOrganizationAllHoldsReport?: Maybe<Scalars['Int']['output']>;
  /** Endpoint for sending coaching notes report to all manager and enablement accounts */
  executeOrganizationCoachingNotesReport?: Maybe<Scalars['Int']['output']>;
  /** Endpoint for sending released leads report */
  executeOrganizationReleasedLeadsReport?: Maybe<Scalars['Int']['output']>;
  /** Endpoint for sending skipped sequence leads report */
  executeOrganizationSkippedSequenceLeadsReport?: Maybe<Scalars['Int']['output']>;
  executePipelineReport?: Maybe<Scalars['Int']['output']>;
  /** Triggers a report to be sent out, number of self sourced leads */
  executeSelfSourcedLeadsReport?: Maybe<Scalars['Json']['output']>;
  /** Triggers executeSelfSourceNoIndustryLeadsReport report to be sent out */
  executeSelfSourceNoIndustryLeadsReport?: Maybe<Scalars['Json']['output']>;
  /** Triggers a sales report to be sent out */
  executeSurefireSalesReport?: Maybe<Scalars['Int']['output']>;
  executeTimekitReport?: Maybe<Scalars['Int']['output']>;
  /** Triggers a report to be sent out, TimeToFirstContact */
  executeTimeToFirstContactReport?: Maybe<Scalars['Int']['output']>;
  /** number of unique leads dialed per rep (usually ran quarterly) */
  executeUniqueLeadsDialedReport?: Maybe<Scalars['Int']['output']>;
  /** undo ignore sequences if exitdialer was clicked after tryalternatecontact wast ticked */
  exitDialer?: Maybe<Scalars['Boolean']['output']>;
  exportCallReport?: Maybe<ExportCallReportType>;
  exportDashboardSavedView?: Maybe<ExportDashboardDataType>;
  exportDashboardView?: Maybe<ExportDashboardDataType>;
  exportErrorReport?: Maybe<ExportUrlType>;
  exportEventCallReport?: Maybe<ExportCallReportType>;
  /** Bulk export leads based on filter */
  exportFilteredLead?: Maybe<Scalars['Boolean']['output']>;
  exportIntegrationPickListOptions?: Maybe<ExportUrlType>;
  exportLeads?: Maybe<Scalars['String']['output']>;
  exportPipelineReport?: Maybe<ExportCallReportType>;
  favoriteLead?: Maybe<Lead>;
  /** Call findAndEnterSequence for batch of leads */
  findAndEnterSequence?: Maybe<Scalars['Boolean']['output']>;
  fixLOSIssue?: Maybe<Scalars['Boolean']['output']>;
  /** Populate the yelp default industry */
  generateYelpDefaultIndustries?: Maybe<Scalars['Boolean']['output']>;
  getSFIndustry?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  getSFLeads?: Maybe<Scalars['String']['output']>;
  /** just for testing purposes, addinga note here just in case fyi */
  hitconciergenotification?: Maybe<Scalars['Boolean']['output']>;
  /**
   * Return of possible actions a user can take based on lead. Does not include Sale at the moment. Sale is manually filtered out. It will be included later.
   * @deprecated Only for one-time use
   */
  hotfixAdjustGeneralTimeCallbacks?: Maybe<Scalars['Int']['output']>;
  /** Ignore the upload and overwrite existing lead with staged leads */
  ignoreLeadUploadWarning?: Maybe<Scalars['Boolean']['output']>;
  /** Ignore the upload and overwrite existing user with staged users */
  ignoreUserUploadWarning?: Maybe<Scalars['Boolean']['output']>;
  InboundConciergeClosed?: Maybe<Scalars['Boolean']['output']>;
  /** Runs a lead assignment test for one lead and user */
  leadAlgoAssignmentTest?: Maybe<Scalars['Boolean']['output']>;
  /** Runs a lead assignment test for one lead and user */
  leadAlgoAssignmentTestUser?: Maybe<Scalars['Boolean']['output']>;
  leadAlgoSetup?: Maybe<Scalars['String']['output']>;
  leadAlgoSetupOrg?: Maybe<Scalars['String']['output']>;
  /** Lead Batch Operation Result for Resting, Long Resting, and Retired update */
  leadBatchPhaseUpdate?: Maybe<AllLeadBatchResult>;
  /** For password reset. returns true if successful */
  localResetPassword?: Maybe<User>;
  /** Logs a disposition */
  logDisposition?: Maybe<LeadActivity>;
  login?: Maybe<User>;
  /** Logout user */
  Logout?: Maybe<Scalars['Boolean']['output']>;
  /** Sends a notification to the reps manager to review a call report */
  logReviewCallReport?: Maybe<Scalars['Boolean']['output']>;
  logTimeOffRequest?: Maybe<TimeOffRequest>;
  makePrimary?: Maybe<SuccessResponse>;
  makeSale?: Maybe<LeadActivity>;
  /** Return boolean if result is successful */
  manualPushSaleToSF?: Maybe<Scalars['Boolean']['output']>;
  /** Marks all notifications as read by the user */
  markNotificationsAsRead?: Maybe<Scalars['Boolean']['output']>;
  markSMSAsRead?: Maybe<Array<Maybe<SmsItem>>>;
  mergeAssociateLead?: Maybe<SuccessResponse>;
  /** Onetime use */
  migrateDispositions?: Maybe<Scalars['Json']['output']>;
  /** Move report from one folder to another */
  moveCallFromFolder?: Maybe<Scalars['Boolean']['output']>;
  mutateStaticMetrics?: Maybe<Scalars['Json']['output']>;
  /** Sets a new password. Must pass in JWT in token parameter. */
  newPassword?: Maybe<Scalars['Boolean']['output']>;
  /** Creates a blank organization. Meant to be called off platform. Should only be ran once for each organization. If you need to run it again, you should remove the organization first. */
  oneTimeCreateOrgHours?: Maybe<Scalars['Boolean']['output']>;
  /** Just a onetime seed function for calendar metadata */
  onetimeSeedCalMeta?: Maybe<Scalars['Boolean']['output']>;
  oneTimeSeedConcession?: Maybe<Scalars['Boolean']['output']>;
  /** Just a onetime seed function for dispositions */
  onetimeSeedDispositions?: Maybe<Scalars['Boolean']['output']>;
  /** One time seed function to populate org hours and org holidays */
  oneTimeSeedHoursAndHolidays?: Maybe<Organization>;
  /** Just a onetime seed function for existing companies */
  onetimeSeedIndustryAndLeadSource?: Maybe<Scalars['Boolean']['output']>;
  /** Just a onetime seed function for existing companies */
  onetimeSeedRevenueGoals?: Maybe<Scalars['Boolean']['output']>;
  /** Seeds the database with recording consent laws by state/territory. This operates at a global level rather than org level. */
  onetimeSeedStateRecordingLaws?: Maybe<Scalars['Boolean']['output']>;
  oneTimeSeedSurefireConcessions?: Maybe<Array<Maybe<Concession>>>;
  oneTimeSeedSurefireProducts?: Maybe<Array<Maybe<Product>>>;
  /** One time use */
  onetimeZachTimekit?: Maybe<Scalars['Boolean']['output']>;
  orderLeadIntentBySS?: Maybe<Array<Maybe<LeadIntent>>>;
  OrgLATemplateCsv?: Maybe<ExportDashboardDataType>;
  OrgTemplateCsv?: Maybe<ExportDashboardDataType>;
  /** Handles notifcation actions. */
  performNotificationAction?: Maybe<Notification>;
  prepareForScheduleCallback?: Maybe<LeadIntent>;
  /** Backfill provider request for Tom K */
  providerUpdateVI?: Maybe<Scalars['Int']['output']>;
  provisionLocalNumbers?: Maybe<Array<Maybe<TwilioNumber>>>;
  purgeLeads?: Maybe<Scalars['Boolean']['output']>;
  /** Use to remove old industry options */
  purgeOrgIndustryOption?: Maybe<Scalars['Boolean']['output']>;
  /** endpoint for pushing customized data to S3 targets */
  pushSampleToS3?: Maybe<Scalars['Json']['output']>;
  /** Switch in progress leads from one user to another */
  reassignInProgressLeads?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** Switch lead from one user to another */
  reassignLead?: Maybe<Lead>;
  /** Switch leads from one user to another. Currently Used Internally */
  reassignLeads?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** Reassgined skipped leads to target user. Return number of leads reassigned */
  reassignSkippedLeads?: Maybe<Scalars['Int']['output']>;
  recalculateLeadPool?: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated This has been deprecated. Do not use. */
  refillLeadLOS?: Maybe<Scalars['Boolean']['output']>;
  refillLeadQueue?: Maybe<Scalars['Boolean']['output']>;
  /** Registers a user onto opsiq. Role defaults to Admin. Meant to be called off platform. */
  register?: Maybe<User>;
  /** Triggers a release of phone numbers that were deprecated  */
  releaseDeprecatedNumbers?: Maybe<Scalars['Boolean']['output']>;
  /** Release lead if there is no scheduled items */
  releaseLead?: Maybe<Lead>;
  /** mostly used for dev purposes */
  reloadSequenceCount?: Maybe<Scalars['Boolean']['output']>;
  /** Remove a call from the folder */
  removeCallFromFolder?: Maybe<Folder>;
  removeCallResult?: Maybe<LeadActivity>;
  /** Remove a folder */
  removeFolder?: Maybe<Folder>;
  /** Removes the lead from the rep's custom queue */
  removeFromCustomQueue?: Maybe<CustomQueueItem>;
  removeIntegrationErrorLog?: Maybe<Scalars['Boolean']['output']>;
  removeIntegrationMapping?: Maybe<Scalars['Boolean']['output']>;
  /** remove a joinMeItem */
  removeJoinMeItem?: Maybe<JoinMeItem>;
  /** remove joinMe meeting through scheduled_item_id */
  removeJoinMeMeeting?: Maybe<ScheduleItem>;
  /** Updates dispositions based on phase, person_spoke_to, and type. Uses static dispositions file. */
  removeOldDispositions?: Maybe<Scalars['Boolean']['output']>;
  /** Delete a lead routing rule */
  removeRoutingRule?: Maybe<Scalars['Boolean']['output']>;
  /** Deletes scheduled item and lead_intent */
  removeScheduledItem?: Maybe<ScheduleItem>;
  removeSystemField?: Maybe<Scalars['Boolean']['output']>;
  /** Remove user from folder */
  removeUserFromFolder?: Maybe<User>;
  reprovisionLocalNumbers?: Maybe<Array<Maybe<Scalars['Json']['output']>>>;
  repushEvent?: Maybe<Scalars['String']['output']>;
  resetLeadPhase?: Maybe<Lead>;
  /** For password reset. returns true if successful */
  resetPassword?: Maybe<Scalars['Boolean']['output']>;
  /** Reset the variables on the users. Currently only use on daily claim limit. This is daily cronjob */
  resetUserDailyStatus?: Maybe<Scalars['Int']['output']>;
  resolveDuplicateLeads?: Maybe<Scalars['Boolean']['output']>;
  /** The user chooses to finish the upload without handling the duplicates */
  resolveWithoutFixingDuplicates?: Maybe<Scalars['Boolean']['output']>;
  /** meant to be hit by reps */
  respondToConcierge?: Maybe<Scalars['Boolean']['output']>;
  /** OPS-4940. route leads from inbound concierge event that are unknown or error.  */
  routeChaseMeLeads?: Maybe<Scalars['Boolean']['output']>;
  runBestMetricCronJob?: Maybe<Scalars['Int']['output']>;
  /** check released numbers */
  runCheckReleasedNumbersAlgo?: Maybe<Scalars['Boolean']['output']>;
  /** this is to handle backfill for partial demo as demo flake in pipeline state */
  runDemoFlakeDispositionBackfill?: Maybe<Scalars['Int']['output']>;
  /** Assigns lead intents to reps */
  runLeadAssignmentAlgo?: Maybe<Scalars['Int']['output']>;
  /** Assigns lead intents to reps for org */
  runLeadAssignmentAlgoOrg?: Maybe<Scalars['Int']['output']>;
  /** Assigns lead intents to reps for org */
  runLeadAssignmentAlgoOrgUserBackfill?: Maybe<Scalars['Int']['output']>;
  runSFCronJob?: Maybe<Scalars['String']['output']>;
  /** Seeds the sales account with data */
  salesDemoFakeData?: Maybe<Scalars['Boolean']['output']>;
  SalesForceLeadInbound?: Maybe<Scalars['Boolean']['output']>;
  /** Saves the columns selected by the rep in the event drilldown section */
  saveCallReportEventSelectedColumns?: Maybe<User>;
  /** Saves the columns selected by the rep in the pipeline drilldown list */
  saveCallReportPipelineSelectedColumns?: Maybe<User>;
  /** Saves the columns selected by the rep in the coaching drilldown list */
  saveCallReportSelectedColumns?: Maybe<User>;
  /** Saves the columns selected by the rep in the my leads section */
  saveRepSelectedColumns?: Maybe<User>;
  /** Seeds the database with data */
  seed?: Maybe<Scalars['Boolean']['output']>;
  seedAllStatItem?: Maybe<Scalars['String']['output']>;
  /** Updates dispositions based on phase, person_spoke_to, and type. Uses static dispositions file. */
  seedDialerActions?: Maybe<Array<Maybe<DialerActions>>>;
  /** Onetime use to see activities for icon */
  seedLAIcons?: Maybe<Array<Maybe<LeadActivity>>>;
  seedLead?: Maybe<Scalars['Boolean']['output']>;
  /** send automated campaigns to qualified leads */
  sendAutomatedCampaigns?: Maybe<Scalars['Int']['output']>;
  /** creating new email thread and send first email to lead. For email content, use either body or html parameters. Do not use both. Html is the html form. */
  sendEmailToLead?: Maybe<EmailItem>;
  /** send lead import history that is in progress */
  sendLeadImportNotification?: Maybe<Scalars['String']['output']>;
  /** OllyOlly would like reporting 4 times per month until we have sale amount avaiable in the UI */
  sendReportingForAllSales?: Maybe<Scalars['Int']['output']>;
  /** Generates report of dials before contact with a lead */
  sendReportingForDialsBeforeContact?: Maybe<Scalars['Int']['output']>;
  /** Sends a notification to the rep to review feedback */
  sendReviewCallReportToRep?: Maybe<Scalars['Boolean']['output']>;
  sendSMS?: Maybe<SmsItem>;
  setHubSpotSyncSettings?: Maybe<Scalars['Boolean']['output']>;
  setInboundConciergeWidgetSettings?: Maybe<Scalars['Boolean']['output']>;
  /** Controls the list of emails that get notified when a lead is routed */
  setLeadRoutingEmailList?: Maybe<Organization>;
  /** Determines whether an email will be sent out */
  setLeadRoutingEmailToggle?: Maybe<Organization>;
  skipLead?: Maybe<LeadIntent>;
  startAdHocJoinMeMeeting?: Maybe<Scalars['Json']['output']>;
  /** Records timestamp of when closing script starts */
  startClosingScript?: Maybe<Conference>;
  startJoinMeMeeting?: Maybe<Scalars['Json']['output']>;
  /** Use to indicate sale flow initialized.  */
  startSale?: Maybe<Scalars['Boolean']['output']>;
  statAggregationBackfill?: Maybe<Scalars['Int']['output']>;
  statAggregationBackfillPipeline?: Maybe<Scalars['Int']['output']>;
  statAggregationOrg?: Maybe<Scalars['Int']['output']>;
  statAggregationUser?: Maybe<Scalars['Int']['output']>;
  /** swap a lead's primary number and a secondary number from a contact object. */
  swapPrimaryNumberOnLead?: Maybe<Lead>;
  /**
   * By default, a user scheduled meeting is only displayed during the 15 minute
   *         window before the start of the meeting. After it starts, a cold call is shown.
   *         A user can switch back to the scheduled call during the duration of the meeting
   *         with this mutation.
   */
  switchToColdCall?: Maybe<Scalars['Boolean']['output']>;
  /** Opposite of switchToColdCall. Only applies if there is a currently scheduled event. */
  switchToScheduledEvent?: Maybe<Scalars['Boolean']['output']>;
  syncAllMappingFieldSelectOption?: Maybe<Scalars['String']['output']>;
  /** Sync associate lead in batch as cron job, will be running every 12:00 AM EST */
  syncAssociateLeadInBatch?: Maybe<Scalars['Boolean']['output']>;
  syncHubSpotCustomFields?: Maybe<Scalars['Int']['output']>;
  /** Updates leads in hubspot */
  syncHubSpotLeads?: Maybe<Scalars['Int']['output']>;
  syncMappingFieldSelectOption?: Maybe<Scalars['String']['output']>;
  syncPandaDocTemplate?: Maybe<Array<Maybe<PandaDocTemplate>>>;
  teamIdLABackfill?: Maybe<Scalars['Int']['output']>;
  /** Just a onetime seed function for calendar metadata */
  testCrash5?: Maybe<Scalars['Boolean']['output']>;
  /** Just a onetime seed function for calendar metadata */
  testCrash6?: Maybe<Scalars['Boolean']['output']>;
  /** Just a onetime seed function for calendar metadata */
  testCrash7?: Maybe<Scalars['Boolean']['output']>;
  /** Just a onetime seed function for calendar metadata */
  testCrash8?: Maybe<Scalars['Boolean']['output']>;
  /** Just a onetime seed function for existing companies */
  testCreateOrgShellNumbers?: Maybe<Scalars['Boolean']['output']>;
  /** Only used to backfill timezones to lead */
  timezoneBackfill?: Maybe<Scalars['Boolean']['output']>;
  TwilioInfoCreate?: Maybe<Array<Maybe<TwilioInfo>>>;
  /** Unassign all leads in the org Except those with Demo/ScheduledCallBack. Use after business hours */
  unassignLeadsForRep?: Maybe<Scalars['Boolean']['output']>;
  /** Unassign all leads in the org Except those with Demo/ScheduledCallBack. Use after business hours */
  unassignLeadsInOrg?: Maybe<Scalars['Boolean']['output']>;
  /** Unassign skipped lead to specific user. Return number of unassigned */
  unassignSkippedLeads?: Maybe<Scalars['Int']['output']>;
  /** Unassigns a user from a team if the user has a team assignment */
  unassignUserToTeam?: Maybe<User>;
  unfavoriteLead?: Maybe<Lead>;
  updateActiveTwilioRecording?: Maybe<Scalars['Boolean']['output']>;
  updateBestMetricInRange?: Maybe<Scalars['Boolean']['output']>;
  /** Adds the call notes to the conference */
  updateCallNotes?: Maybe<LeadActivity>;
  updateDashboardColumnOption?: Maybe<DashboardColumnOption>;
  updateDashboardSavedView?: Maybe<DashboardSavedView>;
  /** Update default audio input and output */
  updateDefaultAudio?: Maybe<User>;
  updateDefaultColumnOption?: Maybe<DashboardColumnOption>;
  /** Updates dispositions based on phase, person_spoke_to, and type. Uses static dispositions file. */
  updateDispositions?: Maybe<Scalars['Boolean']['output']>;
  /** Updates dispositions based on phase, person_spoke_to, and type. Uses static dispositions file. */
  updateDispositionsOrg?: Maybe<Scalars['Boolean']['output']>;
  updateEmailSubscriptionStatus?: Maybe<Lead>;
  /** Update folder name */
  updateFolderName?: Maybe<Folder>;
  /** Update the permission of the folder */
  updateFolderPermission?: Maybe<Folder>;
  updateHubSpotSyncRule?: Maybe<OrgHubSpotSync>;
  /** Edit a single lead to the organization in the JWT */
  updateLead?: Maybe<Lead>;
  updateLeadCallHoldStatus?: Maybe<LeadCallHoldStatus>;
  /** Update lead conference number */
  updateLeadConferenceNumber?: Maybe<Lead>;
  /** create new lead for algo purposes */
  updateManyLeadSource?: Maybe<Array<Maybe<Lead>>>;
  updateNoEventDate?: Maybe<OrgNoEventDate>;
  updateOneAutomatedCampaign?: Maybe<AutomatedCampaign>;
  updateOneBestMetricRecord?: Maybe<BestMetricRecord>;
  updateOneCustomField?: Maybe<CustomField>;
  updateOneCustomObject?: Maybe<CustomObject>;
  updateOneCustomObjectField?: Maybe<CustomObjectField>;
  updateOneLead?: Maybe<Lead>;
  updateOneNoteItem?: Maybe<NoteItem>;
  updateOneNotification?: Maybe<Notification>;
  updateOneObjectFKMapping?: Maybe<CustomObjectFieldForeignKey>;
  updateOneOrganization?: Maybe<Organization>;
  updateOneProduct?: Maybe<Product>;
  updateOneSMSItem?: Maybe<SmsItem>;
  updateOneUser?: Maybe<User>;
  /** Deprecated from now on */
  updateOrganizationRestRule?: Maybe<Organization>;
  updateOrganizationSettings?: Maybe<Organization>;
  /** Updates Date To Close for org */
  updateOrgDayToClose?: Maybe<Organization>;
  updateOrgFutureBookingSetting?: Maybe<FutureBookingSetting>;
  updateOrgLiveTransferSetting?: Maybe<LiveTransferSetting>;
  /** Updates MRR for org */
  updateOrgMRR?: Maybe<Organization>;
  /** Return success response based on the request */
  updateOrgPaymentSettings?: Maybe<OrganizationPaymentSettings>;
  updateOrgTimeslotAvailabilities?: Maybe<Array<Maybe<OrgTimeslotAvailability>>>;
  updateOrgTimeslotAvailability?: Maybe<OrgTimeslotAvailability>;
  /** Update sequences priority */
  updatePriorities?: Maybe<Scalars['Boolean']['output']>;
  /** Update whether reps can view all notes or just their own */
  updateRepNoteView?: Maybe<Organization>;
  updateRepTab?: Maybe<Scalars['Boolean']['output']>;
  /** Updates revenue planning items */
  updateRevenuePlan?: Maybe<Array<Maybe<RevenuePlanItem>>>;
  updateRevenuePlanningSetting?: Maybe<Organization>;
  updateROObjectActiveStatus?: Maybe<IntegrationReadOnlyObject>;
  /** Set the active status of a lead routing rule */
  updateRoutingRuleApplyToInboundConcierge?: Maybe<Rule>;
  /** Set the active status of a lead routing rule */
  updateRoutingRuleStatus?: Maybe<Rule>;
  /** Update the priority of the rules */
  updateRuleOrder?: Maybe<Scalars['Boolean']['output']>;
  updateSalesForceDomain?: Maybe<Organization>;
  /** Update organization sequence dialer notification settings */
  updateSequenceDialerNotifications?: Maybe<Scalars['Boolean']['output']>;
  /** mostly used for dev purposes */
  updateSeveralStatAggregation?: Maybe<Scalars['Boolean']['output']>;
  updateSFDCSyncRule?: Maybe<OrgSalesforceSync>;
  updateSingleLeadStatAggregate?: Maybe<Scalars['Boolean']['output']>;
  /** mostly used for dev purposes */
  updateSingleStatAggregation?: Maybe<Scalars['Boolean']['output']>;
  updateSite?: Maybe<Site>;
  /** Updates sms dialog for org */
  updateSMSDialog?: Maybe<Organization>;
  updateSMSSubscriptionStatus?: Maybe<Lead>;
  updateSubIndustryList?: Maybe<IndustryOption>;
  /** Updates sync status for gmail or outlook. */
  updateSync?: Maybe<User>;
  updateTeam?: Maybe<Team>;
  updateTemplateActiveStatus?: Maybe<PandaDocTemplate>;
  /** Set the active status of a lead routing rule */
  updateTranferRuleStatus?: Maybe<Rule>;
  /** Edit the user */
  updateUser?: Maybe<User>;
  updateUserDashboardV2Access?: Maybe<User>;
  /** Update User profile image on user object */
  updateUserProfileImage?: Maybe<User>;
  /** Update User settings in Settings screen */
  updateUserSettings?: Maybe<User>;
  /** Updates user status */
  updateUserStatus?: Maybe<User>;
  updateUserStatusTest?: Maybe<Scalars['Boolean']['output']>;
  /** Update user transfer type */
  updateUserTransferType?: Maybe<Scalars['Boolean']['output']>;
  /** Update white list of specific user */
  updateUserWhiteList?: Maybe<User>;
  uploadImage?: Maybe<ImageUpload>;
  upsertCustomObjectRow?: Maybe<SuccessResponse>;
  upsertLeadCustomObjectRowAssociation?: Maybe<LeadCustomObjectRowAssociation>;
  upsertOneBestMetricRecord: BestMetricRecord;
  upsertROObject?: Maybe<IntegrationReadOnlyObject>;
  /** Validate an existing domain on sendgrid */
  validateOrganizationDomain?: Maybe<Scalars['Json']['output']>;
  /** Return true if phone number is valid. False otherwise */
  validatePhoneNumber?: Maybe<Scalars['Boolean']['output']>;
  /** one-time use for backfill */
  viBackfillDropdown?: Maybe<Scalars['Boolean']['output']>;
  /** Only used to backfill an issue with leads going to resting from createScheduledEvent */
  viBackfillFirstName?: Maybe<Scalars['Boolean']['output']>;
};


export type MutationAdd_End_Sequence_Step_ActionArgs = {
  post_step_action: PostAction;
  step_id: Scalars['String']['input'];
  transfer_to_sequence_id?: InputMaybe<Scalars['String']['input']>;
};


export type MutationAddAssociateLeadArgs = {
  lead_input: AddAssociateLeadInput;
  primary_lead_id: Scalars['String']['input'];
};


export type MutationAddAutomatedCampaignArgs = {
  body?: InputMaybe<Scalars['String']['input']>;
  event: Scalars['String']['input'];
  increment?: InputMaybe<Scalars['Int']['input']>;
  increment_multiplier?: InputMaybe<Scalars['Int']['input']>;
  method_email?: InputMaybe<Scalars['Boolean']['input']>;
  method_sms?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  subject?: InputMaybe<Scalars['String']['input']>;
  when?: InputMaybe<When>;
};


export type MutationAddCallToFolderArgs = {
  conference_id: Scalars['String']['input'];
  folder_id: Scalars['String']['input'];
};


export type MutationAddCoachingNoteArgs = {
  conference_id: Scalars['String']['input'];
  end: Scalars['Int']['input'];
  note_id?: InputMaybe<Scalars['String']['input']>;
  start: Scalars['Int']['input'];
  text: Scalars['String']['input'];
};


export type MutationAddContactRequestArgs = {
  channel?: InputMaybe<ContactType>;
  country_code?: InputMaybe<Scalars['String']['input']>;
  lead_id: Scalars['String']['input'];
  mapping_order?: InputMaybe<Scalars['Int']['input']>;
  title: Scalars['String']['input'];
  type?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};


export type MutationAddCustomFieldArgs = {
  allow_reps_to_edit?: InputMaybe<Scalars['Boolean']['input']>;
  key: Scalars['String']['input'];
  options?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  type?: InputMaybe<CustomFieldType>;
  value?: InputMaybe<Scalars['String']['input']>;
  visible?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationAddEmailToErrorReportingArgs = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  emails?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type MutationAddJoinMeToScheduleItemArgs = {
  schedule_item_id: Scalars['String']['input'];
};


export type MutationAddLeadArgs = {
  address?: InputMaybe<Scalars['String']['input']>;
  address_2?: InputMaybe<Scalars['String']['input']>;
  business_name?: InputMaybe<Scalars['String']['input']>;
  channel?: InputMaybe<Channel>;
  city?: InputMaybe<Scalars['String']['input']>;
  claim?: InputMaybe<Scalars['Boolean']['input']>;
  conference_number?: InputMaybe<Scalars['String']['input']>;
  content?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  country_code?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  industry?: InputMaybe<Scalars['String']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  lead_source?: InputMaybe<Scalars['String']['input']>;
  mrr?: InputMaybe<Scalars['Int']['input']>;
  phone_number: Scalars['String']['input'];
  self_sourced?: InputMaybe<Scalars['Boolean']['input']>;
  source_type?: InputMaybe<Channel>;
  state?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  sub_industry?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};


export type MutationAddLeadNoteArgs = {
  lead_id: Scalars['String']['input'];
  note: Scalars['String']['input'];
};


export type MutationAddOrgConferenceNumberArgs = {
  number: Scalars['String']['input'];
  org_id: Scalars['String']['input'];
};


export type MutationAddOrUpdateAutomatedCampaignArgs = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  body?: InputMaybe<Scalars['String']['input']>;
  event: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  increment?: InputMaybe<Scalars['Int']['input']>;
  increment_multiplier?: InputMaybe<Scalars['Int']['input']>;
  method_email?: InputMaybe<Scalars['Boolean']['input']>;
  method_sms?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  subject?: InputMaybe<Scalars['String']['input']>;
  template_id?: InputMaybe<Scalars['String']['input']>;
  when?: InputMaybe<When>;
};


export type MutationAddOrUpdateCustomRuleSettingArgs = {
  data: CustomTransferRuleInput;
  global_rule_id?: InputMaybe<Scalars['String']['input']>;
};


export type MutationAddOrUpdateFederalHolidayArgs = {
  data: FederalHolidayInput;
};


export type MutationAddOrUpdateGlobalTransferRuleArgs = {
  data: GlobalTransferRuleInput;
  global_rule_id?: InputMaybe<Scalars['String']['input']>;
};


export type MutationAddorUpdateIntegrationMappingArgs = {
  integration_type: Integration_Type;
  mapping: IntegrationMappingInput;
  mapping_id?: InputMaybe<Scalars['String']['input']>;
};


export type MutationAddOrUpdateProductsArgs = {
  products: Array<ProductsInputNew>;
};


export type MutationAddOrUpdateProducts_DeprecatedArgs = {
  products: Array<ProductsInput>;
};


export type MutationAddProductArgs = {
  product: ProductInputType;
  sale_id: Scalars['String']['input'];
};


export type MutationAddSiteArgs = {
  site_name: Scalars['String']['input'];
};


export type MutationAddSystemFieldArgs = {
  label: Scalars['String']['input'];
  subFields?: InputMaybe<Array<Scalars['String']['input']>>;
  systemFieldType: SystemFieldType;
};


export type MutationAddToCustomQueueArgs = {
  lead_id: Scalars['String']['input'];
};


export type MutationAddUserArgs = {
  user?: InputMaybe<AddUserInput>;
};


export type MutationAddUserToFolderArgs = {
  folder_id: Scalars['String']['input'];
  user_id: Scalars['String']['input'];
};


export type MutationAdjustAlgoMultipliersArgs = {
  organization_id?: InputMaybe<Scalars['String']['input']>;
};


export type MutationAdjustRestingTestArgs = {
  lead_id: Scalars['String']['input'];
};


export type MutationAdjustRestingTestSingleArgs = {
  lead_id: Scalars['String']['input'];
};


export type MutationArrangeLeadQueueToTheEndArgs = {
  end_rank: Scalars['Int']['input'];
  start_rank: Scalars['Int']['input'];
  user_email: Scalars['String']['input'];
};


export type MutationAssignLeadsAdhocArgs = {
  clear_current_intent?: InputMaybe<Scalars['Boolean']['input']>;
  industry_white_list?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  min_date_time?: InputMaybe<Scalars['DateTime']['input']>;
  quota?: InputMaybe<Scalars['Int']['input']>;
  user_email: Scalars['String']['input'];
};


export type MutationAssignUserToTeamArgs = {
  team_id?: InputMaybe<Scalars['String']['input']>;
  user_id: Scalars['String']['input'];
};


export type MutationAssociateMultipleLeadsArgs = {
  associate_lead_ids: Array<Scalars['String']['input']>;
  primary_lead_id: Scalars['String']['input'];
  rep_id?: InputMaybe<Scalars['String']['input']>;
};


export type MutationAuthorizeJoinMeArgs = {
  code: Scalars['String']['input'];
};


export type MutationBackfillAlgoDataArgs = {
  lowerbound_date: Scalars['DateTime']['input'];
  organization_id: Scalars['String']['input'];
};


export type MutationBackFillAllLeadClosedByArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  take: Scalars['Int']['input'];
};


export type MutationBackFillAllLeadFirstdemoDateArgs = {
  user_email: Scalars['String']['input'];
};


export type MutationBackFillAllLeadSetByArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  take: Scalars['Int']['input'];
};


export type MutationBackFillAllStatDataArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type MutationBackfillBestRecordArgs = {
  run_int?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationBackfillCsvHistoryLinkArgs = {
  organization_id: Scalars['String']['input'];
};


export type MutationBackfillCustomerArgs = {
  organization_id: Scalars['String']['input'];
};


export type MutationBackfillDaysSetOutArgs = {
  org_id: Scalars['String']['input'];
};


export type MutationBackfillDncArgs = {
  organization_id: Scalars['String']['input'];
};


export type MutationBackfillImpliedActionArgs = {
  organization_id: Scalars['String']['input'];
};


export type MutationBackFillLeadAggretationSiteIdsArgs = {
  take?: Scalars['Int']['input'];
};


export type MutationBackfillLeadCreationSourceArgs = {
  show_teams?: InputMaybe<Scalars['Boolean']['input']>;
  team_id?: InputMaybe<Scalars['String']['input']>;
  view_filter?: InputMaybe<LeadPoolViewFilter>;
};


export type MutationBackfillLeadStatAggregateArgs = {
  max_date?: InputMaybe<Scalars['String']['input']>;
  min_date: Scalars['String']['input'];
  missing_stat?: InputMaybe<Scalars['Boolean']['input']>;
  organization_id: Scalars['String']['input'];
};


export type MutationBackfillLeadStatAggregateDirectArgs = {
  min_date: Scalars['String']['input'];
  organization_id: Scalars['String']['input'];
};


export type MutationBackfillLeadStatAggregateNoQueueArgs = {
  org_id?: InputMaybe<Scalars['String']['input']>;
};


export type MutationBackfillLeadValueArgs = {
  organization_id: Scalars['String']['input'];
};


export type MutationBackfillLvLeadArgs = {
  lead_id: Scalars['String']['input'];
};


export type MutationBackfillOrgLeadLastClosedDateArgs = {
  days_after_first_contact: Scalars['Int']['input'];
  organization_id: Scalars['String']['input'];
};


export type MutationBackfillPipelineLeadStatsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type MutationBackFillRescheduleSetsOldArgs = {
  organization_id: Scalars['String']['input'];
};


export type MutationBackFillRoleAndSiteIdsArgs = {
  role_only?: Scalars['Boolean']['input'];
  site_only?: Scalars['Boolean']['input'];
  take?: Scalars['Int']['input'];
};


export type MutationBackfillSaleCycleBulkArgs = {
  max_date?: InputMaybe<Scalars['DateTime']['input']>;
  min_date?: InputMaybe<Scalars['DateTime']['input']>;
};


export type MutationBackfillSaleStatsCycleBulkArgs = {
  max_date?: InputMaybe<Scalars['DateTime']['input']>;
  min_date?: InputMaybe<Scalars['DateTime']['input']>;
};


export type MutationBackfillSaleStatsCycleListArgs = {
  activity_ids: Array<Scalars['String']['input']>;
};


export type MutationBackfillSingleSaleCycleArgs = {
  lead_activity_id: Scalars['String']['input'];
};


export type MutationBackfillSiRoleArgs = {
  lowerbound_date: Scalars['DateTime']['input'];
  org_id: Scalars['String']['input'];
  upperbound_date: Scalars['DateTime']['input'];
};


export type MutationBackfillSiRoleTestArgs = {
  lead_activity_ids: Array<Scalars['String']['input']>;
};


export type MutationBackfillUserVisibleCallReportPipeLineColumnsArgs = {
  deprecated_fields: Array<Scalars['String']['input']>;
  organization_id?: InputMaybe<Scalars['String']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
};


export type MutationBulkAddLeadsCustomQueueArgs = {
  lead_ids: Array<Scalars['String']['input']>;
};


export type MutationBulkDeleteLeadsArgs = {
  industry?: InputMaybe<Scalars['String']['input']>;
  lead_creation_source?: InputMaybe<Leadcreationsource>;
  lead_source?: InputMaybe<Scalars['String']['input']>;
  team_id?: InputMaybe<Scalars['String']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
};


export type MutationBulkDeleteUsersArgs = {
  user_ids: Array<Scalars['String']['input']>;
};


export type MutationBulkEditUsersArgs = {
  param: UserBatchEditInput;
  user_ids: Array<Scalars['String']['input']>;
};


export type MutationBulkStarLeadsArgs = {
  lead_ids: Array<Scalars['String']['input']>;
};


export type MutationBulkTransferLeadArgs = {
  dest_user_id: Scalars['String']['input'];
  ignore_future_event_warning?: InputMaybe<Scalars['Boolean']['input']>;
  lead_ids: Array<Scalars['String']['input']>;
  set_own?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationBulkUnassignLeadArgs = {
  ignore_future_event_warning?: InputMaybe<Scalars['Boolean']['input']>;
  lead_ids: Array<Scalars['String']['input']>;
};


export type MutationCallMeNowArgs = {
  conciergeEventId: Scalars['String']['input'];
};


export type MutationCancelCallMeNowArgs = {
  callMeNowId: Scalars['String']['input'];
};


export type MutationCancelCurrentLeadImportArgs = {
  lead_import_id: Scalars['String']['input'];
};


export type MutationChangeDispositionOptionArgs = {
  disposition_id: Scalars['String']['input'];
  lead_activity_id: Scalars['String']['input'];
};


export type MutationChangeDispositionRepArgs = {
  lead_activity_id: Scalars['String']['input'];
  new_rep_id: Scalars['String']['input'];
};


export type MutationCheckSelfSourceMappingArgs = {
  user_email: Scalars['String']['input'];
};


export type MutationClaimLeadViaPhoneArgs = {
  country_code?: InputMaybe<Scalars['String']['input']>;
  primary_phone_number: Scalars['String']['input'];
};


export type MutationClone_SequenceArgs = {
  sequence_id: Scalars['String']['input'];
};


export type MutationComputeLeadValueLeadArgs = {
  lead_id: Scalars['String']['input'];
};


export type MutationComputeOrgLeadValueArgs = {
  max_update_time?: InputMaybe<Scalars['DateTime']['input']>;
  min_update_time?: InputMaybe<Scalars['DateTime']['input']>;
  org_id?: InputMaybe<Scalars['String']['input']>;
};


export type MutationComputeOrgLeadValueBackfillArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  org_id?: InputMaybe<Scalars['String']['input']>;
};


export type MutationConfigureAlgoArgs = {
  enable_modified_metrics: Scalars['Boolean']['input'];
  estimated_average_num_of_weekly_overbooked_demo_slots?: InputMaybe<Scalars['Int']['input']>;
  inbound_close_rate: Scalars['Float']['input'];
  inbound_dm_connect_rate: Scalars['Float']['input'];
  inbound_lt_hold_rate: Scalars['Float']['input'];
  inbound_revenue_value_of_sale: Scalars['Float']['input'];
  inbound_scheduled_hold_rate: Scalars['Float']['input'];
  inbound_set_rate: Scalars['Float']['input'];
  initial_sdr_sets_reps_day: Scalars['Float']['input'];
  maxium_day_lag_set_to_schedule: Scalars['Int']['input'];
  outbound_close_rate: Scalars['Float']['input'];
  outbound_dm_connect_rate: Scalars['Float']['input'];
  outbound_lt_hold_rate: Scalars['Float']['input'];
  outbound_revenue_value_of_sale: Scalars['Float']['input'];
  outbound_scheduled_hold_rate: Scalars['Float']['input'];
  outbound_set_rate: Scalars['Float']['input'];
  resting_period_after_unsuccessful_cold_call: Scalars['Float']['input'];
  resting_period_after_unsuccessful_demo: Scalars['Float']['input'];
};


export type MutationConfigureGlobalScheduleArgs = {
  data?: InputMaybe<OrganizationHoursInput>;
};


export type MutationConfigureHolidayTimeOffArgs = {
  organization_holidays?: InputMaybe<Array<InputMaybe<OrganizationHolidaysInput>>>;
};


export type MutationConfigureProductPricingArgs = {
  data?: InputMaybe<ProductInput>;
};


export type MutationCountScheduledItemForLeadArgs = {
  lead_id: Scalars['String']['input'];
};


export type MutationCreateConciergeEventArgs = {
  form_data?: InputMaybe<Scalars['Json']['input']>;
};


export type MutationCreateConciergeReportingArgs = {
  bucket_name: Scalars['String']['input'];
  org_id?: InputMaybe<Scalars['String']['input']>;
};


export type MutationCreateDashboardSavedViewArgs = {
  data: DashBoardSavedViewArgument;
  name: Scalars['String']['input'];
};


export type MutationCreateDefaultSaleConfigArgs = {
  organization_ids: Array<Scalars['String']['input']>;
};


export type MutationCreateDocumentArgs = {
  lead_id?: InputMaybe<Scalars['String']['input']>;
  previewPandaDocData: PandaDocMappingInput;
  sale_id?: InputMaybe<Scalars['String']['input']>;
  template_id: Scalars['String']['input'];
};


export type MutationCreateGeneralTimeScheduledEventArgs = {
  action?: InputMaybe<Scalars['String']['input']>;
  anytime_after?: InputMaybe<Scalars['DateTime']['input']>;
  anytime_before?: InputMaybe<Scalars['DateTime']['input']>;
  call?: InputMaybe<Scalars['Boolean']['input']>;
  call_sid?: InputMaybe<Scalars['String']['input']>;
  day?: InputMaybe<Scalars['DateTime']['input']>;
  disposition_id?: InputMaybe<Scalars['String']['input']>;
  disposition_type?: InputMaybe<Dispositiontype>;
  from_custom_call_queue?: InputMaybe<Scalars['Boolean']['input']>;
  intent_id?: InputMaybe<Scalars['String']['input']>;
  lead_id: Scalars['String']['input'];
  month?: InputMaybe<Scalars['DateTime']['input']>;
  person_spoke_to?: InputMaybe<Callresult>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
  week_end?: InputMaybe<Scalars['DateTime']['input']>;
  week_start?: InputMaybe<Scalars['DateTime']['input']>;
};


export type MutationCreateIntegrationErrorReportCustomDatesArgs = {
  end_date?: InputMaybe<Scalars['DateTime']['input']>;
  integration_type: Integration_Type;
  send_email: Scalars['Boolean']['input'];
  send_url: Scalars['Boolean']['input'];
  start_date?: InputMaybe<Scalars['DateTime']['input']>;
};


export type MutationCreateJoinMeArgs = {
  end: Scalars['DateTime']['input'];
  start: Scalars['DateTime']['input'];
};


export type MutationCreateManyNewLeadArgs = {
  number?: InputMaybe<Scalars['Int']['input']>;
};


export type MutationCreateManyNewRepArgs = {
  number?: InputMaybe<Scalars['Int']['input']>;
};


export type MutationCreateNewFolderArgs = {
  name: Scalars['String']['input'];
};


export type MutationCreateNoEventDateArgs = {
  data: OrgNoEventDateInput;
};


export type MutationCreateOneBestMetricRecordArgs = {
  data: BestMetricRecordCreateInput;
};


export type MutationCreateOneCustomObjectArgs = {
  field_name: Scalars['String']['input'];
  field_type: CustomFieldType;
  name: Scalars['String']['input'];
};


export type MutationCreateOneCustomObjectFieldArgs = {
  data: CustomObjectDataInput;
};


export type MutationCreateOneObjectFkMappingArgs = {
  data: CustomFieldFkDataInput;
};


export type MutationCreateOneProductArgs = {
  data: ProductCreateInput;
};


export type MutationCreateOneTwilioInfoArgs = {
  data: TwilioInfoCreateInput;
};


export type MutationCreateOrganizationShellArgs = {
  add_local_numbers?: InputMaybe<Scalars['Boolean']['input']>;
  dev_account?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};


export type MutationCreateOrSequenceDashboardViewArgs = {
  SequenceDashboardViewInput: SequenceDashboardViewInput;
};


export type MutationCreateOrUpdateCustomTransferRuleArgs = {
  data: TransferRuleInput;
  rule_id?: InputMaybe<Scalars['String']['input']>;
};


export type MutationCreateOrUpdateLeadRoutingRuleArgs = {
  conditions: Array<RoutingCondition>;
  deferral_date_time?: InputMaybe<Scalars['DateTime']['input']>;
  distribution_method?: InputMaybe<Distribution_Method>;
  lead_creation_status?: InputMaybe<LeadCreationStatus>;
  los_rules?: InputMaybe<Array<Los>>;
  name: Scalars['String']['input'];
  rep_ids: Array<Scalars['String']['input']>;
  rep_priority_rule?: InputMaybe<RepPriorityRule>;
  rep_selection_method?: InputMaybe<Rep_Selection_Method>;
  rule_id?: InputMaybe<Scalars['String']['input']>;
  rule_type?: InputMaybe<RuleType>;
};


export type MutationCreateOrUpdateOrganizationDomainArgs = {
  domain: Scalars['String']['input'];
};


export type MutationCreateOrUpdateSaleConfigPagesArgs = {
  input: PagesInput;
};


export type MutationCreateOrUpdateSequenceArgs = {
  SequenceInput: SequenceInput;
};


export type MutationCreateOrUpdateSequenceEntryCriteriaArgs = {
  SequenceCriteriaInput: SequenceCriteriaInput;
};


export type MutationCreateOrUpdateSequenceStepArgs = {
  SequenceStepInput: SequenceStepInput;
};


export type MutationCreateOrUpdateTemplateArgs = {
  TemplateInput: TemplateInput;
};


export type MutationCreateRandomActivitiesArgs = {
  number?: InputMaybe<Scalars['Int']['input']>;
};


export type MutationCreateRandomConferenceArgs = {
  number?: InputMaybe<Scalars['Int']['input']>;
};


export type MutationCreateScheduledEventArgs = {
  action?: InputMaybe<Scalars['String']['input']>;
  calendarInvite?: InputMaybe<Scalars['Boolean']['input']>;
  call?: InputMaybe<Scalars['Boolean']['input']>;
  call_sid?: InputMaybe<Scalars['String']['input']>;
  disposition_id?: InputMaybe<Scalars['String']['input']>;
  disposition_type?: InputMaybe<Dispositiontype>;
  end_time?: InputMaybe<Scalars['DateTime']['input']>;
  event_description?: InputMaybe<Scalars['String']['input']>;
  event_title?: InputMaybe<Scalars['String']['input']>;
  from_custom_call_queue?: InputMaybe<Scalars['Boolean']['input']>;
  guest_list?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  intent_id?: InputMaybe<Scalars['String']['input']>;
  joinMe_id?: InputMaybe<Scalars['String']['input']>;
  joinMe_meeting?: InputMaybe<Scalars['Boolean']['input']>;
  lead_id: Scalars['String']['input'];
  old_schedule_item_id?: InputMaybe<Scalars['String']['input']>;
  person_spoke_to?: InputMaybe<Callresult>;
  primary_email?: InputMaybe<Scalars['String']['input']>;
  start_time: Scalars['DateTime']['input'];
  timezone?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<IntentType>;
  user_id?: InputMaybe<Scalars['String']['input']>;
};


export type MutationCreateScheduledEventWidgetArgs = {
  concierge_event_id?: InputMaybe<Scalars['String']['input']>;
  end_time?: InputMaybe<Scalars['DateTime']['input']>;
  lead_id: Scalars['String']['input'];
  rep_ids?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  start_time: Scalars['DateTime']['input'];
  timezone?: InputMaybe<Scalars['String']['input']>;
};


export type MutationCreateTestLeadArgs = {
  phone_number: Scalars['String']['input'];
};


export type MutationCreateTwilioFeedbackArgs = {
  category_reason: Array<FeedBackCategoryReason>;
  feedback_text?: InputMaybe<Scalars['String']['input']>;
  quality: Scalars['Int']['input'];
};


export type MutationCustomTaskAckArgs = {
  customTaskAckInput: CustomTaskAckInput;
};


export type MutationDedupeDispositionsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  org_id?: InputMaybe<Scalars['String']['input']>;
  user_email?: InputMaybe<Scalars['String']['input']>;
};


export type MutationDelete_SequenceArgs = {
  sequence_id: Scalars['String']['input'];
};


export type MutationDelete_StepArgs = {
  step_id: Scalars['String']['input'];
};


export type MutationDeleteContactRequestArgs = {
  contact_id: Scalars['String']['input'];
};


export type MutationDeleteCustomFieldArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteDashboardSavedViewArgs = {
  saved_view_id: Scalars['String']['input'];
};


export type MutationDeleteFederalHolidayArgs = {
  title: Scalars['String']['input'];
};


export type MutationDeleteLeadCustomObjectRowAssociationArgs = {
  lead_id: Scalars['String']['input'];
  row_id: Scalars['String']['input'];
};


export type MutationDeleteNoEventDateArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteNoteArgs = {
  lead_activity_id: Scalars['String']['input'];
};


export type MutationDeleteOneAutomatedCampaignArgs = {
  automated_campaign_id: Scalars['String']['input'];
};


export type MutationDeleteOneBestMetricRecordArgs = {
  where: BestMetricRecordWhereUniqueInput;
};


export type MutationDeleteOneCustomObjectArgs = {
  custom_object_id: Scalars['String']['input'];
};


export type MutationDeleteOneCustomObjectFieldArgs = {
  custom_object_field_id: Scalars['String']['input'];
};


export type MutationDeleteOneNoteItemArgs = {
  note_item_id: Scalars['String']['input'];
};


export type MutationDeleteOneNotificationArgs = {
  where: NotificationWhereUniqueInput;
};


export type MutationDeleteOneProductArgs = {
  product_id: Scalars['String']['input'];
};


export type MutationDeleteProductArgs = {
  sale_item_id: Scalars['String']['input'];
};


export type MutationDeleteProductAdminArgs = {
  product_id: Scalars['String']['input'];
};


export type MutationDeleteRoObjectArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteScheduledEventArgs = {
  disposition_id: Scalars['String']['input'];
  lead_id: Scalars['String']['input'];
  schedule_item_id: Scalars['String']['input'];
};


export type MutationDeleteSequenceDashboardViewArgs = {
  view_id: Scalars['String']['input'];
};


export type MutationDeleteSiteArgs = {
  site_id: Scalars['String']['input'];
};


export type MutationDeleteTemplateArgs = {
  template_id: Scalars['String']['input'];
};


export type MutationDeleteUserArgs = {
  user_id: Scalars['String']['input'];
};


export type MutationDisassociateLeadArgs = {
  associate_lead_id: Scalars['String']['input'];
  primary_lead_id: Scalars['String']['input'];
  rep_id?: InputMaybe<Scalars['String']['input']>;
};


export type MutationDisconnectIntegrationArgs = {
  integration_type: Integration_Type;
};


export type MutationEditCalendarEventArgs = {
  dest_user_id?: InputMaybe<Scalars['String']['input']>;
  disposition_id?: InputMaybe<Scalars['String']['input']>;
  event_description: Scalars['String']['input'];
  event_end_time?: InputMaybe<Scalars['DateTime']['input']>;
  event_location: Scalars['String']['input'];
  event_start_time: Scalars['DateTime']['input'];
  event_title: Scalars['String']['input'];
  guest_list?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id: Scalars['String']['input'];
  joinMe_id?: InputMaybe<Scalars['String']['input']>;
  overflow_time?: InputMaybe<Scalars['Int']['input']>;
  event_timezone?: InputMaybe<Scalars['String']['input']>;
};


export type MutationEditContactRequestArgs = {
  channel?: InputMaybe<ContactType>;
  contact_id?: InputMaybe<Scalars['String']['input']>;
  country_code?: InputMaybe<Scalars['String']['input']>;
  lead_id: Scalars['String']['input'];
  mapping_order?: InputMaybe<Scalars['Int']['input']>;
  primary_email?: InputMaybe<Scalars['Boolean']['input']>;
  primary_phone_number?: InputMaybe<Scalars['Boolean']['input']>;
  title: Scalars['String']['input'];
  type?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};


export type MutationEditCustomFieldsForLeadArgs = {
  custom_fields?: InputMaybe<Array<InputMaybe<CustomFieldInput>>>;
  lead_id?: InputMaybe<Scalars['String']['input']>;
};


export type MutationEditNoteArgs = {
  lead_activity_id: Scalars['String']['input'];
  notes: Scalars['String']['input'];
};


export type MutationEditProductArgs = {
  product: ProductInputType;
  sale_id: Scalars['String']['input'];
  sale_item_id: Scalars['String']['input'];
};


export type MutationEditSalesTeamColumnOptionsArgs = {
  column: Array<Scalars['String']['input']>;
};


export type MutationEditScheduledEventArgs = {
  end_time?: InputMaybe<Scalars['DateTime']['input']>;
  id: Scalars['String']['input'];
  start_time: Scalars['DateTime']['input'];
};


export type MutationEditTermsArgs = {
  contract_duration?: InputMaybe<Scalars['String']['input']>;
  custom_fields?: InputMaybe<Array<InputMaybe<CustomFieldInput>>>;
  lead_id: Scalars['String']['input'];
  notes?: InputMaybe<Scalars['String']['input']>;
  payment_terms?: InputMaybe<Scalars['String']['input']>;
  prepayment?: InputMaybe<Scalars['Int']['input']>;
  sale_id: Scalars['String']['input'];
};


export type MutationEditTimeOffRequestArgs = {
  end_time: Scalars['DateTime']['input'];
  schedule_item_id: Scalars['String']['input'];
  start_time: Scalars['DateTime']['input'];
};


export type MutationEnterSequenceArgs = {
  lead_activity_id: Scalars['String']['input'];
  sequence_id: Scalars['String']['input'];
};


export type MutationExecuteActivitiesAndLeadsReportsArgs = {
  email_list: Array<Scalars['String']['input']>;
  organization_id: Scalars['String']['input'];
  organization_name: Scalars['String']['input'];
};


export type MutationExecuteActivitiesReportArgs = {
  email_list: Array<Scalars['String']['input']>;
  organization_id: Scalars['String']['input'];
  organization_name: Scalars['String']['input'];
};


export type MutationExecuteAssignedColdCallReportArgs = {
  emailAddresses: Array<Scalars['String']['input']>;
};


export type MutationExecuteInboundConciergeReportArgs = {
  email_list: Array<Scalars['String']['input']>;
  organization_id: Scalars['String']['input'];
  organization_name: Scalars['String']['input'];
};


export type MutationExecuteIntegrationErrorReportArgs = {
  integration_type: Integration_Type;
  org_id: Scalars['String']['input'];
};


export type MutationExecuteLeadActivityPastDayReportViArgs = {
  email_list: Array<Scalars['String']['input']>;
  organization_name: Scalars['String']['input'];
};


export type MutationExecuteLeadCreatedPastDayReportArgs = {
  email_list: Array<Scalars['String']['input']>;
  organization_id: Scalars['String']['input'];
  organization_name: Scalars['String']['input'];
};


export type MutationExecuteMyCoachingNotesReportArgs = {
  email: Scalars['String']['input'];
};


export type MutationExecuteNumberOfAssignedLeadsReportArgs = {
  email_list: Array<Scalars['String']['input']>;
  org_id: Scalars['String']['input'];
  org_name?: InputMaybe<Scalars['String']['input']>;
};


export type MutationExecuteOllyOllySalesReportArgs = {
  email_list: Array<Scalars['String']['input']>;
};


export type MutationExecuteOrganizationAllHoldsReportArgs = {
  email_list: Array<Scalars['String']['input']>;
  lowerbound_date?: InputMaybe<Scalars['DateTime']['input']>;
  organization_id: Scalars['String']['input'];
  upperbound_date?: InputMaybe<Scalars['DateTime']['input']>;
};


export type MutationExecuteOrganizationCoachingNotesReportArgs = {
  organization_id: Scalars['String']['input'];
};


export type MutationExecuteOrganizationReleasedLeadsReportArgs = {
  email_list: Array<Scalars['String']['input']>;
  lowerbound_date?: InputMaybe<Scalars['DateTime']['input']>;
  organization_id: Scalars['String']['input'];
  upperbound_date?: InputMaybe<Scalars['DateTime']['input']>;
};


export type MutationExecuteOrganizationSkippedSequenceLeadsReportArgs = {
  email_list: Array<Scalars['String']['input']>;
  lowerbound_date?: InputMaybe<Scalars['DateTime']['input']>;
  organization_id: Scalars['String']['input'];
  upperbound_date?: InputMaybe<Scalars['DateTime']['input']>;
};


export type MutationExecutePipelineReportArgs = {
  emailAddresses: Array<Scalars['String']['input']>;
  org_id: Scalars['String']['input'];
};


export type MutationExecuteSelfSourcedLeadsReportArgs = {
  email_list: Array<Scalars['String']['input']>;
  organization_id: Scalars['String']['input'];
  organization_name: Scalars['String']['input'];
};


export type MutationExecuteSelfSourceNoIndustryLeadsReportArgs = {
  email_list: Array<Scalars['String']['input']>;
  organization_id: Scalars['String']['input'];
  organization_name: Scalars['String']['input'];
};


export type MutationExecuteSurefireSalesReportArgs = {
  email_list: Array<Scalars['String']['input']>;
  organization_id: Scalars['String']['input'];
};


export type MutationExecuteTimeToFirstContactReportArgs = {
  email_list: Array<Scalars['String']['input']>;
  organization_id: Scalars['String']['input'];
  organization_name: Scalars['String']['input'];
};


export type MutationExecuteUniqueLeadsDialedReportArgs = {
  email_list: Array<Scalars['String']['input']>;
  org_id: Scalars['String']['input'];
  org_name?: InputMaybe<Scalars['String']['input']>;
};


export type MutationExitDialerArgs = {
  lead_activity_id: Scalars['String']['input'];
};


export type MutationExportCallReportArgs = {
  column_options: Array<CallReportListSortOption>;
  dashboard_detail_args: DashboardDetailArgument;
};


export type MutationExportDashboardSavedViewArgs = {
  dashboard_type: DashboardType;
  saved_view_id: Scalars['String']['input'];
};


export type MutationExportDashboardViewArgs = {
  dashboard_args: DashBoardSavedViewArgument;
  dashboard_type: DashboardType;
};


export type MutationExportErrorReportArgs = {
  intergration_args: IntegrationLogArgument;
};


export type MutationExportEventCallReportArgs = {
  column_options: Array<CallReportListSortOption>;
  dashboard_detail_args: DashboardDetailArgument;
};


export type MutationExportFilteredLeadArgs = {
  ascending?: Scalars['Boolean']['input'];
  lead_filter?: InputMaybe<LeadFilter>;
  my_leads?: Scalars['Boolean']['input'];
  order_by?: InputMaybe<Scalars['String']['input']>;
};


export type MutationExportIntegrationPickListOptionsArgs = {
  integration_mapping_id: Scalars['String']['input'];
  integration_type: Integration_Type;
};


export type MutationExportLeadsArgs = {
  industry?: InputMaybe<Scalars['String']['input']>;
  lead_creation_source?: InputMaybe<Scalars['String']['input']>;
  lead_source?: InputMaybe<Scalars['String']['input']>;
  metric?: InputMaybe<LeadPoolMetrics>;
  organization_id?: InputMaybe<Scalars['String']['input']>;
  team_id?: InputMaybe<Scalars['String']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
};


export type MutationExportPipelineReportArgs = {
  column_options: Array<Scalars['String']['input']>;
  funnel?: InputMaybe<Scalars['String']['input']>;
  pipeline_export_arg: PipelineExportArg;
};


export type MutationFavoriteLeadArgs = {
  lead_id: Scalars['String']['input'];
};


export type MutationFindAndEnterSequenceArgs = {
  lead_ids: Array<Scalars['String']['input']>;
};


export type MutationGenerateYelpDefaultIndustriesArgs = {
  org_id: Scalars['String']['input'];
};


export type MutationHitconciergenotificationArgs = {
  business_name: Scalars['String']['input'];
  callMeNowId: Scalars['String']['input'];
  expires_at: Scalars['DateTime']['input'];
  lead_first_name: Scalars['String']['input'];
  lead_last_name: Scalars['String']['input'];
  phone_number: Scalars['String']['input'];
  user_id: Scalars['String']['input'];
};


export type MutationIgnoreLeadUploadWarningArgs = {
  force_upload?: InputMaybe<Scalars['Boolean']['input']>;
  upload_id: Scalars['String']['input'];
};


export type MutationIgnoreUserUploadWarningArgs = {
  upload_id: Scalars['String']['input'];
};


export type MutationInboundConciergeClosedArgs = {
  conciergeEventId: Scalars['String']['input'];
};


export type MutationLeadAlgoAssignmentTestArgs = {
  lead_id: Scalars['String']['input'];
  user_id: Scalars['String']['input'];
};


export type MutationLeadAlgoAssignmentTestUserArgs = {
  num_leads_to_fetch?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  user_id: Scalars['String']['input'];
};


export type MutationLeadAlgoSetupOrgArgs = {
  organization_id: Scalars['String']['input'];
};


export type MutationLeadBatchPhaseUpdateArgs = {
  lead_ids: Array<Scalars['String']['input']>;
  phase: Phase;
};


export type MutationLocalResetPasswordArgs = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};


export type MutationLogDispositionArgs = {
  call?: InputMaybe<Scalars['Boolean']['input']>;
  call_sid?: InputMaybe<Scalars['String']['input']>;
  disposition_id: Scalars['String']['input'];
  from_custom_call_queue?: InputMaybe<Scalars['Boolean']['input']>;
  intent_id?: InputMaybe<Scalars['String']['input']>;
  lead_id: Scalars['String']['input'];
  release_lead?: InputMaybe<Scalars['Boolean']['input']>;
  scheduled_event_ack?: Scalars['Boolean']['input'];
  try_alternate_contact?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationLoginArgs = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};


export type MutationLogoutArgs = {
  session_token: Scalars['String']['input'];
};


export type MutationLogReviewCallReportArgs = {
  conference_id: Scalars['String']['input'];
  reviewer_ids?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type MutationLogTimeOffRequestArgs = {
  end_time: Scalars['DateTime']['input'];
  start_time: Scalars['DateTime']['input'];
};


export type MutationMakePrimaryArgs = {
  associate_lead_id: Scalars['String']['input'];
  primary_lead_id: Scalars['String']['input'];
};


export type MutationMakeSaleArgs = {
  call?: InputMaybe<Scalars['Boolean']['input']>;
  call_sid?: InputMaybe<Scalars['String']['input']>;
  channel?: InputMaybe<Channel>;
  commission_splits?: InputMaybe<Array<InputMaybe<CommissionSplitInputType>>>;
  contract_duration?: InputMaybe<Scalars['String']['input']>;
  from_custom_call_queue?: InputMaybe<Scalars['Boolean']['input']>;
  intent_id?: InputMaybe<Scalars['String']['input']>;
  lead_id: Scalars['String']['input'];
  mrr?: InputMaybe<Scalars['Float']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  pandadoc_sale_id?: InputMaybe<Scalars['String']['input']>;
  payment_terms?: InputMaybe<Scalars['String']['input']>;
  person_spoke_to: Callresult;
  prepayment?: InputMaybe<Scalars['Int']['input']>;
  products: Array<ProductInputType>;
};


export type MutationManualPushSaleToSfArgs = {
  lead_activity_id: Scalars['String']['input'];
};


export type MutationMergeAssociateLeadArgs = {
  associate_lead_id: Scalars['String']['input'];
  primary_lead_id: Scalars['String']['input'];
  rep_id?: InputMaybe<Scalars['String']['input']>;
};


export type MutationMoveCallFromFolderArgs = {
  conference_id: Scalars['String']['input'];
  des_folder_id: Scalars['String']['input'];
  src_folder_id: Scalars['String']['input'];
};


export type MutationNewPasswordArgs = {
  password: Scalars['String']['input'];
  token: Scalars['String']['input'];
};


export type MutationOrderLeadIntentBySsArgs = {
  user_email: Scalars['String']['input'];
};


export type MutationPerformNotificationActionArgs = {
  accept?: InputMaybe<Scalars['Boolean']['input']>;
  notification_id: Scalars['String']['input'];
  relevant_id: Scalars['String']['input'];
  type: NotificationType;
};


export type MutationPrepareForScheduleCallbackArgs = {
  lead_intent_id: Scalars['String']['input'];
  prepare: Scalars['Boolean']['input'];
};


export type MutationProvisionLocalNumbersArgs = {
  country_code?: InputMaybe<Scalars['String']['input']>;
  organization_id: Scalars['String']['input'];
  test_limit?: InputMaybe<Scalars['Int']['input']>;
};


export type MutationPurgeOrgIndustryOptionArgs = {
  org_id: Scalars['String']['input'];
};


export type MutationPushSampleToS3Args = {
  bucket_name: Scalars['String']['input'];
  org_id: Scalars['String']['input'];
};


export type MutationReassignInProgressLeadsArgs = {
  dest_email: Scalars['String']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  source_email: Scalars['String']['input'];
};


export type MutationReassignLeadArgs = {
  dest_user_id: Scalars['String']['input'];
  ignore_future_event_warning?: InputMaybe<Scalars['Boolean']['input']>;
  lead_id: Scalars['String']['input'];
  override?: InputMaybe<Scalars['Boolean']['input']>;
  set_own?: InputMaybe<Scalars['Boolean']['input']>;
  source_user_id?: InputMaybe<Scalars['String']['input']>;
};


export type MutationReassignLeadsArgs = {
  dest_email: Scalars['String']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  source_email: Scalars['String']['input'];
};


export type MutationReassignSkippedLeadsArgs = {
  des_email: Scalars['String']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  src_email: Scalars['String']['input'];
};


export type MutationRecalculateLeadPoolArgs = {
  organization_id?: InputMaybe<Scalars['String']['input']>;
  team_id?: InputMaybe<Scalars['String']['input']>;
};


export type MutationRefillLeadQueueArgs = {
  user_id: Scalars['String']['input'];
};


export type MutationRegisterArgs = {
  email: Scalars['String']['input'];
  first_name?: InputMaybe<Scalars['String']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  organization_id: Scalars['String']['input'];
  password: Scalars['String']['input'];
  role?: InputMaybe<Role>;
};


export type MutationReleaseLeadArgs = {
  lead_id?: InputMaybe<Scalars['String']['input']>;
  lead_intent_id?: InputMaybe<Scalars['String']['input']>;
  reason?: InputMaybe<Scalars['String']['input']>;
  scheduled_event_ack?: Scalars['Boolean']['input'];
};


export type MutationReloadSequenceCountArgs = {
  sequence_id: Scalars['String']['input'];
};


export type MutationRemoveCallFromFolderArgs = {
  conference_id: Scalars['String']['input'];
  folder_id: Scalars['String']['input'];
};


export type MutationRemoveCallResultArgs = {
  lead_activity_id: Scalars['String']['input'];
};


export type MutationRemoveFolderArgs = {
  folder_id: Scalars['String']['input'];
};


export type MutationRemoveFromCustomQueueArgs = {
  lead_id: Scalars['String']['input'];
};


export type MutationRemoveIntegrationErrorLogArgs = {
  error_id: Scalars['String']['input'];
};


export type MutationRemoveIntegrationMappingArgs = {
  mapping_id: Scalars['String']['input'];
};


export type MutationRemoveJoinMeItemArgs = {
  joinME_id: Scalars['String']['input'];
};


export type MutationRemoveJoinMeMeetingArgs = {
  scheduled_item_id: Scalars['String']['input'];
};


export type MutationRemoveRoutingRuleArgs = {
  rule_id: Scalars['String']['input'];
};


export type MutationRemoveScheduledItemArgs = {
  id: Scalars['String']['input'];
};


export type MutationRemoveSystemFieldArgs = {
  label: Scalars['String']['input'];
  systemFieldType: SystemFieldType;
};


export type MutationRemoveUserFromFolderArgs = {
  folder_id: Scalars['String']['input'];
  user_id: Scalars['String']['input'];
};


export type MutationReprovisionLocalNumbersArgs = {
  call_forwarding: Scalars['Boolean']['input'];
  organization_id: Scalars['String']['input'];
};


export type MutationRepushEventArgs = {
  error_log_id: Scalars['String']['input'];
  integration_type?: InputMaybe<Integration_Type>;
};


export type MutationResetLeadPhaseArgs = {
  lead_id: Scalars['String']['input'];
};


export type MutationResetPasswordArgs = {
  email: Scalars['String']['input'];
};


export type MutationResolveDuplicateLeadsArgs = {
  lead_import_id: Scalars['String']['input'];
};


export type MutationResolveWithoutFixingDuplicatesArgs = {
  upload_id: Scalars['String']['input'];
};


export type MutationRespondToConciergeArgs = {
  called: Scalars['Boolean']['input'];
  callMeNowId: Scalars['String']['input'];
};


export type MutationRunCheckReleasedNumbersAlgoArgs = {
  organization_ids: Array<Scalars['String']['input']>;
};


export type MutationRunLeadAssignmentAlgoOrgArgs = {
  organization_id?: InputMaybe<Scalars['String']['input']>;
};


export type MutationRunLeadAssignmentAlgoOrgUserBackfillArgs = {
  organization_id?: InputMaybe<Scalars['String']['input']>;
};


export type MutationSalesForceLeadInboundArgs = {
  lowerbound_date?: InputMaybe<Scalars['DateTime']['input']>;
  organization_id?: InputMaybe<Scalars['String']['input']>;
  upperbound_date?: InputMaybe<Scalars['DateTime']['input']>;
};


export type MutationSaveCallReportEventSelectedColumnsArgs = {
  selected_columns: Array<Scalars['String']['input']>;
};


export type MutationSaveCallReportPipelineSelectedColumnsArgs = {
  selected_columns: Array<Scalars['String']['input']>;
};


export type MutationSaveCallReportSelectedColumnsArgs = {
  selected_columns: Array<Scalars['String']['input']>;
};


export type MutationSaveRepSelectedColumnsArgs = {
  selected_columns: Array<Scalars['String']['input']>;
};


export type MutationSeedLaIconsArgs = {
  lead_id: Scalars['String']['input'];
};


export type MutationSendEmailToLeadArgs = {
  email?: InputMaybe<Scalars['String']['input']>;
  email_thread_id?: InputMaybe<Scalars['String']['input']>;
  html?: InputMaybe<Scalars['String']['input']>;
  lead_id: Scalars['String']['input'];
  step_action_id?: InputMaybe<Scalars['String']['input']>;
  subject?: InputMaybe<Scalars['String']['input']>;
  template_id?: InputMaybe<Scalars['String']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
};


export type MutationSendReportingForAllSalesArgs = {
  email_list: Array<Scalars['String']['input']>;
  organization_id: Scalars['String']['input'];
};


export type MutationSendReportingForDialsBeforeContactArgs = {
  email_list: Array<Scalars['String']['input']>;
  organization_id: Scalars['String']['input'];
};


export type MutationSendReviewCallReportToRepArgs = {
  conference_id: Scalars['String']['input'];
  rep_ids?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type MutationSendSmsArgs = {
  lead_id: Scalars['String']['input'];
  phone_number: Scalars['String']['input'];
  step_action_id?: InputMaybe<Scalars['String']['input']>;
  template_id?: InputMaybe<Scalars['String']['input']>;
  text: Scalars['String']['input'];
};


export type MutationSetHubSpotSyncSettingsArgs = {
  hubspot_inbound_activity_sync?: InputMaybe<Scalars['Boolean']['input']>;
  hubspot_inbound_creation_sync?: InputMaybe<Scalars['Boolean']['input']>;
  hubspot_inbound_update_sync?: InputMaybe<Scalars['Boolean']['input']>;
  hubspot_outbound_activity_sync?: InputMaybe<Scalars['Boolean']['input']>;
  hubspot_outbound_creation_sync?: InputMaybe<Scalars['Boolean']['input']>;
  hubspot_outbound_update_sync?: InputMaybe<Scalars['Boolean']['input']>;
  hubspot_sale_create_outbound?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationSetInboundConciergeWidgetSettingsArgs = {
  brand_name?: InputMaybe<Scalars['String']['input']>;
  button_color?: InputMaybe<Scalars['String']['input']>;
  button_text_color?: InputMaybe<Scalars['String']['input']>;
  custom_existing_lead_response_time?: InputMaybe<Scalars['Boolean']['input']>;
  existing_lead_response_time?: InputMaybe<Scalars['Int']['input']>;
  font?: InputMaybe<Scalars['String']['input']>;
  header_background_color?: InputMaybe<Scalars['String']['input']>;
  header_text_color?: InputMaybe<Scalars['String']['input']>;
  overflow_reps_on_existing_lead?: InputMaybe<Scalars['Boolean']['input']>;
  representative_response_time?: InputMaybe<Scalars['Int']['input']>;
  scheduleItemType?: InputMaybe<ScheduleItemType>;
  session_token?: InputMaybe<Scalars['String']['input']>;
  show_leads_custom_time_options?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationSetLeadRoutingEmailListArgs = {
  lead_routing_email_list: Array<Scalars['String']['input']>;
};


export type MutationSetLeadRoutingEmailToggleArgs = {
  send_lead_routing_email: Scalars['Boolean']['input'];
};


export type MutationSkipLeadArgs = {
  lead_intent_id: Scalars['String']['input'];
  reason?: InputMaybe<Scalars['String']['input']>;
};


export type MutationStartJoinMeMeetingArgs = {
  scheduled_item_id: Scalars['String']['input'];
};


export type MutationStartSaleArgs = {
  contract_duration?: InputMaybe<Scalars['String']['input']>;
  lead_id: Scalars['String']['input'];
  person_spoke_to: Callresult;
  products: Array<ProductInputType>;
};


export type MutationStatAggregationBackfillArgs = {
  lowerbound_date?: InputMaybe<Scalars['DateTime']['input']>;
  organization_id: Scalars['String']['input'];
  upperbound_date?: InputMaybe<Scalars['DateTime']['input']>;
};


export type MutationStatAggregationBackfillPipelineArgs = {
  organization_id: Scalars['String']['input'];
};


export type MutationStatAggregationOrgArgs = {
  min_date: Scalars['String']['input'];
  organization_id: Scalars['String']['input'];
};


export type MutationStatAggregationUserArgs = {
  email: Scalars['String']['input'];
};


export type MutationSwapPrimaryNumberOnLeadArgs = {
  contact_id: Scalars['String']['input'];
};


export type MutationSyncAllMappingFieldSelectOptionArgs = {
  integration_mapping_id: Scalars['String']['input'];
};


export type MutationSyncHubSpotCustomFieldsArgs = {
  leads?: InputMaybe<Array<InputMaybe<Scalars['Json']['input']>>>;
  use_static?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationSyncHubSpotLeadsArgs = {
  organization_id: Scalars['String']['input'];
};


export type MutationSyncMappingFieldSelectOptionArgs = {
  integration_mapping_id: Scalars['String']['input'];
  select_option_value: Scalars['String']['input'];
};


export type MutationTeamIdLaBackfillArgs = {
  lowerbound_date?: InputMaybe<Scalars['DateTime']['input']>;
  organization_id: Scalars['String']['input'];
  upperbound_date?: InputMaybe<Scalars['DateTime']['input']>;
};


export type MutationTwilioInfoCreateArgs = {
  create_all?: InputMaybe<Scalars['Boolean']['input']>;
  twilio_info?: InputMaybe<TwilioInfoCreateInput>;
};


export type MutationUnassignLeadsForRepArgs = {
  ignore_self_source?: InputMaybe<Scalars['Boolean']['input']>;
  industries?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  lead_count?: InputMaybe<Scalars['Int']['input']>;
  lead_sources?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  rep_email: Scalars['String']['input'];
};


export type MutationUnassignLeadsInOrgArgs = {
  org_id: Scalars['String']['input'];
};


export type MutationUnassignSkippedLeadsArgs = {
  email: Scalars['String']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
};


export type MutationUnassignUserToTeamArgs = {
  user_id: Scalars['String']['input'];
};


export type MutationUnfavoriteLeadArgs = {
  lead_id: Scalars['String']['input'];
};


export type MutationUpdateActiveTwilioRecordingArgs = {
  action: TwilioRecordingAction;
};


export type MutationUpdateBestMetricInRangeArgs = {
  end_date_key: Scalars['String']['input'];
  organization_id: Scalars['String']['input'];
  record_type: Best_Metric_Record_Type;
  start_date_key: Scalars['String']['input'];
};


export type MutationUpdateCallNotesArgs = {
  call_notes: Scalars['String']['input'];
  lead_id?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateDashboardColumnOptionArgs = {
  column_options: ColumnOptionArgument;
  saved_view_id: Scalars['String']['input'];
};


export type MutationUpdateDashboardSavedViewArgs = {
  data: DashBoardSavedViewArgument;
  name: Scalars['String']['input'];
  saved_view_id: Scalars['String']['input'];
};


export type MutationUpdateDefaultAudioArgs = {
  default_audio_input?: InputMaybe<Scalars['String']['input']>;
  default_audio_output?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateDefaultColumnOptionArgs = {
  column_options: ColumnOptionArgument;
};


export type MutationUpdateDispositionsArgs = {
  org_id?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateDispositionsOrgArgs = {
  org_id: Scalars['String']['input'];
};


export type MutationUpdateEmailSubscriptionStatusArgs = {
  email_sub_status: OptInStatus;
  lead_id: Scalars['String']['input'];
};


export type MutationUpdateFolderNameArgs = {
  folder_id: Scalars['String']['input'];
  name: Scalars['String']['input'];
};


export type MutationUpdateFolderPermissionArgs = {
  folder_id: Scalars['String']['input'];
  user_id_list: Array<Scalars['String']['input']>;
};


export type MutationUpdateHubSpotSyncRuleArgs = {
  sync_rule_input: OrgHubSpotSyncInput;
};


export type MutationUpdateLeadArgs = {
  address?: InputMaybe<Scalars['String']['input']>;
  address_2?: InputMaybe<Scalars['String']['input']>;
  business_name?: InputMaybe<Scalars['String']['input']>;
  channel?: InputMaybe<Channel>;
  city?: InputMaybe<Scalars['String']['input']>;
  content?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  country_code?: InputMaybe<Scalars['String']['input']>;
  current_close_date?: InputMaybe<Scalars['DateTime']['input']>;
  custom_fields?: InputMaybe<Array<InputMaybe<CustomFieldInput>>>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  industry?: InputMaybe<Scalars['String']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  lead_source?: InputMaybe<Scalars['String']['input']>;
  mrr?: InputMaybe<Scalars['Float']['input']>;
  primary_email?: InputMaybe<Scalars['String']['input']>;
  primary_phone_number?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  sub_industry?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateLeadCallHoldStatusArgs = {
  hold: Scalars['Boolean']['input'];
  lead_id: Scalars['String']['input'];
};


export type MutationUpdateLeadConferenceNumberArgs = {
  country?: InputMaybe<Scalars['String']['input']>;
  lead_id: Scalars['String']['input'];
  phone_number: Scalars['String']['input'];
};


export type MutationUpdateNoEventDateArgs = {
  data: OrgNoEventDateInput;
  id: Scalars['String']['input'];
};


export type MutationUpdateOneAutomatedCampaignArgs = {
  automated_campaign_id: Scalars['String']['input'];
  body?: InputMaybe<Scalars['String']['input']>;
  event?: InputMaybe<Scalars['String']['input']>;
  increment?: InputMaybe<Scalars['Int']['input']>;
  increment_multiplier?: InputMaybe<Scalars['Int']['input']>;
  method_email?: InputMaybe<Scalars['Boolean']['input']>;
  method_sms?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  subject?: InputMaybe<Scalars['String']['input']>;
  when?: InputMaybe<When>;
};


export type MutationUpdateOneBestMetricRecordArgs = {
  data: BestMetricRecordUpdateInput;
  where: BestMetricRecordWhereUniqueInput;
};


export type MutationUpdateOneCustomFieldArgs = {
  allow_reps_to_edit?: InputMaybe<Scalars['Boolean']['input']>;
  custom_field_id: Scalars['String']['input'];
  key?: InputMaybe<Scalars['String']['input']>;
  options?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  type?: InputMaybe<CustomFieldType>;
  visible?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationUpdateOneCustomObjectArgs = {
  custom_object_id: Scalars['String']['input'];
  name: Scalars['String']['input'];
};


export type MutationUpdateOneCustomObjectFieldArgs = {
  custom_object_field_id: Scalars['String']['input'];
  data: CustomObjectDataInput;
};


export type MutationUpdateOneLeadArgs = {
  business_name?: InputMaybe<Scalars['String']['input']>;
  channel?: InputMaybe<Channel>;
  city?: InputMaybe<Scalars['String']['input']>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  industry?: InputMaybe<Scalars['String']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  lead_source?: InputMaybe<Scalars['String']['input']>;
  newIncoming?: InputMaybe<Scalars['Boolean']['input']>;
  primary_email?: InputMaybe<Scalars['String']['input']>;
  primary_phone_number?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  sub_industry?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateOneNoteItemArgs = {
  end?: InputMaybe<Scalars['Int']['input']>;
  note_item_id: Scalars['String']['input'];
  start?: InputMaybe<Scalars['Int']['input']>;
  text: Scalars['String']['input'];
};


export type MutationUpdateOneNotificationArgs = {
  notification_id: Scalars['String']['input'];
  read: Scalars['Boolean']['input'];
};


export type MutationUpdateOneObjectFkMappingArgs = {
  custom_object_fk_id: Scalars['String']['input'];
  data: CustomFieldFkDataInput;
};


export type MutationUpdateOneOrganizationArgs = {
  data: OrganizationUpdateInput;
  where: OrganizationWhereUniqueInput;
};


export type MutationUpdateOneProductArgs = {
  data: ProductUpdateInput;
  where: ProductWhereUniqueInput;
};


export type MutationUpdateOneSmsItemArgs = {
  read: Scalars['Boolean']['input'];
  read_at: Scalars['String']['input'];
  sms_item_id: Scalars['String']['input'];
};


export type MutationUpdateOneUserArgs = {
  data: UserUpdateInput;
  where: UserWhereUniqueInput;
};


export type MutationUpdateOrganizationRestRuleArgs = {
  long_rest_interval: Scalars['Int']['input'];
  release_rest_interval?: InputMaybe<Scalars['Int']['input']>;
  short_rest_interval: Scalars['Int']['input'];
  skipped_CC_rest_interval?: InputMaybe<Scalars['Int']['input']>;
};


export type MutationUpdateOrganizationSettingsArgs = {
  auto_call?: InputMaybe<Scalars['Boolean']['input']>;
  auto_call_interval?: InputMaybe<Scalars['Int']['input']>;
  claim_rule?: InputMaybe<Scalars['Int']['input']>;
  dial_rule?: InputMaybe<Scalars['Int']['input']>;
  hold_goal?: InputMaybe<Scalars['Float']['input']>;
  lead_release_same_rep_delay_rule?: InputMaybe<Scalars['Int']['input']>;
  long_rest_interval?: InputMaybe<Scalars['Int']['input']>;
  made_contact_rule?: InputMaybe<Scalars['Int']['input']>;
  release_rest_interval?: InputMaybe<Scalars['Int']['input']>;
  rep_claim_limit?: InputMaybe<Scalars['Int']['input']>;
  self_sourcing_rule?: InputMaybe<Scalars['Int']['input']>;
  set_goal?: InputMaybe<Scalars['Float']['input']>;
  short_rest_interval?: InputMaybe<Scalars['Int']['input']>;
  show_system_view?: InputMaybe<Scalars['Boolean']['input']>;
  win_rep_day_goal?: InputMaybe<Scalars['Float']['input']>;
};


export type MutationUpdateOrgDayToCloseArgs = {
  day_to_close: Scalars['Int']['input'];
};


export type MutationUpdateOrgFutureBookingSettingArgs = {
  data: FutureBookingSettingInput;
};


export type MutationUpdateOrgLiveTransferSettingArgs = {
  data: LiveTransferSettingInput;
};


export type MutationUpdateOrgMrrArgs = {
  mrr: Scalars['Int']['input'];
  mrr_label: Scalars['String']['input'];
  mrr_required_on_sale: Scalars['Boolean']['input'];
};


export type MutationUpdateOrgPaymentSettingsArgs = {
  input: OrganizationPaymentSettingsInput;
};


export type MutationUpdateOrgTimeslotAvailabilitiesArgs = {
  data: Array<OrgAvailabilityUpdateInput>;
};


export type MutationUpdateOrgTimeslotAvailabilityArgs = {
  data: OrgTimeslotAvailabilityInput;
  id: Scalars['String']['input'];
};


export type MutationUpdatePrioritiesArgs = {
  updateSequencesPriorityInput: UpdateSequencesPriorityInput;
};


export type MutationUpdateRepNoteViewArgs = {
  reps_can_view_all_notes: Scalars['Boolean']['input'];
};


export type MutationUpdateRepTabArgs = {
  tab_id: Scalars['String']['input'];
};


export type MutationUpdateRevenuePlanArgs = {
  preferred_goal_setting?: InputMaybe<Goalsetting>;
  revenue_plan_items?: InputMaybe<Array<InputMaybe<RevenueItemInput>>>;
  use_revenue_planning?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationUpdateRevenuePlanningSettingArgs = {
  use_revenue_planning: Scalars['Boolean']['input'];
};


export type MutationUpdateRoObjectActiveStatusArgs = {
  active: Scalars['Boolean']['input'];
  id: Scalars['String']['input'];
};


export type MutationUpdateRoutingRuleApplyToInboundConciergeArgs = {
  applyToInboundConcierge?: InputMaybe<Scalars['Boolean']['input']>;
  applyToInboundConciergeCallMeNow?: InputMaybe<Scalars['Boolean']['input']>;
  applyToInboundConciergeSchedule?: InputMaybe<Scalars['Boolean']['input']>;
  rule_id: Scalars['String']['input'];
};


export type MutationUpdateRoutingRuleStatusArgs = {
  active: Scalars['Boolean']['input'];
  rule_id: Scalars['String']['input'];
};


export type MutationUpdateRuleOrderArgs = {
  rule_ids: Array<Scalars['String']['input']>;
};


export type MutationUpdateSalesForceDomainArgs = {
  salesforce_domain: Scalars['String']['input'];
};


export type MutationUpdateSequenceDialerNotificationsArgs = {
  allow_remove_step_action: Scalars['Boolean']['input'];
  allow_snooze_step_action: Scalars['Boolean']['input'];
};


export type MutationUpdateSeveralStatAggregationArgs = {
  lead_activity_ids: Array<Scalars['String']['input']>;
};


export type MutationUpdateSfdcSyncRuleArgs = {
  sync_rule_input: OrgSalesforceSyncInput;
};


export type MutationUpdateSingleLeadStatAggregateArgs = {
  lead_id?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateSingleStatAggregationArgs = {
  lead_activity_id: Scalars['String']['input'];
};


export type MutationUpdateSiteArgs = {
  site_id: Scalars['String']['input'];
  site_name: Scalars['String']['input'];
};


export type MutationUpdateSmsDialogArgs = {
  sms_opt_in_dialog: Scalars['String']['input'];
};


export type MutationUpdateSmsSubscriptionStatusArgs = {
  lead_id: Scalars['String']['input'];
  sms_sub_status: OptInStatus;
};


export type MutationUpdateSubIndustryListArgs = {
  label: Scalars['String']['input'];
  sub_industries: Array<Scalars['String']['input']>;
};


export type MutationUpdateSyncArgs = {
  timekit_type: Timekittype;
};


export type MutationUpdateTeamArgs = {
  site_id?: InputMaybe<Scalars['String']['input']>;
  team_id: Scalars['String']['input'];
  team_name?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateTemplateActiveStatusArgs = {
  active: Scalars['Boolean']['input'];
  template_id: Scalars['String']['input'];
};


export type MutationUpdateTranferRuleStatusArgs = {
  active: Scalars['Boolean']['input'];
  rule_id: Scalars['String']['input'];
};


export type MutationUpdateUserArgs = {
  user_id: Scalars['String']['input'];
  user_input: AddUserInput;
};


export type MutationUpdateUserDashboardV2AccessArgs = {
  dashboard_2_access: Scalars['Boolean']['input'];
};


export type MutationUpdateUserProfileImageArgs = {
  profile_image: Scalars['String']['input'];
};


export type MutationUpdateUserSettingsArgs = {
  alternate_email: Scalars['String']['input'];
  email: Scalars['String']['input'];
  phone_number: Scalars['String']['input'];
};


export type MutationUpdateUserStatusArgs = {
  do_not_update_if_on_call?: InputMaybe<Scalars['Boolean']['input']>;
  status: Status;
};


export type MutationUpdateUserTransferTypeArgs = {
  automatic: Array<Scalars['String']['input']>;
  manual: Array<Scalars['String']['input']>;
};


export type MutationUpdateUserWhiteListArgs = {
  id: Scalars['String']['input'];
  industry_filter: Scalars['Boolean']['input'];
  industry_white_list_array: Array<Scalars['String']['input']>;
  lead_source_filter: Scalars['Boolean']['input'];
  lead_source_white_list_array: Array<Scalars['String']['input']>;
  sub_industry_white_list_array: Array<Scalars['String']['input']>;
};


export type MutationUploadImageArgs = {
  file: Scalars['Upload']['input'];
};


export type MutationUpsertCustomObjectRowArgs = {
  custom_object_id: Scalars['String']['input'];
  data?: InputMaybe<Scalars['Json']['input']>;
  lead_id: Scalars['String']['input'];
  row_id?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpsertLeadCustomObjectRowAssociationArgs = {
  data: LeadCustomObjectRowUpsertInput;
  id?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpsertOneBestMetricRecordArgs = {
  create: BestMetricRecordCreateInput;
  update: BestMetricRecordUpdateInput;
  where: BestMetricRecordWhereUniqueInput;
};


export type MutationUpsertRoObjectArgs = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  external_object: Scalars['String']['input'];
  integration: Integration_Type;
  integration_fk_field: Scalars['String']['input'];
  lead_fk_field: Scalars['String']['input'];
  selected_fields: Array<RoFieldDetailInput>;
};


export type MutationValidatePhoneNumberArgs = {
  lead_id: Scalars['String']['input'];
  phone_number: Scalars['String']['input'];
};

/** Data for rendering the My Schedule component at dialer screen */
export type MySchedule = {
  __typename?: 'MySchedule';
  events: Array<EventObject>;
  general_call_count: Scalars['Int']['output'];
};

export type NestedBoolFilter = {
  equals?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<NestedBoolFilter>;
};

export type NestedBoolNullableFilter = {
  equals?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<NestedBoolNullableFilter>;
};

export type NestedDateTimeFilter = {
  equals?: InputMaybe<Scalars['DateTime']['input']>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  not?: InputMaybe<NestedDateTimeFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']['input']>>;
};

export type NestedDateTimeNullableFilter = {
  equals?: InputMaybe<Scalars['DateTime']['input']>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  not?: InputMaybe<NestedDateTimeNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']['input']>>;
};

export type NestedEnumAm_TypeFilter = {
  equals?: InputMaybe<Am_Type>;
  in?: InputMaybe<Array<Am_Type>>;
  not?: InputMaybe<NestedEnumAm_TypeFilter>;
  notIn?: InputMaybe<Array<Am_Type>>;
};

export type NestedEnumAssignmentOriginFilter = {
  equals?: InputMaybe<AssignmentOrigin>;
  in?: InputMaybe<Array<AssignmentOrigin>>;
  not?: InputMaybe<NestedEnumAssignmentOriginFilter>;
  notIn?: InputMaybe<Array<AssignmentOrigin>>;
};

export type NestedEnumAssignTypeFilter = {
  equals?: InputMaybe<AssignType>;
  in?: InputMaybe<Array<AssignType>>;
  not?: InputMaybe<NestedEnumAssignTypeFilter>;
  notIn?: InputMaybe<Array<AssignType>>;
};

export type NestedEnumAssociationHistoryActionFilter = {
  equals?: InputMaybe<AssociationHistoryAction>;
  in?: InputMaybe<Array<AssociationHistoryAction>>;
  not?: InputMaybe<NestedEnumAssociationHistoryActionFilter>;
  notIn?: InputMaybe<Array<AssociationHistoryAction>>;
};

export type NestedEnumAutoSelectionTypeFilter = {
  equals?: InputMaybe<AutoSelectionType>;
  in?: InputMaybe<Array<AutoSelectionType>>;
  not?: InputMaybe<NestedEnumAutoSelectionTypeFilter>;
  notIn?: InputMaybe<Array<AutoSelectionType>>;
};

export type NestedEnumBackUpRuleFilter = {
  equals?: InputMaybe<BackUpRule>;
  in?: InputMaybe<Array<BackUpRule>>;
  not?: InputMaybe<NestedEnumBackUpRuleFilter>;
  notIn?: InputMaybe<Array<BackUpRule>>;
};

export type NestedEnumBest_Metric_Record_TypeFilter = {
  equals?: InputMaybe<Best_Metric_Record_Type>;
  in?: InputMaybe<Array<Best_Metric_Record_Type>>;
  not?: InputMaybe<NestedEnumBest_Metric_Record_TypeFilter>;
  notIn?: InputMaybe<Array<Best_Metric_Record_Type>>;
};

export type NestedEnumBgMusicOptionFilter = {
  equals?: InputMaybe<BgMusicOption>;
  in?: InputMaybe<Array<BgMusicOption>>;
  not?: InputMaybe<NestedEnumBgMusicOptionFilter>;
  notIn?: InputMaybe<Array<BgMusicOption>>;
};

export type NestedEnumCadenceFilter = {
  equals?: InputMaybe<Cadence>;
  in?: InputMaybe<Array<Cadence>>;
  not?: InputMaybe<NestedEnumCadenceFilter>;
  notIn?: InputMaybe<Array<Cadence>>;
};

export type NestedEnumCallbackTypeFilter = {
  equals?: InputMaybe<CallbackType>;
  in?: InputMaybe<Array<CallbackType>>;
  not?: InputMaybe<NestedEnumCallbackTypeFilter>;
  notIn?: InputMaybe<Array<CallbackType>>;
};

export type NestedEnumCallbackTypeNullableFilter = {
  equals?: InputMaybe<CallbackType>;
  in?: InputMaybe<Array<CallbackType>>;
  not?: InputMaybe<NestedEnumCallbackTypeNullableFilter>;
  notIn?: InputMaybe<Array<CallbackType>>;
};

export type NestedEnumCallMeNowStatusFilter = {
  equals?: InputMaybe<CallMeNowStatus>;
  in?: InputMaybe<Array<CallMeNowStatus>>;
  not?: InputMaybe<NestedEnumCallMeNowStatusFilter>;
  notIn?: InputMaybe<Array<CallMeNowStatus>>;
};

export type NestedEnumCallresultNullableFilter = {
  equals?: InputMaybe<Callresult>;
  in?: InputMaybe<Array<Callresult>>;
  not?: InputMaybe<NestedEnumCallresultNullableFilter>;
  notIn?: InputMaybe<Array<Callresult>>;
};

export type NestedEnumChannelfilterNullableFilter = {
  equals?: InputMaybe<Channelfilter>;
  in?: InputMaybe<Array<Channelfilter>>;
  not?: InputMaybe<NestedEnumChannelfilterNullableFilter>;
  notIn?: InputMaybe<Array<Channelfilter>>;
};

export type NestedEnumChannelNullableFilter = {
  equals?: InputMaybe<Channel>;
  in?: InputMaybe<Array<Channel>>;
  not?: InputMaybe<NestedEnumChannelNullableFilter>;
  notIn?: InputMaybe<Array<Channel>>;
};

export type NestedEnumCommunication_TypeFilter = {
  equals?: InputMaybe<Communication_Type>;
  in?: InputMaybe<Array<Communication_Type>>;
  not?: InputMaybe<NestedEnumCommunication_TypeFilter>;
  notIn?: InputMaybe<Array<Communication_Type>>;
};

export type NestedEnumCommunication_TypeNullableFilter = {
  equals?: InputMaybe<Communication_Type>;
  in?: InputMaybe<Array<Communication_Type>>;
  not?: InputMaybe<NestedEnumCommunication_TypeNullableFilter>;
  notIn?: InputMaybe<Array<Communication_Type>>;
};

export type NestedEnumConcessiontypeFilter = {
  equals?: InputMaybe<Concessiontype>;
  in?: InputMaybe<Array<Concessiontype>>;
  not?: InputMaybe<NestedEnumConcessiontypeFilter>;
  notIn?: InputMaybe<Array<Concessiontype>>;
};

export type NestedEnumconditionTypeFilter = {
  equals?: InputMaybe<ConditionType>;
  in?: InputMaybe<Array<ConditionType>>;
  not?: InputMaybe<NestedEnumconditionTypeFilter>;
  notIn?: InputMaybe<Array<ConditionType>>;
};

export type NestedEnumConferenceRegionFilter = {
  equals?: InputMaybe<ConferenceRegion>;
  in?: InputMaybe<Array<ConferenceRegion>>;
  not?: InputMaybe<NestedEnumConferenceRegionFilter>;
  notIn?: InputMaybe<Array<ConferenceRegion>>;
};

export type NestedEnumContactTypeNullableFilter = {
  equals?: InputMaybe<ContactType>;
  in?: InputMaybe<Array<ContactType>>;
  not?: InputMaybe<NestedEnumContactTypeNullableFilter>;
  notIn?: InputMaybe<Array<ContactType>>;
};

export type NestedEnumCustomFieldTypeFilter = {
  equals?: InputMaybe<CustomFieldType>;
  in?: InputMaybe<Array<CustomFieldType>>;
  not?: InputMaybe<NestedEnumCustomFieldTypeFilter>;
  notIn?: InputMaybe<Array<CustomFieldType>>;
};

export type NestedEnumCustomFieldTypeNullableFilter = {
  equals?: InputMaybe<CustomFieldType>;
  in?: InputMaybe<Array<CustomFieldType>>;
  not?: InputMaybe<NestedEnumCustomFieldTypeNullableFilter>;
  notIn?: InputMaybe<Array<CustomFieldType>>;
};

export type NestedEnumDashboard_Group_OptionFilter = {
  equals?: InputMaybe<Dashboard_Group_Option>;
  in?: InputMaybe<Array<Dashboard_Group_Option>>;
  not?: InputMaybe<NestedEnumDashboard_Group_OptionFilter>;
  notIn?: InputMaybe<Array<Dashboard_Group_Option>>;
};

export type NestedEnumDateOptionFilter = {
  equals?: InputMaybe<DateOption>;
  in?: InputMaybe<Array<DateOption>>;
  not?: InputMaybe<NestedEnumDateOptionFilter>;
  notIn?: InputMaybe<Array<DateOption>>;
};

export type NestedEnumDateOptionNullableFilter = {
  equals?: InputMaybe<DateOption>;
  in?: InputMaybe<Array<DateOption>>;
  not?: InputMaybe<NestedEnumDateOptionNullableFilter>;
  notIn?: InputMaybe<Array<DateOption>>;
};

export type NestedEnumDispositiontypeFilter = {
  equals?: InputMaybe<Dispositiontype>;
  in?: InputMaybe<Array<Dispositiontype>>;
  not?: InputMaybe<NestedEnumDispositiontypeFilter>;
  notIn?: InputMaybe<Array<Dispositiontype>>;
};

export type NestedEnumDispositiontypeNullableFilter = {
  equals?: InputMaybe<Dispositiontype>;
  in?: InputMaybe<Array<Dispositiontype>>;
  not?: InputMaybe<NestedEnumDispositiontypeNullableFilter>;
  notIn?: InputMaybe<Array<Dispositiontype>>;
};

export type NestedEnumDistribution_MethodFilter = {
  equals?: InputMaybe<Distribution_Method>;
  in?: InputMaybe<Array<Distribution_Method>>;
  not?: InputMaybe<NestedEnumDistribution_MethodFilter>;
  notIn?: InputMaybe<Array<Distribution_Method>>;
};

export type NestedEnumEmailthreadoriginFilter = {
  equals?: InputMaybe<Emailthreadorigin>;
  in?: InputMaybe<Array<Emailthreadorigin>>;
  not?: InputMaybe<NestedEnumEmailthreadoriginFilter>;
  notIn?: InputMaybe<Array<Emailthreadorigin>>;
};

export type NestedEnumEntityTypeFilter = {
  equals?: InputMaybe<EntityType>;
  in?: InputMaybe<Array<EntityType>>;
  not?: InputMaybe<NestedEnumEntityTypeFilter>;
  notIn?: InputMaybe<Array<EntityType>>;
};

export type NestedEnumFee_TypeFilter = {
  equals?: InputMaybe<Fee_Type>;
  in?: InputMaybe<Array<Fee_Type>>;
  not?: InputMaybe<NestedEnumFee_TypeFilter>;
  notIn?: InputMaybe<Array<Fee_Type>>;
};

export type NestedEnumGoalsettingNullableFilter = {
  equals?: InputMaybe<Goalsetting>;
  in?: InputMaybe<Array<Goalsetting>>;
  not?: InputMaybe<NestedEnumGoalsettingNullableFilter>;
  notIn?: InputMaybe<Array<Goalsetting>>;
};

export type NestedEnumHingepointFilter = {
  equals?: InputMaybe<Hingepoint>;
  in?: InputMaybe<Array<Hingepoint>>;
  not?: InputMaybe<NestedEnumHingepointFilter>;
  notIn?: InputMaybe<Array<Hingepoint>>;
};

export type NestedEnumHingepointNullableFilter = {
  equals?: InputMaybe<Hingepoint>;
  in?: InputMaybe<Array<Hingepoint>>;
  not?: InputMaybe<NestedEnumHingepointNullableFilter>;
  notIn?: InputMaybe<Array<Hingepoint>>;
};

export type NestedEnumInboundConciergeEventTypeFilter = {
  equals?: InputMaybe<InboundConciergeEventType>;
  in?: InputMaybe<Array<InboundConciergeEventType>>;
  not?: InputMaybe<NestedEnumInboundConciergeEventTypeFilter>;
  notIn?: InputMaybe<Array<InboundConciergeEventType>>;
};

export type NestedEnumIntegration_TypeFilter = {
  equals?: InputMaybe<Integration_Type>;
  in?: InputMaybe<Array<Integration_Type>>;
  not?: InputMaybe<NestedEnumIntegration_TypeFilter>;
  notIn?: InputMaybe<Array<Integration_Type>>;
};

export type NestedEnumIntegrationErrorEntityTypeFilter = {
  equals?: InputMaybe<IntegrationErrorEntityType>;
  in?: InputMaybe<Array<IntegrationErrorEntityType>>;
  not?: InputMaybe<NestedEnumIntegrationErrorEntityTypeFilter>;
  notIn?: InputMaybe<Array<IntegrationErrorEntityType>>;
};

export type NestedEnumIntegrationOperationTypeFilter = {
  equals?: InputMaybe<IntegrationOperationType>;
  in?: InputMaybe<Array<IntegrationOperationType>>;
  not?: InputMaybe<NestedEnumIntegrationOperationTypeFilter>;
  notIn?: InputMaybe<Array<IntegrationOperationType>>;
};

export type NestedEnumIntentTypeNullableFilter = {
  equals?: InputMaybe<IntentType>;
  in?: InputMaybe<Array<IntentType>>;
  not?: InputMaybe<NestedEnumIntentTypeNullableFilter>;
  notIn?: InputMaybe<Array<IntentType>>;
};

export type NestedEnumInvitationResultFilter = {
  equals?: InputMaybe<InvitationResult>;
  in?: InputMaybe<Array<InvitationResult>>;
  not?: InputMaybe<NestedEnumInvitationResultFilter>;
  notIn?: InputMaybe<Array<InvitationResult>>;
};

export type NestedEnumJitterBufferSizeFilter = {
  equals?: InputMaybe<JitterBufferSize>;
  in?: InputMaybe<Array<JitterBufferSize>>;
  not?: InputMaybe<NestedEnumJitterBufferSizeFilter>;
  notIn?: InputMaybe<Array<JitterBufferSize>>;
};

export type NestedEnumLeadcreationsourceNullableFilter = {
  equals?: InputMaybe<Leadcreationsource>;
  in?: InputMaybe<Array<Leadcreationsource>>;
  not?: InputMaybe<NestedEnumLeadcreationsourceNullableFilter>;
  notIn?: InputMaybe<Array<Leadcreationsource>>;
};

export type NestedEnumLeadCreationStatusFilter = {
  equals?: InputMaybe<LeadCreationStatus>;
  in?: InputMaybe<Array<LeadCreationStatus>>;
  not?: InputMaybe<NestedEnumLeadCreationStatusFilter>;
  notIn?: InputMaybe<Array<LeadCreationStatus>>;
};

export type NestedEnumLeadCustomObjectAssociationSourceFilter = {
  equals?: InputMaybe<LeadCustomObjectAssociationSource>;
  in?: InputMaybe<Array<LeadCustomObjectAssociationSource>>;
  not?: InputMaybe<NestedEnumLeadCustomObjectAssociationSourceFilter>;
  notIn?: InputMaybe<Array<LeadCustomObjectAssociationSource>>;
};

export type NestedEnumLeadImportTypeFilter = {
  equals?: InputMaybe<LeadImportType>;
  in?: InputMaybe<Array<LeadImportType>>;
  not?: InputMaybe<NestedEnumLeadImportTypeFilter>;
  notIn?: InputMaybe<Array<LeadImportType>>;
};

export type NestedEnumLeadUploadStatusFilter = {
  equals?: InputMaybe<LeadUploadStatus>;
  in?: InputMaybe<Array<LeadUploadStatus>>;
  not?: InputMaybe<NestedEnumLeadUploadStatusFilter>;
  notIn?: InputMaybe<Array<LeadUploadStatus>>;
};

export type NestedEnumLfmTypeFilter = {
  equals?: InputMaybe<LfmType>;
  in?: InputMaybe<Array<LfmType>>;
  not?: InputMaybe<NestedEnumLfmTypeFilter>;
  notIn?: InputMaybe<Array<LfmType>>;
};

export type NestedEnumLosFilter = {
  equals?: InputMaybe<Los>;
  in?: InputMaybe<Array<Los>>;
  not?: InputMaybe<NestedEnumLosFilter>;
  notIn?: InputMaybe<Array<Los>>;
};

export type NestedEnumLosNullableFilter = {
  equals?: InputMaybe<Los>;
  in?: InputMaybe<Array<Los>>;
  not?: InputMaybe<NestedEnumLosNullableFilter>;
  notIn?: InputMaybe<Array<Los>>;
};

export type NestedEnumMapping_TypeFilter = {
  equals?: InputMaybe<Mapping_Type>;
  in?: InputMaybe<Array<Mapping_Type>>;
  not?: InputMaybe<NestedEnumMapping_TypeFilter>;
  notIn?: InputMaybe<Array<Mapping_Type>>;
};

export type NestedEnumMeasureByFilterNullableFilter = {
  equals?: InputMaybe<MeasureByFilter>;
  in?: InputMaybe<Array<MeasureByFilter>>;
  not?: InputMaybe<NestedEnumMeasureByFilterNullableFilter>;
  notIn?: InputMaybe<Array<MeasureByFilter>>;
};

export type NestedEnumMetricFilter = {
  equals?: InputMaybe<Metric>;
  in?: InputMaybe<Array<Metric>>;
  not?: InputMaybe<NestedEnumMetricFilter>;
  notIn?: InputMaybe<Array<Metric>>;
};

export type NestedEnumMetricNullableFilter = {
  equals?: InputMaybe<Metric>;
  in?: InputMaybe<Array<Metric>>;
  not?: InputMaybe<NestedEnumMetricNullableFilter>;
  notIn?: InputMaybe<Array<Metric>>;
};

export type NestedEnumMetrictypeNullableFilter = {
  equals?: InputMaybe<Metrictype>;
  in?: InputMaybe<Array<Metrictype>>;
  not?: InputMaybe<NestedEnumMetrictypeNullableFilter>;
  notIn?: InputMaybe<Array<Metrictype>>;
};

export type NestedEnumMonthNullableFilter = {
  equals?: InputMaybe<Month>;
  in?: InputMaybe<Array<Month>>;
  not?: InputMaybe<NestedEnumMonthNullableFilter>;
  notIn?: InputMaybe<Array<Month>>;
};

export type NestedEnumNotificationTypeFilter = {
  equals?: InputMaybe<NotificationType>;
  in?: InputMaybe<Array<NotificationType>>;
  not?: InputMaybe<NestedEnumNotificationTypeFilter>;
  notIn?: InputMaybe<Array<NotificationType>>;
};

export type NestedEnumOperationFilter = {
  equals?: InputMaybe<Operation>;
  in?: InputMaybe<Array<Operation>>;
  not?: InputMaybe<NestedEnumOperationFilter>;
  notIn?: InputMaybe<Array<Operation>>;
};

export type NestedEnumOperatorFilter = {
  equals?: InputMaybe<Operator>;
  in?: InputMaybe<Array<Operator>>;
  not?: InputMaybe<NestedEnumOperatorFilter>;
  notIn?: InputMaybe<Array<Operator>>;
};

export type NestedEnumOptInStatusFilter = {
  equals?: InputMaybe<OptInStatus>;
  in?: InputMaybe<Array<OptInStatus>>;
  not?: InputMaybe<NestedEnumOptInStatusFilter>;
  notIn?: InputMaybe<Array<OptInStatus>>;
};

export type NestedEnumPhaseFilter = {
  equals?: InputMaybe<Phase>;
  in?: InputMaybe<Array<Phase>>;
  not?: InputMaybe<NestedEnumPhaseFilter>;
  notIn?: InputMaybe<Array<Phase>>;
};

export type NestedEnumPhaseNullableFilter = {
  equals?: InputMaybe<Phase>;
  in?: InputMaybe<Array<Phase>>;
  not?: InputMaybe<NestedEnumPhaseNullableFilter>;
  notIn?: InputMaybe<Array<Phase>>;
};

export type NestedEnumPipelineColumnFilter = {
  equals?: InputMaybe<PipelineColumn>;
  in?: InputMaybe<Array<PipelineColumn>>;
  not?: InputMaybe<NestedEnumPipelineColumnFilter>;
  notIn?: InputMaybe<Array<PipelineColumn>>;
};

export type NestedEnumPositionNullableFilter = {
  equals?: InputMaybe<Position>;
  in?: InputMaybe<Array<Position>>;
  not?: InputMaybe<NestedEnumPositionNullableFilter>;
  notIn?: InputMaybe<Array<Position>>;
};

export type NestedEnumpostActionFilter = {
  equals?: InputMaybe<PostAction>;
  in?: InputMaybe<Array<PostAction>>;
  not?: InputMaybe<NestedEnumpostActionFilter>;
  notIn?: InputMaybe<Array<PostAction>>;
};

export type NestedEnumRankSortTypeFilter = {
  equals?: InputMaybe<RankSortType>;
  in?: InputMaybe<Array<RankSortType>>;
  not?: InputMaybe<NestedEnumRankSortTypeFilter>;
  notIn?: InputMaybe<Array<RankSortType>>;
};

export type NestedEnumRecordingStatusFilter = {
  equals?: InputMaybe<RecordingStatus>;
  in?: InputMaybe<Array<RecordingStatus>>;
  not?: InputMaybe<NestedEnumRecordingStatusFilter>;
  notIn?: InputMaybe<Array<RecordingStatus>>;
};

export type NestedEnumRep_Selection_MethodFilter = {
  equals?: InputMaybe<Rep_Selection_Method>;
  in?: InputMaybe<Array<Rep_Selection_Method>>;
  not?: InputMaybe<NestedEnumRep_Selection_MethodFilter>;
  notIn?: InputMaybe<Array<Rep_Selection_Method>>;
};

export type NestedEnumRepPriorityRuleFilter = {
  equals?: InputMaybe<RepPriorityRule>;
  in?: InputMaybe<Array<RepPriorityRule>>;
  not?: InputMaybe<NestedEnumRepPriorityRuleFilter>;
  notIn?: InputMaybe<Array<RepPriorityRule>>;
};

export type NestedEnumRestingtypeNullableFilter = {
  equals?: InputMaybe<Restingtype>;
  in?: InputMaybe<Array<Restingtype>>;
  not?: InputMaybe<NestedEnumRestingtypeNullableFilter>;
  notIn?: InputMaybe<Array<Restingtype>>;
};

export type NestedEnumResultTypeFilter = {
  equals?: InputMaybe<ResultType>;
  in?: InputMaybe<Array<ResultType>>;
  not?: InputMaybe<NestedEnumResultTypeFilter>;
  notIn?: InputMaybe<Array<ResultType>>;
};

export type NestedEnumRoleFilter = {
  equals?: InputMaybe<Role>;
  in?: InputMaybe<Array<Role>>;
  not?: InputMaybe<NestedEnumRoleFilter>;
  notIn?: InputMaybe<Array<Role>>;
};

export type NestedEnumRoleNullableFilter = {
  equals?: InputMaybe<Role>;
  in?: InputMaybe<Array<Role>>;
  not?: InputMaybe<NestedEnumRoleNullableFilter>;
  notIn?: InputMaybe<Array<Role>>;
};

export type NestedEnumRuleTypeFilter = {
  equals?: InputMaybe<RuleType>;
  in?: InputMaybe<Array<RuleType>>;
  not?: InputMaybe<NestedEnumRuleTypeFilter>;
  notIn?: InputMaybe<Array<RuleType>>;
};

export type NestedEnumSaleSectionTypeFilter = {
  equals?: InputMaybe<SaleSectionType>;
  in?: InputMaybe<Array<SaleSectionType>>;
  not?: InputMaybe<NestedEnumSaleSectionTypeFilter>;
  notIn?: InputMaybe<Array<SaleSectionType>>;
};

export type NestedEnumScheduleItemTypeFilter = {
  equals?: InputMaybe<ScheduleItemType>;
  in?: InputMaybe<Array<ScheduleItemType>>;
  not?: InputMaybe<NestedEnumScheduleItemTypeFilter>;
  notIn?: InputMaybe<Array<ScheduleItemType>>;
};

export type NestedEnumScheduleItemTypeNullableFilter = {
  equals?: InputMaybe<ScheduleItemType>;
  in?: InputMaybe<Array<ScheduleItemType>>;
  not?: InputMaybe<NestedEnumScheduleItemTypeNullableFilter>;
  notIn?: InputMaybe<Array<ScheduleItemType>>;
};

export type NestedEnumSequenceEntryExitTypeFilter = {
  equals?: InputMaybe<SequenceEntryExitType>;
  in?: InputMaybe<Array<SequenceEntryExitType>>;
  not?: InputMaybe<NestedEnumSequenceEntryExitTypeFilter>;
  notIn?: InputMaybe<Array<SequenceEntryExitType>>;
};

export type NestedEnumSequenceExitTypeNullableFilter = {
  equals?: InputMaybe<SequenceExitType>;
  in?: InputMaybe<Array<SequenceExitType>>;
  not?: InputMaybe<NestedEnumSequenceExitTypeNullableFilter>;
  notIn?: InputMaybe<Array<SequenceExitType>>;
};

export type NestedEnumSequenceStepScheduledEventBehaviorFilter = {
  equals?: InputMaybe<SequenceStepScheduledEventBehavior>;
  in?: InputMaybe<Array<SequenceStepScheduledEventBehavior>>;
  not?: InputMaybe<NestedEnumSequenceStepScheduledEventBehaviorFilter>;
  notIn?: InputMaybe<Array<SequenceStepScheduledEventBehavior>>;
};

export type NestedEnumSequenceTaskNullableFilter = {
  equals?: InputMaybe<SequenceTask>;
  in?: InputMaybe<Array<SequenceTask>>;
  not?: InputMaybe<NestedEnumSequenceTaskNullableFilter>;
  notIn?: InputMaybe<Array<SequenceTask>>;
};

export type NestedEnumStatItemAggregationTypeFilter = {
  equals?: InputMaybe<StatItemAggregationType>;
  in?: InputMaybe<Array<StatItemAggregationType>>;
  not?: InputMaybe<NestedEnumStatItemAggregationTypeFilter>;
  notIn?: InputMaybe<Array<StatItemAggregationType>>;
};

export type NestedEnumStatusFilter = {
  equals?: InputMaybe<Status>;
  in?: InputMaybe<Array<Status>>;
  not?: InputMaybe<NestedEnumStatusFilter>;
  notIn?: InputMaybe<Array<Status>>;
};

export type NestedEnumSubscriptioneventtypeFilter = {
  equals?: InputMaybe<Subscriptioneventtype>;
  in?: InputMaybe<Array<Subscriptioneventtype>>;
  not?: InputMaybe<NestedEnumSubscriptioneventtypeFilter>;
  notIn?: InputMaybe<Array<Subscriptioneventtype>>;
};

export type NestedEnumSync_RuleFilter = {
  equals?: InputMaybe<Sync_Rule>;
  in?: InputMaybe<Array<Sync_Rule>>;
  not?: InputMaybe<NestedEnumSync_RuleFilter>;
  notIn?: InputMaybe<Array<Sync_Rule>>;
};

export type NestedEnumSystemFieldTypesNullableFilter = {
  equals?: InputMaybe<SystemFieldTypes>;
  in?: InputMaybe<Array<SystemFieldTypes>>;
  not?: InputMaybe<NestedEnumSystemFieldTypesNullableFilter>;
  notIn?: InputMaybe<Array<SystemFieldTypes>>;
};

export type NestedEnumTemplateTypeFilter = {
  equals?: InputMaybe<TemplateType>;
  in?: InputMaybe<Array<TemplateType>>;
  not?: InputMaybe<NestedEnumTemplateTypeFilter>;
  notIn?: InputMaybe<Array<TemplateType>>;
};

export type NestedEnumTimekittypeFilter = {
  equals?: InputMaybe<Timekittype>;
  in?: InputMaybe<Array<Timekittype>>;
  not?: InputMaybe<NestedEnumTimekittypeFilter>;
  notIn?: InputMaybe<Array<Timekittype>>;
};

export type NestedEnumTimeoffrequestresultNullableFilter = {
  equals?: InputMaybe<Timeoffrequestresult>;
  in?: InputMaybe<Array<Timeoffrequestresult>>;
  not?: InputMaybe<NestedEnumTimeoffrequestresultNullableFilter>;
  notIn?: InputMaybe<Array<Timeoffrequestresult>>;
};

export type NestedEnumTransferActionResultFilter = {
  equals?: InputMaybe<TransferActionResult>;
  in?: InputMaybe<Array<TransferActionResult>>;
  not?: InputMaybe<NestedEnumTransferActionResultFilter>;
  notIn?: InputMaybe<Array<TransferActionResult>>;
};

export type NestedEnumTransferAttemptStatusFilter = {
  equals?: InputMaybe<TransferAttemptStatus>;
  in?: InputMaybe<Array<TransferAttemptStatus>>;
  not?: InputMaybe<NestedEnumTransferAttemptStatusFilter>;
  notIn?: InputMaybe<Array<TransferAttemptStatus>>;
};

export type NestedEnumTransferRuleTypeFilter = {
  equals?: InputMaybe<TransferRuleType>;
  in?: InputMaybe<Array<TransferRuleType>>;
  not?: InputMaybe<NestedEnumTransferRuleTypeFilter>;
  notIn?: InputMaybe<Array<TransferRuleType>>;
};

export type NestedEnumTransferTypeFilter = {
  equals?: InputMaybe<TransferType>;
  in?: InputMaybe<Array<TransferType>>;
  not?: InputMaybe<NestedEnumTransferTypeFilter>;
  notIn?: InputMaybe<Array<TransferType>>;
};

export type NestedEnumWeekdayFilter = {
  equals?: InputMaybe<Weekday>;
  in?: InputMaybe<Array<Weekday>>;
  not?: InputMaybe<NestedEnumWeekdayFilter>;
  notIn?: InputMaybe<Array<Weekday>>;
};

export type NestedEnumWhenNullableFilter = {
  equals?: InputMaybe<When>;
  in?: InputMaybe<Array<When>>;
  not?: InputMaybe<NestedEnumWhenNullableFilter>;
  notIn?: InputMaybe<Array<When>>;
};

export type NestedFloatFilter = {
  equals?: InputMaybe<Scalars['Float']['input']>;
  gt?: InputMaybe<Scalars['Float']['input']>;
  gte?: InputMaybe<Scalars['Float']['input']>;
  in?: InputMaybe<Array<Scalars['Float']['input']>>;
  lt?: InputMaybe<Scalars['Float']['input']>;
  lte?: InputMaybe<Scalars['Float']['input']>;
  not?: InputMaybe<NestedFloatFilter>;
  notIn?: InputMaybe<Array<Scalars['Float']['input']>>;
};

export type NestedFloatNullableFilter = {
  equals?: InputMaybe<Scalars['Float']['input']>;
  gt?: InputMaybe<Scalars['Float']['input']>;
  gte?: InputMaybe<Scalars['Float']['input']>;
  in?: InputMaybe<Array<Scalars['Float']['input']>>;
  lt?: InputMaybe<Scalars['Float']['input']>;
  lte?: InputMaybe<Scalars['Float']['input']>;
  not?: InputMaybe<NestedFloatNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['Float']['input']>>;
};

export type NestedIntFilter = {
  equals?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<Scalars['Int']['input']>>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
  not?: InputMaybe<NestedIntFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type NestedIntNullableFilter = {
  equals?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<Scalars['Int']['input']>>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
  not?: InputMaybe<NestedIntNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type NestedStringFilter = {
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  not?: InputMaybe<NestedStringFilter>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type NestedStringNullableFilter = {
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  not?: InputMaybe<NestedStringNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type NoteItem = {
  __typename?: 'NoteItem';
  conference?: Maybe<Conference>;
  conference_id?: Maybe<Scalars['String']['output']>;
  created_at: Scalars['DateTime']['output'];
  end: Scalars['Int']['output'];
  id: Scalars['String']['output'];
  start: Scalars['Int']['output'];
  text: Scalars['String']['output'];
  updated_at: Scalars['DateTime']['output'];
  user?: Maybe<User>;
  user_id?: Maybe<Scalars['String']['output']>;
};

export type NoteItemCreateManyConferenceInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  end: Scalars['Int']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  organization_id?: InputMaybe<Scalars['String']['input']>;
  start: Scalars['Int']['input'];
  text: Scalars['String']['input'];
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
};

export type NoteItemCreateManyConferenceInputEnvelope = {
  data?: InputMaybe<Array<NoteItemCreateManyConferenceInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type NoteItemCreateManyOrganizationInput = {
  conference_id?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  end: Scalars['Int']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  start: Scalars['Int']['input'];
  text: Scalars['String']['input'];
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
};

export type NoteItemCreateManyOrganizationInputEnvelope = {
  data?: InputMaybe<Array<NoteItemCreateManyOrganizationInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type NoteItemCreateManyUserInput = {
  conference_id?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  end: Scalars['Int']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  organization_id?: InputMaybe<Scalars['String']['input']>;
  start: Scalars['Int']['input'];
  text: Scalars['String']['input'];
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type NoteItemCreateManyUserInputEnvelope = {
  data?: InputMaybe<Array<NoteItemCreateManyUserInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type NoteItemCreateNestedManyWithoutConferenceInput = {
  connect?: InputMaybe<Array<NoteItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<NoteItemCreateOrConnectWithoutConferenceInput>>;
  create?: InputMaybe<Array<NoteItemCreateWithoutConferenceInput>>;
  createMany?: InputMaybe<NoteItemCreateManyConferenceInputEnvelope>;
};

export type NoteItemCreateNestedManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<NoteItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<NoteItemCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<NoteItemCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<NoteItemCreateManyOrganizationInputEnvelope>;
};

export type NoteItemCreateNestedManyWithoutUserInput = {
  connect?: InputMaybe<Array<NoteItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<NoteItemCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<NoteItemCreateWithoutUserInput>>;
  createMany?: InputMaybe<NoteItemCreateManyUserInputEnvelope>;
};

export type NoteItemCreateOrConnectWithoutConferenceInput = {
  create: NoteItemCreateWithoutConferenceInput;
  where: NoteItemWhereUniqueInput;
};

export type NoteItemCreateOrConnectWithoutOrganizationInput = {
  create: NoteItemCreateWithoutOrganizationInput;
  where: NoteItemWhereUniqueInput;
};

export type NoteItemCreateOrConnectWithoutUserInput = {
  create: NoteItemCreateWithoutUserInput;
  where: NoteItemWhereUniqueInput;
};

export type NoteItemCreateWithoutConferenceInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  end: Scalars['Int']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  Organization?: InputMaybe<OrganizationCreateNestedOneWithoutNoteItemsInput>;
  start: Scalars['Int']['input'];
  text: Scalars['String']['input'];
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user?: InputMaybe<UserCreateNestedOneWithoutNote_ItemInput>;
};

export type NoteItemCreateWithoutOrganizationInput = {
  conference?: InputMaybe<ConferenceCreateNestedOneWithoutCoaching_NotesInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  end: Scalars['Int']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  start: Scalars['Int']['input'];
  text: Scalars['String']['input'];
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user?: InputMaybe<UserCreateNestedOneWithoutNote_ItemInput>;
};

export type NoteItemCreateWithoutUserInput = {
  conference?: InputMaybe<ConferenceCreateNestedOneWithoutCoaching_NotesInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  end: Scalars['Int']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  Organization?: InputMaybe<OrganizationCreateNestedOneWithoutNoteItemsInput>;
  start: Scalars['Int']['input'];
  text: Scalars['String']['input'];
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type NoteItemListRelationFilter = {
  every?: InputMaybe<NoteItemWhereInput>;
  none?: InputMaybe<NoteItemWhereInput>;
  some?: InputMaybe<NoteItemWhereInput>;
};

export type NoteItemOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
  count?: InputMaybe<SortOrder>;
};

export type NoteItemScalarWhereInput = {
  AND?: InputMaybe<Array<NoteItemScalarWhereInput>>;
  conference_id?: InputMaybe<StringNullableFilter>;
  created_at?: InputMaybe<DateTimeFilter>;
  end?: InputMaybe<IntFilter>;
  id?: InputMaybe<StringFilter>;
  NOT?: InputMaybe<Array<NoteItemScalarWhereInput>>;
  OR?: InputMaybe<Array<NoteItemScalarWhereInput>>;
  organization_id?: InputMaybe<StringNullableFilter>;
  start?: InputMaybe<IntFilter>;
  text?: InputMaybe<StringFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
  user_id?: InputMaybe<StringNullableFilter>;
};

export type NoteItemUpdateManyMutationInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  end?: InputMaybe<IntFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  start?: InputMaybe<IntFieldUpdateOperationsInput>;
  text?: InputMaybe<StringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type NoteItemUpdateManyWithoutConferenceInput = {
  connect?: InputMaybe<Array<NoteItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<NoteItemCreateOrConnectWithoutConferenceInput>>;
  create?: InputMaybe<Array<NoteItemCreateWithoutConferenceInput>>;
  createMany?: InputMaybe<NoteItemCreateManyConferenceInputEnvelope>;
  delete?: InputMaybe<Array<NoteItemWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<NoteItemScalarWhereInput>>;
  disconnect?: InputMaybe<Array<NoteItemWhereUniqueInput>>;
  set?: InputMaybe<Array<NoteItemWhereUniqueInput>>;
  update?: InputMaybe<Array<NoteItemUpdateWithWhereUniqueWithoutConferenceInput>>;
  updateMany?: InputMaybe<Array<NoteItemUpdateManyWithWhereWithoutConferenceInput>>;
  upsert?: InputMaybe<Array<NoteItemUpsertWithWhereUniqueWithoutConferenceInput>>;
};

export type NoteItemUpdateManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<NoteItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<NoteItemCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<NoteItemCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<NoteItemCreateManyOrganizationInputEnvelope>;
  delete?: InputMaybe<Array<NoteItemWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<NoteItemScalarWhereInput>>;
  disconnect?: InputMaybe<Array<NoteItemWhereUniqueInput>>;
  set?: InputMaybe<Array<NoteItemWhereUniqueInput>>;
  update?: InputMaybe<Array<NoteItemUpdateWithWhereUniqueWithoutOrganizationInput>>;
  updateMany?: InputMaybe<Array<NoteItemUpdateManyWithWhereWithoutOrganizationInput>>;
  upsert?: InputMaybe<Array<NoteItemUpsertWithWhereUniqueWithoutOrganizationInput>>;
};

export type NoteItemUpdateManyWithoutUserInput = {
  connect?: InputMaybe<Array<NoteItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<NoteItemCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<NoteItemCreateWithoutUserInput>>;
  createMany?: InputMaybe<NoteItemCreateManyUserInputEnvelope>;
  delete?: InputMaybe<Array<NoteItemWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<NoteItemScalarWhereInput>>;
  disconnect?: InputMaybe<Array<NoteItemWhereUniqueInput>>;
  set?: InputMaybe<Array<NoteItemWhereUniqueInput>>;
  update?: InputMaybe<Array<NoteItemUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: InputMaybe<Array<NoteItemUpdateManyWithWhereWithoutUserInput>>;
  upsert?: InputMaybe<Array<NoteItemUpsertWithWhereUniqueWithoutUserInput>>;
};

export type NoteItemUpdateManyWithWhereWithoutConferenceInput = {
  data: NoteItemUpdateManyMutationInput;
  where: NoteItemScalarWhereInput;
};

export type NoteItemUpdateManyWithWhereWithoutOrganizationInput = {
  data: NoteItemUpdateManyMutationInput;
  where: NoteItemScalarWhereInput;
};

export type NoteItemUpdateManyWithWhereWithoutUserInput = {
  data: NoteItemUpdateManyMutationInput;
  where: NoteItemScalarWhereInput;
};

export type NoteItemUpdateWithoutConferenceInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  end?: InputMaybe<IntFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  Organization?: InputMaybe<OrganizationUpdateOneWithoutNoteItemsInput>;
  start?: InputMaybe<IntFieldUpdateOperationsInput>;
  text?: InputMaybe<StringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneWithoutNote_ItemInput>;
};

export type NoteItemUpdateWithoutOrganizationInput = {
  conference?: InputMaybe<ConferenceUpdateOneWithoutCoaching_NotesInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  end?: InputMaybe<IntFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  start?: InputMaybe<IntFieldUpdateOperationsInput>;
  text?: InputMaybe<StringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneWithoutNote_ItemInput>;
};

export type NoteItemUpdateWithoutUserInput = {
  conference?: InputMaybe<ConferenceUpdateOneWithoutCoaching_NotesInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  end?: InputMaybe<IntFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  Organization?: InputMaybe<OrganizationUpdateOneWithoutNoteItemsInput>;
  start?: InputMaybe<IntFieldUpdateOperationsInput>;
  text?: InputMaybe<StringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type NoteItemUpdateWithWhereUniqueWithoutConferenceInput = {
  data: NoteItemUpdateWithoutConferenceInput;
  where: NoteItemWhereUniqueInput;
};

export type NoteItemUpdateWithWhereUniqueWithoutOrganizationInput = {
  data: NoteItemUpdateWithoutOrganizationInput;
  where: NoteItemWhereUniqueInput;
};

export type NoteItemUpdateWithWhereUniqueWithoutUserInput = {
  data: NoteItemUpdateWithoutUserInput;
  where: NoteItemWhereUniqueInput;
};

export type NoteItemUpsertWithWhereUniqueWithoutConferenceInput = {
  create: NoteItemCreateWithoutConferenceInput;
  update: NoteItemUpdateWithoutConferenceInput;
  where: NoteItemWhereUniqueInput;
};

export type NoteItemUpsertWithWhereUniqueWithoutOrganizationInput = {
  create: NoteItemCreateWithoutOrganizationInput;
  update: NoteItemUpdateWithoutOrganizationInput;
  where: NoteItemWhereUniqueInput;
};

export type NoteItemUpsertWithWhereUniqueWithoutUserInput = {
  create: NoteItemCreateWithoutUserInput;
  update: NoteItemUpdateWithoutUserInput;
  where: NoteItemWhereUniqueInput;
};

export type NoteItemWhereInput = {
  AND?: InputMaybe<Array<NoteItemWhereInput>>;
  conference?: InputMaybe<ConferenceWhereInput>;
  conference_id?: InputMaybe<StringNullableFilter>;
  created_at?: InputMaybe<DateTimeFilter>;
  end?: InputMaybe<IntFilter>;
  id?: InputMaybe<StringFilter>;
  NOT?: InputMaybe<Array<NoteItemWhereInput>>;
  OR?: InputMaybe<Array<NoteItemWhereInput>>;
  Organization?: InputMaybe<OrganizationWhereInput>;
  organization_id?: InputMaybe<StringNullableFilter>;
  start?: InputMaybe<IntFilter>;
  text?: InputMaybe<StringFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserWhereInput>;
  user_id?: InputMaybe<StringNullableFilter>;
};

export type NoteItemWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']['input']>;
};

export type Notification = {
  __typename?: 'Notification';
  affected_user_id?: Maybe<Scalars['String']['output']>;
  call_to_action_read: Scalars['Boolean']['output'];
  can_dial?: Maybe<Scalars['Boolean']['output']>;
  created_at: Scalars['DateTime']['output'];
  date_string?: Maybe<Scalars['String']['output']>;
  details?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  lead?: Maybe<Lead>;
  lead_id?: Maybe<Scalars['String']['output']>;
  payload?: Maybe<Scalars['Json']['output']>;
  phone_number?: Maybe<Scalars['String']['output']>;
  read: Scalars['Boolean']['output'];
  relevant_id?: Maybe<Scalars['String']['output']>;
  salesperson?: Maybe<Scalars['String']['output']>;
  send_action_back_to_server: Scalars['Boolean']['output'];
  text: Scalars['String']['output'];
  text_action?: Maybe<Scalars['String']['output']>;
  text_computed?: Maybe<Scalars['String']['output']>;
  type: NotificationType;
  type_label?: Maybe<Scalars['String']['output']>;
  updated_at: Scalars['DateTime']['output'];
  user?: Maybe<User>;
  user_id?: Maybe<Scalars['String']['output']>;
  voicemail_url?: Maybe<Scalars['String']['output']>;
};

export type NotificationCreateManyConferenceInput = {
  affected_user_id?: InputMaybe<Scalars['String']['input']>;
  call_to_action_read?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  details?: InputMaybe<Scalars['String']['input']>;
  expires_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  lead_id?: InputMaybe<Scalars['String']['input']>;
  organization_id?: InputMaybe<Scalars['String']['input']>;
  payload?: InputMaybe<Scalars['Json']['input']>;
  read?: InputMaybe<Scalars['Boolean']['input']>;
  relevant_id?: InputMaybe<Scalars['String']['input']>;
  send_action_back_to_server?: InputMaybe<Scalars['Boolean']['input']>;
  text: Scalars['String']['input'];
  text_action?: InputMaybe<Scalars['String']['input']>;
  type: NotificationType;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
  voicemail_url?: InputMaybe<Scalars['String']['input']>;
};

export type NotificationCreateManyConferenceInputEnvelope = {
  data?: InputMaybe<Array<NotificationCreateManyConferenceInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type NotificationCreateManyLeadInput = {
  affected_user_id?: InputMaybe<Scalars['String']['input']>;
  call_to_action_read?: InputMaybe<Scalars['Boolean']['input']>;
  conference_id?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  details?: InputMaybe<Scalars['String']['input']>;
  expires_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  organization_id?: InputMaybe<Scalars['String']['input']>;
  payload?: InputMaybe<Scalars['Json']['input']>;
  read?: InputMaybe<Scalars['Boolean']['input']>;
  relevant_id?: InputMaybe<Scalars['String']['input']>;
  send_action_back_to_server?: InputMaybe<Scalars['Boolean']['input']>;
  text: Scalars['String']['input'];
  text_action?: InputMaybe<Scalars['String']['input']>;
  type: NotificationType;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
  voicemail_url?: InputMaybe<Scalars['String']['input']>;
};

export type NotificationCreateManyLeadInputEnvelope = {
  data?: InputMaybe<Array<NotificationCreateManyLeadInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type NotificationCreateManyOrganizationInput = {
  affected_user_id?: InputMaybe<Scalars['String']['input']>;
  call_to_action_read?: InputMaybe<Scalars['Boolean']['input']>;
  conference_id?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  details?: InputMaybe<Scalars['String']['input']>;
  expires_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  lead_id?: InputMaybe<Scalars['String']['input']>;
  payload?: InputMaybe<Scalars['Json']['input']>;
  read?: InputMaybe<Scalars['Boolean']['input']>;
  relevant_id?: InputMaybe<Scalars['String']['input']>;
  send_action_back_to_server?: InputMaybe<Scalars['Boolean']['input']>;
  text: Scalars['String']['input'];
  text_action?: InputMaybe<Scalars['String']['input']>;
  type: NotificationType;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
  voicemail_url?: InputMaybe<Scalars['String']['input']>;
};

export type NotificationCreateManyOrganizationInputEnvelope = {
  data?: InputMaybe<Array<NotificationCreateManyOrganizationInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type NotificationCreateManyUserInput = {
  affected_user_id?: InputMaybe<Scalars['String']['input']>;
  call_to_action_read?: InputMaybe<Scalars['Boolean']['input']>;
  conference_id?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  details?: InputMaybe<Scalars['String']['input']>;
  expires_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  lead_id?: InputMaybe<Scalars['String']['input']>;
  organization_id?: InputMaybe<Scalars['String']['input']>;
  payload?: InputMaybe<Scalars['Json']['input']>;
  read?: InputMaybe<Scalars['Boolean']['input']>;
  relevant_id?: InputMaybe<Scalars['String']['input']>;
  send_action_back_to_server?: InputMaybe<Scalars['Boolean']['input']>;
  text: Scalars['String']['input'];
  text_action?: InputMaybe<Scalars['String']['input']>;
  type: NotificationType;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  voicemail_url?: InputMaybe<Scalars['String']['input']>;
};

export type NotificationCreateManyUserInputEnvelope = {
  data?: InputMaybe<Array<NotificationCreateManyUserInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type NotificationCreateNestedManyWithoutConferenceInput = {
  connect?: InputMaybe<Array<NotificationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<NotificationCreateOrConnectWithoutConferenceInput>>;
  create?: InputMaybe<Array<NotificationCreateWithoutConferenceInput>>;
  createMany?: InputMaybe<NotificationCreateManyConferenceInputEnvelope>;
};

export type NotificationCreateNestedManyWithoutLeadInput = {
  connect?: InputMaybe<Array<NotificationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<NotificationCreateOrConnectWithoutLeadInput>>;
  create?: InputMaybe<Array<NotificationCreateWithoutLeadInput>>;
  createMany?: InputMaybe<NotificationCreateManyLeadInputEnvelope>;
};

export type NotificationCreateNestedManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<NotificationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<NotificationCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<NotificationCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<NotificationCreateManyOrganizationInputEnvelope>;
};

export type NotificationCreateNestedManyWithoutUserInput = {
  connect?: InputMaybe<Array<NotificationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<NotificationCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<NotificationCreateWithoutUserInput>>;
  createMany?: InputMaybe<NotificationCreateManyUserInputEnvelope>;
};

export type NotificationCreateOrConnectWithoutConferenceInput = {
  create: NotificationCreateWithoutConferenceInput;
  where: NotificationWhereUniqueInput;
};

export type NotificationCreateOrConnectWithoutLeadInput = {
  create: NotificationCreateWithoutLeadInput;
  where: NotificationWhereUniqueInput;
};

export type NotificationCreateOrConnectWithoutOrganizationInput = {
  create: NotificationCreateWithoutOrganizationInput;
  where: NotificationWhereUniqueInput;
};

export type NotificationCreateOrConnectWithoutUserInput = {
  create: NotificationCreateWithoutUserInput;
  where: NotificationWhereUniqueInput;
};

export type NotificationCreateWithoutConferenceInput = {
  affected_user_id?: InputMaybe<Scalars['String']['input']>;
  call_to_action_read?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  details?: InputMaybe<Scalars['String']['input']>;
  expires_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  lead?: InputMaybe<LeadCreateNestedOneWithoutNotificationsInput>;
  Organization?: InputMaybe<OrganizationCreateNestedOneWithoutNotificationsInput>;
  payload?: InputMaybe<Scalars['Json']['input']>;
  read?: InputMaybe<Scalars['Boolean']['input']>;
  relevant_id?: InputMaybe<Scalars['String']['input']>;
  send_action_back_to_server?: InputMaybe<Scalars['Boolean']['input']>;
  text: Scalars['String']['input'];
  text_action?: InputMaybe<Scalars['String']['input']>;
  type: NotificationType;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user?: InputMaybe<UserCreateNestedOneWithoutNotificationsInput>;
  voicemail_url?: InputMaybe<Scalars['String']['input']>;
};

export type NotificationCreateWithoutLeadInput = {
  affected_user_id?: InputMaybe<Scalars['String']['input']>;
  call_to_action_read?: InputMaybe<Scalars['Boolean']['input']>;
  conference?: InputMaybe<ConferenceCreateNestedOneWithoutNotificationsInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  details?: InputMaybe<Scalars['String']['input']>;
  expires_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  Organization?: InputMaybe<OrganizationCreateNestedOneWithoutNotificationsInput>;
  payload?: InputMaybe<Scalars['Json']['input']>;
  read?: InputMaybe<Scalars['Boolean']['input']>;
  relevant_id?: InputMaybe<Scalars['String']['input']>;
  send_action_back_to_server?: InputMaybe<Scalars['Boolean']['input']>;
  text: Scalars['String']['input'];
  text_action?: InputMaybe<Scalars['String']['input']>;
  type: NotificationType;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user?: InputMaybe<UserCreateNestedOneWithoutNotificationsInput>;
  voicemail_url?: InputMaybe<Scalars['String']['input']>;
};

export type NotificationCreateWithoutOrganizationInput = {
  affected_user_id?: InputMaybe<Scalars['String']['input']>;
  call_to_action_read?: InputMaybe<Scalars['Boolean']['input']>;
  conference?: InputMaybe<ConferenceCreateNestedOneWithoutNotificationsInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  details?: InputMaybe<Scalars['String']['input']>;
  expires_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  lead?: InputMaybe<LeadCreateNestedOneWithoutNotificationsInput>;
  payload?: InputMaybe<Scalars['Json']['input']>;
  read?: InputMaybe<Scalars['Boolean']['input']>;
  relevant_id?: InputMaybe<Scalars['String']['input']>;
  send_action_back_to_server?: InputMaybe<Scalars['Boolean']['input']>;
  text: Scalars['String']['input'];
  text_action?: InputMaybe<Scalars['String']['input']>;
  type: NotificationType;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user?: InputMaybe<UserCreateNestedOneWithoutNotificationsInput>;
  voicemail_url?: InputMaybe<Scalars['String']['input']>;
};

export type NotificationCreateWithoutUserInput = {
  affected_user_id?: InputMaybe<Scalars['String']['input']>;
  call_to_action_read?: InputMaybe<Scalars['Boolean']['input']>;
  conference?: InputMaybe<ConferenceCreateNestedOneWithoutNotificationsInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  details?: InputMaybe<Scalars['String']['input']>;
  expires_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  lead?: InputMaybe<LeadCreateNestedOneWithoutNotificationsInput>;
  Organization?: InputMaybe<OrganizationCreateNestedOneWithoutNotificationsInput>;
  payload?: InputMaybe<Scalars['Json']['input']>;
  read?: InputMaybe<Scalars['Boolean']['input']>;
  relevant_id?: InputMaybe<Scalars['String']['input']>;
  send_action_back_to_server?: InputMaybe<Scalars['Boolean']['input']>;
  text: Scalars['String']['input'];
  text_action?: InputMaybe<Scalars['String']['input']>;
  type: NotificationType;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  voicemail_url?: InputMaybe<Scalars['String']['input']>;
};

export type NotificationListRelationFilter = {
  every?: InputMaybe<NotificationWhereInput>;
  none?: InputMaybe<NotificationWhereInput>;
  some?: InputMaybe<NotificationWhereInput>;
};

export type NotificationOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
  count?: InputMaybe<SortOrder>;
};

export type NotificationScalarWhereInput = {
  affected_user_id?: InputMaybe<StringNullableFilter>;
  AND?: InputMaybe<Array<NotificationScalarWhereInput>>;
  call_to_action_read?: InputMaybe<BoolFilter>;
  conference_id?: InputMaybe<StringNullableFilter>;
  created_at?: InputMaybe<DateTimeFilter>;
  details?: InputMaybe<StringNullableFilter>;
  expires_at?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<StringFilter>;
  lead_id?: InputMaybe<StringNullableFilter>;
  NOT?: InputMaybe<Array<NotificationScalarWhereInput>>;
  OR?: InputMaybe<Array<NotificationScalarWhereInput>>;
  organization_id?: InputMaybe<StringNullableFilter>;
  payload?: InputMaybe<JsonNullableFilter>;
  read?: InputMaybe<BoolFilter>;
  relevant_id?: InputMaybe<StringNullableFilter>;
  send_action_back_to_server?: InputMaybe<BoolFilter>;
  text?: InputMaybe<StringFilter>;
  text_action?: InputMaybe<StringNullableFilter>;
  type?: InputMaybe<EnumNotificationTypeFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
  user_id?: InputMaybe<StringNullableFilter>;
  voicemail_url?: InputMaybe<StringNullableFilter>;
};

export enum NotificationType {
  AddContactRequest = 'AddContactRequest',
  CallReportReviewed = 'CallReportReviewed',
  CallReportReviewedFeedback = 'CallReportReviewedFeedback',
  ConciergeWidget = 'ConciergeWidget',
  DeleteContactRequest = 'DeleteContactRequest',
  EditContactRequest = 'EditContactRequest',
  EmailRecieved = 'EmailRecieved',
  HotLead = 'HotLead',
  LeadAlreadyOwned = 'LeadAlreadyOwned',
  LeadImport = 'LeadImport',
  MissedCall = 'MissedCall',
  MissedCallMeNow = 'MissedCallMeNow',
  MissedEvent = 'MissedEvent',
  NewLead = 'NewLead',
  NewSms = 'NewSMS',
  Other = 'Other',
  ReassginLead = 'ReassginLead',
  SequenceExitedDueToScheduledEvent = 'SequenceExitedDueToScheduledEvent',
  TimeOffRequest = 'TimeOffRequest',
  TimeOffRequestAccepted = 'TimeOffRequestAccepted',
  TimeOffRequestDeclined = 'TimeOffRequestDeclined',
  UserDeleted = 'UserDeleted'
}

export type NotificationUpdateManyMutationInput = {
  affected_user_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  call_to_action_read?: InputMaybe<BoolFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  details?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  expires_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  payload?: InputMaybe<Scalars['Json']['input']>;
  read?: InputMaybe<BoolFieldUpdateOperationsInput>;
  relevant_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  send_action_back_to_server?: InputMaybe<BoolFieldUpdateOperationsInput>;
  text?: InputMaybe<StringFieldUpdateOperationsInput>;
  text_action?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumNotificationTypeFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  voicemail_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type NotificationUpdateManyWithoutConferenceInput = {
  connect?: InputMaybe<Array<NotificationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<NotificationCreateOrConnectWithoutConferenceInput>>;
  create?: InputMaybe<Array<NotificationCreateWithoutConferenceInput>>;
  createMany?: InputMaybe<NotificationCreateManyConferenceInputEnvelope>;
  delete?: InputMaybe<Array<NotificationWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<NotificationScalarWhereInput>>;
  disconnect?: InputMaybe<Array<NotificationWhereUniqueInput>>;
  set?: InputMaybe<Array<NotificationWhereUniqueInput>>;
  update?: InputMaybe<Array<NotificationUpdateWithWhereUniqueWithoutConferenceInput>>;
  updateMany?: InputMaybe<Array<NotificationUpdateManyWithWhereWithoutConferenceInput>>;
  upsert?: InputMaybe<Array<NotificationUpsertWithWhereUniqueWithoutConferenceInput>>;
};

export type NotificationUpdateManyWithoutLeadInput = {
  connect?: InputMaybe<Array<NotificationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<NotificationCreateOrConnectWithoutLeadInput>>;
  create?: InputMaybe<Array<NotificationCreateWithoutLeadInput>>;
  createMany?: InputMaybe<NotificationCreateManyLeadInputEnvelope>;
  delete?: InputMaybe<Array<NotificationWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<NotificationScalarWhereInput>>;
  disconnect?: InputMaybe<Array<NotificationWhereUniqueInput>>;
  set?: InputMaybe<Array<NotificationWhereUniqueInput>>;
  update?: InputMaybe<Array<NotificationUpdateWithWhereUniqueWithoutLeadInput>>;
  updateMany?: InputMaybe<Array<NotificationUpdateManyWithWhereWithoutLeadInput>>;
  upsert?: InputMaybe<Array<NotificationUpsertWithWhereUniqueWithoutLeadInput>>;
};

export type NotificationUpdateManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<NotificationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<NotificationCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<NotificationCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<NotificationCreateManyOrganizationInputEnvelope>;
  delete?: InputMaybe<Array<NotificationWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<NotificationScalarWhereInput>>;
  disconnect?: InputMaybe<Array<NotificationWhereUniqueInput>>;
  set?: InputMaybe<Array<NotificationWhereUniqueInput>>;
  update?: InputMaybe<Array<NotificationUpdateWithWhereUniqueWithoutOrganizationInput>>;
  updateMany?: InputMaybe<Array<NotificationUpdateManyWithWhereWithoutOrganizationInput>>;
  upsert?: InputMaybe<Array<NotificationUpsertWithWhereUniqueWithoutOrganizationInput>>;
};

export type NotificationUpdateManyWithoutUserInput = {
  connect?: InputMaybe<Array<NotificationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<NotificationCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<NotificationCreateWithoutUserInput>>;
  createMany?: InputMaybe<NotificationCreateManyUserInputEnvelope>;
  delete?: InputMaybe<Array<NotificationWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<NotificationScalarWhereInput>>;
  disconnect?: InputMaybe<Array<NotificationWhereUniqueInput>>;
  set?: InputMaybe<Array<NotificationWhereUniqueInput>>;
  update?: InputMaybe<Array<NotificationUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: InputMaybe<Array<NotificationUpdateManyWithWhereWithoutUserInput>>;
  upsert?: InputMaybe<Array<NotificationUpsertWithWhereUniqueWithoutUserInput>>;
};

export type NotificationUpdateManyWithWhereWithoutConferenceInput = {
  data: NotificationUpdateManyMutationInput;
  where: NotificationScalarWhereInput;
};

export type NotificationUpdateManyWithWhereWithoutLeadInput = {
  data: NotificationUpdateManyMutationInput;
  where: NotificationScalarWhereInput;
};

export type NotificationUpdateManyWithWhereWithoutOrganizationInput = {
  data: NotificationUpdateManyMutationInput;
  where: NotificationScalarWhereInput;
};

export type NotificationUpdateManyWithWhereWithoutUserInput = {
  data: NotificationUpdateManyMutationInput;
  where: NotificationScalarWhereInput;
};

export type NotificationUpdateWithoutConferenceInput = {
  affected_user_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  call_to_action_read?: InputMaybe<BoolFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  details?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  expires_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lead?: InputMaybe<LeadUpdateOneWithoutNotificationsInput>;
  Organization?: InputMaybe<OrganizationUpdateOneWithoutNotificationsInput>;
  payload?: InputMaybe<Scalars['Json']['input']>;
  read?: InputMaybe<BoolFieldUpdateOperationsInput>;
  relevant_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  send_action_back_to_server?: InputMaybe<BoolFieldUpdateOperationsInput>;
  text?: InputMaybe<StringFieldUpdateOperationsInput>;
  text_action?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumNotificationTypeFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneWithoutNotificationsInput>;
  voicemail_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type NotificationUpdateWithoutLeadInput = {
  affected_user_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  call_to_action_read?: InputMaybe<BoolFieldUpdateOperationsInput>;
  conference?: InputMaybe<ConferenceUpdateOneWithoutNotificationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  details?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  expires_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  Organization?: InputMaybe<OrganizationUpdateOneWithoutNotificationsInput>;
  payload?: InputMaybe<Scalars['Json']['input']>;
  read?: InputMaybe<BoolFieldUpdateOperationsInput>;
  relevant_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  send_action_back_to_server?: InputMaybe<BoolFieldUpdateOperationsInput>;
  text?: InputMaybe<StringFieldUpdateOperationsInput>;
  text_action?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumNotificationTypeFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneWithoutNotificationsInput>;
  voicemail_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type NotificationUpdateWithoutOrganizationInput = {
  affected_user_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  call_to_action_read?: InputMaybe<BoolFieldUpdateOperationsInput>;
  conference?: InputMaybe<ConferenceUpdateOneWithoutNotificationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  details?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  expires_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lead?: InputMaybe<LeadUpdateOneWithoutNotificationsInput>;
  payload?: InputMaybe<Scalars['Json']['input']>;
  read?: InputMaybe<BoolFieldUpdateOperationsInput>;
  relevant_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  send_action_back_to_server?: InputMaybe<BoolFieldUpdateOperationsInput>;
  text?: InputMaybe<StringFieldUpdateOperationsInput>;
  text_action?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumNotificationTypeFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneWithoutNotificationsInput>;
  voicemail_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type NotificationUpdateWithoutUserInput = {
  affected_user_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  call_to_action_read?: InputMaybe<BoolFieldUpdateOperationsInput>;
  conference?: InputMaybe<ConferenceUpdateOneWithoutNotificationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  details?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  expires_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lead?: InputMaybe<LeadUpdateOneWithoutNotificationsInput>;
  Organization?: InputMaybe<OrganizationUpdateOneWithoutNotificationsInput>;
  payload?: InputMaybe<Scalars['Json']['input']>;
  read?: InputMaybe<BoolFieldUpdateOperationsInput>;
  relevant_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  send_action_back_to_server?: InputMaybe<BoolFieldUpdateOperationsInput>;
  text?: InputMaybe<StringFieldUpdateOperationsInput>;
  text_action?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumNotificationTypeFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  voicemail_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type NotificationUpdateWithWhereUniqueWithoutConferenceInput = {
  data: NotificationUpdateWithoutConferenceInput;
  where: NotificationWhereUniqueInput;
};

export type NotificationUpdateWithWhereUniqueWithoutLeadInput = {
  data: NotificationUpdateWithoutLeadInput;
  where: NotificationWhereUniqueInput;
};

export type NotificationUpdateWithWhereUniqueWithoutOrganizationInput = {
  data: NotificationUpdateWithoutOrganizationInput;
  where: NotificationWhereUniqueInput;
};

export type NotificationUpdateWithWhereUniqueWithoutUserInput = {
  data: NotificationUpdateWithoutUserInput;
  where: NotificationWhereUniqueInput;
};

export type NotificationUpsertWithWhereUniqueWithoutConferenceInput = {
  create: NotificationCreateWithoutConferenceInput;
  update: NotificationUpdateWithoutConferenceInput;
  where: NotificationWhereUniqueInput;
};

export type NotificationUpsertWithWhereUniqueWithoutLeadInput = {
  create: NotificationCreateWithoutLeadInput;
  update: NotificationUpdateWithoutLeadInput;
  where: NotificationWhereUniqueInput;
};

export type NotificationUpsertWithWhereUniqueWithoutOrganizationInput = {
  create: NotificationCreateWithoutOrganizationInput;
  update: NotificationUpdateWithoutOrganizationInput;
  where: NotificationWhereUniqueInput;
};

export type NotificationUpsertWithWhereUniqueWithoutUserInput = {
  create: NotificationCreateWithoutUserInput;
  update: NotificationUpdateWithoutUserInput;
  where: NotificationWhereUniqueInput;
};

export type NotificationWhereInput = {
  affected_user_id?: InputMaybe<StringNullableFilter>;
  AND?: InputMaybe<Array<NotificationWhereInput>>;
  call_to_action_read?: InputMaybe<BoolFilter>;
  conference?: InputMaybe<ConferenceWhereInput>;
  conference_id?: InputMaybe<StringNullableFilter>;
  created_at?: InputMaybe<DateTimeFilter>;
  details?: InputMaybe<StringNullableFilter>;
  expires_at?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<StringFilter>;
  lead?: InputMaybe<LeadWhereInput>;
  lead_id?: InputMaybe<StringNullableFilter>;
  NOT?: InputMaybe<Array<NotificationWhereInput>>;
  OR?: InputMaybe<Array<NotificationWhereInput>>;
  Organization?: InputMaybe<OrganizationWhereInput>;
  organization_id?: InputMaybe<StringNullableFilter>;
  payload?: InputMaybe<JsonNullableFilter>;
  read?: InputMaybe<BoolFilter>;
  relevant_id?: InputMaybe<StringNullableFilter>;
  send_action_back_to_server?: InputMaybe<BoolFilter>;
  text?: InputMaybe<StringFilter>;
  text_action?: InputMaybe<StringNullableFilter>;
  type?: InputMaybe<EnumNotificationTypeFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserWhereInput>;
  user_id?: InputMaybe<StringNullableFilter>;
  voicemail_url?: InputMaybe<StringNullableFilter>;
};

export type NotificationWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']['input']>;
};

export type NullableBoolFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars['Boolean']['input']>;
};

export type NullableDateTimeFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars['DateTime']['input']>;
};

export type NullableEnumCallbackTypeFieldUpdateOperationsInput = {
  set?: InputMaybe<CallbackType>;
};

export type NullableEnumCallresultFieldUpdateOperationsInput = {
  set?: InputMaybe<Callresult>;
};

export type NullableEnumChannelFieldUpdateOperationsInput = {
  set?: InputMaybe<Channel>;
};

export type NullableEnumChannelfilterFieldUpdateOperationsInput = {
  set?: InputMaybe<Channelfilter>;
};

export type NullableEnumCommunication_TypeFieldUpdateOperationsInput = {
  set?: InputMaybe<Communication_Type>;
};

export type NullableEnumContactTypeFieldUpdateOperationsInput = {
  set?: InputMaybe<ContactType>;
};

export type NullableEnumCustomFieldTypeFieldUpdateOperationsInput = {
  set?: InputMaybe<CustomFieldType>;
};

export type NullableEnumDateOptionFieldUpdateOperationsInput = {
  set?: InputMaybe<DateOption>;
};

export type NullableEnumDispositiontypeFieldUpdateOperationsInput = {
  set?: InputMaybe<Dispositiontype>;
};

export type NullableEnumGoalsettingFieldUpdateOperationsInput = {
  set?: InputMaybe<Goalsetting>;
};

export type NullableEnumHingepointFieldUpdateOperationsInput = {
  set?: InputMaybe<Hingepoint>;
};

export type NullableEnumIntentTypeFieldUpdateOperationsInput = {
  set?: InputMaybe<IntentType>;
};

export type NullableEnumLeadcreationsourceFieldUpdateOperationsInput = {
  set?: InputMaybe<Leadcreationsource>;
};

export type NullableEnumLosFieldUpdateOperationsInput = {
  set?: InputMaybe<Los>;
};

export type NullableEnumMeasureByFilterFieldUpdateOperationsInput = {
  set?: InputMaybe<MeasureByFilter>;
};

export type NullableEnumMetricFieldUpdateOperationsInput = {
  set?: InputMaybe<Metric>;
};

export type NullableEnumMetrictypeFieldUpdateOperationsInput = {
  set?: InputMaybe<Metrictype>;
};

export type NullableEnumMonthFieldUpdateOperationsInput = {
  set?: InputMaybe<Month>;
};

export type NullableEnumPhaseFieldUpdateOperationsInput = {
  set?: InputMaybe<Phase>;
};

export type NullableEnumPositionFieldUpdateOperationsInput = {
  set?: InputMaybe<Position>;
};

export type NullableEnumRestingtypeFieldUpdateOperationsInput = {
  set?: InputMaybe<Restingtype>;
};

export type NullableEnumRoleFieldUpdateOperationsInput = {
  set?: InputMaybe<Role>;
};

export type NullableEnumScheduleItemTypeFieldUpdateOperationsInput = {
  set?: InputMaybe<ScheduleItemType>;
};

export type NullableEnumSequenceExitTypeFieldUpdateOperationsInput = {
  set?: InputMaybe<SequenceExitType>;
};

export type NullableEnumSequenceTaskFieldUpdateOperationsInput = {
  set?: InputMaybe<SequenceTask>;
};

export type NullableEnumSystemFieldTypesFieldUpdateOperationsInput = {
  set?: InputMaybe<SystemFieldTypes>;
};

export type NullableEnumTimeoffrequestresultFieldUpdateOperationsInput = {
  set?: InputMaybe<Timeoffrequestresult>;
};

export type NullableEnumWhenFieldUpdateOperationsInput = {
  set?: InputMaybe<When>;
};

export type NullableFloatFieldUpdateOperationsInput = {
  decrement?: InputMaybe<Scalars['Float']['input']>;
  divide?: InputMaybe<Scalars['Float']['input']>;
  increment?: InputMaybe<Scalars['Float']['input']>;
  multiply?: InputMaybe<Scalars['Float']['input']>;
  set?: InputMaybe<Scalars['Float']['input']>;
};

export type NullableIntFieldUpdateOperationsInput = {
  decrement?: InputMaybe<Scalars['Int']['input']>;
  divide?: InputMaybe<Scalars['Int']['input']>;
  increment?: InputMaybe<Scalars['Int']['input']>;
  multiply?: InputMaybe<Scalars['Int']['input']>;
  set?: InputMaybe<Scalars['Int']['input']>;
};

export type NullableStringFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars['String']['input']>;
};

export enum Operation {
  Add = 'Add',
  Delete = 'Delete',
  Update = 'Update'
}

export enum Operator {
  Contain = 'Contain',
  Equal = 'Equal',
  GreaterThan = 'GreaterThan',
  GreaterThanOrEqualTo = 'GreaterThanOrEqualTo',
  In = 'In',
  InTheNext = 'InTheNext',
  IsNull = 'IsNull',
  LessThan = 'LessThan',
  LessThanOrEqualTo = 'LessThanOrEqualTo',
  NotContain = 'NotContain',
  NotEqual = 'NotEqual',
  NotIn = 'NotIn',
  NotNull = 'NotNull',
  SinceTheLast = 'SinceTheLast'
}

/** Field Option for OPSIQ Fields */
export type OpsIqExternalMappingOption = {
  __typename?: 'OpsIQExternalMappingOption';
  external_field: Scalars['String']['output'];
  match: Scalars['Boolean']['output'];
  opsiq_field: Scalars['String']['output'];
};

export enum OptInStatus {
  LeadUnsubscribed = 'LeadUnsubscribed',
  OpsiqUnsubscribed = 'OPSIQUnsubscribed',
  Subscribed = 'Subscribed'
}

export type Organization = {
  __typename?: 'Organization';
  /** Return all admins of the organization */
  admins?: Maybe<Array<Maybe<User>>>;
  /** Return all managers and admins of the organization */
  admins_managers?: Maybe<Array<Maybe<User>>>;
  allow_remove_step_action: Scalars['Boolean']['output'];
  allow_snooze_step_action: Scalars['Boolean']['output'];
  auto_call_interval: Scalars['Int']['output'];
  auto_dial: Scalars['Boolean']['output'];
  automatic_call_back_delay?: Maybe<Scalars['Float']['output']>;
  best_action_ever_multiplier: Scalars['Float']['output'];
  /** Duration of call back event. */
  callback_duration?: Maybe<Scalars['Int']['output']>;
  /** Duration of overflow callback event. */
  callback_overflow?: Maybe<Scalars['Int']['output']>;
  can_edit_sdr_transfer_rules: Scalars['Boolean']['output'];
  claim_rule: Scalars['Int']['output'];
  concession: Array<Concession>;
  contract_duration_options?: Maybe<Array<Maybe<Scalars['Json']['output']>>>;
  created_at: Scalars['DateTime']['output'];
  custom_fields: Array<CustomField>;
  day_to_close: Scalars['Int']['output'];
  /** Duration of decision call demo. */
  decision_call_duration?: Maybe<Scalars['Int']['output']>;
  /** Duration of overflow decision call meeting. */
  decision_call_overflow?: Maybe<Scalars['Int']['output']>;
  default_contract_duration?: Maybe<Scalars['String']['output']>;
  default_demo_time_duration?: Maybe<Scalars['Int']['output']>;
  default_payment_terms_option?: Maybe<Scalars['String']['output']>;
  default_prepayment_option?: Maybe<Scalars['String']['output']>;
  /** Duration of demo. */
  demo_duration?: Maybe<Scalars['Int']['output']>;
  demo_held_call_back_delay?: Maybe<Scalars['Float']['output']>;
  demo_not_held_call_back_delay?: Maybe<Scalars['Float']['output']>;
  demo_not_held_call_back_short_delay?: Maybe<Scalars['Float']['output']>;
  /** Duration of overflow meeting. */
  demo_overflow?: Maybe<Scalars['Int']['output']>;
  demo_prep_time_minutes: Scalars['Int']['output'];
  dev_account?: Maybe<Scalars['Boolean']['output']>;
  dial_rule: Scalars['Int']['output'];
  dispositions: Array<Disposition>;
  double_booking: Scalars['Boolean']['output'];
  edge_servers: Array<Scalars['String']['output']>;
  enable_modified_metrics?: Maybe<Scalars['Boolean']['output']>;
  enable_pay_period_view?: Maybe<Scalars['Boolean']['output']>;
  enable_time_constraint?: Maybe<Scalars['Boolean']['output']>;
  error_log_email_address: Array<Scalars['String']['output']>;
  estimated_average_num_of_weekly_overbooked_demo_slots?: Maybe<Scalars['Int']['output']>;
  helpful_links: Array<Scalars['String']['output']>;
  hold_goal: Scalars['Float']['output'];
  hubspot_last_sync_date?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['String']['output'];
  inbound_close_rate?: Maybe<Scalars['Float']['output']>;
  inbound_dm_connect_rate?: Maybe<Scalars['Float']['output']>;
  inbound_lt_hold_rate?: Maybe<Scalars['Float']['output']>;
  inbound_revenue_value_of_sale?: Maybe<Scalars['Float']['output']>;
  inbound_scheduled_hold_rate?: Maybe<Scalars['Float']['output']>;
  inbound_set_rate?: Maybe<Scalars['Float']['output']>;
  industry_options: Array<IndustryOption>;
  initial_sdr_sets_reps_day?: Maybe<Scalars['Float']['output']>;
  international: Scalars['Boolean']['output'];
  is_email_domain_verified: Scalars['Boolean']['output'];
  last_lead_value_compute_time?: Maybe<Scalars['DateTime']['output']>;
  lead_activities: Array<LeadActivity>;
  lead_intent: Array<LeadIntent>;
  lead_release_same_rep_delay_rule: Scalars['Int']['output'];
  lead_routing_email_list: Array<Scalars['String']['output']>;
  lead_routing_rules: Array<Rule>;
  leads: Array<Lead>;
  leadsource_options: Array<LeadSourceOption>;
  liveUserStatus: Array<LiveUserStatus>;
  made_contact_rule: Scalars['Int']['output'];
  make_sale_address_required: Scalars['Boolean']['output'];
  make_sale_business_name_required: Scalars['Boolean']['output'];
  make_sale_city_required: Scalars['Boolean']['output'];
  make_sale_country_required: Scalars['Boolean']['output'];
  make_sale_first_name_required: Scalars['Boolean']['output'];
  make_sale_industry_required: Scalars['Boolean']['output'];
  make_sale_last_name_required: Scalars['Boolean']['output'];
  make_sale_lead_source_required: Scalars['Boolean']['output'];
  make_sale_primary_email_required: Scalars['Boolean']['output'];
  make_sale_primary_phone_number_required: Scalars['Boolean']['output'];
  make_sale_state_required: Scalars['Boolean']['output'];
  make_sale_sub_industry_required: Scalars['Boolean']['output'];
  make_sale_zip_required: Scalars['Boolean']['output'];
  /** Return all managers of the organization */
  managers?: Maybe<Array<Maybe<User>>>;
  maxium_day_lag_set_to_schedule?: Maybe<Scalars['Int']['output']>;
  mrr: Scalars['Int']['output'];
  mrr_label?: Maybe<Scalars['String']['output']>;
  mrr_required_on_sale: Scalars['Boolean']['output'];
  name?: Maybe<Scalars['String']['output']>;
  /** The number of Account Executives taking demos in the organization */
  number_of_aes_taking_demos?: Maybe<Scalars['Int']['output']>;
  onboardComplete?: Maybe<Scalars['Boolean']['output']>;
  organization_holidays: Array<OrganizationHoliday>;
  organization_hours: Array<OrganizationHour>;
  OrganizationPaymentSetting?: Maybe<OrganizationPaymentSettings>;
  orgCalendarInviteMetadata: Array<OrgCalendarInviteMetadata>;
  outbound_close_rate?: Maybe<Scalars['Float']['output']>;
  outbound_dm_connect_rate?: Maybe<Scalars['Float']['output']>;
  outbound_lt_hold_rate?: Maybe<Scalars['Float']['output']>;
  outbound_revenue_value_of_sale?: Maybe<Scalars['Float']['output']>;
  outbound_scheduled_hold_rate?: Maybe<Scalars['Float']['output']>;
  outbound_set_rate?: Maybe<Scalars['Float']['output']>;
  payment_terms_options?: Maybe<Array<Maybe<Scalars['Json']['output']>>>;
  preferred_goal_setting?: Maybe<Goalsetting>;
  prepayment: Scalars['Boolean']['output'];
  prepayment_options?: Maybe<Array<Maybe<Scalars['Json']['output']>>>;
  products: Array<Product>;
  re_cold_call_hour_delay?: Maybe<Scalars['Float']['output']>;
  release_lead_resting: Scalars['Int']['output'];
  rep_claim_limit: Scalars['Int']['output'];
  rep_lead_allotment?: Maybe<Scalars['Int']['output']>;
  rep_level_call_transfer: Scalars['Boolean']['output'];
  /** Return all reps of the organization */
  Reps?: Maybe<Array<Maybe<User>>>;
  reps_can_bulk_unassign: Scalars['Boolean']['output'];
  reps_can_view_all_notes: Scalars['Boolean']['output'];
  require_sale_notes: Scalars['Boolean']['output'];
  resting_after_skipped_CC: Scalars['Int']['output'];
  resting_period: Scalars['Int']['output'];
  resting_period_after_unsuccessful_cold_call?: Maybe<Scalars['Float']['output']>;
  resting_period_after_unsuccessful_demo?: Maybe<Scalars['Float']['output']>;
  revenue_plan: Array<RevenuePlanItem>;
  router_numbers?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  sale_config_id?: Maybe<Scalars['String']['output']>;
  sale_notification_email_list: Array<Scalars['String']['output']>;
  SaleConfig?: Maybe<SaleConfig>;
  sales: Array<Sale>;
  salesforce_domain?: Maybe<Scalars['String']['output']>;
  self_sourcing_rule: Scalars['Int']['output'];
  send_error_log_email?: Maybe<Scalars['Boolean']['output']>;
  send_lead_routing_email: Scalars['Boolean']['output'];
  sendgrid_email_domain?: Maybe<Scalars['String']['output']>;
  sendgrid_email_domain_id?: Maybe<Scalars['Int']['output']>;
  set_goal: Scalars['Float']['output'];
  short_resting: Scalars['Int']['output'];
  show_calendar_options: Scalars['Boolean']['output'];
  show_contract_duration_sales: Scalars['Boolean']['output'];
  show_lead_source_to_rep: Scalars['Boolean']['output'];
  show_nocontact_button: Scalars['Boolean']['output'];
  show_payment_terms_sales: Scalars['Boolean']['output'];
  show_rep_all_leads: Scalars['Boolean']['output'];
  show_rep_my_leads: Scalars['Boolean']['output'];
  /** Returns true if the user should show a custom confirm details screen in the make sale flow. */
  show_sale_flow_custom_fields?: Maybe<Scalars['Boolean']['output']>;
  show_system_view: Scalars['Boolean']['output'];
  showLeadSourceToUser?: Maybe<Scalars['Boolean']['output']>;
  sites: Array<Site>;
  /** Return all active sites of the organization */
  Sites?: Maybe<Array<Maybe<Site>>>;
  sms_opt_in_dialog?: Maybe<Scalars['String']['output']>;
  teams: Array<Team>;
  /** Return all active teams of the organization. */
  Teams?: Maybe<Array<Maybe<Team>>>;
  timekit_id?: Maybe<Scalars['String']['output']>;
  timezone?: Maybe<Scalars['String']['output']>;
  transfer_timeout: Scalars['Int']['output'];
  updated_at: Scalars['DateTime']['output'];
  use_revenue_planning?: Maybe<Scalars['Boolean']['output']>;
  users: Array<User>;
  win_rep_day_goal: Scalars['Float']['output'];
};


export type OrganizationConcessionArgs = {
  after?: InputMaybe<ConcessionWhereUniqueInput>;
  before?: InputMaybe<ConcessionWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type OrganizationCustom_FieldsArgs = {
  after?: InputMaybe<CustomFieldWhereUniqueInput>;
  before?: InputMaybe<CustomFieldWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type OrganizationDispositionsArgs = {
  after?: InputMaybe<DispositionWhereUniqueInput>;
  before?: InputMaybe<DispositionWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type OrganizationIndustry_OptionsArgs = {
  after?: InputMaybe<IndustryOptionWhereUniqueInput>;
  before?: InputMaybe<IndustryOptionWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type OrganizationLead_ActivitiesArgs = {
  after?: InputMaybe<LeadActivityWhereUniqueInput>;
  before?: InputMaybe<LeadActivityWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type OrganizationLead_IntentArgs = {
  after?: InputMaybe<LeadIntentWhereUniqueInput>;
  before?: InputMaybe<LeadIntentWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type OrganizationLead_Routing_RulesArgs = {
  after?: InputMaybe<RuleWhereUniqueInput>;
  before?: InputMaybe<RuleWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type OrganizationLeadsArgs = {
  after?: InputMaybe<LeadWhereUniqueInput>;
  before?: InputMaybe<LeadWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type OrganizationLeadsource_OptionsArgs = {
  after?: InputMaybe<LeadSourceOptionWhereUniqueInput>;
  before?: InputMaybe<LeadSourceOptionWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type OrganizationLiveUserStatusArgs = {
  after?: InputMaybe<LiveUserStatusWhereUniqueInput>;
  before?: InputMaybe<LiveUserStatusWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type OrganizationOrganization_HolidaysArgs = {
  after?: InputMaybe<OrganizationHolidayWhereUniqueInput>;
  before?: InputMaybe<OrganizationHolidayWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type OrganizationOrganization_HoursArgs = {
  after?: InputMaybe<OrganizationHourWhereUniqueInput>;
  before?: InputMaybe<OrganizationHourWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type OrganizationOrgCalendarInviteMetadataArgs = {
  after?: InputMaybe<OrgCalendarInviteMetadataWhereUniqueInput>;
  before?: InputMaybe<OrgCalendarInviteMetadataWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type OrganizationProductsArgs = {
  after?: InputMaybe<ProductWhereUniqueInput>;
  before?: InputMaybe<ProductWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type OrganizationRevenue_PlanArgs = {
  after?: InputMaybe<RevenuePlanItemWhereUniqueInput>;
  before?: InputMaybe<RevenuePlanItemWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type OrganizationSalesArgs = {
  after?: InputMaybe<SaleWhereUniqueInput>;
  before?: InputMaybe<SaleWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type OrganizationSitesArgs = {
  after?: InputMaybe<SiteWhereUniqueInput>;
  before?: InputMaybe<SiteWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type OrganizationTeamsArgs = {
  after?: InputMaybe<TeamWhereUniqueInput>;
  before?: InputMaybe<TeamWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type OrganizationUsersArgs = {
  after?: InputMaybe<UserWhereUniqueInput>;
  before?: InputMaybe<UserWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type OrganizationCreateedge_ServersInput = {
  set?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type OrganizationCreateemail_Conflict_Notification_Email_ListInput = {
  set?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type OrganizationCreateerror_Log_Email_AddressInput = {
  set?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type OrganizationCreatehelpful_LinksInput = {
  set?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type OrganizationCreatelead_Routing_Email_ListInput = {
  set?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type OrganizationCreateManyedge_ServersInput = {
  set?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type OrganizationCreateManyemail_Conflict_Notification_Email_ListInput = {
  set?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type OrganizationCreateManyerror_Log_Email_AddressInput = {
  set?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type OrganizationCreateManyhelpful_LinksInput = {
  set?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type OrganizationCreateManylead_Routing_Email_ListInput = {
  set?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type OrganizationCreateManyOrganizationPaymentSettingsInput = {
  allow_calendar_invite?: InputMaybe<Scalars['Boolean']['input']>;
  allow_remove_step_action?: InputMaybe<Scalars['Boolean']['input']>;
  allow_snooze_step_action?: InputMaybe<Scalars['Boolean']['input']>;
  always_record?: InputMaybe<Scalars['Boolean']['input']>;
  arv_initial_held_demos_generated_by_sdr_weight?: InputMaybe<Scalars['Float']['input']>;
  auto_call_interval?: InputMaybe<Scalars['Int']['input']>;
  auto_dial?: InputMaybe<Scalars['Boolean']['input']>;
  automatic_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  best_action_ever_multiplier?: InputMaybe<Scalars['Float']['input']>;
  can_edit_sdr_transfer_rules?: InputMaybe<Scalars['Boolean']['input']>;
  claim_rule?: InputMaybe<Scalars['Int']['input']>;
  conference_region?: InputMaybe<ConferenceRegion>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  day_to_close?: InputMaybe<Scalars['Int']['input']>;
  default_demo_time_duration?: InputMaybe<Scalars['Int']['input']>;
  demo_held_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_not_held_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_not_held_call_back_short_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_prep_time_minutes?: InputMaybe<Scalars['Int']['input']>;
  dev_account?: InputMaybe<Scalars['Boolean']['input']>;
  dial_rule?: InputMaybe<Scalars['Int']['input']>;
  double_booking?: InputMaybe<Scalars['Boolean']['input']>;
  dynamic_date_operators?: InputMaybe<Scalars['Boolean']['input']>;
  edge_servers?: InputMaybe<OrganizationCreateManyedge_ServersInput>;
  email_conflict_notification_email_list?: InputMaybe<OrganizationCreateManyemail_Conflict_Notification_Email_ListInput>;
  enable_modified_metrics?: InputMaybe<Scalars['Boolean']['input']>;
  enable_pay_period_view?: InputMaybe<Scalars['Boolean']['input']>;
  enable_time_constraint?: InputMaybe<Scalars['Boolean']['input']>;
  error_log_email_address?: InputMaybe<OrganizationCreateManyerror_Log_Email_AddressInput>;
  estimated_average_num_of_weekly_overbooked_demo_slots?: InputMaybe<Scalars['Int']['input']>;
  held_demos_per_cc_connect?: InputMaybe<Scalars['Float']['input']>;
  helpful_links?: InputMaybe<OrganizationCreateManyhelpful_LinksInput>;
  hold_goal?: InputMaybe<Scalars['Float']['input']>;
  hubspot_last_sync_date?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  inbound_close_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_dm_connect_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_lt_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_revenue_value_of_sale?: InputMaybe<Scalars['Float']['input']>;
  inbound_scheduled_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_set_rate?: InputMaybe<Scalars['Float']['input']>;
  initial_sdr_sets_reps_day?: InputMaybe<Scalars['Float']['input']>;
  international?: InputMaybe<Scalars['Boolean']['input']>;
  is_email_domain_verified?: InputMaybe<Scalars['Boolean']['input']>;
  is_salesforce_sandbox?: InputMaybe<Scalars['Boolean']['input']>;
  last_lead_value_compute_time?: InputMaybe<Scalars['DateTime']['input']>;
  lead_release_same_rep_delay_rule?: InputMaybe<Scalars['Int']['input']>;
  lead_routing_email_list?: InputMaybe<OrganizationCreateManylead_Routing_Email_ListInput>;
  made_contact_rule?: InputMaybe<Scalars['Int']['input']>;
  make_sale_address_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_business_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_city_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_country_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_first_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_industry_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_last_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_lead_source_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_primary_email_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_primary_phone_number_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_state_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_sub_industry_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_zip_required?: InputMaybe<Scalars['Boolean']['input']>;
  maxium_day_lag_set_to_schedule?: InputMaybe<Scalars['Int']['input']>;
  mrr?: InputMaybe<Scalars['Int']['input']>;
  mrr_label?: InputMaybe<Scalars['String']['input']>;
  mrr_required_on_sale?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  onboardComplete?: InputMaybe<Scalars['Boolean']['input']>;
  outbound_close_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_dm_connect_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_lt_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_revenue_value_of_sale?: InputMaybe<Scalars['Float']['input']>;
  outbound_scheduled_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_set_rate?: InputMaybe<Scalars['Float']['input']>;
  participant_jitter_buffer_size?: InputMaybe<JitterBufferSize>;
  preferred_goal_setting?: InputMaybe<Goalsetting>;
  prepayment?: InputMaybe<Scalars['Boolean']['input']>;
  re_cold_call_hour_delay?: InputMaybe<Scalars['Float']['input']>;
  recordingStatus?: InputMaybe<RecordingStatus>;
  release_lead_resting?: InputMaybe<Scalars['Int']['input']>;
  rep_claim_limit?: InputMaybe<Scalars['Int']['input']>;
  rep_lead_allotment?: InputMaybe<Scalars['Int']['input']>;
  rep_level_call_transfer?: InputMaybe<Scalars['Boolean']['input']>;
  reps_can_bulk_unassign?: InputMaybe<Scalars['Boolean']['input']>;
  reps_can_view_all_notes?: InputMaybe<Scalars['Boolean']['input']>;
  require_sale_notes?: InputMaybe<Scalars['Boolean']['input']>;
  resting_after_skipped_CC?: InputMaybe<Scalars['Int']['input']>;
  resting_period?: InputMaybe<Scalars['Int']['input']>;
  resting_period_after_unsuccessful_cold_call?: InputMaybe<Scalars['Float']['input']>;
  resting_period_after_unsuccessful_demo?: InputMaybe<Scalars['Float']['input']>;
  sale_config_id?: InputMaybe<Scalars['String']['input']>;
  sale_notification_email_list?: InputMaybe<OrganizationCreateManysale_Notification_Email_ListInput>;
  salesforce_domain?: InputMaybe<Scalars['String']['input']>;
  salesforce_last_sync_date?: InputMaybe<Scalars['DateTime']['input']>;
  self_sourcing_rule?: InputMaybe<Scalars['Int']['input']>;
  send_error_log_email?: InputMaybe<Scalars['Boolean']['input']>;
  send_lead_routing_email?: InputMaybe<Scalars['Boolean']['input']>;
  sendgrid_email_domain?: InputMaybe<Scalars['String']['input']>;
  sendgrid_email_domain_id?: InputMaybe<Scalars['Int']['input']>;
  set_goal?: InputMaybe<Scalars['Float']['input']>;
  short_resting?: InputMaybe<Scalars['Int']['input']>;
  show_calendar_options?: InputMaybe<Scalars['Boolean']['input']>;
  show_contract_duration_sales?: InputMaybe<Scalars['Boolean']['input']>;
  show_lead_source_to_rep?: InputMaybe<Scalars['Boolean']['input']>;
  show_nocontact_button?: InputMaybe<Scalars['Boolean']['input']>;
  show_payment_terms_sales?: InputMaybe<Scalars['Boolean']['input']>;
  show_rep_all_leads?: InputMaybe<Scalars['Boolean']['input']>;
  show_rep_my_leads?: InputMaybe<Scalars['Boolean']['input']>;
  show_system_view?: InputMaybe<Scalars['Boolean']['input']>;
  sms_opt_in_dialog?: InputMaybe<Scalars['String']['input']>;
  timekit_id?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  transfer_timeout?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  use_revenue_planning?: InputMaybe<Scalars['Boolean']['input']>;
  win_rep_day_goal?: InputMaybe<Scalars['Float']['input']>;
};

export type OrganizationCreateManyOrganizationPaymentSettingsInputEnvelope = {
  data?: InputMaybe<Array<OrganizationCreateManyOrganizationPaymentSettingsInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type OrganizationCreateManysale_Notification_Email_ListInput = {
  set?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type OrganizationCreateManySaleConfigInput = {
  allow_calendar_invite?: InputMaybe<Scalars['Boolean']['input']>;
  allow_remove_step_action?: InputMaybe<Scalars['Boolean']['input']>;
  allow_snooze_step_action?: InputMaybe<Scalars['Boolean']['input']>;
  always_record?: InputMaybe<Scalars['Boolean']['input']>;
  arv_initial_held_demos_generated_by_sdr_weight?: InputMaybe<Scalars['Float']['input']>;
  auto_call_interval?: InputMaybe<Scalars['Int']['input']>;
  auto_dial?: InputMaybe<Scalars['Boolean']['input']>;
  automatic_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  best_action_ever_multiplier?: InputMaybe<Scalars['Float']['input']>;
  can_edit_sdr_transfer_rules?: InputMaybe<Scalars['Boolean']['input']>;
  claim_rule?: InputMaybe<Scalars['Int']['input']>;
  conference_region?: InputMaybe<ConferenceRegion>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  day_to_close?: InputMaybe<Scalars['Int']['input']>;
  default_demo_time_duration?: InputMaybe<Scalars['Int']['input']>;
  demo_held_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_not_held_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_not_held_call_back_short_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_prep_time_minutes?: InputMaybe<Scalars['Int']['input']>;
  dev_account?: InputMaybe<Scalars['Boolean']['input']>;
  dial_rule?: InputMaybe<Scalars['Int']['input']>;
  double_booking?: InputMaybe<Scalars['Boolean']['input']>;
  dynamic_date_operators?: InputMaybe<Scalars['Boolean']['input']>;
  edge_servers?: InputMaybe<OrganizationCreateManyedge_ServersInput>;
  email_conflict_notification_email_list?: InputMaybe<OrganizationCreateManyemail_Conflict_Notification_Email_ListInput>;
  enable_modified_metrics?: InputMaybe<Scalars['Boolean']['input']>;
  enable_pay_period_view?: InputMaybe<Scalars['Boolean']['input']>;
  enable_time_constraint?: InputMaybe<Scalars['Boolean']['input']>;
  error_log_email_address?: InputMaybe<OrganizationCreateManyerror_Log_Email_AddressInput>;
  estimated_average_num_of_weekly_overbooked_demo_slots?: InputMaybe<Scalars['Int']['input']>;
  held_demos_per_cc_connect?: InputMaybe<Scalars['Float']['input']>;
  helpful_links?: InputMaybe<OrganizationCreateManyhelpful_LinksInput>;
  hold_goal?: InputMaybe<Scalars['Float']['input']>;
  hubspot_last_sync_date?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  inbound_close_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_dm_connect_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_lt_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_revenue_value_of_sale?: InputMaybe<Scalars['Float']['input']>;
  inbound_scheduled_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_set_rate?: InputMaybe<Scalars['Float']['input']>;
  initial_sdr_sets_reps_day?: InputMaybe<Scalars['Float']['input']>;
  international?: InputMaybe<Scalars['Boolean']['input']>;
  is_email_domain_verified?: InputMaybe<Scalars['Boolean']['input']>;
  is_salesforce_sandbox?: InputMaybe<Scalars['Boolean']['input']>;
  last_lead_value_compute_time?: InputMaybe<Scalars['DateTime']['input']>;
  lead_release_same_rep_delay_rule?: InputMaybe<Scalars['Int']['input']>;
  lead_routing_email_list?: InputMaybe<OrganizationCreateManylead_Routing_Email_ListInput>;
  made_contact_rule?: InputMaybe<Scalars['Int']['input']>;
  make_sale_address_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_business_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_city_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_country_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_first_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_industry_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_last_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_lead_source_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_primary_email_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_primary_phone_number_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_state_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_sub_industry_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_zip_required?: InputMaybe<Scalars['Boolean']['input']>;
  maxium_day_lag_set_to_schedule?: InputMaybe<Scalars['Int']['input']>;
  mrr?: InputMaybe<Scalars['Int']['input']>;
  mrr_label?: InputMaybe<Scalars['String']['input']>;
  mrr_required_on_sale?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  onboardComplete?: InputMaybe<Scalars['Boolean']['input']>;
  outbound_close_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_dm_connect_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_lt_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_revenue_value_of_sale?: InputMaybe<Scalars['Float']['input']>;
  outbound_scheduled_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_set_rate?: InputMaybe<Scalars['Float']['input']>;
  participant_jitter_buffer_size?: InputMaybe<JitterBufferSize>;
  payment_config_id?: InputMaybe<Scalars['String']['input']>;
  preferred_goal_setting?: InputMaybe<Goalsetting>;
  prepayment?: InputMaybe<Scalars['Boolean']['input']>;
  re_cold_call_hour_delay?: InputMaybe<Scalars['Float']['input']>;
  recordingStatus?: InputMaybe<RecordingStatus>;
  release_lead_resting?: InputMaybe<Scalars['Int']['input']>;
  rep_claim_limit?: InputMaybe<Scalars['Int']['input']>;
  rep_lead_allotment?: InputMaybe<Scalars['Int']['input']>;
  rep_level_call_transfer?: InputMaybe<Scalars['Boolean']['input']>;
  reps_can_bulk_unassign?: InputMaybe<Scalars['Boolean']['input']>;
  reps_can_view_all_notes?: InputMaybe<Scalars['Boolean']['input']>;
  require_sale_notes?: InputMaybe<Scalars['Boolean']['input']>;
  resting_after_skipped_CC?: InputMaybe<Scalars['Int']['input']>;
  resting_period?: InputMaybe<Scalars['Int']['input']>;
  resting_period_after_unsuccessful_cold_call?: InputMaybe<Scalars['Float']['input']>;
  resting_period_after_unsuccessful_demo?: InputMaybe<Scalars['Float']['input']>;
  sale_notification_email_list?: InputMaybe<OrganizationCreateManysale_Notification_Email_ListInput>;
  salesforce_domain?: InputMaybe<Scalars['String']['input']>;
  salesforce_last_sync_date?: InputMaybe<Scalars['DateTime']['input']>;
  self_sourcing_rule?: InputMaybe<Scalars['Int']['input']>;
  send_error_log_email?: InputMaybe<Scalars['Boolean']['input']>;
  send_lead_routing_email?: InputMaybe<Scalars['Boolean']['input']>;
  sendgrid_email_domain?: InputMaybe<Scalars['String']['input']>;
  sendgrid_email_domain_id?: InputMaybe<Scalars['Int']['input']>;
  set_goal?: InputMaybe<Scalars['Float']['input']>;
  short_resting?: InputMaybe<Scalars['Int']['input']>;
  show_calendar_options?: InputMaybe<Scalars['Boolean']['input']>;
  show_contract_duration_sales?: InputMaybe<Scalars['Boolean']['input']>;
  show_lead_source_to_rep?: InputMaybe<Scalars['Boolean']['input']>;
  show_nocontact_button?: InputMaybe<Scalars['Boolean']['input']>;
  show_payment_terms_sales?: InputMaybe<Scalars['Boolean']['input']>;
  show_rep_all_leads?: InputMaybe<Scalars['Boolean']['input']>;
  show_rep_my_leads?: InputMaybe<Scalars['Boolean']['input']>;
  show_system_view?: InputMaybe<Scalars['Boolean']['input']>;
  sms_opt_in_dialog?: InputMaybe<Scalars['String']['input']>;
  timekit_id?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  transfer_timeout?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  use_revenue_planning?: InputMaybe<Scalars['Boolean']['input']>;
  win_rep_day_goal?: InputMaybe<Scalars['Float']['input']>;
};

export type OrganizationCreateManySaleConfigInputEnvelope = {
  data?: InputMaybe<Array<OrganizationCreateManySaleConfigInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type OrganizationCreateNestedManyWithoutOrganizationPaymentSettingsInput = {
  connect?: InputMaybe<Array<OrganizationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<OrganizationCreateOrConnectWithoutOrganizationPaymentSettingsInput>>;
  create?: InputMaybe<Array<OrganizationCreateWithoutOrganizationPaymentSettingsInput>>;
  createMany?: InputMaybe<OrganizationCreateManyOrganizationPaymentSettingsInputEnvelope>;
};

export type OrganizationCreateNestedManyWithoutSaleConfigInput = {
  connect?: InputMaybe<Array<OrganizationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<OrganizationCreateOrConnectWithoutSaleConfigInput>>;
  create?: InputMaybe<Array<OrganizationCreateWithoutSaleConfigInput>>;
  createMany?: InputMaybe<OrganizationCreateManySaleConfigInputEnvelope>;
};

export type OrganizationCreateNestedOneWithoutAssociationHistoriesInput = {
  connect?: InputMaybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrganizationCreateOrConnectWithoutAssociationHistoriesInput>;
  create?: InputMaybe<OrganizationCreateWithoutAssociationHistoriesInput>;
};

export type OrganizationCreateNestedOneWithoutAutomated_CampaignsInput = {
  connect?: InputMaybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrganizationCreateOrConnectWithoutAutomated_CampaignsInput>;
  create?: InputMaybe<OrganizationCreateWithoutAutomated_CampaignsInput>;
};

export type OrganizationCreateNestedOneWithoutAutoTransferSelectionsInput = {
  connect?: InputMaybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrganizationCreateOrConnectWithoutAutoTransferSelectionsInput>;
  create?: InputMaybe<OrganizationCreateWithoutAutoTransferSelectionsInput>;
};

export type OrganizationCreateNestedOneWithoutAvailablitiesInput = {
  connect?: InputMaybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrganizationCreateOrConnectWithoutAvailablitiesInput>;
  create?: InputMaybe<OrganizationCreateWithoutAvailablitiesInput>;
};

export type OrganizationCreateNestedOneWithoutBest_MetricsInput = {
  connect?: InputMaybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrganizationCreateOrConnectWithoutBest_MetricsInput>;
  create?: InputMaybe<OrganizationCreateWithoutBest_MetricsInput>;
};

export type OrganizationCreateNestedOneWithoutCallMeNowAttemptsInput = {
  connect?: InputMaybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrganizationCreateOrConnectWithoutCallMeNowAttemptsInput>;
  create?: InputMaybe<OrganizationCreateWithoutCallMeNowAttemptsInput>;
};

export type OrganizationCreateNestedOneWithoutCallMeNowsInput = {
  connect?: InputMaybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrganizationCreateOrConnectWithoutCallMeNowsInput>;
  create?: InputMaybe<OrganizationCreateWithoutCallMeNowsInput>;
};

export type OrganizationCreateNestedOneWithoutCallSharingsInput = {
  connect?: InputMaybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrganizationCreateOrConnectWithoutCallSharingsInput>;
  create?: InputMaybe<OrganizationCreateWithoutCallSharingsInput>;
};

export type OrganizationCreateNestedOneWithoutConcessionInput = {
  connect?: InputMaybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrganizationCreateOrConnectWithoutConcessionInput>;
  create?: InputMaybe<OrganizationCreateWithoutConcessionInput>;
};

export type OrganizationCreateNestedOneWithoutConferencesInput = {
  connect?: InputMaybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrganizationCreateOrConnectWithoutConferencesInput>;
  create?: InputMaybe<OrganizationCreateWithoutConferencesInput>;
};

export type OrganizationCreateNestedOneWithoutContactRequestsInput = {
  connect?: InputMaybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrganizationCreateOrConnectWithoutContactRequestsInput>;
  create?: InputMaybe<OrganizationCreateWithoutContactRequestsInput>;
};

export type OrganizationCreateNestedOneWithoutContactsInput = {
  connect?: InputMaybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrganizationCreateOrConnectWithoutContactsInput>;
  create?: InputMaybe<OrganizationCreateWithoutContactsInput>;
};

export type OrganizationCreateNestedOneWithoutCustom_FieldsInput = {
  connect?: InputMaybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrganizationCreateOrConnectWithoutCustom_FieldsInput>;
  create?: InputMaybe<OrganizationCreateWithoutCustom_FieldsInput>;
};

export type OrganizationCreateNestedOneWithoutCustomObjectFieldForeignKeyInput = {
  connect?: InputMaybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrganizationCreateOrConnectWithoutCustomObjectFieldForeignKeyInput>;
  create?: InputMaybe<OrganizationCreateWithoutCustomObjectFieldForeignKeyInput>;
};

export type OrganizationCreateNestedOneWithoutCustomObjectFieldInput = {
  connect?: InputMaybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrganizationCreateOrConnectWithoutCustomObjectFieldInput>;
  create?: InputMaybe<OrganizationCreateWithoutCustomObjectFieldInput>;
};

export type OrganizationCreateNestedOneWithoutCustomObjectInput = {
  connect?: InputMaybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrganizationCreateOrConnectWithoutCustomObjectInput>;
  create?: InputMaybe<OrganizationCreateWithoutCustomObjectInput>;
};

export type OrganizationCreateNestedOneWithoutCustomObjectRecordInput = {
  connect?: InputMaybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrganizationCreateOrConnectWithoutCustomObjectRecordInput>;
  create?: InputMaybe<OrganizationCreateWithoutCustomObjectRecordInput>;
};

export type OrganizationCreateNestedOneWithoutCustomObjectRowInput = {
  connect?: InputMaybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrganizationCreateOrConnectWithoutCustomObjectRowInput>;
  create?: InputMaybe<OrganizationCreateWithoutCustomObjectRowInput>;
};

export type OrganizationCreateNestedOneWithoutCustomQueueItemsInput = {
  connect?: InputMaybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrganizationCreateOrConnectWithoutCustomQueueItemsInput>;
  create?: InputMaybe<OrganizationCreateWithoutCustomQueueItemsInput>;
};

export type OrganizationCreateNestedOneWithoutDashboardColumnOptionsInput = {
  connect?: InputMaybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrganizationCreateOrConnectWithoutDashboardColumnOptionsInput>;
  create?: InputMaybe<OrganizationCreateWithoutDashboardColumnOptionsInput>;
};

export type OrganizationCreateNestedOneWithoutDashboardLeadFiltersInput = {
  connect?: InputMaybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrganizationCreateOrConnectWithoutDashboardLeadFiltersInput>;
  create?: InputMaybe<OrganizationCreateWithoutDashboardLeadFiltersInput>;
};

export type OrganizationCreateNestedOneWithoutDashboardRepFiltersInput = {
  connect?: InputMaybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrganizationCreateOrConnectWithoutDashboardRepFiltersInput>;
  create?: InputMaybe<OrganizationCreateWithoutDashboardRepFiltersInput>;
};

export type OrganizationCreateNestedOneWithoutDashboardSavedViewInput = {
  connect?: InputMaybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrganizationCreateOrConnectWithoutDashboardSavedViewInput>;
  create?: InputMaybe<OrganizationCreateWithoutDashboardSavedViewInput>;
};

export type OrganizationCreateNestedOneWithoutDispositionMultiplierInput = {
  connect?: InputMaybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrganizationCreateOrConnectWithoutDispositionMultiplierInput>;
  create?: InputMaybe<OrganizationCreateWithoutDispositionMultiplierInput>;
};

export type OrganizationCreateNestedOneWithoutDispositionsInput = {
  connect?: InputMaybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrganizationCreateOrConnectWithoutDispositionsInput>;
  create?: InputMaybe<OrganizationCreateWithoutDispositionsInput>;
};

export type OrganizationCreateNestedOneWithoutEmailItemsInput = {
  connect?: InputMaybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrganizationCreateOrConnectWithoutEmailItemsInput>;
  create?: InputMaybe<OrganizationCreateWithoutEmailItemsInput>;
};

export type OrganizationCreateNestedOneWithoutEmailSyncRecordInput = {
  connect?: InputMaybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrganizationCreateOrConnectWithoutEmailSyncRecordInput>;
  create?: InputMaybe<OrganizationCreateWithoutEmailSyncRecordInput>;
};

export type OrganizationCreateNestedOneWithoutEmailThreadsInput = {
  connect?: InputMaybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrganizationCreateOrConnectWithoutEmailThreadsInput>;
  create?: InputMaybe<OrganizationCreateWithoutEmailThreadsInput>;
};

export type OrganizationCreateNestedOneWithoutFolderPermissionsInput = {
  connect?: InputMaybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrganizationCreateOrConnectWithoutFolderPermissionsInput>;
  create?: InputMaybe<OrganizationCreateWithoutFolderPermissionsInput>;
};

export type OrganizationCreateNestedOneWithoutFoldersInput = {
  connect?: InputMaybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrganizationCreateOrConnectWithoutFoldersInput>;
  create?: InputMaybe<OrganizationCreateWithoutFoldersInput>;
};

export type OrganizationCreateNestedOneWithoutGlobalTransferRuleInput = {
  connect?: InputMaybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrganizationCreateOrConnectWithoutGlobalTransferRuleInput>;
  create?: InputMaybe<OrganizationCreateWithoutGlobalTransferRuleInput>;
};

export type OrganizationCreateNestedOneWithoutHingePointMultiplierInput = {
  connect?: InputMaybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrganizationCreateOrConnectWithoutHingePointMultiplierInput>;
  create?: InputMaybe<OrganizationCreateWithoutHingePointMultiplierInput>;
};

export type OrganizationCreateNestedOneWithoutInboundConciergeEventsInput = {
  connect?: InputMaybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrganizationCreateOrConnectWithoutInboundConciergeEventsInput>;
  create?: InputMaybe<OrganizationCreateWithoutInboundConciergeEventsInput>;
};

export type OrganizationCreateNestedOneWithoutIntegration_Error_LogsInput = {
  connect?: InputMaybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrganizationCreateOrConnectWithoutIntegration_Error_LogsInput>;
  create?: InputMaybe<OrganizationCreateWithoutIntegration_Error_LogsInput>;
};

export type OrganizationCreateNestedOneWithoutIntegrationOperationLogInput = {
  connect?: InputMaybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrganizationCreateOrConnectWithoutIntegrationOperationLogInput>;
  create?: InputMaybe<OrganizationCreateWithoutIntegrationOperationLogInput>;
};

export type OrganizationCreateNestedOneWithoutLead_ActivitiesInput = {
  connect?: InputMaybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrganizationCreateOrConnectWithoutLead_ActivitiesInput>;
  create?: InputMaybe<OrganizationCreateWithoutLead_ActivitiesInput>;
};

export type OrganizationCreateNestedOneWithoutLead_Import_HistoryInput = {
  connect?: InputMaybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrganizationCreateOrConnectWithoutLead_Import_HistoryInput>;
  create?: InputMaybe<OrganizationCreateWithoutLead_Import_HistoryInput>;
};

export type OrganizationCreateNestedOneWithoutLead_IntentInput = {
  connect?: InputMaybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrganizationCreateOrConnectWithoutLead_IntentInput>;
  create?: InputMaybe<OrganizationCreateWithoutLead_IntentInput>;
};

export type OrganizationCreateNestedOneWithoutLead_Routing_RulesInput = {
  connect?: InputMaybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrganizationCreateOrConnectWithoutLead_Routing_RulesInput>;
  create?: InputMaybe<OrganizationCreateWithoutLead_Routing_RulesInput>;
};

export type OrganizationCreateNestedOneWithoutLeadCustomObjectRowAssociationInput = {
  connect?: InputMaybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrganizationCreateOrConnectWithoutLeadCustomObjectRowAssociationInput>;
  create?: InputMaybe<OrganizationCreateWithoutLeadCustomObjectRowAssociationInput>;
};

export type OrganizationCreateNestedOneWithoutLeadFieldMultiplierCustomInput = {
  connect?: InputMaybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrganizationCreateOrConnectWithoutLeadFieldMultiplierCustomInput>;
  create?: InputMaybe<OrganizationCreateWithoutLeadFieldMultiplierCustomInput>;
};

export type OrganizationCreateNestedOneWithoutLeadFieldMultiplierInput = {
  connect?: InputMaybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrganizationCreateOrConnectWithoutLeadFieldMultiplierInput>;
  create?: InputMaybe<OrganizationCreateWithoutLeadFieldMultiplierInput>;
};

export type OrganizationCreateNestedOneWithoutLeadsInput = {
  connect?: InputMaybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrganizationCreateOrConnectWithoutLeadsInput>;
  create?: InputMaybe<OrganizationCreateWithoutLeadsInput>;
};

export type OrganizationCreateNestedOneWithoutLeadValueInput = {
  connect?: InputMaybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrganizationCreateOrConnectWithoutLeadValueInput>;
  create?: InputMaybe<OrganizationCreateWithoutLeadValueInput>;
};

export type OrganizationCreateNestedOneWithoutLiveUserStatusInput = {
  connect?: InputMaybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrganizationCreateOrConnectWithoutLiveUserStatusInput>;
  create?: InputMaybe<OrganizationCreateWithoutLiveUserStatusInput>;
};

export type OrganizationCreateNestedOneWithoutNoteItemsInput = {
  connect?: InputMaybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrganizationCreateOrConnectWithoutNoteItemsInput>;
  create?: InputMaybe<OrganizationCreateWithoutNoteItemsInput>;
};

export type OrganizationCreateNestedOneWithoutNotificationsInput = {
  connect?: InputMaybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrganizationCreateOrConnectWithoutNotificationsInput>;
  create?: InputMaybe<OrganizationCreateWithoutNotificationsInput>;
};

export type OrganizationCreateNestedOneWithoutOrganizationPaymentSettingInput = {
  connect?: InputMaybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrganizationCreateOrConnectWithoutOrganizationPaymentSettingInput>;
  create?: InputMaybe<OrganizationCreateWithoutOrganizationPaymentSettingInput>;
};

export type OrganizationCreateNestedOneWithoutProductChangeLogInput = {
  connect?: InputMaybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrganizationCreateOrConnectWithoutProductChangeLogInput>;
  create?: InputMaybe<OrganizationCreateWithoutProductChangeLogInput>;
};

export type OrganizationCreateNestedOneWithoutProductsInput = {
  connect?: InputMaybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrganizationCreateOrConnectWithoutProductsInput>;
  create?: InputMaybe<OrganizationCreateWithoutProductsInput>;
};

export type OrganizationCreateNestedOneWithoutRoutingsInput = {
  connect?: InputMaybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrganizationCreateOrConnectWithoutRoutingsInput>;
  create?: InputMaybe<OrganizationCreateWithoutRoutingsInput>;
};

export type OrganizationCreateNestedOneWithoutSaleConfigPageInput = {
  connect?: InputMaybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrganizationCreateOrConnectWithoutSaleConfigPageInput>;
  create?: InputMaybe<OrganizationCreateWithoutSaleConfigPageInput>;
};

export type OrganizationCreateNestedOneWithoutSaleConfigSectionInput = {
  connect?: InputMaybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrganizationCreateOrConnectWithoutSaleConfigSectionInput>;
  create?: InputMaybe<OrganizationCreateWithoutSaleConfigSectionInput>;
};

export type OrganizationCreateNestedOneWithoutSaleCyclesInput = {
  connect?: InputMaybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrganizationCreateOrConnectWithoutSaleCyclesInput>;
  create?: InputMaybe<OrganizationCreateWithoutSaleCyclesInput>;
};

export type OrganizationCreateNestedOneWithoutSaleItemsInput = {
  connect?: InputMaybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrganizationCreateOrConnectWithoutSaleItemsInput>;
  create?: InputMaybe<OrganizationCreateWithoutSaleItemsInput>;
};

export type OrganizationCreateNestedOneWithoutSalesInput = {
  connect?: InputMaybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrganizationCreateOrConnectWithoutSalesInput>;
  create?: InputMaybe<OrganizationCreateWithoutSalesInput>;
};

export type OrganizationCreateNestedOneWithoutScheduleItemsInput = {
  connect?: InputMaybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrganizationCreateOrConnectWithoutScheduleItemsInput>;
  create?: InputMaybe<OrganizationCreateWithoutScheduleItemsInput>;
};

export type OrganizationCreateNestedOneWithoutSequenceCriteriaConditionsInput = {
  connect?: InputMaybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrganizationCreateOrConnectWithoutSequenceCriteriaConditionsInput>;
  create?: InputMaybe<OrganizationCreateWithoutSequenceCriteriaConditionsInput>;
};

export type OrganizationCreateNestedOneWithoutSequenceDashboardViewsInput = {
  connect?: InputMaybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrganizationCreateOrConnectWithoutSequenceDashboardViewsInput>;
  create?: InputMaybe<OrganizationCreateWithoutSequenceDashboardViewsInput>;
};

export type OrganizationCreateNestedOneWithoutSequenceEntryCriteriasInput = {
  connect?: InputMaybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrganizationCreateOrConnectWithoutSequenceEntryCriteriasInput>;
  create?: InputMaybe<OrganizationCreateWithoutSequenceEntryCriteriasInput>;
};

export type OrganizationCreateNestedOneWithoutSequenceEntryExitLeadInput = {
  connect?: InputMaybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrganizationCreateOrConnectWithoutSequenceEntryExitLeadInput>;
  create?: InputMaybe<OrganizationCreateWithoutSequenceEntryExitLeadInput>;
};

export type OrganizationCreateNestedOneWithoutSequenceInput = {
  connect?: InputMaybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrganizationCreateOrConnectWithoutSequenceInput>;
  create?: InputMaybe<OrganizationCreateWithoutSequenceInput>;
};

export type OrganizationCreateNestedOneWithoutSequenceStepsInput = {
  connect?: InputMaybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrganizationCreateOrConnectWithoutSequenceStepsInput>;
  create?: InputMaybe<OrganizationCreateWithoutSequenceStepsInput>;
};

export type OrganizationCreateNestedOneWithoutSitesInput = {
  connect?: InputMaybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrganizationCreateOrConnectWithoutSitesInput>;
  create?: InputMaybe<OrganizationCreateWithoutSitesInput>;
};

export type OrganizationCreateNestedOneWithoutSmsItemsInput = {
  connect?: InputMaybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrganizationCreateOrConnectWithoutSmsItemsInput>;
  create?: InputMaybe<OrganizationCreateWithoutSmsItemsInput>;
};

export type OrganizationCreateNestedOneWithoutSnoozeTrackingsInput = {
  connect?: InputMaybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrganizationCreateOrConnectWithoutSnoozeTrackingsInput>;
  create?: InputMaybe<OrganizationCreateWithoutSnoozeTrackingsInput>;
};

export type OrganizationCreateNestedOneWithoutStepActionLogsInput = {
  connect?: InputMaybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrganizationCreateOrConnectWithoutStepActionLogsInput>;
  create?: InputMaybe<OrganizationCreateWithoutStepActionLogsInput>;
};

export type OrganizationCreateNestedOneWithoutStepActionsInput = {
  connect?: InputMaybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrganizationCreateOrConnectWithoutStepActionsInput>;
  create?: InputMaybe<OrganizationCreateWithoutStepActionsInput>;
};

export type OrganizationCreateNestedOneWithoutStepConditionsInput = {
  connect?: InputMaybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrganizationCreateOrConnectWithoutStepConditionsInput>;
  create?: InputMaybe<OrganizationCreateWithoutStepConditionsInput>;
};

export type OrganizationCreateNestedOneWithoutTeamsInput = {
  connect?: InputMaybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrganizationCreateOrConnectWithoutTeamsInput>;
  create?: InputMaybe<OrganizationCreateWithoutTeamsInput>;
};

export type OrganizationCreateNestedOneWithoutTemplateInput = {
  connect?: InputMaybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrganizationCreateOrConnectWithoutTemplateInput>;
  create?: InputMaybe<OrganizationCreateWithoutTemplateInput>;
};

export type OrganizationCreateNestedOneWithoutTemplateVersionsInput = {
  connect?: InputMaybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrganizationCreateOrConnectWithoutTemplateVersionsInput>;
  create?: InputMaybe<OrganizationCreateWithoutTemplateVersionsInput>;
};

export type OrganizationCreateNestedOneWithoutTransferAttemptsInput = {
  connect?: InputMaybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrganizationCreateOrConnectWithoutTransferAttemptsInput>;
  create?: InputMaybe<OrganizationCreateWithoutTransferAttemptsInput>;
};

export type OrganizationCreateNestedOneWithoutTwilio_NumbersInput = {
  connect?: InputMaybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrganizationCreateOrConnectWithoutTwilio_NumbersInput>;
  create?: InputMaybe<OrganizationCreateWithoutTwilio_NumbersInput>;
};

export type OrganizationCreateNestedOneWithoutTwilioFeedbacksInput = {
  connect?: InputMaybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrganizationCreateOrConnectWithoutTwilioFeedbacksInput>;
  create?: InputMaybe<OrganizationCreateWithoutTwilioFeedbacksInput>;
};

export type OrganizationCreateNestedOneWithoutTwilioInfoInput = {
  connect?: InputMaybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrganizationCreateOrConnectWithoutTwilioInfoInput>;
  create?: InputMaybe<OrganizationCreateWithoutTwilioInfoInput>;
};

export type OrganizationCreateNestedOneWithoutUserCustomFieldFiltersInput = {
  connect?: InputMaybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrganizationCreateOrConnectWithoutUserCustomFieldFiltersInput>;
  create?: InputMaybe<OrganizationCreateWithoutUserCustomFieldFiltersInput>;
};

export type OrganizationCreateNestedOneWithoutUsersInput = {
  connect?: InputMaybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrganizationCreateOrConnectWithoutUsersInput>;
  create?: InputMaybe<OrganizationCreateWithoutUsersInput>;
};

export type OrganizationCreateOrConnectWithoutAssociationHistoriesInput = {
  create: OrganizationCreateWithoutAssociationHistoriesInput;
  where: OrganizationWhereUniqueInput;
};

export type OrganizationCreateOrConnectWithoutAutomated_CampaignsInput = {
  create: OrganizationCreateWithoutAutomated_CampaignsInput;
  where: OrganizationWhereUniqueInput;
};

export type OrganizationCreateOrConnectWithoutAutoTransferSelectionsInput = {
  create: OrganizationCreateWithoutAutoTransferSelectionsInput;
  where: OrganizationWhereUniqueInput;
};

export type OrganizationCreateOrConnectWithoutAvailablitiesInput = {
  create: OrganizationCreateWithoutAvailablitiesInput;
  where: OrganizationWhereUniqueInput;
};

export type OrganizationCreateOrConnectWithoutBest_MetricsInput = {
  create: OrganizationCreateWithoutBest_MetricsInput;
  where: OrganizationWhereUniqueInput;
};

export type OrganizationCreateOrConnectWithoutCallMeNowAttemptsInput = {
  create: OrganizationCreateWithoutCallMeNowAttemptsInput;
  where: OrganizationWhereUniqueInput;
};

export type OrganizationCreateOrConnectWithoutCallMeNowsInput = {
  create: OrganizationCreateWithoutCallMeNowsInput;
  where: OrganizationWhereUniqueInput;
};

export type OrganizationCreateOrConnectWithoutCallSharingsInput = {
  create: OrganizationCreateWithoutCallSharingsInput;
  where: OrganizationWhereUniqueInput;
};

export type OrganizationCreateOrConnectWithoutConcessionInput = {
  create: OrganizationCreateWithoutConcessionInput;
  where: OrganizationWhereUniqueInput;
};

export type OrganizationCreateOrConnectWithoutConferencesInput = {
  create: OrganizationCreateWithoutConferencesInput;
  where: OrganizationWhereUniqueInput;
};

export type OrganizationCreateOrConnectWithoutContactRequestsInput = {
  create: OrganizationCreateWithoutContactRequestsInput;
  where: OrganizationWhereUniqueInput;
};

export type OrganizationCreateOrConnectWithoutContactsInput = {
  create: OrganizationCreateWithoutContactsInput;
  where: OrganizationWhereUniqueInput;
};

export type OrganizationCreateOrConnectWithoutCustom_FieldsInput = {
  create: OrganizationCreateWithoutCustom_FieldsInput;
  where: OrganizationWhereUniqueInput;
};

export type OrganizationCreateOrConnectWithoutCustomObjectFieldForeignKeyInput = {
  create: OrganizationCreateWithoutCustomObjectFieldForeignKeyInput;
  where: OrganizationWhereUniqueInput;
};

export type OrganizationCreateOrConnectWithoutCustomObjectFieldInput = {
  create: OrganizationCreateWithoutCustomObjectFieldInput;
  where: OrganizationWhereUniqueInput;
};

export type OrganizationCreateOrConnectWithoutCustomObjectInput = {
  create: OrganizationCreateWithoutCustomObjectInput;
  where: OrganizationWhereUniqueInput;
};

export type OrganizationCreateOrConnectWithoutCustomObjectRecordInput = {
  create: OrganizationCreateWithoutCustomObjectRecordInput;
  where: OrganizationWhereUniqueInput;
};

export type OrganizationCreateOrConnectWithoutCustomObjectRowInput = {
  create: OrganizationCreateWithoutCustomObjectRowInput;
  where: OrganizationWhereUniqueInput;
};

export type OrganizationCreateOrConnectWithoutCustomQueueItemsInput = {
  create: OrganizationCreateWithoutCustomQueueItemsInput;
  where: OrganizationWhereUniqueInput;
};

export type OrganizationCreateOrConnectWithoutDashboardColumnOptionsInput = {
  create: OrganizationCreateWithoutDashboardColumnOptionsInput;
  where: OrganizationWhereUniqueInput;
};

export type OrganizationCreateOrConnectWithoutDashboardLeadFiltersInput = {
  create: OrganizationCreateWithoutDashboardLeadFiltersInput;
  where: OrganizationWhereUniqueInput;
};

export type OrganizationCreateOrConnectWithoutDashboardRepFiltersInput = {
  create: OrganizationCreateWithoutDashboardRepFiltersInput;
  where: OrganizationWhereUniqueInput;
};

export type OrganizationCreateOrConnectWithoutDashboardSavedViewInput = {
  create: OrganizationCreateWithoutDashboardSavedViewInput;
  where: OrganizationWhereUniqueInput;
};

export type OrganizationCreateOrConnectWithoutDispositionMultiplierInput = {
  create: OrganizationCreateWithoutDispositionMultiplierInput;
  where: OrganizationWhereUniqueInput;
};

export type OrganizationCreateOrConnectWithoutDispositionsInput = {
  create: OrganizationCreateWithoutDispositionsInput;
  where: OrganizationWhereUniqueInput;
};

export type OrganizationCreateOrConnectWithoutEmailItemsInput = {
  create: OrganizationCreateWithoutEmailItemsInput;
  where: OrganizationWhereUniqueInput;
};

export type OrganizationCreateOrConnectWithoutEmailSyncRecordInput = {
  create: OrganizationCreateWithoutEmailSyncRecordInput;
  where: OrganizationWhereUniqueInput;
};

export type OrganizationCreateOrConnectWithoutEmailThreadsInput = {
  create: OrganizationCreateWithoutEmailThreadsInput;
  where: OrganizationWhereUniqueInput;
};

export type OrganizationCreateOrConnectWithoutFolderPermissionsInput = {
  create: OrganizationCreateWithoutFolderPermissionsInput;
  where: OrganizationWhereUniqueInput;
};

export type OrganizationCreateOrConnectWithoutFoldersInput = {
  create: OrganizationCreateWithoutFoldersInput;
  where: OrganizationWhereUniqueInput;
};

export type OrganizationCreateOrConnectWithoutGlobalTransferRuleInput = {
  create: OrganizationCreateWithoutGlobalTransferRuleInput;
  where: OrganizationWhereUniqueInput;
};

export type OrganizationCreateOrConnectWithoutHingePointMultiplierInput = {
  create: OrganizationCreateWithoutHingePointMultiplierInput;
  where: OrganizationWhereUniqueInput;
};

export type OrganizationCreateOrConnectWithoutInboundConciergeEventsInput = {
  create: OrganizationCreateWithoutInboundConciergeEventsInput;
  where: OrganizationWhereUniqueInput;
};

export type OrganizationCreateOrConnectWithoutIntegration_Error_LogsInput = {
  create: OrganizationCreateWithoutIntegration_Error_LogsInput;
  where: OrganizationWhereUniqueInput;
};

export type OrganizationCreateOrConnectWithoutIntegrationOperationLogInput = {
  create: OrganizationCreateWithoutIntegrationOperationLogInput;
  where: OrganizationWhereUniqueInput;
};

export type OrganizationCreateOrConnectWithoutLead_ActivitiesInput = {
  create: OrganizationCreateWithoutLead_ActivitiesInput;
  where: OrganizationWhereUniqueInput;
};

export type OrganizationCreateOrConnectWithoutLead_Import_HistoryInput = {
  create: OrganizationCreateWithoutLead_Import_HistoryInput;
  where: OrganizationWhereUniqueInput;
};

export type OrganizationCreateOrConnectWithoutLead_IntentInput = {
  create: OrganizationCreateWithoutLead_IntentInput;
  where: OrganizationWhereUniqueInput;
};

export type OrganizationCreateOrConnectWithoutLead_Routing_RulesInput = {
  create: OrganizationCreateWithoutLead_Routing_RulesInput;
  where: OrganizationWhereUniqueInput;
};

export type OrganizationCreateOrConnectWithoutLeadCustomObjectRowAssociationInput = {
  create: OrganizationCreateWithoutLeadCustomObjectRowAssociationInput;
  where: OrganizationWhereUniqueInput;
};

export type OrganizationCreateOrConnectWithoutLeadFieldMultiplierCustomInput = {
  create: OrganizationCreateWithoutLeadFieldMultiplierCustomInput;
  where: OrganizationWhereUniqueInput;
};

export type OrganizationCreateOrConnectWithoutLeadFieldMultiplierInput = {
  create: OrganizationCreateWithoutLeadFieldMultiplierInput;
  where: OrganizationWhereUniqueInput;
};

export type OrganizationCreateOrConnectWithoutLeadsInput = {
  create: OrganizationCreateWithoutLeadsInput;
  where: OrganizationWhereUniqueInput;
};

export type OrganizationCreateOrConnectWithoutLeadValueInput = {
  create: OrganizationCreateWithoutLeadValueInput;
  where: OrganizationWhereUniqueInput;
};

export type OrganizationCreateOrConnectWithoutLiveUserStatusInput = {
  create: OrganizationCreateWithoutLiveUserStatusInput;
  where: OrganizationWhereUniqueInput;
};

export type OrganizationCreateOrConnectWithoutNoteItemsInput = {
  create: OrganizationCreateWithoutNoteItemsInput;
  where: OrganizationWhereUniqueInput;
};

export type OrganizationCreateOrConnectWithoutNotificationsInput = {
  create: OrganizationCreateWithoutNotificationsInput;
  where: OrganizationWhereUniqueInput;
};

export type OrganizationCreateOrConnectWithoutOrganizationPaymentSettingInput = {
  create: OrganizationCreateWithoutOrganizationPaymentSettingInput;
  where: OrganizationWhereUniqueInput;
};

export type OrganizationCreateOrConnectWithoutOrganizationPaymentSettingsInput = {
  create: OrganizationCreateWithoutOrganizationPaymentSettingsInput;
  where: OrganizationWhereUniqueInput;
};

export type OrganizationCreateOrConnectWithoutProductChangeLogInput = {
  create: OrganizationCreateWithoutProductChangeLogInput;
  where: OrganizationWhereUniqueInput;
};

export type OrganizationCreateOrConnectWithoutProductsInput = {
  create: OrganizationCreateWithoutProductsInput;
  where: OrganizationWhereUniqueInput;
};

export type OrganizationCreateOrConnectWithoutRoutingsInput = {
  create: OrganizationCreateWithoutRoutingsInput;
  where: OrganizationWhereUniqueInput;
};

export type OrganizationCreateOrConnectWithoutSaleConfigInput = {
  create: OrganizationCreateWithoutSaleConfigInput;
  where: OrganizationWhereUniqueInput;
};

export type OrganizationCreateOrConnectWithoutSaleConfigPageInput = {
  create: OrganizationCreateWithoutSaleConfigPageInput;
  where: OrganizationWhereUniqueInput;
};

export type OrganizationCreateOrConnectWithoutSaleConfigSectionInput = {
  create: OrganizationCreateWithoutSaleConfigSectionInput;
  where: OrganizationWhereUniqueInput;
};

export type OrganizationCreateOrConnectWithoutSaleCyclesInput = {
  create: OrganizationCreateWithoutSaleCyclesInput;
  where: OrganizationWhereUniqueInput;
};

export type OrganizationCreateOrConnectWithoutSaleItemsInput = {
  create: OrganizationCreateWithoutSaleItemsInput;
  where: OrganizationWhereUniqueInput;
};

export type OrganizationCreateOrConnectWithoutSalesInput = {
  create: OrganizationCreateWithoutSalesInput;
  where: OrganizationWhereUniqueInput;
};

export type OrganizationCreateOrConnectWithoutScheduleItemsInput = {
  create: OrganizationCreateWithoutScheduleItemsInput;
  where: OrganizationWhereUniqueInput;
};

export type OrganizationCreateOrConnectWithoutSequenceCriteriaConditionsInput = {
  create: OrganizationCreateWithoutSequenceCriteriaConditionsInput;
  where: OrganizationWhereUniqueInput;
};

export type OrganizationCreateOrConnectWithoutSequenceDashboardViewsInput = {
  create: OrganizationCreateWithoutSequenceDashboardViewsInput;
  where: OrganizationWhereUniqueInput;
};

export type OrganizationCreateOrConnectWithoutSequenceEntryCriteriasInput = {
  create: OrganizationCreateWithoutSequenceEntryCriteriasInput;
  where: OrganizationWhereUniqueInput;
};

export type OrganizationCreateOrConnectWithoutSequenceEntryExitLeadInput = {
  create: OrganizationCreateWithoutSequenceEntryExitLeadInput;
  where: OrganizationWhereUniqueInput;
};

export type OrganizationCreateOrConnectWithoutSequenceInput = {
  create: OrganizationCreateWithoutSequenceInput;
  where: OrganizationWhereUniqueInput;
};

export type OrganizationCreateOrConnectWithoutSequenceStepsInput = {
  create: OrganizationCreateWithoutSequenceStepsInput;
  where: OrganizationWhereUniqueInput;
};

export type OrganizationCreateOrConnectWithoutSitesInput = {
  create: OrganizationCreateWithoutSitesInput;
  where: OrganizationWhereUniqueInput;
};

export type OrganizationCreateOrConnectWithoutSmsItemsInput = {
  create: OrganizationCreateWithoutSmsItemsInput;
  where: OrganizationWhereUniqueInput;
};

export type OrganizationCreateOrConnectWithoutSnoozeTrackingsInput = {
  create: OrganizationCreateWithoutSnoozeTrackingsInput;
  where: OrganizationWhereUniqueInput;
};

export type OrganizationCreateOrConnectWithoutStepActionLogsInput = {
  create: OrganizationCreateWithoutStepActionLogsInput;
  where: OrganizationWhereUniqueInput;
};

export type OrganizationCreateOrConnectWithoutStepActionsInput = {
  create: OrganizationCreateWithoutStepActionsInput;
  where: OrganizationWhereUniqueInput;
};

export type OrganizationCreateOrConnectWithoutStepConditionsInput = {
  create: OrganizationCreateWithoutStepConditionsInput;
  where: OrganizationWhereUniqueInput;
};

export type OrganizationCreateOrConnectWithoutTeamsInput = {
  create: OrganizationCreateWithoutTeamsInput;
  where: OrganizationWhereUniqueInput;
};

export type OrganizationCreateOrConnectWithoutTemplateInput = {
  create: OrganizationCreateWithoutTemplateInput;
  where: OrganizationWhereUniqueInput;
};

export type OrganizationCreateOrConnectWithoutTemplateVersionsInput = {
  create: OrganizationCreateWithoutTemplateVersionsInput;
  where: OrganizationWhereUniqueInput;
};

export type OrganizationCreateOrConnectWithoutTransferAttemptsInput = {
  create: OrganizationCreateWithoutTransferAttemptsInput;
  where: OrganizationWhereUniqueInput;
};

export type OrganizationCreateOrConnectWithoutTwilio_NumbersInput = {
  create: OrganizationCreateWithoutTwilio_NumbersInput;
  where: OrganizationWhereUniqueInput;
};

export type OrganizationCreateOrConnectWithoutTwilioFeedbacksInput = {
  create: OrganizationCreateWithoutTwilioFeedbacksInput;
  where: OrganizationWhereUniqueInput;
};

export type OrganizationCreateOrConnectWithoutTwilioInfoInput = {
  create: OrganizationCreateWithoutTwilioInfoInput;
  where: OrganizationWhereUniqueInput;
};

export type OrganizationCreateOrConnectWithoutUserCustomFieldFiltersInput = {
  create: OrganizationCreateWithoutUserCustomFieldFiltersInput;
  where: OrganizationWhereUniqueInput;
};

export type OrganizationCreateOrConnectWithoutUsersInput = {
  create: OrganizationCreateWithoutUsersInput;
  where: OrganizationWhereUniqueInput;
};

export type OrganizationCreatesale_Notification_Email_ListInput = {
  set?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type OrganizationCreateWithoutAssociationHistoriesInput = {
  ActivityMonitorMultiplier?: InputMaybe<ActivityMonitorMultiplierCreateNestedManyWithoutOrganizationInput>;
  algoFactor?: InputMaybe<AlgoFactorCreateNestedManyWithoutOrganizationInput>;
  allow_calendar_invite?: InputMaybe<Scalars['Boolean']['input']>;
  allow_remove_step_action?: InputMaybe<Scalars['Boolean']['input']>;
  allow_snooze_step_action?: InputMaybe<Scalars['Boolean']['input']>;
  always_record?: InputMaybe<Scalars['Boolean']['input']>;
  arv_initial_held_demos_generated_by_sdr_weight?: InputMaybe<Scalars['Float']['input']>;
  auto_call_interval?: InputMaybe<Scalars['Int']['input']>;
  auto_dial?: InputMaybe<Scalars['Boolean']['input']>;
  automated_campaigns?: InputMaybe<AutomatedCampaignCreateNestedManyWithoutOrganizationInput>;
  automatic_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  AutoTransferSelections?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutOrganizationInput>;
  Availablities?: InputMaybe<AvailabilityCreateNestedManyWithoutOrganizationInput>;
  AverageOrderValue?: InputMaybe<AverageOrderValueCreateNestedManyWithoutOrganizationInput>;
  best_action_ever_multiplier?: InputMaybe<Scalars['Float']['input']>;
  best_metrics?: InputMaybe<BestMetricRecordCreateNestedManyWithoutOrganizationInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptCreateNestedManyWithoutOrganizationInput>;
  callMeNows?: InputMaybe<CallMeNowCreateNestedManyWithoutOrganizationInput>;
  CallMultiplier?: InputMaybe<CallMultiplierCreateNestedManyWithoutOrganizationInput>;
  CallSharings?: InputMaybe<CallSharingCreateNestedManyWithoutOrganizationInput>;
  can_edit_sdr_transfer_rules?: InputMaybe<Scalars['Boolean']['input']>;
  claim_rule?: InputMaybe<Scalars['Int']['input']>;
  concession?: InputMaybe<ConcessionCreateNestedManyWithoutOrganizationInput>;
  conference_region?: InputMaybe<ConferenceRegion>;
  Conferences?: InputMaybe<ConferenceCreateNestedManyWithoutOrganizationInput>;
  ContactRequests?: InputMaybe<ContactRequestCreateNestedManyWithoutOrganizationInput>;
  Contacts?: InputMaybe<ContactCreateNestedManyWithoutOrganizationInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  custom_fields?: InputMaybe<CustomFieldCreateNestedManyWithoutOrganizationInput>;
  CustomObject?: InputMaybe<CustomObjectCreateNestedManyWithoutOrganizationInput>;
  CustomObjectField?: InputMaybe<CustomObjectFieldCreateNestedManyWithoutOrganizationInput>;
  CustomObjectFieldForeignKey?: InputMaybe<CustomObjectFieldForeignKeyCreateNestedManyWithoutOrganizationInput>;
  CustomObjectRecord?: InputMaybe<CustomObjectRecordCreateNestedManyWithoutOrganizationInput>;
  CustomObjectRow?: InputMaybe<CustomObjectRowCreateNestedManyWithoutOrganizationInput>;
  CustomQueueItems?: InputMaybe<CustomQueueItemCreateNestedManyWithoutOrganizationInput>;
  DashboardColumnOptions?: InputMaybe<DashboardColumnOptionCreateNestedManyWithoutOrganizationInput>;
  DashboardLeadFilters?: InputMaybe<DashboardLeadFilterCreateNestedManyWithoutOrganizationInput>;
  DashboardRepFilters?: InputMaybe<DashboardRepFilterCreateNestedManyWithoutOrganizationInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewCreateNestedManyWithoutOrganizationInput>;
  day_to_close?: InputMaybe<Scalars['Int']['input']>;
  default_demo_time_duration?: InputMaybe<Scalars['Int']['input']>;
  demo_held_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_not_held_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_not_held_call_back_short_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_prep_time_minutes?: InputMaybe<Scalars['Int']['input']>;
  dev_account?: InputMaybe<Scalars['Boolean']['input']>;
  dial_rule?: InputMaybe<Scalars['Int']['input']>;
  DispositionMultiplier?: InputMaybe<DispositionMultiplierCreateNestedManyWithoutOrganizationInput>;
  dispositions?: InputMaybe<DispositionCreateNestedManyWithoutOrganizationInput>;
  double_booking?: InputMaybe<Scalars['Boolean']['input']>;
  dynamic_date_operators?: InputMaybe<Scalars['Boolean']['input']>;
  edge_servers?: InputMaybe<OrganizationCreateedge_ServersInput>;
  email_conflict_notification_email_list?: InputMaybe<OrganizationCreateemail_Conflict_Notification_Email_ListInput>;
  EmailItems?: InputMaybe<EmailItemCreateNestedManyWithoutOrganizationInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordCreateNestedManyWithoutOrganizationInput>;
  EmailThreads?: InputMaybe<EmailThreadCreateNestedManyWithoutOrganizationInput>;
  enable_modified_metrics?: InputMaybe<Scalars['Boolean']['input']>;
  enable_pay_period_view?: InputMaybe<Scalars['Boolean']['input']>;
  enable_time_constraint?: InputMaybe<Scalars['Boolean']['input']>;
  error_log_email_address?: InputMaybe<OrganizationCreateerror_Log_Email_AddressInput>;
  estimated_average_num_of_weekly_overbooked_demo_slots?: InputMaybe<Scalars['Int']['input']>;
  FolderPermissions?: InputMaybe<FolderPermissionCreateNestedManyWithoutOrganizationInput>;
  Folders?: InputMaybe<FolderCreateNestedManyWithoutOrganizationInput>;
  FutureBookingSetting?: InputMaybe<FutureBookingSettingCreateNestedOneWithoutOrganizationInput>;
  GlobalTransferRule?: InputMaybe<GlobalTransferRuleCreateNestedManyWithoutOrganizationInput>;
  held_demos_per_cc_connect?: InputMaybe<Scalars['Float']['input']>;
  helpful_links?: InputMaybe<OrganizationCreatehelpful_LinksInput>;
  HingePointMultiplier?: InputMaybe<HingePointMultiplierCreateNestedManyWithoutOrganizationInput>;
  hold_goal?: InputMaybe<Scalars['Float']['input']>;
  hubspot_credential?: InputMaybe<HubSpotCredentialCreateNestedOneWithoutOrganizationInput>;
  hubspot_last_sync_date?: InputMaybe<Scalars['DateTime']['input']>;
  hubspot_white_list?: InputMaybe<HubSpotWhitelistCreateNestedManyWithoutOrganizationInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  inbound_close_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_dm_connect_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_lt_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_revenue_value_of_sale?: InputMaybe<Scalars['Float']['input']>;
  inbound_scheduled_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_set_rate?: InputMaybe<Scalars['Float']['input']>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventCreateNestedManyWithoutOrganizationInput>;
  InboundConciergeWidget?: InputMaybe<InboundConciergeWidgetCreateNestedManyWithoutOrganizationInput>;
  industry_options?: InputMaybe<IndustryOptionCreateNestedManyWithoutOrganizationInput>;
  initial_sdr_sets_reps_day?: InputMaybe<Scalars['Float']['input']>;
  integration_error_logs?: InputMaybe<IntegrationErrorLogCreateNestedManyWithoutOrganizationInput>;
  integration_mappings?: InputMaybe<IntegrationMappingCreateNestedManyWithoutOrganizationInput>;
  IntegrationMappingCustomObject?: InputMaybe<IntegrationMappingCustomObjectCreateNestedManyWithoutOrganizationInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogCreateNestedManyWithoutOrganizationInput>;
  IntegrationReadOnlyObject?: InputMaybe<IntegrationReadOnlyObjectCreateNestedManyWithoutOrganizationInput>;
  international?: InputMaybe<Scalars['Boolean']['input']>;
  is_email_domain_verified?: InputMaybe<Scalars['Boolean']['input']>;
  is_salesforce_sandbox?: InputMaybe<Scalars['Boolean']['input']>;
  last_lead_value_compute_time?: InputMaybe<Scalars['DateTime']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutOrganizationInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryCreateNestedManyWithoutOrganizationInput>;
  lead_intent?: InputMaybe<LeadIntentCreateNestedManyWithoutOrganizationInput>;
  lead_release_same_rep_delay_rule?: InputMaybe<Scalars['Int']['input']>;
  lead_routing_email_list?: InputMaybe<OrganizationCreatelead_Routing_Email_ListInput>;
  lead_routing_rules?: InputMaybe<RuleCreateNestedManyWithoutOrganizationInput>;
  LeadCustomObjectRowAssociation?: InputMaybe<LeadCustomObjectRowAssociationCreateNestedManyWithoutOrganizationInput>;
  LeadFieldMultiplier?: InputMaybe<LeadFieldMultiplierCreateNestedManyWithoutOrganizationInput>;
  LeadFieldMultiplierCustom?: InputMaybe<LeadFieldMultiplierCustomCreateNestedManyWithoutOrganizationInput>;
  leads?: InputMaybe<LeadCreateNestedManyWithoutOrganizationInput>;
  leadsource_options?: InputMaybe<LeadSourceOptionCreateNestedManyWithoutOrganizationInput>;
  LeadValue?: InputMaybe<LeadValueCreateNestedManyWithoutOrganizationInput>;
  LifeTimeValueMultiplier?: InputMaybe<LifeTimeValueMultiplierCreateNestedManyWithoutOrganizationInput>;
  LiveTransferSetting?: InputMaybe<LiveTransferSettingCreateNestedOneWithoutOrganizationInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusCreateNestedManyWithoutOrganizationInput>;
  ltv_multiplier_custom_fields?: InputMaybe<LifeTimeValueMultiplierCustomCreateNestedManyWithoutOrganizationInput>;
  made_contact_rule?: InputMaybe<Scalars['Int']['input']>;
  make_sale_address_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_business_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_city_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_country_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_first_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_industry_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_last_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_lead_source_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_primary_email_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_primary_phone_number_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_state_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_sub_industry_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_zip_required?: InputMaybe<Scalars['Boolean']['input']>;
  maxium_day_lag_set_to_schedule?: InputMaybe<Scalars['Int']['input']>;
  mrr?: InputMaybe<Scalars['Int']['input']>;
  mrr_label?: InputMaybe<Scalars['String']['input']>;
  mrr_required_on_sale?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  NoteItems?: InputMaybe<NoteItemCreateNestedManyWithoutOrganizationInput>;
  Notifications?: InputMaybe<NotificationCreateNestedManyWithoutOrganizationInput>;
  onboardComplete?: InputMaybe<Scalars['Boolean']['input']>;
  Opportunity?: InputMaybe<SfOpportunityCreateNestedManyWithoutOrganizationInput>;
  organization_holidays?: InputMaybe<OrganizationHolidayCreateNestedManyWithoutOrganizationInput>;
  organization_hours?: InputMaybe<OrganizationHourCreateNestedManyWithoutOrganizationInput>;
  OrganizationPaymentSetting?: InputMaybe<OrganizationPaymentSettingsCreateNestedOneWithoutOrganizationInput>;
  OrganizationPaymentSettings?: InputMaybe<OrganizationPaymentSettingsCreateNestedOneWithoutOrganizationsInput>;
  orgCalendarInviteMetadata?: InputMaybe<OrgCalendarInviteMetadataCreateNestedManyWithoutOrganizationInput>;
  OrgDefaultPipelinePerecentValue?: InputMaybe<OrgDefaultPipelinePerecentValueCreateNestedOneWithoutOrganizationInput>;
  OrgHubSpotSync?: InputMaybe<OrgHubSpotSyncCreateNestedOneWithoutOrganizationInput>;
  OrgNoEventDate?: InputMaybe<OrgNoEventDateCreateNestedManyWithoutOrganizationInput>;
  OrgSalesforceSync?: InputMaybe<OrgSalesforceSyncCreateNestedOneWithoutOrganizationInput>;
  OrgTimeslotAvailability?: InputMaybe<OrgTimeslotAvailabilityCreateNestedManyWithoutOrganizationInput>;
  outbound_close_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_dm_connect_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_lt_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_revenue_value_of_sale?: InputMaybe<Scalars['Float']['input']>;
  outbound_scheduled_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_set_rate?: InputMaybe<Scalars['Float']['input']>;
  PandadocData?: InputMaybe<PandadocDataCreateNestedOneWithoutOrganizationInput>;
  PandaDocTemplate?: InputMaybe<PandaDocTemplateCreateNestedManyWithoutOrganizationInput>;
  participant_jitter_buffer_size?: InputMaybe<JitterBufferSize>;
  preferred_goal_setting?: InputMaybe<Goalsetting>;
  prepayment?: InputMaybe<Scalars['Boolean']['input']>;
  ProductChangeLog?: InputMaybe<ProductChangeLogCreateNestedManyWithoutOrganizationInput>;
  products?: InputMaybe<ProductCreateNestedManyWithoutOrganizationInput>;
  re_cold_call_hour_delay?: InputMaybe<Scalars['Float']['input']>;
  recordingStatus?: InputMaybe<RecordingStatus>;
  release_lead_resting?: InputMaybe<Scalars['Int']['input']>;
  rep_claim_limit?: InputMaybe<Scalars['Int']['input']>;
  rep_lead_allotment?: InputMaybe<Scalars['Int']['input']>;
  rep_level_call_transfer?: InputMaybe<Scalars['Boolean']['input']>;
  reps_can_bulk_unassign?: InputMaybe<Scalars['Boolean']['input']>;
  reps_can_view_all_notes?: InputMaybe<Scalars['Boolean']['input']>;
  require_sale_notes?: InputMaybe<Scalars['Boolean']['input']>;
  resting_after_skipped_CC?: InputMaybe<Scalars['Int']['input']>;
  resting_period?: InputMaybe<Scalars['Int']['input']>;
  resting_period_after_unsuccessful_cold_call?: InputMaybe<Scalars['Float']['input']>;
  resting_period_after_unsuccessful_demo?: InputMaybe<Scalars['Float']['input']>;
  revenue_plan?: InputMaybe<RevenuePlanItemCreateNestedManyWithoutOrganizationInput>;
  Routings?: InputMaybe<RoutingCreateNestedManyWithoutOrganizationInput>;
  sale_field_options?: InputMaybe<SaleFieldOptionCreateNestedManyWithoutOrganizationInput>;
  sale_notification_email_list?: InputMaybe<OrganizationCreatesale_Notification_Email_ListInput>;
  SaleConfig?: InputMaybe<SaleConfigCreateNestedOneWithoutOrganizationInput>;
  SaleConfigPage?: InputMaybe<SaleConfigPageCreateNestedManyWithoutOrganizationInput>;
  SaleConfigSection?: InputMaybe<SaleConfigSectionCreateNestedManyWithoutOrganizationInput>;
  SaleCycles?: InputMaybe<SaleCycleCreateNestedManyWithoutOrganizationInput>;
  SaleItems?: InputMaybe<SaleItemCreateNestedManyWithoutOrganizationInput>;
  sales?: InputMaybe<SaleCreateNestedManyWithoutOrganizationInput>;
  salesforce_domain?: InputMaybe<Scalars['String']['input']>;
  salesforce_last_sync_date?: InputMaybe<Scalars['DateTime']['input']>;
  SalesForceColumn?: InputMaybe<SalesForceColumnCreateNestedManyWithoutOrganizationInput>;
  salesForceData?: InputMaybe<SalesForceDataCreateNestedOneWithoutOrganizationInput>;
  ScheduleItems?: InputMaybe<ScheduleItemCreateNestedManyWithoutOrganizationInput>;
  self_sourcing_rule?: InputMaybe<Scalars['Int']['input']>;
  send_error_log_email?: InputMaybe<Scalars['Boolean']['input']>;
  send_lead_routing_email?: InputMaybe<Scalars['Boolean']['input']>;
  sendgrid_email_domain?: InputMaybe<Scalars['String']['input']>;
  sendgrid_email_domain_id?: InputMaybe<Scalars['Int']['input']>;
  sequence?: InputMaybe<SequenceCreateNestedManyWithoutOrganzationInput>;
  SequenceCriteriaConditions?: InputMaybe<SequenceCriteriaConditionCreateNestedManyWithoutOrganizationInput>;
  SequenceDashboardViews?: InputMaybe<SequenceDashboardViewCreateNestedManyWithoutOrganizationInput>;
  SequenceEntryCriterias?: InputMaybe<SequenceEntryCriteriaCreateNestedManyWithoutOrganizationInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutOrganizationInput>;
  SequenceSteps?: InputMaybe<SequenceStepCreateNestedManyWithoutOrganizationInput>;
  set_goal?: InputMaybe<Scalars['Float']['input']>;
  short_resting?: InputMaybe<Scalars['Int']['input']>;
  show_calendar_options?: InputMaybe<Scalars['Boolean']['input']>;
  show_contract_duration_sales?: InputMaybe<Scalars['Boolean']['input']>;
  show_lead_source_to_rep?: InputMaybe<Scalars['Boolean']['input']>;
  show_nocontact_button?: InputMaybe<Scalars['Boolean']['input']>;
  show_payment_terms_sales?: InputMaybe<Scalars['Boolean']['input']>;
  show_rep_all_leads?: InputMaybe<Scalars['Boolean']['input']>;
  show_rep_my_leads?: InputMaybe<Scalars['Boolean']['input']>;
  show_system_view?: InputMaybe<Scalars['Boolean']['input']>;
  sites?: InputMaybe<SiteCreateNestedManyWithoutOrganizationInput>;
  sms_opt_in_dialog?: InputMaybe<Scalars['String']['input']>;
  SMSItems?: InputMaybe<SmsItemCreateNestedManyWithoutOrganizationInput>;
  SnoozeTrackings?: InputMaybe<SnoozeTrackingCreateNestedManyWithoutOrganizationInput>;
  StepActionLogs?: InputMaybe<StepActionLogCreateNestedManyWithoutOrganizationInput>;
  StepActions?: InputMaybe<StepActionCreateNestedManyWithoutOrganizationInput>;
  StepConditions?: InputMaybe<StepConditionCreateNestedManyWithoutOrganizationInput>;
  teams?: InputMaybe<TeamCreateNestedManyWithoutOrganizationInput>;
  Template?: InputMaybe<TemplateCreateNestedManyWithoutOrganizationInput>;
  TemplateVersions?: InputMaybe<TemplateVersionCreateNestedManyWithoutOrganizationInput>;
  timekit_id?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  transfer_timeout?: InputMaybe<Scalars['Int']['input']>;
  TransferAttempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutOrganizationInput>;
  twilio_numbers?: InputMaybe<TwilioNumberCreateNestedManyWithoutOrganizationInput>;
  TwilioFeedbacks?: InputMaybe<TwilioFeedbackCreateNestedManyWithoutOrganizationInput>;
  TwilioInfo?: InputMaybe<TwilioInfoCreateNestedOneWithoutOrganzationInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  use_revenue_planning?: InputMaybe<Scalars['Boolean']['input']>;
  UserCustomFieldFilters?: InputMaybe<UserCustomFieldFilterCreateNestedManyWithoutOrganizationInput>;
  users?: InputMaybe<UserCreateNestedManyWithoutOrganizationInput>;
  win_rep_day_goal?: InputMaybe<Scalars['Float']['input']>;
};

export type OrganizationCreateWithoutAutomated_CampaignsInput = {
  ActivityMonitorMultiplier?: InputMaybe<ActivityMonitorMultiplierCreateNestedManyWithoutOrganizationInput>;
  algoFactor?: InputMaybe<AlgoFactorCreateNestedManyWithoutOrganizationInput>;
  allow_calendar_invite?: InputMaybe<Scalars['Boolean']['input']>;
  allow_remove_step_action?: InputMaybe<Scalars['Boolean']['input']>;
  allow_snooze_step_action?: InputMaybe<Scalars['Boolean']['input']>;
  always_record?: InputMaybe<Scalars['Boolean']['input']>;
  arv_initial_held_demos_generated_by_sdr_weight?: InputMaybe<Scalars['Float']['input']>;
  AssociationHistories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutOrganizationInput>;
  auto_call_interval?: InputMaybe<Scalars['Int']['input']>;
  auto_dial?: InputMaybe<Scalars['Boolean']['input']>;
  automatic_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  AutoTransferSelections?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutOrganizationInput>;
  Availablities?: InputMaybe<AvailabilityCreateNestedManyWithoutOrganizationInput>;
  AverageOrderValue?: InputMaybe<AverageOrderValueCreateNestedManyWithoutOrganizationInput>;
  best_action_ever_multiplier?: InputMaybe<Scalars['Float']['input']>;
  best_metrics?: InputMaybe<BestMetricRecordCreateNestedManyWithoutOrganizationInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptCreateNestedManyWithoutOrganizationInput>;
  callMeNows?: InputMaybe<CallMeNowCreateNestedManyWithoutOrganizationInput>;
  CallMultiplier?: InputMaybe<CallMultiplierCreateNestedManyWithoutOrganizationInput>;
  CallSharings?: InputMaybe<CallSharingCreateNestedManyWithoutOrganizationInput>;
  can_edit_sdr_transfer_rules?: InputMaybe<Scalars['Boolean']['input']>;
  claim_rule?: InputMaybe<Scalars['Int']['input']>;
  concession?: InputMaybe<ConcessionCreateNestedManyWithoutOrganizationInput>;
  conference_region?: InputMaybe<ConferenceRegion>;
  Conferences?: InputMaybe<ConferenceCreateNestedManyWithoutOrganizationInput>;
  ContactRequests?: InputMaybe<ContactRequestCreateNestedManyWithoutOrganizationInput>;
  Contacts?: InputMaybe<ContactCreateNestedManyWithoutOrganizationInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  custom_fields?: InputMaybe<CustomFieldCreateNestedManyWithoutOrganizationInput>;
  CustomObject?: InputMaybe<CustomObjectCreateNestedManyWithoutOrganizationInput>;
  CustomObjectField?: InputMaybe<CustomObjectFieldCreateNestedManyWithoutOrganizationInput>;
  CustomObjectFieldForeignKey?: InputMaybe<CustomObjectFieldForeignKeyCreateNestedManyWithoutOrganizationInput>;
  CustomObjectRecord?: InputMaybe<CustomObjectRecordCreateNestedManyWithoutOrganizationInput>;
  CustomObjectRow?: InputMaybe<CustomObjectRowCreateNestedManyWithoutOrganizationInput>;
  CustomQueueItems?: InputMaybe<CustomQueueItemCreateNestedManyWithoutOrganizationInput>;
  DashboardColumnOptions?: InputMaybe<DashboardColumnOptionCreateNestedManyWithoutOrganizationInput>;
  DashboardLeadFilters?: InputMaybe<DashboardLeadFilterCreateNestedManyWithoutOrganizationInput>;
  DashboardRepFilters?: InputMaybe<DashboardRepFilterCreateNestedManyWithoutOrganizationInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewCreateNestedManyWithoutOrganizationInput>;
  day_to_close?: InputMaybe<Scalars['Int']['input']>;
  default_demo_time_duration?: InputMaybe<Scalars['Int']['input']>;
  demo_held_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_not_held_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_not_held_call_back_short_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_prep_time_minutes?: InputMaybe<Scalars['Int']['input']>;
  dev_account?: InputMaybe<Scalars['Boolean']['input']>;
  dial_rule?: InputMaybe<Scalars['Int']['input']>;
  DispositionMultiplier?: InputMaybe<DispositionMultiplierCreateNestedManyWithoutOrganizationInput>;
  dispositions?: InputMaybe<DispositionCreateNestedManyWithoutOrganizationInput>;
  double_booking?: InputMaybe<Scalars['Boolean']['input']>;
  dynamic_date_operators?: InputMaybe<Scalars['Boolean']['input']>;
  edge_servers?: InputMaybe<OrganizationCreateedge_ServersInput>;
  email_conflict_notification_email_list?: InputMaybe<OrganizationCreateemail_Conflict_Notification_Email_ListInput>;
  EmailItems?: InputMaybe<EmailItemCreateNestedManyWithoutOrganizationInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordCreateNestedManyWithoutOrganizationInput>;
  EmailThreads?: InputMaybe<EmailThreadCreateNestedManyWithoutOrganizationInput>;
  enable_modified_metrics?: InputMaybe<Scalars['Boolean']['input']>;
  enable_pay_period_view?: InputMaybe<Scalars['Boolean']['input']>;
  enable_time_constraint?: InputMaybe<Scalars['Boolean']['input']>;
  error_log_email_address?: InputMaybe<OrganizationCreateerror_Log_Email_AddressInput>;
  estimated_average_num_of_weekly_overbooked_demo_slots?: InputMaybe<Scalars['Int']['input']>;
  FolderPermissions?: InputMaybe<FolderPermissionCreateNestedManyWithoutOrganizationInput>;
  Folders?: InputMaybe<FolderCreateNestedManyWithoutOrganizationInput>;
  FutureBookingSetting?: InputMaybe<FutureBookingSettingCreateNestedOneWithoutOrganizationInput>;
  GlobalTransferRule?: InputMaybe<GlobalTransferRuleCreateNestedManyWithoutOrganizationInput>;
  held_demos_per_cc_connect?: InputMaybe<Scalars['Float']['input']>;
  helpful_links?: InputMaybe<OrganizationCreatehelpful_LinksInput>;
  HingePointMultiplier?: InputMaybe<HingePointMultiplierCreateNestedManyWithoutOrganizationInput>;
  hold_goal?: InputMaybe<Scalars['Float']['input']>;
  hubspot_credential?: InputMaybe<HubSpotCredentialCreateNestedOneWithoutOrganizationInput>;
  hubspot_last_sync_date?: InputMaybe<Scalars['DateTime']['input']>;
  hubspot_white_list?: InputMaybe<HubSpotWhitelistCreateNestedManyWithoutOrganizationInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  inbound_close_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_dm_connect_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_lt_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_revenue_value_of_sale?: InputMaybe<Scalars['Float']['input']>;
  inbound_scheduled_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_set_rate?: InputMaybe<Scalars['Float']['input']>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventCreateNestedManyWithoutOrganizationInput>;
  InboundConciergeWidget?: InputMaybe<InboundConciergeWidgetCreateNestedManyWithoutOrganizationInput>;
  industry_options?: InputMaybe<IndustryOptionCreateNestedManyWithoutOrganizationInput>;
  initial_sdr_sets_reps_day?: InputMaybe<Scalars['Float']['input']>;
  integration_error_logs?: InputMaybe<IntegrationErrorLogCreateNestedManyWithoutOrganizationInput>;
  integration_mappings?: InputMaybe<IntegrationMappingCreateNestedManyWithoutOrganizationInput>;
  IntegrationMappingCustomObject?: InputMaybe<IntegrationMappingCustomObjectCreateNestedManyWithoutOrganizationInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogCreateNestedManyWithoutOrganizationInput>;
  IntegrationReadOnlyObject?: InputMaybe<IntegrationReadOnlyObjectCreateNestedManyWithoutOrganizationInput>;
  international?: InputMaybe<Scalars['Boolean']['input']>;
  is_email_domain_verified?: InputMaybe<Scalars['Boolean']['input']>;
  is_salesforce_sandbox?: InputMaybe<Scalars['Boolean']['input']>;
  last_lead_value_compute_time?: InputMaybe<Scalars['DateTime']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutOrganizationInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryCreateNestedManyWithoutOrganizationInput>;
  lead_intent?: InputMaybe<LeadIntentCreateNestedManyWithoutOrganizationInput>;
  lead_release_same_rep_delay_rule?: InputMaybe<Scalars['Int']['input']>;
  lead_routing_email_list?: InputMaybe<OrganizationCreatelead_Routing_Email_ListInput>;
  lead_routing_rules?: InputMaybe<RuleCreateNestedManyWithoutOrganizationInput>;
  LeadCustomObjectRowAssociation?: InputMaybe<LeadCustomObjectRowAssociationCreateNestedManyWithoutOrganizationInput>;
  LeadFieldMultiplier?: InputMaybe<LeadFieldMultiplierCreateNestedManyWithoutOrganizationInput>;
  LeadFieldMultiplierCustom?: InputMaybe<LeadFieldMultiplierCustomCreateNestedManyWithoutOrganizationInput>;
  leads?: InputMaybe<LeadCreateNestedManyWithoutOrganizationInput>;
  leadsource_options?: InputMaybe<LeadSourceOptionCreateNestedManyWithoutOrganizationInput>;
  LeadValue?: InputMaybe<LeadValueCreateNestedManyWithoutOrganizationInput>;
  LifeTimeValueMultiplier?: InputMaybe<LifeTimeValueMultiplierCreateNestedManyWithoutOrganizationInput>;
  LiveTransferSetting?: InputMaybe<LiveTransferSettingCreateNestedOneWithoutOrganizationInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusCreateNestedManyWithoutOrganizationInput>;
  ltv_multiplier_custom_fields?: InputMaybe<LifeTimeValueMultiplierCustomCreateNestedManyWithoutOrganizationInput>;
  made_contact_rule?: InputMaybe<Scalars['Int']['input']>;
  make_sale_address_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_business_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_city_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_country_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_first_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_industry_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_last_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_lead_source_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_primary_email_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_primary_phone_number_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_state_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_sub_industry_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_zip_required?: InputMaybe<Scalars['Boolean']['input']>;
  maxium_day_lag_set_to_schedule?: InputMaybe<Scalars['Int']['input']>;
  mrr?: InputMaybe<Scalars['Int']['input']>;
  mrr_label?: InputMaybe<Scalars['String']['input']>;
  mrr_required_on_sale?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  NoteItems?: InputMaybe<NoteItemCreateNestedManyWithoutOrganizationInput>;
  Notifications?: InputMaybe<NotificationCreateNestedManyWithoutOrganizationInput>;
  onboardComplete?: InputMaybe<Scalars['Boolean']['input']>;
  Opportunity?: InputMaybe<SfOpportunityCreateNestedManyWithoutOrganizationInput>;
  organization_holidays?: InputMaybe<OrganizationHolidayCreateNestedManyWithoutOrganizationInput>;
  organization_hours?: InputMaybe<OrganizationHourCreateNestedManyWithoutOrganizationInput>;
  OrganizationPaymentSetting?: InputMaybe<OrganizationPaymentSettingsCreateNestedOneWithoutOrganizationInput>;
  OrganizationPaymentSettings?: InputMaybe<OrganizationPaymentSettingsCreateNestedOneWithoutOrganizationsInput>;
  orgCalendarInviteMetadata?: InputMaybe<OrgCalendarInviteMetadataCreateNestedManyWithoutOrganizationInput>;
  OrgDefaultPipelinePerecentValue?: InputMaybe<OrgDefaultPipelinePerecentValueCreateNestedOneWithoutOrganizationInput>;
  OrgHubSpotSync?: InputMaybe<OrgHubSpotSyncCreateNestedOneWithoutOrganizationInput>;
  OrgNoEventDate?: InputMaybe<OrgNoEventDateCreateNestedManyWithoutOrganizationInput>;
  OrgSalesforceSync?: InputMaybe<OrgSalesforceSyncCreateNestedOneWithoutOrganizationInput>;
  OrgTimeslotAvailability?: InputMaybe<OrgTimeslotAvailabilityCreateNestedManyWithoutOrganizationInput>;
  outbound_close_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_dm_connect_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_lt_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_revenue_value_of_sale?: InputMaybe<Scalars['Float']['input']>;
  outbound_scheduled_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_set_rate?: InputMaybe<Scalars['Float']['input']>;
  PandadocData?: InputMaybe<PandadocDataCreateNestedOneWithoutOrganizationInput>;
  PandaDocTemplate?: InputMaybe<PandaDocTemplateCreateNestedManyWithoutOrganizationInput>;
  participant_jitter_buffer_size?: InputMaybe<JitterBufferSize>;
  preferred_goal_setting?: InputMaybe<Goalsetting>;
  prepayment?: InputMaybe<Scalars['Boolean']['input']>;
  ProductChangeLog?: InputMaybe<ProductChangeLogCreateNestedManyWithoutOrganizationInput>;
  products?: InputMaybe<ProductCreateNestedManyWithoutOrganizationInput>;
  re_cold_call_hour_delay?: InputMaybe<Scalars['Float']['input']>;
  recordingStatus?: InputMaybe<RecordingStatus>;
  release_lead_resting?: InputMaybe<Scalars['Int']['input']>;
  rep_claim_limit?: InputMaybe<Scalars['Int']['input']>;
  rep_lead_allotment?: InputMaybe<Scalars['Int']['input']>;
  rep_level_call_transfer?: InputMaybe<Scalars['Boolean']['input']>;
  reps_can_bulk_unassign?: InputMaybe<Scalars['Boolean']['input']>;
  reps_can_view_all_notes?: InputMaybe<Scalars['Boolean']['input']>;
  require_sale_notes?: InputMaybe<Scalars['Boolean']['input']>;
  resting_after_skipped_CC?: InputMaybe<Scalars['Int']['input']>;
  resting_period?: InputMaybe<Scalars['Int']['input']>;
  resting_period_after_unsuccessful_cold_call?: InputMaybe<Scalars['Float']['input']>;
  resting_period_after_unsuccessful_demo?: InputMaybe<Scalars['Float']['input']>;
  revenue_plan?: InputMaybe<RevenuePlanItemCreateNestedManyWithoutOrganizationInput>;
  Routings?: InputMaybe<RoutingCreateNestedManyWithoutOrganizationInput>;
  sale_field_options?: InputMaybe<SaleFieldOptionCreateNestedManyWithoutOrganizationInput>;
  sale_notification_email_list?: InputMaybe<OrganizationCreatesale_Notification_Email_ListInput>;
  SaleConfig?: InputMaybe<SaleConfigCreateNestedOneWithoutOrganizationInput>;
  SaleConfigPage?: InputMaybe<SaleConfigPageCreateNestedManyWithoutOrganizationInput>;
  SaleConfigSection?: InputMaybe<SaleConfigSectionCreateNestedManyWithoutOrganizationInput>;
  SaleCycles?: InputMaybe<SaleCycleCreateNestedManyWithoutOrganizationInput>;
  SaleItems?: InputMaybe<SaleItemCreateNestedManyWithoutOrganizationInput>;
  sales?: InputMaybe<SaleCreateNestedManyWithoutOrganizationInput>;
  salesforce_domain?: InputMaybe<Scalars['String']['input']>;
  salesforce_last_sync_date?: InputMaybe<Scalars['DateTime']['input']>;
  SalesForceColumn?: InputMaybe<SalesForceColumnCreateNestedManyWithoutOrganizationInput>;
  salesForceData?: InputMaybe<SalesForceDataCreateNestedOneWithoutOrganizationInput>;
  ScheduleItems?: InputMaybe<ScheduleItemCreateNestedManyWithoutOrganizationInput>;
  self_sourcing_rule?: InputMaybe<Scalars['Int']['input']>;
  send_error_log_email?: InputMaybe<Scalars['Boolean']['input']>;
  send_lead_routing_email?: InputMaybe<Scalars['Boolean']['input']>;
  sendgrid_email_domain?: InputMaybe<Scalars['String']['input']>;
  sendgrid_email_domain_id?: InputMaybe<Scalars['Int']['input']>;
  sequence?: InputMaybe<SequenceCreateNestedManyWithoutOrganzationInput>;
  SequenceCriteriaConditions?: InputMaybe<SequenceCriteriaConditionCreateNestedManyWithoutOrganizationInput>;
  SequenceDashboardViews?: InputMaybe<SequenceDashboardViewCreateNestedManyWithoutOrganizationInput>;
  SequenceEntryCriterias?: InputMaybe<SequenceEntryCriteriaCreateNestedManyWithoutOrganizationInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutOrganizationInput>;
  SequenceSteps?: InputMaybe<SequenceStepCreateNestedManyWithoutOrganizationInput>;
  set_goal?: InputMaybe<Scalars['Float']['input']>;
  short_resting?: InputMaybe<Scalars['Int']['input']>;
  show_calendar_options?: InputMaybe<Scalars['Boolean']['input']>;
  show_contract_duration_sales?: InputMaybe<Scalars['Boolean']['input']>;
  show_lead_source_to_rep?: InputMaybe<Scalars['Boolean']['input']>;
  show_nocontact_button?: InputMaybe<Scalars['Boolean']['input']>;
  show_payment_terms_sales?: InputMaybe<Scalars['Boolean']['input']>;
  show_rep_all_leads?: InputMaybe<Scalars['Boolean']['input']>;
  show_rep_my_leads?: InputMaybe<Scalars['Boolean']['input']>;
  show_system_view?: InputMaybe<Scalars['Boolean']['input']>;
  sites?: InputMaybe<SiteCreateNestedManyWithoutOrganizationInput>;
  sms_opt_in_dialog?: InputMaybe<Scalars['String']['input']>;
  SMSItems?: InputMaybe<SmsItemCreateNestedManyWithoutOrganizationInput>;
  SnoozeTrackings?: InputMaybe<SnoozeTrackingCreateNestedManyWithoutOrganizationInput>;
  StepActionLogs?: InputMaybe<StepActionLogCreateNestedManyWithoutOrganizationInput>;
  StepActions?: InputMaybe<StepActionCreateNestedManyWithoutOrganizationInput>;
  StepConditions?: InputMaybe<StepConditionCreateNestedManyWithoutOrganizationInput>;
  teams?: InputMaybe<TeamCreateNestedManyWithoutOrganizationInput>;
  Template?: InputMaybe<TemplateCreateNestedManyWithoutOrganizationInput>;
  TemplateVersions?: InputMaybe<TemplateVersionCreateNestedManyWithoutOrganizationInput>;
  timekit_id?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  transfer_timeout?: InputMaybe<Scalars['Int']['input']>;
  TransferAttempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutOrganizationInput>;
  twilio_numbers?: InputMaybe<TwilioNumberCreateNestedManyWithoutOrganizationInput>;
  TwilioFeedbacks?: InputMaybe<TwilioFeedbackCreateNestedManyWithoutOrganizationInput>;
  TwilioInfo?: InputMaybe<TwilioInfoCreateNestedOneWithoutOrganzationInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  use_revenue_planning?: InputMaybe<Scalars['Boolean']['input']>;
  UserCustomFieldFilters?: InputMaybe<UserCustomFieldFilterCreateNestedManyWithoutOrganizationInput>;
  users?: InputMaybe<UserCreateNestedManyWithoutOrganizationInput>;
  win_rep_day_goal?: InputMaybe<Scalars['Float']['input']>;
};

export type OrganizationCreateWithoutAutoTransferSelectionsInput = {
  ActivityMonitorMultiplier?: InputMaybe<ActivityMonitorMultiplierCreateNestedManyWithoutOrganizationInput>;
  algoFactor?: InputMaybe<AlgoFactorCreateNestedManyWithoutOrganizationInput>;
  allow_calendar_invite?: InputMaybe<Scalars['Boolean']['input']>;
  allow_remove_step_action?: InputMaybe<Scalars['Boolean']['input']>;
  allow_snooze_step_action?: InputMaybe<Scalars['Boolean']['input']>;
  always_record?: InputMaybe<Scalars['Boolean']['input']>;
  arv_initial_held_demos_generated_by_sdr_weight?: InputMaybe<Scalars['Float']['input']>;
  AssociationHistories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutOrganizationInput>;
  auto_call_interval?: InputMaybe<Scalars['Int']['input']>;
  auto_dial?: InputMaybe<Scalars['Boolean']['input']>;
  automated_campaigns?: InputMaybe<AutomatedCampaignCreateNestedManyWithoutOrganizationInput>;
  automatic_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  Availablities?: InputMaybe<AvailabilityCreateNestedManyWithoutOrganizationInput>;
  AverageOrderValue?: InputMaybe<AverageOrderValueCreateNestedManyWithoutOrganizationInput>;
  best_action_ever_multiplier?: InputMaybe<Scalars['Float']['input']>;
  best_metrics?: InputMaybe<BestMetricRecordCreateNestedManyWithoutOrganizationInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptCreateNestedManyWithoutOrganizationInput>;
  callMeNows?: InputMaybe<CallMeNowCreateNestedManyWithoutOrganizationInput>;
  CallMultiplier?: InputMaybe<CallMultiplierCreateNestedManyWithoutOrganizationInput>;
  CallSharings?: InputMaybe<CallSharingCreateNestedManyWithoutOrganizationInput>;
  can_edit_sdr_transfer_rules?: InputMaybe<Scalars['Boolean']['input']>;
  claim_rule?: InputMaybe<Scalars['Int']['input']>;
  concession?: InputMaybe<ConcessionCreateNestedManyWithoutOrganizationInput>;
  conference_region?: InputMaybe<ConferenceRegion>;
  Conferences?: InputMaybe<ConferenceCreateNestedManyWithoutOrganizationInput>;
  ContactRequests?: InputMaybe<ContactRequestCreateNestedManyWithoutOrganizationInput>;
  Contacts?: InputMaybe<ContactCreateNestedManyWithoutOrganizationInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  custom_fields?: InputMaybe<CustomFieldCreateNestedManyWithoutOrganizationInput>;
  CustomObject?: InputMaybe<CustomObjectCreateNestedManyWithoutOrganizationInput>;
  CustomObjectField?: InputMaybe<CustomObjectFieldCreateNestedManyWithoutOrganizationInput>;
  CustomObjectFieldForeignKey?: InputMaybe<CustomObjectFieldForeignKeyCreateNestedManyWithoutOrganizationInput>;
  CustomObjectRecord?: InputMaybe<CustomObjectRecordCreateNestedManyWithoutOrganizationInput>;
  CustomObjectRow?: InputMaybe<CustomObjectRowCreateNestedManyWithoutOrganizationInput>;
  CustomQueueItems?: InputMaybe<CustomQueueItemCreateNestedManyWithoutOrganizationInput>;
  DashboardColumnOptions?: InputMaybe<DashboardColumnOptionCreateNestedManyWithoutOrganizationInput>;
  DashboardLeadFilters?: InputMaybe<DashboardLeadFilterCreateNestedManyWithoutOrganizationInput>;
  DashboardRepFilters?: InputMaybe<DashboardRepFilterCreateNestedManyWithoutOrganizationInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewCreateNestedManyWithoutOrganizationInput>;
  day_to_close?: InputMaybe<Scalars['Int']['input']>;
  default_demo_time_duration?: InputMaybe<Scalars['Int']['input']>;
  demo_held_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_not_held_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_not_held_call_back_short_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_prep_time_minutes?: InputMaybe<Scalars['Int']['input']>;
  dev_account?: InputMaybe<Scalars['Boolean']['input']>;
  dial_rule?: InputMaybe<Scalars['Int']['input']>;
  DispositionMultiplier?: InputMaybe<DispositionMultiplierCreateNestedManyWithoutOrganizationInput>;
  dispositions?: InputMaybe<DispositionCreateNestedManyWithoutOrganizationInput>;
  double_booking?: InputMaybe<Scalars['Boolean']['input']>;
  dynamic_date_operators?: InputMaybe<Scalars['Boolean']['input']>;
  edge_servers?: InputMaybe<OrganizationCreateedge_ServersInput>;
  email_conflict_notification_email_list?: InputMaybe<OrganizationCreateemail_Conflict_Notification_Email_ListInput>;
  EmailItems?: InputMaybe<EmailItemCreateNestedManyWithoutOrganizationInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordCreateNestedManyWithoutOrganizationInput>;
  EmailThreads?: InputMaybe<EmailThreadCreateNestedManyWithoutOrganizationInput>;
  enable_modified_metrics?: InputMaybe<Scalars['Boolean']['input']>;
  enable_pay_period_view?: InputMaybe<Scalars['Boolean']['input']>;
  enable_time_constraint?: InputMaybe<Scalars['Boolean']['input']>;
  error_log_email_address?: InputMaybe<OrganizationCreateerror_Log_Email_AddressInput>;
  estimated_average_num_of_weekly_overbooked_demo_slots?: InputMaybe<Scalars['Int']['input']>;
  FolderPermissions?: InputMaybe<FolderPermissionCreateNestedManyWithoutOrganizationInput>;
  Folders?: InputMaybe<FolderCreateNestedManyWithoutOrganizationInput>;
  FutureBookingSetting?: InputMaybe<FutureBookingSettingCreateNestedOneWithoutOrganizationInput>;
  GlobalTransferRule?: InputMaybe<GlobalTransferRuleCreateNestedManyWithoutOrganizationInput>;
  held_demos_per_cc_connect?: InputMaybe<Scalars['Float']['input']>;
  helpful_links?: InputMaybe<OrganizationCreatehelpful_LinksInput>;
  HingePointMultiplier?: InputMaybe<HingePointMultiplierCreateNestedManyWithoutOrganizationInput>;
  hold_goal?: InputMaybe<Scalars['Float']['input']>;
  hubspot_credential?: InputMaybe<HubSpotCredentialCreateNestedOneWithoutOrganizationInput>;
  hubspot_last_sync_date?: InputMaybe<Scalars['DateTime']['input']>;
  hubspot_white_list?: InputMaybe<HubSpotWhitelistCreateNestedManyWithoutOrganizationInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  inbound_close_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_dm_connect_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_lt_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_revenue_value_of_sale?: InputMaybe<Scalars['Float']['input']>;
  inbound_scheduled_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_set_rate?: InputMaybe<Scalars['Float']['input']>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventCreateNestedManyWithoutOrganizationInput>;
  InboundConciergeWidget?: InputMaybe<InboundConciergeWidgetCreateNestedManyWithoutOrganizationInput>;
  industry_options?: InputMaybe<IndustryOptionCreateNestedManyWithoutOrganizationInput>;
  initial_sdr_sets_reps_day?: InputMaybe<Scalars['Float']['input']>;
  integration_error_logs?: InputMaybe<IntegrationErrorLogCreateNestedManyWithoutOrganizationInput>;
  integration_mappings?: InputMaybe<IntegrationMappingCreateNestedManyWithoutOrganizationInput>;
  IntegrationMappingCustomObject?: InputMaybe<IntegrationMappingCustomObjectCreateNestedManyWithoutOrganizationInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogCreateNestedManyWithoutOrganizationInput>;
  IntegrationReadOnlyObject?: InputMaybe<IntegrationReadOnlyObjectCreateNestedManyWithoutOrganizationInput>;
  international?: InputMaybe<Scalars['Boolean']['input']>;
  is_email_domain_verified?: InputMaybe<Scalars['Boolean']['input']>;
  is_salesforce_sandbox?: InputMaybe<Scalars['Boolean']['input']>;
  last_lead_value_compute_time?: InputMaybe<Scalars['DateTime']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutOrganizationInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryCreateNestedManyWithoutOrganizationInput>;
  lead_intent?: InputMaybe<LeadIntentCreateNestedManyWithoutOrganizationInput>;
  lead_release_same_rep_delay_rule?: InputMaybe<Scalars['Int']['input']>;
  lead_routing_email_list?: InputMaybe<OrganizationCreatelead_Routing_Email_ListInput>;
  lead_routing_rules?: InputMaybe<RuleCreateNestedManyWithoutOrganizationInput>;
  LeadCustomObjectRowAssociation?: InputMaybe<LeadCustomObjectRowAssociationCreateNestedManyWithoutOrganizationInput>;
  LeadFieldMultiplier?: InputMaybe<LeadFieldMultiplierCreateNestedManyWithoutOrganizationInput>;
  LeadFieldMultiplierCustom?: InputMaybe<LeadFieldMultiplierCustomCreateNestedManyWithoutOrganizationInput>;
  leads?: InputMaybe<LeadCreateNestedManyWithoutOrganizationInput>;
  leadsource_options?: InputMaybe<LeadSourceOptionCreateNestedManyWithoutOrganizationInput>;
  LeadValue?: InputMaybe<LeadValueCreateNestedManyWithoutOrganizationInput>;
  LifeTimeValueMultiplier?: InputMaybe<LifeTimeValueMultiplierCreateNestedManyWithoutOrganizationInput>;
  LiveTransferSetting?: InputMaybe<LiveTransferSettingCreateNestedOneWithoutOrganizationInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusCreateNestedManyWithoutOrganizationInput>;
  ltv_multiplier_custom_fields?: InputMaybe<LifeTimeValueMultiplierCustomCreateNestedManyWithoutOrganizationInput>;
  made_contact_rule?: InputMaybe<Scalars['Int']['input']>;
  make_sale_address_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_business_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_city_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_country_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_first_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_industry_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_last_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_lead_source_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_primary_email_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_primary_phone_number_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_state_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_sub_industry_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_zip_required?: InputMaybe<Scalars['Boolean']['input']>;
  maxium_day_lag_set_to_schedule?: InputMaybe<Scalars['Int']['input']>;
  mrr?: InputMaybe<Scalars['Int']['input']>;
  mrr_label?: InputMaybe<Scalars['String']['input']>;
  mrr_required_on_sale?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  NoteItems?: InputMaybe<NoteItemCreateNestedManyWithoutOrganizationInput>;
  Notifications?: InputMaybe<NotificationCreateNestedManyWithoutOrganizationInput>;
  onboardComplete?: InputMaybe<Scalars['Boolean']['input']>;
  Opportunity?: InputMaybe<SfOpportunityCreateNestedManyWithoutOrganizationInput>;
  organization_holidays?: InputMaybe<OrganizationHolidayCreateNestedManyWithoutOrganizationInput>;
  organization_hours?: InputMaybe<OrganizationHourCreateNestedManyWithoutOrganizationInput>;
  OrganizationPaymentSetting?: InputMaybe<OrganizationPaymentSettingsCreateNestedOneWithoutOrganizationInput>;
  OrganizationPaymentSettings?: InputMaybe<OrganizationPaymentSettingsCreateNestedOneWithoutOrganizationsInput>;
  orgCalendarInviteMetadata?: InputMaybe<OrgCalendarInviteMetadataCreateNestedManyWithoutOrganizationInput>;
  OrgDefaultPipelinePerecentValue?: InputMaybe<OrgDefaultPipelinePerecentValueCreateNestedOneWithoutOrganizationInput>;
  OrgHubSpotSync?: InputMaybe<OrgHubSpotSyncCreateNestedOneWithoutOrganizationInput>;
  OrgNoEventDate?: InputMaybe<OrgNoEventDateCreateNestedManyWithoutOrganizationInput>;
  OrgSalesforceSync?: InputMaybe<OrgSalesforceSyncCreateNestedOneWithoutOrganizationInput>;
  OrgTimeslotAvailability?: InputMaybe<OrgTimeslotAvailabilityCreateNestedManyWithoutOrganizationInput>;
  outbound_close_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_dm_connect_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_lt_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_revenue_value_of_sale?: InputMaybe<Scalars['Float']['input']>;
  outbound_scheduled_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_set_rate?: InputMaybe<Scalars['Float']['input']>;
  PandadocData?: InputMaybe<PandadocDataCreateNestedOneWithoutOrganizationInput>;
  PandaDocTemplate?: InputMaybe<PandaDocTemplateCreateNestedManyWithoutOrganizationInput>;
  participant_jitter_buffer_size?: InputMaybe<JitterBufferSize>;
  preferred_goal_setting?: InputMaybe<Goalsetting>;
  prepayment?: InputMaybe<Scalars['Boolean']['input']>;
  ProductChangeLog?: InputMaybe<ProductChangeLogCreateNestedManyWithoutOrganizationInput>;
  products?: InputMaybe<ProductCreateNestedManyWithoutOrganizationInput>;
  re_cold_call_hour_delay?: InputMaybe<Scalars['Float']['input']>;
  recordingStatus?: InputMaybe<RecordingStatus>;
  release_lead_resting?: InputMaybe<Scalars['Int']['input']>;
  rep_claim_limit?: InputMaybe<Scalars['Int']['input']>;
  rep_lead_allotment?: InputMaybe<Scalars['Int']['input']>;
  rep_level_call_transfer?: InputMaybe<Scalars['Boolean']['input']>;
  reps_can_bulk_unassign?: InputMaybe<Scalars['Boolean']['input']>;
  reps_can_view_all_notes?: InputMaybe<Scalars['Boolean']['input']>;
  require_sale_notes?: InputMaybe<Scalars['Boolean']['input']>;
  resting_after_skipped_CC?: InputMaybe<Scalars['Int']['input']>;
  resting_period?: InputMaybe<Scalars['Int']['input']>;
  resting_period_after_unsuccessful_cold_call?: InputMaybe<Scalars['Float']['input']>;
  resting_period_after_unsuccessful_demo?: InputMaybe<Scalars['Float']['input']>;
  revenue_plan?: InputMaybe<RevenuePlanItemCreateNestedManyWithoutOrganizationInput>;
  Routings?: InputMaybe<RoutingCreateNestedManyWithoutOrganizationInput>;
  sale_field_options?: InputMaybe<SaleFieldOptionCreateNestedManyWithoutOrganizationInput>;
  sale_notification_email_list?: InputMaybe<OrganizationCreatesale_Notification_Email_ListInput>;
  SaleConfig?: InputMaybe<SaleConfigCreateNestedOneWithoutOrganizationInput>;
  SaleConfigPage?: InputMaybe<SaleConfigPageCreateNestedManyWithoutOrganizationInput>;
  SaleConfigSection?: InputMaybe<SaleConfigSectionCreateNestedManyWithoutOrganizationInput>;
  SaleCycles?: InputMaybe<SaleCycleCreateNestedManyWithoutOrganizationInput>;
  SaleItems?: InputMaybe<SaleItemCreateNestedManyWithoutOrganizationInput>;
  sales?: InputMaybe<SaleCreateNestedManyWithoutOrganizationInput>;
  salesforce_domain?: InputMaybe<Scalars['String']['input']>;
  salesforce_last_sync_date?: InputMaybe<Scalars['DateTime']['input']>;
  SalesForceColumn?: InputMaybe<SalesForceColumnCreateNestedManyWithoutOrganizationInput>;
  salesForceData?: InputMaybe<SalesForceDataCreateNestedOneWithoutOrganizationInput>;
  ScheduleItems?: InputMaybe<ScheduleItemCreateNestedManyWithoutOrganizationInput>;
  self_sourcing_rule?: InputMaybe<Scalars['Int']['input']>;
  send_error_log_email?: InputMaybe<Scalars['Boolean']['input']>;
  send_lead_routing_email?: InputMaybe<Scalars['Boolean']['input']>;
  sendgrid_email_domain?: InputMaybe<Scalars['String']['input']>;
  sendgrid_email_domain_id?: InputMaybe<Scalars['Int']['input']>;
  sequence?: InputMaybe<SequenceCreateNestedManyWithoutOrganzationInput>;
  SequenceCriteriaConditions?: InputMaybe<SequenceCriteriaConditionCreateNestedManyWithoutOrganizationInput>;
  SequenceDashboardViews?: InputMaybe<SequenceDashboardViewCreateNestedManyWithoutOrganizationInput>;
  SequenceEntryCriterias?: InputMaybe<SequenceEntryCriteriaCreateNestedManyWithoutOrganizationInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutOrganizationInput>;
  SequenceSteps?: InputMaybe<SequenceStepCreateNestedManyWithoutOrganizationInput>;
  set_goal?: InputMaybe<Scalars['Float']['input']>;
  short_resting?: InputMaybe<Scalars['Int']['input']>;
  show_calendar_options?: InputMaybe<Scalars['Boolean']['input']>;
  show_contract_duration_sales?: InputMaybe<Scalars['Boolean']['input']>;
  show_lead_source_to_rep?: InputMaybe<Scalars['Boolean']['input']>;
  show_nocontact_button?: InputMaybe<Scalars['Boolean']['input']>;
  show_payment_terms_sales?: InputMaybe<Scalars['Boolean']['input']>;
  show_rep_all_leads?: InputMaybe<Scalars['Boolean']['input']>;
  show_rep_my_leads?: InputMaybe<Scalars['Boolean']['input']>;
  show_system_view?: InputMaybe<Scalars['Boolean']['input']>;
  sites?: InputMaybe<SiteCreateNestedManyWithoutOrganizationInput>;
  sms_opt_in_dialog?: InputMaybe<Scalars['String']['input']>;
  SMSItems?: InputMaybe<SmsItemCreateNestedManyWithoutOrganizationInput>;
  SnoozeTrackings?: InputMaybe<SnoozeTrackingCreateNestedManyWithoutOrganizationInput>;
  StepActionLogs?: InputMaybe<StepActionLogCreateNestedManyWithoutOrganizationInput>;
  StepActions?: InputMaybe<StepActionCreateNestedManyWithoutOrganizationInput>;
  StepConditions?: InputMaybe<StepConditionCreateNestedManyWithoutOrganizationInput>;
  teams?: InputMaybe<TeamCreateNestedManyWithoutOrganizationInput>;
  Template?: InputMaybe<TemplateCreateNestedManyWithoutOrganizationInput>;
  TemplateVersions?: InputMaybe<TemplateVersionCreateNestedManyWithoutOrganizationInput>;
  timekit_id?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  transfer_timeout?: InputMaybe<Scalars['Int']['input']>;
  TransferAttempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutOrganizationInput>;
  twilio_numbers?: InputMaybe<TwilioNumberCreateNestedManyWithoutOrganizationInput>;
  TwilioFeedbacks?: InputMaybe<TwilioFeedbackCreateNestedManyWithoutOrganizationInput>;
  TwilioInfo?: InputMaybe<TwilioInfoCreateNestedOneWithoutOrganzationInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  use_revenue_planning?: InputMaybe<Scalars['Boolean']['input']>;
  UserCustomFieldFilters?: InputMaybe<UserCustomFieldFilterCreateNestedManyWithoutOrganizationInput>;
  users?: InputMaybe<UserCreateNestedManyWithoutOrganizationInput>;
  win_rep_day_goal?: InputMaybe<Scalars['Float']['input']>;
};

export type OrganizationCreateWithoutAvailablitiesInput = {
  ActivityMonitorMultiplier?: InputMaybe<ActivityMonitorMultiplierCreateNestedManyWithoutOrganizationInput>;
  algoFactor?: InputMaybe<AlgoFactorCreateNestedManyWithoutOrganizationInput>;
  allow_calendar_invite?: InputMaybe<Scalars['Boolean']['input']>;
  allow_remove_step_action?: InputMaybe<Scalars['Boolean']['input']>;
  allow_snooze_step_action?: InputMaybe<Scalars['Boolean']['input']>;
  always_record?: InputMaybe<Scalars['Boolean']['input']>;
  arv_initial_held_demos_generated_by_sdr_weight?: InputMaybe<Scalars['Float']['input']>;
  AssociationHistories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutOrganizationInput>;
  auto_call_interval?: InputMaybe<Scalars['Int']['input']>;
  auto_dial?: InputMaybe<Scalars['Boolean']['input']>;
  automated_campaigns?: InputMaybe<AutomatedCampaignCreateNestedManyWithoutOrganizationInput>;
  automatic_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  AutoTransferSelections?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutOrganizationInput>;
  AverageOrderValue?: InputMaybe<AverageOrderValueCreateNestedManyWithoutOrganizationInput>;
  best_action_ever_multiplier?: InputMaybe<Scalars['Float']['input']>;
  best_metrics?: InputMaybe<BestMetricRecordCreateNestedManyWithoutOrganizationInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptCreateNestedManyWithoutOrganizationInput>;
  callMeNows?: InputMaybe<CallMeNowCreateNestedManyWithoutOrganizationInput>;
  CallMultiplier?: InputMaybe<CallMultiplierCreateNestedManyWithoutOrganizationInput>;
  CallSharings?: InputMaybe<CallSharingCreateNestedManyWithoutOrganizationInput>;
  can_edit_sdr_transfer_rules?: InputMaybe<Scalars['Boolean']['input']>;
  claim_rule?: InputMaybe<Scalars['Int']['input']>;
  concession?: InputMaybe<ConcessionCreateNestedManyWithoutOrganizationInput>;
  conference_region?: InputMaybe<ConferenceRegion>;
  Conferences?: InputMaybe<ConferenceCreateNestedManyWithoutOrganizationInput>;
  ContactRequests?: InputMaybe<ContactRequestCreateNestedManyWithoutOrganizationInput>;
  Contacts?: InputMaybe<ContactCreateNestedManyWithoutOrganizationInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  custom_fields?: InputMaybe<CustomFieldCreateNestedManyWithoutOrganizationInput>;
  CustomObject?: InputMaybe<CustomObjectCreateNestedManyWithoutOrganizationInput>;
  CustomObjectField?: InputMaybe<CustomObjectFieldCreateNestedManyWithoutOrganizationInput>;
  CustomObjectFieldForeignKey?: InputMaybe<CustomObjectFieldForeignKeyCreateNestedManyWithoutOrganizationInput>;
  CustomObjectRecord?: InputMaybe<CustomObjectRecordCreateNestedManyWithoutOrganizationInput>;
  CustomObjectRow?: InputMaybe<CustomObjectRowCreateNestedManyWithoutOrganizationInput>;
  CustomQueueItems?: InputMaybe<CustomQueueItemCreateNestedManyWithoutOrganizationInput>;
  DashboardColumnOptions?: InputMaybe<DashboardColumnOptionCreateNestedManyWithoutOrganizationInput>;
  DashboardLeadFilters?: InputMaybe<DashboardLeadFilterCreateNestedManyWithoutOrganizationInput>;
  DashboardRepFilters?: InputMaybe<DashboardRepFilterCreateNestedManyWithoutOrganizationInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewCreateNestedManyWithoutOrganizationInput>;
  day_to_close?: InputMaybe<Scalars['Int']['input']>;
  default_demo_time_duration?: InputMaybe<Scalars['Int']['input']>;
  demo_held_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_not_held_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_not_held_call_back_short_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_prep_time_minutes?: InputMaybe<Scalars['Int']['input']>;
  dev_account?: InputMaybe<Scalars['Boolean']['input']>;
  dial_rule?: InputMaybe<Scalars['Int']['input']>;
  DispositionMultiplier?: InputMaybe<DispositionMultiplierCreateNestedManyWithoutOrganizationInput>;
  dispositions?: InputMaybe<DispositionCreateNestedManyWithoutOrganizationInput>;
  double_booking?: InputMaybe<Scalars['Boolean']['input']>;
  dynamic_date_operators?: InputMaybe<Scalars['Boolean']['input']>;
  edge_servers?: InputMaybe<OrganizationCreateedge_ServersInput>;
  email_conflict_notification_email_list?: InputMaybe<OrganizationCreateemail_Conflict_Notification_Email_ListInput>;
  EmailItems?: InputMaybe<EmailItemCreateNestedManyWithoutOrganizationInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordCreateNestedManyWithoutOrganizationInput>;
  EmailThreads?: InputMaybe<EmailThreadCreateNestedManyWithoutOrganizationInput>;
  enable_modified_metrics?: InputMaybe<Scalars['Boolean']['input']>;
  enable_pay_period_view?: InputMaybe<Scalars['Boolean']['input']>;
  enable_time_constraint?: InputMaybe<Scalars['Boolean']['input']>;
  error_log_email_address?: InputMaybe<OrganizationCreateerror_Log_Email_AddressInput>;
  estimated_average_num_of_weekly_overbooked_demo_slots?: InputMaybe<Scalars['Int']['input']>;
  FolderPermissions?: InputMaybe<FolderPermissionCreateNestedManyWithoutOrganizationInput>;
  Folders?: InputMaybe<FolderCreateNestedManyWithoutOrganizationInput>;
  FutureBookingSetting?: InputMaybe<FutureBookingSettingCreateNestedOneWithoutOrganizationInput>;
  GlobalTransferRule?: InputMaybe<GlobalTransferRuleCreateNestedManyWithoutOrganizationInput>;
  held_demos_per_cc_connect?: InputMaybe<Scalars['Float']['input']>;
  helpful_links?: InputMaybe<OrganizationCreatehelpful_LinksInput>;
  HingePointMultiplier?: InputMaybe<HingePointMultiplierCreateNestedManyWithoutOrganizationInput>;
  hold_goal?: InputMaybe<Scalars['Float']['input']>;
  hubspot_credential?: InputMaybe<HubSpotCredentialCreateNestedOneWithoutOrganizationInput>;
  hubspot_last_sync_date?: InputMaybe<Scalars['DateTime']['input']>;
  hubspot_white_list?: InputMaybe<HubSpotWhitelistCreateNestedManyWithoutOrganizationInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  inbound_close_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_dm_connect_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_lt_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_revenue_value_of_sale?: InputMaybe<Scalars['Float']['input']>;
  inbound_scheduled_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_set_rate?: InputMaybe<Scalars['Float']['input']>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventCreateNestedManyWithoutOrganizationInput>;
  InboundConciergeWidget?: InputMaybe<InboundConciergeWidgetCreateNestedManyWithoutOrganizationInput>;
  industry_options?: InputMaybe<IndustryOptionCreateNestedManyWithoutOrganizationInput>;
  initial_sdr_sets_reps_day?: InputMaybe<Scalars['Float']['input']>;
  integration_error_logs?: InputMaybe<IntegrationErrorLogCreateNestedManyWithoutOrganizationInput>;
  integration_mappings?: InputMaybe<IntegrationMappingCreateNestedManyWithoutOrganizationInput>;
  IntegrationMappingCustomObject?: InputMaybe<IntegrationMappingCustomObjectCreateNestedManyWithoutOrganizationInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogCreateNestedManyWithoutOrganizationInput>;
  IntegrationReadOnlyObject?: InputMaybe<IntegrationReadOnlyObjectCreateNestedManyWithoutOrganizationInput>;
  international?: InputMaybe<Scalars['Boolean']['input']>;
  is_email_domain_verified?: InputMaybe<Scalars['Boolean']['input']>;
  is_salesforce_sandbox?: InputMaybe<Scalars['Boolean']['input']>;
  last_lead_value_compute_time?: InputMaybe<Scalars['DateTime']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutOrganizationInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryCreateNestedManyWithoutOrganizationInput>;
  lead_intent?: InputMaybe<LeadIntentCreateNestedManyWithoutOrganizationInput>;
  lead_release_same_rep_delay_rule?: InputMaybe<Scalars['Int']['input']>;
  lead_routing_email_list?: InputMaybe<OrganizationCreatelead_Routing_Email_ListInput>;
  lead_routing_rules?: InputMaybe<RuleCreateNestedManyWithoutOrganizationInput>;
  LeadCustomObjectRowAssociation?: InputMaybe<LeadCustomObjectRowAssociationCreateNestedManyWithoutOrganizationInput>;
  LeadFieldMultiplier?: InputMaybe<LeadFieldMultiplierCreateNestedManyWithoutOrganizationInput>;
  LeadFieldMultiplierCustom?: InputMaybe<LeadFieldMultiplierCustomCreateNestedManyWithoutOrganizationInput>;
  leads?: InputMaybe<LeadCreateNestedManyWithoutOrganizationInput>;
  leadsource_options?: InputMaybe<LeadSourceOptionCreateNestedManyWithoutOrganizationInput>;
  LeadValue?: InputMaybe<LeadValueCreateNestedManyWithoutOrganizationInput>;
  LifeTimeValueMultiplier?: InputMaybe<LifeTimeValueMultiplierCreateNestedManyWithoutOrganizationInput>;
  LiveTransferSetting?: InputMaybe<LiveTransferSettingCreateNestedOneWithoutOrganizationInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusCreateNestedManyWithoutOrganizationInput>;
  ltv_multiplier_custom_fields?: InputMaybe<LifeTimeValueMultiplierCustomCreateNestedManyWithoutOrganizationInput>;
  made_contact_rule?: InputMaybe<Scalars['Int']['input']>;
  make_sale_address_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_business_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_city_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_country_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_first_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_industry_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_last_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_lead_source_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_primary_email_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_primary_phone_number_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_state_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_sub_industry_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_zip_required?: InputMaybe<Scalars['Boolean']['input']>;
  maxium_day_lag_set_to_schedule?: InputMaybe<Scalars['Int']['input']>;
  mrr?: InputMaybe<Scalars['Int']['input']>;
  mrr_label?: InputMaybe<Scalars['String']['input']>;
  mrr_required_on_sale?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  NoteItems?: InputMaybe<NoteItemCreateNestedManyWithoutOrganizationInput>;
  Notifications?: InputMaybe<NotificationCreateNestedManyWithoutOrganizationInput>;
  onboardComplete?: InputMaybe<Scalars['Boolean']['input']>;
  Opportunity?: InputMaybe<SfOpportunityCreateNestedManyWithoutOrganizationInput>;
  organization_holidays?: InputMaybe<OrganizationHolidayCreateNestedManyWithoutOrganizationInput>;
  organization_hours?: InputMaybe<OrganizationHourCreateNestedManyWithoutOrganizationInput>;
  OrganizationPaymentSetting?: InputMaybe<OrganizationPaymentSettingsCreateNestedOneWithoutOrganizationInput>;
  OrganizationPaymentSettings?: InputMaybe<OrganizationPaymentSettingsCreateNestedOneWithoutOrganizationsInput>;
  orgCalendarInviteMetadata?: InputMaybe<OrgCalendarInviteMetadataCreateNestedManyWithoutOrganizationInput>;
  OrgDefaultPipelinePerecentValue?: InputMaybe<OrgDefaultPipelinePerecentValueCreateNestedOneWithoutOrganizationInput>;
  OrgHubSpotSync?: InputMaybe<OrgHubSpotSyncCreateNestedOneWithoutOrganizationInput>;
  OrgNoEventDate?: InputMaybe<OrgNoEventDateCreateNestedManyWithoutOrganizationInput>;
  OrgSalesforceSync?: InputMaybe<OrgSalesforceSyncCreateNestedOneWithoutOrganizationInput>;
  OrgTimeslotAvailability?: InputMaybe<OrgTimeslotAvailabilityCreateNestedManyWithoutOrganizationInput>;
  outbound_close_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_dm_connect_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_lt_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_revenue_value_of_sale?: InputMaybe<Scalars['Float']['input']>;
  outbound_scheduled_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_set_rate?: InputMaybe<Scalars['Float']['input']>;
  PandadocData?: InputMaybe<PandadocDataCreateNestedOneWithoutOrganizationInput>;
  PandaDocTemplate?: InputMaybe<PandaDocTemplateCreateNestedManyWithoutOrganizationInput>;
  participant_jitter_buffer_size?: InputMaybe<JitterBufferSize>;
  preferred_goal_setting?: InputMaybe<Goalsetting>;
  prepayment?: InputMaybe<Scalars['Boolean']['input']>;
  ProductChangeLog?: InputMaybe<ProductChangeLogCreateNestedManyWithoutOrganizationInput>;
  products?: InputMaybe<ProductCreateNestedManyWithoutOrganizationInput>;
  re_cold_call_hour_delay?: InputMaybe<Scalars['Float']['input']>;
  recordingStatus?: InputMaybe<RecordingStatus>;
  release_lead_resting?: InputMaybe<Scalars['Int']['input']>;
  rep_claim_limit?: InputMaybe<Scalars['Int']['input']>;
  rep_lead_allotment?: InputMaybe<Scalars['Int']['input']>;
  rep_level_call_transfer?: InputMaybe<Scalars['Boolean']['input']>;
  reps_can_bulk_unassign?: InputMaybe<Scalars['Boolean']['input']>;
  reps_can_view_all_notes?: InputMaybe<Scalars['Boolean']['input']>;
  require_sale_notes?: InputMaybe<Scalars['Boolean']['input']>;
  resting_after_skipped_CC?: InputMaybe<Scalars['Int']['input']>;
  resting_period?: InputMaybe<Scalars['Int']['input']>;
  resting_period_after_unsuccessful_cold_call?: InputMaybe<Scalars['Float']['input']>;
  resting_period_after_unsuccessful_demo?: InputMaybe<Scalars['Float']['input']>;
  revenue_plan?: InputMaybe<RevenuePlanItemCreateNestedManyWithoutOrganizationInput>;
  Routings?: InputMaybe<RoutingCreateNestedManyWithoutOrganizationInput>;
  sale_field_options?: InputMaybe<SaleFieldOptionCreateNestedManyWithoutOrganizationInput>;
  sale_notification_email_list?: InputMaybe<OrganizationCreatesale_Notification_Email_ListInput>;
  SaleConfig?: InputMaybe<SaleConfigCreateNestedOneWithoutOrganizationInput>;
  SaleConfigPage?: InputMaybe<SaleConfigPageCreateNestedManyWithoutOrganizationInput>;
  SaleConfigSection?: InputMaybe<SaleConfigSectionCreateNestedManyWithoutOrganizationInput>;
  SaleCycles?: InputMaybe<SaleCycleCreateNestedManyWithoutOrganizationInput>;
  SaleItems?: InputMaybe<SaleItemCreateNestedManyWithoutOrganizationInput>;
  sales?: InputMaybe<SaleCreateNestedManyWithoutOrganizationInput>;
  salesforce_domain?: InputMaybe<Scalars['String']['input']>;
  salesforce_last_sync_date?: InputMaybe<Scalars['DateTime']['input']>;
  SalesForceColumn?: InputMaybe<SalesForceColumnCreateNestedManyWithoutOrganizationInput>;
  salesForceData?: InputMaybe<SalesForceDataCreateNestedOneWithoutOrganizationInput>;
  ScheduleItems?: InputMaybe<ScheduleItemCreateNestedManyWithoutOrganizationInput>;
  self_sourcing_rule?: InputMaybe<Scalars['Int']['input']>;
  send_error_log_email?: InputMaybe<Scalars['Boolean']['input']>;
  send_lead_routing_email?: InputMaybe<Scalars['Boolean']['input']>;
  sendgrid_email_domain?: InputMaybe<Scalars['String']['input']>;
  sendgrid_email_domain_id?: InputMaybe<Scalars['Int']['input']>;
  sequence?: InputMaybe<SequenceCreateNestedManyWithoutOrganzationInput>;
  SequenceCriteriaConditions?: InputMaybe<SequenceCriteriaConditionCreateNestedManyWithoutOrganizationInput>;
  SequenceDashboardViews?: InputMaybe<SequenceDashboardViewCreateNestedManyWithoutOrganizationInput>;
  SequenceEntryCriterias?: InputMaybe<SequenceEntryCriteriaCreateNestedManyWithoutOrganizationInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutOrganizationInput>;
  SequenceSteps?: InputMaybe<SequenceStepCreateNestedManyWithoutOrganizationInput>;
  set_goal?: InputMaybe<Scalars['Float']['input']>;
  short_resting?: InputMaybe<Scalars['Int']['input']>;
  show_calendar_options?: InputMaybe<Scalars['Boolean']['input']>;
  show_contract_duration_sales?: InputMaybe<Scalars['Boolean']['input']>;
  show_lead_source_to_rep?: InputMaybe<Scalars['Boolean']['input']>;
  show_nocontact_button?: InputMaybe<Scalars['Boolean']['input']>;
  show_payment_terms_sales?: InputMaybe<Scalars['Boolean']['input']>;
  show_rep_all_leads?: InputMaybe<Scalars['Boolean']['input']>;
  show_rep_my_leads?: InputMaybe<Scalars['Boolean']['input']>;
  show_system_view?: InputMaybe<Scalars['Boolean']['input']>;
  sites?: InputMaybe<SiteCreateNestedManyWithoutOrganizationInput>;
  sms_opt_in_dialog?: InputMaybe<Scalars['String']['input']>;
  SMSItems?: InputMaybe<SmsItemCreateNestedManyWithoutOrganizationInput>;
  SnoozeTrackings?: InputMaybe<SnoozeTrackingCreateNestedManyWithoutOrganizationInput>;
  StepActionLogs?: InputMaybe<StepActionLogCreateNestedManyWithoutOrganizationInput>;
  StepActions?: InputMaybe<StepActionCreateNestedManyWithoutOrganizationInput>;
  StepConditions?: InputMaybe<StepConditionCreateNestedManyWithoutOrganizationInput>;
  teams?: InputMaybe<TeamCreateNestedManyWithoutOrganizationInput>;
  Template?: InputMaybe<TemplateCreateNestedManyWithoutOrganizationInput>;
  TemplateVersions?: InputMaybe<TemplateVersionCreateNestedManyWithoutOrganizationInput>;
  timekit_id?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  transfer_timeout?: InputMaybe<Scalars['Int']['input']>;
  TransferAttempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutOrganizationInput>;
  twilio_numbers?: InputMaybe<TwilioNumberCreateNestedManyWithoutOrganizationInput>;
  TwilioFeedbacks?: InputMaybe<TwilioFeedbackCreateNestedManyWithoutOrganizationInput>;
  TwilioInfo?: InputMaybe<TwilioInfoCreateNestedOneWithoutOrganzationInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  use_revenue_planning?: InputMaybe<Scalars['Boolean']['input']>;
  UserCustomFieldFilters?: InputMaybe<UserCustomFieldFilterCreateNestedManyWithoutOrganizationInput>;
  users?: InputMaybe<UserCreateNestedManyWithoutOrganizationInput>;
  win_rep_day_goal?: InputMaybe<Scalars['Float']['input']>;
};

export type OrganizationCreateWithoutBest_MetricsInput = {
  ActivityMonitorMultiplier?: InputMaybe<ActivityMonitorMultiplierCreateNestedManyWithoutOrganizationInput>;
  algoFactor?: InputMaybe<AlgoFactorCreateNestedManyWithoutOrganizationInput>;
  allow_calendar_invite?: InputMaybe<Scalars['Boolean']['input']>;
  allow_remove_step_action?: InputMaybe<Scalars['Boolean']['input']>;
  allow_snooze_step_action?: InputMaybe<Scalars['Boolean']['input']>;
  always_record?: InputMaybe<Scalars['Boolean']['input']>;
  arv_initial_held_demos_generated_by_sdr_weight?: InputMaybe<Scalars['Float']['input']>;
  AssociationHistories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutOrganizationInput>;
  auto_call_interval?: InputMaybe<Scalars['Int']['input']>;
  auto_dial?: InputMaybe<Scalars['Boolean']['input']>;
  automated_campaigns?: InputMaybe<AutomatedCampaignCreateNestedManyWithoutOrganizationInput>;
  automatic_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  AutoTransferSelections?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutOrganizationInput>;
  Availablities?: InputMaybe<AvailabilityCreateNestedManyWithoutOrganizationInput>;
  AverageOrderValue?: InputMaybe<AverageOrderValueCreateNestedManyWithoutOrganizationInput>;
  best_action_ever_multiplier?: InputMaybe<Scalars['Float']['input']>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptCreateNestedManyWithoutOrganizationInput>;
  callMeNows?: InputMaybe<CallMeNowCreateNestedManyWithoutOrganizationInput>;
  CallMultiplier?: InputMaybe<CallMultiplierCreateNestedManyWithoutOrganizationInput>;
  CallSharings?: InputMaybe<CallSharingCreateNestedManyWithoutOrganizationInput>;
  can_edit_sdr_transfer_rules?: InputMaybe<Scalars['Boolean']['input']>;
  claim_rule?: InputMaybe<Scalars['Int']['input']>;
  concession?: InputMaybe<ConcessionCreateNestedManyWithoutOrganizationInput>;
  conference_region?: InputMaybe<ConferenceRegion>;
  Conferences?: InputMaybe<ConferenceCreateNestedManyWithoutOrganizationInput>;
  ContactRequests?: InputMaybe<ContactRequestCreateNestedManyWithoutOrganizationInput>;
  Contacts?: InputMaybe<ContactCreateNestedManyWithoutOrganizationInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  custom_fields?: InputMaybe<CustomFieldCreateNestedManyWithoutOrganizationInput>;
  CustomObject?: InputMaybe<CustomObjectCreateNestedManyWithoutOrganizationInput>;
  CustomObjectField?: InputMaybe<CustomObjectFieldCreateNestedManyWithoutOrganizationInput>;
  CustomObjectFieldForeignKey?: InputMaybe<CustomObjectFieldForeignKeyCreateNestedManyWithoutOrganizationInput>;
  CustomObjectRecord?: InputMaybe<CustomObjectRecordCreateNestedManyWithoutOrganizationInput>;
  CustomObjectRow?: InputMaybe<CustomObjectRowCreateNestedManyWithoutOrganizationInput>;
  CustomQueueItems?: InputMaybe<CustomQueueItemCreateNestedManyWithoutOrganizationInput>;
  DashboardColumnOptions?: InputMaybe<DashboardColumnOptionCreateNestedManyWithoutOrganizationInput>;
  DashboardLeadFilters?: InputMaybe<DashboardLeadFilterCreateNestedManyWithoutOrganizationInput>;
  DashboardRepFilters?: InputMaybe<DashboardRepFilterCreateNestedManyWithoutOrganizationInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewCreateNestedManyWithoutOrganizationInput>;
  day_to_close?: InputMaybe<Scalars['Int']['input']>;
  default_demo_time_duration?: InputMaybe<Scalars['Int']['input']>;
  demo_held_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_not_held_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_not_held_call_back_short_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_prep_time_minutes?: InputMaybe<Scalars['Int']['input']>;
  dev_account?: InputMaybe<Scalars['Boolean']['input']>;
  dial_rule?: InputMaybe<Scalars['Int']['input']>;
  DispositionMultiplier?: InputMaybe<DispositionMultiplierCreateNestedManyWithoutOrganizationInput>;
  dispositions?: InputMaybe<DispositionCreateNestedManyWithoutOrganizationInput>;
  double_booking?: InputMaybe<Scalars['Boolean']['input']>;
  dynamic_date_operators?: InputMaybe<Scalars['Boolean']['input']>;
  edge_servers?: InputMaybe<OrganizationCreateedge_ServersInput>;
  email_conflict_notification_email_list?: InputMaybe<OrganizationCreateemail_Conflict_Notification_Email_ListInput>;
  EmailItems?: InputMaybe<EmailItemCreateNestedManyWithoutOrganizationInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordCreateNestedManyWithoutOrganizationInput>;
  EmailThreads?: InputMaybe<EmailThreadCreateNestedManyWithoutOrganizationInput>;
  enable_modified_metrics?: InputMaybe<Scalars['Boolean']['input']>;
  enable_pay_period_view?: InputMaybe<Scalars['Boolean']['input']>;
  enable_time_constraint?: InputMaybe<Scalars['Boolean']['input']>;
  error_log_email_address?: InputMaybe<OrganizationCreateerror_Log_Email_AddressInput>;
  estimated_average_num_of_weekly_overbooked_demo_slots?: InputMaybe<Scalars['Int']['input']>;
  FolderPermissions?: InputMaybe<FolderPermissionCreateNestedManyWithoutOrganizationInput>;
  Folders?: InputMaybe<FolderCreateNestedManyWithoutOrganizationInput>;
  FutureBookingSetting?: InputMaybe<FutureBookingSettingCreateNestedOneWithoutOrganizationInput>;
  GlobalTransferRule?: InputMaybe<GlobalTransferRuleCreateNestedManyWithoutOrganizationInput>;
  held_demos_per_cc_connect?: InputMaybe<Scalars['Float']['input']>;
  helpful_links?: InputMaybe<OrganizationCreatehelpful_LinksInput>;
  HingePointMultiplier?: InputMaybe<HingePointMultiplierCreateNestedManyWithoutOrganizationInput>;
  hold_goal?: InputMaybe<Scalars['Float']['input']>;
  hubspot_credential?: InputMaybe<HubSpotCredentialCreateNestedOneWithoutOrganizationInput>;
  hubspot_last_sync_date?: InputMaybe<Scalars['DateTime']['input']>;
  hubspot_white_list?: InputMaybe<HubSpotWhitelistCreateNestedManyWithoutOrganizationInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  inbound_close_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_dm_connect_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_lt_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_revenue_value_of_sale?: InputMaybe<Scalars['Float']['input']>;
  inbound_scheduled_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_set_rate?: InputMaybe<Scalars['Float']['input']>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventCreateNestedManyWithoutOrganizationInput>;
  InboundConciergeWidget?: InputMaybe<InboundConciergeWidgetCreateNestedManyWithoutOrganizationInput>;
  industry_options?: InputMaybe<IndustryOptionCreateNestedManyWithoutOrganizationInput>;
  initial_sdr_sets_reps_day?: InputMaybe<Scalars['Float']['input']>;
  integration_error_logs?: InputMaybe<IntegrationErrorLogCreateNestedManyWithoutOrganizationInput>;
  integration_mappings?: InputMaybe<IntegrationMappingCreateNestedManyWithoutOrganizationInput>;
  IntegrationMappingCustomObject?: InputMaybe<IntegrationMappingCustomObjectCreateNestedManyWithoutOrganizationInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogCreateNestedManyWithoutOrganizationInput>;
  IntegrationReadOnlyObject?: InputMaybe<IntegrationReadOnlyObjectCreateNestedManyWithoutOrganizationInput>;
  international?: InputMaybe<Scalars['Boolean']['input']>;
  is_email_domain_verified?: InputMaybe<Scalars['Boolean']['input']>;
  is_salesforce_sandbox?: InputMaybe<Scalars['Boolean']['input']>;
  last_lead_value_compute_time?: InputMaybe<Scalars['DateTime']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutOrganizationInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryCreateNestedManyWithoutOrganizationInput>;
  lead_intent?: InputMaybe<LeadIntentCreateNestedManyWithoutOrganizationInput>;
  lead_release_same_rep_delay_rule?: InputMaybe<Scalars['Int']['input']>;
  lead_routing_email_list?: InputMaybe<OrganizationCreatelead_Routing_Email_ListInput>;
  lead_routing_rules?: InputMaybe<RuleCreateNestedManyWithoutOrganizationInput>;
  LeadCustomObjectRowAssociation?: InputMaybe<LeadCustomObjectRowAssociationCreateNestedManyWithoutOrganizationInput>;
  LeadFieldMultiplier?: InputMaybe<LeadFieldMultiplierCreateNestedManyWithoutOrganizationInput>;
  LeadFieldMultiplierCustom?: InputMaybe<LeadFieldMultiplierCustomCreateNestedManyWithoutOrganizationInput>;
  leads?: InputMaybe<LeadCreateNestedManyWithoutOrganizationInput>;
  leadsource_options?: InputMaybe<LeadSourceOptionCreateNestedManyWithoutOrganizationInput>;
  LeadValue?: InputMaybe<LeadValueCreateNestedManyWithoutOrganizationInput>;
  LifeTimeValueMultiplier?: InputMaybe<LifeTimeValueMultiplierCreateNestedManyWithoutOrganizationInput>;
  LiveTransferSetting?: InputMaybe<LiveTransferSettingCreateNestedOneWithoutOrganizationInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusCreateNestedManyWithoutOrganizationInput>;
  ltv_multiplier_custom_fields?: InputMaybe<LifeTimeValueMultiplierCustomCreateNestedManyWithoutOrganizationInput>;
  made_contact_rule?: InputMaybe<Scalars['Int']['input']>;
  make_sale_address_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_business_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_city_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_country_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_first_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_industry_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_last_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_lead_source_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_primary_email_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_primary_phone_number_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_state_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_sub_industry_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_zip_required?: InputMaybe<Scalars['Boolean']['input']>;
  maxium_day_lag_set_to_schedule?: InputMaybe<Scalars['Int']['input']>;
  mrr?: InputMaybe<Scalars['Int']['input']>;
  mrr_label?: InputMaybe<Scalars['String']['input']>;
  mrr_required_on_sale?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  NoteItems?: InputMaybe<NoteItemCreateNestedManyWithoutOrganizationInput>;
  Notifications?: InputMaybe<NotificationCreateNestedManyWithoutOrganizationInput>;
  onboardComplete?: InputMaybe<Scalars['Boolean']['input']>;
  Opportunity?: InputMaybe<SfOpportunityCreateNestedManyWithoutOrganizationInput>;
  organization_holidays?: InputMaybe<OrganizationHolidayCreateNestedManyWithoutOrganizationInput>;
  organization_hours?: InputMaybe<OrganizationHourCreateNestedManyWithoutOrganizationInput>;
  OrganizationPaymentSetting?: InputMaybe<OrganizationPaymentSettingsCreateNestedOneWithoutOrganizationInput>;
  OrganizationPaymentSettings?: InputMaybe<OrganizationPaymentSettingsCreateNestedOneWithoutOrganizationsInput>;
  orgCalendarInviteMetadata?: InputMaybe<OrgCalendarInviteMetadataCreateNestedManyWithoutOrganizationInput>;
  OrgDefaultPipelinePerecentValue?: InputMaybe<OrgDefaultPipelinePerecentValueCreateNestedOneWithoutOrganizationInput>;
  OrgHubSpotSync?: InputMaybe<OrgHubSpotSyncCreateNestedOneWithoutOrganizationInput>;
  OrgNoEventDate?: InputMaybe<OrgNoEventDateCreateNestedManyWithoutOrganizationInput>;
  OrgSalesforceSync?: InputMaybe<OrgSalesforceSyncCreateNestedOneWithoutOrganizationInput>;
  OrgTimeslotAvailability?: InputMaybe<OrgTimeslotAvailabilityCreateNestedManyWithoutOrganizationInput>;
  outbound_close_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_dm_connect_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_lt_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_revenue_value_of_sale?: InputMaybe<Scalars['Float']['input']>;
  outbound_scheduled_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_set_rate?: InputMaybe<Scalars['Float']['input']>;
  PandadocData?: InputMaybe<PandadocDataCreateNestedOneWithoutOrganizationInput>;
  PandaDocTemplate?: InputMaybe<PandaDocTemplateCreateNestedManyWithoutOrganizationInput>;
  participant_jitter_buffer_size?: InputMaybe<JitterBufferSize>;
  preferred_goal_setting?: InputMaybe<Goalsetting>;
  prepayment?: InputMaybe<Scalars['Boolean']['input']>;
  ProductChangeLog?: InputMaybe<ProductChangeLogCreateNestedManyWithoutOrganizationInput>;
  products?: InputMaybe<ProductCreateNestedManyWithoutOrganizationInput>;
  re_cold_call_hour_delay?: InputMaybe<Scalars['Float']['input']>;
  recordingStatus?: InputMaybe<RecordingStatus>;
  release_lead_resting?: InputMaybe<Scalars['Int']['input']>;
  rep_claim_limit?: InputMaybe<Scalars['Int']['input']>;
  rep_lead_allotment?: InputMaybe<Scalars['Int']['input']>;
  rep_level_call_transfer?: InputMaybe<Scalars['Boolean']['input']>;
  reps_can_bulk_unassign?: InputMaybe<Scalars['Boolean']['input']>;
  reps_can_view_all_notes?: InputMaybe<Scalars['Boolean']['input']>;
  require_sale_notes?: InputMaybe<Scalars['Boolean']['input']>;
  resting_after_skipped_CC?: InputMaybe<Scalars['Int']['input']>;
  resting_period?: InputMaybe<Scalars['Int']['input']>;
  resting_period_after_unsuccessful_cold_call?: InputMaybe<Scalars['Float']['input']>;
  resting_period_after_unsuccessful_demo?: InputMaybe<Scalars['Float']['input']>;
  revenue_plan?: InputMaybe<RevenuePlanItemCreateNestedManyWithoutOrganizationInput>;
  Routings?: InputMaybe<RoutingCreateNestedManyWithoutOrganizationInput>;
  sale_field_options?: InputMaybe<SaleFieldOptionCreateNestedManyWithoutOrganizationInput>;
  sale_notification_email_list?: InputMaybe<OrganizationCreatesale_Notification_Email_ListInput>;
  SaleConfig?: InputMaybe<SaleConfigCreateNestedOneWithoutOrganizationInput>;
  SaleConfigPage?: InputMaybe<SaleConfigPageCreateNestedManyWithoutOrganizationInput>;
  SaleConfigSection?: InputMaybe<SaleConfigSectionCreateNestedManyWithoutOrganizationInput>;
  SaleCycles?: InputMaybe<SaleCycleCreateNestedManyWithoutOrganizationInput>;
  SaleItems?: InputMaybe<SaleItemCreateNestedManyWithoutOrganizationInput>;
  sales?: InputMaybe<SaleCreateNestedManyWithoutOrganizationInput>;
  salesforce_domain?: InputMaybe<Scalars['String']['input']>;
  salesforce_last_sync_date?: InputMaybe<Scalars['DateTime']['input']>;
  SalesForceColumn?: InputMaybe<SalesForceColumnCreateNestedManyWithoutOrganizationInput>;
  salesForceData?: InputMaybe<SalesForceDataCreateNestedOneWithoutOrganizationInput>;
  ScheduleItems?: InputMaybe<ScheduleItemCreateNestedManyWithoutOrganizationInput>;
  self_sourcing_rule?: InputMaybe<Scalars['Int']['input']>;
  send_error_log_email?: InputMaybe<Scalars['Boolean']['input']>;
  send_lead_routing_email?: InputMaybe<Scalars['Boolean']['input']>;
  sendgrid_email_domain?: InputMaybe<Scalars['String']['input']>;
  sendgrid_email_domain_id?: InputMaybe<Scalars['Int']['input']>;
  sequence?: InputMaybe<SequenceCreateNestedManyWithoutOrganzationInput>;
  SequenceCriteriaConditions?: InputMaybe<SequenceCriteriaConditionCreateNestedManyWithoutOrganizationInput>;
  SequenceDashboardViews?: InputMaybe<SequenceDashboardViewCreateNestedManyWithoutOrganizationInput>;
  SequenceEntryCriterias?: InputMaybe<SequenceEntryCriteriaCreateNestedManyWithoutOrganizationInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutOrganizationInput>;
  SequenceSteps?: InputMaybe<SequenceStepCreateNestedManyWithoutOrganizationInput>;
  set_goal?: InputMaybe<Scalars['Float']['input']>;
  short_resting?: InputMaybe<Scalars['Int']['input']>;
  show_calendar_options?: InputMaybe<Scalars['Boolean']['input']>;
  show_contract_duration_sales?: InputMaybe<Scalars['Boolean']['input']>;
  show_lead_source_to_rep?: InputMaybe<Scalars['Boolean']['input']>;
  show_nocontact_button?: InputMaybe<Scalars['Boolean']['input']>;
  show_payment_terms_sales?: InputMaybe<Scalars['Boolean']['input']>;
  show_rep_all_leads?: InputMaybe<Scalars['Boolean']['input']>;
  show_rep_my_leads?: InputMaybe<Scalars['Boolean']['input']>;
  show_system_view?: InputMaybe<Scalars['Boolean']['input']>;
  sites?: InputMaybe<SiteCreateNestedManyWithoutOrganizationInput>;
  sms_opt_in_dialog?: InputMaybe<Scalars['String']['input']>;
  SMSItems?: InputMaybe<SmsItemCreateNestedManyWithoutOrganizationInput>;
  SnoozeTrackings?: InputMaybe<SnoozeTrackingCreateNestedManyWithoutOrganizationInput>;
  StepActionLogs?: InputMaybe<StepActionLogCreateNestedManyWithoutOrganizationInput>;
  StepActions?: InputMaybe<StepActionCreateNestedManyWithoutOrganizationInput>;
  StepConditions?: InputMaybe<StepConditionCreateNestedManyWithoutOrganizationInput>;
  teams?: InputMaybe<TeamCreateNestedManyWithoutOrganizationInput>;
  Template?: InputMaybe<TemplateCreateNestedManyWithoutOrganizationInput>;
  TemplateVersions?: InputMaybe<TemplateVersionCreateNestedManyWithoutOrganizationInput>;
  timekit_id?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  transfer_timeout?: InputMaybe<Scalars['Int']['input']>;
  TransferAttempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutOrganizationInput>;
  twilio_numbers?: InputMaybe<TwilioNumberCreateNestedManyWithoutOrganizationInput>;
  TwilioFeedbacks?: InputMaybe<TwilioFeedbackCreateNestedManyWithoutOrganizationInput>;
  TwilioInfo?: InputMaybe<TwilioInfoCreateNestedOneWithoutOrganzationInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  use_revenue_planning?: InputMaybe<Scalars['Boolean']['input']>;
  UserCustomFieldFilters?: InputMaybe<UserCustomFieldFilterCreateNestedManyWithoutOrganizationInput>;
  users?: InputMaybe<UserCreateNestedManyWithoutOrganizationInput>;
  win_rep_day_goal?: InputMaybe<Scalars['Float']['input']>;
};

export type OrganizationCreateWithoutCallMeNowAttemptsInput = {
  ActivityMonitorMultiplier?: InputMaybe<ActivityMonitorMultiplierCreateNestedManyWithoutOrganizationInput>;
  algoFactor?: InputMaybe<AlgoFactorCreateNestedManyWithoutOrganizationInput>;
  allow_calendar_invite?: InputMaybe<Scalars['Boolean']['input']>;
  allow_remove_step_action?: InputMaybe<Scalars['Boolean']['input']>;
  allow_snooze_step_action?: InputMaybe<Scalars['Boolean']['input']>;
  always_record?: InputMaybe<Scalars['Boolean']['input']>;
  arv_initial_held_demos_generated_by_sdr_weight?: InputMaybe<Scalars['Float']['input']>;
  AssociationHistories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutOrganizationInput>;
  auto_call_interval?: InputMaybe<Scalars['Int']['input']>;
  auto_dial?: InputMaybe<Scalars['Boolean']['input']>;
  automated_campaigns?: InputMaybe<AutomatedCampaignCreateNestedManyWithoutOrganizationInput>;
  automatic_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  AutoTransferSelections?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutOrganizationInput>;
  Availablities?: InputMaybe<AvailabilityCreateNestedManyWithoutOrganizationInput>;
  AverageOrderValue?: InputMaybe<AverageOrderValueCreateNestedManyWithoutOrganizationInput>;
  best_action_ever_multiplier?: InputMaybe<Scalars['Float']['input']>;
  best_metrics?: InputMaybe<BestMetricRecordCreateNestedManyWithoutOrganizationInput>;
  callMeNows?: InputMaybe<CallMeNowCreateNestedManyWithoutOrganizationInput>;
  CallMultiplier?: InputMaybe<CallMultiplierCreateNestedManyWithoutOrganizationInput>;
  CallSharings?: InputMaybe<CallSharingCreateNestedManyWithoutOrganizationInput>;
  can_edit_sdr_transfer_rules?: InputMaybe<Scalars['Boolean']['input']>;
  claim_rule?: InputMaybe<Scalars['Int']['input']>;
  concession?: InputMaybe<ConcessionCreateNestedManyWithoutOrganizationInput>;
  conference_region?: InputMaybe<ConferenceRegion>;
  Conferences?: InputMaybe<ConferenceCreateNestedManyWithoutOrganizationInput>;
  ContactRequests?: InputMaybe<ContactRequestCreateNestedManyWithoutOrganizationInput>;
  Contacts?: InputMaybe<ContactCreateNestedManyWithoutOrganizationInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  custom_fields?: InputMaybe<CustomFieldCreateNestedManyWithoutOrganizationInput>;
  CustomObject?: InputMaybe<CustomObjectCreateNestedManyWithoutOrganizationInput>;
  CustomObjectField?: InputMaybe<CustomObjectFieldCreateNestedManyWithoutOrganizationInput>;
  CustomObjectFieldForeignKey?: InputMaybe<CustomObjectFieldForeignKeyCreateNestedManyWithoutOrganizationInput>;
  CustomObjectRecord?: InputMaybe<CustomObjectRecordCreateNestedManyWithoutOrganizationInput>;
  CustomObjectRow?: InputMaybe<CustomObjectRowCreateNestedManyWithoutOrganizationInput>;
  CustomQueueItems?: InputMaybe<CustomQueueItemCreateNestedManyWithoutOrganizationInput>;
  DashboardColumnOptions?: InputMaybe<DashboardColumnOptionCreateNestedManyWithoutOrganizationInput>;
  DashboardLeadFilters?: InputMaybe<DashboardLeadFilterCreateNestedManyWithoutOrganizationInput>;
  DashboardRepFilters?: InputMaybe<DashboardRepFilterCreateNestedManyWithoutOrganizationInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewCreateNestedManyWithoutOrganizationInput>;
  day_to_close?: InputMaybe<Scalars['Int']['input']>;
  default_demo_time_duration?: InputMaybe<Scalars['Int']['input']>;
  demo_held_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_not_held_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_not_held_call_back_short_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_prep_time_minutes?: InputMaybe<Scalars['Int']['input']>;
  dev_account?: InputMaybe<Scalars['Boolean']['input']>;
  dial_rule?: InputMaybe<Scalars['Int']['input']>;
  DispositionMultiplier?: InputMaybe<DispositionMultiplierCreateNestedManyWithoutOrganizationInput>;
  dispositions?: InputMaybe<DispositionCreateNestedManyWithoutOrganizationInput>;
  double_booking?: InputMaybe<Scalars['Boolean']['input']>;
  dynamic_date_operators?: InputMaybe<Scalars['Boolean']['input']>;
  edge_servers?: InputMaybe<OrganizationCreateedge_ServersInput>;
  email_conflict_notification_email_list?: InputMaybe<OrganizationCreateemail_Conflict_Notification_Email_ListInput>;
  EmailItems?: InputMaybe<EmailItemCreateNestedManyWithoutOrganizationInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordCreateNestedManyWithoutOrganizationInput>;
  EmailThreads?: InputMaybe<EmailThreadCreateNestedManyWithoutOrganizationInput>;
  enable_modified_metrics?: InputMaybe<Scalars['Boolean']['input']>;
  enable_pay_period_view?: InputMaybe<Scalars['Boolean']['input']>;
  enable_time_constraint?: InputMaybe<Scalars['Boolean']['input']>;
  error_log_email_address?: InputMaybe<OrganizationCreateerror_Log_Email_AddressInput>;
  estimated_average_num_of_weekly_overbooked_demo_slots?: InputMaybe<Scalars['Int']['input']>;
  FolderPermissions?: InputMaybe<FolderPermissionCreateNestedManyWithoutOrganizationInput>;
  Folders?: InputMaybe<FolderCreateNestedManyWithoutOrganizationInput>;
  FutureBookingSetting?: InputMaybe<FutureBookingSettingCreateNestedOneWithoutOrganizationInput>;
  GlobalTransferRule?: InputMaybe<GlobalTransferRuleCreateNestedManyWithoutOrganizationInput>;
  held_demos_per_cc_connect?: InputMaybe<Scalars['Float']['input']>;
  helpful_links?: InputMaybe<OrganizationCreatehelpful_LinksInput>;
  HingePointMultiplier?: InputMaybe<HingePointMultiplierCreateNestedManyWithoutOrganizationInput>;
  hold_goal?: InputMaybe<Scalars['Float']['input']>;
  hubspot_credential?: InputMaybe<HubSpotCredentialCreateNestedOneWithoutOrganizationInput>;
  hubspot_last_sync_date?: InputMaybe<Scalars['DateTime']['input']>;
  hubspot_white_list?: InputMaybe<HubSpotWhitelistCreateNestedManyWithoutOrganizationInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  inbound_close_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_dm_connect_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_lt_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_revenue_value_of_sale?: InputMaybe<Scalars['Float']['input']>;
  inbound_scheduled_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_set_rate?: InputMaybe<Scalars['Float']['input']>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventCreateNestedManyWithoutOrganizationInput>;
  InboundConciergeWidget?: InputMaybe<InboundConciergeWidgetCreateNestedManyWithoutOrganizationInput>;
  industry_options?: InputMaybe<IndustryOptionCreateNestedManyWithoutOrganizationInput>;
  initial_sdr_sets_reps_day?: InputMaybe<Scalars['Float']['input']>;
  integration_error_logs?: InputMaybe<IntegrationErrorLogCreateNestedManyWithoutOrganizationInput>;
  integration_mappings?: InputMaybe<IntegrationMappingCreateNestedManyWithoutOrganizationInput>;
  IntegrationMappingCustomObject?: InputMaybe<IntegrationMappingCustomObjectCreateNestedManyWithoutOrganizationInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogCreateNestedManyWithoutOrganizationInput>;
  IntegrationReadOnlyObject?: InputMaybe<IntegrationReadOnlyObjectCreateNestedManyWithoutOrganizationInput>;
  international?: InputMaybe<Scalars['Boolean']['input']>;
  is_email_domain_verified?: InputMaybe<Scalars['Boolean']['input']>;
  is_salesforce_sandbox?: InputMaybe<Scalars['Boolean']['input']>;
  last_lead_value_compute_time?: InputMaybe<Scalars['DateTime']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutOrganizationInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryCreateNestedManyWithoutOrganizationInput>;
  lead_intent?: InputMaybe<LeadIntentCreateNestedManyWithoutOrganizationInput>;
  lead_release_same_rep_delay_rule?: InputMaybe<Scalars['Int']['input']>;
  lead_routing_email_list?: InputMaybe<OrganizationCreatelead_Routing_Email_ListInput>;
  lead_routing_rules?: InputMaybe<RuleCreateNestedManyWithoutOrganizationInput>;
  LeadCustomObjectRowAssociation?: InputMaybe<LeadCustomObjectRowAssociationCreateNestedManyWithoutOrganizationInput>;
  LeadFieldMultiplier?: InputMaybe<LeadFieldMultiplierCreateNestedManyWithoutOrganizationInput>;
  LeadFieldMultiplierCustom?: InputMaybe<LeadFieldMultiplierCustomCreateNestedManyWithoutOrganizationInput>;
  leads?: InputMaybe<LeadCreateNestedManyWithoutOrganizationInput>;
  leadsource_options?: InputMaybe<LeadSourceOptionCreateNestedManyWithoutOrganizationInput>;
  LeadValue?: InputMaybe<LeadValueCreateNestedManyWithoutOrganizationInput>;
  LifeTimeValueMultiplier?: InputMaybe<LifeTimeValueMultiplierCreateNestedManyWithoutOrganizationInput>;
  LiveTransferSetting?: InputMaybe<LiveTransferSettingCreateNestedOneWithoutOrganizationInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusCreateNestedManyWithoutOrganizationInput>;
  ltv_multiplier_custom_fields?: InputMaybe<LifeTimeValueMultiplierCustomCreateNestedManyWithoutOrganizationInput>;
  made_contact_rule?: InputMaybe<Scalars['Int']['input']>;
  make_sale_address_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_business_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_city_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_country_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_first_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_industry_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_last_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_lead_source_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_primary_email_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_primary_phone_number_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_state_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_sub_industry_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_zip_required?: InputMaybe<Scalars['Boolean']['input']>;
  maxium_day_lag_set_to_schedule?: InputMaybe<Scalars['Int']['input']>;
  mrr?: InputMaybe<Scalars['Int']['input']>;
  mrr_label?: InputMaybe<Scalars['String']['input']>;
  mrr_required_on_sale?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  NoteItems?: InputMaybe<NoteItemCreateNestedManyWithoutOrganizationInput>;
  Notifications?: InputMaybe<NotificationCreateNestedManyWithoutOrganizationInput>;
  onboardComplete?: InputMaybe<Scalars['Boolean']['input']>;
  Opportunity?: InputMaybe<SfOpportunityCreateNestedManyWithoutOrganizationInput>;
  organization_holidays?: InputMaybe<OrganizationHolidayCreateNestedManyWithoutOrganizationInput>;
  organization_hours?: InputMaybe<OrganizationHourCreateNestedManyWithoutOrganizationInput>;
  OrganizationPaymentSetting?: InputMaybe<OrganizationPaymentSettingsCreateNestedOneWithoutOrganizationInput>;
  OrganizationPaymentSettings?: InputMaybe<OrganizationPaymentSettingsCreateNestedOneWithoutOrganizationsInput>;
  orgCalendarInviteMetadata?: InputMaybe<OrgCalendarInviteMetadataCreateNestedManyWithoutOrganizationInput>;
  OrgDefaultPipelinePerecentValue?: InputMaybe<OrgDefaultPipelinePerecentValueCreateNestedOneWithoutOrganizationInput>;
  OrgHubSpotSync?: InputMaybe<OrgHubSpotSyncCreateNestedOneWithoutOrganizationInput>;
  OrgNoEventDate?: InputMaybe<OrgNoEventDateCreateNestedManyWithoutOrganizationInput>;
  OrgSalesforceSync?: InputMaybe<OrgSalesforceSyncCreateNestedOneWithoutOrganizationInput>;
  OrgTimeslotAvailability?: InputMaybe<OrgTimeslotAvailabilityCreateNestedManyWithoutOrganizationInput>;
  outbound_close_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_dm_connect_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_lt_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_revenue_value_of_sale?: InputMaybe<Scalars['Float']['input']>;
  outbound_scheduled_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_set_rate?: InputMaybe<Scalars['Float']['input']>;
  PandadocData?: InputMaybe<PandadocDataCreateNestedOneWithoutOrganizationInput>;
  PandaDocTemplate?: InputMaybe<PandaDocTemplateCreateNestedManyWithoutOrganizationInput>;
  participant_jitter_buffer_size?: InputMaybe<JitterBufferSize>;
  preferred_goal_setting?: InputMaybe<Goalsetting>;
  prepayment?: InputMaybe<Scalars['Boolean']['input']>;
  ProductChangeLog?: InputMaybe<ProductChangeLogCreateNestedManyWithoutOrganizationInput>;
  products?: InputMaybe<ProductCreateNestedManyWithoutOrganizationInput>;
  re_cold_call_hour_delay?: InputMaybe<Scalars['Float']['input']>;
  recordingStatus?: InputMaybe<RecordingStatus>;
  release_lead_resting?: InputMaybe<Scalars['Int']['input']>;
  rep_claim_limit?: InputMaybe<Scalars['Int']['input']>;
  rep_lead_allotment?: InputMaybe<Scalars['Int']['input']>;
  rep_level_call_transfer?: InputMaybe<Scalars['Boolean']['input']>;
  reps_can_bulk_unassign?: InputMaybe<Scalars['Boolean']['input']>;
  reps_can_view_all_notes?: InputMaybe<Scalars['Boolean']['input']>;
  require_sale_notes?: InputMaybe<Scalars['Boolean']['input']>;
  resting_after_skipped_CC?: InputMaybe<Scalars['Int']['input']>;
  resting_period?: InputMaybe<Scalars['Int']['input']>;
  resting_period_after_unsuccessful_cold_call?: InputMaybe<Scalars['Float']['input']>;
  resting_period_after_unsuccessful_demo?: InputMaybe<Scalars['Float']['input']>;
  revenue_plan?: InputMaybe<RevenuePlanItemCreateNestedManyWithoutOrganizationInput>;
  Routings?: InputMaybe<RoutingCreateNestedManyWithoutOrganizationInput>;
  sale_field_options?: InputMaybe<SaleFieldOptionCreateNestedManyWithoutOrganizationInput>;
  sale_notification_email_list?: InputMaybe<OrganizationCreatesale_Notification_Email_ListInput>;
  SaleConfig?: InputMaybe<SaleConfigCreateNestedOneWithoutOrganizationInput>;
  SaleConfigPage?: InputMaybe<SaleConfigPageCreateNestedManyWithoutOrganizationInput>;
  SaleConfigSection?: InputMaybe<SaleConfigSectionCreateNestedManyWithoutOrganizationInput>;
  SaleCycles?: InputMaybe<SaleCycleCreateNestedManyWithoutOrganizationInput>;
  SaleItems?: InputMaybe<SaleItemCreateNestedManyWithoutOrganizationInput>;
  sales?: InputMaybe<SaleCreateNestedManyWithoutOrganizationInput>;
  salesforce_domain?: InputMaybe<Scalars['String']['input']>;
  salesforce_last_sync_date?: InputMaybe<Scalars['DateTime']['input']>;
  SalesForceColumn?: InputMaybe<SalesForceColumnCreateNestedManyWithoutOrganizationInput>;
  salesForceData?: InputMaybe<SalesForceDataCreateNestedOneWithoutOrganizationInput>;
  ScheduleItems?: InputMaybe<ScheduleItemCreateNestedManyWithoutOrganizationInput>;
  self_sourcing_rule?: InputMaybe<Scalars['Int']['input']>;
  send_error_log_email?: InputMaybe<Scalars['Boolean']['input']>;
  send_lead_routing_email?: InputMaybe<Scalars['Boolean']['input']>;
  sendgrid_email_domain?: InputMaybe<Scalars['String']['input']>;
  sendgrid_email_domain_id?: InputMaybe<Scalars['Int']['input']>;
  sequence?: InputMaybe<SequenceCreateNestedManyWithoutOrganzationInput>;
  SequenceCriteriaConditions?: InputMaybe<SequenceCriteriaConditionCreateNestedManyWithoutOrganizationInput>;
  SequenceDashboardViews?: InputMaybe<SequenceDashboardViewCreateNestedManyWithoutOrganizationInput>;
  SequenceEntryCriterias?: InputMaybe<SequenceEntryCriteriaCreateNestedManyWithoutOrganizationInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutOrganizationInput>;
  SequenceSteps?: InputMaybe<SequenceStepCreateNestedManyWithoutOrganizationInput>;
  set_goal?: InputMaybe<Scalars['Float']['input']>;
  short_resting?: InputMaybe<Scalars['Int']['input']>;
  show_calendar_options?: InputMaybe<Scalars['Boolean']['input']>;
  show_contract_duration_sales?: InputMaybe<Scalars['Boolean']['input']>;
  show_lead_source_to_rep?: InputMaybe<Scalars['Boolean']['input']>;
  show_nocontact_button?: InputMaybe<Scalars['Boolean']['input']>;
  show_payment_terms_sales?: InputMaybe<Scalars['Boolean']['input']>;
  show_rep_all_leads?: InputMaybe<Scalars['Boolean']['input']>;
  show_rep_my_leads?: InputMaybe<Scalars['Boolean']['input']>;
  show_system_view?: InputMaybe<Scalars['Boolean']['input']>;
  sites?: InputMaybe<SiteCreateNestedManyWithoutOrganizationInput>;
  sms_opt_in_dialog?: InputMaybe<Scalars['String']['input']>;
  SMSItems?: InputMaybe<SmsItemCreateNestedManyWithoutOrganizationInput>;
  SnoozeTrackings?: InputMaybe<SnoozeTrackingCreateNestedManyWithoutOrganizationInput>;
  StepActionLogs?: InputMaybe<StepActionLogCreateNestedManyWithoutOrganizationInput>;
  StepActions?: InputMaybe<StepActionCreateNestedManyWithoutOrganizationInput>;
  StepConditions?: InputMaybe<StepConditionCreateNestedManyWithoutOrganizationInput>;
  teams?: InputMaybe<TeamCreateNestedManyWithoutOrganizationInput>;
  Template?: InputMaybe<TemplateCreateNestedManyWithoutOrganizationInput>;
  TemplateVersions?: InputMaybe<TemplateVersionCreateNestedManyWithoutOrganizationInput>;
  timekit_id?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  transfer_timeout?: InputMaybe<Scalars['Int']['input']>;
  TransferAttempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutOrganizationInput>;
  twilio_numbers?: InputMaybe<TwilioNumberCreateNestedManyWithoutOrganizationInput>;
  TwilioFeedbacks?: InputMaybe<TwilioFeedbackCreateNestedManyWithoutOrganizationInput>;
  TwilioInfo?: InputMaybe<TwilioInfoCreateNestedOneWithoutOrganzationInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  use_revenue_planning?: InputMaybe<Scalars['Boolean']['input']>;
  UserCustomFieldFilters?: InputMaybe<UserCustomFieldFilterCreateNestedManyWithoutOrganizationInput>;
  users?: InputMaybe<UserCreateNestedManyWithoutOrganizationInput>;
  win_rep_day_goal?: InputMaybe<Scalars['Float']['input']>;
};

export type OrganizationCreateWithoutCallMeNowsInput = {
  ActivityMonitorMultiplier?: InputMaybe<ActivityMonitorMultiplierCreateNestedManyWithoutOrganizationInput>;
  algoFactor?: InputMaybe<AlgoFactorCreateNestedManyWithoutOrganizationInput>;
  allow_calendar_invite?: InputMaybe<Scalars['Boolean']['input']>;
  allow_remove_step_action?: InputMaybe<Scalars['Boolean']['input']>;
  allow_snooze_step_action?: InputMaybe<Scalars['Boolean']['input']>;
  always_record?: InputMaybe<Scalars['Boolean']['input']>;
  arv_initial_held_demos_generated_by_sdr_weight?: InputMaybe<Scalars['Float']['input']>;
  AssociationHistories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutOrganizationInput>;
  auto_call_interval?: InputMaybe<Scalars['Int']['input']>;
  auto_dial?: InputMaybe<Scalars['Boolean']['input']>;
  automated_campaigns?: InputMaybe<AutomatedCampaignCreateNestedManyWithoutOrganizationInput>;
  automatic_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  AutoTransferSelections?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutOrganizationInput>;
  Availablities?: InputMaybe<AvailabilityCreateNestedManyWithoutOrganizationInput>;
  AverageOrderValue?: InputMaybe<AverageOrderValueCreateNestedManyWithoutOrganizationInput>;
  best_action_ever_multiplier?: InputMaybe<Scalars['Float']['input']>;
  best_metrics?: InputMaybe<BestMetricRecordCreateNestedManyWithoutOrganizationInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptCreateNestedManyWithoutOrganizationInput>;
  CallMultiplier?: InputMaybe<CallMultiplierCreateNestedManyWithoutOrganizationInput>;
  CallSharings?: InputMaybe<CallSharingCreateNestedManyWithoutOrganizationInput>;
  can_edit_sdr_transfer_rules?: InputMaybe<Scalars['Boolean']['input']>;
  claim_rule?: InputMaybe<Scalars['Int']['input']>;
  concession?: InputMaybe<ConcessionCreateNestedManyWithoutOrganizationInput>;
  conference_region?: InputMaybe<ConferenceRegion>;
  Conferences?: InputMaybe<ConferenceCreateNestedManyWithoutOrganizationInput>;
  ContactRequests?: InputMaybe<ContactRequestCreateNestedManyWithoutOrganizationInput>;
  Contacts?: InputMaybe<ContactCreateNestedManyWithoutOrganizationInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  custom_fields?: InputMaybe<CustomFieldCreateNestedManyWithoutOrganizationInput>;
  CustomObject?: InputMaybe<CustomObjectCreateNestedManyWithoutOrganizationInput>;
  CustomObjectField?: InputMaybe<CustomObjectFieldCreateNestedManyWithoutOrganizationInput>;
  CustomObjectFieldForeignKey?: InputMaybe<CustomObjectFieldForeignKeyCreateNestedManyWithoutOrganizationInput>;
  CustomObjectRecord?: InputMaybe<CustomObjectRecordCreateNestedManyWithoutOrganizationInput>;
  CustomObjectRow?: InputMaybe<CustomObjectRowCreateNestedManyWithoutOrganizationInput>;
  CustomQueueItems?: InputMaybe<CustomQueueItemCreateNestedManyWithoutOrganizationInput>;
  DashboardColumnOptions?: InputMaybe<DashboardColumnOptionCreateNestedManyWithoutOrganizationInput>;
  DashboardLeadFilters?: InputMaybe<DashboardLeadFilterCreateNestedManyWithoutOrganizationInput>;
  DashboardRepFilters?: InputMaybe<DashboardRepFilterCreateNestedManyWithoutOrganizationInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewCreateNestedManyWithoutOrganizationInput>;
  day_to_close?: InputMaybe<Scalars['Int']['input']>;
  default_demo_time_duration?: InputMaybe<Scalars['Int']['input']>;
  demo_held_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_not_held_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_not_held_call_back_short_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_prep_time_minutes?: InputMaybe<Scalars['Int']['input']>;
  dev_account?: InputMaybe<Scalars['Boolean']['input']>;
  dial_rule?: InputMaybe<Scalars['Int']['input']>;
  DispositionMultiplier?: InputMaybe<DispositionMultiplierCreateNestedManyWithoutOrganizationInput>;
  dispositions?: InputMaybe<DispositionCreateNestedManyWithoutOrganizationInput>;
  double_booking?: InputMaybe<Scalars['Boolean']['input']>;
  dynamic_date_operators?: InputMaybe<Scalars['Boolean']['input']>;
  edge_servers?: InputMaybe<OrganizationCreateedge_ServersInput>;
  email_conflict_notification_email_list?: InputMaybe<OrganizationCreateemail_Conflict_Notification_Email_ListInput>;
  EmailItems?: InputMaybe<EmailItemCreateNestedManyWithoutOrganizationInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordCreateNestedManyWithoutOrganizationInput>;
  EmailThreads?: InputMaybe<EmailThreadCreateNestedManyWithoutOrganizationInput>;
  enable_modified_metrics?: InputMaybe<Scalars['Boolean']['input']>;
  enable_pay_period_view?: InputMaybe<Scalars['Boolean']['input']>;
  enable_time_constraint?: InputMaybe<Scalars['Boolean']['input']>;
  error_log_email_address?: InputMaybe<OrganizationCreateerror_Log_Email_AddressInput>;
  estimated_average_num_of_weekly_overbooked_demo_slots?: InputMaybe<Scalars['Int']['input']>;
  FolderPermissions?: InputMaybe<FolderPermissionCreateNestedManyWithoutOrganizationInput>;
  Folders?: InputMaybe<FolderCreateNestedManyWithoutOrganizationInput>;
  FutureBookingSetting?: InputMaybe<FutureBookingSettingCreateNestedOneWithoutOrganizationInput>;
  GlobalTransferRule?: InputMaybe<GlobalTransferRuleCreateNestedManyWithoutOrganizationInput>;
  held_demos_per_cc_connect?: InputMaybe<Scalars['Float']['input']>;
  helpful_links?: InputMaybe<OrganizationCreatehelpful_LinksInput>;
  HingePointMultiplier?: InputMaybe<HingePointMultiplierCreateNestedManyWithoutOrganizationInput>;
  hold_goal?: InputMaybe<Scalars['Float']['input']>;
  hubspot_credential?: InputMaybe<HubSpotCredentialCreateNestedOneWithoutOrganizationInput>;
  hubspot_last_sync_date?: InputMaybe<Scalars['DateTime']['input']>;
  hubspot_white_list?: InputMaybe<HubSpotWhitelistCreateNestedManyWithoutOrganizationInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  inbound_close_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_dm_connect_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_lt_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_revenue_value_of_sale?: InputMaybe<Scalars['Float']['input']>;
  inbound_scheduled_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_set_rate?: InputMaybe<Scalars['Float']['input']>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventCreateNestedManyWithoutOrganizationInput>;
  InboundConciergeWidget?: InputMaybe<InboundConciergeWidgetCreateNestedManyWithoutOrganizationInput>;
  industry_options?: InputMaybe<IndustryOptionCreateNestedManyWithoutOrganizationInput>;
  initial_sdr_sets_reps_day?: InputMaybe<Scalars['Float']['input']>;
  integration_error_logs?: InputMaybe<IntegrationErrorLogCreateNestedManyWithoutOrganizationInput>;
  integration_mappings?: InputMaybe<IntegrationMappingCreateNestedManyWithoutOrganizationInput>;
  IntegrationMappingCustomObject?: InputMaybe<IntegrationMappingCustomObjectCreateNestedManyWithoutOrganizationInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogCreateNestedManyWithoutOrganizationInput>;
  IntegrationReadOnlyObject?: InputMaybe<IntegrationReadOnlyObjectCreateNestedManyWithoutOrganizationInput>;
  international?: InputMaybe<Scalars['Boolean']['input']>;
  is_email_domain_verified?: InputMaybe<Scalars['Boolean']['input']>;
  is_salesforce_sandbox?: InputMaybe<Scalars['Boolean']['input']>;
  last_lead_value_compute_time?: InputMaybe<Scalars['DateTime']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutOrganizationInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryCreateNestedManyWithoutOrganizationInput>;
  lead_intent?: InputMaybe<LeadIntentCreateNestedManyWithoutOrganizationInput>;
  lead_release_same_rep_delay_rule?: InputMaybe<Scalars['Int']['input']>;
  lead_routing_email_list?: InputMaybe<OrganizationCreatelead_Routing_Email_ListInput>;
  lead_routing_rules?: InputMaybe<RuleCreateNestedManyWithoutOrganizationInput>;
  LeadCustomObjectRowAssociation?: InputMaybe<LeadCustomObjectRowAssociationCreateNestedManyWithoutOrganizationInput>;
  LeadFieldMultiplier?: InputMaybe<LeadFieldMultiplierCreateNestedManyWithoutOrganizationInput>;
  LeadFieldMultiplierCustom?: InputMaybe<LeadFieldMultiplierCustomCreateNestedManyWithoutOrganizationInput>;
  leads?: InputMaybe<LeadCreateNestedManyWithoutOrganizationInput>;
  leadsource_options?: InputMaybe<LeadSourceOptionCreateNestedManyWithoutOrganizationInput>;
  LeadValue?: InputMaybe<LeadValueCreateNestedManyWithoutOrganizationInput>;
  LifeTimeValueMultiplier?: InputMaybe<LifeTimeValueMultiplierCreateNestedManyWithoutOrganizationInput>;
  LiveTransferSetting?: InputMaybe<LiveTransferSettingCreateNestedOneWithoutOrganizationInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusCreateNestedManyWithoutOrganizationInput>;
  ltv_multiplier_custom_fields?: InputMaybe<LifeTimeValueMultiplierCustomCreateNestedManyWithoutOrganizationInput>;
  made_contact_rule?: InputMaybe<Scalars['Int']['input']>;
  make_sale_address_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_business_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_city_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_country_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_first_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_industry_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_last_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_lead_source_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_primary_email_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_primary_phone_number_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_state_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_sub_industry_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_zip_required?: InputMaybe<Scalars['Boolean']['input']>;
  maxium_day_lag_set_to_schedule?: InputMaybe<Scalars['Int']['input']>;
  mrr?: InputMaybe<Scalars['Int']['input']>;
  mrr_label?: InputMaybe<Scalars['String']['input']>;
  mrr_required_on_sale?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  NoteItems?: InputMaybe<NoteItemCreateNestedManyWithoutOrganizationInput>;
  Notifications?: InputMaybe<NotificationCreateNestedManyWithoutOrganizationInput>;
  onboardComplete?: InputMaybe<Scalars['Boolean']['input']>;
  Opportunity?: InputMaybe<SfOpportunityCreateNestedManyWithoutOrganizationInput>;
  organization_holidays?: InputMaybe<OrganizationHolidayCreateNestedManyWithoutOrganizationInput>;
  organization_hours?: InputMaybe<OrganizationHourCreateNestedManyWithoutOrganizationInput>;
  OrganizationPaymentSetting?: InputMaybe<OrganizationPaymentSettingsCreateNestedOneWithoutOrganizationInput>;
  OrganizationPaymentSettings?: InputMaybe<OrganizationPaymentSettingsCreateNestedOneWithoutOrganizationsInput>;
  orgCalendarInviteMetadata?: InputMaybe<OrgCalendarInviteMetadataCreateNestedManyWithoutOrganizationInput>;
  OrgDefaultPipelinePerecentValue?: InputMaybe<OrgDefaultPipelinePerecentValueCreateNestedOneWithoutOrganizationInput>;
  OrgHubSpotSync?: InputMaybe<OrgHubSpotSyncCreateNestedOneWithoutOrganizationInput>;
  OrgNoEventDate?: InputMaybe<OrgNoEventDateCreateNestedManyWithoutOrganizationInput>;
  OrgSalesforceSync?: InputMaybe<OrgSalesforceSyncCreateNestedOneWithoutOrganizationInput>;
  OrgTimeslotAvailability?: InputMaybe<OrgTimeslotAvailabilityCreateNestedManyWithoutOrganizationInput>;
  outbound_close_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_dm_connect_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_lt_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_revenue_value_of_sale?: InputMaybe<Scalars['Float']['input']>;
  outbound_scheduled_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_set_rate?: InputMaybe<Scalars['Float']['input']>;
  PandadocData?: InputMaybe<PandadocDataCreateNestedOneWithoutOrganizationInput>;
  PandaDocTemplate?: InputMaybe<PandaDocTemplateCreateNestedManyWithoutOrganizationInput>;
  participant_jitter_buffer_size?: InputMaybe<JitterBufferSize>;
  preferred_goal_setting?: InputMaybe<Goalsetting>;
  prepayment?: InputMaybe<Scalars['Boolean']['input']>;
  ProductChangeLog?: InputMaybe<ProductChangeLogCreateNestedManyWithoutOrganizationInput>;
  products?: InputMaybe<ProductCreateNestedManyWithoutOrganizationInput>;
  re_cold_call_hour_delay?: InputMaybe<Scalars['Float']['input']>;
  recordingStatus?: InputMaybe<RecordingStatus>;
  release_lead_resting?: InputMaybe<Scalars['Int']['input']>;
  rep_claim_limit?: InputMaybe<Scalars['Int']['input']>;
  rep_lead_allotment?: InputMaybe<Scalars['Int']['input']>;
  rep_level_call_transfer?: InputMaybe<Scalars['Boolean']['input']>;
  reps_can_bulk_unassign?: InputMaybe<Scalars['Boolean']['input']>;
  reps_can_view_all_notes?: InputMaybe<Scalars['Boolean']['input']>;
  require_sale_notes?: InputMaybe<Scalars['Boolean']['input']>;
  resting_after_skipped_CC?: InputMaybe<Scalars['Int']['input']>;
  resting_period?: InputMaybe<Scalars['Int']['input']>;
  resting_period_after_unsuccessful_cold_call?: InputMaybe<Scalars['Float']['input']>;
  resting_period_after_unsuccessful_demo?: InputMaybe<Scalars['Float']['input']>;
  revenue_plan?: InputMaybe<RevenuePlanItemCreateNestedManyWithoutOrganizationInput>;
  Routings?: InputMaybe<RoutingCreateNestedManyWithoutOrganizationInput>;
  sale_field_options?: InputMaybe<SaleFieldOptionCreateNestedManyWithoutOrganizationInput>;
  sale_notification_email_list?: InputMaybe<OrganizationCreatesale_Notification_Email_ListInput>;
  SaleConfig?: InputMaybe<SaleConfigCreateNestedOneWithoutOrganizationInput>;
  SaleConfigPage?: InputMaybe<SaleConfigPageCreateNestedManyWithoutOrganizationInput>;
  SaleConfigSection?: InputMaybe<SaleConfigSectionCreateNestedManyWithoutOrganizationInput>;
  SaleCycles?: InputMaybe<SaleCycleCreateNestedManyWithoutOrganizationInput>;
  SaleItems?: InputMaybe<SaleItemCreateNestedManyWithoutOrganizationInput>;
  sales?: InputMaybe<SaleCreateNestedManyWithoutOrganizationInput>;
  salesforce_domain?: InputMaybe<Scalars['String']['input']>;
  salesforce_last_sync_date?: InputMaybe<Scalars['DateTime']['input']>;
  SalesForceColumn?: InputMaybe<SalesForceColumnCreateNestedManyWithoutOrganizationInput>;
  salesForceData?: InputMaybe<SalesForceDataCreateNestedOneWithoutOrganizationInput>;
  ScheduleItems?: InputMaybe<ScheduleItemCreateNestedManyWithoutOrganizationInput>;
  self_sourcing_rule?: InputMaybe<Scalars['Int']['input']>;
  send_error_log_email?: InputMaybe<Scalars['Boolean']['input']>;
  send_lead_routing_email?: InputMaybe<Scalars['Boolean']['input']>;
  sendgrid_email_domain?: InputMaybe<Scalars['String']['input']>;
  sendgrid_email_domain_id?: InputMaybe<Scalars['Int']['input']>;
  sequence?: InputMaybe<SequenceCreateNestedManyWithoutOrganzationInput>;
  SequenceCriteriaConditions?: InputMaybe<SequenceCriteriaConditionCreateNestedManyWithoutOrganizationInput>;
  SequenceDashboardViews?: InputMaybe<SequenceDashboardViewCreateNestedManyWithoutOrganizationInput>;
  SequenceEntryCriterias?: InputMaybe<SequenceEntryCriteriaCreateNestedManyWithoutOrganizationInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutOrganizationInput>;
  SequenceSteps?: InputMaybe<SequenceStepCreateNestedManyWithoutOrganizationInput>;
  set_goal?: InputMaybe<Scalars['Float']['input']>;
  short_resting?: InputMaybe<Scalars['Int']['input']>;
  show_calendar_options?: InputMaybe<Scalars['Boolean']['input']>;
  show_contract_duration_sales?: InputMaybe<Scalars['Boolean']['input']>;
  show_lead_source_to_rep?: InputMaybe<Scalars['Boolean']['input']>;
  show_nocontact_button?: InputMaybe<Scalars['Boolean']['input']>;
  show_payment_terms_sales?: InputMaybe<Scalars['Boolean']['input']>;
  show_rep_all_leads?: InputMaybe<Scalars['Boolean']['input']>;
  show_rep_my_leads?: InputMaybe<Scalars['Boolean']['input']>;
  show_system_view?: InputMaybe<Scalars['Boolean']['input']>;
  sites?: InputMaybe<SiteCreateNestedManyWithoutOrganizationInput>;
  sms_opt_in_dialog?: InputMaybe<Scalars['String']['input']>;
  SMSItems?: InputMaybe<SmsItemCreateNestedManyWithoutOrganizationInput>;
  SnoozeTrackings?: InputMaybe<SnoozeTrackingCreateNestedManyWithoutOrganizationInput>;
  StepActionLogs?: InputMaybe<StepActionLogCreateNestedManyWithoutOrganizationInput>;
  StepActions?: InputMaybe<StepActionCreateNestedManyWithoutOrganizationInput>;
  StepConditions?: InputMaybe<StepConditionCreateNestedManyWithoutOrganizationInput>;
  teams?: InputMaybe<TeamCreateNestedManyWithoutOrganizationInput>;
  Template?: InputMaybe<TemplateCreateNestedManyWithoutOrganizationInput>;
  TemplateVersions?: InputMaybe<TemplateVersionCreateNestedManyWithoutOrganizationInput>;
  timekit_id?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  transfer_timeout?: InputMaybe<Scalars['Int']['input']>;
  TransferAttempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutOrganizationInput>;
  twilio_numbers?: InputMaybe<TwilioNumberCreateNestedManyWithoutOrganizationInput>;
  TwilioFeedbacks?: InputMaybe<TwilioFeedbackCreateNestedManyWithoutOrganizationInput>;
  TwilioInfo?: InputMaybe<TwilioInfoCreateNestedOneWithoutOrganzationInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  use_revenue_planning?: InputMaybe<Scalars['Boolean']['input']>;
  UserCustomFieldFilters?: InputMaybe<UserCustomFieldFilterCreateNestedManyWithoutOrganizationInput>;
  users?: InputMaybe<UserCreateNestedManyWithoutOrganizationInput>;
  win_rep_day_goal?: InputMaybe<Scalars['Float']['input']>;
};

export type OrganizationCreateWithoutCallSharingsInput = {
  ActivityMonitorMultiplier?: InputMaybe<ActivityMonitorMultiplierCreateNestedManyWithoutOrganizationInput>;
  algoFactor?: InputMaybe<AlgoFactorCreateNestedManyWithoutOrganizationInput>;
  allow_calendar_invite?: InputMaybe<Scalars['Boolean']['input']>;
  allow_remove_step_action?: InputMaybe<Scalars['Boolean']['input']>;
  allow_snooze_step_action?: InputMaybe<Scalars['Boolean']['input']>;
  always_record?: InputMaybe<Scalars['Boolean']['input']>;
  arv_initial_held_demos_generated_by_sdr_weight?: InputMaybe<Scalars['Float']['input']>;
  AssociationHistories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutOrganizationInput>;
  auto_call_interval?: InputMaybe<Scalars['Int']['input']>;
  auto_dial?: InputMaybe<Scalars['Boolean']['input']>;
  automated_campaigns?: InputMaybe<AutomatedCampaignCreateNestedManyWithoutOrganizationInput>;
  automatic_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  AutoTransferSelections?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutOrganizationInput>;
  Availablities?: InputMaybe<AvailabilityCreateNestedManyWithoutOrganizationInput>;
  AverageOrderValue?: InputMaybe<AverageOrderValueCreateNestedManyWithoutOrganizationInput>;
  best_action_ever_multiplier?: InputMaybe<Scalars['Float']['input']>;
  best_metrics?: InputMaybe<BestMetricRecordCreateNestedManyWithoutOrganizationInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptCreateNestedManyWithoutOrganizationInput>;
  callMeNows?: InputMaybe<CallMeNowCreateNestedManyWithoutOrganizationInput>;
  CallMultiplier?: InputMaybe<CallMultiplierCreateNestedManyWithoutOrganizationInput>;
  can_edit_sdr_transfer_rules?: InputMaybe<Scalars['Boolean']['input']>;
  claim_rule?: InputMaybe<Scalars['Int']['input']>;
  concession?: InputMaybe<ConcessionCreateNestedManyWithoutOrganizationInput>;
  conference_region?: InputMaybe<ConferenceRegion>;
  Conferences?: InputMaybe<ConferenceCreateNestedManyWithoutOrganizationInput>;
  ContactRequests?: InputMaybe<ContactRequestCreateNestedManyWithoutOrganizationInput>;
  Contacts?: InputMaybe<ContactCreateNestedManyWithoutOrganizationInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  custom_fields?: InputMaybe<CustomFieldCreateNestedManyWithoutOrganizationInput>;
  CustomObject?: InputMaybe<CustomObjectCreateNestedManyWithoutOrganizationInput>;
  CustomObjectField?: InputMaybe<CustomObjectFieldCreateNestedManyWithoutOrganizationInput>;
  CustomObjectFieldForeignKey?: InputMaybe<CustomObjectFieldForeignKeyCreateNestedManyWithoutOrganizationInput>;
  CustomObjectRecord?: InputMaybe<CustomObjectRecordCreateNestedManyWithoutOrganizationInput>;
  CustomObjectRow?: InputMaybe<CustomObjectRowCreateNestedManyWithoutOrganizationInput>;
  CustomQueueItems?: InputMaybe<CustomQueueItemCreateNestedManyWithoutOrganizationInput>;
  DashboardColumnOptions?: InputMaybe<DashboardColumnOptionCreateNestedManyWithoutOrganizationInput>;
  DashboardLeadFilters?: InputMaybe<DashboardLeadFilterCreateNestedManyWithoutOrganizationInput>;
  DashboardRepFilters?: InputMaybe<DashboardRepFilterCreateNestedManyWithoutOrganizationInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewCreateNestedManyWithoutOrganizationInput>;
  day_to_close?: InputMaybe<Scalars['Int']['input']>;
  default_demo_time_duration?: InputMaybe<Scalars['Int']['input']>;
  demo_held_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_not_held_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_not_held_call_back_short_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_prep_time_minutes?: InputMaybe<Scalars['Int']['input']>;
  dev_account?: InputMaybe<Scalars['Boolean']['input']>;
  dial_rule?: InputMaybe<Scalars['Int']['input']>;
  DispositionMultiplier?: InputMaybe<DispositionMultiplierCreateNestedManyWithoutOrganizationInput>;
  dispositions?: InputMaybe<DispositionCreateNestedManyWithoutOrganizationInput>;
  double_booking?: InputMaybe<Scalars['Boolean']['input']>;
  dynamic_date_operators?: InputMaybe<Scalars['Boolean']['input']>;
  edge_servers?: InputMaybe<OrganizationCreateedge_ServersInput>;
  email_conflict_notification_email_list?: InputMaybe<OrganizationCreateemail_Conflict_Notification_Email_ListInput>;
  EmailItems?: InputMaybe<EmailItemCreateNestedManyWithoutOrganizationInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordCreateNestedManyWithoutOrganizationInput>;
  EmailThreads?: InputMaybe<EmailThreadCreateNestedManyWithoutOrganizationInput>;
  enable_modified_metrics?: InputMaybe<Scalars['Boolean']['input']>;
  enable_pay_period_view?: InputMaybe<Scalars['Boolean']['input']>;
  enable_time_constraint?: InputMaybe<Scalars['Boolean']['input']>;
  error_log_email_address?: InputMaybe<OrganizationCreateerror_Log_Email_AddressInput>;
  estimated_average_num_of_weekly_overbooked_demo_slots?: InputMaybe<Scalars['Int']['input']>;
  FolderPermissions?: InputMaybe<FolderPermissionCreateNestedManyWithoutOrganizationInput>;
  Folders?: InputMaybe<FolderCreateNestedManyWithoutOrganizationInput>;
  FutureBookingSetting?: InputMaybe<FutureBookingSettingCreateNestedOneWithoutOrganizationInput>;
  GlobalTransferRule?: InputMaybe<GlobalTransferRuleCreateNestedManyWithoutOrganizationInput>;
  held_demos_per_cc_connect?: InputMaybe<Scalars['Float']['input']>;
  helpful_links?: InputMaybe<OrganizationCreatehelpful_LinksInput>;
  HingePointMultiplier?: InputMaybe<HingePointMultiplierCreateNestedManyWithoutOrganizationInput>;
  hold_goal?: InputMaybe<Scalars['Float']['input']>;
  hubspot_credential?: InputMaybe<HubSpotCredentialCreateNestedOneWithoutOrganizationInput>;
  hubspot_last_sync_date?: InputMaybe<Scalars['DateTime']['input']>;
  hubspot_white_list?: InputMaybe<HubSpotWhitelistCreateNestedManyWithoutOrganizationInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  inbound_close_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_dm_connect_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_lt_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_revenue_value_of_sale?: InputMaybe<Scalars['Float']['input']>;
  inbound_scheduled_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_set_rate?: InputMaybe<Scalars['Float']['input']>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventCreateNestedManyWithoutOrganizationInput>;
  InboundConciergeWidget?: InputMaybe<InboundConciergeWidgetCreateNestedManyWithoutOrganizationInput>;
  industry_options?: InputMaybe<IndustryOptionCreateNestedManyWithoutOrganizationInput>;
  initial_sdr_sets_reps_day?: InputMaybe<Scalars['Float']['input']>;
  integration_error_logs?: InputMaybe<IntegrationErrorLogCreateNestedManyWithoutOrganizationInput>;
  integration_mappings?: InputMaybe<IntegrationMappingCreateNestedManyWithoutOrganizationInput>;
  IntegrationMappingCustomObject?: InputMaybe<IntegrationMappingCustomObjectCreateNestedManyWithoutOrganizationInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogCreateNestedManyWithoutOrganizationInput>;
  IntegrationReadOnlyObject?: InputMaybe<IntegrationReadOnlyObjectCreateNestedManyWithoutOrganizationInput>;
  international?: InputMaybe<Scalars['Boolean']['input']>;
  is_email_domain_verified?: InputMaybe<Scalars['Boolean']['input']>;
  is_salesforce_sandbox?: InputMaybe<Scalars['Boolean']['input']>;
  last_lead_value_compute_time?: InputMaybe<Scalars['DateTime']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutOrganizationInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryCreateNestedManyWithoutOrganizationInput>;
  lead_intent?: InputMaybe<LeadIntentCreateNestedManyWithoutOrganizationInput>;
  lead_release_same_rep_delay_rule?: InputMaybe<Scalars['Int']['input']>;
  lead_routing_email_list?: InputMaybe<OrganizationCreatelead_Routing_Email_ListInput>;
  lead_routing_rules?: InputMaybe<RuleCreateNestedManyWithoutOrganizationInput>;
  LeadCustomObjectRowAssociation?: InputMaybe<LeadCustomObjectRowAssociationCreateNestedManyWithoutOrganizationInput>;
  LeadFieldMultiplier?: InputMaybe<LeadFieldMultiplierCreateNestedManyWithoutOrganizationInput>;
  LeadFieldMultiplierCustom?: InputMaybe<LeadFieldMultiplierCustomCreateNestedManyWithoutOrganizationInput>;
  leads?: InputMaybe<LeadCreateNestedManyWithoutOrganizationInput>;
  leadsource_options?: InputMaybe<LeadSourceOptionCreateNestedManyWithoutOrganizationInput>;
  LeadValue?: InputMaybe<LeadValueCreateNestedManyWithoutOrganizationInput>;
  LifeTimeValueMultiplier?: InputMaybe<LifeTimeValueMultiplierCreateNestedManyWithoutOrganizationInput>;
  LiveTransferSetting?: InputMaybe<LiveTransferSettingCreateNestedOneWithoutOrganizationInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusCreateNestedManyWithoutOrganizationInput>;
  ltv_multiplier_custom_fields?: InputMaybe<LifeTimeValueMultiplierCustomCreateNestedManyWithoutOrganizationInput>;
  made_contact_rule?: InputMaybe<Scalars['Int']['input']>;
  make_sale_address_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_business_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_city_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_country_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_first_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_industry_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_last_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_lead_source_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_primary_email_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_primary_phone_number_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_state_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_sub_industry_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_zip_required?: InputMaybe<Scalars['Boolean']['input']>;
  maxium_day_lag_set_to_schedule?: InputMaybe<Scalars['Int']['input']>;
  mrr?: InputMaybe<Scalars['Int']['input']>;
  mrr_label?: InputMaybe<Scalars['String']['input']>;
  mrr_required_on_sale?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  NoteItems?: InputMaybe<NoteItemCreateNestedManyWithoutOrganizationInput>;
  Notifications?: InputMaybe<NotificationCreateNestedManyWithoutOrganizationInput>;
  onboardComplete?: InputMaybe<Scalars['Boolean']['input']>;
  Opportunity?: InputMaybe<SfOpportunityCreateNestedManyWithoutOrganizationInput>;
  organization_holidays?: InputMaybe<OrganizationHolidayCreateNestedManyWithoutOrganizationInput>;
  organization_hours?: InputMaybe<OrganizationHourCreateNestedManyWithoutOrganizationInput>;
  OrganizationPaymentSetting?: InputMaybe<OrganizationPaymentSettingsCreateNestedOneWithoutOrganizationInput>;
  OrganizationPaymentSettings?: InputMaybe<OrganizationPaymentSettingsCreateNestedOneWithoutOrganizationsInput>;
  orgCalendarInviteMetadata?: InputMaybe<OrgCalendarInviteMetadataCreateNestedManyWithoutOrganizationInput>;
  OrgDefaultPipelinePerecentValue?: InputMaybe<OrgDefaultPipelinePerecentValueCreateNestedOneWithoutOrganizationInput>;
  OrgHubSpotSync?: InputMaybe<OrgHubSpotSyncCreateNestedOneWithoutOrganizationInput>;
  OrgNoEventDate?: InputMaybe<OrgNoEventDateCreateNestedManyWithoutOrganizationInput>;
  OrgSalesforceSync?: InputMaybe<OrgSalesforceSyncCreateNestedOneWithoutOrganizationInput>;
  OrgTimeslotAvailability?: InputMaybe<OrgTimeslotAvailabilityCreateNestedManyWithoutOrganizationInput>;
  outbound_close_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_dm_connect_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_lt_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_revenue_value_of_sale?: InputMaybe<Scalars['Float']['input']>;
  outbound_scheduled_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_set_rate?: InputMaybe<Scalars['Float']['input']>;
  PandadocData?: InputMaybe<PandadocDataCreateNestedOneWithoutOrganizationInput>;
  PandaDocTemplate?: InputMaybe<PandaDocTemplateCreateNestedManyWithoutOrganizationInput>;
  participant_jitter_buffer_size?: InputMaybe<JitterBufferSize>;
  preferred_goal_setting?: InputMaybe<Goalsetting>;
  prepayment?: InputMaybe<Scalars['Boolean']['input']>;
  ProductChangeLog?: InputMaybe<ProductChangeLogCreateNestedManyWithoutOrganizationInput>;
  products?: InputMaybe<ProductCreateNestedManyWithoutOrganizationInput>;
  re_cold_call_hour_delay?: InputMaybe<Scalars['Float']['input']>;
  recordingStatus?: InputMaybe<RecordingStatus>;
  release_lead_resting?: InputMaybe<Scalars['Int']['input']>;
  rep_claim_limit?: InputMaybe<Scalars['Int']['input']>;
  rep_lead_allotment?: InputMaybe<Scalars['Int']['input']>;
  rep_level_call_transfer?: InputMaybe<Scalars['Boolean']['input']>;
  reps_can_bulk_unassign?: InputMaybe<Scalars['Boolean']['input']>;
  reps_can_view_all_notes?: InputMaybe<Scalars['Boolean']['input']>;
  require_sale_notes?: InputMaybe<Scalars['Boolean']['input']>;
  resting_after_skipped_CC?: InputMaybe<Scalars['Int']['input']>;
  resting_period?: InputMaybe<Scalars['Int']['input']>;
  resting_period_after_unsuccessful_cold_call?: InputMaybe<Scalars['Float']['input']>;
  resting_period_after_unsuccessful_demo?: InputMaybe<Scalars['Float']['input']>;
  revenue_plan?: InputMaybe<RevenuePlanItemCreateNestedManyWithoutOrganizationInput>;
  Routings?: InputMaybe<RoutingCreateNestedManyWithoutOrganizationInput>;
  sale_field_options?: InputMaybe<SaleFieldOptionCreateNestedManyWithoutOrganizationInput>;
  sale_notification_email_list?: InputMaybe<OrganizationCreatesale_Notification_Email_ListInput>;
  SaleConfig?: InputMaybe<SaleConfigCreateNestedOneWithoutOrganizationInput>;
  SaleConfigPage?: InputMaybe<SaleConfigPageCreateNestedManyWithoutOrganizationInput>;
  SaleConfigSection?: InputMaybe<SaleConfigSectionCreateNestedManyWithoutOrganizationInput>;
  SaleCycles?: InputMaybe<SaleCycleCreateNestedManyWithoutOrganizationInput>;
  SaleItems?: InputMaybe<SaleItemCreateNestedManyWithoutOrganizationInput>;
  sales?: InputMaybe<SaleCreateNestedManyWithoutOrganizationInput>;
  salesforce_domain?: InputMaybe<Scalars['String']['input']>;
  salesforce_last_sync_date?: InputMaybe<Scalars['DateTime']['input']>;
  SalesForceColumn?: InputMaybe<SalesForceColumnCreateNestedManyWithoutOrganizationInput>;
  salesForceData?: InputMaybe<SalesForceDataCreateNestedOneWithoutOrganizationInput>;
  ScheduleItems?: InputMaybe<ScheduleItemCreateNestedManyWithoutOrganizationInput>;
  self_sourcing_rule?: InputMaybe<Scalars['Int']['input']>;
  send_error_log_email?: InputMaybe<Scalars['Boolean']['input']>;
  send_lead_routing_email?: InputMaybe<Scalars['Boolean']['input']>;
  sendgrid_email_domain?: InputMaybe<Scalars['String']['input']>;
  sendgrid_email_domain_id?: InputMaybe<Scalars['Int']['input']>;
  sequence?: InputMaybe<SequenceCreateNestedManyWithoutOrganzationInput>;
  SequenceCriteriaConditions?: InputMaybe<SequenceCriteriaConditionCreateNestedManyWithoutOrganizationInput>;
  SequenceDashboardViews?: InputMaybe<SequenceDashboardViewCreateNestedManyWithoutOrganizationInput>;
  SequenceEntryCriterias?: InputMaybe<SequenceEntryCriteriaCreateNestedManyWithoutOrganizationInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutOrganizationInput>;
  SequenceSteps?: InputMaybe<SequenceStepCreateNestedManyWithoutOrganizationInput>;
  set_goal?: InputMaybe<Scalars['Float']['input']>;
  short_resting?: InputMaybe<Scalars['Int']['input']>;
  show_calendar_options?: InputMaybe<Scalars['Boolean']['input']>;
  show_contract_duration_sales?: InputMaybe<Scalars['Boolean']['input']>;
  show_lead_source_to_rep?: InputMaybe<Scalars['Boolean']['input']>;
  show_nocontact_button?: InputMaybe<Scalars['Boolean']['input']>;
  show_payment_terms_sales?: InputMaybe<Scalars['Boolean']['input']>;
  show_rep_all_leads?: InputMaybe<Scalars['Boolean']['input']>;
  show_rep_my_leads?: InputMaybe<Scalars['Boolean']['input']>;
  show_system_view?: InputMaybe<Scalars['Boolean']['input']>;
  sites?: InputMaybe<SiteCreateNestedManyWithoutOrganizationInput>;
  sms_opt_in_dialog?: InputMaybe<Scalars['String']['input']>;
  SMSItems?: InputMaybe<SmsItemCreateNestedManyWithoutOrganizationInput>;
  SnoozeTrackings?: InputMaybe<SnoozeTrackingCreateNestedManyWithoutOrganizationInput>;
  StepActionLogs?: InputMaybe<StepActionLogCreateNestedManyWithoutOrganizationInput>;
  StepActions?: InputMaybe<StepActionCreateNestedManyWithoutOrganizationInput>;
  StepConditions?: InputMaybe<StepConditionCreateNestedManyWithoutOrganizationInput>;
  teams?: InputMaybe<TeamCreateNestedManyWithoutOrganizationInput>;
  Template?: InputMaybe<TemplateCreateNestedManyWithoutOrganizationInput>;
  TemplateVersions?: InputMaybe<TemplateVersionCreateNestedManyWithoutOrganizationInput>;
  timekit_id?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  transfer_timeout?: InputMaybe<Scalars['Int']['input']>;
  TransferAttempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutOrganizationInput>;
  twilio_numbers?: InputMaybe<TwilioNumberCreateNestedManyWithoutOrganizationInput>;
  TwilioFeedbacks?: InputMaybe<TwilioFeedbackCreateNestedManyWithoutOrganizationInput>;
  TwilioInfo?: InputMaybe<TwilioInfoCreateNestedOneWithoutOrganzationInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  use_revenue_planning?: InputMaybe<Scalars['Boolean']['input']>;
  UserCustomFieldFilters?: InputMaybe<UserCustomFieldFilterCreateNestedManyWithoutOrganizationInput>;
  users?: InputMaybe<UserCreateNestedManyWithoutOrganizationInput>;
  win_rep_day_goal?: InputMaybe<Scalars['Float']['input']>;
};

export type OrganizationCreateWithoutConcessionInput = {
  ActivityMonitorMultiplier?: InputMaybe<ActivityMonitorMultiplierCreateNestedManyWithoutOrganizationInput>;
  algoFactor?: InputMaybe<AlgoFactorCreateNestedManyWithoutOrganizationInput>;
  allow_calendar_invite?: InputMaybe<Scalars['Boolean']['input']>;
  allow_remove_step_action?: InputMaybe<Scalars['Boolean']['input']>;
  allow_snooze_step_action?: InputMaybe<Scalars['Boolean']['input']>;
  always_record?: InputMaybe<Scalars['Boolean']['input']>;
  arv_initial_held_demos_generated_by_sdr_weight?: InputMaybe<Scalars['Float']['input']>;
  AssociationHistories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutOrganizationInput>;
  auto_call_interval?: InputMaybe<Scalars['Int']['input']>;
  auto_dial?: InputMaybe<Scalars['Boolean']['input']>;
  automated_campaigns?: InputMaybe<AutomatedCampaignCreateNestedManyWithoutOrganizationInput>;
  automatic_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  AutoTransferSelections?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutOrganizationInput>;
  Availablities?: InputMaybe<AvailabilityCreateNestedManyWithoutOrganizationInput>;
  AverageOrderValue?: InputMaybe<AverageOrderValueCreateNestedManyWithoutOrganizationInput>;
  best_action_ever_multiplier?: InputMaybe<Scalars['Float']['input']>;
  best_metrics?: InputMaybe<BestMetricRecordCreateNestedManyWithoutOrganizationInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptCreateNestedManyWithoutOrganizationInput>;
  callMeNows?: InputMaybe<CallMeNowCreateNestedManyWithoutOrganizationInput>;
  CallMultiplier?: InputMaybe<CallMultiplierCreateNestedManyWithoutOrganizationInput>;
  CallSharings?: InputMaybe<CallSharingCreateNestedManyWithoutOrganizationInput>;
  can_edit_sdr_transfer_rules?: InputMaybe<Scalars['Boolean']['input']>;
  claim_rule?: InputMaybe<Scalars['Int']['input']>;
  conference_region?: InputMaybe<ConferenceRegion>;
  Conferences?: InputMaybe<ConferenceCreateNestedManyWithoutOrganizationInput>;
  ContactRequests?: InputMaybe<ContactRequestCreateNestedManyWithoutOrganizationInput>;
  Contacts?: InputMaybe<ContactCreateNestedManyWithoutOrganizationInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  custom_fields?: InputMaybe<CustomFieldCreateNestedManyWithoutOrganizationInput>;
  CustomObject?: InputMaybe<CustomObjectCreateNestedManyWithoutOrganizationInput>;
  CustomObjectField?: InputMaybe<CustomObjectFieldCreateNestedManyWithoutOrganizationInput>;
  CustomObjectFieldForeignKey?: InputMaybe<CustomObjectFieldForeignKeyCreateNestedManyWithoutOrganizationInput>;
  CustomObjectRecord?: InputMaybe<CustomObjectRecordCreateNestedManyWithoutOrganizationInput>;
  CustomObjectRow?: InputMaybe<CustomObjectRowCreateNestedManyWithoutOrganizationInput>;
  CustomQueueItems?: InputMaybe<CustomQueueItemCreateNestedManyWithoutOrganizationInput>;
  DashboardColumnOptions?: InputMaybe<DashboardColumnOptionCreateNestedManyWithoutOrganizationInput>;
  DashboardLeadFilters?: InputMaybe<DashboardLeadFilterCreateNestedManyWithoutOrganizationInput>;
  DashboardRepFilters?: InputMaybe<DashboardRepFilterCreateNestedManyWithoutOrganizationInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewCreateNestedManyWithoutOrganizationInput>;
  day_to_close?: InputMaybe<Scalars['Int']['input']>;
  default_demo_time_duration?: InputMaybe<Scalars['Int']['input']>;
  demo_held_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_not_held_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_not_held_call_back_short_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_prep_time_minutes?: InputMaybe<Scalars['Int']['input']>;
  dev_account?: InputMaybe<Scalars['Boolean']['input']>;
  dial_rule?: InputMaybe<Scalars['Int']['input']>;
  DispositionMultiplier?: InputMaybe<DispositionMultiplierCreateNestedManyWithoutOrganizationInput>;
  dispositions?: InputMaybe<DispositionCreateNestedManyWithoutOrganizationInput>;
  double_booking?: InputMaybe<Scalars['Boolean']['input']>;
  dynamic_date_operators?: InputMaybe<Scalars['Boolean']['input']>;
  edge_servers?: InputMaybe<OrganizationCreateedge_ServersInput>;
  email_conflict_notification_email_list?: InputMaybe<OrganizationCreateemail_Conflict_Notification_Email_ListInput>;
  EmailItems?: InputMaybe<EmailItemCreateNestedManyWithoutOrganizationInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordCreateNestedManyWithoutOrganizationInput>;
  EmailThreads?: InputMaybe<EmailThreadCreateNestedManyWithoutOrganizationInput>;
  enable_modified_metrics?: InputMaybe<Scalars['Boolean']['input']>;
  enable_pay_period_view?: InputMaybe<Scalars['Boolean']['input']>;
  enable_time_constraint?: InputMaybe<Scalars['Boolean']['input']>;
  error_log_email_address?: InputMaybe<OrganizationCreateerror_Log_Email_AddressInput>;
  estimated_average_num_of_weekly_overbooked_demo_slots?: InputMaybe<Scalars['Int']['input']>;
  FolderPermissions?: InputMaybe<FolderPermissionCreateNestedManyWithoutOrganizationInput>;
  Folders?: InputMaybe<FolderCreateNestedManyWithoutOrganizationInput>;
  FutureBookingSetting?: InputMaybe<FutureBookingSettingCreateNestedOneWithoutOrganizationInput>;
  GlobalTransferRule?: InputMaybe<GlobalTransferRuleCreateNestedManyWithoutOrganizationInput>;
  held_demos_per_cc_connect?: InputMaybe<Scalars['Float']['input']>;
  helpful_links?: InputMaybe<OrganizationCreatehelpful_LinksInput>;
  HingePointMultiplier?: InputMaybe<HingePointMultiplierCreateNestedManyWithoutOrganizationInput>;
  hold_goal?: InputMaybe<Scalars['Float']['input']>;
  hubspot_credential?: InputMaybe<HubSpotCredentialCreateNestedOneWithoutOrganizationInput>;
  hubspot_last_sync_date?: InputMaybe<Scalars['DateTime']['input']>;
  hubspot_white_list?: InputMaybe<HubSpotWhitelistCreateNestedManyWithoutOrganizationInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  inbound_close_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_dm_connect_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_lt_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_revenue_value_of_sale?: InputMaybe<Scalars['Float']['input']>;
  inbound_scheduled_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_set_rate?: InputMaybe<Scalars['Float']['input']>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventCreateNestedManyWithoutOrganizationInput>;
  InboundConciergeWidget?: InputMaybe<InboundConciergeWidgetCreateNestedManyWithoutOrganizationInput>;
  industry_options?: InputMaybe<IndustryOptionCreateNestedManyWithoutOrganizationInput>;
  initial_sdr_sets_reps_day?: InputMaybe<Scalars['Float']['input']>;
  integration_error_logs?: InputMaybe<IntegrationErrorLogCreateNestedManyWithoutOrganizationInput>;
  integration_mappings?: InputMaybe<IntegrationMappingCreateNestedManyWithoutOrganizationInput>;
  IntegrationMappingCustomObject?: InputMaybe<IntegrationMappingCustomObjectCreateNestedManyWithoutOrganizationInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogCreateNestedManyWithoutOrganizationInput>;
  IntegrationReadOnlyObject?: InputMaybe<IntegrationReadOnlyObjectCreateNestedManyWithoutOrganizationInput>;
  international?: InputMaybe<Scalars['Boolean']['input']>;
  is_email_domain_verified?: InputMaybe<Scalars['Boolean']['input']>;
  is_salesforce_sandbox?: InputMaybe<Scalars['Boolean']['input']>;
  last_lead_value_compute_time?: InputMaybe<Scalars['DateTime']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutOrganizationInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryCreateNestedManyWithoutOrganizationInput>;
  lead_intent?: InputMaybe<LeadIntentCreateNestedManyWithoutOrganizationInput>;
  lead_release_same_rep_delay_rule?: InputMaybe<Scalars['Int']['input']>;
  lead_routing_email_list?: InputMaybe<OrganizationCreatelead_Routing_Email_ListInput>;
  lead_routing_rules?: InputMaybe<RuleCreateNestedManyWithoutOrganizationInput>;
  LeadCustomObjectRowAssociation?: InputMaybe<LeadCustomObjectRowAssociationCreateNestedManyWithoutOrganizationInput>;
  LeadFieldMultiplier?: InputMaybe<LeadFieldMultiplierCreateNestedManyWithoutOrganizationInput>;
  LeadFieldMultiplierCustom?: InputMaybe<LeadFieldMultiplierCustomCreateNestedManyWithoutOrganizationInput>;
  leads?: InputMaybe<LeadCreateNestedManyWithoutOrganizationInput>;
  leadsource_options?: InputMaybe<LeadSourceOptionCreateNestedManyWithoutOrganizationInput>;
  LeadValue?: InputMaybe<LeadValueCreateNestedManyWithoutOrganizationInput>;
  LifeTimeValueMultiplier?: InputMaybe<LifeTimeValueMultiplierCreateNestedManyWithoutOrganizationInput>;
  LiveTransferSetting?: InputMaybe<LiveTransferSettingCreateNestedOneWithoutOrganizationInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusCreateNestedManyWithoutOrganizationInput>;
  ltv_multiplier_custom_fields?: InputMaybe<LifeTimeValueMultiplierCustomCreateNestedManyWithoutOrganizationInput>;
  made_contact_rule?: InputMaybe<Scalars['Int']['input']>;
  make_sale_address_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_business_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_city_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_country_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_first_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_industry_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_last_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_lead_source_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_primary_email_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_primary_phone_number_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_state_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_sub_industry_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_zip_required?: InputMaybe<Scalars['Boolean']['input']>;
  maxium_day_lag_set_to_schedule?: InputMaybe<Scalars['Int']['input']>;
  mrr?: InputMaybe<Scalars['Int']['input']>;
  mrr_label?: InputMaybe<Scalars['String']['input']>;
  mrr_required_on_sale?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  NoteItems?: InputMaybe<NoteItemCreateNestedManyWithoutOrganizationInput>;
  Notifications?: InputMaybe<NotificationCreateNestedManyWithoutOrganizationInput>;
  onboardComplete?: InputMaybe<Scalars['Boolean']['input']>;
  Opportunity?: InputMaybe<SfOpportunityCreateNestedManyWithoutOrganizationInput>;
  organization_holidays?: InputMaybe<OrganizationHolidayCreateNestedManyWithoutOrganizationInput>;
  organization_hours?: InputMaybe<OrganizationHourCreateNestedManyWithoutOrganizationInput>;
  OrganizationPaymentSetting?: InputMaybe<OrganizationPaymentSettingsCreateNestedOneWithoutOrganizationInput>;
  OrganizationPaymentSettings?: InputMaybe<OrganizationPaymentSettingsCreateNestedOneWithoutOrganizationsInput>;
  orgCalendarInviteMetadata?: InputMaybe<OrgCalendarInviteMetadataCreateNestedManyWithoutOrganizationInput>;
  OrgDefaultPipelinePerecentValue?: InputMaybe<OrgDefaultPipelinePerecentValueCreateNestedOneWithoutOrganizationInput>;
  OrgHubSpotSync?: InputMaybe<OrgHubSpotSyncCreateNestedOneWithoutOrganizationInput>;
  OrgNoEventDate?: InputMaybe<OrgNoEventDateCreateNestedManyWithoutOrganizationInput>;
  OrgSalesforceSync?: InputMaybe<OrgSalesforceSyncCreateNestedOneWithoutOrganizationInput>;
  OrgTimeslotAvailability?: InputMaybe<OrgTimeslotAvailabilityCreateNestedManyWithoutOrganizationInput>;
  outbound_close_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_dm_connect_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_lt_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_revenue_value_of_sale?: InputMaybe<Scalars['Float']['input']>;
  outbound_scheduled_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_set_rate?: InputMaybe<Scalars['Float']['input']>;
  PandadocData?: InputMaybe<PandadocDataCreateNestedOneWithoutOrganizationInput>;
  PandaDocTemplate?: InputMaybe<PandaDocTemplateCreateNestedManyWithoutOrganizationInput>;
  participant_jitter_buffer_size?: InputMaybe<JitterBufferSize>;
  preferred_goal_setting?: InputMaybe<Goalsetting>;
  prepayment?: InputMaybe<Scalars['Boolean']['input']>;
  ProductChangeLog?: InputMaybe<ProductChangeLogCreateNestedManyWithoutOrganizationInput>;
  products?: InputMaybe<ProductCreateNestedManyWithoutOrganizationInput>;
  re_cold_call_hour_delay?: InputMaybe<Scalars['Float']['input']>;
  recordingStatus?: InputMaybe<RecordingStatus>;
  release_lead_resting?: InputMaybe<Scalars['Int']['input']>;
  rep_claim_limit?: InputMaybe<Scalars['Int']['input']>;
  rep_lead_allotment?: InputMaybe<Scalars['Int']['input']>;
  rep_level_call_transfer?: InputMaybe<Scalars['Boolean']['input']>;
  reps_can_bulk_unassign?: InputMaybe<Scalars['Boolean']['input']>;
  reps_can_view_all_notes?: InputMaybe<Scalars['Boolean']['input']>;
  require_sale_notes?: InputMaybe<Scalars['Boolean']['input']>;
  resting_after_skipped_CC?: InputMaybe<Scalars['Int']['input']>;
  resting_period?: InputMaybe<Scalars['Int']['input']>;
  resting_period_after_unsuccessful_cold_call?: InputMaybe<Scalars['Float']['input']>;
  resting_period_after_unsuccessful_demo?: InputMaybe<Scalars['Float']['input']>;
  revenue_plan?: InputMaybe<RevenuePlanItemCreateNestedManyWithoutOrganizationInput>;
  Routings?: InputMaybe<RoutingCreateNestedManyWithoutOrganizationInput>;
  sale_field_options?: InputMaybe<SaleFieldOptionCreateNestedManyWithoutOrganizationInput>;
  sale_notification_email_list?: InputMaybe<OrganizationCreatesale_Notification_Email_ListInput>;
  SaleConfig?: InputMaybe<SaleConfigCreateNestedOneWithoutOrganizationInput>;
  SaleConfigPage?: InputMaybe<SaleConfigPageCreateNestedManyWithoutOrganizationInput>;
  SaleConfigSection?: InputMaybe<SaleConfigSectionCreateNestedManyWithoutOrganizationInput>;
  SaleCycles?: InputMaybe<SaleCycleCreateNestedManyWithoutOrganizationInput>;
  SaleItems?: InputMaybe<SaleItemCreateNestedManyWithoutOrganizationInput>;
  sales?: InputMaybe<SaleCreateNestedManyWithoutOrganizationInput>;
  salesforce_domain?: InputMaybe<Scalars['String']['input']>;
  salesforce_last_sync_date?: InputMaybe<Scalars['DateTime']['input']>;
  SalesForceColumn?: InputMaybe<SalesForceColumnCreateNestedManyWithoutOrganizationInput>;
  salesForceData?: InputMaybe<SalesForceDataCreateNestedOneWithoutOrganizationInput>;
  ScheduleItems?: InputMaybe<ScheduleItemCreateNestedManyWithoutOrganizationInput>;
  self_sourcing_rule?: InputMaybe<Scalars['Int']['input']>;
  send_error_log_email?: InputMaybe<Scalars['Boolean']['input']>;
  send_lead_routing_email?: InputMaybe<Scalars['Boolean']['input']>;
  sendgrid_email_domain?: InputMaybe<Scalars['String']['input']>;
  sendgrid_email_domain_id?: InputMaybe<Scalars['Int']['input']>;
  sequence?: InputMaybe<SequenceCreateNestedManyWithoutOrganzationInput>;
  SequenceCriteriaConditions?: InputMaybe<SequenceCriteriaConditionCreateNestedManyWithoutOrganizationInput>;
  SequenceDashboardViews?: InputMaybe<SequenceDashboardViewCreateNestedManyWithoutOrganizationInput>;
  SequenceEntryCriterias?: InputMaybe<SequenceEntryCriteriaCreateNestedManyWithoutOrganizationInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutOrganizationInput>;
  SequenceSteps?: InputMaybe<SequenceStepCreateNestedManyWithoutOrganizationInput>;
  set_goal?: InputMaybe<Scalars['Float']['input']>;
  short_resting?: InputMaybe<Scalars['Int']['input']>;
  show_calendar_options?: InputMaybe<Scalars['Boolean']['input']>;
  show_contract_duration_sales?: InputMaybe<Scalars['Boolean']['input']>;
  show_lead_source_to_rep?: InputMaybe<Scalars['Boolean']['input']>;
  show_nocontact_button?: InputMaybe<Scalars['Boolean']['input']>;
  show_payment_terms_sales?: InputMaybe<Scalars['Boolean']['input']>;
  show_rep_all_leads?: InputMaybe<Scalars['Boolean']['input']>;
  show_rep_my_leads?: InputMaybe<Scalars['Boolean']['input']>;
  show_system_view?: InputMaybe<Scalars['Boolean']['input']>;
  sites?: InputMaybe<SiteCreateNestedManyWithoutOrganizationInput>;
  sms_opt_in_dialog?: InputMaybe<Scalars['String']['input']>;
  SMSItems?: InputMaybe<SmsItemCreateNestedManyWithoutOrganizationInput>;
  SnoozeTrackings?: InputMaybe<SnoozeTrackingCreateNestedManyWithoutOrganizationInput>;
  StepActionLogs?: InputMaybe<StepActionLogCreateNestedManyWithoutOrganizationInput>;
  StepActions?: InputMaybe<StepActionCreateNestedManyWithoutOrganizationInput>;
  StepConditions?: InputMaybe<StepConditionCreateNestedManyWithoutOrganizationInput>;
  teams?: InputMaybe<TeamCreateNestedManyWithoutOrganizationInput>;
  Template?: InputMaybe<TemplateCreateNestedManyWithoutOrganizationInput>;
  TemplateVersions?: InputMaybe<TemplateVersionCreateNestedManyWithoutOrganizationInput>;
  timekit_id?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  transfer_timeout?: InputMaybe<Scalars['Int']['input']>;
  TransferAttempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutOrganizationInput>;
  twilio_numbers?: InputMaybe<TwilioNumberCreateNestedManyWithoutOrganizationInput>;
  TwilioFeedbacks?: InputMaybe<TwilioFeedbackCreateNestedManyWithoutOrganizationInput>;
  TwilioInfo?: InputMaybe<TwilioInfoCreateNestedOneWithoutOrganzationInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  use_revenue_planning?: InputMaybe<Scalars['Boolean']['input']>;
  UserCustomFieldFilters?: InputMaybe<UserCustomFieldFilterCreateNestedManyWithoutOrganizationInput>;
  users?: InputMaybe<UserCreateNestedManyWithoutOrganizationInput>;
  win_rep_day_goal?: InputMaybe<Scalars['Float']['input']>;
};

export type OrganizationCreateWithoutConferencesInput = {
  ActivityMonitorMultiplier?: InputMaybe<ActivityMonitorMultiplierCreateNestedManyWithoutOrganizationInput>;
  algoFactor?: InputMaybe<AlgoFactorCreateNestedManyWithoutOrganizationInput>;
  allow_calendar_invite?: InputMaybe<Scalars['Boolean']['input']>;
  allow_remove_step_action?: InputMaybe<Scalars['Boolean']['input']>;
  allow_snooze_step_action?: InputMaybe<Scalars['Boolean']['input']>;
  always_record?: InputMaybe<Scalars['Boolean']['input']>;
  arv_initial_held_demos_generated_by_sdr_weight?: InputMaybe<Scalars['Float']['input']>;
  AssociationHistories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutOrganizationInput>;
  auto_call_interval?: InputMaybe<Scalars['Int']['input']>;
  auto_dial?: InputMaybe<Scalars['Boolean']['input']>;
  automated_campaigns?: InputMaybe<AutomatedCampaignCreateNestedManyWithoutOrganizationInput>;
  automatic_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  AutoTransferSelections?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutOrganizationInput>;
  Availablities?: InputMaybe<AvailabilityCreateNestedManyWithoutOrganizationInput>;
  AverageOrderValue?: InputMaybe<AverageOrderValueCreateNestedManyWithoutOrganizationInput>;
  best_action_ever_multiplier?: InputMaybe<Scalars['Float']['input']>;
  best_metrics?: InputMaybe<BestMetricRecordCreateNestedManyWithoutOrganizationInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptCreateNestedManyWithoutOrganizationInput>;
  callMeNows?: InputMaybe<CallMeNowCreateNestedManyWithoutOrganizationInput>;
  CallMultiplier?: InputMaybe<CallMultiplierCreateNestedManyWithoutOrganizationInput>;
  CallSharings?: InputMaybe<CallSharingCreateNestedManyWithoutOrganizationInput>;
  can_edit_sdr_transfer_rules?: InputMaybe<Scalars['Boolean']['input']>;
  claim_rule?: InputMaybe<Scalars['Int']['input']>;
  concession?: InputMaybe<ConcessionCreateNestedManyWithoutOrganizationInput>;
  conference_region?: InputMaybe<ConferenceRegion>;
  ContactRequests?: InputMaybe<ContactRequestCreateNestedManyWithoutOrganizationInput>;
  Contacts?: InputMaybe<ContactCreateNestedManyWithoutOrganizationInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  custom_fields?: InputMaybe<CustomFieldCreateNestedManyWithoutOrganizationInput>;
  CustomObject?: InputMaybe<CustomObjectCreateNestedManyWithoutOrganizationInput>;
  CustomObjectField?: InputMaybe<CustomObjectFieldCreateNestedManyWithoutOrganizationInput>;
  CustomObjectFieldForeignKey?: InputMaybe<CustomObjectFieldForeignKeyCreateNestedManyWithoutOrganizationInput>;
  CustomObjectRecord?: InputMaybe<CustomObjectRecordCreateNestedManyWithoutOrganizationInput>;
  CustomObjectRow?: InputMaybe<CustomObjectRowCreateNestedManyWithoutOrganizationInput>;
  CustomQueueItems?: InputMaybe<CustomQueueItemCreateNestedManyWithoutOrganizationInput>;
  DashboardColumnOptions?: InputMaybe<DashboardColumnOptionCreateNestedManyWithoutOrganizationInput>;
  DashboardLeadFilters?: InputMaybe<DashboardLeadFilterCreateNestedManyWithoutOrganizationInput>;
  DashboardRepFilters?: InputMaybe<DashboardRepFilterCreateNestedManyWithoutOrganizationInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewCreateNestedManyWithoutOrganizationInput>;
  day_to_close?: InputMaybe<Scalars['Int']['input']>;
  default_demo_time_duration?: InputMaybe<Scalars['Int']['input']>;
  demo_held_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_not_held_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_not_held_call_back_short_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_prep_time_minutes?: InputMaybe<Scalars['Int']['input']>;
  dev_account?: InputMaybe<Scalars['Boolean']['input']>;
  dial_rule?: InputMaybe<Scalars['Int']['input']>;
  DispositionMultiplier?: InputMaybe<DispositionMultiplierCreateNestedManyWithoutOrganizationInput>;
  dispositions?: InputMaybe<DispositionCreateNestedManyWithoutOrganizationInput>;
  double_booking?: InputMaybe<Scalars['Boolean']['input']>;
  dynamic_date_operators?: InputMaybe<Scalars['Boolean']['input']>;
  edge_servers?: InputMaybe<OrganizationCreateedge_ServersInput>;
  email_conflict_notification_email_list?: InputMaybe<OrganizationCreateemail_Conflict_Notification_Email_ListInput>;
  EmailItems?: InputMaybe<EmailItemCreateNestedManyWithoutOrganizationInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordCreateNestedManyWithoutOrganizationInput>;
  EmailThreads?: InputMaybe<EmailThreadCreateNestedManyWithoutOrganizationInput>;
  enable_modified_metrics?: InputMaybe<Scalars['Boolean']['input']>;
  enable_pay_period_view?: InputMaybe<Scalars['Boolean']['input']>;
  enable_time_constraint?: InputMaybe<Scalars['Boolean']['input']>;
  error_log_email_address?: InputMaybe<OrganizationCreateerror_Log_Email_AddressInput>;
  estimated_average_num_of_weekly_overbooked_demo_slots?: InputMaybe<Scalars['Int']['input']>;
  FolderPermissions?: InputMaybe<FolderPermissionCreateNestedManyWithoutOrganizationInput>;
  Folders?: InputMaybe<FolderCreateNestedManyWithoutOrganizationInput>;
  FutureBookingSetting?: InputMaybe<FutureBookingSettingCreateNestedOneWithoutOrganizationInput>;
  GlobalTransferRule?: InputMaybe<GlobalTransferRuleCreateNestedManyWithoutOrganizationInput>;
  held_demos_per_cc_connect?: InputMaybe<Scalars['Float']['input']>;
  helpful_links?: InputMaybe<OrganizationCreatehelpful_LinksInput>;
  HingePointMultiplier?: InputMaybe<HingePointMultiplierCreateNestedManyWithoutOrganizationInput>;
  hold_goal?: InputMaybe<Scalars['Float']['input']>;
  hubspot_credential?: InputMaybe<HubSpotCredentialCreateNestedOneWithoutOrganizationInput>;
  hubspot_last_sync_date?: InputMaybe<Scalars['DateTime']['input']>;
  hubspot_white_list?: InputMaybe<HubSpotWhitelistCreateNestedManyWithoutOrganizationInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  inbound_close_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_dm_connect_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_lt_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_revenue_value_of_sale?: InputMaybe<Scalars['Float']['input']>;
  inbound_scheduled_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_set_rate?: InputMaybe<Scalars['Float']['input']>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventCreateNestedManyWithoutOrganizationInput>;
  InboundConciergeWidget?: InputMaybe<InboundConciergeWidgetCreateNestedManyWithoutOrganizationInput>;
  industry_options?: InputMaybe<IndustryOptionCreateNestedManyWithoutOrganizationInput>;
  initial_sdr_sets_reps_day?: InputMaybe<Scalars['Float']['input']>;
  integration_error_logs?: InputMaybe<IntegrationErrorLogCreateNestedManyWithoutOrganizationInput>;
  integration_mappings?: InputMaybe<IntegrationMappingCreateNestedManyWithoutOrganizationInput>;
  IntegrationMappingCustomObject?: InputMaybe<IntegrationMappingCustomObjectCreateNestedManyWithoutOrganizationInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogCreateNestedManyWithoutOrganizationInput>;
  IntegrationReadOnlyObject?: InputMaybe<IntegrationReadOnlyObjectCreateNestedManyWithoutOrganizationInput>;
  international?: InputMaybe<Scalars['Boolean']['input']>;
  is_email_domain_verified?: InputMaybe<Scalars['Boolean']['input']>;
  is_salesforce_sandbox?: InputMaybe<Scalars['Boolean']['input']>;
  last_lead_value_compute_time?: InputMaybe<Scalars['DateTime']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutOrganizationInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryCreateNestedManyWithoutOrganizationInput>;
  lead_intent?: InputMaybe<LeadIntentCreateNestedManyWithoutOrganizationInput>;
  lead_release_same_rep_delay_rule?: InputMaybe<Scalars['Int']['input']>;
  lead_routing_email_list?: InputMaybe<OrganizationCreatelead_Routing_Email_ListInput>;
  lead_routing_rules?: InputMaybe<RuleCreateNestedManyWithoutOrganizationInput>;
  LeadCustomObjectRowAssociation?: InputMaybe<LeadCustomObjectRowAssociationCreateNestedManyWithoutOrganizationInput>;
  LeadFieldMultiplier?: InputMaybe<LeadFieldMultiplierCreateNestedManyWithoutOrganizationInput>;
  LeadFieldMultiplierCustom?: InputMaybe<LeadFieldMultiplierCustomCreateNestedManyWithoutOrganizationInput>;
  leads?: InputMaybe<LeadCreateNestedManyWithoutOrganizationInput>;
  leadsource_options?: InputMaybe<LeadSourceOptionCreateNestedManyWithoutOrganizationInput>;
  LeadValue?: InputMaybe<LeadValueCreateNestedManyWithoutOrganizationInput>;
  LifeTimeValueMultiplier?: InputMaybe<LifeTimeValueMultiplierCreateNestedManyWithoutOrganizationInput>;
  LiveTransferSetting?: InputMaybe<LiveTransferSettingCreateNestedOneWithoutOrganizationInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusCreateNestedManyWithoutOrganizationInput>;
  ltv_multiplier_custom_fields?: InputMaybe<LifeTimeValueMultiplierCustomCreateNestedManyWithoutOrganizationInput>;
  made_contact_rule?: InputMaybe<Scalars['Int']['input']>;
  make_sale_address_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_business_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_city_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_country_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_first_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_industry_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_last_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_lead_source_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_primary_email_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_primary_phone_number_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_state_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_sub_industry_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_zip_required?: InputMaybe<Scalars['Boolean']['input']>;
  maxium_day_lag_set_to_schedule?: InputMaybe<Scalars['Int']['input']>;
  mrr?: InputMaybe<Scalars['Int']['input']>;
  mrr_label?: InputMaybe<Scalars['String']['input']>;
  mrr_required_on_sale?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  NoteItems?: InputMaybe<NoteItemCreateNestedManyWithoutOrganizationInput>;
  Notifications?: InputMaybe<NotificationCreateNestedManyWithoutOrganizationInput>;
  onboardComplete?: InputMaybe<Scalars['Boolean']['input']>;
  Opportunity?: InputMaybe<SfOpportunityCreateNestedManyWithoutOrganizationInput>;
  organization_holidays?: InputMaybe<OrganizationHolidayCreateNestedManyWithoutOrganizationInput>;
  organization_hours?: InputMaybe<OrganizationHourCreateNestedManyWithoutOrganizationInput>;
  OrganizationPaymentSetting?: InputMaybe<OrganizationPaymentSettingsCreateNestedOneWithoutOrganizationInput>;
  OrganizationPaymentSettings?: InputMaybe<OrganizationPaymentSettingsCreateNestedOneWithoutOrganizationsInput>;
  orgCalendarInviteMetadata?: InputMaybe<OrgCalendarInviteMetadataCreateNestedManyWithoutOrganizationInput>;
  OrgDefaultPipelinePerecentValue?: InputMaybe<OrgDefaultPipelinePerecentValueCreateNestedOneWithoutOrganizationInput>;
  OrgHubSpotSync?: InputMaybe<OrgHubSpotSyncCreateNestedOneWithoutOrganizationInput>;
  OrgNoEventDate?: InputMaybe<OrgNoEventDateCreateNestedManyWithoutOrganizationInput>;
  OrgSalesforceSync?: InputMaybe<OrgSalesforceSyncCreateNestedOneWithoutOrganizationInput>;
  OrgTimeslotAvailability?: InputMaybe<OrgTimeslotAvailabilityCreateNestedManyWithoutOrganizationInput>;
  outbound_close_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_dm_connect_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_lt_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_revenue_value_of_sale?: InputMaybe<Scalars['Float']['input']>;
  outbound_scheduled_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_set_rate?: InputMaybe<Scalars['Float']['input']>;
  PandadocData?: InputMaybe<PandadocDataCreateNestedOneWithoutOrganizationInput>;
  PandaDocTemplate?: InputMaybe<PandaDocTemplateCreateNestedManyWithoutOrganizationInput>;
  participant_jitter_buffer_size?: InputMaybe<JitterBufferSize>;
  preferred_goal_setting?: InputMaybe<Goalsetting>;
  prepayment?: InputMaybe<Scalars['Boolean']['input']>;
  ProductChangeLog?: InputMaybe<ProductChangeLogCreateNestedManyWithoutOrganizationInput>;
  products?: InputMaybe<ProductCreateNestedManyWithoutOrganizationInput>;
  re_cold_call_hour_delay?: InputMaybe<Scalars['Float']['input']>;
  recordingStatus?: InputMaybe<RecordingStatus>;
  release_lead_resting?: InputMaybe<Scalars['Int']['input']>;
  rep_claim_limit?: InputMaybe<Scalars['Int']['input']>;
  rep_lead_allotment?: InputMaybe<Scalars['Int']['input']>;
  rep_level_call_transfer?: InputMaybe<Scalars['Boolean']['input']>;
  reps_can_bulk_unassign?: InputMaybe<Scalars['Boolean']['input']>;
  reps_can_view_all_notes?: InputMaybe<Scalars['Boolean']['input']>;
  require_sale_notes?: InputMaybe<Scalars['Boolean']['input']>;
  resting_after_skipped_CC?: InputMaybe<Scalars['Int']['input']>;
  resting_period?: InputMaybe<Scalars['Int']['input']>;
  resting_period_after_unsuccessful_cold_call?: InputMaybe<Scalars['Float']['input']>;
  resting_period_after_unsuccessful_demo?: InputMaybe<Scalars['Float']['input']>;
  revenue_plan?: InputMaybe<RevenuePlanItemCreateNestedManyWithoutOrganizationInput>;
  Routings?: InputMaybe<RoutingCreateNestedManyWithoutOrganizationInput>;
  sale_field_options?: InputMaybe<SaleFieldOptionCreateNestedManyWithoutOrganizationInput>;
  sale_notification_email_list?: InputMaybe<OrganizationCreatesale_Notification_Email_ListInput>;
  SaleConfig?: InputMaybe<SaleConfigCreateNestedOneWithoutOrganizationInput>;
  SaleConfigPage?: InputMaybe<SaleConfigPageCreateNestedManyWithoutOrganizationInput>;
  SaleConfigSection?: InputMaybe<SaleConfigSectionCreateNestedManyWithoutOrganizationInput>;
  SaleCycles?: InputMaybe<SaleCycleCreateNestedManyWithoutOrganizationInput>;
  SaleItems?: InputMaybe<SaleItemCreateNestedManyWithoutOrganizationInput>;
  sales?: InputMaybe<SaleCreateNestedManyWithoutOrganizationInput>;
  salesforce_domain?: InputMaybe<Scalars['String']['input']>;
  salesforce_last_sync_date?: InputMaybe<Scalars['DateTime']['input']>;
  SalesForceColumn?: InputMaybe<SalesForceColumnCreateNestedManyWithoutOrganizationInput>;
  salesForceData?: InputMaybe<SalesForceDataCreateNestedOneWithoutOrganizationInput>;
  ScheduleItems?: InputMaybe<ScheduleItemCreateNestedManyWithoutOrganizationInput>;
  self_sourcing_rule?: InputMaybe<Scalars['Int']['input']>;
  send_error_log_email?: InputMaybe<Scalars['Boolean']['input']>;
  send_lead_routing_email?: InputMaybe<Scalars['Boolean']['input']>;
  sendgrid_email_domain?: InputMaybe<Scalars['String']['input']>;
  sendgrid_email_domain_id?: InputMaybe<Scalars['Int']['input']>;
  sequence?: InputMaybe<SequenceCreateNestedManyWithoutOrganzationInput>;
  SequenceCriteriaConditions?: InputMaybe<SequenceCriteriaConditionCreateNestedManyWithoutOrganizationInput>;
  SequenceDashboardViews?: InputMaybe<SequenceDashboardViewCreateNestedManyWithoutOrganizationInput>;
  SequenceEntryCriterias?: InputMaybe<SequenceEntryCriteriaCreateNestedManyWithoutOrganizationInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutOrganizationInput>;
  SequenceSteps?: InputMaybe<SequenceStepCreateNestedManyWithoutOrganizationInput>;
  set_goal?: InputMaybe<Scalars['Float']['input']>;
  short_resting?: InputMaybe<Scalars['Int']['input']>;
  show_calendar_options?: InputMaybe<Scalars['Boolean']['input']>;
  show_contract_duration_sales?: InputMaybe<Scalars['Boolean']['input']>;
  show_lead_source_to_rep?: InputMaybe<Scalars['Boolean']['input']>;
  show_nocontact_button?: InputMaybe<Scalars['Boolean']['input']>;
  show_payment_terms_sales?: InputMaybe<Scalars['Boolean']['input']>;
  show_rep_all_leads?: InputMaybe<Scalars['Boolean']['input']>;
  show_rep_my_leads?: InputMaybe<Scalars['Boolean']['input']>;
  show_system_view?: InputMaybe<Scalars['Boolean']['input']>;
  sites?: InputMaybe<SiteCreateNestedManyWithoutOrganizationInput>;
  sms_opt_in_dialog?: InputMaybe<Scalars['String']['input']>;
  SMSItems?: InputMaybe<SmsItemCreateNestedManyWithoutOrganizationInput>;
  SnoozeTrackings?: InputMaybe<SnoozeTrackingCreateNestedManyWithoutOrganizationInput>;
  StepActionLogs?: InputMaybe<StepActionLogCreateNestedManyWithoutOrganizationInput>;
  StepActions?: InputMaybe<StepActionCreateNestedManyWithoutOrganizationInput>;
  StepConditions?: InputMaybe<StepConditionCreateNestedManyWithoutOrganizationInput>;
  teams?: InputMaybe<TeamCreateNestedManyWithoutOrganizationInput>;
  Template?: InputMaybe<TemplateCreateNestedManyWithoutOrganizationInput>;
  TemplateVersions?: InputMaybe<TemplateVersionCreateNestedManyWithoutOrganizationInput>;
  timekit_id?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  transfer_timeout?: InputMaybe<Scalars['Int']['input']>;
  TransferAttempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutOrganizationInput>;
  twilio_numbers?: InputMaybe<TwilioNumberCreateNestedManyWithoutOrganizationInput>;
  TwilioFeedbacks?: InputMaybe<TwilioFeedbackCreateNestedManyWithoutOrganizationInput>;
  TwilioInfo?: InputMaybe<TwilioInfoCreateNestedOneWithoutOrganzationInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  use_revenue_planning?: InputMaybe<Scalars['Boolean']['input']>;
  UserCustomFieldFilters?: InputMaybe<UserCustomFieldFilterCreateNestedManyWithoutOrganizationInput>;
  users?: InputMaybe<UserCreateNestedManyWithoutOrganizationInput>;
  win_rep_day_goal?: InputMaybe<Scalars['Float']['input']>;
};

export type OrganizationCreateWithoutContactRequestsInput = {
  ActivityMonitorMultiplier?: InputMaybe<ActivityMonitorMultiplierCreateNestedManyWithoutOrganizationInput>;
  algoFactor?: InputMaybe<AlgoFactorCreateNestedManyWithoutOrganizationInput>;
  allow_calendar_invite?: InputMaybe<Scalars['Boolean']['input']>;
  allow_remove_step_action?: InputMaybe<Scalars['Boolean']['input']>;
  allow_snooze_step_action?: InputMaybe<Scalars['Boolean']['input']>;
  always_record?: InputMaybe<Scalars['Boolean']['input']>;
  arv_initial_held_demos_generated_by_sdr_weight?: InputMaybe<Scalars['Float']['input']>;
  AssociationHistories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutOrganizationInput>;
  auto_call_interval?: InputMaybe<Scalars['Int']['input']>;
  auto_dial?: InputMaybe<Scalars['Boolean']['input']>;
  automated_campaigns?: InputMaybe<AutomatedCampaignCreateNestedManyWithoutOrganizationInput>;
  automatic_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  AutoTransferSelections?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutOrganizationInput>;
  Availablities?: InputMaybe<AvailabilityCreateNestedManyWithoutOrganizationInput>;
  AverageOrderValue?: InputMaybe<AverageOrderValueCreateNestedManyWithoutOrganizationInput>;
  best_action_ever_multiplier?: InputMaybe<Scalars['Float']['input']>;
  best_metrics?: InputMaybe<BestMetricRecordCreateNestedManyWithoutOrganizationInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptCreateNestedManyWithoutOrganizationInput>;
  callMeNows?: InputMaybe<CallMeNowCreateNestedManyWithoutOrganizationInput>;
  CallMultiplier?: InputMaybe<CallMultiplierCreateNestedManyWithoutOrganizationInput>;
  CallSharings?: InputMaybe<CallSharingCreateNestedManyWithoutOrganizationInput>;
  can_edit_sdr_transfer_rules?: InputMaybe<Scalars['Boolean']['input']>;
  claim_rule?: InputMaybe<Scalars['Int']['input']>;
  concession?: InputMaybe<ConcessionCreateNestedManyWithoutOrganizationInput>;
  conference_region?: InputMaybe<ConferenceRegion>;
  Conferences?: InputMaybe<ConferenceCreateNestedManyWithoutOrganizationInput>;
  Contacts?: InputMaybe<ContactCreateNestedManyWithoutOrganizationInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  custom_fields?: InputMaybe<CustomFieldCreateNestedManyWithoutOrganizationInput>;
  CustomObject?: InputMaybe<CustomObjectCreateNestedManyWithoutOrganizationInput>;
  CustomObjectField?: InputMaybe<CustomObjectFieldCreateNestedManyWithoutOrganizationInput>;
  CustomObjectFieldForeignKey?: InputMaybe<CustomObjectFieldForeignKeyCreateNestedManyWithoutOrganizationInput>;
  CustomObjectRecord?: InputMaybe<CustomObjectRecordCreateNestedManyWithoutOrganizationInput>;
  CustomObjectRow?: InputMaybe<CustomObjectRowCreateNestedManyWithoutOrganizationInput>;
  CustomQueueItems?: InputMaybe<CustomQueueItemCreateNestedManyWithoutOrganizationInput>;
  DashboardColumnOptions?: InputMaybe<DashboardColumnOptionCreateNestedManyWithoutOrganizationInput>;
  DashboardLeadFilters?: InputMaybe<DashboardLeadFilterCreateNestedManyWithoutOrganizationInput>;
  DashboardRepFilters?: InputMaybe<DashboardRepFilterCreateNestedManyWithoutOrganizationInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewCreateNestedManyWithoutOrganizationInput>;
  day_to_close?: InputMaybe<Scalars['Int']['input']>;
  default_demo_time_duration?: InputMaybe<Scalars['Int']['input']>;
  demo_held_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_not_held_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_not_held_call_back_short_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_prep_time_minutes?: InputMaybe<Scalars['Int']['input']>;
  dev_account?: InputMaybe<Scalars['Boolean']['input']>;
  dial_rule?: InputMaybe<Scalars['Int']['input']>;
  DispositionMultiplier?: InputMaybe<DispositionMultiplierCreateNestedManyWithoutOrganizationInput>;
  dispositions?: InputMaybe<DispositionCreateNestedManyWithoutOrganizationInput>;
  double_booking?: InputMaybe<Scalars['Boolean']['input']>;
  dynamic_date_operators?: InputMaybe<Scalars['Boolean']['input']>;
  edge_servers?: InputMaybe<OrganizationCreateedge_ServersInput>;
  email_conflict_notification_email_list?: InputMaybe<OrganizationCreateemail_Conflict_Notification_Email_ListInput>;
  EmailItems?: InputMaybe<EmailItemCreateNestedManyWithoutOrganizationInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordCreateNestedManyWithoutOrganizationInput>;
  EmailThreads?: InputMaybe<EmailThreadCreateNestedManyWithoutOrganizationInput>;
  enable_modified_metrics?: InputMaybe<Scalars['Boolean']['input']>;
  enable_pay_period_view?: InputMaybe<Scalars['Boolean']['input']>;
  enable_time_constraint?: InputMaybe<Scalars['Boolean']['input']>;
  error_log_email_address?: InputMaybe<OrganizationCreateerror_Log_Email_AddressInput>;
  estimated_average_num_of_weekly_overbooked_demo_slots?: InputMaybe<Scalars['Int']['input']>;
  FolderPermissions?: InputMaybe<FolderPermissionCreateNestedManyWithoutOrganizationInput>;
  Folders?: InputMaybe<FolderCreateNestedManyWithoutOrganizationInput>;
  FutureBookingSetting?: InputMaybe<FutureBookingSettingCreateNestedOneWithoutOrganizationInput>;
  GlobalTransferRule?: InputMaybe<GlobalTransferRuleCreateNestedManyWithoutOrganizationInput>;
  held_demos_per_cc_connect?: InputMaybe<Scalars['Float']['input']>;
  helpful_links?: InputMaybe<OrganizationCreatehelpful_LinksInput>;
  HingePointMultiplier?: InputMaybe<HingePointMultiplierCreateNestedManyWithoutOrganizationInput>;
  hold_goal?: InputMaybe<Scalars['Float']['input']>;
  hubspot_credential?: InputMaybe<HubSpotCredentialCreateNestedOneWithoutOrganizationInput>;
  hubspot_last_sync_date?: InputMaybe<Scalars['DateTime']['input']>;
  hubspot_white_list?: InputMaybe<HubSpotWhitelistCreateNestedManyWithoutOrganizationInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  inbound_close_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_dm_connect_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_lt_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_revenue_value_of_sale?: InputMaybe<Scalars['Float']['input']>;
  inbound_scheduled_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_set_rate?: InputMaybe<Scalars['Float']['input']>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventCreateNestedManyWithoutOrganizationInput>;
  InboundConciergeWidget?: InputMaybe<InboundConciergeWidgetCreateNestedManyWithoutOrganizationInput>;
  industry_options?: InputMaybe<IndustryOptionCreateNestedManyWithoutOrganizationInput>;
  initial_sdr_sets_reps_day?: InputMaybe<Scalars['Float']['input']>;
  integration_error_logs?: InputMaybe<IntegrationErrorLogCreateNestedManyWithoutOrganizationInput>;
  integration_mappings?: InputMaybe<IntegrationMappingCreateNestedManyWithoutOrganizationInput>;
  IntegrationMappingCustomObject?: InputMaybe<IntegrationMappingCustomObjectCreateNestedManyWithoutOrganizationInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogCreateNestedManyWithoutOrganizationInput>;
  IntegrationReadOnlyObject?: InputMaybe<IntegrationReadOnlyObjectCreateNestedManyWithoutOrganizationInput>;
  international?: InputMaybe<Scalars['Boolean']['input']>;
  is_email_domain_verified?: InputMaybe<Scalars['Boolean']['input']>;
  is_salesforce_sandbox?: InputMaybe<Scalars['Boolean']['input']>;
  last_lead_value_compute_time?: InputMaybe<Scalars['DateTime']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutOrganizationInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryCreateNestedManyWithoutOrganizationInput>;
  lead_intent?: InputMaybe<LeadIntentCreateNestedManyWithoutOrganizationInput>;
  lead_release_same_rep_delay_rule?: InputMaybe<Scalars['Int']['input']>;
  lead_routing_email_list?: InputMaybe<OrganizationCreatelead_Routing_Email_ListInput>;
  lead_routing_rules?: InputMaybe<RuleCreateNestedManyWithoutOrganizationInput>;
  LeadCustomObjectRowAssociation?: InputMaybe<LeadCustomObjectRowAssociationCreateNestedManyWithoutOrganizationInput>;
  LeadFieldMultiplier?: InputMaybe<LeadFieldMultiplierCreateNestedManyWithoutOrganizationInput>;
  LeadFieldMultiplierCustom?: InputMaybe<LeadFieldMultiplierCustomCreateNestedManyWithoutOrganizationInput>;
  leads?: InputMaybe<LeadCreateNestedManyWithoutOrganizationInput>;
  leadsource_options?: InputMaybe<LeadSourceOptionCreateNestedManyWithoutOrganizationInput>;
  LeadValue?: InputMaybe<LeadValueCreateNestedManyWithoutOrganizationInput>;
  LifeTimeValueMultiplier?: InputMaybe<LifeTimeValueMultiplierCreateNestedManyWithoutOrganizationInput>;
  LiveTransferSetting?: InputMaybe<LiveTransferSettingCreateNestedOneWithoutOrganizationInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusCreateNestedManyWithoutOrganizationInput>;
  ltv_multiplier_custom_fields?: InputMaybe<LifeTimeValueMultiplierCustomCreateNestedManyWithoutOrganizationInput>;
  made_contact_rule?: InputMaybe<Scalars['Int']['input']>;
  make_sale_address_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_business_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_city_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_country_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_first_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_industry_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_last_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_lead_source_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_primary_email_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_primary_phone_number_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_state_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_sub_industry_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_zip_required?: InputMaybe<Scalars['Boolean']['input']>;
  maxium_day_lag_set_to_schedule?: InputMaybe<Scalars['Int']['input']>;
  mrr?: InputMaybe<Scalars['Int']['input']>;
  mrr_label?: InputMaybe<Scalars['String']['input']>;
  mrr_required_on_sale?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  NoteItems?: InputMaybe<NoteItemCreateNestedManyWithoutOrganizationInput>;
  Notifications?: InputMaybe<NotificationCreateNestedManyWithoutOrganizationInput>;
  onboardComplete?: InputMaybe<Scalars['Boolean']['input']>;
  Opportunity?: InputMaybe<SfOpportunityCreateNestedManyWithoutOrganizationInput>;
  organization_holidays?: InputMaybe<OrganizationHolidayCreateNestedManyWithoutOrganizationInput>;
  organization_hours?: InputMaybe<OrganizationHourCreateNestedManyWithoutOrganizationInput>;
  OrganizationPaymentSetting?: InputMaybe<OrganizationPaymentSettingsCreateNestedOneWithoutOrganizationInput>;
  OrganizationPaymentSettings?: InputMaybe<OrganizationPaymentSettingsCreateNestedOneWithoutOrganizationsInput>;
  orgCalendarInviteMetadata?: InputMaybe<OrgCalendarInviteMetadataCreateNestedManyWithoutOrganizationInput>;
  OrgDefaultPipelinePerecentValue?: InputMaybe<OrgDefaultPipelinePerecentValueCreateNestedOneWithoutOrganizationInput>;
  OrgHubSpotSync?: InputMaybe<OrgHubSpotSyncCreateNestedOneWithoutOrganizationInput>;
  OrgNoEventDate?: InputMaybe<OrgNoEventDateCreateNestedManyWithoutOrganizationInput>;
  OrgSalesforceSync?: InputMaybe<OrgSalesforceSyncCreateNestedOneWithoutOrganizationInput>;
  OrgTimeslotAvailability?: InputMaybe<OrgTimeslotAvailabilityCreateNestedManyWithoutOrganizationInput>;
  outbound_close_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_dm_connect_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_lt_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_revenue_value_of_sale?: InputMaybe<Scalars['Float']['input']>;
  outbound_scheduled_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_set_rate?: InputMaybe<Scalars['Float']['input']>;
  PandadocData?: InputMaybe<PandadocDataCreateNestedOneWithoutOrganizationInput>;
  PandaDocTemplate?: InputMaybe<PandaDocTemplateCreateNestedManyWithoutOrganizationInput>;
  participant_jitter_buffer_size?: InputMaybe<JitterBufferSize>;
  preferred_goal_setting?: InputMaybe<Goalsetting>;
  prepayment?: InputMaybe<Scalars['Boolean']['input']>;
  ProductChangeLog?: InputMaybe<ProductChangeLogCreateNestedManyWithoutOrganizationInput>;
  products?: InputMaybe<ProductCreateNestedManyWithoutOrganizationInput>;
  re_cold_call_hour_delay?: InputMaybe<Scalars['Float']['input']>;
  recordingStatus?: InputMaybe<RecordingStatus>;
  release_lead_resting?: InputMaybe<Scalars['Int']['input']>;
  rep_claim_limit?: InputMaybe<Scalars['Int']['input']>;
  rep_lead_allotment?: InputMaybe<Scalars['Int']['input']>;
  rep_level_call_transfer?: InputMaybe<Scalars['Boolean']['input']>;
  reps_can_bulk_unassign?: InputMaybe<Scalars['Boolean']['input']>;
  reps_can_view_all_notes?: InputMaybe<Scalars['Boolean']['input']>;
  require_sale_notes?: InputMaybe<Scalars['Boolean']['input']>;
  resting_after_skipped_CC?: InputMaybe<Scalars['Int']['input']>;
  resting_period?: InputMaybe<Scalars['Int']['input']>;
  resting_period_after_unsuccessful_cold_call?: InputMaybe<Scalars['Float']['input']>;
  resting_period_after_unsuccessful_demo?: InputMaybe<Scalars['Float']['input']>;
  revenue_plan?: InputMaybe<RevenuePlanItemCreateNestedManyWithoutOrganizationInput>;
  Routings?: InputMaybe<RoutingCreateNestedManyWithoutOrganizationInput>;
  sale_field_options?: InputMaybe<SaleFieldOptionCreateNestedManyWithoutOrganizationInput>;
  sale_notification_email_list?: InputMaybe<OrganizationCreatesale_Notification_Email_ListInput>;
  SaleConfig?: InputMaybe<SaleConfigCreateNestedOneWithoutOrganizationInput>;
  SaleConfigPage?: InputMaybe<SaleConfigPageCreateNestedManyWithoutOrganizationInput>;
  SaleConfigSection?: InputMaybe<SaleConfigSectionCreateNestedManyWithoutOrganizationInput>;
  SaleCycles?: InputMaybe<SaleCycleCreateNestedManyWithoutOrganizationInput>;
  SaleItems?: InputMaybe<SaleItemCreateNestedManyWithoutOrganizationInput>;
  sales?: InputMaybe<SaleCreateNestedManyWithoutOrganizationInput>;
  salesforce_domain?: InputMaybe<Scalars['String']['input']>;
  salesforce_last_sync_date?: InputMaybe<Scalars['DateTime']['input']>;
  SalesForceColumn?: InputMaybe<SalesForceColumnCreateNestedManyWithoutOrganizationInput>;
  salesForceData?: InputMaybe<SalesForceDataCreateNestedOneWithoutOrganizationInput>;
  ScheduleItems?: InputMaybe<ScheduleItemCreateNestedManyWithoutOrganizationInput>;
  self_sourcing_rule?: InputMaybe<Scalars['Int']['input']>;
  send_error_log_email?: InputMaybe<Scalars['Boolean']['input']>;
  send_lead_routing_email?: InputMaybe<Scalars['Boolean']['input']>;
  sendgrid_email_domain?: InputMaybe<Scalars['String']['input']>;
  sendgrid_email_domain_id?: InputMaybe<Scalars['Int']['input']>;
  sequence?: InputMaybe<SequenceCreateNestedManyWithoutOrganzationInput>;
  SequenceCriteriaConditions?: InputMaybe<SequenceCriteriaConditionCreateNestedManyWithoutOrganizationInput>;
  SequenceDashboardViews?: InputMaybe<SequenceDashboardViewCreateNestedManyWithoutOrganizationInput>;
  SequenceEntryCriterias?: InputMaybe<SequenceEntryCriteriaCreateNestedManyWithoutOrganizationInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutOrganizationInput>;
  SequenceSteps?: InputMaybe<SequenceStepCreateNestedManyWithoutOrganizationInput>;
  set_goal?: InputMaybe<Scalars['Float']['input']>;
  short_resting?: InputMaybe<Scalars['Int']['input']>;
  show_calendar_options?: InputMaybe<Scalars['Boolean']['input']>;
  show_contract_duration_sales?: InputMaybe<Scalars['Boolean']['input']>;
  show_lead_source_to_rep?: InputMaybe<Scalars['Boolean']['input']>;
  show_nocontact_button?: InputMaybe<Scalars['Boolean']['input']>;
  show_payment_terms_sales?: InputMaybe<Scalars['Boolean']['input']>;
  show_rep_all_leads?: InputMaybe<Scalars['Boolean']['input']>;
  show_rep_my_leads?: InputMaybe<Scalars['Boolean']['input']>;
  show_system_view?: InputMaybe<Scalars['Boolean']['input']>;
  sites?: InputMaybe<SiteCreateNestedManyWithoutOrganizationInput>;
  sms_opt_in_dialog?: InputMaybe<Scalars['String']['input']>;
  SMSItems?: InputMaybe<SmsItemCreateNestedManyWithoutOrganizationInput>;
  SnoozeTrackings?: InputMaybe<SnoozeTrackingCreateNestedManyWithoutOrganizationInput>;
  StepActionLogs?: InputMaybe<StepActionLogCreateNestedManyWithoutOrganizationInput>;
  StepActions?: InputMaybe<StepActionCreateNestedManyWithoutOrganizationInput>;
  StepConditions?: InputMaybe<StepConditionCreateNestedManyWithoutOrganizationInput>;
  teams?: InputMaybe<TeamCreateNestedManyWithoutOrganizationInput>;
  Template?: InputMaybe<TemplateCreateNestedManyWithoutOrganizationInput>;
  TemplateVersions?: InputMaybe<TemplateVersionCreateNestedManyWithoutOrganizationInput>;
  timekit_id?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  transfer_timeout?: InputMaybe<Scalars['Int']['input']>;
  TransferAttempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutOrganizationInput>;
  twilio_numbers?: InputMaybe<TwilioNumberCreateNestedManyWithoutOrganizationInput>;
  TwilioFeedbacks?: InputMaybe<TwilioFeedbackCreateNestedManyWithoutOrganizationInput>;
  TwilioInfo?: InputMaybe<TwilioInfoCreateNestedOneWithoutOrganzationInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  use_revenue_planning?: InputMaybe<Scalars['Boolean']['input']>;
  UserCustomFieldFilters?: InputMaybe<UserCustomFieldFilterCreateNestedManyWithoutOrganizationInput>;
  users?: InputMaybe<UserCreateNestedManyWithoutOrganizationInput>;
  win_rep_day_goal?: InputMaybe<Scalars['Float']['input']>;
};

export type OrganizationCreateWithoutContactsInput = {
  ActivityMonitorMultiplier?: InputMaybe<ActivityMonitorMultiplierCreateNestedManyWithoutOrganizationInput>;
  algoFactor?: InputMaybe<AlgoFactorCreateNestedManyWithoutOrganizationInput>;
  allow_calendar_invite?: InputMaybe<Scalars['Boolean']['input']>;
  allow_remove_step_action?: InputMaybe<Scalars['Boolean']['input']>;
  allow_snooze_step_action?: InputMaybe<Scalars['Boolean']['input']>;
  always_record?: InputMaybe<Scalars['Boolean']['input']>;
  arv_initial_held_demos_generated_by_sdr_weight?: InputMaybe<Scalars['Float']['input']>;
  AssociationHistories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutOrganizationInput>;
  auto_call_interval?: InputMaybe<Scalars['Int']['input']>;
  auto_dial?: InputMaybe<Scalars['Boolean']['input']>;
  automated_campaigns?: InputMaybe<AutomatedCampaignCreateNestedManyWithoutOrganizationInput>;
  automatic_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  AutoTransferSelections?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutOrganizationInput>;
  Availablities?: InputMaybe<AvailabilityCreateNestedManyWithoutOrganizationInput>;
  AverageOrderValue?: InputMaybe<AverageOrderValueCreateNestedManyWithoutOrganizationInput>;
  best_action_ever_multiplier?: InputMaybe<Scalars['Float']['input']>;
  best_metrics?: InputMaybe<BestMetricRecordCreateNestedManyWithoutOrganizationInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptCreateNestedManyWithoutOrganizationInput>;
  callMeNows?: InputMaybe<CallMeNowCreateNestedManyWithoutOrganizationInput>;
  CallMultiplier?: InputMaybe<CallMultiplierCreateNestedManyWithoutOrganizationInput>;
  CallSharings?: InputMaybe<CallSharingCreateNestedManyWithoutOrganizationInput>;
  can_edit_sdr_transfer_rules?: InputMaybe<Scalars['Boolean']['input']>;
  claim_rule?: InputMaybe<Scalars['Int']['input']>;
  concession?: InputMaybe<ConcessionCreateNestedManyWithoutOrganizationInput>;
  conference_region?: InputMaybe<ConferenceRegion>;
  Conferences?: InputMaybe<ConferenceCreateNestedManyWithoutOrganizationInput>;
  ContactRequests?: InputMaybe<ContactRequestCreateNestedManyWithoutOrganizationInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  custom_fields?: InputMaybe<CustomFieldCreateNestedManyWithoutOrganizationInput>;
  CustomObject?: InputMaybe<CustomObjectCreateNestedManyWithoutOrganizationInput>;
  CustomObjectField?: InputMaybe<CustomObjectFieldCreateNestedManyWithoutOrganizationInput>;
  CustomObjectFieldForeignKey?: InputMaybe<CustomObjectFieldForeignKeyCreateNestedManyWithoutOrganizationInput>;
  CustomObjectRecord?: InputMaybe<CustomObjectRecordCreateNestedManyWithoutOrganizationInput>;
  CustomObjectRow?: InputMaybe<CustomObjectRowCreateNestedManyWithoutOrganizationInput>;
  CustomQueueItems?: InputMaybe<CustomQueueItemCreateNestedManyWithoutOrganizationInput>;
  DashboardColumnOptions?: InputMaybe<DashboardColumnOptionCreateNestedManyWithoutOrganizationInput>;
  DashboardLeadFilters?: InputMaybe<DashboardLeadFilterCreateNestedManyWithoutOrganizationInput>;
  DashboardRepFilters?: InputMaybe<DashboardRepFilterCreateNestedManyWithoutOrganizationInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewCreateNestedManyWithoutOrganizationInput>;
  day_to_close?: InputMaybe<Scalars['Int']['input']>;
  default_demo_time_duration?: InputMaybe<Scalars['Int']['input']>;
  demo_held_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_not_held_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_not_held_call_back_short_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_prep_time_minutes?: InputMaybe<Scalars['Int']['input']>;
  dev_account?: InputMaybe<Scalars['Boolean']['input']>;
  dial_rule?: InputMaybe<Scalars['Int']['input']>;
  DispositionMultiplier?: InputMaybe<DispositionMultiplierCreateNestedManyWithoutOrganizationInput>;
  dispositions?: InputMaybe<DispositionCreateNestedManyWithoutOrganizationInput>;
  double_booking?: InputMaybe<Scalars['Boolean']['input']>;
  dynamic_date_operators?: InputMaybe<Scalars['Boolean']['input']>;
  edge_servers?: InputMaybe<OrganizationCreateedge_ServersInput>;
  email_conflict_notification_email_list?: InputMaybe<OrganizationCreateemail_Conflict_Notification_Email_ListInput>;
  EmailItems?: InputMaybe<EmailItemCreateNestedManyWithoutOrganizationInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordCreateNestedManyWithoutOrganizationInput>;
  EmailThreads?: InputMaybe<EmailThreadCreateNestedManyWithoutOrganizationInput>;
  enable_modified_metrics?: InputMaybe<Scalars['Boolean']['input']>;
  enable_pay_period_view?: InputMaybe<Scalars['Boolean']['input']>;
  enable_time_constraint?: InputMaybe<Scalars['Boolean']['input']>;
  error_log_email_address?: InputMaybe<OrganizationCreateerror_Log_Email_AddressInput>;
  estimated_average_num_of_weekly_overbooked_demo_slots?: InputMaybe<Scalars['Int']['input']>;
  FolderPermissions?: InputMaybe<FolderPermissionCreateNestedManyWithoutOrganizationInput>;
  Folders?: InputMaybe<FolderCreateNestedManyWithoutOrganizationInput>;
  FutureBookingSetting?: InputMaybe<FutureBookingSettingCreateNestedOneWithoutOrganizationInput>;
  GlobalTransferRule?: InputMaybe<GlobalTransferRuleCreateNestedManyWithoutOrganizationInput>;
  held_demos_per_cc_connect?: InputMaybe<Scalars['Float']['input']>;
  helpful_links?: InputMaybe<OrganizationCreatehelpful_LinksInput>;
  HingePointMultiplier?: InputMaybe<HingePointMultiplierCreateNestedManyWithoutOrganizationInput>;
  hold_goal?: InputMaybe<Scalars['Float']['input']>;
  hubspot_credential?: InputMaybe<HubSpotCredentialCreateNestedOneWithoutOrganizationInput>;
  hubspot_last_sync_date?: InputMaybe<Scalars['DateTime']['input']>;
  hubspot_white_list?: InputMaybe<HubSpotWhitelistCreateNestedManyWithoutOrganizationInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  inbound_close_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_dm_connect_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_lt_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_revenue_value_of_sale?: InputMaybe<Scalars['Float']['input']>;
  inbound_scheduled_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_set_rate?: InputMaybe<Scalars['Float']['input']>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventCreateNestedManyWithoutOrganizationInput>;
  InboundConciergeWidget?: InputMaybe<InboundConciergeWidgetCreateNestedManyWithoutOrganizationInput>;
  industry_options?: InputMaybe<IndustryOptionCreateNestedManyWithoutOrganizationInput>;
  initial_sdr_sets_reps_day?: InputMaybe<Scalars['Float']['input']>;
  integration_error_logs?: InputMaybe<IntegrationErrorLogCreateNestedManyWithoutOrganizationInput>;
  integration_mappings?: InputMaybe<IntegrationMappingCreateNestedManyWithoutOrganizationInput>;
  IntegrationMappingCustomObject?: InputMaybe<IntegrationMappingCustomObjectCreateNestedManyWithoutOrganizationInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogCreateNestedManyWithoutOrganizationInput>;
  IntegrationReadOnlyObject?: InputMaybe<IntegrationReadOnlyObjectCreateNestedManyWithoutOrganizationInput>;
  international?: InputMaybe<Scalars['Boolean']['input']>;
  is_email_domain_verified?: InputMaybe<Scalars['Boolean']['input']>;
  is_salesforce_sandbox?: InputMaybe<Scalars['Boolean']['input']>;
  last_lead_value_compute_time?: InputMaybe<Scalars['DateTime']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutOrganizationInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryCreateNestedManyWithoutOrganizationInput>;
  lead_intent?: InputMaybe<LeadIntentCreateNestedManyWithoutOrganizationInput>;
  lead_release_same_rep_delay_rule?: InputMaybe<Scalars['Int']['input']>;
  lead_routing_email_list?: InputMaybe<OrganizationCreatelead_Routing_Email_ListInput>;
  lead_routing_rules?: InputMaybe<RuleCreateNestedManyWithoutOrganizationInput>;
  LeadCustomObjectRowAssociation?: InputMaybe<LeadCustomObjectRowAssociationCreateNestedManyWithoutOrganizationInput>;
  LeadFieldMultiplier?: InputMaybe<LeadFieldMultiplierCreateNestedManyWithoutOrganizationInput>;
  LeadFieldMultiplierCustom?: InputMaybe<LeadFieldMultiplierCustomCreateNestedManyWithoutOrganizationInput>;
  leads?: InputMaybe<LeadCreateNestedManyWithoutOrganizationInput>;
  leadsource_options?: InputMaybe<LeadSourceOptionCreateNestedManyWithoutOrganizationInput>;
  LeadValue?: InputMaybe<LeadValueCreateNestedManyWithoutOrganizationInput>;
  LifeTimeValueMultiplier?: InputMaybe<LifeTimeValueMultiplierCreateNestedManyWithoutOrganizationInput>;
  LiveTransferSetting?: InputMaybe<LiveTransferSettingCreateNestedOneWithoutOrganizationInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusCreateNestedManyWithoutOrganizationInput>;
  ltv_multiplier_custom_fields?: InputMaybe<LifeTimeValueMultiplierCustomCreateNestedManyWithoutOrganizationInput>;
  made_contact_rule?: InputMaybe<Scalars['Int']['input']>;
  make_sale_address_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_business_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_city_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_country_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_first_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_industry_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_last_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_lead_source_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_primary_email_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_primary_phone_number_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_state_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_sub_industry_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_zip_required?: InputMaybe<Scalars['Boolean']['input']>;
  maxium_day_lag_set_to_schedule?: InputMaybe<Scalars['Int']['input']>;
  mrr?: InputMaybe<Scalars['Int']['input']>;
  mrr_label?: InputMaybe<Scalars['String']['input']>;
  mrr_required_on_sale?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  NoteItems?: InputMaybe<NoteItemCreateNestedManyWithoutOrganizationInput>;
  Notifications?: InputMaybe<NotificationCreateNestedManyWithoutOrganizationInput>;
  onboardComplete?: InputMaybe<Scalars['Boolean']['input']>;
  Opportunity?: InputMaybe<SfOpportunityCreateNestedManyWithoutOrganizationInput>;
  organization_holidays?: InputMaybe<OrganizationHolidayCreateNestedManyWithoutOrganizationInput>;
  organization_hours?: InputMaybe<OrganizationHourCreateNestedManyWithoutOrganizationInput>;
  OrganizationPaymentSetting?: InputMaybe<OrganizationPaymentSettingsCreateNestedOneWithoutOrganizationInput>;
  OrganizationPaymentSettings?: InputMaybe<OrganizationPaymentSettingsCreateNestedOneWithoutOrganizationsInput>;
  orgCalendarInviteMetadata?: InputMaybe<OrgCalendarInviteMetadataCreateNestedManyWithoutOrganizationInput>;
  OrgDefaultPipelinePerecentValue?: InputMaybe<OrgDefaultPipelinePerecentValueCreateNestedOneWithoutOrganizationInput>;
  OrgHubSpotSync?: InputMaybe<OrgHubSpotSyncCreateNestedOneWithoutOrganizationInput>;
  OrgNoEventDate?: InputMaybe<OrgNoEventDateCreateNestedManyWithoutOrganizationInput>;
  OrgSalesforceSync?: InputMaybe<OrgSalesforceSyncCreateNestedOneWithoutOrganizationInput>;
  OrgTimeslotAvailability?: InputMaybe<OrgTimeslotAvailabilityCreateNestedManyWithoutOrganizationInput>;
  outbound_close_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_dm_connect_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_lt_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_revenue_value_of_sale?: InputMaybe<Scalars['Float']['input']>;
  outbound_scheduled_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_set_rate?: InputMaybe<Scalars['Float']['input']>;
  PandadocData?: InputMaybe<PandadocDataCreateNestedOneWithoutOrganizationInput>;
  PandaDocTemplate?: InputMaybe<PandaDocTemplateCreateNestedManyWithoutOrganizationInput>;
  participant_jitter_buffer_size?: InputMaybe<JitterBufferSize>;
  preferred_goal_setting?: InputMaybe<Goalsetting>;
  prepayment?: InputMaybe<Scalars['Boolean']['input']>;
  ProductChangeLog?: InputMaybe<ProductChangeLogCreateNestedManyWithoutOrganizationInput>;
  products?: InputMaybe<ProductCreateNestedManyWithoutOrganizationInput>;
  re_cold_call_hour_delay?: InputMaybe<Scalars['Float']['input']>;
  recordingStatus?: InputMaybe<RecordingStatus>;
  release_lead_resting?: InputMaybe<Scalars['Int']['input']>;
  rep_claim_limit?: InputMaybe<Scalars['Int']['input']>;
  rep_lead_allotment?: InputMaybe<Scalars['Int']['input']>;
  rep_level_call_transfer?: InputMaybe<Scalars['Boolean']['input']>;
  reps_can_bulk_unassign?: InputMaybe<Scalars['Boolean']['input']>;
  reps_can_view_all_notes?: InputMaybe<Scalars['Boolean']['input']>;
  require_sale_notes?: InputMaybe<Scalars['Boolean']['input']>;
  resting_after_skipped_CC?: InputMaybe<Scalars['Int']['input']>;
  resting_period?: InputMaybe<Scalars['Int']['input']>;
  resting_period_after_unsuccessful_cold_call?: InputMaybe<Scalars['Float']['input']>;
  resting_period_after_unsuccessful_demo?: InputMaybe<Scalars['Float']['input']>;
  revenue_plan?: InputMaybe<RevenuePlanItemCreateNestedManyWithoutOrganizationInput>;
  Routings?: InputMaybe<RoutingCreateNestedManyWithoutOrganizationInput>;
  sale_field_options?: InputMaybe<SaleFieldOptionCreateNestedManyWithoutOrganizationInput>;
  sale_notification_email_list?: InputMaybe<OrganizationCreatesale_Notification_Email_ListInput>;
  SaleConfig?: InputMaybe<SaleConfigCreateNestedOneWithoutOrganizationInput>;
  SaleConfigPage?: InputMaybe<SaleConfigPageCreateNestedManyWithoutOrganizationInput>;
  SaleConfigSection?: InputMaybe<SaleConfigSectionCreateNestedManyWithoutOrganizationInput>;
  SaleCycles?: InputMaybe<SaleCycleCreateNestedManyWithoutOrganizationInput>;
  SaleItems?: InputMaybe<SaleItemCreateNestedManyWithoutOrganizationInput>;
  sales?: InputMaybe<SaleCreateNestedManyWithoutOrganizationInput>;
  salesforce_domain?: InputMaybe<Scalars['String']['input']>;
  salesforce_last_sync_date?: InputMaybe<Scalars['DateTime']['input']>;
  SalesForceColumn?: InputMaybe<SalesForceColumnCreateNestedManyWithoutOrganizationInput>;
  salesForceData?: InputMaybe<SalesForceDataCreateNestedOneWithoutOrganizationInput>;
  ScheduleItems?: InputMaybe<ScheduleItemCreateNestedManyWithoutOrganizationInput>;
  self_sourcing_rule?: InputMaybe<Scalars['Int']['input']>;
  send_error_log_email?: InputMaybe<Scalars['Boolean']['input']>;
  send_lead_routing_email?: InputMaybe<Scalars['Boolean']['input']>;
  sendgrid_email_domain?: InputMaybe<Scalars['String']['input']>;
  sendgrid_email_domain_id?: InputMaybe<Scalars['Int']['input']>;
  sequence?: InputMaybe<SequenceCreateNestedManyWithoutOrganzationInput>;
  SequenceCriteriaConditions?: InputMaybe<SequenceCriteriaConditionCreateNestedManyWithoutOrganizationInput>;
  SequenceDashboardViews?: InputMaybe<SequenceDashboardViewCreateNestedManyWithoutOrganizationInput>;
  SequenceEntryCriterias?: InputMaybe<SequenceEntryCriteriaCreateNestedManyWithoutOrganizationInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutOrganizationInput>;
  SequenceSteps?: InputMaybe<SequenceStepCreateNestedManyWithoutOrganizationInput>;
  set_goal?: InputMaybe<Scalars['Float']['input']>;
  short_resting?: InputMaybe<Scalars['Int']['input']>;
  show_calendar_options?: InputMaybe<Scalars['Boolean']['input']>;
  show_contract_duration_sales?: InputMaybe<Scalars['Boolean']['input']>;
  show_lead_source_to_rep?: InputMaybe<Scalars['Boolean']['input']>;
  show_nocontact_button?: InputMaybe<Scalars['Boolean']['input']>;
  show_payment_terms_sales?: InputMaybe<Scalars['Boolean']['input']>;
  show_rep_all_leads?: InputMaybe<Scalars['Boolean']['input']>;
  show_rep_my_leads?: InputMaybe<Scalars['Boolean']['input']>;
  show_system_view?: InputMaybe<Scalars['Boolean']['input']>;
  sites?: InputMaybe<SiteCreateNestedManyWithoutOrganizationInput>;
  sms_opt_in_dialog?: InputMaybe<Scalars['String']['input']>;
  SMSItems?: InputMaybe<SmsItemCreateNestedManyWithoutOrganizationInput>;
  SnoozeTrackings?: InputMaybe<SnoozeTrackingCreateNestedManyWithoutOrganizationInput>;
  StepActionLogs?: InputMaybe<StepActionLogCreateNestedManyWithoutOrganizationInput>;
  StepActions?: InputMaybe<StepActionCreateNestedManyWithoutOrganizationInput>;
  StepConditions?: InputMaybe<StepConditionCreateNestedManyWithoutOrganizationInput>;
  teams?: InputMaybe<TeamCreateNestedManyWithoutOrganizationInput>;
  Template?: InputMaybe<TemplateCreateNestedManyWithoutOrganizationInput>;
  TemplateVersions?: InputMaybe<TemplateVersionCreateNestedManyWithoutOrganizationInput>;
  timekit_id?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  transfer_timeout?: InputMaybe<Scalars['Int']['input']>;
  TransferAttempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutOrganizationInput>;
  twilio_numbers?: InputMaybe<TwilioNumberCreateNestedManyWithoutOrganizationInput>;
  TwilioFeedbacks?: InputMaybe<TwilioFeedbackCreateNestedManyWithoutOrganizationInput>;
  TwilioInfo?: InputMaybe<TwilioInfoCreateNestedOneWithoutOrganzationInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  use_revenue_planning?: InputMaybe<Scalars['Boolean']['input']>;
  UserCustomFieldFilters?: InputMaybe<UserCustomFieldFilterCreateNestedManyWithoutOrganizationInput>;
  users?: InputMaybe<UserCreateNestedManyWithoutOrganizationInput>;
  win_rep_day_goal?: InputMaybe<Scalars['Float']['input']>;
};

export type OrganizationCreateWithoutCustom_FieldsInput = {
  ActivityMonitorMultiplier?: InputMaybe<ActivityMonitorMultiplierCreateNestedManyWithoutOrganizationInput>;
  algoFactor?: InputMaybe<AlgoFactorCreateNestedManyWithoutOrganizationInput>;
  allow_calendar_invite?: InputMaybe<Scalars['Boolean']['input']>;
  allow_remove_step_action?: InputMaybe<Scalars['Boolean']['input']>;
  allow_snooze_step_action?: InputMaybe<Scalars['Boolean']['input']>;
  always_record?: InputMaybe<Scalars['Boolean']['input']>;
  arv_initial_held_demos_generated_by_sdr_weight?: InputMaybe<Scalars['Float']['input']>;
  AssociationHistories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutOrganizationInput>;
  auto_call_interval?: InputMaybe<Scalars['Int']['input']>;
  auto_dial?: InputMaybe<Scalars['Boolean']['input']>;
  automated_campaigns?: InputMaybe<AutomatedCampaignCreateNestedManyWithoutOrganizationInput>;
  automatic_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  AutoTransferSelections?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutOrganizationInput>;
  Availablities?: InputMaybe<AvailabilityCreateNestedManyWithoutOrganizationInput>;
  AverageOrderValue?: InputMaybe<AverageOrderValueCreateNestedManyWithoutOrganizationInput>;
  best_action_ever_multiplier?: InputMaybe<Scalars['Float']['input']>;
  best_metrics?: InputMaybe<BestMetricRecordCreateNestedManyWithoutOrganizationInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptCreateNestedManyWithoutOrganizationInput>;
  callMeNows?: InputMaybe<CallMeNowCreateNestedManyWithoutOrganizationInput>;
  CallMultiplier?: InputMaybe<CallMultiplierCreateNestedManyWithoutOrganizationInput>;
  CallSharings?: InputMaybe<CallSharingCreateNestedManyWithoutOrganizationInput>;
  can_edit_sdr_transfer_rules?: InputMaybe<Scalars['Boolean']['input']>;
  claim_rule?: InputMaybe<Scalars['Int']['input']>;
  concession?: InputMaybe<ConcessionCreateNestedManyWithoutOrganizationInput>;
  conference_region?: InputMaybe<ConferenceRegion>;
  Conferences?: InputMaybe<ConferenceCreateNestedManyWithoutOrganizationInput>;
  ContactRequests?: InputMaybe<ContactRequestCreateNestedManyWithoutOrganizationInput>;
  Contacts?: InputMaybe<ContactCreateNestedManyWithoutOrganizationInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  CustomObject?: InputMaybe<CustomObjectCreateNestedManyWithoutOrganizationInput>;
  CustomObjectField?: InputMaybe<CustomObjectFieldCreateNestedManyWithoutOrganizationInput>;
  CustomObjectFieldForeignKey?: InputMaybe<CustomObjectFieldForeignKeyCreateNestedManyWithoutOrganizationInput>;
  CustomObjectRecord?: InputMaybe<CustomObjectRecordCreateNestedManyWithoutOrganizationInput>;
  CustomObjectRow?: InputMaybe<CustomObjectRowCreateNestedManyWithoutOrganizationInput>;
  CustomQueueItems?: InputMaybe<CustomQueueItemCreateNestedManyWithoutOrganizationInput>;
  DashboardColumnOptions?: InputMaybe<DashboardColumnOptionCreateNestedManyWithoutOrganizationInput>;
  DashboardLeadFilters?: InputMaybe<DashboardLeadFilterCreateNestedManyWithoutOrganizationInput>;
  DashboardRepFilters?: InputMaybe<DashboardRepFilterCreateNestedManyWithoutOrganizationInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewCreateNestedManyWithoutOrganizationInput>;
  day_to_close?: InputMaybe<Scalars['Int']['input']>;
  default_demo_time_duration?: InputMaybe<Scalars['Int']['input']>;
  demo_held_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_not_held_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_not_held_call_back_short_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_prep_time_minutes?: InputMaybe<Scalars['Int']['input']>;
  dev_account?: InputMaybe<Scalars['Boolean']['input']>;
  dial_rule?: InputMaybe<Scalars['Int']['input']>;
  DispositionMultiplier?: InputMaybe<DispositionMultiplierCreateNestedManyWithoutOrganizationInput>;
  dispositions?: InputMaybe<DispositionCreateNestedManyWithoutOrganizationInput>;
  double_booking?: InputMaybe<Scalars['Boolean']['input']>;
  dynamic_date_operators?: InputMaybe<Scalars['Boolean']['input']>;
  edge_servers?: InputMaybe<OrganizationCreateedge_ServersInput>;
  email_conflict_notification_email_list?: InputMaybe<OrganizationCreateemail_Conflict_Notification_Email_ListInput>;
  EmailItems?: InputMaybe<EmailItemCreateNestedManyWithoutOrganizationInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordCreateNestedManyWithoutOrganizationInput>;
  EmailThreads?: InputMaybe<EmailThreadCreateNestedManyWithoutOrganizationInput>;
  enable_modified_metrics?: InputMaybe<Scalars['Boolean']['input']>;
  enable_pay_period_view?: InputMaybe<Scalars['Boolean']['input']>;
  enable_time_constraint?: InputMaybe<Scalars['Boolean']['input']>;
  error_log_email_address?: InputMaybe<OrganizationCreateerror_Log_Email_AddressInput>;
  estimated_average_num_of_weekly_overbooked_demo_slots?: InputMaybe<Scalars['Int']['input']>;
  FolderPermissions?: InputMaybe<FolderPermissionCreateNestedManyWithoutOrganizationInput>;
  Folders?: InputMaybe<FolderCreateNestedManyWithoutOrganizationInput>;
  FutureBookingSetting?: InputMaybe<FutureBookingSettingCreateNestedOneWithoutOrganizationInput>;
  GlobalTransferRule?: InputMaybe<GlobalTransferRuleCreateNestedManyWithoutOrganizationInput>;
  held_demos_per_cc_connect?: InputMaybe<Scalars['Float']['input']>;
  helpful_links?: InputMaybe<OrganizationCreatehelpful_LinksInput>;
  HingePointMultiplier?: InputMaybe<HingePointMultiplierCreateNestedManyWithoutOrganizationInput>;
  hold_goal?: InputMaybe<Scalars['Float']['input']>;
  hubspot_credential?: InputMaybe<HubSpotCredentialCreateNestedOneWithoutOrganizationInput>;
  hubspot_last_sync_date?: InputMaybe<Scalars['DateTime']['input']>;
  hubspot_white_list?: InputMaybe<HubSpotWhitelistCreateNestedManyWithoutOrganizationInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  inbound_close_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_dm_connect_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_lt_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_revenue_value_of_sale?: InputMaybe<Scalars['Float']['input']>;
  inbound_scheduled_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_set_rate?: InputMaybe<Scalars['Float']['input']>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventCreateNestedManyWithoutOrganizationInput>;
  InboundConciergeWidget?: InputMaybe<InboundConciergeWidgetCreateNestedManyWithoutOrganizationInput>;
  industry_options?: InputMaybe<IndustryOptionCreateNestedManyWithoutOrganizationInput>;
  initial_sdr_sets_reps_day?: InputMaybe<Scalars['Float']['input']>;
  integration_error_logs?: InputMaybe<IntegrationErrorLogCreateNestedManyWithoutOrganizationInput>;
  integration_mappings?: InputMaybe<IntegrationMappingCreateNestedManyWithoutOrganizationInput>;
  IntegrationMappingCustomObject?: InputMaybe<IntegrationMappingCustomObjectCreateNestedManyWithoutOrganizationInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogCreateNestedManyWithoutOrganizationInput>;
  IntegrationReadOnlyObject?: InputMaybe<IntegrationReadOnlyObjectCreateNestedManyWithoutOrganizationInput>;
  international?: InputMaybe<Scalars['Boolean']['input']>;
  is_email_domain_verified?: InputMaybe<Scalars['Boolean']['input']>;
  is_salesforce_sandbox?: InputMaybe<Scalars['Boolean']['input']>;
  last_lead_value_compute_time?: InputMaybe<Scalars['DateTime']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutOrganizationInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryCreateNestedManyWithoutOrganizationInput>;
  lead_intent?: InputMaybe<LeadIntentCreateNestedManyWithoutOrganizationInput>;
  lead_release_same_rep_delay_rule?: InputMaybe<Scalars['Int']['input']>;
  lead_routing_email_list?: InputMaybe<OrganizationCreatelead_Routing_Email_ListInput>;
  lead_routing_rules?: InputMaybe<RuleCreateNestedManyWithoutOrganizationInput>;
  LeadCustomObjectRowAssociation?: InputMaybe<LeadCustomObjectRowAssociationCreateNestedManyWithoutOrganizationInput>;
  LeadFieldMultiplier?: InputMaybe<LeadFieldMultiplierCreateNestedManyWithoutOrganizationInput>;
  LeadFieldMultiplierCustom?: InputMaybe<LeadFieldMultiplierCustomCreateNestedManyWithoutOrganizationInput>;
  leads?: InputMaybe<LeadCreateNestedManyWithoutOrganizationInput>;
  leadsource_options?: InputMaybe<LeadSourceOptionCreateNestedManyWithoutOrganizationInput>;
  LeadValue?: InputMaybe<LeadValueCreateNestedManyWithoutOrganizationInput>;
  LifeTimeValueMultiplier?: InputMaybe<LifeTimeValueMultiplierCreateNestedManyWithoutOrganizationInput>;
  LiveTransferSetting?: InputMaybe<LiveTransferSettingCreateNestedOneWithoutOrganizationInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusCreateNestedManyWithoutOrganizationInput>;
  ltv_multiplier_custom_fields?: InputMaybe<LifeTimeValueMultiplierCustomCreateNestedManyWithoutOrganizationInput>;
  made_contact_rule?: InputMaybe<Scalars['Int']['input']>;
  make_sale_address_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_business_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_city_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_country_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_first_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_industry_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_last_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_lead_source_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_primary_email_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_primary_phone_number_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_state_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_sub_industry_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_zip_required?: InputMaybe<Scalars['Boolean']['input']>;
  maxium_day_lag_set_to_schedule?: InputMaybe<Scalars['Int']['input']>;
  mrr?: InputMaybe<Scalars['Int']['input']>;
  mrr_label?: InputMaybe<Scalars['String']['input']>;
  mrr_required_on_sale?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  NoteItems?: InputMaybe<NoteItemCreateNestedManyWithoutOrganizationInput>;
  Notifications?: InputMaybe<NotificationCreateNestedManyWithoutOrganizationInput>;
  onboardComplete?: InputMaybe<Scalars['Boolean']['input']>;
  Opportunity?: InputMaybe<SfOpportunityCreateNestedManyWithoutOrganizationInput>;
  organization_holidays?: InputMaybe<OrganizationHolidayCreateNestedManyWithoutOrganizationInput>;
  organization_hours?: InputMaybe<OrganizationHourCreateNestedManyWithoutOrganizationInput>;
  OrganizationPaymentSetting?: InputMaybe<OrganizationPaymentSettingsCreateNestedOneWithoutOrganizationInput>;
  OrganizationPaymentSettings?: InputMaybe<OrganizationPaymentSettingsCreateNestedOneWithoutOrganizationsInput>;
  orgCalendarInviteMetadata?: InputMaybe<OrgCalendarInviteMetadataCreateNestedManyWithoutOrganizationInput>;
  OrgDefaultPipelinePerecentValue?: InputMaybe<OrgDefaultPipelinePerecentValueCreateNestedOneWithoutOrganizationInput>;
  OrgHubSpotSync?: InputMaybe<OrgHubSpotSyncCreateNestedOneWithoutOrganizationInput>;
  OrgNoEventDate?: InputMaybe<OrgNoEventDateCreateNestedManyWithoutOrganizationInput>;
  OrgSalesforceSync?: InputMaybe<OrgSalesforceSyncCreateNestedOneWithoutOrganizationInput>;
  OrgTimeslotAvailability?: InputMaybe<OrgTimeslotAvailabilityCreateNestedManyWithoutOrganizationInput>;
  outbound_close_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_dm_connect_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_lt_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_revenue_value_of_sale?: InputMaybe<Scalars['Float']['input']>;
  outbound_scheduled_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_set_rate?: InputMaybe<Scalars['Float']['input']>;
  PandadocData?: InputMaybe<PandadocDataCreateNestedOneWithoutOrganizationInput>;
  PandaDocTemplate?: InputMaybe<PandaDocTemplateCreateNestedManyWithoutOrganizationInput>;
  participant_jitter_buffer_size?: InputMaybe<JitterBufferSize>;
  preferred_goal_setting?: InputMaybe<Goalsetting>;
  prepayment?: InputMaybe<Scalars['Boolean']['input']>;
  ProductChangeLog?: InputMaybe<ProductChangeLogCreateNestedManyWithoutOrganizationInput>;
  products?: InputMaybe<ProductCreateNestedManyWithoutOrganizationInput>;
  re_cold_call_hour_delay?: InputMaybe<Scalars['Float']['input']>;
  recordingStatus?: InputMaybe<RecordingStatus>;
  release_lead_resting?: InputMaybe<Scalars['Int']['input']>;
  rep_claim_limit?: InputMaybe<Scalars['Int']['input']>;
  rep_lead_allotment?: InputMaybe<Scalars['Int']['input']>;
  rep_level_call_transfer?: InputMaybe<Scalars['Boolean']['input']>;
  reps_can_bulk_unassign?: InputMaybe<Scalars['Boolean']['input']>;
  reps_can_view_all_notes?: InputMaybe<Scalars['Boolean']['input']>;
  require_sale_notes?: InputMaybe<Scalars['Boolean']['input']>;
  resting_after_skipped_CC?: InputMaybe<Scalars['Int']['input']>;
  resting_period?: InputMaybe<Scalars['Int']['input']>;
  resting_period_after_unsuccessful_cold_call?: InputMaybe<Scalars['Float']['input']>;
  resting_period_after_unsuccessful_demo?: InputMaybe<Scalars['Float']['input']>;
  revenue_plan?: InputMaybe<RevenuePlanItemCreateNestedManyWithoutOrganizationInput>;
  Routings?: InputMaybe<RoutingCreateNestedManyWithoutOrganizationInput>;
  sale_field_options?: InputMaybe<SaleFieldOptionCreateNestedManyWithoutOrganizationInput>;
  sale_notification_email_list?: InputMaybe<OrganizationCreatesale_Notification_Email_ListInput>;
  SaleConfig?: InputMaybe<SaleConfigCreateNestedOneWithoutOrganizationInput>;
  SaleConfigPage?: InputMaybe<SaleConfigPageCreateNestedManyWithoutOrganizationInput>;
  SaleConfigSection?: InputMaybe<SaleConfigSectionCreateNestedManyWithoutOrganizationInput>;
  SaleCycles?: InputMaybe<SaleCycleCreateNestedManyWithoutOrganizationInput>;
  SaleItems?: InputMaybe<SaleItemCreateNestedManyWithoutOrganizationInput>;
  sales?: InputMaybe<SaleCreateNestedManyWithoutOrganizationInput>;
  salesforce_domain?: InputMaybe<Scalars['String']['input']>;
  salesforce_last_sync_date?: InputMaybe<Scalars['DateTime']['input']>;
  SalesForceColumn?: InputMaybe<SalesForceColumnCreateNestedManyWithoutOrganizationInput>;
  salesForceData?: InputMaybe<SalesForceDataCreateNestedOneWithoutOrganizationInput>;
  ScheduleItems?: InputMaybe<ScheduleItemCreateNestedManyWithoutOrganizationInput>;
  self_sourcing_rule?: InputMaybe<Scalars['Int']['input']>;
  send_error_log_email?: InputMaybe<Scalars['Boolean']['input']>;
  send_lead_routing_email?: InputMaybe<Scalars['Boolean']['input']>;
  sendgrid_email_domain?: InputMaybe<Scalars['String']['input']>;
  sendgrid_email_domain_id?: InputMaybe<Scalars['Int']['input']>;
  sequence?: InputMaybe<SequenceCreateNestedManyWithoutOrganzationInput>;
  SequenceCriteriaConditions?: InputMaybe<SequenceCriteriaConditionCreateNestedManyWithoutOrganizationInput>;
  SequenceDashboardViews?: InputMaybe<SequenceDashboardViewCreateNestedManyWithoutOrganizationInput>;
  SequenceEntryCriterias?: InputMaybe<SequenceEntryCriteriaCreateNestedManyWithoutOrganizationInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutOrganizationInput>;
  SequenceSteps?: InputMaybe<SequenceStepCreateNestedManyWithoutOrganizationInput>;
  set_goal?: InputMaybe<Scalars['Float']['input']>;
  short_resting?: InputMaybe<Scalars['Int']['input']>;
  show_calendar_options?: InputMaybe<Scalars['Boolean']['input']>;
  show_contract_duration_sales?: InputMaybe<Scalars['Boolean']['input']>;
  show_lead_source_to_rep?: InputMaybe<Scalars['Boolean']['input']>;
  show_nocontact_button?: InputMaybe<Scalars['Boolean']['input']>;
  show_payment_terms_sales?: InputMaybe<Scalars['Boolean']['input']>;
  show_rep_all_leads?: InputMaybe<Scalars['Boolean']['input']>;
  show_rep_my_leads?: InputMaybe<Scalars['Boolean']['input']>;
  show_system_view?: InputMaybe<Scalars['Boolean']['input']>;
  sites?: InputMaybe<SiteCreateNestedManyWithoutOrganizationInput>;
  sms_opt_in_dialog?: InputMaybe<Scalars['String']['input']>;
  SMSItems?: InputMaybe<SmsItemCreateNestedManyWithoutOrganizationInput>;
  SnoozeTrackings?: InputMaybe<SnoozeTrackingCreateNestedManyWithoutOrganizationInput>;
  StepActionLogs?: InputMaybe<StepActionLogCreateNestedManyWithoutOrganizationInput>;
  StepActions?: InputMaybe<StepActionCreateNestedManyWithoutOrganizationInput>;
  StepConditions?: InputMaybe<StepConditionCreateNestedManyWithoutOrganizationInput>;
  teams?: InputMaybe<TeamCreateNestedManyWithoutOrganizationInput>;
  Template?: InputMaybe<TemplateCreateNestedManyWithoutOrganizationInput>;
  TemplateVersions?: InputMaybe<TemplateVersionCreateNestedManyWithoutOrganizationInput>;
  timekit_id?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  transfer_timeout?: InputMaybe<Scalars['Int']['input']>;
  TransferAttempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutOrganizationInput>;
  twilio_numbers?: InputMaybe<TwilioNumberCreateNestedManyWithoutOrganizationInput>;
  TwilioFeedbacks?: InputMaybe<TwilioFeedbackCreateNestedManyWithoutOrganizationInput>;
  TwilioInfo?: InputMaybe<TwilioInfoCreateNestedOneWithoutOrganzationInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  use_revenue_planning?: InputMaybe<Scalars['Boolean']['input']>;
  UserCustomFieldFilters?: InputMaybe<UserCustomFieldFilterCreateNestedManyWithoutOrganizationInput>;
  users?: InputMaybe<UserCreateNestedManyWithoutOrganizationInput>;
  win_rep_day_goal?: InputMaybe<Scalars['Float']['input']>;
};

export type OrganizationCreateWithoutCustomObjectFieldForeignKeyInput = {
  ActivityMonitorMultiplier?: InputMaybe<ActivityMonitorMultiplierCreateNestedManyWithoutOrganizationInput>;
  algoFactor?: InputMaybe<AlgoFactorCreateNestedManyWithoutOrganizationInput>;
  allow_calendar_invite?: InputMaybe<Scalars['Boolean']['input']>;
  allow_remove_step_action?: InputMaybe<Scalars['Boolean']['input']>;
  allow_snooze_step_action?: InputMaybe<Scalars['Boolean']['input']>;
  always_record?: InputMaybe<Scalars['Boolean']['input']>;
  arv_initial_held_demos_generated_by_sdr_weight?: InputMaybe<Scalars['Float']['input']>;
  AssociationHistories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutOrganizationInput>;
  auto_call_interval?: InputMaybe<Scalars['Int']['input']>;
  auto_dial?: InputMaybe<Scalars['Boolean']['input']>;
  automated_campaigns?: InputMaybe<AutomatedCampaignCreateNestedManyWithoutOrganizationInput>;
  automatic_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  AutoTransferSelections?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutOrganizationInput>;
  Availablities?: InputMaybe<AvailabilityCreateNestedManyWithoutOrganizationInput>;
  AverageOrderValue?: InputMaybe<AverageOrderValueCreateNestedManyWithoutOrganizationInput>;
  best_action_ever_multiplier?: InputMaybe<Scalars['Float']['input']>;
  best_metrics?: InputMaybe<BestMetricRecordCreateNestedManyWithoutOrganizationInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptCreateNestedManyWithoutOrganizationInput>;
  callMeNows?: InputMaybe<CallMeNowCreateNestedManyWithoutOrganizationInput>;
  CallMultiplier?: InputMaybe<CallMultiplierCreateNestedManyWithoutOrganizationInput>;
  CallSharings?: InputMaybe<CallSharingCreateNestedManyWithoutOrganizationInput>;
  can_edit_sdr_transfer_rules?: InputMaybe<Scalars['Boolean']['input']>;
  claim_rule?: InputMaybe<Scalars['Int']['input']>;
  concession?: InputMaybe<ConcessionCreateNestedManyWithoutOrganizationInput>;
  conference_region?: InputMaybe<ConferenceRegion>;
  Conferences?: InputMaybe<ConferenceCreateNestedManyWithoutOrganizationInput>;
  ContactRequests?: InputMaybe<ContactRequestCreateNestedManyWithoutOrganizationInput>;
  Contacts?: InputMaybe<ContactCreateNestedManyWithoutOrganizationInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  custom_fields?: InputMaybe<CustomFieldCreateNestedManyWithoutOrganizationInput>;
  CustomObject?: InputMaybe<CustomObjectCreateNestedManyWithoutOrganizationInput>;
  CustomObjectField?: InputMaybe<CustomObjectFieldCreateNestedManyWithoutOrganizationInput>;
  CustomObjectRecord?: InputMaybe<CustomObjectRecordCreateNestedManyWithoutOrganizationInput>;
  CustomObjectRow?: InputMaybe<CustomObjectRowCreateNestedManyWithoutOrganizationInput>;
  CustomQueueItems?: InputMaybe<CustomQueueItemCreateNestedManyWithoutOrganizationInput>;
  DashboardColumnOptions?: InputMaybe<DashboardColumnOptionCreateNestedManyWithoutOrganizationInput>;
  DashboardLeadFilters?: InputMaybe<DashboardLeadFilterCreateNestedManyWithoutOrganizationInput>;
  DashboardRepFilters?: InputMaybe<DashboardRepFilterCreateNestedManyWithoutOrganizationInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewCreateNestedManyWithoutOrganizationInput>;
  day_to_close?: InputMaybe<Scalars['Int']['input']>;
  default_demo_time_duration?: InputMaybe<Scalars['Int']['input']>;
  demo_held_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_not_held_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_not_held_call_back_short_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_prep_time_minutes?: InputMaybe<Scalars['Int']['input']>;
  dev_account?: InputMaybe<Scalars['Boolean']['input']>;
  dial_rule?: InputMaybe<Scalars['Int']['input']>;
  DispositionMultiplier?: InputMaybe<DispositionMultiplierCreateNestedManyWithoutOrganizationInput>;
  dispositions?: InputMaybe<DispositionCreateNestedManyWithoutOrganizationInput>;
  double_booking?: InputMaybe<Scalars['Boolean']['input']>;
  dynamic_date_operators?: InputMaybe<Scalars['Boolean']['input']>;
  edge_servers?: InputMaybe<OrganizationCreateedge_ServersInput>;
  email_conflict_notification_email_list?: InputMaybe<OrganizationCreateemail_Conflict_Notification_Email_ListInput>;
  EmailItems?: InputMaybe<EmailItemCreateNestedManyWithoutOrganizationInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordCreateNestedManyWithoutOrganizationInput>;
  EmailThreads?: InputMaybe<EmailThreadCreateNestedManyWithoutOrganizationInput>;
  enable_modified_metrics?: InputMaybe<Scalars['Boolean']['input']>;
  enable_pay_period_view?: InputMaybe<Scalars['Boolean']['input']>;
  enable_time_constraint?: InputMaybe<Scalars['Boolean']['input']>;
  error_log_email_address?: InputMaybe<OrganizationCreateerror_Log_Email_AddressInput>;
  estimated_average_num_of_weekly_overbooked_demo_slots?: InputMaybe<Scalars['Int']['input']>;
  FolderPermissions?: InputMaybe<FolderPermissionCreateNestedManyWithoutOrganizationInput>;
  Folders?: InputMaybe<FolderCreateNestedManyWithoutOrganizationInput>;
  FutureBookingSetting?: InputMaybe<FutureBookingSettingCreateNestedOneWithoutOrganizationInput>;
  GlobalTransferRule?: InputMaybe<GlobalTransferRuleCreateNestedManyWithoutOrganizationInput>;
  held_demos_per_cc_connect?: InputMaybe<Scalars['Float']['input']>;
  helpful_links?: InputMaybe<OrganizationCreatehelpful_LinksInput>;
  HingePointMultiplier?: InputMaybe<HingePointMultiplierCreateNestedManyWithoutOrganizationInput>;
  hold_goal?: InputMaybe<Scalars['Float']['input']>;
  hubspot_credential?: InputMaybe<HubSpotCredentialCreateNestedOneWithoutOrganizationInput>;
  hubspot_last_sync_date?: InputMaybe<Scalars['DateTime']['input']>;
  hubspot_white_list?: InputMaybe<HubSpotWhitelistCreateNestedManyWithoutOrganizationInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  inbound_close_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_dm_connect_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_lt_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_revenue_value_of_sale?: InputMaybe<Scalars['Float']['input']>;
  inbound_scheduled_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_set_rate?: InputMaybe<Scalars['Float']['input']>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventCreateNestedManyWithoutOrganizationInput>;
  InboundConciergeWidget?: InputMaybe<InboundConciergeWidgetCreateNestedManyWithoutOrganizationInput>;
  industry_options?: InputMaybe<IndustryOptionCreateNestedManyWithoutOrganizationInput>;
  initial_sdr_sets_reps_day?: InputMaybe<Scalars['Float']['input']>;
  integration_error_logs?: InputMaybe<IntegrationErrorLogCreateNestedManyWithoutOrganizationInput>;
  integration_mappings?: InputMaybe<IntegrationMappingCreateNestedManyWithoutOrganizationInput>;
  IntegrationMappingCustomObject?: InputMaybe<IntegrationMappingCustomObjectCreateNestedManyWithoutOrganizationInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogCreateNestedManyWithoutOrganizationInput>;
  IntegrationReadOnlyObject?: InputMaybe<IntegrationReadOnlyObjectCreateNestedManyWithoutOrganizationInput>;
  international?: InputMaybe<Scalars['Boolean']['input']>;
  is_email_domain_verified?: InputMaybe<Scalars['Boolean']['input']>;
  is_salesforce_sandbox?: InputMaybe<Scalars['Boolean']['input']>;
  last_lead_value_compute_time?: InputMaybe<Scalars['DateTime']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutOrganizationInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryCreateNestedManyWithoutOrganizationInput>;
  lead_intent?: InputMaybe<LeadIntentCreateNestedManyWithoutOrganizationInput>;
  lead_release_same_rep_delay_rule?: InputMaybe<Scalars['Int']['input']>;
  lead_routing_email_list?: InputMaybe<OrganizationCreatelead_Routing_Email_ListInput>;
  lead_routing_rules?: InputMaybe<RuleCreateNestedManyWithoutOrganizationInput>;
  LeadCustomObjectRowAssociation?: InputMaybe<LeadCustomObjectRowAssociationCreateNestedManyWithoutOrganizationInput>;
  LeadFieldMultiplier?: InputMaybe<LeadFieldMultiplierCreateNestedManyWithoutOrganizationInput>;
  LeadFieldMultiplierCustom?: InputMaybe<LeadFieldMultiplierCustomCreateNestedManyWithoutOrganizationInput>;
  leads?: InputMaybe<LeadCreateNestedManyWithoutOrganizationInput>;
  leadsource_options?: InputMaybe<LeadSourceOptionCreateNestedManyWithoutOrganizationInput>;
  LeadValue?: InputMaybe<LeadValueCreateNestedManyWithoutOrganizationInput>;
  LifeTimeValueMultiplier?: InputMaybe<LifeTimeValueMultiplierCreateNestedManyWithoutOrganizationInput>;
  LiveTransferSetting?: InputMaybe<LiveTransferSettingCreateNestedOneWithoutOrganizationInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusCreateNestedManyWithoutOrganizationInput>;
  ltv_multiplier_custom_fields?: InputMaybe<LifeTimeValueMultiplierCustomCreateNestedManyWithoutOrganizationInput>;
  made_contact_rule?: InputMaybe<Scalars['Int']['input']>;
  make_sale_address_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_business_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_city_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_country_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_first_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_industry_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_last_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_lead_source_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_primary_email_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_primary_phone_number_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_state_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_sub_industry_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_zip_required?: InputMaybe<Scalars['Boolean']['input']>;
  maxium_day_lag_set_to_schedule?: InputMaybe<Scalars['Int']['input']>;
  mrr?: InputMaybe<Scalars['Int']['input']>;
  mrr_label?: InputMaybe<Scalars['String']['input']>;
  mrr_required_on_sale?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  NoteItems?: InputMaybe<NoteItemCreateNestedManyWithoutOrganizationInput>;
  Notifications?: InputMaybe<NotificationCreateNestedManyWithoutOrganizationInput>;
  onboardComplete?: InputMaybe<Scalars['Boolean']['input']>;
  Opportunity?: InputMaybe<SfOpportunityCreateNestedManyWithoutOrganizationInput>;
  organization_holidays?: InputMaybe<OrganizationHolidayCreateNestedManyWithoutOrganizationInput>;
  organization_hours?: InputMaybe<OrganizationHourCreateNestedManyWithoutOrganizationInput>;
  OrganizationPaymentSetting?: InputMaybe<OrganizationPaymentSettingsCreateNestedOneWithoutOrganizationInput>;
  OrganizationPaymentSettings?: InputMaybe<OrganizationPaymentSettingsCreateNestedOneWithoutOrganizationsInput>;
  orgCalendarInviteMetadata?: InputMaybe<OrgCalendarInviteMetadataCreateNestedManyWithoutOrganizationInput>;
  OrgDefaultPipelinePerecentValue?: InputMaybe<OrgDefaultPipelinePerecentValueCreateNestedOneWithoutOrganizationInput>;
  OrgHubSpotSync?: InputMaybe<OrgHubSpotSyncCreateNestedOneWithoutOrganizationInput>;
  OrgNoEventDate?: InputMaybe<OrgNoEventDateCreateNestedManyWithoutOrganizationInput>;
  OrgSalesforceSync?: InputMaybe<OrgSalesforceSyncCreateNestedOneWithoutOrganizationInput>;
  OrgTimeslotAvailability?: InputMaybe<OrgTimeslotAvailabilityCreateNestedManyWithoutOrganizationInput>;
  outbound_close_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_dm_connect_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_lt_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_revenue_value_of_sale?: InputMaybe<Scalars['Float']['input']>;
  outbound_scheduled_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_set_rate?: InputMaybe<Scalars['Float']['input']>;
  PandadocData?: InputMaybe<PandadocDataCreateNestedOneWithoutOrganizationInput>;
  PandaDocTemplate?: InputMaybe<PandaDocTemplateCreateNestedManyWithoutOrganizationInput>;
  participant_jitter_buffer_size?: InputMaybe<JitterBufferSize>;
  preferred_goal_setting?: InputMaybe<Goalsetting>;
  prepayment?: InputMaybe<Scalars['Boolean']['input']>;
  ProductChangeLog?: InputMaybe<ProductChangeLogCreateNestedManyWithoutOrganizationInput>;
  products?: InputMaybe<ProductCreateNestedManyWithoutOrganizationInput>;
  re_cold_call_hour_delay?: InputMaybe<Scalars['Float']['input']>;
  recordingStatus?: InputMaybe<RecordingStatus>;
  release_lead_resting?: InputMaybe<Scalars['Int']['input']>;
  rep_claim_limit?: InputMaybe<Scalars['Int']['input']>;
  rep_lead_allotment?: InputMaybe<Scalars['Int']['input']>;
  rep_level_call_transfer?: InputMaybe<Scalars['Boolean']['input']>;
  reps_can_bulk_unassign?: InputMaybe<Scalars['Boolean']['input']>;
  reps_can_view_all_notes?: InputMaybe<Scalars['Boolean']['input']>;
  require_sale_notes?: InputMaybe<Scalars['Boolean']['input']>;
  resting_after_skipped_CC?: InputMaybe<Scalars['Int']['input']>;
  resting_period?: InputMaybe<Scalars['Int']['input']>;
  resting_period_after_unsuccessful_cold_call?: InputMaybe<Scalars['Float']['input']>;
  resting_period_after_unsuccessful_demo?: InputMaybe<Scalars['Float']['input']>;
  revenue_plan?: InputMaybe<RevenuePlanItemCreateNestedManyWithoutOrganizationInput>;
  Routings?: InputMaybe<RoutingCreateNestedManyWithoutOrganizationInput>;
  sale_field_options?: InputMaybe<SaleFieldOptionCreateNestedManyWithoutOrganizationInput>;
  sale_notification_email_list?: InputMaybe<OrganizationCreatesale_Notification_Email_ListInput>;
  SaleConfig?: InputMaybe<SaleConfigCreateNestedOneWithoutOrganizationInput>;
  SaleConfigPage?: InputMaybe<SaleConfigPageCreateNestedManyWithoutOrganizationInput>;
  SaleConfigSection?: InputMaybe<SaleConfigSectionCreateNestedManyWithoutOrganizationInput>;
  SaleCycles?: InputMaybe<SaleCycleCreateNestedManyWithoutOrganizationInput>;
  SaleItems?: InputMaybe<SaleItemCreateNestedManyWithoutOrganizationInput>;
  sales?: InputMaybe<SaleCreateNestedManyWithoutOrganizationInput>;
  salesforce_domain?: InputMaybe<Scalars['String']['input']>;
  salesforce_last_sync_date?: InputMaybe<Scalars['DateTime']['input']>;
  SalesForceColumn?: InputMaybe<SalesForceColumnCreateNestedManyWithoutOrganizationInput>;
  salesForceData?: InputMaybe<SalesForceDataCreateNestedOneWithoutOrganizationInput>;
  ScheduleItems?: InputMaybe<ScheduleItemCreateNestedManyWithoutOrganizationInput>;
  self_sourcing_rule?: InputMaybe<Scalars['Int']['input']>;
  send_error_log_email?: InputMaybe<Scalars['Boolean']['input']>;
  send_lead_routing_email?: InputMaybe<Scalars['Boolean']['input']>;
  sendgrid_email_domain?: InputMaybe<Scalars['String']['input']>;
  sendgrid_email_domain_id?: InputMaybe<Scalars['Int']['input']>;
  sequence?: InputMaybe<SequenceCreateNestedManyWithoutOrganzationInput>;
  SequenceCriteriaConditions?: InputMaybe<SequenceCriteriaConditionCreateNestedManyWithoutOrganizationInput>;
  SequenceDashboardViews?: InputMaybe<SequenceDashboardViewCreateNestedManyWithoutOrganizationInput>;
  SequenceEntryCriterias?: InputMaybe<SequenceEntryCriteriaCreateNestedManyWithoutOrganizationInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutOrganizationInput>;
  SequenceSteps?: InputMaybe<SequenceStepCreateNestedManyWithoutOrganizationInput>;
  set_goal?: InputMaybe<Scalars['Float']['input']>;
  short_resting?: InputMaybe<Scalars['Int']['input']>;
  show_calendar_options?: InputMaybe<Scalars['Boolean']['input']>;
  show_contract_duration_sales?: InputMaybe<Scalars['Boolean']['input']>;
  show_lead_source_to_rep?: InputMaybe<Scalars['Boolean']['input']>;
  show_nocontact_button?: InputMaybe<Scalars['Boolean']['input']>;
  show_payment_terms_sales?: InputMaybe<Scalars['Boolean']['input']>;
  show_rep_all_leads?: InputMaybe<Scalars['Boolean']['input']>;
  show_rep_my_leads?: InputMaybe<Scalars['Boolean']['input']>;
  show_system_view?: InputMaybe<Scalars['Boolean']['input']>;
  sites?: InputMaybe<SiteCreateNestedManyWithoutOrganizationInput>;
  sms_opt_in_dialog?: InputMaybe<Scalars['String']['input']>;
  SMSItems?: InputMaybe<SmsItemCreateNestedManyWithoutOrganizationInput>;
  SnoozeTrackings?: InputMaybe<SnoozeTrackingCreateNestedManyWithoutOrganizationInput>;
  StepActionLogs?: InputMaybe<StepActionLogCreateNestedManyWithoutOrganizationInput>;
  StepActions?: InputMaybe<StepActionCreateNestedManyWithoutOrganizationInput>;
  StepConditions?: InputMaybe<StepConditionCreateNestedManyWithoutOrganizationInput>;
  teams?: InputMaybe<TeamCreateNestedManyWithoutOrganizationInput>;
  Template?: InputMaybe<TemplateCreateNestedManyWithoutOrganizationInput>;
  TemplateVersions?: InputMaybe<TemplateVersionCreateNestedManyWithoutOrganizationInput>;
  timekit_id?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  transfer_timeout?: InputMaybe<Scalars['Int']['input']>;
  TransferAttempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutOrganizationInput>;
  twilio_numbers?: InputMaybe<TwilioNumberCreateNestedManyWithoutOrganizationInput>;
  TwilioFeedbacks?: InputMaybe<TwilioFeedbackCreateNestedManyWithoutOrganizationInput>;
  TwilioInfo?: InputMaybe<TwilioInfoCreateNestedOneWithoutOrganzationInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  use_revenue_planning?: InputMaybe<Scalars['Boolean']['input']>;
  UserCustomFieldFilters?: InputMaybe<UserCustomFieldFilterCreateNestedManyWithoutOrganizationInput>;
  users?: InputMaybe<UserCreateNestedManyWithoutOrganizationInput>;
  win_rep_day_goal?: InputMaybe<Scalars['Float']['input']>;
};

export type OrganizationCreateWithoutCustomObjectFieldInput = {
  ActivityMonitorMultiplier?: InputMaybe<ActivityMonitorMultiplierCreateNestedManyWithoutOrganizationInput>;
  algoFactor?: InputMaybe<AlgoFactorCreateNestedManyWithoutOrganizationInput>;
  allow_calendar_invite?: InputMaybe<Scalars['Boolean']['input']>;
  allow_remove_step_action?: InputMaybe<Scalars['Boolean']['input']>;
  allow_snooze_step_action?: InputMaybe<Scalars['Boolean']['input']>;
  always_record?: InputMaybe<Scalars['Boolean']['input']>;
  arv_initial_held_demos_generated_by_sdr_weight?: InputMaybe<Scalars['Float']['input']>;
  AssociationHistories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutOrganizationInput>;
  auto_call_interval?: InputMaybe<Scalars['Int']['input']>;
  auto_dial?: InputMaybe<Scalars['Boolean']['input']>;
  automated_campaigns?: InputMaybe<AutomatedCampaignCreateNestedManyWithoutOrganizationInput>;
  automatic_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  AutoTransferSelections?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutOrganizationInput>;
  Availablities?: InputMaybe<AvailabilityCreateNestedManyWithoutOrganizationInput>;
  AverageOrderValue?: InputMaybe<AverageOrderValueCreateNestedManyWithoutOrganizationInput>;
  best_action_ever_multiplier?: InputMaybe<Scalars['Float']['input']>;
  best_metrics?: InputMaybe<BestMetricRecordCreateNestedManyWithoutOrganizationInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptCreateNestedManyWithoutOrganizationInput>;
  callMeNows?: InputMaybe<CallMeNowCreateNestedManyWithoutOrganizationInput>;
  CallMultiplier?: InputMaybe<CallMultiplierCreateNestedManyWithoutOrganizationInput>;
  CallSharings?: InputMaybe<CallSharingCreateNestedManyWithoutOrganizationInput>;
  can_edit_sdr_transfer_rules?: InputMaybe<Scalars['Boolean']['input']>;
  claim_rule?: InputMaybe<Scalars['Int']['input']>;
  concession?: InputMaybe<ConcessionCreateNestedManyWithoutOrganizationInput>;
  conference_region?: InputMaybe<ConferenceRegion>;
  Conferences?: InputMaybe<ConferenceCreateNestedManyWithoutOrganizationInput>;
  ContactRequests?: InputMaybe<ContactRequestCreateNestedManyWithoutOrganizationInput>;
  Contacts?: InputMaybe<ContactCreateNestedManyWithoutOrganizationInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  custom_fields?: InputMaybe<CustomFieldCreateNestedManyWithoutOrganizationInput>;
  CustomObject?: InputMaybe<CustomObjectCreateNestedManyWithoutOrganizationInput>;
  CustomObjectFieldForeignKey?: InputMaybe<CustomObjectFieldForeignKeyCreateNestedManyWithoutOrganizationInput>;
  CustomObjectRecord?: InputMaybe<CustomObjectRecordCreateNestedManyWithoutOrganizationInput>;
  CustomObjectRow?: InputMaybe<CustomObjectRowCreateNestedManyWithoutOrganizationInput>;
  CustomQueueItems?: InputMaybe<CustomQueueItemCreateNestedManyWithoutOrganizationInput>;
  DashboardColumnOptions?: InputMaybe<DashboardColumnOptionCreateNestedManyWithoutOrganizationInput>;
  DashboardLeadFilters?: InputMaybe<DashboardLeadFilterCreateNestedManyWithoutOrganizationInput>;
  DashboardRepFilters?: InputMaybe<DashboardRepFilterCreateNestedManyWithoutOrganizationInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewCreateNestedManyWithoutOrganizationInput>;
  day_to_close?: InputMaybe<Scalars['Int']['input']>;
  default_demo_time_duration?: InputMaybe<Scalars['Int']['input']>;
  demo_held_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_not_held_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_not_held_call_back_short_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_prep_time_minutes?: InputMaybe<Scalars['Int']['input']>;
  dev_account?: InputMaybe<Scalars['Boolean']['input']>;
  dial_rule?: InputMaybe<Scalars['Int']['input']>;
  DispositionMultiplier?: InputMaybe<DispositionMultiplierCreateNestedManyWithoutOrganizationInput>;
  dispositions?: InputMaybe<DispositionCreateNestedManyWithoutOrganizationInput>;
  double_booking?: InputMaybe<Scalars['Boolean']['input']>;
  dynamic_date_operators?: InputMaybe<Scalars['Boolean']['input']>;
  edge_servers?: InputMaybe<OrganizationCreateedge_ServersInput>;
  email_conflict_notification_email_list?: InputMaybe<OrganizationCreateemail_Conflict_Notification_Email_ListInput>;
  EmailItems?: InputMaybe<EmailItemCreateNestedManyWithoutOrganizationInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordCreateNestedManyWithoutOrganizationInput>;
  EmailThreads?: InputMaybe<EmailThreadCreateNestedManyWithoutOrganizationInput>;
  enable_modified_metrics?: InputMaybe<Scalars['Boolean']['input']>;
  enable_pay_period_view?: InputMaybe<Scalars['Boolean']['input']>;
  enable_time_constraint?: InputMaybe<Scalars['Boolean']['input']>;
  error_log_email_address?: InputMaybe<OrganizationCreateerror_Log_Email_AddressInput>;
  estimated_average_num_of_weekly_overbooked_demo_slots?: InputMaybe<Scalars['Int']['input']>;
  FolderPermissions?: InputMaybe<FolderPermissionCreateNestedManyWithoutOrganizationInput>;
  Folders?: InputMaybe<FolderCreateNestedManyWithoutOrganizationInput>;
  FutureBookingSetting?: InputMaybe<FutureBookingSettingCreateNestedOneWithoutOrganizationInput>;
  GlobalTransferRule?: InputMaybe<GlobalTransferRuleCreateNestedManyWithoutOrganizationInput>;
  held_demos_per_cc_connect?: InputMaybe<Scalars['Float']['input']>;
  helpful_links?: InputMaybe<OrganizationCreatehelpful_LinksInput>;
  HingePointMultiplier?: InputMaybe<HingePointMultiplierCreateNestedManyWithoutOrganizationInput>;
  hold_goal?: InputMaybe<Scalars['Float']['input']>;
  hubspot_credential?: InputMaybe<HubSpotCredentialCreateNestedOneWithoutOrganizationInput>;
  hubspot_last_sync_date?: InputMaybe<Scalars['DateTime']['input']>;
  hubspot_white_list?: InputMaybe<HubSpotWhitelistCreateNestedManyWithoutOrganizationInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  inbound_close_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_dm_connect_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_lt_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_revenue_value_of_sale?: InputMaybe<Scalars['Float']['input']>;
  inbound_scheduled_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_set_rate?: InputMaybe<Scalars['Float']['input']>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventCreateNestedManyWithoutOrganizationInput>;
  InboundConciergeWidget?: InputMaybe<InboundConciergeWidgetCreateNestedManyWithoutOrganizationInput>;
  industry_options?: InputMaybe<IndustryOptionCreateNestedManyWithoutOrganizationInput>;
  initial_sdr_sets_reps_day?: InputMaybe<Scalars['Float']['input']>;
  integration_error_logs?: InputMaybe<IntegrationErrorLogCreateNestedManyWithoutOrganizationInput>;
  integration_mappings?: InputMaybe<IntegrationMappingCreateNestedManyWithoutOrganizationInput>;
  IntegrationMappingCustomObject?: InputMaybe<IntegrationMappingCustomObjectCreateNestedManyWithoutOrganizationInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogCreateNestedManyWithoutOrganizationInput>;
  IntegrationReadOnlyObject?: InputMaybe<IntegrationReadOnlyObjectCreateNestedManyWithoutOrganizationInput>;
  international?: InputMaybe<Scalars['Boolean']['input']>;
  is_email_domain_verified?: InputMaybe<Scalars['Boolean']['input']>;
  is_salesforce_sandbox?: InputMaybe<Scalars['Boolean']['input']>;
  last_lead_value_compute_time?: InputMaybe<Scalars['DateTime']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutOrganizationInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryCreateNestedManyWithoutOrganizationInput>;
  lead_intent?: InputMaybe<LeadIntentCreateNestedManyWithoutOrganizationInput>;
  lead_release_same_rep_delay_rule?: InputMaybe<Scalars['Int']['input']>;
  lead_routing_email_list?: InputMaybe<OrganizationCreatelead_Routing_Email_ListInput>;
  lead_routing_rules?: InputMaybe<RuleCreateNestedManyWithoutOrganizationInput>;
  LeadCustomObjectRowAssociation?: InputMaybe<LeadCustomObjectRowAssociationCreateNestedManyWithoutOrganizationInput>;
  LeadFieldMultiplier?: InputMaybe<LeadFieldMultiplierCreateNestedManyWithoutOrganizationInput>;
  LeadFieldMultiplierCustom?: InputMaybe<LeadFieldMultiplierCustomCreateNestedManyWithoutOrganizationInput>;
  leads?: InputMaybe<LeadCreateNestedManyWithoutOrganizationInput>;
  leadsource_options?: InputMaybe<LeadSourceOptionCreateNestedManyWithoutOrganizationInput>;
  LeadValue?: InputMaybe<LeadValueCreateNestedManyWithoutOrganizationInput>;
  LifeTimeValueMultiplier?: InputMaybe<LifeTimeValueMultiplierCreateNestedManyWithoutOrganizationInput>;
  LiveTransferSetting?: InputMaybe<LiveTransferSettingCreateNestedOneWithoutOrganizationInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusCreateNestedManyWithoutOrganizationInput>;
  ltv_multiplier_custom_fields?: InputMaybe<LifeTimeValueMultiplierCustomCreateNestedManyWithoutOrganizationInput>;
  made_contact_rule?: InputMaybe<Scalars['Int']['input']>;
  make_sale_address_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_business_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_city_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_country_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_first_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_industry_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_last_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_lead_source_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_primary_email_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_primary_phone_number_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_state_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_sub_industry_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_zip_required?: InputMaybe<Scalars['Boolean']['input']>;
  maxium_day_lag_set_to_schedule?: InputMaybe<Scalars['Int']['input']>;
  mrr?: InputMaybe<Scalars['Int']['input']>;
  mrr_label?: InputMaybe<Scalars['String']['input']>;
  mrr_required_on_sale?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  NoteItems?: InputMaybe<NoteItemCreateNestedManyWithoutOrganizationInput>;
  Notifications?: InputMaybe<NotificationCreateNestedManyWithoutOrganizationInput>;
  onboardComplete?: InputMaybe<Scalars['Boolean']['input']>;
  Opportunity?: InputMaybe<SfOpportunityCreateNestedManyWithoutOrganizationInput>;
  organization_holidays?: InputMaybe<OrganizationHolidayCreateNestedManyWithoutOrganizationInput>;
  organization_hours?: InputMaybe<OrganizationHourCreateNestedManyWithoutOrganizationInput>;
  OrganizationPaymentSetting?: InputMaybe<OrganizationPaymentSettingsCreateNestedOneWithoutOrganizationInput>;
  OrganizationPaymentSettings?: InputMaybe<OrganizationPaymentSettingsCreateNestedOneWithoutOrganizationsInput>;
  orgCalendarInviteMetadata?: InputMaybe<OrgCalendarInviteMetadataCreateNestedManyWithoutOrganizationInput>;
  OrgDefaultPipelinePerecentValue?: InputMaybe<OrgDefaultPipelinePerecentValueCreateNestedOneWithoutOrganizationInput>;
  OrgHubSpotSync?: InputMaybe<OrgHubSpotSyncCreateNestedOneWithoutOrganizationInput>;
  OrgNoEventDate?: InputMaybe<OrgNoEventDateCreateNestedManyWithoutOrganizationInput>;
  OrgSalesforceSync?: InputMaybe<OrgSalesforceSyncCreateNestedOneWithoutOrganizationInput>;
  OrgTimeslotAvailability?: InputMaybe<OrgTimeslotAvailabilityCreateNestedManyWithoutOrganizationInput>;
  outbound_close_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_dm_connect_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_lt_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_revenue_value_of_sale?: InputMaybe<Scalars['Float']['input']>;
  outbound_scheduled_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_set_rate?: InputMaybe<Scalars['Float']['input']>;
  PandadocData?: InputMaybe<PandadocDataCreateNestedOneWithoutOrganizationInput>;
  PandaDocTemplate?: InputMaybe<PandaDocTemplateCreateNestedManyWithoutOrganizationInput>;
  participant_jitter_buffer_size?: InputMaybe<JitterBufferSize>;
  preferred_goal_setting?: InputMaybe<Goalsetting>;
  prepayment?: InputMaybe<Scalars['Boolean']['input']>;
  ProductChangeLog?: InputMaybe<ProductChangeLogCreateNestedManyWithoutOrganizationInput>;
  products?: InputMaybe<ProductCreateNestedManyWithoutOrganizationInput>;
  re_cold_call_hour_delay?: InputMaybe<Scalars['Float']['input']>;
  recordingStatus?: InputMaybe<RecordingStatus>;
  release_lead_resting?: InputMaybe<Scalars['Int']['input']>;
  rep_claim_limit?: InputMaybe<Scalars['Int']['input']>;
  rep_lead_allotment?: InputMaybe<Scalars['Int']['input']>;
  rep_level_call_transfer?: InputMaybe<Scalars['Boolean']['input']>;
  reps_can_bulk_unassign?: InputMaybe<Scalars['Boolean']['input']>;
  reps_can_view_all_notes?: InputMaybe<Scalars['Boolean']['input']>;
  require_sale_notes?: InputMaybe<Scalars['Boolean']['input']>;
  resting_after_skipped_CC?: InputMaybe<Scalars['Int']['input']>;
  resting_period?: InputMaybe<Scalars['Int']['input']>;
  resting_period_after_unsuccessful_cold_call?: InputMaybe<Scalars['Float']['input']>;
  resting_period_after_unsuccessful_demo?: InputMaybe<Scalars['Float']['input']>;
  revenue_plan?: InputMaybe<RevenuePlanItemCreateNestedManyWithoutOrganizationInput>;
  Routings?: InputMaybe<RoutingCreateNestedManyWithoutOrganizationInput>;
  sale_field_options?: InputMaybe<SaleFieldOptionCreateNestedManyWithoutOrganizationInput>;
  sale_notification_email_list?: InputMaybe<OrganizationCreatesale_Notification_Email_ListInput>;
  SaleConfig?: InputMaybe<SaleConfigCreateNestedOneWithoutOrganizationInput>;
  SaleConfigPage?: InputMaybe<SaleConfigPageCreateNestedManyWithoutOrganizationInput>;
  SaleConfigSection?: InputMaybe<SaleConfigSectionCreateNestedManyWithoutOrganizationInput>;
  SaleCycles?: InputMaybe<SaleCycleCreateNestedManyWithoutOrganizationInput>;
  SaleItems?: InputMaybe<SaleItemCreateNestedManyWithoutOrganizationInput>;
  sales?: InputMaybe<SaleCreateNestedManyWithoutOrganizationInput>;
  salesforce_domain?: InputMaybe<Scalars['String']['input']>;
  salesforce_last_sync_date?: InputMaybe<Scalars['DateTime']['input']>;
  SalesForceColumn?: InputMaybe<SalesForceColumnCreateNestedManyWithoutOrganizationInput>;
  salesForceData?: InputMaybe<SalesForceDataCreateNestedOneWithoutOrganizationInput>;
  ScheduleItems?: InputMaybe<ScheduleItemCreateNestedManyWithoutOrganizationInput>;
  self_sourcing_rule?: InputMaybe<Scalars['Int']['input']>;
  send_error_log_email?: InputMaybe<Scalars['Boolean']['input']>;
  send_lead_routing_email?: InputMaybe<Scalars['Boolean']['input']>;
  sendgrid_email_domain?: InputMaybe<Scalars['String']['input']>;
  sendgrid_email_domain_id?: InputMaybe<Scalars['Int']['input']>;
  sequence?: InputMaybe<SequenceCreateNestedManyWithoutOrganzationInput>;
  SequenceCriteriaConditions?: InputMaybe<SequenceCriteriaConditionCreateNestedManyWithoutOrganizationInput>;
  SequenceDashboardViews?: InputMaybe<SequenceDashboardViewCreateNestedManyWithoutOrganizationInput>;
  SequenceEntryCriterias?: InputMaybe<SequenceEntryCriteriaCreateNestedManyWithoutOrganizationInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutOrganizationInput>;
  SequenceSteps?: InputMaybe<SequenceStepCreateNestedManyWithoutOrganizationInput>;
  set_goal?: InputMaybe<Scalars['Float']['input']>;
  short_resting?: InputMaybe<Scalars['Int']['input']>;
  show_calendar_options?: InputMaybe<Scalars['Boolean']['input']>;
  show_contract_duration_sales?: InputMaybe<Scalars['Boolean']['input']>;
  show_lead_source_to_rep?: InputMaybe<Scalars['Boolean']['input']>;
  show_nocontact_button?: InputMaybe<Scalars['Boolean']['input']>;
  show_payment_terms_sales?: InputMaybe<Scalars['Boolean']['input']>;
  show_rep_all_leads?: InputMaybe<Scalars['Boolean']['input']>;
  show_rep_my_leads?: InputMaybe<Scalars['Boolean']['input']>;
  show_system_view?: InputMaybe<Scalars['Boolean']['input']>;
  sites?: InputMaybe<SiteCreateNestedManyWithoutOrganizationInput>;
  sms_opt_in_dialog?: InputMaybe<Scalars['String']['input']>;
  SMSItems?: InputMaybe<SmsItemCreateNestedManyWithoutOrganizationInput>;
  SnoozeTrackings?: InputMaybe<SnoozeTrackingCreateNestedManyWithoutOrganizationInput>;
  StepActionLogs?: InputMaybe<StepActionLogCreateNestedManyWithoutOrganizationInput>;
  StepActions?: InputMaybe<StepActionCreateNestedManyWithoutOrganizationInput>;
  StepConditions?: InputMaybe<StepConditionCreateNestedManyWithoutOrganizationInput>;
  teams?: InputMaybe<TeamCreateNestedManyWithoutOrganizationInput>;
  Template?: InputMaybe<TemplateCreateNestedManyWithoutOrganizationInput>;
  TemplateVersions?: InputMaybe<TemplateVersionCreateNestedManyWithoutOrganizationInput>;
  timekit_id?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  transfer_timeout?: InputMaybe<Scalars['Int']['input']>;
  TransferAttempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutOrganizationInput>;
  twilio_numbers?: InputMaybe<TwilioNumberCreateNestedManyWithoutOrganizationInput>;
  TwilioFeedbacks?: InputMaybe<TwilioFeedbackCreateNestedManyWithoutOrganizationInput>;
  TwilioInfo?: InputMaybe<TwilioInfoCreateNestedOneWithoutOrganzationInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  use_revenue_planning?: InputMaybe<Scalars['Boolean']['input']>;
  UserCustomFieldFilters?: InputMaybe<UserCustomFieldFilterCreateNestedManyWithoutOrganizationInput>;
  users?: InputMaybe<UserCreateNestedManyWithoutOrganizationInput>;
  win_rep_day_goal?: InputMaybe<Scalars['Float']['input']>;
};

export type OrganizationCreateWithoutCustomObjectInput = {
  ActivityMonitorMultiplier?: InputMaybe<ActivityMonitorMultiplierCreateNestedManyWithoutOrganizationInput>;
  algoFactor?: InputMaybe<AlgoFactorCreateNestedManyWithoutOrganizationInput>;
  allow_calendar_invite?: InputMaybe<Scalars['Boolean']['input']>;
  allow_remove_step_action?: InputMaybe<Scalars['Boolean']['input']>;
  allow_snooze_step_action?: InputMaybe<Scalars['Boolean']['input']>;
  always_record?: InputMaybe<Scalars['Boolean']['input']>;
  arv_initial_held_demos_generated_by_sdr_weight?: InputMaybe<Scalars['Float']['input']>;
  AssociationHistories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutOrganizationInput>;
  auto_call_interval?: InputMaybe<Scalars['Int']['input']>;
  auto_dial?: InputMaybe<Scalars['Boolean']['input']>;
  automated_campaigns?: InputMaybe<AutomatedCampaignCreateNestedManyWithoutOrganizationInput>;
  automatic_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  AutoTransferSelections?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutOrganizationInput>;
  Availablities?: InputMaybe<AvailabilityCreateNestedManyWithoutOrganizationInput>;
  AverageOrderValue?: InputMaybe<AverageOrderValueCreateNestedManyWithoutOrganizationInput>;
  best_action_ever_multiplier?: InputMaybe<Scalars['Float']['input']>;
  best_metrics?: InputMaybe<BestMetricRecordCreateNestedManyWithoutOrganizationInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptCreateNestedManyWithoutOrganizationInput>;
  callMeNows?: InputMaybe<CallMeNowCreateNestedManyWithoutOrganizationInput>;
  CallMultiplier?: InputMaybe<CallMultiplierCreateNestedManyWithoutOrganizationInput>;
  CallSharings?: InputMaybe<CallSharingCreateNestedManyWithoutOrganizationInput>;
  can_edit_sdr_transfer_rules?: InputMaybe<Scalars['Boolean']['input']>;
  claim_rule?: InputMaybe<Scalars['Int']['input']>;
  concession?: InputMaybe<ConcessionCreateNestedManyWithoutOrganizationInput>;
  conference_region?: InputMaybe<ConferenceRegion>;
  Conferences?: InputMaybe<ConferenceCreateNestedManyWithoutOrganizationInput>;
  ContactRequests?: InputMaybe<ContactRequestCreateNestedManyWithoutOrganizationInput>;
  Contacts?: InputMaybe<ContactCreateNestedManyWithoutOrganizationInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  custom_fields?: InputMaybe<CustomFieldCreateNestedManyWithoutOrganizationInput>;
  CustomObjectField?: InputMaybe<CustomObjectFieldCreateNestedManyWithoutOrganizationInput>;
  CustomObjectFieldForeignKey?: InputMaybe<CustomObjectFieldForeignKeyCreateNestedManyWithoutOrganizationInput>;
  CustomObjectRecord?: InputMaybe<CustomObjectRecordCreateNestedManyWithoutOrganizationInput>;
  CustomObjectRow?: InputMaybe<CustomObjectRowCreateNestedManyWithoutOrganizationInput>;
  CustomQueueItems?: InputMaybe<CustomQueueItemCreateNestedManyWithoutOrganizationInput>;
  DashboardColumnOptions?: InputMaybe<DashboardColumnOptionCreateNestedManyWithoutOrganizationInput>;
  DashboardLeadFilters?: InputMaybe<DashboardLeadFilterCreateNestedManyWithoutOrganizationInput>;
  DashboardRepFilters?: InputMaybe<DashboardRepFilterCreateNestedManyWithoutOrganizationInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewCreateNestedManyWithoutOrganizationInput>;
  day_to_close?: InputMaybe<Scalars['Int']['input']>;
  default_demo_time_duration?: InputMaybe<Scalars['Int']['input']>;
  demo_held_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_not_held_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_not_held_call_back_short_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_prep_time_minutes?: InputMaybe<Scalars['Int']['input']>;
  dev_account?: InputMaybe<Scalars['Boolean']['input']>;
  dial_rule?: InputMaybe<Scalars['Int']['input']>;
  DispositionMultiplier?: InputMaybe<DispositionMultiplierCreateNestedManyWithoutOrganizationInput>;
  dispositions?: InputMaybe<DispositionCreateNestedManyWithoutOrganizationInput>;
  double_booking?: InputMaybe<Scalars['Boolean']['input']>;
  dynamic_date_operators?: InputMaybe<Scalars['Boolean']['input']>;
  edge_servers?: InputMaybe<OrganizationCreateedge_ServersInput>;
  email_conflict_notification_email_list?: InputMaybe<OrganizationCreateemail_Conflict_Notification_Email_ListInput>;
  EmailItems?: InputMaybe<EmailItemCreateNestedManyWithoutOrganizationInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordCreateNestedManyWithoutOrganizationInput>;
  EmailThreads?: InputMaybe<EmailThreadCreateNestedManyWithoutOrganizationInput>;
  enable_modified_metrics?: InputMaybe<Scalars['Boolean']['input']>;
  enable_pay_period_view?: InputMaybe<Scalars['Boolean']['input']>;
  enable_time_constraint?: InputMaybe<Scalars['Boolean']['input']>;
  error_log_email_address?: InputMaybe<OrganizationCreateerror_Log_Email_AddressInput>;
  estimated_average_num_of_weekly_overbooked_demo_slots?: InputMaybe<Scalars['Int']['input']>;
  FolderPermissions?: InputMaybe<FolderPermissionCreateNestedManyWithoutOrganizationInput>;
  Folders?: InputMaybe<FolderCreateNestedManyWithoutOrganizationInput>;
  FutureBookingSetting?: InputMaybe<FutureBookingSettingCreateNestedOneWithoutOrganizationInput>;
  GlobalTransferRule?: InputMaybe<GlobalTransferRuleCreateNestedManyWithoutOrganizationInput>;
  held_demos_per_cc_connect?: InputMaybe<Scalars['Float']['input']>;
  helpful_links?: InputMaybe<OrganizationCreatehelpful_LinksInput>;
  HingePointMultiplier?: InputMaybe<HingePointMultiplierCreateNestedManyWithoutOrganizationInput>;
  hold_goal?: InputMaybe<Scalars['Float']['input']>;
  hubspot_credential?: InputMaybe<HubSpotCredentialCreateNestedOneWithoutOrganizationInput>;
  hubspot_last_sync_date?: InputMaybe<Scalars['DateTime']['input']>;
  hubspot_white_list?: InputMaybe<HubSpotWhitelistCreateNestedManyWithoutOrganizationInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  inbound_close_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_dm_connect_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_lt_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_revenue_value_of_sale?: InputMaybe<Scalars['Float']['input']>;
  inbound_scheduled_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_set_rate?: InputMaybe<Scalars['Float']['input']>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventCreateNestedManyWithoutOrganizationInput>;
  InboundConciergeWidget?: InputMaybe<InboundConciergeWidgetCreateNestedManyWithoutOrganizationInput>;
  industry_options?: InputMaybe<IndustryOptionCreateNestedManyWithoutOrganizationInput>;
  initial_sdr_sets_reps_day?: InputMaybe<Scalars['Float']['input']>;
  integration_error_logs?: InputMaybe<IntegrationErrorLogCreateNestedManyWithoutOrganizationInput>;
  integration_mappings?: InputMaybe<IntegrationMappingCreateNestedManyWithoutOrganizationInput>;
  IntegrationMappingCustomObject?: InputMaybe<IntegrationMappingCustomObjectCreateNestedManyWithoutOrganizationInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogCreateNestedManyWithoutOrganizationInput>;
  IntegrationReadOnlyObject?: InputMaybe<IntegrationReadOnlyObjectCreateNestedManyWithoutOrganizationInput>;
  international?: InputMaybe<Scalars['Boolean']['input']>;
  is_email_domain_verified?: InputMaybe<Scalars['Boolean']['input']>;
  is_salesforce_sandbox?: InputMaybe<Scalars['Boolean']['input']>;
  last_lead_value_compute_time?: InputMaybe<Scalars['DateTime']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutOrganizationInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryCreateNestedManyWithoutOrganizationInput>;
  lead_intent?: InputMaybe<LeadIntentCreateNestedManyWithoutOrganizationInput>;
  lead_release_same_rep_delay_rule?: InputMaybe<Scalars['Int']['input']>;
  lead_routing_email_list?: InputMaybe<OrganizationCreatelead_Routing_Email_ListInput>;
  lead_routing_rules?: InputMaybe<RuleCreateNestedManyWithoutOrganizationInput>;
  LeadCustomObjectRowAssociation?: InputMaybe<LeadCustomObjectRowAssociationCreateNestedManyWithoutOrganizationInput>;
  LeadFieldMultiplier?: InputMaybe<LeadFieldMultiplierCreateNestedManyWithoutOrganizationInput>;
  LeadFieldMultiplierCustom?: InputMaybe<LeadFieldMultiplierCustomCreateNestedManyWithoutOrganizationInput>;
  leads?: InputMaybe<LeadCreateNestedManyWithoutOrganizationInput>;
  leadsource_options?: InputMaybe<LeadSourceOptionCreateNestedManyWithoutOrganizationInput>;
  LeadValue?: InputMaybe<LeadValueCreateNestedManyWithoutOrganizationInput>;
  LifeTimeValueMultiplier?: InputMaybe<LifeTimeValueMultiplierCreateNestedManyWithoutOrganizationInput>;
  LiveTransferSetting?: InputMaybe<LiveTransferSettingCreateNestedOneWithoutOrganizationInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusCreateNestedManyWithoutOrganizationInput>;
  ltv_multiplier_custom_fields?: InputMaybe<LifeTimeValueMultiplierCustomCreateNestedManyWithoutOrganizationInput>;
  made_contact_rule?: InputMaybe<Scalars['Int']['input']>;
  make_sale_address_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_business_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_city_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_country_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_first_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_industry_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_last_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_lead_source_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_primary_email_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_primary_phone_number_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_state_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_sub_industry_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_zip_required?: InputMaybe<Scalars['Boolean']['input']>;
  maxium_day_lag_set_to_schedule?: InputMaybe<Scalars['Int']['input']>;
  mrr?: InputMaybe<Scalars['Int']['input']>;
  mrr_label?: InputMaybe<Scalars['String']['input']>;
  mrr_required_on_sale?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  NoteItems?: InputMaybe<NoteItemCreateNestedManyWithoutOrganizationInput>;
  Notifications?: InputMaybe<NotificationCreateNestedManyWithoutOrganizationInput>;
  onboardComplete?: InputMaybe<Scalars['Boolean']['input']>;
  Opportunity?: InputMaybe<SfOpportunityCreateNestedManyWithoutOrganizationInput>;
  organization_holidays?: InputMaybe<OrganizationHolidayCreateNestedManyWithoutOrganizationInput>;
  organization_hours?: InputMaybe<OrganizationHourCreateNestedManyWithoutOrganizationInput>;
  OrganizationPaymentSetting?: InputMaybe<OrganizationPaymentSettingsCreateNestedOneWithoutOrganizationInput>;
  OrganizationPaymentSettings?: InputMaybe<OrganizationPaymentSettingsCreateNestedOneWithoutOrganizationsInput>;
  orgCalendarInviteMetadata?: InputMaybe<OrgCalendarInviteMetadataCreateNestedManyWithoutOrganizationInput>;
  OrgDefaultPipelinePerecentValue?: InputMaybe<OrgDefaultPipelinePerecentValueCreateNestedOneWithoutOrganizationInput>;
  OrgHubSpotSync?: InputMaybe<OrgHubSpotSyncCreateNestedOneWithoutOrganizationInput>;
  OrgNoEventDate?: InputMaybe<OrgNoEventDateCreateNestedManyWithoutOrganizationInput>;
  OrgSalesforceSync?: InputMaybe<OrgSalesforceSyncCreateNestedOneWithoutOrganizationInput>;
  OrgTimeslotAvailability?: InputMaybe<OrgTimeslotAvailabilityCreateNestedManyWithoutOrganizationInput>;
  outbound_close_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_dm_connect_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_lt_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_revenue_value_of_sale?: InputMaybe<Scalars['Float']['input']>;
  outbound_scheduled_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_set_rate?: InputMaybe<Scalars['Float']['input']>;
  PandadocData?: InputMaybe<PandadocDataCreateNestedOneWithoutOrganizationInput>;
  PandaDocTemplate?: InputMaybe<PandaDocTemplateCreateNestedManyWithoutOrganizationInput>;
  participant_jitter_buffer_size?: InputMaybe<JitterBufferSize>;
  preferred_goal_setting?: InputMaybe<Goalsetting>;
  prepayment?: InputMaybe<Scalars['Boolean']['input']>;
  ProductChangeLog?: InputMaybe<ProductChangeLogCreateNestedManyWithoutOrganizationInput>;
  products?: InputMaybe<ProductCreateNestedManyWithoutOrganizationInput>;
  re_cold_call_hour_delay?: InputMaybe<Scalars['Float']['input']>;
  recordingStatus?: InputMaybe<RecordingStatus>;
  release_lead_resting?: InputMaybe<Scalars['Int']['input']>;
  rep_claim_limit?: InputMaybe<Scalars['Int']['input']>;
  rep_lead_allotment?: InputMaybe<Scalars['Int']['input']>;
  rep_level_call_transfer?: InputMaybe<Scalars['Boolean']['input']>;
  reps_can_bulk_unassign?: InputMaybe<Scalars['Boolean']['input']>;
  reps_can_view_all_notes?: InputMaybe<Scalars['Boolean']['input']>;
  require_sale_notes?: InputMaybe<Scalars['Boolean']['input']>;
  resting_after_skipped_CC?: InputMaybe<Scalars['Int']['input']>;
  resting_period?: InputMaybe<Scalars['Int']['input']>;
  resting_period_after_unsuccessful_cold_call?: InputMaybe<Scalars['Float']['input']>;
  resting_period_after_unsuccessful_demo?: InputMaybe<Scalars['Float']['input']>;
  revenue_plan?: InputMaybe<RevenuePlanItemCreateNestedManyWithoutOrganizationInput>;
  Routings?: InputMaybe<RoutingCreateNestedManyWithoutOrganizationInput>;
  sale_field_options?: InputMaybe<SaleFieldOptionCreateNestedManyWithoutOrganizationInput>;
  sale_notification_email_list?: InputMaybe<OrganizationCreatesale_Notification_Email_ListInput>;
  SaleConfig?: InputMaybe<SaleConfigCreateNestedOneWithoutOrganizationInput>;
  SaleConfigPage?: InputMaybe<SaleConfigPageCreateNestedManyWithoutOrganizationInput>;
  SaleConfigSection?: InputMaybe<SaleConfigSectionCreateNestedManyWithoutOrganizationInput>;
  SaleCycles?: InputMaybe<SaleCycleCreateNestedManyWithoutOrganizationInput>;
  SaleItems?: InputMaybe<SaleItemCreateNestedManyWithoutOrganizationInput>;
  sales?: InputMaybe<SaleCreateNestedManyWithoutOrganizationInput>;
  salesforce_domain?: InputMaybe<Scalars['String']['input']>;
  salesforce_last_sync_date?: InputMaybe<Scalars['DateTime']['input']>;
  SalesForceColumn?: InputMaybe<SalesForceColumnCreateNestedManyWithoutOrganizationInput>;
  salesForceData?: InputMaybe<SalesForceDataCreateNestedOneWithoutOrganizationInput>;
  ScheduleItems?: InputMaybe<ScheduleItemCreateNestedManyWithoutOrganizationInput>;
  self_sourcing_rule?: InputMaybe<Scalars['Int']['input']>;
  send_error_log_email?: InputMaybe<Scalars['Boolean']['input']>;
  send_lead_routing_email?: InputMaybe<Scalars['Boolean']['input']>;
  sendgrid_email_domain?: InputMaybe<Scalars['String']['input']>;
  sendgrid_email_domain_id?: InputMaybe<Scalars['Int']['input']>;
  sequence?: InputMaybe<SequenceCreateNestedManyWithoutOrganzationInput>;
  SequenceCriteriaConditions?: InputMaybe<SequenceCriteriaConditionCreateNestedManyWithoutOrganizationInput>;
  SequenceDashboardViews?: InputMaybe<SequenceDashboardViewCreateNestedManyWithoutOrganizationInput>;
  SequenceEntryCriterias?: InputMaybe<SequenceEntryCriteriaCreateNestedManyWithoutOrganizationInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutOrganizationInput>;
  SequenceSteps?: InputMaybe<SequenceStepCreateNestedManyWithoutOrganizationInput>;
  set_goal?: InputMaybe<Scalars['Float']['input']>;
  short_resting?: InputMaybe<Scalars['Int']['input']>;
  show_calendar_options?: InputMaybe<Scalars['Boolean']['input']>;
  show_contract_duration_sales?: InputMaybe<Scalars['Boolean']['input']>;
  show_lead_source_to_rep?: InputMaybe<Scalars['Boolean']['input']>;
  show_nocontact_button?: InputMaybe<Scalars['Boolean']['input']>;
  show_payment_terms_sales?: InputMaybe<Scalars['Boolean']['input']>;
  show_rep_all_leads?: InputMaybe<Scalars['Boolean']['input']>;
  show_rep_my_leads?: InputMaybe<Scalars['Boolean']['input']>;
  show_system_view?: InputMaybe<Scalars['Boolean']['input']>;
  sites?: InputMaybe<SiteCreateNestedManyWithoutOrganizationInput>;
  sms_opt_in_dialog?: InputMaybe<Scalars['String']['input']>;
  SMSItems?: InputMaybe<SmsItemCreateNestedManyWithoutOrganizationInput>;
  SnoozeTrackings?: InputMaybe<SnoozeTrackingCreateNestedManyWithoutOrganizationInput>;
  StepActionLogs?: InputMaybe<StepActionLogCreateNestedManyWithoutOrganizationInput>;
  StepActions?: InputMaybe<StepActionCreateNestedManyWithoutOrganizationInput>;
  StepConditions?: InputMaybe<StepConditionCreateNestedManyWithoutOrganizationInput>;
  teams?: InputMaybe<TeamCreateNestedManyWithoutOrganizationInput>;
  Template?: InputMaybe<TemplateCreateNestedManyWithoutOrganizationInput>;
  TemplateVersions?: InputMaybe<TemplateVersionCreateNestedManyWithoutOrganizationInput>;
  timekit_id?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  transfer_timeout?: InputMaybe<Scalars['Int']['input']>;
  TransferAttempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutOrganizationInput>;
  twilio_numbers?: InputMaybe<TwilioNumberCreateNestedManyWithoutOrganizationInput>;
  TwilioFeedbacks?: InputMaybe<TwilioFeedbackCreateNestedManyWithoutOrganizationInput>;
  TwilioInfo?: InputMaybe<TwilioInfoCreateNestedOneWithoutOrganzationInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  use_revenue_planning?: InputMaybe<Scalars['Boolean']['input']>;
  UserCustomFieldFilters?: InputMaybe<UserCustomFieldFilterCreateNestedManyWithoutOrganizationInput>;
  users?: InputMaybe<UserCreateNestedManyWithoutOrganizationInput>;
  win_rep_day_goal?: InputMaybe<Scalars['Float']['input']>;
};

export type OrganizationCreateWithoutCustomObjectRecordInput = {
  ActivityMonitorMultiplier?: InputMaybe<ActivityMonitorMultiplierCreateNestedManyWithoutOrganizationInput>;
  algoFactor?: InputMaybe<AlgoFactorCreateNestedManyWithoutOrganizationInput>;
  allow_calendar_invite?: InputMaybe<Scalars['Boolean']['input']>;
  allow_remove_step_action?: InputMaybe<Scalars['Boolean']['input']>;
  allow_snooze_step_action?: InputMaybe<Scalars['Boolean']['input']>;
  always_record?: InputMaybe<Scalars['Boolean']['input']>;
  arv_initial_held_demos_generated_by_sdr_weight?: InputMaybe<Scalars['Float']['input']>;
  AssociationHistories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutOrganizationInput>;
  auto_call_interval?: InputMaybe<Scalars['Int']['input']>;
  auto_dial?: InputMaybe<Scalars['Boolean']['input']>;
  automated_campaigns?: InputMaybe<AutomatedCampaignCreateNestedManyWithoutOrganizationInput>;
  automatic_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  AutoTransferSelections?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutOrganizationInput>;
  Availablities?: InputMaybe<AvailabilityCreateNestedManyWithoutOrganizationInput>;
  AverageOrderValue?: InputMaybe<AverageOrderValueCreateNestedManyWithoutOrganizationInput>;
  best_action_ever_multiplier?: InputMaybe<Scalars['Float']['input']>;
  best_metrics?: InputMaybe<BestMetricRecordCreateNestedManyWithoutOrganizationInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptCreateNestedManyWithoutOrganizationInput>;
  callMeNows?: InputMaybe<CallMeNowCreateNestedManyWithoutOrganizationInput>;
  CallMultiplier?: InputMaybe<CallMultiplierCreateNestedManyWithoutOrganizationInput>;
  CallSharings?: InputMaybe<CallSharingCreateNestedManyWithoutOrganizationInput>;
  can_edit_sdr_transfer_rules?: InputMaybe<Scalars['Boolean']['input']>;
  claim_rule?: InputMaybe<Scalars['Int']['input']>;
  concession?: InputMaybe<ConcessionCreateNestedManyWithoutOrganizationInput>;
  conference_region?: InputMaybe<ConferenceRegion>;
  Conferences?: InputMaybe<ConferenceCreateNestedManyWithoutOrganizationInput>;
  ContactRequests?: InputMaybe<ContactRequestCreateNestedManyWithoutOrganizationInput>;
  Contacts?: InputMaybe<ContactCreateNestedManyWithoutOrganizationInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  custom_fields?: InputMaybe<CustomFieldCreateNestedManyWithoutOrganizationInput>;
  CustomObject?: InputMaybe<CustomObjectCreateNestedManyWithoutOrganizationInput>;
  CustomObjectField?: InputMaybe<CustomObjectFieldCreateNestedManyWithoutOrganizationInput>;
  CustomObjectFieldForeignKey?: InputMaybe<CustomObjectFieldForeignKeyCreateNestedManyWithoutOrganizationInput>;
  CustomObjectRow?: InputMaybe<CustomObjectRowCreateNestedManyWithoutOrganizationInput>;
  CustomQueueItems?: InputMaybe<CustomQueueItemCreateNestedManyWithoutOrganizationInput>;
  DashboardColumnOptions?: InputMaybe<DashboardColumnOptionCreateNestedManyWithoutOrganizationInput>;
  DashboardLeadFilters?: InputMaybe<DashboardLeadFilterCreateNestedManyWithoutOrganizationInput>;
  DashboardRepFilters?: InputMaybe<DashboardRepFilterCreateNestedManyWithoutOrganizationInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewCreateNestedManyWithoutOrganizationInput>;
  day_to_close?: InputMaybe<Scalars['Int']['input']>;
  default_demo_time_duration?: InputMaybe<Scalars['Int']['input']>;
  demo_held_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_not_held_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_not_held_call_back_short_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_prep_time_minutes?: InputMaybe<Scalars['Int']['input']>;
  dev_account?: InputMaybe<Scalars['Boolean']['input']>;
  dial_rule?: InputMaybe<Scalars['Int']['input']>;
  DispositionMultiplier?: InputMaybe<DispositionMultiplierCreateNestedManyWithoutOrganizationInput>;
  dispositions?: InputMaybe<DispositionCreateNestedManyWithoutOrganizationInput>;
  double_booking?: InputMaybe<Scalars['Boolean']['input']>;
  dynamic_date_operators?: InputMaybe<Scalars['Boolean']['input']>;
  edge_servers?: InputMaybe<OrganizationCreateedge_ServersInput>;
  email_conflict_notification_email_list?: InputMaybe<OrganizationCreateemail_Conflict_Notification_Email_ListInput>;
  EmailItems?: InputMaybe<EmailItemCreateNestedManyWithoutOrganizationInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordCreateNestedManyWithoutOrganizationInput>;
  EmailThreads?: InputMaybe<EmailThreadCreateNestedManyWithoutOrganizationInput>;
  enable_modified_metrics?: InputMaybe<Scalars['Boolean']['input']>;
  enable_pay_period_view?: InputMaybe<Scalars['Boolean']['input']>;
  enable_time_constraint?: InputMaybe<Scalars['Boolean']['input']>;
  error_log_email_address?: InputMaybe<OrganizationCreateerror_Log_Email_AddressInput>;
  estimated_average_num_of_weekly_overbooked_demo_slots?: InputMaybe<Scalars['Int']['input']>;
  FolderPermissions?: InputMaybe<FolderPermissionCreateNestedManyWithoutOrganizationInput>;
  Folders?: InputMaybe<FolderCreateNestedManyWithoutOrganizationInput>;
  FutureBookingSetting?: InputMaybe<FutureBookingSettingCreateNestedOneWithoutOrganizationInput>;
  GlobalTransferRule?: InputMaybe<GlobalTransferRuleCreateNestedManyWithoutOrganizationInput>;
  held_demos_per_cc_connect?: InputMaybe<Scalars['Float']['input']>;
  helpful_links?: InputMaybe<OrganizationCreatehelpful_LinksInput>;
  HingePointMultiplier?: InputMaybe<HingePointMultiplierCreateNestedManyWithoutOrganizationInput>;
  hold_goal?: InputMaybe<Scalars['Float']['input']>;
  hubspot_credential?: InputMaybe<HubSpotCredentialCreateNestedOneWithoutOrganizationInput>;
  hubspot_last_sync_date?: InputMaybe<Scalars['DateTime']['input']>;
  hubspot_white_list?: InputMaybe<HubSpotWhitelistCreateNestedManyWithoutOrganizationInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  inbound_close_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_dm_connect_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_lt_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_revenue_value_of_sale?: InputMaybe<Scalars['Float']['input']>;
  inbound_scheduled_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_set_rate?: InputMaybe<Scalars['Float']['input']>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventCreateNestedManyWithoutOrganizationInput>;
  InboundConciergeWidget?: InputMaybe<InboundConciergeWidgetCreateNestedManyWithoutOrganizationInput>;
  industry_options?: InputMaybe<IndustryOptionCreateNestedManyWithoutOrganizationInput>;
  initial_sdr_sets_reps_day?: InputMaybe<Scalars['Float']['input']>;
  integration_error_logs?: InputMaybe<IntegrationErrorLogCreateNestedManyWithoutOrganizationInput>;
  integration_mappings?: InputMaybe<IntegrationMappingCreateNestedManyWithoutOrganizationInput>;
  IntegrationMappingCustomObject?: InputMaybe<IntegrationMappingCustomObjectCreateNestedManyWithoutOrganizationInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogCreateNestedManyWithoutOrganizationInput>;
  IntegrationReadOnlyObject?: InputMaybe<IntegrationReadOnlyObjectCreateNestedManyWithoutOrganizationInput>;
  international?: InputMaybe<Scalars['Boolean']['input']>;
  is_email_domain_verified?: InputMaybe<Scalars['Boolean']['input']>;
  is_salesforce_sandbox?: InputMaybe<Scalars['Boolean']['input']>;
  last_lead_value_compute_time?: InputMaybe<Scalars['DateTime']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutOrganizationInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryCreateNestedManyWithoutOrganizationInput>;
  lead_intent?: InputMaybe<LeadIntentCreateNestedManyWithoutOrganizationInput>;
  lead_release_same_rep_delay_rule?: InputMaybe<Scalars['Int']['input']>;
  lead_routing_email_list?: InputMaybe<OrganizationCreatelead_Routing_Email_ListInput>;
  lead_routing_rules?: InputMaybe<RuleCreateNestedManyWithoutOrganizationInput>;
  LeadCustomObjectRowAssociation?: InputMaybe<LeadCustomObjectRowAssociationCreateNestedManyWithoutOrganizationInput>;
  LeadFieldMultiplier?: InputMaybe<LeadFieldMultiplierCreateNestedManyWithoutOrganizationInput>;
  LeadFieldMultiplierCustom?: InputMaybe<LeadFieldMultiplierCustomCreateNestedManyWithoutOrganizationInput>;
  leads?: InputMaybe<LeadCreateNestedManyWithoutOrganizationInput>;
  leadsource_options?: InputMaybe<LeadSourceOptionCreateNestedManyWithoutOrganizationInput>;
  LeadValue?: InputMaybe<LeadValueCreateNestedManyWithoutOrganizationInput>;
  LifeTimeValueMultiplier?: InputMaybe<LifeTimeValueMultiplierCreateNestedManyWithoutOrganizationInput>;
  LiveTransferSetting?: InputMaybe<LiveTransferSettingCreateNestedOneWithoutOrganizationInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusCreateNestedManyWithoutOrganizationInput>;
  ltv_multiplier_custom_fields?: InputMaybe<LifeTimeValueMultiplierCustomCreateNestedManyWithoutOrganizationInput>;
  made_contact_rule?: InputMaybe<Scalars['Int']['input']>;
  make_sale_address_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_business_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_city_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_country_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_first_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_industry_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_last_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_lead_source_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_primary_email_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_primary_phone_number_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_state_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_sub_industry_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_zip_required?: InputMaybe<Scalars['Boolean']['input']>;
  maxium_day_lag_set_to_schedule?: InputMaybe<Scalars['Int']['input']>;
  mrr?: InputMaybe<Scalars['Int']['input']>;
  mrr_label?: InputMaybe<Scalars['String']['input']>;
  mrr_required_on_sale?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  NoteItems?: InputMaybe<NoteItemCreateNestedManyWithoutOrganizationInput>;
  Notifications?: InputMaybe<NotificationCreateNestedManyWithoutOrganizationInput>;
  onboardComplete?: InputMaybe<Scalars['Boolean']['input']>;
  Opportunity?: InputMaybe<SfOpportunityCreateNestedManyWithoutOrganizationInput>;
  organization_holidays?: InputMaybe<OrganizationHolidayCreateNestedManyWithoutOrganizationInput>;
  organization_hours?: InputMaybe<OrganizationHourCreateNestedManyWithoutOrganizationInput>;
  OrganizationPaymentSetting?: InputMaybe<OrganizationPaymentSettingsCreateNestedOneWithoutOrganizationInput>;
  OrganizationPaymentSettings?: InputMaybe<OrganizationPaymentSettingsCreateNestedOneWithoutOrganizationsInput>;
  orgCalendarInviteMetadata?: InputMaybe<OrgCalendarInviteMetadataCreateNestedManyWithoutOrganizationInput>;
  OrgDefaultPipelinePerecentValue?: InputMaybe<OrgDefaultPipelinePerecentValueCreateNestedOneWithoutOrganizationInput>;
  OrgHubSpotSync?: InputMaybe<OrgHubSpotSyncCreateNestedOneWithoutOrganizationInput>;
  OrgNoEventDate?: InputMaybe<OrgNoEventDateCreateNestedManyWithoutOrganizationInput>;
  OrgSalesforceSync?: InputMaybe<OrgSalesforceSyncCreateNestedOneWithoutOrganizationInput>;
  OrgTimeslotAvailability?: InputMaybe<OrgTimeslotAvailabilityCreateNestedManyWithoutOrganizationInput>;
  outbound_close_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_dm_connect_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_lt_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_revenue_value_of_sale?: InputMaybe<Scalars['Float']['input']>;
  outbound_scheduled_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_set_rate?: InputMaybe<Scalars['Float']['input']>;
  PandadocData?: InputMaybe<PandadocDataCreateNestedOneWithoutOrganizationInput>;
  PandaDocTemplate?: InputMaybe<PandaDocTemplateCreateNestedManyWithoutOrganizationInput>;
  participant_jitter_buffer_size?: InputMaybe<JitterBufferSize>;
  preferred_goal_setting?: InputMaybe<Goalsetting>;
  prepayment?: InputMaybe<Scalars['Boolean']['input']>;
  ProductChangeLog?: InputMaybe<ProductChangeLogCreateNestedManyWithoutOrganizationInput>;
  products?: InputMaybe<ProductCreateNestedManyWithoutOrganizationInput>;
  re_cold_call_hour_delay?: InputMaybe<Scalars['Float']['input']>;
  recordingStatus?: InputMaybe<RecordingStatus>;
  release_lead_resting?: InputMaybe<Scalars['Int']['input']>;
  rep_claim_limit?: InputMaybe<Scalars['Int']['input']>;
  rep_lead_allotment?: InputMaybe<Scalars['Int']['input']>;
  rep_level_call_transfer?: InputMaybe<Scalars['Boolean']['input']>;
  reps_can_bulk_unassign?: InputMaybe<Scalars['Boolean']['input']>;
  reps_can_view_all_notes?: InputMaybe<Scalars['Boolean']['input']>;
  require_sale_notes?: InputMaybe<Scalars['Boolean']['input']>;
  resting_after_skipped_CC?: InputMaybe<Scalars['Int']['input']>;
  resting_period?: InputMaybe<Scalars['Int']['input']>;
  resting_period_after_unsuccessful_cold_call?: InputMaybe<Scalars['Float']['input']>;
  resting_period_after_unsuccessful_demo?: InputMaybe<Scalars['Float']['input']>;
  revenue_plan?: InputMaybe<RevenuePlanItemCreateNestedManyWithoutOrganizationInput>;
  Routings?: InputMaybe<RoutingCreateNestedManyWithoutOrganizationInput>;
  sale_field_options?: InputMaybe<SaleFieldOptionCreateNestedManyWithoutOrganizationInput>;
  sale_notification_email_list?: InputMaybe<OrganizationCreatesale_Notification_Email_ListInput>;
  SaleConfig?: InputMaybe<SaleConfigCreateNestedOneWithoutOrganizationInput>;
  SaleConfigPage?: InputMaybe<SaleConfigPageCreateNestedManyWithoutOrganizationInput>;
  SaleConfigSection?: InputMaybe<SaleConfigSectionCreateNestedManyWithoutOrganizationInput>;
  SaleCycles?: InputMaybe<SaleCycleCreateNestedManyWithoutOrganizationInput>;
  SaleItems?: InputMaybe<SaleItemCreateNestedManyWithoutOrganizationInput>;
  sales?: InputMaybe<SaleCreateNestedManyWithoutOrganizationInput>;
  salesforce_domain?: InputMaybe<Scalars['String']['input']>;
  salesforce_last_sync_date?: InputMaybe<Scalars['DateTime']['input']>;
  SalesForceColumn?: InputMaybe<SalesForceColumnCreateNestedManyWithoutOrganizationInput>;
  salesForceData?: InputMaybe<SalesForceDataCreateNestedOneWithoutOrganizationInput>;
  ScheduleItems?: InputMaybe<ScheduleItemCreateNestedManyWithoutOrganizationInput>;
  self_sourcing_rule?: InputMaybe<Scalars['Int']['input']>;
  send_error_log_email?: InputMaybe<Scalars['Boolean']['input']>;
  send_lead_routing_email?: InputMaybe<Scalars['Boolean']['input']>;
  sendgrid_email_domain?: InputMaybe<Scalars['String']['input']>;
  sendgrid_email_domain_id?: InputMaybe<Scalars['Int']['input']>;
  sequence?: InputMaybe<SequenceCreateNestedManyWithoutOrganzationInput>;
  SequenceCriteriaConditions?: InputMaybe<SequenceCriteriaConditionCreateNestedManyWithoutOrganizationInput>;
  SequenceDashboardViews?: InputMaybe<SequenceDashboardViewCreateNestedManyWithoutOrganizationInput>;
  SequenceEntryCriterias?: InputMaybe<SequenceEntryCriteriaCreateNestedManyWithoutOrganizationInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutOrganizationInput>;
  SequenceSteps?: InputMaybe<SequenceStepCreateNestedManyWithoutOrganizationInput>;
  set_goal?: InputMaybe<Scalars['Float']['input']>;
  short_resting?: InputMaybe<Scalars['Int']['input']>;
  show_calendar_options?: InputMaybe<Scalars['Boolean']['input']>;
  show_contract_duration_sales?: InputMaybe<Scalars['Boolean']['input']>;
  show_lead_source_to_rep?: InputMaybe<Scalars['Boolean']['input']>;
  show_nocontact_button?: InputMaybe<Scalars['Boolean']['input']>;
  show_payment_terms_sales?: InputMaybe<Scalars['Boolean']['input']>;
  show_rep_all_leads?: InputMaybe<Scalars['Boolean']['input']>;
  show_rep_my_leads?: InputMaybe<Scalars['Boolean']['input']>;
  show_system_view?: InputMaybe<Scalars['Boolean']['input']>;
  sites?: InputMaybe<SiteCreateNestedManyWithoutOrganizationInput>;
  sms_opt_in_dialog?: InputMaybe<Scalars['String']['input']>;
  SMSItems?: InputMaybe<SmsItemCreateNestedManyWithoutOrganizationInput>;
  SnoozeTrackings?: InputMaybe<SnoozeTrackingCreateNestedManyWithoutOrganizationInput>;
  StepActionLogs?: InputMaybe<StepActionLogCreateNestedManyWithoutOrganizationInput>;
  StepActions?: InputMaybe<StepActionCreateNestedManyWithoutOrganizationInput>;
  StepConditions?: InputMaybe<StepConditionCreateNestedManyWithoutOrganizationInput>;
  teams?: InputMaybe<TeamCreateNestedManyWithoutOrganizationInput>;
  Template?: InputMaybe<TemplateCreateNestedManyWithoutOrganizationInput>;
  TemplateVersions?: InputMaybe<TemplateVersionCreateNestedManyWithoutOrganizationInput>;
  timekit_id?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  transfer_timeout?: InputMaybe<Scalars['Int']['input']>;
  TransferAttempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutOrganizationInput>;
  twilio_numbers?: InputMaybe<TwilioNumberCreateNestedManyWithoutOrganizationInput>;
  TwilioFeedbacks?: InputMaybe<TwilioFeedbackCreateNestedManyWithoutOrganizationInput>;
  TwilioInfo?: InputMaybe<TwilioInfoCreateNestedOneWithoutOrganzationInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  use_revenue_planning?: InputMaybe<Scalars['Boolean']['input']>;
  UserCustomFieldFilters?: InputMaybe<UserCustomFieldFilterCreateNestedManyWithoutOrganizationInput>;
  users?: InputMaybe<UserCreateNestedManyWithoutOrganizationInput>;
  win_rep_day_goal?: InputMaybe<Scalars['Float']['input']>;
};

export type OrganizationCreateWithoutCustomObjectRowInput = {
  ActivityMonitorMultiplier?: InputMaybe<ActivityMonitorMultiplierCreateNestedManyWithoutOrganizationInput>;
  algoFactor?: InputMaybe<AlgoFactorCreateNestedManyWithoutOrganizationInput>;
  allow_calendar_invite?: InputMaybe<Scalars['Boolean']['input']>;
  allow_remove_step_action?: InputMaybe<Scalars['Boolean']['input']>;
  allow_snooze_step_action?: InputMaybe<Scalars['Boolean']['input']>;
  always_record?: InputMaybe<Scalars['Boolean']['input']>;
  arv_initial_held_demos_generated_by_sdr_weight?: InputMaybe<Scalars['Float']['input']>;
  AssociationHistories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutOrganizationInput>;
  auto_call_interval?: InputMaybe<Scalars['Int']['input']>;
  auto_dial?: InputMaybe<Scalars['Boolean']['input']>;
  automated_campaigns?: InputMaybe<AutomatedCampaignCreateNestedManyWithoutOrganizationInput>;
  automatic_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  AutoTransferSelections?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutOrganizationInput>;
  Availablities?: InputMaybe<AvailabilityCreateNestedManyWithoutOrganizationInput>;
  AverageOrderValue?: InputMaybe<AverageOrderValueCreateNestedManyWithoutOrganizationInput>;
  best_action_ever_multiplier?: InputMaybe<Scalars['Float']['input']>;
  best_metrics?: InputMaybe<BestMetricRecordCreateNestedManyWithoutOrganizationInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptCreateNestedManyWithoutOrganizationInput>;
  callMeNows?: InputMaybe<CallMeNowCreateNestedManyWithoutOrganizationInput>;
  CallMultiplier?: InputMaybe<CallMultiplierCreateNestedManyWithoutOrganizationInput>;
  CallSharings?: InputMaybe<CallSharingCreateNestedManyWithoutOrganizationInput>;
  can_edit_sdr_transfer_rules?: InputMaybe<Scalars['Boolean']['input']>;
  claim_rule?: InputMaybe<Scalars['Int']['input']>;
  concession?: InputMaybe<ConcessionCreateNestedManyWithoutOrganizationInput>;
  conference_region?: InputMaybe<ConferenceRegion>;
  Conferences?: InputMaybe<ConferenceCreateNestedManyWithoutOrganizationInput>;
  ContactRequests?: InputMaybe<ContactRequestCreateNestedManyWithoutOrganizationInput>;
  Contacts?: InputMaybe<ContactCreateNestedManyWithoutOrganizationInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  custom_fields?: InputMaybe<CustomFieldCreateNestedManyWithoutOrganizationInput>;
  CustomObject?: InputMaybe<CustomObjectCreateNestedManyWithoutOrganizationInput>;
  CustomObjectField?: InputMaybe<CustomObjectFieldCreateNestedManyWithoutOrganizationInput>;
  CustomObjectFieldForeignKey?: InputMaybe<CustomObjectFieldForeignKeyCreateNestedManyWithoutOrganizationInput>;
  CustomObjectRecord?: InputMaybe<CustomObjectRecordCreateNestedManyWithoutOrganizationInput>;
  CustomQueueItems?: InputMaybe<CustomQueueItemCreateNestedManyWithoutOrganizationInput>;
  DashboardColumnOptions?: InputMaybe<DashboardColumnOptionCreateNestedManyWithoutOrganizationInput>;
  DashboardLeadFilters?: InputMaybe<DashboardLeadFilterCreateNestedManyWithoutOrganizationInput>;
  DashboardRepFilters?: InputMaybe<DashboardRepFilterCreateNestedManyWithoutOrganizationInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewCreateNestedManyWithoutOrganizationInput>;
  day_to_close?: InputMaybe<Scalars['Int']['input']>;
  default_demo_time_duration?: InputMaybe<Scalars['Int']['input']>;
  demo_held_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_not_held_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_not_held_call_back_short_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_prep_time_minutes?: InputMaybe<Scalars['Int']['input']>;
  dev_account?: InputMaybe<Scalars['Boolean']['input']>;
  dial_rule?: InputMaybe<Scalars['Int']['input']>;
  DispositionMultiplier?: InputMaybe<DispositionMultiplierCreateNestedManyWithoutOrganizationInput>;
  dispositions?: InputMaybe<DispositionCreateNestedManyWithoutOrganizationInput>;
  double_booking?: InputMaybe<Scalars['Boolean']['input']>;
  dynamic_date_operators?: InputMaybe<Scalars['Boolean']['input']>;
  edge_servers?: InputMaybe<OrganizationCreateedge_ServersInput>;
  email_conflict_notification_email_list?: InputMaybe<OrganizationCreateemail_Conflict_Notification_Email_ListInput>;
  EmailItems?: InputMaybe<EmailItemCreateNestedManyWithoutOrganizationInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordCreateNestedManyWithoutOrganizationInput>;
  EmailThreads?: InputMaybe<EmailThreadCreateNestedManyWithoutOrganizationInput>;
  enable_modified_metrics?: InputMaybe<Scalars['Boolean']['input']>;
  enable_pay_period_view?: InputMaybe<Scalars['Boolean']['input']>;
  enable_time_constraint?: InputMaybe<Scalars['Boolean']['input']>;
  error_log_email_address?: InputMaybe<OrganizationCreateerror_Log_Email_AddressInput>;
  estimated_average_num_of_weekly_overbooked_demo_slots?: InputMaybe<Scalars['Int']['input']>;
  FolderPermissions?: InputMaybe<FolderPermissionCreateNestedManyWithoutOrganizationInput>;
  Folders?: InputMaybe<FolderCreateNestedManyWithoutOrganizationInput>;
  FutureBookingSetting?: InputMaybe<FutureBookingSettingCreateNestedOneWithoutOrganizationInput>;
  GlobalTransferRule?: InputMaybe<GlobalTransferRuleCreateNestedManyWithoutOrganizationInput>;
  held_demos_per_cc_connect?: InputMaybe<Scalars['Float']['input']>;
  helpful_links?: InputMaybe<OrganizationCreatehelpful_LinksInput>;
  HingePointMultiplier?: InputMaybe<HingePointMultiplierCreateNestedManyWithoutOrganizationInput>;
  hold_goal?: InputMaybe<Scalars['Float']['input']>;
  hubspot_credential?: InputMaybe<HubSpotCredentialCreateNestedOneWithoutOrganizationInput>;
  hubspot_last_sync_date?: InputMaybe<Scalars['DateTime']['input']>;
  hubspot_white_list?: InputMaybe<HubSpotWhitelistCreateNestedManyWithoutOrganizationInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  inbound_close_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_dm_connect_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_lt_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_revenue_value_of_sale?: InputMaybe<Scalars['Float']['input']>;
  inbound_scheduled_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_set_rate?: InputMaybe<Scalars['Float']['input']>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventCreateNestedManyWithoutOrganizationInput>;
  InboundConciergeWidget?: InputMaybe<InboundConciergeWidgetCreateNestedManyWithoutOrganizationInput>;
  industry_options?: InputMaybe<IndustryOptionCreateNestedManyWithoutOrganizationInput>;
  initial_sdr_sets_reps_day?: InputMaybe<Scalars['Float']['input']>;
  integration_error_logs?: InputMaybe<IntegrationErrorLogCreateNestedManyWithoutOrganizationInput>;
  integration_mappings?: InputMaybe<IntegrationMappingCreateNestedManyWithoutOrganizationInput>;
  IntegrationMappingCustomObject?: InputMaybe<IntegrationMappingCustomObjectCreateNestedManyWithoutOrganizationInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogCreateNestedManyWithoutOrganizationInput>;
  IntegrationReadOnlyObject?: InputMaybe<IntegrationReadOnlyObjectCreateNestedManyWithoutOrganizationInput>;
  international?: InputMaybe<Scalars['Boolean']['input']>;
  is_email_domain_verified?: InputMaybe<Scalars['Boolean']['input']>;
  is_salesforce_sandbox?: InputMaybe<Scalars['Boolean']['input']>;
  last_lead_value_compute_time?: InputMaybe<Scalars['DateTime']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutOrganizationInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryCreateNestedManyWithoutOrganizationInput>;
  lead_intent?: InputMaybe<LeadIntentCreateNestedManyWithoutOrganizationInput>;
  lead_release_same_rep_delay_rule?: InputMaybe<Scalars['Int']['input']>;
  lead_routing_email_list?: InputMaybe<OrganizationCreatelead_Routing_Email_ListInput>;
  lead_routing_rules?: InputMaybe<RuleCreateNestedManyWithoutOrganizationInput>;
  LeadCustomObjectRowAssociation?: InputMaybe<LeadCustomObjectRowAssociationCreateNestedManyWithoutOrganizationInput>;
  LeadFieldMultiplier?: InputMaybe<LeadFieldMultiplierCreateNestedManyWithoutOrganizationInput>;
  LeadFieldMultiplierCustom?: InputMaybe<LeadFieldMultiplierCustomCreateNestedManyWithoutOrganizationInput>;
  leads?: InputMaybe<LeadCreateNestedManyWithoutOrganizationInput>;
  leadsource_options?: InputMaybe<LeadSourceOptionCreateNestedManyWithoutOrganizationInput>;
  LeadValue?: InputMaybe<LeadValueCreateNestedManyWithoutOrganizationInput>;
  LifeTimeValueMultiplier?: InputMaybe<LifeTimeValueMultiplierCreateNestedManyWithoutOrganizationInput>;
  LiveTransferSetting?: InputMaybe<LiveTransferSettingCreateNestedOneWithoutOrganizationInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusCreateNestedManyWithoutOrganizationInput>;
  ltv_multiplier_custom_fields?: InputMaybe<LifeTimeValueMultiplierCustomCreateNestedManyWithoutOrganizationInput>;
  made_contact_rule?: InputMaybe<Scalars['Int']['input']>;
  make_sale_address_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_business_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_city_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_country_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_first_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_industry_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_last_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_lead_source_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_primary_email_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_primary_phone_number_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_state_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_sub_industry_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_zip_required?: InputMaybe<Scalars['Boolean']['input']>;
  maxium_day_lag_set_to_schedule?: InputMaybe<Scalars['Int']['input']>;
  mrr?: InputMaybe<Scalars['Int']['input']>;
  mrr_label?: InputMaybe<Scalars['String']['input']>;
  mrr_required_on_sale?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  NoteItems?: InputMaybe<NoteItemCreateNestedManyWithoutOrganizationInput>;
  Notifications?: InputMaybe<NotificationCreateNestedManyWithoutOrganizationInput>;
  onboardComplete?: InputMaybe<Scalars['Boolean']['input']>;
  Opportunity?: InputMaybe<SfOpportunityCreateNestedManyWithoutOrganizationInput>;
  organization_holidays?: InputMaybe<OrganizationHolidayCreateNestedManyWithoutOrganizationInput>;
  organization_hours?: InputMaybe<OrganizationHourCreateNestedManyWithoutOrganizationInput>;
  OrganizationPaymentSetting?: InputMaybe<OrganizationPaymentSettingsCreateNestedOneWithoutOrganizationInput>;
  OrganizationPaymentSettings?: InputMaybe<OrganizationPaymentSettingsCreateNestedOneWithoutOrganizationsInput>;
  orgCalendarInviteMetadata?: InputMaybe<OrgCalendarInviteMetadataCreateNestedManyWithoutOrganizationInput>;
  OrgDefaultPipelinePerecentValue?: InputMaybe<OrgDefaultPipelinePerecentValueCreateNestedOneWithoutOrganizationInput>;
  OrgHubSpotSync?: InputMaybe<OrgHubSpotSyncCreateNestedOneWithoutOrganizationInput>;
  OrgNoEventDate?: InputMaybe<OrgNoEventDateCreateNestedManyWithoutOrganizationInput>;
  OrgSalesforceSync?: InputMaybe<OrgSalesforceSyncCreateNestedOneWithoutOrganizationInput>;
  OrgTimeslotAvailability?: InputMaybe<OrgTimeslotAvailabilityCreateNestedManyWithoutOrganizationInput>;
  outbound_close_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_dm_connect_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_lt_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_revenue_value_of_sale?: InputMaybe<Scalars['Float']['input']>;
  outbound_scheduled_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_set_rate?: InputMaybe<Scalars['Float']['input']>;
  PandadocData?: InputMaybe<PandadocDataCreateNestedOneWithoutOrganizationInput>;
  PandaDocTemplate?: InputMaybe<PandaDocTemplateCreateNestedManyWithoutOrganizationInput>;
  participant_jitter_buffer_size?: InputMaybe<JitterBufferSize>;
  preferred_goal_setting?: InputMaybe<Goalsetting>;
  prepayment?: InputMaybe<Scalars['Boolean']['input']>;
  ProductChangeLog?: InputMaybe<ProductChangeLogCreateNestedManyWithoutOrganizationInput>;
  products?: InputMaybe<ProductCreateNestedManyWithoutOrganizationInput>;
  re_cold_call_hour_delay?: InputMaybe<Scalars['Float']['input']>;
  recordingStatus?: InputMaybe<RecordingStatus>;
  release_lead_resting?: InputMaybe<Scalars['Int']['input']>;
  rep_claim_limit?: InputMaybe<Scalars['Int']['input']>;
  rep_lead_allotment?: InputMaybe<Scalars['Int']['input']>;
  rep_level_call_transfer?: InputMaybe<Scalars['Boolean']['input']>;
  reps_can_bulk_unassign?: InputMaybe<Scalars['Boolean']['input']>;
  reps_can_view_all_notes?: InputMaybe<Scalars['Boolean']['input']>;
  require_sale_notes?: InputMaybe<Scalars['Boolean']['input']>;
  resting_after_skipped_CC?: InputMaybe<Scalars['Int']['input']>;
  resting_period?: InputMaybe<Scalars['Int']['input']>;
  resting_period_after_unsuccessful_cold_call?: InputMaybe<Scalars['Float']['input']>;
  resting_period_after_unsuccessful_demo?: InputMaybe<Scalars['Float']['input']>;
  revenue_plan?: InputMaybe<RevenuePlanItemCreateNestedManyWithoutOrganizationInput>;
  Routings?: InputMaybe<RoutingCreateNestedManyWithoutOrganizationInput>;
  sale_field_options?: InputMaybe<SaleFieldOptionCreateNestedManyWithoutOrganizationInput>;
  sale_notification_email_list?: InputMaybe<OrganizationCreatesale_Notification_Email_ListInput>;
  SaleConfig?: InputMaybe<SaleConfigCreateNestedOneWithoutOrganizationInput>;
  SaleConfigPage?: InputMaybe<SaleConfigPageCreateNestedManyWithoutOrganizationInput>;
  SaleConfigSection?: InputMaybe<SaleConfigSectionCreateNestedManyWithoutOrganizationInput>;
  SaleCycles?: InputMaybe<SaleCycleCreateNestedManyWithoutOrganizationInput>;
  SaleItems?: InputMaybe<SaleItemCreateNestedManyWithoutOrganizationInput>;
  sales?: InputMaybe<SaleCreateNestedManyWithoutOrganizationInput>;
  salesforce_domain?: InputMaybe<Scalars['String']['input']>;
  salesforce_last_sync_date?: InputMaybe<Scalars['DateTime']['input']>;
  SalesForceColumn?: InputMaybe<SalesForceColumnCreateNestedManyWithoutOrganizationInput>;
  salesForceData?: InputMaybe<SalesForceDataCreateNestedOneWithoutOrganizationInput>;
  ScheduleItems?: InputMaybe<ScheduleItemCreateNestedManyWithoutOrganizationInput>;
  self_sourcing_rule?: InputMaybe<Scalars['Int']['input']>;
  send_error_log_email?: InputMaybe<Scalars['Boolean']['input']>;
  send_lead_routing_email?: InputMaybe<Scalars['Boolean']['input']>;
  sendgrid_email_domain?: InputMaybe<Scalars['String']['input']>;
  sendgrid_email_domain_id?: InputMaybe<Scalars['Int']['input']>;
  sequence?: InputMaybe<SequenceCreateNestedManyWithoutOrganzationInput>;
  SequenceCriteriaConditions?: InputMaybe<SequenceCriteriaConditionCreateNestedManyWithoutOrganizationInput>;
  SequenceDashboardViews?: InputMaybe<SequenceDashboardViewCreateNestedManyWithoutOrganizationInput>;
  SequenceEntryCriterias?: InputMaybe<SequenceEntryCriteriaCreateNestedManyWithoutOrganizationInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutOrganizationInput>;
  SequenceSteps?: InputMaybe<SequenceStepCreateNestedManyWithoutOrganizationInput>;
  set_goal?: InputMaybe<Scalars['Float']['input']>;
  short_resting?: InputMaybe<Scalars['Int']['input']>;
  show_calendar_options?: InputMaybe<Scalars['Boolean']['input']>;
  show_contract_duration_sales?: InputMaybe<Scalars['Boolean']['input']>;
  show_lead_source_to_rep?: InputMaybe<Scalars['Boolean']['input']>;
  show_nocontact_button?: InputMaybe<Scalars['Boolean']['input']>;
  show_payment_terms_sales?: InputMaybe<Scalars['Boolean']['input']>;
  show_rep_all_leads?: InputMaybe<Scalars['Boolean']['input']>;
  show_rep_my_leads?: InputMaybe<Scalars['Boolean']['input']>;
  show_system_view?: InputMaybe<Scalars['Boolean']['input']>;
  sites?: InputMaybe<SiteCreateNestedManyWithoutOrganizationInput>;
  sms_opt_in_dialog?: InputMaybe<Scalars['String']['input']>;
  SMSItems?: InputMaybe<SmsItemCreateNestedManyWithoutOrganizationInput>;
  SnoozeTrackings?: InputMaybe<SnoozeTrackingCreateNestedManyWithoutOrganizationInput>;
  StepActionLogs?: InputMaybe<StepActionLogCreateNestedManyWithoutOrganizationInput>;
  StepActions?: InputMaybe<StepActionCreateNestedManyWithoutOrganizationInput>;
  StepConditions?: InputMaybe<StepConditionCreateNestedManyWithoutOrganizationInput>;
  teams?: InputMaybe<TeamCreateNestedManyWithoutOrganizationInput>;
  Template?: InputMaybe<TemplateCreateNestedManyWithoutOrganizationInput>;
  TemplateVersions?: InputMaybe<TemplateVersionCreateNestedManyWithoutOrganizationInput>;
  timekit_id?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  transfer_timeout?: InputMaybe<Scalars['Int']['input']>;
  TransferAttempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutOrganizationInput>;
  twilio_numbers?: InputMaybe<TwilioNumberCreateNestedManyWithoutOrganizationInput>;
  TwilioFeedbacks?: InputMaybe<TwilioFeedbackCreateNestedManyWithoutOrganizationInput>;
  TwilioInfo?: InputMaybe<TwilioInfoCreateNestedOneWithoutOrganzationInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  use_revenue_planning?: InputMaybe<Scalars['Boolean']['input']>;
  UserCustomFieldFilters?: InputMaybe<UserCustomFieldFilterCreateNestedManyWithoutOrganizationInput>;
  users?: InputMaybe<UserCreateNestedManyWithoutOrganizationInput>;
  win_rep_day_goal?: InputMaybe<Scalars['Float']['input']>;
};

export type OrganizationCreateWithoutCustomQueueItemsInput = {
  ActivityMonitorMultiplier?: InputMaybe<ActivityMonitorMultiplierCreateNestedManyWithoutOrganizationInput>;
  algoFactor?: InputMaybe<AlgoFactorCreateNestedManyWithoutOrganizationInput>;
  allow_calendar_invite?: InputMaybe<Scalars['Boolean']['input']>;
  allow_remove_step_action?: InputMaybe<Scalars['Boolean']['input']>;
  allow_snooze_step_action?: InputMaybe<Scalars['Boolean']['input']>;
  always_record?: InputMaybe<Scalars['Boolean']['input']>;
  arv_initial_held_demos_generated_by_sdr_weight?: InputMaybe<Scalars['Float']['input']>;
  AssociationHistories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutOrganizationInput>;
  auto_call_interval?: InputMaybe<Scalars['Int']['input']>;
  auto_dial?: InputMaybe<Scalars['Boolean']['input']>;
  automated_campaigns?: InputMaybe<AutomatedCampaignCreateNestedManyWithoutOrganizationInput>;
  automatic_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  AutoTransferSelections?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutOrganizationInput>;
  Availablities?: InputMaybe<AvailabilityCreateNestedManyWithoutOrganizationInput>;
  AverageOrderValue?: InputMaybe<AverageOrderValueCreateNestedManyWithoutOrganizationInput>;
  best_action_ever_multiplier?: InputMaybe<Scalars['Float']['input']>;
  best_metrics?: InputMaybe<BestMetricRecordCreateNestedManyWithoutOrganizationInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptCreateNestedManyWithoutOrganizationInput>;
  callMeNows?: InputMaybe<CallMeNowCreateNestedManyWithoutOrganizationInput>;
  CallMultiplier?: InputMaybe<CallMultiplierCreateNestedManyWithoutOrganizationInput>;
  CallSharings?: InputMaybe<CallSharingCreateNestedManyWithoutOrganizationInput>;
  can_edit_sdr_transfer_rules?: InputMaybe<Scalars['Boolean']['input']>;
  claim_rule?: InputMaybe<Scalars['Int']['input']>;
  concession?: InputMaybe<ConcessionCreateNestedManyWithoutOrganizationInput>;
  conference_region?: InputMaybe<ConferenceRegion>;
  Conferences?: InputMaybe<ConferenceCreateNestedManyWithoutOrganizationInput>;
  ContactRequests?: InputMaybe<ContactRequestCreateNestedManyWithoutOrganizationInput>;
  Contacts?: InputMaybe<ContactCreateNestedManyWithoutOrganizationInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  custom_fields?: InputMaybe<CustomFieldCreateNestedManyWithoutOrganizationInput>;
  CustomObject?: InputMaybe<CustomObjectCreateNestedManyWithoutOrganizationInput>;
  CustomObjectField?: InputMaybe<CustomObjectFieldCreateNestedManyWithoutOrganizationInput>;
  CustomObjectFieldForeignKey?: InputMaybe<CustomObjectFieldForeignKeyCreateNestedManyWithoutOrganizationInput>;
  CustomObjectRecord?: InputMaybe<CustomObjectRecordCreateNestedManyWithoutOrganizationInput>;
  CustomObjectRow?: InputMaybe<CustomObjectRowCreateNestedManyWithoutOrganizationInput>;
  DashboardColumnOptions?: InputMaybe<DashboardColumnOptionCreateNestedManyWithoutOrganizationInput>;
  DashboardLeadFilters?: InputMaybe<DashboardLeadFilterCreateNestedManyWithoutOrganizationInput>;
  DashboardRepFilters?: InputMaybe<DashboardRepFilterCreateNestedManyWithoutOrganizationInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewCreateNestedManyWithoutOrganizationInput>;
  day_to_close?: InputMaybe<Scalars['Int']['input']>;
  default_demo_time_duration?: InputMaybe<Scalars['Int']['input']>;
  demo_held_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_not_held_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_not_held_call_back_short_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_prep_time_minutes?: InputMaybe<Scalars['Int']['input']>;
  dev_account?: InputMaybe<Scalars['Boolean']['input']>;
  dial_rule?: InputMaybe<Scalars['Int']['input']>;
  DispositionMultiplier?: InputMaybe<DispositionMultiplierCreateNestedManyWithoutOrganizationInput>;
  dispositions?: InputMaybe<DispositionCreateNestedManyWithoutOrganizationInput>;
  double_booking?: InputMaybe<Scalars['Boolean']['input']>;
  dynamic_date_operators?: InputMaybe<Scalars['Boolean']['input']>;
  edge_servers?: InputMaybe<OrganizationCreateedge_ServersInput>;
  email_conflict_notification_email_list?: InputMaybe<OrganizationCreateemail_Conflict_Notification_Email_ListInput>;
  EmailItems?: InputMaybe<EmailItemCreateNestedManyWithoutOrganizationInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordCreateNestedManyWithoutOrganizationInput>;
  EmailThreads?: InputMaybe<EmailThreadCreateNestedManyWithoutOrganizationInput>;
  enable_modified_metrics?: InputMaybe<Scalars['Boolean']['input']>;
  enable_pay_period_view?: InputMaybe<Scalars['Boolean']['input']>;
  enable_time_constraint?: InputMaybe<Scalars['Boolean']['input']>;
  error_log_email_address?: InputMaybe<OrganizationCreateerror_Log_Email_AddressInput>;
  estimated_average_num_of_weekly_overbooked_demo_slots?: InputMaybe<Scalars['Int']['input']>;
  FolderPermissions?: InputMaybe<FolderPermissionCreateNestedManyWithoutOrganizationInput>;
  Folders?: InputMaybe<FolderCreateNestedManyWithoutOrganizationInput>;
  FutureBookingSetting?: InputMaybe<FutureBookingSettingCreateNestedOneWithoutOrganizationInput>;
  GlobalTransferRule?: InputMaybe<GlobalTransferRuleCreateNestedManyWithoutOrganizationInput>;
  held_demos_per_cc_connect?: InputMaybe<Scalars['Float']['input']>;
  helpful_links?: InputMaybe<OrganizationCreatehelpful_LinksInput>;
  HingePointMultiplier?: InputMaybe<HingePointMultiplierCreateNestedManyWithoutOrganizationInput>;
  hold_goal?: InputMaybe<Scalars['Float']['input']>;
  hubspot_credential?: InputMaybe<HubSpotCredentialCreateNestedOneWithoutOrganizationInput>;
  hubspot_last_sync_date?: InputMaybe<Scalars['DateTime']['input']>;
  hubspot_white_list?: InputMaybe<HubSpotWhitelistCreateNestedManyWithoutOrganizationInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  inbound_close_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_dm_connect_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_lt_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_revenue_value_of_sale?: InputMaybe<Scalars['Float']['input']>;
  inbound_scheduled_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_set_rate?: InputMaybe<Scalars['Float']['input']>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventCreateNestedManyWithoutOrganizationInput>;
  InboundConciergeWidget?: InputMaybe<InboundConciergeWidgetCreateNestedManyWithoutOrganizationInput>;
  industry_options?: InputMaybe<IndustryOptionCreateNestedManyWithoutOrganizationInput>;
  initial_sdr_sets_reps_day?: InputMaybe<Scalars['Float']['input']>;
  integration_error_logs?: InputMaybe<IntegrationErrorLogCreateNestedManyWithoutOrganizationInput>;
  integration_mappings?: InputMaybe<IntegrationMappingCreateNestedManyWithoutOrganizationInput>;
  IntegrationMappingCustomObject?: InputMaybe<IntegrationMappingCustomObjectCreateNestedManyWithoutOrganizationInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogCreateNestedManyWithoutOrganizationInput>;
  IntegrationReadOnlyObject?: InputMaybe<IntegrationReadOnlyObjectCreateNestedManyWithoutOrganizationInput>;
  international?: InputMaybe<Scalars['Boolean']['input']>;
  is_email_domain_verified?: InputMaybe<Scalars['Boolean']['input']>;
  is_salesforce_sandbox?: InputMaybe<Scalars['Boolean']['input']>;
  last_lead_value_compute_time?: InputMaybe<Scalars['DateTime']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutOrganizationInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryCreateNestedManyWithoutOrganizationInput>;
  lead_intent?: InputMaybe<LeadIntentCreateNestedManyWithoutOrganizationInput>;
  lead_release_same_rep_delay_rule?: InputMaybe<Scalars['Int']['input']>;
  lead_routing_email_list?: InputMaybe<OrganizationCreatelead_Routing_Email_ListInput>;
  lead_routing_rules?: InputMaybe<RuleCreateNestedManyWithoutOrganizationInput>;
  LeadCustomObjectRowAssociation?: InputMaybe<LeadCustomObjectRowAssociationCreateNestedManyWithoutOrganizationInput>;
  LeadFieldMultiplier?: InputMaybe<LeadFieldMultiplierCreateNestedManyWithoutOrganizationInput>;
  LeadFieldMultiplierCustom?: InputMaybe<LeadFieldMultiplierCustomCreateNestedManyWithoutOrganizationInput>;
  leads?: InputMaybe<LeadCreateNestedManyWithoutOrganizationInput>;
  leadsource_options?: InputMaybe<LeadSourceOptionCreateNestedManyWithoutOrganizationInput>;
  LeadValue?: InputMaybe<LeadValueCreateNestedManyWithoutOrganizationInput>;
  LifeTimeValueMultiplier?: InputMaybe<LifeTimeValueMultiplierCreateNestedManyWithoutOrganizationInput>;
  LiveTransferSetting?: InputMaybe<LiveTransferSettingCreateNestedOneWithoutOrganizationInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusCreateNestedManyWithoutOrganizationInput>;
  ltv_multiplier_custom_fields?: InputMaybe<LifeTimeValueMultiplierCustomCreateNestedManyWithoutOrganizationInput>;
  made_contact_rule?: InputMaybe<Scalars['Int']['input']>;
  make_sale_address_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_business_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_city_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_country_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_first_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_industry_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_last_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_lead_source_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_primary_email_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_primary_phone_number_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_state_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_sub_industry_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_zip_required?: InputMaybe<Scalars['Boolean']['input']>;
  maxium_day_lag_set_to_schedule?: InputMaybe<Scalars['Int']['input']>;
  mrr?: InputMaybe<Scalars['Int']['input']>;
  mrr_label?: InputMaybe<Scalars['String']['input']>;
  mrr_required_on_sale?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  NoteItems?: InputMaybe<NoteItemCreateNestedManyWithoutOrganizationInput>;
  Notifications?: InputMaybe<NotificationCreateNestedManyWithoutOrganizationInput>;
  onboardComplete?: InputMaybe<Scalars['Boolean']['input']>;
  Opportunity?: InputMaybe<SfOpportunityCreateNestedManyWithoutOrganizationInput>;
  organization_holidays?: InputMaybe<OrganizationHolidayCreateNestedManyWithoutOrganizationInput>;
  organization_hours?: InputMaybe<OrganizationHourCreateNestedManyWithoutOrganizationInput>;
  OrganizationPaymentSetting?: InputMaybe<OrganizationPaymentSettingsCreateNestedOneWithoutOrganizationInput>;
  OrganizationPaymentSettings?: InputMaybe<OrganizationPaymentSettingsCreateNestedOneWithoutOrganizationsInput>;
  orgCalendarInviteMetadata?: InputMaybe<OrgCalendarInviteMetadataCreateNestedManyWithoutOrganizationInput>;
  OrgDefaultPipelinePerecentValue?: InputMaybe<OrgDefaultPipelinePerecentValueCreateNestedOneWithoutOrganizationInput>;
  OrgHubSpotSync?: InputMaybe<OrgHubSpotSyncCreateNestedOneWithoutOrganizationInput>;
  OrgNoEventDate?: InputMaybe<OrgNoEventDateCreateNestedManyWithoutOrganizationInput>;
  OrgSalesforceSync?: InputMaybe<OrgSalesforceSyncCreateNestedOneWithoutOrganizationInput>;
  OrgTimeslotAvailability?: InputMaybe<OrgTimeslotAvailabilityCreateNestedManyWithoutOrganizationInput>;
  outbound_close_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_dm_connect_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_lt_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_revenue_value_of_sale?: InputMaybe<Scalars['Float']['input']>;
  outbound_scheduled_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_set_rate?: InputMaybe<Scalars['Float']['input']>;
  PandadocData?: InputMaybe<PandadocDataCreateNestedOneWithoutOrganizationInput>;
  PandaDocTemplate?: InputMaybe<PandaDocTemplateCreateNestedManyWithoutOrganizationInput>;
  participant_jitter_buffer_size?: InputMaybe<JitterBufferSize>;
  preferred_goal_setting?: InputMaybe<Goalsetting>;
  prepayment?: InputMaybe<Scalars['Boolean']['input']>;
  ProductChangeLog?: InputMaybe<ProductChangeLogCreateNestedManyWithoutOrganizationInput>;
  products?: InputMaybe<ProductCreateNestedManyWithoutOrganizationInput>;
  re_cold_call_hour_delay?: InputMaybe<Scalars['Float']['input']>;
  recordingStatus?: InputMaybe<RecordingStatus>;
  release_lead_resting?: InputMaybe<Scalars['Int']['input']>;
  rep_claim_limit?: InputMaybe<Scalars['Int']['input']>;
  rep_lead_allotment?: InputMaybe<Scalars['Int']['input']>;
  rep_level_call_transfer?: InputMaybe<Scalars['Boolean']['input']>;
  reps_can_bulk_unassign?: InputMaybe<Scalars['Boolean']['input']>;
  reps_can_view_all_notes?: InputMaybe<Scalars['Boolean']['input']>;
  require_sale_notes?: InputMaybe<Scalars['Boolean']['input']>;
  resting_after_skipped_CC?: InputMaybe<Scalars['Int']['input']>;
  resting_period?: InputMaybe<Scalars['Int']['input']>;
  resting_period_after_unsuccessful_cold_call?: InputMaybe<Scalars['Float']['input']>;
  resting_period_after_unsuccessful_demo?: InputMaybe<Scalars['Float']['input']>;
  revenue_plan?: InputMaybe<RevenuePlanItemCreateNestedManyWithoutOrganizationInput>;
  Routings?: InputMaybe<RoutingCreateNestedManyWithoutOrganizationInput>;
  sale_field_options?: InputMaybe<SaleFieldOptionCreateNestedManyWithoutOrganizationInput>;
  sale_notification_email_list?: InputMaybe<OrganizationCreatesale_Notification_Email_ListInput>;
  SaleConfig?: InputMaybe<SaleConfigCreateNestedOneWithoutOrganizationInput>;
  SaleConfigPage?: InputMaybe<SaleConfigPageCreateNestedManyWithoutOrganizationInput>;
  SaleConfigSection?: InputMaybe<SaleConfigSectionCreateNestedManyWithoutOrganizationInput>;
  SaleCycles?: InputMaybe<SaleCycleCreateNestedManyWithoutOrganizationInput>;
  SaleItems?: InputMaybe<SaleItemCreateNestedManyWithoutOrganizationInput>;
  sales?: InputMaybe<SaleCreateNestedManyWithoutOrganizationInput>;
  salesforce_domain?: InputMaybe<Scalars['String']['input']>;
  salesforce_last_sync_date?: InputMaybe<Scalars['DateTime']['input']>;
  SalesForceColumn?: InputMaybe<SalesForceColumnCreateNestedManyWithoutOrganizationInput>;
  salesForceData?: InputMaybe<SalesForceDataCreateNestedOneWithoutOrganizationInput>;
  ScheduleItems?: InputMaybe<ScheduleItemCreateNestedManyWithoutOrganizationInput>;
  self_sourcing_rule?: InputMaybe<Scalars['Int']['input']>;
  send_error_log_email?: InputMaybe<Scalars['Boolean']['input']>;
  send_lead_routing_email?: InputMaybe<Scalars['Boolean']['input']>;
  sendgrid_email_domain?: InputMaybe<Scalars['String']['input']>;
  sendgrid_email_domain_id?: InputMaybe<Scalars['Int']['input']>;
  sequence?: InputMaybe<SequenceCreateNestedManyWithoutOrganzationInput>;
  SequenceCriteriaConditions?: InputMaybe<SequenceCriteriaConditionCreateNestedManyWithoutOrganizationInput>;
  SequenceDashboardViews?: InputMaybe<SequenceDashboardViewCreateNestedManyWithoutOrganizationInput>;
  SequenceEntryCriterias?: InputMaybe<SequenceEntryCriteriaCreateNestedManyWithoutOrganizationInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutOrganizationInput>;
  SequenceSteps?: InputMaybe<SequenceStepCreateNestedManyWithoutOrganizationInput>;
  set_goal?: InputMaybe<Scalars['Float']['input']>;
  short_resting?: InputMaybe<Scalars['Int']['input']>;
  show_calendar_options?: InputMaybe<Scalars['Boolean']['input']>;
  show_contract_duration_sales?: InputMaybe<Scalars['Boolean']['input']>;
  show_lead_source_to_rep?: InputMaybe<Scalars['Boolean']['input']>;
  show_nocontact_button?: InputMaybe<Scalars['Boolean']['input']>;
  show_payment_terms_sales?: InputMaybe<Scalars['Boolean']['input']>;
  show_rep_all_leads?: InputMaybe<Scalars['Boolean']['input']>;
  show_rep_my_leads?: InputMaybe<Scalars['Boolean']['input']>;
  show_system_view?: InputMaybe<Scalars['Boolean']['input']>;
  sites?: InputMaybe<SiteCreateNestedManyWithoutOrganizationInput>;
  sms_opt_in_dialog?: InputMaybe<Scalars['String']['input']>;
  SMSItems?: InputMaybe<SmsItemCreateNestedManyWithoutOrganizationInput>;
  SnoozeTrackings?: InputMaybe<SnoozeTrackingCreateNestedManyWithoutOrganizationInput>;
  StepActionLogs?: InputMaybe<StepActionLogCreateNestedManyWithoutOrganizationInput>;
  StepActions?: InputMaybe<StepActionCreateNestedManyWithoutOrganizationInput>;
  StepConditions?: InputMaybe<StepConditionCreateNestedManyWithoutOrganizationInput>;
  teams?: InputMaybe<TeamCreateNestedManyWithoutOrganizationInput>;
  Template?: InputMaybe<TemplateCreateNestedManyWithoutOrganizationInput>;
  TemplateVersions?: InputMaybe<TemplateVersionCreateNestedManyWithoutOrganizationInput>;
  timekit_id?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  transfer_timeout?: InputMaybe<Scalars['Int']['input']>;
  TransferAttempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutOrganizationInput>;
  twilio_numbers?: InputMaybe<TwilioNumberCreateNestedManyWithoutOrganizationInput>;
  TwilioFeedbacks?: InputMaybe<TwilioFeedbackCreateNestedManyWithoutOrganizationInput>;
  TwilioInfo?: InputMaybe<TwilioInfoCreateNestedOneWithoutOrganzationInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  use_revenue_planning?: InputMaybe<Scalars['Boolean']['input']>;
  UserCustomFieldFilters?: InputMaybe<UserCustomFieldFilterCreateNestedManyWithoutOrganizationInput>;
  users?: InputMaybe<UserCreateNestedManyWithoutOrganizationInput>;
  win_rep_day_goal?: InputMaybe<Scalars['Float']['input']>;
};

export type OrganizationCreateWithoutDashboardColumnOptionsInput = {
  ActivityMonitorMultiplier?: InputMaybe<ActivityMonitorMultiplierCreateNestedManyWithoutOrganizationInput>;
  algoFactor?: InputMaybe<AlgoFactorCreateNestedManyWithoutOrganizationInput>;
  allow_calendar_invite?: InputMaybe<Scalars['Boolean']['input']>;
  allow_remove_step_action?: InputMaybe<Scalars['Boolean']['input']>;
  allow_snooze_step_action?: InputMaybe<Scalars['Boolean']['input']>;
  always_record?: InputMaybe<Scalars['Boolean']['input']>;
  arv_initial_held_demos_generated_by_sdr_weight?: InputMaybe<Scalars['Float']['input']>;
  AssociationHistories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutOrganizationInput>;
  auto_call_interval?: InputMaybe<Scalars['Int']['input']>;
  auto_dial?: InputMaybe<Scalars['Boolean']['input']>;
  automated_campaigns?: InputMaybe<AutomatedCampaignCreateNestedManyWithoutOrganizationInput>;
  automatic_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  AutoTransferSelections?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutOrganizationInput>;
  Availablities?: InputMaybe<AvailabilityCreateNestedManyWithoutOrganizationInput>;
  AverageOrderValue?: InputMaybe<AverageOrderValueCreateNestedManyWithoutOrganizationInput>;
  best_action_ever_multiplier?: InputMaybe<Scalars['Float']['input']>;
  best_metrics?: InputMaybe<BestMetricRecordCreateNestedManyWithoutOrganizationInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptCreateNestedManyWithoutOrganizationInput>;
  callMeNows?: InputMaybe<CallMeNowCreateNestedManyWithoutOrganizationInput>;
  CallMultiplier?: InputMaybe<CallMultiplierCreateNestedManyWithoutOrganizationInput>;
  CallSharings?: InputMaybe<CallSharingCreateNestedManyWithoutOrganizationInput>;
  can_edit_sdr_transfer_rules?: InputMaybe<Scalars['Boolean']['input']>;
  claim_rule?: InputMaybe<Scalars['Int']['input']>;
  concession?: InputMaybe<ConcessionCreateNestedManyWithoutOrganizationInput>;
  conference_region?: InputMaybe<ConferenceRegion>;
  Conferences?: InputMaybe<ConferenceCreateNestedManyWithoutOrganizationInput>;
  ContactRequests?: InputMaybe<ContactRequestCreateNestedManyWithoutOrganizationInput>;
  Contacts?: InputMaybe<ContactCreateNestedManyWithoutOrganizationInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  custom_fields?: InputMaybe<CustomFieldCreateNestedManyWithoutOrganizationInput>;
  CustomObject?: InputMaybe<CustomObjectCreateNestedManyWithoutOrganizationInput>;
  CustomObjectField?: InputMaybe<CustomObjectFieldCreateNestedManyWithoutOrganizationInput>;
  CustomObjectFieldForeignKey?: InputMaybe<CustomObjectFieldForeignKeyCreateNestedManyWithoutOrganizationInput>;
  CustomObjectRecord?: InputMaybe<CustomObjectRecordCreateNestedManyWithoutOrganizationInput>;
  CustomObjectRow?: InputMaybe<CustomObjectRowCreateNestedManyWithoutOrganizationInput>;
  CustomQueueItems?: InputMaybe<CustomQueueItemCreateNestedManyWithoutOrganizationInput>;
  DashboardLeadFilters?: InputMaybe<DashboardLeadFilterCreateNestedManyWithoutOrganizationInput>;
  DashboardRepFilters?: InputMaybe<DashboardRepFilterCreateNestedManyWithoutOrganizationInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewCreateNestedManyWithoutOrganizationInput>;
  day_to_close?: InputMaybe<Scalars['Int']['input']>;
  default_demo_time_duration?: InputMaybe<Scalars['Int']['input']>;
  demo_held_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_not_held_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_not_held_call_back_short_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_prep_time_minutes?: InputMaybe<Scalars['Int']['input']>;
  dev_account?: InputMaybe<Scalars['Boolean']['input']>;
  dial_rule?: InputMaybe<Scalars['Int']['input']>;
  DispositionMultiplier?: InputMaybe<DispositionMultiplierCreateNestedManyWithoutOrganizationInput>;
  dispositions?: InputMaybe<DispositionCreateNestedManyWithoutOrganizationInput>;
  double_booking?: InputMaybe<Scalars['Boolean']['input']>;
  dynamic_date_operators?: InputMaybe<Scalars['Boolean']['input']>;
  edge_servers?: InputMaybe<OrganizationCreateedge_ServersInput>;
  email_conflict_notification_email_list?: InputMaybe<OrganizationCreateemail_Conflict_Notification_Email_ListInput>;
  EmailItems?: InputMaybe<EmailItemCreateNestedManyWithoutOrganizationInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordCreateNestedManyWithoutOrganizationInput>;
  EmailThreads?: InputMaybe<EmailThreadCreateNestedManyWithoutOrganizationInput>;
  enable_modified_metrics?: InputMaybe<Scalars['Boolean']['input']>;
  enable_pay_period_view?: InputMaybe<Scalars['Boolean']['input']>;
  enable_time_constraint?: InputMaybe<Scalars['Boolean']['input']>;
  error_log_email_address?: InputMaybe<OrganizationCreateerror_Log_Email_AddressInput>;
  estimated_average_num_of_weekly_overbooked_demo_slots?: InputMaybe<Scalars['Int']['input']>;
  FolderPermissions?: InputMaybe<FolderPermissionCreateNestedManyWithoutOrganizationInput>;
  Folders?: InputMaybe<FolderCreateNestedManyWithoutOrganizationInput>;
  FutureBookingSetting?: InputMaybe<FutureBookingSettingCreateNestedOneWithoutOrganizationInput>;
  GlobalTransferRule?: InputMaybe<GlobalTransferRuleCreateNestedManyWithoutOrganizationInput>;
  held_demos_per_cc_connect?: InputMaybe<Scalars['Float']['input']>;
  helpful_links?: InputMaybe<OrganizationCreatehelpful_LinksInput>;
  HingePointMultiplier?: InputMaybe<HingePointMultiplierCreateNestedManyWithoutOrganizationInput>;
  hold_goal?: InputMaybe<Scalars['Float']['input']>;
  hubspot_credential?: InputMaybe<HubSpotCredentialCreateNestedOneWithoutOrganizationInput>;
  hubspot_last_sync_date?: InputMaybe<Scalars['DateTime']['input']>;
  hubspot_white_list?: InputMaybe<HubSpotWhitelistCreateNestedManyWithoutOrganizationInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  inbound_close_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_dm_connect_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_lt_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_revenue_value_of_sale?: InputMaybe<Scalars['Float']['input']>;
  inbound_scheduled_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_set_rate?: InputMaybe<Scalars['Float']['input']>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventCreateNestedManyWithoutOrganizationInput>;
  InboundConciergeWidget?: InputMaybe<InboundConciergeWidgetCreateNestedManyWithoutOrganizationInput>;
  industry_options?: InputMaybe<IndustryOptionCreateNestedManyWithoutOrganizationInput>;
  initial_sdr_sets_reps_day?: InputMaybe<Scalars['Float']['input']>;
  integration_error_logs?: InputMaybe<IntegrationErrorLogCreateNestedManyWithoutOrganizationInput>;
  integration_mappings?: InputMaybe<IntegrationMappingCreateNestedManyWithoutOrganizationInput>;
  IntegrationMappingCustomObject?: InputMaybe<IntegrationMappingCustomObjectCreateNestedManyWithoutOrganizationInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogCreateNestedManyWithoutOrganizationInput>;
  IntegrationReadOnlyObject?: InputMaybe<IntegrationReadOnlyObjectCreateNestedManyWithoutOrganizationInput>;
  international?: InputMaybe<Scalars['Boolean']['input']>;
  is_email_domain_verified?: InputMaybe<Scalars['Boolean']['input']>;
  is_salesforce_sandbox?: InputMaybe<Scalars['Boolean']['input']>;
  last_lead_value_compute_time?: InputMaybe<Scalars['DateTime']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutOrganizationInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryCreateNestedManyWithoutOrganizationInput>;
  lead_intent?: InputMaybe<LeadIntentCreateNestedManyWithoutOrganizationInput>;
  lead_release_same_rep_delay_rule?: InputMaybe<Scalars['Int']['input']>;
  lead_routing_email_list?: InputMaybe<OrganizationCreatelead_Routing_Email_ListInput>;
  lead_routing_rules?: InputMaybe<RuleCreateNestedManyWithoutOrganizationInput>;
  LeadCustomObjectRowAssociation?: InputMaybe<LeadCustomObjectRowAssociationCreateNestedManyWithoutOrganizationInput>;
  LeadFieldMultiplier?: InputMaybe<LeadFieldMultiplierCreateNestedManyWithoutOrganizationInput>;
  LeadFieldMultiplierCustom?: InputMaybe<LeadFieldMultiplierCustomCreateNestedManyWithoutOrganizationInput>;
  leads?: InputMaybe<LeadCreateNestedManyWithoutOrganizationInput>;
  leadsource_options?: InputMaybe<LeadSourceOptionCreateNestedManyWithoutOrganizationInput>;
  LeadValue?: InputMaybe<LeadValueCreateNestedManyWithoutOrganizationInput>;
  LifeTimeValueMultiplier?: InputMaybe<LifeTimeValueMultiplierCreateNestedManyWithoutOrganizationInput>;
  LiveTransferSetting?: InputMaybe<LiveTransferSettingCreateNestedOneWithoutOrganizationInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusCreateNestedManyWithoutOrganizationInput>;
  ltv_multiplier_custom_fields?: InputMaybe<LifeTimeValueMultiplierCustomCreateNestedManyWithoutOrganizationInput>;
  made_contact_rule?: InputMaybe<Scalars['Int']['input']>;
  make_sale_address_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_business_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_city_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_country_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_first_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_industry_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_last_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_lead_source_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_primary_email_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_primary_phone_number_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_state_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_sub_industry_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_zip_required?: InputMaybe<Scalars['Boolean']['input']>;
  maxium_day_lag_set_to_schedule?: InputMaybe<Scalars['Int']['input']>;
  mrr?: InputMaybe<Scalars['Int']['input']>;
  mrr_label?: InputMaybe<Scalars['String']['input']>;
  mrr_required_on_sale?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  NoteItems?: InputMaybe<NoteItemCreateNestedManyWithoutOrganizationInput>;
  Notifications?: InputMaybe<NotificationCreateNestedManyWithoutOrganizationInput>;
  onboardComplete?: InputMaybe<Scalars['Boolean']['input']>;
  Opportunity?: InputMaybe<SfOpportunityCreateNestedManyWithoutOrganizationInput>;
  organization_holidays?: InputMaybe<OrganizationHolidayCreateNestedManyWithoutOrganizationInput>;
  organization_hours?: InputMaybe<OrganizationHourCreateNestedManyWithoutOrganizationInput>;
  OrganizationPaymentSetting?: InputMaybe<OrganizationPaymentSettingsCreateNestedOneWithoutOrganizationInput>;
  OrganizationPaymentSettings?: InputMaybe<OrganizationPaymentSettingsCreateNestedOneWithoutOrganizationsInput>;
  orgCalendarInviteMetadata?: InputMaybe<OrgCalendarInviteMetadataCreateNestedManyWithoutOrganizationInput>;
  OrgDefaultPipelinePerecentValue?: InputMaybe<OrgDefaultPipelinePerecentValueCreateNestedOneWithoutOrganizationInput>;
  OrgHubSpotSync?: InputMaybe<OrgHubSpotSyncCreateNestedOneWithoutOrganizationInput>;
  OrgNoEventDate?: InputMaybe<OrgNoEventDateCreateNestedManyWithoutOrganizationInput>;
  OrgSalesforceSync?: InputMaybe<OrgSalesforceSyncCreateNestedOneWithoutOrganizationInput>;
  OrgTimeslotAvailability?: InputMaybe<OrgTimeslotAvailabilityCreateNestedManyWithoutOrganizationInput>;
  outbound_close_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_dm_connect_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_lt_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_revenue_value_of_sale?: InputMaybe<Scalars['Float']['input']>;
  outbound_scheduled_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_set_rate?: InputMaybe<Scalars['Float']['input']>;
  PandadocData?: InputMaybe<PandadocDataCreateNestedOneWithoutOrganizationInput>;
  PandaDocTemplate?: InputMaybe<PandaDocTemplateCreateNestedManyWithoutOrganizationInput>;
  participant_jitter_buffer_size?: InputMaybe<JitterBufferSize>;
  preferred_goal_setting?: InputMaybe<Goalsetting>;
  prepayment?: InputMaybe<Scalars['Boolean']['input']>;
  ProductChangeLog?: InputMaybe<ProductChangeLogCreateNestedManyWithoutOrganizationInput>;
  products?: InputMaybe<ProductCreateNestedManyWithoutOrganizationInput>;
  re_cold_call_hour_delay?: InputMaybe<Scalars['Float']['input']>;
  recordingStatus?: InputMaybe<RecordingStatus>;
  release_lead_resting?: InputMaybe<Scalars['Int']['input']>;
  rep_claim_limit?: InputMaybe<Scalars['Int']['input']>;
  rep_lead_allotment?: InputMaybe<Scalars['Int']['input']>;
  rep_level_call_transfer?: InputMaybe<Scalars['Boolean']['input']>;
  reps_can_bulk_unassign?: InputMaybe<Scalars['Boolean']['input']>;
  reps_can_view_all_notes?: InputMaybe<Scalars['Boolean']['input']>;
  require_sale_notes?: InputMaybe<Scalars['Boolean']['input']>;
  resting_after_skipped_CC?: InputMaybe<Scalars['Int']['input']>;
  resting_period?: InputMaybe<Scalars['Int']['input']>;
  resting_period_after_unsuccessful_cold_call?: InputMaybe<Scalars['Float']['input']>;
  resting_period_after_unsuccessful_demo?: InputMaybe<Scalars['Float']['input']>;
  revenue_plan?: InputMaybe<RevenuePlanItemCreateNestedManyWithoutOrganizationInput>;
  Routings?: InputMaybe<RoutingCreateNestedManyWithoutOrganizationInput>;
  sale_field_options?: InputMaybe<SaleFieldOptionCreateNestedManyWithoutOrganizationInput>;
  sale_notification_email_list?: InputMaybe<OrganizationCreatesale_Notification_Email_ListInput>;
  SaleConfig?: InputMaybe<SaleConfigCreateNestedOneWithoutOrganizationInput>;
  SaleConfigPage?: InputMaybe<SaleConfigPageCreateNestedManyWithoutOrganizationInput>;
  SaleConfigSection?: InputMaybe<SaleConfigSectionCreateNestedManyWithoutOrganizationInput>;
  SaleCycles?: InputMaybe<SaleCycleCreateNestedManyWithoutOrganizationInput>;
  SaleItems?: InputMaybe<SaleItemCreateNestedManyWithoutOrganizationInput>;
  sales?: InputMaybe<SaleCreateNestedManyWithoutOrganizationInput>;
  salesforce_domain?: InputMaybe<Scalars['String']['input']>;
  salesforce_last_sync_date?: InputMaybe<Scalars['DateTime']['input']>;
  SalesForceColumn?: InputMaybe<SalesForceColumnCreateNestedManyWithoutOrganizationInput>;
  salesForceData?: InputMaybe<SalesForceDataCreateNestedOneWithoutOrganizationInput>;
  ScheduleItems?: InputMaybe<ScheduleItemCreateNestedManyWithoutOrganizationInput>;
  self_sourcing_rule?: InputMaybe<Scalars['Int']['input']>;
  send_error_log_email?: InputMaybe<Scalars['Boolean']['input']>;
  send_lead_routing_email?: InputMaybe<Scalars['Boolean']['input']>;
  sendgrid_email_domain?: InputMaybe<Scalars['String']['input']>;
  sendgrid_email_domain_id?: InputMaybe<Scalars['Int']['input']>;
  sequence?: InputMaybe<SequenceCreateNestedManyWithoutOrganzationInput>;
  SequenceCriteriaConditions?: InputMaybe<SequenceCriteriaConditionCreateNestedManyWithoutOrganizationInput>;
  SequenceDashboardViews?: InputMaybe<SequenceDashboardViewCreateNestedManyWithoutOrganizationInput>;
  SequenceEntryCriterias?: InputMaybe<SequenceEntryCriteriaCreateNestedManyWithoutOrganizationInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutOrganizationInput>;
  SequenceSteps?: InputMaybe<SequenceStepCreateNestedManyWithoutOrganizationInput>;
  set_goal?: InputMaybe<Scalars['Float']['input']>;
  short_resting?: InputMaybe<Scalars['Int']['input']>;
  show_calendar_options?: InputMaybe<Scalars['Boolean']['input']>;
  show_contract_duration_sales?: InputMaybe<Scalars['Boolean']['input']>;
  show_lead_source_to_rep?: InputMaybe<Scalars['Boolean']['input']>;
  show_nocontact_button?: InputMaybe<Scalars['Boolean']['input']>;
  show_payment_terms_sales?: InputMaybe<Scalars['Boolean']['input']>;
  show_rep_all_leads?: InputMaybe<Scalars['Boolean']['input']>;
  show_rep_my_leads?: InputMaybe<Scalars['Boolean']['input']>;
  show_system_view?: InputMaybe<Scalars['Boolean']['input']>;
  sites?: InputMaybe<SiteCreateNestedManyWithoutOrganizationInput>;
  sms_opt_in_dialog?: InputMaybe<Scalars['String']['input']>;
  SMSItems?: InputMaybe<SmsItemCreateNestedManyWithoutOrganizationInput>;
  SnoozeTrackings?: InputMaybe<SnoozeTrackingCreateNestedManyWithoutOrganizationInput>;
  StepActionLogs?: InputMaybe<StepActionLogCreateNestedManyWithoutOrganizationInput>;
  StepActions?: InputMaybe<StepActionCreateNestedManyWithoutOrganizationInput>;
  StepConditions?: InputMaybe<StepConditionCreateNestedManyWithoutOrganizationInput>;
  teams?: InputMaybe<TeamCreateNestedManyWithoutOrganizationInput>;
  Template?: InputMaybe<TemplateCreateNestedManyWithoutOrganizationInput>;
  TemplateVersions?: InputMaybe<TemplateVersionCreateNestedManyWithoutOrganizationInput>;
  timekit_id?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  transfer_timeout?: InputMaybe<Scalars['Int']['input']>;
  TransferAttempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutOrganizationInput>;
  twilio_numbers?: InputMaybe<TwilioNumberCreateNestedManyWithoutOrganizationInput>;
  TwilioFeedbacks?: InputMaybe<TwilioFeedbackCreateNestedManyWithoutOrganizationInput>;
  TwilioInfo?: InputMaybe<TwilioInfoCreateNestedOneWithoutOrganzationInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  use_revenue_planning?: InputMaybe<Scalars['Boolean']['input']>;
  UserCustomFieldFilters?: InputMaybe<UserCustomFieldFilterCreateNestedManyWithoutOrganizationInput>;
  users?: InputMaybe<UserCreateNestedManyWithoutOrganizationInput>;
  win_rep_day_goal?: InputMaybe<Scalars['Float']['input']>;
};

export type OrganizationCreateWithoutDashboardLeadFiltersInput = {
  ActivityMonitorMultiplier?: InputMaybe<ActivityMonitorMultiplierCreateNestedManyWithoutOrganizationInput>;
  algoFactor?: InputMaybe<AlgoFactorCreateNestedManyWithoutOrganizationInput>;
  allow_calendar_invite?: InputMaybe<Scalars['Boolean']['input']>;
  allow_remove_step_action?: InputMaybe<Scalars['Boolean']['input']>;
  allow_snooze_step_action?: InputMaybe<Scalars['Boolean']['input']>;
  always_record?: InputMaybe<Scalars['Boolean']['input']>;
  arv_initial_held_demos_generated_by_sdr_weight?: InputMaybe<Scalars['Float']['input']>;
  AssociationHistories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutOrganizationInput>;
  auto_call_interval?: InputMaybe<Scalars['Int']['input']>;
  auto_dial?: InputMaybe<Scalars['Boolean']['input']>;
  automated_campaigns?: InputMaybe<AutomatedCampaignCreateNestedManyWithoutOrganizationInput>;
  automatic_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  AutoTransferSelections?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutOrganizationInput>;
  Availablities?: InputMaybe<AvailabilityCreateNestedManyWithoutOrganizationInput>;
  AverageOrderValue?: InputMaybe<AverageOrderValueCreateNestedManyWithoutOrganizationInput>;
  best_action_ever_multiplier?: InputMaybe<Scalars['Float']['input']>;
  best_metrics?: InputMaybe<BestMetricRecordCreateNestedManyWithoutOrganizationInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptCreateNestedManyWithoutOrganizationInput>;
  callMeNows?: InputMaybe<CallMeNowCreateNestedManyWithoutOrganizationInput>;
  CallMultiplier?: InputMaybe<CallMultiplierCreateNestedManyWithoutOrganizationInput>;
  CallSharings?: InputMaybe<CallSharingCreateNestedManyWithoutOrganizationInput>;
  can_edit_sdr_transfer_rules?: InputMaybe<Scalars['Boolean']['input']>;
  claim_rule?: InputMaybe<Scalars['Int']['input']>;
  concession?: InputMaybe<ConcessionCreateNestedManyWithoutOrganizationInput>;
  conference_region?: InputMaybe<ConferenceRegion>;
  Conferences?: InputMaybe<ConferenceCreateNestedManyWithoutOrganizationInput>;
  ContactRequests?: InputMaybe<ContactRequestCreateNestedManyWithoutOrganizationInput>;
  Contacts?: InputMaybe<ContactCreateNestedManyWithoutOrganizationInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  custom_fields?: InputMaybe<CustomFieldCreateNestedManyWithoutOrganizationInput>;
  CustomObject?: InputMaybe<CustomObjectCreateNestedManyWithoutOrganizationInput>;
  CustomObjectField?: InputMaybe<CustomObjectFieldCreateNestedManyWithoutOrganizationInput>;
  CustomObjectFieldForeignKey?: InputMaybe<CustomObjectFieldForeignKeyCreateNestedManyWithoutOrganizationInput>;
  CustomObjectRecord?: InputMaybe<CustomObjectRecordCreateNestedManyWithoutOrganizationInput>;
  CustomObjectRow?: InputMaybe<CustomObjectRowCreateNestedManyWithoutOrganizationInput>;
  CustomQueueItems?: InputMaybe<CustomQueueItemCreateNestedManyWithoutOrganizationInput>;
  DashboardColumnOptions?: InputMaybe<DashboardColumnOptionCreateNestedManyWithoutOrganizationInput>;
  DashboardRepFilters?: InputMaybe<DashboardRepFilterCreateNestedManyWithoutOrganizationInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewCreateNestedManyWithoutOrganizationInput>;
  day_to_close?: InputMaybe<Scalars['Int']['input']>;
  default_demo_time_duration?: InputMaybe<Scalars['Int']['input']>;
  demo_held_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_not_held_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_not_held_call_back_short_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_prep_time_minutes?: InputMaybe<Scalars['Int']['input']>;
  dev_account?: InputMaybe<Scalars['Boolean']['input']>;
  dial_rule?: InputMaybe<Scalars['Int']['input']>;
  DispositionMultiplier?: InputMaybe<DispositionMultiplierCreateNestedManyWithoutOrganizationInput>;
  dispositions?: InputMaybe<DispositionCreateNestedManyWithoutOrganizationInput>;
  double_booking?: InputMaybe<Scalars['Boolean']['input']>;
  dynamic_date_operators?: InputMaybe<Scalars['Boolean']['input']>;
  edge_servers?: InputMaybe<OrganizationCreateedge_ServersInput>;
  email_conflict_notification_email_list?: InputMaybe<OrganizationCreateemail_Conflict_Notification_Email_ListInput>;
  EmailItems?: InputMaybe<EmailItemCreateNestedManyWithoutOrganizationInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordCreateNestedManyWithoutOrganizationInput>;
  EmailThreads?: InputMaybe<EmailThreadCreateNestedManyWithoutOrganizationInput>;
  enable_modified_metrics?: InputMaybe<Scalars['Boolean']['input']>;
  enable_pay_period_view?: InputMaybe<Scalars['Boolean']['input']>;
  enable_time_constraint?: InputMaybe<Scalars['Boolean']['input']>;
  error_log_email_address?: InputMaybe<OrganizationCreateerror_Log_Email_AddressInput>;
  estimated_average_num_of_weekly_overbooked_demo_slots?: InputMaybe<Scalars['Int']['input']>;
  FolderPermissions?: InputMaybe<FolderPermissionCreateNestedManyWithoutOrganizationInput>;
  Folders?: InputMaybe<FolderCreateNestedManyWithoutOrganizationInput>;
  FutureBookingSetting?: InputMaybe<FutureBookingSettingCreateNestedOneWithoutOrganizationInput>;
  GlobalTransferRule?: InputMaybe<GlobalTransferRuleCreateNestedManyWithoutOrganizationInput>;
  held_demos_per_cc_connect?: InputMaybe<Scalars['Float']['input']>;
  helpful_links?: InputMaybe<OrganizationCreatehelpful_LinksInput>;
  HingePointMultiplier?: InputMaybe<HingePointMultiplierCreateNestedManyWithoutOrganizationInput>;
  hold_goal?: InputMaybe<Scalars['Float']['input']>;
  hubspot_credential?: InputMaybe<HubSpotCredentialCreateNestedOneWithoutOrganizationInput>;
  hubspot_last_sync_date?: InputMaybe<Scalars['DateTime']['input']>;
  hubspot_white_list?: InputMaybe<HubSpotWhitelistCreateNestedManyWithoutOrganizationInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  inbound_close_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_dm_connect_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_lt_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_revenue_value_of_sale?: InputMaybe<Scalars['Float']['input']>;
  inbound_scheduled_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_set_rate?: InputMaybe<Scalars['Float']['input']>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventCreateNestedManyWithoutOrganizationInput>;
  InboundConciergeWidget?: InputMaybe<InboundConciergeWidgetCreateNestedManyWithoutOrganizationInput>;
  industry_options?: InputMaybe<IndustryOptionCreateNestedManyWithoutOrganizationInput>;
  initial_sdr_sets_reps_day?: InputMaybe<Scalars['Float']['input']>;
  integration_error_logs?: InputMaybe<IntegrationErrorLogCreateNestedManyWithoutOrganizationInput>;
  integration_mappings?: InputMaybe<IntegrationMappingCreateNestedManyWithoutOrganizationInput>;
  IntegrationMappingCustomObject?: InputMaybe<IntegrationMappingCustomObjectCreateNestedManyWithoutOrganizationInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogCreateNestedManyWithoutOrganizationInput>;
  IntegrationReadOnlyObject?: InputMaybe<IntegrationReadOnlyObjectCreateNestedManyWithoutOrganizationInput>;
  international?: InputMaybe<Scalars['Boolean']['input']>;
  is_email_domain_verified?: InputMaybe<Scalars['Boolean']['input']>;
  is_salesforce_sandbox?: InputMaybe<Scalars['Boolean']['input']>;
  last_lead_value_compute_time?: InputMaybe<Scalars['DateTime']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutOrganizationInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryCreateNestedManyWithoutOrganizationInput>;
  lead_intent?: InputMaybe<LeadIntentCreateNestedManyWithoutOrganizationInput>;
  lead_release_same_rep_delay_rule?: InputMaybe<Scalars['Int']['input']>;
  lead_routing_email_list?: InputMaybe<OrganizationCreatelead_Routing_Email_ListInput>;
  lead_routing_rules?: InputMaybe<RuleCreateNestedManyWithoutOrganizationInput>;
  LeadCustomObjectRowAssociation?: InputMaybe<LeadCustomObjectRowAssociationCreateNestedManyWithoutOrganizationInput>;
  LeadFieldMultiplier?: InputMaybe<LeadFieldMultiplierCreateNestedManyWithoutOrganizationInput>;
  LeadFieldMultiplierCustom?: InputMaybe<LeadFieldMultiplierCustomCreateNestedManyWithoutOrganizationInput>;
  leads?: InputMaybe<LeadCreateNestedManyWithoutOrganizationInput>;
  leadsource_options?: InputMaybe<LeadSourceOptionCreateNestedManyWithoutOrganizationInput>;
  LeadValue?: InputMaybe<LeadValueCreateNestedManyWithoutOrganizationInput>;
  LifeTimeValueMultiplier?: InputMaybe<LifeTimeValueMultiplierCreateNestedManyWithoutOrganizationInput>;
  LiveTransferSetting?: InputMaybe<LiveTransferSettingCreateNestedOneWithoutOrganizationInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusCreateNestedManyWithoutOrganizationInput>;
  ltv_multiplier_custom_fields?: InputMaybe<LifeTimeValueMultiplierCustomCreateNestedManyWithoutOrganizationInput>;
  made_contact_rule?: InputMaybe<Scalars['Int']['input']>;
  make_sale_address_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_business_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_city_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_country_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_first_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_industry_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_last_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_lead_source_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_primary_email_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_primary_phone_number_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_state_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_sub_industry_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_zip_required?: InputMaybe<Scalars['Boolean']['input']>;
  maxium_day_lag_set_to_schedule?: InputMaybe<Scalars['Int']['input']>;
  mrr?: InputMaybe<Scalars['Int']['input']>;
  mrr_label?: InputMaybe<Scalars['String']['input']>;
  mrr_required_on_sale?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  NoteItems?: InputMaybe<NoteItemCreateNestedManyWithoutOrganizationInput>;
  Notifications?: InputMaybe<NotificationCreateNestedManyWithoutOrganizationInput>;
  onboardComplete?: InputMaybe<Scalars['Boolean']['input']>;
  Opportunity?: InputMaybe<SfOpportunityCreateNestedManyWithoutOrganizationInput>;
  organization_holidays?: InputMaybe<OrganizationHolidayCreateNestedManyWithoutOrganizationInput>;
  organization_hours?: InputMaybe<OrganizationHourCreateNestedManyWithoutOrganizationInput>;
  OrganizationPaymentSetting?: InputMaybe<OrganizationPaymentSettingsCreateNestedOneWithoutOrganizationInput>;
  OrganizationPaymentSettings?: InputMaybe<OrganizationPaymentSettingsCreateNestedOneWithoutOrganizationsInput>;
  orgCalendarInviteMetadata?: InputMaybe<OrgCalendarInviteMetadataCreateNestedManyWithoutOrganizationInput>;
  OrgDefaultPipelinePerecentValue?: InputMaybe<OrgDefaultPipelinePerecentValueCreateNestedOneWithoutOrganizationInput>;
  OrgHubSpotSync?: InputMaybe<OrgHubSpotSyncCreateNestedOneWithoutOrganizationInput>;
  OrgNoEventDate?: InputMaybe<OrgNoEventDateCreateNestedManyWithoutOrganizationInput>;
  OrgSalesforceSync?: InputMaybe<OrgSalesforceSyncCreateNestedOneWithoutOrganizationInput>;
  OrgTimeslotAvailability?: InputMaybe<OrgTimeslotAvailabilityCreateNestedManyWithoutOrganizationInput>;
  outbound_close_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_dm_connect_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_lt_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_revenue_value_of_sale?: InputMaybe<Scalars['Float']['input']>;
  outbound_scheduled_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_set_rate?: InputMaybe<Scalars['Float']['input']>;
  PandadocData?: InputMaybe<PandadocDataCreateNestedOneWithoutOrganizationInput>;
  PandaDocTemplate?: InputMaybe<PandaDocTemplateCreateNestedManyWithoutOrganizationInput>;
  participant_jitter_buffer_size?: InputMaybe<JitterBufferSize>;
  preferred_goal_setting?: InputMaybe<Goalsetting>;
  prepayment?: InputMaybe<Scalars['Boolean']['input']>;
  ProductChangeLog?: InputMaybe<ProductChangeLogCreateNestedManyWithoutOrganizationInput>;
  products?: InputMaybe<ProductCreateNestedManyWithoutOrganizationInput>;
  re_cold_call_hour_delay?: InputMaybe<Scalars['Float']['input']>;
  recordingStatus?: InputMaybe<RecordingStatus>;
  release_lead_resting?: InputMaybe<Scalars['Int']['input']>;
  rep_claim_limit?: InputMaybe<Scalars['Int']['input']>;
  rep_lead_allotment?: InputMaybe<Scalars['Int']['input']>;
  rep_level_call_transfer?: InputMaybe<Scalars['Boolean']['input']>;
  reps_can_bulk_unassign?: InputMaybe<Scalars['Boolean']['input']>;
  reps_can_view_all_notes?: InputMaybe<Scalars['Boolean']['input']>;
  require_sale_notes?: InputMaybe<Scalars['Boolean']['input']>;
  resting_after_skipped_CC?: InputMaybe<Scalars['Int']['input']>;
  resting_period?: InputMaybe<Scalars['Int']['input']>;
  resting_period_after_unsuccessful_cold_call?: InputMaybe<Scalars['Float']['input']>;
  resting_period_after_unsuccessful_demo?: InputMaybe<Scalars['Float']['input']>;
  revenue_plan?: InputMaybe<RevenuePlanItemCreateNestedManyWithoutOrganizationInput>;
  Routings?: InputMaybe<RoutingCreateNestedManyWithoutOrganizationInput>;
  sale_field_options?: InputMaybe<SaleFieldOptionCreateNestedManyWithoutOrganizationInput>;
  sale_notification_email_list?: InputMaybe<OrganizationCreatesale_Notification_Email_ListInput>;
  SaleConfig?: InputMaybe<SaleConfigCreateNestedOneWithoutOrganizationInput>;
  SaleConfigPage?: InputMaybe<SaleConfigPageCreateNestedManyWithoutOrganizationInput>;
  SaleConfigSection?: InputMaybe<SaleConfigSectionCreateNestedManyWithoutOrganizationInput>;
  SaleCycles?: InputMaybe<SaleCycleCreateNestedManyWithoutOrganizationInput>;
  SaleItems?: InputMaybe<SaleItemCreateNestedManyWithoutOrganizationInput>;
  sales?: InputMaybe<SaleCreateNestedManyWithoutOrganizationInput>;
  salesforce_domain?: InputMaybe<Scalars['String']['input']>;
  salesforce_last_sync_date?: InputMaybe<Scalars['DateTime']['input']>;
  SalesForceColumn?: InputMaybe<SalesForceColumnCreateNestedManyWithoutOrganizationInput>;
  salesForceData?: InputMaybe<SalesForceDataCreateNestedOneWithoutOrganizationInput>;
  ScheduleItems?: InputMaybe<ScheduleItemCreateNestedManyWithoutOrganizationInput>;
  self_sourcing_rule?: InputMaybe<Scalars['Int']['input']>;
  send_error_log_email?: InputMaybe<Scalars['Boolean']['input']>;
  send_lead_routing_email?: InputMaybe<Scalars['Boolean']['input']>;
  sendgrid_email_domain?: InputMaybe<Scalars['String']['input']>;
  sendgrid_email_domain_id?: InputMaybe<Scalars['Int']['input']>;
  sequence?: InputMaybe<SequenceCreateNestedManyWithoutOrganzationInput>;
  SequenceCriteriaConditions?: InputMaybe<SequenceCriteriaConditionCreateNestedManyWithoutOrganizationInput>;
  SequenceDashboardViews?: InputMaybe<SequenceDashboardViewCreateNestedManyWithoutOrganizationInput>;
  SequenceEntryCriterias?: InputMaybe<SequenceEntryCriteriaCreateNestedManyWithoutOrganizationInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutOrganizationInput>;
  SequenceSteps?: InputMaybe<SequenceStepCreateNestedManyWithoutOrganizationInput>;
  set_goal?: InputMaybe<Scalars['Float']['input']>;
  short_resting?: InputMaybe<Scalars['Int']['input']>;
  show_calendar_options?: InputMaybe<Scalars['Boolean']['input']>;
  show_contract_duration_sales?: InputMaybe<Scalars['Boolean']['input']>;
  show_lead_source_to_rep?: InputMaybe<Scalars['Boolean']['input']>;
  show_nocontact_button?: InputMaybe<Scalars['Boolean']['input']>;
  show_payment_terms_sales?: InputMaybe<Scalars['Boolean']['input']>;
  show_rep_all_leads?: InputMaybe<Scalars['Boolean']['input']>;
  show_rep_my_leads?: InputMaybe<Scalars['Boolean']['input']>;
  show_system_view?: InputMaybe<Scalars['Boolean']['input']>;
  sites?: InputMaybe<SiteCreateNestedManyWithoutOrganizationInput>;
  sms_opt_in_dialog?: InputMaybe<Scalars['String']['input']>;
  SMSItems?: InputMaybe<SmsItemCreateNestedManyWithoutOrganizationInput>;
  SnoozeTrackings?: InputMaybe<SnoozeTrackingCreateNestedManyWithoutOrganizationInput>;
  StepActionLogs?: InputMaybe<StepActionLogCreateNestedManyWithoutOrganizationInput>;
  StepActions?: InputMaybe<StepActionCreateNestedManyWithoutOrganizationInput>;
  StepConditions?: InputMaybe<StepConditionCreateNestedManyWithoutOrganizationInput>;
  teams?: InputMaybe<TeamCreateNestedManyWithoutOrganizationInput>;
  Template?: InputMaybe<TemplateCreateNestedManyWithoutOrganizationInput>;
  TemplateVersions?: InputMaybe<TemplateVersionCreateNestedManyWithoutOrganizationInput>;
  timekit_id?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  transfer_timeout?: InputMaybe<Scalars['Int']['input']>;
  TransferAttempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutOrganizationInput>;
  twilio_numbers?: InputMaybe<TwilioNumberCreateNestedManyWithoutOrganizationInput>;
  TwilioFeedbacks?: InputMaybe<TwilioFeedbackCreateNestedManyWithoutOrganizationInput>;
  TwilioInfo?: InputMaybe<TwilioInfoCreateNestedOneWithoutOrganzationInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  use_revenue_planning?: InputMaybe<Scalars['Boolean']['input']>;
  UserCustomFieldFilters?: InputMaybe<UserCustomFieldFilterCreateNestedManyWithoutOrganizationInput>;
  users?: InputMaybe<UserCreateNestedManyWithoutOrganizationInput>;
  win_rep_day_goal?: InputMaybe<Scalars['Float']['input']>;
};

export type OrganizationCreateWithoutDashboardRepFiltersInput = {
  ActivityMonitorMultiplier?: InputMaybe<ActivityMonitorMultiplierCreateNestedManyWithoutOrganizationInput>;
  algoFactor?: InputMaybe<AlgoFactorCreateNestedManyWithoutOrganizationInput>;
  allow_calendar_invite?: InputMaybe<Scalars['Boolean']['input']>;
  allow_remove_step_action?: InputMaybe<Scalars['Boolean']['input']>;
  allow_snooze_step_action?: InputMaybe<Scalars['Boolean']['input']>;
  always_record?: InputMaybe<Scalars['Boolean']['input']>;
  arv_initial_held_demos_generated_by_sdr_weight?: InputMaybe<Scalars['Float']['input']>;
  AssociationHistories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutOrganizationInput>;
  auto_call_interval?: InputMaybe<Scalars['Int']['input']>;
  auto_dial?: InputMaybe<Scalars['Boolean']['input']>;
  automated_campaigns?: InputMaybe<AutomatedCampaignCreateNestedManyWithoutOrganizationInput>;
  automatic_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  AutoTransferSelections?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutOrganizationInput>;
  Availablities?: InputMaybe<AvailabilityCreateNestedManyWithoutOrganizationInput>;
  AverageOrderValue?: InputMaybe<AverageOrderValueCreateNestedManyWithoutOrganizationInput>;
  best_action_ever_multiplier?: InputMaybe<Scalars['Float']['input']>;
  best_metrics?: InputMaybe<BestMetricRecordCreateNestedManyWithoutOrganizationInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptCreateNestedManyWithoutOrganizationInput>;
  callMeNows?: InputMaybe<CallMeNowCreateNestedManyWithoutOrganizationInput>;
  CallMultiplier?: InputMaybe<CallMultiplierCreateNestedManyWithoutOrganizationInput>;
  CallSharings?: InputMaybe<CallSharingCreateNestedManyWithoutOrganizationInput>;
  can_edit_sdr_transfer_rules?: InputMaybe<Scalars['Boolean']['input']>;
  claim_rule?: InputMaybe<Scalars['Int']['input']>;
  concession?: InputMaybe<ConcessionCreateNestedManyWithoutOrganizationInput>;
  conference_region?: InputMaybe<ConferenceRegion>;
  Conferences?: InputMaybe<ConferenceCreateNestedManyWithoutOrganizationInput>;
  ContactRequests?: InputMaybe<ContactRequestCreateNestedManyWithoutOrganizationInput>;
  Contacts?: InputMaybe<ContactCreateNestedManyWithoutOrganizationInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  custom_fields?: InputMaybe<CustomFieldCreateNestedManyWithoutOrganizationInput>;
  CustomObject?: InputMaybe<CustomObjectCreateNestedManyWithoutOrganizationInput>;
  CustomObjectField?: InputMaybe<CustomObjectFieldCreateNestedManyWithoutOrganizationInput>;
  CustomObjectFieldForeignKey?: InputMaybe<CustomObjectFieldForeignKeyCreateNestedManyWithoutOrganizationInput>;
  CustomObjectRecord?: InputMaybe<CustomObjectRecordCreateNestedManyWithoutOrganizationInput>;
  CustomObjectRow?: InputMaybe<CustomObjectRowCreateNestedManyWithoutOrganizationInput>;
  CustomQueueItems?: InputMaybe<CustomQueueItemCreateNestedManyWithoutOrganizationInput>;
  DashboardColumnOptions?: InputMaybe<DashboardColumnOptionCreateNestedManyWithoutOrganizationInput>;
  DashboardLeadFilters?: InputMaybe<DashboardLeadFilterCreateNestedManyWithoutOrganizationInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewCreateNestedManyWithoutOrganizationInput>;
  day_to_close?: InputMaybe<Scalars['Int']['input']>;
  default_demo_time_duration?: InputMaybe<Scalars['Int']['input']>;
  demo_held_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_not_held_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_not_held_call_back_short_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_prep_time_minutes?: InputMaybe<Scalars['Int']['input']>;
  dev_account?: InputMaybe<Scalars['Boolean']['input']>;
  dial_rule?: InputMaybe<Scalars['Int']['input']>;
  DispositionMultiplier?: InputMaybe<DispositionMultiplierCreateNestedManyWithoutOrganizationInput>;
  dispositions?: InputMaybe<DispositionCreateNestedManyWithoutOrganizationInput>;
  double_booking?: InputMaybe<Scalars['Boolean']['input']>;
  dynamic_date_operators?: InputMaybe<Scalars['Boolean']['input']>;
  edge_servers?: InputMaybe<OrganizationCreateedge_ServersInput>;
  email_conflict_notification_email_list?: InputMaybe<OrganizationCreateemail_Conflict_Notification_Email_ListInput>;
  EmailItems?: InputMaybe<EmailItemCreateNestedManyWithoutOrganizationInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordCreateNestedManyWithoutOrganizationInput>;
  EmailThreads?: InputMaybe<EmailThreadCreateNestedManyWithoutOrganizationInput>;
  enable_modified_metrics?: InputMaybe<Scalars['Boolean']['input']>;
  enable_pay_period_view?: InputMaybe<Scalars['Boolean']['input']>;
  enable_time_constraint?: InputMaybe<Scalars['Boolean']['input']>;
  error_log_email_address?: InputMaybe<OrganizationCreateerror_Log_Email_AddressInput>;
  estimated_average_num_of_weekly_overbooked_demo_slots?: InputMaybe<Scalars['Int']['input']>;
  FolderPermissions?: InputMaybe<FolderPermissionCreateNestedManyWithoutOrganizationInput>;
  Folders?: InputMaybe<FolderCreateNestedManyWithoutOrganizationInput>;
  FutureBookingSetting?: InputMaybe<FutureBookingSettingCreateNestedOneWithoutOrganizationInput>;
  GlobalTransferRule?: InputMaybe<GlobalTransferRuleCreateNestedManyWithoutOrganizationInput>;
  held_demos_per_cc_connect?: InputMaybe<Scalars['Float']['input']>;
  helpful_links?: InputMaybe<OrganizationCreatehelpful_LinksInput>;
  HingePointMultiplier?: InputMaybe<HingePointMultiplierCreateNestedManyWithoutOrganizationInput>;
  hold_goal?: InputMaybe<Scalars['Float']['input']>;
  hubspot_credential?: InputMaybe<HubSpotCredentialCreateNestedOneWithoutOrganizationInput>;
  hubspot_last_sync_date?: InputMaybe<Scalars['DateTime']['input']>;
  hubspot_white_list?: InputMaybe<HubSpotWhitelistCreateNestedManyWithoutOrganizationInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  inbound_close_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_dm_connect_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_lt_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_revenue_value_of_sale?: InputMaybe<Scalars['Float']['input']>;
  inbound_scheduled_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_set_rate?: InputMaybe<Scalars['Float']['input']>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventCreateNestedManyWithoutOrganizationInput>;
  InboundConciergeWidget?: InputMaybe<InboundConciergeWidgetCreateNestedManyWithoutOrganizationInput>;
  industry_options?: InputMaybe<IndustryOptionCreateNestedManyWithoutOrganizationInput>;
  initial_sdr_sets_reps_day?: InputMaybe<Scalars['Float']['input']>;
  integration_error_logs?: InputMaybe<IntegrationErrorLogCreateNestedManyWithoutOrganizationInput>;
  integration_mappings?: InputMaybe<IntegrationMappingCreateNestedManyWithoutOrganizationInput>;
  IntegrationMappingCustomObject?: InputMaybe<IntegrationMappingCustomObjectCreateNestedManyWithoutOrganizationInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogCreateNestedManyWithoutOrganizationInput>;
  IntegrationReadOnlyObject?: InputMaybe<IntegrationReadOnlyObjectCreateNestedManyWithoutOrganizationInput>;
  international?: InputMaybe<Scalars['Boolean']['input']>;
  is_email_domain_verified?: InputMaybe<Scalars['Boolean']['input']>;
  is_salesforce_sandbox?: InputMaybe<Scalars['Boolean']['input']>;
  last_lead_value_compute_time?: InputMaybe<Scalars['DateTime']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutOrganizationInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryCreateNestedManyWithoutOrganizationInput>;
  lead_intent?: InputMaybe<LeadIntentCreateNestedManyWithoutOrganizationInput>;
  lead_release_same_rep_delay_rule?: InputMaybe<Scalars['Int']['input']>;
  lead_routing_email_list?: InputMaybe<OrganizationCreatelead_Routing_Email_ListInput>;
  lead_routing_rules?: InputMaybe<RuleCreateNestedManyWithoutOrganizationInput>;
  LeadCustomObjectRowAssociation?: InputMaybe<LeadCustomObjectRowAssociationCreateNestedManyWithoutOrganizationInput>;
  LeadFieldMultiplier?: InputMaybe<LeadFieldMultiplierCreateNestedManyWithoutOrganizationInput>;
  LeadFieldMultiplierCustom?: InputMaybe<LeadFieldMultiplierCustomCreateNestedManyWithoutOrganizationInput>;
  leads?: InputMaybe<LeadCreateNestedManyWithoutOrganizationInput>;
  leadsource_options?: InputMaybe<LeadSourceOptionCreateNestedManyWithoutOrganizationInput>;
  LeadValue?: InputMaybe<LeadValueCreateNestedManyWithoutOrganizationInput>;
  LifeTimeValueMultiplier?: InputMaybe<LifeTimeValueMultiplierCreateNestedManyWithoutOrganizationInput>;
  LiveTransferSetting?: InputMaybe<LiveTransferSettingCreateNestedOneWithoutOrganizationInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusCreateNestedManyWithoutOrganizationInput>;
  ltv_multiplier_custom_fields?: InputMaybe<LifeTimeValueMultiplierCustomCreateNestedManyWithoutOrganizationInput>;
  made_contact_rule?: InputMaybe<Scalars['Int']['input']>;
  make_sale_address_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_business_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_city_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_country_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_first_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_industry_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_last_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_lead_source_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_primary_email_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_primary_phone_number_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_state_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_sub_industry_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_zip_required?: InputMaybe<Scalars['Boolean']['input']>;
  maxium_day_lag_set_to_schedule?: InputMaybe<Scalars['Int']['input']>;
  mrr?: InputMaybe<Scalars['Int']['input']>;
  mrr_label?: InputMaybe<Scalars['String']['input']>;
  mrr_required_on_sale?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  NoteItems?: InputMaybe<NoteItemCreateNestedManyWithoutOrganizationInput>;
  Notifications?: InputMaybe<NotificationCreateNestedManyWithoutOrganizationInput>;
  onboardComplete?: InputMaybe<Scalars['Boolean']['input']>;
  Opportunity?: InputMaybe<SfOpportunityCreateNestedManyWithoutOrganizationInput>;
  organization_holidays?: InputMaybe<OrganizationHolidayCreateNestedManyWithoutOrganizationInput>;
  organization_hours?: InputMaybe<OrganizationHourCreateNestedManyWithoutOrganizationInput>;
  OrganizationPaymentSetting?: InputMaybe<OrganizationPaymentSettingsCreateNestedOneWithoutOrganizationInput>;
  OrganizationPaymentSettings?: InputMaybe<OrganizationPaymentSettingsCreateNestedOneWithoutOrganizationsInput>;
  orgCalendarInviteMetadata?: InputMaybe<OrgCalendarInviteMetadataCreateNestedManyWithoutOrganizationInput>;
  OrgDefaultPipelinePerecentValue?: InputMaybe<OrgDefaultPipelinePerecentValueCreateNestedOneWithoutOrganizationInput>;
  OrgHubSpotSync?: InputMaybe<OrgHubSpotSyncCreateNestedOneWithoutOrganizationInput>;
  OrgNoEventDate?: InputMaybe<OrgNoEventDateCreateNestedManyWithoutOrganizationInput>;
  OrgSalesforceSync?: InputMaybe<OrgSalesforceSyncCreateNestedOneWithoutOrganizationInput>;
  OrgTimeslotAvailability?: InputMaybe<OrgTimeslotAvailabilityCreateNestedManyWithoutOrganizationInput>;
  outbound_close_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_dm_connect_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_lt_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_revenue_value_of_sale?: InputMaybe<Scalars['Float']['input']>;
  outbound_scheduled_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_set_rate?: InputMaybe<Scalars['Float']['input']>;
  PandadocData?: InputMaybe<PandadocDataCreateNestedOneWithoutOrganizationInput>;
  PandaDocTemplate?: InputMaybe<PandaDocTemplateCreateNestedManyWithoutOrganizationInput>;
  participant_jitter_buffer_size?: InputMaybe<JitterBufferSize>;
  preferred_goal_setting?: InputMaybe<Goalsetting>;
  prepayment?: InputMaybe<Scalars['Boolean']['input']>;
  ProductChangeLog?: InputMaybe<ProductChangeLogCreateNestedManyWithoutOrganizationInput>;
  products?: InputMaybe<ProductCreateNestedManyWithoutOrganizationInput>;
  re_cold_call_hour_delay?: InputMaybe<Scalars['Float']['input']>;
  recordingStatus?: InputMaybe<RecordingStatus>;
  release_lead_resting?: InputMaybe<Scalars['Int']['input']>;
  rep_claim_limit?: InputMaybe<Scalars['Int']['input']>;
  rep_lead_allotment?: InputMaybe<Scalars['Int']['input']>;
  rep_level_call_transfer?: InputMaybe<Scalars['Boolean']['input']>;
  reps_can_bulk_unassign?: InputMaybe<Scalars['Boolean']['input']>;
  reps_can_view_all_notes?: InputMaybe<Scalars['Boolean']['input']>;
  require_sale_notes?: InputMaybe<Scalars['Boolean']['input']>;
  resting_after_skipped_CC?: InputMaybe<Scalars['Int']['input']>;
  resting_period?: InputMaybe<Scalars['Int']['input']>;
  resting_period_after_unsuccessful_cold_call?: InputMaybe<Scalars['Float']['input']>;
  resting_period_after_unsuccessful_demo?: InputMaybe<Scalars['Float']['input']>;
  revenue_plan?: InputMaybe<RevenuePlanItemCreateNestedManyWithoutOrganizationInput>;
  Routings?: InputMaybe<RoutingCreateNestedManyWithoutOrganizationInput>;
  sale_field_options?: InputMaybe<SaleFieldOptionCreateNestedManyWithoutOrganizationInput>;
  sale_notification_email_list?: InputMaybe<OrganizationCreatesale_Notification_Email_ListInput>;
  SaleConfig?: InputMaybe<SaleConfigCreateNestedOneWithoutOrganizationInput>;
  SaleConfigPage?: InputMaybe<SaleConfigPageCreateNestedManyWithoutOrganizationInput>;
  SaleConfigSection?: InputMaybe<SaleConfigSectionCreateNestedManyWithoutOrganizationInput>;
  SaleCycles?: InputMaybe<SaleCycleCreateNestedManyWithoutOrganizationInput>;
  SaleItems?: InputMaybe<SaleItemCreateNestedManyWithoutOrganizationInput>;
  sales?: InputMaybe<SaleCreateNestedManyWithoutOrganizationInput>;
  salesforce_domain?: InputMaybe<Scalars['String']['input']>;
  salesforce_last_sync_date?: InputMaybe<Scalars['DateTime']['input']>;
  SalesForceColumn?: InputMaybe<SalesForceColumnCreateNestedManyWithoutOrganizationInput>;
  salesForceData?: InputMaybe<SalesForceDataCreateNestedOneWithoutOrganizationInput>;
  ScheduleItems?: InputMaybe<ScheduleItemCreateNestedManyWithoutOrganizationInput>;
  self_sourcing_rule?: InputMaybe<Scalars['Int']['input']>;
  send_error_log_email?: InputMaybe<Scalars['Boolean']['input']>;
  send_lead_routing_email?: InputMaybe<Scalars['Boolean']['input']>;
  sendgrid_email_domain?: InputMaybe<Scalars['String']['input']>;
  sendgrid_email_domain_id?: InputMaybe<Scalars['Int']['input']>;
  sequence?: InputMaybe<SequenceCreateNestedManyWithoutOrganzationInput>;
  SequenceCriteriaConditions?: InputMaybe<SequenceCriteriaConditionCreateNestedManyWithoutOrganizationInput>;
  SequenceDashboardViews?: InputMaybe<SequenceDashboardViewCreateNestedManyWithoutOrganizationInput>;
  SequenceEntryCriterias?: InputMaybe<SequenceEntryCriteriaCreateNestedManyWithoutOrganizationInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutOrganizationInput>;
  SequenceSteps?: InputMaybe<SequenceStepCreateNestedManyWithoutOrganizationInput>;
  set_goal?: InputMaybe<Scalars['Float']['input']>;
  short_resting?: InputMaybe<Scalars['Int']['input']>;
  show_calendar_options?: InputMaybe<Scalars['Boolean']['input']>;
  show_contract_duration_sales?: InputMaybe<Scalars['Boolean']['input']>;
  show_lead_source_to_rep?: InputMaybe<Scalars['Boolean']['input']>;
  show_nocontact_button?: InputMaybe<Scalars['Boolean']['input']>;
  show_payment_terms_sales?: InputMaybe<Scalars['Boolean']['input']>;
  show_rep_all_leads?: InputMaybe<Scalars['Boolean']['input']>;
  show_rep_my_leads?: InputMaybe<Scalars['Boolean']['input']>;
  show_system_view?: InputMaybe<Scalars['Boolean']['input']>;
  sites?: InputMaybe<SiteCreateNestedManyWithoutOrganizationInput>;
  sms_opt_in_dialog?: InputMaybe<Scalars['String']['input']>;
  SMSItems?: InputMaybe<SmsItemCreateNestedManyWithoutOrganizationInput>;
  SnoozeTrackings?: InputMaybe<SnoozeTrackingCreateNestedManyWithoutOrganizationInput>;
  StepActionLogs?: InputMaybe<StepActionLogCreateNestedManyWithoutOrganizationInput>;
  StepActions?: InputMaybe<StepActionCreateNestedManyWithoutOrganizationInput>;
  StepConditions?: InputMaybe<StepConditionCreateNestedManyWithoutOrganizationInput>;
  teams?: InputMaybe<TeamCreateNestedManyWithoutOrganizationInput>;
  Template?: InputMaybe<TemplateCreateNestedManyWithoutOrganizationInput>;
  TemplateVersions?: InputMaybe<TemplateVersionCreateNestedManyWithoutOrganizationInput>;
  timekit_id?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  transfer_timeout?: InputMaybe<Scalars['Int']['input']>;
  TransferAttempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutOrganizationInput>;
  twilio_numbers?: InputMaybe<TwilioNumberCreateNestedManyWithoutOrganizationInput>;
  TwilioFeedbacks?: InputMaybe<TwilioFeedbackCreateNestedManyWithoutOrganizationInput>;
  TwilioInfo?: InputMaybe<TwilioInfoCreateNestedOneWithoutOrganzationInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  use_revenue_planning?: InputMaybe<Scalars['Boolean']['input']>;
  UserCustomFieldFilters?: InputMaybe<UserCustomFieldFilterCreateNestedManyWithoutOrganizationInput>;
  users?: InputMaybe<UserCreateNestedManyWithoutOrganizationInput>;
  win_rep_day_goal?: InputMaybe<Scalars['Float']['input']>;
};

export type OrganizationCreateWithoutDashboardSavedViewInput = {
  ActivityMonitorMultiplier?: InputMaybe<ActivityMonitorMultiplierCreateNestedManyWithoutOrganizationInput>;
  algoFactor?: InputMaybe<AlgoFactorCreateNestedManyWithoutOrganizationInput>;
  allow_calendar_invite?: InputMaybe<Scalars['Boolean']['input']>;
  allow_remove_step_action?: InputMaybe<Scalars['Boolean']['input']>;
  allow_snooze_step_action?: InputMaybe<Scalars['Boolean']['input']>;
  always_record?: InputMaybe<Scalars['Boolean']['input']>;
  arv_initial_held_demos_generated_by_sdr_weight?: InputMaybe<Scalars['Float']['input']>;
  AssociationHistories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutOrganizationInput>;
  auto_call_interval?: InputMaybe<Scalars['Int']['input']>;
  auto_dial?: InputMaybe<Scalars['Boolean']['input']>;
  automated_campaigns?: InputMaybe<AutomatedCampaignCreateNestedManyWithoutOrganizationInput>;
  automatic_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  AutoTransferSelections?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutOrganizationInput>;
  Availablities?: InputMaybe<AvailabilityCreateNestedManyWithoutOrganizationInput>;
  AverageOrderValue?: InputMaybe<AverageOrderValueCreateNestedManyWithoutOrganizationInput>;
  best_action_ever_multiplier?: InputMaybe<Scalars['Float']['input']>;
  best_metrics?: InputMaybe<BestMetricRecordCreateNestedManyWithoutOrganizationInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptCreateNestedManyWithoutOrganizationInput>;
  callMeNows?: InputMaybe<CallMeNowCreateNestedManyWithoutOrganizationInput>;
  CallMultiplier?: InputMaybe<CallMultiplierCreateNestedManyWithoutOrganizationInput>;
  CallSharings?: InputMaybe<CallSharingCreateNestedManyWithoutOrganizationInput>;
  can_edit_sdr_transfer_rules?: InputMaybe<Scalars['Boolean']['input']>;
  claim_rule?: InputMaybe<Scalars['Int']['input']>;
  concession?: InputMaybe<ConcessionCreateNestedManyWithoutOrganizationInput>;
  conference_region?: InputMaybe<ConferenceRegion>;
  Conferences?: InputMaybe<ConferenceCreateNestedManyWithoutOrganizationInput>;
  ContactRequests?: InputMaybe<ContactRequestCreateNestedManyWithoutOrganizationInput>;
  Contacts?: InputMaybe<ContactCreateNestedManyWithoutOrganizationInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  custom_fields?: InputMaybe<CustomFieldCreateNestedManyWithoutOrganizationInput>;
  CustomObject?: InputMaybe<CustomObjectCreateNestedManyWithoutOrganizationInput>;
  CustomObjectField?: InputMaybe<CustomObjectFieldCreateNestedManyWithoutOrganizationInput>;
  CustomObjectFieldForeignKey?: InputMaybe<CustomObjectFieldForeignKeyCreateNestedManyWithoutOrganizationInput>;
  CustomObjectRecord?: InputMaybe<CustomObjectRecordCreateNestedManyWithoutOrganizationInput>;
  CustomObjectRow?: InputMaybe<CustomObjectRowCreateNestedManyWithoutOrganizationInput>;
  CustomQueueItems?: InputMaybe<CustomQueueItemCreateNestedManyWithoutOrganizationInput>;
  DashboardColumnOptions?: InputMaybe<DashboardColumnOptionCreateNestedManyWithoutOrganizationInput>;
  DashboardLeadFilters?: InputMaybe<DashboardLeadFilterCreateNestedManyWithoutOrganizationInput>;
  DashboardRepFilters?: InputMaybe<DashboardRepFilterCreateNestedManyWithoutOrganizationInput>;
  day_to_close?: InputMaybe<Scalars['Int']['input']>;
  default_demo_time_duration?: InputMaybe<Scalars['Int']['input']>;
  demo_held_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_not_held_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_not_held_call_back_short_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_prep_time_minutes?: InputMaybe<Scalars['Int']['input']>;
  dev_account?: InputMaybe<Scalars['Boolean']['input']>;
  dial_rule?: InputMaybe<Scalars['Int']['input']>;
  DispositionMultiplier?: InputMaybe<DispositionMultiplierCreateNestedManyWithoutOrganizationInput>;
  dispositions?: InputMaybe<DispositionCreateNestedManyWithoutOrganizationInput>;
  double_booking?: InputMaybe<Scalars['Boolean']['input']>;
  dynamic_date_operators?: InputMaybe<Scalars['Boolean']['input']>;
  edge_servers?: InputMaybe<OrganizationCreateedge_ServersInput>;
  email_conflict_notification_email_list?: InputMaybe<OrganizationCreateemail_Conflict_Notification_Email_ListInput>;
  EmailItems?: InputMaybe<EmailItemCreateNestedManyWithoutOrganizationInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordCreateNestedManyWithoutOrganizationInput>;
  EmailThreads?: InputMaybe<EmailThreadCreateNestedManyWithoutOrganizationInput>;
  enable_modified_metrics?: InputMaybe<Scalars['Boolean']['input']>;
  enable_pay_period_view?: InputMaybe<Scalars['Boolean']['input']>;
  enable_time_constraint?: InputMaybe<Scalars['Boolean']['input']>;
  error_log_email_address?: InputMaybe<OrganizationCreateerror_Log_Email_AddressInput>;
  estimated_average_num_of_weekly_overbooked_demo_slots?: InputMaybe<Scalars['Int']['input']>;
  FolderPermissions?: InputMaybe<FolderPermissionCreateNestedManyWithoutOrganizationInput>;
  Folders?: InputMaybe<FolderCreateNestedManyWithoutOrganizationInput>;
  FutureBookingSetting?: InputMaybe<FutureBookingSettingCreateNestedOneWithoutOrganizationInput>;
  GlobalTransferRule?: InputMaybe<GlobalTransferRuleCreateNestedManyWithoutOrganizationInput>;
  held_demos_per_cc_connect?: InputMaybe<Scalars['Float']['input']>;
  helpful_links?: InputMaybe<OrganizationCreatehelpful_LinksInput>;
  HingePointMultiplier?: InputMaybe<HingePointMultiplierCreateNestedManyWithoutOrganizationInput>;
  hold_goal?: InputMaybe<Scalars['Float']['input']>;
  hubspot_credential?: InputMaybe<HubSpotCredentialCreateNestedOneWithoutOrganizationInput>;
  hubspot_last_sync_date?: InputMaybe<Scalars['DateTime']['input']>;
  hubspot_white_list?: InputMaybe<HubSpotWhitelistCreateNestedManyWithoutOrganizationInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  inbound_close_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_dm_connect_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_lt_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_revenue_value_of_sale?: InputMaybe<Scalars['Float']['input']>;
  inbound_scheduled_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_set_rate?: InputMaybe<Scalars['Float']['input']>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventCreateNestedManyWithoutOrganizationInput>;
  InboundConciergeWidget?: InputMaybe<InboundConciergeWidgetCreateNestedManyWithoutOrganizationInput>;
  industry_options?: InputMaybe<IndustryOptionCreateNestedManyWithoutOrganizationInput>;
  initial_sdr_sets_reps_day?: InputMaybe<Scalars['Float']['input']>;
  integration_error_logs?: InputMaybe<IntegrationErrorLogCreateNestedManyWithoutOrganizationInput>;
  integration_mappings?: InputMaybe<IntegrationMappingCreateNestedManyWithoutOrganizationInput>;
  IntegrationMappingCustomObject?: InputMaybe<IntegrationMappingCustomObjectCreateNestedManyWithoutOrganizationInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogCreateNestedManyWithoutOrganizationInput>;
  IntegrationReadOnlyObject?: InputMaybe<IntegrationReadOnlyObjectCreateNestedManyWithoutOrganizationInput>;
  international?: InputMaybe<Scalars['Boolean']['input']>;
  is_email_domain_verified?: InputMaybe<Scalars['Boolean']['input']>;
  is_salesforce_sandbox?: InputMaybe<Scalars['Boolean']['input']>;
  last_lead_value_compute_time?: InputMaybe<Scalars['DateTime']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutOrganizationInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryCreateNestedManyWithoutOrganizationInput>;
  lead_intent?: InputMaybe<LeadIntentCreateNestedManyWithoutOrganizationInput>;
  lead_release_same_rep_delay_rule?: InputMaybe<Scalars['Int']['input']>;
  lead_routing_email_list?: InputMaybe<OrganizationCreatelead_Routing_Email_ListInput>;
  lead_routing_rules?: InputMaybe<RuleCreateNestedManyWithoutOrganizationInput>;
  LeadCustomObjectRowAssociation?: InputMaybe<LeadCustomObjectRowAssociationCreateNestedManyWithoutOrganizationInput>;
  LeadFieldMultiplier?: InputMaybe<LeadFieldMultiplierCreateNestedManyWithoutOrganizationInput>;
  LeadFieldMultiplierCustom?: InputMaybe<LeadFieldMultiplierCustomCreateNestedManyWithoutOrganizationInput>;
  leads?: InputMaybe<LeadCreateNestedManyWithoutOrganizationInput>;
  leadsource_options?: InputMaybe<LeadSourceOptionCreateNestedManyWithoutOrganizationInput>;
  LeadValue?: InputMaybe<LeadValueCreateNestedManyWithoutOrganizationInput>;
  LifeTimeValueMultiplier?: InputMaybe<LifeTimeValueMultiplierCreateNestedManyWithoutOrganizationInput>;
  LiveTransferSetting?: InputMaybe<LiveTransferSettingCreateNestedOneWithoutOrganizationInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusCreateNestedManyWithoutOrganizationInput>;
  ltv_multiplier_custom_fields?: InputMaybe<LifeTimeValueMultiplierCustomCreateNestedManyWithoutOrganizationInput>;
  made_contact_rule?: InputMaybe<Scalars['Int']['input']>;
  make_sale_address_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_business_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_city_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_country_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_first_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_industry_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_last_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_lead_source_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_primary_email_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_primary_phone_number_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_state_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_sub_industry_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_zip_required?: InputMaybe<Scalars['Boolean']['input']>;
  maxium_day_lag_set_to_schedule?: InputMaybe<Scalars['Int']['input']>;
  mrr?: InputMaybe<Scalars['Int']['input']>;
  mrr_label?: InputMaybe<Scalars['String']['input']>;
  mrr_required_on_sale?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  NoteItems?: InputMaybe<NoteItemCreateNestedManyWithoutOrganizationInput>;
  Notifications?: InputMaybe<NotificationCreateNestedManyWithoutOrganizationInput>;
  onboardComplete?: InputMaybe<Scalars['Boolean']['input']>;
  Opportunity?: InputMaybe<SfOpportunityCreateNestedManyWithoutOrganizationInput>;
  organization_holidays?: InputMaybe<OrganizationHolidayCreateNestedManyWithoutOrganizationInput>;
  organization_hours?: InputMaybe<OrganizationHourCreateNestedManyWithoutOrganizationInput>;
  OrganizationPaymentSetting?: InputMaybe<OrganizationPaymentSettingsCreateNestedOneWithoutOrganizationInput>;
  OrganizationPaymentSettings?: InputMaybe<OrganizationPaymentSettingsCreateNestedOneWithoutOrganizationsInput>;
  orgCalendarInviteMetadata?: InputMaybe<OrgCalendarInviteMetadataCreateNestedManyWithoutOrganizationInput>;
  OrgDefaultPipelinePerecentValue?: InputMaybe<OrgDefaultPipelinePerecentValueCreateNestedOneWithoutOrganizationInput>;
  OrgHubSpotSync?: InputMaybe<OrgHubSpotSyncCreateNestedOneWithoutOrganizationInput>;
  OrgNoEventDate?: InputMaybe<OrgNoEventDateCreateNestedManyWithoutOrganizationInput>;
  OrgSalesforceSync?: InputMaybe<OrgSalesforceSyncCreateNestedOneWithoutOrganizationInput>;
  OrgTimeslotAvailability?: InputMaybe<OrgTimeslotAvailabilityCreateNestedManyWithoutOrganizationInput>;
  outbound_close_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_dm_connect_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_lt_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_revenue_value_of_sale?: InputMaybe<Scalars['Float']['input']>;
  outbound_scheduled_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_set_rate?: InputMaybe<Scalars['Float']['input']>;
  PandadocData?: InputMaybe<PandadocDataCreateNestedOneWithoutOrganizationInput>;
  PandaDocTemplate?: InputMaybe<PandaDocTemplateCreateNestedManyWithoutOrganizationInput>;
  participant_jitter_buffer_size?: InputMaybe<JitterBufferSize>;
  preferred_goal_setting?: InputMaybe<Goalsetting>;
  prepayment?: InputMaybe<Scalars['Boolean']['input']>;
  ProductChangeLog?: InputMaybe<ProductChangeLogCreateNestedManyWithoutOrganizationInput>;
  products?: InputMaybe<ProductCreateNestedManyWithoutOrganizationInput>;
  re_cold_call_hour_delay?: InputMaybe<Scalars['Float']['input']>;
  recordingStatus?: InputMaybe<RecordingStatus>;
  release_lead_resting?: InputMaybe<Scalars['Int']['input']>;
  rep_claim_limit?: InputMaybe<Scalars['Int']['input']>;
  rep_lead_allotment?: InputMaybe<Scalars['Int']['input']>;
  rep_level_call_transfer?: InputMaybe<Scalars['Boolean']['input']>;
  reps_can_bulk_unassign?: InputMaybe<Scalars['Boolean']['input']>;
  reps_can_view_all_notes?: InputMaybe<Scalars['Boolean']['input']>;
  require_sale_notes?: InputMaybe<Scalars['Boolean']['input']>;
  resting_after_skipped_CC?: InputMaybe<Scalars['Int']['input']>;
  resting_period?: InputMaybe<Scalars['Int']['input']>;
  resting_period_after_unsuccessful_cold_call?: InputMaybe<Scalars['Float']['input']>;
  resting_period_after_unsuccessful_demo?: InputMaybe<Scalars['Float']['input']>;
  revenue_plan?: InputMaybe<RevenuePlanItemCreateNestedManyWithoutOrganizationInput>;
  Routings?: InputMaybe<RoutingCreateNestedManyWithoutOrganizationInput>;
  sale_field_options?: InputMaybe<SaleFieldOptionCreateNestedManyWithoutOrganizationInput>;
  sale_notification_email_list?: InputMaybe<OrganizationCreatesale_Notification_Email_ListInput>;
  SaleConfig?: InputMaybe<SaleConfigCreateNestedOneWithoutOrganizationInput>;
  SaleConfigPage?: InputMaybe<SaleConfigPageCreateNestedManyWithoutOrganizationInput>;
  SaleConfigSection?: InputMaybe<SaleConfigSectionCreateNestedManyWithoutOrganizationInput>;
  SaleCycles?: InputMaybe<SaleCycleCreateNestedManyWithoutOrganizationInput>;
  SaleItems?: InputMaybe<SaleItemCreateNestedManyWithoutOrganizationInput>;
  sales?: InputMaybe<SaleCreateNestedManyWithoutOrganizationInput>;
  salesforce_domain?: InputMaybe<Scalars['String']['input']>;
  salesforce_last_sync_date?: InputMaybe<Scalars['DateTime']['input']>;
  SalesForceColumn?: InputMaybe<SalesForceColumnCreateNestedManyWithoutOrganizationInput>;
  salesForceData?: InputMaybe<SalesForceDataCreateNestedOneWithoutOrganizationInput>;
  ScheduleItems?: InputMaybe<ScheduleItemCreateNestedManyWithoutOrganizationInput>;
  self_sourcing_rule?: InputMaybe<Scalars['Int']['input']>;
  send_error_log_email?: InputMaybe<Scalars['Boolean']['input']>;
  send_lead_routing_email?: InputMaybe<Scalars['Boolean']['input']>;
  sendgrid_email_domain?: InputMaybe<Scalars['String']['input']>;
  sendgrid_email_domain_id?: InputMaybe<Scalars['Int']['input']>;
  sequence?: InputMaybe<SequenceCreateNestedManyWithoutOrganzationInput>;
  SequenceCriteriaConditions?: InputMaybe<SequenceCriteriaConditionCreateNestedManyWithoutOrganizationInput>;
  SequenceDashboardViews?: InputMaybe<SequenceDashboardViewCreateNestedManyWithoutOrganizationInput>;
  SequenceEntryCriterias?: InputMaybe<SequenceEntryCriteriaCreateNestedManyWithoutOrganizationInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutOrganizationInput>;
  SequenceSteps?: InputMaybe<SequenceStepCreateNestedManyWithoutOrganizationInput>;
  set_goal?: InputMaybe<Scalars['Float']['input']>;
  short_resting?: InputMaybe<Scalars['Int']['input']>;
  show_calendar_options?: InputMaybe<Scalars['Boolean']['input']>;
  show_contract_duration_sales?: InputMaybe<Scalars['Boolean']['input']>;
  show_lead_source_to_rep?: InputMaybe<Scalars['Boolean']['input']>;
  show_nocontact_button?: InputMaybe<Scalars['Boolean']['input']>;
  show_payment_terms_sales?: InputMaybe<Scalars['Boolean']['input']>;
  show_rep_all_leads?: InputMaybe<Scalars['Boolean']['input']>;
  show_rep_my_leads?: InputMaybe<Scalars['Boolean']['input']>;
  show_system_view?: InputMaybe<Scalars['Boolean']['input']>;
  sites?: InputMaybe<SiteCreateNestedManyWithoutOrganizationInput>;
  sms_opt_in_dialog?: InputMaybe<Scalars['String']['input']>;
  SMSItems?: InputMaybe<SmsItemCreateNestedManyWithoutOrganizationInput>;
  SnoozeTrackings?: InputMaybe<SnoozeTrackingCreateNestedManyWithoutOrganizationInput>;
  StepActionLogs?: InputMaybe<StepActionLogCreateNestedManyWithoutOrganizationInput>;
  StepActions?: InputMaybe<StepActionCreateNestedManyWithoutOrganizationInput>;
  StepConditions?: InputMaybe<StepConditionCreateNestedManyWithoutOrganizationInput>;
  teams?: InputMaybe<TeamCreateNestedManyWithoutOrganizationInput>;
  Template?: InputMaybe<TemplateCreateNestedManyWithoutOrganizationInput>;
  TemplateVersions?: InputMaybe<TemplateVersionCreateNestedManyWithoutOrganizationInput>;
  timekit_id?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  transfer_timeout?: InputMaybe<Scalars['Int']['input']>;
  TransferAttempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutOrganizationInput>;
  twilio_numbers?: InputMaybe<TwilioNumberCreateNestedManyWithoutOrganizationInput>;
  TwilioFeedbacks?: InputMaybe<TwilioFeedbackCreateNestedManyWithoutOrganizationInput>;
  TwilioInfo?: InputMaybe<TwilioInfoCreateNestedOneWithoutOrganzationInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  use_revenue_planning?: InputMaybe<Scalars['Boolean']['input']>;
  UserCustomFieldFilters?: InputMaybe<UserCustomFieldFilterCreateNestedManyWithoutOrganizationInput>;
  users?: InputMaybe<UserCreateNestedManyWithoutOrganizationInput>;
  win_rep_day_goal?: InputMaybe<Scalars['Float']['input']>;
};

export type OrganizationCreateWithoutDispositionMultiplierInput = {
  ActivityMonitorMultiplier?: InputMaybe<ActivityMonitorMultiplierCreateNestedManyWithoutOrganizationInput>;
  algoFactor?: InputMaybe<AlgoFactorCreateNestedManyWithoutOrganizationInput>;
  allow_calendar_invite?: InputMaybe<Scalars['Boolean']['input']>;
  allow_remove_step_action?: InputMaybe<Scalars['Boolean']['input']>;
  allow_snooze_step_action?: InputMaybe<Scalars['Boolean']['input']>;
  always_record?: InputMaybe<Scalars['Boolean']['input']>;
  arv_initial_held_demos_generated_by_sdr_weight?: InputMaybe<Scalars['Float']['input']>;
  AssociationHistories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutOrganizationInput>;
  auto_call_interval?: InputMaybe<Scalars['Int']['input']>;
  auto_dial?: InputMaybe<Scalars['Boolean']['input']>;
  automated_campaigns?: InputMaybe<AutomatedCampaignCreateNestedManyWithoutOrganizationInput>;
  automatic_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  AutoTransferSelections?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutOrganizationInput>;
  Availablities?: InputMaybe<AvailabilityCreateNestedManyWithoutOrganizationInput>;
  AverageOrderValue?: InputMaybe<AverageOrderValueCreateNestedManyWithoutOrganizationInput>;
  best_action_ever_multiplier?: InputMaybe<Scalars['Float']['input']>;
  best_metrics?: InputMaybe<BestMetricRecordCreateNestedManyWithoutOrganizationInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptCreateNestedManyWithoutOrganizationInput>;
  callMeNows?: InputMaybe<CallMeNowCreateNestedManyWithoutOrganizationInput>;
  CallMultiplier?: InputMaybe<CallMultiplierCreateNestedManyWithoutOrganizationInput>;
  CallSharings?: InputMaybe<CallSharingCreateNestedManyWithoutOrganizationInput>;
  can_edit_sdr_transfer_rules?: InputMaybe<Scalars['Boolean']['input']>;
  claim_rule?: InputMaybe<Scalars['Int']['input']>;
  concession?: InputMaybe<ConcessionCreateNestedManyWithoutOrganizationInput>;
  conference_region?: InputMaybe<ConferenceRegion>;
  Conferences?: InputMaybe<ConferenceCreateNestedManyWithoutOrganizationInput>;
  ContactRequests?: InputMaybe<ContactRequestCreateNestedManyWithoutOrganizationInput>;
  Contacts?: InputMaybe<ContactCreateNestedManyWithoutOrganizationInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  custom_fields?: InputMaybe<CustomFieldCreateNestedManyWithoutOrganizationInput>;
  CustomObject?: InputMaybe<CustomObjectCreateNestedManyWithoutOrganizationInput>;
  CustomObjectField?: InputMaybe<CustomObjectFieldCreateNestedManyWithoutOrganizationInput>;
  CustomObjectFieldForeignKey?: InputMaybe<CustomObjectFieldForeignKeyCreateNestedManyWithoutOrganizationInput>;
  CustomObjectRecord?: InputMaybe<CustomObjectRecordCreateNestedManyWithoutOrganizationInput>;
  CustomObjectRow?: InputMaybe<CustomObjectRowCreateNestedManyWithoutOrganizationInput>;
  CustomQueueItems?: InputMaybe<CustomQueueItemCreateNestedManyWithoutOrganizationInput>;
  DashboardColumnOptions?: InputMaybe<DashboardColumnOptionCreateNestedManyWithoutOrganizationInput>;
  DashboardLeadFilters?: InputMaybe<DashboardLeadFilterCreateNestedManyWithoutOrganizationInput>;
  DashboardRepFilters?: InputMaybe<DashboardRepFilterCreateNestedManyWithoutOrganizationInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewCreateNestedManyWithoutOrganizationInput>;
  day_to_close?: InputMaybe<Scalars['Int']['input']>;
  default_demo_time_duration?: InputMaybe<Scalars['Int']['input']>;
  demo_held_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_not_held_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_not_held_call_back_short_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_prep_time_minutes?: InputMaybe<Scalars['Int']['input']>;
  dev_account?: InputMaybe<Scalars['Boolean']['input']>;
  dial_rule?: InputMaybe<Scalars['Int']['input']>;
  dispositions?: InputMaybe<DispositionCreateNestedManyWithoutOrganizationInput>;
  double_booking?: InputMaybe<Scalars['Boolean']['input']>;
  dynamic_date_operators?: InputMaybe<Scalars['Boolean']['input']>;
  edge_servers?: InputMaybe<OrganizationCreateedge_ServersInput>;
  email_conflict_notification_email_list?: InputMaybe<OrganizationCreateemail_Conflict_Notification_Email_ListInput>;
  EmailItems?: InputMaybe<EmailItemCreateNestedManyWithoutOrganizationInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordCreateNestedManyWithoutOrganizationInput>;
  EmailThreads?: InputMaybe<EmailThreadCreateNestedManyWithoutOrganizationInput>;
  enable_modified_metrics?: InputMaybe<Scalars['Boolean']['input']>;
  enable_pay_period_view?: InputMaybe<Scalars['Boolean']['input']>;
  enable_time_constraint?: InputMaybe<Scalars['Boolean']['input']>;
  error_log_email_address?: InputMaybe<OrganizationCreateerror_Log_Email_AddressInput>;
  estimated_average_num_of_weekly_overbooked_demo_slots?: InputMaybe<Scalars['Int']['input']>;
  FolderPermissions?: InputMaybe<FolderPermissionCreateNestedManyWithoutOrganizationInput>;
  Folders?: InputMaybe<FolderCreateNestedManyWithoutOrganizationInput>;
  FutureBookingSetting?: InputMaybe<FutureBookingSettingCreateNestedOneWithoutOrganizationInput>;
  GlobalTransferRule?: InputMaybe<GlobalTransferRuleCreateNestedManyWithoutOrganizationInput>;
  held_demos_per_cc_connect?: InputMaybe<Scalars['Float']['input']>;
  helpful_links?: InputMaybe<OrganizationCreatehelpful_LinksInput>;
  HingePointMultiplier?: InputMaybe<HingePointMultiplierCreateNestedManyWithoutOrganizationInput>;
  hold_goal?: InputMaybe<Scalars['Float']['input']>;
  hubspot_credential?: InputMaybe<HubSpotCredentialCreateNestedOneWithoutOrganizationInput>;
  hubspot_last_sync_date?: InputMaybe<Scalars['DateTime']['input']>;
  hubspot_white_list?: InputMaybe<HubSpotWhitelistCreateNestedManyWithoutOrganizationInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  inbound_close_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_dm_connect_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_lt_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_revenue_value_of_sale?: InputMaybe<Scalars['Float']['input']>;
  inbound_scheduled_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_set_rate?: InputMaybe<Scalars['Float']['input']>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventCreateNestedManyWithoutOrganizationInput>;
  InboundConciergeWidget?: InputMaybe<InboundConciergeWidgetCreateNestedManyWithoutOrganizationInput>;
  industry_options?: InputMaybe<IndustryOptionCreateNestedManyWithoutOrganizationInput>;
  initial_sdr_sets_reps_day?: InputMaybe<Scalars['Float']['input']>;
  integration_error_logs?: InputMaybe<IntegrationErrorLogCreateNestedManyWithoutOrganizationInput>;
  integration_mappings?: InputMaybe<IntegrationMappingCreateNestedManyWithoutOrganizationInput>;
  IntegrationMappingCustomObject?: InputMaybe<IntegrationMappingCustomObjectCreateNestedManyWithoutOrganizationInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogCreateNestedManyWithoutOrganizationInput>;
  IntegrationReadOnlyObject?: InputMaybe<IntegrationReadOnlyObjectCreateNestedManyWithoutOrganizationInput>;
  international?: InputMaybe<Scalars['Boolean']['input']>;
  is_email_domain_verified?: InputMaybe<Scalars['Boolean']['input']>;
  is_salesforce_sandbox?: InputMaybe<Scalars['Boolean']['input']>;
  last_lead_value_compute_time?: InputMaybe<Scalars['DateTime']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutOrganizationInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryCreateNestedManyWithoutOrganizationInput>;
  lead_intent?: InputMaybe<LeadIntentCreateNestedManyWithoutOrganizationInput>;
  lead_release_same_rep_delay_rule?: InputMaybe<Scalars['Int']['input']>;
  lead_routing_email_list?: InputMaybe<OrganizationCreatelead_Routing_Email_ListInput>;
  lead_routing_rules?: InputMaybe<RuleCreateNestedManyWithoutOrganizationInput>;
  LeadCustomObjectRowAssociation?: InputMaybe<LeadCustomObjectRowAssociationCreateNestedManyWithoutOrganizationInput>;
  LeadFieldMultiplier?: InputMaybe<LeadFieldMultiplierCreateNestedManyWithoutOrganizationInput>;
  LeadFieldMultiplierCustom?: InputMaybe<LeadFieldMultiplierCustomCreateNestedManyWithoutOrganizationInput>;
  leads?: InputMaybe<LeadCreateNestedManyWithoutOrganizationInput>;
  leadsource_options?: InputMaybe<LeadSourceOptionCreateNestedManyWithoutOrganizationInput>;
  LeadValue?: InputMaybe<LeadValueCreateNestedManyWithoutOrganizationInput>;
  LifeTimeValueMultiplier?: InputMaybe<LifeTimeValueMultiplierCreateNestedManyWithoutOrganizationInput>;
  LiveTransferSetting?: InputMaybe<LiveTransferSettingCreateNestedOneWithoutOrganizationInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusCreateNestedManyWithoutOrganizationInput>;
  ltv_multiplier_custom_fields?: InputMaybe<LifeTimeValueMultiplierCustomCreateNestedManyWithoutOrganizationInput>;
  made_contact_rule?: InputMaybe<Scalars['Int']['input']>;
  make_sale_address_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_business_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_city_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_country_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_first_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_industry_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_last_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_lead_source_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_primary_email_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_primary_phone_number_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_state_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_sub_industry_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_zip_required?: InputMaybe<Scalars['Boolean']['input']>;
  maxium_day_lag_set_to_schedule?: InputMaybe<Scalars['Int']['input']>;
  mrr?: InputMaybe<Scalars['Int']['input']>;
  mrr_label?: InputMaybe<Scalars['String']['input']>;
  mrr_required_on_sale?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  NoteItems?: InputMaybe<NoteItemCreateNestedManyWithoutOrganizationInput>;
  Notifications?: InputMaybe<NotificationCreateNestedManyWithoutOrganizationInput>;
  onboardComplete?: InputMaybe<Scalars['Boolean']['input']>;
  Opportunity?: InputMaybe<SfOpportunityCreateNestedManyWithoutOrganizationInput>;
  organization_holidays?: InputMaybe<OrganizationHolidayCreateNestedManyWithoutOrganizationInput>;
  organization_hours?: InputMaybe<OrganizationHourCreateNestedManyWithoutOrganizationInput>;
  OrganizationPaymentSetting?: InputMaybe<OrganizationPaymentSettingsCreateNestedOneWithoutOrganizationInput>;
  OrganizationPaymentSettings?: InputMaybe<OrganizationPaymentSettingsCreateNestedOneWithoutOrganizationsInput>;
  orgCalendarInviteMetadata?: InputMaybe<OrgCalendarInviteMetadataCreateNestedManyWithoutOrganizationInput>;
  OrgDefaultPipelinePerecentValue?: InputMaybe<OrgDefaultPipelinePerecentValueCreateNestedOneWithoutOrganizationInput>;
  OrgHubSpotSync?: InputMaybe<OrgHubSpotSyncCreateNestedOneWithoutOrganizationInput>;
  OrgNoEventDate?: InputMaybe<OrgNoEventDateCreateNestedManyWithoutOrganizationInput>;
  OrgSalesforceSync?: InputMaybe<OrgSalesforceSyncCreateNestedOneWithoutOrganizationInput>;
  OrgTimeslotAvailability?: InputMaybe<OrgTimeslotAvailabilityCreateNestedManyWithoutOrganizationInput>;
  outbound_close_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_dm_connect_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_lt_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_revenue_value_of_sale?: InputMaybe<Scalars['Float']['input']>;
  outbound_scheduled_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_set_rate?: InputMaybe<Scalars['Float']['input']>;
  PandadocData?: InputMaybe<PandadocDataCreateNestedOneWithoutOrganizationInput>;
  PandaDocTemplate?: InputMaybe<PandaDocTemplateCreateNestedManyWithoutOrganizationInput>;
  participant_jitter_buffer_size?: InputMaybe<JitterBufferSize>;
  preferred_goal_setting?: InputMaybe<Goalsetting>;
  prepayment?: InputMaybe<Scalars['Boolean']['input']>;
  ProductChangeLog?: InputMaybe<ProductChangeLogCreateNestedManyWithoutOrganizationInput>;
  products?: InputMaybe<ProductCreateNestedManyWithoutOrganizationInput>;
  re_cold_call_hour_delay?: InputMaybe<Scalars['Float']['input']>;
  recordingStatus?: InputMaybe<RecordingStatus>;
  release_lead_resting?: InputMaybe<Scalars['Int']['input']>;
  rep_claim_limit?: InputMaybe<Scalars['Int']['input']>;
  rep_lead_allotment?: InputMaybe<Scalars['Int']['input']>;
  rep_level_call_transfer?: InputMaybe<Scalars['Boolean']['input']>;
  reps_can_bulk_unassign?: InputMaybe<Scalars['Boolean']['input']>;
  reps_can_view_all_notes?: InputMaybe<Scalars['Boolean']['input']>;
  require_sale_notes?: InputMaybe<Scalars['Boolean']['input']>;
  resting_after_skipped_CC?: InputMaybe<Scalars['Int']['input']>;
  resting_period?: InputMaybe<Scalars['Int']['input']>;
  resting_period_after_unsuccessful_cold_call?: InputMaybe<Scalars['Float']['input']>;
  resting_period_after_unsuccessful_demo?: InputMaybe<Scalars['Float']['input']>;
  revenue_plan?: InputMaybe<RevenuePlanItemCreateNestedManyWithoutOrganizationInput>;
  Routings?: InputMaybe<RoutingCreateNestedManyWithoutOrganizationInput>;
  sale_field_options?: InputMaybe<SaleFieldOptionCreateNestedManyWithoutOrganizationInput>;
  sale_notification_email_list?: InputMaybe<OrganizationCreatesale_Notification_Email_ListInput>;
  SaleConfig?: InputMaybe<SaleConfigCreateNestedOneWithoutOrganizationInput>;
  SaleConfigPage?: InputMaybe<SaleConfigPageCreateNestedManyWithoutOrganizationInput>;
  SaleConfigSection?: InputMaybe<SaleConfigSectionCreateNestedManyWithoutOrganizationInput>;
  SaleCycles?: InputMaybe<SaleCycleCreateNestedManyWithoutOrganizationInput>;
  SaleItems?: InputMaybe<SaleItemCreateNestedManyWithoutOrganizationInput>;
  sales?: InputMaybe<SaleCreateNestedManyWithoutOrganizationInput>;
  salesforce_domain?: InputMaybe<Scalars['String']['input']>;
  salesforce_last_sync_date?: InputMaybe<Scalars['DateTime']['input']>;
  SalesForceColumn?: InputMaybe<SalesForceColumnCreateNestedManyWithoutOrganizationInput>;
  salesForceData?: InputMaybe<SalesForceDataCreateNestedOneWithoutOrganizationInput>;
  ScheduleItems?: InputMaybe<ScheduleItemCreateNestedManyWithoutOrganizationInput>;
  self_sourcing_rule?: InputMaybe<Scalars['Int']['input']>;
  send_error_log_email?: InputMaybe<Scalars['Boolean']['input']>;
  send_lead_routing_email?: InputMaybe<Scalars['Boolean']['input']>;
  sendgrid_email_domain?: InputMaybe<Scalars['String']['input']>;
  sendgrid_email_domain_id?: InputMaybe<Scalars['Int']['input']>;
  sequence?: InputMaybe<SequenceCreateNestedManyWithoutOrganzationInput>;
  SequenceCriteriaConditions?: InputMaybe<SequenceCriteriaConditionCreateNestedManyWithoutOrganizationInput>;
  SequenceDashboardViews?: InputMaybe<SequenceDashboardViewCreateNestedManyWithoutOrganizationInput>;
  SequenceEntryCriterias?: InputMaybe<SequenceEntryCriteriaCreateNestedManyWithoutOrganizationInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutOrganizationInput>;
  SequenceSteps?: InputMaybe<SequenceStepCreateNestedManyWithoutOrganizationInput>;
  set_goal?: InputMaybe<Scalars['Float']['input']>;
  short_resting?: InputMaybe<Scalars['Int']['input']>;
  show_calendar_options?: InputMaybe<Scalars['Boolean']['input']>;
  show_contract_duration_sales?: InputMaybe<Scalars['Boolean']['input']>;
  show_lead_source_to_rep?: InputMaybe<Scalars['Boolean']['input']>;
  show_nocontact_button?: InputMaybe<Scalars['Boolean']['input']>;
  show_payment_terms_sales?: InputMaybe<Scalars['Boolean']['input']>;
  show_rep_all_leads?: InputMaybe<Scalars['Boolean']['input']>;
  show_rep_my_leads?: InputMaybe<Scalars['Boolean']['input']>;
  show_system_view?: InputMaybe<Scalars['Boolean']['input']>;
  sites?: InputMaybe<SiteCreateNestedManyWithoutOrganizationInput>;
  sms_opt_in_dialog?: InputMaybe<Scalars['String']['input']>;
  SMSItems?: InputMaybe<SmsItemCreateNestedManyWithoutOrganizationInput>;
  SnoozeTrackings?: InputMaybe<SnoozeTrackingCreateNestedManyWithoutOrganizationInput>;
  StepActionLogs?: InputMaybe<StepActionLogCreateNestedManyWithoutOrganizationInput>;
  StepActions?: InputMaybe<StepActionCreateNestedManyWithoutOrganizationInput>;
  StepConditions?: InputMaybe<StepConditionCreateNestedManyWithoutOrganizationInput>;
  teams?: InputMaybe<TeamCreateNestedManyWithoutOrganizationInput>;
  Template?: InputMaybe<TemplateCreateNestedManyWithoutOrganizationInput>;
  TemplateVersions?: InputMaybe<TemplateVersionCreateNestedManyWithoutOrganizationInput>;
  timekit_id?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  transfer_timeout?: InputMaybe<Scalars['Int']['input']>;
  TransferAttempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutOrganizationInput>;
  twilio_numbers?: InputMaybe<TwilioNumberCreateNestedManyWithoutOrganizationInput>;
  TwilioFeedbacks?: InputMaybe<TwilioFeedbackCreateNestedManyWithoutOrganizationInput>;
  TwilioInfo?: InputMaybe<TwilioInfoCreateNestedOneWithoutOrganzationInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  use_revenue_planning?: InputMaybe<Scalars['Boolean']['input']>;
  UserCustomFieldFilters?: InputMaybe<UserCustomFieldFilterCreateNestedManyWithoutOrganizationInput>;
  users?: InputMaybe<UserCreateNestedManyWithoutOrganizationInput>;
  win_rep_day_goal?: InputMaybe<Scalars['Float']['input']>;
};

export type OrganizationCreateWithoutDispositionsInput = {
  ActivityMonitorMultiplier?: InputMaybe<ActivityMonitorMultiplierCreateNestedManyWithoutOrganizationInput>;
  algoFactor?: InputMaybe<AlgoFactorCreateNestedManyWithoutOrganizationInput>;
  allow_calendar_invite?: InputMaybe<Scalars['Boolean']['input']>;
  allow_remove_step_action?: InputMaybe<Scalars['Boolean']['input']>;
  allow_snooze_step_action?: InputMaybe<Scalars['Boolean']['input']>;
  always_record?: InputMaybe<Scalars['Boolean']['input']>;
  arv_initial_held_demos_generated_by_sdr_weight?: InputMaybe<Scalars['Float']['input']>;
  AssociationHistories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutOrganizationInput>;
  auto_call_interval?: InputMaybe<Scalars['Int']['input']>;
  auto_dial?: InputMaybe<Scalars['Boolean']['input']>;
  automated_campaigns?: InputMaybe<AutomatedCampaignCreateNestedManyWithoutOrganizationInput>;
  automatic_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  AutoTransferSelections?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutOrganizationInput>;
  Availablities?: InputMaybe<AvailabilityCreateNestedManyWithoutOrganizationInput>;
  AverageOrderValue?: InputMaybe<AverageOrderValueCreateNestedManyWithoutOrganizationInput>;
  best_action_ever_multiplier?: InputMaybe<Scalars['Float']['input']>;
  best_metrics?: InputMaybe<BestMetricRecordCreateNestedManyWithoutOrganizationInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptCreateNestedManyWithoutOrganizationInput>;
  callMeNows?: InputMaybe<CallMeNowCreateNestedManyWithoutOrganizationInput>;
  CallMultiplier?: InputMaybe<CallMultiplierCreateNestedManyWithoutOrganizationInput>;
  CallSharings?: InputMaybe<CallSharingCreateNestedManyWithoutOrganizationInput>;
  can_edit_sdr_transfer_rules?: InputMaybe<Scalars['Boolean']['input']>;
  claim_rule?: InputMaybe<Scalars['Int']['input']>;
  concession?: InputMaybe<ConcessionCreateNestedManyWithoutOrganizationInput>;
  conference_region?: InputMaybe<ConferenceRegion>;
  Conferences?: InputMaybe<ConferenceCreateNestedManyWithoutOrganizationInput>;
  ContactRequests?: InputMaybe<ContactRequestCreateNestedManyWithoutOrganizationInput>;
  Contacts?: InputMaybe<ContactCreateNestedManyWithoutOrganizationInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  custom_fields?: InputMaybe<CustomFieldCreateNestedManyWithoutOrganizationInput>;
  CustomObject?: InputMaybe<CustomObjectCreateNestedManyWithoutOrganizationInput>;
  CustomObjectField?: InputMaybe<CustomObjectFieldCreateNestedManyWithoutOrganizationInput>;
  CustomObjectFieldForeignKey?: InputMaybe<CustomObjectFieldForeignKeyCreateNestedManyWithoutOrganizationInput>;
  CustomObjectRecord?: InputMaybe<CustomObjectRecordCreateNestedManyWithoutOrganizationInput>;
  CustomObjectRow?: InputMaybe<CustomObjectRowCreateNestedManyWithoutOrganizationInput>;
  CustomQueueItems?: InputMaybe<CustomQueueItemCreateNestedManyWithoutOrganizationInput>;
  DashboardColumnOptions?: InputMaybe<DashboardColumnOptionCreateNestedManyWithoutOrganizationInput>;
  DashboardLeadFilters?: InputMaybe<DashboardLeadFilterCreateNestedManyWithoutOrganizationInput>;
  DashboardRepFilters?: InputMaybe<DashboardRepFilterCreateNestedManyWithoutOrganizationInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewCreateNestedManyWithoutOrganizationInput>;
  day_to_close?: InputMaybe<Scalars['Int']['input']>;
  default_demo_time_duration?: InputMaybe<Scalars['Int']['input']>;
  demo_held_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_not_held_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_not_held_call_back_short_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_prep_time_minutes?: InputMaybe<Scalars['Int']['input']>;
  dev_account?: InputMaybe<Scalars['Boolean']['input']>;
  dial_rule?: InputMaybe<Scalars['Int']['input']>;
  DispositionMultiplier?: InputMaybe<DispositionMultiplierCreateNestedManyWithoutOrganizationInput>;
  double_booking?: InputMaybe<Scalars['Boolean']['input']>;
  dynamic_date_operators?: InputMaybe<Scalars['Boolean']['input']>;
  edge_servers?: InputMaybe<OrganizationCreateedge_ServersInput>;
  email_conflict_notification_email_list?: InputMaybe<OrganizationCreateemail_Conflict_Notification_Email_ListInput>;
  EmailItems?: InputMaybe<EmailItemCreateNestedManyWithoutOrganizationInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordCreateNestedManyWithoutOrganizationInput>;
  EmailThreads?: InputMaybe<EmailThreadCreateNestedManyWithoutOrganizationInput>;
  enable_modified_metrics?: InputMaybe<Scalars['Boolean']['input']>;
  enable_pay_period_view?: InputMaybe<Scalars['Boolean']['input']>;
  enable_time_constraint?: InputMaybe<Scalars['Boolean']['input']>;
  error_log_email_address?: InputMaybe<OrganizationCreateerror_Log_Email_AddressInput>;
  estimated_average_num_of_weekly_overbooked_demo_slots?: InputMaybe<Scalars['Int']['input']>;
  FolderPermissions?: InputMaybe<FolderPermissionCreateNestedManyWithoutOrganizationInput>;
  Folders?: InputMaybe<FolderCreateNestedManyWithoutOrganizationInput>;
  FutureBookingSetting?: InputMaybe<FutureBookingSettingCreateNestedOneWithoutOrganizationInput>;
  GlobalTransferRule?: InputMaybe<GlobalTransferRuleCreateNestedManyWithoutOrganizationInput>;
  held_demos_per_cc_connect?: InputMaybe<Scalars['Float']['input']>;
  helpful_links?: InputMaybe<OrganizationCreatehelpful_LinksInput>;
  HingePointMultiplier?: InputMaybe<HingePointMultiplierCreateNestedManyWithoutOrganizationInput>;
  hold_goal?: InputMaybe<Scalars['Float']['input']>;
  hubspot_credential?: InputMaybe<HubSpotCredentialCreateNestedOneWithoutOrganizationInput>;
  hubspot_last_sync_date?: InputMaybe<Scalars['DateTime']['input']>;
  hubspot_white_list?: InputMaybe<HubSpotWhitelistCreateNestedManyWithoutOrganizationInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  inbound_close_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_dm_connect_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_lt_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_revenue_value_of_sale?: InputMaybe<Scalars['Float']['input']>;
  inbound_scheduled_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_set_rate?: InputMaybe<Scalars['Float']['input']>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventCreateNestedManyWithoutOrganizationInput>;
  InboundConciergeWidget?: InputMaybe<InboundConciergeWidgetCreateNestedManyWithoutOrganizationInput>;
  industry_options?: InputMaybe<IndustryOptionCreateNestedManyWithoutOrganizationInput>;
  initial_sdr_sets_reps_day?: InputMaybe<Scalars['Float']['input']>;
  integration_error_logs?: InputMaybe<IntegrationErrorLogCreateNestedManyWithoutOrganizationInput>;
  integration_mappings?: InputMaybe<IntegrationMappingCreateNestedManyWithoutOrganizationInput>;
  IntegrationMappingCustomObject?: InputMaybe<IntegrationMappingCustomObjectCreateNestedManyWithoutOrganizationInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogCreateNestedManyWithoutOrganizationInput>;
  IntegrationReadOnlyObject?: InputMaybe<IntegrationReadOnlyObjectCreateNestedManyWithoutOrganizationInput>;
  international?: InputMaybe<Scalars['Boolean']['input']>;
  is_email_domain_verified?: InputMaybe<Scalars['Boolean']['input']>;
  is_salesforce_sandbox?: InputMaybe<Scalars['Boolean']['input']>;
  last_lead_value_compute_time?: InputMaybe<Scalars['DateTime']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutOrganizationInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryCreateNestedManyWithoutOrganizationInput>;
  lead_intent?: InputMaybe<LeadIntentCreateNestedManyWithoutOrganizationInput>;
  lead_release_same_rep_delay_rule?: InputMaybe<Scalars['Int']['input']>;
  lead_routing_email_list?: InputMaybe<OrganizationCreatelead_Routing_Email_ListInput>;
  lead_routing_rules?: InputMaybe<RuleCreateNestedManyWithoutOrganizationInput>;
  LeadCustomObjectRowAssociation?: InputMaybe<LeadCustomObjectRowAssociationCreateNestedManyWithoutOrganizationInput>;
  LeadFieldMultiplier?: InputMaybe<LeadFieldMultiplierCreateNestedManyWithoutOrganizationInput>;
  LeadFieldMultiplierCustom?: InputMaybe<LeadFieldMultiplierCustomCreateNestedManyWithoutOrganizationInput>;
  leads?: InputMaybe<LeadCreateNestedManyWithoutOrganizationInput>;
  leadsource_options?: InputMaybe<LeadSourceOptionCreateNestedManyWithoutOrganizationInput>;
  LeadValue?: InputMaybe<LeadValueCreateNestedManyWithoutOrganizationInput>;
  LifeTimeValueMultiplier?: InputMaybe<LifeTimeValueMultiplierCreateNestedManyWithoutOrganizationInput>;
  LiveTransferSetting?: InputMaybe<LiveTransferSettingCreateNestedOneWithoutOrganizationInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusCreateNestedManyWithoutOrganizationInput>;
  ltv_multiplier_custom_fields?: InputMaybe<LifeTimeValueMultiplierCustomCreateNestedManyWithoutOrganizationInput>;
  made_contact_rule?: InputMaybe<Scalars['Int']['input']>;
  make_sale_address_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_business_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_city_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_country_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_first_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_industry_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_last_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_lead_source_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_primary_email_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_primary_phone_number_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_state_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_sub_industry_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_zip_required?: InputMaybe<Scalars['Boolean']['input']>;
  maxium_day_lag_set_to_schedule?: InputMaybe<Scalars['Int']['input']>;
  mrr?: InputMaybe<Scalars['Int']['input']>;
  mrr_label?: InputMaybe<Scalars['String']['input']>;
  mrr_required_on_sale?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  NoteItems?: InputMaybe<NoteItemCreateNestedManyWithoutOrganizationInput>;
  Notifications?: InputMaybe<NotificationCreateNestedManyWithoutOrganizationInput>;
  onboardComplete?: InputMaybe<Scalars['Boolean']['input']>;
  Opportunity?: InputMaybe<SfOpportunityCreateNestedManyWithoutOrganizationInput>;
  organization_holidays?: InputMaybe<OrganizationHolidayCreateNestedManyWithoutOrganizationInput>;
  organization_hours?: InputMaybe<OrganizationHourCreateNestedManyWithoutOrganizationInput>;
  OrganizationPaymentSetting?: InputMaybe<OrganizationPaymentSettingsCreateNestedOneWithoutOrganizationInput>;
  OrganizationPaymentSettings?: InputMaybe<OrganizationPaymentSettingsCreateNestedOneWithoutOrganizationsInput>;
  orgCalendarInviteMetadata?: InputMaybe<OrgCalendarInviteMetadataCreateNestedManyWithoutOrganizationInput>;
  OrgDefaultPipelinePerecentValue?: InputMaybe<OrgDefaultPipelinePerecentValueCreateNestedOneWithoutOrganizationInput>;
  OrgHubSpotSync?: InputMaybe<OrgHubSpotSyncCreateNestedOneWithoutOrganizationInput>;
  OrgNoEventDate?: InputMaybe<OrgNoEventDateCreateNestedManyWithoutOrganizationInput>;
  OrgSalesforceSync?: InputMaybe<OrgSalesforceSyncCreateNestedOneWithoutOrganizationInput>;
  OrgTimeslotAvailability?: InputMaybe<OrgTimeslotAvailabilityCreateNestedManyWithoutOrganizationInput>;
  outbound_close_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_dm_connect_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_lt_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_revenue_value_of_sale?: InputMaybe<Scalars['Float']['input']>;
  outbound_scheduled_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_set_rate?: InputMaybe<Scalars['Float']['input']>;
  PandadocData?: InputMaybe<PandadocDataCreateNestedOneWithoutOrganizationInput>;
  PandaDocTemplate?: InputMaybe<PandaDocTemplateCreateNestedManyWithoutOrganizationInput>;
  participant_jitter_buffer_size?: InputMaybe<JitterBufferSize>;
  preferred_goal_setting?: InputMaybe<Goalsetting>;
  prepayment?: InputMaybe<Scalars['Boolean']['input']>;
  ProductChangeLog?: InputMaybe<ProductChangeLogCreateNestedManyWithoutOrganizationInput>;
  products?: InputMaybe<ProductCreateNestedManyWithoutOrganizationInput>;
  re_cold_call_hour_delay?: InputMaybe<Scalars['Float']['input']>;
  recordingStatus?: InputMaybe<RecordingStatus>;
  release_lead_resting?: InputMaybe<Scalars['Int']['input']>;
  rep_claim_limit?: InputMaybe<Scalars['Int']['input']>;
  rep_lead_allotment?: InputMaybe<Scalars['Int']['input']>;
  rep_level_call_transfer?: InputMaybe<Scalars['Boolean']['input']>;
  reps_can_bulk_unassign?: InputMaybe<Scalars['Boolean']['input']>;
  reps_can_view_all_notes?: InputMaybe<Scalars['Boolean']['input']>;
  require_sale_notes?: InputMaybe<Scalars['Boolean']['input']>;
  resting_after_skipped_CC?: InputMaybe<Scalars['Int']['input']>;
  resting_period?: InputMaybe<Scalars['Int']['input']>;
  resting_period_after_unsuccessful_cold_call?: InputMaybe<Scalars['Float']['input']>;
  resting_period_after_unsuccessful_demo?: InputMaybe<Scalars['Float']['input']>;
  revenue_plan?: InputMaybe<RevenuePlanItemCreateNestedManyWithoutOrganizationInput>;
  Routings?: InputMaybe<RoutingCreateNestedManyWithoutOrganizationInput>;
  sale_field_options?: InputMaybe<SaleFieldOptionCreateNestedManyWithoutOrganizationInput>;
  sale_notification_email_list?: InputMaybe<OrganizationCreatesale_Notification_Email_ListInput>;
  SaleConfig?: InputMaybe<SaleConfigCreateNestedOneWithoutOrganizationInput>;
  SaleConfigPage?: InputMaybe<SaleConfigPageCreateNestedManyWithoutOrganizationInput>;
  SaleConfigSection?: InputMaybe<SaleConfigSectionCreateNestedManyWithoutOrganizationInput>;
  SaleCycles?: InputMaybe<SaleCycleCreateNestedManyWithoutOrganizationInput>;
  SaleItems?: InputMaybe<SaleItemCreateNestedManyWithoutOrganizationInput>;
  sales?: InputMaybe<SaleCreateNestedManyWithoutOrganizationInput>;
  salesforce_domain?: InputMaybe<Scalars['String']['input']>;
  salesforce_last_sync_date?: InputMaybe<Scalars['DateTime']['input']>;
  SalesForceColumn?: InputMaybe<SalesForceColumnCreateNestedManyWithoutOrganizationInput>;
  salesForceData?: InputMaybe<SalesForceDataCreateNestedOneWithoutOrganizationInput>;
  ScheduleItems?: InputMaybe<ScheduleItemCreateNestedManyWithoutOrganizationInput>;
  self_sourcing_rule?: InputMaybe<Scalars['Int']['input']>;
  send_error_log_email?: InputMaybe<Scalars['Boolean']['input']>;
  send_lead_routing_email?: InputMaybe<Scalars['Boolean']['input']>;
  sendgrid_email_domain?: InputMaybe<Scalars['String']['input']>;
  sendgrid_email_domain_id?: InputMaybe<Scalars['Int']['input']>;
  sequence?: InputMaybe<SequenceCreateNestedManyWithoutOrganzationInput>;
  SequenceCriteriaConditions?: InputMaybe<SequenceCriteriaConditionCreateNestedManyWithoutOrganizationInput>;
  SequenceDashboardViews?: InputMaybe<SequenceDashboardViewCreateNestedManyWithoutOrganizationInput>;
  SequenceEntryCriterias?: InputMaybe<SequenceEntryCriteriaCreateNestedManyWithoutOrganizationInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutOrganizationInput>;
  SequenceSteps?: InputMaybe<SequenceStepCreateNestedManyWithoutOrganizationInput>;
  set_goal?: InputMaybe<Scalars['Float']['input']>;
  short_resting?: InputMaybe<Scalars['Int']['input']>;
  show_calendar_options?: InputMaybe<Scalars['Boolean']['input']>;
  show_contract_duration_sales?: InputMaybe<Scalars['Boolean']['input']>;
  show_lead_source_to_rep?: InputMaybe<Scalars['Boolean']['input']>;
  show_nocontact_button?: InputMaybe<Scalars['Boolean']['input']>;
  show_payment_terms_sales?: InputMaybe<Scalars['Boolean']['input']>;
  show_rep_all_leads?: InputMaybe<Scalars['Boolean']['input']>;
  show_rep_my_leads?: InputMaybe<Scalars['Boolean']['input']>;
  show_system_view?: InputMaybe<Scalars['Boolean']['input']>;
  sites?: InputMaybe<SiteCreateNestedManyWithoutOrganizationInput>;
  sms_opt_in_dialog?: InputMaybe<Scalars['String']['input']>;
  SMSItems?: InputMaybe<SmsItemCreateNestedManyWithoutOrganizationInput>;
  SnoozeTrackings?: InputMaybe<SnoozeTrackingCreateNestedManyWithoutOrganizationInput>;
  StepActionLogs?: InputMaybe<StepActionLogCreateNestedManyWithoutOrganizationInput>;
  StepActions?: InputMaybe<StepActionCreateNestedManyWithoutOrganizationInput>;
  StepConditions?: InputMaybe<StepConditionCreateNestedManyWithoutOrganizationInput>;
  teams?: InputMaybe<TeamCreateNestedManyWithoutOrganizationInput>;
  Template?: InputMaybe<TemplateCreateNestedManyWithoutOrganizationInput>;
  TemplateVersions?: InputMaybe<TemplateVersionCreateNestedManyWithoutOrganizationInput>;
  timekit_id?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  transfer_timeout?: InputMaybe<Scalars['Int']['input']>;
  TransferAttempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutOrganizationInput>;
  twilio_numbers?: InputMaybe<TwilioNumberCreateNestedManyWithoutOrganizationInput>;
  TwilioFeedbacks?: InputMaybe<TwilioFeedbackCreateNestedManyWithoutOrganizationInput>;
  TwilioInfo?: InputMaybe<TwilioInfoCreateNestedOneWithoutOrganzationInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  use_revenue_planning?: InputMaybe<Scalars['Boolean']['input']>;
  UserCustomFieldFilters?: InputMaybe<UserCustomFieldFilterCreateNestedManyWithoutOrganizationInput>;
  users?: InputMaybe<UserCreateNestedManyWithoutOrganizationInput>;
  win_rep_day_goal?: InputMaybe<Scalars['Float']['input']>;
};

export type OrganizationCreateWithoutEmailItemsInput = {
  ActivityMonitorMultiplier?: InputMaybe<ActivityMonitorMultiplierCreateNestedManyWithoutOrganizationInput>;
  algoFactor?: InputMaybe<AlgoFactorCreateNestedManyWithoutOrganizationInput>;
  allow_calendar_invite?: InputMaybe<Scalars['Boolean']['input']>;
  allow_remove_step_action?: InputMaybe<Scalars['Boolean']['input']>;
  allow_snooze_step_action?: InputMaybe<Scalars['Boolean']['input']>;
  always_record?: InputMaybe<Scalars['Boolean']['input']>;
  arv_initial_held_demos_generated_by_sdr_weight?: InputMaybe<Scalars['Float']['input']>;
  AssociationHistories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutOrganizationInput>;
  auto_call_interval?: InputMaybe<Scalars['Int']['input']>;
  auto_dial?: InputMaybe<Scalars['Boolean']['input']>;
  automated_campaigns?: InputMaybe<AutomatedCampaignCreateNestedManyWithoutOrganizationInput>;
  automatic_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  AutoTransferSelections?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutOrganizationInput>;
  Availablities?: InputMaybe<AvailabilityCreateNestedManyWithoutOrganizationInput>;
  AverageOrderValue?: InputMaybe<AverageOrderValueCreateNestedManyWithoutOrganizationInput>;
  best_action_ever_multiplier?: InputMaybe<Scalars['Float']['input']>;
  best_metrics?: InputMaybe<BestMetricRecordCreateNestedManyWithoutOrganizationInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptCreateNestedManyWithoutOrganizationInput>;
  callMeNows?: InputMaybe<CallMeNowCreateNestedManyWithoutOrganizationInput>;
  CallMultiplier?: InputMaybe<CallMultiplierCreateNestedManyWithoutOrganizationInput>;
  CallSharings?: InputMaybe<CallSharingCreateNestedManyWithoutOrganizationInput>;
  can_edit_sdr_transfer_rules?: InputMaybe<Scalars['Boolean']['input']>;
  claim_rule?: InputMaybe<Scalars['Int']['input']>;
  concession?: InputMaybe<ConcessionCreateNestedManyWithoutOrganizationInput>;
  conference_region?: InputMaybe<ConferenceRegion>;
  Conferences?: InputMaybe<ConferenceCreateNestedManyWithoutOrganizationInput>;
  ContactRequests?: InputMaybe<ContactRequestCreateNestedManyWithoutOrganizationInput>;
  Contacts?: InputMaybe<ContactCreateNestedManyWithoutOrganizationInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  custom_fields?: InputMaybe<CustomFieldCreateNestedManyWithoutOrganizationInput>;
  CustomObject?: InputMaybe<CustomObjectCreateNestedManyWithoutOrganizationInput>;
  CustomObjectField?: InputMaybe<CustomObjectFieldCreateNestedManyWithoutOrganizationInput>;
  CustomObjectFieldForeignKey?: InputMaybe<CustomObjectFieldForeignKeyCreateNestedManyWithoutOrganizationInput>;
  CustomObjectRecord?: InputMaybe<CustomObjectRecordCreateNestedManyWithoutOrganizationInput>;
  CustomObjectRow?: InputMaybe<CustomObjectRowCreateNestedManyWithoutOrganizationInput>;
  CustomQueueItems?: InputMaybe<CustomQueueItemCreateNestedManyWithoutOrganizationInput>;
  DashboardColumnOptions?: InputMaybe<DashboardColumnOptionCreateNestedManyWithoutOrganizationInput>;
  DashboardLeadFilters?: InputMaybe<DashboardLeadFilterCreateNestedManyWithoutOrganizationInput>;
  DashboardRepFilters?: InputMaybe<DashboardRepFilterCreateNestedManyWithoutOrganizationInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewCreateNestedManyWithoutOrganizationInput>;
  day_to_close?: InputMaybe<Scalars['Int']['input']>;
  default_demo_time_duration?: InputMaybe<Scalars['Int']['input']>;
  demo_held_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_not_held_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_not_held_call_back_short_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_prep_time_minutes?: InputMaybe<Scalars['Int']['input']>;
  dev_account?: InputMaybe<Scalars['Boolean']['input']>;
  dial_rule?: InputMaybe<Scalars['Int']['input']>;
  DispositionMultiplier?: InputMaybe<DispositionMultiplierCreateNestedManyWithoutOrganizationInput>;
  dispositions?: InputMaybe<DispositionCreateNestedManyWithoutOrganizationInput>;
  double_booking?: InputMaybe<Scalars['Boolean']['input']>;
  dynamic_date_operators?: InputMaybe<Scalars['Boolean']['input']>;
  edge_servers?: InputMaybe<OrganizationCreateedge_ServersInput>;
  email_conflict_notification_email_list?: InputMaybe<OrganizationCreateemail_Conflict_Notification_Email_ListInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordCreateNestedManyWithoutOrganizationInput>;
  EmailThreads?: InputMaybe<EmailThreadCreateNestedManyWithoutOrganizationInput>;
  enable_modified_metrics?: InputMaybe<Scalars['Boolean']['input']>;
  enable_pay_period_view?: InputMaybe<Scalars['Boolean']['input']>;
  enable_time_constraint?: InputMaybe<Scalars['Boolean']['input']>;
  error_log_email_address?: InputMaybe<OrganizationCreateerror_Log_Email_AddressInput>;
  estimated_average_num_of_weekly_overbooked_demo_slots?: InputMaybe<Scalars['Int']['input']>;
  FolderPermissions?: InputMaybe<FolderPermissionCreateNestedManyWithoutOrganizationInput>;
  Folders?: InputMaybe<FolderCreateNestedManyWithoutOrganizationInput>;
  FutureBookingSetting?: InputMaybe<FutureBookingSettingCreateNestedOneWithoutOrganizationInput>;
  GlobalTransferRule?: InputMaybe<GlobalTransferRuleCreateNestedManyWithoutOrganizationInput>;
  held_demos_per_cc_connect?: InputMaybe<Scalars['Float']['input']>;
  helpful_links?: InputMaybe<OrganizationCreatehelpful_LinksInput>;
  HingePointMultiplier?: InputMaybe<HingePointMultiplierCreateNestedManyWithoutOrganizationInput>;
  hold_goal?: InputMaybe<Scalars['Float']['input']>;
  hubspot_credential?: InputMaybe<HubSpotCredentialCreateNestedOneWithoutOrganizationInput>;
  hubspot_last_sync_date?: InputMaybe<Scalars['DateTime']['input']>;
  hubspot_white_list?: InputMaybe<HubSpotWhitelistCreateNestedManyWithoutOrganizationInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  inbound_close_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_dm_connect_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_lt_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_revenue_value_of_sale?: InputMaybe<Scalars['Float']['input']>;
  inbound_scheduled_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_set_rate?: InputMaybe<Scalars['Float']['input']>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventCreateNestedManyWithoutOrganizationInput>;
  InboundConciergeWidget?: InputMaybe<InboundConciergeWidgetCreateNestedManyWithoutOrganizationInput>;
  industry_options?: InputMaybe<IndustryOptionCreateNestedManyWithoutOrganizationInput>;
  initial_sdr_sets_reps_day?: InputMaybe<Scalars['Float']['input']>;
  integration_error_logs?: InputMaybe<IntegrationErrorLogCreateNestedManyWithoutOrganizationInput>;
  integration_mappings?: InputMaybe<IntegrationMappingCreateNestedManyWithoutOrganizationInput>;
  IntegrationMappingCustomObject?: InputMaybe<IntegrationMappingCustomObjectCreateNestedManyWithoutOrganizationInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogCreateNestedManyWithoutOrganizationInput>;
  IntegrationReadOnlyObject?: InputMaybe<IntegrationReadOnlyObjectCreateNestedManyWithoutOrganizationInput>;
  international?: InputMaybe<Scalars['Boolean']['input']>;
  is_email_domain_verified?: InputMaybe<Scalars['Boolean']['input']>;
  is_salesforce_sandbox?: InputMaybe<Scalars['Boolean']['input']>;
  last_lead_value_compute_time?: InputMaybe<Scalars['DateTime']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutOrganizationInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryCreateNestedManyWithoutOrganizationInput>;
  lead_intent?: InputMaybe<LeadIntentCreateNestedManyWithoutOrganizationInput>;
  lead_release_same_rep_delay_rule?: InputMaybe<Scalars['Int']['input']>;
  lead_routing_email_list?: InputMaybe<OrganizationCreatelead_Routing_Email_ListInput>;
  lead_routing_rules?: InputMaybe<RuleCreateNestedManyWithoutOrganizationInput>;
  LeadCustomObjectRowAssociation?: InputMaybe<LeadCustomObjectRowAssociationCreateNestedManyWithoutOrganizationInput>;
  LeadFieldMultiplier?: InputMaybe<LeadFieldMultiplierCreateNestedManyWithoutOrganizationInput>;
  LeadFieldMultiplierCustom?: InputMaybe<LeadFieldMultiplierCustomCreateNestedManyWithoutOrganizationInput>;
  leads?: InputMaybe<LeadCreateNestedManyWithoutOrganizationInput>;
  leadsource_options?: InputMaybe<LeadSourceOptionCreateNestedManyWithoutOrganizationInput>;
  LeadValue?: InputMaybe<LeadValueCreateNestedManyWithoutOrganizationInput>;
  LifeTimeValueMultiplier?: InputMaybe<LifeTimeValueMultiplierCreateNestedManyWithoutOrganizationInput>;
  LiveTransferSetting?: InputMaybe<LiveTransferSettingCreateNestedOneWithoutOrganizationInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusCreateNestedManyWithoutOrganizationInput>;
  ltv_multiplier_custom_fields?: InputMaybe<LifeTimeValueMultiplierCustomCreateNestedManyWithoutOrganizationInput>;
  made_contact_rule?: InputMaybe<Scalars['Int']['input']>;
  make_sale_address_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_business_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_city_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_country_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_first_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_industry_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_last_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_lead_source_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_primary_email_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_primary_phone_number_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_state_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_sub_industry_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_zip_required?: InputMaybe<Scalars['Boolean']['input']>;
  maxium_day_lag_set_to_schedule?: InputMaybe<Scalars['Int']['input']>;
  mrr?: InputMaybe<Scalars['Int']['input']>;
  mrr_label?: InputMaybe<Scalars['String']['input']>;
  mrr_required_on_sale?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  NoteItems?: InputMaybe<NoteItemCreateNestedManyWithoutOrganizationInput>;
  Notifications?: InputMaybe<NotificationCreateNestedManyWithoutOrganizationInput>;
  onboardComplete?: InputMaybe<Scalars['Boolean']['input']>;
  Opportunity?: InputMaybe<SfOpportunityCreateNestedManyWithoutOrganizationInput>;
  organization_holidays?: InputMaybe<OrganizationHolidayCreateNestedManyWithoutOrganizationInput>;
  organization_hours?: InputMaybe<OrganizationHourCreateNestedManyWithoutOrganizationInput>;
  OrganizationPaymentSetting?: InputMaybe<OrganizationPaymentSettingsCreateNestedOneWithoutOrganizationInput>;
  OrganizationPaymentSettings?: InputMaybe<OrganizationPaymentSettingsCreateNestedOneWithoutOrganizationsInput>;
  orgCalendarInviteMetadata?: InputMaybe<OrgCalendarInviteMetadataCreateNestedManyWithoutOrganizationInput>;
  OrgDefaultPipelinePerecentValue?: InputMaybe<OrgDefaultPipelinePerecentValueCreateNestedOneWithoutOrganizationInput>;
  OrgHubSpotSync?: InputMaybe<OrgHubSpotSyncCreateNestedOneWithoutOrganizationInput>;
  OrgNoEventDate?: InputMaybe<OrgNoEventDateCreateNestedManyWithoutOrganizationInput>;
  OrgSalesforceSync?: InputMaybe<OrgSalesforceSyncCreateNestedOneWithoutOrganizationInput>;
  OrgTimeslotAvailability?: InputMaybe<OrgTimeslotAvailabilityCreateNestedManyWithoutOrganizationInput>;
  outbound_close_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_dm_connect_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_lt_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_revenue_value_of_sale?: InputMaybe<Scalars['Float']['input']>;
  outbound_scheduled_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_set_rate?: InputMaybe<Scalars['Float']['input']>;
  PandadocData?: InputMaybe<PandadocDataCreateNestedOneWithoutOrganizationInput>;
  PandaDocTemplate?: InputMaybe<PandaDocTemplateCreateNestedManyWithoutOrganizationInput>;
  participant_jitter_buffer_size?: InputMaybe<JitterBufferSize>;
  preferred_goal_setting?: InputMaybe<Goalsetting>;
  prepayment?: InputMaybe<Scalars['Boolean']['input']>;
  ProductChangeLog?: InputMaybe<ProductChangeLogCreateNestedManyWithoutOrganizationInput>;
  products?: InputMaybe<ProductCreateNestedManyWithoutOrganizationInput>;
  re_cold_call_hour_delay?: InputMaybe<Scalars['Float']['input']>;
  recordingStatus?: InputMaybe<RecordingStatus>;
  release_lead_resting?: InputMaybe<Scalars['Int']['input']>;
  rep_claim_limit?: InputMaybe<Scalars['Int']['input']>;
  rep_lead_allotment?: InputMaybe<Scalars['Int']['input']>;
  rep_level_call_transfer?: InputMaybe<Scalars['Boolean']['input']>;
  reps_can_bulk_unassign?: InputMaybe<Scalars['Boolean']['input']>;
  reps_can_view_all_notes?: InputMaybe<Scalars['Boolean']['input']>;
  require_sale_notes?: InputMaybe<Scalars['Boolean']['input']>;
  resting_after_skipped_CC?: InputMaybe<Scalars['Int']['input']>;
  resting_period?: InputMaybe<Scalars['Int']['input']>;
  resting_period_after_unsuccessful_cold_call?: InputMaybe<Scalars['Float']['input']>;
  resting_period_after_unsuccessful_demo?: InputMaybe<Scalars['Float']['input']>;
  revenue_plan?: InputMaybe<RevenuePlanItemCreateNestedManyWithoutOrganizationInput>;
  Routings?: InputMaybe<RoutingCreateNestedManyWithoutOrganizationInput>;
  sale_field_options?: InputMaybe<SaleFieldOptionCreateNestedManyWithoutOrganizationInput>;
  sale_notification_email_list?: InputMaybe<OrganizationCreatesale_Notification_Email_ListInput>;
  SaleConfig?: InputMaybe<SaleConfigCreateNestedOneWithoutOrganizationInput>;
  SaleConfigPage?: InputMaybe<SaleConfigPageCreateNestedManyWithoutOrganizationInput>;
  SaleConfigSection?: InputMaybe<SaleConfigSectionCreateNestedManyWithoutOrganizationInput>;
  SaleCycles?: InputMaybe<SaleCycleCreateNestedManyWithoutOrganizationInput>;
  SaleItems?: InputMaybe<SaleItemCreateNestedManyWithoutOrganizationInput>;
  sales?: InputMaybe<SaleCreateNestedManyWithoutOrganizationInput>;
  salesforce_domain?: InputMaybe<Scalars['String']['input']>;
  salesforce_last_sync_date?: InputMaybe<Scalars['DateTime']['input']>;
  SalesForceColumn?: InputMaybe<SalesForceColumnCreateNestedManyWithoutOrganizationInput>;
  salesForceData?: InputMaybe<SalesForceDataCreateNestedOneWithoutOrganizationInput>;
  ScheduleItems?: InputMaybe<ScheduleItemCreateNestedManyWithoutOrganizationInput>;
  self_sourcing_rule?: InputMaybe<Scalars['Int']['input']>;
  send_error_log_email?: InputMaybe<Scalars['Boolean']['input']>;
  send_lead_routing_email?: InputMaybe<Scalars['Boolean']['input']>;
  sendgrid_email_domain?: InputMaybe<Scalars['String']['input']>;
  sendgrid_email_domain_id?: InputMaybe<Scalars['Int']['input']>;
  sequence?: InputMaybe<SequenceCreateNestedManyWithoutOrganzationInput>;
  SequenceCriteriaConditions?: InputMaybe<SequenceCriteriaConditionCreateNestedManyWithoutOrganizationInput>;
  SequenceDashboardViews?: InputMaybe<SequenceDashboardViewCreateNestedManyWithoutOrganizationInput>;
  SequenceEntryCriterias?: InputMaybe<SequenceEntryCriteriaCreateNestedManyWithoutOrganizationInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutOrganizationInput>;
  SequenceSteps?: InputMaybe<SequenceStepCreateNestedManyWithoutOrganizationInput>;
  set_goal?: InputMaybe<Scalars['Float']['input']>;
  short_resting?: InputMaybe<Scalars['Int']['input']>;
  show_calendar_options?: InputMaybe<Scalars['Boolean']['input']>;
  show_contract_duration_sales?: InputMaybe<Scalars['Boolean']['input']>;
  show_lead_source_to_rep?: InputMaybe<Scalars['Boolean']['input']>;
  show_nocontact_button?: InputMaybe<Scalars['Boolean']['input']>;
  show_payment_terms_sales?: InputMaybe<Scalars['Boolean']['input']>;
  show_rep_all_leads?: InputMaybe<Scalars['Boolean']['input']>;
  show_rep_my_leads?: InputMaybe<Scalars['Boolean']['input']>;
  show_system_view?: InputMaybe<Scalars['Boolean']['input']>;
  sites?: InputMaybe<SiteCreateNestedManyWithoutOrganizationInput>;
  sms_opt_in_dialog?: InputMaybe<Scalars['String']['input']>;
  SMSItems?: InputMaybe<SmsItemCreateNestedManyWithoutOrganizationInput>;
  SnoozeTrackings?: InputMaybe<SnoozeTrackingCreateNestedManyWithoutOrganizationInput>;
  StepActionLogs?: InputMaybe<StepActionLogCreateNestedManyWithoutOrganizationInput>;
  StepActions?: InputMaybe<StepActionCreateNestedManyWithoutOrganizationInput>;
  StepConditions?: InputMaybe<StepConditionCreateNestedManyWithoutOrganizationInput>;
  teams?: InputMaybe<TeamCreateNestedManyWithoutOrganizationInput>;
  Template?: InputMaybe<TemplateCreateNestedManyWithoutOrganizationInput>;
  TemplateVersions?: InputMaybe<TemplateVersionCreateNestedManyWithoutOrganizationInput>;
  timekit_id?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  transfer_timeout?: InputMaybe<Scalars['Int']['input']>;
  TransferAttempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutOrganizationInput>;
  twilio_numbers?: InputMaybe<TwilioNumberCreateNestedManyWithoutOrganizationInput>;
  TwilioFeedbacks?: InputMaybe<TwilioFeedbackCreateNestedManyWithoutOrganizationInput>;
  TwilioInfo?: InputMaybe<TwilioInfoCreateNestedOneWithoutOrganzationInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  use_revenue_planning?: InputMaybe<Scalars['Boolean']['input']>;
  UserCustomFieldFilters?: InputMaybe<UserCustomFieldFilterCreateNestedManyWithoutOrganizationInput>;
  users?: InputMaybe<UserCreateNestedManyWithoutOrganizationInput>;
  win_rep_day_goal?: InputMaybe<Scalars['Float']['input']>;
};

export type OrganizationCreateWithoutEmailSyncRecordInput = {
  ActivityMonitorMultiplier?: InputMaybe<ActivityMonitorMultiplierCreateNestedManyWithoutOrganizationInput>;
  algoFactor?: InputMaybe<AlgoFactorCreateNestedManyWithoutOrganizationInput>;
  allow_calendar_invite?: InputMaybe<Scalars['Boolean']['input']>;
  allow_remove_step_action?: InputMaybe<Scalars['Boolean']['input']>;
  allow_snooze_step_action?: InputMaybe<Scalars['Boolean']['input']>;
  always_record?: InputMaybe<Scalars['Boolean']['input']>;
  arv_initial_held_demos_generated_by_sdr_weight?: InputMaybe<Scalars['Float']['input']>;
  AssociationHistories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutOrganizationInput>;
  auto_call_interval?: InputMaybe<Scalars['Int']['input']>;
  auto_dial?: InputMaybe<Scalars['Boolean']['input']>;
  automated_campaigns?: InputMaybe<AutomatedCampaignCreateNestedManyWithoutOrganizationInput>;
  automatic_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  AutoTransferSelections?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutOrganizationInput>;
  Availablities?: InputMaybe<AvailabilityCreateNestedManyWithoutOrganizationInput>;
  AverageOrderValue?: InputMaybe<AverageOrderValueCreateNestedManyWithoutOrganizationInput>;
  best_action_ever_multiplier?: InputMaybe<Scalars['Float']['input']>;
  best_metrics?: InputMaybe<BestMetricRecordCreateNestedManyWithoutOrganizationInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptCreateNestedManyWithoutOrganizationInput>;
  callMeNows?: InputMaybe<CallMeNowCreateNestedManyWithoutOrganizationInput>;
  CallMultiplier?: InputMaybe<CallMultiplierCreateNestedManyWithoutOrganizationInput>;
  CallSharings?: InputMaybe<CallSharingCreateNestedManyWithoutOrganizationInput>;
  can_edit_sdr_transfer_rules?: InputMaybe<Scalars['Boolean']['input']>;
  claim_rule?: InputMaybe<Scalars['Int']['input']>;
  concession?: InputMaybe<ConcessionCreateNestedManyWithoutOrganizationInput>;
  conference_region?: InputMaybe<ConferenceRegion>;
  Conferences?: InputMaybe<ConferenceCreateNestedManyWithoutOrganizationInput>;
  ContactRequests?: InputMaybe<ContactRequestCreateNestedManyWithoutOrganizationInput>;
  Contacts?: InputMaybe<ContactCreateNestedManyWithoutOrganizationInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  custom_fields?: InputMaybe<CustomFieldCreateNestedManyWithoutOrganizationInput>;
  CustomObject?: InputMaybe<CustomObjectCreateNestedManyWithoutOrganizationInput>;
  CustomObjectField?: InputMaybe<CustomObjectFieldCreateNestedManyWithoutOrganizationInput>;
  CustomObjectFieldForeignKey?: InputMaybe<CustomObjectFieldForeignKeyCreateNestedManyWithoutOrganizationInput>;
  CustomObjectRecord?: InputMaybe<CustomObjectRecordCreateNestedManyWithoutOrganizationInput>;
  CustomObjectRow?: InputMaybe<CustomObjectRowCreateNestedManyWithoutOrganizationInput>;
  CustomQueueItems?: InputMaybe<CustomQueueItemCreateNestedManyWithoutOrganizationInput>;
  DashboardColumnOptions?: InputMaybe<DashboardColumnOptionCreateNestedManyWithoutOrganizationInput>;
  DashboardLeadFilters?: InputMaybe<DashboardLeadFilterCreateNestedManyWithoutOrganizationInput>;
  DashboardRepFilters?: InputMaybe<DashboardRepFilterCreateNestedManyWithoutOrganizationInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewCreateNestedManyWithoutOrganizationInput>;
  day_to_close?: InputMaybe<Scalars['Int']['input']>;
  default_demo_time_duration?: InputMaybe<Scalars['Int']['input']>;
  demo_held_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_not_held_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_not_held_call_back_short_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_prep_time_minutes?: InputMaybe<Scalars['Int']['input']>;
  dev_account?: InputMaybe<Scalars['Boolean']['input']>;
  dial_rule?: InputMaybe<Scalars['Int']['input']>;
  DispositionMultiplier?: InputMaybe<DispositionMultiplierCreateNestedManyWithoutOrganizationInput>;
  dispositions?: InputMaybe<DispositionCreateNestedManyWithoutOrganizationInput>;
  double_booking?: InputMaybe<Scalars['Boolean']['input']>;
  dynamic_date_operators?: InputMaybe<Scalars['Boolean']['input']>;
  edge_servers?: InputMaybe<OrganizationCreateedge_ServersInput>;
  email_conflict_notification_email_list?: InputMaybe<OrganizationCreateemail_Conflict_Notification_Email_ListInput>;
  EmailItems?: InputMaybe<EmailItemCreateNestedManyWithoutOrganizationInput>;
  EmailThreads?: InputMaybe<EmailThreadCreateNestedManyWithoutOrganizationInput>;
  enable_modified_metrics?: InputMaybe<Scalars['Boolean']['input']>;
  enable_pay_period_view?: InputMaybe<Scalars['Boolean']['input']>;
  enable_time_constraint?: InputMaybe<Scalars['Boolean']['input']>;
  error_log_email_address?: InputMaybe<OrganizationCreateerror_Log_Email_AddressInput>;
  estimated_average_num_of_weekly_overbooked_demo_slots?: InputMaybe<Scalars['Int']['input']>;
  FolderPermissions?: InputMaybe<FolderPermissionCreateNestedManyWithoutOrganizationInput>;
  Folders?: InputMaybe<FolderCreateNestedManyWithoutOrganizationInput>;
  FutureBookingSetting?: InputMaybe<FutureBookingSettingCreateNestedOneWithoutOrganizationInput>;
  GlobalTransferRule?: InputMaybe<GlobalTransferRuleCreateNestedManyWithoutOrganizationInput>;
  held_demos_per_cc_connect?: InputMaybe<Scalars['Float']['input']>;
  helpful_links?: InputMaybe<OrganizationCreatehelpful_LinksInput>;
  HingePointMultiplier?: InputMaybe<HingePointMultiplierCreateNestedManyWithoutOrganizationInput>;
  hold_goal?: InputMaybe<Scalars['Float']['input']>;
  hubspot_credential?: InputMaybe<HubSpotCredentialCreateNestedOneWithoutOrganizationInput>;
  hubspot_last_sync_date?: InputMaybe<Scalars['DateTime']['input']>;
  hubspot_white_list?: InputMaybe<HubSpotWhitelistCreateNestedManyWithoutOrganizationInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  inbound_close_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_dm_connect_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_lt_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_revenue_value_of_sale?: InputMaybe<Scalars['Float']['input']>;
  inbound_scheduled_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_set_rate?: InputMaybe<Scalars['Float']['input']>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventCreateNestedManyWithoutOrganizationInput>;
  InboundConciergeWidget?: InputMaybe<InboundConciergeWidgetCreateNestedManyWithoutOrganizationInput>;
  industry_options?: InputMaybe<IndustryOptionCreateNestedManyWithoutOrganizationInput>;
  initial_sdr_sets_reps_day?: InputMaybe<Scalars['Float']['input']>;
  integration_error_logs?: InputMaybe<IntegrationErrorLogCreateNestedManyWithoutOrganizationInput>;
  integration_mappings?: InputMaybe<IntegrationMappingCreateNestedManyWithoutOrganizationInput>;
  IntegrationMappingCustomObject?: InputMaybe<IntegrationMappingCustomObjectCreateNestedManyWithoutOrganizationInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogCreateNestedManyWithoutOrganizationInput>;
  IntegrationReadOnlyObject?: InputMaybe<IntegrationReadOnlyObjectCreateNestedManyWithoutOrganizationInput>;
  international?: InputMaybe<Scalars['Boolean']['input']>;
  is_email_domain_verified?: InputMaybe<Scalars['Boolean']['input']>;
  is_salesforce_sandbox?: InputMaybe<Scalars['Boolean']['input']>;
  last_lead_value_compute_time?: InputMaybe<Scalars['DateTime']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutOrganizationInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryCreateNestedManyWithoutOrganizationInput>;
  lead_intent?: InputMaybe<LeadIntentCreateNestedManyWithoutOrganizationInput>;
  lead_release_same_rep_delay_rule?: InputMaybe<Scalars['Int']['input']>;
  lead_routing_email_list?: InputMaybe<OrganizationCreatelead_Routing_Email_ListInput>;
  lead_routing_rules?: InputMaybe<RuleCreateNestedManyWithoutOrganizationInput>;
  LeadCustomObjectRowAssociation?: InputMaybe<LeadCustomObjectRowAssociationCreateNestedManyWithoutOrganizationInput>;
  LeadFieldMultiplier?: InputMaybe<LeadFieldMultiplierCreateNestedManyWithoutOrganizationInput>;
  LeadFieldMultiplierCustom?: InputMaybe<LeadFieldMultiplierCustomCreateNestedManyWithoutOrganizationInput>;
  leads?: InputMaybe<LeadCreateNestedManyWithoutOrganizationInput>;
  leadsource_options?: InputMaybe<LeadSourceOptionCreateNestedManyWithoutOrganizationInput>;
  LeadValue?: InputMaybe<LeadValueCreateNestedManyWithoutOrganizationInput>;
  LifeTimeValueMultiplier?: InputMaybe<LifeTimeValueMultiplierCreateNestedManyWithoutOrganizationInput>;
  LiveTransferSetting?: InputMaybe<LiveTransferSettingCreateNestedOneWithoutOrganizationInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusCreateNestedManyWithoutOrganizationInput>;
  ltv_multiplier_custom_fields?: InputMaybe<LifeTimeValueMultiplierCustomCreateNestedManyWithoutOrganizationInput>;
  made_contact_rule?: InputMaybe<Scalars['Int']['input']>;
  make_sale_address_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_business_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_city_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_country_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_first_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_industry_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_last_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_lead_source_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_primary_email_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_primary_phone_number_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_state_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_sub_industry_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_zip_required?: InputMaybe<Scalars['Boolean']['input']>;
  maxium_day_lag_set_to_schedule?: InputMaybe<Scalars['Int']['input']>;
  mrr?: InputMaybe<Scalars['Int']['input']>;
  mrr_label?: InputMaybe<Scalars['String']['input']>;
  mrr_required_on_sale?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  NoteItems?: InputMaybe<NoteItemCreateNestedManyWithoutOrganizationInput>;
  Notifications?: InputMaybe<NotificationCreateNestedManyWithoutOrganizationInput>;
  onboardComplete?: InputMaybe<Scalars['Boolean']['input']>;
  Opportunity?: InputMaybe<SfOpportunityCreateNestedManyWithoutOrganizationInput>;
  organization_holidays?: InputMaybe<OrganizationHolidayCreateNestedManyWithoutOrganizationInput>;
  organization_hours?: InputMaybe<OrganizationHourCreateNestedManyWithoutOrganizationInput>;
  OrganizationPaymentSetting?: InputMaybe<OrganizationPaymentSettingsCreateNestedOneWithoutOrganizationInput>;
  OrganizationPaymentSettings?: InputMaybe<OrganizationPaymentSettingsCreateNestedOneWithoutOrganizationsInput>;
  orgCalendarInviteMetadata?: InputMaybe<OrgCalendarInviteMetadataCreateNestedManyWithoutOrganizationInput>;
  OrgDefaultPipelinePerecentValue?: InputMaybe<OrgDefaultPipelinePerecentValueCreateNestedOneWithoutOrganizationInput>;
  OrgHubSpotSync?: InputMaybe<OrgHubSpotSyncCreateNestedOneWithoutOrganizationInput>;
  OrgNoEventDate?: InputMaybe<OrgNoEventDateCreateNestedManyWithoutOrganizationInput>;
  OrgSalesforceSync?: InputMaybe<OrgSalesforceSyncCreateNestedOneWithoutOrganizationInput>;
  OrgTimeslotAvailability?: InputMaybe<OrgTimeslotAvailabilityCreateNestedManyWithoutOrganizationInput>;
  outbound_close_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_dm_connect_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_lt_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_revenue_value_of_sale?: InputMaybe<Scalars['Float']['input']>;
  outbound_scheduled_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_set_rate?: InputMaybe<Scalars['Float']['input']>;
  PandadocData?: InputMaybe<PandadocDataCreateNestedOneWithoutOrganizationInput>;
  PandaDocTemplate?: InputMaybe<PandaDocTemplateCreateNestedManyWithoutOrganizationInput>;
  participant_jitter_buffer_size?: InputMaybe<JitterBufferSize>;
  preferred_goal_setting?: InputMaybe<Goalsetting>;
  prepayment?: InputMaybe<Scalars['Boolean']['input']>;
  ProductChangeLog?: InputMaybe<ProductChangeLogCreateNestedManyWithoutOrganizationInput>;
  products?: InputMaybe<ProductCreateNestedManyWithoutOrganizationInput>;
  re_cold_call_hour_delay?: InputMaybe<Scalars['Float']['input']>;
  recordingStatus?: InputMaybe<RecordingStatus>;
  release_lead_resting?: InputMaybe<Scalars['Int']['input']>;
  rep_claim_limit?: InputMaybe<Scalars['Int']['input']>;
  rep_lead_allotment?: InputMaybe<Scalars['Int']['input']>;
  rep_level_call_transfer?: InputMaybe<Scalars['Boolean']['input']>;
  reps_can_bulk_unassign?: InputMaybe<Scalars['Boolean']['input']>;
  reps_can_view_all_notes?: InputMaybe<Scalars['Boolean']['input']>;
  require_sale_notes?: InputMaybe<Scalars['Boolean']['input']>;
  resting_after_skipped_CC?: InputMaybe<Scalars['Int']['input']>;
  resting_period?: InputMaybe<Scalars['Int']['input']>;
  resting_period_after_unsuccessful_cold_call?: InputMaybe<Scalars['Float']['input']>;
  resting_period_after_unsuccessful_demo?: InputMaybe<Scalars['Float']['input']>;
  revenue_plan?: InputMaybe<RevenuePlanItemCreateNestedManyWithoutOrganizationInput>;
  Routings?: InputMaybe<RoutingCreateNestedManyWithoutOrganizationInput>;
  sale_field_options?: InputMaybe<SaleFieldOptionCreateNestedManyWithoutOrganizationInput>;
  sale_notification_email_list?: InputMaybe<OrganizationCreatesale_Notification_Email_ListInput>;
  SaleConfig?: InputMaybe<SaleConfigCreateNestedOneWithoutOrganizationInput>;
  SaleConfigPage?: InputMaybe<SaleConfigPageCreateNestedManyWithoutOrganizationInput>;
  SaleConfigSection?: InputMaybe<SaleConfigSectionCreateNestedManyWithoutOrganizationInput>;
  SaleCycles?: InputMaybe<SaleCycleCreateNestedManyWithoutOrganizationInput>;
  SaleItems?: InputMaybe<SaleItemCreateNestedManyWithoutOrganizationInput>;
  sales?: InputMaybe<SaleCreateNestedManyWithoutOrganizationInput>;
  salesforce_domain?: InputMaybe<Scalars['String']['input']>;
  salesforce_last_sync_date?: InputMaybe<Scalars['DateTime']['input']>;
  SalesForceColumn?: InputMaybe<SalesForceColumnCreateNestedManyWithoutOrganizationInput>;
  salesForceData?: InputMaybe<SalesForceDataCreateNestedOneWithoutOrganizationInput>;
  ScheduleItems?: InputMaybe<ScheduleItemCreateNestedManyWithoutOrganizationInput>;
  self_sourcing_rule?: InputMaybe<Scalars['Int']['input']>;
  send_error_log_email?: InputMaybe<Scalars['Boolean']['input']>;
  send_lead_routing_email?: InputMaybe<Scalars['Boolean']['input']>;
  sendgrid_email_domain?: InputMaybe<Scalars['String']['input']>;
  sendgrid_email_domain_id?: InputMaybe<Scalars['Int']['input']>;
  sequence?: InputMaybe<SequenceCreateNestedManyWithoutOrganzationInput>;
  SequenceCriteriaConditions?: InputMaybe<SequenceCriteriaConditionCreateNestedManyWithoutOrganizationInput>;
  SequenceDashboardViews?: InputMaybe<SequenceDashboardViewCreateNestedManyWithoutOrganizationInput>;
  SequenceEntryCriterias?: InputMaybe<SequenceEntryCriteriaCreateNestedManyWithoutOrganizationInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutOrganizationInput>;
  SequenceSteps?: InputMaybe<SequenceStepCreateNestedManyWithoutOrganizationInput>;
  set_goal?: InputMaybe<Scalars['Float']['input']>;
  short_resting?: InputMaybe<Scalars['Int']['input']>;
  show_calendar_options?: InputMaybe<Scalars['Boolean']['input']>;
  show_contract_duration_sales?: InputMaybe<Scalars['Boolean']['input']>;
  show_lead_source_to_rep?: InputMaybe<Scalars['Boolean']['input']>;
  show_nocontact_button?: InputMaybe<Scalars['Boolean']['input']>;
  show_payment_terms_sales?: InputMaybe<Scalars['Boolean']['input']>;
  show_rep_all_leads?: InputMaybe<Scalars['Boolean']['input']>;
  show_rep_my_leads?: InputMaybe<Scalars['Boolean']['input']>;
  show_system_view?: InputMaybe<Scalars['Boolean']['input']>;
  sites?: InputMaybe<SiteCreateNestedManyWithoutOrganizationInput>;
  sms_opt_in_dialog?: InputMaybe<Scalars['String']['input']>;
  SMSItems?: InputMaybe<SmsItemCreateNestedManyWithoutOrganizationInput>;
  SnoozeTrackings?: InputMaybe<SnoozeTrackingCreateNestedManyWithoutOrganizationInput>;
  StepActionLogs?: InputMaybe<StepActionLogCreateNestedManyWithoutOrganizationInput>;
  StepActions?: InputMaybe<StepActionCreateNestedManyWithoutOrganizationInput>;
  StepConditions?: InputMaybe<StepConditionCreateNestedManyWithoutOrganizationInput>;
  teams?: InputMaybe<TeamCreateNestedManyWithoutOrganizationInput>;
  Template?: InputMaybe<TemplateCreateNestedManyWithoutOrganizationInput>;
  TemplateVersions?: InputMaybe<TemplateVersionCreateNestedManyWithoutOrganizationInput>;
  timekit_id?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  transfer_timeout?: InputMaybe<Scalars['Int']['input']>;
  TransferAttempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutOrganizationInput>;
  twilio_numbers?: InputMaybe<TwilioNumberCreateNestedManyWithoutOrganizationInput>;
  TwilioFeedbacks?: InputMaybe<TwilioFeedbackCreateNestedManyWithoutOrganizationInput>;
  TwilioInfo?: InputMaybe<TwilioInfoCreateNestedOneWithoutOrganzationInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  use_revenue_planning?: InputMaybe<Scalars['Boolean']['input']>;
  UserCustomFieldFilters?: InputMaybe<UserCustomFieldFilterCreateNestedManyWithoutOrganizationInput>;
  users?: InputMaybe<UserCreateNestedManyWithoutOrganizationInput>;
  win_rep_day_goal?: InputMaybe<Scalars['Float']['input']>;
};

export type OrganizationCreateWithoutEmailThreadsInput = {
  ActivityMonitorMultiplier?: InputMaybe<ActivityMonitorMultiplierCreateNestedManyWithoutOrganizationInput>;
  algoFactor?: InputMaybe<AlgoFactorCreateNestedManyWithoutOrganizationInput>;
  allow_calendar_invite?: InputMaybe<Scalars['Boolean']['input']>;
  allow_remove_step_action?: InputMaybe<Scalars['Boolean']['input']>;
  allow_snooze_step_action?: InputMaybe<Scalars['Boolean']['input']>;
  always_record?: InputMaybe<Scalars['Boolean']['input']>;
  arv_initial_held_demos_generated_by_sdr_weight?: InputMaybe<Scalars['Float']['input']>;
  AssociationHistories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutOrganizationInput>;
  auto_call_interval?: InputMaybe<Scalars['Int']['input']>;
  auto_dial?: InputMaybe<Scalars['Boolean']['input']>;
  automated_campaigns?: InputMaybe<AutomatedCampaignCreateNestedManyWithoutOrganizationInput>;
  automatic_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  AutoTransferSelections?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutOrganizationInput>;
  Availablities?: InputMaybe<AvailabilityCreateNestedManyWithoutOrganizationInput>;
  AverageOrderValue?: InputMaybe<AverageOrderValueCreateNestedManyWithoutOrganizationInput>;
  best_action_ever_multiplier?: InputMaybe<Scalars['Float']['input']>;
  best_metrics?: InputMaybe<BestMetricRecordCreateNestedManyWithoutOrganizationInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptCreateNestedManyWithoutOrganizationInput>;
  callMeNows?: InputMaybe<CallMeNowCreateNestedManyWithoutOrganizationInput>;
  CallMultiplier?: InputMaybe<CallMultiplierCreateNestedManyWithoutOrganizationInput>;
  CallSharings?: InputMaybe<CallSharingCreateNestedManyWithoutOrganizationInput>;
  can_edit_sdr_transfer_rules?: InputMaybe<Scalars['Boolean']['input']>;
  claim_rule?: InputMaybe<Scalars['Int']['input']>;
  concession?: InputMaybe<ConcessionCreateNestedManyWithoutOrganizationInput>;
  conference_region?: InputMaybe<ConferenceRegion>;
  Conferences?: InputMaybe<ConferenceCreateNestedManyWithoutOrganizationInput>;
  ContactRequests?: InputMaybe<ContactRequestCreateNestedManyWithoutOrganizationInput>;
  Contacts?: InputMaybe<ContactCreateNestedManyWithoutOrganizationInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  custom_fields?: InputMaybe<CustomFieldCreateNestedManyWithoutOrganizationInput>;
  CustomObject?: InputMaybe<CustomObjectCreateNestedManyWithoutOrganizationInput>;
  CustomObjectField?: InputMaybe<CustomObjectFieldCreateNestedManyWithoutOrganizationInput>;
  CustomObjectFieldForeignKey?: InputMaybe<CustomObjectFieldForeignKeyCreateNestedManyWithoutOrganizationInput>;
  CustomObjectRecord?: InputMaybe<CustomObjectRecordCreateNestedManyWithoutOrganizationInput>;
  CustomObjectRow?: InputMaybe<CustomObjectRowCreateNestedManyWithoutOrganizationInput>;
  CustomQueueItems?: InputMaybe<CustomQueueItemCreateNestedManyWithoutOrganizationInput>;
  DashboardColumnOptions?: InputMaybe<DashboardColumnOptionCreateNestedManyWithoutOrganizationInput>;
  DashboardLeadFilters?: InputMaybe<DashboardLeadFilterCreateNestedManyWithoutOrganizationInput>;
  DashboardRepFilters?: InputMaybe<DashboardRepFilterCreateNestedManyWithoutOrganizationInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewCreateNestedManyWithoutOrganizationInput>;
  day_to_close?: InputMaybe<Scalars['Int']['input']>;
  default_demo_time_duration?: InputMaybe<Scalars['Int']['input']>;
  demo_held_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_not_held_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_not_held_call_back_short_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_prep_time_minutes?: InputMaybe<Scalars['Int']['input']>;
  dev_account?: InputMaybe<Scalars['Boolean']['input']>;
  dial_rule?: InputMaybe<Scalars['Int']['input']>;
  DispositionMultiplier?: InputMaybe<DispositionMultiplierCreateNestedManyWithoutOrganizationInput>;
  dispositions?: InputMaybe<DispositionCreateNestedManyWithoutOrganizationInput>;
  double_booking?: InputMaybe<Scalars['Boolean']['input']>;
  dynamic_date_operators?: InputMaybe<Scalars['Boolean']['input']>;
  edge_servers?: InputMaybe<OrganizationCreateedge_ServersInput>;
  email_conflict_notification_email_list?: InputMaybe<OrganizationCreateemail_Conflict_Notification_Email_ListInput>;
  EmailItems?: InputMaybe<EmailItemCreateNestedManyWithoutOrganizationInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordCreateNestedManyWithoutOrganizationInput>;
  enable_modified_metrics?: InputMaybe<Scalars['Boolean']['input']>;
  enable_pay_period_view?: InputMaybe<Scalars['Boolean']['input']>;
  enable_time_constraint?: InputMaybe<Scalars['Boolean']['input']>;
  error_log_email_address?: InputMaybe<OrganizationCreateerror_Log_Email_AddressInput>;
  estimated_average_num_of_weekly_overbooked_demo_slots?: InputMaybe<Scalars['Int']['input']>;
  FolderPermissions?: InputMaybe<FolderPermissionCreateNestedManyWithoutOrganizationInput>;
  Folders?: InputMaybe<FolderCreateNestedManyWithoutOrganizationInput>;
  FutureBookingSetting?: InputMaybe<FutureBookingSettingCreateNestedOneWithoutOrganizationInput>;
  GlobalTransferRule?: InputMaybe<GlobalTransferRuleCreateNestedManyWithoutOrganizationInput>;
  held_demos_per_cc_connect?: InputMaybe<Scalars['Float']['input']>;
  helpful_links?: InputMaybe<OrganizationCreatehelpful_LinksInput>;
  HingePointMultiplier?: InputMaybe<HingePointMultiplierCreateNestedManyWithoutOrganizationInput>;
  hold_goal?: InputMaybe<Scalars['Float']['input']>;
  hubspot_credential?: InputMaybe<HubSpotCredentialCreateNestedOneWithoutOrganizationInput>;
  hubspot_last_sync_date?: InputMaybe<Scalars['DateTime']['input']>;
  hubspot_white_list?: InputMaybe<HubSpotWhitelistCreateNestedManyWithoutOrganizationInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  inbound_close_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_dm_connect_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_lt_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_revenue_value_of_sale?: InputMaybe<Scalars['Float']['input']>;
  inbound_scheduled_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_set_rate?: InputMaybe<Scalars['Float']['input']>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventCreateNestedManyWithoutOrganizationInput>;
  InboundConciergeWidget?: InputMaybe<InboundConciergeWidgetCreateNestedManyWithoutOrganizationInput>;
  industry_options?: InputMaybe<IndustryOptionCreateNestedManyWithoutOrganizationInput>;
  initial_sdr_sets_reps_day?: InputMaybe<Scalars['Float']['input']>;
  integration_error_logs?: InputMaybe<IntegrationErrorLogCreateNestedManyWithoutOrganizationInput>;
  integration_mappings?: InputMaybe<IntegrationMappingCreateNestedManyWithoutOrganizationInput>;
  IntegrationMappingCustomObject?: InputMaybe<IntegrationMappingCustomObjectCreateNestedManyWithoutOrganizationInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogCreateNestedManyWithoutOrganizationInput>;
  IntegrationReadOnlyObject?: InputMaybe<IntegrationReadOnlyObjectCreateNestedManyWithoutOrganizationInput>;
  international?: InputMaybe<Scalars['Boolean']['input']>;
  is_email_domain_verified?: InputMaybe<Scalars['Boolean']['input']>;
  is_salesforce_sandbox?: InputMaybe<Scalars['Boolean']['input']>;
  last_lead_value_compute_time?: InputMaybe<Scalars['DateTime']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutOrganizationInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryCreateNestedManyWithoutOrganizationInput>;
  lead_intent?: InputMaybe<LeadIntentCreateNestedManyWithoutOrganizationInput>;
  lead_release_same_rep_delay_rule?: InputMaybe<Scalars['Int']['input']>;
  lead_routing_email_list?: InputMaybe<OrganizationCreatelead_Routing_Email_ListInput>;
  lead_routing_rules?: InputMaybe<RuleCreateNestedManyWithoutOrganizationInput>;
  LeadCustomObjectRowAssociation?: InputMaybe<LeadCustomObjectRowAssociationCreateNestedManyWithoutOrganizationInput>;
  LeadFieldMultiplier?: InputMaybe<LeadFieldMultiplierCreateNestedManyWithoutOrganizationInput>;
  LeadFieldMultiplierCustom?: InputMaybe<LeadFieldMultiplierCustomCreateNestedManyWithoutOrganizationInput>;
  leads?: InputMaybe<LeadCreateNestedManyWithoutOrganizationInput>;
  leadsource_options?: InputMaybe<LeadSourceOptionCreateNestedManyWithoutOrganizationInput>;
  LeadValue?: InputMaybe<LeadValueCreateNestedManyWithoutOrganizationInput>;
  LifeTimeValueMultiplier?: InputMaybe<LifeTimeValueMultiplierCreateNestedManyWithoutOrganizationInput>;
  LiveTransferSetting?: InputMaybe<LiveTransferSettingCreateNestedOneWithoutOrganizationInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusCreateNestedManyWithoutOrganizationInput>;
  ltv_multiplier_custom_fields?: InputMaybe<LifeTimeValueMultiplierCustomCreateNestedManyWithoutOrganizationInput>;
  made_contact_rule?: InputMaybe<Scalars['Int']['input']>;
  make_sale_address_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_business_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_city_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_country_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_first_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_industry_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_last_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_lead_source_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_primary_email_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_primary_phone_number_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_state_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_sub_industry_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_zip_required?: InputMaybe<Scalars['Boolean']['input']>;
  maxium_day_lag_set_to_schedule?: InputMaybe<Scalars['Int']['input']>;
  mrr?: InputMaybe<Scalars['Int']['input']>;
  mrr_label?: InputMaybe<Scalars['String']['input']>;
  mrr_required_on_sale?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  NoteItems?: InputMaybe<NoteItemCreateNestedManyWithoutOrganizationInput>;
  Notifications?: InputMaybe<NotificationCreateNestedManyWithoutOrganizationInput>;
  onboardComplete?: InputMaybe<Scalars['Boolean']['input']>;
  Opportunity?: InputMaybe<SfOpportunityCreateNestedManyWithoutOrganizationInput>;
  organization_holidays?: InputMaybe<OrganizationHolidayCreateNestedManyWithoutOrganizationInput>;
  organization_hours?: InputMaybe<OrganizationHourCreateNestedManyWithoutOrganizationInput>;
  OrganizationPaymentSetting?: InputMaybe<OrganizationPaymentSettingsCreateNestedOneWithoutOrganizationInput>;
  OrganizationPaymentSettings?: InputMaybe<OrganizationPaymentSettingsCreateNestedOneWithoutOrganizationsInput>;
  orgCalendarInviteMetadata?: InputMaybe<OrgCalendarInviteMetadataCreateNestedManyWithoutOrganizationInput>;
  OrgDefaultPipelinePerecentValue?: InputMaybe<OrgDefaultPipelinePerecentValueCreateNestedOneWithoutOrganizationInput>;
  OrgHubSpotSync?: InputMaybe<OrgHubSpotSyncCreateNestedOneWithoutOrganizationInput>;
  OrgNoEventDate?: InputMaybe<OrgNoEventDateCreateNestedManyWithoutOrganizationInput>;
  OrgSalesforceSync?: InputMaybe<OrgSalesforceSyncCreateNestedOneWithoutOrganizationInput>;
  OrgTimeslotAvailability?: InputMaybe<OrgTimeslotAvailabilityCreateNestedManyWithoutOrganizationInput>;
  outbound_close_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_dm_connect_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_lt_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_revenue_value_of_sale?: InputMaybe<Scalars['Float']['input']>;
  outbound_scheduled_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_set_rate?: InputMaybe<Scalars['Float']['input']>;
  PandadocData?: InputMaybe<PandadocDataCreateNestedOneWithoutOrganizationInput>;
  PandaDocTemplate?: InputMaybe<PandaDocTemplateCreateNestedManyWithoutOrganizationInput>;
  participant_jitter_buffer_size?: InputMaybe<JitterBufferSize>;
  preferred_goal_setting?: InputMaybe<Goalsetting>;
  prepayment?: InputMaybe<Scalars['Boolean']['input']>;
  ProductChangeLog?: InputMaybe<ProductChangeLogCreateNestedManyWithoutOrganizationInput>;
  products?: InputMaybe<ProductCreateNestedManyWithoutOrganizationInput>;
  re_cold_call_hour_delay?: InputMaybe<Scalars['Float']['input']>;
  recordingStatus?: InputMaybe<RecordingStatus>;
  release_lead_resting?: InputMaybe<Scalars['Int']['input']>;
  rep_claim_limit?: InputMaybe<Scalars['Int']['input']>;
  rep_lead_allotment?: InputMaybe<Scalars['Int']['input']>;
  rep_level_call_transfer?: InputMaybe<Scalars['Boolean']['input']>;
  reps_can_bulk_unassign?: InputMaybe<Scalars['Boolean']['input']>;
  reps_can_view_all_notes?: InputMaybe<Scalars['Boolean']['input']>;
  require_sale_notes?: InputMaybe<Scalars['Boolean']['input']>;
  resting_after_skipped_CC?: InputMaybe<Scalars['Int']['input']>;
  resting_period?: InputMaybe<Scalars['Int']['input']>;
  resting_period_after_unsuccessful_cold_call?: InputMaybe<Scalars['Float']['input']>;
  resting_period_after_unsuccessful_demo?: InputMaybe<Scalars['Float']['input']>;
  revenue_plan?: InputMaybe<RevenuePlanItemCreateNestedManyWithoutOrganizationInput>;
  Routings?: InputMaybe<RoutingCreateNestedManyWithoutOrganizationInput>;
  sale_field_options?: InputMaybe<SaleFieldOptionCreateNestedManyWithoutOrganizationInput>;
  sale_notification_email_list?: InputMaybe<OrganizationCreatesale_Notification_Email_ListInput>;
  SaleConfig?: InputMaybe<SaleConfigCreateNestedOneWithoutOrganizationInput>;
  SaleConfigPage?: InputMaybe<SaleConfigPageCreateNestedManyWithoutOrganizationInput>;
  SaleConfigSection?: InputMaybe<SaleConfigSectionCreateNestedManyWithoutOrganizationInput>;
  SaleCycles?: InputMaybe<SaleCycleCreateNestedManyWithoutOrganizationInput>;
  SaleItems?: InputMaybe<SaleItemCreateNestedManyWithoutOrganizationInput>;
  sales?: InputMaybe<SaleCreateNestedManyWithoutOrganizationInput>;
  salesforce_domain?: InputMaybe<Scalars['String']['input']>;
  salesforce_last_sync_date?: InputMaybe<Scalars['DateTime']['input']>;
  SalesForceColumn?: InputMaybe<SalesForceColumnCreateNestedManyWithoutOrganizationInput>;
  salesForceData?: InputMaybe<SalesForceDataCreateNestedOneWithoutOrganizationInput>;
  ScheduleItems?: InputMaybe<ScheduleItemCreateNestedManyWithoutOrganizationInput>;
  self_sourcing_rule?: InputMaybe<Scalars['Int']['input']>;
  send_error_log_email?: InputMaybe<Scalars['Boolean']['input']>;
  send_lead_routing_email?: InputMaybe<Scalars['Boolean']['input']>;
  sendgrid_email_domain?: InputMaybe<Scalars['String']['input']>;
  sendgrid_email_domain_id?: InputMaybe<Scalars['Int']['input']>;
  sequence?: InputMaybe<SequenceCreateNestedManyWithoutOrganzationInput>;
  SequenceCriteriaConditions?: InputMaybe<SequenceCriteriaConditionCreateNestedManyWithoutOrganizationInput>;
  SequenceDashboardViews?: InputMaybe<SequenceDashboardViewCreateNestedManyWithoutOrganizationInput>;
  SequenceEntryCriterias?: InputMaybe<SequenceEntryCriteriaCreateNestedManyWithoutOrganizationInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutOrganizationInput>;
  SequenceSteps?: InputMaybe<SequenceStepCreateNestedManyWithoutOrganizationInput>;
  set_goal?: InputMaybe<Scalars['Float']['input']>;
  short_resting?: InputMaybe<Scalars['Int']['input']>;
  show_calendar_options?: InputMaybe<Scalars['Boolean']['input']>;
  show_contract_duration_sales?: InputMaybe<Scalars['Boolean']['input']>;
  show_lead_source_to_rep?: InputMaybe<Scalars['Boolean']['input']>;
  show_nocontact_button?: InputMaybe<Scalars['Boolean']['input']>;
  show_payment_terms_sales?: InputMaybe<Scalars['Boolean']['input']>;
  show_rep_all_leads?: InputMaybe<Scalars['Boolean']['input']>;
  show_rep_my_leads?: InputMaybe<Scalars['Boolean']['input']>;
  show_system_view?: InputMaybe<Scalars['Boolean']['input']>;
  sites?: InputMaybe<SiteCreateNestedManyWithoutOrganizationInput>;
  sms_opt_in_dialog?: InputMaybe<Scalars['String']['input']>;
  SMSItems?: InputMaybe<SmsItemCreateNestedManyWithoutOrganizationInput>;
  SnoozeTrackings?: InputMaybe<SnoozeTrackingCreateNestedManyWithoutOrganizationInput>;
  StepActionLogs?: InputMaybe<StepActionLogCreateNestedManyWithoutOrganizationInput>;
  StepActions?: InputMaybe<StepActionCreateNestedManyWithoutOrganizationInput>;
  StepConditions?: InputMaybe<StepConditionCreateNestedManyWithoutOrganizationInput>;
  teams?: InputMaybe<TeamCreateNestedManyWithoutOrganizationInput>;
  Template?: InputMaybe<TemplateCreateNestedManyWithoutOrganizationInput>;
  TemplateVersions?: InputMaybe<TemplateVersionCreateNestedManyWithoutOrganizationInput>;
  timekit_id?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  transfer_timeout?: InputMaybe<Scalars['Int']['input']>;
  TransferAttempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutOrganizationInput>;
  twilio_numbers?: InputMaybe<TwilioNumberCreateNestedManyWithoutOrganizationInput>;
  TwilioFeedbacks?: InputMaybe<TwilioFeedbackCreateNestedManyWithoutOrganizationInput>;
  TwilioInfo?: InputMaybe<TwilioInfoCreateNestedOneWithoutOrganzationInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  use_revenue_planning?: InputMaybe<Scalars['Boolean']['input']>;
  UserCustomFieldFilters?: InputMaybe<UserCustomFieldFilterCreateNestedManyWithoutOrganizationInput>;
  users?: InputMaybe<UserCreateNestedManyWithoutOrganizationInput>;
  win_rep_day_goal?: InputMaybe<Scalars['Float']['input']>;
};

export type OrganizationCreateWithoutFolderPermissionsInput = {
  ActivityMonitorMultiplier?: InputMaybe<ActivityMonitorMultiplierCreateNestedManyWithoutOrganizationInput>;
  algoFactor?: InputMaybe<AlgoFactorCreateNestedManyWithoutOrganizationInput>;
  allow_calendar_invite?: InputMaybe<Scalars['Boolean']['input']>;
  allow_remove_step_action?: InputMaybe<Scalars['Boolean']['input']>;
  allow_snooze_step_action?: InputMaybe<Scalars['Boolean']['input']>;
  always_record?: InputMaybe<Scalars['Boolean']['input']>;
  arv_initial_held_demos_generated_by_sdr_weight?: InputMaybe<Scalars['Float']['input']>;
  AssociationHistories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutOrganizationInput>;
  auto_call_interval?: InputMaybe<Scalars['Int']['input']>;
  auto_dial?: InputMaybe<Scalars['Boolean']['input']>;
  automated_campaigns?: InputMaybe<AutomatedCampaignCreateNestedManyWithoutOrganizationInput>;
  automatic_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  AutoTransferSelections?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutOrganizationInput>;
  Availablities?: InputMaybe<AvailabilityCreateNestedManyWithoutOrganizationInput>;
  AverageOrderValue?: InputMaybe<AverageOrderValueCreateNestedManyWithoutOrganizationInput>;
  best_action_ever_multiplier?: InputMaybe<Scalars['Float']['input']>;
  best_metrics?: InputMaybe<BestMetricRecordCreateNestedManyWithoutOrganizationInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptCreateNestedManyWithoutOrganizationInput>;
  callMeNows?: InputMaybe<CallMeNowCreateNestedManyWithoutOrganizationInput>;
  CallMultiplier?: InputMaybe<CallMultiplierCreateNestedManyWithoutOrganizationInput>;
  CallSharings?: InputMaybe<CallSharingCreateNestedManyWithoutOrganizationInput>;
  can_edit_sdr_transfer_rules?: InputMaybe<Scalars['Boolean']['input']>;
  claim_rule?: InputMaybe<Scalars['Int']['input']>;
  concession?: InputMaybe<ConcessionCreateNestedManyWithoutOrganizationInput>;
  conference_region?: InputMaybe<ConferenceRegion>;
  Conferences?: InputMaybe<ConferenceCreateNestedManyWithoutOrganizationInput>;
  ContactRequests?: InputMaybe<ContactRequestCreateNestedManyWithoutOrganizationInput>;
  Contacts?: InputMaybe<ContactCreateNestedManyWithoutOrganizationInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  custom_fields?: InputMaybe<CustomFieldCreateNestedManyWithoutOrganizationInput>;
  CustomObject?: InputMaybe<CustomObjectCreateNestedManyWithoutOrganizationInput>;
  CustomObjectField?: InputMaybe<CustomObjectFieldCreateNestedManyWithoutOrganizationInput>;
  CustomObjectFieldForeignKey?: InputMaybe<CustomObjectFieldForeignKeyCreateNestedManyWithoutOrganizationInput>;
  CustomObjectRecord?: InputMaybe<CustomObjectRecordCreateNestedManyWithoutOrganizationInput>;
  CustomObjectRow?: InputMaybe<CustomObjectRowCreateNestedManyWithoutOrganizationInput>;
  CustomQueueItems?: InputMaybe<CustomQueueItemCreateNestedManyWithoutOrganizationInput>;
  DashboardColumnOptions?: InputMaybe<DashboardColumnOptionCreateNestedManyWithoutOrganizationInput>;
  DashboardLeadFilters?: InputMaybe<DashboardLeadFilterCreateNestedManyWithoutOrganizationInput>;
  DashboardRepFilters?: InputMaybe<DashboardRepFilterCreateNestedManyWithoutOrganizationInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewCreateNestedManyWithoutOrganizationInput>;
  day_to_close?: InputMaybe<Scalars['Int']['input']>;
  default_demo_time_duration?: InputMaybe<Scalars['Int']['input']>;
  demo_held_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_not_held_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_not_held_call_back_short_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_prep_time_minutes?: InputMaybe<Scalars['Int']['input']>;
  dev_account?: InputMaybe<Scalars['Boolean']['input']>;
  dial_rule?: InputMaybe<Scalars['Int']['input']>;
  DispositionMultiplier?: InputMaybe<DispositionMultiplierCreateNestedManyWithoutOrganizationInput>;
  dispositions?: InputMaybe<DispositionCreateNestedManyWithoutOrganizationInput>;
  double_booking?: InputMaybe<Scalars['Boolean']['input']>;
  dynamic_date_operators?: InputMaybe<Scalars['Boolean']['input']>;
  edge_servers?: InputMaybe<OrganizationCreateedge_ServersInput>;
  email_conflict_notification_email_list?: InputMaybe<OrganizationCreateemail_Conflict_Notification_Email_ListInput>;
  EmailItems?: InputMaybe<EmailItemCreateNestedManyWithoutOrganizationInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordCreateNestedManyWithoutOrganizationInput>;
  EmailThreads?: InputMaybe<EmailThreadCreateNestedManyWithoutOrganizationInput>;
  enable_modified_metrics?: InputMaybe<Scalars['Boolean']['input']>;
  enable_pay_period_view?: InputMaybe<Scalars['Boolean']['input']>;
  enable_time_constraint?: InputMaybe<Scalars['Boolean']['input']>;
  error_log_email_address?: InputMaybe<OrganizationCreateerror_Log_Email_AddressInput>;
  estimated_average_num_of_weekly_overbooked_demo_slots?: InputMaybe<Scalars['Int']['input']>;
  Folders?: InputMaybe<FolderCreateNestedManyWithoutOrganizationInput>;
  FutureBookingSetting?: InputMaybe<FutureBookingSettingCreateNestedOneWithoutOrganizationInput>;
  GlobalTransferRule?: InputMaybe<GlobalTransferRuleCreateNestedManyWithoutOrganizationInput>;
  held_demos_per_cc_connect?: InputMaybe<Scalars['Float']['input']>;
  helpful_links?: InputMaybe<OrganizationCreatehelpful_LinksInput>;
  HingePointMultiplier?: InputMaybe<HingePointMultiplierCreateNestedManyWithoutOrganizationInput>;
  hold_goal?: InputMaybe<Scalars['Float']['input']>;
  hubspot_credential?: InputMaybe<HubSpotCredentialCreateNestedOneWithoutOrganizationInput>;
  hubspot_last_sync_date?: InputMaybe<Scalars['DateTime']['input']>;
  hubspot_white_list?: InputMaybe<HubSpotWhitelistCreateNestedManyWithoutOrganizationInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  inbound_close_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_dm_connect_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_lt_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_revenue_value_of_sale?: InputMaybe<Scalars['Float']['input']>;
  inbound_scheduled_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_set_rate?: InputMaybe<Scalars['Float']['input']>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventCreateNestedManyWithoutOrganizationInput>;
  InboundConciergeWidget?: InputMaybe<InboundConciergeWidgetCreateNestedManyWithoutOrganizationInput>;
  industry_options?: InputMaybe<IndustryOptionCreateNestedManyWithoutOrganizationInput>;
  initial_sdr_sets_reps_day?: InputMaybe<Scalars['Float']['input']>;
  integration_error_logs?: InputMaybe<IntegrationErrorLogCreateNestedManyWithoutOrganizationInput>;
  integration_mappings?: InputMaybe<IntegrationMappingCreateNestedManyWithoutOrganizationInput>;
  IntegrationMappingCustomObject?: InputMaybe<IntegrationMappingCustomObjectCreateNestedManyWithoutOrganizationInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogCreateNestedManyWithoutOrganizationInput>;
  IntegrationReadOnlyObject?: InputMaybe<IntegrationReadOnlyObjectCreateNestedManyWithoutOrganizationInput>;
  international?: InputMaybe<Scalars['Boolean']['input']>;
  is_email_domain_verified?: InputMaybe<Scalars['Boolean']['input']>;
  is_salesforce_sandbox?: InputMaybe<Scalars['Boolean']['input']>;
  last_lead_value_compute_time?: InputMaybe<Scalars['DateTime']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutOrganizationInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryCreateNestedManyWithoutOrganizationInput>;
  lead_intent?: InputMaybe<LeadIntentCreateNestedManyWithoutOrganizationInput>;
  lead_release_same_rep_delay_rule?: InputMaybe<Scalars['Int']['input']>;
  lead_routing_email_list?: InputMaybe<OrganizationCreatelead_Routing_Email_ListInput>;
  lead_routing_rules?: InputMaybe<RuleCreateNestedManyWithoutOrganizationInput>;
  LeadCustomObjectRowAssociation?: InputMaybe<LeadCustomObjectRowAssociationCreateNestedManyWithoutOrganizationInput>;
  LeadFieldMultiplier?: InputMaybe<LeadFieldMultiplierCreateNestedManyWithoutOrganizationInput>;
  LeadFieldMultiplierCustom?: InputMaybe<LeadFieldMultiplierCustomCreateNestedManyWithoutOrganizationInput>;
  leads?: InputMaybe<LeadCreateNestedManyWithoutOrganizationInput>;
  leadsource_options?: InputMaybe<LeadSourceOptionCreateNestedManyWithoutOrganizationInput>;
  LeadValue?: InputMaybe<LeadValueCreateNestedManyWithoutOrganizationInput>;
  LifeTimeValueMultiplier?: InputMaybe<LifeTimeValueMultiplierCreateNestedManyWithoutOrganizationInput>;
  LiveTransferSetting?: InputMaybe<LiveTransferSettingCreateNestedOneWithoutOrganizationInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusCreateNestedManyWithoutOrganizationInput>;
  ltv_multiplier_custom_fields?: InputMaybe<LifeTimeValueMultiplierCustomCreateNestedManyWithoutOrganizationInput>;
  made_contact_rule?: InputMaybe<Scalars['Int']['input']>;
  make_sale_address_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_business_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_city_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_country_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_first_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_industry_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_last_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_lead_source_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_primary_email_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_primary_phone_number_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_state_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_sub_industry_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_zip_required?: InputMaybe<Scalars['Boolean']['input']>;
  maxium_day_lag_set_to_schedule?: InputMaybe<Scalars['Int']['input']>;
  mrr?: InputMaybe<Scalars['Int']['input']>;
  mrr_label?: InputMaybe<Scalars['String']['input']>;
  mrr_required_on_sale?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  NoteItems?: InputMaybe<NoteItemCreateNestedManyWithoutOrganizationInput>;
  Notifications?: InputMaybe<NotificationCreateNestedManyWithoutOrganizationInput>;
  onboardComplete?: InputMaybe<Scalars['Boolean']['input']>;
  Opportunity?: InputMaybe<SfOpportunityCreateNestedManyWithoutOrganizationInput>;
  organization_holidays?: InputMaybe<OrganizationHolidayCreateNestedManyWithoutOrganizationInput>;
  organization_hours?: InputMaybe<OrganizationHourCreateNestedManyWithoutOrganizationInput>;
  OrganizationPaymentSetting?: InputMaybe<OrganizationPaymentSettingsCreateNestedOneWithoutOrganizationInput>;
  OrganizationPaymentSettings?: InputMaybe<OrganizationPaymentSettingsCreateNestedOneWithoutOrganizationsInput>;
  orgCalendarInviteMetadata?: InputMaybe<OrgCalendarInviteMetadataCreateNestedManyWithoutOrganizationInput>;
  OrgDefaultPipelinePerecentValue?: InputMaybe<OrgDefaultPipelinePerecentValueCreateNestedOneWithoutOrganizationInput>;
  OrgHubSpotSync?: InputMaybe<OrgHubSpotSyncCreateNestedOneWithoutOrganizationInput>;
  OrgNoEventDate?: InputMaybe<OrgNoEventDateCreateNestedManyWithoutOrganizationInput>;
  OrgSalesforceSync?: InputMaybe<OrgSalesforceSyncCreateNestedOneWithoutOrganizationInput>;
  OrgTimeslotAvailability?: InputMaybe<OrgTimeslotAvailabilityCreateNestedManyWithoutOrganizationInput>;
  outbound_close_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_dm_connect_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_lt_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_revenue_value_of_sale?: InputMaybe<Scalars['Float']['input']>;
  outbound_scheduled_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_set_rate?: InputMaybe<Scalars['Float']['input']>;
  PandadocData?: InputMaybe<PandadocDataCreateNestedOneWithoutOrganizationInput>;
  PandaDocTemplate?: InputMaybe<PandaDocTemplateCreateNestedManyWithoutOrganizationInput>;
  participant_jitter_buffer_size?: InputMaybe<JitterBufferSize>;
  preferred_goal_setting?: InputMaybe<Goalsetting>;
  prepayment?: InputMaybe<Scalars['Boolean']['input']>;
  ProductChangeLog?: InputMaybe<ProductChangeLogCreateNestedManyWithoutOrganizationInput>;
  products?: InputMaybe<ProductCreateNestedManyWithoutOrganizationInput>;
  re_cold_call_hour_delay?: InputMaybe<Scalars['Float']['input']>;
  recordingStatus?: InputMaybe<RecordingStatus>;
  release_lead_resting?: InputMaybe<Scalars['Int']['input']>;
  rep_claim_limit?: InputMaybe<Scalars['Int']['input']>;
  rep_lead_allotment?: InputMaybe<Scalars['Int']['input']>;
  rep_level_call_transfer?: InputMaybe<Scalars['Boolean']['input']>;
  reps_can_bulk_unassign?: InputMaybe<Scalars['Boolean']['input']>;
  reps_can_view_all_notes?: InputMaybe<Scalars['Boolean']['input']>;
  require_sale_notes?: InputMaybe<Scalars['Boolean']['input']>;
  resting_after_skipped_CC?: InputMaybe<Scalars['Int']['input']>;
  resting_period?: InputMaybe<Scalars['Int']['input']>;
  resting_period_after_unsuccessful_cold_call?: InputMaybe<Scalars['Float']['input']>;
  resting_period_after_unsuccessful_demo?: InputMaybe<Scalars['Float']['input']>;
  revenue_plan?: InputMaybe<RevenuePlanItemCreateNestedManyWithoutOrganizationInput>;
  Routings?: InputMaybe<RoutingCreateNestedManyWithoutOrganizationInput>;
  sale_field_options?: InputMaybe<SaleFieldOptionCreateNestedManyWithoutOrganizationInput>;
  sale_notification_email_list?: InputMaybe<OrganizationCreatesale_Notification_Email_ListInput>;
  SaleConfig?: InputMaybe<SaleConfigCreateNestedOneWithoutOrganizationInput>;
  SaleConfigPage?: InputMaybe<SaleConfigPageCreateNestedManyWithoutOrganizationInput>;
  SaleConfigSection?: InputMaybe<SaleConfigSectionCreateNestedManyWithoutOrganizationInput>;
  SaleCycles?: InputMaybe<SaleCycleCreateNestedManyWithoutOrganizationInput>;
  SaleItems?: InputMaybe<SaleItemCreateNestedManyWithoutOrganizationInput>;
  sales?: InputMaybe<SaleCreateNestedManyWithoutOrganizationInput>;
  salesforce_domain?: InputMaybe<Scalars['String']['input']>;
  salesforce_last_sync_date?: InputMaybe<Scalars['DateTime']['input']>;
  SalesForceColumn?: InputMaybe<SalesForceColumnCreateNestedManyWithoutOrganizationInput>;
  salesForceData?: InputMaybe<SalesForceDataCreateNestedOneWithoutOrganizationInput>;
  ScheduleItems?: InputMaybe<ScheduleItemCreateNestedManyWithoutOrganizationInput>;
  self_sourcing_rule?: InputMaybe<Scalars['Int']['input']>;
  send_error_log_email?: InputMaybe<Scalars['Boolean']['input']>;
  send_lead_routing_email?: InputMaybe<Scalars['Boolean']['input']>;
  sendgrid_email_domain?: InputMaybe<Scalars['String']['input']>;
  sendgrid_email_domain_id?: InputMaybe<Scalars['Int']['input']>;
  sequence?: InputMaybe<SequenceCreateNestedManyWithoutOrganzationInput>;
  SequenceCriteriaConditions?: InputMaybe<SequenceCriteriaConditionCreateNestedManyWithoutOrganizationInput>;
  SequenceDashboardViews?: InputMaybe<SequenceDashboardViewCreateNestedManyWithoutOrganizationInput>;
  SequenceEntryCriterias?: InputMaybe<SequenceEntryCriteriaCreateNestedManyWithoutOrganizationInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutOrganizationInput>;
  SequenceSteps?: InputMaybe<SequenceStepCreateNestedManyWithoutOrganizationInput>;
  set_goal?: InputMaybe<Scalars['Float']['input']>;
  short_resting?: InputMaybe<Scalars['Int']['input']>;
  show_calendar_options?: InputMaybe<Scalars['Boolean']['input']>;
  show_contract_duration_sales?: InputMaybe<Scalars['Boolean']['input']>;
  show_lead_source_to_rep?: InputMaybe<Scalars['Boolean']['input']>;
  show_nocontact_button?: InputMaybe<Scalars['Boolean']['input']>;
  show_payment_terms_sales?: InputMaybe<Scalars['Boolean']['input']>;
  show_rep_all_leads?: InputMaybe<Scalars['Boolean']['input']>;
  show_rep_my_leads?: InputMaybe<Scalars['Boolean']['input']>;
  show_system_view?: InputMaybe<Scalars['Boolean']['input']>;
  sites?: InputMaybe<SiteCreateNestedManyWithoutOrganizationInput>;
  sms_opt_in_dialog?: InputMaybe<Scalars['String']['input']>;
  SMSItems?: InputMaybe<SmsItemCreateNestedManyWithoutOrganizationInput>;
  SnoozeTrackings?: InputMaybe<SnoozeTrackingCreateNestedManyWithoutOrganizationInput>;
  StepActionLogs?: InputMaybe<StepActionLogCreateNestedManyWithoutOrganizationInput>;
  StepActions?: InputMaybe<StepActionCreateNestedManyWithoutOrganizationInput>;
  StepConditions?: InputMaybe<StepConditionCreateNestedManyWithoutOrganizationInput>;
  teams?: InputMaybe<TeamCreateNestedManyWithoutOrganizationInput>;
  Template?: InputMaybe<TemplateCreateNestedManyWithoutOrganizationInput>;
  TemplateVersions?: InputMaybe<TemplateVersionCreateNestedManyWithoutOrganizationInput>;
  timekit_id?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  transfer_timeout?: InputMaybe<Scalars['Int']['input']>;
  TransferAttempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutOrganizationInput>;
  twilio_numbers?: InputMaybe<TwilioNumberCreateNestedManyWithoutOrganizationInput>;
  TwilioFeedbacks?: InputMaybe<TwilioFeedbackCreateNestedManyWithoutOrganizationInput>;
  TwilioInfo?: InputMaybe<TwilioInfoCreateNestedOneWithoutOrganzationInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  use_revenue_planning?: InputMaybe<Scalars['Boolean']['input']>;
  UserCustomFieldFilters?: InputMaybe<UserCustomFieldFilterCreateNestedManyWithoutOrganizationInput>;
  users?: InputMaybe<UserCreateNestedManyWithoutOrganizationInput>;
  win_rep_day_goal?: InputMaybe<Scalars['Float']['input']>;
};

export type OrganizationCreateWithoutFoldersInput = {
  ActivityMonitorMultiplier?: InputMaybe<ActivityMonitorMultiplierCreateNestedManyWithoutOrganizationInput>;
  algoFactor?: InputMaybe<AlgoFactorCreateNestedManyWithoutOrganizationInput>;
  allow_calendar_invite?: InputMaybe<Scalars['Boolean']['input']>;
  allow_remove_step_action?: InputMaybe<Scalars['Boolean']['input']>;
  allow_snooze_step_action?: InputMaybe<Scalars['Boolean']['input']>;
  always_record?: InputMaybe<Scalars['Boolean']['input']>;
  arv_initial_held_demos_generated_by_sdr_weight?: InputMaybe<Scalars['Float']['input']>;
  AssociationHistories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutOrganizationInput>;
  auto_call_interval?: InputMaybe<Scalars['Int']['input']>;
  auto_dial?: InputMaybe<Scalars['Boolean']['input']>;
  automated_campaigns?: InputMaybe<AutomatedCampaignCreateNestedManyWithoutOrganizationInput>;
  automatic_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  AutoTransferSelections?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutOrganizationInput>;
  Availablities?: InputMaybe<AvailabilityCreateNestedManyWithoutOrganizationInput>;
  AverageOrderValue?: InputMaybe<AverageOrderValueCreateNestedManyWithoutOrganizationInput>;
  best_action_ever_multiplier?: InputMaybe<Scalars['Float']['input']>;
  best_metrics?: InputMaybe<BestMetricRecordCreateNestedManyWithoutOrganizationInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptCreateNestedManyWithoutOrganizationInput>;
  callMeNows?: InputMaybe<CallMeNowCreateNestedManyWithoutOrganizationInput>;
  CallMultiplier?: InputMaybe<CallMultiplierCreateNestedManyWithoutOrganizationInput>;
  CallSharings?: InputMaybe<CallSharingCreateNestedManyWithoutOrganizationInput>;
  can_edit_sdr_transfer_rules?: InputMaybe<Scalars['Boolean']['input']>;
  claim_rule?: InputMaybe<Scalars['Int']['input']>;
  concession?: InputMaybe<ConcessionCreateNestedManyWithoutOrganizationInput>;
  conference_region?: InputMaybe<ConferenceRegion>;
  Conferences?: InputMaybe<ConferenceCreateNestedManyWithoutOrganizationInput>;
  ContactRequests?: InputMaybe<ContactRequestCreateNestedManyWithoutOrganizationInput>;
  Contacts?: InputMaybe<ContactCreateNestedManyWithoutOrganizationInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  custom_fields?: InputMaybe<CustomFieldCreateNestedManyWithoutOrganizationInput>;
  CustomObject?: InputMaybe<CustomObjectCreateNestedManyWithoutOrganizationInput>;
  CustomObjectField?: InputMaybe<CustomObjectFieldCreateNestedManyWithoutOrganizationInput>;
  CustomObjectFieldForeignKey?: InputMaybe<CustomObjectFieldForeignKeyCreateNestedManyWithoutOrganizationInput>;
  CustomObjectRecord?: InputMaybe<CustomObjectRecordCreateNestedManyWithoutOrganizationInput>;
  CustomObjectRow?: InputMaybe<CustomObjectRowCreateNestedManyWithoutOrganizationInput>;
  CustomQueueItems?: InputMaybe<CustomQueueItemCreateNestedManyWithoutOrganizationInput>;
  DashboardColumnOptions?: InputMaybe<DashboardColumnOptionCreateNestedManyWithoutOrganizationInput>;
  DashboardLeadFilters?: InputMaybe<DashboardLeadFilterCreateNestedManyWithoutOrganizationInput>;
  DashboardRepFilters?: InputMaybe<DashboardRepFilterCreateNestedManyWithoutOrganizationInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewCreateNestedManyWithoutOrganizationInput>;
  day_to_close?: InputMaybe<Scalars['Int']['input']>;
  default_demo_time_duration?: InputMaybe<Scalars['Int']['input']>;
  demo_held_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_not_held_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_not_held_call_back_short_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_prep_time_minutes?: InputMaybe<Scalars['Int']['input']>;
  dev_account?: InputMaybe<Scalars['Boolean']['input']>;
  dial_rule?: InputMaybe<Scalars['Int']['input']>;
  DispositionMultiplier?: InputMaybe<DispositionMultiplierCreateNestedManyWithoutOrganizationInput>;
  dispositions?: InputMaybe<DispositionCreateNestedManyWithoutOrganizationInput>;
  double_booking?: InputMaybe<Scalars['Boolean']['input']>;
  dynamic_date_operators?: InputMaybe<Scalars['Boolean']['input']>;
  edge_servers?: InputMaybe<OrganizationCreateedge_ServersInput>;
  email_conflict_notification_email_list?: InputMaybe<OrganizationCreateemail_Conflict_Notification_Email_ListInput>;
  EmailItems?: InputMaybe<EmailItemCreateNestedManyWithoutOrganizationInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordCreateNestedManyWithoutOrganizationInput>;
  EmailThreads?: InputMaybe<EmailThreadCreateNestedManyWithoutOrganizationInput>;
  enable_modified_metrics?: InputMaybe<Scalars['Boolean']['input']>;
  enable_pay_period_view?: InputMaybe<Scalars['Boolean']['input']>;
  enable_time_constraint?: InputMaybe<Scalars['Boolean']['input']>;
  error_log_email_address?: InputMaybe<OrganizationCreateerror_Log_Email_AddressInput>;
  estimated_average_num_of_weekly_overbooked_demo_slots?: InputMaybe<Scalars['Int']['input']>;
  FolderPermissions?: InputMaybe<FolderPermissionCreateNestedManyWithoutOrganizationInput>;
  FutureBookingSetting?: InputMaybe<FutureBookingSettingCreateNestedOneWithoutOrganizationInput>;
  GlobalTransferRule?: InputMaybe<GlobalTransferRuleCreateNestedManyWithoutOrganizationInput>;
  held_demos_per_cc_connect?: InputMaybe<Scalars['Float']['input']>;
  helpful_links?: InputMaybe<OrganizationCreatehelpful_LinksInput>;
  HingePointMultiplier?: InputMaybe<HingePointMultiplierCreateNestedManyWithoutOrganizationInput>;
  hold_goal?: InputMaybe<Scalars['Float']['input']>;
  hubspot_credential?: InputMaybe<HubSpotCredentialCreateNestedOneWithoutOrganizationInput>;
  hubspot_last_sync_date?: InputMaybe<Scalars['DateTime']['input']>;
  hubspot_white_list?: InputMaybe<HubSpotWhitelistCreateNestedManyWithoutOrganizationInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  inbound_close_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_dm_connect_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_lt_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_revenue_value_of_sale?: InputMaybe<Scalars['Float']['input']>;
  inbound_scheduled_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_set_rate?: InputMaybe<Scalars['Float']['input']>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventCreateNestedManyWithoutOrganizationInput>;
  InboundConciergeWidget?: InputMaybe<InboundConciergeWidgetCreateNestedManyWithoutOrganizationInput>;
  industry_options?: InputMaybe<IndustryOptionCreateNestedManyWithoutOrganizationInput>;
  initial_sdr_sets_reps_day?: InputMaybe<Scalars['Float']['input']>;
  integration_error_logs?: InputMaybe<IntegrationErrorLogCreateNestedManyWithoutOrganizationInput>;
  integration_mappings?: InputMaybe<IntegrationMappingCreateNestedManyWithoutOrganizationInput>;
  IntegrationMappingCustomObject?: InputMaybe<IntegrationMappingCustomObjectCreateNestedManyWithoutOrganizationInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogCreateNestedManyWithoutOrganizationInput>;
  IntegrationReadOnlyObject?: InputMaybe<IntegrationReadOnlyObjectCreateNestedManyWithoutOrganizationInput>;
  international?: InputMaybe<Scalars['Boolean']['input']>;
  is_email_domain_verified?: InputMaybe<Scalars['Boolean']['input']>;
  is_salesforce_sandbox?: InputMaybe<Scalars['Boolean']['input']>;
  last_lead_value_compute_time?: InputMaybe<Scalars['DateTime']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutOrganizationInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryCreateNestedManyWithoutOrganizationInput>;
  lead_intent?: InputMaybe<LeadIntentCreateNestedManyWithoutOrganizationInput>;
  lead_release_same_rep_delay_rule?: InputMaybe<Scalars['Int']['input']>;
  lead_routing_email_list?: InputMaybe<OrganizationCreatelead_Routing_Email_ListInput>;
  lead_routing_rules?: InputMaybe<RuleCreateNestedManyWithoutOrganizationInput>;
  LeadCustomObjectRowAssociation?: InputMaybe<LeadCustomObjectRowAssociationCreateNestedManyWithoutOrganizationInput>;
  LeadFieldMultiplier?: InputMaybe<LeadFieldMultiplierCreateNestedManyWithoutOrganizationInput>;
  LeadFieldMultiplierCustom?: InputMaybe<LeadFieldMultiplierCustomCreateNestedManyWithoutOrganizationInput>;
  leads?: InputMaybe<LeadCreateNestedManyWithoutOrganizationInput>;
  leadsource_options?: InputMaybe<LeadSourceOptionCreateNestedManyWithoutOrganizationInput>;
  LeadValue?: InputMaybe<LeadValueCreateNestedManyWithoutOrganizationInput>;
  LifeTimeValueMultiplier?: InputMaybe<LifeTimeValueMultiplierCreateNestedManyWithoutOrganizationInput>;
  LiveTransferSetting?: InputMaybe<LiveTransferSettingCreateNestedOneWithoutOrganizationInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusCreateNestedManyWithoutOrganizationInput>;
  ltv_multiplier_custom_fields?: InputMaybe<LifeTimeValueMultiplierCustomCreateNestedManyWithoutOrganizationInput>;
  made_contact_rule?: InputMaybe<Scalars['Int']['input']>;
  make_sale_address_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_business_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_city_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_country_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_first_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_industry_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_last_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_lead_source_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_primary_email_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_primary_phone_number_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_state_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_sub_industry_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_zip_required?: InputMaybe<Scalars['Boolean']['input']>;
  maxium_day_lag_set_to_schedule?: InputMaybe<Scalars['Int']['input']>;
  mrr?: InputMaybe<Scalars['Int']['input']>;
  mrr_label?: InputMaybe<Scalars['String']['input']>;
  mrr_required_on_sale?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  NoteItems?: InputMaybe<NoteItemCreateNestedManyWithoutOrganizationInput>;
  Notifications?: InputMaybe<NotificationCreateNestedManyWithoutOrganizationInput>;
  onboardComplete?: InputMaybe<Scalars['Boolean']['input']>;
  Opportunity?: InputMaybe<SfOpportunityCreateNestedManyWithoutOrganizationInput>;
  organization_holidays?: InputMaybe<OrganizationHolidayCreateNestedManyWithoutOrganizationInput>;
  organization_hours?: InputMaybe<OrganizationHourCreateNestedManyWithoutOrganizationInput>;
  OrganizationPaymentSetting?: InputMaybe<OrganizationPaymentSettingsCreateNestedOneWithoutOrganizationInput>;
  OrganizationPaymentSettings?: InputMaybe<OrganizationPaymentSettingsCreateNestedOneWithoutOrganizationsInput>;
  orgCalendarInviteMetadata?: InputMaybe<OrgCalendarInviteMetadataCreateNestedManyWithoutOrganizationInput>;
  OrgDefaultPipelinePerecentValue?: InputMaybe<OrgDefaultPipelinePerecentValueCreateNestedOneWithoutOrganizationInput>;
  OrgHubSpotSync?: InputMaybe<OrgHubSpotSyncCreateNestedOneWithoutOrganizationInput>;
  OrgNoEventDate?: InputMaybe<OrgNoEventDateCreateNestedManyWithoutOrganizationInput>;
  OrgSalesforceSync?: InputMaybe<OrgSalesforceSyncCreateNestedOneWithoutOrganizationInput>;
  OrgTimeslotAvailability?: InputMaybe<OrgTimeslotAvailabilityCreateNestedManyWithoutOrganizationInput>;
  outbound_close_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_dm_connect_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_lt_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_revenue_value_of_sale?: InputMaybe<Scalars['Float']['input']>;
  outbound_scheduled_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_set_rate?: InputMaybe<Scalars['Float']['input']>;
  PandadocData?: InputMaybe<PandadocDataCreateNestedOneWithoutOrganizationInput>;
  PandaDocTemplate?: InputMaybe<PandaDocTemplateCreateNestedManyWithoutOrganizationInput>;
  participant_jitter_buffer_size?: InputMaybe<JitterBufferSize>;
  preferred_goal_setting?: InputMaybe<Goalsetting>;
  prepayment?: InputMaybe<Scalars['Boolean']['input']>;
  ProductChangeLog?: InputMaybe<ProductChangeLogCreateNestedManyWithoutOrganizationInput>;
  products?: InputMaybe<ProductCreateNestedManyWithoutOrganizationInput>;
  re_cold_call_hour_delay?: InputMaybe<Scalars['Float']['input']>;
  recordingStatus?: InputMaybe<RecordingStatus>;
  release_lead_resting?: InputMaybe<Scalars['Int']['input']>;
  rep_claim_limit?: InputMaybe<Scalars['Int']['input']>;
  rep_lead_allotment?: InputMaybe<Scalars['Int']['input']>;
  rep_level_call_transfer?: InputMaybe<Scalars['Boolean']['input']>;
  reps_can_bulk_unassign?: InputMaybe<Scalars['Boolean']['input']>;
  reps_can_view_all_notes?: InputMaybe<Scalars['Boolean']['input']>;
  require_sale_notes?: InputMaybe<Scalars['Boolean']['input']>;
  resting_after_skipped_CC?: InputMaybe<Scalars['Int']['input']>;
  resting_period?: InputMaybe<Scalars['Int']['input']>;
  resting_period_after_unsuccessful_cold_call?: InputMaybe<Scalars['Float']['input']>;
  resting_period_after_unsuccessful_demo?: InputMaybe<Scalars['Float']['input']>;
  revenue_plan?: InputMaybe<RevenuePlanItemCreateNestedManyWithoutOrganizationInput>;
  Routings?: InputMaybe<RoutingCreateNestedManyWithoutOrganizationInput>;
  sale_field_options?: InputMaybe<SaleFieldOptionCreateNestedManyWithoutOrganizationInput>;
  sale_notification_email_list?: InputMaybe<OrganizationCreatesale_Notification_Email_ListInput>;
  SaleConfig?: InputMaybe<SaleConfigCreateNestedOneWithoutOrganizationInput>;
  SaleConfigPage?: InputMaybe<SaleConfigPageCreateNestedManyWithoutOrganizationInput>;
  SaleConfigSection?: InputMaybe<SaleConfigSectionCreateNestedManyWithoutOrganizationInput>;
  SaleCycles?: InputMaybe<SaleCycleCreateNestedManyWithoutOrganizationInput>;
  SaleItems?: InputMaybe<SaleItemCreateNestedManyWithoutOrganizationInput>;
  sales?: InputMaybe<SaleCreateNestedManyWithoutOrganizationInput>;
  salesforce_domain?: InputMaybe<Scalars['String']['input']>;
  salesforce_last_sync_date?: InputMaybe<Scalars['DateTime']['input']>;
  SalesForceColumn?: InputMaybe<SalesForceColumnCreateNestedManyWithoutOrganizationInput>;
  salesForceData?: InputMaybe<SalesForceDataCreateNestedOneWithoutOrganizationInput>;
  ScheduleItems?: InputMaybe<ScheduleItemCreateNestedManyWithoutOrganizationInput>;
  self_sourcing_rule?: InputMaybe<Scalars['Int']['input']>;
  send_error_log_email?: InputMaybe<Scalars['Boolean']['input']>;
  send_lead_routing_email?: InputMaybe<Scalars['Boolean']['input']>;
  sendgrid_email_domain?: InputMaybe<Scalars['String']['input']>;
  sendgrid_email_domain_id?: InputMaybe<Scalars['Int']['input']>;
  sequence?: InputMaybe<SequenceCreateNestedManyWithoutOrganzationInput>;
  SequenceCriteriaConditions?: InputMaybe<SequenceCriteriaConditionCreateNestedManyWithoutOrganizationInput>;
  SequenceDashboardViews?: InputMaybe<SequenceDashboardViewCreateNestedManyWithoutOrganizationInput>;
  SequenceEntryCriterias?: InputMaybe<SequenceEntryCriteriaCreateNestedManyWithoutOrganizationInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutOrganizationInput>;
  SequenceSteps?: InputMaybe<SequenceStepCreateNestedManyWithoutOrganizationInput>;
  set_goal?: InputMaybe<Scalars['Float']['input']>;
  short_resting?: InputMaybe<Scalars['Int']['input']>;
  show_calendar_options?: InputMaybe<Scalars['Boolean']['input']>;
  show_contract_duration_sales?: InputMaybe<Scalars['Boolean']['input']>;
  show_lead_source_to_rep?: InputMaybe<Scalars['Boolean']['input']>;
  show_nocontact_button?: InputMaybe<Scalars['Boolean']['input']>;
  show_payment_terms_sales?: InputMaybe<Scalars['Boolean']['input']>;
  show_rep_all_leads?: InputMaybe<Scalars['Boolean']['input']>;
  show_rep_my_leads?: InputMaybe<Scalars['Boolean']['input']>;
  show_system_view?: InputMaybe<Scalars['Boolean']['input']>;
  sites?: InputMaybe<SiteCreateNestedManyWithoutOrganizationInput>;
  sms_opt_in_dialog?: InputMaybe<Scalars['String']['input']>;
  SMSItems?: InputMaybe<SmsItemCreateNestedManyWithoutOrganizationInput>;
  SnoozeTrackings?: InputMaybe<SnoozeTrackingCreateNestedManyWithoutOrganizationInput>;
  StepActionLogs?: InputMaybe<StepActionLogCreateNestedManyWithoutOrganizationInput>;
  StepActions?: InputMaybe<StepActionCreateNestedManyWithoutOrganizationInput>;
  StepConditions?: InputMaybe<StepConditionCreateNestedManyWithoutOrganizationInput>;
  teams?: InputMaybe<TeamCreateNestedManyWithoutOrganizationInput>;
  Template?: InputMaybe<TemplateCreateNestedManyWithoutOrganizationInput>;
  TemplateVersions?: InputMaybe<TemplateVersionCreateNestedManyWithoutOrganizationInput>;
  timekit_id?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  transfer_timeout?: InputMaybe<Scalars['Int']['input']>;
  TransferAttempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutOrganizationInput>;
  twilio_numbers?: InputMaybe<TwilioNumberCreateNestedManyWithoutOrganizationInput>;
  TwilioFeedbacks?: InputMaybe<TwilioFeedbackCreateNestedManyWithoutOrganizationInput>;
  TwilioInfo?: InputMaybe<TwilioInfoCreateNestedOneWithoutOrganzationInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  use_revenue_planning?: InputMaybe<Scalars['Boolean']['input']>;
  UserCustomFieldFilters?: InputMaybe<UserCustomFieldFilterCreateNestedManyWithoutOrganizationInput>;
  users?: InputMaybe<UserCreateNestedManyWithoutOrganizationInput>;
  win_rep_day_goal?: InputMaybe<Scalars['Float']['input']>;
};

export type OrganizationCreateWithoutGlobalTransferRuleInput = {
  ActivityMonitorMultiplier?: InputMaybe<ActivityMonitorMultiplierCreateNestedManyWithoutOrganizationInput>;
  algoFactor?: InputMaybe<AlgoFactorCreateNestedManyWithoutOrganizationInput>;
  allow_calendar_invite?: InputMaybe<Scalars['Boolean']['input']>;
  allow_remove_step_action?: InputMaybe<Scalars['Boolean']['input']>;
  allow_snooze_step_action?: InputMaybe<Scalars['Boolean']['input']>;
  always_record?: InputMaybe<Scalars['Boolean']['input']>;
  arv_initial_held_demos_generated_by_sdr_weight?: InputMaybe<Scalars['Float']['input']>;
  AssociationHistories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutOrganizationInput>;
  auto_call_interval?: InputMaybe<Scalars['Int']['input']>;
  auto_dial?: InputMaybe<Scalars['Boolean']['input']>;
  automated_campaigns?: InputMaybe<AutomatedCampaignCreateNestedManyWithoutOrganizationInput>;
  automatic_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  AutoTransferSelections?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutOrganizationInput>;
  Availablities?: InputMaybe<AvailabilityCreateNestedManyWithoutOrganizationInput>;
  AverageOrderValue?: InputMaybe<AverageOrderValueCreateNestedManyWithoutOrganizationInput>;
  best_action_ever_multiplier?: InputMaybe<Scalars['Float']['input']>;
  best_metrics?: InputMaybe<BestMetricRecordCreateNestedManyWithoutOrganizationInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptCreateNestedManyWithoutOrganizationInput>;
  callMeNows?: InputMaybe<CallMeNowCreateNestedManyWithoutOrganizationInput>;
  CallMultiplier?: InputMaybe<CallMultiplierCreateNestedManyWithoutOrganizationInput>;
  CallSharings?: InputMaybe<CallSharingCreateNestedManyWithoutOrganizationInput>;
  can_edit_sdr_transfer_rules?: InputMaybe<Scalars['Boolean']['input']>;
  claim_rule?: InputMaybe<Scalars['Int']['input']>;
  concession?: InputMaybe<ConcessionCreateNestedManyWithoutOrganizationInput>;
  conference_region?: InputMaybe<ConferenceRegion>;
  Conferences?: InputMaybe<ConferenceCreateNestedManyWithoutOrganizationInput>;
  ContactRequests?: InputMaybe<ContactRequestCreateNestedManyWithoutOrganizationInput>;
  Contacts?: InputMaybe<ContactCreateNestedManyWithoutOrganizationInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  custom_fields?: InputMaybe<CustomFieldCreateNestedManyWithoutOrganizationInput>;
  CustomObject?: InputMaybe<CustomObjectCreateNestedManyWithoutOrganizationInput>;
  CustomObjectField?: InputMaybe<CustomObjectFieldCreateNestedManyWithoutOrganizationInput>;
  CustomObjectFieldForeignKey?: InputMaybe<CustomObjectFieldForeignKeyCreateNestedManyWithoutOrganizationInput>;
  CustomObjectRecord?: InputMaybe<CustomObjectRecordCreateNestedManyWithoutOrganizationInput>;
  CustomObjectRow?: InputMaybe<CustomObjectRowCreateNestedManyWithoutOrganizationInput>;
  CustomQueueItems?: InputMaybe<CustomQueueItemCreateNestedManyWithoutOrganizationInput>;
  DashboardColumnOptions?: InputMaybe<DashboardColumnOptionCreateNestedManyWithoutOrganizationInput>;
  DashboardLeadFilters?: InputMaybe<DashboardLeadFilterCreateNestedManyWithoutOrganizationInput>;
  DashboardRepFilters?: InputMaybe<DashboardRepFilterCreateNestedManyWithoutOrganizationInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewCreateNestedManyWithoutOrganizationInput>;
  day_to_close?: InputMaybe<Scalars['Int']['input']>;
  default_demo_time_duration?: InputMaybe<Scalars['Int']['input']>;
  demo_held_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_not_held_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_not_held_call_back_short_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_prep_time_minutes?: InputMaybe<Scalars['Int']['input']>;
  dev_account?: InputMaybe<Scalars['Boolean']['input']>;
  dial_rule?: InputMaybe<Scalars['Int']['input']>;
  DispositionMultiplier?: InputMaybe<DispositionMultiplierCreateNestedManyWithoutOrganizationInput>;
  dispositions?: InputMaybe<DispositionCreateNestedManyWithoutOrganizationInput>;
  double_booking?: InputMaybe<Scalars['Boolean']['input']>;
  dynamic_date_operators?: InputMaybe<Scalars['Boolean']['input']>;
  edge_servers?: InputMaybe<OrganizationCreateedge_ServersInput>;
  email_conflict_notification_email_list?: InputMaybe<OrganizationCreateemail_Conflict_Notification_Email_ListInput>;
  EmailItems?: InputMaybe<EmailItemCreateNestedManyWithoutOrganizationInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordCreateNestedManyWithoutOrganizationInput>;
  EmailThreads?: InputMaybe<EmailThreadCreateNestedManyWithoutOrganizationInput>;
  enable_modified_metrics?: InputMaybe<Scalars['Boolean']['input']>;
  enable_pay_period_view?: InputMaybe<Scalars['Boolean']['input']>;
  enable_time_constraint?: InputMaybe<Scalars['Boolean']['input']>;
  error_log_email_address?: InputMaybe<OrganizationCreateerror_Log_Email_AddressInput>;
  estimated_average_num_of_weekly_overbooked_demo_slots?: InputMaybe<Scalars['Int']['input']>;
  FolderPermissions?: InputMaybe<FolderPermissionCreateNestedManyWithoutOrganizationInput>;
  Folders?: InputMaybe<FolderCreateNestedManyWithoutOrganizationInput>;
  FutureBookingSetting?: InputMaybe<FutureBookingSettingCreateNestedOneWithoutOrganizationInput>;
  held_demos_per_cc_connect?: InputMaybe<Scalars['Float']['input']>;
  helpful_links?: InputMaybe<OrganizationCreatehelpful_LinksInput>;
  HingePointMultiplier?: InputMaybe<HingePointMultiplierCreateNestedManyWithoutOrganizationInput>;
  hold_goal?: InputMaybe<Scalars['Float']['input']>;
  hubspot_credential?: InputMaybe<HubSpotCredentialCreateNestedOneWithoutOrganizationInput>;
  hubspot_last_sync_date?: InputMaybe<Scalars['DateTime']['input']>;
  hubspot_white_list?: InputMaybe<HubSpotWhitelistCreateNestedManyWithoutOrganizationInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  inbound_close_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_dm_connect_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_lt_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_revenue_value_of_sale?: InputMaybe<Scalars['Float']['input']>;
  inbound_scheduled_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_set_rate?: InputMaybe<Scalars['Float']['input']>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventCreateNestedManyWithoutOrganizationInput>;
  InboundConciergeWidget?: InputMaybe<InboundConciergeWidgetCreateNestedManyWithoutOrganizationInput>;
  industry_options?: InputMaybe<IndustryOptionCreateNestedManyWithoutOrganizationInput>;
  initial_sdr_sets_reps_day?: InputMaybe<Scalars['Float']['input']>;
  integration_error_logs?: InputMaybe<IntegrationErrorLogCreateNestedManyWithoutOrganizationInput>;
  integration_mappings?: InputMaybe<IntegrationMappingCreateNestedManyWithoutOrganizationInput>;
  IntegrationMappingCustomObject?: InputMaybe<IntegrationMappingCustomObjectCreateNestedManyWithoutOrganizationInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogCreateNestedManyWithoutOrganizationInput>;
  IntegrationReadOnlyObject?: InputMaybe<IntegrationReadOnlyObjectCreateNestedManyWithoutOrganizationInput>;
  international?: InputMaybe<Scalars['Boolean']['input']>;
  is_email_domain_verified?: InputMaybe<Scalars['Boolean']['input']>;
  is_salesforce_sandbox?: InputMaybe<Scalars['Boolean']['input']>;
  last_lead_value_compute_time?: InputMaybe<Scalars['DateTime']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutOrganizationInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryCreateNestedManyWithoutOrganizationInput>;
  lead_intent?: InputMaybe<LeadIntentCreateNestedManyWithoutOrganizationInput>;
  lead_release_same_rep_delay_rule?: InputMaybe<Scalars['Int']['input']>;
  lead_routing_email_list?: InputMaybe<OrganizationCreatelead_Routing_Email_ListInput>;
  lead_routing_rules?: InputMaybe<RuleCreateNestedManyWithoutOrganizationInput>;
  LeadCustomObjectRowAssociation?: InputMaybe<LeadCustomObjectRowAssociationCreateNestedManyWithoutOrganizationInput>;
  LeadFieldMultiplier?: InputMaybe<LeadFieldMultiplierCreateNestedManyWithoutOrganizationInput>;
  LeadFieldMultiplierCustom?: InputMaybe<LeadFieldMultiplierCustomCreateNestedManyWithoutOrganizationInput>;
  leads?: InputMaybe<LeadCreateNestedManyWithoutOrganizationInput>;
  leadsource_options?: InputMaybe<LeadSourceOptionCreateNestedManyWithoutOrganizationInput>;
  LeadValue?: InputMaybe<LeadValueCreateNestedManyWithoutOrganizationInput>;
  LifeTimeValueMultiplier?: InputMaybe<LifeTimeValueMultiplierCreateNestedManyWithoutOrganizationInput>;
  LiveTransferSetting?: InputMaybe<LiveTransferSettingCreateNestedOneWithoutOrganizationInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusCreateNestedManyWithoutOrganizationInput>;
  ltv_multiplier_custom_fields?: InputMaybe<LifeTimeValueMultiplierCustomCreateNestedManyWithoutOrganizationInput>;
  made_contact_rule?: InputMaybe<Scalars['Int']['input']>;
  make_sale_address_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_business_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_city_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_country_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_first_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_industry_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_last_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_lead_source_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_primary_email_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_primary_phone_number_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_state_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_sub_industry_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_zip_required?: InputMaybe<Scalars['Boolean']['input']>;
  maxium_day_lag_set_to_schedule?: InputMaybe<Scalars['Int']['input']>;
  mrr?: InputMaybe<Scalars['Int']['input']>;
  mrr_label?: InputMaybe<Scalars['String']['input']>;
  mrr_required_on_sale?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  NoteItems?: InputMaybe<NoteItemCreateNestedManyWithoutOrganizationInput>;
  Notifications?: InputMaybe<NotificationCreateNestedManyWithoutOrganizationInput>;
  onboardComplete?: InputMaybe<Scalars['Boolean']['input']>;
  Opportunity?: InputMaybe<SfOpportunityCreateNestedManyWithoutOrganizationInput>;
  organization_holidays?: InputMaybe<OrganizationHolidayCreateNestedManyWithoutOrganizationInput>;
  organization_hours?: InputMaybe<OrganizationHourCreateNestedManyWithoutOrganizationInput>;
  OrganizationPaymentSetting?: InputMaybe<OrganizationPaymentSettingsCreateNestedOneWithoutOrganizationInput>;
  OrganizationPaymentSettings?: InputMaybe<OrganizationPaymentSettingsCreateNestedOneWithoutOrganizationsInput>;
  orgCalendarInviteMetadata?: InputMaybe<OrgCalendarInviteMetadataCreateNestedManyWithoutOrganizationInput>;
  OrgDefaultPipelinePerecentValue?: InputMaybe<OrgDefaultPipelinePerecentValueCreateNestedOneWithoutOrganizationInput>;
  OrgHubSpotSync?: InputMaybe<OrgHubSpotSyncCreateNestedOneWithoutOrganizationInput>;
  OrgNoEventDate?: InputMaybe<OrgNoEventDateCreateNestedManyWithoutOrganizationInput>;
  OrgSalesforceSync?: InputMaybe<OrgSalesforceSyncCreateNestedOneWithoutOrganizationInput>;
  OrgTimeslotAvailability?: InputMaybe<OrgTimeslotAvailabilityCreateNestedManyWithoutOrganizationInput>;
  outbound_close_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_dm_connect_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_lt_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_revenue_value_of_sale?: InputMaybe<Scalars['Float']['input']>;
  outbound_scheduled_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_set_rate?: InputMaybe<Scalars['Float']['input']>;
  PandadocData?: InputMaybe<PandadocDataCreateNestedOneWithoutOrganizationInput>;
  PandaDocTemplate?: InputMaybe<PandaDocTemplateCreateNestedManyWithoutOrganizationInput>;
  participant_jitter_buffer_size?: InputMaybe<JitterBufferSize>;
  preferred_goal_setting?: InputMaybe<Goalsetting>;
  prepayment?: InputMaybe<Scalars['Boolean']['input']>;
  ProductChangeLog?: InputMaybe<ProductChangeLogCreateNestedManyWithoutOrganizationInput>;
  products?: InputMaybe<ProductCreateNestedManyWithoutOrganizationInput>;
  re_cold_call_hour_delay?: InputMaybe<Scalars['Float']['input']>;
  recordingStatus?: InputMaybe<RecordingStatus>;
  release_lead_resting?: InputMaybe<Scalars['Int']['input']>;
  rep_claim_limit?: InputMaybe<Scalars['Int']['input']>;
  rep_lead_allotment?: InputMaybe<Scalars['Int']['input']>;
  rep_level_call_transfer?: InputMaybe<Scalars['Boolean']['input']>;
  reps_can_bulk_unassign?: InputMaybe<Scalars['Boolean']['input']>;
  reps_can_view_all_notes?: InputMaybe<Scalars['Boolean']['input']>;
  require_sale_notes?: InputMaybe<Scalars['Boolean']['input']>;
  resting_after_skipped_CC?: InputMaybe<Scalars['Int']['input']>;
  resting_period?: InputMaybe<Scalars['Int']['input']>;
  resting_period_after_unsuccessful_cold_call?: InputMaybe<Scalars['Float']['input']>;
  resting_period_after_unsuccessful_demo?: InputMaybe<Scalars['Float']['input']>;
  revenue_plan?: InputMaybe<RevenuePlanItemCreateNestedManyWithoutOrganizationInput>;
  Routings?: InputMaybe<RoutingCreateNestedManyWithoutOrganizationInput>;
  sale_field_options?: InputMaybe<SaleFieldOptionCreateNestedManyWithoutOrganizationInput>;
  sale_notification_email_list?: InputMaybe<OrganizationCreatesale_Notification_Email_ListInput>;
  SaleConfig?: InputMaybe<SaleConfigCreateNestedOneWithoutOrganizationInput>;
  SaleConfigPage?: InputMaybe<SaleConfigPageCreateNestedManyWithoutOrganizationInput>;
  SaleConfigSection?: InputMaybe<SaleConfigSectionCreateNestedManyWithoutOrganizationInput>;
  SaleCycles?: InputMaybe<SaleCycleCreateNestedManyWithoutOrganizationInput>;
  SaleItems?: InputMaybe<SaleItemCreateNestedManyWithoutOrganizationInput>;
  sales?: InputMaybe<SaleCreateNestedManyWithoutOrganizationInput>;
  salesforce_domain?: InputMaybe<Scalars['String']['input']>;
  salesforce_last_sync_date?: InputMaybe<Scalars['DateTime']['input']>;
  SalesForceColumn?: InputMaybe<SalesForceColumnCreateNestedManyWithoutOrganizationInput>;
  salesForceData?: InputMaybe<SalesForceDataCreateNestedOneWithoutOrganizationInput>;
  ScheduleItems?: InputMaybe<ScheduleItemCreateNestedManyWithoutOrganizationInput>;
  self_sourcing_rule?: InputMaybe<Scalars['Int']['input']>;
  send_error_log_email?: InputMaybe<Scalars['Boolean']['input']>;
  send_lead_routing_email?: InputMaybe<Scalars['Boolean']['input']>;
  sendgrid_email_domain?: InputMaybe<Scalars['String']['input']>;
  sendgrid_email_domain_id?: InputMaybe<Scalars['Int']['input']>;
  sequence?: InputMaybe<SequenceCreateNestedManyWithoutOrganzationInput>;
  SequenceCriteriaConditions?: InputMaybe<SequenceCriteriaConditionCreateNestedManyWithoutOrganizationInput>;
  SequenceDashboardViews?: InputMaybe<SequenceDashboardViewCreateNestedManyWithoutOrganizationInput>;
  SequenceEntryCriterias?: InputMaybe<SequenceEntryCriteriaCreateNestedManyWithoutOrganizationInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutOrganizationInput>;
  SequenceSteps?: InputMaybe<SequenceStepCreateNestedManyWithoutOrganizationInput>;
  set_goal?: InputMaybe<Scalars['Float']['input']>;
  short_resting?: InputMaybe<Scalars['Int']['input']>;
  show_calendar_options?: InputMaybe<Scalars['Boolean']['input']>;
  show_contract_duration_sales?: InputMaybe<Scalars['Boolean']['input']>;
  show_lead_source_to_rep?: InputMaybe<Scalars['Boolean']['input']>;
  show_nocontact_button?: InputMaybe<Scalars['Boolean']['input']>;
  show_payment_terms_sales?: InputMaybe<Scalars['Boolean']['input']>;
  show_rep_all_leads?: InputMaybe<Scalars['Boolean']['input']>;
  show_rep_my_leads?: InputMaybe<Scalars['Boolean']['input']>;
  show_system_view?: InputMaybe<Scalars['Boolean']['input']>;
  sites?: InputMaybe<SiteCreateNestedManyWithoutOrganizationInput>;
  sms_opt_in_dialog?: InputMaybe<Scalars['String']['input']>;
  SMSItems?: InputMaybe<SmsItemCreateNestedManyWithoutOrganizationInput>;
  SnoozeTrackings?: InputMaybe<SnoozeTrackingCreateNestedManyWithoutOrganizationInput>;
  StepActionLogs?: InputMaybe<StepActionLogCreateNestedManyWithoutOrganizationInput>;
  StepActions?: InputMaybe<StepActionCreateNestedManyWithoutOrganizationInput>;
  StepConditions?: InputMaybe<StepConditionCreateNestedManyWithoutOrganizationInput>;
  teams?: InputMaybe<TeamCreateNestedManyWithoutOrganizationInput>;
  Template?: InputMaybe<TemplateCreateNestedManyWithoutOrganizationInput>;
  TemplateVersions?: InputMaybe<TemplateVersionCreateNestedManyWithoutOrganizationInput>;
  timekit_id?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  transfer_timeout?: InputMaybe<Scalars['Int']['input']>;
  TransferAttempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutOrganizationInput>;
  twilio_numbers?: InputMaybe<TwilioNumberCreateNestedManyWithoutOrganizationInput>;
  TwilioFeedbacks?: InputMaybe<TwilioFeedbackCreateNestedManyWithoutOrganizationInput>;
  TwilioInfo?: InputMaybe<TwilioInfoCreateNestedOneWithoutOrganzationInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  use_revenue_planning?: InputMaybe<Scalars['Boolean']['input']>;
  UserCustomFieldFilters?: InputMaybe<UserCustomFieldFilterCreateNestedManyWithoutOrganizationInput>;
  users?: InputMaybe<UserCreateNestedManyWithoutOrganizationInput>;
  win_rep_day_goal?: InputMaybe<Scalars['Float']['input']>;
};

export type OrganizationCreateWithoutHingePointMultiplierInput = {
  ActivityMonitorMultiplier?: InputMaybe<ActivityMonitorMultiplierCreateNestedManyWithoutOrganizationInput>;
  algoFactor?: InputMaybe<AlgoFactorCreateNestedManyWithoutOrganizationInput>;
  allow_calendar_invite?: InputMaybe<Scalars['Boolean']['input']>;
  allow_remove_step_action?: InputMaybe<Scalars['Boolean']['input']>;
  allow_snooze_step_action?: InputMaybe<Scalars['Boolean']['input']>;
  always_record?: InputMaybe<Scalars['Boolean']['input']>;
  arv_initial_held_demos_generated_by_sdr_weight?: InputMaybe<Scalars['Float']['input']>;
  AssociationHistories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutOrganizationInput>;
  auto_call_interval?: InputMaybe<Scalars['Int']['input']>;
  auto_dial?: InputMaybe<Scalars['Boolean']['input']>;
  automated_campaigns?: InputMaybe<AutomatedCampaignCreateNestedManyWithoutOrganizationInput>;
  automatic_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  AutoTransferSelections?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutOrganizationInput>;
  Availablities?: InputMaybe<AvailabilityCreateNestedManyWithoutOrganizationInput>;
  AverageOrderValue?: InputMaybe<AverageOrderValueCreateNestedManyWithoutOrganizationInput>;
  best_action_ever_multiplier?: InputMaybe<Scalars['Float']['input']>;
  best_metrics?: InputMaybe<BestMetricRecordCreateNestedManyWithoutOrganizationInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptCreateNestedManyWithoutOrganizationInput>;
  callMeNows?: InputMaybe<CallMeNowCreateNestedManyWithoutOrganizationInput>;
  CallMultiplier?: InputMaybe<CallMultiplierCreateNestedManyWithoutOrganizationInput>;
  CallSharings?: InputMaybe<CallSharingCreateNestedManyWithoutOrganizationInput>;
  can_edit_sdr_transfer_rules?: InputMaybe<Scalars['Boolean']['input']>;
  claim_rule?: InputMaybe<Scalars['Int']['input']>;
  concession?: InputMaybe<ConcessionCreateNestedManyWithoutOrganizationInput>;
  conference_region?: InputMaybe<ConferenceRegion>;
  Conferences?: InputMaybe<ConferenceCreateNestedManyWithoutOrganizationInput>;
  ContactRequests?: InputMaybe<ContactRequestCreateNestedManyWithoutOrganizationInput>;
  Contacts?: InputMaybe<ContactCreateNestedManyWithoutOrganizationInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  custom_fields?: InputMaybe<CustomFieldCreateNestedManyWithoutOrganizationInput>;
  CustomObject?: InputMaybe<CustomObjectCreateNestedManyWithoutOrganizationInput>;
  CustomObjectField?: InputMaybe<CustomObjectFieldCreateNestedManyWithoutOrganizationInput>;
  CustomObjectFieldForeignKey?: InputMaybe<CustomObjectFieldForeignKeyCreateNestedManyWithoutOrganizationInput>;
  CustomObjectRecord?: InputMaybe<CustomObjectRecordCreateNestedManyWithoutOrganizationInput>;
  CustomObjectRow?: InputMaybe<CustomObjectRowCreateNestedManyWithoutOrganizationInput>;
  CustomQueueItems?: InputMaybe<CustomQueueItemCreateNestedManyWithoutOrganizationInput>;
  DashboardColumnOptions?: InputMaybe<DashboardColumnOptionCreateNestedManyWithoutOrganizationInput>;
  DashboardLeadFilters?: InputMaybe<DashboardLeadFilterCreateNestedManyWithoutOrganizationInput>;
  DashboardRepFilters?: InputMaybe<DashboardRepFilterCreateNestedManyWithoutOrganizationInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewCreateNestedManyWithoutOrganizationInput>;
  day_to_close?: InputMaybe<Scalars['Int']['input']>;
  default_demo_time_duration?: InputMaybe<Scalars['Int']['input']>;
  demo_held_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_not_held_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_not_held_call_back_short_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_prep_time_minutes?: InputMaybe<Scalars['Int']['input']>;
  dev_account?: InputMaybe<Scalars['Boolean']['input']>;
  dial_rule?: InputMaybe<Scalars['Int']['input']>;
  DispositionMultiplier?: InputMaybe<DispositionMultiplierCreateNestedManyWithoutOrganizationInput>;
  dispositions?: InputMaybe<DispositionCreateNestedManyWithoutOrganizationInput>;
  double_booking?: InputMaybe<Scalars['Boolean']['input']>;
  dynamic_date_operators?: InputMaybe<Scalars['Boolean']['input']>;
  edge_servers?: InputMaybe<OrganizationCreateedge_ServersInput>;
  email_conflict_notification_email_list?: InputMaybe<OrganizationCreateemail_Conflict_Notification_Email_ListInput>;
  EmailItems?: InputMaybe<EmailItemCreateNestedManyWithoutOrganizationInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordCreateNestedManyWithoutOrganizationInput>;
  EmailThreads?: InputMaybe<EmailThreadCreateNestedManyWithoutOrganizationInput>;
  enable_modified_metrics?: InputMaybe<Scalars['Boolean']['input']>;
  enable_pay_period_view?: InputMaybe<Scalars['Boolean']['input']>;
  enable_time_constraint?: InputMaybe<Scalars['Boolean']['input']>;
  error_log_email_address?: InputMaybe<OrganizationCreateerror_Log_Email_AddressInput>;
  estimated_average_num_of_weekly_overbooked_demo_slots?: InputMaybe<Scalars['Int']['input']>;
  FolderPermissions?: InputMaybe<FolderPermissionCreateNestedManyWithoutOrganizationInput>;
  Folders?: InputMaybe<FolderCreateNestedManyWithoutOrganizationInput>;
  FutureBookingSetting?: InputMaybe<FutureBookingSettingCreateNestedOneWithoutOrganizationInput>;
  GlobalTransferRule?: InputMaybe<GlobalTransferRuleCreateNestedManyWithoutOrganizationInput>;
  held_demos_per_cc_connect?: InputMaybe<Scalars['Float']['input']>;
  helpful_links?: InputMaybe<OrganizationCreatehelpful_LinksInput>;
  hold_goal?: InputMaybe<Scalars['Float']['input']>;
  hubspot_credential?: InputMaybe<HubSpotCredentialCreateNestedOneWithoutOrganizationInput>;
  hubspot_last_sync_date?: InputMaybe<Scalars['DateTime']['input']>;
  hubspot_white_list?: InputMaybe<HubSpotWhitelistCreateNestedManyWithoutOrganizationInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  inbound_close_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_dm_connect_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_lt_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_revenue_value_of_sale?: InputMaybe<Scalars['Float']['input']>;
  inbound_scheduled_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_set_rate?: InputMaybe<Scalars['Float']['input']>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventCreateNestedManyWithoutOrganizationInput>;
  InboundConciergeWidget?: InputMaybe<InboundConciergeWidgetCreateNestedManyWithoutOrganizationInput>;
  industry_options?: InputMaybe<IndustryOptionCreateNestedManyWithoutOrganizationInput>;
  initial_sdr_sets_reps_day?: InputMaybe<Scalars['Float']['input']>;
  integration_error_logs?: InputMaybe<IntegrationErrorLogCreateNestedManyWithoutOrganizationInput>;
  integration_mappings?: InputMaybe<IntegrationMappingCreateNestedManyWithoutOrganizationInput>;
  IntegrationMappingCustomObject?: InputMaybe<IntegrationMappingCustomObjectCreateNestedManyWithoutOrganizationInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogCreateNestedManyWithoutOrganizationInput>;
  IntegrationReadOnlyObject?: InputMaybe<IntegrationReadOnlyObjectCreateNestedManyWithoutOrganizationInput>;
  international?: InputMaybe<Scalars['Boolean']['input']>;
  is_email_domain_verified?: InputMaybe<Scalars['Boolean']['input']>;
  is_salesforce_sandbox?: InputMaybe<Scalars['Boolean']['input']>;
  last_lead_value_compute_time?: InputMaybe<Scalars['DateTime']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutOrganizationInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryCreateNestedManyWithoutOrganizationInput>;
  lead_intent?: InputMaybe<LeadIntentCreateNestedManyWithoutOrganizationInput>;
  lead_release_same_rep_delay_rule?: InputMaybe<Scalars['Int']['input']>;
  lead_routing_email_list?: InputMaybe<OrganizationCreatelead_Routing_Email_ListInput>;
  lead_routing_rules?: InputMaybe<RuleCreateNestedManyWithoutOrganizationInput>;
  LeadCustomObjectRowAssociation?: InputMaybe<LeadCustomObjectRowAssociationCreateNestedManyWithoutOrganizationInput>;
  LeadFieldMultiplier?: InputMaybe<LeadFieldMultiplierCreateNestedManyWithoutOrganizationInput>;
  LeadFieldMultiplierCustom?: InputMaybe<LeadFieldMultiplierCustomCreateNestedManyWithoutOrganizationInput>;
  leads?: InputMaybe<LeadCreateNestedManyWithoutOrganizationInput>;
  leadsource_options?: InputMaybe<LeadSourceOptionCreateNestedManyWithoutOrganizationInput>;
  LeadValue?: InputMaybe<LeadValueCreateNestedManyWithoutOrganizationInput>;
  LifeTimeValueMultiplier?: InputMaybe<LifeTimeValueMultiplierCreateNestedManyWithoutOrganizationInput>;
  LiveTransferSetting?: InputMaybe<LiveTransferSettingCreateNestedOneWithoutOrganizationInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusCreateNestedManyWithoutOrganizationInput>;
  ltv_multiplier_custom_fields?: InputMaybe<LifeTimeValueMultiplierCustomCreateNestedManyWithoutOrganizationInput>;
  made_contact_rule?: InputMaybe<Scalars['Int']['input']>;
  make_sale_address_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_business_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_city_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_country_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_first_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_industry_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_last_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_lead_source_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_primary_email_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_primary_phone_number_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_state_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_sub_industry_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_zip_required?: InputMaybe<Scalars['Boolean']['input']>;
  maxium_day_lag_set_to_schedule?: InputMaybe<Scalars['Int']['input']>;
  mrr?: InputMaybe<Scalars['Int']['input']>;
  mrr_label?: InputMaybe<Scalars['String']['input']>;
  mrr_required_on_sale?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  NoteItems?: InputMaybe<NoteItemCreateNestedManyWithoutOrganizationInput>;
  Notifications?: InputMaybe<NotificationCreateNestedManyWithoutOrganizationInput>;
  onboardComplete?: InputMaybe<Scalars['Boolean']['input']>;
  Opportunity?: InputMaybe<SfOpportunityCreateNestedManyWithoutOrganizationInput>;
  organization_holidays?: InputMaybe<OrganizationHolidayCreateNestedManyWithoutOrganizationInput>;
  organization_hours?: InputMaybe<OrganizationHourCreateNestedManyWithoutOrganizationInput>;
  OrganizationPaymentSetting?: InputMaybe<OrganizationPaymentSettingsCreateNestedOneWithoutOrganizationInput>;
  OrganizationPaymentSettings?: InputMaybe<OrganizationPaymentSettingsCreateNestedOneWithoutOrganizationsInput>;
  orgCalendarInviteMetadata?: InputMaybe<OrgCalendarInviteMetadataCreateNestedManyWithoutOrganizationInput>;
  OrgDefaultPipelinePerecentValue?: InputMaybe<OrgDefaultPipelinePerecentValueCreateNestedOneWithoutOrganizationInput>;
  OrgHubSpotSync?: InputMaybe<OrgHubSpotSyncCreateNestedOneWithoutOrganizationInput>;
  OrgNoEventDate?: InputMaybe<OrgNoEventDateCreateNestedManyWithoutOrganizationInput>;
  OrgSalesforceSync?: InputMaybe<OrgSalesforceSyncCreateNestedOneWithoutOrganizationInput>;
  OrgTimeslotAvailability?: InputMaybe<OrgTimeslotAvailabilityCreateNestedManyWithoutOrganizationInput>;
  outbound_close_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_dm_connect_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_lt_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_revenue_value_of_sale?: InputMaybe<Scalars['Float']['input']>;
  outbound_scheduled_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_set_rate?: InputMaybe<Scalars['Float']['input']>;
  PandadocData?: InputMaybe<PandadocDataCreateNestedOneWithoutOrganizationInput>;
  PandaDocTemplate?: InputMaybe<PandaDocTemplateCreateNestedManyWithoutOrganizationInput>;
  participant_jitter_buffer_size?: InputMaybe<JitterBufferSize>;
  preferred_goal_setting?: InputMaybe<Goalsetting>;
  prepayment?: InputMaybe<Scalars['Boolean']['input']>;
  ProductChangeLog?: InputMaybe<ProductChangeLogCreateNestedManyWithoutOrganizationInput>;
  products?: InputMaybe<ProductCreateNestedManyWithoutOrganizationInput>;
  re_cold_call_hour_delay?: InputMaybe<Scalars['Float']['input']>;
  recordingStatus?: InputMaybe<RecordingStatus>;
  release_lead_resting?: InputMaybe<Scalars['Int']['input']>;
  rep_claim_limit?: InputMaybe<Scalars['Int']['input']>;
  rep_lead_allotment?: InputMaybe<Scalars['Int']['input']>;
  rep_level_call_transfer?: InputMaybe<Scalars['Boolean']['input']>;
  reps_can_bulk_unassign?: InputMaybe<Scalars['Boolean']['input']>;
  reps_can_view_all_notes?: InputMaybe<Scalars['Boolean']['input']>;
  require_sale_notes?: InputMaybe<Scalars['Boolean']['input']>;
  resting_after_skipped_CC?: InputMaybe<Scalars['Int']['input']>;
  resting_period?: InputMaybe<Scalars['Int']['input']>;
  resting_period_after_unsuccessful_cold_call?: InputMaybe<Scalars['Float']['input']>;
  resting_period_after_unsuccessful_demo?: InputMaybe<Scalars['Float']['input']>;
  revenue_plan?: InputMaybe<RevenuePlanItemCreateNestedManyWithoutOrganizationInput>;
  Routings?: InputMaybe<RoutingCreateNestedManyWithoutOrganizationInput>;
  sale_field_options?: InputMaybe<SaleFieldOptionCreateNestedManyWithoutOrganizationInput>;
  sale_notification_email_list?: InputMaybe<OrganizationCreatesale_Notification_Email_ListInput>;
  SaleConfig?: InputMaybe<SaleConfigCreateNestedOneWithoutOrganizationInput>;
  SaleConfigPage?: InputMaybe<SaleConfigPageCreateNestedManyWithoutOrganizationInput>;
  SaleConfigSection?: InputMaybe<SaleConfigSectionCreateNestedManyWithoutOrganizationInput>;
  SaleCycles?: InputMaybe<SaleCycleCreateNestedManyWithoutOrganizationInput>;
  SaleItems?: InputMaybe<SaleItemCreateNestedManyWithoutOrganizationInput>;
  sales?: InputMaybe<SaleCreateNestedManyWithoutOrganizationInput>;
  salesforce_domain?: InputMaybe<Scalars['String']['input']>;
  salesforce_last_sync_date?: InputMaybe<Scalars['DateTime']['input']>;
  SalesForceColumn?: InputMaybe<SalesForceColumnCreateNestedManyWithoutOrganizationInput>;
  salesForceData?: InputMaybe<SalesForceDataCreateNestedOneWithoutOrganizationInput>;
  ScheduleItems?: InputMaybe<ScheduleItemCreateNestedManyWithoutOrganizationInput>;
  self_sourcing_rule?: InputMaybe<Scalars['Int']['input']>;
  send_error_log_email?: InputMaybe<Scalars['Boolean']['input']>;
  send_lead_routing_email?: InputMaybe<Scalars['Boolean']['input']>;
  sendgrid_email_domain?: InputMaybe<Scalars['String']['input']>;
  sendgrid_email_domain_id?: InputMaybe<Scalars['Int']['input']>;
  sequence?: InputMaybe<SequenceCreateNestedManyWithoutOrganzationInput>;
  SequenceCriteriaConditions?: InputMaybe<SequenceCriteriaConditionCreateNestedManyWithoutOrganizationInput>;
  SequenceDashboardViews?: InputMaybe<SequenceDashboardViewCreateNestedManyWithoutOrganizationInput>;
  SequenceEntryCriterias?: InputMaybe<SequenceEntryCriteriaCreateNestedManyWithoutOrganizationInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutOrganizationInput>;
  SequenceSteps?: InputMaybe<SequenceStepCreateNestedManyWithoutOrganizationInput>;
  set_goal?: InputMaybe<Scalars['Float']['input']>;
  short_resting?: InputMaybe<Scalars['Int']['input']>;
  show_calendar_options?: InputMaybe<Scalars['Boolean']['input']>;
  show_contract_duration_sales?: InputMaybe<Scalars['Boolean']['input']>;
  show_lead_source_to_rep?: InputMaybe<Scalars['Boolean']['input']>;
  show_nocontact_button?: InputMaybe<Scalars['Boolean']['input']>;
  show_payment_terms_sales?: InputMaybe<Scalars['Boolean']['input']>;
  show_rep_all_leads?: InputMaybe<Scalars['Boolean']['input']>;
  show_rep_my_leads?: InputMaybe<Scalars['Boolean']['input']>;
  show_system_view?: InputMaybe<Scalars['Boolean']['input']>;
  sites?: InputMaybe<SiteCreateNestedManyWithoutOrganizationInput>;
  sms_opt_in_dialog?: InputMaybe<Scalars['String']['input']>;
  SMSItems?: InputMaybe<SmsItemCreateNestedManyWithoutOrganizationInput>;
  SnoozeTrackings?: InputMaybe<SnoozeTrackingCreateNestedManyWithoutOrganizationInput>;
  StepActionLogs?: InputMaybe<StepActionLogCreateNestedManyWithoutOrganizationInput>;
  StepActions?: InputMaybe<StepActionCreateNestedManyWithoutOrganizationInput>;
  StepConditions?: InputMaybe<StepConditionCreateNestedManyWithoutOrganizationInput>;
  teams?: InputMaybe<TeamCreateNestedManyWithoutOrganizationInput>;
  Template?: InputMaybe<TemplateCreateNestedManyWithoutOrganizationInput>;
  TemplateVersions?: InputMaybe<TemplateVersionCreateNestedManyWithoutOrganizationInput>;
  timekit_id?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  transfer_timeout?: InputMaybe<Scalars['Int']['input']>;
  TransferAttempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutOrganizationInput>;
  twilio_numbers?: InputMaybe<TwilioNumberCreateNestedManyWithoutOrganizationInput>;
  TwilioFeedbacks?: InputMaybe<TwilioFeedbackCreateNestedManyWithoutOrganizationInput>;
  TwilioInfo?: InputMaybe<TwilioInfoCreateNestedOneWithoutOrganzationInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  use_revenue_planning?: InputMaybe<Scalars['Boolean']['input']>;
  UserCustomFieldFilters?: InputMaybe<UserCustomFieldFilterCreateNestedManyWithoutOrganizationInput>;
  users?: InputMaybe<UserCreateNestedManyWithoutOrganizationInput>;
  win_rep_day_goal?: InputMaybe<Scalars['Float']['input']>;
};

export type OrganizationCreateWithoutInboundConciergeEventsInput = {
  ActivityMonitorMultiplier?: InputMaybe<ActivityMonitorMultiplierCreateNestedManyWithoutOrganizationInput>;
  algoFactor?: InputMaybe<AlgoFactorCreateNestedManyWithoutOrganizationInput>;
  allow_calendar_invite?: InputMaybe<Scalars['Boolean']['input']>;
  allow_remove_step_action?: InputMaybe<Scalars['Boolean']['input']>;
  allow_snooze_step_action?: InputMaybe<Scalars['Boolean']['input']>;
  always_record?: InputMaybe<Scalars['Boolean']['input']>;
  arv_initial_held_demos_generated_by_sdr_weight?: InputMaybe<Scalars['Float']['input']>;
  AssociationHistories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutOrganizationInput>;
  auto_call_interval?: InputMaybe<Scalars['Int']['input']>;
  auto_dial?: InputMaybe<Scalars['Boolean']['input']>;
  automated_campaigns?: InputMaybe<AutomatedCampaignCreateNestedManyWithoutOrganizationInput>;
  automatic_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  AutoTransferSelections?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutOrganizationInput>;
  Availablities?: InputMaybe<AvailabilityCreateNestedManyWithoutOrganizationInput>;
  AverageOrderValue?: InputMaybe<AverageOrderValueCreateNestedManyWithoutOrganizationInput>;
  best_action_ever_multiplier?: InputMaybe<Scalars['Float']['input']>;
  best_metrics?: InputMaybe<BestMetricRecordCreateNestedManyWithoutOrganizationInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptCreateNestedManyWithoutOrganizationInput>;
  callMeNows?: InputMaybe<CallMeNowCreateNestedManyWithoutOrganizationInput>;
  CallMultiplier?: InputMaybe<CallMultiplierCreateNestedManyWithoutOrganizationInput>;
  CallSharings?: InputMaybe<CallSharingCreateNestedManyWithoutOrganizationInput>;
  can_edit_sdr_transfer_rules?: InputMaybe<Scalars['Boolean']['input']>;
  claim_rule?: InputMaybe<Scalars['Int']['input']>;
  concession?: InputMaybe<ConcessionCreateNestedManyWithoutOrganizationInput>;
  conference_region?: InputMaybe<ConferenceRegion>;
  Conferences?: InputMaybe<ConferenceCreateNestedManyWithoutOrganizationInput>;
  ContactRequests?: InputMaybe<ContactRequestCreateNestedManyWithoutOrganizationInput>;
  Contacts?: InputMaybe<ContactCreateNestedManyWithoutOrganizationInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  custom_fields?: InputMaybe<CustomFieldCreateNestedManyWithoutOrganizationInput>;
  CustomObject?: InputMaybe<CustomObjectCreateNestedManyWithoutOrganizationInput>;
  CustomObjectField?: InputMaybe<CustomObjectFieldCreateNestedManyWithoutOrganizationInput>;
  CustomObjectFieldForeignKey?: InputMaybe<CustomObjectFieldForeignKeyCreateNestedManyWithoutOrganizationInput>;
  CustomObjectRecord?: InputMaybe<CustomObjectRecordCreateNestedManyWithoutOrganizationInput>;
  CustomObjectRow?: InputMaybe<CustomObjectRowCreateNestedManyWithoutOrganizationInput>;
  CustomQueueItems?: InputMaybe<CustomQueueItemCreateNestedManyWithoutOrganizationInput>;
  DashboardColumnOptions?: InputMaybe<DashboardColumnOptionCreateNestedManyWithoutOrganizationInput>;
  DashboardLeadFilters?: InputMaybe<DashboardLeadFilterCreateNestedManyWithoutOrganizationInput>;
  DashboardRepFilters?: InputMaybe<DashboardRepFilterCreateNestedManyWithoutOrganizationInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewCreateNestedManyWithoutOrganizationInput>;
  day_to_close?: InputMaybe<Scalars['Int']['input']>;
  default_demo_time_duration?: InputMaybe<Scalars['Int']['input']>;
  demo_held_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_not_held_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_not_held_call_back_short_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_prep_time_minutes?: InputMaybe<Scalars['Int']['input']>;
  dev_account?: InputMaybe<Scalars['Boolean']['input']>;
  dial_rule?: InputMaybe<Scalars['Int']['input']>;
  DispositionMultiplier?: InputMaybe<DispositionMultiplierCreateNestedManyWithoutOrganizationInput>;
  dispositions?: InputMaybe<DispositionCreateNestedManyWithoutOrganizationInput>;
  double_booking?: InputMaybe<Scalars['Boolean']['input']>;
  dynamic_date_operators?: InputMaybe<Scalars['Boolean']['input']>;
  edge_servers?: InputMaybe<OrganizationCreateedge_ServersInput>;
  email_conflict_notification_email_list?: InputMaybe<OrganizationCreateemail_Conflict_Notification_Email_ListInput>;
  EmailItems?: InputMaybe<EmailItemCreateNestedManyWithoutOrganizationInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordCreateNestedManyWithoutOrganizationInput>;
  EmailThreads?: InputMaybe<EmailThreadCreateNestedManyWithoutOrganizationInput>;
  enable_modified_metrics?: InputMaybe<Scalars['Boolean']['input']>;
  enable_pay_period_view?: InputMaybe<Scalars['Boolean']['input']>;
  enable_time_constraint?: InputMaybe<Scalars['Boolean']['input']>;
  error_log_email_address?: InputMaybe<OrganizationCreateerror_Log_Email_AddressInput>;
  estimated_average_num_of_weekly_overbooked_demo_slots?: InputMaybe<Scalars['Int']['input']>;
  FolderPermissions?: InputMaybe<FolderPermissionCreateNestedManyWithoutOrganizationInput>;
  Folders?: InputMaybe<FolderCreateNestedManyWithoutOrganizationInput>;
  FutureBookingSetting?: InputMaybe<FutureBookingSettingCreateNestedOneWithoutOrganizationInput>;
  GlobalTransferRule?: InputMaybe<GlobalTransferRuleCreateNestedManyWithoutOrganizationInput>;
  held_demos_per_cc_connect?: InputMaybe<Scalars['Float']['input']>;
  helpful_links?: InputMaybe<OrganizationCreatehelpful_LinksInput>;
  HingePointMultiplier?: InputMaybe<HingePointMultiplierCreateNestedManyWithoutOrganizationInput>;
  hold_goal?: InputMaybe<Scalars['Float']['input']>;
  hubspot_credential?: InputMaybe<HubSpotCredentialCreateNestedOneWithoutOrganizationInput>;
  hubspot_last_sync_date?: InputMaybe<Scalars['DateTime']['input']>;
  hubspot_white_list?: InputMaybe<HubSpotWhitelistCreateNestedManyWithoutOrganizationInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  inbound_close_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_dm_connect_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_lt_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_revenue_value_of_sale?: InputMaybe<Scalars['Float']['input']>;
  inbound_scheduled_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_set_rate?: InputMaybe<Scalars['Float']['input']>;
  InboundConciergeWidget?: InputMaybe<InboundConciergeWidgetCreateNestedManyWithoutOrganizationInput>;
  industry_options?: InputMaybe<IndustryOptionCreateNestedManyWithoutOrganizationInput>;
  initial_sdr_sets_reps_day?: InputMaybe<Scalars['Float']['input']>;
  integration_error_logs?: InputMaybe<IntegrationErrorLogCreateNestedManyWithoutOrganizationInput>;
  integration_mappings?: InputMaybe<IntegrationMappingCreateNestedManyWithoutOrganizationInput>;
  IntegrationMappingCustomObject?: InputMaybe<IntegrationMappingCustomObjectCreateNestedManyWithoutOrganizationInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogCreateNestedManyWithoutOrganizationInput>;
  IntegrationReadOnlyObject?: InputMaybe<IntegrationReadOnlyObjectCreateNestedManyWithoutOrganizationInput>;
  international?: InputMaybe<Scalars['Boolean']['input']>;
  is_email_domain_verified?: InputMaybe<Scalars['Boolean']['input']>;
  is_salesforce_sandbox?: InputMaybe<Scalars['Boolean']['input']>;
  last_lead_value_compute_time?: InputMaybe<Scalars['DateTime']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutOrganizationInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryCreateNestedManyWithoutOrganizationInput>;
  lead_intent?: InputMaybe<LeadIntentCreateNestedManyWithoutOrganizationInput>;
  lead_release_same_rep_delay_rule?: InputMaybe<Scalars['Int']['input']>;
  lead_routing_email_list?: InputMaybe<OrganizationCreatelead_Routing_Email_ListInput>;
  lead_routing_rules?: InputMaybe<RuleCreateNestedManyWithoutOrganizationInput>;
  LeadCustomObjectRowAssociation?: InputMaybe<LeadCustomObjectRowAssociationCreateNestedManyWithoutOrganizationInput>;
  LeadFieldMultiplier?: InputMaybe<LeadFieldMultiplierCreateNestedManyWithoutOrganizationInput>;
  LeadFieldMultiplierCustom?: InputMaybe<LeadFieldMultiplierCustomCreateNestedManyWithoutOrganizationInput>;
  leads?: InputMaybe<LeadCreateNestedManyWithoutOrganizationInput>;
  leadsource_options?: InputMaybe<LeadSourceOptionCreateNestedManyWithoutOrganizationInput>;
  LeadValue?: InputMaybe<LeadValueCreateNestedManyWithoutOrganizationInput>;
  LifeTimeValueMultiplier?: InputMaybe<LifeTimeValueMultiplierCreateNestedManyWithoutOrganizationInput>;
  LiveTransferSetting?: InputMaybe<LiveTransferSettingCreateNestedOneWithoutOrganizationInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusCreateNestedManyWithoutOrganizationInput>;
  ltv_multiplier_custom_fields?: InputMaybe<LifeTimeValueMultiplierCustomCreateNestedManyWithoutOrganizationInput>;
  made_contact_rule?: InputMaybe<Scalars['Int']['input']>;
  make_sale_address_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_business_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_city_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_country_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_first_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_industry_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_last_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_lead_source_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_primary_email_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_primary_phone_number_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_state_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_sub_industry_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_zip_required?: InputMaybe<Scalars['Boolean']['input']>;
  maxium_day_lag_set_to_schedule?: InputMaybe<Scalars['Int']['input']>;
  mrr?: InputMaybe<Scalars['Int']['input']>;
  mrr_label?: InputMaybe<Scalars['String']['input']>;
  mrr_required_on_sale?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  NoteItems?: InputMaybe<NoteItemCreateNestedManyWithoutOrganizationInput>;
  Notifications?: InputMaybe<NotificationCreateNestedManyWithoutOrganizationInput>;
  onboardComplete?: InputMaybe<Scalars['Boolean']['input']>;
  Opportunity?: InputMaybe<SfOpportunityCreateNestedManyWithoutOrganizationInput>;
  organization_holidays?: InputMaybe<OrganizationHolidayCreateNestedManyWithoutOrganizationInput>;
  organization_hours?: InputMaybe<OrganizationHourCreateNestedManyWithoutOrganizationInput>;
  OrganizationPaymentSetting?: InputMaybe<OrganizationPaymentSettingsCreateNestedOneWithoutOrganizationInput>;
  OrganizationPaymentSettings?: InputMaybe<OrganizationPaymentSettingsCreateNestedOneWithoutOrganizationsInput>;
  orgCalendarInviteMetadata?: InputMaybe<OrgCalendarInviteMetadataCreateNestedManyWithoutOrganizationInput>;
  OrgDefaultPipelinePerecentValue?: InputMaybe<OrgDefaultPipelinePerecentValueCreateNestedOneWithoutOrganizationInput>;
  OrgHubSpotSync?: InputMaybe<OrgHubSpotSyncCreateNestedOneWithoutOrganizationInput>;
  OrgNoEventDate?: InputMaybe<OrgNoEventDateCreateNestedManyWithoutOrganizationInput>;
  OrgSalesforceSync?: InputMaybe<OrgSalesforceSyncCreateNestedOneWithoutOrganizationInput>;
  OrgTimeslotAvailability?: InputMaybe<OrgTimeslotAvailabilityCreateNestedManyWithoutOrganizationInput>;
  outbound_close_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_dm_connect_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_lt_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_revenue_value_of_sale?: InputMaybe<Scalars['Float']['input']>;
  outbound_scheduled_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_set_rate?: InputMaybe<Scalars['Float']['input']>;
  PandadocData?: InputMaybe<PandadocDataCreateNestedOneWithoutOrganizationInput>;
  PandaDocTemplate?: InputMaybe<PandaDocTemplateCreateNestedManyWithoutOrganizationInput>;
  participant_jitter_buffer_size?: InputMaybe<JitterBufferSize>;
  preferred_goal_setting?: InputMaybe<Goalsetting>;
  prepayment?: InputMaybe<Scalars['Boolean']['input']>;
  ProductChangeLog?: InputMaybe<ProductChangeLogCreateNestedManyWithoutOrganizationInput>;
  products?: InputMaybe<ProductCreateNestedManyWithoutOrganizationInput>;
  re_cold_call_hour_delay?: InputMaybe<Scalars['Float']['input']>;
  recordingStatus?: InputMaybe<RecordingStatus>;
  release_lead_resting?: InputMaybe<Scalars['Int']['input']>;
  rep_claim_limit?: InputMaybe<Scalars['Int']['input']>;
  rep_lead_allotment?: InputMaybe<Scalars['Int']['input']>;
  rep_level_call_transfer?: InputMaybe<Scalars['Boolean']['input']>;
  reps_can_bulk_unassign?: InputMaybe<Scalars['Boolean']['input']>;
  reps_can_view_all_notes?: InputMaybe<Scalars['Boolean']['input']>;
  require_sale_notes?: InputMaybe<Scalars['Boolean']['input']>;
  resting_after_skipped_CC?: InputMaybe<Scalars['Int']['input']>;
  resting_period?: InputMaybe<Scalars['Int']['input']>;
  resting_period_after_unsuccessful_cold_call?: InputMaybe<Scalars['Float']['input']>;
  resting_period_after_unsuccessful_demo?: InputMaybe<Scalars['Float']['input']>;
  revenue_plan?: InputMaybe<RevenuePlanItemCreateNestedManyWithoutOrganizationInput>;
  Routings?: InputMaybe<RoutingCreateNestedManyWithoutOrganizationInput>;
  sale_field_options?: InputMaybe<SaleFieldOptionCreateNestedManyWithoutOrganizationInput>;
  sale_notification_email_list?: InputMaybe<OrganizationCreatesale_Notification_Email_ListInput>;
  SaleConfig?: InputMaybe<SaleConfigCreateNestedOneWithoutOrganizationInput>;
  SaleConfigPage?: InputMaybe<SaleConfigPageCreateNestedManyWithoutOrganizationInput>;
  SaleConfigSection?: InputMaybe<SaleConfigSectionCreateNestedManyWithoutOrganizationInput>;
  SaleCycles?: InputMaybe<SaleCycleCreateNestedManyWithoutOrganizationInput>;
  SaleItems?: InputMaybe<SaleItemCreateNestedManyWithoutOrganizationInput>;
  sales?: InputMaybe<SaleCreateNestedManyWithoutOrganizationInput>;
  salesforce_domain?: InputMaybe<Scalars['String']['input']>;
  salesforce_last_sync_date?: InputMaybe<Scalars['DateTime']['input']>;
  SalesForceColumn?: InputMaybe<SalesForceColumnCreateNestedManyWithoutOrganizationInput>;
  salesForceData?: InputMaybe<SalesForceDataCreateNestedOneWithoutOrganizationInput>;
  ScheduleItems?: InputMaybe<ScheduleItemCreateNestedManyWithoutOrganizationInput>;
  self_sourcing_rule?: InputMaybe<Scalars['Int']['input']>;
  send_error_log_email?: InputMaybe<Scalars['Boolean']['input']>;
  send_lead_routing_email?: InputMaybe<Scalars['Boolean']['input']>;
  sendgrid_email_domain?: InputMaybe<Scalars['String']['input']>;
  sendgrid_email_domain_id?: InputMaybe<Scalars['Int']['input']>;
  sequence?: InputMaybe<SequenceCreateNestedManyWithoutOrganzationInput>;
  SequenceCriteriaConditions?: InputMaybe<SequenceCriteriaConditionCreateNestedManyWithoutOrganizationInput>;
  SequenceDashboardViews?: InputMaybe<SequenceDashboardViewCreateNestedManyWithoutOrganizationInput>;
  SequenceEntryCriterias?: InputMaybe<SequenceEntryCriteriaCreateNestedManyWithoutOrganizationInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutOrganizationInput>;
  SequenceSteps?: InputMaybe<SequenceStepCreateNestedManyWithoutOrganizationInput>;
  set_goal?: InputMaybe<Scalars['Float']['input']>;
  short_resting?: InputMaybe<Scalars['Int']['input']>;
  show_calendar_options?: InputMaybe<Scalars['Boolean']['input']>;
  show_contract_duration_sales?: InputMaybe<Scalars['Boolean']['input']>;
  show_lead_source_to_rep?: InputMaybe<Scalars['Boolean']['input']>;
  show_nocontact_button?: InputMaybe<Scalars['Boolean']['input']>;
  show_payment_terms_sales?: InputMaybe<Scalars['Boolean']['input']>;
  show_rep_all_leads?: InputMaybe<Scalars['Boolean']['input']>;
  show_rep_my_leads?: InputMaybe<Scalars['Boolean']['input']>;
  show_system_view?: InputMaybe<Scalars['Boolean']['input']>;
  sites?: InputMaybe<SiteCreateNestedManyWithoutOrganizationInput>;
  sms_opt_in_dialog?: InputMaybe<Scalars['String']['input']>;
  SMSItems?: InputMaybe<SmsItemCreateNestedManyWithoutOrganizationInput>;
  SnoozeTrackings?: InputMaybe<SnoozeTrackingCreateNestedManyWithoutOrganizationInput>;
  StepActionLogs?: InputMaybe<StepActionLogCreateNestedManyWithoutOrganizationInput>;
  StepActions?: InputMaybe<StepActionCreateNestedManyWithoutOrganizationInput>;
  StepConditions?: InputMaybe<StepConditionCreateNestedManyWithoutOrganizationInput>;
  teams?: InputMaybe<TeamCreateNestedManyWithoutOrganizationInput>;
  Template?: InputMaybe<TemplateCreateNestedManyWithoutOrganizationInput>;
  TemplateVersions?: InputMaybe<TemplateVersionCreateNestedManyWithoutOrganizationInput>;
  timekit_id?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  transfer_timeout?: InputMaybe<Scalars['Int']['input']>;
  TransferAttempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutOrganizationInput>;
  twilio_numbers?: InputMaybe<TwilioNumberCreateNestedManyWithoutOrganizationInput>;
  TwilioFeedbacks?: InputMaybe<TwilioFeedbackCreateNestedManyWithoutOrganizationInput>;
  TwilioInfo?: InputMaybe<TwilioInfoCreateNestedOneWithoutOrganzationInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  use_revenue_planning?: InputMaybe<Scalars['Boolean']['input']>;
  UserCustomFieldFilters?: InputMaybe<UserCustomFieldFilterCreateNestedManyWithoutOrganizationInput>;
  users?: InputMaybe<UserCreateNestedManyWithoutOrganizationInput>;
  win_rep_day_goal?: InputMaybe<Scalars['Float']['input']>;
};

export type OrganizationCreateWithoutIntegration_Error_LogsInput = {
  ActivityMonitorMultiplier?: InputMaybe<ActivityMonitorMultiplierCreateNestedManyWithoutOrganizationInput>;
  algoFactor?: InputMaybe<AlgoFactorCreateNestedManyWithoutOrganizationInput>;
  allow_calendar_invite?: InputMaybe<Scalars['Boolean']['input']>;
  allow_remove_step_action?: InputMaybe<Scalars['Boolean']['input']>;
  allow_snooze_step_action?: InputMaybe<Scalars['Boolean']['input']>;
  always_record?: InputMaybe<Scalars['Boolean']['input']>;
  arv_initial_held_demos_generated_by_sdr_weight?: InputMaybe<Scalars['Float']['input']>;
  AssociationHistories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutOrganizationInput>;
  auto_call_interval?: InputMaybe<Scalars['Int']['input']>;
  auto_dial?: InputMaybe<Scalars['Boolean']['input']>;
  automated_campaigns?: InputMaybe<AutomatedCampaignCreateNestedManyWithoutOrganizationInput>;
  automatic_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  AutoTransferSelections?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutOrganizationInput>;
  Availablities?: InputMaybe<AvailabilityCreateNestedManyWithoutOrganizationInput>;
  AverageOrderValue?: InputMaybe<AverageOrderValueCreateNestedManyWithoutOrganizationInput>;
  best_action_ever_multiplier?: InputMaybe<Scalars['Float']['input']>;
  best_metrics?: InputMaybe<BestMetricRecordCreateNestedManyWithoutOrganizationInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptCreateNestedManyWithoutOrganizationInput>;
  callMeNows?: InputMaybe<CallMeNowCreateNestedManyWithoutOrganizationInput>;
  CallMultiplier?: InputMaybe<CallMultiplierCreateNestedManyWithoutOrganizationInput>;
  CallSharings?: InputMaybe<CallSharingCreateNestedManyWithoutOrganizationInput>;
  can_edit_sdr_transfer_rules?: InputMaybe<Scalars['Boolean']['input']>;
  claim_rule?: InputMaybe<Scalars['Int']['input']>;
  concession?: InputMaybe<ConcessionCreateNestedManyWithoutOrganizationInput>;
  conference_region?: InputMaybe<ConferenceRegion>;
  Conferences?: InputMaybe<ConferenceCreateNestedManyWithoutOrganizationInput>;
  ContactRequests?: InputMaybe<ContactRequestCreateNestedManyWithoutOrganizationInput>;
  Contacts?: InputMaybe<ContactCreateNestedManyWithoutOrganizationInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  custom_fields?: InputMaybe<CustomFieldCreateNestedManyWithoutOrganizationInput>;
  CustomObject?: InputMaybe<CustomObjectCreateNestedManyWithoutOrganizationInput>;
  CustomObjectField?: InputMaybe<CustomObjectFieldCreateNestedManyWithoutOrganizationInput>;
  CustomObjectFieldForeignKey?: InputMaybe<CustomObjectFieldForeignKeyCreateNestedManyWithoutOrganizationInput>;
  CustomObjectRecord?: InputMaybe<CustomObjectRecordCreateNestedManyWithoutOrganizationInput>;
  CustomObjectRow?: InputMaybe<CustomObjectRowCreateNestedManyWithoutOrganizationInput>;
  CustomQueueItems?: InputMaybe<CustomQueueItemCreateNestedManyWithoutOrganizationInput>;
  DashboardColumnOptions?: InputMaybe<DashboardColumnOptionCreateNestedManyWithoutOrganizationInput>;
  DashboardLeadFilters?: InputMaybe<DashboardLeadFilterCreateNestedManyWithoutOrganizationInput>;
  DashboardRepFilters?: InputMaybe<DashboardRepFilterCreateNestedManyWithoutOrganizationInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewCreateNestedManyWithoutOrganizationInput>;
  day_to_close?: InputMaybe<Scalars['Int']['input']>;
  default_demo_time_duration?: InputMaybe<Scalars['Int']['input']>;
  demo_held_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_not_held_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_not_held_call_back_short_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_prep_time_minutes?: InputMaybe<Scalars['Int']['input']>;
  dev_account?: InputMaybe<Scalars['Boolean']['input']>;
  dial_rule?: InputMaybe<Scalars['Int']['input']>;
  DispositionMultiplier?: InputMaybe<DispositionMultiplierCreateNestedManyWithoutOrganizationInput>;
  dispositions?: InputMaybe<DispositionCreateNestedManyWithoutOrganizationInput>;
  double_booking?: InputMaybe<Scalars['Boolean']['input']>;
  dynamic_date_operators?: InputMaybe<Scalars['Boolean']['input']>;
  edge_servers?: InputMaybe<OrganizationCreateedge_ServersInput>;
  email_conflict_notification_email_list?: InputMaybe<OrganizationCreateemail_Conflict_Notification_Email_ListInput>;
  EmailItems?: InputMaybe<EmailItemCreateNestedManyWithoutOrganizationInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordCreateNestedManyWithoutOrganizationInput>;
  EmailThreads?: InputMaybe<EmailThreadCreateNestedManyWithoutOrganizationInput>;
  enable_modified_metrics?: InputMaybe<Scalars['Boolean']['input']>;
  enable_pay_period_view?: InputMaybe<Scalars['Boolean']['input']>;
  enable_time_constraint?: InputMaybe<Scalars['Boolean']['input']>;
  error_log_email_address?: InputMaybe<OrganizationCreateerror_Log_Email_AddressInput>;
  estimated_average_num_of_weekly_overbooked_demo_slots?: InputMaybe<Scalars['Int']['input']>;
  FolderPermissions?: InputMaybe<FolderPermissionCreateNestedManyWithoutOrganizationInput>;
  Folders?: InputMaybe<FolderCreateNestedManyWithoutOrganizationInput>;
  FutureBookingSetting?: InputMaybe<FutureBookingSettingCreateNestedOneWithoutOrganizationInput>;
  GlobalTransferRule?: InputMaybe<GlobalTransferRuleCreateNestedManyWithoutOrganizationInput>;
  held_demos_per_cc_connect?: InputMaybe<Scalars['Float']['input']>;
  helpful_links?: InputMaybe<OrganizationCreatehelpful_LinksInput>;
  HingePointMultiplier?: InputMaybe<HingePointMultiplierCreateNestedManyWithoutOrganizationInput>;
  hold_goal?: InputMaybe<Scalars['Float']['input']>;
  hubspot_credential?: InputMaybe<HubSpotCredentialCreateNestedOneWithoutOrganizationInput>;
  hubspot_last_sync_date?: InputMaybe<Scalars['DateTime']['input']>;
  hubspot_white_list?: InputMaybe<HubSpotWhitelistCreateNestedManyWithoutOrganizationInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  inbound_close_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_dm_connect_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_lt_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_revenue_value_of_sale?: InputMaybe<Scalars['Float']['input']>;
  inbound_scheduled_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_set_rate?: InputMaybe<Scalars['Float']['input']>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventCreateNestedManyWithoutOrganizationInput>;
  InboundConciergeWidget?: InputMaybe<InboundConciergeWidgetCreateNestedManyWithoutOrganizationInput>;
  industry_options?: InputMaybe<IndustryOptionCreateNestedManyWithoutOrganizationInput>;
  initial_sdr_sets_reps_day?: InputMaybe<Scalars['Float']['input']>;
  integration_mappings?: InputMaybe<IntegrationMappingCreateNestedManyWithoutOrganizationInput>;
  IntegrationMappingCustomObject?: InputMaybe<IntegrationMappingCustomObjectCreateNestedManyWithoutOrganizationInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogCreateNestedManyWithoutOrganizationInput>;
  IntegrationReadOnlyObject?: InputMaybe<IntegrationReadOnlyObjectCreateNestedManyWithoutOrganizationInput>;
  international?: InputMaybe<Scalars['Boolean']['input']>;
  is_email_domain_verified?: InputMaybe<Scalars['Boolean']['input']>;
  is_salesforce_sandbox?: InputMaybe<Scalars['Boolean']['input']>;
  last_lead_value_compute_time?: InputMaybe<Scalars['DateTime']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutOrganizationInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryCreateNestedManyWithoutOrganizationInput>;
  lead_intent?: InputMaybe<LeadIntentCreateNestedManyWithoutOrganizationInput>;
  lead_release_same_rep_delay_rule?: InputMaybe<Scalars['Int']['input']>;
  lead_routing_email_list?: InputMaybe<OrganizationCreatelead_Routing_Email_ListInput>;
  lead_routing_rules?: InputMaybe<RuleCreateNestedManyWithoutOrganizationInput>;
  LeadCustomObjectRowAssociation?: InputMaybe<LeadCustomObjectRowAssociationCreateNestedManyWithoutOrganizationInput>;
  LeadFieldMultiplier?: InputMaybe<LeadFieldMultiplierCreateNestedManyWithoutOrganizationInput>;
  LeadFieldMultiplierCustom?: InputMaybe<LeadFieldMultiplierCustomCreateNestedManyWithoutOrganizationInput>;
  leads?: InputMaybe<LeadCreateNestedManyWithoutOrganizationInput>;
  leadsource_options?: InputMaybe<LeadSourceOptionCreateNestedManyWithoutOrganizationInput>;
  LeadValue?: InputMaybe<LeadValueCreateNestedManyWithoutOrganizationInput>;
  LifeTimeValueMultiplier?: InputMaybe<LifeTimeValueMultiplierCreateNestedManyWithoutOrganizationInput>;
  LiveTransferSetting?: InputMaybe<LiveTransferSettingCreateNestedOneWithoutOrganizationInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusCreateNestedManyWithoutOrganizationInput>;
  ltv_multiplier_custom_fields?: InputMaybe<LifeTimeValueMultiplierCustomCreateNestedManyWithoutOrganizationInput>;
  made_contact_rule?: InputMaybe<Scalars['Int']['input']>;
  make_sale_address_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_business_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_city_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_country_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_first_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_industry_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_last_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_lead_source_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_primary_email_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_primary_phone_number_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_state_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_sub_industry_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_zip_required?: InputMaybe<Scalars['Boolean']['input']>;
  maxium_day_lag_set_to_schedule?: InputMaybe<Scalars['Int']['input']>;
  mrr?: InputMaybe<Scalars['Int']['input']>;
  mrr_label?: InputMaybe<Scalars['String']['input']>;
  mrr_required_on_sale?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  NoteItems?: InputMaybe<NoteItemCreateNestedManyWithoutOrganizationInput>;
  Notifications?: InputMaybe<NotificationCreateNestedManyWithoutOrganizationInput>;
  onboardComplete?: InputMaybe<Scalars['Boolean']['input']>;
  Opportunity?: InputMaybe<SfOpportunityCreateNestedManyWithoutOrganizationInput>;
  organization_holidays?: InputMaybe<OrganizationHolidayCreateNestedManyWithoutOrganizationInput>;
  organization_hours?: InputMaybe<OrganizationHourCreateNestedManyWithoutOrganizationInput>;
  OrganizationPaymentSetting?: InputMaybe<OrganizationPaymentSettingsCreateNestedOneWithoutOrganizationInput>;
  OrganizationPaymentSettings?: InputMaybe<OrganizationPaymentSettingsCreateNestedOneWithoutOrganizationsInput>;
  orgCalendarInviteMetadata?: InputMaybe<OrgCalendarInviteMetadataCreateNestedManyWithoutOrganizationInput>;
  OrgDefaultPipelinePerecentValue?: InputMaybe<OrgDefaultPipelinePerecentValueCreateNestedOneWithoutOrganizationInput>;
  OrgHubSpotSync?: InputMaybe<OrgHubSpotSyncCreateNestedOneWithoutOrganizationInput>;
  OrgNoEventDate?: InputMaybe<OrgNoEventDateCreateNestedManyWithoutOrganizationInput>;
  OrgSalesforceSync?: InputMaybe<OrgSalesforceSyncCreateNestedOneWithoutOrganizationInput>;
  OrgTimeslotAvailability?: InputMaybe<OrgTimeslotAvailabilityCreateNestedManyWithoutOrganizationInput>;
  outbound_close_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_dm_connect_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_lt_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_revenue_value_of_sale?: InputMaybe<Scalars['Float']['input']>;
  outbound_scheduled_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_set_rate?: InputMaybe<Scalars['Float']['input']>;
  PandadocData?: InputMaybe<PandadocDataCreateNestedOneWithoutOrganizationInput>;
  PandaDocTemplate?: InputMaybe<PandaDocTemplateCreateNestedManyWithoutOrganizationInput>;
  participant_jitter_buffer_size?: InputMaybe<JitterBufferSize>;
  preferred_goal_setting?: InputMaybe<Goalsetting>;
  prepayment?: InputMaybe<Scalars['Boolean']['input']>;
  ProductChangeLog?: InputMaybe<ProductChangeLogCreateNestedManyWithoutOrganizationInput>;
  products?: InputMaybe<ProductCreateNestedManyWithoutOrganizationInput>;
  re_cold_call_hour_delay?: InputMaybe<Scalars['Float']['input']>;
  recordingStatus?: InputMaybe<RecordingStatus>;
  release_lead_resting?: InputMaybe<Scalars['Int']['input']>;
  rep_claim_limit?: InputMaybe<Scalars['Int']['input']>;
  rep_lead_allotment?: InputMaybe<Scalars['Int']['input']>;
  rep_level_call_transfer?: InputMaybe<Scalars['Boolean']['input']>;
  reps_can_bulk_unassign?: InputMaybe<Scalars['Boolean']['input']>;
  reps_can_view_all_notes?: InputMaybe<Scalars['Boolean']['input']>;
  require_sale_notes?: InputMaybe<Scalars['Boolean']['input']>;
  resting_after_skipped_CC?: InputMaybe<Scalars['Int']['input']>;
  resting_period?: InputMaybe<Scalars['Int']['input']>;
  resting_period_after_unsuccessful_cold_call?: InputMaybe<Scalars['Float']['input']>;
  resting_period_after_unsuccessful_demo?: InputMaybe<Scalars['Float']['input']>;
  revenue_plan?: InputMaybe<RevenuePlanItemCreateNestedManyWithoutOrganizationInput>;
  Routings?: InputMaybe<RoutingCreateNestedManyWithoutOrganizationInput>;
  sale_field_options?: InputMaybe<SaleFieldOptionCreateNestedManyWithoutOrganizationInput>;
  sale_notification_email_list?: InputMaybe<OrganizationCreatesale_Notification_Email_ListInput>;
  SaleConfig?: InputMaybe<SaleConfigCreateNestedOneWithoutOrganizationInput>;
  SaleConfigPage?: InputMaybe<SaleConfigPageCreateNestedManyWithoutOrganizationInput>;
  SaleConfigSection?: InputMaybe<SaleConfigSectionCreateNestedManyWithoutOrganizationInput>;
  SaleCycles?: InputMaybe<SaleCycleCreateNestedManyWithoutOrganizationInput>;
  SaleItems?: InputMaybe<SaleItemCreateNestedManyWithoutOrganizationInput>;
  sales?: InputMaybe<SaleCreateNestedManyWithoutOrganizationInput>;
  salesforce_domain?: InputMaybe<Scalars['String']['input']>;
  salesforce_last_sync_date?: InputMaybe<Scalars['DateTime']['input']>;
  SalesForceColumn?: InputMaybe<SalesForceColumnCreateNestedManyWithoutOrganizationInput>;
  salesForceData?: InputMaybe<SalesForceDataCreateNestedOneWithoutOrganizationInput>;
  ScheduleItems?: InputMaybe<ScheduleItemCreateNestedManyWithoutOrganizationInput>;
  self_sourcing_rule?: InputMaybe<Scalars['Int']['input']>;
  send_error_log_email?: InputMaybe<Scalars['Boolean']['input']>;
  send_lead_routing_email?: InputMaybe<Scalars['Boolean']['input']>;
  sendgrid_email_domain?: InputMaybe<Scalars['String']['input']>;
  sendgrid_email_domain_id?: InputMaybe<Scalars['Int']['input']>;
  sequence?: InputMaybe<SequenceCreateNestedManyWithoutOrganzationInput>;
  SequenceCriteriaConditions?: InputMaybe<SequenceCriteriaConditionCreateNestedManyWithoutOrganizationInput>;
  SequenceDashboardViews?: InputMaybe<SequenceDashboardViewCreateNestedManyWithoutOrganizationInput>;
  SequenceEntryCriterias?: InputMaybe<SequenceEntryCriteriaCreateNestedManyWithoutOrganizationInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutOrganizationInput>;
  SequenceSteps?: InputMaybe<SequenceStepCreateNestedManyWithoutOrganizationInput>;
  set_goal?: InputMaybe<Scalars['Float']['input']>;
  short_resting?: InputMaybe<Scalars['Int']['input']>;
  show_calendar_options?: InputMaybe<Scalars['Boolean']['input']>;
  show_contract_duration_sales?: InputMaybe<Scalars['Boolean']['input']>;
  show_lead_source_to_rep?: InputMaybe<Scalars['Boolean']['input']>;
  show_nocontact_button?: InputMaybe<Scalars['Boolean']['input']>;
  show_payment_terms_sales?: InputMaybe<Scalars['Boolean']['input']>;
  show_rep_all_leads?: InputMaybe<Scalars['Boolean']['input']>;
  show_rep_my_leads?: InputMaybe<Scalars['Boolean']['input']>;
  show_system_view?: InputMaybe<Scalars['Boolean']['input']>;
  sites?: InputMaybe<SiteCreateNestedManyWithoutOrganizationInput>;
  sms_opt_in_dialog?: InputMaybe<Scalars['String']['input']>;
  SMSItems?: InputMaybe<SmsItemCreateNestedManyWithoutOrganizationInput>;
  SnoozeTrackings?: InputMaybe<SnoozeTrackingCreateNestedManyWithoutOrganizationInput>;
  StepActionLogs?: InputMaybe<StepActionLogCreateNestedManyWithoutOrganizationInput>;
  StepActions?: InputMaybe<StepActionCreateNestedManyWithoutOrganizationInput>;
  StepConditions?: InputMaybe<StepConditionCreateNestedManyWithoutOrganizationInput>;
  teams?: InputMaybe<TeamCreateNestedManyWithoutOrganizationInput>;
  Template?: InputMaybe<TemplateCreateNestedManyWithoutOrganizationInput>;
  TemplateVersions?: InputMaybe<TemplateVersionCreateNestedManyWithoutOrganizationInput>;
  timekit_id?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  transfer_timeout?: InputMaybe<Scalars['Int']['input']>;
  TransferAttempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutOrganizationInput>;
  twilio_numbers?: InputMaybe<TwilioNumberCreateNestedManyWithoutOrganizationInput>;
  TwilioFeedbacks?: InputMaybe<TwilioFeedbackCreateNestedManyWithoutOrganizationInput>;
  TwilioInfo?: InputMaybe<TwilioInfoCreateNestedOneWithoutOrganzationInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  use_revenue_planning?: InputMaybe<Scalars['Boolean']['input']>;
  UserCustomFieldFilters?: InputMaybe<UserCustomFieldFilterCreateNestedManyWithoutOrganizationInput>;
  users?: InputMaybe<UserCreateNestedManyWithoutOrganizationInput>;
  win_rep_day_goal?: InputMaybe<Scalars['Float']['input']>;
};

export type OrganizationCreateWithoutIntegrationOperationLogInput = {
  ActivityMonitorMultiplier?: InputMaybe<ActivityMonitorMultiplierCreateNestedManyWithoutOrganizationInput>;
  algoFactor?: InputMaybe<AlgoFactorCreateNestedManyWithoutOrganizationInput>;
  allow_calendar_invite?: InputMaybe<Scalars['Boolean']['input']>;
  allow_remove_step_action?: InputMaybe<Scalars['Boolean']['input']>;
  allow_snooze_step_action?: InputMaybe<Scalars['Boolean']['input']>;
  always_record?: InputMaybe<Scalars['Boolean']['input']>;
  arv_initial_held_demos_generated_by_sdr_weight?: InputMaybe<Scalars['Float']['input']>;
  AssociationHistories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutOrganizationInput>;
  auto_call_interval?: InputMaybe<Scalars['Int']['input']>;
  auto_dial?: InputMaybe<Scalars['Boolean']['input']>;
  automated_campaigns?: InputMaybe<AutomatedCampaignCreateNestedManyWithoutOrganizationInput>;
  automatic_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  AutoTransferSelections?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutOrganizationInput>;
  Availablities?: InputMaybe<AvailabilityCreateNestedManyWithoutOrganizationInput>;
  AverageOrderValue?: InputMaybe<AverageOrderValueCreateNestedManyWithoutOrganizationInput>;
  best_action_ever_multiplier?: InputMaybe<Scalars['Float']['input']>;
  best_metrics?: InputMaybe<BestMetricRecordCreateNestedManyWithoutOrganizationInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptCreateNestedManyWithoutOrganizationInput>;
  callMeNows?: InputMaybe<CallMeNowCreateNestedManyWithoutOrganizationInput>;
  CallMultiplier?: InputMaybe<CallMultiplierCreateNestedManyWithoutOrganizationInput>;
  CallSharings?: InputMaybe<CallSharingCreateNestedManyWithoutOrganizationInput>;
  can_edit_sdr_transfer_rules?: InputMaybe<Scalars['Boolean']['input']>;
  claim_rule?: InputMaybe<Scalars['Int']['input']>;
  concession?: InputMaybe<ConcessionCreateNestedManyWithoutOrganizationInput>;
  conference_region?: InputMaybe<ConferenceRegion>;
  Conferences?: InputMaybe<ConferenceCreateNestedManyWithoutOrganizationInput>;
  ContactRequests?: InputMaybe<ContactRequestCreateNestedManyWithoutOrganizationInput>;
  Contacts?: InputMaybe<ContactCreateNestedManyWithoutOrganizationInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  custom_fields?: InputMaybe<CustomFieldCreateNestedManyWithoutOrganizationInput>;
  CustomObject?: InputMaybe<CustomObjectCreateNestedManyWithoutOrganizationInput>;
  CustomObjectField?: InputMaybe<CustomObjectFieldCreateNestedManyWithoutOrganizationInput>;
  CustomObjectFieldForeignKey?: InputMaybe<CustomObjectFieldForeignKeyCreateNestedManyWithoutOrganizationInput>;
  CustomObjectRecord?: InputMaybe<CustomObjectRecordCreateNestedManyWithoutOrganizationInput>;
  CustomObjectRow?: InputMaybe<CustomObjectRowCreateNestedManyWithoutOrganizationInput>;
  CustomQueueItems?: InputMaybe<CustomQueueItemCreateNestedManyWithoutOrganizationInput>;
  DashboardColumnOptions?: InputMaybe<DashboardColumnOptionCreateNestedManyWithoutOrganizationInput>;
  DashboardLeadFilters?: InputMaybe<DashboardLeadFilterCreateNestedManyWithoutOrganizationInput>;
  DashboardRepFilters?: InputMaybe<DashboardRepFilterCreateNestedManyWithoutOrganizationInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewCreateNestedManyWithoutOrganizationInput>;
  day_to_close?: InputMaybe<Scalars['Int']['input']>;
  default_demo_time_duration?: InputMaybe<Scalars['Int']['input']>;
  demo_held_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_not_held_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_not_held_call_back_short_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_prep_time_minutes?: InputMaybe<Scalars['Int']['input']>;
  dev_account?: InputMaybe<Scalars['Boolean']['input']>;
  dial_rule?: InputMaybe<Scalars['Int']['input']>;
  DispositionMultiplier?: InputMaybe<DispositionMultiplierCreateNestedManyWithoutOrganizationInput>;
  dispositions?: InputMaybe<DispositionCreateNestedManyWithoutOrganizationInput>;
  double_booking?: InputMaybe<Scalars['Boolean']['input']>;
  dynamic_date_operators?: InputMaybe<Scalars['Boolean']['input']>;
  edge_servers?: InputMaybe<OrganizationCreateedge_ServersInput>;
  email_conflict_notification_email_list?: InputMaybe<OrganizationCreateemail_Conflict_Notification_Email_ListInput>;
  EmailItems?: InputMaybe<EmailItemCreateNestedManyWithoutOrganizationInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordCreateNestedManyWithoutOrganizationInput>;
  EmailThreads?: InputMaybe<EmailThreadCreateNestedManyWithoutOrganizationInput>;
  enable_modified_metrics?: InputMaybe<Scalars['Boolean']['input']>;
  enable_pay_period_view?: InputMaybe<Scalars['Boolean']['input']>;
  enable_time_constraint?: InputMaybe<Scalars['Boolean']['input']>;
  error_log_email_address?: InputMaybe<OrganizationCreateerror_Log_Email_AddressInput>;
  estimated_average_num_of_weekly_overbooked_demo_slots?: InputMaybe<Scalars['Int']['input']>;
  FolderPermissions?: InputMaybe<FolderPermissionCreateNestedManyWithoutOrganizationInput>;
  Folders?: InputMaybe<FolderCreateNestedManyWithoutOrganizationInput>;
  FutureBookingSetting?: InputMaybe<FutureBookingSettingCreateNestedOneWithoutOrganizationInput>;
  GlobalTransferRule?: InputMaybe<GlobalTransferRuleCreateNestedManyWithoutOrganizationInput>;
  held_demos_per_cc_connect?: InputMaybe<Scalars['Float']['input']>;
  helpful_links?: InputMaybe<OrganizationCreatehelpful_LinksInput>;
  HingePointMultiplier?: InputMaybe<HingePointMultiplierCreateNestedManyWithoutOrganizationInput>;
  hold_goal?: InputMaybe<Scalars['Float']['input']>;
  hubspot_credential?: InputMaybe<HubSpotCredentialCreateNestedOneWithoutOrganizationInput>;
  hubspot_last_sync_date?: InputMaybe<Scalars['DateTime']['input']>;
  hubspot_white_list?: InputMaybe<HubSpotWhitelistCreateNestedManyWithoutOrganizationInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  inbound_close_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_dm_connect_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_lt_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_revenue_value_of_sale?: InputMaybe<Scalars['Float']['input']>;
  inbound_scheduled_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_set_rate?: InputMaybe<Scalars['Float']['input']>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventCreateNestedManyWithoutOrganizationInput>;
  InboundConciergeWidget?: InputMaybe<InboundConciergeWidgetCreateNestedManyWithoutOrganizationInput>;
  industry_options?: InputMaybe<IndustryOptionCreateNestedManyWithoutOrganizationInput>;
  initial_sdr_sets_reps_day?: InputMaybe<Scalars['Float']['input']>;
  integration_error_logs?: InputMaybe<IntegrationErrorLogCreateNestedManyWithoutOrganizationInput>;
  integration_mappings?: InputMaybe<IntegrationMappingCreateNestedManyWithoutOrganizationInput>;
  IntegrationMappingCustomObject?: InputMaybe<IntegrationMappingCustomObjectCreateNestedManyWithoutOrganizationInput>;
  IntegrationReadOnlyObject?: InputMaybe<IntegrationReadOnlyObjectCreateNestedManyWithoutOrganizationInput>;
  international?: InputMaybe<Scalars['Boolean']['input']>;
  is_email_domain_verified?: InputMaybe<Scalars['Boolean']['input']>;
  is_salesforce_sandbox?: InputMaybe<Scalars['Boolean']['input']>;
  last_lead_value_compute_time?: InputMaybe<Scalars['DateTime']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutOrganizationInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryCreateNestedManyWithoutOrganizationInput>;
  lead_intent?: InputMaybe<LeadIntentCreateNestedManyWithoutOrganizationInput>;
  lead_release_same_rep_delay_rule?: InputMaybe<Scalars['Int']['input']>;
  lead_routing_email_list?: InputMaybe<OrganizationCreatelead_Routing_Email_ListInput>;
  lead_routing_rules?: InputMaybe<RuleCreateNestedManyWithoutOrganizationInput>;
  LeadCustomObjectRowAssociation?: InputMaybe<LeadCustomObjectRowAssociationCreateNestedManyWithoutOrganizationInput>;
  LeadFieldMultiplier?: InputMaybe<LeadFieldMultiplierCreateNestedManyWithoutOrganizationInput>;
  LeadFieldMultiplierCustom?: InputMaybe<LeadFieldMultiplierCustomCreateNestedManyWithoutOrganizationInput>;
  leads?: InputMaybe<LeadCreateNestedManyWithoutOrganizationInput>;
  leadsource_options?: InputMaybe<LeadSourceOptionCreateNestedManyWithoutOrganizationInput>;
  LeadValue?: InputMaybe<LeadValueCreateNestedManyWithoutOrganizationInput>;
  LifeTimeValueMultiplier?: InputMaybe<LifeTimeValueMultiplierCreateNestedManyWithoutOrganizationInput>;
  LiveTransferSetting?: InputMaybe<LiveTransferSettingCreateNestedOneWithoutOrganizationInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusCreateNestedManyWithoutOrganizationInput>;
  ltv_multiplier_custom_fields?: InputMaybe<LifeTimeValueMultiplierCustomCreateNestedManyWithoutOrganizationInput>;
  made_contact_rule?: InputMaybe<Scalars['Int']['input']>;
  make_sale_address_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_business_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_city_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_country_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_first_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_industry_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_last_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_lead_source_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_primary_email_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_primary_phone_number_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_state_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_sub_industry_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_zip_required?: InputMaybe<Scalars['Boolean']['input']>;
  maxium_day_lag_set_to_schedule?: InputMaybe<Scalars['Int']['input']>;
  mrr?: InputMaybe<Scalars['Int']['input']>;
  mrr_label?: InputMaybe<Scalars['String']['input']>;
  mrr_required_on_sale?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  NoteItems?: InputMaybe<NoteItemCreateNestedManyWithoutOrganizationInput>;
  Notifications?: InputMaybe<NotificationCreateNestedManyWithoutOrganizationInput>;
  onboardComplete?: InputMaybe<Scalars['Boolean']['input']>;
  Opportunity?: InputMaybe<SfOpportunityCreateNestedManyWithoutOrganizationInput>;
  organization_holidays?: InputMaybe<OrganizationHolidayCreateNestedManyWithoutOrganizationInput>;
  organization_hours?: InputMaybe<OrganizationHourCreateNestedManyWithoutOrganizationInput>;
  OrganizationPaymentSetting?: InputMaybe<OrganizationPaymentSettingsCreateNestedOneWithoutOrganizationInput>;
  OrganizationPaymentSettings?: InputMaybe<OrganizationPaymentSettingsCreateNestedOneWithoutOrganizationsInput>;
  orgCalendarInviteMetadata?: InputMaybe<OrgCalendarInviteMetadataCreateNestedManyWithoutOrganizationInput>;
  OrgDefaultPipelinePerecentValue?: InputMaybe<OrgDefaultPipelinePerecentValueCreateNestedOneWithoutOrganizationInput>;
  OrgHubSpotSync?: InputMaybe<OrgHubSpotSyncCreateNestedOneWithoutOrganizationInput>;
  OrgNoEventDate?: InputMaybe<OrgNoEventDateCreateNestedManyWithoutOrganizationInput>;
  OrgSalesforceSync?: InputMaybe<OrgSalesforceSyncCreateNestedOneWithoutOrganizationInput>;
  OrgTimeslotAvailability?: InputMaybe<OrgTimeslotAvailabilityCreateNestedManyWithoutOrganizationInput>;
  outbound_close_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_dm_connect_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_lt_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_revenue_value_of_sale?: InputMaybe<Scalars['Float']['input']>;
  outbound_scheduled_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_set_rate?: InputMaybe<Scalars['Float']['input']>;
  PandadocData?: InputMaybe<PandadocDataCreateNestedOneWithoutOrganizationInput>;
  PandaDocTemplate?: InputMaybe<PandaDocTemplateCreateNestedManyWithoutOrganizationInput>;
  participant_jitter_buffer_size?: InputMaybe<JitterBufferSize>;
  preferred_goal_setting?: InputMaybe<Goalsetting>;
  prepayment?: InputMaybe<Scalars['Boolean']['input']>;
  ProductChangeLog?: InputMaybe<ProductChangeLogCreateNestedManyWithoutOrganizationInput>;
  products?: InputMaybe<ProductCreateNestedManyWithoutOrganizationInput>;
  re_cold_call_hour_delay?: InputMaybe<Scalars['Float']['input']>;
  recordingStatus?: InputMaybe<RecordingStatus>;
  release_lead_resting?: InputMaybe<Scalars['Int']['input']>;
  rep_claim_limit?: InputMaybe<Scalars['Int']['input']>;
  rep_lead_allotment?: InputMaybe<Scalars['Int']['input']>;
  rep_level_call_transfer?: InputMaybe<Scalars['Boolean']['input']>;
  reps_can_bulk_unassign?: InputMaybe<Scalars['Boolean']['input']>;
  reps_can_view_all_notes?: InputMaybe<Scalars['Boolean']['input']>;
  require_sale_notes?: InputMaybe<Scalars['Boolean']['input']>;
  resting_after_skipped_CC?: InputMaybe<Scalars['Int']['input']>;
  resting_period?: InputMaybe<Scalars['Int']['input']>;
  resting_period_after_unsuccessful_cold_call?: InputMaybe<Scalars['Float']['input']>;
  resting_period_after_unsuccessful_demo?: InputMaybe<Scalars['Float']['input']>;
  revenue_plan?: InputMaybe<RevenuePlanItemCreateNestedManyWithoutOrganizationInput>;
  Routings?: InputMaybe<RoutingCreateNestedManyWithoutOrganizationInput>;
  sale_field_options?: InputMaybe<SaleFieldOptionCreateNestedManyWithoutOrganizationInput>;
  sale_notification_email_list?: InputMaybe<OrganizationCreatesale_Notification_Email_ListInput>;
  SaleConfig?: InputMaybe<SaleConfigCreateNestedOneWithoutOrganizationInput>;
  SaleConfigPage?: InputMaybe<SaleConfigPageCreateNestedManyWithoutOrganizationInput>;
  SaleConfigSection?: InputMaybe<SaleConfigSectionCreateNestedManyWithoutOrganizationInput>;
  SaleCycles?: InputMaybe<SaleCycleCreateNestedManyWithoutOrganizationInput>;
  SaleItems?: InputMaybe<SaleItemCreateNestedManyWithoutOrganizationInput>;
  sales?: InputMaybe<SaleCreateNestedManyWithoutOrganizationInput>;
  salesforce_domain?: InputMaybe<Scalars['String']['input']>;
  salesforce_last_sync_date?: InputMaybe<Scalars['DateTime']['input']>;
  SalesForceColumn?: InputMaybe<SalesForceColumnCreateNestedManyWithoutOrganizationInput>;
  salesForceData?: InputMaybe<SalesForceDataCreateNestedOneWithoutOrganizationInput>;
  ScheduleItems?: InputMaybe<ScheduleItemCreateNestedManyWithoutOrganizationInput>;
  self_sourcing_rule?: InputMaybe<Scalars['Int']['input']>;
  send_error_log_email?: InputMaybe<Scalars['Boolean']['input']>;
  send_lead_routing_email?: InputMaybe<Scalars['Boolean']['input']>;
  sendgrid_email_domain?: InputMaybe<Scalars['String']['input']>;
  sendgrid_email_domain_id?: InputMaybe<Scalars['Int']['input']>;
  sequence?: InputMaybe<SequenceCreateNestedManyWithoutOrganzationInput>;
  SequenceCriteriaConditions?: InputMaybe<SequenceCriteriaConditionCreateNestedManyWithoutOrganizationInput>;
  SequenceDashboardViews?: InputMaybe<SequenceDashboardViewCreateNestedManyWithoutOrganizationInput>;
  SequenceEntryCriterias?: InputMaybe<SequenceEntryCriteriaCreateNestedManyWithoutOrganizationInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutOrganizationInput>;
  SequenceSteps?: InputMaybe<SequenceStepCreateNestedManyWithoutOrganizationInput>;
  set_goal?: InputMaybe<Scalars['Float']['input']>;
  short_resting?: InputMaybe<Scalars['Int']['input']>;
  show_calendar_options?: InputMaybe<Scalars['Boolean']['input']>;
  show_contract_duration_sales?: InputMaybe<Scalars['Boolean']['input']>;
  show_lead_source_to_rep?: InputMaybe<Scalars['Boolean']['input']>;
  show_nocontact_button?: InputMaybe<Scalars['Boolean']['input']>;
  show_payment_terms_sales?: InputMaybe<Scalars['Boolean']['input']>;
  show_rep_all_leads?: InputMaybe<Scalars['Boolean']['input']>;
  show_rep_my_leads?: InputMaybe<Scalars['Boolean']['input']>;
  show_system_view?: InputMaybe<Scalars['Boolean']['input']>;
  sites?: InputMaybe<SiteCreateNestedManyWithoutOrganizationInput>;
  sms_opt_in_dialog?: InputMaybe<Scalars['String']['input']>;
  SMSItems?: InputMaybe<SmsItemCreateNestedManyWithoutOrganizationInput>;
  SnoozeTrackings?: InputMaybe<SnoozeTrackingCreateNestedManyWithoutOrganizationInput>;
  StepActionLogs?: InputMaybe<StepActionLogCreateNestedManyWithoutOrganizationInput>;
  StepActions?: InputMaybe<StepActionCreateNestedManyWithoutOrganizationInput>;
  StepConditions?: InputMaybe<StepConditionCreateNestedManyWithoutOrganizationInput>;
  teams?: InputMaybe<TeamCreateNestedManyWithoutOrganizationInput>;
  Template?: InputMaybe<TemplateCreateNestedManyWithoutOrganizationInput>;
  TemplateVersions?: InputMaybe<TemplateVersionCreateNestedManyWithoutOrganizationInput>;
  timekit_id?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  transfer_timeout?: InputMaybe<Scalars['Int']['input']>;
  TransferAttempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutOrganizationInput>;
  twilio_numbers?: InputMaybe<TwilioNumberCreateNestedManyWithoutOrganizationInput>;
  TwilioFeedbacks?: InputMaybe<TwilioFeedbackCreateNestedManyWithoutOrganizationInput>;
  TwilioInfo?: InputMaybe<TwilioInfoCreateNestedOneWithoutOrganzationInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  use_revenue_planning?: InputMaybe<Scalars['Boolean']['input']>;
  UserCustomFieldFilters?: InputMaybe<UserCustomFieldFilterCreateNestedManyWithoutOrganizationInput>;
  users?: InputMaybe<UserCreateNestedManyWithoutOrganizationInput>;
  win_rep_day_goal?: InputMaybe<Scalars['Float']['input']>;
};

export type OrganizationCreateWithoutLead_ActivitiesInput = {
  ActivityMonitorMultiplier?: InputMaybe<ActivityMonitorMultiplierCreateNestedManyWithoutOrganizationInput>;
  algoFactor?: InputMaybe<AlgoFactorCreateNestedManyWithoutOrganizationInput>;
  allow_calendar_invite?: InputMaybe<Scalars['Boolean']['input']>;
  allow_remove_step_action?: InputMaybe<Scalars['Boolean']['input']>;
  allow_snooze_step_action?: InputMaybe<Scalars['Boolean']['input']>;
  always_record?: InputMaybe<Scalars['Boolean']['input']>;
  arv_initial_held_demos_generated_by_sdr_weight?: InputMaybe<Scalars['Float']['input']>;
  AssociationHistories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutOrganizationInput>;
  auto_call_interval?: InputMaybe<Scalars['Int']['input']>;
  auto_dial?: InputMaybe<Scalars['Boolean']['input']>;
  automated_campaigns?: InputMaybe<AutomatedCampaignCreateNestedManyWithoutOrganizationInput>;
  automatic_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  AutoTransferSelections?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutOrganizationInput>;
  Availablities?: InputMaybe<AvailabilityCreateNestedManyWithoutOrganizationInput>;
  AverageOrderValue?: InputMaybe<AverageOrderValueCreateNestedManyWithoutOrganizationInput>;
  best_action_ever_multiplier?: InputMaybe<Scalars['Float']['input']>;
  best_metrics?: InputMaybe<BestMetricRecordCreateNestedManyWithoutOrganizationInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptCreateNestedManyWithoutOrganizationInput>;
  callMeNows?: InputMaybe<CallMeNowCreateNestedManyWithoutOrganizationInput>;
  CallMultiplier?: InputMaybe<CallMultiplierCreateNestedManyWithoutOrganizationInput>;
  CallSharings?: InputMaybe<CallSharingCreateNestedManyWithoutOrganizationInput>;
  can_edit_sdr_transfer_rules?: InputMaybe<Scalars['Boolean']['input']>;
  claim_rule?: InputMaybe<Scalars['Int']['input']>;
  concession?: InputMaybe<ConcessionCreateNestedManyWithoutOrganizationInput>;
  conference_region?: InputMaybe<ConferenceRegion>;
  Conferences?: InputMaybe<ConferenceCreateNestedManyWithoutOrganizationInput>;
  ContactRequests?: InputMaybe<ContactRequestCreateNestedManyWithoutOrganizationInput>;
  Contacts?: InputMaybe<ContactCreateNestedManyWithoutOrganizationInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  custom_fields?: InputMaybe<CustomFieldCreateNestedManyWithoutOrganizationInput>;
  CustomObject?: InputMaybe<CustomObjectCreateNestedManyWithoutOrganizationInput>;
  CustomObjectField?: InputMaybe<CustomObjectFieldCreateNestedManyWithoutOrganizationInput>;
  CustomObjectFieldForeignKey?: InputMaybe<CustomObjectFieldForeignKeyCreateNestedManyWithoutOrganizationInput>;
  CustomObjectRecord?: InputMaybe<CustomObjectRecordCreateNestedManyWithoutOrganizationInput>;
  CustomObjectRow?: InputMaybe<CustomObjectRowCreateNestedManyWithoutOrganizationInput>;
  CustomQueueItems?: InputMaybe<CustomQueueItemCreateNestedManyWithoutOrganizationInput>;
  DashboardColumnOptions?: InputMaybe<DashboardColumnOptionCreateNestedManyWithoutOrganizationInput>;
  DashboardLeadFilters?: InputMaybe<DashboardLeadFilterCreateNestedManyWithoutOrganizationInput>;
  DashboardRepFilters?: InputMaybe<DashboardRepFilterCreateNestedManyWithoutOrganizationInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewCreateNestedManyWithoutOrganizationInput>;
  day_to_close?: InputMaybe<Scalars['Int']['input']>;
  default_demo_time_duration?: InputMaybe<Scalars['Int']['input']>;
  demo_held_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_not_held_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_not_held_call_back_short_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_prep_time_minutes?: InputMaybe<Scalars['Int']['input']>;
  dev_account?: InputMaybe<Scalars['Boolean']['input']>;
  dial_rule?: InputMaybe<Scalars['Int']['input']>;
  DispositionMultiplier?: InputMaybe<DispositionMultiplierCreateNestedManyWithoutOrganizationInput>;
  dispositions?: InputMaybe<DispositionCreateNestedManyWithoutOrganizationInput>;
  double_booking?: InputMaybe<Scalars['Boolean']['input']>;
  dynamic_date_operators?: InputMaybe<Scalars['Boolean']['input']>;
  edge_servers?: InputMaybe<OrganizationCreateedge_ServersInput>;
  email_conflict_notification_email_list?: InputMaybe<OrganizationCreateemail_Conflict_Notification_Email_ListInput>;
  EmailItems?: InputMaybe<EmailItemCreateNestedManyWithoutOrganizationInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordCreateNestedManyWithoutOrganizationInput>;
  EmailThreads?: InputMaybe<EmailThreadCreateNestedManyWithoutOrganizationInput>;
  enable_modified_metrics?: InputMaybe<Scalars['Boolean']['input']>;
  enable_pay_period_view?: InputMaybe<Scalars['Boolean']['input']>;
  enable_time_constraint?: InputMaybe<Scalars['Boolean']['input']>;
  error_log_email_address?: InputMaybe<OrganizationCreateerror_Log_Email_AddressInput>;
  estimated_average_num_of_weekly_overbooked_demo_slots?: InputMaybe<Scalars['Int']['input']>;
  FolderPermissions?: InputMaybe<FolderPermissionCreateNestedManyWithoutOrganizationInput>;
  Folders?: InputMaybe<FolderCreateNestedManyWithoutOrganizationInput>;
  FutureBookingSetting?: InputMaybe<FutureBookingSettingCreateNestedOneWithoutOrganizationInput>;
  GlobalTransferRule?: InputMaybe<GlobalTransferRuleCreateNestedManyWithoutOrganizationInput>;
  held_demos_per_cc_connect?: InputMaybe<Scalars['Float']['input']>;
  helpful_links?: InputMaybe<OrganizationCreatehelpful_LinksInput>;
  HingePointMultiplier?: InputMaybe<HingePointMultiplierCreateNestedManyWithoutOrganizationInput>;
  hold_goal?: InputMaybe<Scalars['Float']['input']>;
  hubspot_credential?: InputMaybe<HubSpotCredentialCreateNestedOneWithoutOrganizationInput>;
  hubspot_last_sync_date?: InputMaybe<Scalars['DateTime']['input']>;
  hubspot_white_list?: InputMaybe<HubSpotWhitelistCreateNestedManyWithoutOrganizationInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  inbound_close_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_dm_connect_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_lt_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_revenue_value_of_sale?: InputMaybe<Scalars['Float']['input']>;
  inbound_scheduled_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_set_rate?: InputMaybe<Scalars['Float']['input']>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventCreateNestedManyWithoutOrganizationInput>;
  InboundConciergeWidget?: InputMaybe<InboundConciergeWidgetCreateNestedManyWithoutOrganizationInput>;
  industry_options?: InputMaybe<IndustryOptionCreateNestedManyWithoutOrganizationInput>;
  initial_sdr_sets_reps_day?: InputMaybe<Scalars['Float']['input']>;
  integration_error_logs?: InputMaybe<IntegrationErrorLogCreateNestedManyWithoutOrganizationInput>;
  integration_mappings?: InputMaybe<IntegrationMappingCreateNestedManyWithoutOrganizationInput>;
  IntegrationMappingCustomObject?: InputMaybe<IntegrationMappingCustomObjectCreateNestedManyWithoutOrganizationInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogCreateNestedManyWithoutOrganizationInput>;
  IntegrationReadOnlyObject?: InputMaybe<IntegrationReadOnlyObjectCreateNestedManyWithoutOrganizationInput>;
  international?: InputMaybe<Scalars['Boolean']['input']>;
  is_email_domain_verified?: InputMaybe<Scalars['Boolean']['input']>;
  is_salesforce_sandbox?: InputMaybe<Scalars['Boolean']['input']>;
  last_lead_value_compute_time?: InputMaybe<Scalars['DateTime']['input']>;
  lead_import_history?: InputMaybe<LeadImportHistoryCreateNestedManyWithoutOrganizationInput>;
  lead_intent?: InputMaybe<LeadIntentCreateNestedManyWithoutOrganizationInput>;
  lead_release_same_rep_delay_rule?: InputMaybe<Scalars['Int']['input']>;
  lead_routing_email_list?: InputMaybe<OrganizationCreatelead_Routing_Email_ListInput>;
  lead_routing_rules?: InputMaybe<RuleCreateNestedManyWithoutOrganizationInput>;
  LeadCustomObjectRowAssociation?: InputMaybe<LeadCustomObjectRowAssociationCreateNestedManyWithoutOrganizationInput>;
  LeadFieldMultiplier?: InputMaybe<LeadFieldMultiplierCreateNestedManyWithoutOrganizationInput>;
  LeadFieldMultiplierCustom?: InputMaybe<LeadFieldMultiplierCustomCreateNestedManyWithoutOrganizationInput>;
  leads?: InputMaybe<LeadCreateNestedManyWithoutOrganizationInput>;
  leadsource_options?: InputMaybe<LeadSourceOptionCreateNestedManyWithoutOrganizationInput>;
  LeadValue?: InputMaybe<LeadValueCreateNestedManyWithoutOrganizationInput>;
  LifeTimeValueMultiplier?: InputMaybe<LifeTimeValueMultiplierCreateNestedManyWithoutOrganizationInput>;
  LiveTransferSetting?: InputMaybe<LiveTransferSettingCreateNestedOneWithoutOrganizationInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusCreateNestedManyWithoutOrganizationInput>;
  ltv_multiplier_custom_fields?: InputMaybe<LifeTimeValueMultiplierCustomCreateNestedManyWithoutOrganizationInput>;
  made_contact_rule?: InputMaybe<Scalars['Int']['input']>;
  make_sale_address_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_business_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_city_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_country_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_first_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_industry_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_last_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_lead_source_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_primary_email_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_primary_phone_number_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_state_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_sub_industry_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_zip_required?: InputMaybe<Scalars['Boolean']['input']>;
  maxium_day_lag_set_to_schedule?: InputMaybe<Scalars['Int']['input']>;
  mrr?: InputMaybe<Scalars['Int']['input']>;
  mrr_label?: InputMaybe<Scalars['String']['input']>;
  mrr_required_on_sale?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  NoteItems?: InputMaybe<NoteItemCreateNestedManyWithoutOrganizationInput>;
  Notifications?: InputMaybe<NotificationCreateNestedManyWithoutOrganizationInput>;
  onboardComplete?: InputMaybe<Scalars['Boolean']['input']>;
  Opportunity?: InputMaybe<SfOpportunityCreateNestedManyWithoutOrganizationInput>;
  organization_holidays?: InputMaybe<OrganizationHolidayCreateNestedManyWithoutOrganizationInput>;
  organization_hours?: InputMaybe<OrganizationHourCreateNestedManyWithoutOrganizationInput>;
  OrganizationPaymentSetting?: InputMaybe<OrganizationPaymentSettingsCreateNestedOneWithoutOrganizationInput>;
  OrganizationPaymentSettings?: InputMaybe<OrganizationPaymentSettingsCreateNestedOneWithoutOrganizationsInput>;
  orgCalendarInviteMetadata?: InputMaybe<OrgCalendarInviteMetadataCreateNestedManyWithoutOrganizationInput>;
  OrgDefaultPipelinePerecentValue?: InputMaybe<OrgDefaultPipelinePerecentValueCreateNestedOneWithoutOrganizationInput>;
  OrgHubSpotSync?: InputMaybe<OrgHubSpotSyncCreateNestedOneWithoutOrganizationInput>;
  OrgNoEventDate?: InputMaybe<OrgNoEventDateCreateNestedManyWithoutOrganizationInput>;
  OrgSalesforceSync?: InputMaybe<OrgSalesforceSyncCreateNestedOneWithoutOrganizationInput>;
  OrgTimeslotAvailability?: InputMaybe<OrgTimeslotAvailabilityCreateNestedManyWithoutOrganizationInput>;
  outbound_close_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_dm_connect_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_lt_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_revenue_value_of_sale?: InputMaybe<Scalars['Float']['input']>;
  outbound_scheduled_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_set_rate?: InputMaybe<Scalars['Float']['input']>;
  PandadocData?: InputMaybe<PandadocDataCreateNestedOneWithoutOrganizationInput>;
  PandaDocTemplate?: InputMaybe<PandaDocTemplateCreateNestedManyWithoutOrganizationInput>;
  participant_jitter_buffer_size?: InputMaybe<JitterBufferSize>;
  preferred_goal_setting?: InputMaybe<Goalsetting>;
  prepayment?: InputMaybe<Scalars['Boolean']['input']>;
  ProductChangeLog?: InputMaybe<ProductChangeLogCreateNestedManyWithoutOrganizationInput>;
  products?: InputMaybe<ProductCreateNestedManyWithoutOrganizationInput>;
  re_cold_call_hour_delay?: InputMaybe<Scalars['Float']['input']>;
  recordingStatus?: InputMaybe<RecordingStatus>;
  release_lead_resting?: InputMaybe<Scalars['Int']['input']>;
  rep_claim_limit?: InputMaybe<Scalars['Int']['input']>;
  rep_lead_allotment?: InputMaybe<Scalars['Int']['input']>;
  rep_level_call_transfer?: InputMaybe<Scalars['Boolean']['input']>;
  reps_can_bulk_unassign?: InputMaybe<Scalars['Boolean']['input']>;
  reps_can_view_all_notes?: InputMaybe<Scalars['Boolean']['input']>;
  require_sale_notes?: InputMaybe<Scalars['Boolean']['input']>;
  resting_after_skipped_CC?: InputMaybe<Scalars['Int']['input']>;
  resting_period?: InputMaybe<Scalars['Int']['input']>;
  resting_period_after_unsuccessful_cold_call?: InputMaybe<Scalars['Float']['input']>;
  resting_period_after_unsuccessful_demo?: InputMaybe<Scalars['Float']['input']>;
  revenue_plan?: InputMaybe<RevenuePlanItemCreateNestedManyWithoutOrganizationInput>;
  Routings?: InputMaybe<RoutingCreateNestedManyWithoutOrganizationInput>;
  sale_field_options?: InputMaybe<SaleFieldOptionCreateNestedManyWithoutOrganizationInput>;
  sale_notification_email_list?: InputMaybe<OrganizationCreatesale_Notification_Email_ListInput>;
  SaleConfig?: InputMaybe<SaleConfigCreateNestedOneWithoutOrganizationInput>;
  SaleConfigPage?: InputMaybe<SaleConfigPageCreateNestedManyWithoutOrganizationInput>;
  SaleConfigSection?: InputMaybe<SaleConfigSectionCreateNestedManyWithoutOrganizationInput>;
  SaleCycles?: InputMaybe<SaleCycleCreateNestedManyWithoutOrganizationInput>;
  SaleItems?: InputMaybe<SaleItemCreateNestedManyWithoutOrganizationInput>;
  sales?: InputMaybe<SaleCreateNestedManyWithoutOrganizationInput>;
  salesforce_domain?: InputMaybe<Scalars['String']['input']>;
  salesforce_last_sync_date?: InputMaybe<Scalars['DateTime']['input']>;
  SalesForceColumn?: InputMaybe<SalesForceColumnCreateNestedManyWithoutOrganizationInput>;
  salesForceData?: InputMaybe<SalesForceDataCreateNestedOneWithoutOrganizationInput>;
  ScheduleItems?: InputMaybe<ScheduleItemCreateNestedManyWithoutOrganizationInput>;
  self_sourcing_rule?: InputMaybe<Scalars['Int']['input']>;
  send_error_log_email?: InputMaybe<Scalars['Boolean']['input']>;
  send_lead_routing_email?: InputMaybe<Scalars['Boolean']['input']>;
  sendgrid_email_domain?: InputMaybe<Scalars['String']['input']>;
  sendgrid_email_domain_id?: InputMaybe<Scalars['Int']['input']>;
  sequence?: InputMaybe<SequenceCreateNestedManyWithoutOrganzationInput>;
  SequenceCriteriaConditions?: InputMaybe<SequenceCriteriaConditionCreateNestedManyWithoutOrganizationInput>;
  SequenceDashboardViews?: InputMaybe<SequenceDashboardViewCreateNestedManyWithoutOrganizationInput>;
  SequenceEntryCriterias?: InputMaybe<SequenceEntryCriteriaCreateNestedManyWithoutOrganizationInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutOrganizationInput>;
  SequenceSteps?: InputMaybe<SequenceStepCreateNestedManyWithoutOrganizationInput>;
  set_goal?: InputMaybe<Scalars['Float']['input']>;
  short_resting?: InputMaybe<Scalars['Int']['input']>;
  show_calendar_options?: InputMaybe<Scalars['Boolean']['input']>;
  show_contract_duration_sales?: InputMaybe<Scalars['Boolean']['input']>;
  show_lead_source_to_rep?: InputMaybe<Scalars['Boolean']['input']>;
  show_nocontact_button?: InputMaybe<Scalars['Boolean']['input']>;
  show_payment_terms_sales?: InputMaybe<Scalars['Boolean']['input']>;
  show_rep_all_leads?: InputMaybe<Scalars['Boolean']['input']>;
  show_rep_my_leads?: InputMaybe<Scalars['Boolean']['input']>;
  show_system_view?: InputMaybe<Scalars['Boolean']['input']>;
  sites?: InputMaybe<SiteCreateNestedManyWithoutOrganizationInput>;
  sms_opt_in_dialog?: InputMaybe<Scalars['String']['input']>;
  SMSItems?: InputMaybe<SmsItemCreateNestedManyWithoutOrganizationInput>;
  SnoozeTrackings?: InputMaybe<SnoozeTrackingCreateNestedManyWithoutOrganizationInput>;
  StepActionLogs?: InputMaybe<StepActionLogCreateNestedManyWithoutOrganizationInput>;
  StepActions?: InputMaybe<StepActionCreateNestedManyWithoutOrganizationInput>;
  StepConditions?: InputMaybe<StepConditionCreateNestedManyWithoutOrganizationInput>;
  teams?: InputMaybe<TeamCreateNestedManyWithoutOrganizationInput>;
  Template?: InputMaybe<TemplateCreateNestedManyWithoutOrganizationInput>;
  TemplateVersions?: InputMaybe<TemplateVersionCreateNestedManyWithoutOrganizationInput>;
  timekit_id?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  transfer_timeout?: InputMaybe<Scalars['Int']['input']>;
  TransferAttempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutOrganizationInput>;
  twilio_numbers?: InputMaybe<TwilioNumberCreateNestedManyWithoutOrganizationInput>;
  TwilioFeedbacks?: InputMaybe<TwilioFeedbackCreateNestedManyWithoutOrganizationInput>;
  TwilioInfo?: InputMaybe<TwilioInfoCreateNestedOneWithoutOrganzationInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  use_revenue_planning?: InputMaybe<Scalars['Boolean']['input']>;
  UserCustomFieldFilters?: InputMaybe<UserCustomFieldFilterCreateNestedManyWithoutOrganizationInput>;
  users?: InputMaybe<UserCreateNestedManyWithoutOrganizationInput>;
  win_rep_day_goal?: InputMaybe<Scalars['Float']['input']>;
};

export type OrganizationCreateWithoutLead_Import_HistoryInput = {
  ActivityMonitorMultiplier?: InputMaybe<ActivityMonitorMultiplierCreateNestedManyWithoutOrganizationInput>;
  algoFactor?: InputMaybe<AlgoFactorCreateNestedManyWithoutOrganizationInput>;
  allow_calendar_invite?: InputMaybe<Scalars['Boolean']['input']>;
  allow_remove_step_action?: InputMaybe<Scalars['Boolean']['input']>;
  allow_snooze_step_action?: InputMaybe<Scalars['Boolean']['input']>;
  always_record?: InputMaybe<Scalars['Boolean']['input']>;
  arv_initial_held_demos_generated_by_sdr_weight?: InputMaybe<Scalars['Float']['input']>;
  AssociationHistories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutOrganizationInput>;
  auto_call_interval?: InputMaybe<Scalars['Int']['input']>;
  auto_dial?: InputMaybe<Scalars['Boolean']['input']>;
  automated_campaigns?: InputMaybe<AutomatedCampaignCreateNestedManyWithoutOrganizationInput>;
  automatic_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  AutoTransferSelections?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutOrganizationInput>;
  Availablities?: InputMaybe<AvailabilityCreateNestedManyWithoutOrganizationInput>;
  AverageOrderValue?: InputMaybe<AverageOrderValueCreateNestedManyWithoutOrganizationInput>;
  best_action_ever_multiplier?: InputMaybe<Scalars['Float']['input']>;
  best_metrics?: InputMaybe<BestMetricRecordCreateNestedManyWithoutOrganizationInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptCreateNestedManyWithoutOrganizationInput>;
  callMeNows?: InputMaybe<CallMeNowCreateNestedManyWithoutOrganizationInput>;
  CallMultiplier?: InputMaybe<CallMultiplierCreateNestedManyWithoutOrganizationInput>;
  CallSharings?: InputMaybe<CallSharingCreateNestedManyWithoutOrganizationInput>;
  can_edit_sdr_transfer_rules?: InputMaybe<Scalars['Boolean']['input']>;
  claim_rule?: InputMaybe<Scalars['Int']['input']>;
  concession?: InputMaybe<ConcessionCreateNestedManyWithoutOrganizationInput>;
  conference_region?: InputMaybe<ConferenceRegion>;
  Conferences?: InputMaybe<ConferenceCreateNestedManyWithoutOrganizationInput>;
  ContactRequests?: InputMaybe<ContactRequestCreateNestedManyWithoutOrganizationInput>;
  Contacts?: InputMaybe<ContactCreateNestedManyWithoutOrganizationInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  custom_fields?: InputMaybe<CustomFieldCreateNestedManyWithoutOrganizationInput>;
  CustomObject?: InputMaybe<CustomObjectCreateNestedManyWithoutOrganizationInput>;
  CustomObjectField?: InputMaybe<CustomObjectFieldCreateNestedManyWithoutOrganizationInput>;
  CustomObjectFieldForeignKey?: InputMaybe<CustomObjectFieldForeignKeyCreateNestedManyWithoutOrganizationInput>;
  CustomObjectRecord?: InputMaybe<CustomObjectRecordCreateNestedManyWithoutOrganizationInput>;
  CustomObjectRow?: InputMaybe<CustomObjectRowCreateNestedManyWithoutOrganizationInput>;
  CustomQueueItems?: InputMaybe<CustomQueueItemCreateNestedManyWithoutOrganizationInput>;
  DashboardColumnOptions?: InputMaybe<DashboardColumnOptionCreateNestedManyWithoutOrganizationInput>;
  DashboardLeadFilters?: InputMaybe<DashboardLeadFilterCreateNestedManyWithoutOrganizationInput>;
  DashboardRepFilters?: InputMaybe<DashboardRepFilterCreateNestedManyWithoutOrganizationInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewCreateNestedManyWithoutOrganizationInput>;
  day_to_close?: InputMaybe<Scalars['Int']['input']>;
  default_demo_time_duration?: InputMaybe<Scalars['Int']['input']>;
  demo_held_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_not_held_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_not_held_call_back_short_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_prep_time_minutes?: InputMaybe<Scalars['Int']['input']>;
  dev_account?: InputMaybe<Scalars['Boolean']['input']>;
  dial_rule?: InputMaybe<Scalars['Int']['input']>;
  DispositionMultiplier?: InputMaybe<DispositionMultiplierCreateNestedManyWithoutOrganizationInput>;
  dispositions?: InputMaybe<DispositionCreateNestedManyWithoutOrganizationInput>;
  double_booking?: InputMaybe<Scalars['Boolean']['input']>;
  dynamic_date_operators?: InputMaybe<Scalars['Boolean']['input']>;
  edge_servers?: InputMaybe<OrganizationCreateedge_ServersInput>;
  email_conflict_notification_email_list?: InputMaybe<OrganizationCreateemail_Conflict_Notification_Email_ListInput>;
  EmailItems?: InputMaybe<EmailItemCreateNestedManyWithoutOrganizationInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordCreateNestedManyWithoutOrganizationInput>;
  EmailThreads?: InputMaybe<EmailThreadCreateNestedManyWithoutOrganizationInput>;
  enable_modified_metrics?: InputMaybe<Scalars['Boolean']['input']>;
  enable_pay_period_view?: InputMaybe<Scalars['Boolean']['input']>;
  enable_time_constraint?: InputMaybe<Scalars['Boolean']['input']>;
  error_log_email_address?: InputMaybe<OrganizationCreateerror_Log_Email_AddressInput>;
  estimated_average_num_of_weekly_overbooked_demo_slots?: InputMaybe<Scalars['Int']['input']>;
  FolderPermissions?: InputMaybe<FolderPermissionCreateNestedManyWithoutOrganizationInput>;
  Folders?: InputMaybe<FolderCreateNestedManyWithoutOrganizationInput>;
  FutureBookingSetting?: InputMaybe<FutureBookingSettingCreateNestedOneWithoutOrganizationInput>;
  GlobalTransferRule?: InputMaybe<GlobalTransferRuleCreateNestedManyWithoutOrganizationInput>;
  held_demos_per_cc_connect?: InputMaybe<Scalars['Float']['input']>;
  helpful_links?: InputMaybe<OrganizationCreatehelpful_LinksInput>;
  HingePointMultiplier?: InputMaybe<HingePointMultiplierCreateNestedManyWithoutOrganizationInput>;
  hold_goal?: InputMaybe<Scalars['Float']['input']>;
  hubspot_credential?: InputMaybe<HubSpotCredentialCreateNestedOneWithoutOrganizationInput>;
  hubspot_last_sync_date?: InputMaybe<Scalars['DateTime']['input']>;
  hubspot_white_list?: InputMaybe<HubSpotWhitelistCreateNestedManyWithoutOrganizationInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  inbound_close_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_dm_connect_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_lt_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_revenue_value_of_sale?: InputMaybe<Scalars['Float']['input']>;
  inbound_scheduled_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_set_rate?: InputMaybe<Scalars['Float']['input']>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventCreateNestedManyWithoutOrganizationInput>;
  InboundConciergeWidget?: InputMaybe<InboundConciergeWidgetCreateNestedManyWithoutOrganizationInput>;
  industry_options?: InputMaybe<IndustryOptionCreateNestedManyWithoutOrganizationInput>;
  initial_sdr_sets_reps_day?: InputMaybe<Scalars['Float']['input']>;
  integration_error_logs?: InputMaybe<IntegrationErrorLogCreateNestedManyWithoutOrganizationInput>;
  integration_mappings?: InputMaybe<IntegrationMappingCreateNestedManyWithoutOrganizationInput>;
  IntegrationMappingCustomObject?: InputMaybe<IntegrationMappingCustomObjectCreateNestedManyWithoutOrganizationInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogCreateNestedManyWithoutOrganizationInput>;
  IntegrationReadOnlyObject?: InputMaybe<IntegrationReadOnlyObjectCreateNestedManyWithoutOrganizationInput>;
  international?: InputMaybe<Scalars['Boolean']['input']>;
  is_email_domain_verified?: InputMaybe<Scalars['Boolean']['input']>;
  is_salesforce_sandbox?: InputMaybe<Scalars['Boolean']['input']>;
  last_lead_value_compute_time?: InputMaybe<Scalars['DateTime']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutOrganizationInput>;
  lead_intent?: InputMaybe<LeadIntentCreateNestedManyWithoutOrganizationInput>;
  lead_release_same_rep_delay_rule?: InputMaybe<Scalars['Int']['input']>;
  lead_routing_email_list?: InputMaybe<OrganizationCreatelead_Routing_Email_ListInput>;
  lead_routing_rules?: InputMaybe<RuleCreateNestedManyWithoutOrganizationInput>;
  LeadCustomObjectRowAssociation?: InputMaybe<LeadCustomObjectRowAssociationCreateNestedManyWithoutOrganizationInput>;
  LeadFieldMultiplier?: InputMaybe<LeadFieldMultiplierCreateNestedManyWithoutOrganizationInput>;
  LeadFieldMultiplierCustom?: InputMaybe<LeadFieldMultiplierCustomCreateNestedManyWithoutOrganizationInput>;
  leads?: InputMaybe<LeadCreateNestedManyWithoutOrganizationInput>;
  leadsource_options?: InputMaybe<LeadSourceOptionCreateNestedManyWithoutOrganizationInput>;
  LeadValue?: InputMaybe<LeadValueCreateNestedManyWithoutOrganizationInput>;
  LifeTimeValueMultiplier?: InputMaybe<LifeTimeValueMultiplierCreateNestedManyWithoutOrganizationInput>;
  LiveTransferSetting?: InputMaybe<LiveTransferSettingCreateNestedOneWithoutOrganizationInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusCreateNestedManyWithoutOrganizationInput>;
  ltv_multiplier_custom_fields?: InputMaybe<LifeTimeValueMultiplierCustomCreateNestedManyWithoutOrganizationInput>;
  made_contact_rule?: InputMaybe<Scalars['Int']['input']>;
  make_sale_address_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_business_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_city_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_country_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_first_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_industry_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_last_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_lead_source_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_primary_email_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_primary_phone_number_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_state_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_sub_industry_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_zip_required?: InputMaybe<Scalars['Boolean']['input']>;
  maxium_day_lag_set_to_schedule?: InputMaybe<Scalars['Int']['input']>;
  mrr?: InputMaybe<Scalars['Int']['input']>;
  mrr_label?: InputMaybe<Scalars['String']['input']>;
  mrr_required_on_sale?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  NoteItems?: InputMaybe<NoteItemCreateNestedManyWithoutOrganizationInput>;
  Notifications?: InputMaybe<NotificationCreateNestedManyWithoutOrganizationInput>;
  onboardComplete?: InputMaybe<Scalars['Boolean']['input']>;
  Opportunity?: InputMaybe<SfOpportunityCreateNestedManyWithoutOrganizationInput>;
  organization_holidays?: InputMaybe<OrganizationHolidayCreateNestedManyWithoutOrganizationInput>;
  organization_hours?: InputMaybe<OrganizationHourCreateNestedManyWithoutOrganizationInput>;
  OrganizationPaymentSetting?: InputMaybe<OrganizationPaymentSettingsCreateNestedOneWithoutOrganizationInput>;
  OrganizationPaymentSettings?: InputMaybe<OrganizationPaymentSettingsCreateNestedOneWithoutOrganizationsInput>;
  orgCalendarInviteMetadata?: InputMaybe<OrgCalendarInviteMetadataCreateNestedManyWithoutOrganizationInput>;
  OrgDefaultPipelinePerecentValue?: InputMaybe<OrgDefaultPipelinePerecentValueCreateNestedOneWithoutOrganizationInput>;
  OrgHubSpotSync?: InputMaybe<OrgHubSpotSyncCreateNestedOneWithoutOrganizationInput>;
  OrgNoEventDate?: InputMaybe<OrgNoEventDateCreateNestedManyWithoutOrganizationInput>;
  OrgSalesforceSync?: InputMaybe<OrgSalesforceSyncCreateNestedOneWithoutOrganizationInput>;
  OrgTimeslotAvailability?: InputMaybe<OrgTimeslotAvailabilityCreateNestedManyWithoutOrganizationInput>;
  outbound_close_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_dm_connect_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_lt_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_revenue_value_of_sale?: InputMaybe<Scalars['Float']['input']>;
  outbound_scheduled_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_set_rate?: InputMaybe<Scalars['Float']['input']>;
  PandadocData?: InputMaybe<PandadocDataCreateNestedOneWithoutOrganizationInput>;
  PandaDocTemplate?: InputMaybe<PandaDocTemplateCreateNestedManyWithoutOrganizationInput>;
  participant_jitter_buffer_size?: InputMaybe<JitterBufferSize>;
  preferred_goal_setting?: InputMaybe<Goalsetting>;
  prepayment?: InputMaybe<Scalars['Boolean']['input']>;
  ProductChangeLog?: InputMaybe<ProductChangeLogCreateNestedManyWithoutOrganizationInput>;
  products?: InputMaybe<ProductCreateNestedManyWithoutOrganizationInput>;
  re_cold_call_hour_delay?: InputMaybe<Scalars['Float']['input']>;
  recordingStatus?: InputMaybe<RecordingStatus>;
  release_lead_resting?: InputMaybe<Scalars['Int']['input']>;
  rep_claim_limit?: InputMaybe<Scalars['Int']['input']>;
  rep_lead_allotment?: InputMaybe<Scalars['Int']['input']>;
  rep_level_call_transfer?: InputMaybe<Scalars['Boolean']['input']>;
  reps_can_bulk_unassign?: InputMaybe<Scalars['Boolean']['input']>;
  reps_can_view_all_notes?: InputMaybe<Scalars['Boolean']['input']>;
  require_sale_notes?: InputMaybe<Scalars['Boolean']['input']>;
  resting_after_skipped_CC?: InputMaybe<Scalars['Int']['input']>;
  resting_period?: InputMaybe<Scalars['Int']['input']>;
  resting_period_after_unsuccessful_cold_call?: InputMaybe<Scalars['Float']['input']>;
  resting_period_after_unsuccessful_demo?: InputMaybe<Scalars['Float']['input']>;
  revenue_plan?: InputMaybe<RevenuePlanItemCreateNestedManyWithoutOrganizationInput>;
  Routings?: InputMaybe<RoutingCreateNestedManyWithoutOrganizationInput>;
  sale_field_options?: InputMaybe<SaleFieldOptionCreateNestedManyWithoutOrganizationInput>;
  sale_notification_email_list?: InputMaybe<OrganizationCreatesale_Notification_Email_ListInput>;
  SaleConfig?: InputMaybe<SaleConfigCreateNestedOneWithoutOrganizationInput>;
  SaleConfigPage?: InputMaybe<SaleConfigPageCreateNestedManyWithoutOrganizationInput>;
  SaleConfigSection?: InputMaybe<SaleConfigSectionCreateNestedManyWithoutOrganizationInput>;
  SaleCycles?: InputMaybe<SaleCycleCreateNestedManyWithoutOrganizationInput>;
  SaleItems?: InputMaybe<SaleItemCreateNestedManyWithoutOrganizationInput>;
  sales?: InputMaybe<SaleCreateNestedManyWithoutOrganizationInput>;
  salesforce_domain?: InputMaybe<Scalars['String']['input']>;
  salesforce_last_sync_date?: InputMaybe<Scalars['DateTime']['input']>;
  SalesForceColumn?: InputMaybe<SalesForceColumnCreateNestedManyWithoutOrganizationInput>;
  salesForceData?: InputMaybe<SalesForceDataCreateNestedOneWithoutOrganizationInput>;
  ScheduleItems?: InputMaybe<ScheduleItemCreateNestedManyWithoutOrganizationInput>;
  self_sourcing_rule?: InputMaybe<Scalars['Int']['input']>;
  send_error_log_email?: InputMaybe<Scalars['Boolean']['input']>;
  send_lead_routing_email?: InputMaybe<Scalars['Boolean']['input']>;
  sendgrid_email_domain?: InputMaybe<Scalars['String']['input']>;
  sendgrid_email_domain_id?: InputMaybe<Scalars['Int']['input']>;
  sequence?: InputMaybe<SequenceCreateNestedManyWithoutOrganzationInput>;
  SequenceCriteriaConditions?: InputMaybe<SequenceCriteriaConditionCreateNestedManyWithoutOrganizationInput>;
  SequenceDashboardViews?: InputMaybe<SequenceDashboardViewCreateNestedManyWithoutOrganizationInput>;
  SequenceEntryCriterias?: InputMaybe<SequenceEntryCriteriaCreateNestedManyWithoutOrganizationInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutOrganizationInput>;
  SequenceSteps?: InputMaybe<SequenceStepCreateNestedManyWithoutOrganizationInput>;
  set_goal?: InputMaybe<Scalars['Float']['input']>;
  short_resting?: InputMaybe<Scalars['Int']['input']>;
  show_calendar_options?: InputMaybe<Scalars['Boolean']['input']>;
  show_contract_duration_sales?: InputMaybe<Scalars['Boolean']['input']>;
  show_lead_source_to_rep?: InputMaybe<Scalars['Boolean']['input']>;
  show_nocontact_button?: InputMaybe<Scalars['Boolean']['input']>;
  show_payment_terms_sales?: InputMaybe<Scalars['Boolean']['input']>;
  show_rep_all_leads?: InputMaybe<Scalars['Boolean']['input']>;
  show_rep_my_leads?: InputMaybe<Scalars['Boolean']['input']>;
  show_system_view?: InputMaybe<Scalars['Boolean']['input']>;
  sites?: InputMaybe<SiteCreateNestedManyWithoutOrganizationInput>;
  sms_opt_in_dialog?: InputMaybe<Scalars['String']['input']>;
  SMSItems?: InputMaybe<SmsItemCreateNestedManyWithoutOrganizationInput>;
  SnoozeTrackings?: InputMaybe<SnoozeTrackingCreateNestedManyWithoutOrganizationInput>;
  StepActionLogs?: InputMaybe<StepActionLogCreateNestedManyWithoutOrganizationInput>;
  StepActions?: InputMaybe<StepActionCreateNestedManyWithoutOrganizationInput>;
  StepConditions?: InputMaybe<StepConditionCreateNestedManyWithoutOrganizationInput>;
  teams?: InputMaybe<TeamCreateNestedManyWithoutOrganizationInput>;
  Template?: InputMaybe<TemplateCreateNestedManyWithoutOrganizationInput>;
  TemplateVersions?: InputMaybe<TemplateVersionCreateNestedManyWithoutOrganizationInput>;
  timekit_id?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  transfer_timeout?: InputMaybe<Scalars['Int']['input']>;
  TransferAttempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutOrganizationInput>;
  twilio_numbers?: InputMaybe<TwilioNumberCreateNestedManyWithoutOrganizationInput>;
  TwilioFeedbacks?: InputMaybe<TwilioFeedbackCreateNestedManyWithoutOrganizationInput>;
  TwilioInfo?: InputMaybe<TwilioInfoCreateNestedOneWithoutOrganzationInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  use_revenue_planning?: InputMaybe<Scalars['Boolean']['input']>;
  UserCustomFieldFilters?: InputMaybe<UserCustomFieldFilterCreateNestedManyWithoutOrganizationInput>;
  users?: InputMaybe<UserCreateNestedManyWithoutOrganizationInput>;
  win_rep_day_goal?: InputMaybe<Scalars['Float']['input']>;
};

export type OrganizationCreateWithoutLead_IntentInput = {
  ActivityMonitorMultiplier?: InputMaybe<ActivityMonitorMultiplierCreateNestedManyWithoutOrganizationInput>;
  algoFactor?: InputMaybe<AlgoFactorCreateNestedManyWithoutOrganizationInput>;
  allow_calendar_invite?: InputMaybe<Scalars['Boolean']['input']>;
  allow_remove_step_action?: InputMaybe<Scalars['Boolean']['input']>;
  allow_snooze_step_action?: InputMaybe<Scalars['Boolean']['input']>;
  always_record?: InputMaybe<Scalars['Boolean']['input']>;
  arv_initial_held_demos_generated_by_sdr_weight?: InputMaybe<Scalars['Float']['input']>;
  AssociationHistories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutOrganizationInput>;
  auto_call_interval?: InputMaybe<Scalars['Int']['input']>;
  auto_dial?: InputMaybe<Scalars['Boolean']['input']>;
  automated_campaigns?: InputMaybe<AutomatedCampaignCreateNestedManyWithoutOrganizationInput>;
  automatic_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  AutoTransferSelections?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutOrganizationInput>;
  Availablities?: InputMaybe<AvailabilityCreateNestedManyWithoutOrganizationInput>;
  AverageOrderValue?: InputMaybe<AverageOrderValueCreateNestedManyWithoutOrganizationInput>;
  best_action_ever_multiplier?: InputMaybe<Scalars['Float']['input']>;
  best_metrics?: InputMaybe<BestMetricRecordCreateNestedManyWithoutOrganizationInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptCreateNestedManyWithoutOrganizationInput>;
  callMeNows?: InputMaybe<CallMeNowCreateNestedManyWithoutOrganizationInput>;
  CallMultiplier?: InputMaybe<CallMultiplierCreateNestedManyWithoutOrganizationInput>;
  CallSharings?: InputMaybe<CallSharingCreateNestedManyWithoutOrganizationInput>;
  can_edit_sdr_transfer_rules?: InputMaybe<Scalars['Boolean']['input']>;
  claim_rule?: InputMaybe<Scalars['Int']['input']>;
  concession?: InputMaybe<ConcessionCreateNestedManyWithoutOrganizationInput>;
  conference_region?: InputMaybe<ConferenceRegion>;
  Conferences?: InputMaybe<ConferenceCreateNestedManyWithoutOrganizationInput>;
  ContactRequests?: InputMaybe<ContactRequestCreateNestedManyWithoutOrganizationInput>;
  Contacts?: InputMaybe<ContactCreateNestedManyWithoutOrganizationInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  custom_fields?: InputMaybe<CustomFieldCreateNestedManyWithoutOrganizationInput>;
  CustomObject?: InputMaybe<CustomObjectCreateNestedManyWithoutOrganizationInput>;
  CustomObjectField?: InputMaybe<CustomObjectFieldCreateNestedManyWithoutOrganizationInput>;
  CustomObjectFieldForeignKey?: InputMaybe<CustomObjectFieldForeignKeyCreateNestedManyWithoutOrganizationInput>;
  CustomObjectRecord?: InputMaybe<CustomObjectRecordCreateNestedManyWithoutOrganizationInput>;
  CustomObjectRow?: InputMaybe<CustomObjectRowCreateNestedManyWithoutOrganizationInput>;
  CustomQueueItems?: InputMaybe<CustomQueueItemCreateNestedManyWithoutOrganizationInput>;
  DashboardColumnOptions?: InputMaybe<DashboardColumnOptionCreateNestedManyWithoutOrganizationInput>;
  DashboardLeadFilters?: InputMaybe<DashboardLeadFilterCreateNestedManyWithoutOrganizationInput>;
  DashboardRepFilters?: InputMaybe<DashboardRepFilterCreateNestedManyWithoutOrganizationInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewCreateNestedManyWithoutOrganizationInput>;
  day_to_close?: InputMaybe<Scalars['Int']['input']>;
  default_demo_time_duration?: InputMaybe<Scalars['Int']['input']>;
  demo_held_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_not_held_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_not_held_call_back_short_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_prep_time_minutes?: InputMaybe<Scalars['Int']['input']>;
  dev_account?: InputMaybe<Scalars['Boolean']['input']>;
  dial_rule?: InputMaybe<Scalars['Int']['input']>;
  DispositionMultiplier?: InputMaybe<DispositionMultiplierCreateNestedManyWithoutOrganizationInput>;
  dispositions?: InputMaybe<DispositionCreateNestedManyWithoutOrganizationInput>;
  double_booking?: InputMaybe<Scalars['Boolean']['input']>;
  dynamic_date_operators?: InputMaybe<Scalars['Boolean']['input']>;
  edge_servers?: InputMaybe<OrganizationCreateedge_ServersInput>;
  email_conflict_notification_email_list?: InputMaybe<OrganizationCreateemail_Conflict_Notification_Email_ListInput>;
  EmailItems?: InputMaybe<EmailItemCreateNestedManyWithoutOrganizationInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordCreateNestedManyWithoutOrganizationInput>;
  EmailThreads?: InputMaybe<EmailThreadCreateNestedManyWithoutOrganizationInput>;
  enable_modified_metrics?: InputMaybe<Scalars['Boolean']['input']>;
  enable_pay_period_view?: InputMaybe<Scalars['Boolean']['input']>;
  enable_time_constraint?: InputMaybe<Scalars['Boolean']['input']>;
  error_log_email_address?: InputMaybe<OrganizationCreateerror_Log_Email_AddressInput>;
  estimated_average_num_of_weekly_overbooked_demo_slots?: InputMaybe<Scalars['Int']['input']>;
  FolderPermissions?: InputMaybe<FolderPermissionCreateNestedManyWithoutOrganizationInput>;
  Folders?: InputMaybe<FolderCreateNestedManyWithoutOrganizationInput>;
  FutureBookingSetting?: InputMaybe<FutureBookingSettingCreateNestedOneWithoutOrganizationInput>;
  GlobalTransferRule?: InputMaybe<GlobalTransferRuleCreateNestedManyWithoutOrganizationInput>;
  held_demos_per_cc_connect?: InputMaybe<Scalars['Float']['input']>;
  helpful_links?: InputMaybe<OrganizationCreatehelpful_LinksInput>;
  HingePointMultiplier?: InputMaybe<HingePointMultiplierCreateNestedManyWithoutOrganizationInput>;
  hold_goal?: InputMaybe<Scalars['Float']['input']>;
  hubspot_credential?: InputMaybe<HubSpotCredentialCreateNestedOneWithoutOrganizationInput>;
  hubspot_last_sync_date?: InputMaybe<Scalars['DateTime']['input']>;
  hubspot_white_list?: InputMaybe<HubSpotWhitelistCreateNestedManyWithoutOrganizationInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  inbound_close_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_dm_connect_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_lt_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_revenue_value_of_sale?: InputMaybe<Scalars['Float']['input']>;
  inbound_scheduled_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_set_rate?: InputMaybe<Scalars['Float']['input']>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventCreateNestedManyWithoutOrganizationInput>;
  InboundConciergeWidget?: InputMaybe<InboundConciergeWidgetCreateNestedManyWithoutOrganizationInput>;
  industry_options?: InputMaybe<IndustryOptionCreateNestedManyWithoutOrganizationInput>;
  initial_sdr_sets_reps_day?: InputMaybe<Scalars['Float']['input']>;
  integration_error_logs?: InputMaybe<IntegrationErrorLogCreateNestedManyWithoutOrganizationInput>;
  integration_mappings?: InputMaybe<IntegrationMappingCreateNestedManyWithoutOrganizationInput>;
  IntegrationMappingCustomObject?: InputMaybe<IntegrationMappingCustomObjectCreateNestedManyWithoutOrganizationInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogCreateNestedManyWithoutOrganizationInput>;
  IntegrationReadOnlyObject?: InputMaybe<IntegrationReadOnlyObjectCreateNestedManyWithoutOrganizationInput>;
  international?: InputMaybe<Scalars['Boolean']['input']>;
  is_email_domain_verified?: InputMaybe<Scalars['Boolean']['input']>;
  is_salesforce_sandbox?: InputMaybe<Scalars['Boolean']['input']>;
  last_lead_value_compute_time?: InputMaybe<Scalars['DateTime']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutOrganizationInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryCreateNestedManyWithoutOrganizationInput>;
  lead_release_same_rep_delay_rule?: InputMaybe<Scalars['Int']['input']>;
  lead_routing_email_list?: InputMaybe<OrganizationCreatelead_Routing_Email_ListInput>;
  lead_routing_rules?: InputMaybe<RuleCreateNestedManyWithoutOrganizationInput>;
  LeadCustomObjectRowAssociation?: InputMaybe<LeadCustomObjectRowAssociationCreateNestedManyWithoutOrganizationInput>;
  LeadFieldMultiplier?: InputMaybe<LeadFieldMultiplierCreateNestedManyWithoutOrganizationInput>;
  LeadFieldMultiplierCustom?: InputMaybe<LeadFieldMultiplierCustomCreateNestedManyWithoutOrganizationInput>;
  leads?: InputMaybe<LeadCreateNestedManyWithoutOrganizationInput>;
  leadsource_options?: InputMaybe<LeadSourceOptionCreateNestedManyWithoutOrganizationInput>;
  LeadValue?: InputMaybe<LeadValueCreateNestedManyWithoutOrganizationInput>;
  LifeTimeValueMultiplier?: InputMaybe<LifeTimeValueMultiplierCreateNestedManyWithoutOrganizationInput>;
  LiveTransferSetting?: InputMaybe<LiveTransferSettingCreateNestedOneWithoutOrganizationInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusCreateNestedManyWithoutOrganizationInput>;
  ltv_multiplier_custom_fields?: InputMaybe<LifeTimeValueMultiplierCustomCreateNestedManyWithoutOrganizationInput>;
  made_contact_rule?: InputMaybe<Scalars['Int']['input']>;
  make_sale_address_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_business_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_city_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_country_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_first_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_industry_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_last_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_lead_source_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_primary_email_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_primary_phone_number_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_state_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_sub_industry_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_zip_required?: InputMaybe<Scalars['Boolean']['input']>;
  maxium_day_lag_set_to_schedule?: InputMaybe<Scalars['Int']['input']>;
  mrr?: InputMaybe<Scalars['Int']['input']>;
  mrr_label?: InputMaybe<Scalars['String']['input']>;
  mrr_required_on_sale?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  NoteItems?: InputMaybe<NoteItemCreateNestedManyWithoutOrganizationInput>;
  Notifications?: InputMaybe<NotificationCreateNestedManyWithoutOrganizationInput>;
  onboardComplete?: InputMaybe<Scalars['Boolean']['input']>;
  Opportunity?: InputMaybe<SfOpportunityCreateNestedManyWithoutOrganizationInput>;
  organization_holidays?: InputMaybe<OrganizationHolidayCreateNestedManyWithoutOrganizationInput>;
  organization_hours?: InputMaybe<OrganizationHourCreateNestedManyWithoutOrganizationInput>;
  OrganizationPaymentSetting?: InputMaybe<OrganizationPaymentSettingsCreateNestedOneWithoutOrganizationInput>;
  OrganizationPaymentSettings?: InputMaybe<OrganizationPaymentSettingsCreateNestedOneWithoutOrganizationsInput>;
  orgCalendarInviteMetadata?: InputMaybe<OrgCalendarInviteMetadataCreateNestedManyWithoutOrganizationInput>;
  OrgDefaultPipelinePerecentValue?: InputMaybe<OrgDefaultPipelinePerecentValueCreateNestedOneWithoutOrganizationInput>;
  OrgHubSpotSync?: InputMaybe<OrgHubSpotSyncCreateNestedOneWithoutOrganizationInput>;
  OrgNoEventDate?: InputMaybe<OrgNoEventDateCreateNestedManyWithoutOrganizationInput>;
  OrgSalesforceSync?: InputMaybe<OrgSalesforceSyncCreateNestedOneWithoutOrganizationInput>;
  OrgTimeslotAvailability?: InputMaybe<OrgTimeslotAvailabilityCreateNestedManyWithoutOrganizationInput>;
  outbound_close_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_dm_connect_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_lt_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_revenue_value_of_sale?: InputMaybe<Scalars['Float']['input']>;
  outbound_scheduled_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_set_rate?: InputMaybe<Scalars['Float']['input']>;
  PandadocData?: InputMaybe<PandadocDataCreateNestedOneWithoutOrganizationInput>;
  PandaDocTemplate?: InputMaybe<PandaDocTemplateCreateNestedManyWithoutOrganizationInput>;
  participant_jitter_buffer_size?: InputMaybe<JitterBufferSize>;
  preferred_goal_setting?: InputMaybe<Goalsetting>;
  prepayment?: InputMaybe<Scalars['Boolean']['input']>;
  ProductChangeLog?: InputMaybe<ProductChangeLogCreateNestedManyWithoutOrganizationInput>;
  products?: InputMaybe<ProductCreateNestedManyWithoutOrganizationInput>;
  re_cold_call_hour_delay?: InputMaybe<Scalars['Float']['input']>;
  recordingStatus?: InputMaybe<RecordingStatus>;
  release_lead_resting?: InputMaybe<Scalars['Int']['input']>;
  rep_claim_limit?: InputMaybe<Scalars['Int']['input']>;
  rep_lead_allotment?: InputMaybe<Scalars['Int']['input']>;
  rep_level_call_transfer?: InputMaybe<Scalars['Boolean']['input']>;
  reps_can_bulk_unassign?: InputMaybe<Scalars['Boolean']['input']>;
  reps_can_view_all_notes?: InputMaybe<Scalars['Boolean']['input']>;
  require_sale_notes?: InputMaybe<Scalars['Boolean']['input']>;
  resting_after_skipped_CC?: InputMaybe<Scalars['Int']['input']>;
  resting_period?: InputMaybe<Scalars['Int']['input']>;
  resting_period_after_unsuccessful_cold_call?: InputMaybe<Scalars['Float']['input']>;
  resting_period_after_unsuccessful_demo?: InputMaybe<Scalars['Float']['input']>;
  revenue_plan?: InputMaybe<RevenuePlanItemCreateNestedManyWithoutOrganizationInput>;
  Routings?: InputMaybe<RoutingCreateNestedManyWithoutOrganizationInput>;
  sale_field_options?: InputMaybe<SaleFieldOptionCreateNestedManyWithoutOrganizationInput>;
  sale_notification_email_list?: InputMaybe<OrganizationCreatesale_Notification_Email_ListInput>;
  SaleConfig?: InputMaybe<SaleConfigCreateNestedOneWithoutOrganizationInput>;
  SaleConfigPage?: InputMaybe<SaleConfigPageCreateNestedManyWithoutOrganizationInput>;
  SaleConfigSection?: InputMaybe<SaleConfigSectionCreateNestedManyWithoutOrganizationInput>;
  SaleCycles?: InputMaybe<SaleCycleCreateNestedManyWithoutOrganizationInput>;
  SaleItems?: InputMaybe<SaleItemCreateNestedManyWithoutOrganizationInput>;
  sales?: InputMaybe<SaleCreateNestedManyWithoutOrganizationInput>;
  salesforce_domain?: InputMaybe<Scalars['String']['input']>;
  salesforce_last_sync_date?: InputMaybe<Scalars['DateTime']['input']>;
  SalesForceColumn?: InputMaybe<SalesForceColumnCreateNestedManyWithoutOrganizationInput>;
  salesForceData?: InputMaybe<SalesForceDataCreateNestedOneWithoutOrganizationInput>;
  ScheduleItems?: InputMaybe<ScheduleItemCreateNestedManyWithoutOrganizationInput>;
  self_sourcing_rule?: InputMaybe<Scalars['Int']['input']>;
  send_error_log_email?: InputMaybe<Scalars['Boolean']['input']>;
  send_lead_routing_email?: InputMaybe<Scalars['Boolean']['input']>;
  sendgrid_email_domain?: InputMaybe<Scalars['String']['input']>;
  sendgrid_email_domain_id?: InputMaybe<Scalars['Int']['input']>;
  sequence?: InputMaybe<SequenceCreateNestedManyWithoutOrganzationInput>;
  SequenceCriteriaConditions?: InputMaybe<SequenceCriteriaConditionCreateNestedManyWithoutOrganizationInput>;
  SequenceDashboardViews?: InputMaybe<SequenceDashboardViewCreateNestedManyWithoutOrganizationInput>;
  SequenceEntryCriterias?: InputMaybe<SequenceEntryCriteriaCreateNestedManyWithoutOrganizationInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutOrganizationInput>;
  SequenceSteps?: InputMaybe<SequenceStepCreateNestedManyWithoutOrganizationInput>;
  set_goal?: InputMaybe<Scalars['Float']['input']>;
  short_resting?: InputMaybe<Scalars['Int']['input']>;
  show_calendar_options?: InputMaybe<Scalars['Boolean']['input']>;
  show_contract_duration_sales?: InputMaybe<Scalars['Boolean']['input']>;
  show_lead_source_to_rep?: InputMaybe<Scalars['Boolean']['input']>;
  show_nocontact_button?: InputMaybe<Scalars['Boolean']['input']>;
  show_payment_terms_sales?: InputMaybe<Scalars['Boolean']['input']>;
  show_rep_all_leads?: InputMaybe<Scalars['Boolean']['input']>;
  show_rep_my_leads?: InputMaybe<Scalars['Boolean']['input']>;
  show_system_view?: InputMaybe<Scalars['Boolean']['input']>;
  sites?: InputMaybe<SiteCreateNestedManyWithoutOrganizationInput>;
  sms_opt_in_dialog?: InputMaybe<Scalars['String']['input']>;
  SMSItems?: InputMaybe<SmsItemCreateNestedManyWithoutOrganizationInput>;
  SnoozeTrackings?: InputMaybe<SnoozeTrackingCreateNestedManyWithoutOrganizationInput>;
  StepActionLogs?: InputMaybe<StepActionLogCreateNestedManyWithoutOrganizationInput>;
  StepActions?: InputMaybe<StepActionCreateNestedManyWithoutOrganizationInput>;
  StepConditions?: InputMaybe<StepConditionCreateNestedManyWithoutOrganizationInput>;
  teams?: InputMaybe<TeamCreateNestedManyWithoutOrganizationInput>;
  Template?: InputMaybe<TemplateCreateNestedManyWithoutOrganizationInput>;
  TemplateVersions?: InputMaybe<TemplateVersionCreateNestedManyWithoutOrganizationInput>;
  timekit_id?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  transfer_timeout?: InputMaybe<Scalars['Int']['input']>;
  TransferAttempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutOrganizationInput>;
  twilio_numbers?: InputMaybe<TwilioNumberCreateNestedManyWithoutOrganizationInput>;
  TwilioFeedbacks?: InputMaybe<TwilioFeedbackCreateNestedManyWithoutOrganizationInput>;
  TwilioInfo?: InputMaybe<TwilioInfoCreateNestedOneWithoutOrganzationInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  use_revenue_planning?: InputMaybe<Scalars['Boolean']['input']>;
  UserCustomFieldFilters?: InputMaybe<UserCustomFieldFilterCreateNestedManyWithoutOrganizationInput>;
  users?: InputMaybe<UserCreateNestedManyWithoutOrganizationInput>;
  win_rep_day_goal?: InputMaybe<Scalars['Float']['input']>;
};

export type OrganizationCreateWithoutLead_Routing_RulesInput = {
  ActivityMonitorMultiplier?: InputMaybe<ActivityMonitorMultiplierCreateNestedManyWithoutOrganizationInput>;
  algoFactor?: InputMaybe<AlgoFactorCreateNestedManyWithoutOrganizationInput>;
  allow_calendar_invite?: InputMaybe<Scalars['Boolean']['input']>;
  allow_remove_step_action?: InputMaybe<Scalars['Boolean']['input']>;
  allow_snooze_step_action?: InputMaybe<Scalars['Boolean']['input']>;
  always_record?: InputMaybe<Scalars['Boolean']['input']>;
  arv_initial_held_demos_generated_by_sdr_weight?: InputMaybe<Scalars['Float']['input']>;
  AssociationHistories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutOrganizationInput>;
  auto_call_interval?: InputMaybe<Scalars['Int']['input']>;
  auto_dial?: InputMaybe<Scalars['Boolean']['input']>;
  automated_campaigns?: InputMaybe<AutomatedCampaignCreateNestedManyWithoutOrganizationInput>;
  automatic_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  AutoTransferSelections?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutOrganizationInput>;
  Availablities?: InputMaybe<AvailabilityCreateNestedManyWithoutOrganizationInput>;
  AverageOrderValue?: InputMaybe<AverageOrderValueCreateNestedManyWithoutOrganizationInput>;
  best_action_ever_multiplier?: InputMaybe<Scalars['Float']['input']>;
  best_metrics?: InputMaybe<BestMetricRecordCreateNestedManyWithoutOrganizationInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptCreateNestedManyWithoutOrganizationInput>;
  callMeNows?: InputMaybe<CallMeNowCreateNestedManyWithoutOrganizationInput>;
  CallMultiplier?: InputMaybe<CallMultiplierCreateNestedManyWithoutOrganizationInput>;
  CallSharings?: InputMaybe<CallSharingCreateNestedManyWithoutOrganizationInput>;
  can_edit_sdr_transfer_rules?: InputMaybe<Scalars['Boolean']['input']>;
  claim_rule?: InputMaybe<Scalars['Int']['input']>;
  concession?: InputMaybe<ConcessionCreateNestedManyWithoutOrganizationInput>;
  conference_region?: InputMaybe<ConferenceRegion>;
  Conferences?: InputMaybe<ConferenceCreateNestedManyWithoutOrganizationInput>;
  ContactRequests?: InputMaybe<ContactRequestCreateNestedManyWithoutOrganizationInput>;
  Contacts?: InputMaybe<ContactCreateNestedManyWithoutOrganizationInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  custom_fields?: InputMaybe<CustomFieldCreateNestedManyWithoutOrganizationInput>;
  CustomObject?: InputMaybe<CustomObjectCreateNestedManyWithoutOrganizationInput>;
  CustomObjectField?: InputMaybe<CustomObjectFieldCreateNestedManyWithoutOrganizationInput>;
  CustomObjectFieldForeignKey?: InputMaybe<CustomObjectFieldForeignKeyCreateNestedManyWithoutOrganizationInput>;
  CustomObjectRecord?: InputMaybe<CustomObjectRecordCreateNestedManyWithoutOrganizationInput>;
  CustomObjectRow?: InputMaybe<CustomObjectRowCreateNestedManyWithoutOrganizationInput>;
  CustomQueueItems?: InputMaybe<CustomQueueItemCreateNestedManyWithoutOrganizationInput>;
  DashboardColumnOptions?: InputMaybe<DashboardColumnOptionCreateNestedManyWithoutOrganizationInput>;
  DashboardLeadFilters?: InputMaybe<DashboardLeadFilterCreateNestedManyWithoutOrganizationInput>;
  DashboardRepFilters?: InputMaybe<DashboardRepFilterCreateNestedManyWithoutOrganizationInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewCreateNestedManyWithoutOrganizationInput>;
  day_to_close?: InputMaybe<Scalars['Int']['input']>;
  default_demo_time_duration?: InputMaybe<Scalars['Int']['input']>;
  demo_held_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_not_held_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_not_held_call_back_short_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_prep_time_minutes?: InputMaybe<Scalars['Int']['input']>;
  dev_account?: InputMaybe<Scalars['Boolean']['input']>;
  dial_rule?: InputMaybe<Scalars['Int']['input']>;
  DispositionMultiplier?: InputMaybe<DispositionMultiplierCreateNestedManyWithoutOrganizationInput>;
  dispositions?: InputMaybe<DispositionCreateNestedManyWithoutOrganizationInput>;
  double_booking?: InputMaybe<Scalars['Boolean']['input']>;
  dynamic_date_operators?: InputMaybe<Scalars['Boolean']['input']>;
  edge_servers?: InputMaybe<OrganizationCreateedge_ServersInput>;
  email_conflict_notification_email_list?: InputMaybe<OrganizationCreateemail_Conflict_Notification_Email_ListInput>;
  EmailItems?: InputMaybe<EmailItemCreateNestedManyWithoutOrganizationInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordCreateNestedManyWithoutOrganizationInput>;
  EmailThreads?: InputMaybe<EmailThreadCreateNestedManyWithoutOrganizationInput>;
  enable_modified_metrics?: InputMaybe<Scalars['Boolean']['input']>;
  enable_pay_period_view?: InputMaybe<Scalars['Boolean']['input']>;
  enable_time_constraint?: InputMaybe<Scalars['Boolean']['input']>;
  error_log_email_address?: InputMaybe<OrganizationCreateerror_Log_Email_AddressInput>;
  estimated_average_num_of_weekly_overbooked_demo_slots?: InputMaybe<Scalars['Int']['input']>;
  FolderPermissions?: InputMaybe<FolderPermissionCreateNestedManyWithoutOrganizationInput>;
  Folders?: InputMaybe<FolderCreateNestedManyWithoutOrganizationInput>;
  FutureBookingSetting?: InputMaybe<FutureBookingSettingCreateNestedOneWithoutOrganizationInput>;
  GlobalTransferRule?: InputMaybe<GlobalTransferRuleCreateNestedManyWithoutOrganizationInput>;
  held_demos_per_cc_connect?: InputMaybe<Scalars['Float']['input']>;
  helpful_links?: InputMaybe<OrganizationCreatehelpful_LinksInput>;
  HingePointMultiplier?: InputMaybe<HingePointMultiplierCreateNestedManyWithoutOrganizationInput>;
  hold_goal?: InputMaybe<Scalars['Float']['input']>;
  hubspot_credential?: InputMaybe<HubSpotCredentialCreateNestedOneWithoutOrganizationInput>;
  hubspot_last_sync_date?: InputMaybe<Scalars['DateTime']['input']>;
  hubspot_white_list?: InputMaybe<HubSpotWhitelistCreateNestedManyWithoutOrganizationInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  inbound_close_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_dm_connect_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_lt_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_revenue_value_of_sale?: InputMaybe<Scalars['Float']['input']>;
  inbound_scheduled_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_set_rate?: InputMaybe<Scalars['Float']['input']>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventCreateNestedManyWithoutOrganizationInput>;
  InboundConciergeWidget?: InputMaybe<InboundConciergeWidgetCreateNestedManyWithoutOrganizationInput>;
  industry_options?: InputMaybe<IndustryOptionCreateNestedManyWithoutOrganizationInput>;
  initial_sdr_sets_reps_day?: InputMaybe<Scalars['Float']['input']>;
  integration_error_logs?: InputMaybe<IntegrationErrorLogCreateNestedManyWithoutOrganizationInput>;
  integration_mappings?: InputMaybe<IntegrationMappingCreateNestedManyWithoutOrganizationInput>;
  IntegrationMappingCustomObject?: InputMaybe<IntegrationMappingCustomObjectCreateNestedManyWithoutOrganizationInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogCreateNestedManyWithoutOrganizationInput>;
  IntegrationReadOnlyObject?: InputMaybe<IntegrationReadOnlyObjectCreateNestedManyWithoutOrganizationInput>;
  international?: InputMaybe<Scalars['Boolean']['input']>;
  is_email_domain_verified?: InputMaybe<Scalars['Boolean']['input']>;
  is_salesforce_sandbox?: InputMaybe<Scalars['Boolean']['input']>;
  last_lead_value_compute_time?: InputMaybe<Scalars['DateTime']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutOrganizationInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryCreateNestedManyWithoutOrganizationInput>;
  lead_intent?: InputMaybe<LeadIntentCreateNestedManyWithoutOrganizationInput>;
  lead_release_same_rep_delay_rule?: InputMaybe<Scalars['Int']['input']>;
  lead_routing_email_list?: InputMaybe<OrganizationCreatelead_Routing_Email_ListInput>;
  LeadCustomObjectRowAssociation?: InputMaybe<LeadCustomObjectRowAssociationCreateNestedManyWithoutOrganizationInput>;
  LeadFieldMultiplier?: InputMaybe<LeadFieldMultiplierCreateNestedManyWithoutOrganizationInput>;
  LeadFieldMultiplierCustom?: InputMaybe<LeadFieldMultiplierCustomCreateNestedManyWithoutOrganizationInput>;
  leads?: InputMaybe<LeadCreateNestedManyWithoutOrganizationInput>;
  leadsource_options?: InputMaybe<LeadSourceOptionCreateNestedManyWithoutOrganizationInput>;
  LeadValue?: InputMaybe<LeadValueCreateNestedManyWithoutOrganizationInput>;
  LifeTimeValueMultiplier?: InputMaybe<LifeTimeValueMultiplierCreateNestedManyWithoutOrganizationInput>;
  LiveTransferSetting?: InputMaybe<LiveTransferSettingCreateNestedOneWithoutOrganizationInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusCreateNestedManyWithoutOrganizationInput>;
  ltv_multiplier_custom_fields?: InputMaybe<LifeTimeValueMultiplierCustomCreateNestedManyWithoutOrganizationInput>;
  made_contact_rule?: InputMaybe<Scalars['Int']['input']>;
  make_sale_address_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_business_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_city_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_country_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_first_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_industry_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_last_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_lead_source_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_primary_email_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_primary_phone_number_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_state_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_sub_industry_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_zip_required?: InputMaybe<Scalars['Boolean']['input']>;
  maxium_day_lag_set_to_schedule?: InputMaybe<Scalars['Int']['input']>;
  mrr?: InputMaybe<Scalars['Int']['input']>;
  mrr_label?: InputMaybe<Scalars['String']['input']>;
  mrr_required_on_sale?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  NoteItems?: InputMaybe<NoteItemCreateNestedManyWithoutOrganizationInput>;
  Notifications?: InputMaybe<NotificationCreateNestedManyWithoutOrganizationInput>;
  onboardComplete?: InputMaybe<Scalars['Boolean']['input']>;
  Opportunity?: InputMaybe<SfOpportunityCreateNestedManyWithoutOrganizationInput>;
  organization_holidays?: InputMaybe<OrganizationHolidayCreateNestedManyWithoutOrganizationInput>;
  organization_hours?: InputMaybe<OrganizationHourCreateNestedManyWithoutOrganizationInput>;
  OrganizationPaymentSetting?: InputMaybe<OrganizationPaymentSettingsCreateNestedOneWithoutOrganizationInput>;
  OrganizationPaymentSettings?: InputMaybe<OrganizationPaymentSettingsCreateNestedOneWithoutOrganizationsInput>;
  orgCalendarInviteMetadata?: InputMaybe<OrgCalendarInviteMetadataCreateNestedManyWithoutOrganizationInput>;
  OrgDefaultPipelinePerecentValue?: InputMaybe<OrgDefaultPipelinePerecentValueCreateNestedOneWithoutOrganizationInput>;
  OrgHubSpotSync?: InputMaybe<OrgHubSpotSyncCreateNestedOneWithoutOrganizationInput>;
  OrgNoEventDate?: InputMaybe<OrgNoEventDateCreateNestedManyWithoutOrganizationInput>;
  OrgSalesforceSync?: InputMaybe<OrgSalesforceSyncCreateNestedOneWithoutOrganizationInput>;
  OrgTimeslotAvailability?: InputMaybe<OrgTimeslotAvailabilityCreateNestedManyWithoutOrganizationInput>;
  outbound_close_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_dm_connect_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_lt_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_revenue_value_of_sale?: InputMaybe<Scalars['Float']['input']>;
  outbound_scheduled_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_set_rate?: InputMaybe<Scalars['Float']['input']>;
  PandadocData?: InputMaybe<PandadocDataCreateNestedOneWithoutOrganizationInput>;
  PandaDocTemplate?: InputMaybe<PandaDocTemplateCreateNestedManyWithoutOrganizationInput>;
  participant_jitter_buffer_size?: InputMaybe<JitterBufferSize>;
  preferred_goal_setting?: InputMaybe<Goalsetting>;
  prepayment?: InputMaybe<Scalars['Boolean']['input']>;
  ProductChangeLog?: InputMaybe<ProductChangeLogCreateNestedManyWithoutOrganizationInput>;
  products?: InputMaybe<ProductCreateNestedManyWithoutOrganizationInput>;
  re_cold_call_hour_delay?: InputMaybe<Scalars['Float']['input']>;
  recordingStatus?: InputMaybe<RecordingStatus>;
  release_lead_resting?: InputMaybe<Scalars['Int']['input']>;
  rep_claim_limit?: InputMaybe<Scalars['Int']['input']>;
  rep_lead_allotment?: InputMaybe<Scalars['Int']['input']>;
  rep_level_call_transfer?: InputMaybe<Scalars['Boolean']['input']>;
  reps_can_bulk_unassign?: InputMaybe<Scalars['Boolean']['input']>;
  reps_can_view_all_notes?: InputMaybe<Scalars['Boolean']['input']>;
  require_sale_notes?: InputMaybe<Scalars['Boolean']['input']>;
  resting_after_skipped_CC?: InputMaybe<Scalars['Int']['input']>;
  resting_period?: InputMaybe<Scalars['Int']['input']>;
  resting_period_after_unsuccessful_cold_call?: InputMaybe<Scalars['Float']['input']>;
  resting_period_after_unsuccessful_demo?: InputMaybe<Scalars['Float']['input']>;
  revenue_plan?: InputMaybe<RevenuePlanItemCreateNestedManyWithoutOrganizationInput>;
  Routings?: InputMaybe<RoutingCreateNestedManyWithoutOrganizationInput>;
  sale_field_options?: InputMaybe<SaleFieldOptionCreateNestedManyWithoutOrganizationInput>;
  sale_notification_email_list?: InputMaybe<OrganizationCreatesale_Notification_Email_ListInput>;
  SaleConfig?: InputMaybe<SaleConfigCreateNestedOneWithoutOrganizationInput>;
  SaleConfigPage?: InputMaybe<SaleConfigPageCreateNestedManyWithoutOrganizationInput>;
  SaleConfigSection?: InputMaybe<SaleConfigSectionCreateNestedManyWithoutOrganizationInput>;
  SaleCycles?: InputMaybe<SaleCycleCreateNestedManyWithoutOrganizationInput>;
  SaleItems?: InputMaybe<SaleItemCreateNestedManyWithoutOrganizationInput>;
  sales?: InputMaybe<SaleCreateNestedManyWithoutOrganizationInput>;
  salesforce_domain?: InputMaybe<Scalars['String']['input']>;
  salesforce_last_sync_date?: InputMaybe<Scalars['DateTime']['input']>;
  SalesForceColumn?: InputMaybe<SalesForceColumnCreateNestedManyWithoutOrganizationInput>;
  salesForceData?: InputMaybe<SalesForceDataCreateNestedOneWithoutOrganizationInput>;
  ScheduleItems?: InputMaybe<ScheduleItemCreateNestedManyWithoutOrganizationInput>;
  self_sourcing_rule?: InputMaybe<Scalars['Int']['input']>;
  send_error_log_email?: InputMaybe<Scalars['Boolean']['input']>;
  send_lead_routing_email?: InputMaybe<Scalars['Boolean']['input']>;
  sendgrid_email_domain?: InputMaybe<Scalars['String']['input']>;
  sendgrid_email_domain_id?: InputMaybe<Scalars['Int']['input']>;
  sequence?: InputMaybe<SequenceCreateNestedManyWithoutOrganzationInput>;
  SequenceCriteriaConditions?: InputMaybe<SequenceCriteriaConditionCreateNestedManyWithoutOrganizationInput>;
  SequenceDashboardViews?: InputMaybe<SequenceDashboardViewCreateNestedManyWithoutOrganizationInput>;
  SequenceEntryCriterias?: InputMaybe<SequenceEntryCriteriaCreateNestedManyWithoutOrganizationInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutOrganizationInput>;
  SequenceSteps?: InputMaybe<SequenceStepCreateNestedManyWithoutOrganizationInput>;
  set_goal?: InputMaybe<Scalars['Float']['input']>;
  short_resting?: InputMaybe<Scalars['Int']['input']>;
  show_calendar_options?: InputMaybe<Scalars['Boolean']['input']>;
  show_contract_duration_sales?: InputMaybe<Scalars['Boolean']['input']>;
  show_lead_source_to_rep?: InputMaybe<Scalars['Boolean']['input']>;
  show_nocontact_button?: InputMaybe<Scalars['Boolean']['input']>;
  show_payment_terms_sales?: InputMaybe<Scalars['Boolean']['input']>;
  show_rep_all_leads?: InputMaybe<Scalars['Boolean']['input']>;
  show_rep_my_leads?: InputMaybe<Scalars['Boolean']['input']>;
  show_system_view?: InputMaybe<Scalars['Boolean']['input']>;
  sites?: InputMaybe<SiteCreateNestedManyWithoutOrganizationInput>;
  sms_opt_in_dialog?: InputMaybe<Scalars['String']['input']>;
  SMSItems?: InputMaybe<SmsItemCreateNestedManyWithoutOrganizationInput>;
  SnoozeTrackings?: InputMaybe<SnoozeTrackingCreateNestedManyWithoutOrganizationInput>;
  StepActionLogs?: InputMaybe<StepActionLogCreateNestedManyWithoutOrganizationInput>;
  StepActions?: InputMaybe<StepActionCreateNestedManyWithoutOrganizationInput>;
  StepConditions?: InputMaybe<StepConditionCreateNestedManyWithoutOrganizationInput>;
  teams?: InputMaybe<TeamCreateNestedManyWithoutOrganizationInput>;
  Template?: InputMaybe<TemplateCreateNestedManyWithoutOrganizationInput>;
  TemplateVersions?: InputMaybe<TemplateVersionCreateNestedManyWithoutOrganizationInput>;
  timekit_id?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  transfer_timeout?: InputMaybe<Scalars['Int']['input']>;
  TransferAttempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutOrganizationInput>;
  twilio_numbers?: InputMaybe<TwilioNumberCreateNestedManyWithoutOrganizationInput>;
  TwilioFeedbacks?: InputMaybe<TwilioFeedbackCreateNestedManyWithoutOrganizationInput>;
  TwilioInfo?: InputMaybe<TwilioInfoCreateNestedOneWithoutOrganzationInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  use_revenue_planning?: InputMaybe<Scalars['Boolean']['input']>;
  UserCustomFieldFilters?: InputMaybe<UserCustomFieldFilterCreateNestedManyWithoutOrganizationInput>;
  users?: InputMaybe<UserCreateNestedManyWithoutOrganizationInput>;
  win_rep_day_goal?: InputMaybe<Scalars['Float']['input']>;
};

export type OrganizationCreateWithoutLeadCustomObjectRowAssociationInput = {
  ActivityMonitorMultiplier?: InputMaybe<ActivityMonitorMultiplierCreateNestedManyWithoutOrganizationInput>;
  algoFactor?: InputMaybe<AlgoFactorCreateNestedManyWithoutOrganizationInput>;
  allow_calendar_invite?: InputMaybe<Scalars['Boolean']['input']>;
  allow_remove_step_action?: InputMaybe<Scalars['Boolean']['input']>;
  allow_snooze_step_action?: InputMaybe<Scalars['Boolean']['input']>;
  always_record?: InputMaybe<Scalars['Boolean']['input']>;
  arv_initial_held_demos_generated_by_sdr_weight?: InputMaybe<Scalars['Float']['input']>;
  AssociationHistories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutOrganizationInput>;
  auto_call_interval?: InputMaybe<Scalars['Int']['input']>;
  auto_dial?: InputMaybe<Scalars['Boolean']['input']>;
  automated_campaigns?: InputMaybe<AutomatedCampaignCreateNestedManyWithoutOrganizationInput>;
  automatic_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  AutoTransferSelections?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutOrganizationInput>;
  Availablities?: InputMaybe<AvailabilityCreateNestedManyWithoutOrganizationInput>;
  AverageOrderValue?: InputMaybe<AverageOrderValueCreateNestedManyWithoutOrganizationInput>;
  best_action_ever_multiplier?: InputMaybe<Scalars['Float']['input']>;
  best_metrics?: InputMaybe<BestMetricRecordCreateNestedManyWithoutOrganizationInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptCreateNestedManyWithoutOrganizationInput>;
  callMeNows?: InputMaybe<CallMeNowCreateNestedManyWithoutOrganizationInput>;
  CallMultiplier?: InputMaybe<CallMultiplierCreateNestedManyWithoutOrganizationInput>;
  CallSharings?: InputMaybe<CallSharingCreateNestedManyWithoutOrganizationInput>;
  can_edit_sdr_transfer_rules?: InputMaybe<Scalars['Boolean']['input']>;
  claim_rule?: InputMaybe<Scalars['Int']['input']>;
  concession?: InputMaybe<ConcessionCreateNestedManyWithoutOrganizationInput>;
  conference_region?: InputMaybe<ConferenceRegion>;
  Conferences?: InputMaybe<ConferenceCreateNestedManyWithoutOrganizationInput>;
  ContactRequests?: InputMaybe<ContactRequestCreateNestedManyWithoutOrganizationInput>;
  Contacts?: InputMaybe<ContactCreateNestedManyWithoutOrganizationInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  custom_fields?: InputMaybe<CustomFieldCreateNestedManyWithoutOrganizationInput>;
  CustomObject?: InputMaybe<CustomObjectCreateNestedManyWithoutOrganizationInput>;
  CustomObjectField?: InputMaybe<CustomObjectFieldCreateNestedManyWithoutOrganizationInput>;
  CustomObjectFieldForeignKey?: InputMaybe<CustomObjectFieldForeignKeyCreateNestedManyWithoutOrganizationInput>;
  CustomObjectRecord?: InputMaybe<CustomObjectRecordCreateNestedManyWithoutOrganizationInput>;
  CustomObjectRow?: InputMaybe<CustomObjectRowCreateNestedManyWithoutOrganizationInput>;
  CustomQueueItems?: InputMaybe<CustomQueueItemCreateNestedManyWithoutOrganizationInput>;
  DashboardColumnOptions?: InputMaybe<DashboardColumnOptionCreateNestedManyWithoutOrganizationInput>;
  DashboardLeadFilters?: InputMaybe<DashboardLeadFilterCreateNestedManyWithoutOrganizationInput>;
  DashboardRepFilters?: InputMaybe<DashboardRepFilterCreateNestedManyWithoutOrganizationInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewCreateNestedManyWithoutOrganizationInput>;
  day_to_close?: InputMaybe<Scalars['Int']['input']>;
  default_demo_time_duration?: InputMaybe<Scalars['Int']['input']>;
  demo_held_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_not_held_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_not_held_call_back_short_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_prep_time_minutes?: InputMaybe<Scalars['Int']['input']>;
  dev_account?: InputMaybe<Scalars['Boolean']['input']>;
  dial_rule?: InputMaybe<Scalars['Int']['input']>;
  DispositionMultiplier?: InputMaybe<DispositionMultiplierCreateNestedManyWithoutOrganizationInput>;
  dispositions?: InputMaybe<DispositionCreateNestedManyWithoutOrganizationInput>;
  double_booking?: InputMaybe<Scalars['Boolean']['input']>;
  dynamic_date_operators?: InputMaybe<Scalars['Boolean']['input']>;
  edge_servers?: InputMaybe<OrganizationCreateedge_ServersInput>;
  email_conflict_notification_email_list?: InputMaybe<OrganizationCreateemail_Conflict_Notification_Email_ListInput>;
  EmailItems?: InputMaybe<EmailItemCreateNestedManyWithoutOrganizationInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordCreateNestedManyWithoutOrganizationInput>;
  EmailThreads?: InputMaybe<EmailThreadCreateNestedManyWithoutOrganizationInput>;
  enable_modified_metrics?: InputMaybe<Scalars['Boolean']['input']>;
  enable_pay_period_view?: InputMaybe<Scalars['Boolean']['input']>;
  enable_time_constraint?: InputMaybe<Scalars['Boolean']['input']>;
  error_log_email_address?: InputMaybe<OrganizationCreateerror_Log_Email_AddressInput>;
  estimated_average_num_of_weekly_overbooked_demo_slots?: InputMaybe<Scalars['Int']['input']>;
  FolderPermissions?: InputMaybe<FolderPermissionCreateNestedManyWithoutOrganizationInput>;
  Folders?: InputMaybe<FolderCreateNestedManyWithoutOrganizationInput>;
  FutureBookingSetting?: InputMaybe<FutureBookingSettingCreateNestedOneWithoutOrganizationInput>;
  GlobalTransferRule?: InputMaybe<GlobalTransferRuleCreateNestedManyWithoutOrganizationInput>;
  held_demos_per_cc_connect?: InputMaybe<Scalars['Float']['input']>;
  helpful_links?: InputMaybe<OrganizationCreatehelpful_LinksInput>;
  HingePointMultiplier?: InputMaybe<HingePointMultiplierCreateNestedManyWithoutOrganizationInput>;
  hold_goal?: InputMaybe<Scalars['Float']['input']>;
  hubspot_credential?: InputMaybe<HubSpotCredentialCreateNestedOneWithoutOrganizationInput>;
  hubspot_last_sync_date?: InputMaybe<Scalars['DateTime']['input']>;
  hubspot_white_list?: InputMaybe<HubSpotWhitelistCreateNestedManyWithoutOrganizationInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  inbound_close_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_dm_connect_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_lt_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_revenue_value_of_sale?: InputMaybe<Scalars['Float']['input']>;
  inbound_scheduled_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_set_rate?: InputMaybe<Scalars['Float']['input']>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventCreateNestedManyWithoutOrganizationInput>;
  InboundConciergeWidget?: InputMaybe<InboundConciergeWidgetCreateNestedManyWithoutOrganizationInput>;
  industry_options?: InputMaybe<IndustryOptionCreateNestedManyWithoutOrganizationInput>;
  initial_sdr_sets_reps_day?: InputMaybe<Scalars['Float']['input']>;
  integration_error_logs?: InputMaybe<IntegrationErrorLogCreateNestedManyWithoutOrganizationInput>;
  integration_mappings?: InputMaybe<IntegrationMappingCreateNestedManyWithoutOrganizationInput>;
  IntegrationMappingCustomObject?: InputMaybe<IntegrationMappingCustomObjectCreateNestedManyWithoutOrganizationInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogCreateNestedManyWithoutOrganizationInput>;
  IntegrationReadOnlyObject?: InputMaybe<IntegrationReadOnlyObjectCreateNestedManyWithoutOrganizationInput>;
  international?: InputMaybe<Scalars['Boolean']['input']>;
  is_email_domain_verified?: InputMaybe<Scalars['Boolean']['input']>;
  is_salesforce_sandbox?: InputMaybe<Scalars['Boolean']['input']>;
  last_lead_value_compute_time?: InputMaybe<Scalars['DateTime']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutOrganizationInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryCreateNestedManyWithoutOrganizationInput>;
  lead_intent?: InputMaybe<LeadIntentCreateNestedManyWithoutOrganizationInput>;
  lead_release_same_rep_delay_rule?: InputMaybe<Scalars['Int']['input']>;
  lead_routing_email_list?: InputMaybe<OrganizationCreatelead_Routing_Email_ListInput>;
  lead_routing_rules?: InputMaybe<RuleCreateNestedManyWithoutOrganizationInput>;
  LeadFieldMultiplier?: InputMaybe<LeadFieldMultiplierCreateNestedManyWithoutOrganizationInput>;
  LeadFieldMultiplierCustom?: InputMaybe<LeadFieldMultiplierCustomCreateNestedManyWithoutOrganizationInput>;
  leads?: InputMaybe<LeadCreateNestedManyWithoutOrganizationInput>;
  leadsource_options?: InputMaybe<LeadSourceOptionCreateNestedManyWithoutOrganizationInput>;
  LeadValue?: InputMaybe<LeadValueCreateNestedManyWithoutOrganizationInput>;
  LifeTimeValueMultiplier?: InputMaybe<LifeTimeValueMultiplierCreateNestedManyWithoutOrganizationInput>;
  LiveTransferSetting?: InputMaybe<LiveTransferSettingCreateNestedOneWithoutOrganizationInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusCreateNestedManyWithoutOrganizationInput>;
  ltv_multiplier_custom_fields?: InputMaybe<LifeTimeValueMultiplierCustomCreateNestedManyWithoutOrganizationInput>;
  made_contact_rule?: InputMaybe<Scalars['Int']['input']>;
  make_sale_address_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_business_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_city_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_country_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_first_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_industry_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_last_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_lead_source_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_primary_email_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_primary_phone_number_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_state_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_sub_industry_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_zip_required?: InputMaybe<Scalars['Boolean']['input']>;
  maxium_day_lag_set_to_schedule?: InputMaybe<Scalars['Int']['input']>;
  mrr?: InputMaybe<Scalars['Int']['input']>;
  mrr_label?: InputMaybe<Scalars['String']['input']>;
  mrr_required_on_sale?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  NoteItems?: InputMaybe<NoteItemCreateNestedManyWithoutOrganizationInput>;
  Notifications?: InputMaybe<NotificationCreateNestedManyWithoutOrganizationInput>;
  onboardComplete?: InputMaybe<Scalars['Boolean']['input']>;
  Opportunity?: InputMaybe<SfOpportunityCreateNestedManyWithoutOrganizationInput>;
  organization_holidays?: InputMaybe<OrganizationHolidayCreateNestedManyWithoutOrganizationInput>;
  organization_hours?: InputMaybe<OrganizationHourCreateNestedManyWithoutOrganizationInput>;
  OrganizationPaymentSetting?: InputMaybe<OrganizationPaymentSettingsCreateNestedOneWithoutOrganizationInput>;
  OrganizationPaymentSettings?: InputMaybe<OrganizationPaymentSettingsCreateNestedOneWithoutOrganizationsInput>;
  orgCalendarInviteMetadata?: InputMaybe<OrgCalendarInviteMetadataCreateNestedManyWithoutOrganizationInput>;
  OrgDefaultPipelinePerecentValue?: InputMaybe<OrgDefaultPipelinePerecentValueCreateNestedOneWithoutOrganizationInput>;
  OrgHubSpotSync?: InputMaybe<OrgHubSpotSyncCreateNestedOneWithoutOrganizationInput>;
  OrgNoEventDate?: InputMaybe<OrgNoEventDateCreateNestedManyWithoutOrganizationInput>;
  OrgSalesforceSync?: InputMaybe<OrgSalesforceSyncCreateNestedOneWithoutOrganizationInput>;
  OrgTimeslotAvailability?: InputMaybe<OrgTimeslotAvailabilityCreateNestedManyWithoutOrganizationInput>;
  outbound_close_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_dm_connect_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_lt_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_revenue_value_of_sale?: InputMaybe<Scalars['Float']['input']>;
  outbound_scheduled_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_set_rate?: InputMaybe<Scalars['Float']['input']>;
  PandadocData?: InputMaybe<PandadocDataCreateNestedOneWithoutOrganizationInput>;
  PandaDocTemplate?: InputMaybe<PandaDocTemplateCreateNestedManyWithoutOrganizationInput>;
  participant_jitter_buffer_size?: InputMaybe<JitterBufferSize>;
  preferred_goal_setting?: InputMaybe<Goalsetting>;
  prepayment?: InputMaybe<Scalars['Boolean']['input']>;
  ProductChangeLog?: InputMaybe<ProductChangeLogCreateNestedManyWithoutOrganizationInput>;
  products?: InputMaybe<ProductCreateNestedManyWithoutOrganizationInput>;
  re_cold_call_hour_delay?: InputMaybe<Scalars['Float']['input']>;
  recordingStatus?: InputMaybe<RecordingStatus>;
  release_lead_resting?: InputMaybe<Scalars['Int']['input']>;
  rep_claim_limit?: InputMaybe<Scalars['Int']['input']>;
  rep_lead_allotment?: InputMaybe<Scalars['Int']['input']>;
  rep_level_call_transfer?: InputMaybe<Scalars['Boolean']['input']>;
  reps_can_bulk_unassign?: InputMaybe<Scalars['Boolean']['input']>;
  reps_can_view_all_notes?: InputMaybe<Scalars['Boolean']['input']>;
  require_sale_notes?: InputMaybe<Scalars['Boolean']['input']>;
  resting_after_skipped_CC?: InputMaybe<Scalars['Int']['input']>;
  resting_period?: InputMaybe<Scalars['Int']['input']>;
  resting_period_after_unsuccessful_cold_call?: InputMaybe<Scalars['Float']['input']>;
  resting_period_after_unsuccessful_demo?: InputMaybe<Scalars['Float']['input']>;
  revenue_plan?: InputMaybe<RevenuePlanItemCreateNestedManyWithoutOrganizationInput>;
  Routings?: InputMaybe<RoutingCreateNestedManyWithoutOrganizationInput>;
  sale_field_options?: InputMaybe<SaleFieldOptionCreateNestedManyWithoutOrganizationInput>;
  sale_notification_email_list?: InputMaybe<OrganizationCreatesale_Notification_Email_ListInput>;
  SaleConfig?: InputMaybe<SaleConfigCreateNestedOneWithoutOrganizationInput>;
  SaleConfigPage?: InputMaybe<SaleConfigPageCreateNestedManyWithoutOrganizationInput>;
  SaleConfigSection?: InputMaybe<SaleConfigSectionCreateNestedManyWithoutOrganizationInput>;
  SaleCycles?: InputMaybe<SaleCycleCreateNestedManyWithoutOrganizationInput>;
  SaleItems?: InputMaybe<SaleItemCreateNestedManyWithoutOrganizationInput>;
  sales?: InputMaybe<SaleCreateNestedManyWithoutOrganizationInput>;
  salesforce_domain?: InputMaybe<Scalars['String']['input']>;
  salesforce_last_sync_date?: InputMaybe<Scalars['DateTime']['input']>;
  SalesForceColumn?: InputMaybe<SalesForceColumnCreateNestedManyWithoutOrganizationInput>;
  salesForceData?: InputMaybe<SalesForceDataCreateNestedOneWithoutOrganizationInput>;
  ScheduleItems?: InputMaybe<ScheduleItemCreateNestedManyWithoutOrganizationInput>;
  self_sourcing_rule?: InputMaybe<Scalars['Int']['input']>;
  send_error_log_email?: InputMaybe<Scalars['Boolean']['input']>;
  send_lead_routing_email?: InputMaybe<Scalars['Boolean']['input']>;
  sendgrid_email_domain?: InputMaybe<Scalars['String']['input']>;
  sendgrid_email_domain_id?: InputMaybe<Scalars['Int']['input']>;
  sequence?: InputMaybe<SequenceCreateNestedManyWithoutOrganzationInput>;
  SequenceCriteriaConditions?: InputMaybe<SequenceCriteriaConditionCreateNestedManyWithoutOrganizationInput>;
  SequenceDashboardViews?: InputMaybe<SequenceDashboardViewCreateNestedManyWithoutOrganizationInput>;
  SequenceEntryCriterias?: InputMaybe<SequenceEntryCriteriaCreateNestedManyWithoutOrganizationInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutOrganizationInput>;
  SequenceSteps?: InputMaybe<SequenceStepCreateNestedManyWithoutOrganizationInput>;
  set_goal?: InputMaybe<Scalars['Float']['input']>;
  short_resting?: InputMaybe<Scalars['Int']['input']>;
  show_calendar_options?: InputMaybe<Scalars['Boolean']['input']>;
  show_contract_duration_sales?: InputMaybe<Scalars['Boolean']['input']>;
  show_lead_source_to_rep?: InputMaybe<Scalars['Boolean']['input']>;
  show_nocontact_button?: InputMaybe<Scalars['Boolean']['input']>;
  show_payment_terms_sales?: InputMaybe<Scalars['Boolean']['input']>;
  show_rep_all_leads?: InputMaybe<Scalars['Boolean']['input']>;
  show_rep_my_leads?: InputMaybe<Scalars['Boolean']['input']>;
  show_system_view?: InputMaybe<Scalars['Boolean']['input']>;
  sites?: InputMaybe<SiteCreateNestedManyWithoutOrganizationInput>;
  sms_opt_in_dialog?: InputMaybe<Scalars['String']['input']>;
  SMSItems?: InputMaybe<SmsItemCreateNestedManyWithoutOrganizationInput>;
  SnoozeTrackings?: InputMaybe<SnoozeTrackingCreateNestedManyWithoutOrganizationInput>;
  StepActionLogs?: InputMaybe<StepActionLogCreateNestedManyWithoutOrganizationInput>;
  StepActions?: InputMaybe<StepActionCreateNestedManyWithoutOrganizationInput>;
  StepConditions?: InputMaybe<StepConditionCreateNestedManyWithoutOrganizationInput>;
  teams?: InputMaybe<TeamCreateNestedManyWithoutOrganizationInput>;
  Template?: InputMaybe<TemplateCreateNestedManyWithoutOrganizationInput>;
  TemplateVersions?: InputMaybe<TemplateVersionCreateNestedManyWithoutOrganizationInput>;
  timekit_id?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  transfer_timeout?: InputMaybe<Scalars['Int']['input']>;
  TransferAttempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutOrganizationInput>;
  twilio_numbers?: InputMaybe<TwilioNumberCreateNestedManyWithoutOrganizationInput>;
  TwilioFeedbacks?: InputMaybe<TwilioFeedbackCreateNestedManyWithoutOrganizationInput>;
  TwilioInfo?: InputMaybe<TwilioInfoCreateNestedOneWithoutOrganzationInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  use_revenue_planning?: InputMaybe<Scalars['Boolean']['input']>;
  UserCustomFieldFilters?: InputMaybe<UserCustomFieldFilterCreateNestedManyWithoutOrganizationInput>;
  users?: InputMaybe<UserCreateNestedManyWithoutOrganizationInput>;
  win_rep_day_goal?: InputMaybe<Scalars['Float']['input']>;
};

export type OrganizationCreateWithoutLeadFieldMultiplierCustomInput = {
  ActivityMonitorMultiplier?: InputMaybe<ActivityMonitorMultiplierCreateNestedManyWithoutOrganizationInput>;
  algoFactor?: InputMaybe<AlgoFactorCreateNestedManyWithoutOrganizationInput>;
  allow_calendar_invite?: InputMaybe<Scalars['Boolean']['input']>;
  allow_remove_step_action?: InputMaybe<Scalars['Boolean']['input']>;
  allow_snooze_step_action?: InputMaybe<Scalars['Boolean']['input']>;
  always_record?: InputMaybe<Scalars['Boolean']['input']>;
  arv_initial_held_demos_generated_by_sdr_weight?: InputMaybe<Scalars['Float']['input']>;
  AssociationHistories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutOrganizationInput>;
  auto_call_interval?: InputMaybe<Scalars['Int']['input']>;
  auto_dial?: InputMaybe<Scalars['Boolean']['input']>;
  automated_campaigns?: InputMaybe<AutomatedCampaignCreateNestedManyWithoutOrganizationInput>;
  automatic_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  AutoTransferSelections?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutOrganizationInput>;
  Availablities?: InputMaybe<AvailabilityCreateNestedManyWithoutOrganizationInput>;
  AverageOrderValue?: InputMaybe<AverageOrderValueCreateNestedManyWithoutOrganizationInput>;
  best_action_ever_multiplier?: InputMaybe<Scalars['Float']['input']>;
  best_metrics?: InputMaybe<BestMetricRecordCreateNestedManyWithoutOrganizationInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptCreateNestedManyWithoutOrganizationInput>;
  callMeNows?: InputMaybe<CallMeNowCreateNestedManyWithoutOrganizationInput>;
  CallMultiplier?: InputMaybe<CallMultiplierCreateNestedManyWithoutOrganizationInput>;
  CallSharings?: InputMaybe<CallSharingCreateNestedManyWithoutOrganizationInput>;
  can_edit_sdr_transfer_rules?: InputMaybe<Scalars['Boolean']['input']>;
  claim_rule?: InputMaybe<Scalars['Int']['input']>;
  concession?: InputMaybe<ConcessionCreateNestedManyWithoutOrganizationInput>;
  conference_region?: InputMaybe<ConferenceRegion>;
  Conferences?: InputMaybe<ConferenceCreateNestedManyWithoutOrganizationInput>;
  ContactRequests?: InputMaybe<ContactRequestCreateNestedManyWithoutOrganizationInput>;
  Contacts?: InputMaybe<ContactCreateNestedManyWithoutOrganizationInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  custom_fields?: InputMaybe<CustomFieldCreateNestedManyWithoutOrganizationInput>;
  CustomObject?: InputMaybe<CustomObjectCreateNestedManyWithoutOrganizationInput>;
  CustomObjectField?: InputMaybe<CustomObjectFieldCreateNestedManyWithoutOrganizationInput>;
  CustomObjectFieldForeignKey?: InputMaybe<CustomObjectFieldForeignKeyCreateNestedManyWithoutOrganizationInput>;
  CustomObjectRecord?: InputMaybe<CustomObjectRecordCreateNestedManyWithoutOrganizationInput>;
  CustomObjectRow?: InputMaybe<CustomObjectRowCreateNestedManyWithoutOrganizationInput>;
  CustomQueueItems?: InputMaybe<CustomQueueItemCreateNestedManyWithoutOrganizationInput>;
  DashboardColumnOptions?: InputMaybe<DashboardColumnOptionCreateNestedManyWithoutOrganizationInput>;
  DashboardLeadFilters?: InputMaybe<DashboardLeadFilterCreateNestedManyWithoutOrganizationInput>;
  DashboardRepFilters?: InputMaybe<DashboardRepFilterCreateNestedManyWithoutOrganizationInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewCreateNestedManyWithoutOrganizationInput>;
  day_to_close?: InputMaybe<Scalars['Int']['input']>;
  default_demo_time_duration?: InputMaybe<Scalars['Int']['input']>;
  demo_held_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_not_held_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_not_held_call_back_short_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_prep_time_minutes?: InputMaybe<Scalars['Int']['input']>;
  dev_account?: InputMaybe<Scalars['Boolean']['input']>;
  dial_rule?: InputMaybe<Scalars['Int']['input']>;
  DispositionMultiplier?: InputMaybe<DispositionMultiplierCreateNestedManyWithoutOrganizationInput>;
  dispositions?: InputMaybe<DispositionCreateNestedManyWithoutOrganizationInput>;
  double_booking?: InputMaybe<Scalars['Boolean']['input']>;
  dynamic_date_operators?: InputMaybe<Scalars['Boolean']['input']>;
  edge_servers?: InputMaybe<OrganizationCreateedge_ServersInput>;
  email_conflict_notification_email_list?: InputMaybe<OrganizationCreateemail_Conflict_Notification_Email_ListInput>;
  EmailItems?: InputMaybe<EmailItemCreateNestedManyWithoutOrganizationInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordCreateNestedManyWithoutOrganizationInput>;
  EmailThreads?: InputMaybe<EmailThreadCreateNestedManyWithoutOrganizationInput>;
  enable_modified_metrics?: InputMaybe<Scalars['Boolean']['input']>;
  enable_pay_period_view?: InputMaybe<Scalars['Boolean']['input']>;
  enable_time_constraint?: InputMaybe<Scalars['Boolean']['input']>;
  error_log_email_address?: InputMaybe<OrganizationCreateerror_Log_Email_AddressInput>;
  estimated_average_num_of_weekly_overbooked_demo_slots?: InputMaybe<Scalars['Int']['input']>;
  FolderPermissions?: InputMaybe<FolderPermissionCreateNestedManyWithoutOrganizationInput>;
  Folders?: InputMaybe<FolderCreateNestedManyWithoutOrganizationInput>;
  FutureBookingSetting?: InputMaybe<FutureBookingSettingCreateNestedOneWithoutOrganizationInput>;
  GlobalTransferRule?: InputMaybe<GlobalTransferRuleCreateNestedManyWithoutOrganizationInput>;
  held_demos_per_cc_connect?: InputMaybe<Scalars['Float']['input']>;
  helpful_links?: InputMaybe<OrganizationCreatehelpful_LinksInput>;
  HingePointMultiplier?: InputMaybe<HingePointMultiplierCreateNestedManyWithoutOrganizationInput>;
  hold_goal?: InputMaybe<Scalars['Float']['input']>;
  hubspot_credential?: InputMaybe<HubSpotCredentialCreateNestedOneWithoutOrganizationInput>;
  hubspot_last_sync_date?: InputMaybe<Scalars['DateTime']['input']>;
  hubspot_white_list?: InputMaybe<HubSpotWhitelistCreateNestedManyWithoutOrganizationInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  inbound_close_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_dm_connect_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_lt_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_revenue_value_of_sale?: InputMaybe<Scalars['Float']['input']>;
  inbound_scheduled_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_set_rate?: InputMaybe<Scalars['Float']['input']>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventCreateNestedManyWithoutOrganizationInput>;
  InboundConciergeWidget?: InputMaybe<InboundConciergeWidgetCreateNestedManyWithoutOrganizationInput>;
  industry_options?: InputMaybe<IndustryOptionCreateNestedManyWithoutOrganizationInput>;
  initial_sdr_sets_reps_day?: InputMaybe<Scalars['Float']['input']>;
  integration_error_logs?: InputMaybe<IntegrationErrorLogCreateNestedManyWithoutOrganizationInput>;
  integration_mappings?: InputMaybe<IntegrationMappingCreateNestedManyWithoutOrganizationInput>;
  IntegrationMappingCustomObject?: InputMaybe<IntegrationMappingCustomObjectCreateNestedManyWithoutOrganizationInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogCreateNestedManyWithoutOrganizationInput>;
  IntegrationReadOnlyObject?: InputMaybe<IntegrationReadOnlyObjectCreateNestedManyWithoutOrganizationInput>;
  international?: InputMaybe<Scalars['Boolean']['input']>;
  is_email_domain_verified?: InputMaybe<Scalars['Boolean']['input']>;
  is_salesforce_sandbox?: InputMaybe<Scalars['Boolean']['input']>;
  last_lead_value_compute_time?: InputMaybe<Scalars['DateTime']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutOrganizationInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryCreateNestedManyWithoutOrganizationInput>;
  lead_intent?: InputMaybe<LeadIntentCreateNestedManyWithoutOrganizationInput>;
  lead_release_same_rep_delay_rule?: InputMaybe<Scalars['Int']['input']>;
  lead_routing_email_list?: InputMaybe<OrganizationCreatelead_Routing_Email_ListInput>;
  lead_routing_rules?: InputMaybe<RuleCreateNestedManyWithoutOrganizationInput>;
  LeadCustomObjectRowAssociation?: InputMaybe<LeadCustomObjectRowAssociationCreateNestedManyWithoutOrganizationInput>;
  LeadFieldMultiplier?: InputMaybe<LeadFieldMultiplierCreateNestedManyWithoutOrganizationInput>;
  leads?: InputMaybe<LeadCreateNestedManyWithoutOrganizationInput>;
  leadsource_options?: InputMaybe<LeadSourceOptionCreateNestedManyWithoutOrganizationInput>;
  LeadValue?: InputMaybe<LeadValueCreateNestedManyWithoutOrganizationInput>;
  LifeTimeValueMultiplier?: InputMaybe<LifeTimeValueMultiplierCreateNestedManyWithoutOrganizationInput>;
  LiveTransferSetting?: InputMaybe<LiveTransferSettingCreateNestedOneWithoutOrganizationInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusCreateNestedManyWithoutOrganizationInput>;
  ltv_multiplier_custom_fields?: InputMaybe<LifeTimeValueMultiplierCustomCreateNestedManyWithoutOrganizationInput>;
  made_contact_rule?: InputMaybe<Scalars['Int']['input']>;
  make_sale_address_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_business_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_city_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_country_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_first_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_industry_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_last_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_lead_source_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_primary_email_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_primary_phone_number_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_state_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_sub_industry_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_zip_required?: InputMaybe<Scalars['Boolean']['input']>;
  maxium_day_lag_set_to_schedule?: InputMaybe<Scalars['Int']['input']>;
  mrr?: InputMaybe<Scalars['Int']['input']>;
  mrr_label?: InputMaybe<Scalars['String']['input']>;
  mrr_required_on_sale?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  NoteItems?: InputMaybe<NoteItemCreateNestedManyWithoutOrganizationInput>;
  Notifications?: InputMaybe<NotificationCreateNestedManyWithoutOrganizationInput>;
  onboardComplete?: InputMaybe<Scalars['Boolean']['input']>;
  Opportunity?: InputMaybe<SfOpportunityCreateNestedManyWithoutOrganizationInput>;
  organization_holidays?: InputMaybe<OrganizationHolidayCreateNestedManyWithoutOrganizationInput>;
  organization_hours?: InputMaybe<OrganizationHourCreateNestedManyWithoutOrganizationInput>;
  OrganizationPaymentSetting?: InputMaybe<OrganizationPaymentSettingsCreateNestedOneWithoutOrganizationInput>;
  OrganizationPaymentSettings?: InputMaybe<OrganizationPaymentSettingsCreateNestedOneWithoutOrganizationsInput>;
  orgCalendarInviteMetadata?: InputMaybe<OrgCalendarInviteMetadataCreateNestedManyWithoutOrganizationInput>;
  OrgDefaultPipelinePerecentValue?: InputMaybe<OrgDefaultPipelinePerecentValueCreateNestedOneWithoutOrganizationInput>;
  OrgHubSpotSync?: InputMaybe<OrgHubSpotSyncCreateNestedOneWithoutOrganizationInput>;
  OrgNoEventDate?: InputMaybe<OrgNoEventDateCreateNestedManyWithoutOrganizationInput>;
  OrgSalesforceSync?: InputMaybe<OrgSalesforceSyncCreateNestedOneWithoutOrganizationInput>;
  OrgTimeslotAvailability?: InputMaybe<OrgTimeslotAvailabilityCreateNestedManyWithoutOrganizationInput>;
  outbound_close_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_dm_connect_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_lt_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_revenue_value_of_sale?: InputMaybe<Scalars['Float']['input']>;
  outbound_scheduled_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_set_rate?: InputMaybe<Scalars['Float']['input']>;
  PandadocData?: InputMaybe<PandadocDataCreateNestedOneWithoutOrganizationInput>;
  PandaDocTemplate?: InputMaybe<PandaDocTemplateCreateNestedManyWithoutOrganizationInput>;
  participant_jitter_buffer_size?: InputMaybe<JitterBufferSize>;
  preferred_goal_setting?: InputMaybe<Goalsetting>;
  prepayment?: InputMaybe<Scalars['Boolean']['input']>;
  ProductChangeLog?: InputMaybe<ProductChangeLogCreateNestedManyWithoutOrganizationInput>;
  products?: InputMaybe<ProductCreateNestedManyWithoutOrganizationInput>;
  re_cold_call_hour_delay?: InputMaybe<Scalars['Float']['input']>;
  recordingStatus?: InputMaybe<RecordingStatus>;
  release_lead_resting?: InputMaybe<Scalars['Int']['input']>;
  rep_claim_limit?: InputMaybe<Scalars['Int']['input']>;
  rep_lead_allotment?: InputMaybe<Scalars['Int']['input']>;
  rep_level_call_transfer?: InputMaybe<Scalars['Boolean']['input']>;
  reps_can_bulk_unassign?: InputMaybe<Scalars['Boolean']['input']>;
  reps_can_view_all_notes?: InputMaybe<Scalars['Boolean']['input']>;
  require_sale_notes?: InputMaybe<Scalars['Boolean']['input']>;
  resting_after_skipped_CC?: InputMaybe<Scalars['Int']['input']>;
  resting_period?: InputMaybe<Scalars['Int']['input']>;
  resting_period_after_unsuccessful_cold_call?: InputMaybe<Scalars['Float']['input']>;
  resting_period_after_unsuccessful_demo?: InputMaybe<Scalars['Float']['input']>;
  revenue_plan?: InputMaybe<RevenuePlanItemCreateNestedManyWithoutOrganizationInput>;
  Routings?: InputMaybe<RoutingCreateNestedManyWithoutOrganizationInput>;
  sale_field_options?: InputMaybe<SaleFieldOptionCreateNestedManyWithoutOrganizationInput>;
  sale_notification_email_list?: InputMaybe<OrganizationCreatesale_Notification_Email_ListInput>;
  SaleConfig?: InputMaybe<SaleConfigCreateNestedOneWithoutOrganizationInput>;
  SaleConfigPage?: InputMaybe<SaleConfigPageCreateNestedManyWithoutOrganizationInput>;
  SaleConfigSection?: InputMaybe<SaleConfigSectionCreateNestedManyWithoutOrganizationInput>;
  SaleCycles?: InputMaybe<SaleCycleCreateNestedManyWithoutOrganizationInput>;
  SaleItems?: InputMaybe<SaleItemCreateNestedManyWithoutOrganizationInput>;
  sales?: InputMaybe<SaleCreateNestedManyWithoutOrganizationInput>;
  salesforce_domain?: InputMaybe<Scalars['String']['input']>;
  salesforce_last_sync_date?: InputMaybe<Scalars['DateTime']['input']>;
  SalesForceColumn?: InputMaybe<SalesForceColumnCreateNestedManyWithoutOrganizationInput>;
  salesForceData?: InputMaybe<SalesForceDataCreateNestedOneWithoutOrganizationInput>;
  ScheduleItems?: InputMaybe<ScheduleItemCreateNestedManyWithoutOrganizationInput>;
  self_sourcing_rule?: InputMaybe<Scalars['Int']['input']>;
  send_error_log_email?: InputMaybe<Scalars['Boolean']['input']>;
  send_lead_routing_email?: InputMaybe<Scalars['Boolean']['input']>;
  sendgrid_email_domain?: InputMaybe<Scalars['String']['input']>;
  sendgrid_email_domain_id?: InputMaybe<Scalars['Int']['input']>;
  sequence?: InputMaybe<SequenceCreateNestedManyWithoutOrganzationInput>;
  SequenceCriteriaConditions?: InputMaybe<SequenceCriteriaConditionCreateNestedManyWithoutOrganizationInput>;
  SequenceDashboardViews?: InputMaybe<SequenceDashboardViewCreateNestedManyWithoutOrganizationInput>;
  SequenceEntryCriterias?: InputMaybe<SequenceEntryCriteriaCreateNestedManyWithoutOrganizationInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutOrganizationInput>;
  SequenceSteps?: InputMaybe<SequenceStepCreateNestedManyWithoutOrganizationInput>;
  set_goal?: InputMaybe<Scalars['Float']['input']>;
  short_resting?: InputMaybe<Scalars['Int']['input']>;
  show_calendar_options?: InputMaybe<Scalars['Boolean']['input']>;
  show_contract_duration_sales?: InputMaybe<Scalars['Boolean']['input']>;
  show_lead_source_to_rep?: InputMaybe<Scalars['Boolean']['input']>;
  show_nocontact_button?: InputMaybe<Scalars['Boolean']['input']>;
  show_payment_terms_sales?: InputMaybe<Scalars['Boolean']['input']>;
  show_rep_all_leads?: InputMaybe<Scalars['Boolean']['input']>;
  show_rep_my_leads?: InputMaybe<Scalars['Boolean']['input']>;
  show_system_view?: InputMaybe<Scalars['Boolean']['input']>;
  sites?: InputMaybe<SiteCreateNestedManyWithoutOrganizationInput>;
  sms_opt_in_dialog?: InputMaybe<Scalars['String']['input']>;
  SMSItems?: InputMaybe<SmsItemCreateNestedManyWithoutOrganizationInput>;
  SnoozeTrackings?: InputMaybe<SnoozeTrackingCreateNestedManyWithoutOrganizationInput>;
  StepActionLogs?: InputMaybe<StepActionLogCreateNestedManyWithoutOrganizationInput>;
  StepActions?: InputMaybe<StepActionCreateNestedManyWithoutOrganizationInput>;
  StepConditions?: InputMaybe<StepConditionCreateNestedManyWithoutOrganizationInput>;
  teams?: InputMaybe<TeamCreateNestedManyWithoutOrganizationInput>;
  Template?: InputMaybe<TemplateCreateNestedManyWithoutOrganizationInput>;
  TemplateVersions?: InputMaybe<TemplateVersionCreateNestedManyWithoutOrganizationInput>;
  timekit_id?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  transfer_timeout?: InputMaybe<Scalars['Int']['input']>;
  TransferAttempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutOrganizationInput>;
  twilio_numbers?: InputMaybe<TwilioNumberCreateNestedManyWithoutOrganizationInput>;
  TwilioFeedbacks?: InputMaybe<TwilioFeedbackCreateNestedManyWithoutOrganizationInput>;
  TwilioInfo?: InputMaybe<TwilioInfoCreateNestedOneWithoutOrganzationInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  use_revenue_planning?: InputMaybe<Scalars['Boolean']['input']>;
  UserCustomFieldFilters?: InputMaybe<UserCustomFieldFilterCreateNestedManyWithoutOrganizationInput>;
  users?: InputMaybe<UserCreateNestedManyWithoutOrganizationInput>;
  win_rep_day_goal?: InputMaybe<Scalars['Float']['input']>;
};

export type OrganizationCreateWithoutLeadFieldMultiplierInput = {
  ActivityMonitorMultiplier?: InputMaybe<ActivityMonitorMultiplierCreateNestedManyWithoutOrganizationInput>;
  algoFactor?: InputMaybe<AlgoFactorCreateNestedManyWithoutOrganizationInput>;
  allow_calendar_invite?: InputMaybe<Scalars['Boolean']['input']>;
  allow_remove_step_action?: InputMaybe<Scalars['Boolean']['input']>;
  allow_snooze_step_action?: InputMaybe<Scalars['Boolean']['input']>;
  always_record?: InputMaybe<Scalars['Boolean']['input']>;
  arv_initial_held_demos_generated_by_sdr_weight?: InputMaybe<Scalars['Float']['input']>;
  AssociationHistories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutOrganizationInput>;
  auto_call_interval?: InputMaybe<Scalars['Int']['input']>;
  auto_dial?: InputMaybe<Scalars['Boolean']['input']>;
  automated_campaigns?: InputMaybe<AutomatedCampaignCreateNestedManyWithoutOrganizationInput>;
  automatic_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  AutoTransferSelections?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutOrganizationInput>;
  Availablities?: InputMaybe<AvailabilityCreateNestedManyWithoutOrganizationInput>;
  AverageOrderValue?: InputMaybe<AverageOrderValueCreateNestedManyWithoutOrganizationInput>;
  best_action_ever_multiplier?: InputMaybe<Scalars['Float']['input']>;
  best_metrics?: InputMaybe<BestMetricRecordCreateNestedManyWithoutOrganizationInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptCreateNestedManyWithoutOrganizationInput>;
  callMeNows?: InputMaybe<CallMeNowCreateNestedManyWithoutOrganizationInput>;
  CallMultiplier?: InputMaybe<CallMultiplierCreateNestedManyWithoutOrganizationInput>;
  CallSharings?: InputMaybe<CallSharingCreateNestedManyWithoutOrganizationInput>;
  can_edit_sdr_transfer_rules?: InputMaybe<Scalars['Boolean']['input']>;
  claim_rule?: InputMaybe<Scalars['Int']['input']>;
  concession?: InputMaybe<ConcessionCreateNestedManyWithoutOrganizationInput>;
  conference_region?: InputMaybe<ConferenceRegion>;
  Conferences?: InputMaybe<ConferenceCreateNestedManyWithoutOrganizationInput>;
  ContactRequests?: InputMaybe<ContactRequestCreateNestedManyWithoutOrganizationInput>;
  Contacts?: InputMaybe<ContactCreateNestedManyWithoutOrganizationInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  custom_fields?: InputMaybe<CustomFieldCreateNestedManyWithoutOrganizationInput>;
  CustomObject?: InputMaybe<CustomObjectCreateNestedManyWithoutOrganizationInput>;
  CustomObjectField?: InputMaybe<CustomObjectFieldCreateNestedManyWithoutOrganizationInput>;
  CustomObjectFieldForeignKey?: InputMaybe<CustomObjectFieldForeignKeyCreateNestedManyWithoutOrganizationInput>;
  CustomObjectRecord?: InputMaybe<CustomObjectRecordCreateNestedManyWithoutOrganizationInput>;
  CustomObjectRow?: InputMaybe<CustomObjectRowCreateNestedManyWithoutOrganizationInput>;
  CustomQueueItems?: InputMaybe<CustomQueueItemCreateNestedManyWithoutOrganizationInput>;
  DashboardColumnOptions?: InputMaybe<DashboardColumnOptionCreateNestedManyWithoutOrganizationInput>;
  DashboardLeadFilters?: InputMaybe<DashboardLeadFilterCreateNestedManyWithoutOrganizationInput>;
  DashboardRepFilters?: InputMaybe<DashboardRepFilterCreateNestedManyWithoutOrganizationInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewCreateNestedManyWithoutOrganizationInput>;
  day_to_close?: InputMaybe<Scalars['Int']['input']>;
  default_demo_time_duration?: InputMaybe<Scalars['Int']['input']>;
  demo_held_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_not_held_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_not_held_call_back_short_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_prep_time_minutes?: InputMaybe<Scalars['Int']['input']>;
  dev_account?: InputMaybe<Scalars['Boolean']['input']>;
  dial_rule?: InputMaybe<Scalars['Int']['input']>;
  DispositionMultiplier?: InputMaybe<DispositionMultiplierCreateNestedManyWithoutOrganizationInput>;
  dispositions?: InputMaybe<DispositionCreateNestedManyWithoutOrganizationInput>;
  double_booking?: InputMaybe<Scalars['Boolean']['input']>;
  dynamic_date_operators?: InputMaybe<Scalars['Boolean']['input']>;
  edge_servers?: InputMaybe<OrganizationCreateedge_ServersInput>;
  email_conflict_notification_email_list?: InputMaybe<OrganizationCreateemail_Conflict_Notification_Email_ListInput>;
  EmailItems?: InputMaybe<EmailItemCreateNestedManyWithoutOrganizationInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordCreateNestedManyWithoutOrganizationInput>;
  EmailThreads?: InputMaybe<EmailThreadCreateNestedManyWithoutOrganizationInput>;
  enable_modified_metrics?: InputMaybe<Scalars['Boolean']['input']>;
  enable_pay_period_view?: InputMaybe<Scalars['Boolean']['input']>;
  enable_time_constraint?: InputMaybe<Scalars['Boolean']['input']>;
  error_log_email_address?: InputMaybe<OrganizationCreateerror_Log_Email_AddressInput>;
  estimated_average_num_of_weekly_overbooked_demo_slots?: InputMaybe<Scalars['Int']['input']>;
  FolderPermissions?: InputMaybe<FolderPermissionCreateNestedManyWithoutOrganizationInput>;
  Folders?: InputMaybe<FolderCreateNestedManyWithoutOrganizationInput>;
  FutureBookingSetting?: InputMaybe<FutureBookingSettingCreateNestedOneWithoutOrganizationInput>;
  GlobalTransferRule?: InputMaybe<GlobalTransferRuleCreateNestedManyWithoutOrganizationInput>;
  held_demos_per_cc_connect?: InputMaybe<Scalars['Float']['input']>;
  helpful_links?: InputMaybe<OrganizationCreatehelpful_LinksInput>;
  HingePointMultiplier?: InputMaybe<HingePointMultiplierCreateNestedManyWithoutOrganizationInput>;
  hold_goal?: InputMaybe<Scalars['Float']['input']>;
  hubspot_credential?: InputMaybe<HubSpotCredentialCreateNestedOneWithoutOrganizationInput>;
  hubspot_last_sync_date?: InputMaybe<Scalars['DateTime']['input']>;
  hubspot_white_list?: InputMaybe<HubSpotWhitelistCreateNestedManyWithoutOrganizationInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  inbound_close_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_dm_connect_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_lt_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_revenue_value_of_sale?: InputMaybe<Scalars['Float']['input']>;
  inbound_scheduled_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_set_rate?: InputMaybe<Scalars['Float']['input']>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventCreateNestedManyWithoutOrganizationInput>;
  InboundConciergeWidget?: InputMaybe<InboundConciergeWidgetCreateNestedManyWithoutOrganizationInput>;
  industry_options?: InputMaybe<IndustryOptionCreateNestedManyWithoutOrganizationInput>;
  initial_sdr_sets_reps_day?: InputMaybe<Scalars['Float']['input']>;
  integration_error_logs?: InputMaybe<IntegrationErrorLogCreateNestedManyWithoutOrganizationInput>;
  integration_mappings?: InputMaybe<IntegrationMappingCreateNestedManyWithoutOrganizationInput>;
  IntegrationMappingCustomObject?: InputMaybe<IntegrationMappingCustomObjectCreateNestedManyWithoutOrganizationInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogCreateNestedManyWithoutOrganizationInput>;
  IntegrationReadOnlyObject?: InputMaybe<IntegrationReadOnlyObjectCreateNestedManyWithoutOrganizationInput>;
  international?: InputMaybe<Scalars['Boolean']['input']>;
  is_email_domain_verified?: InputMaybe<Scalars['Boolean']['input']>;
  is_salesforce_sandbox?: InputMaybe<Scalars['Boolean']['input']>;
  last_lead_value_compute_time?: InputMaybe<Scalars['DateTime']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutOrganizationInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryCreateNestedManyWithoutOrganizationInput>;
  lead_intent?: InputMaybe<LeadIntentCreateNestedManyWithoutOrganizationInput>;
  lead_release_same_rep_delay_rule?: InputMaybe<Scalars['Int']['input']>;
  lead_routing_email_list?: InputMaybe<OrganizationCreatelead_Routing_Email_ListInput>;
  lead_routing_rules?: InputMaybe<RuleCreateNestedManyWithoutOrganizationInput>;
  LeadCustomObjectRowAssociation?: InputMaybe<LeadCustomObjectRowAssociationCreateNestedManyWithoutOrganizationInput>;
  LeadFieldMultiplierCustom?: InputMaybe<LeadFieldMultiplierCustomCreateNestedManyWithoutOrganizationInput>;
  leads?: InputMaybe<LeadCreateNestedManyWithoutOrganizationInput>;
  leadsource_options?: InputMaybe<LeadSourceOptionCreateNestedManyWithoutOrganizationInput>;
  LeadValue?: InputMaybe<LeadValueCreateNestedManyWithoutOrganizationInput>;
  LifeTimeValueMultiplier?: InputMaybe<LifeTimeValueMultiplierCreateNestedManyWithoutOrganizationInput>;
  LiveTransferSetting?: InputMaybe<LiveTransferSettingCreateNestedOneWithoutOrganizationInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusCreateNestedManyWithoutOrganizationInput>;
  ltv_multiplier_custom_fields?: InputMaybe<LifeTimeValueMultiplierCustomCreateNestedManyWithoutOrganizationInput>;
  made_contact_rule?: InputMaybe<Scalars['Int']['input']>;
  make_sale_address_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_business_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_city_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_country_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_first_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_industry_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_last_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_lead_source_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_primary_email_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_primary_phone_number_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_state_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_sub_industry_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_zip_required?: InputMaybe<Scalars['Boolean']['input']>;
  maxium_day_lag_set_to_schedule?: InputMaybe<Scalars['Int']['input']>;
  mrr?: InputMaybe<Scalars['Int']['input']>;
  mrr_label?: InputMaybe<Scalars['String']['input']>;
  mrr_required_on_sale?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  NoteItems?: InputMaybe<NoteItemCreateNestedManyWithoutOrganizationInput>;
  Notifications?: InputMaybe<NotificationCreateNestedManyWithoutOrganizationInput>;
  onboardComplete?: InputMaybe<Scalars['Boolean']['input']>;
  Opportunity?: InputMaybe<SfOpportunityCreateNestedManyWithoutOrganizationInput>;
  organization_holidays?: InputMaybe<OrganizationHolidayCreateNestedManyWithoutOrganizationInput>;
  organization_hours?: InputMaybe<OrganizationHourCreateNestedManyWithoutOrganizationInput>;
  OrganizationPaymentSetting?: InputMaybe<OrganizationPaymentSettingsCreateNestedOneWithoutOrganizationInput>;
  OrganizationPaymentSettings?: InputMaybe<OrganizationPaymentSettingsCreateNestedOneWithoutOrganizationsInput>;
  orgCalendarInviteMetadata?: InputMaybe<OrgCalendarInviteMetadataCreateNestedManyWithoutOrganizationInput>;
  OrgDefaultPipelinePerecentValue?: InputMaybe<OrgDefaultPipelinePerecentValueCreateNestedOneWithoutOrganizationInput>;
  OrgHubSpotSync?: InputMaybe<OrgHubSpotSyncCreateNestedOneWithoutOrganizationInput>;
  OrgNoEventDate?: InputMaybe<OrgNoEventDateCreateNestedManyWithoutOrganizationInput>;
  OrgSalesforceSync?: InputMaybe<OrgSalesforceSyncCreateNestedOneWithoutOrganizationInput>;
  OrgTimeslotAvailability?: InputMaybe<OrgTimeslotAvailabilityCreateNestedManyWithoutOrganizationInput>;
  outbound_close_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_dm_connect_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_lt_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_revenue_value_of_sale?: InputMaybe<Scalars['Float']['input']>;
  outbound_scheduled_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_set_rate?: InputMaybe<Scalars['Float']['input']>;
  PandadocData?: InputMaybe<PandadocDataCreateNestedOneWithoutOrganizationInput>;
  PandaDocTemplate?: InputMaybe<PandaDocTemplateCreateNestedManyWithoutOrganizationInput>;
  participant_jitter_buffer_size?: InputMaybe<JitterBufferSize>;
  preferred_goal_setting?: InputMaybe<Goalsetting>;
  prepayment?: InputMaybe<Scalars['Boolean']['input']>;
  ProductChangeLog?: InputMaybe<ProductChangeLogCreateNestedManyWithoutOrganizationInput>;
  products?: InputMaybe<ProductCreateNestedManyWithoutOrganizationInput>;
  re_cold_call_hour_delay?: InputMaybe<Scalars['Float']['input']>;
  recordingStatus?: InputMaybe<RecordingStatus>;
  release_lead_resting?: InputMaybe<Scalars['Int']['input']>;
  rep_claim_limit?: InputMaybe<Scalars['Int']['input']>;
  rep_lead_allotment?: InputMaybe<Scalars['Int']['input']>;
  rep_level_call_transfer?: InputMaybe<Scalars['Boolean']['input']>;
  reps_can_bulk_unassign?: InputMaybe<Scalars['Boolean']['input']>;
  reps_can_view_all_notes?: InputMaybe<Scalars['Boolean']['input']>;
  require_sale_notes?: InputMaybe<Scalars['Boolean']['input']>;
  resting_after_skipped_CC?: InputMaybe<Scalars['Int']['input']>;
  resting_period?: InputMaybe<Scalars['Int']['input']>;
  resting_period_after_unsuccessful_cold_call?: InputMaybe<Scalars['Float']['input']>;
  resting_period_after_unsuccessful_demo?: InputMaybe<Scalars['Float']['input']>;
  revenue_plan?: InputMaybe<RevenuePlanItemCreateNestedManyWithoutOrganizationInput>;
  Routings?: InputMaybe<RoutingCreateNestedManyWithoutOrganizationInput>;
  sale_field_options?: InputMaybe<SaleFieldOptionCreateNestedManyWithoutOrganizationInput>;
  sale_notification_email_list?: InputMaybe<OrganizationCreatesale_Notification_Email_ListInput>;
  SaleConfig?: InputMaybe<SaleConfigCreateNestedOneWithoutOrganizationInput>;
  SaleConfigPage?: InputMaybe<SaleConfigPageCreateNestedManyWithoutOrganizationInput>;
  SaleConfigSection?: InputMaybe<SaleConfigSectionCreateNestedManyWithoutOrganizationInput>;
  SaleCycles?: InputMaybe<SaleCycleCreateNestedManyWithoutOrganizationInput>;
  SaleItems?: InputMaybe<SaleItemCreateNestedManyWithoutOrganizationInput>;
  sales?: InputMaybe<SaleCreateNestedManyWithoutOrganizationInput>;
  salesforce_domain?: InputMaybe<Scalars['String']['input']>;
  salesforce_last_sync_date?: InputMaybe<Scalars['DateTime']['input']>;
  SalesForceColumn?: InputMaybe<SalesForceColumnCreateNestedManyWithoutOrganizationInput>;
  salesForceData?: InputMaybe<SalesForceDataCreateNestedOneWithoutOrganizationInput>;
  ScheduleItems?: InputMaybe<ScheduleItemCreateNestedManyWithoutOrganizationInput>;
  self_sourcing_rule?: InputMaybe<Scalars['Int']['input']>;
  send_error_log_email?: InputMaybe<Scalars['Boolean']['input']>;
  send_lead_routing_email?: InputMaybe<Scalars['Boolean']['input']>;
  sendgrid_email_domain?: InputMaybe<Scalars['String']['input']>;
  sendgrid_email_domain_id?: InputMaybe<Scalars['Int']['input']>;
  sequence?: InputMaybe<SequenceCreateNestedManyWithoutOrganzationInput>;
  SequenceCriteriaConditions?: InputMaybe<SequenceCriteriaConditionCreateNestedManyWithoutOrganizationInput>;
  SequenceDashboardViews?: InputMaybe<SequenceDashboardViewCreateNestedManyWithoutOrganizationInput>;
  SequenceEntryCriterias?: InputMaybe<SequenceEntryCriteriaCreateNestedManyWithoutOrganizationInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutOrganizationInput>;
  SequenceSteps?: InputMaybe<SequenceStepCreateNestedManyWithoutOrganizationInput>;
  set_goal?: InputMaybe<Scalars['Float']['input']>;
  short_resting?: InputMaybe<Scalars['Int']['input']>;
  show_calendar_options?: InputMaybe<Scalars['Boolean']['input']>;
  show_contract_duration_sales?: InputMaybe<Scalars['Boolean']['input']>;
  show_lead_source_to_rep?: InputMaybe<Scalars['Boolean']['input']>;
  show_nocontact_button?: InputMaybe<Scalars['Boolean']['input']>;
  show_payment_terms_sales?: InputMaybe<Scalars['Boolean']['input']>;
  show_rep_all_leads?: InputMaybe<Scalars['Boolean']['input']>;
  show_rep_my_leads?: InputMaybe<Scalars['Boolean']['input']>;
  show_system_view?: InputMaybe<Scalars['Boolean']['input']>;
  sites?: InputMaybe<SiteCreateNestedManyWithoutOrganizationInput>;
  sms_opt_in_dialog?: InputMaybe<Scalars['String']['input']>;
  SMSItems?: InputMaybe<SmsItemCreateNestedManyWithoutOrganizationInput>;
  SnoozeTrackings?: InputMaybe<SnoozeTrackingCreateNestedManyWithoutOrganizationInput>;
  StepActionLogs?: InputMaybe<StepActionLogCreateNestedManyWithoutOrganizationInput>;
  StepActions?: InputMaybe<StepActionCreateNestedManyWithoutOrganizationInput>;
  StepConditions?: InputMaybe<StepConditionCreateNestedManyWithoutOrganizationInput>;
  teams?: InputMaybe<TeamCreateNestedManyWithoutOrganizationInput>;
  Template?: InputMaybe<TemplateCreateNestedManyWithoutOrganizationInput>;
  TemplateVersions?: InputMaybe<TemplateVersionCreateNestedManyWithoutOrganizationInput>;
  timekit_id?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  transfer_timeout?: InputMaybe<Scalars['Int']['input']>;
  TransferAttempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutOrganizationInput>;
  twilio_numbers?: InputMaybe<TwilioNumberCreateNestedManyWithoutOrganizationInput>;
  TwilioFeedbacks?: InputMaybe<TwilioFeedbackCreateNestedManyWithoutOrganizationInput>;
  TwilioInfo?: InputMaybe<TwilioInfoCreateNestedOneWithoutOrganzationInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  use_revenue_planning?: InputMaybe<Scalars['Boolean']['input']>;
  UserCustomFieldFilters?: InputMaybe<UserCustomFieldFilterCreateNestedManyWithoutOrganizationInput>;
  users?: InputMaybe<UserCreateNestedManyWithoutOrganizationInput>;
  win_rep_day_goal?: InputMaybe<Scalars['Float']['input']>;
};

export type OrganizationCreateWithoutLeadsInput = {
  ActivityMonitorMultiplier?: InputMaybe<ActivityMonitorMultiplierCreateNestedManyWithoutOrganizationInput>;
  algoFactor?: InputMaybe<AlgoFactorCreateNestedManyWithoutOrganizationInput>;
  allow_calendar_invite?: InputMaybe<Scalars['Boolean']['input']>;
  allow_remove_step_action?: InputMaybe<Scalars['Boolean']['input']>;
  allow_snooze_step_action?: InputMaybe<Scalars['Boolean']['input']>;
  always_record?: InputMaybe<Scalars['Boolean']['input']>;
  arv_initial_held_demos_generated_by_sdr_weight?: InputMaybe<Scalars['Float']['input']>;
  AssociationHistories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutOrganizationInput>;
  auto_call_interval?: InputMaybe<Scalars['Int']['input']>;
  auto_dial?: InputMaybe<Scalars['Boolean']['input']>;
  automated_campaigns?: InputMaybe<AutomatedCampaignCreateNestedManyWithoutOrganizationInput>;
  automatic_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  AutoTransferSelections?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutOrganizationInput>;
  Availablities?: InputMaybe<AvailabilityCreateNestedManyWithoutOrganizationInput>;
  AverageOrderValue?: InputMaybe<AverageOrderValueCreateNestedManyWithoutOrganizationInput>;
  best_action_ever_multiplier?: InputMaybe<Scalars['Float']['input']>;
  best_metrics?: InputMaybe<BestMetricRecordCreateNestedManyWithoutOrganizationInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptCreateNestedManyWithoutOrganizationInput>;
  callMeNows?: InputMaybe<CallMeNowCreateNestedManyWithoutOrganizationInput>;
  CallMultiplier?: InputMaybe<CallMultiplierCreateNestedManyWithoutOrganizationInput>;
  CallSharings?: InputMaybe<CallSharingCreateNestedManyWithoutOrganizationInput>;
  can_edit_sdr_transfer_rules?: InputMaybe<Scalars['Boolean']['input']>;
  claim_rule?: InputMaybe<Scalars['Int']['input']>;
  concession?: InputMaybe<ConcessionCreateNestedManyWithoutOrganizationInput>;
  conference_region?: InputMaybe<ConferenceRegion>;
  Conferences?: InputMaybe<ConferenceCreateNestedManyWithoutOrganizationInput>;
  ContactRequests?: InputMaybe<ContactRequestCreateNestedManyWithoutOrganizationInput>;
  Contacts?: InputMaybe<ContactCreateNestedManyWithoutOrganizationInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  custom_fields?: InputMaybe<CustomFieldCreateNestedManyWithoutOrganizationInput>;
  CustomObject?: InputMaybe<CustomObjectCreateNestedManyWithoutOrganizationInput>;
  CustomObjectField?: InputMaybe<CustomObjectFieldCreateNestedManyWithoutOrganizationInput>;
  CustomObjectFieldForeignKey?: InputMaybe<CustomObjectFieldForeignKeyCreateNestedManyWithoutOrganizationInput>;
  CustomObjectRecord?: InputMaybe<CustomObjectRecordCreateNestedManyWithoutOrganizationInput>;
  CustomObjectRow?: InputMaybe<CustomObjectRowCreateNestedManyWithoutOrganizationInput>;
  CustomQueueItems?: InputMaybe<CustomQueueItemCreateNestedManyWithoutOrganizationInput>;
  DashboardColumnOptions?: InputMaybe<DashboardColumnOptionCreateNestedManyWithoutOrganizationInput>;
  DashboardLeadFilters?: InputMaybe<DashboardLeadFilterCreateNestedManyWithoutOrganizationInput>;
  DashboardRepFilters?: InputMaybe<DashboardRepFilterCreateNestedManyWithoutOrganizationInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewCreateNestedManyWithoutOrganizationInput>;
  day_to_close?: InputMaybe<Scalars['Int']['input']>;
  default_demo_time_duration?: InputMaybe<Scalars['Int']['input']>;
  demo_held_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_not_held_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_not_held_call_back_short_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_prep_time_minutes?: InputMaybe<Scalars['Int']['input']>;
  dev_account?: InputMaybe<Scalars['Boolean']['input']>;
  dial_rule?: InputMaybe<Scalars['Int']['input']>;
  DispositionMultiplier?: InputMaybe<DispositionMultiplierCreateNestedManyWithoutOrganizationInput>;
  dispositions?: InputMaybe<DispositionCreateNestedManyWithoutOrganizationInput>;
  double_booking?: InputMaybe<Scalars['Boolean']['input']>;
  dynamic_date_operators?: InputMaybe<Scalars['Boolean']['input']>;
  edge_servers?: InputMaybe<OrganizationCreateedge_ServersInput>;
  email_conflict_notification_email_list?: InputMaybe<OrganizationCreateemail_Conflict_Notification_Email_ListInput>;
  EmailItems?: InputMaybe<EmailItemCreateNestedManyWithoutOrganizationInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordCreateNestedManyWithoutOrganizationInput>;
  EmailThreads?: InputMaybe<EmailThreadCreateNestedManyWithoutOrganizationInput>;
  enable_modified_metrics?: InputMaybe<Scalars['Boolean']['input']>;
  enable_pay_period_view?: InputMaybe<Scalars['Boolean']['input']>;
  enable_time_constraint?: InputMaybe<Scalars['Boolean']['input']>;
  error_log_email_address?: InputMaybe<OrganizationCreateerror_Log_Email_AddressInput>;
  estimated_average_num_of_weekly_overbooked_demo_slots?: InputMaybe<Scalars['Int']['input']>;
  FolderPermissions?: InputMaybe<FolderPermissionCreateNestedManyWithoutOrganizationInput>;
  Folders?: InputMaybe<FolderCreateNestedManyWithoutOrganizationInput>;
  FutureBookingSetting?: InputMaybe<FutureBookingSettingCreateNestedOneWithoutOrganizationInput>;
  GlobalTransferRule?: InputMaybe<GlobalTransferRuleCreateNestedManyWithoutOrganizationInput>;
  held_demos_per_cc_connect?: InputMaybe<Scalars['Float']['input']>;
  helpful_links?: InputMaybe<OrganizationCreatehelpful_LinksInput>;
  HingePointMultiplier?: InputMaybe<HingePointMultiplierCreateNestedManyWithoutOrganizationInput>;
  hold_goal?: InputMaybe<Scalars['Float']['input']>;
  hubspot_credential?: InputMaybe<HubSpotCredentialCreateNestedOneWithoutOrganizationInput>;
  hubspot_last_sync_date?: InputMaybe<Scalars['DateTime']['input']>;
  hubspot_white_list?: InputMaybe<HubSpotWhitelistCreateNestedManyWithoutOrganizationInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  inbound_close_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_dm_connect_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_lt_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_revenue_value_of_sale?: InputMaybe<Scalars['Float']['input']>;
  inbound_scheduled_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_set_rate?: InputMaybe<Scalars['Float']['input']>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventCreateNestedManyWithoutOrganizationInput>;
  InboundConciergeWidget?: InputMaybe<InboundConciergeWidgetCreateNestedManyWithoutOrganizationInput>;
  industry_options?: InputMaybe<IndustryOptionCreateNestedManyWithoutOrganizationInput>;
  initial_sdr_sets_reps_day?: InputMaybe<Scalars['Float']['input']>;
  integration_error_logs?: InputMaybe<IntegrationErrorLogCreateNestedManyWithoutOrganizationInput>;
  integration_mappings?: InputMaybe<IntegrationMappingCreateNestedManyWithoutOrganizationInput>;
  IntegrationMappingCustomObject?: InputMaybe<IntegrationMappingCustomObjectCreateNestedManyWithoutOrganizationInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogCreateNestedManyWithoutOrganizationInput>;
  IntegrationReadOnlyObject?: InputMaybe<IntegrationReadOnlyObjectCreateNestedManyWithoutOrganizationInput>;
  international?: InputMaybe<Scalars['Boolean']['input']>;
  is_email_domain_verified?: InputMaybe<Scalars['Boolean']['input']>;
  is_salesforce_sandbox?: InputMaybe<Scalars['Boolean']['input']>;
  last_lead_value_compute_time?: InputMaybe<Scalars['DateTime']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutOrganizationInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryCreateNestedManyWithoutOrganizationInput>;
  lead_intent?: InputMaybe<LeadIntentCreateNestedManyWithoutOrganizationInput>;
  lead_release_same_rep_delay_rule?: InputMaybe<Scalars['Int']['input']>;
  lead_routing_email_list?: InputMaybe<OrganizationCreatelead_Routing_Email_ListInput>;
  lead_routing_rules?: InputMaybe<RuleCreateNestedManyWithoutOrganizationInput>;
  LeadCustomObjectRowAssociation?: InputMaybe<LeadCustomObjectRowAssociationCreateNestedManyWithoutOrganizationInput>;
  LeadFieldMultiplier?: InputMaybe<LeadFieldMultiplierCreateNestedManyWithoutOrganizationInput>;
  LeadFieldMultiplierCustom?: InputMaybe<LeadFieldMultiplierCustomCreateNestedManyWithoutOrganizationInput>;
  leadsource_options?: InputMaybe<LeadSourceOptionCreateNestedManyWithoutOrganizationInput>;
  LeadValue?: InputMaybe<LeadValueCreateNestedManyWithoutOrganizationInput>;
  LifeTimeValueMultiplier?: InputMaybe<LifeTimeValueMultiplierCreateNestedManyWithoutOrganizationInput>;
  LiveTransferSetting?: InputMaybe<LiveTransferSettingCreateNestedOneWithoutOrganizationInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusCreateNestedManyWithoutOrganizationInput>;
  ltv_multiplier_custom_fields?: InputMaybe<LifeTimeValueMultiplierCustomCreateNestedManyWithoutOrganizationInput>;
  made_contact_rule?: InputMaybe<Scalars['Int']['input']>;
  make_sale_address_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_business_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_city_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_country_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_first_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_industry_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_last_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_lead_source_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_primary_email_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_primary_phone_number_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_state_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_sub_industry_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_zip_required?: InputMaybe<Scalars['Boolean']['input']>;
  maxium_day_lag_set_to_schedule?: InputMaybe<Scalars['Int']['input']>;
  mrr?: InputMaybe<Scalars['Int']['input']>;
  mrr_label?: InputMaybe<Scalars['String']['input']>;
  mrr_required_on_sale?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  NoteItems?: InputMaybe<NoteItemCreateNestedManyWithoutOrganizationInput>;
  Notifications?: InputMaybe<NotificationCreateNestedManyWithoutOrganizationInput>;
  onboardComplete?: InputMaybe<Scalars['Boolean']['input']>;
  Opportunity?: InputMaybe<SfOpportunityCreateNestedManyWithoutOrganizationInput>;
  organization_holidays?: InputMaybe<OrganizationHolidayCreateNestedManyWithoutOrganizationInput>;
  organization_hours?: InputMaybe<OrganizationHourCreateNestedManyWithoutOrganizationInput>;
  OrganizationPaymentSetting?: InputMaybe<OrganizationPaymentSettingsCreateNestedOneWithoutOrganizationInput>;
  OrganizationPaymentSettings?: InputMaybe<OrganizationPaymentSettingsCreateNestedOneWithoutOrganizationsInput>;
  orgCalendarInviteMetadata?: InputMaybe<OrgCalendarInviteMetadataCreateNestedManyWithoutOrganizationInput>;
  OrgDefaultPipelinePerecentValue?: InputMaybe<OrgDefaultPipelinePerecentValueCreateNestedOneWithoutOrganizationInput>;
  OrgHubSpotSync?: InputMaybe<OrgHubSpotSyncCreateNestedOneWithoutOrganizationInput>;
  OrgNoEventDate?: InputMaybe<OrgNoEventDateCreateNestedManyWithoutOrganizationInput>;
  OrgSalesforceSync?: InputMaybe<OrgSalesforceSyncCreateNestedOneWithoutOrganizationInput>;
  OrgTimeslotAvailability?: InputMaybe<OrgTimeslotAvailabilityCreateNestedManyWithoutOrganizationInput>;
  outbound_close_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_dm_connect_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_lt_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_revenue_value_of_sale?: InputMaybe<Scalars['Float']['input']>;
  outbound_scheduled_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_set_rate?: InputMaybe<Scalars['Float']['input']>;
  PandadocData?: InputMaybe<PandadocDataCreateNestedOneWithoutOrganizationInput>;
  PandaDocTemplate?: InputMaybe<PandaDocTemplateCreateNestedManyWithoutOrganizationInput>;
  participant_jitter_buffer_size?: InputMaybe<JitterBufferSize>;
  preferred_goal_setting?: InputMaybe<Goalsetting>;
  prepayment?: InputMaybe<Scalars['Boolean']['input']>;
  ProductChangeLog?: InputMaybe<ProductChangeLogCreateNestedManyWithoutOrganizationInput>;
  products?: InputMaybe<ProductCreateNestedManyWithoutOrganizationInput>;
  re_cold_call_hour_delay?: InputMaybe<Scalars['Float']['input']>;
  recordingStatus?: InputMaybe<RecordingStatus>;
  release_lead_resting?: InputMaybe<Scalars['Int']['input']>;
  rep_claim_limit?: InputMaybe<Scalars['Int']['input']>;
  rep_lead_allotment?: InputMaybe<Scalars['Int']['input']>;
  rep_level_call_transfer?: InputMaybe<Scalars['Boolean']['input']>;
  reps_can_bulk_unassign?: InputMaybe<Scalars['Boolean']['input']>;
  reps_can_view_all_notes?: InputMaybe<Scalars['Boolean']['input']>;
  require_sale_notes?: InputMaybe<Scalars['Boolean']['input']>;
  resting_after_skipped_CC?: InputMaybe<Scalars['Int']['input']>;
  resting_period?: InputMaybe<Scalars['Int']['input']>;
  resting_period_after_unsuccessful_cold_call?: InputMaybe<Scalars['Float']['input']>;
  resting_period_after_unsuccessful_demo?: InputMaybe<Scalars['Float']['input']>;
  revenue_plan?: InputMaybe<RevenuePlanItemCreateNestedManyWithoutOrganizationInput>;
  Routings?: InputMaybe<RoutingCreateNestedManyWithoutOrganizationInput>;
  sale_field_options?: InputMaybe<SaleFieldOptionCreateNestedManyWithoutOrganizationInput>;
  sale_notification_email_list?: InputMaybe<OrganizationCreatesale_Notification_Email_ListInput>;
  SaleConfig?: InputMaybe<SaleConfigCreateNestedOneWithoutOrganizationInput>;
  SaleConfigPage?: InputMaybe<SaleConfigPageCreateNestedManyWithoutOrganizationInput>;
  SaleConfigSection?: InputMaybe<SaleConfigSectionCreateNestedManyWithoutOrganizationInput>;
  SaleCycles?: InputMaybe<SaleCycleCreateNestedManyWithoutOrganizationInput>;
  SaleItems?: InputMaybe<SaleItemCreateNestedManyWithoutOrganizationInput>;
  sales?: InputMaybe<SaleCreateNestedManyWithoutOrganizationInput>;
  salesforce_domain?: InputMaybe<Scalars['String']['input']>;
  salesforce_last_sync_date?: InputMaybe<Scalars['DateTime']['input']>;
  SalesForceColumn?: InputMaybe<SalesForceColumnCreateNestedManyWithoutOrganizationInput>;
  salesForceData?: InputMaybe<SalesForceDataCreateNestedOneWithoutOrganizationInput>;
  ScheduleItems?: InputMaybe<ScheduleItemCreateNestedManyWithoutOrganizationInput>;
  self_sourcing_rule?: InputMaybe<Scalars['Int']['input']>;
  send_error_log_email?: InputMaybe<Scalars['Boolean']['input']>;
  send_lead_routing_email?: InputMaybe<Scalars['Boolean']['input']>;
  sendgrid_email_domain?: InputMaybe<Scalars['String']['input']>;
  sendgrid_email_domain_id?: InputMaybe<Scalars['Int']['input']>;
  sequence?: InputMaybe<SequenceCreateNestedManyWithoutOrganzationInput>;
  SequenceCriteriaConditions?: InputMaybe<SequenceCriteriaConditionCreateNestedManyWithoutOrganizationInput>;
  SequenceDashboardViews?: InputMaybe<SequenceDashboardViewCreateNestedManyWithoutOrganizationInput>;
  SequenceEntryCriterias?: InputMaybe<SequenceEntryCriteriaCreateNestedManyWithoutOrganizationInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutOrganizationInput>;
  SequenceSteps?: InputMaybe<SequenceStepCreateNestedManyWithoutOrganizationInput>;
  set_goal?: InputMaybe<Scalars['Float']['input']>;
  short_resting?: InputMaybe<Scalars['Int']['input']>;
  show_calendar_options?: InputMaybe<Scalars['Boolean']['input']>;
  show_contract_duration_sales?: InputMaybe<Scalars['Boolean']['input']>;
  show_lead_source_to_rep?: InputMaybe<Scalars['Boolean']['input']>;
  show_nocontact_button?: InputMaybe<Scalars['Boolean']['input']>;
  show_payment_terms_sales?: InputMaybe<Scalars['Boolean']['input']>;
  show_rep_all_leads?: InputMaybe<Scalars['Boolean']['input']>;
  show_rep_my_leads?: InputMaybe<Scalars['Boolean']['input']>;
  show_system_view?: InputMaybe<Scalars['Boolean']['input']>;
  sites?: InputMaybe<SiteCreateNestedManyWithoutOrganizationInput>;
  sms_opt_in_dialog?: InputMaybe<Scalars['String']['input']>;
  SMSItems?: InputMaybe<SmsItemCreateNestedManyWithoutOrganizationInput>;
  SnoozeTrackings?: InputMaybe<SnoozeTrackingCreateNestedManyWithoutOrganizationInput>;
  StepActionLogs?: InputMaybe<StepActionLogCreateNestedManyWithoutOrganizationInput>;
  StepActions?: InputMaybe<StepActionCreateNestedManyWithoutOrganizationInput>;
  StepConditions?: InputMaybe<StepConditionCreateNestedManyWithoutOrganizationInput>;
  teams?: InputMaybe<TeamCreateNestedManyWithoutOrganizationInput>;
  Template?: InputMaybe<TemplateCreateNestedManyWithoutOrganizationInput>;
  TemplateVersions?: InputMaybe<TemplateVersionCreateNestedManyWithoutOrganizationInput>;
  timekit_id?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  transfer_timeout?: InputMaybe<Scalars['Int']['input']>;
  TransferAttempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutOrganizationInput>;
  twilio_numbers?: InputMaybe<TwilioNumberCreateNestedManyWithoutOrganizationInput>;
  TwilioFeedbacks?: InputMaybe<TwilioFeedbackCreateNestedManyWithoutOrganizationInput>;
  TwilioInfo?: InputMaybe<TwilioInfoCreateNestedOneWithoutOrganzationInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  use_revenue_planning?: InputMaybe<Scalars['Boolean']['input']>;
  UserCustomFieldFilters?: InputMaybe<UserCustomFieldFilterCreateNestedManyWithoutOrganizationInput>;
  users?: InputMaybe<UserCreateNestedManyWithoutOrganizationInput>;
  win_rep_day_goal?: InputMaybe<Scalars['Float']['input']>;
};

export type OrganizationCreateWithoutLeadValueInput = {
  ActivityMonitorMultiplier?: InputMaybe<ActivityMonitorMultiplierCreateNestedManyWithoutOrganizationInput>;
  algoFactor?: InputMaybe<AlgoFactorCreateNestedManyWithoutOrganizationInput>;
  allow_calendar_invite?: InputMaybe<Scalars['Boolean']['input']>;
  allow_remove_step_action?: InputMaybe<Scalars['Boolean']['input']>;
  allow_snooze_step_action?: InputMaybe<Scalars['Boolean']['input']>;
  always_record?: InputMaybe<Scalars['Boolean']['input']>;
  arv_initial_held_demos_generated_by_sdr_weight?: InputMaybe<Scalars['Float']['input']>;
  AssociationHistories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutOrganizationInput>;
  auto_call_interval?: InputMaybe<Scalars['Int']['input']>;
  auto_dial?: InputMaybe<Scalars['Boolean']['input']>;
  automated_campaigns?: InputMaybe<AutomatedCampaignCreateNestedManyWithoutOrganizationInput>;
  automatic_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  AutoTransferSelections?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutOrganizationInput>;
  Availablities?: InputMaybe<AvailabilityCreateNestedManyWithoutOrganizationInput>;
  AverageOrderValue?: InputMaybe<AverageOrderValueCreateNestedManyWithoutOrganizationInput>;
  best_action_ever_multiplier?: InputMaybe<Scalars['Float']['input']>;
  best_metrics?: InputMaybe<BestMetricRecordCreateNestedManyWithoutOrganizationInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptCreateNestedManyWithoutOrganizationInput>;
  callMeNows?: InputMaybe<CallMeNowCreateNestedManyWithoutOrganizationInput>;
  CallMultiplier?: InputMaybe<CallMultiplierCreateNestedManyWithoutOrganizationInput>;
  CallSharings?: InputMaybe<CallSharingCreateNestedManyWithoutOrganizationInput>;
  can_edit_sdr_transfer_rules?: InputMaybe<Scalars['Boolean']['input']>;
  claim_rule?: InputMaybe<Scalars['Int']['input']>;
  concession?: InputMaybe<ConcessionCreateNestedManyWithoutOrganizationInput>;
  conference_region?: InputMaybe<ConferenceRegion>;
  Conferences?: InputMaybe<ConferenceCreateNestedManyWithoutOrganizationInput>;
  ContactRequests?: InputMaybe<ContactRequestCreateNestedManyWithoutOrganizationInput>;
  Contacts?: InputMaybe<ContactCreateNestedManyWithoutOrganizationInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  custom_fields?: InputMaybe<CustomFieldCreateNestedManyWithoutOrganizationInput>;
  CustomObject?: InputMaybe<CustomObjectCreateNestedManyWithoutOrganizationInput>;
  CustomObjectField?: InputMaybe<CustomObjectFieldCreateNestedManyWithoutOrganizationInput>;
  CustomObjectFieldForeignKey?: InputMaybe<CustomObjectFieldForeignKeyCreateNestedManyWithoutOrganizationInput>;
  CustomObjectRecord?: InputMaybe<CustomObjectRecordCreateNestedManyWithoutOrganizationInput>;
  CustomObjectRow?: InputMaybe<CustomObjectRowCreateNestedManyWithoutOrganizationInput>;
  CustomQueueItems?: InputMaybe<CustomQueueItemCreateNestedManyWithoutOrganizationInput>;
  DashboardColumnOptions?: InputMaybe<DashboardColumnOptionCreateNestedManyWithoutOrganizationInput>;
  DashboardLeadFilters?: InputMaybe<DashboardLeadFilterCreateNestedManyWithoutOrganizationInput>;
  DashboardRepFilters?: InputMaybe<DashboardRepFilterCreateNestedManyWithoutOrganizationInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewCreateNestedManyWithoutOrganizationInput>;
  day_to_close?: InputMaybe<Scalars['Int']['input']>;
  default_demo_time_duration?: InputMaybe<Scalars['Int']['input']>;
  demo_held_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_not_held_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_not_held_call_back_short_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_prep_time_minutes?: InputMaybe<Scalars['Int']['input']>;
  dev_account?: InputMaybe<Scalars['Boolean']['input']>;
  dial_rule?: InputMaybe<Scalars['Int']['input']>;
  DispositionMultiplier?: InputMaybe<DispositionMultiplierCreateNestedManyWithoutOrganizationInput>;
  dispositions?: InputMaybe<DispositionCreateNestedManyWithoutOrganizationInput>;
  double_booking?: InputMaybe<Scalars['Boolean']['input']>;
  dynamic_date_operators?: InputMaybe<Scalars['Boolean']['input']>;
  edge_servers?: InputMaybe<OrganizationCreateedge_ServersInput>;
  email_conflict_notification_email_list?: InputMaybe<OrganizationCreateemail_Conflict_Notification_Email_ListInput>;
  EmailItems?: InputMaybe<EmailItemCreateNestedManyWithoutOrganizationInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordCreateNestedManyWithoutOrganizationInput>;
  EmailThreads?: InputMaybe<EmailThreadCreateNestedManyWithoutOrganizationInput>;
  enable_modified_metrics?: InputMaybe<Scalars['Boolean']['input']>;
  enable_pay_period_view?: InputMaybe<Scalars['Boolean']['input']>;
  enable_time_constraint?: InputMaybe<Scalars['Boolean']['input']>;
  error_log_email_address?: InputMaybe<OrganizationCreateerror_Log_Email_AddressInput>;
  estimated_average_num_of_weekly_overbooked_demo_slots?: InputMaybe<Scalars['Int']['input']>;
  FolderPermissions?: InputMaybe<FolderPermissionCreateNestedManyWithoutOrganizationInput>;
  Folders?: InputMaybe<FolderCreateNestedManyWithoutOrganizationInput>;
  FutureBookingSetting?: InputMaybe<FutureBookingSettingCreateNestedOneWithoutOrganizationInput>;
  GlobalTransferRule?: InputMaybe<GlobalTransferRuleCreateNestedManyWithoutOrganizationInput>;
  held_demos_per_cc_connect?: InputMaybe<Scalars['Float']['input']>;
  helpful_links?: InputMaybe<OrganizationCreatehelpful_LinksInput>;
  HingePointMultiplier?: InputMaybe<HingePointMultiplierCreateNestedManyWithoutOrganizationInput>;
  hold_goal?: InputMaybe<Scalars['Float']['input']>;
  hubspot_credential?: InputMaybe<HubSpotCredentialCreateNestedOneWithoutOrganizationInput>;
  hubspot_last_sync_date?: InputMaybe<Scalars['DateTime']['input']>;
  hubspot_white_list?: InputMaybe<HubSpotWhitelistCreateNestedManyWithoutOrganizationInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  inbound_close_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_dm_connect_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_lt_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_revenue_value_of_sale?: InputMaybe<Scalars['Float']['input']>;
  inbound_scheduled_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_set_rate?: InputMaybe<Scalars['Float']['input']>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventCreateNestedManyWithoutOrganizationInput>;
  InboundConciergeWidget?: InputMaybe<InboundConciergeWidgetCreateNestedManyWithoutOrganizationInput>;
  industry_options?: InputMaybe<IndustryOptionCreateNestedManyWithoutOrganizationInput>;
  initial_sdr_sets_reps_day?: InputMaybe<Scalars['Float']['input']>;
  integration_error_logs?: InputMaybe<IntegrationErrorLogCreateNestedManyWithoutOrganizationInput>;
  integration_mappings?: InputMaybe<IntegrationMappingCreateNestedManyWithoutOrganizationInput>;
  IntegrationMappingCustomObject?: InputMaybe<IntegrationMappingCustomObjectCreateNestedManyWithoutOrganizationInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogCreateNestedManyWithoutOrganizationInput>;
  IntegrationReadOnlyObject?: InputMaybe<IntegrationReadOnlyObjectCreateNestedManyWithoutOrganizationInput>;
  international?: InputMaybe<Scalars['Boolean']['input']>;
  is_email_domain_verified?: InputMaybe<Scalars['Boolean']['input']>;
  is_salesforce_sandbox?: InputMaybe<Scalars['Boolean']['input']>;
  last_lead_value_compute_time?: InputMaybe<Scalars['DateTime']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutOrganizationInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryCreateNestedManyWithoutOrganizationInput>;
  lead_intent?: InputMaybe<LeadIntentCreateNestedManyWithoutOrganizationInput>;
  lead_release_same_rep_delay_rule?: InputMaybe<Scalars['Int']['input']>;
  lead_routing_email_list?: InputMaybe<OrganizationCreatelead_Routing_Email_ListInput>;
  lead_routing_rules?: InputMaybe<RuleCreateNestedManyWithoutOrganizationInput>;
  LeadCustomObjectRowAssociation?: InputMaybe<LeadCustomObjectRowAssociationCreateNestedManyWithoutOrganizationInput>;
  LeadFieldMultiplier?: InputMaybe<LeadFieldMultiplierCreateNestedManyWithoutOrganizationInput>;
  LeadFieldMultiplierCustom?: InputMaybe<LeadFieldMultiplierCustomCreateNestedManyWithoutOrganizationInput>;
  leads?: InputMaybe<LeadCreateNestedManyWithoutOrganizationInput>;
  leadsource_options?: InputMaybe<LeadSourceOptionCreateNestedManyWithoutOrganizationInput>;
  LifeTimeValueMultiplier?: InputMaybe<LifeTimeValueMultiplierCreateNestedManyWithoutOrganizationInput>;
  LiveTransferSetting?: InputMaybe<LiveTransferSettingCreateNestedOneWithoutOrganizationInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusCreateNestedManyWithoutOrganizationInput>;
  ltv_multiplier_custom_fields?: InputMaybe<LifeTimeValueMultiplierCustomCreateNestedManyWithoutOrganizationInput>;
  made_contact_rule?: InputMaybe<Scalars['Int']['input']>;
  make_sale_address_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_business_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_city_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_country_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_first_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_industry_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_last_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_lead_source_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_primary_email_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_primary_phone_number_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_state_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_sub_industry_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_zip_required?: InputMaybe<Scalars['Boolean']['input']>;
  maxium_day_lag_set_to_schedule?: InputMaybe<Scalars['Int']['input']>;
  mrr?: InputMaybe<Scalars['Int']['input']>;
  mrr_label?: InputMaybe<Scalars['String']['input']>;
  mrr_required_on_sale?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  NoteItems?: InputMaybe<NoteItemCreateNestedManyWithoutOrganizationInput>;
  Notifications?: InputMaybe<NotificationCreateNestedManyWithoutOrganizationInput>;
  onboardComplete?: InputMaybe<Scalars['Boolean']['input']>;
  Opportunity?: InputMaybe<SfOpportunityCreateNestedManyWithoutOrganizationInput>;
  organization_holidays?: InputMaybe<OrganizationHolidayCreateNestedManyWithoutOrganizationInput>;
  organization_hours?: InputMaybe<OrganizationHourCreateNestedManyWithoutOrganizationInput>;
  OrganizationPaymentSetting?: InputMaybe<OrganizationPaymentSettingsCreateNestedOneWithoutOrganizationInput>;
  OrganizationPaymentSettings?: InputMaybe<OrganizationPaymentSettingsCreateNestedOneWithoutOrganizationsInput>;
  orgCalendarInviteMetadata?: InputMaybe<OrgCalendarInviteMetadataCreateNestedManyWithoutOrganizationInput>;
  OrgDefaultPipelinePerecentValue?: InputMaybe<OrgDefaultPipelinePerecentValueCreateNestedOneWithoutOrganizationInput>;
  OrgHubSpotSync?: InputMaybe<OrgHubSpotSyncCreateNestedOneWithoutOrganizationInput>;
  OrgNoEventDate?: InputMaybe<OrgNoEventDateCreateNestedManyWithoutOrganizationInput>;
  OrgSalesforceSync?: InputMaybe<OrgSalesforceSyncCreateNestedOneWithoutOrganizationInput>;
  OrgTimeslotAvailability?: InputMaybe<OrgTimeslotAvailabilityCreateNestedManyWithoutOrganizationInput>;
  outbound_close_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_dm_connect_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_lt_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_revenue_value_of_sale?: InputMaybe<Scalars['Float']['input']>;
  outbound_scheduled_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_set_rate?: InputMaybe<Scalars['Float']['input']>;
  PandadocData?: InputMaybe<PandadocDataCreateNestedOneWithoutOrganizationInput>;
  PandaDocTemplate?: InputMaybe<PandaDocTemplateCreateNestedManyWithoutOrganizationInput>;
  participant_jitter_buffer_size?: InputMaybe<JitterBufferSize>;
  preferred_goal_setting?: InputMaybe<Goalsetting>;
  prepayment?: InputMaybe<Scalars['Boolean']['input']>;
  ProductChangeLog?: InputMaybe<ProductChangeLogCreateNestedManyWithoutOrganizationInput>;
  products?: InputMaybe<ProductCreateNestedManyWithoutOrganizationInput>;
  re_cold_call_hour_delay?: InputMaybe<Scalars['Float']['input']>;
  recordingStatus?: InputMaybe<RecordingStatus>;
  release_lead_resting?: InputMaybe<Scalars['Int']['input']>;
  rep_claim_limit?: InputMaybe<Scalars['Int']['input']>;
  rep_lead_allotment?: InputMaybe<Scalars['Int']['input']>;
  rep_level_call_transfer?: InputMaybe<Scalars['Boolean']['input']>;
  reps_can_bulk_unassign?: InputMaybe<Scalars['Boolean']['input']>;
  reps_can_view_all_notes?: InputMaybe<Scalars['Boolean']['input']>;
  require_sale_notes?: InputMaybe<Scalars['Boolean']['input']>;
  resting_after_skipped_CC?: InputMaybe<Scalars['Int']['input']>;
  resting_period?: InputMaybe<Scalars['Int']['input']>;
  resting_period_after_unsuccessful_cold_call?: InputMaybe<Scalars['Float']['input']>;
  resting_period_after_unsuccessful_demo?: InputMaybe<Scalars['Float']['input']>;
  revenue_plan?: InputMaybe<RevenuePlanItemCreateNestedManyWithoutOrganizationInput>;
  Routings?: InputMaybe<RoutingCreateNestedManyWithoutOrganizationInput>;
  sale_field_options?: InputMaybe<SaleFieldOptionCreateNestedManyWithoutOrganizationInput>;
  sale_notification_email_list?: InputMaybe<OrganizationCreatesale_Notification_Email_ListInput>;
  SaleConfig?: InputMaybe<SaleConfigCreateNestedOneWithoutOrganizationInput>;
  SaleConfigPage?: InputMaybe<SaleConfigPageCreateNestedManyWithoutOrganizationInput>;
  SaleConfigSection?: InputMaybe<SaleConfigSectionCreateNestedManyWithoutOrganizationInput>;
  SaleCycles?: InputMaybe<SaleCycleCreateNestedManyWithoutOrganizationInput>;
  SaleItems?: InputMaybe<SaleItemCreateNestedManyWithoutOrganizationInput>;
  sales?: InputMaybe<SaleCreateNestedManyWithoutOrganizationInput>;
  salesforce_domain?: InputMaybe<Scalars['String']['input']>;
  salesforce_last_sync_date?: InputMaybe<Scalars['DateTime']['input']>;
  SalesForceColumn?: InputMaybe<SalesForceColumnCreateNestedManyWithoutOrganizationInput>;
  salesForceData?: InputMaybe<SalesForceDataCreateNestedOneWithoutOrganizationInput>;
  ScheduleItems?: InputMaybe<ScheduleItemCreateNestedManyWithoutOrganizationInput>;
  self_sourcing_rule?: InputMaybe<Scalars['Int']['input']>;
  send_error_log_email?: InputMaybe<Scalars['Boolean']['input']>;
  send_lead_routing_email?: InputMaybe<Scalars['Boolean']['input']>;
  sendgrid_email_domain?: InputMaybe<Scalars['String']['input']>;
  sendgrid_email_domain_id?: InputMaybe<Scalars['Int']['input']>;
  sequence?: InputMaybe<SequenceCreateNestedManyWithoutOrganzationInput>;
  SequenceCriteriaConditions?: InputMaybe<SequenceCriteriaConditionCreateNestedManyWithoutOrganizationInput>;
  SequenceDashboardViews?: InputMaybe<SequenceDashboardViewCreateNestedManyWithoutOrganizationInput>;
  SequenceEntryCriterias?: InputMaybe<SequenceEntryCriteriaCreateNestedManyWithoutOrganizationInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutOrganizationInput>;
  SequenceSteps?: InputMaybe<SequenceStepCreateNestedManyWithoutOrganizationInput>;
  set_goal?: InputMaybe<Scalars['Float']['input']>;
  short_resting?: InputMaybe<Scalars['Int']['input']>;
  show_calendar_options?: InputMaybe<Scalars['Boolean']['input']>;
  show_contract_duration_sales?: InputMaybe<Scalars['Boolean']['input']>;
  show_lead_source_to_rep?: InputMaybe<Scalars['Boolean']['input']>;
  show_nocontact_button?: InputMaybe<Scalars['Boolean']['input']>;
  show_payment_terms_sales?: InputMaybe<Scalars['Boolean']['input']>;
  show_rep_all_leads?: InputMaybe<Scalars['Boolean']['input']>;
  show_rep_my_leads?: InputMaybe<Scalars['Boolean']['input']>;
  show_system_view?: InputMaybe<Scalars['Boolean']['input']>;
  sites?: InputMaybe<SiteCreateNestedManyWithoutOrganizationInput>;
  sms_opt_in_dialog?: InputMaybe<Scalars['String']['input']>;
  SMSItems?: InputMaybe<SmsItemCreateNestedManyWithoutOrganizationInput>;
  SnoozeTrackings?: InputMaybe<SnoozeTrackingCreateNestedManyWithoutOrganizationInput>;
  StepActionLogs?: InputMaybe<StepActionLogCreateNestedManyWithoutOrganizationInput>;
  StepActions?: InputMaybe<StepActionCreateNestedManyWithoutOrganizationInput>;
  StepConditions?: InputMaybe<StepConditionCreateNestedManyWithoutOrganizationInput>;
  teams?: InputMaybe<TeamCreateNestedManyWithoutOrganizationInput>;
  Template?: InputMaybe<TemplateCreateNestedManyWithoutOrganizationInput>;
  TemplateVersions?: InputMaybe<TemplateVersionCreateNestedManyWithoutOrganizationInput>;
  timekit_id?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  transfer_timeout?: InputMaybe<Scalars['Int']['input']>;
  TransferAttempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutOrganizationInput>;
  twilio_numbers?: InputMaybe<TwilioNumberCreateNestedManyWithoutOrganizationInput>;
  TwilioFeedbacks?: InputMaybe<TwilioFeedbackCreateNestedManyWithoutOrganizationInput>;
  TwilioInfo?: InputMaybe<TwilioInfoCreateNestedOneWithoutOrganzationInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  use_revenue_planning?: InputMaybe<Scalars['Boolean']['input']>;
  UserCustomFieldFilters?: InputMaybe<UserCustomFieldFilterCreateNestedManyWithoutOrganizationInput>;
  users?: InputMaybe<UserCreateNestedManyWithoutOrganizationInput>;
  win_rep_day_goal?: InputMaybe<Scalars['Float']['input']>;
};

export type OrganizationCreateWithoutLiveUserStatusInput = {
  ActivityMonitorMultiplier?: InputMaybe<ActivityMonitorMultiplierCreateNestedManyWithoutOrganizationInput>;
  algoFactor?: InputMaybe<AlgoFactorCreateNestedManyWithoutOrganizationInput>;
  allow_calendar_invite?: InputMaybe<Scalars['Boolean']['input']>;
  allow_remove_step_action?: InputMaybe<Scalars['Boolean']['input']>;
  allow_snooze_step_action?: InputMaybe<Scalars['Boolean']['input']>;
  always_record?: InputMaybe<Scalars['Boolean']['input']>;
  arv_initial_held_demos_generated_by_sdr_weight?: InputMaybe<Scalars['Float']['input']>;
  AssociationHistories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutOrganizationInput>;
  auto_call_interval?: InputMaybe<Scalars['Int']['input']>;
  auto_dial?: InputMaybe<Scalars['Boolean']['input']>;
  automated_campaigns?: InputMaybe<AutomatedCampaignCreateNestedManyWithoutOrganizationInput>;
  automatic_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  AutoTransferSelections?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutOrganizationInput>;
  Availablities?: InputMaybe<AvailabilityCreateNestedManyWithoutOrganizationInput>;
  AverageOrderValue?: InputMaybe<AverageOrderValueCreateNestedManyWithoutOrganizationInput>;
  best_action_ever_multiplier?: InputMaybe<Scalars['Float']['input']>;
  best_metrics?: InputMaybe<BestMetricRecordCreateNestedManyWithoutOrganizationInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptCreateNestedManyWithoutOrganizationInput>;
  callMeNows?: InputMaybe<CallMeNowCreateNestedManyWithoutOrganizationInput>;
  CallMultiplier?: InputMaybe<CallMultiplierCreateNestedManyWithoutOrganizationInput>;
  CallSharings?: InputMaybe<CallSharingCreateNestedManyWithoutOrganizationInput>;
  can_edit_sdr_transfer_rules?: InputMaybe<Scalars['Boolean']['input']>;
  claim_rule?: InputMaybe<Scalars['Int']['input']>;
  concession?: InputMaybe<ConcessionCreateNestedManyWithoutOrganizationInput>;
  conference_region?: InputMaybe<ConferenceRegion>;
  Conferences?: InputMaybe<ConferenceCreateNestedManyWithoutOrganizationInput>;
  ContactRequests?: InputMaybe<ContactRequestCreateNestedManyWithoutOrganizationInput>;
  Contacts?: InputMaybe<ContactCreateNestedManyWithoutOrganizationInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  custom_fields?: InputMaybe<CustomFieldCreateNestedManyWithoutOrganizationInput>;
  CustomObject?: InputMaybe<CustomObjectCreateNestedManyWithoutOrganizationInput>;
  CustomObjectField?: InputMaybe<CustomObjectFieldCreateNestedManyWithoutOrganizationInput>;
  CustomObjectFieldForeignKey?: InputMaybe<CustomObjectFieldForeignKeyCreateNestedManyWithoutOrganizationInput>;
  CustomObjectRecord?: InputMaybe<CustomObjectRecordCreateNestedManyWithoutOrganizationInput>;
  CustomObjectRow?: InputMaybe<CustomObjectRowCreateNestedManyWithoutOrganizationInput>;
  CustomQueueItems?: InputMaybe<CustomQueueItemCreateNestedManyWithoutOrganizationInput>;
  DashboardColumnOptions?: InputMaybe<DashboardColumnOptionCreateNestedManyWithoutOrganizationInput>;
  DashboardLeadFilters?: InputMaybe<DashboardLeadFilterCreateNestedManyWithoutOrganizationInput>;
  DashboardRepFilters?: InputMaybe<DashboardRepFilterCreateNestedManyWithoutOrganizationInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewCreateNestedManyWithoutOrganizationInput>;
  day_to_close?: InputMaybe<Scalars['Int']['input']>;
  default_demo_time_duration?: InputMaybe<Scalars['Int']['input']>;
  demo_held_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_not_held_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_not_held_call_back_short_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_prep_time_minutes?: InputMaybe<Scalars['Int']['input']>;
  dev_account?: InputMaybe<Scalars['Boolean']['input']>;
  dial_rule?: InputMaybe<Scalars['Int']['input']>;
  DispositionMultiplier?: InputMaybe<DispositionMultiplierCreateNestedManyWithoutOrganizationInput>;
  dispositions?: InputMaybe<DispositionCreateNestedManyWithoutOrganizationInput>;
  double_booking?: InputMaybe<Scalars['Boolean']['input']>;
  dynamic_date_operators?: InputMaybe<Scalars['Boolean']['input']>;
  edge_servers?: InputMaybe<OrganizationCreateedge_ServersInput>;
  email_conflict_notification_email_list?: InputMaybe<OrganizationCreateemail_Conflict_Notification_Email_ListInput>;
  EmailItems?: InputMaybe<EmailItemCreateNestedManyWithoutOrganizationInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordCreateNestedManyWithoutOrganizationInput>;
  EmailThreads?: InputMaybe<EmailThreadCreateNestedManyWithoutOrganizationInput>;
  enable_modified_metrics?: InputMaybe<Scalars['Boolean']['input']>;
  enable_pay_period_view?: InputMaybe<Scalars['Boolean']['input']>;
  enable_time_constraint?: InputMaybe<Scalars['Boolean']['input']>;
  error_log_email_address?: InputMaybe<OrganizationCreateerror_Log_Email_AddressInput>;
  estimated_average_num_of_weekly_overbooked_demo_slots?: InputMaybe<Scalars['Int']['input']>;
  FolderPermissions?: InputMaybe<FolderPermissionCreateNestedManyWithoutOrganizationInput>;
  Folders?: InputMaybe<FolderCreateNestedManyWithoutOrganizationInput>;
  FutureBookingSetting?: InputMaybe<FutureBookingSettingCreateNestedOneWithoutOrganizationInput>;
  GlobalTransferRule?: InputMaybe<GlobalTransferRuleCreateNestedManyWithoutOrganizationInput>;
  held_demos_per_cc_connect?: InputMaybe<Scalars['Float']['input']>;
  helpful_links?: InputMaybe<OrganizationCreatehelpful_LinksInput>;
  HingePointMultiplier?: InputMaybe<HingePointMultiplierCreateNestedManyWithoutOrganizationInput>;
  hold_goal?: InputMaybe<Scalars['Float']['input']>;
  hubspot_credential?: InputMaybe<HubSpotCredentialCreateNestedOneWithoutOrganizationInput>;
  hubspot_last_sync_date?: InputMaybe<Scalars['DateTime']['input']>;
  hubspot_white_list?: InputMaybe<HubSpotWhitelistCreateNestedManyWithoutOrganizationInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  inbound_close_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_dm_connect_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_lt_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_revenue_value_of_sale?: InputMaybe<Scalars['Float']['input']>;
  inbound_scheduled_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_set_rate?: InputMaybe<Scalars['Float']['input']>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventCreateNestedManyWithoutOrganizationInput>;
  InboundConciergeWidget?: InputMaybe<InboundConciergeWidgetCreateNestedManyWithoutOrganizationInput>;
  industry_options?: InputMaybe<IndustryOptionCreateNestedManyWithoutOrganizationInput>;
  initial_sdr_sets_reps_day?: InputMaybe<Scalars['Float']['input']>;
  integration_error_logs?: InputMaybe<IntegrationErrorLogCreateNestedManyWithoutOrganizationInput>;
  integration_mappings?: InputMaybe<IntegrationMappingCreateNestedManyWithoutOrganizationInput>;
  IntegrationMappingCustomObject?: InputMaybe<IntegrationMappingCustomObjectCreateNestedManyWithoutOrganizationInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogCreateNestedManyWithoutOrganizationInput>;
  IntegrationReadOnlyObject?: InputMaybe<IntegrationReadOnlyObjectCreateNestedManyWithoutOrganizationInput>;
  international?: InputMaybe<Scalars['Boolean']['input']>;
  is_email_domain_verified?: InputMaybe<Scalars['Boolean']['input']>;
  is_salesforce_sandbox?: InputMaybe<Scalars['Boolean']['input']>;
  last_lead_value_compute_time?: InputMaybe<Scalars['DateTime']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutOrganizationInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryCreateNestedManyWithoutOrganizationInput>;
  lead_intent?: InputMaybe<LeadIntentCreateNestedManyWithoutOrganizationInput>;
  lead_release_same_rep_delay_rule?: InputMaybe<Scalars['Int']['input']>;
  lead_routing_email_list?: InputMaybe<OrganizationCreatelead_Routing_Email_ListInput>;
  lead_routing_rules?: InputMaybe<RuleCreateNestedManyWithoutOrganizationInput>;
  LeadCustomObjectRowAssociation?: InputMaybe<LeadCustomObjectRowAssociationCreateNestedManyWithoutOrganizationInput>;
  LeadFieldMultiplier?: InputMaybe<LeadFieldMultiplierCreateNestedManyWithoutOrganizationInput>;
  LeadFieldMultiplierCustom?: InputMaybe<LeadFieldMultiplierCustomCreateNestedManyWithoutOrganizationInput>;
  leads?: InputMaybe<LeadCreateNestedManyWithoutOrganizationInput>;
  leadsource_options?: InputMaybe<LeadSourceOptionCreateNestedManyWithoutOrganizationInput>;
  LeadValue?: InputMaybe<LeadValueCreateNestedManyWithoutOrganizationInput>;
  LifeTimeValueMultiplier?: InputMaybe<LifeTimeValueMultiplierCreateNestedManyWithoutOrganizationInput>;
  LiveTransferSetting?: InputMaybe<LiveTransferSettingCreateNestedOneWithoutOrganizationInput>;
  ltv_multiplier_custom_fields?: InputMaybe<LifeTimeValueMultiplierCustomCreateNestedManyWithoutOrganizationInput>;
  made_contact_rule?: InputMaybe<Scalars['Int']['input']>;
  make_sale_address_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_business_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_city_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_country_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_first_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_industry_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_last_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_lead_source_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_primary_email_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_primary_phone_number_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_state_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_sub_industry_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_zip_required?: InputMaybe<Scalars['Boolean']['input']>;
  maxium_day_lag_set_to_schedule?: InputMaybe<Scalars['Int']['input']>;
  mrr?: InputMaybe<Scalars['Int']['input']>;
  mrr_label?: InputMaybe<Scalars['String']['input']>;
  mrr_required_on_sale?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  NoteItems?: InputMaybe<NoteItemCreateNestedManyWithoutOrganizationInput>;
  Notifications?: InputMaybe<NotificationCreateNestedManyWithoutOrganizationInput>;
  onboardComplete?: InputMaybe<Scalars['Boolean']['input']>;
  Opportunity?: InputMaybe<SfOpportunityCreateNestedManyWithoutOrganizationInput>;
  organization_holidays?: InputMaybe<OrganizationHolidayCreateNestedManyWithoutOrganizationInput>;
  organization_hours?: InputMaybe<OrganizationHourCreateNestedManyWithoutOrganizationInput>;
  OrganizationPaymentSetting?: InputMaybe<OrganizationPaymentSettingsCreateNestedOneWithoutOrganizationInput>;
  OrganizationPaymentSettings?: InputMaybe<OrganizationPaymentSettingsCreateNestedOneWithoutOrganizationsInput>;
  orgCalendarInviteMetadata?: InputMaybe<OrgCalendarInviteMetadataCreateNestedManyWithoutOrganizationInput>;
  OrgDefaultPipelinePerecentValue?: InputMaybe<OrgDefaultPipelinePerecentValueCreateNestedOneWithoutOrganizationInput>;
  OrgHubSpotSync?: InputMaybe<OrgHubSpotSyncCreateNestedOneWithoutOrganizationInput>;
  OrgNoEventDate?: InputMaybe<OrgNoEventDateCreateNestedManyWithoutOrganizationInput>;
  OrgSalesforceSync?: InputMaybe<OrgSalesforceSyncCreateNestedOneWithoutOrganizationInput>;
  OrgTimeslotAvailability?: InputMaybe<OrgTimeslotAvailabilityCreateNestedManyWithoutOrganizationInput>;
  outbound_close_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_dm_connect_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_lt_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_revenue_value_of_sale?: InputMaybe<Scalars['Float']['input']>;
  outbound_scheduled_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_set_rate?: InputMaybe<Scalars['Float']['input']>;
  PandadocData?: InputMaybe<PandadocDataCreateNestedOneWithoutOrganizationInput>;
  PandaDocTemplate?: InputMaybe<PandaDocTemplateCreateNestedManyWithoutOrganizationInput>;
  participant_jitter_buffer_size?: InputMaybe<JitterBufferSize>;
  preferred_goal_setting?: InputMaybe<Goalsetting>;
  prepayment?: InputMaybe<Scalars['Boolean']['input']>;
  ProductChangeLog?: InputMaybe<ProductChangeLogCreateNestedManyWithoutOrganizationInput>;
  products?: InputMaybe<ProductCreateNestedManyWithoutOrganizationInput>;
  re_cold_call_hour_delay?: InputMaybe<Scalars['Float']['input']>;
  recordingStatus?: InputMaybe<RecordingStatus>;
  release_lead_resting?: InputMaybe<Scalars['Int']['input']>;
  rep_claim_limit?: InputMaybe<Scalars['Int']['input']>;
  rep_lead_allotment?: InputMaybe<Scalars['Int']['input']>;
  rep_level_call_transfer?: InputMaybe<Scalars['Boolean']['input']>;
  reps_can_bulk_unassign?: InputMaybe<Scalars['Boolean']['input']>;
  reps_can_view_all_notes?: InputMaybe<Scalars['Boolean']['input']>;
  require_sale_notes?: InputMaybe<Scalars['Boolean']['input']>;
  resting_after_skipped_CC?: InputMaybe<Scalars['Int']['input']>;
  resting_period?: InputMaybe<Scalars['Int']['input']>;
  resting_period_after_unsuccessful_cold_call?: InputMaybe<Scalars['Float']['input']>;
  resting_period_after_unsuccessful_demo?: InputMaybe<Scalars['Float']['input']>;
  revenue_plan?: InputMaybe<RevenuePlanItemCreateNestedManyWithoutOrganizationInput>;
  Routings?: InputMaybe<RoutingCreateNestedManyWithoutOrganizationInput>;
  sale_field_options?: InputMaybe<SaleFieldOptionCreateNestedManyWithoutOrganizationInput>;
  sale_notification_email_list?: InputMaybe<OrganizationCreatesale_Notification_Email_ListInput>;
  SaleConfig?: InputMaybe<SaleConfigCreateNestedOneWithoutOrganizationInput>;
  SaleConfigPage?: InputMaybe<SaleConfigPageCreateNestedManyWithoutOrganizationInput>;
  SaleConfigSection?: InputMaybe<SaleConfigSectionCreateNestedManyWithoutOrganizationInput>;
  SaleCycles?: InputMaybe<SaleCycleCreateNestedManyWithoutOrganizationInput>;
  SaleItems?: InputMaybe<SaleItemCreateNestedManyWithoutOrganizationInput>;
  sales?: InputMaybe<SaleCreateNestedManyWithoutOrganizationInput>;
  salesforce_domain?: InputMaybe<Scalars['String']['input']>;
  salesforce_last_sync_date?: InputMaybe<Scalars['DateTime']['input']>;
  SalesForceColumn?: InputMaybe<SalesForceColumnCreateNestedManyWithoutOrganizationInput>;
  salesForceData?: InputMaybe<SalesForceDataCreateNestedOneWithoutOrganizationInput>;
  ScheduleItems?: InputMaybe<ScheduleItemCreateNestedManyWithoutOrganizationInput>;
  self_sourcing_rule?: InputMaybe<Scalars['Int']['input']>;
  send_error_log_email?: InputMaybe<Scalars['Boolean']['input']>;
  send_lead_routing_email?: InputMaybe<Scalars['Boolean']['input']>;
  sendgrid_email_domain?: InputMaybe<Scalars['String']['input']>;
  sendgrid_email_domain_id?: InputMaybe<Scalars['Int']['input']>;
  sequence?: InputMaybe<SequenceCreateNestedManyWithoutOrganzationInput>;
  SequenceCriteriaConditions?: InputMaybe<SequenceCriteriaConditionCreateNestedManyWithoutOrganizationInput>;
  SequenceDashboardViews?: InputMaybe<SequenceDashboardViewCreateNestedManyWithoutOrganizationInput>;
  SequenceEntryCriterias?: InputMaybe<SequenceEntryCriteriaCreateNestedManyWithoutOrganizationInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutOrganizationInput>;
  SequenceSteps?: InputMaybe<SequenceStepCreateNestedManyWithoutOrganizationInput>;
  set_goal?: InputMaybe<Scalars['Float']['input']>;
  short_resting?: InputMaybe<Scalars['Int']['input']>;
  show_calendar_options?: InputMaybe<Scalars['Boolean']['input']>;
  show_contract_duration_sales?: InputMaybe<Scalars['Boolean']['input']>;
  show_lead_source_to_rep?: InputMaybe<Scalars['Boolean']['input']>;
  show_nocontact_button?: InputMaybe<Scalars['Boolean']['input']>;
  show_payment_terms_sales?: InputMaybe<Scalars['Boolean']['input']>;
  show_rep_all_leads?: InputMaybe<Scalars['Boolean']['input']>;
  show_rep_my_leads?: InputMaybe<Scalars['Boolean']['input']>;
  show_system_view?: InputMaybe<Scalars['Boolean']['input']>;
  sites?: InputMaybe<SiteCreateNestedManyWithoutOrganizationInput>;
  sms_opt_in_dialog?: InputMaybe<Scalars['String']['input']>;
  SMSItems?: InputMaybe<SmsItemCreateNestedManyWithoutOrganizationInput>;
  SnoozeTrackings?: InputMaybe<SnoozeTrackingCreateNestedManyWithoutOrganizationInput>;
  StepActionLogs?: InputMaybe<StepActionLogCreateNestedManyWithoutOrganizationInput>;
  StepActions?: InputMaybe<StepActionCreateNestedManyWithoutOrganizationInput>;
  StepConditions?: InputMaybe<StepConditionCreateNestedManyWithoutOrganizationInput>;
  teams?: InputMaybe<TeamCreateNestedManyWithoutOrganizationInput>;
  Template?: InputMaybe<TemplateCreateNestedManyWithoutOrganizationInput>;
  TemplateVersions?: InputMaybe<TemplateVersionCreateNestedManyWithoutOrganizationInput>;
  timekit_id?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  transfer_timeout?: InputMaybe<Scalars['Int']['input']>;
  TransferAttempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutOrganizationInput>;
  twilio_numbers?: InputMaybe<TwilioNumberCreateNestedManyWithoutOrganizationInput>;
  TwilioFeedbacks?: InputMaybe<TwilioFeedbackCreateNestedManyWithoutOrganizationInput>;
  TwilioInfo?: InputMaybe<TwilioInfoCreateNestedOneWithoutOrganzationInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  use_revenue_planning?: InputMaybe<Scalars['Boolean']['input']>;
  UserCustomFieldFilters?: InputMaybe<UserCustomFieldFilterCreateNestedManyWithoutOrganizationInput>;
  users?: InputMaybe<UserCreateNestedManyWithoutOrganizationInput>;
  win_rep_day_goal?: InputMaybe<Scalars['Float']['input']>;
};

export type OrganizationCreateWithoutNoteItemsInput = {
  ActivityMonitorMultiplier?: InputMaybe<ActivityMonitorMultiplierCreateNestedManyWithoutOrganizationInput>;
  algoFactor?: InputMaybe<AlgoFactorCreateNestedManyWithoutOrganizationInput>;
  allow_calendar_invite?: InputMaybe<Scalars['Boolean']['input']>;
  allow_remove_step_action?: InputMaybe<Scalars['Boolean']['input']>;
  allow_snooze_step_action?: InputMaybe<Scalars['Boolean']['input']>;
  always_record?: InputMaybe<Scalars['Boolean']['input']>;
  arv_initial_held_demos_generated_by_sdr_weight?: InputMaybe<Scalars['Float']['input']>;
  AssociationHistories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutOrganizationInput>;
  auto_call_interval?: InputMaybe<Scalars['Int']['input']>;
  auto_dial?: InputMaybe<Scalars['Boolean']['input']>;
  automated_campaigns?: InputMaybe<AutomatedCampaignCreateNestedManyWithoutOrganizationInput>;
  automatic_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  AutoTransferSelections?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutOrganizationInput>;
  Availablities?: InputMaybe<AvailabilityCreateNestedManyWithoutOrganizationInput>;
  AverageOrderValue?: InputMaybe<AverageOrderValueCreateNestedManyWithoutOrganizationInput>;
  best_action_ever_multiplier?: InputMaybe<Scalars['Float']['input']>;
  best_metrics?: InputMaybe<BestMetricRecordCreateNestedManyWithoutOrganizationInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptCreateNestedManyWithoutOrganizationInput>;
  callMeNows?: InputMaybe<CallMeNowCreateNestedManyWithoutOrganizationInput>;
  CallMultiplier?: InputMaybe<CallMultiplierCreateNestedManyWithoutOrganizationInput>;
  CallSharings?: InputMaybe<CallSharingCreateNestedManyWithoutOrganizationInput>;
  can_edit_sdr_transfer_rules?: InputMaybe<Scalars['Boolean']['input']>;
  claim_rule?: InputMaybe<Scalars['Int']['input']>;
  concession?: InputMaybe<ConcessionCreateNestedManyWithoutOrganizationInput>;
  conference_region?: InputMaybe<ConferenceRegion>;
  Conferences?: InputMaybe<ConferenceCreateNestedManyWithoutOrganizationInput>;
  ContactRequests?: InputMaybe<ContactRequestCreateNestedManyWithoutOrganizationInput>;
  Contacts?: InputMaybe<ContactCreateNestedManyWithoutOrganizationInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  custom_fields?: InputMaybe<CustomFieldCreateNestedManyWithoutOrganizationInput>;
  CustomObject?: InputMaybe<CustomObjectCreateNestedManyWithoutOrganizationInput>;
  CustomObjectField?: InputMaybe<CustomObjectFieldCreateNestedManyWithoutOrganizationInput>;
  CustomObjectFieldForeignKey?: InputMaybe<CustomObjectFieldForeignKeyCreateNestedManyWithoutOrganizationInput>;
  CustomObjectRecord?: InputMaybe<CustomObjectRecordCreateNestedManyWithoutOrganizationInput>;
  CustomObjectRow?: InputMaybe<CustomObjectRowCreateNestedManyWithoutOrganizationInput>;
  CustomQueueItems?: InputMaybe<CustomQueueItemCreateNestedManyWithoutOrganizationInput>;
  DashboardColumnOptions?: InputMaybe<DashboardColumnOptionCreateNestedManyWithoutOrganizationInput>;
  DashboardLeadFilters?: InputMaybe<DashboardLeadFilterCreateNestedManyWithoutOrganizationInput>;
  DashboardRepFilters?: InputMaybe<DashboardRepFilterCreateNestedManyWithoutOrganizationInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewCreateNestedManyWithoutOrganizationInput>;
  day_to_close?: InputMaybe<Scalars['Int']['input']>;
  default_demo_time_duration?: InputMaybe<Scalars['Int']['input']>;
  demo_held_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_not_held_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_not_held_call_back_short_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_prep_time_minutes?: InputMaybe<Scalars['Int']['input']>;
  dev_account?: InputMaybe<Scalars['Boolean']['input']>;
  dial_rule?: InputMaybe<Scalars['Int']['input']>;
  DispositionMultiplier?: InputMaybe<DispositionMultiplierCreateNestedManyWithoutOrganizationInput>;
  dispositions?: InputMaybe<DispositionCreateNestedManyWithoutOrganizationInput>;
  double_booking?: InputMaybe<Scalars['Boolean']['input']>;
  dynamic_date_operators?: InputMaybe<Scalars['Boolean']['input']>;
  edge_servers?: InputMaybe<OrganizationCreateedge_ServersInput>;
  email_conflict_notification_email_list?: InputMaybe<OrganizationCreateemail_Conflict_Notification_Email_ListInput>;
  EmailItems?: InputMaybe<EmailItemCreateNestedManyWithoutOrganizationInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordCreateNestedManyWithoutOrganizationInput>;
  EmailThreads?: InputMaybe<EmailThreadCreateNestedManyWithoutOrganizationInput>;
  enable_modified_metrics?: InputMaybe<Scalars['Boolean']['input']>;
  enable_pay_period_view?: InputMaybe<Scalars['Boolean']['input']>;
  enable_time_constraint?: InputMaybe<Scalars['Boolean']['input']>;
  error_log_email_address?: InputMaybe<OrganizationCreateerror_Log_Email_AddressInput>;
  estimated_average_num_of_weekly_overbooked_demo_slots?: InputMaybe<Scalars['Int']['input']>;
  FolderPermissions?: InputMaybe<FolderPermissionCreateNestedManyWithoutOrganizationInput>;
  Folders?: InputMaybe<FolderCreateNestedManyWithoutOrganizationInput>;
  FutureBookingSetting?: InputMaybe<FutureBookingSettingCreateNestedOneWithoutOrganizationInput>;
  GlobalTransferRule?: InputMaybe<GlobalTransferRuleCreateNestedManyWithoutOrganizationInput>;
  held_demos_per_cc_connect?: InputMaybe<Scalars['Float']['input']>;
  helpful_links?: InputMaybe<OrganizationCreatehelpful_LinksInput>;
  HingePointMultiplier?: InputMaybe<HingePointMultiplierCreateNestedManyWithoutOrganizationInput>;
  hold_goal?: InputMaybe<Scalars['Float']['input']>;
  hubspot_credential?: InputMaybe<HubSpotCredentialCreateNestedOneWithoutOrganizationInput>;
  hubspot_last_sync_date?: InputMaybe<Scalars['DateTime']['input']>;
  hubspot_white_list?: InputMaybe<HubSpotWhitelistCreateNestedManyWithoutOrganizationInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  inbound_close_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_dm_connect_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_lt_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_revenue_value_of_sale?: InputMaybe<Scalars['Float']['input']>;
  inbound_scheduled_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_set_rate?: InputMaybe<Scalars['Float']['input']>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventCreateNestedManyWithoutOrganizationInput>;
  InboundConciergeWidget?: InputMaybe<InboundConciergeWidgetCreateNestedManyWithoutOrganizationInput>;
  industry_options?: InputMaybe<IndustryOptionCreateNestedManyWithoutOrganizationInput>;
  initial_sdr_sets_reps_day?: InputMaybe<Scalars['Float']['input']>;
  integration_error_logs?: InputMaybe<IntegrationErrorLogCreateNestedManyWithoutOrganizationInput>;
  integration_mappings?: InputMaybe<IntegrationMappingCreateNestedManyWithoutOrganizationInput>;
  IntegrationMappingCustomObject?: InputMaybe<IntegrationMappingCustomObjectCreateNestedManyWithoutOrganizationInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogCreateNestedManyWithoutOrganizationInput>;
  IntegrationReadOnlyObject?: InputMaybe<IntegrationReadOnlyObjectCreateNestedManyWithoutOrganizationInput>;
  international?: InputMaybe<Scalars['Boolean']['input']>;
  is_email_domain_verified?: InputMaybe<Scalars['Boolean']['input']>;
  is_salesforce_sandbox?: InputMaybe<Scalars['Boolean']['input']>;
  last_lead_value_compute_time?: InputMaybe<Scalars['DateTime']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutOrganizationInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryCreateNestedManyWithoutOrganizationInput>;
  lead_intent?: InputMaybe<LeadIntentCreateNestedManyWithoutOrganizationInput>;
  lead_release_same_rep_delay_rule?: InputMaybe<Scalars['Int']['input']>;
  lead_routing_email_list?: InputMaybe<OrganizationCreatelead_Routing_Email_ListInput>;
  lead_routing_rules?: InputMaybe<RuleCreateNestedManyWithoutOrganizationInput>;
  LeadCustomObjectRowAssociation?: InputMaybe<LeadCustomObjectRowAssociationCreateNestedManyWithoutOrganizationInput>;
  LeadFieldMultiplier?: InputMaybe<LeadFieldMultiplierCreateNestedManyWithoutOrganizationInput>;
  LeadFieldMultiplierCustom?: InputMaybe<LeadFieldMultiplierCustomCreateNestedManyWithoutOrganizationInput>;
  leads?: InputMaybe<LeadCreateNestedManyWithoutOrganizationInput>;
  leadsource_options?: InputMaybe<LeadSourceOptionCreateNestedManyWithoutOrganizationInput>;
  LeadValue?: InputMaybe<LeadValueCreateNestedManyWithoutOrganizationInput>;
  LifeTimeValueMultiplier?: InputMaybe<LifeTimeValueMultiplierCreateNestedManyWithoutOrganizationInput>;
  LiveTransferSetting?: InputMaybe<LiveTransferSettingCreateNestedOneWithoutOrganizationInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusCreateNestedManyWithoutOrganizationInput>;
  ltv_multiplier_custom_fields?: InputMaybe<LifeTimeValueMultiplierCustomCreateNestedManyWithoutOrganizationInput>;
  made_contact_rule?: InputMaybe<Scalars['Int']['input']>;
  make_sale_address_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_business_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_city_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_country_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_first_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_industry_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_last_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_lead_source_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_primary_email_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_primary_phone_number_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_state_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_sub_industry_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_zip_required?: InputMaybe<Scalars['Boolean']['input']>;
  maxium_day_lag_set_to_schedule?: InputMaybe<Scalars['Int']['input']>;
  mrr?: InputMaybe<Scalars['Int']['input']>;
  mrr_label?: InputMaybe<Scalars['String']['input']>;
  mrr_required_on_sale?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  Notifications?: InputMaybe<NotificationCreateNestedManyWithoutOrganizationInput>;
  onboardComplete?: InputMaybe<Scalars['Boolean']['input']>;
  Opportunity?: InputMaybe<SfOpportunityCreateNestedManyWithoutOrganizationInput>;
  organization_holidays?: InputMaybe<OrganizationHolidayCreateNestedManyWithoutOrganizationInput>;
  organization_hours?: InputMaybe<OrganizationHourCreateNestedManyWithoutOrganizationInput>;
  OrganizationPaymentSetting?: InputMaybe<OrganizationPaymentSettingsCreateNestedOneWithoutOrganizationInput>;
  OrganizationPaymentSettings?: InputMaybe<OrganizationPaymentSettingsCreateNestedOneWithoutOrganizationsInput>;
  orgCalendarInviteMetadata?: InputMaybe<OrgCalendarInviteMetadataCreateNestedManyWithoutOrganizationInput>;
  OrgDefaultPipelinePerecentValue?: InputMaybe<OrgDefaultPipelinePerecentValueCreateNestedOneWithoutOrganizationInput>;
  OrgHubSpotSync?: InputMaybe<OrgHubSpotSyncCreateNestedOneWithoutOrganizationInput>;
  OrgNoEventDate?: InputMaybe<OrgNoEventDateCreateNestedManyWithoutOrganizationInput>;
  OrgSalesforceSync?: InputMaybe<OrgSalesforceSyncCreateNestedOneWithoutOrganizationInput>;
  OrgTimeslotAvailability?: InputMaybe<OrgTimeslotAvailabilityCreateNestedManyWithoutOrganizationInput>;
  outbound_close_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_dm_connect_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_lt_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_revenue_value_of_sale?: InputMaybe<Scalars['Float']['input']>;
  outbound_scheduled_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_set_rate?: InputMaybe<Scalars['Float']['input']>;
  PandadocData?: InputMaybe<PandadocDataCreateNestedOneWithoutOrganizationInput>;
  PandaDocTemplate?: InputMaybe<PandaDocTemplateCreateNestedManyWithoutOrganizationInput>;
  participant_jitter_buffer_size?: InputMaybe<JitterBufferSize>;
  preferred_goal_setting?: InputMaybe<Goalsetting>;
  prepayment?: InputMaybe<Scalars['Boolean']['input']>;
  ProductChangeLog?: InputMaybe<ProductChangeLogCreateNestedManyWithoutOrganizationInput>;
  products?: InputMaybe<ProductCreateNestedManyWithoutOrganizationInput>;
  re_cold_call_hour_delay?: InputMaybe<Scalars['Float']['input']>;
  recordingStatus?: InputMaybe<RecordingStatus>;
  release_lead_resting?: InputMaybe<Scalars['Int']['input']>;
  rep_claim_limit?: InputMaybe<Scalars['Int']['input']>;
  rep_lead_allotment?: InputMaybe<Scalars['Int']['input']>;
  rep_level_call_transfer?: InputMaybe<Scalars['Boolean']['input']>;
  reps_can_bulk_unassign?: InputMaybe<Scalars['Boolean']['input']>;
  reps_can_view_all_notes?: InputMaybe<Scalars['Boolean']['input']>;
  require_sale_notes?: InputMaybe<Scalars['Boolean']['input']>;
  resting_after_skipped_CC?: InputMaybe<Scalars['Int']['input']>;
  resting_period?: InputMaybe<Scalars['Int']['input']>;
  resting_period_after_unsuccessful_cold_call?: InputMaybe<Scalars['Float']['input']>;
  resting_period_after_unsuccessful_demo?: InputMaybe<Scalars['Float']['input']>;
  revenue_plan?: InputMaybe<RevenuePlanItemCreateNestedManyWithoutOrganizationInput>;
  Routings?: InputMaybe<RoutingCreateNestedManyWithoutOrganizationInput>;
  sale_field_options?: InputMaybe<SaleFieldOptionCreateNestedManyWithoutOrganizationInput>;
  sale_notification_email_list?: InputMaybe<OrganizationCreatesale_Notification_Email_ListInput>;
  SaleConfig?: InputMaybe<SaleConfigCreateNestedOneWithoutOrganizationInput>;
  SaleConfigPage?: InputMaybe<SaleConfigPageCreateNestedManyWithoutOrganizationInput>;
  SaleConfigSection?: InputMaybe<SaleConfigSectionCreateNestedManyWithoutOrganizationInput>;
  SaleCycles?: InputMaybe<SaleCycleCreateNestedManyWithoutOrganizationInput>;
  SaleItems?: InputMaybe<SaleItemCreateNestedManyWithoutOrganizationInput>;
  sales?: InputMaybe<SaleCreateNestedManyWithoutOrganizationInput>;
  salesforce_domain?: InputMaybe<Scalars['String']['input']>;
  salesforce_last_sync_date?: InputMaybe<Scalars['DateTime']['input']>;
  SalesForceColumn?: InputMaybe<SalesForceColumnCreateNestedManyWithoutOrganizationInput>;
  salesForceData?: InputMaybe<SalesForceDataCreateNestedOneWithoutOrganizationInput>;
  ScheduleItems?: InputMaybe<ScheduleItemCreateNestedManyWithoutOrganizationInput>;
  self_sourcing_rule?: InputMaybe<Scalars['Int']['input']>;
  send_error_log_email?: InputMaybe<Scalars['Boolean']['input']>;
  send_lead_routing_email?: InputMaybe<Scalars['Boolean']['input']>;
  sendgrid_email_domain?: InputMaybe<Scalars['String']['input']>;
  sendgrid_email_domain_id?: InputMaybe<Scalars['Int']['input']>;
  sequence?: InputMaybe<SequenceCreateNestedManyWithoutOrganzationInput>;
  SequenceCriteriaConditions?: InputMaybe<SequenceCriteriaConditionCreateNestedManyWithoutOrganizationInput>;
  SequenceDashboardViews?: InputMaybe<SequenceDashboardViewCreateNestedManyWithoutOrganizationInput>;
  SequenceEntryCriterias?: InputMaybe<SequenceEntryCriteriaCreateNestedManyWithoutOrganizationInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutOrganizationInput>;
  SequenceSteps?: InputMaybe<SequenceStepCreateNestedManyWithoutOrganizationInput>;
  set_goal?: InputMaybe<Scalars['Float']['input']>;
  short_resting?: InputMaybe<Scalars['Int']['input']>;
  show_calendar_options?: InputMaybe<Scalars['Boolean']['input']>;
  show_contract_duration_sales?: InputMaybe<Scalars['Boolean']['input']>;
  show_lead_source_to_rep?: InputMaybe<Scalars['Boolean']['input']>;
  show_nocontact_button?: InputMaybe<Scalars['Boolean']['input']>;
  show_payment_terms_sales?: InputMaybe<Scalars['Boolean']['input']>;
  show_rep_all_leads?: InputMaybe<Scalars['Boolean']['input']>;
  show_rep_my_leads?: InputMaybe<Scalars['Boolean']['input']>;
  show_system_view?: InputMaybe<Scalars['Boolean']['input']>;
  sites?: InputMaybe<SiteCreateNestedManyWithoutOrganizationInput>;
  sms_opt_in_dialog?: InputMaybe<Scalars['String']['input']>;
  SMSItems?: InputMaybe<SmsItemCreateNestedManyWithoutOrganizationInput>;
  SnoozeTrackings?: InputMaybe<SnoozeTrackingCreateNestedManyWithoutOrganizationInput>;
  StepActionLogs?: InputMaybe<StepActionLogCreateNestedManyWithoutOrganizationInput>;
  StepActions?: InputMaybe<StepActionCreateNestedManyWithoutOrganizationInput>;
  StepConditions?: InputMaybe<StepConditionCreateNestedManyWithoutOrganizationInput>;
  teams?: InputMaybe<TeamCreateNestedManyWithoutOrganizationInput>;
  Template?: InputMaybe<TemplateCreateNestedManyWithoutOrganizationInput>;
  TemplateVersions?: InputMaybe<TemplateVersionCreateNestedManyWithoutOrganizationInput>;
  timekit_id?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  transfer_timeout?: InputMaybe<Scalars['Int']['input']>;
  TransferAttempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutOrganizationInput>;
  twilio_numbers?: InputMaybe<TwilioNumberCreateNestedManyWithoutOrganizationInput>;
  TwilioFeedbacks?: InputMaybe<TwilioFeedbackCreateNestedManyWithoutOrganizationInput>;
  TwilioInfo?: InputMaybe<TwilioInfoCreateNestedOneWithoutOrganzationInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  use_revenue_planning?: InputMaybe<Scalars['Boolean']['input']>;
  UserCustomFieldFilters?: InputMaybe<UserCustomFieldFilterCreateNestedManyWithoutOrganizationInput>;
  users?: InputMaybe<UserCreateNestedManyWithoutOrganizationInput>;
  win_rep_day_goal?: InputMaybe<Scalars['Float']['input']>;
};

export type OrganizationCreateWithoutNotificationsInput = {
  ActivityMonitorMultiplier?: InputMaybe<ActivityMonitorMultiplierCreateNestedManyWithoutOrganizationInput>;
  algoFactor?: InputMaybe<AlgoFactorCreateNestedManyWithoutOrganizationInput>;
  allow_calendar_invite?: InputMaybe<Scalars['Boolean']['input']>;
  allow_remove_step_action?: InputMaybe<Scalars['Boolean']['input']>;
  allow_snooze_step_action?: InputMaybe<Scalars['Boolean']['input']>;
  always_record?: InputMaybe<Scalars['Boolean']['input']>;
  arv_initial_held_demos_generated_by_sdr_weight?: InputMaybe<Scalars['Float']['input']>;
  AssociationHistories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutOrganizationInput>;
  auto_call_interval?: InputMaybe<Scalars['Int']['input']>;
  auto_dial?: InputMaybe<Scalars['Boolean']['input']>;
  automated_campaigns?: InputMaybe<AutomatedCampaignCreateNestedManyWithoutOrganizationInput>;
  automatic_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  AutoTransferSelections?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutOrganizationInput>;
  Availablities?: InputMaybe<AvailabilityCreateNestedManyWithoutOrganizationInput>;
  AverageOrderValue?: InputMaybe<AverageOrderValueCreateNestedManyWithoutOrganizationInput>;
  best_action_ever_multiplier?: InputMaybe<Scalars['Float']['input']>;
  best_metrics?: InputMaybe<BestMetricRecordCreateNestedManyWithoutOrganizationInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptCreateNestedManyWithoutOrganizationInput>;
  callMeNows?: InputMaybe<CallMeNowCreateNestedManyWithoutOrganizationInput>;
  CallMultiplier?: InputMaybe<CallMultiplierCreateNestedManyWithoutOrganizationInput>;
  CallSharings?: InputMaybe<CallSharingCreateNestedManyWithoutOrganizationInput>;
  can_edit_sdr_transfer_rules?: InputMaybe<Scalars['Boolean']['input']>;
  claim_rule?: InputMaybe<Scalars['Int']['input']>;
  concession?: InputMaybe<ConcessionCreateNestedManyWithoutOrganizationInput>;
  conference_region?: InputMaybe<ConferenceRegion>;
  Conferences?: InputMaybe<ConferenceCreateNestedManyWithoutOrganizationInput>;
  ContactRequests?: InputMaybe<ContactRequestCreateNestedManyWithoutOrganizationInput>;
  Contacts?: InputMaybe<ContactCreateNestedManyWithoutOrganizationInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  custom_fields?: InputMaybe<CustomFieldCreateNestedManyWithoutOrganizationInput>;
  CustomObject?: InputMaybe<CustomObjectCreateNestedManyWithoutOrganizationInput>;
  CustomObjectField?: InputMaybe<CustomObjectFieldCreateNestedManyWithoutOrganizationInput>;
  CustomObjectFieldForeignKey?: InputMaybe<CustomObjectFieldForeignKeyCreateNestedManyWithoutOrganizationInput>;
  CustomObjectRecord?: InputMaybe<CustomObjectRecordCreateNestedManyWithoutOrganizationInput>;
  CustomObjectRow?: InputMaybe<CustomObjectRowCreateNestedManyWithoutOrganizationInput>;
  CustomQueueItems?: InputMaybe<CustomQueueItemCreateNestedManyWithoutOrganizationInput>;
  DashboardColumnOptions?: InputMaybe<DashboardColumnOptionCreateNestedManyWithoutOrganizationInput>;
  DashboardLeadFilters?: InputMaybe<DashboardLeadFilterCreateNestedManyWithoutOrganizationInput>;
  DashboardRepFilters?: InputMaybe<DashboardRepFilterCreateNestedManyWithoutOrganizationInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewCreateNestedManyWithoutOrganizationInput>;
  day_to_close?: InputMaybe<Scalars['Int']['input']>;
  default_demo_time_duration?: InputMaybe<Scalars['Int']['input']>;
  demo_held_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_not_held_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_not_held_call_back_short_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_prep_time_minutes?: InputMaybe<Scalars['Int']['input']>;
  dev_account?: InputMaybe<Scalars['Boolean']['input']>;
  dial_rule?: InputMaybe<Scalars['Int']['input']>;
  DispositionMultiplier?: InputMaybe<DispositionMultiplierCreateNestedManyWithoutOrganizationInput>;
  dispositions?: InputMaybe<DispositionCreateNestedManyWithoutOrganizationInput>;
  double_booking?: InputMaybe<Scalars['Boolean']['input']>;
  dynamic_date_operators?: InputMaybe<Scalars['Boolean']['input']>;
  edge_servers?: InputMaybe<OrganizationCreateedge_ServersInput>;
  email_conflict_notification_email_list?: InputMaybe<OrganizationCreateemail_Conflict_Notification_Email_ListInput>;
  EmailItems?: InputMaybe<EmailItemCreateNestedManyWithoutOrganizationInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordCreateNestedManyWithoutOrganizationInput>;
  EmailThreads?: InputMaybe<EmailThreadCreateNestedManyWithoutOrganizationInput>;
  enable_modified_metrics?: InputMaybe<Scalars['Boolean']['input']>;
  enable_pay_period_view?: InputMaybe<Scalars['Boolean']['input']>;
  enable_time_constraint?: InputMaybe<Scalars['Boolean']['input']>;
  error_log_email_address?: InputMaybe<OrganizationCreateerror_Log_Email_AddressInput>;
  estimated_average_num_of_weekly_overbooked_demo_slots?: InputMaybe<Scalars['Int']['input']>;
  FolderPermissions?: InputMaybe<FolderPermissionCreateNestedManyWithoutOrganizationInput>;
  Folders?: InputMaybe<FolderCreateNestedManyWithoutOrganizationInput>;
  FutureBookingSetting?: InputMaybe<FutureBookingSettingCreateNestedOneWithoutOrganizationInput>;
  GlobalTransferRule?: InputMaybe<GlobalTransferRuleCreateNestedManyWithoutOrganizationInput>;
  held_demos_per_cc_connect?: InputMaybe<Scalars['Float']['input']>;
  helpful_links?: InputMaybe<OrganizationCreatehelpful_LinksInput>;
  HingePointMultiplier?: InputMaybe<HingePointMultiplierCreateNestedManyWithoutOrganizationInput>;
  hold_goal?: InputMaybe<Scalars['Float']['input']>;
  hubspot_credential?: InputMaybe<HubSpotCredentialCreateNestedOneWithoutOrganizationInput>;
  hubspot_last_sync_date?: InputMaybe<Scalars['DateTime']['input']>;
  hubspot_white_list?: InputMaybe<HubSpotWhitelistCreateNestedManyWithoutOrganizationInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  inbound_close_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_dm_connect_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_lt_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_revenue_value_of_sale?: InputMaybe<Scalars['Float']['input']>;
  inbound_scheduled_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_set_rate?: InputMaybe<Scalars['Float']['input']>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventCreateNestedManyWithoutOrganizationInput>;
  InboundConciergeWidget?: InputMaybe<InboundConciergeWidgetCreateNestedManyWithoutOrganizationInput>;
  industry_options?: InputMaybe<IndustryOptionCreateNestedManyWithoutOrganizationInput>;
  initial_sdr_sets_reps_day?: InputMaybe<Scalars['Float']['input']>;
  integration_error_logs?: InputMaybe<IntegrationErrorLogCreateNestedManyWithoutOrganizationInput>;
  integration_mappings?: InputMaybe<IntegrationMappingCreateNestedManyWithoutOrganizationInput>;
  IntegrationMappingCustomObject?: InputMaybe<IntegrationMappingCustomObjectCreateNestedManyWithoutOrganizationInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogCreateNestedManyWithoutOrganizationInput>;
  IntegrationReadOnlyObject?: InputMaybe<IntegrationReadOnlyObjectCreateNestedManyWithoutOrganizationInput>;
  international?: InputMaybe<Scalars['Boolean']['input']>;
  is_email_domain_verified?: InputMaybe<Scalars['Boolean']['input']>;
  is_salesforce_sandbox?: InputMaybe<Scalars['Boolean']['input']>;
  last_lead_value_compute_time?: InputMaybe<Scalars['DateTime']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutOrganizationInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryCreateNestedManyWithoutOrganizationInput>;
  lead_intent?: InputMaybe<LeadIntentCreateNestedManyWithoutOrganizationInput>;
  lead_release_same_rep_delay_rule?: InputMaybe<Scalars['Int']['input']>;
  lead_routing_email_list?: InputMaybe<OrganizationCreatelead_Routing_Email_ListInput>;
  lead_routing_rules?: InputMaybe<RuleCreateNestedManyWithoutOrganizationInput>;
  LeadCustomObjectRowAssociation?: InputMaybe<LeadCustomObjectRowAssociationCreateNestedManyWithoutOrganizationInput>;
  LeadFieldMultiplier?: InputMaybe<LeadFieldMultiplierCreateNestedManyWithoutOrganizationInput>;
  LeadFieldMultiplierCustom?: InputMaybe<LeadFieldMultiplierCustomCreateNestedManyWithoutOrganizationInput>;
  leads?: InputMaybe<LeadCreateNestedManyWithoutOrganizationInput>;
  leadsource_options?: InputMaybe<LeadSourceOptionCreateNestedManyWithoutOrganizationInput>;
  LeadValue?: InputMaybe<LeadValueCreateNestedManyWithoutOrganizationInput>;
  LifeTimeValueMultiplier?: InputMaybe<LifeTimeValueMultiplierCreateNestedManyWithoutOrganizationInput>;
  LiveTransferSetting?: InputMaybe<LiveTransferSettingCreateNestedOneWithoutOrganizationInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusCreateNestedManyWithoutOrganizationInput>;
  ltv_multiplier_custom_fields?: InputMaybe<LifeTimeValueMultiplierCustomCreateNestedManyWithoutOrganizationInput>;
  made_contact_rule?: InputMaybe<Scalars['Int']['input']>;
  make_sale_address_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_business_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_city_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_country_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_first_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_industry_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_last_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_lead_source_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_primary_email_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_primary_phone_number_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_state_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_sub_industry_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_zip_required?: InputMaybe<Scalars['Boolean']['input']>;
  maxium_day_lag_set_to_schedule?: InputMaybe<Scalars['Int']['input']>;
  mrr?: InputMaybe<Scalars['Int']['input']>;
  mrr_label?: InputMaybe<Scalars['String']['input']>;
  mrr_required_on_sale?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  NoteItems?: InputMaybe<NoteItemCreateNestedManyWithoutOrganizationInput>;
  onboardComplete?: InputMaybe<Scalars['Boolean']['input']>;
  Opportunity?: InputMaybe<SfOpportunityCreateNestedManyWithoutOrganizationInput>;
  organization_holidays?: InputMaybe<OrganizationHolidayCreateNestedManyWithoutOrganizationInput>;
  organization_hours?: InputMaybe<OrganizationHourCreateNestedManyWithoutOrganizationInput>;
  OrganizationPaymentSetting?: InputMaybe<OrganizationPaymentSettingsCreateNestedOneWithoutOrganizationInput>;
  OrganizationPaymentSettings?: InputMaybe<OrganizationPaymentSettingsCreateNestedOneWithoutOrganizationsInput>;
  orgCalendarInviteMetadata?: InputMaybe<OrgCalendarInviteMetadataCreateNestedManyWithoutOrganizationInput>;
  OrgDefaultPipelinePerecentValue?: InputMaybe<OrgDefaultPipelinePerecentValueCreateNestedOneWithoutOrganizationInput>;
  OrgHubSpotSync?: InputMaybe<OrgHubSpotSyncCreateNestedOneWithoutOrganizationInput>;
  OrgNoEventDate?: InputMaybe<OrgNoEventDateCreateNestedManyWithoutOrganizationInput>;
  OrgSalesforceSync?: InputMaybe<OrgSalesforceSyncCreateNestedOneWithoutOrganizationInput>;
  OrgTimeslotAvailability?: InputMaybe<OrgTimeslotAvailabilityCreateNestedManyWithoutOrganizationInput>;
  outbound_close_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_dm_connect_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_lt_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_revenue_value_of_sale?: InputMaybe<Scalars['Float']['input']>;
  outbound_scheduled_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_set_rate?: InputMaybe<Scalars['Float']['input']>;
  PandadocData?: InputMaybe<PandadocDataCreateNestedOneWithoutOrganizationInput>;
  PandaDocTemplate?: InputMaybe<PandaDocTemplateCreateNestedManyWithoutOrganizationInput>;
  participant_jitter_buffer_size?: InputMaybe<JitterBufferSize>;
  preferred_goal_setting?: InputMaybe<Goalsetting>;
  prepayment?: InputMaybe<Scalars['Boolean']['input']>;
  ProductChangeLog?: InputMaybe<ProductChangeLogCreateNestedManyWithoutOrganizationInput>;
  products?: InputMaybe<ProductCreateNestedManyWithoutOrganizationInput>;
  re_cold_call_hour_delay?: InputMaybe<Scalars['Float']['input']>;
  recordingStatus?: InputMaybe<RecordingStatus>;
  release_lead_resting?: InputMaybe<Scalars['Int']['input']>;
  rep_claim_limit?: InputMaybe<Scalars['Int']['input']>;
  rep_lead_allotment?: InputMaybe<Scalars['Int']['input']>;
  rep_level_call_transfer?: InputMaybe<Scalars['Boolean']['input']>;
  reps_can_bulk_unassign?: InputMaybe<Scalars['Boolean']['input']>;
  reps_can_view_all_notes?: InputMaybe<Scalars['Boolean']['input']>;
  require_sale_notes?: InputMaybe<Scalars['Boolean']['input']>;
  resting_after_skipped_CC?: InputMaybe<Scalars['Int']['input']>;
  resting_period?: InputMaybe<Scalars['Int']['input']>;
  resting_period_after_unsuccessful_cold_call?: InputMaybe<Scalars['Float']['input']>;
  resting_period_after_unsuccessful_demo?: InputMaybe<Scalars['Float']['input']>;
  revenue_plan?: InputMaybe<RevenuePlanItemCreateNestedManyWithoutOrganizationInput>;
  Routings?: InputMaybe<RoutingCreateNestedManyWithoutOrganizationInput>;
  sale_field_options?: InputMaybe<SaleFieldOptionCreateNestedManyWithoutOrganizationInput>;
  sale_notification_email_list?: InputMaybe<OrganizationCreatesale_Notification_Email_ListInput>;
  SaleConfig?: InputMaybe<SaleConfigCreateNestedOneWithoutOrganizationInput>;
  SaleConfigPage?: InputMaybe<SaleConfigPageCreateNestedManyWithoutOrganizationInput>;
  SaleConfigSection?: InputMaybe<SaleConfigSectionCreateNestedManyWithoutOrganizationInput>;
  SaleCycles?: InputMaybe<SaleCycleCreateNestedManyWithoutOrganizationInput>;
  SaleItems?: InputMaybe<SaleItemCreateNestedManyWithoutOrganizationInput>;
  sales?: InputMaybe<SaleCreateNestedManyWithoutOrganizationInput>;
  salesforce_domain?: InputMaybe<Scalars['String']['input']>;
  salesforce_last_sync_date?: InputMaybe<Scalars['DateTime']['input']>;
  SalesForceColumn?: InputMaybe<SalesForceColumnCreateNestedManyWithoutOrganizationInput>;
  salesForceData?: InputMaybe<SalesForceDataCreateNestedOneWithoutOrganizationInput>;
  ScheduleItems?: InputMaybe<ScheduleItemCreateNestedManyWithoutOrganizationInput>;
  self_sourcing_rule?: InputMaybe<Scalars['Int']['input']>;
  send_error_log_email?: InputMaybe<Scalars['Boolean']['input']>;
  send_lead_routing_email?: InputMaybe<Scalars['Boolean']['input']>;
  sendgrid_email_domain?: InputMaybe<Scalars['String']['input']>;
  sendgrid_email_domain_id?: InputMaybe<Scalars['Int']['input']>;
  sequence?: InputMaybe<SequenceCreateNestedManyWithoutOrganzationInput>;
  SequenceCriteriaConditions?: InputMaybe<SequenceCriteriaConditionCreateNestedManyWithoutOrganizationInput>;
  SequenceDashboardViews?: InputMaybe<SequenceDashboardViewCreateNestedManyWithoutOrganizationInput>;
  SequenceEntryCriterias?: InputMaybe<SequenceEntryCriteriaCreateNestedManyWithoutOrganizationInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutOrganizationInput>;
  SequenceSteps?: InputMaybe<SequenceStepCreateNestedManyWithoutOrganizationInput>;
  set_goal?: InputMaybe<Scalars['Float']['input']>;
  short_resting?: InputMaybe<Scalars['Int']['input']>;
  show_calendar_options?: InputMaybe<Scalars['Boolean']['input']>;
  show_contract_duration_sales?: InputMaybe<Scalars['Boolean']['input']>;
  show_lead_source_to_rep?: InputMaybe<Scalars['Boolean']['input']>;
  show_nocontact_button?: InputMaybe<Scalars['Boolean']['input']>;
  show_payment_terms_sales?: InputMaybe<Scalars['Boolean']['input']>;
  show_rep_all_leads?: InputMaybe<Scalars['Boolean']['input']>;
  show_rep_my_leads?: InputMaybe<Scalars['Boolean']['input']>;
  show_system_view?: InputMaybe<Scalars['Boolean']['input']>;
  sites?: InputMaybe<SiteCreateNestedManyWithoutOrganizationInput>;
  sms_opt_in_dialog?: InputMaybe<Scalars['String']['input']>;
  SMSItems?: InputMaybe<SmsItemCreateNestedManyWithoutOrganizationInput>;
  SnoozeTrackings?: InputMaybe<SnoozeTrackingCreateNestedManyWithoutOrganizationInput>;
  StepActionLogs?: InputMaybe<StepActionLogCreateNestedManyWithoutOrganizationInput>;
  StepActions?: InputMaybe<StepActionCreateNestedManyWithoutOrganizationInput>;
  StepConditions?: InputMaybe<StepConditionCreateNestedManyWithoutOrganizationInput>;
  teams?: InputMaybe<TeamCreateNestedManyWithoutOrganizationInput>;
  Template?: InputMaybe<TemplateCreateNestedManyWithoutOrganizationInput>;
  TemplateVersions?: InputMaybe<TemplateVersionCreateNestedManyWithoutOrganizationInput>;
  timekit_id?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  transfer_timeout?: InputMaybe<Scalars['Int']['input']>;
  TransferAttempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutOrganizationInput>;
  twilio_numbers?: InputMaybe<TwilioNumberCreateNestedManyWithoutOrganizationInput>;
  TwilioFeedbacks?: InputMaybe<TwilioFeedbackCreateNestedManyWithoutOrganizationInput>;
  TwilioInfo?: InputMaybe<TwilioInfoCreateNestedOneWithoutOrganzationInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  use_revenue_planning?: InputMaybe<Scalars['Boolean']['input']>;
  UserCustomFieldFilters?: InputMaybe<UserCustomFieldFilterCreateNestedManyWithoutOrganizationInput>;
  users?: InputMaybe<UserCreateNestedManyWithoutOrganizationInput>;
  win_rep_day_goal?: InputMaybe<Scalars['Float']['input']>;
};

export type OrganizationCreateWithoutOrganizationPaymentSettingInput = {
  ActivityMonitorMultiplier?: InputMaybe<ActivityMonitorMultiplierCreateNestedManyWithoutOrganizationInput>;
  algoFactor?: InputMaybe<AlgoFactorCreateNestedManyWithoutOrganizationInput>;
  allow_calendar_invite?: InputMaybe<Scalars['Boolean']['input']>;
  allow_remove_step_action?: InputMaybe<Scalars['Boolean']['input']>;
  allow_snooze_step_action?: InputMaybe<Scalars['Boolean']['input']>;
  always_record?: InputMaybe<Scalars['Boolean']['input']>;
  arv_initial_held_demos_generated_by_sdr_weight?: InputMaybe<Scalars['Float']['input']>;
  AssociationHistories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutOrganizationInput>;
  auto_call_interval?: InputMaybe<Scalars['Int']['input']>;
  auto_dial?: InputMaybe<Scalars['Boolean']['input']>;
  automated_campaigns?: InputMaybe<AutomatedCampaignCreateNestedManyWithoutOrganizationInput>;
  automatic_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  AutoTransferSelections?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutOrganizationInput>;
  Availablities?: InputMaybe<AvailabilityCreateNestedManyWithoutOrganizationInput>;
  AverageOrderValue?: InputMaybe<AverageOrderValueCreateNestedManyWithoutOrganizationInput>;
  best_action_ever_multiplier?: InputMaybe<Scalars['Float']['input']>;
  best_metrics?: InputMaybe<BestMetricRecordCreateNestedManyWithoutOrganizationInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptCreateNestedManyWithoutOrganizationInput>;
  callMeNows?: InputMaybe<CallMeNowCreateNestedManyWithoutOrganizationInput>;
  CallMultiplier?: InputMaybe<CallMultiplierCreateNestedManyWithoutOrganizationInput>;
  CallSharings?: InputMaybe<CallSharingCreateNestedManyWithoutOrganizationInput>;
  can_edit_sdr_transfer_rules?: InputMaybe<Scalars['Boolean']['input']>;
  claim_rule?: InputMaybe<Scalars['Int']['input']>;
  concession?: InputMaybe<ConcessionCreateNestedManyWithoutOrganizationInput>;
  conference_region?: InputMaybe<ConferenceRegion>;
  Conferences?: InputMaybe<ConferenceCreateNestedManyWithoutOrganizationInput>;
  ContactRequests?: InputMaybe<ContactRequestCreateNestedManyWithoutOrganizationInput>;
  Contacts?: InputMaybe<ContactCreateNestedManyWithoutOrganizationInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  custom_fields?: InputMaybe<CustomFieldCreateNestedManyWithoutOrganizationInput>;
  CustomObject?: InputMaybe<CustomObjectCreateNestedManyWithoutOrganizationInput>;
  CustomObjectField?: InputMaybe<CustomObjectFieldCreateNestedManyWithoutOrganizationInput>;
  CustomObjectFieldForeignKey?: InputMaybe<CustomObjectFieldForeignKeyCreateNestedManyWithoutOrganizationInput>;
  CustomObjectRecord?: InputMaybe<CustomObjectRecordCreateNestedManyWithoutOrganizationInput>;
  CustomObjectRow?: InputMaybe<CustomObjectRowCreateNestedManyWithoutOrganizationInput>;
  CustomQueueItems?: InputMaybe<CustomQueueItemCreateNestedManyWithoutOrganizationInput>;
  DashboardColumnOptions?: InputMaybe<DashboardColumnOptionCreateNestedManyWithoutOrganizationInput>;
  DashboardLeadFilters?: InputMaybe<DashboardLeadFilterCreateNestedManyWithoutOrganizationInput>;
  DashboardRepFilters?: InputMaybe<DashboardRepFilterCreateNestedManyWithoutOrganizationInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewCreateNestedManyWithoutOrganizationInput>;
  day_to_close?: InputMaybe<Scalars['Int']['input']>;
  default_demo_time_duration?: InputMaybe<Scalars['Int']['input']>;
  demo_held_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_not_held_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_not_held_call_back_short_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_prep_time_minutes?: InputMaybe<Scalars['Int']['input']>;
  dev_account?: InputMaybe<Scalars['Boolean']['input']>;
  dial_rule?: InputMaybe<Scalars['Int']['input']>;
  DispositionMultiplier?: InputMaybe<DispositionMultiplierCreateNestedManyWithoutOrganizationInput>;
  dispositions?: InputMaybe<DispositionCreateNestedManyWithoutOrganizationInput>;
  double_booking?: InputMaybe<Scalars['Boolean']['input']>;
  dynamic_date_operators?: InputMaybe<Scalars['Boolean']['input']>;
  edge_servers?: InputMaybe<OrganizationCreateedge_ServersInput>;
  email_conflict_notification_email_list?: InputMaybe<OrganizationCreateemail_Conflict_Notification_Email_ListInput>;
  EmailItems?: InputMaybe<EmailItemCreateNestedManyWithoutOrganizationInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordCreateNestedManyWithoutOrganizationInput>;
  EmailThreads?: InputMaybe<EmailThreadCreateNestedManyWithoutOrganizationInput>;
  enable_modified_metrics?: InputMaybe<Scalars['Boolean']['input']>;
  enable_pay_period_view?: InputMaybe<Scalars['Boolean']['input']>;
  enable_time_constraint?: InputMaybe<Scalars['Boolean']['input']>;
  error_log_email_address?: InputMaybe<OrganizationCreateerror_Log_Email_AddressInput>;
  estimated_average_num_of_weekly_overbooked_demo_slots?: InputMaybe<Scalars['Int']['input']>;
  FolderPermissions?: InputMaybe<FolderPermissionCreateNestedManyWithoutOrganizationInput>;
  Folders?: InputMaybe<FolderCreateNestedManyWithoutOrganizationInput>;
  FutureBookingSetting?: InputMaybe<FutureBookingSettingCreateNestedOneWithoutOrganizationInput>;
  GlobalTransferRule?: InputMaybe<GlobalTransferRuleCreateNestedManyWithoutOrganizationInput>;
  held_demos_per_cc_connect?: InputMaybe<Scalars['Float']['input']>;
  helpful_links?: InputMaybe<OrganizationCreatehelpful_LinksInput>;
  HingePointMultiplier?: InputMaybe<HingePointMultiplierCreateNestedManyWithoutOrganizationInput>;
  hold_goal?: InputMaybe<Scalars['Float']['input']>;
  hubspot_credential?: InputMaybe<HubSpotCredentialCreateNestedOneWithoutOrganizationInput>;
  hubspot_last_sync_date?: InputMaybe<Scalars['DateTime']['input']>;
  hubspot_white_list?: InputMaybe<HubSpotWhitelistCreateNestedManyWithoutOrganizationInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  inbound_close_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_dm_connect_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_lt_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_revenue_value_of_sale?: InputMaybe<Scalars['Float']['input']>;
  inbound_scheduled_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_set_rate?: InputMaybe<Scalars['Float']['input']>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventCreateNestedManyWithoutOrganizationInput>;
  InboundConciergeWidget?: InputMaybe<InboundConciergeWidgetCreateNestedManyWithoutOrganizationInput>;
  industry_options?: InputMaybe<IndustryOptionCreateNestedManyWithoutOrganizationInput>;
  initial_sdr_sets_reps_day?: InputMaybe<Scalars['Float']['input']>;
  integration_error_logs?: InputMaybe<IntegrationErrorLogCreateNestedManyWithoutOrganizationInput>;
  integration_mappings?: InputMaybe<IntegrationMappingCreateNestedManyWithoutOrganizationInput>;
  IntegrationMappingCustomObject?: InputMaybe<IntegrationMappingCustomObjectCreateNestedManyWithoutOrganizationInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogCreateNestedManyWithoutOrganizationInput>;
  IntegrationReadOnlyObject?: InputMaybe<IntegrationReadOnlyObjectCreateNestedManyWithoutOrganizationInput>;
  international?: InputMaybe<Scalars['Boolean']['input']>;
  is_email_domain_verified?: InputMaybe<Scalars['Boolean']['input']>;
  is_salesforce_sandbox?: InputMaybe<Scalars['Boolean']['input']>;
  last_lead_value_compute_time?: InputMaybe<Scalars['DateTime']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutOrganizationInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryCreateNestedManyWithoutOrganizationInput>;
  lead_intent?: InputMaybe<LeadIntentCreateNestedManyWithoutOrganizationInput>;
  lead_release_same_rep_delay_rule?: InputMaybe<Scalars['Int']['input']>;
  lead_routing_email_list?: InputMaybe<OrganizationCreatelead_Routing_Email_ListInput>;
  lead_routing_rules?: InputMaybe<RuleCreateNestedManyWithoutOrganizationInput>;
  LeadCustomObjectRowAssociation?: InputMaybe<LeadCustomObjectRowAssociationCreateNestedManyWithoutOrganizationInput>;
  LeadFieldMultiplier?: InputMaybe<LeadFieldMultiplierCreateNestedManyWithoutOrganizationInput>;
  LeadFieldMultiplierCustom?: InputMaybe<LeadFieldMultiplierCustomCreateNestedManyWithoutOrganizationInput>;
  leads?: InputMaybe<LeadCreateNestedManyWithoutOrganizationInput>;
  leadsource_options?: InputMaybe<LeadSourceOptionCreateNestedManyWithoutOrganizationInput>;
  LeadValue?: InputMaybe<LeadValueCreateNestedManyWithoutOrganizationInput>;
  LifeTimeValueMultiplier?: InputMaybe<LifeTimeValueMultiplierCreateNestedManyWithoutOrganizationInput>;
  LiveTransferSetting?: InputMaybe<LiveTransferSettingCreateNestedOneWithoutOrganizationInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusCreateNestedManyWithoutOrganizationInput>;
  ltv_multiplier_custom_fields?: InputMaybe<LifeTimeValueMultiplierCustomCreateNestedManyWithoutOrganizationInput>;
  made_contact_rule?: InputMaybe<Scalars['Int']['input']>;
  make_sale_address_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_business_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_city_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_country_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_first_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_industry_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_last_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_lead_source_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_primary_email_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_primary_phone_number_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_state_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_sub_industry_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_zip_required?: InputMaybe<Scalars['Boolean']['input']>;
  maxium_day_lag_set_to_schedule?: InputMaybe<Scalars['Int']['input']>;
  mrr?: InputMaybe<Scalars['Int']['input']>;
  mrr_label?: InputMaybe<Scalars['String']['input']>;
  mrr_required_on_sale?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  NoteItems?: InputMaybe<NoteItemCreateNestedManyWithoutOrganizationInput>;
  Notifications?: InputMaybe<NotificationCreateNestedManyWithoutOrganizationInput>;
  onboardComplete?: InputMaybe<Scalars['Boolean']['input']>;
  Opportunity?: InputMaybe<SfOpportunityCreateNestedManyWithoutOrganizationInput>;
  organization_holidays?: InputMaybe<OrganizationHolidayCreateNestedManyWithoutOrganizationInput>;
  organization_hours?: InputMaybe<OrganizationHourCreateNestedManyWithoutOrganizationInput>;
  OrganizationPaymentSettings?: InputMaybe<OrganizationPaymentSettingsCreateNestedOneWithoutOrganizationsInput>;
  orgCalendarInviteMetadata?: InputMaybe<OrgCalendarInviteMetadataCreateNestedManyWithoutOrganizationInput>;
  OrgDefaultPipelinePerecentValue?: InputMaybe<OrgDefaultPipelinePerecentValueCreateNestedOneWithoutOrganizationInput>;
  OrgHubSpotSync?: InputMaybe<OrgHubSpotSyncCreateNestedOneWithoutOrganizationInput>;
  OrgNoEventDate?: InputMaybe<OrgNoEventDateCreateNestedManyWithoutOrganizationInput>;
  OrgSalesforceSync?: InputMaybe<OrgSalesforceSyncCreateNestedOneWithoutOrganizationInput>;
  OrgTimeslotAvailability?: InputMaybe<OrgTimeslotAvailabilityCreateNestedManyWithoutOrganizationInput>;
  outbound_close_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_dm_connect_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_lt_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_revenue_value_of_sale?: InputMaybe<Scalars['Float']['input']>;
  outbound_scheduled_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_set_rate?: InputMaybe<Scalars['Float']['input']>;
  PandadocData?: InputMaybe<PandadocDataCreateNestedOneWithoutOrganizationInput>;
  PandaDocTemplate?: InputMaybe<PandaDocTemplateCreateNestedManyWithoutOrganizationInput>;
  participant_jitter_buffer_size?: InputMaybe<JitterBufferSize>;
  preferred_goal_setting?: InputMaybe<Goalsetting>;
  prepayment?: InputMaybe<Scalars['Boolean']['input']>;
  ProductChangeLog?: InputMaybe<ProductChangeLogCreateNestedManyWithoutOrganizationInput>;
  products?: InputMaybe<ProductCreateNestedManyWithoutOrganizationInput>;
  re_cold_call_hour_delay?: InputMaybe<Scalars['Float']['input']>;
  recordingStatus?: InputMaybe<RecordingStatus>;
  release_lead_resting?: InputMaybe<Scalars['Int']['input']>;
  rep_claim_limit?: InputMaybe<Scalars['Int']['input']>;
  rep_lead_allotment?: InputMaybe<Scalars['Int']['input']>;
  rep_level_call_transfer?: InputMaybe<Scalars['Boolean']['input']>;
  reps_can_bulk_unassign?: InputMaybe<Scalars['Boolean']['input']>;
  reps_can_view_all_notes?: InputMaybe<Scalars['Boolean']['input']>;
  require_sale_notes?: InputMaybe<Scalars['Boolean']['input']>;
  resting_after_skipped_CC?: InputMaybe<Scalars['Int']['input']>;
  resting_period?: InputMaybe<Scalars['Int']['input']>;
  resting_period_after_unsuccessful_cold_call?: InputMaybe<Scalars['Float']['input']>;
  resting_period_after_unsuccessful_demo?: InputMaybe<Scalars['Float']['input']>;
  revenue_plan?: InputMaybe<RevenuePlanItemCreateNestedManyWithoutOrganizationInput>;
  Routings?: InputMaybe<RoutingCreateNestedManyWithoutOrganizationInput>;
  sale_field_options?: InputMaybe<SaleFieldOptionCreateNestedManyWithoutOrganizationInput>;
  sale_notification_email_list?: InputMaybe<OrganizationCreatesale_Notification_Email_ListInput>;
  SaleConfig?: InputMaybe<SaleConfigCreateNestedOneWithoutOrganizationInput>;
  SaleConfigPage?: InputMaybe<SaleConfigPageCreateNestedManyWithoutOrganizationInput>;
  SaleConfigSection?: InputMaybe<SaleConfigSectionCreateNestedManyWithoutOrganizationInput>;
  SaleCycles?: InputMaybe<SaleCycleCreateNestedManyWithoutOrganizationInput>;
  SaleItems?: InputMaybe<SaleItemCreateNestedManyWithoutOrganizationInput>;
  sales?: InputMaybe<SaleCreateNestedManyWithoutOrganizationInput>;
  salesforce_domain?: InputMaybe<Scalars['String']['input']>;
  salesforce_last_sync_date?: InputMaybe<Scalars['DateTime']['input']>;
  SalesForceColumn?: InputMaybe<SalesForceColumnCreateNestedManyWithoutOrganizationInput>;
  salesForceData?: InputMaybe<SalesForceDataCreateNestedOneWithoutOrganizationInput>;
  ScheduleItems?: InputMaybe<ScheduleItemCreateNestedManyWithoutOrganizationInput>;
  self_sourcing_rule?: InputMaybe<Scalars['Int']['input']>;
  send_error_log_email?: InputMaybe<Scalars['Boolean']['input']>;
  send_lead_routing_email?: InputMaybe<Scalars['Boolean']['input']>;
  sendgrid_email_domain?: InputMaybe<Scalars['String']['input']>;
  sendgrid_email_domain_id?: InputMaybe<Scalars['Int']['input']>;
  sequence?: InputMaybe<SequenceCreateNestedManyWithoutOrganzationInput>;
  SequenceCriteriaConditions?: InputMaybe<SequenceCriteriaConditionCreateNestedManyWithoutOrganizationInput>;
  SequenceDashboardViews?: InputMaybe<SequenceDashboardViewCreateNestedManyWithoutOrganizationInput>;
  SequenceEntryCriterias?: InputMaybe<SequenceEntryCriteriaCreateNestedManyWithoutOrganizationInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutOrganizationInput>;
  SequenceSteps?: InputMaybe<SequenceStepCreateNestedManyWithoutOrganizationInput>;
  set_goal?: InputMaybe<Scalars['Float']['input']>;
  short_resting?: InputMaybe<Scalars['Int']['input']>;
  show_calendar_options?: InputMaybe<Scalars['Boolean']['input']>;
  show_contract_duration_sales?: InputMaybe<Scalars['Boolean']['input']>;
  show_lead_source_to_rep?: InputMaybe<Scalars['Boolean']['input']>;
  show_nocontact_button?: InputMaybe<Scalars['Boolean']['input']>;
  show_payment_terms_sales?: InputMaybe<Scalars['Boolean']['input']>;
  show_rep_all_leads?: InputMaybe<Scalars['Boolean']['input']>;
  show_rep_my_leads?: InputMaybe<Scalars['Boolean']['input']>;
  show_system_view?: InputMaybe<Scalars['Boolean']['input']>;
  sites?: InputMaybe<SiteCreateNestedManyWithoutOrganizationInput>;
  sms_opt_in_dialog?: InputMaybe<Scalars['String']['input']>;
  SMSItems?: InputMaybe<SmsItemCreateNestedManyWithoutOrganizationInput>;
  SnoozeTrackings?: InputMaybe<SnoozeTrackingCreateNestedManyWithoutOrganizationInput>;
  StepActionLogs?: InputMaybe<StepActionLogCreateNestedManyWithoutOrganizationInput>;
  StepActions?: InputMaybe<StepActionCreateNestedManyWithoutOrganizationInput>;
  StepConditions?: InputMaybe<StepConditionCreateNestedManyWithoutOrganizationInput>;
  teams?: InputMaybe<TeamCreateNestedManyWithoutOrganizationInput>;
  Template?: InputMaybe<TemplateCreateNestedManyWithoutOrganizationInput>;
  TemplateVersions?: InputMaybe<TemplateVersionCreateNestedManyWithoutOrganizationInput>;
  timekit_id?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  transfer_timeout?: InputMaybe<Scalars['Int']['input']>;
  TransferAttempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutOrganizationInput>;
  twilio_numbers?: InputMaybe<TwilioNumberCreateNestedManyWithoutOrganizationInput>;
  TwilioFeedbacks?: InputMaybe<TwilioFeedbackCreateNestedManyWithoutOrganizationInput>;
  TwilioInfo?: InputMaybe<TwilioInfoCreateNestedOneWithoutOrganzationInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  use_revenue_planning?: InputMaybe<Scalars['Boolean']['input']>;
  UserCustomFieldFilters?: InputMaybe<UserCustomFieldFilterCreateNestedManyWithoutOrganizationInput>;
  users?: InputMaybe<UserCreateNestedManyWithoutOrganizationInput>;
  win_rep_day_goal?: InputMaybe<Scalars['Float']['input']>;
};

export type OrganizationCreateWithoutOrganizationPaymentSettingsInput = {
  ActivityMonitorMultiplier?: InputMaybe<ActivityMonitorMultiplierCreateNestedManyWithoutOrganizationInput>;
  algoFactor?: InputMaybe<AlgoFactorCreateNestedManyWithoutOrganizationInput>;
  allow_calendar_invite?: InputMaybe<Scalars['Boolean']['input']>;
  allow_remove_step_action?: InputMaybe<Scalars['Boolean']['input']>;
  allow_snooze_step_action?: InputMaybe<Scalars['Boolean']['input']>;
  always_record?: InputMaybe<Scalars['Boolean']['input']>;
  arv_initial_held_demos_generated_by_sdr_weight?: InputMaybe<Scalars['Float']['input']>;
  AssociationHistories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutOrganizationInput>;
  auto_call_interval?: InputMaybe<Scalars['Int']['input']>;
  auto_dial?: InputMaybe<Scalars['Boolean']['input']>;
  automated_campaigns?: InputMaybe<AutomatedCampaignCreateNestedManyWithoutOrganizationInput>;
  automatic_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  AutoTransferSelections?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutOrganizationInput>;
  Availablities?: InputMaybe<AvailabilityCreateNestedManyWithoutOrganizationInput>;
  AverageOrderValue?: InputMaybe<AverageOrderValueCreateNestedManyWithoutOrganizationInput>;
  best_action_ever_multiplier?: InputMaybe<Scalars['Float']['input']>;
  best_metrics?: InputMaybe<BestMetricRecordCreateNestedManyWithoutOrganizationInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptCreateNestedManyWithoutOrganizationInput>;
  callMeNows?: InputMaybe<CallMeNowCreateNestedManyWithoutOrganizationInput>;
  CallMultiplier?: InputMaybe<CallMultiplierCreateNestedManyWithoutOrganizationInput>;
  CallSharings?: InputMaybe<CallSharingCreateNestedManyWithoutOrganizationInput>;
  can_edit_sdr_transfer_rules?: InputMaybe<Scalars['Boolean']['input']>;
  claim_rule?: InputMaybe<Scalars['Int']['input']>;
  concession?: InputMaybe<ConcessionCreateNestedManyWithoutOrganizationInput>;
  conference_region?: InputMaybe<ConferenceRegion>;
  Conferences?: InputMaybe<ConferenceCreateNestedManyWithoutOrganizationInput>;
  ContactRequests?: InputMaybe<ContactRequestCreateNestedManyWithoutOrganizationInput>;
  Contacts?: InputMaybe<ContactCreateNestedManyWithoutOrganizationInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  custom_fields?: InputMaybe<CustomFieldCreateNestedManyWithoutOrganizationInput>;
  CustomObject?: InputMaybe<CustomObjectCreateNestedManyWithoutOrganizationInput>;
  CustomObjectField?: InputMaybe<CustomObjectFieldCreateNestedManyWithoutOrganizationInput>;
  CustomObjectFieldForeignKey?: InputMaybe<CustomObjectFieldForeignKeyCreateNestedManyWithoutOrganizationInput>;
  CustomObjectRecord?: InputMaybe<CustomObjectRecordCreateNestedManyWithoutOrganizationInput>;
  CustomObjectRow?: InputMaybe<CustomObjectRowCreateNestedManyWithoutOrganizationInput>;
  CustomQueueItems?: InputMaybe<CustomQueueItemCreateNestedManyWithoutOrganizationInput>;
  DashboardColumnOptions?: InputMaybe<DashboardColumnOptionCreateNestedManyWithoutOrganizationInput>;
  DashboardLeadFilters?: InputMaybe<DashboardLeadFilterCreateNestedManyWithoutOrganizationInput>;
  DashboardRepFilters?: InputMaybe<DashboardRepFilterCreateNestedManyWithoutOrganizationInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewCreateNestedManyWithoutOrganizationInput>;
  day_to_close?: InputMaybe<Scalars['Int']['input']>;
  default_demo_time_duration?: InputMaybe<Scalars['Int']['input']>;
  demo_held_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_not_held_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_not_held_call_back_short_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_prep_time_minutes?: InputMaybe<Scalars['Int']['input']>;
  dev_account?: InputMaybe<Scalars['Boolean']['input']>;
  dial_rule?: InputMaybe<Scalars['Int']['input']>;
  DispositionMultiplier?: InputMaybe<DispositionMultiplierCreateNestedManyWithoutOrganizationInput>;
  dispositions?: InputMaybe<DispositionCreateNestedManyWithoutOrganizationInput>;
  double_booking?: InputMaybe<Scalars['Boolean']['input']>;
  dynamic_date_operators?: InputMaybe<Scalars['Boolean']['input']>;
  edge_servers?: InputMaybe<OrganizationCreateedge_ServersInput>;
  email_conflict_notification_email_list?: InputMaybe<OrganizationCreateemail_Conflict_Notification_Email_ListInput>;
  EmailItems?: InputMaybe<EmailItemCreateNestedManyWithoutOrganizationInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordCreateNestedManyWithoutOrganizationInput>;
  EmailThreads?: InputMaybe<EmailThreadCreateNestedManyWithoutOrganizationInput>;
  enable_modified_metrics?: InputMaybe<Scalars['Boolean']['input']>;
  enable_pay_period_view?: InputMaybe<Scalars['Boolean']['input']>;
  enable_time_constraint?: InputMaybe<Scalars['Boolean']['input']>;
  error_log_email_address?: InputMaybe<OrganizationCreateerror_Log_Email_AddressInput>;
  estimated_average_num_of_weekly_overbooked_demo_slots?: InputMaybe<Scalars['Int']['input']>;
  FolderPermissions?: InputMaybe<FolderPermissionCreateNestedManyWithoutOrganizationInput>;
  Folders?: InputMaybe<FolderCreateNestedManyWithoutOrganizationInput>;
  FutureBookingSetting?: InputMaybe<FutureBookingSettingCreateNestedOneWithoutOrganizationInput>;
  GlobalTransferRule?: InputMaybe<GlobalTransferRuleCreateNestedManyWithoutOrganizationInput>;
  held_demos_per_cc_connect?: InputMaybe<Scalars['Float']['input']>;
  helpful_links?: InputMaybe<OrganizationCreatehelpful_LinksInput>;
  HingePointMultiplier?: InputMaybe<HingePointMultiplierCreateNestedManyWithoutOrganizationInput>;
  hold_goal?: InputMaybe<Scalars['Float']['input']>;
  hubspot_credential?: InputMaybe<HubSpotCredentialCreateNestedOneWithoutOrganizationInput>;
  hubspot_last_sync_date?: InputMaybe<Scalars['DateTime']['input']>;
  hubspot_white_list?: InputMaybe<HubSpotWhitelistCreateNestedManyWithoutOrganizationInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  inbound_close_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_dm_connect_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_lt_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_revenue_value_of_sale?: InputMaybe<Scalars['Float']['input']>;
  inbound_scheduled_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_set_rate?: InputMaybe<Scalars['Float']['input']>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventCreateNestedManyWithoutOrganizationInput>;
  InboundConciergeWidget?: InputMaybe<InboundConciergeWidgetCreateNestedManyWithoutOrganizationInput>;
  industry_options?: InputMaybe<IndustryOptionCreateNestedManyWithoutOrganizationInput>;
  initial_sdr_sets_reps_day?: InputMaybe<Scalars['Float']['input']>;
  integration_error_logs?: InputMaybe<IntegrationErrorLogCreateNestedManyWithoutOrganizationInput>;
  integration_mappings?: InputMaybe<IntegrationMappingCreateNestedManyWithoutOrganizationInput>;
  IntegrationMappingCustomObject?: InputMaybe<IntegrationMappingCustomObjectCreateNestedManyWithoutOrganizationInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogCreateNestedManyWithoutOrganizationInput>;
  IntegrationReadOnlyObject?: InputMaybe<IntegrationReadOnlyObjectCreateNestedManyWithoutOrganizationInput>;
  international?: InputMaybe<Scalars['Boolean']['input']>;
  is_email_domain_verified?: InputMaybe<Scalars['Boolean']['input']>;
  is_salesforce_sandbox?: InputMaybe<Scalars['Boolean']['input']>;
  last_lead_value_compute_time?: InputMaybe<Scalars['DateTime']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutOrganizationInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryCreateNestedManyWithoutOrganizationInput>;
  lead_intent?: InputMaybe<LeadIntentCreateNestedManyWithoutOrganizationInput>;
  lead_release_same_rep_delay_rule?: InputMaybe<Scalars['Int']['input']>;
  lead_routing_email_list?: InputMaybe<OrganizationCreatelead_Routing_Email_ListInput>;
  lead_routing_rules?: InputMaybe<RuleCreateNestedManyWithoutOrganizationInput>;
  LeadCustomObjectRowAssociation?: InputMaybe<LeadCustomObjectRowAssociationCreateNestedManyWithoutOrganizationInput>;
  LeadFieldMultiplier?: InputMaybe<LeadFieldMultiplierCreateNestedManyWithoutOrganizationInput>;
  LeadFieldMultiplierCustom?: InputMaybe<LeadFieldMultiplierCustomCreateNestedManyWithoutOrganizationInput>;
  leads?: InputMaybe<LeadCreateNestedManyWithoutOrganizationInput>;
  leadsource_options?: InputMaybe<LeadSourceOptionCreateNestedManyWithoutOrganizationInput>;
  LeadValue?: InputMaybe<LeadValueCreateNestedManyWithoutOrganizationInput>;
  LifeTimeValueMultiplier?: InputMaybe<LifeTimeValueMultiplierCreateNestedManyWithoutOrganizationInput>;
  LiveTransferSetting?: InputMaybe<LiveTransferSettingCreateNestedOneWithoutOrganizationInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusCreateNestedManyWithoutOrganizationInput>;
  ltv_multiplier_custom_fields?: InputMaybe<LifeTimeValueMultiplierCustomCreateNestedManyWithoutOrganizationInput>;
  made_contact_rule?: InputMaybe<Scalars['Int']['input']>;
  make_sale_address_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_business_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_city_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_country_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_first_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_industry_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_last_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_lead_source_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_primary_email_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_primary_phone_number_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_state_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_sub_industry_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_zip_required?: InputMaybe<Scalars['Boolean']['input']>;
  maxium_day_lag_set_to_schedule?: InputMaybe<Scalars['Int']['input']>;
  mrr?: InputMaybe<Scalars['Int']['input']>;
  mrr_label?: InputMaybe<Scalars['String']['input']>;
  mrr_required_on_sale?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  NoteItems?: InputMaybe<NoteItemCreateNestedManyWithoutOrganizationInput>;
  Notifications?: InputMaybe<NotificationCreateNestedManyWithoutOrganizationInput>;
  onboardComplete?: InputMaybe<Scalars['Boolean']['input']>;
  Opportunity?: InputMaybe<SfOpportunityCreateNestedManyWithoutOrganizationInput>;
  organization_holidays?: InputMaybe<OrganizationHolidayCreateNestedManyWithoutOrganizationInput>;
  organization_hours?: InputMaybe<OrganizationHourCreateNestedManyWithoutOrganizationInput>;
  OrganizationPaymentSetting?: InputMaybe<OrganizationPaymentSettingsCreateNestedOneWithoutOrganizationInput>;
  orgCalendarInviteMetadata?: InputMaybe<OrgCalendarInviteMetadataCreateNestedManyWithoutOrganizationInput>;
  OrgDefaultPipelinePerecentValue?: InputMaybe<OrgDefaultPipelinePerecentValueCreateNestedOneWithoutOrganizationInput>;
  OrgHubSpotSync?: InputMaybe<OrgHubSpotSyncCreateNestedOneWithoutOrganizationInput>;
  OrgNoEventDate?: InputMaybe<OrgNoEventDateCreateNestedManyWithoutOrganizationInput>;
  OrgSalesforceSync?: InputMaybe<OrgSalesforceSyncCreateNestedOneWithoutOrganizationInput>;
  OrgTimeslotAvailability?: InputMaybe<OrgTimeslotAvailabilityCreateNestedManyWithoutOrganizationInput>;
  outbound_close_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_dm_connect_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_lt_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_revenue_value_of_sale?: InputMaybe<Scalars['Float']['input']>;
  outbound_scheduled_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_set_rate?: InputMaybe<Scalars['Float']['input']>;
  PandadocData?: InputMaybe<PandadocDataCreateNestedOneWithoutOrganizationInput>;
  PandaDocTemplate?: InputMaybe<PandaDocTemplateCreateNestedManyWithoutOrganizationInput>;
  participant_jitter_buffer_size?: InputMaybe<JitterBufferSize>;
  preferred_goal_setting?: InputMaybe<Goalsetting>;
  prepayment?: InputMaybe<Scalars['Boolean']['input']>;
  ProductChangeLog?: InputMaybe<ProductChangeLogCreateNestedManyWithoutOrganizationInput>;
  products?: InputMaybe<ProductCreateNestedManyWithoutOrganizationInput>;
  re_cold_call_hour_delay?: InputMaybe<Scalars['Float']['input']>;
  recordingStatus?: InputMaybe<RecordingStatus>;
  release_lead_resting?: InputMaybe<Scalars['Int']['input']>;
  rep_claim_limit?: InputMaybe<Scalars['Int']['input']>;
  rep_lead_allotment?: InputMaybe<Scalars['Int']['input']>;
  rep_level_call_transfer?: InputMaybe<Scalars['Boolean']['input']>;
  reps_can_bulk_unassign?: InputMaybe<Scalars['Boolean']['input']>;
  reps_can_view_all_notes?: InputMaybe<Scalars['Boolean']['input']>;
  require_sale_notes?: InputMaybe<Scalars['Boolean']['input']>;
  resting_after_skipped_CC?: InputMaybe<Scalars['Int']['input']>;
  resting_period?: InputMaybe<Scalars['Int']['input']>;
  resting_period_after_unsuccessful_cold_call?: InputMaybe<Scalars['Float']['input']>;
  resting_period_after_unsuccessful_demo?: InputMaybe<Scalars['Float']['input']>;
  revenue_plan?: InputMaybe<RevenuePlanItemCreateNestedManyWithoutOrganizationInput>;
  Routings?: InputMaybe<RoutingCreateNestedManyWithoutOrganizationInput>;
  sale_field_options?: InputMaybe<SaleFieldOptionCreateNestedManyWithoutOrganizationInput>;
  sale_notification_email_list?: InputMaybe<OrganizationCreatesale_Notification_Email_ListInput>;
  SaleConfig?: InputMaybe<SaleConfigCreateNestedOneWithoutOrganizationInput>;
  SaleConfigPage?: InputMaybe<SaleConfigPageCreateNestedManyWithoutOrganizationInput>;
  SaleConfigSection?: InputMaybe<SaleConfigSectionCreateNestedManyWithoutOrganizationInput>;
  SaleCycles?: InputMaybe<SaleCycleCreateNestedManyWithoutOrganizationInput>;
  SaleItems?: InputMaybe<SaleItemCreateNestedManyWithoutOrganizationInput>;
  sales?: InputMaybe<SaleCreateNestedManyWithoutOrganizationInput>;
  salesforce_domain?: InputMaybe<Scalars['String']['input']>;
  salesforce_last_sync_date?: InputMaybe<Scalars['DateTime']['input']>;
  SalesForceColumn?: InputMaybe<SalesForceColumnCreateNestedManyWithoutOrganizationInput>;
  salesForceData?: InputMaybe<SalesForceDataCreateNestedOneWithoutOrganizationInput>;
  ScheduleItems?: InputMaybe<ScheduleItemCreateNestedManyWithoutOrganizationInput>;
  self_sourcing_rule?: InputMaybe<Scalars['Int']['input']>;
  send_error_log_email?: InputMaybe<Scalars['Boolean']['input']>;
  send_lead_routing_email?: InputMaybe<Scalars['Boolean']['input']>;
  sendgrid_email_domain?: InputMaybe<Scalars['String']['input']>;
  sendgrid_email_domain_id?: InputMaybe<Scalars['Int']['input']>;
  sequence?: InputMaybe<SequenceCreateNestedManyWithoutOrganzationInput>;
  SequenceCriteriaConditions?: InputMaybe<SequenceCriteriaConditionCreateNestedManyWithoutOrganizationInput>;
  SequenceDashboardViews?: InputMaybe<SequenceDashboardViewCreateNestedManyWithoutOrganizationInput>;
  SequenceEntryCriterias?: InputMaybe<SequenceEntryCriteriaCreateNestedManyWithoutOrganizationInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutOrganizationInput>;
  SequenceSteps?: InputMaybe<SequenceStepCreateNestedManyWithoutOrganizationInput>;
  set_goal?: InputMaybe<Scalars['Float']['input']>;
  short_resting?: InputMaybe<Scalars['Int']['input']>;
  show_calendar_options?: InputMaybe<Scalars['Boolean']['input']>;
  show_contract_duration_sales?: InputMaybe<Scalars['Boolean']['input']>;
  show_lead_source_to_rep?: InputMaybe<Scalars['Boolean']['input']>;
  show_nocontact_button?: InputMaybe<Scalars['Boolean']['input']>;
  show_payment_terms_sales?: InputMaybe<Scalars['Boolean']['input']>;
  show_rep_all_leads?: InputMaybe<Scalars['Boolean']['input']>;
  show_rep_my_leads?: InputMaybe<Scalars['Boolean']['input']>;
  show_system_view?: InputMaybe<Scalars['Boolean']['input']>;
  sites?: InputMaybe<SiteCreateNestedManyWithoutOrganizationInput>;
  sms_opt_in_dialog?: InputMaybe<Scalars['String']['input']>;
  SMSItems?: InputMaybe<SmsItemCreateNestedManyWithoutOrganizationInput>;
  SnoozeTrackings?: InputMaybe<SnoozeTrackingCreateNestedManyWithoutOrganizationInput>;
  StepActionLogs?: InputMaybe<StepActionLogCreateNestedManyWithoutOrganizationInput>;
  StepActions?: InputMaybe<StepActionCreateNestedManyWithoutOrganizationInput>;
  StepConditions?: InputMaybe<StepConditionCreateNestedManyWithoutOrganizationInput>;
  teams?: InputMaybe<TeamCreateNestedManyWithoutOrganizationInput>;
  Template?: InputMaybe<TemplateCreateNestedManyWithoutOrganizationInput>;
  TemplateVersions?: InputMaybe<TemplateVersionCreateNestedManyWithoutOrganizationInput>;
  timekit_id?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  transfer_timeout?: InputMaybe<Scalars['Int']['input']>;
  TransferAttempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutOrganizationInput>;
  twilio_numbers?: InputMaybe<TwilioNumberCreateNestedManyWithoutOrganizationInput>;
  TwilioFeedbacks?: InputMaybe<TwilioFeedbackCreateNestedManyWithoutOrganizationInput>;
  TwilioInfo?: InputMaybe<TwilioInfoCreateNestedOneWithoutOrganzationInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  use_revenue_planning?: InputMaybe<Scalars['Boolean']['input']>;
  UserCustomFieldFilters?: InputMaybe<UserCustomFieldFilterCreateNestedManyWithoutOrganizationInput>;
  users?: InputMaybe<UserCreateNestedManyWithoutOrganizationInput>;
  win_rep_day_goal?: InputMaybe<Scalars['Float']['input']>;
};

export type OrganizationCreateWithoutProductChangeLogInput = {
  ActivityMonitorMultiplier?: InputMaybe<ActivityMonitorMultiplierCreateNestedManyWithoutOrganizationInput>;
  algoFactor?: InputMaybe<AlgoFactorCreateNestedManyWithoutOrganizationInput>;
  allow_calendar_invite?: InputMaybe<Scalars['Boolean']['input']>;
  allow_remove_step_action?: InputMaybe<Scalars['Boolean']['input']>;
  allow_snooze_step_action?: InputMaybe<Scalars['Boolean']['input']>;
  always_record?: InputMaybe<Scalars['Boolean']['input']>;
  arv_initial_held_demos_generated_by_sdr_weight?: InputMaybe<Scalars['Float']['input']>;
  AssociationHistories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutOrganizationInput>;
  auto_call_interval?: InputMaybe<Scalars['Int']['input']>;
  auto_dial?: InputMaybe<Scalars['Boolean']['input']>;
  automated_campaigns?: InputMaybe<AutomatedCampaignCreateNestedManyWithoutOrganizationInput>;
  automatic_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  AutoTransferSelections?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutOrganizationInput>;
  Availablities?: InputMaybe<AvailabilityCreateNestedManyWithoutOrganizationInput>;
  AverageOrderValue?: InputMaybe<AverageOrderValueCreateNestedManyWithoutOrganizationInput>;
  best_action_ever_multiplier?: InputMaybe<Scalars['Float']['input']>;
  best_metrics?: InputMaybe<BestMetricRecordCreateNestedManyWithoutOrganizationInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptCreateNestedManyWithoutOrganizationInput>;
  callMeNows?: InputMaybe<CallMeNowCreateNestedManyWithoutOrganizationInput>;
  CallMultiplier?: InputMaybe<CallMultiplierCreateNestedManyWithoutOrganizationInput>;
  CallSharings?: InputMaybe<CallSharingCreateNestedManyWithoutOrganizationInput>;
  can_edit_sdr_transfer_rules?: InputMaybe<Scalars['Boolean']['input']>;
  claim_rule?: InputMaybe<Scalars['Int']['input']>;
  concession?: InputMaybe<ConcessionCreateNestedManyWithoutOrganizationInput>;
  conference_region?: InputMaybe<ConferenceRegion>;
  Conferences?: InputMaybe<ConferenceCreateNestedManyWithoutOrganizationInput>;
  ContactRequests?: InputMaybe<ContactRequestCreateNestedManyWithoutOrganizationInput>;
  Contacts?: InputMaybe<ContactCreateNestedManyWithoutOrganizationInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  custom_fields?: InputMaybe<CustomFieldCreateNestedManyWithoutOrganizationInput>;
  CustomObject?: InputMaybe<CustomObjectCreateNestedManyWithoutOrganizationInput>;
  CustomObjectField?: InputMaybe<CustomObjectFieldCreateNestedManyWithoutOrganizationInput>;
  CustomObjectFieldForeignKey?: InputMaybe<CustomObjectFieldForeignKeyCreateNestedManyWithoutOrganizationInput>;
  CustomObjectRecord?: InputMaybe<CustomObjectRecordCreateNestedManyWithoutOrganizationInput>;
  CustomObjectRow?: InputMaybe<CustomObjectRowCreateNestedManyWithoutOrganizationInput>;
  CustomQueueItems?: InputMaybe<CustomQueueItemCreateNestedManyWithoutOrganizationInput>;
  DashboardColumnOptions?: InputMaybe<DashboardColumnOptionCreateNestedManyWithoutOrganizationInput>;
  DashboardLeadFilters?: InputMaybe<DashboardLeadFilterCreateNestedManyWithoutOrganizationInput>;
  DashboardRepFilters?: InputMaybe<DashboardRepFilterCreateNestedManyWithoutOrganizationInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewCreateNestedManyWithoutOrganizationInput>;
  day_to_close?: InputMaybe<Scalars['Int']['input']>;
  default_demo_time_duration?: InputMaybe<Scalars['Int']['input']>;
  demo_held_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_not_held_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_not_held_call_back_short_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_prep_time_minutes?: InputMaybe<Scalars['Int']['input']>;
  dev_account?: InputMaybe<Scalars['Boolean']['input']>;
  dial_rule?: InputMaybe<Scalars['Int']['input']>;
  DispositionMultiplier?: InputMaybe<DispositionMultiplierCreateNestedManyWithoutOrganizationInput>;
  dispositions?: InputMaybe<DispositionCreateNestedManyWithoutOrganizationInput>;
  double_booking?: InputMaybe<Scalars['Boolean']['input']>;
  dynamic_date_operators?: InputMaybe<Scalars['Boolean']['input']>;
  edge_servers?: InputMaybe<OrganizationCreateedge_ServersInput>;
  email_conflict_notification_email_list?: InputMaybe<OrganizationCreateemail_Conflict_Notification_Email_ListInput>;
  EmailItems?: InputMaybe<EmailItemCreateNestedManyWithoutOrganizationInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordCreateNestedManyWithoutOrganizationInput>;
  EmailThreads?: InputMaybe<EmailThreadCreateNestedManyWithoutOrganizationInput>;
  enable_modified_metrics?: InputMaybe<Scalars['Boolean']['input']>;
  enable_pay_period_view?: InputMaybe<Scalars['Boolean']['input']>;
  enable_time_constraint?: InputMaybe<Scalars['Boolean']['input']>;
  error_log_email_address?: InputMaybe<OrganizationCreateerror_Log_Email_AddressInput>;
  estimated_average_num_of_weekly_overbooked_demo_slots?: InputMaybe<Scalars['Int']['input']>;
  FolderPermissions?: InputMaybe<FolderPermissionCreateNestedManyWithoutOrganizationInput>;
  Folders?: InputMaybe<FolderCreateNestedManyWithoutOrganizationInput>;
  FutureBookingSetting?: InputMaybe<FutureBookingSettingCreateNestedOneWithoutOrganizationInput>;
  GlobalTransferRule?: InputMaybe<GlobalTransferRuleCreateNestedManyWithoutOrganizationInput>;
  held_demos_per_cc_connect?: InputMaybe<Scalars['Float']['input']>;
  helpful_links?: InputMaybe<OrganizationCreatehelpful_LinksInput>;
  HingePointMultiplier?: InputMaybe<HingePointMultiplierCreateNestedManyWithoutOrganizationInput>;
  hold_goal?: InputMaybe<Scalars['Float']['input']>;
  hubspot_credential?: InputMaybe<HubSpotCredentialCreateNestedOneWithoutOrganizationInput>;
  hubspot_last_sync_date?: InputMaybe<Scalars['DateTime']['input']>;
  hubspot_white_list?: InputMaybe<HubSpotWhitelistCreateNestedManyWithoutOrganizationInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  inbound_close_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_dm_connect_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_lt_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_revenue_value_of_sale?: InputMaybe<Scalars['Float']['input']>;
  inbound_scheduled_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_set_rate?: InputMaybe<Scalars['Float']['input']>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventCreateNestedManyWithoutOrganizationInput>;
  InboundConciergeWidget?: InputMaybe<InboundConciergeWidgetCreateNestedManyWithoutOrganizationInput>;
  industry_options?: InputMaybe<IndustryOptionCreateNestedManyWithoutOrganizationInput>;
  initial_sdr_sets_reps_day?: InputMaybe<Scalars['Float']['input']>;
  integration_error_logs?: InputMaybe<IntegrationErrorLogCreateNestedManyWithoutOrganizationInput>;
  integration_mappings?: InputMaybe<IntegrationMappingCreateNestedManyWithoutOrganizationInput>;
  IntegrationMappingCustomObject?: InputMaybe<IntegrationMappingCustomObjectCreateNestedManyWithoutOrganizationInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogCreateNestedManyWithoutOrganizationInput>;
  IntegrationReadOnlyObject?: InputMaybe<IntegrationReadOnlyObjectCreateNestedManyWithoutOrganizationInput>;
  international?: InputMaybe<Scalars['Boolean']['input']>;
  is_email_domain_verified?: InputMaybe<Scalars['Boolean']['input']>;
  is_salesforce_sandbox?: InputMaybe<Scalars['Boolean']['input']>;
  last_lead_value_compute_time?: InputMaybe<Scalars['DateTime']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutOrganizationInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryCreateNestedManyWithoutOrganizationInput>;
  lead_intent?: InputMaybe<LeadIntentCreateNestedManyWithoutOrganizationInput>;
  lead_release_same_rep_delay_rule?: InputMaybe<Scalars['Int']['input']>;
  lead_routing_email_list?: InputMaybe<OrganizationCreatelead_Routing_Email_ListInput>;
  lead_routing_rules?: InputMaybe<RuleCreateNestedManyWithoutOrganizationInput>;
  LeadCustomObjectRowAssociation?: InputMaybe<LeadCustomObjectRowAssociationCreateNestedManyWithoutOrganizationInput>;
  LeadFieldMultiplier?: InputMaybe<LeadFieldMultiplierCreateNestedManyWithoutOrganizationInput>;
  LeadFieldMultiplierCustom?: InputMaybe<LeadFieldMultiplierCustomCreateNestedManyWithoutOrganizationInput>;
  leads?: InputMaybe<LeadCreateNestedManyWithoutOrganizationInput>;
  leadsource_options?: InputMaybe<LeadSourceOptionCreateNestedManyWithoutOrganizationInput>;
  LeadValue?: InputMaybe<LeadValueCreateNestedManyWithoutOrganizationInput>;
  LifeTimeValueMultiplier?: InputMaybe<LifeTimeValueMultiplierCreateNestedManyWithoutOrganizationInput>;
  LiveTransferSetting?: InputMaybe<LiveTransferSettingCreateNestedOneWithoutOrganizationInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusCreateNestedManyWithoutOrganizationInput>;
  ltv_multiplier_custom_fields?: InputMaybe<LifeTimeValueMultiplierCustomCreateNestedManyWithoutOrganizationInput>;
  made_contact_rule?: InputMaybe<Scalars['Int']['input']>;
  make_sale_address_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_business_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_city_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_country_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_first_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_industry_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_last_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_lead_source_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_primary_email_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_primary_phone_number_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_state_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_sub_industry_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_zip_required?: InputMaybe<Scalars['Boolean']['input']>;
  maxium_day_lag_set_to_schedule?: InputMaybe<Scalars['Int']['input']>;
  mrr?: InputMaybe<Scalars['Int']['input']>;
  mrr_label?: InputMaybe<Scalars['String']['input']>;
  mrr_required_on_sale?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  NoteItems?: InputMaybe<NoteItemCreateNestedManyWithoutOrganizationInput>;
  Notifications?: InputMaybe<NotificationCreateNestedManyWithoutOrganizationInput>;
  onboardComplete?: InputMaybe<Scalars['Boolean']['input']>;
  Opportunity?: InputMaybe<SfOpportunityCreateNestedManyWithoutOrganizationInput>;
  organization_holidays?: InputMaybe<OrganizationHolidayCreateNestedManyWithoutOrganizationInput>;
  organization_hours?: InputMaybe<OrganizationHourCreateNestedManyWithoutOrganizationInput>;
  OrganizationPaymentSetting?: InputMaybe<OrganizationPaymentSettingsCreateNestedOneWithoutOrganizationInput>;
  OrganizationPaymentSettings?: InputMaybe<OrganizationPaymentSettingsCreateNestedOneWithoutOrganizationsInput>;
  orgCalendarInviteMetadata?: InputMaybe<OrgCalendarInviteMetadataCreateNestedManyWithoutOrganizationInput>;
  OrgDefaultPipelinePerecentValue?: InputMaybe<OrgDefaultPipelinePerecentValueCreateNestedOneWithoutOrganizationInput>;
  OrgHubSpotSync?: InputMaybe<OrgHubSpotSyncCreateNestedOneWithoutOrganizationInput>;
  OrgNoEventDate?: InputMaybe<OrgNoEventDateCreateNestedManyWithoutOrganizationInput>;
  OrgSalesforceSync?: InputMaybe<OrgSalesforceSyncCreateNestedOneWithoutOrganizationInput>;
  OrgTimeslotAvailability?: InputMaybe<OrgTimeslotAvailabilityCreateNestedManyWithoutOrganizationInput>;
  outbound_close_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_dm_connect_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_lt_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_revenue_value_of_sale?: InputMaybe<Scalars['Float']['input']>;
  outbound_scheduled_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_set_rate?: InputMaybe<Scalars['Float']['input']>;
  PandadocData?: InputMaybe<PandadocDataCreateNestedOneWithoutOrganizationInput>;
  PandaDocTemplate?: InputMaybe<PandaDocTemplateCreateNestedManyWithoutOrganizationInput>;
  participant_jitter_buffer_size?: InputMaybe<JitterBufferSize>;
  preferred_goal_setting?: InputMaybe<Goalsetting>;
  prepayment?: InputMaybe<Scalars['Boolean']['input']>;
  products?: InputMaybe<ProductCreateNestedManyWithoutOrganizationInput>;
  re_cold_call_hour_delay?: InputMaybe<Scalars['Float']['input']>;
  recordingStatus?: InputMaybe<RecordingStatus>;
  release_lead_resting?: InputMaybe<Scalars['Int']['input']>;
  rep_claim_limit?: InputMaybe<Scalars['Int']['input']>;
  rep_lead_allotment?: InputMaybe<Scalars['Int']['input']>;
  rep_level_call_transfer?: InputMaybe<Scalars['Boolean']['input']>;
  reps_can_bulk_unassign?: InputMaybe<Scalars['Boolean']['input']>;
  reps_can_view_all_notes?: InputMaybe<Scalars['Boolean']['input']>;
  require_sale_notes?: InputMaybe<Scalars['Boolean']['input']>;
  resting_after_skipped_CC?: InputMaybe<Scalars['Int']['input']>;
  resting_period?: InputMaybe<Scalars['Int']['input']>;
  resting_period_after_unsuccessful_cold_call?: InputMaybe<Scalars['Float']['input']>;
  resting_period_after_unsuccessful_demo?: InputMaybe<Scalars['Float']['input']>;
  revenue_plan?: InputMaybe<RevenuePlanItemCreateNestedManyWithoutOrganizationInput>;
  Routings?: InputMaybe<RoutingCreateNestedManyWithoutOrganizationInput>;
  sale_field_options?: InputMaybe<SaleFieldOptionCreateNestedManyWithoutOrganizationInput>;
  sale_notification_email_list?: InputMaybe<OrganizationCreatesale_Notification_Email_ListInput>;
  SaleConfig?: InputMaybe<SaleConfigCreateNestedOneWithoutOrganizationInput>;
  SaleConfigPage?: InputMaybe<SaleConfigPageCreateNestedManyWithoutOrganizationInput>;
  SaleConfigSection?: InputMaybe<SaleConfigSectionCreateNestedManyWithoutOrganizationInput>;
  SaleCycles?: InputMaybe<SaleCycleCreateNestedManyWithoutOrganizationInput>;
  SaleItems?: InputMaybe<SaleItemCreateNestedManyWithoutOrganizationInput>;
  sales?: InputMaybe<SaleCreateNestedManyWithoutOrganizationInput>;
  salesforce_domain?: InputMaybe<Scalars['String']['input']>;
  salesforce_last_sync_date?: InputMaybe<Scalars['DateTime']['input']>;
  SalesForceColumn?: InputMaybe<SalesForceColumnCreateNestedManyWithoutOrganizationInput>;
  salesForceData?: InputMaybe<SalesForceDataCreateNestedOneWithoutOrganizationInput>;
  ScheduleItems?: InputMaybe<ScheduleItemCreateNestedManyWithoutOrganizationInput>;
  self_sourcing_rule?: InputMaybe<Scalars['Int']['input']>;
  send_error_log_email?: InputMaybe<Scalars['Boolean']['input']>;
  send_lead_routing_email?: InputMaybe<Scalars['Boolean']['input']>;
  sendgrid_email_domain?: InputMaybe<Scalars['String']['input']>;
  sendgrid_email_domain_id?: InputMaybe<Scalars['Int']['input']>;
  sequence?: InputMaybe<SequenceCreateNestedManyWithoutOrganzationInput>;
  SequenceCriteriaConditions?: InputMaybe<SequenceCriteriaConditionCreateNestedManyWithoutOrganizationInput>;
  SequenceDashboardViews?: InputMaybe<SequenceDashboardViewCreateNestedManyWithoutOrganizationInput>;
  SequenceEntryCriterias?: InputMaybe<SequenceEntryCriteriaCreateNestedManyWithoutOrganizationInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutOrganizationInput>;
  SequenceSteps?: InputMaybe<SequenceStepCreateNestedManyWithoutOrganizationInput>;
  set_goal?: InputMaybe<Scalars['Float']['input']>;
  short_resting?: InputMaybe<Scalars['Int']['input']>;
  show_calendar_options?: InputMaybe<Scalars['Boolean']['input']>;
  show_contract_duration_sales?: InputMaybe<Scalars['Boolean']['input']>;
  show_lead_source_to_rep?: InputMaybe<Scalars['Boolean']['input']>;
  show_nocontact_button?: InputMaybe<Scalars['Boolean']['input']>;
  show_payment_terms_sales?: InputMaybe<Scalars['Boolean']['input']>;
  show_rep_all_leads?: InputMaybe<Scalars['Boolean']['input']>;
  show_rep_my_leads?: InputMaybe<Scalars['Boolean']['input']>;
  show_system_view?: InputMaybe<Scalars['Boolean']['input']>;
  sites?: InputMaybe<SiteCreateNestedManyWithoutOrganizationInput>;
  sms_opt_in_dialog?: InputMaybe<Scalars['String']['input']>;
  SMSItems?: InputMaybe<SmsItemCreateNestedManyWithoutOrganizationInput>;
  SnoozeTrackings?: InputMaybe<SnoozeTrackingCreateNestedManyWithoutOrganizationInput>;
  StepActionLogs?: InputMaybe<StepActionLogCreateNestedManyWithoutOrganizationInput>;
  StepActions?: InputMaybe<StepActionCreateNestedManyWithoutOrganizationInput>;
  StepConditions?: InputMaybe<StepConditionCreateNestedManyWithoutOrganizationInput>;
  teams?: InputMaybe<TeamCreateNestedManyWithoutOrganizationInput>;
  Template?: InputMaybe<TemplateCreateNestedManyWithoutOrganizationInput>;
  TemplateVersions?: InputMaybe<TemplateVersionCreateNestedManyWithoutOrganizationInput>;
  timekit_id?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  transfer_timeout?: InputMaybe<Scalars['Int']['input']>;
  TransferAttempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutOrganizationInput>;
  twilio_numbers?: InputMaybe<TwilioNumberCreateNestedManyWithoutOrganizationInput>;
  TwilioFeedbacks?: InputMaybe<TwilioFeedbackCreateNestedManyWithoutOrganizationInput>;
  TwilioInfo?: InputMaybe<TwilioInfoCreateNestedOneWithoutOrganzationInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  use_revenue_planning?: InputMaybe<Scalars['Boolean']['input']>;
  UserCustomFieldFilters?: InputMaybe<UserCustomFieldFilterCreateNestedManyWithoutOrganizationInput>;
  users?: InputMaybe<UserCreateNestedManyWithoutOrganizationInput>;
  win_rep_day_goal?: InputMaybe<Scalars['Float']['input']>;
};

export type OrganizationCreateWithoutProductsInput = {
  ActivityMonitorMultiplier?: InputMaybe<ActivityMonitorMultiplierCreateNestedManyWithoutOrganizationInput>;
  algoFactor?: InputMaybe<AlgoFactorCreateNestedManyWithoutOrganizationInput>;
  allow_calendar_invite?: InputMaybe<Scalars['Boolean']['input']>;
  allow_remove_step_action?: InputMaybe<Scalars['Boolean']['input']>;
  allow_snooze_step_action?: InputMaybe<Scalars['Boolean']['input']>;
  always_record?: InputMaybe<Scalars['Boolean']['input']>;
  arv_initial_held_demos_generated_by_sdr_weight?: InputMaybe<Scalars['Float']['input']>;
  AssociationHistories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutOrganizationInput>;
  auto_call_interval?: InputMaybe<Scalars['Int']['input']>;
  auto_dial?: InputMaybe<Scalars['Boolean']['input']>;
  automated_campaigns?: InputMaybe<AutomatedCampaignCreateNestedManyWithoutOrganizationInput>;
  automatic_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  AutoTransferSelections?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutOrganizationInput>;
  Availablities?: InputMaybe<AvailabilityCreateNestedManyWithoutOrganizationInput>;
  AverageOrderValue?: InputMaybe<AverageOrderValueCreateNestedManyWithoutOrganizationInput>;
  best_action_ever_multiplier?: InputMaybe<Scalars['Float']['input']>;
  best_metrics?: InputMaybe<BestMetricRecordCreateNestedManyWithoutOrganizationInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptCreateNestedManyWithoutOrganizationInput>;
  callMeNows?: InputMaybe<CallMeNowCreateNestedManyWithoutOrganizationInput>;
  CallMultiplier?: InputMaybe<CallMultiplierCreateNestedManyWithoutOrganizationInput>;
  CallSharings?: InputMaybe<CallSharingCreateNestedManyWithoutOrganizationInput>;
  can_edit_sdr_transfer_rules?: InputMaybe<Scalars['Boolean']['input']>;
  claim_rule?: InputMaybe<Scalars['Int']['input']>;
  concession?: InputMaybe<ConcessionCreateNestedManyWithoutOrganizationInput>;
  conference_region?: InputMaybe<ConferenceRegion>;
  Conferences?: InputMaybe<ConferenceCreateNestedManyWithoutOrganizationInput>;
  ContactRequests?: InputMaybe<ContactRequestCreateNestedManyWithoutOrganizationInput>;
  Contacts?: InputMaybe<ContactCreateNestedManyWithoutOrganizationInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  custom_fields?: InputMaybe<CustomFieldCreateNestedManyWithoutOrganizationInput>;
  CustomObject?: InputMaybe<CustomObjectCreateNestedManyWithoutOrganizationInput>;
  CustomObjectField?: InputMaybe<CustomObjectFieldCreateNestedManyWithoutOrganizationInput>;
  CustomObjectFieldForeignKey?: InputMaybe<CustomObjectFieldForeignKeyCreateNestedManyWithoutOrganizationInput>;
  CustomObjectRecord?: InputMaybe<CustomObjectRecordCreateNestedManyWithoutOrganizationInput>;
  CustomObjectRow?: InputMaybe<CustomObjectRowCreateNestedManyWithoutOrganizationInput>;
  CustomQueueItems?: InputMaybe<CustomQueueItemCreateNestedManyWithoutOrganizationInput>;
  DashboardColumnOptions?: InputMaybe<DashboardColumnOptionCreateNestedManyWithoutOrganizationInput>;
  DashboardLeadFilters?: InputMaybe<DashboardLeadFilterCreateNestedManyWithoutOrganizationInput>;
  DashboardRepFilters?: InputMaybe<DashboardRepFilterCreateNestedManyWithoutOrganizationInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewCreateNestedManyWithoutOrganizationInput>;
  day_to_close?: InputMaybe<Scalars['Int']['input']>;
  default_demo_time_duration?: InputMaybe<Scalars['Int']['input']>;
  demo_held_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_not_held_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_not_held_call_back_short_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_prep_time_minutes?: InputMaybe<Scalars['Int']['input']>;
  dev_account?: InputMaybe<Scalars['Boolean']['input']>;
  dial_rule?: InputMaybe<Scalars['Int']['input']>;
  DispositionMultiplier?: InputMaybe<DispositionMultiplierCreateNestedManyWithoutOrganizationInput>;
  dispositions?: InputMaybe<DispositionCreateNestedManyWithoutOrganizationInput>;
  double_booking?: InputMaybe<Scalars['Boolean']['input']>;
  dynamic_date_operators?: InputMaybe<Scalars['Boolean']['input']>;
  edge_servers?: InputMaybe<OrganizationCreateedge_ServersInput>;
  email_conflict_notification_email_list?: InputMaybe<OrganizationCreateemail_Conflict_Notification_Email_ListInput>;
  EmailItems?: InputMaybe<EmailItemCreateNestedManyWithoutOrganizationInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordCreateNestedManyWithoutOrganizationInput>;
  EmailThreads?: InputMaybe<EmailThreadCreateNestedManyWithoutOrganizationInput>;
  enable_modified_metrics?: InputMaybe<Scalars['Boolean']['input']>;
  enable_pay_period_view?: InputMaybe<Scalars['Boolean']['input']>;
  enable_time_constraint?: InputMaybe<Scalars['Boolean']['input']>;
  error_log_email_address?: InputMaybe<OrganizationCreateerror_Log_Email_AddressInput>;
  estimated_average_num_of_weekly_overbooked_demo_slots?: InputMaybe<Scalars['Int']['input']>;
  FolderPermissions?: InputMaybe<FolderPermissionCreateNestedManyWithoutOrganizationInput>;
  Folders?: InputMaybe<FolderCreateNestedManyWithoutOrganizationInput>;
  FutureBookingSetting?: InputMaybe<FutureBookingSettingCreateNestedOneWithoutOrganizationInput>;
  GlobalTransferRule?: InputMaybe<GlobalTransferRuleCreateNestedManyWithoutOrganizationInput>;
  held_demos_per_cc_connect?: InputMaybe<Scalars['Float']['input']>;
  helpful_links?: InputMaybe<OrganizationCreatehelpful_LinksInput>;
  HingePointMultiplier?: InputMaybe<HingePointMultiplierCreateNestedManyWithoutOrganizationInput>;
  hold_goal?: InputMaybe<Scalars['Float']['input']>;
  hubspot_credential?: InputMaybe<HubSpotCredentialCreateNestedOneWithoutOrganizationInput>;
  hubspot_last_sync_date?: InputMaybe<Scalars['DateTime']['input']>;
  hubspot_white_list?: InputMaybe<HubSpotWhitelistCreateNestedManyWithoutOrganizationInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  inbound_close_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_dm_connect_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_lt_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_revenue_value_of_sale?: InputMaybe<Scalars['Float']['input']>;
  inbound_scheduled_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_set_rate?: InputMaybe<Scalars['Float']['input']>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventCreateNestedManyWithoutOrganizationInput>;
  InboundConciergeWidget?: InputMaybe<InboundConciergeWidgetCreateNestedManyWithoutOrganizationInput>;
  industry_options?: InputMaybe<IndustryOptionCreateNestedManyWithoutOrganizationInput>;
  initial_sdr_sets_reps_day?: InputMaybe<Scalars['Float']['input']>;
  integration_error_logs?: InputMaybe<IntegrationErrorLogCreateNestedManyWithoutOrganizationInput>;
  integration_mappings?: InputMaybe<IntegrationMappingCreateNestedManyWithoutOrganizationInput>;
  IntegrationMappingCustomObject?: InputMaybe<IntegrationMappingCustomObjectCreateNestedManyWithoutOrganizationInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogCreateNestedManyWithoutOrganizationInput>;
  IntegrationReadOnlyObject?: InputMaybe<IntegrationReadOnlyObjectCreateNestedManyWithoutOrganizationInput>;
  international?: InputMaybe<Scalars['Boolean']['input']>;
  is_email_domain_verified?: InputMaybe<Scalars['Boolean']['input']>;
  is_salesforce_sandbox?: InputMaybe<Scalars['Boolean']['input']>;
  last_lead_value_compute_time?: InputMaybe<Scalars['DateTime']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutOrganizationInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryCreateNestedManyWithoutOrganizationInput>;
  lead_intent?: InputMaybe<LeadIntentCreateNestedManyWithoutOrganizationInput>;
  lead_release_same_rep_delay_rule?: InputMaybe<Scalars['Int']['input']>;
  lead_routing_email_list?: InputMaybe<OrganizationCreatelead_Routing_Email_ListInput>;
  lead_routing_rules?: InputMaybe<RuleCreateNestedManyWithoutOrganizationInput>;
  LeadCustomObjectRowAssociation?: InputMaybe<LeadCustomObjectRowAssociationCreateNestedManyWithoutOrganizationInput>;
  LeadFieldMultiplier?: InputMaybe<LeadFieldMultiplierCreateNestedManyWithoutOrganizationInput>;
  LeadFieldMultiplierCustom?: InputMaybe<LeadFieldMultiplierCustomCreateNestedManyWithoutOrganizationInput>;
  leads?: InputMaybe<LeadCreateNestedManyWithoutOrganizationInput>;
  leadsource_options?: InputMaybe<LeadSourceOptionCreateNestedManyWithoutOrganizationInput>;
  LeadValue?: InputMaybe<LeadValueCreateNestedManyWithoutOrganizationInput>;
  LifeTimeValueMultiplier?: InputMaybe<LifeTimeValueMultiplierCreateNestedManyWithoutOrganizationInput>;
  LiveTransferSetting?: InputMaybe<LiveTransferSettingCreateNestedOneWithoutOrganizationInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusCreateNestedManyWithoutOrganizationInput>;
  ltv_multiplier_custom_fields?: InputMaybe<LifeTimeValueMultiplierCustomCreateNestedManyWithoutOrganizationInput>;
  made_contact_rule?: InputMaybe<Scalars['Int']['input']>;
  make_sale_address_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_business_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_city_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_country_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_first_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_industry_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_last_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_lead_source_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_primary_email_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_primary_phone_number_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_state_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_sub_industry_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_zip_required?: InputMaybe<Scalars['Boolean']['input']>;
  maxium_day_lag_set_to_schedule?: InputMaybe<Scalars['Int']['input']>;
  mrr?: InputMaybe<Scalars['Int']['input']>;
  mrr_label?: InputMaybe<Scalars['String']['input']>;
  mrr_required_on_sale?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  NoteItems?: InputMaybe<NoteItemCreateNestedManyWithoutOrganizationInput>;
  Notifications?: InputMaybe<NotificationCreateNestedManyWithoutOrganizationInput>;
  onboardComplete?: InputMaybe<Scalars['Boolean']['input']>;
  Opportunity?: InputMaybe<SfOpportunityCreateNestedManyWithoutOrganizationInput>;
  organization_holidays?: InputMaybe<OrganizationHolidayCreateNestedManyWithoutOrganizationInput>;
  organization_hours?: InputMaybe<OrganizationHourCreateNestedManyWithoutOrganizationInput>;
  OrganizationPaymentSetting?: InputMaybe<OrganizationPaymentSettingsCreateNestedOneWithoutOrganizationInput>;
  OrganizationPaymentSettings?: InputMaybe<OrganizationPaymentSettingsCreateNestedOneWithoutOrganizationsInput>;
  orgCalendarInviteMetadata?: InputMaybe<OrgCalendarInviteMetadataCreateNestedManyWithoutOrganizationInput>;
  OrgDefaultPipelinePerecentValue?: InputMaybe<OrgDefaultPipelinePerecentValueCreateNestedOneWithoutOrganizationInput>;
  OrgHubSpotSync?: InputMaybe<OrgHubSpotSyncCreateNestedOneWithoutOrganizationInput>;
  OrgNoEventDate?: InputMaybe<OrgNoEventDateCreateNestedManyWithoutOrganizationInput>;
  OrgSalesforceSync?: InputMaybe<OrgSalesforceSyncCreateNestedOneWithoutOrganizationInput>;
  OrgTimeslotAvailability?: InputMaybe<OrgTimeslotAvailabilityCreateNestedManyWithoutOrganizationInput>;
  outbound_close_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_dm_connect_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_lt_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_revenue_value_of_sale?: InputMaybe<Scalars['Float']['input']>;
  outbound_scheduled_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_set_rate?: InputMaybe<Scalars['Float']['input']>;
  PandadocData?: InputMaybe<PandadocDataCreateNestedOneWithoutOrganizationInput>;
  PandaDocTemplate?: InputMaybe<PandaDocTemplateCreateNestedManyWithoutOrganizationInput>;
  participant_jitter_buffer_size?: InputMaybe<JitterBufferSize>;
  preferred_goal_setting?: InputMaybe<Goalsetting>;
  prepayment?: InputMaybe<Scalars['Boolean']['input']>;
  ProductChangeLog?: InputMaybe<ProductChangeLogCreateNestedManyWithoutOrganizationInput>;
  re_cold_call_hour_delay?: InputMaybe<Scalars['Float']['input']>;
  recordingStatus?: InputMaybe<RecordingStatus>;
  release_lead_resting?: InputMaybe<Scalars['Int']['input']>;
  rep_claim_limit?: InputMaybe<Scalars['Int']['input']>;
  rep_lead_allotment?: InputMaybe<Scalars['Int']['input']>;
  rep_level_call_transfer?: InputMaybe<Scalars['Boolean']['input']>;
  reps_can_bulk_unassign?: InputMaybe<Scalars['Boolean']['input']>;
  reps_can_view_all_notes?: InputMaybe<Scalars['Boolean']['input']>;
  require_sale_notes?: InputMaybe<Scalars['Boolean']['input']>;
  resting_after_skipped_CC?: InputMaybe<Scalars['Int']['input']>;
  resting_period?: InputMaybe<Scalars['Int']['input']>;
  resting_period_after_unsuccessful_cold_call?: InputMaybe<Scalars['Float']['input']>;
  resting_period_after_unsuccessful_demo?: InputMaybe<Scalars['Float']['input']>;
  revenue_plan?: InputMaybe<RevenuePlanItemCreateNestedManyWithoutOrganizationInput>;
  Routings?: InputMaybe<RoutingCreateNestedManyWithoutOrganizationInput>;
  sale_field_options?: InputMaybe<SaleFieldOptionCreateNestedManyWithoutOrganizationInput>;
  sale_notification_email_list?: InputMaybe<OrganizationCreatesale_Notification_Email_ListInput>;
  SaleConfig?: InputMaybe<SaleConfigCreateNestedOneWithoutOrganizationInput>;
  SaleConfigPage?: InputMaybe<SaleConfigPageCreateNestedManyWithoutOrganizationInput>;
  SaleConfigSection?: InputMaybe<SaleConfigSectionCreateNestedManyWithoutOrganizationInput>;
  SaleCycles?: InputMaybe<SaleCycleCreateNestedManyWithoutOrganizationInput>;
  SaleItems?: InputMaybe<SaleItemCreateNestedManyWithoutOrganizationInput>;
  sales?: InputMaybe<SaleCreateNestedManyWithoutOrganizationInput>;
  salesforce_domain?: InputMaybe<Scalars['String']['input']>;
  salesforce_last_sync_date?: InputMaybe<Scalars['DateTime']['input']>;
  SalesForceColumn?: InputMaybe<SalesForceColumnCreateNestedManyWithoutOrganizationInput>;
  salesForceData?: InputMaybe<SalesForceDataCreateNestedOneWithoutOrganizationInput>;
  ScheduleItems?: InputMaybe<ScheduleItemCreateNestedManyWithoutOrganizationInput>;
  self_sourcing_rule?: InputMaybe<Scalars['Int']['input']>;
  send_error_log_email?: InputMaybe<Scalars['Boolean']['input']>;
  send_lead_routing_email?: InputMaybe<Scalars['Boolean']['input']>;
  sendgrid_email_domain?: InputMaybe<Scalars['String']['input']>;
  sendgrid_email_domain_id?: InputMaybe<Scalars['Int']['input']>;
  sequence?: InputMaybe<SequenceCreateNestedManyWithoutOrganzationInput>;
  SequenceCriteriaConditions?: InputMaybe<SequenceCriteriaConditionCreateNestedManyWithoutOrganizationInput>;
  SequenceDashboardViews?: InputMaybe<SequenceDashboardViewCreateNestedManyWithoutOrganizationInput>;
  SequenceEntryCriterias?: InputMaybe<SequenceEntryCriteriaCreateNestedManyWithoutOrganizationInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutOrganizationInput>;
  SequenceSteps?: InputMaybe<SequenceStepCreateNestedManyWithoutOrganizationInput>;
  set_goal?: InputMaybe<Scalars['Float']['input']>;
  short_resting?: InputMaybe<Scalars['Int']['input']>;
  show_calendar_options?: InputMaybe<Scalars['Boolean']['input']>;
  show_contract_duration_sales?: InputMaybe<Scalars['Boolean']['input']>;
  show_lead_source_to_rep?: InputMaybe<Scalars['Boolean']['input']>;
  show_nocontact_button?: InputMaybe<Scalars['Boolean']['input']>;
  show_payment_terms_sales?: InputMaybe<Scalars['Boolean']['input']>;
  show_rep_all_leads?: InputMaybe<Scalars['Boolean']['input']>;
  show_rep_my_leads?: InputMaybe<Scalars['Boolean']['input']>;
  show_system_view?: InputMaybe<Scalars['Boolean']['input']>;
  sites?: InputMaybe<SiteCreateNestedManyWithoutOrganizationInput>;
  sms_opt_in_dialog?: InputMaybe<Scalars['String']['input']>;
  SMSItems?: InputMaybe<SmsItemCreateNestedManyWithoutOrganizationInput>;
  SnoozeTrackings?: InputMaybe<SnoozeTrackingCreateNestedManyWithoutOrganizationInput>;
  StepActionLogs?: InputMaybe<StepActionLogCreateNestedManyWithoutOrganizationInput>;
  StepActions?: InputMaybe<StepActionCreateNestedManyWithoutOrganizationInput>;
  StepConditions?: InputMaybe<StepConditionCreateNestedManyWithoutOrganizationInput>;
  teams?: InputMaybe<TeamCreateNestedManyWithoutOrganizationInput>;
  Template?: InputMaybe<TemplateCreateNestedManyWithoutOrganizationInput>;
  TemplateVersions?: InputMaybe<TemplateVersionCreateNestedManyWithoutOrganizationInput>;
  timekit_id?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  transfer_timeout?: InputMaybe<Scalars['Int']['input']>;
  TransferAttempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutOrganizationInput>;
  twilio_numbers?: InputMaybe<TwilioNumberCreateNestedManyWithoutOrganizationInput>;
  TwilioFeedbacks?: InputMaybe<TwilioFeedbackCreateNestedManyWithoutOrganizationInput>;
  TwilioInfo?: InputMaybe<TwilioInfoCreateNestedOneWithoutOrganzationInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  use_revenue_planning?: InputMaybe<Scalars['Boolean']['input']>;
  UserCustomFieldFilters?: InputMaybe<UserCustomFieldFilterCreateNestedManyWithoutOrganizationInput>;
  users?: InputMaybe<UserCreateNestedManyWithoutOrganizationInput>;
  win_rep_day_goal?: InputMaybe<Scalars['Float']['input']>;
};

export type OrganizationCreateWithoutRoutingsInput = {
  ActivityMonitorMultiplier?: InputMaybe<ActivityMonitorMultiplierCreateNestedManyWithoutOrganizationInput>;
  algoFactor?: InputMaybe<AlgoFactorCreateNestedManyWithoutOrganizationInput>;
  allow_calendar_invite?: InputMaybe<Scalars['Boolean']['input']>;
  allow_remove_step_action?: InputMaybe<Scalars['Boolean']['input']>;
  allow_snooze_step_action?: InputMaybe<Scalars['Boolean']['input']>;
  always_record?: InputMaybe<Scalars['Boolean']['input']>;
  arv_initial_held_demos_generated_by_sdr_weight?: InputMaybe<Scalars['Float']['input']>;
  AssociationHistories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutOrganizationInput>;
  auto_call_interval?: InputMaybe<Scalars['Int']['input']>;
  auto_dial?: InputMaybe<Scalars['Boolean']['input']>;
  automated_campaigns?: InputMaybe<AutomatedCampaignCreateNestedManyWithoutOrganizationInput>;
  automatic_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  AutoTransferSelections?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutOrganizationInput>;
  Availablities?: InputMaybe<AvailabilityCreateNestedManyWithoutOrganizationInput>;
  AverageOrderValue?: InputMaybe<AverageOrderValueCreateNestedManyWithoutOrganizationInput>;
  best_action_ever_multiplier?: InputMaybe<Scalars['Float']['input']>;
  best_metrics?: InputMaybe<BestMetricRecordCreateNestedManyWithoutOrganizationInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptCreateNestedManyWithoutOrganizationInput>;
  callMeNows?: InputMaybe<CallMeNowCreateNestedManyWithoutOrganizationInput>;
  CallMultiplier?: InputMaybe<CallMultiplierCreateNestedManyWithoutOrganizationInput>;
  CallSharings?: InputMaybe<CallSharingCreateNestedManyWithoutOrganizationInput>;
  can_edit_sdr_transfer_rules?: InputMaybe<Scalars['Boolean']['input']>;
  claim_rule?: InputMaybe<Scalars['Int']['input']>;
  concession?: InputMaybe<ConcessionCreateNestedManyWithoutOrganizationInput>;
  conference_region?: InputMaybe<ConferenceRegion>;
  Conferences?: InputMaybe<ConferenceCreateNestedManyWithoutOrganizationInput>;
  ContactRequests?: InputMaybe<ContactRequestCreateNestedManyWithoutOrganizationInput>;
  Contacts?: InputMaybe<ContactCreateNestedManyWithoutOrganizationInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  custom_fields?: InputMaybe<CustomFieldCreateNestedManyWithoutOrganizationInput>;
  CustomObject?: InputMaybe<CustomObjectCreateNestedManyWithoutOrganizationInput>;
  CustomObjectField?: InputMaybe<CustomObjectFieldCreateNestedManyWithoutOrganizationInput>;
  CustomObjectFieldForeignKey?: InputMaybe<CustomObjectFieldForeignKeyCreateNestedManyWithoutOrganizationInput>;
  CustomObjectRecord?: InputMaybe<CustomObjectRecordCreateNestedManyWithoutOrganizationInput>;
  CustomObjectRow?: InputMaybe<CustomObjectRowCreateNestedManyWithoutOrganizationInput>;
  CustomQueueItems?: InputMaybe<CustomQueueItemCreateNestedManyWithoutOrganizationInput>;
  DashboardColumnOptions?: InputMaybe<DashboardColumnOptionCreateNestedManyWithoutOrganizationInput>;
  DashboardLeadFilters?: InputMaybe<DashboardLeadFilterCreateNestedManyWithoutOrganizationInput>;
  DashboardRepFilters?: InputMaybe<DashboardRepFilterCreateNestedManyWithoutOrganizationInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewCreateNestedManyWithoutOrganizationInput>;
  day_to_close?: InputMaybe<Scalars['Int']['input']>;
  default_demo_time_duration?: InputMaybe<Scalars['Int']['input']>;
  demo_held_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_not_held_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_not_held_call_back_short_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_prep_time_minutes?: InputMaybe<Scalars['Int']['input']>;
  dev_account?: InputMaybe<Scalars['Boolean']['input']>;
  dial_rule?: InputMaybe<Scalars['Int']['input']>;
  DispositionMultiplier?: InputMaybe<DispositionMultiplierCreateNestedManyWithoutOrganizationInput>;
  dispositions?: InputMaybe<DispositionCreateNestedManyWithoutOrganizationInput>;
  double_booking?: InputMaybe<Scalars['Boolean']['input']>;
  dynamic_date_operators?: InputMaybe<Scalars['Boolean']['input']>;
  edge_servers?: InputMaybe<OrganizationCreateedge_ServersInput>;
  email_conflict_notification_email_list?: InputMaybe<OrganizationCreateemail_Conflict_Notification_Email_ListInput>;
  EmailItems?: InputMaybe<EmailItemCreateNestedManyWithoutOrganizationInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordCreateNestedManyWithoutOrganizationInput>;
  EmailThreads?: InputMaybe<EmailThreadCreateNestedManyWithoutOrganizationInput>;
  enable_modified_metrics?: InputMaybe<Scalars['Boolean']['input']>;
  enable_pay_period_view?: InputMaybe<Scalars['Boolean']['input']>;
  enable_time_constraint?: InputMaybe<Scalars['Boolean']['input']>;
  error_log_email_address?: InputMaybe<OrganizationCreateerror_Log_Email_AddressInput>;
  estimated_average_num_of_weekly_overbooked_demo_slots?: InputMaybe<Scalars['Int']['input']>;
  FolderPermissions?: InputMaybe<FolderPermissionCreateNestedManyWithoutOrganizationInput>;
  Folders?: InputMaybe<FolderCreateNestedManyWithoutOrganizationInput>;
  FutureBookingSetting?: InputMaybe<FutureBookingSettingCreateNestedOneWithoutOrganizationInput>;
  GlobalTransferRule?: InputMaybe<GlobalTransferRuleCreateNestedManyWithoutOrganizationInput>;
  held_demos_per_cc_connect?: InputMaybe<Scalars['Float']['input']>;
  helpful_links?: InputMaybe<OrganizationCreatehelpful_LinksInput>;
  HingePointMultiplier?: InputMaybe<HingePointMultiplierCreateNestedManyWithoutOrganizationInput>;
  hold_goal?: InputMaybe<Scalars['Float']['input']>;
  hubspot_credential?: InputMaybe<HubSpotCredentialCreateNestedOneWithoutOrganizationInput>;
  hubspot_last_sync_date?: InputMaybe<Scalars['DateTime']['input']>;
  hubspot_white_list?: InputMaybe<HubSpotWhitelistCreateNestedManyWithoutOrganizationInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  inbound_close_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_dm_connect_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_lt_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_revenue_value_of_sale?: InputMaybe<Scalars['Float']['input']>;
  inbound_scheduled_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_set_rate?: InputMaybe<Scalars['Float']['input']>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventCreateNestedManyWithoutOrganizationInput>;
  InboundConciergeWidget?: InputMaybe<InboundConciergeWidgetCreateNestedManyWithoutOrganizationInput>;
  industry_options?: InputMaybe<IndustryOptionCreateNestedManyWithoutOrganizationInput>;
  initial_sdr_sets_reps_day?: InputMaybe<Scalars['Float']['input']>;
  integration_error_logs?: InputMaybe<IntegrationErrorLogCreateNestedManyWithoutOrganizationInput>;
  integration_mappings?: InputMaybe<IntegrationMappingCreateNestedManyWithoutOrganizationInput>;
  IntegrationMappingCustomObject?: InputMaybe<IntegrationMappingCustomObjectCreateNestedManyWithoutOrganizationInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogCreateNestedManyWithoutOrganizationInput>;
  IntegrationReadOnlyObject?: InputMaybe<IntegrationReadOnlyObjectCreateNestedManyWithoutOrganizationInput>;
  international?: InputMaybe<Scalars['Boolean']['input']>;
  is_email_domain_verified?: InputMaybe<Scalars['Boolean']['input']>;
  is_salesforce_sandbox?: InputMaybe<Scalars['Boolean']['input']>;
  last_lead_value_compute_time?: InputMaybe<Scalars['DateTime']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutOrganizationInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryCreateNestedManyWithoutOrganizationInput>;
  lead_intent?: InputMaybe<LeadIntentCreateNestedManyWithoutOrganizationInput>;
  lead_release_same_rep_delay_rule?: InputMaybe<Scalars['Int']['input']>;
  lead_routing_email_list?: InputMaybe<OrganizationCreatelead_Routing_Email_ListInput>;
  lead_routing_rules?: InputMaybe<RuleCreateNestedManyWithoutOrganizationInput>;
  LeadCustomObjectRowAssociation?: InputMaybe<LeadCustomObjectRowAssociationCreateNestedManyWithoutOrganizationInput>;
  LeadFieldMultiplier?: InputMaybe<LeadFieldMultiplierCreateNestedManyWithoutOrganizationInput>;
  LeadFieldMultiplierCustom?: InputMaybe<LeadFieldMultiplierCustomCreateNestedManyWithoutOrganizationInput>;
  leads?: InputMaybe<LeadCreateNestedManyWithoutOrganizationInput>;
  leadsource_options?: InputMaybe<LeadSourceOptionCreateNestedManyWithoutOrganizationInput>;
  LeadValue?: InputMaybe<LeadValueCreateNestedManyWithoutOrganizationInput>;
  LifeTimeValueMultiplier?: InputMaybe<LifeTimeValueMultiplierCreateNestedManyWithoutOrganizationInput>;
  LiveTransferSetting?: InputMaybe<LiveTransferSettingCreateNestedOneWithoutOrganizationInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusCreateNestedManyWithoutOrganizationInput>;
  ltv_multiplier_custom_fields?: InputMaybe<LifeTimeValueMultiplierCustomCreateNestedManyWithoutOrganizationInput>;
  made_contact_rule?: InputMaybe<Scalars['Int']['input']>;
  make_sale_address_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_business_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_city_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_country_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_first_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_industry_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_last_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_lead_source_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_primary_email_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_primary_phone_number_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_state_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_sub_industry_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_zip_required?: InputMaybe<Scalars['Boolean']['input']>;
  maxium_day_lag_set_to_schedule?: InputMaybe<Scalars['Int']['input']>;
  mrr?: InputMaybe<Scalars['Int']['input']>;
  mrr_label?: InputMaybe<Scalars['String']['input']>;
  mrr_required_on_sale?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  NoteItems?: InputMaybe<NoteItemCreateNestedManyWithoutOrganizationInput>;
  Notifications?: InputMaybe<NotificationCreateNestedManyWithoutOrganizationInput>;
  onboardComplete?: InputMaybe<Scalars['Boolean']['input']>;
  Opportunity?: InputMaybe<SfOpportunityCreateNestedManyWithoutOrganizationInput>;
  organization_holidays?: InputMaybe<OrganizationHolidayCreateNestedManyWithoutOrganizationInput>;
  organization_hours?: InputMaybe<OrganizationHourCreateNestedManyWithoutOrganizationInput>;
  OrganizationPaymentSetting?: InputMaybe<OrganizationPaymentSettingsCreateNestedOneWithoutOrganizationInput>;
  OrganizationPaymentSettings?: InputMaybe<OrganizationPaymentSettingsCreateNestedOneWithoutOrganizationsInput>;
  orgCalendarInviteMetadata?: InputMaybe<OrgCalendarInviteMetadataCreateNestedManyWithoutOrganizationInput>;
  OrgDefaultPipelinePerecentValue?: InputMaybe<OrgDefaultPipelinePerecentValueCreateNestedOneWithoutOrganizationInput>;
  OrgHubSpotSync?: InputMaybe<OrgHubSpotSyncCreateNestedOneWithoutOrganizationInput>;
  OrgNoEventDate?: InputMaybe<OrgNoEventDateCreateNestedManyWithoutOrganizationInput>;
  OrgSalesforceSync?: InputMaybe<OrgSalesforceSyncCreateNestedOneWithoutOrganizationInput>;
  OrgTimeslotAvailability?: InputMaybe<OrgTimeslotAvailabilityCreateNestedManyWithoutOrganizationInput>;
  outbound_close_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_dm_connect_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_lt_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_revenue_value_of_sale?: InputMaybe<Scalars['Float']['input']>;
  outbound_scheduled_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_set_rate?: InputMaybe<Scalars['Float']['input']>;
  PandadocData?: InputMaybe<PandadocDataCreateNestedOneWithoutOrganizationInput>;
  PandaDocTemplate?: InputMaybe<PandaDocTemplateCreateNestedManyWithoutOrganizationInput>;
  participant_jitter_buffer_size?: InputMaybe<JitterBufferSize>;
  preferred_goal_setting?: InputMaybe<Goalsetting>;
  prepayment?: InputMaybe<Scalars['Boolean']['input']>;
  ProductChangeLog?: InputMaybe<ProductChangeLogCreateNestedManyWithoutOrganizationInput>;
  products?: InputMaybe<ProductCreateNestedManyWithoutOrganizationInput>;
  re_cold_call_hour_delay?: InputMaybe<Scalars['Float']['input']>;
  recordingStatus?: InputMaybe<RecordingStatus>;
  release_lead_resting?: InputMaybe<Scalars['Int']['input']>;
  rep_claim_limit?: InputMaybe<Scalars['Int']['input']>;
  rep_lead_allotment?: InputMaybe<Scalars['Int']['input']>;
  rep_level_call_transfer?: InputMaybe<Scalars['Boolean']['input']>;
  reps_can_bulk_unassign?: InputMaybe<Scalars['Boolean']['input']>;
  reps_can_view_all_notes?: InputMaybe<Scalars['Boolean']['input']>;
  require_sale_notes?: InputMaybe<Scalars['Boolean']['input']>;
  resting_after_skipped_CC?: InputMaybe<Scalars['Int']['input']>;
  resting_period?: InputMaybe<Scalars['Int']['input']>;
  resting_period_after_unsuccessful_cold_call?: InputMaybe<Scalars['Float']['input']>;
  resting_period_after_unsuccessful_demo?: InputMaybe<Scalars['Float']['input']>;
  revenue_plan?: InputMaybe<RevenuePlanItemCreateNestedManyWithoutOrganizationInput>;
  sale_field_options?: InputMaybe<SaleFieldOptionCreateNestedManyWithoutOrganizationInput>;
  sale_notification_email_list?: InputMaybe<OrganizationCreatesale_Notification_Email_ListInput>;
  SaleConfig?: InputMaybe<SaleConfigCreateNestedOneWithoutOrganizationInput>;
  SaleConfigPage?: InputMaybe<SaleConfigPageCreateNestedManyWithoutOrganizationInput>;
  SaleConfigSection?: InputMaybe<SaleConfigSectionCreateNestedManyWithoutOrganizationInput>;
  SaleCycles?: InputMaybe<SaleCycleCreateNestedManyWithoutOrganizationInput>;
  SaleItems?: InputMaybe<SaleItemCreateNestedManyWithoutOrganizationInput>;
  sales?: InputMaybe<SaleCreateNestedManyWithoutOrganizationInput>;
  salesforce_domain?: InputMaybe<Scalars['String']['input']>;
  salesforce_last_sync_date?: InputMaybe<Scalars['DateTime']['input']>;
  SalesForceColumn?: InputMaybe<SalesForceColumnCreateNestedManyWithoutOrganizationInput>;
  salesForceData?: InputMaybe<SalesForceDataCreateNestedOneWithoutOrganizationInput>;
  ScheduleItems?: InputMaybe<ScheduleItemCreateNestedManyWithoutOrganizationInput>;
  self_sourcing_rule?: InputMaybe<Scalars['Int']['input']>;
  send_error_log_email?: InputMaybe<Scalars['Boolean']['input']>;
  send_lead_routing_email?: InputMaybe<Scalars['Boolean']['input']>;
  sendgrid_email_domain?: InputMaybe<Scalars['String']['input']>;
  sendgrid_email_domain_id?: InputMaybe<Scalars['Int']['input']>;
  sequence?: InputMaybe<SequenceCreateNestedManyWithoutOrganzationInput>;
  SequenceCriteriaConditions?: InputMaybe<SequenceCriteriaConditionCreateNestedManyWithoutOrganizationInput>;
  SequenceDashboardViews?: InputMaybe<SequenceDashboardViewCreateNestedManyWithoutOrganizationInput>;
  SequenceEntryCriterias?: InputMaybe<SequenceEntryCriteriaCreateNestedManyWithoutOrganizationInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutOrganizationInput>;
  SequenceSteps?: InputMaybe<SequenceStepCreateNestedManyWithoutOrganizationInput>;
  set_goal?: InputMaybe<Scalars['Float']['input']>;
  short_resting?: InputMaybe<Scalars['Int']['input']>;
  show_calendar_options?: InputMaybe<Scalars['Boolean']['input']>;
  show_contract_duration_sales?: InputMaybe<Scalars['Boolean']['input']>;
  show_lead_source_to_rep?: InputMaybe<Scalars['Boolean']['input']>;
  show_nocontact_button?: InputMaybe<Scalars['Boolean']['input']>;
  show_payment_terms_sales?: InputMaybe<Scalars['Boolean']['input']>;
  show_rep_all_leads?: InputMaybe<Scalars['Boolean']['input']>;
  show_rep_my_leads?: InputMaybe<Scalars['Boolean']['input']>;
  show_system_view?: InputMaybe<Scalars['Boolean']['input']>;
  sites?: InputMaybe<SiteCreateNestedManyWithoutOrganizationInput>;
  sms_opt_in_dialog?: InputMaybe<Scalars['String']['input']>;
  SMSItems?: InputMaybe<SmsItemCreateNestedManyWithoutOrganizationInput>;
  SnoozeTrackings?: InputMaybe<SnoozeTrackingCreateNestedManyWithoutOrganizationInput>;
  StepActionLogs?: InputMaybe<StepActionLogCreateNestedManyWithoutOrganizationInput>;
  StepActions?: InputMaybe<StepActionCreateNestedManyWithoutOrganizationInput>;
  StepConditions?: InputMaybe<StepConditionCreateNestedManyWithoutOrganizationInput>;
  teams?: InputMaybe<TeamCreateNestedManyWithoutOrganizationInput>;
  Template?: InputMaybe<TemplateCreateNestedManyWithoutOrganizationInput>;
  TemplateVersions?: InputMaybe<TemplateVersionCreateNestedManyWithoutOrganizationInput>;
  timekit_id?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  transfer_timeout?: InputMaybe<Scalars['Int']['input']>;
  TransferAttempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutOrganizationInput>;
  twilio_numbers?: InputMaybe<TwilioNumberCreateNestedManyWithoutOrganizationInput>;
  TwilioFeedbacks?: InputMaybe<TwilioFeedbackCreateNestedManyWithoutOrganizationInput>;
  TwilioInfo?: InputMaybe<TwilioInfoCreateNestedOneWithoutOrganzationInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  use_revenue_planning?: InputMaybe<Scalars['Boolean']['input']>;
  UserCustomFieldFilters?: InputMaybe<UserCustomFieldFilterCreateNestedManyWithoutOrganizationInput>;
  users?: InputMaybe<UserCreateNestedManyWithoutOrganizationInput>;
  win_rep_day_goal?: InputMaybe<Scalars['Float']['input']>;
};

export type OrganizationCreateWithoutSaleConfigInput = {
  ActivityMonitorMultiplier?: InputMaybe<ActivityMonitorMultiplierCreateNestedManyWithoutOrganizationInput>;
  algoFactor?: InputMaybe<AlgoFactorCreateNestedManyWithoutOrganizationInput>;
  allow_calendar_invite?: InputMaybe<Scalars['Boolean']['input']>;
  allow_remove_step_action?: InputMaybe<Scalars['Boolean']['input']>;
  allow_snooze_step_action?: InputMaybe<Scalars['Boolean']['input']>;
  always_record?: InputMaybe<Scalars['Boolean']['input']>;
  arv_initial_held_demos_generated_by_sdr_weight?: InputMaybe<Scalars['Float']['input']>;
  AssociationHistories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutOrganizationInput>;
  auto_call_interval?: InputMaybe<Scalars['Int']['input']>;
  auto_dial?: InputMaybe<Scalars['Boolean']['input']>;
  automated_campaigns?: InputMaybe<AutomatedCampaignCreateNestedManyWithoutOrganizationInput>;
  automatic_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  AutoTransferSelections?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutOrganizationInput>;
  Availablities?: InputMaybe<AvailabilityCreateNestedManyWithoutOrganizationInput>;
  AverageOrderValue?: InputMaybe<AverageOrderValueCreateNestedManyWithoutOrganizationInput>;
  best_action_ever_multiplier?: InputMaybe<Scalars['Float']['input']>;
  best_metrics?: InputMaybe<BestMetricRecordCreateNestedManyWithoutOrganizationInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptCreateNestedManyWithoutOrganizationInput>;
  callMeNows?: InputMaybe<CallMeNowCreateNestedManyWithoutOrganizationInput>;
  CallMultiplier?: InputMaybe<CallMultiplierCreateNestedManyWithoutOrganizationInput>;
  CallSharings?: InputMaybe<CallSharingCreateNestedManyWithoutOrganizationInput>;
  can_edit_sdr_transfer_rules?: InputMaybe<Scalars['Boolean']['input']>;
  claim_rule?: InputMaybe<Scalars['Int']['input']>;
  concession?: InputMaybe<ConcessionCreateNestedManyWithoutOrganizationInput>;
  conference_region?: InputMaybe<ConferenceRegion>;
  Conferences?: InputMaybe<ConferenceCreateNestedManyWithoutOrganizationInput>;
  ContactRequests?: InputMaybe<ContactRequestCreateNestedManyWithoutOrganizationInput>;
  Contacts?: InputMaybe<ContactCreateNestedManyWithoutOrganizationInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  custom_fields?: InputMaybe<CustomFieldCreateNestedManyWithoutOrganizationInput>;
  CustomObject?: InputMaybe<CustomObjectCreateNestedManyWithoutOrganizationInput>;
  CustomObjectField?: InputMaybe<CustomObjectFieldCreateNestedManyWithoutOrganizationInput>;
  CustomObjectFieldForeignKey?: InputMaybe<CustomObjectFieldForeignKeyCreateNestedManyWithoutOrganizationInput>;
  CustomObjectRecord?: InputMaybe<CustomObjectRecordCreateNestedManyWithoutOrganizationInput>;
  CustomObjectRow?: InputMaybe<CustomObjectRowCreateNestedManyWithoutOrganizationInput>;
  CustomQueueItems?: InputMaybe<CustomQueueItemCreateNestedManyWithoutOrganizationInput>;
  DashboardColumnOptions?: InputMaybe<DashboardColumnOptionCreateNestedManyWithoutOrganizationInput>;
  DashboardLeadFilters?: InputMaybe<DashboardLeadFilterCreateNestedManyWithoutOrganizationInput>;
  DashboardRepFilters?: InputMaybe<DashboardRepFilterCreateNestedManyWithoutOrganizationInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewCreateNestedManyWithoutOrganizationInput>;
  day_to_close?: InputMaybe<Scalars['Int']['input']>;
  default_demo_time_duration?: InputMaybe<Scalars['Int']['input']>;
  demo_held_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_not_held_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_not_held_call_back_short_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_prep_time_minutes?: InputMaybe<Scalars['Int']['input']>;
  dev_account?: InputMaybe<Scalars['Boolean']['input']>;
  dial_rule?: InputMaybe<Scalars['Int']['input']>;
  DispositionMultiplier?: InputMaybe<DispositionMultiplierCreateNestedManyWithoutOrganizationInput>;
  dispositions?: InputMaybe<DispositionCreateNestedManyWithoutOrganizationInput>;
  double_booking?: InputMaybe<Scalars['Boolean']['input']>;
  dynamic_date_operators?: InputMaybe<Scalars['Boolean']['input']>;
  edge_servers?: InputMaybe<OrganizationCreateedge_ServersInput>;
  email_conflict_notification_email_list?: InputMaybe<OrganizationCreateemail_Conflict_Notification_Email_ListInput>;
  EmailItems?: InputMaybe<EmailItemCreateNestedManyWithoutOrganizationInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordCreateNestedManyWithoutOrganizationInput>;
  EmailThreads?: InputMaybe<EmailThreadCreateNestedManyWithoutOrganizationInput>;
  enable_modified_metrics?: InputMaybe<Scalars['Boolean']['input']>;
  enable_pay_period_view?: InputMaybe<Scalars['Boolean']['input']>;
  enable_time_constraint?: InputMaybe<Scalars['Boolean']['input']>;
  error_log_email_address?: InputMaybe<OrganizationCreateerror_Log_Email_AddressInput>;
  estimated_average_num_of_weekly_overbooked_demo_slots?: InputMaybe<Scalars['Int']['input']>;
  FolderPermissions?: InputMaybe<FolderPermissionCreateNestedManyWithoutOrganizationInput>;
  Folders?: InputMaybe<FolderCreateNestedManyWithoutOrganizationInput>;
  FutureBookingSetting?: InputMaybe<FutureBookingSettingCreateNestedOneWithoutOrganizationInput>;
  GlobalTransferRule?: InputMaybe<GlobalTransferRuleCreateNestedManyWithoutOrganizationInput>;
  held_demos_per_cc_connect?: InputMaybe<Scalars['Float']['input']>;
  helpful_links?: InputMaybe<OrganizationCreatehelpful_LinksInput>;
  HingePointMultiplier?: InputMaybe<HingePointMultiplierCreateNestedManyWithoutOrganizationInput>;
  hold_goal?: InputMaybe<Scalars['Float']['input']>;
  hubspot_credential?: InputMaybe<HubSpotCredentialCreateNestedOneWithoutOrganizationInput>;
  hubspot_last_sync_date?: InputMaybe<Scalars['DateTime']['input']>;
  hubspot_white_list?: InputMaybe<HubSpotWhitelistCreateNestedManyWithoutOrganizationInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  inbound_close_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_dm_connect_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_lt_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_revenue_value_of_sale?: InputMaybe<Scalars['Float']['input']>;
  inbound_scheduled_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_set_rate?: InputMaybe<Scalars['Float']['input']>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventCreateNestedManyWithoutOrganizationInput>;
  InboundConciergeWidget?: InputMaybe<InboundConciergeWidgetCreateNestedManyWithoutOrganizationInput>;
  industry_options?: InputMaybe<IndustryOptionCreateNestedManyWithoutOrganizationInput>;
  initial_sdr_sets_reps_day?: InputMaybe<Scalars['Float']['input']>;
  integration_error_logs?: InputMaybe<IntegrationErrorLogCreateNestedManyWithoutOrganizationInput>;
  integration_mappings?: InputMaybe<IntegrationMappingCreateNestedManyWithoutOrganizationInput>;
  IntegrationMappingCustomObject?: InputMaybe<IntegrationMappingCustomObjectCreateNestedManyWithoutOrganizationInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogCreateNestedManyWithoutOrganizationInput>;
  IntegrationReadOnlyObject?: InputMaybe<IntegrationReadOnlyObjectCreateNestedManyWithoutOrganizationInput>;
  international?: InputMaybe<Scalars['Boolean']['input']>;
  is_email_domain_verified?: InputMaybe<Scalars['Boolean']['input']>;
  is_salesforce_sandbox?: InputMaybe<Scalars['Boolean']['input']>;
  last_lead_value_compute_time?: InputMaybe<Scalars['DateTime']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutOrganizationInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryCreateNestedManyWithoutOrganizationInput>;
  lead_intent?: InputMaybe<LeadIntentCreateNestedManyWithoutOrganizationInput>;
  lead_release_same_rep_delay_rule?: InputMaybe<Scalars['Int']['input']>;
  lead_routing_email_list?: InputMaybe<OrganizationCreatelead_Routing_Email_ListInput>;
  lead_routing_rules?: InputMaybe<RuleCreateNestedManyWithoutOrganizationInput>;
  LeadCustomObjectRowAssociation?: InputMaybe<LeadCustomObjectRowAssociationCreateNestedManyWithoutOrganizationInput>;
  LeadFieldMultiplier?: InputMaybe<LeadFieldMultiplierCreateNestedManyWithoutOrganizationInput>;
  LeadFieldMultiplierCustom?: InputMaybe<LeadFieldMultiplierCustomCreateNestedManyWithoutOrganizationInput>;
  leads?: InputMaybe<LeadCreateNestedManyWithoutOrganizationInput>;
  leadsource_options?: InputMaybe<LeadSourceOptionCreateNestedManyWithoutOrganizationInput>;
  LeadValue?: InputMaybe<LeadValueCreateNestedManyWithoutOrganizationInput>;
  LifeTimeValueMultiplier?: InputMaybe<LifeTimeValueMultiplierCreateNestedManyWithoutOrganizationInput>;
  LiveTransferSetting?: InputMaybe<LiveTransferSettingCreateNestedOneWithoutOrganizationInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusCreateNestedManyWithoutOrganizationInput>;
  ltv_multiplier_custom_fields?: InputMaybe<LifeTimeValueMultiplierCustomCreateNestedManyWithoutOrganizationInput>;
  made_contact_rule?: InputMaybe<Scalars['Int']['input']>;
  make_sale_address_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_business_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_city_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_country_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_first_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_industry_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_last_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_lead_source_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_primary_email_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_primary_phone_number_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_state_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_sub_industry_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_zip_required?: InputMaybe<Scalars['Boolean']['input']>;
  maxium_day_lag_set_to_schedule?: InputMaybe<Scalars['Int']['input']>;
  mrr?: InputMaybe<Scalars['Int']['input']>;
  mrr_label?: InputMaybe<Scalars['String']['input']>;
  mrr_required_on_sale?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  NoteItems?: InputMaybe<NoteItemCreateNestedManyWithoutOrganizationInput>;
  Notifications?: InputMaybe<NotificationCreateNestedManyWithoutOrganizationInput>;
  onboardComplete?: InputMaybe<Scalars['Boolean']['input']>;
  Opportunity?: InputMaybe<SfOpportunityCreateNestedManyWithoutOrganizationInput>;
  organization_holidays?: InputMaybe<OrganizationHolidayCreateNestedManyWithoutOrganizationInput>;
  organization_hours?: InputMaybe<OrganizationHourCreateNestedManyWithoutOrganizationInput>;
  OrganizationPaymentSetting?: InputMaybe<OrganizationPaymentSettingsCreateNestedOneWithoutOrganizationInput>;
  OrganizationPaymentSettings?: InputMaybe<OrganizationPaymentSettingsCreateNestedOneWithoutOrganizationsInput>;
  orgCalendarInviteMetadata?: InputMaybe<OrgCalendarInviteMetadataCreateNestedManyWithoutOrganizationInput>;
  OrgDefaultPipelinePerecentValue?: InputMaybe<OrgDefaultPipelinePerecentValueCreateNestedOneWithoutOrganizationInput>;
  OrgHubSpotSync?: InputMaybe<OrgHubSpotSyncCreateNestedOneWithoutOrganizationInput>;
  OrgNoEventDate?: InputMaybe<OrgNoEventDateCreateNestedManyWithoutOrganizationInput>;
  OrgSalesforceSync?: InputMaybe<OrgSalesforceSyncCreateNestedOneWithoutOrganizationInput>;
  OrgTimeslotAvailability?: InputMaybe<OrgTimeslotAvailabilityCreateNestedManyWithoutOrganizationInput>;
  outbound_close_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_dm_connect_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_lt_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_revenue_value_of_sale?: InputMaybe<Scalars['Float']['input']>;
  outbound_scheduled_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_set_rate?: InputMaybe<Scalars['Float']['input']>;
  PandadocData?: InputMaybe<PandadocDataCreateNestedOneWithoutOrganizationInput>;
  PandaDocTemplate?: InputMaybe<PandaDocTemplateCreateNestedManyWithoutOrganizationInput>;
  participant_jitter_buffer_size?: InputMaybe<JitterBufferSize>;
  preferred_goal_setting?: InputMaybe<Goalsetting>;
  prepayment?: InputMaybe<Scalars['Boolean']['input']>;
  ProductChangeLog?: InputMaybe<ProductChangeLogCreateNestedManyWithoutOrganizationInput>;
  products?: InputMaybe<ProductCreateNestedManyWithoutOrganizationInput>;
  re_cold_call_hour_delay?: InputMaybe<Scalars['Float']['input']>;
  recordingStatus?: InputMaybe<RecordingStatus>;
  release_lead_resting?: InputMaybe<Scalars['Int']['input']>;
  rep_claim_limit?: InputMaybe<Scalars['Int']['input']>;
  rep_lead_allotment?: InputMaybe<Scalars['Int']['input']>;
  rep_level_call_transfer?: InputMaybe<Scalars['Boolean']['input']>;
  reps_can_bulk_unassign?: InputMaybe<Scalars['Boolean']['input']>;
  reps_can_view_all_notes?: InputMaybe<Scalars['Boolean']['input']>;
  require_sale_notes?: InputMaybe<Scalars['Boolean']['input']>;
  resting_after_skipped_CC?: InputMaybe<Scalars['Int']['input']>;
  resting_period?: InputMaybe<Scalars['Int']['input']>;
  resting_period_after_unsuccessful_cold_call?: InputMaybe<Scalars['Float']['input']>;
  resting_period_after_unsuccessful_demo?: InputMaybe<Scalars['Float']['input']>;
  revenue_plan?: InputMaybe<RevenuePlanItemCreateNestedManyWithoutOrganizationInput>;
  Routings?: InputMaybe<RoutingCreateNestedManyWithoutOrganizationInput>;
  sale_field_options?: InputMaybe<SaleFieldOptionCreateNestedManyWithoutOrganizationInput>;
  sale_notification_email_list?: InputMaybe<OrganizationCreatesale_Notification_Email_ListInput>;
  SaleConfigPage?: InputMaybe<SaleConfigPageCreateNestedManyWithoutOrganizationInput>;
  SaleConfigSection?: InputMaybe<SaleConfigSectionCreateNestedManyWithoutOrganizationInput>;
  SaleCycles?: InputMaybe<SaleCycleCreateNestedManyWithoutOrganizationInput>;
  SaleItems?: InputMaybe<SaleItemCreateNestedManyWithoutOrganizationInput>;
  sales?: InputMaybe<SaleCreateNestedManyWithoutOrganizationInput>;
  salesforce_domain?: InputMaybe<Scalars['String']['input']>;
  salesforce_last_sync_date?: InputMaybe<Scalars['DateTime']['input']>;
  SalesForceColumn?: InputMaybe<SalesForceColumnCreateNestedManyWithoutOrganizationInput>;
  salesForceData?: InputMaybe<SalesForceDataCreateNestedOneWithoutOrganizationInput>;
  ScheduleItems?: InputMaybe<ScheduleItemCreateNestedManyWithoutOrganizationInput>;
  self_sourcing_rule?: InputMaybe<Scalars['Int']['input']>;
  send_error_log_email?: InputMaybe<Scalars['Boolean']['input']>;
  send_lead_routing_email?: InputMaybe<Scalars['Boolean']['input']>;
  sendgrid_email_domain?: InputMaybe<Scalars['String']['input']>;
  sendgrid_email_domain_id?: InputMaybe<Scalars['Int']['input']>;
  sequence?: InputMaybe<SequenceCreateNestedManyWithoutOrganzationInput>;
  SequenceCriteriaConditions?: InputMaybe<SequenceCriteriaConditionCreateNestedManyWithoutOrganizationInput>;
  SequenceDashboardViews?: InputMaybe<SequenceDashboardViewCreateNestedManyWithoutOrganizationInput>;
  SequenceEntryCriterias?: InputMaybe<SequenceEntryCriteriaCreateNestedManyWithoutOrganizationInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutOrganizationInput>;
  SequenceSteps?: InputMaybe<SequenceStepCreateNestedManyWithoutOrganizationInput>;
  set_goal?: InputMaybe<Scalars['Float']['input']>;
  short_resting?: InputMaybe<Scalars['Int']['input']>;
  show_calendar_options?: InputMaybe<Scalars['Boolean']['input']>;
  show_contract_duration_sales?: InputMaybe<Scalars['Boolean']['input']>;
  show_lead_source_to_rep?: InputMaybe<Scalars['Boolean']['input']>;
  show_nocontact_button?: InputMaybe<Scalars['Boolean']['input']>;
  show_payment_terms_sales?: InputMaybe<Scalars['Boolean']['input']>;
  show_rep_all_leads?: InputMaybe<Scalars['Boolean']['input']>;
  show_rep_my_leads?: InputMaybe<Scalars['Boolean']['input']>;
  show_system_view?: InputMaybe<Scalars['Boolean']['input']>;
  sites?: InputMaybe<SiteCreateNestedManyWithoutOrganizationInput>;
  sms_opt_in_dialog?: InputMaybe<Scalars['String']['input']>;
  SMSItems?: InputMaybe<SmsItemCreateNestedManyWithoutOrganizationInput>;
  SnoozeTrackings?: InputMaybe<SnoozeTrackingCreateNestedManyWithoutOrganizationInput>;
  StepActionLogs?: InputMaybe<StepActionLogCreateNestedManyWithoutOrganizationInput>;
  StepActions?: InputMaybe<StepActionCreateNestedManyWithoutOrganizationInput>;
  StepConditions?: InputMaybe<StepConditionCreateNestedManyWithoutOrganizationInput>;
  teams?: InputMaybe<TeamCreateNestedManyWithoutOrganizationInput>;
  Template?: InputMaybe<TemplateCreateNestedManyWithoutOrganizationInput>;
  TemplateVersions?: InputMaybe<TemplateVersionCreateNestedManyWithoutOrganizationInput>;
  timekit_id?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  transfer_timeout?: InputMaybe<Scalars['Int']['input']>;
  TransferAttempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutOrganizationInput>;
  twilio_numbers?: InputMaybe<TwilioNumberCreateNestedManyWithoutOrganizationInput>;
  TwilioFeedbacks?: InputMaybe<TwilioFeedbackCreateNestedManyWithoutOrganizationInput>;
  TwilioInfo?: InputMaybe<TwilioInfoCreateNestedOneWithoutOrganzationInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  use_revenue_planning?: InputMaybe<Scalars['Boolean']['input']>;
  UserCustomFieldFilters?: InputMaybe<UserCustomFieldFilterCreateNestedManyWithoutOrganizationInput>;
  users?: InputMaybe<UserCreateNestedManyWithoutOrganizationInput>;
  win_rep_day_goal?: InputMaybe<Scalars['Float']['input']>;
};

export type OrganizationCreateWithoutSaleConfigPageInput = {
  ActivityMonitorMultiplier?: InputMaybe<ActivityMonitorMultiplierCreateNestedManyWithoutOrganizationInput>;
  algoFactor?: InputMaybe<AlgoFactorCreateNestedManyWithoutOrganizationInput>;
  allow_calendar_invite?: InputMaybe<Scalars['Boolean']['input']>;
  allow_remove_step_action?: InputMaybe<Scalars['Boolean']['input']>;
  allow_snooze_step_action?: InputMaybe<Scalars['Boolean']['input']>;
  always_record?: InputMaybe<Scalars['Boolean']['input']>;
  arv_initial_held_demos_generated_by_sdr_weight?: InputMaybe<Scalars['Float']['input']>;
  AssociationHistories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutOrganizationInput>;
  auto_call_interval?: InputMaybe<Scalars['Int']['input']>;
  auto_dial?: InputMaybe<Scalars['Boolean']['input']>;
  automated_campaigns?: InputMaybe<AutomatedCampaignCreateNestedManyWithoutOrganizationInput>;
  automatic_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  AutoTransferSelections?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutOrganizationInput>;
  Availablities?: InputMaybe<AvailabilityCreateNestedManyWithoutOrganizationInput>;
  AverageOrderValue?: InputMaybe<AverageOrderValueCreateNestedManyWithoutOrganizationInput>;
  best_action_ever_multiplier?: InputMaybe<Scalars['Float']['input']>;
  best_metrics?: InputMaybe<BestMetricRecordCreateNestedManyWithoutOrganizationInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptCreateNestedManyWithoutOrganizationInput>;
  callMeNows?: InputMaybe<CallMeNowCreateNestedManyWithoutOrganizationInput>;
  CallMultiplier?: InputMaybe<CallMultiplierCreateNestedManyWithoutOrganizationInput>;
  CallSharings?: InputMaybe<CallSharingCreateNestedManyWithoutOrganizationInput>;
  can_edit_sdr_transfer_rules?: InputMaybe<Scalars['Boolean']['input']>;
  claim_rule?: InputMaybe<Scalars['Int']['input']>;
  concession?: InputMaybe<ConcessionCreateNestedManyWithoutOrganizationInput>;
  conference_region?: InputMaybe<ConferenceRegion>;
  Conferences?: InputMaybe<ConferenceCreateNestedManyWithoutOrganizationInput>;
  ContactRequests?: InputMaybe<ContactRequestCreateNestedManyWithoutOrganizationInput>;
  Contacts?: InputMaybe<ContactCreateNestedManyWithoutOrganizationInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  custom_fields?: InputMaybe<CustomFieldCreateNestedManyWithoutOrganizationInput>;
  CustomObject?: InputMaybe<CustomObjectCreateNestedManyWithoutOrganizationInput>;
  CustomObjectField?: InputMaybe<CustomObjectFieldCreateNestedManyWithoutOrganizationInput>;
  CustomObjectFieldForeignKey?: InputMaybe<CustomObjectFieldForeignKeyCreateNestedManyWithoutOrganizationInput>;
  CustomObjectRecord?: InputMaybe<CustomObjectRecordCreateNestedManyWithoutOrganizationInput>;
  CustomObjectRow?: InputMaybe<CustomObjectRowCreateNestedManyWithoutOrganizationInput>;
  CustomQueueItems?: InputMaybe<CustomQueueItemCreateNestedManyWithoutOrganizationInput>;
  DashboardColumnOptions?: InputMaybe<DashboardColumnOptionCreateNestedManyWithoutOrganizationInput>;
  DashboardLeadFilters?: InputMaybe<DashboardLeadFilterCreateNestedManyWithoutOrganizationInput>;
  DashboardRepFilters?: InputMaybe<DashboardRepFilterCreateNestedManyWithoutOrganizationInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewCreateNestedManyWithoutOrganizationInput>;
  day_to_close?: InputMaybe<Scalars['Int']['input']>;
  default_demo_time_duration?: InputMaybe<Scalars['Int']['input']>;
  demo_held_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_not_held_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_not_held_call_back_short_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_prep_time_minutes?: InputMaybe<Scalars['Int']['input']>;
  dev_account?: InputMaybe<Scalars['Boolean']['input']>;
  dial_rule?: InputMaybe<Scalars['Int']['input']>;
  DispositionMultiplier?: InputMaybe<DispositionMultiplierCreateNestedManyWithoutOrganizationInput>;
  dispositions?: InputMaybe<DispositionCreateNestedManyWithoutOrganizationInput>;
  double_booking?: InputMaybe<Scalars['Boolean']['input']>;
  dynamic_date_operators?: InputMaybe<Scalars['Boolean']['input']>;
  edge_servers?: InputMaybe<OrganizationCreateedge_ServersInput>;
  email_conflict_notification_email_list?: InputMaybe<OrganizationCreateemail_Conflict_Notification_Email_ListInput>;
  EmailItems?: InputMaybe<EmailItemCreateNestedManyWithoutOrganizationInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordCreateNestedManyWithoutOrganizationInput>;
  EmailThreads?: InputMaybe<EmailThreadCreateNestedManyWithoutOrganizationInput>;
  enable_modified_metrics?: InputMaybe<Scalars['Boolean']['input']>;
  enable_pay_period_view?: InputMaybe<Scalars['Boolean']['input']>;
  enable_time_constraint?: InputMaybe<Scalars['Boolean']['input']>;
  error_log_email_address?: InputMaybe<OrganizationCreateerror_Log_Email_AddressInput>;
  estimated_average_num_of_weekly_overbooked_demo_slots?: InputMaybe<Scalars['Int']['input']>;
  FolderPermissions?: InputMaybe<FolderPermissionCreateNestedManyWithoutOrganizationInput>;
  Folders?: InputMaybe<FolderCreateNestedManyWithoutOrganizationInput>;
  FutureBookingSetting?: InputMaybe<FutureBookingSettingCreateNestedOneWithoutOrganizationInput>;
  GlobalTransferRule?: InputMaybe<GlobalTransferRuleCreateNestedManyWithoutOrganizationInput>;
  held_demos_per_cc_connect?: InputMaybe<Scalars['Float']['input']>;
  helpful_links?: InputMaybe<OrganizationCreatehelpful_LinksInput>;
  HingePointMultiplier?: InputMaybe<HingePointMultiplierCreateNestedManyWithoutOrganizationInput>;
  hold_goal?: InputMaybe<Scalars['Float']['input']>;
  hubspot_credential?: InputMaybe<HubSpotCredentialCreateNestedOneWithoutOrganizationInput>;
  hubspot_last_sync_date?: InputMaybe<Scalars['DateTime']['input']>;
  hubspot_white_list?: InputMaybe<HubSpotWhitelistCreateNestedManyWithoutOrganizationInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  inbound_close_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_dm_connect_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_lt_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_revenue_value_of_sale?: InputMaybe<Scalars['Float']['input']>;
  inbound_scheduled_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_set_rate?: InputMaybe<Scalars['Float']['input']>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventCreateNestedManyWithoutOrganizationInput>;
  InboundConciergeWidget?: InputMaybe<InboundConciergeWidgetCreateNestedManyWithoutOrganizationInput>;
  industry_options?: InputMaybe<IndustryOptionCreateNestedManyWithoutOrganizationInput>;
  initial_sdr_sets_reps_day?: InputMaybe<Scalars['Float']['input']>;
  integration_error_logs?: InputMaybe<IntegrationErrorLogCreateNestedManyWithoutOrganizationInput>;
  integration_mappings?: InputMaybe<IntegrationMappingCreateNestedManyWithoutOrganizationInput>;
  IntegrationMappingCustomObject?: InputMaybe<IntegrationMappingCustomObjectCreateNestedManyWithoutOrganizationInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogCreateNestedManyWithoutOrganizationInput>;
  IntegrationReadOnlyObject?: InputMaybe<IntegrationReadOnlyObjectCreateNestedManyWithoutOrganizationInput>;
  international?: InputMaybe<Scalars['Boolean']['input']>;
  is_email_domain_verified?: InputMaybe<Scalars['Boolean']['input']>;
  is_salesforce_sandbox?: InputMaybe<Scalars['Boolean']['input']>;
  last_lead_value_compute_time?: InputMaybe<Scalars['DateTime']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutOrganizationInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryCreateNestedManyWithoutOrganizationInput>;
  lead_intent?: InputMaybe<LeadIntentCreateNestedManyWithoutOrganizationInput>;
  lead_release_same_rep_delay_rule?: InputMaybe<Scalars['Int']['input']>;
  lead_routing_email_list?: InputMaybe<OrganizationCreatelead_Routing_Email_ListInput>;
  lead_routing_rules?: InputMaybe<RuleCreateNestedManyWithoutOrganizationInput>;
  LeadCustomObjectRowAssociation?: InputMaybe<LeadCustomObjectRowAssociationCreateNestedManyWithoutOrganizationInput>;
  LeadFieldMultiplier?: InputMaybe<LeadFieldMultiplierCreateNestedManyWithoutOrganizationInput>;
  LeadFieldMultiplierCustom?: InputMaybe<LeadFieldMultiplierCustomCreateNestedManyWithoutOrganizationInput>;
  leads?: InputMaybe<LeadCreateNestedManyWithoutOrganizationInput>;
  leadsource_options?: InputMaybe<LeadSourceOptionCreateNestedManyWithoutOrganizationInput>;
  LeadValue?: InputMaybe<LeadValueCreateNestedManyWithoutOrganizationInput>;
  LifeTimeValueMultiplier?: InputMaybe<LifeTimeValueMultiplierCreateNestedManyWithoutOrganizationInput>;
  LiveTransferSetting?: InputMaybe<LiveTransferSettingCreateNestedOneWithoutOrganizationInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusCreateNestedManyWithoutOrganizationInput>;
  ltv_multiplier_custom_fields?: InputMaybe<LifeTimeValueMultiplierCustomCreateNestedManyWithoutOrganizationInput>;
  made_contact_rule?: InputMaybe<Scalars['Int']['input']>;
  make_sale_address_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_business_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_city_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_country_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_first_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_industry_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_last_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_lead_source_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_primary_email_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_primary_phone_number_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_state_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_sub_industry_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_zip_required?: InputMaybe<Scalars['Boolean']['input']>;
  maxium_day_lag_set_to_schedule?: InputMaybe<Scalars['Int']['input']>;
  mrr?: InputMaybe<Scalars['Int']['input']>;
  mrr_label?: InputMaybe<Scalars['String']['input']>;
  mrr_required_on_sale?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  NoteItems?: InputMaybe<NoteItemCreateNestedManyWithoutOrganizationInput>;
  Notifications?: InputMaybe<NotificationCreateNestedManyWithoutOrganizationInput>;
  onboardComplete?: InputMaybe<Scalars['Boolean']['input']>;
  Opportunity?: InputMaybe<SfOpportunityCreateNestedManyWithoutOrganizationInput>;
  organization_holidays?: InputMaybe<OrganizationHolidayCreateNestedManyWithoutOrganizationInput>;
  organization_hours?: InputMaybe<OrganizationHourCreateNestedManyWithoutOrganizationInput>;
  OrganizationPaymentSetting?: InputMaybe<OrganizationPaymentSettingsCreateNestedOneWithoutOrganizationInput>;
  OrganizationPaymentSettings?: InputMaybe<OrganizationPaymentSettingsCreateNestedOneWithoutOrganizationsInput>;
  orgCalendarInviteMetadata?: InputMaybe<OrgCalendarInviteMetadataCreateNestedManyWithoutOrganizationInput>;
  OrgDefaultPipelinePerecentValue?: InputMaybe<OrgDefaultPipelinePerecentValueCreateNestedOneWithoutOrganizationInput>;
  OrgHubSpotSync?: InputMaybe<OrgHubSpotSyncCreateNestedOneWithoutOrganizationInput>;
  OrgNoEventDate?: InputMaybe<OrgNoEventDateCreateNestedManyWithoutOrganizationInput>;
  OrgSalesforceSync?: InputMaybe<OrgSalesforceSyncCreateNestedOneWithoutOrganizationInput>;
  OrgTimeslotAvailability?: InputMaybe<OrgTimeslotAvailabilityCreateNestedManyWithoutOrganizationInput>;
  outbound_close_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_dm_connect_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_lt_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_revenue_value_of_sale?: InputMaybe<Scalars['Float']['input']>;
  outbound_scheduled_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_set_rate?: InputMaybe<Scalars['Float']['input']>;
  PandadocData?: InputMaybe<PandadocDataCreateNestedOneWithoutOrganizationInput>;
  PandaDocTemplate?: InputMaybe<PandaDocTemplateCreateNestedManyWithoutOrganizationInput>;
  participant_jitter_buffer_size?: InputMaybe<JitterBufferSize>;
  preferred_goal_setting?: InputMaybe<Goalsetting>;
  prepayment?: InputMaybe<Scalars['Boolean']['input']>;
  ProductChangeLog?: InputMaybe<ProductChangeLogCreateNestedManyWithoutOrganizationInput>;
  products?: InputMaybe<ProductCreateNestedManyWithoutOrganizationInput>;
  re_cold_call_hour_delay?: InputMaybe<Scalars['Float']['input']>;
  recordingStatus?: InputMaybe<RecordingStatus>;
  release_lead_resting?: InputMaybe<Scalars['Int']['input']>;
  rep_claim_limit?: InputMaybe<Scalars['Int']['input']>;
  rep_lead_allotment?: InputMaybe<Scalars['Int']['input']>;
  rep_level_call_transfer?: InputMaybe<Scalars['Boolean']['input']>;
  reps_can_bulk_unassign?: InputMaybe<Scalars['Boolean']['input']>;
  reps_can_view_all_notes?: InputMaybe<Scalars['Boolean']['input']>;
  require_sale_notes?: InputMaybe<Scalars['Boolean']['input']>;
  resting_after_skipped_CC?: InputMaybe<Scalars['Int']['input']>;
  resting_period?: InputMaybe<Scalars['Int']['input']>;
  resting_period_after_unsuccessful_cold_call?: InputMaybe<Scalars['Float']['input']>;
  resting_period_after_unsuccessful_demo?: InputMaybe<Scalars['Float']['input']>;
  revenue_plan?: InputMaybe<RevenuePlanItemCreateNestedManyWithoutOrganizationInput>;
  Routings?: InputMaybe<RoutingCreateNestedManyWithoutOrganizationInput>;
  sale_field_options?: InputMaybe<SaleFieldOptionCreateNestedManyWithoutOrganizationInput>;
  sale_notification_email_list?: InputMaybe<OrganizationCreatesale_Notification_Email_ListInput>;
  SaleConfig?: InputMaybe<SaleConfigCreateNestedOneWithoutOrganizationInput>;
  SaleConfigSection?: InputMaybe<SaleConfigSectionCreateNestedManyWithoutOrganizationInput>;
  SaleCycles?: InputMaybe<SaleCycleCreateNestedManyWithoutOrganizationInput>;
  SaleItems?: InputMaybe<SaleItemCreateNestedManyWithoutOrganizationInput>;
  sales?: InputMaybe<SaleCreateNestedManyWithoutOrganizationInput>;
  salesforce_domain?: InputMaybe<Scalars['String']['input']>;
  salesforce_last_sync_date?: InputMaybe<Scalars['DateTime']['input']>;
  SalesForceColumn?: InputMaybe<SalesForceColumnCreateNestedManyWithoutOrganizationInput>;
  salesForceData?: InputMaybe<SalesForceDataCreateNestedOneWithoutOrganizationInput>;
  ScheduleItems?: InputMaybe<ScheduleItemCreateNestedManyWithoutOrganizationInput>;
  self_sourcing_rule?: InputMaybe<Scalars['Int']['input']>;
  send_error_log_email?: InputMaybe<Scalars['Boolean']['input']>;
  send_lead_routing_email?: InputMaybe<Scalars['Boolean']['input']>;
  sendgrid_email_domain?: InputMaybe<Scalars['String']['input']>;
  sendgrid_email_domain_id?: InputMaybe<Scalars['Int']['input']>;
  sequence?: InputMaybe<SequenceCreateNestedManyWithoutOrganzationInput>;
  SequenceCriteriaConditions?: InputMaybe<SequenceCriteriaConditionCreateNestedManyWithoutOrganizationInput>;
  SequenceDashboardViews?: InputMaybe<SequenceDashboardViewCreateNestedManyWithoutOrganizationInput>;
  SequenceEntryCriterias?: InputMaybe<SequenceEntryCriteriaCreateNestedManyWithoutOrganizationInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutOrganizationInput>;
  SequenceSteps?: InputMaybe<SequenceStepCreateNestedManyWithoutOrganizationInput>;
  set_goal?: InputMaybe<Scalars['Float']['input']>;
  short_resting?: InputMaybe<Scalars['Int']['input']>;
  show_calendar_options?: InputMaybe<Scalars['Boolean']['input']>;
  show_contract_duration_sales?: InputMaybe<Scalars['Boolean']['input']>;
  show_lead_source_to_rep?: InputMaybe<Scalars['Boolean']['input']>;
  show_nocontact_button?: InputMaybe<Scalars['Boolean']['input']>;
  show_payment_terms_sales?: InputMaybe<Scalars['Boolean']['input']>;
  show_rep_all_leads?: InputMaybe<Scalars['Boolean']['input']>;
  show_rep_my_leads?: InputMaybe<Scalars['Boolean']['input']>;
  show_system_view?: InputMaybe<Scalars['Boolean']['input']>;
  sites?: InputMaybe<SiteCreateNestedManyWithoutOrganizationInput>;
  sms_opt_in_dialog?: InputMaybe<Scalars['String']['input']>;
  SMSItems?: InputMaybe<SmsItemCreateNestedManyWithoutOrganizationInput>;
  SnoozeTrackings?: InputMaybe<SnoozeTrackingCreateNestedManyWithoutOrganizationInput>;
  StepActionLogs?: InputMaybe<StepActionLogCreateNestedManyWithoutOrganizationInput>;
  StepActions?: InputMaybe<StepActionCreateNestedManyWithoutOrganizationInput>;
  StepConditions?: InputMaybe<StepConditionCreateNestedManyWithoutOrganizationInput>;
  teams?: InputMaybe<TeamCreateNestedManyWithoutOrganizationInput>;
  Template?: InputMaybe<TemplateCreateNestedManyWithoutOrganizationInput>;
  TemplateVersions?: InputMaybe<TemplateVersionCreateNestedManyWithoutOrganizationInput>;
  timekit_id?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  transfer_timeout?: InputMaybe<Scalars['Int']['input']>;
  TransferAttempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutOrganizationInput>;
  twilio_numbers?: InputMaybe<TwilioNumberCreateNestedManyWithoutOrganizationInput>;
  TwilioFeedbacks?: InputMaybe<TwilioFeedbackCreateNestedManyWithoutOrganizationInput>;
  TwilioInfo?: InputMaybe<TwilioInfoCreateNestedOneWithoutOrganzationInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  use_revenue_planning?: InputMaybe<Scalars['Boolean']['input']>;
  UserCustomFieldFilters?: InputMaybe<UserCustomFieldFilterCreateNestedManyWithoutOrganizationInput>;
  users?: InputMaybe<UserCreateNestedManyWithoutOrganizationInput>;
  win_rep_day_goal?: InputMaybe<Scalars['Float']['input']>;
};

export type OrganizationCreateWithoutSaleConfigSectionInput = {
  ActivityMonitorMultiplier?: InputMaybe<ActivityMonitorMultiplierCreateNestedManyWithoutOrganizationInput>;
  algoFactor?: InputMaybe<AlgoFactorCreateNestedManyWithoutOrganizationInput>;
  allow_calendar_invite?: InputMaybe<Scalars['Boolean']['input']>;
  allow_remove_step_action?: InputMaybe<Scalars['Boolean']['input']>;
  allow_snooze_step_action?: InputMaybe<Scalars['Boolean']['input']>;
  always_record?: InputMaybe<Scalars['Boolean']['input']>;
  arv_initial_held_demos_generated_by_sdr_weight?: InputMaybe<Scalars['Float']['input']>;
  AssociationHistories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutOrganizationInput>;
  auto_call_interval?: InputMaybe<Scalars['Int']['input']>;
  auto_dial?: InputMaybe<Scalars['Boolean']['input']>;
  automated_campaigns?: InputMaybe<AutomatedCampaignCreateNestedManyWithoutOrganizationInput>;
  automatic_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  AutoTransferSelections?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutOrganizationInput>;
  Availablities?: InputMaybe<AvailabilityCreateNestedManyWithoutOrganizationInput>;
  AverageOrderValue?: InputMaybe<AverageOrderValueCreateNestedManyWithoutOrganizationInput>;
  best_action_ever_multiplier?: InputMaybe<Scalars['Float']['input']>;
  best_metrics?: InputMaybe<BestMetricRecordCreateNestedManyWithoutOrganizationInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptCreateNestedManyWithoutOrganizationInput>;
  callMeNows?: InputMaybe<CallMeNowCreateNestedManyWithoutOrganizationInput>;
  CallMultiplier?: InputMaybe<CallMultiplierCreateNestedManyWithoutOrganizationInput>;
  CallSharings?: InputMaybe<CallSharingCreateNestedManyWithoutOrganizationInput>;
  can_edit_sdr_transfer_rules?: InputMaybe<Scalars['Boolean']['input']>;
  claim_rule?: InputMaybe<Scalars['Int']['input']>;
  concession?: InputMaybe<ConcessionCreateNestedManyWithoutOrganizationInput>;
  conference_region?: InputMaybe<ConferenceRegion>;
  Conferences?: InputMaybe<ConferenceCreateNestedManyWithoutOrganizationInput>;
  ContactRequests?: InputMaybe<ContactRequestCreateNestedManyWithoutOrganizationInput>;
  Contacts?: InputMaybe<ContactCreateNestedManyWithoutOrganizationInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  custom_fields?: InputMaybe<CustomFieldCreateNestedManyWithoutOrganizationInput>;
  CustomObject?: InputMaybe<CustomObjectCreateNestedManyWithoutOrganizationInput>;
  CustomObjectField?: InputMaybe<CustomObjectFieldCreateNestedManyWithoutOrganizationInput>;
  CustomObjectFieldForeignKey?: InputMaybe<CustomObjectFieldForeignKeyCreateNestedManyWithoutOrganizationInput>;
  CustomObjectRecord?: InputMaybe<CustomObjectRecordCreateNestedManyWithoutOrganizationInput>;
  CustomObjectRow?: InputMaybe<CustomObjectRowCreateNestedManyWithoutOrganizationInput>;
  CustomQueueItems?: InputMaybe<CustomQueueItemCreateNestedManyWithoutOrganizationInput>;
  DashboardColumnOptions?: InputMaybe<DashboardColumnOptionCreateNestedManyWithoutOrganizationInput>;
  DashboardLeadFilters?: InputMaybe<DashboardLeadFilterCreateNestedManyWithoutOrganizationInput>;
  DashboardRepFilters?: InputMaybe<DashboardRepFilterCreateNestedManyWithoutOrganizationInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewCreateNestedManyWithoutOrganizationInput>;
  day_to_close?: InputMaybe<Scalars['Int']['input']>;
  default_demo_time_duration?: InputMaybe<Scalars['Int']['input']>;
  demo_held_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_not_held_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_not_held_call_back_short_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_prep_time_minutes?: InputMaybe<Scalars['Int']['input']>;
  dev_account?: InputMaybe<Scalars['Boolean']['input']>;
  dial_rule?: InputMaybe<Scalars['Int']['input']>;
  DispositionMultiplier?: InputMaybe<DispositionMultiplierCreateNestedManyWithoutOrganizationInput>;
  dispositions?: InputMaybe<DispositionCreateNestedManyWithoutOrganizationInput>;
  double_booking?: InputMaybe<Scalars['Boolean']['input']>;
  dynamic_date_operators?: InputMaybe<Scalars['Boolean']['input']>;
  edge_servers?: InputMaybe<OrganizationCreateedge_ServersInput>;
  email_conflict_notification_email_list?: InputMaybe<OrganizationCreateemail_Conflict_Notification_Email_ListInput>;
  EmailItems?: InputMaybe<EmailItemCreateNestedManyWithoutOrganizationInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordCreateNestedManyWithoutOrganizationInput>;
  EmailThreads?: InputMaybe<EmailThreadCreateNestedManyWithoutOrganizationInput>;
  enable_modified_metrics?: InputMaybe<Scalars['Boolean']['input']>;
  enable_pay_period_view?: InputMaybe<Scalars['Boolean']['input']>;
  enable_time_constraint?: InputMaybe<Scalars['Boolean']['input']>;
  error_log_email_address?: InputMaybe<OrganizationCreateerror_Log_Email_AddressInput>;
  estimated_average_num_of_weekly_overbooked_demo_slots?: InputMaybe<Scalars['Int']['input']>;
  FolderPermissions?: InputMaybe<FolderPermissionCreateNestedManyWithoutOrganizationInput>;
  Folders?: InputMaybe<FolderCreateNestedManyWithoutOrganizationInput>;
  FutureBookingSetting?: InputMaybe<FutureBookingSettingCreateNestedOneWithoutOrganizationInput>;
  GlobalTransferRule?: InputMaybe<GlobalTransferRuleCreateNestedManyWithoutOrganizationInput>;
  held_demos_per_cc_connect?: InputMaybe<Scalars['Float']['input']>;
  helpful_links?: InputMaybe<OrganizationCreatehelpful_LinksInput>;
  HingePointMultiplier?: InputMaybe<HingePointMultiplierCreateNestedManyWithoutOrganizationInput>;
  hold_goal?: InputMaybe<Scalars['Float']['input']>;
  hubspot_credential?: InputMaybe<HubSpotCredentialCreateNestedOneWithoutOrganizationInput>;
  hubspot_last_sync_date?: InputMaybe<Scalars['DateTime']['input']>;
  hubspot_white_list?: InputMaybe<HubSpotWhitelistCreateNestedManyWithoutOrganizationInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  inbound_close_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_dm_connect_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_lt_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_revenue_value_of_sale?: InputMaybe<Scalars['Float']['input']>;
  inbound_scheduled_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_set_rate?: InputMaybe<Scalars['Float']['input']>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventCreateNestedManyWithoutOrganizationInput>;
  InboundConciergeWidget?: InputMaybe<InboundConciergeWidgetCreateNestedManyWithoutOrganizationInput>;
  industry_options?: InputMaybe<IndustryOptionCreateNestedManyWithoutOrganizationInput>;
  initial_sdr_sets_reps_day?: InputMaybe<Scalars['Float']['input']>;
  integration_error_logs?: InputMaybe<IntegrationErrorLogCreateNestedManyWithoutOrganizationInput>;
  integration_mappings?: InputMaybe<IntegrationMappingCreateNestedManyWithoutOrganizationInput>;
  IntegrationMappingCustomObject?: InputMaybe<IntegrationMappingCustomObjectCreateNestedManyWithoutOrganizationInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogCreateNestedManyWithoutOrganizationInput>;
  IntegrationReadOnlyObject?: InputMaybe<IntegrationReadOnlyObjectCreateNestedManyWithoutOrganizationInput>;
  international?: InputMaybe<Scalars['Boolean']['input']>;
  is_email_domain_verified?: InputMaybe<Scalars['Boolean']['input']>;
  is_salesforce_sandbox?: InputMaybe<Scalars['Boolean']['input']>;
  last_lead_value_compute_time?: InputMaybe<Scalars['DateTime']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutOrganizationInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryCreateNestedManyWithoutOrganizationInput>;
  lead_intent?: InputMaybe<LeadIntentCreateNestedManyWithoutOrganizationInput>;
  lead_release_same_rep_delay_rule?: InputMaybe<Scalars['Int']['input']>;
  lead_routing_email_list?: InputMaybe<OrganizationCreatelead_Routing_Email_ListInput>;
  lead_routing_rules?: InputMaybe<RuleCreateNestedManyWithoutOrganizationInput>;
  LeadCustomObjectRowAssociation?: InputMaybe<LeadCustomObjectRowAssociationCreateNestedManyWithoutOrganizationInput>;
  LeadFieldMultiplier?: InputMaybe<LeadFieldMultiplierCreateNestedManyWithoutOrganizationInput>;
  LeadFieldMultiplierCustom?: InputMaybe<LeadFieldMultiplierCustomCreateNestedManyWithoutOrganizationInput>;
  leads?: InputMaybe<LeadCreateNestedManyWithoutOrganizationInput>;
  leadsource_options?: InputMaybe<LeadSourceOptionCreateNestedManyWithoutOrganizationInput>;
  LeadValue?: InputMaybe<LeadValueCreateNestedManyWithoutOrganizationInput>;
  LifeTimeValueMultiplier?: InputMaybe<LifeTimeValueMultiplierCreateNestedManyWithoutOrganizationInput>;
  LiveTransferSetting?: InputMaybe<LiveTransferSettingCreateNestedOneWithoutOrganizationInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusCreateNestedManyWithoutOrganizationInput>;
  ltv_multiplier_custom_fields?: InputMaybe<LifeTimeValueMultiplierCustomCreateNestedManyWithoutOrganizationInput>;
  made_contact_rule?: InputMaybe<Scalars['Int']['input']>;
  make_sale_address_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_business_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_city_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_country_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_first_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_industry_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_last_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_lead_source_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_primary_email_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_primary_phone_number_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_state_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_sub_industry_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_zip_required?: InputMaybe<Scalars['Boolean']['input']>;
  maxium_day_lag_set_to_schedule?: InputMaybe<Scalars['Int']['input']>;
  mrr?: InputMaybe<Scalars['Int']['input']>;
  mrr_label?: InputMaybe<Scalars['String']['input']>;
  mrr_required_on_sale?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  NoteItems?: InputMaybe<NoteItemCreateNestedManyWithoutOrganizationInput>;
  Notifications?: InputMaybe<NotificationCreateNestedManyWithoutOrganizationInput>;
  onboardComplete?: InputMaybe<Scalars['Boolean']['input']>;
  Opportunity?: InputMaybe<SfOpportunityCreateNestedManyWithoutOrganizationInput>;
  organization_holidays?: InputMaybe<OrganizationHolidayCreateNestedManyWithoutOrganizationInput>;
  organization_hours?: InputMaybe<OrganizationHourCreateNestedManyWithoutOrganizationInput>;
  OrganizationPaymentSetting?: InputMaybe<OrganizationPaymentSettingsCreateNestedOneWithoutOrganizationInput>;
  OrganizationPaymentSettings?: InputMaybe<OrganizationPaymentSettingsCreateNestedOneWithoutOrganizationsInput>;
  orgCalendarInviteMetadata?: InputMaybe<OrgCalendarInviteMetadataCreateNestedManyWithoutOrganizationInput>;
  OrgDefaultPipelinePerecentValue?: InputMaybe<OrgDefaultPipelinePerecentValueCreateNestedOneWithoutOrganizationInput>;
  OrgHubSpotSync?: InputMaybe<OrgHubSpotSyncCreateNestedOneWithoutOrganizationInput>;
  OrgNoEventDate?: InputMaybe<OrgNoEventDateCreateNestedManyWithoutOrganizationInput>;
  OrgSalesforceSync?: InputMaybe<OrgSalesforceSyncCreateNestedOneWithoutOrganizationInput>;
  OrgTimeslotAvailability?: InputMaybe<OrgTimeslotAvailabilityCreateNestedManyWithoutOrganizationInput>;
  outbound_close_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_dm_connect_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_lt_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_revenue_value_of_sale?: InputMaybe<Scalars['Float']['input']>;
  outbound_scheduled_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_set_rate?: InputMaybe<Scalars['Float']['input']>;
  PandadocData?: InputMaybe<PandadocDataCreateNestedOneWithoutOrganizationInput>;
  PandaDocTemplate?: InputMaybe<PandaDocTemplateCreateNestedManyWithoutOrganizationInput>;
  participant_jitter_buffer_size?: InputMaybe<JitterBufferSize>;
  preferred_goal_setting?: InputMaybe<Goalsetting>;
  prepayment?: InputMaybe<Scalars['Boolean']['input']>;
  ProductChangeLog?: InputMaybe<ProductChangeLogCreateNestedManyWithoutOrganizationInput>;
  products?: InputMaybe<ProductCreateNestedManyWithoutOrganizationInput>;
  re_cold_call_hour_delay?: InputMaybe<Scalars['Float']['input']>;
  recordingStatus?: InputMaybe<RecordingStatus>;
  release_lead_resting?: InputMaybe<Scalars['Int']['input']>;
  rep_claim_limit?: InputMaybe<Scalars['Int']['input']>;
  rep_lead_allotment?: InputMaybe<Scalars['Int']['input']>;
  rep_level_call_transfer?: InputMaybe<Scalars['Boolean']['input']>;
  reps_can_bulk_unassign?: InputMaybe<Scalars['Boolean']['input']>;
  reps_can_view_all_notes?: InputMaybe<Scalars['Boolean']['input']>;
  require_sale_notes?: InputMaybe<Scalars['Boolean']['input']>;
  resting_after_skipped_CC?: InputMaybe<Scalars['Int']['input']>;
  resting_period?: InputMaybe<Scalars['Int']['input']>;
  resting_period_after_unsuccessful_cold_call?: InputMaybe<Scalars['Float']['input']>;
  resting_period_after_unsuccessful_demo?: InputMaybe<Scalars['Float']['input']>;
  revenue_plan?: InputMaybe<RevenuePlanItemCreateNestedManyWithoutOrganizationInput>;
  Routings?: InputMaybe<RoutingCreateNestedManyWithoutOrganizationInput>;
  sale_field_options?: InputMaybe<SaleFieldOptionCreateNestedManyWithoutOrganizationInput>;
  sale_notification_email_list?: InputMaybe<OrganizationCreatesale_Notification_Email_ListInput>;
  SaleConfig?: InputMaybe<SaleConfigCreateNestedOneWithoutOrganizationInput>;
  SaleConfigPage?: InputMaybe<SaleConfigPageCreateNestedManyWithoutOrganizationInput>;
  SaleCycles?: InputMaybe<SaleCycleCreateNestedManyWithoutOrganizationInput>;
  SaleItems?: InputMaybe<SaleItemCreateNestedManyWithoutOrganizationInput>;
  sales?: InputMaybe<SaleCreateNestedManyWithoutOrganizationInput>;
  salesforce_domain?: InputMaybe<Scalars['String']['input']>;
  salesforce_last_sync_date?: InputMaybe<Scalars['DateTime']['input']>;
  SalesForceColumn?: InputMaybe<SalesForceColumnCreateNestedManyWithoutOrganizationInput>;
  salesForceData?: InputMaybe<SalesForceDataCreateNestedOneWithoutOrganizationInput>;
  ScheduleItems?: InputMaybe<ScheduleItemCreateNestedManyWithoutOrganizationInput>;
  self_sourcing_rule?: InputMaybe<Scalars['Int']['input']>;
  send_error_log_email?: InputMaybe<Scalars['Boolean']['input']>;
  send_lead_routing_email?: InputMaybe<Scalars['Boolean']['input']>;
  sendgrid_email_domain?: InputMaybe<Scalars['String']['input']>;
  sendgrid_email_domain_id?: InputMaybe<Scalars['Int']['input']>;
  sequence?: InputMaybe<SequenceCreateNestedManyWithoutOrganzationInput>;
  SequenceCriteriaConditions?: InputMaybe<SequenceCriteriaConditionCreateNestedManyWithoutOrganizationInput>;
  SequenceDashboardViews?: InputMaybe<SequenceDashboardViewCreateNestedManyWithoutOrganizationInput>;
  SequenceEntryCriterias?: InputMaybe<SequenceEntryCriteriaCreateNestedManyWithoutOrganizationInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutOrganizationInput>;
  SequenceSteps?: InputMaybe<SequenceStepCreateNestedManyWithoutOrganizationInput>;
  set_goal?: InputMaybe<Scalars['Float']['input']>;
  short_resting?: InputMaybe<Scalars['Int']['input']>;
  show_calendar_options?: InputMaybe<Scalars['Boolean']['input']>;
  show_contract_duration_sales?: InputMaybe<Scalars['Boolean']['input']>;
  show_lead_source_to_rep?: InputMaybe<Scalars['Boolean']['input']>;
  show_nocontact_button?: InputMaybe<Scalars['Boolean']['input']>;
  show_payment_terms_sales?: InputMaybe<Scalars['Boolean']['input']>;
  show_rep_all_leads?: InputMaybe<Scalars['Boolean']['input']>;
  show_rep_my_leads?: InputMaybe<Scalars['Boolean']['input']>;
  show_system_view?: InputMaybe<Scalars['Boolean']['input']>;
  sites?: InputMaybe<SiteCreateNestedManyWithoutOrganizationInput>;
  sms_opt_in_dialog?: InputMaybe<Scalars['String']['input']>;
  SMSItems?: InputMaybe<SmsItemCreateNestedManyWithoutOrganizationInput>;
  SnoozeTrackings?: InputMaybe<SnoozeTrackingCreateNestedManyWithoutOrganizationInput>;
  StepActionLogs?: InputMaybe<StepActionLogCreateNestedManyWithoutOrganizationInput>;
  StepActions?: InputMaybe<StepActionCreateNestedManyWithoutOrganizationInput>;
  StepConditions?: InputMaybe<StepConditionCreateNestedManyWithoutOrganizationInput>;
  teams?: InputMaybe<TeamCreateNestedManyWithoutOrganizationInput>;
  Template?: InputMaybe<TemplateCreateNestedManyWithoutOrganizationInput>;
  TemplateVersions?: InputMaybe<TemplateVersionCreateNestedManyWithoutOrganizationInput>;
  timekit_id?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  transfer_timeout?: InputMaybe<Scalars['Int']['input']>;
  TransferAttempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutOrganizationInput>;
  twilio_numbers?: InputMaybe<TwilioNumberCreateNestedManyWithoutOrganizationInput>;
  TwilioFeedbacks?: InputMaybe<TwilioFeedbackCreateNestedManyWithoutOrganizationInput>;
  TwilioInfo?: InputMaybe<TwilioInfoCreateNestedOneWithoutOrganzationInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  use_revenue_planning?: InputMaybe<Scalars['Boolean']['input']>;
  UserCustomFieldFilters?: InputMaybe<UserCustomFieldFilterCreateNestedManyWithoutOrganizationInput>;
  users?: InputMaybe<UserCreateNestedManyWithoutOrganizationInput>;
  win_rep_day_goal?: InputMaybe<Scalars['Float']['input']>;
};

export type OrganizationCreateWithoutSaleCyclesInput = {
  ActivityMonitorMultiplier?: InputMaybe<ActivityMonitorMultiplierCreateNestedManyWithoutOrganizationInput>;
  algoFactor?: InputMaybe<AlgoFactorCreateNestedManyWithoutOrganizationInput>;
  allow_calendar_invite?: InputMaybe<Scalars['Boolean']['input']>;
  allow_remove_step_action?: InputMaybe<Scalars['Boolean']['input']>;
  allow_snooze_step_action?: InputMaybe<Scalars['Boolean']['input']>;
  always_record?: InputMaybe<Scalars['Boolean']['input']>;
  arv_initial_held_demos_generated_by_sdr_weight?: InputMaybe<Scalars['Float']['input']>;
  AssociationHistories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutOrganizationInput>;
  auto_call_interval?: InputMaybe<Scalars['Int']['input']>;
  auto_dial?: InputMaybe<Scalars['Boolean']['input']>;
  automated_campaigns?: InputMaybe<AutomatedCampaignCreateNestedManyWithoutOrganizationInput>;
  automatic_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  AutoTransferSelections?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutOrganizationInput>;
  Availablities?: InputMaybe<AvailabilityCreateNestedManyWithoutOrganizationInput>;
  AverageOrderValue?: InputMaybe<AverageOrderValueCreateNestedManyWithoutOrganizationInput>;
  best_action_ever_multiplier?: InputMaybe<Scalars['Float']['input']>;
  best_metrics?: InputMaybe<BestMetricRecordCreateNestedManyWithoutOrganizationInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptCreateNestedManyWithoutOrganizationInput>;
  callMeNows?: InputMaybe<CallMeNowCreateNestedManyWithoutOrganizationInput>;
  CallMultiplier?: InputMaybe<CallMultiplierCreateNestedManyWithoutOrganizationInput>;
  CallSharings?: InputMaybe<CallSharingCreateNestedManyWithoutOrganizationInput>;
  can_edit_sdr_transfer_rules?: InputMaybe<Scalars['Boolean']['input']>;
  claim_rule?: InputMaybe<Scalars['Int']['input']>;
  concession?: InputMaybe<ConcessionCreateNestedManyWithoutOrganizationInput>;
  conference_region?: InputMaybe<ConferenceRegion>;
  Conferences?: InputMaybe<ConferenceCreateNestedManyWithoutOrganizationInput>;
  ContactRequests?: InputMaybe<ContactRequestCreateNestedManyWithoutOrganizationInput>;
  Contacts?: InputMaybe<ContactCreateNestedManyWithoutOrganizationInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  custom_fields?: InputMaybe<CustomFieldCreateNestedManyWithoutOrganizationInput>;
  CustomObject?: InputMaybe<CustomObjectCreateNestedManyWithoutOrganizationInput>;
  CustomObjectField?: InputMaybe<CustomObjectFieldCreateNestedManyWithoutOrganizationInput>;
  CustomObjectFieldForeignKey?: InputMaybe<CustomObjectFieldForeignKeyCreateNestedManyWithoutOrganizationInput>;
  CustomObjectRecord?: InputMaybe<CustomObjectRecordCreateNestedManyWithoutOrganizationInput>;
  CustomObjectRow?: InputMaybe<CustomObjectRowCreateNestedManyWithoutOrganizationInput>;
  CustomQueueItems?: InputMaybe<CustomQueueItemCreateNestedManyWithoutOrganizationInput>;
  DashboardColumnOptions?: InputMaybe<DashboardColumnOptionCreateNestedManyWithoutOrganizationInput>;
  DashboardLeadFilters?: InputMaybe<DashboardLeadFilterCreateNestedManyWithoutOrganizationInput>;
  DashboardRepFilters?: InputMaybe<DashboardRepFilterCreateNestedManyWithoutOrganizationInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewCreateNestedManyWithoutOrganizationInput>;
  day_to_close?: InputMaybe<Scalars['Int']['input']>;
  default_demo_time_duration?: InputMaybe<Scalars['Int']['input']>;
  demo_held_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_not_held_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_not_held_call_back_short_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_prep_time_minutes?: InputMaybe<Scalars['Int']['input']>;
  dev_account?: InputMaybe<Scalars['Boolean']['input']>;
  dial_rule?: InputMaybe<Scalars['Int']['input']>;
  DispositionMultiplier?: InputMaybe<DispositionMultiplierCreateNestedManyWithoutOrganizationInput>;
  dispositions?: InputMaybe<DispositionCreateNestedManyWithoutOrganizationInput>;
  double_booking?: InputMaybe<Scalars['Boolean']['input']>;
  dynamic_date_operators?: InputMaybe<Scalars['Boolean']['input']>;
  edge_servers?: InputMaybe<OrganizationCreateedge_ServersInput>;
  email_conflict_notification_email_list?: InputMaybe<OrganizationCreateemail_Conflict_Notification_Email_ListInput>;
  EmailItems?: InputMaybe<EmailItemCreateNestedManyWithoutOrganizationInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordCreateNestedManyWithoutOrganizationInput>;
  EmailThreads?: InputMaybe<EmailThreadCreateNestedManyWithoutOrganizationInput>;
  enable_modified_metrics?: InputMaybe<Scalars['Boolean']['input']>;
  enable_pay_period_view?: InputMaybe<Scalars['Boolean']['input']>;
  enable_time_constraint?: InputMaybe<Scalars['Boolean']['input']>;
  error_log_email_address?: InputMaybe<OrganizationCreateerror_Log_Email_AddressInput>;
  estimated_average_num_of_weekly_overbooked_demo_slots?: InputMaybe<Scalars['Int']['input']>;
  FolderPermissions?: InputMaybe<FolderPermissionCreateNestedManyWithoutOrganizationInput>;
  Folders?: InputMaybe<FolderCreateNestedManyWithoutOrganizationInput>;
  FutureBookingSetting?: InputMaybe<FutureBookingSettingCreateNestedOneWithoutOrganizationInput>;
  GlobalTransferRule?: InputMaybe<GlobalTransferRuleCreateNestedManyWithoutOrganizationInput>;
  held_demos_per_cc_connect?: InputMaybe<Scalars['Float']['input']>;
  helpful_links?: InputMaybe<OrganizationCreatehelpful_LinksInput>;
  HingePointMultiplier?: InputMaybe<HingePointMultiplierCreateNestedManyWithoutOrganizationInput>;
  hold_goal?: InputMaybe<Scalars['Float']['input']>;
  hubspot_credential?: InputMaybe<HubSpotCredentialCreateNestedOneWithoutOrganizationInput>;
  hubspot_last_sync_date?: InputMaybe<Scalars['DateTime']['input']>;
  hubspot_white_list?: InputMaybe<HubSpotWhitelistCreateNestedManyWithoutOrganizationInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  inbound_close_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_dm_connect_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_lt_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_revenue_value_of_sale?: InputMaybe<Scalars['Float']['input']>;
  inbound_scheduled_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_set_rate?: InputMaybe<Scalars['Float']['input']>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventCreateNestedManyWithoutOrganizationInput>;
  InboundConciergeWidget?: InputMaybe<InboundConciergeWidgetCreateNestedManyWithoutOrganizationInput>;
  industry_options?: InputMaybe<IndustryOptionCreateNestedManyWithoutOrganizationInput>;
  initial_sdr_sets_reps_day?: InputMaybe<Scalars['Float']['input']>;
  integration_error_logs?: InputMaybe<IntegrationErrorLogCreateNestedManyWithoutOrganizationInput>;
  integration_mappings?: InputMaybe<IntegrationMappingCreateNestedManyWithoutOrganizationInput>;
  IntegrationMappingCustomObject?: InputMaybe<IntegrationMappingCustomObjectCreateNestedManyWithoutOrganizationInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogCreateNestedManyWithoutOrganizationInput>;
  IntegrationReadOnlyObject?: InputMaybe<IntegrationReadOnlyObjectCreateNestedManyWithoutOrganizationInput>;
  international?: InputMaybe<Scalars['Boolean']['input']>;
  is_email_domain_verified?: InputMaybe<Scalars['Boolean']['input']>;
  is_salesforce_sandbox?: InputMaybe<Scalars['Boolean']['input']>;
  last_lead_value_compute_time?: InputMaybe<Scalars['DateTime']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutOrganizationInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryCreateNestedManyWithoutOrganizationInput>;
  lead_intent?: InputMaybe<LeadIntentCreateNestedManyWithoutOrganizationInput>;
  lead_release_same_rep_delay_rule?: InputMaybe<Scalars['Int']['input']>;
  lead_routing_email_list?: InputMaybe<OrganizationCreatelead_Routing_Email_ListInput>;
  lead_routing_rules?: InputMaybe<RuleCreateNestedManyWithoutOrganizationInput>;
  LeadCustomObjectRowAssociation?: InputMaybe<LeadCustomObjectRowAssociationCreateNestedManyWithoutOrganizationInput>;
  LeadFieldMultiplier?: InputMaybe<LeadFieldMultiplierCreateNestedManyWithoutOrganizationInput>;
  LeadFieldMultiplierCustom?: InputMaybe<LeadFieldMultiplierCustomCreateNestedManyWithoutOrganizationInput>;
  leads?: InputMaybe<LeadCreateNestedManyWithoutOrganizationInput>;
  leadsource_options?: InputMaybe<LeadSourceOptionCreateNestedManyWithoutOrganizationInput>;
  LeadValue?: InputMaybe<LeadValueCreateNestedManyWithoutOrganizationInput>;
  LifeTimeValueMultiplier?: InputMaybe<LifeTimeValueMultiplierCreateNestedManyWithoutOrganizationInput>;
  LiveTransferSetting?: InputMaybe<LiveTransferSettingCreateNestedOneWithoutOrganizationInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusCreateNestedManyWithoutOrganizationInput>;
  ltv_multiplier_custom_fields?: InputMaybe<LifeTimeValueMultiplierCustomCreateNestedManyWithoutOrganizationInput>;
  made_contact_rule?: InputMaybe<Scalars['Int']['input']>;
  make_sale_address_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_business_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_city_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_country_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_first_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_industry_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_last_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_lead_source_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_primary_email_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_primary_phone_number_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_state_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_sub_industry_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_zip_required?: InputMaybe<Scalars['Boolean']['input']>;
  maxium_day_lag_set_to_schedule?: InputMaybe<Scalars['Int']['input']>;
  mrr?: InputMaybe<Scalars['Int']['input']>;
  mrr_label?: InputMaybe<Scalars['String']['input']>;
  mrr_required_on_sale?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  NoteItems?: InputMaybe<NoteItemCreateNestedManyWithoutOrganizationInput>;
  Notifications?: InputMaybe<NotificationCreateNestedManyWithoutOrganizationInput>;
  onboardComplete?: InputMaybe<Scalars['Boolean']['input']>;
  Opportunity?: InputMaybe<SfOpportunityCreateNestedManyWithoutOrganizationInput>;
  organization_holidays?: InputMaybe<OrganizationHolidayCreateNestedManyWithoutOrganizationInput>;
  organization_hours?: InputMaybe<OrganizationHourCreateNestedManyWithoutOrganizationInput>;
  OrganizationPaymentSetting?: InputMaybe<OrganizationPaymentSettingsCreateNestedOneWithoutOrganizationInput>;
  OrganizationPaymentSettings?: InputMaybe<OrganizationPaymentSettingsCreateNestedOneWithoutOrganizationsInput>;
  orgCalendarInviteMetadata?: InputMaybe<OrgCalendarInviteMetadataCreateNestedManyWithoutOrganizationInput>;
  OrgDefaultPipelinePerecentValue?: InputMaybe<OrgDefaultPipelinePerecentValueCreateNestedOneWithoutOrganizationInput>;
  OrgHubSpotSync?: InputMaybe<OrgHubSpotSyncCreateNestedOneWithoutOrganizationInput>;
  OrgNoEventDate?: InputMaybe<OrgNoEventDateCreateNestedManyWithoutOrganizationInput>;
  OrgSalesforceSync?: InputMaybe<OrgSalesforceSyncCreateNestedOneWithoutOrganizationInput>;
  OrgTimeslotAvailability?: InputMaybe<OrgTimeslotAvailabilityCreateNestedManyWithoutOrganizationInput>;
  outbound_close_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_dm_connect_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_lt_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_revenue_value_of_sale?: InputMaybe<Scalars['Float']['input']>;
  outbound_scheduled_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_set_rate?: InputMaybe<Scalars['Float']['input']>;
  PandadocData?: InputMaybe<PandadocDataCreateNestedOneWithoutOrganizationInput>;
  PandaDocTemplate?: InputMaybe<PandaDocTemplateCreateNestedManyWithoutOrganizationInput>;
  participant_jitter_buffer_size?: InputMaybe<JitterBufferSize>;
  preferred_goal_setting?: InputMaybe<Goalsetting>;
  prepayment?: InputMaybe<Scalars['Boolean']['input']>;
  ProductChangeLog?: InputMaybe<ProductChangeLogCreateNestedManyWithoutOrganizationInput>;
  products?: InputMaybe<ProductCreateNestedManyWithoutOrganizationInput>;
  re_cold_call_hour_delay?: InputMaybe<Scalars['Float']['input']>;
  recordingStatus?: InputMaybe<RecordingStatus>;
  release_lead_resting?: InputMaybe<Scalars['Int']['input']>;
  rep_claim_limit?: InputMaybe<Scalars['Int']['input']>;
  rep_lead_allotment?: InputMaybe<Scalars['Int']['input']>;
  rep_level_call_transfer?: InputMaybe<Scalars['Boolean']['input']>;
  reps_can_bulk_unassign?: InputMaybe<Scalars['Boolean']['input']>;
  reps_can_view_all_notes?: InputMaybe<Scalars['Boolean']['input']>;
  require_sale_notes?: InputMaybe<Scalars['Boolean']['input']>;
  resting_after_skipped_CC?: InputMaybe<Scalars['Int']['input']>;
  resting_period?: InputMaybe<Scalars['Int']['input']>;
  resting_period_after_unsuccessful_cold_call?: InputMaybe<Scalars['Float']['input']>;
  resting_period_after_unsuccessful_demo?: InputMaybe<Scalars['Float']['input']>;
  revenue_plan?: InputMaybe<RevenuePlanItemCreateNestedManyWithoutOrganizationInput>;
  Routings?: InputMaybe<RoutingCreateNestedManyWithoutOrganizationInput>;
  sale_field_options?: InputMaybe<SaleFieldOptionCreateNestedManyWithoutOrganizationInput>;
  sale_notification_email_list?: InputMaybe<OrganizationCreatesale_Notification_Email_ListInput>;
  SaleConfig?: InputMaybe<SaleConfigCreateNestedOneWithoutOrganizationInput>;
  SaleConfigPage?: InputMaybe<SaleConfigPageCreateNestedManyWithoutOrganizationInput>;
  SaleConfigSection?: InputMaybe<SaleConfigSectionCreateNestedManyWithoutOrganizationInput>;
  SaleItems?: InputMaybe<SaleItemCreateNestedManyWithoutOrganizationInput>;
  sales?: InputMaybe<SaleCreateNestedManyWithoutOrganizationInput>;
  salesforce_domain?: InputMaybe<Scalars['String']['input']>;
  salesforce_last_sync_date?: InputMaybe<Scalars['DateTime']['input']>;
  SalesForceColumn?: InputMaybe<SalesForceColumnCreateNestedManyWithoutOrganizationInput>;
  salesForceData?: InputMaybe<SalesForceDataCreateNestedOneWithoutOrganizationInput>;
  ScheduleItems?: InputMaybe<ScheduleItemCreateNestedManyWithoutOrganizationInput>;
  self_sourcing_rule?: InputMaybe<Scalars['Int']['input']>;
  send_error_log_email?: InputMaybe<Scalars['Boolean']['input']>;
  send_lead_routing_email?: InputMaybe<Scalars['Boolean']['input']>;
  sendgrid_email_domain?: InputMaybe<Scalars['String']['input']>;
  sendgrid_email_domain_id?: InputMaybe<Scalars['Int']['input']>;
  sequence?: InputMaybe<SequenceCreateNestedManyWithoutOrganzationInput>;
  SequenceCriteriaConditions?: InputMaybe<SequenceCriteriaConditionCreateNestedManyWithoutOrganizationInput>;
  SequenceDashboardViews?: InputMaybe<SequenceDashboardViewCreateNestedManyWithoutOrganizationInput>;
  SequenceEntryCriterias?: InputMaybe<SequenceEntryCriteriaCreateNestedManyWithoutOrganizationInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutOrganizationInput>;
  SequenceSteps?: InputMaybe<SequenceStepCreateNestedManyWithoutOrganizationInput>;
  set_goal?: InputMaybe<Scalars['Float']['input']>;
  short_resting?: InputMaybe<Scalars['Int']['input']>;
  show_calendar_options?: InputMaybe<Scalars['Boolean']['input']>;
  show_contract_duration_sales?: InputMaybe<Scalars['Boolean']['input']>;
  show_lead_source_to_rep?: InputMaybe<Scalars['Boolean']['input']>;
  show_nocontact_button?: InputMaybe<Scalars['Boolean']['input']>;
  show_payment_terms_sales?: InputMaybe<Scalars['Boolean']['input']>;
  show_rep_all_leads?: InputMaybe<Scalars['Boolean']['input']>;
  show_rep_my_leads?: InputMaybe<Scalars['Boolean']['input']>;
  show_system_view?: InputMaybe<Scalars['Boolean']['input']>;
  sites?: InputMaybe<SiteCreateNestedManyWithoutOrganizationInput>;
  sms_opt_in_dialog?: InputMaybe<Scalars['String']['input']>;
  SMSItems?: InputMaybe<SmsItemCreateNestedManyWithoutOrganizationInput>;
  SnoozeTrackings?: InputMaybe<SnoozeTrackingCreateNestedManyWithoutOrganizationInput>;
  StepActionLogs?: InputMaybe<StepActionLogCreateNestedManyWithoutOrganizationInput>;
  StepActions?: InputMaybe<StepActionCreateNestedManyWithoutOrganizationInput>;
  StepConditions?: InputMaybe<StepConditionCreateNestedManyWithoutOrganizationInput>;
  teams?: InputMaybe<TeamCreateNestedManyWithoutOrganizationInput>;
  Template?: InputMaybe<TemplateCreateNestedManyWithoutOrganizationInput>;
  TemplateVersions?: InputMaybe<TemplateVersionCreateNestedManyWithoutOrganizationInput>;
  timekit_id?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  transfer_timeout?: InputMaybe<Scalars['Int']['input']>;
  TransferAttempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutOrganizationInput>;
  twilio_numbers?: InputMaybe<TwilioNumberCreateNestedManyWithoutOrganizationInput>;
  TwilioFeedbacks?: InputMaybe<TwilioFeedbackCreateNestedManyWithoutOrganizationInput>;
  TwilioInfo?: InputMaybe<TwilioInfoCreateNestedOneWithoutOrganzationInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  use_revenue_planning?: InputMaybe<Scalars['Boolean']['input']>;
  UserCustomFieldFilters?: InputMaybe<UserCustomFieldFilterCreateNestedManyWithoutOrganizationInput>;
  users?: InputMaybe<UserCreateNestedManyWithoutOrganizationInput>;
  win_rep_day_goal?: InputMaybe<Scalars['Float']['input']>;
};

export type OrganizationCreateWithoutSaleItemsInput = {
  ActivityMonitorMultiplier?: InputMaybe<ActivityMonitorMultiplierCreateNestedManyWithoutOrganizationInput>;
  algoFactor?: InputMaybe<AlgoFactorCreateNestedManyWithoutOrganizationInput>;
  allow_calendar_invite?: InputMaybe<Scalars['Boolean']['input']>;
  allow_remove_step_action?: InputMaybe<Scalars['Boolean']['input']>;
  allow_snooze_step_action?: InputMaybe<Scalars['Boolean']['input']>;
  always_record?: InputMaybe<Scalars['Boolean']['input']>;
  arv_initial_held_demos_generated_by_sdr_weight?: InputMaybe<Scalars['Float']['input']>;
  AssociationHistories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutOrganizationInput>;
  auto_call_interval?: InputMaybe<Scalars['Int']['input']>;
  auto_dial?: InputMaybe<Scalars['Boolean']['input']>;
  automated_campaigns?: InputMaybe<AutomatedCampaignCreateNestedManyWithoutOrganizationInput>;
  automatic_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  AutoTransferSelections?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutOrganizationInput>;
  Availablities?: InputMaybe<AvailabilityCreateNestedManyWithoutOrganizationInput>;
  AverageOrderValue?: InputMaybe<AverageOrderValueCreateNestedManyWithoutOrganizationInput>;
  best_action_ever_multiplier?: InputMaybe<Scalars['Float']['input']>;
  best_metrics?: InputMaybe<BestMetricRecordCreateNestedManyWithoutOrganizationInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptCreateNestedManyWithoutOrganizationInput>;
  callMeNows?: InputMaybe<CallMeNowCreateNestedManyWithoutOrganizationInput>;
  CallMultiplier?: InputMaybe<CallMultiplierCreateNestedManyWithoutOrganizationInput>;
  CallSharings?: InputMaybe<CallSharingCreateNestedManyWithoutOrganizationInput>;
  can_edit_sdr_transfer_rules?: InputMaybe<Scalars['Boolean']['input']>;
  claim_rule?: InputMaybe<Scalars['Int']['input']>;
  concession?: InputMaybe<ConcessionCreateNestedManyWithoutOrganizationInput>;
  conference_region?: InputMaybe<ConferenceRegion>;
  Conferences?: InputMaybe<ConferenceCreateNestedManyWithoutOrganizationInput>;
  ContactRequests?: InputMaybe<ContactRequestCreateNestedManyWithoutOrganizationInput>;
  Contacts?: InputMaybe<ContactCreateNestedManyWithoutOrganizationInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  custom_fields?: InputMaybe<CustomFieldCreateNestedManyWithoutOrganizationInput>;
  CustomObject?: InputMaybe<CustomObjectCreateNestedManyWithoutOrganizationInput>;
  CustomObjectField?: InputMaybe<CustomObjectFieldCreateNestedManyWithoutOrganizationInput>;
  CustomObjectFieldForeignKey?: InputMaybe<CustomObjectFieldForeignKeyCreateNestedManyWithoutOrganizationInput>;
  CustomObjectRecord?: InputMaybe<CustomObjectRecordCreateNestedManyWithoutOrganizationInput>;
  CustomObjectRow?: InputMaybe<CustomObjectRowCreateNestedManyWithoutOrganizationInput>;
  CustomQueueItems?: InputMaybe<CustomQueueItemCreateNestedManyWithoutOrganizationInput>;
  DashboardColumnOptions?: InputMaybe<DashboardColumnOptionCreateNestedManyWithoutOrganizationInput>;
  DashboardLeadFilters?: InputMaybe<DashboardLeadFilterCreateNestedManyWithoutOrganizationInput>;
  DashboardRepFilters?: InputMaybe<DashboardRepFilterCreateNestedManyWithoutOrganizationInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewCreateNestedManyWithoutOrganizationInput>;
  day_to_close?: InputMaybe<Scalars['Int']['input']>;
  default_demo_time_duration?: InputMaybe<Scalars['Int']['input']>;
  demo_held_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_not_held_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_not_held_call_back_short_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_prep_time_minutes?: InputMaybe<Scalars['Int']['input']>;
  dev_account?: InputMaybe<Scalars['Boolean']['input']>;
  dial_rule?: InputMaybe<Scalars['Int']['input']>;
  DispositionMultiplier?: InputMaybe<DispositionMultiplierCreateNestedManyWithoutOrganizationInput>;
  dispositions?: InputMaybe<DispositionCreateNestedManyWithoutOrganizationInput>;
  double_booking?: InputMaybe<Scalars['Boolean']['input']>;
  dynamic_date_operators?: InputMaybe<Scalars['Boolean']['input']>;
  edge_servers?: InputMaybe<OrganizationCreateedge_ServersInput>;
  email_conflict_notification_email_list?: InputMaybe<OrganizationCreateemail_Conflict_Notification_Email_ListInput>;
  EmailItems?: InputMaybe<EmailItemCreateNestedManyWithoutOrganizationInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordCreateNestedManyWithoutOrganizationInput>;
  EmailThreads?: InputMaybe<EmailThreadCreateNestedManyWithoutOrganizationInput>;
  enable_modified_metrics?: InputMaybe<Scalars['Boolean']['input']>;
  enable_pay_period_view?: InputMaybe<Scalars['Boolean']['input']>;
  enable_time_constraint?: InputMaybe<Scalars['Boolean']['input']>;
  error_log_email_address?: InputMaybe<OrganizationCreateerror_Log_Email_AddressInput>;
  estimated_average_num_of_weekly_overbooked_demo_slots?: InputMaybe<Scalars['Int']['input']>;
  FolderPermissions?: InputMaybe<FolderPermissionCreateNestedManyWithoutOrganizationInput>;
  Folders?: InputMaybe<FolderCreateNestedManyWithoutOrganizationInput>;
  FutureBookingSetting?: InputMaybe<FutureBookingSettingCreateNestedOneWithoutOrganizationInput>;
  GlobalTransferRule?: InputMaybe<GlobalTransferRuleCreateNestedManyWithoutOrganizationInput>;
  held_demos_per_cc_connect?: InputMaybe<Scalars['Float']['input']>;
  helpful_links?: InputMaybe<OrganizationCreatehelpful_LinksInput>;
  HingePointMultiplier?: InputMaybe<HingePointMultiplierCreateNestedManyWithoutOrganizationInput>;
  hold_goal?: InputMaybe<Scalars['Float']['input']>;
  hubspot_credential?: InputMaybe<HubSpotCredentialCreateNestedOneWithoutOrganizationInput>;
  hubspot_last_sync_date?: InputMaybe<Scalars['DateTime']['input']>;
  hubspot_white_list?: InputMaybe<HubSpotWhitelistCreateNestedManyWithoutOrganizationInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  inbound_close_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_dm_connect_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_lt_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_revenue_value_of_sale?: InputMaybe<Scalars['Float']['input']>;
  inbound_scheduled_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_set_rate?: InputMaybe<Scalars['Float']['input']>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventCreateNestedManyWithoutOrganizationInput>;
  InboundConciergeWidget?: InputMaybe<InboundConciergeWidgetCreateNestedManyWithoutOrganizationInput>;
  industry_options?: InputMaybe<IndustryOptionCreateNestedManyWithoutOrganizationInput>;
  initial_sdr_sets_reps_day?: InputMaybe<Scalars['Float']['input']>;
  integration_error_logs?: InputMaybe<IntegrationErrorLogCreateNestedManyWithoutOrganizationInput>;
  integration_mappings?: InputMaybe<IntegrationMappingCreateNestedManyWithoutOrganizationInput>;
  IntegrationMappingCustomObject?: InputMaybe<IntegrationMappingCustomObjectCreateNestedManyWithoutOrganizationInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogCreateNestedManyWithoutOrganizationInput>;
  IntegrationReadOnlyObject?: InputMaybe<IntegrationReadOnlyObjectCreateNestedManyWithoutOrganizationInput>;
  international?: InputMaybe<Scalars['Boolean']['input']>;
  is_email_domain_verified?: InputMaybe<Scalars['Boolean']['input']>;
  is_salesforce_sandbox?: InputMaybe<Scalars['Boolean']['input']>;
  last_lead_value_compute_time?: InputMaybe<Scalars['DateTime']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutOrganizationInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryCreateNestedManyWithoutOrganizationInput>;
  lead_intent?: InputMaybe<LeadIntentCreateNestedManyWithoutOrganizationInput>;
  lead_release_same_rep_delay_rule?: InputMaybe<Scalars['Int']['input']>;
  lead_routing_email_list?: InputMaybe<OrganizationCreatelead_Routing_Email_ListInput>;
  lead_routing_rules?: InputMaybe<RuleCreateNestedManyWithoutOrganizationInput>;
  LeadCustomObjectRowAssociation?: InputMaybe<LeadCustomObjectRowAssociationCreateNestedManyWithoutOrganizationInput>;
  LeadFieldMultiplier?: InputMaybe<LeadFieldMultiplierCreateNestedManyWithoutOrganizationInput>;
  LeadFieldMultiplierCustom?: InputMaybe<LeadFieldMultiplierCustomCreateNestedManyWithoutOrganizationInput>;
  leads?: InputMaybe<LeadCreateNestedManyWithoutOrganizationInput>;
  leadsource_options?: InputMaybe<LeadSourceOptionCreateNestedManyWithoutOrganizationInput>;
  LeadValue?: InputMaybe<LeadValueCreateNestedManyWithoutOrganizationInput>;
  LifeTimeValueMultiplier?: InputMaybe<LifeTimeValueMultiplierCreateNestedManyWithoutOrganizationInput>;
  LiveTransferSetting?: InputMaybe<LiveTransferSettingCreateNestedOneWithoutOrganizationInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusCreateNestedManyWithoutOrganizationInput>;
  ltv_multiplier_custom_fields?: InputMaybe<LifeTimeValueMultiplierCustomCreateNestedManyWithoutOrganizationInput>;
  made_contact_rule?: InputMaybe<Scalars['Int']['input']>;
  make_sale_address_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_business_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_city_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_country_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_first_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_industry_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_last_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_lead_source_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_primary_email_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_primary_phone_number_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_state_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_sub_industry_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_zip_required?: InputMaybe<Scalars['Boolean']['input']>;
  maxium_day_lag_set_to_schedule?: InputMaybe<Scalars['Int']['input']>;
  mrr?: InputMaybe<Scalars['Int']['input']>;
  mrr_label?: InputMaybe<Scalars['String']['input']>;
  mrr_required_on_sale?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  NoteItems?: InputMaybe<NoteItemCreateNestedManyWithoutOrganizationInput>;
  Notifications?: InputMaybe<NotificationCreateNestedManyWithoutOrganizationInput>;
  onboardComplete?: InputMaybe<Scalars['Boolean']['input']>;
  Opportunity?: InputMaybe<SfOpportunityCreateNestedManyWithoutOrganizationInput>;
  organization_holidays?: InputMaybe<OrganizationHolidayCreateNestedManyWithoutOrganizationInput>;
  organization_hours?: InputMaybe<OrganizationHourCreateNestedManyWithoutOrganizationInput>;
  OrganizationPaymentSetting?: InputMaybe<OrganizationPaymentSettingsCreateNestedOneWithoutOrganizationInput>;
  OrganizationPaymentSettings?: InputMaybe<OrganizationPaymentSettingsCreateNestedOneWithoutOrganizationsInput>;
  orgCalendarInviteMetadata?: InputMaybe<OrgCalendarInviteMetadataCreateNestedManyWithoutOrganizationInput>;
  OrgDefaultPipelinePerecentValue?: InputMaybe<OrgDefaultPipelinePerecentValueCreateNestedOneWithoutOrganizationInput>;
  OrgHubSpotSync?: InputMaybe<OrgHubSpotSyncCreateNestedOneWithoutOrganizationInput>;
  OrgNoEventDate?: InputMaybe<OrgNoEventDateCreateNestedManyWithoutOrganizationInput>;
  OrgSalesforceSync?: InputMaybe<OrgSalesforceSyncCreateNestedOneWithoutOrganizationInput>;
  OrgTimeslotAvailability?: InputMaybe<OrgTimeslotAvailabilityCreateNestedManyWithoutOrganizationInput>;
  outbound_close_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_dm_connect_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_lt_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_revenue_value_of_sale?: InputMaybe<Scalars['Float']['input']>;
  outbound_scheduled_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_set_rate?: InputMaybe<Scalars['Float']['input']>;
  PandadocData?: InputMaybe<PandadocDataCreateNestedOneWithoutOrganizationInput>;
  PandaDocTemplate?: InputMaybe<PandaDocTemplateCreateNestedManyWithoutOrganizationInput>;
  participant_jitter_buffer_size?: InputMaybe<JitterBufferSize>;
  preferred_goal_setting?: InputMaybe<Goalsetting>;
  prepayment?: InputMaybe<Scalars['Boolean']['input']>;
  ProductChangeLog?: InputMaybe<ProductChangeLogCreateNestedManyWithoutOrganizationInput>;
  products?: InputMaybe<ProductCreateNestedManyWithoutOrganizationInput>;
  re_cold_call_hour_delay?: InputMaybe<Scalars['Float']['input']>;
  recordingStatus?: InputMaybe<RecordingStatus>;
  release_lead_resting?: InputMaybe<Scalars['Int']['input']>;
  rep_claim_limit?: InputMaybe<Scalars['Int']['input']>;
  rep_lead_allotment?: InputMaybe<Scalars['Int']['input']>;
  rep_level_call_transfer?: InputMaybe<Scalars['Boolean']['input']>;
  reps_can_bulk_unassign?: InputMaybe<Scalars['Boolean']['input']>;
  reps_can_view_all_notes?: InputMaybe<Scalars['Boolean']['input']>;
  require_sale_notes?: InputMaybe<Scalars['Boolean']['input']>;
  resting_after_skipped_CC?: InputMaybe<Scalars['Int']['input']>;
  resting_period?: InputMaybe<Scalars['Int']['input']>;
  resting_period_after_unsuccessful_cold_call?: InputMaybe<Scalars['Float']['input']>;
  resting_period_after_unsuccessful_demo?: InputMaybe<Scalars['Float']['input']>;
  revenue_plan?: InputMaybe<RevenuePlanItemCreateNestedManyWithoutOrganizationInput>;
  Routings?: InputMaybe<RoutingCreateNestedManyWithoutOrganizationInput>;
  sale_field_options?: InputMaybe<SaleFieldOptionCreateNestedManyWithoutOrganizationInput>;
  sale_notification_email_list?: InputMaybe<OrganizationCreatesale_Notification_Email_ListInput>;
  SaleConfig?: InputMaybe<SaleConfigCreateNestedOneWithoutOrganizationInput>;
  SaleConfigPage?: InputMaybe<SaleConfigPageCreateNestedManyWithoutOrganizationInput>;
  SaleConfigSection?: InputMaybe<SaleConfigSectionCreateNestedManyWithoutOrganizationInput>;
  SaleCycles?: InputMaybe<SaleCycleCreateNestedManyWithoutOrganizationInput>;
  sales?: InputMaybe<SaleCreateNestedManyWithoutOrganizationInput>;
  salesforce_domain?: InputMaybe<Scalars['String']['input']>;
  salesforce_last_sync_date?: InputMaybe<Scalars['DateTime']['input']>;
  SalesForceColumn?: InputMaybe<SalesForceColumnCreateNestedManyWithoutOrganizationInput>;
  salesForceData?: InputMaybe<SalesForceDataCreateNestedOneWithoutOrganizationInput>;
  ScheduleItems?: InputMaybe<ScheduleItemCreateNestedManyWithoutOrganizationInput>;
  self_sourcing_rule?: InputMaybe<Scalars['Int']['input']>;
  send_error_log_email?: InputMaybe<Scalars['Boolean']['input']>;
  send_lead_routing_email?: InputMaybe<Scalars['Boolean']['input']>;
  sendgrid_email_domain?: InputMaybe<Scalars['String']['input']>;
  sendgrid_email_domain_id?: InputMaybe<Scalars['Int']['input']>;
  sequence?: InputMaybe<SequenceCreateNestedManyWithoutOrganzationInput>;
  SequenceCriteriaConditions?: InputMaybe<SequenceCriteriaConditionCreateNestedManyWithoutOrganizationInput>;
  SequenceDashboardViews?: InputMaybe<SequenceDashboardViewCreateNestedManyWithoutOrganizationInput>;
  SequenceEntryCriterias?: InputMaybe<SequenceEntryCriteriaCreateNestedManyWithoutOrganizationInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutOrganizationInput>;
  SequenceSteps?: InputMaybe<SequenceStepCreateNestedManyWithoutOrganizationInput>;
  set_goal?: InputMaybe<Scalars['Float']['input']>;
  short_resting?: InputMaybe<Scalars['Int']['input']>;
  show_calendar_options?: InputMaybe<Scalars['Boolean']['input']>;
  show_contract_duration_sales?: InputMaybe<Scalars['Boolean']['input']>;
  show_lead_source_to_rep?: InputMaybe<Scalars['Boolean']['input']>;
  show_nocontact_button?: InputMaybe<Scalars['Boolean']['input']>;
  show_payment_terms_sales?: InputMaybe<Scalars['Boolean']['input']>;
  show_rep_all_leads?: InputMaybe<Scalars['Boolean']['input']>;
  show_rep_my_leads?: InputMaybe<Scalars['Boolean']['input']>;
  show_system_view?: InputMaybe<Scalars['Boolean']['input']>;
  sites?: InputMaybe<SiteCreateNestedManyWithoutOrganizationInput>;
  sms_opt_in_dialog?: InputMaybe<Scalars['String']['input']>;
  SMSItems?: InputMaybe<SmsItemCreateNestedManyWithoutOrganizationInput>;
  SnoozeTrackings?: InputMaybe<SnoozeTrackingCreateNestedManyWithoutOrganizationInput>;
  StepActionLogs?: InputMaybe<StepActionLogCreateNestedManyWithoutOrganizationInput>;
  StepActions?: InputMaybe<StepActionCreateNestedManyWithoutOrganizationInput>;
  StepConditions?: InputMaybe<StepConditionCreateNestedManyWithoutOrganizationInput>;
  teams?: InputMaybe<TeamCreateNestedManyWithoutOrganizationInput>;
  Template?: InputMaybe<TemplateCreateNestedManyWithoutOrganizationInput>;
  TemplateVersions?: InputMaybe<TemplateVersionCreateNestedManyWithoutOrganizationInput>;
  timekit_id?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  transfer_timeout?: InputMaybe<Scalars['Int']['input']>;
  TransferAttempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutOrganizationInput>;
  twilio_numbers?: InputMaybe<TwilioNumberCreateNestedManyWithoutOrganizationInput>;
  TwilioFeedbacks?: InputMaybe<TwilioFeedbackCreateNestedManyWithoutOrganizationInput>;
  TwilioInfo?: InputMaybe<TwilioInfoCreateNestedOneWithoutOrganzationInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  use_revenue_planning?: InputMaybe<Scalars['Boolean']['input']>;
  UserCustomFieldFilters?: InputMaybe<UserCustomFieldFilterCreateNestedManyWithoutOrganizationInput>;
  users?: InputMaybe<UserCreateNestedManyWithoutOrganizationInput>;
  win_rep_day_goal?: InputMaybe<Scalars['Float']['input']>;
};

export type OrganizationCreateWithoutSalesInput = {
  ActivityMonitorMultiplier?: InputMaybe<ActivityMonitorMultiplierCreateNestedManyWithoutOrganizationInput>;
  algoFactor?: InputMaybe<AlgoFactorCreateNestedManyWithoutOrganizationInput>;
  allow_calendar_invite?: InputMaybe<Scalars['Boolean']['input']>;
  allow_remove_step_action?: InputMaybe<Scalars['Boolean']['input']>;
  allow_snooze_step_action?: InputMaybe<Scalars['Boolean']['input']>;
  always_record?: InputMaybe<Scalars['Boolean']['input']>;
  arv_initial_held_demos_generated_by_sdr_weight?: InputMaybe<Scalars['Float']['input']>;
  AssociationHistories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutOrganizationInput>;
  auto_call_interval?: InputMaybe<Scalars['Int']['input']>;
  auto_dial?: InputMaybe<Scalars['Boolean']['input']>;
  automated_campaigns?: InputMaybe<AutomatedCampaignCreateNestedManyWithoutOrganizationInput>;
  automatic_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  AutoTransferSelections?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutOrganizationInput>;
  Availablities?: InputMaybe<AvailabilityCreateNestedManyWithoutOrganizationInput>;
  AverageOrderValue?: InputMaybe<AverageOrderValueCreateNestedManyWithoutOrganizationInput>;
  best_action_ever_multiplier?: InputMaybe<Scalars['Float']['input']>;
  best_metrics?: InputMaybe<BestMetricRecordCreateNestedManyWithoutOrganizationInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptCreateNestedManyWithoutOrganizationInput>;
  callMeNows?: InputMaybe<CallMeNowCreateNestedManyWithoutOrganizationInput>;
  CallMultiplier?: InputMaybe<CallMultiplierCreateNestedManyWithoutOrganizationInput>;
  CallSharings?: InputMaybe<CallSharingCreateNestedManyWithoutOrganizationInput>;
  can_edit_sdr_transfer_rules?: InputMaybe<Scalars['Boolean']['input']>;
  claim_rule?: InputMaybe<Scalars['Int']['input']>;
  concession?: InputMaybe<ConcessionCreateNestedManyWithoutOrganizationInput>;
  conference_region?: InputMaybe<ConferenceRegion>;
  Conferences?: InputMaybe<ConferenceCreateNestedManyWithoutOrganizationInput>;
  ContactRequests?: InputMaybe<ContactRequestCreateNestedManyWithoutOrganizationInput>;
  Contacts?: InputMaybe<ContactCreateNestedManyWithoutOrganizationInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  custom_fields?: InputMaybe<CustomFieldCreateNestedManyWithoutOrganizationInput>;
  CustomObject?: InputMaybe<CustomObjectCreateNestedManyWithoutOrganizationInput>;
  CustomObjectField?: InputMaybe<CustomObjectFieldCreateNestedManyWithoutOrganizationInput>;
  CustomObjectFieldForeignKey?: InputMaybe<CustomObjectFieldForeignKeyCreateNestedManyWithoutOrganizationInput>;
  CustomObjectRecord?: InputMaybe<CustomObjectRecordCreateNestedManyWithoutOrganizationInput>;
  CustomObjectRow?: InputMaybe<CustomObjectRowCreateNestedManyWithoutOrganizationInput>;
  CustomQueueItems?: InputMaybe<CustomQueueItemCreateNestedManyWithoutOrganizationInput>;
  DashboardColumnOptions?: InputMaybe<DashboardColumnOptionCreateNestedManyWithoutOrganizationInput>;
  DashboardLeadFilters?: InputMaybe<DashboardLeadFilterCreateNestedManyWithoutOrganizationInput>;
  DashboardRepFilters?: InputMaybe<DashboardRepFilterCreateNestedManyWithoutOrganizationInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewCreateNestedManyWithoutOrganizationInput>;
  day_to_close?: InputMaybe<Scalars['Int']['input']>;
  default_demo_time_duration?: InputMaybe<Scalars['Int']['input']>;
  demo_held_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_not_held_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_not_held_call_back_short_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_prep_time_minutes?: InputMaybe<Scalars['Int']['input']>;
  dev_account?: InputMaybe<Scalars['Boolean']['input']>;
  dial_rule?: InputMaybe<Scalars['Int']['input']>;
  DispositionMultiplier?: InputMaybe<DispositionMultiplierCreateNestedManyWithoutOrganizationInput>;
  dispositions?: InputMaybe<DispositionCreateNestedManyWithoutOrganizationInput>;
  double_booking?: InputMaybe<Scalars['Boolean']['input']>;
  dynamic_date_operators?: InputMaybe<Scalars['Boolean']['input']>;
  edge_servers?: InputMaybe<OrganizationCreateedge_ServersInput>;
  email_conflict_notification_email_list?: InputMaybe<OrganizationCreateemail_Conflict_Notification_Email_ListInput>;
  EmailItems?: InputMaybe<EmailItemCreateNestedManyWithoutOrganizationInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordCreateNestedManyWithoutOrganizationInput>;
  EmailThreads?: InputMaybe<EmailThreadCreateNestedManyWithoutOrganizationInput>;
  enable_modified_metrics?: InputMaybe<Scalars['Boolean']['input']>;
  enable_pay_period_view?: InputMaybe<Scalars['Boolean']['input']>;
  enable_time_constraint?: InputMaybe<Scalars['Boolean']['input']>;
  error_log_email_address?: InputMaybe<OrganizationCreateerror_Log_Email_AddressInput>;
  estimated_average_num_of_weekly_overbooked_demo_slots?: InputMaybe<Scalars['Int']['input']>;
  FolderPermissions?: InputMaybe<FolderPermissionCreateNestedManyWithoutOrganizationInput>;
  Folders?: InputMaybe<FolderCreateNestedManyWithoutOrganizationInput>;
  FutureBookingSetting?: InputMaybe<FutureBookingSettingCreateNestedOneWithoutOrganizationInput>;
  GlobalTransferRule?: InputMaybe<GlobalTransferRuleCreateNestedManyWithoutOrganizationInput>;
  held_demos_per_cc_connect?: InputMaybe<Scalars['Float']['input']>;
  helpful_links?: InputMaybe<OrganizationCreatehelpful_LinksInput>;
  HingePointMultiplier?: InputMaybe<HingePointMultiplierCreateNestedManyWithoutOrganizationInput>;
  hold_goal?: InputMaybe<Scalars['Float']['input']>;
  hubspot_credential?: InputMaybe<HubSpotCredentialCreateNestedOneWithoutOrganizationInput>;
  hubspot_last_sync_date?: InputMaybe<Scalars['DateTime']['input']>;
  hubspot_white_list?: InputMaybe<HubSpotWhitelistCreateNestedManyWithoutOrganizationInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  inbound_close_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_dm_connect_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_lt_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_revenue_value_of_sale?: InputMaybe<Scalars['Float']['input']>;
  inbound_scheduled_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_set_rate?: InputMaybe<Scalars['Float']['input']>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventCreateNestedManyWithoutOrganizationInput>;
  InboundConciergeWidget?: InputMaybe<InboundConciergeWidgetCreateNestedManyWithoutOrganizationInput>;
  industry_options?: InputMaybe<IndustryOptionCreateNestedManyWithoutOrganizationInput>;
  initial_sdr_sets_reps_day?: InputMaybe<Scalars['Float']['input']>;
  integration_error_logs?: InputMaybe<IntegrationErrorLogCreateNestedManyWithoutOrganizationInput>;
  integration_mappings?: InputMaybe<IntegrationMappingCreateNestedManyWithoutOrganizationInput>;
  IntegrationMappingCustomObject?: InputMaybe<IntegrationMappingCustomObjectCreateNestedManyWithoutOrganizationInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogCreateNestedManyWithoutOrganizationInput>;
  IntegrationReadOnlyObject?: InputMaybe<IntegrationReadOnlyObjectCreateNestedManyWithoutOrganizationInput>;
  international?: InputMaybe<Scalars['Boolean']['input']>;
  is_email_domain_verified?: InputMaybe<Scalars['Boolean']['input']>;
  is_salesforce_sandbox?: InputMaybe<Scalars['Boolean']['input']>;
  last_lead_value_compute_time?: InputMaybe<Scalars['DateTime']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutOrganizationInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryCreateNestedManyWithoutOrganizationInput>;
  lead_intent?: InputMaybe<LeadIntentCreateNestedManyWithoutOrganizationInput>;
  lead_release_same_rep_delay_rule?: InputMaybe<Scalars['Int']['input']>;
  lead_routing_email_list?: InputMaybe<OrganizationCreatelead_Routing_Email_ListInput>;
  lead_routing_rules?: InputMaybe<RuleCreateNestedManyWithoutOrganizationInput>;
  LeadCustomObjectRowAssociation?: InputMaybe<LeadCustomObjectRowAssociationCreateNestedManyWithoutOrganizationInput>;
  LeadFieldMultiplier?: InputMaybe<LeadFieldMultiplierCreateNestedManyWithoutOrganizationInput>;
  LeadFieldMultiplierCustom?: InputMaybe<LeadFieldMultiplierCustomCreateNestedManyWithoutOrganizationInput>;
  leads?: InputMaybe<LeadCreateNestedManyWithoutOrganizationInput>;
  leadsource_options?: InputMaybe<LeadSourceOptionCreateNestedManyWithoutOrganizationInput>;
  LeadValue?: InputMaybe<LeadValueCreateNestedManyWithoutOrganizationInput>;
  LifeTimeValueMultiplier?: InputMaybe<LifeTimeValueMultiplierCreateNestedManyWithoutOrganizationInput>;
  LiveTransferSetting?: InputMaybe<LiveTransferSettingCreateNestedOneWithoutOrganizationInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusCreateNestedManyWithoutOrganizationInput>;
  ltv_multiplier_custom_fields?: InputMaybe<LifeTimeValueMultiplierCustomCreateNestedManyWithoutOrganizationInput>;
  made_contact_rule?: InputMaybe<Scalars['Int']['input']>;
  make_sale_address_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_business_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_city_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_country_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_first_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_industry_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_last_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_lead_source_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_primary_email_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_primary_phone_number_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_state_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_sub_industry_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_zip_required?: InputMaybe<Scalars['Boolean']['input']>;
  maxium_day_lag_set_to_schedule?: InputMaybe<Scalars['Int']['input']>;
  mrr?: InputMaybe<Scalars['Int']['input']>;
  mrr_label?: InputMaybe<Scalars['String']['input']>;
  mrr_required_on_sale?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  NoteItems?: InputMaybe<NoteItemCreateNestedManyWithoutOrganizationInput>;
  Notifications?: InputMaybe<NotificationCreateNestedManyWithoutOrganizationInput>;
  onboardComplete?: InputMaybe<Scalars['Boolean']['input']>;
  Opportunity?: InputMaybe<SfOpportunityCreateNestedManyWithoutOrganizationInput>;
  organization_holidays?: InputMaybe<OrganizationHolidayCreateNestedManyWithoutOrganizationInput>;
  organization_hours?: InputMaybe<OrganizationHourCreateNestedManyWithoutOrganizationInput>;
  OrganizationPaymentSetting?: InputMaybe<OrganizationPaymentSettingsCreateNestedOneWithoutOrganizationInput>;
  OrganizationPaymentSettings?: InputMaybe<OrganizationPaymentSettingsCreateNestedOneWithoutOrganizationsInput>;
  orgCalendarInviteMetadata?: InputMaybe<OrgCalendarInviteMetadataCreateNestedManyWithoutOrganizationInput>;
  OrgDefaultPipelinePerecentValue?: InputMaybe<OrgDefaultPipelinePerecentValueCreateNestedOneWithoutOrganizationInput>;
  OrgHubSpotSync?: InputMaybe<OrgHubSpotSyncCreateNestedOneWithoutOrganizationInput>;
  OrgNoEventDate?: InputMaybe<OrgNoEventDateCreateNestedManyWithoutOrganizationInput>;
  OrgSalesforceSync?: InputMaybe<OrgSalesforceSyncCreateNestedOneWithoutOrganizationInput>;
  OrgTimeslotAvailability?: InputMaybe<OrgTimeslotAvailabilityCreateNestedManyWithoutOrganizationInput>;
  outbound_close_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_dm_connect_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_lt_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_revenue_value_of_sale?: InputMaybe<Scalars['Float']['input']>;
  outbound_scheduled_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_set_rate?: InputMaybe<Scalars['Float']['input']>;
  PandadocData?: InputMaybe<PandadocDataCreateNestedOneWithoutOrganizationInput>;
  PandaDocTemplate?: InputMaybe<PandaDocTemplateCreateNestedManyWithoutOrganizationInput>;
  participant_jitter_buffer_size?: InputMaybe<JitterBufferSize>;
  preferred_goal_setting?: InputMaybe<Goalsetting>;
  prepayment?: InputMaybe<Scalars['Boolean']['input']>;
  ProductChangeLog?: InputMaybe<ProductChangeLogCreateNestedManyWithoutOrganizationInput>;
  products?: InputMaybe<ProductCreateNestedManyWithoutOrganizationInput>;
  re_cold_call_hour_delay?: InputMaybe<Scalars['Float']['input']>;
  recordingStatus?: InputMaybe<RecordingStatus>;
  release_lead_resting?: InputMaybe<Scalars['Int']['input']>;
  rep_claim_limit?: InputMaybe<Scalars['Int']['input']>;
  rep_lead_allotment?: InputMaybe<Scalars['Int']['input']>;
  rep_level_call_transfer?: InputMaybe<Scalars['Boolean']['input']>;
  reps_can_bulk_unassign?: InputMaybe<Scalars['Boolean']['input']>;
  reps_can_view_all_notes?: InputMaybe<Scalars['Boolean']['input']>;
  require_sale_notes?: InputMaybe<Scalars['Boolean']['input']>;
  resting_after_skipped_CC?: InputMaybe<Scalars['Int']['input']>;
  resting_period?: InputMaybe<Scalars['Int']['input']>;
  resting_period_after_unsuccessful_cold_call?: InputMaybe<Scalars['Float']['input']>;
  resting_period_after_unsuccessful_demo?: InputMaybe<Scalars['Float']['input']>;
  revenue_plan?: InputMaybe<RevenuePlanItemCreateNestedManyWithoutOrganizationInput>;
  Routings?: InputMaybe<RoutingCreateNestedManyWithoutOrganizationInput>;
  sale_field_options?: InputMaybe<SaleFieldOptionCreateNestedManyWithoutOrganizationInput>;
  sale_notification_email_list?: InputMaybe<OrganizationCreatesale_Notification_Email_ListInput>;
  SaleConfig?: InputMaybe<SaleConfigCreateNestedOneWithoutOrganizationInput>;
  SaleConfigPage?: InputMaybe<SaleConfigPageCreateNestedManyWithoutOrganizationInput>;
  SaleConfigSection?: InputMaybe<SaleConfigSectionCreateNestedManyWithoutOrganizationInput>;
  SaleCycles?: InputMaybe<SaleCycleCreateNestedManyWithoutOrganizationInput>;
  SaleItems?: InputMaybe<SaleItemCreateNestedManyWithoutOrganizationInput>;
  salesforce_domain?: InputMaybe<Scalars['String']['input']>;
  salesforce_last_sync_date?: InputMaybe<Scalars['DateTime']['input']>;
  SalesForceColumn?: InputMaybe<SalesForceColumnCreateNestedManyWithoutOrganizationInput>;
  salesForceData?: InputMaybe<SalesForceDataCreateNestedOneWithoutOrganizationInput>;
  ScheduleItems?: InputMaybe<ScheduleItemCreateNestedManyWithoutOrganizationInput>;
  self_sourcing_rule?: InputMaybe<Scalars['Int']['input']>;
  send_error_log_email?: InputMaybe<Scalars['Boolean']['input']>;
  send_lead_routing_email?: InputMaybe<Scalars['Boolean']['input']>;
  sendgrid_email_domain?: InputMaybe<Scalars['String']['input']>;
  sendgrid_email_domain_id?: InputMaybe<Scalars['Int']['input']>;
  sequence?: InputMaybe<SequenceCreateNestedManyWithoutOrganzationInput>;
  SequenceCriteriaConditions?: InputMaybe<SequenceCriteriaConditionCreateNestedManyWithoutOrganizationInput>;
  SequenceDashboardViews?: InputMaybe<SequenceDashboardViewCreateNestedManyWithoutOrganizationInput>;
  SequenceEntryCriterias?: InputMaybe<SequenceEntryCriteriaCreateNestedManyWithoutOrganizationInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutOrganizationInput>;
  SequenceSteps?: InputMaybe<SequenceStepCreateNestedManyWithoutOrganizationInput>;
  set_goal?: InputMaybe<Scalars['Float']['input']>;
  short_resting?: InputMaybe<Scalars['Int']['input']>;
  show_calendar_options?: InputMaybe<Scalars['Boolean']['input']>;
  show_contract_duration_sales?: InputMaybe<Scalars['Boolean']['input']>;
  show_lead_source_to_rep?: InputMaybe<Scalars['Boolean']['input']>;
  show_nocontact_button?: InputMaybe<Scalars['Boolean']['input']>;
  show_payment_terms_sales?: InputMaybe<Scalars['Boolean']['input']>;
  show_rep_all_leads?: InputMaybe<Scalars['Boolean']['input']>;
  show_rep_my_leads?: InputMaybe<Scalars['Boolean']['input']>;
  show_system_view?: InputMaybe<Scalars['Boolean']['input']>;
  sites?: InputMaybe<SiteCreateNestedManyWithoutOrganizationInput>;
  sms_opt_in_dialog?: InputMaybe<Scalars['String']['input']>;
  SMSItems?: InputMaybe<SmsItemCreateNestedManyWithoutOrganizationInput>;
  SnoozeTrackings?: InputMaybe<SnoozeTrackingCreateNestedManyWithoutOrganizationInput>;
  StepActionLogs?: InputMaybe<StepActionLogCreateNestedManyWithoutOrganizationInput>;
  StepActions?: InputMaybe<StepActionCreateNestedManyWithoutOrganizationInput>;
  StepConditions?: InputMaybe<StepConditionCreateNestedManyWithoutOrganizationInput>;
  teams?: InputMaybe<TeamCreateNestedManyWithoutOrganizationInput>;
  Template?: InputMaybe<TemplateCreateNestedManyWithoutOrganizationInput>;
  TemplateVersions?: InputMaybe<TemplateVersionCreateNestedManyWithoutOrganizationInput>;
  timekit_id?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  transfer_timeout?: InputMaybe<Scalars['Int']['input']>;
  TransferAttempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutOrganizationInput>;
  twilio_numbers?: InputMaybe<TwilioNumberCreateNestedManyWithoutOrganizationInput>;
  TwilioFeedbacks?: InputMaybe<TwilioFeedbackCreateNestedManyWithoutOrganizationInput>;
  TwilioInfo?: InputMaybe<TwilioInfoCreateNestedOneWithoutOrganzationInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  use_revenue_planning?: InputMaybe<Scalars['Boolean']['input']>;
  UserCustomFieldFilters?: InputMaybe<UserCustomFieldFilterCreateNestedManyWithoutOrganizationInput>;
  users?: InputMaybe<UserCreateNestedManyWithoutOrganizationInput>;
  win_rep_day_goal?: InputMaybe<Scalars['Float']['input']>;
};

export type OrganizationCreateWithoutScheduleItemsInput = {
  ActivityMonitorMultiplier?: InputMaybe<ActivityMonitorMultiplierCreateNestedManyWithoutOrganizationInput>;
  algoFactor?: InputMaybe<AlgoFactorCreateNestedManyWithoutOrganizationInput>;
  allow_calendar_invite?: InputMaybe<Scalars['Boolean']['input']>;
  allow_remove_step_action?: InputMaybe<Scalars['Boolean']['input']>;
  allow_snooze_step_action?: InputMaybe<Scalars['Boolean']['input']>;
  always_record?: InputMaybe<Scalars['Boolean']['input']>;
  arv_initial_held_demos_generated_by_sdr_weight?: InputMaybe<Scalars['Float']['input']>;
  AssociationHistories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutOrganizationInput>;
  auto_call_interval?: InputMaybe<Scalars['Int']['input']>;
  auto_dial?: InputMaybe<Scalars['Boolean']['input']>;
  automated_campaigns?: InputMaybe<AutomatedCampaignCreateNestedManyWithoutOrganizationInput>;
  automatic_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  AutoTransferSelections?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutOrganizationInput>;
  Availablities?: InputMaybe<AvailabilityCreateNestedManyWithoutOrganizationInput>;
  AverageOrderValue?: InputMaybe<AverageOrderValueCreateNestedManyWithoutOrganizationInput>;
  best_action_ever_multiplier?: InputMaybe<Scalars['Float']['input']>;
  best_metrics?: InputMaybe<BestMetricRecordCreateNestedManyWithoutOrganizationInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptCreateNestedManyWithoutOrganizationInput>;
  callMeNows?: InputMaybe<CallMeNowCreateNestedManyWithoutOrganizationInput>;
  CallMultiplier?: InputMaybe<CallMultiplierCreateNestedManyWithoutOrganizationInput>;
  CallSharings?: InputMaybe<CallSharingCreateNestedManyWithoutOrganizationInput>;
  can_edit_sdr_transfer_rules?: InputMaybe<Scalars['Boolean']['input']>;
  claim_rule?: InputMaybe<Scalars['Int']['input']>;
  concession?: InputMaybe<ConcessionCreateNestedManyWithoutOrganizationInput>;
  conference_region?: InputMaybe<ConferenceRegion>;
  Conferences?: InputMaybe<ConferenceCreateNestedManyWithoutOrganizationInput>;
  ContactRequests?: InputMaybe<ContactRequestCreateNestedManyWithoutOrganizationInput>;
  Contacts?: InputMaybe<ContactCreateNestedManyWithoutOrganizationInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  custom_fields?: InputMaybe<CustomFieldCreateNestedManyWithoutOrganizationInput>;
  CustomObject?: InputMaybe<CustomObjectCreateNestedManyWithoutOrganizationInput>;
  CustomObjectField?: InputMaybe<CustomObjectFieldCreateNestedManyWithoutOrganizationInput>;
  CustomObjectFieldForeignKey?: InputMaybe<CustomObjectFieldForeignKeyCreateNestedManyWithoutOrganizationInput>;
  CustomObjectRecord?: InputMaybe<CustomObjectRecordCreateNestedManyWithoutOrganizationInput>;
  CustomObjectRow?: InputMaybe<CustomObjectRowCreateNestedManyWithoutOrganizationInput>;
  CustomQueueItems?: InputMaybe<CustomQueueItemCreateNestedManyWithoutOrganizationInput>;
  DashboardColumnOptions?: InputMaybe<DashboardColumnOptionCreateNestedManyWithoutOrganizationInput>;
  DashboardLeadFilters?: InputMaybe<DashboardLeadFilterCreateNestedManyWithoutOrganizationInput>;
  DashboardRepFilters?: InputMaybe<DashboardRepFilterCreateNestedManyWithoutOrganizationInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewCreateNestedManyWithoutOrganizationInput>;
  day_to_close?: InputMaybe<Scalars['Int']['input']>;
  default_demo_time_duration?: InputMaybe<Scalars['Int']['input']>;
  demo_held_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_not_held_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_not_held_call_back_short_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_prep_time_minutes?: InputMaybe<Scalars['Int']['input']>;
  dev_account?: InputMaybe<Scalars['Boolean']['input']>;
  dial_rule?: InputMaybe<Scalars['Int']['input']>;
  DispositionMultiplier?: InputMaybe<DispositionMultiplierCreateNestedManyWithoutOrganizationInput>;
  dispositions?: InputMaybe<DispositionCreateNestedManyWithoutOrganizationInput>;
  double_booking?: InputMaybe<Scalars['Boolean']['input']>;
  dynamic_date_operators?: InputMaybe<Scalars['Boolean']['input']>;
  edge_servers?: InputMaybe<OrganizationCreateedge_ServersInput>;
  email_conflict_notification_email_list?: InputMaybe<OrganizationCreateemail_Conflict_Notification_Email_ListInput>;
  EmailItems?: InputMaybe<EmailItemCreateNestedManyWithoutOrganizationInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordCreateNestedManyWithoutOrganizationInput>;
  EmailThreads?: InputMaybe<EmailThreadCreateNestedManyWithoutOrganizationInput>;
  enable_modified_metrics?: InputMaybe<Scalars['Boolean']['input']>;
  enable_pay_period_view?: InputMaybe<Scalars['Boolean']['input']>;
  enable_time_constraint?: InputMaybe<Scalars['Boolean']['input']>;
  error_log_email_address?: InputMaybe<OrganizationCreateerror_Log_Email_AddressInput>;
  estimated_average_num_of_weekly_overbooked_demo_slots?: InputMaybe<Scalars['Int']['input']>;
  FolderPermissions?: InputMaybe<FolderPermissionCreateNestedManyWithoutOrganizationInput>;
  Folders?: InputMaybe<FolderCreateNestedManyWithoutOrganizationInput>;
  FutureBookingSetting?: InputMaybe<FutureBookingSettingCreateNestedOneWithoutOrganizationInput>;
  GlobalTransferRule?: InputMaybe<GlobalTransferRuleCreateNestedManyWithoutOrganizationInput>;
  held_demos_per_cc_connect?: InputMaybe<Scalars['Float']['input']>;
  helpful_links?: InputMaybe<OrganizationCreatehelpful_LinksInput>;
  HingePointMultiplier?: InputMaybe<HingePointMultiplierCreateNestedManyWithoutOrganizationInput>;
  hold_goal?: InputMaybe<Scalars['Float']['input']>;
  hubspot_credential?: InputMaybe<HubSpotCredentialCreateNestedOneWithoutOrganizationInput>;
  hubspot_last_sync_date?: InputMaybe<Scalars['DateTime']['input']>;
  hubspot_white_list?: InputMaybe<HubSpotWhitelistCreateNestedManyWithoutOrganizationInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  inbound_close_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_dm_connect_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_lt_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_revenue_value_of_sale?: InputMaybe<Scalars['Float']['input']>;
  inbound_scheduled_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_set_rate?: InputMaybe<Scalars['Float']['input']>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventCreateNestedManyWithoutOrganizationInput>;
  InboundConciergeWidget?: InputMaybe<InboundConciergeWidgetCreateNestedManyWithoutOrganizationInput>;
  industry_options?: InputMaybe<IndustryOptionCreateNestedManyWithoutOrganizationInput>;
  initial_sdr_sets_reps_day?: InputMaybe<Scalars['Float']['input']>;
  integration_error_logs?: InputMaybe<IntegrationErrorLogCreateNestedManyWithoutOrganizationInput>;
  integration_mappings?: InputMaybe<IntegrationMappingCreateNestedManyWithoutOrganizationInput>;
  IntegrationMappingCustomObject?: InputMaybe<IntegrationMappingCustomObjectCreateNestedManyWithoutOrganizationInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogCreateNestedManyWithoutOrganizationInput>;
  IntegrationReadOnlyObject?: InputMaybe<IntegrationReadOnlyObjectCreateNestedManyWithoutOrganizationInput>;
  international?: InputMaybe<Scalars['Boolean']['input']>;
  is_email_domain_verified?: InputMaybe<Scalars['Boolean']['input']>;
  is_salesforce_sandbox?: InputMaybe<Scalars['Boolean']['input']>;
  last_lead_value_compute_time?: InputMaybe<Scalars['DateTime']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutOrganizationInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryCreateNestedManyWithoutOrganizationInput>;
  lead_intent?: InputMaybe<LeadIntentCreateNestedManyWithoutOrganizationInput>;
  lead_release_same_rep_delay_rule?: InputMaybe<Scalars['Int']['input']>;
  lead_routing_email_list?: InputMaybe<OrganizationCreatelead_Routing_Email_ListInput>;
  lead_routing_rules?: InputMaybe<RuleCreateNestedManyWithoutOrganizationInput>;
  LeadCustomObjectRowAssociation?: InputMaybe<LeadCustomObjectRowAssociationCreateNestedManyWithoutOrganizationInput>;
  LeadFieldMultiplier?: InputMaybe<LeadFieldMultiplierCreateNestedManyWithoutOrganizationInput>;
  LeadFieldMultiplierCustom?: InputMaybe<LeadFieldMultiplierCustomCreateNestedManyWithoutOrganizationInput>;
  leads?: InputMaybe<LeadCreateNestedManyWithoutOrganizationInput>;
  leadsource_options?: InputMaybe<LeadSourceOptionCreateNestedManyWithoutOrganizationInput>;
  LeadValue?: InputMaybe<LeadValueCreateNestedManyWithoutOrganizationInput>;
  LifeTimeValueMultiplier?: InputMaybe<LifeTimeValueMultiplierCreateNestedManyWithoutOrganizationInput>;
  LiveTransferSetting?: InputMaybe<LiveTransferSettingCreateNestedOneWithoutOrganizationInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusCreateNestedManyWithoutOrganizationInput>;
  ltv_multiplier_custom_fields?: InputMaybe<LifeTimeValueMultiplierCustomCreateNestedManyWithoutOrganizationInput>;
  made_contact_rule?: InputMaybe<Scalars['Int']['input']>;
  make_sale_address_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_business_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_city_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_country_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_first_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_industry_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_last_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_lead_source_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_primary_email_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_primary_phone_number_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_state_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_sub_industry_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_zip_required?: InputMaybe<Scalars['Boolean']['input']>;
  maxium_day_lag_set_to_schedule?: InputMaybe<Scalars['Int']['input']>;
  mrr?: InputMaybe<Scalars['Int']['input']>;
  mrr_label?: InputMaybe<Scalars['String']['input']>;
  mrr_required_on_sale?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  NoteItems?: InputMaybe<NoteItemCreateNestedManyWithoutOrganizationInput>;
  Notifications?: InputMaybe<NotificationCreateNestedManyWithoutOrganizationInput>;
  onboardComplete?: InputMaybe<Scalars['Boolean']['input']>;
  Opportunity?: InputMaybe<SfOpportunityCreateNestedManyWithoutOrganizationInput>;
  organization_holidays?: InputMaybe<OrganizationHolidayCreateNestedManyWithoutOrganizationInput>;
  organization_hours?: InputMaybe<OrganizationHourCreateNestedManyWithoutOrganizationInput>;
  OrganizationPaymentSetting?: InputMaybe<OrganizationPaymentSettingsCreateNestedOneWithoutOrganizationInput>;
  OrganizationPaymentSettings?: InputMaybe<OrganizationPaymentSettingsCreateNestedOneWithoutOrganizationsInput>;
  orgCalendarInviteMetadata?: InputMaybe<OrgCalendarInviteMetadataCreateNestedManyWithoutOrganizationInput>;
  OrgDefaultPipelinePerecentValue?: InputMaybe<OrgDefaultPipelinePerecentValueCreateNestedOneWithoutOrganizationInput>;
  OrgHubSpotSync?: InputMaybe<OrgHubSpotSyncCreateNestedOneWithoutOrganizationInput>;
  OrgNoEventDate?: InputMaybe<OrgNoEventDateCreateNestedManyWithoutOrganizationInput>;
  OrgSalesforceSync?: InputMaybe<OrgSalesforceSyncCreateNestedOneWithoutOrganizationInput>;
  OrgTimeslotAvailability?: InputMaybe<OrgTimeslotAvailabilityCreateNestedManyWithoutOrganizationInput>;
  outbound_close_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_dm_connect_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_lt_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_revenue_value_of_sale?: InputMaybe<Scalars['Float']['input']>;
  outbound_scheduled_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_set_rate?: InputMaybe<Scalars['Float']['input']>;
  PandadocData?: InputMaybe<PandadocDataCreateNestedOneWithoutOrganizationInput>;
  PandaDocTemplate?: InputMaybe<PandaDocTemplateCreateNestedManyWithoutOrganizationInput>;
  participant_jitter_buffer_size?: InputMaybe<JitterBufferSize>;
  preferred_goal_setting?: InputMaybe<Goalsetting>;
  prepayment?: InputMaybe<Scalars['Boolean']['input']>;
  ProductChangeLog?: InputMaybe<ProductChangeLogCreateNestedManyWithoutOrganizationInput>;
  products?: InputMaybe<ProductCreateNestedManyWithoutOrganizationInput>;
  re_cold_call_hour_delay?: InputMaybe<Scalars['Float']['input']>;
  recordingStatus?: InputMaybe<RecordingStatus>;
  release_lead_resting?: InputMaybe<Scalars['Int']['input']>;
  rep_claim_limit?: InputMaybe<Scalars['Int']['input']>;
  rep_lead_allotment?: InputMaybe<Scalars['Int']['input']>;
  rep_level_call_transfer?: InputMaybe<Scalars['Boolean']['input']>;
  reps_can_bulk_unassign?: InputMaybe<Scalars['Boolean']['input']>;
  reps_can_view_all_notes?: InputMaybe<Scalars['Boolean']['input']>;
  require_sale_notes?: InputMaybe<Scalars['Boolean']['input']>;
  resting_after_skipped_CC?: InputMaybe<Scalars['Int']['input']>;
  resting_period?: InputMaybe<Scalars['Int']['input']>;
  resting_period_after_unsuccessful_cold_call?: InputMaybe<Scalars['Float']['input']>;
  resting_period_after_unsuccessful_demo?: InputMaybe<Scalars['Float']['input']>;
  revenue_plan?: InputMaybe<RevenuePlanItemCreateNestedManyWithoutOrganizationInput>;
  Routings?: InputMaybe<RoutingCreateNestedManyWithoutOrganizationInput>;
  sale_field_options?: InputMaybe<SaleFieldOptionCreateNestedManyWithoutOrganizationInput>;
  sale_notification_email_list?: InputMaybe<OrganizationCreatesale_Notification_Email_ListInput>;
  SaleConfig?: InputMaybe<SaleConfigCreateNestedOneWithoutOrganizationInput>;
  SaleConfigPage?: InputMaybe<SaleConfigPageCreateNestedManyWithoutOrganizationInput>;
  SaleConfigSection?: InputMaybe<SaleConfigSectionCreateNestedManyWithoutOrganizationInput>;
  SaleCycles?: InputMaybe<SaleCycleCreateNestedManyWithoutOrganizationInput>;
  SaleItems?: InputMaybe<SaleItemCreateNestedManyWithoutOrganizationInput>;
  sales?: InputMaybe<SaleCreateNestedManyWithoutOrganizationInput>;
  salesforce_domain?: InputMaybe<Scalars['String']['input']>;
  salesforce_last_sync_date?: InputMaybe<Scalars['DateTime']['input']>;
  SalesForceColumn?: InputMaybe<SalesForceColumnCreateNestedManyWithoutOrganizationInput>;
  salesForceData?: InputMaybe<SalesForceDataCreateNestedOneWithoutOrganizationInput>;
  self_sourcing_rule?: InputMaybe<Scalars['Int']['input']>;
  send_error_log_email?: InputMaybe<Scalars['Boolean']['input']>;
  send_lead_routing_email?: InputMaybe<Scalars['Boolean']['input']>;
  sendgrid_email_domain?: InputMaybe<Scalars['String']['input']>;
  sendgrid_email_domain_id?: InputMaybe<Scalars['Int']['input']>;
  sequence?: InputMaybe<SequenceCreateNestedManyWithoutOrganzationInput>;
  SequenceCriteriaConditions?: InputMaybe<SequenceCriteriaConditionCreateNestedManyWithoutOrganizationInput>;
  SequenceDashboardViews?: InputMaybe<SequenceDashboardViewCreateNestedManyWithoutOrganizationInput>;
  SequenceEntryCriterias?: InputMaybe<SequenceEntryCriteriaCreateNestedManyWithoutOrganizationInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutOrganizationInput>;
  SequenceSteps?: InputMaybe<SequenceStepCreateNestedManyWithoutOrganizationInput>;
  set_goal?: InputMaybe<Scalars['Float']['input']>;
  short_resting?: InputMaybe<Scalars['Int']['input']>;
  show_calendar_options?: InputMaybe<Scalars['Boolean']['input']>;
  show_contract_duration_sales?: InputMaybe<Scalars['Boolean']['input']>;
  show_lead_source_to_rep?: InputMaybe<Scalars['Boolean']['input']>;
  show_nocontact_button?: InputMaybe<Scalars['Boolean']['input']>;
  show_payment_terms_sales?: InputMaybe<Scalars['Boolean']['input']>;
  show_rep_all_leads?: InputMaybe<Scalars['Boolean']['input']>;
  show_rep_my_leads?: InputMaybe<Scalars['Boolean']['input']>;
  show_system_view?: InputMaybe<Scalars['Boolean']['input']>;
  sites?: InputMaybe<SiteCreateNestedManyWithoutOrganizationInput>;
  sms_opt_in_dialog?: InputMaybe<Scalars['String']['input']>;
  SMSItems?: InputMaybe<SmsItemCreateNestedManyWithoutOrganizationInput>;
  SnoozeTrackings?: InputMaybe<SnoozeTrackingCreateNestedManyWithoutOrganizationInput>;
  StepActionLogs?: InputMaybe<StepActionLogCreateNestedManyWithoutOrganizationInput>;
  StepActions?: InputMaybe<StepActionCreateNestedManyWithoutOrganizationInput>;
  StepConditions?: InputMaybe<StepConditionCreateNestedManyWithoutOrganizationInput>;
  teams?: InputMaybe<TeamCreateNestedManyWithoutOrganizationInput>;
  Template?: InputMaybe<TemplateCreateNestedManyWithoutOrganizationInput>;
  TemplateVersions?: InputMaybe<TemplateVersionCreateNestedManyWithoutOrganizationInput>;
  timekit_id?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  transfer_timeout?: InputMaybe<Scalars['Int']['input']>;
  TransferAttempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutOrganizationInput>;
  twilio_numbers?: InputMaybe<TwilioNumberCreateNestedManyWithoutOrganizationInput>;
  TwilioFeedbacks?: InputMaybe<TwilioFeedbackCreateNestedManyWithoutOrganizationInput>;
  TwilioInfo?: InputMaybe<TwilioInfoCreateNestedOneWithoutOrganzationInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  use_revenue_planning?: InputMaybe<Scalars['Boolean']['input']>;
  UserCustomFieldFilters?: InputMaybe<UserCustomFieldFilterCreateNestedManyWithoutOrganizationInput>;
  users?: InputMaybe<UserCreateNestedManyWithoutOrganizationInput>;
  win_rep_day_goal?: InputMaybe<Scalars['Float']['input']>;
};

export type OrganizationCreateWithoutSequenceCriteriaConditionsInput = {
  ActivityMonitorMultiplier?: InputMaybe<ActivityMonitorMultiplierCreateNestedManyWithoutOrganizationInput>;
  algoFactor?: InputMaybe<AlgoFactorCreateNestedManyWithoutOrganizationInput>;
  allow_calendar_invite?: InputMaybe<Scalars['Boolean']['input']>;
  allow_remove_step_action?: InputMaybe<Scalars['Boolean']['input']>;
  allow_snooze_step_action?: InputMaybe<Scalars['Boolean']['input']>;
  always_record?: InputMaybe<Scalars['Boolean']['input']>;
  arv_initial_held_demos_generated_by_sdr_weight?: InputMaybe<Scalars['Float']['input']>;
  AssociationHistories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutOrganizationInput>;
  auto_call_interval?: InputMaybe<Scalars['Int']['input']>;
  auto_dial?: InputMaybe<Scalars['Boolean']['input']>;
  automated_campaigns?: InputMaybe<AutomatedCampaignCreateNestedManyWithoutOrganizationInput>;
  automatic_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  AutoTransferSelections?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutOrganizationInput>;
  Availablities?: InputMaybe<AvailabilityCreateNestedManyWithoutOrganizationInput>;
  AverageOrderValue?: InputMaybe<AverageOrderValueCreateNestedManyWithoutOrganizationInput>;
  best_action_ever_multiplier?: InputMaybe<Scalars['Float']['input']>;
  best_metrics?: InputMaybe<BestMetricRecordCreateNestedManyWithoutOrganizationInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptCreateNestedManyWithoutOrganizationInput>;
  callMeNows?: InputMaybe<CallMeNowCreateNestedManyWithoutOrganizationInput>;
  CallMultiplier?: InputMaybe<CallMultiplierCreateNestedManyWithoutOrganizationInput>;
  CallSharings?: InputMaybe<CallSharingCreateNestedManyWithoutOrganizationInput>;
  can_edit_sdr_transfer_rules?: InputMaybe<Scalars['Boolean']['input']>;
  claim_rule?: InputMaybe<Scalars['Int']['input']>;
  concession?: InputMaybe<ConcessionCreateNestedManyWithoutOrganizationInput>;
  conference_region?: InputMaybe<ConferenceRegion>;
  Conferences?: InputMaybe<ConferenceCreateNestedManyWithoutOrganizationInput>;
  ContactRequests?: InputMaybe<ContactRequestCreateNestedManyWithoutOrganizationInput>;
  Contacts?: InputMaybe<ContactCreateNestedManyWithoutOrganizationInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  custom_fields?: InputMaybe<CustomFieldCreateNestedManyWithoutOrganizationInput>;
  CustomObject?: InputMaybe<CustomObjectCreateNestedManyWithoutOrganizationInput>;
  CustomObjectField?: InputMaybe<CustomObjectFieldCreateNestedManyWithoutOrganizationInput>;
  CustomObjectFieldForeignKey?: InputMaybe<CustomObjectFieldForeignKeyCreateNestedManyWithoutOrganizationInput>;
  CustomObjectRecord?: InputMaybe<CustomObjectRecordCreateNestedManyWithoutOrganizationInput>;
  CustomObjectRow?: InputMaybe<CustomObjectRowCreateNestedManyWithoutOrganizationInput>;
  CustomQueueItems?: InputMaybe<CustomQueueItemCreateNestedManyWithoutOrganizationInput>;
  DashboardColumnOptions?: InputMaybe<DashboardColumnOptionCreateNestedManyWithoutOrganizationInput>;
  DashboardLeadFilters?: InputMaybe<DashboardLeadFilterCreateNestedManyWithoutOrganizationInput>;
  DashboardRepFilters?: InputMaybe<DashboardRepFilterCreateNestedManyWithoutOrganizationInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewCreateNestedManyWithoutOrganizationInput>;
  day_to_close?: InputMaybe<Scalars['Int']['input']>;
  default_demo_time_duration?: InputMaybe<Scalars['Int']['input']>;
  demo_held_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_not_held_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_not_held_call_back_short_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_prep_time_minutes?: InputMaybe<Scalars['Int']['input']>;
  dev_account?: InputMaybe<Scalars['Boolean']['input']>;
  dial_rule?: InputMaybe<Scalars['Int']['input']>;
  DispositionMultiplier?: InputMaybe<DispositionMultiplierCreateNestedManyWithoutOrganizationInput>;
  dispositions?: InputMaybe<DispositionCreateNestedManyWithoutOrganizationInput>;
  double_booking?: InputMaybe<Scalars['Boolean']['input']>;
  dynamic_date_operators?: InputMaybe<Scalars['Boolean']['input']>;
  edge_servers?: InputMaybe<OrganizationCreateedge_ServersInput>;
  email_conflict_notification_email_list?: InputMaybe<OrganizationCreateemail_Conflict_Notification_Email_ListInput>;
  EmailItems?: InputMaybe<EmailItemCreateNestedManyWithoutOrganizationInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordCreateNestedManyWithoutOrganizationInput>;
  EmailThreads?: InputMaybe<EmailThreadCreateNestedManyWithoutOrganizationInput>;
  enable_modified_metrics?: InputMaybe<Scalars['Boolean']['input']>;
  enable_pay_period_view?: InputMaybe<Scalars['Boolean']['input']>;
  enable_time_constraint?: InputMaybe<Scalars['Boolean']['input']>;
  error_log_email_address?: InputMaybe<OrganizationCreateerror_Log_Email_AddressInput>;
  estimated_average_num_of_weekly_overbooked_demo_slots?: InputMaybe<Scalars['Int']['input']>;
  FolderPermissions?: InputMaybe<FolderPermissionCreateNestedManyWithoutOrganizationInput>;
  Folders?: InputMaybe<FolderCreateNestedManyWithoutOrganizationInput>;
  FutureBookingSetting?: InputMaybe<FutureBookingSettingCreateNestedOneWithoutOrganizationInput>;
  GlobalTransferRule?: InputMaybe<GlobalTransferRuleCreateNestedManyWithoutOrganizationInput>;
  held_demos_per_cc_connect?: InputMaybe<Scalars['Float']['input']>;
  helpful_links?: InputMaybe<OrganizationCreatehelpful_LinksInput>;
  HingePointMultiplier?: InputMaybe<HingePointMultiplierCreateNestedManyWithoutOrganizationInput>;
  hold_goal?: InputMaybe<Scalars['Float']['input']>;
  hubspot_credential?: InputMaybe<HubSpotCredentialCreateNestedOneWithoutOrganizationInput>;
  hubspot_last_sync_date?: InputMaybe<Scalars['DateTime']['input']>;
  hubspot_white_list?: InputMaybe<HubSpotWhitelistCreateNestedManyWithoutOrganizationInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  inbound_close_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_dm_connect_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_lt_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_revenue_value_of_sale?: InputMaybe<Scalars['Float']['input']>;
  inbound_scheduled_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_set_rate?: InputMaybe<Scalars['Float']['input']>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventCreateNestedManyWithoutOrganizationInput>;
  InboundConciergeWidget?: InputMaybe<InboundConciergeWidgetCreateNestedManyWithoutOrganizationInput>;
  industry_options?: InputMaybe<IndustryOptionCreateNestedManyWithoutOrganizationInput>;
  initial_sdr_sets_reps_day?: InputMaybe<Scalars['Float']['input']>;
  integration_error_logs?: InputMaybe<IntegrationErrorLogCreateNestedManyWithoutOrganizationInput>;
  integration_mappings?: InputMaybe<IntegrationMappingCreateNestedManyWithoutOrganizationInput>;
  IntegrationMappingCustomObject?: InputMaybe<IntegrationMappingCustomObjectCreateNestedManyWithoutOrganizationInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogCreateNestedManyWithoutOrganizationInput>;
  IntegrationReadOnlyObject?: InputMaybe<IntegrationReadOnlyObjectCreateNestedManyWithoutOrganizationInput>;
  international?: InputMaybe<Scalars['Boolean']['input']>;
  is_email_domain_verified?: InputMaybe<Scalars['Boolean']['input']>;
  is_salesforce_sandbox?: InputMaybe<Scalars['Boolean']['input']>;
  last_lead_value_compute_time?: InputMaybe<Scalars['DateTime']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutOrganizationInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryCreateNestedManyWithoutOrganizationInput>;
  lead_intent?: InputMaybe<LeadIntentCreateNestedManyWithoutOrganizationInput>;
  lead_release_same_rep_delay_rule?: InputMaybe<Scalars['Int']['input']>;
  lead_routing_email_list?: InputMaybe<OrganizationCreatelead_Routing_Email_ListInput>;
  lead_routing_rules?: InputMaybe<RuleCreateNestedManyWithoutOrganizationInput>;
  LeadCustomObjectRowAssociation?: InputMaybe<LeadCustomObjectRowAssociationCreateNestedManyWithoutOrganizationInput>;
  LeadFieldMultiplier?: InputMaybe<LeadFieldMultiplierCreateNestedManyWithoutOrganizationInput>;
  LeadFieldMultiplierCustom?: InputMaybe<LeadFieldMultiplierCustomCreateNestedManyWithoutOrganizationInput>;
  leads?: InputMaybe<LeadCreateNestedManyWithoutOrganizationInput>;
  leadsource_options?: InputMaybe<LeadSourceOptionCreateNestedManyWithoutOrganizationInput>;
  LeadValue?: InputMaybe<LeadValueCreateNestedManyWithoutOrganizationInput>;
  LifeTimeValueMultiplier?: InputMaybe<LifeTimeValueMultiplierCreateNestedManyWithoutOrganizationInput>;
  LiveTransferSetting?: InputMaybe<LiveTransferSettingCreateNestedOneWithoutOrganizationInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusCreateNestedManyWithoutOrganizationInput>;
  ltv_multiplier_custom_fields?: InputMaybe<LifeTimeValueMultiplierCustomCreateNestedManyWithoutOrganizationInput>;
  made_contact_rule?: InputMaybe<Scalars['Int']['input']>;
  make_sale_address_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_business_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_city_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_country_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_first_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_industry_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_last_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_lead_source_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_primary_email_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_primary_phone_number_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_state_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_sub_industry_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_zip_required?: InputMaybe<Scalars['Boolean']['input']>;
  maxium_day_lag_set_to_schedule?: InputMaybe<Scalars['Int']['input']>;
  mrr?: InputMaybe<Scalars['Int']['input']>;
  mrr_label?: InputMaybe<Scalars['String']['input']>;
  mrr_required_on_sale?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  NoteItems?: InputMaybe<NoteItemCreateNestedManyWithoutOrganizationInput>;
  Notifications?: InputMaybe<NotificationCreateNestedManyWithoutOrganizationInput>;
  onboardComplete?: InputMaybe<Scalars['Boolean']['input']>;
  Opportunity?: InputMaybe<SfOpportunityCreateNestedManyWithoutOrganizationInput>;
  organization_holidays?: InputMaybe<OrganizationHolidayCreateNestedManyWithoutOrganizationInput>;
  organization_hours?: InputMaybe<OrganizationHourCreateNestedManyWithoutOrganizationInput>;
  OrganizationPaymentSetting?: InputMaybe<OrganizationPaymentSettingsCreateNestedOneWithoutOrganizationInput>;
  OrganizationPaymentSettings?: InputMaybe<OrganizationPaymentSettingsCreateNestedOneWithoutOrganizationsInput>;
  orgCalendarInviteMetadata?: InputMaybe<OrgCalendarInviteMetadataCreateNestedManyWithoutOrganizationInput>;
  OrgDefaultPipelinePerecentValue?: InputMaybe<OrgDefaultPipelinePerecentValueCreateNestedOneWithoutOrganizationInput>;
  OrgHubSpotSync?: InputMaybe<OrgHubSpotSyncCreateNestedOneWithoutOrganizationInput>;
  OrgNoEventDate?: InputMaybe<OrgNoEventDateCreateNestedManyWithoutOrganizationInput>;
  OrgSalesforceSync?: InputMaybe<OrgSalesforceSyncCreateNestedOneWithoutOrganizationInput>;
  OrgTimeslotAvailability?: InputMaybe<OrgTimeslotAvailabilityCreateNestedManyWithoutOrganizationInput>;
  outbound_close_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_dm_connect_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_lt_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_revenue_value_of_sale?: InputMaybe<Scalars['Float']['input']>;
  outbound_scheduled_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_set_rate?: InputMaybe<Scalars['Float']['input']>;
  PandadocData?: InputMaybe<PandadocDataCreateNestedOneWithoutOrganizationInput>;
  PandaDocTemplate?: InputMaybe<PandaDocTemplateCreateNestedManyWithoutOrganizationInput>;
  participant_jitter_buffer_size?: InputMaybe<JitterBufferSize>;
  preferred_goal_setting?: InputMaybe<Goalsetting>;
  prepayment?: InputMaybe<Scalars['Boolean']['input']>;
  ProductChangeLog?: InputMaybe<ProductChangeLogCreateNestedManyWithoutOrganizationInput>;
  products?: InputMaybe<ProductCreateNestedManyWithoutOrganizationInput>;
  re_cold_call_hour_delay?: InputMaybe<Scalars['Float']['input']>;
  recordingStatus?: InputMaybe<RecordingStatus>;
  release_lead_resting?: InputMaybe<Scalars['Int']['input']>;
  rep_claim_limit?: InputMaybe<Scalars['Int']['input']>;
  rep_lead_allotment?: InputMaybe<Scalars['Int']['input']>;
  rep_level_call_transfer?: InputMaybe<Scalars['Boolean']['input']>;
  reps_can_bulk_unassign?: InputMaybe<Scalars['Boolean']['input']>;
  reps_can_view_all_notes?: InputMaybe<Scalars['Boolean']['input']>;
  require_sale_notes?: InputMaybe<Scalars['Boolean']['input']>;
  resting_after_skipped_CC?: InputMaybe<Scalars['Int']['input']>;
  resting_period?: InputMaybe<Scalars['Int']['input']>;
  resting_period_after_unsuccessful_cold_call?: InputMaybe<Scalars['Float']['input']>;
  resting_period_after_unsuccessful_demo?: InputMaybe<Scalars['Float']['input']>;
  revenue_plan?: InputMaybe<RevenuePlanItemCreateNestedManyWithoutOrganizationInput>;
  Routings?: InputMaybe<RoutingCreateNestedManyWithoutOrganizationInput>;
  sale_field_options?: InputMaybe<SaleFieldOptionCreateNestedManyWithoutOrganizationInput>;
  sale_notification_email_list?: InputMaybe<OrganizationCreatesale_Notification_Email_ListInput>;
  SaleConfig?: InputMaybe<SaleConfigCreateNestedOneWithoutOrganizationInput>;
  SaleConfigPage?: InputMaybe<SaleConfigPageCreateNestedManyWithoutOrganizationInput>;
  SaleConfigSection?: InputMaybe<SaleConfigSectionCreateNestedManyWithoutOrganizationInput>;
  SaleCycles?: InputMaybe<SaleCycleCreateNestedManyWithoutOrganizationInput>;
  SaleItems?: InputMaybe<SaleItemCreateNestedManyWithoutOrganizationInput>;
  sales?: InputMaybe<SaleCreateNestedManyWithoutOrganizationInput>;
  salesforce_domain?: InputMaybe<Scalars['String']['input']>;
  salesforce_last_sync_date?: InputMaybe<Scalars['DateTime']['input']>;
  SalesForceColumn?: InputMaybe<SalesForceColumnCreateNestedManyWithoutOrganizationInput>;
  salesForceData?: InputMaybe<SalesForceDataCreateNestedOneWithoutOrganizationInput>;
  ScheduleItems?: InputMaybe<ScheduleItemCreateNestedManyWithoutOrganizationInput>;
  self_sourcing_rule?: InputMaybe<Scalars['Int']['input']>;
  send_error_log_email?: InputMaybe<Scalars['Boolean']['input']>;
  send_lead_routing_email?: InputMaybe<Scalars['Boolean']['input']>;
  sendgrid_email_domain?: InputMaybe<Scalars['String']['input']>;
  sendgrid_email_domain_id?: InputMaybe<Scalars['Int']['input']>;
  sequence?: InputMaybe<SequenceCreateNestedManyWithoutOrganzationInput>;
  SequenceDashboardViews?: InputMaybe<SequenceDashboardViewCreateNestedManyWithoutOrganizationInput>;
  SequenceEntryCriterias?: InputMaybe<SequenceEntryCriteriaCreateNestedManyWithoutOrganizationInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutOrganizationInput>;
  SequenceSteps?: InputMaybe<SequenceStepCreateNestedManyWithoutOrganizationInput>;
  set_goal?: InputMaybe<Scalars['Float']['input']>;
  short_resting?: InputMaybe<Scalars['Int']['input']>;
  show_calendar_options?: InputMaybe<Scalars['Boolean']['input']>;
  show_contract_duration_sales?: InputMaybe<Scalars['Boolean']['input']>;
  show_lead_source_to_rep?: InputMaybe<Scalars['Boolean']['input']>;
  show_nocontact_button?: InputMaybe<Scalars['Boolean']['input']>;
  show_payment_terms_sales?: InputMaybe<Scalars['Boolean']['input']>;
  show_rep_all_leads?: InputMaybe<Scalars['Boolean']['input']>;
  show_rep_my_leads?: InputMaybe<Scalars['Boolean']['input']>;
  show_system_view?: InputMaybe<Scalars['Boolean']['input']>;
  sites?: InputMaybe<SiteCreateNestedManyWithoutOrganizationInput>;
  sms_opt_in_dialog?: InputMaybe<Scalars['String']['input']>;
  SMSItems?: InputMaybe<SmsItemCreateNestedManyWithoutOrganizationInput>;
  SnoozeTrackings?: InputMaybe<SnoozeTrackingCreateNestedManyWithoutOrganizationInput>;
  StepActionLogs?: InputMaybe<StepActionLogCreateNestedManyWithoutOrganizationInput>;
  StepActions?: InputMaybe<StepActionCreateNestedManyWithoutOrganizationInput>;
  StepConditions?: InputMaybe<StepConditionCreateNestedManyWithoutOrganizationInput>;
  teams?: InputMaybe<TeamCreateNestedManyWithoutOrganizationInput>;
  Template?: InputMaybe<TemplateCreateNestedManyWithoutOrganizationInput>;
  TemplateVersions?: InputMaybe<TemplateVersionCreateNestedManyWithoutOrganizationInput>;
  timekit_id?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  transfer_timeout?: InputMaybe<Scalars['Int']['input']>;
  TransferAttempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutOrganizationInput>;
  twilio_numbers?: InputMaybe<TwilioNumberCreateNestedManyWithoutOrganizationInput>;
  TwilioFeedbacks?: InputMaybe<TwilioFeedbackCreateNestedManyWithoutOrganizationInput>;
  TwilioInfo?: InputMaybe<TwilioInfoCreateNestedOneWithoutOrganzationInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  use_revenue_planning?: InputMaybe<Scalars['Boolean']['input']>;
  UserCustomFieldFilters?: InputMaybe<UserCustomFieldFilterCreateNestedManyWithoutOrganizationInput>;
  users?: InputMaybe<UserCreateNestedManyWithoutOrganizationInput>;
  win_rep_day_goal?: InputMaybe<Scalars['Float']['input']>;
};

export type OrganizationCreateWithoutSequenceDashboardViewsInput = {
  ActivityMonitorMultiplier?: InputMaybe<ActivityMonitorMultiplierCreateNestedManyWithoutOrganizationInput>;
  algoFactor?: InputMaybe<AlgoFactorCreateNestedManyWithoutOrganizationInput>;
  allow_calendar_invite?: InputMaybe<Scalars['Boolean']['input']>;
  allow_remove_step_action?: InputMaybe<Scalars['Boolean']['input']>;
  allow_snooze_step_action?: InputMaybe<Scalars['Boolean']['input']>;
  always_record?: InputMaybe<Scalars['Boolean']['input']>;
  arv_initial_held_demos_generated_by_sdr_weight?: InputMaybe<Scalars['Float']['input']>;
  AssociationHistories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutOrganizationInput>;
  auto_call_interval?: InputMaybe<Scalars['Int']['input']>;
  auto_dial?: InputMaybe<Scalars['Boolean']['input']>;
  automated_campaigns?: InputMaybe<AutomatedCampaignCreateNestedManyWithoutOrganizationInput>;
  automatic_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  AutoTransferSelections?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutOrganizationInput>;
  Availablities?: InputMaybe<AvailabilityCreateNestedManyWithoutOrganizationInput>;
  AverageOrderValue?: InputMaybe<AverageOrderValueCreateNestedManyWithoutOrganizationInput>;
  best_action_ever_multiplier?: InputMaybe<Scalars['Float']['input']>;
  best_metrics?: InputMaybe<BestMetricRecordCreateNestedManyWithoutOrganizationInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptCreateNestedManyWithoutOrganizationInput>;
  callMeNows?: InputMaybe<CallMeNowCreateNestedManyWithoutOrganizationInput>;
  CallMultiplier?: InputMaybe<CallMultiplierCreateNestedManyWithoutOrganizationInput>;
  CallSharings?: InputMaybe<CallSharingCreateNestedManyWithoutOrganizationInput>;
  can_edit_sdr_transfer_rules?: InputMaybe<Scalars['Boolean']['input']>;
  claim_rule?: InputMaybe<Scalars['Int']['input']>;
  concession?: InputMaybe<ConcessionCreateNestedManyWithoutOrganizationInput>;
  conference_region?: InputMaybe<ConferenceRegion>;
  Conferences?: InputMaybe<ConferenceCreateNestedManyWithoutOrganizationInput>;
  ContactRequests?: InputMaybe<ContactRequestCreateNestedManyWithoutOrganizationInput>;
  Contacts?: InputMaybe<ContactCreateNestedManyWithoutOrganizationInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  custom_fields?: InputMaybe<CustomFieldCreateNestedManyWithoutOrganizationInput>;
  CustomObject?: InputMaybe<CustomObjectCreateNestedManyWithoutOrganizationInput>;
  CustomObjectField?: InputMaybe<CustomObjectFieldCreateNestedManyWithoutOrganizationInput>;
  CustomObjectFieldForeignKey?: InputMaybe<CustomObjectFieldForeignKeyCreateNestedManyWithoutOrganizationInput>;
  CustomObjectRecord?: InputMaybe<CustomObjectRecordCreateNestedManyWithoutOrganizationInput>;
  CustomObjectRow?: InputMaybe<CustomObjectRowCreateNestedManyWithoutOrganizationInput>;
  CustomQueueItems?: InputMaybe<CustomQueueItemCreateNestedManyWithoutOrganizationInput>;
  DashboardColumnOptions?: InputMaybe<DashboardColumnOptionCreateNestedManyWithoutOrganizationInput>;
  DashboardLeadFilters?: InputMaybe<DashboardLeadFilterCreateNestedManyWithoutOrganizationInput>;
  DashboardRepFilters?: InputMaybe<DashboardRepFilterCreateNestedManyWithoutOrganizationInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewCreateNestedManyWithoutOrganizationInput>;
  day_to_close?: InputMaybe<Scalars['Int']['input']>;
  default_demo_time_duration?: InputMaybe<Scalars['Int']['input']>;
  demo_held_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_not_held_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_not_held_call_back_short_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_prep_time_minutes?: InputMaybe<Scalars['Int']['input']>;
  dev_account?: InputMaybe<Scalars['Boolean']['input']>;
  dial_rule?: InputMaybe<Scalars['Int']['input']>;
  DispositionMultiplier?: InputMaybe<DispositionMultiplierCreateNestedManyWithoutOrganizationInput>;
  dispositions?: InputMaybe<DispositionCreateNestedManyWithoutOrganizationInput>;
  double_booking?: InputMaybe<Scalars['Boolean']['input']>;
  dynamic_date_operators?: InputMaybe<Scalars['Boolean']['input']>;
  edge_servers?: InputMaybe<OrganizationCreateedge_ServersInput>;
  email_conflict_notification_email_list?: InputMaybe<OrganizationCreateemail_Conflict_Notification_Email_ListInput>;
  EmailItems?: InputMaybe<EmailItemCreateNestedManyWithoutOrganizationInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordCreateNestedManyWithoutOrganizationInput>;
  EmailThreads?: InputMaybe<EmailThreadCreateNestedManyWithoutOrganizationInput>;
  enable_modified_metrics?: InputMaybe<Scalars['Boolean']['input']>;
  enable_pay_period_view?: InputMaybe<Scalars['Boolean']['input']>;
  enable_time_constraint?: InputMaybe<Scalars['Boolean']['input']>;
  error_log_email_address?: InputMaybe<OrganizationCreateerror_Log_Email_AddressInput>;
  estimated_average_num_of_weekly_overbooked_demo_slots?: InputMaybe<Scalars['Int']['input']>;
  FolderPermissions?: InputMaybe<FolderPermissionCreateNestedManyWithoutOrganizationInput>;
  Folders?: InputMaybe<FolderCreateNestedManyWithoutOrganizationInput>;
  FutureBookingSetting?: InputMaybe<FutureBookingSettingCreateNestedOneWithoutOrganizationInput>;
  GlobalTransferRule?: InputMaybe<GlobalTransferRuleCreateNestedManyWithoutOrganizationInput>;
  held_demos_per_cc_connect?: InputMaybe<Scalars['Float']['input']>;
  helpful_links?: InputMaybe<OrganizationCreatehelpful_LinksInput>;
  HingePointMultiplier?: InputMaybe<HingePointMultiplierCreateNestedManyWithoutOrganizationInput>;
  hold_goal?: InputMaybe<Scalars['Float']['input']>;
  hubspot_credential?: InputMaybe<HubSpotCredentialCreateNestedOneWithoutOrganizationInput>;
  hubspot_last_sync_date?: InputMaybe<Scalars['DateTime']['input']>;
  hubspot_white_list?: InputMaybe<HubSpotWhitelistCreateNestedManyWithoutOrganizationInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  inbound_close_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_dm_connect_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_lt_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_revenue_value_of_sale?: InputMaybe<Scalars['Float']['input']>;
  inbound_scheduled_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_set_rate?: InputMaybe<Scalars['Float']['input']>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventCreateNestedManyWithoutOrganizationInput>;
  InboundConciergeWidget?: InputMaybe<InboundConciergeWidgetCreateNestedManyWithoutOrganizationInput>;
  industry_options?: InputMaybe<IndustryOptionCreateNestedManyWithoutOrganizationInput>;
  initial_sdr_sets_reps_day?: InputMaybe<Scalars['Float']['input']>;
  integration_error_logs?: InputMaybe<IntegrationErrorLogCreateNestedManyWithoutOrganizationInput>;
  integration_mappings?: InputMaybe<IntegrationMappingCreateNestedManyWithoutOrganizationInput>;
  IntegrationMappingCustomObject?: InputMaybe<IntegrationMappingCustomObjectCreateNestedManyWithoutOrganizationInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogCreateNestedManyWithoutOrganizationInput>;
  IntegrationReadOnlyObject?: InputMaybe<IntegrationReadOnlyObjectCreateNestedManyWithoutOrganizationInput>;
  international?: InputMaybe<Scalars['Boolean']['input']>;
  is_email_domain_verified?: InputMaybe<Scalars['Boolean']['input']>;
  is_salesforce_sandbox?: InputMaybe<Scalars['Boolean']['input']>;
  last_lead_value_compute_time?: InputMaybe<Scalars['DateTime']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutOrganizationInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryCreateNestedManyWithoutOrganizationInput>;
  lead_intent?: InputMaybe<LeadIntentCreateNestedManyWithoutOrganizationInput>;
  lead_release_same_rep_delay_rule?: InputMaybe<Scalars['Int']['input']>;
  lead_routing_email_list?: InputMaybe<OrganizationCreatelead_Routing_Email_ListInput>;
  lead_routing_rules?: InputMaybe<RuleCreateNestedManyWithoutOrganizationInput>;
  LeadCustomObjectRowAssociation?: InputMaybe<LeadCustomObjectRowAssociationCreateNestedManyWithoutOrganizationInput>;
  LeadFieldMultiplier?: InputMaybe<LeadFieldMultiplierCreateNestedManyWithoutOrganizationInput>;
  LeadFieldMultiplierCustom?: InputMaybe<LeadFieldMultiplierCustomCreateNestedManyWithoutOrganizationInput>;
  leads?: InputMaybe<LeadCreateNestedManyWithoutOrganizationInput>;
  leadsource_options?: InputMaybe<LeadSourceOptionCreateNestedManyWithoutOrganizationInput>;
  LeadValue?: InputMaybe<LeadValueCreateNestedManyWithoutOrganizationInput>;
  LifeTimeValueMultiplier?: InputMaybe<LifeTimeValueMultiplierCreateNestedManyWithoutOrganizationInput>;
  LiveTransferSetting?: InputMaybe<LiveTransferSettingCreateNestedOneWithoutOrganizationInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusCreateNestedManyWithoutOrganizationInput>;
  ltv_multiplier_custom_fields?: InputMaybe<LifeTimeValueMultiplierCustomCreateNestedManyWithoutOrganizationInput>;
  made_contact_rule?: InputMaybe<Scalars['Int']['input']>;
  make_sale_address_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_business_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_city_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_country_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_first_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_industry_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_last_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_lead_source_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_primary_email_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_primary_phone_number_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_state_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_sub_industry_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_zip_required?: InputMaybe<Scalars['Boolean']['input']>;
  maxium_day_lag_set_to_schedule?: InputMaybe<Scalars['Int']['input']>;
  mrr?: InputMaybe<Scalars['Int']['input']>;
  mrr_label?: InputMaybe<Scalars['String']['input']>;
  mrr_required_on_sale?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  NoteItems?: InputMaybe<NoteItemCreateNestedManyWithoutOrganizationInput>;
  Notifications?: InputMaybe<NotificationCreateNestedManyWithoutOrganizationInput>;
  onboardComplete?: InputMaybe<Scalars['Boolean']['input']>;
  Opportunity?: InputMaybe<SfOpportunityCreateNestedManyWithoutOrganizationInput>;
  organization_holidays?: InputMaybe<OrganizationHolidayCreateNestedManyWithoutOrganizationInput>;
  organization_hours?: InputMaybe<OrganizationHourCreateNestedManyWithoutOrganizationInput>;
  OrganizationPaymentSetting?: InputMaybe<OrganizationPaymentSettingsCreateNestedOneWithoutOrganizationInput>;
  OrganizationPaymentSettings?: InputMaybe<OrganizationPaymentSettingsCreateNestedOneWithoutOrganizationsInput>;
  orgCalendarInviteMetadata?: InputMaybe<OrgCalendarInviteMetadataCreateNestedManyWithoutOrganizationInput>;
  OrgDefaultPipelinePerecentValue?: InputMaybe<OrgDefaultPipelinePerecentValueCreateNestedOneWithoutOrganizationInput>;
  OrgHubSpotSync?: InputMaybe<OrgHubSpotSyncCreateNestedOneWithoutOrganizationInput>;
  OrgNoEventDate?: InputMaybe<OrgNoEventDateCreateNestedManyWithoutOrganizationInput>;
  OrgSalesforceSync?: InputMaybe<OrgSalesforceSyncCreateNestedOneWithoutOrganizationInput>;
  OrgTimeslotAvailability?: InputMaybe<OrgTimeslotAvailabilityCreateNestedManyWithoutOrganizationInput>;
  outbound_close_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_dm_connect_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_lt_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_revenue_value_of_sale?: InputMaybe<Scalars['Float']['input']>;
  outbound_scheduled_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_set_rate?: InputMaybe<Scalars['Float']['input']>;
  PandadocData?: InputMaybe<PandadocDataCreateNestedOneWithoutOrganizationInput>;
  PandaDocTemplate?: InputMaybe<PandaDocTemplateCreateNestedManyWithoutOrganizationInput>;
  participant_jitter_buffer_size?: InputMaybe<JitterBufferSize>;
  preferred_goal_setting?: InputMaybe<Goalsetting>;
  prepayment?: InputMaybe<Scalars['Boolean']['input']>;
  ProductChangeLog?: InputMaybe<ProductChangeLogCreateNestedManyWithoutOrganizationInput>;
  products?: InputMaybe<ProductCreateNestedManyWithoutOrganizationInput>;
  re_cold_call_hour_delay?: InputMaybe<Scalars['Float']['input']>;
  recordingStatus?: InputMaybe<RecordingStatus>;
  release_lead_resting?: InputMaybe<Scalars['Int']['input']>;
  rep_claim_limit?: InputMaybe<Scalars['Int']['input']>;
  rep_lead_allotment?: InputMaybe<Scalars['Int']['input']>;
  rep_level_call_transfer?: InputMaybe<Scalars['Boolean']['input']>;
  reps_can_bulk_unassign?: InputMaybe<Scalars['Boolean']['input']>;
  reps_can_view_all_notes?: InputMaybe<Scalars['Boolean']['input']>;
  require_sale_notes?: InputMaybe<Scalars['Boolean']['input']>;
  resting_after_skipped_CC?: InputMaybe<Scalars['Int']['input']>;
  resting_period?: InputMaybe<Scalars['Int']['input']>;
  resting_period_after_unsuccessful_cold_call?: InputMaybe<Scalars['Float']['input']>;
  resting_period_after_unsuccessful_demo?: InputMaybe<Scalars['Float']['input']>;
  revenue_plan?: InputMaybe<RevenuePlanItemCreateNestedManyWithoutOrganizationInput>;
  Routings?: InputMaybe<RoutingCreateNestedManyWithoutOrganizationInput>;
  sale_field_options?: InputMaybe<SaleFieldOptionCreateNestedManyWithoutOrganizationInput>;
  sale_notification_email_list?: InputMaybe<OrganizationCreatesale_Notification_Email_ListInput>;
  SaleConfig?: InputMaybe<SaleConfigCreateNestedOneWithoutOrganizationInput>;
  SaleConfigPage?: InputMaybe<SaleConfigPageCreateNestedManyWithoutOrganizationInput>;
  SaleConfigSection?: InputMaybe<SaleConfigSectionCreateNestedManyWithoutOrganizationInput>;
  SaleCycles?: InputMaybe<SaleCycleCreateNestedManyWithoutOrganizationInput>;
  SaleItems?: InputMaybe<SaleItemCreateNestedManyWithoutOrganizationInput>;
  sales?: InputMaybe<SaleCreateNestedManyWithoutOrganizationInput>;
  salesforce_domain?: InputMaybe<Scalars['String']['input']>;
  salesforce_last_sync_date?: InputMaybe<Scalars['DateTime']['input']>;
  SalesForceColumn?: InputMaybe<SalesForceColumnCreateNestedManyWithoutOrganizationInput>;
  salesForceData?: InputMaybe<SalesForceDataCreateNestedOneWithoutOrganizationInput>;
  ScheduleItems?: InputMaybe<ScheduleItemCreateNestedManyWithoutOrganizationInput>;
  self_sourcing_rule?: InputMaybe<Scalars['Int']['input']>;
  send_error_log_email?: InputMaybe<Scalars['Boolean']['input']>;
  send_lead_routing_email?: InputMaybe<Scalars['Boolean']['input']>;
  sendgrid_email_domain?: InputMaybe<Scalars['String']['input']>;
  sendgrid_email_domain_id?: InputMaybe<Scalars['Int']['input']>;
  sequence?: InputMaybe<SequenceCreateNestedManyWithoutOrganzationInput>;
  SequenceCriteriaConditions?: InputMaybe<SequenceCriteriaConditionCreateNestedManyWithoutOrganizationInput>;
  SequenceEntryCriterias?: InputMaybe<SequenceEntryCriteriaCreateNestedManyWithoutOrganizationInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutOrganizationInput>;
  SequenceSteps?: InputMaybe<SequenceStepCreateNestedManyWithoutOrganizationInput>;
  set_goal?: InputMaybe<Scalars['Float']['input']>;
  short_resting?: InputMaybe<Scalars['Int']['input']>;
  show_calendar_options?: InputMaybe<Scalars['Boolean']['input']>;
  show_contract_duration_sales?: InputMaybe<Scalars['Boolean']['input']>;
  show_lead_source_to_rep?: InputMaybe<Scalars['Boolean']['input']>;
  show_nocontact_button?: InputMaybe<Scalars['Boolean']['input']>;
  show_payment_terms_sales?: InputMaybe<Scalars['Boolean']['input']>;
  show_rep_all_leads?: InputMaybe<Scalars['Boolean']['input']>;
  show_rep_my_leads?: InputMaybe<Scalars['Boolean']['input']>;
  show_system_view?: InputMaybe<Scalars['Boolean']['input']>;
  sites?: InputMaybe<SiteCreateNestedManyWithoutOrganizationInput>;
  sms_opt_in_dialog?: InputMaybe<Scalars['String']['input']>;
  SMSItems?: InputMaybe<SmsItemCreateNestedManyWithoutOrganizationInput>;
  SnoozeTrackings?: InputMaybe<SnoozeTrackingCreateNestedManyWithoutOrganizationInput>;
  StepActionLogs?: InputMaybe<StepActionLogCreateNestedManyWithoutOrganizationInput>;
  StepActions?: InputMaybe<StepActionCreateNestedManyWithoutOrganizationInput>;
  StepConditions?: InputMaybe<StepConditionCreateNestedManyWithoutOrganizationInput>;
  teams?: InputMaybe<TeamCreateNestedManyWithoutOrganizationInput>;
  Template?: InputMaybe<TemplateCreateNestedManyWithoutOrganizationInput>;
  TemplateVersions?: InputMaybe<TemplateVersionCreateNestedManyWithoutOrganizationInput>;
  timekit_id?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  transfer_timeout?: InputMaybe<Scalars['Int']['input']>;
  TransferAttempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutOrganizationInput>;
  twilio_numbers?: InputMaybe<TwilioNumberCreateNestedManyWithoutOrganizationInput>;
  TwilioFeedbacks?: InputMaybe<TwilioFeedbackCreateNestedManyWithoutOrganizationInput>;
  TwilioInfo?: InputMaybe<TwilioInfoCreateNestedOneWithoutOrganzationInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  use_revenue_planning?: InputMaybe<Scalars['Boolean']['input']>;
  UserCustomFieldFilters?: InputMaybe<UserCustomFieldFilterCreateNestedManyWithoutOrganizationInput>;
  users?: InputMaybe<UserCreateNestedManyWithoutOrganizationInput>;
  win_rep_day_goal?: InputMaybe<Scalars['Float']['input']>;
};

export type OrganizationCreateWithoutSequenceEntryCriteriasInput = {
  ActivityMonitorMultiplier?: InputMaybe<ActivityMonitorMultiplierCreateNestedManyWithoutOrganizationInput>;
  algoFactor?: InputMaybe<AlgoFactorCreateNestedManyWithoutOrganizationInput>;
  allow_calendar_invite?: InputMaybe<Scalars['Boolean']['input']>;
  allow_remove_step_action?: InputMaybe<Scalars['Boolean']['input']>;
  allow_snooze_step_action?: InputMaybe<Scalars['Boolean']['input']>;
  always_record?: InputMaybe<Scalars['Boolean']['input']>;
  arv_initial_held_demos_generated_by_sdr_weight?: InputMaybe<Scalars['Float']['input']>;
  AssociationHistories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutOrganizationInput>;
  auto_call_interval?: InputMaybe<Scalars['Int']['input']>;
  auto_dial?: InputMaybe<Scalars['Boolean']['input']>;
  automated_campaigns?: InputMaybe<AutomatedCampaignCreateNestedManyWithoutOrganizationInput>;
  automatic_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  AutoTransferSelections?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutOrganizationInput>;
  Availablities?: InputMaybe<AvailabilityCreateNestedManyWithoutOrganizationInput>;
  AverageOrderValue?: InputMaybe<AverageOrderValueCreateNestedManyWithoutOrganizationInput>;
  best_action_ever_multiplier?: InputMaybe<Scalars['Float']['input']>;
  best_metrics?: InputMaybe<BestMetricRecordCreateNestedManyWithoutOrganizationInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptCreateNestedManyWithoutOrganizationInput>;
  callMeNows?: InputMaybe<CallMeNowCreateNestedManyWithoutOrganizationInput>;
  CallMultiplier?: InputMaybe<CallMultiplierCreateNestedManyWithoutOrganizationInput>;
  CallSharings?: InputMaybe<CallSharingCreateNestedManyWithoutOrganizationInput>;
  can_edit_sdr_transfer_rules?: InputMaybe<Scalars['Boolean']['input']>;
  claim_rule?: InputMaybe<Scalars['Int']['input']>;
  concession?: InputMaybe<ConcessionCreateNestedManyWithoutOrganizationInput>;
  conference_region?: InputMaybe<ConferenceRegion>;
  Conferences?: InputMaybe<ConferenceCreateNestedManyWithoutOrganizationInput>;
  ContactRequests?: InputMaybe<ContactRequestCreateNestedManyWithoutOrganizationInput>;
  Contacts?: InputMaybe<ContactCreateNestedManyWithoutOrganizationInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  custom_fields?: InputMaybe<CustomFieldCreateNestedManyWithoutOrganizationInput>;
  CustomObject?: InputMaybe<CustomObjectCreateNestedManyWithoutOrganizationInput>;
  CustomObjectField?: InputMaybe<CustomObjectFieldCreateNestedManyWithoutOrganizationInput>;
  CustomObjectFieldForeignKey?: InputMaybe<CustomObjectFieldForeignKeyCreateNestedManyWithoutOrganizationInput>;
  CustomObjectRecord?: InputMaybe<CustomObjectRecordCreateNestedManyWithoutOrganizationInput>;
  CustomObjectRow?: InputMaybe<CustomObjectRowCreateNestedManyWithoutOrganizationInput>;
  CustomQueueItems?: InputMaybe<CustomQueueItemCreateNestedManyWithoutOrganizationInput>;
  DashboardColumnOptions?: InputMaybe<DashboardColumnOptionCreateNestedManyWithoutOrganizationInput>;
  DashboardLeadFilters?: InputMaybe<DashboardLeadFilterCreateNestedManyWithoutOrganizationInput>;
  DashboardRepFilters?: InputMaybe<DashboardRepFilterCreateNestedManyWithoutOrganizationInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewCreateNestedManyWithoutOrganizationInput>;
  day_to_close?: InputMaybe<Scalars['Int']['input']>;
  default_demo_time_duration?: InputMaybe<Scalars['Int']['input']>;
  demo_held_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_not_held_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_not_held_call_back_short_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_prep_time_minutes?: InputMaybe<Scalars['Int']['input']>;
  dev_account?: InputMaybe<Scalars['Boolean']['input']>;
  dial_rule?: InputMaybe<Scalars['Int']['input']>;
  DispositionMultiplier?: InputMaybe<DispositionMultiplierCreateNestedManyWithoutOrganizationInput>;
  dispositions?: InputMaybe<DispositionCreateNestedManyWithoutOrganizationInput>;
  double_booking?: InputMaybe<Scalars['Boolean']['input']>;
  dynamic_date_operators?: InputMaybe<Scalars['Boolean']['input']>;
  edge_servers?: InputMaybe<OrganizationCreateedge_ServersInput>;
  email_conflict_notification_email_list?: InputMaybe<OrganizationCreateemail_Conflict_Notification_Email_ListInput>;
  EmailItems?: InputMaybe<EmailItemCreateNestedManyWithoutOrganizationInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordCreateNestedManyWithoutOrganizationInput>;
  EmailThreads?: InputMaybe<EmailThreadCreateNestedManyWithoutOrganizationInput>;
  enable_modified_metrics?: InputMaybe<Scalars['Boolean']['input']>;
  enable_pay_period_view?: InputMaybe<Scalars['Boolean']['input']>;
  enable_time_constraint?: InputMaybe<Scalars['Boolean']['input']>;
  error_log_email_address?: InputMaybe<OrganizationCreateerror_Log_Email_AddressInput>;
  estimated_average_num_of_weekly_overbooked_demo_slots?: InputMaybe<Scalars['Int']['input']>;
  FolderPermissions?: InputMaybe<FolderPermissionCreateNestedManyWithoutOrganizationInput>;
  Folders?: InputMaybe<FolderCreateNestedManyWithoutOrganizationInput>;
  FutureBookingSetting?: InputMaybe<FutureBookingSettingCreateNestedOneWithoutOrganizationInput>;
  GlobalTransferRule?: InputMaybe<GlobalTransferRuleCreateNestedManyWithoutOrganizationInput>;
  held_demos_per_cc_connect?: InputMaybe<Scalars['Float']['input']>;
  helpful_links?: InputMaybe<OrganizationCreatehelpful_LinksInput>;
  HingePointMultiplier?: InputMaybe<HingePointMultiplierCreateNestedManyWithoutOrganizationInput>;
  hold_goal?: InputMaybe<Scalars['Float']['input']>;
  hubspot_credential?: InputMaybe<HubSpotCredentialCreateNestedOneWithoutOrganizationInput>;
  hubspot_last_sync_date?: InputMaybe<Scalars['DateTime']['input']>;
  hubspot_white_list?: InputMaybe<HubSpotWhitelistCreateNestedManyWithoutOrganizationInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  inbound_close_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_dm_connect_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_lt_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_revenue_value_of_sale?: InputMaybe<Scalars['Float']['input']>;
  inbound_scheduled_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_set_rate?: InputMaybe<Scalars['Float']['input']>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventCreateNestedManyWithoutOrganizationInput>;
  InboundConciergeWidget?: InputMaybe<InboundConciergeWidgetCreateNestedManyWithoutOrganizationInput>;
  industry_options?: InputMaybe<IndustryOptionCreateNestedManyWithoutOrganizationInput>;
  initial_sdr_sets_reps_day?: InputMaybe<Scalars['Float']['input']>;
  integration_error_logs?: InputMaybe<IntegrationErrorLogCreateNestedManyWithoutOrganizationInput>;
  integration_mappings?: InputMaybe<IntegrationMappingCreateNestedManyWithoutOrganizationInput>;
  IntegrationMappingCustomObject?: InputMaybe<IntegrationMappingCustomObjectCreateNestedManyWithoutOrganizationInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogCreateNestedManyWithoutOrganizationInput>;
  IntegrationReadOnlyObject?: InputMaybe<IntegrationReadOnlyObjectCreateNestedManyWithoutOrganizationInput>;
  international?: InputMaybe<Scalars['Boolean']['input']>;
  is_email_domain_verified?: InputMaybe<Scalars['Boolean']['input']>;
  is_salesforce_sandbox?: InputMaybe<Scalars['Boolean']['input']>;
  last_lead_value_compute_time?: InputMaybe<Scalars['DateTime']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutOrganizationInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryCreateNestedManyWithoutOrganizationInput>;
  lead_intent?: InputMaybe<LeadIntentCreateNestedManyWithoutOrganizationInput>;
  lead_release_same_rep_delay_rule?: InputMaybe<Scalars['Int']['input']>;
  lead_routing_email_list?: InputMaybe<OrganizationCreatelead_Routing_Email_ListInput>;
  lead_routing_rules?: InputMaybe<RuleCreateNestedManyWithoutOrganizationInput>;
  LeadCustomObjectRowAssociation?: InputMaybe<LeadCustomObjectRowAssociationCreateNestedManyWithoutOrganizationInput>;
  LeadFieldMultiplier?: InputMaybe<LeadFieldMultiplierCreateNestedManyWithoutOrganizationInput>;
  LeadFieldMultiplierCustom?: InputMaybe<LeadFieldMultiplierCustomCreateNestedManyWithoutOrganizationInput>;
  leads?: InputMaybe<LeadCreateNestedManyWithoutOrganizationInput>;
  leadsource_options?: InputMaybe<LeadSourceOptionCreateNestedManyWithoutOrganizationInput>;
  LeadValue?: InputMaybe<LeadValueCreateNestedManyWithoutOrganizationInput>;
  LifeTimeValueMultiplier?: InputMaybe<LifeTimeValueMultiplierCreateNestedManyWithoutOrganizationInput>;
  LiveTransferSetting?: InputMaybe<LiveTransferSettingCreateNestedOneWithoutOrganizationInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusCreateNestedManyWithoutOrganizationInput>;
  ltv_multiplier_custom_fields?: InputMaybe<LifeTimeValueMultiplierCustomCreateNestedManyWithoutOrganizationInput>;
  made_contact_rule?: InputMaybe<Scalars['Int']['input']>;
  make_sale_address_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_business_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_city_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_country_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_first_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_industry_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_last_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_lead_source_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_primary_email_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_primary_phone_number_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_state_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_sub_industry_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_zip_required?: InputMaybe<Scalars['Boolean']['input']>;
  maxium_day_lag_set_to_schedule?: InputMaybe<Scalars['Int']['input']>;
  mrr?: InputMaybe<Scalars['Int']['input']>;
  mrr_label?: InputMaybe<Scalars['String']['input']>;
  mrr_required_on_sale?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  NoteItems?: InputMaybe<NoteItemCreateNestedManyWithoutOrganizationInput>;
  Notifications?: InputMaybe<NotificationCreateNestedManyWithoutOrganizationInput>;
  onboardComplete?: InputMaybe<Scalars['Boolean']['input']>;
  Opportunity?: InputMaybe<SfOpportunityCreateNestedManyWithoutOrganizationInput>;
  organization_holidays?: InputMaybe<OrganizationHolidayCreateNestedManyWithoutOrganizationInput>;
  organization_hours?: InputMaybe<OrganizationHourCreateNestedManyWithoutOrganizationInput>;
  OrganizationPaymentSetting?: InputMaybe<OrganizationPaymentSettingsCreateNestedOneWithoutOrganizationInput>;
  OrganizationPaymentSettings?: InputMaybe<OrganizationPaymentSettingsCreateNestedOneWithoutOrganizationsInput>;
  orgCalendarInviteMetadata?: InputMaybe<OrgCalendarInviteMetadataCreateNestedManyWithoutOrganizationInput>;
  OrgDefaultPipelinePerecentValue?: InputMaybe<OrgDefaultPipelinePerecentValueCreateNestedOneWithoutOrganizationInput>;
  OrgHubSpotSync?: InputMaybe<OrgHubSpotSyncCreateNestedOneWithoutOrganizationInput>;
  OrgNoEventDate?: InputMaybe<OrgNoEventDateCreateNestedManyWithoutOrganizationInput>;
  OrgSalesforceSync?: InputMaybe<OrgSalesforceSyncCreateNestedOneWithoutOrganizationInput>;
  OrgTimeslotAvailability?: InputMaybe<OrgTimeslotAvailabilityCreateNestedManyWithoutOrganizationInput>;
  outbound_close_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_dm_connect_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_lt_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_revenue_value_of_sale?: InputMaybe<Scalars['Float']['input']>;
  outbound_scheduled_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_set_rate?: InputMaybe<Scalars['Float']['input']>;
  PandadocData?: InputMaybe<PandadocDataCreateNestedOneWithoutOrganizationInput>;
  PandaDocTemplate?: InputMaybe<PandaDocTemplateCreateNestedManyWithoutOrganizationInput>;
  participant_jitter_buffer_size?: InputMaybe<JitterBufferSize>;
  preferred_goal_setting?: InputMaybe<Goalsetting>;
  prepayment?: InputMaybe<Scalars['Boolean']['input']>;
  ProductChangeLog?: InputMaybe<ProductChangeLogCreateNestedManyWithoutOrganizationInput>;
  products?: InputMaybe<ProductCreateNestedManyWithoutOrganizationInput>;
  re_cold_call_hour_delay?: InputMaybe<Scalars['Float']['input']>;
  recordingStatus?: InputMaybe<RecordingStatus>;
  release_lead_resting?: InputMaybe<Scalars['Int']['input']>;
  rep_claim_limit?: InputMaybe<Scalars['Int']['input']>;
  rep_lead_allotment?: InputMaybe<Scalars['Int']['input']>;
  rep_level_call_transfer?: InputMaybe<Scalars['Boolean']['input']>;
  reps_can_bulk_unassign?: InputMaybe<Scalars['Boolean']['input']>;
  reps_can_view_all_notes?: InputMaybe<Scalars['Boolean']['input']>;
  require_sale_notes?: InputMaybe<Scalars['Boolean']['input']>;
  resting_after_skipped_CC?: InputMaybe<Scalars['Int']['input']>;
  resting_period?: InputMaybe<Scalars['Int']['input']>;
  resting_period_after_unsuccessful_cold_call?: InputMaybe<Scalars['Float']['input']>;
  resting_period_after_unsuccessful_demo?: InputMaybe<Scalars['Float']['input']>;
  revenue_plan?: InputMaybe<RevenuePlanItemCreateNestedManyWithoutOrganizationInput>;
  Routings?: InputMaybe<RoutingCreateNestedManyWithoutOrganizationInput>;
  sale_field_options?: InputMaybe<SaleFieldOptionCreateNestedManyWithoutOrganizationInput>;
  sale_notification_email_list?: InputMaybe<OrganizationCreatesale_Notification_Email_ListInput>;
  SaleConfig?: InputMaybe<SaleConfigCreateNestedOneWithoutOrganizationInput>;
  SaleConfigPage?: InputMaybe<SaleConfigPageCreateNestedManyWithoutOrganizationInput>;
  SaleConfigSection?: InputMaybe<SaleConfigSectionCreateNestedManyWithoutOrganizationInput>;
  SaleCycles?: InputMaybe<SaleCycleCreateNestedManyWithoutOrganizationInput>;
  SaleItems?: InputMaybe<SaleItemCreateNestedManyWithoutOrganizationInput>;
  sales?: InputMaybe<SaleCreateNestedManyWithoutOrganizationInput>;
  salesforce_domain?: InputMaybe<Scalars['String']['input']>;
  salesforce_last_sync_date?: InputMaybe<Scalars['DateTime']['input']>;
  SalesForceColumn?: InputMaybe<SalesForceColumnCreateNestedManyWithoutOrganizationInput>;
  salesForceData?: InputMaybe<SalesForceDataCreateNestedOneWithoutOrganizationInput>;
  ScheduleItems?: InputMaybe<ScheduleItemCreateNestedManyWithoutOrganizationInput>;
  self_sourcing_rule?: InputMaybe<Scalars['Int']['input']>;
  send_error_log_email?: InputMaybe<Scalars['Boolean']['input']>;
  send_lead_routing_email?: InputMaybe<Scalars['Boolean']['input']>;
  sendgrid_email_domain?: InputMaybe<Scalars['String']['input']>;
  sendgrid_email_domain_id?: InputMaybe<Scalars['Int']['input']>;
  sequence?: InputMaybe<SequenceCreateNestedManyWithoutOrganzationInput>;
  SequenceCriteriaConditions?: InputMaybe<SequenceCriteriaConditionCreateNestedManyWithoutOrganizationInput>;
  SequenceDashboardViews?: InputMaybe<SequenceDashboardViewCreateNestedManyWithoutOrganizationInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutOrganizationInput>;
  SequenceSteps?: InputMaybe<SequenceStepCreateNestedManyWithoutOrganizationInput>;
  set_goal?: InputMaybe<Scalars['Float']['input']>;
  short_resting?: InputMaybe<Scalars['Int']['input']>;
  show_calendar_options?: InputMaybe<Scalars['Boolean']['input']>;
  show_contract_duration_sales?: InputMaybe<Scalars['Boolean']['input']>;
  show_lead_source_to_rep?: InputMaybe<Scalars['Boolean']['input']>;
  show_nocontact_button?: InputMaybe<Scalars['Boolean']['input']>;
  show_payment_terms_sales?: InputMaybe<Scalars['Boolean']['input']>;
  show_rep_all_leads?: InputMaybe<Scalars['Boolean']['input']>;
  show_rep_my_leads?: InputMaybe<Scalars['Boolean']['input']>;
  show_system_view?: InputMaybe<Scalars['Boolean']['input']>;
  sites?: InputMaybe<SiteCreateNestedManyWithoutOrganizationInput>;
  sms_opt_in_dialog?: InputMaybe<Scalars['String']['input']>;
  SMSItems?: InputMaybe<SmsItemCreateNestedManyWithoutOrganizationInput>;
  SnoozeTrackings?: InputMaybe<SnoozeTrackingCreateNestedManyWithoutOrganizationInput>;
  StepActionLogs?: InputMaybe<StepActionLogCreateNestedManyWithoutOrganizationInput>;
  StepActions?: InputMaybe<StepActionCreateNestedManyWithoutOrganizationInput>;
  StepConditions?: InputMaybe<StepConditionCreateNestedManyWithoutOrganizationInput>;
  teams?: InputMaybe<TeamCreateNestedManyWithoutOrganizationInput>;
  Template?: InputMaybe<TemplateCreateNestedManyWithoutOrganizationInput>;
  TemplateVersions?: InputMaybe<TemplateVersionCreateNestedManyWithoutOrganizationInput>;
  timekit_id?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  transfer_timeout?: InputMaybe<Scalars['Int']['input']>;
  TransferAttempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutOrganizationInput>;
  twilio_numbers?: InputMaybe<TwilioNumberCreateNestedManyWithoutOrganizationInput>;
  TwilioFeedbacks?: InputMaybe<TwilioFeedbackCreateNestedManyWithoutOrganizationInput>;
  TwilioInfo?: InputMaybe<TwilioInfoCreateNestedOneWithoutOrganzationInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  use_revenue_planning?: InputMaybe<Scalars['Boolean']['input']>;
  UserCustomFieldFilters?: InputMaybe<UserCustomFieldFilterCreateNestedManyWithoutOrganizationInput>;
  users?: InputMaybe<UserCreateNestedManyWithoutOrganizationInput>;
  win_rep_day_goal?: InputMaybe<Scalars['Float']['input']>;
};

export type OrganizationCreateWithoutSequenceEntryExitLeadInput = {
  ActivityMonitorMultiplier?: InputMaybe<ActivityMonitorMultiplierCreateNestedManyWithoutOrganizationInput>;
  algoFactor?: InputMaybe<AlgoFactorCreateNestedManyWithoutOrganizationInput>;
  allow_calendar_invite?: InputMaybe<Scalars['Boolean']['input']>;
  allow_remove_step_action?: InputMaybe<Scalars['Boolean']['input']>;
  allow_snooze_step_action?: InputMaybe<Scalars['Boolean']['input']>;
  always_record?: InputMaybe<Scalars['Boolean']['input']>;
  arv_initial_held_demos_generated_by_sdr_weight?: InputMaybe<Scalars['Float']['input']>;
  AssociationHistories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutOrganizationInput>;
  auto_call_interval?: InputMaybe<Scalars['Int']['input']>;
  auto_dial?: InputMaybe<Scalars['Boolean']['input']>;
  automated_campaigns?: InputMaybe<AutomatedCampaignCreateNestedManyWithoutOrganizationInput>;
  automatic_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  AutoTransferSelections?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutOrganizationInput>;
  Availablities?: InputMaybe<AvailabilityCreateNestedManyWithoutOrganizationInput>;
  AverageOrderValue?: InputMaybe<AverageOrderValueCreateNestedManyWithoutOrganizationInput>;
  best_action_ever_multiplier?: InputMaybe<Scalars['Float']['input']>;
  best_metrics?: InputMaybe<BestMetricRecordCreateNestedManyWithoutOrganizationInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptCreateNestedManyWithoutOrganizationInput>;
  callMeNows?: InputMaybe<CallMeNowCreateNestedManyWithoutOrganizationInput>;
  CallMultiplier?: InputMaybe<CallMultiplierCreateNestedManyWithoutOrganizationInput>;
  CallSharings?: InputMaybe<CallSharingCreateNestedManyWithoutOrganizationInput>;
  can_edit_sdr_transfer_rules?: InputMaybe<Scalars['Boolean']['input']>;
  claim_rule?: InputMaybe<Scalars['Int']['input']>;
  concession?: InputMaybe<ConcessionCreateNestedManyWithoutOrganizationInput>;
  conference_region?: InputMaybe<ConferenceRegion>;
  Conferences?: InputMaybe<ConferenceCreateNestedManyWithoutOrganizationInput>;
  ContactRequests?: InputMaybe<ContactRequestCreateNestedManyWithoutOrganizationInput>;
  Contacts?: InputMaybe<ContactCreateNestedManyWithoutOrganizationInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  custom_fields?: InputMaybe<CustomFieldCreateNestedManyWithoutOrganizationInput>;
  CustomObject?: InputMaybe<CustomObjectCreateNestedManyWithoutOrganizationInput>;
  CustomObjectField?: InputMaybe<CustomObjectFieldCreateNestedManyWithoutOrganizationInput>;
  CustomObjectFieldForeignKey?: InputMaybe<CustomObjectFieldForeignKeyCreateNestedManyWithoutOrganizationInput>;
  CustomObjectRecord?: InputMaybe<CustomObjectRecordCreateNestedManyWithoutOrganizationInput>;
  CustomObjectRow?: InputMaybe<CustomObjectRowCreateNestedManyWithoutOrganizationInput>;
  CustomQueueItems?: InputMaybe<CustomQueueItemCreateNestedManyWithoutOrganizationInput>;
  DashboardColumnOptions?: InputMaybe<DashboardColumnOptionCreateNestedManyWithoutOrganizationInput>;
  DashboardLeadFilters?: InputMaybe<DashboardLeadFilterCreateNestedManyWithoutOrganizationInput>;
  DashboardRepFilters?: InputMaybe<DashboardRepFilterCreateNestedManyWithoutOrganizationInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewCreateNestedManyWithoutOrganizationInput>;
  day_to_close?: InputMaybe<Scalars['Int']['input']>;
  default_demo_time_duration?: InputMaybe<Scalars['Int']['input']>;
  demo_held_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_not_held_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_not_held_call_back_short_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_prep_time_minutes?: InputMaybe<Scalars['Int']['input']>;
  dev_account?: InputMaybe<Scalars['Boolean']['input']>;
  dial_rule?: InputMaybe<Scalars['Int']['input']>;
  DispositionMultiplier?: InputMaybe<DispositionMultiplierCreateNestedManyWithoutOrganizationInput>;
  dispositions?: InputMaybe<DispositionCreateNestedManyWithoutOrganizationInput>;
  double_booking?: InputMaybe<Scalars['Boolean']['input']>;
  dynamic_date_operators?: InputMaybe<Scalars['Boolean']['input']>;
  edge_servers?: InputMaybe<OrganizationCreateedge_ServersInput>;
  email_conflict_notification_email_list?: InputMaybe<OrganizationCreateemail_Conflict_Notification_Email_ListInput>;
  EmailItems?: InputMaybe<EmailItemCreateNestedManyWithoutOrganizationInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordCreateNestedManyWithoutOrganizationInput>;
  EmailThreads?: InputMaybe<EmailThreadCreateNestedManyWithoutOrganizationInput>;
  enable_modified_metrics?: InputMaybe<Scalars['Boolean']['input']>;
  enable_pay_period_view?: InputMaybe<Scalars['Boolean']['input']>;
  enable_time_constraint?: InputMaybe<Scalars['Boolean']['input']>;
  error_log_email_address?: InputMaybe<OrganizationCreateerror_Log_Email_AddressInput>;
  estimated_average_num_of_weekly_overbooked_demo_slots?: InputMaybe<Scalars['Int']['input']>;
  FolderPermissions?: InputMaybe<FolderPermissionCreateNestedManyWithoutOrganizationInput>;
  Folders?: InputMaybe<FolderCreateNestedManyWithoutOrganizationInput>;
  FutureBookingSetting?: InputMaybe<FutureBookingSettingCreateNestedOneWithoutOrganizationInput>;
  GlobalTransferRule?: InputMaybe<GlobalTransferRuleCreateNestedManyWithoutOrganizationInput>;
  held_demos_per_cc_connect?: InputMaybe<Scalars['Float']['input']>;
  helpful_links?: InputMaybe<OrganizationCreatehelpful_LinksInput>;
  HingePointMultiplier?: InputMaybe<HingePointMultiplierCreateNestedManyWithoutOrganizationInput>;
  hold_goal?: InputMaybe<Scalars['Float']['input']>;
  hubspot_credential?: InputMaybe<HubSpotCredentialCreateNestedOneWithoutOrganizationInput>;
  hubspot_last_sync_date?: InputMaybe<Scalars['DateTime']['input']>;
  hubspot_white_list?: InputMaybe<HubSpotWhitelistCreateNestedManyWithoutOrganizationInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  inbound_close_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_dm_connect_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_lt_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_revenue_value_of_sale?: InputMaybe<Scalars['Float']['input']>;
  inbound_scheduled_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_set_rate?: InputMaybe<Scalars['Float']['input']>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventCreateNestedManyWithoutOrganizationInput>;
  InboundConciergeWidget?: InputMaybe<InboundConciergeWidgetCreateNestedManyWithoutOrganizationInput>;
  industry_options?: InputMaybe<IndustryOptionCreateNestedManyWithoutOrganizationInput>;
  initial_sdr_sets_reps_day?: InputMaybe<Scalars['Float']['input']>;
  integration_error_logs?: InputMaybe<IntegrationErrorLogCreateNestedManyWithoutOrganizationInput>;
  integration_mappings?: InputMaybe<IntegrationMappingCreateNestedManyWithoutOrganizationInput>;
  IntegrationMappingCustomObject?: InputMaybe<IntegrationMappingCustomObjectCreateNestedManyWithoutOrganizationInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogCreateNestedManyWithoutOrganizationInput>;
  IntegrationReadOnlyObject?: InputMaybe<IntegrationReadOnlyObjectCreateNestedManyWithoutOrganizationInput>;
  international?: InputMaybe<Scalars['Boolean']['input']>;
  is_email_domain_verified?: InputMaybe<Scalars['Boolean']['input']>;
  is_salesforce_sandbox?: InputMaybe<Scalars['Boolean']['input']>;
  last_lead_value_compute_time?: InputMaybe<Scalars['DateTime']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutOrganizationInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryCreateNestedManyWithoutOrganizationInput>;
  lead_intent?: InputMaybe<LeadIntentCreateNestedManyWithoutOrganizationInput>;
  lead_release_same_rep_delay_rule?: InputMaybe<Scalars['Int']['input']>;
  lead_routing_email_list?: InputMaybe<OrganizationCreatelead_Routing_Email_ListInput>;
  lead_routing_rules?: InputMaybe<RuleCreateNestedManyWithoutOrganizationInput>;
  LeadCustomObjectRowAssociation?: InputMaybe<LeadCustomObjectRowAssociationCreateNestedManyWithoutOrganizationInput>;
  LeadFieldMultiplier?: InputMaybe<LeadFieldMultiplierCreateNestedManyWithoutOrganizationInput>;
  LeadFieldMultiplierCustom?: InputMaybe<LeadFieldMultiplierCustomCreateNestedManyWithoutOrganizationInput>;
  leads?: InputMaybe<LeadCreateNestedManyWithoutOrganizationInput>;
  leadsource_options?: InputMaybe<LeadSourceOptionCreateNestedManyWithoutOrganizationInput>;
  LeadValue?: InputMaybe<LeadValueCreateNestedManyWithoutOrganizationInput>;
  LifeTimeValueMultiplier?: InputMaybe<LifeTimeValueMultiplierCreateNestedManyWithoutOrganizationInput>;
  LiveTransferSetting?: InputMaybe<LiveTransferSettingCreateNestedOneWithoutOrganizationInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusCreateNestedManyWithoutOrganizationInput>;
  ltv_multiplier_custom_fields?: InputMaybe<LifeTimeValueMultiplierCustomCreateNestedManyWithoutOrganizationInput>;
  made_contact_rule?: InputMaybe<Scalars['Int']['input']>;
  make_sale_address_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_business_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_city_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_country_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_first_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_industry_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_last_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_lead_source_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_primary_email_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_primary_phone_number_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_state_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_sub_industry_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_zip_required?: InputMaybe<Scalars['Boolean']['input']>;
  maxium_day_lag_set_to_schedule?: InputMaybe<Scalars['Int']['input']>;
  mrr?: InputMaybe<Scalars['Int']['input']>;
  mrr_label?: InputMaybe<Scalars['String']['input']>;
  mrr_required_on_sale?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  NoteItems?: InputMaybe<NoteItemCreateNestedManyWithoutOrganizationInput>;
  Notifications?: InputMaybe<NotificationCreateNestedManyWithoutOrganizationInput>;
  onboardComplete?: InputMaybe<Scalars['Boolean']['input']>;
  Opportunity?: InputMaybe<SfOpportunityCreateNestedManyWithoutOrganizationInput>;
  organization_holidays?: InputMaybe<OrganizationHolidayCreateNestedManyWithoutOrganizationInput>;
  organization_hours?: InputMaybe<OrganizationHourCreateNestedManyWithoutOrganizationInput>;
  OrganizationPaymentSetting?: InputMaybe<OrganizationPaymentSettingsCreateNestedOneWithoutOrganizationInput>;
  OrganizationPaymentSettings?: InputMaybe<OrganizationPaymentSettingsCreateNestedOneWithoutOrganizationsInput>;
  orgCalendarInviteMetadata?: InputMaybe<OrgCalendarInviteMetadataCreateNestedManyWithoutOrganizationInput>;
  OrgDefaultPipelinePerecentValue?: InputMaybe<OrgDefaultPipelinePerecentValueCreateNestedOneWithoutOrganizationInput>;
  OrgHubSpotSync?: InputMaybe<OrgHubSpotSyncCreateNestedOneWithoutOrganizationInput>;
  OrgNoEventDate?: InputMaybe<OrgNoEventDateCreateNestedManyWithoutOrganizationInput>;
  OrgSalesforceSync?: InputMaybe<OrgSalesforceSyncCreateNestedOneWithoutOrganizationInput>;
  OrgTimeslotAvailability?: InputMaybe<OrgTimeslotAvailabilityCreateNestedManyWithoutOrganizationInput>;
  outbound_close_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_dm_connect_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_lt_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_revenue_value_of_sale?: InputMaybe<Scalars['Float']['input']>;
  outbound_scheduled_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_set_rate?: InputMaybe<Scalars['Float']['input']>;
  PandadocData?: InputMaybe<PandadocDataCreateNestedOneWithoutOrganizationInput>;
  PandaDocTemplate?: InputMaybe<PandaDocTemplateCreateNestedManyWithoutOrganizationInput>;
  participant_jitter_buffer_size?: InputMaybe<JitterBufferSize>;
  preferred_goal_setting?: InputMaybe<Goalsetting>;
  prepayment?: InputMaybe<Scalars['Boolean']['input']>;
  ProductChangeLog?: InputMaybe<ProductChangeLogCreateNestedManyWithoutOrganizationInput>;
  products?: InputMaybe<ProductCreateNestedManyWithoutOrganizationInput>;
  re_cold_call_hour_delay?: InputMaybe<Scalars['Float']['input']>;
  recordingStatus?: InputMaybe<RecordingStatus>;
  release_lead_resting?: InputMaybe<Scalars['Int']['input']>;
  rep_claim_limit?: InputMaybe<Scalars['Int']['input']>;
  rep_lead_allotment?: InputMaybe<Scalars['Int']['input']>;
  rep_level_call_transfer?: InputMaybe<Scalars['Boolean']['input']>;
  reps_can_bulk_unassign?: InputMaybe<Scalars['Boolean']['input']>;
  reps_can_view_all_notes?: InputMaybe<Scalars['Boolean']['input']>;
  require_sale_notes?: InputMaybe<Scalars['Boolean']['input']>;
  resting_after_skipped_CC?: InputMaybe<Scalars['Int']['input']>;
  resting_period?: InputMaybe<Scalars['Int']['input']>;
  resting_period_after_unsuccessful_cold_call?: InputMaybe<Scalars['Float']['input']>;
  resting_period_after_unsuccessful_demo?: InputMaybe<Scalars['Float']['input']>;
  revenue_plan?: InputMaybe<RevenuePlanItemCreateNestedManyWithoutOrganizationInput>;
  Routings?: InputMaybe<RoutingCreateNestedManyWithoutOrganizationInput>;
  sale_field_options?: InputMaybe<SaleFieldOptionCreateNestedManyWithoutOrganizationInput>;
  sale_notification_email_list?: InputMaybe<OrganizationCreatesale_Notification_Email_ListInput>;
  SaleConfig?: InputMaybe<SaleConfigCreateNestedOneWithoutOrganizationInput>;
  SaleConfigPage?: InputMaybe<SaleConfigPageCreateNestedManyWithoutOrganizationInput>;
  SaleConfigSection?: InputMaybe<SaleConfigSectionCreateNestedManyWithoutOrganizationInput>;
  SaleCycles?: InputMaybe<SaleCycleCreateNestedManyWithoutOrganizationInput>;
  SaleItems?: InputMaybe<SaleItemCreateNestedManyWithoutOrganizationInput>;
  sales?: InputMaybe<SaleCreateNestedManyWithoutOrganizationInput>;
  salesforce_domain?: InputMaybe<Scalars['String']['input']>;
  salesforce_last_sync_date?: InputMaybe<Scalars['DateTime']['input']>;
  SalesForceColumn?: InputMaybe<SalesForceColumnCreateNestedManyWithoutOrganizationInput>;
  salesForceData?: InputMaybe<SalesForceDataCreateNestedOneWithoutOrganizationInput>;
  ScheduleItems?: InputMaybe<ScheduleItemCreateNestedManyWithoutOrganizationInput>;
  self_sourcing_rule?: InputMaybe<Scalars['Int']['input']>;
  send_error_log_email?: InputMaybe<Scalars['Boolean']['input']>;
  send_lead_routing_email?: InputMaybe<Scalars['Boolean']['input']>;
  sendgrid_email_domain?: InputMaybe<Scalars['String']['input']>;
  sendgrid_email_domain_id?: InputMaybe<Scalars['Int']['input']>;
  sequence?: InputMaybe<SequenceCreateNestedManyWithoutOrganzationInput>;
  SequenceCriteriaConditions?: InputMaybe<SequenceCriteriaConditionCreateNestedManyWithoutOrganizationInput>;
  SequenceDashboardViews?: InputMaybe<SequenceDashboardViewCreateNestedManyWithoutOrganizationInput>;
  SequenceEntryCriterias?: InputMaybe<SequenceEntryCriteriaCreateNestedManyWithoutOrganizationInput>;
  SequenceSteps?: InputMaybe<SequenceStepCreateNestedManyWithoutOrganizationInput>;
  set_goal?: InputMaybe<Scalars['Float']['input']>;
  short_resting?: InputMaybe<Scalars['Int']['input']>;
  show_calendar_options?: InputMaybe<Scalars['Boolean']['input']>;
  show_contract_duration_sales?: InputMaybe<Scalars['Boolean']['input']>;
  show_lead_source_to_rep?: InputMaybe<Scalars['Boolean']['input']>;
  show_nocontact_button?: InputMaybe<Scalars['Boolean']['input']>;
  show_payment_terms_sales?: InputMaybe<Scalars['Boolean']['input']>;
  show_rep_all_leads?: InputMaybe<Scalars['Boolean']['input']>;
  show_rep_my_leads?: InputMaybe<Scalars['Boolean']['input']>;
  show_system_view?: InputMaybe<Scalars['Boolean']['input']>;
  sites?: InputMaybe<SiteCreateNestedManyWithoutOrganizationInput>;
  sms_opt_in_dialog?: InputMaybe<Scalars['String']['input']>;
  SMSItems?: InputMaybe<SmsItemCreateNestedManyWithoutOrganizationInput>;
  SnoozeTrackings?: InputMaybe<SnoozeTrackingCreateNestedManyWithoutOrganizationInput>;
  StepActionLogs?: InputMaybe<StepActionLogCreateNestedManyWithoutOrganizationInput>;
  StepActions?: InputMaybe<StepActionCreateNestedManyWithoutOrganizationInput>;
  StepConditions?: InputMaybe<StepConditionCreateNestedManyWithoutOrganizationInput>;
  teams?: InputMaybe<TeamCreateNestedManyWithoutOrganizationInput>;
  Template?: InputMaybe<TemplateCreateNestedManyWithoutOrganizationInput>;
  TemplateVersions?: InputMaybe<TemplateVersionCreateNestedManyWithoutOrganizationInput>;
  timekit_id?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  transfer_timeout?: InputMaybe<Scalars['Int']['input']>;
  TransferAttempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutOrganizationInput>;
  twilio_numbers?: InputMaybe<TwilioNumberCreateNestedManyWithoutOrganizationInput>;
  TwilioFeedbacks?: InputMaybe<TwilioFeedbackCreateNestedManyWithoutOrganizationInput>;
  TwilioInfo?: InputMaybe<TwilioInfoCreateNestedOneWithoutOrganzationInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  use_revenue_planning?: InputMaybe<Scalars['Boolean']['input']>;
  UserCustomFieldFilters?: InputMaybe<UserCustomFieldFilterCreateNestedManyWithoutOrganizationInput>;
  users?: InputMaybe<UserCreateNestedManyWithoutOrganizationInput>;
  win_rep_day_goal?: InputMaybe<Scalars['Float']['input']>;
};

export type OrganizationCreateWithoutSequenceInput = {
  ActivityMonitorMultiplier?: InputMaybe<ActivityMonitorMultiplierCreateNestedManyWithoutOrganizationInput>;
  algoFactor?: InputMaybe<AlgoFactorCreateNestedManyWithoutOrganizationInput>;
  allow_calendar_invite?: InputMaybe<Scalars['Boolean']['input']>;
  allow_remove_step_action?: InputMaybe<Scalars['Boolean']['input']>;
  allow_snooze_step_action?: InputMaybe<Scalars['Boolean']['input']>;
  always_record?: InputMaybe<Scalars['Boolean']['input']>;
  arv_initial_held_demos_generated_by_sdr_weight?: InputMaybe<Scalars['Float']['input']>;
  AssociationHistories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutOrganizationInput>;
  auto_call_interval?: InputMaybe<Scalars['Int']['input']>;
  auto_dial?: InputMaybe<Scalars['Boolean']['input']>;
  automated_campaigns?: InputMaybe<AutomatedCampaignCreateNestedManyWithoutOrganizationInput>;
  automatic_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  AutoTransferSelections?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutOrganizationInput>;
  Availablities?: InputMaybe<AvailabilityCreateNestedManyWithoutOrganizationInput>;
  AverageOrderValue?: InputMaybe<AverageOrderValueCreateNestedManyWithoutOrganizationInput>;
  best_action_ever_multiplier?: InputMaybe<Scalars['Float']['input']>;
  best_metrics?: InputMaybe<BestMetricRecordCreateNestedManyWithoutOrganizationInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptCreateNestedManyWithoutOrganizationInput>;
  callMeNows?: InputMaybe<CallMeNowCreateNestedManyWithoutOrganizationInput>;
  CallMultiplier?: InputMaybe<CallMultiplierCreateNestedManyWithoutOrganizationInput>;
  CallSharings?: InputMaybe<CallSharingCreateNestedManyWithoutOrganizationInput>;
  can_edit_sdr_transfer_rules?: InputMaybe<Scalars['Boolean']['input']>;
  claim_rule?: InputMaybe<Scalars['Int']['input']>;
  concession?: InputMaybe<ConcessionCreateNestedManyWithoutOrganizationInput>;
  conference_region?: InputMaybe<ConferenceRegion>;
  Conferences?: InputMaybe<ConferenceCreateNestedManyWithoutOrganizationInput>;
  ContactRequests?: InputMaybe<ContactRequestCreateNestedManyWithoutOrganizationInput>;
  Contacts?: InputMaybe<ContactCreateNestedManyWithoutOrganizationInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  custom_fields?: InputMaybe<CustomFieldCreateNestedManyWithoutOrganizationInput>;
  CustomObject?: InputMaybe<CustomObjectCreateNestedManyWithoutOrganizationInput>;
  CustomObjectField?: InputMaybe<CustomObjectFieldCreateNestedManyWithoutOrganizationInput>;
  CustomObjectFieldForeignKey?: InputMaybe<CustomObjectFieldForeignKeyCreateNestedManyWithoutOrganizationInput>;
  CustomObjectRecord?: InputMaybe<CustomObjectRecordCreateNestedManyWithoutOrganizationInput>;
  CustomObjectRow?: InputMaybe<CustomObjectRowCreateNestedManyWithoutOrganizationInput>;
  CustomQueueItems?: InputMaybe<CustomQueueItemCreateNestedManyWithoutOrganizationInput>;
  DashboardColumnOptions?: InputMaybe<DashboardColumnOptionCreateNestedManyWithoutOrganizationInput>;
  DashboardLeadFilters?: InputMaybe<DashboardLeadFilterCreateNestedManyWithoutOrganizationInput>;
  DashboardRepFilters?: InputMaybe<DashboardRepFilterCreateNestedManyWithoutOrganizationInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewCreateNestedManyWithoutOrganizationInput>;
  day_to_close?: InputMaybe<Scalars['Int']['input']>;
  default_demo_time_duration?: InputMaybe<Scalars['Int']['input']>;
  demo_held_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_not_held_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_not_held_call_back_short_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_prep_time_minutes?: InputMaybe<Scalars['Int']['input']>;
  dev_account?: InputMaybe<Scalars['Boolean']['input']>;
  dial_rule?: InputMaybe<Scalars['Int']['input']>;
  DispositionMultiplier?: InputMaybe<DispositionMultiplierCreateNestedManyWithoutOrganizationInput>;
  dispositions?: InputMaybe<DispositionCreateNestedManyWithoutOrganizationInput>;
  double_booking?: InputMaybe<Scalars['Boolean']['input']>;
  dynamic_date_operators?: InputMaybe<Scalars['Boolean']['input']>;
  edge_servers?: InputMaybe<OrganizationCreateedge_ServersInput>;
  email_conflict_notification_email_list?: InputMaybe<OrganizationCreateemail_Conflict_Notification_Email_ListInput>;
  EmailItems?: InputMaybe<EmailItemCreateNestedManyWithoutOrganizationInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordCreateNestedManyWithoutOrganizationInput>;
  EmailThreads?: InputMaybe<EmailThreadCreateNestedManyWithoutOrganizationInput>;
  enable_modified_metrics?: InputMaybe<Scalars['Boolean']['input']>;
  enable_pay_period_view?: InputMaybe<Scalars['Boolean']['input']>;
  enable_time_constraint?: InputMaybe<Scalars['Boolean']['input']>;
  error_log_email_address?: InputMaybe<OrganizationCreateerror_Log_Email_AddressInput>;
  estimated_average_num_of_weekly_overbooked_demo_slots?: InputMaybe<Scalars['Int']['input']>;
  FolderPermissions?: InputMaybe<FolderPermissionCreateNestedManyWithoutOrganizationInput>;
  Folders?: InputMaybe<FolderCreateNestedManyWithoutOrganizationInput>;
  FutureBookingSetting?: InputMaybe<FutureBookingSettingCreateNestedOneWithoutOrganizationInput>;
  GlobalTransferRule?: InputMaybe<GlobalTransferRuleCreateNestedManyWithoutOrganizationInput>;
  held_demos_per_cc_connect?: InputMaybe<Scalars['Float']['input']>;
  helpful_links?: InputMaybe<OrganizationCreatehelpful_LinksInput>;
  HingePointMultiplier?: InputMaybe<HingePointMultiplierCreateNestedManyWithoutOrganizationInput>;
  hold_goal?: InputMaybe<Scalars['Float']['input']>;
  hubspot_credential?: InputMaybe<HubSpotCredentialCreateNestedOneWithoutOrganizationInput>;
  hubspot_last_sync_date?: InputMaybe<Scalars['DateTime']['input']>;
  hubspot_white_list?: InputMaybe<HubSpotWhitelistCreateNestedManyWithoutOrganizationInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  inbound_close_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_dm_connect_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_lt_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_revenue_value_of_sale?: InputMaybe<Scalars['Float']['input']>;
  inbound_scheduled_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_set_rate?: InputMaybe<Scalars['Float']['input']>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventCreateNestedManyWithoutOrganizationInput>;
  InboundConciergeWidget?: InputMaybe<InboundConciergeWidgetCreateNestedManyWithoutOrganizationInput>;
  industry_options?: InputMaybe<IndustryOptionCreateNestedManyWithoutOrganizationInput>;
  initial_sdr_sets_reps_day?: InputMaybe<Scalars['Float']['input']>;
  integration_error_logs?: InputMaybe<IntegrationErrorLogCreateNestedManyWithoutOrganizationInput>;
  integration_mappings?: InputMaybe<IntegrationMappingCreateNestedManyWithoutOrganizationInput>;
  IntegrationMappingCustomObject?: InputMaybe<IntegrationMappingCustomObjectCreateNestedManyWithoutOrganizationInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogCreateNestedManyWithoutOrganizationInput>;
  IntegrationReadOnlyObject?: InputMaybe<IntegrationReadOnlyObjectCreateNestedManyWithoutOrganizationInput>;
  international?: InputMaybe<Scalars['Boolean']['input']>;
  is_email_domain_verified?: InputMaybe<Scalars['Boolean']['input']>;
  is_salesforce_sandbox?: InputMaybe<Scalars['Boolean']['input']>;
  last_lead_value_compute_time?: InputMaybe<Scalars['DateTime']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutOrganizationInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryCreateNestedManyWithoutOrganizationInput>;
  lead_intent?: InputMaybe<LeadIntentCreateNestedManyWithoutOrganizationInput>;
  lead_release_same_rep_delay_rule?: InputMaybe<Scalars['Int']['input']>;
  lead_routing_email_list?: InputMaybe<OrganizationCreatelead_Routing_Email_ListInput>;
  lead_routing_rules?: InputMaybe<RuleCreateNestedManyWithoutOrganizationInput>;
  LeadCustomObjectRowAssociation?: InputMaybe<LeadCustomObjectRowAssociationCreateNestedManyWithoutOrganizationInput>;
  LeadFieldMultiplier?: InputMaybe<LeadFieldMultiplierCreateNestedManyWithoutOrganizationInput>;
  LeadFieldMultiplierCustom?: InputMaybe<LeadFieldMultiplierCustomCreateNestedManyWithoutOrganizationInput>;
  leads?: InputMaybe<LeadCreateNestedManyWithoutOrganizationInput>;
  leadsource_options?: InputMaybe<LeadSourceOptionCreateNestedManyWithoutOrganizationInput>;
  LeadValue?: InputMaybe<LeadValueCreateNestedManyWithoutOrganizationInput>;
  LifeTimeValueMultiplier?: InputMaybe<LifeTimeValueMultiplierCreateNestedManyWithoutOrganizationInput>;
  LiveTransferSetting?: InputMaybe<LiveTransferSettingCreateNestedOneWithoutOrganizationInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusCreateNestedManyWithoutOrganizationInput>;
  ltv_multiplier_custom_fields?: InputMaybe<LifeTimeValueMultiplierCustomCreateNestedManyWithoutOrganizationInput>;
  made_contact_rule?: InputMaybe<Scalars['Int']['input']>;
  make_sale_address_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_business_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_city_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_country_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_first_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_industry_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_last_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_lead_source_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_primary_email_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_primary_phone_number_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_state_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_sub_industry_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_zip_required?: InputMaybe<Scalars['Boolean']['input']>;
  maxium_day_lag_set_to_schedule?: InputMaybe<Scalars['Int']['input']>;
  mrr?: InputMaybe<Scalars['Int']['input']>;
  mrr_label?: InputMaybe<Scalars['String']['input']>;
  mrr_required_on_sale?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  NoteItems?: InputMaybe<NoteItemCreateNestedManyWithoutOrganizationInput>;
  Notifications?: InputMaybe<NotificationCreateNestedManyWithoutOrganizationInput>;
  onboardComplete?: InputMaybe<Scalars['Boolean']['input']>;
  Opportunity?: InputMaybe<SfOpportunityCreateNestedManyWithoutOrganizationInput>;
  organization_holidays?: InputMaybe<OrganizationHolidayCreateNestedManyWithoutOrganizationInput>;
  organization_hours?: InputMaybe<OrganizationHourCreateNestedManyWithoutOrganizationInput>;
  OrganizationPaymentSetting?: InputMaybe<OrganizationPaymentSettingsCreateNestedOneWithoutOrganizationInput>;
  OrganizationPaymentSettings?: InputMaybe<OrganizationPaymentSettingsCreateNestedOneWithoutOrganizationsInput>;
  orgCalendarInviteMetadata?: InputMaybe<OrgCalendarInviteMetadataCreateNestedManyWithoutOrganizationInput>;
  OrgDefaultPipelinePerecentValue?: InputMaybe<OrgDefaultPipelinePerecentValueCreateNestedOneWithoutOrganizationInput>;
  OrgHubSpotSync?: InputMaybe<OrgHubSpotSyncCreateNestedOneWithoutOrganizationInput>;
  OrgNoEventDate?: InputMaybe<OrgNoEventDateCreateNestedManyWithoutOrganizationInput>;
  OrgSalesforceSync?: InputMaybe<OrgSalesforceSyncCreateNestedOneWithoutOrganizationInput>;
  OrgTimeslotAvailability?: InputMaybe<OrgTimeslotAvailabilityCreateNestedManyWithoutOrganizationInput>;
  outbound_close_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_dm_connect_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_lt_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_revenue_value_of_sale?: InputMaybe<Scalars['Float']['input']>;
  outbound_scheduled_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_set_rate?: InputMaybe<Scalars['Float']['input']>;
  PandadocData?: InputMaybe<PandadocDataCreateNestedOneWithoutOrganizationInput>;
  PandaDocTemplate?: InputMaybe<PandaDocTemplateCreateNestedManyWithoutOrganizationInput>;
  participant_jitter_buffer_size?: InputMaybe<JitterBufferSize>;
  preferred_goal_setting?: InputMaybe<Goalsetting>;
  prepayment?: InputMaybe<Scalars['Boolean']['input']>;
  ProductChangeLog?: InputMaybe<ProductChangeLogCreateNestedManyWithoutOrganizationInput>;
  products?: InputMaybe<ProductCreateNestedManyWithoutOrganizationInput>;
  re_cold_call_hour_delay?: InputMaybe<Scalars['Float']['input']>;
  recordingStatus?: InputMaybe<RecordingStatus>;
  release_lead_resting?: InputMaybe<Scalars['Int']['input']>;
  rep_claim_limit?: InputMaybe<Scalars['Int']['input']>;
  rep_lead_allotment?: InputMaybe<Scalars['Int']['input']>;
  rep_level_call_transfer?: InputMaybe<Scalars['Boolean']['input']>;
  reps_can_bulk_unassign?: InputMaybe<Scalars['Boolean']['input']>;
  reps_can_view_all_notes?: InputMaybe<Scalars['Boolean']['input']>;
  require_sale_notes?: InputMaybe<Scalars['Boolean']['input']>;
  resting_after_skipped_CC?: InputMaybe<Scalars['Int']['input']>;
  resting_period?: InputMaybe<Scalars['Int']['input']>;
  resting_period_after_unsuccessful_cold_call?: InputMaybe<Scalars['Float']['input']>;
  resting_period_after_unsuccessful_demo?: InputMaybe<Scalars['Float']['input']>;
  revenue_plan?: InputMaybe<RevenuePlanItemCreateNestedManyWithoutOrganizationInput>;
  Routings?: InputMaybe<RoutingCreateNestedManyWithoutOrganizationInput>;
  sale_field_options?: InputMaybe<SaleFieldOptionCreateNestedManyWithoutOrganizationInput>;
  sale_notification_email_list?: InputMaybe<OrganizationCreatesale_Notification_Email_ListInput>;
  SaleConfig?: InputMaybe<SaleConfigCreateNestedOneWithoutOrganizationInput>;
  SaleConfigPage?: InputMaybe<SaleConfigPageCreateNestedManyWithoutOrganizationInput>;
  SaleConfigSection?: InputMaybe<SaleConfigSectionCreateNestedManyWithoutOrganizationInput>;
  SaleCycles?: InputMaybe<SaleCycleCreateNestedManyWithoutOrganizationInput>;
  SaleItems?: InputMaybe<SaleItemCreateNestedManyWithoutOrganizationInput>;
  sales?: InputMaybe<SaleCreateNestedManyWithoutOrganizationInput>;
  salesforce_domain?: InputMaybe<Scalars['String']['input']>;
  salesforce_last_sync_date?: InputMaybe<Scalars['DateTime']['input']>;
  SalesForceColumn?: InputMaybe<SalesForceColumnCreateNestedManyWithoutOrganizationInput>;
  salesForceData?: InputMaybe<SalesForceDataCreateNestedOneWithoutOrganizationInput>;
  ScheduleItems?: InputMaybe<ScheduleItemCreateNestedManyWithoutOrganizationInput>;
  self_sourcing_rule?: InputMaybe<Scalars['Int']['input']>;
  send_error_log_email?: InputMaybe<Scalars['Boolean']['input']>;
  send_lead_routing_email?: InputMaybe<Scalars['Boolean']['input']>;
  sendgrid_email_domain?: InputMaybe<Scalars['String']['input']>;
  sendgrid_email_domain_id?: InputMaybe<Scalars['Int']['input']>;
  SequenceCriteriaConditions?: InputMaybe<SequenceCriteriaConditionCreateNestedManyWithoutOrganizationInput>;
  SequenceDashboardViews?: InputMaybe<SequenceDashboardViewCreateNestedManyWithoutOrganizationInput>;
  SequenceEntryCriterias?: InputMaybe<SequenceEntryCriteriaCreateNestedManyWithoutOrganizationInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutOrganizationInput>;
  SequenceSteps?: InputMaybe<SequenceStepCreateNestedManyWithoutOrganizationInput>;
  set_goal?: InputMaybe<Scalars['Float']['input']>;
  short_resting?: InputMaybe<Scalars['Int']['input']>;
  show_calendar_options?: InputMaybe<Scalars['Boolean']['input']>;
  show_contract_duration_sales?: InputMaybe<Scalars['Boolean']['input']>;
  show_lead_source_to_rep?: InputMaybe<Scalars['Boolean']['input']>;
  show_nocontact_button?: InputMaybe<Scalars['Boolean']['input']>;
  show_payment_terms_sales?: InputMaybe<Scalars['Boolean']['input']>;
  show_rep_all_leads?: InputMaybe<Scalars['Boolean']['input']>;
  show_rep_my_leads?: InputMaybe<Scalars['Boolean']['input']>;
  show_system_view?: InputMaybe<Scalars['Boolean']['input']>;
  sites?: InputMaybe<SiteCreateNestedManyWithoutOrganizationInput>;
  sms_opt_in_dialog?: InputMaybe<Scalars['String']['input']>;
  SMSItems?: InputMaybe<SmsItemCreateNestedManyWithoutOrganizationInput>;
  SnoozeTrackings?: InputMaybe<SnoozeTrackingCreateNestedManyWithoutOrganizationInput>;
  StepActionLogs?: InputMaybe<StepActionLogCreateNestedManyWithoutOrganizationInput>;
  StepActions?: InputMaybe<StepActionCreateNestedManyWithoutOrganizationInput>;
  StepConditions?: InputMaybe<StepConditionCreateNestedManyWithoutOrganizationInput>;
  teams?: InputMaybe<TeamCreateNestedManyWithoutOrganizationInput>;
  Template?: InputMaybe<TemplateCreateNestedManyWithoutOrganizationInput>;
  TemplateVersions?: InputMaybe<TemplateVersionCreateNestedManyWithoutOrganizationInput>;
  timekit_id?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  transfer_timeout?: InputMaybe<Scalars['Int']['input']>;
  TransferAttempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutOrganizationInput>;
  twilio_numbers?: InputMaybe<TwilioNumberCreateNestedManyWithoutOrganizationInput>;
  TwilioFeedbacks?: InputMaybe<TwilioFeedbackCreateNestedManyWithoutOrganizationInput>;
  TwilioInfo?: InputMaybe<TwilioInfoCreateNestedOneWithoutOrganzationInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  use_revenue_planning?: InputMaybe<Scalars['Boolean']['input']>;
  UserCustomFieldFilters?: InputMaybe<UserCustomFieldFilterCreateNestedManyWithoutOrganizationInput>;
  users?: InputMaybe<UserCreateNestedManyWithoutOrganizationInput>;
  win_rep_day_goal?: InputMaybe<Scalars['Float']['input']>;
};

export type OrganizationCreateWithoutSequenceStepsInput = {
  ActivityMonitorMultiplier?: InputMaybe<ActivityMonitorMultiplierCreateNestedManyWithoutOrganizationInput>;
  algoFactor?: InputMaybe<AlgoFactorCreateNestedManyWithoutOrganizationInput>;
  allow_calendar_invite?: InputMaybe<Scalars['Boolean']['input']>;
  allow_remove_step_action?: InputMaybe<Scalars['Boolean']['input']>;
  allow_snooze_step_action?: InputMaybe<Scalars['Boolean']['input']>;
  always_record?: InputMaybe<Scalars['Boolean']['input']>;
  arv_initial_held_demos_generated_by_sdr_weight?: InputMaybe<Scalars['Float']['input']>;
  AssociationHistories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutOrganizationInput>;
  auto_call_interval?: InputMaybe<Scalars['Int']['input']>;
  auto_dial?: InputMaybe<Scalars['Boolean']['input']>;
  automated_campaigns?: InputMaybe<AutomatedCampaignCreateNestedManyWithoutOrganizationInput>;
  automatic_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  AutoTransferSelections?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutOrganizationInput>;
  Availablities?: InputMaybe<AvailabilityCreateNestedManyWithoutOrganizationInput>;
  AverageOrderValue?: InputMaybe<AverageOrderValueCreateNestedManyWithoutOrganizationInput>;
  best_action_ever_multiplier?: InputMaybe<Scalars['Float']['input']>;
  best_metrics?: InputMaybe<BestMetricRecordCreateNestedManyWithoutOrganizationInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptCreateNestedManyWithoutOrganizationInput>;
  callMeNows?: InputMaybe<CallMeNowCreateNestedManyWithoutOrganizationInput>;
  CallMultiplier?: InputMaybe<CallMultiplierCreateNestedManyWithoutOrganizationInput>;
  CallSharings?: InputMaybe<CallSharingCreateNestedManyWithoutOrganizationInput>;
  can_edit_sdr_transfer_rules?: InputMaybe<Scalars['Boolean']['input']>;
  claim_rule?: InputMaybe<Scalars['Int']['input']>;
  concession?: InputMaybe<ConcessionCreateNestedManyWithoutOrganizationInput>;
  conference_region?: InputMaybe<ConferenceRegion>;
  Conferences?: InputMaybe<ConferenceCreateNestedManyWithoutOrganizationInput>;
  ContactRequests?: InputMaybe<ContactRequestCreateNestedManyWithoutOrganizationInput>;
  Contacts?: InputMaybe<ContactCreateNestedManyWithoutOrganizationInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  custom_fields?: InputMaybe<CustomFieldCreateNestedManyWithoutOrganizationInput>;
  CustomObject?: InputMaybe<CustomObjectCreateNestedManyWithoutOrganizationInput>;
  CustomObjectField?: InputMaybe<CustomObjectFieldCreateNestedManyWithoutOrganizationInput>;
  CustomObjectFieldForeignKey?: InputMaybe<CustomObjectFieldForeignKeyCreateNestedManyWithoutOrganizationInput>;
  CustomObjectRecord?: InputMaybe<CustomObjectRecordCreateNestedManyWithoutOrganizationInput>;
  CustomObjectRow?: InputMaybe<CustomObjectRowCreateNestedManyWithoutOrganizationInput>;
  CustomQueueItems?: InputMaybe<CustomQueueItemCreateNestedManyWithoutOrganizationInput>;
  DashboardColumnOptions?: InputMaybe<DashboardColumnOptionCreateNestedManyWithoutOrganizationInput>;
  DashboardLeadFilters?: InputMaybe<DashboardLeadFilterCreateNestedManyWithoutOrganizationInput>;
  DashboardRepFilters?: InputMaybe<DashboardRepFilterCreateNestedManyWithoutOrganizationInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewCreateNestedManyWithoutOrganizationInput>;
  day_to_close?: InputMaybe<Scalars['Int']['input']>;
  default_demo_time_duration?: InputMaybe<Scalars['Int']['input']>;
  demo_held_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_not_held_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_not_held_call_back_short_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_prep_time_minutes?: InputMaybe<Scalars['Int']['input']>;
  dev_account?: InputMaybe<Scalars['Boolean']['input']>;
  dial_rule?: InputMaybe<Scalars['Int']['input']>;
  DispositionMultiplier?: InputMaybe<DispositionMultiplierCreateNestedManyWithoutOrganizationInput>;
  dispositions?: InputMaybe<DispositionCreateNestedManyWithoutOrganizationInput>;
  double_booking?: InputMaybe<Scalars['Boolean']['input']>;
  dynamic_date_operators?: InputMaybe<Scalars['Boolean']['input']>;
  edge_servers?: InputMaybe<OrganizationCreateedge_ServersInput>;
  email_conflict_notification_email_list?: InputMaybe<OrganizationCreateemail_Conflict_Notification_Email_ListInput>;
  EmailItems?: InputMaybe<EmailItemCreateNestedManyWithoutOrganizationInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordCreateNestedManyWithoutOrganizationInput>;
  EmailThreads?: InputMaybe<EmailThreadCreateNestedManyWithoutOrganizationInput>;
  enable_modified_metrics?: InputMaybe<Scalars['Boolean']['input']>;
  enable_pay_period_view?: InputMaybe<Scalars['Boolean']['input']>;
  enable_time_constraint?: InputMaybe<Scalars['Boolean']['input']>;
  error_log_email_address?: InputMaybe<OrganizationCreateerror_Log_Email_AddressInput>;
  estimated_average_num_of_weekly_overbooked_demo_slots?: InputMaybe<Scalars['Int']['input']>;
  FolderPermissions?: InputMaybe<FolderPermissionCreateNestedManyWithoutOrganizationInput>;
  Folders?: InputMaybe<FolderCreateNestedManyWithoutOrganizationInput>;
  FutureBookingSetting?: InputMaybe<FutureBookingSettingCreateNestedOneWithoutOrganizationInput>;
  GlobalTransferRule?: InputMaybe<GlobalTransferRuleCreateNestedManyWithoutOrganizationInput>;
  held_demos_per_cc_connect?: InputMaybe<Scalars['Float']['input']>;
  helpful_links?: InputMaybe<OrganizationCreatehelpful_LinksInput>;
  HingePointMultiplier?: InputMaybe<HingePointMultiplierCreateNestedManyWithoutOrganizationInput>;
  hold_goal?: InputMaybe<Scalars['Float']['input']>;
  hubspot_credential?: InputMaybe<HubSpotCredentialCreateNestedOneWithoutOrganizationInput>;
  hubspot_last_sync_date?: InputMaybe<Scalars['DateTime']['input']>;
  hubspot_white_list?: InputMaybe<HubSpotWhitelistCreateNestedManyWithoutOrganizationInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  inbound_close_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_dm_connect_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_lt_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_revenue_value_of_sale?: InputMaybe<Scalars['Float']['input']>;
  inbound_scheduled_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_set_rate?: InputMaybe<Scalars['Float']['input']>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventCreateNestedManyWithoutOrganizationInput>;
  InboundConciergeWidget?: InputMaybe<InboundConciergeWidgetCreateNestedManyWithoutOrganizationInput>;
  industry_options?: InputMaybe<IndustryOptionCreateNestedManyWithoutOrganizationInput>;
  initial_sdr_sets_reps_day?: InputMaybe<Scalars['Float']['input']>;
  integration_error_logs?: InputMaybe<IntegrationErrorLogCreateNestedManyWithoutOrganizationInput>;
  integration_mappings?: InputMaybe<IntegrationMappingCreateNestedManyWithoutOrganizationInput>;
  IntegrationMappingCustomObject?: InputMaybe<IntegrationMappingCustomObjectCreateNestedManyWithoutOrganizationInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogCreateNestedManyWithoutOrganizationInput>;
  IntegrationReadOnlyObject?: InputMaybe<IntegrationReadOnlyObjectCreateNestedManyWithoutOrganizationInput>;
  international?: InputMaybe<Scalars['Boolean']['input']>;
  is_email_domain_verified?: InputMaybe<Scalars['Boolean']['input']>;
  is_salesforce_sandbox?: InputMaybe<Scalars['Boolean']['input']>;
  last_lead_value_compute_time?: InputMaybe<Scalars['DateTime']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutOrganizationInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryCreateNestedManyWithoutOrganizationInput>;
  lead_intent?: InputMaybe<LeadIntentCreateNestedManyWithoutOrganizationInput>;
  lead_release_same_rep_delay_rule?: InputMaybe<Scalars['Int']['input']>;
  lead_routing_email_list?: InputMaybe<OrganizationCreatelead_Routing_Email_ListInput>;
  lead_routing_rules?: InputMaybe<RuleCreateNestedManyWithoutOrganizationInput>;
  LeadCustomObjectRowAssociation?: InputMaybe<LeadCustomObjectRowAssociationCreateNestedManyWithoutOrganizationInput>;
  LeadFieldMultiplier?: InputMaybe<LeadFieldMultiplierCreateNestedManyWithoutOrganizationInput>;
  LeadFieldMultiplierCustom?: InputMaybe<LeadFieldMultiplierCustomCreateNestedManyWithoutOrganizationInput>;
  leads?: InputMaybe<LeadCreateNestedManyWithoutOrganizationInput>;
  leadsource_options?: InputMaybe<LeadSourceOptionCreateNestedManyWithoutOrganizationInput>;
  LeadValue?: InputMaybe<LeadValueCreateNestedManyWithoutOrganizationInput>;
  LifeTimeValueMultiplier?: InputMaybe<LifeTimeValueMultiplierCreateNestedManyWithoutOrganizationInput>;
  LiveTransferSetting?: InputMaybe<LiveTransferSettingCreateNestedOneWithoutOrganizationInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusCreateNestedManyWithoutOrganizationInput>;
  ltv_multiplier_custom_fields?: InputMaybe<LifeTimeValueMultiplierCustomCreateNestedManyWithoutOrganizationInput>;
  made_contact_rule?: InputMaybe<Scalars['Int']['input']>;
  make_sale_address_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_business_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_city_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_country_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_first_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_industry_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_last_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_lead_source_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_primary_email_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_primary_phone_number_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_state_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_sub_industry_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_zip_required?: InputMaybe<Scalars['Boolean']['input']>;
  maxium_day_lag_set_to_schedule?: InputMaybe<Scalars['Int']['input']>;
  mrr?: InputMaybe<Scalars['Int']['input']>;
  mrr_label?: InputMaybe<Scalars['String']['input']>;
  mrr_required_on_sale?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  NoteItems?: InputMaybe<NoteItemCreateNestedManyWithoutOrganizationInput>;
  Notifications?: InputMaybe<NotificationCreateNestedManyWithoutOrganizationInput>;
  onboardComplete?: InputMaybe<Scalars['Boolean']['input']>;
  Opportunity?: InputMaybe<SfOpportunityCreateNestedManyWithoutOrganizationInput>;
  organization_holidays?: InputMaybe<OrganizationHolidayCreateNestedManyWithoutOrganizationInput>;
  organization_hours?: InputMaybe<OrganizationHourCreateNestedManyWithoutOrganizationInput>;
  OrganizationPaymentSetting?: InputMaybe<OrganizationPaymentSettingsCreateNestedOneWithoutOrganizationInput>;
  OrganizationPaymentSettings?: InputMaybe<OrganizationPaymentSettingsCreateNestedOneWithoutOrganizationsInput>;
  orgCalendarInviteMetadata?: InputMaybe<OrgCalendarInviteMetadataCreateNestedManyWithoutOrganizationInput>;
  OrgDefaultPipelinePerecentValue?: InputMaybe<OrgDefaultPipelinePerecentValueCreateNestedOneWithoutOrganizationInput>;
  OrgHubSpotSync?: InputMaybe<OrgHubSpotSyncCreateNestedOneWithoutOrganizationInput>;
  OrgNoEventDate?: InputMaybe<OrgNoEventDateCreateNestedManyWithoutOrganizationInput>;
  OrgSalesforceSync?: InputMaybe<OrgSalesforceSyncCreateNestedOneWithoutOrganizationInput>;
  OrgTimeslotAvailability?: InputMaybe<OrgTimeslotAvailabilityCreateNestedManyWithoutOrganizationInput>;
  outbound_close_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_dm_connect_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_lt_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_revenue_value_of_sale?: InputMaybe<Scalars['Float']['input']>;
  outbound_scheduled_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_set_rate?: InputMaybe<Scalars['Float']['input']>;
  PandadocData?: InputMaybe<PandadocDataCreateNestedOneWithoutOrganizationInput>;
  PandaDocTemplate?: InputMaybe<PandaDocTemplateCreateNestedManyWithoutOrganizationInput>;
  participant_jitter_buffer_size?: InputMaybe<JitterBufferSize>;
  preferred_goal_setting?: InputMaybe<Goalsetting>;
  prepayment?: InputMaybe<Scalars['Boolean']['input']>;
  ProductChangeLog?: InputMaybe<ProductChangeLogCreateNestedManyWithoutOrganizationInput>;
  products?: InputMaybe<ProductCreateNestedManyWithoutOrganizationInput>;
  re_cold_call_hour_delay?: InputMaybe<Scalars['Float']['input']>;
  recordingStatus?: InputMaybe<RecordingStatus>;
  release_lead_resting?: InputMaybe<Scalars['Int']['input']>;
  rep_claim_limit?: InputMaybe<Scalars['Int']['input']>;
  rep_lead_allotment?: InputMaybe<Scalars['Int']['input']>;
  rep_level_call_transfer?: InputMaybe<Scalars['Boolean']['input']>;
  reps_can_bulk_unassign?: InputMaybe<Scalars['Boolean']['input']>;
  reps_can_view_all_notes?: InputMaybe<Scalars['Boolean']['input']>;
  require_sale_notes?: InputMaybe<Scalars['Boolean']['input']>;
  resting_after_skipped_CC?: InputMaybe<Scalars['Int']['input']>;
  resting_period?: InputMaybe<Scalars['Int']['input']>;
  resting_period_after_unsuccessful_cold_call?: InputMaybe<Scalars['Float']['input']>;
  resting_period_after_unsuccessful_demo?: InputMaybe<Scalars['Float']['input']>;
  revenue_plan?: InputMaybe<RevenuePlanItemCreateNestedManyWithoutOrganizationInput>;
  Routings?: InputMaybe<RoutingCreateNestedManyWithoutOrganizationInput>;
  sale_field_options?: InputMaybe<SaleFieldOptionCreateNestedManyWithoutOrganizationInput>;
  sale_notification_email_list?: InputMaybe<OrganizationCreatesale_Notification_Email_ListInput>;
  SaleConfig?: InputMaybe<SaleConfigCreateNestedOneWithoutOrganizationInput>;
  SaleConfigPage?: InputMaybe<SaleConfigPageCreateNestedManyWithoutOrganizationInput>;
  SaleConfigSection?: InputMaybe<SaleConfigSectionCreateNestedManyWithoutOrganizationInput>;
  SaleCycles?: InputMaybe<SaleCycleCreateNestedManyWithoutOrganizationInput>;
  SaleItems?: InputMaybe<SaleItemCreateNestedManyWithoutOrganizationInput>;
  sales?: InputMaybe<SaleCreateNestedManyWithoutOrganizationInput>;
  salesforce_domain?: InputMaybe<Scalars['String']['input']>;
  salesforce_last_sync_date?: InputMaybe<Scalars['DateTime']['input']>;
  SalesForceColumn?: InputMaybe<SalesForceColumnCreateNestedManyWithoutOrganizationInput>;
  salesForceData?: InputMaybe<SalesForceDataCreateNestedOneWithoutOrganizationInput>;
  ScheduleItems?: InputMaybe<ScheduleItemCreateNestedManyWithoutOrganizationInput>;
  self_sourcing_rule?: InputMaybe<Scalars['Int']['input']>;
  send_error_log_email?: InputMaybe<Scalars['Boolean']['input']>;
  send_lead_routing_email?: InputMaybe<Scalars['Boolean']['input']>;
  sendgrid_email_domain?: InputMaybe<Scalars['String']['input']>;
  sendgrid_email_domain_id?: InputMaybe<Scalars['Int']['input']>;
  sequence?: InputMaybe<SequenceCreateNestedManyWithoutOrganzationInput>;
  SequenceCriteriaConditions?: InputMaybe<SequenceCriteriaConditionCreateNestedManyWithoutOrganizationInput>;
  SequenceDashboardViews?: InputMaybe<SequenceDashboardViewCreateNestedManyWithoutOrganizationInput>;
  SequenceEntryCriterias?: InputMaybe<SequenceEntryCriteriaCreateNestedManyWithoutOrganizationInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutOrganizationInput>;
  set_goal?: InputMaybe<Scalars['Float']['input']>;
  short_resting?: InputMaybe<Scalars['Int']['input']>;
  show_calendar_options?: InputMaybe<Scalars['Boolean']['input']>;
  show_contract_duration_sales?: InputMaybe<Scalars['Boolean']['input']>;
  show_lead_source_to_rep?: InputMaybe<Scalars['Boolean']['input']>;
  show_nocontact_button?: InputMaybe<Scalars['Boolean']['input']>;
  show_payment_terms_sales?: InputMaybe<Scalars['Boolean']['input']>;
  show_rep_all_leads?: InputMaybe<Scalars['Boolean']['input']>;
  show_rep_my_leads?: InputMaybe<Scalars['Boolean']['input']>;
  show_system_view?: InputMaybe<Scalars['Boolean']['input']>;
  sites?: InputMaybe<SiteCreateNestedManyWithoutOrganizationInput>;
  sms_opt_in_dialog?: InputMaybe<Scalars['String']['input']>;
  SMSItems?: InputMaybe<SmsItemCreateNestedManyWithoutOrganizationInput>;
  SnoozeTrackings?: InputMaybe<SnoozeTrackingCreateNestedManyWithoutOrganizationInput>;
  StepActionLogs?: InputMaybe<StepActionLogCreateNestedManyWithoutOrganizationInput>;
  StepActions?: InputMaybe<StepActionCreateNestedManyWithoutOrganizationInput>;
  StepConditions?: InputMaybe<StepConditionCreateNestedManyWithoutOrganizationInput>;
  teams?: InputMaybe<TeamCreateNestedManyWithoutOrganizationInput>;
  Template?: InputMaybe<TemplateCreateNestedManyWithoutOrganizationInput>;
  TemplateVersions?: InputMaybe<TemplateVersionCreateNestedManyWithoutOrganizationInput>;
  timekit_id?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  transfer_timeout?: InputMaybe<Scalars['Int']['input']>;
  TransferAttempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutOrganizationInput>;
  twilio_numbers?: InputMaybe<TwilioNumberCreateNestedManyWithoutOrganizationInput>;
  TwilioFeedbacks?: InputMaybe<TwilioFeedbackCreateNestedManyWithoutOrganizationInput>;
  TwilioInfo?: InputMaybe<TwilioInfoCreateNestedOneWithoutOrganzationInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  use_revenue_planning?: InputMaybe<Scalars['Boolean']['input']>;
  UserCustomFieldFilters?: InputMaybe<UserCustomFieldFilterCreateNestedManyWithoutOrganizationInput>;
  users?: InputMaybe<UserCreateNestedManyWithoutOrganizationInput>;
  win_rep_day_goal?: InputMaybe<Scalars['Float']['input']>;
};

export type OrganizationCreateWithoutSitesInput = {
  ActivityMonitorMultiplier?: InputMaybe<ActivityMonitorMultiplierCreateNestedManyWithoutOrganizationInput>;
  algoFactor?: InputMaybe<AlgoFactorCreateNestedManyWithoutOrganizationInput>;
  allow_calendar_invite?: InputMaybe<Scalars['Boolean']['input']>;
  allow_remove_step_action?: InputMaybe<Scalars['Boolean']['input']>;
  allow_snooze_step_action?: InputMaybe<Scalars['Boolean']['input']>;
  always_record?: InputMaybe<Scalars['Boolean']['input']>;
  arv_initial_held_demos_generated_by_sdr_weight?: InputMaybe<Scalars['Float']['input']>;
  AssociationHistories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutOrganizationInput>;
  auto_call_interval?: InputMaybe<Scalars['Int']['input']>;
  auto_dial?: InputMaybe<Scalars['Boolean']['input']>;
  automated_campaigns?: InputMaybe<AutomatedCampaignCreateNestedManyWithoutOrganizationInput>;
  automatic_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  AutoTransferSelections?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutOrganizationInput>;
  Availablities?: InputMaybe<AvailabilityCreateNestedManyWithoutOrganizationInput>;
  AverageOrderValue?: InputMaybe<AverageOrderValueCreateNestedManyWithoutOrganizationInput>;
  best_action_ever_multiplier?: InputMaybe<Scalars['Float']['input']>;
  best_metrics?: InputMaybe<BestMetricRecordCreateNestedManyWithoutOrganizationInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptCreateNestedManyWithoutOrganizationInput>;
  callMeNows?: InputMaybe<CallMeNowCreateNestedManyWithoutOrganizationInput>;
  CallMultiplier?: InputMaybe<CallMultiplierCreateNestedManyWithoutOrganizationInput>;
  CallSharings?: InputMaybe<CallSharingCreateNestedManyWithoutOrganizationInput>;
  can_edit_sdr_transfer_rules?: InputMaybe<Scalars['Boolean']['input']>;
  claim_rule?: InputMaybe<Scalars['Int']['input']>;
  concession?: InputMaybe<ConcessionCreateNestedManyWithoutOrganizationInput>;
  conference_region?: InputMaybe<ConferenceRegion>;
  Conferences?: InputMaybe<ConferenceCreateNestedManyWithoutOrganizationInput>;
  ContactRequests?: InputMaybe<ContactRequestCreateNestedManyWithoutOrganizationInput>;
  Contacts?: InputMaybe<ContactCreateNestedManyWithoutOrganizationInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  custom_fields?: InputMaybe<CustomFieldCreateNestedManyWithoutOrganizationInput>;
  CustomObject?: InputMaybe<CustomObjectCreateNestedManyWithoutOrganizationInput>;
  CustomObjectField?: InputMaybe<CustomObjectFieldCreateNestedManyWithoutOrganizationInput>;
  CustomObjectFieldForeignKey?: InputMaybe<CustomObjectFieldForeignKeyCreateNestedManyWithoutOrganizationInput>;
  CustomObjectRecord?: InputMaybe<CustomObjectRecordCreateNestedManyWithoutOrganizationInput>;
  CustomObjectRow?: InputMaybe<CustomObjectRowCreateNestedManyWithoutOrganizationInput>;
  CustomQueueItems?: InputMaybe<CustomQueueItemCreateNestedManyWithoutOrganizationInput>;
  DashboardColumnOptions?: InputMaybe<DashboardColumnOptionCreateNestedManyWithoutOrganizationInput>;
  DashboardLeadFilters?: InputMaybe<DashboardLeadFilterCreateNestedManyWithoutOrganizationInput>;
  DashboardRepFilters?: InputMaybe<DashboardRepFilterCreateNestedManyWithoutOrganizationInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewCreateNestedManyWithoutOrganizationInput>;
  day_to_close?: InputMaybe<Scalars['Int']['input']>;
  default_demo_time_duration?: InputMaybe<Scalars['Int']['input']>;
  demo_held_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_not_held_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_not_held_call_back_short_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_prep_time_minutes?: InputMaybe<Scalars['Int']['input']>;
  dev_account?: InputMaybe<Scalars['Boolean']['input']>;
  dial_rule?: InputMaybe<Scalars['Int']['input']>;
  DispositionMultiplier?: InputMaybe<DispositionMultiplierCreateNestedManyWithoutOrganizationInput>;
  dispositions?: InputMaybe<DispositionCreateNestedManyWithoutOrganizationInput>;
  double_booking?: InputMaybe<Scalars['Boolean']['input']>;
  dynamic_date_operators?: InputMaybe<Scalars['Boolean']['input']>;
  edge_servers?: InputMaybe<OrganizationCreateedge_ServersInput>;
  email_conflict_notification_email_list?: InputMaybe<OrganizationCreateemail_Conflict_Notification_Email_ListInput>;
  EmailItems?: InputMaybe<EmailItemCreateNestedManyWithoutOrganizationInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordCreateNestedManyWithoutOrganizationInput>;
  EmailThreads?: InputMaybe<EmailThreadCreateNestedManyWithoutOrganizationInput>;
  enable_modified_metrics?: InputMaybe<Scalars['Boolean']['input']>;
  enable_pay_period_view?: InputMaybe<Scalars['Boolean']['input']>;
  enable_time_constraint?: InputMaybe<Scalars['Boolean']['input']>;
  error_log_email_address?: InputMaybe<OrganizationCreateerror_Log_Email_AddressInput>;
  estimated_average_num_of_weekly_overbooked_demo_slots?: InputMaybe<Scalars['Int']['input']>;
  FolderPermissions?: InputMaybe<FolderPermissionCreateNestedManyWithoutOrganizationInput>;
  Folders?: InputMaybe<FolderCreateNestedManyWithoutOrganizationInput>;
  FutureBookingSetting?: InputMaybe<FutureBookingSettingCreateNestedOneWithoutOrganizationInput>;
  GlobalTransferRule?: InputMaybe<GlobalTransferRuleCreateNestedManyWithoutOrganizationInput>;
  held_demos_per_cc_connect?: InputMaybe<Scalars['Float']['input']>;
  helpful_links?: InputMaybe<OrganizationCreatehelpful_LinksInput>;
  HingePointMultiplier?: InputMaybe<HingePointMultiplierCreateNestedManyWithoutOrganizationInput>;
  hold_goal?: InputMaybe<Scalars['Float']['input']>;
  hubspot_credential?: InputMaybe<HubSpotCredentialCreateNestedOneWithoutOrganizationInput>;
  hubspot_last_sync_date?: InputMaybe<Scalars['DateTime']['input']>;
  hubspot_white_list?: InputMaybe<HubSpotWhitelistCreateNestedManyWithoutOrganizationInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  inbound_close_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_dm_connect_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_lt_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_revenue_value_of_sale?: InputMaybe<Scalars['Float']['input']>;
  inbound_scheduled_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_set_rate?: InputMaybe<Scalars['Float']['input']>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventCreateNestedManyWithoutOrganizationInput>;
  InboundConciergeWidget?: InputMaybe<InboundConciergeWidgetCreateNestedManyWithoutOrganizationInput>;
  industry_options?: InputMaybe<IndustryOptionCreateNestedManyWithoutOrganizationInput>;
  initial_sdr_sets_reps_day?: InputMaybe<Scalars['Float']['input']>;
  integration_error_logs?: InputMaybe<IntegrationErrorLogCreateNestedManyWithoutOrganizationInput>;
  integration_mappings?: InputMaybe<IntegrationMappingCreateNestedManyWithoutOrganizationInput>;
  IntegrationMappingCustomObject?: InputMaybe<IntegrationMappingCustomObjectCreateNestedManyWithoutOrganizationInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogCreateNestedManyWithoutOrganizationInput>;
  IntegrationReadOnlyObject?: InputMaybe<IntegrationReadOnlyObjectCreateNestedManyWithoutOrganizationInput>;
  international?: InputMaybe<Scalars['Boolean']['input']>;
  is_email_domain_verified?: InputMaybe<Scalars['Boolean']['input']>;
  is_salesforce_sandbox?: InputMaybe<Scalars['Boolean']['input']>;
  last_lead_value_compute_time?: InputMaybe<Scalars['DateTime']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutOrganizationInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryCreateNestedManyWithoutOrganizationInput>;
  lead_intent?: InputMaybe<LeadIntentCreateNestedManyWithoutOrganizationInput>;
  lead_release_same_rep_delay_rule?: InputMaybe<Scalars['Int']['input']>;
  lead_routing_email_list?: InputMaybe<OrganizationCreatelead_Routing_Email_ListInput>;
  lead_routing_rules?: InputMaybe<RuleCreateNestedManyWithoutOrganizationInput>;
  LeadCustomObjectRowAssociation?: InputMaybe<LeadCustomObjectRowAssociationCreateNestedManyWithoutOrganizationInput>;
  LeadFieldMultiplier?: InputMaybe<LeadFieldMultiplierCreateNestedManyWithoutOrganizationInput>;
  LeadFieldMultiplierCustom?: InputMaybe<LeadFieldMultiplierCustomCreateNestedManyWithoutOrganizationInput>;
  leads?: InputMaybe<LeadCreateNestedManyWithoutOrganizationInput>;
  leadsource_options?: InputMaybe<LeadSourceOptionCreateNestedManyWithoutOrganizationInput>;
  LeadValue?: InputMaybe<LeadValueCreateNestedManyWithoutOrganizationInput>;
  LifeTimeValueMultiplier?: InputMaybe<LifeTimeValueMultiplierCreateNestedManyWithoutOrganizationInput>;
  LiveTransferSetting?: InputMaybe<LiveTransferSettingCreateNestedOneWithoutOrganizationInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusCreateNestedManyWithoutOrganizationInput>;
  ltv_multiplier_custom_fields?: InputMaybe<LifeTimeValueMultiplierCustomCreateNestedManyWithoutOrganizationInput>;
  made_contact_rule?: InputMaybe<Scalars['Int']['input']>;
  make_sale_address_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_business_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_city_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_country_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_first_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_industry_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_last_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_lead_source_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_primary_email_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_primary_phone_number_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_state_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_sub_industry_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_zip_required?: InputMaybe<Scalars['Boolean']['input']>;
  maxium_day_lag_set_to_schedule?: InputMaybe<Scalars['Int']['input']>;
  mrr?: InputMaybe<Scalars['Int']['input']>;
  mrr_label?: InputMaybe<Scalars['String']['input']>;
  mrr_required_on_sale?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  NoteItems?: InputMaybe<NoteItemCreateNestedManyWithoutOrganizationInput>;
  Notifications?: InputMaybe<NotificationCreateNestedManyWithoutOrganizationInput>;
  onboardComplete?: InputMaybe<Scalars['Boolean']['input']>;
  Opportunity?: InputMaybe<SfOpportunityCreateNestedManyWithoutOrganizationInput>;
  organization_holidays?: InputMaybe<OrganizationHolidayCreateNestedManyWithoutOrganizationInput>;
  organization_hours?: InputMaybe<OrganizationHourCreateNestedManyWithoutOrganizationInput>;
  OrganizationPaymentSetting?: InputMaybe<OrganizationPaymentSettingsCreateNestedOneWithoutOrganizationInput>;
  OrganizationPaymentSettings?: InputMaybe<OrganizationPaymentSettingsCreateNestedOneWithoutOrganizationsInput>;
  orgCalendarInviteMetadata?: InputMaybe<OrgCalendarInviteMetadataCreateNestedManyWithoutOrganizationInput>;
  OrgDefaultPipelinePerecentValue?: InputMaybe<OrgDefaultPipelinePerecentValueCreateNestedOneWithoutOrganizationInput>;
  OrgHubSpotSync?: InputMaybe<OrgHubSpotSyncCreateNestedOneWithoutOrganizationInput>;
  OrgNoEventDate?: InputMaybe<OrgNoEventDateCreateNestedManyWithoutOrganizationInput>;
  OrgSalesforceSync?: InputMaybe<OrgSalesforceSyncCreateNestedOneWithoutOrganizationInput>;
  OrgTimeslotAvailability?: InputMaybe<OrgTimeslotAvailabilityCreateNestedManyWithoutOrganizationInput>;
  outbound_close_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_dm_connect_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_lt_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_revenue_value_of_sale?: InputMaybe<Scalars['Float']['input']>;
  outbound_scheduled_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_set_rate?: InputMaybe<Scalars['Float']['input']>;
  PandadocData?: InputMaybe<PandadocDataCreateNestedOneWithoutOrganizationInput>;
  PandaDocTemplate?: InputMaybe<PandaDocTemplateCreateNestedManyWithoutOrganizationInput>;
  participant_jitter_buffer_size?: InputMaybe<JitterBufferSize>;
  preferred_goal_setting?: InputMaybe<Goalsetting>;
  prepayment?: InputMaybe<Scalars['Boolean']['input']>;
  ProductChangeLog?: InputMaybe<ProductChangeLogCreateNestedManyWithoutOrganizationInput>;
  products?: InputMaybe<ProductCreateNestedManyWithoutOrganizationInput>;
  re_cold_call_hour_delay?: InputMaybe<Scalars['Float']['input']>;
  recordingStatus?: InputMaybe<RecordingStatus>;
  release_lead_resting?: InputMaybe<Scalars['Int']['input']>;
  rep_claim_limit?: InputMaybe<Scalars['Int']['input']>;
  rep_lead_allotment?: InputMaybe<Scalars['Int']['input']>;
  rep_level_call_transfer?: InputMaybe<Scalars['Boolean']['input']>;
  reps_can_bulk_unassign?: InputMaybe<Scalars['Boolean']['input']>;
  reps_can_view_all_notes?: InputMaybe<Scalars['Boolean']['input']>;
  require_sale_notes?: InputMaybe<Scalars['Boolean']['input']>;
  resting_after_skipped_CC?: InputMaybe<Scalars['Int']['input']>;
  resting_period?: InputMaybe<Scalars['Int']['input']>;
  resting_period_after_unsuccessful_cold_call?: InputMaybe<Scalars['Float']['input']>;
  resting_period_after_unsuccessful_demo?: InputMaybe<Scalars['Float']['input']>;
  revenue_plan?: InputMaybe<RevenuePlanItemCreateNestedManyWithoutOrganizationInput>;
  Routings?: InputMaybe<RoutingCreateNestedManyWithoutOrganizationInput>;
  sale_field_options?: InputMaybe<SaleFieldOptionCreateNestedManyWithoutOrganizationInput>;
  sale_notification_email_list?: InputMaybe<OrganizationCreatesale_Notification_Email_ListInput>;
  SaleConfig?: InputMaybe<SaleConfigCreateNestedOneWithoutOrganizationInput>;
  SaleConfigPage?: InputMaybe<SaleConfigPageCreateNestedManyWithoutOrganizationInput>;
  SaleConfigSection?: InputMaybe<SaleConfigSectionCreateNestedManyWithoutOrganizationInput>;
  SaleCycles?: InputMaybe<SaleCycleCreateNestedManyWithoutOrganizationInput>;
  SaleItems?: InputMaybe<SaleItemCreateNestedManyWithoutOrganizationInput>;
  sales?: InputMaybe<SaleCreateNestedManyWithoutOrganizationInput>;
  salesforce_domain?: InputMaybe<Scalars['String']['input']>;
  salesforce_last_sync_date?: InputMaybe<Scalars['DateTime']['input']>;
  SalesForceColumn?: InputMaybe<SalesForceColumnCreateNestedManyWithoutOrganizationInput>;
  salesForceData?: InputMaybe<SalesForceDataCreateNestedOneWithoutOrganizationInput>;
  ScheduleItems?: InputMaybe<ScheduleItemCreateNestedManyWithoutOrganizationInput>;
  self_sourcing_rule?: InputMaybe<Scalars['Int']['input']>;
  send_error_log_email?: InputMaybe<Scalars['Boolean']['input']>;
  send_lead_routing_email?: InputMaybe<Scalars['Boolean']['input']>;
  sendgrid_email_domain?: InputMaybe<Scalars['String']['input']>;
  sendgrid_email_domain_id?: InputMaybe<Scalars['Int']['input']>;
  sequence?: InputMaybe<SequenceCreateNestedManyWithoutOrganzationInput>;
  SequenceCriteriaConditions?: InputMaybe<SequenceCriteriaConditionCreateNestedManyWithoutOrganizationInput>;
  SequenceDashboardViews?: InputMaybe<SequenceDashboardViewCreateNestedManyWithoutOrganizationInput>;
  SequenceEntryCriterias?: InputMaybe<SequenceEntryCriteriaCreateNestedManyWithoutOrganizationInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutOrganizationInput>;
  SequenceSteps?: InputMaybe<SequenceStepCreateNestedManyWithoutOrganizationInput>;
  set_goal?: InputMaybe<Scalars['Float']['input']>;
  short_resting?: InputMaybe<Scalars['Int']['input']>;
  show_calendar_options?: InputMaybe<Scalars['Boolean']['input']>;
  show_contract_duration_sales?: InputMaybe<Scalars['Boolean']['input']>;
  show_lead_source_to_rep?: InputMaybe<Scalars['Boolean']['input']>;
  show_nocontact_button?: InputMaybe<Scalars['Boolean']['input']>;
  show_payment_terms_sales?: InputMaybe<Scalars['Boolean']['input']>;
  show_rep_all_leads?: InputMaybe<Scalars['Boolean']['input']>;
  show_rep_my_leads?: InputMaybe<Scalars['Boolean']['input']>;
  show_system_view?: InputMaybe<Scalars['Boolean']['input']>;
  sms_opt_in_dialog?: InputMaybe<Scalars['String']['input']>;
  SMSItems?: InputMaybe<SmsItemCreateNestedManyWithoutOrganizationInput>;
  SnoozeTrackings?: InputMaybe<SnoozeTrackingCreateNestedManyWithoutOrganizationInput>;
  StepActionLogs?: InputMaybe<StepActionLogCreateNestedManyWithoutOrganizationInput>;
  StepActions?: InputMaybe<StepActionCreateNestedManyWithoutOrganizationInput>;
  StepConditions?: InputMaybe<StepConditionCreateNestedManyWithoutOrganizationInput>;
  teams?: InputMaybe<TeamCreateNestedManyWithoutOrganizationInput>;
  Template?: InputMaybe<TemplateCreateNestedManyWithoutOrganizationInput>;
  TemplateVersions?: InputMaybe<TemplateVersionCreateNestedManyWithoutOrganizationInput>;
  timekit_id?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  transfer_timeout?: InputMaybe<Scalars['Int']['input']>;
  TransferAttempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutOrganizationInput>;
  twilio_numbers?: InputMaybe<TwilioNumberCreateNestedManyWithoutOrganizationInput>;
  TwilioFeedbacks?: InputMaybe<TwilioFeedbackCreateNestedManyWithoutOrganizationInput>;
  TwilioInfo?: InputMaybe<TwilioInfoCreateNestedOneWithoutOrganzationInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  use_revenue_planning?: InputMaybe<Scalars['Boolean']['input']>;
  UserCustomFieldFilters?: InputMaybe<UserCustomFieldFilterCreateNestedManyWithoutOrganizationInput>;
  users?: InputMaybe<UserCreateNestedManyWithoutOrganizationInput>;
  win_rep_day_goal?: InputMaybe<Scalars['Float']['input']>;
};

export type OrganizationCreateWithoutSmsItemsInput = {
  ActivityMonitorMultiplier?: InputMaybe<ActivityMonitorMultiplierCreateNestedManyWithoutOrganizationInput>;
  algoFactor?: InputMaybe<AlgoFactorCreateNestedManyWithoutOrganizationInput>;
  allow_calendar_invite?: InputMaybe<Scalars['Boolean']['input']>;
  allow_remove_step_action?: InputMaybe<Scalars['Boolean']['input']>;
  allow_snooze_step_action?: InputMaybe<Scalars['Boolean']['input']>;
  always_record?: InputMaybe<Scalars['Boolean']['input']>;
  arv_initial_held_demos_generated_by_sdr_weight?: InputMaybe<Scalars['Float']['input']>;
  AssociationHistories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutOrganizationInput>;
  auto_call_interval?: InputMaybe<Scalars['Int']['input']>;
  auto_dial?: InputMaybe<Scalars['Boolean']['input']>;
  automated_campaigns?: InputMaybe<AutomatedCampaignCreateNestedManyWithoutOrganizationInput>;
  automatic_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  AutoTransferSelections?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutOrganizationInput>;
  Availablities?: InputMaybe<AvailabilityCreateNestedManyWithoutOrganizationInput>;
  AverageOrderValue?: InputMaybe<AverageOrderValueCreateNestedManyWithoutOrganizationInput>;
  best_action_ever_multiplier?: InputMaybe<Scalars['Float']['input']>;
  best_metrics?: InputMaybe<BestMetricRecordCreateNestedManyWithoutOrganizationInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptCreateNestedManyWithoutOrganizationInput>;
  callMeNows?: InputMaybe<CallMeNowCreateNestedManyWithoutOrganizationInput>;
  CallMultiplier?: InputMaybe<CallMultiplierCreateNestedManyWithoutOrganizationInput>;
  CallSharings?: InputMaybe<CallSharingCreateNestedManyWithoutOrganizationInput>;
  can_edit_sdr_transfer_rules?: InputMaybe<Scalars['Boolean']['input']>;
  claim_rule?: InputMaybe<Scalars['Int']['input']>;
  concession?: InputMaybe<ConcessionCreateNestedManyWithoutOrganizationInput>;
  conference_region?: InputMaybe<ConferenceRegion>;
  Conferences?: InputMaybe<ConferenceCreateNestedManyWithoutOrganizationInput>;
  ContactRequests?: InputMaybe<ContactRequestCreateNestedManyWithoutOrganizationInput>;
  Contacts?: InputMaybe<ContactCreateNestedManyWithoutOrganizationInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  custom_fields?: InputMaybe<CustomFieldCreateNestedManyWithoutOrganizationInput>;
  CustomObject?: InputMaybe<CustomObjectCreateNestedManyWithoutOrganizationInput>;
  CustomObjectField?: InputMaybe<CustomObjectFieldCreateNestedManyWithoutOrganizationInput>;
  CustomObjectFieldForeignKey?: InputMaybe<CustomObjectFieldForeignKeyCreateNestedManyWithoutOrganizationInput>;
  CustomObjectRecord?: InputMaybe<CustomObjectRecordCreateNestedManyWithoutOrganizationInput>;
  CustomObjectRow?: InputMaybe<CustomObjectRowCreateNestedManyWithoutOrganizationInput>;
  CustomQueueItems?: InputMaybe<CustomQueueItemCreateNestedManyWithoutOrganizationInput>;
  DashboardColumnOptions?: InputMaybe<DashboardColumnOptionCreateNestedManyWithoutOrganizationInput>;
  DashboardLeadFilters?: InputMaybe<DashboardLeadFilterCreateNestedManyWithoutOrganizationInput>;
  DashboardRepFilters?: InputMaybe<DashboardRepFilterCreateNestedManyWithoutOrganizationInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewCreateNestedManyWithoutOrganizationInput>;
  day_to_close?: InputMaybe<Scalars['Int']['input']>;
  default_demo_time_duration?: InputMaybe<Scalars['Int']['input']>;
  demo_held_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_not_held_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_not_held_call_back_short_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_prep_time_minutes?: InputMaybe<Scalars['Int']['input']>;
  dev_account?: InputMaybe<Scalars['Boolean']['input']>;
  dial_rule?: InputMaybe<Scalars['Int']['input']>;
  DispositionMultiplier?: InputMaybe<DispositionMultiplierCreateNestedManyWithoutOrganizationInput>;
  dispositions?: InputMaybe<DispositionCreateNestedManyWithoutOrganizationInput>;
  double_booking?: InputMaybe<Scalars['Boolean']['input']>;
  dynamic_date_operators?: InputMaybe<Scalars['Boolean']['input']>;
  edge_servers?: InputMaybe<OrganizationCreateedge_ServersInput>;
  email_conflict_notification_email_list?: InputMaybe<OrganizationCreateemail_Conflict_Notification_Email_ListInput>;
  EmailItems?: InputMaybe<EmailItemCreateNestedManyWithoutOrganizationInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordCreateNestedManyWithoutOrganizationInput>;
  EmailThreads?: InputMaybe<EmailThreadCreateNestedManyWithoutOrganizationInput>;
  enable_modified_metrics?: InputMaybe<Scalars['Boolean']['input']>;
  enable_pay_period_view?: InputMaybe<Scalars['Boolean']['input']>;
  enable_time_constraint?: InputMaybe<Scalars['Boolean']['input']>;
  error_log_email_address?: InputMaybe<OrganizationCreateerror_Log_Email_AddressInput>;
  estimated_average_num_of_weekly_overbooked_demo_slots?: InputMaybe<Scalars['Int']['input']>;
  FolderPermissions?: InputMaybe<FolderPermissionCreateNestedManyWithoutOrganizationInput>;
  Folders?: InputMaybe<FolderCreateNestedManyWithoutOrganizationInput>;
  FutureBookingSetting?: InputMaybe<FutureBookingSettingCreateNestedOneWithoutOrganizationInput>;
  GlobalTransferRule?: InputMaybe<GlobalTransferRuleCreateNestedManyWithoutOrganizationInput>;
  held_demos_per_cc_connect?: InputMaybe<Scalars['Float']['input']>;
  helpful_links?: InputMaybe<OrganizationCreatehelpful_LinksInput>;
  HingePointMultiplier?: InputMaybe<HingePointMultiplierCreateNestedManyWithoutOrganizationInput>;
  hold_goal?: InputMaybe<Scalars['Float']['input']>;
  hubspot_credential?: InputMaybe<HubSpotCredentialCreateNestedOneWithoutOrganizationInput>;
  hubspot_last_sync_date?: InputMaybe<Scalars['DateTime']['input']>;
  hubspot_white_list?: InputMaybe<HubSpotWhitelistCreateNestedManyWithoutOrganizationInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  inbound_close_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_dm_connect_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_lt_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_revenue_value_of_sale?: InputMaybe<Scalars['Float']['input']>;
  inbound_scheduled_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_set_rate?: InputMaybe<Scalars['Float']['input']>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventCreateNestedManyWithoutOrganizationInput>;
  InboundConciergeWidget?: InputMaybe<InboundConciergeWidgetCreateNestedManyWithoutOrganizationInput>;
  industry_options?: InputMaybe<IndustryOptionCreateNestedManyWithoutOrganizationInput>;
  initial_sdr_sets_reps_day?: InputMaybe<Scalars['Float']['input']>;
  integration_error_logs?: InputMaybe<IntegrationErrorLogCreateNestedManyWithoutOrganizationInput>;
  integration_mappings?: InputMaybe<IntegrationMappingCreateNestedManyWithoutOrganizationInput>;
  IntegrationMappingCustomObject?: InputMaybe<IntegrationMappingCustomObjectCreateNestedManyWithoutOrganizationInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogCreateNestedManyWithoutOrganizationInput>;
  IntegrationReadOnlyObject?: InputMaybe<IntegrationReadOnlyObjectCreateNestedManyWithoutOrganizationInput>;
  international?: InputMaybe<Scalars['Boolean']['input']>;
  is_email_domain_verified?: InputMaybe<Scalars['Boolean']['input']>;
  is_salesforce_sandbox?: InputMaybe<Scalars['Boolean']['input']>;
  last_lead_value_compute_time?: InputMaybe<Scalars['DateTime']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutOrganizationInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryCreateNestedManyWithoutOrganizationInput>;
  lead_intent?: InputMaybe<LeadIntentCreateNestedManyWithoutOrganizationInput>;
  lead_release_same_rep_delay_rule?: InputMaybe<Scalars['Int']['input']>;
  lead_routing_email_list?: InputMaybe<OrganizationCreatelead_Routing_Email_ListInput>;
  lead_routing_rules?: InputMaybe<RuleCreateNestedManyWithoutOrganizationInput>;
  LeadCustomObjectRowAssociation?: InputMaybe<LeadCustomObjectRowAssociationCreateNestedManyWithoutOrganizationInput>;
  LeadFieldMultiplier?: InputMaybe<LeadFieldMultiplierCreateNestedManyWithoutOrganizationInput>;
  LeadFieldMultiplierCustom?: InputMaybe<LeadFieldMultiplierCustomCreateNestedManyWithoutOrganizationInput>;
  leads?: InputMaybe<LeadCreateNestedManyWithoutOrganizationInput>;
  leadsource_options?: InputMaybe<LeadSourceOptionCreateNestedManyWithoutOrganizationInput>;
  LeadValue?: InputMaybe<LeadValueCreateNestedManyWithoutOrganizationInput>;
  LifeTimeValueMultiplier?: InputMaybe<LifeTimeValueMultiplierCreateNestedManyWithoutOrganizationInput>;
  LiveTransferSetting?: InputMaybe<LiveTransferSettingCreateNestedOneWithoutOrganizationInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusCreateNestedManyWithoutOrganizationInput>;
  ltv_multiplier_custom_fields?: InputMaybe<LifeTimeValueMultiplierCustomCreateNestedManyWithoutOrganizationInput>;
  made_contact_rule?: InputMaybe<Scalars['Int']['input']>;
  make_sale_address_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_business_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_city_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_country_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_first_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_industry_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_last_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_lead_source_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_primary_email_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_primary_phone_number_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_state_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_sub_industry_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_zip_required?: InputMaybe<Scalars['Boolean']['input']>;
  maxium_day_lag_set_to_schedule?: InputMaybe<Scalars['Int']['input']>;
  mrr?: InputMaybe<Scalars['Int']['input']>;
  mrr_label?: InputMaybe<Scalars['String']['input']>;
  mrr_required_on_sale?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  NoteItems?: InputMaybe<NoteItemCreateNestedManyWithoutOrganizationInput>;
  Notifications?: InputMaybe<NotificationCreateNestedManyWithoutOrganizationInput>;
  onboardComplete?: InputMaybe<Scalars['Boolean']['input']>;
  Opportunity?: InputMaybe<SfOpportunityCreateNestedManyWithoutOrganizationInput>;
  organization_holidays?: InputMaybe<OrganizationHolidayCreateNestedManyWithoutOrganizationInput>;
  organization_hours?: InputMaybe<OrganizationHourCreateNestedManyWithoutOrganizationInput>;
  OrganizationPaymentSetting?: InputMaybe<OrganizationPaymentSettingsCreateNestedOneWithoutOrganizationInput>;
  OrganizationPaymentSettings?: InputMaybe<OrganizationPaymentSettingsCreateNestedOneWithoutOrganizationsInput>;
  orgCalendarInviteMetadata?: InputMaybe<OrgCalendarInviteMetadataCreateNestedManyWithoutOrganizationInput>;
  OrgDefaultPipelinePerecentValue?: InputMaybe<OrgDefaultPipelinePerecentValueCreateNestedOneWithoutOrganizationInput>;
  OrgHubSpotSync?: InputMaybe<OrgHubSpotSyncCreateNestedOneWithoutOrganizationInput>;
  OrgNoEventDate?: InputMaybe<OrgNoEventDateCreateNestedManyWithoutOrganizationInput>;
  OrgSalesforceSync?: InputMaybe<OrgSalesforceSyncCreateNestedOneWithoutOrganizationInput>;
  OrgTimeslotAvailability?: InputMaybe<OrgTimeslotAvailabilityCreateNestedManyWithoutOrganizationInput>;
  outbound_close_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_dm_connect_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_lt_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_revenue_value_of_sale?: InputMaybe<Scalars['Float']['input']>;
  outbound_scheduled_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_set_rate?: InputMaybe<Scalars['Float']['input']>;
  PandadocData?: InputMaybe<PandadocDataCreateNestedOneWithoutOrganizationInput>;
  PandaDocTemplate?: InputMaybe<PandaDocTemplateCreateNestedManyWithoutOrganizationInput>;
  participant_jitter_buffer_size?: InputMaybe<JitterBufferSize>;
  preferred_goal_setting?: InputMaybe<Goalsetting>;
  prepayment?: InputMaybe<Scalars['Boolean']['input']>;
  ProductChangeLog?: InputMaybe<ProductChangeLogCreateNestedManyWithoutOrganizationInput>;
  products?: InputMaybe<ProductCreateNestedManyWithoutOrganizationInput>;
  re_cold_call_hour_delay?: InputMaybe<Scalars['Float']['input']>;
  recordingStatus?: InputMaybe<RecordingStatus>;
  release_lead_resting?: InputMaybe<Scalars['Int']['input']>;
  rep_claim_limit?: InputMaybe<Scalars['Int']['input']>;
  rep_lead_allotment?: InputMaybe<Scalars['Int']['input']>;
  rep_level_call_transfer?: InputMaybe<Scalars['Boolean']['input']>;
  reps_can_bulk_unassign?: InputMaybe<Scalars['Boolean']['input']>;
  reps_can_view_all_notes?: InputMaybe<Scalars['Boolean']['input']>;
  require_sale_notes?: InputMaybe<Scalars['Boolean']['input']>;
  resting_after_skipped_CC?: InputMaybe<Scalars['Int']['input']>;
  resting_period?: InputMaybe<Scalars['Int']['input']>;
  resting_period_after_unsuccessful_cold_call?: InputMaybe<Scalars['Float']['input']>;
  resting_period_after_unsuccessful_demo?: InputMaybe<Scalars['Float']['input']>;
  revenue_plan?: InputMaybe<RevenuePlanItemCreateNestedManyWithoutOrganizationInput>;
  Routings?: InputMaybe<RoutingCreateNestedManyWithoutOrganizationInput>;
  sale_field_options?: InputMaybe<SaleFieldOptionCreateNestedManyWithoutOrganizationInput>;
  sale_notification_email_list?: InputMaybe<OrganizationCreatesale_Notification_Email_ListInput>;
  SaleConfig?: InputMaybe<SaleConfigCreateNestedOneWithoutOrganizationInput>;
  SaleConfigPage?: InputMaybe<SaleConfigPageCreateNestedManyWithoutOrganizationInput>;
  SaleConfigSection?: InputMaybe<SaleConfigSectionCreateNestedManyWithoutOrganizationInput>;
  SaleCycles?: InputMaybe<SaleCycleCreateNestedManyWithoutOrganizationInput>;
  SaleItems?: InputMaybe<SaleItemCreateNestedManyWithoutOrganizationInput>;
  sales?: InputMaybe<SaleCreateNestedManyWithoutOrganizationInput>;
  salesforce_domain?: InputMaybe<Scalars['String']['input']>;
  salesforce_last_sync_date?: InputMaybe<Scalars['DateTime']['input']>;
  SalesForceColumn?: InputMaybe<SalesForceColumnCreateNestedManyWithoutOrganizationInput>;
  salesForceData?: InputMaybe<SalesForceDataCreateNestedOneWithoutOrganizationInput>;
  ScheduleItems?: InputMaybe<ScheduleItemCreateNestedManyWithoutOrganizationInput>;
  self_sourcing_rule?: InputMaybe<Scalars['Int']['input']>;
  send_error_log_email?: InputMaybe<Scalars['Boolean']['input']>;
  send_lead_routing_email?: InputMaybe<Scalars['Boolean']['input']>;
  sendgrid_email_domain?: InputMaybe<Scalars['String']['input']>;
  sendgrid_email_domain_id?: InputMaybe<Scalars['Int']['input']>;
  sequence?: InputMaybe<SequenceCreateNestedManyWithoutOrganzationInput>;
  SequenceCriteriaConditions?: InputMaybe<SequenceCriteriaConditionCreateNestedManyWithoutOrganizationInput>;
  SequenceDashboardViews?: InputMaybe<SequenceDashboardViewCreateNestedManyWithoutOrganizationInput>;
  SequenceEntryCriterias?: InputMaybe<SequenceEntryCriteriaCreateNestedManyWithoutOrganizationInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutOrganizationInput>;
  SequenceSteps?: InputMaybe<SequenceStepCreateNestedManyWithoutOrganizationInput>;
  set_goal?: InputMaybe<Scalars['Float']['input']>;
  short_resting?: InputMaybe<Scalars['Int']['input']>;
  show_calendar_options?: InputMaybe<Scalars['Boolean']['input']>;
  show_contract_duration_sales?: InputMaybe<Scalars['Boolean']['input']>;
  show_lead_source_to_rep?: InputMaybe<Scalars['Boolean']['input']>;
  show_nocontact_button?: InputMaybe<Scalars['Boolean']['input']>;
  show_payment_terms_sales?: InputMaybe<Scalars['Boolean']['input']>;
  show_rep_all_leads?: InputMaybe<Scalars['Boolean']['input']>;
  show_rep_my_leads?: InputMaybe<Scalars['Boolean']['input']>;
  show_system_view?: InputMaybe<Scalars['Boolean']['input']>;
  sites?: InputMaybe<SiteCreateNestedManyWithoutOrganizationInput>;
  sms_opt_in_dialog?: InputMaybe<Scalars['String']['input']>;
  SnoozeTrackings?: InputMaybe<SnoozeTrackingCreateNestedManyWithoutOrganizationInput>;
  StepActionLogs?: InputMaybe<StepActionLogCreateNestedManyWithoutOrganizationInput>;
  StepActions?: InputMaybe<StepActionCreateNestedManyWithoutOrganizationInput>;
  StepConditions?: InputMaybe<StepConditionCreateNestedManyWithoutOrganizationInput>;
  teams?: InputMaybe<TeamCreateNestedManyWithoutOrganizationInput>;
  Template?: InputMaybe<TemplateCreateNestedManyWithoutOrganizationInput>;
  TemplateVersions?: InputMaybe<TemplateVersionCreateNestedManyWithoutOrganizationInput>;
  timekit_id?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  transfer_timeout?: InputMaybe<Scalars['Int']['input']>;
  TransferAttempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutOrganizationInput>;
  twilio_numbers?: InputMaybe<TwilioNumberCreateNestedManyWithoutOrganizationInput>;
  TwilioFeedbacks?: InputMaybe<TwilioFeedbackCreateNestedManyWithoutOrganizationInput>;
  TwilioInfo?: InputMaybe<TwilioInfoCreateNestedOneWithoutOrganzationInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  use_revenue_planning?: InputMaybe<Scalars['Boolean']['input']>;
  UserCustomFieldFilters?: InputMaybe<UserCustomFieldFilterCreateNestedManyWithoutOrganizationInput>;
  users?: InputMaybe<UserCreateNestedManyWithoutOrganizationInput>;
  win_rep_day_goal?: InputMaybe<Scalars['Float']['input']>;
};

export type OrganizationCreateWithoutSnoozeTrackingsInput = {
  ActivityMonitorMultiplier?: InputMaybe<ActivityMonitorMultiplierCreateNestedManyWithoutOrganizationInput>;
  algoFactor?: InputMaybe<AlgoFactorCreateNestedManyWithoutOrganizationInput>;
  allow_calendar_invite?: InputMaybe<Scalars['Boolean']['input']>;
  allow_remove_step_action?: InputMaybe<Scalars['Boolean']['input']>;
  allow_snooze_step_action?: InputMaybe<Scalars['Boolean']['input']>;
  always_record?: InputMaybe<Scalars['Boolean']['input']>;
  arv_initial_held_demos_generated_by_sdr_weight?: InputMaybe<Scalars['Float']['input']>;
  AssociationHistories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutOrganizationInput>;
  auto_call_interval?: InputMaybe<Scalars['Int']['input']>;
  auto_dial?: InputMaybe<Scalars['Boolean']['input']>;
  automated_campaigns?: InputMaybe<AutomatedCampaignCreateNestedManyWithoutOrganizationInput>;
  automatic_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  AutoTransferSelections?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutOrganizationInput>;
  Availablities?: InputMaybe<AvailabilityCreateNestedManyWithoutOrganizationInput>;
  AverageOrderValue?: InputMaybe<AverageOrderValueCreateNestedManyWithoutOrganizationInput>;
  best_action_ever_multiplier?: InputMaybe<Scalars['Float']['input']>;
  best_metrics?: InputMaybe<BestMetricRecordCreateNestedManyWithoutOrganizationInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptCreateNestedManyWithoutOrganizationInput>;
  callMeNows?: InputMaybe<CallMeNowCreateNestedManyWithoutOrganizationInput>;
  CallMultiplier?: InputMaybe<CallMultiplierCreateNestedManyWithoutOrganizationInput>;
  CallSharings?: InputMaybe<CallSharingCreateNestedManyWithoutOrganizationInput>;
  can_edit_sdr_transfer_rules?: InputMaybe<Scalars['Boolean']['input']>;
  claim_rule?: InputMaybe<Scalars['Int']['input']>;
  concession?: InputMaybe<ConcessionCreateNestedManyWithoutOrganizationInput>;
  conference_region?: InputMaybe<ConferenceRegion>;
  Conferences?: InputMaybe<ConferenceCreateNestedManyWithoutOrganizationInput>;
  ContactRequests?: InputMaybe<ContactRequestCreateNestedManyWithoutOrganizationInput>;
  Contacts?: InputMaybe<ContactCreateNestedManyWithoutOrganizationInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  custom_fields?: InputMaybe<CustomFieldCreateNestedManyWithoutOrganizationInput>;
  CustomObject?: InputMaybe<CustomObjectCreateNestedManyWithoutOrganizationInput>;
  CustomObjectField?: InputMaybe<CustomObjectFieldCreateNestedManyWithoutOrganizationInput>;
  CustomObjectFieldForeignKey?: InputMaybe<CustomObjectFieldForeignKeyCreateNestedManyWithoutOrganizationInput>;
  CustomObjectRecord?: InputMaybe<CustomObjectRecordCreateNestedManyWithoutOrganizationInput>;
  CustomObjectRow?: InputMaybe<CustomObjectRowCreateNestedManyWithoutOrganizationInput>;
  CustomQueueItems?: InputMaybe<CustomQueueItemCreateNestedManyWithoutOrganizationInput>;
  DashboardColumnOptions?: InputMaybe<DashboardColumnOptionCreateNestedManyWithoutOrganizationInput>;
  DashboardLeadFilters?: InputMaybe<DashboardLeadFilterCreateNestedManyWithoutOrganizationInput>;
  DashboardRepFilters?: InputMaybe<DashboardRepFilterCreateNestedManyWithoutOrganizationInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewCreateNestedManyWithoutOrganizationInput>;
  day_to_close?: InputMaybe<Scalars['Int']['input']>;
  default_demo_time_duration?: InputMaybe<Scalars['Int']['input']>;
  demo_held_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_not_held_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_not_held_call_back_short_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_prep_time_minutes?: InputMaybe<Scalars['Int']['input']>;
  dev_account?: InputMaybe<Scalars['Boolean']['input']>;
  dial_rule?: InputMaybe<Scalars['Int']['input']>;
  DispositionMultiplier?: InputMaybe<DispositionMultiplierCreateNestedManyWithoutOrganizationInput>;
  dispositions?: InputMaybe<DispositionCreateNestedManyWithoutOrganizationInput>;
  double_booking?: InputMaybe<Scalars['Boolean']['input']>;
  dynamic_date_operators?: InputMaybe<Scalars['Boolean']['input']>;
  edge_servers?: InputMaybe<OrganizationCreateedge_ServersInput>;
  email_conflict_notification_email_list?: InputMaybe<OrganizationCreateemail_Conflict_Notification_Email_ListInput>;
  EmailItems?: InputMaybe<EmailItemCreateNestedManyWithoutOrganizationInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordCreateNestedManyWithoutOrganizationInput>;
  EmailThreads?: InputMaybe<EmailThreadCreateNestedManyWithoutOrganizationInput>;
  enable_modified_metrics?: InputMaybe<Scalars['Boolean']['input']>;
  enable_pay_period_view?: InputMaybe<Scalars['Boolean']['input']>;
  enable_time_constraint?: InputMaybe<Scalars['Boolean']['input']>;
  error_log_email_address?: InputMaybe<OrganizationCreateerror_Log_Email_AddressInput>;
  estimated_average_num_of_weekly_overbooked_demo_slots?: InputMaybe<Scalars['Int']['input']>;
  FolderPermissions?: InputMaybe<FolderPermissionCreateNestedManyWithoutOrganizationInput>;
  Folders?: InputMaybe<FolderCreateNestedManyWithoutOrganizationInput>;
  FutureBookingSetting?: InputMaybe<FutureBookingSettingCreateNestedOneWithoutOrganizationInput>;
  GlobalTransferRule?: InputMaybe<GlobalTransferRuleCreateNestedManyWithoutOrganizationInput>;
  held_demos_per_cc_connect?: InputMaybe<Scalars['Float']['input']>;
  helpful_links?: InputMaybe<OrganizationCreatehelpful_LinksInput>;
  HingePointMultiplier?: InputMaybe<HingePointMultiplierCreateNestedManyWithoutOrganizationInput>;
  hold_goal?: InputMaybe<Scalars['Float']['input']>;
  hubspot_credential?: InputMaybe<HubSpotCredentialCreateNestedOneWithoutOrganizationInput>;
  hubspot_last_sync_date?: InputMaybe<Scalars['DateTime']['input']>;
  hubspot_white_list?: InputMaybe<HubSpotWhitelistCreateNestedManyWithoutOrganizationInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  inbound_close_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_dm_connect_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_lt_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_revenue_value_of_sale?: InputMaybe<Scalars['Float']['input']>;
  inbound_scheduled_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_set_rate?: InputMaybe<Scalars['Float']['input']>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventCreateNestedManyWithoutOrganizationInput>;
  InboundConciergeWidget?: InputMaybe<InboundConciergeWidgetCreateNestedManyWithoutOrganizationInput>;
  industry_options?: InputMaybe<IndustryOptionCreateNestedManyWithoutOrganizationInput>;
  initial_sdr_sets_reps_day?: InputMaybe<Scalars['Float']['input']>;
  integration_error_logs?: InputMaybe<IntegrationErrorLogCreateNestedManyWithoutOrganizationInput>;
  integration_mappings?: InputMaybe<IntegrationMappingCreateNestedManyWithoutOrganizationInput>;
  IntegrationMappingCustomObject?: InputMaybe<IntegrationMappingCustomObjectCreateNestedManyWithoutOrganizationInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogCreateNestedManyWithoutOrganizationInput>;
  IntegrationReadOnlyObject?: InputMaybe<IntegrationReadOnlyObjectCreateNestedManyWithoutOrganizationInput>;
  international?: InputMaybe<Scalars['Boolean']['input']>;
  is_email_domain_verified?: InputMaybe<Scalars['Boolean']['input']>;
  is_salesforce_sandbox?: InputMaybe<Scalars['Boolean']['input']>;
  last_lead_value_compute_time?: InputMaybe<Scalars['DateTime']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutOrganizationInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryCreateNestedManyWithoutOrganizationInput>;
  lead_intent?: InputMaybe<LeadIntentCreateNestedManyWithoutOrganizationInput>;
  lead_release_same_rep_delay_rule?: InputMaybe<Scalars['Int']['input']>;
  lead_routing_email_list?: InputMaybe<OrganizationCreatelead_Routing_Email_ListInput>;
  lead_routing_rules?: InputMaybe<RuleCreateNestedManyWithoutOrganizationInput>;
  LeadCustomObjectRowAssociation?: InputMaybe<LeadCustomObjectRowAssociationCreateNestedManyWithoutOrganizationInput>;
  LeadFieldMultiplier?: InputMaybe<LeadFieldMultiplierCreateNestedManyWithoutOrganizationInput>;
  LeadFieldMultiplierCustom?: InputMaybe<LeadFieldMultiplierCustomCreateNestedManyWithoutOrganizationInput>;
  leads?: InputMaybe<LeadCreateNestedManyWithoutOrganizationInput>;
  leadsource_options?: InputMaybe<LeadSourceOptionCreateNestedManyWithoutOrganizationInput>;
  LeadValue?: InputMaybe<LeadValueCreateNestedManyWithoutOrganizationInput>;
  LifeTimeValueMultiplier?: InputMaybe<LifeTimeValueMultiplierCreateNestedManyWithoutOrganizationInput>;
  LiveTransferSetting?: InputMaybe<LiveTransferSettingCreateNestedOneWithoutOrganizationInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusCreateNestedManyWithoutOrganizationInput>;
  ltv_multiplier_custom_fields?: InputMaybe<LifeTimeValueMultiplierCustomCreateNestedManyWithoutOrganizationInput>;
  made_contact_rule?: InputMaybe<Scalars['Int']['input']>;
  make_sale_address_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_business_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_city_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_country_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_first_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_industry_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_last_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_lead_source_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_primary_email_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_primary_phone_number_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_state_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_sub_industry_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_zip_required?: InputMaybe<Scalars['Boolean']['input']>;
  maxium_day_lag_set_to_schedule?: InputMaybe<Scalars['Int']['input']>;
  mrr?: InputMaybe<Scalars['Int']['input']>;
  mrr_label?: InputMaybe<Scalars['String']['input']>;
  mrr_required_on_sale?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  NoteItems?: InputMaybe<NoteItemCreateNestedManyWithoutOrganizationInput>;
  Notifications?: InputMaybe<NotificationCreateNestedManyWithoutOrganizationInput>;
  onboardComplete?: InputMaybe<Scalars['Boolean']['input']>;
  Opportunity?: InputMaybe<SfOpportunityCreateNestedManyWithoutOrganizationInput>;
  organization_holidays?: InputMaybe<OrganizationHolidayCreateNestedManyWithoutOrganizationInput>;
  organization_hours?: InputMaybe<OrganizationHourCreateNestedManyWithoutOrganizationInput>;
  OrganizationPaymentSetting?: InputMaybe<OrganizationPaymentSettingsCreateNestedOneWithoutOrganizationInput>;
  OrganizationPaymentSettings?: InputMaybe<OrganizationPaymentSettingsCreateNestedOneWithoutOrganizationsInput>;
  orgCalendarInviteMetadata?: InputMaybe<OrgCalendarInviteMetadataCreateNestedManyWithoutOrganizationInput>;
  OrgDefaultPipelinePerecentValue?: InputMaybe<OrgDefaultPipelinePerecentValueCreateNestedOneWithoutOrganizationInput>;
  OrgHubSpotSync?: InputMaybe<OrgHubSpotSyncCreateNestedOneWithoutOrganizationInput>;
  OrgNoEventDate?: InputMaybe<OrgNoEventDateCreateNestedManyWithoutOrganizationInput>;
  OrgSalesforceSync?: InputMaybe<OrgSalesforceSyncCreateNestedOneWithoutOrganizationInput>;
  OrgTimeslotAvailability?: InputMaybe<OrgTimeslotAvailabilityCreateNestedManyWithoutOrganizationInput>;
  outbound_close_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_dm_connect_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_lt_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_revenue_value_of_sale?: InputMaybe<Scalars['Float']['input']>;
  outbound_scheduled_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_set_rate?: InputMaybe<Scalars['Float']['input']>;
  PandadocData?: InputMaybe<PandadocDataCreateNestedOneWithoutOrganizationInput>;
  PandaDocTemplate?: InputMaybe<PandaDocTemplateCreateNestedManyWithoutOrganizationInput>;
  participant_jitter_buffer_size?: InputMaybe<JitterBufferSize>;
  preferred_goal_setting?: InputMaybe<Goalsetting>;
  prepayment?: InputMaybe<Scalars['Boolean']['input']>;
  ProductChangeLog?: InputMaybe<ProductChangeLogCreateNestedManyWithoutOrganizationInput>;
  products?: InputMaybe<ProductCreateNestedManyWithoutOrganizationInput>;
  re_cold_call_hour_delay?: InputMaybe<Scalars['Float']['input']>;
  recordingStatus?: InputMaybe<RecordingStatus>;
  release_lead_resting?: InputMaybe<Scalars['Int']['input']>;
  rep_claim_limit?: InputMaybe<Scalars['Int']['input']>;
  rep_lead_allotment?: InputMaybe<Scalars['Int']['input']>;
  rep_level_call_transfer?: InputMaybe<Scalars['Boolean']['input']>;
  reps_can_bulk_unassign?: InputMaybe<Scalars['Boolean']['input']>;
  reps_can_view_all_notes?: InputMaybe<Scalars['Boolean']['input']>;
  require_sale_notes?: InputMaybe<Scalars['Boolean']['input']>;
  resting_after_skipped_CC?: InputMaybe<Scalars['Int']['input']>;
  resting_period?: InputMaybe<Scalars['Int']['input']>;
  resting_period_after_unsuccessful_cold_call?: InputMaybe<Scalars['Float']['input']>;
  resting_period_after_unsuccessful_demo?: InputMaybe<Scalars['Float']['input']>;
  revenue_plan?: InputMaybe<RevenuePlanItemCreateNestedManyWithoutOrganizationInput>;
  Routings?: InputMaybe<RoutingCreateNestedManyWithoutOrganizationInput>;
  sale_field_options?: InputMaybe<SaleFieldOptionCreateNestedManyWithoutOrganizationInput>;
  sale_notification_email_list?: InputMaybe<OrganizationCreatesale_Notification_Email_ListInput>;
  SaleConfig?: InputMaybe<SaleConfigCreateNestedOneWithoutOrganizationInput>;
  SaleConfigPage?: InputMaybe<SaleConfigPageCreateNestedManyWithoutOrganizationInput>;
  SaleConfigSection?: InputMaybe<SaleConfigSectionCreateNestedManyWithoutOrganizationInput>;
  SaleCycles?: InputMaybe<SaleCycleCreateNestedManyWithoutOrganizationInput>;
  SaleItems?: InputMaybe<SaleItemCreateNestedManyWithoutOrganizationInput>;
  sales?: InputMaybe<SaleCreateNestedManyWithoutOrganizationInput>;
  salesforce_domain?: InputMaybe<Scalars['String']['input']>;
  salesforce_last_sync_date?: InputMaybe<Scalars['DateTime']['input']>;
  SalesForceColumn?: InputMaybe<SalesForceColumnCreateNestedManyWithoutOrganizationInput>;
  salesForceData?: InputMaybe<SalesForceDataCreateNestedOneWithoutOrganizationInput>;
  ScheduleItems?: InputMaybe<ScheduleItemCreateNestedManyWithoutOrganizationInput>;
  self_sourcing_rule?: InputMaybe<Scalars['Int']['input']>;
  send_error_log_email?: InputMaybe<Scalars['Boolean']['input']>;
  send_lead_routing_email?: InputMaybe<Scalars['Boolean']['input']>;
  sendgrid_email_domain?: InputMaybe<Scalars['String']['input']>;
  sendgrid_email_domain_id?: InputMaybe<Scalars['Int']['input']>;
  sequence?: InputMaybe<SequenceCreateNestedManyWithoutOrganzationInput>;
  SequenceCriteriaConditions?: InputMaybe<SequenceCriteriaConditionCreateNestedManyWithoutOrganizationInput>;
  SequenceDashboardViews?: InputMaybe<SequenceDashboardViewCreateNestedManyWithoutOrganizationInput>;
  SequenceEntryCriterias?: InputMaybe<SequenceEntryCriteriaCreateNestedManyWithoutOrganizationInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutOrganizationInput>;
  SequenceSteps?: InputMaybe<SequenceStepCreateNestedManyWithoutOrganizationInput>;
  set_goal?: InputMaybe<Scalars['Float']['input']>;
  short_resting?: InputMaybe<Scalars['Int']['input']>;
  show_calendar_options?: InputMaybe<Scalars['Boolean']['input']>;
  show_contract_duration_sales?: InputMaybe<Scalars['Boolean']['input']>;
  show_lead_source_to_rep?: InputMaybe<Scalars['Boolean']['input']>;
  show_nocontact_button?: InputMaybe<Scalars['Boolean']['input']>;
  show_payment_terms_sales?: InputMaybe<Scalars['Boolean']['input']>;
  show_rep_all_leads?: InputMaybe<Scalars['Boolean']['input']>;
  show_rep_my_leads?: InputMaybe<Scalars['Boolean']['input']>;
  show_system_view?: InputMaybe<Scalars['Boolean']['input']>;
  sites?: InputMaybe<SiteCreateNestedManyWithoutOrganizationInput>;
  sms_opt_in_dialog?: InputMaybe<Scalars['String']['input']>;
  SMSItems?: InputMaybe<SmsItemCreateNestedManyWithoutOrganizationInput>;
  StepActionLogs?: InputMaybe<StepActionLogCreateNestedManyWithoutOrganizationInput>;
  StepActions?: InputMaybe<StepActionCreateNestedManyWithoutOrganizationInput>;
  StepConditions?: InputMaybe<StepConditionCreateNestedManyWithoutOrganizationInput>;
  teams?: InputMaybe<TeamCreateNestedManyWithoutOrganizationInput>;
  Template?: InputMaybe<TemplateCreateNestedManyWithoutOrganizationInput>;
  TemplateVersions?: InputMaybe<TemplateVersionCreateNestedManyWithoutOrganizationInput>;
  timekit_id?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  transfer_timeout?: InputMaybe<Scalars['Int']['input']>;
  TransferAttempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutOrganizationInput>;
  twilio_numbers?: InputMaybe<TwilioNumberCreateNestedManyWithoutOrganizationInput>;
  TwilioFeedbacks?: InputMaybe<TwilioFeedbackCreateNestedManyWithoutOrganizationInput>;
  TwilioInfo?: InputMaybe<TwilioInfoCreateNestedOneWithoutOrganzationInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  use_revenue_planning?: InputMaybe<Scalars['Boolean']['input']>;
  UserCustomFieldFilters?: InputMaybe<UserCustomFieldFilterCreateNestedManyWithoutOrganizationInput>;
  users?: InputMaybe<UserCreateNestedManyWithoutOrganizationInput>;
  win_rep_day_goal?: InputMaybe<Scalars['Float']['input']>;
};

export type OrganizationCreateWithoutStepActionLogsInput = {
  ActivityMonitorMultiplier?: InputMaybe<ActivityMonitorMultiplierCreateNestedManyWithoutOrganizationInput>;
  algoFactor?: InputMaybe<AlgoFactorCreateNestedManyWithoutOrganizationInput>;
  allow_calendar_invite?: InputMaybe<Scalars['Boolean']['input']>;
  allow_remove_step_action?: InputMaybe<Scalars['Boolean']['input']>;
  allow_snooze_step_action?: InputMaybe<Scalars['Boolean']['input']>;
  always_record?: InputMaybe<Scalars['Boolean']['input']>;
  arv_initial_held_demos_generated_by_sdr_weight?: InputMaybe<Scalars['Float']['input']>;
  AssociationHistories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutOrganizationInput>;
  auto_call_interval?: InputMaybe<Scalars['Int']['input']>;
  auto_dial?: InputMaybe<Scalars['Boolean']['input']>;
  automated_campaigns?: InputMaybe<AutomatedCampaignCreateNestedManyWithoutOrganizationInput>;
  automatic_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  AutoTransferSelections?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutOrganizationInput>;
  Availablities?: InputMaybe<AvailabilityCreateNestedManyWithoutOrganizationInput>;
  AverageOrderValue?: InputMaybe<AverageOrderValueCreateNestedManyWithoutOrganizationInput>;
  best_action_ever_multiplier?: InputMaybe<Scalars['Float']['input']>;
  best_metrics?: InputMaybe<BestMetricRecordCreateNestedManyWithoutOrganizationInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptCreateNestedManyWithoutOrganizationInput>;
  callMeNows?: InputMaybe<CallMeNowCreateNestedManyWithoutOrganizationInput>;
  CallMultiplier?: InputMaybe<CallMultiplierCreateNestedManyWithoutOrganizationInput>;
  CallSharings?: InputMaybe<CallSharingCreateNestedManyWithoutOrganizationInput>;
  can_edit_sdr_transfer_rules?: InputMaybe<Scalars['Boolean']['input']>;
  claim_rule?: InputMaybe<Scalars['Int']['input']>;
  concession?: InputMaybe<ConcessionCreateNestedManyWithoutOrganizationInput>;
  conference_region?: InputMaybe<ConferenceRegion>;
  Conferences?: InputMaybe<ConferenceCreateNestedManyWithoutOrganizationInput>;
  ContactRequests?: InputMaybe<ContactRequestCreateNestedManyWithoutOrganizationInput>;
  Contacts?: InputMaybe<ContactCreateNestedManyWithoutOrganizationInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  custom_fields?: InputMaybe<CustomFieldCreateNestedManyWithoutOrganizationInput>;
  CustomObject?: InputMaybe<CustomObjectCreateNestedManyWithoutOrganizationInput>;
  CustomObjectField?: InputMaybe<CustomObjectFieldCreateNestedManyWithoutOrganizationInput>;
  CustomObjectFieldForeignKey?: InputMaybe<CustomObjectFieldForeignKeyCreateNestedManyWithoutOrganizationInput>;
  CustomObjectRecord?: InputMaybe<CustomObjectRecordCreateNestedManyWithoutOrganizationInput>;
  CustomObjectRow?: InputMaybe<CustomObjectRowCreateNestedManyWithoutOrganizationInput>;
  CustomQueueItems?: InputMaybe<CustomQueueItemCreateNestedManyWithoutOrganizationInput>;
  DashboardColumnOptions?: InputMaybe<DashboardColumnOptionCreateNestedManyWithoutOrganizationInput>;
  DashboardLeadFilters?: InputMaybe<DashboardLeadFilterCreateNestedManyWithoutOrganizationInput>;
  DashboardRepFilters?: InputMaybe<DashboardRepFilterCreateNestedManyWithoutOrganizationInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewCreateNestedManyWithoutOrganizationInput>;
  day_to_close?: InputMaybe<Scalars['Int']['input']>;
  default_demo_time_duration?: InputMaybe<Scalars['Int']['input']>;
  demo_held_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_not_held_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_not_held_call_back_short_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_prep_time_minutes?: InputMaybe<Scalars['Int']['input']>;
  dev_account?: InputMaybe<Scalars['Boolean']['input']>;
  dial_rule?: InputMaybe<Scalars['Int']['input']>;
  DispositionMultiplier?: InputMaybe<DispositionMultiplierCreateNestedManyWithoutOrganizationInput>;
  dispositions?: InputMaybe<DispositionCreateNestedManyWithoutOrganizationInput>;
  double_booking?: InputMaybe<Scalars['Boolean']['input']>;
  dynamic_date_operators?: InputMaybe<Scalars['Boolean']['input']>;
  edge_servers?: InputMaybe<OrganizationCreateedge_ServersInput>;
  email_conflict_notification_email_list?: InputMaybe<OrganizationCreateemail_Conflict_Notification_Email_ListInput>;
  EmailItems?: InputMaybe<EmailItemCreateNestedManyWithoutOrganizationInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordCreateNestedManyWithoutOrganizationInput>;
  EmailThreads?: InputMaybe<EmailThreadCreateNestedManyWithoutOrganizationInput>;
  enable_modified_metrics?: InputMaybe<Scalars['Boolean']['input']>;
  enable_pay_period_view?: InputMaybe<Scalars['Boolean']['input']>;
  enable_time_constraint?: InputMaybe<Scalars['Boolean']['input']>;
  error_log_email_address?: InputMaybe<OrganizationCreateerror_Log_Email_AddressInput>;
  estimated_average_num_of_weekly_overbooked_demo_slots?: InputMaybe<Scalars['Int']['input']>;
  FolderPermissions?: InputMaybe<FolderPermissionCreateNestedManyWithoutOrganizationInput>;
  Folders?: InputMaybe<FolderCreateNestedManyWithoutOrganizationInput>;
  FutureBookingSetting?: InputMaybe<FutureBookingSettingCreateNestedOneWithoutOrganizationInput>;
  GlobalTransferRule?: InputMaybe<GlobalTransferRuleCreateNestedManyWithoutOrganizationInput>;
  held_demos_per_cc_connect?: InputMaybe<Scalars['Float']['input']>;
  helpful_links?: InputMaybe<OrganizationCreatehelpful_LinksInput>;
  HingePointMultiplier?: InputMaybe<HingePointMultiplierCreateNestedManyWithoutOrganizationInput>;
  hold_goal?: InputMaybe<Scalars['Float']['input']>;
  hubspot_credential?: InputMaybe<HubSpotCredentialCreateNestedOneWithoutOrganizationInput>;
  hubspot_last_sync_date?: InputMaybe<Scalars['DateTime']['input']>;
  hubspot_white_list?: InputMaybe<HubSpotWhitelistCreateNestedManyWithoutOrganizationInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  inbound_close_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_dm_connect_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_lt_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_revenue_value_of_sale?: InputMaybe<Scalars['Float']['input']>;
  inbound_scheduled_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_set_rate?: InputMaybe<Scalars['Float']['input']>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventCreateNestedManyWithoutOrganizationInput>;
  InboundConciergeWidget?: InputMaybe<InboundConciergeWidgetCreateNestedManyWithoutOrganizationInput>;
  industry_options?: InputMaybe<IndustryOptionCreateNestedManyWithoutOrganizationInput>;
  initial_sdr_sets_reps_day?: InputMaybe<Scalars['Float']['input']>;
  integration_error_logs?: InputMaybe<IntegrationErrorLogCreateNestedManyWithoutOrganizationInput>;
  integration_mappings?: InputMaybe<IntegrationMappingCreateNestedManyWithoutOrganizationInput>;
  IntegrationMappingCustomObject?: InputMaybe<IntegrationMappingCustomObjectCreateNestedManyWithoutOrganizationInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogCreateNestedManyWithoutOrganizationInput>;
  IntegrationReadOnlyObject?: InputMaybe<IntegrationReadOnlyObjectCreateNestedManyWithoutOrganizationInput>;
  international?: InputMaybe<Scalars['Boolean']['input']>;
  is_email_domain_verified?: InputMaybe<Scalars['Boolean']['input']>;
  is_salesforce_sandbox?: InputMaybe<Scalars['Boolean']['input']>;
  last_lead_value_compute_time?: InputMaybe<Scalars['DateTime']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutOrganizationInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryCreateNestedManyWithoutOrganizationInput>;
  lead_intent?: InputMaybe<LeadIntentCreateNestedManyWithoutOrganizationInput>;
  lead_release_same_rep_delay_rule?: InputMaybe<Scalars['Int']['input']>;
  lead_routing_email_list?: InputMaybe<OrganizationCreatelead_Routing_Email_ListInput>;
  lead_routing_rules?: InputMaybe<RuleCreateNestedManyWithoutOrganizationInput>;
  LeadCustomObjectRowAssociation?: InputMaybe<LeadCustomObjectRowAssociationCreateNestedManyWithoutOrganizationInput>;
  LeadFieldMultiplier?: InputMaybe<LeadFieldMultiplierCreateNestedManyWithoutOrganizationInput>;
  LeadFieldMultiplierCustom?: InputMaybe<LeadFieldMultiplierCustomCreateNestedManyWithoutOrganizationInput>;
  leads?: InputMaybe<LeadCreateNestedManyWithoutOrganizationInput>;
  leadsource_options?: InputMaybe<LeadSourceOptionCreateNestedManyWithoutOrganizationInput>;
  LeadValue?: InputMaybe<LeadValueCreateNestedManyWithoutOrganizationInput>;
  LifeTimeValueMultiplier?: InputMaybe<LifeTimeValueMultiplierCreateNestedManyWithoutOrganizationInput>;
  LiveTransferSetting?: InputMaybe<LiveTransferSettingCreateNestedOneWithoutOrganizationInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusCreateNestedManyWithoutOrganizationInput>;
  ltv_multiplier_custom_fields?: InputMaybe<LifeTimeValueMultiplierCustomCreateNestedManyWithoutOrganizationInput>;
  made_contact_rule?: InputMaybe<Scalars['Int']['input']>;
  make_sale_address_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_business_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_city_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_country_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_first_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_industry_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_last_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_lead_source_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_primary_email_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_primary_phone_number_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_state_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_sub_industry_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_zip_required?: InputMaybe<Scalars['Boolean']['input']>;
  maxium_day_lag_set_to_schedule?: InputMaybe<Scalars['Int']['input']>;
  mrr?: InputMaybe<Scalars['Int']['input']>;
  mrr_label?: InputMaybe<Scalars['String']['input']>;
  mrr_required_on_sale?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  NoteItems?: InputMaybe<NoteItemCreateNestedManyWithoutOrganizationInput>;
  Notifications?: InputMaybe<NotificationCreateNestedManyWithoutOrganizationInput>;
  onboardComplete?: InputMaybe<Scalars['Boolean']['input']>;
  Opportunity?: InputMaybe<SfOpportunityCreateNestedManyWithoutOrganizationInput>;
  organization_holidays?: InputMaybe<OrganizationHolidayCreateNestedManyWithoutOrganizationInput>;
  organization_hours?: InputMaybe<OrganizationHourCreateNestedManyWithoutOrganizationInput>;
  OrganizationPaymentSetting?: InputMaybe<OrganizationPaymentSettingsCreateNestedOneWithoutOrganizationInput>;
  OrganizationPaymentSettings?: InputMaybe<OrganizationPaymentSettingsCreateNestedOneWithoutOrganizationsInput>;
  orgCalendarInviteMetadata?: InputMaybe<OrgCalendarInviteMetadataCreateNestedManyWithoutOrganizationInput>;
  OrgDefaultPipelinePerecentValue?: InputMaybe<OrgDefaultPipelinePerecentValueCreateNestedOneWithoutOrganizationInput>;
  OrgHubSpotSync?: InputMaybe<OrgHubSpotSyncCreateNestedOneWithoutOrganizationInput>;
  OrgNoEventDate?: InputMaybe<OrgNoEventDateCreateNestedManyWithoutOrganizationInput>;
  OrgSalesforceSync?: InputMaybe<OrgSalesforceSyncCreateNestedOneWithoutOrganizationInput>;
  OrgTimeslotAvailability?: InputMaybe<OrgTimeslotAvailabilityCreateNestedManyWithoutOrganizationInput>;
  outbound_close_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_dm_connect_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_lt_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_revenue_value_of_sale?: InputMaybe<Scalars['Float']['input']>;
  outbound_scheduled_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_set_rate?: InputMaybe<Scalars['Float']['input']>;
  PandadocData?: InputMaybe<PandadocDataCreateNestedOneWithoutOrganizationInput>;
  PandaDocTemplate?: InputMaybe<PandaDocTemplateCreateNestedManyWithoutOrganizationInput>;
  participant_jitter_buffer_size?: InputMaybe<JitterBufferSize>;
  preferred_goal_setting?: InputMaybe<Goalsetting>;
  prepayment?: InputMaybe<Scalars['Boolean']['input']>;
  ProductChangeLog?: InputMaybe<ProductChangeLogCreateNestedManyWithoutOrganizationInput>;
  products?: InputMaybe<ProductCreateNestedManyWithoutOrganizationInput>;
  re_cold_call_hour_delay?: InputMaybe<Scalars['Float']['input']>;
  recordingStatus?: InputMaybe<RecordingStatus>;
  release_lead_resting?: InputMaybe<Scalars['Int']['input']>;
  rep_claim_limit?: InputMaybe<Scalars['Int']['input']>;
  rep_lead_allotment?: InputMaybe<Scalars['Int']['input']>;
  rep_level_call_transfer?: InputMaybe<Scalars['Boolean']['input']>;
  reps_can_bulk_unassign?: InputMaybe<Scalars['Boolean']['input']>;
  reps_can_view_all_notes?: InputMaybe<Scalars['Boolean']['input']>;
  require_sale_notes?: InputMaybe<Scalars['Boolean']['input']>;
  resting_after_skipped_CC?: InputMaybe<Scalars['Int']['input']>;
  resting_period?: InputMaybe<Scalars['Int']['input']>;
  resting_period_after_unsuccessful_cold_call?: InputMaybe<Scalars['Float']['input']>;
  resting_period_after_unsuccessful_demo?: InputMaybe<Scalars['Float']['input']>;
  revenue_plan?: InputMaybe<RevenuePlanItemCreateNestedManyWithoutOrganizationInput>;
  Routings?: InputMaybe<RoutingCreateNestedManyWithoutOrganizationInput>;
  sale_field_options?: InputMaybe<SaleFieldOptionCreateNestedManyWithoutOrganizationInput>;
  sale_notification_email_list?: InputMaybe<OrganizationCreatesale_Notification_Email_ListInput>;
  SaleConfig?: InputMaybe<SaleConfigCreateNestedOneWithoutOrganizationInput>;
  SaleConfigPage?: InputMaybe<SaleConfigPageCreateNestedManyWithoutOrganizationInput>;
  SaleConfigSection?: InputMaybe<SaleConfigSectionCreateNestedManyWithoutOrganizationInput>;
  SaleCycles?: InputMaybe<SaleCycleCreateNestedManyWithoutOrganizationInput>;
  SaleItems?: InputMaybe<SaleItemCreateNestedManyWithoutOrganizationInput>;
  sales?: InputMaybe<SaleCreateNestedManyWithoutOrganizationInput>;
  salesforce_domain?: InputMaybe<Scalars['String']['input']>;
  salesforce_last_sync_date?: InputMaybe<Scalars['DateTime']['input']>;
  SalesForceColumn?: InputMaybe<SalesForceColumnCreateNestedManyWithoutOrganizationInput>;
  salesForceData?: InputMaybe<SalesForceDataCreateNestedOneWithoutOrganizationInput>;
  ScheduleItems?: InputMaybe<ScheduleItemCreateNestedManyWithoutOrganizationInput>;
  self_sourcing_rule?: InputMaybe<Scalars['Int']['input']>;
  send_error_log_email?: InputMaybe<Scalars['Boolean']['input']>;
  send_lead_routing_email?: InputMaybe<Scalars['Boolean']['input']>;
  sendgrid_email_domain?: InputMaybe<Scalars['String']['input']>;
  sendgrid_email_domain_id?: InputMaybe<Scalars['Int']['input']>;
  sequence?: InputMaybe<SequenceCreateNestedManyWithoutOrganzationInput>;
  SequenceCriteriaConditions?: InputMaybe<SequenceCriteriaConditionCreateNestedManyWithoutOrganizationInput>;
  SequenceDashboardViews?: InputMaybe<SequenceDashboardViewCreateNestedManyWithoutOrganizationInput>;
  SequenceEntryCriterias?: InputMaybe<SequenceEntryCriteriaCreateNestedManyWithoutOrganizationInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutOrganizationInput>;
  SequenceSteps?: InputMaybe<SequenceStepCreateNestedManyWithoutOrganizationInput>;
  set_goal?: InputMaybe<Scalars['Float']['input']>;
  short_resting?: InputMaybe<Scalars['Int']['input']>;
  show_calendar_options?: InputMaybe<Scalars['Boolean']['input']>;
  show_contract_duration_sales?: InputMaybe<Scalars['Boolean']['input']>;
  show_lead_source_to_rep?: InputMaybe<Scalars['Boolean']['input']>;
  show_nocontact_button?: InputMaybe<Scalars['Boolean']['input']>;
  show_payment_terms_sales?: InputMaybe<Scalars['Boolean']['input']>;
  show_rep_all_leads?: InputMaybe<Scalars['Boolean']['input']>;
  show_rep_my_leads?: InputMaybe<Scalars['Boolean']['input']>;
  show_system_view?: InputMaybe<Scalars['Boolean']['input']>;
  sites?: InputMaybe<SiteCreateNestedManyWithoutOrganizationInput>;
  sms_opt_in_dialog?: InputMaybe<Scalars['String']['input']>;
  SMSItems?: InputMaybe<SmsItemCreateNestedManyWithoutOrganizationInput>;
  SnoozeTrackings?: InputMaybe<SnoozeTrackingCreateNestedManyWithoutOrganizationInput>;
  StepActions?: InputMaybe<StepActionCreateNestedManyWithoutOrganizationInput>;
  StepConditions?: InputMaybe<StepConditionCreateNestedManyWithoutOrganizationInput>;
  teams?: InputMaybe<TeamCreateNestedManyWithoutOrganizationInput>;
  Template?: InputMaybe<TemplateCreateNestedManyWithoutOrganizationInput>;
  TemplateVersions?: InputMaybe<TemplateVersionCreateNestedManyWithoutOrganizationInput>;
  timekit_id?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  transfer_timeout?: InputMaybe<Scalars['Int']['input']>;
  TransferAttempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutOrganizationInput>;
  twilio_numbers?: InputMaybe<TwilioNumberCreateNestedManyWithoutOrganizationInput>;
  TwilioFeedbacks?: InputMaybe<TwilioFeedbackCreateNestedManyWithoutOrganizationInput>;
  TwilioInfo?: InputMaybe<TwilioInfoCreateNestedOneWithoutOrganzationInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  use_revenue_planning?: InputMaybe<Scalars['Boolean']['input']>;
  UserCustomFieldFilters?: InputMaybe<UserCustomFieldFilterCreateNestedManyWithoutOrganizationInput>;
  users?: InputMaybe<UserCreateNestedManyWithoutOrganizationInput>;
  win_rep_day_goal?: InputMaybe<Scalars['Float']['input']>;
};

export type OrganizationCreateWithoutStepActionsInput = {
  ActivityMonitorMultiplier?: InputMaybe<ActivityMonitorMultiplierCreateNestedManyWithoutOrganizationInput>;
  algoFactor?: InputMaybe<AlgoFactorCreateNestedManyWithoutOrganizationInput>;
  allow_calendar_invite?: InputMaybe<Scalars['Boolean']['input']>;
  allow_remove_step_action?: InputMaybe<Scalars['Boolean']['input']>;
  allow_snooze_step_action?: InputMaybe<Scalars['Boolean']['input']>;
  always_record?: InputMaybe<Scalars['Boolean']['input']>;
  arv_initial_held_demos_generated_by_sdr_weight?: InputMaybe<Scalars['Float']['input']>;
  AssociationHistories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutOrganizationInput>;
  auto_call_interval?: InputMaybe<Scalars['Int']['input']>;
  auto_dial?: InputMaybe<Scalars['Boolean']['input']>;
  automated_campaigns?: InputMaybe<AutomatedCampaignCreateNestedManyWithoutOrganizationInput>;
  automatic_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  AutoTransferSelections?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutOrganizationInput>;
  Availablities?: InputMaybe<AvailabilityCreateNestedManyWithoutOrganizationInput>;
  AverageOrderValue?: InputMaybe<AverageOrderValueCreateNestedManyWithoutOrganizationInput>;
  best_action_ever_multiplier?: InputMaybe<Scalars['Float']['input']>;
  best_metrics?: InputMaybe<BestMetricRecordCreateNestedManyWithoutOrganizationInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptCreateNestedManyWithoutOrganizationInput>;
  callMeNows?: InputMaybe<CallMeNowCreateNestedManyWithoutOrganizationInput>;
  CallMultiplier?: InputMaybe<CallMultiplierCreateNestedManyWithoutOrganizationInput>;
  CallSharings?: InputMaybe<CallSharingCreateNestedManyWithoutOrganizationInput>;
  can_edit_sdr_transfer_rules?: InputMaybe<Scalars['Boolean']['input']>;
  claim_rule?: InputMaybe<Scalars['Int']['input']>;
  concession?: InputMaybe<ConcessionCreateNestedManyWithoutOrganizationInput>;
  conference_region?: InputMaybe<ConferenceRegion>;
  Conferences?: InputMaybe<ConferenceCreateNestedManyWithoutOrganizationInput>;
  ContactRequests?: InputMaybe<ContactRequestCreateNestedManyWithoutOrganizationInput>;
  Contacts?: InputMaybe<ContactCreateNestedManyWithoutOrganizationInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  custom_fields?: InputMaybe<CustomFieldCreateNestedManyWithoutOrganizationInput>;
  CustomObject?: InputMaybe<CustomObjectCreateNestedManyWithoutOrganizationInput>;
  CustomObjectField?: InputMaybe<CustomObjectFieldCreateNestedManyWithoutOrganizationInput>;
  CustomObjectFieldForeignKey?: InputMaybe<CustomObjectFieldForeignKeyCreateNestedManyWithoutOrganizationInput>;
  CustomObjectRecord?: InputMaybe<CustomObjectRecordCreateNestedManyWithoutOrganizationInput>;
  CustomObjectRow?: InputMaybe<CustomObjectRowCreateNestedManyWithoutOrganizationInput>;
  CustomQueueItems?: InputMaybe<CustomQueueItemCreateNestedManyWithoutOrganizationInput>;
  DashboardColumnOptions?: InputMaybe<DashboardColumnOptionCreateNestedManyWithoutOrganizationInput>;
  DashboardLeadFilters?: InputMaybe<DashboardLeadFilterCreateNestedManyWithoutOrganizationInput>;
  DashboardRepFilters?: InputMaybe<DashboardRepFilterCreateNestedManyWithoutOrganizationInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewCreateNestedManyWithoutOrganizationInput>;
  day_to_close?: InputMaybe<Scalars['Int']['input']>;
  default_demo_time_duration?: InputMaybe<Scalars['Int']['input']>;
  demo_held_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_not_held_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_not_held_call_back_short_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_prep_time_minutes?: InputMaybe<Scalars['Int']['input']>;
  dev_account?: InputMaybe<Scalars['Boolean']['input']>;
  dial_rule?: InputMaybe<Scalars['Int']['input']>;
  DispositionMultiplier?: InputMaybe<DispositionMultiplierCreateNestedManyWithoutOrganizationInput>;
  dispositions?: InputMaybe<DispositionCreateNestedManyWithoutOrganizationInput>;
  double_booking?: InputMaybe<Scalars['Boolean']['input']>;
  dynamic_date_operators?: InputMaybe<Scalars['Boolean']['input']>;
  edge_servers?: InputMaybe<OrganizationCreateedge_ServersInput>;
  email_conflict_notification_email_list?: InputMaybe<OrganizationCreateemail_Conflict_Notification_Email_ListInput>;
  EmailItems?: InputMaybe<EmailItemCreateNestedManyWithoutOrganizationInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordCreateNestedManyWithoutOrganizationInput>;
  EmailThreads?: InputMaybe<EmailThreadCreateNestedManyWithoutOrganizationInput>;
  enable_modified_metrics?: InputMaybe<Scalars['Boolean']['input']>;
  enable_pay_period_view?: InputMaybe<Scalars['Boolean']['input']>;
  enable_time_constraint?: InputMaybe<Scalars['Boolean']['input']>;
  error_log_email_address?: InputMaybe<OrganizationCreateerror_Log_Email_AddressInput>;
  estimated_average_num_of_weekly_overbooked_demo_slots?: InputMaybe<Scalars['Int']['input']>;
  FolderPermissions?: InputMaybe<FolderPermissionCreateNestedManyWithoutOrganizationInput>;
  Folders?: InputMaybe<FolderCreateNestedManyWithoutOrganizationInput>;
  FutureBookingSetting?: InputMaybe<FutureBookingSettingCreateNestedOneWithoutOrganizationInput>;
  GlobalTransferRule?: InputMaybe<GlobalTransferRuleCreateNestedManyWithoutOrganizationInput>;
  held_demos_per_cc_connect?: InputMaybe<Scalars['Float']['input']>;
  helpful_links?: InputMaybe<OrganizationCreatehelpful_LinksInput>;
  HingePointMultiplier?: InputMaybe<HingePointMultiplierCreateNestedManyWithoutOrganizationInput>;
  hold_goal?: InputMaybe<Scalars['Float']['input']>;
  hubspot_credential?: InputMaybe<HubSpotCredentialCreateNestedOneWithoutOrganizationInput>;
  hubspot_last_sync_date?: InputMaybe<Scalars['DateTime']['input']>;
  hubspot_white_list?: InputMaybe<HubSpotWhitelistCreateNestedManyWithoutOrganizationInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  inbound_close_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_dm_connect_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_lt_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_revenue_value_of_sale?: InputMaybe<Scalars['Float']['input']>;
  inbound_scheduled_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_set_rate?: InputMaybe<Scalars['Float']['input']>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventCreateNestedManyWithoutOrganizationInput>;
  InboundConciergeWidget?: InputMaybe<InboundConciergeWidgetCreateNestedManyWithoutOrganizationInput>;
  industry_options?: InputMaybe<IndustryOptionCreateNestedManyWithoutOrganizationInput>;
  initial_sdr_sets_reps_day?: InputMaybe<Scalars['Float']['input']>;
  integration_error_logs?: InputMaybe<IntegrationErrorLogCreateNestedManyWithoutOrganizationInput>;
  integration_mappings?: InputMaybe<IntegrationMappingCreateNestedManyWithoutOrganizationInput>;
  IntegrationMappingCustomObject?: InputMaybe<IntegrationMappingCustomObjectCreateNestedManyWithoutOrganizationInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogCreateNestedManyWithoutOrganizationInput>;
  IntegrationReadOnlyObject?: InputMaybe<IntegrationReadOnlyObjectCreateNestedManyWithoutOrganizationInput>;
  international?: InputMaybe<Scalars['Boolean']['input']>;
  is_email_domain_verified?: InputMaybe<Scalars['Boolean']['input']>;
  is_salesforce_sandbox?: InputMaybe<Scalars['Boolean']['input']>;
  last_lead_value_compute_time?: InputMaybe<Scalars['DateTime']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutOrganizationInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryCreateNestedManyWithoutOrganizationInput>;
  lead_intent?: InputMaybe<LeadIntentCreateNestedManyWithoutOrganizationInput>;
  lead_release_same_rep_delay_rule?: InputMaybe<Scalars['Int']['input']>;
  lead_routing_email_list?: InputMaybe<OrganizationCreatelead_Routing_Email_ListInput>;
  lead_routing_rules?: InputMaybe<RuleCreateNestedManyWithoutOrganizationInput>;
  LeadCustomObjectRowAssociation?: InputMaybe<LeadCustomObjectRowAssociationCreateNestedManyWithoutOrganizationInput>;
  LeadFieldMultiplier?: InputMaybe<LeadFieldMultiplierCreateNestedManyWithoutOrganizationInput>;
  LeadFieldMultiplierCustom?: InputMaybe<LeadFieldMultiplierCustomCreateNestedManyWithoutOrganizationInput>;
  leads?: InputMaybe<LeadCreateNestedManyWithoutOrganizationInput>;
  leadsource_options?: InputMaybe<LeadSourceOptionCreateNestedManyWithoutOrganizationInput>;
  LeadValue?: InputMaybe<LeadValueCreateNestedManyWithoutOrganizationInput>;
  LifeTimeValueMultiplier?: InputMaybe<LifeTimeValueMultiplierCreateNestedManyWithoutOrganizationInput>;
  LiveTransferSetting?: InputMaybe<LiveTransferSettingCreateNestedOneWithoutOrganizationInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusCreateNestedManyWithoutOrganizationInput>;
  ltv_multiplier_custom_fields?: InputMaybe<LifeTimeValueMultiplierCustomCreateNestedManyWithoutOrganizationInput>;
  made_contact_rule?: InputMaybe<Scalars['Int']['input']>;
  make_sale_address_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_business_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_city_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_country_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_first_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_industry_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_last_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_lead_source_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_primary_email_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_primary_phone_number_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_state_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_sub_industry_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_zip_required?: InputMaybe<Scalars['Boolean']['input']>;
  maxium_day_lag_set_to_schedule?: InputMaybe<Scalars['Int']['input']>;
  mrr?: InputMaybe<Scalars['Int']['input']>;
  mrr_label?: InputMaybe<Scalars['String']['input']>;
  mrr_required_on_sale?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  NoteItems?: InputMaybe<NoteItemCreateNestedManyWithoutOrganizationInput>;
  Notifications?: InputMaybe<NotificationCreateNestedManyWithoutOrganizationInput>;
  onboardComplete?: InputMaybe<Scalars['Boolean']['input']>;
  Opportunity?: InputMaybe<SfOpportunityCreateNestedManyWithoutOrganizationInput>;
  organization_holidays?: InputMaybe<OrganizationHolidayCreateNestedManyWithoutOrganizationInput>;
  organization_hours?: InputMaybe<OrganizationHourCreateNestedManyWithoutOrganizationInput>;
  OrganizationPaymentSetting?: InputMaybe<OrganizationPaymentSettingsCreateNestedOneWithoutOrganizationInput>;
  OrganizationPaymentSettings?: InputMaybe<OrganizationPaymentSettingsCreateNestedOneWithoutOrganizationsInput>;
  orgCalendarInviteMetadata?: InputMaybe<OrgCalendarInviteMetadataCreateNestedManyWithoutOrganizationInput>;
  OrgDefaultPipelinePerecentValue?: InputMaybe<OrgDefaultPipelinePerecentValueCreateNestedOneWithoutOrganizationInput>;
  OrgHubSpotSync?: InputMaybe<OrgHubSpotSyncCreateNestedOneWithoutOrganizationInput>;
  OrgNoEventDate?: InputMaybe<OrgNoEventDateCreateNestedManyWithoutOrganizationInput>;
  OrgSalesforceSync?: InputMaybe<OrgSalesforceSyncCreateNestedOneWithoutOrganizationInput>;
  OrgTimeslotAvailability?: InputMaybe<OrgTimeslotAvailabilityCreateNestedManyWithoutOrganizationInput>;
  outbound_close_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_dm_connect_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_lt_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_revenue_value_of_sale?: InputMaybe<Scalars['Float']['input']>;
  outbound_scheduled_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_set_rate?: InputMaybe<Scalars['Float']['input']>;
  PandadocData?: InputMaybe<PandadocDataCreateNestedOneWithoutOrganizationInput>;
  PandaDocTemplate?: InputMaybe<PandaDocTemplateCreateNestedManyWithoutOrganizationInput>;
  participant_jitter_buffer_size?: InputMaybe<JitterBufferSize>;
  preferred_goal_setting?: InputMaybe<Goalsetting>;
  prepayment?: InputMaybe<Scalars['Boolean']['input']>;
  ProductChangeLog?: InputMaybe<ProductChangeLogCreateNestedManyWithoutOrganizationInput>;
  products?: InputMaybe<ProductCreateNestedManyWithoutOrganizationInput>;
  re_cold_call_hour_delay?: InputMaybe<Scalars['Float']['input']>;
  recordingStatus?: InputMaybe<RecordingStatus>;
  release_lead_resting?: InputMaybe<Scalars['Int']['input']>;
  rep_claim_limit?: InputMaybe<Scalars['Int']['input']>;
  rep_lead_allotment?: InputMaybe<Scalars['Int']['input']>;
  rep_level_call_transfer?: InputMaybe<Scalars['Boolean']['input']>;
  reps_can_bulk_unassign?: InputMaybe<Scalars['Boolean']['input']>;
  reps_can_view_all_notes?: InputMaybe<Scalars['Boolean']['input']>;
  require_sale_notes?: InputMaybe<Scalars['Boolean']['input']>;
  resting_after_skipped_CC?: InputMaybe<Scalars['Int']['input']>;
  resting_period?: InputMaybe<Scalars['Int']['input']>;
  resting_period_after_unsuccessful_cold_call?: InputMaybe<Scalars['Float']['input']>;
  resting_period_after_unsuccessful_demo?: InputMaybe<Scalars['Float']['input']>;
  revenue_plan?: InputMaybe<RevenuePlanItemCreateNestedManyWithoutOrganizationInput>;
  Routings?: InputMaybe<RoutingCreateNestedManyWithoutOrganizationInput>;
  sale_field_options?: InputMaybe<SaleFieldOptionCreateNestedManyWithoutOrganizationInput>;
  sale_notification_email_list?: InputMaybe<OrganizationCreatesale_Notification_Email_ListInput>;
  SaleConfig?: InputMaybe<SaleConfigCreateNestedOneWithoutOrganizationInput>;
  SaleConfigPage?: InputMaybe<SaleConfigPageCreateNestedManyWithoutOrganizationInput>;
  SaleConfigSection?: InputMaybe<SaleConfigSectionCreateNestedManyWithoutOrganizationInput>;
  SaleCycles?: InputMaybe<SaleCycleCreateNestedManyWithoutOrganizationInput>;
  SaleItems?: InputMaybe<SaleItemCreateNestedManyWithoutOrganizationInput>;
  sales?: InputMaybe<SaleCreateNestedManyWithoutOrganizationInput>;
  salesforce_domain?: InputMaybe<Scalars['String']['input']>;
  salesforce_last_sync_date?: InputMaybe<Scalars['DateTime']['input']>;
  SalesForceColumn?: InputMaybe<SalesForceColumnCreateNestedManyWithoutOrganizationInput>;
  salesForceData?: InputMaybe<SalesForceDataCreateNestedOneWithoutOrganizationInput>;
  ScheduleItems?: InputMaybe<ScheduleItemCreateNestedManyWithoutOrganizationInput>;
  self_sourcing_rule?: InputMaybe<Scalars['Int']['input']>;
  send_error_log_email?: InputMaybe<Scalars['Boolean']['input']>;
  send_lead_routing_email?: InputMaybe<Scalars['Boolean']['input']>;
  sendgrid_email_domain?: InputMaybe<Scalars['String']['input']>;
  sendgrid_email_domain_id?: InputMaybe<Scalars['Int']['input']>;
  sequence?: InputMaybe<SequenceCreateNestedManyWithoutOrganzationInput>;
  SequenceCriteriaConditions?: InputMaybe<SequenceCriteriaConditionCreateNestedManyWithoutOrganizationInput>;
  SequenceDashboardViews?: InputMaybe<SequenceDashboardViewCreateNestedManyWithoutOrganizationInput>;
  SequenceEntryCriterias?: InputMaybe<SequenceEntryCriteriaCreateNestedManyWithoutOrganizationInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutOrganizationInput>;
  SequenceSteps?: InputMaybe<SequenceStepCreateNestedManyWithoutOrganizationInput>;
  set_goal?: InputMaybe<Scalars['Float']['input']>;
  short_resting?: InputMaybe<Scalars['Int']['input']>;
  show_calendar_options?: InputMaybe<Scalars['Boolean']['input']>;
  show_contract_duration_sales?: InputMaybe<Scalars['Boolean']['input']>;
  show_lead_source_to_rep?: InputMaybe<Scalars['Boolean']['input']>;
  show_nocontact_button?: InputMaybe<Scalars['Boolean']['input']>;
  show_payment_terms_sales?: InputMaybe<Scalars['Boolean']['input']>;
  show_rep_all_leads?: InputMaybe<Scalars['Boolean']['input']>;
  show_rep_my_leads?: InputMaybe<Scalars['Boolean']['input']>;
  show_system_view?: InputMaybe<Scalars['Boolean']['input']>;
  sites?: InputMaybe<SiteCreateNestedManyWithoutOrganizationInput>;
  sms_opt_in_dialog?: InputMaybe<Scalars['String']['input']>;
  SMSItems?: InputMaybe<SmsItemCreateNestedManyWithoutOrganizationInput>;
  SnoozeTrackings?: InputMaybe<SnoozeTrackingCreateNestedManyWithoutOrganizationInput>;
  StepActionLogs?: InputMaybe<StepActionLogCreateNestedManyWithoutOrganizationInput>;
  StepConditions?: InputMaybe<StepConditionCreateNestedManyWithoutOrganizationInput>;
  teams?: InputMaybe<TeamCreateNestedManyWithoutOrganizationInput>;
  Template?: InputMaybe<TemplateCreateNestedManyWithoutOrganizationInput>;
  TemplateVersions?: InputMaybe<TemplateVersionCreateNestedManyWithoutOrganizationInput>;
  timekit_id?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  transfer_timeout?: InputMaybe<Scalars['Int']['input']>;
  TransferAttempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutOrganizationInput>;
  twilio_numbers?: InputMaybe<TwilioNumberCreateNestedManyWithoutOrganizationInput>;
  TwilioFeedbacks?: InputMaybe<TwilioFeedbackCreateNestedManyWithoutOrganizationInput>;
  TwilioInfo?: InputMaybe<TwilioInfoCreateNestedOneWithoutOrganzationInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  use_revenue_planning?: InputMaybe<Scalars['Boolean']['input']>;
  UserCustomFieldFilters?: InputMaybe<UserCustomFieldFilterCreateNestedManyWithoutOrganizationInput>;
  users?: InputMaybe<UserCreateNestedManyWithoutOrganizationInput>;
  win_rep_day_goal?: InputMaybe<Scalars['Float']['input']>;
};

export type OrganizationCreateWithoutStepConditionsInput = {
  ActivityMonitorMultiplier?: InputMaybe<ActivityMonitorMultiplierCreateNestedManyWithoutOrganizationInput>;
  algoFactor?: InputMaybe<AlgoFactorCreateNestedManyWithoutOrganizationInput>;
  allow_calendar_invite?: InputMaybe<Scalars['Boolean']['input']>;
  allow_remove_step_action?: InputMaybe<Scalars['Boolean']['input']>;
  allow_snooze_step_action?: InputMaybe<Scalars['Boolean']['input']>;
  always_record?: InputMaybe<Scalars['Boolean']['input']>;
  arv_initial_held_demos_generated_by_sdr_weight?: InputMaybe<Scalars['Float']['input']>;
  AssociationHistories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutOrganizationInput>;
  auto_call_interval?: InputMaybe<Scalars['Int']['input']>;
  auto_dial?: InputMaybe<Scalars['Boolean']['input']>;
  automated_campaigns?: InputMaybe<AutomatedCampaignCreateNestedManyWithoutOrganizationInput>;
  automatic_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  AutoTransferSelections?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutOrganizationInput>;
  Availablities?: InputMaybe<AvailabilityCreateNestedManyWithoutOrganizationInput>;
  AverageOrderValue?: InputMaybe<AverageOrderValueCreateNestedManyWithoutOrganizationInput>;
  best_action_ever_multiplier?: InputMaybe<Scalars['Float']['input']>;
  best_metrics?: InputMaybe<BestMetricRecordCreateNestedManyWithoutOrganizationInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptCreateNestedManyWithoutOrganizationInput>;
  callMeNows?: InputMaybe<CallMeNowCreateNestedManyWithoutOrganizationInput>;
  CallMultiplier?: InputMaybe<CallMultiplierCreateNestedManyWithoutOrganizationInput>;
  CallSharings?: InputMaybe<CallSharingCreateNestedManyWithoutOrganizationInput>;
  can_edit_sdr_transfer_rules?: InputMaybe<Scalars['Boolean']['input']>;
  claim_rule?: InputMaybe<Scalars['Int']['input']>;
  concession?: InputMaybe<ConcessionCreateNestedManyWithoutOrganizationInput>;
  conference_region?: InputMaybe<ConferenceRegion>;
  Conferences?: InputMaybe<ConferenceCreateNestedManyWithoutOrganizationInput>;
  ContactRequests?: InputMaybe<ContactRequestCreateNestedManyWithoutOrganizationInput>;
  Contacts?: InputMaybe<ContactCreateNestedManyWithoutOrganizationInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  custom_fields?: InputMaybe<CustomFieldCreateNestedManyWithoutOrganizationInput>;
  CustomObject?: InputMaybe<CustomObjectCreateNestedManyWithoutOrganizationInput>;
  CustomObjectField?: InputMaybe<CustomObjectFieldCreateNestedManyWithoutOrganizationInput>;
  CustomObjectFieldForeignKey?: InputMaybe<CustomObjectFieldForeignKeyCreateNestedManyWithoutOrganizationInput>;
  CustomObjectRecord?: InputMaybe<CustomObjectRecordCreateNestedManyWithoutOrganizationInput>;
  CustomObjectRow?: InputMaybe<CustomObjectRowCreateNestedManyWithoutOrganizationInput>;
  CustomQueueItems?: InputMaybe<CustomQueueItemCreateNestedManyWithoutOrganizationInput>;
  DashboardColumnOptions?: InputMaybe<DashboardColumnOptionCreateNestedManyWithoutOrganizationInput>;
  DashboardLeadFilters?: InputMaybe<DashboardLeadFilterCreateNestedManyWithoutOrganizationInput>;
  DashboardRepFilters?: InputMaybe<DashboardRepFilterCreateNestedManyWithoutOrganizationInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewCreateNestedManyWithoutOrganizationInput>;
  day_to_close?: InputMaybe<Scalars['Int']['input']>;
  default_demo_time_duration?: InputMaybe<Scalars['Int']['input']>;
  demo_held_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_not_held_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_not_held_call_back_short_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_prep_time_minutes?: InputMaybe<Scalars['Int']['input']>;
  dev_account?: InputMaybe<Scalars['Boolean']['input']>;
  dial_rule?: InputMaybe<Scalars['Int']['input']>;
  DispositionMultiplier?: InputMaybe<DispositionMultiplierCreateNestedManyWithoutOrganizationInput>;
  dispositions?: InputMaybe<DispositionCreateNestedManyWithoutOrganizationInput>;
  double_booking?: InputMaybe<Scalars['Boolean']['input']>;
  dynamic_date_operators?: InputMaybe<Scalars['Boolean']['input']>;
  edge_servers?: InputMaybe<OrganizationCreateedge_ServersInput>;
  email_conflict_notification_email_list?: InputMaybe<OrganizationCreateemail_Conflict_Notification_Email_ListInput>;
  EmailItems?: InputMaybe<EmailItemCreateNestedManyWithoutOrganizationInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordCreateNestedManyWithoutOrganizationInput>;
  EmailThreads?: InputMaybe<EmailThreadCreateNestedManyWithoutOrganizationInput>;
  enable_modified_metrics?: InputMaybe<Scalars['Boolean']['input']>;
  enable_pay_period_view?: InputMaybe<Scalars['Boolean']['input']>;
  enable_time_constraint?: InputMaybe<Scalars['Boolean']['input']>;
  error_log_email_address?: InputMaybe<OrganizationCreateerror_Log_Email_AddressInput>;
  estimated_average_num_of_weekly_overbooked_demo_slots?: InputMaybe<Scalars['Int']['input']>;
  FolderPermissions?: InputMaybe<FolderPermissionCreateNestedManyWithoutOrganizationInput>;
  Folders?: InputMaybe<FolderCreateNestedManyWithoutOrganizationInput>;
  FutureBookingSetting?: InputMaybe<FutureBookingSettingCreateNestedOneWithoutOrganizationInput>;
  GlobalTransferRule?: InputMaybe<GlobalTransferRuleCreateNestedManyWithoutOrganizationInput>;
  held_demos_per_cc_connect?: InputMaybe<Scalars['Float']['input']>;
  helpful_links?: InputMaybe<OrganizationCreatehelpful_LinksInput>;
  HingePointMultiplier?: InputMaybe<HingePointMultiplierCreateNestedManyWithoutOrganizationInput>;
  hold_goal?: InputMaybe<Scalars['Float']['input']>;
  hubspot_credential?: InputMaybe<HubSpotCredentialCreateNestedOneWithoutOrganizationInput>;
  hubspot_last_sync_date?: InputMaybe<Scalars['DateTime']['input']>;
  hubspot_white_list?: InputMaybe<HubSpotWhitelistCreateNestedManyWithoutOrganizationInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  inbound_close_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_dm_connect_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_lt_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_revenue_value_of_sale?: InputMaybe<Scalars['Float']['input']>;
  inbound_scheduled_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_set_rate?: InputMaybe<Scalars['Float']['input']>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventCreateNestedManyWithoutOrganizationInput>;
  InboundConciergeWidget?: InputMaybe<InboundConciergeWidgetCreateNestedManyWithoutOrganizationInput>;
  industry_options?: InputMaybe<IndustryOptionCreateNestedManyWithoutOrganizationInput>;
  initial_sdr_sets_reps_day?: InputMaybe<Scalars['Float']['input']>;
  integration_error_logs?: InputMaybe<IntegrationErrorLogCreateNestedManyWithoutOrganizationInput>;
  integration_mappings?: InputMaybe<IntegrationMappingCreateNestedManyWithoutOrganizationInput>;
  IntegrationMappingCustomObject?: InputMaybe<IntegrationMappingCustomObjectCreateNestedManyWithoutOrganizationInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogCreateNestedManyWithoutOrganizationInput>;
  IntegrationReadOnlyObject?: InputMaybe<IntegrationReadOnlyObjectCreateNestedManyWithoutOrganizationInput>;
  international?: InputMaybe<Scalars['Boolean']['input']>;
  is_email_domain_verified?: InputMaybe<Scalars['Boolean']['input']>;
  is_salesforce_sandbox?: InputMaybe<Scalars['Boolean']['input']>;
  last_lead_value_compute_time?: InputMaybe<Scalars['DateTime']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutOrganizationInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryCreateNestedManyWithoutOrganizationInput>;
  lead_intent?: InputMaybe<LeadIntentCreateNestedManyWithoutOrganizationInput>;
  lead_release_same_rep_delay_rule?: InputMaybe<Scalars['Int']['input']>;
  lead_routing_email_list?: InputMaybe<OrganizationCreatelead_Routing_Email_ListInput>;
  lead_routing_rules?: InputMaybe<RuleCreateNestedManyWithoutOrganizationInput>;
  LeadCustomObjectRowAssociation?: InputMaybe<LeadCustomObjectRowAssociationCreateNestedManyWithoutOrganizationInput>;
  LeadFieldMultiplier?: InputMaybe<LeadFieldMultiplierCreateNestedManyWithoutOrganizationInput>;
  LeadFieldMultiplierCustom?: InputMaybe<LeadFieldMultiplierCustomCreateNestedManyWithoutOrganizationInput>;
  leads?: InputMaybe<LeadCreateNestedManyWithoutOrganizationInput>;
  leadsource_options?: InputMaybe<LeadSourceOptionCreateNestedManyWithoutOrganizationInput>;
  LeadValue?: InputMaybe<LeadValueCreateNestedManyWithoutOrganizationInput>;
  LifeTimeValueMultiplier?: InputMaybe<LifeTimeValueMultiplierCreateNestedManyWithoutOrganizationInput>;
  LiveTransferSetting?: InputMaybe<LiveTransferSettingCreateNestedOneWithoutOrganizationInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusCreateNestedManyWithoutOrganizationInput>;
  ltv_multiplier_custom_fields?: InputMaybe<LifeTimeValueMultiplierCustomCreateNestedManyWithoutOrganizationInput>;
  made_contact_rule?: InputMaybe<Scalars['Int']['input']>;
  make_sale_address_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_business_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_city_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_country_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_first_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_industry_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_last_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_lead_source_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_primary_email_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_primary_phone_number_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_state_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_sub_industry_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_zip_required?: InputMaybe<Scalars['Boolean']['input']>;
  maxium_day_lag_set_to_schedule?: InputMaybe<Scalars['Int']['input']>;
  mrr?: InputMaybe<Scalars['Int']['input']>;
  mrr_label?: InputMaybe<Scalars['String']['input']>;
  mrr_required_on_sale?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  NoteItems?: InputMaybe<NoteItemCreateNestedManyWithoutOrganizationInput>;
  Notifications?: InputMaybe<NotificationCreateNestedManyWithoutOrganizationInput>;
  onboardComplete?: InputMaybe<Scalars['Boolean']['input']>;
  Opportunity?: InputMaybe<SfOpportunityCreateNestedManyWithoutOrganizationInput>;
  organization_holidays?: InputMaybe<OrganizationHolidayCreateNestedManyWithoutOrganizationInput>;
  organization_hours?: InputMaybe<OrganizationHourCreateNestedManyWithoutOrganizationInput>;
  OrganizationPaymentSetting?: InputMaybe<OrganizationPaymentSettingsCreateNestedOneWithoutOrganizationInput>;
  OrganizationPaymentSettings?: InputMaybe<OrganizationPaymentSettingsCreateNestedOneWithoutOrganizationsInput>;
  orgCalendarInviteMetadata?: InputMaybe<OrgCalendarInviteMetadataCreateNestedManyWithoutOrganizationInput>;
  OrgDefaultPipelinePerecentValue?: InputMaybe<OrgDefaultPipelinePerecentValueCreateNestedOneWithoutOrganizationInput>;
  OrgHubSpotSync?: InputMaybe<OrgHubSpotSyncCreateNestedOneWithoutOrganizationInput>;
  OrgNoEventDate?: InputMaybe<OrgNoEventDateCreateNestedManyWithoutOrganizationInput>;
  OrgSalesforceSync?: InputMaybe<OrgSalesforceSyncCreateNestedOneWithoutOrganizationInput>;
  OrgTimeslotAvailability?: InputMaybe<OrgTimeslotAvailabilityCreateNestedManyWithoutOrganizationInput>;
  outbound_close_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_dm_connect_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_lt_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_revenue_value_of_sale?: InputMaybe<Scalars['Float']['input']>;
  outbound_scheduled_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_set_rate?: InputMaybe<Scalars['Float']['input']>;
  PandadocData?: InputMaybe<PandadocDataCreateNestedOneWithoutOrganizationInput>;
  PandaDocTemplate?: InputMaybe<PandaDocTemplateCreateNestedManyWithoutOrganizationInput>;
  participant_jitter_buffer_size?: InputMaybe<JitterBufferSize>;
  preferred_goal_setting?: InputMaybe<Goalsetting>;
  prepayment?: InputMaybe<Scalars['Boolean']['input']>;
  ProductChangeLog?: InputMaybe<ProductChangeLogCreateNestedManyWithoutOrganizationInput>;
  products?: InputMaybe<ProductCreateNestedManyWithoutOrganizationInput>;
  re_cold_call_hour_delay?: InputMaybe<Scalars['Float']['input']>;
  recordingStatus?: InputMaybe<RecordingStatus>;
  release_lead_resting?: InputMaybe<Scalars['Int']['input']>;
  rep_claim_limit?: InputMaybe<Scalars['Int']['input']>;
  rep_lead_allotment?: InputMaybe<Scalars['Int']['input']>;
  rep_level_call_transfer?: InputMaybe<Scalars['Boolean']['input']>;
  reps_can_bulk_unassign?: InputMaybe<Scalars['Boolean']['input']>;
  reps_can_view_all_notes?: InputMaybe<Scalars['Boolean']['input']>;
  require_sale_notes?: InputMaybe<Scalars['Boolean']['input']>;
  resting_after_skipped_CC?: InputMaybe<Scalars['Int']['input']>;
  resting_period?: InputMaybe<Scalars['Int']['input']>;
  resting_period_after_unsuccessful_cold_call?: InputMaybe<Scalars['Float']['input']>;
  resting_period_after_unsuccessful_demo?: InputMaybe<Scalars['Float']['input']>;
  revenue_plan?: InputMaybe<RevenuePlanItemCreateNestedManyWithoutOrganizationInput>;
  Routings?: InputMaybe<RoutingCreateNestedManyWithoutOrganizationInput>;
  sale_field_options?: InputMaybe<SaleFieldOptionCreateNestedManyWithoutOrganizationInput>;
  sale_notification_email_list?: InputMaybe<OrganizationCreatesale_Notification_Email_ListInput>;
  SaleConfig?: InputMaybe<SaleConfigCreateNestedOneWithoutOrganizationInput>;
  SaleConfigPage?: InputMaybe<SaleConfigPageCreateNestedManyWithoutOrganizationInput>;
  SaleConfigSection?: InputMaybe<SaleConfigSectionCreateNestedManyWithoutOrganizationInput>;
  SaleCycles?: InputMaybe<SaleCycleCreateNestedManyWithoutOrganizationInput>;
  SaleItems?: InputMaybe<SaleItemCreateNestedManyWithoutOrganizationInput>;
  sales?: InputMaybe<SaleCreateNestedManyWithoutOrganizationInput>;
  salesforce_domain?: InputMaybe<Scalars['String']['input']>;
  salesforce_last_sync_date?: InputMaybe<Scalars['DateTime']['input']>;
  SalesForceColumn?: InputMaybe<SalesForceColumnCreateNestedManyWithoutOrganizationInput>;
  salesForceData?: InputMaybe<SalesForceDataCreateNestedOneWithoutOrganizationInput>;
  ScheduleItems?: InputMaybe<ScheduleItemCreateNestedManyWithoutOrganizationInput>;
  self_sourcing_rule?: InputMaybe<Scalars['Int']['input']>;
  send_error_log_email?: InputMaybe<Scalars['Boolean']['input']>;
  send_lead_routing_email?: InputMaybe<Scalars['Boolean']['input']>;
  sendgrid_email_domain?: InputMaybe<Scalars['String']['input']>;
  sendgrid_email_domain_id?: InputMaybe<Scalars['Int']['input']>;
  sequence?: InputMaybe<SequenceCreateNestedManyWithoutOrganzationInput>;
  SequenceCriteriaConditions?: InputMaybe<SequenceCriteriaConditionCreateNestedManyWithoutOrganizationInput>;
  SequenceDashboardViews?: InputMaybe<SequenceDashboardViewCreateNestedManyWithoutOrganizationInput>;
  SequenceEntryCriterias?: InputMaybe<SequenceEntryCriteriaCreateNestedManyWithoutOrganizationInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutOrganizationInput>;
  SequenceSteps?: InputMaybe<SequenceStepCreateNestedManyWithoutOrganizationInput>;
  set_goal?: InputMaybe<Scalars['Float']['input']>;
  short_resting?: InputMaybe<Scalars['Int']['input']>;
  show_calendar_options?: InputMaybe<Scalars['Boolean']['input']>;
  show_contract_duration_sales?: InputMaybe<Scalars['Boolean']['input']>;
  show_lead_source_to_rep?: InputMaybe<Scalars['Boolean']['input']>;
  show_nocontact_button?: InputMaybe<Scalars['Boolean']['input']>;
  show_payment_terms_sales?: InputMaybe<Scalars['Boolean']['input']>;
  show_rep_all_leads?: InputMaybe<Scalars['Boolean']['input']>;
  show_rep_my_leads?: InputMaybe<Scalars['Boolean']['input']>;
  show_system_view?: InputMaybe<Scalars['Boolean']['input']>;
  sites?: InputMaybe<SiteCreateNestedManyWithoutOrganizationInput>;
  sms_opt_in_dialog?: InputMaybe<Scalars['String']['input']>;
  SMSItems?: InputMaybe<SmsItemCreateNestedManyWithoutOrganizationInput>;
  SnoozeTrackings?: InputMaybe<SnoozeTrackingCreateNestedManyWithoutOrganizationInput>;
  StepActionLogs?: InputMaybe<StepActionLogCreateNestedManyWithoutOrganizationInput>;
  StepActions?: InputMaybe<StepActionCreateNestedManyWithoutOrganizationInput>;
  teams?: InputMaybe<TeamCreateNestedManyWithoutOrganizationInput>;
  Template?: InputMaybe<TemplateCreateNestedManyWithoutOrganizationInput>;
  TemplateVersions?: InputMaybe<TemplateVersionCreateNestedManyWithoutOrganizationInput>;
  timekit_id?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  transfer_timeout?: InputMaybe<Scalars['Int']['input']>;
  TransferAttempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutOrganizationInput>;
  twilio_numbers?: InputMaybe<TwilioNumberCreateNestedManyWithoutOrganizationInput>;
  TwilioFeedbacks?: InputMaybe<TwilioFeedbackCreateNestedManyWithoutOrganizationInput>;
  TwilioInfo?: InputMaybe<TwilioInfoCreateNestedOneWithoutOrganzationInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  use_revenue_planning?: InputMaybe<Scalars['Boolean']['input']>;
  UserCustomFieldFilters?: InputMaybe<UserCustomFieldFilterCreateNestedManyWithoutOrganizationInput>;
  users?: InputMaybe<UserCreateNestedManyWithoutOrganizationInput>;
  win_rep_day_goal?: InputMaybe<Scalars['Float']['input']>;
};

export type OrganizationCreateWithoutTeamsInput = {
  ActivityMonitorMultiplier?: InputMaybe<ActivityMonitorMultiplierCreateNestedManyWithoutOrganizationInput>;
  algoFactor?: InputMaybe<AlgoFactorCreateNestedManyWithoutOrganizationInput>;
  allow_calendar_invite?: InputMaybe<Scalars['Boolean']['input']>;
  allow_remove_step_action?: InputMaybe<Scalars['Boolean']['input']>;
  allow_snooze_step_action?: InputMaybe<Scalars['Boolean']['input']>;
  always_record?: InputMaybe<Scalars['Boolean']['input']>;
  arv_initial_held_demos_generated_by_sdr_weight?: InputMaybe<Scalars['Float']['input']>;
  AssociationHistories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutOrganizationInput>;
  auto_call_interval?: InputMaybe<Scalars['Int']['input']>;
  auto_dial?: InputMaybe<Scalars['Boolean']['input']>;
  automated_campaigns?: InputMaybe<AutomatedCampaignCreateNestedManyWithoutOrganizationInput>;
  automatic_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  AutoTransferSelections?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutOrganizationInput>;
  Availablities?: InputMaybe<AvailabilityCreateNestedManyWithoutOrganizationInput>;
  AverageOrderValue?: InputMaybe<AverageOrderValueCreateNestedManyWithoutOrganizationInput>;
  best_action_ever_multiplier?: InputMaybe<Scalars['Float']['input']>;
  best_metrics?: InputMaybe<BestMetricRecordCreateNestedManyWithoutOrganizationInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptCreateNestedManyWithoutOrganizationInput>;
  callMeNows?: InputMaybe<CallMeNowCreateNestedManyWithoutOrganizationInput>;
  CallMultiplier?: InputMaybe<CallMultiplierCreateNestedManyWithoutOrganizationInput>;
  CallSharings?: InputMaybe<CallSharingCreateNestedManyWithoutOrganizationInput>;
  can_edit_sdr_transfer_rules?: InputMaybe<Scalars['Boolean']['input']>;
  claim_rule?: InputMaybe<Scalars['Int']['input']>;
  concession?: InputMaybe<ConcessionCreateNestedManyWithoutOrganizationInput>;
  conference_region?: InputMaybe<ConferenceRegion>;
  Conferences?: InputMaybe<ConferenceCreateNestedManyWithoutOrganizationInput>;
  ContactRequests?: InputMaybe<ContactRequestCreateNestedManyWithoutOrganizationInput>;
  Contacts?: InputMaybe<ContactCreateNestedManyWithoutOrganizationInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  custom_fields?: InputMaybe<CustomFieldCreateNestedManyWithoutOrganizationInput>;
  CustomObject?: InputMaybe<CustomObjectCreateNestedManyWithoutOrganizationInput>;
  CustomObjectField?: InputMaybe<CustomObjectFieldCreateNestedManyWithoutOrganizationInput>;
  CustomObjectFieldForeignKey?: InputMaybe<CustomObjectFieldForeignKeyCreateNestedManyWithoutOrganizationInput>;
  CustomObjectRecord?: InputMaybe<CustomObjectRecordCreateNestedManyWithoutOrganizationInput>;
  CustomObjectRow?: InputMaybe<CustomObjectRowCreateNestedManyWithoutOrganizationInput>;
  CustomQueueItems?: InputMaybe<CustomQueueItemCreateNestedManyWithoutOrganizationInput>;
  DashboardColumnOptions?: InputMaybe<DashboardColumnOptionCreateNestedManyWithoutOrganizationInput>;
  DashboardLeadFilters?: InputMaybe<DashboardLeadFilterCreateNestedManyWithoutOrganizationInput>;
  DashboardRepFilters?: InputMaybe<DashboardRepFilterCreateNestedManyWithoutOrganizationInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewCreateNestedManyWithoutOrganizationInput>;
  day_to_close?: InputMaybe<Scalars['Int']['input']>;
  default_demo_time_duration?: InputMaybe<Scalars['Int']['input']>;
  demo_held_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_not_held_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_not_held_call_back_short_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_prep_time_minutes?: InputMaybe<Scalars['Int']['input']>;
  dev_account?: InputMaybe<Scalars['Boolean']['input']>;
  dial_rule?: InputMaybe<Scalars['Int']['input']>;
  DispositionMultiplier?: InputMaybe<DispositionMultiplierCreateNestedManyWithoutOrganizationInput>;
  dispositions?: InputMaybe<DispositionCreateNestedManyWithoutOrganizationInput>;
  double_booking?: InputMaybe<Scalars['Boolean']['input']>;
  dynamic_date_operators?: InputMaybe<Scalars['Boolean']['input']>;
  edge_servers?: InputMaybe<OrganizationCreateedge_ServersInput>;
  email_conflict_notification_email_list?: InputMaybe<OrganizationCreateemail_Conflict_Notification_Email_ListInput>;
  EmailItems?: InputMaybe<EmailItemCreateNestedManyWithoutOrganizationInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordCreateNestedManyWithoutOrganizationInput>;
  EmailThreads?: InputMaybe<EmailThreadCreateNestedManyWithoutOrganizationInput>;
  enable_modified_metrics?: InputMaybe<Scalars['Boolean']['input']>;
  enable_pay_period_view?: InputMaybe<Scalars['Boolean']['input']>;
  enable_time_constraint?: InputMaybe<Scalars['Boolean']['input']>;
  error_log_email_address?: InputMaybe<OrganizationCreateerror_Log_Email_AddressInput>;
  estimated_average_num_of_weekly_overbooked_demo_slots?: InputMaybe<Scalars['Int']['input']>;
  FolderPermissions?: InputMaybe<FolderPermissionCreateNestedManyWithoutOrganizationInput>;
  Folders?: InputMaybe<FolderCreateNestedManyWithoutOrganizationInput>;
  FutureBookingSetting?: InputMaybe<FutureBookingSettingCreateNestedOneWithoutOrganizationInput>;
  GlobalTransferRule?: InputMaybe<GlobalTransferRuleCreateNestedManyWithoutOrganizationInput>;
  held_demos_per_cc_connect?: InputMaybe<Scalars['Float']['input']>;
  helpful_links?: InputMaybe<OrganizationCreatehelpful_LinksInput>;
  HingePointMultiplier?: InputMaybe<HingePointMultiplierCreateNestedManyWithoutOrganizationInput>;
  hold_goal?: InputMaybe<Scalars['Float']['input']>;
  hubspot_credential?: InputMaybe<HubSpotCredentialCreateNestedOneWithoutOrganizationInput>;
  hubspot_last_sync_date?: InputMaybe<Scalars['DateTime']['input']>;
  hubspot_white_list?: InputMaybe<HubSpotWhitelistCreateNestedManyWithoutOrganizationInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  inbound_close_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_dm_connect_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_lt_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_revenue_value_of_sale?: InputMaybe<Scalars['Float']['input']>;
  inbound_scheduled_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_set_rate?: InputMaybe<Scalars['Float']['input']>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventCreateNestedManyWithoutOrganizationInput>;
  InboundConciergeWidget?: InputMaybe<InboundConciergeWidgetCreateNestedManyWithoutOrganizationInput>;
  industry_options?: InputMaybe<IndustryOptionCreateNestedManyWithoutOrganizationInput>;
  initial_sdr_sets_reps_day?: InputMaybe<Scalars['Float']['input']>;
  integration_error_logs?: InputMaybe<IntegrationErrorLogCreateNestedManyWithoutOrganizationInput>;
  integration_mappings?: InputMaybe<IntegrationMappingCreateNestedManyWithoutOrganizationInput>;
  IntegrationMappingCustomObject?: InputMaybe<IntegrationMappingCustomObjectCreateNestedManyWithoutOrganizationInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogCreateNestedManyWithoutOrganizationInput>;
  IntegrationReadOnlyObject?: InputMaybe<IntegrationReadOnlyObjectCreateNestedManyWithoutOrganizationInput>;
  international?: InputMaybe<Scalars['Boolean']['input']>;
  is_email_domain_verified?: InputMaybe<Scalars['Boolean']['input']>;
  is_salesforce_sandbox?: InputMaybe<Scalars['Boolean']['input']>;
  last_lead_value_compute_time?: InputMaybe<Scalars['DateTime']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutOrganizationInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryCreateNestedManyWithoutOrganizationInput>;
  lead_intent?: InputMaybe<LeadIntentCreateNestedManyWithoutOrganizationInput>;
  lead_release_same_rep_delay_rule?: InputMaybe<Scalars['Int']['input']>;
  lead_routing_email_list?: InputMaybe<OrganizationCreatelead_Routing_Email_ListInput>;
  lead_routing_rules?: InputMaybe<RuleCreateNestedManyWithoutOrganizationInput>;
  LeadCustomObjectRowAssociation?: InputMaybe<LeadCustomObjectRowAssociationCreateNestedManyWithoutOrganizationInput>;
  LeadFieldMultiplier?: InputMaybe<LeadFieldMultiplierCreateNestedManyWithoutOrganizationInput>;
  LeadFieldMultiplierCustom?: InputMaybe<LeadFieldMultiplierCustomCreateNestedManyWithoutOrganizationInput>;
  leads?: InputMaybe<LeadCreateNestedManyWithoutOrganizationInput>;
  leadsource_options?: InputMaybe<LeadSourceOptionCreateNestedManyWithoutOrganizationInput>;
  LeadValue?: InputMaybe<LeadValueCreateNestedManyWithoutOrganizationInput>;
  LifeTimeValueMultiplier?: InputMaybe<LifeTimeValueMultiplierCreateNestedManyWithoutOrganizationInput>;
  LiveTransferSetting?: InputMaybe<LiveTransferSettingCreateNestedOneWithoutOrganizationInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusCreateNestedManyWithoutOrganizationInput>;
  ltv_multiplier_custom_fields?: InputMaybe<LifeTimeValueMultiplierCustomCreateNestedManyWithoutOrganizationInput>;
  made_contact_rule?: InputMaybe<Scalars['Int']['input']>;
  make_sale_address_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_business_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_city_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_country_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_first_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_industry_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_last_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_lead_source_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_primary_email_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_primary_phone_number_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_state_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_sub_industry_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_zip_required?: InputMaybe<Scalars['Boolean']['input']>;
  maxium_day_lag_set_to_schedule?: InputMaybe<Scalars['Int']['input']>;
  mrr?: InputMaybe<Scalars['Int']['input']>;
  mrr_label?: InputMaybe<Scalars['String']['input']>;
  mrr_required_on_sale?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  NoteItems?: InputMaybe<NoteItemCreateNestedManyWithoutOrganizationInput>;
  Notifications?: InputMaybe<NotificationCreateNestedManyWithoutOrganizationInput>;
  onboardComplete?: InputMaybe<Scalars['Boolean']['input']>;
  Opportunity?: InputMaybe<SfOpportunityCreateNestedManyWithoutOrganizationInput>;
  organization_holidays?: InputMaybe<OrganizationHolidayCreateNestedManyWithoutOrganizationInput>;
  organization_hours?: InputMaybe<OrganizationHourCreateNestedManyWithoutOrganizationInput>;
  OrganizationPaymentSetting?: InputMaybe<OrganizationPaymentSettingsCreateNestedOneWithoutOrganizationInput>;
  OrganizationPaymentSettings?: InputMaybe<OrganizationPaymentSettingsCreateNestedOneWithoutOrganizationsInput>;
  orgCalendarInviteMetadata?: InputMaybe<OrgCalendarInviteMetadataCreateNestedManyWithoutOrganizationInput>;
  OrgDefaultPipelinePerecentValue?: InputMaybe<OrgDefaultPipelinePerecentValueCreateNestedOneWithoutOrganizationInput>;
  OrgHubSpotSync?: InputMaybe<OrgHubSpotSyncCreateNestedOneWithoutOrganizationInput>;
  OrgNoEventDate?: InputMaybe<OrgNoEventDateCreateNestedManyWithoutOrganizationInput>;
  OrgSalesforceSync?: InputMaybe<OrgSalesforceSyncCreateNestedOneWithoutOrganizationInput>;
  OrgTimeslotAvailability?: InputMaybe<OrgTimeslotAvailabilityCreateNestedManyWithoutOrganizationInput>;
  outbound_close_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_dm_connect_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_lt_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_revenue_value_of_sale?: InputMaybe<Scalars['Float']['input']>;
  outbound_scheduled_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_set_rate?: InputMaybe<Scalars['Float']['input']>;
  PandadocData?: InputMaybe<PandadocDataCreateNestedOneWithoutOrganizationInput>;
  PandaDocTemplate?: InputMaybe<PandaDocTemplateCreateNestedManyWithoutOrganizationInput>;
  participant_jitter_buffer_size?: InputMaybe<JitterBufferSize>;
  preferred_goal_setting?: InputMaybe<Goalsetting>;
  prepayment?: InputMaybe<Scalars['Boolean']['input']>;
  ProductChangeLog?: InputMaybe<ProductChangeLogCreateNestedManyWithoutOrganizationInput>;
  products?: InputMaybe<ProductCreateNestedManyWithoutOrganizationInput>;
  re_cold_call_hour_delay?: InputMaybe<Scalars['Float']['input']>;
  recordingStatus?: InputMaybe<RecordingStatus>;
  release_lead_resting?: InputMaybe<Scalars['Int']['input']>;
  rep_claim_limit?: InputMaybe<Scalars['Int']['input']>;
  rep_lead_allotment?: InputMaybe<Scalars['Int']['input']>;
  rep_level_call_transfer?: InputMaybe<Scalars['Boolean']['input']>;
  reps_can_bulk_unassign?: InputMaybe<Scalars['Boolean']['input']>;
  reps_can_view_all_notes?: InputMaybe<Scalars['Boolean']['input']>;
  require_sale_notes?: InputMaybe<Scalars['Boolean']['input']>;
  resting_after_skipped_CC?: InputMaybe<Scalars['Int']['input']>;
  resting_period?: InputMaybe<Scalars['Int']['input']>;
  resting_period_after_unsuccessful_cold_call?: InputMaybe<Scalars['Float']['input']>;
  resting_period_after_unsuccessful_demo?: InputMaybe<Scalars['Float']['input']>;
  revenue_plan?: InputMaybe<RevenuePlanItemCreateNestedManyWithoutOrganizationInput>;
  Routings?: InputMaybe<RoutingCreateNestedManyWithoutOrganizationInput>;
  sale_field_options?: InputMaybe<SaleFieldOptionCreateNestedManyWithoutOrganizationInput>;
  sale_notification_email_list?: InputMaybe<OrganizationCreatesale_Notification_Email_ListInput>;
  SaleConfig?: InputMaybe<SaleConfigCreateNestedOneWithoutOrganizationInput>;
  SaleConfigPage?: InputMaybe<SaleConfigPageCreateNestedManyWithoutOrganizationInput>;
  SaleConfigSection?: InputMaybe<SaleConfigSectionCreateNestedManyWithoutOrganizationInput>;
  SaleCycles?: InputMaybe<SaleCycleCreateNestedManyWithoutOrganizationInput>;
  SaleItems?: InputMaybe<SaleItemCreateNestedManyWithoutOrganizationInput>;
  sales?: InputMaybe<SaleCreateNestedManyWithoutOrganizationInput>;
  salesforce_domain?: InputMaybe<Scalars['String']['input']>;
  salesforce_last_sync_date?: InputMaybe<Scalars['DateTime']['input']>;
  SalesForceColumn?: InputMaybe<SalesForceColumnCreateNestedManyWithoutOrganizationInput>;
  salesForceData?: InputMaybe<SalesForceDataCreateNestedOneWithoutOrganizationInput>;
  ScheduleItems?: InputMaybe<ScheduleItemCreateNestedManyWithoutOrganizationInput>;
  self_sourcing_rule?: InputMaybe<Scalars['Int']['input']>;
  send_error_log_email?: InputMaybe<Scalars['Boolean']['input']>;
  send_lead_routing_email?: InputMaybe<Scalars['Boolean']['input']>;
  sendgrid_email_domain?: InputMaybe<Scalars['String']['input']>;
  sendgrid_email_domain_id?: InputMaybe<Scalars['Int']['input']>;
  sequence?: InputMaybe<SequenceCreateNestedManyWithoutOrganzationInput>;
  SequenceCriteriaConditions?: InputMaybe<SequenceCriteriaConditionCreateNestedManyWithoutOrganizationInput>;
  SequenceDashboardViews?: InputMaybe<SequenceDashboardViewCreateNestedManyWithoutOrganizationInput>;
  SequenceEntryCriterias?: InputMaybe<SequenceEntryCriteriaCreateNestedManyWithoutOrganizationInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutOrganizationInput>;
  SequenceSteps?: InputMaybe<SequenceStepCreateNestedManyWithoutOrganizationInput>;
  set_goal?: InputMaybe<Scalars['Float']['input']>;
  short_resting?: InputMaybe<Scalars['Int']['input']>;
  show_calendar_options?: InputMaybe<Scalars['Boolean']['input']>;
  show_contract_duration_sales?: InputMaybe<Scalars['Boolean']['input']>;
  show_lead_source_to_rep?: InputMaybe<Scalars['Boolean']['input']>;
  show_nocontact_button?: InputMaybe<Scalars['Boolean']['input']>;
  show_payment_terms_sales?: InputMaybe<Scalars['Boolean']['input']>;
  show_rep_all_leads?: InputMaybe<Scalars['Boolean']['input']>;
  show_rep_my_leads?: InputMaybe<Scalars['Boolean']['input']>;
  show_system_view?: InputMaybe<Scalars['Boolean']['input']>;
  sites?: InputMaybe<SiteCreateNestedManyWithoutOrganizationInput>;
  sms_opt_in_dialog?: InputMaybe<Scalars['String']['input']>;
  SMSItems?: InputMaybe<SmsItemCreateNestedManyWithoutOrganizationInput>;
  SnoozeTrackings?: InputMaybe<SnoozeTrackingCreateNestedManyWithoutOrganizationInput>;
  StepActionLogs?: InputMaybe<StepActionLogCreateNestedManyWithoutOrganizationInput>;
  StepActions?: InputMaybe<StepActionCreateNestedManyWithoutOrganizationInput>;
  StepConditions?: InputMaybe<StepConditionCreateNestedManyWithoutOrganizationInput>;
  Template?: InputMaybe<TemplateCreateNestedManyWithoutOrganizationInput>;
  TemplateVersions?: InputMaybe<TemplateVersionCreateNestedManyWithoutOrganizationInput>;
  timekit_id?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  transfer_timeout?: InputMaybe<Scalars['Int']['input']>;
  TransferAttempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutOrganizationInput>;
  twilio_numbers?: InputMaybe<TwilioNumberCreateNestedManyWithoutOrganizationInput>;
  TwilioFeedbacks?: InputMaybe<TwilioFeedbackCreateNestedManyWithoutOrganizationInput>;
  TwilioInfo?: InputMaybe<TwilioInfoCreateNestedOneWithoutOrganzationInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  use_revenue_planning?: InputMaybe<Scalars['Boolean']['input']>;
  UserCustomFieldFilters?: InputMaybe<UserCustomFieldFilterCreateNestedManyWithoutOrganizationInput>;
  users?: InputMaybe<UserCreateNestedManyWithoutOrganizationInput>;
  win_rep_day_goal?: InputMaybe<Scalars['Float']['input']>;
};

export type OrganizationCreateWithoutTemplateInput = {
  ActivityMonitorMultiplier?: InputMaybe<ActivityMonitorMultiplierCreateNestedManyWithoutOrganizationInput>;
  algoFactor?: InputMaybe<AlgoFactorCreateNestedManyWithoutOrganizationInput>;
  allow_calendar_invite?: InputMaybe<Scalars['Boolean']['input']>;
  allow_remove_step_action?: InputMaybe<Scalars['Boolean']['input']>;
  allow_snooze_step_action?: InputMaybe<Scalars['Boolean']['input']>;
  always_record?: InputMaybe<Scalars['Boolean']['input']>;
  arv_initial_held_demos_generated_by_sdr_weight?: InputMaybe<Scalars['Float']['input']>;
  AssociationHistories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutOrganizationInput>;
  auto_call_interval?: InputMaybe<Scalars['Int']['input']>;
  auto_dial?: InputMaybe<Scalars['Boolean']['input']>;
  automated_campaigns?: InputMaybe<AutomatedCampaignCreateNestedManyWithoutOrganizationInput>;
  automatic_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  AutoTransferSelections?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutOrganizationInput>;
  Availablities?: InputMaybe<AvailabilityCreateNestedManyWithoutOrganizationInput>;
  AverageOrderValue?: InputMaybe<AverageOrderValueCreateNestedManyWithoutOrganizationInput>;
  best_action_ever_multiplier?: InputMaybe<Scalars['Float']['input']>;
  best_metrics?: InputMaybe<BestMetricRecordCreateNestedManyWithoutOrganizationInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptCreateNestedManyWithoutOrganizationInput>;
  callMeNows?: InputMaybe<CallMeNowCreateNestedManyWithoutOrganizationInput>;
  CallMultiplier?: InputMaybe<CallMultiplierCreateNestedManyWithoutOrganizationInput>;
  CallSharings?: InputMaybe<CallSharingCreateNestedManyWithoutOrganizationInput>;
  can_edit_sdr_transfer_rules?: InputMaybe<Scalars['Boolean']['input']>;
  claim_rule?: InputMaybe<Scalars['Int']['input']>;
  concession?: InputMaybe<ConcessionCreateNestedManyWithoutOrganizationInput>;
  conference_region?: InputMaybe<ConferenceRegion>;
  Conferences?: InputMaybe<ConferenceCreateNestedManyWithoutOrganizationInput>;
  ContactRequests?: InputMaybe<ContactRequestCreateNestedManyWithoutOrganizationInput>;
  Contacts?: InputMaybe<ContactCreateNestedManyWithoutOrganizationInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  custom_fields?: InputMaybe<CustomFieldCreateNestedManyWithoutOrganizationInput>;
  CustomObject?: InputMaybe<CustomObjectCreateNestedManyWithoutOrganizationInput>;
  CustomObjectField?: InputMaybe<CustomObjectFieldCreateNestedManyWithoutOrganizationInput>;
  CustomObjectFieldForeignKey?: InputMaybe<CustomObjectFieldForeignKeyCreateNestedManyWithoutOrganizationInput>;
  CustomObjectRecord?: InputMaybe<CustomObjectRecordCreateNestedManyWithoutOrganizationInput>;
  CustomObjectRow?: InputMaybe<CustomObjectRowCreateNestedManyWithoutOrganizationInput>;
  CustomQueueItems?: InputMaybe<CustomQueueItemCreateNestedManyWithoutOrganizationInput>;
  DashboardColumnOptions?: InputMaybe<DashboardColumnOptionCreateNestedManyWithoutOrganizationInput>;
  DashboardLeadFilters?: InputMaybe<DashboardLeadFilterCreateNestedManyWithoutOrganizationInput>;
  DashboardRepFilters?: InputMaybe<DashboardRepFilterCreateNestedManyWithoutOrganizationInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewCreateNestedManyWithoutOrganizationInput>;
  day_to_close?: InputMaybe<Scalars['Int']['input']>;
  default_demo_time_duration?: InputMaybe<Scalars['Int']['input']>;
  demo_held_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_not_held_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_not_held_call_back_short_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_prep_time_minutes?: InputMaybe<Scalars['Int']['input']>;
  dev_account?: InputMaybe<Scalars['Boolean']['input']>;
  dial_rule?: InputMaybe<Scalars['Int']['input']>;
  DispositionMultiplier?: InputMaybe<DispositionMultiplierCreateNestedManyWithoutOrganizationInput>;
  dispositions?: InputMaybe<DispositionCreateNestedManyWithoutOrganizationInput>;
  double_booking?: InputMaybe<Scalars['Boolean']['input']>;
  dynamic_date_operators?: InputMaybe<Scalars['Boolean']['input']>;
  edge_servers?: InputMaybe<OrganizationCreateedge_ServersInput>;
  email_conflict_notification_email_list?: InputMaybe<OrganizationCreateemail_Conflict_Notification_Email_ListInput>;
  EmailItems?: InputMaybe<EmailItemCreateNestedManyWithoutOrganizationInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordCreateNestedManyWithoutOrganizationInput>;
  EmailThreads?: InputMaybe<EmailThreadCreateNestedManyWithoutOrganizationInput>;
  enable_modified_metrics?: InputMaybe<Scalars['Boolean']['input']>;
  enable_pay_period_view?: InputMaybe<Scalars['Boolean']['input']>;
  enable_time_constraint?: InputMaybe<Scalars['Boolean']['input']>;
  error_log_email_address?: InputMaybe<OrganizationCreateerror_Log_Email_AddressInput>;
  estimated_average_num_of_weekly_overbooked_demo_slots?: InputMaybe<Scalars['Int']['input']>;
  FolderPermissions?: InputMaybe<FolderPermissionCreateNestedManyWithoutOrganizationInput>;
  Folders?: InputMaybe<FolderCreateNestedManyWithoutOrganizationInput>;
  FutureBookingSetting?: InputMaybe<FutureBookingSettingCreateNestedOneWithoutOrganizationInput>;
  GlobalTransferRule?: InputMaybe<GlobalTransferRuleCreateNestedManyWithoutOrganizationInput>;
  held_demos_per_cc_connect?: InputMaybe<Scalars['Float']['input']>;
  helpful_links?: InputMaybe<OrganizationCreatehelpful_LinksInput>;
  HingePointMultiplier?: InputMaybe<HingePointMultiplierCreateNestedManyWithoutOrganizationInput>;
  hold_goal?: InputMaybe<Scalars['Float']['input']>;
  hubspot_credential?: InputMaybe<HubSpotCredentialCreateNestedOneWithoutOrganizationInput>;
  hubspot_last_sync_date?: InputMaybe<Scalars['DateTime']['input']>;
  hubspot_white_list?: InputMaybe<HubSpotWhitelistCreateNestedManyWithoutOrganizationInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  inbound_close_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_dm_connect_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_lt_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_revenue_value_of_sale?: InputMaybe<Scalars['Float']['input']>;
  inbound_scheduled_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_set_rate?: InputMaybe<Scalars['Float']['input']>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventCreateNestedManyWithoutOrganizationInput>;
  InboundConciergeWidget?: InputMaybe<InboundConciergeWidgetCreateNestedManyWithoutOrganizationInput>;
  industry_options?: InputMaybe<IndustryOptionCreateNestedManyWithoutOrganizationInput>;
  initial_sdr_sets_reps_day?: InputMaybe<Scalars['Float']['input']>;
  integration_error_logs?: InputMaybe<IntegrationErrorLogCreateNestedManyWithoutOrganizationInput>;
  integration_mappings?: InputMaybe<IntegrationMappingCreateNestedManyWithoutOrganizationInput>;
  IntegrationMappingCustomObject?: InputMaybe<IntegrationMappingCustomObjectCreateNestedManyWithoutOrganizationInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogCreateNestedManyWithoutOrganizationInput>;
  IntegrationReadOnlyObject?: InputMaybe<IntegrationReadOnlyObjectCreateNestedManyWithoutOrganizationInput>;
  international?: InputMaybe<Scalars['Boolean']['input']>;
  is_email_domain_verified?: InputMaybe<Scalars['Boolean']['input']>;
  is_salesforce_sandbox?: InputMaybe<Scalars['Boolean']['input']>;
  last_lead_value_compute_time?: InputMaybe<Scalars['DateTime']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutOrganizationInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryCreateNestedManyWithoutOrganizationInput>;
  lead_intent?: InputMaybe<LeadIntentCreateNestedManyWithoutOrganizationInput>;
  lead_release_same_rep_delay_rule?: InputMaybe<Scalars['Int']['input']>;
  lead_routing_email_list?: InputMaybe<OrganizationCreatelead_Routing_Email_ListInput>;
  lead_routing_rules?: InputMaybe<RuleCreateNestedManyWithoutOrganizationInput>;
  LeadCustomObjectRowAssociation?: InputMaybe<LeadCustomObjectRowAssociationCreateNestedManyWithoutOrganizationInput>;
  LeadFieldMultiplier?: InputMaybe<LeadFieldMultiplierCreateNestedManyWithoutOrganizationInput>;
  LeadFieldMultiplierCustom?: InputMaybe<LeadFieldMultiplierCustomCreateNestedManyWithoutOrganizationInput>;
  leads?: InputMaybe<LeadCreateNestedManyWithoutOrganizationInput>;
  leadsource_options?: InputMaybe<LeadSourceOptionCreateNestedManyWithoutOrganizationInput>;
  LeadValue?: InputMaybe<LeadValueCreateNestedManyWithoutOrganizationInput>;
  LifeTimeValueMultiplier?: InputMaybe<LifeTimeValueMultiplierCreateNestedManyWithoutOrganizationInput>;
  LiveTransferSetting?: InputMaybe<LiveTransferSettingCreateNestedOneWithoutOrganizationInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusCreateNestedManyWithoutOrganizationInput>;
  ltv_multiplier_custom_fields?: InputMaybe<LifeTimeValueMultiplierCustomCreateNestedManyWithoutOrganizationInput>;
  made_contact_rule?: InputMaybe<Scalars['Int']['input']>;
  make_sale_address_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_business_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_city_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_country_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_first_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_industry_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_last_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_lead_source_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_primary_email_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_primary_phone_number_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_state_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_sub_industry_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_zip_required?: InputMaybe<Scalars['Boolean']['input']>;
  maxium_day_lag_set_to_schedule?: InputMaybe<Scalars['Int']['input']>;
  mrr?: InputMaybe<Scalars['Int']['input']>;
  mrr_label?: InputMaybe<Scalars['String']['input']>;
  mrr_required_on_sale?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  NoteItems?: InputMaybe<NoteItemCreateNestedManyWithoutOrganizationInput>;
  Notifications?: InputMaybe<NotificationCreateNestedManyWithoutOrganizationInput>;
  onboardComplete?: InputMaybe<Scalars['Boolean']['input']>;
  Opportunity?: InputMaybe<SfOpportunityCreateNestedManyWithoutOrganizationInput>;
  organization_holidays?: InputMaybe<OrganizationHolidayCreateNestedManyWithoutOrganizationInput>;
  organization_hours?: InputMaybe<OrganizationHourCreateNestedManyWithoutOrganizationInput>;
  OrganizationPaymentSetting?: InputMaybe<OrganizationPaymentSettingsCreateNestedOneWithoutOrganizationInput>;
  OrganizationPaymentSettings?: InputMaybe<OrganizationPaymentSettingsCreateNestedOneWithoutOrganizationsInput>;
  orgCalendarInviteMetadata?: InputMaybe<OrgCalendarInviteMetadataCreateNestedManyWithoutOrganizationInput>;
  OrgDefaultPipelinePerecentValue?: InputMaybe<OrgDefaultPipelinePerecentValueCreateNestedOneWithoutOrganizationInput>;
  OrgHubSpotSync?: InputMaybe<OrgHubSpotSyncCreateNestedOneWithoutOrganizationInput>;
  OrgNoEventDate?: InputMaybe<OrgNoEventDateCreateNestedManyWithoutOrganizationInput>;
  OrgSalesforceSync?: InputMaybe<OrgSalesforceSyncCreateNestedOneWithoutOrganizationInput>;
  OrgTimeslotAvailability?: InputMaybe<OrgTimeslotAvailabilityCreateNestedManyWithoutOrganizationInput>;
  outbound_close_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_dm_connect_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_lt_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_revenue_value_of_sale?: InputMaybe<Scalars['Float']['input']>;
  outbound_scheduled_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_set_rate?: InputMaybe<Scalars['Float']['input']>;
  PandadocData?: InputMaybe<PandadocDataCreateNestedOneWithoutOrganizationInput>;
  PandaDocTemplate?: InputMaybe<PandaDocTemplateCreateNestedManyWithoutOrganizationInput>;
  participant_jitter_buffer_size?: InputMaybe<JitterBufferSize>;
  preferred_goal_setting?: InputMaybe<Goalsetting>;
  prepayment?: InputMaybe<Scalars['Boolean']['input']>;
  ProductChangeLog?: InputMaybe<ProductChangeLogCreateNestedManyWithoutOrganizationInput>;
  products?: InputMaybe<ProductCreateNestedManyWithoutOrganizationInput>;
  re_cold_call_hour_delay?: InputMaybe<Scalars['Float']['input']>;
  recordingStatus?: InputMaybe<RecordingStatus>;
  release_lead_resting?: InputMaybe<Scalars['Int']['input']>;
  rep_claim_limit?: InputMaybe<Scalars['Int']['input']>;
  rep_lead_allotment?: InputMaybe<Scalars['Int']['input']>;
  rep_level_call_transfer?: InputMaybe<Scalars['Boolean']['input']>;
  reps_can_bulk_unassign?: InputMaybe<Scalars['Boolean']['input']>;
  reps_can_view_all_notes?: InputMaybe<Scalars['Boolean']['input']>;
  require_sale_notes?: InputMaybe<Scalars['Boolean']['input']>;
  resting_after_skipped_CC?: InputMaybe<Scalars['Int']['input']>;
  resting_period?: InputMaybe<Scalars['Int']['input']>;
  resting_period_after_unsuccessful_cold_call?: InputMaybe<Scalars['Float']['input']>;
  resting_period_after_unsuccessful_demo?: InputMaybe<Scalars['Float']['input']>;
  revenue_plan?: InputMaybe<RevenuePlanItemCreateNestedManyWithoutOrganizationInput>;
  Routings?: InputMaybe<RoutingCreateNestedManyWithoutOrganizationInput>;
  sale_field_options?: InputMaybe<SaleFieldOptionCreateNestedManyWithoutOrganizationInput>;
  sale_notification_email_list?: InputMaybe<OrganizationCreatesale_Notification_Email_ListInput>;
  SaleConfig?: InputMaybe<SaleConfigCreateNestedOneWithoutOrganizationInput>;
  SaleConfigPage?: InputMaybe<SaleConfigPageCreateNestedManyWithoutOrganizationInput>;
  SaleConfigSection?: InputMaybe<SaleConfigSectionCreateNestedManyWithoutOrganizationInput>;
  SaleCycles?: InputMaybe<SaleCycleCreateNestedManyWithoutOrganizationInput>;
  SaleItems?: InputMaybe<SaleItemCreateNestedManyWithoutOrganizationInput>;
  sales?: InputMaybe<SaleCreateNestedManyWithoutOrganizationInput>;
  salesforce_domain?: InputMaybe<Scalars['String']['input']>;
  salesforce_last_sync_date?: InputMaybe<Scalars['DateTime']['input']>;
  SalesForceColumn?: InputMaybe<SalesForceColumnCreateNestedManyWithoutOrganizationInput>;
  salesForceData?: InputMaybe<SalesForceDataCreateNestedOneWithoutOrganizationInput>;
  ScheduleItems?: InputMaybe<ScheduleItemCreateNestedManyWithoutOrganizationInput>;
  self_sourcing_rule?: InputMaybe<Scalars['Int']['input']>;
  send_error_log_email?: InputMaybe<Scalars['Boolean']['input']>;
  send_lead_routing_email?: InputMaybe<Scalars['Boolean']['input']>;
  sendgrid_email_domain?: InputMaybe<Scalars['String']['input']>;
  sendgrid_email_domain_id?: InputMaybe<Scalars['Int']['input']>;
  sequence?: InputMaybe<SequenceCreateNestedManyWithoutOrganzationInput>;
  SequenceCriteriaConditions?: InputMaybe<SequenceCriteriaConditionCreateNestedManyWithoutOrganizationInput>;
  SequenceDashboardViews?: InputMaybe<SequenceDashboardViewCreateNestedManyWithoutOrganizationInput>;
  SequenceEntryCriterias?: InputMaybe<SequenceEntryCriteriaCreateNestedManyWithoutOrganizationInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutOrganizationInput>;
  SequenceSteps?: InputMaybe<SequenceStepCreateNestedManyWithoutOrganizationInput>;
  set_goal?: InputMaybe<Scalars['Float']['input']>;
  short_resting?: InputMaybe<Scalars['Int']['input']>;
  show_calendar_options?: InputMaybe<Scalars['Boolean']['input']>;
  show_contract_duration_sales?: InputMaybe<Scalars['Boolean']['input']>;
  show_lead_source_to_rep?: InputMaybe<Scalars['Boolean']['input']>;
  show_nocontact_button?: InputMaybe<Scalars['Boolean']['input']>;
  show_payment_terms_sales?: InputMaybe<Scalars['Boolean']['input']>;
  show_rep_all_leads?: InputMaybe<Scalars['Boolean']['input']>;
  show_rep_my_leads?: InputMaybe<Scalars['Boolean']['input']>;
  show_system_view?: InputMaybe<Scalars['Boolean']['input']>;
  sites?: InputMaybe<SiteCreateNestedManyWithoutOrganizationInput>;
  sms_opt_in_dialog?: InputMaybe<Scalars['String']['input']>;
  SMSItems?: InputMaybe<SmsItemCreateNestedManyWithoutOrganizationInput>;
  SnoozeTrackings?: InputMaybe<SnoozeTrackingCreateNestedManyWithoutOrganizationInput>;
  StepActionLogs?: InputMaybe<StepActionLogCreateNestedManyWithoutOrganizationInput>;
  StepActions?: InputMaybe<StepActionCreateNestedManyWithoutOrganizationInput>;
  StepConditions?: InputMaybe<StepConditionCreateNestedManyWithoutOrganizationInput>;
  teams?: InputMaybe<TeamCreateNestedManyWithoutOrganizationInput>;
  TemplateVersions?: InputMaybe<TemplateVersionCreateNestedManyWithoutOrganizationInput>;
  timekit_id?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  transfer_timeout?: InputMaybe<Scalars['Int']['input']>;
  TransferAttempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutOrganizationInput>;
  twilio_numbers?: InputMaybe<TwilioNumberCreateNestedManyWithoutOrganizationInput>;
  TwilioFeedbacks?: InputMaybe<TwilioFeedbackCreateNestedManyWithoutOrganizationInput>;
  TwilioInfo?: InputMaybe<TwilioInfoCreateNestedOneWithoutOrganzationInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  use_revenue_planning?: InputMaybe<Scalars['Boolean']['input']>;
  UserCustomFieldFilters?: InputMaybe<UserCustomFieldFilterCreateNestedManyWithoutOrganizationInput>;
  users?: InputMaybe<UserCreateNestedManyWithoutOrganizationInput>;
  win_rep_day_goal?: InputMaybe<Scalars['Float']['input']>;
};

export type OrganizationCreateWithoutTemplateVersionsInput = {
  ActivityMonitorMultiplier?: InputMaybe<ActivityMonitorMultiplierCreateNestedManyWithoutOrganizationInput>;
  algoFactor?: InputMaybe<AlgoFactorCreateNestedManyWithoutOrganizationInput>;
  allow_calendar_invite?: InputMaybe<Scalars['Boolean']['input']>;
  allow_remove_step_action?: InputMaybe<Scalars['Boolean']['input']>;
  allow_snooze_step_action?: InputMaybe<Scalars['Boolean']['input']>;
  always_record?: InputMaybe<Scalars['Boolean']['input']>;
  arv_initial_held_demos_generated_by_sdr_weight?: InputMaybe<Scalars['Float']['input']>;
  AssociationHistories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutOrganizationInput>;
  auto_call_interval?: InputMaybe<Scalars['Int']['input']>;
  auto_dial?: InputMaybe<Scalars['Boolean']['input']>;
  automated_campaigns?: InputMaybe<AutomatedCampaignCreateNestedManyWithoutOrganizationInput>;
  automatic_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  AutoTransferSelections?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutOrganizationInput>;
  Availablities?: InputMaybe<AvailabilityCreateNestedManyWithoutOrganizationInput>;
  AverageOrderValue?: InputMaybe<AverageOrderValueCreateNestedManyWithoutOrganizationInput>;
  best_action_ever_multiplier?: InputMaybe<Scalars['Float']['input']>;
  best_metrics?: InputMaybe<BestMetricRecordCreateNestedManyWithoutOrganizationInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptCreateNestedManyWithoutOrganizationInput>;
  callMeNows?: InputMaybe<CallMeNowCreateNestedManyWithoutOrganizationInput>;
  CallMultiplier?: InputMaybe<CallMultiplierCreateNestedManyWithoutOrganizationInput>;
  CallSharings?: InputMaybe<CallSharingCreateNestedManyWithoutOrganizationInput>;
  can_edit_sdr_transfer_rules?: InputMaybe<Scalars['Boolean']['input']>;
  claim_rule?: InputMaybe<Scalars['Int']['input']>;
  concession?: InputMaybe<ConcessionCreateNestedManyWithoutOrganizationInput>;
  conference_region?: InputMaybe<ConferenceRegion>;
  Conferences?: InputMaybe<ConferenceCreateNestedManyWithoutOrganizationInput>;
  ContactRequests?: InputMaybe<ContactRequestCreateNestedManyWithoutOrganizationInput>;
  Contacts?: InputMaybe<ContactCreateNestedManyWithoutOrganizationInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  custom_fields?: InputMaybe<CustomFieldCreateNestedManyWithoutOrganizationInput>;
  CustomObject?: InputMaybe<CustomObjectCreateNestedManyWithoutOrganizationInput>;
  CustomObjectField?: InputMaybe<CustomObjectFieldCreateNestedManyWithoutOrganizationInput>;
  CustomObjectFieldForeignKey?: InputMaybe<CustomObjectFieldForeignKeyCreateNestedManyWithoutOrganizationInput>;
  CustomObjectRecord?: InputMaybe<CustomObjectRecordCreateNestedManyWithoutOrganizationInput>;
  CustomObjectRow?: InputMaybe<CustomObjectRowCreateNestedManyWithoutOrganizationInput>;
  CustomQueueItems?: InputMaybe<CustomQueueItemCreateNestedManyWithoutOrganizationInput>;
  DashboardColumnOptions?: InputMaybe<DashboardColumnOptionCreateNestedManyWithoutOrganizationInput>;
  DashboardLeadFilters?: InputMaybe<DashboardLeadFilterCreateNestedManyWithoutOrganizationInput>;
  DashboardRepFilters?: InputMaybe<DashboardRepFilterCreateNestedManyWithoutOrganizationInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewCreateNestedManyWithoutOrganizationInput>;
  day_to_close?: InputMaybe<Scalars['Int']['input']>;
  default_demo_time_duration?: InputMaybe<Scalars['Int']['input']>;
  demo_held_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_not_held_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_not_held_call_back_short_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_prep_time_minutes?: InputMaybe<Scalars['Int']['input']>;
  dev_account?: InputMaybe<Scalars['Boolean']['input']>;
  dial_rule?: InputMaybe<Scalars['Int']['input']>;
  DispositionMultiplier?: InputMaybe<DispositionMultiplierCreateNestedManyWithoutOrganizationInput>;
  dispositions?: InputMaybe<DispositionCreateNestedManyWithoutOrganizationInput>;
  double_booking?: InputMaybe<Scalars['Boolean']['input']>;
  dynamic_date_operators?: InputMaybe<Scalars['Boolean']['input']>;
  edge_servers?: InputMaybe<OrganizationCreateedge_ServersInput>;
  email_conflict_notification_email_list?: InputMaybe<OrganizationCreateemail_Conflict_Notification_Email_ListInput>;
  EmailItems?: InputMaybe<EmailItemCreateNestedManyWithoutOrganizationInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordCreateNestedManyWithoutOrganizationInput>;
  EmailThreads?: InputMaybe<EmailThreadCreateNestedManyWithoutOrganizationInput>;
  enable_modified_metrics?: InputMaybe<Scalars['Boolean']['input']>;
  enable_pay_period_view?: InputMaybe<Scalars['Boolean']['input']>;
  enable_time_constraint?: InputMaybe<Scalars['Boolean']['input']>;
  error_log_email_address?: InputMaybe<OrganizationCreateerror_Log_Email_AddressInput>;
  estimated_average_num_of_weekly_overbooked_demo_slots?: InputMaybe<Scalars['Int']['input']>;
  FolderPermissions?: InputMaybe<FolderPermissionCreateNestedManyWithoutOrganizationInput>;
  Folders?: InputMaybe<FolderCreateNestedManyWithoutOrganizationInput>;
  FutureBookingSetting?: InputMaybe<FutureBookingSettingCreateNestedOneWithoutOrganizationInput>;
  GlobalTransferRule?: InputMaybe<GlobalTransferRuleCreateNestedManyWithoutOrganizationInput>;
  held_demos_per_cc_connect?: InputMaybe<Scalars['Float']['input']>;
  helpful_links?: InputMaybe<OrganizationCreatehelpful_LinksInput>;
  HingePointMultiplier?: InputMaybe<HingePointMultiplierCreateNestedManyWithoutOrganizationInput>;
  hold_goal?: InputMaybe<Scalars['Float']['input']>;
  hubspot_credential?: InputMaybe<HubSpotCredentialCreateNestedOneWithoutOrganizationInput>;
  hubspot_last_sync_date?: InputMaybe<Scalars['DateTime']['input']>;
  hubspot_white_list?: InputMaybe<HubSpotWhitelistCreateNestedManyWithoutOrganizationInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  inbound_close_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_dm_connect_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_lt_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_revenue_value_of_sale?: InputMaybe<Scalars['Float']['input']>;
  inbound_scheduled_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_set_rate?: InputMaybe<Scalars['Float']['input']>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventCreateNestedManyWithoutOrganizationInput>;
  InboundConciergeWidget?: InputMaybe<InboundConciergeWidgetCreateNestedManyWithoutOrganizationInput>;
  industry_options?: InputMaybe<IndustryOptionCreateNestedManyWithoutOrganizationInput>;
  initial_sdr_sets_reps_day?: InputMaybe<Scalars['Float']['input']>;
  integration_error_logs?: InputMaybe<IntegrationErrorLogCreateNestedManyWithoutOrganizationInput>;
  integration_mappings?: InputMaybe<IntegrationMappingCreateNestedManyWithoutOrganizationInput>;
  IntegrationMappingCustomObject?: InputMaybe<IntegrationMappingCustomObjectCreateNestedManyWithoutOrganizationInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogCreateNestedManyWithoutOrganizationInput>;
  IntegrationReadOnlyObject?: InputMaybe<IntegrationReadOnlyObjectCreateNestedManyWithoutOrganizationInput>;
  international?: InputMaybe<Scalars['Boolean']['input']>;
  is_email_domain_verified?: InputMaybe<Scalars['Boolean']['input']>;
  is_salesforce_sandbox?: InputMaybe<Scalars['Boolean']['input']>;
  last_lead_value_compute_time?: InputMaybe<Scalars['DateTime']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutOrganizationInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryCreateNestedManyWithoutOrganizationInput>;
  lead_intent?: InputMaybe<LeadIntentCreateNestedManyWithoutOrganizationInput>;
  lead_release_same_rep_delay_rule?: InputMaybe<Scalars['Int']['input']>;
  lead_routing_email_list?: InputMaybe<OrganizationCreatelead_Routing_Email_ListInput>;
  lead_routing_rules?: InputMaybe<RuleCreateNestedManyWithoutOrganizationInput>;
  LeadCustomObjectRowAssociation?: InputMaybe<LeadCustomObjectRowAssociationCreateNestedManyWithoutOrganizationInput>;
  LeadFieldMultiplier?: InputMaybe<LeadFieldMultiplierCreateNestedManyWithoutOrganizationInput>;
  LeadFieldMultiplierCustom?: InputMaybe<LeadFieldMultiplierCustomCreateNestedManyWithoutOrganizationInput>;
  leads?: InputMaybe<LeadCreateNestedManyWithoutOrganizationInput>;
  leadsource_options?: InputMaybe<LeadSourceOptionCreateNestedManyWithoutOrganizationInput>;
  LeadValue?: InputMaybe<LeadValueCreateNestedManyWithoutOrganizationInput>;
  LifeTimeValueMultiplier?: InputMaybe<LifeTimeValueMultiplierCreateNestedManyWithoutOrganizationInput>;
  LiveTransferSetting?: InputMaybe<LiveTransferSettingCreateNestedOneWithoutOrganizationInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusCreateNestedManyWithoutOrganizationInput>;
  ltv_multiplier_custom_fields?: InputMaybe<LifeTimeValueMultiplierCustomCreateNestedManyWithoutOrganizationInput>;
  made_contact_rule?: InputMaybe<Scalars['Int']['input']>;
  make_sale_address_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_business_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_city_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_country_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_first_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_industry_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_last_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_lead_source_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_primary_email_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_primary_phone_number_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_state_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_sub_industry_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_zip_required?: InputMaybe<Scalars['Boolean']['input']>;
  maxium_day_lag_set_to_schedule?: InputMaybe<Scalars['Int']['input']>;
  mrr?: InputMaybe<Scalars['Int']['input']>;
  mrr_label?: InputMaybe<Scalars['String']['input']>;
  mrr_required_on_sale?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  NoteItems?: InputMaybe<NoteItemCreateNestedManyWithoutOrganizationInput>;
  Notifications?: InputMaybe<NotificationCreateNestedManyWithoutOrganizationInput>;
  onboardComplete?: InputMaybe<Scalars['Boolean']['input']>;
  Opportunity?: InputMaybe<SfOpportunityCreateNestedManyWithoutOrganizationInput>;
  organization_holidays?: InputMaybe<OrganizationHolidayCreateNestedManyWithoutOrganizationInput>;
  organization_hours?: InputMaybe<OrganizationHourCreateNestedManyWithoutOrganizationInput>;
  OrganizationPaymentSetting?: InputMaybe<OrganizationPaymentSettingsCreateNestedOneWithoutOrganizationInput>;
  OrganizationPaymentSettings?: InputMaybe<OrganizationPaymentSettingsCreateNestedOneWithoutOrganizationsInput>;
  orgCalendarInviteMetadata?: InputMaybe<OrgCalendarInviteMetadataCreateNestedManyWithoutOrganizationInput>;
  OrgDefaultPipelinePerecentValue?: InputMaybe<OrgDefaultPipelinePerecentValueCreateNestedOneWithoutOrganizationInput>;
  OrgHubSpotSync?: InputMaybe<OrgHubSpotSyncCreateNestedOneWithoutOrganizationInput>;
  OrgNoEventDate?: InputMaybe<OrgNoEventDateCreateNestedManyWithoutOrganizationInput>;
  OrgSalesforceSync?: InputMaybe<OrgSalesforceSyncCreateNestedOneWithoutOrganizationInput>;
  OrgTimeslotAvailability?: InputMaybe<OrgTimeslotAvailabilityCreateNestedManyWithoutOrganizationInput>;
  outbound_close_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_dm_connect_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_lt_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_revenue_value_of_sale?: InputMaybe<Scalars['Float']['input']>;
  outbound_scheduled_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_set_rate?: InputMaybe<Scalars['Float']['input']>;
  PandadocData?: InputMaybe<PandadocDataCreateNestedOneWithoutOrganizationInput>;
  PandaDocTemplate?: InputMaybe<PandaDocTemplateCreateNestedManyWithoutOrganizationInput>;
  participant_jitter_buffer_size?: InputMaybe<JitterBufferSize>;
  preferred_goal_setting?: InputMaybe<Goalsetting>;
  prepayment?: InputMaybe<Scalars['Boolean']['input']>;
  ProductChangeLog?: InputMaybe<ProductChangeLogCreateNestedManyWithoutOrganizationInput>;
  products?: InputMaybe<ProductCreateNestedManyWithoutOrganizationInput>;
  re_cold_call_hour_delay?: InputMaybe<Scalars['Float']['input']>;
  recordingStatus?: InputMaybe<RecordingStatus>;
  release_lead_resting?: InputMaybe<Scalars['Int']['input']>;
  rep_claim_limit?: InputMaybe<Scalars['Int']['input']>;
  rep_lead_allotment?: InputMaybe<Scalars['Int']['input']>;
  rep_level_call_transfer?: InputMaybe<Scalars['Boolean']['input']>;
  reps_can_bulk_unassign?: InputMaybe<Scalars['Boolean']['input']>;
  reps_can_view_all_notes?: InputMaybe<Scalars['Boolean']['input']>;
  require_sale_notes?: InputMaybe<Scalars['Boolean']['input']>;
  resting_after_skipped_CC?: InputMaybe<Scalars['Int']['input']>;
  resting_period?: InputMaybe<Scalars['Int']['input']>;
  resting_period_after_unsuccessful_cold_call?: InputMaybe<Scalars['Float']['input']>;
  resting_period_after_unsuccessful_demo?: InputMaybe<Scalars['Float']['input']>;
  revenue_plan?: InputMaybe<RevenuePlanItemCreateNestedManyWithoutOrganizationInput>;
  Routings?: InputMaybe<RoutingCreateNestedManyWithoutOrganizationInput>;
  sale_field_options?: InputMaybe<SaleFieldOptionCreateNestedManyWithoutOrganizationInput>;
  sale_notification_email_list?: InputMaybe<OrganizationCreatesale_Notification_Email_ListInput>;
  SaleConfig?: InputMaybe<SaleConfigCreateNestedOneWithoutOrganizationInput>;
  SaleConfigPage?: InputMaybe<SaleConfigPageCreateNestedManyWithoutOrganizationInput>;
  SaleConfigSection?: InputMaybe<SaleConfigSectionCreateNestedManyWithoutOrganizationInput>;
  SaleCycles?: InputMaybe<SaleCycleCreateNestedManyWithoutOrganizationInput>;
  SaleItems?: InputMaybe<SaleItemCreateNestedManyWithoutOrganizationInput>;
  sales?: InputMaybe<SaleCreateNestedManyWithoutOrganizationInput>;
  salesforce_domain?: InputMaybe<Scalars['String']['input']>;
  salesforce_last_sync_date?: InputMaybe<Scalars['DateTime']['input']>;
  SalesForceColumn?: InputMaybe<SalesForceColumnCreateNestedManyWithoutOrganizationInput>;
  salesForceData?: InputMaybe<SalesForceDataCreateNestedOneWithoutOrganizationInput>;
  ScheduleItems?: InputMaybe<ScheduleItemCreateNestedManyWithoutOrganizationInput>;
  self_sourcing_rule?: InputMaybe<Scalars['Int']['input']>;
  send_error_log_email?: InputMaybe<Scalars['Boolean']['input']>;
  send_lead_routing_email?: InputMaybe<Scalars['Boolean']['input']>;
  sendgrid_email_domain?: InputMaybe<Scalars['String']['input']>;
  sendgrid_email_domain_id?: InputMaybe<Scalars['Int']['input']>;
  sequence?: InputMaybe<SequenceCreateNestedManyWithoutOrganzationInput>;
  SequenceCriteriaConditions?: InputMaybe<SequenceCriteriaConditionCreateNestedManyWithoutOrganizationInput>;
  SequenceDashboardViews?: InputMaybe<SequenceDashboardViewCreateNestedManyWithoutOrganizationInput>;
  SequenceEntryCriterias?: InputMaybe<SequenceEntryCriteriaCreateNestedManyWithoutOrganizationInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutOrganizationInput>;
  SequenceSteps?: InputMaybe<SequenceStepCreateNestedManyWithoutOrganizationInput>;
  set_goal?: InputMaybe<Scalars['Float']['input']>;
  short_resting?: InputMaybe<Scalars['Int']['input']>;
  show_calendar_options?: InputMaybe<Scalars['Boolean']['input']>;
  show_contract_duration_sales?: InputMaybe<Scalars['Boolean']['input']>;
  show_lead_source_to_rep?: InputMaybe<Scalars['Boolean']['input']>;
  show_nocontact_button?: InputMaybe<Scalars['Boolean']['input']>;
  show_payment_terms_sales?: InputMaybe<Scalars['Boolean']['input']>;
  show_rep_all_leads?: InputMaybe<Scalars['Boolean']['input']>;
  show_rep_my_leads?: InputMaybe<Scalars['Boolean']['input']>;
  show_system_view?: InputMaybe<Scalars['Boolean']['input']>;
  sites?: InputMaybe<SiteCreateNestedManyWithoutOrganizationInput>;
  sms_opt_in_dialog?: InputMaybe<Scalars['String']['input']>;
  SMSItems?: InputMaybe<SmsItemCreateNestedManyWithoutOrganizationInput>;
  SnoozeTrackings?: InputMaybe<SnoozeTrackingCreateNestedManyWithoutOrganizationInput>;
  StepActionLogs?: InputMaybe<StepActionLogCreateNestedManyWithoutOrganizationInput>;
  StepActions?: InputMaybe<StepActionCreateNestedManyWithoutOrganizationInput>;
  StepConditions?: InputMaybe<StepConditionCreateNestedManyWithoutOrganizationInput>;
  teams?: InputMaybe<TeamCreateNestedManyWithoutOrganizationInput>;
  Template?: InputMaybe<TemplateCreateNestedManyWithoutOrganizationInput>;
  timekit_id?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  transfer_timeout?: InputMaybe<Scalars['Int']['input']>;
  TransferAttempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutOrganizationInput>;
  twilio_numbers?: InputMaybe<TwilioNumberCreateNestedManyWithoutOrganizationInput>;
  TwilioFeedbacks?: InputMaybe<TwilioFeedbackCreateNestedManyWithoutOrganizationInput>;
  TwilioInfo?: InputMaybe<TwilioInfoCreateNestedOneWithoutOrganzationInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  use_revenue_planning?: InputMaybe<Scalars['Boolean']['input']>;
  UserCustomFieldFilters?: InputMaybe<UserCustomFieldFilterCreateNestedManyWithoutOrganizationInput>;
  users?: InputMaybe<UserCreateNestedManyWithoutOrganizationInput>;
  win_rep_day_goal?: InputMaybe<Scalars['Float']['input']>;
};

export type OrganizationCreateWithoutTransferAttemptsInput = {
  ActivityMonitorMultiplier?: InputMaybe<ActivityMonitorMultiplierCreateNestedManyWithoutOrganizationInput>;
  algoFactor?: InputMaybe<AlgoFactorCreateNestedManyWithoutOrganizationInput>;
  allow_calendar_invite?: InputMaybe<Scalars['Boolean']['input']>;
  allow_remove_step_action?: InputMaybe<Scalars['Boolean']['input']>;
  allow_snooze_step_action?: InputMaybe<Scalars['Boolean']['input']>;
  always_record?: InputMaybe<Scalars['Boolean']['input']>;
  arv_initial_held_demos_generated_by_sdr_weight?: InputMaybe<Scalars['Float']['input']>;
  AssociationHistories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutOrganizationInput>;
  auto_call_interval?: InputMaybe<Scalars['Int']['input']>;
  auto_dial?: InputMaybe<Scalars['Boolean']['input']>;
  automated_campaigns?: InputMaybe<AutomatedCampaignCreateNestedManyWithoutOrganizationInput>;
  automatic_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  AutoTransferSelections?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutOrganizationInput>;
  Availablities?: InputMaybe<AvailabilityCreateNestedManyWithoutOrganizationInput>;
  AverageOrderValue?: InputMaybe<AverageOrderValueCreateNestedManyWithoutOrganizationInput>;
  best_action_ever_multiplier?: InputMaybe<Scalars['Float']['input']>;
  best_metrics?: InputMaybe<BestMetricRecordCreateNestedManyWithoutOrganizationInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptCreateNestedManyWithoutOrganizationInput>;
  callMeNows?: InputMaybe<CallMeNowCreateNestedManyWithoutOrganizationInput>;
  CallMultiplier?: InputMaybe<CallMultiplierCreateNestedManyWithoutOrganizationInput>;
  CallSharings?: InputMaybe<CallSharingCreateNestedManyWithoutOrganizationInput>;
  can_edit_sdr_transfer_rules?: InputMaybe<Scalars['Boolean']['input']>;
  claim_rule?: InputMaybe<Scalars['Int']['input']>;
  concession?: InputMaybe<ConcessionCreateNestedManyWithoutOrganizationInput>;
  conference_region?: InputMaybe<ConferenceRegion>;
  Conferences?: InputMaybe<ConferenceCreateNestedManyWithoutOrganizationInput>;
  ContactRequests?: InputMaybe<ContactRequestCreateNestedManyWithoutOrganizationInput>;
  Contacts?: InputMaybe<ContactCreateNestedManyWithoutOrganizationInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  custom_fields?: InputMaybe<CustomFieldCreateNestedManyWithoutOrganizationInput>;
  CustomObject?: InputMaybe<CustomObjectCreateNestedManyWithoutOrganizationInput>;
  CustomObjectField?: InputMaybe<CustomObjectFieldCreateNestedManyWithoutOrganizationInput>;
  CustomObjectFieldForeignKey?: InputMaybe<CustomObjectFieldForeignKeyCreateNestedManyWithoutOrganizationInput>;
  CustomObjectRecord?: InputMaybe<CustomObjectRecordCreateNestedManyWithoutOrganizationInput>;
  CustomObjectRow?: InputMaybe<CustomObjectRowCreateNestedManyWithoutOrganizationInput>;
  CustomQueueItems?: InputMaybe<CustomQueueItemCreateNestedManyWithoutOrganizationInput>;
  DashboardColumnOptions?: InputMaybe<DashboardColumnOptionCreateNestedManyWithoutOrganizationInput>;
  DashboardLeadFilters?: InputMaybe<DashboardLeadFilterCreateNestedManyWithoutOrganizationInput>;
  DashboardRepFilters?: InputMaybe<DashboardRepFilterCreateNestedManyWithoutOrganizationInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewCreateNestedManyWithoutOrganizationInput>;
  day_to_close?: InputMaybe<Scalars['Int']['input']>;
  default_demo_time_duration?: InputMaybe<Scalars['Int']['input']>;
  demo_held_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_not_held_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_not_held_call_back_short_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_prep_time_minutes?: InputMaybe<Scalars['Int']['input']>;
  dev_account?: InputMaybe<Scalars['Boolean']['input']>;
  dial_rule?: InputMaybe<Scalars['Int']['input']>;
  DispositionMultiplier?: InputMaybe<DispositionMultiplierCreateNestedManyWithoutOrganizationInput>;
  dispositions?: InputMaybe<DispositionCreateNestedManyWithoutOrganizationInput>;
  double_booking?: InputMaybe<Scalars['Boolean']['input']>;
  dynamic_date_operators?: InputMaybe<Scalars['Boolean']['input']>;
  edge_servers?: InputMaybe<OrganizationCreateedge_ServersInput>;
  email_conflict_notification_email_list?: InputMaybe<OrganizationCreateemail_Conflict_Notification_Email_ListInput>;
  EmailItems?: InputMaybe<EmailItemCreateNestedManyWithoutOrganizationInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordCreateNestedManyWithoutOrganizationInput>;
  EmailThreads?: InputMaybe<EmailThreadCreateNestedManyWithoutOrganizationInput>;
  enable_modified_metrics?: InputMaybe<Scalars['Boolean']['input']>;
  enable_pay_period_view?: InputMaybe<Scalars['Boolean']['input']>;
  enable_time_constraint?: InputMaybe<Scalars['Boolean']['input']>;
  error_log_email_address?: InputMaybe<OrganizationCreateerror_Log_Email_AddressInput>;
  estimated_average_num_of_weekly_overbooked_demo_slots?: InputMaybe<Scalars['Int']['input']>;
  FolderPermissions?: InputMaybe<FolderPermissionCreateNestedManyWithoutOrganizationInput>;
  Folders?: InputMaybe<FolderCreateNestedManyWithoutOrganizationInput>;
  FutureBookingSetting?: InputMaybe<FutureBookingSettingCreateNestedOneWithoutOrganizationInput>;
  GlobalTransferRule?: InputMaybe<GlobalTransferRuleCreateNestedManyWithoutOrganizationInput>;
  held_demos_per_cc_connect?: InputMaybe<Scalars['Float']['input']>;
  helpful_links?: InputMaybe<OrganizationCreatehelpful_LinksInput>;
  HingePointMultiplier?: InputMaybe<HingePointMultiplierCreateNestedManyWithoutOrganizationInput>;
  hold_goal?: InputMaybe<Scalars['Float']['input']>;
  hubspot_credential?: InputMaybe<HubSpotCredentialCreateNestedOneWithoutOrganizationInput>;
  hubspot_last_sync_date?: InputMaybe<Scalars['DateTime']['input']>;
  hubspot_white_list?: InputMaybe<HubSpotWhitelistCreateNestedManyWithoutOrganizationInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  inbound_close_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_dm_connect_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_lt_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_revenue_value_of_sale?: InputMaybe<Scalars['Float']['input']>;
  inbound_scheduled_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_set_rate?: InputMaybe<Scalars['Float']['input']>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventCreateNestedManyWithoutOrganizationInput>;
  InboundConciergeWidget?: InputMaybe<InboundConciergeWidgetCreateNestedManyWithoutOrganizationInput>;
  industry_options?: InputMaybe<IndustryOptionCreateNestedManyWithoutOrganizationInput>;
  initial_sdr_sets_reps_day?: InputMaybe<Scalars['Float']['input']>;
  integration_error_logs?: InputMaybe<IntegrationErrorLogCreateNestedManyWithoutOrganizationInput>;
  integration_mappings?: InputMaybe<IntegrationMappingCreateNestedManyWithoutOrganizationInput>;
  IntegrationMappingCustomObject?: InputMaybe<IntegrationMappingCustomObjectCreateNestedManyWithoutOrganizationInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogCreateNestedManyWithoutOrganizationInput>;
  IntegrationReadOnlyObject?: InputMaybe<IntegrationReadOnlyObjectCreateNestedManyWithoutOrganizationInput>;
  international?: InputMaybe<Scalars['Boolean']['input']>;
  is_email_domain_verified?: InputMaybe<Scalars['Boolean']['input']>;
  is_salesforce_sandbox?: InputMaybe<Scalars['Boolean']['input']>;
  last_lead_value_compute_time?: InputMaybe<Scalars['DateTime']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutOrganizationInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryCreateNestedManyWithoutOrganizationInput>;
  lead_intent?: InputMaybe<LeadIntentCreateNestedManyWithoutOrganizationInput>;
  lead_release_same_rep_delay_rule?: InputMaybe<Scalars['Int']['input']>;
  lead_routing_email_list?: InputMaybe<OrganizationCreatelead_Routing_Email_ListInput>;
  lead_routing_rules?: InputMaybe<RuleCreateNestedManyWithoutOrganizationInput>;
  LeadCustomObjectRowAssociation?: InputMaybe<LeadCustomObjectRowAssociationCreateNestedManyWithoutOrganizationInput>;
  LeadFieldMultiplier?: InputMaybe<LeadFieldMultiplierCreateNestedManyWithoutOrganizationInput>;
  LeadFieldMultiplierCustom?: InputMaybe<LeadFieldMultiplierCustomCreateNestedManyWithoutOrganizationInput>;
  leads?: InputMaybe<LeadCreateNestedManyWithoutOrganizationInput>;
  leadsource_options?: InputMaybe<LeadSourceOptionCreateNestedManyWithoutOrganizationInput>;
  LeadValue?: InputMaybe<LeadValueCreateNestedManyWithoutOrganizationInput>;
  LifeTimeValueMultiplier?: InputMaybe<LifeTimeValueMultiplierCreateNestedManyWithoutOrganizationInput>;
  LiveTransferSetting?: InputMaybe<LiveTransferSettingCreateNestedOneWithoutOrganizationInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusCreateNestedManyWithoutOrganizationInput>;
  ltv_multiplier_custom_fields?: InputMaybe<LifeTimeValueMultiplierCustomCreateNestedManyWithoutOrganizationInput>;
  made_contact_rule?: InputMaybe<Scalars['Int']['input']>;
  make_sale_address_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_business_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_city_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_country_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_first_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_industry_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_last_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_lead_source_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_primary_email_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_primary_phone_number_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_state_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_sub_industry_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_zip_required?: InputMaybe<Scalars['Boolean']['input']>;
  maxium_day_lag_set_to_schedule?: InputMaybe<Scalars['Int']['input']>;
  mrr?: InputMaybe<Scalars['Int']['input']>;
  mrr_label?: InputMaybe<Scalars['String']['input']>;
  mrr_required_on_sale?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  NoteItems?: InputMaybe<NoteItemCreateNestedManyWithoutOrganizationInput>;
  Notifications?: InputMaybe<NotificationCreateNestedManyWithoutOrganizationInput>;
  onboardComplete?: InputMaybe<Scalars['Boolean']['input']>;
  Opportunity?: InputMaybe<SfOpportunityCreateNestedManyWithoutOrganizationInput>;
  organization_holidays?: InputMaybe<OrganizationHolidayCreateNestedManyWithoutOrganizationInput>;
  organization_hours?: InputMaybe<OrganizationHourCreateNestedManyWithoutOrganizationInput>;
  OrganizationPaymentSetting?: InputMaybe<OrganizationPaymentSettingsCreateNestedOneWithoutOrganizationInput>;
  OrganizationPaymentSettings?: InputMaybe<OrganizationPaymentSettingsCreateNestedOneWithoutOrganizationsInput>;
  orgCalendarInviteMetadata?: InputMaybe<OrgCalendarInviteMetadataCreateNestedManyWithoutOrganizationInput>;
  OrgDefaultPipelinePerecentValue?: InputMaybe<OrgDefaultPipelinePerecentValueCreateNestedOneWithoutOrganizationInput>;
  OrgHubSpotSync?: InputMaybe<OrgHubSpotSyncCreateNestedOneWithoutOrganizationInput>;
  OrgNoEventDate?: InputMaybe<OrgNoEventDateCreateNestedManyWithoutOrganizationInput>;
  OrgSalesforceSync?: InputMaybe<OrgSalesforceSyncCreateNestedOneWithoutOrganizationInput>;
  OrgTimeslotAvailability?: InputMaybe<OrgTimeslotAvailabilityCreateNestedManyWithoutOrganizationInput>;
  outbound_close_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_dm_connect_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_lt_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_revenue_value_of_sale?: InputMaybe<Scalars['Float']['input']>;
  outbound_scheduled_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_set_rate?: InputMaybe<Scalars['Float']['input']>;
  PandadocData?: InputMaybe<PandadocDataCreateNestedOneWithoutOrganizationInput>;
  PandaDocTemplate?: InputMaybe<PandaDocTemplateCreateNestedManyWithoutOrganizationInput>;
  participant_jitter_buffer_size?: InputMaybe<JitterBufferSize>;
  preferred_goal_setting?: InputMaybe<Goalsetting>;
  prepayment?: InputMaybe<Scalars['Boolean']['input']>;
  ProductChangeLog?: InputMaybe<ProductChangeLogCreateNestedManyWithoutOrganizationInput>;
  products?: InputMaybe<ProductCreateNestedManyWithoutOrganizationInput>;
  re_cold_call_hour_delay?: InputMaybe<Scalars['Float']['input']>;
  recordingStatus?: InputMaybe<RecordingStatus>;
  release_lead_resting?: InputMaybe<Scalars['Int']['input']>;
  rep_claim_limit?: InputMaybe<Scalars['Int']['input']>;
  rep_lead_allotment?: InputMaybe<Scalars['Int']['input']>;
  rep_level_call_transfer?: InputMaybe<Scalars['Boolean']['input']>;
  reps_can_bulk_unassign?: InputMaybe<Scalars['Boolean']['input']>;
  reps_can_view_all_notes?: InputMaybe<Scalars['Boolean']['input']>;
  require_sale_notes?: InputMaybe<Scalars['Boolean']['input']>;
  resting_after_skipped_CC?: InputMaybe<Scalars['Int']['input']>;
  resting_period?: InputMaybe<Scalars['Int']['input']>;
  resting_period_after_unsuccessful_cold_call?: InputMaybe<Scalars['Float']['input']>;
  resting_period_after_unsuccessful_demo?: InputMaybe<Scalars['Float']['input']>;
  revenue_plan?: InputMaybe<RevenuePlanItemCreateNestedManyWithoutOrganizationInput>;
  Routings?: InputMaybe<RoutingCreateNestedManyWithoutOrganizationInput>;
  sale_field_options?: InputMaybe<SaleFieldOptionCreateNestedManyWithoutOrganizationInput>;
  sale_notification_email_list?: InputMaybe<OrganizationCreatesale_Notification_Email_ListInput>;
  SaleConfig?: InputMaybe<SaleConfigCreateNestedOneWithoutOrganizationInput>;
  SaleConfigPage?: InputMaybe<SaleConfigPageCreateNestedManyWithoutOrganizationInput>;
  SaleConfigSection?: InputMaybe<SaleConfigSectionCreateNestedManyWithoutOrganizationInput>;
  SaleCycles?: InputMaybe<SaleCycleCreateNestedManyWithoutOrganizationInput>;
  SaleItems?: InputMaybe<SaleItemCreateNestedManyWithoutOrganizationInput>;
  sales?: InputMaybe<SaleCreateNestedManyWithoutOrganizationInput>;
  salesforce_domain?: InputMaybe<Scalars['String']['input']>;
  salesforce_last_sync_date?: InputMaybe<Scalars['DateTime']['input']>;
  SalesForceColumn?: InputMaybe<SalesForceColumnCreateNestedManyWithoutOrganizationInput>;
  salesForceData?: InputMaybe<SalesForceDataCreateNestedOneWithoutOrganizationInput>;
  ScheduleItems?: InputMaybe<ScheduleItemCreateNestedManyWithoutOrganizationInput>;
  self_sourcing_rule?: InputMaybe<Scalars['Int']['input']>;
  send_error_log_email?: InputMaybe<Scalars['Boolean']['input']>;
  send_lead_routing_email?: InputMaybe<Scalars['Boolean']['input']>;
  sendgrid_email_domain?: InputMaybe<Scalars['String']['input']>;
  sendgrid_email_domain_id?: InputMaybe<Scalars['Int']['input']>;
  sequence?: InputMaybe<SequenceCreateNestedManyWithoutOrganzationInput>;
  SequenceCriteriaConditions?: InputMaybe<SequenceCriteriaConditionCreateNestedManyWithoutOrganizationInput>;
  SequenceDashboardViews?: InputMaybe<SequenceDashboardViewCreateNestedManyWithoutOrganizationInput>;
  SequenceEntryCriterias?: InputMaybe<SequenceEntryCriteriaCreateNestedManyWithoutOrganizationInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutOrganizationInput>;
  SequenceSteps?: InputMaybe<SequenceStepCreateNestedManyWithoutOrganizationInput>;
  set_goal?: InputMaybe<Scalars['Float']['input']>;
  short_resting?: InputMaybe<Scalars['Int']['input']>;
  show_calendar_options?: InputMaybe<Scalars['Boolean']['input']>;
  show_contract_duration_sales?: InputMaybe<Scalars['Boolean']['input']>;
  show_lead_source_to_rep?: InputMaybe<Scalars['Boolean']['input']>;
  show_nocontact_button?: InputMaybe<Scalars['Boolean']['input']>;
  show_payment_terms_sales?: InputMaybe<Scalars['Boolean']['input']>;
  show_rep_all_leads?: InputMaybe<Scalars['Boolean']['input']>;
  show_rep_my_leads?: InputMaybe<Scalars['Boolean']['input']>;
  show_system_view?: InputMaybe<Scalars['Boolean']['input']>;
  sites?: InputMaybe<SiteCreateNestedManyWithoutOrganizationInput>;
  sms_opt_in_dialog?: InputMaybe<Scalars['String']['input']>;
  SMSItems?: InputMaybe<SmsItemCreateNestedManyWithoutOrganizationInput>;
  SnoozeTrackings?: InputMaybe<SnoozeTrackingCreateNestedManyWithoutOrganizationInput>;
  StepActionLogs?: InputMaybe<StepActionLogCreateNestedManyWithoutOrganizationInput>;
  StepActions?: InputMaybe<StepActionCreateNestedManyWithoutOrganizationInput>;
  StepConditions?: InputMaybe<StepConditionCreateNestedManyWithoutOrganizationInput>;
  teams?: InputMaybe<TeamCreateNestedManyWithoutOrganizationInput>;
  Template?: InputMaybe<TemplateCreateNestedManyWithoutOrganizationInput>;
  TemplateVersions?: InputMaybe<TemplateVersionCreateNestedManyWithoutOrganizationInput>;
  timekit_id?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  transfer_timeout?: InputMaybe<Scalars['Int']['input']>;
  twilio_numbers?: InputMaybe<TwilioNumberCreateNestedManyWithoutOrganizationInput>;
  TwilioFeedbacks?: InputMaybe<TwilioFeedbackCreateNestedManyWithoutOrganizationInput>;
  TwilioInfo?: InputMaybe<TwilioInfoCreateNestedOneWithoutOrganzationInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  use_revenue_planning?: InputMaybe<Scalars['Boolean']['input']>;
  UserCustomFieldFilters?: InputMaybe<UserCustomFieldFilterCreateNestedManyWithoutOrganizationInput>;
  users?: InputMaybe<UserCreateNestedManyWithoutOrganizationInput>;
  win_rep_day_goal?: InputMaybe<Scalars['Float']['input']>;
};

export type OrganizationCreateWithoutTwilio_NumbersInput = {
  ActivityMonitorMultiplier?: InputMaybe<ActivityMonitorMultiplierCreateNestedManyWithoutOrganizationInput>;
  algoFactor?: InputMaybe<AlgoFactorCreateNestedManyWithoutOrganizationInput>;
  allow_calendar_invite?: InputMaybe<Scalars['Boolean']['input']>;
  allow_remove_step_action?: InputMaybe<Scalars['Boolean']['input']>;
  allow_snooze_step_action?: InputMaybe<Scalars['Boolean']['input']>;
  always_record?: InputMaybe<Scalars['Boolean']['input']>;
  arv_initial_held_demos_generated_by_sdr_weight?: InputMaybe<Scalars['Float']['input']>;
  AssociationHistories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutOrganizationInput>;
  auto_call_interval?: InputMaybe<Scalars['Int']['input']>;
  auto_dial?: InputMaybe<Scalars['Boolean']['input']>;
  automated_campaigns?: InputMaybe<AutomatedCampaignCreateNestedManyWithoutOrganizationInput>;
  automatic_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  AutoTransferSelections?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutOrganizationInput>;
  Availablities?: InputMaybe<AvailabilityCreateNestedManyWithoutOrganizationInput>;
  AverageOrderValue?: InputMaybe<AverageOrderValueCreateNestedManyWithoutOrganizationInput>;
  best_action_ever_multiplier?: InputMaybe<Scalars['Float']['input']>;
  best_metrics?: InputMaybe<BestMetricRecordCreateNestedManyWithoutOrganizationInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptCreateNestedManyWithoutOrganizationInput>;
  callMeNows?: InputMaybe<CallMeNowCreateNestedManyWithoutOrganizationInput>;
  CallMultiplier?: InputMaybe<CallMultiplierCreateNestedManyWithoutOrganizationInput>;
  CallSharings?: InputMaybe<CallSharingCreateNestedManyWithoutOrganizationInput>;
  can_edit_sdr_transfer_rules?: InputMaybe<Scalars['Boolean']['input']>;
  claim_rule?: InputMaybe<Scalars['Int']['input']>;
  concession?: InputMaybe<ConcessionCreateNestedManyWithoutOrganizationInput>;
  conference_region?: InputMaybe<ConferenceRegion>;
  Conferences?: InputMaybe<ConferenceCreateNestedManyWithoutOrganizationInput>;
  ContactRequests?: InputMaybe<ContactRequestCreateNestedManyWithoutOrganizationInput>;
  Contacts?: InputMaybe<ContactCreateNestedManyWithoutOrganizationInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  custom_fields?: InputMaybe<CustomFieldCreateNestedManyWithoutOrganizationInput>;
  CustomObject?: InputMaybe<CustomObjectCreateNestedManyWithoutOrganizationInput>;
  CustomObjectField?: InputMaybe<CustomObjectFieldCreateNestedManyWithoutOrganizationInput>;
  CustomObjectFieldForeignKey?: InputMaybe<CustomObjectFieldForeignKeyCreateNestedManyWithoutOrganizationInput>;
  CustomObjectRecord?: InputMaybe<CustomObjectRecordCreateNestedManyWithoutOrganizationInput>;
  CustomObjectRow?: InputMaybe<CustomObjectRowCreateNestedManyWithoutOrganizationInput>;
  CustomQueueItems?: InputMaybe<CustomQueueItemCreateNestedManyWithoutOrganizationInput>;
  DashboardColumnOptions?: InputMaybe<DashboardColumnOptionCreateNestedManyWithoutOrganizationInput>;
  DashboardLeadFilters?: InputMaybe<DashboardLeadFilterCreateNestedManyWithoutOrganizationInput>;
  DashboardRepFilters?: InputMaybe<DashboardRepFilterCreateNestedManyWithoutOrganizationInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewCreateNestedManyWithoutOrganizationInput>;
  day_to_close?: InputMaybe<Scalars['Int']['input']>;
  default_demo_time_duration?: InputMaybe<Scalars['Int']['input']>;
  demo_held_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_not_held_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_not_held_call_back_short_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_prep_time_minutes?: InputMaybe<Scalars['Int']['input']>;
  dev_account?: InputMaybe<Scalars['Boolean']['input']>;
  dial_rule?: InputMaybe<Scalars['Int']['input']>;
  DispositionMultiplier?: InputMaybe<DispositionMultiplierCreateNestedManyWithoutOrganizationInput>;
  dispositions?: InputMaybe<DispositionCreateNestedManyWithoutOrganizationInput>;
  double_booking?: InputMaybe<Scalars['Boolean']['input']>;
  dynamic_date_operators?: InputMaybe<Scalars['Boolean']['input']>;
  edge_servers?: InputMaybe<OrganizationCreateedge_ServersInput>;
  email_conflict_notification_email_list?: InputMaybe<OrganizationCreateemail_Conflict_Notification_Email_ListInput>;
  EmailItems?: InputMaybe<EmailItemCreateNestedManyWithoutOrganizationInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordCreateNestedManyWithoutOrganizationInput>;
  EmailThreads?: InputMaybe<EmailThreadCreateNestedManyWithoutOrganizationInput>;
  enable_modified_metrics?: InputMaybe<Scalars['Boolean']['input']>;
  enable_pay_period_view?: InputMaybe<Scalars['Boolean']['input']>;
  enable_time_constraint?: InputMaybe<Scalars['Boolean']['input']>;
  error_log_email_address?: InputMaybe<OrganizationCreateerror_Log_Email_AddressInput>;
  estimated_average_num_of_weekly_overbooked_demo_slots?: InputMaybe<Scalars['Int']['input']>;
  FolderPermissions?: InputMaybe<FolderPermissionCreateNestedManyWithoutOrganizationInput>;
  Folders?: InputMaybe<FolderCreateNestedManyWithoutOrganizationInput>;
  FutureBookingSetting?: InputMaybe<FutureBookingSettingCreateNestedOneWithoutOrganizationInput>;
  GlobalTransferRule?: InputMaybe<GlobalTransferRuleCreateNestedManyWithoutOrganizationInput>;
  held_demos_per_cc_connect?: InputMaybe<Scalars['Float']['input']>;
  helpful_links?: InputMaybe<OrganizationCreatehelpful_LinksInput>;
  HingePointMultiplier?: InputMaybe<HingePointMultiplierCreateNestedManyWithoutOrganizationInput>;
  hold_goal?: InputMaybe<Scalars['Float']['input']>;
  hubspot_credential?: InputMaybe<HubSpotCredentialCreateNestedOneWithoutOrganizationInput>;
  hubspot_last_sync_date?: InputMaybe<Scalars['DateTime']['input']>;
  hubspot_white_list?: InputMaybe<HubSpotWhitelistCreateNestedManyWithoutOrganizationInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  inbound_close_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_dm_connect_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_lt_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_revenue_value_of_sale?: InputMaybe<Scalars['Float']['input']>;
  inbound_scheduled_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_set_rate?: InputMaybe<Scalars['Float']['input']>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventCreateNestedManyWithoutOrganizationInput>;
  InboundConciergeWidget?: InputMaybe<InboundConciergeWidgetCreateNestedManyWithoutOrganizationInput>;
  industry_options?: InputMaybe<IndustryOptionCreateNestedManyWithoutOrganizationInput>;
  initial_sdr_sets_reps_day?: InputMaybe<Scalars['Float']['input']>;
  integration_error_logs?: InputMaybe<IntegrationErrorLogCreateNestedManyWithoutOrganizationInput>;
  integration_mappings?: InputMaybe<IntegrationMappingCreateNestedManyWithoutOrganizationInput>;
  IntegrationMappingCustomObject?: InputMaybe<IntegrationMappingCustomObjectCreateNestedManyWithoutOrganizationInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogCreateNestedManyWithoutOrganizationInput>;
  IntegrationReadOnlyObject?: InputMaybe<IntegrationReadOnlyObjectCreateNestedManyWithoutOrganizationInput>;
  international?: InputMaybe<Scalars['Boolean']['input']>;
  is_email_domain_verified?: InputMaybe<Scalars['Boolean']['input']>;
  is_salesforce_sandbox?: InputMaybe<Scalars['Boolean']['input']>;
  last_lead_value_compute_time?: InputMaybe<Scalars['DateTime']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutOrganizationInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryCreateNestedManyWithoutOrganizationInput>;
  lead_intent?: InputMaybe<LeadIntentCreateNestedManyWithoutOrganizationInput>;
  lead_release_same_rep_delay_rule?: InputMaybe<Scalars['Int']['input']>;
  lead_routing_email_list?: InputMaybe<OrganizationCreatelead_Routing_Email_ListInput>;
  lead_routing_rules?: InputMaybe<RuleCreateNestedManyWithoutOrganizationInput>;
  LeadCustomObjectRowAssociation?: InputMaybe<LeadCustomObjectRowAssociationCreateNestedManyWithoutOrganizationInput>;
  LeadFieldMultiplier?: InputMaybe<LeadFieldMultiplierCreateNestedManyWithoutOrganizationInput>;
  LeadFieldMultiplierCustom?: InputMaybe<LeadFieldMultiplierCustomCreateNestedManyWithoutOrganizationInput>;
  leads?: InputMaybe<LeadCreateNestedManyWithoutOrganizationInput>;
  leadsource_options?: InputMaybe<LeadSourceOptionCreateNestedManyWithoutOrganizationInput>;
  LeadValue?: InputMaybe<LeadValueCreateNestedManyWithoutOrganizationInput>;
  LifeTimeValueMultiplier?: InputMaybe<LifeTimeValueMultiplierCreateNestedManyWithoutOrganizationInput>;
  LiveTransferSetting?: InputMaybe<LiveTransferSettingCreateNestedOneWithoutOrganizationInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusCreateNestedManyWithoutOrganizationInput>;
  ltv_multiplier_custom_fields?: InputMaybe<LifeTimeValueMultiplierCustomCreateNestedManyWithoutOrganizationInput>;
  made_contact_rule?: InputMaybe<Scalars['Int']['input']>;
  make_sale_address_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_business_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_city_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_country_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_first_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_industry_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_last_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_lead_source_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_primary_email_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_primary_phone_number_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_state_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_sub_industry_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_zip_required?: InputMaybe<Scalars['Boolean']['input']>;
  maxium_day_lag_set_to_schedule?: InputMaybe<Scalars['Int']['input']>;
  mrr?: InputMaybe<Scalars['Int']['input']>;
  mrr_label?: InputMaybe<Scalars['String']['input']>;
  mrr_required_on_sale?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  NoteItems?: InputMaybe<NoteItemCreateNestedManyWithoutOrganizationInput>;
  Notifications?: InputMaybe<NotificationCreateNestedManyWithoutOrganizationInput>;
  onboardComplete?: InputMaybe<Scalars['Boolean']['input']>;
  Opportunity?: InputMaybe<SfOpportunityCreateNestedManyWithoutOrganizationInput>;
  organization_holidays?: InputMaybe<OrganizationHolidayCreateNestedManyWithoutOrganizationInput>;
  organization_hours?: InputMaybe<OrganizationHourCreateNestedManyWithoutOrganizationInput>;
  OrganizationPaymentSetting?: InputMaybe<OrganizationPaymentSettingsCreateNestedOneWithoutOrganizationInput>;
  OrganizationPaymentSettings?: InputMaybe<OrganizationPaymentSettingsCreateNestedOneWithoutOrganizationsInput>;
  orgCalendarInviteMetadata?: InputMaybe<OrgCalendarInviteMetadataCreateNestedManyWithoutOrganizationInput>;
  OrgDefaultPipelinePerecentValue?: InputMaybe<OrgDefaultPipelinePerecentValueCreateNestedOneWithoutOrganizationInput>;
  OrgHubSpotSync?: InputMaybe<OrgHubSpotSyncCreateNestedOneWithoutOrganizationInput>;
  OrgNoEventDate?: InputMaybe<OrgNoEventDateCreateNestedManyWithoutOrganizationInput>;
  OrgSalesforceSync?: InputMaybe<OrgSalesforceSyncCreateNestedOneWithoutOrganizationInput>;
  OrgTimeslotAvailability?: InputMaybe<OrgTimeslotAvailabilityCreateNestedManyWithoutOrganizationInput>;
  outbound_close_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_dm_connect_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_lt_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_revenue_value_of_sale?: InputMaybe<Scalars['Float']['input']>;
  outbound_scheduled_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_set_rate?: InputMaybe<Scalars['Float']['input']>;
  PandadocData?: InputMaybe<PandadocDataCreateNestedOneWithoutOrganizationInput>;
  PandaDocTemplate?: InputMaybe<PandaDocTemplateCreateNestedManyWithoutOrganizationInput>;
  participant_jitter_buffer_size?: InputMaybe<JitterBufferSize>;
  preferred_goal_setting?: InputMaybe<Goalsetting>;
  prepayment?: InputMaybe<Scalars['Boolean']['input']>;
  ProductChangeLog?: InputMaybe<ProductChangeLogCreateNestedManyWithoutOrganizationInput>;
  products?: InputMaybe<ProductCreateNestedManyWithoutOrganizationInput>;
  re_cold_call_hour_delay?: InputMaybe<Scalars['Float']['input']>;
  recordingStatus?: InputMaybe<RecordingStatus>;
  release_lead_resting?: InputMaybe<Scalars['Int']['input']>;
  rep_claim_limit?: InputMaybe<Scalars['Int']['input']>;
  rep_lead_allotment?: InputMaybe<Scalars['Int']['input']>;
  rep_level_call_transfer?: InputMaybe<Scalars['Boolean']['input']>;
  reps_can_bulk_unassign?: InputMaybe<Scalars['Boolean']['input']>;
  reps_can_view_all_notes?: InputMaybe<Scalars['Boolean']['input']>;
  require_sale_notes?: InputMaybe<Scalars['Boolean']['input']>;
  resting_after_skipped_CC?: InputMaybe<Scalars['Int']['input']>;
  resting_period?: InputMaybe<Scalars['Int']['input']>;
  resting_period_after_unsuccessful_cold_call?: InputMaybe<Scalars['Float']['input']>;
  resting_period_after_unsuccessful_demo?: InputMaybe<Scalars['Float']['input']>;
  revenue_plan?: InputMaybe<RevenuePlanItemCreateNestedManyWithoutOrganizationInput>;
  Routings?: InputMaybe<RoutingCreateNestedManyWithoutOrganizationInput>;
  sale_field_options?: InputMaybe<SaleFieldOptionCreateNestedManyWithoutOrganizationInput>;
  sale_notification_email_list?: InputMaybe<OrganizationCreatesale_Notification_Email_ListInput>;
  SaleConfig?: InputMaybe<SaleConfigCreateNestedOneWithoutOrganizationInput>;
  SaleConfigPage?: InputMaybe<SaleConfigPageCreateNestedManyWithoutOrganizationInput>;
  SaleConfigSection?: InputMaybe<SaleConfigSectionCreateNestedManyWithoutOrganizationInput>;
  SaleCycles?: InputMaybe<SaleCycleCreateNestedManyWithoutOrganizationInput>;
  SaleItems?: InputMaybe<SaleItemCreateNestedManyWithoutOrganizationInput>;
  sales?: InputMaybe<SaleCreateNestedManyWithoutOrganizationInput>;
  salesforce_domain?: InputMaybe<Scalars['String']['input']>;
  salesforce_last_sync_date?: InputMaybe<Scalars['DateTime']['input']>;
  SalesForceColumn?: InputMaybe<SalesForceColumnCreateNestedManyWithoutOrganizationInput>;
  salesForceData?: InputMaybe<SalesForceDataCreateNestedOneWithoutOrganizationInput>;
  ScheduleItems?: InputMaybe<ScheduleItemCreateNestedManyWithoutOrganizationInput>;
  self_sourcing_rule?: InputMaybe<Scalars['Int']['input']>;
  send_error_log_email?: InputMaybe<Scalars['Boolean']['input']>;
  send_lead_routing_email?: InputMaybe<Scalars['Boolean']['input']>;
  sendgrid_email_domain?: InputMaybe<Scalars['String']['input']>;
  sendgrid_email_domain_id?: InputMaybe<Scalars['Int']['input']>;
  sequence?: InputMaybe<SequenceCreateNestedManyWithoutOrganzationInput>;
  SequenceCriteriaConditions?: InputMaybe<SequenceCriteriaConditionCreateNestedManyWithoutOrganizationInput>;
  SequenceDashboardViews?: InputMaybe<SequenceDashboardViewCreateNestedManyWithoutOrganizationInput>;
  SequenceEntryCriterias?: InputMaybe<SequenceEntryCriteriaCreateNestedManyWithoutOrganizationInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutOrganizationInput>;
  SequenceSteps?: InputMaybe<SequenceStepCreateNestedManyWithoutOrganizationInput>;
  set_goal?: InputMaybe<Scalars['Float']['input']>;
  short_resting?: InputMaybe<Scalars['Int']['input']>;
  show_calendar_options?: InputMaybe<Scalars['Boolean']['input']>;
  show_contract_duration_sales?: InputMaybe<Scalars['Boolean']['input']>;
  show_lead_source_to_rep?: InputMaybe<Scalars['Boolean']['input']>;
  show_nocontact_button?: InputMaybe<Scalars['Boolean']['input']>;
  show_payment_terms_sales?: InputMaybe<Scalars['Boolean']['input']>;
  show_rep_all_leads?: InputMaybe<Scalars['Boolean']['input']>;
  show_rep_my_leads?: InputMaybe<Scalars['Boolean']['input']>;
  show_system_view?: InputMaybe<Scalars['Boolean']['input']>;
  sites?: InputMaybe<SiteCreateNestedManyWithoutOrganizationInput>;
  sms_opt_in_dialog?: InputMaybe<Scalars['String']['input']>;
  SMSItems?: InputMaybe<SmsItemCreateNestedManyWithoutOrganizationInput>;
  SnoozeTrackings?: InputMaybe<SnoozeTrackingCreateNestedManyWithoutOrganizationInput>;
  StepActionLogs?: InputMaybe<StepActionLogCreateNestedManyWithoutOrganizationInput>;
  StepActions?: InputMaybe<StepActionCreateNestedManyWithoutOrganizationInput>;
  StepConditions?: InputMaybe<StepConditionCreateNestedManyWithoutOrganizationInput>;
  teams?: InputMaybe<TeamCreateNestedManyWithoutOrganizationInput>;
  Template?: InputMaybe<TemplateCreateNestedManyWithoutOrganizationInput>;
  TemplateVersions?: InputMaybe<TemplateVersionCreateNestedManyWithoutOrganizationInput>;
  timekit_id?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  transfer_timeout?: InputMaybe<Scalars['Int']['input']>;
  TransferAttempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutOrganizationInput>;
  TwilioFeedbacks?: InputMaybe<TwilioFeedbackCreateNestedManyWithoutOrganizationInput>;
  TwilioInfo?: InputMaybe<TwilioInfoCreateNestedOneWithoutOrganzationInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  use_revenue_planning?: InputMaybe<Scalars['Boolean']['input']>;
  UserCustomFieldFilters?: InputMaybe<UserCustomFieldFilterCreateNestedManyWithoutOrganizationInput>;
  users?: InputMaybe<UserCreateNestedManyWithoutOrganizationInput>;
  win_rep_day_goal?: InputMaybe<Scalars['Float']['input']>;
};

export type OrganizationCreateWithoutTwilioFeedbacksInput = {
  ActivityMonitorMultiplier?: InputMaybe<ActivityMonitorMultiplierCreateNestedManyWithoutOrganizationInput>;
  algoFactor?: InputMaybe<AlgoFactorCreateNestedManyWithoutOrganizationInput>;
  allow_calendar_invite?: InputMaybe<Scalars['Boolean']['input']>;
  allow_remove_step_action?: InputMaybe<Scalars['Boolean']['input']>;
  allow_snooze_step_action?: InputMaybe<Scalars['Boolean']['input']>;
  always_record?: InputMaybe<Scalars['Boolean']['input']>;
  arv_initial_held_demos_generated_by_sdr_weight?: InputMaybe<Scalars['Float']['input']>;
  AssociationHistories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutOrganizationInput>;
  auto_call_interval?: InputMaybe<Scalars['Int']['input']>;
  auto_dial?: InputMaybe<Scalars['Boolean']['input']>;
  automated_campaigns?: InputMaybe<AutomatedCampaignCreateNestedManyWithoutOrganizationInput>;
  automatic_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  AutoTransferSelections?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutOrganizationInput>;
  Availablities?: InputMaybe<AvailabilityCreateNestedManyWithoutOrganizationInput>;
  AverageOrderValue?: InputMaybe<AverageOrderValueCreateNestedManyWithoutOrganizationInput>;
  best_action_ever_multiplier?: InputMaybe<Scalars['Float']['input']>;
  best_metrics?: InputMaybe<BestMetricRecordCreateNestedManyWithoutOrganizationInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptCreateNestedManyWithoutOrganizationInput>;
  callMeNows?: InputMaybe<CallMeNowCreateNestedManyWithoutOrganizationInput>;
  CallMultiplier?: InputMaybe<CallMultiplierCreateNestedManyWithoutOrganizationInput>;
  CallSharings?: InputMaybe<CallSharingCreateNestedManyWithoutOrganizationInput>;
  can_edit_sdr_transfer_rules?: InputMaybe<Scalars['Boolean']['input']>;
  claim_rule?: InputMaybe<Scalars['Int']['input']>;
  concession?: InputMaybe<ConcessionCreateNestedManyWithoutOrganizationInput>;
  conference_region?: InputMaybe<ConferenceRegion>;
  Conferences?: InputMaybe<ConferenceCreateNestedManyWithoutOrganizationInput>;
  ContactRequests?: InputMaybe<ContactRequestCreateNestedManyWithoutOrganizationInput>;
  Contacts?: InputMaybe<ContactCreateNestedManyWithoutOrganizationInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  custom_fields?: InputMaybe<CustomFieldCreateNestedManyWithoutOrganizationInput>;
  CustomObject?: InputMaybe<CustomObjectCreateNestedManyWithoutOrganizationInput>;
  CustomObjectField?: InputMaybe<CustomObjectFieldCreateNestedManyWithoutOrganizationInput>;
  CustomObjectFieldForeignKey?: InputMaybe<CustomObjectFieldForeignKeyCreateNestedManyWithoutOrganizationInput>;
  CustomObjectRecord?: InputMaybe<CustomObjectRecordCreateNestedManyWithoutOrganizationInput>;
  CustomObjectRow?: InputMaybe<CustomObjectRowCreateNestedManyWithoutOrganizationInput>;
  CustomQueueItems?: InputMaybe<CustomQueueItemCreateNestedManyWithoutOrganizationInput>;
  DashboardColumnOptions?: InputMaybe<DashboardColumnOptionCreateNestedManyWithoutOrganizationInput>;
  DashboardLeadFilters?: InputMaybe<DashboardLeadFilterCreateNestedManyWithoutOrganizationInput>;
  DashboardRepFilters?: InputMaybe<DashboardRepFilterCreateNestedManyWithoutOrganizationInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewCreateNestedManyWithoutOrganizationInput>;
  day_to_close?: InputMaybe<Scalars['Int']['input']>;
  default_demo_time_duration?: InputMaybe<Scalars['Int']['input']>;
  demo_held_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_not_held_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_not_held_call_back_short_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_prep_time_minutes?: InputMaybe<Scalars['Int']['input']>;
  dev_account?: InputMaybe<Scalars['Boolean']['input']>;
  dial_rule?: InputMaybe<Scalars['Int']['input']>;
  DispositionMultiplier?: InputMaybe<DispositionMultiplierCreateNestedManyWithoutOrganizationInput>;
  dispositions?: InputMaybe<DispositionCreateNestedManyWithoutOrganizationInput>;
  double_booking?: InputMaybe<Scalars['Boolean']['input']>;
  dynamic_date_operators?: InputMaybe<Scalars['Boolean']['input']>;
  edge_servers?: InputMaybe<OrganizationCreateedge_ServersInput>;
  email_conflict_notification_email_list?: InputMaybe<OrganizationCreateemail_Conflict_Notification_Email_ListInput>;
  EmailItems?: InputMaybe<EmailItemCreateNestedManyWithoutOrganizationInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordCreateNestedManyWithoutOrganizationInput>;
  EmailThreads?: InputMaybe<EmailThreadCreateNestedManyWithoutOrganizationInput>;
  enable_modified_metrics?: InputMaybe<Scalars['Boolean']['input']>;
  enable_pay_period_view?: InputMaybe<Scalars['Boolean']['input']>;
  enable_time_constraint?: InputMaybe<Scalars['Boolean']['input']>;
  error_log_email_address?: InputMaybe<OrganizationCreateerror_Log_Email_AddressInput>;
  estimated_average_num_of_weekly_overbooked_demo_slots?: InputMaybe<Scalars['Int']['input']>;
  FolderPermissions?: InputMaybe<FolderPermissionCreateNestedManyWithoutOrganizationInput>;
  Folders?: InputMaybe<FolderCreateNestedManyWithoutOrganizationInput>;
  FutureBookingSetting?: InputMaybe<FutureBookingSettingCreateNestedOneWithoutOrganizationInput>;
  GlobalTransferRule?: InputMaybe<GlobalTransferRuleCreateNestedManyWithoutOrganizationInput>;
  held_demos_per_cc_connect?: InputMaybe<Scalars['Float']['input']>;
  helpful_links?: InputMaybe<OrganizationCreatehelpful_LinksInput>;
  HingePointMultiplier?: InputMaybe<HingePointMultiplierCreateNestedManyWithoutOrganizationInput>;
  hold_goal?: InputMaybe<Scalars['Float']['input']>;
  hubspot_credential?: InputMaybe<HubSpotCredentialCreateNestedOneWithoutOrganizationInput>;
  hubspot_last_sync_date?: InputMaybe<Scalars['DateTime']['input']>;
  hubspot_white_list?: InputMaybe<HubSpotWhitelistCreateNestedManyWithoutOrganizationInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  inbound_close_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_dm_connect_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_lt_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_revenue_value_of_sale?: InputMaybe<Scalars['Float']['input']>;
  inbound_scheduled_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_set_rate?: InputMaybe<Scalars['Float']['input']>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventCreateNestedManyWithoutOrganizationInput>;
  InboundConciergeWidget?: InputMaybe<InboundConciergeWidgetCreateNestedManyWithoutOrganizationInput>;
  industry_options?: InputMaybe<IndustryOptionCreateNestedManyWithoutOrganizationInput>;
  initial_sdr_sets_reps_day?: InputMaybe<Scalars['Float']['input']>;
  integration_error_logs?: InputMaybe<IntegrationErrorLogCreateNestedManyWithoutOrganizationInput>;
  integration_mappings?: InputMaybe<IntegrationMappingCreateNestedManyWithoutOrganizationInput>;
  IntegrationMappingCustomObject?: InputMaybe<IntegrationMappingCustomObjectCreateNestedManyWithoutOrganizationInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogCreateNestedManyWithoutOrganizationInput>;
  IntegrationReadOnlyObject?: InputMaybe<IntegrationReadOnlyObjectCreateNestedManyWithoutOrganizationInput>;
  international?: InputMaybe<Scalars['Boolean']['input']>;
  is_email_domain_verified?: InputMaybe<Scalars['Boolean']['input']>;
  is_salesforce_sandbox?: InputMaybe<Scalars['Boolean']['input']>;
  last_lead_value_compute_time?: InputMaybe<Scalars['DateTime']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutOrganizationInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryCreateNestedManyWithoutOrganizationInput>;
  lead_intent?: InputMaybe<LeadIntentCreateNestedManyWithoutOrganizationInput>;
  lead_release_same_rep_delay_rule?: InputMaybe<Scalars['Int']['input']>;
  lead_routing_email_list?: InputMaybe<OrganizationCreatelead_Routing_Email_ListInput>;
  lead_routing_rules?: InputMaybe<RuleCreateNestedManyWithoutOrganizationInput>;
  LeadCustomObjectRowAssociation?: InputMaybe<LeadCustomObjectRowAssociationCreateNestedManyWithoutOrganizationInput>;
  LeadFieldMultiplier?: InputMaybe<LeadFieldMultiplierCreateNestedManyWithoutOrganizationInput>;
  LeadFieldMultiplierCustom?: InputMaybe<LeadFieldMultiplierCustomCreateNestedManyWithoutOrganizationInput>;
  leads?: InputMaybe<LeadCreateNestedManyWithoutOrganizationInput>;
  leadsource_options?: InputMaybe<LeadSourceOptionCreateNestedManyWithoutOrganizationInput>;
  LeadValue?: InputMaybe<LeadValueCreateNestedManyWithoutOrganizationInput>;
  LifeTimeValueMultiplier?: InputMaybe<LifeTimeValueMultiplierCreateNestedManyWithoutOrganizationInput>;
  LiveTransferSetting?: InputMaybe<LiveTransferSettingCreateNestedOneWithoutOrganizationInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusCreateNestedManyWithoutOrganizationInput>;
  ltv_multiplier_custom_fields?: InputMaybe<LifeTimeValueMultiplierCustomCreateNestedManyWithoutOrganizationInput>;
  made_contact_rule?: InputMaybe<Scalars['Int']['input']>;
  make_sale_address_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_business_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_city_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_country_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_first_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_industry_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_last_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_lead_source_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_primary_email_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_primary_phone_number_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_state_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_sub_industry_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_zip_required?: InputMaybe<Scalars['Boolean']['input']>;
  maxium_day_lag_set_to_schedule?: InputMaybe<Scalars['Int']['input']>;
  mrr?: InputMaybe<Scalars['Int']['input']>;
  mrr_label?: InputMaybe<Scalars['String']['input']>;
  mrr_required_on_sale?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  NoteItems?: InputMaybe<NoteItemCreateNestedManyWithoutOrganizationInput>;
  Notifications?: InputMaybe<NotificationCreateNestedManyWithoutOrganizationInput>;
  onboardComplete?: InputMaybe<Scalars['Boolean']['input']>;
  Opportunity?: InputMaybe<SfOpportunityCreateNestedManyWithoutOrganizationInput>;
  organization_holidays?: InputMaybe<OrganizationHolidayCreateNestedManyWithoutOrganizationInput>;
  organization_hours?: InputMaybe<OrganizationHourCreateNestedManyWithoutOrganizationInput>;
  OrganizationPaymentSetting?: InputMaybe<OrganizationPaymentSettingsCreateNestedOneWithoutOrganizationInput>;
  OrganizationPaymentSettings?: InputMaybe<OrganizationPaymentSettingsCreateNestedOneWithoutOrganizationsInput>;
  orgCalendarInviteMetadata?: InputMaybe<OrgCalendarInviteMetadataCreateNestedManyWithoutOrganizationInput>;
  OrgDefaultPipelinePerecentValue?: InputMaybe<OrgDefaultPipelinePerecentValueCreateNestedOneWithoutOrganizationInput>;
  OrgHubSpotSync?: InputMaybe<OrgHubSpotSyncCreateNestedOneWithoutOrganizationInput>;
  OrgNoEventDate?: InputMaybe<OrgNoEventDateCreateNestedManyWithoutOrganizationInput>;
  OrgSalesforceSync?: InputMaybe<OrgSalesforceSyncCreateNestedOneWithoutOrganizationInput>;
  OrgTimeslotAvailability?: InputMaybe<OrgTimeslotAvailabilityCreateNestedManyWithoutOrganizationInput>;
  outbound_close_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_dm_connect_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_lt_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_revenue_value_of_sale?: InputMaybe<Scalars['Float']['input']>;
  outbound_scheduled_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_set_rate?: InputMaybe<Scalars['Float']['input']>;
  PandadocData?: InputMaybe<PandadocDataCreateNestedOneWithoutOrganizationInput>;
  PandaDocTemplate?: InputMaybe<PandaDocTemplateCreateNestedManyWithoutOrganizationInput>;
  participant_jitter_buffer_size?: InputMaybe<JitterBufferSize>;
  preferred_goal_setting?: InputMaybe<Goalsetting>;
  prepayment?: InputMaybe<Scalars['Boolean']['input']>;
  ProductChangeLog?: InputMaybe<ProductChangeLogCreateNestedManyWithoutOrganizationInput>;
  products?: InputMaybe<ProductCreateNestedManyWithoutOrganizationInput>;
  re_cold_call_hour_delay?: InputMaybe<Scalars['Float']['input']>;
  recordingStatus?: InputMaybe<RecordingStatus>;
  release_lead_resting?: InputMaybe<Scalars['Int']['input']>;
  rep_claim_limit?: InputMaybe<Scalars['Int']['input']>;
  rep_lead_allotment?: InputMaybe<Scalars['Int']['input']>;
  rep_level_call_transfer?: InputMaybe<Scalars['Boolean']['input']>;
  reps_can_bulk_unassign?: InputMaybe<Scalars['Boolean']['input']>;
  reps_can_view_all_notes?: InputMaybe<Scalars['Boolean']['input']>;
  require_sale_notes?: InputMaybe<Scalars['Boolean']['input']>;
  resting_after_skipped_CC?: InputMaybe<Scalars['Int']['input']>;
  resting_period?: InputMaybe<Scalars['Int']['input']>;
  resting_period_after_unsuccessful_cold_call?: InputMaybe<Scalars['Float']['input']>;
  resting_period_after_unsuccessful_demo?: InputMaybe<Scalars['Float']['input']>;
  revenue_plan?: InputMaybe<RevenuePlanItemCreateNestedManyWithoutOrganizationInput>;
  Routings?: InputMaybe<RoutingCreateNestedManyWithoutOrganizationInput>;
  sale_field_options?: InputMaybe<SaleFieldOptionCreateNestedManyWithoutOrganizationInput>;
  sale_notification_email_list?: InputMaybe<OrganizationCreatesale_Notification_Email_ListInput>;
  SaleConfig?: InputMaybe<SaleConfigCreateNestedOneWithoutOrganizationInput>;
  SaleConfigPage?: InputMaybe<SaleConfigPageCreateNestedManyWithoutOrganizationInput>;
  SaleConfigSection?: InputMaybe<SaleConfigSectionCreateNestedManyWithoutOrganizationInput>;
  SaleCycles?: InputMaybe<SaleCycleCreateNestedManyWithoutOrganizationInput>;
  SaleItems?: InputMaybe<SaleItemCreateNestedManyWithoutOrganizationInput>;
  sales?: InputMaybe<SaleCreateNestedManyWithoutOrganizationInput>;
  salesforce_domain?: InputMaybe<Scalars['String']['input']>;
  salesforce_last_sync_date?: InputMaybe<Scalars['DateTime']['input']>;
  SalesForceColumn?: InputMaybe<SalesForceColumnCreateNestedManyWithoutOrganizationInput>;
  salesForceData?: InputMaybe<SalesForceDataCreateNestedOneWithoutOrganizationInput>;
  ScheduleItems?: InputMaybe<ScheduleItemCreateNestedManyWithoutOrganizationInput>;
  self_sourcing_rule?: InputMaybe<Scalars['Int']['input']>;
  send_error_log_email?: InputMaybe<Scalars['Boolean']['input']>;
  send_lead_routing_email?: InputMaybe<Scalars['Boolean']['input']>;
  sendgrid_email_domain?: InputMaybe<Scalars['String']['input']>;
  sendgrid_email_domain_id?: InputMaybe<Scalars['Int']['input']>;
  sequence?: InputMaybe<SequenceCreateNestedManyWithoutOrganzationInput>;
  SequenceCriteriaConditions?: InputMaybe<SequenceCriteriaConditionCreateNestedManyWithoutOrganizationInput>;
  SequenceDashboardViews?: InputMaybe<SequenceDashboardViewCreateNestedManyWithoutOrganizationInput>;
  SequenceEntryCriterias?: InputMaybe<SequenceEntryCriteriaCreateNestedManyWithoutOrganizationInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutOrganizationInput>;
  SequenceSteps?: InputMaybe<SequenceStepCreateNestedManyWithoutOrganizationInput>;
  set_goal?: InputMaybe<Scalars['Float']['input']>;
  short_resting?: InputMaybe<Scalars['Int']['input']>;
  show_calendar_options?: InputMaybe<Scalars['Boolean']['input']>;
  show_contract_duration_sales?: InputMaybe<Scalars['Boolean']['input']>;
  show_lead_source_to_rep?: InputMaybe<Scalars['Boolean']['input']>;
  show_nocontact_button?: InputMaybe<Scalars['Boolean']['input']>;
  show_payment_terms_sales?: InputMaybe<Scalars['Boolean']['input']>;
  show_rep_all_leads?: InputMaybe<Scalars['Boolean']['input']>;
  show_rep_my_leads?: InputMaybe<Scalars['Boolean']['input']>;
  show_system_view?: InputMaybe<Scalars['Boolean']['input']>;
  sites?: InputMaybe<SiteCreateNestedManyWithoutOrganizationInput>;
  sms_opt_in_dialog?: InputMaybe<Scalars['String']['input']>;
  SMSItems?: InputMaybe<SmsItemCreateNestedManyWithoutOrganizationInput>;
  SnoozeTrackings?: InputMaybe<SnoozeTrackingCreateNestedManyWithoutOrganizationInput>;
  StepActionLogs?: InputMaybe<StepActionLogCreateNestedManyWithoutOrganizationInput>;
  StepActions?: InputMaybe<StepActionCreateNestedManyWithoutOrganizationInput>;
  StepConditions?: InputMaybe<StepConditionCreateNestedManyWithoutOrganizationInput>;
  teams?: InputMaybe<TeamCreateNestedManyWithoutOrganizationInput>;
  Template?: InputMaybe<TemplateCreateNestedManyWithoutOrganizationInput>;
  TemplateVersions?: InputMaybe<TemplateVersionCreateNestedManyWithoutOrganizationInput>;
  timekit_id?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  transfer_timeout?: InputMaybe<Scalars['Int']['input']>;
  TransferAttempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutOrganizationInput>;
  twilio_numbers?: InputMaybe<TwilioNumberCreateNestedManyWithoutOrganizationInput>;
  TwilioInfo?: InputMaybe<TwilioInfoCreateNestedOneWithoutOrganzationInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  use_revenue_planning?: InputMaybe<Scalars['Boolean']['input']>;
  UserCustomFieldFilters?: InputMaybe<UserCustomFieldFilterCreateNestedManyWithoutOrganizationInput>;
  users?: InputMaybe<UserCreateNestedManyWithoutOrganizationInput>;
  win_rep_day_goal?: InputMaybe<Scalars['Float']['input']>;
};

export type OrganizationCreateWithoutTwilioInfoInput = {
  ActivityMonitorMultiplier?: InputMaybe<ActivityMonitorMultiplierCreateNestedManyWithoutOrganizationInput>;
  algoFactor?: InputMaybe<AlgoFactorCreateNestedManyWithoutOrganizationInput>;
  allow_calendar_invite?: InputMaybe<Scalars['Boolean']['input']>;
  allow_remove_step_action?: InputMaybe<Scalars['Boolean']['input']>;
  allow_snooze_step_action?: InputMaybe<Scalars['Boolean']['input']>;
  always_record?: InputMaybe<Scalars['Boolean']['input']>;
  arv_initial_held_demos_generated_by_sdr_weight?: InputMaybe<Scalars['Float']['input']>;
  AssociationHistories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutOrganizationInput>;
  auto_call_interval?: InputMaybe<Scalars['Int']['input']>;
  auto_dial?: InputMaybe<Scalars['Boolean']['input']>;
  automated_campaigns?: InputMaybe<AutomatedCampaignCreateNestedManyWithoutOrganizationInput>;
  automatic_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  AutoTransferSelections?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutOrganizationInput>;
  Availablities?: InputMaybe<AvailabilityCreateNestedManyWithoutOrganizationInput>;
  AverageOrderValue?: InputMaybe<AverageOrderValueCreateNestedManyWithoutOrganizationInput>;
  best_action_ever_multiplier?: InputMaybe<Scalars['Float']['input']>;
  best_metrics?: InputMaybe<BestMetricRecordCreateNestedManyWithoutOrganizationInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptCreateNestedManyWithoutOrganizationInput>;
  callMeNows?: InputMaybe<CallMeNowCreateNestedManyWithoutOrganizationInput>;
  CallMultiplier?: InputMaybe<CallMultiplierCreateNestedManyWithoutOrganizationInput>;
  CallSharings?: InputMaybe<CallSharingCreateNestedManyWithoutOrganizationInput>;
  can_edit_sdr_transfer_rules?: InputMaybe<Scalars['Boolean']['input']>;
  claim_rule?: InputMaybe<Scalars['Int']['input']>;
  concession?: InputMaybe<ConcessionCreateNestedManyWithoutOrganizationInput>;
  conference_region?: InputMaybe<ConferenceRegion>;
  Conferences?: InputMaybe<ConferenceCreateNestedManyWithoutOrganizationInput>;
  ContactRequests?: InputMaybe<ContactRequestCreateNestedManyWithoutOrganizationInput>;
  Contacts?: InputMaybe<ContactCreateNestedManyWithoutOrganizationInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  custom_fields?: InputMaybe<CustomFieldCreateNestedManyWithoutOrganizationInput>;
  CustomObject?: InputMaybe<CustomObjectCreateNestedManyWithoutOrganizationInput>;
  CustomObjectField?: InputMaybe<CustomObjectFieldCreateNestedManyWithoutOrganizationInput>;
  CustomObjectFieldForeignKey?: InputMaybe<CustomObjectFieldForeignKeyCreateNestedManyWithoutOrganizationInput>;
  CustomObjectRecord?: InputMaybe<CustomObjectRecordCreateNestedManyWithoutOrganizationInput>;
  CustomObjectRow?: InputMaybe<CustomObjectRowCreateNestedManyWithoutOrganizationInput>;
  CustomQueueItems?: InputMaybe<CustomQueueItemCreateNestedManyWithoutOrganizationInput>;
  DashboardColumnOptions?: InputMaybe<DashboardColumnOptionCreateNestedManyWithoutOrganizationInput>;
  DashboardLeadFilters?: InputMaybe<DashboardLeadFilterCreateNestedManyWithoutOrganizationInput>;
  DashboardRepFilters?: InputMaybe<DashboardRepFilterCreateNestedManyWithoutOrganizationInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewCreateNestedManyWithoutOrganizationInput>;
  day_to_close?: InputMaybe<Scalars['Int']['input']>;
  default_demo_time_duration?: InputMaybe<Scalars['Int']['input']>;
  demo_held_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_not_held_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_not_held_call_back_short_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_prep_time_minutes?: InputMaybe<Scalars['Int']['input']>;
  dev_account?: InputMaybe<Scalars['Boolean']['input']>;
  dial_rule?: InputMaybe<Scalars['Int']['input']>;
  DispositionMultiplier?: InputMaybe<DispositionMultiplierCreateNestedManyWithoutOrganizationInput>;
  dispositions?: InputMaybe<DispositionCreateNestedManyWithoutOrganizationInput>;
  double_booking?: InputMaybe<Scalars['Boolean']['input']>;
  dynamic_date_operators?: InputMaybe<Scalars['Boolean']['input']>;
  edge_servers?: InputMaybe<OrganizationCreateedge_ServersInput>;
  email_conflict_notification_email_list?: InputMaybe<OrganizationCreateemail_Conflict_Notification_Email_ListInput>;
  EmailItems?: InputMaybe<EmailItemCreateNestedManyWithoutOrganizationInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordCreateNestedManyWithoutOrganizationInput>;
  EmailThreads?: InputMaybe<EmailThreadCreateNestedManyWithoutOrganizationInput>;
  enable_modified_metrics?: InputMaybe<Scalars['Boolean']['input']>;
  enable_pay_period_view?: InputMaybe<Scalars['Boolean']['input']>;
  enable_time_constraint?: InputMaybe<Scalars['Boolean']['input']>;
  error_log_email_address?: InputMaybe<OrganizationCreateerror_Log_Email_AddressInput>;
  estimated_average_num_of_weekly_overbooked_demo_slots?: InputMaybe<Scalars['Int']['input']>;
  FolderPermissions?: InputMaybe<FolderPermissionCreateNestedManyWithoutOrganizationInput>;
  Folders?: InputMaybe<FolderCreateNestedManyWithoutOrganizationInput>;
  FutureBookingSetting?: InputMaybe<FutureBookingSettingCreateNestedOneWithoutOrganizationInput>;
  GlobalTransferRule?: InputMaybe<GlobalTransferRuleCreateNestedManyWithoutOrganizationInput>;
  held_demos_per_cc_connect?: InputMaybe<Scalars['Float']['input']>;
  helpful_links?: InputMaybe<OrganizationCreatehelpful_LinksInput>;
  HingePointMultiplier?: InputMaybe<HingePointMultiplierCreateNestedManyWithoutOrganizationInput>;
  hold_goal?: InputMaybe<Scalars['Float']['input']>;
  hubspot_credential?: InputMaybe<HubSpotCredentialCreateNestedOneWithoutOrganizationInput>;
  hubspot_last_sync_date?: InputMaybe<Scalars['DateTime']['input']>;
  hubspot_white_list?: InputMaybe<HubSpotWhitelistCreateNestedManyWithoutOrganizationInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  inbound_close_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_dm_connect_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_lt_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_revenue_value_of_sale?: InputMaybe<Scalars['Float']['input']>;
  inbound_scheduled_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_set_rate?: InputMaybe<Scalars['Float']['input']>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventCreateNestedManyWithoutOrganizationInput>;
  InboundConciergeWidget?: InputMaybe<InboundConciergeWidgetCreateNestedManyWithoutOrganizationInput>;
  industry_options?: InputMaybe<IndustryOptionCreateNestedManyWithoutOrganizationInput>;
  initial_sdr_sets_reps_day?: InputMaybe<Scalars['Float']['input']>;
  integration_error_logs?: InputMaybe<IntegrationErrorLogCreateNestedManyWithoutOrganizationInput>;
  integration_mappings?: InputMaybe<IntegrationMappingCreateNestedManyWithoutOrganizationInput>;
  IntegrationMappingCustomObject?: InputMaybe<IntegrationMappingCustomObjectCreateNestedManyWithoutOrganizationInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogCreateNestedManyWithoutOrganizationInput>;
  IntegrationReadOnlyObject?: InputMaybe<IntegrationReadOnlyObjectCreateNestedManyWithoutOrganizationInput>;
  international?: InputMaybe<Scalars['Boolean']['input']>;
  is_email_domain_verified?: InputMaybe<Scalars['Boolean']['input']>;
  is_salesforce_sandbox?: InputMaybe<Scalars['Boolean']['input']>;
  last_lead_value_compute_time?: InputMaybe<Scalars['DateTime']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutOrganizationInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryCreateNestedManyWithoutOrganizationInput>;
  lead_intent?: InputMaybe<LeadIntentCreateNestedManyWithoutOrganizationInput>;
  lead_release_same_rep_delay_rule?: InputMaybe<Scalars['Int']['input']>;
  lead_routing_email_list?: InputMaybe<OrganizationCreatelead_Routing_Email_ListInput>;
  lead_routing_rules?: InputMaybe<RuleCreateNestedManyWithoutOrganizationInput>;
  LeadCustomObjectRowAssociation?: InputMaybe<LeadCustomObjectRowAssociationCreateNestedManyWithoutOrganizationInput>;
  LeadFieldMultiplier?: InputMaybe<LeadFieldMultiplierCreateNestedManyWithoutOrganizationInput>;
  LeadFieldMultiplierCustom?: InputMaybe<LeadFieldMultiplierCustomCreateNestedManyWithoutOrganizationInput>;
  leads?: InputMaybe<LeadCreateNestedManyWithoutOrganizationInput>;
  leadsource_options?: InputMaybe<LeadSourceOptionCreateNestedManyWithoutOrganizationInput>;
  LeadValue?: InputMaybe<LeadValueCreateNestedManyWithoutOrganizationInput>;
  LifeTimeValueMultiplier?: InputMaybe<LifeTimeValueMultiplierCreateNestedManyWithoutOrganizationInput>;
  LiveTransferSetting?: InputMaybe<LiveTransferSettingCreateNestedOneWithoutOrganizationInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusCreateNestedManyWithoutOrganizationInput>;
  ltv_multiplier_custom_fields?: InputMaybe<LifeTimeValueMultiplierCustomCreateNestedManyWithoutOrganizationInput>;
  made_contact_rule?: InputMaybe<Scalars['Int']['input']>;
  make_sale_address_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_business_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_city_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_country_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_first_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_industry_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_last_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_lead_source_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_primary_email_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_primary_phone_number_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_state_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_sub_industry_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_zip_required?: InputMaybe<Scalars['Boolean']['input']>;
  maxium_day_lag_set_to_schedule?: InputMaybe<Scalars['Int']['input']>;
  mrr?: InputMaybe<Scalars['Int']['input']>;
  mrr_label?: InputMaybe<Scalars['String']['input']>;
  mrr_required_on_sale?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  NoteItems?: InputMaybe<NoteItemCreateNestedManyWithoutOrganizationInput>;
  Notifications?: InputMaybe<NotificationCreateNestedManyWithoutOrganizationInput>;
  onboardComplete?: InputMaybe<Scalars['Boolean']['input']>;
  Opportunity?: InputMaybe<SfOpportunityCreateNestedManyWithoutOrganizationInput>;
  organization_holidays?: InputMaybe<OrganizationHolidayCreateNestedManyWithoutOrganizationInput>;
  organization_hours?: InputMaybe<OrganizationHourCreateNestedManyWithoutOrganizationInput>;
  OrganizationPaymentSetting?: InputMaybe<OrganizationPaymentSettingsCreateNestedOneWithoutOrganizationInput>;
  OrganizationPaymentSettings?: InputMaybe<OrganizationPaymentSettingsCreateNestedOneWithoutOrganizationsInput>;
  orgCalendarInviteMetadata?: InputMaybe<OrgCalendarInviteMetadataCreateNestedManyWithoutOrganizationInput>;
  OrgDefaultPipelinePerecentValue?: InputMaybe<OrgDefaultPipelinePerecentValueCreateNestedOneWithoutOrganizationInput>;
  OrgHubSpotSync?: InputMaybe<OrgHubSpotSyncCreateNestedOneWithoutOrganizationInput>;
  OrgNoEventDate?: InputMaybe<OrgNoEventDateCreateNestedManyWithoutOrganizationInput>;
  OrgSalesforceSync?: InputMaybe<OrgSalesforceSyncCreateNestedOneWithoutOrganizationInput>;
  OrgTimeslotAvailability?: InputMaybe<OrgTimeslotAvailabilityCreateNestedManyWithoutOrganizationInput>;
  outbound_close_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_dm_connect_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_lt_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_revenue_value_of_sale?: InputMaybe<Scalars['Float']['input']>;
  outbound_scheduled_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_set_rate?: InputMaybe<Scalars['Float']['input']>;
  PandadocData?: InputMaybe<PandadocDataCreateNestedOneWithoutOrganizationInput>;
  PandaDocTemplate?: InputMaybe<PandaDocTemplateCreateNestedManyWithoutOrganizationInput>;
  participant_jitter_buffer_size?: InputMaybe<JitterBufferSize>;
  preferred_goal_setting?: InputMaybe<Goalsetting>;
  prepayment?: InputMaybe<Scalars['Boolean']['input']>;
  ProductChangeLog?: InputMaybe<ProductChangeLogCreateNestedManyWithoutOrganizationInput>;
  products?: InputMaybe<ProductCreateNestedManyWithoutOrganizationInput>;
  re_cold_call_hour_delay?: InputMaybe<Scalars['Float']['input']>;
  recordingStatus?: InputMaybe<RecordingStatus>;
  release_lead_resting?: InputMaybe<Scalars['Int']['input']>;
  rep_claim_limit?: InputMaybe<Scalars['Int']['input']>;
  rep_lead_allotment?: InputMaybe<Scalars['Int']['input']>;
  rep_level_call_transfer?: InputMaybe<Scalars['Boolean']['input']>;
  reps_can_bulk_unassign?: InputMaybe<Scalars['Boolean']['input']>;
  reps_can_view_all_notes?: InputMaybe<Scalars['Boolean']['input']>;
  require_sale_notes?: InputMaybe<Scalars['Boolean']['input']>;
  resting_after_skipped_CC?: InputMaybe<Scalars['Int']['input']>;
  resting_period?: InputMaybe<Scalars['Int']['input']>;
  resting_period_after_unsuccessful_cold_call?: InputMaybe<Scalars['Float']['input']>;
  resting_period_after_unsuccessful_demo?: InputMaybe<Scalars['Float']['input']>;
  revenue_plan?: InputMaybe<RevenuePlanItemCreateNestedManyWithoutOrganizationInput>;
  Routings?: InputMaybe<RoutingCreateNestedManyWithoutOrganizationInput>;
  sale_field_options?: InputMaybe<SaleFieldOptionCreateNestedManyWithoutOrganizationInput>;
  sale_notification_email_list?: InputMaybe<OrganizationCreatesale_Notification_Email_ListInput>;
  SaleConfig?: InputMaybe<SaleConfigCreateNestedOneWithoutOrganizationInput>;
  SaleConfigPage?: InputMaybe<SaleConfigPageCreateNestedManyWithoutOrganizationInput>;
  SaleConfigSection?: InputMaybe<SaleConfigSectionCreateNestedManyWithoutOrganizationInput>;
  SaleCycles?: InputMaybe<SaleCycleCreateNestedManyWithoutOrganizationInput>;
  SaleItems?: InputMaybe<SaleItemCreateNestedManyWithoutOrganizationInput>;
  sales?: InputMaybe<SaleCreateNestedManyWithoutOrganizationInput>;
  salesforce_domain?: InputMaybe<Scalars['String']['input']>;
  salesforce_last_sync_date?: InputMaybe<Scalars['DateTime']['input']>;
  SalesForceColumn?: InputMaybe<SalesForceColumnCreateNestedManyWithoutOrganizationInput>;
  salesForceData?: InputMaybe<SalesForceDataCreateNestedOneWithoutOrganizationInput>;
  ScheduleItems?: InputMaybe<ScheduleItemCreateNestedManyWithoutOrganizationInput>;
  self_sourcing_rule?: InputMaybe<Scalars['Int']['input']>;
  send_error_log_email?: InputMaybe<Scalars['Boolean']['input']>;
  send_lead_routing_email?: InputMaybe<Scalars['Boolean']['input']>;
  sendgrid_email_domain?: InputMaybe<Scalars['String']['input']>;
  sendgrid_email_domain_id?: InputMaybe<Scalars['Int']['input']>;
  sequence?: InputMaybe<SequenceCreateNestedManyWithoutOrganzationInput>;
  SequenceCriteriaConditions?: InputMaybe<SequenceCriteriaConditionCreateNestedManyWithoutOrganizationInput>;
  SequenceDashboardViews?: InputMaybe<SequenceDashboardViewCreateNestedManyWithoutOrganizationInput>;
  SequenceEntryCriterias?: InputMaybe<SequenceEntryCriteriaCreateNestedManyWithoutOrganizationInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutOrganizationInput>;
  SequenceSteps?: InputMaybe<SequenceStepCreateNestedManyWithoutOrganizationInput>;
  set_goal?: InputMaybe<Scalars['Float']['input']>;
  short_resting?: InputMaybe<Scalars['Int']['input']>;
  show_calendar_options?: InputMaybe<Scalars['Boolean']['input']>;
  show_contract_duration_sales?: InputMaybe<Scalars['Boolean']['input']>;
  show_lead_source_to_rep?: InputMaybe<Scalars['Boolean']['input']>;
  show_nocontact_button?: InputMaybe<Scalars['Boolean']['input']>;
  show_payment_terms_sales?: InputMaybe<Scalars['Boolean']['input']>;
  show_rep_all_leads?: InputMaybe<Scalars['Boolean']['input']>;
  show_rep_my_leads?: InputMaybe<Scalars['Boolean']['input']>;
  show_system_view?: InputMaybe<Scalars['Boolean']['input']>;
  sites?: InputMaybe<SiteCreateNestedManyWithoutOrganizationInput>;
  sms_opt_in_dialog?: InputMaybe<Scalars['String']['input']>;
  SMSItems?: InputMaybe<SmsItemCreateNestedManyWithoutOrganizationInput>;
  SnoozeTrackings?: InputMaybe<SnoozeTrackingCreateNestedManyWithoutOrganizationInput>;
  StepActionLogs?: InputMaybe<StepActionLogCreateNestedManyWithoutOrganizationInput>;
  StepActions?: InputMaybe<StepActionCreateNestedManyWithoutOrganizationInput>;
  StepConditions?: InputMaybe<StepConditionCreateNestedManyWithoutOrganizationInput>;
  teams?: InputMaybe<TeamCreateNestedManyWithoutOrganizationInput>;
  Template?: InputMaybe<TemplateCreateNestedManyWithoutOrganizationInput>;
  TemplateVersions?: InputMaybe<TemplateVersionCreateNestedManyWithoutOrganizationInput>;
  timekit_id?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  transfer_timeout?: InputMaybe<Scalars['Int']['input']>;
  TransferAttempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutOrganizationInput>;
  twilio_numbers?: InputMaybe<TwilioNumberCreateNestedManyWithoutOrganizationInput>;
  TwilioFeedbacks?: InputMaybe<TwilioFeedbackCreateNestedManyWithoutOrganizationInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  use_revenue_planning?: InputMaybe<Scalars['Boolean']['input']>;
  UserCustomFieldFilters?: InputMaybe<UserCustomFieldFilterCreateNestedManyWithoutOrganizationInput>;
  users?: InputMaybe<UserCreateNestedManyWithoutOrganizationInput>;
  win_rep_day_goal?: InputMaybe<Scalars['Float']['input']>;
};

export type OrganizationCreateWithoutUserCustomFieldFiltersInput = {
  ActivityMonitorMultiplier?: InputMaybe<ActivityMonitorMultiplierCreateNestedManyWithoutOrganizationInput>;
  algoFactor?: InputMaybe<AlgoFactorCreateNestedManyWithoutOrganizationInput>;
  allow_calendar_invite?: InputMaybe<Scalars['Boolean']['input']>;
  allow_remove_step_action?: InputMaybe<Scalars['Boolean']['input']>;
  allow_snooze_step_action?: InputMaybe<Scalars['Boolean']['input']>;
  always_record?: InputMaybe<Scalars['Boolean']['input']>;
  arv_initial_held_demos_generated_by_sdr_weight?: InputMaybe<Scalars['Float']['input']>;
  AssociationHistories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutOrganizationInput>;
  auto_call_interval?: InputMaybe<Scalars['Int']['input']>;
  auto_dial?: InputMaybe<Scalars['Boolean']['input']>;
  automated_campaigns?: InputMaybe<AutomatedCampaignCreateNestedManyWithoutOrganizationInput>;
  automatic_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  AutoTransferSelections?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutOrganizationInput>;
  Availablities?: InputMaybe<AvailabilityCreateNestedManyWithoutOrganizationInput>;
  AverageOrderValue?: InputMaybe<AverageOrderValueCreateNestedManyWithoutOrganizationInput>;
  best_action_ever_multiplier?: InputMaybe<Scalars['Float']['input']>;
  best_metrics?: InputMaybe<BestMetricRecordCreateNestedManyWithoutOrganizationInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptCreateNestedManyWithoutOrganizationInput>;
  callMeNows?: InputMaybe<CallMeNowCreateNestedManyWithoutOrganizationInput>;
  CallMultiplier?: InputMaybe<CallMultiplierCreateNestedManyWithoutOrganizationInput>;
  CallSharings?: InputMaybe<CallSharingCreateNestedManyWithoutOrganizationInput>;
  can_edit_sdr_transfer_rules?: InputMaybe<Scalars['Boolean']['input']>;
  claim_rule?: InputMaybe<Scalars['Int']['input']>;
  concession?: InputMaybe<ConcessionCreateNestedManyWithoutOrganizationInput>;
  conference_region?: InputMaybe<ConferenceRegion>;
  Conferences?: InputMaybe<ConferenceCreateNestedManyWithoutOrganizationInput>;
  ContactRequests?: InputMaybe<ContactRequestCreateNestedManyWithoutOrganizationInput>;
  Contacts?: InputMaybe<ContactCreateNestedManyWithoutOrganizationInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  custom_fields?: InputMaybe<CustomFieldCreateNestedManyWithoutOrganizationInput>;
  CustomObject?: InputMaybe<CustomObjectCreateNestedManyWithoutOrganizationInput>;
  CustomObjectField?: InputMaybe<CustomObjectFieldCreateNestedManyWithoutOrganizationInput>;
  CustomObjectFieldForeignKey?: InputMaybe<CustomObjectFieldForeignKeyCreateNestedManyWithoutOrganizationInput>;
  CustomObjectRecord?: InputMaybe<CustomObjectRecordCreateNestedManyWithoutOrganizationInput>;
  CustomObjectRow?: InputMaybe<CustomObjectRowCreateNestedManyWithoutOrganizationInput>;
  CustomQueueItems?: InputMaybe<CustomQueueItemCreateNestedManyWithoutOrganizationInput>;
  DashboardColumnOptions?: InputMaybe<DashboardColumnOptionCreateNestedManyWithoutOrganizationInput>;
  DashboardLeadFilters?: InputMaybe<DashboardLeadFilterCreateNestedManyWithoutOrganizationInput>;
  DashboardRepFilters?: InputMaybe<DashboardRepFilterCreateNestedManyWithoutOrganizationInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewCreateNestedManyWithoutOrganizationInput>;
  day_to_close?: InputMaybe<Scalars['Int']['input']>;
  default_demo_time_duration?: InputMaybe<Scalars['Int']['input']>;
  demo_held_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_not_held_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_not_held_call_back_short_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_prep_time_minutes?: InputMaybe<Scalars['Int']['input']>;
  dev_account?: InputMaybe<Scalars['Boolean']['input']>;
  dial_rule?: InputMaybe<Scalars['Int']['input']>;
  DispositionMultiplier?: InputMaybe<DispositionMultiplierCreateNestedManyWithoutOrganizationInput>;
  dispositions?: InputMaybe<DispositionCreateNestedManyWithoutOrganizationInput>;
  double_booking?: InputMaybe<Scalars['Boolean']['input']>;
  dynamic_date_operators?: InputMaybe<Scalars['Boolean']['input']>;
  edge_servers?: InputMaybe<OrganizationCreateedge_ServersInput>;
  email_conflict_notification_email_list?: InputMaybe<OrganizationCreateemail_Conflict_Notification_Email_ListInput>;
  EmailItems?: InputMaybe<EmailItemCreateNestedManyWithoutOrganizationInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordCreateNestedManyWithoutOrganizationInput>;
  EmailThreads?: InputMaybe<EmailThreadCreateNestedManyWithoutOrganizationInput>;
  enable_modified_metrics?: InputMaybe<Scalars['Boolean']['input']>;
  enable_pay_period_view?: InputMaybe<Scalars['Boolean']['input']>;
  enable_time_constraint?: InputMaybe<Scalars['Boolean']['input']>;
  error_log_email_address?: InputMaybe<OrganizationCreateerror_Log_Email_AddressInput>;
  estimated_average_num_of_weekly_overbooked_demo_slots?: InputMaybe<Scalars['Int']['input']>;
  FolderPermissions?: InputMaybe<FolderPermissionCreateNestedManyWithoutOrganizationInput>;
  Folders?: InputMaybe<FolderCreateNestedManyWithoutOrganizationInput>;
  FutureBookingSetting?: InputMaybe<FutureBookingSettingCreateNestedOneWithoutOrganizationInput>;
  GlobalTransferRule?: InputMaybe<GlobalTransferRuleCreateNestedManyWithoutOrganizationInput>;
  held_demos_per_cc_connect?: InputMaybe<Scalars['Float']['input']>;
  helpful_links?: InputMaybe<OrganizationCreatehelpful_LinksInput>;
  HingePointMultiplier?: InputMaybe<HingePointMultiplierCreateNestedManyWithoutOrganizationInput>;
  hold_goal?: InputMaybe<Scalars['Float']['input']>;
  hubspot_credential?: InputMaybe<HubSpotCredentialCreateNestedOneWithoutOrganizationInput>;
  hubspot_last_sync_date?: InputMaybe<Scalars['DateTime']['input']>;
  hubspot_white_list?: InputMaybe<HubSpotWhitelistCreateNestedManyWithoutOrganizationInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  inbound_close_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_dm_connect_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_lt_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_revenue_value_of_sale?: InputMaybe<Scalars['Float']['input']>;
  inbound_scheduled_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_set_rate?: InputMaybe<Scalars['Float']['input']>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventCreateNestedManyWithoutOrganizationInput>;
  InboundConciergeWidget?: InputMaybe<InboundConciergeWidgetCreateNestedManyWithoutOrganizationInput>;
  industry_options?: InputMaybe<IndustryOptionCreateNestedManyWithoutOrganizationInput>;
  initial_sdr_sets_reps_day?: InputMaybe<Scalars['Float']['input']>;
  integration_error_logs?: InputMaybe<IntegrationErrorLogCreateNestedManyWithoutOrganizationInput>;
  integration_mappings?: InputMaybe<IntegrationMappingCreateNestedManyWithoutOrganizationInput>;
  IntegrationMappingCustomObject?: InputMaybe<IntegrationMappingCustomObjectCreateNestedManyWithoutOrganizationInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogCreateNestedManyWithoutOrganizationInput>;
  IntegrationReadOnlyObject?: InputMaybe<IntegrationReadOnlyObjectCreateNestedManyWithoutOrganizationInput>;
  international?: InputMaybe<Scalars['Boolean']['input']>;
  is_email_domain_verified?: InputMaybe<Scalars['Boolean']['input']>;
  is_salesforce_sandbox?: InputMaybe<Scalars['Boolean']['input']>;
  last_lead_value_compute_time?: InputMaybe<Scalars['DateTime']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutOrganizationInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryCreateNestedManyWithoutOrganizationInput>;
  lead_intent?: InputMaybe<LeadIntentCreateNestedManyWithoutOrganizationInput>;
  lead_release_same_rep_delay_rule?: InputMaybe<Scalars['Int']['input']>;
  lead_routing_email_list?: InputMaybe<OrganizationCreatelead_Routing_Email_ListInput>;
  lead_routing_rules?: InputMaybe<RuleCreateNestedManyWithoutOrganizationInput>;
  LeadCustomObjectRowAssociation?: InputMaybe<LeadCustomObjectRowAssociationCreateNestedManyWithoutOrganizationInput>;
  LeadFieldMultiplier?: InputMaybe<LeadFieldMultiplierCreateNestedManyWithoutOrganizationInput>;
  LeadFieldMultiplierCustom?: InputMaybe<LeadFieldMultiplierCustomCreateNestedManyWithoutOrganizationInput>;
  leads?: InputMaybe<LeadCreateNestedManyWithoutOrganizationInput>;
  leadsource_options?: InputMaybe<LeadSourceOptionCreateNestedManyWithoutOrganizationInput>;
  LeadValue?: InputMaybe<LeadValueCreateNestedManyWithoutOrganizationInput>;
  LifeTimeValueMultiplier?: InputMaybe<LifeTimeValueMultiplierCreateNestedManyWithoutOrganizationInput>;
  LiveTransferSetting?: InputMaybe<LiveTransferSettingCreateNestedOneWithoutOrganizationInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusCreateNestedManyWithoutOrganizationInput>;
  ltv_multiplier_custom_fields?: InputMaybe<LifeTimeValueMultiplierCustomCreateNestedManyWithoutOrganizationInput>;
  made_contact_rule?: InputMaybe<Scalars['Int']['input']>;
  make_sale_address_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_business_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_city_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_country_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_first_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_industry_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_last_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_lead_source_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_primary_email_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_primary_phone_number_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_state_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_sub_industry_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_zip_required?: InputMaybe<Scalars['Boolean']['input']>;
  maxium_day_lag_set_to_schedule?: InputMaybe<Scalars['Int']['input']>;
  mrr?: InputMaybe<Scalars['Int']['input']>;
  mrr_label?: InputMaybe<Scalars['String']['input']>;
  mrr_required_on_sale?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  NoteItems?: InputMaybe<NoteItemCreateNestedManyWithoutOrganizationInput>;
  Notifications?: InputMaybe<NotificationCreateNestedManyWithoutOrganizationInput>;
  onboardComplete?: InputMaybe<Scalars['Boolean']['input']>;
  Opportunity?: InputMaybe<SfOpportunityCreateNestedManyWithoutOrganizationInput>;
  organization_holidays?: InputMaybe<OrganizationHolidayCreateNestedManyWithoutOrganizationInput>;
  organization_hours?: InputMaybe<OrganizationHourCreateNestedManyWithoutOrganizationInput>;
  OrganizationPaymentSetting?: InputMaybe<OrganizationPaymentSettingsCreateNestedOneWithoutOrganizationInput>;
  OrganizationPaymentSettings?: InputMaybe<OrganizationPaymentSettingsCreateNestedOneWithoutOrganizationsInput>;
  orgCalendarInviteMetadata?: InputMaybe<OrgCalendarInviteMetadataCreateNestedManyWithoutOrganizationInput>;
  OrgDefaultPipelinePerecentValue?: InputMaybe<OrgDefaultPipelinePerecentValueCreateNestedOneWithoutOrganizationInput>;
  OrgHubSpotSync?: InputMaybe<OrgHubSpotSyncCreateNestedOneWithoutOrganizationInput>;
  OrgNoEventDate?: InputMaybe<OrgNoEventDateCreateNestedManyWithoutOrganizationInput>;
  OrgSalesforceSync?: InputMaybe<OrgSalesforceSyncCreateNestedOneWithoutOrganizationInput>;
  OrgTimeslotAvailability?: InputMaybe<OrgTimeslotAvailabilityCreateNestedManyWithoutOrganizationInput>;
  outbound_close_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_dm_connect_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_lt_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_revenue_value_of_sale?: InputMaybe<Scalars['Float']['input']>;
  outbound_scheduled_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_set_rate?: InputMaybe<Scalars['Float']['input']>;
  PandadocData?: InputMaybe<PandadocDataCreateNestedOneWithoutOrganizationInput>;
  PandaDocTemplate?: InputMaybe<PandaDocTemplateCreateNestedManyWithoutOrganizationInput>;
  participant_jitter_buffer_size?: InputMaybe<JitterBufferSize>;
  preferred_goal_setting?: InputMaybe<Goalsetting>;
  prepayment?: InputMaybe<Scalars['Boolean']['input']>;
  ProductChangeLog?: InputMaybe<ProductChangeLogCreateNestedManyWithoutOrganizationInput>;
  products?: InputMaybe<ProductCreateNestedManyWithoutOrganizationInput>;
  re_cold_call_hour_delay?: InputMaybe<Scalars['Float']['input']>;
  recordingStatus?: InputMaybe<RecordingStatus>;
  release_lead_resting?: InputMaybe<Scalars['Int']['input']>;
  rep_claim_limit?: InputMaybe<Scalars['Int']['input']>;
  rep_lead_allotment?: InputMaybe<Scalars['Int']['input']>;
  rep_level_call_transfer?: InputMaybe<Scalars['Boolean']['input']>;
  reps_can_bulk_unassign?: InputMaybe<Scalars['Boolean']['input']>;
  reps_can_view_all_notes?: InputMaybe<Scalars['Boolean']['input']>;
  require_sale_notes?: InputMaybe<Scalars['Boolean']['input']>;
  resting_after_skipped_CC?: InputMaybe<Scalars['Int']['input']>;
  resting_period?: InputMaybe<Scalars['Int']['input']>;
  resting_period_after_unsuccessful_cold_call?: InputMaybe<Scalars['Float']['input']>;
  resting_period_after_unsuccessful_demo?: InputMaybe<Scalars['Float']['input']>;
  revenue_plan?: InputMaybe<RevenuePlanItemCreateNestedManyWithoutOrganizationInput>;
  Routings?: InputMaybe<RoutingCreateNestedManyWithoutOrganizationInput>;
  sale_field_options?: InputMaybe<SaleFieldOptionCreateNestedManyWithoutOrganizationInput>;
  sale_notification_email_list?: InputMaybe<OrganizationCreatesale_Notification_Email_ListInput>;
  SaleConfig?: InputMaybe<SaleConfigCreateNestedOneWithoutOrganizationInput>;
  SaleConfigPage?: InputMaybe<SaleConfigPageCreateNestedManyWithoutOrganizationInput>;
  SaleConfigSection?: InputMaybe<SaleConfigSectionCreateNestedManyWithoutOrganizationInput>;
  SaleCycles?: InputMaybe<SaleCycleCreateNestedManyWithoutOrganizationInput>;
  SaleItems?: InputMaybe<SaleItemCreateNestedManyWithoutOrganizationInput>;
  sales?: InputMaybe<SaleCreateNestedManyWithoutOrganizationInput>;
  salesforce_domain?: InputMaybe<Scalars['String']['input']>;
  salesforce_last_sync_date?: InputMaybe<Scalars['DateTime']['input']>;
  SalesForceColumn?: InputMaybe<SalesForceColumnCreateNestedManyWithoutOrganizationInput>;
  salesForceData?: InputMaybe<SalesForceDataCreateNestedOneWithoutOrganizationInput>;
  ScheduleItems?: InputMaybe<ScheduleItemCreateNestedManyWithoutOrganizationInput>;
  self_sourcing_rule?: InputMaybe<Scalars['Int']['input']>;
  send_error_log_email?: InputMaybe<Scalars['Boolean']['input']>;
  send_lead_routing_email?: InputMaybe<Scalars['Boolean']['input']>;
  sendgrid_email_domain?: InputMaybe<Scalars['String']['input']>;
  sendgrid_email_domain_id?: InputMaybe<Scalars['Int']['input']>;
  sequence?: InputMaybe<SequenceCreateNestedManyWithoutOrganzationInput>;
  SequenceCriteriaConditions?: InputMaybe<SequenceCriteriaConditionCreateNestedManyWithoutOrganizationInput>;
  SequenceDashboardViews?: InputMaybe<SequenceDashboardViewCreateNestedManyWithoutOrganizationInput>;
  SequenceEntryCriterias?: InputMaybe<SequenceEntryCriteriaCreateNestedManyWithoutOrganizationInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutOrganizationInput>;
  SequenceSteps?: InputMaybe<SequenceStepCreateNestedManyWithoutOrganizationInput>;
  set_goal?: InputMaybe<Scalars['Float']['input']>;
  short_resting?: InputMaybe<Scalars['Int']['input']>;
  show_calendar_options?: InputMaybe<Scalars['Boolean']['input']>;
  show_contract_duration_sales?: InputMaybe<Scalars['Boolean']['input']>;
  show_lead_source_to_rep?: InputMaybe<Scalars['Boolean']['input']>;
  show_nocontact_button?: InputMaybe<Scalars['Boolean']['input']>;
  show_payment_terms_sales?: InputMaybe<Scalars['Boolean']['input']>;
  show_rep_all_leads?: InputMaybe<Scalars['Boolean']['input']>;
  show_rep_my_leads?: InputMaybe<Scalars['Boolean']['input']>;
  show_system_view?: InputMaybe<Scalars['Boolean']['input']>;
  sites?: InputMaybe<SiteCreateNestedManyWithoutOrganizationInput>;
  sms_opt_in_dialog?: InputMaybe<Scalars['String']['input']>;
  SMSItems?: InputMaybe<SmsItemCreateNestedManyWithoutOrganizationInput>;
  SnoozeTrackings?: InputMaybe<SnoozeTrackingCreateNestedManyWithoutOrganizationInput>;
  StepActionLogs?: InputMaybe<StepActionLogCreateNestedManyWithoutOrganizationInput>;
  StepActions?: InputMaybe<StepActionCreateNestedManyWithoutOrganizationInput>;
  StepConditions?: InputMaybe<StepConditionCreateNestedManyWithoutOrganizationInput>;
  teams?: InputMaybe<TeamCreateNestedManyWithoutOrganizationInput>;
  Template?: InputMaybe<TemplateCreateNestedManyWithoutOrganizationInput>;
  TemplateVersions?: InputMaybe<TemplateVersionCreateNestedManyWithoutOrganizationInput>;
  timekit_id?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  transfer_timeout?: InputMaybe<Scalars['Int']['input']>;
  TransferAttempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutOrganizationInput>;
  twilio_numbers?: InputMaybe<TwilioNumberCreateNestedManyWithoutOrganizationInput>;
  TwilioFeedbacks?: InputMaybe<TwilioFeedbackCreateNestedManyWithoutOrganizationInput>;
  TwilioInfo?: InputMaybe<TwilioInfoCreateNestedOneWithoutOrganzationInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  use_revenue_planning?: InputMaybe<Scalars['Boolean']['input']>;
  users?: InputMaybe<UserCreateNestedManyWithoutOrganizationInput>;
  win_rep_day_goal?: InputMaybe<Scalars['Float']['input']>;
};

export type OrganizationCreateWithoutUsersInput = {
  ActivityMonitorMultiplier?: InputMaybe<ActivityMonitorMultiplierCreateNestedManyWithoutOrganizationInput>;
  algoFactor?: InputMaybe<AlgoFactorCreateNestedManyWithoutOrganizationInput>;
  allow_calendar_invite?: InputMaybe<Scalars['Boolean']['input']>;
  allow_remove_step_action?: InputMaybe<Scalars['Boolean']['input']>;
  allow_snooze_step_action?: InputMaybe<Scalars['Boolean']['input']>;
  always_record?: InputMaybe<Scalars['Boolean']['input']>;
  arv_initial_held_demos_generated_by_sdr_weight?: InputMaybe<Scalars['Float']['input']>;
  AssociationHistories?: InputMaybe<AssociationHistoryCreateNestedManyWithoutOrganizationInput>;
  auto_call_interval?: InputMaybe<Scalars['Int']['input']>;
  auto_dial?: InputMaybe<Scalars['Boolean']['input']>;
  automated_campaigns?: InputMaybe<AutomatedCampaignCreateNestedManyWithoutOrganizationInput>;
  automatic_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  AutoTransferSelections?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutOrganizationInput>;
  Availablities?: InputMaybe<AvailabilityCreateNestedManyWithoutOrganizationInput>;
  AverageOrderValue?: InputMaybe<AverageOrderValueCreateNestedManyWithoutOrganizationInput>;
  best_action_ever_multiplier?: InputMaybe<Scalars['Float']['input']>;
  best_metrics?: InputMaybe<BestMetricRecordCreateNestedManyWithoutOrganizationInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptCreateNestedManyWithoutOrganizationInput>;
  callMeNows?: InputMaybe<CallMeNowCreateNestedManyWithoutOrganizationInput>;
  CallMultiplier?: InputMaybe<CallMultiplierCreateNestedManyWithoutOrganizationInput>;
  CallSharings?: InputMaybe<CallSharingCreateNestedManyWithoutOrganizationInput>;
  can_edit_sdr_transfer_rules?: InputMaybe<Scalars['Boolean']['input']>;
  claim_rule?: InputMaybe<Scalars['Int']['input']>;
  concession?: InputMaybe<ConcessionCreateNestedManyWithoutOrganizationInput>;
  conference_region?: InputMaybe<ConferenceRegion>;
  Conferences?: InputMaybe<ConferenceCreateNestedManyWithoutOrganizationInput>;
  ContactRequests?: InputMaybe<ContactRequestCreateNestedManyWithoutOrganizationInput>;
  Contacts?: InputMaybe<ContactCreateNestedManyWithoutOrganizationInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  custom_fields?: InputMaybe<CustomFieldCreateNestedManyWithoutOrganizationInput>;
  CustomObject?: InputMaybe<CustomObjectCreateNestedManyWithoutOrganizationInput>;
  CustomObjectField?: InputMaybe<CustomObjectFieldCreateNestedManyWithoutOrganizationInput>;
  CustomObjectFieldForeignKey?: InputMaybe<CustomObjectFieldForeignKeyCreateNestedManyWithoutOrganizationInput>;
  CustomObjectRecord?: InputMaybe<CustomObjectRecordCreateNestedManyWithoutOrganizationInput>;
  CustomObjectRow?: InputMaybe<CustomObjectRowCreateNestedManyWithoutOrganizationInput>;
  CustomQueueItems?: InputMaybe<CustomQueueItemCreateNestedManyWithoutOrganizationInput>;
  DashboardColumnOptions?: InputMaybe<DashboardColumnOptionCreateNestedManyWithoutOrganizationInput>;
  DashboardLeadFilters?: InputMaybe<DashboardLeadFilterCreateNestedManyWithoutOrganizationInput>;
  DashboardRepFilters?: InputMaybe<DashboardRepFilterCreateNestedManyWithoutOrganizationInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewCreateNestedManyWithoutOrganizationInput>;
  day_to_close?: InputMaybe<Scalars['Int']['input']>;
  default_demo_time_duration?: InputMaybe<Scalars['Int']['input']>;
  demo_held_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_not_held_call_back_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_not_held_call_back_short_delay?: InputMaybe<Scalars['Float']['input']>;
  demo_prep_time_minutes?: InputMaybe<Scalars['Int']['input']>;
  dev_account?: InputMaybe<Scalars['Boolean']['input']>;
  dial_rule?: InputMaybe<Scalars['Int']['input']>;
  DispositionMultiplier?: InputMaybe<DispositionMultiplierCreateNestedManyWithoutOrganizationInput>;
  dispositions?: InputMaybe<DispositionCreateNestedManyWithoutOrganizationInput>;
  double_booking?: InputMaybe<Scalars['Boolean']['input']>;
  dynamic_date_operators?: InputMaybe<Scalars['Boolean']['input']>;
  edge_servers?: InputMaybe<OrganizationCreateedge_ServersInput>;
  email_conflict_notification_email_list?: InputMaybe<OrganizationCreateemail_Conflict_Notification_Email_ListInput>;
  EmailItems?: InputMaybe<EmailItemCreateNestedManyWithoutOrganizationInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordCreateNestedManyWithoutOrganizationInput>;
  EmailThreads?: InputMaybe<EmailThreadCreateNestedManyWithoutOrganizationInput>;
  enable_modified_metrics?: InputMaybe<Scalars['Boolean']['input']>;
  enable_pay_period_view?: InputMaybe<Scalars['Boolean']['input']>;
  enable_time_constraint?: InputMaybe<Scalars['Boolean']['input']>;
  error_log_email_address?: InputMaybe<OrganizationCreateerror_Log_Email_AddressInput>;
  estimated_average_num_of_weekly_overbooked_demo_slots?: InputMaybe<Scalars['Int']['input']>;
  FolderPermissions?: InputMaybe<FolderPermissionCreateNestedManyWithoutOrganizationInput>;
  Folders?: InputMaybe<FolderCreateNestedManyWithoutOrganizationInput>;
  FutureBookingSetting?: InputMaybe<FutureBookingSettingCreateNestedOneWithoutOrganizationInput>;
  GlobalTransferRule?: InputMaybe<GlobalTransferRuleCreateNestedManyWithoutOrganizationInput>;
  held_demos_per_cc_connect?: InputMaybe<Scalars['Float']['input']>;
  helpful_links?: InputMaybe<OrganizationCreatehelpful_LinksInput>;
  HingePointMultiplier?: InputMaybe<HingePointMultiplierCreateNestedManyWithoutOrganizationInput>;
  hold_goal?: InputMaybe<Scalars['Float']['input']>;
  hubspot_credential?: InputMaybe<HubSpotCredentialCreateNestedOneWithoutOrganizationInput>;
  hubspot_last_sync_date?: InputMaybe<Scalars['DateTime']['input']>;
  hubspot_white_list?: InputMaybe<HubSpotWhitelistCreateNestedManyWithoutOrganizationInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  inbound_close_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_dm_connect_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_lt_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_revenue_value_of_sale?: InputMaybe<Scalars['Float']['input']>;
  inbound_scheduled_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  inbound_set_rate?: InputMaybe<Scalars['Float']['input']>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventCreateNestedManyWithoutOrganizationInput>;
  InboundConciergeWidget?: InputMaybe<InboundConciergeWidgetCreateNestedManyWithoutOrganizationInput>;
  industry_options?: InputMaybe<IndustryOptionCreateNestedManyWithoutOrganizationInput>;
  initial_sdr_sets_reps_day?: InputMaybe<Scalars['Float']['input']>;
  integration_error_logs?: InputMaybe<IntegrationErrorLogCreateNestedManyWithoutOrganizationInput>;
  integration_mappings?: InputMaybe<IntegrationMappingCreateNestedManyWithoutOrganizationInput>;
  IntegrationMappingCustomObject?: InputMaybe<IntegrationMappingCustomObjectCreateNestedManyWithoutOrganizationInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogCreateNestedManyWithoutOrganizationInput>;
  IntegrationReadOnlyObject?: InputMaybe<IntegrationReadOnlyObjectCreateNestedManyWithoutOrganizationInput>;
  international?: InputMaybe<Scalars['Boolean']['input']>;
  is_email_domain_verified?: InputMaybe<Scalars['Boolean']['input']>;
  is_salesforce_sandbox?: InputMaybe<Scalars['Boolean']['input']>;
  last_lead_value_compute_time?: InputMaybe<Scalars['DateTime']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutOrganizationInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryCreateNestedManyWithoutOrganizationInput>;
  lead_intent?: InputMaybe<LeadIntentCreateNestedManyWithoutOrganizationInput>;
  lead_release_same_rep_delay_rule?: InputMaybe<Scalars['Int']['input']>;
  lead_routing_email_list?: InputMaybe<OrganizationCreatelead_Routing_Email_ListInput>;
  lead_routing_rules?: InputMaybe<RuleCreateNestedManyWithoutOrganizationInput>;
  LeadCustomObjectRowAssociation?: InputMaybe<LeadCustomObjectRowAssociationCreateNestedManyWithoutOrganizationInput>;
  LeadFieldMultiplier?: InputMaybe<LeadFieldMultiplierCreateNestedManyWithoutOrganizationInput>;
  LeadFieldMultiplierCustom?: InputMaybe<LeadFieldMultiplierCustomCreateNestedManyWithoutOrganizationInput>;
  leads?: InputMaybe<LeadCreateNestedManyWithoutOrganizationInput>;
  leadsource_options?: InputMaybe<LeadSourceOptionCreateNestedManyWithoutOrganizationInput>;
  LeadValue?: InputMaybe<LeadValueCreateNestedManyWithoutOrganizationInput>;
  LifeTimeValueMultiplier?: InputMaybe<LifeTimeValueMultiplierCreateNestedManyWithoutOrganizationInput>;
  LiveTransferSetting?: InputMaybe<LiveTransferSettingCreateNestedOneWithoutOrganizationInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusCreateNestedManyWithoutOrganizationInput>;
  ltv_multiplier_custom_fields?: InputMaybe<LifeTimeValueMultiplierCustomCreateNestedManyWithoutOrganizationInput>;
  made_contact_rule?: InputMaybe<Scalars['Int']['input']>;
  make_sale_address_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_business_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_city_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_country_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_first_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_industry_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_last_name_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_lead_source_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_primary_email_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_primary_phone_number_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_state_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_sub_industry_required?: InputMaybe<Scalars['Boolean']['input']>;
  make_sale_zip_required?: InputMaybe<Scalars['Boolean']['input']>;
  maxium_day_lag_set_to_schedule?: InputMaybe<Scalars['Int']['input']>;
  mrr?: InputMaybe<Scalars['Int']['input']>;
  mrr_label?: InputMaybe<Scalars['String']['input']>;
  mrr_required_on_sale?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  NoteItems?: InputMaybe<NoteItemCreateNestedManyWithoutOrganizationInput>;
  Notifications?: InputMaybe<NotificationCreateNestedManyWithoutOrganizationInput>;
  onboardComplete?: InputMaybe<Scalars['Boolean']['input']>;
  Opportunity?: InputMaybe<SfOpportunityCreateNestedManyWithoutOrganizationInput>;
  organization_holidays?: InputMaybe<OrganizationHolidayCreateNestedManyWithoutOrganizationInput>;
  organization_hours?: InputMaybe<OrganizationHourCreateNestedManyWithoutOrganizationInput>;
  OrganizationPaymentSetting?: InputMaybe<OrganizationPaymentSettingsCreateNestedOneWithoutOrganizationInput>;
  OrganizationPaymentSettings?: InputMaybe<OrganizationPaymentSettingsCreateNestedOneWithoutOrganizationsInput>;
  orgCalendarInviteMetadata?: InputMaybe<OrgCalendarInviteMetadataCreateNestedManyWithoutOrganizationInput>;
  OrgDefaultPipelinePerecentValue?: InputMaybe<OrgDefaultPipelinePerecentValueCreateNestedOneWithoutOrganizationInput>;
  OrgHubSpotSync?: InputMaybe<OrgHubSpotSyncCreateNestedOneWithoutOrganizationInput>;
  OrgNoEventDate?: InputMaybe<OrgNoEventDateCreateNestedManyWithoutOrganizationInput>;
  OrgSalesforceSync?: InputMaybe<OrgSalesforceSyncCreateNestedOneWithoutOrganizationInput>;
  OrgTimeslotAvailability?: InputMaybe<OrgTimeslotAvailabilityCreateNestedManyWithoutOrganizationInput>;
  outbound_close_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_dm_connect_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_lt_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_revenue_value_of_sale?: InputMaybe<Scalars['Float']['input']>;
  outbound_scheduled_hold_rate?: InputMaybe<Scalars['Float']['input']>;
  outbound_set_rate?: InputMaybe<Scalars['Float']['input']>;
  PandadocData?: InputMaybe<PandadocDataCreateNestedOneWithoutOrganizationInput>;
  PandaDocTemplate?: InputMaybe<PandaDocTemplateCreateNestedManyWithoutOrganizationInput>;
  participant_jitter_buffer_size?: InputMaybe<JitterBufferSize>;
  preferred_goal_setting?: InputMaybe<Goalsetting>;
  prepayment?: InputMaybe<Scalars['Boolean']['input']>;
  ProductChangeLog?: InputMaybe<ProductChangeLogCreateNestedManyWithoutOrganizationInput>;
  products?: InputMaybe<ProductCreateNestedManyWithoutOrganizationInput>;
  re_cold_call_hour_delay?: InputMaybe<Scalars['Float']['input']>;
  recordingStatus?: InputMaybe<RecordingStatus>;
  release_lead_resting?: InputMaybe<Scalars['Int']['input']>;
  rep_claim_limit?: InputMaybe<Scalars['Int']['input']>;
  rep_lead_allotment?: InputMaybe<Scalars['Int']['input']>;
  rep_level_call_transfer?: InputMaybe<Scalars['Boolean']['input']>;
  reps_can_bulk_unassign?: InputMaybe<Scalars['Boolean']['input']>;
  reps_can_view_all_notes?: InputMaybe<Scalars['Boolean']['input']>;
  require_sale_notes?: InputMaybe<Scalars['Boolean']['input']>;
  resting_after_skipped_CC?: InputMaybe<Scalars['Int']['input']>;
  resting_period?: InputMaybe<Scalars['Int']['input']>;
  resting_period_after_unsuccessful_cold_call?: InputMaybe<Scalars['Float']['input']>;
  resting_period_after_unsuccessful_demo?: InputMaybe<Scalars['Float']['input']>;
  revenue_plan?: InputMaybe<RevenuePlanItemCreateNestedManyWithoutOrganizationInput>;
  Routings?: InputMaybe<RoutingCreateNestedManyWithoutOrganizationInput>;
  sale_field_options?: InputMaybe<SaleFieldOptionCreateNestedManyWithoutOrganizationInput>;
  sale_notification_email_list?: InputMaybe<OrganizationCreatesale_Notification_Email_ListInput>;
  SaleConfig?: InputMaybe<SaleConfigCreateNestedOneWithoutOrganizationInput>;
  SaleConfigPage?: InputMaybe<SaleConfigPageCreateNestedManyWithoutOrganizationInput>;
  SaleConfigSection?: InputMaybe<SaleConfigSectionCreateNestedManyWithoutOrganizationInput>;
  SaleCycles?: InputMaybe<SaleCycleCreateNestedManyWithoutOrganizationInput>;
  SaleItems?: InputMaybe<SaleItemCreateNestedManyWithoutOrganizationInput>;
  sales?: InputMaybe<SaleCreateNestedManyWithoutOrganizationInput>;
  salesforce_domain?: InputMaybe<Scalars['String']['input']>;
  salesforce_last_sync_date?: InputMaybe<Scalars['DateTime']['input']>;
  SalesForceColumn?: InputMaybe<SalesForceColumnCreateNestedManyWithoutOrganizationInput>;
  salesForceData?: InputMaybe<SalesForceDataCreateNestedOneWithoutOrganizationInput>;
  ScheduleItems?: InputMaybe<ScheduleItemCreateNestedManyWithoutOrganizationInput>;
  self_sourcing_rule?: InputMaybe<Scalars['Int']['input']>;
  send_error_log_email?: InputMaybe<Scalars['Boolean']['input']>;
  send_lead_routing_email?: InputMaybe<Scalars['Boolean']['input']>;
  sendgrid_email_domain?: InputMaybe<Scalars['String']['input']>;
  sendgrid_email_domain_id?: InputMaybe<Scalars['Int']['input']>;
  sequence?: InputMaybe<SequenceCreateNestedManyWithoutOrganzationInput>;
  SequenceCriteriaConditions?: InputMaybe<SequenceCriteriaConditionCreateNestedManyWithoutOrganizationInput>;
  SequenceDashboardViews?: InputMaybe<SequenceDashboardViewCreateNestedManyWithoutOrganizationInput>;
  SequenceEntryCriterias?: InputMaybe<SequenceEntryCriteriaCreateNestedManyWithoutOrganizationInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutOrganizationInput>;
  SequenceSteps?: InputMaybe<SequenceStepCreateNestedManyWithoutOrganizationInput>;
  set_goal?: InputMaybe<Scalars['Float']['input']>;
  short_resting?: InputMaybe<Scalars['Int']['input']>;
  show_calendar_options?: InputMaybe<Scalars['Boolean']['input']>;
  show_contract_duration_sales?: InputMaybe<Scalars['Boolean']['input']>;
  show_lead_source_to_rep?: InputMaybe<Scalars['Boolean']['input']>;
  show_nocontact_button?: InputMaybe<Scalars['Boolean']['input']>;
  show_payment_terms_sales?: InputMaybe<Scalars['Boolean']['input']>;
  show_rep_all_leads?: InputMaybe<Scalars['Boolean']['input']>;
  show_rep_my_leads?: InputMaybe<Scalars['Boolean']['input']>;
  show_system_view?: InputMaybe<Scalars['Boolean']['input']>;
  sites?: InputMaybe<SiteCreateNestedManyWithoutOrganizationInput>;
  sms_opt_in_dialog?: InputMaybe<Scalars['String']['input']>;
  SMSItems?: InputMaybe<SmsItemCreateNestedManyWithoutOrganizationInput>;
  SnoozeTrackings?: InputMaybe<SnoozeTrackingCreateNestedManyWithoutOrganizationInput>;
  StepActionLogs?: InputMaybe<StepActionLogCreateNestedManyWithoutOrganizationInput>;
  StepActions?: InputMaybe<StepActionCreateNestedManyWithoutOrganizationInput>;
  StepConditions?: InputMaybe<StepConditionCreateNestedManyWithoutOrganizationInput>;
  teams?: InputMaybe<TeamCreateNestedManyWithoutOrganizationInput>;
  Template?: InputMaybe<TemplateCreateNestedManyWithoutOrganizationInput>;
  TemplateVersions?: InputMaybe<TemplateVersionCreateNestedManyWithoutOrganizationInput>;
  timekit_id?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  transfer_timeout?: InputMaybe<Scalars['Int']['input']>;
  TransferAttempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutOrganizationInput>;
  twilio_numbers?: InputMaybe<TwilioNumberCreateNestedManyWithoutOrganizationInput>;
  TwilioFeedbacks?: InputMaybe<TwilioFeedbackCreateNestedManyWithoutOrganizationInput>;
  TwilioInfo?: InputMaybe<TwilioInfoCreateNestedOneWithoutOrganzationInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  use_revenue_planning?: InputMaybe<Scalars['Boolean']['input']>;
  UserCustomFieldFilters?: InputMaybe<UserCustomFieldFilterCreateNestedManyWithoutOrganizationInput>;
  win_rep_day_goal?: InputMaybe<Scalars['Float']['input']>;
};

export type OrganizationHoliday = {
  __typename?: 'OrganizationHoliday';
  created_at: Scalars['DateTime']['output'];
  holiday: Holiday;
  holiday_id: Scalars['String']['output'];
  id: Scalars['String']['output'];
  observe: Scalars['Boolean']['output'];
  organization: Organization;
  organization_id: Scalars['String']['output'];
  updated_at: Scalars['DateTime']['output'];
};

export type OrganizationHolidayCreateManyOrganizationInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  holiday_id: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  observe: Scalars['Boolean']['input'];
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type OrganizationHolidayCreateManyOrganizationInputEnvelope = {
  data?: InputMaybe<Array<OrganizationHolidayCreateManyOrganizationInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type OrganizationHolidayCreateNestedManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<OrganizationHolidayWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<OrganizationHolidayCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<OrganizationHolidayCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<OrganizationHolidayCreateManyOrganizationInputEnvelope>;
};

export type OrganizationHolidayCreateOrConnectWithoutOrganizationInput = {
  create: OrganizationHolidayCreateWithoutOrganizationInput;
  where: OrganizationHolidayWhereUniqueInput;
};

export type OrganizationHolidayCreateWithoutOrganizationInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  holiday: HolidayCreateNestedOneWithoutOrganization_HolidayInput;
  id?: InputMaybe<Scalars['String']['input']>;
  observe: Scalars['Boolean']['input'];
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type OrganizationHolidayListRelationFilter = {
  every?: InputMaybe<OrganizationHolidayWhereInput>;
  none?: InputMaybe<OrganizationHolidayWhereInput>;
  some?: InputMaybe<OrganizationHolidayWhereInput>;
};

export type OrganizationHolidayOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
  count?: InputMaybe<SortOrder>;
};

export type OrganizationHolidayScalarWhereInput = {
  AND?: InputMaybe<Array<OrganizationHolidayScalarWhereInput>>;
  created_at?: InputMaybe<DateTimeFilter>;
  holiday_id?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  NOT?: InputMaybe<Array<OrganizationHolidayScalarWhereInput>>;
  observe?: InputMaybe<BoolFilter>;
  OR?: InputMaybe<Array<OrganizationHolidayScalarWhereInput>>;
  organization_id?: InputMaybe<StringFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
};

export type OrganizationHolidaysInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  observe?: InputMaybe<Scalars['Boolean']['input']>;
};

export type OrganizationHolidayUpdateManyMutationInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  observe?: InputMaybe<BoolFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type OrganizationHolidayUpdateManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<OrganizationHolidayWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<OrganizationHolidayCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<OrganizationHolidayCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<OrganizationHolidayCreateManyOrganizationInputEnvelope>;
  delete?: InputMaybe<Array<OrganizationHolidayWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<OrganizationHolidayScalarWhereInput>>;
  disconnect?: InputMaybe<Array<OrganizationHolidayWhereUniqueInput>>;
  set?: InputMaybe<Array<OrganizationHolidayWhereUniqueInput>>;
  update?: InputMaybe<Array<OrganizationHolidayUpdateWithWhereUniqueWithoutOrganizationInput>>;
  updateMany?: InputMaybe<Array<OrganizationHolidayUpdateManyWithWhereWithoutOrganizationInput>>;
  upsert?: InputMaybe<Array<OrganizationHolidayUpsertWithWhereUniqueWithoutOrganizationInput>>;
};

export type OrganizationHolidayUpdateManyWithWhereWithoutOrganizationInput = {
  data: OrganizationHolidayUpdateManyMutationInput;
  where: OrganizationHolidayScalarWhereInput;
};

export type OrganizationHolidayUpdateWithoutOrganizationInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  holiday?: InputMaybe<HolidayUpdateOneRequiredWithoutOrganization_HolidayInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  observe?: InputMaybe<BoolFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type OrganizationHolidayUpdateWithWhereUniqueWithoutOrganizationInput = {
  data: OrganizationHolidayUpdateWithoutOrganizationInput;
  where: OrganizationHolidayWhereUniqueInput;
};

export type OrganizationHolidayUpsertWithWhereUniqueWithoutOrganizationInput = {
  create: OrganizationHolidayCreateWithoutOrganizationInput;
  update: OrganizationHolidayUpdateWithoutOrganizationInput;
  where: OrganizationHolidayWhereUniqueInput;
};

export type OrganizationHolidayWhereInput = {
  AND?: InputMaybe<Array<OrganizationHolidayWhereInput>>;
  created_at?: InputMaybe<DateTimeFilter>;
  holiday?: InputMaybe<HolidayWhereInput>;
  holiday_id?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  NOT?: InputMaybe<Array<OrganizationHolidayWhereInput>>;
  observe?: InputMaybe<BoolFilter>;
  OR?: InputMaybe<Array<OrganizationHolidayWhereInput>>;
  organization?: InputMaybe<OrganizationWhereInput>;
  organization_id?: InputMaybe<StringFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
};

export type OrganizationHolidayWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']['input']>;
};

export type OrganizationHour = {
  __typename?: 'OrganizationHour';
  created_at: Scalars['DateTime']['output'];
  day: Weekday;
  end_hour?: Maybe<Scalars['Int']['output']>;
  end_minute?: Maybe<Scalars['Int']['output']>;
  /** computed representation of end_time in string format */
  end_time?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  organization: Organization;
  organization_id: Scalars['String']['output'];
  start_hour?: Maybe<Scalars['Int']['output']>;
  start_minute?: Maybe<Scalars['Int']['output']>;
  /** computed representation of start_time in string format */
  start_time?: Maybe<Scalars['String']['output']>;
  updated_at: Scalars['DateTime']['output'];
};

export type OrganizationHourCreateManyOrganizationInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  day: Weekday;
  end_hour?: InputMaybe<Scalars['Int']['input']>;
  end_minute?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  start_hour?: InputMaybe<Scalars['Int']['input']>;
  start_minute?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type OrganizationHourCreateManyOrganizationInputEnvelope = {
  data?: InputMaybe<Array<OrganizationHourCreateManyOrganizationInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type OrganizationHourCreateNestedManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<OrganizationHourWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<OrganizationHourCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<OrganizationHourCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<OrganizationHourCreateManyOrganizationInputEnvelope>;
};

export type OrganizationHourCreateOrConnectWithoutOrganizationInput = {
  create: OrganizationHourCreateWithoutOrganizationInput;
  where: OrganizationHourWhereUniqueInput;
};

export type OrganizationHourCreateWithoutOrganizationInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  day: Weekday;
  end_hour?: InputMaybe<Scalars['Int']['input']>;
  end_minute?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  start_hour?: InputMaybe<Scalars['Int']['input']>;
  start_minute?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

/** Used to input organization hours into a mutation/query */
export type OrganizationHourInput = {
  day: Weekday;
  end_hour?: InputMaybe<Scalars['Int']['input']>;
  end_minute?: InputMaybe<Scalars['Int']['input']>;
  /** Id of the org hour. Not required. */
  id?: InputMaybe<Scalars['String']['input']>;
  start_hour?: InputMaybe<Scalars['Int']['input']>;
  start_minute?: InputMaybe<Scalars['Int']['input']>;
};

export type OrganizationHourListRelationFilter = {
  every?: InputMaybe<OrganizationHourWhereInput>;
  none?: InputMaybe<OrganizationHourWhereInput>;
  some?: InputMaybe<OrganizationHourWhereInput>;
};

export type OrganizationHourOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
  count?: InputMaybe<SortOrder>;
};

export type OrganizationHourScalarWhereInput = {
  AND?: InputMaybe<Array<OrganizationHourScalarWhereInput>>;
  created_at?: InputMaybe<DateTimeFilter>;
  day?: InputMaybe<EnumWeekdayFilter>;
  end_hour?: InputMaybe<IntNullableFilter>;
  end_minute?: InputMaybe<IntNullableFilter>;
  id?: InputMaybe<StringFilter>;
  NOT?: InputMaybe<Array<OrganizationHourScalarWhereInput>>;
  OR?: InputMaybe<Array<OrganizationHourScalarWhereInput>>;
  organization_id?: InputMaybe<StringFilter>;
  start_hour?: InputMaybe<IntNullableFilter>;
  start_minute?: InputMaybe<IntNullableFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
};

export type OrganizationHoursInput = {
  callback_duration?: InputMaybe<Scalars['Int']['input']>;
  callback_overflow?: InputMaybe<Scalars['Int']['input']>;
  decision_call_duration?: InputMaybe<Scalars['Int']['input']>;
  decision_call_overflow?: InputMaybe<Scalars['Int']['input']>;
  demo_duration?: InputMaybe<Scalars['Int']['input']>;
  demo_overflow?: InputMaybe<Scalars['Int']['input']>;
  double_booking?: InputMaybe<Scalars['Boolean']['input']>;
  enable_pay_period_view?: InputMaybe<Scalars['Boolean']['input']>;
  enable_time_constraint?: InputMaybe<Scalars['Boolean']['input']>;
  organization_hours?: InputMaybe<Array<OrganizationHourInput>>;
  timezone: Scalars['String']['input'];
};

export type OrganizationHourUpdateManyMutationInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  day?: InputMaybe<EnumWeekdayFieldUpdateOperationsInput>;
  end_hour?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  end_minute?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  start_hour?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  start_minute?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type OrganizationHourUpdateManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<OrganizationHourWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<OrganizationHourCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<OrganizationHourCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<OrganizationHourCreateManyOrganizationInputEnvelope>;
  delete?: InputMaybe<Array<OrganizationHourWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<OrganizationHourScalarWhereInput>>;
  disconnect?: InputMaybe<Array<OrganizationHourWhereUniqueInput>>;
  set?: InputMaybe<Array<OrganizationHourWhereUniqueInput>>;
  update?: InputMaybe<Array<OrganizationHourUpdateWithWhereUniqueWithoutOrganizationInput>>;
  updateMany?: InputMaybe<Array<OrganizationHourUpdateManyWithWhereWithoutOrganizationInput>>;
  upsert?: InputMaybe<Array<OrganizationHourUpsertWithWhereUniqueWithoutOrganizationInput>>;
};

export type OrganizationHourUpdateManyWithWhereWithoutOrganizationInput = {
  data: OrganizationHourUpdateManyMutationInput;
  where: OrganizationHourScalarWhereInput;
};

export type OrganizationHourUpdateWithoutOrganizationInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  day?: InputMaybe<EnumWeekdayFieldUpdateOperationsInput>;
  end_hour?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  end_minute?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  start_hour?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  start_minute?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type OrganizationHourUpdateWithWhereUniqueWithoutOrganizationInput = {
  data: OrganizationHourUpdateWithoutOrganizationInput;
  where: OrganizationHourWhereUniqueInput;
};

export type OrganizationHourUpsertWithWhereUniqueWithoutOrganizationInput = {
  create: OrganizationHourCreateWithoutOrganizationInput;
  update: OrganizationHourUpdateWithoutOrganizationInput;
  where: OrganizationHourWhereUniqueInput;
};

export type OrganizationHourWhereInput = {
  AND?: InputMaybe<Array<OrganizationHourWhereInput>>;
  created_at?: InputMaybe<DateTimeFilter>;
  day?: InputMaybe<EnumWeekdayFilter>;
  end_hour?: InputMaybe<IntNullableFilter>;
  end_minute?: InputMaybe<IntNullableFilter>;
  id?: InputMaybe<StringFilter>;
  NOT?: InputMaybe<Array<OrganizationHourWhereInput>>;
  OR?: InputMaybe<Array<OrganizationHourWhereInput>>;
  organization?: InputMaybe<OrganizationWhereInput>;
  organization_id?: InputMaybe<StringFilter>;
  start_hour?: InputMaybe<IntNullableFilter>;
  start_minute?: InputMaybe<IntNullableFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
};

export type OrganizationHourWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']['input']>;
};

export type OrganizationListRelationFilter = {
  every?: InputMaybe<OrganizationWhereInput>;
  none?: InputMaybe<OrganizationWhereInput>;
  some?: InputMaybe<OrganizationWhereInput>;
};

export type OrganizationLog = {
  __typename?: 'OrganizationLog';
  dml_created_by?: Maybe<Scalars['String']['output']>;
  dml_timestamp: Scalars['DateTime']['output'];
  dml_type: Dml_Type;
  new_row_data?: Maybe<Scalars['Json']['output']>;
  old_row_data?: Maybe<Scalars['Json']['output']>;
  organization_id: Scalars['String']['output'];
};

export type OrganizationOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
  count?: InputMaybe<SortOrder>;
};

export type OrganizationOrderByWithRelationInput = {
  ActivityMonitorMultiplier?: InputMaybe<ActivityMonitorMultiplierOrderByRelationAggregateInput>;
  algoFactor?: InputMaybe<AlgoFactorOrderByRelationAggregateInput>;
  allow_calendar_invite?: InputMaybe<SortOrder>;
  allow_remove_step_action?: InputMaybe<SortOrder>;
  allow_snooze_step_action?: InputMaybe<SortOrder>;
  always_record?: InputMaybe<SortOrder>;
  arv_initial_held_demos_generated_by_sdr_weight?: InputMaybe<SortOrder>;
  AssociationHistories?: InputMaybe<AssociationHistoryOrderByRelationAggregateInput>;
  auto_call_interval?: InputMaybe<SortOrder>;
  auto_dial?: InputMaybe<SortOrder>;
  automated_campaigns?: InputMaybe<AutomatedCampaignOrderByRelationAggregateInput>;
  automatic_call_back_delay?: InputMaybe<SortOrder>;
  AutoTransferSelections?: InputMaybe<AutoTransferSelectionOrderByRelationAggregateInput>;
  Availablities?: InputMaybe<AvailabilityOrderByRelationAggregateInput>;
  AverageOrderValue?: InputMaybe<AverageOrderValueOrderByRelationAggregateInput>;
  best_action_ever_multiplier?: InputMaybe<SortOrder>;
  best_metrics?: InputMaybe<BestMetricRecordOrderByRelationAggregateInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptOrderByRelationAggregateInput>;
  callMeNows?: InputMaybe<CallMeNowOrderByRelationAggregateInput>;
  CallMultiplier?: InputMaybe<CallMultiplierOrderByRelationAggregateInput>;
  CallSharings?: InputMaybe<CallSharingOrderByRelationAggregateInput>;
  can_edit_sdr_transfer_rules?: InputMaybe<SortOrder>;
  claim_rule?: InputMaybe<SortOrder>;
  concession?: InputMaybe<ConcessionOrderByRelationAggregateInput>;
  conference_region?: InputMaybe<SortOrder>;
  Conferences?: InputMaybe<ConferenceOrderByRelationAggregateInput>;
  ContactRequests?: InputMaybe<ContactRequestOrderByRelationAggregateInput>;
  Contacts?: InputMaybe<ContactOrderByRelationAggregateInput>;
  created_at?: InputMaybe<SortOrder>;
  custom_fields?: InputMaybe<CustomFieldOrderByRelationAggregateInput>;
  CustomObject?: InputMaybe<CustomObjectOrderByRelationAggregateInput>;
  CustomObjectField?: InputMaybe<CustomObjectFieldOrderByRelationAggregateInput>;
  CustomObjectFieldForeignKey?: InputMaybe<CustomObjectFieldForeignKeyOrderByRelationAggregateInput>;
  CustomObjectRecord?: InputMaybe<CustomObjectRecordOrderByRelationAggregateInput>;
  CustomObjectRow?: InputMaybe<CustomObjectRowOrderByRelationAggregateInput>;
  CustomQueueItems?: InputMaybe<CustomQueueItemOrderByRelationAggregateInput>;
  DashboardColumnOptions?: InputMaybe<DashboardColumnOptionOrderByRelationAggregateInput>;
  DashboardLeadFilters?: InputMaybe<DashboardLeadFilterOrderByRelationAggregateInput>;
  DashboardRepFilters?: InputMaybe<DashboardRepFilterOrderByRelationAggregateInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewOrderByRelationAggregateInput>;
  day_to_close?: InputMaybe<SortOrder>;
  default_demo_time_duration?: InputMaybe<SortOrder>;
  demo_held_call_back_delay?: InputMaybe<SortOrder>;
  demo_not_held_call_back_delay?: InputMaybe<SortOrder>;
  demo_not_held_call_back_short_delay?: InputMaybe<SortOrder>;
  demo_prep_time_minutes?: InputMaybe<SortOrder>;
  dev_account?: InputMaybe<SortOrder>;
  dial_rule?: InputMaybe<SortOrder>;
  DispositionMultiplier?: InputMaybe<DispositionMultiplierOrderByRelationAggregateInput>;
  dispositions?: InputMaybe<DispositionOrderByRelationAggregateInput>;
  double_booking?: InputMaybe<SortOrder>;
  dynamic_date_operators?: InputMaybe<SortOrder>;
  edge_servers?: InputMaybe<SortOrder>;
  email_conflict_notification_email_list?: InputMaybe<SortOrder>;
  EmailItems?: InputMaybe<EmailItemOrderByRelationAggregateInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordOrderByRelationAggregateInput>;
  EmailThreads?: InputMaybe<EmailThreadOrderByRelationAggregateInput>;
  enable_modified_metrics?: InputMaybe<SortOrder>;
  enable_pay_period_view?: InputMaybe<SortOrder>;
  enable_time_constraint?: InputMaybe<SortOrder>;
  error_log_email_address?: InputMaybe<SortOrder>;
  estimated_average_num_of_weekly_overbooked_demo_slots?: InputMaybe<SortOrder>;
  FolderPermissions?: InputMaybe<FolderPermissionOrderByRelationAggregateInput>;
  Folders?: InputMaybe<FolderOrderByRelationAggregateInput>;
  FutureBookingSetting?: InputMaybe<FutureBookingSettingOrderByWithRelationInput>;
  GlobalTransferRule?: InputMaybe<GlobalTransferRuleOrderByRelationAggregateInput>;
  held_demos_per_cc_connect?: InputMaybe<SortOrder>;
  helpful_links?: InputMaybe<SortOrder>;
  HingePointMultiplier?: InputMaybe<HingePointMultiplierOrderByRelationAggregateInput>;
  hold_goal?: InputMaybe<SortOrder>;
  hubspot_credential?: InputMaybe<HubSpotCredentialOrderByWithRelationInput>;
  hubspot_last_sync_date?: InputMaybe<SortOrder>;
  hubspot_white_list?: InputMaybe<HubSpotWhitelistOrderByRelationAggregateInput>;
  id?: InputMaybe<SortOrder>;
  inbound_close_rate?: InputMaybe<SortOrder>;
  inbound_dm_connect_rate?: InputMaybe<SortOrder>;
  inbound_lt_hold_rate?: InputMaybe<SortOrder>;
  inbound_revenue_value_of_sale?: InputMaybe<SortOrder>;
  inbound_scheduled_hold_rate?: InputMaybe<SortOrder>;
  inbound_set_rate?: InputMaybe<SortOrder>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventOrderByRelationAggregateInput>;
  InboundConciergeWidget?: InputMaybe<InboundConciergeWidgetOrderByRelationAggregateInput>;
  industry_options?: InputMaybe<IndustryOptionOrderByRelationAggregateInput>;
  initial_sdr_sets_reps_day?: InputMaybe<SortOrder>;
  integration_error_logs?: InputMaybe<IntegrationErrorLogOrderByRelationAggregateInput>;
  integration_mappings?: InputMaybe<IntegrationMappingOrderByRelationAggregateInput>;
  IntegrationMappingCustomObject?: InputMaybe<IntegrationMappingCustomObjectOrderByRelationAggregateInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogOrderByRelationAggregateInput>;
  IntegrationReadOnlyObject?: InputMaybe<IntegrationReadOnlyObjectOrderByRelationAggregateInput>;
  international?: InputMaybe<SortOrder>;
  is_email_domain_verified?: InputMaybe<SortOrder>;
  is_salesforce_sandbox?: InputMaybe<SortOrder>;
  last_lead_value_compute_time?: InputMaybe<SortOrder>;
  lead_activities?: InputMaybe<LeadActivityOrderByRelationAggregateInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryOrderByRelationAggregateInput>;
  lead_intent?: InputMaybe<LeadIntentOrderByRelationAggregateInput>;
  lead_release_same_rep_delay_rule?: InputMaybe<SortOrder>;
  lead_routing_email_list?: InputMaybe<SortOrder>;
  lead_routing_rules?: InputMaybe<RuleOrderByRelationAggregateInput>;
  LeadCustomObjectRowAssociation?: InputMaybe<LeadCustomObjectRowAssociationOrderByRelationAggregateInput>;
  LeadFieldMultiplier?: InputMaybe<LeadFieldMultiplierOrderByRelationAggregateInput>;
  LeadFieldMultiplierCustom?: InputMaybe<LeadFieldMultiplierCustomOrderByRelationAggregateInput>;
  leads?: InputMaybe<LeadOrderByRelationAggregateInput>;
  leadsource_options?: InputMaybe<LeadSourceOptionOrderByRelationAggregateInput>;
  LeadValue?: InputMaybe<LeadValueOrderByRelationAggregateInput>;
  LifeTimeValueMultiplier?: InputMaybe<LifeTimeValueMultiplierOrderByRelationAggregateInput>;
  LiveTransferSetting?: InputMaybe<LiveTransferSettingOrderByWithRelationInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusOrderByRelationAggregateInput>;
  ltv_multiplier_custom_fields?: InputMaybe<LifeTimeValueMultiplierCustomOrderByRelationAggregateInput>;
  made_contact_rule?: InputMaybe<SortOrder>;
  make_sale_address_required?: InputMaybe<SortOrder>;
  make_sale_business_name_required?: InputMaybe<SortOrder>;
  make_sale_city_required?: InputMaybe<SortOrder>;
  make_sale_country_required?: InputMaybe<SortOrder>;
  make_sale_first_name_required?: InputMaybe<SortOrder>;
  make_sale_industry_required?: InputMaybe<SortOrder>;
  make_sale_last_name_required?: InputMaybe<SortOrder>;
  make_sale_lead_source_required?: InputMaybe<SortOrder>;
  make_sale_primary_email_required?: InputMaybe<SortOrder>;
  make_sale_primary_phone_number_required?: InputMaybe<SortOrder>;
  make_sale_state_required?: InputMaybe<SortOrder>;
  make_sale_sub_industry_required?: InputMaybe<SortOrder>;
  make_sale_zip_required?: InputMaybe<SortOrder>;
  maxium_day_lag_set_to_schedule?: InputMaybe<SortOrder>;
  mrr?: InputMaybe<SortOrder>;
  mrr_label?: InputMaybe<SortOrder>;
  mrr_required_on_sale?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  NoteItems?: InputMaybe<NoteItemOrderByRelationAggregateInput>;
  Notifications?: InputMaybe<NotificationOrderByRelationAggregateInput>;
  onboardComplete?: InputMaybe<SortOrder>;
  Opportunity?: InputMaybe<SfOpportunityOrderByRelationAggregateInput>;
  organization_holidays?: InputMaybe<OrganizationHolidayOrderByRelationAggregateInput>;
  organization_hours?: InputMaybe<OrganizationHourOrderByRelationAggregateInput>;
  OrganizationPaymentSetting?: InputMaybe<OrganizationPaymentSettingsOrderByWithRelationInput>;
  OrganizationPaymentSettings?: InputMaybe<OrganizationPaymentSettingsOrderByWithRelationInput>;
  orgCalendarInviteMetadata?: InputMaybe<OrgCalendarInviteMetadataOrderByRelationAggregateInput>;
  OrgDefaultPipelinePerecentValue?: InputMaybe<OrgDefaultPipelinePerecentValueOrderByWithRelationInput>;
  OrgHubSpotSync?: InputMaybe<OrgHubSpotSyncOrderByWithRelationInput>;
  OrgNoEventDate?: InputMaybe<OrgNoEventDateOrderByRelationAggregateInput>;
  OrgSalesforceSync?: InputMaybe<OrgSalesforceSyncOrderByWithRelationInput>;
  OrgTimeslotAvailability?: InputMaybe<OrgTimeslotAvailabilityOrderByRelationAggregateInput>;
  outbound_close_rate?: InputMaybe<SortOrder>;
  outbound_dm_connect_rate?: InputMaybe<SortOrder>;
  outbound_lt_hold_rate?: InputMaybe<SortOrder>;
  outbound_revenue_value_of_sale?: InputMaybe<SortOrder>;
  outbound_scheduled_hold_rate?: InputMaybe<SortOrder>;
  outbound_set_rate?: InputMaybe<SortOrder>;
  PandadocData?: InputMaybe<PandadocDataOrderByWithRelationInput>;
  PandaDocTemplate?: InputMaybe<PandaDocTemplateOrderByRelationAggregateInput>;
  participant_jitter_buffer_size?: InputMaybe<SortOrder>;
  payment_config_id?: InputMaybe<SortOrder>;
  preferred_goal_setting?: InputMaybe<SortOrder>;
  prepayment?: InputMaybe<SortOrder>;
  ProductChangeLog?: InputMaybe<ProductChangeLogOrderByRelationAggregateInput>;
  products?: InputMaybe<ProductOrderByRelationAggregateInput>;
  re_cold_call_hour_delay?: InputMaybe<SortOrder>;
  recordingStatus?: InputMaybe<SortOrder>;
  release_lead_resting?: InputMaybe<SortOrder>;
  rep_claim_limit?: InputMaybe<SortOrder>;
  rep_lead_allotment?: InputMaybe<SortOrder>;
  rep_level_call_transfer?: InputMaybe<SortOrder>;
  reps_can_bulk_unassign?: InputMaybe<SortOrder>;
  reps_can_view_all_notes?: InputMaybe<SortOrder>;
  require_sale_notes?: InputMaybe<SortOrder>;
  resting_after_skipped_CC?: InputMaybe<SortOrder>;
  resting_period?: InputMaybe<SortOrder>;
  resting_period_after_unsuccessful_cold_call?: InputMaybe<SortOrder>;
  resting_period_after_unsuccessful_demo?: InputMaybe<SortOrder>;
  revenue_plan?: InputMaybe<RevenuePlanItemOrderByRelationAggregateInput>;
  Routings?: InputMaybe<RoutingOrderByRelationAggregateInput>;
  sale_config_id?: InputMaybe<SortOrder>;
  sale_field_options?: InputMaybe<SaleFieldOptionOrderByRelationAggregateInput>;
  sale_notification_email_list?: InputMaybe<SortOrder>;
  SaleConfig?: InputMaybe<SaleConfigOrderByWithRelationInput>;
  SaleConfigPage?: InputMaybe<SaleConfigPageOrderByRelationAggregateInput>;
  SaleConfigSection?: InputMaybe<SaleConfigSectionOrderByRelationAggregateInput>;
  SaleCycles?: InputMaybe<SaleCycleOrderByRelationAggregateInput>;
  SaleItems?: InputMaybe<SaleItemOrderByRelationAggregateInput>;
  sales?: InputMaybe<SaleOrderByRelationAggregateInput>;
  salesforce_domain?: InputMaybe<SortOrder>;
  salesforce_last_sync_date?: InputMaybe<SortOrder>;
  SalesForceColumn?: InputMaybe<SalesForceColumnOrderByRelationAggregateInput>;
  salesForceData?: InputMaybe<SalesForceDataOrderByWithRelationInput>;
  ScheduleItems?: InputMaybe<ScheduleItemOrderByRelationAggregateInput>;
  self_sourcing_rule?: InputMaybe<SortOrder>;
  send_error_log_email?: InputMaybe<SortOrder>;
  send_lead_routing_email?: InputMaybe<SortOrder>;
  sendgrid_email_domain?: InputMaybe<SortOrder>;
  sendgrid_email_domain_id?: InputMaybe<SortOrder>;
  sequence?: InputMaybe<SequenceOrderByRelationAggregateInput>;
  SequenceCriteriaConditions?: InputMaybe<SequenceCriteriaConditionOrderByRelationAggregateInput>;
  SequenceDashboardViews?: InputMaybe<SequenceDashboardViewOrderByRelationAggregateInput>;
  SequenceEntryCriterias?: InputMaybe<SequenceEntryCriteriaOrderByRelationAggregateInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadOrderByRelationAggregateInput>;
  SequenceSteps?: InputMaybe<SequenceStepOrderByRelationAggregateInput>;
  set_goal?: InputMaybe<SortOrder>;
  short_resting?: InputMaybe<SortOrder>;
  show_calendar_options?: InputMaybe<SortOrder>;
  show_contract_duration_sales?: InputMaybe<SortOrder>;
  show_lead_source_to_rep?: InputMaybe<SortOrder>;
  show_nocontact_button?: InputMaybe<SortOrder>;
  show_payment_terms_sales?: InputMaybe<SortOrder>;
  show_rep_all_leads?: InputMaybe<SortOrder>;
  show_rep_my_leads?: InputMaybe<SortOrder>;
  show_system_view?: InputMaybe<SortOrder>;
  sites?: InputMaybe<SiteOrderByRelationAggregateInput>;
  sms_opt_in_dialog?: InputMaybe<SortOrder>;
  SMSItems?: InputMaybe<SmsItemOrderByRelationAggregateInput>;
  SnoozeTrackings?: InputMaybe<SnoozeTrackingOrderByRelationAggregateInput>;
  StepActionLogs?: InputMaybe<StepActionLogOrderByRelationAggregateInput>;
  StepActions?: InputMaybe<StepActionOrderByRelationAggregateInput>;
  StepConditions?: InputMaybe<StepConditionOrderByRelationAggregateInput>;
  teams?: InputMaybe<TeamOrderByRelationAggregateInput>;
  Template?: InputMaybe<TemplateOrderByRelationAggregateInput>;
  TemplateVersions?: InputMaybe<TemplateVersionOrderByRelationAggregateInput>;
  timekit_id?: InputMaybe<SortOrder>;
  timezone?: InputMaybe<SortOrder>;
  transfer_timeout?: InputMaybe<SortOrder>;
  TransferAttempts?: InputMaybe<TransferAttemptOrderByRelationAggregateInput>;
  twilio_numbers?: InputMaybe<TwilioNumberOrderByRelationAggregateInput>;
  TwilioFeedbacks?: InputMaybe<TwilioFeedbackOrderByRelationAggregateInput>;
  TwilioInfo?: InputMaybe<TwilioInfoOrderByWithRelationInput>;
  updated_at?: InputMaybe<SortOrder>;
  use_revenue_planning?: InputMaybe<SortOrder>;
  UserCustomFieldFilters?: InputMaybe<UserCustomFieldFilterOrderByRelationAggregateInput>;
  users?: InputMaybe<UserOrderByRelationAggregateInput>;
  win_rep_day_goal?: InputMaybe<SortOrder>;
};

export type OrganizationPaymentSettings = {
  __typename?: 'OrganizationPaymentSettings';
  contract_duration_options: Array<Scalars['String']['output']>;
  contract_term_options: Array<Scalars['String']['output']>;
  created_at: Scalars['DateTime']['output'];
  created_by?: Maybe<User>;
  created_by_user_id?: Maybe<Scalars['String']['output']>;
  default_contract_duration: Scalars['String']['output'];
  default_term_duration: Scalars['String']['output'];
  enable_contract_duration: Scalars['Boolean']['output'];
  enable_payment_duration: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
  last_updated_by?: Maybe<User>;
  organization: Organization;
  organization_id: Scalars['String']['output'];
  updated_at: Scalars['DateTime']['output'];
};

export type OrganizationPaymentSettingsCreatecontract_Duration_OptionsInput = {
  set?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type OrganizationPaymentSettingsCreatecontract_Term_OptionsInput = {
  set?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type OrganizationPaymentSettingsCreateManycontract_Duration_OptionsInput = {
  set?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type OrganizationPaymentSettingsCreateManycontract_Term_OptionsInput = {
  set?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type OrganizationPaymentSettingsCreateManyCreated_ByInput = {
  contract_duration_options?: InputMaybe<OrganizationPaymentSettingsCreateManycontract_Duration_OptionsInput>;
  contract_term_options?: InputMaybe<OrganizationPaymentSettingsCreateManycontract_Term_OptionsInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  default_contract_duration: Scalars['String']['input'];
  default_term_duration: Scalars['String']['input'];
  enable_contract_duration?: InputMaybe<Scalars['Boolean']['input']>;
  enable_payment_duration?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  organization_id: Scalars['String']['input'];
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  updated_by_user_id?: InputMaybe<Scalars['String']['input']>;
};

export type OrganizationPaymentSettingsCreateManyCreated_ByInputEnvelope = {
  data?: InputMaybe<Array<OrganizationPaymentSettingsCreateManyCreated_ByInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type OrganizationPaymentSettingsCreateManyLast_Updated_ByInput = {
  contract_duration_options?: InputMaybe<OrganizationPaymentSettingsCreateManycontract_Duration_OptionsInput>;
  contract_term_options?: InputMaybe<OrganizationPaymentSettingsCreateManycontract_Term_OptionsInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  created_by_user_id?: InputMaybe<Scalars['String']['input']>;
  default_contract_duration: Scalars['String']['input'];
  default_term_duration: Scalars['String']['input'];
  enable_contract_duration?: InputMaybe<Scalars['Boolean']['input']>;
  enable_payment_duration?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  organization_id: Scalars['String']['input'];
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type OrganizationPaymentSettingsCreateManyLast_Updated_ByInputEnvelope = {
  data?: InputMaybe<Array<OrganizationPaymentSettingsCreateManyLast_Updated_ByInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type OrganizationPaymentSettingsCreateNestedManyWithoutCreated_ByInput = {
  connect?: InputMaybe<Array<OrganizationPaymentSettingsWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<OrganizationPaymentSettingsCreateOrConnectWithoutCreated_ByInput>>;
  create?: InputMaybe<Array<OrganizationPaymentSettingsCreateWithoutCreated_ByInput>>;
  createMany?: InputMaybe<OrganizationPaymentSettingsCreateManyCreated_ByInputEnvelope>;
};

export type OrganizationPaymentSettingsCreateNestedManyWithoutLast_Updated_ByInput = {
  connect?: InputMaybe<Array<OrganizationPaymentSettingsWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<OrganizationPaymentSettingsCreateOrConnectWithoutLast_Updated_ByInput>>;
  create?: InputMaybe<Array<OrganizationPaymentSettingsCreateWithoutLast_Updated_ByInput>>;
  createMany?: InputMaybe<OrganizationPaymentSettingsCreateManyLast_Updated_ByInputEnvelope>;
};

export type OrganizationPaymentSettingsCreateNestedOneWithoutOrganizationInput = {
  connect?: InputMaybe<OrganizationPaymentSettingsWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrganizationPaymentSettingsCreateOrConnectWithoutOrganizationInput>;
  create?: InputMaybe<OrganizationPaymentSettingsCreateWithoutOrganizationInput>;
};

export type OrganizationPaymentSettingsCreateNestedOneWithoutOrganizationsInput = {
  connect?: InputMaybe<OrganizationPaymentSettingsWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrganizationPaymentSettingsCreateOrConnectWithoutOrganizationsInput>;
  create?: InputMaybe<OrganizationPaymentSettingsCreateWithoutOrganizationsInput>;
};

export type OrganizationPaymentSettingsCreateOrConnectWithoutCreated_ByInput = {
  create: OrganizationPaymentSettingsCreateWithoutCreated_ByInput;
  where: OrganizationPaymentSettingsWhereUniqueInput;
};

export type OrganizationPaymentSettingsCreateOrConnectWithoutLast_Updated_ByInput = {
  create: OrganizationPaymentSettingsCreateWithoutLast_Updated_ByInput;
  where: OrganizationPaymentSettingsWhereUniqueInput;
};

export type OrganizationPaymentSettingsCreateOrConnectWithoutOrganizationInput = {
  create: OrganizationPaymentSettingsCreateWithoutOrganizationInput;
  where: OrganizationPaymentSettingsWhereUniqueInput;
};

export type OrganizationPaymentSettingsCreateOrConnectWithoutOrganizationsInput = {
  create: OrganizationPaymentSettingsCreateWithoutOrganizationsInput;
  where: OrganizationPaymentSettingsWhereUniqueInput;
};

export type OrganizationPaymentSettingsCreateWithoutCreated_ByInput = {
  contract_duration_options?: InputMaybe<OrganizationPaymentSettingsCreatecontract_Duration_OptionsInput>;
  contract_term_options?: InputMaybe<OrganizationPaymentSettingsCreatecontract_Term_OptionsInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  default_contract_duration: Scalars['String']['input'];
  default_term_duration: Scalars['String']['input'];
  enable_contract_duration?: InputMaybe<Scalars['Boolean']['input']>;
  enable_payment_duration?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  last_updated_by?: InputMaybe<UserCreateNestedOneWithoutPayment_Settings_UpdatedInput>;
  organization: OrganizationCreateNestedOneWithoutOrganizationPaymentSettingInput;
  organizations?: InputMaybe<OrganizationCreateNestedManyWithoutOrganizationPaymentSettingsInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type OrganizationPaymentSettingsCreateWithoutLast_Updated_ByInput = {
  contract_duration_options?: InputMaybe<OrganizationPaymentSettingsCreatecontract_Duration_OptionsInput>;
  contract_term_options?: InputMaybe<OrganizationPaymentSettingsCreatecontract_Term_OptionsInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  created_by?: InputMaybe<UserCreateNestedOneWithoutPayment_SettingsInput>;
  default_contract_duration: Scalars['String']['input'];
  default_term_duration: Scalars['String']['input'];
  enable_contract_duration?: InputMaybe<Scalars['Boolean']['input']>;
  enable_payment_duration?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  organization: OrganizationCreateNestedOneWithoutOrganizationPaymentSettingInput;
  organizations?: InputMaybe<OrganizationCreateNestedManyWithoutOrganizationPaymentSettingsInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type OrganizationPaymentSettingsCreateWithoutOrganizationInput = {
  contract_duration_options?: InputMaybe<OrganizationPaymentSettingsCreatecontract_Duration_OptionsInput>;
  contract_term_options?: InputMaybe<OrganizationPaymentSettingsCreatecontract_Term_OptionsInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  created_by?: InputMaybe<UserCreateNestedOneWithoutPayment_SettingsInput>;
  default_contract_duration: Scalars['String']['input'];
  default_term_duration: Scalars['String']['input'];
  enable_contract_duration?: InputMaybe<Scalars['Boolean']['input']>;
  enable_payment_duration?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  last_updated_by?: InputMaybe<UserCreateNestedOneWithoutPayment_Settings_UpdatedInput>;
  organizations?: InputMaybe<OrganizationCreateNestedManyWithoutOrganizationPaymentSettingsInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type OrganizationPaymentSettingsCreateWithoutOrganizationsInput = {
  contract_duration_options?: InputMaybe<OrganizationPaymentSettingsCreatecontract_Duration_OptionsInput>;
  contract_term_options?: InputMaybe<OrganizationPaymentSettingsCreatecontract_Term_OptionsInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  created_by?: InputMaybe<UserCreateNestedOneWithoutPayment_SettingsInput>;
  default_contract_duration: Scalars['String']['input'];
  default_term_duration: Scalars['String']['input'];
  enable_contract_duration?: InputMaybe<Scalars['Boolean']['input']>;
  enable_payment_duration?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  last_updated_by?: InputMaybe<UserCreateNestedOneWithoutPayment_Settings_UpdatedInput>;
  organization: OrganizationCreateNestedOneWithoutOrganizationPaymentSettingInput;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type OrganizationPaymentSettingsInput = {
  contract_duration_options: Array<Scalars['String']['input']>;
  contract_term_options: Array<Scalars['String']['input']>;
  default_contract_duration: Scalars['String']['input'];
  default_term_duration: Scalars['String']['input'];
  enable_contract_duration: Scalars['Boolean']['input'];
  enable_payment_duration: Scalars['Boolean']['input'];
};

export type OrganizationPaymentSettingsListRelationFilter = {
  every?: InputMaybe<OrganizationPaymentSettingsWhereInput>;
  none?: InputMaybe<OrganizationPaymentSettingsWhereInput>;
  some?: InputMaybe<OrganizationPaymentSettingsWhereInput>;
};

export type OrganizationPaymentSettingsOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
  count?: InputMaybe<SortOrder>;
};

export type OrganizationPaymentSettingsOrderByWithRelationInput = {
  contract_duration_options?: InputMaybe<SortOrder>;
  contract_term_options?: InputMaybe<SortOrder>;
  created_at?: InputMaybe<SortOrder>;
  created_by?: InputMaybe<UserOrderByWithRelationInput>;
  created_by_user_id?: InputMaybe<SortOrder>;
  default_contract_duration?: InputMaybe<SortOrder>;
  default_term_duration?: InputMaybe<SortOrder>;
  enable_contract_duration?: InputMaybe<SortOrder>;
  enable_payment_duration?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  last_updated_by?: InputMaybe<UserOrderByWithRelationInput>;
  organization?: InputMaybe<OrganizationOrderByWithRelationInput>;
  organization_id?: InputMaybe<SortOrder>;
  organizations?: InputMaybe<OrganizationOrderByRelationAggregateInput>;
  updated_at?: InputMaybe<SortOrder>;
  updated_by_user_id?: InputMaybe<SortOrder>;
};

export type OrganizationPaymentSettingsScalarWhereInput = {
  AND?: InputMaybe<Array<OrganizationPaymentSettingsScalarWhereInput>>;
  contract_duration_options?: InputMaybe<StringNullableListFilter>;
  contract_term_options?: InputMaybe<StringNullableListFilter>;
  created_at?: InputMaybe<DateTimeFilter>;
  created_by_user_id?: InputMaybe<StringNullableFilter>;
  default_contract_duration?: InputMaybe<StringFilter>;
  default_term_duration?: InputMaybe<StringFilter>;
  enable_contract_duration?: InputMaybe<BoolFilter>;
  enable_payment_duration?: InputMaybe<BoolFilter>;
  id?: InputMaybe<StringFilter>;
  NOT?: InputMaybe<Array<OrganizationPaymentSettingsScalarWhereInput>>;
  OR?: InputMaybe<Array<OrganizationPaymentSettingsScalarWhereInput>>;
  organization_id?: InputMaybe<StringFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
  updated_by_user_id?: InputMaybe<StringNullableFilter>;
};

export type OrganizationPaymentSettingsUpdatecontract_Duration_OptionsInput = {
  push?: InputMaybe<Scalars['String']['input']>;
  set?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type OrganizationPaymentSettingsUpdatecontract_Term_OptionsInput = {
  push?: InputMaybe<Scalars['String']['input']>;
  set?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type OrganizationPaymentSettingsUpdateManyMutationInput = {
  contract_duration_options?: InputMaybe<OrganizationPaymentSettingsUpdatecontract_Duration_OptionsInput>;
  contract_term_options?: InputMaybe<OrganizationPaymentSettingsUpdatecontract_Term_OptionsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  default_contract_duration?: InputMaybe<StringFieldUpdateOperationsInput>;
  default_term_duration?: InputMaybe<StringFieldUpdateOperationsInput>;
  enable_contract_duration?: InputMaybe<BoolFieldUpdateOperationsInput>;
  enable_payment_duration?: InputMaybe<BoolFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type OrganizationPaymentSettingsUpdateManyWithoutCreated_ByInput = {
  connect?: InputMaybe<Array<OrganizationPaymentSettingsWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<OrganizationPaymentSettingsCreateOrConnectWithoutCreated_ByInput>>;
  create?: InputMaybe<Array<OrganizationPaymentSettingsCreateWithoutCreated_ByInput>>;
  createMany?: InputMaybe<OrganizationPaymentSettingsCreateManyCreated_ByInputEnvelope>;
  delete?: InputMaybe<Array<OrganizationPaymentSettingsWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<OrganizationPaymentSettingsScalarWhereInput>>;
  disconnect?: InputMaybe<Array<OrganizationPaymentSettingsWhereUniqueInput>>;
  set?: InputMaybe<Array<OrganizationPaymentSettingsWhereUniqueInput>>;
  update?: InputMaybe<Array<OrganizationPaymentSettingsUpdateWithWhereUniqueWithoutCreated_ByInput>>;
  updateMany?: InputMaybe<Array<OrganizationPaymentSettingsUpdateManyWithWhereWithoutCreated_ByInput>>;
  upsert?: InputMaybe<Array<OrganizationPaymentSettingsUpsertWithWhereUniqueWithoutCreated_ByInput>>;
};

export type OrganizationPaymentSettingsUpdateManyWithoutLast_Updated_ByInput = {
  connect?: InputMaybe<Array<OrganizationPaymentSettingsWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<OrganizationPaymentSettingsCreateOrConnectWithoutLast_Updated_ByInput>>;
  create?: InputMaybe<Array<OrganizationPaymentSettingsCreateWithoutLast_Updated_ByInput>>;
  createMany?: InputMaybe<OrganizationPaymentSettingsCreateManyLast_Updated_ByInputEnvelope>;
  delete?: InputMaybe<Array<OrganizationPaymentSettingsWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<OrganizationPaymentSettingsScalarWhereInput>>;
  disconnect?: InputMaybe<Array<OrganizationPaymentSettingsWhereUniqueInput>>;
  set?: InputMaybe<Array<OrganizationPaymentSettingsWhereUniqueInput>>;
  update?: InputMaybe<Array<OrganizationPaymentSettingsUpdateWithWhereUniqueWithoutLast_Updated_ByInput>>;
  updateMany?: InputMaybe<Array<OrganizationPaymentSettingsUpdateManyWithWhereWithoutLast_Updated_ByInput>>;
  upsert?: InputMaybe<Array<OrganizationPaymentSettingsUpsertWithWhereUniqueWithoutLast_Updated_ByInput>>;
};

export type OrganizationPaymentSettingsUpdateManyWithWhereWithoutCreated_ByInput = {
  data: OrganizationPaymentSettingsUpdateManyMutationInput;
  where: OrganizationPaymentSettingsScalarWhereInput;
};

export type OrganizationPaymentSettingsUpdateManyWithWhereWithoutLast_Updated_ByInput = {
  data: OrganizationPaymentSettingsUpdateManyMutationInput;
  where: OrganizationPaymentSettingsScalarWhereInput;
};

export type OrganizationPaymentSettingsUpdateOneWithoutOrganizationInput = {
  connect?: InputMaybe<OrganizationPaymentSettingsWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrganizationPaymentSettingsCreateOrConnectWithoutOrganizationInput>;
  create?: InputMaybe<OrganizationPaymentSettingsCreateWithoutOrganizationInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<OrganizationPaymentSettingsUpdateWithoutOrganizationInput>;
  upsert?: InputMaybe<OrganizationPaymentSettingsUpsertWithoutOrganizationInput>;
};

export type OrganizationPaymentSettingsUpdateOneWithoutOrganizationsInput = {
  connect?: InputMaybe<OrganizationPaymentSettingsWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrganizationPaymentSettingsCreateOrConnectWithoutOrganizationsInput>;
  create?: InputMaybe<OrganizationPaymentSettingsCreateWithoutOrganizationsInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<OrganizationPaymentSettingsUpdateWithoutOrganizationsInput>;
  upsert?: InputMaybe<OrganizationPaymentSettingsUpsertWithoutOrganizationsInput>;
};

export type OrganizationPaymentSettingsUpdateWithoutCreated_ByInput = {
  contract_duration_options?: InputMaybe<OrganizationPaymentSettingsUpdatecontract_Duration_OptionsInput>;
  contract_term_options?: InputMaybe<OrganizationPaymentSettingsUpdatecontract_Term_OptionsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  default_contract_duration?: InputMaybe<StringFieldUpdateOperationsInput>;
  default_term_duration?: InputMaybe<StringFieldUpdateOperationsInput>;
  enable_contract_duration?: InputMaybe<BoolFieldUpdateOperationsInput>;
  enable_payment_duration?: InputMaybe<BoolFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  last_updated_by?: InputMaybe<UserUpdateOneWithoutPayment_Settings_UpdatedInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutOrganizationPaymentSettingInput>;
  organizations?: InputMaybe<OrganizationUpdateManyWithoutOrganizationPaymentSettingsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type OrganizationPaymentSettingsUpdateWithoutLast_Updated_ByInput = {
  contract_duration_options?: InputMaybe<OrganizationPaymentSettingsUpdatecontract_Duration_OptionsInput>;
  contract_term_options?: InputMaybe<OrganizationPaymentSettingsUpdatecontract_Term_OptionsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  created_by?: InputMaybe<UserUpdateOneWithoutPayment_SettingsInput>;
  default_contract_duration?: InputMaybe<StringFieldUpdateOperationsInput>;
  default_term_duration?: InputMaybe<StringFieldUpdateOperationsInput>;
  enable_contract_duration?: InputMaybe<BoolFieldUpdateOperationsInput>;
  enable_payment_duration?: InputMaybe<BoolFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutOrganizationPaymentSettingInput>;
  organizations?: InputMaybe<OrganizationUpdateManyWithoutOrganizationPaymentSettingsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type OrganizationPaymentSettingsUpdateWithoutOrganizationInput = {
  contract_duration_options?: InputMaybe<OrganizationPaymentSettingsUpdatecontract_Duration_OptionsInput>;
  contract_term_options?: InputMaybe<OrganizationPaymentSettingsUpdatecontract_Term_OptionsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  created_by?: InputMaybe<UserUpdateOneWithoutPayment_SettingsInput>;
  default_contract_duration?: InputMaybe<StringFieldUpdateOperationsInput>;
  default_term_duration?: InputMaybe<StringFieldUpdateOperationsInput>;
  enable_contract_duration?: InputMaybe<BoolFieldUpdateOperationsInput>;
  enable_payment_duration?: InputMaybe<BoolFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  last_updated_by?: InputMaybe<UserUpdateOneWithoutPayment_Settings_UpdatedInput>;
  organizations?: InputMaybe<OrganizationUpdateManyWithoutOrganizationPaymentSettingsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type OrganizationPaymentSettingsUpdateWithoutOrganizationsInput = {
  contract_duration_options?: InputMaybe<OrganizationPaymentSettingsUpdatecontract_Duration_OptionsInput>;
  contract_term_options?: InputMaybe<OrganizationPaymentSettingsUpdatecontract_Term_OptionsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  created_by?: InputMaybe<UserUpdateOneWithoutPayment_SettingsInput>;
  default_contract_duration?: InputMaybe<StringFieldUpdateOperationsInput>;
  default_term_duration?: InputMaybe<StringFieldUpdateOperationsInput>;
  enable_contract_duration?: InputMaybe<BoolFieldUpdateOperationsInput>;
  enable_payment_duration?: InputMaybe<BoolFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  last_updated_by?: InputMaybe<UserUpdateOneWithoutPayment_Settings_UpdatedInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutOrganizationPaymentSettingInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type OrganizationPaymentSettingsUpdateWithWhereUniqueWithoutCreated_ByInput = {
  data: OrganizationPaymentSettingsUpdateWithoutCreated_ByInput;
  where: OrganizationPaymentSettingsWhereUniqueInput;
};

export type OrganizationPaymentSettingsUpdateWithWhereUniqueWithoutLast_Updated_ByInput = {
  data: OrganizationPaymentSettingsUpdateWithoutLast_Updated_ByInput;
  where: OrganizationPaymentSettingsWhereUniqueInput;
};

export type OrganizationPaymentSettingsUpsertWithoutOrganizationInput = {
  create: OrganizationPaymentSettingsCreateWithoutOrganizationInput;
  update: OrganizationPaymentSettingsUpdateWithoutOrganizationInput;
};

export type OrganizationPaymentSettingsUpsertWithoutOrganizationsInput = {
  create: OrganizationPaymentSettingsCreateWithoutOrganizationsInput;
  update: OrganizationPaymentSettingsUpdateWithoutOrganizationsInput;
};

export type OrganizationPaymentSettingsUpsertWithWhereUniqueWithoutCreated_ByInput = {
  create: OrganizationPaymentSettingsCreateWithoutCreated_ByInput;
  update: OrganizationPaymentSettingsUpdateWithoutCreated_ByInput;
  where: OrganizationPaymentSettingsWhereUniqueInput;
};

export type OrganizationPaymentSettingsUpsertWithWhereUniqueWithoutLast_Updated_ByInput = {
  create: OrganizationPaymentSettingsCreateWithoutLast_Updated_ByInput;
  update: OrganizationPaymentSettingsUpdateWithoutLast_Updated_ByInput;
  where: OrganizationPaymentSettingsWhereUniqueInput;
};

export type OrganizationPaymentSettingsWhereInput = {
  AND?: InputMaybe<Array<OrganizationPaymentSettingsWhereInput>>;
  contract_duration_options?: InputMaybe<StringNullableListFilter>;
  contract_term_options?: InputMaybe<StringNullableListFilter>;
  created_at?: InputMaybe<DateTimeFilter>;
  created_by?: InputMaybe<UserWhereInput>;
  created_by_user_id?: InputMaybe<StringNullableFilter>;
  default_contract_duration?: InputMaybe<StringFilter>;
  default_term_duration?: InputMaybe<StringFilter>;
  enable_contract_duration?: InputMaybe<BoolFilter>;
  enable_payment_duration?: InputMaybe<BoolFilter>;
  id?: InputMaybe<StringFilter>;
  last_updated_by?: InputMaybe<UserWhereInput>;
  NOT?: InputMaybe<Array<OrganizationPaymentSettingsWhereInput>>;
  OR?: InputMaybe<Array<OrganizationPaymentSettingsWhereInput>>;
  organization?: InputMaybe<OrganizationWhereInput>;
  organization_id?: InputMaybe<StringFilter>;
  organizations?: InputMaybe<OrganizationListRelationFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
  updated_by_user_id?: InputMaybe<StringNullableFilter>;
};

export type OrganizationPaymentSettingsWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  organization_id?: InputMaybe<Scalars['String']['input']>;
};

export type OrganizationScalarWhereInput = {
  allow_calendar_invite?: InputMaybe<BoolFilter>;
  allow_remove_step_action?: InputMaybe<BoolFilter>;
  allow_snooze_step_action?: InputMaybe<BoolFilter>;
  always_record?: InputMaybe<BoolFilter>;
  AND?: InputMaybe<Array<OrganizationScalarWhereInput>>;
  arv_initial_held_demos_generated_by_sdr_weight?: InputMaybe<FloatNullableFilter>;
  auto_call_interval?: InputMaybe<IntFilter>;
  auto_dial?: InputMaybe<BoolFilter>;
  automatic_call_back_delay?: InputMaybe<FloatNullableFilter>;
  best_action_ever_multiplier?: InputMaybe<FloatFilter>;
  can_edit_sdr_transfer_rules?: InputMaybe<BoolFilter>;
  claim_rule?: InputMaybe<IntFilter>;
  conference_region?: InputMaybe<EnumConferenceRegionFilter>;
  created_at?: InputMaybe<DateTimeFilter>;
  day_to_close?: InputMaybe<IntFilter>;
  default_demo_time_duration?: InputMaybe<IntNullableFilter>;
  demo_held_call_back_delay?: InputMaybe<FloatNullableFilter>;
  demo_not_held_call_back_delay?: InputMaybe<FloatNullableFilter>;
  demo_not_held_call_back_short_delay?: InputMaybe<FloatNullableFilter>;
  demo_prep_time_minutes?: InputMaybe<IntFilter>;
  dev_account?: InputMaybe<BoolNullableFilter>;
  dial_rule?: InputMaybe<IntFilter>;
  double_booking?: InputMaybe<BoolFilter>;
  dynamic_date_operators?: InputMaybe<BoolFilter>;
  edge_servers?: InputMaybe<StringNullableListFilter>;
  email_conflict_notification_email_list?: InputMaybe<StringNullableListFilter>;
  enable_modified_metrics?: InputMaybe<BoolNullableFilter>;
  enable_pay_period_view?: InputMaybe<BoolNullableFilter>;
  enable_time_constraint?: InputMaybe<BoolNullableFilter>;
  error_log_email_address?: InputMaybe<StringNullableListFilter>;
  estimated_average_num_of_weekly_overbooked_demo_slots?: InputMaybe<IntNullableFilter>;
  held_demos_per_cc_connect?: InputMaybe<FloatNullableFilter>;
  helpful_links?: InputMaybe<StringNullableListFilter>;
  hold_goal?: InputMaybe<FloatFilter>;
  hubspot_last_sync_date?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<StringFilter>;
  inbound_close_rate?: InputMaybe<FloatNullableFilter>;
  inbound_dm_connect_rate?: InputMaybe<FloatNullableFilter>;
  inbound_lt_hold_rate?: InputMaybe<FloatNullableFilter>;
  inbound_revenue_value_of_sale?: InputMaybe<FloatNullableFilter>;
  inbound_scheduled_hold_rate?: InputMaybe<FloatNullableFilter>;
  inbound_set_rate?: InputMaybe<FloatNullableFilter>;
  initial_sdr_sets_reps_day?: InputMaybe<FloatNullableFilter>;
  international?: InputMaybe<BoolFilter>;
  is_email_domain_verified?: InputMaybe<BoolFilter>;
  is_salesforce_sandbox?: InputMaybe<BoolFilter>;
  last_lead_value_compute_time?: InputMaybe<DateTimeNullableFilter>;
  lead_release_same_rep_delay_rule?: InputMaybe<IntFilter>;
  lead_routing_email_list?: InputMaybe<StringNullableListFilter>;
  made_contact_rule?: InputMaybe<IntFilter>;
  make_sale_address_required?: InputMaybe<BoolFilter>;
  make_sale_business_name_required?: InputMaybe<BoolFilter>;
  make_sale_city_required?: InputMaybe<BoolFilter>;
  make_sale_country_required?: InputMaybe<BoolFilter>;
  make_sale_first_name_required?: InputMaybe<BoolFilter>;
  make_sale_industry_required?: InputMaybe<BoolFilter>;
  make_sale_last_name_required?: InputMaybe<BoolFilter>;
  make_sale_lead_source_required?: InputMaybe<BoolFilter>;
  make_sale_primary_email_required?: InputMaybe<BoolFilter>;
  make_sale_primary_phone_number_required?: InputMaybe<BoolFilter>;
  make_sale_state_required?: InputMaybe<BoolFilter>;
  make_sale_sub_industry_required?: InputMaybe<BoolFilter>;
  make_sale_zip_required?: InputMaybe<BoolFilter>;
  maxium_day_lag_set_to_schedule?: InputMaybe<IntNullableFilter>;
  mrr?: InputMaybe<IntFilter>;
  mrr_label?: InputMaybe<StringNullableFilter>;
  mrr_required_on_sale?: InputMaybe<BoolFilter>;
  name?: InputMaybe<StringNullableFilter>;
  NOT?: InputMaybe<Array<OrganizationScalarWhereInput>>;
  onboardComplete?: InputMaybe<BoolNullableFilter>;
  OR?: InputMaybe<Array<OrganizationScalarWhereInput>>;
  outbound_close_rate?: InputMaybe<FloatNullableFilter>;
  outbound_dm_connect_rate?: InputMaybe<FloatNullableFilter>;
  outbound_lt_hold_rate?: InputMaybe<FloatNullableFilter>;
  outbound_revenue_value_of_sale?: InputMaybe<FloatNullableFilter>;
  outbound_scheduled_hold_rate?: InputMaybe<FloatNullableFilter>;
  outbound_set_rate?: InputMaybe<FloatNullableFilter>;
  participant_jitter_buffer_size?: InputMaybe<EnumJitterBufferSizeFilter>;
  payment_config_id?: InputMaybe<StringNullableFilter>;
  preferred_goal_setting?: InputMaybe<EnumGoalsettingNullableFilter>;
  prepayment?: InputMaybe<BoolFilter>;
  re_cold_call_hour_delay?: InputMaybe<FloatNullableFilter>;
  recordingStatus?: InputMaybe<EnumRecordingStatusFilter>;
  release_lead_resting?: InputMaybe<IntFilter>;
  rep_claim_limit?: InputMaybe<IntFilter>;
  rep_lead_allotment?: InputMaybe<IntNullableFilter>;
  rep_level_call_transfer?: InputMaybe<BoolFilter>;
  reps_can_bulk_unassign?: InputMaybe<BoolFilter>;
  reps_can_view_all_notes?: InputMaybe<BoolFilter>;
  require_sale_notes?: InputMaybe<BoolFilter>;
  resting_after_skipped_CC?: InputMaybe<IntFilter>;
  resting_period?: InputMaybe<IntFilter>;
  resting_period_after_unsuccessful_cold_call?: InputMaybe<FloatNullableFilter>;
  resting_period_after_unsuccessful_demo?: InputMaybe<FloatNullableFilter>;
  sale_config_id?: InputMaybe<StringNullableFilter>;
  sale_notification_email_list?: InputMaybe<StringNullableListFilter>;
  salesforce_domain?: InputMaybe<StringNullableFilter>;
  salesforce_last_sync_date?: InputMaybe<DateTimeNullableFilter>;
  self_sourcing_rule?: InputMaybe<IntFilter>;
  send_error_log_email?: InputMaybe<BoolNullableFilter>;
  send_lead_routing_email?: InputMaybe<BoolFilter>;
  sendgrid_email_domain?: InputMaybe<StringNullableFilter>;
  sendgrid_email_domain_id?: InputMaybe<IntNullableFilter>;
  set_goal?: InputMaybe<FloatFilter>;
  short_resting?: InputMaybe<IntFilter>;
  show_calendar_options?: InputMaybe<BoolFilter>;
  show_contract_duration_sales?: InputMaybe<BoolFilter>;
  show_lead_source_to_rep?: InputMaybe<BoolFilter>;
  show_nocontact_button?: InputMaybe<BoolFilter>;
  show_payment_terms_sales?: InputMaybe<BoolFilter>;
  show_rep_all_leads?: InputMaybe<BoolFilter>;
  show_rep_my_leads?: InputMaybe<BoolFilter>;
  show_system_view?: InputMaybe<BoolFilter>;
  sms_opt_in_dialog?: InputMaybe<StringNullableFilter>;
  timekit_id?: InputMaybe<StringNullableFilter>;
  timezone?: InputMaybe<StringNullableFilter>;
  transfer_timeout?: InputMaybe<IntFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
  use_revenue_planning?: InputMaybe<BoolNullableFilter>;
  win_rep_day_goal?: InputMaybe<FloatFilter>;
};

export type OrganizationUpdateedge_ServersInput = {
  push?: InputMaybe<Scalars['String']['input']>;
  set?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type OrganizationUpdateemail_Conflict_Notification_Email_ListInput = {
  push?: InputMaybe<Scalars['String']['input']>;
  set?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type OrganizationUpdateerror_Log_Email_AddressInput = {
  push?: InputMaybe<Scalars['String']['input']>;
  set?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type OrganizationUpdatehelpful_LinksInput = {
  push?: InputMaybe<Scalars['String']['input']>;
  set?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type OrganizationUpdateInput = {
  ActivityMonitorMultiplier?: InputMaybe<ActivityMonitorMultiplierUpdateManyWithoutOrganizationInput>;
  algoFactor?: InputMaybe<AlgoFactorUpdateManyWithoutOrganizationInput>;
  allow_calendar_invite?: InputMaybe<BoolFieldUpdateOperationsInput>;
  allow_remove_step_action?: InputMaybe<BoolFieldUpdateOperationsInput>;
  allow_snooze_step_action?: InputMaybe<BoolFieldUpdateOperationsInput>;
  always_record?: InputMaybe<BoolFieldUpdateOperationsInput>;
  arv_initial_held_demos_generated_by_sdr_weight?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  AssociationHistories?: InputMaybe<AssociationHistoryUpdateManyWithoutOrganizationInput>;
  auto_call_interval?: InputMaybe<IntFieldUpdateOperationsInput>;
  auto_dial?: InputMaybe<BoolFieldUpdateOperationsInput>;
  automated_campaigns?: InputMaybe<AutomatedCampaignUpdateManyWithoutOrganizationInput>;
  automatic_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  AutoTransferSelections?: InputMaybe<AutoTransferSelectionUpdateManyWithoutOrganizationInput>;
  Availablities?: InputMaybe<AvailabilityUpdateManyWithoutOrganizationInput>;
  AverageOrderValue?: InputMaybe<AverageOrderValueUpdateManyWithoutOrganizationInput>;
  best_action_ever_multiplier?: InputMaybe<FloatFieldUpdateOperationsInput>;
  best_metrics?: InputMaybe<BestMetricRecordUpdateManyWithoutOrganizationInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptUpdateManyWithoutOrganizationInput>;
  callMeNows?: InputMaybe<CallMeNowUpdateManyWithoutOrganizationInput>;
  CallMultiplier?: InputMaybe<CallMultiplierUpdateManyWithoutOrganizationInput>;
  CallSharings?: InputMaybe<CallSharingUpdateManyWithoutOrganizationInput>;
  can_edit_sdr_transfer_rules?: InputMaybe<BoolFieldUpdateOperationsInput>;
  claim_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  concession?: InputMaybe<ConcessionUpdateManyWithoutOrganizationInput>;
  conference_region?: InputMaybe<EnumConferenceRegionFieldUpdateOperationsInput>;
  Conferences?: InputMaybe<ConferenceUpdateManyWithoutOrganizationInput>;
  ContactRequests?: InputMaybe<ContactRequestUpdateManyWithoutOrganizationInput>;
  Contacts?: InputMaybe<ContactUpdateManyWithoutOrganizationInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  custom_fields?: InputMaybe<CustomFieldUpdateManyWithoutOrganizationInput>;
  CustomObject?: InputMaybe<CustomObjectUpdateManyWithoutOrganizationInput>;
  CustomObjectField?: InputMaybe<CustomObjectFieldUpdateManyWithoutOrganizationInput>;
  CustomObjectFieldForeignKey?: InputMaybe<CustomObjectFieldForeignKeyUpdateManyWithoutOrganizationInput>;
  CustomObjectRecord?: InputMaybe<CustomObjectRecordUpdateManyWithoutOrganizationInput>;
  CustomObjectRow?: InputMaybe<CustomObjectRowUpdateManyWithoutOrganizationInput>;
  CustomQueueItems?: InputMaybe<CustomQueueItemUpdateManyWithoutOrganizationInput>;
  DashboardColumnOptions?: InputMaybe<DashboardColumnOptionUpdateManyWithoutOrganizationInput>;
  DashboardLeadFilters?: InputMaybe<DashboardLeadFilterUpdateManyWithoutOrganizationInput>;
  DashboardRepFilters?: InputMaybe<DashboardRepFilterUpdateManyWithoutOrganizationInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewUpdateManyWithoutOrganizationInput>;
  day_to_close?: InputMaybe<IntFieldUpdateOperationsInput>;
  default_demo_time_duration?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  demo_held_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_not_held_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_not_held_call_back_short_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_prep_time_minutes?: InputMaybe<IntFieldUpdateOperationsInput>;
  dev_account?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  dial_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  DispositionMultiplier?: InputMaybe<DispositionMultiplierUpdateManyWithoutOrganizationInput>;
  dispositions?: InputMaybe<DispositionUpdateManyWithoutOrganizationInput>;
  double_booking?: InputMaybe<BoolFieldUpdateOperationsInput>;
  dynamic_date_operators?: InputMaybe<BoolFieldUpdateOperationsInput>;
  edge_servers?: InputMaybe<OrganizationUpdateedge_ServersInput>;
  email_conflict_notification_email_list?: InputMaybe<OrganizationUpdateemail_Conflict_Notification_Email_ListInput>;
  EmailItems?: InputMaybe<EmailItemUpdateManyWithoutOrganizationInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordUpdateManyWithoutOrganizationInput>;
  EmailThreads?: InputMaybe<EmailThreadUpdateManyWithoutOrganizationInput>;
  enable_modified_metrics?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  enable_pay_period_view?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  enable_time_constraint?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  error_log_email_address?: InputMaybe<OrganizationUpdateerror_Log_Email_AddressInput>;
  estimated_average_num_of_weekly_overbooked_demo_slots?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  FolderPermissions?: InputMaybe<FolderPermissionUpdateManyWithoutOrganizationInput>;
  Folders?: InputMaybe<FolderUpdateManyWithoutOrganizationInput>;
  FutureBookingSetting?: InputMaybe<FutureBookingSettingUpdateOneWithoutOrganizationInput>;
  GlobalTransferRule?: InputMaybe<GlobalTransferRuleUpdateManyWithoutOrganizationInput>;
  held_demos_per_cc_connect?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  helpful_links?: InputMaybe<OrganizationUpdatehelpful_LinksInput>;
  HingePointMultiplier?: InputMaybe<HingePointMultiplierUpdateManyWithoutOrganizationInput>;
  hold_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
  hubspot_credential?: InputMaybe<HubSpotCredentialUpdateOneWithoutOrganizationInput>;
  hubspot_last_sync_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  hubspot_white_list?: InputMaybe<HubSpotWhitelistUpdateManyWithoutOrganizationInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inbound_close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_dm_connect_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_lt_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_revenue_value_of_sale?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_scheduled_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_set_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventUpdateManyWithoutOrganizationInput>;
  InboundConciergeWidget?: InputMaybe<InboundConciergeWidgetUpdateManyWithoutOrganizationInput>;
  industry_options?: InputMaybe<IndustryOptionUpdateManyWithoutOrganizationInput>;
  initial_sdr_sets_reps_day?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  integration_error_logs?: InputMaybe<IntegrationErrorLogUpdateManyWithoutOrganizationInput>;
  integration_mappings?: InputMaybe<IntegrationMappingUpdateManyWithoutOrganizationInput>;
  IntegrationMappingCustomObject?: InputMaybe<IntegrationMappingCustomObjectUpdateManyWithoutOrganizationInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogUpdateManyWithoutOrganizationInput>;
  IntegrationReadOnlyObject?: InputMaybe<IntegrationReadOnlyObjectUpdateManyWithoutOrganizationInput>;
  international?: InputMaybe<BoolFieldUpdateOperationsInput>;
  is_email_domain_verified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  is_salesforce_sandbox?: InputMaybe<BoolFieldUpdateOperationsInput>;
  last_lead_value_compute_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutOrganizationInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryUpdateManyWithoutOrganizationInput>;
  lead_intent?: InputMaybe<LeadIntentUpdateManyWithoutOrganizationInput>;
  lead_release_same_rep_delay_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  lead_routing_email_list?: InputMaybe<OrganizationUpdatelead_Routing_Email_ListInput>;
  lead_routing_rules?: InputMaybe<RuleUpdateManyWithoutOrganizationInput>;
  LeadCustomObjectRowAssociation?: InputMaybe<LeadCustomObjectRowAssociationUpdateManyWithoutOrganizationInput>;
  LeadFieldMultiplier?: InputMaybe<LeadFieldMultiplierUpdateManyWithoutOrganizationInput>;
  LeadFieldMultiplierCustom?: InputMaybe<LeadFieldMultiplierCustomUpdateManyWithoutOrganizationInput>;
  leads?: InputMaybe<LeadUpdateManyWithoutOrganizationInput>;
  leadsource_options?: InputMaybe<LeadSourceOptionUpdateManyWithoutOrganizationInput>;
  LeadValue?: InputMaybe<LeadValueUpdateManyWithoutOrganizationInput>;
  LifeTimeValueMultiplier?: InputMaybe<LifeTimeValueMultiplierUpdateManyWithoutOrganizationInput>;
  LiveTransferSetting?: InputMaybe<LiveTransferSettingUpdateOneWithoutOrganizationInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusUpdateManyWithoutOrganizationInput>;
  ltv_multiplier_custom_fields?: InputMaybe<LifeTimeValueMultiplierCustomUpdateManyWithoutOrganizationInput>;
  made_contact_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  make_sale_address_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_business_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_city_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_country_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_first_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_industry_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_last_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_lead_source_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_primary_email_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_primary_phone_number_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_state_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_sub_industry_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_zip_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  maxium_day_lag_set_to_schedule?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  mrr?: InputMaybe<IntFieldUpdateOperationsInput>;
  mrr_label?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  mrr_required_on_sale?: InputMaybe<BoolFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  NoteItems?: InputMaybe<NoteItemUpdateManyWithoutOrganizationInput>;
  Notifications?: InputMaybe<NotificationUpdateManyWithoutOrganizationInput>;
  onboardComplete?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  Opportunity?: InputMaybe<SfOpportunityUpdateManyWithoutOrganizationInput>;
  organization_holidays?: InputMaybe<OrganizationHolidayUpdateManyWithoutOrganizationInput>;
  organization_hours?: InputMaybe<OrganizationHourUpdateManyWithoutOrganizationInput>;
  OrganizationPaymentSetting?: InputMaybe<OrganizationPaymentSettingsUpdateOneWithoutOrganizationInput>;
  OrganizationPaymentSettings?: InputMaybe<OrganizationPaymentSettingsUpdateOneWithoutOrganizationsInput>;
  orgCalendarInviteMetadata?: InputMaybe<OrgCalendarInviteMetadataUpdateManyWithoutOrganizationInput>;
  OrgDefaultPipelinePerecentValue?: InputMaybe<OrgDefaultPipelinePerecentValueUpdateOneWithoutOrganizationInput>;
  OrgHubSpotSync?: InputMaybe<OrgHubSpotSyncUpdateOneWithoutOrganizationInput>;
  OrgNoEventDate?: InputMaybe<OrgNoEventDateUpdateManyWithoutOrganizationInput>;
  OrgSalesforceSync?: InputMaybe<OrgSalesforceSyncUpdateOneWithoutOrganizationInput>;
  OrgTimeslotAvailability?: InputMaybe<OrgTimeslotAvailabilityUpdateManyWithoutOrganizationInput>;
  outbound_close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_dm_connect_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_lt_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_revenue_value_of_sale?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_scheduled_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_set_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  PandadocData?: InputMaybe<PandadocDataUpdateOneWithoutOrganizationInput>;
  PandaDocTemplate?: InputMaybe<PandaDocTemplateUpdateManyWithoutOrganizationInput>;
  participant_jitter_buffer_size?: InputMaybe<EnumJitterBufferSizeFieldUpdateOperationsInput>;
  preferred_goal_setting?: InputMaybe<NullableEnumGoalsettingFieldUpdateOperationsInput>;
  prepayment?: InputMaybe<BoolFieldUpdateOperationsInput>;
  ProductChangeLog?: InputMaybe<ProductChangeLogUpdateManyWithoutOrganizationInput>;
  products?: InputMaybe<ProductUpdateManyWithoutOrganizationInput>;
  re_cold_call_hour_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  recordingStatus?: InputMaybe<EnumRecordingStatusFieldUpdateOperationsInput>;
  release_lead_resting?: InputMaybe<IntFieldUpdateOperationsInput>;
  rep_claim_limit?: InputMaybe<IntFieldUpdateOperationsInput>;
  rep_lead_allotment?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  rep_level_call_transfer?: InputMaybe<BoolFieldUpdateOperationsInput>;
  reps_can_bulk_unassign?: InputMaybe<BoolFieldUpdateOperationsInput>;
  reps_can_view_all_notes?: InputMaybe<BoolFieldUpdateOperationsInput>;
  require_sale_notes?: InputMaybe<BoolFieldUpdateOperationsInput>;
  resting_after_skipped_CC?: InputMaybe<IntFieldUpdateOperationsInput>;
  resting_period?: InputMaybe<IntFieldUpdateOperationsInput>;
  resting_period_after_unsuccessful_cold_call?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  resting_period_after_unsuccessful_demo?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  revenue_plan?: InputMaybe<RevenuePlanItemUpdateManyWithoutOrganizationInput>;
  Routings?: InputMaybe<RoutingUpdateManyWithoutOrganizationInput>;
  sale_field_options?: InputMaybe<SaleFieldOptionUpdateManyWithoutOrganizationInput>;
  sale_notification_email_list?: InputMaybe<OrganizationUpdatesale_Notification_Email_ListInput>;
  SaleConfig?: InputMaybe<SaleConfigUpdateOneWithoutOrganizationInput>;
  SaleConfigPage?: InputMaybe<SaleConfigPageUpdateManyWithoutOrganizationInput>;
  SaleConfigSection?: InputMaybe<SaleConfigSectionUpdateManyWithoutOrganizationInput>;
  SaleCycles?: InputMaybe<SaleCycleUpdateManyWithoutOrganizationInput>;
  SaleItems?: InputMaybe<SaleItemUpdateManyWithoutOrganizationInput>;
  sales?: InputMaybe<SaleUpdateManyWithoutOrganizationInput>;
  salesforce_domain?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  salesforce_last_sync_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  SalesForceColumn?: InputMaybe<SalesForceColumnUpdateManyWithoutOrganizationInput>;
  salesForceData?: InputMaybe<SalesForceDataUpdateOneWithoutOrganizationInput>;
  ScheduleItems?: InputMaybe<ScheduleItemUpdateManyWithoutOrganizationInput>;
  self_sourcing_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  send_error_log_email?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  send_lead_routing_email?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sendgrid_email_domain?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sendgrid_email_domain_id?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  sequence?: InputMaybe<SequenceUpdateManyWithoutOrganzationInput>;
  SequenceCriteriaConditions?: InputMaybe<SequenceCriteriaConditionUpdateManyWithoutOrganizationInput>;
  SequenceDashboardViews?: InputMaybe<SequenceDashboardViewUpdateManyWithoutOrganizationInput>;
  SequenceEntryCriterias?: InputMaybe<SequenceEntryCriteriaUpdateManyWithoutOrganizationInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutOrganizationInput>;
  SequenceSteps?: InputMaybe<SequenceStepUpdateManyWithoutOrganizationInput>;
  set_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
  short_resting?: InputMaybe<IntFieldUpdateOperationsInput>;
  show_calendar_options?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_contract_duration_sales?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_lead_source_to_rep?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_nocontact_button?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_payment_terms_sales?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_rep_all_leads?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_rep_my_leads?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_system_view?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sites?: InputMaybe<SiteUpdateManyWithoutOrganizationInput>;
  sms_opt_in_dialog?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SMSItems?: InputMaybe<SmsItemUpdateManyWithoutOrganizationInput>;
  SnoozeTrackings?: InputMaybe<SnoozeTrackingUpdateManyWithoutOrganizationInput>;
  StepActionLogs?: InputMaybe<StepActionLogUpdateManyWithoutOrganizationInput>;
  StepActions?: InputMaybe<StepActionUpdateManyWithoutOrganizationInput>;
  StepConditions?: InputMaybe<StepConditionUpdateManyWithoutOrganizationInput>;
  teams?: InputMaybe<TeamUpdateManyWithoutOrganizationInput>;
  Template?: InputMaybe<TemplateUpdateManyWithoutOrganizationInput>;
  TemplateVersions?: InputMaybe<TemplateVersionUpdateManyWithoutOrganizationInput>;
  timekit_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timezone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transfer_timeout?: InputMaybe<IntFieldUpdateOperationsInput>;
  TransferAttempts?: InputMaybe<TransferAttemptUpdateManyWithoutOrganizationInput>;
  twilio_numbers?: InputMaybe<TwilioNumberUpdateManyWithoutOrganizationInput>;
  TwilioFeedbacks?: InputMaybe<TwilioFeedbackUpdateManyWithoutOrganizationInput>;
  TwilioInfo?: InputMaybe<TwilioInfoUpdateOneWithoutOrganzationInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  use_revenue_planning?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  UserCustomFieldFilters?: InputMaybe<UserCustomFieldFilterUpdateManyWithoutOrganizationInput>;
  users?: InputMaybe<UserUpdateManyWithoutOrganizationInput>;
  win_rep_day_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
};

export type OrganizationUpdatelead_Routing_Email_ListInput = {
  push?: InputMaybe<Scalars['String']['input']>;
  set?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type OrganizationUpdateManyMutationInput = {
  allow_calendar_invite?: InputMaybe<BoolFieldUpdateOperationsInput>;
  allow_remove_step_action?: InputMaybe<BoolFieldUpdateOperationsInput>;
  allow_snooze_step_action?: InputMaybe<BoolFieldUpdateOperationsInput>;
  always_record?: InputMaybe<BoolFieldUpdateOperationsInput>;
  arv_initial_held_demos_generated_by_sdr_weight?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  auto_call_interval?: InputMaybe<IntFieldUpdateOperationsInput>;
  auto_dial?: InputMaybe<BoolFieldUpdateOperationsInput>;
  automatic_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  best_action_ever_multiplier?: InputMaybe<FloatFieldUpdateOperationsInput>;
  can_edit_sdr_transfer_rules?: InputMaybe<BoolFieldUpdateOperationsInput>;
  claim_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  conference_region?: InputMaybe<EnumConferenceRegionFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  day_to_close?: InputMaybe<IntFieldUpdateOperationsInput>;
  default_demo_time_duration?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  demo_held_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_not_held_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_not_held_call_back_short_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_prep_time_minutes?: InputMaybe<IntFieldUpdateOperationsInput>;
  dev_account?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  dial_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  double_booking?: InputMaybe<BoolFieldUpdateOperationsInput>;
  dynamic_date_operators?: InputMaybe<BoolFieldUpdateOperationsInput>;
  edge_servers?: InputMaybe<OrganizationUpdateedge_ServersInput>;
  email_conflict_notification_email_list?: InputMaybe<OrganizationUpdateemail_Conflict_Notification_Email_ListInput>;
  enable_modified_metrics?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  enable_pay_period_view?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  enable_time_constraint?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  error_log_email_address?: InputMaybe<OrganizationUpdateerror_Log_Email_AddressInput>;
  estimated_average_num_of_weekly_overbooked_demo_slots?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  held_demos_per_cc_connect?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  helpful_links?: InputMaybe<OrganizationUpdatehelpful_LinksInput>;
  hold_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
  hubspot_last_sync_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inbound_close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_dm_connect_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_lt_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_revenue_value_of_sale?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_scheduled_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_set_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  initial_sdr_sets_reps_day?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  international?: InputMaybe<BoolFieldUpdateOperationsInput>;
  is_email_domain_verified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  is_salesforce_sandbox?: InputMaybe<BoolFieldUpdateOperationsInput>;
  last_lead_value_compute_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lead_release_same_rep_delay_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  lead_routing_email_list?: InputMaybe<OrganizationUpdatelead_Routing_Email_ListInput>;
  made_contact_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  make_sale_address_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_business_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_city_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_country_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_first_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_industry_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_last_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_lead_source_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_primary_email_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_primary_phone_number_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_state_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_sub_industry_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_zip_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  maxium_day_lag_set_to_schedule?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  mrr?: InputMaybe<IntFieldUpdateOperationsInput>;
  mrr_label?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  mrr_required_on_sale?: InputMaybe<BoolFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  onboardComplete?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  outbound_close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_dm_connect_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_lt_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_revenue_value_of_sale?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_scheduled_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_set_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  participant_jitter_buffer_size?: InputMaybe<EnumJitterBufferSizeFieldUpdateOperationsInput>;
  preferred_goal_setting?: InputMaybe<NullableEnumGoalsettingFieldUpdateOperationsInput>;
  prepayment?: InputMaybe<BoolFieldUpdateOperationsInput>;
  re_cold_call_hour_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  recordingStatus?: InputMaybe<EnumRecordingStatusFieldUpdateOperationsInput>;
  release_lead_resting?: InputMaybe<IntFieldUpdateOperationsInput>;
  rep_claim_limit?: InputMaybe<IntFieldUpdateOperationsInput>;
  rep_lead_allotment?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  rep_level_call_transfer?: InputMaybe<BoolFieldUpdateOperationsInput>;
  reps_can_bulk_unassign?: InputMaybe<BoolFieldUpdateOperationsInput>;
  reps_can_view_all_notes?: InputMaybe<BoolFieldUpdateOperationsInput>;
  require_sale_notes?: InputMaybe<BoolFieldUpdateOperationsInput>;
  resting_after_skipped_CC?: InputMaybe<IntFieldUpdateOperationsInput>;
  resting_period?: InputMaybe<IntFieldUpdateOperationsInput>;
  resting_period_after_unsuccessful_cold_call?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  resting_period_after_unsuccessful_demo?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  sale_notification_email_list?: InputMaybe<OrganizationUpdatesale_Notification_Email_ListInput>;
  salesforce_domain?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  salesforce_last_sync_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  self_sourcing_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  send_error_log_email?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  send_lead_routing_email?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sendgrid_email_domain?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sendgrid_email_domain_id?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  set_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
  short_resting?: InputMaybe<IntFieldUpdateOperationsInput>;
  show_calendar_options?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_contract_duration_sales?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_lead_source_to_rep?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_nocontact_button?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_payment_terms_sales?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_rep_all_leads?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_rep_my_leads?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_system_view?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sms_opt_in_dialog?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timekit_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timezone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transfer_timeout?: InputMaybe<IntFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  use_revenue_planning?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  win_rep_day_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
};

export type OrganizationUpdateManyWithoutOrganizationPaymentSettingsInput = {
  connect?: InputMaybe<Array<OrganizationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<OrganizationCreateOrConnectWithoutOrganizationPaymentSettingsInput>>;
  create?: InputMaybe<Array<OrganizationCreateWithoutOrganizationPaymentSettingsInput>>;
  createMany?: InputMaybe<OrganizationCreateManyOrganizationPaymentSettingsInputEnvelope>;
  delete?: InputMaybe<Array<OrganizationWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<OrganizationScalarWhereInput>>;
  disconnect?: InputMaybe<Array<OrganizationWhereUniqueInput>>;
  set?: InputMaybe<Array<OrganizationWhereUniqueInput>>;
  update?: InputMaybe<Array<OrganizationUpdateWithWhereUniqueWithoutOrganizationPaymentSettingsInput>>;
  updateMany?: InputMaybe<Array<OrganizationUpdateManyWithWhereWithoutOrganizationPaymentSettingsInput>>;
  upsert?: InputMaybe<Array<OrganizationUpsertWithWhereUniqueWithoutOrganizationPaymentSettingsInput>>;
};

export type OrganizationUpdateManyWithoutSaleConfigInput = {
  connect?: InputMaybe<Array<OrganizationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<OrganizationCreateOrConnectWithoutSaleConfigInput>>;
  create?: InputMaybe<Array<OrganizationCreateWithoutSaleConfigInput>>;
  createMany?: InputMaybe<OrganizationCreateManySaleConfigInputEnvelope>;
  delete?: InputMaybe<Array<OrganizationWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<OrganizationScalarWhereInput>>;
  disconnect?: InputMaybe<Array<OrganizationWhereUniqueInput>>;
  set?: InputMaybe<Array<OrganizationWhereUniqueInput>>;
  update?: InputMaybe<Array<OrganizationUpdateWithWhereUniqueWithoutSaleConfigInput>>;
  updateMany?: InputMaybe<Array<OrganizationUpdateManyWithWhereWithoutSaleConfigInput>>;
  upsert?: InputMaybe<Array<OrganizationUpsertWithWhereUniqueWithoutSaleConfigInput>>;
};

export type OrganizationUpdateManyWithWhereWithoutOrganizationPaymentSettingsInput = {
  data: OrganizationUpdateManyMutationInput;
  where: OrganizationScalarWhereInput;
};

export type OrganizationUpdateManyWithWhereWithoutSaleConfigInput = {
  data: OrganizationUpdateManyMutationInput;
  where: OrganizationScalarWhereInput;
};

export type OrganizationUpdateOneRequiredWithoutBest_MetricsInput = {
  connect?: InputMaybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrganizationCreateOrConnectWithoutBest_MetricsInput>;
  create?: InputMaybe<OrganizationCreateWithoutBest_MetricsInput>;
  update?: InputMaybe<OrganizationUpdateWithoutBest_MetricsInput>;
  upsert?: InputMaybe<OrganizationUpsertWithoutBest_MetricsInput>;
};

export type OrganizationUpdateOneRequiredWithoutCallMeNowAttemptsInput = {
  connect?: InputMaybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrganizationCreateOrConnectWithoutCallMeNowAttemptsInput>;
  create?: InputMaybe<OrganizationCreateWithoutCallMeNowAttemptsInput>;
  update?: InputMaybe<OrganizationUpdateWithoutCallMeNowAttemptsInput>;
  upsert?: InputMaybe<OrganizationUpsertWithoutCallMeNowAttemptsInput>;
};

export type OrganizationUpdateOneRequiredWithoutCallMeNowsInput = {
  connect?: InputMaybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrganizationCreateOrConnectWithoutCallMeNowsInput>;
  create?: InputMaybe<OrganizationCreateWithoutCallMeNowsInput>;
  update?: InputMaybe<OrganizationUpdateWithoutCallMeNowsInput>;
  upsert?: InputMaybe<OrganizationUpsertWithoutCallMeNowsInput>;
};

export type OrganizationUpdateOneRequiredWithoutCustomObjectFieldForeignKeyInput = {
  connect?: InputMaybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrganizationCreateOrConnectWithoutCustomObjectFieldForeignKeyInput>;
  create?: InputMaybe<OrganizationCreateWithoutCustomObjectFieldForeignKeyInput>;
  update?: InputMaybe<OrganizationUpdateWithoutCustomObjectFieldForeignKeyInput>;
  upsert?: InputMaybe<OrganizationUpsertWithoutCustomObjectFieldForeignKeyInput>;
};

export type OrganizationUpdateOneRequiredWithoutCustomObjectFieldInput = {
  connect?: InputMaybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrganizationCreateOrConnectWithoutCustomObjectFieldInput>;
  create?: InputMaybe<OrganizationCreateWithoutCustomObjectFieldInput>;
  update?: InputMaybe<OrganizationUpdateWithoutCustomObjectFieldInput>;
  upsert?: InputMaybe<OrganizationUpsertWithoutCustomObjectFieldInput>;
};

export type OrganizationUpdateOneRequiredWithoutCustomObjectInput = {
  connect?: InputMaybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrganizationCreateOrConnectWithoutCustomObjectInput>;
  create?: InputMaybe<OrganizationCreateWithoutCustomObjectInput>;
  update?: InputMaybe<OrganizationUpdateWithoutCustomObjectInput>;
  upsert?: InputMaybe<OrganizationUpsertWithoutCustomObjectInput>;
};

export type OrganizationUpdateOneRequiredWithoutCustomObjectRecordInput = {
  connect?: InputMaybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrganizationCreateOrConnectWithoutCustomObjectRecordInput>;
  create?: InputMaybe<OrganizationCreateWithoutCustomObjectRecordInput>;
  update?: InputMaybe<OrganizationUpdateWithoutCustomObjectRecordInput>;
  upsert?: InputMaybe<OrganizationUpsertWithoutCustomObjectRecordInput>;
};

export type OrganizationUpdateOneRequiredWithoutCustomObjectRowInput = {
  connect?: InputMaybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrganizationCreateOrConnectWithoutCustomObjectRowInput>;
  create?: InputMaybe<OrganizationCreateWithoutCustomObjectRowInput>;
  update?: InputMaybe<OrganizationUpdateWithoutCustomObjectRowInput>;
  upsert?: InputMaybe<OrganizationUpsertWithoutCustomObjectRowInput>;
};

export type OrganizationUpdateOneRequiredWithoutDispositionMultiplierInput = {
  connect?: InputMaybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrganizationCreateOrConnectWithoutDispositionMultiplierInput>;
  create?: InputMaybe<OrganizationCreateWithoutDispositionMultiplierInput>;
  update?: InputMaybe<OrganizationUpdateWithoutDispositionMultiplierInput>;
  upsert?: InputMaybe<OrganizationUpsertWithoutDispositionMultiplierInput>;
};

export type OrganizationUpdateOneRequiredWithoutEmailSyncRecordInput = {
  connect?: InputMaybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrganizationCreateOrConnectWithoutEmailSyncRecordInput>;
  create?: InputMaybe<OrganizationCreateWithoutEmailSyncRecordInput>;
  update?: InputMaybe<OrganizationUpdateWithoutEmailSyncRecordInput>;
  upsert?: InputMaybe<OrganizationUpsertWithoutEmailSyncRecordInput>;
};

export type OrganizationUpdateOneRequiredWithoutGlobalTransferRuleInput = {
  connect?: InputMaybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrganizationCreateOrConnectWithoutGlobalTransferRuleInput>;
  create?: InputMaybe<OrganizationCreateWithoutGlobalTransferRuleInput>;
  update?: InputMaybe<OrganizationUpdateWithoutGlobalTransferRuleInput>;
  upsert?: InputMaybe<OrganizationUpsertWithoutGlobalTransferRuleInput>;
};

export type OrganizationUpdateOneRequiredWithoutHingePointMultiplierInput = {
  connect?: InputMaybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrganizationCreateOrConnectWithoutHingePointMultiplierInput>;
  create?: InputMaybe<OrganizationCreateWithoutHingePointMultiplierInput>;
  update?: InputMaybe<OrganizationUpdateWithoutHingePointMultiplierInput>;
  upsert?: InputMaybe<OrganizationUpsertWithoutHingePointMultiplierInput>;
};

export type OrganizationUpdateOneRequiredWithoutInboundConciergeEventsInput = {
  connect?: InputMaybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrganizationCreateOrConnectWithoutInboundConciergeEventsInput>;
  create?: InputMaybe<OrganizationCreateWithoutInboundConciergeEventsInput>;
  update?: InputMaybe<OrganizationUpdateWithoutInboundConciergeEventsInput>;
  upsert?: InputMaybe<OrganizationUpsertWithoutInboundConciergeEventsInput>;
};

export type OrganizationUpdateOneRequiredWithoutIntegration_Error_LogsInput = {
  connect?: InputMaybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrganizationCreateOrConnectWithoutIntegration_Error_LogsInput>;
  create?: InputMaybe<OrganizationCreateWithoutIntegration_Error_LogsInput>;
  update?: InputMaybe<OrganizationUpdateWithoutIntegration_Error_LogsInput>;
  upsert?: InputMaybe<OrganizationUpsertWithoutIntegration_Error_LogsInput>;
};

export type OrganizationUpdateOneRequiredWithoutIntegrationOperationLogInput = {
  connect?: InputMaybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrganizationCreateOrConnectWithoutIntegrationOperationLogInput>;
  create?: InputMaybe<OrganizationCreateWithoutIntegrationOperationLogInput>;
  update?: InputMaybe<OrganizationUpdateWithoutIntegrationOperationLogInput>;
  upsert?: InputMaybe<OrganizationUpsertWithoutIntegrationOperationLogInput>;
};

export type OrganizationUpdateOneRequiredWithoutLead_IntentInput = {
  connect?: InputMaybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrganizationCreateOrConnectWithoutLead_IntentInput>;
  create?: InputMaybe<OrganizationCreateWithoutLead_IntentInput>;
  update?: InputMaybe<OrganizationUpdateWithoutLead_IntentInput>;
  upsert?: InputMaybe<OrganizationUpsertWithoutLead_IntentInput>;
};

export type OrganizationUpdateOneRequiredWithoutLead_Routing_RulesInput = {
  connect?: InputMaybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrganizationCreateOrConnectWithoutLead_Routing_RulesInput>;
  create?: InputMaybe<OrganizationCreateWithoutLead_Routing_RulesInput>;
  update?: InputMaybe<OrganizationUpdateWithoutLead_Routing_RulesInput>;
  upsert?: InputMaybe<OrganizationUpsertWithoutLead_Routing_RulesInput>;
};

export type OrganizationUpdateOneRequiredWithoutLeadCustomObjectRowAssociationInput = {
  connect?: InputMaybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrganizationCreateOrConnectWithoutLeadCustomObjectRowAssociationInput>;
  create?: InputMaybe<OrganizationCreateWithoutLeadCustomObjectRowAssociationInput>;
  update?: InputMaybe<OrganizationUpdateWithoutLeadCustomObjectRowAssociationInput>;
  upsert?: InputMaybe<OrganizationUpsertWithoutLeadCustomObjectRowAssociationInput>;
};

export type OrganizationUpdateOneRequiredWithoutLeadFieldMultiplierCustomInput = {
  connect?: InputMaybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrganizationCreateOrConnectWithoutLeadFieldMultiplierCustomInput>;
  create?: InputMaybe<OrganizationCreateWithoutLeadFieldMultiplierCustomInput>;
  update?: InputMaybe<OrganizationUpdateWithoutLeadFieldMultiplierCustomInput>;
  upsert?: InputMaybe<OrganizationUpsertWithoutLeadFieldMultiplierCustomInput>;
};

export type OrganizationUpdateOneRequiredWithoutLeadFieldMultiplierInput = {
  connect?: InputMaybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrganizationCreateOrConnectWithoutLeadFieldMultiplierInput>;
  create?: InputMaybe<OrganizationCreateWithoutLeadFieldMultiplierInput>;
  update?: InputMaybe<OrganizationUpdateWithoutLeadFieldMultiplierInput>;
  upsert?: InputMaybe<OrganizationUpsertWithoutLeadFieldMultiplierInput>;
};

export type OrganizationUpdateOneRequiredWithoutLeadsInput = {
  connect?: InputMaybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrganizationCreateOrConnectWithoutLeadsInput>;
  create?: InputMaybe<OrganizationCreateWithoutLeadsInput>;
  update?: InputMaybe<OrganizationUpdateWithoutLeadsInput>;
  upsert?: InputMaybe<OrganizationUpsertWithoutLeadsInput>;
};

export type OrganizationUpdateOneRequiredWithoutLeadValueInput = {
  connect?: InputMaybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrganizationCreateOrConnectWithoutLeadValueInput>;
  create?: InputMaybe<OrganizationCreateWithoutLeadValueInput>;
  update?: InputMaybe<OrganizationUpdateWithoutLeadValueInput>;
  upsert?: InputMaybe<OrganizationUpsertWithoutLeadValueInput>;
};

export type OrganizationUpdateOneRequiredWithoutLiveUserStatusInput = {
  connect?: InputMaybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrganizationCreateOrConnectWithoutLiveUserStatusInput>;
  create?: InputMaybe<OrganizationCreateWithoutLiveUserStatusInput>;
  update?: InputMaybe<OrganizationUpdateWithoutLiveUserStatusInput>;
  upsert?: InputMaybe<OrganizationUpsertWithoutLiveUserStatusInput>;
};

export type OrganizationUpdateOneRequiredWithoutOrganizationPaymentSettingInput = {
  connect?: InputMaybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrganizationCreateOrConnectWithoutOrganizationPaymentSettingInput>;
  create?: InputMaybe<OrganizationCreateWithoutOrganizationPaymentSettingInput>;
  update?: InputMaybe<OrganizationUpdateWithoutOrganizationPaymentSettingInput>;
  upsert?: InputMaybe<OrganizationUpsertWithoutOrganizationPaymentSettingInput>;
};

export type OrganizationUpdateOneRequiredWithoutProductChangeLogInput = {
  connect?: InputMaybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrganizationCreateOrConnectWithoutProductChangeLogInput>;
  create?: InputMaybe<OrganizationCreateWithoutProductChangeLogInput>;
  update?: InputMaybe<OrganizationUpdateWithoutProductChangeLogInput>;
  upsert?: InputMaybe<OrganizationUpsertWithoutProductChangeLogInput>;
};

export type OrganizationUpdateOneRequiredWithoutProductsInput = {
  connect?: InputMaybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrganizationCreateOrConnectWithoutProductsInput>;
  create?: InputMaybe<OrganizationCreateWithoutProductsInput>;
  update?: InputMaybe<OrganizationUpdateWithoutProductsInput>;
  upsert?: InputMaybe<OrganizationUpsertWithoutProductsInput>;
};

export type OrganizationUpdateOneRequiredWithoutSaleConfigPageInput = {
  connect?: InputMaybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrganizationCreateOrConnectWithoutSaleConfigPageInput>;
  create?: InputMaybe<OrganizationCreateWithoutSaleConfigPageInput>;
  update?: InputMaybe<OrganizationUpdateWithoutSaleConfigPageInput>;
  upsert?: InputMaybe<OrganizationUpsertWithoutSaleConfigPageInput>;
};

export type OrganizationUpdateOneRequiredWithoutSaleConfigSectionInput = {
  connect?: InputMaybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrganizationCreateOrConnectWithoutSaleConfigSectionInput>;
  create?: InputMaybe<OrganizationCreateWithoutSaleConfigSectionInput>;
  update?: InputMaybe<OrganizationUpdateWithoutSaleConfigSectionInput>;
  upsert?: InputMaybe<OrganizationUpsertWithoutSaleConfigSectionInput>;
};

export type OrganizationUpdateOneRequiredWithoutSequenceEntryExitLeadInput = {
  connect?: InputMaybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrganizationCreateOrConnectWithoutSequenceEntryExitLeadInput>;
  create?: InputMaybe<OrganizationCreateWithoutSequenceEntryExitLeadInput>;
  update?: InputMaybe<OrganizationUpdateWithoutSequenceEntryExitLeadInput>;
  upsert?: InputMaybe<OrganizationUpsertWithoutSequenceEntryExitLeadInput>;
};

export type OrganizationUpdateOneRequiredWithoutSitesInput = {
  connect?: InputMaybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrganizationCreateOrConnectWithoutSitesInput>;
  create?: InputMaybe<OrganizationCreateWithoutSitesInput>;
  update?: InputMaybe<OrganizationUpdateWithoutSitesInput>;
  upsert?: InputMaybe<OrganizationUpsertWithoutSitesInput>;
};

export type OrganizationUpdateOneRequiredWithoutTeamsInput = {
  connect?: InputMaybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrganizationCreateOrConnectWithoutTeamsInput>;
  create?: InputMaybe<OrganizationCreateWithoutTeamsInput>;
  update?: InputMaybe<OrganizationUpdateWithoutTeamsInput>;
  upsert?: InputMaybe<OrganizationUpsertWithoutTeamsInput>;
};

export type OrganizationUpdateOneRequiredWithoutTemplateInput = {
  connect?: InputMaybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrganizationCreateOrConnectWithoutTemplateInput>;
  create?: InputMaybe<OrganizationCreateWithoutTemplateInput>;
  update?: InputMaybe<OrganizationUpdateWithoutTemplateInput>;
  upsert?: InputMaybe<OrganizationUpsertWithoutTemplateInput>;
};

export type OrganizationUpdateOneRequiredWithoutUsersInput = {
  connect?: InputMaybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrganizationCreateOrConnectWithoutUsersInput>;
  create?: InputMaybe<OrganizationCreateWithoutUsersInput>;
  update?: InputMaybe<OrganizationUpdateWithoutUsersInput>;
  upsert?: InputMaybe<OrganizationUpsertWithoutUsersInput>;
};

export type OrganizationUpdateOneWithoutAssociationHistoriesInput = {
  connect?: InputMaybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrganizationCreateOrConnectWithoutAssociationHistoriesInput>;
  create?: InputMaybe<OrganizationCreateWithoutAssociationHistoriesInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<OrganizationUpdateWithoutAssociationHistoriesInput>;
  upsert?: InputMaybe<OrganizationUpsertWithoutAssociationHistoriesInput>;
};

export type OrganizationUpdateOneWithoutAutomated_CampaignsInput = {
  connect?: InputMaybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrganizationCreateOrConnectWithoutAutomated_CampaignsInput>;
  create?: InputMaybe<OrganizationCreateWithoutAutomated_CampaignsInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<OrganizationUpdateWithoutAutomated_CampaignsInput>;
  upsert?: InputMaybe<OrganizationUpsertWithoutAutomated_CampaignsInput>;
};

export type OrganizationUpdateOneWithoutAutoTransferSelectionsInput = {
  connect?: InputMaybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrganizationCreateOrConnectWithoutAutoTransferSelectionsInput>;
  create?: InputMaybe<OrganizationCreateWithoutAutoTransferSelectionsInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<OrganizationUpdateWithoutAutoTransferSelectionsInput>;
  upsert?: InputMaybe<OrganizationUpsertWithoutAutoTransferSelectionsInput>;
};

export type OrganizationUpdateOneWithoutAvailablitiesInput = {
  connect?: InputMaybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrganizationCreateOrConnectWithoutAvailablitiesInput>;
  create?: InputMaybe<OrganizationCreateWithoutAvailablitiesInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<OrganizationUpdateWithoutAvailablitiesInput>;
  upsert?: InputMaybe<OrganizationUpsertWithoutAvailablitiesInput>;
};

export type OrganizationUpdateOneWithoutCallSharingsInput = {
  connect?: InputMaybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrganizationCreateOrConnectWithoutCallSharingsInput>;
  create?: InputMaybe<OrganizationCreateWithoutCallSharingsInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<OrganizationUpdateWithoutCallSharingsInput>;
  upsert?: InputMaybe<OrganizationUpsertWithoutCallSharingsInput>;
};

export type OrganizationUpdateOneWithoutConcessionInput = {
  connect?: InputMaybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrganizationCreateOrConnectWithoutConcessionInput>;
  create?: InputMaybe<OrganizationCreateWithoutConcessionInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<OrganizationUpdateWithoutConcessionInput>;
  upsert?: InputMaybe<OrganizationUpsertWithoutConcessionInput>;
};

export type OrganizationUpdateOneWithoutConferencesInput = {
  connect?: InputMaybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrganizationCreateOrConnectWithoutConferencesInput>;
  create?: InputMaybe<OrganizationCreateWithoutConferencesInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<OrganizationUpdateWithoutConferencesInput>;
  upsert?: InputMaybe<OrganizationUpsertWithoutConferencesInput>;
};

export type OrganizationUpdateOneWithoutContactRequestsInput = {
  connect?: InputMaybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrganizationCreateOrConnectWithoutContactRequestsInput>;
  create?: InputMaybe<OrganizationCreateWithoutContactRequestsInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<OrganizationUpdateWithoutContactRequestsInput>;
  upsert?: InputMaybe<OrganizationUpsertWithoutContactRequestsInput>;
};

export type OrganizationUpdateOneWithoutContactsInput = {
  connect?: InputMaybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrganizationCreateOrConnectWithoutContactsInput>;
  create?: InputMaybe<OrganizationCreateWithoutContactsInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<OrganizationUpdateWithoutContactsInput>;
  upsert?: InputMaybe<OrganizationUpsertWithoutContactsInput>;
};

export type OrganizationUpdateOneWithoutCustom_FieldsInput = {
  connect?: InputMaybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrganizationCreateOrConnectWithoutCustom_FieldsInput>;
  create?: InputMaybe<OrganizationCreateWithoutCustom_FieldsInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<OrganizationUpdateWithoutCustom_FieldsInput>;
  upsert?: InputMaybe<OrganizationUpsertWithoutCustom_FieldsInput>;
};

export type OrganizationUpdateOneWithoutCustomQueueItemsInput = {
  connect?: InputMaybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrganizationCreateOrConnectWithoutCustomQueueItemsInput>;
  create?: InputMaybe<OrganizationCreateWithoutCustomQueueItemsInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<OrganizationUpdateWithoutCustomQueueItemsInput>;
  upsert?: InputMaybe<OrganizationUpsertWithoutCustomQueueItemsInput>;
};

export type OrganizationUpdateOneWithoutDashboardColumnOptionsInput = {
  connect?: InputMaybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrganizationCreateOrConnectWithoutDashboardColumnOptionsInput>;
  create?: InputMaybe<OrganizationCreateWithoutDashboardColumnOptionsInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<OrganizationUpdateWithoutDashboardColumnOptionsInput>;
  upsert?: InputMaybe<OrganizationUpsertWithoutDashboardColumnOptionsInput>;
};

export type OrganizationUpdateOneWithoutDashboardLeadFiltersInput = {
  connect?: InputMaybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrganizationCreateOrConnectWithoutDashboardLeadFiltersInput>;
  create?: InputMaybe<OrganizationCreateWithoutDashboardLeadFiltersInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<OrganizationUpdateWithoutDashboardLeadFiltersInput>;
  upsert?: InputMaybe<OrganizationUpsertWithoutDashboardLeadFiltersInput>;
};

export type OrganizationUpdateOneWithoutDashboardRepFiltersInput = {
  connect?: InputMaybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrganizationCreateOrConnectWithoutDashboardRepFiltersInput>;
  create?: InputMaybe<OrganizationCreateWithoutDashboardRepFiltersInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<OrganizationUpdateWithoutDashboardRepFiltersInput>;
  upsert?: InputMaybe<OrganizationUpsertWithoutDashboardRepFiltersInput>;
};

export type OrganizationUpdateOneWithoutDashboardSavedViewInput = {
  connect?: InputMaybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrganizationCreateOrConnectWithoutDashboardSavedViewInput>;
  create?: InputMaybe<OrganizationCreateWithoutDashboardSavedViewInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<OrganizationUpdateWithoutDashboardSavedViewInput>;
  upsert?: InputMaybe<OrganizationUpsertWithoutDashboardSavedViewInput>;
};

export type OrganizationUpdateOneWithoutDispositionsInput = {
  connect?: InputMaybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrganizationCreateOrConnectWithoutDispositionsInput>;
  create?: InputMaybe<OrganizationCreateWithoutDispositionsInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<OrganizationUpdateWithoutDispositionsInput>;
  upsert?: InputMaybe<OrganizationUpsertWithoutDispositionsInput>;
};

export type OrganizationUpdateOneWithoutEmailItemsInput = {
  connect?: InputMaybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrganizationCreateOrConnectWithoutEmailItemsInput>;
  create?: InputMaybe<OrganizationCreateWithoutEmailItemsInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<OrganizationUpdateWithoutEmailItemsInput>;
  upsert?: InputMaybe<OrganizationUpsertWithoutEmailItemsInput>;
};

export type OrganizationUpdateOneWithoutEmailThreadsInput = {
  connect?: InputMaybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrganizationCreateOrConnectWithoutEmailThreadsInput>;
  create?: InputMaybe<OrganizationCreateWithoutEmailThreadsInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<OrganizationUpdateWithoutEmailThreadsInput>;
  upsert?: InputMaybe<OrganizationUpsertWithoutEmailThreadsInput>;
};

export type OrganizationUpdateOneWithoutFolderPermissionsInput = {
  connect?: InputMaybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrganizationCreateOrConnectWithoutFolderPermissionsInput>;
  create?: InputMaybe<OrganizationCreateWithoutFolderPermissionsInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<OrganizationUpdateWithoutFolderPermissionsInput>;
  upsert?: InputMaybe<OrganizationUpsertWithoutFolderPermissionsInput>;
};

export type OrganizationUpdateOneWithoutFoldersInput = {
  connect?: InputMaybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrganizationCreateOrConnectWithoutFoldersInput>;
  create?: InputMaybe<OrganizationCreateWithoutFoldersInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<OrganizationUpdateWithoutFoldersInput>;
  upsert?: InputMaybe<OrganizationUpsertWithoutFoldersInput>;
};

export type OrganizationUpdateOneWithoutLead_ActivitiesInput = {
  connect?: InputMaybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrganizationCreateOrConnectWithoutLead_ActivitiesInput>;
  create?: InputMaybe<OrganizationCreateWithoutLead_ActivitiesInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<OrganizationUpdateWithoutLead_ActivitiesInput>;
  upsert?: InputMaybe<OrganizationUpsertWithoutLead_ActivitiesInput>;
};

export type OrganizationUpdateOneWithoutLead_Import_HistoryInput = {
  connect?: InputMaybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrganizationCreateOrConnectWithoutLead_Import_HistoryInput>;
  create?: InputMaybe<OrganizationCreateWithoutLead_Import_HistoryInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<OrganizationUpdateWithoutLead_Import_HistoryInput>;
  upsert?: InputMaybe<OrganizationUpsertWithoutLead_Import_HistoryInput>;
};

export type OrganizationUpdateOneWithoutNoteItemsInput = {
  connect?: InputMaybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrganizationCreateOrConnectWithoutNoteItemsInput>;
  create?: InputMaybe<OrganizationCreateWithoutNoteItemsInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<OrganizationUpdateWithoutNoteItemsInput>;
  upsert?: InputMaybe<OrganizationUpsertWithoutNoteItemsInput>;
};

export type OrganizationUpdateOneWithoutNotificationsInput = {
  connect?: InputMaybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrganizationCreateOrConnectWithoutNotificationsInput>;
  create?: InputMaybe<OrganizationCreateWithoutNotificationsInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<OrganizationUpdateWithoutNotificationsInput>;
  upsert?: InputMaybe<OrganizationUpsertWithoutNotificationsInput>;
};

export type OrganizationUpdateOneWithoutRoutingsInput = {
  connect?: InputMaybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrganizationCreateOrConnectWithoutRoutingsInput>;
  create?: InputMaybe<OrganizationCreateWithoutRoutingsInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<OrganizationUpdateWithoutRoutingsInput>;
  upsert?: InputMaybe<OrganizationUpsertWithoutRoutingsInput>;
};

export type OrganizationUpdateOneWithoutSaleCyclesInput = {
  connect?: InputMaybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrganizationCreateOrConnectWithoutSaleCyclesInput>;
  create?: InputMaybe<OrganizationCreateWithoutSaleCyclesInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<OrganizationUpdateWithoutSaleCyclesInput>;
  upsert?: InputMaybe<OrganizationUpsertWithoutSaleCyclesInput>;
};

export type OrganizationUpdateOneWithoutSaleItemsInput = {
  connect?: InputMaybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrganizationCreateOrConnectWithoutSaleItemsInput>;
  create?: InputMaybe<OrganizationCreateWithoutSaleItemsInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<OrganizationUpdateWithoutSaleItemsInput>;
  upsert?: InputMaybe<OrganizationUpsertWithoutSaleItemsInput>;
};

export type OrganizationUpdateOneWithoutSalesInput = {
  connect?: InputMaybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrganizationCreateOrConnectWithoutSalesInput>;
  create?: InputMaybe<OrganizationCreateWithoutSalesInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<OrganizationUpdateWithoutSalesInput>;
  upsert?: InputMaybe<OrganizationUpsertWithoutSalesInput>;
};

export type OrganizationUpdateOneWithoutScheduleItemsInput = {
  connect?: InputMaybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrganizationCreateOrConnectWithoutScheduleItemsInput>;
  create?: InputMaybe<OrganizationCreateWithoutScheduleItemsInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<OrganizationUpdateWithoutScheduleItemsInput>;
  upsert?: InputMaybe<OrganizationUpsertWithoutScheduleItemsInput>;
};

export type OrganizationUpdateOneWithoutSequenceCriteriaConditionsInput = {
  connect?: InputMaybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrganizationCreateOrConnectWithoutSequenceCriteriaConditionsInput>;
  create?: InputMaybe<OrganizationCreateWithoutSequenceCriteriaConditionsInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<OrganizationUpdateWithoutSequenceCriteriaConditionsInput>;
  upsert?: InputMaybe<OrganizationUpsertWithoutSequenceCriteriaConditionsInput>;
};

export type OrganizationUpdateOneWithoutSequenceDashboardViewsInput = {
  connect?: InputMaybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrganizationCreateOrConnectWithoutSequenceDashboardViewsInput>;
  create?: InputMaybe<OrganizationCreateWithoutSequenceDashboardViewsInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<OrganizationUpdateWithoutSequenceDashboardViewsInput>;
  upsert?: InputMaybe<OrganizationUpsertWithoutSequenceDashboardViewsInput>;
};

export type OrganizationUpdateOneWithoutSequenceEntryCriteriasInput = {
  connect?: InputMaybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrganizationCreateOrConnectWithoutSequenceEntryCriteriasInput>;
  create?: InputMaybe<OrganizationCreateWithoutSequenceEntryCriteriasInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<OrganizationUpdateWithoutSequenceEntryCriteriasInput>;
  upsert?: InputMaybe<OrganizationUpsertWithoutSequenceEntryCriteriasInput>;
};

export type OrganizationUpdateOneWithoutSequenceInput = {
  connect?: InputMaybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrganizationCreateOrConnectWithoutSequenceInput>;
  create?: InputMaybe<OrganizationCreateWithoutSequenceInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<OrganizationUpdateWithoutSequenceInput>;
  upsert?: InputMaybe<OrganizationUpsertWithoutSequenceInput>;
};

export type OrganizationUpdateOneWithoutSequenceStepsInput = {
  connect?: InputMaybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrganizationCreateOrConnectWithoutSequenceStepsInput>;
  create?: InputMaybe<OrganizationCreateWithoutSequenceStepsInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<OrganizationUpdateWithoutSequenceStepsInput>;
  upsert?: InputMaybe<OrganizationUpsertWithoutSequenceStepsInput>;
};

export type OrganizationUpdateOneWithoutSmsItemsInput = {
  connect?: InputMaybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrganizationCreateOrConnectWithoutSmsItemsInput>;
  create?: InputMaybe<OrganizationCreateWithoutSmsItemsInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<OrganizationUpdateWithoutSmsItemsInput>;
  upsert?: InputMaybe<OrganizationUpsertWithoutSmsItemsInput>;
};

export type OrganizationUpdateOneWithoutSnoozeTrackingsInput = {
  connect?: InputMaybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrganizationCreateOrConnectWithoutSnoozeTrackingsInput>;
  create?: InputMaybe<OrganizationCreateWithoutSnoozeTrackingsInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<OrganizationUpdateWithoutSnoozeTrackingsInput>;
  upsert?: InputMaybe<OrganizationUpsertWithoutSnoozeTrackingsInput>;
};

export type OrganizationUpdateOneWithoutStepActionLogsInput = {
  connect?: InputMaybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrganizationCreateOrConnectWithoutStepActionLogsInput>;
  create?: InputMaybe<OrganizationCreateWithoutStepActionLogsInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<OrganizationUpdateWithoutStepActionLogsInput>;
  upsert?: InputMaybe<OrganizationUpsertWithoutStepActionLogsInput>;
};

export type OrganizationUpdateOneWithoutStepActionsInput = {
  connect?: InputMaybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrganizationCreateOrConnectWithoutStepActionsInput>;
  create?: InputMaybe<OrganizationCreateWithoutStepActionsInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<OrganizationUpdateWithoutStepActionsInput>;
  upsert?: InputMaybe<OrganizationUpsertWithoutStepActionsInput>;
};

export type OrganizationUpdateOneWithoutStepConditionsInput = {
  connect?: InputMaybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrganizationCreateOrConnectWithoutStepConditionsInput>;
  create?: InputMaybe<OrganizationCreateWithoutStepConditionsInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<OrganizationUpdateWithoutStepConditionsInput>;
  upsert?: InputMaybe<OrganizationUpsertWithoutStepConditionsInput>;
};

export type OrganizationUpdateOneWithoutTemplateVersionsInput = {
  connect?: InputMaybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrganizationCreateOrConnectWithoutTemplateVersionsInput>;
  create?: InputMaybe<OrganizationCreateWithoutTemplateVersionsInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<OrganizationUpdateWithoutTemplateVersionsInput>;
  upsert?: InputMaybe<OrganizationUpsertWithoutTemplateVersionsInput>;
};

export type OrganizationUpdateOneWithoutTransferAttemptsInput = {
  connect?: InputMaybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrganizationCreateOrConnectWithoutTransferAttemptsInput>;
  create?: InputMaybe<OrganizationCreateWithoutTransferAttemptsInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<OrganizationUpdateWithoutTransferAttemptsInput>;
  upsert?: InputMaybe<OrganizationUpsertWithoutTransferAttemptsInput>;
};

export type OrganizationUpdateOneWithoutTwilio_NumbersInput = {
  connect?: InputMaybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrganizationCreateOrConnectWithoutTwilio_NumbersInput>;
  create?: InputMaybe<OrganizationCreateWithoutTwilio_NumbersInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<OrganizationUpdateWithoutTwilio_NumbersInput>;
  upsert?: InputMaybe<OrganizationUpsertWithoutTwilio_NumbersInput>;
};

export type OrganizationUpdateOneWithoutTwilioFeedbacksInput = {
  connect?: InputMaybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrganizationCreateOrConnectWithoutTwilioFeedbacksInput>;
  create?: InputMaybe<OrganizationCreateWithoutTwilioFeedbacksInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<OrganizationUpdateWithoutTwilioFeedbacksInput>;
  upsert?: InputMaybe<OrganizationUpsertWithoutTwilioFeedbacksInput>;
};

export type OrganizationUpdateOneWithoutUserCustomFieldFiltersInput = {
  connect?: InputMaybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrganizationCreateOrConnectWithoutUserCustomFieldFiltersInput>;
  create?: InputMaybe<OrganizationCreateWithoutUserCustomFieldFiltersInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<OrganizationUpdateWithoutUserCustomFieldFiltersInput>;
  upsert?: InputMaybe<OrganizationUpsertWithoutUserCustomFieldFiltersInput>;
};

export type OrganizationUpdatesale_Notification_Email_ListInput = {
  push?: InputMaybe<Scalars['String']['input']>;
  set?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type OrganizationUpdateWithoutAssociationHistoriesInput = {
  ActivityMonitorMultiplier?: InputMaybe<ActivityMonitorMultiplierUpdateManyWithoutOrganizationInput>;
  algoFactor?: InputMaybe<AlgoFactorUpdateManyWithoutOrganizationInput>;
  allow_calendar_invite?: InputMaybe<BoolFieldUpdateOperationsInput>;
  allow_remove_step_action?: InputMaybe<BoolFieldUpdateOperationsInput>;
  allow_snooze_step_action?: InputMaybe<BoolFieldUpdateOperationsInput>;
  always_record?: InputMaybe<BoolFieldUpdateOperationsInput>;
  arv_initial_held_demos_generated_by_sdr_weight?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  auto_call_interval?: InputMaybe<IntFieldUpdateOperationsInput>;
  auto_dial?: InputMaybe<BoolFieldUpdateOperationsInput>;
  automated_campaigns?: InputMaybe<AutomatedCampaignUpdateManyWithoutOrganizationInput>;
  automatic_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  AutoTransferSelections?: InputMaybe<AutoTransferSelectionUpdateManyWithoutOrganizationInput>;
  Availablities?: InputMaybe<AvailabilityUpdateManyWithoutOrganizationInput>;
  AverageOrderValue?: InputMaybe<AverageOrderValueUpdateManyWithoutOrganizationInput>;
  best_action_ever_multiplier?: InputMaybe<FloatFieldUpdateOperationsInput>;
  best_metrics?: InputMaybe<BestMetricRecordUpdateManyWithoutOrganizationInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptUpdateManyWithoutOrganizationInput>;
  callMeNows?: InputMaybe<CallMeNowUpdateManyWithoutOrganizationInput>;
  CallMultiplier?: InputMaybe<CallMultiplierUpdateManyWithoutOrganizationInput>;
  CallSharings?: InputMaybe<CallSharingUpdateManyWithoutOrganizationInput>;
  can_edit_sdr_transfer_rules?: InputMaybe<BoolFieldUpdateOperationsInput>;
  claim_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  concession?: InputMaybe<ConcessionUpdateManyWithoutOrganizationInput>;
  conference_region?: InputMaybe<EnumConferenceRegionFieldUpdateOperationsInput>;
  Conferences?: InputMaybe<ConferenceUpdateManyWithoutOrganizationInput>;
  ContactRequests?: InputMaybe<ContactRequestUpdateManyWithoutOrganizationInput>;
  Contacts?: InputMaybe<ContactUpdateManyWithoutOrganizationInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  custom_fields?: InputMaybe<CustomFieldUpdateManyWithoutOrganizationInput>;
  CustomObject?: InputMaybe<CustomObjectUpdateManyWithoutOrganizationInput>;
  CustomObjectField?: InputMaybe<CustomObjectFieldUpdateManyWithoutOrganizationInput>;
  CustomObjectFieldForeignKey?: InputMaybe<CustomObjectFieldForeignKeyUpdateManyWithoutOrganizationInput>;
  CustomObjectRecord?: InputMaybe<CustomObjectRecordUpdateManyWithoutOrganizationInput>;
  CustomObjectRow?: InputMaybe<CustomObjectRowUpdateManyWithoutOrganizationInput>;
  CustomQueueItems?: InputMaybe<CustomQueueItemUpdateManyWithoutOrganizationInput>;
  DashboardColumnOptions?: InputMaybe<DashboardColumnOptionUpdateManyWithoutOrganizationInput>;
  DashboardLeadFilters?: InputMaybe<DashboardLeadFilterUpdateManyWithoutOrganizationInput>;
  DashboardRepFilters?: InputMaybe<DashboardRepFilterUpdateManyWithoutOrganizationInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewUpdateManyWithoutOrganizationInput>;
  day_to_close?: InputMaybe<IntFieldUpdateOperationsInput>;
  default_demo_time_duration?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  demo_held_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_not_held_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_not_held_call_back_short_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_prep_time_minutes?: InputMaybe<IntFieldUpdateOperationsInput>;
  dev_account?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  dial_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  DispositionMultiplier?: InputMaybe<DispositionMultiplierUpdateManyWithoutOrganizationInput>;
  dispositions?: InputMaybe<DispositionUpdateManyWithoutOrganizationInput>;
  double_booking?: InputMaybe<BoolFieldUpdateOperationsInput>;
  dynamic_date_operators?: InputMaybe<BoolFieldUpdateOperationsInput>;
  edge_servers?: InputMaybe<OrganizationUpdateedge_ServersInput>;
  email_conflict_notification_email_list?: InputMaybe<OrganizationUpdateemail_Conflict_Notification_Email_ListInput>;
  EmailItems?: InputMaybe<EmailItemUpdateManyWithoutOrganizationInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordUpdateManyWithoutOrganizationInput>;
  EmailThreads?: InputMaybe<EmailThreadUpdateManyWithoutOrganizationInput>;
  enable_modified_metrics?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  enable_pay_period_view?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  enable_time_constraint?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  error_log_email_address?: InputMaybe<OrganizationUpdateerror_Log_Email_AddressInput>;
  estimated_average_num_of_weekly_overbooked_demo_slots?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  FolderPermissions?: InputMaybe<FolderPermissionUpdateManyWithoutOrganizationInput>;
  Folders?: InputMaybe<FolderUpdateManyWithoutOrganizationInput>;
  FutureBookingSetting?: InputMaybe<FutureBookingSettingUpdateOneWithoutOrganizationInput>;
  GlobalTransferRule?: InputMaybe<GlobalTransferRuleUpdateManyWithoutOrganizationInput>;
  held_demos_per_cc_connect?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  helpful_links?: InputMaybe<OrganizationUpdatehelpful_LinksInput>;
  HingePointMultiplier?: InputMaybe<HingePointMultiplierUpdateManyWithoutOrganizationInput>;
  hold_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
  hubspot_credential?: InputMaybe<HubSpotCredentialUpdateOneWithoutOrganizationInput>;
  hubspot_last_sync_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  hubspot_white_list?: InputMaybe<HubSpotWhitelistUpdateManyWithoutOrganizationInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inbound_close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_dm_connect_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_lt_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_revenue_value_of_sale?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_scheduled_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_set_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventUpdateManyWithoutOrganizationInput>;
  InboundConciergeWidget?: InputMaybe<InboundConciergeWidgetUpdateManyWithoutOrganizationInput>;
  industry_options?: InputMaybe<IndustryOptionUpdateManyWithoutOrganizationInput>;
  initial_sdr_sets_reps_day?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  integration_error_logs?: InputMaybe<IntegrationErrorLogUpdateManyWithoutOrganizationInput>;
  integration_mappings?: InputMaybe<IntegrationMappingUpdateManyWithoutOrganizationInput>;
  IntegrationMappingCustomObject?: InputMaybe<IntegrationMappingCustomObjectUpdateManyWithoutOrganizationInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogUpdateManyWithoutOrganizationInput>;
  IntegrationReadOnlyObject?: InputMaybe<IntegrationReadOnlyObjectUpdateManyWithoutOrganizationInput>;
  international?: InputMaybe<BoolFieldUpdateOperationsInput>;
  is_email_domain_verified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  is_salesforce_sandbox?: InputMaybe<BoolFieldUpdateOperationsInput>;
  last_lead_value_compute_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutOrganizationInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryUpdateManyWithoutOrganizationInput>;
  lead_intent?: InputMaybe<LeadIntentUpdateManyWithoutOrganizationInput>;
  lead_release_same_rep_delay_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  lead_routing_email_list?: InputMaybe<OrganizationUpdatelead_Routing_Email_ListInput>;
  lead_routing_rules?: InputMaybe<RuleUpdateManyWithoutOrganizationInput>;
  LeadCustomObjectRowAssociation?: InputMaybe<LeadCustomObjectRowAssociationUpdateManyWithoutOrganizationInput>;
  LeadFieldMultiplier?: InputMaybe<LeadFieldMultiplierUpdateManyWithoutOrganizationInput>;
  LeadFieldMultiplierCustom?: InputMaybe<LeadFieldMultiplierCustomUpdateManyWithoutOrganizationInput>;
  leads?: InputMaybe<LeadUpdateManyWithoutOrganizationInput>;
  leadsource_options?: InputMaybe<LeadSourceOptionUpdateManyWithoutOrganizationInput>;
  LeadValue?: InputMaybe<LeadValueUpdateManyWithoutOrganizationInput>;
  LifeTimeValueMultiplier?: InputMaybe<LifeTimeValueMultiplierUpdateManyWithoutOrganizationInput>;
  LiveTransferSetting?: InputMaybe<LiveTransferSettingUpdateOneWithoutOrganizationInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusUpdateManyWithoutOrganizationInput>;
  ltv_multiplier_custom_fields?: InputMaybe<LifeTimeValueMultiplierCustomUpdateManyWithoutOrganizationInput>;
  made_contact_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  make_sale_address_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_business_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_city_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_country_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_first_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_industry_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_last_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_lead_source_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_primary_email_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_primary_phone_number_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_state_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_sub_industry_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_zip_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  maxium_day_lag_set_to_schedule?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  mrr?: InputMaybe<IntFieldUpdateOperationsInput>;
  mrr_label?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  mrr_required_on_sale?: InputMaybe<BoolFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  NoteItems?: InputMaybe<NoteItemUpdateManyWithoutOrganizationInput>;
  Notifications?: InputMaybe<NotificationUpdateManyWithoutOrganizationInput>;
  onboardComplete?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  Opportunity?: InputMaybe<SfOpportunityUpdateManyWithoutOrganizationInput>;
  organization_holidays?: InputMaybe<OrganizationHolidayUpdateManyWithoutOrganizationInput>;
  organization_hours?: InputMaybe<OrganizationHourUpdateManyWithoutOrganizationInput>;
  OrganizationPaymentSetting?: InputMaybe<OrganizationPaymentSettingsUpdateOneWithoutOrganizationInput>;
  OrganizationPaymentSettings?: InputMaybe<OrganizationPaymentSettingsUpdateOneWithoutOrganizationsInput>;
  orgCalendarInviteMetadata?: InputMaybe<OrgCalendarInviteMetadataUpdateManyWithoutOrganizationInput>;
  OrgDefaultPipelinePerecentValue?: InputMaybe<OrgDefaultPipelinePerecentValueUpdateOneWithoutOrganizationInput>;
  OrgHubSpotSync?: InputMaybe<OrgHubSpotSyncUpdateOneWithoutOrganizationInput>;
  OrgNoEventDate?: InputMaybe<OrgNoEventDateUpdateManyWithoutOrganizationInput>;
  OrgSalesforceSync?: InputMaybe<OrgSalesforceSyncUpdateOneWithoutOrganizationInput>;
  OrgTimeslotAvailability?: InputMaybe<OrgTimeslotAvailabilityUpdateManyWithoutOrganizationInput>;
  outbound_close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_dm_connect_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_lt_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_revenue_value_of_sale?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_scheduled_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_set_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  PandadocData?: InputMaybe<PandadocDataUpdateOneWithoutOrganizationInput>;
  PandaDocTemplate?: InputMaybe<PandaDocTemplateUpdateManyWithoutOrganizationInput>;
  participant_jitter_buffer_size?: InputMaybe<EnumJitterBufferSizeFieldUpdateOperationsInput>;
  preferred_goal_setting?: InputMaybe<NullableEnumGoalsettingFieldUpdateOperationsInput>;
  prepayment?: InputMaybe<BoolFieldUpdateOperationsInput>;
  ProductChangeLog?: InputMaybe<ProductChangeLogUpdateManyWithoutOrganizationInput>;
  products?: InputMaybe<ProductUpdateManyWithoutOrganizationInput>;
  re_cold_call_hour_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  recordingStatus?: InputMaybe<EnumRecordingStatusFieldUpdateOperationsInput>;
  release_lead_resting?: InputMaybe<IntFieldUpdateOperationsInput>;
  rep_claim_limit?: InputMaybe<IntFieldUpdateOperationsInput>;
  rep_lead_allotment?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  rep_level_call_transfer?: InputMaybe<BoolFieldUpdateOperationsInput>;
  reps_can_bulk_unassign?: InputMaybe<BoolFieldUpdateOperationsInput>;
  reps_can_view_all_notes?: InputMaybe<BoolFieldUpdateOperationsInput>;
  require_sale_notes?: InputMaybe<BoolFieldUpdateOperationsInput>;
  resting_after_skipped_CC?: InputMaybe<IntFieldUpdateOperationsInput>;
  resting_period?: InputMaybe<IntFieldUpdateOperationsInput>;
  resting_period_after_unsuccessful_cold_call?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  resting_period_after_unsuccessful_demo?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  revenue_plan?: InputMaybe<RevenuePlanItemUpdateManyWithoutOrganizationInput>;
  Routings?: InputMaybe<RoutingUpdateManyWithoutOrganizationInput>;
  sale_field_options?: InputMaybe<SaleFieldOptionUpdateManyWithoutOrganizationInput>;
  sale_notification_email_list?: InputMaybe<OrganizationUpdatesale_Notification_Email_ListInput>;
  SaleConfig?: InputMaybe<SaleConfigUpdateOneWithoutOrganizationInput>;
  SaleConfigPage?: InputMaybe<SaleConfigPageUpdateManyWithoutOrganizationInput>;
  SaleConfigSection?: InputMaybe<SaleConfigSectionUpdateManyWithoutOrganizationInput>;
  SaleCycles?: InputMaybe<SaleCycleUpdateManyWithoutOrganizationInput>;
  SaleItems?: InputMaybe<SaleItemUpdateManyWithoutOrganizationInput>;
  sales?: InputMaybe<SaleUpdateManyWithoutOrganizationInput>;
  salesforce_domain?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  salesforce_last_sync_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  SalesForceColumn?: InputMaybe<SalesForceColumnUpdateManyWithoutOrganizationInput>;
  salesForceData?: InputMaybe<SalesForceDataUpdateOneWithoutOrganizationInput>;
  ScheduleItems?: InputMaybe<ScheduleItemUpdateManyWithoutOrganizationInput>;
  self_sourcing_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  send_error_log_email?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  send_lead_routing_email?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sendgrid_email_domain?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sendgrid_email_domain_id?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  sequence?: InputMaybe<SequenceUpdateManyWithoutOrganzationInput>;
  SequenceCriteriaConditions?: InputMaybe<SequenceCriteriaConditionUpdateManyWithoutOrganizationInput>;
  SequenceDashboardViews?: InputMaybe<SequenceDashboardViewUpdateManyWithoutOrganizationInput>;
  SequenceEntryCriterias?: InputMaybe<SequenceEntryCriteriaUpdateManyWithoutOrganizationInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutOrganizationInput>;
  SequenceSteps?: InputMaybe<SequenceStepUpdateManyWithoutOrganizationInput>;
  set_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
  short_resting?: InputMaybe<IntFieldUpdateOperationsInput>;
  show_calendar_options?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_contract_duration_sales?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_lead_source_to_rep?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_nocontact_button?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_payment_terms_sales?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_rep_all_leads?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_rep_my_leads?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_system_view?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sites?: InputMaybe<SiteUpdateManyWithoutOrganizationInput>;
  sms_opt_in_dialog?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SMSItems?: InputMaybe<SmsItemUpdateManyWithoutOrganizationInput>;
  SnoozeTrackings?: InputMaybe<SnoozeTrackingUpdateManyWithoutOrganizationInput>;
  StepActionLogs?: InputMaybe<StepActionLogUpdateManyWithoutOrganizationInput>;
  StepActions?: InputMaybe<StepActionUpdateManyWithoutOrganizationInput>;
  StepConditions?: InputMaybe<StepConditionUpdateManyWithoutOrganizationInput>;
  teams?: InputMaybe<TeamUpdateManyWithoutOrganizationInput>;
  Template?: InputMaybe<TemplateUpdateManyWithoutOrganizationInput>;
  TemplateVersions?: InputMaybe<TemplateVersionUpdateManyWithoutOrganizationInput>;
  timekit_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timezone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transfer_timeout?: InputMaybe<IntFieldUpdateOperationsInput>;
  TransferAttempts?: InputMaybe<TransferAttemptUpdateManyWithoutOrganizationInput>;
  twilio_numbers?: InputMaybe<TwilioNumberUpdateManyWithoutOrganizationInput>;
  TwilioFeedbacks?: InputMaybe<TwilioFeedbackUpdateManyWithoutOrganizationInput>;
  TwilioInfo?: InputMaybe<TwilioInfoUpdateOneWithoutOrganzationInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  use_revenue_planning?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  UserCustomFieldFilters?: InputMaybe<UserCustomFieldFilterUpdateManyWithoutOrganizationInput>;
  users?: InputMaybe<UserUpdateManyWithoutOrganizationInput>;
  win_rep_day_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
};

export type OrganizationUpdateWithoutAutomated_CampaignsInput = {
  ActivityMonitorMultiplier?: InputMaybe<ActivityMonitorMultiplierUpdateManyWithoutOrganizationInput>;
  algoFactor?: InputMaybe<AlgoFactorUpdateManyWithoutOrganizationInput>;
  allow_calendar_invite?: InputMaybe<BoolFieldUpdateOperationsInput>;
  allow_remove_step_action?: InputMaybe<BoolFieldUpdateOperationsInput>;
  allow_snooze_step_action?: InputMaybe<BoolFieldUpdateOperationsInput>;
  always_record?: InputMaybe<BoolFieldUpdateOperationsInput>;
  arv_initial_held_demos_generated_by_sdr_weight?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  AssociationHistories?: InputMaybe<AssociationHistoryUpdateManyWithoutOrganizationInput>;
  auto_call_interval?: InputMaybe<IntFieldUpdateOperationsInput>;
  auto_dial?: InputMaybe<BoolFieldUpdateOperationsInput>;
  automatic_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  AutoTransferSelections?: InputMaybe<AutoTransferSelectionUpdateManyWithoutOrganizationInput>;
  Availablities?: InputMaybe<AvailabilityUpdateManyWithoutOrganizationInput>;
  AverageOrderValue?: InputMaybe<AverageOrderValueUpdateManyWithoutOrganizationInput>;
  best_action_ever_multiplier?: InputMaybe<FloatFieldUpdateOperationsInput>;
  best_metrics?: InputMaybe<BestMetricRecordUpdateManyWithoutOrganizationInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptUpdateManyWithoutOrganizationInput>;
  callMeNows?: InputMaybe<CallMeNowUpdateManyWithoutOrganizationInput>;
  CallMultiplier?: InputMaybe<CallMultiplierUpdateManyWithoutOrganizationInput>;
  CallSharings?: InputMaybe<CallSharingUpdateManyWithoutOrganizationInput>;
  can_edit_sdr_transfer_rules?: InputMaybe<BoolFieldUpdateOperationsInput>;
  claim_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  concession?: InputMaybe<ConcessionUpdateManyWithoutOrganizationInput>;
  conference_region?: InputMaybe<EnumConferenceRegionFieldUpdateOperationsInput>;
  Conferences?: InputMaybe<ConferenceUpdateManyWithoutOrganizationInput>;
  ContactRequests?: InputMaybe<ContactRequestUpdateManyWithoutOrganizationInput>;
  Contacts?: InputMaybe<ContactUpdateManyWithoutOrganizationInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  custom_fields?: InputMaybe<CustomFieldUpdateManyWithoutOrganizationInput>;
  CustomObject?: InputMaybe<CustomObjectUpdateManyWithoutOrganizationInput>;
  CustomObjectField?: InputMaybe<CustomObjectFieldUpdateManyWithoutOrganizationInput>;
  CustomObjectFieldForeignKey?: InputMaybe<CustomObjectFieldForeignKeyUpdateManyWithoutOrganizationInput>;
  CustomObjectRecord?: InputMaybe<CustomObjectRecordUpdateManyWithoutOrganizationInput>;
  CustomObjectRow?: InputMaybe<CustomObjectRowUpdateManyWithoutOrganizationInput>;
  CustomQueueItems?: InputMaybe<CustomQueueItemUpdateManyWithoutOrganizationInput>;
  DashboardColumnOptions?: InputMaybe<DashboardColumnOptionUpdateManyWithoutOrganizationInput>;
  DashboardLeadFilters?: InputMaybe<DashboardLeadFilterUpdateManyWithoutOrganizationInput>;
  DashboardRepFilters?: InputMaybe<DashboardRepFilterUpdateManyWithoutOrganizationInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewUpdateManyWithoutOrganizationInput>;
  day_to_close?: InputMaybe<IntFieldUpdateOperationsInput>;
  default_demo_time_duration?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  demo_held_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_not_held_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_not_held_call_back_short_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_prep_time_minutes?: InputMaybe<IntFieldUpdateOperationsInput>;
  dev_account?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  dial_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  DispositionMultiplier?: InputMaybe<DispositionMultiplierUpdateManyWithoutOrganizationInput>;
  dispositions?: InputMaybe<DispositionUpdateManyWithoutOrganizationInput>;
  double_booking?: InputMaybe<BoolFieldUpdateOperationsInput>;
  dynamic_date_operators?: InputMaybe<BoolFieldUpdateOperationsInput>;
  edge_servers?: InputMaybe<OrganizationUpdateedge_ServersInput>;
  email_conflict_notification_email_list?: InputMaybe<OrganizationUpdateemail_Conflict_Notification_Email_ListInput>;
  EmailItems?: InputMaybe<EmailItemUpdateManyWithoutOrganizationInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordUpdateManyWithoutOrganizationInput>;
  EmailThreads?: InputMaybe<EmailThreadUpdateManyWithoutOrganizationInput>;
  enable_modified_metrics?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  enable_pay_period_view?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  enable_time_constraint?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  error_log_email_address?: InputMaybe<OrganizationUpdateerror_Log_Email_AddressInput>;
  estimated_average_num_of_weekly_overbooked_demo_slots?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  FolderPermissions?: InputMaybe<FolderPermissionUpdateManyWithoutOrganizationInput>;
  Folders?: InputMaybe<FolderUpdateManyWithoutOrganizationInput>;
  FutureBookingSetting?: InputMaybe<FutureBookingSettingUpdateOneWithoutOrganizationInput>;
  GlobalTransferRule?: InputMaybe<GlobalTransferRuleUpdateManyWithoutOrganizationInput>;
  held_demos_per_cc_connect?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  helpful_links?: InputMaybe<OrganizationUpdatehelpful_LinksInput>;
  HingePointMultiplier?: InputMaybe<HingePointMultiplierUpdateManyWithoutOrganizationInput>;
  hold_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
  hubspot_credential?: InputMaybe<HubSpotCredentialUpdateOneWithoutOrganizationInput>;
  hubspot_last_sync_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  hubspot_white_list?: InputMaybe<HubSpotWhitelistUpdateManyWithoutOrganizationInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inbound_close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_dm_connect_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_lt_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_revenue_value_of_sale?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_scheduled_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_set_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventUpdateManyWithoutOrganizationInput>;
  InboundConciergeWidget?: InputMaybe<InboundConciergeWidgetUpdateManyWithoutOrganizationInput>;
  industry_options?: InputMaybe<IndustryOptionUpdateManyWithoutOrganizationInput>;
  initial_sdr_sets_reps_day?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  integration_error_logs?: InputMaybe<IntegrationErrorLogUpdateManyWithoutOrganizationInput>;
  integration_mappings?: InputMaybe<IntegrationMappingUpdateManyWithoutOrganizationInput>;
  IntegrationMappingCustomObject?: InputMaybe<IntegrationMappingCustomObjectUpdateManyWithoutOrganizationInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogUpdateManyWithoutOrganizationInput>;
  IntegrationReadOnlyObject?: InputMaybe<IntegrationReadOnlyObjectUpdateManyWithoutOrganizationInput>;
  international?: InputMaybe<BoolFieldUpdateOperationsInput>;
  is_email_domain_verified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  is_salesforce_sandbox?: InputMaybe<BoolFieldUpdateOperationsInput>;
  last_lead_value_compute_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutOrganizationInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryUpdateManyWithoutOrganizationInput>;
  lead_intent?: InputMaybe<LeadIntentUpdateManyWithoutOrganizationInput>;
  lead_release_same_rep_delay_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  lead_routing_email_list?: InputMaybe<OrganizationUpdatelead_Routing_Email_ListInput>;
  lead_routing_rules?: InputMaybe<RuleUpdateManyWithoutOrganizationInput>;
  LeadCustomObjectRowAssociation?: InputMaybe<LeadCustomObjectRowAssociationUpdateManyWithoutOrganizationInput>;
  LeadFieldMultiplier?: InputMaybe<LeadFieldMultiplierUpdateManyWithoutOrganizationInput>;
  LeadFieldMultiplierCustom?: InputMaybe<LeadFieldMultiplierCustomUpdateManyWithoutOrganizationInput>;
  leads?: InputMaybe<LeadUpdateManyWithoutOrganizationInput>;
  leadsource_options?: InputMaybe<LeadSourceOptionUpdateManyWithoutOrganizationInput>;
  LeadValue?: InputMaybe<LeadValueUpdateManyWithoutOrganizationInput>;
  LifeTimeValueMultiplier?: InputMaybe<LifeTimeValueMultiplierUpdateManyWithoutOrganizationInput>;
  LiveTransferSetting?: InputMaybe<LiveTransferSettingUpdateOneWithoutOrganizationInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusUpdateManyWithoutOrganizationInput>;
  ltv_multiplier_custom_fields?: InputMaybe<LifeTimeValueMultiplierCustomUpdateManyWithoutOrganizationInput>;
  made_contact_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  make_sale_address_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_business_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_city_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_country_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_first_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_industry_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_last_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_lead_source_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_primary_email_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_primary_phone_number_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_state_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_sub_industry_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_zip_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  maxium_day_lag_set_to_schedule?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  mrr?: InputMaybe<IntFieldUpdateOperationsInput>;
  mrr_label?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  mrr_required_on_sale?: InputMaybe<BoolFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  NoteItems?: InputMaybe<NoteItemUpdateManyWithoutOrganizationInput>;
  Notifications?: InputMaybe<NotificationUpdateManyWithoutOrganizationInput>;
  onboardComplete?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  Opportunity?: InputMaybe<SfOpportunityUpdateManyWithoutOrganizationInput>;
  organization_holidays?: InputMaybe<OrganizationHolidayUpdateManyWithoutOrganizationInput>;
  organization_hours?: InputMaybe<OrganizationHourUpdateManyWithoutOrganizationInput>;
  OrganizationPaymentSetting?: InputMaybe<OrganizationPaymentSettingsUpdateOneWithoutOrganizationInput>;
  OrganizationPaymentSettings?: InputMaybe<OrganizationPaymentSettingsUpdateOneWithoutOrganizationsInput>;
  orgCalendarInviteMetadata?: InputMaybe<OrgCalendarInviteMetadataUpdateManyWithoutOrganizationInput>;
  OrgDefaultPipelinePerecentValue?: InputMaybe<OrgDefaultPipelinePerecentValueUpdateOneWithoutOrganizationInput>;
  OrgHubSpotSync?: InputMaybe<OrgHubSpotSyncUpdateOneWithoutOrganizationInput>;
  OrgNoEventDate?: InputMaybe<OrgNoEventDateUpdateManyWithoutOrganizationInput>;
  OrgSalesforceSync?: InputMaybe<OrgSalesforceSyncUpdateOneWithoutOrganizationInput>;
  OrgTimeslotAvailability?: InputMaybe<OrgTimeslotAvailabilityUpdateManyWithoutOrganizationInput>;
  outbound_close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_dm_connect_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_lt_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_revenue_value_of_sale?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_scheduled_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_set_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  PandadocData?: InputMaybe<PandadocDataUpdateOneWithoutOrganizationInput>;
  PandaDocTemplate?: InputMaybe<PandaDocTemplateUpdateManyWithoutOrganizationInput>;
  participant_jitter_buffer_size?: InputMaybe<EnumJitterBufferSizeFieldUpdateOperationsInput>;
  preferred_goal_setting?: InputMaybe<NullableEnumGoalsettingFieldUpdateOperationsInput>;
  prepayment?: InputMaybe<BoolFieldUpdateOperationsInput>;
  ProductChangeLog?: InputMaybe<ProductChangeLogUpdateManyWithoutOrganizationInput>;
  products?: InputMaybe<ProductUpdateManyWithoutOrganizationInput>;
  re_cold_call_hour_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  recordingStatus?: InputMaybe<EnumRecordingStatusFieldUpdateOperationsInput>;
  release_lead_resting?: InputMaybe<IntFieldUpdateOperationsInput>;
  rep_claim_limit?: InputMaybe<IntFieldUpdateOperationsInput>;
  rep_lead_allotment?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  rep_level_call_transfer?: InputMaybe<BoolFieldUpdateOperationsInput>;
  reps_can_bulk_unassign?: InputMaybe<BoolFieldUpdateOperationsInput>;
  reps_can_view_all_notes?: InputMaybe<BoolFieldUpdateOperationsInput>;
  require_sale_notes?: InputMaybe<BoolFieldUpdateOperationsInput>;
  resting_after_skipped_CC?: InputMaybe<IntFieldUpdateOperationsInput>;
  resting_period?: InputMaybe<IntFieldUpdateOperationsInput>;
  resting_period_after_unsuccessful_cold_call?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  resting_period_after_unsuccessful_demo?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  revenue_plan?: InputMaybe<RevenuePlanItemUpdateManyWithoutOrganizationInput>;
  Routings?: InputMaybe<RoutingUpdateManyWithoutOrganizationInput>;
  sale_field_options?: InputMaybe<SaleFieldOptionUpdateManyWithoutOrganizationInput>;
  sale_notification_email_list?: InputMaybe<OrganizationUpdatesale_Notification_Email_ListInput>;
  SaleConfig?: InputMaybe<SaleConfigUpdateOneWithoutOrganizationInput>;
  SaleConfigPage?: InputMaybe<SaleConfigPageUpdateManyWithoutOrganizationInput>;
  SaleConfigSection?: InputMaybe<SaleConfigSectionUpdateManyWithoutOrganizationInput>;
  SaleCycles?: InputMaybe<SaleCycleUpdateManyWithoutOrganizationInput>;
  SaleItems?: InputMaybe<SaleItemUpdateManyWithoutOrganizationInput>;
  sales?: InputMaybe<SaleUpdateManyWithoutOrganizationInput>;
  salesforce_domain?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  salesforce_last_sync_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  SalesForceColumn?: InputMaybe<SalesForceColumnUpdateManyWithoutOrganizationInput>;
  salesForceData?: InputMaybe<SalesForceDataUpdateOneWithoutOrganizationInput>;
  ScheduleItems?: InputMaybe<ScheduleItemUpdateManyWithoutOrganizationInput>;
  self_sourcing_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  send_error_log_email?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  send_lead_routing_email?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sendgrid_email_domain?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sendgrid_email_domain_id?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  sequence?: InputMaybe<SequenceUpdateManyWithoutOrganzationInput>;
  SequenceCriteriaConditions?: InputMaybe<SequenceCriteriaConditionUpdateManyWithoutOrganizationInput>;
  SequenceDashboardViews?: InputMaybe<SequenceDashboardViewUpdateManyWithoutOrganizationInput>;
  SequenceEntryCriterias?: InputMaybe<SequenceEntryCriteriaUpdateManyWithoutOrganizationInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutOrganizationInput>;
  SequenceSteps?: InputMaybe<SequenceStepUpdateManyWithoutOrganizationInput>;
  set_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
  short_resting?: InputMaybe<IntFieldUpdateOperationsInput>;
  show_calendar_options?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_contract_duration_sales?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_lead_source_to_rep?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_nocontact_button?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_payment_terms_sales?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_rep_all_leads?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_rep_my_leads?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_system_view?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sites?: InputMaybe<SiteUpdateManyWithoutOrganizationInput>;
  sms_opt_in_dialog?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SMSItems?: InputMaybe<SmsItemUpdateManyWithoutOrganizationInput>;
  SnoozeTrackings?: InputMaybe<SnoozeTrackingUpdateManyWithoutOrganizationInput>;
  StepActionLogs?: InputMaybe<StepActionLogUpdateManyWithoutOrganizationInput>;
  StepActions?: InputMaybe<StepActionUpdateManyWithoutOrganizationInput>;
  StepConditions?: InputMaybe<StepConditionUpdateManyWithoutOrganizationInput>;
  teams?: InputMaybe<TeamUpdateManyWithoutOrganizationInput>;
  Template?: InputMaybe<TemplateUpdateManyWithoutOrganizationInput>;
  TemplateVersions?: InputMaybe<TemplateVersionUpdateManyWithoutOrganizationInput>;
  timekit_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timezone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transfer_timeout?: InputMaybe<IntFieldUpdateOperationsInput>;
  TransferAttempts?: InputMaybe<TransferAttemptUpdateManyWithoutOrganizationInput>;
  twilio_numbers?: InputMaybe<TwilioNumberUpdateManyWithoutOrganizationInput>;
  TwilioFeedbacks?: InputMaybe<TwilioFeedbackUpdateManyWithoutOrganizationInput>;
  TwilioInfo?: InputMaybe<TwilioInfoUpdateOneWithoutOrganzationInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  use_revenue_planning?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  UserCustomFieldFilters?: InputMaybe<UserCustomFieldFilterUpdateManyWithoutOrganizationInput>;
  users?: InputMaybe<UserUpdateManyWithoutOrganizationInput>;
  win_rep_day_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
};

export type OrganizationUpdateWithoutAutoTransferSelectionsInput = {
  ActivityMonitorMultiplier?: InputMaybe<ActivityMonitorMultiplierUpdateManyWithoutOrganizationInput>;
  algoFactor?: InputMaybe<AlgoFactorUpdateManyWithoutOrganizationInput>;
  allow_calendar_invite?: InputMaybe<BoolFieldUpdateOperationsInput>;
  allow_remove_step_action?: InputMaybe<BoolFieldUpdateOperationsInput>;
  allow_snooze_step_action?: InputMaybe<BoolFieldUpdateOperationsInput>;
  always_record?: InputMaybe<BoolFieldUpdateOperationsInput>;
  arv_initial_held_demos_generated_by_sdr_weight?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  AssociationHistories?: InputMaybe<AssociationHistoryUpdateManyWithoutOrganizationInput>;
  auto_call_interval?: InputMaybe<IntFieldUpdateOperationsInput>;
  auto_dial?: InputMaybe<BoolFieldUpdateOperationsInput>;
  automated_campaigns?: InputMaybe<AutomatedCampaignUpdateManyWithoutOrganizationInput>;
  automatic_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  Availablities?: InputMaybe<AvailabilityUpdateManyWithoutOrganizationInput>;
  AverageOrderValue?: InputMaybe<AverageOrderValueUpdateManyWithoutOrganizationInput>;
  best_action_ever_multiplier?: InputMaybe<FloatFieldUpdateOperationsInput>;
  best_metrics?: InputMaybe<BestMetricRecordUpdateManyWithoutOrganizationInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptUpdateManyWithoutOrganizationInput>;
  callMeNows?: InputMaybe<CallMeNowUpdateManyWithoutOrganizationInput>;
  CallMultiplier?: InputMaybe<CallMultiplierUpdateManyWithoutOrganizationInput>;
  CallSharings?: InputMaybe<CallSharingUpdateManyWithoutOrganizationInput>;
  can_edit_sdr_transfer_rules?: InputMaybe<BoolFieldUpdateOperationsInput>;
  claim_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  concession?: InputMaybe<ConcessionUpdateManyWithoutOrganizationInput>;
  conference_region?: InputMaybe<EnumConferenceRegionFieldUpdateOperationsInput>;
  Conferences?: InputMaybe<ConferenceUpdateManyWithoutOrganizationInput>;
  ContactRequests?: InputMaybe<ContactRequestUpdateManyWithoutOrganizationInput>;
  Contacts?: InputMaybe<ContactUpdateManyWithoutOrganizationInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  custom_fields?: InputMaybe<CustomFieldUpdateManyWithoutOrganizationInput>;
  CustomObject?: InputMaybe<CustomObjectUpdateManyWithoutOrganizationInput>;
  CustomObjectField?: InputMaybe<CustomObjectFieldUpdateManyWithoutOrganizationInput>;
  CustomObjectFieldForeignKey?: InputMaybe<CustomObjectFieldForeignKeyUpdateManyWithoutOrganizationInput>;
  CustomObjectRecord?: InputMaybe<CustomObjectRecordUpdateManyWithoutOrganizationInput>;
  CustomObjectRow?: InputMaybe<CustomObjectRowUpdateManyWithoutOrganizationInput>;
  CustomQueueItems?: InputMaybe<CustomQueueItemUpdateManyWithoutOrganizationInput>;
  DashboardColumnOptions?: InputMaybe<DashboardColumnOptionUpdateManyWithoutOrganizationInput>;
  DashboardLeadFilters?: InputMaybe<DashboardLeadFilterUpdateManyWithoutOrganizationInput>;
  DashboardRepFilters?: InputMaybe<DashboardRepFilterUpdateManyWithoutOrganizationInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewUpdateManyWithoutOrganizationInput>;
  day_to_close?: InputMaybe<IntFieldUpdateOperationsInput>;
  default_demo_time_duration?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  demo_held_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_not_held_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_not_held_call_back_short_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_prep_time_minutes?: InputMaybe<IntFieldUpdateOperationsInput>;
  dev_account?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  dial_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  DispositionMultiplier?: InputMaybe<DispositionMultiplierUpdateManyWithoutOrganizationInput>;
  dispositions?: InputMaybe<DispositionUpdateManyWithoutOrganizationInput>;
  double_booking?: InputMaybe<BoolFieldUpdateOperationsInput>;
  dynamic_date_operators?: InputMaybe<BoolFieldUpdateOperationsInput>;
  edge_servers?: InputMaybe<OrganizationUpdateedge_ServersInput>;
  email_conflict_notification_email_list?: InputMaybe<OrganizationUpdateemail_Conflict_Notification_Email_ListInput>;
  EmailItems?: InputMaybe<EmailItemUpdateManyWithoutOrganizationInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordUpdateManyWithoutOrganizationInput>;
  EmailThreads?: InputMaybe<EmailThreadUpdateManyWithoutOrganizationInput>;
  enable_modified_metrics?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  enable_pay_period_view?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  enable_time_constraint?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  error_log_email_address?: InputMaybe<OrganizationUpdateerror_Log_Email_AddressInput>;
  estimated_average_num_of_weekly_overbooked_demo_slots?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  FolderPermissions?: InputMaybe<FolderPermissionUpdateManyWithoutOrganizationInput>;
  Folders?: InputMaybe<FolderUpdateManyWithoutOrganizationInput>;
  FutureBookingSetting?: InputMaybe<FutureBookingSettingUpdateOneWithoutOrganizationInput>;
  GlobalTransferRule?: InputMaybe<GlobalTransferRuleUpdateManyWithoutOrganizationInput>;
  held_demos_per_cc_connect?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  helpful_links?: InputMaybe<OrganizationUpdatehelpful_LinksInput>;
  HingePointMultiplier?: InputMaybe<HingePointMultiplierUpdateManyWithoutOrganizationInput>;
  hold_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
  hubspot_credential?: InputMaybe<HubSpotCredentialUpdateOneWithoutOrganizationInput>;
  hubspot_last_sync_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  hubspot_white_list?: InputMaybe<HubSpotWhitelistUpdateManyWithoutOrganizationInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inbound_close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_dm_connect_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_lt_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_revenue_value_of_sale?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_scheduled_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_set_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventUpdateManyWithoutOrganizationInput>;
  InboundConciergeWidget?: InputMaybe<InboundConciergeWidgetUpdateManyWithoutOrganizationInput>;
  industry_options?: InputMaybe<IndustryOptionUpdateManyWithoutOrganizationInput>;
  initial_sdr_sets_reps_day?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  integration_error_logs?: InputMaybe<IntegrationErrorLogUpdateManyWithoutOrganizationInput>;
  integration_mappings?: InputMaybe<IntegrationMappingUpdateManyWithoutOrganizationInput>;
  IntegrationMappingCustomObject?: InputMaybe<IntegrationMappingCustomObjectUpdateManyWithoutOrganizationInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogUpdateManyWithoutOrganizationInput>;
  IntegrationReadOnlyObject?: InputMaybe<IntegrationReadOnlyObjectUpdateManyWithoutOrganizationInput>;
  international?: InputMaybe<BoolFieldUpdateOperationsInput>;
  is_email_domain_verified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  is_salesforce_sandbox?: InputMaybe<BoolFieldUpdateOperationsInput>;
  last_lead_value_compute_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutOrganizationInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryUpdateManyWithoutOrganizationInput>;
  lead_intent?: InputMaybe<LeadIntentUpdateManyWithoutOrganizationInput>;
  lead_release_same_rep_delay_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  lead_routing_email_list?: InputMaybe<OrganizationUpdatelead_Routing_Email_ListInput>;
  lead_routing_rules?: InputMaybe<RuleUpdateManyWithoutOrganizationInput>;
  LeadCustomObjectRowAssociation?: InputMaybe<LeadCustomObjectRowAssociationUpdateManyWithoutOrganizationInput>;
  LeadFieldMultiplier?: InputMaybe<LeadFieldMultiplierUpdateManyWithoutOrganizationInput>;
  LeadFieldMultiplierCustom?: InputMaybe<LeadFieldMultiplierCustomUpdateManyWithoutOrganizationInput>;
  leads?: InputMaybe<LeadUpdateManyWithoutOrganizationInput>;
  leadsource_options?: InputMaybe<LeadSourceOptionUpdateManyWithoutOrganizationInput>;
  LeadValue?: InputMaybe<LeadValueUpdateManyWithoutOrganizationInput>;
  LifeTimeValueMultiplier?: InputMaybe<LifeTimeValueMultiplierUpdateManyWithoutOrganizationInput>;
  LiveTransferSetting?: InputMaybe<LiveTransferSettingUpdateOneWithoutOrganizationInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusUpdateManyWithoutOrganizationInput>;
  ltv_multiplier_custom_fields?: InputMaybe<LifeTimeValueMultiplierCustomUpdateManyWithoutOrganizationInput>;
  made_contact_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  make_sale_address_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_business_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_city_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_country_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_first_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_industry_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_last_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_lead_source_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_primary_email_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_primary_phone_number_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_state_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_sub_industry_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_zip_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  maxium_day_lag_set_to_schedule?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  mrr?: InputMaybe<IntFieldUpdateOperationsInput>;
  mrr_label?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  mrr_required_on_sale?: InputMaybe<BoolFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  NoteItems?: InputMaybe<NoteItemUpdateManyWithoutOrganizationInput>;
  Notifications?: InputMaybe<NotificationUpdateManyWithoutOrganizationInput>;
  onboardComplete?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  Opportunity?: InputMaybe<SfOpportunityUpdateManyWithoutOrganizationInput>;
  organization_holidays?: InputMaybe<OrganizationHolidayUpdateManyWithoutOrganizationInput>;
  organization_hours?: InputMaybe<OrganizationHourUpdateManyWithoutOrganizationInput>;
  OrganizationPaymentSetting?: InputMaybe<OrganizationPaymentSettingsUpdateOneWithoutOrganizationInput>;
  OrganizationPaymentSettings?: InputMaybe<OrganizationPaymentSettingsUpdateOneWithoutOrganizationsInput>;
  orgCalendarInviteMetadata?: InputMaybe<OrgCalendarInviteMetadataUpdateManyWithoutOrganizationInput>;
  OrgDefaultPipelinePerecentValue?: InputMaybe<OrgDefaultPipelinePerecentValueUpdateOneWithoutOrganizationInput>;
  OrgHubSpotSync?: InputMaybe<OrgHubSpotSyncUpdateOneWithoutOrganizationInput>;
  OrgNoEventDate?: InputMaybe<OrgNoEventDateUpdateManyWithoutOrganizationInput>;
  OrgSalesforceSync?: InputMaybe<OrgSalesforceSyncUpdateOneWithoutOrganizationInput>;
  OrgTimeslotAvailability?: InputMaybe<OrgTimeslotAvailabilityUpdateManyWithoutOrganizationInput>;
  outbound_close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_dm_connect_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_lt_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_revenue_value_of_sale?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_scheduled_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_set_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  PandadocData?: InputMaybe<PandadocDataUpdateOneWithoutOrganizationInput>;
  PandaDocTemplate?: InputMaybe<PandaDocTemplateUpdateManyWithoutOrganizationInput>;
  participant_jitter_buffer_size?: InputMaybe<EnumJitterBufferSizeFieldUpdateOperationsInput>;
  preferred_goal_setting?: InputMaybe<NullableEnumGoalsettingFieldUpdateOperationsInput>;
  prepayment?: InputMaybe<BoolFieldUpdateOperationsInput>;
  ProductChangeLog?: InputMaybe<ProductChangeLogUpdateManyWithoutOrganizationInput>;
  products?: InputMaybe<ProductUpdateManyWithoutOrganizationInput>;
  re_cold_call_hour_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  recordingStatus?: InputMaybe<EnumRecordingStatusFieldUpdateOperationsInput>;
  release_lead_resting?: InputMaybe<IntFieldUpdateOperationsInput>;
  rep_claim_limit?: InputMaybe<IntFieldUpdateOperationsInput>;
  rep_lead_allotment?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  rep_level_call_transfer?: InputMaybe<BoolFieldUpdateOperationsInput>;
  reps_can_bulk_unassign?: InputMaybe<BoolFieldUpdateOperationsInput>;
  reps_can_view_all_notes?: InputMaybe<BoolFieldUpdateOperationsInput>;
  require_sale_notes?: InputMaybe<BoolFieldUpdateOperationsInput>;
  resting_after_skipped_CC?: InputMaybe<IntFieldUpdateOperationsInput>;
  resting_period?: InputMaybe<IntFieldUpdateOperationsInput>;
  resting_period_after_unsuccessful_cold_call?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  resting_period_after_unsuccessful_demo?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  revenue_plan?: InputMaybe<RevenuePlanItemUpdateManyWithoutOrganizationInput>;
  Routings?: InputMaybe<RoutingUpdateManyWithoutOrganizationInput>;
  sale_field_options?: InputMaybe<SaleFieldOptionUpdateManyWithoutOrganizationInput>;
  sale_notification_email_list?: InputMaybe<OrganizationUpdatesale_Notification_Email_ListInput>;
  SaleConfig?: InputMaybe<SaleConfigUpdateOneWithoutOrganizationInput>;
  SaleConfigPage?: InputMaybe<SaleConfigPageUpdateManyWithoutOrganizationInput>;
  SaleConfigSection?: InputMaybe<SaleConfigSectionUpdateManyWithoutOrganizationInput>;
  SaleCycles?: InputMaybe<SaleCycleUpdateManyWithoutOrganizationInput>;
  SaleItems?: InputMaybe<SaleItemUpdateManyWithoutOrganizationInput>;
  sales?: InputMaybe<SaleUpdateManyWithoutOrganizationInput>;
  salesforce_domain?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  salesforce_last_sync_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  SalesForceColumn?: InputMaybe<SalesForceColumnUpdateManyWithoutOrganizationInput>;
  salesForceData?: InputMaybe<SalesForceDataUpdateOneWithoutOrganizationInput>;
  ScheduleItems?: InputMaybe<ScheduleItemUpdateManyWithoutOrganizationInput>;
  self_sourcing_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  send_error_log_email?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  send_lead_routing_email?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sendgrid_email_domain?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sendgrid_email_domain_id?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  sequence?: InputMaybe<SequenceUpdateManyWithoutOrganzationInput>;
  SequenceCriteriaConditions?: InputMaybe<SequenceCriteriaConditionUpdateManyWithoutOrganizationInput>;
  SequenceDashboardViews?: InputMaybe<SequenceDashboardViewUpdateManyWithoutOrganizationInput>;
  SequenceEntryCriterias?: InputMaybe<SequenceEntryCriteriaUpdateManyWithoutOrganizationInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutOrganizationInput>;
  SequenceSteps?: InputMaybe<SequenceStepUpdateManyWithoutOrganizationInput>;
  set_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
  short_resting?: InputMaybe<IntFieldUpdateOperationsInput>;
  show_calendar_options?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_contract_duration_sales?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_lead_source_to_rep?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_nocontact_button?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_payment_terms_sales?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_rep_all_leads?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_rep_my_leads?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_system_view?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sites?: InputMaybe<SiteUpdateManyWithoutOrganizationInput>;
  sms_opt_in_dialog?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SMSItems?: InputMaybe<SmsItemUpdateManyWithoutOrganizationInput>;
  SnoozeTrackings?: InputMaybe<SnoozeTrackingUpdateManyWithoutOrganizationInput>;
  StepActionLogs?: InputMaybe<StepActionLogUpdateManyWithoutOrganizationInput>;
  StepActions?: InputMaybe<StepActionUpdateManyWithoutOrganizationInput>;
  StepConditions?: InputMaybe<StepConditionUpdateManyWithoutOrganizationInput>;
  teams?: InputMaybe<TeamUpdateManyWithoutOrganizationInput>;
  Template?: InputMaybe<TemplateUpdateManyWithoutOrganizationInput>;
  TemplateVersions?: InputMaybe<TemplateVersionUpdateManyWithoutOrganizationInput>;
  timekit_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timezone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transfer_timeout?: InputMaybe<IntFieldUpdateOperationsInput>;
  TransferAttempts?: InputMaybe<TransferAttemptUpdateManyWithoutOrganizationInput>;
  twilio_numbers?: InputMaybe<TwilioNumberUpdateManyWithoutOrganizationInput>;
  TwilioFeedbacks?: InputMaybe<TwilioFeedbackUpdateManyWithoutOrganizationInput>;
  TwilioInfo?: InputMaybe<TwilioInfoUpdateOneWithoutOrganzationInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  use_revenue_planning?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  UserCustomFieldFilters?: InputMaybe<UserCustomFieldFilterUpdateManyWithoutOrganizationInput>;
  users?: InputMaybe<UserUpdateManyWithoutOrganizationInput>;
  win_rep_day_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
};

export type OrganizationUpdateWithoutAvailablitiesInput = {
  ActivityMonitorMultiplier?: InputMaybe<ActivityMonitorMultiplierUpdateManyWithoutOrganizationInput>;
  algoFactor?: InputMaybe<AlgoFactorUpdateManyWithoutOrganizationInput>;
  allow_calendar_invite?: InputMaybe<BoolFieldUpdateOperationsInput>;
  allow_remove_step_action?: InputMaybe<BoolFieldUpdateOperationsInput>;
  allow_snooze_step_action?: InputMaybe<BoolFieldUpdateOperationsInput>;
  always_record?: InputMaybe<BoolFieldUpdateOperationsInput>;
  arv_initial_held_demos_generated_by_sdr_weight?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  AssociationHistories?: InputMaybe<AssociationHistoryUpdateManyWithoutOrganizationInput>;
  auto_call_interval?: InputMaybe<IntFieldUpdateOperationsInput>;
  auto_dial?: InputMaybe<BoolFieldUpdateOperationsInput>;
  automated_campaigns?: InputMaybe<AutomatedCampaignUpdateManyWithoutOrganizationInput>;
  automatic_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  AutoTransferSelections?: InputMaybe<AutoTransferSelectionUpdateManyWithoutOrganizationInput>;
  AverageOrderValue?: InputMaybe<AverageOrderValueUpdateManyWithoutOrganizationInput>;
  best_action_ever_multiplier?: InputMaybe<FloatFieldUpdateOperationsInput>;
  best_metrics?: InputMaybe<BestMetricRecordUpdateManyWithoutOrganizationInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptUpdateManyWithoutOrganizationInput>;
  callMeNows?: InputMaybe<CallMeNowUpdateManyWithoutOrganizationInput>;
  CallMultiplier?: InputMaybe<CallMultiplierUpdateManyWithoutOrganizationInput>;
  CallSharings?: InputMaybe<CallSharingUpdateManyWithoutOrganizationInput>;
  can_edit_sdr_transfer_rules?: InputMaybe<BoolFieldUpdateOperationsInput>;
  claim_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  concession?: InputMaybe<ConcessionUpdateManyWithoutOrganizationInput>;
  conference_region?: InputMaybe<EnumConferenceRegionFieldUpdateOperationsInput>;
  Conferences?: InputMaybe<ConferenceUpdateManyWithoutOrganizationInput>;
  ContactRequests?: InputMaybe<ContactRequestUpdateManyWithoutOrganizationInput>;
  Contacts?: InputMaybe<ContactUpdateManyWithoutOrganizationInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  custom_fields?: InputMaybe<CustomFieldUpdateManyWithoutOrganizationInput>;
  CustomObject?: InputMaybe<CustomObjectUpdateManyWithoutOrganizationInput>;
  CustomObjectField?: InputMaybe<CustomObjectFieldUpdateManyWithoutOrganizationInput>;
  CustomObjectFieldForeignKey?: InputMaybe<CustomObjectFieldForeignKeyUpdateManyWithoutOrganizationInput>;
  CustomObjectRecord?: InputMaybe<CustomObjectRecordUpdateManyWithoutOrganizationInput>;
  CustomObjectRow?: InputMaybe<CustomObjectRowUpdateManyWithoutOrganizationInput>;
  CustomQueueItems?: InputMaybe<CustomQueueItemUpdateManyWithoutOrganizationInput>;
  DashboardColumnOptions?: InputMaybe<DashboardColumnOptionUpdateManyWithoutOrganizationInput>;
  DashboardLeadFilters?: InputMaybe<DashboardLeadFilterUpdateManyWithoutOrganizationInput>;
  DashboardRepFilters?: InputMaybe<DashboardRepFilterUpdateManyWithoutOrganizationInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewUpdateManyWithoutOrganizationInput>;
  day_to_close?: InputMaybe<IntFieldUpdateOperationsInput>;
  default_demo_time_duration?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  demo_held_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_not_held_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_not_held_call_back_short_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_prep_time_minutes?: InputMaybe<IntFieldUpdateOperationsInput>;
  dev_account?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  dial_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  DispositionMultiplier?: InputMaybe<DispositionMultiplierUpdateManyWithoutOrganizationInput>;
  dispositions?: InputMaybe<DispositionUpdateManyWithoutOrganizationInput>;
  double_booking?: InputMaybe<BoolFieldUpdateOperationsInput>;
  dynamic_date_operators?: InputMaybe<BoolFieldUpdateOperationsInput>;
  edge_servers?: InputMaybe<OrganizationUpdateedge_ServersInput>;
  email_conflict_notification_email_list?: InputMaybe<OrganizationUpdateemail_Conflict_Notification_Email_ListInput>;
  EmailItems?: InputMaybe<EmailItemUpdateManyWithoutOrganizationInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordUpdateManyWithoutOrganizationInput>;
  EmailThreads?: InputMaybe<EmailThreadUpdateManyWithoutOrganizationInput>;
  enable_modified_metrics?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  enable_pay_period_view?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  enable_time_constraint?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  error_log_email_address?: InputMaybe<OrganizationUpdateerror_Log_Email_AddressInput>;
  estimated_average_num_of_weekly_overbooked_demo_slots?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  FolderPermissions?: InputMaybe<FolderPermissionUpdateManyWithoutOrganizationInput>;
  Folders?: InputMaybe<FolderUpdateManyWithoutOrganizationInput>;
  FutureBookingSetting?: InputMaybe<FutureBookingSettingUpdateOneWithoutOrganizationInput>;
  GlobalTransferRule?: InputMaybe<GlobalTransferRuleUpdateManyWithoutOrganizationInput>;
  held_demos_per_cc_connect?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  helpful_links?: InputMaybe<OrganizationUpdatehelpful_LinksInput>;
  HingePointMultiplier?: InputMaybe<HingePointMultiplierUpdateManyWithoutOrganizationInput>;
  hold_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
  hubspot_credential?: InputMaybe<HubSpotCredentialUpdateOneWithoutOrganizationInput>;
  hubspot_last_sync_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  hubspot_white_list?: InputMaybe<HubSpotWhitelistUpdateManyWithoutOrganizationInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inbound_close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_dm_connect_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_lt_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_revenue_value_of_sale?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_scheduled_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_set_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventUpdateManyWithoutOrganizationInput>;
  InboundConciergeWidget?: InputMaybe<InboundConciergeWidgetUpdateManyWithoutOrganizationInput>;
  industry_options?: InputMaybe<IndustryOptionUpdateManyWithoutOrganizationInput>;
  initial_sdr_sets_reps_day?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  integration_error_logs?: InputMaybe<IntegrationErrorLogUpdateManyWithoutOrganizationInput>;
  integration_mappings?: InputMaybe<IntegrationMappingUpdateManyWithoutOrganizationInput>;
  IntegrationMappingCustomObject?: InputMaybe<IntegrationMappingCustomObjectUpdateManyWithoutOrganizationInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogUpdateManyWithoutOrganizationInput>;
  IntegrationReadOnlyObject?: InputMaybe<IntegrationReadOnlyObjectUpdateManyWithoutOrganizationInput>;
  international?: InputMaybe<BoolFieldUpdateOperationsInput>;
  is_email_domain_verified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  is_salesforce_sandbox?: InputMaybe<BoolFieldUpdateOperationsInput>;
  last_lead_value_compute_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutOrganizationInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryUpdateManyWithoutOrganizationInput>;
  lead_intent?: InputMaybe<LeadIntentUpdateManyWithoutOrganizationInput>;
  lead_release_same_rep_delay_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  lead_routing_email_list?: InputMaybe<OrganizationUpdatelead_Routing_Email_ListInput>;
  lead_routing_rules?: InputMaybe<RuleUpdateManyWithoutOrganizationInput>;
  LeadCustomObjectRowAssociation?: InputMaybe<LeadCustomObjectRowAssociationUpdateManyWithoutOrganizationInput>;
  LeadFieldMultiplier?: InputMaybe<LeadFieldMultiplierUpdateManyWithoutOrganizationInput>;
  LeadFieldMultiplierCustom?: InputMaybe<LeadFieldMultiplierCustomUpdateManyWithoutOrganizationInput>;
  leads?: InputMaybe<LeadUpdateManyWithoutOrganizationInput>;
  leadsource_options?: InputMaybe<LeadSourceOptionUpdateManyWithoutOrganizationInput>;
  LeadValue?: InputMaybe<LeadValueUpdateManyWithoutOrganizationInput>;
  LifeTimeValueMultiplier?: InputMaybe<LifeTimeValueMultiplierUpdateManyWithoutOrganizationInput>;
  LiveTransferSetting?: InputMaybe<LiveTransferSettingUpdateOneWithoutOrganizationInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusUpdateManyWithoutOrganizationInput>;
  ltv_multiplier_custom_fields?: InputMaybe<LifeTimeValueMultiplierCustomUpdateManyWithoutOrganizationInput>;
  made_contact_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  make_sale_address_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_business_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_city_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_country_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_first_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_industry_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_last_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_lead_source_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_primary_email_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_primary_phone_number_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_state_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_sub_industry_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_zip_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  maxium_day_lag_set_to_schedule?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  mrr?: InputMaybe<IntFieldUpdateOperationsInput>;
  mrr_label?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  mrr_required_on_sale?: InputMaybe<BoolFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  NoteItems?: InputMaybe<NoteItemUpdateManyWithoutOrganizationInput>;
  Notifications?: InputMaybe<NotificationUpdateManyWithoutOrganizationInput>;
  onboardComplete?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  Opportunity?: InputMaybe<SfOpportunityUpdateManyWithoutOrganizationInput>;
  organization_holidays?: InputMaybe<OrganizationHolidayUpdateManyWithoutOrganizationInput>;
  organization_hours?: InputMaybe<OrganizationHourUpdateManyWithoutOrganizationInput>;
  OrganizationPaymentSetting?: InputMaybe<OrganizationPaymentSettingsUpdateOneWithoutOrganizationInput>;
  OrganizationPaymentSettings?: InputMaybe<OrganizationPaymentSettingsUpdateOneWithoutOrganizationsInput>;
  orgCalendarInviteMetadata?: InputMaybe<OrgCalendarInviteMetadataUpdateManyWithoutOrganizationInput>;
  OrgDefaultPipelinePerecentValue?: InputMaybe<OrgDefaultPipelinePerecentValueUpdateOneWithoutOrganizationInput>;
  OrgHubSpotSync?: InputMaybe<OrgHubSpotSyncUpdateOneWithoutOrganizationInput>;
  OrgNoEventDate?: InputMaybe<OrgNoEventDateUpdateManyWithoutOrganizationInput>;
  OrgSalesforceSync?: InputMaybe<OrgSalesforceSyncUpdateOneWithoutOrganizationInput>;
  OrgTimeslotAvailability?: InputMaybe<OrgTimeslotAvailabilityUpdateManyWithoutOrganizationInput>;
  outbound_close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_dm_connect_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_lt_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_revenue_value_of_sale?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_scheduled_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_set_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  PandadocData?: InputMaybe<PandadocDataUpdateOneWithoutOrganizationInput>;
  PandaDocTemplate?: InputMaybe<PandaDocTemplateUpdateManyWithoutOrganizationInput>;
  participant_jitter_buffer_size?: InputMaybe<EnumJitterBufferSizeFieldUpdateOperationsInput>;
  preferred_goal_setting?: InputMaybe<NullableEnumGoalsettingFieldUpdateOperationsInput>;
  prepayment?: InputMaybe<BoolFieldUpdateOperationsInput>;
  ProductChangeLog?: InputMaybe<ProductChangeLogUpdateManyWithoutOrganizationInput>;
  products?: InputMaybe<ProductUpdateManyWithoutOrganizationInput>;
  re_cold_call_hour_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  recordingStatus?: InputMaybe<EnumRecordingStatusFieldUpdateOperationsInput>;
  release_lead_resting?: InputMaybe<IntFieldUpdateOperationsInput>;
  rep_claim_limit?: InputMaybe<IntFieldUpdateOperationsInput>;
  rep_lead_allotment?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  rep_level_call_transfer?: InputMaybe<BoolFieldUpdateOperationsInput>;
  reps_can_bulk_unassign?: InputMaybe<BoolFieldUpdateOperationsInput>;
  reps_can_view_all_notes?: InputMaybe<BoolFieldUpdateOperationsInput>;
  require_sale_notes?: InputMaybe<BoolFieldUpdateOperationsInput>;
  resting_after_skipped_CC?: InputMaybe<IntFieldUpdateOperationsInput>;
  resting_period?: InputMaybe<IntFieldUpdateOperationsInput>;
  resting_period_after_unsuccessful_cold_call?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  resting_period_after_unsuccessful_demo?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  revenue_plan?: InputMaybe<RevenuePlanItemUpdateManyWithoutOrganizationInput>;
  Routings?: InputMaybe<RoutingUpdateManyWithoutOrganizationInput>;
  sale_field_options?: InputMaybe<SaleFieldOptionUpdateManyWithoutOrganizationInput>;
  sale_notification_email_list?: InputMaybe<OrganizationUpdatesale_Notification_Email_ListInput>;
  SaleConfig?: InputMaybe<SaleConfigUpdateOneWithoutOrganizationInput>;
  SaleConfigPage?: InputMaybe<SaleConfigPageUpdateManyWithoutOrganizationInput>;
  SaleConfigSection?: InputMaybe<SaleConfigSectionUpdateManyWithoutOrganizationInput>;
  SaleCycles?: InputMaybe<SaleCycleUpdateManyWithoutOrganizationInput>;
  SaleItems?: InputMaybe<SaleItemUpdateManyWithoutOrganizationInput>;
  sales?: InputMaybe<SaleUpdateManyWithoutOrganizationInput>;
  salesforce_domain?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  salesforce_last_sync_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  SalesForceColumn?: InputMaybe<SalesForceColumnUpdateManyWithoutOrganizationInput>;
  salesForceData?: InputMaybe<SalesForceDataUpdateOneWithoutOrganizationInput>;
  ScheduleItems?: InputMaybe<ScheduleItemUpdateManyWithoutOrganizationInput>;
  self_sourcing_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  send_error_log_email?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  send_lead_routing_email?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sendgrid_email_domain?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sendgrid_email_domain_id?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  sequence?: InputMaybe<SequenceUpdateManyWithoutOrganzationInput>;
  SequenceCriteriaConditions?: InputMaybe<SequenceCriteriaConditionUpdateManyWithoutOrganizationInput>;
  SequenceDashboardViews?: InputMaybe<SequenceDashboardViewUpdateManyWithoutOrganizationInput>;
  SequenceEntryCriterias?: InputMaybe<SequenceEntryCriteriaUpdateManyWithoutOrganizationInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutOrganizationInput>;
  SequenceSteps?: InputMaybe<SequenceStepUpdateManyWithoutOrganizationInput>;
  set_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
  short_resting?: InputMaybe<IntFieldUpdateOperationsInput>;
  show_calendar_options?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_contract_duration_sales?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_lead_source_to_rep?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_nocontact_button?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_payment_terms_sales?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_rep_all_leads?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_rep_my_leads?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_system_view?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sites?: InputMaybe<SiteUpdateManyWithoutOrganizationInput>;
  sms_opt_in_dialog?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SMSItems?: InputMaybe<SmsItemUpdateManyWithoutOrganizationInput>;
  SnoozeTrackings?: InputMaybe<SnoozeTrackingUpdateManyWithoutOrganizationInput>;
  StepActionLogs?: InputMaybe<StepActionLogUpdateManyWithoutOrganizationInput>;
  StepActions?: InputMaybe<StepActionUpdateManyWithoutOrganizationInput>;
  StepConditions?: InputMaybe<StepConditionUpdateManyWithoutOrganizationInput>;
  teams?: InputMaybe<TeamUpdateManyWithoutOrganizationInput>;
  Template?: InputMaybe<TemplateUpdateManyWithoutOrganizationInput>;
  TemplateVersions?: InputMaybe<TemplateVersionUpdateManyWithoutOrganizationInput>;
  timekit_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timezone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transfer_timeout?: InputMaybe<IntFieldUpdateOperationsInput>;
  TransferAttempts?: InputMaybe<TransferAttemptUpdateManyWithoutOrganizationInput>;
  twilio_numbers?: InputMaybe<TwilioNumberUpdateManyWithoutOrganizationInput>;
  TwilioFeedbacks?: InputMaybe<TwilioFeedbackUpdateManyWithoutOrganizationInput>;
  TwilioInfo?: InputMaybe<TwilioInfoUpdateOneWithoutOrganzationInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  use_revenue_planning?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  UserCustomFieldFilters?: InputMaybe<UserCustomFieldFilterUpdateManyWithoutOrganizationInput>;
  users?: InputMaybe<UserUpdateManyWithoutOrganizationInput>;
  win_rep_day_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
};

export type OrganizationUpdateWithoutBest_MetricsInput = {
  ActivityMonitorMultiplier?: InputMaybe<ActivityMonitorMultiplierUpdateManyWithoutOrganizationInput>;
  algoFactor?: InputMaybe<AlgoFactorUpdateManyWithoutOrganizationInput>;
  allow_calendar_invite?: InputMaybe<BoolFieldUpdateOperationsInput>;
  allow_remove_step_action?: InputMaybe<BoolFieldUpdateOperationsInput>;
  allow_snooze_step_action?: InputMaybe<BoolFieldUpdateOperationsInput>;
  always_record?: InputMaybe<BoolFieldUpdateOperationsInput>;
  arv_initial_held_demos_generated_by_sdr_weight?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  AssociationHistories?: InputMaybe<AssociationHistoryUpdateManyWithoutOrganizationInput>;
  auto_call_interval?: InputMaybe<IntFieldUpdateOperationsInput>;
  auto_dial?: InputMaybe<BoolFieldUpdateOperationsInput>;
  automated_campaigns?: InputMaybe<AutomatedCampaignUpdateManyWithoutOrganizationInput>;
  automatic_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  AutoTransferSelections?: InputMaybe<AutoTransferSelectionUpdateManyWithoutOrganizationInput>;
  Availablities?: InputMaybe<AvailabilityUpdateManyWithoutOrganizationInput>;
  AverageOrderValue?: InputMaybe<AverageOrderValueUpdateManyWithoutOrganizationInput>;
  best_action_ever_multiplier?: InputMaybe<FloatFieldUpdateOperationsInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptUpdateManyWithoutOrganizationInput>;
  callMeNows?: InputMaybe<CallMeNowUpdateManyWithoutOrganizationInput>;
  CallMultiplier?: InputMaybe<CallMultiplierUpdateManyWithoutOrganizationInput>;
  CallSharings?: InputMaybe<CallSharingUpdateManyWithoutOrganizationInput>;
  can_edit_sdr_transfer_rules?: InputMaybe<BoolFieldUpdateOperationsInput>;
  claim_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  concession?: InputMaybe<ConcessionUpdateManyWithoutOrganizationInput>;
  conference_region?: InputMaybe<EnumConferenceRegionFieldUpdateOperationsInput>;
  Conferences?: InputMaybe<ConferenceUpdateManyWithoutOrganizationInput>;
  ContactRequests?: InputMaybe<ContactRequestUpdateManyWithoutOrganizationInput>;
  Contacts?: InputMaybe<ContactUpdateManyWithoutOrganizationInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  custom_fields?: InputMaybe<CustomFieldUpdateManyWithoutOrganizationInput>;
  CustomObject?: InputMaybe<CustomObjectUpdateManyWithoutOrganizationInput>;
  CustomObjectField?: InputMaybe<CustomObjectFieldUpdateManyWithoutOrganizationInput>;
  CustomObjectFieldForeignKey?: InputMaybe<CustomObjectFieldForeignKeyUpdateManyWithoutOrganizationInput>;
  CustomObjectRecord?: InputMaybe<CustomObjectRecordUpdateManyWithoutOrganizationInput>;
  CustomObjectRow?: InputMaybe<CustomObjectRowUpdateManyWithoutOrganizationInput>;
  CustomQueueItems?: InputMaybe<CustomQueueItemUpdateManyWithoutOrganizationInput>;
  DashboardColumnOptions?: InputMaybe<DashboardColumnOptionUpdateManyWithoutOrganizationInput>;
  DashboardLeadFilters?: InputMaybe<DashboardLeadFilterUpdateManyWithoutOrganizationInput>;
  DashboardRepFilters?: InputMaybe<DashboardRepFilterUpdateManyWithoutOrganizationInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewUpdateManyWithoutOrganizationInput>;
  day_to_close?: InputMaybe<IntFieldUpdateOperationsInput>;
  default_demo_time_duration?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  demo_held_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_not_held_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_not_held_call_back_short_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_prep_time_minutes?: InputMaybe<IntFieldUpdateOperationsInput>;
  dev_account?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  dial_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  DispositionMultiplier?: InputMaybe<DispositionMultiplierUpdateManyWithoutOrganizationInput>;
  dispositions?: InputMaybe<DispositionUpdateManyWithoutOrganizationInput>;
  double_booking?: InputMaybe<BoolFieldUpdateOperationsInput>;
  dynamic_date_operators?: InputMaybe<BoolFieldUpdateOperationsInput>;
  edge_servers?: InputMaybe<OrganizationUpdateedge_ServersInput>;
  email_conflict_notification_email_list?: InputMaybe<OrganizationUpdateemail_Conflict_Notification_Email_ListInput>;
  EmailItems?: InputMaybe<EmailItemUpdateManyWithoutOrganizationInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordUpdateManyWithoutOrganizationInput>;
  EmailThreads?: InputMaybe<EmailThreadUpdateManyWithoutOrganizationInput>;
  enable_modified_metrics?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  enable_pay_period_view?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  enable_time_constraint?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  error_log_email_address?: InputMaybe<OrganizationUpdateerror_Log_Email_AddressInput>;
  estimated_average_num_of_weekly_overbooked_demo_slots?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  FolderPermissions?: InputMaybe<FolderPermissionUpdateManyWithoutOrganizationInput>;
  Folders?: InputMaybe<FolderUpdateManyWithoutOrganizationInput>;
  FutureBookingSetting?: InputMaybe<FutureBookingSettingUpdateOneWithoutOrganizationInput>;
  GlobalTransferRule?: InputMaybe<GlobalTransferRuleUpdateManyWithoutOrganizationInput>;
  held_demos_per_cc_connect?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  helpful_links?: InputMaybe<OrganizationUpdatehelpful_LinksInput>;
  HingePointMultiplier?: InputMaybe<HingePointMultiplierUpdateManyWithoutOrganizationInput>;
  hold_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
  hubspot_credential?: InputMaybe<HubSpotCredentialUpdateOneWithoutOrganizationInput>;
  hubspot_last_sync_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  hubspot_white_list?: InputMaybe<HubSpotWhitelistUpdateManyWithoutOrganizationInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inbound_close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_dm_connect_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_lt_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_revenue_value_of_sale?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_scheduled_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_set_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventUpdateManyWithoutOrganizationInput>;
  InboundConciergeWidget?: InputMaybe<InboundConciergeWidgetUpdateManyWithoutOrganizationInput>;
  industry_options?: InputMaybe<IndustryOptionUpdateManyWithoutOrganizationInput>;
  initial_sdr_sets_reps_day?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  integration_error_logs?: InputMaybe<IntegrationErrorLogUpdateManyWithoutOrganizationInput>;
  integration_mappings?: InputMaybe<IntegrationMappingUpdateManyWithoutOrganizationInput>;
  IntegrationMappingCustomObject?: InputMaybe<IntegrationMappingCustomObjectUpdateManyWithoutOrganizationInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogUpdateManyWithoutOrganizationInput>;
  IntegrationReadOnlyObject?: InputMaybe<IntegrationReadOnlyObjectUpdateManyWithoutOrganizationInput>;
  international?: InputMaybe<BoolFieldUpdateOperationsInput>;
  is_email_domain_verified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  is_salesforce_sandbox?: InputMaybe<BoolFieldUpdateOperationsInput>;
  last_lead_value_compute_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutOrganizationInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryUpdateManyWithoutOrganizationInput>;
  lead_intent?: InputMaybe<LeadIntentUpdateManyWithoutOrganizationInput>;
  lead_release_same_rep_delay_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  lead_routing_email_list?: InputMaybe<OrganizationUpdatelead_Routing_Email_ListInput>;
  lead_routing_rules?: InputMaybe<RuleUpdateManyWithoutOrganizationInput>;
  LeadCustomObjectRowAssociation?: InputMaybe<LeadCustomObjectRowAssociationUpdateManyWithoutOrganizationInput>;
  LeadFieldMultiplier?: InputMaybe<LeadFieldMultiplierUpdateManyWithoutOrganizationInput>;
  LeadFieldMultiplierCustom?: InputMaybe<LeadFieldMultiplierCustomUpdateManyWithoutOrganizationInput>;
  leads?: InputMaybe<LeadUpdateManyWithoutOrganizationInput>;
  leadsource_options?: InputMaybe<LeadSourceOptionUpdateManyWithoutOrganizationInput>;
  LeadValue?: InputMaybe<LeadValueUpdateManyWithoutOrganizationInput>;
  LifeTimeValueMultiplier?: InputMaybe<LifeTimeValueMultiplierUpdateManyWithoutOrganizationInput>;
  LiveTransferSetting?: InputMaybe<LiveTransferSettingUpdateOneWithoutOrganizationInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusUpdateManyWithoutOrganizationInput>;
  ltv_multiplier_custom_fields?: InputMaybe<LifeTimeValueMultiplierCustomUpdateManyWithoutOrganizationInput>;
  made_contact_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  make_sale_address_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_business_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_city_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_country_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_first_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_industry_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_last_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_lead_source_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_primary_email_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_primary_phone_number_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_state_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_sub_industry_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_zip_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  maxium_day_lag_set_to_schedule?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  mrr?: InputMaybe<IntFieldUpdateOperationsInput>;
  mrr_label?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  mrr_required_on_sale?: InputMaybe<BoolFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  NoteItems?: InputMaybe<NoteItemUpdateManyWithoutOrganizationInput>;
  Notifications?: InputMaybe<NotificationUpdateManyWithoutOrganizationInput>;
  onboardComplete?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  Opportunity?: InputMaybe<SfOpportunityUpdateManyWithoutOrganizationInput>;
  organization_holidays?: InputMaybe<OrganizationHolidayUpdateManyWithoutOrganizationInput>;
  organization_hours?: InputMaybe<OrganizationHourUpdateManyWithoutOrganizationInput>;
  OrganizationPaymentSetting?: InputMaybe<OrganizationPaymentSettingsUpdateOneWithoutOrganizationInput>;
  OrganizationPaymentSettings?: InputMaybe<OrganizationPaymentSettingsUpdateOneWithoutOrganizationsInput>;
  orgCalendarInviteMetadata?: InputMaybe<OrgCalendarInviteMetadataUpdateManyWithoutOrganizationInput>;
  OrgDefaultPipelinePerecentValue?: InputMaybe<OrgDefaultPipelinePerecentValueUpdateOneWithoutOrganizationInput>;
  OrgHubSpotSync?: InputMaybe<OrgHubSpotSyncUpdateOneWithoutOrganizationInput>;
  OrgNoEventDate?: InputMaybe<OrgNoEventDateUpdateManyWithoutOrganizationInput>;
  OrgSalesforceSync?: InputMaybe<OrgSalesforceSyncUpdateOneWithoutOrganizationInput>;
  OrgTimeslotAvailability?: InputMaybe<OrgTimeslotAvailabilityUpdateManyWithoutOrganizationInput>;
  outbound_close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_dm_connect_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_lt_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_revenue_value_of_sale?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_scheduled_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_set_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  PandadocData?: InputMaybe<PandadocDataUpdateOneWithoutOrganizationInput>;
  PandaDocTemplate?: InputMaybe<PandaDocTemplateUpdateManyWithoutOrganizationInput>;
  participant_jitter_buffer_size?: InputMaybe<EnumJitterBufferSizeFieldUpdateOperationsInput>;
  preferred_goal_setting?: InputMaybe<NullableEnumGoalsettingFieldUpdateOperationsInput>;
  prepayment?: InputMaybe<BoolFieldUpdateOperationsInput>;
  ProductChangeLog?: InputMaybe<ProductChangeLogUpdateManyWithoutOrganizationInput>;
  products?: InputMaybe<ProductUpdateManyWithoutOrganizationInput>;
  re_cold_call_hour_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  recordingStatus?: InputMaybe<EnumRecordingStatusFieldUpdateOperationsInput>;
  release_lead_resting?: InputMaybe<IntFieldUpdateOperationsInput>;
  rep_claim_limit?: InputMaybe<IntFieldUpdateOperationsInput>;
  rep_lead_allotment?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  rep_level_call_transfer?: InputMaybe<BoolFieldUpdateOperationsInput>;
  reps_can_bulk_unassign?: InputMaybe<BoolFieldUpdateOperationsInput>;
  reps_can_view_all_notes?: InputMaybe<BoolFieldUpdateOperationsInput>;
  require_sale_notes?: InputMaybe<BoolFieldUpdateOperationsInput>;
  resting_after_skipped_CC?: InputMaybe<IntFieldUpdateOperationsInput>;
  resting_period?: InputMaybe<IntFieldUpdateOperationsInput>;
  resting_period_after_unsuccessful_cold_call?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  resting_period_after_unsuccessful_demo?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  revenue_plan?: InputMaybe<RevenuePlanItemUpdateManyWithoutOrganizationInput>;
  Routings?: InputMaybe<RoutingUpdateManyWithoutOrganizationInput>;
  sale_field_options?: InputMaybe<SaleFieldOptionUpdateManyWithoutOrganizationInput>;
  sale_notification_email_list?: InputMaybe<OrganizationUpdatesale_Notification_Email_ListInput>;
  SaleConfig?: InputMaybe<SaleConfigUpdateOneWithoutOrganizationInput>;
  SaleConfigPage?: InputMaybe<SaleConfigPageUpdateManyWithoutOrganizationInput>;
  SaleConfigSection?: InputMaybe<SaleConfigSectionUpdateManyWithoutOrganizationInput>;
  SaleCycles?: InputMaybe<SaleCycleUpdateManyWithoutOrganizationInput>;
  SaleItems?: InputMaybe<SaleItemUpdateManyWithoutOrganizationInput>;
  sales?: InputMaybe<SaleUpdateManyWithoutOrganizationInput>;
  salesforce_domain?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  salesforce_last_sync_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  SalesForceColumn?: InputMaybe<SalesForceColumnUpdateManyWithoutOrganizationInput>;
  salesForceData?: InputMaybe<SalesForceDataUpdateOneWithoutOrganizationInput>;
  ScheduleItems?: InputMaybe<ScheduleItemUpdateManyWithoutOrganizationInput>;
  self_sourcing_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  send_error_log_email?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  send_lead_routing_email?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sendgrid_email_domain?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sendgrid_email_domain_id?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  sequence?: InputMaybe<SequenceUpdateManyWithoutOrganzationInput>;
  SequenceCriteriaConditions?: InputMaybe<SequenceCriteriaConditionUpdateManyWithoutOrganizationInput>;
  SequenceDashboardViews?: InputMaybe<SequenceDashboardViewUpdateManyWithoutOrganizationInput>;
  SequenceEntryCriterias?: InputMaybe<SequenceEntryCriteriaUpdateManyWithoutOrganizationInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutOrganizationInput>;
  SequenceSteps?: InputMaybe<SequenceStepUpdateManyWithoutOrganizationInput>;
  set_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
  short_resting?: InputMaybe<IntFieldUpdateOperationsInput>;
  show_calendar_options?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_contract_duration_sales?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_lead_source_to_rep?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_nocontact_button?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_payment_terms_sales?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_rep_all_leads?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_rep_my_leads?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_system_view?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sites?: InputMaybe<SiteUpdateManyWithoutOrganizationInput>;
  sms_opt_in_dialog?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SMSItems?: InputMaybe<SmsItemUpdateManyWithoutOrganizationInput>;
  SnoozeTrackings?: InputMaybe<SnoozeTrackingUpdateManyWithoutOrganizationInput>;
  StepActionLogs?: InputMaybe<StepActionLogUpdateManyWithoutOrganizationInput>;
  StepActions?: InputMaybe<StepActionUpdateManyWithoutOrganizationInput>;
  StepConditions?: InputMaybe<StepConditionUpdateManyWithoutOrganizationInput>;
  teams?: InputMaybe<TeamUpdateManyWithoutOrganizationInput>;
  Template?: InputMaybe<TemplateUpdateManyWithoutOrganizationInput>;
  TemplateVersions?: InputMaybe<TemplateVersionUpdateManyWithoutOrganizationInput>;
  timekit_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timezone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transfer_timeout?: InputMaybe<IntFieldUpdateOperationsInput>;
  TransferAttempts?: InputMaybe<TransferAttemptUpdateManyWithoutOrganizationInput>;
  twilio_numbers?: InputMaybe<TwilioNumberUpdateManyWithoutOrganizationInput>;
  TwilioFeedbacks?: InputMaybe<TwilioFeedbackUpdateManyWithoutOrganizationInput>;
  TwilioInfo?: InputMaybe<TwilioInfoUpdateOneWithoutOrganzationInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  use_revenue_planning?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  UserCustomFieldFilters?: InputMaybe<UserCustomFieldFilterUpdateManyWithoutOrganizationInput>;
  users?: InputMaybe<UserUpdateManyWithoutOrganizationInput>;
  win_rep_day_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
};

export type OrganizationUpdateWithoutCallMeNowAttemptsInput = {
  ActivityMonitorMultiplier?: InputMaybe<ActivityMonitorMultiplierUpdateManyWithoutOrganizationInput>;
  algoFactor?: InputMaybe<AlgoFactorUpdateManyWithoutOrganizationInput>;
  allow_calendar_invite?: InputMaybe<BoolFieldUpdateOperationsInput>;
  allow_remove_step_action?: InputMaybe<BoolFieldUpdateOperationsInput>;
  allow_snooze_step_action?: InputMaybe<BoolFieldUpdateOperationsInput>;
  always_record?: InputMaybe<BoolFieldUpdateOperationsInput>;
  arv_initial_held_demos_generated_by_sdr_weight?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  AssociationHistories?: InputMaybe<AssociationHistoryUpdateManyWithoutOrganizationInput>;
  auto_call_interval?: InputMaybe<IntFieldUpdateOperationsInput>;
  auto_dial?: InputMaybe<BoolFieldUpdateOperationsInput>;
  automated_campaigns?: InputMaybe<AutomatedCampaignUpdateManyWithoutOrganizationInput>;
  automatic_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  AutoTransferSelections?: InputMaybe<AutoTransferSelectionUpdateManyWithoutOrganizationInput>;
  Availablities?: InputMaybe<AvailabilityUpdateManyWithoutOrganizationInput>;
  AverageOrderValue?: InputMaybe<AverageOrderValueUpdateManyWithoutOrganizationInput>;
  best_action_ever_multiplier?: InputMaybe<FloatFieldUpdateOperationsInput>;
  best_metrics?: InputMaybe<BestMetricRecordUpdateManyWithoutOrganizationInput>;
  callMeNows?: InputMaybe<CallMeNowUpdateManyWithoutOrganizationInput>;
  CallMultiplier?: InputMaybe<CallMultiplierUpdateManyWithoutOrganizationInput>;
  CallSharings?: InputMaybe<CallSharingUpdateManyWithoutOrganizationInput>;
  can_edit_sdr_transfer_rules?: InputMaybe<BoolFieldUpdateOperationsInput>;
  claim_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  concession?: InputMaybe<ConcessionUpdateManyWithoutOrganizationInput>;
  conference_region?: InputMaybe<EnumConferenceRegionFieldUpdateOperationsInput>;
  Conferences?: InputMaybe<ConferenceUpdateManyWithoutOrganizationInput>;
  ContactRequests?: InputMaybe<ContactRequestUpdateManyWithoutOrganizationInput>;
  Contacts?: InputMaybe<ContactUpdateManyWithoutOrganizationInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  custom_fields?: InputMaybe<CustomFieldUpdateManyWithoutOrganizationInput>;
  CustomObject?: InputMaybe<CustomObjectUpdateManyWithoutOrganizationInput>;
  CustomObjectField?: InputMaybe<CustomObjectFieldUpdateManyWithoutOrganizationInput>;
  CustomObjectFieldForeignKey?: InputMaybe<CustomObjectFieldForeignKeyUpdateManyWithoutOrganizationInput>;
  CustomObjectRecord?: InputMaybe<CustomObjectRecordUpdateManyWithoutOrganizationInput>;
  CustomObjectRow?: InputMaybe<CustomObjectRowUpdateManyWithoutOrganizationInput>;
  CustomQueueItems?: InputMaybe<CustomQueueItemUpdateManyWithoutOrganizationInput>;
  DashboardColumnOptions?: InputMaybe<DashboardColumnOptionUpdateManyWithoutOrganizationInput>;
  DashboardLeadFilters?: InputMaybe<DashboardLeadFilterUpdateManyWithoutOrganizationInput>;
  DashboardRepFilters?: InputMaybe<DashboardRepFilterUpdateManyWithoutOrganizationInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewUpdateManyWithoutOrganizationInput>;
  day_to_close?: InputMaybe<IntFieldUpdateOperationsInput>;
  default_demo_time_duration?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  demo_held_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_not_held_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_not_held_call_back_short_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_prep_time_minutes?: InputMaybe<IntFieldUpdateOperationsInput>;
  dev_account?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  dial_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  DispositionMultiplier?: InputMaybe<DispositionMultiplierUpdateManyWithoutOrganizationInput>;
  dispositions?: InputMaybe<DispositionUpdateManyWithoutOrganizationInput>;
  double_booking?: InputMaybe<BoolFieldUpdateOperationsInput>;
  dynamic_date_operators?: InputMaybe<BoolFieldUpdateOperationsInput>;
  edge_servers?: InputMaybe<OrganizationUpdateedge_ServersInput>;
  email_conflict_notification_email_list?: InputMaybe<OrganizationUpdateemail_Conflict_Notification_Email_ListInput>;
  EmailItems?: InputMaybe<EmailItemUpdateManyWithoutOrganizationInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordUpdateManyWithoutOrganizationInput>;
  EmailThreads?: InputMaybe<EmailThreadUpdateManyWithoutOrganizationInput>;
  enable_modified_metrics?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  enable_pay_period_view?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  enable_time_constraint?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  error_log_email_address?: InputMaybe<OrganizationUpdateerror_Log_Email_AddressInput>;
  estimated_average_num_of_weekly_overbooked_demo_slots?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  FolderPermissions?: InputMaybe<FolderPermissionUpdateManyWithoutOrganizationInput>;
  Folders?: InputMaybe<FolderUpdateManyWithoutOrganizationInput>;
  FutureBookingSetting?: InputMaybe<FutureBookingSettingUpdateOneWithoutOrganizationInput>;
  GlobalTransferRule?: InputMaybe<GlobalTransferRuleUpdateManyWithoutOrganizationInput>;
  held_demos_per_cc_connect?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  helpful_links?: InputMaybe<OrganizationUpdatehelpful_LinksInput>;
  HingePointMultiplier?: InputMaybe<HingePointMultiplierUpdateManyWithoutOrganizationInput>;
  hold_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
  hubspot_credential?: InputMaybe<HubSpotCredentialUpdateOneWithoutOrganizationInput>;
  hubspot_last_sync_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  hubspot_white_list?: InputMaybe<HubSpotWhitelistUpdateManyWithoutOrganizationInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inbound_close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_dm_connect_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_lt_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_revenue_value_of_sale?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_scheduled_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_set_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventUpdateManyWithoutOrganizationInput>;
  InboundConciergeWidget?: InputMaybe<InboundConciergeWidgetUpdateManyWithoutOrganizationInput>;
  industry_options?: InputMaybe<IndustryOptionUpdateManyWithoutOrganizationInput>;
  initial_sdr_sets_reps_day?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  integration_error_logs?: InputMaybe<IntegrationErrorLogUpdateManyWithoutOrganizationInput>;
  integration_mappings?: InputMaybe<IntegrationMappingUpdateManyWithoutOrganizationInput>;
  IntegrationMappingCustomObject?: InputMaybe<IntegrationMappingCustomObjectUpdateManyWithoutOrganizationInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogUpdateManyWithoutOrganizationInput>;
  IntegrationReadOnlyObject?: InputMaybe<IntegrationReadOnlyObjectUpdateManyWithoutOrganizationInput>;
  international?: InputMaybe<BoolFieldUpdateOperationsInput>;
  is_email_domain_verified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  is_salesforce_sandbox?: InputMaybe<BoolFieldUpdateOperationsInput>;
  last_lead_value_compute_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutOrganizationInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryUpdateManyWithoutOrganizationInput>;
  lead_intent?: InputMaybe<LeadIntentUpdateManyWithoutOrganizationInput>;
  lead_release_same_rep_delay_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  lead_routing_email_list?: InputMaybe<OrganizationUpdatelead_Routing_Email_ListInput>;
  lead_routing_rules?: InputMaybe<RuleUpdateManyWithoutOrganizationInput>;
  LeadCustomObjectRowAssociation?: InputMaybe<LeadCustomObjectRowAssociationUpdateManyWithoutOrganizationInput>;
  LeadFieldMultiplier?: InputMaybe<LeadFieldMultiplierUpdateManyWithoutOrganizationInput>;
  LeadFieldMultiplierCustom?: InputMaybe<LeadFieldMultiplierCustomUpdateManyWithoutOrganizationInput>;
  leads?: InputMaybe<LeadUpdateManyWithoutOrganizationInput>;
  leadsource_options?: InputMaybe<LeadSourceOptionUpdateManyWithoutOrganizationInput>;
  LeadValue?: InputMaybe<LeadValueUpdateManyWithoutOrganizationInput>;
  LifeTimeValueMultiplier?: InputMaybe<LifeTimeValueMultiplierUpdateManyWithoutOrganizationInput>;
  LiveTransferSetting?: InputMaybe<LiveTransferSettingUpdateOneWithoutOrganizationInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusUpdateManyWithoutOrganizationInput>;
  ltv_multiplier_custom_fields?: InputMaybe<LifeTimeValueMultiplierCustomUpdateManyWithoutOrganizationInput>;
  made_contact_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  make_sale_address_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_business_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_city_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_country_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_first_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_industry_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_last_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_lead_source_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_primary_email_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_primary_phone_number_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_state_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_sub_industry_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_zip_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  maxium_day_lag_set_to_schedule?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  mrr?: InputMaybe<IntFieldUpdateOperationsInput>;
  mrr_label?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  mrr_required_on_sale?: InputMaybe<BoolFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  NoteItems?: InputMaybe<NoteItemUpdateManyWithoutOrganizationInput>;
  Notifications?: InputMaybe<NotificationUpdateManyWithoutOrganizationInput>;
  onboardComplete?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  Opportunity?: InputMaybe<SfOpportunityUpdateManyWithoutOrganizationInput>;
  organization_holidays?: InputMaybe<OrganizationHolidayUpdateManyWithoutOrganizationInput>;
  organization_hours?: InputMaybe<OrganizationHourUpdateManyWithoutOrganizationInput>;
  OrganizationPaymentSetting?: InputMaybe<OrganizationPaymentSettingsUpdateOneWithoutOrganizationInput>;
  OrganizationPaymentSettings?: InputMaybe<OrganizationPaymentSettingsUpdateOneWithoutOrganizationsInput>;
  orgCalendarInviteMetadata?: InputMaybe<OrgCalendarInviteMetadataUpdateManyWithoutOrganizationInput>;
  OrgDefaultPipelinePerecentValue?: InputMaybe<OrgDefaultPipelinePerecentValueUpdateOneWithoutOrganizationInput>;
  OrgHubSpotSync?: InputMaybe<OrgHubSpotSyncUpdateOneWithoutOrganizationInput>;
  OrgNoEventDate?: InputMaybe<OrgNoEventDateUpdateManyWithoutOrganizationInput>;
  OrgSalesforceSync?: InputMaybe<OrgSalesforceSyncUpdateOneWithoutOrganizationInput>;
  OrgTimeslotAvailability?: InputMaybe<OrgTimeslotAvailabilityUpdateManyWithoutOrganizationInput>;
  outbound_close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_dm_connect_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_lt_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_revenue_value_of_sale?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_scheduled_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_set_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  PandadocData?: InputMaybe<PandadocDataUpdateOneWithoutOrganizationInput>;
  PandaDocTemplate?: InputMaybe<PandaDocTemplateUpdateManyWithoutOrganizationInput>;
  participant_jitter_buffer_size?: InputMaybe<EnumJitterBufferSizeFieldUpdateOperationsInput>;
  preferred_goal_setting?: InputMaybe<NullableEnumGoalsettingFieldUpdateOperationsInput>;
  prepayment?: InputMaybe<BoolFieldUpdateOperationsInput>;
  ProductChangeLog?: InputMaybe<ProductChangeLogUpdateManyWithoutOrganizationInput>;
  products?: InputMaybe<ProductUpdateManyWithoutOrganizationInput>;
  re_cold_call_hour_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  recordingStatus?: InputMaybe<EnumRecordingStatusFieldUpdateOperationsInput>;
  release_lead_resting?: InputMaybe<IntFieldUpdateOperationsInput>;
  rep_claim_limit?: InputMaybe<IntFieldUpdateOperationsInput>;
  rep_lead_allotment?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  rep_level_call_transfer?: InputMaybe<BoolFieldUpdateOperationsInput>;
  reps_can_bulk_unassign?: InputMaybe<BoolFieldUpdateOperationsInput>;
  reps_can_view_all_notes?: InputMaybe<BoolFieldUpdateOperationsInput>;
  require_sale_notes?: InputMaybe<BoolFieldUpdateOperationsInput>;
  resting_after_skipped_CC?: InputMaybe<IntFieldUpdateOperationsInput>;
  resting_period?: InputMaybe<IntFieldUpdateOperationsInput>;
  resting_period_after_unsuccessful_cold_call?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  resting_period_after_unsuccessful_demo?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  revenue_plan?: InputMaybe<RevenuePlanItemUpdateManyWithoutOrganizationInput>;
  Routings?: InputMaybe<RoutingUpdateManyWithoutOrganizationInput>;
  sale_field_options?: InputMaybe<SaleFieldOptionUpdateManyWithoutOrganizationInput>;
  sale_notification_email_list?: InputMaybe<OrganizationUpdatesale_Notification_Email_ListInput>;
  SaleConfig?: InputMaybe<SaleConfigUpdateOneWithoutOrganizationInput>;
  SaleConfigPage?: InputMaybe<SaleConfigPageUpdateManyWithoutOrganizationInput>;
  SaleConfigSection?: InputMaybe<SaleConfigSectionUpdateManyWithoutOrganizationInput>;
  SaleCycles?: InputMaybe<SaleCycleUpdateManyWithoutOrganizationInput>;
  SaleItems?: InputMaybe<SaleItemUpdateManyWithoutOrganizationInput>;
  sales?: InputMaybe<SaleUpdateManyWithoutOrganizationInput>;
  salesforce_domain?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  salesforce_last_sync_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  SalesForceColumn?: InputMaybe<SalesForceColumnUpdateManyWithoutOrganizationInput>;
  salesForceData?: InputMaybe<SalesForceDataUpdateOneWithoutOrganizationInput>;
  ScheduleItems?: InputMaybe<ScheduleItemUpdateManyWithoutOrganizationInput>;
  self_sourcing_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  send_error_log_email?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  send_lead_routing_email?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sendgrid_email_domain?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sendgrid_email_domain_id?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  sequence?: InputMaybe<SequenceUpdateManyWithoutOrganzationInput>;
  SequenceCriteriaConditions?: InputMaybe<SequenceCriteriaConditionUpdateManyWithoutOrganizationInput>;
  SequenceDashboardViews?: InputMaybe<SequenceDashboardViewUpdateManyWithoutOrganizationInput>;
  SequenceEntryCriterias?: InputMaybe<SequenceEntryCriteriaUpdateManyWithoutOrganizationInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutOrganizationInput>;
  SequenceSteps?: InputMaybe<SequenceStepUpdateManyWithoutOrganizationInput>;
  set_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
  short_resting?: InputMaybe<IntFieldUpdateOperationsInput>;
  show_calendar_options?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_contract_duration_sales?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_lead_source_to_rep?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_nocontact_button?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_payment_terms_sales?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_rep_all_leads?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_rep_my_leads?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_system_view?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sites?: InputMaybe<SiteUpdateManyWithoutOrganizationInput>;
  sms_opt_in_dialog?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SMSItems?: InputMaybe<SmsItemUpdateManyWithoutOrganizationInput>;
  SnoozeTrackings?: InputMaybe<SnoozeTrackingUpdateManyWithoutOrganizationInput>;
  StepActionLogs?: InputMaybe<StepActionLogUpdateManyWithoutOrganizationInput>;
  StepActions?: InputMaybe<StepActionUpdateManyWithoutOrganizationInput>;
  StepConditions?: InputMaybe<StepConditionUpdateManyWithoutOrganizationInput>;
  teams?: InputMaybe<TeamUpdateManyWithoutOrganizationInput>;
  Template?: InputMaybe<TemplateUpdateManyWithoutOrganizationInput>;
  TemplateVersions?: InputMaybe<TemplateVersionUpdateManyWithoutOrganizationInput>;
  timekit_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timezone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transfer_timeout?: InputMaybe<IntFieldUpdateOperationsInput>;
  TransferAttempts?: InputMaybe<TransferAttemptUpdateManyWithoutOrganizationInput>;
  twilio_numbers?: InputMaybe<TwilioNumberUpdateManyWithoutOrganizationInput>;
  TwilioFeedbacks?: InputMaybe<TwilioFeedbackUpdateManyWithoutOrganizationInput>;
  TwilioInfo?: InputMaybe<TwilioInfoUpdateOneWithoutOrganzationInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  use_revenue_planning?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  UserCustomFieldFilters?: InputMaybe<UserCustomFieldFilterUpdateManyWithoutOrganizationInput>;
  users?: InputMaybe<UserUpdateManyWithoutOrganizationInput>;
  win_rep_day_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
};

export type OrganizationUpdateWithoutCallMeNowsInput = {
  ActivityMonitorMultiplier?: InputMaybe<ActivityMonitorMultiplierUpdateManyWithoutOrganizationInput>;
  algoFactor?: InputMaybe<AlgoFactorUpdateManyWithoutOrganizationInput>;
  allow_calendar_invite?: InputMaybe<BoolFieldUpdateOperationsInput>;
  allow_remove_step_action?: InputMaybe<BoolFieldUpdateOperationsInput>;
  allow_snooze_step_action?: InputMaybe<BoolFieldUpdateOperationsInput>;
  always_record?: InputMaybe<BoolFieldUpdateOperationsInput>;
  arv_initial_held_demos_generated_by_sdr_weight?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  AssociationHistories?: InputMaybe<AssociationHistoryUpdateManyWithoutOrganizationInput>;
  auto_call_interval?: InputMaybe<IntFieldUpdateOperationsInput>;
  auto_dial?: InputMaybe<BoolFieldUpdateOperationsInput>;
  automated_campaigns?: InputMaybe<AutomatedCampaignUpdateManyWithoutOrganizationInput>;
  automatic_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  AutoTransferSelections?: InputMaybe<AutoTransferSelectionUpdateManyWithoutOrganizationInput>;
  Availablities?: InputMaybe<AvailabilityUpdateManyWithoutOrganizationInput>;
  AverageOrderValue?: InputMaybe<AverageOrderValueUpdateManyWithoutOrganizationInput>;
  best_action_ever_multiplier?: InputMaybe<FloatFieldUpdateOperationsInput>;
  best_metrics?: InputMaybe<BestMetricRecordUpdateManyWithoutOrganizationInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptUpdateManyWithoutOrganizationInput>;
  CallMultiplier?: InputMaybe<CallMultiplierUpdateManyWithoutOrganizationInput>;
  CallSharings?: InputMaybe<CallSharingUpdateManyWithoutOrganizationInput>;
  can_edit_sdr_transfer_rules?: InputMaybe<BoolFieldUpdateOperationsInput>;
  claim_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  concession?: InputMaybe<ConcessionUpdateManyWithoutOrganizationInput>;
  conference_region?: InputMaybe<EnumConferenceRegionFieldUpdateOperationsInput>;
  Conferences?: InputMaybe<ConferenceUpdateManyWithoutOrganizationInput>;
  ContactRequests?: InputMaybe<ContactRequestUpdateManyWithoutOrganizationInput>;
  Contacts?: InputMaybe<ContactUpdateManyWithoutOrganizationInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  custom_fields?: InputMaybe<CustomFieldUpdateManyWithoutOrganizationInput>;
  CustomObject?: InputMaybe<CustomObjectUpdateManyWithoutOrganizationInput>;
  CustomObjectField?: InputMaybe<CustomObjectFieldUpdateManyWithoutOrganizationInput>;
  CustomObjectFieldForeignKey?: InputMaybe<CustomObjectFieldForeignKeyUpdateManyWithoutOrganizationInput>;
  CustomObjectRecord?: InputMaybe<CustomObjectRecordUpdateManyWithoutOrganizationInput>;
  CustomObjectRow?: InputMaybe<CustomObjectRowUpdateManyWithoutOrganizationInput>;
  CustomQueueItems?: InputMaybe<CustomQueueItemUpdateManyWithoutOrganizationInput>;
  DashboardColumnOptions?: InputMaybe<DashboardColumnOptionUpdateManyWithoutOrganizationInput>;
  DashboardLeadFilters?: InputMaybe<DashboardLeadFilterUpdateManyWithoutOrganizationInput>;
  DashboardRepFilters?: InputMaybe<DashboardRepFilterUpdateManyWithoutOrganizationInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewUpdateManyWithoutOrganizationInput>;
  day_to_close?: InputMaybe<IntFieldUpdateOperationsInput>;
  default_demo_time_duration?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  demo_held_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_not_held_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_not_held_call_back_short_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_prep_time_minutes?: InputMaybe<IntFieldUpdateOperationsInput>;
  dev_account?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  dial_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  DispositionMultiplier?: InputMaybe<DispositionMultiplierUpdateManyWithoutOrganizationInput>;
  dispositions?: InputMaybe<DispositionUpdateManyWithoutOrganizationInput>;
  double_booking?: InputMaybe<BoolFieldUpdateOperationsInput>;
  dynamic_date_operators?: InputMaybe<BoolFieldUpdateOperationsInput>;
  edge_servers?: InputMaybe<OrganizationUpdateedge_ServersInput>;
  email_conflict_notification_email_list?: InputMaybe<OrganizationUpdateemail_Conflict_Notification_Email_ListInput>;
  EmailItems?: InputMaybe<EmailItemUpdateManyWithoutOrganizationInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordUpdateManyWithoutOrganizationInput>;
  EmailThreads?: InputMaybe<EmailThreadUpdateManyWithoutOrganizationInput>;
  enable_modified_metrics?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  enable_pay_period_view?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  enable_time_constraint?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  error_log_email_address?: InputMaybe<OrganizationUpdateerror_Log_Email_AddressInput>;
  estimated_average_num_of_weekly_overbooked_demo_slots?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  FolderPermissions?: InputMaybe<FolderPermissionUpdateManyWithoutOrganizationInput>;
  Folders?: InputMaybe<FolderUpdateManyWithoutOrganizationInput>;
  FutureBookingSetting?: InputMaybe<FutureBookingSettingUpdateOneWithoutOrganizationInput>;
  GlobalTransferRule?: InputMaybe<GlobalTransferRuleUpdateManyWithoutOrganizationInput>;
  held_demos_per_cc_connect?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  helpful_links?: InputMaybe<OrganizationUpdatehelpful_LinksInput>;
  HingePointMultiplier?: InputMaybe<HingePointMultiplierUpdateManyWithoutOrganizationInput>;
  hold_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
  hubspot_credential?: InputMaybe<HubSpotCredentialUpdateOneWithoutOrganizationInput>;
  hubspot_last_sync_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  hubspot_white_list?: InputMaybe<HubSpotWhitelistUpdateManyWithoutOrganizationInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inbound_close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_dm_connect_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_lt_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_revenue_value_of_sale?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_scheduled_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_set_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventUpdateManyWithoutOrganizationInput>;
  InboundConciergeWidget?: InputMaybe<InboundConciergeWidgetUpdateManyWithoutOrganizationInput>;
  industry_options?: InputMaybe<IndustryOptionUpdateManyWithoutOrganizationInput>;
  initial_sdr_sets_reps_day?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  integration_error_logs?: InputMaybe<IntegrationErrorLogUpdateManyWithoutOrganizationInput>;
  integration_mappings?: InputMaybe<IntegrationMappingUpdateManyWithoutOrganizationInput>;
  IntegrationMappingCustomObject?: InputMaybe<IntegrationMappingCustomObjectUpdateManyWithoutOrganizationInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogUpdateManyWithoutOrganizationInput>;
  IntegrationReadOnlyObject?: InputMaybe<IntegrationReadOnlyObjectUpdateManyWithoutOrganizationInput>;
  international?: InputMaybe<BoolFieldUpdateOperationsInput>;
  is_email_domain_verified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  is_salesforce_sandbox?: InputMaybe<BoolFieldUpdateOperationsInput>;
  last_lead_value_compute_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutOrganizationInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryUpdateManyWithoutOrganizationInput>;
  lead_intent?: InputMaybe<LeadIntentUpdateManyWithoutOrganizationInput>;
  lead_release_same_rep_delay_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  lead_routing_email_list?: InputMaybe<OrganizationUpdatelead_Routing_Email_ListInput>;
  lead_routing_rules?: InputMaybe<RuleUpdateManyWithoutOrganizationInput>;
  LeadCustomObjectRowAssociation?: InputMaybe<LeadCustomObjectRowAssociationUpdateManyWithoutOrganizationInput>;
  LeadFieldMultiplier?: InputMaybe<LeadFieldMultiplierUpdateManyWithoutOrganizationInput>;
  LeadFieldMultiplierCustom?: InputMaybe<LeadFieldMultiplierCustomUpdateManyWithoutOrganizationInput>;
  leads?: InputMaybe<LeadUpdateManyWithoutOrganizationInput>;
  leadsource_options?: InputMaybe<LeadSourceOptionUpdateManyWithoutOrganizationInput>;
  LeadValue?: InputMaybe<LeadValueUpdateManyWithoutOrganizationInput>;
  LifeTimeValueMultiplier?: InputMaybe<LifeTimeValueMultiplierUpdateManyWithoutOrganizationInput>;
  LiveTransferSetting?: InputMaybe<LiveTransferSettingUpdateOneWithoutOrganizationInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusUpdateManyWithoutOrganizationInput>;
  ltv_multiplier_custom_fields?: InputMaybe<LifeTimeValueMultiplierCustomUpdateManyWithoutOrganizationInput>;
  made_contact_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  make_sale_address_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_business_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_city_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_country_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_first_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_industry_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_last_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_lead_source_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_primary_email_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_primary_phone_number_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_state_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_sub_industry_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_zip_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  maxium_day_lag_set_to_schedule?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  mrr?: InputMaybe<IntFieldUpdateOperationsInput>;
  mrr_label?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  mrr_required_on_sale?: InputMaybe<BoolFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  NoteItems?: InputMaybe<NoteItemUpdateManyWithoutOrganizationInput>;
  Notifications?: InputMaybe<NotificationUpdateManyWithoutOrganizationInput>;
  onboardComplete?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  Opportunity?: InputMaybe<SfOpportunityUpdateManyWithoutOrganizationInput>;
  organization_holidays?: InputMaybe<OrganizationHolidayUpdateManyWithoutOrganizationInput>;
  organization_hours?: InputMaybe<OrganizationHourUpdateManyWithoutOrganizationInput>;
  OrganizationPaymentSetting?: InputMaybe<OrganizationPaymentSettingsUpdateOneWithoutOrganizationInput>;
  OrganizationPaymentSettings?: InputMaybe<OrganizationPaymentSettingsUpdateOneWithoutOrganizationsInput>;
  orgCalendarInviteMetadata?: InputMaybe<OrgCalendarInviteMetadataUpdateManyWithoutOrganizationInput>;
  OrgDefaultPipelinePerecentValue?: InputMaybe<OrgDefaultPipelinePerecentValueUpdateOneWithoutOrganizationInput>;
  OrgHubSpotSync?: InputMaybe<OrgHubSpotSyncUpdateOneWithoutOrganizationInput>;
  OrgNoEventDate?: InputMaybe<OrgNoEventDateUpdateManyWithoutOrganizationInput>;
  OrgSalesforceSync?: InputMaybe<OrgSalesforceSyncUpdateOneWithoutOrganizationInput>;
  OrgTimeslotAvailability?: InputMaybe<OrgTimeslotAvailabilityUpdateManyWithoutOrganizationInput>;
  outbound_close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_dm_connect_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_lt_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_revenue_value_of_sale?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_scheduled_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_set_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  PandadocData?: InputMaybe<PandadocDataUpdateOneWithoutOrganizationInput>;
  PandaDocTemplate?: InputMaybe<PandaDocTemplateUpdateManyWithoutOrganizationInput>;
  participant_jitter_buffer_size?: InputMaybe<EnumJitterBufferSizeFieldUpdateOperationsInput>;
  preferred_goal_setting?: InputMaybe<NullableEnumGoalsettingFieldUpdateOperationsInput>;
  prepayment?: InputMaybe<BoolFieldUpdateOperationsInput>;
  ProductChangeLog?: InputMaybe<ProductChangeLogUpdateManyWithoutOrganizationInput>;
  products?: InputMaybe<ProductUpdateManyWithoutOrganizationInput>;
  re_cold_call_hour_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  recordingStatus?: InputMaybe<EnumRecordingStatusFieldUpdateOperationsInput>;
  release_lead_resting?: InputMaybe<IntFieldUpdateOperationsInput>;
  rep_claim_limit?: InputMaybe<IntFieldUpdateOperationsInput>;
  rep_lead_allotment?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  rep_level_call_transfer?: InputMaybe<BoolFieldUpdateOperationsInput>;
  reps_can_bulk_unassign?: InputMaybe<BoolFieldUpdateOperationsInput>;
  reps_can_view_all_notes?: InputMaybe<BoolFieldUpdateOperationsInput>;
  require_sale_notes?: InputMaybe<BoolFieldUpdateOperationsInput>;
  resting_after_skipped_CC?: InputMaybe<IntFieldUpdateOperationsInput>;
  resting_period?: InputMaybe<IntFieldUpdateOperationsInput>;
  resting_period_after_unsuccessful_cold_call?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  resting_period_after_unsuccessful_demo?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  revenue_plan?: InputMaybe<RevenuePlanItemUpdateManyWithoutOrganizationInput>;
  Routings?: InputMaybe<RoutingUpdateManyWithoutOrganizationInput>;
  sale_field_options?: InputMaybe<SaleFieldOptionUpdateManyWithoutOrganizationInput>;
  sale_notification_email_list?: InputMaybe<OrganizationUpdatesale_Notification_Email_ListInput>;
  SaleConfig?: InputMaybe<SaleConfigUpdateOneWithoutOrganizationInput>;
  SaleConfigPage?: InputMaybe<SaleConfigPageUpdateManyWithoutOrganizationInput>;
  SaleConfigSection?: InputMaybe<SaleConfigSectionUpdateManyWithoutOrganizationInput>;
  SaleCycles?: InputMaybe<SaleCycleUpdateManyWithoutOrganizationInput>;
  SaleItems?: InputMaybe<SaleItemUpdateManyWithoutOrganizationInput>;
  sales?: InputMaybe<SaleUpdateManyWithoutOrganizationInput>;
  salesforce_domain?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  salesforce_last_sync_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  SalesForceColumn?: InputMaybe<SalesForceColumnUpdateManyWithoutOrganizationInput>;
  salesForceData?: InputMaybe<SalesForceDataUpdateOneWithoutOrganizationInput>;
  ScheduleItems?: InputMaybe<ScheduleItemUpdateManyWithoutOrganizationInput>;
  self_sourcing_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  send_error_log_email?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  send_lead_routing_email?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sendgrid_email_domain?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sendgrid_email_domain_id?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  sequence?: InputMaybe<SequenceUpdateManyWithoutOrganzationInput>;
  SequenceCriteriaConditions?: InputMaybe<SequenceCriteriaConditionUpdateManyWithoutOrganizationInput>;
  SequenceDashboardViews?: InputMaybe<SequenceDashboardViewUpdateManyWithoutOrganizationInput>;
  SequenceEntryCriterias?: InputMaybe<SequenceEntryCriteriaUpdateManyWithoutOrganizationInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutOrganizationInput>;
  SequenceSteps?: InputMaybe<SequenceStepUpdateManyWithoutOrganizationInput>;
  set_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
  short_resting?: InputMaybe<IntFieldUpdateOperationsInput>;
  show_calendar_options?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_contract_duration_sales?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_lead_source_to_rep?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_nocontact_button?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_payment_terms_sales?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_rep_all_leads?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_rep_my_leads?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_system_view?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sites?: InputMaybe<SiteUpdateManyWithoutOrganizationInput>;
  sms_opt_in_dialog?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SMSItems?: InputMaybe<SmsItemUpdateManyWithoutOrganizationInput>;
  SnoozeTrackings?: InputMaybe<SnoozeTrackingUpdateManyWithoutOrganizationInput>;
  StepActionLogs?: InputMaybe<StepActionLogUpdateManyWithoutOrganizationInput>;
  StepActions?: InputMaybe<StepActionUpdateManyWithoutOrganizationInput>;
  StepConditions?: InputMaybe<StepConditionUpdateManyWithoutOrganizationInput>;
  teams?: InputMaybe<TeamUpdateManyWithoutOrganizationInput>;
  Template?: InputMaybe<TemplateUpdateManyWithoutOrganizationInput>;
  TemplateVersions?: InputMaybe<TemplateVersionUpdateManyWithoutOrganizationInput>;
  timekit_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timezone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transfer_timeout?: InputMaybe<IntFieldUpdateOperationsInput>;
  TransferAttempts?: InputMaybe<TransferAttemptUpdateManyWithoutOrganizationInput>;
  twilio_numbers?: InputMaybe<TwilioNumberUpdateManyWithoutOrganizationInput>;
  TwilioFeedbacks?: InputMaybe<TwilioFeedbackUpdateManyWithoutOrganizationInput>;
  TwilioInfo?: InputMaybe<TwilioInfoUpdateOneWithoutOrganzationInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  use_revenue_planning?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  UserCustomFieldFilters?: InputMaybe<UserCustomFieldFilterUpdateManyWithoutOrganizationInput>;
  users?: InputMaybe<UserUpdateManyWithoutOrganizationInput>;
  win_rep_day_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
};

export type OrganizationUpdateWithoutCallSharingsInput = {
  ActivityMonitorMultiplier?: InputMaybe<ActivityMonitorMultiplierUpdateManyWithoutOrganizationInput>;
  algoFactor?: InputMaybe<AlgoFactorUpdateManyWithoutOrganizationInput>;
  allow_calendar_invite?: InputMaybe<BoolFieldUpdateOperationsInput>;
  allow_remove_step_action?: InputMaybe<BoolFieldUpdateOperationsInput>;
  allow_snooze_step_action?: InputMaybe<BoolFieldUpdateOperationsInput>;
  always_record?: InputMaybe<BoolFieldUpdateOperationsInput>;
  arv_initial_held_demos_generated_by_sdr_weight?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  AssociationHistories?: InputMaybe<AssociationHistoryUpdateManyWithoutOrganizationInput>;
  auto_call_interval?: InputMaybe<IntFieldUpdateOperationsInput>;
  auto_dial?: InputMaybe<BoolFieldUpdateOperationsInput>;
  automated_campaigns?: InputMaybe<AutomatedCampaignUpdateManyWithoutOrganizationInput>;
  automatic_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  AutoTransferSelections?: InputMaybe<AutoTransferSelectionUpdateManyWithoutOrganizationInput>;
  Availablities?: InputMaybe<AvailabilityUpdateManyWithoutOrganizationInput>;
  AverageOrderValue?: InputMaybe<AverageOrderValueUpdateManyWithoutOrganizationInput>;
  best_action_ever_multiplier?: InputMaybe<FloatFieldUpdateOperationsInput>;
  best_metrics?: InputMaybe<BestMetricRecordUpdateManyWithoutOrganizationInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptUpdateManyWithoutOrganizationInput>;
  callMeNows?: InputMaybe<CallMeNowUpdateManyWithoutOrganizationInput>;
  CallMultiplier?: InputMaybe<CallMultiplierUpdateManyWithoutOrganizationInput>;
  can_edit_sdr_transfer_rules?: InputMaybe<BoolFieldUpdateOperationsInput>;
  claim_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  concession?: InputMaybe<ConcessionUpdateManyWithoutOrganizationInput>;
  conference_region?: InputMaybe<EnumConferenceRegionFieldUpdateOperationsInput>;
  Conferences?: InputMaybe<ConferenceUpdateManyWithoutOrganizationInput>;
  ContactRequests?: InputMaybe<ContactRequestUpdateManyWithoutOrganizationInput>;
  Contacts?: InputMaybe<ContactUpdateManyWithoutOrganizationInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  custom_fields?: InputMaybe<CustomFieldUpdateManyWithoutOrganizationInput>;
  CustomObject?: InputMaybe<CustomObjectUpdateManyWithoutOrganizationInput>;
  CustomObjectField?: InputMaybe<CustomObjectFieldUpdateManyWithoutOrganizationInput>;
  CustomObjectFieldForeignKey?: InputMaybe<CustomObjectFieldForeignKeyUpdateManyWithoutOrganizationInput>;
  CustomObjectRecord?: InputMaybe<CustomObjectRecordUpdateManyWithoutOrganizationInput>;
  CustomObjectRow?: InputMaybe<CustomObjectRowUpdateManyWithoutOrganizationInput>;
  CustomQueueItems?: InputMaybe<CustomQueueItemUpdateManyWithoutOrganizationInput>;
  DashboardColumnOptions?: InputMaybe<DashboardColumnOptionUpdateManyWithoutOrganizationInput>;
  DashboardLeadFilters?: InputMaybe<DashboardLeadFilterUpdateManyWithoutOrganizationInput>;
  DashboardRepFilters?: InputMaybe<DashboardRepFilterUpdateManyWithoutOrganizationInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewUpdateManyWithoutOrganizationInput>;
  day_to_close?: InputMaybe<IntFieldUpdateOperationsInput>;
  default_demo_time_duration?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  demo_held_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_not_held_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_not_held_call_back_short_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_prep_time_minutes?: InputMaybe<IntFieldUpdateOperationsInput>;
  dev_account?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  dial_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  DispositionMultiplier?: InputMaybe<DispositionMultiplierUpdateManyWithoutOrganizationInput>;
  dispositions?: InputMaybe<DispositionUpdateManyWithoutOrganizationInput>;
  double_booking?: InputMaybe<BoolFieldUpdateOperationsInput>;
  dynamic_date_operators?: InputMaybe<BoolFieldUpdateOperationsInput>;
  edge_servers?: InputMaybe<OrganizationUpdateedge_ServersInput>;
  email_conflict_notification_email_list?: InputMaybe<OrganizationUpdateemail_Conflict_Notification_Email_ListInput>;
  EmailItems?: InputMaybe<EmailItemUpdateManyWithoutOrganizationInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordUpdateManyWithoutOrganizationInput>;
  EmailThreads?: InputMaybe<EmailThreadUpdateManyWithoutOrganizationInput>;
  enable_modified_metrics?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  enable_pay_period_view?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  enable_time_constraint?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  error_log_email_address?: InputMaybe<OrganizationUpdateerror_Log_Email_AddressInput>;
  estimated_average_num_of_weekly_overbooked_demo_slots?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  FolderPermissions?: InputMaybe<FolderPermissionUpdateManyWithoutOrganizationInput>;
  Folders?: InputMaybe<FolderUpdateManyWithoutOrganizationInput>;
  FutureBookingSetting?: InputMaybe<FutureBookingSettingUpdateOneWithoutOrganizationInput>;
  GlobalTransferRule?: InputMaybe<GlobalTransferRuleUpdateManyWithoutOrganizationInput>;
  held_demos_per_cc_connect?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  helpful_links?: InputMaybe<OrganizationUpdatehelpful_LinksInput>;
  HingePointMultiplier?: InputMaybe<HingePointMultiplierUpdateManyWithoutOrganizationInput>;
  hold_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
  hubspot_credential?: InputMaybe<HubSpotCredentialUpdateOneWithoutOrganizationInput>;
  hubspot_last_sync_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  hubspot_white_list?: InputMaybe<HubSpotWhitelistUpdateManyWithoutOrganizationInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inbound_close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_dm_connect_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_lt_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_revenue_value_of_sale?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_scheduled_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_set_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventUpdateManyWithoutOrganizationInput>;
  InboundConciergeWidget?: InputMaybe<InboundConciergeWidgetUpdateManyWithoutOrganizationInput>;
  industry_options?: InputMaybe<IndustryOptionUpdateManyWithoutOrganizationInput>;
  initial_sdr_sets_reps_day?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  integration_error_logs?: InputMaybe<IntegrationErrorLogUpdateManyWithoutOrganizationInput>;
  integration_mappings?: InputMaybe<IntegrationMappingUpdateManyWithoutOrganizationInput>;
  IntegrationMappingCustomObject?: InputMaybe<IntegrationMappingCustomObjectUpdateManyWithoutOrganizationInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogUpdateManyWithoutOrganizationInput>;
  IntegrationReadOnlyObject?: InputMaybe<IntegrationReadOnlyObjectUpdateManyWithoutOrganizationInput>;
  international?: InputMaybe<BoolFieldUpdateOperationsInput>;
  is_email_domain_verified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  is_salesforce_sandbox?: InputMaybe<BoolFieldUpdateOperationsInput>;
  last_lead_value_compute_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutOrganizationInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryUpdateManyWithoutOrganizationInput>;
  lead_intent?: InputMaybe<LeadIntentUpdateManyWithoutOrganizationInput>;
  lead_release_same_rep_delay_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  lead_routing_email_list?: InputMaybe<OrganizationUpdatelead_Routing_Email_ListInput>;
  lead_routing_rules?: InputMaybe<RuleUpdateManyWithoutOrganizationInput>;
  LeadCustomObjectRowAssociation?: InputMaybe<LeadCustomObjectRowAssociationUpdateManyWithoutOrganizationInput>;
  LeadFieldMultiplier?: InputMaybe<LeadFieldMultiplierUpdateManyWithoutOrganizationInput>;
  LeadFieldMultiplierCustom?: InputMaybe<LeadFieldMultiplierCustomUpdateManyWithoutOrganizationInput>;
  leads?: InputMaybe<LeadUpdateManyWithoutOrganizationInput>;
  leadsource_options?: InputMaybe<LeadSourceOptionUpdateManyWithoutOrganizationInput>;
  LeadValue?: InputMaybe<LeadValueUpdateManyWithoutOrganizationInput>;
  LifeTimeValueMultiplier?: InputMaybe<LifeTimeValueMultiplierUpdateManyWithoutOrganizationInput>;
  LiveTransferSetting?: InputMaybe<LiveTransferSettingUpdateOneWithoutOrganizationInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusUpdateManyWithoutOrganizationInput>;
  ltv_multiplier_custom_fields?: InputMaybe<LifeTimeValueMultiplierCustomUpdateManyWithoutOrganizationInput>;
  made_contact_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  make_sale_address_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_business_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_city_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_country_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_first_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_industry_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_last_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_lead_source_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_primary_email_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_primary_phone_number_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_state_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_sub_industry_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_zip_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  maxium_day_lag_set_to_schedule?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  mrr?: InputMaybe<IntFieldUpdateOperationsInput>;
  mrr_label?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  mrr_required_on_sale?: InputMaybe<BoolFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  NoteItems?: InputMaybe<NoteItemUpdateManyWithoutOrganizationInput>;
  Notifications?: InputMaybe<NotificationUpdateManyWithoutOrganizationInput>;
  onboardComplete?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  Opportunity?: InputMaybe<SfOpportunityUpdateManyWithoutOrganizationInput>;
  organization_holidays?: InputMaybe<OrganizationHolidayUpdateManyWithoutOrganizationInput>;
  organization_hours?: InputMaybe<OrganizationHourUpdateManyWithoutOrganizationInput>;
  OrganizationPaymentSetting?: InputMaybe<OrganizationPaymentSettingsUpdateOneWithoutOrganizationInput>;
  OrganizationPaymentSettings?: InputMaybe<OrganizationPaymentSettingsUpdateOneWithoutOrganizationsInput>;
  orgCalendarInviteMetadata?: InputMaybe<OrgCalendarInviteMetadataUpdateManyWithoutOrganizationInput>;
  OrgDefaultPipelinePerecentValue?: InputMaybe<OrgDefaultPipelinePerecentValueUpdateOneWithoutOrganizationInput>;
  OrgHubSpotSync?: InputMaybe<OrgHubSpotSyncUpdateOneWithoutOrganizationInput>;
  OrgNoEventDate?: InputMaybe<OrgNoEventDateUpdateManyWithoutOrganizationInput>;
  OrgSalesforceSync?: InputMaybe<OrgSalesforceSyncUpdateOneWithoutOrganizationInput>;
  OrgTimeslotAvailability?: InputMaybe<OrgTimeslotAvailabilityUpdateManyWithoutOrganizationInput>;
  outbound_close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_dm_connect_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_lt_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_revenue_value_of_sale?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_scheduled_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_set_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  PandadocData?: InputMaybe<PandadocDataUpdateOneWithoutOrganizationInput>;
  PandaDocTemplate?: InputMaybe<PandaDocTemplateUpdateManyWithoutOrganizationInput>;
  participant_jitter_buffer_size?: InputMaybe<EnumJitterBufferSizeFieldUpdateOperationsInput>;
  preferred_goal_setting?: InputMaybe<NullableEnumGoalsettingFieldUpdateOperationsInput>;
  prepayment?: InputMaybe<BoolFieldUpdateOperationsInput>;
  ProductChangeLog?: InputMaybe<ProductChangeLogUpdateManyWithoutOrganizationInput>;
  products?: InputMaybe<ProductUpdateManyWithoutOrganizationInput>;
  re_cold_call_hour_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  recordingStatus?: InputMaybe<EnumRecordingStatusFieldUpdateOperationsInput>;
  release_lead_resting?: InputMaybe<IntFieldUpdateOperationsInput>;
  rep_claim_limit?: InputMaybe<IntFieldUpdateOperationsInput>;
  rep_lead_allotment?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  rep_level_call_transfer?: InputMaybe<BoolFieldUpdateOperationsInput>;
  reps_can_bulk_unassign?: InputMaybe<BoolFieldUpdateOperationsInput>;
  reps_can_view_all_notes?: InputMaybe<BoolFieldUpdateOperationsInput>;
  require_sale_notes?: InputMaybe<BoolFieldUpdateOperationsInput>;
  resting_after_skipped_CC?: InputMaybe<IntFieldUpdateOperationsInput>;
  resting_period?: InputMaybe<IntFieldUpdateOperationsInput>;
  resting_period_after_unsuccessful_cold_call?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  resting_period_after_unsuccessful_demo?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  revenue_plan?: InputMaybe<RevenuePlanItemUpdateManyWithoutOrganizationInput>;
  Routings?: InputMaybe<RoutingUpdateManyWithoutOrganizationInput>;
  sale_field_options?: InputMaybe<SaleFieldOptionUpdateManyWithoutOrganizationInput>;
  sale_notification_email_list?: InputMaybe<OrganizationUpdatesale_Notification_Email_ListInput>;
  SaleConfig?: InputMaybe<SaleConfigUpdateOneWithoutOrganizationInput>;
  SaleConfigPage?: InputMaybe<SaleConfigPageUpdateManyWithoutOrganizationInput>;
  SaleConfigSection?: InputMaybe<SaleConfigSectionUpdateManyWithoutOrganizationInput>;
  SaleCycles?: InputMaybe<SaleCycleUpdateManyWithoutOrganizationInput>;
  SaleItems?: InputMaybe<SaleItemUpdateManyWithoutOrganizationInput>;
  sales?: InputMaybe<SaleUpdateManyWithoutOrganizationInput>;
  salesforce_domain?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  salesforce_last_sync_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  SalesForceColumn?: InputMaybe<SalesForceColumnUpdateManyWithoutOrganizationInput>;
  salesForceData?: InputMaybe<SalesForceDataUpdateOneWithoutOrganizationInput>;
  ScheduleItems?: InputMaybe<ScheduleItemUpdateManyWithoutOrganizationInput>;
  self_sourcing_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  send_error_log_email?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  send_lead_routing_email?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sendgrid_email_domain?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sendgrid_email_domain_id?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  sequence?: InputMaybe<SequenceUpdateManyWithoutOrganzationInput>;
  SequenceCriteriaConditions?: InputMaybe<SequenceCriteriaConditionUpdateManyWithoutOrganizationInput>;
  SequenceDashboardViews?: InputMaybe<SequenceDashboardViewUpdateManyWithoutOrganizationInput>;
  SequenceEntryCriterias?: InputMaybe<SequenceEntryCriteriaUpdateManyWithoutOrganizationInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutOrganizationInput>;
  SequenceSteps?: InputMaybe<SequenceStepUpdateManyWithoutOrganizationInput>;
  set_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
  short_resting?: InputMaybe<IntFieldUpdateOperationsInput>;
  show_calendar_options?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_contract_duration_sales?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_lead_source_to_rep?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_nocontact_button?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_payment_terms_sales?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_rep_all_leads?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_rep_my_leads?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_system_view?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sites?: InputMaybe<SiteUpdateManyWithoutOrganizationInput>;
  sms_opt_in_dialog?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SMSItems?: InputMaybe<SmsItemUpdateManyWithoutOrganizationInput>;
  SnoozeTrackings?: InputMaybe<SnoozeTrackingUpdateManyWithoutOrganizationInput>;
  StepActionLogs?: InputMaybe<StepActionLogUpdateManyWithoutOrganizationInput>;
  StepActions?: InputMaybe<StepActionUpdateManyWithoutOrganizationInput>;
  StepConditions?: InputMaybe<StepConditionUpdateManyWithoutOrganizationInput>;
  teams?: InputMaybe<TeamUpdateManyWithoutOrganizationInput>;
  Template?: InputMaybe<TemplateUpdateManyWithoutOrganizationInput>;
  TemplateVersions?: InputMaybe<TemplateVersionUpdateManyWithoutOrganizationInput>;
  timekit_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timezone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transfer_timeout?: InputMaybe<IntFieldUpdateOperationsInput>;
  TransferAttempts?: InputMaybe<TransferAttemptUpdateManyWithoutOrganizationInput>;
  twilio_numbers?: InputMaybe<TwilioNumberUpdateManyWithoutOrganizationInput>;
  TwilioFeedbacks?: InputMaybe<TwilioFeedbackUpdateManyWithoutOrganizationInput>;
  TwilioInfo?: InputMaybe<TwilioInfoUpdateOneWithoutOrganzationInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  use_revenue_planning?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  UserCustomFieldFilters?: InputMaybe<UserCustomFieldFilterUpdateManyWithoutOrganizationInput>;
  users?: InputMaybe<UserUpdateManyWithoutOrganizationInput>;
  win_rep_day_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
};

export type OrganizationUpdateWithoutConcessionInput = {
  ActivityMonitorMultiplier?: InputMaybe<ActivityMonitorMultiplierUpdateManyWithoutOrganizationInput>;
  algoFactor?: InputMaybe<AlgoFactorUpdateManyWithoutOrganizationInput>;
  allow_calendar_invite?: InputMaybe<BoolFieldUpdateOperationsInput>;
  allow_remove_step_action?: InputMaybe<BoolFieldUpdateOperationsInput>;
  allow_snooze_step_action?: InputMaybe<BoolFieldUpdateOperationsInput>;
  always_record?: InputMaybe<BoolFieldUpdateOperationsInput>;
  arv_initial_held_demos_generated_by_sdr_weight?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  AssociationHistories?: InputMaybe<AssociationHistoryUpdateManyWithoutOrganizationInput>;
  auto_call_interval?: InputMaybe<IntFieldUpdateOperationsInput>;
  auto_dial?: InputMaybe<BoolFieldUpdateOperationsInput>;
  automated_campaigns?: InputMaybe<AutomatedCampaignUpdateManyWithoutOrganizationInput>;
  automatic_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  AutoTransferSelections?: InputMaybe<AutoTransferSelectionUpdateManyWithoutOrganizationInput>;
  Availablities?: InputMaybe<AvailabilityUpdateManyWithoutOrganizationInput>;
  AverageOrderValue?: InputMaybe<AverageOrderValueUpdateManyWithoutOrganizationInput>;
  best_action_ever_multiplier?: InputMaybe<FloatFieldUpdateOperationsInput>;
  best_metrics?: InputMaybe<BestMetricRecordUpdateManyWithoutOrganizationInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptUpdateManyWithoutOrganizationInput>;
  callMeNows?: InputMaybe<CallMeNowUpdateManyWithoutOrganizationInput>;
  CallMultiplier?: InputMaybe<CallMultiplierUpdateManyWithoutOrganizationInput>;
  CallSharings?: InputMaybe<CallSharingUpdateManyWithoutOrganizationInput>;
  can_edit_sdr_transfer_rules?: InputMaybe<BoolFieldUpdateOperationsInput>;
  claim_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  conference_region?: InputMaybe<EnumConferenceRegionFieldUpdateOperationsInput>;
  Conferences?: InputMaybe<ConferenceUpdateManyWithoutOrganizationInput>;
  ContactRequests?: InputMaybe<ContactRequestUpdateManyWithoutOrganizationInput>;
  Contacts?: InputMaybe<ContactUpdateManyWithoutOrganizationInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  custom_fields?: InputMaybe<CustomFieldUpdateManyWithoutOrganizationInput>;
  CustomObject?: InputMaybe<CustomObjectUpdateManyWithoutOrganizationInput>;
  CustomObjectField?: InputMaybe<CustomObjectFieldUpdateManyWithoutOrganizationInput>;
  CustomObjectFieldForeignKey?: InputMaybe<CustomObjectFieldForeignKeyUpdateManyWithoutOrganizationInput>;
  CustomObjectRecord?: InputMaybe<CustomObjectRecordUpdateManyWithoutOrganizationInput>;
  CustomObjectRow?: InputMaybe<CustomObjectRowUpdateManyWithoutOrganizationInput>;
  CustomQueueItems?: InputMaybe<CustomQueueItemUpdateManyWithoutOrganizationInput>;
  DashboardColumnOptions?: InputMaybe<DashboardColumnOptionUpdateManyWithoutOrganizationInput>;
  DashboardLeadFilters?: InputMaybe<DashboardLeadFilterUpdateManyWithoutOrganizationInput>;
  DashboardRepFilters?: InputMaybe<DashboardRepFilterUpdateManyWithoutOrganizationInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewUpdateManyWithoutOrganizationInput>;
  day_to_close?: InputMaybe<IntFieldUpdateOperationsInput>;
  default_demo_time_duration?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  demo_held_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_not_held_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_not_held_call_back_short_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_prep_time_minutes?: InputMaybe<IntFieldUpdateOperationsInput>;
  dev_account?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  dial_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  DispositionMultiplier?: InputMaybe<DispositionMultiplierUpdateManyWithoutOrganizationInput>;
  dispositions?: InputMaybe<DispositionUpdateManyWithoutOrganizationInput>;
  double_booking?: InputMaybe<BoolFieldUpdateOperationsInput>;
  dynamic_date_operators?: InputMaybe<BoolFieldUpdateOperationsInput>;
  edge_servers?: InputMaybe<OrganizationUpdateedge_ServersInput>;
  email_conflict_notification_email_list?: InputMaybe<OrganizationUpdateemail_Conflict_Notification_Email_ListInput>;
  EmailItems?: InputMaybe<EmailItemUpdateManyWithoutOrganizationInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordUpdateManyWithoutOrganizationInput>;
  EmailThreads?: InputMaybe<EmailThreadUpdateManyWithoutOrganizationInput>;
  enable_modified_metrics?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  enable_pay_period_view?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  enable_time_constraint?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  error_log_email_address?: InputMaybe<OrganizationUpdateerror_Log_Email_AddressInput>;
  estimated_average_num_of_weekly_overbooked_demo_slots?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  FolderPermissions?: InputMaybe<FolderPermissionUpdateManyWithoutOrganizationInput>;
  Folders?: InputMaybe<FolderUpdateManyWithoutOrganizationInput>;
  FutureBookingSetting?: InputMaybe<FutureBookingSettingUpdateOneWithoutOrganizationInput>;
  GlobalTransferRule?: InputMaybe<GlobalTransferRuleUpdateManyWithoutOrganizationInput>;
  held_demos_per_cc_connect?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  helpful_links?: InputMaybe<OrganizationUpdatehelpful_LinksInput>;
  HingePointMultiplier?: InputMaybe<HingePointMultiplierUpdateManyWithoutOrganizationInput>;
  hold_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
  hubspot_credential?: InputMaybe<HubSpotCredentialUpdateOneWithoutOrganizationInput>;
  hubspot_last_sync_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  hubspot_white_list?: InputMaybe<HubSpotWhitelistUpdateManyWithoutOrganizationInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inbound_close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_dm_connect_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_lt_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_revenue_value_of_sale?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_scheduled_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_set_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventUpdateManyWithoutOrganizationInput>;
  InboundConciergeWidget?: InputMaybe<InboundConciergeWidgetUpdateManyWithoutOrganizationInput>;
  industry_options?: InputMaybe<IndustryOptionUpdateManyWithoutOrganizationInput>;
  initial_sdr_sets_reps_day?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  integration_error_logs?: InputMaybe<IntegrationErrorLogUpdateManyWithoutOrganizationInput>;
  integration_mappings?: InputMaybe<IntegrationMappingUpdateManyWithoutOrganizationInput>;
  IntegrationMappingCustomObject?: InputMaybe<IntegrationMappingCustomObjectUpdateManyWithoutOrganizationInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogUpdateManyWithoutOrganizationInput>;
  IntegrationReadOnlyObject?: InputMaybe<IntegrationReadOnlyObjectUpdateManyWithoutOrganizationInput>;
  international?: InputMaybe<BoolFieldUpdateOperationsInput>;
  is_email_domain_verified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  is_salesforce_sandbox?: InputMaybe<BoolFieldUpdateOperationsInput>;
  last_lead_value_compute_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutOrganizationInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryUpdateManyWithoutOrganizationInput>;
  lead_intent?: InputMaybe<LeadIntentUpdateManyWithoutOrganizationInput>;
  lead_release_same_rep_delay_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  lead_routing_email_list?: InputMaybe<OrganizationUpdatelead_Routing_Email_ListInput>;
  lead_routing_rules?: InputMaybe<RuleUpdateManyWithoutOrganizationInput>;
  LeadCustomObjectRowAssociation?: InputMaybe<LeadCustomObjectRowAssociationUpdateManyWithoutOrganizationInput>;
  LeadFieldMultiplier?: InputMaybe<LeadFieldMultiplierUpdateManyWithoutOrganizationInput>;
  LeadFieldMultiplierCustom?: InputMaybe<LeadFieldMultiplierCustomUpdateManyWithoutOrganizationInput>;
  leads?: InputMaybe<LeadUpdateManyWithoutOrganizationInput>;
  leadsource_options?: InputMaybe<LeadSourceOptionUpdateManyWithoutOrganizationInput>;
  LeadValue?: InputMaybe<LeadValueUpdateManyWithoutOrganizationInput>;
  LifeTimeValueMultiplier?: InputMaybe<LifeTimeValueMultiplierUpdateManyWithoutOrganizationInput>;
  LiveTransferSetting?: InputMaybe<LiveTransferSettingUpdateOneWithoutOrganizationInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusUpdateManyWithoutOrganizationInput>;
  ltv_multiplier_custom_fields?: InputMaybe<LifeTimeValueMultiplierCustomUpdateManyWithoutOrganizationInput>;
  made_contact_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  make_sale_address_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_business_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_city_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_country_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_first_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_industry_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_last_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_lead_source_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_primary_email_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_primary_phone_number_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_state_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_sub_industry_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_zip_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  maxium_day_lag_set_to_schedule?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  mrr?: InputMaybe<IntFieldUpdateOperationsInput>;
  mrr_label?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  mrr_required_on_sale?: InputMaybe<BoolFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  NoteItems?: InputMaybe<NoteItemUpdateManyWithoutOrganizationInput>;
  Notifications?: InputMaybe<NotificationUpdateManyWithoutOrganizationInput>;
  onboardComplete?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  Opportunity?: InputMaybe<SfOpportunityUpdateManyWithoutOrganizationInput>;
  organization_holidays?: InputMaybe<OrganizationHolidayUpdateManyWithoutOrganizationInput>;
  organization_hours?: InputMaybe<OrganizationHourUpdateManyWithoutOrganizationInput>;
  OrganizationPaymentSetting?: InputMaybe<OrganizationPaymentSettingsUpdateOneWithoutOrganizationInput>;
  OrganizationPaymentSettings?: InputMaybe<OrganizationPaymentSettingsUpdateOneWithoutOrganizationsInput>;
  orgCalendarInviteMetadata?: InputMaybe<OrgCalendarInviteMetadataUpdateManyWithoutOrganizationInput>;
  OrgDefaultPipelinePerecentValue?: InputMaybe<OrgDefaultPipelinePerecentValueUpdateOneWithoutOrganizationInput>;
  OrgHubSpotSync?: InputMaybe<OrgHubSpotSyncUpdateOneWithoutOrganizationInput>;
  OrgNoEventDate?: InputMaybe<OrgNoEventDateUpdateManyWithoutOrganizationInput>;
  OrgSalesforceSync?: InputMaybe<OrgSalesforceSyncUpdateOneWithoutOrganizationInput>;
  OrgTimeslotAvailability?: InputMaybe<OrgTimeslotAvailabilityUpdateManyWithoutOrganizationInput>;
  outbound_close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_dm_connect_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_lt_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_revenue_value_of_sale?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_scheduled_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_set_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  PandadocData?: InputMaybe<PandadocDataUpdateOneWithoutOrganizationInput>;
  PandaDocTemplate?: InputMaybe<PandaDocTemplateUpdateManyWithoutOrganizationInput>;
  participant_jitter_buffer_size?: InputMaybe<EnumJitterBufferSizeFieldUpdateOperationsInput>;
  preferred_goal_setting?: InputMaybe<NullableEnumGoalsettingFieldUpdateOperationsInput>;
  prepayment?: InputMaybe<BoolFieldUpdateOperationsInput>;
  ProductChangeLog?: InputMaybe<ProductChangeLogUpdateManyWithoutOrganizationInput>;
  products?: InputMaybe<ProductUpdateManyWithoutOrganizationInput>;
  re_cold_call_hour_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  recordingStatus?: InputMaybe<EnumRecordingStatusFieldUpdateOperationsInput>;
  release_lead_resting?: InputMaybe<IntFieldUpdateOperationsInput>;
  rep_claim_limit?: InputMaybe<IntFieldUpdateOperationsInput>;
  rep_lead_allotment?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  rep_level_call_transfer?: InputMaybe<BoolFieldUpdateOperationsInput>;
  reps_can_bulk_unassign?: InputMaybe<BoolFieldUpdateOperationsInput>;
  reps_can_view_all_notes?: InputMaybe<BoolFieldUpdateOperationsInput>;
  require_sale_notes?: InputMaybe<BoolFieldUpdateOperationsInput>;
  resting_after_skipped_CC?: InputMaybe<IntFieldUpdateOperationsInput>;
  resting_period?: InputMaybe<IntFieldUpdateOperationsInput>;
  resting_period_after_unsuccessful_cold_call?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  resting_period_after_unsuccessful_demo?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  revenue_plan?: InputMaybe<RevenuePlanItemUpdateManyWithoutOrganizationInput>;
  Routings?: InputMaybe<RoutingUpdateManyWithoutOrganizationInput>;
  sale_field_options?: InputMaybe<SaleFieldOptionUpdateManyWithoutOrganizationInput>;
  sale_notification_email_list?: InputMaybe<OrganizationUpdatesale_Notification_Email_ListInput>;
  SaleConfig?: InputMaybe<SaleConfigUpdateOneWithoutOrganizationInput>;
  SaleConfigPage?: InputMaybe<SaleConfigPageUpdateManyWithoutOrganizationInput>;
  SaleConfigSection?: InputMaybe<SaleConfigSectionUpdateManyWithoutOrganizationInput>;
  SaleCycles?: InputMaybe<SaleCycleUpdateManyWithoutOrganizationInput>;
  SaleItems?: InputMaybe<SaleItemUpdateManyWithoutOrganizationInput>;
  sales?: InputMaybe<SaleUpdateManyWithoutOrganizationInput>;
  salesforce_domain?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  salesforce_last_sync_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  SalesForceColumn?: InputMaybe<SalesForceColumnUpdateManyWithoutOrganizationInput>;
  salesForceData?: InputMaybe<SalesForceDataUpdateOneWithoutOrganizationInput>;
  ScheduleItems?: InputMaybe<ScheduleItemUpdateManyWithoutOrganizationInput>;
  self_sourcing_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  send_error_log_email?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  send_lead_routing_email?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sendgrid_email_domain?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sendgrid_email_domain_id?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  sequence?: InputMaybe<SequenceUpdateManyWithoutOrganzationInput>;
  SequenceCriteriaConditions?: InputMaybe<SequenceCriteriaConditionUpdateManyWithoutOrganizationInput>;
  SequenceDashboardViews?: InputMaybe<SequenceDashboardViewUpdateManyWithoutOrganizationInput>;
  SequenceEntryCriterias?: InputMaybe<SequenceEntryCriteriaUpdateManyWithoutOrganizationInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutOrganizationInput>;
  SequenceSteps?: InputMaybe<SequenceStepUpdateManyWithoutOrganizationInput>;
  set_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
  short_resting?: InputMaybe<IntFieldUpdateOperationsInput>;
  show_calendar_options?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_contract_duration_sales?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_lead_source_to_rep?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_nocontact_button?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_payment_terms_sales?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_rep_all_leads?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_rep_my_leads?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_system_view?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sites?: InputMaybe<SiteUpdateManyWithoutOrganizationInput>;
  sms_opt_in_dialog?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SMSItems?: InputMaybe<SmsItemUpdateManyWithoutOrganizationInput>;
  SnoozeTrackings?: InputMaybe<SnoozeTrackingUpdateManyWithoutOrganizationInput>;
  StepActionLogs?: InputMaybe<StepActionLogUpdateManyWithoutOrganizationInput>;
  StepActions?: InputMaybe<StepActionUpdateManyWithoutOrganizationInput>;
  StepConditions?: InputMaybe<StepConditionUpdateManyWithoutOrganizationInput>;
  teams?: InputMaybe<TeamUpdateManyWithoutOrganizationInput>;
  Template?: InputMaybe<TemplateUpdateManyWithoutOrganizationInput>;
  TemplateVersions?: InputMaybe<TemplateVersionUpdateManyWithoutOrganizationInput>;
  timekit_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timezone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transfer_timeout?: InputMaybe<IntFieldUpdateOperationsInput>;
  TransferAttempts?: InputMaybe<TransferAttemptUpdateManyWithoutOrganizationInput>;
  twilio_numbers?: InputMaybe<TwilioNumberUpdateManyWithoutOrganizationInput>;
  TwilioFeedbacks?: InputMaybe<TwilioFeedbackUpdateManyWithoutOrganizationInput>;
  TwilioInfo?: InputMaybe<TwilioInfoUpdateOneWithoutOrganzationInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  use_revenue_planning?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  UserCustomFieldFilters?: InputMaybe<UserCustomFieldFilterUpdateManyWithoutOrganizationInput>;
  users?: InputMaybe<UserUpdateManyWithoutOrganizationInput>;
  win_rep_day_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
};

export type OrganizationUpdateWithoutConferencesInput = {
  ActivityMonitorMultiplier?: InputMaybe<ActivityMonitorMultiplierUpdateManyWithoutOrganizationInput>;
  algoFactor?: InputMaybe<AlgoFactorUpdateManyWithoutOrganizationInput>;
  allow_calendar_invite?: InputMaybe<BoolFieldUpdateOperationsInput>;
  allow_remove_step_action?: InputMaybe<BoolFieldUpdateOperationsInput>;
  allow_snooze_step_action?: InputMaybe<BoolFieldUpdateOperationsInput>;
  always_record?: InputMaybe<BoolFieldUpdateOperationsInput>;
  arv_initial_held_demos_generated_by_sdr_weight?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  AssociationHistories?: InputMaybe<AssociationHistoryUpdateManyWithoutOrganizationInput>;
  auto_call_interval?: InputMaybe<IntFieldUpdateOperationsInput>;
  auto_dial?: InputMaybe<BoolFieldUpdateOperationsInput>;
  automated_campaigns?: InputMaybe<AutomatedCampaignUpdateManyWithoutOrganizationInput>;
  automatic_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  AutoTransferSelections?: InputMaybe<AutoTransferSelectionUpdateManyWithoutOrganizationInput>;
  Availablities?: InputMaybe<AvailabilityUpdateManyWithoutOrganizationInput>;
  AverageOrderValue?: InputMaybe<AverageOrderValueUpdateManyWithoutOrganizationInput>;
  best_action_ever_multiplier?: InputMaybe<FloatFieldUpdateOperationsInput>;
  best_metrics?: InputMaybe<BestMetricRecordUpdateManyWithoutOrganizationInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptUpdateManyWithoutOrganizationInput>;
  callMeNows?: InputMaybe<CallMeNowUpdateManyWithoutOrganizationInput>;
  CallMultiplier?: InputMaybe<CallMultiplierUpdateManyWithoutOrganizationInput>;
  CallSharings?: InputMaybe<CallSharingUpdateManyWithoutOrganizationInput>;
  can_edit_sdr_transfer_rules?: InputMaybe<BoolFieldUpdateOperationsInput>;
  claim_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  concession?: InputMaybe<ConcessionUpdateManyWithoutOrganizationInput>;
  conference_region?: InputMaybe<EnumConferenceRegionFieldUpdateOperationsInput>;
  ContactRequests?: InputMaybe<ContactRequestUpdateManyWithoutOrganizationInput>;
  Contacts?: InputMaybe<ContactUpdateManyWithoutOrganizationInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  custom_fields?: InputMaybe<CustomFieldUpdateManyWithoutOrganizationInput>;
  CustomObject?: InputMaybe<CustomObjectUpdateManyWithoutOrganizationInput>;
  CustomObjectField?: InputMaybe<CustomObjectFieldUpdateManyWithoutOrganizationInput>;
  CustomObjectFieldForeignKey?: InputMaybe<CustomObjectFieldForeignKeyUpdateManyWithoutOrganizationInput>;
  CustomObjectRecord?: InputMaybe<CustomObjectRecordUpdateManyWithoutOrganizationInput>;
  CustomObjectRow?: InputMaybe<CustomObjectRowUpdateManyWithoutOrganizationInput>;
  CustomQueueItems?: InputMaybe<CustomQueueItemUpdateManyWithoutOrganizationInput>;
  DashboardColumnOptions?: InputMaybe<DashboardColumnOptionUpdateManyWithoutOrganizationInput>;
  DashboardLeadFilters?: InputMaybe<DashboardLeadFilterUpdateManyWithoutOrganizationInput>;
  DashboardRepFilters?: InputMaybe<DashboardRepFilterUpdateManyWithoutOrganizationInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewUpdateManyWithoutOrganizationInput>;
  day_to_close?: InputMaybe<IntFieldUpdateOperationsInput>;
  default_demo_time_duration?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  demo_held_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_not_held_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_not_held_call_back_short_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_prep_time_minutes?: InputMaybe<IntFieldUpdateOperationsInput>;
  dev_account?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  dial_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  DispositionMultiplier?: InputMaybe<DispositionMultiplierUpdateManyWithoutOrganizationInput>;
  dispositions?: InputMaybe<DispositionUpdateManyWithoutOrganizationInput>;
  double_booking?: InputMaybe<BoolFieldUpdateOperationsInput>;
  dynamic_date_operators?: InputMaybe<BoolFieldUpdateOperationsInput>;
  edge_servers?: InputMaybe<OrganizationUpdateedge_ServersInput>;
  email_conflict_notification_email_list?: InputMaybe<OrganizationUpdateemail_Conflict_Notification_Email_ListInput>;
  EmailItems?: InputMaybe<EmailItemUpdateManyWithoutOrganizationInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordUpdateManyWithoutOrganizationInput>;
  EmailThreads?: InputMaybe<EmailThreadUpdateManyWithoutOrganizationInput>;
  enable_modified_metrics?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  enable_pay_period_view?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  enable_time_constraint?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  error_log_email_address?: InputMaybe<OrganizationUpdateerror_Log_Email_AddressInput>;
  estimated_average_num_of_weekly_overbooked_demo_slots?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  FolderPermissions?: InputMaybe<FolderPermissionUpdateManyWithoutOrganizationInput>;
  Folders?: InputMaybe<FolderUpdateManyWithoutOrganizationInput>;
  FutureBookingSetting?: InputMaybe<FutureBookingSettingUpdateOneWithoutOrganizationInput>;
  GlobalTransferRule?: InputMaybe<GlobalTransferRuleUpdateManyWithoutOrganizationInput>;
  held_demos_per_cc_connect?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  helpful_links?: InputMaybe<OrganizationUpdatehelpful_LinksInput>;
  HingePointMultiplier?: InputMaybe<HingePointMultiplierUpdateManyWithoutOrganizationInput>;
  hold_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
  hubspot_credential?: InputMaybe<HubSpotCredentialUpdateOneWithoutOrganizationInput>;
  hubspot_last_sync_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  hubspot_white_list?: InputMaybe<HubSpotWhitelistUpdateManyWithoutOrganizationInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inbound_close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_dm_connect_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_lt_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_revenue_value_of_sale?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_scheduled_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_set_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventUpdateManyWithoutOrganizationInput>;
  InboundConciergeWidget?: InputMaybe<InboundConciergeWidgetUpdateManyWithoutOrganizationInput>;
  industry_options?: InputMaybe<IndustryOptionUpdateManyWithoutOrganizationInput>;
  initial_sdr_sets_reps_day?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  integration_error_logs?: InputMaybe<IntegrationErrorLogUpdateManyWithoutOrganizationInput>;
  integration_mappings?: InputMaybe<IntegrationMappingUpdateManyWithoutOrganizationInput>;
  IntegrationMappingCustomObject?: InputMaybe<IntegrationMappingCustomObjectUpdateManyWithoutOrganizationInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogUpdateManyWithoutOrganizationInput>;
  IntegrationReadOnlyObject?: InputMaybe<IntegrationReadOnlyObjectUpdateManyWithoutOrganizationInput>;
  international?: InputMaybe<BoolFieldUpdateOperationsInput>;
  is_email_domain_verified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  is_salesforce_sandbox?: InputMaybe<BoolFieldUpdateOperationsInput>;
  last_lead_value_compute_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutOrganizationInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryUpdateManyWithoutOrganizationInput>;
  lead_intent?: InputMaybe<LeadIntentUpdateManyWithoutOrganizationInput>;
  lead_release_same_rep_delay_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  lead_routing_email_list?: InputMaybe<OrganizationUpdatelead_Routing_Email_ListInput>;
  lead_routing_rules?: InputMaybe<RuleUpdateManyWithoutOrganizationInput>;
  LeadCustomObjectRowAssociation?: InputMaybe<LeadCustomObjectRowAssociationUpdateManyWithoutOrganizationInput>;
  LeadFieldMultiplier?: InputMaybe<LeadFieldMultiplierUpdateManyWithoutOrganizationInput>;
  LeadFieldMultiplierCustom?: InputMaybe<LeadFieldMultiplierCustomUpdateManyWithoutOrganizationInput>;
  leads?: InputMaybe<LeadUpdateManyWithoutOrganizationInput>;
  leadsource_options?: InputMaybe<LeadSourceOptionUpdateManyWithoutOrganizationInput>;
  LeadValue?: InputMaybe<LeadValueUpdateManyWithoutOrganizationInput>;
  LifeTimeValueMultiplier?: InputMaybe<LifeTimeValueMultiplierUpdateManyWithoutOrganizationInput>;
  LiveTransferSetting?: InputMaybe<LiveTransferSettingUpdateOneWithoutOrganizationInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusUpdateManyWithoutOrganizationInput>;
  ltv_multiplier_custom_fields?: InputMaybe<LifeTimeValueMultiplierCustomUpdateManyWithoutOrganizationInput>;
  made_contact_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  make_sale_address_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_business_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_city_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_country_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_first_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_industry_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_last_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_lead_source_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_primary_email_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_primary_phone_number_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_state_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_sub_industry_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_zip_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  maxium_day_lag_set_to_schedule?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  mrr?: InputMaybe<IntFieldUpdateOperationsInput>;
  mrr_label?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  mrr_required_on_sale?: InputMaybe<BoolFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  NoteItems?: InputMaybe<NoteItemUpdateManyWithoutOrganizationInput>;
  Notifications?: InputMaybe<NotificationUpdateManyWithoutOrganizationInput>;
  onboardComplete?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  Opportunity?: InputMaybe<SfOpportunityUpdateManyWithoutOrganizationInput>;
  organization_holidays?: InputMaybe<OrganizationHolidayUpdateManyWithoutOrganizationInput>;
  organization_hours?: InputMaybe<OrganizationHourUpdateManyWithoutOrganizationInput>;
  OrganizationPaymentSetting?: InputMaybe<OrganizationPaymentSettingsUpdateOneWithoutOrganizationInput>;
  OrganizationPaymentSettings?: InputMaybe<OrganizationPaymentSettingsUpdateOneWithoutOrganizationsInput>;
  orgCalendarInviteMetadata?: InputMaybe<OrgCalendarInviteMetadataUpdateManyWithoutOrganizationInput>;
  OrgDefaultPipelinePerecentValue?: InputMaybe<OrgDefaultPipelinePerecentValueUpdateOneWithoutOrganizationInput>;
  OrgHubSpotSync?: InputMaybe<OrgHubSpotSyncUpdateOneWithoutOrganizationInput>;
  OrgNoEventDate?: InputMaybe<OrgNoEventDateUpdateManyWithoutOrganizationInput>;
  OrgSalesforceSync?: InputMaybe<OrgSalesforceSyncUpdateOneWithoutOrganizationInput>;
  OrgTimeslotAvailability?: InputMaybe<OrgTimeslotAvailabilityUpdateManyWithoutOrganizationInput>;
  outbound_close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_dm_connect_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_lt_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_revenue_value_of_sale?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_scheduled_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_set_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  PandadocData?: InputMaybe<PandadocDataUpdateOneWithoutOrganizationInput>;
  PandaDocTemplate?: InputMaybe<PandaDocTemplateUpdateManyWithoutOrganizationInput>;
  participant_jitter_buffer_size?: InputMaybe<EnumJitterBufferSizeFieldUpdateOperationsInput>;
  preferred_goal_setting?: InputMaybe<NullableEnumGoalsettingFieldUpdateOperationsInput>;
  prepayment?: InputMaybe<BoolFieldUpdateOperationsInput>;
  ProductChangeLog?: InputMaybe<ProductChangeLogUpdateManyWithoutOrganizationInput>;
  products?: InputMaybe<ProductUpdateManyWithoutOrganizationInput>;
  re_cold_call_hour_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  recordingStatus?: InputMaybe<EnumRecordingStatusFieldUpdateOperationsInput>;
  release_lead_resting?: InputMaybe<IntFieldUpdateOperationsInput>;
  rep_claim_limit?: InputMaybe<IntFieldUpdateOperationsInput>;
  rep_lead_allotment?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  rep_level_call_transfer?: InputMaybe<BoolFieldUpdateOperationsInput>;
  reps_can_bulk_unassign?: InputMaybe<BoolFieldUpdateOperationsInput>;
  reps_can_view_all_notes?: InputMaybe<BoolFieldUpdateOperationsInput>;
  require_sale_notes?: InputMaybe<BoolFieldUpdateOperationsInput>;
  resting_after_skipped_CC?: InputMaybe<IntFieldUpdateOperationsInput>;
  resting_period?: InputMaybe<IntFieldUpdateOperationsInput>;
  resting_period_after_unsuccessful_cold_call?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  resting_period_after_unsuccessful_demo?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  revenue_plan?: InputMaybe<RevenuePlanItemUpdateManyWithoutOrganizationInput>;
  Routings?: InputMaybe<RoutingUpdateManyWithoutOrganizationInput>;
  sale_field_options?: InputMaybe<SaleFieldOptionUpdateManyWithoutOrganizationInput>;
  sale_notification_email_list?: InputMaybe<OrganizationUpdatesale_Notification_Email_ListInput>;
  SaleConfig?: InputMaybe<SaleConfigUpdateOneWithoutOrganizationInput>;
  SaleConfigPage?: InputMaybe<SaleConfigPageUpdateManyWithoutOrganizationInput>;
  SaleConfigSection?: InputMaybe<SaleConfigSectionUpdateManyWithoutOrganizationInput>;
  SaleCycles?: InputMaybe<SaleCycleUpdateManyWithoutOrganizationInput>;
  SaleItems?: InputMaybe<SaleItemUpdateManyWithoutOrganizationInput>;
  sales?: InputMaybe<SaleUpdateManyWithoutOrganizationInput>;
  salesforce_domain?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  salesforce_last_sync_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  SalesForceColumn?: InputMaybe<SalesForceColumnUpdateManyWithoutOrganizationInput>;
  salesForceData?: InputMaybe<SalesForceDataUpdateOneWithoutOrganizationInput>;
  ScheduleItems?: InputMaybe<ScheduleItemUpdateManyWithoutOrganizationInput>;
  self_sourcing_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  send_error_log_email?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  send_lead_routing_email?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sendgrid_email_domain?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sendgrid_email_domain_id?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  sequence?: InputMaybe<SequenceUpdateManyWithoutOrganzationInput>;
  SequenceCriteriaConditions?: InputMaybe<SequenceCriteriaConditionUpdateManyWithoutOrganizationInput>;
  SequenceDashboardViews?: InputMaybe<SequenceDashboardViewUpdateManyWithoutOrganizationInput>;
  SequenceEntryCriterias?: InputMaybe<SequenceEntryCriteriaUpdateManyWithoutOrganizationInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutOrganizationInput>;
  SequenceSteps?: InputMaybe<SequenceStepUpdateManyWithoutOrganizationInput>;
  set_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
  short_resting?: InputMaybe<IntFieldUpdateOperationsInput>;
  show_calendar_options?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_contract_duration_sales?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_lead_source_to_rep?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_nocontact_button?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_payment_terms_sales?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_rep_all_leads?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_rep_my_leads?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_system_view?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sites?: InputMaybe<SiteUpdateManyWithoutOrganizationInput>;
  sms_opt_in_dialog?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SMSItems?: InputMaybe<SmsItemUpdateManyWithoutOrganizationInput>;
  SnoozeTrackings?: InputMaybe<SnoozeTrackingUpdateManyWithoutOrganizationInput>;
  StepActionLogs?: InputMaybe<StepActionLogUpdateManyWithoutOrganizationInput>;
  StepActions?: InputMaybe<StepActionUpdateManyWithoutOrganizationInput>;
  StepConditions?: InputMaybe<StepConditionUpdateManyWithoutOrganizationInput>;
  teams?: InputMaybe<TeamUpdateManyWithoutOrganizationInput>;
  Template?: InputMaybe<TemplateUpdateManyWithoutOrganizationInput>;
  TemplateVersions?: InputMaybe<TemplateVersionUpdateManyWithoutOrganizationInput>;
  timekit_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timezone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transfer_timeout?: InputMaybe<IntFieldUpdateOperationsInput>;
  TransferAttempts?: InputMaybe<TransferAttemptUpdateManyWithoutOrganizationInput>;
  twilio_numbers?: InputMaybe<TwilioNumberUpdateManyWithoutOrganizationInput>;
  TwilioFeedbacks?: InputMaybe<TwilioFeedbackUpdateManyWithoutOrganizationInput>;
  TwilioInfo?: InputMaybe<TwilioInfoUpdateOneWithoutOrganzationInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  use_revenue_planning?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  UserCustomFieldFilters?: InputMaybe<UserCustomFieldFilterUpdateManyWithoutOrganizationInput>;
  users?: InputMaybe<UserUpdateManyWithoutOrganizationInput>;
  win_rep_day_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
};

export type OrganizationUpdateWithoutContactRequestsInput = {
  ActivityMonitorMultiplier?: InputMaybe<ActivityMonitorMultiplierUpdateManyWithoutOrganizationInput>;
  algoFactor?: InputMaybe<AlgoFactorUpdateManyWithoutOrganizationInput>;
  allow_calendar_invite?: InputMaybe<BoolFieldUpdateOperationsInput>;
  allow_remove_step_action?: InputMaybe<BoolFieldUpdateOperationsInput>;
  allow_snooze_step_action?: InputMaybe<BoolFieldUpdateOperationsInput>;
  always_record?: InputMaybe<BoolFieldUpdateOperationsInput>;
  arv_initial_held_demos_generated_by_sdr_weight?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  AssociationHistories?: InputMaybe<AssociationHistoryUpdateManyWithoutOrganizationInput>;
  auto_call_interval?: InputMaybe<IntFieldUpdateOperationsInput>;
  auto_dial?: InputMaybe<BoolFieldUpdateOperationsInput>;
  automated_campaigns?: InputMaybe<AutomatedCampaignUpdateManyWithoutOrganizationInput>;
  automatic_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  AutoTransferSelections?: InputMaybe<AutoTransferSelectionUpdateManyWithoutOrganizationInput>;
  Availablities?: InputMaybe<AvailabilityUpdateManyWithoutOrganizationInput>;
  AverageOrderValue?: InputMaybe<AverageOrderValueUpdateManyWithoutOrganizationInput>;
  best_action_ever_multiplier?: InputMaybe<FloatFieldUpdateOperationsInput>;
  best_metrics?: InputMaybe<BestMetricRecordUpdateManyWithoutOrganizationInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptUpdateManyWithoutOrganizationInput>;
  callMeNows?: InputMaybe<CallMeNowUpdateManyWithoutOrganizationInput>;
  CallMultiplier?: InputMaybe<CallMultiplierUpdateManyWithoutOrganizationInput>;
  CallSharings?: InputMaybe<CallSharingUpdateManyWithoutOrganizationInput>;
  can_edit_sdr_transfer_rules?: InputMaybe<BoolFieldUpdateOperationsInput>;
  claim_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  concession?: InputMaybe<ConcessionUpdateManyWithoutOrganizationInput>;
  conference_region?: InputMaybe<EnumConferenceRegionFieldUpdateOperationsInput>;
  Conferences?: InputMaybe<ConferenceUpdateManyWithoutOrganizationInput>;
  Contacts?: InputMaybe<ContactUpdateManyWithoutOrganizationInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  custom_fields?: InputMaybe<CustomFieldUpdateManyWithoutOrganizationInput>;
  CustomObject?: InputMaybe<CustomObjectUpdateManyWithoutOrganizationInput>;
  CustomObjectField?: InputMaybe<CustomObjectFieldUpdateManyWithoutOrganizationInput>;
  CustomObjectFieldForeignKey?: InputMaybe<CustomObjectFieldForeignKeyUpdateManyWithoutOrganizationInput>;
  CustomObjectRecord?: InputMaybe<CustomObjectRecordUpdateManyWithoutOrganizationInput>;
  CustomObjectRow?: InputMaybe<CustomObjectRowUpdateManyWithoutOrganizationInput>;
  CustomQueueItems?: InputMaybe<CustomQueueItemUpdateManyWithoutOrganizationInput>;
  DashboardColumnOptions?: InputMaybe<DashboardColumnOptionUpdateManyWithoutOrganizationInput>;
  DashboardLeadFilters?: InputMaybe<DashboardLeadFilterUpdateManyWithoutOrganizationInput>;
  DashboardRepFilters?: InputMaybe<DashboardRepFilterUpdateManyWithoutOrganizationInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewUpdateManyWithoutOrganizationInput>;
  day_to_close?: InputMaybe<IntFieldUpdateOperationsInput>;
  default_demo_time_duration?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  demo_held_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_not_held_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_not_held_call_back_short_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_prep_time_minutes?: InputMaybe<IntFieldUpdateOperationsInput>;
  dev_account?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  dial_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  DispositionMultiplier?: InputMaybe<DispositionMultiplierUpdateManyWithoutOrganizationInput>;
  dispositions?: InputMaybe<DispositionUpdateManyWithoutOrganizationInput>;
  double_booking?: InputMaybe<BoolFieldUpdateOperationsInput>;
  dynamic_date_operators?: InputMaybe<BoolFieldUpdateOperationsInput>;
  edge_servers?: InputMaybe<OrganizationUpdateedge_ServersInput>;
  email_conflict_notification_email_list?: InputMaybe<OrganizationUpdateemail_Conflict_Notification_Email_ListInput>;
  EmailItems?: InputMaybe<EmailItemUpdateManyWithoutOrganizationInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordUpdateManyWithoutOrganizationInput>;
  EmailThreads?: InputMaybe<EmailThreadUpdateManyWithoutOrganizationInput>;
  enable_modified_metrics?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  enable_pay_period_view?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  enable_time_constraint?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  error_log_email_address?: InputMaybe<OrganizationUpdateerror_Log_Email_AddressInput>;
  estimated_average_num_of_weekly_overbooked_demo_slots?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  FolderPermissions?: InputMaybe<FolderPermissionUpdateManyWithoutOrganizationInput>;
  Folders?: InputMaybe<FolderUpdateManyWithoutOrganizationInput>;
  FutureBookingSetting?: InputMaybe<FutureBookingSettingUpdateOneWithoutOrganizationInput>;
  GlobalTransferRule?: InputMaybe<GlobalTransferRuleUpdateManyWithoutOrganizationInput>;
  held_demos_per_cc_connect?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  helpful_links?: InputMaybe<OrganizationUpdatehelpful_LinksInput>;
  HingePointMultiplier?: InputMaybe<HingePointMultiplierUpdateManyWithoutOrganizationInput>;
  hold_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
  hubspot_credential?: InputMaybe<HubSpotCredentialUpdateOneWithoutOrganizationInput>;
  hubspot_last_sync_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  hubspot_white_list?: InputMaybe<HubSpotWhitelistUpdateManyWithoutOrganizationInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inbound_close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_dm_connect_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_lt_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_revenue_value_of_sale?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_scheduled_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_set_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventUpdateManyWithoutOrganizationInput>;
  InboundConciergeWidget?: InputMaybe<InboundConciergeWidgetUpdateManyWithoutOrganizationInput>;
  industry_options?: InputMaybe<IndustryOptionUpdateManyWithoutOrganizationInput>;
  initial_sdr_sets_reps_day?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  integration_error_logs?: InputMaybe<IntegrationErrorLogUpdateManyWithoutOrganizationInput>;
  integration_mappings?: InputMaybe<IntegrationMappingUpdateManyWithoutOrganizationInput>;
  IntegrationMappingCustomObject?: InputMaybe<IntegrationMappingCustomObjectUpdateManyWithoutOrganizationInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogUpdateManyWithoutOrganizationInput>;
  IntegrationReadOnlyObject?: InputMaybe<IntegrationReadOnlyObjectUpdateManyWithoutOrganizationInput>;
  international?: InputMaybe<BoolFieldUpdateOperationsInput>;
  is_email_domain_verified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  is_salesforce_sandbox?: InputMaybe<BoolFieldUpdateOperationsInput>;
  last_lead_value_compute_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutOrganizationInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryUpdateManyWithoutOrganizationInput>;
  lead_intent?: InputMaybe<LeadIntentUpdateManyWithoutOrganizationInput>;
  lead_release_same_rep_delay_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  lead_routing_email_list?: InputMaybe<OrganizationUpdatelead_Routing_Email_ListInput>;
  lead_routing_rules?: InputMaybe<RuleUpdateManyWithoutOrganizationInput>;
  LeadCustomObjectRowAssociation?: InputMaybe<LeadCustomObjectRowAssociationUpdateManyWithoutOrganizationInput>;
  LeadFieldMultiplier?: InputMaybe<LeadFieldMultiplierUpdateManyWithoutOrganizationInput>;
  LeadFieldMultiplierCustom?: InputMaybe<LeadFieldMultiplierCustomUpdateManyWithoutOrganizationInput>;
  leads?: InputMaybe<LeadUpdateManyWithoutOrganizationInput>;
  leadsource_options?: InputMaybe<LeadSourceOptionUpdateManyWithoutOrganizationInput>;
  LeadValue?: InputMaybe<LeadValueUpdateManyWithoutOrganizationInput>;
  LifeTimeValueMultiplier?: InputMaybe<LifeTimeValueMultiplierUpdateManyWithoutOrganizationInput>;
  LiveTransferSetting?: InputMaybe<LiveTransferSettingUpdateOneWithoutOrganizationInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusUpdateManyWithoutOrganizationInput>;
  ltv_multiplier_custom_fields?: InputMaybe<LifeTimeValueMultiplierCustomUpdateManyWithoutOrganizationInput>;
  made_contact_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  make_sale_address_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_business_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_city_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_country_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_first_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_industry_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_last_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_lead_source_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_primary_email_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_primary_phone_number_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_state_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_sub_industry_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_zip_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  maxium_day_lag_set_to_schedule?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  mrr?: InputMaybe<IntFieldUpdateOperationsInput>;
  mrr_label?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  mrr_required_on_sale?: InputMaybe<BoolFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  NoteItems?: InputMaybe<NoteItemUpdateManyWithoutOrganizationInput>;
  Notifications?: InputMaybe<NotificationUpdateManyWithoutOrganizationInput>;
  onboardComplete?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  Opportunity?: InputMaybe<SfOpportunityUpdateManyWithoutOrganizationInput>;
  organization_holidays?: InputMaybe<OrganizationHolidayUpdateManyWithoutOrganizationInput>;
  organization_hours?: InputMaybe<OrganizationHourUpdateManyWithoutOrganizationInput>;
  OrganizationPaymentSetting?: InputMaybe<OrganizationPaymentSettingsUpdateOneWithoutOrganizationInput>;
  OrganizationPaymentSettings?: InputMaybe<OrganizationPaymentSettingsUpdateOneWithoutOrganizationsInput>;
  orgCalendarInviteMetadata?: InputMaybe<OrgCalendarInviteMetadataUpdateManyWithoutOrganizationInput>;
  OrgDefaultPipelinePerecentValue?: InputMaybe<OrgDefaultPipelinePerecentValueUpdateOneWithoutOrganizationInput>;
  OrgHubSpotSync?: InputMaybe<OrgHubSpotSyncUpdateOneWithoutOrganizationInput>;
  OrgNoEventDate?: InputMaybe<OrgNoEventDateUpdateManyWithoutOrganizationInput>;
  OrgSalesforceSync?: InputMaybe<OrgSalesforceSyncUpdateOneWithoutOrganizationInput>;
  OrgTimeslotAvailability?: InputMaybe<OrgTimeslotAvailabilityUpdateManyWithoutOrganizationInput>;
  outbound_close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_dm_connect_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_lt_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_revenue_value_of_sale?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_scheduled_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_set_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  PandadocData?: InputMaybe<PandadocDataUpdateOneWithoutOrganizationInput>;
  PandaDocTemplate?: InputMaybe<PandaDocTemplateUpdateManyWithoutOrganizationInput>;
  participant_jitter_buffer_size?: InputMaybe<EnumJitterBufferSizeFieldUpdateOperationsInput>;
  preferred_goal_setting?: InputMaybe<NullableEnumGoalsettingFieldUpdateOperationsInput>;
  prepayment?: InputMaybe<BoolFieldUpdateOperationsInput>;
  ProductChangeLog?: InputMaybe<ProductChangeLogUpdateManyWithoutOrganizationInput>;
  products?: InputMaybe<ProductUpdateManyWithoutOrganizationInput>;
  re_cold_call_hour_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  recordingStatus?: InputMaybe<EnumRecordingStatusFieldUpdateOperationsInput>;
  release_lead_resting?: InputMaybe<IntFieldUpdateOperationsInput>;
  rep_claim_limit?: InputMaybe<IntFieldUpdateOperationsInput>;
  rep_lead_allotment?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  rep_level_call_transfer?: InputMaybe<BoolFieldUpdateOperationsInput>;
  reps_can_bulk_unassign?: InputMaybe<BoolFieldUpdateOperationsInput>;
  reps_can_view_all_notes?: InputMaybe<BoolFieldUpdateOperationsInput>;
  require_sale_notes?: InputMaybe<BoolFieldUpdateOperationsInput>;
  resting_after_skipped_CC?: InputMaybe<IntFieldUpdateOperationsInput>;
  resting_period?: InputMaybe<IntFieldUpdateOperationsInput>;
  resting_period_after_unsuccessful_cold_call?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  resting_period_after_unsuccessful_demo?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  revenue_plan?: InputMaybe<RevenuePlanItemUpdateManyWithoutOrganizationInput>;
  Routings?: InputMaybe<RoutingUpdateManyWithoutOrganizationInput>;
  sale_field_options?: InputMaybe<SaleFieldOptionUpdateManyWithoutOrganizationInput>;
  sale_notification_email_list?: InputMaybe<OrganizationUpdatesale_Notification_Email_ListInput>;
  SaleConfig?: InputMaybe<SaleConfigUpdateOneWithoutOrganizationInput>;
  SaleConfigPage?: InputMaybe<SaleConfigPageUpdateManyWithoutOrganizationInput>;
  SaleConfigSection?: InputMaybe<SaleConfigSectionUpdateManyWithoutOrganizationInput>;
  SaleCycles?: InputMaybe<SaleCycleUpdateManyWithoutOrganizationInput>;
  SaleItems?: InputMaybe<SaleItemUpdateManyWithoutOrganizationInput>;
  sales?: InputMaybe<SaleUpdateManyWithoutOrganizationInput>;
  salesforce_domain?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  salesforce_last_sync_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  SalesForceColumn?: InputMaybe<SalesForceColumnUpdateManyWithoutOrganizationInput>;
  salesForceData?: InputMaybe<SalesForceDataUpdateOneWithoutOrganizationInput>;
  ScheduleItems?: InputMaybe<ScheduleItemUpdateManyWithoutOrganizationInput>;
  self_sourcing_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  send_error_log_email?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  send_lead_routing_email?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sendgrid_email_domain?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sendgrid_email_domain_id?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  sequence?: InputMaybe<SequenceUpdateManyWithoutOrganzationInput>;
  SequenceCriteriaConditions?: InputMaybe<SequenceCriteriaConditionUpdateManyWithoutOrganizationInput>;
  SequenceDashboardViews?: InputMaybe<SequenceDashboardViewUpdateManyWithoutOrganizationInput>;
  SequenceEntryCriterias?: InputMaybe<SequenceEntryCriteriaUpdateManyWithoutOrganizationInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutOrganizationInput>;
  SequenceSteps?: InputMaybe<SequenceStepUpdateManyWithoutOrganizationInput>;
  set_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
  short_resting?: InputMaybe<IntFieldUpdateOperationsInput>;
  show_calendar_options?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_contract_duration_sales?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_lead_source_to_rep?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_nocontact_button?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_payment_terms_sales?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_rep_all_leads?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_rep_my_leads?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_system_view?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sites?: InputMaybe<SiteUpdateManyWithoutOrganizationInput>;
  sms_opt_in_dialog?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SMSItems?: InputMaybe<SmsItemUpdateManyWithoutOrganizationInput>;
  SnoozeTrackings?: InputMaybe<SnoozeTrackingUpdateManyWithoutOrganizationInput>;
  StepActionLogs?: InputMaybe<StepActionLogUpdateManyWithoutOrganizationInput>;
  StepActions?: InputMaybe<StepActionUpdateManyWithoutOrganizationInput>;
  StepConditions?: InputMaybe<StepConditionUpdateManyWithoutOrganizationInput>;
  teams?: InputMaybe<TeamUpdateManyWithoutOrganizationInput>;
  Template?: InputMaybe<TemplateUpdateManyWithoutOrganizationInput>;
  TemplateVersions?: InputMaybe<TemplateVersionUpdateManyWithoutOrganizationInput>;
  timekit_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timezone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transfer_timeout?: InputMaybe<IntFieldUpdateOperationsInput>;
  TransferAttempts?: InputMaybe<TransferAttemptUpdateManyWithoutOrganizationInput>;
  twilio_numbers?: InputMaybe<TwilioNumberUpdateManyWithoutOrganizationInput>;
  TwilioFeedbacks?: InputMaybe<TwilioFeedbackUpdateManyWithoutOrganizationInput>;
  TwilioInfo?: InputMaybe<TwilioInfoUpdateOneWithoutOrganzationInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  use_revenue_planning?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  UserCustomFieldFilters?: InputMaybe<UserCustomFieldFilterUpdateManyWithoutOrganizationInput>;
  users?: InputMaybe<UserUpdateManyWithoutOrganizationInput>;
  win_rep_day_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
};

export type OrganizationUpdateWithoutContactsInput = {
  ActivityMonitorMultiplier?: InputMaybe<ActivityMonitorMultiplierUpdateManyWithoutOrganizationInput>;
  algoFactor?: InputMaybe<AlgoFactorUpdateManyWithoutOrganizationInput>;
  allow_calendar_invite?: InputMaybe<BoolFieldUpdateOperationsInput>;
  allow_remove_step_action?: InputMaybe<BoolFieldUpdateOperationsInput>;
  allow_snooze_step_action?: InputMaybe<BoolFieldUpdateOperationsInput>;
  always_record?: InputMaybe<BoolFieldUpdateOperationsInput>;
  arv_initial_held_demos_generated_by_sdr_weight?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  AssociationHistories?: InputMaybe<AssociationHistoryUpdateManyWithoutOrganizationInput>;
  auto_call_interval?: InputMaybe<IntFieldUpdateOperationsInput>;
  auto_dial?: InputMaybe<BoolFieldUpdateOperationsInput>;
  automated_campaigns?: InputMaybe<AutomatedCampaignUpdateManyWithoutOrganizationInput>;
  automatic_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  AutoTransferSelections?: InputMaybe<AutoTransferSelectionUpdateManyWithoutOrganizationInput>;
  Availablities?: InputMaybe<AvailabilityUpdateManyWithoutOrganizationInput>;
  AverageOrderValue?: InputMaybe<AverageOrderValueUpdateManyWithoutOrganizationInput>;
  best_action_ever_multiplier?: InputMaybe<FloatFieldUpdateOperationsInput>;
  best_metrics?: InputMaybe<BestMetricRecordUpdateManyWithoutOrganizationInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptUpdateManyWithoutOrganizationInput>;
  callMeNows?: InputMaybe<CallMeNowUpdateManyWithoutOrganizationInput>;
  CallMultiplier?: InputMaybe<CallMultiplierUpdateManyWithoutOrganizationInput>;
  CallSharings?: InputMaybe<CallSharingUpdateManyWithoutOrganizationInput>;
  can_edit_sdr_transfer_rules?: InputMaybe<BoolFieldUpdateOperationsInput>;
  claim_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  concession?: InputMaybe<ConcessionUpdateManyWithoutOrganizationInput>;
  conference_region?: InputMaybe<EnumConferenceRegionFieldUpdateOperationsInput>;
  Conferences?: InputMaybe<ConferenceUpdateManyWithoutOrganizationInput>;
  ContactRequests?: InputMaybe<ContactRequestUpdateManyWithoutOrganizationInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  custom_fields?: InputMaybe<CustomFieldUpdateManyWithoutOrganizationInput>;
  CustomObject?: InputMaybe<CustomObjectUpdateManyWithoutOrganizationInput>;
  CustomObjectField?: InputMaybe<CustomObjectFieldUpdateManyWithoutOrganizationInput>;
  CustomObjectFieldForeignKey?: InputMaybe<CustomObjectFieldForeignKeyUpdateManyWithoutOrganizationInput>;
  CustomObjectRecord?: InputMaybe<CustomObjectRecordUpdateManyWithoutOrganizationInput>;
  CustomObjectRow?: InputMaybe<CustomObjectRowUpdateManyWithoutOrganizationInput>;
  CustomQueueItems?: InputMaybe<CustomQueueItemUpdateManyWithoutOrganizationInput>;
  DashboardColumnOptions?: InputMaybe<DashboardColumnOptionUpdateManyWithoutOrganizationInput>;
  DashboardLeadFilters?: InputMaybe<DashboardLeadFilterUpdateManyWithoutOrganizationInput>;
  DashboardRepFilters?: InputMaybe<DashboardRepFilterUpdateManyWithoutOrganizationInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewUpdateManyWithoutOrganizationInput>;
  day_to_close?: InputMaybe<IntFieldUpdateOperationsInput>;
  default_demo_time_duration?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  demo_held_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_not_held_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_not_held_call_back_short_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_prep_time_minutes?: InputMaybe<IntFieldUpdateOperationsInput>;
  dev_account?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  dial_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  DispositionMultiplier?: InputMaybe<DispositionMultiplierUpdateManyWithoutOrganizationInput>;
  dispositions?: InputMaybe<DispositionUpdateManyWithoutOrganizationInput>;
  double_booking?: InputMaybe<BoolFieldUpdateOperationsInput>;
  dynamic_date_operators?: InputMaybe<BoolFieldUpdateOperationsInput>;
  edge_servers?: InputMaybe<OrganizationUpdateedge_ServersInput>;
  email_conflict_notification_email_list?: InputMaybe<OrganizationUpdateemail_Conflict_Notification_Email_ListInput>;
  EmailItems?: InputMaybe<EmailItemUpdateManyWithoutOrganizationInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordUpdateManyWithoutOrganizationInput>;
  EmailThreads?: InputMaybe<EmailThreadUpdateManyWithoutOrganizationInput>;
  enable_modified_metrics?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  enable_pay_period_view?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  enable_time_constraint?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  error_log_email_address?: InputMaybe<OrganizationUpdateerror_Log_Email_AddressInput>;
  estimated_average_num_of_weekly_overbooked_demo_slots?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  FolderPermissions?: InputMaybe<FolderPermissionUpdateManyWithoutOrganizationInput>;
  Folders?: InputMaybe<FolderUpdateManyWithoutOrganizationInput>;
  FutureBookingSetting?: InputMaybe<FutureBookingSettingUpdateOneWithoutOrganizationInput>;
  GlobalTransferRule?: InputMaybe<GlobalTransferRuleUpdateManyWithoutOrganizationInput>;
  held_demos_per_cc_connect?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  helpful_links?: InputMaybe<OrganizationUpdatehelpful_LinksInput>;
  HingePointMultiplier?: InputMaybe<HingePointMultiplierUpdateManyWithoutOrganizationInput>;
  hold_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
  hubspot_credential?: InputMaybe<HubSpotCredentialUpdateOneWithoutOrganizationInput>;
  hubspot_last_sync_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  hubspot_white_list?: InputMaybe<HubSpotWhitelistUpdateManyWithoutOrganizationInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inbound_close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_dm_connect_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_lt_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_revenue_value_of_sale?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_scheduled_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_set_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventUpdateManyWithoutOrganizationInput>;
  InboundConciergeWidget?: InputMaybe<InboundConciergeWidgetUpdateManyWithoutOrganizationInput>;
  industry_options?: InputMaybe<IndustryOptionUpdateManyWithoutOrganizationInput>;
  initial_sdr_sets_reps_day?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  integration_error_logs?: InputMaybe<IntegrationErrorLogUpdateManyWithoutOrganizationInput>;
  integration_mappings?: InputMaybe<IntegrationMappingUpdateManyWithoutOrganizationInput>;
  IntegrationMappingCustomObject?: InputMaybe<IntegrationMappingCustomObjectUpdateManyWithoutOrganizationInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogUpdateManyWithoutOrganizationInput>;
  IntegrationReadOnlyObject?: InputMaybe<IntegrationReadOnlyObjectUpdateManyWithoutOrganizationInput>;
  international?: InputMaybe<BoolFieldUpdateOperationsInput>;
  is_email_domain_verified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  is_salesforce_sandbox?: InputMaybe<BoolFieldUpdateOperationsInput>;
  last_lead_value_compute_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutOrganizationInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryUpdateManyWithoutOrganizationInput>;
  lead_intent?: InputMaybe<LeadIntentUpdateManyWithoutOrganizationInput>;
  lead_release_same_rep_delay_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  lead_routing_email_list?: InputMaybe<OrganizationUpdatelead_Routing_Email_ListInput>;
  lead_routing_rules?: InputMaybe<RuleUpdateManyWithoutOrganizationInput>;
  LeadCustomObjectRowAssociation?: InputMaybe<LeadCustomObjectRowAssociationUpdateManyWithoutOrganizationInput>;
  LeadFieldMultiplier?: InputMaybe<LeadFieldMultiplierUpdateManyWithoutOrganizationInput>;
  LeadFieldMultiplierCustom?: InputMaybe<LeadFieldMultiplierCustomUpdateManyWithoutOrganizationInput>;
  leads?: InputMaybe<LeadUpdateManyWithoutOrganizationInput>;
  leadsource_options?: InputMaybe<LeadSourceOptionUpdateManyWithoutOrganizationInput>;
  LeadValue?: InputMaybe<LeadValueUpdateManyWithoutOrganizationInput>;
  LifeTimeValueMultiplier?: InputMaybe<LifeTimeValueMultiplierUpdateManyWithoutOrganizationInput>;
  LiveTransferSetting?: InputMaybe<LiveTransferSettingUpdateOneWithoutOrganizationInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusUpdateManyWithoutOrganizationInput>;
  ltv_multiplier_custom_fields?: InputMaybe<LifeTimeValueMultiplierCustomUpdateManyWithoutOrganizationInput>;
  made_contact_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  make_sale_address_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_business_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_city_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_country_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_first_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_industry_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_last_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_lead_source_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_primary_email_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_primary_phone_number_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_state_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_sub_industry_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_zip_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  maxium_day_lag_set_to_schedule?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  mrr?: InputMaybe<IntFieldUpdateOperationsInput>;
  mrr_label?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  mrr_required_on_sale?: InputMaybe<BoolFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  NoteItems?: InputMaybe<NoteItemUpdateManyWithoutOrganizationInput>;
  Notifications?: InputMaybe<NotificationUpdateManyWithoutOrganizationInput>;
  onboardComplete?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  Opportunity?: InputMaybe<SfOpportunityUpdateManyWithoutOrganizationInput>;
  organization_holidays?: InputMaybe<OrganizationHolidayUpdateManyWithoutOrganizationInput>;
  organization_hours?: InputMaybe<OrganizationHourUpdateManyWithoutOrganizationInput>;
  OrganizationPaymentSetting?: InputMaybe<OrganizationPaymentSettingsUpdateOneWithoutOrganizationInput>;
  OrganizationPaymentSettings?: InputMaybe<OrganizationPaymentSettingsUpdateOneWithoutOrganizationsInput>;
  orgCalendarInviteMetadata?: InputMaybe<OrgCalendarInviteMetadataUpdateManyWithoutOrganizationInput>;
  OrgDefaultPipelinePerecentValue?: InputMaybe<OrgDefaultPipelinePerecentValueUpdateOneWithoutOrganizationInput>;
  OrgHubSpotSync?: InputMaybe<OrgHubSpotSyncUpdateOneWithoutOrganizationInput>;
  OrgNoEventDate?: InputMaybe<OrgNoEventDateUpdateManyWithoutOrganizationInput>;
  OrgSalesforceSync?: InputMaybe<OrgSalesforceSyncUpdateOneWithoutOrganizationInput>;
  OrgTimeslotAvailability?: InputMaybe<OrgTimeslotAvailabilityUpdateManyWithoutOrganizationInput>;
  outbound_close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_dm_connect_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_lt_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_revenue_value_of_sale?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_scheduled_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_set_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  PandadocData?: InputMaybe<PandadocDataUpdateOneWithoutOrganizationInput>;
  PandaDocTemplate?: InputMaybe<PandaDocTemplateUpdateManyWithoutOrganizationInput>;
  participant_jitter_buffer_size?: InputMaybe<EnumJitterBufferSizeFieldUpdateOperationsInput>;
  preferred_goal_setting?: InputMaybe<NullableEnumGoalsettingFieldUpdateOperationsInput>;
  prepayment?: InputMaybe<BoolFieldUpdateOperationsInput>;
  ProductChangeLog?: InputMaybe<ProductChangeLogUpdateManyWithoutOrganizationInput>;
  products?: InputMaybe<ProductUpdateManyWithoutOrganizationInput>;
  re_cold_call_hour_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  recordingStatus?: InputMaybe<EnumRecordingStatusFieldUpdateOperationsInput>;
  release_lead_resting?: InputMaybe<IntFieldUpdateOperationsInput>;
  rep_claim_limit?: InputMaybe<IntFieldUpdateOperationsInput>;
  rep_lead_allotment?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  rep_level_call_transfer?: InputMaybe<BoolFieldUpdateOperationsInput>;
  reps_can_bulk_unassign?: InputMaybe<BoolFieldUpdateOperationsInput>;
  reps_can_view_all_notes?: InputMaybe<BoolFieldUpdateOperationsInput>;
  require_sale_notes?: InputMaybe<BoolFieldUpdateOperationsInput>;
  resting_after_skipped_CC?: InputMaybe<IntFieldUpdateOperationsInput>;
  resting_period?: InputMaybe<IntFieldUpdateOperationsInput>;
  resting_period_after_unsuccessful_cold_call?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  resting_period_after_unsuccessful_demo?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  revenue_plan?: InputMaybe<RevenuePlanItemUpdateManyWithoutOrganizationInput>;
  Routings?: InputMaybe<RoutingUpdateManyWithoutOrganizationInput>;
  sale_field_options?: InputMaybe<SaleFieldOptionUpdateManyWithoutOrganizationInput>;
  sale_notification_email_list?: InputMaybe<OrganizationUpdatesale_Notification_Email_ListInput>;
  SaleConfig?: InputMaybe<SaleConfigUpdateOneWithoutOrganizationInput>;
  SaleConfigPage?: InputMaybe<SaleConfigPageUpdateManyWithoutOrganizationInput>;
  SaleConfigSection?: InputMaybe<SaleConfigSectionUpdateManyWithoutOrganizationInput>;
  SaleCycles?: InputMaybe<SaleCycleUpdateManyWithoutOrganizationInput>;
  SaleItems?: InputMaybe<SaleItemUpdateManyWithoutOrganizationInput>;
  sales?: InputMaybe<SaleUpdateManyWithoutOrganizationInput>;
  salesforce_domain?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  salesforce_last_sync_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  SalesForceColumn?: InputMaybe<SalesForceColumnUpdateManyWithoutOrganizationInput>;
  salesForceData?: InputMaybe<SalesForceDataUpdateOneWithoutOrganizationInput>;
  ScheduleItems?: InputMaybe<ScheduleItemUpdateManyWithoutOrganizationInput>;
  self_sourcing_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  send_error_log_email?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  send_lead_routing_email?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sendgrid_email_domain?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sendgrid_email_domain_id?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  sequence?: InputMaybe<SequenceUpdateManyWithoutOrganzationInput>;
  SequenceCriteriaConditions?: InputMaybe<SequenceCriteriaConditionUpdateManyWithoutOrganizationInput>;
  SequenceDashboardViews?: InputMaybe<SequenceDashboardViewUpdateManyWithoutOrganizationInput>;
  SequenceEntryCriterias?: InputMaybe<SequenceEntryCriteriaUpdateManyWithoutOrganizationInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutOrganizationInput>;
  SequenceSteps?: InputMaybe<SequenceStepUpdateManyWithoutOrganizationInput>;
  set_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
  short_resting?: InputMaybe<IntFieldUpdateOperationsInput>;
  show_calendar_options?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_contract_duration_sales?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_lead_source_to_rep?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_nocontact_button?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_payment_terms_sales?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_rep_all_leads?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_rep_my_leads?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_system_view?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sites?: InputMaybe<SiteUpdateManyWithoutOrganizationInput>;
  sms_opt_in_dialog?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SMSItems?: InputMaybe<SmsItemUpdateManyWithoutOrganizationInput>;
  SnoozeTrackings?: InputMaybe<SnoozeTrackingUpdateManyWithoutOrganizationInput>;
  StepActionLogs?: InputMaybe<StepActionLogUpdateManyWithoutOrganizationInput>;
  StepActions?: InputMaybe<StepActionUpdateManyWithoutOrganizationInput>;
  StepConditions?: InputMaybe<StepConditionUpdateManyWithoutOrganizationInput>;
  teams?: InputMaybe<TeamUpdateManyWithoutOrganizationInput>;
  Template?: InputMaybe<TemplateUpdateManyWithoutOrganizationInput>;
  TemplateVersions?: InputMaybe<TemplateVersionUpdateManyWithoutOrganizationInput>;
  timekit_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timezone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transfer_timeout?: InputMaybe<IntFieldUpdateOperationsInput>;
  TransferAttempts?: InputMaybe<TransferAttemptUpdateManyWithoutOrganizationInput>;
  twilio_numbers?: InputMaybe<TwilioNumberUpdateManyWithoutOrganizationInput>;
  TwilioFeedbacks?: InputMaybe<TwilioFeedbackUpdateManyWithoutOrganizationInput>;
  TwilioInfo?: InputMaybe<TwilioInfoUpdateOneWithoutOrganzationInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  use_revenue_planning?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  UserCustomFieldFilters?: InputMaybe<UserCustomFieldFilterUpdateManyWithoutOrganizationInput>;
  users?: InputMaybe<UserUpdateManyWithoutOrganizationInput>;
  win_rep_day_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
};

export type OrganizationUpdateWithoutCustom_FieldsInput = {
  ActivityMonitorMultiplier?: InputMaybe<ActivityMonitorMultiplierUpdateManyWithoutOrganizationInput>;
  algoFactor?: InputMaybe<AlgoFactorUpdateManyWithoutOrganizationInput>;
  allow_calendar_invite?: InputMaybe<BoolFieldUpdateOperationsInput>;
  allow_remove_step_action?: InputMaybe<BoolFieldUpdateOperationsInput>;
  allow_snooze_step_action?: InputMaybe<BoolFieldUpdateOperationsInput>;
  always_record?: InputMaybe<BoolFieldUpdateOperationsInput>;
  arv_initial_held_demos_generated_by_sdr_weight?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  AssociationHistories?: InputMaybe<AssociationHistoryUpdateManyWithoutOrganizationInput>;
  auto_call_interval?: InputMaybe<IntFieldUpdateOperationsInput>;
  auto_dial?: InputMaybe<BoolFieldUpdateOperationsInput>;
  automated_campaigns?: InputMaybe<AutomatedCampaignUpdateManyWithoutOrganizationInput>;
  automatic_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  AutoTransferSelections?: InputMaybe<AutoTransferSelectionUpdateManyWithoutOrganizationInput>;
  Availablities?: InputMaybe<AvailabilityUpdateManyWithoutOrganizationInput>;
  AverageOrderValue?: InputMaybe<AverageOrderValueUpdateManyWithoutOrganizationInput>;
  best_action_ever_multiplier?: InputMaybe<FloatFieldUpdateOperationsInput>;
  best_metrics?: InputMaybe<BestMetricRecordUpdateManyWithoutOrganizationInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptUpdateManyWithoutOrganizationInput>;
  callMeNows?: InputMaybe<CallMeNowUpdateManyWithoutOrganizationInput>;
  CallMultiplier?: InputMaybe<CallMultiplierUpdateManyWithoutOrganizationInput>;
  CallSharings?: InputMaybe<CallSharingUpdateManyWithoutOrganizationInput>;
  can_edit_sdr_transfer_rules?: InputMaybe<BoolFieldUpdateOperationsInput>;
  claim_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  concession?: InputMaybe<ConcessionUpdateManyWithoutOrganizationInput>;
  conference_region?: InputMaybe<EnumConferenceRegionFieldUpdateOperationsInput>;
  Conferences?: InputMaybe<ConferenceUpdateManyWithoutOrganizationInput>;
  ContactRequests?: InputMaybe<ContactRequestUpdateManyWithoutOrganizationInput>;
  Contacts?: InputMaybe<ContactUpdateManyWithoutOrganizationInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  CustomObject?: InputMaybe<CustomObjectUpdateManyWithoutOrganizationInput>;
  CustomObjectField?: InputMaybe<CustomObjectFieldUpdateManyWithoutOrganizationInput>;
  CustomObjectFieldForeignKey?: InputMaybe<CustomObjectFieldForeignKeyUpdateManyWithoutOrganizationInput>;
  CustomObjectRecord?: InputMaybe<CustomObjectRecordUpdateManyWithoutOrganizationInput>;
  CustomObjectRow?: InputMaybe<CustomObjectRowUpdateManyWithoutOrganizationInput>;
  CustomQueueItems?: InputMaybe<CustomQueueItemUpdateManyWithoutOrganizationInput>;
  DashboardColumnOptions?: InputMaybe<DashboardColumnOptionUpdateManyWithoutOrganizationInput>;
  DashboardLeadFilters?: InputMaybe<DashboardLeadFilterUpdateManyWithoutOrganizationInput>;
  DashboardRepFilters?: InputMaybe<DashboardRepFilterUpdateManyWithoutOrganizationInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewUpdateManyWithoutOrganizationInput>;
  day_to_close?: InputMaybe<IntFieldUpdateOperationsInput>;
  default_demo_time_duration?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  demo_held_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_not_held_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_not_held_call_back_short_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_prep_time_minutes?: InputMaybe<IntFieldUpdateOperationsInput>;
  dev_account?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  dial_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  DispositionMultiplier?: InputMaybe<DispositionMultiplierUpdateManyWithoutOrganizationInput>;
  dispositions?: InputMaybe<DispositionUpdateManyWithoutOrganizationInput>;
  double_booking?: InputMaybe<BoolFieldUpdateOperationsInput>;
  dynamic_date_operators?: InputMaybe<BoolFieldUpdateOperationsInput>;
  edge_servers?: InputMaybe<OrganizationUpdateedge_ServersInput>;
  email_conflict_notification_email_list?: InputMaybe<OrganizationUpdateemail_Conflict_Notification_Email_ListInput>;
  EmailItems?: InputMaybe<EmailItemUpdateManyWithoutOrganizationInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordUpdateManyWithoutOrganizationInput>;
  EmailThreads?: InputMaybe<EmailThreadUpdateManyWithoutOrganizationInput>;
  enable_modified_metrics?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  enable_pay_period_view?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  enable_time_constraint?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  error_log_email_address?: InputMaybe<OrganizationUpdateerror_Log_Email_AddressInput>;
  estimated_average_num_of_weekly_overbooked_demo_slots?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  FolderPermissions?: InputMaybe<FolderPermissionUpdateManyWithoutOrganizationInput>;
  Folders?: InputMaybe<FolderUpdateManyWithoutOrganizationInput>;
  FutureBookingSetting?: InputMaybe<FutureBookingSettingUpdateOneWithoutOrganizationInput>;
  GlobalTransferRule?: InputMaybe<GlobalTransferRuleUpdateManyWithoutOrganizationInput>;
  held_demos_per_cc_connect?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  helpful_links?: InputMaybe<OrganizationUpdatehelpful_LinksInput>;
  HingePointMultiplier?: InputMaybe<HingePointMultiplierUpdateManyWithoutOrganizationInput>;
  hold_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
  hubspot_credential?: InputMaybe<HubSpotCredentialUpdateOneWithoutOrganizationInput>;
  hubspot_last_sync_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  hubspot_white_list?: InputMaybe<HubSpotWhitelistUpdateManyWithoutOrganizationInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inbound_close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_dm_connect_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_lt_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_revenue_value_of_sale?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_scheduled_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_set_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventUpdateManyWithoutOrganizationInput>;
  InboundConciergeWidget?: InputMaybe<InboundConciergeWidgetUpdateManyWithoutOrganizationInput>;
  industry_options?: InputMaybe<IndustryOptionUpdateManyWithoutOrganizationInput>;
  initial_sdr_sets_reps_day?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  integration_error_logs?: InputMaybe<IntegrationErrorLogUpdateManyWithoutOrganizationInput>;
  integration_mappings?: InputMaybe<IntegrationMappingUpdateManyWithoutOrganizationInput>;
  IntegrationMappingCustomObject?: InputMaybe<IntegrationMappingCustomObjectUpdateManyWithoutOrganizationInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogUpdateManyWithoutOrganizationInput>;
  IntegrationReadOnlyObject?: InputMaybe<IntegrationReadOnlyObjectUpdateManyWithoutOrganizationInput>;
  international?: InputMaybe<BoolFieldUpdateOperationsInput>;
  is_email_domain_verified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  is_salesforce_sandbox?: InputMaybe<BoolFieldUpdateOperationsInput>;
  last_lead_value_compute_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutOrganizationInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryUpdateManyWithoutOrganizationInput>;
  lead_intent?: InputMaybe<LeadIntentUpdateManyWithoutOrganizationInput>;
  lead_release_same_rep_delay_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  lead_routing_email_list?: InputMaybe<OrganizationUpdatelead_Routing_Email_ListInput>;
  lead_routing_rules?: InputMaybe<RuleUpdateManyWithoutOrganizationInput>;
  LeadCustomObjectRowAssociation?: InputMaybe<LeadCustomObjectRowAssociationUpdateManyWithoutOrganizationInput>;
  LeadFieldMultiplier?: InputMaybe<LeadFieldMultiplierUpdateManyWithoutOrganizationInput>;
  LeadFieldMultiplierCustom?: InputMaybe<LeadFieldMultiplierCustomUpdateManyWithoutOrganizationInput>;
  leads?: InputMaybe<LeadUpdateManyWithoutOrganizationInput>;
  leadsource_options?: InputMaybe<LeadSourceOptionUpdateManyWithoutOrganizationInput>;
  LeadValue?: InputMaybe<LeadValueUpdateManyWithoutOrganizationInput>;
  LifeTimeValueMultiplier?: InputMaybe<LifeTimeValueMultiplierUpdateManyWithoutOrganizationInput>;
  LiveTransferSetting?: InputMaybe<LiveTransferSettingUpdateOneWithoutOrganizationInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusUpdateManyWithoutOrganizationInput>;
  ltv_multiplier_custom_fields?: InputMaybe<LifeTimeValueMultiplierCustomUpdateManyWithoutOrganizationInput>;
  made_contact_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  make_sale_address_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_business_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_city_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_country_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_first_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_industry_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_last_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_lead_source_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_primary_email_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_primary_phone_number_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_state_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_sub_industry_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_zip_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  maxium_day_lag_set_to_schedule?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  mrr?: InputMaybe<IntFieldUpdateOperationsInput>;
  mrr_label?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  mrr_required_on_sale?: InputMaybe<BoolFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  NoteItems?: InputMaybe<NoteItemUpdateManyWithoutOrganizationInput>;
  Notifications?: InputMaybe<NotificationUpdateManyWithoutOrganizationInput>;
  onboardComplete?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  Opportunity?: InputMaybe<SfOpportunityUpdateManyWithoutOrganizationInput>;
  organization_holidays?: InputMaybe<OrganizationHolidayUpdateManyWithoutOrganizationInput>;
  organization_hours?: InputMaybe<OrganizationHourUpdateManyWithoutOrganizationInput>;
  OrganizationPaymentSetting?: InputMaybe<OrganizationPaymentSettingsUpdateOneWithoutOrganizationInput>;
  OrganizationPaymentSettings?: InputMaybe<OrganizationPaymentSettingsUpdateOneWithoutOrganizationsInput>;
  orgCalendarInviteMetadata?: InputMaybe<OrgCalendarInviteMetadataUpdateManyWithoutOrganizationInput>;
  OrgDefaultPipelinePerecentValue?: InputMaybe<OrgDefaultPipelinePerecentValueUpdateOneWithoutOrganizationInput>;
  OrgHubSpotSync?: InputMaybe<OrgHubSpotSyncUpdateOneWithoutOrganizationInput>;
  OrgNoEventDate?: InputMaybe<OrgNoEventDateUpdateManyWithoutOrganizationInput>;
  OrgSalesforceSync?: InputMaybe<OrgSalesforceSyncUpdateOneWithoutOrganizationInput>;
  OrgTimeslotAvailability?: InputMaybe<OrgTimeslotAvailabilityUpdateManyWithoutOrganizationInput>;
  outbound_close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_dm_connect_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_lt_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_revenue_value_of_sale?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_scheduled_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_set_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  PandadocData?: InputMaybe<PandadocDataUpdateOneWithoutOrganizationInput>;
  PandaDocTemplate?: InputMaybe<PandaDocTemplateUpdateManyWithoutOrganizationInput>;
  participant_jitter_buffer_size?: InputMaybe<EnumJitterBufferSizeFieldUpdateOperationsInput>;
  preferred_goal_setting?: InputMaybe<NullableEnumGoalsettingFieldUpdateOperationsInput>;
  prepayment?: InputMaybe<BoolFieldUpdateOperationsInput>;
  ProductChangeLog?: InputMaybe<ProductChangeLogUpdateManyWithoutOrganizationInput>;
  products?: InputMaybe<ProductUpdateManyWithoutOrganizationInput>;
  re_cold_call_hour_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  recordingStatus?: InputMaybe<EnumRecordingStatusFieldUpdateOperationsInput>;
  release_lead_resting?: InputMaybe<IntFieldUpdateOperationsInput>;
  rep_claim_limit?: InputMaybe<IntFieldUpdateOperationsInput>;
  rep_lead_allotment?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  rep_level_call_transfer?: InputMaybe<BoolFieldUpdateOperationsInput>;
  reps_can_bulk_unassign?: InputMaybe<BoolFieldUpdateOperationsInput>;
  reps_can_view_all_notes?: InputMaybe<BoolFieldUpdateOperationsInput>;
  require_sale_notes?: InputMaybe<BoolFieldUpdateOperationsInput>;
  resting_after_skipped_CC?: InputMaybe<IntFieldUpdateOperationsInput>;
  resting_period?: InputMaybe<IntFieldUpdateOperationsInput>;
  resting_period_after_unsuccessful_cold_call?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  resting_period_after_unsuccessful_demo?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  revenue_plan?: InputMaybe<RevenuePlanItemUpdateManyWithoutOrganizationInput>;
  Routings?: InputMaybe<RoutingUpdateManyWithoutOrganizationInput>;
  sale_field_options?: InputMaybe<SaleFieldOptionUpdateManyWithoutOrganizationInput>;
  sale_notification_email_list?: InputMaybe<OrganizationUpdatesale_Notification_Email_ListInput>;
  SaleConfig?: InputMaybe<SaleConfigUpdateOneWithoutOrganizationInput>;
  SaleConfigPage?: InputMaybe<SaleConfigPageUpdateManyWithoutOrganizationInput>;
  SaleConfigSection?: InputMaybe<SaleConfigSectionUpdateManyWithoutOrganizationInput>;
  SaleCycles?: InputMaybe<SaleCycleUpdateManyWithoutOrganizationInput>;
  SaleItems?: InputMaybe<SaleItemUpdateManyWithoutOrganizationInput>;
  sales?: InputMaybe<SaleUpdateManyWithoutOrganizationInput>;
  salesforce_domain?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  salesforce_last_sync_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  SalesForceColumn?: InputMaybe<SalesForceColumnUpdateManyWithoutOrganizationInput>;
  salesForceData?: InputMaybe<SalesForceDataUpdateOneWithoutOrganizationInput>;
  ScheduleItems?: InputMaybe<ScheduleItemUpdateManyWithoutOrganizationInput>;
  self_sourcing_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  send_error_log_email?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  send_lead_routing_email?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sendgrid_email_domain?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sendgrid_email_domain_id?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  sequence?: InputMaybe<SequenceUpdateManyWithoutOrganzationInput>;
  SequenceCriteriaConditions?: InputMaybe<SequenceCriteriaConditionUpdateManyWithoutOrganizationInput>;
  SequenceDashboardViews?: InputMaybe<SequenceDashboardViewUpdateManyWithoutOrganizationInput>;
  SequenceEntryCriterias?: InputMaybe<SequenceEntryCriteriaUpdateManyWithoutOrganizationInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutOrganizationInput>;
  SequenceSteps?: InputMaybe<SequenceStepUpdateManyWithoutOrganizationInput>;
  set_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
  short_resting?: InputMaybe<IntFieldUpdateOperationsInput>;
  show_calendar_options?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_contract_duration_sales?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_lead_source_to_rep?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_nocontact_button?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_payment_terms_sales?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_rep_all_leads?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_rep_my_leads?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_system_view?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sites?: InputMaybe<SiteUpdateManyWithoutOrganizationInput>;
  sms_opt_in_dialog?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SMSItems?: InputMaybe<SmsItemUpdateManyWithoutOrganizationInput>;
  SnoozeTrackings?: InputMaybe<SnoozeTrackingUpdateManyWithoutOrganizationInput>;
  StepActionLogs?: InputMaybe<StepActionLogUpdateManyWithoutOrganizationInput>;
  StepActions?: InputMaybe<StepActionUpdateManyWithoutOrganizationInput>;
  StepConditions?: InputMaybe<StepConditionUpdateManyWithoutOrganizationInput>;
  teams?: InputMaybe<TeamUpdateManyWithoutOrganizationInput>;
  Template?: InputMaybe<TemplateUpdateManyWithoutOrganizationInput>;
  TemplateVersions?: InputMaybe<TemplateVersionUpdateManyWithoutOrganizationInput>;
  timekit_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timezone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transfer_timeout?: InputMaybe<IntFieldUpdateOperationsInput>;
  TransferAttempts?: InputMaybe<TransferAttemptUpdateManyWithoutOrganizationInput>;
  twilio_numbers?: InputMaybe<TwilioNumberUpdateManyWithoutOrganizationInput>;
  TwilioFeedbacks?: InputMaybe<TwilioFeedbackUpdateManyWithoutOrganizationInput>;
  TwilioInfo?: InputMaybe<TwilioInfoUpdateOneWithoutOrganzationInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  use_revenue_planning?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  UserCustomFieldFilters?: InputMaybe<UserCustomFieldFilterUpdateManyWithoutOrganizationInput>;
  users?: InputMaybe<UserUpdateManyWithoutOrganizationInput>;
  win_rep_day_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
};

export type OrganizationUpdateWithoutCustomObjectFieldForeignKeyInput = {
  ActivityMonitorMultiplier?: InputMaybe<ActivityMonitorMultiplierUpdateManyWithoutOrganizationInput>;
  algoFactor?: InputMaybe<AlgoFactorUpdateManyWithoutOrganizationInput>;
  allow_calendar_invite?: InputMaybe<BoolFieldUpdateOperationsInput>;
  allow_remove_step_action?: InputMaybe<BoolFieldUpdateOperationsInput>;
  allow_snooze_step_action?: InputMaybe<BoolFieldUpdateOperationsInput>;
  always_record?: InputMaybe<BoolFieldUpdateOperationsInput>;
  arv_initial_held_demos_generated_by_sdr_weight?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  AssociationHistories?: InputMaybe<AssociationHistoryUpdateManyWithoutOrganizationInput>;
  auto_call_interval?: InputMaybe<IntFieldUpdateOperationsInput>;
  auto_dial?: InputMaybe<BoolFieldUpdateOperationsInput>;
  automated_campaigns?: InputMaybe<AutomatedCampaignUpdateManyWithoutOrganizationInput>;
  automatic_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  AutoTransferSelections?: InputMaybe<AutoTransferSelectionUpdateManyWithoutOrganizationInput>;
  Availablities?: InputMaybe<AvailabilityUpdateManyWithoutOrganizationInput>;
  AverageOrderValue?: InputMaybe<AverageOrderValueUpdateManyWithoutOrganizationInput>;
  best_action_ever_multiplier?: InputMaybe<FloatFieldUpdateOperationsInput>;
  best_metrics?: InputMaybe<BestMetricRecordUpdateManyWithoutOrganizationInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptUpdateManyWithoutOrganizationInput>;
  callMeNows?: InputMaybe<CallMeNowUpdateManyWithoutOrganizationInput>;
  CallMultiplier?: InputMaybe<CallMultiplierUpdateManyWithoutOrganizationInput>;
  CallSharings?: InputMaybe<CallSharingUpdateManyWithoutOrganizationInput>;
  can_edit_sdr_transfer_rules?: InputMaybe<BoolFieldUpdateOperationsInput>;
  claim_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  concession?: InputMaybe<ConcessionUpdateManyWithoutOrganizationInput>;
  conference_region?: InputMaybe<EnumConferenceRegionFieldUpdateOperationsInput>;
  Conferences?: InputMaybe<ConferenceUpdateManyWithoutOrganizationInput>;
  ContactRequests?: InputMaybe<ContactRequestUpdateManyWithoutOrganizationInput>;
  Contacts?: InputMaybe<ContactUpdateManyWithoutOrganizationInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  custom_fields?: InputMaybe<CustomFieldUpdateManyWithoutOrganizationInput>;
  CustomObject?: InputMaybe<CustomObjectUpdateManyWithoutOrganizationInput>;
  CustomObjectField?: InputMaybe<CustomObjectFieldUpdateManyWithoutOrganizationInput>;
  CustomObjectRecord?: InputMaybe<CustomObjectRecordUpdateManyWithoutOrganizationInput>;
  CustomObjectRow?: InputMaybe<CustomObjectRowUpdateManyWithoutOrganizationInput>;
  CustomQueueItems?: InputMaybe<CustomQueueItemUpdateManyWithoutOrganizationInput>;
  DashboardColumnOptions?: InputMaybe<DashboardColumnOptionUpdateManyWithoutOrganizationInput>;
  DashboardLeadFilters?: InputMaybe<DashboardLeadFilterUpdateManyWithoutOrganizationInput>;
  DashboardRepFilters?: InputMaybe<DashboardRepFilterUpdateManyWithoutOrganizationInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewUpdateManyWithoutOrganizationInput>;
  day_to_close?: InputMaybe<IntFieldUpdateOperationsInput>;
  default_demo_time_duration?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  demo_held_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_not_held_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_not_held_call_back_short_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_prep_time_minutes?: InputMaybe<IntFieldUpdateOperationsInput>;
  dev_account?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  dial_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  DispositionMultiplier?: InputMaybe<DispositionMultiplierUpdateManyWithoutOrganizationInput>;
  dispositions?: InputMaybe<DispositionUpdateManyWithoutOrganizationInput>;
  double_booking?: InputMaybe<BoolFieldUpdateOperationsInput>;
  dynamic_date_operators?: InputMaybe<BoolFieldUpdateOperationsInput>;
  edge_servers?: InputMaybe<OrganizationUpdateedge_ServersInput>;
  email_conflict_notification_email_list?: InputMaybe<OrganizationUpdateemail_Conflict_Notification_Email_ListInput>;
  EmailItems?: InputMaybe<EmailItemUpdateManyWithoutOrganizationInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordUpdateManyWithoutOrganizationInput>;
  EmailThreads?: InputMaybe<EmailThreadUpdateManyWithoutOrganizationInput>;
  enable_modified_metrics?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  enable_pay_period_view?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  enable_time_constraint?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  error_log_email_address?: InputMaybe<OrganizationUpdateerror_Log_Email_AddressInput>;
  estimated_average_num_of_weekly_overbooked_demo_slots?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  FolderPermissions?: InputMaybe<FolderPermissionUpdateManyWithoutOrganizationInput>;
  Folders?: InputMaybe<FolderUpdateManyWithoutOrganizationInput>;
  FutureBookingSetting?: InputMaybe<FutureBookingSettingUpdateOneWithoutOrganizationInput>;
  GlobalTransferRule?: InputMaybe<GlobalTransferRuleUpdateManyWithoutOrganizationInput>;
  held_demos_per_cc_connect?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  helpful_links?: InputMaybe<OrganizationUpdatehelpful_LinksInput>;
  HingePointMultiplier?: InputMaybe<HingePointMultiplierUpdateManyWithoutOrganizationInput>;
  hold_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
  hubspot_credential?: InputMaybe<HubSpotCredentialUpdateOneWithoutOrganizationInput>;
  hubspot_last_sync_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  hubspot_white_list?: InputMaybe<HubSpotWhitelistUpdateManyWithoutOrganizationInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inbound_close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_dm_connect_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_lt_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_revenue_value_of_sale?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_scheduled_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_set_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventUpdateManyWithoutOrganizationInput>;
  InboundConciergeWidget?: InputMaybe<InboundConciergeWidgetUpdateManyWithoutOrganizationInput>;
  industry_options?: InputMaybe<IndustryOptionUpdateManyWithoutOrganizationInput>;
  initial_sdr_sets_reps_day?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  integration_error_logs?: InputMaybe<IntegrationErrorLogUpdateManyWithoutOrganizationInput>;
  integration_mappings?: InputMaybe<IntegrationMappingUpdateManyWithoutOrganizationInput>;
  IntegrationMappingCustomObject?: InputMaybe<IntegrationMappingCustomObjectUpdateManyWithoutOrganizationInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogUpdateManyWithoutOrganizationInput>;
  IntegrationReadOnlyObject?: InputMaybe<IntegrationReadOnlyObjectUpdateManyWithoutOrganizationInput>;
  international?: InputMaybe<BoolFieldUpdateOperationsInput>;
  is_email_domain_verified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  is_salesforce_sandbox?: InputMaybe<BoolFieldUpdateOperationsInput>;
  last_lead_value_compute_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutOrganizationInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryUpdateManyWithoutOrganizationInput>;
  lead_intent?: InputMaybe<LeadIntentUpdateManyWithoutOrganizationInput>;
  lead_release_same_rep_delay_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  lead_routing_email_list?: InputMaybe<OrganizationUpdatelead_Routing_Email_ListInput>;
  lead_routing_rules?: InputMaybe<RuleUpdateManyWithoutOrganizationInput>;
  LeadCustomObjectRowAssociation?: InputMaybe<LeadCustomObjectRowAssociationUpdateManyWithoutOrganizationInput>;
  LeadFieldMultiplier?: InputMaybe<LeadFieldMultiplierUpdateManyWithoutOrganizationInput>;
  LeadFieldMultiplierCustom?: InputMaybe<LeadFieldMultiplierCustomUpdateManyWithoutOrganizationInput>;
  leads?: InputMaybe<LeadUpdateManyWithoutOrganizationInput>;
  leadsource_options?: InputMaybe<LeadSourceOptionUpdateManyWithoutOrganizationInput>;
  LeadValue?: InputMaybe<LeadValueUpdateManyWithoutOrganizationInput>;
  LifeTimeValueMultiplier?: InputMaybe<LifeTimeValueMultiplierUpdateManyWithoutOrganizationInput>;
  LiveTransferSetting?: InputMaybe<LiveTransferSettingUpdateOneWithoutOrganizationInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusUpdateManyWithoutOrganizationInput>;
  ltv_multiplier_custom_fields?: InputMaybe<LifeTimeValueMultiplierCustomUpdateManyWithoutOrganizationInput>;
  made_contact_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  make_sale_address_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_business_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_city_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_country_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_first_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_industry_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_last_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_lead_source_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_primary_email_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_primary_phone_number_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_state_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_sub_industry_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_zip_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  maxium_day_lag_set_to_schedule?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  mrr?: InputMaybe<IntFieldUpdateOperationsInput>;
  mrr_label?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  mrr_required_on_sale?: InputMaybe<BoolFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  NoteItems?: InputMaybe<NoteItemUpdateManyWithoutOrganizationInput>;
  Notifications?: InputMaybe<NotificationUpdateManyWithoutOrganizationInput>;
  onboardComplete?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  Opportunity?: InputMaybe<SfOpportunityUpdateManyWithoutOrganizationInput>;
  organization_holidays?: InputMaybe<OrganizationHolidayUpdateManyWithoutOrganizationInput>;
  organization_hours?: InputMaybe<OrganizationHourUpdateManyWithoutOrganizationInput>;
  OrganizationPaymentSetting?: InputMaybe<OrganizationPaymentSettingsUpdateOneWithoutOrganizationInput>;
  OrganizationPaymentSettings?: InputMaybe<OrganizationPaymentSettingsUpdateOneWithoutOrganizationsInput>;
  orgCalendarInviteMetadata?: InputMaybe<OrgCalendarInviteMetadataUpdateManyWithoutOrganizationInput>;
  OrgDefaultPipelinePerecentValue?: InputMaybe<OrgDefaultPipelinePerecentValueUpdateOneWithoutOrganizationInput>;
  OrgHubSpotSync?: InputMaybe<OrgHubSpotSyncUpdateOneWithoutOrganizationInput>;
  OrgNoEventDate?: InputMaybe<OrgNoEventDateUpdateManyWithoutOrganizationInput>;
  OrgSalesforceSync?: InputMaybe<OrgSalesforceSyncUpdateOneWithoutOrganizationInput>;
  OrgTimeslotAvailability?: InputMaybe<OrgTimeslotAvailabilityUpdateManyWithoutOrganizationInput>;
  outbound_close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_dm_connect_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_lt_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_revenue_value_of_sale?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_scheduled_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_set_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  PandadocData?: InputMaybe<PandadocDataUpdateOneWithoutOrganizationInput>;
  PandaDocTemplate?: InputMaybe<PandaDocTemplateUpdateManyWithoutOrganizationInput>;
  participant_jitter_buffer_size?: InputMaybe<EnumJitterBufferSizeFieldUpdateOperationsInput>;
  preferred_goal_setting?: InputMaybe<NullableEnumGoalsettingFieldUpdateOperationsInput>;
  prepayment?: InputMaybe<BoolFieldUpdateOperationsInput>;
  ProductChangeLog?: InputMaybe<ProductChangeLogUpdateManyWithoutOrganizationInput>;
  products?: InputMaybe<ProductUpdateManyWithoutOrganizationInput>;
  re_cold_call_hour_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  recordingStatus?: InputMaybe<EnumRecordingStatusFieldUpdateOperationsInput>;
  release_lead_resting?: InputMaybe<IntFieldUpdateOperationsInput>;
  rep_claim_limit?: InputMaybe<IntFieldUpdateOperationsInput>;
  rep_lead_allotment?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  rep_level_call_transfer?: InputMaybe<BoolFieldUpdateOperationsInput>;
  reps_can_bulk_unassign?: InputMaybe<BoolFieldUpdateOperationsInput>;
  reps_can_view_all_notes?: InputMaybe<BoolFieldUpdateOperationsInput>;
  require_sale_notes?: InputMaybe<BoolFieldUpdateOperationsInput>;
  resting_after_skipped_CC?: InputMaybe<IntFieldUpdateOperationsInput>;
  resting_period?: InputMaybe<IntFieldUpdateOperationsInput>;
  resting_period_after_unsuccessful_cold_call?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  resting_period_after_unsuccessful_demo?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  revenue_plan?: InputMaybe<RevenuePlanItemUpdateManyWithoutOrganizationInput>;
  Routings?: InputMaybe<RoutingUpdateManyWithoutOrganizationInput>;
  sale_field_options?: InputMaybe<SaleFieldOptionUpdateManyWithoutOrganizationInput>;
  sale_notification_email_list?: InputMaybe<OrganizationUpdatesale_Notification_Email_ListInput>;
  SaleConfig?: InputMaybe<SaleConfigUpdateOneWithoutOrganizationInput>;
  SaleConfigPage?: InputMaybe<SaleConfigPageUpdateManyWithoutOrganizationInput>;
  SaleConfigSection?: InputMaybe<SaleConfigSectionUpdateManyWithoutOrganizationInput>;
  SaleCycles?: InputMaybe<SaleCycleUpdateManyWithoutOrganizationInput>;
  SaleItems?: InputMaybe<SaleItemUpdateManyWithoutOrganizationInput>;
  sales?: InputMaybe<SaleUpdateManyWithoutOrganizationInput>;
  salesforce_domain?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  salesforce_last_sync_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  SalesForceColumn?: InputMaybe<SalesForceColumnUpdateManyWithoutOrganizationInput>;
  salesForceData?: InputMaybe<SalesForceDataUpdateOneWithoutOrganizationInput>;
  ScheduleItems?: InputMaybe<ScheduleItemUpdateManyWithoutOrganizationInput>;
  self_sourcing_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  send_error_log_email?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  send_lead_routing_email?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sendgrid_email_domain?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sendgrid_email_domain_id?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  sequence?: InputMaybe<SequenceUpdateManyWithoutOrganzationInput>;
  SequenceCriteriaConditions?: InputMaybe<SequenceCriteriaConditionUpdateManyWithoutOrganizationInput>;
  SequenceDashboardViews?: InputMaybe<SequenceDashboardViewUpdateManyWithoutOrganizationInput>;
  SequenceEntryCriterias?: InputMaybe<SequenceEntryCriteriaUpdateManyWithoutOrganizationInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutOrganizationInput>;
  SequenceSteps?: InputMaybe<SequenceStepUpdateManyWithoutOrganizationInput>;
  set_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
  short_resting?: InputMaybe<IntFieldUpdateOperationsInput>;
  show_calendar_options?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_contract_duration_sales?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_lead_source_to_rep?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_nocontact_button?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_payment_terms_sales?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_rep_all_leads?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_rep_my_leads?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_system_view?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sites?: InputMaybe<SiteUpdateManyWithoutOrganizationInput>;
  sms_opt_in_dialog?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SMSItems?: InputMaybe<SmsItemUpdateManyWithoutOrganizationInput>;
  SnoozeTrackings?: InputMaybe<SnoozeTrackingUpdateManyWithoutOrganizationInput>;
  StepActionLogs?: InputMaybe<StepActionLogUpdateManyWithoutOrganizationInput>;
  StepActions?: InputMaybe<StepActionUpdateManyWithoutOrganizationInput>;
  StepConditions?: InputMaybe<StepConditionUpdateManyWithoutOrganizationInput>;
  teams?: InputMaybe<TeamUpdateManyWithoutOrganizationInput>;
  Template?: InputMaybe<TemplateUpdateManyWithoutOrganizationInput>;
  TemplateVersions?: InputMaybe<TemplateVersionUpdateManyWithoutOrganizationInput>;
  timekit_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timezone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transfer_timeout?: InputMaybe<IntFieldUpdateOperationsInput>;
  TransferAttempts?: InputMaybe<TransferAttemptUpdateManyWithoutOrganizationInput>;
  twilio_numbers?: InputMaybe<TwilioNumberUpdateManyWithoutOrganizationInput>;
  TwilioFeedbacks?: InputMaybe<TwilioFeedbackUpdateManyWithoutOrganizationInput>;
  TwilioInfo?: InputMaybe<TwilioInfoUpdateOneWithoutOrganzationInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  use_revenue_planning?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  UserCustomFieldFilters?: InputMaybe<UserCustomFieldFilterUpdateManyWithoutOrganizationInput>;
  users?: InputMaybe<UserUpdateManyWithoutOrganizationInput>;
  win_rep_day_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
};

export type OrganizationUpdateWithoutCustomObjectFieldInput = {
  ActivityMonitorMultiplier?: InputMaybe<ActivityMonitorMultiplierUpdateManyWithoutOrganizationInput>;
  algoFactor?: InputMaybe<AlgoFactorUpdateManyWithoutOrganizationInput>;
  allow_calendar_invite?: InputMaybe<BoolFieldUpdateOperationsInput>;
  allow_remove_step_action?: InputMaybe<BoolFieldUpdateOperationsInput>;
  allow_snooze_step_action?: InputMaybe<BoolFieldUpdateOperationsInput>;
  always_record?: InputMaybe<BoolFieldUpdateOperationsInput>;
  arv_initial_held_demos_generated_by_sdr_weight?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  AssociationHistories?: InputMaybe<AssociationHistoryUpdateManyWithoutOrganizationInput>;
  auto_call_interval?: InputMaybe<IntFieldUpdateOperationsInput>;
  auto_dial?: InputMaybe<BoolFieldUpdateOperationsInput>;
  automated_campaigns?: InputMaybe<AutomatedCampaignUpdateManyWithoutOrganizationInput>;
  automatic_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  AutoTransferSelections?: InputMaybe<AutoTransferSelectionUpdateManyWithoutOrganizationInput>;
  Availablities?: InputMaybe<AvailabilityUpdateManyWithoutOrganizationInput>;
  AverageOrderValue?: InputMaybe<AverageOrderValueUpdateManyWithoutOrganizationInput>;
  best_action_ever_multiplier?: InputMaybe<FloatFieldUpdateOperationsInput>;
  best_metrics?: InputMaybe<BestMetricRecordUpdateManyWithoutOrganizationInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptUpdateManyWithoutOrganizationInput>;
  callMeNows?: InputMaybe<CallMeNowUpdateManyWithoutOrganizationInput>;
  CallMultiplier?: InputMaybe<CallMultiplierUpdateManyWithoutOrganizationInput>;
  CallSharings?: InputMaybe<CallSharingUpdateManyWithoutOrganizationInput>;
  can_edit_sdr_transfer_rules?: InputMaybe<BoolFieldUpdateOperationsInput>;
  claim_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  concession?: InputMaybe<ConcessionUpdateManyWithoutOrganizationInput>;
  conference_region?: InputMaybe<EnumConferenceRegionFieldUpdateOperationsInput>;
  Conferences?: InputMaybe<ConferenceUpdateManyWithoutOrganizationInput>;
  ContactRequests?: InputMaybe<ContactRequestUpdateManyWithoutOrganizationInput>;
  Contacts?: InputMaybe<ContactUpdateManyWithoutOrganizationInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  custom_fields?: InputMaybe<CustomFieldUpdateManyWithoutOrganizationInput>;
  CustomObject?: InputMaybe<CustomObjectUpdateManyWithoutOrganizationInput>;
  CustomObjectFieldForeignKey?: InputMaybe<CustomObjectFieldForeignKeyUpdateManyWithoutOrganizationInput>;
  CustomObjectRecord?: InputMaybe<CustomObjectRecordUpdateManyWithoutOrganizationInput>;
  CustomObjectRow?: InputMaybe<CustomObjectRowUpdateManyWithoutOrganizationInput>;
  CustomQueueItems?: InputMaybe<CustomQueueItemUpdateManyWithoutOrganizationInput>;
  DashboardColumnOptions?: InputMaybe<DashboardColumnOptionUpdateManyWithoutOrganizationInput>;
  DashboardLeadFilters?: InputMaybe<DashboardLeadFilterUpdateManyWithoutOrganizationInput>;
  DashboardRepFilters?: InputMaybe<DashboardRepFilterUpdateManyWithoutOrganizationInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewUpdateManyWithoutOrganizationInput>;
  day_to_close?: InputMaybe<IntFieldUpdateOperationsInput>;
  default_demo_time_duration?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  demo_held_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_not_held_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_not_held_call_back_short_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_prep_time_minutes?: InputMaybe<IntFieldUpdateOperationsInput>;
  dev_account?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  dial_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  DispositionMultiplier?: InputMaybe<DispositionMultiplierUpdateManyWithoutOrganizationInput>;
  dispositions?: InputMaybe<DispositionUpdateManyWithoutOrganizationInput>;
  double_booking?: InputMaybe<BoolFieldUpdateOperationsInput>;
  dynamic_date_operators?: InputMaybe<BoolFieldUpdateOperationsInput>;
  edge_servers?: InputMaybe<OrganizationUpdateedge_ServersInput>;
  email_conflict_notification_email_list?: InputMaybe<OrganizationUpdateemail_Conflict_Notification_Email_ListInput>;
  EmailItems?: InputMaybe<EmailItemUpdateManyWithoutOrganizationInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordUpdateManyWithoutOrganizationInput>;
  EmailThreads?: InputMaybe<EmailThreadUpdateManyWithoutOrganizationInput>;
  enable_modified_metrics?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  enable_pay_period_view?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  enable_time_constraint?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  error_log_email_address?: InputMaybe<OrganizationUpdateerror_Log_Email_AddressInput>;
  estimated_average_num_of_weekly_overbooked_demo_slots?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  FolderPermissions?: InputMaybe<FolderPermissionUpdateManyWithoutOrganizationInput>;
  Folders?: InputMaybe<FolderUpdateManyWithoutOrganizationInput>;
  FutureBookingSetting?: InputMaybe<FutureBookingSettingUpdateOneWithoutOrganizationInput>;
  GlobalTransferRule?: InputMaybe<GlobalTransferRuleUpdateManyWithoutOrganizationInput>;
  held_demos_per_cc_connect?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  helpful_links?: InputMaybe<OrganizationUpdatehelpful_LinksInput>;
  HingePointMultiplier?: InputMaybe<HingePointMultiplierUpdateManyWithoutOrganizationInput>;
  hold_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
  hubspot_credential?: InputMaybe<HubSpotCredentialUpdateOneWithoutOrganizationInput>;
  hubspot_last_sync_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  hubspot_white_list?: InputMaybe<HubSpotWhitelistUpdateManyWithoutOrganizationInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inbound_close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_dm_connect_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_lt_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_revenue_value_of_sale?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_scheduled_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_set_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventUpdateManyWithoutOrganizationInput>;
  InboundConciergeWidget?: InputMaybe<InboundConciergeWidgetUpdateManyWithoutOrganizationInput>;
  industry_options?: InputMaybe<IndustryOptionUpdateManyWithoutOrganizationInput>;
  initial_sdr_sets_reps_day?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  integration_error_logs?: InputMaybe<IntegrationErrorLogUpdateManyWithoutOrganizationInput>;
  integration_mappings?: InputMaybe<IntegrationMappingUpdateManyWithoutOrganizationInput>;
  IntegrationMappingCustomObject?: InputMaybe<IntegrationMappingCustomObjectUpdateManyWithoutOrganizationInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogUpdateManyWithoutOrganizationInput>;
  IntegrationReadOnlyObject?: InputMaybe<IntegrationReadOnlyObjectUpdateManyWithoutOrganizationInput>;
  international?: InputMaybe<BoolFieldUpdateOperationsInput>;
  is_email_domain_verified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  is_salesforce_sandbox?: InputMaybe<BoolFieldUpdateOperationsInput>;
  last_lead_value_compute_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutOrganizationInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryUpdateManyWithoutOrganizationInput>;
  lead_intent?: InputMaybe<LeadIntentUpdateManyWithoutOrganizationInput>;
  lead_release_same_rep_delay_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  lead_routing_email_list?: InputMaybe<OrganizationUpdatelead_Routing_Email_ListInput>;
  lead_routing_rules?: InputMaybe<RuleUpdateManyWithoutOrganizationInput>;
  LeadCustomObjectRowAssociation?: InputMaybe<LeadCustomObjectRowAssociationUpdateManyWithoutOrganizationInput>;
  LeadFieldMultiplier?: InputMaybe<LeadFieldMultiplierUpdateManyWithoutOrganizationInput>;
  LeadFieldMultiplierCustom?: InputMaybe<LeadFieldMultiplierCustomUpdateManyWithoutOrganizationInput>;
  leads?: InputMaybe<LeadUpdateManyWithoutOrganizationInput>;
  leadsource_options?: InputMaybe<LeadSourceOptionUpdateManyWithoutOrganizationInput>;
  LeadValue?: InputMaybe<LeadValueUpdateManyWithoutOrganizationInput>;
  LifeTimeValueMultiplier?: InputMaybe<LifeTimeValueMultiplierUpdateManyWithoutOrganizationInput>;
  LiveTransferSetting?: InputMaybe<LiveTransferSettingUpdateOneWithoutOrganizationInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusUpdateManyWithoutOrganizationInput>;
  ltv_multiplier_custom_fields?: InputMaybe<LifeTimeValueMultiplierCustomUpdateManyWithoutOrganizationInput>;
  made_contact_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  make_sale_address_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_business_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_city_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_country_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_first_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_industry_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_last_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_lead_source_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_primary_email_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_primary_phone_number_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_state_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_sub_industry_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_zip_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  maxium_day_lag_set_to_schedule?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  mrr?: InputMaybe<IntFieldUpdateOperationsInput>;
  mrr_label?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  mrr_required_on_sale?: InputMaybe<BoolFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  NoteItems?: InputMaybe<NoteItemUpdateManyWithoutOrganizationInput>;
  Notifications?: InputMaybe<NotificationUpdateManyWithoutOrganizationInput>;
  onboardComplete?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  Opportunity?: InputMaybe<SfOpportunityUpdateManyWithoutOrganizationInput>;
  organization_holidays?: InputMaybe<OrganizationHolidayUpdateManyWithoutOrganizationInput>;
  organization_hours?: InputMaybe<OrganizationHourUpdateManyWithoutOrganizationInput>;
  OrganizationPaymentSetting?: InputMaybe<OrganizationPaymentSettingsUpdateOneWithoutOrganizationInput>;
  OrganizationPaymentSettings?: InputMaybe<OrganizationPaymentSettingsUpdateOneWithoutOrganizationsInput>;
  orgCalendarInviteMetadata?: InputMaybe<OrgCalendarInviteMetadataUpdateManyWithoutOrganizationInput>;
  OrgDefaultPipelinePerecentValue?: InputMaybe<OrgDefaultPipelinePerecentValueUpdateOneWithoutOrganizationInput>;
  OrgHubSpotSync?: InputMaybe<OrgHubSpotSyncUpdateOneWithoutOrganizationInput>;
  OrgNoEventDate?: InputMaybe<OrgNoEventDateUpdateManyWithoutOrganizationInput>;
  OrgSalesforceSync?: InputMaybe<OrgSalesforceSyncUpdateOneWithoutOrganizationInput>;
  OrgTimeslotAvailability?: InputMaybe<OrgTimeslotAvailabilityUpdateManyWithoutOrganizationInput>;
  outbound_close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_dm_connect_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_lt_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_revenue_value_of_sale?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_scheduled_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_set_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  PandadocData?: InputMaybe<PandadocDataUpdateOneWithoutOrganizationInput>;
  PandaDocTemplate?: InputMaybe<PandaDocTemplateUpdateManyWithoutOrganizationInput>;
  participant_jitter_buffer_size?: InputMaybe<EnumJitterBufferSizeFieldUpdateOperationsInput>;
  preferred_goal_setting?: InputMaybe<NullableEnumGoalsettingFieldUpdateOperationsInput>;
  prepayment?: InputMaybe<BoolFieldUpdateOperationsInput>;
  ProductChangeLog?: InputMaybe<ProductChangeLogUpdateManyWithoutOrganizationInput>;
  products?: InputMaybe<ProductUpdateManyWithoutOrganizationInput>;
  re_cold_call_hour_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  recordingStatus?: InputMaybe<EnumRecordingStatusFieldUpdateOperationsInput>;
  release_lead_resting?: InputMaybe<IntFieldUpdateOperationsInput>;
  rep_claim_limit?: InputMaybe<IntFieldUpdateOperationsInput>;
  rep_lead_allotment?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  rep_level_call_transfer?: InputMaybe<BoolFieldUpdateOperationsInput>;
  reps_can_bulk_unassign?: InputMaybe<BoolFieldUpdateOperationsInput>;
  reps_can_view_all_notes?: InputMaybe<BoolFieldUpdateOperationsInput>;
  require_sale_notes?: InputMaybe<BoolFieldUpdateOperationsInput>;
  resting_after_skipped_CC?: InputMaybe<IntFieldUpdateOperationsInput>;
  resting_period?: InputMaybe<IntFieldUpdateOperationsInput>;
  resting_period_after_unsuccessful_cold_call?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  resting_period_after_unsuccessful_demo?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  revenue_plan?: InputMaybe<RevenuePlanItemUpdateManyWithoutOrganizationInput>;
  Routings?: InputMaybe<RoutingUpdateManyWithoutOrganizationInput>;
  sale_field_options?: InputMaybe<SaleFieldOptionUpdateManyWithoutOrganizationInput>;
  sale_notification_email_list?: InputMaybe<OrganizationUpdatesale_Notification_Email_ListInput>;
  SaleConfig?: InputMaybe<SaleConfigUpdateOneWithoutOrganizationInput>;
  SaleConfigPage?: InputMaybe<SaleConfigPageUpdateManyWithoutOrganizationInput>;
  SaleConfigSection?: InputMaybe<SaleConfigSectionUpdateManyWithoutOrganizationInput>;
  SaleCycles?: InputMaybe<SaleCycleUpdateManyWithoutOrganizationInput>;
  SaleItems?: InputMaybe<SaleItemUpdateManyWithoutOrganizationInput>;
  sales?: InputMaybe<SaleUpdateManyWithoutOrganizationInput>;
  salesforce_domain?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  salesforce_last_sync_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  SalesForceColumn?: InputMaybe<SalesForceColumnUpdateManyWithoutOrganizationInput>;
  salesForceData?: InputMaybe<SalesForceDataUpdateOneWithoutOrganizationInput>;
  ScheduleItems?: InputMaybe<ScheduleItemUpdateManyWithoutOrganizationInput>;
  self_sourcing_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  send_error_log_email?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  send_lead_routing_email?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sendgrid_email_domain?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sendgrid_email_domain_id?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  sequence?: InputMaybe<SequenceUpdateManyWithoutOrganzationInput>;
  SequenceCriteriaConditions?: InputMaybe<SequenceCriteriaConditionUpdateManyWithoutOrganizationInput>;
  SequenceDashboardViews?: InputMaybe<SequenceDashboardViewUpdateManyWithoutOrganizationInput>;
  SequenceEntryCriterias?: InputMaybe<SequenceEntryCriteriaUpdateManyWithoutOrganizationInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutOrganizationInput>;
  SequenceSteps?: InputMaybe<SequenceStepUpdateManyWithoutOrganizationInput>;
  set_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
  short_resting?: InputMaybe<IntFieldUpdateOperationsInput>;
  show_calendar_options?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_contract_duration_sales?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_lead_source_to_rep?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_nocontact_button?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_payment_terms_sales?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_rep_all_leads?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_rep_my_leads?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_system_view?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sites?: InputMaybe<SiteUpdateManyWithoutOrganizationInput>;
  sms_opt_in_dialog?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SMSItems?: InputMaybe<SmsItemUpdateManyWithoutOrganizationInput>;
  SnoozeTrackings?: InputMaybe<SnoozeTrackingUpdateManyWithoutOrganizationInput>;
  StepActionLogs?: InputMaybe<StepActionLogUpdateManyWithoutOrganizationInput>;
  StepActions?: InputMaybe<StepActionUpdateManyWithoutOrganizationInput>;
  StepConditions?: InputMaybe<StepConditionUpdateManyWithoutOrganizationInput>;
  teams?: InputMaybe<TeamUpdateManyWithoutOrganizationInput>;
  Template?: InputMaybe<TemplateUpdateManyWithoutOrganizationInput>;
  TemplateVersions?: InputMaybe<TemplateVersionUpdateManyWithoutOrganizationInput>;
  timekit_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timezone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transfer_timeout?: InputMaybe<IntFieldUpdateOperationsInput>;
  TransferAttempts?: InputMaybe<TransferAttemptUpdateManyWithoutOrganizationInput>;
  twilio_numbers?: InputMaybe<TwilioNumberUpdateManyWithoutOrganizationInput>;
  TwilioFeedbacks?: InputMaybe<TwilioFeedbackUpdateManyWithoutOrganizationInput>;
  TwilioInfo?: InputMaybe<TwilioInfoUpdateOneWithoutOrganzationInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  use_revenue_planning?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  UserCustomFieldFilters?: InputMaybe<UserCustomFieldFilterUpdateManyWithoutOrganizationInput>;
  users?: InputMaybe<UserUpdateManyWithoutOrganizationInput>;
  win_rep_day_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
};

export type OrganizationUpdateWithoutCustomObjectInput = {
  ActivityMonitorMultiplier?: InputMaybe<ActivityMonitorMultiplierUpdateManyWithoutOrganizationInput>;
  algoFactor?: InputMaybe<AlgoFactorUpdateManyWithoutOrganizationInput>;
  allow_calendar_invite?: InputMaybe<BoolFieldUpdateOperationsInput>;
  allow_remove_step_action?: InputMaybe<BoolFieldUpdateOperationsInput>;
  allow_snooze_step_action?: InputMaybe<BoolFieldUpdateOperationsInput>;
  always_record?: InputMaybe<BoolFieldUpdateOperationsInput>;
  arv_initial_held_demos_generated_by_sdr_weight?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  AssociationHistories?: InputMaybe<AssociationHistoryUpdateManyWithoutOrganizationInput>;
  auto_call_interval?: InputMaybe<IntFieldUpdateOperationsInput>;
  auto_dial?: InputMaybe<BoolFieldUpdateOperationsInput>;
  automated_campaigns?: InputMaybe<AutomatedCampaignUpdateManyWithoutOrganizationInput>;
  automatic_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  AutoTransferSelections?: InputMaybe<AutoTransferSelectionUpdateManyWithoutOrganizationInput>;
  Availablities?: InputMaybe<AvailabilityUpdateManyWithoutOrganizationInput>;
  AverageOrderValue?: InputMaybe<AverageOrderValueUpdateManyWithoutOrganizationInput>;
  best_action_ever_multiplier?: InputMaybe<FloatFieldUpdateOperationsInput>;
  best_metrics?: InputMaybe<BestMetricRecordUpdateManyWithoutOrganizationInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptUpdateManyWithoutOrganizationInput>;
  callMeNows?: InputMaybe<CallMeNowUpdateManyWithoutOrganizationInput>;
  CallMultiplier?: InputMaybe<CallMultiplierUpdateManyWithoutOrganizationInput>;
  CallSharings?: InputMaybe<CallSharingUpdateManyWithoutOrganizationInput>;
  can_edit_sdr_transfer_rules?: InputMaybe<BoolFieldUpdateOperationsInput>;
  claim_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  concession?: InputMaybe<ConcessionUpdateManyWithoutOrganizationInput>;
  conference_region?: InputMaybe<EnumConferenceRegionFieldUpdateOperationsInput>;
  Conferences?: InputMaybe<ConferenceUpdateManyWithoutOrganizationInput>;
  ContactRequests?: InputMaybe<ContactRequestUpdateManyWithoutOrganizationInput>;
  Contacts?: InputMaybe<ContactUpdateManyWithoutOrganizationInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  custom_fields?: InputMaybe<CustomFieldUpdateManyWithoutOrganizationInput>;
  CustomObjectField?: InputMaybe<CustomObjectFieldUpdateManyWithoutOrganizationInput>;
  CustomObjectFieldForeignKey?: InputMaybe<CustomObjectFieldForeignKeyUpdateManyWithoutOrganizationInput>;
  CustomObjectRecord?: InputMaybe<CustomObjectRecordUpdateManyWithoutOrganizationInput>;
  CustomObjectRow?: InputMaybe<CustomObjectRowUpdateManyWithoutOrganizationInput>;
  CustomQueueItems?: InputMaybe<CustomQueueItemUpdateManyWithoutOrganizationInput>;
  DashboardColumnOptions?: InputMaybe<DashboardColumnOptionUpdateManyWithoutOrganizationInput>;
  DashboardLeadFilters?: InputMaybe<DashboardLeadFilterUpdateManyWithoutOrganizationInput>;
  DashboardRepFilters?: InputMaybe<DashboardRepFilterUpdateManyWithoutOrganizationInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewUpdateManyWithoutOrganizationInput>;
  day_to_close?: InputMaybe<IntFieldUpdateOperationsInput>;
  default_demo_time_duration?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  demo_held_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_not_held_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_not_held_call_back_short_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_prep_time_minutes?: InputMaybe<IntFieldUpdateOperationsInput>;
  dev_account?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  dial_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  DispositionMultiplier?: InputMaybe<DispositionMultiplierUpdateManyWithoutOrganizationInput>;
  dispositions?: InputMaybe<DispositionUpdateManyWithoutOrganizationInput>;
  double_booking?: InputMaybe<BoolFieldUpdateOperationsInput>;
  dynamic_date_operators?: InputMaybe<BoolFieldUpdateOperationsInput>;
  edge_servers?: InputMaybe<OrganizationUpdateedge_ServersInput>;
  email_conflict_notification_email_list?: InputMaybe<OrganizationUpdateemail_Conflict_Notification_Email_ListInput>;
  EmailItems?: InputMaybe<EmailItemUpdateManyWithoutOrganizationInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordUpdateManyWithoutOrganizationInput>;
  EmailThreads?: InputMaybe<EmailThreadUpdateManyWithoutOrganizationInput>;
  enable_modified_metrics?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  enable_pay_period_view?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  enable_time_constraint?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  error_log_email_address?: InputMaybe<OrganizationUpdateerror_Log_Email_AddressInput>;
  estimated_average_num_of_weekly_overbooked_demo_slots?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  FolderPermissions?: InputMaybe<FolderPermissionUpdateManyWithoutOrganizationInput>;
  Folders?: InputMaybe<FolderUpdateManyWithoutOrganizationInput>;
  FutureBookingSetting?: InputMaybe<FutureBookingSettingUpdateOneWithoutOrganizationInput>;
  GlobalTransferRule?: InputMaybe<GlobalTransferRuleUpdateManyWithoutOrganizationInput>;
  held_demos_per_cc_connect?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  helpful_links?: InputMaybe<OrganizationUpdatehelpful_LinksInput>;
  HingePointMultiplier?: InputMaybe<HingePointMultiplierUpdateManyWithoutOrganizationInput>;
  hold_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
  hubspot_credential?: InputMaybe<HubSpotCredentialUpdateOneWithoutOrganizationInput>;
  hubspot_last_sync_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  hubspot_white_list?: InputMaybe<HubSpotWhitelistUpdateManyWithoutOrganizationInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inbound_close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_dm_connect_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_lt_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_revenue_value_of_sale?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_scheduled_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_set_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventUpdateManyWithoutOrganizationInput>;
  InboundConciergeWidget?: InputMaybe<InboundConciergeWidgetUpdateManyWithoutOrganizationInput>;
  industry_options?: InputMaybe<IndustryOptionUpdateManyWithoutOrganizationInput>;
  initial_sdr_sets_reps_day?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  integration_error_logs?: InputMaybe<IntegrationErrorLogUpdateManyWithoutOrganizationInput>;
  integration_mappings?: InputMaybe<IntegrationMappingUpdateManyWithoutOrganizationInput>;
  IntegrationMappingCustomObject?: InputMaybe<IntegrationMappingCustomObjectUpdateManyWithoutOrganizationInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogUpdateManyWithoutOrganizationInput>;
  IntegrationReadOnlyObject?: InputMaybe<IntegrationReadOnlyObjectUpdateManyWithoutOrganizationInput>;
  international?: InputMaybe<BoolFieldUpdateOperationsInput>;
  is_email_domain_verified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  is_salesforce_sandbox?: InputMaybe<BoolFieldUpdateOperationsInput>;
  last_lead_value_compute_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutOrganizationInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryUpdateManyWithoutOrganizationInput>;
  lead_intent?: InputMaybe<LeadIntentUpdateManyWithoutOrganizationInput>;
  lead_release_same_rep_delay_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  lead_routing_email_list?: InputMaybe<OrganizationUpdatelead_Routing_Email_ListInput>;
  lead_routing_rules?: InputMaybe<RuleUpdateManyWithoutOrganizationInput>;
  LeadCustomObjectRowAssociation?: InputMaybe<LeadCustomObjectRowAssociationUpdateManyWithoutOrganizationInput>;
  LeadFieldMultiplier?: InputMaybe<LeadFieldMultiplierUpdateManyWithoutOrganizationInput>;
  LeadFieldMultiplierCustom?: InputMaybe<LeadFieldMultiplierCustomUpdateManyWithoutOrganizationInput>;
  leads?: InputMaybe<LeadUpdateManyWithoutOrganizationInput>;
  leadsource_options?: InputMaybe<LeadSourceOptionUpdateManyWithoutOrganizationInput>;
  LeadValue?: InputMaybe<LeadValueUpdateManyWithoutOrganizationInput>;
  LifeTimeValueMultiplier?: InputMaybe<LifeTimeValueMultiplierUpdateManyWithoutOrganizationInput>;
  LiveTransferSetting?: InputMaybe<LiveTransferSettingUpdateOneWithoutOrganizationInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusUpdateManyWithoutOrganizationInput>;
  ltv_multiplier_custom_fields?: InputMaybe<LifeTimeValueMultiplierCustomUpdateManyWithoutOrganizationInput>;
  made_contact_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  make_sale_address_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_business_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_city_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_country_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_first_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_industry_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_last_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_lead_source_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_primary_email_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_primary_phone_number_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_state_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_sub_industry_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_zip_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  maxium_day_lag_set_to_schedule?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  mrr?: InputMaybe<IntFieldUpdateOperationsInput>;
  mrr_label?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  mrr_required_on_sale?: InputMaybe<BoolFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  NoteItems?: InputMaybe<NoteItemUpdateManyWithoutOrganizationInput>;
  Notifications?: InputMaybe<NotificationUpdateManyWithoutOrganizationInput>;
  onboardComplete?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  Opportunity?: InputMaybe<SfOpportunityUpdateManyWithoutOrganizationInput>;
  organization_holidays?: InputMaybe<OrganizationHolidayUpdateManyWithoutOrganizationInput>;
  organization_hours?: InputMaybe<OrganizationHourUpdateManyWithoutOrganizationInput>;
  OrganizationPaymentSetting?: InputMaybe<OrganizationPaymentSettingsUpdateOneWithoutOrganizationInput>;
  OrganizationPaymentSettings?: InputMaybe<OrganizationPaymentSettingsUpdateOneWithoutOrganizationsInput>;
  orgCalendarInviteMetadata?: InputMaybe<OrgCalendarInviteMetadataUpdateManyWithoutOrganizationInput>;
  OrgDefaultPipelinePerecentValue?: InputMaybe<OrgDefaultPipelinePerecentValueUpdateOneWithoutOrganizationInput>;
  OrgHubSpotSync?: InputMaybe<OrgHubSpotSyncUpdateOneWithoutOrganizationInput>;
  OrgNoEventDate?: InputMaybe<OrgNoEventDateUpdateManyWithoutOrganizationInput>;
  OrgSalesforceSync?: InputMaybe<OrgSalesforceSyncUpdateOneWithoutOrganizationInput>;
  OrgTimeslotAvailability?: InputMaybe<OrgTimeslotAvailabilityUpdateManyWithoutOrganizationInput>;
  outbound_close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_dm_connect_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_lt_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_revenue_value_of_sale?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_scheduled_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_set_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  PandadocData?: InputMaybe<PandadocDataUpdateOneWithoutOrganizationInput>;
  PandaDocTemplate?: InputMaybe<PandaDocTemplateUpdateManyWithoutOrganizationInput>;
  participant_jitter_buffer_size?: InputMaybe<EnumJitterBufferSizeFieldUpdateOperationsInput>;
  preferred_goal_setting?: InputMaybe<NullableEnumGoalsettingFieldUpdateOperationsInput>;
  prepayment?: InputMaybe<BoolFieldUpdateOperationsInput>;
  ProductChangeLog?: InputMaybe<ProductChangeLogUpdateManyWithoutOrganizationInput>;
  products?: InputMaybe<ProductUpdateManyWithoutOrganizationInput>;
  re_cold_call_hour_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  recordingStatus?: InputMaybe<EnumRecordingStatusFieldUpdateOperationsInput>;
  release_lead_resting?: InputMaybe<IntFieldUpdateOperationsInput>;
  rep_claim_limit?: InputMaybe<IntFieldUpdateOperationsInput>;
  rep_lead_allotment?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  rep_level_call_transfer?: InputMaybe<BoolFieldUpdateOperationsInput>;
  reps_can_bulk_unassign?: InputMaybe<BoolFieldUpdateOperationsInput>;
  reps_can_view_all_notes?: InputMaybe<BoolFieldUpdateOperationsInput>;
  require_sale_notes?: InputMaybe<BoolFieldUpdateOperationsInput>;
  resting_after_skipped_CC?: InputMaybe<IntFieldUpdateOperationsInput>;
  resting_period?: InputMaybe<IntFieldUpdateOperationsInput>;
  resting_period_after_unsuccessful_cold_call?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  resting_period_after_unsuccessful_demo?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  revenue_plan?: InputMaybe<RevenuePlanItemUpdateManyWithoutOrganizationInput>;
  Routings?: InputMaybe<RoutingUpdateManyWithoutOrganizationInput>;
  sale_field_options?: InputMaybe<SaleFieldOptionUpdateManyWithoutOrganizationInput>;
  sale_notification_email_list?: InputMaybe<OrganizationUpdatesale_Notification_Email_ListInput>;
  SaleConfig?: InputMaybe<SaleConfigUpdateOneWithoutOrganizationInput>;
  SaleConfigPage?: InputMaybe<SaleConfigPageUpdateManyWithoutOrganizationInput>;
  SaleConfigSection?: InputMaybe<SaleConfigSectionUpdateManyWithoutOrganizationInput>;
  SaleCycles?: InputMaybe<SaleCycleUpdateManyWithoutOrganizationInput>;
  SaleItems?: InputMaybe<SaleItemUpdateManyWithoutOrganizationInput>;
  sales?: InputMaybe<SaleUpdateManyWithoutOrganizationInput>;
  salesforce_domain?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  salesforce_last_sync_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  SalesForceColumn?: InputMaybe<SalesForceColumnUpdateManyWithoutOrganizationInput>;
  salesForceData?: InputMaybe<SalesForceDataUpdateOneWithoutOrganizationInput>;
  ScheduleItems?: InputMaybe<ScheduleItemUpdateManyWithoutOrganizationInput>;
  self_sourcing_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  send_error_log_email?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  send_lead_routing_email?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sendgrid_email_domain?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sendgrid_email_domain_id?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  sequence?: InputMaybe<SequenceUpdateManyWithoutOrganzationInput>;
  SequenceCriteriaConditions?: InputMaybe<SequenceCriteriaConditionUpdateManyWithoutOrganizationInput>;
  SequenceDashboardViews?: InputMaybe<SequenceDashboardViewUpdateManyWithoutOrganizationInput>;
  SequenceEntryCriterias?: InputMaybe<SequenceEntryCriteriaUpdateManyWithoutOrganizationInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutOrganizationInput>;
  SequenceSteps?: InputMaybe<SequenceStepUpdateManyWithoutOrganizationInput>;
  set_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
  short_resting?: InputMaybe<IntFieldUpdateOperationsInput>;
  show_calendar_options?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_contract_duration_sales?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_lead_source_to_rep?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_nocontact_button?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_payment_terms_sales?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_rep_all_leads?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_rep_my_leads?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_system_view?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sites?: InputMaybe<SiteUpdateManyWithoutOrganizationInput>;
  sms_opt_in_dialog?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SMSItems?: InputMaybe<SmsItemUpdateManyWithoutOrganizationInput>;
  SnoozeTrackings?: InputMaybe<SnoozeTrackingUpdateManyWithoutOrganizationInput>;
  StepActionLogs?: InputMaybe<StepActionLogUpdateManyWithoutOrganizationInput>;
  StepActions?: InputMaybe<StepActionUpdateManyWithoutOrganizationInput>;
  StepConditions?: InputMaybe<StepConditionUpdateManyWithoutOrganizationInput>;
  teams?: InputMaybe<TeamUpdateManyWithoutOrganizationInput>;
  Template?: InputMaybe<TemplateUpdateManyWithoutOrganizationInput>;
  TemplateVersions?: InputMaybe<TemplateVersionUpdateManyWithoutOrganizationInput>;
  timekit_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timezone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transfer_timeout?: InputMaybe<IntFieldUpdateOperationsInput>;
  TransferAttempts?: InputMaybe<TransferAttemptUpdateManyWithoutOrganizationInput>;
  twilio_numbers?: InputMaybe<TwilioNumberUpdateManyWithoutOrganizationInput>;
  TwilioFeedbacks?: InputMaybe<TwilioFeedbackUpdateManyWithoutOrganizationInput>;
  TwilioInfo?: InputMaybe<TwilioInfoUpdateOneWithoutOrganzationInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  use_revenue_planning?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  UserCustomFieldFilters?: InputMaybe<UserCustomFieldFilterUpdateManyWithoutOrganizationInput>;
  users?: InputMaybe<UserUpdateManyWithoutOrganizationInput>;
  win_rep_day_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
};

export type OrganizationUpdateWithoutCustomObjectRecordInput = {
  ActivityMonitorMultiplier?: InputMaybe<ActivityMonitorMultiplierUpdateManyWithoutOrganizationInput>;
  algoFactor?: InputMaybe<AlgoFactorUpdateManyWithoutOrganizationInput>;
  allow_calendar_invite?: InputMaybe<BoolFieldUpdateOperationsInput>;
  allow_remove_step_action?: InputMaybe<BoolFieldUpdateOperationsInput>;
  allow_snooze_step_action?: InputMaybe<BoolFieldUpdateOperationsInput>;
  always_record?: InputMaybe<BoolFieldUpdateOperationsInput>;
  arv_initial_held_demos_generated_by_sdr_weight?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  AssociationHistories?: InputMaybe<AssociationHistoryUpdateManyWithoutOrganizationInput>;
  auto_call_interval?: InputMaybe<IntFieldUpdateOperationsInput>;
  auto_dial?: InputMaybe<BoolFieldUpdateOperationsInput>;
  automated_campaigns?: InputMaybe<AutomatedCampaignUpdateManyWithoutOrganizationInput>;
  automatic_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  AutoTransferSelections?: InputMaybe<AutoTransferSelectionUpdateManyWithoutOrganizationInput>;
  Availablities?: InputMaybe<AvailabilityUpdateManyWithoutOrganizationInput>;
  AverageOrderValue?: InputMaybe<AverageOrderValueUpdateManyWithoutOrganizationInput>;
  best_action_ever_multiplier?: InputMaybe<FloatFieldUpdateOperationsInput>;
  best_metrics?: InputMaybe<BestMetricRecordUpdateManyWithoutOrganizationInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptUpdateManyWithoutOrganizationInput>;
  callMeNows?: InputMaybe<CallMeNowUpdateManyWithoutOrganizationInput>;
  CallMultiplier?: InputMaybe<CallMultiplierUpdateManyWithoutOrganizationInput>;
  CallSharings?: InputMaybe<CallSharingUpdateManyWithoutOrganizationInput>;
  can_edit_sdr_transfer_rules?: InputMaybe<BoolFieldUpdateOperationsInput>;
  claim_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  concession?: InputMaybe<ConcessionUpdateManyWithoutOrganizationInput>;
  conference_region?: InputMaybe<EnumConferenceRegionFieldUpdateOperationsInput>;
  Conferences?: InputMaybe<ConferenceUpdateManyWithoutOrganizationInput>;
  ContactRequests?: InputMaybe<ContactRequestUpdateManyWithoutOrganizationInput>;
  Contacts?: InputMaybe<ContactUpdateManyWithoutOrganizationInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  custom_fields?: InputMaybe<CustomFieldUpdateManyWithoutOrganizationInput>;
  CustomObject?: InputMaybe<CustomObjectUpdateManyWithoutOrganizationInput>;
  CustomObjectField?: InputMaybe<CustomObjectFieldUpdateManyWithoutOrganizationInput>;
  CustomObjectFieldForeignKey?: InputMaybe<CustomObjectFieldForeignKeyUpdateManyWithoutOrganizationInput>;
  CustomObjectRow?: InputMaybe<CustomObjectRowUpdateManyWithoutOrganizationInput>;
  CustomQueueItems?: InputMaybe<CustomQueueItemUpdateManyWithoutOrganizationInput>;
  DashboardColumnOptions?: InputMaybe<DashboardColumnOptionUpdateManyWithoutOrganizationInput>;
  DashboardLeadFilters?: InputMaybe<DashboardLeadFilterUpdateManyWithoutOrganizationInput>;
  DashboardRepFilters?: InputMaybe<DashboardRepFilterUpdateManyWithoutOrganizationInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewUpdateManyWithoutOrganizationInput>;
  day_to_close?: InputMaybe<IntFieldUpdateOperationsInput>;
  default_demo_time_duration?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  demo_held_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_not_held_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_not_held_call_back_short_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_prep_time_minutes?: InputMaybe<IntFieldUpdateOperationsInput>;
  dev_account?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  dial_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  DispositionMultiplier?: InputMaybe<DispositionMultiplierUpdateManyWithoutOrganizationInput>;
  dispositions?: InputMaybe<DispositionUpdateManyWithoutOrganizationInput>;
  double_booking?: InputMaybe<BoolFieldUpdateOperationsInput>;
  dynamic_date_operators?: InputMaybe<BoolFieldUpdateOperationsInput>;
  edge_servers?: InputMaybe<OrganizationUpdateedge_ServersInput>;
  email_conflict_notification_email_list?: InputMaybe<OrganizationUpdateemail_Conflict_Notification_Email_ListInput>;
  EmailItems?: InputMaybe<EmailItemUpdateManyWithoutOrganizationInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordUpdateManyWithoutOrganizationInput>;
  EmailThreads?: InputMaybe<EmailThreadUpdateManyWithoutOrganizationInput>;
  enable_modified_metrics?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  enable_pay_period_view?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  enable_time_constraint?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  error_log_email_address?: InputMaybe<OrganizationUpdateerror_Log_Email_AddressInput>;
  estimated_average_num_of_weekly_overbooked_demo_slots?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  FolderPermissions?: InputMaybe<FolderPermissionUpdateManyWithoutOrganizationInput>;
  Folders?: InputMaybe<FolderUpdateManyWithoutOrganizationInput>;
  FutureBookingSetting?: InputMaybe<FutureBookingSettingUpdateOneWithoutOrganizationInput>;
  GlobalTransferRule?: InputMaybe<GlobalTransferRuleUpdateManyWithoutOrganizationInput>;
  held_demos_per_cc_connect?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  helpful_links?: InputMaybe<OrganizationUpdatehelpful_LinksInput>;
  HingePointMultiplier?: InputMaybe<HingePointMultiplierUpdateManyWithoutOrganizationInput>;
  hold_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
  hubspot_credential?: InputMaybe<HubSpotCredentialUpdateOneWithoutOrganizationInput>;
  hubspot_last_sync_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  hubspot_white_list?: InputMaybe<HubSpotWhitelistUpdateManyWithoutOrganizationInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inbound_close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_dm_connect_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_lt_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_revenue_value_of_sale?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_scheduled_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_set_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventUpdateManyWithoutOrganizationInput>;
  InboundConciergeWidget?: InputMaybe<InboundConciergeWidgetUpdateManyWithoutOrganizationInput>;
  industry_options?: InputMaybe<IndustryOptionUpdateManyWithoutOrganizationInput>;
  initial_sdr_sets_reps_day?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  integration_error_logs?: InputMaybe<IntegrationErrorLogUpdateManyWithoutOrganizationInput>;
  integration_mappings?: InputMaybe<IntegrationMappingUpdateManyWithoutOrganizationInput>;
  IntegrationMappingCustomObject?: InputMaybe<IntegrationMappingCustomObjectUpdateManyWithoutOrganizationInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogUpdateManyWithoutOrganizationInput>;
  IntegrationReadOnlyObject?: InputMaybe<IntegrationReadOnlyObjectUpdateManyWithoutOrganizationInput>;
  international?: InputMaybe<BoolFieldUpdateOperationsInput>;
  is_email_domain_verified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  is_salesforce_sandbox?: InputMaybe<BoolFieldUpdateOperationsInput>;
  last_lead_value_compute_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutOrganizationInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryUpdateManyWithoutOrganizationInput>;
  lead_intent?: InputMaybe<LeadIntentUpdateManyWithoutOrganizationInput>;
  lead_release_same_rep_delay_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  lead_routing_email_list?: InputMaybe<OrganizationUpdatelead_Routing_Email_ListInput>;
  lead_routing_rules?: InputMaybe<RuleUpdateManyWithoutOrganizationInput>;
  LeadCustomObjectRowAssociation?: InputMaybe<LeadCustomObjectRowAssociationUpdateManyWithoutOrganizationInput>;
  LeadFieldMultiplier?: InputMaybe<LeadFieldMultiplierUpdateManyWithoutOrganizationInput>;
  LeadFieldMultiplierCustom?: InputMaybe<LeadFieldMultiplierCustomUpdateManyWithoutOrganizationInput>;
  leads?: InputMaybe<LeadUpdateManyWithoutOrganizationInput>;
  leadsource_options?: InputMaybe<LeadSourceOptionUpdateManyWithoutOrganizationInput>;
  LeadValue?: InputMaybe<LeadValueUpdateManyWithoutOrganizationInput>;
  LifeTimeValueMultiplier?: InputMaybe<LifeTimeValueMultiplierUpdateManyWithoutOrganizationInput>;
  LiveTransferSetting?: InputMaybe<LiveTransferSettingUpdateOneWithoutOrganizationInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusUpdateManyWithoutOrganizationInput>;
  ltv_multiplier_custom_fields?: InputMaybe<LifeTimeValueMultiplierCustomUpdateManyWithoutOrganizationInput>;
  made_contact_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  make_sale_address_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_business_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_city_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_country_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_first_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_industry_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_last_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_lead_source_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_primary_email_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_primary_phone_number_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_state_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_sub_industry_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_zip_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  maxium_day_lag_set_to_schedule?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  mrr?: InputMaybe<IntFieldUpdateOperationsInput>;
  mrr_label?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  mrr_required_on_sale?: InputMaybe<BoolFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  NoteItems?: InputMaybe<NoteItemUpdateManyWithoutOrganizationInput>;
  Notifications?: InputMaybe<NotificationUpdateManyWithoutOrganizationInput>;
  onboardComplete?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  Opportunity?: InputMaybe<SfOpportunityUpdateManyWithoutOrganizationInput>;
  organization_holidays?: InputMaybe<OrganizationHolidayUpdateManyWithoutOrganizationInput>;
  organization_hours?: InputMaybe<OrganizationHourUpdateManyWithoutOrganizationInput>;
  OrganizationPaymentSetting?: InputMaybe<OrganizationPaymentSettingsUpdateOneWithoutOrganizationInput>;
  OrganizationPaymentSettings?: InputMaybe<OrganizationPaymentSettingsUpdateOneWithoutOrganizationsInput>;
  orgCalendarInviteMetadata?: InputMaybe<OrgCalendarInviteMetadataUpdateManyWithoutOrganizationInput>;
  OrgDefaultPipelinePerecentValue?: InputMaybe<OrgDefaultPipelinePerecentValueUpdateOneWithoutOrganizationInput>;
  OrgHubSpotSync?: InputMaybe<OrgHubSpotSyncUpdateOneWithoutOrganizationInput>;
  OrgNoEventDate?: InputMaybe<OrgNoEventDateUpdateManyWithoutOrganizationInput>;
  OrgSalesforceSync?: InputMaybe<OrgSalesforceSyncUpdateOneWithoutOrganizationInput>;
  OrgTimeslotAvailability?: InputMaybe<OrgTimeslotAvailabilityUpdateManyWithoutOrganizationInput>;
  outbound_close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_dm_connect_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_lt_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_revenue_value_of_sale?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_scheduled_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_set_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  PandadocData?: InputMaybe<PandadocDataUpdateOneWithoutOrganizationInput>;
  PandaDocTemplate?: InputMaybe<PandaDocTemplateUpdateManyWithoutOrganizationInput>;
  participant_jitter_buffer_size?: InputMaybe<EnumJitterBufferSizeFieldUpdateOperationsInput>;
  preferred_goal_setting?: InputMaybe<NullableEnumGoalsettingFieldUpdateOperationsInput>;
  prepayment?: InputMaybe<BoolFieldUpdateOperationsInput>;
  ProductChangeLog?: InputMaybe<ProductChangeLogUpdateManyWithoutOrganizationInput>;
  products?: InputMaybe<ProductUpdateManyWithoutOrganizationInput>;
  re_cold_call_hour_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  recordingStatus?: InputMaybe<EnumRecordingStatusFieldUpdateOperationsInput>;
  release_lead_resting?: InputMaybe<IntFieldUpdateOperationsInput>;
  rep_claim_limit?: InputMaybe<IntFieldUpdateOperationsInput>;
  rep_lead_allotment?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  rep_level_call_transfer?: InputMaybe<BoolFieldUpdateOperationsInput>;
  reps_can_bulk_unassign?: InputMaybe<BoolFieldUpdateOperationsInput>;
  reps_can_view_all_notes?: InputMaybe<BoolFieldUpdateOperationsInput>;
  require_sale_notes?: InputMaybe<BoolFieldUpdateOperationsInput>;
  resting_after_skipped_CC?: InputMaybe<IntFieldUpdateOperationsInput>;
  resting_period?: InputMaybe<IntFieldUpdateOperationsInput>;
  resting_period_after_unsuccessful_cold_call?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  resting_period_after_unsuccessful_demo?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  revenue_plan?: InputMaybe<RevenuePlanItemUpdateManyWithoutOrganizationInput>;
  Routings?: InputMaybe<RoutingUpdateManyWithoutOrganizationInput>;
  sale_field_options?: InputMaybe<SaleFieldOptionUpdateManyWithoutOrganizationInput>;
  sale_notification_email_list?: InputMaybe<OrganizationUpdatesale_Notification_Email_ListInput>;
  SaleConfig?: InputMaybe<SaleConfigUpdateOneWithoutOrganizationInput>;
  SaleConfigPage?: InputMaybe<SaleConfigPageUpdateManyWithoutOrganizationInput>;
  SaleConfigSection?: InputMaybe<SaleConfigSectionUpdateManyWithoutOrganizationInput>;
  SaleCycles?: InputMaybe<SaleCycleUpdateManyWithoutOrganizationInput>;
  SaleItems?: InputMaybe<SaleItemUpdateManyWithoutOrganizationInput>;
  sales?: InputMaybe<SaleUpdateManyWithoutOrganizationInput>;
  salesforce_domain?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  salesforce_last_sync_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  SalesForceColumn?: InputMaybe<SalesForceColumnUpdateManyWithoutOrganizationInput>;
  salesForceData?: InputMaybe<SalesForceDataUpdateOneWithoutOrganizationInput>;
  ScheduleItems?: InputMaybe<ScheduleItemUpdateManyWithoutOrganizationInput>;
  self_sourcing_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  send_error_log_email?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  send_lead_routing_email?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sendgrid_email_domain?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sendgrid_email_domain_id?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  sequence?: InputMaybe<SequenceUpdateManyWithoutOrganzationInput>;
  SequenceCriteriaConditions?: InputMaybe<SequenceCriteriaConditionUpdateManyWithoutOrganizationInput>;
  SequenceDashboardViews?: InputMaybe<SequenceDashboardViewUpdateManyWithoutOrganizationInput>;
  SequenceEntryCriterias?: InputMaybe<SequenceEntryCriteriaUpdateManyWithoutOrganizationInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutOrganizationInput>;
  SequenceSteps?: InputMaybe<SequenceStepUpdateManyWithoutOrganizationInput>;
  set_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
  short_resting?: InputMaybe<IntFieldUpdateOperationsInput>;
  show_calendar_options?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_contract_duration_sales?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_lead_source_to_rep?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_nocontact_button?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_payment_terms_sales?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_rep_all_leads?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_rep_my_leads?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_system_view?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sites?: InputMaybe<SiteUpdateManyWithoutOrganizationInput>;
  sms_opt_in_dialog?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SMSItems?: InputMaybe<SmsItemUpdateManyWithoutOrganizationInput>;
  SnoozeTrackings?: InputMaybe<SnoozeTrackingUpdateManyWithoutOrganizationInput>;
  StepActionLogs?: InputMaybe<StepActionLogUpdateManyWithoutOrganizationInput>;
  StepActions?: InputMaybe<StepActionUpdateManyWithoutOrganizationInput>;
  StepConditions?: InputMaybe<StepConditionUpdateManyWithoutOrganizationInput>;
  teams?: InputMaybe<TeamUpdateManyWithoutOrganizationInput>;
  Template?: InputMaybe<TemplateUpdateManyWithoutOrganizationInput>;
  TemplateVersions?: InputMaybe<TemplateVersionUpdateManyWithoutOrganizationInput>;
  timekit_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timezone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transfer_timeout?: InputMaybe<IntFieldUpdateOperationsInput>;
  TransferAttempts?: InputMaybe<TransferAttemptUpdateManyWithoutOrganizationInput>;
  twilio_numbers?: InputMaybe<TwilioNumberUpdateManyWithoutOrganizationInput>;
  TwilioFeedbacks?: InputMaybe<TwilioFeedbackUpdateManyWithoutOrganizationInput>;
  TwilioInfo?: InputMaybe<TwilioInfoUpdateOneWithoutOrganzationInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  use_revenue_planning?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  UserCustomFieldFilters?: InputMaybe<UserCustomFieldFilterUpdateManyWithoutOrganizationInput>;
  users?: InputMaybe<UserUpdateManyWithoutOrganizationInput>;
  win_rep_day_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
};

export type OrganizationUpdateWithoutCustomObjectRowInput = {
  ActivityMonitorMultiplier?: InputMaybe<ActivityMonitorMultiplierUpdateManyWithoutOrganizationInput>;
  algoFactor?: InputMaybe<AlgoFactorUpdateManyWithoutOrganizationInput>;
  allow_calendar_invite?: InputMaybe<BoolFieldUpdateOperationsInput>;
  allow_remove_step_action?: InputMaybe<BoolFieldUpdateOperationsInput>;
  allow_snooze_step_action?: InputMaybe<BoolFieldUpdateOperationsInput>;
  always_record?: InputMaybe<BoolFieldUpdateOperationsInput>;
  arv_initial_held_demos_generated_by_sdr_weight?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  AssociationHistories?: InputMaybe<AssociationHistoryUpdateManyWithoutOrganizationInput>;
  auto_call_interval?: InputMaybe<IntFieldUpdateOperationsInput>;
  auto_dial?: InputMaybe<BoolFieldUpdateOperationsInput>;
  automated_campaigns?: InputMaybe<AutomatedCampaignUpdateManyWithoutOrganizationInput>;
  automatic_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  AutoTransferSelections?: InputMaybe<AutoTransferSelectionUpdateManyWithoutOrganizationInput>;
  Availablities?: InputMaybe<AvailabilityUpdateManyWithoutOrganizationInput>;
  AverageOrderValue?: InputMaybe<AverageOrderValueUpdateManyWithoutOrganizationInput>;
  best_action_ever_multiplier?: InputMaybe<FloatFieldUpdateOperationsInput>;
  best_metrics?: InputMaybe<BestMetricRecordUpdateManyWithoutOrganizationInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptUpdateManyWithoutOrganizationInput>;
  callMeNows?: InputMaybe<CallMeNowUpdateManyWithoutOrganizationInput>;
  CallMultiplier?: InputMaybe<CallMultiplierUpdateManyWithoutOrganizationInput>;
  CallSharings?: InputMaybe<CallSharingUpdateManyWithoutOrganizationInput>;
  can_edit_sdr_transfer_rules?: InputMaybe<BoolFieldUpdateOperationsInput>;
  claim_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  concession?: InputMaybe<ConcessionUpdateManyWithoutOrganizationInput>;
  conference_region?: InputMaybe<EnumConferenceRegionFieldUpdateOperationsInput>;
  Conferences?: InputMaybe<ConferenceUpdateManyWithoutOrganizationInput>;
  ContactRequests?: InputMaybe<ContactRequestUpdateManyWithoutOrganizationInput>;
  Contacts?: InputMaybe<ContactUpdateManyWithoutOrganizationInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  custom_fields?: InputMaybe<CustomFieldUpdateManyWithoutOrganizationInput>;
  CustomObject?: InputMaybe<CustomObjectUpdateManyWithoutOrganizationInput>;
  CustomObjectField?: InputMaybe<CustomObjectFieldUpdateManyWithoutOrganizationInput>;
  CustomObjectFieldForeignKey?: InputMaybe<CustomObjectFieldForeignKeyUpdateManyWithoutOrganizationInput>;
  CustomObjectRecord?: InputMaybe<CustomObjectRecordUpdateManyWithoutOrganizationInput>;
  CustomQueueItems?: InputMaybe<CustomQueueItemUpdateManyWithoutOrganizationInput>;
  DashboardColumnOptions?: InputMaybe<DashboardColumnOptionUpdateManyWithoutOrganizationInput>;
  DashboardLeadFilters?: InputMaybe<DashboardLeadFilterUpdateManyWithoutOrganizationInput>;
  DashboardRepFilters?: InputMaybe<DashboardRepFilterUpdateManyWithoutOrganizationInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewUpdateManyWithoutOrganizationInput>;
  day_to_close?: InputMaybe<IntFieldUpdateOperationsInput>;
  default_demo_time_duration?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  demo_held_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_not_held_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_not_held_call_back_short_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_prep_time_minutes?: InputMaybe<IntFieldUpdateOperationsInput>;
  dev_account?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  dial_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  DispositionMultiplier?: InputMaybe<DispositionMultiplierUpdateManyWithoutOrganizationInput>;
  dispositions?: InputMaybe<DispositionUpdateManyWithoutOrganizationInput>;
  double_booking?: InputMaybe<BoolFieldUpdateOperationsInput>;
  dynamic_date_operators?: InputMaybe<BoolFieldUpdateOperationsInput>;
  edge_servers?: InputMaybe<OrganizationUpdateedge_ServersInput>;
  email_conflict_notification_email_list?: InputMaybe<OrganizationUpdateemail_Conflict_Notification_Email_ListInput>;
  EmailItems?: InputMaybe<EmailItemUpdateManyWithoutOrganizationInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordUpdateManyWithoutOrganizationInput>;
  EmailThreads?: InputMaybe<EmailThreadUpdateManyWithoutOrganizationInput>;
  enable_modified_metrics?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  enable_pay_period_view?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  enable_time_constraint?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  error_log_email_address?: InputMaybe<OrganizationUpdateerror_Log_Email_AddressInput>;
  estimated_average_num_of_weekly_overbooked_demo_slots?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  FolderPermissions?: InputMaybe<FolderPermissionUpdateManyWithoutOrganizationInput>;
  Folders?: InputMaybe<FolderUpdateManyWithoutOrganizationInput>;
  FutureBookingSetting?: InputMaybe<FutureBookingSettingUpdateOneWithoutOrganizationInput>;
  GlobalTransferRule?: InputMaybe<GlobalTransferRuleUpdateManyWithoutOrganizationInput>;
  held_demos_per_cc_connect?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  helpful_links?: InputMaybe<OrganizationUpdatehelpful_LinksInput>;
  HingePointMultiplier?: InputMaybe<HingePointMultiplierUpdateManyWithoutOrganizationInput>;
  hold_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
  hubspot_credential?: InputMaybe<HubSpotCredentialUpdateOneWithoutOrganizationInput>;
  hubspot_last_sync_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  hubspot_white_list?: InputMaybe<HubSpotWhitelistUpdateManyWithoutOrganizationInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inbound_close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_dm_connect_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_lt_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_revenue_value_of_sale?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_scheduled_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_set_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventUpdateManyWithoutOrganizationInput>;
  InboundConciergeWidget?: InputMaybe<InboundConciergeWidgetUpdateManyWithoutOrganizationInput>;
  industry_options?: InputMaybe<IndustryOptionUpdateManyWithoutOrganizationInput>;
  initial_sdr_sets_reps_day?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  integration_error_logs?: InputMaybe<IntegrationErrorLogUpdateManyWithoutOrganizationInput>;
  integration_mappings?: InputMaybe<IntegrationMappingUpdateManyWithoutOrganizationInput>;
  IntegrationMappingCustomObject?: InputMaybe<IntegrationMappingCustomObjectUpdateManyWithoutOrganizationInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogUpdateManyWithoutOrganizationInput>;
  IntegrationReadOnlyObject?: InputMaybe<IntegrationReadOnlyObjectUpdateManyWithoutOrganizationInput>;
  international?: InputMaybe<BoolFieldUpdateOperationsInput>;
  is_email_domain_verified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  is_salesforce_sandbox?: InputMaybe<BoolFieldUpdateOperationsInput>;
  last_lead_value_compute_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutOrganizationInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryUpdateManyWithoutOrganizationInput>;
  lead_intent?: InputMaybe<LeadIntentUpdateManyWithoutOrganizationInput>;
  lead_release_same_rep_delay_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  lead_routing_email_list?: InputMaybe<OrganizationUpdatelead_Routing_Email_ListInput>;
  lead_routing_rules?: InputMaybe<RuleUpdateManyWithoutOrganizationInput>;
  LeadCustomObjectRowAssociation?: InputMaybe<LeadCustomObjectRowAssociationUpdateManyWithoutOrganizationInput>;
  LeadFieldMultiplier?: InputMaybe<LeadFieldMultiplierUpdateManyWithoutOrganizationInput>;
  LeadFieldMultiplierCustom?: InputMaybe<LeadFieldMultiplierCustomUpdateManyWithoutOrganizationInput>;
  leads?: InputMaybe<LeadUpdateManyWithoutOrganizationInput>;
  leadsource_options?: InputMaybe<LeadSourceOptionUpdateManyWithoutOrganizationInput>;
  LeadValue?: InputMaybe<LeadValueUpdateManyWithoutOrganizationInput>;
  LifeTimeValueMultiplier?: InputMaybe<LifeTimeValueMultiplierUpdateManyWithoutOrganizationInput>;
  LiveTransferSetting?: InputMaybe<LiveTransferSettingUpdateOneWithoutOrganizationInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusUpdateManyWithoutOrganizationInput>;
  ltv_multiplier_custom_fields?: InputMaybe<LifeTimeValueMultiplierCustomUpdateManyWithoutOrganizationInput>;
  made_contact_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  make_sale_address_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_business_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_city_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_country_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_first_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_industry_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_last_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_lead_source_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_primary_email_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_primary_phone_number_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_state_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_sub_industry_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_zip_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  maxium_day_lag_set_to_schedule?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  mrr?: InputMaybe<IntFieldUpdateOperationsInput>;
  mrr_label?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  mrr_required_on_sale?: InputMaybe<BoolFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  NoteItems?: InputMaybe<NoteItemUpdateManyWithoutOrganizationInput>;
  Notifications?: InputMaybe<NotificationUpdateManyWithoutOrganizationInput>;
  onboardComplete?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  Opportunity?: InputMaybe<SfOpportunityUpdateManyWithoutOrganizationInput>;
  organization_holidays?: InputMaybe<OrganizationHolidayUpdateManyWithoutOrganizationInput>;
  organization_hours?: InputMaybe<OrganizationHourUpdateManyWithoutOrganizationInput>;
  OrganizationPaymentSetting?: InputMaybe<OrganizationPaymentSettingsUpdateOneWithoutOrganizationInput>;
  OrganizationPaymentSettings?: InputMaybe<OrganizationPaymentSettingsUpdateOneWithoutOrganizationsInput>;
  orgCalendarInviteMetadata?: InputMaybe<OrgCalendarInviteMetadataUpdateManyWithoutOrganizationInput>;
  OrgDefaultPipelinePerecentValue?: InputMaybe<OrgDefaultPipelinePerecentValueUpdateOneWithoutOrganizationInput>;
  OrgHubSpotSync?: InputMaybe<OrgHubSpotSyncUpdateOneWithoutOrganizationInput>;
  OrgNoEventDate?: InputMaybe<OrgNoEventDateUpdateManyWithoutOrganizationInput>;
  OrgSalesforceSync?: InputMaybe<OrgSalesforceSyncUpdateOneWithoutOrganizationInput>;
  OrgTimeslotAvailability?: InputMaybe<OrgTimeslotAvailabilityUpdateManyWithoutOrganizationInput>;
  outbound_close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_dm_connect_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_lt_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_revenue_value_of_sale?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_scheduled_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_set_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  PandadocData?: InputMaybe<PandadocDataUpdateOneWithoutOrganizationInput>;
  PandaDocTemplate?: InputMaybe<PandaDocTemplateUpdateManyWithoutOrganizationInput>;
  participant_jitter_buffer_size?: InputMaybe<EnumJitterBufferSizeFieldUpdateOperationsInput>;
  preferred_goal_setting?: InputMaybe<NullableEnumGoalsettingFieldUpdateOperationsInput>;
  prepayment?: InputMaybe<BoolFieldUpdateOperationsInput>;
  ProductChangeLog?: InputMaybe<ProductChangeLogUpdateManyWithoutOrganizationInput>;
  products?: InputMaybe<ProductUpdateManyWithoutOrganizationInput>;
  re_cold_call_hour_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  recordingStatus?: InputMaybe<EnumRecordingStatusFieldUpdateOperationsInput>;
  release_lead_resting?: InputMaybe<IntFieldUpdateOperationsInput>;
  rep_claim_limit?: InputMaybe<IntFieldUpdateOperationsInput>;
  rep_lead_allotment?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  rep_level_call_transfer?: InputMaybe<BoolFieldUpdateOperationsInput>;
  reps_can_bulk_unassign?: InputMaybe<BoolFieldUpdateOperationsInput>;
  reps_can_view_all_notes?: InputMaybe<BoolFieldUpdateOperationsInput>;
  require_sale_notes?: InputMaybe<BoolFieldUpdateOperationsInput>;
  resting_after_skipped_CC?: InputMaybe<IntFieldUpdateOperationsInput>;
  resting_period?: InputMaybe<IntFieldUpdateOperationsInput>;
  resting_period_after_unsuccessful_cold_call?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  resting_period_after_unsuccessful_demo?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  revenue_plan?: InputMaybe<RevenuePlanItemUpdateManyWithoutOrganizationInput>;
  Routings?: InputMaybe<RoutingUpdateManyWithoutOrganizationInput>;
  sale_field_options?: InputMaybe<SaleFieldOptionUpdateManyWithoutOrganizationInput>;
  sale_notification_email_list?: InputMaybe<OrganizationUpdatesale_Notification_Email_ListInput>;
  SaleConfig?: InputMaybe<SaleConfigUpdateOneWithoutOrganizationInput>;
  SaleConfigPage?: InputMaybe<SaleConfigPageUpdateManyWithoutOrganizationInput>;
  SaleConfigSection?: InputMaybe<SaleConfigSectionUpdateManyWithoutOrganizationInput>;
  SaleCycles?: InputMaybe<SaleCycleUpdateManyWithoutOrganizationInput>;
  SaleItems?: InputMaybe<SaleItemUpdateManyWithoutOrganizationInput>;
  sales?: InputMaybe<SaleUpdateManyWithoutOrganizationInput>;
  salesforce_domain?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  salesforce_last_sync_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  SalesForceColumn?: InputMaybe<SalesForceColumnUpdateManyWithoutOrganizationInput>;
  salesForceData?: InputMaybe<SalesForceDataUpdateOneWithoutOrganizationInput>;
  ScheduleItems?: InputMaybe<ScheduleItemUpdateManyWithoutOrganizationInput>;
  self_sourcing_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  send_error_log_email?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  send_lead_routing_email?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sendgrid_email_domain?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sendgrid_email_domain_id?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  sequence?: InputMaybe<SequenceUpdateManyWithoutOrganzationInput>;
  SequenceCriteriaConditions?: InputMaybe<SequenceCriteriaConditionUpdateManyWithoutOrganizationInput>;
  SequenceDashboardViews?: InputMaybe<SequenceDashboardViewUpdateManyWithoutOrganizationInput>;
  SequenceEntryCriterias?: InputMaybe<SequenceEntryCriteriaUpdateManyWithoutOrganizationInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutOrganizationInput>;
  SequenceSteps?: InputMaybe<SequenceStepUpdateManyWithoutOrganizationInput>;
  set_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
  short_resting?: InputMaybe<IntFieldUpdateOperationsInput>;
  show_calendar_options?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_contract_duration_sales?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_lead_source_to_rep?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_nocontact_button?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_payment_terms_sales?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_rep_all_leads?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_rep_my_leads?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_system_view?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sites?: InputMaybe<SiteUpdateManyWithoutOrganizationInput>;
  sms_opt_in_dialog?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SMSItems?: InputMaybe<SmsItemUpdateManyWithoutOrganizationInput>;
  SnoozeTrackings?: InputMaybe<SnoozeTrackingUpdateManyWithoutOrganizationInput>;
  StepActionLogs?: InputMaybe<StepActionLogUpdateManyWithoutOrganizationInput>;
  StepActions?: InputMaybe<StepActionUpdateManyWithoutOrganizationInput>;
  StepConditions?: InputMaybe<StepConditionUpdateManyWithoutOrganizationInput>;
  teams?: InputMaybe<TeamUpdateManyWithoutOrganizationInput>;
  Template?: InputMaybe<TemplateUpdateManyWithoutOrganizationInput>;
  TemplateVersions?: InputMaybe<TemplateVersionUpdateManyWithoutOrganizationInput>;
  timekit_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timezone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transfer_timeout?: InputMaybe<IntFieldUpdateOperationsInput>;
  TransferAttempts?: InputMaybe<TransferAttemptUpdateManyWithoutOrganizationInput>;
  twilio_numbers?: InputMaybe<TwilioNumberUpdateManyWithoutOrganizationInput>;
  TwilioFeedbacks?: InputMaybe<TwilioFeedbackUpdateManyWithoutOrganizationInput>;
  TwilioInfo?: InputMaybe<TwilioInfoUpdateOneWithoutOrganzationInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  use_revenue_planning?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  UserCustomFieldFilters?: InputMaybe<UserCustomFieldFilterUpdateManyWithoutOrganizationInput>;
  users?: InputMaybe<UserUpdateManyWithoutOrganizationInput>;
  win_rep_day_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
};

export type OrganizationUpdateWithoutCustomQueueItemsInput = {
  ActivityMonitorMultiplier?: InputMaybe<ActivityMonitorMultiplierUpdateManyWithoutOrganizationInput>;
  algoFactor?: InputMaybe<AlgoFactorUpdateManyWithoutOrganizationInput>;
  allow_calendar_invite?: InputMaybe<BoolFieldUpdateOperationsInput>;
  allow_remove_step_action?: InputMaybe<BoolFieldUpdateOperationsInput>;
  allow_snooze_step_action?: InputMaybe<BoolFieldUpdateOperationsInput>;
  always_record?: InputMaybe<BoolFieldUpdateOperationsInput>;
  arv_initial_held_demos_generated_by_sdr_weight?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  AssociationHistories?: InputMaybe<AssociationHistoryUpdateManyWithoutOrganizationInput>;
  auto_call_interval?: InputMaybe<IntFieldUpdateOperationsInput>;
  auto_dial?: InputMaybe<BoolFieldUpdateOperationsInput>;
  automated_campaigns?: InputMaybe<AutomatedCampaignUpdateManyWithoutOrganizationInput>;
  automatic_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  AutoTransferSelections?: InputMaybe<AutoTransferSelectionUpdateManyWithoutOrganizationInput>;
  Availablities?: InputMaybe<AvailabilityUpdateManyWithoutOrganizationInput>;
  AverageOrderValue?: InputMaybe<AverageOrderValueUpdateManyWithoutOrganizationInput>;
  best_action_ever_multiplier?: InputMaybe<FloatFieldUpdateOperationsInput>;
  best_metrics?: InputMaybe<BestMetricRecordUpdateManyWithoutOrganizationInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptUpdateManyWithoutOrganizationInput>;
  callMeNows?: InputMaybe<CallMeNowUpdateManyWithoutOrganizationInput>;
  CallMultiplier?: InputMaybe<CallMultiplierUpdateManyWithoutOrganizationInput>;
  CallSharings?: InputMaybe<CallSharingUpdateManyWithoutOrganizationInput>;
  can_edit_sdr_transfer_rules?: InputMaybe<BoolFieldUpdateOperationsInput>;
  claim_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  concession?: InputMaybe<ConcessionUpdateManyWithoutOrganizationInput>;
  conference_region?: InputMaybe<EnumConferenceRegionFieldUpdateOperationsInput>;
  Conferences?: InputMaybe<ConferenceUpdateManyWithoutOrganizationInput>;
  ContactRequests?: InputMaybe<ContactRequestUpdateManyWithoutOrganizationInput>;
  Contacts?: InputMaybe<ContactUpdateManyWithoutOrganizationInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  custom_fields?: InputMaybe<CustomFieldUpdateManyWithoutOrganizationInput>;
  CustomObject?: InputMaybe<CustomObjectUpdateManyWithoutOrganizationInput>;
  CustomObjectField?: InputMaybe<CustomObjectFieldUpdateManyWithoutOrganizationInput>;
  CustomObjectFieldForeignKey?: InputMaybe<CustomObjectFieldForeignKeyUpdateManyWithoutOrganizationInput>;
  CustomObjectRecord?: InputMaybe<CustomObjectRecordUpdateManyWithoutOrganizationInput>;
  CustomObjectRow?: InputMaybe<CustomObjectRowUpdateManyWithoutOrganizationInput>;
  DashboardColumnOptions?: InputMaybe<DashboardColumnOptionUpdateManyWithoutOrganizationInput>;
  DashboardLeadFilters?: InputMaybe<DashboardLeadFilterUpdateManyWithoutOrganizationInput>;
  DashboardRepFilters?: InputMaybe<DashboardRepFilterUpdateManyWithoutOrganizationInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewUpdateManyWithoutOrganizationInput>;
  day_to_close?: InputMaybe<IntFieldUpdateOperationsInput>;
  default_demo_time_duration?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  demo_held_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_not_held_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_not_held_call_back_short_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_prep_time_minutes?: InputMaybe<IntFieldUpdateOperationsInput>;
  dev_account?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  dial_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  DispositionMultiplier?: InputMaybe<DispositionMultiplierUpdateManyWithoutOrganizationInput>;
  dispositions?: InputMaybe<DispositionUpdateManyWithoutOrganizationInput>;
  double_booking?: InputMaybe<BoolFieldUpdateOperationsInput>;
  dynamic_date_operators?: InputMaybe<BoolFieldUpdateOperationsInput>;
  edge_servers?: InputMaybe<OrganizationUpdateedge_ServersInput>;
  email_conflict_notification_email_list?: InputMaybe<OrganizationUpdateemail_Conflict_Notification_Email_ListInput>;
  EmailItems?: InputMaybe<EmailItemUpdateManyWithoutOrganizationInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordUpdateManyWithoutOrganizationInput>;
  EmailThreads?: InputMaybe<EmailThreadUpdateManyWithoutOrganizationInput>;
  enable_modified_metrics?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  enable_pay_period_view?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  enable_time_constraint?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  error_log_email_address?: InputMaybe<OrganizationUpdateerror_Log_Email_AddressInput>;
  estimated_average_num_of_weekly_overbooked_demo_slots?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  FolderPermissions?: InputMaybe<FolderPermissionUpdateManyWithoutOrganizationInput>;
  Folders?: InputMaybe<FolderUpdateManyWithoutOrganizationInput>;
  FutureBookingSetting?: InputMaybe<FutureBookingSettingUpdateOneWithoutOrganizationInput>;
  GlobalTransferRule?: InputMaybe<GlobalTransferRuleUpdateManyWithoutOrganizationInput>;
  held_demos_per_cc_connect?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  helpful_links?: InputMaybe<OrganizationUpdatehelpful_LinksInput>;
  HingePointMultiplier?: InputMaybe<HingePointMultiplierUpdateManyWithoutOrganizationInput>;
  hold_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
  hubspot_credential?: InputMaybe<HubSpotCredentialUpdateOneWithoutOrganizationInput>;
  hubspot_last_sync_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  hubspot_white_list?: InputMaybe<HubSpotWhitelistUpdateManyWithoutOrganizationInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inbound_close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_dm_connect_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_lt_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_revenue_value_of_sale?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_scheduled_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_set_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventUpdateManyWithoutOrganizationInput>;
  InboundConciergeWidget?: InputMaybe<InboundConciergeWidgetUpdateManyWithoutOrganizationInput>;
  industry_options?: InputMaybe<IndustryOptionUpdateManyWithoutOrganizationInput>;
  initial_sdr_sets_reps_day?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  integration_error_logs?: InputMaybe<IntegrationErrorLogUpdateManyWithoutOrganizationInput>;
  integration_mappings?: InputMaybe<IntegrationMappingUpdateManyWithoutOrganizationInput>;
  IntegrationMappingCustomObject?: InputMaybe<IntegrationMappingCustomObjectUpdateManyWithoutOrganizationInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogUpdateManyWithoutOrganizationInput>;
  IntegrationReadOnlyObject?: InputMaybe<IntegrationReadOnlyObjectUpdateManyWithoutOrganizationInput>;
  international?: InputMaybe<BoolFieldUpdateOperationsInput>;
  is_email_domain_verified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  is_salesforce_sandbox?: InputMaybe<BoolFieldUpdateOperationsInput>;
  last_lead_value_compute_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutOrganizationInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryUpdateManyWithoutOrganizationInput>;
  lead_intent?: InputMaybe<LeadIntentUpdateManyWithoutOrganizationInput>;
  lead_release_same_rep_delay_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  lead_routing_email_list?: InputMaybe<OrganizationUpdatelead_Routing_Email_ListInput>;
  lead_routing_rules?: InputMaybe<RuleUpdateManyWithoutOrganizationInput>;
  LeadCustomObjectRowAssociation?: InputMaybe<LeadCustomObjectRowAssociationUpdateManyWithoutOrganizationInput>;
  LeadFieldMultiplier?: InputMaybe<LeadFieldMultiplierUpdateManyWithoutOrganizationInput>;
  LeadFieldMultiplierCustom?: InputMaybe<LeadFieldMultiplierCustomUpdateManyWithoutOrganizationInput>;
  leads?: InputMaybe<LeadUpdateManyWithoutOrganizationInput>;
  leadsource_options?: InputMaybe<LeadSourceOptionUpdateManyWithoutOrganizationInput>;
  LeadValue?: InputMaybe<LeadValueUpdateManyWithoutOrganizationInput>;
  LifeTimeValueMultiplier?: InputMaybe<LifeTimeValueMultiplierUpdateManyWithoutOrganizationInput>;
  LiveTransferSetting?: InputMaybe<LiveTransferSettingUpdateOneWithoutOrganizationInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusUpdateManyWithoutOrganizationInput>;
  ltv_multiplier_custom_fields?: InputMaybe<LifeTimeValueMultiplierCustomUpdateManyWithoutOrganizationInput>;
  made_contact_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  make_sale_address_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_business_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_city_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_country_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_first_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_industry_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_last_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_lead_source_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_primary_email_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_primary_phone_number_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_state_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_sub_industry_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_zip_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  maxium_day_lag_set_to_schedule?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  mrr?: InputMaybe<IntFieldUpdateOperationsInput>;
  mrr_label?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  mrr_required_on_sale?: InputMaybe<BoolFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  NoteItems?: InputMaybe<NoteItemUpdateManyWithoutOrganizationInput>;
  Notifications?: InputMaybe<NotificationUpdateManyWithoutOrganizationInput>;
  onboardComplete?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  Opportunity?: InputMaybe<SfOpportunityUpdateManyWithoutOrganizationInput>;
  organization_holidays?: InputMaybe<OrganizationHolidayUpdateManyWithoutOrganizationInput>;
  organization_hours?: InputMaybe<OrganizationHourUpdateManyWithoutOrganizationInput>;
  OrganizationPaymentSetting?: InputMaybe<OrganizationPaymentSettingsUpdateOneWithoutOrganizationInput>;
  OrganizationPaymentSettings?: InputMaybe<OrganizationPaymentSettingsUpdateOneWithoutOrganizationsInput>;
  orgCalendarInviteMetadata?: InputMaybe<OrgCalendarInviteMetadataUpdateManyWithoutOrganizationInput>;
  OrgDefaultPipelinePerecentValue?: InputMaybe<OrgDefaultPipelinePerecentValueUpdateOneWithoutOrganizationInput>;
  OrgHubSpotSync?: InputMaybe<OrgHubSpotSyncUpdateOneWithoutOrganizationInput>;
  OrgNoEventDate?: InputMaybe<OrgNoEventDateUpdateManyWithoutOrganizationInput>;
  OrgSalesforceSync?: InputMaybe<OrgSalesforceSyncUpdateOneWithoutOrganizationInput>;
  OrgTimeslotAvailability?: InputMaybe<OrgTimeslotAvailabilityUpdateManyWithoutOrganizationInput>;
  outbound_close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_dm_connect_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_lt_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_revenue_value_of_sale?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_scheduled_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_set_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  PandadocData?: InputMaybe<PandadocDataUpdateOneWithoutOrganizationInput>;
  PandaDocTemplate?: InputMaybe<PandaDocTemplateUpdateManyWithoutOrganizationInput>;
  participant_jitter_buffer_size?: InputMaybe<EnumJitterBufferSizeFieldUpdateOperationsInput>;
  preferred_goal_setting?: InputMaybe<NullableEnumGoalsettingFieldUpdateOperationsInput>;
  prepayment?: InputMaybe<BoolFieldUpdateOperationsInput>;
  ProductChangeLog?: InputMaybe<ProductChangeLogUpdateManyWithoutOrganizationInput>;
  products?: InputMaybe<ProductUpdateManyWithoutOrganizationInput>;
  re_cold_call_hour_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  recordingStatus?: InputMaybe<EnumRecordingStatusFieldUpdateOperationsInput>;
  release_lead_resting?: InputMaybe<IntFieldUpdateOperationsInput>;
  rep_claim_limit?: InputMaybe<IntFieldUpdateOperationsInput>;
  rep_lead_allotment?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  rep_level_call_transfer?: InputMaybe<BoolFieldUpdateOperationsInput>;
  reps_can_bulk_unassign?: InputMaybe<BoolFieldUpdateOperationsInput>;
  reps_can_view_all_notes?: InputMaybe<BoolFieldUpdateOperationsInput>;
  require_sale_notes?: InputMaybe<BoolFieldUpdateOperationsInput>;
  resting_after_skipped_CC?: InputMaybe<IntFieldUpdateOperationsInput>;
  resting_period?: InputMaybe<IntFieldUpdateOperationsInput>;
  resting_period_after_unsuccessful_cold_call?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  resting_period_after_unsuccessful_demo?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  revenue_plan?: InputMaybe<RevenuePlanItemUpdateManyWithoutOrganizationInput>;
  Routings?: InputMaybe<RoutingUpdateManyWithoutOrganizationInput>;
  sale_field_options?: InputMaybe<SaleFieldOptionUpdateManyWithoutOrganizationInput>;
  sale_notification_email_list?: InputMaybe<OrganizationUpdatesale_Notification_Email_ListInput>;
  SaleConfig?: InputMaybe<SaleConfigUpdateOneWithoutOrganizationInput>;
  SaleConfigPage?: InputMaybe<SaleConfigPageUpdateManyWithoutOrganizationInput>;
  SaleConfigSection?: InputMaybe<SaleConfigSectionUpdateManyWithoutOrganizationInput>;
  SaleCycles?: InputMaybe<SaleCycleUpdateManyWithoutOrganizationInput>;
  SaleItems?: InputMaybe<SaleItemUpdateManyWithoutOrganizationInput>;
  sales?: InputMaybe<SaleUpdateManyWithoutOrganizationInput>;
  salesforce_domain?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  salesforce_last_sync_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  SalesForceColumn?: InputMaybe<SalesForceColumnUpdateManyWithoutOrganizationInput>;
  salesForceData?: InputMaybe<SalesForceDataUpdateOneWithoutOrganizationInput>;
  ScheduleItems?: InputMaybe<ScheduleItemUpdateManyWithoutOrganizationInput>;
  self_sourcing_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  send_error_log_email?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  send_lead_routing_email?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sendgrid_email_domain?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sendgrid_email_domain_id?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  sequence?: InputMaybe<SequenceUpdateManyWithoutOrganzationInput>;
  SequenceCriteriaConditions?: InputMaybe<SequenceCriteriaConditionUpdateManyWithoutOrganizationInput>;
  SequenceDashboardViews?: InputMaybe<SequenceDashboardViewUpdateManyWithoutOrganizationInput>;
  SequenceEntryCriterias?: InputMaybe<SequenceEntryCriteriaUpdateManyWithoutOrganizationInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutOrganizationInput>;
  SequenceSteps?: InputMaybe<SequenceStepUpdateManyWithoutOrganizationInput>;
  set_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
  short_resting?: InputMaybe<IntFieldUpdateOperationsInput>;
  show_calendar_options?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_contract_duration_sales?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_lead_source_to_rep?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_nocontact_button?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_payment_terms_sales?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_rep_all_leads?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_rep_my_leads?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_system_view?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sites?: InputMaybe<SiteUpdateManyWithoutOrganizationInput>;
  sms_opt_in_dialog?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SMSItems?: InputMaybe<SmsItemUpdateManyWithoutOrganizationInput>;
  SnoozeTrackings?: InputMaybe<SnoozeTrackingUpdateManyWithoutOrganizationInput>;
  StepActionLogs?: InputMaybe<StepActionLogUpdateManyWithoutOrganizationInput>;
  StepActions?: InputMaybe<StepActionUpdateManyWithoutOrganizationInput>;
  StepConditions?: InputMaybe<StepConditionUpdateManyWithoutOrganizationInput>;
  teams?: InputMaybe<TeamUpdateManyWithoutOrganizationInput>;
  Template?: InputMaybe<TemplateUpdateManyWithoutOrganizationInput>;
  TemplateVersions?: InputMaybe<TemplateVersionUpdateManyWithoutOrganizationInput>;
  timekit_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timezone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transfer_timeout?: InputMaybe<IntFieldUpdateOperationsInput>;
  TransferAttempts?: InputMaybe<TransferAttemptUpdateManyWithoutOrganizationInput>;
  twilio_numbers?: InputMaybe<TwilioNumberUpdateManyWithoutOrganizationInput>;
  TwilioFeedbacks?: InputMaybe<TwilioFeedbackUpdateManyWithoutOrganizationInput>;
  TwilioInfo?: InputMaybe<TwilioInfoUpdateOneWithoutOrganzationInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  use_revenue_planning?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  UserCustomFieldFilters?: InputMaybe<UserCustomFieldFilterUpdateManyWithoutOrganizationInput>;
  users?: InputMaybe<UserUpdateManyWithoutOrganizationInput>;
  win_rep_day_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
};

export type OrganizationUpdateWithoutDashboardColumnOptionsInput = {
  ActivityMonitorMultiplier?: InputMaybe<ActivityMonitorMultiplierUpdateManyWithoutOrganizationInput>;
  algoFactor?: InputMaybe<AlgoFactorUpdateManyWithoutOrganizationInput>;
  allow_calendar_invite?: InputMaybe<BoolFieldUpdateOperationsInput>;
  allow_remove_step_action?: InputMaybe<BoolFieldUpdateOperationsInput>;
  allow_snooze_step_action?: InputMaybe<BoolFieldUpdateOperationsInput>;
  always_record?: InputMaybe<BoolFieldUpdateOperationsInput>;
  arv_initial_held_demos_generated_by_sdr_weight?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  AssociationHistories?: InputMaybe<AssociationHistoryUpdateManyWithoutOrganizationInput>;
  auto_call_interval?: InputMaybe<IntFieldUpdateOperationsInput>;
  auto_dial?: InputMaybe<BoolFieldUpdateOperationsInput>;
  automated_campaigns?: InputMaybe<AutomatedCampaignUpdateManyWithoutOrganizationInput>;
  automatic_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  AutoTransferSelections?: InputMaybe<AutoTransferSelectionUpdateManyWithoutOrganizationInput>;
  Availablities?: InputMaybe<AvailabilityUpdateManyWithoutOrganizationInput>;
  AverageOrderValue?: InputMaybe<AverageOrderValueUpdateManyWithoutOrganizationInput>;
  best_action_ever_multiplier?: InputMaybe<FloatFieldUpdateOperationsInput>;
  best_metrics?: InputMaybe<BestMetricRecordUpdateManyWithoutOrganizationInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptUpdateManyWithoutOrganizationInput>;
  callMeNows?: InputMaybe<CallMeNowUpdateManyWithoutOrganizationInput>;
  CallMultiplier?: InputMaybe<CallMultiplierUpdateManyWithoutOrganizationInput>;
  CallSharings?: InputMaybe<CallSharingUpdateManyWithoutOrganizationInput>;
  can_edit_sdr_transfer_rules?: InputMaybe<BoolFieldUpdateOperationsInput>;
  claim_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  concession?: InputMaybe<ConcessionUpdateManyWithoutOrganizationInput>;
  conference_region?: InputMaybe<EnumConferenceRegionFieldUpdateOperationsInput>;
  Conferences?: InputMaybe<ConferenceUpdateManyWithoutOrganizationInput>;
  ContactRequests?: InputMaybe<ContactRequestUpdateManyWithoutOrganizationInput>;
  Contacts?: InputMaybe<ContactUpdateManyWithoutOrganizationInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  custom_fields?: InputMaybe<CustomFieldUpdateManyWithoutOrganizationInput>;
  CustomObject?: InputMaybe<CustomObjectUpdateManyWithoutOrganizationInput>;
  CustomObjectField?: InputMaybe<CustomObjectFieldUpdateManyWithoutOrganizationInput>;
  CustomObjectFieldForeignKey?: InputMaybe<CustomObjectFieldForeignKeyUpdateManyWithoutOrganizationInput>;
  CustomObjectRecord?: InputMaybe<CustomObjectRecordUpdateManyWithoutOrganizationInput>;
  CustomObjectRow?: InputMaybe<CustomObjectRowUpdateManyWithoutOrganizationInput>;
  CustomQueueItems?: InputMaybe<CustomQueueItemUpdateManyWithoutOrganizationInput>;
  DashboardLeadFilters?: InputMaybe<DashboardLeadFilterUpdateManyWithoutOrganizationInput>;
  DashboardRepFilters?: InputMaybe<DashboardRepFilterUpdateManyWithoutOrganizationInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewUpdateManyWithoutOrganizationInput>;
  day_to_close?: InputMaybe<IntFieldUpdateOperationsInput>;
  default_demo_time_duration?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  demo_held_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_not_held_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_not_held_call_back_short_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_prep_time_minutes?: InputMaybe<IntFieldUpdateOperationsInput>;
  dev_account?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  dial_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  DispositionMultiplier?: InputMaybe<DispositionMultiplierUpdateManyWithoutOrganizationInput>;
  dispositions?: InputMaybe<DispositionUpdateManyWithoutOrganizationInput>;
  double_booking?: InputMaybe<BoolFieldUpdateOperationsInput>;
  dynamic_date_operators?: InputMaybe<BoolFieldUpdateOperationsInput>;
  edge_servers?: InputMaybe<OrganizationUpdateedge_ServersInput>;
  email_conflict_notification_email_list?: InputMaybe<OrganizationUpdateemail_Conflict_Notification_Email_ListInput>;
  EmailItems?: InputMaybe<EmailItemUpdateManyWithoutOrganizationInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordUpdateManyWithoutOrganizationInput>;
  EmailThreads?: InputMaybe<EmailThreadUpdateManyWithoutOrganizationInput>;
  enable_modified_metrics?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  enable_pay_period_view?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  enable_time_constraint?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  error_log_email_address?: InputMaybe<OrganizationUpdateerror_Log_Email_AddressInput>;
  estimated_average_num_of_weekly_overbooked_demo_slots?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  FolderPermissions?: InputMaybe<FolderPermissionUpdateManyWithoutOrganizationInput>;
  Folders?: InputMaybe<FolderUpdateManyWithoutOrganizationInput>;
  FutureBookingSetting?: InputMaybe<FutureBookingSettingUpdateOneWithoutOrganizationInput>;
  GlobalTransferRule?: InputMaybe<GlobalTransferRuleUpdateManyWithoutOrganizationInput>;
  held_demos_per_cc_connect?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  helpful_links?: InputMaybe<OrganizationUpdatehelpful_LinksInput>;
  HingePointMultiplier?: InputMaybe<HingePointMultiplierUpdateManyWithoutOrganizationInput>;
  hold_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
  hubspot_credential?: InputMaybe<HubSpotCredentialUpdateOneWithoutOrganizationInput>;
  hubspot_last_sync_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  hubspot_white_list?: InputMaybe<HubSpotWhitelistUpdateManyWithoutOrganizationInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inbound_close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_dm_connect_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_lt_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_revenue_value_of_sale?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_scheduled_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_set_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventUpdateManyWithoutOrganizationInput>;
  InboundConciergeWidget?: InputMaybe<InboundConciergeWidgetUpdateManyWithoutOrganizationInput>;
  industry_options?: InputMaybe<IndustryOptionUpdateManyWithoutOrganizationInput>;
  initial_sdr_sets_reps_day?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  integration_error_logs?: InputMaybe<IntegrationErrorLogUpdateManyWithoutOrganizationInput>;
  integration_mappings?: InputMaybe<IntegrationMappingUpdateManyWithoutOrganizationInput>;
  IntegrationMappingCustomObject?: InputMaybe<IntegrationMappingCustomObjectUpdateManyWithoutOrganizationInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogUpdateManyWithoutOrganizationInput>;
  IntegrationReadOnlyObject?: InputMaybe<IntegrationReadOnlyObjectUpdateManyWithoutOrganizationInput>;
  international?: InputMaybe<BoolFieldUpdateOperationsInput>;
  is_email_domain_verified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  is_salesforce_sandbox?: InputMaybe<BoolFieldUpdateOperationsInput>;
  last_lead_value_compute_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutOrganizationInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryUpdateManyWithoutOrganizationInput>;
  lead_intent?: InputMaybe<LeadIntentUpdateManyWithoutOrganizationInput>;
  lead_release_same_rep_delay_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  lead_routing_email_list?: InputMaybe<OrganizationUpdatelead_Routing_Email_ListInput>;
  lead_routing_rules?: InputMaybe<RuleUpdateManyWithoutOrganizationInput>;
  LeadCustomObjectRowAssociation?: InputMaybe<LeadCustomObjectRowAssociationUpdateManyWithoutOrganizationInput>;
  LeadFieldMultiplier?: InputMaybe<LeadFieldMultiplierUpdateManyWithoutOrganizationInput>;
  LeadFieldMultiplierCustom?: InputMaybe<LeadFieldMultiplierCustomUpdateManyWithoutOrganizationInput>;
  leads?: InputMaybe<LeadUpdateManyWithoutOrganizationInput>;
  leadsource_options?: InputMaybe<LeadSourceOptionUpdateManyWithoutOrganizationInput>;
  LeadValue?: InputMaybe<LeadValueUpdateManyWithoutOrganizationInput>;
  LifeTimeValueMultiplier?: InputMaybe<LifeTimeValueMultiplierUpdateManyWithoutOrganizationInput>;
  LiveTransferSetting?: InputMaybe<LiveTransferSettingUpdateOneWithoutOrganizationInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusUpdateManyWithoutOrganizationInput>;
  ltv_multiplier_custom_fields?: InputMaybe<LifeTimeValueMultiplierCustomUpdateManyWithoutOrganizationInput>;
  made_contact_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  make_sale_address_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_business_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_city_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_country_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_first_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_industry_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_last_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_lead_source_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_primary_email_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_primary_phone_number_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_state_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_sub_industry_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_zip_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  maxium_day_lag_set_to_schedule?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  mrr?: InputMaybe<IntFieldUpdateOperationsInput>;
  mrr_label?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  mrr_required_on_sale?: InputMaybe<BoolFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  NoteItems?: InputMaybe<NoteItemUpdateManyWithoutOrganizationInput>;
  Notifications?: InputMaybe<NotificationUpdateManyWithoutOrganizationInput>;
  onboardComplete?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  Opportunity?: InputMaybe<SfOpportunityUpdateManyWithoutOrganizationInput>;
  organization_holidays?: InputMaybe<OrganizationHolidayUpdateManyWithoutOrganizationInput>;
  organization_hours?: InputMaybe<OrganizationHourUpdateManyWithoutOrganizationInput>;
  OrganizationPaymentSetting?: InputMaybe<OrganizationPaymentSettingsUpdateOneWithoutOrganizationInput>;
  OrganizationPaymentSettings?: InputMaybe<OrganizationPaymentSettingsUpdateOneWithoutOrganizationsInput>;
  orgCalendarInviteMetadata?: InputMaybe<OrgCalendarInviteMetadataUpdateManyWithoutOrganizationInput>;
  OrgDefaultPipelinePerecentValue?: InputMaybe<OrgDefaultPipelinePerecentValueUpdateOneWithoutOrganizationInput>;
  OrgHubSpotSync?: InputMaybe<OrgHubSpotSyncUpdateOneWithoutOrganizationInput>;
  OrgNoEventDate?: InputMaybe<OrgNoEventDateUpdateManyWithoutOrganizationInput>;
  OrgSalesforceSync?: InputMaybe<OrgSalesforceSyncUpdateOneWithoutOrganizationInput>;
  OrgTimeslotAvailability?: InputMaybe<OrgTimeslotAvailabilityUpdateManyWithoutOrganizationInput>;
  outbound_close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_dm_connect_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_lt_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_revenue_value_of_sale?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_scheduled_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_set_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  PandadocData?: InputMaybe<PandadocDataUpdateOneWithoutOrganizationInput>;
  PandaDocTemplate?: InputMaybe<PandaDocTemplateUpdateManyWithoutOrganizationInput>;
  participant_jitter_buffer_size?: InputMaybe<EnumJitterBufferSizeFieldUpdateOperationsInput>;
  preferred_goal_setting?: InputMaybe<NullableEnumGoalsettingFieldUpdateOperationsInput>;
  prepayment?: InputMaybe<BoolFieldUpdateOperationsInput>;
  ProductChangeLog?: InputMaybe<ProductChangeLogUpdateManyWithoutOrganizationInput>;
  products?: InputMaybe<ProductUpdateManyWithoutOrganizationInput>;
  re_cold_call_hour_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  recordingStatus?: InputMaybe<EnumRecordingStatusFieldUpdateOperationsInput>;
  release_lead_resting?: InputMaybe<IntFieldUpdateOperationsInput>;
  rep_claim_limit?: InputMaybe<IntFieldUpdateOperationsInput>;
  rep_lead_allotment?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  rep_level_call_transfer?: InputMaybe<BoolFieldUpdateOperationsInput>;
  reps_can_bulk_unassign?: InputMaybe<BoolFieldUpdateOperationsInput>;
  reps_can_view_all_notes?: InputMaybe<BoolFieldUpdateOperationsInput>;
  require_sale_notes?: InputMaybe<BoolFieldUpdateOperationsInput>;
  resting_after_skipped_CC?: InputMaybe<IntFieldUpdateOperationsInput>;
  resting_period?: InputMaybe<IntFieldUpdateOperationsInput>;
  resting_period_after_unsuccessful_cold_call?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  resting_period_after_unsuccessful_demo?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  revenue_plan?: InputMaybe<RevenuePlanItemUpdateManyWithoutOrganizationInput>;
  Routings?: InputMaybe<RoutingUpdateManyWithoutOrganizationInput>;
  sale_field_options?: InputMaybe<SaleFieldOptionUpdateManyWithoutOrganizationInput>;
  sale_notification_email_list?: InputMaybe<OrganizationUpdatesale_Notification_Email_ListInput>;
  SaleConfig?: InputMaybe<SaleConfigUpdateOneWithoutOrganizationInput>;
  SaleConfigPage?: InputMaybe<SaleConfigPageUpdateManyWithoutOrganizationInput>;
  SaleConfigSection?: InputMaybe<SaleConfigSectionUpdateManyWithoutOrganizationInput>;
  SaleCycles?: InputMaybe<SaleCycleUpdateManyWithoutOrganizationInput>;
  SaleItems?: InputMaybe<SaleItemUpdateManyWithoutOrganizationInput>;
  sales?: InputMaybe<SaleUpdateManyWithoutOrganizationInput>;
  salesforce_domain?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  salesforce_last_sync_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  SalesForceColumn?: InputMaybe<SalesForceColumnUpdateManyWithoutOrganizationInput>;
  salesForceData?: InputMaybe<SalesForceDataUpdateOneWithoutOrganizationInput>;
  ScheduleItems?: InputMaybe<ScheduleItemUpdateManyWithoutOrganizationInput>;
  self_sourcing_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  send_error_log_email?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  send_lead_routing_email?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sendgrid_email_domain?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sendgrid_email_domain_id?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  sequence?: InputMaybe<SequenceUpdateManyWithoutOrganzationInput>;
  SequenceCriteriaConditions?: InputMaybe<SequenceCriteriaConditionUpdateManyWithoutOrganizationInput>;
  SequenceDashboardViews?: InputMaybe<SequenceDashboardViewUpdateManyWithoutOrganizationInput>;
  SequenceEntryCriterias?: InputMaybe<SequenceEntryCriteriaUpdateManyWithoutOrganizationInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutOrganizationInput>;
  SequenceSteps?: InputMaybe<SequenceStepUpdateManyWithoutOrganizationInput>;
  set_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
  short_resting?: InputMaybe<IntFieldUpdateOperationsInput>;
  show_calendar_options?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_contract_duration_sales?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_lead_source_to_rep?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_nocontact_button?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_payment_terms_sales?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_rep_all_leads?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_rep_my_leads?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_system_view?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sites?: InputMaybe<SiteUpdateManyWithoutOrganizationInput>;
  sms_opt_in_dialog?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SMSItems?: InputMaybe<SmsItemUpdateManyWithoutOrganizationInput>;
  SnoozeTrackings?: InputMaybe<SnoozeTrackingUpdateManyWithoutOrganizationInput>;
  StepActionLogs?: InputMaybe<StepActionLogUpdateManyWithoutOrganizationInput>;
  StepActions?: InputMaybe<StepActionUpdateManyWithoutOrganizationInput>;
  StepConditions?: InputMaybe<StepConditionUpdateManyWithoutOrganizationInput>;
  teams?: InputMaybe<TeamUpdateManyWithoutOrganizationInput>;
  Template?: InputMaybe<TemplateUpdateManyWithoutOrganizationInput>;
  TemplateVersions?: InputMaybe<TemplateVersionUpdateManyWithoutOrganizationInput>;
  timekit_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timezone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transfer_timeout?: InputMaybe<IntFieldUpdateOperationsInput>;
  TransferAttempts?: InputMaybe<TransferAttemptUpdateManyWithoutOrganizationInput>;
  twilio_numbers?: InputMaybe<TwilioNumberUpdateManyWithoutOrganizationInput>;
  TwilioFeedbacks?: InputMaybe<TwilioFeedbackUpdateManyWithoutOrganizationInput>;
  TwilioInfo?: InputMaybe<TwilioInfoUpdateOneWithoutOrganzationInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  use_revenue_planning?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  UserCustomFieldFilters?: InputMaybe<UserCustomFieldFilterUpdateManyWithoutOrganizationInput>;
  users?: InputMaybe<UserUpdateManyWithoutOrganizationInput>;
  win_rep_day_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
};

export type OrganizationUpdateWithoutDashboardLeadFiltersInput = {
  ActivityMonitorMultiplier?: InputMaybe<ActivityMonitorMultiplierUpdateManyWithoutOrganizationInput>;
  algoFactor?: InputMaybe<AlgoFactorUpdateManyWithoutOrganizationInput>;
  allow_calendar_invite?: InputMaybe<BoolFieldUpdateOperationsInput>;
  allow_remove_step_action?: InputMaybe<BoolFieldUpdateOperationsInput>;
  allow_snooze_step_action?: InputMaybe<BoolFieldUpdateOperationsInput>;
  always_record?: InputMaybe<BoolFieldUpdateOperationsInput>;
  arv_initial_held_demos_generated_by_sdr_weight?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  AssociationHistories?: InputMaybe<AssociationHistoryUpdateManyWithoutOrganizationInput>;
  auto_call_interval?: InputMaybe<IntFieldUpdateOperationsInput>;
  auto_dial?: InputMaybe<BoolFieldUpdateOperationsInput>;
  automated_campaigns?: InputMaybe<AutomatedCampaignUpdateManyWithoutOrganizationInput>;
  automatic_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  AutoTransferSelections?: InputMaybe<AutoTransferSelectionUpdateManyWithoutOrganizationInput>;
  Availablities?: InputMaybe<AvailabilityUpdateManyWithoutOrganizationInput>;
  AverageOrderValue?: InputMaybe<AverageOrderValueUpdateManyWithoutOrganizationInput>;
  best_action_ever_multiplier?: InputMaybe<FloatFieldUpdateOperationsInput>;
  best_metrics?: InputMaybe<BestMetricRecordUpdateManyWithoutOrganizationInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptUpdateManyWithoutOrganizationInput>;
  callMeNows?: InputMaybe<CallMeNowUpdateManyWithoutOrganizationInput>;
  CallMultiplier?: InputMaybe<CallMultiplierUpdateManyWithoutOrganizationInput>;
  CallSharings?: InputMaybe<CallSharingUpdateManyWithoutOrganizationInput>;
  can_edit_sdr_transfer_rules?: InputMaybe<BoolFieldUpdateOperationsInput>;
  claim_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  concession?: InputMaybe<ConcessionUpdateManyWithoutOrganizationInput>;
  conference_region?: InputMaybe<EnumConferenceRegionFieldUpdateOperationsInput>;
  Conferences?: InputMaybe<ConferenceUpdateManyWithoutOrganizationInput>;
  ContactRequests?: InputMaybe<ContactRequestUpdateManyWithoutOrganizationInput>;
  Contacts?: InputMaybe<ContactUpdateManyWithoutOrganizationInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  custom_fields?: InputMaybe<CustomFieldUpdateManyWithoutOrganizationInput>;
  CustomObject?: InputMaybe<CustomObjectUpdateManyWithoutOrganizationInput>;
  CustomObjectField?: InputMaybe<CustomObjectFieldUpdateManyWithoutOrganizationInput>;
  CustomObjectFieldForeignKey?: InputMaybe<CustomObjectFieldForeignKeyUpdateManyWithoutOrganizationInput>;
  CustomObjectRecord?: InputMaybe<CustomObjectRecordUpdateManyWithoutOrganizationInput>;
  CustomObjectRow?: InputMaybe<CustomObjectRowUpdateManyWithoutOrganizationInput>;
  CustomQueueItems?: InputMaybe<CustomQueueItemUpdateManyWithoutOrganizationInput>;
  DashboardColumnOptions?: InputMaybe<DashboardColumnOptionUpdateManyWithoutOrganizationInput>;
  DashboardRepFilters?: InputMaybe<DashboardRepFilterUpdateManyWithoutOrganizationInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewUpdateManyWithoutOrganizationInput>;
  day_to_close?: InputMaybe<IntFieldUpdateOperationsInput>;
  default_demo_time_duration?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  demo_held_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_not_held_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_not_held_call_back_short_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_prep_time_minutes?: InputMaybe<IntFieldUpdateOperationsInput>;
  dev_account?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  dial_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  DispositionMultiplier?: InputMaybe<DispositionMultiplierUpdateManyWithoutOrganizationInput>;
  dispositions?: InputMaybe<DispositionUpdateManyWithoutOrganizationInput>;
  double_booking?: InputMaybe<BoolFieldUpdateOperationsInput>;
  dynamic_date_operators?: InputMaybe<BoolFieldUpdateOperationsInput>;
  edge_servers?: InputMaybe<OrganizationUpdateedge_ServersInput>;
  email_conflict_notification_email_list?: InputMaybe<OrganizationUpdateemail_Conflict_Notification_Email_ListInput>;
  EmailItems?: InputMaybe<EmailItemUpdateManyWithoutOrganizationInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordUpdateManyWithoutOrganizationInput>;
  EmailThreads?: InputMaybe<EmailThreadUpdateManyWithoutOrganizationInput>;
  enable_modified_metrics?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  enable_pay_period_view?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  enable_time_constraint?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  error_log_email_address?: InputMaybe<OrganizationUpdateerror_Log_Email_AddressInput>;
  estimated_average_num_of_weekly_overbooked_demo_slots?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  FolderPermissions?: InputMaybe<FolderPermissionUpdateManyWithoutOrganizationInput>;
  Folders?: InputMaybe<FolderUpdateManyWithoutOrganizationInput>;
  FutureBookingSetting?: InputMaybe<FutureBookingSettingUpdateOneWithoutOrganizationInput>;
  GlobalTransferRule?: InputMaybe<GlobalTransferRuleUpdateManyWithoutOrganizationInput>;
  held_demos_per_cc_connect?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  helpful_links?: InputMaybe<OrganizationUpdatehelpful_LinksInput>;
  HingePointMultiplier?: InputMaybe<HingePointMultiplierUpdateManyWithoutOrganizationInput>;
  hold_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
  hubspot_credential?: InputMaybe<HubSpotCredentialUpdateOneWithoutOrganizationInput>;
  hubspot_last_sync_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  hubspot_white_list?: InputMaybe<HubSpotWhitelistUpdateManyWithoutOrganizationInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inbound_close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_dm_connect_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_lt_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_revenue_value_of_sale?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_scheduled_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_set_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventUpdateManyWithoutOrganizationInput>;
  InboundConciergeWidget?: InputMaybe<InboundConciergeWidgetUpdateManyWithoutOrganizationInput>;
  industry_options?: InputMaybe<IndustryOptionUpdateManyWithoutOrganizationInput>;
  initial_sdr_sets_reps_day?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  integration_error_logs?: InputMaybe<IntegrationErrorLogUpdateManyWithoutOrganizationInput>;
  integration_mappings?: InputMaybe<IntegrationMappingUpdateManyWithoutOrganizationInput>;
  IntegrationMappingCustomObject?: InputMaybe<IntegrationMappingCustomObjectUpdateManyWithoutOrganizationInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogUpdateManyWithoutOrganizationInput>;
  IntegrationReadOnlyObject?: InputMaybe<IntegrationReadOnlyObjectUpdateManyWithoutOrganizationInput>;
  international?: InputMaybe<BoolFieldUpdateOperationsInput>;
  is_email_domain_verified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  is_salesforce_sandbox?: InputMaybe<BoolFieldUpdateOperationsInput>;
  last_lead_value_compute_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutOrganizationInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryUpdateManyWithoutOrganizationInput>;
  lead_intent?: InputMaybe<LeadIntentUpdateManyWithoutOrganizationInput>;
  lead_release_same_rep_delay_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  lead_routing_email_list?: InputMaybe<OrganizationUpdatelead_Routing_Email_ListInput>;
  lead_routing_rules?: InputMaybe<RuleUpdateManyWithoutOrganizationInput>;
  LeadCustomObjectRowAssociation?: InputMaybe<LeadCustomObjectRowAssociationUpdateManyWithoutOrganizationInput>;
  LeadFieldMultiplier?: InputMaybe<LeadFieldMultiplierUpdateManyWithoutOrganizationInput>;
  LeadFieldMultiplierCustom?: InputMaybe<LeadFieldMultiplierCustomUpdateManyWithoutOrganizationInput>;
  leads?: InputMaybe<LeadUpdateManyWithoutOrganizationInput>;
  leadsource_options?: InputMaybe<LeadSourceOptionUpdateManyWithoutOrganizationInput>;
  LeadValue?: InputMaybe<LeadValueUpdateManyWithoutOrganizationInput>;
  LifeTimeValueMultiplier?: InputMaybe<LifeTimeValueMultiplierUpdateManyWithoutOrganizationInput>;
  LiveTransferSetting?: InputMaybe<LiveTransferSettingUpdateOneWithoutOrganizationInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusUpdateManyWithoutOrganizationInput>;
  ltv_multiplier_custom_fields?: InputMaybe<LifeTimeValueMultiplierCustomUpdateManyWithoutOrganizationInput>;
  made_contact_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  make_sale_address_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_business_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_city_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_country_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_first_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_industry_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_last_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_lead_source_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_primary_email_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_primary_phone_number_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_state_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_sub_industry_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_zip_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  maxium_day_lag_set_to_schedule?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  mrr?: InputMaybe<IntFieldUpdateOperationsInput>;
  mrr_label?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  mrr_required_on_sale?: InputMaybe<BoolFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  NoteItems?: InputMaybe<NoteItemUpdateManyWithoutOrganizationInput>;
  Notifications?: InputMaybe<NotificationUpdateManyWithoutOrganizationInput>;
  onboardComplete?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  Opportunity?: InputMaybe<SfOpportunityUpdateManyWithoutOrganizationInput>;
  organization_holidays?: InputMaybe<OrganizationHolidayUpdateManyWithoutOrganizationInput>;
  organization_hours?: InputMaybe<OrganizationHourUpdateManyWithoutOrganizationInput>;
  OrganizationPaymentSetting?: InputMaybe<OrganizationPaymentSettingsUpdateOneWithoutOrganizationInput>;
  OrganizationPaymentSettings?: InputMaybe<OrganizationPaymentSettingsUpdateOneWithoutOrganizationsInput>;
  orgCalendarInviteMetadata?: InputMaybe<OrgCalendarInviteMetadataUpdateManyWithoutOrganizationInput>;
  OrgDefaultPipelinePerecentValue?: InputMaybe<OrgDefaultPipelinePerecentValueUpdateOneWithoutOrganizationInput>;
  OrgHubSpotSync?: InputMaybe<OrgHubSpotSyncUpdateOneWithoutOrganizationInput>;
  OrgNoEventDate?: InputMaybe<OrgNoEventDateUpdateManyWithoutOrganizationInput>;
  OrgSalesforceSync?: InputMaybe<OrgSalesforceSyncUpdateOneWithoutOrganizationInput>;
  OrgTimeslotAvailability?: InputMaybe<OrgTimeslotAvailabilityUpdateManyWithoutOrganizationInput>;
  outbound_close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_dm_connect_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_lt_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_revenue_value_of_sale?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_scheduled_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_set_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  PandadocData?: InputMaybe<PandadocDataUpdateOneWithoutOrganizationInput>;
  PandaDocTemplate?: InputMaybe<PandaDocTemplateUpdateManyWithoutOrganizationInput>;
  participant_jitter_buffer_size?: InputMaybe<EnumJitterBufferSizeFieldUpdateOperationsInput>;
  preferred_goal_setting?: InputMaybe<NullableEnumGoalsettingFieldUpdateOperationsInput>;
  prepayment?: InputMaybe<BoolFieldUpdateOperationsInput>;
  ProductChangeLog?: InputMaybe<ProductChangeLogUpdateManyWithoutOrganizationInput>;
  products?: InputMaybe<ProductUpdateManyWithoutOrganizationInput>;
  re_cold_call_hour_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  recordingStatus?: InputMaybe<EnumRecordingStatusFieldUpdateOperationsInput>;
  release_lead_resting?: InputMaybe<IntFieldUpdateOperationsInput>;
  rep_claim_limit?: InputMaybe<IntFieldUpdateOperationsInput>;
  rep_lead_allotment?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  rep_level_call_transfer?: InputMaybe<BoolFieldUpdateOperationsInput>;
  reps_can_bulk_unassign?: InputMaybe<BoolFieldUpdateOperationsInput>;
  reps_can_view_all_notes?: InputMaybe<BoolFieldUpdateOperationsInput>;
  require_sale_notes?: InputMaybe<BoolFieldUpdateOperationsInput>;
  resting_after_skipped_CC?: InputMaybe<IntFieldUpdateOperationsInput>;
  resting_period?: InputMaybe<IntFieldUpdateOperationsInput>;
  resting_period_after_unsuccessful_cold_call?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  resting_period_after_unsuccessful_demo?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  revenue_plan?: InputMaybe<RevenuePlanItemUpdateManyWithoutOrganizationInput>;
  Routings?: InputMaybe<RoutingUpdateManyWithoutOrganizationInput>;
  sale_field_options?: InputMaybe<SaleFieldOptionUpdateManyWithoutOrganizationInput>;
  sale_notification_email_list?: InputMaybe<OrganizationUpdatesale_Notification_Email_ListInput>;
  SaleConfig?: InputMaybe<SaleConfigUpdateOneWithoutOrganizationInput>;
  SaleConfigPage?: InputMaybe<SaleConfigPageUpdateManyWithoutOrganizationInput>;
  SaleConfigSection?: InputMaybe<SaleConfigSectionUpdateManyWithoutOrganizationInput>;
  SaleCycles?: InputMaybe<SaleCycleUpdateManyWithoutOrganizationInput>;
  SaleItems?: InputMaybe<SaleItemUpdateManyWithoutOrganizationInput>;
  sales?: InputMaybe<SaleUpdateManyWithoutOrganizationInput>;
  salesforce_domain?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  salesforce_last_sync_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  SalesForceColumn?: InputMaybe<SalesForceColumnUpdateManyWithoutOrganizationInput>;
  salesForceData?: InputMaybe<SalesForceDataUpdateOneWithoutOrganizationInput>;
  ScheduleItems?: InputMaybe<ScheduleItemUpdateManyWithoutOrganizationInput>;
  self_sourcing_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  send_error_log_email?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  send_lead_routing_email?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sendgrid_email_domain?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sendgrid_email_domain_id?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  sequence?: InputMaybe<SequenceUpdateManyWithoutOrganzationInput>;
  SequenceCriteriaConditions?: InputMaybe<SequenceCriteriaConditionUpdateManyWithoutOrganizationInput>;
  SequenceDashboardViews?: InputMaybe<SequenceDashboardViewUpdateManyWithoutOrganizationInput>;
  SequenceEntryCriterias?: InputMaybe<SequenceEntryCriteriaUpdateManyWithoutOrganizationInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutOrganizationInput>;
  SequenceSteps?: InputMaybe<SequenceStepUpdateManyWithoutOrganizationInput>;
  set_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
  short_resting?: InputMaybe<IntFieldUpdateOperationsInput>;
  show_calendar_options?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_contract_duration_sales?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_lead_source_to_rep?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_nocontact_button?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_payment_terms_sales?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_rep_all_leads?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_rep_my_leads?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_system_view?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sites?: InputMaybe<SiteUpdateManyWithoutOrganizationInput>;
  sms_opt_in_dialog?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SMSItems?: InputMaybe<SmsItemUpdateManyWithoutOrganizationInput>;
  SnoozeTrackings?: InputMaybe<SnoozeTrackingUpdateManyWithoutOrganizationInput>;
  StepActionLogs?: InputMaybe<StepActionLogUpdateManyWithoutOrganizationInput>;
  StepActions?: InputMaybe<StepActionUpdateManyWithoutOrganizationInput>;
  StepConditions?: InputMaybe<StepConditionUpdateManyWithoutOrganizationInput>;
  teams?: InputMaybe<TeamUpdateManyWithoutOrganizationInput>;
  Template?: InputMaybe<TemplateUpdateManyWithoutOrganizationInput>;
  TemplateVersions?: InputMaybe<TemplateVersionUpdateManyWithoutOrganizationInput>;
  timekit_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timezone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transfer_timeout?: InputMaybe<IntFieldUpdateOperationsInput>;
  TransferAttempts?: InputMaybe<TransferAttemptUpdateManyWithoutOrganizationInput>;
  twilio_numbers?: InputMaybe<TwilioNumberUpdateManyWithoutOrganizationInput>;
  TwilioFeedbacks?: InputMaybe<TwilioFeedbackUpdateManyWithoutOrganizationInput>;
  TwilioInfo?: InputMaybe<TwilioInfoUpdateOneWithoutOrganzationInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  use_revenue_planning?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  UserCustomFieldFilters?: InputMaybe<UserCustomFieldFilterUpdateManyWithoutOrganizationInput>;
  users?: InputMaybe<UserUpdateManyWithoutOrganizationInput>;
  win_rep_day_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
};

export type OrganizationUpdateWithoutDashboardRepFiltersInput = {
  ActivityMonitorMultiplier?: InputMaybe<ActivityMonitorMultiplierUpdateManyWithoutOrganizationInput>;
  algoFactor?: InputMaybe<AlgoFactorUpdateManyWithoutOrganizationInput>;
  allow_calendar_invite?: InputMaybe<BoolFieldUpdateOperationsInput>;
  allow_remove_step_action?: InputMaybe<BoolFieldUpdateOperationsInput>;
  allow_snooze_step_action?: InputMaybe<BoolFieldUpdateOperationsInput>;
  always_record?: InputMaybe<BoolFieldUpdateOperationsInput>;
  arv_initial_held_demos_generated_by_sdr_weight?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  AssociationHistories?: InputMaybe<AssociationHistoryUpdateManyWithoutOrganizationInput>;
  auto_call_interval?: InputMaybe<IntFieldUpdateOperationsInput>;
  auto_dial?: InputMaybe<BoolFieldUpdateOperationsInput>;
  automated_campaigns?: InputMaybe<AutomatedCampaignUpdateManyWithoutOrganizationInput>;
  automatic_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  AutoTransferSelections?: InputMaybe<AutoTransferSelectionUpdateManyWithoutOrganizationInput>;
  Availablities?: InputMaybe<AvailabilityUpdateManyWithoutOrganizationInput>;
  AverageOrderValue?: InputMaybe<AverageOrderValueUpdateManyWithoutOrganizationInput>;
  best_action_ever_multiplier?: InputMaybe<FloatFieldUpdateOperationsInput>;
  best_metrics?: InputMaybe<BestMetricRecordUpdateManyWithoutOrganizationInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptUpdateManyWithoutOrganizationInput>;
  callMeNows?: InputMaybe<CallMeNowUpdateManyWithoutOrganizationInput>;
  CallMultiplier?: InputMaybe<CallMultiplierUpdateManyWithoutOrganizationInput>;
  CallSharings?: InputMaybe<CallSharingUpdateManyWithoutOrganizationInput>;
  can_edit_sdr_transfer_rules?: InputMaybe<BoolFieldUpdateOperationsInput>;
  claim_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  concession?: InputMaybe<ConcessionUpdateManyWithoutOrganizationInput>;
  conference_region?: InputMaybe<EnumConferenceRegionFieldUpdateOperationsInput>;
  Conferences?: InputMaybe<ConferenceUpdateManyWithoutOrganizationInput>;
  ContactRequests?: InputMaybe<ContactRequestUpdateManyWithoutOrganizationInput>;
  Contacts?: InputMaybe<ContactUpdateManyWithoutOrganizationInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  custom_fields?: InputMaybe<CustomFieldUpdateManyWithoutOrganizationInput>;
  CustomObject?: InputMaybe<CustomObjectUpdateManyWithoutOrganizationInput>;
  CustomObjectField?: InputMaybe<CustomObjectFieldUpdateManyWithoutOrganizationInput>;
  CustomObjectFieldForeignKey?: InputMaybe<CustomObjectFieldForeignKeyUpdateManyWithoutOrganizationInput>;
  CustomObjectRecord?: InputMaybe<CustomObjectRecordUpdateManyWithoutOrganizationInput>;
  CustomObjectRow?: InputMaybe<CustomObjectRowUpdateManyWithoutOrganizationInput>;
  CustomQueueItems?: InputMaybe<CustomQueueItemUpdateManyWithoutOrganizationInput>;
  DashboardColumnOptions?: InputMaybe<DashboardColumnOptionUpdateManyWithoutOrganizationInput>;
  DashboardLeadFilters?: InputMaybe<DashboardLeadFilterUpdateManyWithoutOrganizationInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewUpdateManyWithoutOrganizationInput>;
  day_to_close?: InputMaybe<IntFieldUpdateOperationsInput>;
  default_demo_time_duration?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  demo_held_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_not_held_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_not_held_call_back_short_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_prep_time_minutes?: InputMaybe<IntFieldUpdateOperationsInput>;
  dev_account?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  dial_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  DispositionMultiplier?: InputMaybe<DispositionMultiplierUpdateManyWithoutOrganizationInput>;
  dispositions?: InputMaybe<DispositionUpdateManyWithoutOrganizationInput>;
  double_booking?: InputMaybe<BoolFieldUpdateOperationsInput>;
  dynamic_date_operators?: InputMaybe<BoolFieldUpdateOperationsInput>;
  edge_servers?: InputMaybe<OrganizationUpdateedge_ServersInput>;
  email_conflict_notification_email_list?: InputMaybe<OrganizationUpdateemail_Conflict_Notification_Email_ListInput>;
  EmailItems?: InputMaybe<EmailItemUpdateManyWithoutOrganizationInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordUpdateManyWithoutOrganizationInput>;
  EmailThreads?: InputMaybe<EmailThreadUpdateManyWithoutOrganizationInput>;
  enable_modified_metrics?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  enable_pay_period_view?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  enable_time_constraint?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  error_log_email_address?: InputMaybe<OrganizationUpdateerror_Log_Email_AddressInput>;
  estimated_average_num_of_weekly_overbooked_demo_slots?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  FolderPermissions?: InputMaybe<FolderPermissionUpdateManyWithoutOrganizationInput>;
  Folders?: InputMaybe<FolderUpdateManyWithoutOrganizationInput>;
  FutureBookingSetting?: InputMaybe<FutureBookingSettingUpdateOneWithoutOrganizationInput>;
  GlobalTransferRule?: InputMaybe<GlobalTransferRuleUpdateManyWithoutOrganizationInput>;
  held_demos_per_cc_connect?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  helpful_links?: InputMaybe<OrganizationUpdatehelpful_LinksInput>;
  HingePointMultiplier?: InputMaybe<HingePointMultiplierUpdateManyWithoutOrganizationInput>;
  hold_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
  hubspot_credential?: InputMaybe<HubSpotCredentialUpdateOneWithoutOrganizationInput>;
  hubspot_last_sync_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  hubspot_white_list?: InputMaybe<HubSpotWhitelistUpdateManyWithoutOrganizationInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inbound_close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_dm_connect_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_lt_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_revenue_value_of_sale?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_scheduled_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_set_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventUpdateManyWithoutOrganizationInput>;
  InboundConciergeWidget?: InputMaybe<InboundConciergeWidgetUpdateManyWithoutOrganizationInput>;
  industry_options?: InputMaybe<IndustryOptionUpdateManyWithoutOrganizationInput>;
  initial_sdr_sets_reps_day?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  integration_error_logs?: InputMaybe<IntegrationErrorLogUpdateManyWithoutOrganizationInput>;
  integration_mappings?: InputMaybe<IntegrationMappingUpdateManyWithoutOrganizationInput>;
  IntegrationMappingCustomObject?: InputMaybe<IntegrationMappingCustomObjectUpdateManyWithoutOrganizationInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogUpdateManyWithoutOrganizationInput>;
  IntegrationReadOnlyObject?: InputMaybe<IntegrationReadOnlyObjectUpdateManyWithoutOrganizationInput>;
  international?: InputMaybe<BoolFieldUpdateOperationsInput>;
  is_email_domain_verified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  is_salesforce_sandbox?: InputMaybe<BoolFieldUpdateOperationsInput>;
  last_lead_value_compute_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutOrganizationInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryUpdateManyWithoutOrganizationInput>;
  lead_intent?: InputMaybe<LeadIntentUpdateManyWithoutOrganizationInput>;
  lead_release_same_rep_delay_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  lead_routing_email_list?: InputMaybe<OrganizationUpdatelead_Routing_Email_ListInput>;
  lead_routing_rules?: InputMaybe<RuleUpdateManyWithoutOrganizationInput>;
  LeadCustomObjectRowAssociation?: InputMaybe<LeadCustomObjectRowAssociationUpdateManyWithoutOrganizationInput>;
  LeadFieldMultiplier?: InputMaybe<LeadFieldMultiplierUpdateManyWithoutOrganizationInput>;
  LeadFieldMultiplierCustom?: InputMaybe<LeadFieldMultiplierCustomUpdateManyWithoutOrganizationInput>;
  leads?: InputMaybe<LeadUpdateManyWithoutOrganizationInput>;
  leadsource_options?: InputMaybe<LeadSourceOptionUpdateManyWithoutOrganizationInput>;
  LeadValue?: InputMaybe<LeadValueUpdateManyWithoutOrganizationInput>;
  LifeTimeValueMultiplier?: InputMaybe<LifeTimeValueMultiplierUpdateManyWithoutOrganizationInput>;
  LiveTransferSetting?: InputMaybe<LiveTransferSettingUpdateOneWithoutOrganizationInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusUpdateManyWithoutOrganizationInput>;
  ltv_multiplier_custom_fields?: InputMaybe<LifeTimeValueMultiplierCustomUpdateManyWithoutOrganizationInput>;
  made_contact_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  make_sale_address_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_business_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_city_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_country_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_first_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_industry_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_last_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_lead_source_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_primary_email_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_primary_phone_number_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_state_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_sub_industry_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_zip_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  maxium_day_lag_set_to_schedule?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  mrr?: InputMaybe<IntFieldUpdateOperationsInput>;
  mrr_label?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  mrr_required_on_sale?: InputMaybe<BoolFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  NoteItems?: InputMaybe<NoteItemUpdateManyWithoutOrganizationInput>;
  Notifications?: InputMaybe<NotificationUpdateManyWithoutOrganizationInput>;
  onboardComplete?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  Opportunity?: InputMaybe<SfOpportunityUpdateManyWithoutOrganizationInput>;
  organization_holidays?: InputMaybe<OrganizationHolidayUpdateManyWithoutOrganizationInput>;
  organization_hours?: InputMaybe<OrganizationHourUpdateManyWithoutOrganizationInput>;
  OrganizationPaymentSetting?: InputMaybe<OrganizationPaymentSettingsUpdateOneWithoutOrganizationInput>;
  OrganizationPaymentSettings?: InputMaybe<OrganizationPaymentSettingsUpdateOneWithoutOrganizationsInput>;
  orgCalendarInviteMetadata?: InputMaybe<OrgCalendarInviteMetadataUpdateManyWithoutOrganizationInput>;
  OrgDefaultPipelinePerecentValue?: InputMaybe<OrgDefaultPipelinePerecentValueUpdateOneWithoutOrganizationInput>;
  OrgHubSpotSync?: InputMaybe<OrgHubSpotSyncUpdateOneWithoutOrganizationInput>;
  OrgNoEventDate?: InputMaybe<OrgNoEventDateUpdateManyWithoutOrganizationInput>;
  OrgSalesforceSync?: InputMaybe<OrgSalesforceSyncUpdateOneWithoutOrganizationInput>;
  OrgTimeslotAvailability?: InputMaybe<OrgTimeslotAvailabilityUpdateManyWithoutOrganizationInput>;
  outbound_close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_dm_connect_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_lt_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_revenue_value_of_sale?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_scheduled_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_set_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  PandadocData?: InputMaybe<PandadocDataUpdateOneWithoutOrganizationInput>;
  PandaDocTemplate?: InputMaybe<PandaDocTemplateUpdateManyWithoutOrganizationInput>;
  participant_jitter_buffer_size?: InputMaybe<EnumJitterBufferSizeFieldUpdateOperationsInput>;
  preferred_goal_setting?: InputMaybe<NullableEnumGoalsettingFieldUpdateOperationsInput>;
  prepayment?: InputMaybe<BoolFieldUpdateOperationsInput>;
  ProductChangeLog?: InputMaybe<ProductChangeLogUpdateManyWithoutOrganizationInput>;
  products?: InputMaybe<ProductUpdateManyWithoutOrganizationInput>;
  re_cold_call_hour_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  recordingStatus?: InputMaybe<EnumRecordingStatusFieldUpdateOperationsInput>;
  release_lead_resting?: InputMaybe<IntFieldUpdateOperationsInput>;
  rep_claim_limit?: InputMaybe<IntFieldUpdateOperationsInput>;
  rep_lead_allotment?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  rep_level_call_transfer?: InputMaybe<BoolFieldUpdateOperationsInput>;
  reps_can_bulk_unassign?: InputMaybe<BoolFieldUpdateOperationsInput>;
  reps_can_view_all_notes?: InputMaybe<BoolFieldUpdateOperationsInput>;
  require_sale_notes?: InputMaybe<BoolFieldUpdateOperationsInput>;
  resting_after_skipped_CC?: InputMaybe<IntFieldUpdateOperationsInput>;
  resting_period?: InputMaybe<IntFieldUpdateOperationsInput>;
  resting_period_after_unsuccessful_cold_call?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  resting_period_after_unsuccessful_demo?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  revenue_plan?: InputMaybe<RevenuePlanItemUpdateManyWithoutOrganizationInput>;
  Routings?: InputMaybe<RoutingUpdateManyWithoutOrganizationInput>;
  sale_field_options?: InputMaybe<SaleFieldOptionUpdateManyWithoutOrganizationInput>;
  sale_notification_email_list?: InputMaybe<OrganizationUpdatesale_Notification_Email_ListInput>;
  SaleConfig?: InputMaybe<SaleConfigUpdateOneWithoutOrganizationInput>;
  SaleConfigPage?: InputMaybe<SaleConfigPageUpdateManyWithoutOrganizationInput>;
  SaleConfigSection?: InputMaybe<SaleConfigSectionUpdateManyWithoutOrganizationInput>;
  SaleCycles?: InputMaybe<SaleCycleUpdateManyWithoutOrganizationInput>;
  SaleItems?: InputMaybe<SaleItemUpdateManyWithoutOrganizationInput>;
  sales?: InputMaybe<SaleUpdateManyWithoutOrganizationInput>;
  salesforce_domain?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  salesforce_last_sync_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  SalesForceColumn?: InputMaybe<SalesForceColumnUpdateManyWithoutOrganizationInput>;
  salesForceData?: InputMaybe<SalesForceDataUpdateOneWithoutOrganizationInput>;
  ScheduleItems?: InputMaybe<ScheduleItemUpdateManyWithoutOrganizationInput>;
  self_sourcing_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  send_error_log_email?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  send_lead_routing_email?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sendgrid_email_domain?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sendgrid_email_domain_id?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  sequence?: InputMaybe<SequenceUpdateManyWithoutOrganzationInput>;
  SequenceCriteriaConditions?: InputMaybe<SequenceCriteriaConditionUpdateManyWithoutOrganizationInput>;
  SequenceDashboardViews?: InputMaybe<SequenceDashboardViewUpdateManyWithoutOrganizationInput>;
  SequenceEntryCriterias?: InputMaybe<SequenceEntryCriteriaUpdateManyWithoutOrganizationInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutOrganizationInput>;
  SequenceSteps?: InputMaybe<SequenceStepUpdateManyWithoutOrganizationInput>;
  set_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
  short_resting?: InputMaybe<IntFieldUpdateOperationsInput>;
  show_calendar_options?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_contract_duration_sales?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_lead_source_to_rep?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_nocontact_button?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_payment_terms_sales?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_rep_all_leads?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_rep_my_leads?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_system_view?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sites?: InputMaybe<SiteUpdateManyWithoutOrganizationInput>;
  sms_opt_in_dialog?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SMSItems?: InputMaybe<SmsItemUpdateManyWithoutOrganizationInput>;
  SnoozeTrackings?: InputMaybe<SnoozeTrackingUpdateManyWithoutOrganizationInput>;
  StepActionLogs?: InputMaybe<StepActionLogUpdateManyWithoutOrganizationInput>;
  StepActions?: InputMaybe<StepActionUpdateManyWithoutOrganizationInput>;
  StepConditions?: InputMaybe<StepConditionUpdateManyWithoutOrganizationInput>;
  teams?: InputMaybe<TeamUpdateManyWithoutOrganizationInput>;
  Template?: InputMaybe<TemplateUpdateManyWithoutOrganizationInput>;
  TemplateVersions?: InputMaybe<TemplateVersionUpdateManyWithoutOrganizationInput>;
  timekit_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timezone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transfer_timeout?: InputMaybe<IntFieldUpdateOperationsInput>;
  TransferAttempts?: InputMaybe<TransferAttemptUpdateManyWithoutOrganizationInput>;
  twilio_numbers?: InputMaybe<TwilioNumberUpdateManyWithoutOrganizationInput>;
  TwilioFeedbacks?: InputMaybe<TwilioFeedbackUpdateManyWithoutOrganizationInput>;
  TwilioInfo?: InputMaybe<TwilioInfoUpdateOneWithoutOrganzationInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  use_revenue_planning?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  UserCustomFieldFilters?: InputMaybe<UserCustomFieldFilterUpdateManyWithoutOrganizationInput>;
  users?: InputMaybe<UserUpdateManyWithoutOrganizationInput>;
  win_rep_day_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
};

export type OrganizationUpdateWithoutDashboardSavedViewInput = {
  ActivityMonitorMultiplier?: InputMaybe<ActivityMonitorMultiplierUpdateManyWithoutOrganizationInput>;
  algoFactor?: InputMaybe<AlgoFactorUpdateManyWithoutOrganizationInput>;
  allow_calendar_invite?: InputMaybe<BoolFieldUpdateOperationsInput>;
  allow_remove_step_action?: InputMaybe<BoolFieldUpdateOperationsInput>;
  allow_snooze_step_action?: InputMaybe<BoolFieldUpdateOperationsInput>;
  always_record?: InputMaybe<BoolFieldUpdateOperationsInput>;
  arv_initial_held_demos_generated_by_sdr_weight?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  AssociationHistories?: InputMaybe<AssociationHistoryUpdateManyWithoutOrganizationInput>;
  auto_call_interval?: InputMaybe<IntFieldUpdateOperationsInput>;
  auto_dial?: InputMaybe<BoolFieldUpdateOperationsInput>;
  automated_campaigns?: InputMaybe<AutomatedCampaignUpdateManyWithoutOrganizationInput>;
  automatic_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  AutoTransferSelections?: InputMaybe<AutoTransferSelectionUpdateManyWithoutOrganizationInput>;
  Availablities?: InputMaybe<AvailabilityUpdateManyWithoutOrganizationInput>;
  AverageOrderValue?: InputMaybe<AverageOrderValueUpdateManyWithoutOrganizationInput>;
  best_action_ever_multiplier?: InputMaybe<FloatFieldUpdateOperationsInput>;
  best_metrics?: InputMaybe<BestMetricRecordUpdateManyWithoutOrganizationInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptUpdateManyWithoutOrganizationInput>;
  callMeNows?: InputMaybe<CallMeNowUpdateManyWithoutOrganizationInput>;
  CallMultiplier?: InputMaybe<CallMultiplierUpdateManyWithoutOrganizationInput>;
  CallSharings?: InputMaybe<CallSharingUpdateManyWithoutOrganizationInput>;
  can_edit_sdr_transfer_rules?: InputMaybe<BoolFieldUpdateOperationsInput>;
  claim_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  concession?: InputMaybe<ConcessionUpdateManyWithoutOrganizationInput>;
  conference_region?: InputMaybe<EnumConferenceRegionFieldUpdateOperationsInput>;
  Conferences?: InputMaybe<ConferenceUpdateManyWithoutOrganizationInput>;
  ContactRequests?: InputMaybe<ContactRequestUpdateManyWithoutOrganizationInput>;
  Contacts?: InputMaybe<ContactUpdateManyWithoutOrganizationInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  custom_fields?: InputMaybe<CustomFieldUpdateManyWithoutOrganizationInput>;
  CustomObject?: InputMaybe<CustomObjectUpdateManyWithoutOrganizationInput>;
  CustomObjectField?: InputMaybe<CustomObjectFieldUpdateManyWithoutOrganizationInput>;
  CustomObjectFieldForeignKey?: InputMaybe<CustomObjectFieldForeignKeyUpdateManyWithoutOrganizationInput>;
  CustomObjectRecord?: InputMaybe<CustomObjectRecordUpdateManyWithoutOrganizationInput>;
  CustomObjectRow?: InputMaybe<CustomObjectRowUpdateManyWithoutOrganizationInput>;
  CustomQueueItems?: InputMaybe<CustomQueueItemUpdateManyWithoutOrganizationInput>;
  DashboardColumnOptions?: InputMaybe<DashboardColumnOptionUpdateManyWithoutOrganizationInput>;
  DashboardLeadFilters?: InputMaybe<DashboardLeadFilterUpdateManyWithoutOrganizationInput>;
  DashboardRepFilters?: InputMaybe<DashboardRepFilterUpdateManyWithoutOrganizationInput>;
  day_to_close?: InputMaybe<IntFieldUpdateOperationsInput>;
  default_demo_time_duration?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  demo_held_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_not_held_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_not_held_call_back_short_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_prep_time_minutes?: InputMaybe<IntFieldUpdateOperationsInput>;
  dev_account?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  dial_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  DispositionMultiplier?: InputMaybe<DispositionMultiplierUpdateManyWithoutOrganizationInput>;
  dispositions?: InputMaybe<DispositionUpdateManyWithoutOrganizationInput>;
  double_booking?: InputMaybe<BoolFieldUpdateOperationsInput>;
  dynamic_date_operators?: InputMaybe<BoolFieldUpdateOperationsInput>;
  edge_servers?: InputMaybe<OrganizationUpdateedge_ServersInput>;
  email_conflict_notification_email_list?: InputMaybe<OrganizationUpdateemail_Conflict_Notification_Email_ListInput>;
  EmailItems?: InputMaybe<EmailItemUpdateManyWithoutOrganizationInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordUpdateManyWithoutOrganizationInput>;
  EmailThreads?: InputMaybe<EmailThreadUpdateManyWithoutOrganizationInput>;
  enable_modified_metrics?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  enable_pay_period_view?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  enable_time_constraint?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  error_log_email_address?: InputMaybe<OrganizationUpdateerror_Log_Email_AddressInput>;
  estimated_average_num_of_weekly_overbooked_demo_slots?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  FolderPermissions?: InputMaybe<FolderPermissionUpdateManyWithoutOrganizationInput>;
  Folders?: InputMaybe<FolderUpdateManyWithoutOrganizationInput>;
  FutureBookingSetting?: InputMaybe<FutureBookingSettingUpdateOneWithoutOrganizationInput>;
  GlobalTransferRule?: InputMaybe<GlobalTransferRuleUpdateManyWithoutOrganizationInput>;
  held_demos_per_cc_connect?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  helpful_links?: InputMaybe<OrganizationUpdatehelpful_LinksInput>;
  HingePointMultiplier?: InputMaybe<HingePointMultiplierUpdateManyWithoutOrganizationInput>;
  hold_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
  hubspot_credential?: InputMaybe<HubSpotCredentialUpdateOneWithoutOrganizationInput>;
  hubspot_last_sync_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  hubspot_white_list?: InputMaybe<HubSpotWhitelistUpdateManyWithoutOrganizationInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inbound_close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_dm_connect_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_lt_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_revenue_value_of_sale?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_scheduled_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_set_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventUpdateManyWithoutOrganizationInput>;
  InboundConciergeWidget?: InputMaybe<InboundConciergeWidgetUpdateManyWithoutOrganizationInput>;
  industry_options?: InputMaybe<IndustryOptionUpdateManyWithoutOrganizationInput>;
  initial_sdr_sets_reps_day?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  integration_error_logs?: InputMaybe<IntegrationErrorLogUpdateManyWithoutOrganizationInput>;
  integration_mappings?: InputMaybe<IntegrationMappingUpdateManyWithoutOrganizationInput>;
  IntegrationMappingCustomObject?: InputMaybe<IntegrationMappingCustomObjectUpdateManyWithoutOrganizationInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogUpdateManyWithoutOrganizationInput>;
  IntegrationReadOnlyObject?: InputMaybe<IntegrationReadOnlyObjectUpdateManyWithoutOrganizationInput>;
  international?: InputMaybe<BoolFieldUpdateOperationsInput>;
  is_email_domain_verified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  is_salesforce_sandbox?: InputMaybe<BoolFieldUpdateOperationsInput>;
  last_lead_value_compute_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutOrganizationInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryUpdateManyWithoutOrganizationInput>;
  lead_intent?: InputMaybe<LeadIntentUpdateManyWithoutOrganizationInput>;
  lead_release_same_rep_delay_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  lead_routing_email_list?: InputMaybe<OrganizationUpdatelead_Routing_Email_ListInput>;
  lead_routing_rules?: InputMaybe<RuleUpdateManyWithoutOrganizationInput>;
  LeadCustomObjectRowAssociation?: InputMaybe<LeadCustomObjectRowAssociationUpdateManyWithoutOrganizationInput>;
  LeadFieldMultiplier?: InputMaybe<LeadFieldMultiplierUpdateManyWithoutOrganizationInput>;
  LeadFieldMultiplierCustom?: InputMaybe<LeadFieldMultiplierCustomUpdateManyWithoutOrganizationInput>;
  leads?: InputMaybe<LeadUpdateManyWithoutOrganizationInput>;
  leadsource_options?: InputMaybe<LeadSourceOptionUpdateManyWithoutOrganizationInput>;
  LeadValue?: InputMaybe<LeadValueUpdateManyWithoutOrganizationInput>;
  LifeTimeValueMultiplier?: InputMaybe<LifeTimeValueMultiplierUpdateManyWithoutOrganizationInput>;
  LiveTransferSetting?: InputMaybe<LiveTransferSettingUpdateOneWithoutOrganizationInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusUpdateManyWithoutOrganizationInput>;
  ltv_multiplier_custom_fields?: InputMaybe<LifeTimeValueMultiplierCustomUpdateManyWithoutOrganizationInput>;
  made_contact_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  make_sale_address_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_business_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_city_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_country_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_first_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_industry_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_last_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_lead_source_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_primary_email_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_primary_phone_number_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_state_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_sub_industry_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_zip_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  maxium_day_lag_set_to_schedule?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  mrr?: InputMaybe<IntFieldUpdateOperationsInput>;
  mrr_label?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  mrr_required_on_sale?: InputMaybe<BoolFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  NoteItems?: InputMaybe<NoteItemUpdateManyWithoutOrganizationInput>;
  Notifications?: InputMaybe<NotificationUpdateManyWithoutOrganizationInput>;
  onboardComplete?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  Opportunity?: InputMaybe<SfOpportunityUpdateManyWithoutOrganizationInput>;
  organization_holidays?: InputMaybe<OrganizationHolidayUpdateManyWithoutOrganizationInput>;
  organization_hours?: InputMaybe<OrganizationHourUpdateManyWithoutOrganizationInput>;
  OrganizationPaymentSetting?: InputMaybe<OrganizationPaymentSettingsUpdateOneWithoutOrganizationInput>;
  OrganizationPaymentSettings?: InputMaybe<OrganizationPaymentSettingsUpdateOneWithoutOrganizationsInput>;
  orgCalendarInviteMetadata?: InputMaybe<OrgCalendarInviteMetadataUpdateManyWithoutOrganizationInput>;
  OrgDefaultPipelinePerecentValue?: InputMaybe<OrgDefaultPipelinePerecentValueUpdateOneWithoutOrganizationInput>;
  OrgHubSpotSync?: InputMaybe<OrgHubSpotSyncUpdateOneWithoutOrganizationInput>;
  OrgNoEventDate?: InputMaybe<OrgNoEventDateUpdateManyWithoutOrganizationInput>;
  OrgSalesforceSync?: InputMaybe<OrgSalesforceSyncUpdateOneWithoutOrganizationInput>;
  OrgTimeslotAvailability?: InputMaybe<OrgTimeslotAvailabilityUpdateManyWithoutOrganizationInput>;
  outbound_close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_dm_connect_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_lt_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_revenue_value_of_sale?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_scheduled_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_set_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  PandadocData?: InputMaybe<PandadocDataUpdateOneWithoutOrganizationInput>;
  PandaDocTemplate?: InputMaybe<PandaDocTemplateUpdateManyWithoutOrganizationInput>;
  participant_jitter_buffer_size?: InputMaybe<EnumJitterBufferSizeFieldUpdateOperationsInput>;
  preferred_goal_setting?: InputMaybe<NullableEnumGoalsettingFieldUpdateOperationsInput>;
  prepayment?: InputMaybe<BoolFieldUpdateOperationsInput>;
  ProductChangeLog?: InputMaybe<ProductChangeLogUpdateManyWithoutOrganizationInput>;
  products?: InputMaybe<ProductUpdateManyWithoutOrganizationInput>;
  re_cold_call_hour_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  recordingStatus?: InputMaybe<EnumRecordingStatusFieldUpdateOperationsInput>;
  release_lead_resting?: InputMaybe<IntFieldUpdateOperationsInput>;
  rep_claim_limit?: InputMaybe<IntFieldUpdateOperationsInput>;
  rep_lead_allotment?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  rep_level_call_transfer?: InputMaybe<BoolFieldUpdateOperationsInput>;
  reps_can_bulk_unassign?: InputMaybe<BoolFieldUpdateOperationsInput>;
  reps_can_view_all_notes?: InputMaybe<BoolFieldUpdateOperationsInput>;
  require_sale_notes?: InputMaybe<BoolFieldUpdateOperationsInput>;
  resting_after_skipped_CC?: InputMaybe<IntFieldUpdateOperationsInput>;
  resting_period?: InputMaybe<IntFieldUpdateOperationsInput>;
  resting_period_after_unsuccessful_cold_call?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  resting_period_after_unsuccessful_demo?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  revenue_plan?: InputMaybe<RevenuePlanItemUpdateManyWithoutOrganizationInput>;
  Routings?: InputMaybe<RoutingUpdateManyWithoutOrganizationInput>;
  sale_field_options?: InputMaybe<SaleFieldOptionUpdateManyWithoutOrganizationInput>;
  sale_notification_email_list?: InputMaybe<OrganizationUpdatesale_Notification_Email_ListInput>;
  SaleConfig?: InputMaybe<SaleConfigUpdateOneWithoutOrganizationInput>;
  SaleConfigPage?: InputMaybe<SaleConfigPageUpdateManyWithoutOrganizationInput>;
  SaleConfigSection?: InputMaybe<SaleConfigSectionUpdateManyWithoutOrganizationInput>;
  SaleCycles?: InputMaybe<SaleCycleUpdateManyWithoutOrganizationInput>;
  SaleItems?: InputMaybe<SaleItemUpdateManyWithoutOrganizationInput>;
  sales?: InputMaybe<SaleUpdateManyWithoutOrganizationInput>;
  salesforce_domain?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  salesforce_last_sync_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  SalesForceColumn?: InputMaybe<SalesForceColumnUpdateManyWithoutOrganizationInput>;
  salesForceData?: InputMaybe<SalesForceDataUpdateOneWithoutOrganizationInput>;
  ScheduleItems?: InputMaybe<ScheduleItemUpdateManyWithoutOrganizationInput>;
  self_sourcing_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  send_error_log_email?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  send_lead_routing_email?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sendgrid_email_domain?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sendgrid_email_domain_id?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  sequence?: InputMaybe<SequenceUpdateManyWithoutOrganzationInput>;
  SequenceCriteriaConditions?: InputMaybe<SequenceCriteriaConditionUpdateManyWithoutOrganizationInput>;
  SequenceDashboardViews?: InputMaybe<SequenceDashboardViewUpdateManyWithoutOrganizationInput>;
  SequenceEntryCriterias?: InputMaybe<SequenceEntryCriteriaUpdateManyWithoutOrganizationInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutOrganizationInput>;
  SequenceSteps?: InputMaybe<SequenceStepUpdateManyWithoutOrganizationInput>;
  set_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
  short_resting?: InputMaybe<IntFieldUpdateOperationsInput>;
  show_calendar_options?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_contract_duration_sales?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_lead_source_to_rep?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_nocontact_button?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_payment_terms_sales?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_rep_all_leads?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_rep_my_leads?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_system_view?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sites?: InputMaybe<SiteUpdateManyWithoutOrganizationInput>;
  sms_opt_in_dialog?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SMSItems?: InputMaybe<SmsItemUpdateManyWithoutOrganizationInput>;
  SnoozeTrackings?: InputMaybe<SnoozeTrackingUpdateManyWithoutOrganizationInput>;
  StepActionLogs?: InputMaybe<StepActionLogUpdateManyWithoutOrganizationInput>;
  StepActions?: InputMaybe<StepActionUpdateManyWithoutOrganizationInput>;
  StepConditions?: InputMaybe<StepConditionUpdateManyWithoutOrganizationInput>;
  teams?: InputMaybe<TeamUpdateManyWithoutOrganizationInput>;
  Template?: InputMaybe<TemplateUpdateManyWithoutOrganizationInput>;
  TemplateVersions?: InputMaybe<TemplateVersionUpdateManyWithoutOrganizationInput>;
  timekit_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timezone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transfer_timeout?: InputMaybe<IntFieldUpdateOperationsInput>;
  TransferAttempts?: InputMaybe<TransferAttemptUpdateManyWithoutOrganizationInput>;
  twilio_numbers?: InputMaybe<TwilioNumberUpdateManyWithoutOrganizationInput>;
  TwilioFeedbacks?: InputMaybe<TwilioFeedbackUpdateManyWithoutOrganizationInput>;
  TwilioInfo?: InputMaybe<TwilioInfoUpdateOneWithoutOrganzationInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  use_revenue_planning?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  UserCustomFieldFilters?: InputMaybe<UserCustomFieldFilterUpdateManyWithoutOrganizationInput>;
  users?: InputMaybe<UserUpdateManyWithoutOrganizationInput>;
  win_rep_day_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
};

export type OrganizationUpdateWithoutDispositionMultiplierInput = {
  ActivityMonitorMultiplier?: InputMaybe<ActivityMonitorMultiplierUpdateManyWithoutOrganizationInput>;
  algoFactor?: InputMaybe<AlgoFactorUpdateManyWithoutOrganizationInput>;
  allow_calendar_invite?: InputMaybe<BoolFieldUpdateOperationsInput>;
  allow_remove_step_action?: InputMaybe<BoolFieldUpdateOperationsInput>;
  allow_snooze_step_action?: InputMaybe<BoolFieldUpdateOperationsInput>;
  always_record?: InputMaybe<BoolFieldUpdateOperationsInput>;
  arv_initial_held_demos_generated_by_sdr_weight?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  AssociationHistories?: InputMaybe<AssociationHistoryUpdateManyWithoutOrganizationInput>;
  auto_call_interval?: InputMaybe<IntFieldUpdateOperationsInput>;
  auto_dial?: InputMaybe<BoolFieldUpdateOperationsInput>;
  automated_campaigns?: InputMaybe<AutomatedCampaignUpdateManyWithoutOrganizationInput>;
  automatic_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  AutoTransferSelections?: InputMaybe<AutoTransferSelectionUpdateManyWithoutOrganizationInput>;
  Availablities?: InputMaybe<AvailabilityUpdateManyWithoutOrganizationInput>;
  AverageOrderValue?: InputMaybe<AverageOrderValueUpdateManyWithoutOrganizationInput>;
  best_action_ever_multiplier?: InputMaybe<FloatFieldUpdateOperationsInput>;
  best_metrics?: InputMaybe<BestMetricRecordUpdateManyWithoutOrganizationInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptUpdateManyWithoutOrganizationInput>;
  callMeNows?: InputMaybe<CallMeNowUpdateManyWithoutOrganizationInput>;
  CallMultiplier?: InputMaybe<CallMultiplierUpdateManyWithoutOrganizationInput>;
  CallSharings?: InputMaybe<CallSharingUpdateManyWithoutOrganizationInput>;
  can_edit_sdr_transfer_rules?: InputMaybe<BoolFieldUpdateOperationsInput>;
  claim_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  concession?: InputMaybe<ConcessionUpdateManyWithoutOrganizationInput>;
  conference_region?: InputMaybe<EnumConferenceRegionFieldUpdateOperationsInput>;
  Conferences?: InputMaybe<ConferenceUpdateManyWithoutOrganizationInput>;
  ContactRequests?: InputMaybe<ContactRequestUpdateManyWithoutOrganizationInput>;
  Contacts?: InputMaybe<ContactUpdateManyWithoutOrganizationInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  custom_fields?: InputMaybe<CustomFieldUpdateManyWithoutOrganizationInput>;
  CustomObject?: InputMaybe<CustomObjectUpdateManyWithoutOrganizationInput>;
  CustomObjectField?: InputMaybe<CustomObjectFieldUpdateManyWithoutOrganizationInput>;
  CustomObjectFieldForeignKey?: InputMaybe<CustomObjectFieldForeignKeyUpdateManyWithoutOrganizationInput>;
  CustomObjectRecord?: InputMaybe<CustomObjectRecordUpdateManyWithoutOrganizationInput>;
  CustomObjectRow?: InputMaybe<CustomObjectRowUpdateManyWithoutOrganizationInput>;
  CustomQueueItems?: InputMaybe<CustomQueueItemUpdateManyWithoutOrganizationInput>;
  DashboardColumnOptions?: InputMaybe<DashboardColumnOptionUpdateManyWithoutOrganizationInput>;
  DashboardLeadFilters?: InputMaybe<DashboardLeadFilterUpdateManyWithoutOrganizationInput>;
  DashboardRepFilters?: InputMaybe<DashboardRepFilterUpdateManyWithoutOrganizationInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewUpdateManyWithoutOrganizationInput>;
  day_to_close?: InputMaybe<IntFieldUpdateOperationsInput>;
  default_demo_time_duration?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  demo_held_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_not_held_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_not_held_call_back_short_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_prep_time_minutes?: InputMaybe<IntFieldUpdateOperationsInput>;
  dev_account?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  dial_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  dispositions?: InputMaybe<DispositionUpdateManyWithoutOrganizationInput>;
  double_booking?: InputMaybe<BoolFieldUpdateOperationsInput>;
  dynamic_date_operators?: InputMaybe<BoolFieldUpdateOperationsInput>;
  edge_servers?: InputMaybe<OrganizationUpdateedge_ServersInput>;
  email_conflict_notification_email_list?: InputMaybe<OrganizationUpdateemail_Conflict_Notification_Email_ListInput>;
  EmailItems?: InputMaybe<EmailItemUpdateManyWithoutOrganizationInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordUpdateManyWithoutOrganizationInput>;
  EmailThreads?: InputMaybe<EmailThreadUpdateManyWithoutOrganizationInput>;
  enable_modified_metrics?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  enable_pay_period_view?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  enable_time_constraint?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  error_log_email_address?: InputMaybe<OrganizationUpdateerror_Log_Email_AddressInput>;
  estimated_average_num_of_weekly_overbooked_demo_slots?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  FolderPermissions?: InputMaybe<FolderPermissionUpdateManyWithoutOrganizationInput>;
  Folders?: InputMaybe<FolderUpdateManyWithoutOrganizationInput>;
  FutureBookingSetting?: InputMaybe<FutureBookingSettingUpdateOneWithoutOrganizationInput>;
  GlobalTransferRule?: InputMaybe<GlobalTransferRuleUpdateManyWithoutOrganizationInput>;
  held_demos_per_cc_connect?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  helpful_links?: InputMaybe<OrganizationUpdatehelpful_LinksInput>;
  HingePointMultiplier?: InputMaybe<HingePointMultiplierUpdateManyWithoutOrganizationInput>;
  hold_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
  hubspot_credential?: InputMaybe<HubSpotCredentialUpdateOneWithoutOrganizationInput>;
  hubspot_last_sync_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  hubspot_white_list?: InputMaybe<HubSpotWhitelistUpdateManyWithoutOrganizationInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inbound_close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_dm_connect_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_lt_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_revenue_value_of_sale?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_scheduled_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_set_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventUpdateManyWithoutOrganizationInput>;
  InboundConciergeWidget?: InputMaybe<InboundConciergeWidgetUpdateManyWithoutOrganizationInput>;
  industry_options?: InputMaybe<IndustryOptionUpdateManyWithoutOrganizationInput>;
  initial_sdr_sets_reps_day?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  integration_error_logs?: InputMaybe<IntegrationErrorLogUpdateManyWithoutOrganizationInput>;
  integration_mappings?: InputMaybe<IntegrationMappingUpdateManyWithoutOrganizationInput>;
  IntegrationMappingCustomObject?: InputMaybe<IntegrationMappingCustomObjectUpdateManyWithoutOrganizationInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogUpdateManyWithoutOrganizationInput>;
  IntegrationReadOnlyObject?: InputMaybe<IntegrationReadOnlyObjectUpdateManyWithoutOrganizationInput>;
  international?: InputMaybe<BoolFieldUpdateOperationsInput>;
  is_email_domain_verified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  is_salesforce_sandbox?: InputMaybe<BoolFieldUpdateOperationsInput>;
  last_lead_value_compute_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutOrganizationInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryUpdateManyWithoutOrganizationInput>;
  lead_intent?: InputMaybe<LeadIntentUpdateManyWithoutOrganizationInput>;
  lead_release_same_rep_delay_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  lead_routing_email_list?: InputMaybe<OrganizationUpdatelead_Routing_Email_ListInput>;
  lead_routing_rules?: InputMaybe<RuleUpdateManyWithoutOrganizationInput>;
  LeadCustomObjectRowAssociation?: InputMaybe<LeadCustomObjectRowAssociationUpdateManyWithoutOrganizationInput>;
  LeadFieldMultiplier?: InputMaybe<LeadFieldMultiplierUpdateManyWithoutOrganizationInput>;
  LeadFieldMultiplierCustom?: InputMaybe<LeadFieldMultiplierCustomUpdateManyWithoutOrganizationInput>;
  leads?: InputMaybe<LeadUpdateManyWithoutOrganizationInput>;
  leadsource_options?: InputMaybe<LeadSourceOptionUpdateManyWithoutOrganizationInput>;
  LeadValue?: InputMaybe<LeadValueUpdateManyWithoutOrganizationInput>;
  LifeTimeValueMultiplier?: InputMaybe<LifeTimeValueMultiplierUpdateManyWithoutOrganizationInput>;
  LiveTransferSetting?: InputMaybe<LiveTransferSettingUpdateOneWithoutOrganizationInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusUpdateManyWithoutOrganizationInput>;
  ltv_multiplier_custom_fields?: InputMaybe<LifeTimeValueMultiplierCustomUpdateManyWithoutOrganizationInput>;
  made_contact_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  make_sale_address_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_business_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_city_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_country_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_first_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_industry_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_last_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_lead_source_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_primary_email_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_primary_phone_number_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_state_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_sub_industry_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_zip_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  maxium_day_lag_set_to_schedule?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  mrr?: InputMaybe<IntFieldUpdateOperationsInput>;
  mrr_label?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  mrr_required_on_sale?: InputMaybe<BoolFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  NoteItems?: InputMaybe<NoteItemUpdateManyWithoutOrganizationInput>;
  Notifications?: InputMaybe<NotificationUpdateManyWithoutOrganizationInput>;
  onboardComplete?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  Opportunity?: InputMaybe<SfOpportunityUpdateManyWithoutOrganizationInput>;
  organization_holidays?: InputMaybe<OrganizationHolidayUpdateManyWithoutOrganizationInput>;
  organization_hours?: InputMaybe<OrganizationHourUpdateManyWithoutOrganizationInput>;
  OrganizationPaymentSetting?: InputMaybe<OrganizationPaymentSettingsUpdateOneWithoutOrganizationInput>;
  OrganizationPaymentSettings?: InputMaybe<OrganizationPaymentSettingsUpdateOneWithoutOrganizationsInput>;
  orgCalendarInviteMetadata?: InputMaybe<OrgCalendarInviteMetadataUpdateManyWithoutOrganizationInput>;
  OrgDefaultPipelinePerecentValue?: InputMaybe<OrgDefaultPipelinePerecentValueUpdateOneWithoutOrganizationInput>;
  OrgHubSpotSync?: InputMaybe<OrgHubSpotSyncUpdateOneWithoutOrganizationInput>;
  OrgNoEventDate?: InputMaybe<OrgNoEventDateUpdateManyWithoutOrganizationInput>;
  OrgSalesforceSync?: InputMaybe<OrgSalesforceSyncUpdateOneWithoutOrganizationInput>;
  OrgTimeslotAvailability?: InputMaybe<OrgTimeslotAvailabilityUpdateManyWithoutOrganizationInput>;
  outbound_close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_dm_connect_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_lt_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_revenue_value_of_sale?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_scheduled_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_set_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  PandadocData?: InputMaybe<PandadocDataUpdateOneWithoutOrganizationInput>;
  PandaDocTemplate?: InputMaybe<PandaDocTemplateUpdateManyWithoutOrganizationInput>;
  participant_jitter_buffer_size?: InputMaybe<EnumJitterBufferSizeFieldUpdateOperationsInput>;
  preferred_goal_setting?: InputMaybe<NullableEnumGoalsettingFieldUpdateOperationsInput>;
  prepayment?: InputMaybe<BoolFieldUpdateOperationsInput>;
  ProductChangeLog?: InputMaybe<ProductChangeLogUpdateManyWithoutOrganizationInput>;
  products?: InputMaybe<ProductUpdateManyWithoutOrganizationInput>;
  re_cold_call_hour_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  recordingStatus?: InputMaybe<EnumRecordingStatusFieldUpdateOperationsInput>;
  release_lead_resting?: InputMaybe<IntFieldUpdateOperationsInput>;
  rep_claim_limit?: InputMaybe<IntFieldUpdateOperationsInput>;
  rep_lead_allotment?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  rep_level_call_transfer?: InputMaybe<BoolFieldUpdateOperationsInput>;
  reps_can_bulk_unassign?: InputMaybe<BoolFieldUpdateOperationsInput>;
  reps_can_view_all_notes?: InputMaybe<BoolFieldUpdateOperationsInput>;
  require_sale_notes?: InputMaybe<BoolFieldUpdateOperationsInput>;
  resting_after_skipped_CC?: InputMaybe<IntFieldUpdateOperationsInput>;
  resting_period?: InputMaybe<IntFieldUpdateOperationsInput>;
  resting_period_after_unsuccessful_cold_call?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  resting_period_after_unsuccessful_demo?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  revenue_plan?: InputMaybe<RevenuePlanItemUpdateManyWithoutOrganizationInput>;
  Routings?: InputMaybe<RoutingUpdateManyWithoutOrganizationInput>;
  sale_field_options?: InputMaybe<SaleFieldOptionUpdateManyWithoutOrganizationInput>;
  sale_notification_email_list?: InputMaybe<OrganizationUpdatesale_Notification_Email_ListInput>;
  SaleConfig?: InputMaybe<SaleConfigUpdateOneWithoutOrganizationInput>;
  SaleConfigPage?: InputMaybe<SaleConfigPageUpdateManyWithoutOrganizationInput>;
  SaleConfigSection?: InputMaybe<SaleConfigSectionUpdateManyWithoutOrganizationInput>;
  SaleCycles?: InputMaybe<SaleCycleUpdateManyWithoutOrganizationInput>;
  SaleItems?: InputMaybe<SaleItemUpdateManyWithoutOrganizationInput>;
  sales?: InputMaybe<SaleUpdateManyWithoutOrganizationInput>;
  salesforce_domain?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  salesforce_last_sync_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  SalesForceColumn?: InputMaybe<SalesForceColumnUpdateManyWithoutOrganizationInput>;
  salesForceData?: InputMaybe<SalesForceDataUpdateOneWithoutOrganizationInput>;
  ScheduleItems?: InputMaybe<ScheduleItemUpdateManyWithoutOrganizationInput>;
  self_sourcing_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  send_error_log_email?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  send_lead_routing_email?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sendgrid_email_domain?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sendgrid_email_domain_id?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  sequence?: InputMaybe<SequenceUpdateManyWithoutOrganzationInput>;
  SequenceCriteriaConditions?: InputMaybe<SequenceCriteriaConditionUpdateManyWithoutOrganizationInput>;
  SequenceDashboardViews?: InputMaybe<SequenceDashboardViewUpdateManyWithoutOrganizationInput>;
  SequenceEntryCriterias?: InputMaybe<SequenceEntryCriteriaUpdateManyWithoutOrganizationInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutOrganizationInput>;
  SequenceSteps?: InputMaybe<SequenceStepUpdateManyWithoutOrganizationInput>;
  set_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
  short_resting?: InputMaybe<IntFieldUpdateOperationsInput>;
  show_calendar_options?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_contract_duration_sales?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_lead_source_to_rep?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_nocontact_button?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_payment_terms_sales?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_rep_all_leads?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_rep_my_leads?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_system_view?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sites?: InputMaybe<SiteUpdateManyWithoutOrganizationInput>;
  sms_opt_in_dialog?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SMSItems?: InputMaybe<SmsItemUpdateManyWithoutOrganizationInput>;
  SnoozeTrackings?: InputMaybe<SnoozeTrackingUpdateManyWithoutOrganizationInput>;
  StepActionLogs?: InputMaybe<StepActionLogUpdateManyWithoutOrganizationInput>;
  StepActions?: InputMaybe<StepActionUpdateManyWithoutOrganizationInput>;
  StepConditions?: InputMaybe<StepConditionUpdateManyWithoutOrganizationInput>;
  teams?: InputMaybe<TeamUpdateManyWithoutOrganizationInput>;
  Template?: InputMaybe<TemplateUpdateManyWithoutOrganizationInput>;
  TemplateVersions?: InputMaybe<TemplateVersionUpdateManyWithoutOrganizationInput>;
  timekit_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timezone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transfer_timeout?: InputMaybe<IntFieldUpdateOperationsInput>;
  TransferAttempts?: InputMaybe<TransferAttemptUpdateManyWithoutOrganizationInput>;
  twilio_numbers?: InputMaybe<TwilioNumberUpdateManyWithoutOrganizationInput>;
  TwilioFeedbacks?: InputMaybe<TwilioFeedbackUpdateManyWithoutOrganizationInput>;
  TwilioInfo?: InputMaybe<TwilioInfoUpdateOneWithoutOrganzationInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  use_revenue_planning?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  UserCustomFieldFilters?: InputMaybe<UserCustomFieldFilterUpdateManyWithoutOrganizationInput>;
  users?: InputMaybe<UserUpdateManyWithoutOrganizationInput>;
  win_rep_day_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
};

export type OrganizationUpdateWithoutDispositionsInput = {
  ActivityMonitorMultiplier?: InputMaybe<ActivityMonitorMultiplierUpdateManyWithoutOrganizationInput>;
  algoFactor?: InputMaybe<AlgoFactorUpdateManyWithoutOrganizationInput>;
  allow_calendar_invite?: InputMaybe<BoolFieldUpdateOperationsInput>;
  allow_remove_step_action?: InputMaybe<BoolFieldUpdateOperationsInput>;
  allow_snooze_step_action?: InputMaybe<BoolFieldUpdateOperationsInput>;
  always_record?: InputMaybe<BoolFieldUpdateOperationsInput>;
  arv_initial_held_demos_generated_by_sdr_weight?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  AssociationHistories?: InputMaybe<AssociationHistoryUpdateManyWithoutOrganizationInput>;
  auto_call_interval?: InputMaybe<IntFieldUpdateOperationsInput>;
  auto_dial?: InputMaybe<BoolFieldUpdateOperationsInput>;
  automated_campaigns?: InputMaybe<AutomatedCampaignUpdateManyWithoutOrganizationInput>;
  automatic_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  AutoTransferSelections?: InputMaybe<AutoTransferSelectionUpdateManyWithoutOrganizationInput>;
  Availablities?: InputMaybe<AvailabilityUpdateManyWithoutOrganizationInput>;
  AverageOrderValue?: InputMaybe<AverageOrderValueUpdateManyWithoutOrganizationInput>;
  best_action_ever_multiplier?: InputMaybe<FloatFieldUpdateOperationsInput>;
  best_metrics?: InputMaybe<BestMetricRecordUpdateManyWithoutOrganizationInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptUpdateManyWithoutOrganizationInput>;
  callMeNows?: InputMaybe<CallMeNowUpdateManyWithoutOrganizationInput>;
  CallMultiplier?: InputMaybe<CallMultiplierUpdateManyWithoutOrganizationInput>;
  CallSharings?: InputMaybe<CallSharingUpdateManyWithoutOrganizationInput>;
  can_edit_sdr_transfer_rules?: InputMaybe<BoolFieldUpdateOperationsInput>;
  claim_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  concession?: InputMaybe<ConcessionUpdateManyWithoutOrganizationInput>;
  conference_region?: InputMaybe<EnumConferenceRegionFieldUpdateOperationsInput>;
  Conferences?: InputMaybe<ConferenceUpdateManyWithoutOrganizationInput>;
  ContactRequests?: InputMaybe<ContactRequestUpdateManyWithoutOrganizationInput>;
  Contacts?: InputMaybe<ContactUpdateManyWithoutOrganizationInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  custom_fields?: InputMaybe<CustomFieldUpdateManyWithoutOrganizationInput>;
  CustomObject?: InputMaybe<CustomObjectUpdateManyWithoutOrganizationInput>;
  CustomObjectField?: InputMaybe<CustomObjectFieldUpdateManyWithoutOrganizationInput>;
  CustomObjectFieldForeignKey?: InputMaybe<CustomObjectFieldForeignKeyUpdateManyWithoutOrganizationInput>;
  CustomObjectRecord?: InputMaybe<CustomObjectRecordUpdateManyWithoutOrganizationInput>;
  CustomObjectRow?: InputMaybe<CustomObjectRowUpdateManyWithoutOrganizationInput>;
  CustomQueueItems?: InputMaybe<CustomQueueItemUpdateManyWithoutOrganizationInput>;
  DashboardColumnOptions?: InputMaybe<DashboardColumnOptionUpdateManyWithoutOrganizationInput>;
  DashboardLeadFilters?: InputMaybe<DashboardLeadFilterUpdateManyWithoutOrganizationInput>;
  DashboardRepFilters?: InputMaybe<DashboardRepFilterUpdateManyWithoutOrganizationInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewUpdateManyWithoutOrganizationInput>;
  day_to_close?: InputMaybe<IntFieldUpdateOperationsInput>;
  default_demo_time_duration?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  demo_held_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_not_held_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_not_held_call_back_short_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_prep_time_minutes?: InputMaybe<IntFieldUpdateOperationsInput>;
  dev_account?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  dial_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  DispositionMultiplier?: InputMaybe<DispositionMultiplierUpdateManyWithoutOrganizationInput>;
  double_booking?: InputMaybe<BoolFieldUpdateOperationsInput>;
  dynamic_date_operators?: InputMaybe<BoolFieldUpdateOperationsInput>;
  edge_servers?: InputMaybe<OrganizationUpdateedge_ServersInput>;
  email_conflict_notification_email_list?: InputMaybe<OrganizationUpdateemail_Conflict_Notification_Email_ListInput>;
  EmailItems?: InputMaybe<EmailItemUpdateManyWithoutOrganizationInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordUpdateManyWithoutOrganizationInput>;
  EmailThreads?: InputMaybe<EmailThreadUpdateManyWithoutOrganizationInput>;
  enable_modified_metrics?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  enable_pay_period_view?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  enable_time_constraint?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  error_log_email_address?: InputMaybe<OrganizationUpdateerror_Log_Email_AddressInput>;
  estimated_average_num_of_weekly_overbooked_demo_slots?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  FolderPermissions?: InputMaybe<FolderPermissionUpdateManyWithoutOrganizationInput>;
  Folders?: InputMaybe<FolderUpdateManyWithoutOrganizationInput>;
  FutureBookingSetting?: InputMaybe<FutureBookingSettingUpdateOneWithoutOrganizationInput>;
  GlobalTransferRule?: InputMaybe<GlobalTransferRuleUpdateManyWithoutOrganizationInput>;
  held_demos_per_cc_connect?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  helpful_links?: InputMaybe<OrganizationUpdatehelpful_LinksInput>;
  HingePointMultiplier?: InputMaybe<HingePointMultiplierUpdateManyWithoutOrganizationInput>;
  hold_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
  hubspot_credential?: InputMaybe<HubSpotCredentialUpdateOneWithoutOrganizationInput>;
  hubspot_last_sync_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  hubspot_white_list?: InputMaybe<HubSpotWhitelistUpdateManyWithoutOrganizationInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inbound_close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_dm_connect_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_lt_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_revenue_value_of_sale?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_scheduled_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_set_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventUpdateManyWithoutOrganizationInput>;
  InboundConciergeWidget?: InputMaybe<InboundConciergeWidgetUpdateManyWithoutOrganizationInput>;
  industry_options?: InputMaybe<IndustryOptionUpdateManyWithoutOrganizationInput>;
  initial_sdr_sets_reps_day?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  integration_error_logs?: InputMaybe<IntegrationErrorLogUpdateManyWithoutOrganizationInput>;
  integration_mappings?: InputMaybe<IntegrationMappingUpdateManyWithoutOrganizationInput>;
  IntegrationMappingCustomObject?: InputMaybe<IntegrationMappingCustomObjectUpdateManyWithoutOrganizationInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogUpdateManyWithoutOrganizationInput>;
  IntegrationReadOnlyObject?: InputMaybe<IntegrationReadOnlyObjectUpdateManyWithoutOrganizationInput>;
  international?: InputMaybe<BoolFieldUpdateOperationsInput>;
  is_email_domain_verified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  is_salesforce_sandbox?: InputMaybe<BoolFieldUpdateOperationsInput>;
  last_lead_value_compute_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutOrganizationInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryUpdateManyWithoutOrganizationInput>;
  lead_intent?: InputMaybe<LeadIntentUpdateManyWithoutOrganizationInput>;
  lead_release_same_rep_delay_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  lead_routing_email_list?: InputMaybe<OrganizationUpdatelead_Routing_Email_ListInput>;
  lead_routing_rules?: InputMaybe<RuleUpdateManyWithoutOrganizationInput>;
  LeadCustomObjectRowAssociation?: InputMaybe<LeadCustomObjectRowAssociationUpdateManyWithoutOrganizationInput>;
  LeadFieldMultiplier?: InputMaybe<LeadFieldMultiplierUpdateManyWithoutOrganizationInput>;
  LeadFieldMultiplierCustom?: InputMaybe<LeadFieldMultiplierCustomUpdateManyWithoutOrganizationInput>;
  leads?: InputMaybe<LeadUpdateManyWithoutOrganizationInput>;
  leadsource_options?: InputMaybe<LeadSourceOptionUpdateManyWithoutOrganizationInput>;
  LeadValue?: InputMaybe<LeadValueUpdateManyWithoutOrganizationInput>;
  LifeTimeValueMultiplier?: InputMaybe<LifeTimeValueMultiplierUpdateManyWithoutOrganizationInput>;
  LiveTransferSetting?: InputMaybe<LiveTransferSettingUpdateOneWithoutOrganizationInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusUpdateManyWithoutOrganizationInput>;
  ltv_multiplier_custom_fields?: InputMaybe<LifeTimeValueMultiplierCustomUpdateManyWithoutOrganizationInput>;
  made_contact_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  make_sale_address_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_business_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_city_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_country_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_first_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_industry_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_last_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_lead_source_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_primary_email_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_primary_phone_number_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_state_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_sub_industry_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_zip_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  maxium_day_lag_set_to_schedule?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  mrr?: InputMaybe<IntFieldUpdateOperationsInput>;
  mrr_label?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  mrr_required_on_sale?: InputMaybe<BoolFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  NoteItems?: InputMaybe<NoteItemUpdateManyWithoutOrganizationInput>;
  Notifications?: InputMaybe<NotificationUpdateManyWithoutOrganizationInput>;
  onboardComplete?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  Opportunity?: InputMaybe<SfOpportunityUpdateManyWithoutOrganizationInput>;
  organization_holidays?: InputMaybe<OrganizationHolidayUpdateManyWithoutOrganizationInput>;
  organization_hours?: InputMaybe<OrganizationHourUpdateManyWithoutOrganizationInput>;
  OrganizationPaymentSetting?: InputMaybe<OrganizationPaymentSettingsUpdateOneWithoutOrganizationInput>;
  OrganizationPaymentSettings?: InputMaybe<OrganizationPaymentSettingsUpdateOneWithoutOrganizationsInput>;
  orgCalendarInviteMetadata?: InputMaybe<OrgCalendarInviteMetadataUpdateManyWithoutOrganizationInput>;
  OrgDefaultPipelinePerecentValue?: InputMaybe<OrgDefaultPipelinePerecentValueUpdateOneWithoutOrganizationInput>;
  OrgHubSpotSync?: InputMaybe<OrgHubSpotSyncUpdateOneWithoutOrganizationInput>;
  OrgNoEventDate?: InputMaybe<OrgNoEventDateUpdateManyWithoutOrganizationInput>;
  OrgSalesforceSync?: InputMaybe<OrgSalesforceSyncUpdateOneWithoutOrganizationInput>;
  OrgTimeslotAvailability?: InputMaybe<OrgTimeslotAvailabilityUpdateManyWithoutOrganizationInput>;
  outbound_close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_dm_connect_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_lt_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_revenue_value_of_sale?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_scheduled_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_set_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  PandadocData?: InputMaybe<PandadocDataUpdateOneWithoutOrganizationInput>;
  PandaDocTemplate?: InputMaybe<PandaDocTemplateUpdateManyWithoutOrganizationInput>;
  participant_jitter_buffer_size?: InputMaybe<EnumJitterBufferSizeFieldUpdateOperationsInput>;
  preferred_goal_setting?: InputMaybe<NullableEnumGoalsettingFieldUpdateOperationsInput>;
  prepayment?: InputMaybe<BoolFieldUpdateOperationsInput>;
  ProductChangeLog?: InputMaybe<ProductChangeLogUpdateManyWithoutOrganizationInput>;
  products?: InputMaybe<ProductUpdateManyWithoutOrganizationInput>;
  re_cold_call_hour_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  recordingStatus?: InputMaybe<EnumRecordingStatusFieldUpdateOperationsInput>;
  release_lead_resting?: InputMaybe<IntFieldUpdateOperationsInput>;
  rep_claim_limit?: InputMaybe<IntFieldUpdateOperationsInput>;
  rep_lead_allotment?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  rep_level_call_transfer?: InputMaybe<BoolFieldUpdateOperationsInput>;
  reps_can_bulk_unassign?: InputMaybe<BoolFieldUpdateOperationsInput>;
  reps_can_view_all_notes?: InputMaybe<BoolFieldUpdateOperationsInput>;
  require_sale_notes?: InputMaybe<BoolFieldUpdateOperationsInput>;
  resting_after_skipped_CC?: InputMaybe<IntFieldUpdateOperationsInput>;
  resting_period?: InputMaybe<IntFieldUpdateOperationsInput>;
  resting_period_after_unsuccessful_cold_call?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  resting_period_after_unsuccessful_demo?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  revenue_plan?: InputMaybe<RevenuePlanItemUpdateManyWithoutOrganizationInput>;
  Routings?: InputMaybe<RoutingUpdateManyWithoutOrganizationInput>;
  sale_field_options?: InputMaybe<SaleFieldOptionUpdateManyWithoutOrganizationInput>;
  sale_notification_email_list?: InputMaybe<OrganizationUpdatesale_Notification_Email_ListInput>;
  SaleConfig?: InputMaybe<SaleConfigUpdateOneWithoutOrganizationInput>;
  SaleConfigPage?: InputMaybe<SaleConfigPageUpdateManyWithoutOrganizationInput>;
  SaleConfigSection?: InputMaybe<SaleConfigSectionUpdateManyWithoutOrganizationInput>;
  SaleCycles?: InputMaybe<SaleCycleUpdateManyWithoutOrganizationInput>;
  SaleItems?: InputMaybe<SaleItemUpdateManyWithoutOrganizationInput>;
  sales?: InputMaybe<SaleUpdateManyWithoutOrganizationInput>;
  salesforce_domain?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  salesforce_last_sync_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  SalesForceColumn?: InputMaybe<SalesForceColumnUpdateManyWithoutOrganizationInput>;
  salesForceData?: InputMaybe<SalesForceDataUpdateOneWithoutOrganizationInput>;
  ScheduleItems?: InputMaybe<ScheduleItemUpdateManyWithoutOrganizationInput>;
  self_sourcing_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  send_error_log_email?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  send_lead_routing_email?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sendgrid_email_domain?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sendgrid_email_domain_id?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  sequence?: InputMaybe<SequenceUpdateManyWithoutOrganzationInput>;
  SequenceCriteriaConditions?: InputMaybe<SequenceCriteriaConditionUpdateManyWithoutOrganizationInput>;
  SequenceDashboardViews?: InputMaybe<SequenceDashboardViewUpdateManyWithoutOrganizationInput>;
  SequenceEntryCriterias?: InputMaybe<SequenceEntryCriteriaUpdateManyWithoutOrganizationInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutOrganizationInput>;
  SequenceSteps?: InputMaybe<SequenceStepUpdateManyWithoutOrganizationInput>;
  set_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
  short_resting?: InputMaybe<IntFieldUpdateOperationsInput>;
  show_calendar_options?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_contract_duration_sales?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_lead_source_to_rep?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_nocontact_button?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_payment_terms_sales?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_rep_all_leads?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_rep_my_leads?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_system_view?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sites?: InputMaybe<SiteUpdateManyWithoutOrganizationInput>;
  sms_opt_in_dialog?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SMSItems?: InputMaybe<SmsItemUpdateManyWithoutOrganizationInput>;
  SnoozeTrackings?: InputMaybe<SnoozeTrackingUpdateManyWithoutOrganizationInput>;
  StepActionLogs?: InputMaybe<StepActionLogUpdateManyWithoutOrganizationInput>;
  StepActions?: InputMaybe<StepActionUpdateManyWithoutOrganizationInput>;
  StepConditions?: InputMaybe<StepConditionUpdateManyWithoutOrganizationInput>;
  teams?: InputMaybe<TeamUpdateManyWithoutOrganizationInput>;
  Template?: InputMaybe<TemplateUpdateManyWithoutOrganizationInput>;
  TemplateVersions?: InputMaybe<TemplateVersionUpdateManyWithoutOrganizationInput>;
  timekit_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timezone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transfer_timeout?: InputMaybe<IntFieldUpdateOperationsInput>;
  TransferAttempts?: InputMaybe<TransferAttemptUpdateManyWithoutOrganizationInput>;
  twilio_numbers?: InputMaybe<TwilioNumberUpdateManyWithoutOrganizationInput>;
  TwilioFeedbacks?: InputMaybe<TwilioFeedbackUpdateManyWithoutOrganizationInput>;
  TwilioInfo?: InputMaybe<TwilioInfoUpdateOneWithoutOrganzationInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  use_revenue_planning?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  UserCustomFieldFilters?: InputMaybe<UserCustomFieldFilterUpdateManyWithoutOrganizationInput>;
  users?: InputMaybe<UserUpdateManyWithoutOrganizationInput>;
  win_rep_day_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
};

export type OrganizationUpdateWithoutEmailItemsInput = {
  ActivityMonitorMultiplier?: InputMaybe<ActivityMonitorMultiplierUpdateManyWithoutOrganizationInput>;
  algoFactor?: InputMaybe<AlgoFactorUpdateManyWithoutOrganizationInput>;
  allow_calendar_invite?: InputMaybe<BoolFieldUpdateOperationsInput>;
  allow_remove_step_action?: InputMaybe<BoolFieldUpdateOperationsInput>;
  allow_snooze_step_action?: InputMaybe<BoolFieldUpdateOperationsInput>;
  always_record?: InputMaybe<BoolFieldUpdateOperationsInput>;
  arv_initial_held_demos_generated_by_sdr_weight?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  AssociationHistories?: InputMaybe<AssociationHistoryUpdateManyWithoutOrganizationInput>;
  auto_call_interval?: InputMaybe<IntFieldUpdateOperationsInput>;
  auto_dial?: InputMaybe<BoolFieldUpdateOperationsInput>;
  automated_campaigns?: InputMaybe<AutomatedCampaignUpdateManyWithoutOrganizationInput>;
  automatic_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  AutoTransferSelections?: InputMaybe<AutoTransferSelectionUpdateManyWithoutOrganizationInput>;
  Availablities?: InputMaybe<AvailabilityUpdateManyWithoutOrganizationInput>;
  AverageOrderValue?: InputMaybe<AverageOrderValueUpdateManyWithoutOrganizationInput>;
  best_action_ever_multiplier?: InputMaybe<FloatFieldUpdateOperationsInput>;
  best_metrics?: InputMaybe<BestMetricRecordUpdateManyWithoutOrganizationInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptUpdateManyWithoutOrganizationInput>;
  callMeNows?: InputMaybe<CallMeNowUpdateManyWithoutOrganizationInput>;
  CallMultiplier?: InputMaybe<CallMultiplierUpdateManyWithoutOrganizationInput>;
  CallSharings?: InputMaybe<CallSharingUpdateManyWithoutOrganizationInput>;
  can_edit_sdr_transfer_rules?: InputMaybe<BoolFieldUpdateOperationsInput>;
  claim_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  concession?: InputMaybe<ConcessionUpdateManyWithoutOrganizationInput>;
  conference_region?: InputMaybe<EnumConferenceRegionFieldUpdateOperationsInput>;
  Conferences?: InputMaybe<ConferenceUpdateManyWithoutOrganizationInput>;
  ContactRequests?: InputMaybe<ContactRequestUpdateManyWithoutOrganizationInput>;
  Contacts?: InputMaybe<ContactUpdateManyWithoutOrganizationInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  custom_fields?: InputMaybe<CustomFieldUpdateManyWithoutOrganizationInput>;
  CustomObject?: InputMaybe<CustomObjectUpdateManyWithoutOrganizationInput>;
  CustomObjectField?: InputMaybe<CustomObjectFieldUpdateManyWithoutOrganizationInput>;
  CustomObjectFieldForeignKey?: InputMaybe<CustomObjectFieldForeignKeyUpdateManyWithoutOrganizationInput>;
  CustomObjectRecord?: InputMaybe<CustomObjectRecordUpdateManyWithoutOrganizationInput>;
  CustomObjectRow?: InputMaybe<CustomObjectRowUpdateManyWithoutOrganizationInput>;
  CustomQueueItems?: InputMaybe<CustomQueueItemUpdateManyWithoutOrganizationInput>;
  DashboardColumnOptions?: InputMaybe<DashboardColumnOptionUpdateManyWithoutOrganizationInput>;
  DashboardLeadFilters?: InputMaybe<DashboardLeadFilterUpdateManyWithoutOrganizationInput>;
  DashboardRepFilters?: InputMaybe<DashboardRepFilterUpdateManyWithoutOrganizationInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewUpdateManyWithoutOrganizationInput>;
  day_to_close?: InputMaybe<IntFieldUpdateOperationsInput>;
  default_demo_time_duration?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  demo_held_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_not_held_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_not_held_call_back_short_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_prep_time_minutes?: InputMaybe<IntFieldUpdateOperationsInput>;
  dev_account?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  dial_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  DispositionMultiplier?: InputMaybe<DispositionMultiplierUpdateManyWithoutOrganizationInput>;
  dispositions?: InputMaybe<DispositionUpdateManyWithoutOrganizationInput>;
  double_booking?: InputMaybe<BoolFieldUpdateOperationsInput>;
  dynamic_date_operators?: InputMaybe<BoolFieldUpdateOperationsInput>;
  edge_servers?: InputMaybe<OrganizationUpdateedge_ServersInput>;
  email_conflict_notification_email_list?: InputMaybe<OrganizationUpdateemail_Conflict_Notification_Email_ListInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordUpdateManyWithoutOrganizationInput>;
  EmailThreads?: InputMaybe<EmailThreadUpdateManyWithoutOrganizationInput>;
  enable_modified_metrics?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  enable_pay_period_view?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  enable_time_constraint?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  error_log_email_address?: InputMaybe<OrganizationUpdateerror_Log_Email_AddressInput>;
  estimated_average_num_of_weekly_overbooked_demo_slots?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  FolderPermissions?: InputMaybe<FolderPermissionUpdateManyWithoutOrganizationInput>;
  Folders?: InputMaybe<FolderUpdateManyWithoutOrganizationInput>;
  FutureBookingSetting?: InputMaybe<FutureBookingSettingUpdateOneWithoutOrganizationInput>;
  GlobalTransferRule?: InputMaybe<GlobalTransferRuleUpdateManyWithoutOrganizationInput>;
  held_demos_per_cc_connect?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  helpful_links?: InputMaybe<OrganizationUpdatehelpful_LinksInput>;
  HingePointMultiplier?: InputMaybe<HingePointMultiplierUpdateManyWithoutOrganizationInput>;
  hold_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
  hubspot_credential?: InputMaybe<HubSpotCredentialUpdateOneWithoutOrganizationInput>;
  hubspot_last_sync_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  hubspot_white_list?: InputMaybe<HubSpotWhitelistUpdateManyWithoutOrganizationInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inbound_close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_dm_connect_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_lt_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_revenue_value_of_sale?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_scheduled_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_set_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventUpdateManyWithoutOrganizationInput>;
  InboundConciergeWidget?: InputMaybe<InboundConciergeWidgetUpdateManyWithoutOrganizationInput>;
  industry_options?: InputMaybe<IndustryOptionUpdateManyWithoutOrganizationInput>;
  initial_sdr_sets_reps_day?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  integration_error_logs?: InputMaybe<IntegrationErrorLogUpdateManyWithoutOrganizationInput>;
  integration_mappings?: InputMaybe<IntegrationMappingUpdateManyWithoutOrganizationInput>;
  IntegrationMappingCustomObject?: InputMaybe<IntegrationMappingCustomObjectUpdateManyWithoutOrganizationInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogUpdateManyWithoutOrganizationInput>;
  IntegrationReadOnlyObject?: InputMaybe<IntegrationReadOnlyObjectUpdateManyWithoutOrganizationInput>;
  international?: InputMaybe<BoolFieldUpdateOperationsInput>;
  is_email_domain_verified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  is_salesforce_sandbox?: InputMaybe<BoolFieldUpdateOperationsInput>;
  last_lead_value_compute_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutOrganizationInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryUpdateManyWithoutOrganizationInput>;
  lead_intent?: InputMaybe<LeadIntentUpdateManyWithoutOrganizationInput>;
  lead_release_same_rep_delay_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  lead_routing_email_list?: InputMaybe<OrganizationUpdatelead_Routing_Email_ListInput>;
  lead_routing_rules?: InputMaybe<RuleUpdateManyWithoutOrganizationInput>;
  LeadCustomObjectRowAssociation?: InputMaybe<LeadCustomObjectRowAssociationUpdateManyWithoutOrganizationInput>;
  LeadFieldMultiplier?: InputMaybe<LeadFieldMultiplierUpdateManyWithoutOrganizationInput>;
  LeadFieldMultiplierCustom?: InputMaybe<LeadFieldMultiplierCustomUpdateManyWithoutOrganizationInput>;
  leads?: InputMaybe<LeadUpdateManyWithoutOrganizationInput>;
  leadsource_options?: InputMaybe<LeadSourceOptionUpdateManyWithoutOrganizationInput>;
  LeadValue?: InputMaybe<LeadValueUpdateManyWithoutOrganizationInput>;
  LifeTimeValueMultiplier?: InputMaybe<LifeTimeValueMultiplierUpdateManyWithoutOrganizationInput>;
  LiveTransferSetting?: InputMaybe<LiveTransferSettingUpdateOneWithoutOrganizationInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusUpdateManyWithoutOrganizationInput>;
  ltv_multiplier_custom_fields?: InputMaybe<LifeTimeValueMultiplierCustomUpdateManyWithoutOrganizationInput>;
  made_contact_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  make_sale_address_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_business_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_city_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_country_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_first_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_industry_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_last_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_lead_source_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_primary_email_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_primary_phone_number_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_state_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_sub_industry_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_zip_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  maxium_day_lag_set_to_schedule?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  mrr?: InputMaybe<IntFieldUpdateOperationsInput>;
  mrr_label?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  mrr_required_on_sale?: InputMaybe<BoolFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  NoteItems?: InputMaybe<NoteItemUpdateManyWithoutOrganizationInput>;
  Notifications?: InputMaybe<NotificationUpdateManyWithoutOrganizationInput>;
  onboardComplete?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  Opportunity?: InputMaybe<SfOpportunityUpdateManyWithoutOrganizationInput>;
  organization_holidays?: InputMaybe<OrganizationHolidayUpdateManyWithoutOrganizationInput>;
  organization_hours?: InputMaybe<OrganizationHourUpdateManyWithoutOrganizationInput>;
  OrganizationPaymentSetting?: InputMaybe<OrganizationPaymentSettingsUpdateOneWithoutOrganizationInput>;
  OrganizationPaymentSettings?: InputMaybe<OrganizationPaymentSettingsUpdateOneWithoutOrganizationsInput>;
  orgCalendarInviteMetadata?: InputMaybe<OrgCalendarInviteMetadataUpdateManyWithoutOrganizationInput>;
  OrgDefaultPipelinePerecentValue?: InputMaybe<OrgDefaultPipelinePerecentValueUpdateOneWithoutOrganizationInput>;
  OrgHubSpotSync?: InputMaybe<OrgHubSpotSyncUpdateOneWithoutOrganizationInput>;
  OrgNoEventDate?: InputMaybe<OrgNoEventDateUpdateManyWithoutOrganizationInput>;
  OrgSalesforceSync?: InputMaybe<OrgSalesforceSyncUpdateOneWithoutOrganizationInput>;
  OrgTimeslotAvailability?: InputMaybe<OrgTimeslotAvailabilityUpdateManyWithoutOrganizationInput>;
  outbound_close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_dm_connect_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_lt_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_revenue_value_of_sale?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_scheduled_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_set_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  PandadocData?: InputMaybe<PandadocDataUpdateOneWithoutOrganizationInput>;
  PandaDocTemplate?: InputMaybe<PandaDocTemplateUpdateManyWithoutOrganizationInput>;
  participant_jitter_buffer_size?: InputMaybe<EnumJitterBufferSizeFieldUpdateOperationsInput>;
  preferred_goal_setting?: InputMaybe<NullableEnumGoalsettingFieldUpdateOperationsInput>;
  prepayment?: InputMaybe<BoolFieldUpdateOperationsInput>;
  ProductChangeLog?: InputMaybe<ProductChangeLogUpdateManyWithoutOrganizationInput>;
  products?: InputMaybe<ProductUpdateManyWithoutOrganizationInput>;
  re_cold_call_hour_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  recordingStatus?: InputMaybe<EnumRecordingStatusFieldUpdateOperationsInput>;
  release_lead_resting?: InputMaybe<IntFieldUpdateOperationsInput>;
  rep_claim_limit?: InputMaybe<IntFieldUpdateOperationsInput>;
  rep_lead_allotment?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  rep_level_call_transfer?: InputMaybe<BoolFieldUpdateOperationsInput>;
  reps_can_bulk_unassign?: InputMaybe<BoolFieldUpdateOperationsInput>;
  reps_can_view_all_notes?: InputMaybe<BoolFieldUpdateOperationsInput>;
  require_sale_notes?: InputMaybe<BoolFieldUpdateOperationsInput>;
  resting_after_skipped_CC?: InputMaybe<IntFieldUpdateOperationsInput>;
  resting_period?: InputMaybe<IntFieldUpdateOperationsInput>;
  resting_period_after_unsuccessful_cold_call?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  resting_period_after_unsuccessful_demo?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  revenue_plan?: InputMaybe<RevenuePlanItemUpdateManyWithoutOrganizationInput>;
  Routings?: InputMaybe<RoutingUpdateManyWithoutOrganizationInput>;
  sale_field_options?: InputMaybe<SaleFieldOptionUpdateManyWithoutOrganizationInput>;
  sale_notification_email_list?: InputMaybe<OrganizationUpdatesale_Notification_Email_ListInput>;
  SaleConfig?: InputMaybe<SaleConfigUpdateOneWithoutOrganizationInput>;
  SaleConfigPage?: InputMaybe<SaleConfigPageUpdateManyWithoutOrganizationInput>;
  SaleConfigSection?: InputMaybe<SaleConfigSectionUpdateManyWithoutOrganizationInput>;
  SaleCycles?: InputMaybe<SaleCycleUpdateManyWithoutOrganizationInput>;
  SaleItems?: InputMaybe<SaleItemUpdateManyWithoutOrganizationInput>;
  sales?: InputMaybe<SaleUpdateManyWithoutOrganizationInput>;
  salesforce_domain?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  salesforce_last_sync_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  SalesForceColumn?: InputMaybe<SalesForceColumnUpdateManyWithoutOrganizationInput>;
  salesForceData?: InputMaybe<SalesForceDataUpdateOneWithoutOrganizationInput>;
  ScheduleItems?: InputMaybe<ScheduleItemUpdateManyWithoutOrganizationInput>;
  self_sourcing_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  send_error_log_email?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  send_lead_routing_email?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sendgrid_email_domain?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sendgrid_email_domain_id?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  sequence?: InputMaybe<SequenceUpdateManyWithoutOrganzationInput>;
  SequenceCriteriaConditions?: InputMaybe<SequenceCriteriaConditionUpdateManyWithoutOrganizationInput>;
  SequenceDashboardViews?: InputMaybe<SequenceDashboardViewUpdateManyWithoutOrganizationInput>;
  SequenceEntryCriterias?: InputMaybe<SequenceEntryCriteriaUpdateManyWithoutOrganizationInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutOrganizationInput>;
  SequenceSteps?: InputMaybe<SequenceStepUpdateManyWithoutOrganizationInput>;
  set_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
  short_resting?: InputMaybe<IntFieldUpdateOperationsInput>;
  show_calendar_options?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_contract_duration_sales?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_lead_source_to_rep?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_nocontact_button?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_payment_terms_sales?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_rep_all_leads?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_rep_my_leads?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_system_view?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sites?: InputMaybe<SiteUpdateManyWithoutOrganizationInput>;
  sms_opt_in_dialog?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SMSItems?: InputMaybe<SmsItemUpdateManyWithoutOrganizationInput>;
  SnoozeTrackings?: InputMaybe<SnoozeTrackingUpdateManyWithoutOrganizationInput>;
  StepActionLogs?: InputMaybe<StepActionLogUpdateManyWithoutOrganizationInput>;
  StepActions?: InputMaybe<StepActionUpdateManyWithoutOrganizationInput>;
  StepConditions?: InputMaybe<StepConditionUpdateManyWithoutOrganizationInput>;
  teams?: InputMaybe<TeamUpdateManyWithoutOrganizationInput>;
  Template?: InputMaybe<TemplateUpdateManyWithoutOrganizationInput>;
  TemplateVersions?: InputMaybe<TemplateVersionUpdateManyWithoutOrganizationInput>;
  timekit_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timezone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transfer_timeout?: InputMaybe<IntFieldUpdateOperationsInput>;
  TransferAttempts?: InputMaybe<TransferAttemptUpdateManyWithoutOrganizationInput>;
  twilio_numbers?: InputMaybe<TwilioNumberUpdateManyWithoutOrganizationInput>;
  TwilioFeedbacks?: InputMaybe<TwilioFeedbackUpdateManyWithoutOrganizationInput>;
  TwilioInfo?: InputMaybe<TwilioInfoUpdateOneWithoutOrganzationInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  use_revenue_planning?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  UserCustomFieldFilters?: InputMaybe<UserCustomFieldFilterUpdateManyWithoutOrganizationInput>;
  users?: InputMaybe<UserUpdateManyWithoutOrganizationInput>;
  win_rep_day_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
};

export type OrganizationUpdateWithoutEmailSyncRecordInput = {
  ActivityMonitorMultiplier?: InputMaybe<ActivityMonitorMultiplierUpdateManyWithoutOrganizationInput>;
  algoFactor?: InputMaybe<AlgoFactorUpdateManyWithoutOrganizationInput>;
  allow_calendar_invite?: InputMaybe<BoolFieldUpdateOperationsInput>;
  allow_remove_step_action?: InputMaybe<BoolFieldUpdateOperationsInput>;
  allow_snooze_step_action?: InputMaybe<BoolFieldUpdateOperationsInput>;
  always_record?: InputMaybe<BoolFieldUpdateOperationsInput>;
  arv_initial_held_demos_generated_by_sdr_weight?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  AssociationHistories?: InputMaybe<AssociationHistoryUpdateManyWithoutOrganizationInput>;
  auto_call_interval?: InputMaybe<IntFieldUpdateOperationsInput>;
  auto_dial?: InputMaybe<BoolFieldUpdateOperationsInput>;
  automated_campaigns?: InputMaybe<AutomatedCampaignUpdateManyWithoutOrganizationInput>;
  automatic_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  AutoTransferSelections?: InputMaybe<AutoTransferSelectionUpdateManyWithoutOrganizationInput>;
  Availablities?: InputMaybe<AvailabilityUpdateManyWithoutOrganizationInput>;
  AverageOrderValue?: InputMaybe<AverageOrderValueUpdateManyWithoutOrganizationInput>;
  best_action_ever_multiplier?: InputMaybe<FloatFieldUpdateOperationsInput>;
  best_metrics?: InputMaybe<BestMetricRecordUpdateManyWithoutOrganizationInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptUpdateManyWithoutOrganizationInput>;
  callMeNows?: InputMaybe<CallMeNowUpdateManyWithoutOrganizationInput>;
  CallMultiplier?: InputMaybe<CallMultiplierUpdateManyWithoutOrganizationInput>;
  CallSharings?: InputMaybe<CallSharingUpdateManyWithoutOrganizationInput>;
  can_edit_sdr_transfer_rules?: InputMaybe<BoolFieldUpdateOperationsInput>;
  claim_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  concession?: InputMaybe<ConcessionUpdateManyWithoutOrganizationInput>;
  conference_region?: InputMaybe<EnumConferenceRegionFieldUpdateOperationsInput>;
  Conferences?: InputMaybe<ConferenceUpdateManyWithoutOrganizationInput>;
  ContactRequests?: InputMaybe<ContactRequestUpdateManyWithoutOrganizationInput>;
  Contacts?: InputMaybe<ContactUpdateManyWithoutOrganizationInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  custom_fields?: InputMaybe<CustomFieldUpdateManyWithoutOrganizationInput>;
  CustomObject?: InputMaybe<CustomObjectUpdateManyWithoutOrganizationInput>;
  CustomObjectField?: InputMaybe<CustomObjectFieldUpdateManyWithoutOrganizationInput>;
  CustomObjectFieldForeignKey?: InputMaybe<CustomObjectFieldForeignKeyUpdateManyWithoutOrganizationInput>;
  CustomObjectRecord?: InputMaybe<CustomObjectRecordUpdateManyWithoutOrganizationInput>;
  CustomObjectRow?: InputMaybe<CustomObjectRowUpdateManyWithoutOrganizationInput>;
  CustomQueueItems?: InputMaybe<CustomQueueItemUpdateManyWithoutOrganizationInput>;
  DashboardColumnOptions?: InputMaybe<DashboardColumnOptionUpdateManyWithoutOrganizationInput>;
  DashboardLeadFilters?: InputMaybe<DashboardLeadFilterUpdateManyWithoutOrganizationInput>;
  DashboardRepFilters?: InputMaybe<DashboardRepFilterUpdateManyWithoutOrganizationInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewUpdateManyWithoutOrganizationInput>;
  day_to_close?: InputMaybe<IntFieldUpdateOperationsInput>;
  default_demo_time_duration?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  demo_held_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_not_held_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_not_held_call_back_short_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_prep_time_minutes?: InputMaybe<IntFieldUpdateOperationsInput>;
  dev_account?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  dial_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  DispositionMultiplier?: InputMaybe<DispositionMultiplierUpdateManyWithoutOrganizationInput>;
  dispositions?: InputMaybe<DispositionUpdateManyWithoutOrganizationInput>;
  double_booking?: InputMaybe<BoolFieldUpdateOperationsInput>;
  dynamic_date_operators?: InputMaybe<BoolFieldUpdateOperationsInput>;
  edge_servers?: InputMaybe<OrganizationUpdateedge_ServersInput>;
  email_conflict_notification_email_list?: InputMaybe<OrganizationUpdateemail_Conflict_Notification_Email_ListInput>;
  EmailItems?: InputMaybe<EmailItemUpdateManyWithoutOrganizationInput>;
  EmailThreads?: InputMaybe<EmailThreadUpdateManyWithoutOrganizationInput>;
  enable_modified_metrics?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  enable_pay_period_view?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  enable_time_constraint?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  error_log_email_address?: InputMaybe<OrganizationUpdateerror_Log_Email_AddressInput>;
  estimated_average_num_of_weekly_overbooked_demo_slots?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  FolderPermissions?: InputMaybe<FolderPermissionUpdateManyWithoutOrganizationInput>;
  Folders?: InputMaybe<FolderUpdateManyWithoutOrganizationInput>;
  FutureBookingSetting?: InputMaybe<FutureBookingSettingUpdateOneWithoutOrganizationInput>;
  GlobalTransferRule?: InputMaybe<GlobalTransferRuleUpdateManyWithoutOrganizationInput>;
  held_demos_per_cc_connect?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  helpful_links?: InputMaybe<OrganizationUpdatehelpful_LinksInput>;
  HingePointMultiplier?: InputMaybe<HingePointMultiplierUpdateManyWithoutOrganizationInput>;
  hold_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
  hubspot_credential?: InputMaybe<HubSpotCredentialUpdateOneWithoutOrganizationInput>;
  hubspot_last_sync_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  hubspot_white_list?: InputMaybe<HubSpotWhitelistUpdateManyWithoutOrganizationInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inbound_close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_dm_connect_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_lt_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_revenue_value_of_sale?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_scheduled_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_set_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventUpdateManyWithoutOrganizationInput>;
  InboundConciergeWidget?: InputMaybe<InboundConciergeWidgetUpdateManyWithoutOrganizationInput>;
  industry_options?: InputMaybe<IndustryOptionUpdateManyWithoutOrganizationInput>;
  initial_sdr_sets_reps_day?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  integration_error_logs?: InputMaybe<IntegrationErrorLogUpdateManyWithoutOrganizationInput>;
  integration_mappings?: InputMaybe<IntegrationMappingUpdateManyWithoutOrganizationInput>;
  IntegrationMappingCustomObject?: InputMaybe<IntegrationMappingCustomObjectUpdateManyWithoutOrganizationInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogUpdateManyWithoutOrganizationInput>;
  IntegrationReadOnlyObject?: InputMaybe<IntegrationReadOnlyObjectUpdateManyWithoutOrganizationInput>;
  international?: InputMaybe<BoolFieldUpdateOperationsInput>;
  is_email_domain_verified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  is_salesforce_sandbox?: InputMaybe<BoolFieldUpdateOperationsInput>;
  last_lead_value_compute_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutOrganizationInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryUpdateManyWithoutOrganizationInput>;
  lead_intent?: InputMaybe<LeadIntentUpdateManyWithoutOrganizationInput>;
  lead_release_same_rep_delay_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  lead_routing_email_list?: InputMaybe<OrganizationUpdatelead_Routing_Email_ListInput>;
  lead_routing_rules?: InputMaybe<RuleUpdateManyWithoutOrganizationInput>;
  LeadCustomObjectRowAssociation?: InputMaybe<LeadCustomObjectRowAssociationUpdateManyWithoutOrganizationInput>;
  LeadFieldMultiplier?: InputMaybe<LeadFieldMultiplierUpdateManyWithoutOrganizationInput>;
  LeadFieldMultiplierCustom?: InputMaybe<LeadFieldMultiplierCustomUpdateManyWithoutOrganizationInput>;
  leads?: InputMaybe<LeadUpdateManyWithoutOrganizationInput>;
  leadsource_options?: InputMaybe<LeadSourceOptionUpdateManyWithoutOrganizationInput>;
  LeadValue?: InputMaybe<LeadValueUpdateManyWithoutOrganizationInput>;
  LifeTimeValueMultiplier?: InputMaybe<LifeTimeValueMultiplierUpdateManyWithoutOrganizationInput>;
  LiveTransferSetting?: InputMaybe<LiveTransferSettingUpdateOneWithoutOrganizationInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusUpdateManyWithoutOrganizationInput>;
  ltv_multiplier_custom_fields?: InputMaybe<LifeTimeValueMultiplierCustomUpdateManyWithoutOrganizationInput>;
  made_contact_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  make_sale_address_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_business_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_city_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_country_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_first_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_industry_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_last_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_lead_source_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_primary_email_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_primary_phone_number_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_state_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_sub_industry_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_zip_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  maxium_day_lag_set_to_schedule?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  mrr?: InputMaybe<IntFieldUpdateOperationsInput>;
  mrr_label?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  mrr_required_on_sale?: InputMaybe<BoolFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  NoteItems?: InputMaybe<NoteItemUpdateManyWithoutOrganizationInput>;
  Notifications?: InputMaybe<NotificationUpdateManyWithoutOrganizationInput>;
  onboardComplete?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  Opportunity?: InputMaybe<SfOpportunityUpdateManyWithoutOrganizationInput>;
  organization_holidays?: InputMaybe<OrganizationHolidayUpdateManyWithoutOrganizationInput>;
  organization_hours?: InputMaybe<OrganizationHourUpdateManyWithoutOrganizationInput>;
  OrganizationPaymentSetting?: InputMaybe<OrganizationPaymentSettingsUpdateOneWithoutOrganizationInput>;
  OrganizationPaymentSettings?: InputMaybe<OrganizationPaymentSettingsUpdateOneWithoutOrganizationsInput>;
  orgCalendarInviteMetadata?: InputMaybe<OrgCalendarInviteMetadataUpdateManyWithoutOrganizationInput>;
  OrgDefaultPipelinePerecentValue?: InputMaybe<OrgDefaultPipelinePerecentValueUpdateOneWithoutOrganizationInput>;
  OrgHubSpotSync?: InputMaybe<OrgHubSpotSyncUpdateOneWithoutOrganizationInput>;
  OrgNoEventDate?: InputMaybe<OrgNoEventDateUpdateManyWithoutOrganizationInput>;
  OrgSalesforceSync?: InputMaybe<OrgSalesforceSyncUpdateOneWithoutOrganizationInput>;
  OrgTimeslotAvailability?: InputMaybe<OrgTimeslotAvailabilityUpdateManyWithoutOrganizationInput>;
  outbound_close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_dm_connect_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_lt_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_revenue_value_of_sale?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_scheduled_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_set_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  PandadocData?: InputMaybe<PandadocDataUpdateOneWithoutOrganizationInput>;
  PandaDocTemplate?: InputMaybe<PandaDocTemplateUpdateManyWithoutOrganizationInput>;
  participant_jitter_buffer_size?: InputMaybe<EnumJitterBufferSizeFieldUpdateOperationsInput>;
  preferred_goal_setting?: InputMaybe<NullableEnumGoalsettingFieldUpdateOperationsInput>;
  prepayment?: InputMaybe<BoolFieldUpdateOperationsInput>;
  ProductChangeLog?: InputMaybe<ProductChangeLogUpdateManyWithoutOrganizationInput>;
  products?: InputMaybe<ProductUpdateManyWithoutOrganizationInput>;
  re_cold_call_hour_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  recordingStatus?: InputMaybe<EnumRecordingStatusFieldUpdateOperationsInput>;
  release_lead_resting?: InputMaybe<IntFieldUpdateOperationsInput>;
  rep_claim_limit?: InputMaybe<IntFieldUpdateOperationsInput>;
  rep_lead_allotment?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  rep_level_call_transfer?: InputMaybe<BoolFieldUpdateOperationsInput>;
  reps_can_bulk_unassign?: InputMaybe<BoolFieldUpdateOperationsInput>;
  reps_can_view_all_notes?: InputMaybe<BoolFieldUpdateOperationsInput>;
  require_sale_notes?: InputMaybe<BoolFieldUpdateOperationsInput>;
  resting_after_skipped_CC?: InputMaybe<IntFieldUpdateOperationsInput>;
  resting_period?: InputMaybe<IntFieldUpdateOperationsInput>;
  resting_period_after_unsuccessful_cold_call?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  resting_period_after_unsuccessful_demo?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  revenue_plan?: InputMaybe<RevenuePlanItemUpdateManyWithoutOrganizationInput>;
  Routings?: InputMaybe<RoutingUpdateManyWithoutOrganizationInput>;
  sale_field_options?: InputMaybe<SaleFieldOptionUpdateManyWithoutOrganizationInput>;
  sale_notification_email_list?: InputMaybe<OrganizationUpdatesale_Notification_Email_ListInput>;
  SaleConfig?: InputMaybe<SaleConfigUpdateOneWithoutOrganizationInput>;
  SaleConfigPage?: InputMaybe<SaleConfigPageUpdateManyWithoutOrganizationInput>;
  SaleConfigSection?: InputMaybe<SaleConfigSectionUpdateManyWithoutOrganizationInput>;
  SaleCycles?: InputMaybe<SaleCycleUpdateManyWithoutOrganizationInput>;
  SaleItems?: InputMaybe<SaleItemUpdateManyWithoutOrganizationInput>;
  sales?: InputMaybe<SaleUpdateManyWithoutOrganizationInput>;
  salesforce_domain?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  salesforce_last_sync_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  SalesForceColumn?: InputMaybe<SalesForceColumnUpdateManyWithoutOrganizationInput>;
  salesForceData?: InputMaybe<SalesForceDataUpdateOneWithoutOrganizationInput>;
  ScheduleItems?: InputMaybe<ScheduleItemUpdateManyWithoutOrganizationInput>;
  self_sourcing_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  send_error_log_email?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  send_lead_routing_email?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sendgrid_email_domain?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sendgrid_email_domain_id?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  sequence?: InputMaybe<SequenceUpdateManyWithoutOrganzationInput>;
  SequenceCriteriaConditions?: InputMaybe<SequenceCriteriaConditionUpdateManyWithoutOrganizationInput>;
  SequenceDashboardViews?: InputMaybe<SequenceDashboardViewUpdateManyWithoutOrganizationInput>;
  SequenceEntryCriterias?: InputMaybe<SequenceEntryCriteriaUpdateManyWithoutOrganizationInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutOrganizationInput>;
  SequenceSteps?: InputMaybe<SequenceStepUpdateManyWithoutOrganizationInput>;
  set_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
  short_resting?: InputMaybe<IntFieldUpdateOperationsInput>;
  show_calendar_options?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_contract_duration_sales?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_lead_source_to_rep?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_nocontact_button?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_payment_terms_sales?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_rep_all_leads?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_rep_my_leads?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_system_view?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sites?: InputMaybe<SiteUpdateManyWithoutOrganizationInput>;
  sms_opt_in_dialog?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SMSItems?: InputMaybe<SmsItemUpdateManyWithoutOrganizationInput>;
  SnoozeTrackings?: InputMaybe<SnoozeTrackingUpdateManyWithoutOrganizationInput>;
  StepActionLogs?: InputMaybe<StepActionLogUpdateManyWithoutOrganizationInput>;
  StepActions?: InputMaybe<StepActionUpdateManyWithoutOrganizationInput>;
  StepConditions?: InputMaybe<StepConditionUpdateManyWithoutOrganizationInput>;
  teams?: InputMaybe<TeamUpdateManyWithoutOrganizationInput>;
  Template?: InputMaybe<TemplateUpdateManyWithoutOrganizationInput>;
  TemplateVersions?: InputMaybe<TemplateVersionUpdateManyWithoutOrganizationInput>;
  timekit_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timezone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transfer_timeout?: InputMaybe<IntFieldUpdateOperationsInput>;
  TransferAttempts?: InputMaybe<TransferAttemptUpdateManyWithoutOrganizationInput>;
  twilio_numbers?: InputMaybe<TwilioNumberUpdateManyWithoutOrganizationInput>;
  TwilioFeedbacks?: InputMaybe<TwilioFeedbackUpdateManyWithoutOrganizationInput>;
  TwilioInfo?: InputMaybe<TwilioInfoUpdateOneWithoutOrganzationInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  use_revenue_planning?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  UserCustomFieldFilters?: InputMaybe<UserCustomFieldFilterUpdateManyWithoutOrganizationInput>;
  users?: InputMaybe<UserUpdateManyWithoutOrganizationInput>;
  win_rep_day_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
};

export type OrganizationUpdateWithoutEmailThreadsInput = {
  ActivityMonitorMultiplier?: InputMaybe<ActivityMonitorMultiplierUpdateManyWithoutOrganizationInput>;
  algoFactor?: InputMaybe<AlgoFactorUpdateManyWithoutOrganizationInput>;
  allow_calendar_invite?: InputMaybe<BoolFieldUpdateOperationsInput>;
  allow_remove_step_action?: InputMaybe<BoolFieldUpdateOperationsInput>;
  allow_snooze_step_action?: InputMaybe<BoolFieldUpdateOperationsInput>;
  always_record?: InputMaybe<BoolFieldUpdateOperationsInput>;
  arv_initial_held_demos_generated_by_sdr_weight?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  AssociationHistories?: InputMaybe<AssociationHistoryUpdateManyWithoutOrganizationInput>;
  auto_call_interval?: InputMaybe<IntFieldUpdateOperationsInput>;
  auto_dial?: InputMaybe<BoolFieldUpdateOperationsInput>;
  automated_campaigns?: InputMaybe<AutomatedCampaignUpdateManyWithoutOrganizationInput>;
  automatic_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  AutoTransferSelections?: InputMaybe<AutoTransferSelectionUpdateManyWithoutOrganizationInput>;
  Availablities?: InputMaybe<AvailabilityUpdateManyWithoutOrganizationInput>;
  AverageOrderValue?: InputMaybe<AverageOrderValueUpdateManyWithoutOrganizationInput>;
  best_action_ever_multiplier?: InputMaybe<FloatFieldUpdateOperationsInput>;
  best_metrics?: InputMaybe<BestMetricRecordUpdateManyWithoutOrganizationInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptUpdateManyWithoutOrganizationInput>;
  callMeNows?: InputMaybe<CallMeNowUpdateManyWithoutOrganizationInput>;
  CallMultiplier?: InputMaybe<CallMultiplierUpdateManyWithoutOrganizationInput>;
  CallSharings?: InputMaybe<CallSharingUpdateManyWithoutOrganizationInput>;
  can_edit_sdr_transfer_rules?: InputMaybe<BoolFieldUpdateOperationsInput>;
  claim_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  concession?: InputMaybe<ConcessionUpdateManyWithoutOrganizationInput>;
  conference_region?: InputMaybe<EnumConferenceRegionFieldUpdateOperationsInput>;
  Conferences?: InputMaybe<ConferenceUpdateManyWithoutOrganizationInput>;
  ContactRequests?: InputMaybe<ContactRequestUpdateManyWithoutOrganizationInput>;
  Contacts?: InputMaybe<ContactUpdateManyWithoutOrganizationInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  custom_fields?: InputMaybe<CustomFieldUpdateManyWithoutOrganizationInput>;
  CustomObject?: InputMaybe<CustomObjectUpdateManyWithoutOrganizationInput>;
  CustomObjectField?: InputMaybe<CustomObjectFieldUpdateManyWithoutOrganizationInput>;
  CustomObjectFieldForeignKey?: InputMaybe<CustomObjectFieldForeignKeyUpdateManyWithoutOrganizationInput>;
  CustomObjectRecord?: InputMaybe<CustomObjectRecordUpdateManyWithoutOrganizationInput>;
  CustomObjectRow?: InputMaybe<CustomObjectRowUpdateManyWithoutOrganizationInput>;
  CustomQueueItems?: InputMaybe<CustomQueueItemUpdateManyWithoutOrganizationInput>;
  DashboardColumnOptions?: InputMaybe<DashboardColumnOptionUpdateManyWithoutOrganizationInput>;
  DashboardLeadFilters?: InputMaybe<DashboardLeadFilterUpdateManyWithoutOrganizationInput>;
  DashboardRepFilters?: InputMaybe<DashboardRepFilterUpdateManyWithoutOrganizationInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewUpdateManyWithoutOrganizationInput>;
  day_to_close?: InputMaybe<IntFieldUpdateOperationsInput>;
  default_demo_time_duration?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  demo_held_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_not_held_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_not_held_call_back_short_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_prep_time_minutes?: InputMaybe<IntFieldUpdateOperationsInput>;
  dev_account?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  dial_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  DispositionMultiplier?: InputMaybe<DispositionMultiplierUpdateManyWithoutOrganizationInput>;
  dispositions?: InputMaybe<DispositionUpdateManyWithoutOrganizationInput>;
  double_booking?: InputMaybe<BoolFieldUpdateOperationsInput>;
  dynamic_date_operators?: InputMaybe<BoolFieldUpdateOperationsInput>;
  edge_servers?: InputMaybe<OrganizationUpdateedge_ServersInput>;
  email_conflict_notification_email_list?: InputMaybe<OrganizationUpdateemail_Conflict_Notification_Email_ListInput>;
  EmailItems?: InputMaybe<EmailItemUpdateManyWithoutOrganizationInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordUpdateManyWithoutOrganizationInput>;
  enable_modified_metrics?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  enable_pay_period_view?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  enable_time_constraint?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  error_log_email_address?: InputMaybe<OrganizationUpdateerror_Log_Email_AddressInput>;
  estimated_average_num_of_weekly_overbooked_demo_slots?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  FolderPermissions?: InputMaybe<FolderPermissionUpdateManyWithoutOrganizationInput>;
  Folders?: InputMaybe<FolderUpdateManyWithoutOrganizationInput>;
  FutureBookingSetting?: InputMaybe<FutureBookingSettingUpdateOneWithoutOrganizationInput>;
  GlobalTransferRule?: InputMaybe<GlobalTransferRuleUpdateManyWithoutOrganizationInput>;
  held_demos_per_cc_connect?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  helpful_links?: InputMaybe<OrganizationUpdatehelpful_LinksInput>;
  HingePointMultiplier?: InputMaybe<HingePointMultiplierUpdateManyWithoutOrganizationInput>;
  hold_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
  hubspot_credential?: InputMaybe<HubSpotCredentialUpdateOneWithoutOrganizationInput>;
  hubspot_last_sync_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  hubspot_white_list?: InputMaybe<HubSpotWhitelistUpdateManyWithoutOrganizationInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inbound_close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_dm_connect_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_lt_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_revenue_value_of_sale?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_scheduled_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_set_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventUpdateManyWithoutOrganizationInput>;
  InboundConciergeWidget?: InputMaybe<InboundConciergeWidgetUpdateManyWithoutOrganizationInput>;
  industry_options?: InputMaybe<IndustryOptionUpdateManyWithoutOrganizationInput>;
  initial_sdr_sets_reps_day?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  integration_error_logs?: InputMaybe<IntegrationErrorLogUpdateManyWithoutOrganizationInput>;
  integration_mappings?: InputMaybe<IntegrationMappingUpdateManyWithoutOrganizationInput>;
  IntegrationMappingCustomObject?: InputMaybe<IntegrationMappingCustomObjectUpdateManyWithoutOrganizationInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogUpdateManyWithoutOrganizationInput>;
  IntegrationReadOnlyObject?: InputMaybe<IntegrationReadOnlyObjectUpdateManyWithoutOrganizationInput>;
  international?: InputMaybe<BoolFieldUpdateOperationsInput>;
  is_email_domain_verified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  is_salesforce_sandbox?: InputMaybe<BoolFieldUpdateOperationsInput>;
  last_lead_value_compute_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutOrganizationInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryUpdateManyWithoutOrganizationInput>;
  lead_intent?: InputMaybe<LeadIntentUpdateManyWithoutOrganizationInput>;
  lead_release_same_rep_delay_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  lead_routing_email_list?: InputMaybe<OrganizationUpdatelead_Routing_Email_ListInput>;
  lead_routing_rules?: InputMaybe<RuleUpdateManyWithoutOrganizationInput>;
  LeadCustomObjectRowAssociation?: InputMaybe<LeadCustomObjectRowAssociationUpdateManyWithoutOrganizationInput>;
  LeadFieldMultiplier?: InputMaybe<LeadFieldMultiplierUpdateManyWithoutOrganizationInput>;
  LeadFieldMultiplierCustom?: InputMaybe<LeadFieldMultiplierCustomUpdateManyWithoutOrganizationInput>;
  leads?: InputMaybe<LeadUpdateManyWithoutOrganizationInput>;
  leadsource_options?: InputMaybe<LeadSourceOptionUpdateManyWithoutOrganizationInput>;
  LeadValue?: InputMaybe<LeadValueUpdateManyWithoutOrganizationInput>;
  LifeTimeValueMultiplier?: InputMaybe<LifeTimeValueMultiplierUpdateManyWithoutOrganizationInput>;
  LiveTransferSetting?: InputMaybe<LiveTransferSettingUpdateOneWithoutOrganizationInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusUpdateManyWithoutOrganizationInput>;
  ltv_multiplier_custom_fields?: InputMaybe<LifeTimeValueMultiplierCustomUpdateManyWithoutOrganizationInput>;
  made_contact_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  make_sale_address_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_business_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_city_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_country_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_first_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_industry_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_last_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_lead_source_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_primary_email_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_primary_phone_number_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_state_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_sub_industry_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_zip_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  maxium_day_lag_set_to_schedule?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  mrr?: InputMaybe<IntFieldUpdateOperationsInput>;
  mrr_label?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  mrr_required_on_sale?: InputMaybe<BoolFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  NoteItems?: InputMaybe<NoteItemUpdateManyWithoutOrganizationInput>;
  Notifications?: InputMaybe<NotificationUpdateManyWithoutOrganizationInput>;
  onboardComplete?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  Opportunity?: InputMaybe<SfOpportunityUpdateManyWithoutOrganizationInput>;
  organization_holidays?: InputMaybe<OrganizationHolidayUpdateManyWithoutOrganizationInput>;
  organization_hours?: InputMaybe<OrganizationHourUpdateManyWithoutOrganizationInput>;
  OrganizationPaymentSetting?: InputMaybe<OrganizationPaymentSettingsUpdateOneWithoutOrganizationInput>;
  OrganizationPaymentSettings?: InputMaybe<OrganizationPaymentSettingsUpdateOneWithoutOrganizationsInput>;
  orgCalendarInviteMetadata?: InputMaybe<OrgCalendarInviteMetadataUpdateManyWithoutOrganizationInput>;
  OrgDefaultPipelinePerecentValue?: InputMaybe<OrgDefaultPipelinePerecentValueUpdateOneWithoutOrganizationInput>;
  OrgHubSpotSync?: InputMaybe<OrgHubSpotSyncUpdateOneWithoutOrganizationInput>;
  OrgNoEventDate?: InputMaybe<OrgNoEventDateUpdateManyWithoutOrganizationInput>;
  OrgSalesforceSync?: InputMaybe<OrgSalesforceSyncUpdateOneWithoutOrganizationInput>;
  OrgTimeslotAvailability?: InputMaybe<OrgTimeslotAvailabilityUpdateManyWithoutOrganizationInput>;
  outbound_close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_dm_connect_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_lt_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_revenue_value_of_sale?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_scheduled_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_set_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  PandadocData?: InputMaybe<PandadocDataUpdateOneWithoutOrganizationInput>;
  PandaDocTemplate?: InputMaybe<PandaDocTemplateUpdateManyWithoutOrganizationInput>;
  participant_jitter_buffer_size?: InputMaybe<EnumJitterBufferSizeFieldUpdateOperationsInput>;
  preferred_goal_setting?: InputMaybe<NullableEnumGoalsettingFieldUpdateOperationsInput>;
  prepayment?: InputMaybe<BoolFieldUpdateOperationsInput>;
  ProductChangeLog?: InputMaybe<ProductChangeLogUpdateManyWithoutOrganizationInput>;
  products?: InputMaybe<ProductUpdateManyWithoutOrganizationInput>;
  re_cold_call_hour_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  recordingStatus?: InputMaybe<EnumRecordingStatusFieldUpdateOperationsInput>;
  release_lead_resting?: InputMaybe<IntFieldUpdateOperationsInput>;
  rep_claim_limit?: InputMaybe<IntFieldUpdateOperationsInput>;
  rep_lead_allotment?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  rep_level_call_transfer?: InputMaybe<BoolFieldUpdateOperationsInput>;
  reps_can_bulk_unassign?: InputMaybe<BoolFieldUpdateOperationsInput>;
  reps_can_view_all_notes?: InputMaybe<BoolFieldUpdateOperationsInput>;
  require_sale_notes?: InputMaybe<BoolFieldUpdateOperationsInput>;
  resting_after_skipped_CC?: InputMaybe<IntFieldUpdateOperationsInput>;
  resting_period?: InputMaybe<IntFieldUpdateOperationsInput>;
  resting_period_after_unsuccessful_cold_call?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  resting_period_after_unsuccessful_demo?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  revenue_plan?: InputMaybe<RevenuePlanItemUpdateManyWithoutOrganizationInput>;
  Routings?: InputMaybe<RoutingUpdateManyWithoutOrganizationInput>;
  sale_field_options?: InputMaybe<SaleFieldOptionUpdateManyWithoutOrganizationInput>;
  sale_notification_email_list?: InputMaybe<OrganizationUpdatesale_Notification_Email_ListInput>;
  SaleConfig?: InputMaybe<SaleConfigUpdateOneWithoutOrganizationInput>;
  SaleConfigPage?: InputMaybe<SaleConfigPageUpdateManyWithoutOrganizationInput>;
  SaleConfigSection?: InputMaybe<SaleConfigSectionUpdateManyWithoutOrganizationInput>;
  SaleCycles?: InputMaybe<SaleCycleUpdateManyWithoutOrganizationInput>;
  SaleItems?: InputMaybe<SaleItemUpdateManyWithoutOrganizationInput>;
  sales?: InputMaybe<SaleUpdateManyWithoutOrganizationInput>;
  salesforce_domain?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  salesforce_last_sync_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  SalesForceColumn?: InputMaybe<SalesForceColumnUpdateManyWithoutOrganizationInput>;
  salesForceData?: InputMaybe<SalesForceDataUpdateOneWithoutOrganizationInput>;
  ScheduleItems?: InputMaybe<ScheduleItemUpdateManyWithoutOrganizationInput>;
  self_sourcing_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  send_error_log_email?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  send_lead_routing_email?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sendgrid_email_domain?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sendgrid_email_domain_id?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  sequence?: InputMaybe<SequenceUpdateManyWithoutOrganzationInput>;
  SequenceCriteriaConditions?: InputMaybe<SequenceCriteriaConditionUpdateManyWithoutOrganizationInput>;
  SequenceDashboardViews?: InputMaybe<SequenceDashboardViewUpdateManyWithoutOrganizationInput>;
  SequenceEntryCriterias?: InputMaybe<SequenceEntryCriteriaUpdateManyWithoutOrganizationInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutOrganizationInput>;
  SequenceSteps?: InputMaybe<SequenceStepUpdateManyWithoutOrganizationInput>;
  set_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
  short_resting?: InputMaybe<IntFieldUpdateOperationsInput>;
  show_calendar_options?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_contract_duration_sales?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_lead_source_to_rep?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_nocontact_button?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_payment_terms_sales?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_rep_all_leads?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_rep_my_leads?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_system_view?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sites?: InputMaybe<SiteUpdateManyWithoutOrganizationInput>;
  sms_opt_in_dialog?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SMSItems?: InputMaybe<SmsItemUpdateManyWithoutOrganizationInput>;
  SnoozeTrackings?: InputMaybe<SnoozeTrackingUpdateManyWithoutOrganizationInput>;
  StepActionLogs?: InputMaybe<StepActionLogUpdateManyWithoutOrganizationInput>;
  StepActions?: InputMaybe<StepActionUpdateManyWithoutOrganizationInput>;
  StepConditions?: InputMaybe<StepConditionUpdateManyWithoutOrganizationInput>;
  teams?: InputMaybe<TeamUpdateManyWithoutOrganizationInput>;
  Template?: InputMaybe<TemplateUpdateManyWithoutOrganizationInput>;
  TemplateVersions?: InputMaybe<TemplateVersionUpdateManyWithoutOrganizationInput>;
  timekit_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timezone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transfer_timeout?: InputMaybe<IntFieldUpdateOperationsInput>;
  TransferAttempts?: InputMaybe<TransferAttemptUpdateManyWithoutOrganizationInput>;
  twilio_numbers?: InputMaybe<TwilioNumberUpdateManyWithoutOrganizationInput>;
  TwilioFeedbacks?: InputMaybe<TwilioFeedbackUpdateManyWithoutOrganizationInput>;
  TwilioInfo?: InputMaybe<TwilioInfoUpdateOneWithoutOrganzationInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  use_revenue_planning?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  UserCustomFieldFilters?: InputMaybe<UserCustomFieldFilterUpdateManyWithoutOrganizationInput>;
  users?: InputMaybe<UserUpdateManyWithoutOrganizationInput>;
  win_rep_day_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
};

export type OrganizationUpdateWithoutFolderPermissionsInput = {
  ActivityMonitorMultiplier?: InputMaybe<ActivityMonitorMultiplierUpdateManyWithoutOrganizationInput>;
  algoFactor?: InputMaybe<AlgoFactorUpdateManyWithoutOrganizationInput>;
  allow_calendar_invite?: InputMaybe<BoolFieldUpdateOperationsInput>;
  allow_remove_step_action?: InputMaybe<BoolFieldUpdateOperationsInput>;
  allow_snooze_step_action?: InputMaybe<BoolFieldUpdateOperationsInput>;
  always_record?: InputMaybe<BoolFieldUpdateOperationsInput>;
  arv_initial_held_demos_generated_by_sdr_weight?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  AssociationHistories?: InputMaybe<AssociationHistoryUpdateManyWithoutOrganizationInput>;
  auto_call_interval?: InputMaybe<IntFieldUpdateOperationsInput>;
  auto_dial?: InputMaybe<BoolFieldUpdateOperationsInput>;
  automated_campaigns?: InputMaybe<AutomatedCampaignUpdateManyWithoutOrganizationInput>;
  automatic_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  AutoTransferSelections?: InputMaybe<AutoTransferSelectionUpdateManyWithoutOrganizationInput>;
  Availablities?: InputMaybe<AvailabilityUpdateManyWithoutOrganizationInput>;
  AverageOrderValue?: InputMaybe<AverageOrderValueUpdateManyWithoutOrganizationInput>;
  best_action_ever_multiplier?: InputMaybe<FloatFieldUpdateOperationsInput>;
  best_metrics?: InputMaybe<BestMetricRecordUpdateManyWithoutOrganizationInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptUpdateManyWithoutOrganizationInput>;
  callMeNows?: InputMaybe<CallMeNowUpdateManyWithoutOrganizationInput>;
  CallMultiplier?: InputMaybe<CallMultiplierUpdateManyWithoutOrganizationInput>;
  CallSharings?: InputMaybe<CallSharingUpdateManyWithoutOrganizationInput>;
  can_edit_sdr_transfer_rules?: InputMaybe<BoolFieldUpdateOperationsInput>;
  claim_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  concession?: InputMaybe<ConcessionUpdateManyWithoutOrganizationInput>;
  conference_region?: InputMaybe<EnumConferenceRegionFieldUpdateOperationsInput>;
  Conferences?: InputMaybe<ConferenceUpdateManyWithoutOrganizationInput>;
  ContactRequests?: InputMaybe<ContactRequestUpdateManyWithoutOrganizationInput>;
  Contacts?: InputMaybe<ContactUpdateManyWithoutOrganizationInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  custom_fields?: InputMaybe<CustomFieldUpdateManyWithoutOrganizationInput>;
  CustomObject?: InputMaybe<CustomObjectUpdateManyWithoutOrganizationInput>;
  CustomObjectField?: InputMaybe<CustomObjectFieldUpdateManyWithoutOrganizationInput>;
  CustomObjectFieldForeignKey?: InputMaybe<CustomObjectFieldForeignKeyUpdateManyWithoutOrganizationInput>;
  CustomObjectRecord?: InputMaybe<CustomObjectRecordUpdateManyWithoutOrganizationInput>;
  CustomObjectRow?: InputMaybe<CustomObjectRowUpdateManyWithoutOrganizationInput>;
  CustomQueueItems?: InputMaybe<CustomQueueItemUpdateManyWithoutOrganizationInput>;
  DashboardColumnOptions?: InputMaybe<DashboardColumnOptionUpdateManyWithoutOrganizationInput>;
  DashboardLeadFilters?: InputMaybe<DashboardLeadFilterUpdateManyWithoutOrganizationInput>;
  DashboardRepFilters?: InputMaybe<DashboardRepFilterUpdateManyWithoutOrganizationInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewUpdateManyWithoutOrganizationInput>;
  day_to_close?: InputMaybe<IntFieldUpdateOperationsInput>;
  default_demo_time_duration?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  demo_held_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_not_held_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_not_held_call_back_short_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_prep_time_minutes?: InputMaybe<IntFieldUpdateOperationsInput>;
  dev_account?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  dial_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  DispositionMultiplier?: InputMaybe<DispositionMultiplierUpdateManyWithoutOrganizationInput>;
  dispositions?: InputMaybe<DispositionUpdateManyWithoutOrganizationInput>;
  double_booking?: InputMaybe<BoolFieldUpdateOperationsInput>;
  dynamic_date_operators?: InputMaybe<BoolFieldUpdateOperationsInput>;
  edge_servers?: InputMaybe<OrganizationUpdateedge_ServersInput>;
  email_conflict_notification_email_list?: InputMaybe<OrganizationUpdateemail_Conflict_Notification_Email_ListInput>;
  EmailItems?: InputMaybe<EmailItemUpdateManyWithoutOrganizationInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordUpdateManyWithoutOrganizationInput>;
  EmailThreads?: InputMaybe<EmailThreadUpdateManyWithoutOrganizationInput>;
  enable_modified_metrics?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  enable_pay_period_view?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  enable_time_constraint?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  error_log_email_address?: InputMaybe<OrganizationUpdateerror_Log_Email_AddressInput>;
  estimated_average_num_of_weekly_overbooked_demo_slots?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  Folders?: InputMaybe<FolderUpdateManyWithoutOrganizationInput>;
  FutureBookingSetting?: InputMaybe<FutureBookingSettingUpdateOneWithoutOrganizationInput>;
  GlobalTransferRule?: InputMaybe<GlobalTransferRuleUpdateManyWithoutOrganizationInput>;
  held_demos_per_cc_connect?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  helpful_links?: InputMaybe<OrganizationUpdatehelpful_LinksInput>;
  HingePointMultiplier?: InputMaybe<HingePointMultiplierUpdateManyWithoutOrganizationInput>;
  hold_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
  hubspot_credential?: InputMaybe<HubSpotCredentialUpdateOneWithoutOrganizationInput>;
  hubspot_last_sync_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  hubspot_white_list?: InputMaybe<HubSpotWhitelistUpdateManyWithoutOrganizationInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inbound_close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_dm_connect_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_lt_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_revenue_value_of_sale?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_scheduled_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_set_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventUpdateManyWithoutOrganizationInput>;
  InboundConciergeWidget?: InputMaybe<InboundConciergeWidgetUpdateManyWithoutOrganizationInput>;
  industry_options?: InputMaybe<IndustryOptionUpdateManyWithoutOrganizationInput>;
  initial_sdr_sets_reps_day?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  integration_error_logs?: InputMaybe<IntegrationErrorLogUpdateManyWithoutOrganizationInput>;
  integration_mappings?: InputMaybe<IntegrationMappingUpdateManyWithoutOrganizationInput>;
  IntegrationMappingCustomObject?: InputMaybe<IntegrationMappingCustomObjectUpdateManyWithoutOrganizationInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogUpdateManyWithoutOrganizationInput>;
  IntegrationReadOnlyObject?: InputMaybe<IntegrationReadOnlyObjectUpdateManyWithoutOrganizationInput>;
  international?: InputMaybe<BoolFieldUpdateOperationsInput>;
  is_email_domain_verified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  is_salesforce_sandbox?: InputMaybe<BoolFieldUpdateOperationsInput>;
  last_lead_value_compute_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutOrganizationInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryUpdateManyWithoutOrganizationInput>;
  lead_intent?: InputMaybe<LeadIntentUpdateManyWithoutOrganizationInput>;
  lead_release_same_rep_delay_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  lead_routing_email_list?: InputMaybe<OrganizationUpdatelead_Routing_Email_ListInput>;
  lead_routing_rules?: InputMaybe<RuleUpdateManyWithoutOrganizationInput>;
  LeadCustomObjectRowAssociation?: InputMaybe<LeadCustomObjectRowAssociationUpdateManyWithoutOrganizationInput>;
  LeadFieldMultiplier?: InputMaybe<LeadFieldMultiplierUpdateManyWithoutOrganizationInput>;
  LeadFieldMultiplierCustom?: InputMaybe<LeadFieldMultiplierCustomUpdateManyWithoutOrganizationInput>;
  leads?: InputMaybe<LeadUpdateManyWithoutOrganizationInput>;
  leadsource_options?: InputMaybe<LeadSourceOptionUpdateManyWithoutOrganizationInput>;
  LeadValue?: InputMaybe<LeadValueUpdateManyWithoutOrganizationInput>;
  LifeTimeValueMultiplier?: InputMaybe<LifeTimeValueMultiplierUpdateManyWithoutOrganizationInput>;
  LiveTransferSetting?: InputMaybe<LiveTransferSettingUpdateOneWithoutOrganizationInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusUpdateManyWithoutOrganizationInput>;
  ltv_multiplier_custom_fields?: InputMaybe<LifeTimeValueMultiplierCustomUpdateManyWithoutOrganizationInput>;
  made_contact_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  make_sale_address_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_business_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_city_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_country_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_first_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_industry_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_last_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_lead_source_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_primary_email_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_primary_phone_number_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_state_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_sub_industry_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_zip_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  maxium_day_lag_set_to_schedule?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  mrr?: InputMaybe<IntFieldUpdateOperationsInput>;
  mrr_label?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  mrr_required_on_sale?: InputMaybe<BoolFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  NoteItems?: InputMaybe<NoteItemUpdateManyWithoutOrganizationInput>;
  Notifications?: InputMaybe<NotificationUpdateManyWithoutOrganizationInput>;
  onboardComplete?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  Opportunity?: InputMaybe<SfOpportunityUpdateManyWithoutOrganizationInput>;
  organization_holidays?: InputMaybe<OrganizationHolidayUpdateManyWithoutOrganizationInput>;
  organization_hours?: InputMaybe<OrganizationHourUpdateManyWithoutOrganizationInput>;
  OrganizationPaymentSetting?: InputMaybe<OrganizationPaymentSettingsUpdateOneWithoutOrganizationInput>;
  OrganizationPaymentSettings?: InputMaybe<OrganizationPaymentSettingsUpdateOneWithoutOrganizationsInput>;
  orgCalendarInviteMetadata?: InputMaybe<OrgCalendarInviteMetadataUpdateManyWithoutOrganizationInput>;
  OrgDefaultPipelinePerecentValue?: InputMaybe<OrgDefaultPipelinePerecentValueUpdateOneWithoutOrganizationInput>;
  OrgHubSpotSync?: InputMaybe<OrgHubSpotSyncUpdateOneWithoutOrganizationInput>;
  OrgNoEventDate?: InputMaybe<OrgNoEventDateUpdateManyWithoutOrganizationInput>;
  OrgSalesforceSync?: InputMaybe<OrgSalesforceSyncUpdateOneWithoutOrganizationInput>;
  OrgTimeslotAvailability?: InputMaybe<OrgTimeslotAvailabilityUpdateManyWithoutOrganizationInput>;
  outbound_close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_dm_connect_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_lt_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_revenue_value_of_sale?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_scheduled_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_set_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  PandadocData?: InputMaybe<PandadocDataUpdateOneWithoutOrganizationInput>;
  PandaDocTemplate?: InputMaybe<PandaDocTemplateUpdateManyWithoutOrganizationInput>;
  participant_jitter_buffer_size?: InputMaybe<EnumJitterBufferSizeFieldUpdateOperationsInput>;
  preferred_goal_setting?: InputMaybe<NullableEnumGoalsettingFieldUpdateOperationsInput>;
  prepayment?: InputMaybe<BoolFieldUpdateOperationsInput>;
  ProductChangeLog?: InputMaybe<ProductChangeLogUpdateManyWithoutOrganizationInput>;
  products?: InputMaybe<ProductUpdateManyWithoutOrganizationInput>;
  re_cold_call_hour_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  recordingStatus?: InputMaybe<EnumRecordingStatusFieldUpdateOperationsInput>;
  release_lead_resting?: InputMaybe<IntFieldUpdateOperationsInput>;
  rep_claim_limit?: InputMaybe<IntFieldUpdateOperationsInput>;
  rep_lead_allotment?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  rep_level_call_transfer?: InputMaybe<BoolFieldUpdateOperationsInput>;
  reps_can_bulk_unassign?: InputMaybe<BoolFieldUpdateOperationsInput>;
  reps_can_view_all_notes?: InputMaybe<BoolFieldUpdateOperationsInput>;
  require_sale_notes?: InputMaybe<BoolFieldUpdateOperationsInput>;
  resting_after_skipped_CC?: InputMaybe<IntFieldUpdateOperationsInput>;
  resting_period?: InputMaybe<IntFieldUpdateOperationsInput>;
  resting_period_after_unsuccessful_cold_call?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  resting_period_after_unsuccessful_demo?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  revenue_plan?: InputMaybe<RevenuePlanItemUpdateManyWithoutOrganizationInput>;
  Routings?: InputMaybe<RoutingUpdateManyWithoutOrganizationInput>;
  sale_field_options?: InputMaybe<SaleFieldOptionUpdateManyWithoutOrganizationInput>;
  sale_notification_email_list?: InputMaybe<OrganizationUpdatesale_Notification_Email_ListInput>;
  SaleConfig?: InputMaybe<SaleConfigUpdateOneWithoutOrganizationInput>;
  SaleConfigPage?: InputMaybe<SaleConfigPageUpdateManyWithoutOrganizationInput>;
  SaleConfigSection?: InputMaybe<SaleConfigSectionUpdateManyWithoutOrganizationInput>;
  SaleCycles?: InputMaybe<SaleCycleUpdateManyWithoutOrganizationInput>;
  SaleItems?: InputMaybe<SaleItemUpdateManyWithoutOrganizationInput>;
  sales?: InputMaybe<SaleUpdateManyWithoutOrganizationInput>;
  salesforce_domain?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  salesforce_last_sync_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  SalesForceColumn?: InputMaybe<SalesForceColumnUpdateManyWithoutOrganizationInput>;
  salesForceData?: InputMaybe<SalesForceDataUpdateOneWithoutOrganizationInput>;
  ScheduleItems?: InputMaybe<ScheduleItemUpdateManyWithoutOrganizationInput>;
  self_sourcing_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  send_error_log_email?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  send_lead_routing_email?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sendgrid_email_domain?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sendgrid_email_domain_id?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  sequence?: InputMaybe<SequenceUpdateManyWithoutOrganzationInput>;
  SequenceCriteriaConditions?: InputMaybe<SequenceCriteriaConditionUpdateManyWithoutOrganizationInput>;
  SequenceDashboardViews?: InputMaybe<SequenceDashboardViewUpdateManyWithoutOrganizationInput>;
  SequenceEntryCriterias?: InputMaybe<SequenceEntryCriteriaUpdateManyWithoutOrganizationInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutOrganizationInput>;
  SequenceSteps?: InputMaybe<SequenceStepUpdateManyWithoutOrganizationInput>;
  set_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
  short_resting?: InputMaybe<IntFieldUpdateOperationsInput>;
  show_calendar_options?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_contract_duration_sales?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_lead_source_to_rep?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_nocontact_button?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_payment_terms_sales?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_rep_all_leads?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_rep_my_leads?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_system_view?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sites?: InputMaybe<SiteUpdateManyWithoutOrganizationInput>;
  sms_opt_in_dialog?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SMSItems?: InputMaybe<SmsItemUpdateManyWithoutOrganizationInput>;
  SnoozeTrackings?: InputMaybe<SnoozeTrackingUpdateManyWithoutOrganizationInput>;
  StepActionLogs?: InputMaybe<StepActionLogUpdateManyWithoutOrganizationInput>;
  StepActions?: InputMaybe<StepActionUpdateManyWithoutOrganizationInput>;
  StepConditions?: InputMaybe<StepConditionUpdateManyWithoutOrganizationInput>;
  teams?: InputMaybe<TeamUpdateManyWithoutOrganizationInput>;
  Template?: InputMaybe<TemplateUpdateManyWithoutOrganizationInput>;
  TemplateVersions?: InputMaybe<TemplateVersionUpdateManyWithoutOrganizationInput>;
  timekit_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timezone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transfer_timeout?: InputMaybe<IntFieldUpdateOperationsInput>;
  TransferAttempts?: InputMaybe<TransferAttemptUpdateManyWithoutOrganizationInput>;
  twilio_numbers?: InputMaybe<TwilioNumberUpdateManyWithoutOrganizationInput>;
  TwilioFeedbacks?: InputMaybe<TwilioFeedbackUpdateManyWithoutOrganizationInput>;
  TwilioInfo?: InputMaybe<TwilioInfoUpdateOneWithoutOrganzationInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  use_revenue_planning?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  UserCustomFieldFilters?: InputMaybe<UserCustomFieldFilterUpdateManyWithoutOrganizationInput>;
  users?: InputMaybe<UserUpdateManyWithoutOrganizationInput>;
  win_rep_day_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
};

export type OrganizationUpdateWithoutFoldersInput = {
  ActivityMonitorMultiplier?: InputMaybe<ActivityMonitorMultiplierUpdateManyWithoutOrganizationInput>;
  algoFactor?: InputMaybe<AlgoFactorUpdateManyWithoutOrganizationInput>;
  allow_calendar_invite?: InputMaybe<BoolFieldUpdateOperationsInput>;
  allow_remove_step_action?: InputMaybe<BoolFieldUpdateOperationsInput>;
  allow_snooze_step_action?: InputMaybe<BoolFieldUpdateOperationsInput>;
  always_record?: InputMaybe<BoolFieldUpdateOperationsInput>;
  arv_initial_held_demos_generated_by_sdr_weight?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  AssociationHistories?: InputMaybe<AssociationHistoryUpdateManyWithoutOrganizationInput>;
  auto_call_interval?: InputMaybe<IntFieldUpdateOperationsInput>;
  auto_dial?: InputMaybe<BoolFieldUpdateOperationsInput>;
  automated_campaigns?: InputMaybe<AutomatedCampaignUpdateManyWithoutOrganizationInput>;
  automatic_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  AutoTransferSelections?: InputMaybe<AutoTransferSelectionUpdateManyWithoutOrganizationInput>;
  Availablities?: InputMaybe<AvailabilityUpdateManyWithoutOrganizationInput>;
  AverageOrderValue?: InputMaybe<AverageOrderValueUpdateManyWithoutOrganizationInput>;
  best_action_ever_multiplier?: InputMaybe<FloatFieldUpdateOperationsInput>;
  best_metrics?: InputMaybe<BestMetricRecordUpdateManyWithoutOrganizationInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptUpdateManyWithoutOrganizationInput>;
  callMeNows?: InputMaybe<CallMeNowUpdateManyWithoutOrganizationInput>;
  CallMultiplier?: InputMaybe<CallMultiplierUpdateManyWithoutOrganizationInput>;
  CallSharings?: InputMaybe<CallSharingUpdateManyWithoutOrganizationInput>;
  can_edit_sdr_transfer_rules?: InputMaybe<BoolFieldUpdateOperationsInput>;
  claim_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  concession?: InputMaybe<ConcessionUpdateManyWithoutOrganizationInput>;
  conference_region?: InputMaybe<EnumConferenceRegionFieldUpdateOperationsInput>;
  Conferences?: InputMaybe<ConferenceUpdateManyWithoutOrganizationInput>;
  ContactRequests?: InputMaybe<ContactRequestUpdateManyWithoutOrganizationInput>;
  Contacts?: InputMaybe<ContactUpdateManyWithoutOrganizationInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  custom_fields?: InputMaybe<CustomFieldUpdateManyWithoutOrganizationInput>;
  CustomObject?: InputMaybe<CustomObjectUpdateManyWithoutOrganizationInput>;
  CustomObjectField?: InputMaybe<CustomObjectFieldUpdateManyWithoutOrganizationInput>;
  CustomObjectFieldForeignKey?: InputMaybe<CustomObjectFieldForeignKeyUpdateManyWithoutOrganizationInput>;
  CustomObjectRecord?: InputMaybe<CustomObjectRecordUpdateManyWithoutOrganizationInput>;
  CustomObjectRow?: InputMaybe<CustomObjectRowUpdateManyWithoutOrganizationInput>;
  CustomQueueItems?: InputMaybe<CustomQueueItemUpdateManyWithoutOrganizationInput>;
  DashboardColumnOptions?: InputMaybe<DashboardColumnOptionUpdateManyWithoutOrganizationInput>;
  DashboardLeadFilters?: InputMaybe<DashboardLeadFilterUpdateManyWithoutOrganizationInput>;
  DashboardRepFilters?: InputMaybe<DashboardRepFilterUpdateManyWithoutOrganizationInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewUpdateManyWithoutOrganizationInput>;
  day_to_close?: InputMaybe<IntFieldUpdateOperationsInput>;
  default_demo_time_duration?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  demo_held_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_not_held_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_not_held_call_back_short_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_prep_time_minutes?: InputMaybe<IntFieldUpdateOperationsInput>;
  dev_account?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  dial_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  DispositionMultiplier?: InputMaybe<DispositionMultiplierUpdateManyWithoutOrganizationInput>;
  dispositions?: InputMaybe<DispositionUpdateManyWithoutOrganizationInput>;
  double_booking?: InputMaybe<BoolFieldUpdateOperationsInput>;
  dynamic_date_operators?: InputMaybe<BoolFieldUpdateOperationsInput>;
  edge_servers?: InputMaybe<OrganizationUpdateedge_ServersInput>;
  email_conflict_notification_email_list?: InputMaybe<OrganizationUpdateemail_Conflict_Notification_Email_ListInput>;
  EmailItems?: InputMaybe<EmailItemUpdateManyWithoutOrganizationInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordUpdateManyWithoutOrganizationInput>;
  EmailThreads?: InputMaybe<EmailThreadUpdateManyWithoutOrganizationInput>;
  enable_modified_metrics?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  enable_pay_period_view?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  enable_time_constraint?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  error_log_email_address?: InputMaybe<OrganizationUpdateerror_Log_Email_AddressInput>;
  estimated_average_num_of_weekly_overbooked_demo_slots?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  FolderPermissions?: InputMaybe<FolderPermissionUpdateManyWithoutOrganizationInput>;
  FutureBookingSetting?: InputMaybe<FutureBookingSettingUpdateOneWithoutOrganizationInput>;
  GlobalTransferRule?: InputMaybe<GlobalTransferRuleUpdateManyWithoutOrganizationInput>;
  held_demos_per_cc_connect?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  helpful_links?: InputMaybe<OrganizationUpdatehelpful_LinksInput>;
  HingePointMultiplier?: InputMaybe<HingePointMultiplierUpdateManyWithoutOrganizationInput>;
  hold_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
  hubspot_credential?: InputMaybe<HubSpotCredentialUpdateOneWithoutOrganizationInput>;
  hubspot_last_sync_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  hubspot_white_list?: InputMaybe<HubSpotWhitelistUpdateManyWithoutOrganizationInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inbound_close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_dm_connect_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_lt_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_revenue_value_of_sale?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_scheduled_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_set_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventUpdateManyWithoutOrganizationInput>;
  InboundConciergeWidget?: InputMaybe<InboundConciergeWidgetUpdateManyWithoutOrganizationInput>;
  industry_options?: InputMaybe<IndustryOptionUpdateManyWithoutOrganizationInput>;
  initial_sdr_sets_reps_day?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  integration_error_logs?: InputMaybe<IntegrationErrorLogUpdateManyWithoutOrganizationInput>;
  integration_mappings?: InputMaybe<IntegrationMappingUpdateManyWithoutOrganizationInput>;
  IntegrationMappingCustomObject?: InputMaybe<IntegrationMappingCustomObjectUpdateManyWithoutOrganizationInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogUpdateManyWithoutOrganizationInput>;
  IntegrationReadOnlyObject?: InputMaybe<IntegrationReadOnlyObjectUpdateManyWithoutOrganizationInput>;
  international?: InputMaybe<BoolFieldUpdateOperationsInput>;
  is_email_domain_verified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  is_salesforce_sandbox?: InputMaybe<BoolFieldUpdateOperationsInput>;
  last_lead_value_compute_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutOrganizationInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryUpdateManyWithoutOrganizationInput>;
  lead_intent?: InputMaybe<LeadIntentUpdateManyWithoutOrganizationInput>;
  lead_release_same_rep_delay_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  lead_routing_email_list?: InputMaybe<OrganizationUpdatelead_Routing_Email_ListInput>;
  lead_routing_rules?: InputMaybe<RuleUpdateManyWithoutOrganizationInput>;
  LeadCustomObjectRowAssociation?: InputMaybe<LeadCustomObjectRowAssociationUpdateManyWithoutOrganizationInput>;
  LeadFieldMultiplier?: InputMaybe<LeadFieldMultiplierUpdateManyWithoutOrganizationInput>;
  LeadFieldMultiplierCustom?: InputMaybe<LeadFieldMultiplierCustomUpdateManyWithoutOrganizationInput>;
  leads?: InputMaybe<LeadUpdateManyWithoutOrganizationInput>;
  leadsource_options?: InputMaybe<LeadSourceOptionUpdateManyWithoutOrganizationInput>;
  LeadValue?: InputMaybe<LeadValueUpdateManyWithoutOrganizationInput>;
  LifeTimeValueMultiplier?: InputMaybe<LifeTimeValueMultiplierUpdateManyWithoutOrganizationInput>;
  LiveTransferSetting?: InputMaybe<LiveTransferSettingUpdateOneWithoutOrganizationInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusUpdateManyWithoutOrganizationInput>;
  ltv_multiplier_custom_fields?: InputMaybe<LifeTimeValueMultiplierCustomUpdateManyWithoutOrganizationInput>;
  made_contact_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  make_sale_address_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_business_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_city_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_country_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_first_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_industry_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_last_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_lead_source_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_primary_email_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_primary_phone_number_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_state_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_sub_industry_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_zip_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  maxium_day_lag_set_to_schedule?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  mrr?: InputMaybe<IntFieldUpdateOperationsInput>;
  mrr_label?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  mrr_required_on_sale?: InputMaybe<BoolFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  NoteItems?: InputMaybe<NoteItemUpdateManyWithoutOrganizationInput>;
  Notifications?: InputMaybe<NotificationUpdateManyWithoutOrganizationInput>;
  onboardComplete?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  Opportunity?: InputMaybe<SfOpportunityUpdateManyWithoutOrganizationInput>;
  organization_holidays?: InputMaybe<OrganizationHolidayUpdateManyWithoutOrganizationInput>;
  organization_hours?: InputMaybe<OrganizationHourUpdateManyWithoutOrganizationInput>;
  OrganizationPaymentSetting?: InputMaybe<OrganizationPaymentSettingsUpdateOneWithoutOrganizationInput>;
  OrganizationPaymentSettings?: InputMaybe<OrganizationPaymentSettingsUpdateOneWithoutOrganizationsInput>;
  orgCalendarInviteMetadata?: InputMaybe<OrgCalendarInviteMetadataUpdateManyWithoutOrganizationInput>;
  OrgDefaultPipelinePerecentValue?: InputMaybe<OrgDefaultPipelinePerecentValueUpdateOneWithoutOrganizationInput>;
  OrgHubSpotSync?: InputMaybe<OrgHubSpotSyncUpdateOneWithoutOrganizationInput>;
  OrgNoEventDate?: InputMaybe<OrgNoEventDateUpdateManyWithoutOrganizationInput>;
  OrgSalesforceSync?: InputMaybe<OrgSalesforceSyncUpdateOneWithoutOrganizationInput>;
  OrgTimeslotAvailability?: InputMaybe<OrgTimeslotAvailabilityUpdateManyWithoutOrganizationInput>;
  outbound_close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_dm_connect_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_lt_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_revenue_value_of_sale?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_scheduled_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_set_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  PandadocData?: InputMaybe<PandadocDataUpdateOneWithoutOrganizationInput>;
  PandaDocTemplate?: InputMaybe<PandaDocTemplateUpdateManyWithoutOrganizationInput>;
  participant_jitter_buffer_size?: InputMaybe<EnumJitterBufferSizeFieldUpdateOperationsInput>;
  preferred_goal_setting?: InputMaybe<NullableEnumGoalsettingFieldUpdateOperationsInput>;
  prepayment?: InputMaybe<BoolFieldUpdateOperationsInput>;
  ProductChangeLog?: InputMaybe<ProductChangeLogUpdateManyWithoutOrganizationInput>;
  products?: InputMaybe<ProductUpdateManyWithoutOrganizationInput>;
  re_cold_call_hour_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  recordingStatus?: InputMaybe<EnumRecordingStatusFieldUpdateOperationsInput>;
  release_lead_resting?: InputMaybe<IntFieldUpdateOperationsInput>;
  rep_claim_limit?: InputMaybe<IntFieldUpdateOperationsInput>;
  rep_lead_allotment?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  rep_level_call_transfer?: InputMaybe<BoolFieldUpdateOperationsInput>;
  reps_can_bulk_unassign?: InputMaybe<BoolFieldUpdateOperationsInput>;
  reps_can_view_all_notes?: InputMaybe<BoolFieldUpdateOperationsInput>;
  require_sale_notes?: InputMaybe<BoolFieldUpdateOperationsInput>;
  resting_after_skipped_CC?: InputMaybe<IntFieldUpdateOperationsInput>;
  resting_period?: InputMaybe<IntFieldUpdateOperationsInput>;
  resting_period_after_unsuccessful_cold_call?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  resting_period_after_unsuccessful_demo?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  revenue_plan?: InputMaybe<RevenuePlanItemUpdateManyWithoutOrganizationInput>;
  Routings?: InputMaybe<RoutingUpdateManyWithoutOrganizationInput>;
  sale_field_options?: InputMaybe<SaleFieldOptionUpdateManyWithoutOrganizationInput>;
  sale_notification_email_list?: InputMaybe<OrganizationUpdatesale_Notification_Email_ListInput>;
  SaleConfig?: InputMaybe<SaleConfigUpdateOneWithoutOrganizationInput>;
  SaleConfigPage?: InputMaybe<SaleConfigPageUpdateManyWithoutOrganizationInput>;
  SaleConfigSection?: InputMaybe<SaleConfigSectionUpdateManyWithoutOrganizationInput>;
  SaleCycles?: InputMaybe<SaleCycleUpdateManyWithoutOrganizationInput>;
  SaleItems?: InputMaybe<SaleItemUpdateManyWithoutOrganizationInput>;
  sales?: InputMaybe<SaleUpdateManyWithoutOrganizationInput>;
  salesforce_domain?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  salesforce_last_sync_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  SalesForceColumn?: InputMaybe<SalesForceColumnUpdateManyWithoutOrganizationInput>;
  salesForceData?: InputMaybe<SalesForceDataUpdateOneWithoutOrganizationInput>;
  ScheduleItems?: InputMaybe<ScheduleItemUpdateManyWithoutOrganizationInput>;
  self_sourcing_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  send_error_log_email?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  send_lead_routing_email?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sendgrid_email_domain?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sendgrid_email_domain_id?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  sequence?: InputMaybe<SequenceUpdateManyWithoutOrganzationInput>;
  SequenceCriteriaConditions?: InputMaybe<SequenceCriteriaConditionUpdateManyWithoutOrganizationInput>;
  SequenceDashboardViews?: InputMaybe<SequenceDashboardViewUpdateManyWithoutOrganizationInput>;
  SequenceEntryCriterias?: InputMaybe<SequenceEntryCriteriaUpdateManyWithoutOrganizationInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutOrganizationInput>;
  SequenceSteps?: InputMaybe<SequenceStepUpdateManyWithoutOrganizationInput>;
  set_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
  short_resting?: InputMaybe<IntFieldUpdateOperationsInput>;
  show_calendar_options?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_contract_duration_sales?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_lead_source_to_rep?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_nocontact_button?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_payment_terms_sales?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_rep_all_leads?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_rep_my_leads?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_system_view?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sites?: InputMaybe<SiteUpdateManyWithoutOrganizationInput>;
  sms_opt_in_dialog?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SMSItems?: InputMaybe<SmsItemUpdateManyWithoutOrganizationInput>;
  SnoozeTrackings?: InputMaybe<SnoozeTrackingUpdateManyWithoutOrganizationInput>;
  StepActionLogs?: InputMaybe<StepActionLogUpdateManyWithoutOrganizationInput>;
  StepActions?: InputMaybe<StepActionUpdateManyWithoutOrganizationInput>;
  StepConditions?: InputMaybe<StepConditionUpdateManyWithoutOrganizationInput>;
  teams?: InputMaybe<TeamUpdateManyWithoutOrganizationInput>;
  Template?: InputMaybe<TemplateUpdateManyWithoutOrganizationInput>;
  TemplateVersions?: InputMaybe<TemplateVersionUpdateManyWithoutOrganizationInput>;
  timekit_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timezone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transfer_timeout?: InputMaybe<IntFieldUpdateOperationsInput>;
  TransferAttempts?: InputMaybe<TransferAttemptUpdateManyWithoutOrganizationInput>;
  twilio_numbers?: InputMaybe<TwilioNumberUpdateManyWithoutOrganizationInput>;
  TwilioFeedbacks?: InputMaybe<TwilioFeedbackUpdateManyWithoutOrganizationInput>;
  TwilioInfo?: InputMaybe<TwilioInfoUpdateOneWithoutOrganzationInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  use_revenue_planning?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  UserCustomFieldFilters?: InputMaybe<UserCustomFieldFilterUpdateManyWithoutOrganizationInput>;
  users?: InputMaybe<UserUpdateManyWithoutOrganizationInput>;
  win_rep_day_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
};

export type OrganizationUpdateWithoutGlobalTransferRuleInput = {
  ActivityMonitorMultiplier?: InputMaybe<ActivityMonitorMultiplierUpdateManyWithoutOrganizationInput>;
  algoFactor?: InputMaybe<AlgoFactorUpdateManyWithoutOrganizationInput>;
  allow_calendar_invite?: InputMaybe<BoolFieldUpdateOperationsInput>;
  allow_remove_step_action?: InputMaybe<BoolFieldUpdateOperationsInput>;
  allow_snooze_step_action?: InputMaybe<BoolFieldUpdateOperationsInput>;
  always_record?: InputMaybe<BoolFieldUpdateOperationsInput>;
  arv_initial_held_demos_generated_by_sdr_weight?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  AssociationHistories?: InputMaybe<AssociationHistoryUpdateManyWithoutOrganizationInput>;
  auto_call_interval?: InputMaybe<IntFieldUpdateOperationsInput>;
  auto_dial?: InputMaybe<BoolFieldUpdateOperationsInput>;
  automated_campaigns?: InputMaybe<AutomatedCampaignUpdateManyWithoutOrganizationInput>;
  automatic_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  AutoTransferSelections?: InputMaybe<AutoTransferSelectionUpdateManyWithoutOrganizationInput>;
  Availablities?: InputMaybe<AvailabilityUpdateManyWithoutOrganizationInput>;
  AverageOrderValue?: InputMaybe<AverageOrderValueUpdateManyWithoutOrganizationInput>;
  best_action_ever_multiplier?: InputMaybe<FloatFieldUpdateOperationsInput>;
  best_metrics?: InputMaybe<BestMetricRecordUpdateManyWithoutOrganizationInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptUpdateManyWithoutOrganizationInput>;
  callMeNows?: InputMaybe<CallMeNowUpdateManyWithoutOrganizationInput>;
  CallMultiplier?: InputMaybe<CallMultiplierUpdateManyWithoutOrganizationInput>;
  CallSharings?: InputMaybe<CallSharingUpdateManyWithoutOrganizationInput>;
  can_edit_sdr_transfer_rules?: InputMaybe<BoolFieldUpdateOperationsInput>;
  claim_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  concession?: InputMaybe<ConcessionUpdateManyWithoutOrganizationInput>;
  conference_region?: InputMaybe<EnumConferenceRegionFieldUpdateOperationsInput>;
  Conferences?: InputMaybe<ConferenceUpdateManyWithoutOrganizationInput>;
  ContactRequests?: InputMaybe<ContactRequestUpdateManyWithoutOrganizationInput>;
  Contacts?: InputMaybe<ContactUpdateManyWithoutOrganizationInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  custom_fields?: InputMaybe<CustomFieldUpdateManyWithoutOrganizationInput>;
  CustomObject?: InputMaybe<CustomObjectUpdateManyWithoutOrganizationInput>;
  CustomObjectField?: InputMaybe<CustomObjectFieldUpdateManyWithoutOrganizationInput>;
  CustomObjectFieldForeignKey?: InputMaybe<CustomObjectFieldForeignKeyUpdateManyWithoutOrganizationInput>;
  CustomObjectRecord?: InputMaybe<CustomObjectRecordUpdateManyWithoutOrganizationInput>;
  CustomObjectRow?: InputMaybe<CustomObjectRowUpdateManyWithoutOrganizationInput>;
  CustomQueueItems?: InputMaybe<CustomQueueItemUpdateManyWithoutOrganizationInput>;
  DashboardColumnOptions?: InputMaybe<DashboardColumnOptionUpdateManyWithoutOrganizationInput>;
  DashboardLeadFilters?: InputMaybe<DashboardLeadFilterUpdateManyWithoutOrganizationInput>;
  DashboardRepFilters?: InputMaybe<DashboardRepFilterUpdateManyWithoutOrganizationInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewUpdateManyWithoutOrganizationInput>;
  day_to_close?: InputMaybe<IntFieldUpdateOperationsInput>;
  default_demo_time_duration?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  demo_held_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_not_held_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_not_held_call_back_short_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_prep_time_minutes?: InputMaybe<IntFieldUpdateOperationsInput>;
  dev_account?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  dial_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  DispositionMultiplier?: InputMaybe<DispositionMultiplierUpdateManyWithoutOrganizationInput>;
  dispositions?: InputMaybe<DispositionUpdateManyWithoutOrganizationInput>;
  double_booking?: InputMaybe<BoolFieldUpdateOperationsInput>;
  dynamic_date_operators?: InputMaybe<BoolFieldUpdateOperationsInput>;
  edge_servers?: InputMaybe<OrganizationUpdateedge_ServersInput>;
  email_conflict_notification_email_list?: InputMaybe<OrganizationUpdateemail_Conflict_Notification_Email_ListInput>;
  EmailItems?: InputMaybe<EmailItemUpdateManyWithoutOrganizationInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordUpdateManyWithoutOrganizationInput>;
  EmailThreads?: InputMaybe<EmailThreadUpdateManyWithoutOrganizationInput>;
  enable_modified_metrics?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  enable_pay_period_view?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  enable_time_constraint?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  error_log_email_address?: InputMaybe<OrganizationUpdateerror_Log_Email_AddressInput>;
  estimated_average_num_of_weekly_overbooked_demo_slots?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  FolderPermissions?: InputMaybe<FolderPermissionUpdateManyWithoutOrganizationInput>;
  Folders?: InputMaybe<FolderUpdateManyWithoutOrganizationInput>;
  FutureBookingSetting?: InputMaybe<FutureBookingSettingUpdateOneWithoutOrganizationInput>;
  held_demos_per_cc_connect?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  helpful_links?: InputMaybe<OrganizationUpdatehelpful_LinksInput>;
  HingePointMultiplier?: InputMaybe<HingePointMultiplierUpdateManyWithoutOrganizationInput>;
  hold_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
  hubspot_credential?: InputMaybe<HubSpotCredentialUpdateOneWithoutOrganizationInput>;
  hubspot_last_sync_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  hubspot_white_list?: InputMaybe<HubSpotWhitelistUpdateManyWithoutOrganizationInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inbound_close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_dm_connect_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_lt_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_revenue_value_of_sale?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_scheduled_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_set_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventUpdateManyWithoutOrganizationInput>;
  InboundConciergeWidget?: InputMaybe<InboundConciergeWidgetUpdateManyWithoutOrganizationInput>;
  industry_options?: InputMaybe<IndustryOptionUpdateManyWithoutOrganizationInput>;
  initial_sdr_sets_reps_day?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  integration_error_logs?: InputMaybe<IntegrationErrorLogUpdateManyWithoutOrganizationInput>;
  integration_mappings?: InputMaybe<IntegrationMappingUpdateManyWithoutOrganizationInput>;
  IntegrationMappingCustomObject?: InputMaybe<IntegrationMappingCustomObjectUpdateManyWithoutOrganizationInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogUpdateManyWithoutOrganizationInput>;
  IntegrationReadOnlyObject?: InputMaybe<IntegrationReadOnlyObjectUpdateManyWithoutOrganizationInput>;
  international?: InputMaybe<BoolFieldUpdateOperationsInput>;
  is_email_domain_verified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  is_salesforce_sandbox?: InputMaybe<BoolFieldUpdateOperationsInput>;
  last_lead_value_compute_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutOrganizationInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryUpdateManyWithoutOrganizationInput>;
  lead_intent?: InputMaybe<LeadIntentUpdateManyWithoutOrganizationInput>;
  lead_release_same_rep_delay_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  lead_routing_email_list?: InputMaybe<OrganizationUpdatelead_Routing_Email_ListInput>;
  lead_routing_rules?: InputMaybe<RuleUpdateManyWithoutOrganizationInput>;
  LeadCustomObjectRowAssociation?: InputMaybe<LeadCustomObjectRowAssociationUpdateManyWithoutOrganizationInput>;
  LeadFieldMultiplier?: InputMaybe<LeadFieldMultiplierUpdateManyWithoutOrganizationInput>;
  LeadFieldMultiplierCustom?: InputMaybe<LeadFieldMultiplierCustomUpdateManyWithoutOrganizationInput>;
  leads?: InputMaybe<LeadUpdateManyWithoutOrganizationInput>;
  leadsource_options?: InputMaybe<LeadSourceOptionUpdateManyWithoutOrganizationInput>;
  LeadValue?: InputMaybe<LeadValueUpdateManyWithoutOrganizationInput>;
  LifeTimeValueMultiplier?: InputMaybe<LifeTimeValueMultiplierUpdateManyWithoutOrganizationInput>;
  LiveTransferSetting?: InputMaybe<LiveTransferSettingUpdateOneWithoutOrganizationInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusUpdateManyWithoutOrganizationInput>;
  ltv_multiplier_custom_fields?: InputMaybe<LifeTimeValueMultiplierCustomUpdateManyWithoutOrganizationInput>;
  made_contact_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  make_sale_address_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_business_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_city_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_country_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_first_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_industry_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_last_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_lead_source_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_primary_email_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_primary_phone_number_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_state_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_sub_industry_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_zip_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  maxium_day_lag_set_to_schedule?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  mrr?: InputMaybe<IntFieldUpdateOperationsInput>;
  mrr_label?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  mrr_required_on_sale?: InputMaybe<BoolFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  NoteItems?: InputMaybe<NoteItemUpdateManyWithoutOrganizationInput>;
  Notifications?: InputMaybe<NotificationUpdateManyWithoutOrganizationInput>;
  onboardComplete?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  Opportunity?: InputMaybe<SfOpportunityUpdateManyWithoutOrganizationInput>;
  organization_holidays?: InputMaybe<OrganizationHolidayUpdateManyWithoutOrganizationInput>;
  organization_hours?: InputMaybe<OrganizationHourUpdateManyWithoutOrganizationInput>;
  OrganizationPaymentSetting?: InputMaybe<OrganizationPaymentSettingsUpdateOneWithoutOrganizationInput>;
  OrganizationPaymentSettings?: InputMaybe<OrganizationPaymentSettingsUpdateOneWithoutOrganizationsInput>;
  orgCalendarInviteMetadata?: InputMaybe<OrgCalendarInviteMetadataUpdateManyWithoutOrganizationInput>;
  OrgDefaultPipelinePerecentValue?: InputMaybe<OrgDefaultPipelinePerecentValueUpdateOneWithoutOrganizationInput>;
  OrgHubSpotSync?: InputMaybe<OrgHubSpotSyncUpdateOneWithoutOrganizationInput>;
  OrgNoEventDate?: InputMaybe<OrgNoEventDateUpdateManyWithoutOrganizationInput>;
  OrgSalesforceSync?: InputMaybe<OrgSalesforceSyncUpdateOneWithoutOrganizationInput>;
  OrgTimeslotAvailability?: InputMaybe<OrgTimeslotAvailabilityUpdateManyWithoutOrganizationInput>;
  outbound_close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_dm_connect_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_lt_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_revenue_value_of_sale?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_scheduled_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_set_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  PandadocData?: InputMaybe<PandadocDataUpdateOneWithoutOrganizationInput>;
  PandaDocTemplate?: InputMaybe<PandaDocTemplateUpdateManyWithoutOrganizationInput>;
  participant_jitter_buffer_size?: InputMaybe<EnumJitterBufferSizeFieldUpdateOperationsInput>;
  preferred_goal_setting?: InputMaybe<NullableEnumGoalsettingFieldUpdateOperationsInput>;
  prepayment?: InputMaybe<BoolFieldUpdateOperationsInput>;
  ProductChangeLog?: InputMaybe<ProductChangeLogUpdateManyWithoutOrganizationInput>;
  products?: InputMaybe<ProductUpdateManyWithoutOrganizationInput>;
  re_cold_call_hour_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  recordingStatus?: InputMaybe<EnumRecordingStatusFieldUpdateOperationsInput>;
  release_lead_resting?: InputMaybe<IntFieldUpdateOperationsInput>;
  rep_claim_limit?: InputMaybe<IntFieldUpdateOperationsInput>;
  rep_lead_allotment?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  rep_level_call_transfer?: InputMaybe<BoolFieldUpdateOperationsInput>;
  reps_can_bulk_unassign?: InputMaybe<BoolFieldUpdateOperationsInput>;
  reps_can_view_all_notes?: InputMaybe<BoolFieldUpdateOperationsInput>;
  require_sale_notes?: InputMaybe<BoolFieldUpdateOperationsInput>;
  resting_after_skipped_CC?: InputMaybe<IntFieldUpdateOperationsInput>;
  resting_period?: InputMaybe<IntFieldUpdateOperationsInput>;
  resting_period_after_unsuccessful_cold_call?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  resting_period_after_unsuccessful_demo?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  revenue_plan?: InputMaybe<RevenuePlanItemUpdateManyWithoutOrganizationInput>;
  Routings?: InputMaybe<RoutingUpdateManyWithoutOrganizationInput>;
  sale_field_options?: InputMaybe<SaleFieldOptionUpdateManyWithoutOrganizationInput>;
  sale_notification_email_list?: InputMaybe<OrganizationUpdatesale_Notification_Email_ListInput>;
  SaleConfig?: InputMaybe<SaleConfigUpdateOneWithoutOrganizationInput>;
  SaleConfigPage?: InputMaybe<SaleConfigPageUpdateManyWithoutOrganizationInput>;
  SaleConfigSection?: InputMaybe<SaleConfigSectionUpdateManyWithoutOrganizationInput>;
  SaleCycles?: InputMaybe<SaleCycleUpdateManyWithoutOrganizationInput>;
  SaleItems?: InputMaybe<SaleItemUpdateManyWithoutOrganizationInput>;
  sales?: InputMaybe<SaleUpdateManyWithoutOrganizationInput>;
  salesforce_domain?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  salesforce_last_sync_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  SalesForceColumn?: InputMaybe<SalesForceColumnUpdateManyWithoutOrganizationInput>;
  salesForceData?: InputMaybe<SalesForceDataUpdateOneWithoutOrganizationInput>;
  ScheduleItems?: InputMaybe<ScheduleItemUpdateManyWithoutOrganizationInput>;
  self_sourcing_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  send_error_log_email?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  send_lead_routing_email?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sendgrid_email_domain?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sendgrid_email_domain_id?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  sequence?: InputMaybe<SequenceUpdateManyWithoutOrganzationInput>;
  SequenceCriteriaConditions?: InputMaybe<SequenceCriteriaConditionUpdateManyWithoutOrganizationInput>;
  SequenceDashboardViews?: InputMaybe<SequenceDashboardViewUpdateManyWithoutOrganizationInput>;
  SequenceEntryCriterias?: InputMaybe<SequenceEntryCriteriaUpdateManyWithoutOrganizationInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutOrganizationInput>;
  SequenceSteps?: InputMaybe<SequenceStepUpdateManyWithoutOrganizationInput>;
  set_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
  short_resting?: InputMaybe<IntFieldUpdateOperationsInput>;
  show_calendar_options?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_contract_duration_sales?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_lead_source_to_rep?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_nocontact_button?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_payment_terms_sales?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_rep_all_leads?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_rep_my_leads?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_system_view?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sites?: InputMaybe<SiteUpdateManyWithoutOrganizationInput>;
  sms_opt_in_dialog?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SMSItems?: InputMaybe<SmsItemUpdateManyWithoutOrganizationInput>;
  SnoozeTrackings?: InputMaybe<SnoozeTrackingUpdateManyWithoutOrganizationInput>;
  StepActionLogs?: InputMaybe<StepActionLogUpdateManyWithoutOrganizationInput>;
  StepActions?: InputMaybe<StepActionUpdateManyWithoutOrganizationInput>;
  StepConditions?: InputMaybe<StepConditionUpdateManyWithoutOrganizationInput>;
  teams?: InputMaybe<TeamUpdateManyWithoutOrganizationInput>;
  Template?: InputMaybe<TemplateUpdateManyWithoutOrganizationInput>;
  TemplateVersions?: InputMaybe<TemplateVersionUpdateManyWithoutOrganizationInput>;
  timekit_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timezone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transfer_timeout?: InputMaybe<IntFieldUpdateOperationsInput>;
  TransferAttempts?: InputMaybe<TransferAttemptUpdateManyWithoutOrganizationInput>;
  twilio_numbers?: InputMaybe<TwilioNumberUpdateManyWithoutOrganizationInput>;
  TwilioFeedbacks?: InputMaybe<TwilioFeedbackUpdateManyWithoutOrganizationInput>;
  TwilioInfo?: InputMaybe<TwilioInfoUpdateOneWithoutOrganzationInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  use_revenue_planning?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  UserCustomFieldFilters?: InputMaybe<UserCustomFieldFilterUpdateManyWithoutOrganizationInput>;
  users?: InputMaybe<UserUpdateManyWithoutOrganizationInput>;
  win_rep_day_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
};

export type OrganizationUpdateWithoutHingePointMultiplierInput = {
  ActivityMonitorMultiplier?: InputMaybe<ActivityMonitorMultiplierUpdateManyWithoutOrganizationInput>;
  algoFactor?: InputMaybe<AlgoFactorUpdateManyWithoutOrganizationInput>;
  allow_calendar_invite?: InputMaybe<BoolFieldUpdateOperationsInput>;
  allow_remove_step_action?: InputMaybe<BoolFieldUpdateOperationsInput>;
  allow_snooze_step_action?: InputMaybe<BoolFieldUpdateOperationsInput>;
  always_record?: InputMaybe<BoolFieldUpdateOperationsInput>;
  arv_initial_held_demos_generated_by_sdr_weight?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  AssociationHistories?: InputMaybe<AssociationHistoryUpdateManyWithoutOrganizationInput>;
  auto_call_interval?: InputMaybe<IntFieldUpdateOperationsInput>;
  auto_dial?: InputMaybe<BoolFieldUpdateOperationsInput>;
  automated_campaigns?: InputMaybe<AutomatedCampaignUpdateManyWithoutOrganizationInput>;
  automatic_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  AutoTransferSelections?: InputMaybe<AutoTransferSelectionUpdateManyWithoutOrganizationInput>;
  Availablities?: InputMaybe<AvailabilityUpdateManyWithoutOrganizationInput>;
  AverageOrderValue?: InputMaybe<AverageOrderValueUpdateManyWithoutOrganizationInput>;
  best_action_ever_multiplier?: InputMaybe<FloatFieldUpdateOperationsInput>;
  best_metrics?: InputMaybe<BestMetricRecordUpdateManyWithoutOrganizationInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptUpdateManyWithoutOrganizationInput>;
  callMeNows?: InputMaybe<CallMeNowUpdateManyWithoutOrganizationInput>;
  CallMultiplier?: InputMaybe<CallMultiplierUpdateManyWithoutOrganizationInput>;
  CallSharings?: InputMaybe<CallSharingUpdateManyWithoutOrganizationInput>;
  can_edit_sdr_transfer_rules?: InputMaybe<BoolFieldUpdateOperationsInput>;
  claim_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  concession?: InputMaybe<ConcessionUpdateManyWithoutOrganizationInput>;
  conference_region?: InputMaybe<EnumConferenceRegionFieldUpdateOperationsInput>;
  Conferences?: InputMaybe<ConferenceUpdateManyWithoutOrganizationInput>;
  ContactRequests?: InputMaybe<ContactRequestUpdateManyWithoutOrganizationInput>;
  Contacts?: InputMaybe<ContactUpdateManyWithoutOrganizationInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  custom_fields?: InputMaybe<CustomFieldUpdateManyWithoutOrganizationInput>;
  CustomObject?: InputMaybe<CustomObjectUpdateManyWithoutOrganizationInput>;
  CustomObjectField?: InputMaybe<CustomObjectFieldUpdateManyWithoutOrganizationInput>;
  CustomObjectFieldForeignKey?: InputMaybe<CustomObjectFieldForeignKeyUpdateManyWithoutOrganizationInput>;
  CustomObjectRecord?: InputMaybe<CustomObjectRecordUpdateManyWithoutOrganizationInput>;
  CustomObjectRow?: InputMaybe<CustomObjectRowUpdateManyWithoutOrganizationInput>;
  CustomQueueItems?: InputMaybe<CustomQueueItemUpdateManyWithoutOrganizationInput>;
  DashboardColumnOptions?: InputMaybe<DashboardColumnOptionUpdateManyWithoutOrganizationInput>;
  DashboardLeadFilters?: InputMaybe<DashboardLeadFilterUpdateManyWithoutOrganizationInput>;
  DashboardRepFilters?: InputMaybe<DashboardRepFilterUpdateManyWithoutOrganizationInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewUpdateManyWithoutOrganizationInput>;
  day_to_close?: InputMaybe<IntFieldUpdateOperationsInput>;
  default_demo_time_duration?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  demo_held_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_not_held_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_not_held_call_back_short_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_prep_time_minutes?: InputMaybe<IntFieldUpdateOperationsInput>;
  dev_account?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  dial_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  DispositionMultiplier?: InputMaybe<DispositionMultiplierUpdateManyWithoutOrganizationInput>;
  dispositions?: InputMaybe<DispositionUpdateManyWithoutOrganizationInput>;
  double_booking?: InputMaybe<BoolFieldUpdateOperationsInput>;
  dynamic_date_operators?: InputMaybe<BoolFieldUpdateOperationsInput>;
  edge_servers?: InputMaybe<OrganizationUpdateedge_ServersInput>;
  email_conflict_notification_email_list?: InputMaybe<OrganizationUpdateemail_Conflict_Notification_Email_ListInput>;
  EmailItems?: InputMaybe<EmailItemUpdateManyWithoutOrganizationInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordUpdateManyWithoutOrganizationInput>;
  EmailThreads?: InputMaybe<EmailThreadUpdateManyWithoutOrganizationInput>;
  enable_modified_metrics?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  enable_pay_period_view?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  enable_time_constraint?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  error_log_email_address?: InputMaybe<OrganizationUpdateerror_Log_Email_AddressInput>;
  estimated_average_num_of_weekly_overbooked_demo_slots?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  FolderPermissions?: InputMaybe<FolderPermissionUpdateManyWithoutOrganizationInput>;
  Folders?: InputMaybe<FolderUpdateManyWithoutOrganizationInput>;
  FutureBookingSetting?: InputMaybe<FutureBookingSettingUpdateOneWithoutOrganizationInput>;
  GlobalTransferRule?: InputMaybe<GlobalTransferRuleUpdateManyWithoutOrganizationInput>;
  held_demos_per_cc_connect?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  helpful_links?: InputMaybe<OrganizationUpdatehelpful_LinksInput>;
  hold_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
  hubspot_credential?: InputMaybe<HubSpotCredentialUpdateOneWithoutOrganizationInput>;
  hubspot_last_sync_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  hubspot_white_list?: InputMaybe<HubSpotWhitelistUpdateManyWithoutOrganizationInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inbound_close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_dm_connect_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_lt_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_revenue_value_of_sale?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_scheduled_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_set_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventUpdateManyWithoutOrganizationInput>;
  InboundConciergeWidget?: InputMaybe<InboundConciergeWidgetUpdateManyWithoutOrganizationInput>;
  industry_options?: InputMaybe<IndustryOptionUpdateManyWithoutOrganizationInput>;
  initial_sdr_sets_reps_day?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  integration_error_logs?: InputMaybe<IntegrationErrorLogUpdateManyWithoutOrganizationInput>;
  integration_mappings?: InputMaybe<IntegrationMappingUpdateManyWithoutOrganizationInput>;
  IntegrationMappingCustomObject?: InputMaybe<IntegrationMappingCustomObjectUpdateManyWithoutOrganizationInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogUpdateManyWithoutOrganizationInput>;
  IntegrationReadOnlyObject?: InputMaybe<IntegrationReadOnlyObjectUpdateManyWithoutOrganizationInput>;
  international?: InputMaybe<BoolFieldUpdateOperationsInput>;
  is_email_domain_verified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  is_salesforce_sandbox?: InputMaybe<BoolFieldUpdateOperationsInput>;
  last_lead_value_compute_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutOrganizationInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryUpdateManyWithoutOrganizationInput>;
  lead_intent?: InputMaybe<LeadIntentUpdateManyWithoutOrganizationInput>;
  lead_release_same_rep_delay_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  lead_routing_email_list?: InputMaybe<OrganizationUpdatelead_Routing_Email_ListInput>;
  lead_routing_rules?: InputMaybe<RuleUpdateManyWithoutOrganizationInput>;
  LeadCustomObjectRowAssociation?: InputMaybe<LeadCustomObjectRowAssociationUpdateManyWithoutOrganizationInput>;
  LeadFieldMultiplier?: InputMaybe<LeadFieldMultiplierUpdateManyWithoutOrganizationInput>;
  LeadFieldMultiplierCustom?: InputMaybe<LeadFieldMultiplierCustomUpdateManyWithoutOrganizationInput>;
  leads?: InputMaybe<LeadUpdateManyWithoutOrganizationInput>;
  leadsource_options?: InputMaybe<LeadSourceOptionUpdateManyWithoutOrganizationInput>;
  LeadValue?: InputMaybe<LeadValueUpdateManyWithoutOrganizationInput>;
  LifeTimeValueMultiplier?: InputMaybe<LifeTimeValueMultiplierUpdateManyWithoutOrganizationInput>;
  LiveTransferSetting?: InputMaybe<LiveTransferSettingUpdateOneWithoutOrganizationInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusUpdateManyWithoutOrganizationInput>;
  ltv_multiplier_custom_fields?: InputMaybe<LifeTimeValueMultiplierCustomUpdateManyWithoutOrganizationInput>;
  made_contact_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  make_sale_address_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_business_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_city_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_country_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_first_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_industry_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_last_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_lead_source_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_primary_email_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_primary_phone_number_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_state_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_sub_industry_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_zip_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  maxium_day_lag_set_to_schedule?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  mrr?: InputMaybe<IntFieldUpdateOperationsInput>;
  mrr_label?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  mrr_required_on_sale?: InputMaybe<BoolFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  NoteItems?: InputMaybe<NoteItemUpdateManyWithoutOrganizationInput>;
  Notifications?: InputMaybe<NotificationUpdateManyWithoutOrganizationInput>;
  onboardComplete?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  Opportunity?: InputMaybe<SfOpportunityUpdateManyWithoutOrganizationInput>;
  organization_holidays?: InputMaybe<OrganizationHolidayUpdateManyWithoutOrganizationInput>;
  organization_hours?: InputMaybe<OrganizationHourUpdateManyWithoutOrganizationInput>;
  OrganizationPaymentSetting?: InputMaybe<OrganizationPaymentSettingsUpdateOneWithoutOrganizationInput>;
  OrganizationPaymentSettings?: InputMaybe<OrganizationPaymentSettingsUpdateOneWithoutOrganizationsInput>;
  orgCalendarInviteMetadata?: InputMaybe<OrgCalendarInviteMetadataUpdateManyWithoutOrganizationInput>;
  OrgDefaultPipelinePerecentValue?: InputMaybe<OrgDefaultPipelinePerecentValueUpdateOneWithoutOrganizationInput>;
  OrgHubSpotSync?: InputMaybe<OrgHubSpotSyncUpdateOneWithoutOrganizationInput>;
  OrgNoEventDate?: InputMaybe<OrgNoEventDateUpdateManyWithoutOrganizationInput>;
  OrgSalesforceSync?: InputMaybe<OrgSalesforceSyncUpdateOneWithoutOrganizationInput>;
  OrgTimeslotAvailability?: InputMaybe<OrgTimeslotAvailabilityUpdateManyWithoutOrganizationInput>;
  outbound_close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_dm_connect_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_lt_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_revenue_value_of_sale?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_scheduled_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_set_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  PandadocData?: InputMaybe<PandadocDataUpdateOneWithoutOrganizationInput>;
  PandaDocTemplate?: InputMaybe<PandaDocTemplateUpdateManyWithoutOrganizationInput>;
  participant_jitter_buffer_size?: InputMaybe<EnumJitterBufferSizeFieldUpdateOperationsInput>;
  preferred_goal_setting?: InputMaybe<NullableEnumGoalsettingFieldUpdateOperationsInput>;
  prepayment?: InputMaybe<BoolFieldUpdateOperationsInput>;
  ProductChangeLog?: InputMaybe<ProductChangeLogUpdateManyWithoutOrganizationInput>;
  products?: InputMaybe<ProductUpdateManyWithoutOrganizationInput>;
  re_cold_call_hour_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  recordingStatus?: InputMaybe<EnumRecordingStatusFieldUpdateOperationsInput>;
  release_lead_resting?: InputMaybe<IntFieldUpdateOperationsInput>;
  rep_claim_limit?: InputMaybe<IntFieldUpdateOperationsInput>;
  rep_lead_allotment?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  rep_level_call_transfer?: InputMaybe<BoolFieldUpdateOperationsInput>;
  reps_can_bulk_unassign?: InputMaybe<BoolFieldUpdateOperationsInput>;
  reps_can_view_all_notes?: InputMaybe<BoolFieldUpdateOperationsInput>;
  require_sale_notes?: InputMaybe<BoolFieldUpdateOperationsInput>;
  resting_after_skipped_CC?: InputMaybe<IntFieldUpdateOperationsInput>;
  resting_period?: InputMaybe<IntFieldUpdateOperationsInput>;
  resting_period_after_unsuccessful_cold_call?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  resting_period_after_unsuccessful_demo?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  revenue_plan?: InputMaybe<RevenuePlanItemUpdateManyWithoutOrganizationInput>;
  Routings?: InputMaybe<RoutingUpdateManyWithoutOrganizationInput>;
  sale_field_options?: InputMaybe<SaleFieldOptionUpdateManyWithoutOrganizationInput>;
  sale_notification_email_list?: InputMaybe<OrganizationUpdatesale_Notification_Email_ListInput>;
  SaleConfig?: InputMaybe<SaleConfigUpdateOneWithoutOrganizationInput>;
  SaleConfigPage?: InputMaybe<SaleConfigPageUpdateManyWithoutOrganizationInput>;
  SaleConfigSection?: InputMaybe<SaleConfigSectionUpdateManyWithoutOrganizationInput>;
  SaleCycles?: InputMaybe<SaleCycleUpdateManyWithoutOrganizationInput>;
  SaleItems?: InputMaybe<SaleItemUpdateManyWithoutOrganizationInput>;
  sales?: InputMaybe<SaleUpdateManyWithoutOrganizationInput>;
  salesforce_domain?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  salesforce_last_sync_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  SalesForceColumn?: InputMaybe<SalesForceColumnUpdateManyWithoutOrganizationInput>;
  salesForceData?: InputMaybe<SalesForceDataUpdateOneWithoutOrganizationInput>;
  ScheduleItems?: InputMaybe<ScheduleItemUpdateManyWithoutOrganizationInput>;
  self_sourcing_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  send_error_log_email?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  send_lead_routing_email?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sendgrid_email_domain?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sendgrid_email_domain_id?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  sequence?: InputMaybe<SequenceUpdateManyWithoutOrganzationInput>;
  SequenceCriteriaConditions?: InputMaybe<SequenceCriteriaConditionUpdateManyWithoutOrganizationInput>;
  SequenceDashboardViews?: InputMaybe<SequenceDashboardViewUpdateManyWithoutOrganizationInput>;
  SequenceEntryCriterias?: InputMaybe<SequenceEntryCriteriaUpdateManyWithoutOrganizationInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutOrganizationInput>;
  SequenceSteps?: InputMaybe<SequenceStepUpdateManyWithoutOrganizationInput>;
  set_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
  short_resting?: InputMaybe<IntFieldUpdateOperationsInput>;
  show_calendar_options?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_contract_duration_sales?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_lead_source_to_rep?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_nocontact_button?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_payment_terms_sales?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_rep_all_leads?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_rep_my_leads?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_system_view?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sites?: InputMaybe<SiteUpdateManyWithoutOrganizationInput>;
  sms_opt_in_dialog?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SMSItems?: InputMaybe<SmsItemUpdateManyWithoutOrganizationInput>;
  SnoozeTrackings?: InputMaybe<SnoozeTrackingUpdateManyWithoutOrganizationInput>;
  StepActionLogs?: InputMaybe<StepActionLogUpdateManyWithoutOrganizationInput>;
  StepActions?: InputMaybe<StepActionUpdateManyWithoutOrganizationInput>;
  StepConditions?: InputMaybe<StepConditionUpdateManyWithoutOrganizationInput>;
  teams?: InputMaybe<TeamUpdateManyWithoutOrganizationInput>;
  Template?: InputMaybe<TemplateUpdateManyWithoutOrganizationInput>;
  TemplateVersions?: InputMaybe<TemplateVersionUpdateManyWithoutOrganizationInput>;
  timekit_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timezone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transfer_timeout?: InputMaybe<IntFieldUpdateOperationsInput>;
  TransferAttempts?: InputMaybe<TransferAttemptUpdateManyWithoutOrganizationInput>;
  twilio_numbers?: InputMaybe<TwilioNumberUpdateManyWithoutOrganizationInput>;
  TwilioFeedbacks?: InputMaybe<TwilioFeedbackUpdateManyWithoutOrganizationInput>;
  TwilioInfo?: InputMaybe<TwilioInfoUpdateOneWithoutOrganzationInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  use_revenue_planning?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  UserCustomFieldFilters?: InputMaybe<UserCustomFieldFilterUpdateManyWithoutOrganizationInput>;
  users?: InputMaybe<UserUpdateManyWithoutOrganizationInput>;
  win_rep_day_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
};

export type OrganizationUpdateWithoutInboundConciergeEventsInput = {
  ActivityMonitorMultiplier?: InputMaybe<ActivityMonitorMultiplierUpdateManyWithoutOrganizationInput>;
  algoFactor?: InputMaybe<AlgoFactorUpdateManyWithoutOrganizationInput>;
  allow_calendar_invite?: InputMaybe<BoolFieldUpdateOperationsInput>;
  allow_remove_step_action?: InputMaybe<BoolFieldUpdateOperationsInput>;
  allow_snooze_step_action?: InputMaybe<BoolFieldUpdateOperationsInput>;
  always_record?: InputMaybe<BoolFieldUpdateOperationsInput>;
  arv_initial_held_demos_generated_by_sdr_weight?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  AssociationHistories?: InputMaybe<AssociationHistoryUpdateManyWithoutOrganizationInput>;
  auto_call_interval?: InputMaybe<IntFieldUpdateOperationsInput>;
  auto_dial?: InputMaybe<BoolFieldUpdateOperationsInput>;
  automated_campaigns?: InputMaybe<AutomatedCampaignUpdateManyWithoutOrganizationInput>;
  automatic_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  AutoTransferSelections?: InputMaybe<AutoTransferSelectionUpdateManyWithoutOrganizationInput>;
  Availablities?: InputMaybe<AvailabilityUpdateManyWithoutOrganizationInput>;
  AverageOrderValue?: InputMaybe<AverageOrderValueUpdateManyWithoutOrganizationInput>;
  best_action_ever_multiplier?: InputMaybe<FloatFieldUpdateOperationsInput>;
  best_metrics?: InputMaybe<BestMetricRecordUpdateManyWithoutOrganizationInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptUpdateManyWithoutOrganizationInput>;
  callMeNows?: InputMaybe<CallMeNowUpdateManyWithoutOrganizationInput>;
  CallMultiplier?: InputMaybe<CallMultiplierUpdateManyWithoutOrganizationInput>;
  CallSharings?: InputMaybe<CallSharingUpdateManyWithoutOrganizationInput>;
  can_edit_sdr_transfer_rules?: InputMaybe<BoolFieldUpdateOperationsInput>;
  claim_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  concession?: InputMaybe<ConcessionUpdateManyWithoutOrganizationInput>;
  conference_region?: InputMaybe<EnumConferenceRegionFieldUpdateOperationsInput>;
  Conferences?: InputMaybe<ConferenceUpdateManyWithoutOrganizationInput>;
  ContactRequests?: InputMaybe<ContactRequestUpdateManyWithoutOrganizationInput>;
  Contacts?: InputMaybe<ContactUpdateManyWithoutOrganizationInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  custom_fields?: InputMaybe<CustomFieldUpdateManyWithoutOrganizationInput>;
  CustomObject?: InputMaybe<CustomObjectUpdateManyWithoutOrganizationInput>;
  CustomObjectField?: InputMaybe<CustomObjectFieldUpdateManyWithoutOrganizationInput>;
  CustomObjectFieldForeignKey?: InputMaybe<CustomObjectFieldForeignKeyUpdateManyWithoutOrganizationInput>;
  CustomObjectRecord?: InputMaybe<CustomObjectRecordUpdateManyWithoutOrganizationInput>;
  CustomObjectRow?: InputMaybe<CustomObjectRowUpdateManyWithoutOrganizationInput>;
  CustomQueueItems?: InputMaybe<CustomQueueItemUpdateManyWithoutOrganizationInput>;
  DashboardColumnOptions?: InputMaybe<DashboardColumnOptionUpdateManyWithoutOrganizationInput>;
  DashboardLeadFilters?: InputMaybe<DashboardLeadFilterUpdateManyWithoutOrganizationInput>;
  DashboardRepFilters?: InputMaybe<DashboardRepFilterUpdateManyWithoutOrganizationInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewUpdateManyWithoutOrganizationInput>;
  day_to_close?: InputMaybe<IntFieldUpdateOperationsInput>;
  default_demo_time_duration?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  demo_held_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_not_held_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_not_held_call_back_short_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_prep_time_minutes?: InputMaybe<IntFieldUpdateOperationsInput>;
  dev_account?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  dial_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  DispositionMultiplier?: InputMaybe<DispositionMultiplierUpdateManyWithoutOrganizationInput>;
  dispositions?: InputMaybe<DispositionUpdateManyWithoutOrganizationInput>;
  double_booking?: InputMaybe<BoolFieldUpdateOperationsInput>;
  dynamic_date_operators?: InputMaybe<BoolFieldUpdateOperationsInput>;
  edge_servers?: InputMaybe<OrganizationUpdateedge_ServersInput>;
  email_conflict_notification_email_list?: InputMaybe<OrganizationUpdateemail_Conflict_Notification_Email_ListInput>;
  EmailItems?: InputMaybe<EmailItemUpdateManyWithoutOrganizationInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordUpdateManyWithoutOrganizationInput>;
  EmailThreads?: InputMaybe<EmailThreadUpdateManyWithoutOrganizationInput>;
  enable_modified_metrics?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  enable_pay_period_view?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  enable_time_constraint?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  error_log_email_address?: InputMaybe<OrganizationUpdateerror_Log_Email_AddressInput>;
  estimated_average_num_of_weekly_overbooked_demo_slots?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  FolderPermissions?: InputMaybe<FolderPermissionUpdateManyWithoutOrganizationInput>;
  Folders?: InputMaybe<FolderUpdateManyWithoutOrganizationInput>;
  FutureBookingSetting?: InputMaybe<FutureBookingSettingUpdateOneWithoutOrganizationInput>;
  GlobalTransferRule?: InputMaybe<GlobalTransferRuleUpdateManyWithoutOrganizationInput>;
  held_demos_per_cc_connect?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  helpful_links?: InputMaybe<OrganizationUpdatehelpful_LinksInput>;
  HingePointMultiplier?: InputMaybe<HingePointMultiplierUpdateManyWithoutOrganizationInput>;
  hold_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
  hubspot_credential?: InputMaybe<HubSpotCredentialUpdateOneWithoutOrganizationInput>;
  hubspot_last_sync_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  hubspot_white_list?: InputMaybe<HubSpotWhitelistUpdateManyWithoutOrganizationInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inbound_close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_dm_connect_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_lt_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_revenue_value_of_sale?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_scheduled_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_set_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  InboundConciergeWidget?: InputMaybe<InboundConciergeWidgetUpdateManyWithoutOrganizationInput>;
  industry_options?: InputMaybe<IndustryOptionUpdateManyWithoutOrganizationInput>;
  initial_sdr_sets_reps_day?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  integration_error_logs?: InputMaybe<IntegrationErrorLogUpdateManyWithoutOrganizationInput>;
  integration_mappings?: InputMaybe<IntegrationMappingUpdateManyWithoutOrganizationInput>;
  IntegrationMappingCustomObject?: InputMaybe<IntegrationMappingCustomObjectUpdateManyWithoutOrganizationInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogUpdateManyWithoutOrganizationInput>;
  IntegrationReadOnlyObject?: InputMaybe<IntegrationReadOnlyObjectUpdateManyWithoutOrganizationInput>;
  international?: InputMaybe<BoolFieldUpdateOperationsInput>;
  is_email_domain_verified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  is_salesforce_sandbox?: InputMaybe<BoolFieldUpdateOperationsInput>;
  last_lead_value_compute_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutOrganizationInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryUpdateManyWithoutOrganizationInput>;
  lead_intent?: InputMaybe<LeadIntentUpdateManyWithoutOrganizationInput>;
  lead_release_same_rep_delay_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  lead_routing_email_list?: InputMaybe<OrganizationUpdatelead_Routing_Email_ListInput>;
  lead_routing_rules?: InputMaybe<RuleUpdateManyWithoutOrganizationInput>;
  LeadCustomObjectRowAssociation?: InputMaybe<LeadCustomObjectRowAssociationUpdateManyWithoutOrganizationInput>;
  LeadFieldMultiplier?: InputMaybe<LeadFieldMultiplierUpdateManyWithoutOrganizationInput>;
  LeadFieldMultiplierCustom?: InputMaybe<LeadFieldMultiplierCustomUpdateManyWithoutOrganizationInput>;
  leads?: InputMaybe<LeadUpdateManyWithoutOrganizationInput>;
  leadsource_options?: InputMaybe<LeadSourceOptionUpdateManyWithoutOrganizationInput>;
  LeadValue?: InputMaybe<LeadValueUpdateManyWithoutOrganizationInput>;
  LifeTimeValueMultiplier?: InputMaybe<LifeTimeValueMultiplierUpdateManyWithoutOrganizationInput>;
  LiveTransferSetting?: InputMaybe<LiveTransferSettingUpdateOneWithoutOrganizationInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusUpdateManyWithoutOrganizationInput>;
  ltv_multiplier_custom_fields?: InputMaybe<LifeTimeValueMultiplierCustomUpdateManyWithoutOrganizationInput>;
  made_contact_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  make_sale_address_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_business_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_city_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_country_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_first_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_industry_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_last_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_lead_source_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_primary_email_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_primary_phone_number_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_state_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_sub_industry_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_zip_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  maxium_day_lag_set_to_schedule?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  mrr?: InputMaybe<IntFieldUpdateOperationsInput>;
  mrr_label?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  mrr_required_on_sale?: InputMaybe<BoolFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  NoteItems?: InputMaybe<NoteItemUpdateManyWithoutOrganizationInput>;
  Notifications?: InputMaybe<NotificationUpdateManyWithoutOrganizationInput>;
  onboardComplete?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  Opportunity?: InputMaybe<SfOpportunityUpdateManyWithoutOrganizationInput>;
  organization_holidays?: InputMaybe<OrganizationHolidayUpdateManyWithoutOrganizationInput>;
  organization_hours?: InputMaybe<OrganizationHourUpdateManyWithoutOrganizationInput>;
  OrganizationPaymentSetting?: InputMaybe<OrganizationPaymentSettingsUpdateOneWithoutOrganizationInput>;
  OrganizationPaymentSettings?: InputMaybe<OrganizationPaymentSettingsUpdateOneWithoutOrganizationsInput>;
  orgCalendarInviteMetadata?: InputMaybe<OrgCalendarInviteMetadataUpdateManyWithoutOrganizationInput>;
  OrgDefaultPipelinePerecentValue?: InputMaybe<OrgDefaultPipelinePerecentValueUpdateOneWithoutOrganizationInput>;
  OrgHubSpotSync?: InputMaybe<OrgHubSpotSyncUpdateOneWithoutOrganizationInput>;
  OrgNoEventDate?: InputMaybe<OrgNoEventDateUpdateManyWithoutOrganizationInput>;
  OrgSalesforceSync?: InputMaybe<OrgSalesforceSyncUpdateOneWithoutOrganizationInput>;
  OrgTimeslotAvailability?: InputMaybe<OrgTimeslotAvailabilityUpdateManyWithoutOrganizationInput>;
  outbound_close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_dm_connect_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_lt_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_revenue_value_of_sale?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_scheduled_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_set_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  PandadocData?: InputMaybe<PandadocDataUpdateOneWithoutOrganizationInput>;
  PandaDocTemplate?: InputMaybe<PandaDocTemplateUpdateManyWithoutOrganizationInput>;
  participant_jitter_buffer_size?: InputMaybe<EnumJitterBufferSizeFieldUpdateOperationsInput>;
  preferred_goal_setting?: InputMaybe<NullableEnumGoalsettingFieldUpdateOperationsInput>;
  prepayment?: InputMaybe<BoolFieldUpdateOperationsInput>;
  ProductChangeLog?: InputMaybe<ProductChangeLogUpdateManyWithoutOrganizationInput>;
  products?: InputMaybe<ProductUpdateManyWithoutOrganizationInput>;
  re_cold_call_hour_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  recordingStatus?: InputMaybe<EnumRecordingStatusFieldUpdateOperationsInput>;
  release_lead_resting?: InputMaybe<IntFieldUpdateOperationsInput>;
  rep_claim_limit?: InputMaybe<IntFieldUpdateOperationsInput>;
  rep_lead_allotment?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  rep_level_call_transfer?: InputMaybe<BoolFieldUpdateOperationsInput>;
  reps_can_bulk_unassign?: InputMaybe<BoolFieldUpdateOperationsInput>;
  reps_can_view_all_notes?: InputMaybe<BoolFieldUpdateOperationsInput>;
  require_sale_notes?: InputMaybe<BoolFieldUpdateOperationsInput>;
  resting_after_skipped_CC?: InputMaybe<IntFieldUpdateOperationsInput>;
  resting_period?: InputMaybe<IntFieldUpdateOperationsInput>;
  resting_period_after_unsuccessful_cold_call?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  resting_period_after_unsuccessful_demo?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  revenue_plan?: InputMaybe<RevenuePlanItemUpdateManyWithoutOrganizationInput>;
  Routings?: InputMaybe<RoutingUpdateManyWithoutOrganizationInput>;
  sale_field_options?: InputMaybe<SaleFieldOptionUpdateManyWithoutOrganizationInput>;
  sale_notification_email_list?: InputMaybe<OrganizationUpdatesale_Notification_Email_ListInput>;
  SaleConfig?: InputMaybe<SaleConfigUpdateOneWithoutOrganizationInput>;
  SaleConfigPage?: InputMaybe<SaleConfigPageUpdateManyWithoutOrganizationInput>;
  SaleConfigSection?: InputMaybe<SaleConfigSectionUpdateManyWithoutOrganizationInput>;
  SaleCycles?: InputMaybe<SaleCycleUpdateManyWithoutOrganizationInput>;
  SaleItems?: InputMaybe<SaleItemUpdateManyWithoutOrganizationInput>;
  sales?: InputMaybe<SaleUpdateManyWithoutOrganizationInput>;
  salesforce_domain?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  salesforce_last_sync_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  SalesForceColumn?: InputMaybe<SalesForceColumnUpdateManyWithoutOrganizationInput>;
  salesForceData?: InputMaybe<SalesForceDataUpdateOneWithoutOrganizationInput>;
  ScheduleItems?: InputMaybe<ScheduleItemUpdateManyWithoutOrganizationInput>;
  self_sourcing_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  send_error_log_email?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  send_lead_routing_email?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sendgrid_email_domain?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sendgrid_email_domain_id?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  sequence?: InputMaybe<SequenceUpdateManyWithoutOrganzationInput>;
  SequenceCriteriaConditions?: InputMaybe<SequenceCriteriaConditionUpdateManyWithoutOrganizationInput>;
  SequenceDashboardViews?: InputMaybe<SequenceDashboardViewUpdateManyWithoutOrganizationInput>;
  SequenceEntryCriterias?: InputMaybe<SequenceEntryCriteriaUpdateManyWithoutOrganizationInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutOrganizationInput>;
  SequenceSteps?: InputMaybe<SequenceStepUpdateManyWithoutOrganizationInput>;
  set_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
  short_resting?: InputMaybe<IntFieldUpdateOperationsInput>;
  show_calendar_options?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_contract_duration_sales?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_lead_source_to_rep?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_nocontact_button?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_payment_terms_sales?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_rep_all_leads?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_rep_my_leads?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_system_view?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sites?: InputMaybe<SiteUpdateManyWithoutOrganizationInput>;
  sms_opt_in_dialog?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SMSItems?: InputMaybe<SmsItemUpdateManyWithoutOrganizationInput>;
  SnoozeTrackings?: InputMaybe<SnoozeTrackingUpdateManyWithoutOrganizationInput>;
  StepActionLogs?: InputMaybe<StepActionLogUpdateManyWithoutOrganizationInput>;
  StepActions?: InputMaybe<StepActionUpdateManyWithoutOrganizationInput>;
  StepConditions?: InputMaybe<StepConditionUpdateManyWithoutOrganizationInput>;
  teams?: InputMaybe<TeamUpdateManyWithoutOrganizationInput>;
  Template?: InputMaybe<TemplateUpdateManyWithoutOrganizationInput>;
  TemplateVersions?: InputMaybe<TemplateVersionUpdateManyWithoutOrganizationInput>;
  timekit_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timezone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transfer_timeout?: InputMaybe<IntFieldUpdateOperationsInput>;
  TransferAttempts?: InputMaybe<TransferAttemptUpdateManyWithoutOrganizationInput>;
  twilio_numbers?: InputMaybe<TwilioNumberUpdateManyWithoutOrganizationInput>;
  TwilioFeedbacks?: InputMaybe<TwilioFeedbackUpdateManyWithoutOrganizationInput>;
  TwilioInfo?: InputMaybe<TwilioInfoUpdateOneWithoutOrganzationInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  use_revenue_planning?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  UserCustomFieldFilters?: InputMaybe<UserCustomFieldFilterUpdateManyWithoutOrganizationInput>;
  users?: InputMaybe<UserUpdateManyWithoutOrganizationInput>;
  win_rep_day_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
};

export type OrganizationUpdateWithoutIntegration_Error_LogsInput = {
  ActivityMonitorMultiplier?: InputMaybe<ActivityMonitorMultiplierUpdateManyWithoutOrganizationInput>;
  algoFactor?: InputMaybe<AlgoFactorUpdateManyWithoutOrganizationInput>;
  allow_calendar_invite?: InputMaybe<BoolFieldUpdateOperationsInput>;
  allow_remove_step_action?: InputMaybe<BoolFieldUpdateOperationsInput>;
  allow_snooze_step_action?: InputMaybe<BoolFieldUpdateOperationsInput>;
  always_record?: InputMaybe<BoolFieldUpdateOperationsInput>;
  arv_initial_held_demos_generated_by_sdr_weight?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  AssociationHistories?: InputMaybe<AssociationHistoryUpdateManyWithoutOrganizationInput>;
  auto_call_interval?: InputMaybe<IntFieldUpdateOperationsInput>;
  auto_dial?: InputMaybe<BoolFieldUpdateOperationsInput>;
  automated_campaigns?: InputMaybe<AutomatedCampaignUpdateManyWithoutOrganizationInput>;
  automatic_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  AutoTransferSelections?: InputMaybe<AutoTransferSelectionUpdateManyWithoutOrganizationInput>;
  Availablities?: InputMaybe<AvailabilityUpdateManyWithoutOrganizationInput>;
  AverageOrderValue?: InputMaybe<AverageOrderValueUpdateManyWithoutOrganizationInput>;
  best_action_ever_multiplier?: InputMaybe<FloatFieldUpdateOperationsInput>;
  best_metrics?: InputMaybe<BestMetricRecordUpdateManyWithoutOrganizationInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptUpdateManyWithoutOrganizationInput>;
  callMeNows?: InputMaybe<CallMeNowUpdateManyWithoutOrganizationInput>;
  CallMultiplier?: InputMaybe<CallMultiplierUpdateManyWithoutOrganizationInput>;
  CallSharings?: InputMaybe<CallSharingUpdateManyWithoutOrganizationInput>;
  can_edit_sdr_transfer_rules?: InputMaybe<BoolFieldUpdateOperationsInput>;
  claim_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  concession?: InputMaybe<ConcessionUpdateManyWithoutOrganizationInput>;
  conference_region?: InputMaybe<EnumConferenceRegionFieldUpdateOperationsInput>;
  Conferences?: InputMaybe<ConferenceUpdateManyWithoutOrganizationInput>;
  ContactRequests?: InputMaybe<ContactRequestUpdateManyWithoutOrganizationInput>;
  Contacts?: InputMaybe<ContactUpdateManyWithoutOrganizationInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  custom_fields?: InputMaybe<CustomFieldUpdateManyWithoutOrganizationInput>;
  CustomObject?: InputMaybe<CustomObjectUpdateManyWithoutOrganizationInput>;
  CustomObjectField?: InputMaybe<CustomObjectFieldUpdateManyWithoutOrganizationInput>;
  CustomObjectFieldForeignKey?: InputMaybe<CustomObjectFieldForeignKeyUpdateManyWithoutOrganizationInput>;
  CustomObjectRecord?: InputMaybe<CustomObjectRecordUpdateManyWithoutOrganizationInput>;
  CustomObjectRow?: InputMaybe<CustomObjectRowUpdateManyWithoutOrganizationInput>;
  CustomQueueItems?: InputMaybe<CustomQueueItemUpdateManyWithoutOrganizationInput>;
  DashboardColumnOptions?: InputMaybe<DashboardColumnOptionUpdateManyWithoutOrganizationInput>;
  DashboardLeadFilters?: InputMaybe<DashboardLeadFilterUpdateManyWithoutOrganizationInput>;
  DashboardRepFilters?: InputMaybe<DashboardRepFilterUpdateManyWithoutOrganizationInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewUpdateManyWithoutOrganizationInput>;
  day_to_close?: InputMaybe<IntFieldUpdateOperationsInput>;
  default_demo_time_duration?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  demo_held_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_not_held_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_not_held_call_back_short_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_prep_time_minutes?: InputMaybe<IntFieldUpdateOperationsInput>;
  dev_account?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  dial_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  DispositionMultiplier?: InputMaybe<DispositionMultiplierUpdateManyWithoutOrganizationInput>;
  dispositions?: InputMaybe<DispositionUpdateManyWithoutOrganizationInput>;
  double_booking?: InputMaybe<BoolFieldUpdateOperationsInput>;
  dynamic_date_operators?: InputMaybe<BoolFieldUpdateOperationsInput>;
  edge_servers?: InputMaybe<OrganizationUpdateedge_ServersInput>;
  email_conflict_notification_email_list?: InputMaybe<OrganizationUpdateemail_Conflict_Notification_Email_ListInput>;
  EmailItems?: InputMaybe<EmailItemUpdateManyWithoutOrganizationInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordUpdateManyWithoutOrganizationInput>;
  EmailThreads?: InputMaybe<EmailThreadUpdateManyWithoutOrganizationInput>;
  enable_modified_metrics?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  enable_pay_period_view?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  enable_time_constraint?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  error_log_email_address?: InputMaybe<OrganizationUpdateerror_Log_Email_AddressInput>;
  estimated_average_num_of_weekly_overbooked_demo_slots?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  FolderPermissions?: InputMaybe<FolderPermissionUpdateManyWithoutOrganizationInput>;
  Folders?: InputMaybe<FolderUpdateManyWithoutOrganizationInput>;
  FutureBookingSetting?: InputMaybe<FutureBookingSettingUpdateOneWithoutOrganizationInput>;
  GlobalTransferRule?: InputMaybe<GlobalTransferRuleUpdateManyWithoutOrganizationInput>;
  held_demos_per_cc_connect?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  helpful_links?: InputMaybe<OrganizationUpdatehelpful_LinksInput>;
  HingePointMultiplier?: InputMaybe<HingePointMultiplierUpdateManyWithoutOrganizationInput>;
  hold_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
  hubspot_credential?: InputMaybe<HubSpotCredentialUpdateOneWithoutOrganizationInput>;
  hubspot_last_sync_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  hubspot_white_list?: InputMaybe<HubSpotWhitelistUpdateManyWithoutOrganizationInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inbound_close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_dm_connect_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_lt_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_revenue_value_of_sale?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_scheduled_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_set_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventUpdateManyWithoutOrganizationInput>;
  InboundConciergeWidget?: InputMaybe<InboundConciergeWidgetUpdateManyWithoutOrganizationInput>;
  industry_options?: InputMaybe<IndustryOptionUpdateManyWithoutOrganizationInput>;
  initial_sdr_sets_reps_day?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  integration_mappings?: InputMaybe<IntegrationMappingUpdateManyWithoutOrganizationInput>;
  IntegrationMappingCustomObject?: InputMaybe<IntegrationMappingCustomObjectUpdateManyWithoutOrganizationInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogUpdateManyWithoutOrganizationInput>;
  IntegrationReadOnlyObject?: InputMaybe<IntegrationReadOnlyObjectUpdateManyWithoutOrganizationInput>;
  international?: InputMaybe<BoolFieldUpdateOperationsInput>;
  is_email_domain_verified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  is_salesforce_sandbox?: InputMaybe<BoolFieldUpdateOperationsInput>;
  last_lead_value_compute_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutOrganizationInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryUpdateManyWithoutOrganizationInput>;
  lead_intent?: InputMaybe<LeadIntentUpdateManyWithoutOrganizationInput>;
  lead_release_same_rep_delay_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  lead_routing_email_list?: InputMaybe<OrganizationUpdatelead_Routing_Email_ListInput>;
  lead_routing_rules?: InputMaybe<RuleUpdateManyWithoutOrganizationInput>;
  LeadCustomObjectRowAssociation?: InputMaybe<LeadCustomObjectRowAssociationUpdateManyWithoutOrganizationInput>;
  LeadFieldMultiplier?: InputMaybe<LeadFieldMultiplierUpdateManyWithoutOrganizationInput>;
  LeadFieldMultiplierCustom?: InputMaybe<LeadFieldMultiplierCustomUpdateManyWithoutOrganizationInput>;
  leads?: InputMaybe<LeadUpdateManyWithoutOrganizationInput>;
  leadsource_options?: InputMaybe<LeadSourceOptionUpdateManyWithoutOrganizationInput>;
  LeadValue?: InputMaybe<LeadValueUpdateManyWithoutOrganizationInput>;
  LifeTimeValueMultiplier?: InputMaybe<LifeTimeValueMultiplierUpdateManyWithoutOrganizationInput>;
  LiveTransferSetting?: InputMaybe<LiveTransferSettingUpdateOneWithoutOrganizationInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusUpdateManyWithoutOrganizationInput>;
  ltv_multiplier_custom_fields?: InputMaybe<LifeTimeValueMultiplierCustomUpdateManyWithoutOrganizationInput>;
  made_contact_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  make_sale_address_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_business_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_city_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_country_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_first_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_industry_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_last_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_lead_source_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_primary_email_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_primary_phone_number_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_state_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_sub_industry_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_zip_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  maxium_day_lag_set_to_schedule?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  mrr?: InputMaybe<IntFieldUpdateOperationsInput>;
  mrr_label?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  mrr_required_on_sale?: InputMaybe<BoolFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  NoteItems?: InputMaybe<NoteItemUpdateManyWithoutOrganizationInput>;
  Notifications?: InputMaybe<NotificationUpdateManyWithoutOrganizationInput>;
  onboardComplete?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  Opportunity?: InputMaybe<SfOpportunityUpdateManyWithoutOrganizationInput>;
  organization_holidays?: InputMaybe<OrganizationHolidayUpdateManyWithoutOrganizationInput>;
  organization_hours?: InputMaybe<OrganizationHourUpdateManyWithoutOrganizationInput>;
  OrganizationPaymentSetting?: InputMaybe<OrganizationPaymentSettingsUpdateOneWithoutOrganizationInput>;
  OrganizationPaymentSettings?: InputMaybe<OrganizationPaymentSettingsUpdateOneWithoutOrganizationsInput>;
  orgCalendarInviteMetadata?: InputMaybe<OrgCalendarInviteMetadataUpdateManyWithoutOrganizationInput>;
  OrgDefaultPipelinePerecentValue?: InputMaybe<OrgDefaultPipelinePerecentValueUpdateOneWithoutOrganizationInput>;
  OrgHubSpotSync?: InputMaybe<OrgHubSpotSyncUpdateOneWithoutOrganizationInput>;
  OrgNoEventDate?: InputMaybe<OrgNoEventDateUpdateManyWithoutOrganizationInput>;
  OrgSalesforceSync?: InputMaybe<OrgSalesforceSyncUpdateOneWithoutOrganizationInput>;
  OrgTimeslotAvailability?: InputMaybe<OrgTimeslotAvailabilityUpdateManyWithoutOrganizationInput>;
  outbound_close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_dm_connect_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_lt_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_revenue_value_of_sale?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_scheduled_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_set_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  PandadocData?: InputMaybe<PandadocDataUpdateOneWithoutOrganizationInput>;
  PandaDocTemplate?: InputMaybe<PandaDocTemplateUpdateManyWithoutOrganizationInput>;
  participant_jitter_buffer_size?: InputMaybe<EnumJitterBufferSizeFieldUpdateOperationsInput>;
  preferred_goal_setting?: InputMaybe<NullableEnumGoalsettingFieldUpdateOperationsInput>;
  prepayment?: InputMaybe<BoolFieldUpdateOperationsInput>;
  ProductChangeLog?: InputMaybe<ProductChangeLogUpdateManyWithoutOrganizationInput>;
  products?: InputMaybe<ProductUpdateManyWithoutOrganizationInput>;
  re_cold_call_hour_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  recordingStatus?: InputMaybe<EnumRecordingStatusFieldUpdateOperationsInput>;
  release_lead_resting?: InputMaybe<IntFieldUpdateOperationsInput>;
  rep_claim_limit?: InputMaybe<IntFieldUpdateOperationsInput>;
  rep_lead_allotment?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  rep_level_call_transfer?: InputMaybe<BoolFieldUpdateOperationsInput>;
  reps_can_bulk_unassign?: InputMaybe<BoolFieldUpdateOperationsInput>;
  reps_can_view_all_notes?: InputMaybe<BoolFieldUpdateOperationsInput>;
  require_sale_notes?: InputMaybe<BoolFieldUpdateOperationsInput>;
  resting_after_skipped_CC?: InputMaybe<IntFieldUpdateOperationsInput>;
  resting_period?: InputMaybe<IntFieldUpdateOperationsInput>;
  resting_period_after_unsuccessful_cold_call?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  resting_period_after_unsuccessful_demo?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  revenue_plan?: InputMaybe<RevenuePlanItemUpdateManyWithoutOrganizationInput>;
  Routings?: InputMaybe<RoutingUpdateManyWithoutOrganizationInput>;
  sale_field_options?: InputMaybe<SaleFieldOptionUpdateManyWithoutOrganizationInput>;
  sale_notification_email_list?: InputMaybe<OrganizationUpdatesale_Notification_Email_ListInput>;
  SaleConfig?: InputMaybe<SaleConfigUpdateOneWithoutOrganizationInput>;
  SaleConfigPage?: InputMaybe<SaleConfigPageUpdateManyWithoutOrganizationInput>;
  SaleConfigSection?: InputMaybe<SaleConfigSectionUpdateManyWithoutOrganizationInput>;
  SaleCycles?: InputMaybe<SaleCycleUpdateManyWithoutOrganizationInput>;
  SaleItems?: InputMaybe<SaleItemUpdateManyWithoutOrganizationInput>;
  sales?: InputMaybe<SaleUpdateManyWithoutOrganizationInput>;
  salesforce_domain?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  salesforce_last_sync_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  SalesForceColumn?: InputMaybe<SalesForceColumnUpdateManyWithoutOrganizationInput>;
  salesForceData?: InputMaybe<SalesForceDataUpdateOneWithoutOrganizationInput>;
  ScheduleItems?: InputMaybe<ScheduleItemUpdateManyWithoutOrganizationInput>;
  self_sourcing_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  send_error_log_email?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  send_lead_routing_email?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sendgrid_email_domain?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sendgrid_email_domain_id?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  sequence?: InputMaybe<SequenceUpdateManyWithoutOrganzationInput>;
  SequenceCriteriaConditions?: InputMaybe<SequenceCriteriaConditionUpdateManyWithoutOrganizationInput>;
  SequenceDashboardViews?: InputMaybe<SequenceDashboardViewUpdateManyWithoutOrganizationInput>;
  SequenceEntryCriterias?: InputMaybe<SequenceEntryCriteriaUpdateManyWithoutOrganizationInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutOrganizationInput>;
  SequenceSteps?: InputMaybe<SequenceStepUpdateManyWithoutOrganizationInput>;
  set_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
  short_resting?: InputMaybe<IntFieldUpdateOperationsInput>;
  show_calendar_options?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_contract_duration_sales?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_lead_source_to_rep?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_nocontact_button?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_payment_terms_sales?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_rep_all_leads?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_rep_my_leads?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_system_view?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sites?: InputMaybe<SiteUpdateManyWithoutOrganizationInput>;
  sms_opt_in_dialog?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SMSItems?: InputMaybe<SmsItemUpdateManyWithoutOrganizationInput>;
  SnoozeTrackings?: InputMaybe<SnoozeTrackingUpdateManyWithoutOrganizationInput>;
  StepActionLogs?: InputMaybe<StepActionLogUpdateManyWithoutOrganizationInput>;
  StepActions?: InputMaybe<StepActionUpdateManyWithoutOrganizationInput>;
  StepConditions?: InputMaybe<StepConditionUpdateManyWithoutOrganizationInput>;
  teams?: InputMaybe<TeamUpdateManyWithoutOrganizationInput>;
  Template?: InputMaybe<TemplateUpdateManyWithoutOrganizationInput>;
  TemplateVersions?: InputMaybe<TemplateVersionUpdateManyWithoutOrganizationInput>;
  timekit_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timezone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transfer_timeout?: InputMaybe<IntFieldUpdateOperationsInput>;
  TransferAttempts?: InputMaybe<TransferAttemptUpdateManyWithoutOrganizationInput>;
  twilio_numbers?: InputMaybe<TwilioNumberUpdateManyWithoutOrganizationInput>;
  TwilioFeedbacks?: InputMaybe<TwilioFeedbackUpdateManyWithoutOrganizationInput>;
  TwilioInfo?: InputMaybe<TwilioInfoUpdateOneWithoutOrganzationInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  use_revenue_planning?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  UserCustomFieldFilters?: InputMaybe<UserCustomFieldFilterUpdateManyWithoutOrganizationInput>;
  users?: InputMaybe<UserUpdateManyWithoutOrganizationInput>;
  win_rep_day_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
};

export type OrganizationUpdateWithoutIntegrationOperationLogInput = {
  ActivityMonitorMultiplier?: InputMaybe<ActivityMonitorMultiplierUpdateManyWithoutOrganizationInput>;
  algoFactor?: InputMaybe<AlgoFactorUpdateManyWithoutOrganizationInput>;
  allow_calendar_invite?: InputMaybe<BoolFieldUpdateOperationsInput>;
  allow_remove_step_action?: InputMaybe<BoolFieldUpdateOperationsInput>;
  allow_snooze_step_action?: InputMaybe<BoolFieldUpdateOperationsInput>;
  always_record?: InputMaybe<BoolFieldUpdateOperationsInput>;
  arv_initial_held_demos_generated_by_sdr_weight?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  AssociationHistories?: InputMaybe<AssociationHistoryUpdateManyWithoutOrganizationInput>;
  auto_call_interval?: InputMaybe<IntFieldUpdateOperationsInput>;
  auto_dial?: InputMaybe<BoolFieldUpdateOperationsInput>;
  automated_campaigns?: InputMaybe<AutomatedCampaignUpdateManyWithoutOrganizationInput>;
  automatic_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  AutoTransferSelections?: InputMaybe<AutoTransferSelectionUpdateManyWithoutOrganizationInput>;
  Availablities?: InputMaybe<AvailabilityUpdateManyWithoutOrganizationInput>;
  AverageOrderValue?: InputMaybe<AverageOrderValueUpdateManyWithoutOrganizationInput>;
  best_action_ever_multiplier?: InputMaybe<FloatFieldUpdateOperationsInput>;
  best_metrics?: InputMaybe<BestMetricRecordUpdateManyWithoutOrganizationInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptUpdateManyWithoutOrganizationInput>;
  callMeNows?: InputMaybe<CallMeNowUpdateManyWithoutOrganizationInput>;
  CallMultiplier?: InputMaybe<CallMultiplierUpdateManyWithoutOrganizationInput>;
  CallSharings?: InputMaybe<CallSharingUpdateManyWithoutOrganizationInput>;
  can_edit_sdr_transfer_rules?: InputMaybe<BoolFieldUpdateOperationsInput>;
  claim_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  concession?: InputMaybe<ConcessionUpdateManyWithoutOrganizationInput>;
  conference_region?: InputMaybe<EnumConferenceRegionFieldUpdateOperationsInput>;
  Conferences?: InputMaybe<ConferenceUpdateManyWithoutOrganizationInput>;
  ContactRequests?: InputMaybe<ContactRequestUpdateManyWithoutOrganizationInput>;
  Contacts?: InputMaybe<ContactUpdateManyWithoutOrganizationInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  custom_fields?: InputMaybe<CustomFieldUpdateManyWithoutOrganizationInput>;
  CustomObject?: InputMaybe<CustomObjectUpdateManyWithoutOrganizationInput>;
  CustomObjectField?: InputMaybe<CustomObjectFieldUpdateManyWithoutOrganizationInput>;
  CustomObjectFieldForeignKey?: InputMaybe<CustomObjectFieldForeignKeyUpdateManyWithoutOrganizationInput>;
  CustomObjectRecord?: InputMaybe<CustomObjectRecordUpdateManyWithoutOrganizationInput>;
  CustomObjectRow?: InputMaybe<CustomObjectRowUpdateManyWithoutOrganizationInput>;
  CustomQueueItems?: InputMaybe<CustomQueueItemUpdateManyWithoutOrganizationInput>;
  DashboardColumnOptions?: InputMaybe<DashboardColumnOptionUpdateManyWithoutOrganizationInput>;
  DashboardLeadFilters?: InputMaybe<DashboardLeadFilterUpdateManyWithoutOrganizationInput>;
  DashboardRepFilters?: InputMaybe<DashboardRepFilterUpdateManyWithoutOrganizationInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewUpdateManyWithoutOrganizationInput>;
  day_to_close?: InputMaybe<IntFieldUpdateOperationsInput>;
  default_demo_time_duration?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  demo_held_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_not_held_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_not_held_call_back_short_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_prep_time_minutes?: InputMaybe<IntFieldUpdateOperationsInput>;
  dev_account?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  dial_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  DispositionMultiplier?: InputMaybe<DispositionMultiplierUpdateManyWithoutOrganizationInput>;
  dispositions?: InputMaybe<DispositionUpdateManyWithoutOrganizationInput>;
  double_booking?: InputMaybe<BoolFieldUpdateOperationsInput>;
  dynamic_date_operators?: InputMaybe<BoolFieldUpdateOperationsInput>;
  edge_servers?: InputMaybe<OrganizationUpdateedge_ServersInput>;
  email_conflict_notification_email_list?: InputMaybe<OrganizationUpdateemail_Conflict_Notification_Email_ListInput>;
  EmailItems?: InputMaybe<EmailItemUpdateManyWithoutOrganizationInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordUpdateManyWithoutOrganizationInput>;
  EmailThreads?: InputMaybe<EmailThreadUpdateManyWithoutOrganizationInput>;
  enable_modified_metrics?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  enable_pay_period_view?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  enable_time_constraint?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  error_log_email_address?: InputMaybe<OrganizationUpdateerror_Log_Email_AddressInput>;
  estimated_average_num_of_weekly_overbooked_demo_slots?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  FolderPermissions?: InputMaybe<FolderPermissionUpdateManyWithoutOrganizationInput>;
  Folders?: InputMaybe<FolderUpdateManyWithoutOrganizationInput>;
  FutureBookingSetting?: InputMaybe<FutureBookingSettingUpdateOneWithoutOrganizationInput>;
  GlobalTransferRule?: InputMaybe<GlobalTransferRuleUpdateManyWithoutOrganizationInput>;
  held_demos_per_cc_connect?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  helpful_links?: InputMaybe<OrganizationUpdatehelpful_LinksInput>;
  HingePointMultiplier?: InputMaybe<HingePointMultiplierUpdateManyWithoutOrganizationInput>;
  hold_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
  hubspot_credential?: InputMaybe<HubSpotCredentialUpdateOneWithoutOrganizationInput>;
  hubspot_last_sync_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  hubspot_white_list?: InputMaybe<HubSpotWhitelistUpdateManyWithoutOrganizationInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inbound_close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_dm_connect_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_lt_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_revenue_value_of_sale?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_scheduled_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_set_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventUpdateManyWithoutOrganizationInput>;
  InboundConciergeWidget?: InputMaybe<InboundConciergeWidgetUpdateManyWithoutOrganizationInput>;
  industry_options?: InputMaybe<IndustryOptionUpdateManyWithoutOrganizationInput>;
  initial_sdr_sets_reps_day?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  integration_error_logs?: InputMaybe<IntegrationErrorLogUpdateManyWithoutOrganizationInput>;
  integration_mappings?: InputMaybe<IntegrationMappingUpdateManyWithoutOrganizationInput>;
  IntegrationMappingCustomObject?: InputMaybe<IntegrationMappingCustomObjectUpdateManyWithoutOrganizationInput>;
  IntegrationReadOnlyObject?: InputMaybe<IntegrationReadOnlyObjectUpdateManyWithoutOrganizationInput>;
  international?: InputMaybe<BoolFieldUpdateOperationsInput>;
  is_email_domain_verified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  is_salesforce_sandbox?: InputMaybe<BoolFieldUpdateOperationsInput>;
  last_lead_value_compute_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutOrganizationInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryUpdateManyWithoutOrganizationInput>;
  lead_intent?: InputMaybe<LeadIntentUpdateManyWithoutOrganizationInput>;
  lead_release_same_rep_delay_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  lead_routing_email_list?: InputMaybe<OrganizationUpdatelead_Routing_Email_ListInput>;
  lead_routing_rules?: InputMaybe<RuleUpdateManyWithoutOrganizationInput>;
  LeadCustomObjectRowAssociation?: InputMaybe<LeadCustomObjectRowAssociationUpdateManyWithoutOrganizationInput>;
  LeadFieldMultiplier?: InputMaybe<LeadFieldMultiplierUpdateManyWithoutOrganizationInput>;
  LeadFieldMultiplierCustom?: InputMaybe<LeadFieldMultiplierCustomUpdateManyWithoutOrganizationInput>;
  leads?: InputMaybe<LeadUpdateManyWithoutOrganizationInput>;
  leadsource_options?: InputMaybe<LeadSourceOptionUpdateManyWithoutOrganizationInput>;
  LeadValue?: InputMaybe<LeadValueUpdateManyWithoutOrganizationInput>;
  LifeTimeValueMultiplier?: InputMaybe<LifeTimeValueMultiplierUpdateManyWithoutOrganizationInput>;
  LiveTransferSetting?: InputMaybe<LiveTransferSettingUpdateOneWithoutOrganizationInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusUpdateManyWithoutOrganizationInput>;
  ltv_multiplier_custom_fields?: InputMaybe<LifeTimeValueMultiplierCustomUpdateManyWithoutOrganizationInput>;
  made_contact_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  make_sale_address_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_business_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_city_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_country_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_first_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_industry_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_last_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_lead_source_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_primary_email_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_primary_phone_number_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_state_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_sub_industry_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_zip_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  maxium_day_lag_set_to_schedule?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  mrr?: InputMaybe<IntFieldUpdateOperationsInput>;
  mrr_label?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  mrr_required_on_sale?: InputMaybe<BoolFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  NoteItems?: InputMaybe<NoteItemUpdateManyWithoutOrganizationInput>;
  Notifications?: InputMaybe<NotificationUpdateManyWithoutOrganizationInput>;
  onboardComplete?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  Opportunity?: InputMaybe<SfOpportunityUpdateManyWithoutOrganizationInput>;
  organization_holidays?: InputMaybe<OrganizationHolidayUpdateManyWithoutOrganizationInput>;
  organization_hours?: InputMaybe<OrganizationHourUpdateManyWithoutOrganizationInput>;
  OrganizationPaymentSetting?: InputMaybe<OrganizationPaymentSettingsUpdateOneWithoutOrganizationInput>;
  OrganizationPaymentSettings?: InputMaybe<OrganizationPaymentSettingsUpdateOneWithoutOrganizationsInput>;
  orgCalendarInviteMetadata?: InputMaybe<OrgCalendarInviteMetadataUpdateManyWithoutOrganizationInput>;
  OrgDefaultPipelinePerecentValue?: InputMaybe<OrgDefaultPipelinePerecentValueUpdateOneWithoutOrganizationInput>;
  OrgHubSpotSync?: InputMaybe<OrgHubSpotSyncUpdateOneWithoutOrganizationInput>;
  OrgNoEventDate?: InputMaybe<OrgNoEventDateUpdateManyWithoutOrganizationInput>;
  OrgSalesforceSync?: InputMaybe<OrgSalesforceSyncUpdateOneWithoutOrganizationInput>;
  OrgTimeslotAvailability?: InputMaybe<OrgTimeslotAvailabilityUpdateManyWithoutOrganizationInput>;
  outbound_close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_dm_connect_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_lt_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_revenue_value_of_sale?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_scheduled_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_set_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  PandadocData?: InputMaybe<PandadocDataUpdateOneWithoutOrganizationInput>;
  PandaDocTemplate?: InputMaybe<PandaDocTemplateUpdateManyWithoutOrganizationInput>;
  participant_jitter_buffer_size?: InputMaybe<EnumJitterBufferSizeFieldUpdateOperationsInput>;
  preferred_goal_setting?: InputMaybe<NullableEnumGoalsettingFieldUpdateOperationsInput>;
  prepayment?: InputMaybe<BoolFieldUpdateOperationsInput>;
  ProductChangeLog?: InputMaybe<ProductChangeLogUpdateManyWithoutOrganizationInput>;
  products?: InputMaybe<ProductUpdateManyWithoutOrganizationInput>;
  re_cold_call_hour_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  recordingStatus?: InputMaybe<EnumRecordingStatusFieldUpdateOperationsInput>;
  release_lead_resting?: InputMaybe<IntFieldUpdateOperationsInput>;
  rep_claim_limit?: InputMaybe<IntFieldUpdateOperationsInput>;
  rep_lead_allotment?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  rep_level_call_transfer?: InputMaybe<BoolFieldUpdateOperationsInput>;
  reps_can_bulk_unassign?: InputMaybe<BoolFieldUpdateOperationsInput>;
  reps_can_view_all_notes?: InputMaybe<BoolFieldUpdateOperationsInput>;
  require_sale_notes?: InputMaybe<BoolFieldUpdateOperationsInput>;
  resting_after_skipped_CC?: InputMaybe<IntFieldUpdateOperationsInput>;
  resting_period?: InputMaybe<IntFieldUpdateOperationsInput>;
  resting_period_after_unsuccessful_cold_call?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  resting_period_after_unsuccessful_demo?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  revenue_plan?: InputMaybe<RevenuePlanItemUpdateManyWithoutOrganizationInput>;
  Routings?: InputMaybe<RoutingUpdateManyWithoutOrganizationInput>;
  sale_field_options?: InputMaybe<SaleFieldOptionUpdateManyWithoutOrganizationInput>;
  sale_notification_email_list?: InputMaybe<OrganizationUpdatesale_Notification_Email_ListInput>;
  SaleConfig?: InputMaybe<SaleConfigUpdateOneWithoutOrganizationInput>;
  SaleConfigPage?: InputMaybe<SaleConfigPageUpdateManyWithoutOrganizationInput>;
  SaleConfigSection?: InputMaybe<SaleConfigSectionUpdateManyWithoutOrganizationInput>;
  SaleCycles?: InputMaybe<SaleCycleUpdateManyWithoutOrganizationInput>;
  SaleItems?: InputMaybe<SaleItemUpdateManyWithoutOrganizationInput>;
  sales?: InputMaybe<SaleUpdateManyWithoutOrganizationInput>;
  salesforce_domain?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  salesforce_last_sync_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  SalesForceColumn?: InputMaybe<SalesForceColumnUpdateManyWithoutOrganizationInput>;
  salesForceData?: InputMaybe<SalesForceDataUpdateOneWithoutOrganizationInput>;
  ScheduleItems?: InputMaybe<ScheduleItemUpdateManyWithoutOrganizationInput>;
  self_sourcing_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  send_error_log_email?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  send_lead_routing_email?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sendgrid_email_domain?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sendgrid_email_domain_id?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  sequence?: InputMaybe<SequenceUpdateManyWithoutOrganzationInput>;
  SequenceCriteriaConditions?: InputMaybe<SequenceCriteriaConditionUpdateManyWithoutOrganizationInput>;
  SequenceDashboardViews?: InputMaybe<SequenceDashboardViewUpdateManyWithoutOrganizationInput>;
  SequenceEntryCriterias?: InputMaybe<SequenceEntryCriteriaUpdateManyWithoutOrganizationInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutOrganizationInput>;
  SequenceSteps?: InputMaybe<SequenceStepUpdateManyWithoutOrganizationInput>;
  set_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
  short_resting?: InputMaybe<IntFieldUpdateOperationsInput>;
  show_calendar_options?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_contract_duration_sales?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_lead_source_to_rep?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_nocontact_button?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_payment_terms_sales?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_rep_all_leads?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_rep_my_leads?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_system_view?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sites?: InputMaybe<SiteUpdateManyWithoutOrganizationInput>;
  sms_opt_in_dialog?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SMSItems?: InputMaybe<SmsItemUpdateManyWithoutOrganizationInput>;
  SnoozeTrackings?: InputMaybe<SnoozeTrackingUpdateManyWithoutOrganizationInput>;
  StepActionLogs?: InputMaybe<StepActionLogUpdateManyWithoutOrganizationInput>;
  StepActions?: InputMaybe<StepActionUpdateManyWithoutOrganizationInput>;
  StepConditions?: InputMaybe<StepConditionUpdateManyWithoutOrganizationInput>;
  teams?: InputMaybe<TeamUpdateManyWithoutOrganizationInput>;
  Template?: InputMaybe<TemplateUpdateManyWithoutOrganizationInput>;
  TemplateVersions?: InputMaybe<TemplateVersionUpdateManyWithoutOrganizationInput>;
  timekit_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timezone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transfer_timeout?: InputMaybe<IntFieldUpdateOperationsInput>;
  TransferAttempts?: InputMaybe<TransferAttemptUpdateManyWithoutOrganizationInput>;
  twilio_numbers?: InputMaybe<TwilioNumberUpdateManyWithoutOrganizationInput>;
  TwilioFeedbacks?: InputMaybe<TwilioFeedbackUpdateManyWithoutOrganizationInput>;
  TwilioInfo?: InputMaybe<TwilioInfoUpdateOneWithoutOrganzationInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  use_revenue_planning?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  UserCustomFieldFilters?: InputMaybe<UserCustomFieldFilterUpdateManyWithoutOrganizationInput>;
  users?: InputMaybe<UserUpdateManyWithoutOrganizationInput>;
  win_rep_day_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
};

export type OrganizationUpdateWithoutLead_ActivitiesInput = {
  ActivityMonitorMultiplier?: InputMaybe<ActivityMonitorMultiplierUpdateManyWithoutOrganizationInput>;
  algoFactor?: InputMaybe<AlgoFactorUpdateManyWithoutOrganizationInput>;
  allow_calendar_invite?: InputMaybe<BoolFieldUpdateOperationsInput>;
  allow_remove_step_action?: InputMaybe<BoolFieldUpdateOperationsInput>;
  allow_snooze_step_action?: InputMaybe<BoolFieldUpdateOperationsInput>;
  always_record?: InputMaybe<BoolFieldUpdateOperationsInput>;
  arv_initial_held_demos_generated_by_sdr_weight?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  AssociationHistories?: InputMaybe<AssociationHistoryUpdateManyWithoutOrganizationInput>;
  auto_call_interval?: InputMaybe<IntFieldUpdateOperationsInput>;
  auto_dial?: InputMaybe<BoolFieldUpdateOperationsInput>;
  automated_campaigns?: InputMaybe<AutomatedCampaignUpdateManyWithoutOrganizationInput>;
  automatic_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  AutoTransferSelections?: InputMaybe<AutoTransferSelectionUpdateManyWithoutOrganizationInput>;
  Availablities?: InputMaybe<AvailabilityUpdateManyWithoutOrganizationInput>;
  AverageOrderValue?: InputMaybe<AverageOrderValueUpdateManyWithoutOrganizationInput>;
  best_action_ever_multiplier?: InputMaybe<FloatFieldUpdateOperationsInput>;
  best_metrics?: InputMaybe<BestMetricRecordUpdateManyWithoutOrganizationInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptUpdateManyWithoutOrganizationInput>;
  callMeNows?: InputMaybe<CallMeNowUpdateManyWithoutOrganizationInput>;
  CallMultiplier?: InputMaybe<CallMultiplierUpdateManyWithoutOrganizationInput>;
  CallSharings?: InputMaybe<CallSharingUpdateManyWithoutOrganizationInput>;
  can_edit_sdr_transfer_rules?: InputMaybe<BoolFieldUpdateOperationsInput>;
  claim_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  concession?: InputMaybe<ConcessionUpdateManyWithoutOrganizationInput>;
  conference_region?: InputMaybe<EnumConferenceRegionFieldUpdateOperationsInput>;
  Conferences?: InputMaybe<ConferenceUpdateManyWithoutOrganizationInput>;
  ContactRequests?: InputMaybe<ContactRequestUpdateManyWithoutOrganizationInput>;
  Contacts?: InputMaybe<ContactUpdateManyWithoutOrganizationInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  custom_fields?: InputMaybe<CustomFieldUpdateManyWithoutOrganizationInput>;
  CustomObject?: InputMaybe<CustomObjectUpdateManyWithoutOrganizationInput>;
  CustomObjectField?: InputMaybe<CustomObjectFieldUpdateManyWithoutOrganizationInput>;
  CustomObjectFieldForeignKey?: InputMaybe<CustomObjectFieldForeignKeyUpdateManyWithoutOrganizationInput>;
  CustomObjectRecord?: InputMaybe<CustomObjectRecordUpdateManyWithoutOrganizationInput>;
  CustomObjectRow?: InputMaybe<CustomObjectRowUpdateManyWithoutOrganizationInput>;
  CustomQueueItems?: InputMaybe<CustomQueueItemUpdateManyWithoutOrganizationInput>;
  DashboardColumnOptions?: InputMaybe<DashboardColumnOptionUpdateManyWithoutOrganizationInput>;
  DashboardLeadFilters?: InputMaybe<DashboardLeadFilterUpdateManyWithoutOrganizationInput>;
  DashboardRepFilters?: InputMaybe<DashboardRepFilterUpdateManyWithoutOrganizationInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewUpdateManyWithoutOrganizationInput>;
  day_to_close?: InputMaybe<IntFieldUpdateOperationsInput>;
  default_demo_time_duration?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  demo_held_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_not_held_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_not_held_call_back_short_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_prep_time_minutes?: InputMaybe<IntFieldUpdateOperationsInput>;
  dev_account?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  dial_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  DispositionMultiplier?: InputMaybe<DispositionMultiplierUpdateManyWithoutOrganizationInput>;
  dispositions?: InputMaybe<DispositionUpdateManyWithoutOrganizationInput>;
  double_booking?: InputMaybe<BoolFieldUpdateOperationsInput>;
  dynamic_date_operators?: InputMaybe<BoolFieldUpdateOperationsInput>;
  edge_servers?: InputMaybe<OrganizationUpdateedge_ServersInput>;
  email_conflict_notification_email_list?: InputMaybe<OrganizationUpdateemail_Conflict_Notification_Email_ListInput>;
  EmailItems?: InputMaybe<EmailItemUpdateManyWithoutOrganizationInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordUpdateManyWithoutOrganizationInput>;
  EmailThreads?: InputMaybe<EmailThreadUpdateManyWithoutOrganizationInput>;
  enable_modified_metrics?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  enable_pay_period_view?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  enable_time_constraint?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  error_log_email_address?: InputMaybe<OrganizationUpdateerror_Log_Email_AddressInput>;
  estimated_average_num_of_weekly_overbooked_demo_slots?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  FolderPermissions?: InputMaybe<FolderPermissionUpdateManyWithoutOrganizationInput>;
  Folders?: InputMaybe<FolderUpdateManyWithoutOrganizationInput>;
  FutureBookingSetting?: InputMaybe<FutureBookingSettingUpdateOneWithoutOrganizationInput>;
  GlobalTransferRule?: InputMaybe<GlobalTransferRuleUpdateManyWithoutOrganizationInput>;
  held_demos_per_cc_connect?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  helpful_links?: InputMaybe<OrganizationUpdatehelpful_LinksInput>;
  HingePointMultiplier?: InputMaybe<HingePointMultiplierUpdateManyWithoutOrganizationInput>;
  hold_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
  hubspot_credential?: InputMaybe<HubSpotCredentialUpdateOneWithoutOrganizationInput>;
  hubspot_last_sync_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  hubspot_white_list?: InputMaybe<HubSpotWhitelistUpdateManyWithoutOrganizationInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inbound_close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_dm_connect_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_lt_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_revenue_value_of_sale?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_scheduled_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_set_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventUpdateManyWithoutOrganizationInput>;
  InboundConciergeWidget?: InputMaybe<InboundConciergeWidgetUpdateManyWithoutOrganizationInput>;
  industry_options?: InputMaybe<IndustryOptionUpdateManyWithoutOrganizationInput>;
  initial_sdr_sets_reps_day?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  integration_error_logs?: InputMaybe<IntegrationErrorLogUpdateManyWithoutOrganizationInput>;
  integration_mappings?: InputMaybe<IntegrationMappingUpdateManyWithoutOrganizationInput>;
  IntegrationMappingCustomObject?: InputMaybe<IntegrationMappingCustomObjectUpdateManyWithoutOrganizationInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogUpdateManyWithoutOrganizationInput>;
  IntegrationReadOnlyObject?: InputMaybe<IntegrationReadOnlyObjectUpdateManyWithoutOrganizationInput>;
  international?: InputMaybe<BoolFieldUpdateOperationsInput>;
  is_email_domain_verified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  is_salesforce_sandbox?: InputMaybe<BoolFieldUpdateOperationsInput>;
  last_lead_value_compute_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryUpdateManyWithoutOrganizationInput>;
  lead_intent?: InputMaybe<LeadIntentUpdateManyWithoutOrganizationInput>;
  lead_release_same_rep_delay_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  lead_routing_email_list?: InputMaybe<OrganizationUpdatelead_Routing_Email_ListInput>;
  lead_routing_rules?: InputMaybe<RuleUpdateManyWithoutOrganizationInput>;
  LeadCustomObjectRowAssociation?: InputMaybe<LeadCustomObjectRowAssociationUpdateManyWithoutOrganizationInput>;
  LeadFieldMultiplier?: InputMaybe<LeadFieldMultiplierUpdateManyWithoutOrganizationInput>;
  LeadFieldMultiplierCustom?: InputMaybe<LeadFieldMultiplierCustomUpdateManyWithoutOrganizationInput>;
  leads?: InputMaybe<LeadUpdateManyWithoutOrganizationInput>;
  leadsource_options?: InputMaybe<LeadSourceOptionUpdateManyWithoutOrganizationInput>;
  LeadValue?: InputMaybe<LeadValueUpdateManyWithoutOrganizationInput>;
  LifeTimeValueMultiplier?: InputMaybe<LifeTimeValueMultiplierUpdateManyWithoutOrganizationInput>;
  LiveTransferSetting?: InputMaybe<LiveTransferSettingUpdateOneWithoutOrganizationInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusUpdateManyWithoutOrganizationInput>;
  ltv_multiplier_custom_fields?: InputMaybe<LifeTimeValueMultiplierCustomUpdateManyWithoutOrganizationInput>;
  made_contact_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  make_sale_address_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_business_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_city_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_country_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_first_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_industry_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_last_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_lead_source_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_primary_email_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_primary_phone_number_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_state_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_sub_industry_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_zip_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  maxium_day_lag_set_to_schedule?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  mrr?: InputMaybe<IntFieldUpdateOperationsInput>;
  mrr_label?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  mrr_required_on_sale?: InputMaybe<BoolFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  NoteItems?: InputMaybe<NoteItemUpdateManyWithoutOrganizationInput>;
  Notifications?: InputMaybe<NotificationUpdateManyWithoutOrganizationInput>;
  onboardComplete?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  Opportunity?: InputMaybe<SfOpportunityUpdateManyWithoutOrganizationInput>;
  organization_holidays?: InputMaybe<OrganizationHolidayUpdateManyWithoutOrganizationInput>;
  organization_hours?: InputMaybe<OrganizationHourUpdateManyWithoutOrganizationInput>;
  OrganizationPaymentSetting?: InputMaybe<OrganizationPaymentSettingsUpdateOneWithoutOrganizationInput>;
  OrganizationPaymentSettings?: InputMaybe<OrganizationPaymentSettingsUpdateOneWithoutOrganizationsInput>;
  orgCalendarInviteMetadata?: InputMaybe<OrgCalendarInviteMetadataUpdateManyWithoutOrganizationInput>;
  OrgDefaultPipelinePerecentValue?: InputMaybe<OrgDefaultPipelinePerecentValueUpdateOneWithoutOrganizationInput>;
  OrgHubSpotSync?: InputMaybe<OrgHubSpotSyncUpdateOneWithoutOrganizationInput>;
  OrgNoEventDate?: InputMaybe<OrgNoEventDateUpdateManyWithoutOrganizationInput>;
  OrgSalesforceSync?: InputMaybe<OrgSalesforceSyncUpdateOneWithoutOrganizationInput>;
  OrgTimeslotAvailability?: InputMaybe<OrgTimeslotAvailabilityUpdateManyWithoutOrganizationInput>;
  outbound_close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_dm_connect_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_lt_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_revenue_value_of_sale?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_scheduled_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_set_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  PandadocData?: InputMaybe<PandadocDataUpdateOneWithoutOrganizationInput>;
  PandaDocTemplate?: InputMaybe<PandaDocTemplateUpdateManyWithoutOrganizationInput>;
  participant_jitter_buffer_size?: InputMaybe<EnumJitterBufferSizeFieldUpdateOperationsInput>;
  preferred_goal_setting?: InputMaybe<NullableEnumGoalsettingFieldUpdateOperationsInput>;
  prepayment?: InputMaybe<BoolFieldUpdateOperationsInput>;
  ProductChangeLog?: InputMaybe<ProductChangeLogUpdateManyWithoutOrganizationInput>;
  products?: InputMaybe<ProductUpdateManyWithoutOrganizationInput>;
  re_cold_call_hour_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  recordingStatus?: InputMaybe<EnumRecordingStatusFieldUpdateOperationsInput>;
  release_lead_resting?: InputMaybe<IntFieldUpdateOperationsInput>;
  rep_claim_limit?: InputMaybe<IntFieldUpdateOperationsInput>;
  rep_lead_allotment?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  rep_level_call_transfer?: InputMaybe<BoolFieldUpdateOperationsInput>;
  reps_can_bulk_unassign?: InputMaybe<BoolFieldUpdateOperationsInput>;
  reps_can_view_all_notes?: InputMaybe<BoolFieldUpdateOperationsInput>;
  require_sale_notes?: InputMaybe<BoolFieldUpdateOperationsInput>;
  resting_after_skipped_CC?: InputMaybe<IntFieldUpdateOperationsInput>;
  resting_period?: InputMaybe<IntFieldUpdateOperationsInput>;
  resting_period_after_unsuccessful_cold_call?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  resting_period_after_unsuccessful_demo?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  revenue_plan?: InputMaybe<RevenuePlanItemUpdateManyWithoutOrganizationInput>;
  Routings?: InputMaybe<RoutingUpdateManyWithoutOrganizationInput>;
  sale_field_options?: InputMaybe<SaleFieldOptionUpdateManyWithoutOrganizationInput>;
  sale_notification_email_list?: InputMaybe<OrganizationUpdatesale_Notification_Email_ListInput>;
  SaleConfig?: InputMaybe<SaleConfigUpdateOneWithoutOrganizationInput>;
  SaleConfigPage?: InputMaybe<SaleConfigPageUpdateManyWithoutOrganizationInput>;
  SaleConfigSection?: InputMaybe<SaleConfigSectionUpdateManyWithoutOrganizationInput>;
  SaleCycles?: InputMaybe<SaleCycleUpdateManyWithoutOrganizationInput>;
  SaleItems?: InputMaybe<SaleItemUpdateManyWithoutOrganizationInput>;
  sales?: InputMaybe<SaleUpdateManyWithoutOrganizationInput>;
  salesforce_domain?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  salesforce_last_sync_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  SalesForceColumn?: InputMaybe<SalesForceColumnUpdateManyWithoutOrganizationInput>;
  salesForceData?: InputMaybe<SalesForceDataUpdateOneWithoutOrganizationInput>;
  ScheduleItems?: InputMaybe<ScheduleItemUpdateManyWithoutOrganizationInput>;
  self_sourcing_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  send_error_log_email?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  send_lead_routing_email?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sendgrid_email_domain?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sendgrid_email_domain_id?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  sequence?: InputMaybe<SequenceUpdateManyWithoutOrganzationInput>;
  SequenceCriteriaConditions?: InputMaybe<SequenceCriteriaConditionUpdateManyWithoutOrganizationInput>;
  SequenceDashboardViews?: InputMaybe<SequenceDashboardViewUpdateManyWithoutOrganizationInput>;
  SequenceEntryCriterias?: InputMaybe<SequenceEntryCriteriaUpdateManyWithoutOrganizationInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutOrganizationInput>;
  SequenceSteps?: InputMaybe<SequenceStepUpdateManyWithoutOrganizationInput>;
  set_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
  short_resting?: InputMaybe<IntFieldUpdateOperationsInput>;
  show_calendar_options?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_contract_duration_sales?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_lead_source_to_rep?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_nocontact_button?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_payment_terms_sales?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_rep_all_leads?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_rep_my_leads?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_system_view?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sites?: InputMaybe<SiteUpdateManyWithoutOrganizationInput>;
  sms_opt_in_dialog?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SMSItems?: InputMaybe<SmsItemUpdateManyWithoutOrganizationInput>;
  SnoozeTrackings?: InputMaybe<SnoozeTrackingUpdateManyWithoutOrganizationInput>;
  StepActionLogs?: InputMaybe<StepActionLogUpdateManyWithoutOrganizationInput>;
  StepActions?: InputMaybe<StepActionUpdateManyWithoutOrganizationInput>;
  StepConditions?: InputMaybe<StepConditionUpdateManyWithoutOrganizationInput>;
  teams?: InputMaybe<TeamUpdateManyWithoutOrganizationInput>;
  Template?: InputMaybe<TemplateUpdateManyWithoutOrganizationInput>;
  TemplateVersions?: InputMaybe<TemplateVersionUpdateManyWithoutOrganizationInput>;
  timekit_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timezone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transfer_timeout?: InputMaybe<IntFieldUpdateOperationsInput>;
  TransferAttempts?: InputMaybe<TransferAttemptUpdateManyWithoutOrganizationInput>;
  twilio_numbers?: InputMaybe<TwilioNumberUpdateManyWithoutOrganizationInput>;
  TwilioFeedbacks?: InputMaybe<TwilioFeedbackUpdateManyWithoutOrganizationInput>;
  TwilioInfo?: InputMaybe<TwilioInfoUpdateOneWithoutOrganzationInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  use_revenue_planning?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  UserCustomFieldFilters?: InputMaybe<UserCustomFieldFilterUpdateManyWithoutOrganizationInput>;
  users?: InputMaybe<UserUpdateManyWithoutOrganizationInput>;
  win_rep_day_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
};

export type OrganizationUpdateWithoutLead_Import_HistoryInput = {
  ActivityMonitorMultiplier?: InputMaybe<ActivityMonitorMultiplierUpdateManyWithoutOrganizationInput>;
  algoFactor?: InputMaybe<AlgoFactorUpdateManyWithoutOrganizationInput>;
  allow_calendar_invite?: InputMaybe<BoolFieldUpdateOperationsInput>;
  allow_remove_step_action?: InputMaybe<BoolFieldUpdateOperationsInput>;
  allow_snooze_step_action?: InputMaybe<BoolFieldUpdateOperationsInput>;
  always_record?: InputMaybe<BoolFieldUpdateOperationsInput>;
  arv_initial_held_demos_generated_by_sdr_weight?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  AssociationHistories?: InputMaybe<AssociationHistoryUpdateManyWithoutOrganizationInput>;
  auto_call_interval?: InputMaybe<IntFieldUpdateOperationsInput>;
  auto_dial?: InputMaybe<BoolFieldUpdateOperationsInput>;
  automated_campaigns?: InputMaybe<AutomatedCampaignUpdateManyWithoutOrganizationInput>;
  automatic_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  AutoTransferSelections?: InputMaybe<AutoTransferSelectionUpdateManyWithoutOrganizationInput>;
  Availablities?: InputMaybe<AvailabilityUpdateManyWithoutOrganizationInput>;
  AverageOrderValue?: InputMaybe<AverageOrderValueUpdateManyWithoutOrganizationInput>;
  best_action_ever_multiplier?: InputMaybe<FloatFieldUpdateOperationsInput>;
  best_metrics?: InputMaybe<BestMetricRecordUpdateManyWithoutOrganizationInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptUpdateManyWithoutOrganizationInput>;
  callMeNows?: InputMaybe<CallMeNowUpdateManyWithoutOrganizationInput>;
  CallMultiplier?: InputMaybe<CallMultiplierUpdateManyWithoutOrganizationInput>;
  CallSharings?: InputMaybe<CallSharingUpdateManyWithoutOrganizationInput>;
  can_edit_sdr_transfer_rules?: InputMaybe<BoolFieldUpdateOperationsInput>;
  claim_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  concession?: InputMaybe<ConcessionUpdateManyWithoutOrganizationInput>;
  conference_region?: InputMaybe<EnumConferenceRegionFieldUpdateOperationsInput>;
  Conferences?: InputMaybe<ConferenceUpdateManyWithoutOrganizationInput>;
  ContactRequests?: InputMaybe<ContactRequestUpdateManyWithoutOrganizationInput>;
  Contacts?: InputMaybe<ContactUpdateManyWithoutOrganizationInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  custom_fields?: InputMaybe<CustomFieldUpdateManyWithoutOrganizationInput>;
  CustomObject?: InputMaybe<CustomObjectUpdateManyWithoutOrganizationInput>;
  CustomObjectField?: InputMaybe<CustomObjectFieldUpdateManyWithoutOrganizationInput>;
  CustomObjectFieldForeignKey?: InputMaybe<CustomObjectFieldForeignKeyUpdateManyWithoutOrganizationInput>;
  CustomObjectRecord?: InputMaybe<CustomObjectRecordUpdateManyWithoutOrganizationInput>;
  CustomObjectRow?: InputMaybe<CustomObjectRowUpdateManyWithoutOrganizationInput>;
  CustomQueueItems?: InputMaybe<CustomQueueItemUpdateManyWithoutOrganizationInput>;
  DashboardColumnOptions?: InputMaybe<DashboardColumnOptionUpdateManyWithoutOrganizationInput>;
  DashboardLeadFilters?: InputMaybe<DashboardLeadFilterUpdateManyWithoutOrganizationInput>;
  DashboardRepFilters?: InputMaybe<DashboardRepFilterUpdateManyWithoutOrganizationInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewUpdateManyWithoutOrganizationInput>;
  day_to_close?: InputMaybe<IntFieldUpdateOperationsInput>;
  default_demo_time_duration?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  demo_held_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_not_held_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_not_held_call_back_short_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_prep_time_minutes?: InputMaybe<IntFieldUpdateOperationsInput>;
  dev_account?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  dial_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  DispositionMultiplier?: InputMaybe<DispositionMultiplierUpdateManyWithoutOrganizationInput>;
  dispositions?: InputMaybe<DispositionUpdateManyWithoutOrganizationInput>;
  double_booking?: InputMaybe<BoolFieldUpdateOperationsInput>;
  dynamic_date_operators?: InputMaybe<BoolFieldUpdateOperationsInput>;
  edge_servers?: InputMaybe<OrganizationUpdateedge_ServersInput>;
  email_conflict_notification_email_list?: InputMaybe<OrganizationUpdateemail_Conflict_Notification_Email_ListInput>;
  EmailItems?: InputMaybe<EmailItemUpdateManyWithoutOrganizationInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordUpdateManyWithoutOrganizationInput>;
  EmailThreads?: InputMaybe<EmailThreadUpdateManyWithoutOrganizationInput>;
  enable_modified_metrics?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  enable_pay_period_view?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  enable_time_constraint?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  error_log_email_address?: InputMaybe<OrganizationUpdateerror_Log_Email_AddressInput>;
  estimated_average_num_of_weekly_overbooked_demo_slots?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  FolderPermissions?: InputMaybe<FolderPermissionUpdateManyWithoutOrganizationInput>;
  Folders?: InputMaybe<FolderUpdateManyWithoutOrganizationInput>;
  FutureBookingSetting?: InputMaybe<FutureBookingSettingUpdateOneWithoutOrganizationInput>;
  GlobalTransferRule?: InputMaybe<GlobalTransferRuleUpdateManyWithoutOrganizationInput>;
  held_demos_per_cc_connect?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  helpful_links?: InputMaybe<OrganizationUpdatehelpful_LinksInput>;
  HingePointMultiplier?: InputMaybe<HingePointMultiplierUpdateManyWithoutOrganizationInput>;
  hold_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
  hubspot_credential?: InputMaybe<HubSpotCredentialUpdateOneWithoutOrganizationInput>;
  hubspot_last_sync_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  hubspot_white_list?: InputMaybe<HubSpotWhitelistUpdateManyWithoutOrganizationInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inbound_close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_dm_connect_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_lt_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_revenue_value_of_sale?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_scheduled_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_set_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventUpdateManyWithoutOrganizationInput>;
  InboundConciergeWidget?: InputMaybe<InboundConciergeWidgetUpdateManyWithoutOrganizationInput>;
  industry_options?: InputMaybe<IndustryOptionUpdateManyWithoutOrganizationInput>;
  initial_sdr_sets_reps_day?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  integration_error_logs?: InputMaybe<IntegrationErrorLogUpdateManyWithoutOrganizationInput>;
  integration_mappings?: InputMaybe<IntegrationMappingUpdateManyWithoutOrganizationInput>;
  IntegrationMappingCustomObject?: InputMaybe<IntegrationMappingCustomObjectUpdateManyWithoutOrganizationInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogUpdateManyWithoutOrganizationInput>;
  IntegrationReadOnlyObject?: InputMaybe<IntegrationReadOnlyObjectUpdateManyWithoutOrganizationInput>;
  international?: InputMaybe<BoolFieldUpdateOperationsInput>;
  is_email_domain_verified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  is_salesforce_sandbox?: InputMaybe<BoolFieldUpdateOperationsInput>;
  last_lead_value_compute_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutOrganizationInput>;
  lead_intent?: InputMaybe<LeadIntentUpdateManyWithoutOrganizationInput>;
  lead_release_same_rep_delay_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  lead_routing_email_list?: InputMaybe<OrganizationUpdatelead_Routing_Email_ListInput>;
  lead_routing_rules?: InputMaybe<RuleUpdateManyWithoutOrganizationInput>;
  LeadCustomObjectRowAssociation?: InputMaybe<LeadCustomObjectRowAssociationUpdateManyWithoutOrganizationInput>;
  LeadFieldMultiplier?: InputMaybe<LeadFieldMultiplierUpdateManyWithoutOrganizationInput>;
  LeadFieldMultiplierCustom?: InputMaybe<LeadFieldMultiplierCustomUpdateManyWithoutOrganizationInput>;
  leads?: InputMaybe<LeadUpdateManyWithoutOrganizationInput>;
  leadsource_options?: InputMaybe<LeadSourceOptionUpdateManyWithoutOrganizationInput>;
  LeadValue?: InputMaybe<LeadValueUpdateManyWithoutOrganizationInput>;
  LifeTimeValueMultiplier?: InputMaybe<LifeTimeValueMultiplierUpdateManyWithoutOrganizationInput>;
  LiveTransferSetting?: InputMaybe<LiveTransferSettingUpdateOneWithoutOrganizationInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusUpdateManyWithoutOrganizationInput>;
  ltv_multiplier_custom_fields?: InputMaybe<LifeTimeValueMultiplierCustomUpdateManyWithoutOrganizationInput>;
  made_contact_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  make_sale_address_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_business_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_city_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_country_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_first_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_industry_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_last_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_lead_source_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_primary_email_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_primary_phone_number_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_state_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_sub_industry_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_zip_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  maxium_day_lag_set_to_schedule?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  mrr?: InputMaybe<IntFieldUpdateOperationsInput>;
  mrr_label?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  mrr_required_on_sale?: InputMaybe<BoolFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  NoteItems?: InputMaybe<NoteItemUpdateManyWithoutOrganizationInput>;
  Notifications?: InputMaybe<NotificationUpdateManyWithoutOrganizationInput>;
  onboardComplete?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  Opportunity?: InputMaybe<SfOpportunityUpdateManyWithoutOrganizationInput>;
  organization_holidays?: InputMaybe<OrganizationHolidayUpdateManyWithoutOrganizationInput>;
  organization_hours?: InputMaybe<OrganizationHourUpdateManyWithoutOrganizationInput>;
  OrganizationPaymentSetting?: InputMaybe<OrganizationPaymentSettingsUpdateOneWithoutOrganizationInput>;
  OrganizationPaymentSettings?: InputMaybe<OrganizationPaymentSettingsUpdateOneWithoutOrganizationsInput>;
  orgCalendarInviteMetadata?: InputMaybe<OrgCalendarInviteMetadataUpdateManyWithoutOrganizationInput>;
  OrgDefaultPipelinePerecentValue?: InputMaybe<OrgDefaultPipelinePerecentValueUpdateOneWithoutOrganizationInput>;
  OrgHubSpotSync?: InputMaybe<OrgHubSpotSyncUpdateOneWithoutOrganizationInput>;
  OrgNoEventDate?: InputMaybe<OrgNoEventDateUpdateManyWithoutOrganizationInput>;
  OrgSalesforceSync?: InputMaybe<OrgSalesforceSyncUpdateOneWithoutOrganizationInput>;
  OrgTimeslotAvailability?: InputMaybe<OrgTimeslotAvailabilityUpdateManyWithoutOrganizationInput>;
  outbound_close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_dm_connect_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_lt_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_revenue_value_of_sale?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_scheduled_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_set_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  PandadocData?: InputMaybe<PandadocDataUpdateOneWithoutOrganizationInput>;
  PandaDocTemplate?: InputMaybe<PandaDocTemplateUpdateManyWithoutOrganizationInput>;
  participant_jitter_buffer_size?: InputMaybe<EnumJitterBufferSizeFieldUpdateOperationsInput>;
  preferred_goal_setting?: InputMaybe<NullableEnumGoalsettingFieldUpdateOperationsInput>;
  prepayment?: InputMaybe<BoolFieldUpdateOperationsInput>;
  ProductChangeLog?: InputMaybe<ProductChangeLogUpdateManyWithoutOrganizationInput>;
  products?: InputMaybe<ProductUpdateManyWithoutOrganizationInput>;
  re_cold_call_hour_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  recordingStatus?: InputMaybe<EnumRecordingStatusFieldUpdateOperationsInput>;
  release_lead_resting?: InputMaybe<IntFieldUpdateOperationsInput>;
  rep_claim_limit?: InputMaybe<IntFieldUpdateOperationsInput>;
  rep_lead_allotment?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  rep_level_call_transfer?: InputMaybe<BoolFieldUpdateOperationsInput>;
  reps_can_bulk_unassign?: InputMaybe<BoolFieldUpdateOperationsInput>;
  reps_can_view_all_notes?: InputMaybe<BoolFieldUpdateOperationsInput>;
  require_sale_notes?: InputMaybe<BoolFieldUpdateOperationsInput>;
  resting_after_skipped_CC?: InputMaybe<IntFieldUpdateOperationsInput>;
  resting_period?: InputMaybe<IntFieldUpdateOperationsInput>;
  resting_period_after_unsuccessful_cold_call?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  resting_period_after_unsuccessful_demo?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  revenue_plan?: InputMaybe<RevenuePlanItemUpdateManyWithoutOrganizationInput>;
  Routings?: InputMaybe<RoutingUpdateManyWithoutOrganizationInput>;
  sale_field_options?: InputMaybe<SaleFieldOptionUpdateManyWithoutOrganizationInput>;
  sale_notification_email_list?: InputMaybe<OrganizationUpdatesale_Notification_Email_ListInput>;
  SaleConfig?: InputMaybe<SaleConfigUpdateOneWithoutOrganizationInput>;
  SaleConfigPage?: InputMaybe<SaleConfigPageUpdateManyWithoutOrganizationInput>;
  SaleConfigSection?: InputMaybe<SaleConfigSectionUpdateManyWithoutOrganizationInput>;
  SaleCycles?: InputMaybe<SaleCycleUpdateManyWithoutOrganizationInput>;
  SaleItems?: InputMaybe<SaleItemUpdateManyWithoutOrganizationInput>;
  sales?: InputMaybe<SaleUpdateManyWithoutOrganizationInput>;
  salesforce_domain?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  salesforce_last_sync_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  SalesForceColumn?: InputMaybe<SalesForceColumnUpdateManyWithoutOrganizationInput>;
  salesForceData?: InputMaybe<SalesForceDataUpdateOneWithoutOrganizationInput>;
  ScheduleItems?: InputMaybe<ScheduleItemUpdateManyWithoutOrganizationInput>;
  self_sourcing_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  send_error_log_email?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  send_lead_routing_email?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sendgrid_email_domain?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sendgrid_email_domain_id?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  sequence?: InputMaybe<SequenceUpdateManyWithoutOrganzationInput>;
  SequenceCriteriaConditions?: InputMaybe<SequenceCriteriaConditionUpdateManyWithoutOrganizationInput>;
  SequenceDashboardViews?: InputMaybe<SequenceDashboardViewUpdateManyWithoutOrganizationInput>;
  SequenceEntryCriterias?: InputMaybe<SequenceEntryCriteriaUpdateManyWithoutOrganizationInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutOrganizationInput>;
  SequenceSteps?: InputMaybe<SequenceStepUpdateManyWithoutOrganizationInput>;
  set_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
  short_resting?: InputMaybe<IntFieldUpdateOperationsInput>;
  show_calendar_options?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_contract_duration_sales?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_lead_source_to_rep?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_nocontact_button?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_payment_terms_sales?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_rep_all_leads?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_rep_my_leads?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_system_view?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sites?: InputMaybe<SiteUpdateManyWithoutOrganizationInput>;
  sms_opt_in_dialog?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SMSItems?: InputMaybe<SmsItemUpdateManyWithoutOrganizationInput>;
  SnoozeTrackings?: InputMaybe<SnoozeTrackingUpdateManyWithoutOrganizationInput>;
  StepActionLogs?: InputMaybe<StepActionLogUpdateManyWithoutOrganizationInput>;
  StepActions?: InputMaybe<StepActionUpdateManyWithoutOrganizationInput>;
  StepConditions?: InputMaybe<StepConditionUpdateManyWithoutOrganizationInput>;
  teams?: InputMaybe<TeamUpdateManyWithoutOrganizationInput>;
  Template?: InputMaybe<TemplateUpdateManyWithoutOrganizationInput>;
  TemplateVersions?: InputMaybe<TemplateVersionUpdateManyWithoutOrganizationInput>;
  timekit_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timezone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transfer_timeout?: InputMaybe<IntFieldUpdateOperationsInput>;
  TransferAttempts?: InputMaybe<TransferAttemptUpdateManyWithoutOrganizationInput>;
  twilio_numbers?: InputMaybe<TwilioNumberUpdateManyWithoutOrganizationInput>;
  TwilioFeedbacks?: InputMaybe<TwilioFeedbackUpdateManyWithoutOrganizationInput>;
  TwilioInfo?: InputMaybe<TwilioInfoUpdateOneWithoutOrganzationInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  use_revenue_planning?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  UserCustomFieldFilters?: InputMaybe<UserCustomFieldFilterUpdateManyWithoutOrganizationInput>;
  users?: InputMaybe<UserUpdateManyWithoutOrganizationInput>;
  win_rep_day_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
};

export type OrganizationUpdateWithoutLead_IntentInput = {
  ActivityMonitorMultiplier?: InputMaybe<ActivityMonitorMultiplierUpdateManyWithoutOrganizationInput>;
  algoFactor?: InputMaybe<AlgoFactorUpdateManyWithoutOrganizationInput>;
  allow_calendar_invite?: InputMaybe<BoolFieldUpdateOperationsInput>;
  allow_remove_step_action?: InputMaybe<BoolFieldUpdateOperationsInput>;
  allow_snooze_step_action?: InputMaybe<BoolFieldUpdateOperationsInput>;
  always_record?: InputMaybe<BoolFieldUpdateOperationsInput>;
  arv_initial_held_demos_generated_by_sdr_weight?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  AssociationHistories?: InputMaybe<AssociationHistoryUpdateManyWithoutOrganizationInput>;
  auto_call_interval?: InputMaybe<IntFieldUpdateOperationsInput>;
  auto_dial?: InputMaybe<BoolFieldUpdateOperationsInput>;
  automated_campaigns?: InputMaybe<AutomatedCampaignUpdateManyWithoutOrganizationInput>;
  automatic_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  AutoTransferSelections?: InputMaybe<AutoTransferSelectionUpdateManyWithoutOrganizationInput>;
  Availablities?: InputMaybe<AvailabilityUpdateManyWithoutOrganizationInput>;
  AverageOrderValue?: InputMaybe<AverageOrderValueUpdateManyWithoutOrganizationInput>;
  best_action_ever_multiplier?: InputMaybe<FloatFieldUpdateOperationsInput>;
  best_metrics?: InputMaybe<BestMetricRecordUpdateManyWithoutOrganizationInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptUpdateManyWithoutOrganizationInput>;
  callMeNows?: InputMaybe<CallMeNowUpdateManyWithoutOrganizationInput>;
  CallMultiplier?: InputMaybe<CallMultiplierUpdateManyWithoutOrganizationInput>;
  CallSharings?: InputMaybe<CallSharingUpdateManyWithoutOrganizationInput>;
  can_edit_sdr_transfer_rules?: InputMaybe<BoolFieldUpdateOperationsInput>;
  claim_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  concession?: InputMaybe<ConcessionUpdateManyWithoutOrganizationInput>;
  conference_region?: InputMaybe<EnumConferenceRegionFieldUpdateOperationsInput>;
  Conferences?: InputMaybe<ConferenceUpdateManyWithoutOrganizationInput>;
  ContactRequests?: InputMaybe<ContactRequestUpdateManyWithoutOrganizationInput>;
  Contacts?: InputMaybe<ContactUpdateManyWithoutOrganizationInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  custom_fields?: InputMaybe<CustomFieldUpdateManyWithoutOrganizationInput>;
  CustomObject?: InputMaybe<CustomObjectUpdateManyWithoutOrganizationInput>;
  CustomObjectField?: InputMaybe<CustomObjectFieldUpdateManyWithoutOrganizationInput>;
  CustomObjectFieldForeignKey?: InputMaybe<CustomObjectFieldForeignKeyUpdateManyWithoutOrganizationInput>;
  CustomObjectRecord?: InputMaybe<CustomObjectRecordUpdateManyWithoutOrganizationInput>;
  CustomObjectRow?: InputMaybe<CustomObjectRowUpdateManyWithoutOrganizationInput>;
  CustomQueueItems?: InputMaybe<CustomQueueItemUpdateManyWithoutOrganizationInput>;
  DashboardColumnOptions?: InputMaybe<DashboardColumnOptionUpdateManyWithoutOrganizationInput>;
  DashboardLeadFilters?: InputMaybe<DashboardLeadFilterUpdateManyWithoutOrganizationInput>;
  DashboardRepFilters?: InputMaybe<DashboardRepFilterUpdateManyWithoutOrganizationInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewUpdateManyWithoutOrganizationInput>;
  day_to_close?: InputMaybe<IntFieldUpdateOperationsInput>;
  default_demo_time_duration?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  demo_held_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_not_held_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_not_held_call_back_short_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_prep_time_minutes?: InputMaybe<IntFieldUpdateOperationsInput>;
  dev_account?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  dial_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  DispositionMultiplier?: InputMaybe<DispositionMultiplierUpdateManyWithoutOrganizationInput>;
  dispositions?: InputMaybe<DispositionUpdateManyWithoutOrganizationInput>;
  double_booking?: InputMaybe<BoolFieldUpdateOperationsInput>;
  dynamic_date_operators?: InputMaybe<BoolFieldUpdateOperationsInput>;
  edge_servers?: InputMaybe<OrganizationUpdateedge_ServersInput>;
  email_conflict_notification_email_list?: InputMaybe<OrganizationUpdateemail_Conflict_Notification_Email_ListInput>;
  EmailItems?: InputMaybe<EmailItemUpdateManyWithoutOrganizationInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordUpdateManyWithoutOrganizationInput>;
  EmailThreads?: InputMaybe<EmailThreadUpdateManyWithoutOrganizationInput>;
  enable_modified_metrics?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  enable_pay_period_view?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  enable_time_constraint?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  error_log_email_address?: InputMaybe<OrganizationUpdateerror_Log_Email_AddressInput>;
  estimated_average_num_of_weekly_overbooked_demo_slots?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  FolderPermissions?: InputMaybe<FolderPermissionUpdateManyWithoutOrganizationInput>;
  Folders?: InputMaybe<FolderUpdateManyWithoutOrganizationInput>;
  FutureBookingSetting?: InputMaybe<FutureBookingSettingUpdateOneWithoutOrganizationInput>;
  GlobalTransferRule?: InputMaybe<GlobalTransferRuleUpdateManyWithoutOrganizationInput>;
  held_demos_per_cc_connect?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  helpful_links?: InputMaybe<OrganizationUpdatehelpful_LinksInput>;
  HingePointMultiplier?: InputMaybe<HingePointMultiplierUpdateManyWithoutOrganizationInput>;
  hold_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
  hubspot_credential?: InputMaybe<HubSpotCredentialUpdateOneWithoutOrganizationInput>;
  hubspot_last_sync_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  hubspot_white_list?: InputMaybe<HubSpotWhitelistUpdateManyWithoutOrganizationInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inbound_close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_dm_connect_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_lt_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_revenue_value_of_sale?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_scheduled_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_set_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventUpdateManyWithoutOrganizationInput>;
  InboundConciergeWidget?: InputMaybe<InboundConciergeWidgetUpdateManyWithoutOrganizationInput>;
  industry_options?: InputMaybe<IndustryOptionUpdateManyWithoutOrganizationInput>;
  initial_sdr_sets_reps_day?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  integration_error_logs?: InputMaybe<IntegrationErrorLogUpdateManyWithoutOrganizationInput>;
  integration_mappings?: InputMaybe<IntegrationMappingUpdateManyWithoutOrganizationInput>;
  IntegrationMappingCustomObject?: InputMaybe<IntegrationMappingCustomObjectUpdateManyWithoutOrganizationInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogUpdateManyWithoutOrganizationInput>;
  IntegrationReadOnlyObject?: InputMaybe<IntegrationReadOnlyObjectUpdateManyWithoutOrganizationInput>;
  international?: InputMaybe<BoolFieldUpdateOperationsInput>;
  is_email_domain_verified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  is_salesforce_sandbox?: InputMaybe<BoolFieldUpdateOperationsInput>;
  last_lead_value_compute_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutOrganizationInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryUpdateManyWithoutOrganizationInput>;
  lead_release_same_rep_delay_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  lead_routing_email_list?: InputMaybe<OrganizationUpdatelead_Routing_Email_ListInput>;
  lead_routing_rules?: InputMaybe<RuleUpdateManyWithoutOrganizationInput>;
  LeadCustomObjectRowAssociation?: InputMaybe<LeadCustomObjectRowAssociationUpdateManyWithoutOrganizationInput>;
  LeadFieldMultiplier?: InputMaybe<LeadFieldMultiplierUpdateManyWithoutOrganizationInput>;
  LeadFieldMultiplierCustom?: InputMaybe<LeadFieldMultiplierCustomUpdateManyWithoutOrganizationInput>;
  leads?: InputMaybe<LeadUpdateManyWithoutOrganizationInput>;
  leadsource_options?: InputMaybe<LeadSourceOptionUpdateManyWithoutOrganizationInput>;
  LeadValue?: InputMaybe<LeadValueUpdateManyWithoutOrganizationInput>;
  LifeTimeValueMultiplier?: InputMaybe<LifeTimeValueMultiplierUpdateManyWithoutOrganizationInput>;
  LiveTransferSetting?: InputMaybe<LiveTransferSettingUpdateOneWithoutOrganizationInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusUpdateManyWithoutOrganizationInput>;
  ltv_multiplier_custom_fields?: InputMaybe<LifeTimeValueMultiplierCustomUpdateManyWithoutOrganizationInput>;
  made_contact_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  make_sale_address_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_business_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_city_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_country_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_first_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_industry_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_last_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_lead_source_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_primary_email_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_primary_phone_number_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_state_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_sub_industry_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_zip_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  maxium_day_lag_set_to_schedule?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  mrr?: InputMaybe<IntFieldUpdateOperationsInput>;
  mrr_label?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  mrr_required_on_sale?: InputMaybe<BoolFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  NoteItems?: InputMaybe<NoteItemUpdateManyWithoutOrganizationInput>;
  Notifications?: InputMaybe<NotificationUpdateManyWithoutOrganizationInput>;
  onboardComplete?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  Opportunity?: InputMaybe<SfOpportunityUpdateManyWithoutOrganizationInput>;
  organization_holidays?: InputMaybe<OrganizationHolidayUpdateManyWithoutOrganizationInput>;
  organization_hours?: InputMaybe<OrganizationHourUpdateManyWithoutOrganizationInput>;
  OrganizationPaymentSetting?: InputMaybe<OrganizationPaymentSettingsUpdateOneWithoutOrganizationInput>;
  OrganizationPaymentSettings?: InputMaybe<OrganizationPaymentSettingsUpdateOneWithoutOrganizationsInput>;
  orgCalendarInviteMetadata?: InputMaybe<OrgCalendarInviteMetadataUpdateManyWithoutOrganizationInput>;
  OrgDefaultPipelinePerecentValue?: InputMaybe<OrgDefaultPipelinePerecentValueUpdateOneWithoutOrganizationInput>;
  OrgHubSpotSync?: InputMaybe<OrgHubSpotSyncUpdateOneWithoutOrganizationInput>;
  OrgNoEventDate?: InputMaybe<OrgNoEventDateUpdateManyWithoutOrganizationInput>;
  OrgSalesforceSync?: InputMaybe<OrgSalesforceSyncUpdateOneWithoutOrganizationInput>;
  OrgTimeslotAvailability?: InputMaybe<OrgTimeslotAvailabilityUpdateManyWithoutOrganizationInput>;
  outbound_close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_dm_connect_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_lt_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_revenue_value_of_sale?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_scheduled_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_set_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  PandadocData?: InputMaybe<PandadocDataUpdateOneWithoutOrganizationInput>;
  PandaDocTemplate?: InputMaybe<PandaDocTemplateUpdateManyWithoutOrganizationInput>;
  participant_jitter_buffer_size?: InputMaybe<EnumJitterBufferSizeFieldUpdateOperationsInput>;
  preferred_goal_setting?: InputMaybe<NullableEnumGoalsettingFieldUpdateOperationsInput>;
  prepayment?: InputMaybe<BoolFieldUpdateOperationsInput>;
  ProductChangeLog?: InputMaybe<ProductChangeLogUpdateManyWithoutOrganizationInput>;
  products?: InputMaybe<ProductUpdateManyWithoutOrganizationInput>;
  re_cold_call_hour_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  recordingStatus?: InputMaybe<EnumRecordingStatusFieldUpdateOperationsInput>;
  release_lead_resting?: InputMaybe<IntFieldUpdateOperationsInput>;
  rep_claim_limit?: InputMaybe<IntFieldUpdateOperationsInput>;
  rep_lead_allotment?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  rep_level_call_transfer?: InputMaybe<BoolFieldUpdateOperationsInput>;
  reps_can_bulk_unassign?: InputMaybe<BoolFieldUpdateOperationsInput>;
  reps_can_view_all_notes?: InputMaybe<BoolFieldUpdateOperationsInput>;
  require_sale_notes?: InputMaybe<BoolFieldUpdateOperationsInput>;
  resting_after_skipped_CC?: InputMaybe<IntFieldUpdateOperationsInput>;
  resting_period?: InputMaybe<IntFieldUpdateOperationsInput>;
  resting_period_after_unsuccessful_cold_call?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  resting_period_after_unsuccessful_demo?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  revenue_plan?: InputMaybe<RevenuePlanItemUpdateManyWithoutOrganizationInput>;
  Routings?: InputMaybe<RoutingUpdateManyWithoutOrganizationInput>;
  sale_field_options?: InputMaybe<SaleFieldOptionUpdateManyWithoutOrganizationInput>;
  sale_notification_email_list?: InputMaybe<OrganizationUpdatesale_Notification_Email_ListInput>;
  SaleConfig?: InputMaybe<SaleConfigUpdateOneWithoutOrganizationInput>;
  SaleConfigPage?: InputMaybe<SaleConfigPageUpdateManyWithoutOrganizationInput>;
  SaleConfigSection?: InputMaybe<SaleConfigSectionUpdateManyWithoutOrganizationInput>;
  SaleCycles?: InputMaybe<SaleCycleUpdateManyWithoutOrganizationInput>;
  SaleItems?: InputMaybe<SaleItemUpdateManyWithoutOrganizationInput>;
  sales?: InputMaybe<SaleUpdateManyWithoutOrganizationInput>;
  salesforce_domain?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  salesforce_last_sync_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  SalesForceColumn?: InputMaybe<SalesForceColumnUpdateManyWithoutOrganizationInput>;
  salesForceData?: InputMaybe<SalesForceDataUpdateOneWithoutOrganizationInput>;
  ScheduleItems?: InputMaybe<ScheduleItemUpdateManyWithoutOrganizationInput>;
  self_sourcing_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  send_error_log_email?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  send_lead_routing_email?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sendgrid_email_domain?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sendgrid_email_domain_id?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  sequence?: InputMaybe<SequenceUpdateManyWithoutOrganzationInput>;
  SequenceCriteriaConditions?: InputMaybe<SequenceCriteriaConditionUpdateManyWithoutOrganizationInput>;
  SequenceDashboardViews?: InputMaybe<SequenceDashboardViewUpdateManyWithoutOrganizationInput>;
  SequenceEntryCriterias?: InputMaybe<SequenceEntryCriteriaUpdateManyWithoutOrganizationInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutOrganizationInput>;
  SequenceSteps?: InputMaybe<SequenceStepUpdateManyWithoutOrganizationInput>;
  set_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
  short_resting?: InputMaybe<IntFieldUpdateOperationsInput>;
  show_calendar_options?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_contract_duration_sales?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_lead_source_to_rep?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_nocontact_button?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_payment_terms_sales?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_rep_all_leads?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_rep_my_leads?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_system_view?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sites?: InputMaybe<SiteUpdateManyWithoutOrganizationInput>;
  sms_opt_in_dialog?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SMSItems?: InputMaybe<SmsItemUpdateManyWithoutOrganizationInput>;
  SnoozeTrackings?: InputMaybe<SnoozeTrackingUpdateManyWithoutOrganizationInput>;
  StepActionLogs?: InputMaybe<StepActionLogUpdateManyWithoutOrganizationInput>;
  StepActions?: InputMaybe<StepActionUpdateManyWithoutOrganizationInput>;
  StepConditions?: InputMaybe<StepConditionUpdateManyWithoutOrganizationInput>;
  teams?: InputMaybe<TeamUpdateManyWithoutOrganizationInput>;
  Template?: InputMaybe<TemplateUpdateManyWithoutOrganizationInput>;
  TemplateVersions?: InputMaybe<TemplateVersionUpdateManyWithoutOrganizationInput>;
  timekit_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timezone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transfer_timeout?: InputMaybe<IntFieldUpdateOperationsInput>;
  TransferAttempts?: InputMaybe<TransferAttemptUpdateManyWithoutOrganizationInput>;
  twilio_numbers?: InputMaybe<TwilioNumberUpdateManyWithoutOrganizationInput>;
  TwilioFeedbacks?: InputMaybe<TwilioFeedbackUpdateManyWithoutOrganizationInput>;
  TwilioInfo?: InputMaybe<TwilioInfoUpdateOneWithoutOrganzationInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  use_revenue_planning?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  UserCustomFieldFilters?: InputMaybe<UserCustomFieldFilterUpdateManyWithoutOrganizationInput>;
  users?: InputMaybe<UserUpdateManyWithoutOrganizationInput>;
  win_rep_day_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
};

export type OrganizationUpdateWithoutLead_Routing_RulesInput = {
  ActivityMonitorMultiplier?: InputMaybe<ActivityMonitorMultiplierUpdateManyWithoutOrganizationInput>;
  algoFactor?: InputMaybe<AlgoFactorUpdateManyWithoutOrganizationInput>;
  allow_calendar_invite?: InputMaybe<BoolFieldUpdateOperationsInput>;
  allow_remove_step_action?: InputMaybe<BoolFieldUpdateOperationsInput>;
  allow_snooze_step_action?: InputMaybe<BoolFieldUpdateOperationsInput>;
  always_record?: InputMaybe<BoolFieldUpdateOperationsInput>;
  arv_initial_held_demos_generated_by_sdr_weight?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  AssociationHistories?: InputMaybe<AssociationHistoryUpdateManyWithoutOrganizationInput>;
  auto_call_interval?: InputMaybe<IntFieldUpdateOperationsInput>;
  auto_dial?: InputMaybe<BoolFieldUpdateOperationsInput>;
  automated_campaigns?: InputMaybe<AutomatedCampaignUpdateManyWithoutOrganizationInput>;
  automatic_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  AutoTransferSelections?: InputMaybe<AutoTransferSelectionUpdateManyWithoutOrganizationInput>;
  Availablities?: InputMaybe<AvailabilityUpdateManyWithoutOrganizationInput>;
  AverageOrderValue?: InputMaybe<AverageOrderValueUpdateManyWithoutOrganizationInput>;
  best_action_ever_multiplier?: InputMaybe<FloatFieldUpdateOperationsInput>;
  best_metrics?: InputMaybe<BestMetricRecordUpdateManyWithoutOrganizationInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptUpdateManyWithoutOrganizationInput>;
  callMeNows?: InputMaybe<CallMeNowUpdateManyWithoutOrganizationInput>;
  CallMultiplier?: InputMaybe<CallMultiplierUpdateManyWithoutOrganizationInput>;
  CallSharings?: InputMaybe<CallSharingUpdateManyWithoutOrganizationInput>;
  can_edit_sdr_transfer_rules?: InputMaybe<BoolFieldUpdateOperationsInput>;
  claim_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  concession?: InputMaybe<ConcessionUpdateManyWithoutOrganizationInput>;
  conference_region?: InputMaybe<EnumConferenceRegionFieldUpdateOperationsInput>;
  Conferences?: InputMaybe<ConferenceUpdateManyWithoutOrganizationInput>;
  ContactRequests?: InputMaybe<ContactRequestUpdateManyWithoutOrganizationInput>;
  Contacts?: InputMaybe<ContactUpdateManyWithoutOrganizationInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  custom_fields?: InputMaybe<CustomFieldUpdateManyWithoutOrganizationInput>;
  CustomObject?: InputMaybe<CustomObjectUpdateManyWithoutOrganizationInput>;
  CustomObjectField?: InputMaybe<CustomObjectFieldUpdateManyWithoutOrganizationInput>;
  CustomObjectFieldForeignKey?: InputMaybe<CustomObjectFieldForeignKeyUpdateManyWithoutOrganizationInput>;
  CustomObjectRecord?: InputMaybe<CustomObjectRecordUpdateManyWithoutOrganizationInput>;
  CustomObjectRow?: InputMaybe<CustomObjectRowUpdateManyWithoutOrganizationInput>;
  CustomQueueItems?: InputMaybe<CustomQueueItemUpdateManyWithoutOrganizationInput>;
  DashboardColumnOptions?: InputMaybe<DashboardColumnOptionUpdateManyWithoutOrganizationInput>;
  DashboardLeadFilters?: InputMaybe<DashboardLeadFilterUpdateManyWithoutOrganizationInput>;
  DashboardRepFilters?: InputMaybe<DashboardRepFilterUpdateManyWithoutOrganizationInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewUpdateManyWithoutOrganizationInput>;
  day_to_close?: InputMaybe<IntFieldUpdateOperationsInput>;
  default_demo_time_duration?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  demo_held_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_not_held_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_not_held_call_back_short_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_prep_time_minutes?: InputMaybe<IntFieldUpdateOperationsInput>;
  dev_account?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  dial_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  DispositionMultiplier?: InputMaybe<DispositionMultiplierUpdateManyWithoutOrganizationInput>;
  dispositions?: InputMaybe<DispositionUpdateManyWithoutOrganizationInput>;
  double_booking?: InputMaybe<BoolFieldUpdateOperationsInput>;
  dynamic_date_operators?: InputMaybe<BoolFieldUpdateOperationsInput>;
  edge_servers?: InputMaybe<OrganizationUpdateedge_ServersInput>;
  email_conflict_notification_email_list?: InputMaybe<OrganizationUpdateemail_Conflict_Notification_Email_ListInput>;
  EmailItems?: InputMaybe<EmailItemUpdateManyWithoutOrganizationInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordUpdateManyWithoutOrganizationInput>;
  EmailThreads?: InputMaybe<EmailThreadUpdateManyWithoutOrganizationInput>;
  enable_modified_metrics?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  enable_pay_period_view?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  enable_time_constraint?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  error_log_email_address?: InputMaybe<OrganizationUpdateerror_Log_Email_AddressInput>;
  estimated_average_num_of_weekly_overbooked_demo_slots?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  FolderPermissions?: InputMaybe<FolderPermissionUpdateManyWithoutOrganizationInput>;
  Folders?: InputMaybe<FolderUpdateManyWithoutOrganizationInput>;
  FutureBookingSetting?: InputMaybe<FutureBookingSettingUpdateOneWithoutOrganizationInput>;
  GlobalTransferRule?: InputMaybe<GlobalTransferRuleUpdateManyWithoutOrganizationInput>;
  held_demos_per_cc_connect?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  helpful_links?: InputMaybe<OrganizationUpdatehelpful_LinksInput>;
  HingePointMultiplier?: InputMaybe<HingePointMultiplierUpdateManyWithoutOrganizationInput>;
  hold_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
  hubspot_credential?: InputMaybe<HubSpotCredentialUpdateOneWithoutOrganizationInput>;
  hubspot_last_sync_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  hubspot_white_list?: InputMaybe<HubSpotWhitelistUpdateManyWithoutOrganizationInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inbound_close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_dm_connect_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_lt_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_revenue_value_of_sale?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_scheduled_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_set_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventUpdateManyWithoutOrganizationInput>;
  InboundConciergeWidget?: InputMaybe<InboundConciergeWidgetUpdateManyWithoutOrganizationInput>;
  industry_options?: InputMaybe<IndustryOptionUpdateManyWithoutOrganizationInput>;
  initial_sdr_sets_reps_day?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  integration_error_logs?: InputMaybe<IntegrationErrorLogUpdateManyWithoutOrganizationInput>;
  integration_mappings?: InputMaybe<IntegrationMappingUpdateManyWithoutOrganizationInput>;
  IntegrationMappingCustomObject?: InputMaybe<IntegrationMappingCustomObjectUpdateManyWithoutOrganizationInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogUpdateManyWithoutOrganizationInput>;
  IntegrationReadOnlyObject?: InputMaybe<IntegrationReadOnlyObjectUpdateManyWithoutOrganizationInput>;
  international?: InputMaybe<BoolFieldUpdateOperationsInput>;
  is_email_domain_verified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  is_salesforce_sandbox?: InputMaybe<BoolFieldUpdateOperationsInput>;
  last_lead_value_compute_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutOrganizationInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryUpdateManyWithoutOrganizationInput>;
  lead_intent?: InputMaybe<LeadIntentUpdateManyWithoutOrganizationInput>;
  lead_release_same_rep_delay_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  lead_routing_email_list?: InputMaybe<OrganizationUpdatelead_Routing_Email_ListInput>;
  LeadCustomObjectRowAssociation?: InputMaybe<LeadCustomObjectRowAssociationUpdateManyWithoutOrganizationInput>;
  LeadFieldMultiplier?: InputMaybe<LeadFieldMultiplierUpdateManyWithoutOrganizationInput>;
  LeadFieldMultiplierCustom?: InputMaybe<LeadFieldMultiplierCustomUpdateManyWithoutOrganizationInput>;
  leads?: InputMaybe<LeadUpdateManyWithoutOrganizationInput>;
  leadsource_options?: InputMaybe<LeadSourceOptionUpdateManyWithoutOrganizationInput>;
  LeadValue?: InputMaybe<LeadValueUpdateManyWithoutOrganizationInput>;
  LifeTimeValueMultiplier?: InputMaybe<LifeTimeValueMultiplierUpdateManyWithoutOrganizationInput>;
  LiveTransferSetting?: InputMaybe<LiveTransferSettingUpdateOneWithoutOrganizationInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusUpdateManyWithoutOrganizationInput>;
  ltv_multiplier_custom_fields?: InputMaybe<LifeTimeValueMultiplierCustomUpdateManyWithoutOrganizationInput>;
  made_contact_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  make_sale_address_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_business_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_city_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_country_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_first_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_industry_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_last_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_lead_source_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_primary_email_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_primary_phone_number_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_state_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_sub_industry_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_zip_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  maxium_day_lag_set_to_schedule?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  mrr?: InputMaybe<IntFieldUpdateOperationsInput>;
  mrr_label?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  mrr_required_on_sale?: InputMaybe<BoolFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  NoteItems?: InputMaybe<NoteItemUpdateManyWithoutOrganizationInput>;
  Notifications?: InputMaybe<NotificationUpdateManyWithoutOrganizationInput>;
  onboardComplete?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  Opportunity?: InputMaybe<SfOpportunityUpdateManyWithoutOrganizationInput>;
  organization_holidays?: InputMaybe<OrganizationHolidayUpdateManyWithoutOrganizationInput>;
  organization_hours?: InputMaybe<OrganizationHourUpdateManyWithoutOrganizationInput>;
  OrganizationPaymentSetting?: InputMaybe<OrganizationPaymentSettingsUpdateOneWithoutOrganizationInput>;
  OrganizationPaymentSettings?: InputMaybe<OrganizationPaymentSettingsUpdateOneWithoutOrganizationsInput>;
  orgCalendarInviteMetadata?: InputMaybe<OrgCalendarInviteMetadataUpdateManyWithoutOrganizationInput>;
  OrgDefaultPipelinePerecentValue?: InputMaybe<OrgDefaultPipelinePerecentValueUpdateOneWithoutOrganizationInput>;
  OrgHubSpotSync?: InputMaybe<OrgHubSpotSyncUpdateOneWithoutOrganizationInput>;
  OrgNoEventDate?: InputMaybe<OrgNoEventDateUpdateManyWithoutOrganizationInput>;
  OrgSalesforceSync?: InputMaybe<OrgSalesforceSyncUpdateOneWithoutOrganizationInput>;
  OrgTimeslotAvailability?: InputMaybe<OrgTimeslotAvailabilityUpdateManyWithoutOrganizationInput>;
  outbound_close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_dm_connect_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_lt_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_revenue_value_of_sale?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_scheduled_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_set_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  PandadocData?: InputMaybe<PandadocDataUpdateOneWithoutOrganizationInput>;
  PandaDocTemplate?: InputMaybe<PandaDocTemplateUpdateManyWithoutOrganizationInput>;
  participant_jitter_buffer_size?: InputMaybe<EnumJitterBufferSizeFieldUpdateOperationsInput>;
  preferred_goal_setting?: InputMaybe<NullableEnumGoalsettingFieldUpdateOperationsInput>;
  prepayment?: InputMaybe<BoolFieldUpdateOperationsInput>;
  ProductChangeLog?: InputMaybe<ProductChangeLogUpdateManyWithoutOrganizationInput>;
  products?: InputMaybe<ProductUpdateManyWithoutOrganizationInput>;
  re_cold_call_hour_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  recordingStatus?: InputMaybe<EnumRecordingStatusFieldUpdateOperationsInput>;
  release_lead_resting?: InputMaybe<IntFieldUpdateOperationsInput>;
  rep_claim_limit?: InputMaybe<IntFieldUpdateOperationsInput>;
  rep_lead_allotment?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  rep_level_call_transfer?: InputMaybe<BoolFieldUpdateOperationsInput>;
  reps_can_bulk_unassign?: InputMaybe<BoolFieldUpdateOperationsInput>;
  reps_can_view_all_notes?: InputMaybe<BoolFieldUpdateOperationsInput>;
  require_sale_notes?: InputMaybe<BoolFieldUpdateOperationsInput>;
  resting_after_skipped_CC?: InputMaybe<IntFieldUpdateOperationsInput>;
  resting_period?: InputMaybe<IntFieldUpdateOperationsInput>;
  resting_period_after_unsuccessful_cold_call?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  resting_period_after_unsuccessful_demo?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  revenue_plan?: InputMaybe<RevenuePlanItemUpdateManyWithoutOrganizationInput>;
  Routings?: InputMaybe<RoutingUpdateManyWithoutOrganizationInput>;
  sale_field_options?: InputMaybe<SaleFieldOptionUpdateManyWithoutOrganizationInput>;
  sale_notification_email_list?: InputMaybe<OrganizationUpdatesale_Notification_Email_ListInput>;
  SaleConfig?: InputMaybe<SaleConfigUpdateOneWithoutOrganizationInput>;
  SaleConfigPage?: InputMaybe<SaleConfigPageUpdateManyWithoutOrganizationInput>;
  SaleConfigSection?: InputMaybe<SaleConfigSectionUpdateManyWithoutOrganizationInput>;
  SaleCycles?: InputMaybe<SaleCycleUpdateManyWithoutOrganizationInput>;
  SaleItems?: InputMaybe<SaleItemUpdateManyWithoutOrganizationInput>;
  sales?: InputMaybe<SaleUpdateManyWithoutOrganizationInput>;
  salesforce_domain?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  salesforce_last_sync_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  SalesForceColumn?: InputMaybe<SalesForceColumnUpdateManyWithoutOrganizationInput>;
  salesForceData?: InputMaybe<SalesForceDataUpdateOneWithoutOrganizationInput>;
  ScheduleItems?: InputMaybe<ScheduleItemUpdateManyWithoutOrganizationInput>;
  self_sourcing_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  send_error_log_email?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  send_lead_routing_email?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sendgrid_email_domain?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sendgrid_email_domain_id?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  sequence?: InputMaybe<SequenceUpdateManyWithoutOrganzationInput>;
  SequenceCriteriaConditions?: InputMaybe<SequenceCriteriaConditionUpdateManyWithoutOrganizationInput>;
  SequenceDashboardViews?: InputMaybe<SequenceDashboardViewUpdateManyWithoutOrganizationInput>;
  SequenceEntryCriterias?: InputMaybe<SequenceEntryCriteriaUpdateManyWithoutOrganizationInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutOrganizationInput>;
  SequenceSteps?: InputMaybe<SequenceStepUpdateManyWithoutOrganizationInput>;
  set_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
  short_resting?: InputMaybe<IntFieldUpdateOperationsInput>;
  show_calendar_options?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_contract_duration_sales?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_lead_source_to_rep?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_nocontact_button?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_payment_terms_sales?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_rep_all_leads?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_rep_my_leads?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_system_view?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sites?: InputMaybe<SiteUpdateManyWithoutOrganizationInput>;
  sms_opt_in_dialog?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SMSItems?: InputMaybe<SmsItemUpdateManyWithoutOrganizationInput>;
  SnoozeTrackings?: InputMaybe<SnoozeTrackingUpdateManyWithoutOrganizationInput>;
  StepActionLogs?: InputMaybe<StepActionLogUpdateManyWithoutOrganizationInput>;
  StepActions?: InputMaybe<StepActionUpdateManyWithoutOrganizationInput>;
  StepConditions?: InputMaybe<StepConditionUpdateManyWithoutOrganizationInput>;
  teams?: InputMaybe<TeamUpdateManyWithoutOrganizationInput>;
  Template?: InputMaybe<TemplateUpdateManyWithoutOrganizationInput>;
  TemplateVersions?: InputMaybe<TemplateVersionUpdateManyWithoutOrganizationInput>;
  timekit_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timezone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transfer_timeout?: InputMaybe<IntFieldUpdateOperationsInput>;
  TransferAttempts?: InputMaybe<TransferAttemptUpdateManyWithoutOrganizationInput>;
  twilio_numbers?: InputMaybe<TwilioNumberUpdateManyWithoutOrganizationInput>;
  TwilioFeedbacks?: InputMaybe<TwilioFeedbackUpdateManyWithoutOrganizationInput>;
  TwilioInfo?: InputMaybe<TwilioInfoUpdateOneWithoutOrganzationInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  use_revenue_planning?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  UserCustomFieldFilters?: InputMaybe<UserCustomFieldFilterUpdateManyWithoutOrganizationInput>;
  users?: InputMaybe<UserUpdateManyWithoutOrganizationInput>;
  win_rep_day_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
};

export type OrganizationUpdateWithoutLeadCustomObjectRowAssociationInput = {
  ActivityMonitorMultiplier?: InputMaybe<ActivityMonitorMultiplierUpdateManyWithoutOrganizationInput>;
  algoFactor?: InputMaybe<AlgoFactorUpdateManyWithoutOrganizationInput>;
  allow_calendar_invite?: InputMaybe<BoolFieldUpdateOperationsInput>;
  allow_remove_step_action?: InputMaybe<BoolFieldUpdateOperationsInput>;
  allow_snooze_step_action?: InputMaybe<BoolFieldUpdateOperationsInput>;
  always_record?: InputMaybe<BoolFieldUpdateOperationsInput>;
  arv_initial_held_demos_generated_by_sdr_weight?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  AssociationHistories?: InputMaybe<AssociationHistoryUpdateManyWithoutOrganizationInput>;
  auto_call_interval?: InputMaybe<IntFieldUpdateOperationsInput>;
  auto_dial?: InputMaybe<BoolFieldUpdateOperationsInput>;
  automated_campaigns?: InputMaybe<AutomatedCampaignUpdateManyWithoutOrganizationInput>;
  automatic_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  AutoTransferSelections?: InputMaybe<AutoTransferSelectionUpdateManyWithoutOrganizationInput>;
  Availablities?: InputMaybe<AvailabilityUpdateManyWithoutOrganizationInput>;
  AverageOrderValue?: InputMaybe<AverageOrderValueUpdateManyWithoutOrganizationInput>;
  best_action_ever_multiplier?: InputMaybe<FloatFieldUpdateOperationsInput>;
  best_metrics?: InputMaybe<BestMetricRecordUpdateManyWithoutOrganizationInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptUpdateManyWithoutOrganizationInput>;
  callMeNows?: InputMaybe<CallMeNowUpdateManyWithoutOrganizationInput>;
  CallMultiplier?: InputMaybe<CallMultiplierUpdateManyWithoutOrganizationInput>;
  CallSharings?: InputMaybe<CallSharingUpdateManyWithoutOrganizationInput>;
  can_edit_sdr_transfer_rules?: InputMaybe<BoolFieldUpdateOperationsInput>;
  claim_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  concession?: InputMaybe<ConcessionUpdateManyWithoutOrganizationInput>;
  conference_region?: InputMaybe<EnumConferenceRegionFieldUpdateOperationsInput>;
  Conferences?: InputMaybe<ConferenceUpdateManyWithoutOrganizationInput>;
  ContactRequests?: InputMaybe<ContactRequestUpdateManyWithoutOrganizationInput>;
  Contacts?: InputMaybe<ContactUpdateManyWithoutOrganizationInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  custom_fields?: InputMaybe<CustomFieldUpdateManyWithoutOrganizationInput>;
  CustomObject?: InputMaybe<CustomObjectUpdateManyWithoutOrganizationInput>;
  CustomObjectField?: InputMaybe<CustomObjectFieldUpdateManyWithoutOrganizationInput>;
  CustomObjectFieldForeignKey?: InputMaybe<CustomObjectFieldForeignKeyUpdateManyWithoutOrganizationInput>;
  CustomObjectRecord?: InputMaybe<CustomObjectRecordUpdateManyWithoutOrganizationInput>;
  CustomObjectRow?: InputMaybe<CustomObjectRowUpdateManyWithoutOrganizationInput>;
  CustomQueueItems?: InputMaybe<CustomQueueItemUpdateManyWithoutOrganizationInput>;
  DashboardColumnOptions?: InputMaybe<DashboardColumnOptionUpdateManyWithoutOrganizationInput>;
  DashboardLeadFilters?: InputMaybe<DashboardLeadFilterUpdateManyWithoutOrganizationInput>;
  DashboardRepFilters?: InputMaybe<DashboardRepFilterUpdateManyWithoutOrganizationInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewUpdateManyWithoutOrganizationInput>;
  day_to_close?: InputMaybe<IntFieldUpdateOperationsInput>;
  default_demo_time_duration?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  demo_held_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_not_held_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_not_held_call_back_short_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_prep_time_minutes?: InputMaybe<IntFieldUpdateOperationsInput>;
  dev_account?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  dial_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  DispositionMultiplier?: InputMaybe<DispositionMultiplierUpdateManyWithoutOrganizationInput>;
  dispositions?: InputMaybe<DispositionUpdateManyWithoutOrganizationInput>;
  double_booking?: InputMaybe<BoolFieldUpdateOperationsInput>;
  dynamic_date_operators?: InputMaybe<BoolFieldUpdateOperationsInput>;
  edge_servers?: InputMaybe<OrganizationUpdateedge_ServersInput>;
  email_conflict_notification_email_list?: InputMaybe<OrganizationUpdateemail_Conflict_Notification_Email_ListInput>;
  EmailItems?: InputMaybe<EmailItemUpdateManyWithoutOrganizationInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordUpdateManyWithoutOrganizationInput>;
  EmailThreads?: InputMaybe<EmailThreadUpdateManyWithoutOrganizationInput>;
  enable_modified_metrics?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  enable_pay_period_view?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  enable_time_constraint?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  error_log_email_address?: InputMaybe<OrganizationUpdateerror_Log_Email_AddressInput>;
  estimated_average_num_of_weekly_overbooked_demo_slots?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  FolderPermissions?: InputMaybe<FolderPermissionUpdateManyWithoutOrganizationInput>;
  Folders?: InputMaybe<FolderUpdateManyWithoutOrganizationInput>;
  FutureBookingSetting?: InputMaybe<FutureBookingSettingUpdateOneWithoutOrganizationInput>;
  GlobalTransferRule?: InputMaybe<GlobalTransferRuleUpdateManyWithoutOrganizationInput>;
  held_demos_per_cc_connect?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  helpful_links?: InputMaybe<OrganizationUpdatehelpful_LinksInput>;
  HingePointMultiplier?: InputMaybe<HingePointMultiplierUpdateManyWithoutOrganizationInput>;
  hold_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
  hubspot_credential?: InputMaybe<HubSpotCredentialUpdateOneWithoutOrganizationInput>;
  hubspot_last_sync_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  hubspot_white_list?: InputMaybe<HubSpotWhitelistUpdateManyWithoutOrganizationInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inbound_close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_dm_connect_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_lt_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_revenue_value_of_sale?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_scheduled_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_set_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventUpdateManyWithoutOrganizationInput>;
  InboundConciergeWidget?: InputMaybe<InboundConciergeWidgetUpdateManyWithoutOrganizationInput>;
  industry_options?: InputMaybe<IndustryOptionUpdateManyWithoutOrganizationInput>;
  initial_sdr_sets_reps_day?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  integration_error_logs?: InputMaybe<IntegrationErrorLogUpdateManyWithoutOrganizationInput>;
  integration_mappings?: InputMaybe<IntegrationMappingUpdateManyWithoutOrganizationInput>;
  IntegrationMappingCustomObject?: InputMaybe<IntegrationMappingCustomObjectUpdateManyWithoutOrganizationInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogUpdateManyWithoutOrganizationInput>;
  IntegrationReadOnlyObject?: InputMaybe<IntegrationReadOnlyObjectUpdateManyWithoutOrganizationInput>;
  international?: InputMaybe<BoolFieldUpdateOperationsInput>;
  is_email_domain_verified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  is_salesforce_sandbox?: InputMaybe<BoolFieldUpdateOperationsInput>;
  last_lead_value_compute_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutOrganizationInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryUpdateManyWithoutOrganizationInput>;
  lead_intent?: InputMaybe<LeadIntentUpdateManyWithoutOrganizationInput>;
  lead_release_same_rep_delay_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  lead_routing_email_list?: InputMaybe<OrganizationUpdatelead_Routing_Email_ListInput>;
  lead_routing_rules?: InputMaybe<RuleUpdateManyWithoutOrganizationInput>;
  LeadFieldMultiplier?: InputMaybe<LeadFieldMultiplierUpdateManyWithoutOrganizationInput>;
  LeadFieldMultiplierCustom?: InputMaybe<LeadFieldMultiplierCustomUpdateManyWithoutOrganizationInput>;
  leads?: InputMaybe<LeadUpdateManyWithoutOrganizationInput>;
  leadsource_options?: InputMaybe<LeadSourceOptionUpdateManyWithoutOrganizationInput>;
  LeadValue?: InputMaybe<LeadValueUpdateManyWithoutOrganizationInput>;
  LifeTimeValueMultiplier?: InputMaybe<LifeTimeValueMultiplierUpdateManyWithoutOrganizationInput>;
  LiveTransferSetting?: InputMaybe<LiveTransferSettingUpdateOneWithoutOrganizationInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusUpdateManyWithoutOrganizationInput>;
  ltv_multiplier_custom_fields?: InputMaybe<LifeTimeValueMultiplierCustomUpdateManyWithoutOrganizationInput>;
  made_contact_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  make_sale_address_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_business_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_city_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_country_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_first_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_industry_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_last_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_lead_source_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_primary_email_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_primary_phone_number_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_state_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_sub_industry_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_zip_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  maxium_day_lag_set_to_schedule?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  mrr?: InputMaybe<IntFieldUpdateOperationsInput>;
  mrr_label?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  mrr_required_on_sale?: InputMaybe<BoolFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  NoteItems?: InputMaybe<NoteItemUpdateManyWithoutOrganizationInput>;
  Notifications?: InputMaybe<NotificationUpdateManyWithoutOrganizationInput>;
  onboardComplete?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  Opportunity?: InputMaybe<SfOpportunityUpdateManyWithoutOrganizationInput>;
  organization_holidays?: InputMaybe<OrganizationHolidayUpdateManyWithoutOrganizationInput>;
  organization_hours?: InputMaybe<OrganizationHourUpdateManyWithoutOrganizationInput>;
  OrganizationPaymentSetting?: InputMaybe<OrganizationPaymentSettingsUpdateOneWithoutOrganizationInput>;
  OrganizationPaymentSettings?: InputMaybe<OrganizationPaymentSettingsUpdateOneWithoutOrganizationsInput>;
  orgCalendarInviteMetadata?: InputMaybe<OrgCalendarInviteMetadataUpdateManyWithoutOrganizationInput>;
  OrgDefaultPipelinePerecentValue?: InputMaybe<OrgDefaultPipelinePerecentValueUpdateOneWithoutOrganizationInput>;
  OrgHubSpotSync?: InputMaybe<OrgHubSpotSyncUpdateOneWithoutOrganizationInput>;
  OrgNoEventDate?: InputMaybe<OrgNoEventDateUpdateManyWithoutOrganizationInput>;
  OrgSalesforceSync?: InputMaybe<OrgSalesforceSyncUpdateOneWithoutOrganizationInput>;
  OrgTimeslotAvailability?: InputMaybe<OrgTimeslotAvailabilityUpdateManyWithoutOrganizationInput>;
  outbound_close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_dm_connect_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_lt_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_revenue_value_of_sale?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_scheduled_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_set_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  PandadocData?: InputMaybe<PandadocDataUpdateOneWithoutOrganizationInput>;
  PandaDocTemplate?: InputMaybe<PandaDocTemplateUpdateManyWithoutOrganizationInput>;
  participant_jitter_buffer_size?: InputMaybe<EnumJitterBufferSizeFieldUpdateOperationsInput>;
  preferred_goal_setting?: InputMaybe<NullableEnumGoalsettingFieldUpdateOperationsInput>;
  prepayment?: InputMaybe<BoolFieldUpdateOperationsInput>;
  ProductChangeLog?: InputMaybe<ProductChangeLogUpdateManyWithoutOrganizationInput>;
  products?: InputMaybe<ProductUpdateManyWithoutOrganizationInput>;
  re_cold_call_hour_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  recordingStatus?: InputMaybe<EnumRecordingStatusFieldUpdateOperationsInput>;
  release_lead_resting?: InputMaybe<IntFieldUpdateOperationsInput>;
  rep_claim_limit?: InputMaybe<IntFieldUpdateOperationsInput>;
  rep_lead_allotment?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  rep_level_call_transfer?: InputMaybe<BoolFieldUpdateOperationsInput>;
  reps_can_bulk_unassign?: InputMaybe<BoolFieldUpdateOperationsInput>;
  reps_can_view_all_notes?: InputMaybe<BoolFieldUpdateOperationsInput>;
  require_sale_notes?: InputMaybe<BoolFieldUpdateOperationsInput>;
  resting_after_skipped_CC?: InputMaybe<IntFieldUpdateOperationsInput>;
  resting_period?: InputMaybe<IntFieldUpdateOperationsInput>;
  resting_period_after_unsuccessful_cold_call?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  resting_period_after_unsuccessful_demo?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  revenue_plan?: InputMaybe<RevenuePlanItemUpdateManyWithoutOrganizationInput>;
  Routings?: InputMaybe<RoutingUpdateManyWithoutOrganizationInput>;
  sale_field_options?: InputMaybe<SaleFieldOptionUpdateManyWithoutOrganizationInput>;
  sale_notification_email_list?: InputMaybe<OrganizationUpdatesale_Notification_Email_ListInput>;
  SaleConfig?: InputMaybe<SaleConfigUpdateOneWithoutOrganizationInput>;
  SaleConfigPage?: InputMaybe<SaleConfigPageUpdateManyWithoutOrganizationInput>;
  SaleConfigSection?: InputMaybe<SaleConfigSectionUpdateManyWithoutOrganizationInput>;
  SaleCycles?: InputMaybe<SaleCycleUpdateManyWithoutOrganizationInput>;
  SaleItems?: InputMaybe<SaleItemUpdateManyWithoutOrganizationInput>;
  sales?: InputMaybe<SaleUpdateManyWithoutOrganizationInput>;
  salesforce_domain?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  salesforce_last_sync_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  SalesForceColumn?: InputMaybe<SalesForceColumnUpdateManyWithoutOrganizationInput>;
  salesForceData?: InputMaybe<SalesForceDataUpdateOneWithoutOrganizationInput>;
  ScheduleItems?: InputMaybe<ScheduleItemUpdateManyWithoutOrganizationInput>;
  self_sourcing_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  send_error_log_email?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  send_lead_routing_email?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sendgrid_email_domain?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sendgrid_email_domain_id?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  sequence?: InputMaybe<SequenceUpdateManyWithoutOrganzationInput>;
  SequenceCriteriaConditions?: InputMaybe<SequenceCriteriaConditionUpdateManyWithoutOrganizationInput>;
  SequenceDashboardViews?: InputMaybe<SequenceDashboardViewUpdateManyWithoutOrganizationInput>;
  SequenceEntryCriterias?: InputMaybe<SequenceEntryCriteriaUpdateManyWithoutOrganizationInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutOrganizationInput>;
  SequenceSteps?: InputMaybe<SequenceStepUpdateManyWithoutOrganizationInput>;
  set_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
  short_resting?: InputMaybe<IntFieldUpdateOperationsInput>;
  show_calendar_options?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_contract_duration_sales?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_lead_source_to_rep?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_nocontact_button?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_payment_terms_sales?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_rep_all_leads?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_rep_my_leads?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_system_view?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sites?: InputMaybe<SiteUpdateManyWithoutOrganizationInput>;
  sms_opt_in_dialog?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SMSItems?: InputMaybe<SmsItemUpdateManyWithoutOrganizationInput>;
  SnoozeTrackings?: InputMaybe<SnoozeTrackingUpdateManyWithoutOrganizationInput>;
  StepActionLogs?: InputMaybe<StepActionLogUpdateManyWithoutOrganizationInput>;
  StepActions?: InputMaybe<StepActionUpdateManyWithoutOrganizationInput>;
  StepConditions?: InputMaybe<StepConditionUpdateManyWithoutOrganizationInput>;
  teams?: InputMaybe<TeamUpdateManyWithoutOrganizationInput>;
  Template?: InputMaybe<TemplateUpdateManyWithoutOrganizationInput>;
  TemplateVersions?: InputMaybe<TemplateVersionUpdateManyWithoutOrganizationInput>;
  timekit_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timezone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transfer_timeout?: InputMaybe<IntFieldUpdateOperationsInput>;
  TransferAttempts?: InputMaybe<TransferAttemptUpdateManyWithoutOrganizationInput>;
  twilio_numbers?: InputMaybe<TwilioNumberUpdateManyWithoutOrganizationInput>;
  TwilioFeedbacks?: InputMaybe<TwilioFeedbackUpdateManyWithoutOrganizationInput>;
  TwilioInfo?: InputMaybe<TwilioInfoUpdateOneWithoutOrganzationInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  use_revenue_planning?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  UserCustomFieldFilters?: InputMaybe<UserCustomFieldFilterUpdateManyWithoutOrganizationInput>;
  users?: InputMaybe<UserUpdateManyWithoutOrganizationInput>;
  win_rep_day_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
};

export type OrganizationUpdateWithoutLeadFieldMultiplierCustomInput = {
  ActivityMonitorMultiplier?: InputMaybe<ActivityMonitorMultiplierUpdateManyWithoutOrganizationInput>;
  algoFactor?: InputMaybe<AlgoFactorUpdateManyWithoutOrganizationInput>;
  allow_calendar_invite?: InputMaybe<BoolFieldUpdateOperationsInput>;
  allow_remove_step_action?: InputMaybe<BoolFieldUpdateOperationsInput>;
  allow_snooze_step_action?: InputMaybe<BoolFieldUpdateOperationsInput>;
  always_record?: InputMaybe<BoolFieldUpdateOperationsInput>;
  arv_initial_held_demos_generated_by_sdr_weight?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  AssociationHistories?: InputMaybe<AssociationHistoryUpdateManyWithoutOrganizationInput>;
  auto_call_interval?: InputMaybe<IntFieldUpdateOperationsInput>;
  auto_dial?: InputMaybe<BoolFieldUpdateOperationsInput>;
  automated_campaigns?: InputMaybe<AutomatedCampaignUpdateManyWithoutOrganizationInput>;
  automatic_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  AutoTransferSelections?: InputMaybe<AutoTransferSelectionUpdateManyWithoutOrganizationInput>;
  Availablities?: InputMaybe<AvailabilityUpdateManyWithoutOrganizationInput>;
  AverageOrderValue?: InputMaybe<AverageOrderValueUpdateManyWithoutOrganizationInput>;
  best_action_ever_multiplier?: InputMaybe<FloatFieldUpdateOperationsInput>;
  best_metrics?: InputMaybe<BestMetricRecordUpdateManyWithoutOrganizationInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptUpdateManyWithoutOrganizationInput>;
  callMeNows?: InputMaybe<CallMeNowUpdateManyWithoutOrganizationInput>;
  CallMultiplier?: InputMaybe<CallMultiplierUpdateManyWithoutOrganizationInput>;
  CallSharings?: InputMaybe<CallSharingUpdateManyWithoutOrganizationInput>;
  can_edit_sdr_transfer_rules?: InputMaybe<BoolFieldUpdateOperationsInput>;
  claim_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  concession?: InputMaybe<ConcessionUpdateManyWithoutOrganizationInput>;
  conference_region?: InputMaybe<EnumConferenceRegionFieldUpdateOperationsInput>;
  Conferences?: InputMaybe<ConferenceUpdateManyWithoutOrganizationInput>;
  ContactRequests?: InputMaybe<ContactRequestUpdateManyWithoutOrganizationInput>;
  Contacts?: InputMaybe<ContactUpdateManyWithoutOrganizationInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  custom_fields?: InputMaybe<CustomFieldUpdateManyWithoutOrganizationInput>;
  CustomObject?: InputMaybe<CustomObjectUpdateManyWithoutOrganizationInput>;
  CustomObjectField?: InputMaybe<CustomObjectFieldUpdateManyWithoutOrganizationInput>;
  CustomObjectFieldForeignKey?: InputMaybe<CustomObjectFieldForeignKeyUpdateManyWithoutOrganizationInput>;
  CustomObjectRecord?: InputMaybe<CustomObjectRecordUpdateManyWithoutOrganizationInput>;
  CustomObjectRow?: InputMaybe<CustomObjectRowUpdateManyWithoutOrganizationInput>;
  CustomQueueItems?: InputMaybe<CustomQueueItemUpdateManyWithoutOrganizationInput>;
  DashboardColumnOptions?: InputMaybe<DashboardColumnOptionUpdateManyWithoutOrganizationInput>;
  DashboardLeadFilters?: InputMaybe<DashboardLeadFilterUpdateManyWithoutOrganizationInput>;
  DashboardRepFilters?: InputMaybe<DashboardRepFilterUpdateManyWithoutOrganizationInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewUpdateManyWithoutOrganizationInput>;
  day_to_close?: InputMaybe<IntFieldUpdateOperationsInput>;
  default_demo_time_duration?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  demo_held_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_not_held_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_not_held_call_back_short_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_prep_time_minutes?: InputMaybe<IntFieldUpdateOperationsInput>;
  dev_account?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  dial_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  DispositionMultiplier?: InputMaybe<DispositionMultiplierUpdateManyWithoutOrganizationInput>;
  dispositions?: InputMaybe<DispositionUpdateManyWithoutOrganizationInput>;
  double_booking?: InputMaybe<BoolFieldUpdateOperationsInput>;
  dynamic_date_operators?: InputMaybe<BoolFieldUpdateOperationsInput>;
  edge_servers?: InputMaybe<OrganizationUpdateedge_ServersInput>;
  email_conflict_notification_email_list?: InputMaybe<OrganizationUpdateemail_Conflict_Notification_Email_ListInput>;
  EmailItems?: InputMaybe<EmailItemUpdateManyWithoutOrganizationInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordUpdateManyWithoutOrganizationInput>;
  EmailThreads?: InputMaybe<EmailThreadUpdateManyWithoutOrganizationInput>;
  enable_modified_metrics?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  enable_pay_period_view?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  enable_time_constraint?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  error_log_email_address?: InputMaybe<OrganizationUpdateerror_Log_Email_AddressInput>;
  estimated_average_num_of_weekly_overbooked_demo_slots?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  FolderPermissions?: InputMaybe<FolderPermissionUpdateManyWithoutOrganizationInput>;
  Folders?: InputMaybe<FolderUpdateManyWithoutOrganizationInput>;
  FutureBookingSetting?: InputMaybe<FutureBookingSettingUpdateOneWithoutOrganizationInput>;
  GlobalTransferRule?: InputMaybe<GlobalTransferRuleUpdateManyWithoutOrganizationInput>;
  held_demos_per_cc_connect?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  helpful_links?: InputMaybe<OrganizationUpdatehelpful_LinksInput>;
  HingePointMultiplier?: InputMaybe<HingePointMultiplierUpdateManyWithoutOrganizationInput>;
  hold_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
  hubspot_credential?: InputMaybe<HubSpotCredentialUpdateOneWithoutOrganizationInput>;
  hubspot_last_sync_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  hubspot_white_list?: InputMaybe<HubSpotWhitelistUpdateManyWithoutOrganizationInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inbound_close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_dm_connect_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_lt_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_revenue_value_of_sale?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_scheduled_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_set_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventUpdateManyWithoutOrganizationInput>;
  InboundConciergeWidget?: InputMaybe<InboundConciergeWidgetUpdateManyWithoutOrganizationInput>;
  industry_options?: InputMaybe<IndustryOptionUpdateManyWithoutOrganizationInput>;
  initial_sdr_sets_reps_day?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  integration_error_logs?: InputMaybe<IntegrationErrorLogUpdateManyWithoutOrganizationInput>;
  integration_mappings?: InputMaybe<IntegrationMappingUpdateManyWithoutOrganizationInput>;
  IntegrationMappingCustomObject?: InputMaybe<IntegrationMappingCustomObjectUpdateManyWithoutOrganizationInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogUpdateManyWithoutOrganizationInput>;
  IntegrationReadOnlyObject?: InputMaybe<IntegrationReadOnlyObjectUpdateManyWithoutOrganizationInput>;
  international?: InputMaybe<BoolFieldUpdateOperationsInput>;
  is_email_domain_verified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  is_salesforce_sandbox?: InputMaybe<BoolFieldUpdateOperationsInput>;
  last_lead_value_compute_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutOrganizationInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryUpdateManyWithoutOrganizationInput>;
  lead_intent?: InputMaybe<LeadIntentUpdateManyWithoutOrganizationInput>;
  lead_release_same_rep_delay_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  lead_routing_email_list?: InputMaybe<OrganizationUpdatelead_Routing_Email_ListInput>;
  lead_routing_rules?: InputMaybe<RuleUpdateManyWithoutOrganizationInput>;
  LeadCustomObjectRowAssociation?: InputMaybe<LeadCustomObjectRowAssociationUpdateManyWithoutOrganizationInput>;
  LeadFieldMultiplier?: InputMaybe<LeadFieldMultiplierUpdateManyWithoutOrganizationInput>;
  leads?: InputMaybe<LeadUpdateManyWithoutOrganizationInput>;
  leadsource_options?: InputMaybe<LeadSourceOptionUpdateManyWithoutOrganizationInput>;
  LeadValue?: InputMaybe<LeadValueUpdateManyWithoutOrganizationInput>;
  LifeTimeValueMultiplier?: InputMaybe<LifeTimeValueMultiplierUpdateManyWithoutOrganizationInput>;
  LiveTransferSetting?: InputMaybe<LiveTransferSettingUpdateOneWithoutOrganizationInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusUpdateManyWithoutOrganizationInput>;
  ltv_multiplier_custom_fields?: InputMaybe<LifeTimeValueMultiplierCustomUpdateManyWithoutOrganizationInput>;
  made_contact_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  make_sale_address_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_business_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_city_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_country_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_first_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_industry_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_last_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_lead_source_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_primary_email_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_primary_phone_number_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_state_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_sub_industry_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_zip_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  maxium_day_lag_set_to_schedule?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  mrr?: InputMaybe<IntFieldUpdateOperationsInput>;
  mrr_label?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  mrr_required_on_sale?: InputMaybe<BoolFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  NoteItems?: InputMaybe<NoteItemUpdateManyWithoutOrganizationInput>;
  Notifications?: InputMaybe<NotificationUpdateManyWithoutOrganizationInput>;
  onboardComplete?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  Opportunity?: InputMaybe<SfOpportunityUpdateManyWithoutOrganizationInput>;
  organization_holidays?: InputMaybe<OrganizationHolidayUpdateManyWithoutOrganizationInput>;
  organization_hours?: InputMaybe<OrganizationHourUpdateManyWithoutOrganizationInput>;
  OrganizationPaymentSetting?: InputMaybe<OrganizationPaymentSettingsUpdateOneWithoutOrganizationInput>;
  OrganizationPaymentSettings?: InputMaybe<OrganizationPaymentSettingsUpdateOneWithoutOrganizationsInput>;
  orgCalendarInviteMetadata?: InputMaybe<OrgCalendarInviteMetadataUpdateManyWithoutOrganizationInput>;
  OrgDefaultPipelinePerecentValue?: InputMaybe<OrgDefaultPipelinePerecentValueUpdateOneWithoutOrganizationInput>;
  OrgHubSpotSync?: InputMaybe<OrgHubSpotSyncUpdateOneWithoutOrganizationInput>;
  OrgNoEventDate?: InputMaybe<OrgNoEventDateUpdateManyWithoutOrganizationInput>;
  OrgSalesforceSync?: InputMaybe<OrgSalesforceSyncUpdateOneWithoutOrganizationInput>;
  OrgTimeslotAvailability?: InputMaybe<OrgTimeslotAvailabilityUpdateManyWithoutOrganizationInput>;
  outbound_close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_dm_connect_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_lt_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_revenue_value_of_sale?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_scheduled_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_set_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  PandadocData?: InputMaybe<PandadocDataUpdateOneWithoutOrganizationInput>;
  PandaDocTemplate?: InputMaybe<PandaDocTemplateUpdateManyWithoutOrganizationInput>;
  participant_jitter_buffer_size?: InputMaybe<EnumJitterBufferSizeFieldUpdateOperationsInput>;
  preferred_goal_setting?: InputMaybe<NullableEnumGoalsettingFieldUpdateOperationsInput>;
  prepayment?: InputMaybe<BoolFieldUpdateOperationsInput>;
  ProductChangeLog?: InputMaybe<ProductChangeLogUpdateManyWithoutOrganizationInput>;
  products?: InputMaybe<ProductUpdateManyWithoutOrganizationInput>;
  re_cold_call_hour_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  recordingStatus?: InputMaybe<EnumRecordingStatusFieldUpdateOperationsInput>;
  release_lead_resting?: InputMaybe<IntFieldUpdateOperationsInput>;
  rep_claim_limit?: InputMaybe<IntFieldUpdateOperationsInput>;
  rep_lead_allotment?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  rep_level_call_transfer?: InputMaybe<BoolFieldUpdateOperationsInput>;
  reps_can_bulk_unassign?: InputMaybe<BoolFieldUpdateOperationsInput>;
  reps_can_view_all_notes?: InputMaybe<BoolFieldUpdateOperationsInput>;
  require_sale_notes?: InputMaybe<BoolFieldUpdateOperationsInput>;
  resting_after_skipped_CC?: InputMaybe<IntFieldUpdateOperationsInput>;
  resting_period?: InputMaybe<IntFieldUpdateOperationsInput>;
  resting_period_after_unsuccessful_cold_call?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  resting_period_after_unsuccessful_demo?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  revenue_plan?: InputMaybe<RevenuePlanItemUpdateManyWithoutOrganizationInput>;
  Routings?: InputMaybe<RoutingUpdateManyWithoutOrganizationInput>;
  sale_field_options?: InputMaybe<SaleFieldOptionUpdateManyWithoutOrganizationInput>;
  sale_notification_email_list?: InputMaybe<OrganizationUpdatesale_Notification_Email_ListInput>;
  SaleConfig?: InputMaybe<SaleConfigUpdateOneWithoutOrganizationInput>;
  SaleConfigPage?: InputMaybe<SaleConfigPageUpdateManyWithoutOrganizationInput>;
  SaleConfigSection?: InputMaybe<SaleConfigSectionUpdateManyWithoutOrganizationInput>;
  SaleCycles?: InputMaybe<SaleCycleUpdateManyWithoutOrganizationInput>;
  SaleItems?: InputMaybe<SaleItemUpdateManyWithoutOrganizationInput>;
  sales?: InputMaybe<SaleUpdateManyWithoutOrganizationInput>;
  salesforce_domain?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  salesforce_last_sync_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  SalesForceColumn?: InputMaybe<SalesForceColumnUpdateManyWithoutOrganizationInput>;
  salesForceData?: InputMaybe<SalesForceDataUpdateOneWithoutOrganizationInput>;
  ScheduleItems?: InputMaybe<ScheduleItemUpdateManyWithoutOrganizationInput>;
  self_sourcing_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  send_error_log_email?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  send_lead_routing_email?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sendgrid_email_domain?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sendgrid_email_domain_id?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  sequence?: InputMaybe<SequenceUpdateManyWithoutOrganzationInput>;
  SequenceCriteriaConditions?: InputMaybe<SequenceCriteriaConditionUpdateManyWithoutOrganizationInput>;
  SequenceDashboardViews?: InputMaybe<SequenceDashboardViewUpdateManyWithoutOrganizationInput>;
  SequenceEntryCriterias?: InputMaybe<SequenceEntryCriteriaUpdateManyWithoutOrganizationInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutOrganizationInput>;
  SequenceSteps?: InputMaybe<SequenceStepUpdateManyWithoutOrganizationInput>;
  set_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
  short_resting?: InputMaybe<IntFieldUpdateOperationsInput>;
  show_calendar_options?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_contract_duration_sales?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_lead_source_to_rep?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_nocontact_button?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_payment_terms_sales?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_rep_all_leads?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_rep_my_leads?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_system_view?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sites?: InputMaybe<SiteUpdateManyWithoutOrganizationInput>;
  sms_opt_in_dialog?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SMSItems?: InputMaybe<SmsItemUpdateManyWithoutOrganizationInput>;
  SnoozeTrackings?: InputMaybe<SnoozeTrackingUpdateManyWithoutOrganizationInput>;
  StepActionLogs?: InputMaybe<StepActionLogUpdateManyWithoutOrganizationInput>;
  StepActions?: InputMaybe<StepActionUpdateManyWithoutOrganizationInput>;
  StepConditions?: InputMaybe<StepConditionUpdateManyWithoutOrganizationInput>;
  teams?: InputMaybe<TeamUpdateManyWithoutOrganizationInput>;
  Template?: InputMaybe<TemplateUpdateManyWithoutOrganizationInput>;
  TemplateVersions?: InputMaybe<TemplateVersionUpdateManyWithoutOrganizationInput>;
  timekit_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timezone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transfer_timeout?: InputMaybe<IntFieldUpdateOperationsInput>;
  TransferAttempts?: InputMaybe<TransferAttemptUpdateManyWithoutOrganizationInput>;
  twilio_numbers?: InputMaybe<TwilioNumberUpdateManyWithoutOrganizationInput>;
  TwilioFeedbacks?: InputMaybe<TwilioFeedbackUpdateManyWithoutOrganizationInput>;
  TwilioInfo?: InputMaybe<TwilioInfoUpdateOneWithoutOrganzationInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  use_revenue_planning?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  UserCustomFieldFilters?: InputMaybe<UserCustomFieldFilterUpdateManyWithoutOrganizationInput>;
  users?: InputMaybe<UserUpdateManyWithoutOrganizationInput>;
  win_rep_day_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
};

export type OrganizationUpdateWithoutLeadFieldMultiplierInput = {
  ActivityMonitorMultiplier?: InputMaybe<ActivityMonitorMultiplierUpdateManyWithoutOrganizationInput>;
  algoFactor?: InputMaybe<AlgoFactorUpdateManyWithoutOrganizationInput>;
  allow_calendar_invite?: InputMaybe<BoolFieldUpdateOperationsInput>;
  allow_remove_step_action?: InputMaybe<BoolFieldUpdateOperationsInput>;
  allow_snooze_step_action?: InputMaybe<BoolFieldUpdateOperationsInput>;
  always_record?: InputMaybe<BoolFieldUpdateOperationsInput>;
  arv_initial_held_demos_generated_by_sdr_weight?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  AssociationHistories?: InputMaybe<AssociationHistoryUpdateManyWithoutOrganizationInput>;
  auto_call_interval?: InputMaybe<IntFieldUpdateOperationsInput>;
  auto_dial?: InputMaybe<BoolFieldUpdateOperationsInput>;
  automated_campaigns?: InputMaybe<AutomatedCampaignUpdateManyWithoutOrganizationInput>;
  automatic_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  AutoTransferSelections?: InputMaybe<AutoTransferSelectionUpdateManyWithoutOrganizationInput>;
  Availablities?: InputMaybe<AvailabilityUpdateManyWithoutOrganizationInput>;
  AverageOrderValue?: InputMaybe<AverageOrderValueUpdateManyWithoutOrganizationInput>;
  best_action_ever_multiplier?: InputMaybe<FloatFieldUpdateOperationsInput>;
  best_metrics?: InputMaybe<BestMetricRecordUpdateManyWithoutOrganizationInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptUpdateManyWithoutOrganizationInput>;
  callMeNows?: InputMaybe<CallMeNowUpdateManyWithoutOrganizationInput>;
  CallMultiplier?: InputMaybe<CallMultiplierUpdateManyWithoutOrganizationInput>;
  CallSharings?: InputMaybe<CallSharingUpdateManyWithoutOrganizationInput>;
  can_edit_sdr_transfer_rules?: InputMaybe<BoolFieldUpdateOperationsInput>;
  claim_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  concession?: InputMaybe<ConcessionUpdateManyWithoutOrganizationInput>;
  conference_region?: InputMaybe<EnumConferenceRegionFieldUpdateOperationsInput>;
  Conferences?: InputMaybe<ConferenceUpdateManyWithoutOrganizationInput>;
  ContactRequests?: InputMaybe<ContactRequestUpdateManyWithoutOrganizationInput>;
  Contacts?: InputMaybe<ContactUpdateManyWithoutOrganizationInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  custom_fields?: InputMaybe<CustomFieldUpdateManyWithoutOrganizationInput>;
  CustomObject?: InputMaybe<CustomObjectUpdateManyWithoutOrganizationInput>;
  CustomObjectField?: InputMaybe<CustomObjectFieldUpdateManyWithoutOrganizationInput>;
  CustomObjectFieldForeignKey?: InputMaybe<CustomObjectFieldForeignKeyUpdateManyWithoutOrganizationInput>;
  CustomObjectRecord?: InputMaybe<CustomObjectRecordUpdateManyWithoutOrganizationInput>;
  CustomObjectRow?: InputMaybe<CustomObjectRowUpdateManyWithoutOrganizationInput>;
  CustomQueueItems?: InputMaybe<CustomQueueItemUpdateManyWithoutOrganizationInput>;
  DashboardColumnOptions?: InputMaybe<DashboardColumnOptionUpdateManyWithoutOrganizationInput>;
  DashboardLeadFilters?: InputMaybe<DashboardLeadFilterUpdateManyWithoutOrganizationInput>;
  DashboardRepFilters?: InputMaybe<DashboardRepFilterUpdateManyWithoutOrganizationInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewUpdateManyWithoutOrganizationInput>;
  day_to_close?: InputMaybe<IntFieldUpdateOperationsInput>;
  default_demo_time_duration?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  demo_held_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_not_held_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_not_held_call_back_short_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_prep_time_minutes?: InputMaybe<IntFieldUpdateOperationsInput>;
  dev_account?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  dial_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  DispositionMultiplier?: InputMaybe<DispositionMultiplierUpdateManyWithoutOrganizationInput>;
  dispositions?: InputMaybe<DispositionUpdateManyWithoutOrganizationInput>;
  double_booking?: InputMaybe<BoolFieldUpdateOperationsInput>;
  dynamic_date_operators?: InputMaybe<BoolFieldUpdateOperationsInput>;
  edge_servers?: InputMaybe<OrganizationUpdateedge_ServersInput>;
  email_conflict_notification_email_list?: InputMaybe<OrganizationUpdateemail_Conflict_Notification_Email_ListInput>;
  EmailItems?: InputMaybe<EmailItemUpdateManyWithoutOrganizationInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordUpdateManyWithoutOrganizationInput>;
  EmailThreads?: InputMaybe<EmailThreadUpdateManyWithoutOrganizationInput>;
  enable_modified_metrics?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  enable_pay_period_view?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  enable_time_constraint?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  error_log_email_address?: InputMaybe<OrganizationUpdateerror_Log_Email_AddressInput>;
  estimated_average_num_of_weekly_overbooked_demo_slots?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  FolderPermissions?: InputMaybe<FolderPermissionUpdateManyWithoutOrganizationInput>;
  Folders?: InputMaybe<FolderUpdateManyWithoutOrganizationInput>;
  FutureBookingSetting?: InputMaybe<FutureBookingSettingUpdateOneWithoutOrganizationInput>;
  GlobalTransferRule?: InputMaybe<GlobalTransferRuleUpdateManyWithoutOrganizationInput>;
  held_demos_per_cc_connect?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  helpful_links?: InputMaybe<OrganizationUpdatehelpful_LinksInput>;
  HingePointMultiplier?: InputMaybe<HingePointMultiplierUpdateManyWithoutOrganizationInput>;
  hold_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
  hubspot_credential?: InputMaybe<HubSpotCredentialUpdateOneWithoutOrganizationInput>;
  hubspot_last_sync_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  hubspot_white_list?: InputMaybe<HubSpotWhitelistUpdateManyWithoutOrganizationInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inbound_close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_dm_connect_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_lt_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_revenue_value_of_sale?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_scheduled_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_set_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventUpdateManyWithoutOrganizationInput>;
  InboundConciergeWidget?: InputMaybe<InboundConciergeWidgetUpdateManyWithoutOrganizationInput>;
  industry_options?: InputMaybe<IndustryOptionUpdateManyWithoutOrganizationInput>;
  initial_sdr_sets_reps_day?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  integration_error_logs?: InputMaybe<IntegrationErrorLogUpdateManyWithoutOrganizationInput>;
  integration_mappings?: InputMaybe<IntegrationMappingUpdateManyWithoutOrganizationInput>;
  IntegrationMappingCustomObject?: InputMaybe<IntegrationMappingCustomObjectUpdateManyWithoutOrganizationInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogUpdateManyWithoutOrganizationInput>;
  IntegrationReadOnlyObject?: InputMaybe<IntegrationReadOnlyObjectUpdateManyWithoutOrganizationInput>;
  international?: InputMaybe<BoolFieldUpdateOperationsInput>;
  is_email_domain_verified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  is_salesforce_sandbox?: InputMaybe<BoolFieldUpdateOperationsInput>;
  last_lead_value_compute_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutOrganizationInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryUpdateManyWithoutOrganizationInput>;
  lead_intent?: InputMaybe<LeadIntentUpdateManyWithoutOrganizationInput>;
  lead_release_same_rep_delay_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  lead_routing_email_list?: InputMaybe<OrganizationUpdatelead_Routing_Email_ListInput>;
  lead_routing_rules?: InputMaybe<RuleUpdateManyWithoutOrganizationInput>;
  LeadCustomObjectRowAssociation?: InputMaybe<LeadCustomObjectRowAssociationUpdateManyWithoutOrganizationInput>;
  LeadFieldMultiplierCustom?: InputMaybe<LeadFieldMultiplierCustomUpdateManyWithoutOrganizationInput>;
  leads?: InputMaybe<LeadUpdateManyWithoutOrganizationInput>;
  leadsource_options?: InputMaybe<LeadSourceOptionUpdateManyWithoutOrganizationInput>;
  LeadValue?: InputMaybe<LeadValueUpdateManyWithoutOrganizationInput>;
  LifeTimeValueMultiplier?: InputMaybe<LifeTimeValueMultiplierUpdateManyWithoutOrganizationInput>;
  LiveTransferSetting?: InputMaybe<LiveTransferSettingUpdateOneWithoutOrganizationInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusUpdateManyWithoutOrganizationInput>;
  ltv_multiplier_custom_fields?: InputMaybe<LifeTimeValueMultiplierCustomUpdateManyWithoutOrganizationInput>;
  made_contact_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  make_sale_address_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_business_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_city_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_country_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_first_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_industry_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_last_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_lead_source_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_primary_email_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_primary_phone_number_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_state_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_sub_industry_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_zip_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  maxium_day_lag_set_to_schedule?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  mrr?: InputMaybe<IntFieldUpdateOperationsInput>;
  mrr_label?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  mrr_required_on_sale?: InputMaybe<BoolFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  NoteItems?: InputMaybe<NoteItemUpdateManyWithoutOrganizationInput>;
  Notifications?: InputMaybe<NotificationUpdateManyWithoutOrganizationInput>;
  onboardComplete?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  Opportunity?: InputMaybe<SfOpportunityUpdateManyWithoutOrganizationInput>;
  organization_holidays?: InputMaybe<OrganizationHolidayUpdateManyWithoutOrganizationInput>;
  organization_hours?: InputMaybe<OrganizationHourUpdateManyWithoutOrganizationInput>;
  OrganizationPaymentSetting?: InputMaybe<OrganizationPaymentSettingsUpdateOneWithoutOrganizationInput>;
  OrganizationPaymentSettings?: InputMaybe<OrganizationPaymentSettingsUpdateOneWithoutOrganizationsInput>;
  orgCalendarInviteMetadata?: InputMaybe<OrgCalendarInviteMetadataUpdateManyWithoutOrganizationInput>;
  OrgDefaultPipelinePerecentValue?: InputMaybe<OrgDefaultPipelinePerecentValueUpdateOneWithoutOrganizationInput>;
  OrgHubSpotSync?: InputMaybe<OrgHubSpotSyncUpdateOneWithoutOrganizationInput>;
  OrgNoEventDate?: InputMaybe<OrgNoEventDateUpdateManyWithoutOrganizationInput>;
  OrgSalesforceSync?: InputMaybe<OrgSalesforceSyncUpdateOneWithoutOrganizationInput>;
  OrgTimeslotAvailability?: InputMaybe<OrgTimeslotAvailabilityUpdateManyWithoutOrganizationInput>;
  outbound_close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_dm_connect_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_lt_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_revenue_value_of_sale?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_scheduled_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_set_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  PandadocData?: InputMaybe<PandadocDataUpdateOneWithoutOrganizationInput>;
  PandaDocTemplate?: InputMaybe<PandaDocTemplateUpdateManyWithoutOrganizationInput>;
  participant_jitter_buffer_size?: InputMaybe<EnumJitterBufferSizeFieldUpdateOperationsInput>;
  preferred_goal_setting?: InputMaybe<NullableEnumGoalsettingFieldUpdateOperationsInput>;
  prepayment?: InputMaybe<BoolFieldUpdateOperationsInput>;
  ProductChangeLog?: InputMaybe<ProductChangeLogUpdateManyWithoutOrganizationInput>;
  products?: InputMaybe<ProductUpdateManyWithoutOrganizationInput>;
  re_cold_call_hour_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  recordingStatus?: InputMaybe<EnumRecordingStatusFieldUpdateOperationsInput>;
  release_lead_resting?: InputMaybe<IntFieldUpdateOperationsInput>;
  rep_claim_limit?: InputMaybe<IntFieldUpdateOperationsInput>;
  rep_lead_allotment?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  rep_level_call_transfer?: InputMaybe<BoolFieldUpdateOperationsInput>;
  reps_can_bulk_unassign?: InputMaybe<BoolFieldUpdateOperationsInput>;
  reps_can_view_all_notes?: InputMaybe<BoolFieldUpdateOperationsInput>;
  require_sale_notes?: InputMaybe<BoolFieldUpdateOperationsInput>;
  resting_after_skipped_CC?: InputMaybe<IntFieldUpdateOperationsInput>;
  resting_period?: InputMaybe<IntFieldUpdateOperationsInput>;
  resting_period_after_unsuccessful_cold_call?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  resting_period_after_unsuccessful_demo?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  revenue_plan?: InputMaybe<RevenuePlanItemUpdateManyWithoutOrganizationInput>;
  Routings?: InputMaybe<RoutingUpdateManyWithoutOrganizationInput>;
  sale_field_options?: InputMaybe<SaleFieldOptionUpdateManyWithoutOrganizationInput>;
  sale_notification_email_list?: InputMaybe<OrganizationUpdatesale_Notification_Email_ListInput>;
  SaleConfig?: InputMaybe<SaleConfigUpdateOneWithoutOrganizationInput>;
  SaleConfigPage?: InputMaybe<SaleConfigPageUpdateManyWithoutOrganizationInput>;
  SaleConfigSection?: InputMaybe<SaleConfigSectionUpdateManyWithoutOrganizationInput>;
  SaleCycles?: InputMaybe<SaleCycleUpdateManyWithoutOrganizationInput>;
  SaleItems?: InputMaybe<SaleItemUpdateManyWithoutOrganizationInput>;
  sales?: InputMaybe<SaleUpdateManyWithoutOrganizationInput>;
  salesforce_domain?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  salesforce_last_sync_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  SalesForceColumn?: InputMaybe<SalesForceColumnUpdateManyWithoutOrganizationInput>;
  salesForceData?: InputMaybe<SalesForceDataUpdateOneWithoutOrganizationInput>;
  ScheduleItems?: InputMaybe<ScheduleItemUpdateManyWithoutOrganizationInput>;
  self_sourcing_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  send_error_log_email?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  send_lead_routing_email?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sendgrid_email_domain?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sendgrid_email_domain_id?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  sequence?: InputMaybe<SequenceUpdateManyWithoutOrganzationInput>;
  SequenceCriteriaConditions?: InputMaybe<SequenceCriteriaConditionUpdateManyWithoutOrganizationInput>;
  SequenceDashboardViews?: InputMaybe<SequenceDashboardViewUpdateManyWithoutOrganizationInput>;
  SequenceEntryCriterias?: InputMaybe<SequenceEntryCriteriaUpdateManyWithoutOrganizationInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutOrganizationInput>;
  SequenceSteps?: InputMaybe<SequenceStepUpdateManyWithoutOrganizationInput>;
  set_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
  short_resting?: InputMaybe<IntFieldUpdateOperationsInput>;
  show_calendar_options?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_contract_duration_sales?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_lead_source_to_rep?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_nocontact_button?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_payment_terms_sales?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_rep_all_leads?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_rep_my_leads?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_system_view?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sites?: InputMaybe<SiteUpdateManyWithoutOrganizationInput>;
  sms_opt_in_dialog?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SMSItems?: InputMaybe<SmsItemUpdateManyWithoutOrganizationInput>;
  SnoozeTrackings?: InputMaybe<SnoozeTrackingUpdateManyWithoutOrganizationInput>;
  StepActionLogs?: InputMaybe<StepActionLogUpdateManyWithoutOrganizationInput>;
  StepActions?: InputMaybe<StepActionUpdateManyWithoutOrganizationInput>;
  StepConditions?: InputMaybe<StepConditionUpdateManyWithoutOrganizationInput>;
  teams?: InputMaybe<TeamUpdateManyWithoutOrganizationInput>;
  Template?: InputMaybe<TemplateUpdateManyWithoutOrganizationInput>;
  TemplateVersions?: InputMaybe<TemplateVersionUpdateManyWithoutOrganizationInput>;
  timekit_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timezone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transfer_timeout?: InputMaybe<IntFieldUpdateOperationsInput>;
  TransferAttempts?: InputMaybe<TransferAttemptUpdateManyWithoutOrganizationInput>;
  twilio_numbers?: InputMaybe<TwilioNumberUpdateManyWithoutOrganizationInput>;
  TwilioFeedbacks?: InputMaybe<TwilioFeedbackUpdateManyWithoutOrganizationInput>;
  TwilioInfo?: InputMaybe<TwilioInfoUpdateOneWithoutOrganzationInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  use_revenue_planning?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  UserCustomFieldFilters?: InputMaybe<UserCustomFieldFilterUpdateManyWithoutOrganizationInput>;
  users?: InputMaybe<UserUpdateManyWithoutOrganizationInput>;
  win_rep_day_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
};

export type OrganizationUpdateWithoutLeadsInput = {
  ActivityMonitorMultiplier?: InputMaybe<ActivityMonitorMultiplierUpdateManyWithoutOrganizationInput>;
  algoFactor?: InputMaybe<AlgoFactorUpdateManyWithoutOrganizationInput>;
  allow_calendar_invite?: InputMaybe<BoolFieldUpdateOperationsInput>;
  allow_remove_step_action?: InputMaybe<BoolFieldUpdateOperationsInput>;
  allow_snooze_step_action?: InputMaybe<BoolFieldUpdateOperationsInput>;
  always_record?: InputMaybe<BoolFieldUpdateOperationsInput>;
  arv_initial_held_demos_generated_by_sdr_weight?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  AssociationHistories?: InputMaybe<AssociationHistoryUpdateManyWithoutOrganizationInput>;
  auto_call_interval?: InputMaybe<IntFieldUpdateOperationsInput>;
  auto_dial?: InputMaybe<BoolFieldUpdateOperationsInput>;
  automated_campaigns?: InputMaybe<AutomatedCampaignUpdateManyWithoutOrganizationInput>;
  automatic_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  AutoTransferSelections?: InputMaybe<AutoTransferSelectionUpdateManyWithoutOrganizationInput>;
  Availablities?: InputMaybe<AvailabilityUpdateManyWithoutOrganizationInput>;
  AverageOrderValue?: InputMaybe<AverageOrderValueUpdateManyWithoutOrganizationInput>;
  best_action_ever_multiplier?: InputMaybe<FloatFieldUpdateOperationsInput>;
  best_metrics?: InputMaybe<BestMetricRecordUpdateManyWithoutOrganizationInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptUpdateManyWithoutOrganizationInput>;
  callMeNows?: InputMaybe<CallMeNowUpdateManyWithoutOrganizationInput>;
  CallMultiplier?: InputMaybe<CallMultiplierUpdateManyWithoutOrganizationInput>;
  CallSharings?: InputMaybe<CallSharingUpdateManyWithoutOrganizationInput>;
  can_edit_sdr_transfer_rules?: InputMaybe<BoolFieldUpdateOperationsInput>;
  claim_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  concession?: InputMaybe<ConcessionUpdateManyWithoutOrganizationInput>;
  conference_region?: InputMaybe<EnumConferenceRegionFieldUpdateOperationsInput>;
  Conferences?: InputMaybe<ConferenceUpdateManyWithoutOrganizationInput>;
  ContactRequests?: InputMaybe<ContactRequestUpdateManyWithoutOrganizationInput>;
  Contacts?: InputMaybe<ContactUpdateManyWithoutOrganizationInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  custom_fields?: InputMaybe<CustomFieldUpdateManyWithoutOrganizationInput>;
  CustomObject?: InputMaybe<CustomObjectUpdateManyWithoutOrganizationInput>;
  CustomObjectField?: InputMaybe<CustomObjectFieldUpdateManyWithoutOrganizationInput>;
  CustomObjectFieldForeignKey?: InputMaybe<CustomObjectFieldForeignKeyUpdateManyWithoutOrganizationInput>;
  CustomObjectRecord?: InputMaybe<CustomObjectRecordUpdateManyWithoutOrganizationInput>;
  CustomObjectRow?: InputMaybe<CustomObjectRowUpdateManyWithoutOrganizationInput>;
  CustomQueueItems?: InputMaybe<CustomQueueItemUpdateManyWithoutOrganizationInput>;
  DashboardColumnOptions?: InputMaybe<DashboardColumnOptionUpdateManyWithoutOrganizationInput>;
  DashboardLeadFilters?: InputMaybe<DashboardLeadFilterUpdateManyWithoutOrganizationInput>;
  DashboardRepFilters?: InputMaybe<DashboardRepFilterUpdateManyWithoutOrganizationInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewUpdateManyWithoutOrganizationInput>;
  day_to_close?: InputMaybe<IntFieldUpdateOperationsInput>;
  default_demo_time_duration?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  demo_held_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_not_held_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_not_held_call_back_short_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_prep_time_minutes?: InputMaybe<IntFieldUpdateOperationsInput>;
  dev_account?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  dial_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  DispositionMultiplier?: InputMaybe<DispositionMultiplierUpdateManyWithoutOrganizationInput>;
  dispositions?: InputMaybe<DispositionUpdateManyWithoutOrganizationInput>;
  double_booking?: InputMaybe<BoolFieldUpdateOperationsInput>;
  dynamic_date_operators?: InputMaybe<BoolFieldUpdateOperationsInput>;
  edge_servers?: InputMaybe<OrganizationUpdateedge_ServersInput>;
  email_conflict_notification_email_list?: InputMaybe<OrganizationUpdateemail_Conflict_Notification_Email_ListInput>;
  EmailItems?: InputMaybe<EmailItemUpdateManyWithoutOrganizationInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordUpdateManyWithoutOrganizationInput>;
  EmailThreads?: InputMaybe<EmailThreadUpdateManyWithoutOrganizationInput>;
  enable_modified_metrics?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  enable_pay_period_view?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  enable_time_constraint?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  error_log_email_address?: InputMaybe<OrganizationUpdateerror_Log_Email_AddressInput>;
  estimated_average_num_of_weekly_overbooked_demo_slots?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  FolderPermissions?: InputMaybe<FolderPermissionUpdateManyWithoutOrganizationInput>;
  Folders?: InputMaybe<FolderUpdateManyWithoutOrganizationInput>;
  FutureBookingSetting?: InputMaybe<FutureBookingSettingUpdateOneWithoutOrganizationInput>;
  GlobalTransferRule?: InputMaybe<GlobalTransferRuleUpdateManyWithoutOrganizationInput>;
  held_demos_per_cc_connect?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  helpful_links?: InputMaybe<OrganizationUpdatehelpful_LinksInput>;
  HingePointMultiplier?: InputMaybe<HingePointMultiplierUpdateManyWithoutOrganizationInput>;
  hold_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
  hubspot_credential?: InputMaybe<HubSpotCredentialUpdateOneWithoutOrganizationInput>;
  hubspot_last_sync_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  hubspot_white_list?: InputMaybe<HubSpotWhitelistUpdateManyWithoutOrganizationInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inbound_close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_dm_connect_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_lt_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_revenue_value_of_sale?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_scheduled_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_set_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventUpdateManyWithoutOrganizationInput>;
  InboundConciergeWidget?: InputMaybe<InboundConciergeWidgetUpdateManyWithoutOrganizationInput>;
  industry_options?: InputMaybe<IndustryOptionUpdateManyWithoutOrganizationInput>;
  initial_sdr_sets_reps_day?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  integration_error_logs?: InputMaybe<IntegrationErrorLogUpdateManyWithoutOrganizationInput>;
  integration_mappings?: InputMaybe<IntegrationMappingUpdateManyWithoutOrganizationInput>;
  IntegrationMappingCustomObject?: InputMaybe<IntegrationMappingCustomObjectUpdateManyWithoutOrganizationInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogUpdateManyWithoutOrganizationInput>;
  IntegrationReadOnlyObject?: InputMaybe<IntegrationReadOnlyObjectUpdateManyWithoutOrganizationInput>;
  international?: InputMaybe<BoolFieldUpdateOperationsInput>;
  is_email_domain_verified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  is_salesforce_sandbox?: InputMaybe<BoolFieldUpdateOperationsInput>;
  last_lead_value_compute_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutOrganizationInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryUpdateManyWithoutOrganizationInput>;
  lead_intent?: InputMaybe<LeadIntentUpdateManyWithoutOrganizationInput>;
  lead_release_same_rep_delay_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  lead_routing_email_list?: InputMaybe<OrganizationUpdatelead_Routing_Email_ListInput>;
  lead_routing_rules?: InputMaybe<RuleUpdateManyWithoutOrganizationInput>;
  LeadCustomObjectRowAssociation?: InputMaybe<LeadCustomObjectRowAssociationUpdateManyWithoutOrganizationInput>;
  LeadFieldMultiplier?: InputMaybe<LeadFieldMultiplierUpdateManyWithoutOrganizationInput>;
  LeadFieldMultiplierCustom?: InputMaybe<LeadFieldMultiplierCustomUpdateManyWithoutOrganizationInput>;
  leadsource_options?: InputMaybe<LeadSourceOptionUpdateManyWithoutOrganizationInput>;
  LeadValue?: InputMaybe<LeadValueUpdateManyWithoutOrganizationInput>;
  LifeTimeValueMultiplier?: InputMaybe<LifeTimeValueMultiplierUpdateManyWithoutOrganizationInput>;
  LiveTransferSetting?: InputMaybe<LiveTransferSettingUpdateOneWithoutOrganizationInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusUpdateManyWithoutOrganizationInput>;
  ltv_multiplier_custom_fields?: InputMaybe<LifeTimeValueMultiplierCustomUpdateManyWithoutOrganizationInput>;
  made_contact_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  make_sale_address_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_business_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_city_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_country_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_first_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_industry_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_last_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_lead_source_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_primary_email_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_primary_phone_number_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_state_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_sub_industry_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_zip_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  maxium_day_lag_set_to_schedule?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  mrr?: InputMaybe<IntFieldUpdateOperationsInput>;
  mrr_label?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  mrr_required_on_sale?: InputMaybe<BoolFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  NoteItems?: InputMaybe<NoteItemUpdateManyWithoutOrganizationInput>;
  Notifications?: InputMaybe<NotificationUpdateManyWithoutOrganizationInput>;
  onboardComplete?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  Opportunity?: InputMaybe<SfOpportunityUpdateManyWithoutOrganizationInput>;
  organization_holidays?: InputMaybe<OrganizationHolidayUpdateManyWithoutOrganizationInput>;
  organization_hours?: InputMaybe<OrganizationHourUpdateManyWithoutOrganizationInput>;
  OrganizationPaymentSetting?: InputMaybe<OrganizationPaymentSettingsUpdateOneWithoutOrganizationInput>;
  OrganizationPaymentSettings?: InputMaybe<OrganizationPaymentSettingsUpdateOneWithoutOrganizationsInput>;
  orgCalendarInviteMetadata?: InputMaybe<OrgCalendarInviteMetadataUpdateManyWithoutOrganizationInput>;
  OrgDefaultPipelinePerecentValue?: InputMaybe<OrgDefaultPipelinePerecentValueUpdateOneWithoutOrganizationInput>;
  OrgHubSpotSync?: InputMaybe<OrgHubSpotSyncUpdateOneWithoutOrganizationInput>;
  OrgNoEventDate?: InputMaybe<OrgNoEventDateUpdateManyWithoutOrganizationInput>;
  OrgSalesforceSync?: InputMaybe<OrgSalesforceSyncUpdateOneWithoutOrganizationInput>;
  OrgTimeslotAvailability?: InputMaybe<OrgTimeslotAvailabilityUpdateManyWithoutOrganizationInput>;
  outbound_close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_dm_connect_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_lt_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_revenue_value_of_sale?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_scheduled_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_set_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  PandadocData?: InputMaybe<PandadocDataUpdateOneWithoutOrganizationInput>;
  PandaDocTemplate?: InputMaybe<PandaDocTemplateUpdateManyWithoutOrganizationInput>;
  participant_jitter_buffer_size?: InputMaybe<EnumJitterBufferSizeFieldUpdateOperationsInput>;
  preferred_goal_setting?: InputMaybe<NullableEnumGoalsettingFieldUpdateOperationsInput>;
  prepayment?: InputMaybe<BoolFieldUpdateOperationsInput>;
  ProductChangeLog?: InputMaybe<ProductChangeLogUpdateManyWithoutOrganizationInput>;
  products?: InputMaybe<ProductUpdateManyWithoutOrganizationInput>;
  re_cold_call_hour_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  recordingStatus?: InputMaybe<EnumRecordingStatusFieldUpdateOperationsInput>;
  release_lead_resting?: InputMaybe<IntFieldUpdateOperationsInput>;
  rep_claim_limit?: InputMaybe<IntFieldUpdateOperationsInput>;
  rep_lead_allotment?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  rep_level_call_transfer?: InputMaybe<BoolFieldUpdateOperationsInput>;
  reps_can_bulk_unassign?: InputMaybe<BoolFieldUpdateOperationsInput>;
  reps_can_view_all_notes?: InputMaybe<BoolFieldUpdateOperationsInput>;
  require_sale_notes?: InputMaybe<BoolFieldUpdateOperationsInput>;
  resting_after_skipped_CC?: InputMaybe<IntFieldUpdateOperationsInput>;
  resting_period?: InputMaybe<IntFieldUpdateOperationsInput>;
  resting_period_after_unsuccessful_cold_call?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  resting_period_after_unsuccessful_demo?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  revenue_plan?: InputMaybe<RevenuePlanItemUpdateManyWithoutOrganizationInput>;
  Routings?: InputMaybe<RoutingUpdateManyWithoutOrganizationInput>;
  sale_field_options?: InputMaybe<SaleFieldOptionUpdateManyWithoutOrganizationInput>;
  sale_notification_email_list?: InputMaybe<OrganizationUpdatesale_Notification_Email_ListInput>;
  SaleConfig?: InputMaybe<SaleConfigUpdateOneWithoutOrganizationInput>;
  SaleConfigPage?: InputMaybe<SaleConfigPageUpdateManyWithoutOrganizationInput>;
  SaleConfigSection?: InputMaybe<SaleConfigSectionUpdateManyWithoutOrganizationInput>;
  SaleCycles?: InputMaybe<SaleCycleUpdateManyWithoutOrganizationInput>;
  SaleItems?: InputMaybe<SaleItemUpdateManyWithoutOrganizationInput>;
  sales?: InputMaybe<SaleUpdateManyWithoutOrganizationInput>;
  salesforce_domain?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  salesforce_last_sync_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  SalesForceColumn?: InputMaybe<SalesForceColumnUpdateManyWithoutOrganizationInput>;
  salesForceData?: InputMaybe<SalesForceDataUpdateOneWithoutOrganizationInput>;
  ScheduleItems?: InputMaybe<ScheduleItemUpdateManyWithoutOrganizationInput>;
  self_sourcing_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  send_error_log_email?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  send_lead_routing_email?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sendgrid_email_domain?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sendgrid_email_domain_id?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  sequence?: InputMaybe<SequenceUpdateManyWithoutOrganzationInput>;
  SequenceCriteriaConditions?: InputMaybe<SequenceCriteriaConditionUpdateManyWithoutOrganizationInput>;
  SequenceDashboardViews?: InputMaybe<SequenceDashboardViewUpdateManyWithoutOrganizationInput>;
  SequenceEntryCriterias?: InputMaybe<SequenceEntryCriteriaUpdateManyWithoutOrganizationInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutOrganizationInput>;
  SequenceSteps?: InputMaybe<SequenceStepUpdateManyWithoutOrganizationInput>;
  set_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
  short_resting?: InputMaybe<IntFieldUpdateOperationsInput>;
  show_calendar_options?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_contract_duration_sales?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_lead_source_to_rep?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_nocontact_button?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_payment_terms_sales?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_rep_all_leads?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_rep_my_leads?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_system_view?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sites?: InputMaybe<SiteUpdateManyWithoutOrganizationInput>;
  sms_opt_in_dialog?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SMSItems?: InputMaybe<SmsItemUpdateManyWithoutOrganizationInput>;
  SnoozeTrackings?: InputMaybe<SnoozeTrackingUpdateManyWithoutOrganizationInput>;
  StepActionLogs?: InputMaybe<StepActionLogUpdateManyWithoutOrganizationInput>;
  StepActions?: InputMaybe<StepActionUpdateManyWithoutOrganizationInput>;
  StepConditions?: InputMaybe<StepConditionUpdateManyWithoutOrganizationInput>;
  teams?: InputMaybe<TeamUpdateManyWithoutOrganizationInput>;
  Template?: InputMaybe<TemplateUpdateManyWithoutOrganizationInput>;
  TemplateVersions?: InputMaybe<TemplateVersionUpdateManyWithoutOrganizationInput>;
  timekit_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timezone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transfer_timeout?: InputMaybe<IntFieldUpdateOperationsInput>;
  TransferAttempts?: InputMaybe<TransferAttemptUpdateManyWithoutOrganizationInput>;
  twilio_numbers?: InputMaybe<TwilioNumberUpdateManyWithoutOrganizationInput>;
  TwilioFeedbacks?: InputMaybe<TwilioFeedbackUpdateManyWithoutOrganizationInput>;
  TwilioInfo?: InputMaybe<TwilioInfoUpdateOneWithoutOrganzationInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  use_revenue_planning?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  UserCustomFieldFilters?: InputMaybe<UserCustomFieldFilterUpdateManyWithoutOrganizationInput>;
  users?: InputMaybe<UserUpdateManyWithoutOrganizationInput>;
  win_rep_day_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
};

export type OrganizationUpdateWithoutLeadValueInput = {
  ActivityMonitorMultiplier?: InputMaybe<ActivityMonitorMultiplierUpdateManyWithoutOrganizationInput>;
  algoFactor?: InputMaybe<AlgoFactorUpdateManyWithoutOrganizationInput>;
  allow_calendar_invite?: InputMaybe<BoolFieldUpdateOperationsInput>;
  allow_remove_step_action?: InputMaybe<BoolFieldUpdateOperationsInput>;
  allow_snooze_step_action?: InputMaybe<BoolFieldUpdateOperationsInput>;
  always_record?: InputMaybe<BoolFieldUpdateOperationsInput>;
  arv_initial_held_demos_generated_by_sdr_weight?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  AssociationHistories?: InputMaybe<AssociationHistoryUpdateManyWithoutOrganizationInput>;
  auto_call_interval?: InputMaybe<IntFieldUpdateOperationsInput>;
  auto_dial?: InputMaybe<BoolFieldUpdateOperationsInput>;
  automated_campaigns?: InputMaybe<AutomatedCampaignUpdateManyWithoutOrganizationInput>;
  automatic_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  AutoTransferSelections?: InputMaybe<AutoTransferSelectionUpdateManyWithoutOrganizationInput>;
  Availablities?: InputMaybe<AvailabilityUpdateManyWithoutOrganizationInput>;
  AverageOrderValue?: InputMaybe<AverageOrderValueUpdateManyWithoutOrganizationInput>;
  best_action_ever_multiplier?: InputMaybe<FloatFieldUpdateOperationsInput>;
  best_metrics?: InputMaybe<BestMetricRecordUpdateManyWithoutOrganizationInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptUpdateManyWithoutOrganizationInput>;
  callMeNows?: InputMaybe<CallMeNowUpdateManyWithoutOrganizationInput>;
  CallMultiplier?: InputMaybe<CallMultiplierUpdateManyWithoutOrganizationInput>;
  CallSharings?: InputMaybe<CallSharingUpdateManyWithoutOrganizationInput>;
  can_edit_sdr_transfer_rules?: InputMaybe<BoolFieldUpdateOperationsInput>;
  claim_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  concession?: InputMaybe<ConcessionUpdateManyWithoutOrganizationInput>;
  conference_region?: InputMaybe<EnumConferenceRegionFieldUpdateOperationsInput>;
  Conferences?: InputMaybe<ConferenceUpdateManyWithoutOrganizationInput>;
  ContactRequests?: InputMaybe<ContactRequestUpdateManyWithoutOrganizationInput>;
  Contacts?: InputMaybe<ContactUpdateManyWithoutOrganizationInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  custom_fields?: InputMaybe<CustomFieldUpdateManyWithoutOrganizationInput>;
  CustomObject?: InputMaybe<CustomObjectUpdateManyWithoutOrganizationInput>;
  CustomObjectField?: InputMaybe<CustomObjectFieldUpdateManyWithoutOrganizationInput>;
  CustomObjectFieldForeignKey?: InputMaybe<CustomObjectFieldForeignKeyUpdateManyWithoutOrganizationInput>;
  CustomObjectRecord?: InputMaybe<CustomObjectRecordUpdateManyWithoutOrganizationInput>;
  CustomObjectRow?: InputMaybe<CustomObjectRowUpdateManyWithoutOrganizationInput>;
  CustomQueueItems?: InputMaybe<CustomQueueItemUpdateManyWithoutOrganizationInput>;
  DashboardColumnOptions?: InputMaybe<DashboardColumnOptionUpdateManyWithoutOrganizationInput>;
  DashboardLeadFilters?: InputMaybe<DashboardLeadFilterUpdateManyWithoutOrganizationInput>;
  DashboardRepFilters?: InputMaybe<DashboardRepFilterUpdateManyWithoutOrganizationInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewUpdateManyWithoutOrganizationInput>;
  day_to_close?: InputMaybe<IntFieldUpdateOperationsInput>;
  default_demo_time_duration?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  demo_held_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_not_held_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_not_held_call_back_short_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_prep_time_minutes?: InputMaybe<IntFieldUpdateOperationsInput>;
  dev_account?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  dial_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  DispositionMultiplier?: InputMaybe<DispositionMultiplierUpdateManyWithoutOrganizationInput>;
  dispositions?: InputMaybe<DispositionUpdateManyWithoutOrganizationInput>;
  double_booking?: InputMaybe<BoolFieldUpdateOperationsInput>;
  dynamic_date_operators?: InputMaybe<BoolFieldUpdateOperationsInput>;
  edge_servers?: InputMaybe<OrganizationUpdateedge_ServersInput>;
  email_conflict_notification_email_list?: InputMaybe<OrganizationUpdateemail_Conflict_Notification_Email_ListInput>;
  EmailItems?: InputMaybe<EmailItemUpdateManyWithoutOrganizationInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordUpdateManyWithoutOrganizationInput>;
  EmailThreads?: InputMaybe<EmailThreadUpdateManyWithoutOrganizationInput>;
  enable_modified_metrics?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  enable_pay_period_view?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  enable_time_constraint?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  error_log_email_address?: InputMaybe<OrganizationUpdateerror_Log_Email_AddressInput>;
  estimated_average_num_of_weekly_overbooked_demo_slots?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  FolderPermissions?: InputMaybe<FolderPermissionUpdateManyWithoutOrganizationInput>;
  Folders?: InputMaybe<FolderUpdateManyWithoutOrganizationInput>;
  FutureBookingSetting?: InputMaybe<FutureBookingSettingUpdateOneWithoutOrganizationInput>;
  GlobalTransferRule?: InputMaybe<GlobalTransferRuleUpdateManyWithoutOrganizationInput>;
  held_demos_per_cc_connect?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  helpful_links?: InputMaybe<OrganizationUpdatehelpful_LinksInput>;
  HingePointMultiplier?: InputMaybe<HingePointMultiplierUpdateManyWithoutOrganizationInput>;
  hold_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
  hubspot_credential?: InputMaybe<HubSpotCredentialUpdateOneWithoutOrganizationInput>;
  hubspot_last_sync_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  hubspot_white_list?: InputMaybe<HubSpotWhitelistUpdateManyWithoutOrganizationInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inbound_close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_dm_connect_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_lt_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_revenue_value_of_sale?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_scheduled_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_set_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventUpdateManyWithoutOrganizationInput>;
  InboundConciergeWidget?: InputMaybe<InboundConciergeWidgetUpdateManyWithoutOrganizationInput>;
  industry_options?: InputMaybe<IndustryOptionUpdateManyWithoutOrganizationInput>;
  initial_sdr_sets_reps_day?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  integration_error_logs?: InputMaybe<IntegrationErrorLogUpdateManyWithoutOrganizationInput>;
  integration_mappings?: InputMaybe<IntegrationMappingUpdateManyWithoutOrganizationInput>;
  IntegrationMappingCustomObject?: InputMaybe<IntegrationMappingCustomObjectUpdateManyWithoutOrganizationInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogUpdateManyWithoutOrganizationInput>;
  IntegrationReadOnlyObject?: InputMaybe<IntegrationReadOnlyObjectUpdateManyWithoutOrganizationInput>;
  international?: InputMaybe<BoolFieldUpdateOperationsInput>;
  is_email_domain_verified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  is_salesforce_sandbox?: InputMaybe<BoolFieldUpdateOperationsInput>;
  last_lead_value_compute_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutOrganizationInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryUpdateManyWithoutOrganizationInput>;
  lead_intent?: InputMaybe<LeadIntentUpdateManyWithoutOrganizationInput>;
  lead_release_same_rep_delay_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  lead_routing_email_list?: InputMaybe<OrganizationUpdatelead_Routing_Email_ListInput>;
  lead_routing_rules?: InputMaybe<RuleUpdateManyWithoutOrganizationInput>;
  LeadCustomObjectRowAssociation?: InputMaybe<LeadCustomObjectRowAssociationUpdateManyWithoutOrganizationInput>;
  LeadFieldMultiplier?: InputMaybe<LeadFieldMultiplierUpdateManyWithoutOrganizationInput>;
  LeadFieldMultiplierCustom?: InputMaybe<LeadFieldMultiplierCustomUpdateManyWithoutOrganizationInput>;
  leads?: InputMaybe<LeadUpdateManyWithoutOrganizationInput>;
  leadsource_options?: InputMaybe<LeadSourceOptionUpdateManyWithoutOrganizationInput>;
  LifeTimeValueMultiplier?: InputMaybe<LifeTimeValueMultiplierUpdateManyWithoutOrganizationInput>;
  LiveTransferSetting?: InputMaybe<LiveTransferSettingUpdateOneWithoutOrganizationInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusUpdateManyWithoutOrganizationInput>;
  ltv_multiplier_custom_fields?: InputMaybe<LifeTimeValueMultiplierCustomUpdateManyWithoutOrganizationInput>;
  made_contact_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  make_sale_address_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_business_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_city_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_country_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_first_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_industry_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_last_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_lead_source_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_primary_email_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_primary_phone_number_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_state_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_sub_industry_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_zip_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  maxium_day_lag_set_to_schedule?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  mrr?: InputMaybe<IntFieldUpdateOperationsInput>;
  mrr_label?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  mrr_required_on_sale?: InputMaybe<BoolFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  NoteItems?: InputMaybe<NoteItemUpdateManyWithoutOrganizationInput>;
  Notifications?: InputMaybe<NotificationUpdateManyWithoutOrganizationInput>;
  onboardComplete?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  Opportunity?: InputMaybe<SfOpportunityUpdateManyWithoutOrganizationInput>;
  organization_holidays?: InputMaybe<OrganizationHolidayUpdateManyWithoutOrganizationInput>;
  organization_hours?: InputMaybe<OrganizationHourUpdateManyWithoutOrganizationInput>;
  OrganizationPaymentSetting?: InputMaybe<OrganizationPaymentSettingsUpdateOneWithoutOrganizationInput>;
  OrganizationPaymentSettings?: InputMaybe<OrganizationPaymentSettingsUpdateOneWithoutOrganizationsInput>;
  orgCalendarInviteMetadata?: InputMaybe<OrgCalendarInviteMetadataUpdateManyWithoutOrganizationInput>;
  OrgDefaultPipelinePerecentValue?: InputMaybe<OrgDefaultPipelinePerecentValueUpdateOneWithoutOrganizationInput>;
  OrgHubSpotSync?: InputMaybe<OrgHubSpotSyncUpdateOneWithoutOrganizationInput>;
  OrgNoEventDate?: InputMaybe<OrgNoEventDateUpdateManyWithoutOrganizationInput>;
  OrgSalesforceSync?: InputMaybe<OrgSalesforceSyncUpdateOneWithoutOrganizationInput>;
  OrgTimeslotAvailability?: InputMaybe<OrgTimeslotAvailabilityUpdateManyWithoutOrganizationInput>;
  outbound_close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_dm_connect_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_lt_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_revenue_value_of_sale?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_scheduled_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_set_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  PandadocData?: InputMaybe<PandadocDataUpdateOneWithoutOrganizationInput>;
  PandaDocTemplate?: InputMaybe<PandaDocTemplateUpdateManyWithoutOrganizationInput>;
  participant_jitter_buffer_size?: InputMaybe<EnumJitterBufferSizeFieldUpdateOperationsInput>;
  preferred_goal_setting?: InputMaybe<NullableEnumGoalsettingFieldUpdateOperationsInput>;
  prepayment?: InputMaybe<BoolFieldUpdateOperationsInput>;
  ProductChangeLog?: InputMaybe<ProductChangeLogUpdateManyWithoutOrganizationInput>;
  products?: InputMaybe<ProductUpdateManyWithoutOrganizationInput>;
  re_cold_call_hour_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  recordingStatus?: InputMaybe<EnumRecordingStatusFieldUpdateOperationsInput>;
  release_lead_resting?: InputMaybe<IntFieldUpdateOperationsInput>;
  rep_claim_limit?: InputMaybe<IntFieldUpdateOperationsInput>;
  rep_lead_allotment?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  rep_level_call_transfer?: InputMaybe<BoolFieldUpdateOperationsInput>;
  reps_can_bulk_unassign?: InputMaybe<BoolFieldUpdateOperationsInput>;
  reps_can_view_all_notes?: InputMaybe<BoolFieldUpdateOperationsInput>;
  require_sale_notes?: InputMaybe<BoolFieldUpdateOperationsInput>;
  resting_after_skipped_CC?: InputMaybe<IntFieldUpdateOperationsInput>;
  resting_period?: InputMaybe<IntFieldUpdateOperationsInput>;
  resting_period_after_unsuccessful_cold_call?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  resting_period_after_unsuccessful_demo?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  revenue_plan?: InputMaybe<RevenuePlanItemUpdateManyWithoutOrganizationInput>;
  Routings?: InputMaybe<RoutingUpdateManyWithoutOrganizationInput>;
  sale_field_options?: InputMaybe<SaleFieldOptionUpdateManyWithoutOrganizationInput>;
  sale_notification_email_list?: InputMaybe<OrganizationUpdatesale_Notification_Email_ListInput>;
  SaleConfig?: InputMaybe<SaleConfigUpdateOneWithoutOrganizationInput>;
  SaleConfigPage?: InputMaybe<SaleConfigPageUpdateManyWithoutOrganizationInput>;
  SaleConfigSection?: InputMaybe<SaleConfigSectionUpdateManyWithoutOrganizationInput>;
  SaleCycles?: InputMaybe<SaleCycleUpdateManyWithoutOrganizationInput>;
  SaleItems?: InputMaybe<SaleItemUpdateManyWithoutOrganizationInput>;
  sales?: InputMaybe<SaleUpdateManyWithoutOrganizationInput>;
  salesforce_domain?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  salesforce_last_sync_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  SalesForceColumn?: InputMaybe<SalesForceColumnUpdateManyWithoutOrganizationInput>;
  salesForceData?: InputMaybe<SalesForceDataUpdateOneWithoutOrganizationInput>;
  ScheduleItems?: InputMaybe<ScheduleItemUpdateManyWithoutOrganizationInput>;
  self_sourcing_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  send_error_log_email?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  send_lead_routing_email?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sendgrid_email_domain?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sendgrid_email_domain_id?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  sequence?: InputMaybe<SequenceUpdateManyWithoutOrganzationInput>;
  SequenceCriteriaConditions?: InputMaybe<SequenceCriteriaConditionUpdateManyWithoutOrganizationInput>;
  SequenceDashboardViews?: InputMaybe<SequenceDashboardViewUpdateManyWithoutOrganizationInput>;
  SequenceEntryCriterias?: InputMaybe<SequenceEntryCriteriaUpdateManyWithoutOrganizationInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutOrganizationInput>;
  SequenceSteps?: InputMaybe<SequenceStepUpdateManyWithoutOrganizationInput>;
  set_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
  short_resting?: InputMaybe<IntFieldUpdateOperationsInput>;
  show_calendar_options?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_contract_duration_sales?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_lead_source_to_rep?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_nocontact_button?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_payment_terms_sales?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_rep_all_leads?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_rep_my_leads?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_system_view?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sites?: InputMaybe<SiteUpdateManyWithoutOrganizationInput>;
  sms_opt_in_dialog?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SMSItems?: InputMaybe<SmsItemUpdateManyWithoutOrganizationInput>;
  SnoozeTrackings?: InputMaybe<SnoozeTrackingUpdateManyWithoutOrganizationInput>;
  StepActionLogs?: InputMaybe<StepActionLogUpdateManyWithoutOrganizationInput>;
  StepActions?: InputMaybe<StepActionUpdateManyWithoutOrganizationInput>;
  StepConditions?: InputMaybe<StepConditionUpdateManyWithoutOrganizationInput>;
  teams?: InputMaybe<TeamUpdateManyWithoutOrganizationInput>;
  Template?: InputMaybe<TemplateUpdateManyWithoutOrganizationInput>;
  TemplateVersions?: InputMaybe<TemplateVersionUpdateManyWithoutOrganizationInput>;
  timekit_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timezone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transfer_timeout?: InputMaybe<IntFieldUpdateOperationsInput>;
  TransferAttempts?: InputMaybe<TransferAttemptUpdateManyWithoutOrganizationInput>;
  twilio_numbers?: InputMaybe<TwilioNumberUpdateManyWithoutOrganizationInput>;
  TwilioFeedbacks?: InputMaybe<TwilioFeedbackUpdateManyWithoutOrganizationInput>;
  TwilioInfo?: InputMaybe<TwilioInfoUpdateOneWithoutOrganzationInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  use_revenue_planning?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  UserCustomFieldFilters?: InputMaybe<UserCustomFieldFilterUpdateManyWithoutOrganizationInput>;
  users?: InputMaybe<UserUpdateManyWithoutOrganizationInput>;
  win_rep_day_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
};

export type OrganizationUpdateWithoutLiveUserStatusInput = {
  ActivityMonitorMultiplier?: InputMaybe<ActivityMonitorMultiplierUpdateManyWithoutOrganizationInput>;
  algoFactor?: InputMaybe<AlgoFactorUpdateManyWithoutOrganizationInput>;
  allow_calendar_invite?: InputMaybe<BoolFieldUpdateOperationsInput>;
  allow_remove_step_action?: InputMaybe<BoolFieldUpdateOperationsInput>;
  allow_snooze_step_action?: InputMaybe<BoolFieldUpdateOperationsInput>;
  always_record?: InputMaybe<BoolFieldUpdateOperationsInput>;
  arv_initial_held_demos_generated_by_sdr_weight?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  AssociationHistories?: InputMaybe<AssociationHistoryUpdateManyWithoutOrganizationInput>;
  auto_call_interval?: InputMaybe<IntFieldUpdateOperationsInput>;
  auto_dial?: InputMaybe<BoolFieldUpdateOperationsInput>;
  automated_campaigns?: InputMaybe<AutomatedCampaignUpdateManyWithoutOrganizationInput>;
  automatic_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  AutoTransferSelections?: InputMaybe<AutoTransferSelectionUpdateManyWithoutOrganizationInput>;
  Availablities?: InputMaybe<AvailabilityUpdateManyWithoutOrganizationInput>;
  AverageOrderValue?: InputMaybe<AverageOrderValueUpdateManyWithoutOrganizationInput>;
  best_action_ever_multiplier?: InputMaybe<FloatFieldUpdateOperationsInput>;
  best_metrics?: InputMaybe<BestMetricRecordUpdateManyWithoutOrganizationInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptUpdateManyWithoutOrganizationInput>;
  callMeNows?: InputMaybe<CallMeNowUpdateManyWithoutOrganizationInput>;
  CallMultiplier?: InputMaybe<CallMultiplierUpdateManyWithoutOrganizationInput>;
  CallSharings?: InputMaybe<CallSharingUpdateManyWithoutOrganizationInput>;
  can_edit_sdr_transfer_rules?: InputMaybe<BoolFieldUpdateOperationsInput>;
  claim_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  concession?: InputMaybe<ConcessionUpdateManyWithoutOrganizationInput>;
  conference_region?: InputMaybe<EnumConferenceRegionFieldUpdateOperationsInput>;
  Conferences?: InputMaybe<ConferenceUpdateManyWithoutOrganizationInput>;
  ContactRequests?: InputMaybe<ContactRequestUpdateManyWithoutOrganizationInput>;
  Contacts?: InputMaybe<ContactUpdateManyWithoutOrganizationInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  custom_fields?: InputMaybe<CustomFieldUpdateManyWithoutOrganizationInput>;
  CustomObject?: InputMaybe<CustomObjectUpdateManyWithoutOrganizationInput>;
  CustomObjectField?: InputMaybe<CustomObjectFieldUpdateManyWithoutOrganizationInput>;
  CustomObjectFieldForeignKey?: InputMaybe<CustomObjectFieldForeignKeyUpdateManyWithoutOrganizationInput>;
  CustomObjectRecord?: InputMaybe<CustomObjectRecordUpdateManyWithoutOrganizationInput>;
  CustomObjectRow?: InputMaybe<CustomObjectRowUpdateManyWithoutOrganizationInput>;
  CustomQueueItems?: InputMaybe<CustomQueueItemUpdateManyWithoutOrganizationInput>;
  DashboardColumnOptions?: InputMaybe<DashboardColumnOptionUpdateManyWithoutOrganizationInput>;
  DashboardLeadFilters?: InputMaybe<DashboardLeadFilterUpdateManyWithoutOrganizationInput>;
  DashboardRepFilters?: InputMaybe<DashboardRepFilterUpdateManyWithoutOrganizationInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewUpdateManyWithoutOrganizationInput>;
  day_to_close?: InputMaybe<IntFieldUpdateOperationsInput>;
  default_demo_time_duration?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  demo_held_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_not_held_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_not_held_call_back_short_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_prep_time_minutes?: InputMaybe<IntFieldUpdateOperationsInput>;
  dev_account?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  dial_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  DispositionMultiplier?: InputMaybe<DispositionMultiplierUpdateManyWithoutOrganizationInput>;
  dispositions?: InputMaybe<DispositionUpdateManyWithoutOrganizationInput>;
  double_booking?: InputMaybe<BoolFieldUpdateOperationsInput>;
  dynamic_date_operators?: InputMaybe<BoolFieldUpdateOperationsInput>;
  edge_servers?: InputMaybe<OrganizationUpdateedge_ServersInput>;
  email_conflict_notification_email_list?: InputMaybe<OrganizationUpdateemail_Conflict_Notification_Email_ListInput>;
  EmailItems?: InputMaybe<EmailItemUpdateManyWithoutOrganizationInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordUpdateManyWithoutOrganizationInput>;
  EmailThreads?: InputMaybe<EmailThreadUpdateManyWithoutOrganizationInput>;
  enable_modified_metrics?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  enable_pay_period_view?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  enable_time_constraint?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  error_log_email_address?: InputMaybe<OrganizationUpdateerror_Log_Email_AddressInput>;
  estimated_average_num_of_weekly_overbooked_demo_slots?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  FolderPermissions?: InputMaybe<FolderPermissionUpdateManyWithoutOrganizationInput>;
  Folders?: InputMaybe<FolderUpdateManyWithoutOrganizationInput>;
  FutureBookingSetting?: InputMaybe<FutureBookingSettingUpdateOneWithoutOrganizationInput>;
  GlobalTransferRule?: InputMaybe<GlobalTransferRuleUpdateManyWithoutOrganizationInput>;
  held_demos_per_cc_connect?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  helpful_links?: InputMaybe<OrganizationUpdatehelpful_LinksInput>;
  HingePointMultiplier?: InputMaybe<HingePointMultiplierUpdateManyWithoutOrganizationInput>;
  hold_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
  hubspot_credential?: InputMaybe<HubSpotCredentialUpdateOneWithoutOrganizationInput>;
  hubspot_last_sync_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  hubspot_white_list?: InputMaybe<HubSpotWhitelistUpdateManyWithoutOrganizationInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inbound_close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_dm_connect_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_lt_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_revenue_value_of_sale?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_scheduled_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_set_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventUpdateManyWithoutOrganizationInput>;
  InboundConciergeWidget?: InputMaybe<InboundConciergeWidgetUpdateManyWithoutOrganizationInput>;
  industry_options?: InputMaybe<IndustryOptionUpdateManyWithoutOrganizationInput>;
  initial_sdr_sets_reps_day?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  integration_error_logs?: InputMaybe<IntegrationErrorLogUpdateManyWithoutOrganizationInput>;
  integration_mappings?: InputMaybe<IntegrationMappingUpdateManyWithoutOrganizationInput>;
  IntegrationMappingCustomObject?: InputMaybe<IntegrationMappingCustomObjectUpdateManyWithoutOrganizationInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogUpdateManyWithoutOrganizationInput>;
  IntegrationReadOnlyObject?: InputMaybe<IntegrationReadOnlyObjectUpdateManyWithoutOrganizationInput>;
  international?: InputMaybe<BoolFieldUpdateOperationsInput>;
  is_email_domain_verified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  is_salesforce_sandbox?: InputMaybe<BoolFieldUpdateOperationsInput>;
  last_lead_value_compute_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutOrganizationInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryUpdateManyWithoutOrganizationInput>;
  lead_intent?: InputMaybe<LeadIntentUpdateManyWithoutOrganizationInput>;
  lead_release_same_rep_delay_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  lead_routing_email_list?: InputMaybe<OrganizationUpdatelead_Routing_Email_ListInput>;
  lead_routing_rules?: InputMaybe<RuleUpdateManyWithoutOrganizationInput>;
  LeadCustomObjectRowAssociation?: InputMaybe<LeadCustomObjectRowAssociationUpdateManyWithoutOrganizationInput>;
  LeadFieldMultiplier?: InputMaybe<LeadFieldMultiplierUpdateManyWithoutOrganizationInput>;
  LeadFieldMultiplierCustom?: InputMaybe<LeadFieldMultiplierCustomUpdateManyWithoutOrganizationInput>;
  leads?: InputMaybe<LeadUpdateManyWithoutOrganizationInput>;
  leadsource_options?: InputMaybe<LeadSourceOptionUpdateManyWithoutOrganizationInput>;
  LeadValue?: InputMaybe<LeadValueUpdateManyWithoutOrganizationInput>;
  LifeTimeValueMultiplier?: InputMaybe<LifeTimeValueMultiplierUpdateManyWithoutOrganizationInput>;
  LiveTransferSetting?: InputMaybe<LiveTransferSettingUpdateOneWithoutOrganizationInput>;
  ltv_multiplier_custom_fields?: InputMaybe<LifeTimeValueMultiplierCustomUpdateManyWithoutOrganizationInput>;
  made_contact_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  make_sale_address_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_business_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_city_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_country_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_first_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_industry_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_last_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_lead_source_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_primary_email_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_primary_phone_number_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_state_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_sub_industry_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_zip_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  maxium_day_lag_set_to_schedule?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  mrr?: InputMaybe<IntFieldUpdateOperationsInput>;
  mrr_label?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  mrr_required_on_sale?: InputMaybe<BoolFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  NoteItems?: InputMaybe<NoteItemUpdateManyWithoutOrganizationInput>;
  Notifications?: InputMaybe<NotificationUpdateManyWithoutOrganizationInput>;
  onboardComplete?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  Opportunity?: InputMaybe<SfOpportunityUpdateManyWithoutOrganizationInput>;
  organization_holidays?: InputMaybe<OrganizationHolidayUpdateManyWithoutOrganizationInput>;
  organization_hours?: InputMaybe<OrganizationHourUpdateManyWithoutOrganizationInput>;
  OrganizationPaymentSetting?: InputMaybe<OrganizationPaymentSettingsUpdateOneWithoutOrganizationInput>;
  OrganizationPaymentSettings?: InputMaybe<OrganizationPaymentSettingsUpdateOneWithoutOrganizationsInput>;
  orgCalendarInviteMetadata?: InputMaybe<OrgCalendarInviteMetadataUpdateManyWithoutOrganizationInput>;
  OrgDefaultPipelinePerecentValue?: InputMaybe<OrgDefaultPipelinePerecentValueUpdateOneWithoutOrganizationInput>;
  OrgHubSpotSync?: InputMaybe<OrgHubSpotSyncUpdateOneWithoutOrganizationInput>;
  OrgNoEventDate?: InputMaybe<OrgNoEventDateUpdateManyWithoutOrganizationInput>;
  OrgSalesforceSync?: InputMaybe<OrgSalesforceSyncUpdateOneWithoutOrganizationInput>;
  OrgTimeslotAvailability?: InputMaybe<OrgTimeslotAvailabilityUpdateManyWithoutOrganizationInput>;
  outbound_close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_dm_connect_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_lt_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_revenue_value_of_sale?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_scheduled_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_set_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  PandadocData?: InputMaybe<PandadocDataUpdateOneWithoutOrganizationInput>;
  PandaDocTemplate?: InputMaybe<PandaDocTemplateUpdateManyWithoutOrganizationInput>;
  participant_jitter_buffer_size?: InputMaybe<EnumJitterBufferSizeFieldUpdateOperationsInput>;
  preferred_goal_setting?: InputMaybe<NullableEnumGoalsettingFieldUpdateOperationsInput>;
  prepayment?: InputMaybe<BoolFieldUpdateOperationsInput>;
  ProductChangeLog?: InputMaybe<ProductChangeLogUpdateManyWithoutOrganizationInput>;
  products?: InputMaybe<ProductUpdateManyWithoutOrganizationInput>;
  re_cold_call_hour_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  recordingStatus?: InputMaybe<EnumRecordingStatusFieldUpdateOperationsInput>;
  release_lead_resting?: InputMaybe<IntFieldUpdateOperationsInput>;
  rep_claim_limit?: InputMaybe<IntFieldUpdateOperationsInput>;
  rep_lead_allotment?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  rep_level_call_transfer?: InputMaybe<BoolFieldUpdateOperationsInput>;
  reps_can_bulk_unassign?: InputMaybe<BoolFieldUpdateOperationsInput>;
  reps_can_view_all_notes?: InputMaybe<BoolFieldUpdateOperationsInput>;
  require_sale_notes?: InputMaybe<BoolFieldUpdateOperationsInput>;
  resting_after_skipped_CC?: InputMaybe<IntFieldUpdateOperationsInput>;
  resting_period?: InputMaybe<IntFieldUpdateOperationsInput>;
  resting_period_after_unsuccessful_cold_call?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  resting_period_after_unsuccessful_demo?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  revenue_plan?: InputMaybe<RevenuePlanItemUpdateManyWithoutOrganizationInput>;
  Routings?: InputMaybe<RoutingUpdateManyWithoutOrganizationInput>;
  sale_field_options?: InputMaybe<SaleFieldOptionUpdateManyWithoutOrganizationInput>;
  sale_notification_email_list?: InputMaybe<OrganizationUpdatesale_Notification_Email_ListInput>;
  SaleConfig?: InputMaybe<SaleConfigUpdateOneWithoutOrganizationInput>;
  SaleConfigPage?: InputMaybe<SaleConfigPageUpdateManyWithoutOrganizationInput>;
  SaleConfigSection?: InputMaybe<SaleConfigSectionUpdateManyWithoutOrganizationInput>;
  SaleCycles?: InputMaybe<SaleCycleUpdateManyWithoutOrganizationInput>;
  SaleItems?: InputMaybe<SaleItemUpdateManyWithoutOrganizationInput>;
  sales?: InputMaybe<SaleUpdateManyWithoutOrganizationInput>;
  salesforce_domain?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  salesforce_last_sync_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  SalesForceColumn?: InputMaybe<SalesForceColumnUpdateManyWithoutOrganizationInput>;
  salesForceData?: InputMaybe<SalesForceDataUpdateOneWithoutOrganizationInput>;
  ScheduleItems?: InputMaybe<ScheduleItemUpdateManyWithoutOrganizationInput>;
  self_sourcing_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  send_error_log_email?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  send_lead_routing_email?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sendgrid_email_domain?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sendgrid_email_domain_id?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  sequence?: InputMaybe<SequenceUpdateManyWithoutOrganzationInput>;
  SequenceCriteriaConditions?: InputMaybe<SequenceCriteriaConditionUpdateManyWithoutOrganizationInput>;
  SequenceDashboardViews?: InputMaybe<SequenceDashboardViewUpdateManyWithoutOrganizationInput>;
  SequenceEntryCriterias?: InputMaybe<SequenceEntryCriteriaUpdateManyWithoutOrganizationInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutOrganizationInput>;
  SequenceSteps?: InputMaybe<SequenceStepUpdateManyWithoutOrganizationInput>;
  set_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
  short_resting?: InputMaybe<IntFieldUpdateOperationsInput>;
  show_calendar_options?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_contract_duration_sales?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_lead_source_to_rep?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_nocontact_button?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_payment_terms_sales?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_rep_all_leads?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_rep_my_leads?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_system_view?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sites?: InputMaybe<SiteUpdateManyWithoutOrganizationInput>;
  sms_opt_in_dialog?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SMSItems?: InputMaybe<SmsItemUpdateManyWithoutOrganizationInput>;
  SnoozeTrackings?: InputMaybe<SnoozeTrackingUpdateManyWithoutOrganizationInput>;
  StepActionLogs?: InputMaybe<StepActionLogUpdateManyWithoutOrganizationInput>;
  StepActions?: InputMaybe<StepActionUpdateManyWithoutOrganizationInput>;
  StepConditions?: InputMaybe<StepConditionUpdateManyWithoutOrganizationInput>;
  teams?: InputMaybe<TeamUpdateManyWithoutOrganizationInput>;
  Template?: InputMaybe<TemplateUpdateManyWithoutOrganizationInput>;
  TemplateVersions?: InputMaybe<TemplateVersionUpdateManyWithoutOrganizationInput>;
  timekit_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timezone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transfer_timeout?: InputMaybe<IntFieldUpdateOperationsInput>;
  TransferAttempts?: InputMaybe<TransferAttemptUpdateManyWithoutOrganizationInput>;
  twilio_numbers?: InputMaybe<TwilioNumberUpdateManyWithoutOrganizationInput>;
  TwilioFeedbacks?: InputMaybe<TwilioFeedbackUpdateManyWithoutOrganizationInput>;
  TwilioInfo?: InputMaybe<TwilioInfoUpdateOneWithoutOrganzationInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  use_revenue_planning?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  UserCustomFieldFilters?: InputMaybe<UserCustomFieldFilterUpdateManyWithoutOrganizationInput>;
  users?: InputMaybe<UserUpdateManyWithoutOrganizationInput>;
  win_rep_day_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
};

export type OrganizationUpdateWithoutNoteItemsInput = {
  ActivityMonitorMultiplier?: InputMaybe<ActivityMonitorMultiplierUpdateManyWithoutOrganizationInput>;
  algoFactor?: InputMaybe<AlgoFactorUpdateManyWithoutOrganizationInput>;
  allow_calendar_invite?: InputMaybe<BoolFieldUpdateOperationsInput>;
  allow_remove_step_action?: InputMaybe<BoolFieldUpdateOperationsInput>;
  allow_snooze_step_action?: InputMaybe<BoolFieldUpdateOperationsInput>;
  always_record?: InputMaybe<BoolFieldUpdateOperationsInput>;
  arv_initial_held_demos_generated_by_sdr_weight?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  AssociationHistories?: InputMaybe<AssociationHistoryUpdateManyWithoutOrganizationInput>;
  auto_call_interval?: InputMaybe<IntFieldUpdateOperationsInput>;
  auto_dial?: InputMaybe<BoolFieldUpdateOperationsInput>;
  automated_campaigns?: InputMaybe<AutomatedCampaignUpdateManyWithoutOrganizationInput>;
  automatic_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  AutoTransferSelections?: InputMaybe<AutoTransferSelectionUpdateManyWithoutOrganizationInput>;
  Availablities?: InputMaybe<AvailabilityUpdateManyWithoutOrganizationInput>;
  AverageOrderValue?: InputMaybe<AverageOrderValueUpdateManyWithoutOrganizationInput>;
  best_action_ever_multiplier?: InputMaybe<FloatFieldUpdateOperationsInput>;
  best_metrics?: InputMaybe<BestMetricRecordUpdateManyWithoutOrganizationInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptUpdateManyWithoutOrganizationInput>;
  callMeNows?: InputMaybe<CallMeNowUpdateManyWithoutOrganizationInput>;
  CallMultiplier?: InputMaybe<CallMultiplierUpdateManyWithoutOrganizationInput>;
  CallSharings?: InputMaybe<CallSharingUpdateManyWithoutOrganizationInput>;
  can_edit_sdr_transfer_rules?: InputMaybe<BoolFieldUpdateOperationsInput>;
  claim_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  concession?: InputMaybe<ConcessionUpdateManyWithoutOrganizationInput>;
  conference_region?: InputMaybe<EnumConferenceRegionFieldUpdateOperationsInput>;
  Conferences?: InputMaybe<ConferenceUpdateManyWithoutOrganizationInput>;
  ContactRequests?: InputMaybe<ContactRequestUpdateManyWithoutOrganizationInput>;
  Contacts?: InputMaybe<ContactUpdateManyWithoutOrganizationInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  custom_fields?: InputMaybe<CustomFieldUpdateManyWithoutOrganizationInput>;
  CustomObject?: InputMaybe<CustomObjectUpdateManyWithoutOrganizationInput>;
  CustomObjectField?: InputMaybe<CustomObjectFieldUpdateManyWithoutOrganizationInput>;
  CustomObjectFieldForeignKey?: InputMaybe<CustomObjectFieldForeignKeyUpdateManyWithoutOrganizationInput>;
  CustomObjectRecord?: InputMaybe<CustomObjectRecordUpdateManyWithoutOrganizationInput>;
  CustomObjectRow?: InputMaybe<CustomObjectRowUpdateManyWithoutOrganizationInput>;
  CustomQueueItems?: InputMaybe<CustomQueueItemUpdateManyWithoutOrganizationInput>;
  DashboardColumnOptions?: InputMaybe<DashboardColumnOptionUpdateManyWithoutOrganizationInput>;
  DashboardLeadFilters?: InputMaybe<DashboardLeadFilterUpdateManyWithoutOrganizationInput>;
  DashboardRepFilters?: InputMaybe<DashboardRepFilterUpdateManyWithoutOrganizationInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewUpdateManyWithoutOrganizationInput>;
  day_to_close?: InputMaybe<IntFieldUpdateOperationsInput>;
  default_demo_time_duration?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  demo_held_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_not_held_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_not_held_call_back_short_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_prep_time_minutes?: InputMaybe<IntFieldUpdateOperationsInput>;
  dev_account?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  dial_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  DispositionMultiplier?: InputMaybe<DispositionMultiplierUpdateManyWithoutOrganizationInput>;
  dispositions?: InputMaybe<DispositionUpdateManyWithoutOrganizationInput>;
  double_booking?: InputMaybe<BoolFieldUpdateOperationsInput>;
  dynamic_date_operators?: InputMaybe<BoolFieldUpdateOperationsInput>;
  edge_servers?: InputMaybe<OrganizationUpdateedge_ServersInput>;
  email_conflict_notification_email_list?: InputMaybe<OrganizationUpdateemail_Conflict_Notification_Email_ListInput>;
  EmailItems?: InputMaybe<EmailItemUpdateManyWithoutOrganizationInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordUpdateManyWithoutOrganizationInput>;
  EmailThreads?: InputMaybe<EmailThreadUpdateManyWithoutOrganizationInput>;
  enable_modified_metrics?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  enable_pay_period_view?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  enable_time_constraint?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  error_log_email_address?: InputMaybe<OrganizationUpdateerror_Log_Email_AddressInput>;
  estimated_average_num_of_weekly_overbooked_demo_slots?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  FolderPermissions?: InputMaybe<FolderPermissionUpdateManyWithoutOrganizationInput>;
  Folders?: InputMaybe<FolderUpdateManyWithoutOrganizationInput>;
  FutureBookingSetting?: InputMaybe<FutureBookingSettingUpdateOneWithoutOrganizationInput>;
  GlobalTransferRule?: InputMaybe<GlobalTransferRuleUpdateManyWithoutOrganizationInput>;
  held_demos_per_cc_connect?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  helpful_links?: InputMaybe<OrganizationUpdatehelpful_LinksInput>;
  HingePointMultiplier?: InputMaybe<HingePointMultiplierUpdateManyWithoutOrganizationInput>;
  hold_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
  hubspot_credential?: InputMaybe<HubSpotCredentialUpdateOneWithoutOrganizationInput>;
  hubspot_last_sync_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  hubspot_white_list?: InputMaybe<HubSpotWhitelistUpdateManyWithoutOrganizationInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inbound_close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_dm_connect_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_lt_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_revenue_value_of_sale?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_scheduled_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_set_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventUpdateManyWithoutOrganizationInput>;
  InboundConciergeWidget?: InputMaybe<InboundConciergeWidgetUpdateManyWithoutOrganizationInput>;
  industry_options?: InputMaybe<IndustryOptionUpdateManyWithoutOrganizationInput>;
  initial_sdr_sets_reps_day?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  integration_error_logs?: InputMaybe<IntegrationErrorLogUpdateManyWithoutOrganizationInput>;
  integration_mappings?: InputMaybe<IntegrationMappingUpdateManyWithoutOrganizationInput>;
  IntegrationMappingCustomObject?: InputMaybe<IntegrationMappingCustomObjectUpdateManyWithoutOrganizationInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogUpdateManyWithoutOrganizationInput>;
  IntegrationReadOnlyObject?: InputMaybe<IntegrationReadOnlyObjectUpdateManyWithoutOrganizationInput>;
  international?: InputMaybe<BoolFieldUpdateOperationsInput>;
  is_email_domain_verified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  is_salesforce_sandbox?: InputMaybe<BoolFieldUpdateOperationsInput>;
  last_lead_value_compute_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutOrganizationInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryUpdateManyWithoutOrganizationInput>;
  lead_intent?: InputMaybe<LeadIntentUpdateManyWithoutOrganizationInput>;
  lead_release_same_rep_delay_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  lead_routing_email_list?: InputMaybe<OrganizationUpdatelead_Routing_Email_ListInput>;
  lead_routing_rules?: InputMaybe<RuleUpdateManyWithoutOrganizationInput>;
  LeadCustomObjectRowAssociation?: InputMaybe<LeadCustomObjectRowAssociationUpdateManyWithoutOrganizationInput>;
  LeadFieldMultiplier?: InputMaybe<LeadFieldMultiplierUpdateManyWithoutOrganizationInput>;
  LeadFieldMultiplierCustom?: InputMaybe<LeadFieldMultiplierCustomUpdateManyWithoutOrganizationInput>;
  leads?: InputMaybe<LeadUpdateManyWithoutOrganizationInput>;
  leadsource_options?: InputMaybe<LeadSourceOptionUpdateManyWithoutOrganizationInput>;
  LeadValue?: InputMaybe<LeadValueUpdateManyWithoutOrganizationInput>;
  LifeTimeValueMultiplier?: InputMaybe<LifeTimeValueMultiplierUpdateManyWithoutOrganizationInput>;
  LiveTransferSetting?: InputMaybe<LiveTransferSettingUpdateOneWithoutOrganizationInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusUpdateManyWithoutOrganizationInput>;
  ltv_multiplier_custom_fields?: InputMaybe<LifeTimeValueMultiplierCustomUpdateManyWithoutOrganizationInput>;
  made_contact_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  make_sale_address_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_business_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_city_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_country_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_first_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_industry_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_last_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_lead_source_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_primary_email_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_primary_phone_number_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_state_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_sub_industry_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_zip_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  maxium_day_lag_set_to_schedule?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  mrr?: InputMaybe<IntFieldUpdateOperationsInput>;
  mrr_label?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  mrr_required_on_sale?: InputMaybe<BoolFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  Notifications?: InputMaybe<NotificationUpdateManyWithoutOrganizationInput>;
  onboardComplete?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  Opportunity?: InputMaybe<SfOpportunityUpdateManyWithoutOrganizationInput>;
  organization_holidays?: InputMaybe<OrganizationHolidayUpdateManyWithoutOrganizationInput>;
  organization_hours?: InputMaybe<OrganizationHourUpdateManyWithoutOrganizationInput>;
  OrganizationPaymentSetting?: InputMaybe<OrganizationPaymentSettingsUpdateOneWithoutOrganizationInput>;
  OrganizationPaymentSettings?: InputMaybe<OrganizationPaymentSettingsUpdateOneWithoutOrganizationsInput>;
  orgCalendarInviteMetadata?: InputMaybe<OrgCalendarInviteMetadataUpdateManyWithoutOrganizationInput>;
  OrgDefaultPipelinePerecentValue?: InputMaybe<OrgDefaultPipelinePerecentValueUpdateOneWithoutOrganizationInput>;
  OrgHubSpotSync?: InputMaybe<OrgHubSpotSyncUpdateOneWithoutOrganizationInput>;
  OrgNoEventDate?: InputMaybe<OrgNoEventDateUpdateManyWithoutOrganizationInput>;
  OrgSalesforceSync?: InputMaybe<OrgSalesforceSyncUpdateOneWithoutOrganizationInput>;
  OrgTimeslotAvailability?: InputMaybe<OrgTimeslotAvailabilityUpdateManyWithoutOrganizationInput>;
  outbound_close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_dm_connect_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_lt_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_revenue_value_of_sale?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_scheduled_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_set_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  PandadocData?: InputMaybe<PandadocDataUpdateOneWithoutOrganizationInput>;
  PandaDocTemplate?: InputMaybe<PandaDocTemplateUpdateManyWithoutOrganizationInput>;
  participant_jitter_buffer_size?: InputMaybe<EnumJitterBufferSizeFieldUpdateOperationsInput>;
  preferred_goal_setting?: InputMaybe<NullableEnumGoalsettingFieldUpdateOperationsInput>;
  prepayment?: InputMaybe<BoolFieldUpdateOperationsInput>;
  ProductChangeLog?: InputMaybe<ProductChangeLogUpdateManyWithoutOrganizationInput>;
  products?: InputMaybe<ProductUpdateManyWithoutOrganizationInput>;
  re_cold_call_hour_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  recordingStatus?: InputMaybe<EnumRecordingStatusFieldUpdateOperationsInput>;
  release_lead_resting?: InputMaybe<IntFieldUpdateOperationsInput>;
  rep_claim_limit?: InputMaybe<IntFieldUpdateOperationsInput>;
  rep_lead_allotment?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  rep_level_call_transfer?: InputMaybe<BoolFieldUpdateOperationsInput>;
  reps_can_bulk_unassign?: InputMaybe<BoolFieldUpdateOperationsInput>;
  reps_can_view_all_notes?: InputMaybe<BoolFieldUpdateOperationsInput>;
  require_sale_notes?: InputMaybe<BoolFieldUpdateOperationsInput>;
  resting_after_skipped_CC?: InputMaybe<IntFieldUpdateOperationsInput>;
  resting_period?: InputMaybe<IntFieldUpdateOperationsInput>;
  resting_period_after_unsuccessful_cold_call?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  resting_period_after_unsuccessful_demo?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  revenue_plan?: InputMaybe<RevenuePlanItemUpdateManyWithoutOrganizationInput>;
  Routings?: InputMaybe<RoutingUpdateManyWithoutOrganizationInput>;
  sale_field_options?: InputMaybe<SaleFieldOptionUpdateManyWithoutOrganizationInput>;
  sale_notification_email_list?: InputMaybe<OrganizationUpdatesale_Notification_Email_ListInput>;
  SaleConfig?: InputMaybe<SaleConfigUpdateOneWithoutOrganizationInput>;
  SaleConfigPage?: InputMaybe<SaleConfigPageUpdateManyWithoutOrganizationInput>;
  SaleConfigSection?: InputMaybe<SaleConfigSectionUpdateManyWithoutOrganizationInput>;
  SaleCycles?: InputMaybe<SaleCycleUpdateManyWithoutOrganizationInput>;
  SaleItems?: InputMaybe<SaleItemUpdateManyWithoutOrganizationInput>;
  sales?: InputMaybe<SaleUpdateManyWithoutOrganizationInput>;
  salesforce_domain?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  salesforce_last_sync_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  SalesForceColumn?: InputMaybe<SalesForceColumnUpdateManyWithoutOrganizationInput>;
  salesForceData?: InputMaybe<SalesForceDataUpdateOneWithoutOrganizationInput>;
  ScheduleItems?: InputMaybe<ScheduleItemUpdateManyWithoutOrganizationInput>;
  self_sourcing_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  send_error_log_email?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  send_lead_routing_email?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sendgrid_email_domain?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sendgrid_email_domain_id?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  sequence?: InputMaybe<SequenceUpdateManyWithoutOrganzationInput>;
  SequenceCriteriaConditions?: InputMaybe<SequenceCriteriaConditionUpdateManyWithoutOrganizationInput>;
  SequenceDashboardViews?: InputMaybe<SequenceDashboardViewUpdateManyWithoutOrganizationInput>;
  SequenceEntryCriterias?: InputMaybe<SequenceEntryCriteriaUpdateManyWithoutOrganizationInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutOrganizationInput>;
  SequenceSteps?: InputMaybe<SequenceStepUpdateManyWithoutOrganizationInput>;
  set_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
  short_resting?: InputMaybe<IntFieldUpdateOperationsInput>;
  show_calendar_options?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_contract_duration_sales?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_lead_source_to_rep?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_nocontact_button?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_payment_terms_sales?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_rep_all_leads?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_rep_my_leads?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_system_view?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sites?: InputMaybe<SiteUpdateManyWithoutOrganizationInput>;
  sms_opt_in_dialog?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SMSItems?: InputMaybe<SmsItemUpdateManyWithoutOrganizationInput>;
  SnoozeTrackings?: InputMaybe<SnoozeTrackingUpdateManyWithoutOrganizationInput>;
  StepActionLogs?: InputMaybe<StepActionLogUpdateManyWithoutOrganizationInput>;
  StepActions?: InputMaybe<StepActionUpdateManyWithoutOrganizationInput>;
  StepConditions?: InputMaybe<StepConditionUpdateManyWithoutOrganizationInput>;
  teams?: InputMaybe<TeamUpdateManyWithoutOrganizationInput>;
  Template?: InputMaybe<TemplateUpdateManyWithoutOrganizationInput>;
  TemplateVersions?: InputMaybe<TemplateVersionUpdateManyWithoutOrganizationInput>;
  timekit_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timezone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transfer_timeout?: InputMaybe<IntFieldUpdateOperationsInput>;
  TransferAttempts?: InputMaybe<TransferAttemptUpdateManyWithoutOrganizationInput>;
  twilio_numbers?: InputMaybe<TwilioNumberUpdateManyWithoutOrganizationInput>;
  TwilioFeedbacks?: InputMaybe<TwilioFeedbackUpdateManyWithoutOrganizationInput>;
  TwilioInfo?: InputMaybe<TwilioInfoUpdateOneWithoutOrganzationInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  use_revenue_planning?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  UserCustomFieldFilters?: InputMaybe<UserCustomFieldFilterUpdateManyWithoutOrganizationInput>;
  users?: InputMaybe<UserUpdateManyWithoutOrganizationInput>;
  win_rep_day_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
};

export type OrganizationUpdateWithoutNotificationsInput = {
  ActivityMonitorMultiplier?: InputMaybe<ActivityMonitorMultiplierUpdateManyWithoutOrganizationInput>;
  algoFactor?: InputMaybe<AlgoFactorUpdateManyWithoutOrganizationInput>;
  allow_calendar_invite?: InputMaybe<BoolFieldUpdateOperationsInput>;
  allow_remove_step_action?: InputMaybe<BoolFieldUpdateOperationsInput>;
  allow_snooze_step_action?: InputMaybe<BoolFieldUpdateOperationsInput>;
  always_record?: InputMaybe<BoolFieldUpdateOperationsInput>;
  arv_initial_held_demos_generated_by_sdr_weight?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  AssociationHistories?: InputMaybe<AssociationHistoryUpdateManyWithoutOrganizationInput>;
  auto_call_interval?: InputMaybe<IntFieldUpdateOperationsInput>;
  auto_dial?: InputMaybe<BoolFieldUpdateOperationsInput>;
  automated_campaigns?: InputMaybe<AutomatedCampaignUpdateManyWithoutOrganizationInput>;
  automatic_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  AutoTransferSelections?: InputMaybe<AutoTransferSelectionUpdateManyWithoutOrganizationInput>;
  Availablities?: InputMaybe<AvailabilityUpdateManyWithoutOrganizationInput>;
  AverageOrderValue?: InputMaybe<AverageOrderValueUpdateManyWithoutOrganizationInput>;
  best_action_ever_multiplier?: InputMaybe<FloatFieldUpdateOperationsInput>;
  best_metrics?: InputMaybe<BestMetricRecordUpdateManyWithoutOrganizationInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptUpdateManyWithoutOrganizationInput>;
  callMeNows?: InputMaybe<CallMeNowUpdateManyWithoutOrganizationInput>;
  CallMultiplier?: InputMaybe<CallMultiplierUpdateManyWithoutOrganizationInput>;
  CallSharings?: InputMaybe<CallSharingUpdateManyWithoutOrganizationInput>;
  can_edit_sdr_transfer_rules?: InputMaybe<BoolFieldUpdateOperationsInput>;
  claim_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  concession?: InputMaybe<ConcessionUpdateManyWithoutOrganizationInput>;
  conference_region?: InputMaybe<EnumConferenceRegionFieldUpdateOperationsInput>;
  Conferences?: InputMaybe<ConferenceUpdateManyWithoutOrganizationInput>;
  ContactRequests?: InputMaybe<ContactRequestUpdateManyWithoutOrganizationInput>;
  Contacts?: InputMaybe<ContactUpdateManyWithoutOrganizationInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  custom_fields?: InputMaybe<CustomFieldUpdateManyWithoutOrganizationInput>;
  CustomObject?: InputMaybe<CustomObjectUpdateManyWithoutOrganizationInput>;
  CustomObjectField?: InputMaybe<CustomObjectFieldUpdateManyWithoutOrganizationInput>;
  CustomObjectFieldForeignKey?: InputMaybe<CustomObjectFieldForeignKeyUpdateManyWithoutOrganizationInput>;
  CustomObjectRecord?: InputMaybe<CustomObjectRecordUpdateManyWithoutOrganizationInput>;
  CustomObjectRow?: InputMaybe<CustomObjectRowUpdateManyWithoutOrganizationInput>;
  CustomQueueItems?: InputMaybe<CustomQueueItemUpdateManyWithoutOrganizationInput>;
  DashboardColumnOptions?: InputMaybe<DashboardColumnOptionUpdateManyWithoutOrganizationInput>;
  DashboardLeadFilters?: InputMaybe<DashboardLeadFilterUpdateManyWithoutOrganizationInput>;
  DashboardRepFilters?: InputMaybe<DashboardRepFilterUpdateManyWithoutOrganizationInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewUpdateManyWithoutOrganizationInput>;
  day_to_close?: InputMaybe<IntFieldUpdateOperationsInput>;
  default_demo_time_duration?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  demo_held_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_not_held_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_not_held_call_back_short_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_prep_time_minutes?: InputMaybe<IntFieldUpdateOperationsInput>;
  dev_account?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  dial_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  DispositionMultiplier?: InputMaybe<DispositionMultiplierUpdateManyWithoutOrganizationInput>;
  dispositions?: InputMaybe<DispositionUpdateManyWithoutOrganizationInput>;
  double_booking?: InputMaybe<BoolFieldUpdateOperationsInput>;
  dynamic_date_operators?: InputMaybe<BoolFieldUpdateOperationsInput>;
  edge_servers?: InputMaybe<OrganizationUpdateedge_ServersInput>;
  email_conflict_notification_email_list?: InputMaybe<OrganizationUpdateemail_Conflict_Notification_Email_ListInput>;
  EmailItems?: InputMaybe<EmailItemUpdateManyWithoutOrganizationInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordUpdateManyWithoutOrganizationInput>;
  EmailThreads?: InputMaybe<EmailThreadUpdateManyWithoutOrganizationInput>;
  enable_modified_metrics?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  enable_pay_period_view?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  enable_time_constraint?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  error_log_email_address?: InputMaybe<OrganizationUpdateerror_Log_Email_AddressInput>;
  estimated_average_num_of_weekly_overbooked_demo_slots?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  FolderPermissions?: InputMaybe<FolderPermissionUpdateManyWithoutOrganizationInput>;
  Folders?: InputMaybe<FolderUpdateManyWithoutOrganizationInput>;
  FutureBookingSetting?: InputMaybe<FutureBookingSettingUpdateOneWithoutOrganizationInput>;
  GlobalTransferRule?: InputMaybe<GlobalTransferRuleUpdateManyWithoutOrganizationInput>;
  held_demos_per_cc_connect?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  helpful_links?: InputMaybe<OrganizationUpdatehelpful_LinksInput>;
  HingePointMultiplier?: InputMaybe<HingePointMultiplierUpdateManyWithoutOrganizationInput>;
  hold_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
  hubspot_credential?: InputMaybe<HubSpotCredentialUpdateOneWithoutOrganizationInput>;
  hubspot_last_sync_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  hubspot_white_list?: InputMaybe<HubSpotWhitelistUpdateManyWithoutOrganizationInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inbound_close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_dm_connect_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_lt_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_revenue_value_of_sale?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_scheduled_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_set_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventUpdateManyWithoutOrganizationInput>;
  InboundConciergeWidget?: InputMaybe<InboundConciergeWidgetUpdateManyWithoutOrganizationInput>;
  industry_options?: InputMaybe<IndustryOptionUpdateManyWithoutOrganizationInput>;
  initial_sdr_sets_reps_day?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  integration_error_logs?: InputMaybe<IntegrationErrorLogUpdateManyWithoutOrganizationInput>;
  integration_mappings?: InputMaybe<IntegrationMappingUpdateManyWithoutOrganizationInput>;
  IntegrationMappingCustomObject?: InputMaybe<IntegrationMappingCustomObjectUpdateManyWithoutOrganizationInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogUpdateManyWithoutOrganizationInput>;
  IntegrationReadOnlyObject?: InputMaybe<IntegrationReadOnlyObjectUpdateManyWithoutOrganizationInput>;
  international?: InputMaybe<BoolFieldUpdateOperationsInput>;
  is_email_domain_verified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  is_salesforce_sandbox?: InputMaybe<BoolFieldUpdateOperationsInput>;
  last_lead_value_compute_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutOrganizationInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryUpdateManyWithoutOrganizationInput>;
  lead_intent?: InputMaybe<LeadIntentUpdateManyWithoutOrganizationInput>;
  lead_release_same_rep_delay_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  lead_routing_email_list?: InputMaybe<OrganizationUpdatelead_Routing_Email_ListInput>;
  lead_routing_rules?: InputMaybe<RuleUpdateManyWithoutOrganizationInput>;
  LeadCustomObjectRowAssociation?: InputMaybe<LeadCustomObjectRowAssociationUpdateManyWithoutOrganizationInput>;
  LeadFieldMultiplier?: InputMaybe<LeadFieldMultiplierUpdateManyWithoutOrganizationInput>;
  LeadFieldMultiplierCustom?: InputMaybe<LeadFieldMultiplierCustomUpdateManyWithoutOrganizationInput>;
  leads?: InputMaybe<LeadUpdateManyWithoutOrganizationInput>;
  leadsource_options?: InputMaybe<LeadSourceOptionUpdateManyWithoutOrganizationInput>;
  LeadValue?: InputMaybe<LeadValueUpdateManyWithoutOrganizationInput>;
  LifeTimeValueMultiplier?: InputMaybe<LifeTimeValueMultiplierUpdateManyWithoutOrganizationInput>;
  LiveTransferSetting?: InputMaybe<LiveTransferSettingUpdateOneWithoutOrganizationInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusUpdateManyWithoutOrganizationInput>;
  ltv_multiplier_custom_fields?: InputMaybe<LifeTimeValueMultiplierCustomUpdateManyWithoutOrganizationInput>;
  made_contact_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  make_sale_address_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_business_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_city_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_country_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_first_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_industry_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_last_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_lead_source_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_primary_email_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_primary_phone_number_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_state_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_sub_industry_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_zip_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  maxium_day_lag_set_to_schedule?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  mrr?: InputMaybe<IntFieldUpdateOperationsInput>;
  mrr_label?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  mrr_required_on_sale?: InputMaybe<BoolFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  NoteItems?: InputMaybe<NoteItemUpdateManyWithoutOrganizationInput>;
  onboardComplete?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  Opportunity?: InputMaybe<SfOpportunityUpdateManyWithoutOrganizationInput>;
  organization_holidays?: InputMaybe<OrganizationHolidayUpdateManyWithoutOrganizationInput>;
  organization_hours?: InputMaybe<OrganizationHourUpdateManyWithoutOrganizationInput>;
  OrganizationPaymentSetting?: InputMaybe<OrganizationPaymentSettingsUpdateOneWithoutOrganizationInput>;
  OrganizationPaymentSettings?: InputMaybe<OrganizationPaymentSettingsUpdateOneWithoutOrganizationsInput>;
  orgCalendarInviteMetadata?: InputMaybe<OrgCalendarInviteMetadataUpdateManyWithoutOrganizationInput>;
  OrgDefaultPipelinePerecentValue?: InputMaybe<OrgDefaultPipelinePerecentValueUpdateOneWithoutOrganizationInput>;
  OrgHubSpotSync?: InputMaybe<OrgHubSpotSyncUpdateOneWithoutOrganizationInput>;
  OrgNoEventDate?: InputMaybe<OrgNoEventDateUpdateManyWithoutOrganizationInput>;
  OrgSalesforceSync?: InputMaybe<OrgSalesforceSyncUpdateOneWithoutOrganizationInput>;
  OrgTimeslotAvailability?: InputMaybe<OrgTimeslotAvailabilityUpdateManyWithoutOrganizationInput>;
  outbound_close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_dm_connect_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_lt_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_revenue_value_of_sale?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_scheduled_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_set_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  PandadocData?: InputMaybe<PandadocDataUpdateOneWithoutOrganizationInput>;
  PandaDocTemplate?: InputMaybe<PandaDocTemplateUpdateManyWithoutOrganizationInput>;
  participant_jitter_buffer_size?: InputMaybe<EnumJitterBufferSizeFieldUpdateOperationsInput>;
  preferred_goal_setting?: InputMaybe<NullableEnumGoalsettingFieldUpdateOperationsInput>;
  prepayment?: InputMaybe<BoolFieldUpdateOperationsInput>;
  ProductChangeLog?: InputMaybe<ProductChangeLogUpdateManyWithoutOrganizationInput>;
  products?: InputMaybe<ProductUpdateManyWithoutOrganizationInput>;
  re_cold_call_hour_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  recordingStatus?: InputMaybe<EnumRecordingStatusFieldUpdateOperationsInput>;
  release_lead_resting?: InputMaybe<IntFieldUpdateOperationsInput>;
  rep_claim_limit?: InputMaybe<IntFieldUpdateOperationsInput>;
  rep_lead_allotment?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  rep_level_call_transfer?: InputMaybe<BoolFieldUpdateOperationsInput>;
  reps_can_bulk_unassign?: InputMaybe<BoolFieldUpdateOperationsInput>;
  reps_can_view_all_notes?: InputMaybe<BoolFieldUpdateOperationsInput>;
  require_sale_notes?: InputMaybe<BoolFieldUpdateOperationsInput>;
  resting_after_skipped_CC?: InputMaybe<IntFieldUpdateOperationsInput>;
  resting_period?: InputMaybe<IntFieldUpdateOperationsInput>;
  resting_period_after_unsuccessful_cold_call?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  resting_period_after_unsuccessful_demo?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  revenue_plan?: InputMaybe<RevenuePlanItemUpdateManyWithoutOrganizationInput>;
  Routings?: InputMaybe<RoutingUpdateManyWithoutOrganizationInput>;
  sale_field_options?: InputMaybe<SaleFieldOptionUpdateManyWithoutOrganizationInput>;
  sale_notification_email_list?: InputMaybe<OrganizationUpdatesale_Notification_Email_ListInput>;
  SaleConfig?: InputMaybe<SaleConfigUpdateOneWithoutOrganizationInput>;
  SaleConfigPage?: InputMaybe<SaleConfigPageUpdateManyWithoutOrganizationInput>;
  SaleConfigSection?: InputMaybe<SaleConfigSectionUpdateManyWithoutOrganizationInput>;
  SaleCycles?: InputMaybe<SaleCycleUpdateManyWithoutOrganizationInput>;
  SaleItems?: InputMaybe<SaleItemUpdateManyWithoutOrganizationInput>;
  sales?: InputMaybe<SaleUpdateManyWithoutOrganizationInput>;
  salesforce_domain?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  salesforce_last_sync_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  SalesForceColumn?: InputMaybe<SalesForceColumnUpdateManyWithoutOrganizationInput>;
  salesForceData?: InputMaybe<SalesForceDataUpdateOneWithoutOrganizationInput>;
  ScheduleItems?: InputMaybe<ScheduleItemUpdateManyWithoutOrganizationInput>;
  self_sourcing_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  send_error_log_email?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  send_lead_routing_email?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sendgrid_email_domain?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sendgrid_email_domain_id?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  sequence?: InputMaybe<SequenceUpdateManyWithoutOrganzationInput>;
  SequenceCriteriaConditions?: InputMaybe<SequenceCriteriaConditionUpdateManyWithoutOrganizationInput>;
  SequenceDashboardViews?: InputMaybe<SequenceDashboardViewUpdateManyWithoutOrganizationInput>;
  SequenceEntryCriterias?: InputMaybe<SequenceEntryCriteriaUpdateManyWithoutOrganizationInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutOrganizationInput>;
  SequenceSteps?: InputMaybe<SequenceStepUpdateManyWithoutOrganizationInput>;
  set_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
  short_resting?: InputMaybe<IntFieldUpdateOperationsInput>;
  show_calendar_options?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_contract_duration_sales?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_lead_source_to_rep?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_nocontact_button?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_payment_terms_sales?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_rep_all_leads?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_rep_my_leads?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_system_view?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sites?: InputMaybe<SiteUpdateManyWithoutOrganizationInput>;
  sms_opt_in_dialog?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SMSItems?: InputMaybe<SmsItemUpdateManyWithoutOrganizationInput>;
  SnoozeTrackings?: InputMaybe<SnoozeTrackingUpdateManyWithoutOrganizationInput>;
  StepActionLogs?: InputMaybe<StepActionLogUpdateManyWithoutOrganizationInput>;
  StepActions?: InputMaybe<StepActionUpdateManyWithoutOrganizationInput>;
  StepConditions?: InputMaybe<StepConditionUpdateManyWithoutOrganizationInput>;
  teams?: InputMaybe<TeamUpdateManyWithoutOrganizationInput>;
  Template?: InputMaybe<TemplateUpdateManyWithoutOrganizationInput>;
  TemplateVersions?: InputMaybe<TemplateVersionUpdateManyWithoutOrganizationInput>;
  timekit_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timezone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transfer_timeout?: InputMaybe<IntFieldUpdateOperationsInput>;
  TransferAttempts?: InputMaybe<TransferAttemptUpdateManyWithoutOrganizationInput>;
  twilio_numbers?: InputMaybe<TwilioNumberUpdateManyWithoutOrganizationInput>;
  TwilioFeedbacks?: InputMaybe<TwilioFeedbackUpdateManyWithoutOrganizationInput>;
  TwilioInfo?: InputMaybe<TwilioInfoUpdateOneWithoutOrganzationInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  use_revenue_planning?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  UserCustomFieldFilters?: InputMaybe<UserCustomFieldFilterUpdateManyWithoutOrganizationInput>;
  users?: InputMaybe<UserUpdateManyWithoutOrganizationInput>;
  win_rep_day_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
};

export type OrganizationUpdateWithoutOrganizationPaymentSettingInput = {
  ActivityMonitorMultiplier?: InputMaybe<ActivityMonitorMultiplierUpdateManyWithoutOrganizationInput>;
  algoFactor?: InputMaybe<AlgoFactorUpdateManyWithoutOrganizationInput>;
  allow_calendar_invite?: InputMaybe<BoolFieldUpdateOperationsInput>;
  allow_remove_step_action?: InputMaybe<BoolFieldUpdateOperationsInput>;
  allow_snooze_step_action?: InputMaybe<BoolFieldUpdateOperationsInput>;
  always_record?: InputMaybe<BoolFieldUpdateOperationsInput>;
  arv_initial_held_demos_generated_by_sdr_weight?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  AssociationHistories?: InputMaybe<AssociationHistoryUpdateManyWithoutOrganizationInput>;
  auto_call_interval?: InputMaybe<IntFieldUpdateOperationsInput>;
  auto_dial?: InputMaybe<BoolFieldUpdateOperationsInput>;
  automated_campaigns?: InputMaybe<AutomatedCampaignUpdateManyWithoutOrganizationInput>;
  automatic_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  AutoTransferSelections?: InputMaybe<AutoTransferSelectionUpdateManyWithoutOrganizationInput>;
  Availablities?: InputMaybe<AvailabilityUpdateManyWithoutOrganizationInput>;
  AverageOrderValue?: InputMaybe<AverageOrderValueUpdateManyWithoutOrganizationInput>;
  best_action_ever_multiplier?: InputMaybe<FloatFieldUpdateOperationsInput>;
  best_metrics?: InputMaybe<BestMetricRecordUpdateManyWithoutOrganizationInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptUpdateManyWithoutOrganizationInput>;
  callMeNows?: InputMaybe<CallMeNowUpdateManyWithoutOrganizationInput>;
  CallMultiplier?: InputMaybe<CallMultiplierUpdateManyWithoutOrganizationInput>;
  CallSharings?: InputMaybe<CallSharingUpdateManyWithoutOrganizationInput>;
  can_edit_sdr_transfer_rules?: InputMaybe<BoolFieldUpdateOperationsInput>;
  claim_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  concession?: InputMaybe<ConcessionUpdateManyWithoutOrganizationInput>;
  conference_region?: InputMaybe<EnumConferenceRegionFieldUpdateOperationsInput>;
  Conferences?: InputMaybe<ConferenceUpdateManyWithoutOrganizationInput>;
  ContactRequests?: InputMaybe<ContactRequestUpdateManyWithoutOrganizationInput>;
  Contacts?: InputMaybe<ContactUpdateManyWithoutOrganizationInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  custom_fields?: InputMaybe<CustomFieldUpdateManyWithoutOrganizationInput>;
  CustomObject?: InputMaybe<CustomObjectUpdateManyWithoutOrganizationInput>;
  CustomObjectField?: InputMaybe<CustomObjectFieldUpdateManyWithoutOrganizationInput>;
  CustomObjectFieldForeignKey?: InputMaybe<CustomObjectFieldForeignKeyUpdateManyWithoutOrganizationInput>;
  CustomObjectRecord?: InputMaybe<CustomObjectRecordUpdateManyWithoutOrganizationInput>;
  CustomObjectRow?: InputMaybe<CustomObjectRowUpdateManyWithoutOrganizationInput>;
  CustomQueueItems?: InputMaybe<CustomQueueItemUpdateManyWithoutOrganizationInput>;
  DashboardColumnOptions?: InputMaybe<DashboardColumnOptionUpdateManyWithoutOrganizationInput>;
  DashboardLeadFilters?: InputMaybe<DashboardLeadFilterUpdateManyWithoutOrganizationInput>;
  DashboardRepFilters?: InputMaybe<DashboardRepFilterUpdateManyWithoutOrganizationInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewUpdateManyWithoutOrganizationInput>;
  day_to_close?: InputMaybe<IntFieldUpdateOperationsInput>;
  default_demo_time_duration?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  demo_held_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_not_held_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_not_held_call_back_short_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_prep_time_minutes?: InputMaybe<IntFieldUpdateOperationsInput>;
  dev_account?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  dial_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  DispositionMultiplier?: InputMaybe<DispositionMultiplierUpdateManyWithoutOrganizationInput>;
  dispositions?: InputMaybe<DispositionUpdateManyWithoutOrganizationInput>;
  double_booking?: InputMaybe<BoolFieldUpdateOperationsInput>;
  dynamic_date_operators?: InputMaybe<BoolFieldUpdateOperationsInput>;
  edge_servers?: InputMaybe<OrganizationUpdateedge_ServersInput>;
  email_conflict_notification_email_list?: InputMaybe<OrganizationUpdateemail_Conflict_Notification_Email_ListInput>;
  EmailItems?: InputMaybe<EmailItemUpdateManyWithoutOrganizationInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordUpdateManyWithoutOrganizationInput>;
  EmailThreads?: InputMaybe<EmailThreadUpdateManyWithoutOrganizationInput>;
  enable_modified_metrics?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  enable_pay_period_view?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  enable_time_constraint?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  error_log_email_address?: InputMaybe<OrganizationUpdateerror_Log_Email_AddressInput>;
  estimated_average_num_of_weekly_overbooked_demo_slots?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  FolderPermissions?: InputMaybe<FolderPermissionUpdateManyWithoutOrganizationInput>;
  Folders?: InputMaybe<FolderUpdateManyWithoutOrganizationInput>;
  FutureBookingSetting?: InputMaybe<FutureBookingSettingUpdateOneWithoutOrganizationInput>;
  GlobalTransferRule?: InputMaybe<GlobalTransferRuleUpdateManyWithoutOrganizationInput>;
  held_demos_per_cc_connect?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  helpful_links?: InputMaybe<OrganizationUpdatehelpful_LinksInput>;
  HingePointMultiplier?: InputMaybe<HingePointMultiplierUpdateManyWithoutOrganizationInput>;
  hold_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
  hubspot_credential?: InputMaybe<HubSpotCredentialUpdateOneWithoutOrganizationInput>;
  hubspot_last_sync_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  hubspot_white_list?: InputMaybe<HubSpotWhitelistUpdateManyWithoutOrganizationInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inbound_close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_dm_connect_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_lt_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_revenue_value_of_sale?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_scheduled_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_set_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventUpdateManyWithoutOrganizationInput>;
  InboundConciergeWidget?: InputMaybe<InboundConciergeWidgetUpdateManyWithoutOrganizationInput>;
  industry_options?: InputMaybe<IndustryOptionUpdateManyWithoutOrganizationInput>;
  initial_sdr_sets_reps_day?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  integration_error_logs?: InputMaybe<IntegrationErrorLogUpdateManyWithoutOrganizationInput>;
  integration_mappings?: InputMaybe<IntegrationMappingUpdateManyWithoutOrganizationInput>;
  IntegrationMappingCustomObject?: InputMaybe<IntegrationMappingCustomObjectUpdateManyWithoutOrganizationInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogUpdateManyWithoutOrganizationInput>;
  IntegrationReadOnlyObject?: InputMaybe<IntegrationReadOnlyObjectUpdateManyWithoutOrganizationInput>;
  international?: InputMaybe<BoolFieldUpdateOperationsInput>;
  is_email_domain_verified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  is_salesforce_sandbox?: InputMaybe<BoolFieldUpdateOperationsInput>;
  last_lead_value_compute_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutOrganizationInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryUpdateManyWithoutOrganizationInput>;
  lead_intent?: InputMaybe<LeadIntentUpdateManyWithoutOrganizationInput>;
  lead_release_same_rep_delay_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  lead_routing_email_list?: InputMaybe<OrganizationUpdatelead_Routing_Email_ListInput>;
  lead_routing_rules?: InputMaybe<RuleUpdateManyWithoutOrganizationInput>;
  LeadCustomObjectRowAssociation?: InputMaybe<LeadCustomObjectRowAssociationUpdateManyWithoutOrganizationInput>;
  LeadFieldMultiplier?: InputMaybe<LeadFieldMultiplierUpdateManyWithoutOrganizationInput>;
  LeadFieldMultiplierCustom?: InputMaybe<LeadFieldMultiplierCustomUpdateManyWithoutOrganizationInput>;
  leads?: InputMaybe<LeadUpdateManyWithoutOrganizationInput>;
  leadsource_options?: InputMaybe<LeadSourceOptionUpdateManyWithoutOrganizationInput>;
  LeadValue?: InputMaybe<LeadValueUpdateManyWithoutOrganizationInput>;
  LifeTimeValueMultiplier?: InputMaybe<LifeTimeValueMultiplierUpdateManyWithoutOrganizationInput>;
  LiveTransferSetting?: InputMaybe<LiveTransferSettingUpdateOneWithoutOrganizationInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusUpdateManyWithoutOrganizationInput>;
  ltv_multiplier_custom_fields?: InputMaybe<LifeTimeValueMultiplierCustomUpdateManyWithoutOrganizationInput>;
  made_contact_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  make_sale_address_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_business_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_city_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_country_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_first_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_industry_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_last_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_lead_source_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_primary_email_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_primary_phone_number_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_state_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_sub_industry_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_zip_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  maxium_day_lag_set_to_schedule?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  mrr?: InputMaybe<IntFieldUpdateOperationsInput>;
  mrr_label?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  mrr_required_on_sale?: InputMaybe<BoolFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  NoteItems?: InputMaybe<NoteItemUpdateManyWithoutOrganizationInput>;
  Notifications?: InputMaybe<NotificationUpdateManyWithoutOrganizationInput>;
  onboardComplete?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  Opportunity?: InputMaybe<SfOpportunityUpdateManyWithoutOrganizationInput>;
  organization_holidays?: InputMaybe<OrganizationHolidayUpdateManyWithoutOrganizationInput>;
  organization_hours?: InputMaybe<OrganizationHourUpdateManyWithoutOrganizationInput>;
  OrganizationPaymentSettings?: InputMaybe<OrganizationPaymentSettingsUpdateOneWithoutOrganizationsInput>;
  orgCalendarInviteMetadata?: InputMaybe<OrgCalendarInviteMetadataUpdateManyWithoutOrganizationInput>;
  OrgDefaultPipelinePerecentValue?: InputMaybe<OrgDefaultPipelinePerecentValueUpdateOneWithoutOrganizationInput>;
  OrgHubSpotSync?: InputMaybe<OrgHubSpotSyncUpdateOneWithoutOrganizationInput>;
  OrgNoEventDate?: InputMaybe<OrgNoEventDateUpdateManyWithoutOrganizationInput>;
  OrgSalesforceSync?: InputMaybe<OrgSalesforceSyncUpdateOneWithoutOrganizationInput>;
  OrgTimeslotAvailability?: InputMaybe<OrgTimeslotAvailabilityUpdateManyWithoutOrganizationInput>;
  outbound_close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_dm_connect_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_lt_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_revenue_value_of_sale?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_scheduled_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_set_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  PandadocData?: InputMaybe<PandadocDataUpdateOneWithoutOrganizationInput>;
  PandaDocTemplate?: InputMaybe<PandaDocTemplateUpdateManyWithoutOrganizationInput>;
  participant_jitter_buffer_size?: InputMaybe<EnumJitterBufferSizeFieldUpdateOperationsInput>;
  preferred_goal_setting?: InputMaybe<NullableEnumGoalsettingFieldUpdateOperationsInput>;
  prepayment?: InputMaybe<BoolFieldUpdateOperationsInput>;
  ProductChangeLog?: InputMaybe<ProductChangeLogUpdateManyWithoutOrganizationInput>;
  products?: InputMaybe<ProductUpdateManyWithoutOrganizationInput>;
  re_cold_call_hour_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  recordingStatus?: InputMaybe<EnumRecordingStatusFieldUpdateOperationsInput>;
  release_lead_resting?: InputMaybe<IntFieldUpdateOperationsInput>;
  rep_claim_limit?: InputMaybe<IntFieldUpdateOperationsInput>;
  rep_lead_allotment?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  rep_level_call_transfer?: InputMaybe<BoolFieldUpdateOperationsInput>;
  reps_can_bulk_unassign?: InputMaybe<BoolFieldUpdateOperationsInput>;
  reps_can_view_all_notes?: InputMaybe<BoolFieldUpdateOperationsInput>;
  require_sale_notes?: InputMaybe<BoolFieldUpdateOperationsInput>;
  resting_after_skipped_CC?: InputMaybe<IntFieldUpdateOperationsInput>;
  resting_period?: InputMaybe<IntFieldUpdateOperationsInput>;
  resting_period_after_unsuccessful_cold_call?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  resting_period_after_unsuccessful_demo?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  revenue_plan?: InputMaybe<RevenuePlanItemUpdateManyWithoutOrganizationInput>;
  Routings?: InputMaybe<RoutingUpdateManyWithoutOrganizationInput>;
  sale_field_options?: InputMaybe<SaleFieldOptionUpdateManyWithoutOrganizationInput>;
  sale_notification_email_list?: InputMaybe<OrganizationUpdatesale_Notification_Email_ListInput>;
  SaleConfig?: InputMaybe<SaleConfigUpdateOneWithoutOrganizationInput>;
  SaleConfigPage?: InputMaybe<SaleConfigPageUpdateManyWithoutOrganizationInput>;
  SaleConfigSection?: InputMaybe<SaleConfigSectionUpdateManyWithoutOrganizationInput>;
  SaleCycles?: InputMaybe<SaleCycleUpdateManyWithoutOrganizationInput>;
  SaleItems?: InputMaybe<SaleItemUpdateManyWithoutOrganizationInput>;
  sales?: InputMaybe<SaleUpdateManyWithoutOrganizationInput>;
  salesforce_domain?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  salesforce_last_sync_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  SalesForceColumn?: InputMaybe<SalesForceColumnUpdateManyWithoutOrganizationInput>;
  salesForceData?: InputMaybe<SalesForceDataUpdateOneWithoutOrganizationInput>;
  ScheduleItems?: InputMaybe<ScheduleItemUpdateManyWithoutOrganizationInput>;
  self_sourcing_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  send_error_log_email?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  send_lead_routing_email?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sendgrid_email_domain?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sendgrid_email_domain_id?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  sequence?: InputMaybe<SequenceUpdateManyWithoutOrganzationInput>;
  SequenceCriteriaConditions?: InputMaybe<SequenceCriteriaConditionUpdateManyWithoutOrganizationInput>;
  SequenceDashboardViews?: InputMaybe<SequenceDashboardViewUpdateManyWithoutOrganizationInput>;
  SequenceEntryCriterias?: InputMaybe<SequenceEntryCriteriaUpdateManyWithoutOrganizationInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutOrganizationInput>;
  SequenceSteps?: InputMaybe<SequenceStepUpdateManyWithoutOrganizationInput>;
  set_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
  short_resting?: InputMaybe<IntFieldUpdateOperationsInput>;
  show_calendar_options?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_contract_duration_sales?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_lead_source_to_rep?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_nocontact_button?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_payment_terms_sales?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_rep_all_leads?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_rep_my_leads?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_system_view?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sites?: InputMaybe<SiteUpdateManyWithoutOrganizationInput>;
  sms_opt_in_dialog?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SMSItems?: InputMaybe<SmsItemUpdateManyWithoutOrganizationInput>;
  SnoozeTrackings?: InputMaybe<SnoozeTrackingUpdateManyWithoutOrganizationInput>;
  StepActionLogs?: InputMaybe<StepActionLogUpdateManyWithoutOrganizationInput>;
  StepActions?: InputMaybe<StepActionUpdateManyWithoutOrganizationInput>;
  StepConditions?: InputMaybe<StepConditionUpdateManyWithoutOrganizationInput>;
  teams?: InputMaybe<TeamUpdateManyWithoutOrganizationInput>;
  Template?: InputMaybe<TemplateUpdateManyWithoutOrganizationInput>;
  TemplateVersions?: InputMaybe<TemplateVersionUpdateManyWithoutOrganizationInput>;
  timekit_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timezone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transfer_timeout?: InputMaybe<IntFieldUpdateOperationsInput>;
  TransferAttempts?: InputMaybe<TransferAttemptUpdateManyWithoutOrganizationInput>;
  twilio_numbers?: InputMaybe<TwilioNumberUpdateManyWithoutOrganizationInput>;
  TwilioFeedbacks?: InputMaybe<TwilioFeedbackUpdateManyWithoutOrganizationInput>;
  TwilioInfo?: InputMaybe<TwilioInfoUpdateOneWithoutOrganzationInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  use_revenue_planning?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  UserCustomFieldFilters?: InputMaybe<UserCustomFieldFilterUpdateManyWithoutOrganizationInput>;
  users?: InputMaybe<UserUpdateManyWithoutOrganizationInput>;
  win_rep_day_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
};

export type OrganizationUpdateWithoutOrganizationPaymentSettingsInput = {
  ActivityMonitorMultiplier?: InputMaybe<ActivityMonitorMultiplierUpdateManyWithoutOrganizationInput>;
  algoFactor?: InputMaybe<AlgoFactorUpdateManyWithoutOrganizationInput>;
  allow_calendar_invite?: InputMaybe<BoolFieldUpdateOperationsInput>;
  allow_remove_step_action?: InputMaybe<BoolFieldUpdateOperationsInput>;
  allow_snooze_step_action?: InputMaybe<BoolFieldUpdateOperationsInput>;
  always_record?: InputMaybe<BoolFieldUpdateOperationsInput>;
  arv_initial_held_demos_generated_by_sdr_weight?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  AssociationHistories?: InputMaybe<AssociationHistoryUpdateManyWithoutOrganizationInput>;
  auto_call_interval?: InputMaybe<IntFieldUpdateOperationsInput>;
  auto_dial?: InputMaybe<BoolFieldUpdateOperationsInput>;
  automated_campaigns?: InputMaybe<AutomatedCampaignUpdateManyWithoutOrganizationInput>;
  automatic_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  AutoTransferSelections?: InputMaybe<AutoTransferSelectionUpdateManyWithoutOrganizationInput>;
  Availablities?: InputMaybe<AvailabilityUpdateManyWithoutOrganizationInput>;
  AverageOrderValue?: InputMaybe<AverageOrderValueUpdateManyWithoutOrganizationInput>;
  best_action_ever_multiplier?: InputMaybe<FloatFieldUpdateOperationsInput>;
  best_metrics?: InputMaybe<BestMetricRecordUpdateManyWithoutOrganizationInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptUpdateManyWithoutOrganizationInput>;
  callMeNows?: InputMaybe<CallMeNowUpdateManyWithoutOrganizationInput>;
  CallMultiplier?: InputMaybe<CallMultiplierUpdateManyWithoutOrganizationInput>;
  CallSharings?: InputMaybe<CallSharingUpdateManyWithoutOrganizationInput>;
  can_edit_sdr_transfer_rules?: InputMaybe<BoolFieldUpdateOperationsInput>;
  claim_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  concession?: InputMaybe<ConcessionUpdateManyWithoutOrganizationInput>;
  conference_region?: InputMaybe<EnumConferenceRegionFieldUpdateOperationsInput>;
  Conferences?: InputMaybe<ConferenceUpdateManyWithoutOrganizationInput>;
  ContactRequests?: InputMaybe<ContactRequestUpdateManyWithoutOrganizationInput>;
  Contacts?: InputMaybe<ContactUpdateManyWithoutOrganizationInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  custom_fields?: InputMaybe<CustomFieldUpdateManyWithoutOrganizationInput>;
  CustomObject?: InputMaybe<CustomObjectUpdateManyWithoutOrganizationInput>;
  CustomObjectField?: InputMaybe<CustomObjectFieldUpdateManyWithoutOrganizationInput>;
  CustomObjectFieldForeignKey?: InputMaybe<CustomObjectFieldForeignKeyUpdateManyWithoutOrganizationInput>;
  CustomObjectRecord?: InputMaybe<CustomObjectRecordUpdateManyWithoutOrganizationInput>;
  CustomObjectRow?: InputMaybe<CustomObjectRowUpdateManyWithoutOrganizationInput>;
  CustomQueueItems?: InputMaybe<CustomQueueItemUpdateManyWithoutOrganizationInput>;
  DashboardColumnOptions?: InputMaybe<DashboardColumnOptionUpdateManyWithoutOrganizationInput>;
  DashboardLeadFilters?: InputMaybe<DashboardLeadFilterUpdateManyWithoutOrganizationInput>;
  DashboardRepFilters?: InputMaybe<DashboardRepFilterUpdateManyWithoutOrganizationInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewUpdateManyWithoutOrganizationInput>;
  day_to_close?: InputMaybe<IntFieldUpdateOperationsInput>;
  default_demo_time_duration?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  demo_held_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_not_held_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_not_held_call_back_short_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_prep_time_minutes?: InputMaybe<IntFieldUpdateOperationsInput>;
  dev_account?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  dial_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  DispositionMultiplier?: InputMaybe<DispositionMultiplierUpdateManyWithoutOrganizationInput>;
  dispositions?: InputMaybe<DispositionUpdateManyWithoutOrganizationInput>;
  double_booking?: InputMaybe<BoolFieldUpdateOperationsInput>;
  dynamic_date_operators?: InputMaybe<BoolFieldUpdateOperationsInput>;
  edge_servers?: InputMaybe<OrganizationUpdateedge_ServersInput>;
  email_conflict_notification_email_list?: InputMaybe<OrganizationUpdateemail_Conflict_Notification_Email_ListInput>;
  EmailItems?: InputMaybe<EmailItemUpdateManyWithoutOrganizationInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordUpdateManyWithoutOrganizationInput>;
  EmailThreads?: InputMaybe<EmailThreadUpdateManyWithoutOrganizationInput>;
  enable_modified_metrics?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  enable_pay_period_view?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  enable_time_constraint?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  error_log_email_address?: InputMaybe<OrganizationUpdateerror_Log_Email_AddressInput>;
  estimated_average_num_of_weekly_overbooked_demo_slots?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  FolderPermissions?: InputMaybe<FolderPermissionUpdateManyWithoutOrganizationInput>;
  Folders?: InputMaybe<FolderUpdateManyWithoutOrganizationInput>;
  FutureBookingSetting?: InputMaybe<FutureBookingSettingUpdateOneWithoutOrganizationInput>;
  GlobalTransferRule?: InputMaybe<GlobalTransferRuleUpdateManyWithoutOrganizationInput>;
  held_demos_per_cc_connect?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  helpful_links?: InputMaybe<OrganizationUpdatehelpful_LinksInput>;
  HingePointMultiplier?: InputMaybe<HingePointMultiplierUpdateManyWithoutOrganizationInput>;
  hold_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
  hubspot_credential?: InputMaybe<HubSpotCredentialUpdateOneWithoutOrganizationInput>;
  hubspot_last_sync_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  hubspot_white_list?: InputMaybe<HubSpotWhitelistUpdateManyWithoutOrganizationInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inbound_close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_dm_connect_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_lt_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_revenue_value_of_sale?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_scheduled_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_set_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventUpdateManyWithoutOrganizationInput>;
  InboundConciergeWidget?: InputMaybe<InboundConciergeWidgetUpdateManyWithoutOrganizationInput>;
  industry_options?: InputMaybe<IndustryOptionUpdateManyWithoutOrganizationInput>;
  initial_sdr_sets_reps_day?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  integration_error_logs?: InputMaybe<IntegrationErrorLogUpdateManyWithoutOrganizationInput>;
  integration_mappings?: InputMaybe<IntegrationMappingUpdateManyWithoutOrganizationInput>;
  IntegrationMappingCustomObject?: InputMaybe<IntegrationMappingCustomObjectUpdateManyWithoutOrganizationInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogUpdateManyWithoutOrganizationInput>;
  IntegrationReadOnlyObject?: InputMaybe<IntegrationReadOnlyObjectUpdateManyWithoutOrganizationInput>;
  international?: InputMaybe<BoolFieldUpdateOperationsInput>;
  is_email_domain_verified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  is_salesforce_sandbox?: InputMaybe<BoolFieldUpdateOperationsInput>;
  last_lead_value_compute_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutOrganizationInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryUpdateManyWithoutOrganizationInput>;
  lead_intent?: InputMaybe<LeadIntentUpdateManyWithoutOrganizationInput>;
  lead_release_same_rep_delay_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  lead_routing_email_list?: InputMaybe<OrganizationUpdatelead_Routing_Email_ListInput>;
  lead_routing_rules?: InputMaybe<RuleUpdateManyWithoutOrganizationInput>;
  LeadCustomObjectRowAssociation?: InputMaybe<LeadCustomObjectRowAssociationUpdateManyWithoutOrganizationInput>;
  LeadFieldMultiplier?: InputMaybe<LeadFieldMultiplierUpdateManyWithoutOrganizationInput>;
  LeadFieldMultiplierCustom?: InputMaybe<LeadFieldMultiplierCustomUpdateManyWithoutOrganizationInput>;
  leads?: InputMaybe<LeadUpdateManyWithoutOrganizationInput>;
  leadsource_options?: InputMaybe<LeadSourceOptionUpdateManyWithoutOrganizationInput>;
  LeadValue?: InputMaybe<LeadValueUpdateManyWithoutOrganizationInput>;
  LifeTimeValueMultiplier?: InputMaybe<LifeTimeValueMultiplierUpdateManyWithoutOrganizationInput>;
  LiveTransferSetting?: InputMaybe<LiveTransferSettingUpdateOneWithoutOrganizationInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusUpdateManyWithoutOrganizationInput>;
  ltv_multiplier_custom_fields?: InputMaybe<LifeTimeValueMultiplierCustomUpdateManyWithoutOrganizationInput>;
  made_contact_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  make_sale_address_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_business_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_city_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_country_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_first_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_industry_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_last_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_lead_source_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_primary_email_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_primary_phone_number_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_state_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_sub_industry_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_zip_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  maxium_day_lag_set_to_schedule?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  mrr?: InputMaybe<IntFieldUpdateOperationsInput>;
  mrr_label?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  mrr_required_on_sale?: InputMaybe<BoolFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  NoteItems?: InputMaybe<NoteItemUpdateManyWithoutOrganizationInput>;
  Notifications?: InputMaybe<NotificationUpdateManyWithoutOrganizationInput>;
  onboardComplete?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  Opportunity?: InputMaybe<SfOpportunityUpdateManyWithoutOrganizationInput>;
  organization_holidays?: InputMaybe<OrganizationHolidayUpdateManyWithoutOrganizationInput>;
  organization_hours?: InputMaybe<OrganizationHourUpdateManyWithoutOrganizationInput>;
  OrganizationPaymentSetting?: InputMaybe<OrganizationPaymentSettingsUpdateOneWithoutOrganizationInput>;
  orgCalendarInviteMetadata?: InputMaybe<OrgCalendarInviteMetadataUpdateManyWithoutOrganizationInput>;
  OrgDefaultPipelinePerecentValue?: InputMaybe<OrgDefaultPipelinePerecentValueUpdateOneWithoutOrganizationInput>;
  OrgHubSpotSync?: InputMaybe<OrgHubSpotSyncUpdateOneWithoutOrganizationInput>;
  OrgNoEventDate?: InputMaybe<OrgNoEventDateUpdateManyWithoutOrganizationInput>;
  OrgSalesforceSync?: InputMaybe<OrgSalesforceSyncUpdateOneWithoutOrganizationInput>;
  OrgTimeslotAvailability?: InputMaybe<OrgTimeslotAvailabilityUpdateManyWithoutOrganizationInput>;
  outbound_close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_dm_connect_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_lt_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_revenue_value_of_sale?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_scheduled_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_set_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  PandadocData?: InputMaybe<PandadocDataUpdateOneWithoutOrganizationInput>;
  PandaDocTemplate?: InputMaybe<PandaDocTemplateUpdateManyWithoutOrganizationInput>;
  participant_jitter_buffer_size?: InputMaybe<EnumJitterBufferSizeFieldUpdateOperationsInput>;
  preferred_goal_setting?: InputMaybe<NullableEnumGoalsettingFieldUpdateOperationsInput>;
  prepayment?: InputMaybe<BoolFieldUpdateOperationsInput>;
  ProductChangeLog?: InputMaybe<ProductChangeLogUpdateManyWithoutOrganizationInput>;
  products?: InputMaybe<ProductUpdateManyWithoutOrganizationInput>;
  re_cold_call_hour_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  recordingStatus?: InputMaybe<EnumRecordingStatusFieldUpdateOperationsInput>;
  release_lead_resting?: InputMaybe<IntFieldUpdateOperationsInput>;
  rep_claim_limit?: InputMaybe<IntFieldUpdateOperationsInput>;
  rep_lead_allotment?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  rep_level_call_transfer?: InputMaybe<BoolFieldUpdateOperationsInput>;
  reps_can_bulk_unassign?: InputMaybe<BoolFieldUpdateOperationsInput>;
  reps_can_view_all_notes?: InputMaybe<BoolFieldUpdateOperationsInput>;
  require_sale_notes?: InputMaybe<BoolFieldUpdateOperationsInput>;
  resting_after_skipped_CC?: InputMaybe<IntFieldUpdateOperationsInput>;
  resting_period?: InputMaybe<IntFieldUpdateOperationsInput>;
  resting_period_after_unsuccessful_cold_call?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  resting_period_after_unsuccessful_demo?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  revenue_plan?: InputMaybe<RevenuePlanItemUpdateManyWithoutOrganizationInput>;
  Routings?: InputMaybe<RoutingUpdateManyWithoutOrganizationInput>;
  sale_field_options?: InputMaybe<SaleFieldOptionUpdateManyWithoutOrganizationInput>;
  sale_notification_email_list?: InputMaybe<OrganizationUpdatesale_Notification_Email_ListInput>;
  SaleConfig?: InputMaybe<SaleConfigUpdateOneWithoutOrganizationInput>;
  SaleConfigPage?: InputMaybe<SaleConfigPageUpdateManyWithoutOrganizationInput>;
  SaleConfigSection?: InputMaybe<SaleConfigSectionUpdateManyWithoutOrganizationInput>;
  SaleCycles?: InputMaybe<SaleCycleUpdateManyWithoutOrganizationInput>;
  SaleItems?: InputMaybe<SaleItemUpdateManyWithoutOrganizationInput>;
  sales?: InputMaybe<SaleUpdateManyWithoutOrganizationInput>;
  salesforce_domain?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  salesforce_last_sync_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  SalesForceColumn?: InputMaybe<SalesForceColumnUpdateManyWithoutOrganizationInput>;
  salesForceData?: InputMaybe<SalesForceDataUpdateOneWithoutOrganizationInput>;
  ScheduleItems?: InputMaybe<ScheduleItemUpdateManyWithoutOrganizationInput>;
  self_sourcing_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  send_error_log_email?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  send_lead_routing_email?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sendgrid_email_domain?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sendgrid_email_domain_id?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  sequence?: InputMaybe<SequenceUpdateManyWithoutOrganzationInput>;
  SequenceCriteriaConditions?: InputMaybe<SequenceCriteriaConditionUpdateManyWithoutOrganizationInput>;
  SequenceDashboardViews?: InputMaybe<SequenceDashboardViewUpdateManyWithoutOrganizationInput>;
  SequenceEntryCriterias?: InputMaybe<SequenceEntryCriteriaUpdateManyWithoutOrganizationInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutOrganizationInput>;
  SequenceSteps?: InputMaybe<SequenceStepUpdateManyWithoutOrganizationInput>;
  set_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
  short_resting?: InputMaybe<IntFieldUpdateOperationsInput>;
  show_calendar_options?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_contract_duration_sales?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_lead_source_to_rep?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_nocontact_button?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_payment_terms_sales?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_rep_all_leads?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_rep_my_leads?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_system_view?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sites?: InputMaybe<SiteUpdateManyWithoutOrganizationInput>;
  sms_opt_in_dialog?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SMSItems?: InputMaybe<SmsItemUpdateManyWithoutOrganizationInput>;
  SnoozeTrackings?: InputMaybe<SnoozeTrackingUpdateManyWithoutOrganizationInput>;
  StepActionLogs?: InputMaybe<StepActionLogUpdateManyWithoutOrganizationInput>;
  StepActions?: InputMaybe<StepActionUpdateManyWithoutOrganizationInput>;
  StepConditions?: InputMaybe<StepConditionUpdateManyWithoutOrganizationInput>;
  teams?: InputMaybe<TeamUpdateManyWithoutOrganizationInput>;
  Template?: InputMaybe<TemplateUpdateManyWithoutOrganizationInput>;
  TemplateVersions?: InputMaybe<TemplateVersionUpdateManyWithoutOrganizationInput>;
  timekit_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timezone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transfer_timeout?: InputMaybe<IntFieldUpdateOperationsInput>;
  TransferAttempts?: InputMaybe<TransferAttemptUpdateManyWithoutOrganizationInput>;
  twilio_numbers?: InputMaybe<TwilioNumberUpdateManyWithoutOrganizationInput>;
  TwilioFeedbacks?: InputMaybe<TwilioFeedbackUpdateManyWithoutOrganizationInput>;
  TwilioInfo?: InputMaybe<TwilioInfoUpdateOneWithoutOrganzationInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  use_revenue_planning?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  UserCustomFieldFilters?: InputMaybe<UserCustomFieldFilterUpdateManyWithoutOrganizationInput>;
  users?: InputMaybe<UserUpdateManyWithoutOrganizationInput>;
  win_rep_day_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
};

export type OrganizationUpdateWithoutProductChangeLogInput = {
  ActivityMonitorMultiplier?: InputMaybe<ActivityMonitorMultiplierUpdateManyWithoutOrganizationInput>;
  algoFactor?: InputMaybe<AlgoFactorUpdateManyWithoutOrganizationInput>;
  allow_calendar_invite?: InputMaybe<BoolFieldUpdateOperationsInput>;
  allow_remove_step_action?: InputMaybe<BoolFieldUpdateOperationsInput>;
  allow_snooze_step_action?: InputMaybe<BoolFieldUpdateOperationsInput>;
  always_record?: InputMaybe<BoolFieldUpdateOperationsInput>;
  arv_initial_held_demos_generated_by_sdr_weight?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  AssociationHistories?: InputMaybe<AssociationHistoryUpdateManyWithoutOrganizationInput>;
  auto_call_interval?: InputMaybe<IntFieldUpdateOperationsInput>;
  auto_dial?: InputMaybe<BoolFieldUpdateOperationsInput>;
  automated_campaigns?: InputMaybe<AutomatedCampaignUpdateManyWithoutOrganizationInput>;
  automatic_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  AutoTransferSelections?: InputMaybe<AutoTransferSelectionUpdateManyWithoutOrganizationInput>;
  Availablities?: InputMaybe<AvailabilityUpdateManyWithoutOrganizationInput>;
  AverageOrderValue?: InputMaybe<AverageOrderValueUpdateManyWithoutOrganizationInput>;
  best_action_ever_multiplier?: InputMaybe<FloatFieldUpdateOperationsInput>;
  best_metrics?: InputMaybe<BestMetricRecordUpdateManyWithoutOrganizationInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptUpdateManyWithoutOrganizationInput>;
  callMeNows?: InputMaybe<CallMeNowUpdateManyWithoutOrganizationInput>;
  CallMultiplier?: InputMaybe<CallMultiplierUpdateManyWithoutOrganizationInput>;
  CallSharings?: InputMaybe<CallSharingUpdateManyWithoutOrganizationInput>;
  can_edit_sdr_transfer_rules?: InputMaybe<BoolFieldUpdateOperationsInput>;
  claim_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  concession?: InputMaybe<ConcessionUpdateManyWithoutOrganizationInput>;
  conference_region?: InputMaybe<EnumConferenceRegionFieldUpdateOperationsInput>;
  Conferences?: InputMaybe<ConferenceUpdateManyWithoutOrganizationInput>;
  ContactRequests?: InputMaybe<ContactRequestUpdateManyWithoutOrganizationInput>;
  Contacts?: InputMaybe<ContactUpdateManyWithoutOrganizationInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  custom_fields?: InputMaybe<CustomFieldUpdateManyWithoutOrganizationInput>;
  CustomObject?: InputMaybe<CustomObjectUpdateManyWithoutOrganizationInput>;
  CustomObjectField?: InputMaybe<CustomObjectFieldUpdateManyWithoutOrganizationInput>;
  CustomObjectFieldForeignKey?: InputMaybe<CustomObjectFieldForeignKeyUpdateManyWithoutOrganizationInput>;
  CustomObjectRecord?: InputMaybe<CustomObjectRecordUpdateManyWithoutOrganizationInput>;
  CustomObjectRow?: InputMaybe<CustomObjectRowUpdateManyWithoutOrganizationInput>;
  CustomQueueItems?: InputMaybe<CustomQueueItemUpdateManyWithoutOrganizationInput>;
  DashboardColumnOptions?: InputMaybe<DashboardColumnOptionUpdateManyWithoutOrganizationInput>;
  DashboardLeadFilters?: InputMaybe<DashboardLeadFilterUpdateManyWithoutOrganizationInput>;
  DashboardRepFilters?: InputMaybe<DashboardRepFilterUpdateManyWithoutOrganizationInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewUpdateManyWithoutOrganizationInput>;
  day_to_close?: InputMaybe<IntFieldUpdateOperationsInput>;
  default_demo_time_duration?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  demo_held_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_not_held_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_not_held_call_back_short_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_prep_time_minutes?: InputMaybe<IntFieldUpdateOperationsInput>;
  dev_account?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  dial_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  DispositionMultiplier?: InputMaybe<DispositionMultiplierUpdateManyWithoutOrganizationInput>;
  dispositions?: InputMaybe<DispositionUpdateManyWithoutOrganizationInput>;
  double_booking?: InputMaybe<BoolFieldUpdateOperationsInput>;
  dynamic_date_operators?: InputMaybe<BoolFieldUpdateOperationsInput>;
  edge_servers?: InputMaybe<OrganizationUpdateedge_ServersInput>;
  email_conflict_notification_email_list?: InputMaybe<OrganizationUpdateemail_Conflict_Notification_Email_ListInput>;
  EmailItems?: InputMaybe<EmailItemUpdateManyWithoutOrganizationInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordUpdateManyWithoutOrganizationInput>;
  EmailThreads?: InputMaybe<EmailThreadUpdateManyWithoutOrganizationInput>;
  enable_modified_metrics?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  enable_pay_period_view?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  enable_time_constraint?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  error_log_email_address?: InputMaybe<OrganizationUpdateerror_Log_Email_AddressInput>;
  estimated_average_num_of_weekly_overbooked_demo_slots?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  FolderPermissions?: InputMaybe<FolderPermissionUpdateManyWithoutOrganizationInput>;
  Folders?: InputMaybe<FolderUpdateManyWithoutOrganizationInput>;
  FutureBookingSetting?: InputMaybe<FutureBookingSettingUpdateOneWithoutOrganizationInput>;
  GlobalTransferRule?: InputMaybe<GlobalTransferRuleUpdateManyWithoutOrganizationInput>;
  held_demos_per_cc_connect?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  helpful_links?: InputMaybe<OrganizationUpdatehelpful_LinksInput>;
  HingePointMultiplier?: InputMaybe<HingePointMultiplierUpdateManyWithoutOrganizationInput>;
  hold_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
  hubspot_credential?: InputMaybe<HubSpotCredentialUpdateOneWithoutOrganizationInput>;
  hubspot_last_sync_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  hubspot_white_list?: InputMaybe<HubSpotWhitelistUpdateManyWithoutOrganizationInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inbound_close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_dm_connect_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_lt_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_revenue_value_of_sale?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_scheduled_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_set_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventUpdateManyWithoutOrganizationInput>;
  InboundConciergeWidget?: InputMaybe<InboundConciergeWidgetUpdateManyWithoutOrganizationInput>;
  industry_options?: InputMaybe<IndustryOptionUpdateManyWithoutOrganizationInput>;
  initial_sdr_sets_reps_day?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  integration_error_logs?: InputMaybe<IntegrationErrorLogUpdateManyWithoutOrganizationInput>;
  integration_mappings?: InputMaybe<IntegrationMappingUpdateManyWithoutOrganizationInput>;
  IntegrationMappingCustomObject?: InputMaybe<IntegrationMappingCustomObjectUpdateManyWithoutOrganizationInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogUpdateManyWithoutOrganizationInput>;
  IntegrationReadOnlyObject?: InputMaybe<IntegrationReadOnlyObjectUpdateManyWithoutOrganizationInput>;
  international?: InputMaybe<BoolFieldUpdateOperationsInput>;
  is_email_domain_verified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  is_salesforce_sandbox?: InputMaybe<BoolFieldUpdateOperationsInput>;
  last_lead_value_compute_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutOrganizationInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryUpdateManyWithoutOrganizationInput>;
  lead_intent?: InputMaybe<LeadIntentUpdateManyWithoutOrganizationInput>;
  lead_release_same_rep_delay_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  lead_routing_email_list?: InputMaybe<OrganizationUpdatelead_Routing_Email_ListInput>;
  lead_routing_rules?: InputMaybe<RuleUpdateManyWithoutOrganizationInput>;
  LeadCustomObjectRowAssociation?: InputMaybe<LeadCustomObjectRowAssociationUpdateManyWithoutOrganizationInput>;
  LeadFieldMultiplier?: InputMaybe<LeadFieldMultiplierUpdateManyWithoutOrganizationInput>;
  LeadFieldMultiplierCustom?: InputMaybe<LeadFieldMultiplierCustomUpdateManyWithoutOrganizationInput>;
  leads?: InputMaybe<LeadUpdateManyWithoutOrganizationInput>;
  leadsource_options?: InputMaybe<LeadSourceOptionUpdateManyWithoutOrganizationInput>;
  LeadValue?: InputMaybe<LeadValueUpdateManyWithoutOrganizationInput>;
  LifeTimeValueMultiplier?: InputMaybe<LifeTimeValueMultiplierUpdateManyWithoutOrganizationInput>;
  LiveTransferSetting?: InputMaybe<LiveTransferSettingUpdateOneWithoutOrganizationInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusUpdateManyWithoutOrganizationInput>;
  ltv_multiplier_custom_fields?: InputMaybe<LifeTimeValueMultiplierCustomUpdateManyWithoutOrganizationInput>;
  made_contact_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  make_sale_address_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_business_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_city_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_country_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_first_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_industry_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_last_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_lead_source_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_primary_email_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_primary_phone_number_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_state_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_sub_industry_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_zip_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  maxium_day_lag_set_to_schedule?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  mrr?: InputMaybe<IntFieldUpdateOperationsInput>;
  mrr_label?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  mrr_required_on_sale?: InputMaybe<BoolFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  NoteItems?: InputMaybe<NoteItemUpdateManyWithoutOrganizationInput>;
  Notifications?: InputMaybe<NotificationUpdateManyWithoutOrganizationInput>;
  onboardComplete?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  Opportunity?: InputMaybe<SfOpportunityUpdateManyWithoutOrganizationInput>;
  organization_holidays?: InputMaybe<OrganizationHolidayUpdateManyWithoutOrganizationInput>;
  organization_hours?: InputMaybe<OrganizationHourUpdateManyWithoutOrganizationInput>;
  OrganizationPaymentSetting?: InputMaybe<OrganizationPaymentSettingsUpdateOneWithoutOrganizationInput>;
  OrganizationPaymentSettings?: InputMaybe<OrganizationPaymentSettingsUpdateOneWithoutOrganizationsInput>;
  orgCalendarInviteMetadata?: InputMaybe<OrgCalendarInviteMetadataUpdateManyWithoutOrganizationInput>;
  OrgDefaultPipelinePerecentValue?: InputMaybe<OrgDefaultPipelinePerecentValueUpdateOneWithoutOrganizationInput>;
  OrgHubSpotSync?: InputMaybe<OrgHubSpotSyncUpdateOneWithoutOrganizationInput>;
  OrgNoEventDate?: InputMaybe<OrgNoEventDateUpdateManyWithoutOrganizationInput>;
  OrgSalesforceSync?: InputMaybe<OrgSalesforceSyncUpdateOneWithoutOrganizationInput>;
  OrgTimeslotAvailability?: InputMaybe<OrgTimeslotAvailabilityUpdateManyWithoutOrganizationInput>;
  outbound_close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_dm_connect_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_lt_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_revenue_value_of_sale?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_scheduled_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_set_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  PandadocData?: InputMaybe<PandadocDataUpdateOneWithoutOrganizationInput>;
  PandaDocTemplate?: InputMaybe<PandaDocTemplateUpdateManyWithoutOrganizationInput>;
  participant_jitter_buffer_size?: InputMaybe<EnumJitterBufferSizeFieldUpdateOperationsInput>;
  preferred_goal_setting?: InputMaybe<NullableEnumGoalsettingFieldUpdateOperationsInput>;
  prepayment?: InputMaybe<BoolFieldUpdateOperationsInput>;
  products?: InputMaybe<ProductUpdateManyWithoutOrganizationInput>;
  re_cold_call_hour_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  recordingStatus?: InputMaybe<EnumRecordingStatusFieldUpdateOperationsInput>;
  release_lead_resting?: InputMaybe<IntFieldUpdateOperationsInput>;
  rep_claim_limit?: InputMaybe<IntFieldUpdateOperationsInput>;
  rep_lead_allotment?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  rep_level_call_transfer?: InputMaybe<BoolFieldUpdateOperationsInput>;
  reps_can_bulk_unassign?: InputMaybe<BoolFieldUpdateOperationsInput>;
  reps_can_view_all_notes?: InputMaybe<BoolFieldUpdateOperationsInput>;
  require_sale_notes?: InputMaybe<BoolFieldUpdateOperationsInput>;
  resting_after_skipped_CC?: InputMaybe<IntFieldUpdateOperationsInput>;
  resting_period?: InputMaybe<IntFieldUpdateOperationsInput>;
  resting_period_after_unsuccessful_cold_call?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  resting_period_after_unsuccessful_demo?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  revenue_plan?: InputMaybe<RevenuePlanItemUpdateManyWithoutOrganizationInput>;
  Routings?: InputMaybe<RoutingUpdateManyWithoutOrganizationInput>;
  sale_field_options?: InputMaybe<SaleFieldOptionUpdateManyWithoutOrganizationInput>;
  sale_notification_email_list?: InputMaybe<OrganizationUpdatesale_Notification_Email_ListInput>;
  SaleConfig?: InputMaybe<SaleConfigUpdateOneWithoutOrganizationInput>;
  SaleConfigPage?: InputMaybe<SaleConfigPageUpdateManyWithoutOrganizationInput>;
  SaleConfigSection?: InputMaybe<SaleConfigSectionUpdateManyWithoutOrganizationInput>;
  SaleCycles?: InputMaybe<SaleCycleUpdateManyWithoutOrganizationInput>;
  SaleItems?: InputMaybe<SaleItemUpdateManyWithoutOrganizationInput>;
  sales?: InputMaybe<SaleUpdateManyWithoutOrganizationInput>;
  salesforce_domain?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  salesforce_last_sync_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  SalesForceColumn?: InputMaybe<SalesForceColumnUpdateManyWithoutOrganizationInput>;
  salesForceData?: InputMaybe<SalesForceDataUpdateOneWithoutOrganizationInput>;
  ScheduleItems?: InputMaybe<ScheduleItemUpdateManyWithoutOrganizationInput>;
  self_sourcing_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  send_error_log_email?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  send_lead_routing_email?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sendgrid_email_domain?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sendgrid_email_domain_id?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  sequence?: InputMaybe<SequenceUpdateManyWithoutOrganzationInput>;
  SequenceCriteriaConditions?: InputMaybe<SequenceCriteriaConditionUpdateManyWithoutOrganizationInput>;
  SequenceDashboardViews?: InputMaybe<SequenceDashboardViewUpdateManyWithoutOrganizationInput>;
  SequenceEntryCriterias?: InputMaybe<SequenceEntryCriteriaUpdateManyWithoutOrganizationInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutOrganizationInput>;
  SequenceSteps?: InputMaybe<SequenceStepUpdateManyWithoutOrganizationInput>;
  set_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
  short_resting?: InputMaybe<IntFieldUpdateOperationsInput>;
  show_calendar_options?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_contract_duration_sales?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_lead_source_to_rep?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_nocontact_button?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_payment_terms_sales?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_rep_all_leads?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_rep_my_leads?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_system_view?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sites?: InputMaybe<SiteUpdateManyWithoutOrganizationInput>;
  sms_opt_in_dialog?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SMSItems?: InputMaybe<SmsItemUpdateManyWithoutOrganizationInput>;
  SnoozeTrackings?: InputMaybe<SnoozeTrackingUpdateManyWithoutOrganizationInput>;
  StepActionLogs?: InputMaybe<StepActionLogUpdateManyWithoutOrganizationInput>;
  StepActions?: InputMaybe<StepActionUpdateManyWithoutOrganizationInput>;
  StepConditions?: InputMaybe<StepConditionUpdateManyWithoutOrganizationInput>;
  teams?: InputMaybe<TeamUpdateManyWithoutOrganizationInput>;
  Template?: InputMaybe<TemplateUpdateManyWithoutOrganizationInput>;
  TemplateVersions?: InputMaybe<TemplateVersionUpdateManyWithoutOrganizationInput>;
  timekit_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timezone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transfer_timeout?: InputMaybe<IntFieldUpdateOperationsInput>;
  TransferAttempts?: InputMaybe<TransferAttemptUpdateManyWithoutOrganizationInput>;
  twilio_numbers?: InputMaybe<TwilioNumberUpdateManyWithoutOrganizationInput>;
  TwilioFeedbacks?: InputMaybe<TwilioFeedbackUpdateManyWithoutOrganizationInput>;
  TwilioInfo?: InputMaybe<TwilioInfoUpdateOneWithoutOrganzationInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  use_revenue_planning?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  UserCustomFieldFilters?: InputMaybe<UserCustomFieldFilterUpdateManyWithoutOrganizationInput>;
  users?: InputMaybe<UserUpdateManyWithoutOrganizationInput>;
  win_rep_day_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
};

export type OrganizationUpdateWithoutProductsInput = {
  ActivityMonitorMultiplier?: InputMaybe<ActivityMonitorMultiplierUpdateManyWithoutOrganizationInput>;
  algoFactor?: InputMaybe<AlgoFactorUpdateManyWithoutOrganizationInput>;
  allow_calendar_invite?: InputMaybe<BoolFieldUpdateOperationsInput>;
  allow_remove_step_action?: InputMaybe<BoolFieldUpdateOperationsInput>;
  allow_snooze_step_action?: InputMaybe<BoolFieldUpdateOperationsInput>;
  always_record?: InputMaybe<BoolFieldUpdateOperationsInput>;
  arv_initial_held_demos_generated_by_sdr_weight?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  AssociationHistories?: InputMaybe<AssociationHistoryUpdateManyWithoutOrganizationInput>;
  auto_call_interval?: InputMaybe<IntFieldUpdateOperationsInput>;
  auto_dial?: InputMaybe<BoolFieldUpdateOperationsInput>;
  automated_campaigns?: InputMaybe<AutomatedCampaignUpdateManyWithoutOrganizationInput>;
  automatic_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  AutoTransferSelections?: InputMaybe<AutoTransferSelectionUpdateManyWithoutOrganizationInput>;
  Availablities?: InputMaybe<AvailabilityUpdateManyWithoutOrganizationInput>;
  AverageOrderValue?: InputMaybe<AverageOrderValueUpdateManyWithoutOrganizationInput>;
  best_action_ever_multiplier?: InputMaybe<FloatFieldUpdateOperationsInput>;
  best_metrics?: InputMaybe<BestMetricRecordUpdateManyWithoutOrganizationInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptUpdateManyWithoutOrganizationInput>;
  callMeNows?: InputMaybe<CallMeNowUpdateManyWithoutOrganizationInput>;
  CallMultiplier?: InputMaybe<CallMultiplierUpdateManyWithoutOrganizationInput>;
  CallSharings?: InputMaybe<CallSharingUpdateManyWithoutOrganizationInput>;
  can_edit_sdr_transfer_rules?: InputMaybe<BoolFieldUpdateOperationsInput>;
  claim_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  concession?: InputMaybe<ConcessionUpdateManyWithoutOrganizationInput>;
  conference_region?: InputMaybe<EnumConferenceRegionFieldUpdateOperationsInput>;
  Conferences?: InputMaybe<ConferenceUpdateManyWithoutOrganizationInput>;
  ContactRequests?: InputMaybe<ContactRequestUpdateManyWithoutOrganizationInput>;
  Contacts?: InputMaybe<ContactUpdateManyWithoutOrganizationInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  custom_fields?: InputMaybe<CustomFieldUpdateManyWithoutOrganizationInput>;
  CustomObject?: InputMaybe<CustomObjectUpdateManyWithoutOrganizationInput>;
  CustomObjectField?: InputMaybe<CustomObjectFieldUpdateManyWithoutOrganizationInput>;
  CustomObjectFieldForeignKey?: InputMaybe<CustomObjectFieldForeignKeyUpdateManyWithoutOrganizationInput>;
  CustomObjectRecord?: InputMaybe<CustomObjectRecordUpdateManyWithoutOrganizationInput>;
  CustomObjectRow?: InputMaybe<CustomObjectRowUpdateManyWithoutOrganizationInput>;
  CustomQueueItems?: InputMaybe<CustomQueueItemUpdateManyWithoutOrganizationInput>;
  DashboardColumnOptions?: InputMaybe<DashboardColumnOptionUpdateManyWithoutOrganizationInput>;
  DashboardLeadFilters?: InputMaybe<DashboardLeadFilterUpdateManyWithoutOrganizationInput>;
  DashboardRepFilters?: InputMaybe<DashboardRepFilterUpdateManyWithoutOrganizationInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewUpdateManyWithoutOrganizationInput>;
  day_to_close?: InputMaybe<IntFieldUpdateOperationsInput>;
  default_demo_time_duration?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  demo_held_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_not_held_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_not_held_call_back_short_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_prep_time_minutes?: InputMaybe<IntFieldUpdateOperationsInput>;
  dev_account?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  dial_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  DispositionMultiplier?: InputMaybe<DispositionMultiplierUpdateManyWithoutOrganizationInput>;
  dispositions?: InputMaybe<DispositionUpdateManyWithoutOrganizationInput>;
  double_booking?: InputMaybe<BoolFieldUpdateOperationsInput>;
  dynamic_date_operators?: InputMaybe<BoolFieldUpdateOperationsInput>;
  edge_servers?: InputMaybe<OrganizationUpdateedge_ServersInput>;
  email_conflict_notification_email_list?: InputMaybe<OrganizationUpdateemail_Conflict_Notification_Email_ListInput>;
  EmailItems?: InputMaybe<EmailItemUpdateManyWithoutOrganizationInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordUpdateManyWithoutOrganizationInput>;
  EmailThreads?: InputMaybe<EmailThreadUpdateManyWithoutOrganizationInput>;
  enable_modified_metrics?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  enable_pay_period_view?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  enable_time_constraint?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  error_log_email_address?: InputMaybe<OrganizationUpdateerror_Log_Email_AddressInput>;
  estimated_average_num_of_weekly_overbooked_demo_slots?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  FolderPermissions?: InputMaybe<FolderPermissionUpdateManyWithoutOrganizationInput>;
  Folders?: InputMaybe<FolderUpdateManyWithoutOrganizationInput>;
  FutureBookingSetting?: InputMaybe<FutureBookingSettingUpdateOneWithoutOrganizationInput>;
  GlobalTransferRule?: InputMaybe<GlobalTransferRuleUpdateManyWithoutOrganizationInput>;
  held_demos_per_cc_connect?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  helpful_links?: InputMaybe<OrganizationUpdatehelpful_LinksInput>;
  HingePointMultiplier?: InputMaybe<HingePointMultiplierUpdateManyWithoutOrganizationInput>;
  hold_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
  hubspot_credential?: InputMaybe<HubSpotCredentialUpdateOneWithoutOrganizationInput>;
  hubspot_last_sync_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  hubspot_white_list?: InputMaybe<HubSpotWhitelistUpdateManyWithoutOrganizationInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inbound_close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_dm_connect_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_lt_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_revenue_value_of_sale?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_scheduled_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_set_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventUpdateManyWithoutOrganizationInput>;
  InboundConciergeWidget?: InputMaybe<InboundConciergeWidgetUpdateManyWithoutOrganizationInput>;
  industry_options?: InputMaybe<IndustryOptionUpdateManyWithoutOrganizationInput>;
  initial_sdr_sets_reps_day?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  integration_error_logs?: InputMaybe<IntegrationErrorLogUpdateManyWithoutOrganizationInput>;
  integration_mappings?: InputMaybe<IntegrationMappingUpdateManyWithoutOrganizationInput>;
  IntegrationMappingCustomObject?: InputMaybe<IntegrationMappingCustomObjectUpdateManyWithoutOrganizationInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogUpdateManyWithoutOrganizationInput>;
  IntegrationReadOnlyObject?: InputMaybe<IntegrationReadOnlyObjectUpdateManyWithoutOrganizationInput>;
  international?: InputMaybe<BoolFieldUpdateOperationsInput>;
  is_email_domain_verified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  is_salesforce_sandbox?: InputMaybe<BoolFieldUpdateOperationsInput>;
  last_lead_value_compute_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutOrganizationInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryUpdateManyWithoutOrganizationInput>;
  lead_intent?: InputMaybe<LeadIntentUpdateManyWithoutOrganizationInput>;
  lead_release_same_rep_delay_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  lead_routing_email_list?: InputMaybe<OrganizationUpdatelead_Routing_Email_ListInput>;
  lead_routing_rules?: InputMaybe<RuleUpdateManyWithoutOrganizationInput>;
  LeadCustomObjectRowAssociation?: InputMaybe<LeadCustomObjectRowAssociationUpdateManyWithoutOrganizationInput>;
  LeadFieldMultiplier?: InputMaybe<LeadFieldMultiplierUpdateManyWithoutOrganizationInput>;
  LeadFieldMultiplierCustom?: InputMaybe<LeadFieldMultiplierCustomUpdateManyWithoutOrganizationInput>;
  leads?: InputMaybe<LeadUpdateManyWithoutOrganizationInput>;
  leadsource_options?: InputMaybe<LeadSourceOptionUpdateManyWithoutOrganizationInput>;
  LeadValue?: InputMaybe<LeadValueUpdateManyWithoutOrganizationInput>;
  LifeTimeValueMultiplier?: InputMaybe<LifeTimeValueMultiplierUpdateManyWithoutOrganizationInput>;
  LiveTransferSetting?: InputMaybe<LiveTransferSettingUpdateOneWithoutOrganizationInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusUpdateManyWithoutOrganizationInput>;
  ltv_multiplier_custom_fields?: InputMaybe<LifeTimeValueMultiplierCustomUpdateManyWithoutOrganizationInput>;
  made_contact_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  make_sale_address_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_business_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_city_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_country_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_first_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_industry_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_last_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_lead_source_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_primary_email_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_primary_phone_number_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_state_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_sub_industry_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_zip_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  maxium_day_lag_set_to_schedule?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  mrr?: InputMaybe<IntFieldUpdateOperationsInput>;
  mrr_label?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  mrr_required_on_sale?: InputMaybe<BoolFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  NoteItems?: InputMaybe<NoteItemUpdateManyWithoutOrganizationInput>;
  Notifications?: InputMaybe<NotificationUpdateManyWithoutOrganizationInput>;
  onboardComplete?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  Opportunity?: InputMaybe<SfOpportunityUpdateManyWithoutOrganizationInput>;
  organization_holidays?: InputMaybe<OrganizationHolidayUpdateManyWithoutOrganizationInput>;
  organization_hours?: InputMaybe<OrganizationHourUpdateManyWithoutOrganizationInput>;
  OrganizationPaymentSetting?: InputMaybe<OrganizationPaymentSettingsUpdateOneWithoutOrganizationInput>;
  OrganizationPaymentSettings?: InputMaybe<OrganizationPaymentSettingsUpdateOneWithoutOrganizationsInput>;
  orgCalendarInviteMetadata?: InputMaybe<OrgCalendarInviteMetadataUpdateManyWithoutOrganizationInput>;
  OrgDefaultPipelinePerecentValue?: InputMaybe<OrgDefaultPipelinePerecentValueUpdateOneWithoutOrganizationInput>;
  OrgHubSpotSync?: InputMaybe<OrgHubSpotSyncUpdateOneWithoutOrganizationInput>;
  OrgNoEventDate?: InputMaybe<OrgNoEventDateUpdateManyWithoutOrganizationInput>;
  OrgSalesforceSync?: InputMaybe<OrgSalesforceSyncUpdateOneWithoutOrganizationInput>;
  OrgTimeslotAvailability?: InputMaybe<OrgTimeslotAvailabilityUpdateManyWithoutOrganizationInput>;
  outbound_close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_dm_connect_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_lt_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_revenue_value_of_sale?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_scheduled_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_set_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  PandadocData?: InputMaybe<PandadocDataUpdateOneWithoutOrganizationInput>;
  PandaDocTemplate?: InputMaybe<PandaDocTemplateUpdateManyWithoutOrganizationInput>;
  participant_jitter_buffer_size?: InputMaybe<EnumJitterBufferSizeFieldUpdateOperationsInput>;
  preferred_goal_setting?: InputMaybe<NullableEnumGoalsettingFieldUpdateOperationsInput>;
  prepayment?: InputMaybe<BoolFieldUpdateOperationsInput>;
  ProductChangeLog?: InputMaybe<ProductChangeLogUpdateManyWithoutOrganizationInput>;
  re_cold_call_hour_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  recordingStatus?: InputMaybe<EnumRecordingStatusFieldUpdateOperationsInput>;
  release_lead_resting?: InputMaybe<IntFieldUpdateOperationsInput>;
  rep_claim_limit?: InputMaybe<IntFieldUpdateOperationsInput>;
  rep_lead_allotment?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  rep_level_call_transfer?: InputMaybe<BoolFieldUpdateOperationsInput>;
  reps_can_bulk_unassign?: InputMaybe<BoolFieldUpdateOperationsInput>;
  reps_can_view_all_notes?: InputMaybe<BoolFieldUpdateOperationsInput>;
  require_sale_notes?: InputMaybe<BoolFieldUpdateOperationsInput>;
  resting_after_skipped_CC?: InputMaybe<IntFieldUpdateOperationsInput>;
  resting_period?: InputMaybe<IntFieldUpdateOperationsInput>;
  resting_period_after_unsuccessful_cold_call?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  resting_period_after_unsuccessful_demo?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  revenue_plan?: InputMaybe<RevenuePlanItemUpdateManyWithoutOrganizationInput>;
  Routings?: InputMaybe<RoutingUpdateManyWithoutOrganizationInput>;
  sale_field_options?: InputMaybe<SaleFieldOptionUpdateManyWithoutOrganizationInput>;
  sale_notification_email_list?: InputMaybe<OrganizationUpdatesale_Notification_Email_ListInput>;
  SaleConfig?: InputMaybe<SaleConfigUpdateOneWithoutOrganizationInput>;
  SaleConfigPage?: InputMaybe<SaleConfigPageUpdateManyWithoutOrganizationInput>;
  SaleConfigSection?: InputMaybe<SaleConfigSectionUpdateManyWithoutOrganizationInput>;
  SaleCycles?: InputMaybe<SaleCycleUpdateManyWithoutOrganizationInput>;
  SaleItems?: InputMaybe<SaleItemUpdateManyWithoutOrganizationInput>;
  sales?: InputMaybe<SaleUpdateManyWithoutOrganizationInput>;
  salesforce_domain?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  salesforce_last_sync_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  SalesForceColumn?: InputMaybe<SalesForceColumnUpdateManyWithoutOrganizationInput>;
  salesForceData?: InputMaybe<SalesForceDataUpdateOneWithoutOrganizationInput>;
  ScheduleItems?: InputMaybe<ScheduleItemUpdateManyWithoutOrganizationInput>;
  self_sourcing_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  send_error_log_email?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  send_lead_routing_email?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sendgrid_email_domain?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sendgrid_email_domain_id?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  sequence?: InputMaybe<SequenceUpdateManyWithoutOrganzationInput>;
  SequenceCriteriaConditions?: InputMaybe<SequenceCriteriaConditionUpdateManyWithoutOrganizationInput>;
  SequenceDashboardViews?: InputMaybe<SequenceDashboardViewUpdateManyWithoutOrganizationInput>;
  SequenceEntryCriterias?: InputMaybe<SequenceEntryCriteriaUpdateManyWithoutOrganizationInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutOrganizationInput>;
  SequenceSteps?: InputMaybe<SequenceStepUpdateManyWithoutOrganizationInput>;
  set_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
  short_resting?: InputMaybe<IntFieldUpdateOperationsInput>;
  show_calendar_options?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_contract_duration_sales?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_lead_source_to_rep?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_nocontact_button?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_payment_terms_sales?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_rep_all_leads?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_rep_my_leads?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_system_view?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sites?: InputMaybe<SiteUpdateManyWithoutOrganizationInput>;
  sms_opt_in_dialog?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SMSItems?: InputMaybe<SmsItemUpdateManyWithoutOrganizationInput>;
  SnoozeTrackings?: InputMaybe<SnoozeTrackingUpdateManyWithoutOrganizationInput>;
  StepActionLogs?: InputMaybe<StepActionLogUpdateManyWithoutOrganizationInput>;
  StepActions?: InputMaybe<StepActionUpdateManyWithoutOrganizationInput>;
  StepConditions?: InputMaybe<StepConditionUpdateManyWithoutOrganizationInput>;
  teams?: InputMaybe<TeamUpdateManyWithoutOrganizationInput>;
  Template?: InputMaybe<TemplateUpdateManyWithoutOrganizationInput>;
  TemplateVersions?: InputMaybe<TemplateVersionUpdateManyWithoutOrganizationInput>;
  timekit_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timezone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transfer_timeout?: InputMaybe<IntFieldUpdateOperationsInput>;
  TransferAttempts?: InputMaybe<TransferAttemptUpdateManyWithoutOrganizationInput>;
  twilio_numbers?: InputMaybe<TwilioNumberUpdateManyWithoutOrganizationInput>;
  TwilioFeedbacks?: InputMaybe<TwilioFeedbackUpdateManyWithoutOrganizationInput>;
  TwilioInfo?: InputMaybe<TwilioInfoUpdateOneWithoutOrganzationInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  use_revenue_planning?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  UserCustomFieldFilters?: InputMaybe<UserCustomFieldFilterUpdateManyWithoutOrganizationInput>;
  users?: InputMaybe<UserUpdateManyWithoutOrganizationInput>;
  win_rep_day_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
};

export type OrganizationUpdateWithoutRoutingsInput = {
  ActivityMonitorMultiplier?: InputMaybe<ActivityMonitorMultiplierUpdateManyWithoutOrganizationInput>;
  algoFactor?: InputMaybe<AlgoFactorUpdateManyWithoutOrganizationInput>;
  allow_calendar_invite?: InputMaybe<BoolFieldUpdateOperationsInput>;
  allow_remove_step_action?: InputMaybe<BoolFieldUpdateOperationsInput>;
  allow_snooze_step_action?: InputMaybe<BoolFieldUpdateOperationsInput>;
  always_record?: InputMaybe<BoolFieldUpdateOperationsInput>;
  arv_initial_held_demos_generated_by_sdr_weight?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  AssociationHistories?: InputMaybe<AssociationHistoryUpdateManyWithoutOrganizationInput>;
  auto_call_interval?: InputMaybe<IntFieldUpdateOperationsInput>;
  auto_dial?: InputMaybe<BoolFieldUpdateOperationsInput>;
  automated_campaigns?: InputMaybe<AutomatedCampaignUpdateManyWithoutOrganizationInput>;
  automatic_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  AutoTransferSelections?: InputMaybe<AutoTransferSelectionUpdateManyWithoutOrganizationInput>;
  Availablities?: InputMaybe<AvailabilityUpdateManyWithoutOrganizationInput>;
  AverageOrderValue?: InputMaybe<AverageOrderValueUpdateManyWithoutOrganizationInput>;
  best_action_ever_multiplier?: InputMaybe<FloatFieldUpdateOperationsInput>;
  best_metrics?: InputMaybe<BestMetricRecordUpdateManyWithoutOrganizationInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptUpdateManyWithoutOrganizationInput>;
  callMeNows?: InputMaybe<CallMeNowUpdateManyWithoutOrganizationInput>;
  CallMultiplier?: InputMaybe<CallMultiplierUpdateManyWithoutOrganizationInput>;
  CallSharings?: InputMaybe<CallSharingUpdateManyWithoutOrganizationInput>;
  can_edit_sdr_transfer_rules?: InputMaybe<BoolFieldUpdateOperationsInput>;
  claim_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  concession?: InputMaybe<ConcessionUpdateManyWithoutOrganizationInput>;
  conference_region?: InputMaybe<EnumConferenceRegionFieldUpdateOperationsInput>;
  Conferences?: InputMaybe<ConferenceUpdateManyWithoutOrganizationInput>;
  ContactRequests?: InputMaybe<ContactRequestUpdateManyWithoutOrganizationInput>;
  Contacts?: InputMaybe<ContactUpdateManyWithoutOrganizationInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  custom_fields?: InputMaybe<CustomFieldUpdateManyWithoutOrganizationInput>;
  CustomObject?: InputMaybe<CustomObjectUpdateManyWithoutOrganizationInput>;
  CustomObjectField?: InputMaybe<CustomObjectFieldUpdateManyWithoutOrganizationInput>;
  CustomObjectFieldForeignKey?: InputMaybe<CustomObjectFieldForeignKeyUpdateManyWithoutOrganizationInput>;
  CustomObjectRecord?: InputMaybe<CustomObjectRecordUpdateManyWithoutOrganizationInput>;
  CustomObjectRow?: InputMaybe<CustomObjectRowUpdateManyWithoutOrganizationInput>;
  CustomQueueItems?: InputMaybe<CustomQueueItemUpdateManyWithoutOrganizationInput>;
  DashboardColumnOptions?: InputMaybe<DashboardColumnOptionUpdateManyWithoutOrganizationInput>;
  DashboardLeadFilters?: InputMaybe<DashboardLeadFilterUpdateManyWithoutOrganizationInput>;
  DashboardRepFilters?: InputMaybe<DashboardRepFilterUpdateManyWithoutOrganizationInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewUpdateManyWithoutOrganizationInput>;
  day_to_close?: InputMaybe<IntFieldUpdateOperationsInput>;
  default_demo_time_duration?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  demo_held_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_not_held_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_not_held_call_back_short_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_prep_time_minutes?: InputMaybe<IntFieldUpdateOperationsInput>;
  dev_account?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  dial_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  DispositionMultiplier?: InputMaybe<DispositionMultiplierUpdateManyWithoutOrganizationInput>;
  dispositions?: InputMaybe<DispositionUpdateManyWithoutOrganizationInput>;
  double_booking?: InputMaybe<BoolFieldUpdateOperationsInput>;
  dynamic_date_operators?: InputMaybe<BoolFieldUpdateOperationsInput>;
  edge_servers?: InputMaybe<OrganizationUpdateedge_ServersInput>;
  email_conflict_notification_email_list?: InputMaybe<OrganizationUpdateemail_Conflict_Notification_Email_ListInput>;
  EmailItems?: InputMaybe<EmailItemUpdateManyWithoutOrganizationInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordUpdateManyWithoutOrganizationInput>;
  EmailThreads?: InputMaybe<EmailThreadUpdateManyWithoutOrganizationInput>;
  enable_modified_metrics?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  enable_pay_period_view?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  enable_time_constraint?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  error_log_email_address?: InputMaybe<OrganizationUpdateerror_Log_Email_AddressInput>;
  estimated_average_num_of_weekly_overbooked_demo_slots?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  FolderPermissions?: InputMaybe<FolderPermissionUpdateManyWithoutOrganizationInput>;
  Folders?: InputMaybe<FolderUpdateManyWithoutOrganizationInput>;
  FutureBookingSetting?: InputMaybe<FutureBookingSettingUpdateOneWithoutOrganizationInput>;
  GlobalTransferRule?: InputMaybe<GlobalTransferRuleUpdateManyWithoutOrganizationInput>;
  held_demos_per_cc_connect?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  helpful_links?: InputMaybe<OrganizationUpdatehelpful_LinksInput>;
  HingePointMultiplier?: InputMaybe<HingePointMultiplierUpdateManyWithoutOrganizationInput>;
  hold_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
  hubspot_credential?: InputMaybe<HubSpotCredentialUpdateOneWithoutOrganizationInput>;
  hubspot_last_sync_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  hubspot_white_list?: InputMaybe<HubSpotWhitelistUpdateManyWithoutOrganizationInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inbound_close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_dm_connect_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_lt_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_revenue_value_of_sale?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_scheduled_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_set_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventUpdateManyWithoutOrganizationInput>;
  InboundConciergeWidget?: InputMaybe<InboundConciergeWidgetUpdateManyWithoutOrganizationInput>;
  industry_options?: InputMaybe<IndustryOptionUpdateManyWithoutOrganizationInput>;
  initial_sdr_sets_reps_day?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  integration_error_logs?: InputMaybe<IntegrationErrorLogUpdateManyWithoutOrganizationInput>;
  integration_mappings?: InputMaybe<IntegrationMappingUpdateManyWithoutOrganizationInput>;
  IntegrationMappingCustomObject?: InputMaybe<IntegrationMappingCustomObjectUpdateManyWithoutOrganizationInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogUpdateManyWithoutOrganizationInput>;
  IntegrationReadOnlyObject?: InputMaybe<IntegrationReadOnlyObjectUpdateManyWithoutOrganizationInput>;
  international?: InputMaybe<BoolFieldUpdateOperationsInput>;
  is_email_domain_verified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  is_salesforce_sandbox?: InputMaybe<BoolFieldUpdateOperationsInput>;
  last_lead_value_compute_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutOrganizationInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryUpdateManyWithoutOrganizationInput>;
  lead_intent?: InputMaybe<LeadIntentUpdateManyWithoutOrganizationInput>;
  lead_release_same_rep_delay_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  lead_routing_email_list?: InputMaybe<OrganizationUpdatelead_Routing_Email_ListInput>;
  lead_routing_rules?: InputMaybe<RuleUpdateManyWithoutOrganizationInput>;
  LeadCustomObjectRowAssociation?: InputMaybe<LeadCustomObjectRowAssociationUpdateManyWithoutOrganizationInput>;
  LeadFieldMultiplier?: InputMaybe<LeadFieldMultiplierUpdateManyWithoutOrganizationInput>;
  LeadFieldMultiplierCustom?: InputMaybe<LeadFieldMultiplierCustomUpdateManyWithoutOrganizationInput>;
  leads?: InputMaybe<LeadUpdateManyWithoutOrganizationInput>;
  leadsource_options?: InputMaybe<LeadSourceOptionUpdateManyWithoutOrganizationInput>;
  LeadValue?: InputMaybe<LeadValueUpdateManyWithoutOrganizationInput>;
  LifeTimeValueMultiplier?: InputMaybe<LifeTimeValueMultiplierUpdateManyWithoutOrganizationInput>;
  LiveTransferSetting?: InputMaybe<LiveTransferSettingUpdateOneWithoutOrganizationInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusUpdateManyWithoutOrganizationInput>;
  ltv_multiplier_custom_fields?: InputMaybe<LifeTimeValueMultiplierCustomUpdateManyWithoutOrganizationInput>;
  made_contact_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  make_sale_address_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_business_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_city_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_country_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_first_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_industry_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_last_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_lead_source_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_primary_email_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_primary_phone_number_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_state_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_sub_industry_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_zip_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  maxium_day_lag_set_to_schedule?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  mrr?: InputMaybe<IntFieldUpdateOperationsInput>;
  mrr_label?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  mrr_required_on_sale?: InputMaybe<BoolFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  NoteItems?: InputMaybe<NoteItemUpdateManyWithoutOrganizationInput>;
  Notifications?: InputMaybe<NotificationUpdateManyWithoutOrganizationInput>;
  onboardComplete?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  Opportunity?: InputMaybe<SfOpportunityUpdateManyWithoutOrganizationInput>;
  organization_holidays?: InputMaybe<OrganizationHolidayUpdateManyWithoutOrganizationInput>;
  organization_hours?: InputMaybe<OrganizationHourUpdateManyWithoutOrganizationInput>;
  OrganizationPaymentSetting?: InputMaybe<OrganizationPaymentSettingsUpdateOneWithoutOrganizationInput>;
  OrganizationPaymentSettings?: InputMaybe<OrganizationPaymentSettingsUpdateOneWithoutOrganizationsInput>;
  orgCalendarInviteMetadata?: InputMaybe<OrgCalendarInviteMetadataUpdateManyWithoutOrganizationInput>;
  OrgDefaultPipelinePerecentValue?: InputMaybe<OrgDefaultPipelinePerecentValueUpdateOneWithoutOrganizationInput>;
  OrgHubSpotSync?: InputMaybe<OrgHubSpotSyncUpdateOneWithoutOrganizationInput>;
  OrgNoEventDate?: InputMaybe<OrgNoEventDateUpdateManyWithoutOrganizationInput>;
  OrgSalesforceSync?: InputMaybe<OrgSalesforceSyncUpdateOneWithoutOrganizationInput>;
  OrgTimeslotAvailability?: InputMaybe<OrgTimeslotAvailabilityUpdateManyWithoutOrganizationInput>;
  outbound_close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_dm_connect_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_lt_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_revenue_value_of_sale?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_scheduled_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_set_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  PandadocData?: InputMaybe<PandadocDataUpdateOneWithoutOrganizationInput>;
  PandaDocTemplate?: InputMaybe<PandaDocTemplateUpdateManyWithoutOrganizationInput>;
  participant_jitter_buffer_size?: InputMaybe<EnumJitterBufferSizeFieldUpdateOperationsInput>;
  preferred_goal_setting?: InputMaybe<NullableEnumGoalsettingFieldUpdateOperationsInput>;
  prepayment?: InputMaybe<BoolFieldUpdateOperationsInput>;
  ProductChangeLog?: InputMaybe<ProductChangeLogUpdateManyWithoutOrganizationInput>;
  products?: InputMaybe<ProductUpdateManyWithoutOrganizationInput>;
  re_cold_call_hour_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  recordingStatus?: InputMaybe<EnumRecordingStatusFieldUpdateOperationsInput>;
  release_lead_resting?: InputMaybe<IntFieldUpdateOperationsInput>;
  rep_claim_limit?: InputMaybe<IntFieldUpdateOperationsInput>;
  rep_lead_allotment?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  rep_level_call_transfer?: InputMaybe<BoolFieldUpdateOperationsInput>;
  reps_can_bulk_unassign?: InputMaybe<BoolFieldUpdateOperationsInput>;
  reps_can_view_all_notes?: InputMaybe<BoolFieldUpdateOperationsInput>;
  require_sale_notes?: InputMaybe<BoolFieldUpdateOperationsInput>;
  resting_after_skipped_CC?: InputMaybe<IntFieldUpdateOperationsInput>;
  resting_period?: InputMaybe<IntFieldUpdateOperationsInput>;
  resting_period_after_unsuccessful_cold_call?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  resting_period_after_unsuccessful_demo?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  revenue_plan?: InputMaybe<RevenuePlanItemUpdateManyWithoutOrganizationInput>;
  sale_field_options?: InputMaybe<SaleFieldOptionUpdateManyWithoutOrganizationInput>;
  sale_notification_email_list?: InputMaybe<OrganizationUpdatesale_Notification_Email_ListInput>;
  SaleConfig?: InputMaybe<SaleConfigUpdateOneWithoutOrganizationInput>;
  SaleConfigPage?: InputMaybe<SaleConfigPageUpdateManyWithoutOrganizationInput>;
  SaleConfigSection?: InputMaybe<SaleConfigSectionUpdateManyWithoutOrganizationInput>;
  SaleCycles?: InputMaybe<SaleCycleUpdateManyWithoutOrganizationInput>;
  SaleItems?: InputMaybe<SaleItemUpdateManyWithoutOrganizationInput>;
  sales?: InputMaybe<SaleUpdateManyWithoutOrganizationInput>;
  salesforce_domain?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  salesforce_last_sync_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  SalesForceColumn?: InputMaybe<SalesForceColumnUpdateManyWithoutOrganizationInput>;
  salesForceData?: InputMaybe<SalesForceDataUpdateOneWithoutOrganizationInput>;
  ScheduleItems?: InputMaybe<ScheduleItemUpdateManyWithoutOrganizationInput>;
  self_sourcing_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  send_error_log_email?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  send_lead_routing_email?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sendgrid_email_domain?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sendgrid_email_domain_id?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  sequence?: InputMaybe<SequenceUpdateManyWithoutOrganzationInput>;
  SequenceCriteriaConditions?: InputMaybe<SequenceCriteriaConditionUpdateManyWithoutOrganizationInput>;
  SequenceDashboardViews?: InputMaybe<SequenceDashboardViewUpdateManyWithoutOrganizationInput>;
  SequenceEntryCriterias?: InputMaybe<SequenceEntryCriteriaUpdateManyWithoutOrganizationInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutOrganizationInput>;
  SequenceSteps?: InputMaybe<SequenceStepUpdateManyWithoutOrganizationInput>;
  set_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
  short_resting?: InputMaybe<IntFieldUpdateOperationsInput>;
  show_calendar_options?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_contract_duration_sales?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_lead_source_to_rep?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_nocontact_button?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_payment_terms_sales?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_rep_all_leads?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_rep_my_leads?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_system_view?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sites?: InputMaybe<SiteUpdateManyWithoutOrganizationInput>;
  sms_opt_in_dialog?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SMSItems?: InputMaybe<SmsItemUpdateManyWithoutOrganizationInput>;
  SnoozeTrackings?: InputMaybe<SnoozeTrackingUpdateManyWithoutOrganizationInput>;
  StepActionLogs?: InputMaybe<StepActionLogUpdateManyWithoutOrganizationInput>;
  StepActions?: InputMaybe<StepActionUpdateManyWithoutOrganizationInput>;
  StepConditions?: InputMaybe<StepConditionUpdateManyWithoutOrganizationInput>;
  teams?: InputMaybe<TeamUpdateManyWithoutOrganizationInput>;
  Template?: InputMaybe<TemplateUpdateManyWithoutOrganizationInput>;
  TemplateVersions?: InputMaybe<TemplateVersionUpdateManyWithoutOrganizationInput>;
  timekit_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timezone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transfer_timeout?: InputMaybe<IntFieldUpdateOperationsInput>;
  TransferAttempts?: InputMaybe<TransferAttemptUpdateManyWithoutOrganizationInput>;
  twilio_numbers?: InputMaybe<TwilioNumberUpdateManyWithoutOrganizationInput>;
  TwilioFeedbacks?: InputMaybe<TwilioFeedbackUpdateManyWithoutOrganizationInput>;
  TwilioInfo?: InputMaybe<TwilioInfoUpdateOneWithoutOrganzationInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  use_revenue_planning?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  UserCustomFieldFilters?: InputMaybe<UserCustomFieldFilterUpdateManyWithoutOrganizationInput>;
  users?: InputMaybe<UserUpdateManyWithoutOrganizationInput>;
  win_rep_day_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
};

export type OrganizationUpdateWithoutSaleConfigInput = {
  ActivityMonitorMultiplier?: InputMaybe<ActivityMonitorMultiplierUpdateManyWithoutOrganizationInput>;
  algoFactor?: InputMaybe<AlgoFactorUpdateManyWithoutOrganizationInput>;
  allow_calendar_invite?: InputMaybe<BoolFieldUpdateOperationsInput>;
  allow_remove_step_action?: InputMaybe<BoolFieldUpdateOperationsInput>;
  allow_snooze_step_action?: InputMaybe<BoolFieldUpdateOperationsInput>;
  always_record?: InputMaybe<BoolFieldUpdateOperationsInput>;
  arv_initial_held_demos_generated_by_sdr_weight?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  AssociationHistories?: InputMaybe<AssociationHistoryUpdateManyWithoutOrganizationInput>;
  auto_call_interval?: InputMaybe<IntFieldUpdateOperationsInput>;
  auto_dial?: InputMaybe<BoolFieldUpdateOperationsInput>;
  automated_campaigns?: InputMaybe<AutomatedCampaignUpdateManyWithoutOrganizationInput>;
  automatic_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  AutoTransferSelections?: InputMaybe<AutoTransferSelectionUpdateManyWithoutOrganizationInput>;
  Availablities?: InputMaybe<AvailabilityUpdateManyWithoutOrganizationInput>;
  AverageOrderValue?: InputMaybe<AverageOrderValueUpdateManyWithoutOrganizationInput>;
  best_action_ever_multiplier?: InputMaybe<FloatFieldUpdateOperationsInput>;
  best_metrics?: InputMaybe<BestMetricRecordUpdateManyWithoutOrganizationInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptUpdateManyWithoutOrganizationInput>;
  callMeNows?: InputMaybe<CallMeNowUpdateManyWithoutOrganizationInput>;
  CallMultiplier?: InputMaybe<CallMultiplierUpdateManyWithoutOrganizationInput>;
  CallSharings?: InputMaybe<CallSharingUpdateManyWithoutOrganizationInput>;
  can_edit_sdr_transfer_rules?: InputMaybe<BoolFieldUpdateOperationsInput>;
  claim_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  concession?: InputMaybe<ConcessionUpdateManyWithoutOrganizationInput>;
  conference_region?: InputMaybe<EnumConferenceRegionFieldUpdateOperationsInput>;
  Conferences?: InputMaybe<ConferenceUpdateManyWithoutOrganizationInput>;
  ContactRequests?: InputMaybe<ContactRequestUpdateManyWithoutOrganizationInput>;
  Contacts?: InputMaybe<ContactUpdateManyWithoutOrganizationInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  custom_fields?: InputMaybe<CustomFieldUpdateManyWithoutOrganizationInput>;
  CustomObject?: InputMaybe<CustomObjectUpdateManyWithoutOrganizationInput>;
  CustomObjectField?: InputMaybe<CustomObjectFieldUpdateManyWithoutOrganizationInput>;
  CustomObjectFieldForeignKey?: InputMaybe<CustomObjectFieldForeignKeyUpdateManyWithoutOrganizationInput>;
  CustomObjectRecord?: InputMaybe<CustomObjectRecordUpdateManyWithoutOrganizationInput>;
  CustomObjectRow?: InputMaybe<CustomObjectRowUpdateManyWithoutOrganizationInput>;
  CustomQueueItems?: InputMaybe<CustomQueueItemUpdateManyWithoutOrganizationInput>;
  DashboardColumnOptions?: InputMaybe<DashboardColumnOptionUpdateManyWithoutOrganizationInput>;
  DashboardLeadFilters?: InputMaybe<DashboardLeadFilterUpdateManyWithoutOrganizationInput>;
  DashboardRepFilters?: InputMaybe<DashboardRepFilterUpdateManyWithoutOrganizationInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewUpdateManyWithoutOrganizationInput>;
  day_to_close?: InputMaybe<IntFieldUpdateOperationsInput>;
  default_demo_time_duration?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  demo_held_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_not_held_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_not_held_call_back_short_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_prep_time_minutes?: InputMaybe<IntFieldUpdateOperationsInput>;
  dev_account?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  dial_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  DispositionMultiplier?: InputMaybe<DispositionMultiplierUpdateManyWithoutOrganizationInput>;
  dispositions?: InputMaybe<DispositionUpdateManyWithoutOrganizationInput>;
  double_booking?: InputMaybe<BoolFieldUpdateOperationsInput>;
  dynamic_date_operators?: InputMaybe<BoolFieldUpdateOperationsInput>;
  edge_servers?: InputMaybe<OrganizationUpdateedge_ServersInput>;
  email_conflict_notification_email_list?: InputMaybe<OrganizationUpdateemail_Conflict_Notification_Email_ListInput>;
  EmailItems?: InputMaybe<EmailItemUpdateManyWithoutOrganizationInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordUpdateManyWithoutOrganizationInput>;
  EmailThreads?: InputMaybe<EmailThreadUpdateManyWithoutOrganizationInput>;
  enable_modified_metrics?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  enable_pay_period_view?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  enable_time_constraint?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  error_log_email_address?: InputMaybe<OrganizationUpdateerror_Log_Email_AddressInput>;
  estimated_average_num_of_weekly_overbooked_demo_slots?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  FolderPermissions?: InputMaybe<FolderPermissionUpdateManyWithoutOrganizationInput>;
  Folders?: InputMaybe<FolderUpdateManyWithoutOrganizationInput>;
  FutureBookingSetting?: InputMaybe<FutureBookingSettingUpdateOneWithoutOrganizationInput>;
  GlobalTransferRule?: InputMaybe<GlobalTransferRuleUpdateManyWithoutOrganizationInput>;
  held_demos_per_cc_connect?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  helpful_links?: InputMaybe<OrganizationUpdatehelpful_LinksInput>;
  HingePointMultiplier?: InputMaybe<HingePointMultiplierUpdateManyWithoutOrganizationInput>;
  hold_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
  hubspot_credential?: InputMaybe<HubSpotCredentialUpdateOneWithoutOrganizationInput>;
  hubspot_last_sync_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  hubspot_white_list?: InputMaybe<HubSpotWhitelistUpdateManyWithoutOrganizationInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inbound_close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_dm_connect_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_lt_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_revenue_value_of_sale?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_scheduled_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_set_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventUpdateManyWithoutOrganizationInput>;
  InboundConciergeWidget?: InputMaybe<InboundConciergeWidgetUpdateManyWithoutOrganizationInput>;
  industry_options?: InputMaybe<IndustryOptionUpdateManyWithoutOrganizationInput>;
  initial_sdr_sets_reps_day?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  integration_error_logs?: InputMaybe<IntegrationErrorLogUpdateManyWithoutOrganizationInput>;
  integration_mappings?: InputMaybe<IntegrationMappingUpdateManyWithoutOrganizationInput>;
  IntegrationMappingCustomObject?: InputMaybe<IntegrationMappingCustomObjectUpdateManyWithoutOrganizationInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogUpdateManyWithoutOrganizationInput>;
  IntegrationReadOnlyObject?: InputMaybe<IntegrationReadOnlyObjectUpdateManyWithoutOrganizationInput>;
  international?: InputMaybe<BoolFieldUpdateOperationsInput>;
  is_email_domain_verified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  is_salesforce_sandbox?: InputMaybe<BoolFieldUpdateOperationsInput>;
  last_lead_value_compute_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutOrganizationInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryUpdateManyWithoutOrganizationInput>;
  lead_intent?: InputMaybe<LeadIntentUpdateManyWithoutOrganizationInput>;
  lead_release_same_rep_delay_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  lead_routing_email_list?: InputMaybe<OrganizationUpdatelead_Routing_Email_ListInput>;
  lead_routing_rules?: InputMaybe<RuleUpdateManyWithoutOrganizationInput>;
  LeadCustomObjectRowAssociation?: InputMaybe<LeadCustomObjectRowAssociationUpdateManyWithoutOrganizationInput>;
  LeadFieldMultiplier?: InputMaybe<LeadFieldMultiplierUpdateManyWithoutOrganizationInput>;
  LeadFieldMultiplierCustom?: InputMaybe<LeadFieldMultiplierCustomUpdateManyWithoutOrganizationInput>;
  leads?: InputMaybe<LeadUpdateManyWithoutOrganizationInput>;
  leadsource_options?: InputMaybe<LeadSourceOptionUpdateManyWithoutOrganizationInput>;
  LeadValue?: InputMaybe<LeadValueUpdateManyWithoutOrganizationInput>;
  LifeTimeValueMultiplier?: InputMaybe<LifeTimeValueMultiplierUpdateManyWithoutOrganizationInput>;
  LiveTransferSetting?: InputMaybe<LiveTransferSettingUpdateOneWithoutOrganizationInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusUpdateManyWithoutOrganizationInput>;
  ltv_multiplier_custom_fields?: InputMaybe<LifeTimeValueMultiplierCustomUpdateManyWithoutOrganizationInput>;
  made_contact_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  make_sale_address_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_business_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_city_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_country_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_first_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_industry_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_last_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_lead_source_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_primary_email_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_primary_phone_number_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_state_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_sub_industry_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_zip_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  maxium_day_lag_set_to_schedule?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  mrr?: InputMaybe<IntFieldUpdateOperationsInput>;
  mrr_label?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  mrr_required_on_sale?: InputMaybe<BoolFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  NoteItems?: InputMaybe<NoteItemUpdateManyWithoutOrganizationInput>;
  Notifications?: InputMaybe<NotificationUpdateManyWithoutOrganizationInput>;
  onboardComplete?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  Opportunity?: InputMaybe<SfOpportunityUpdateManyWithoutOrganizationInput>;
  organization_holidays?: InputMaybe<OrganizationHolidayUpdateManyWithoutOrganizationInput>;
  organization_hours?: InputMaybe<OrganizationHourUpdateManyWithoutOrganizationInput>;
  OrganizationPaymentSetting?: InputMaybe<OrganizationPaymentSettingsUpdateOneWithoutOrganizationInput>;
  OrganizationPaymentSettings?: InputMaybe<OrganizationPaymentSettingsUpdateOneWithoutOrganizationsInput>;
  orgCalendarInviteMetadata?: InputMaybe<OrgCalendarInviteMetadataUpdateManyWithoutOrganizationInput>;
  OrgDefaultPipelinePerecentValue?: InputMaybe<OrgDefaultPipelinePerecentValueUpdateOneWithoutOrganizationInput>;
  OrgHubSpotSync?: InputMaybe<OrgHubSpotSyncUpdateOneWithoutOrganizationInput>;
  OrgNoEventDate?: InputMaybe<OrgNoEventDateUpdateManyWithoutOrganizationInput>;
  OrgSalesforceSync?: InputMaybe<OrgSalesforceSyncUpdateOneWithoutOrganizationInput>;
  OrgTimeslotAvailability?: InputMaybe<OrgTimeslotAvailabilityUpdateManyWithoutOrganizationInput>;
  outbound_close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_dm_connect_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_lt_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_revenue_value_of_sale?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_scheduled_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_set_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  PandadocData?: InputMaybe<PandadocDataUpdateOneWithoutOrganizationInput>;
  PandaDocTemplate?: InputMaybe<PandaDocTemplateUpdateManyWithoutOrganizationInput>;
  participant_jitter_buffer_size?: InputMaybe<EnumJitterBufferSizeFieldUpdateOperationsInput>;
  preferred_goal_setting?: InputMaybe<NullableEnumGoalsettingFieldUpdateOperationsInput>;
  prepayment?: InputMaybe<BoolFieldUpdateOperationsInput>;
  ProductChangeLog?: InputMaybe<ProductChangeLogUpdateManyWithoutOrganizationInput>;
  products?: InputMaybe<ProductUpdateManyWithoutOrganizationInput>;
  re_cold_call_hour_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  recordingStatus?: InputMaybe<EnumRecordingStatusFieldUpdateOperationsInput>;
  release_lead_resting?: InputMaybe<IntFieldUpdateOperationsInput>;
  rep_claim_limit?: InputMaybe<IntFieldUpdateOperationsInput>;
  rep_lead_allotment?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  rep_level_call_transfer?: InputMaybe<BoolFieldUpdateOperationsInput>;
  reps_can_bulk_unassign?: InputMaybe<BoolFieldUpdateOperationsInput>;
  reps_can_view_all_notes?: InputMaybe<BoolFieldUpdateOperationsInput>;
  require_sale_notes?: InputMaybe<BoolFieldUpdateOperationsInput>;
  resting_after_skipped_CC?: InputMaybe<IntFieldUpdateOperationsInput>;
  resting_period?: InputMaybe<IntFieldUpdateOperationsInput>;
  resting_period_after_unsuccessful_cold_call?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  resting_period_after_unsuccessful_demo?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  revenue_plan?: InputMaybe<RevenuePlanItemUpdateManyWithoutOrganizationInput>;
  Routings?: InputMaybe<RoutingUpdateManyWithoutOrganizationInput>;
  sale_field_options?: InputMaybe<SaleFieldOptionUpdateManyWithoutOrganizationInput>;
  sale_notification_email_list?: InputMaybe<OrganizationUpdatesale_Notification_Email_ListInput>;
  SaleConfigPage?: InputMaybe<SaleConfigPageUpdateManyWithoutOrganizationInput>;
  SaleConfigSection?: InputMaybe<SaleConfigSectionUpdateManyWithoutOrganizationInput>;
  SaleCycles?: InputMaybe<SaleCycleUpdateManyWithoutOrganizationInput>;
  SaleItems?: InputMaybe<SaleItemUpdateManyWithoutOrganizationInput>;
  sales?: InputMaybe<SaleUpdateManyWithoutOrganizationInput>;
  salesforce_domain?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  salesforce_last_sync_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  SalesForceColumn?: InputMaybe<SalesForceColumnUpdateManyWithoutOrganizationInput>;
  salesForceData?: InputMaybe<SalesForceDataUpdateOneWithoutOrganizationInput>;
  ScheduleItems?: InputMaybe<ScheduleItemUpdateManyWithoutOrganizationInput>;
  self_sourcing_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  send_error_log_email?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  send_lead_routing_email?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sendgrid_email_domain?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sendgrid_email_domain_id?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  sequence?: InputMaybe<SequenceUpdateManyWithoutOrganzationInput>;
  SequenceCriteriaConditions?: InputMaybe<SequenceCriteriaConditionUpdateManyWithoutOrganizationInput>;
  SequenceDashboardViews?: InputMaybe<SequenceDashboardViewUpdateManyWithoutOrganizationInput>;
  SequenceEntryCriterias?: InputMaybe<SequenceEntryCriteriaUpdateManyWithoutOrganizationInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutOrganizationInput>;
  SequenceSteps?: InputMaybe<SequenceStepUpdateManyWithoutOrganizationInput>;
  set_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
  short_resting?: InputMaybe<IntFieldUpdateOperationsInput>;
  show_calendar_options?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_contract_duration_sales?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_lead_source_to_rep?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_nocontact_button?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_payment_terms_sales?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_rep_all_leads?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_rep_my_leads?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_system_view?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sites?: InputMaybe<SiteUpdateManyWithoutOrganizationInput>;
  sms_opt_in_dialog?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SMSItems?: InputMaybe<SmsItemUpdateManyWithoutOrganizationInput>;
  SnoozeTrackings?: InputMaybe<SnoozeTrackingUpdateManyWithoutOrganizationInput>;
  StepActionLogs?: InputMaybe<StepActionLogUpdateManyWithoutOrganizationInput>;
  StepActions?: InputMaybe<StepActionUpdateManyWithoutOrganizationInput>;
  StepConditions?: InputMaybe<StepConditionUpdateManyWithoutOrganizationInput>;
  teams?: InputMaybe<TeamUpdateManyWithoutOrganizationInput>;
  Template?: InputMaybe<TemplateUpdateManyWithoutOrganizationInput>;
  TemplateVersions?: InputMaybe<TemplateVersionUpdateManyWithoutOrganizationInput>;
  timekit_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timezone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transfer_timeout?: InputMaybe<IntFieldUpdateOperationsInput>;
  TransferAttempts?: InputMaybe<TransferAttemptUpdateManyWithoutOrganizationInput>;
  twilio_numbers?: InputMaybe<TwilioNumberUpdateManyWithoutOrganizationInput>;
  TwilioFeedbacks?: InputMaybe<TwilioFeedbackUpdateManyWithoutOrganizationInput>;
  TwilioInfo?: InputMaybe<TwilioInfoUpdateOneWithoutOrganzationInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  use_revenue_planning?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  UserCustomFieldFilters?: InputMaybe<UserCustomFieldFilterUpdateManyWithoutOrganizationInput>;
  users?: InputMaybe<UserUpdateManyWithoutOrganizationInput>;
  win_rep_day_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
};

export type OrganizationUpdateWithoutSaleConfigPageInput = {
  ActivityMonitorMultiplier?: InputMaybe<ActivityMonitorMultiplierUpdateManyWithoutOrganizationInput>;
  algoFactor?: InputMaybe<AlgoFactorUpdateManyWithoutOrganizationInput>;
  allow_calendar_invite?: InputMaybe<BoolFieldUpdateOperationsInput>;
  allow_remove_step_action?: InputMaybe<BoolFieldUpdateOperationsInput>;
  allow_snooze_step_action?: InputMaybe<BoolFieldUpdateOperationsInput>;
  always_record?: InputMaybe<BoolFieldUpdateOperationsInput>;
  arv_initial_held_demos_generated_by_sdr_weight?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  AssociationHistories?: InputMaybe<AssociationHistoryUpdateManyWithoutOrganizationInput>;
  auto_call_interval?: InputMaybe<IntFieldUpdateOperationsInput>;
  auto_dial?: InputMaybe<BoolFieldUpdateOperationsInput>;
  automated_campaigns?: InputMaybe<AutomatedCampaignUpdateManyWithoutOrganizationInput>;
  automatic_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  AutoTransferSelections?: InputMaybe<AutoTransferSelectionUpdateManyWithoutOrganizationInput>;
  Availablities?: InputMaybe<AvailabilityUpdateManyWithoutOrganizationInput>;
  AverageOrderValue?: InputMaybe<AverageOrderValueUpdateManyWithoutOrganizationInput>;
  best_action_ever_multiplier?: InputMaybe<FloatFieldUpdateOperationsInput>;
  best_metrics?: InputMaybe<BestMetricRecordUpdateManyWithoutOrganizationInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptUpdateManyWithoutOrganizationInput>;
  callMeNows?: InputMaybe<CallMeNowUpdateManyWithoutOrganizationInput>;
  CallMultiplier?: InputMaybe<CallMultiplierUpdateManyWithoutOrganizationInput>;
  CallSharings?: InputMaybe<CallSharingUpdateManyWithoutOrganizationInput>;
  can_edit_sdr_transfer_rules?: InputMaybe<BoolFieldUpdateOperationsInput>;
  claim_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  concession?: InputMaybe<ConcessionUpdateManyWithoutOrganizationInput>;
  conference_region?: InputMaybe<EnumConferenceRegionFieldUpdateOperationsInput>;
  Conferences?: InputMaybe<ConferenceUpdateManyWithoutOrganizationInput>;
  ContactRequests?: InputMaybe<ContactRequestUpdateManyWithoutOrganizationInput>;
  Contacts?: InputMaybe<ContactUpdateManyWithoutOrganizationInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  custom_fields?: InputMaybe<CustomFieldUpdateManyWithoutOrganizationInput>;
  CustomObject?: InputMaybe<CustomObjectUpdateManyWithoutOrganizationInput>;
  CustomObjectField?: InputMaybe<CustomObjectFieldUpdateManyWithoutOrganizationInput>;
  CustomObjectFieldForeignKey?: InputMaybe<CustomObjectFieldForeignKeyUpdateManyWithoutOrganizationInput>;
  CustomObjectRecord?: InputMaybe<CustomObjectRecordUpdateManyWithoutOrganizationInput>;
  CustomObjectRow?: InputMaybe<CustomObjectRowUpdateManyWithoutOrganizationInput>;
  CustomQueueItems?: InputMaybe<CustomQueueItemUpdateManyWithoutOrganizationInput>;
  DashboardColumnOptions?: InputMaybe<DashboardColumnOptionUpdateManyWithoutOrganizationInput>;
  DashboardLeadFilters?: InputMaybe<DashboardLeadFilterUpdateManyWithoutOrganizationInput>;
  DashboardRepFilters?: InputMaybe<DashboardRepFilterUpdateManyWithoutOrganizationInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewUpdateManyWithoutOrganizationInput>;
  day_to_close?: InputMaybe<IntFieldUpdateOperationsInput>;
  default_demo_time_duration?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  demo_held_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_not_held_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_not_held_call_back_short_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_prep_time_minutes?: InputMaybe<IntFieldUpdateOperationsInput>;
  dev_account?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  dial_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  DispositionMultiplier?: InputMaybe<DispositionMultiplierUpdateManyWithoutOrganizationInput>;
  dispositions?: InputMaybe<DispositionUpdateManyWithoutOrganizationInput>;
  double_booking?: InputMaybe<BoolFieldUpdateOperationsInput>;
  dynamic_date_operators?: InputMaybe<BoolFieldUpdateOperationsInput>;
  edge_servers?: InputMaybe<OrganizationUpdateedge_ServersInput>;
  email_conflict_notification_email_list?: InputMaybe<OrganizationUpdateemail_Conflict_Notification_Email_ListInput>;
  EmailItems?: InputMaybe<EmailItemUpdateManyWithoutOrganizationInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordUpdateManyWithoutOrganizationInput>;
  EmailThreads?: InputMaybe<EmailThreadUpdateManyWithoutOrganizationInput>;
  enable_modified_metrics?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  enable_pay_period_view?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  enable_time_constraint?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  error_log_email_address?: InputMaybe<OrganizationUpdateerror_Log_Email_AddressInput>;
  estimated_average_num_of_weekly_overbooked_demo_slots?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  FolderPermissions?: InputMaybe<FolderPermissionUpdateManyWithoutOrganizationInput>;
  Folders?: InputMaybe<FolderUpdateManyWithoutOrganizationInput>;
  FutureBookingSetting?: InputMaybe<FutureBookingSettingUpdateOneWithoutOrganizationInput>;
  GlobalTransferRule?: InputMaybe<GlobalTransferRuleUpdateManyWithoutOrganizationInput>;
  held_demos_per_cc_connect?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  helpful_links?: InputMaybe<OrganizationUpdatehelpful_LinksInput>;
  HingePointMultiplier?: InputMaybe<HingePointMultiplierUpdateManyWithoutOrganizationInput>;
  hold_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
  hubspot_credential?: InputMaybe<HubSpotCredentialUpdateOneWithoutOrganizationInput>;
  hubspot_last_sync_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  hubspot_white_list?: InputMaybe<HubSpotWhitelistUpdateManyWithoutOrganizationInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inbound_close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_dm_connect_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_lt_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_revenue_value_of_sale?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_scheduled_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_set_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventUpdateManyWithoutOrganizationInput>;
  InboundConciergeWidget?: InputMaybe<InboundConciergeWidgetUpdateManyWithoutOrganizationInput>;
  industry_options?: InputMaybe<IndustryOptionUpdateManyWithoutOrganizationInput>;
  initial_sdr_sets_reps_day?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  integration_error_logs?: InputMaybe<IntegrationErrorLogUpdateManyWithoutOrganizationInput>;
  integration_mappings?: InputMaybe<IntegrationMappingUpdateManyWithoutOrganizationInput>;
  IntegrationMappingCustomObject?: InputMaybe<IntegrationMappingCustomObjectUpdateManyWithoutOrganizationInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogUpdateManyWithoutOrganizationInput>;
  IntegrationReadOnlyObject?: InputMaybe<IntegrationReadOnlyObjectUpdateManyWithoutOrganizationInput>;
  international?: InputMaybe<BoolFieldUpdateOperationsInput>;
  is_email_domain_verified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  is_salesforce_sandbox?: InputMaybe<BoolFieldUpdateOperationsInput>;
  last_lead_value_compute_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutOrganizationInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryUpdateManyWithoutOrganizationInput>;
  lead_intent?: InputMaybe<LeadIntentUpdateManyWithoutOrganizationInput>;
  lead_release_same_rep_delay_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  lead_routing_email_list?: InputMaybe<OrganizationUpdatelead_Routing_Email_ListInput>;
  lead_routing_rules?: InputMaybe<RuleUpdateManyWithoutOrganizationInput>;
  LeadCustomObjectRowAssociation?: InputMaybe<LeadCustomObjectRowAssociationUpdateManyWithoutOrganizationInput>;
  LeadFieldMultiplier?: InputMaybe<LeadFieldMultiplierUpdateManyWithoutOrganizationInput>;
  LeadFieldMultiplierCustom?: InputMaybe<LeadFieldMultiplierCustomUpdateManyWithoutOrganizationInput>;
  leads?: InputMaybe<LeadUpdateManyWithoutOrganizationInput>;
  leadsource_options?: InputMaybe<LeadSourceOptionUpdateManyWithoutOrganizationInput>;
  LeadValue?: InputMaybe<LeadValueUpdateManyWithoutOrganizationInput>;
  LifeTimeValueMultiplier?: InputMaybe<LifeTimeValueMultiplierUpdateManyWithoutOrganizationInput>;
  LiveTransferSetting?: InputMaybe<LiveTransferSettingUpdateOneWithoutOrganizationInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusUpdateManyWithoutOrganizationInput>;
  ltv_multiplier_custom_fields?: InputMaybe<LifeTimeValueMultiplierCustomUpdateManyWithoutOrganizationInput>;
  made_contact_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  make_sale_address_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_business_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_city_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_country_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_first_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_industry_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_last_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_lead_source_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_primary_email_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_primary_phone_number_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_state_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_sub_industry_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_zip_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  maxium_day_lag_set_to_schedule?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  mrr?: InputMaybe<IntFieldUpdateOperationsInput>;
  mrr_label?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  mrr_required_on_sale?: InputMaybe<BoolFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  NoteItems?: InputMaybe<NoteItemUpdateManyWithoutOrganizationInput>;
  Notifications?: InputMaybe<NotificationUpdateManyWithoutOrganizationInput>;
  onboardComplete?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  Opportunity?: InputMaybe<SfOpportunityUpdateManyWithoutOrganizationInput>;
  organization_holidays?: InputMaybe<OrganizationHolidayUpdateManyWithoutOrganizationInput>;
  organization_hours?: InputMaybe<OrganizationHourUpdateManyWithoutOrganizationInput>;
  OrganizationPaymentSetting?: InputMaybe<OrganizationPaymentSettingsUpdateOneWithoutOrganizationInput>;
  OrganizationPaymentSettings?: InputMaybe<OrganizationPaymentSettingsUpdateOneWithoutOrganizationsInput>;
  orgCalendarInviteMetadata?: InputMaybe<OrgCalendarInviteMetadataUpdateManyWithoutOrganizationInput>;
  OrgDefaultPipelinePerecentValue?: InputMaybe<OrgDefaultPipelinePerecentValueUpdateOneWithoutOrganizationInput>;
  OrgHubSpotSync?: InputMaybe<OrgHubSpotSyncUpdateOneWithoutOrganizationInput>;
  OrgNoEventDate?: InputMaybe<OrgNoEventDateUpdateManyWithoutOrganizationInput>;
  OrgSalesforceSync?: InputMaybe<OrgSalesforceSyncUpdateOneWithoutOrganizationInput>;
  OrgTimeslotAvailability?: InputMaybe<OrgTimeslotAvailabilityUpdateManyWithoutOrganizationInput>;
  outbound_close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_dm_connect_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_lt_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_revenue_value_of_sale?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_scheduled_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_set_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  PandadocData?: InputMaybe<PandadocDataUpdateOneWithoutOrganizationInput>;
  PandaDocTemplate?: InputMaybe<PandaDocTemplateUpdateManyWithoutOrganizationInput>;
  participant_jitter_buffer_size?: InputMaybe<EnumJitterBufferSizeFieldUpdateOperationsInput>;
  preferred_goal_setting?: InputMaybe<NullableEnumGoalsettingFieldUpdateOperationsInput>;
  prepayment?: InputMaybe<BoolFieldUpdateOperationsInput>;
  ProductChangeLog?: InputMaybe<ProductChangeLogUpdateManyWithoutOrganizationInput>;
  products?: InputMaybe<ProductUpdateManyWithoutOrganizationInput>;
  re_cold_call_hour_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  recordingStatus?: InputMaybe<EnumRecordingStatusFieldUpdateOperationsInput>;
  release_lead_resting?: InputMaybe<IntFieldUpdateOperationsInput>;
  rep_claim_limit?: InputMaybe<IntFieldUpdateOperationsInput>;
  rep_lead_allotment?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  rep_level_call_transfer?: InputMaybe<BoolFieldUpdateOperationsInput>;
  reps_can_bulk_unassign?: InputMaybe<BoolFieldUpdateOperationsInput>;
  reps_can_view_all_notes?: InputMaybe<BoolFieldUpdateOperationsInput>;
  require_sale_notes?: InputMaybe<BoolFieldUpdateOperationsInput>;
  resting_after_skipped_CC?: InputMaybe<IntFieldUpdateOperationsInput>;
  resting_period?: InputMaybe<IntFieldUpdateOperationsInput>;
  resting_period_after_unsuccessful_cold_call?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  resting_period_after_unsuccessful_demo?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  revenue_plan?: InputMaybe<RevenuePlanItemUpdateManyWithoutOrganizationInput>;
  Routings?: InputMaybe<RoutingUpdateManyWithoutOrganizationInput>;
  sale_field_options?: InputMaybe<SaleFieldOptionUpdateManyWithoutOrganizationInput>;
  sale_notification_email_list?: InputMaybe<OrganizationUpdatesale_Notification_Email_ListInput>;
  SaleConfig?: InputMaybe<SaleConfigUpdateOneWithoutOrganizationInput>;
  SaleConfigSection?: InputMaybe<SaleConfigSectionUpdateManyWithoutOrganizationInput>;
  SaleCycles?: InputMaybe<SaleCycleUpdateManyWithoutOrganizationInput>;
  SaleItems?: InputMaybe<SaleItemUpdateManyWithoutOrganizationInput>;
  sales?: InputMaybe<SaleUpdateManyWithoutOrganizationInput>;
  salesforce_domain?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  salesforce_last_sync_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  SalesForceColumn?: InputMaybe<SalesForceColumnUpdateManyWithoutOrganizationInput>;
  salesForceData?: InputMaybe<SalesForceDataUpdateOneWithoutOrganizationInput>;
  ScheduleItems?: InputMaybe<ScheduleItemUpdateManyWithoutOrganizationInput>;
  self_sourcing_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  send_error_log_email?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  send_lead_routing_email?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sendgrid_email_domain?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sendgrid_email_domain_id?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  sequence?: InputMaybe<SequenceUpdateManyWithoutOrganzationInput>;
  SequenceCriteriaConditions?: InputMaybe<SequenceCriteriaConditionUpdateManyWithoutOrganizationInput>;
  SequenceDashboardViews?: InputMaybe<SequenceDashboardViewUpdateManyWithoutOrganizationInput>;
  SequenceEntryCriterias?: InputMaybe<SequenceEntryCriteriaUpdateManyWithoutOrganizationInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutOrganizationInput>;
  SequenceSteps?: InputMaybe<SequenceStepUpdateManyWithoutOrganizationInput>;
  set_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
  short_resting?: InputMaybe<IntFieldUpdateOperationsInput>;
  show_calendar_options?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_contract_duration_sales?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_lead_source_to_rep?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_nocontact_button?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_payment_terms_sales?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_rep_all_leads?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_rep_my_leads?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_system_view?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sites?: InputMaybe<SiteUpdateManyWithoutOrganizationInput>;
  sms_opt_in_dialog?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SMSItems?: InputMaybe<SmsItemUpdateManyWithoutOrganizationInput>;
  SnoozeTrackings?: InputMaybe<SnoozeTrackingUpdateManyWithoutOrganizationInput>;
  StepActionLogs?: InputMaybe<StepActionLogUpdateManyWithoutOrganizationInput>;
  StepActions?: InputMaybe<StepActionUpdateManyWithoutOrganizationInput>;
  StepConditions?: InputMaybe<StepConditionUpdateManyWithoutOrganizationInput>;
  teams?: InputMaybe<TeamUpdateManyWithoutOrganizationInput>;
  Template?: InputMaybe<TemplateUpdateManyWithoutOrganizationInput>;
  TemplateVersions?: InputMaybe<TemplateVersionUpdateManyWithoutOrganizationInput>;
  timekit_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timezone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transfer_timeout?: InputMaybe<IntFieldUpdateOperationsInput>;
  TransferAttempts?: InputMaybe<TransferAttemptUpdateManyWithoutOrganizationInput>;
  twilio_numbers?: InputMaybe<TwilioNumberUpdateManyWithoutOrganizationInput>;
  TwilioFeedbacks?: InputMaybe<TwilioFeedbackUpdateManyWithoutOrganizationInput>;
  TwilioInfo?: InputMaybe<TwilioInfoUpdateOneWithoutOrganzationInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  use_revenue_planning?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  UserCustomFieldFilters?: InputMaybe<UserCustomFieldFilterUpdateManyWithoutOrganizationInput>;
  users?: InputMaybe<UserUpdateManyWithoutOrganizationInput>;
  win_rep_day_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
};

export type OrganizationUpdateWithoutSaleConfigSectionInput = {
  ActivityMonitorMultiplier?: InputMaybe<ActivityMonitorMultiplierUpdateManyWithoutOrganizationInput>;
  algoFactor?: InputMaybe<AlgoFactorUpdateManyWithoutOrganizationInput>;
  allow_calendar_invite?: InputMaybe<BoolFieldUpdateOperationsInput>;
  allow_remove_step_action?: InputMaybe<BoolFieldUpdateOperationsInput>;
  allow_snooze_step_action?: InputMaybe<BoolFieldUpdateOperationsInput>;
  always_record?: InputMaybe<BoolFieldUpdateOperationsInput>;
  arv_initial_held_demos_generated_by_sdr_weight?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  AssociationHistories?: InputMaybe<AssociationHistoryUpdateManyWithoutOrganizationInput>;
  auto_call_interval?: InputMaybe<IntFieldUpdateOperationsInput>;
  auto_dial?: InputMaybe<BoolFieldUpdateOperationsInput>;
  automated_campaigns?: InputMaybe<AutomatedCampaignUpdateManyWithoutOrganizationInput>;
  automatic_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  AutoTransferSelections?: InputMaybe<AutoTransferSelectionUpdateManyWithoutOrganizationInput>;
  Availablities?: InputMaybe<AvailabilityUpdateManyWithoutOrganizationInput>;
  AverageOrderValue?: InputMaybe<AverageOrderValueUpdateManyWithoutOrganizationInput>;
  best_action_ever_multiplier?: InputMaybe<FloatFieldUpdateOperationsInput>;
  best_metrics?: InputMaybe<BestMetricRecordUpdateManyWithoutOrganizationInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptUpdateManyWithoutOrganizationInput>;
  callMeNows?: InputMaybe<CallMeNowUpdateManyWithoutOrganizationInput>;
  CallMultiplier?: InputMaybe<CallMultiplierUpdateManyWithoutOrganizationInput>;
  CallSharings?: InputMaybe<CallSharingUpdateManyWithoutOrganizationInput>;
  can_edit_sdr_transfer_rules?: InputMaybe<BoolFieldUpdateOperationsInput>;
  claim_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  concession?: InputMaybe<ConcessionUpdateManyWithoutOrganizationInput>;
  conference_region?: InputMaybe<EnumConferenceRegionFieldUpdateOperationsInput>;
  Conferences?: InputMaybe<ConferenceUpdateManyWithoutOrganizationInput>;
  ContactRequests?: InputMaybe<ContactRequestUpdateManyWithoutOrganizationInput>;
  Contacts?: InputMaybe<ContactUpdateManyWithoutOrganizationInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  custom_fields?: InputMaybe<CustomFieldUpdateManyWithoutOrganizationInput>;
  CustomObject?: InputMaybe<CustomObjectUpdateManyWithoutOrganizationInput>;
  CustomObjectField?: InputMaybe<CustomObjectFieldUpdateManyWithoutOrganizationInput>;
  CustomObjectFieldForeignKey?: InputMaybe<CustomObjectFieldForeignKeyUpdateManyWithoutOrganizationInput>;
  CustomObjectRecord?: InputMaybe<CustomObjectRecordUpdateManyWithoutOrganizationInput>;
  CustomObjectRow?: InputMaybe<CustomObjectRowUpdateManyWithoutOrganizationInput>;
  CustomQueueItems?: InputMaybe<CustomQueueItemUpdateManyWithoutOrganizationInput>;
  DashboardColumnOptions?: InputMaybe<DashboardColumnOptionUpdateManyWithoutOrganizationInput>;
  DashboardLeadFilters?: InputMaybe<DashboardLeadFilterUpdateManyWithoutOrganizationInput>;
  DashboardRepFilters?: InputMaybe<DashboardRepFilterUpdateManyWithoutOrganizationInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewUpdateManyWithoutOrganizationInput>;
  day_to_close?: InputMaybe<IntFieldUpdateOperationsInput>;
  default_demo_time_duration?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  demo_held_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_not_held_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_not_held_call_back_short_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_prep_time_minutes?: InputMaybe<IntFieldUpdateOperationsInput>;
  dev_account?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  dial_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  DispositionMultiplier?: InputMaybe<DispositionMultiplierUpdateManyWithoutOrganizationInput>;
  dispositions?: InputMaybe<DispositionUpdateManyWithoutOrganizationInput>;
  double_booking?: InputMaybe<BoolFieldUpdateOperationsInput>;
  dynamic_date_operators?: InputMaybe<BoolFieldUpdateOperationsInput>;
  edge_servers?: InputMaybe<OrganizationUpdateedge_ServersInput>;
  email_conflict_notification_email_list?: InputMaybe<OrganizationUpdateemail_Conflict_Notification_Email_ListInput>;
  EmailItems?: InputMaybe<EmailItemUpdateManyWithoutOrganizationInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordUpdateManyWithoutOrganizationInput>;
  EmailThreads?: InputMaybe<EmailThreadUpdateManyWithoutOrganizationInput>;
  enable_modified_metrics?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  enable_pay_period_view?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  enable_time_constraint?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  error_log_email_address?: InputMaybe<OrganizationUpdateerror_Log_Email_AddressInput>;
  estimated_average_num_of_weekly_overbooked_demo_slots?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  FolderPermissions?: InputMaybe<FolderPermissionUpdateManyWithoutOrganizationInput>;
  Folders?: InputMaybe<FolderUpdateManyWithoutOrganizationInput>;
  FutureBookingSetting?: InputMaybe<FutureBookingSettingUpdateOneWithoutOrganizationInput>;
  GlobalTransferRule?: InputMaybe<GlobalTransferRuleUpdateManyWithoutOrganizationInput>;
  held_demos_per_cc_connect?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  helpful_links?: InputMaybe<OrganizationUpdatehelpful_LinksInput>;
  HingePointMultiplier?: InputMaybe<HingePointMultiplierUpdateManyWithoutOrganizationInput>;
  hold_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
  hubspot_credential?: InputMaybe<HubSpotCredentialUpdateOneWithoutOrganizationInput>;
  hubspot_last_sync_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  hubspot_white_list?: InputMaybe<HubSpotWhitelistUpdateManyWithoutOrganizationInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inbound_close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_dm_connect_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_lt_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_revenue_value_of_sale?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_scheduled_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_set_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventUpdateManyWithoutOrganizationInput>;
  InboundConciergeWidget?: InputMaybe<InboundConciergeWidgetUpdateManyWithoutOrganizationInput>;
  industry_options?: InputMaybe<IndustryOptionUpdateManyWithoutOrganizationInput>;
  initial_sdr_sets_reps_day?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  integration_error_logs?: InputMaybe<IntegrationErrorLogUpdateManyWithoutOrganizationInput>;
  integration_mappings?: InputMaybe<IntegrationMappingUpdateManyWithoutOrganizationInput>;
  IntegrationMappingCustomObject?: InputMaybe<IntegrationMappingCustomObjectUpdateManyWithoutOrganizationInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogUpdateManyWithoutOrganizationInput>;
  IntegrationReadOnlyObject?: InputMaybe<IntegrationReadOnlyObjectUpdateManyWithoutOrganizationInput>;
  international?: InputMaybe<BoolFieldUpdateOperationsInput>;
  is_email_domain_verified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  is_salesforce_sandbox?: InputMaybe<BoolFieldUpdateOperationsInput>;
  last_lead_value_compute_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutOrganizationInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryUpdateManyWithoutOrganizationInput>;
  lead_intent?: InputMaybe<LeadIntentUpdateManyWithoutOrganizationInput>;
  lead_release_same_rep_delay_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  lead_routing_email_list?: InputMaybe<OrganizationUpdatelead_Routing_Email_ListInput>;
  lead_routing_rules?: InputMaybe<RuleUpdateManyWithoutOrganizationInput>;
  LeadCustomObjectRowAssociation?: InputMaybe<LeadCustomObjectRowAssociationUpdateManyWithoutOrganizationInput>;
  LeadFieldMultiplier?: InputMaybe<LeadFieldMultiplierUpdateManyWithoutOrganizationInput>;
  LeadFieldMultiplierCustom?: InputMaybe<LeadFieldMultiplierCustomUpdateManyWithoutOrganizationInput>;
  leads?: InputMaybe<LeadUpdateManyWithoutOrganizationInput>;
  leadsource_options?: InputMaybe<LeadSourceOptionUpdateManyWithoutOrganizationInput>;
  LeadValue?: InputMaybe<LeadValueUpdateManyWithoutOrganizationInput>;
  LifeTimeValueMultiplier?: InputMaybe<LifeTimeValueMultiplierUpdateManyWithoutOrganizationInput>;
  LiveTransferSetting?: InputMaybe<LiveTransferSettingUpdateOneWithoutOrganizationInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusUpdateManyWithoutOrganizationInput>;
  ltv_multiplier_custom_fields?: InputMaybe<LifeTimeValueMultiplierCustomUpdateManyWithoutOrganizationInput>;
  made_contact_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  make_sale_address_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_business_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_city_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_country_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_first_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_industry_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_last_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_lead_source_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_primary_email_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_primary_phone_number_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_state_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_sub_industry_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_zip_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  maxium_day_lag_set_to_schedule?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  mrr?: InputMaybe<IntFieldUpdateOperationsInput>;
  mrr_label?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  mrr_required_on_sale?: InputMaybe<BoolFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  NoteItems?: InputMaybe<NoteItemUpdateManyWithoutOrganizationInput>;
  Notifications?: InputMaybe<NotificationUpdateManyWithoutOrganizationInput>;
  onboardComplete?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  Opportunity?: InputMaybe<SfOpportunityUpdateManyWithoutOrganizationInput>;
  organization_holidays?: InputMaybe<OrganizationHolidayUpdateManyWithoutOrganizationInput>;
  organization_hours?: InputMaybe<OrganizationHourUpdateManyWithoutOrganizationInput>;
  OrganizationPaymentSetting?: InputMaybe<OrganizationPaymentSettingsUpdateOneWithoutOrganizationInput>;
  OrganizationPaymentSettings?: InputMaybe<OrganizationPaymentSettingsUpdateOneWithoutOrganizationsInput>;
  orgCalendarInviteMetadata?: InputMaybe<OrgCalendarInviteMetadataUpdateManyWithoutOrganizationInput>;
  OrgDefaultPipelinePerecentValue?: InputMaybe<OrgDefaultPipelinePerecentValueUpdateOneWithoutOrganizationInput>;
  OrgHubSpotSync?: InputMaybe<OrgHubSpotSyncUpdateOneWithoutOrganizationInput>;
  OrgNoEventDate?: InputMaybe<OrgNoEventDateUpdateManyWithoutOrganizationInput>;
  OrgSalesforceSync?: InputMaybe<OrgSalesforceSyncUpdateOneWithoutOrganizationInput>;
  OrgTimeslotAvailability?: InputMaybe<OrgTimeslotAvailabilityUpdateManyWithoutOrganizationInput>;
  outbound_close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_dm_connect_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_lt_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_revenue_value_of_sale?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_scheduled_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_set_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  PandadocData?: InputMaybe<PandadocDataUpdateOneWithoutOrganizationInput>;
  PandaDocTemplate?: InputMaybe<PandaDocTemplateUpdateManyWithoutOrganizationInput>;
  participant_jitter_buffer_size?: InputMaybe<EnumJitterBufferSizeFieldUpdateOperationsInput>;
  preferred_goal_setting?: InputMaybe<NullableEnumGoalsettingFieldUpdateOperationsInput>;
  prepayment?: InputMaybe<BoolFieldUpdateOperationsInput>;
  ProductChangeLog?: InputMaybe<ProductChangeLogUpdateManyWithoutOrganizationInput>;
  products?: InputMaybe<ProductUpdateManyWithoutOrganizationInput>;
  re_cold_call_hour_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  recordingStatus?: InputMaybe<EnumRecordingStatusFieldUpdateOperationsInput>;
  release_lead_resting?: InputMaybe<IntFieldUpdateOperationsInput>;
  rep_claim_limit?: InputMaybe<IntFieldUpdateOperationsInput>;
  rep_lead_allotment?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  rep_level_call_transfer?: InputMaybe<BoolFieldUpdateOperationsInput>;
  reps_can_bulk_unassign?: InputMaybe<BoolFieldUpdateOperationsInput>;
  reps_can_view_all_notes?: InputMaybe<BoolFieldUpdateOperationsInput>;
  require_sale_notes?: InputMaybe<BoolFieldUpdateOperationsInput>;
  resting_after_skipped_CC?: InputMaybe<IntFieldUpdateOperationsInput>;
  resting_period?: InputMaybe<IntFieldUpdateOperationsInput>;
  resting_period_after_unsuccessful_cold_call?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  resting_period_after_unsuccessful_demo?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  revenue_plan?: InputMaybe<RevenuePlanItemUpdateManyWithoutOrganizationInput>;
  Routings?: InputMaybe<RoutingUpdateManyWithoutOrganizationInput>;
  sale_field_options?: InputMaybe<SaleFieldOptionUpdateManyWithoutOrganizationInput>;
  sale_notification_email_list?: InputMaybe<OrganizationUpdatesale_Notification_Email_ListInput>;
  SaleConfig?: InputMaybe<SaleConfigUpdateOneWithoutOrganizationInput>;
  SaleConfigPage?: InputMaybe<SaleConfigPageUpdateManyWithoutOrganizationInput>;
  SaleCycles?: InputMaybe<SaleCycleUpdateManyWithoutOrganizationInput>;
  SaleItems?: InputMaybe<SaleItemUpdateManyWithoutOrganizationInput>;
  sales?: InputMaybe<SaleUpdateManyWithoutOrganizationInput>;
  salesforce_domain?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  salesforce_last_sync_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  SalesForceColumn?: InputMaybe<SalesForceColumnUpdateManyWithoutOrganizationInput>;
  salesForceData?: InputMaybe<SalesForceDataUpdateOneWithoutOrganizationInput>;
  ScheduleItems?: InputMaybe<ScheduleItemUpdateManyWithoutOrganizationInput>;
  self_sourcing_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  send_error_log_email?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  send_lead_routing_email?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sendgrid_email_domain?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sendgrid_email_domain_id?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  sequence?: InputMaybe<SequenceUpdateManyWithoutOrganzationInput>;
  SequenceCriteriaConditions?: InputMaybe<SequenceCriteriaConditionUpdateManyWithoutOrganizationInput>;
  SequenceDashboardViews?: InputMaybe<SequenceDashboardViewUpdateManyWithoutOrganizationInput>;
  SequenceEntryCriterias?: InputMaybe<SequenceEntryCriteriaUpdateManyWithoutOrganizationInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutOrganizationInput>;
  SequenceSteps?: InputMaybe<SequenceStepUpdateManyWithoutOrganizationInput>;
  set_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
  short_resting?: InputMaybe<IntFieldUpdateOperationsInput>;
  show_calendar_options?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_contract_duration_sales?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_lead_source_to_rep?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_nocontact_button?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_payment_terms_sales?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_rep_all_leads?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_rep_my_leads?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_system_view?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sites?: InputMaybe<SiteUpdateManyWithoutOrganizationInput>;
  sms_opt_in_dialog?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SMSItems?: InputMaybe<SmsItemUpdateManyWithoutOrganizationInput>;
  SnoozeTrackings?: InputMaybe<SnoozeTrackingUpdateManyWithoutOrganizationInput>;
  StepActionLogs?: InputMaybe<StepActionLogUpdateManyWithoutOrganizationInput>;
  StepActions?: InputMaybe<StepActionUpdateManyWithoutOrganizationInput>;
  StepConditions?: InputMaybe<StepConditionUpdateManyWithoutOrganizationInput>;
  teams?: InputMaybe<TeamUpdateManyWithoutOrganizationInput>;
  Template?: InputMaybe<TemplateUpdateManyWithoutOrganizationInput>;
  TemplateVersions?: InputMaybe<TemplateVersionUpdateManyWithoutOrganizationInput>;
  timekit_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timezone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transfer_timeout?: InputMaybe<IntFieldUpdateOperationsInput>;
  TransferAttempts?: InputMaybe<TransferAttemptUpdateManyWithoutOrganizationInput>;
  twilio_numbers?: InputMaybe<TwilioNumberUpdateManyWithoutOrganizationInput>;
  TwilioFeedbacks?: InputMaybe<TwilioFeedbackUpdateManyWithoutOrganizationInput>;
  TwilioInfo?: InputMaybe<TwilioInfoUpdateOneWithoutOrganzationInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  use_revenue_planning?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  UserCustomFieldFilters?: InputMaybe<UserCustomFieldFilterUpdateManyWithoutOrganizationInput>;
  users?: InputMaybe<UserUpdateManyWithoutOrganizationInput>;
  win_rep_day_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
};

export type OrganizationUpdateWithoutSaleCyclesInput = {
  ActivityMonitorMultiplier?: InputMaybe<ActivityMonitorMultiplierUpdateManyWithoutOrganizationInput>;
  algoFactor?: InputMaybe<AlgoFactorUpdateManyWithoutOrganizationInput>;
  allow_calendar_invite?: InputMaybe<BoolFieldUpdateOperationsInput>;
  allow_remove_step_action?: InputMaybe<BoolFieldUpdateOperationsInput>;
  allow_snooze_step_action?: InputMaybe<BoolFieldUpdateOperationsInput>;
  always_record?: InputMaybe<BoolFieldUpdateOperationsInput>;
  arv_initial_held_demos_generated_by_sdr_weight?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  AssociationHistories?: InputMaybe<AssociationHistoryUpdateManyWithoutOrganizationInput>;
  auto_call_interval?: InputMaybe<IntFieldUpdateOperationsInput>;
  auto_dial?: InputMaybe<BoolFieldUpdateOperationsInput>;
  automated_campaigns?: InputMaybe<AutomatedCampaignUpdateManyWithoutOrganizationInput>;
  automatic_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  AutoTransferSelections?: InputMaybe<AutoTransferSelectionUpdateManyWithoutOrganizationInput>;
  Availablities?: InputMaybe<AvailabilityUpdateManyWithoutOrganizationInput>;
  AverageOrderValue?: InputMaybe<AverageOrderValueUpdateManyWithoutOrganizationInput>;
  best_action_ever_multiplier?: InputMaybe<FloatFieldUpdateOperationsInput>;
  best_metrics?: InputMaybe<BestMetricRecordUpdateManyWithoutOrganizationInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptUpdateManyWithoutOrganizationInput>;
  callMeNows?: InputMaybe<CallMeNowUpdateManyWithoutOrganizationInput>;
  CallMultiplier?: InputMaybe<CallMultiplierUpdateManyWithoutOrganizationInput>;
  CallSharings?: InputMaybe<CallSharingUpdateManyWithoutOrganizationInput>;
  can_edit_sdr_transfer_rules?: InputMaybe<BoolFieldUpdateOperationsInput>;
  claim_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  concession?: InputMaybe<ConcessionUpdateManyWithoutOrganizationInput>;
  conference_region?: InputMaybe<EnumConferenceRegionFieldUpdateOperationsInput>;
  Conferences?: InputMaybe<ConferenceUpdateManyWithoutOrganizationInput>;
  ContactRequests?: InputMaybe<ContactRequestUpdateManyWithoutOrganizationInput>;
  Contacts?: InputMaybe<ContactUpdateManyWithoutOrganizationInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  custom_fields?: InputMaybe<CustomFieldUpdateManyWithoutOrganizationInput>;
  CustomObject?: InputMaybe<CustomObjectUpdateManyWithoutOrganizationInput>;
  CustomObjectField?: InputMaybe<CustomObjectFieldUpdateManyWithoutOrganizationInput>;
  CustomObjectFieldForeignKey?: InputMaybe<CustomObjectFieldForeignKeyUpdateManyWithoutOrganizationInput>;
  CustomObjectRecord?: InputMaybe<CustomObjectRecordUpdateManyWithoutOrganizationInput>;
  CustomObjectRow?: InputMaybe<CustomObjectRowUpdateManyWithoutOrganizationInput>;
  CustomQueueItems?: InputMaybe<CustomQueueItemUpdateManyWithoutOrganizationInput>;
  DashboardColumnOptions?: InputMaybe<DashboardColumnOptionUpdateManyWithoutOrganizationInput>;
  DashboardLeadFilters?: InputMaybe<DashboardLeadFilterUpdateManyWithoutOrganizationInput>;
  DashboardRepFilters?: InputMaybe<DashboardRepFilterUpdateManyWithoutOrganizationInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewUpdateManyWithoutOrganizationInput>;
  day_to_close?: InputMaybe<IntFieldUpdateOperationsInput>;
  default_demo_time_duration?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  demo_held_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_not_held_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_not_held_call_back_short_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_prep_time_minutes?: InputMaybe<IntFieldUpdateOperationsInput>;
  dev_account?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  dial_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  DispositionMultiplier?: InputMaybe<DispositionMultiplierUpdateManyWithoutOrganizationInput>;
  dispositions?: InputMaybe<DispositionUpdateManyWithoutOrganizationInput>;
  double_booking?: InputMaybe<BoolFieldUpdateOperationsInput>;
  dynamic_date_operators?: InputMaybe<BoolFieldUpdateOperationsInput>;
  edge_servers?: InputMaybe<OrganizationUpdateedge_ServersInput>;
  email_conflict_notification_email_list?: InputMaybe<OrganizationUpdateemail_Conflict_Notification_Email_ListInput>;
  EmailItems?: InputMaybe<EmailItemUpdateManyWithoutOrganizationInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordUpdateManyWithoutOrganizationInput>;
  EmailThreads?: InputMaybe<EmailThreadUpdateManyWithoutOrganizationInput>;
  enable_modified_metrics?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  enable_pay_period_view?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  enable_time_constraint?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  error_log_email_address?: InputMaybe<OrganizationUpdateerror_Log_Email_AddressInput>;
  estimated_average_num_of_weekly_overbooked_demo_slots?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  FolderPermissions?: InputMaybe<FolderPermissionUpdateManyWithoutOrganizationInput>;
  Folders?: InputMaybe<FolderUpdateManyWithoutOrganizationInput>;
  FutureBookingSetting?: InputMaybe<FutureBookingSettingUpdateOneWithoutOrganizationInput>;
  GlobalTransferRule?: InputMaybe<GlobalTransferRuleUpdateManyWithoutOrganizationInput>;
  held_demos_per_cc_connect?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  helpful_links?: InputMaybe<OrganizationUpdatehelpful_LinksInput>;
  HingePointMultiplier?: InputMaybe<HingePointMultiplierUpdateManyWithoutOrganizationInput>;
  hold_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
  hubspot_credential?: InputMaybe<HubSpotCredentialUpdateOneWithoutOrganizationInput>;
  hubspot_last_sync_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  hubspot_white_list?: InputMaybe<HubSpotWhitelistUpdateManyWithoutOrganizationInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inbound_close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_dm_connect_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_lt_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_revenue_value_of_sale?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_scheduled_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_set_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventUpdateManyWithoutOrganizationInput>;
  InboundConciergeWidget?: InputMaybe<InboundConciergeWidgetUpdateManyWithoutOrganizationInput>;
  industry_options?: InputMaybe<IndustryOptionUpdateManyWithoutOrganizationInput>;
  initial_sdr_sets_reps_day?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  integration_error_logs?: InputMaybe<IntegrationErrorLogUpdateManyWithoutOrganizationInput>;
  integration_mappings?: InputMaybe<IntegrationMappingUpdateManyWithoutOrganizationInput>;
  IntegrationMappingCustomObject?: InputMaybe<IntegrationMappingCustomObjectUpdateManyWithoutOrganizationInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogUpdateManyWithoutOrganizationInput>;
  IntegrationReadOnlyObject?: InputMaybe<IntegrationReadOnlyObjectUpdateManyWithoutOrganizationInput>;
  international?: InputMaybe<BoolFieldUpdateOperationsInput>;
  is_email_domain_verified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  is_salesforce_sandbox?: InputMaybe<BoolFieldUpdateOperationsInput>;
  last_lead_value_compute_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutOrganizationInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryUpdateManyWithoutOrganizationInput>;
  lead_intent?: InputMaybe<LeadIntentUpdateManyWithoutOrganizationInput>;
  lead_release_same_rep_delay_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  lead_routing_email_list?: InputMaybe<OrganizationUpdatelead_Routing_Email_ListInput>;
  lead_routing_rules?: InputMaybe<RuleUpdateManyWithoutOrganizationInput>;
  LeadCustomObjectRowAssociation?: InputMaybe<LeadCustomObjectRowAssociationUpdateManyWithoutOrganizationInput>;
  LeadFieldMultiplier?: InputMaybe<LeadFieldMultiplierUpdateManyWithoutOrganizationInput>;
  LeadFieldMultiplierCustom?: InputMaybe<LeadFieldMultiplierCustomUpdateManyWithoutOrganizationInput>;
  leads?: InputMaybe<LeadUpdateManyWithoutOrganizationInput>;
  leadsource_options?: InputMaybe<LeadSourceOptionUpdateManyWithoutOrganizationInput>;
  LeadValue?: InputMaybe<LeadValueUpdateManyWithoutOrganizationInput>;
  LifeTimeValueMultiplier?: InputMaybe<LifeTimeValueMultiplierUpdateManyWithoutOrganizationInput>;
  LiveTransferSetting?: InputMaybe<LiveTransferSettingUpdateOneWithoutOrganizationInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusUpdateManyWithoutOrganizationInput>;
  ltv_multiplier_custom_fields?: InputMaybe<LifeTimeValueMultiplierCustomUpdateManyWithoutOrganizationInput>;
  made_contact_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  make_sale_address_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_business_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_city_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_country_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_first_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_industry_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_last_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_lead_source_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_primary_email_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_primary_phone_number_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_state_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_sub_industry_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_zip_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  maxium_day_lag_set_to_schedule?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  mrr?: InputMaybe<IntFieldUpdateOperationsInput>;
  mrr_label?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  mrr_required_on_sale?: InputMaybe<BoolFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  NoteItems?: InputMaybe<NoteItemUpdateManyWithoutOrganizationInput>;
  Notifications?: InputMaybe<NotificationUpdateManyWithoutOrganizationInput>;
  onboardComplete?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  Opportunity?: InputMaybe<SfOpportunityUpdateManyWithoutOrganizationInput>;
  organization_holidays?: InputMaybe<OrganizationHolidayUpdateManyWithoutOrganizationInput>;
  organization_hours?: InputMaybe<OrganizationHourUpdateManyWithoutOrganizationInput>;
  OrganizationPaymentSetting?: InputMaybe<OrganizationPaymentSettingsUpdateOneWithoutOrganizationInput>;
  OrganizationPaymentSettings?: InputMaybe<OrganizationPaymentSettingsUpdateOneWithoutOrganizationsInput>;
  orgCalendarInviteMetadata?: InputMaybe<OrgCalendarInviteMetadataUpdateManyWithoutOrganizationInput>;
  OrgDefaultPipelinePerecentValue?: InputMaybe<OrgDefaultPipelinePerecentValueUpdateOneWithoutOrganizationInput>;
  OrgHubSpotSync?: InputMaybe<OrgHubSpotSyncUpdateOneWithoutOrganizationInput>;
  OrgNoEventDate?: InputMaybe<OrgNoEventDateUpdateManyWithoutOrganizationInput>;
  OrgSalesforceSync?: InputMaybe<OrgSalesforceSyncUpdateOneWithoutOrganizationInput>;
  OrgTimeslotAvailability?: InputMaybe<OrgTimeslotAvailabilityUpdateManyWithoutOrganizationInput>;
  outbound_close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_dm_connect_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_lt_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_revenue_value_of_sale?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_scheduled_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_set_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  PandadocData?: InputMaybe<PandadocDataUpdateOneWithoutOrganizationInput>;
  PandaDocTemplate?: InputMaybe<PandaDocTemplateUpdateManyWithoutOrganizationInput>;
  participant_jitter_buffer_size?: InputMaybe<EnumJitterBufferSizeFieldUpdateOperationsInput>;
  preferred_goal_setting?: InputMaybe<NullableEnumGoalsettingFieldUpdateOperationsInput>;
  prepayment?: InputMaybe<BoolFieldUpdateOperationsInput>;
  ProductChangeLog?: InputMaybe<ProductChangeLogUpdateManyWithoutOrganizationInput>;
  products?: InputMaybe<ProductUpdateManyWithoutOrganizationInput>;
  re_cold_call_hour_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  recordingStatus?: InputMaybe<EnumRecordingStatusFieldUpdateOperationsInput>;
  release_lead_resting?: InputMaybe<IntFieldUpdateOperationsInput>;
  rep_claim_limit?: InputMaybe<IntFieldUpdateOperationsInput>;
  rep_lead_allotment?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  rep_level_call_transfer?: InputMaybe<BoolFieldUpdateOperationsInput>;
  reps_can_bulk_unassign?: InputMaybe<BoolFieldUpdateOperationsInput>;
  reps_can_view_all_notes?: InputMaybe<BoolFieldUpdateOperationsInput>;
  require_sale_notes?: InputMaybe<BoolFieldUpdateOperationsInput>;
  resting_after_skipped_CC?: InputMaybe<IntFieldUpdateOperationsInput>;
  resting_period?: InputMaybe<IntFieldUpdateOperationsInput>;
  resting_period_after_unsuccessful_cold_call?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  resting_period_after_unsuccessful_demo?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  revenue_plan?: InputMaybe<RevenuePlanItemUpdateManyWithoutOrganizationInput>;
  Routings?: InputMaybe<RoutingUpdateManyWithoutOrganizationInput>;
  sale_field_options?: InputMaybe<SaleFieldOptionUpdateManyWithoutOrganizationInput>;
  sale_notification_email_list?: InputMaybe<OrganizationUpdatesale_Notification_Email_ListInput>;
  SaleConfig?: InputMaybe<SaleConfigUpdateOneWithoutOrganizationInput>;
  SaleConfigPage?: InputMaybe<SaleConfigPageUpdateManyWithoutOrganizationInput>;
  SaleConfigSection?: InputMaybe<SaleConfigSectionUpdateManyWithoutOrganizationInput>;
  SaleItems?: InputMaybe<SaleItemUpdateManyWithoutOrganizationInput>;
  sales?: InputMaybe<SaleUpdateManyWithoutOrganizationInput>;
  salesforce_domain?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  salesforce_last_sync_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  SalesForceColumn?: InputMaybe<SalesForceColumnUpdateManyWithoutOrganizationInput>;
  salesForceData?: InputMaybe<SalesForceDataUpdateOneWithoutOrganizationInput>;
  ScheduleItems?: InputMaybe<ScheduleItemUpdateManyWithoutOrganizationInput>;
  self_sourcing_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  send_error_log_email?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  send_lead_routing_email?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sendgrid_email_domain?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sendgrid_email_domain_id?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  sequence?: InputMaybe<SequenceUpdateManyWithoutOrganzationInput>;
  SequenceCriteriaConditions?: InputMaybe<SequenceCriteriaConditionUpdateManyWithoutOrganizationInput>;
  SequenceDashboardViews?: InputMaybe<SequenceDashboardViewUpdateManyWithoutOrganizationInput>;
  SequenceEntryCriterias?: InputMaybe<SequenceEntryCriteriaUpdateManyWithoutOrganizationInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutOrganizationInput>;
  SequenceSteps?: InputMaybe<SequenceStepUpdateManyWithoutOrganizationInput>;
  set_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
  short_resting?: InputMaybe<IntFieldUpdateOperationsInput>;
  show_calendar_options?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_contract_duration_sales?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_lead_source_to_rep?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_nocontact_button?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_payment_terms_sales?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_rep_all_leads?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_rep_my_leads?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_system_view?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sites?: InputMaybe<SiteUpdateManyWithoutOrganizationInput>;
  sms_opt_in_dialog?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SMSItems?: InputMaybe<SmsItemUpdateManyWithoutOrganizationInput>;
  SnoozeTrackings?: InputMaybe<SnoozeTrackingUpdateManyWithoutOrganizationInput>;
  StepActionLogs?: InputMaybe<StepActionLogUpdateManyWithoutOrganizationInput>;
  StepActions?: InputMaybe<StepActionUpdateManyWithoutOrganizationInput>;
  StepConditions?: InputMaybe<StepConditionUpdateManyWithoutOrganizationInput>;
  teams?: InputMaybe<TeamUpdateManyWithoutOrganizationInput>;
  Template?: InputMaybe<TemplateUpdateManyWithoutOrganizationInput>;
  TemplateVersions?: InputMaybe<TemplateVersionUpdateManyWithoutOrganizationInput>;
  timekit_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timezone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transfer_timeout?: InputMaybe<IntFieldUpdateOperationsInput>;
  TransferAttempts?: InputMaybe<TransferAttemptUpdateManyWithoutOrganizationInput>;
  twilio_numbers?: InputMaybe<TwilioNumberUpdateManyWithoutOrganizationInput>;
  TwilioFeedbacks?: InputMaybe<TwilioFeedbackUpdateManyWithoutOrganizationInput>;
  TwilioInfo?: InputMaybe<TwilioInfoUpdateOneWithoutOrganzationInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  use_revenue_planning?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  UserCustomFieldFilters?: InputMaybe<UserCustomFieldFilterUpdateManyWithoutOrganizationInput>;
  users?: InputMaybe<UserUpdateManyWithoutOrganizationInput>;
  win_rep_day_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
};

export type OrganizationUpdateWithoutSaleItemsInput = {
  ActivityMonitorMultiplier?: InputMaybe<ActivityMonitorMultiplierUpdateManyWithoutOrganizationInput>;
  algoFactor?: InputMaybe<AlgoFactorUpdateManyWithoutOrganizationInput>;
  allow_calendar_invite?: InputMaybe<BoolFieldUpdateOperationsInput>;
  allow_remove_step_action?: InputMaybe<BoolFieldUpdateOperationsInput>;
  allow_snooze_step_action?: InputMaybe<BoolFieldUpdateOperationsInput>;
  always_record?: InputMaybe<BoolFieldUpdateOperationsInput>;
  arv_initial_held_demos_generated_by_sdr_weight?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  AssociationHistories?: InputMaybe<AssociationHistoryUpdateManyWithoutOrganizationInput>;
  auto_call_interval?: InputMaybe<IntFieldUpdateOperationsInput>;
  auto_dial?: InputMaybe<BoolFieldUpdateOperationsInput>;
  automated_campaigns?: InputMaybe<AutomatedCampaignUpdateManyWithoutOrganizationInput>;
  automatic_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  AutoTransferSelections?: InputMaybe<AutoTransferSelectionUpdateManyWithoutOrganizationInput>;
  Availablities?: InputMaybe<AvailabilityUpdateManyWithoutOrganizationInput>;
  AverageOrderValue?: InputMaybe<AverageOrderValueUpdateManyWithoutOrganizationInput>;
  best_action_ever_multiplier?: InputMaybe<FloatFieldUpdateOperationsInput>;
  best_metrics?: InputMaybe<BestMetricRecordUpdateManyWithoutOrganizationInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptUpdateManyWithoutOrganizationInput>;
  callMeNows?: InputMaybe<CallMeNowUpdateManyWithoutOrganizationInput>;
  CallMultiplier?: InputMaybe<CallMultiplierUpdateManyWithoutOrganizationInput>;
  CallSharings?: InputMaybe<CallSharingUpdateManyWithoutOrganizationInput>;
  can_edit_sdr_transfer_rules?: InputMaybe<BoolFieldUpdateOperationsInput>;
  claim_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  concession?: InputMaybe<ConcessionUpdateManyWithoutOrganizationInput>;
  conference_region?: InputMaybe<EnumConferenceRegionFieldUpdateOperationsInput>;
  Conferences?: InputMaybe<ConferenceUpdateManyWithoutOrganizationInput>;
  ContactRequests?: InputMaybe<ContactRequestUpdateManyWithoutOrganizationInput>;
  Contacts?: InputMaybe<ContactUpdateManyWithoutOrganizationInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  custom_fields?: InputMaybe<CustomFieldUpdateManyWithoutOrganizationInput>;
  CustomObject?: InputMaybe<CustomObjectUpdateManyWithoutOrganizationInput>;
  CustomObjectField?: InputMaybe<CustomObjectFieldUpdateManyWithoutOrganizationInput>;
  CustomObjectFieldForeignKey?: InputMaybe<CustomObjectFieldForeignKeyUpdateManyWithoutOrganizationInput>;
  CustomObjectRecord?: InputMaybe<CustomObjectRecordUpdateManyWithoutOrganizationInput>;
  CustomObjectRow?: InputMaybe<CustomObjectRowUpdateManyWithoutOrganizationInput>;
  CustomQueueItems?: InputMaybe<CustomQueueItemUpdateManyWithoutOrganizationInput>;
  DashboardColumnOptions?: InputMaybe<DashboardColumnOptionUpdateManyWithoutOrganizationInput>;
  DashboardLeadFilters?: InputMaybe<DashboardLeadFilterUpdateManyWithoutOrganizationInput>;
  DashboardRepFilters?: InputMaybe<DashboardRepFilterUpdateManyWithoutOrganizationInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewUpdateManyWithoutOrganizationInput>;
  day_to_close?: InputMaybe<IntFieldUpdateOperationsInput>;
  default_demo_time_duration?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  demo_held_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_not_held_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_not_held_call_back_short_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_prep_time_minutes?: InputMaybe<IntFieldUpdateOperationsInput>;
  dev_account?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  dial_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  DispositionMultiplier?: InputMaybe<DispositionMultiplierUpdateManyWithoutOrganizationInput>;
  dispositions?: InputMaybe<DispositionUpdateManyWithoutOrganizationInput>;
  double_booking?: InputMaybe<BoolFieldUpdateOperationsInput>;
  dynamic_date_operators?: InputMaybe<BoolFieldUpdateOperationsInput>;
  edge_servers?: InputMaybe<OrganizationUpdateedge_ServersInput>;
  email_conflict_notification_email_list?: InputMaybe<OrganizationUpdateemail_Conflict_Notification_Email_ListInput>;
  EmailItems?: InputMaybe<EmailItemUpdateManyWithoutOrganizationInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordUpdateManyWithoutOrganizationInput>;
  EmailThreads?: InputMaybe<EmailThreadUpdateManyWithoutOrganizationInput>;
  enable_modified_metrics?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  enable_pay_period_view?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  enable_time_constraint?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  error_log_email_address?: InputMaybe<OrganizationUpdateerror_Log_Email_AddressInput>;
  estimated_average_num_of_weekly_overbooked_demo_slots?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  FolderPermissions?: InputMaybe<FolderPermissionUpdateManyWithoutOrganizationInput>;
  Folders?: InputMaybe<FolderUpdateManyWithoutOrganizationInput>;
  FutureBookingSetting?: InputMaybe<FutureBookingSettingUpdateOneWithoutOrganizationInput>;
  GlobalTransferRule?: InputMaybe<GlobalTransferRuleUpdateManyWithoutOrganizationInput>;
  held_demos_per_cc_connect?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  helpful_links?: InputMaybe<OrganizationUpdatehelpful_LinksInput>;
  HingePointMultiplier?: InputMaybe<HingePointMultiplierUpdateManyWithoutOrganizationInput>;
  hold_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
  hubspot_credential?: InputMaybe<HubSpotCredentialUpdateOneWithoutOrganizationInput>;
  hubspot_last_sync_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  hubspot_white_list?: InputMaybe<HubSpotWhitelistUpdateManyWithoutOrganizationInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inbound_close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_dm_connect_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_lt_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_revenue_value_of_sale?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_scheduled_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_set_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventUpdateManyWithoutOrganizationInput>;
  InboundConciergeWidget?: InputMaybe<InboundConciergeWidgetUpdateManyWithoutOrganizationInput>;
  industry_options?: InputMaybe<IndustryOptionUpdateManyWithoutOrganizationInput>;
  initial_sdr_sets_reps_day?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  integration_error_logs?: InputMaybe<IntegrationErrorLogUpdateManyWithoutOrganizationInput>;
  integration_mappings?: InputMaybe<IntegrationMappingUpdateManyWithoutOrganizationInput>;
  IntegrationMappingCustomObject?: InputMaybe<IntegrationMappingCustomObjectUpdateManyWithoutOrganizationInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogUpdateManyWithoutOrganizationInput>;
  IntegrationReadOnlyObject?: InputMaybe<IntegrationReadOnlyObjectUpdateManyWithoutOrganizationInput>;
  international?: InputMaybe<BoolFieldUpdateOperationsInput>;
  is_email_domain_verified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  is_salesforce_sandbox?: InputMaybe<BoolFieldUpdateOperationsInput>;
  last_lead_value_compute_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutOrganizationInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryUpdateManyWithoutOrganizationInput>;
  lead_intent?: InputMaybe<LeadIntentUpdateManyWithoutOrganizationInput>;
  lead_release_same_rep_delay_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  lead_routing_email_list?: InputMaybe<OrganizationUpdatelead_Routing_Email_ListInput>;
  lead_routing_rules?: InputMaybe<RuleUpdateManyWithoutOrganizationInput>;
  LeadCustomObjectRowAssociation?: InputMaybe<LeadCustomObjectRowAssociationUpdateManyWithoutOrganizationInput>;
  LeadFieldMultiplier?: InputMaybe<LeadFieldMultiplierUpdateManyWithoutOrganizationInput>;
  LeadFieldMultiplierCustom?: InputMaybe<LeadFieldMultiplierCustomUpdateManyWithoutOrganizationInput>;
  leads?: InputMaybe<LeadUpdateManyWithoutOrganizationInput>;
  leadsource_options?: InputMaybe<LeadSourceOptionUpdateManyWithoutOrganizationInput>;
  LeadValue?: InputMaybe<LeadValueUpdateManyWithoutOrganizationInput>;
  LifeTimeValueMultiplier?: InputMaybe<LifeTimeValueMultiplierUpdateManyWithoutOrganizationInput>;
  LiveTransferSetting?: InputMaybe<LiveTransferSettingUpdateOneWithoutOrganizationInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusUpdateManyWithoutOrganizationInput>;
  ltv_multiplier_custom_fields?: InputMaybe<LifeTimeValueMultiplierCustomUpdateManyWithoutOrganizationInput>;
  made_contact_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  make_sale_address_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_business_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_city_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_country_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_first_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_industry_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_last_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_lead_source_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_primary_email_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_primary_phone_number_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_state_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_sub_industry_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_zip_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  maxium_day_lag_set_to_schedule?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  mrr?: InputMaybe<IntFieldUpdateOperationsInput>;
  mrr_label?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  mrr_required_on_sale?: InputMaybe<BoolFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  NoteItems?: InputMaybe<NoteItemUpdateManyWithoutOrganizationInput>;
  Notifications?: InputMaybe<NotificationUpdateManyWithoutOrganizationInput>;
  onboardComplete?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  Opportunity?: InputMaybe<SfOpportunityUpdateManyWithoutOrganizationInput>;
  organization_holidays?: InputMaybe<OrganizationHolidayUpdateManyWithoutOrganizationInput>;
  organization_hours?: InputMaybe<OrganizationHourUpdateManyWithoutOrganizationInput>;
  OrganizationPaymentSetting?: InputMaybe<OrganizationPaymentSettingsUpdateOneWithoutOrganizationInput>;
  OrganizationPaymentSettings?: InputMaybe<OrganizationPaymentSettingsUpdateOneWithoutOrganizationsInput>;
  orgCalendarInviteMetadata?: InputMaybe<OrgCalendarInviteMetadataUpdateManyWithoutOrganizationInput>;
  OrgDefaultPipelinePerecentValue?: InputMaybe<OrgDefaultPipelinePerecentValueUpdateOneWithoutOrganizationInput>;
  OrgHubSpotSync?: InputMaybe<OrgHubSpotSyncUpdateOneWithoutOrganizationInput>;
  OrgNoEventDate?: InputMaybe<OrgNoEventDateUpdateManyWithoutOrganizationInput>;
  OrgSalesforceSync?: InputMaybe<OrgSalesforceSyncUpdateOneWithoutOrganizationInput>;
  OrgTimeslotAvailability?: InputMaybe<OrgTimeslotAvailabilityUpdateManyWithoutOrganizationInput>;
  outbound_close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_dm_connect_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_lt_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_revenue_value_of_sale?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_scheduled_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_set_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  PandadocData?: InputMaybe<PandadocDataUpdateOneWithoutOrganizationInput>;
  PandaDocTemplate?: InputMaybe<PandaDocTemplateUpdateManyWithoutOrganizationInput>;
  participant_jitter_buffer_size?: InputMaybe<EnumJitterBufferSizeFieldUpdateOperationsInput>;
  preferred_goal_setting?: InputMaybe<NullableEnumGoalsettingFieldUpdateOperationsInput>;
  prepayment?: InputMaybe<BoolFieldUpdateOperationsInput>;
  ProductChangeLog?: InputMaybe<ProductChangeLogUpdateManyWithoutOrganizationInput>;
  products?: InputMaybe<ProductUpdateManyWithoutOrganizationInput>;
  re_cold_call_hour_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  recordingStatus?: InputMaybe<EnumRecordingStatusFieldUpdateOperationsInput>;
  release_lead_resting?: InputMaybe<IntFieldUpdateOperationsInput>;
  rep_claim_limit?: InputMaybe<IntFieldUpdateOperationsInput>;
  rep_lead_allotment?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  rep_level_call_transfer?: InputMaybe<BoolFieldUpdateOperationsInput>;
  reps_can_bulk_unassign?: InputMaybe<BoolFieldUpdateOperationsInput>;
  reps_can_view_all_notes?: InputMaybe<BoolFieldUpdateOperationsInput>;
  require_sale_notes?: InputMaybe<BoolFieldUpdateOperationsInput>;
  resting_after_skipped_CC?: InputMaybe<IntFieldUpdateOperationsInput>;
  resting_period?: InputMaybe<IntFieldUpdateOperationsInput>;
  resting_period_after_unsuccessful_cold_call?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  resting_period_after_unsuccessful_demo?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  revenue_plan?: InputMaybe<RevenuePlanItemUpdateManyWithoutOrganizationInput>;
  Routings?: InputMaybe<RoutingUpdateManyWithoutOrganizationInput>;
  sale_field_options?: InputMaybe<SaleFieldOptionUpdateManyWithoutOrganizationInput>;
  sale_notification_email_list?: InputMaybe<OrganizationUpdatesale_Notification_Email_ListInput>;
  SaleConfig?: InputMaybe<SaleConfigUpdateOneWithoutOrganizationInput>;
  SaleConfigPage?: InputMaybe<SaleConfigPageUpdateManyWithoutOrganizationInput>;
  SaleConfigSection?: InputMaybe<SaleConfigSectionUpdateManyWithoutOrganizationInput>;
  SaleCycles?: InputMaybe<SaleCycleUpdateManyWithoutOrganizationInput>;
  sales?: InputMaybe<SaleUpdateManyWithoutOrganizationInput>;
  salesforce_domain?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  salesforce_last_sync_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  SalesForceColumn?: InputMaybe<SalesForceColumnUpdateManyWithoutOrganizationInput>;
  salesForceData?: InputMaybe<SalesForceDataUpdateOneWithoutOrganizationInput>;
  ScheduleItems?: InputMaybe<ScheduleItemUpdateManyWithoutOrganizationInput>;
  self_sourcing_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  send_error_log_email?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  send_lead_routing_email?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sendgrid_email_domain?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sendgrid_email_domain_id?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  sequence?: InputMaybe<SequenceUpdateManyWithoutOrganzationInput>;
  SequenceCriteriaConditions?: InputMaybe<SequenceCriteriaConditionUpdateManyWithoutOrganizationInput>;
  SequenceDashboardViews?: InputMaybe<SequenceDashboardViewUpdateManyWithoutOrganizationInput>;
  SequenceEntryCriterias?: InputMaybe<SequenceEntryCriteriaUpdateManyWithoutOrganizationInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutOrganizationInput>;
  SequenceSteps?: InputMaybe<SequenceStepUpdateManyWithoutOrganizationInput>;
  set_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
  short_resting?: InputMaybe<IntFieldUpdateOperationsInput>;
  show_calendar_options?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_contract_duration_sales?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_lead_source_to_rep?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_nocontact_button?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_payment_terms_sales?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_rep_all_leads?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_rep_my_leads?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_system_view?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sites?: InputMaybe<SiteUpdateManyWithoutOrganizationInput>;
  sms_opt_in_dialog?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SMSItems?: InputMaybe<SmsItemUpdateManyWithoutOrganizationInput>;
  SnoozeTrackings?: InputMaybe<SnoozeTrackingUpdateManyWithoutOrganizationInput>;
  StepActionLogs?: InputMaybe<StepActionLogUpdateManyWithoutOrganizationInput>;
  StepActions?: InputMaybe<StepActionUpdateManyWithoutOrganizationInput>;
  StepConditions?: InputMaybe<StepConditionUpdateManyWithoutOrganizationInput>;
  teams?: InputMaybe<TeamUpdateManyWithoutOrganizationInput>;
  Template?: InputMaybe<TemplateUpdateManyWithoutOrganizationInput>;
  TemplateVersions?: InputMaybe<TemplateVersionUpdateManyWithoutOrganizationInput>;
  timekit_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timezone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transfer_timeout?: InputMaybe<IntFieldUpdateOperationsInput>;
  TransferAttempts?: InputMaybe<TransferAttemptUpdateManyWithoutOrganizationInput>;
  twilio_numbers?: InputMaybe<TwilioNumberUpdateManyWithoutOrganizationInput>;
  TwilioFeedbacks?: InputMaybe<TwilioFeedbackUpdateManyWithoutOrganizationInput>;
  TwilioInfo?: InputMaybe<TwilioInfoUpdateOneWithoutOrganzationInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  use_revenue_planning?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  UserCustomFieldFilters?: InputMaybe<UserCustomFieldFilterUpdateManyWithoutOrganizationInput>;
  users?: InputMaybe<UserUpdateManyWithoutOrganizationInput>;
  win_rep_day_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
};

export type OrganizationUpdateWithoutSalesInput = {
  ActivityMonitorMultiplier?: InputMaybe<ActivityMonitorMultiplierUpdateManyWithoutOrganizationInput>;
  algoFactor?: InputMaybe<AlgoFactorUpdateManyWithoutOrganizationInput>;
  allow_calendar_invite?: InputMaybe<BoolFieldUpdateOperationsInput>;
  allow_remove_step_action?: InputMaybe<BoolFieldUpdateOperationsInput>;
  allow_snooze_step_action?: InputMaybe<BoolFieldUpdateOperationsInput>;
  always_record?: InputMaybe<BoolFieldUpdateOperationsInput>;
  arv_initial_held_demos_generated_by_sdr_weight?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  AssociationHistories?: InputMaybe<AssociationHistoryUpdateManyWithoutOrganizationInput>;
  auto_call_interval?: InputMaybe<IntFieldUpdateOperationsInput>;
  auto_dial?: InputMaybe<BoolFieldUpdateOperationsInput>;
  automated_campaigns?: InputMaybe<AutomatedCampaignUpdateManyWithoutOrganizationInput>;
  automatic_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  AutoTransferSelections?: InputMaybe<AutoTransferSelectionUpdateManyWithoutOrganizationInput>;
  Availablities?: InputMaybe<AvailabilityUpdateManyWithoutOrganizationInput>;
  AverageOrderValue?: InputMaybe<AverageOrderValueUpdateManyWithoutOrganizationInput>;
  best_action_ever_multiplier?: InputMaybe<FloatFieldUpdateOperationsInput>;
  best_metrics?: InputMaybe<BestMetricRecordUpdateManyWithoutOrganizationInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptUpdateManyWithoutOrganizationInput>;
  callMeNows?: InputMaybe<CallMeNowUpdateManyWithoutOrganizationInput>;
  CallMultiplier?: InputMaybe<CallMultiplierUpdateManyWithoutOrganizationInput>;
  CallSharings?: InputMaybe<CallSharingUpdateManyWithoutOrganizationInput>;
  can_edit_sdr_transfer_rules?: InputMaybe<BoolFieldUpdateOperationsInput>;
  claim_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  concession?: InputMaybe<ConcessionUpdateManyWithoutOrganizationInput>;
  conference_region?: InputMaybe<EnumConferenceRegionFieldUpdateOperationsInput>;
  Conferences?: InputMaybe<ConferenceUpdateManyWithoutOrganizationInput>;
  ContactRequests?: InputMaybe<ContactRequestUpdateManyWithoutOrganizationInput>;
  Contacts?: InputMaybe<ContactUpdateManyWithoutOrganizationInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  custom_fields?: InputMaybe<CustomFieldUpdateManyWithoutOrganizationInput>;
  CustomObject?: InputMaybe<CustomObjectUpdateManyWithoutOrganizationInput>;
  CustomObjectField?: InputMaybe<CustomObjectFieldUpdateManyWithoutOrganizationInput>;
  CustomObjectFieldForeignKey?: InputMaybe<CustomObjectFieldForeignKeyUpdateManyWithoutOrganizationInput>;
  CustomObjectRecord?: InputMaybe<CustomObjectRecordUpdateManyWithoutOrganizationInput>;
  CustomObjectRow?: InputMaybe<CustomObjectRowUpdateManyWithoutOrganizationInput>;
  CustomQueueItems?: InputMaybe<CustomQueueItemUpdateManyWithoutOrganizationInput>;
  DashboardColumnOptions?: InputMaybe<DashboardColumnOptionUpdateManyWithoutOrganizationInput>;
  DashboardLeadFilters?: InputMaybe<DashboardLeadFilterUpdateManyWithoutOrganizationInput>;
  DashboardRepFilters?: InputMaybe<DashboardRepFilterUpdateManyWithoutOrganizationInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewUpdateManyWithoutOrganizationInput>;
  day_to_close?: InputMaybe<IntFieldUpdateOperationsInput>;
  default_demo_time_duration?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  demo_held_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_not_held_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_not_held_call_back_short_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_prep_time_minutes?: InputMaybe<IntFieldUpdateOperationsInput>;
  dev_account?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  dial_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  DispositionMultiplier?: InputMaybe<DispositionMultiplierUpdateManyWithoutOrganizationInput>;
  dispositions?: InputMaybe<DispositionUpdateManyWithoutOrganizationInput>;
  double_booking?: InputMaybe<BoolFieldUpdateOperationsInput>;
  dynamic_date_operators?: InputMaybe<BoolFieldUpdateOperationsInput>;
  edge_servers?: InputMaybe<OrganizationUpdateedge_ServersInput>;
  email_conflict_notification_email_list?: InputMaybe<OrganizationUpdateemail_Conflict_Notification_Email_ListInput>;
  EmailItems?: InputMaybe<EmailItemUpdateManyWithoutOrganizationInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordUpdateManyWithoutOrganizationInput>;
  EmailThreads?: InputMaybe<EmailThreadUpdateManyWithoutOrganizationInput>;
  enable_modified_metrics?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  enable_pay_period_view?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  enable_time_constraint?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  error_log_email_address?: InputMaybe<OrganizationUpdateerror_Log_Email_AddressInput>;
  estimated_average_num_of_weekly_overbooked_demo_slots?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  FolderPermissions?: InputMaybe<FolderPermissionUpdateManyWithoutOrganizationInput>;
  Folders?: InputMaybe<FolderUpdateManyWithoutOrganizationInput>;
  FutureBookingSetting?: InputMaybe<FutureBookingSettingUpdateOneWithoutOrganizationInput>;
  GlobalTransferRule?: InputMaybe<GlobalTransferRuleUpdateManyWithoutOrganizationInput>;
  held_demos_per_cc_connect?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  helpful_links?: InputMaybe<OrganizationUpdatehelpful_LinksInput>;
  HingePointMultiplier?: InputMaybe<HingePointMultiplierUpdateManyWithoutOrganizationInput>;
  hold_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
  hubspot_credential?: InputMaybe<HubSpotCredentialUpdateOneWithoutOrganizationInput>;
  hubspot_last_sync_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  hubspot_white_list?: InputMaybe<HubSpotWhitelistUpdateManyWithoutOrganizationInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inbound_close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_dm_connect_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_lt_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_revenue_value_of_sale?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_scheduled_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_set_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventUpdateManyWithoutOrganizationInput>;
  InboundConciergeWidget?: InputMaybe<InboundConciergeWidgetUpdateManyWithoutOrganizationInput>;
  industry_options?: InputMaybe<IndustryOptionUpdateManyWithoutOrganizationInput>;
  initial_sdr_sets_reps_day?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  integration_error_logs?: InputMaybe<IntegrationErrorLogUpdateManyWithoutOrganizationInput>;
  integration_mappings?: InputMaybe<IntegrationMappingUpdateManyWithoutOrganizationInput>;
  IntegrationMappingCustomObject?: InputMaybe<IntegrationMappingCustomObjectUpdateManyWithoutOrganizationInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogUpdateManyWithoutOrganizationInput>;
  IntegrationReadOnlyObject?: InputMaybe<IntegrationReadOnlyObjectUpdateManyWithoutOrganizationInput>;
  international?: InputMaybe<BoolFieldUpdateOperationsInput>;
  is_email_domain_verified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  is_salesforce_sandbox?: InputMaybe<BoolFieldUpdateOperationsInput>;
  last_lead_value_compute_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutOrganizationInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryUpdateManyWithoutOrganizationInput>;
  lead_intent?: InputMaybe<LeadIntentUpdateManyWithoutOrganizationInput>;
  lead_release_same_rep_delay_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  lead_routing_email_list?: InputMaybe<OrganizationUpdatelead_Routing_Email_ListInput>;
  lead_routing_rules?: InputMaybe<RuleUpdateManyWithoutOrganizationInput>;
  LeadCustomObjectRowAssociation?: InputMaybe<LeadCustomObjectRowAssociationUpdateManyWithoutOrganizationInput>;
  LeadFieldMultiplier?: InputMaybe<LeadFieldMultiplierUpdateManyWithoutOrganizationInput>;
  LeadFieldMultiplierCustom?: InputMaybe<LeadFieldMultiplierCustomUpdateManyWithoutOrganizationInput>;
  leads?: InputMaybe<LeadUpdateManyWithoutOrganizationInput>;
  leadsource_options?: InputMaybe<LeadSourceOptionUpdateManyWithoutOrganizationInput>;
  LeadValue?: InputMaybe<LeadValueUpdateManyWithoutOrganizationInput>;
  LifeTimeValueMultiplier?: InputMaybe<LifeTimeValueMultiplierUpdateManyWithoutOrganizationInput>;
  LiveTransferSetting?: InputMaybe<LiveTransferSettingUpdateOneWithoutOrganizationInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusUpdateManyWithoutOrganizationInput>;
  ltv_multiplier_custom_fields?: InputMaybe<LifeTimeValueMultiplierCustomUpdateManyWithoutOrganizationInput>;
  made_contact_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  make_sale_address_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_business_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_city_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_country_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_first_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_industry_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_last_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_lead_source_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_primary_email_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_primary_phone_number_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_state_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_sub_industry_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_zip_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  maxium_day_lag_set_to_schedule?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  mrr?: InputMaybe<IntFieldUpdateOperationsInput>;
  mrr_label?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  mrr_required_on_sale?: InputMaybe<BoolFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  NoteItems?: InputMaybe<NoteItemUpdateManyWithoutOrganizationInput>;
  Notifications?: InputMaybe<NotificationUpdateManyWithoutOrganizationInput>;
  onboardComplete?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  Opportunity?: InputMaybe<SfOpportunityUpdateManyWithoutOrganizationInput>;
  organization_holidays?: InputMaybe<OrganizationHolidayUpdateManyWithoutOrganizationInput>;
  organization_hours?: InputMaybe<OrganizationHourUpdateManyWithoutOrganizationInput>;
  OrganizationPaymentSetting?: InputMaybe<OrganizationPaymentSettingsUpdateOneWithoutOrganizationInput>;
  OrganizationPaymentSettings?: InputMaybe<OrganizationPaymentSettingsUpdateOneWithoutOrganizationsInput>;
  orgCalendarInviteMetadata?: InputMaybe<OrgCalendarInviteMetadataUpdateManyWithoutOrganizationInput>;
  OrgDefaultPipelinePerecentValue?: InputMaybe<OrgDefaultPipelinePerecentValueUpdateOneWithoutOrganizationInput>;
  OrgHubSpotSync?: InputMaybe<OrgHubSpotSyncUpdateOneWithoutOrganizationInput>;
  OrgNoEventDate?: InputMaybe<OrgNoEventDateUpdateManyWithoutOrganizationInput>;
  OrgSalesforceSync?: InputMaybe<OrgSalesforceSyncUpdateOneWithoutOrganizationInput>;
  OrgTimeslotAvailability?: InputMaybe<OrgTimeslotAvailabilityUpdateManyWithoutOrganizationInput>;
  outbound_close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_dm_connect_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_lt_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_revenue_value_of_sale?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_scheduled_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_set_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  PandadocData?: InputMaybe<PandadocDataUpdateOneWithoutOrganizationInput>;
  PandaDocTemplate?: InputMaybe<PandaDocTemplateUpdateManyWithoutOrganizationInput>;
  participant_jitter_buffer_size?: InputMaybe<EnumJitterBufferSizeFieldUpdateOperationsInput>;
  preferred_goal_setting?: InputMaybe<NullableEnumGoalsettingFieldUpdateOperationsInput>;
  prepayment?: InputMaybe<BoolFieldUpdateOperationsInput>;
  ProductChangeLog?: InputMaybe<ProductChangeLogUpdateManyWithoutOrganizationInput>;
  products?: InputMaybe<ProductUpdateManyWithoutOrganizationInput>;
  re_cold_call_hour_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  recordingStatus?: InputMaybe<EnumRecordingStatusFieldUpdateOperationsInput>;
  release_lead_resting?: InputMaybe<IntFieldUpdateOperationsInput>;
  rep_claim_limit?: InputMaybe<IntFieldUpdateOperationsInput>;
  rep_lead_allotment?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  rep_level_call_transfer?: InputMaybe<BoolFieldUpdateOperationsInput>;
  reps_can_bulk_unassign?: InputMaybe<BoolFieldUpdateOperationsInput>;
  reps_can_view_all_notes?: InputMaybe<BoolFieldUpdateOperationsInput>;
  require_sale_notes?: InputMaybe<BoolFieldUpdateOperationsInput>;
  resting_after_skipped_CC?: InputMaybe<IntFieldUpdateOperationsInput>;
  resting_period?: InputMaybe<IntFieldUpdateOperationsInput>;
  resting_period_after_unsuccessful_cold_call?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  resting_period_after_unsuccessful_demo?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  revenue_plan?: InputMaybe<RevenuePlanItemUpdateManyWithoutOrganizationInput>;
  Routings?: InputMaybe<RoutingUpdateManyWithoutOrganizationInput>;
  sale_field_options?: InputMaybe<SaleFieldOptionUpdateManyWithoutOrganizationInput>;
  sale_notification_email_list?: InputMaybe<OrganizationUpdatesale_Notification_Email_ListInput>;
  SaleConfig?: InputMaybe<SaleConfigUpdateOneWithoutOrganizationInput>;
  SaleConfigPage?: InputMaybe<SaleConfigPageUpdateManyWithoutOrganizationInput>;
  SaleConfigSection?: InputMaybe<SaleConfigSectionUpdateManyWithoutOrganizationInput>;
  SaleCycles?: InputMaybe<SaleCycleUpdateManyWithoutOrganizationInput>;
  SaleItems?: InputMaybe<SaleItemUpdateManyWithoutOrganizationInput>;
  salesforce_domain?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  salesforce_last_sync_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  SalesForceColumn?: InputMaybe<SalesForceColumnUpdateManyWithoutOrganizationInput>;
  salesForceData?: InputMaybe<SalesForceDataUpdateOneWithoutOrganizationInput>;
  ScheduleItems?: InputMaybe<ScheduleItemUpdateManyWithoutOrganizationInput>;
  self_sourcing_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  send_error_log_email?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  send_lead_routing_email?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sendgrid_email_domain?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sendgrid_email_domain_id?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  sequence?: InputMaybe<SequenceUpdateManyWithoutOrganzationInput>;
  SequenceCriteriaConditions?: InputMaybe<SequenceCriteriaConditionUpdateManyWithoutOrganizationInput>;
  SequenceDashboardViews?: InputMaybe<SequenceDashboardViewUpdateManyWithoutOrganizationInput>;
  SequenceEntryCriterias?: InputMaybe<SequenceEntryCriteriaUpdateManyWithoutOrganizationInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutOrganizationInput>;
  SequenceSteps?: InputMaybe<SequenceStepUpdateManyWithoutOrganizationInput>;
  set_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
  short_resting?: InputMaybe<IntFieldUpdateOperationsInput>;
  show_calendar_options?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_contract_duration_sales?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_lead_source_to_rep?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_nocontact_button?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_payment_terms_sales?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_rep_all_leads?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_rep_my_leads?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_system_view?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sites?: InputMaybe<SiteUpdateManyWithoutOrganizationInput>;
  sms_opt_in_dialog?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SMSItems?: InputMaybe<SmsItemUpdateManyWithoutOrganizationInput>;
  SnoozeTrackings?: InputMaybe<SnoozeTrackingUpdateManyWithoutOrganizationInput>;
  StepActionLogs?: InputMaybe<StepActionLogUpdateManyWithoutOrganizationInput>;
  StepActions?: InputMaybe<StepActionUpdateManyWithoutOrganizationInput>;
  StepConditions?: InputMaybe<StepConditionUpdateManyWithoutOrganizationInput>;
  teams?: InputMaybe<TeamUpdateManyWithoutOrganizationInput>;
  Template?: InputMaybe<TemplateUpdateManyWithoutOrganizationInput>;
  TemplateVersions?: InputMaybe<TemplateVersionUpdateManyWithoutOrganizationInput>;
  timekit_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timezone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transfer_timeout?: InputMaybe<IntFieldUpdateOperationsInput>;
  TransferAttempts?: InputMaybe<TransferAttemptUpdateManyWithoutOrganizationInput>;
  twilio_numbers?: InputMaybe<TwilioNumberUpdateManyWithoutOrganizationInput>;
  TwilioFeedbacks?: InputMaybe<TwilioFeedbackUpdateManyWithoutOrganizationInput>;
  TwilioInfo?: InputMaybe<TwilioInfoUpdateOneWithoutOrganzationInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  use_revenue_planning?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  UserCustomFieldFilters?: InputMaybe<UserCustomFieldFilterUpdateManyWithoutOrganizationInput>;
  users?: InputMaybe<UserUpdateManyWithoutOrganizationInput>;
  win_rep_day_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
};

export type OrganizationUpdateWithoutScheduleItemsInput = {
  ActivityMonitorMultiplier?: InputMaybe<ActivityMonitorMultiplierUpdateManyWithoutOrganizationInput>;
  algoFactor?: InputMaybe<AlgoFactorUpdateManyWithoutOrganizationInput>;
  allow_calendar_invite?: InputMaybe<BoolFieldUpdateOperationsInput>;
  allow_remove_step_action?: InputMaybe<BoolFieldUpdateOperationsInput>;
  allow_snooze_step_action?: InputMaybe<BoolFieldUpdateOperationsInput>;
  always_record?: InputMaybe<BoolFieldUpdateOperationsInput>;
  arv_initial_held_demos_generated_by_sdr_weight?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  AssociationHistories?: InputMaybe<AssociationHistoryUpdateManyWithoutOrganizationInput>;
  auto_call_interval?: InputMaybe<IntFieldUpdateOperationsInput>;
  auto_dial?: InputMaybe<BoolFieldUpdateOperationsInput>;
  automated_campaigns?: InputMaybe<AutomatedCampaignUpdateManyWithoutOrganizationInput>;
  automatic_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  AutoTransferSelections?: InputMaybe<AutoTransferSelectionUpdateManyWithoutOrganizationInput>;
  Availablities?: InputMaybe<AvailabilityUpdateManyWithoutOrganizationInput>;
  AverageOrderValue?: InputMaybe<AverageOrderValueUpdateManyWithoutOrganizationInput>;
  best_action_ever_multiplier?: InputMaybe<FloatFieldUpdateOperationsInput>;
  best_metrics?: InputMaybe<BestMetricRecordUpdateManyWithoutOrganizationInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptUpdateManyWithoutOrganizationInput>;
  callMeNows?: InputMaybe<CallMeNowUpdateManyWithoutOrganizationInput>;
  CallMultiplier?: InputMaybe<CallMultiplierUpdateManyWithoutOrganizationInput>;
  CallSharings?: InputMaybe<CallSharingUpdateManyWithoutOrganizationInput>;
  can_edit_sdr_transfer_rules?: InputMaybe<BoolFieldUpdateOperationsInput>;
  claim_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  concession?: InputMaybe<ConcessionUpdateManyWithoutOrganizationInput>;
  conference_region?: InputMaybe<EnumConferenceRegionFieldUpdateOperationsInput>;
  Conferences?: InputMaybe<ConferenceUpdateManyWithoutOrganizationInput>;
  ContactRequests?: InputMaybe<ContactRequestUpdateManyWithoutOrganizationInput>;
  Contacts?: InputMaybe<ContactUpdateManyWithoutOrganizationInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  custom_fields?: InputMaybe<CustomFieldUpdateManyWithoutOrganizationInput>;
  CustomObject?: InputMaybe<CustomObjectUpdateManyWithoutOrganizationInput>;
  CustomObjectField?: InputMaybe<CustomObjectFieldUpdateManyWithoutOrganizationInput>;
  CustomObjectFieldForeignKey?: InputMaybe<CustomObjectFieldForeignKeyUpdateManyWithoutOrganizationInput>;
  CustomObjectRecord?: InputMaybe<CustomObjectRecordUpdateManyWithoutOrganizationInput>;
  CustomObjectRow?: InputMaybe<CustomObjectRowUpdateManyWithoutOrganizationInput>;
  CustomQueueItems?: InputMaybe<CustomQueueItemUpdateManyWithoutOrganizationInput>;
  DashboardColumnOptions?: InputMaybe<DashboardColumnOptionUpdateManyWithoutOrganizationInput>;
  DashboardLeadFilters?: InputMaybe<DashboardLeadFilterUpdateManyWithoutOrganizationInput>;
  DashboardRepFilters?: InputMaybe<DashboardRepFilterUpdateManyWithoutOrganizationInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewUpdateManyWithoutOrganizationInput>;
  day_to_close?: InputMaybe<IntFieldUpdateOperationsInput>;
  default_demo_time_duration?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  demo_held_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_not_held_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_not_held_call_back_short_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_prep_time_minutes?: InputMaybe<IntFieldUpdateOperationsInput>;
  dev_account?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  dial_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  DispositionMultiplier?: InputMaybe<DispositionMultiplierUpdateManyWithoutOrganizationInput>;
  dispositions?: InputMaybe<DispositionUpdateManyWithoutOrganizationInput>;
  double_booking?: InputMaybe<BoolFieldUpdateOperationsInput>;
  dynamic_date_operators?: InputMaybe<BoolFieldUpdateOperationsInput>;
  edge_servers?: InputMaybe<OrganizationUpdateedge_ServersInput>;
  email_conflict_notification_email_list?: InputMaybe<OrganizationUpdateemail_Conflict_Notification_Email_ListInput>;
  EmailItems?: InputMaybe<EmailItemUpdateManyWithoutOrganizationInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordUpdateManyWithoutOrganizationInput>;
  EmailThreads?: InputMaybe<EmailThreadUpdateManyWithoutOrganizationInput>;
  enable_modified_metrics?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  enable_pay_period_view?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  enable_time_constraint?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  error_log_email_address?: InputMaybe<OrganizationUpdateerror_Log_Email_AddressInput>;
  estimated_average_num_of_weekly_overbooked_demo_slots?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  FolderPermissions?: InputMaybe<FolderPermissionUpdateManyWithoutOrganizationInput>;
  Folders?: InputMaybe<FolderUpdateManyWithoutOrganizationInput>;
  FutureBookingSetting?: InputMaybe<FutureBookingSettingUpdateOneWithoutOrganizationInput>;
  GlobalTransferRule?: InputMaybe<GlobalTransferRuleUpdateManyWithoutOrganizationInput>;
  held_demos_per_cc_connect?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  helpful_links?: InputMaybe<OrganizationUpdatehelpful_LinksInput>;
  HingePointMultiplier?: InputMaybe<HingePointMultiplierUpdateManyWithoutOrganizationInput>;
  hold_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
  hubspot_credential?: InputMaybe<HubSpotCredentialUpdateOneWithoutOrganizationInput>;
  hubspot_last_sync_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  hubspot_white_list?: InputMaybe<HubSpotWhitelistUpdateManyWithoutOrganizationInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inbound_close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_dm_connect_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_lt_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_revenue_value_of_sale?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_scheduled_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_set_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventUpdateManyWithoutOrganizationInput>;
  InboundConciergeWidget?: InputMaybe<InboundConciergeWidgetUpdateManyWithoutOrganizationInput>;
  industry_options?: InputMaybe<IndustryOptionUpdateManyWithoutOrganizationInput>;
  initial_sdr_sets_reps_day?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  integration_error_logs?: InputMaybe<IntegrationErrorLogUpdateManyWithoutOrganizationInput>;
  integration_mappings?: InputMaybe<IntegrationMappingUpdateManyWithoutOrganizationInput>;
  IntegrationMappingCustomObject?: InputMaybe<IntegrationMappingCustomObjectUpdateManyWithoutOrganizationInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogUpdateManyWithoutOrganizationInput>;
  IntegrationReadOnlyObject?: InputMaybe<IntegrationReadOnlyObjectUpdateManyWithoutOrganizationInput>;
  international?: InputMaybe<BoolFieldUpdateOperationsInput>;
  is_email_domain_verified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  is_salesforce_sandbox?: InputMaybe<BoolFieldUpdateOperationsInput>;
  last_lead_value_compute_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutOrganizationInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryUpdateManyWithoutOrganizationInput>;
  lead_intent?: InputMaybe<LeadIntentUpdateManyWithoutOrganizationInput>;
  lead_release_same_rep_delay_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  lead_routing_email_list?: InputMaybe<OrganizationUpdatelead_Routing_Email_ListInput>;
  lead_routing_rules?: InputMaybe<RuleUpdateManyWithoutOrganizationInput>;
  LeadCustomObjectRowAssociation?: InputMaybe<LeadCustomObjectRowAssociationUpdateManyWithoutOrganizationInput>;
  LeadFieldMultiplier?: InputMaybe<LeadFieldMultiplierUpdateManyWithoutOrganizationInput>;
  LeadFieldMultiplierCustom?: InputMaybe<LeadFieldMultiplierCustomUpdateManyWithoutOrganizationInput>;
  leads?: InputMaybe<LeadUpdateManyWithoutOrganizationInput>;
  leadsource_options?: InputMaybe<LeadSourceOptionUpdateManyWithoutOrganizationInput>;
  LeadValue?: InputMaybe<LeadValueUpdateManyWithoutOrganizationInput>;
  LifeTimeValueMultiplier?: InputMaybe<LifeTimeValueMultiplierUpdateManyWithoutOrganizationInput>;
  LiveTransferSetting?: InputMaybe<LiveTransferSettingUpdateOneWithoutOrganizationInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusUpdateManyWithoutOrganizationInput>;
  ltv_multiplier_custom_fields?: InputMaybe<LifeTimeValueMultiplierCustomUpdateManyWithoutOrganizationInput>;
  made_contact_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  make_sale_address_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_business_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_city_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_country_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_first_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_industry_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_last_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_lead_source_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_primary_email_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_primary_phone_number_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_state_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_sub_industry_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_zip_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  maxium_day_lag_set_to_schedule?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  mrr?: InputMaybe<IntFieldUpdateOperationsInput>;
  mrr_label?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  mrr_required_on_sale?: InputMaybe<BoolFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  NoteItems?: InputMaybe<NoteItemUpdateManyWithoutOrganizationInput>;
  Notifications?: InputMaybe<NotificationUpdateManyWithoutOrganizationInput>;
  onboardComplete?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  Opportunity?: InputMaybe<SfOpportunityUpdateManyWithoutOrganizationInput>;
  organization_holidays?: InputMaybe<OrganizationHolidayUpdateManyWithoutOrganizationInput>;
  organization_hours?: InputMaybe<OrganizationHourUpdateManyWithoutOrganizationInput>;
  OrganizationPaymentSetting?: InputMaybe<OrganizationPaymentSettingsUpdateOneWithoutOrganizationInput>;
  OrganizationPaymentSettings?: InputMaybe<OrganizationPaymentSettingsUpdateOneWithoutOrganizationsInput>;
  orgCalendarInviteMetadata?: InputMaybe<OrgCalendarInviteMetadataUpdateManyWithoutOrganizationInput>;
  OrgDefaultPipelinePerecentValue?: InputMaybe<OrgDefaultPipelinePerecentValueUpdateOneWithoutOrganizationInput>;
  OrgHubSpotSync?: InputMaybe<OrgHubSpotSyncUpdateOneWithoutOrganizationInput>;
  OrgNoEventDate?: InputMaybe<OrgNoEventDateUpdateManyWithoutOrganizationInput>;
  OrgSalesforceSync?: InputMaybe<OrgSalesforceSyncUpdateOneWithoutOrganizationInput>;
  OrgTimeslotAvailability?: InputMaybe<OrgTimeslotAvailabilityUpdateManyWithoutOrganizationInput>;
  outbound_close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_dm_connect_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_lt_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_revenue_value_of_sale?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_scheduled_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_set_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  PandadocData?: InputMaybe<PandadocDataUpdateOneWithoutOrganizationInput>;
  PandaDocTemplate?: InputMaybe<PandaDocTemplateUpdateManyWithoutOrganizationInput>;
  participant_jitter_buffer_size?: InputMaybe<EnumJitterBufferSizeFieldUpdateOperationsInput>;
  preferred_goal_setting?: InputMaybe<NullableEnumGoalsettingFieldUpdateOperationsInput>;
  prepayment?: InputMaybe<BoolFieldUpdateOperationsInput>;
  ProductChangeLog?: InputMaybe<ProductChangeLogUpdateManyWithoutOrganizationInput>;
  products?: InputMaybe<ProductUpdateManyWithoutOrganizationInput>;
  re_cold_call_hour_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  recordingStatus?: InputMaybe<EnumRecordingStatusFieldUpdateOperationsInput>;
  release_lead_resting?: InputMaybe<IntFieldUpdateOperationsInput>;
  rep_claim_limit?: InputMaybe<IntFieldUpdateOperationsInput>;
  rep_lead_allotment?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  rep_level_call_transfer?: InputMaybe<BoolFieldUpdateOperationsInput>;
  reps_can_bulk_unassign?: InputMaybe<BoolFieldUpdateOperationsInput>;
  reps_can_view_all_notes?: InputMaybe<BoolFieldUpdateOperationsInput>;
  require_sale_notes?: InputMaybe<BoolFieldUpdateOperationsInput>;
  resting_after_skipped_CC?: InputMaybe<IntFieldUpdateOperationsInput>;
  resting_period?: InputMaybe<IntFieldUpdateOperationsInput>;
  resting_period_after_unsuccessful_cold_call?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  resting_period_after_unsuccessful_demo?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  revenue_plan?: InputMaybe<RevenuePlanItemUpdateManyWithoutOrganizationInput>;
  Routings?: InputMaybe<RoutingUpdateManyWithoutOrganizationInput>;
  sale_field_options?: InputMaybe<SaleFieldOptionUpdateManyWithoutOrganizationInput>;
  sale_notification_email_list?: InputMaybe<OrganizationUpdatesale_Notification_Email_ListInput>;
  SaleConfig?: InputMaybe<SaleConfigUpdateOneWithoutOrganizationInput>;
  SaleConfigPage?: InputMaybe<SaleConfigPageUpdateManyWithoutOrganizationInput>;
  SaleConfigSection?: InputMaybe<SaleConfigSectionUpdateManyWithoutOrganizationInput>;
  SaleCycles?: InputMaybe<SaleCycleUpdateManyWithoutOrganizationInput>;
  SaleItems?: InputMaybe<SaleItemUpdateManyWithoutOrganizationInput>;
  sales?: InputMaybe<SaleUpdateManyWithoutOrganizationInput>;
  salesforce_domain?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  salesforce_last_sync_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  SalesForceColumn?: InputMaybe<SalesForceColumnUpdateManyWithoutOrganizationInput>;
  salesForceData?: InputMaybe<SalesForceDataUpdateOneWithoutOrganizationInput>;
  self_sourcing_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  send_error_log_email?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  send_lead_routing_email?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sendgrid_email_domain?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sendgrid_email_domain_id?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  sequence?: InputMaybe<SequenceUpdateManyWithoutOrganzationInput>;
  SequenceCriteriaConditions?: InputMaybe<SequenceCriteriaConditionUpdateManyWithoutOrganizationInput>;
  SequenceDashboardViews?: InputMaybe<SequenceDashboardViewUpdateManyWithoutOrganizationInput>;
  SequenceEntryCriterias?: InputMaybe<SequenceEntryCriteriaUpdateManyWithoutOrganizationInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutOrganizationInput>;
  SequenceSteps?: InputMaybe<SequenceStepUpdateManyWithoutOrganizationInput>;
  set_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
  short_resting?: InputMaybe<IntFieldUpdateOperationsInput>;
  show_calendar_options?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_contract_duration_sales?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_lead_source_to_rep?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_nocontact_button?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_payment_terms_sales?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_rep_all_leads?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_rep_my_leads?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_system_view?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sites?: InputMaybe<SiteUpdateManyWithoutOrganizationInput>;
  sms_opt_in_dialog?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SMSItems?: InputMaybe<SmsItemUpdateManyWithoutOrganizationInput>;
  SnoozeTrackings?: InputMaybe<SnoozeTrackingUpdateManyWithoutOrganizationInput>;
  StepActionLogs?: InputMaybe<StepActionLogUpdateManyWithoutOrganizationInput>;
  StepActions?: InputMaybe<StepActionUpdateManyWithoutOrganizationInput>;
  StepConditions?: InputMaybe<StepConditionUpdateManyWithoutOrganizationInput>;
  teams?: InputMaybe<TeamUpdateManyWithoutOrganizationInput>;
  Template?: InputMaybe<TemplateUpdateManyWithoutOrganizationInput>;
  TemplateVersions?: InputMaybe<TemplateVersionUpdateManyWithoutOrganizationInput>;
  timekit_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timezone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transfer_timeout?: InputMaybe<IntFieldUpdateOperationsInput>;
  TransferAttempts?: InputMaybe<TransferAttemptUpdateManyWithoutOrganizationInput>;
  twilio_numbers?: InputMaybe<TwilioNumberUpdateManyWithoutOrganizationInput>;
  TwilioFeedbacks?: InputMaybe<TwilioFeedbackUpdateManyWithoutOrganizationInput>;
  TwilioInfo?: InputMaybe<TwilioInfoUpdateOneWithoutOrganzationInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  use_revenue_planning?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  UserCustomFieldFilters?: InputMaybe<UserCustomFieldFilterUpdateManyWithoutOrganizationInput>;
  users?: InputMaybe<UserUpdateManyWithoutOrganizationInput>;
  win_rep_day_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
};

export type OrganizationUpdateWithoutSequenceCriteriaConditionsInput = {
  ActivityMonitorMultiplier?: InputMaybe<ActivityMonitorMultiplierUpdateManyWithoutOrganizationInput>;
  algoFactor?: InputMaybe<AlgoFactorUpdateManyWithoutOrganizationInput>;
  allow_calendar_invite?: InputMaybe<BoolFieldUpdateOperationsInput>;
  allow_remove_step_action?: InputMaybe<BoolFieldUpdateOperationsInput>;
  allow_snooze_step_action?: InputMaybe<BoolFieldUpdateOperationsInput>;
  always_record?: InputMaybe<BoolFieldUpdateOperationsInput>;
  arv_initial_held_demos_generated_by_sdr_weight?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  AssociationHistories?: InputMaybe<AssociationHistoryUpdateManyWithoutOrganizationInput>;
  auto_call_interval?: InputMaybe<IntFieldUpdateOperationsInput>;
  auto_dial?: InputMaybe<BoolFieldUpdateOperationsInput>;
  automated_campaigns?: InputMaybe<AutomatedCampaignUpdateManyWithoutOrganizationInput>;
  automatic_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  AutoTransferSelections?: InputMaybe<AutoTransferSelectionUpdateManyWithoutOrganizationInput>;
  Availablities?: InputMaybe<AvailabilityUpdateManyWithoutOrganizationInput>;
  AverageOrderValue?: InputMaybe<AverageOrderValueUpdateManyWithoutOrganizationInput>;
  best_action_ever_multiplier?: InputMaybe<FloatFieldUpdateOperationsInput>;
  best_metrics?: InputMaybe<BestMetricRecordUpdateManyWithoutOrganizationInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptUpdateManyWithoutOrganizationInput>;
  callMeNows?: InputMaybe<CallMeNowUpdateManyWithoutOrganizationInput>;
  CallMultiplier?: InputMaybe<CallMultiplierUpdateManyWithoutOrganizationInput>;
  CallSharings?: InputMaybe<CallSharingUpdateManyWithoutOrganizationInput>;
  can_edit_sdr_transfer_rules?: InputMaybe<BoolFieldUpdateOperationsInput>;
  claim_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  concession?: InputMaybe<ConcessionUpdateManyWithoutOrganizationInput>;
  conference_region?: InputMaybe<EnumConferenceRegionFieldUpdateOperationsInput>;
  Conferences?: InputMaybe<ConferenceUpdateManyWithoutOrganizationInput>;
  ContactRequests?: InputMaybe<ContactRequestUpdateManyWithoutOrganizationInput>;
  Contacts?: InputMaybe<ContactUpdateManyWithoutOrganizationInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  custom_fields?: InputMaybe<CustomFieldUpdateManyWithoutOrganizationInput>;
  CustomObject?: InputMaybe<CustomObjectUpdateManyWithoutOrganizationInput>;
  CustomObjectField?: InputMaybe<CustomObjectFieldUpdateManyWithoutOrganizationInput>;
  CustomObjectFieldForeignKey?: InputMaybe<CustomObjectFieldForeignKeyUpdateManyWithoutOrganizationInput>;
  CustomObjectRecord?: InputMaybe<CustomObjectRecordUpdateManyWithoutOrganizationInput>;
  CustomObjectRow?: InputMaybe<CustomObjectRowUpdateManyWithoutOrganizationInput>;
  CustomQueueItems?: InputMaybe<CustomQueueItemUpdateManyWithoutOrganizationInput>;
  DashboardColumnOptions?: InputMaybe<DashboardColumnOptionUpdateManyWithoutOrganizationInput>;
  DashboardLeadFilters?: InputMaybe<DashboardLeadFilterUpdateManyWithoutOrganizationInput>;
  DashboardRepFilters?: InputMaybe<DashboardRepFilterUpdateManyWithoutOrganizationInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewUpdateManyWithoutOrganizationInput>;
  day_to_close?: InputMaybe<IntFieldUpdateOperationsInput>;
  default_demo_time_duration?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  demo_held_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_not_held_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_not_held_call_back_short_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_prep_time_minutes?: InputMaybe<IntFieldUpdateOperationsInput>;
  dev_account?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  dial_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  DispositionMultiplier?: InputMaybe<DispositionMultiplierUpdateManyWithoutOrganizationInput>;
  dispositions?: InputMaybe<DispositionUpdateManyWithoutOrganizationInput>;
  double_booking?: InputMaybe<BoolFieldUpdateOperationsInput>;
  dynamic_date_operators?: InputMaybe<BoolFieldUpdateOperationsInput>;
  edge_servers?: InputMaybe<OrganizationUpdateedge_ServersInput>;
  email_conflict_notification_email_list?: InputMaybe<OrganizationUpdateemail_Conflict_Notification_Email_ListInput>;
  EmailItems?: InputMaybe<EmailItemUpdateManyWithoutOrganizationInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordUpdateManyWithoutOrganizationInput>;
  EmailThreads?: InputMaybe<EmailThreadUpdateManyWithoutOrganizationInput>;
  enable_modified_metrics?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  enable_pay_period_view?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  enable_time_constraint?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  error_log_email_address?: InputMaybe<OrganizationUpdateerror_Log_Email_AddressInput>;
  estimated_average_num_of_weekly_overbooked_demo_slots?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  FolderPermissions?: InputMaybe<FolderPermissionUpdateManyWithoutOrganizationInput>;
  Folders?: InputMaybe<FolderUpdateManyWithoutOrganizationInput>;
  FutureBookingSetting?: InputMaybe<FutureBookingSettingUpdateOneWithoutOrganizationInput>;
  GlobalTransferRule?: InputMaybe<GlobalTransferRuleUpdateManyWithoutOrganizationInput>;
  held_demos_per_cc_connect?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  helpful_links?: InputMaybe<OrganizationUpdatehelpful_LinksInput>;
  HingePointMultiplier?: InputMaybe<HingePointMultiplierUpdateManyWithoutOrganizationInput>;
  hold_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
  hubspot_credential?: InputMaybe<HubSpotCredentialUpdateOneWithoutOrganizationInput>;
  hubspot_last_sync_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  hubspot_white_list?: InputMaybe<HubSpotWhitelistUpdateManyWithoutOrganizationInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inbound_close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_dm_connect_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_lt_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_revenue_value_of_sale?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_scheduled_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_set_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventUpdateManyWithoutOrganizationInput>;
  InboundConciergeWidget?: InputMaybe<InboundConciergeWidgetUpdateManyWithoutOrganizationInput>;
  industry_options?: InputMaybe<IndustryOptionUpdateManyWithoutOrganizationInput>;
  initial_sdr_sets_reps_day?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  integration_error_logs?: InputMaybe<IntegrationErrorLogUpdateManyWithoutOrganizationInput>;
  integration_mappings?: InputMaybe<IntegrationMappingUpdateManyWithoutOrganizationInput>;
  IntegrationMappingCustomObject?: InputMaybe<IntegrationMappingCustomObjectUpdateManyWithoutOrganizationInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogUpdateManyWithoutOrganizationInput>;
  IntegrationReadOnlyObject?: InputMaybe<IntegrationReadOnlyObjectUpdateManyWithoutOrganizationInput>;
  international?: InputMaybe<BoolFieldUpdateOperationsInput>;
  is_email_domain_verified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  is_salesforce_sandbox?: InputMaybe<BoolFieldUpdateOperationsInput>;
  last_lead_value_compute_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutOrganizationInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryUpdateManyWithoutOrganizationInput>;
  lead_intent?: InputMaybe<LeadIntentUpdateManyWithoutOrganizationInput>;
  lead_release_same_rep_delay_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  lead_routing_email_list?: InputMaybe<OrganizationUpdatelead_Routing_Email_ListInput>;
  lead_routing_rules?: InputMaybe<RuleUpdateManyWithoutOrganizationInput>;
  LeadCustomObjectRowAssociation?: InputMaybe<LeadCustomObjectRowAssociationUpdateManyWithoutOrganizationInput>;
  LeadFieldMultiplier?: InputMaybe<LeadFieldMultiplierUpdateManyWithoutOrganizationInput>;
  LeadFieldMultiplierCustom?: InputMaybe<LeadFieldMultiplierCustomUpdateManyWithoutOrganizationInput>;
  leads?: InputMaybe<LeadUpdateManyWithoutOrganizationInput>;
  leadsource_options?: InputMaybe<LeadSourceOptionUpdateManyWithoutOrganizationInput>;
  LeadValue?: InputMaybe<LeadValueUpdateManyWithoutOrganizationInput>;
  LifeTimeValueMultiplier?: InputMaybe<LifeTimeValueMultiplierUpdateManyWithoutOrganizationInput>;
  LiveTransferSetting?: InputMaybe<LiveTransferSettingUpdateOneWithoutOrganizationInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusUpdateManyWithoutOrganizationInput>;
  ltv_multiplier_custom_fields?: InputMaybe<LifeTimeValueMultiplierCustomUpdateManyWithoutOrganizationInput>;
  made_contact_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  make_sale_address_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_business_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_city_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_country_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_first_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_industry_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_last_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_lead_source_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_primary_email_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_primary_phone_number_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_state_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_sub_industry_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_zip_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  maxium_day_lag_set_to_schedule?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  mrr?: InputMaybe<IntFieldUpdateOperationsInput>;
  mrr_label?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  mrr_required_on_sale?: InputMaybe<BoolFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  NoteItems?: InputMaybe<NoteItemUpdateManyWithoutOrganizationInput>;
  Notifications?: InputMaybe<NotificationUpdateManyWithoutOrganizationInput>;
  onboardComplete?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  Opportunity?: InputMaybe<SfOpportunityUpdateManyWithoutOrganizationInput>;
  organization_holidays?: InputMaybe<OrganizationHolidayUpdateManyWithoutOrganizationInput>;
  organization_hours?: InputMaybe<OrganizationHourUpdateManyWithoutOrganizationInput>;
  OrganizationPaymentSetting?: InputMaybe<OrganizationPaymentSettingsUpdateOneWithoutOrganizationInput>;
  OrganizationPaymentSettings?: InputMaybe<OrganizationPaymentSettingsUpdateOneWithoutOrganizationsInput>;
  orgCalendarInviteMetadata?: InputMaybe<OrgCalendarInviteMetadataUpdateManyWithoutOrganizationInput>;
  OrgDefaultPipelinePerecentValue?: InputMaybe<OrgDefaultPipelinePerecentValueUpdateOneWithoutOrganizationInput>;
  OrgHubSpotSync?: InputMaybe<OrgHubSpotSyncUpdateOneWithoutOrganizationInput>;
  OrgNoEventDate?: InputMaybe<OrgNoEventDateUpdateManyWithoutOrganizationInput>;
  OrgSalesforceSync?: InputMaybe<OrgSalesforceSyncUpdateOneWithoutOrganizationInput>;
  OrgTimeslotAvailability?: InputMaybe<OrgTimeslotAvailabilityUpdateManyWithoutOrganizationInput>;
  outbound_close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_dm_connect_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_lt_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_revenue_value_of_sale?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_scheduled_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_set_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  PandadocData?: InputMaybe<PandadocDataUpdateOneWithoutOrganizationInput>;
  PandaDocTemplate?: InputMaybe<PandaDocTemplateUpdateManyWithoutOrganizationInput>;
  participant_jitter_buffer_size?: InputMaybe<EnumJitterBufferSizeFieldUpdateOperationsInput>;
  preferred_goal_setting?: InputMaybe<NullableEnumGoalsettingFieldUpdateOperationsInput>;
  prepayment?: InputMaybe<BoolFieldUpdateOperationsInput>;
  ProductChangeLog?: InputMaybe<ProductChangeLogUpdateManyWithoutOrganizationInput>;
  products?: InputMaybe<ProductUpdateManyWithoutOrganizationInput>;
  re_cold_call_hour_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  recordingStatus?: InputMaybe<EnumRecordingStatusFieldUpdateOperationsInput>;
  release_lead_resting?: InputMaybe<IntFieldUpdateOperationsInput>;
  rep_claim_limit?: InputMaybe<IntFieldUpdateOperationsInput>;
  rep_lead_allotment?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  rep_level_call_transfer?: InputMaybe<BoolFieldUpdateOperationsInput>;
  reps_can_bulk_unassign?: InputMaybe<BoolFieldUpdateOperationsInput>;
  reps_can_view_all_notes?: InputMaybe<BoolFieldUpdateOperationsInput>;
  require_sale_notes?: InputMaybe<BoolFieldUpdateOperationsInput>;
  resting_after_skipped_CC?: InputMaybe<IntFieldUpdateOperationsInput>;
  resting_period?: InputMaybe<IntFieldUpdateOperationsInput>;
  resting_period_after_unsuccessful_cold_call?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  resting_period_after_unsuccessful_demo?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  revenue_plan?: InputMaybe<RevenuePlanItemUpdateManyWithoutOrganizationInput>;
  Routings?: InputMaybe<RoutingUpdateManyWithoutOrganizationInput>;
  sale_field_options?: InputMaybe<SaleFieldOptionUpdateManyWithoutOrganizationInput>;
  sale_notification_email_list?: InputMaybe<OrganizationUpdatesale_Notification_Email_ListInput>;
  SaleConfig?: InputMaybe<SaleConfigUpdateOneWithoutOrganizationInput>;
  SaleConfigPage?: InputMaybe<SaleConfigPageUpdateManyWithoutOrganizationInput>;
  SaleConfigSection?: InputMaybe<SaleConfigSectionUpdateManyWithoutOrganizationInput>;
  SaleCycles?: InputMaybe<SaleCycleUpdateManyWithoutOrganizationInput>;
  SaleItems?: InputMaybe<SaleItemUpdateManyWithoutOrganizationInput>;
  sales?: InputMaybe<SaleUpdateManyWithoutOrganizationInput>;
  salesforce_domain?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  salesforce_last_sync_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  SalesForceColumn?: InputMaybe<SalesForceColumnUpdateManyWithoutOrganizationInput>;
  salesForceData?: InputMaybe<SalesForceDataUpdateOneWithoutOrganizationInput>;
  ScheduleItems?: InputMaybe<ScheduleItemUpdateManyWithoutOrganizationInput>;
  self_sourcing_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  send_error_log_email?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  send_lead_routing_email?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sendgrid_email_domain?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sendgrid_email_domain_id?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  sequence?: InputMaybe<SequenceUpdateManyWithoutOrganzationInput>;
  SequenceDashboardViews?: InputMaybe<SequenceDashboardViewUpdateManyWithoutOrganizationInput>;
  SequenceEntryCriterias?: InputMaybe<SequenceEntryCriteriaUpdateManyWithoutOrganizationInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutOrganizationInput>;
  SequenceSteps?: InputMaybe<SequenceStepUpdateManyWithoutOrganizationInput>;
  set_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
  short_resting?: InputMaybe<IntFieldUpdateOperationsInput>;
  show_calendar_options?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_contract_duration_sales?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_lead_source_to_rep?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_nocontact_button?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_payment_terms_sales?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_rep_all_leads?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_rep_my_leads?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_system_view?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sites?: InputMaybe<SiteUpdateManyWithoutOrganizationInput>;
  sms_opt_in_dialog?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SMSItems?: InputMaybe<SmsItemUpdateManyWithoutOrganizationInput>;
  SnoozeTrackings?: InputMaybe<SnoozeTrackingUpdateManyWithoutOrganizationInput>;
  StepActionLogs?: InputMaybe<StepActionLogUpdateManyWithoutOrganizationInput>;
  StepActions?: InputMaybe<StepActionUpdateManyWithoutOrganizationInput>;
  StepConditions?: InputMaybe<StepConditionUpdateManyWithoutOrganizationInput>;
  teams?: InputMaybe<TeamUpdateManyWithoutOrganizationInput>;
  Template?: InputMaybe<TemplateUpdateManyWithoutOrganizationInput>;
  TemplateVersions?: InputMaybe<TemplateVersionUpdateManyWithoutOrganizationInput>;
  timekit_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timezone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transfer_timeout?: InputMaybe<IntFieldUpdateOperationsInput>;
  TransferAttempts?: InputMaybe<TransferAttemptUpdateManyWithoutOrganizationInput>;
  twilio_numbers?: InputMaybe<TwilioNumberUpdateManyWithoutOrganizationInput>;
  TwilioFeedbacks?: InputMaybe<TwilioFeedbackUpdateManyWithoutOrganizationInput>;
  TwilioInfo?: InputMaybe<TwilioInfoUpdateOneWithoutOrganzationInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  use_revenue_planning?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  UserCustomFieldFilters?: InputMaybe<UserCustomFieldFilterUpdateManyWithoutOrganizationInput>;
  users?: InputMaybe<UserUpdateManyWithoutOrganizationInput>;
  win_rep_day_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
};

export type OrganizationUpdateWithoutSequenceDashboardViewsInput = {
  ActivityMonitorMultiplier?: InputMaybe<ActivityMonitorMultiplierUpdateManyWithoutOrganizationInput>;
  algoFactor?: InputMaybe<AlgoFactorUpdateManyWithoutOrganizationInput>;
  allow_calendar_invite?: InputMaybe<BoolFieldUpdateOperationsInput>;
  allow_remove_step_action?: InputMaybe<BoolFieldUpdateOperationsInput>;
  allow_snooze_step_action?: InputMaybe<BoolFieldUpdateOperationsInput>;
  always_record?: InputMaybe<BoolFieldUpdateOperationsInput>;
  arv_initial_held_demos_generated_by_sdr_weight?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  AssociationHistories?: InputMaybe<AssociationHistoryUpdateManyWithoutOrganizationInput>;
  auto_call_interval?: InputMaybe<IntFieldUpdateOperationsInput>;
  auto_dial?: InputMaybe<BoolFieldUpdateOperationsInput>;
  automated_campaigns?: InputMaybe<AutomatedCampaignUpdateManyWithoutOrganizationInput>;
  automatic_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  AutoTransferSelections?: InputMaybe<AutoTransferSelectionUpdateManyWithoutOrganizationInput>;
  Availablities?: InputMaybe<AvailabilityUpdateManyWithoutOrganizationInput>;
  AverageOrderValue?: InputMaybe<AverageOrderValueUpdateManyWithoutOrganizationInput>;
  best_action_ever_multiplier?: InputMaybe<FloatFieldUpdateOperationsInput>;
  best_metrics?: InputMaybe<BestMetricRecordUpdateManyWithoutOrganizationInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptUpdateManyWithoutOrganizationInput>;
  callMeNows?: InputMaybe<CallMeNowUpdateManyWithoutOrganizationInput>;
  CallMultiplier?: InputMaybe<CallMultiplierUpdateManyWithoutOrganizationInput>;
  CallSharings?: InputMaybe<CallSharingUpdateManyWithoutOrganizationInput>;
  can_edit_sdr_transfer_rules?: InputMaybe<BoolFieldUpdateOperationsInput>;
  claim_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  concession?: InputMaybe<ConcessionUpdateManyWithoutOrganizationInput>;
  conference_region?: InputMaybe<EnumConferenceRegionFieldUpdateOperationsInput>;
  Conferences?: InputMaybe<ConferenceUpdateManyWithoutOrganizationInput>;
  ContactRequests?: InputMaybe<ContactRequestUpdateManyWithoutOrganizationInput>;
  Contacts?: InputMaybe<ContactUpdateManyWithoutOrganizationInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  custom_fields?: InputMaybe<CustomFieldUpdateManyWithoutOrganizationInput>;
  CustomObject?: InputMaybe<CustomObjectUpdateManyWithoutOrganizationInput>;
  CustomObjectField?: InputMaybe<CustomObjectFieldUpdateManyWithoutOrganizationInput>;
  CustomObjectFieldForeignKey?: InputMaybe<CustomObjectFieldForeignKeyUpdateManyWithoutOrganizationInput>;
  CustomObjectRecord?: InputMaybe<CustomObjectRecordUpdateManyWithoutOrganizationInput>;
  CustomObjectRow?: InputMaybe<CustomObjectRowUpdateManyWithoutOrganizationInput>;
  CustomQueueItems?: InputMaybe<CustomQueueItemUpdateManyWithoutOrganizationInput>;
  DashboardColumnOptions?: InputMaybe<DashboardColumnOptionUpdateManyWithoutOrganizationInput>;
  DashboardLeadFilters?: InputMaybe<DashboardLeadFilterUpdateManyWithoutOrganizationInput>;
  DashboardRepFilters?: InputMaybe<DashboardRepFilterUpdateManyWithoutOrganizationInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewUpdateManyWithoutOrganizationInput>;
  day_to_close?: InputMaybe<IntFieldUpdateOperationsInput>;
  default_demo_time_duration?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  demo_held_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_not_held_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_not_held_call_back_short_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_prep_time_minutes?: InputMaybe<IntFieldUpdateOperationsInput>;
  dev_account?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  dial_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  DispositionMultiplier?: InputMaybe<DispositionMultiplierUpdateManyWithoutOrganizationInput>;
  dispositions?: InputMaybe<DispositionUpdateManyWithoutOrganizationInput>;
  double_booking?: InputMaybe<BoolFieldUpdateOperationsInput>;
  dynamic_date_operators?: InputMaybe<BoolFieldUpdateOperationsInput>;
  edge_servers?: InputMaybe<OrganizationUpdateedge_ServersInput>;
  email_conflict_notification_email_list?: InputMaybe<OrganizationUpdateemail_Conflict_Notification_Email_ListInput>;
  EmailItems?: InputMaybe<EmailItemUpdateManyWithoutOrganizationInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordUpdateManyWithoutOrganizationInput>;
  EmailThreads?: InputMaybe<EmailThreadUpdateManyWithoutOrganizationInput>;
  enable_modified_metrics?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  enable_pay_period_view?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  enable_time_constraint?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  error_log_email_address?: InputMaybe<OrganizationUpdateerror_Log_Email_AddressInput>;
  estimated_average_num_of_weekly_overbooked_demo_slots?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  FolderPermissions?: InputMaybe<FolderPermissionUpdateManyWithoutOrganizationInput>;
  Folders?: InputMaybe<FolderUpdateManyWithoutOrganizationInput>;
  FutureBookingSetting?: InputMaybe<FutureBookingSettingUpdateOneWithoutOrganizationInput>;
  GlobalTransferRule?: InputMaybe<GlobalTransferRuleUpdateManyWithoutOrganizationInput>;
  held_demos_per_cc_connect?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  helpful_links?: InputMaybe<OrganizationUpdatehelpful_LinksInput>;
  HingePointMultiplier?: InputMaybe<HingePointMultiplierUpdateManyWithoutOrganizationInput>;
  hold_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
  hubspot_credential?: InputMaybe<HubSpotCredentialUpdateOneWithoutOrganizationInput>;
  hubspot_last_sync_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  hubspot_white_list?: InputMaybe<HubSpotWhitelistUpdateManyWithoutOrganizationInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inbound_close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_dm_connect_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_lt_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_revenue_value_of_sale?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_scheduled_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_set_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventUpdateManyWithoutOrganizationInput>;
  InboundConciergeWidget?: InputMaybe<InboundConciergeWidgetUpdateManyWithoutOrganizationInput>;
  industry_options?: InputMaybe<IndustryOptionUpdateManyWithoutOrganizationInput>;
  initial_sdr_sets_reps_day?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  integration_error_logs?: InputMaybe<IntegrationErrorLogUpdateManyWithoutOrganizationInput>;
  integration_mappings?: InputMaybe<IntegrationMappingUpdateManyWithoutOrganizationInput>;
  IntegrationMappingCustomObject?: InputMaybe<IntegrationMappingCustomObjectUpdateManyWithoutOrganizationInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogUpdateManyWithoutOrganizationInput>;
  IntegrationReadOnlyObject?: InputMaybe<IntegrationReadOnlyObjectUpdateManyWithoutOrganizationInput>;
  international?: InputMaybe<BoolFieldUpdateOperationsInput>;
  is_email_domain_verified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  is_salesforce_sandbox?: InputMaybe<BoolFieldUpdateOperationsInput>;
  last_lead_value_compute_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutOrganizationInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryUpdateManyWithoutOrganizationInput>;
  lead_intent?: InputMaybe<LeadIntentUpdateManyWithoutOrganizationInput>;
  lead_release_same_rep_delay_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  lead_routing_email_list?: InputMaybe<OrganizationUpdatelead_Routing_Email_ListInput>;
  lead_routing_rules?: InputMaybe<RuleUpdateManyWithoutOrganizationInput>;
  LeadCustomObjectRowAssociation?: InputMaybe<LeadCustomObjectRowAssociationUpdateManyWithoutOrganizationInput>;
  LeadFieldMultiplier?: InputMaybe<LeadFieldMultiplierUpdateManyWithoutOrganizationInput>;
  LeadFieldMultiplierCustom?: InputMaybe<LeadFieldMultiplierCustomUpdateManyWithoutOrganizationInput>;
  leads?: InputMaybe<LeadUpdateManyWithoutOrganizationInput>;
  leadsource_options?: InputMaybe<LeadSourceOptionUpdateManyWithoutOrganizationInput>;
  LeadValue?: InputMaybe<LeadValueUpdateManyWithoutOrganizationInput>;
  LifeTimeValueMultiplier?: InputMaybe<LifeTimeValueMultiplierUpdateManyWithoutOrganizationInput>;
  LiveTransferSetting?: InputMaybe<LiveTransferSettingUpdateOneWithoutOrganizationInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusUpdateManyWithoutOrganizationInput>;
  ltv_multiplier_custom_fields?: InputMaybe<LifeTimeValueMultiplierCustomUpdateManyWithoutOrganizationInput>;
  made_contact_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  make_sale_address_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_business_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_city_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_country_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_first_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_industry_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_last_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_lead_source_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_primary_email_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_primary_phone_number_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_state_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_sub_industry_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_zip_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  maxium_day_lag_set_to_schedule?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  mrr?: InputMaybe<IntFieldUpdateOperationsInput>;
  mrr_label?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  mrr_required_on_sale?: InputMaybe<BoolFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  NoteItems?: InputMaybe<NoteItemUpdateManyWithoutOrganizationInput>;
  Notifications?: InputMaybe<NotificationUpdateManyWithoutOrganizationInput>;
  onboardComplete?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  Opportunity?: InputMaybe<SfOpportunityUpdateManyWithoutOrganizationInput>;
  organization_holidays?: InputMaybe<OrganizationHolidayUpdateManyWithoutOrganizationInput>;
  organization_hours?: InputMaybe<OrganizationHourUpdateManyWithoutOrganizationInput>;
  OrganizationPaymentSetting?: InputMaybe<OrganizationPaymentSettingsUpdateOneWithoutOrganizationInput>;
  OrganizationPaymentSettings?: InputMaybe<OrganizationPaymentSettingsUpdateOneWithoutOrganizationsInput>;
  orgCalendarInviteMetadata?: InputMaybe<OrgCalendarInviteMetadataUpdateManyWithoutOrganizationInput>;
  OrgDefaultPipelinePerecentValue?: InputMaybe<OrgDefaultPipelinePerecentValueUpdateOneWithoutOrganizationInput>;
  OrgHubSpotSync?: InputMaybe<OrgHubSpotSyncUpdateOneWithoutOrganizationInput>;
  OrgNoEventDate?: InputMaybe<OrgNoEventDateUpdateManyWithoutOrganizationInput>;
  OrgSalesforceSync?: InputMaybe<OrgSalesforceSyncUpdateOneWithoutOrganizationInput>;
  OrgTimeslotAvailability?: InputMaybe<OrgTimeslotAvailabilityUpdateManyWithoutOrganizationInput>;
  outbound_close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_dm_connect_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_lt_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_revenue_value_of_sale?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_scheduled_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_set_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  PandadocData?: InputMaybe<PandadocDataUpdateOneWithoutOrganizationInput>;
  PandaDocTemplate?: InputMaybe<PandaDocTemplateUpdateManyWithoutOrganizationInput>;
  participant_jitter_buffer_size?: InputMaybe<EnumJitterBufferSizeFieldUpdateOperationsInput>;
  preferred_goal_setting?: InputMaybe<NullableEnumGoalsettingFieldUpdateOperationsInput>;
  prepayment?: InputMaybe<BoolFieldUpdateOperationsInput>;
  ProductChangeLog?: InputMaybe<ProductChangeLogUpdateManyWithoutOrganizationInput>;
  products?: InputMaybe<ProductUpdateManyWithoutOrganizationInput>;
  re_cold_call_hour_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  recordingStatus?: InputMaybe<EnumRecordingStatusFieldUpdateOperationsInput>;
  release_lead_resting?: InputMaybe<IntFieldUpdateOperationsInput>;
  rep_claim_limit?: InputMaybe<IntFieldUpdateOperationsInput>;
  rep_lead_allotment?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  rep_level_call_transfer?: InputMaybe<BoolFieldUpdateOperationsInput>;
  reps_can_bulk_unassign?: InputMaybe<BoolFieldUpdateOperationsInput>;
  reps_can_view_all_notes?: InputMaybe<BoolFieldUpdateOperationsInput>;
  require_sale_notes?: InputMaybe<BoolFieldUpdateOperationsInput>;
  resting_after_skipped_CC?: InputMaybe<IntFieldUpdateOperationsInput>;
  resting_period?: InputMaybe<IntFieldUpdateOperationsInput>;
  resting_period_after_unsuccessful_cold_call?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  resting_period_after_unsuccessful_demo?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  revenue_plan?: InputMaybe<RevenuePlanItemUpdateManyWithoutOrganizationInput>;
  Routings?: InputMaybe<RoutingUpdateManyWithoutOrganizationInput>;
  sale_field_options?: InputMaybe<SaleFieldOptionUpdateManyWithoutOrganizationInput>;
  sale_notification_email_list?: InputMaybe<OrganizationUpdatesale_Notification_Email_ListInput>;
  SaleConfig?: InputMaybe<SaleConfigUpdateOneWithoutOrganizationInput>;
  SaleConfigPage?: InputMaybe<SaleConfigPageUpdateManyWithoutOrganizationInput>;
  SaleConfigSection?: InputMaybe<SaleConfigSectionUpdateManyWithoutOrganizationInput>;
  SaleCycles?: InputMaybe<SaleCycleUpdateManyWithoutOrganizationInput>;
  SaleItems?: InputMaybe<SaleItemUpdateManyWithoutOrganizationInput>;
  sales?: InputMaybe<SaleUpdateManyWithoutOrganizationInput>;
  salesforce_domain?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  salesforce_last_sync_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  SalesForceColumn?: InputMaybe<SalesForceColumnUpdateManyWithoutOrganizationInput>;
  salesForceData?: InputMaybe<SalesForceDataUpdateOneWithoutOrganizationInput>;
  ScheduleItems?: InputMaybe<ScheduleItemUpdateManyWithoutOrganizationInput>;
  self_sourcing_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  send_error_log_email?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  send_lead_routing_email?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sendgrid_email_domain?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sendgrid_email_domain_id?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  sequence?: InputMaybe<SequenceUpdateManyWithoutOrganzationInput>;
  SequenceCriteriaConditions?: InputMaybe<SequenceCriteriaConditionUpdateManyWithoutOrganizationInput>;
  SequenceEntryCriterias?: InputMaybe<SequenceEntryCriteriaUpdateManyWithoutOrganizationInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutOrganizationInput>;
  SequenceSteps?: InputMaybe<SequenceStepUpdateManyWithoutOrganizationInput>;
  set_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
  short_resting?: InputMaybe<IntFieldUpdateOperationsInput>;
  show_calendar_options?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_contract_duration_sales?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_lead_source_to_rep?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_nocontact_button?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_payment_terms_sales?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_rep_all_leads?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_rep_my_leads?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_system_view?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sites?: InputMaybe<SiteUpdateManyWithoutOrganizationInput>;
  sms_opt_in_dialog?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SMSItems?: InputMaybe<SmsItemUpdateManyWithoutOrganizationInput>;
  SnoozeTrackings?: InputMaybe<SnoozeTrackingUpdateManyWithoutOrganizationInput>;
  StepActionLogs?: InputMaybe<StepActionLogUpdateManyWithoutOrganizationInput>;
  StepActions?: InputMaybe<StepActionUpdateManyWithoutOrganizationInput>;
  StepConditions?: InputMaybe<StepConditionUpdateManyWithoutOrganizationInput>;
  teams?: InputMaybe<TeamUpdateManyWithoutOrganizationInput>;
  Template?: InputMaybe<TemplateUpdateManyWithoutOrganizationInput>;
  TemplateVersions?: InputMaybe<TemplateVersionUpdateManyWithoutOrganizationInput>;
  timekit_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timezone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transfer_timeout?: InputMaybe<IntFieldUpdateOperationsInput>;
  TransferAttempts?: InputMaybe<TransferAttemptUpdateManyWithoutOrganizationInput>;
  twilio_numbers?: InputMaybe<TwilioNumberUpdateManyWithoutOrganizationInput>;
  TwilioFeedbacks?: InputMaybe<TwilioFeedbackUpdateManyWithoutOrganizationInput>;
  TwilioInfo?: InputMaybe<TwilioInfoUpdateOneWithoutOrganzationInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  use_revenue_planning?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  UserCustomFieldFilters?: InputMaybe<UserCustomFieldFilterUpdateManyWithoutOrganizationInput>;
  users?: InputMaybe<UserUpdateManyWithoutOrganizationInput>;
  win_rep_day_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
};

export type OrganizationUpdateWithoutSequenceEntryCriteriasInput = {
  ActivityMonitorMultiplier?: InputMaybe<ActivityMonitorMultiplierUpdateManyWithoutOrganizationInput>;
  algoFactor?: InputMaybe<AlgoFactorUpdateManyWithoutOrganizationInput>;
  allow_calendar_invite?: InputMaybe<BoolFieldUpdateOperationsInput>;
  allow_remove_step_action?: InputMaybe<BoolFieldUpdateOperationsInput>;
  allow_snooze_step_action?: InputMaybe<BoolFieldUpdateOperationsInput>;
  always_record?: InputMaybe<BoolFieldUpdateOperationsInput>;
  arv_initial_held_demos_generated_by_sdr_weight?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  AssociationHistories?: InputMaybe<AssociationHistoryUpdateManyWithoutOrganizationInput>;
  auto_call_interval?: InputMaybe<IntFieldUpdateOperationsInput>;
  auto_dial?: InputMaybe<BoolFieldUpdateOperationsInput>;
  automated_campaigns?: InputMaybe<AutomatedCampaignUpdateManyWithoutOrganizationInput>;
  automatic_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  AutoTransferSelections?: InputMaybe<AutoTransferSelectionUpdateManyWithoutOrganizationInput>;
  Availablities?: InputMaybe<AvailabilityUpdateManyWithoutOrganizationInput>;
  AverageOrderValue?: InputMaybe<AverageOrderValueUpdateManyWithoutOrganizationInput>;
  best_action_ever_multiplier?: InputMaybe<FloatFieldUpdateOperationsInput>;
  best_metrics?: InputMaybe<BestMetricRecordUpdateManyWithoutOrganizationInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptUpdateManyWithoutOrganizationInput>;
  callMeNows?: InputMaybe<CallMeNowUpdateManyWithoutOrganizationInput>;
  CallMultiplier?: InputMaybe<CallMultiplierUpdateManyWithoutOrganizationInput>;
  CallSharings?: InputMaybe<CallSharingUpdateManyWithoutOrganizationInput>;
  can_edit_sdr_transfer_rules?: InputMaybe<BoolFieldUpdateOperationsInput>;
  claim_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  concession?: InputMaybe<ConcessionUpdateManyWithoutOrganizationInput>;
  conference_region?: InputMaybe<EnumConferenceRegionFieldUpdateOperationsInput>;
  Conferences?: InputMaybe<ConferenceUpdateManyWithoutOrganizationInput>;
  ContactRequests?: InputMaybe<ContactRequestUpdateManyWithoutOrganizationInput>;
  Contacts?: InputMaybe<ContactUpdateManyWithoutOrganizationInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  custom_fields?: InputMaybe<CustomFieldUpdateManyWithoutOrganizationInput>;
  CustomObject?: InputMaybe<CustomObjectUpdateManyWithoutOrganizationInput>;
  CustomObjectField?: InputMaybe<CustomObjectFieldUpdateManyWithoutOrganizationInput>;
  CustomObjectFieldForeignKey?: InputMaybe<CustomObjectFieldForeignKeyUpdateManyWithoutOrganizationInput>;
  CustomObjectRecord?: InputMaybe<CustomObjectRecordUpdateManyWithoutOrganizationInput>;
  CustomObjectRow?: InputMaybe<CustomObjectRowUpdateManyWithoutOrganizationInput>;
  CustomQueueItems?: InputMaybe<CustomQueueItemUpdateManyWithoutOrganizationInput>;
  DashboardColumnOptions?: InputMaybe<DashboardColumnOptionUpdateManyWithoutOrganizationInput>;
  DashboardLeadFilters?: InputMaybe<DashboardLeadFilterUpdateManyWithoutOrganizationInput>;
  DashboardRepFilters?: InputMaybe<DashboardRepFilterUpdateManyWithoutOrganizationInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewUpdateManyWithoutOrganizationInput>;
  day_to_close?: InputMaybe<IntFieldUpdateOperationsInput>;
  default_demo_time_duration?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  demo_held_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_not_held_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_not_held_call_back_short_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_prep_time_minutes?: InputMaybe<IntFieldUpdateOperationsInput>;
  dev_account?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  dial_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  DispositionMultiplier?: InputMaybe<DispositionMultiplierUpdateManyWithoutOrganizationInput>;
  dispositions?: InputMaybe<DispositionUpdateManyWithoutOrganizationInput>;
  double_booking?: InputMaybe<BoolFieldUpdateOperationsInput>;
  dynamic_date_operators?: InputMaybe<BoolFieldUpdateOperationsInput>;
  edge_servers?: InputMaybe<OrganizationUpdateedge_ServersInput>;
  email_conflict_notification_email_list?: InputMaybe<OrganizationUpdateemail_Conflict_Notification_Email_ListInput>;
  EmailItems?: InputMaybe<EmailItemUpdateManyWithoutOrganizationInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordUpdateManyWithoutOrganizationInput>;
  EmailThreads?: InputMaybe<EmailThreadUpdateManyWithoutOrganizationInput>;
  enable_modified_metrics?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  enable_pay_period_view?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  enable_time_constraint?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  error_log_email_address?: InputMaybe<OrganizationUpdateerror_Log_Email_AddressInput>;
  estimated_average_num_of_weekly_overbooked_demo_slots?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  FolderPermissions?: InputMaybe<FolderPermissionUpdateManyWithoutOrganizationInput>;
  Folders?: InputMaybe<FolderUpdateManyWithoutOrganizationInput>;
  FutureBookingSetting?: InputMaybe<FutureBookingSettingUpdateOneWithoutOrganizationInput>;
  GlobalTransferRule?: InputMaybe<GlobalTransferRuleUpdateManyWithoutOrganizationInput>;
  held_demos_per_cc_connect?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  helpful_links?: InputMaybe<OrganizationUpdatehelpful_LinksInput>;
  HingePointMultiplier?: InputMaybe<HingePointMultiplierUpdateManyWithoutOrganizationInput>;
  hold_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
  hubspot_credential?: InputMaybe<HubSpotCredentialUpdateOneWithoutOrganizationInput>;
  hubspot_last_sync_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  hubspot_white_list?: InputMaybe<HubSpotWhitelistUpdateManyWithoutOrganizationInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inbound_close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_dm_connect_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_lt_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_revenue_value_of_sale?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_scheduled_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_set_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventUpdateManyWithoutOrganizationInput>;
  InboundConciergeWidget?: InputMaybe<InboundConciergeWidgetUpdateManyWithoutOrganizationInput>;
  industry_options?: InputMaybe<IndustryOptionUpdateManyWithoutOrganizationInput>;
  initial_sdr_sets_reps_day?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  integration_error_logs?: InputMaybe<IntegrationErrorLogUpdateManyWithoutOrganizationInput>;
  integration_mappings?: InputMaybe<IntegrationMappingUpdateManyWithoutOrganizationInput>;
  IntegrationMappingCustomObject?: InputMaybe<IntegrationMappingCustomObjectUpdateManyWithoutOrganizationInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogUpdateManyWithoutOrganizationInput>;
  IntegrationReadOnlyObject?: InputMaybe<IntegrationReadOnlyObjectUpdateManyWithoutOrganizationInput>;
  international?: InputMaybe<BoolFieldUpdateOperationsInput>;
  is_email_domain_verified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  is_salesforce_sandbox?: InputMaybe<BoolFieldUpdateOperationsInput>;
  last_lead_value_compute_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutOrganizationInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryUpdateManyWithoutOrganizationInput>;
  lead_intent?: InputMaybe<LeadIntentUpdateManyWithoutOrganizationInput>;
  lead_release_same_rep_delay_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  lead_routing_email_list?: InputMaybe<OrganizationUpdatelead_Routing_Email_ListInput>;
  lead_routing_rules?: InputMaybe<RuleUpdateManyWithoutOrganizationInput>;
  LeadCustomObjectRowAssociation?: InputMaybe<LeadCustomObjectRowAssociationUpdateManyWithoutOrganizationInput>;
  LeadFieldMultiplier?: InputMaybe<LeadFieldMultiplierUpdateManyWithoutOrganizationInput>;
  LeadFieldMultiplierCustom?: InputMaybe<LeadFieldMultiplierCustomUpdateManyWithoutOrganizationInput>;
  leads?: InputMaybe<LeadUpdateManyWithoutOrganizationInput>;
  leadsource_options?: InputMaybe<LeadSourceOptionUpdateManyWithoutOrganizationInput>;
  LeadValue?: InputMaybe<LeadValueUpdateManyWithoutOrganizationInput>;
  LifeTimeValueMultiplier?: InputMaybe<LifeTimeValueMultiplierUpdateManyWithoutOrganizationInput>;
  LiveTransferSetting?: InputMaybe<LiveTransferSettingUpdateOneWithoutOrganizationInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusUpdateManyWithoutOrganizationInput>;
  ltv_multiplier_custom_fields?: InputMaybe<LifeTimeValueMultiplierCustomUpdateManyWithoutOrganizationInput>;
  made_contact_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  make_sale_address_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_business_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_city_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_country_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_first_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_industry_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_last_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_lead_source_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_primary_email_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_primary_phone_number_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_state_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_sub_industry_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_zip_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  maxium_day_lag_set_to_schedule?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  mrr?: InputMaybe<IntFieldUpdateOperationsInput>;
  mrr_label?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  mrr_required_on_sale?: InputMaybe<BoolFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  NoteItems?: InputMaybe<NoteItemUpdateManyWithoutOrganizationInput>;
  Notifications?: InputMaybe<NotificationUpdateManyWithoutOrganizationInput>;
  onboardComplete?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  Opportunity?: InputMaybe<SfOpportunityUpdateManyWithoutOrganizationInput>;
  organization_holidays?: InputMaybe<OrganizationHolidayUpdateManyWithoutOrganizationInput>;
  organization_hours?: InputMaybe<OrganizationHourUpdateManyWithoutOrganizationInput>;
  OrganizationPaymentSetting?: InputMaybe<OrganizationPaymentSettingsUpdateOneWithoutOrganizationInput>;
  OrganizationPaymentSettings?: InputMaybe<OrganizationPaymentSettingsUpdateOneWithoutOrganizationsInput>;
  orgCalendarInviteMetadata?: InputMaybe<OrgCalendarInviteMetadataUpdateManyWithoutOrganizationInput>;
  OrgDefaultPipelinePerecentValue?: InputMaybe<OrgDefaultPipelinePerecentValueUpdateOneWithoutOrganizationInput>;
  OrgHubSpotSync?: InputMaybe<OrgHubSpotSyncUpdateOneWithoutOrganizationInput>;
  OrgNoEventDate?: InputMaybe<OrgNoEventDateUpdateManyWithoutOrganizationInput>;
  OrgSalesforceSync?: InputMaybe<OrgSalesforceSyncUpdateOneWithoutOrganizationInput>;
  OrgTimeslotAvailability?: InputMaybe<OrgTimeslotAvailabilityUpdateManyWithoutOrganizationInput>;
  outbound_close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_dm_connect_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_lt_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_revenue_value_of_sale?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_scheduled_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_set_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  PandadocData?: InputMaybe<PandadocDataUpdateOneWithoutOrganizationInput>;
  PandaDocTemplate?: InputMaybe<PandaDocTemplateUpdateManyWithoutOrganizationInput>;
  participant_jitter_buffer_size?: InputMaybe<EnumJitterBufferSizeFieldUpdateOperationsInput>;
  preferred_goal_setting?: InputMaybe<NullableEnumGoalsettingFieldUpdateOperationsInput>;
  prepayment?: InputMaybe<BoolFieldUpdateOperationsInput>;
  ProductChangeLog?: InputMaybe<ProductChangeLogUpdateManyWithoutOrganizationInput>;
  products?: InputMaybe<ProductUpdateManyWithoutOrganizationInput>;
  re_cold_call_hour_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  recordingStatus?: InputMaybe<EnumRecordingStatusFieldUpdateOperationsInput>;
  release_lead_resting?: InputMaybe<IntFieldUpdateOperationsInput>;
  rep_claim_limit?: InputMaybe<IntFieldUpdateOperationsInput>;
  rep_lead_allotment?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  rep_level_call_transfer?: InputMaybe<BoolFieldUpdateOperationsInput>;
  reps_can_bulk_unassign?: InputMaybe<BoolFieldUpdateOperationsInput>;
  reps_can_view_all_notes?: InputMaybe<BoolFieldUpdateOperationsInput>;
  require_sale_notes?: InputMaybe<BoolFieldUpdateOperationsInput>;
  resting_after_skipped_CC?: InputMaybe<IntFieldUpdateOperationsInput>;
  resting_period?: InputMaybe<IntFieldUpdateOperationsInput>;
  resting_period_after_unsuccessful_cold_call?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  resting_period_after_unsuccessful_demo?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  revenue_plan?: InputMaybe<RevenuePlanItemUpdateManyWithoutOrganizationInput>;
  Routings?: InputMaybe<RoutingUpdateManyWithoutOrganizationInput>;
  sale_field_options?: InputMaybe<SaleFieldOptionUpdateManyWithoutOrganizationInput>;
  sale_notification_email_list?: InputMaybe<OrganizationUpdatesale_Notification_Email_ListInput>;
  SaleConfig?: InputMaybe<SaleConfigUpdateOneWithoutOrganizationInput>;
  SaleConfigPage?: InputMaybe<SaleConfigPageUpdateManyWithoutOrganizationInput>;
  SaleConfigSection?: InputMaybe<SaleConfigSectionUpdateManyWithoutOrganizationInput>;
  SaleCycles?: InputMaybe<SaleCycleUpdateManyWithoutOrganizationInput>;
  SaleItems?: InputMaybe<SaleItemUpdateManyWithoutOrganizationInput>;
  sales?: InputMaybe<SaleUpdateManyWithoutOrganizationInput>;
  salesforce_domain?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  salesforce_last_sync_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  SalesForceColumn?: InputMaybe<SalesForceColumnUpdateManyWithoutOrganizationInput>;
  salesForceData?: InputMaybe<SalesForceDataUpdateOneWithoutOrganizationInput>;
  ScheduleItems?: InputMaybe<ScheduleItemUpdateManyWithoutOrganizationInput>;
  self_sourcing_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  send_error_log_email?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  send_lead_routing_email?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sendgrid_email_domain?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sendgrid_email_domain_id?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  sequence?: InputMaybe<SequenceUpdateManyWithoutOrganzationInput>;
  SequenceCriteriaConditions?: InputMaybe<SequenceCriteriaConditionUpdateManyWithoutOrganizationInput>;
  SequenceDashboardViews?: InputMaybe<SequenceDashboardViewUpdateManyWithoutOrganizationInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutOrganizationInput>;
  SequenceSteps?: InputMaybe<SequenceStepUpdateManyWithoutOrganizationInput>;
  set_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
  short_resting?: InputMaybe<IntFieldUpdateOperationsInput>;
  show_calendar_options?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_contract_duration_sales?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_lead_source_to_rep?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_nocontact_button?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_payment_terms_sales?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_rep_all_leads?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_rep_my_leads?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_system_view?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sites?: InputMaybe<SiteUpdateManyWithoutOrganizationInput>;
  sms_opt_in_dialog?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SMSItems?: InputMaybe<SmsItemUpdateManyWithoutOrganizationInput>;
  SnoozeTrackings?: InputMaybe<SnoozeTrackingUpdateManyWithoutOrganizationInput>;
  StepActionLogs?: InputMaybe<StepActionLogUpdateManyWithoutOrganizationInput>;
  StepActions?: InputMaybe<StepActionUpdateManyWithoutOrganizationInput>;
  StepConditions?: InputMaybe<StepConditionUpdateManyWithoutOrganizationInput>;
  teams?: InputMaybe<TeamUpdateManyWithoutOrganizationInput>;
  Template?: InputMaybe<TemplateUpdateManyWithoutOrganizationInput>;
  TemplateVersions?: InputMaybe<TemplateVersionUpdateManyWithoutOrganizationInput>;
  timekit_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timezone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transfer_timeout?: InputMaybe<IntFieldUpdateOperationsInput>;
  TransferAttempts?: InputMaybe<TransferAttemptUpdateManyWithoutOrganizationInput>;
  twilio_numbers?: InputMaybe<TwilioNumberUpdateManyWithoutOrganizationInput>;
  TwilioFeedbacks?: InputMaybe<TwilioFeedbackUpdateManyWithoutOrganizationInput>;
  TwilioInfo?: InputMaybe<TwilioInfoUpdateOneWithoutOrganzationInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  use_revenue_planning?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  UserCustomFieldFilters?: InputMaybe<UserCustomFieldFilterUpdateManyWithoutOrganizationInput>;
  users?: InputMaybe<UserUpdateManyWithoutOrganizationInput>;
  win_rep_day_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
};

export type OrganizationUpdateWithoutSequenceEntryExitLeadInput = {
  ActivityMonitorMultiplier?: InputMaybe<ActivityMonitorMultiplierUpdateManyWithoutOrganizationInput>;
  algoFactor?: InputMaybe<AlgoFactorUpdateManyWithoutOrganizationInput>;
  allow_calendar_invite?: InputMaybe<BoolFieldUpdateOperationsInput>;
  allow_remove_step_action?: InputMaybe<BoolFieldUpdateOperationsInput>;
  allow_snooze_step_action?: InputMaybe<BoolFieldUpdateOperationsInput>;
  always_record?: InputMaybe<BoolFieldUpdateOperationsInput>;
  arv_initial_held_demos_generated_by_sdr_weight?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  AssociationHistories?: InputMaybe<AssociationHistoryUpdateManyWithoutOrganizationInput>;
  auto_call_interval?: InputMaybe<IntFieldUpdateOperationsInput>;
  auto_dial?: InputMaybe<BoolFieldUpdateOperationsInput>;
  automated_campaigns?: InputMaybe<AutomatedCampaignUpdateManyWithoutOrganizationInput>;
  automatic_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  AutoTransferSelections?: InputMaybe<AutoTransferSelectionUpdateManyWithoutOrganizationInput>;
  Availablities?: InputMaybe<AvailabilityUpdateManyWithoutOrganizationInput>;
  AverageOrderValue?: InputMaybe<AverageOrderValueUpdateManyWithoutOrganizationInput>;
  best_action_ever_multiplier?: InputMaybe<FloatFieldUpdateOperationsInput>;
  best_metrics?: InputMaybe<BestMetricRecordUpdateManyWithoutOrganizationInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptUpdateManyWithoutOrganizationInput>;
  callMeNows?: InputMaybe<CallMeNowUpdateManyWithoutOrganizationInput>;
  CallMultiplier?: InputMaybe<CallMultiplierUpdateManyWithoutOrganizationInput>;
  CallSharings?: InputMaybe<CallSharingUpdateManyWithoutOrganizationInput>;
  can_edit_sdr_transfer_rules?: InputMaybe<BoolFieldUpdateOperationsInput>;
  claim_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  concession?: InputMaybe<ConcessionUpdateManyWithoutOrganizationInput>;
  conference_region?: InputMaybe<EnumConferenceRegionFieldUpdateOperationsInput>;
  Conferences?: InputMaybe<ConferenceUpdateManyWithoutOrganizationInput>;
  ContactRequests?: InputMaybe<ContactRequestUpdateManyWithoutOrganizationInput>;
  Contacts?: InputMaybe<ContactUpdateManyWithoutOrganizationInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  custom_fields?: InputMaybe<CustomFieldUpdateManyWithoutOrganizationInput>;
  CustomObject?: InputMaybe<CustomObjectUpdateManyWithoutOrganizationInput>;
  CustomObjectField?: InputMaybe<CustomObjectFieldUpdateManyWithoutOrganizationInput>;
  CustomObjectFieldForeignKey?: InputMaybe<CustomObjectFieldForeignKeyUpdateManyWithoutOrganizationInput>;
  CustomObjectRecord?: InputMaybe<CustomObjectRecordUpdateManyWithoutOrganizationInput>;
  CustomObjectRow?: InputMaybe<CustomObjectRowUpdateManyWithoutOrganizationInput>;
  CustomQueueItems?: InputMaybe<CustomQueueItemUpdateManyWithoutOrganizationInput>;
  DashboardColumnOptions?: InputMaybe<DashboardColumnOptionUpdateManyWithoutOrganizationInput>;
  DashboardLeadFilters?: InputMaybe<DashboardLeadFilterUpdateManyWithoutOrganizationInput>;
  DashboardRepFilters?: InputMaybe<DashboardRepFilterUpdateManyWithoutOrganizationInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewUpdateManyWithoutOrganizationInput>;
  day_to_close?: InputMaybe<IntFieldUpdateOperationsInput>;
  default_demo_time_duration?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  demo_held_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_not_held_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_not_held_call_back_short_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_prep_time_minutes?: InputMaybe<IntFieldUpdateOperationsInput>;
  dev_account?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  dial_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  DispositionMultiplier?: InputMaybe<DispositionMultiplierUpdateManyWithoutOrganizationInput>;
  dispositions?: InputMaybe<DispositionUpdateManyWithoutOrganizationInput>;
  double_booking?: InputMaybe<BoolFieldUpdateOperationsInput>;
  dynamic_date_operators?: InputMaybe<BoolFieldUpdateOperationsInput>;
  edge_servers?: InputMaybe<OrganizationUpdateedge_ServersInput>;
  email_conflict_notification_email_list?: InputMaybe<OrganizationUpdateemail_Conflict_Notification_Email_ListInput>;
  EmailItems?: InputMaybe<EmailItemUpdateManyWithoutOrganizationInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordUpdateManyWithoutOrganizationInput>;
  EmailThreads?: InputMaybe<EmailThreadUpdateManyWithoutOrganizationInput>;
  enable_modified_metrics?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  enable_pay_period_view?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  enable_time_constraint?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  error_log_email_address?: InputMaybe<OrganizationUpdateerror_Log_Email_AddressInput>;
  estimated_average_num_of_weekly_overbooked_demo_slots?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  FolderPermissions?: InputMaybe<FolderPermissionUpdateManyWithoutOrganizationInput>;
  Folders?: InputMaybe<FolderUpdateManyWithoutOrganizationInput>;
  FutureBookingSetting?: InputMaybe<FutureBookingSettingUpdateOneWithoutOrganizationInput>;
  GlobalTransferRule?: InputMaybe<GlobalTransferRuleUpdateManyWithoutOrganizationInput>;
  held_demos_per_cc_connect?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  helpful_links?: InputMaybe<OrganizationUpdatehelpful_LinksInput>;
  HingePointMultiplier?: InputMaybe<HingePointMultiplierUpdateManyWithoutOrganizationInput>;
  hold_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
  hubspot_credential?: InputMaybe<HubSpotCredentialUpdateOneWithoutOrganizationInput>;
  hubspot_last_sync_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  hubspot_white_list?: InputMaybe<HubSpotWhitelistUpdateManyWithoutOrganizationInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inbound_close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_dm_connect_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_lt_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_revenue_value_of_sale?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_scheduled_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_set_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventUpdateManyWithoutOrganizationInput>;
  InboundConciergeWidget?: InputMaybe<InboundConciergeWidgetUpdateManyWithoutOrganizationInput>;
  industry_options?: InputMaybe<IndustryOptionUpdateManyWithoutOrganizationInput>;
  initial_sdr_sets_reps_day?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  integration_error_logs?: InputMaybe<IntegrationErrorLogUpdateManyWithoutOrganizationInput>;
  integration_mappings?: InputMaybe<IntegrationMappingUpdateManyWithoutOrganizationInput>;
  IntegrationMappingCustomObject?: InputMaybe<IntegrationMappingCustomObjectUpdateManyWithoutOrganizationInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogUpdateManyWithoutOrganizationInput>;
  IntegrationReadOnlyObject?: InputMaybe<IntegrationReadOnlyObjectUpdateManyWithoutOrganizationInput>;
  international?: InputMaybe<BoolFieldUpdateOperationsInput>;
  is_email_domain_verified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  is_salesforce_sandbox?: InputMaybe<BoolFieldUpdateOperationsInput>;
  last_lead_value_compute_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutOrganizationInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryUpdateManyWithoutOrganizationInput>;
  lead_intent?: InputMaybe<LeadIntentUpdateManyWithoutOrganizationInput>;
  lead_release_same_rep_delay_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  lead_routing_email_list?: InputMaybe<OrganizationUpdatelead_Routing_Email_ListInput>;
  lead_routing_rules?: InputMaybe<RuleUpdateManyWithoutOrganizationInput>;
  LeadCustomObjectRowAssociation?: InputMaybe<LeadCustomObjectRowAssociationUpdateManyWithoutOrganizationInput>;
  LeadFieldMultiplier?: InputMaybe<LeadFieldMultiplierUpdateManyWithoutOrganizationInput>;
  LeadFieldMultiplierCustom?: InputMaybe<LeadFieldMultiplierCustomUpdateManyWithoutOrganizationInput>;
  leads?: InputMaybe<LeadUpdateManyWithoutOrganizationInput>;
  leadsource_options?: InputMaybe<LeadSourceOptionUpdateManyWithoutOrganizationInput>;
  LeadValue?: InputMaybe<LeadValueUpdateManyWithoutOrganizationInput>;
  LifeTimeValueMultiplier?: InputMaybe<LifeTimeValueMultiplierUpdateManyWithoutOrganizationInput>;
  LiveTransferSetting?: InputMaybe<LiveTransferSettingUpdateOneWithoutOrganizationInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusUpdateManyWithoutOrganizationInput>;
  ltv_multiplier_custom_fields?: InputMaybe<LifeTimeValueMultiplierCustomUpdateManyWithoutOrganizationInput>;
  made_contact_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  make_sale_address_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_business_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_city_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_country_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_first_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_industry_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_last_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_lead_source_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_primary_email_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_primary_phone_number_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_state_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_sub_industry_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_zip_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  maxium_day_lag_set_to_schedule?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  mrr?: InputMaybe<IntFieldUpdateOperationsInput>;
  mrr_label?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  mrr_required_on_sale?: InputMaybe<BoolFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  NoteItems?: InputMaybe<NoteItemUpdateManyWithoutOrganizationInput>;
  Notifications?: InputMaybe<NotificationUpdateManyWithoutOrganizationInput>;
  onboardComplete?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  Opportunity?: InputMaybe<SfOpportunityUpdateManyWithoutOrganizationInput>;
  organization_holidays?: InputMaybe<OrganizationHolidayUpdateManyWithoutOrganizationInput>;
  organization_hours?: InputMaybe<OrganizationHourUpdateManyWithoutOrganizationInput>;
  OrganizationPaymentSetting?: InputMaybe<OrganizationPaymentSettingsUpdateOneWithoutOrganizationInput>;
  OrganizationPaymentSettings?: InputMaybe<OrganizationPaymentSettingsUpdateOneWithoutOrganizationsInput>;
  orgCalendarInviteMetadata?: InputMaybe<OrgCalendarInviteMetadataUpdateManyWithoutOrganizationInput>;
  OrgDefaultPipelinePerecentValue?: InputMaybe<OrgDefaultPipelinePerecentValueUpdateOneWithoutOrganizationInput>;
  OrgHubSpotSync?: InputMaybe<OrgHubSpotSyncUpdateOneWithoutOrganizationInput>;
  OrgNoEventDate?: InputMaybe<OrgNoEventDateUpdateManyWithoutOrganizationInput>;
  OrgSalesforceSync?: InputMaybe<OrgSalesforceSyncUpdateOneWithoutOrganizationInput>;
  OrgTimeslotAvailability?: InputMaybe<OrgTimeslotAvailabilityUpdateManyWithoutOrganizationInput>;
  outbound_close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_dm_connect_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_lt_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_revenue_value_of_sale?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_scheduled_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_set_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  PandadocData?: InputMaybe<PandadocDataUpdateOneWithoutOrganizationInput>;
  PandaDocTemplate?: InputMaybe<PandaDocTemplateUpdateManyWithoutOrganizationInput>;
  participant_jitter_buffer_size?: InputMaybe<EnumJitterBufferSizeFieldUpdateOperationsInput>;
  preferred_goal_setting?: InputMaybe<NullableEnumGoalsettingFieldUpdateOperationsInput>;
  prepayment?: InputMaybe<BoolFieldUpdateOperationsInput>;
  ProductChangeLog?: InputMaybe<ProductChangeLogUpdateManyWithoutOrganizationInput>;
  products?: InputMaybe<ProductUpdateManyWithoutOrganizationInput>;
  re_cold_call_hour_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  recordingStatus?: InputMaybe<EnumRecordingStatusFieldUpdateOperationsInput>;
  release_lead_resting?: InputMaybe<IntFieldUpdateOperationsInput>;
  rep_claim_limit?: InputMaybe<IntFieldUpdateOperationsInput>;
  rep_lead_allotment?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  rep_level_call_transfer?: InputMaybe<BoolFieldUpdateOperationsInput>;
  reps_can_bulk_unassign?: InputMaybe<BoolFieldUpdateOperationsInput>;
  reps_can_view_all_notes?: InputMaybe<BoolFieldUpdateOperationsInput>;
  require_sale_notes?: InputMaybe<BoolFieldUpdateOperationsInput>;
  resting_after_skipped_CC?: InputMaybe<IntFieldUpdateOperationsInput>;
  resting_period?: InputMaybe<IntFieldUpdateOperationsInput>;
  resting_period_after_unsuccessful_cold_call?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  resting_period_after_unsuccessful_demo?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  revenue_plan?: InputMaybe<RevenuePlanItemUpdateManyWithoutOrganizationInput>;
  Routings?: InputMaybe<RoutingUpdateManyWithoutOrganizationInput>;
  sale_field_options?: InputMaybe<SaleFieldOptionUpdateManyWithoutOrganizationInput>;
  sale_notification_email_list?: InputMaybe<OrganizationUpdatesale_Notification_Email_ListInput>;
  SaleConfig?: InputMaybe<SaleConfigUpdateOneWithoutOrganizationInput>;
  SaleConfigPage?: InputMaybe<SaleConfigPageUpdateManyWithoutOrganizationInput>;
  SaleConfigSection?: InputMaybe<SaleConfigSectionUpdateManyWithoutOrganizationInput>;
  SaleCycles?: InputMaybe<SaleCycleUpdateManyWithoutOrganizationInput>;
  SaleItems?: InputMaybe<SaleItemUpdateManyWithoutOrganizationInput>;
  sales?: InputMaybe<SaleUpdateManyWithoutOrganizationInput>;
  salesforce_domain?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  salesforce_last_sync_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  SalesForceColumn?: InputMaybe<SalesForceColumnUpdateManyWithoutOrganizationInput>;
  salesForceData?: InputMaybe<SalesForceDataUpdateOneWithoutOrganizationInput>;
  ScheduleItems?: InputMaybe<ScheduleItemUpdateManyWithoutOrganizationInput>;
  self_sourcing_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  send_error_log_email?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  send_lead_routing_email?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sendgrid_email_domain?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sendgrid_email_domain_id?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  sequence?: InputMaybe<SequenceUpdateManyWithoutOrganzationInput>;
  SequenceCriteriaConditions?: InputMaybe<SequenceCriteriaConditionUpdateManyWithoutOrganizationInput>;
  SequenceDashboardViews?: InputMaybe<SequenceDashboardViewUpdateManyWithoutOrganizationInput>;
  SequenceEntryCriterias?: InputMaybe<SequenceEntryCriteriaUpdateManyWithoutOrganizationInput>;
  SequenceSteps?: InputMaybe<SequenceStepUpdateManyWithoutOrganizationInput>;
  set_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
  short_resting?: InputMaybe<IntFieldUpdateOperationsInput>;
  show_calendar_options?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_contract_duration_sales?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_lead_source_to_rep?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_nocontact_button?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_payment_terms_sales?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_rep_all_leads?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_rep_my_leads?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_system_view?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sites?: InputMaybe<SiteUpdateManyWithoutOrganizationInput>;
  sms_opt_in_dialog?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SMSItems?: InputMaybe<SmsItemUpdateManyWithoutOrganizationInput>;
  SnoozeTrackings?: InputMaybe<SnoozeTrackingUpdateManyWithoutOrganizationInput>;
  StepActionLogs?: InputMaybe<StepActionLogUpdateManyWithoutOrganizationInput>;
  StepActions?: InputMaybe<StepActionUpdateManyWithoutOrganizationInput>;
  StepConditions?: InputMaybe<StepConditionUpdateManyWithoutOrganizationInput>;
  teams?: InputMaybe<TeamUpdateManyWithoutOrganizationInput>;
  Template?: InputMaybe<TemplateUpdateManyWithoutOrganizationInput>;
  TemplateVersions?: InputMaybe<TemplateVersionUpdateManyWithoutOrganizationInput>;
  timekit_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timezone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transfer_timeout?: InputMaybe<IntFieldUpdateOperationsInput>;
  TransferAttempts?: InputMaybe<TransferAttemptUpdateManyWithoutOrganizationInput>;
  twilio_numbers?: InputMaybe<TwilioNumberUpdateManyWithoutOrganizationInput>;
  TwilioFeedbacks?: InputMaybe<TwilioFeedbackUpdateManyWithoutOrganizationInput>;
  TwilioInfo?: InputMaybe<TwilioInfoUpdateOneWithoutOrganzationInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  use_revenue_planning?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  UserCustomFieldFilters?: InputMaybe<UserCustomFieldFilterUpdateManyWithoutOrganizationInput>;
  users?: InputMaybe<UserUpdateManyWithoutOrganizationInput>;
  win_rep_day_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
};

export type OrganizationUpdateWithoutSequenceInput = {
  ActivityMonitorMultiplier?: InputMaybe<ActivityMonitorMultiplierUpdateManyWithoutOrganizationInput>;
  algoFactor?: InputMaybe<AlgoFactorUpdateManyWithoutOrganizationInput>;
  allow_calendar_invite?: InputMaybe<BoolFieldUpdateOperationsInput>;
  allow_remove_step_action?: InputMaybe<BoolFieldUpdateOperationsInput>;
  allow_snooze_step_action?: InputMaybe<BoolFieldUpdateOperationsInput>;
  always_record?: InputMaybe<BoolFieldUpdateOperationsInput>;
  arv_initial_held_demos_generated_by_sdr_weight?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  AssociationHistories?: InputMaybe<AssociationHistoryUpdateManyWithoutOrganizationInput>;
  auto_call_interval?: InputMaybe<IntFieldUpdateOperationsInput>;
  auto_dial?: InputMaybe<BoolFieldUpdateOperationsInput>;
  automated_campaigns?: InputMaybe<AutomatedCampaignUpdateManyWithoutOrganizationInput>;
  automatic_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  AutoTransferSelections?: InputMaybe<AutoTransferSelectionUpdateManyWithoutOrganizationInput>;
  Availablities?: InputMaybe<AvailabilityUpdateManyWithoutOrganizationInput>;
  AverageOrderValue?: InputMaybe<AverageOrderValueUpdateManyWithoutOrganizationInput>;
  best_action_ever_multiplier?: InputMaybe<FloatFieldUpdateOperationsInput>;
  best_metrics?: InputMaybe<BestMetricRecordUpdateManyWithoutOrganizationInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptUpdateManyWithoutOrganizationInput>;
  callMeNows?: InputMaybe<CallMeNowUpdateManyWithoutOrganizationInput>;
  CallMultiplier?: InputMaybe<CallMultiplierUpdateManyWithoutOrganizationInput>;
  CallSharings?: InputMaybe<CallSharingUpdateManyWithoutOrganizationInput>;
  can_edit_sdr_transfer_rules?: InputMaybe<BoolFieldUpdateOperationsInput>;
  claim_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  concession?: InputMaybe<ConcessionUpdateManyWithoutOrganizationInput>;
  conference_region?: InputMaybe<EnumConferenceRegionFieldUpdateOperationsInput>;
  Conferences?: InputMaybe<ConferenceUpdateManyWithoutOrganizationInput>;
  ContactRequests?: InputMaybe<ContactRequestUpdateManyWithoutOrganizationInput>;
  Contacts?: InputMaybe<ContactUpdateManyWithoutOrganizationInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  custom_fields?: InputMaybe<CustomFieldUpdateManyWithoutOrganizationInput>;
  CustomObject?: InputMaybe<CustomObjectUpdateManyWithoutOrganizationInput>;
  CustomObjectField?: InputMaybe<CustomObjectFieldUpdateManyWithoutOrganizationInput>;
  CustomObjectFieldForeignKey?: InputMaybe<CustomObjectFieldForeignKeyUpdateManyWithoutOrganizationInput>;
  CustomObjectRecord?: InputMaybe<CustomObjectRecordUpdateManyWithoutOrganizationInput>;
  CustomObjectRow?: InputMaybe<CustomObjectRowUpdateManyWithoutOrganizationInput>;
  CustomQueueItems?: InputMaybe<CustomQueueItemUpdateManyWithoutOrganizationInput>;
  DashboardColumnOptions?: InputMaybe<DashboardColumnOptionUpdateManyWithoutOrganizationInput>;
  DashboardLeadFilters?: InputMaybe<DashboardLeadFilterUpdateManyWithoutOrganizationInput>;
  DashboardRepFilters?: InputMaybe<DashboardRepFilterUpdateManyWithoutOrganizationInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewUpdateManyWithoutOrganizationInput>;
  day_to_close?: InputMaybe<IntFieldUpdateOperationsInput>;
  default_demo_time_duration?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  demo_held_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_not_held_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_not_held_call_back_short_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_prep_time_minutes?: InputMaybe<IntFieldUpdateOperationsInput>;
  dev_account?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  dial_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  DispositionMultiplier?: InputMaybe<DispositionMultiplierUpdateManyWithoutOrganizationInput>;
  dispositions?: InputMaybe<DispositionUpdateManyWithoutOrganizationInput>;
  double_booking?: InputMaybe<BoolFieldUpdateOperationsInput>;
  dynamic_date_operators?: InputMaybe<BoolFieldUpdateOperationsInput>;
  edge_servers?: InputMaybe<OrganizationUpdateedge_ServersInput>;
  email_conflict_notification_email_list?: InputMaybe<OrganizationUpdateemail_Conflict_Notification_Email_ListInput>;
  EmailItems?: InputMaybe<EmailItemUpdateManyWithoutOrganizationInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordUpdateManyWithoutOrganizationInput>;
  EmailThreads?: InputMaybe<EmailThreadUpdateManyWithoutOrganizationInput>;
  enable_modified_metrics?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  enable_pay_period_view?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  enable_time_constraint?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  error_log_email_address?: InputMaybe<OrganizationUpdateerror_Log_Email_AddressInput>;
  estimated_average_num_of_weekly_overbooked_demo_slots?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  FolderPermissions?: InputMaybe<FolderPermissionUpdateManyWithoutOrganizationInput>;
  Folders?: InputMaybe<FolderUpdateManyWithoutOrganizationInput>;
  FutureBookingSetting?: InputMaybe<FutureBookingSettingUpdateOneWithoutOrganizationInput>;
  GlobalTransferRule?: InputMaybe<GlobalTransferRuleUpdateManyWithoutOrganizationInput>;
  held_demos_per_cc_connect?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  helpful_links?: InputMaybe<OrganizationUpdatehelpful_LinksInput>;
  HingePointMultiplier?: InputMaybe<HingePointMultiplierUpdateManyWithoutOrganizationInput>;
  hold_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
  hubspot_credential?: InputMaybe<HubSpotCredentialUpdateOneWithoutOrganizationInput>;
  hubspot_last_sync_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  hubspot_white_list?: InputMaybe<HubSpotWhitelistUpdateManyWithoutOrganizationInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inbound_close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_dm_connect_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_lt_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_revenue_value_of_sale?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_scheduled_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_set_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventUpdateManyWithoutOrganizationInput>;
  InboundConciergeWidget?: InputMaybe<InboundConciergeWidgetUpdateManyWithoutOrganizationInput>;
  industry_options?: InputMaybe<IndustryOptionUpdateManyWithoutOrganizationInput>;
  initial_sdr_sets_reps_day?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  integration_error_logs?: InputMaybe<IntegrationErrorLogUpdateManyWithoutOrganizationInput>;
  integration_mappings?: InputMaybe<IntegrationMappingUpdateManyWithoutOrganizationInput>;
  IntegrationMappingCustomObject?: InputMaybe<IntegrationMappingCustomObjectUpdateManyWithoutOrganizationInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogUpdateManyWithoutOrganizationInput>;
  IntegrationReadOnlyObject?: InputMaybe<IntegrationReadOnlyObjectUpdateManyWithoutOrganizationInput>;
  international?: InputMaybe<BoolFieldUpdateOperationsInput>;
  is_email_domain_verified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  is_salesforce_sandbox?: InputMaybe<BoolFieldUpdateOperationsInput>;
  last_lead_value_compute_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutOrganizationInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryUpdateManyWithoutOrganizationInput>;
  lead_intent?: InputMaybe<LeadIntentUpdateManyWithoutOrganizationInput>;
  lead_release_same_rep_delay_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  lead_routing_email_list?: InputMaybe<OrganizationUpdatelead_Routing_Email_ListInput>;
  lead_routing_rules?: InputMaybe<RuleUpdateManyWithoutOrganizationInput>;
  LeadCustomObjectRowAssociation?: InputMaybe<LeadCustomObjectRowAssociationUpdateManyWithoutOrganizationInput>;
  LeadFieldMultiplier?: InputMaybe<LeadFieldMultiplierUpdateManyWithoutOrganizationInput>;
  LeadFieldMultiplierCustom?: InputMaybe<LeadFieldMultiplierCustomUpdateManyWithoutOrganizationInput>;
  leads?: InputMaybe<LeadUpdateManyWithoutOrganizationInput>;
  leadsource_options?: InputMaybe<LeadSourceOptionUpdateManyWithoutOrganizationInput>;
  LeadValue?: InputMaybe<LeadValueUpdateManyWithoutOrganizationInput>;
  LifeTimeValueMultiplier?: InputMaybe<LifeTimeValueMultiplierUpdateManyWithoutOrganizationInput>;
  LiveTransferSetting?: InputMaybe<LiveTransferSettingUpdateOneWithoutOrganizationInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusUpdateManyWithoutOrganizationInput>;
  ltv_multiplier_custom_fields?: InputMaybe<LifeTimeValueMultiplierCustomUpdateManyWithoutOrganizationInput>;
  made_contact_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  make_sale_address_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_business_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_city_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_country_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_first_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_industry_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_last_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_lead_source_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_primary_email_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_primary_phone_number_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_state_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_sub_industry_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_zip_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  maxium_day_lag_set_to_schedule?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  mrr?: InputMaybe<IntFieldUpdateOperationsInput>;
  mrr_label?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  mrr_required_on_sale?: InputMaybe<BoolFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  NoteItems?: InputMaybe<NoteItemUpdateManyWithoutOrganizationInput>;
  Notifications?: InputMaybe<NotificationUpdateManyWithoutOrganizationInput>;
  onboardComplete?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  Opportunity?: InputMaybe<SfOpportunityUpdateManyWithoutOrganizationInput>;
  organization_holidays?: InputMaybe<OrganizationHolidayUpdateManyWithoutOrganizationInput>;
  organization_hours?: InputMaybe<OrganizationHourUpdateManyWithoutOrganizationInput>;
  OrganizationPaymentSetting?: InputMaybe<OrganizationPaymentSettingsUpdateOneWithoutOrganizationInput>;
  OrganizationPaymentSettings?: InputMaybe<OrganizationPaymentSettingsUpdateOneWithoutOrganizationsInput>;
  orgCalendarInviteMetadata?: InputMaybe<OrgCalendarInviteMetadataUpdateManyWithoutOrganizationInput>;
  OrgDefaultPipelinePerecentValue?: InputMaybe<OrgDefaultPipelinePerecentValueUpdateOneWithoutOrganizationInput>;
  OrgHubSpotSync?: InputMaybe<OrgHubSpotSyncUpdateOneWithoutOrganizationInput>;
  OrgNoEventDate?: InputMaybe<OrgNoEventDateUpdateManyWithoutOrganizationInput>;
  OrgSalesforceSync?: InputMaybe<OrgSalesforceSyncUpdateOneWithoutOrganizationInput>;
  OrgTimeslotAvailability?: InputMaybe<OrgTimeslotAvailabilityUpdateManyWithoutOrganizationInput>;
  outbound_close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_dm_connect_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_lt_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_revenue_value_of_sale?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_scheduled_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_set_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  PandadocData?: InputMaybe<PandadocDataUpdateOneWithoutOrganizationInput>;
  PandaDocTemplate?: InputMaybe<PandaDocTemplateUpdateManyWithoutOrganizationInput>;
  participant_jitter_buffer_size?: InputMaybe<EnumJitterBufferSizeFieldUpdateOperationsInput>;
  preferred_goal_setting?: InputMaybe<NullableEnumGoalsettingFieldUpdateOperationsInput>;
  prepayment?: InputMaybe<BoolFieldUpdateOperationsInput>;
  ProductChangeLog?: InputMaybe<ProductChangeLogUpdateManyWithoutOrganizationInput>;
  products?: InputMaybe<ProductUpdateManyWithoutOrganizationInput>;
  re_cold_call_hour_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  recordingStatus?: InputMaybe<EnumRecordingStatusFieldUpdateOperationsInput>;
  release_lead_resting?: InputMaybe<IntFieldUpdateOperationsInput>;
  rep_claim_limit?: InputMaybe<IntFieldUpdateOperationsInput>;
  rep_lead_allotment?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  rep_level_call_transfer?: InputMaybe<BoolFieldUpdateOperationsInput>;
  reps_can_bulk_unassign?: InputMaybe<BoolFieldUpdateOperationsInput>;
  reps_can_view_all_notes?: InputMaybe<BoolFieldUpdateOperationsInput>;
  require_sale_notes?: InputMaybe<BoolFieldUpdateOperationsInput>;
  resting_after_skipped_CC?: InputMaybe<IntFieldUpdateOperationsInput>;
  resting_period?: InputMaybe<IntFieldUpdateOperationsInput>;
  resting_period_after_unsuccessful_cold_call?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  resting_period_after_unsuccessful_demo?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  revenue_plan?: InputMaybe<RevenuePlanItemUpdateManyWithoutOrganizationInput>;
  Routings?: InputMaybe<RoutingUpdateManyWithoutOrganizationInput>;
  sale_field_options?: InputMaybe<SaleFieldOptionUpdateManyWithoutOrganizationInput>;
  sale_notification_email_list?: InputMaybe<OrganizationUpdatesale_Notification_Email_ListInput>;
  SaleConfig?: InputMaybe<SaleConfigUpdateOneWithoutOrganizationInput>;
  SaleConfigPage?: InputMaybe<SaleConfigPageUpdateManyWithoutOrganizationInput>;
  SaleConfigSection?: InputMaybe<SaleConfigSectionUpdateManyWithoutOrganizationInput>;
  SaleCycles?: InputMaybe<SaleCycleUpdateManyWithoutOrganizationInput>;
  SaleItems?: InputMaybe<SaleItemUpdateManyWithoutOrganizationInput>;
  sales?: InputMaybe<SaleUpdateManyWithoutOrganizationInput>;
  salesforce_domain?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  salesforce_last_sync_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  SalesForceColumn?: InputMaybe<SalesForceColumnUpdateManyWithoutOrganizationInput>;
  salesForceData?: InputMaybe<SalesForceDataUpdateOneWithoutOrganizationInput>;
  ScheduleItems?: InputMaybe<ScheduleItemUpdateManyWithoutOrganizationInput>;
  self_sourcing_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  send_error_log_email?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  send_lead_routing_email?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sendgrid_email_domain?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sendgrid_email_domain_id?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  SequenceCriteriaConditions?: InputMaybe<SequenceCriteriaConditionUpdateManyWithoutOrganizationInput>;
  SequenceDashboardViews?: InputMaybe<SequenceDashboardViewUpdateManyWithoutOrganizationInput>;
  SequenceEntryCriterias?: InputMaybe<SequenceEntryCriteriaUpdateManyWithoutOrganizationInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutOrganizationInput>;
  SequenceSteps?: InputMaybe<SequenceStepUpdateManyWithoutOrganizationInput>;
  set_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
  short_resting?: InputMaybe<IntFieldUpdateOperationsInput>;
  show_calendar_options?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_contract_duration_sales?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_lead_source_to_rep?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_nocontact_button?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_payment_terms_sales?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_rep_all_leads?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_rep_my_leads?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_system_view?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sites?: InputMaybe<SiteUpdateManyWithoutOrganizationInput>;
  sms_opt_in_dialog?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SMSItems?: InputMaybe<SmsItemUpdateManyWithoutOrganizationInput>;
  SnoozeTrackings?: InputMaybe<SnoozeTrackingUpdateManyWithoutOrganizationInput>;
  StepActionLogs?: InputMaybe<StepActionLogUpdateManyWithoutOrganizationInput>;
  StepActions?: InputMaybe<StepActionUpdateManyWithoutOrganizationInput>;
  StepConditions?: InputMaybe<StepConditionUpdateManyWithoutOrganizationInput>;
  teams?: InputMaybe<TeamUpdateManyWithoutOrganizationInput>;
  Template?: InputMaybe<TemplateUpdateManyWithoutOrganizationInput>;
  TemplateVersions?: InputMaybe<TemplateVersionUpdateManyWithoutOrganizationInput>;
  timekit_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timezone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transfer_timeout?: InputMaybe<IntFieldUpdateOperationsInput>;
  TransferAttempts?: InputMaybe<TransferAttemptUpdateManyWithoutOrganizationInput>;
  twilio_numbers?: InputMaybe<TwilioNumberUpdateManyWithoutOrganizationInput>;
  TwilioFeedbacks?: InputMaybe<TwilioFeedbackUpdateManyWithoutOrganizationInput>;
  TwilioInfo?: InputMaybe<TwilioInfoUpdateOneWithoutOrganzationInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  use_revenue_planning?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  UserCustomFieldFilters?: InputMaybe<UserCustomFieldFilterUpdateManyWithoutOrganizationInput>;
  users?: InputMaybe<UserUpdateManyWithoutOrganizationInput>;
  win_rep_day_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
};

export type OrganizationUpdateWithoutSequenceStepsInput = {
  ActivityMonitorMultiplier?: InputMaybe<ActivityMonitorMultiplierUpdateManyWithoutOrganizationInput>;
  algoFactor?: InputMaybe<AlgoFactorUpdateManyWithoutOrganizationInput>;
  allow_calendar_invite?: InputMaybe<BoolFieldUpdateOperationsInput>;
  allow_remove_step_action?: InputMaybe<BoolFieldUpdateOperationsInput>;
  allow_snooze_step_action?: InputMaybe<BoolFieldUpdateOperationsInput>;
  always_record?: InputMaybe<BoolFieldUpdateOperationsInput>;
  arv_initial_held_demos_generated_by_sdr_weight?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  AssociationHistories?: InputMaybe<AssociationHistoryUpdateManyWithoutOrganizationInput>;
  auto_call_interval?: InputMaybe<IntFieldUpdateOperationsInput>;
  auto_dial?: InputMaybe<BoolFieldUpdateOperationsInput>;
  automated_campaigns?: InputMaybe<AutomatedCampaignUpdateManyWithoutOrganizationInput>;
  automatic_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  AutoTransferSelections?: InputMaybe<AutoTransferSelectionUpdateManyWithoutOrganizationInput>;
  Availablities?: InputMaybe<AvailabilityUpdateManyWithoutOrganizationInput>;
  AverageOrderValue?: InputMaybe<AverageOrderValueUpdateManyWithoutOrganizationInput>;
  best_action_ever_multiplier?: InputMaybe<FloatFieldUpdateOperationsInput>;
  best_metrics?: InputMaybe<BestMetricRecordUpdateManyWithoutOrganizationInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptUpdateManyWithoutOrganizationInput>;
  callMeNows?: InputMaybe<CallMeNowUpdateManyWithoutOrganizationInput>;
  CallMultiplier?: InputMaybe<CallMultiplierUpdateManyWithoutOrganizationInput>;
  CallSharings?: InputMaybe<CallSharingUpdateManyWithoutOrganizationInput>;
  can_edit_sdr_transfer_rules?: InputMaybe<BoolFieldUpdateOperationsInput>;
  claim_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  concession?: InputMaybe<ConcessionUpdateManyWithoutOrganizationInput>;
  conference_region?: InputMaybe<EnumConferenceRegionFieldUpdateOperationsInput>;
  Conferences?: InputMaybe<ConferenceUpdateManyWithoutOrganizationInput>;
  ContactRequests?: InputMaybe<ContactRequestUpdateManyWithoutOrganizationInput>;
  Contacts?: InputMaybe<ContactUpdateManyWithoutOrganizationInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  custom_fields?: InputMaybe<CustomFieldUpdateManyWithoutOrganizationInput>;
  CustomObject?: InputMaybe<CustomObjectUpdateManyWithoutOrganizationInput>;
  CustomObjectField?: InputMaybe<CustomObjectFieldUpdateManyWithoutOrganizationInput>;
  CustomObjectFieldForeignKey?: InputMaybe<CustomObjectFieldForeignKeyUpdateManyWithoutOrganizationInput>;
  CustomObjectRecord?: InputMaybe<CustomObjectRecordUpdateManyWithoutOrganizationInput>;
  CustomObjectRow?: InputMaybe<CustomObjectRowUpdateManyWithoutOrganizationInput>;
  CustomQueueItems?: InputMaybe<CustomQueueItemUpdateManyWithoutOrganizationInput>;
  DashboardColumnOptions?: InputMaybe<DashboardColumnOptionUpdateManyWithoutOrganizationInput>;
  DashboardLeadFilters?: InputMaybe<DashboardLeadFilterUpdateManyWithoutOrganizationInput>;
  DashboardRepFilters?: InputMaybe<DashboardRepFilterUpdateManyWithoutOrganizationInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewUpdateManyWithoutOrganizationInput>;
  day_to_close?: InputMaybe<IntFieldUpdateOperationsInput>;
  default_demo_time_duration?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  demo_held_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_not_held_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_not_held_call_back_short_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_prep_time_minutes?: InputMaybe<IntFieldUpdateOperationsInput>;
  dev_account?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  dial_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  DispositionMultiplier?: InputMaybe<DispositionMultiplierUpdateManyWithoutOrganizationInput>;
  dispositions?: InputMaybe<DispositionUpdateManyWithoutOrganizationInput>;
  double_booking?: InputMaybe<BoolFieldUpdateOperationsInput>;
  dynamic_date_operators?: InputMaybe<BoolFieldUpdateOperationsInput>;
  edge_servers?: InputMaybe<OrganizationUpdateedge_ServersInput>;
  email_conflict_notification_email_list?: InputMaybe<OrganizationUpdateemail_Conflict_Notification_Email_ListInput>;
  EmailItems?: InputMaybe<EmailItemUpdateManyWithoutOrganizationInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordUpdateManyWithoutOrganizationInput>;
  EmailThreads?: InputMaybe<EmailThreadUpdateManyWithoutOrganizationInput>;
  enable_modified_metrics?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  enable_pay_period_view?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  enable_time_constraint?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  error_log_email_address?: InputMaybe<OrganizationUpdateerror_Log_Email_AddressInput>;
  estimated_average_num_of_weekly_overbooked_demo_slots?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  FolderPermissions?: InputMaybe<FolderPermissionUpdateManyWithoutOrganizationInput>;
  Folders?: InputMaybe<FolderUpdateManyWithoutOrganizationInput>;
  FutureBookingSetting?: InputMaybe<FutureBookingSettingUpdateOneWithoutOrganizationInput>;
  GlobalTransferRule?: InputMaybe<GlobalTransferRuleUpdateManyWithoutOrganizationInput>;
  held_demos_per_cc_connect?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  helpful_links?: InputMaybe<OrganizationUpdatehelpful_LinksInput>;
  HingePointMultiplier?: InputMaybe<HingePointMultiplierUpdateManyWithoutOrganizationInput>;
  hold_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
  hubspot_credential?: InputMaybe<HubSpotCredentialUpdateOneWithoutOrganizationInput>;
  hubspot_last_sync_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  hubspot_white_list?: InputMaybe<HubSpotWhitelistUpdateManyWithoutOrganizationInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inbound_close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_dm_connect_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_lt_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_revenue_value_of_sale?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_scheduled_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_set_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventUpdateManyWithoutOrganizationInput>;
  InboundConciergeWidget?: InputMaybe<InboundConciergeWidgetUpdateManyWithoutOrganizationInput>;
  industry_options?: InputMaybe<IndustryOptionUpdateManyWithoutOrganizationInput>;
  initial_sdr_sets_reps_day?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  integration_error_logs?: InputMaybe<IntegrationErrorLogUpdateManyWithoutOrganizationInput>;
  integration_mappings?: InputMaybe<IntegrationMappingUpdateManyWithoutOrganizationInput>;
  IntegrationMappingCustomObject?: InputMaybe<IntegrationMappingCustomObjectUpdateManyWithoutOrganizationInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogUpdateManyWithoutOrganizationInput>;
  IntegrationReadOnlyObject?: InputMaybe<IntegrationReadOnlyObjectUpdateManyWithoutOrganizationInput>;
  international?: InputMaybe<BoolFieldUpdateOperationsInput>;
  is_email_domain_verified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  is_salesforce_sandbox?: InputMaybe<BoolFieldUpdateOperationsInput>;
  last_lead_value_compute_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutOrganizationInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryUpdateManyWithoutOrganizationInput>;
  lead_intent?: InputMaybe<LeadIntentUpdateManyWithoutOrganizationInput>;
  lead_release_same_rep_delay_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  lead_routing_email_list?: InputMaybe<OrganizationUpdatelead_Routing_Email_ListInput>;
  lead_routing_rules?: InputMaybe<RuleUpdateManyWithoutOrganizationInput>;
  LeadCustomObjectRowAssociation?: InputMaybe<LeadCustomObjectRowAssociationUpdateManyWithoutOrganizationInput>;
  LeadFieldMultiplier?: InputMaybe<LeadFieldMultiplierUpdateManyWithoutOrganizationInput>;
  LeadFieldMultiplierCustom?: InputMaybe<LeadFieldMultiplierCustomUpdateManyWithoutOrganizationInput>;
  leads?: InputMaybe<LeadUpdateManyWithoutOrganizationInput>;
  leadsource_options?: InputMaybe<LeadSourceOptionUpdateManyWithoutOrganizationInput>;
  LeadValue?: InputMaybe<LeadValueUpdateManyWithoutOrganizationInput>;
  LifeTimeValueMultiplier?: InputMaybe<LifeTimeValueMultiplierUpdateManyWithoutOrganizationInput>;
  LiveTransferSetting?: InputMaybe<LiveTransferSettingUpdateOneWithoutOrganizationInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusUpdateManyWithoutOrganizationInput>;
  ltv_multiplier_custom_fields?: InputMaybe<LifeTimeValueMultiplierCustomUpdateManyWithoutOrganizationInput>;
  made_contact_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  make_sale_address_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_business_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_city_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_country_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_first_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_industry_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_last_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_lead_source_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_primary_email_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_primary_phone_number_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_state_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_sub_industry_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_zip_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  maxium_day_lag_set_to_schedule?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  mrr?: InputMaybe<IntFieldUpdateOperationsInput>;
  mrr_label?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  mrr_required_on_sale?: InputMaybe<BoolFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  NoteItems?: InputMaybe<NoteItemUpdateManyWithoutOrganizationInput>;
  Notifications?: InputMaybe<NotificationUpdateManyWithoutOrganizationInput>;
  onboardComplete?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  Opportunity?: InputMaybe<SfOpportunityUpdateManyWithoutOrganizationInput>;
  organization_holidays?: InputMaybe<OrganizationHolidayUpdateManyWithoutOrganizationInput>;
  organization_hours?: InputMaybe<OrganizationHourUpdateManyWithoutOrganizationInput>;
  OrganizationPaymentSetting?: InputMaybe<OrganizationPaymentSettingsUpdateOneWithoutOrganizationInput>;
  OrganizationPaymentSettings?: InputMaybe<OrganizationPaymentSettingsUpdateOneWithoutOrganizationsInput>;
  orgCalendarInviteMetadata?: InputMaybe<OrgCalendarInviteMetadataUpdateManyWithoutOrganizationInput>;
  OrgDefaultPipelinePerecentValue?: InputMaybe<OrgDefaultPipelinePerecentValueUpdateOneWithoutOrganizationInput>;
  OrgHubSpotSync?: InputMaybe<OrgHubSpotSyncUpdateOneWithoutOrganizationInput>;
  OrgNoEventDate?: InputMaybe<OrgNoEventDateUpdateManyWithoutOrganizationInput>;
  OrgSalesforceSync?: InputMaybe<OrgSalesforceSyncUpdateOneWithoutOrganizationInput>;
  OrgTimeslotAvailability?: InputMaybe<OrgTimeslotAvailabilityUpdateManyWithoutOrganizationInput>;
  outbound_close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_dm_connect_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_lt_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_revenue_value_of_sale?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_scheduled_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_set_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  PandadocData?: InputMaybe<PandadocDataUpdateOneWithoutOrganizationInput>;
  PandaDocTemplate?: InputMaybe<PandaDocTemplateUpdateManyWithoutOrganizationInput>;
  participant_jitter_buffer_size?: InputMaybe<EnumJitterBufferSizeFieldUpdateOperationsInput>;
  preferred_goal_setting?: InputMaybe<NullableEnumGoalsettingFieldUpdateOperationsInput>;
  prepayment?: InputMaybe<BoolFieldUpdateOperationsInput>;
  ProductChangeLog?: InputMaybe<ProductChangeLogUpdateManyWithoutOrganizationInput>;
  products?: InputMaybe<ProductUpdateManyWithoutOrganizationInput>;
  re_cold_call_hour_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  recordingStatus?: InputMaybe<EnumRecordingStatusFieldUpdateOperationsInput>;
  release_lead_resting?: InputMaybe<IntFieldUpdateOperationsInput>;
  rep_claim_limit?: InputMaybe<IntFieldUpdateOperationsInput>;
  rep_lead_allotment?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  rep_level_call_transfer?: InputMaybe<BoolFieldUpdateOperationsInput>;
  reps_can_bulk_unassign?: InputMaybe<BoolFieldUpdateOperationsInput>;
  reps_can_view_all_notes?: InputMaybe<BoolFieldUpdateOperationsInput>;
  require_sale_notes?: InputMaybe<BoolFieldUpdateOperationsInput>;
  resting_after_skipped_CC?: InputMaybe<IntFieldUpdateOperationsInput>;
  resting_period?: InputMaybe<IntFieldUpdateOperationsInput>;
  resting_period_after_unsuccessful_cold_call?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  resting_period_after_unsuccessful_demo?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  revenue_plan?: InputMaybe<RevenuePlanItemUpdateManyWithoutOrganizationInput>;
  Routings?: InputMaybe<RoutingUpdateManyWithoutOrganizationInput>;
  sale_field_options?: InputMaybe<SaleFieldOptionUpdateManyWithoutOrganizationInput>;
  sale_notification_email_list?: InputMaybe<OrganizationUpdatesale_Notification_Email_ListInput>;
  SaleConfig?: InputMaybe<SaleConfigUpdateOneWithoutOrganizationInput>;
  SaleConfigPage?: InputMaybe<SaleConfigPageUpdateManyWithoutOrganizationInput>;
  SaleConfigSection?: InputMaybe<SaleConfigSectionUpdateManyWithoutOrganizationInput>;
  SaleCycles?: InputMaybe<SaleCycleUpdateManyWithoutOrganizationInput>;
  SaleItems?: InputMaybe<SaleItemUpdateManyWithoutOrganizationInput>;
  sales?: InputMaybe<SaleUpdateManyWithoutOrganizationInput>;
  salesforce_domain?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  salesforce_last_sync_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  SalesForceColumn?: InputMaybe<SalesForceColumnUpdateManyWithoutOrganizationInput>;
  salesForceData?: InputMaybe<SalesForceDataUpdateOneWithoutOrganizationInput>;
  ScheduleItems?: InputMaybe<ScheduleItemUpdateManyWithoutOrganizationInput>;
  self_sourcing_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  send_error_log_email?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  send_lead_routing_email?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sendgrid_email_domain?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sendgrid_email_domain_id?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  sequence?: InputMaybe<SequenceUpdateManyWithoutOrganzationInput>;
  SequenceCriteriaConditions?: InputMaybe<SequenceCriteriaConditionUpdateManyWithoutOrganizationInput>;
  SequenceDashboardViews?: InputMaybe<SequenceDashboardViewUpdateManyWithoutOrganizationInput>;
  SequenceEntryCriterias?: InputMaybe<SequenceEntryCriteriaUpdateManyWithoutOrganizationInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutOrganizationInput>;
  set_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
  short_resting?: InputMaybe<IntFieldUpdateOperationsInput>;
  show_calendar_options?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_contract_duration_sales?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_lead_source_to_rep?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_nocontact_button?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_payment_terms_sales?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_rep_all_leads?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_rep_my_leads?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_system_view?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sites?: InputMaybe<SiteUpdateManyWithoutOrganizationInput>;
  sms_opt_in_dialog?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SMSItems?: InputMaybe<SmsItemUpdateManyWithoutOrganizationInput>;
  SnoozeTrackings?: InputMaybe<SnoozeTrackingUpdateManyWithoutOrganizationInput>;
  StepActionLogs?: InputMaybe<StepActionLogUpdateManyWithoutOrganizationInput>;
  StepActions?: InputMaybe<StepActionUpdateManyWithoutOrganizationInput>;
  StepConditions?: InputMaybe<StepConditionUpdateManyWithoutOrganizationInput>;
  teams?: InputMaybe<TeamUpdateManyWithoutOrganizationInput>;
  Template?: InputMaybe<TemplateUpdateManyWithoutOrganizationInput>;
  TemplateVersions?: InputMaybe<TemplateVersionUpdateManyWithoutOrganizationInput>;
  timekit_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timezone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transfer_timeout?: InputMaybe<IntFieldUpdateOperationsInput>;
  TransferAttempts?: InputMaybe<TransferAttemptUpdateManyWithoutOrganizationInput>;
  twilio_numbers?: InputMaybe<TwilioNumberUpdateManyWithoutOrganizationInput>;
  TwilioFeedbacks?: InputMaybe<TwilioFeedbackUpdateManyWithoutOrganizationInput>;
  TwilioInfo?: InputMaybe<TwilioInfoUpdateOneWithoutOrganzationInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  use_revenue_planning?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  UserCustomFieldFilters?: InputMaybe<UserCustomFieldFilterUpdateManyWithoutOrganizationInput>;
  users?: InputMaybe<UserUpdateManyWithoutOrganizationInput>;
  win_rep_day_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
};

export type OrganizationUpdateWithoutSitesInput = {
  ActivityMonitorMultiplier?: InputMaybe<ActivityMonitorMultiplierUpdateManyWithoutOrganizationInput>;
  algoFactor?: InputMaybe<AlgoFactorUpdateManyWithoutOrganizationInput>;
  allow_calendar_invite?: InputMaybe<BoolFieldUpdateOperationsInput>;
  allow_remove_step_action?: InputMaybe<BoolFieldUpdateOperationsInput>;
  allow_snooze_step_action?: InputMaybe<BoolFieldUpdateOperationsInput>;
  always_record?: InputMaybe<BoolFieldUpdateOperationsInput>;
  arv_initial_held_demos_generated_by_sdr_weight?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  AssociationHistories?: InputMaybe<AssociationHistoryUpdateManyWithoutOrganizationInput>;
  auto_call_interval?: InputMaybe<IntFieldUpdateOperationsInput>;
  auto_dial?: InputMaybe<BoolFieldUpdateOperationsInput>;
  automated_campaigns?: InputMaybe<AutomatedCampaignUpdateManyWithoutOrganizationInput>;
  automatic_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  AutoTransferSelections?: InputMaybe<AutoTransferSelectionUpdateManyWithoutOrganizationInput>;
  Availablities?: InputMaybe<AvailabilityUpdateManyWithoutOrganizationInput>;
  AverageOrderValue?: InputMaybe<AverageOrderValueUpdateManyWithoutOrganizationInput>;
  best_action_ever_multiplier?: InputMaybe<FloatFieldUpdateOperationsInput>;
  best_metrics?: InputMaybe<BestMetricRecordUpdateManyWithoutOrganizationInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptUpdateManyWithoutOrganizationInput>;
  callMeNows?: InputMaybe<CallMeNowUpdateManyWithoutOrganizationInput>;
  CallMultiplier?: InputMaybe<CallMultiplierUpdateManyWithoutOrganizationInput>;
  CallSharings?: InputMaybe<CallSharingUpdateManyWithoutOrganizationInput>;
  can_edit_sdr_transfer_rules?: InputMaybe<BoolFieldUpdateOperationsInput>;
  claim_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  concession?: InputMaybe<ConcessionUpdateManyWithoutOrganizationInput>;
  conference_region?: InputMaybe<EnumConferenceRegionFieldUpdateOperationsInput>;
  Conferences?: InputMaybe<ConferenceUpdateManyWithoutOrganizationInput>;
  ContactRequests?: InputMaybe<ContactRequestUpdateManyWithoutOrganizationInput>;
  Contacts?: InputMaybe<ContactUpdateManyWithoutOrganizationInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  custom_fields?: InputMaybe<CustomFieldUpdateManyWithoutOrganizationInput>;
  CustomObject?: InputMaybe<CustomObjectUpdateManyWithoutOrganizationInput>;
  CustomObjectField?: InputMaybe<CustomObjectFieldUpdateManyWithoutOrganizationInput>;
  CustomObjectFieldForeignKey?: InputMaybe<CustomObjectFieldForeignKeyUpdateManyWithoutOrganizationInput>;
  CustomObjectRecord?: InputMaybe<CustomObjectRecordUpdateManyWithoutOrganizationInput>;
  CustomObjectRow?: InputMaybe<CustomObjectRowUpdateManyWithoutOrganizationInput>;
  CustomQueueItems?: InputMaybe<CustomQueueItemUpdateManyWithoutOrganizationInput>;
  DashboardColumnOptions?: InputMaybe<DashboardColumnOptionUpdateManyWithoutOrganizationInput>;
  DashboardLeadFilters?: InputMaybe<DashboardLeadFilterUpdateManyWithoutOrganizationInput>;
  DashboardRepFilters?: InputMaybe<DashboardRepFilterUpdateManyWithoutOrganizationInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewUpdateManyWithoutOrganizationInput>;
  day_to_close?: InputMaybe<IntFieldUpdateOperationsInput>;
  default_demo_time_duration?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  demo_held_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_not_held_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_not_held_call_back_short_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_prep_time_minutes?: InputMaybe<IntFieldUpdateOperationsInput>;
  dev_account?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  dial_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  DispositionMultiplier?: InputMaybe<DispositionMultiplierUpdateManyWithoutOrganizationInput>;
  dispositions?: InputMaybe<DispositionUpdateManyWithoutOrganizationInput>;
  double_booking?: InputMaybe<BoolFieldUpdateOperationsInput>;
  dynamic_date_operators?: InputMaybe<BoolFieldUpdateOperationsInput>;
  edge_servers?: InputMaybe<OrganizationUpdateedge_ServersInput>;
  email_conflict_notification_email_list?: InputMaybe<OrganizationUpdateemail_Conflict_Notification_Email_ListInput>;
  EmailItems?: InputMaybe<EmailItemUpdateManyWithoutOrganizationInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordUpdateManyWithoutOrganizationInput>;
  EmailThreads?: InputMaybe<EmailThreadUpdateManyWithoutOrganizationInput>;
  enable_modified_metrics?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  enable_pay_period_view?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  enable_time_constraint?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  error_log_email_address?: InputMaybe<OrganizationUpdateerror_Log_Email_AddressInput>;
  estimated_average_num_of_weekly_overbooked_demo_slots?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  FolderPermissions?: InputMaybe<FolderPermissionUpdateManyWithoutOrganizationInput>;
  Folders?: InputMaybe<FolderUpdateManyWithoutOrganizationInput>;
  FutureBookingSetting?: InputMaybe<FutureBookingSettingUpdateOneWithoutOrganizationInput>;
  GlobalTransferRule?: InputMaybe<GlobalTransferRuleUpdateManyWithoutOrganizationInput>;
  held_demos_per_cc_connect?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  helpful_links?: InputMaybe<OrganizationUpdatehelpful_LinksInput>;
  HingePointMultiplier?: InputMaybe<HingePointMultiplierUpdateManyWithoutOrganizationInput>;
  hold_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
  hubspot_credential?: InputMaybe<HubSpotCredentialUpdateOneWithoutOrganizationInput>;
  hubspot_last_sync_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  hubspot_white_list?: InputMaybe<HubSpotWhitelistUpdateManyWithoutOrganizationInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inbound_close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_dm_connect_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_lt_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_revenue_value_of_sale?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_scheduled_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_set_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventUpdateManyWithoutOrganizationInput>;
  InboundConciergeWidget?: InputMaybe<InboundConciergeWidgetUpdateManyWithoutOrganizationInput>;
  industry_options?: InputMaybe<IndustryOptionUpdateManyWithoutOrganizationInput>;
  initial_sdr_sets_reps_day?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  integration_error_logs?: InputMaybe<IntegrationErrorLogUpdateManyWithoutOrganizationInput>;
  integration_mappings?: InputMaybe<IntegrationMappingUpdateManyWithoutOrganizationInput>;
  IntegrationMappingCustomObject?: InputMaybe<IntegrationMappingCustomObjectUpdateManyWithoutOrganizationInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogUpdateManyWithoutOrganizationInput>;
  IntegrationReadOnlyObject?: InputMaybe<IntegrationReadOnlyObjectUpdateManyWithoutOrganizationInput>;
  international?: InputMaybe<BoolFieldUpdateOperationsInput>;
  is_email_domain_verified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  is_salesforce_sandbox?: InputMaybe<BoolFieldUpdateOperationsInput>;
  last_lead_value_compute_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutOrganizationInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryUpdateManyWithoutOrganizationInput>;
  lead_intent?: InputMaybe<LeadIntentUpdateManyWithoutOrganizationInput>;
  lead_release_same_rep_delay_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  lead_routing_email_list?: InputMaybe<OrganizationUpdatelead_Routing_Email_ListInput>;
  lead_routing_rules?: InputMaybe<RuleUpdateManyWithoutOrganizationInput>;
  LeadCustomObjectRowAssociation?: InputMaybe<LeadCustomObjectRowAssociationUpdateManyWithoutOrganizationInput>;
  LeadFieldMultiplier?: InputMaybe<LeadFieldMultiplierUpdateManyWithoutOrganizationInput>;
  LeadFieldMultiplierCustom?: InputMaybe<LeadFieldMultiplierCustomUpdateManyWithoutOrganizationInput>;
  leads?: InputMaybe<LeadUpdateManyWithoutOrganizationInput>;
  leadsource_options?: InputMaybe<LeadSourceOptionUpdateManyWithoutOrganizationInput>;
  LeadValue?: InputMaybe<LeadValueUpdateManyWithoutOrganizationInput>;
  LifeTimeValueMultiplier?: InputMaybe<LifeTimeValueMultiplierUpdateManyWithoutOrganizationInput>;
  LiveTransferSetting?: InputMaybe<LiveTransferSettingUpdateOneWithoutOrganizationInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusUpdateManyWithoutOrganizationInput>;
  ltv_multiplier_custom_fields?: InputMaybe<LifeTimeValueMultiplierCustomUpdateManyWithoutOrganizationInput>;
  made_contact_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  make_sale_address_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_business_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_city_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_country_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_first_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_industry_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_last_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_lead_source_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_primary_email_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_primary_phone_number_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_state_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_sub_industry_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_zip_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  maxium_day_lag_set_to_schedule?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  mrr?: InputMaybe<IntFieldUpdateOperationsInput>;
  mrr_label?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  mrr_required_on_sale?: InputMaybe<BoolFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  NoteItems?: InputMaybe<NoteItemUpdateManyWithoutOrganizationInput>;
  Notifications?: InputMaybe<NotificationUpdateManyWithoutOrganizationInput>;
  onboardComplete?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  Opportunity?: InputMaybe<SfOpportunityUpdateManyWithoutOrganizationInput>;
  organization_holidays?: InputMaybe<OrganizationHolidayUpdateManyWithoutOrganizationInput>;
  organization_hours?: InputMaybe<OrganizationHourUpdateManyWithoutOrganizationInput>;
  OrganizationPaymentSetting?: InputMaybe<OrganizationPaymentSettingsUpdateOneWithoutOrganizationInput>;
  OrganizationPaymentSettings?: InputMaybe<OrganizationPaymentSettingsUpdateOneWithoutOrganizationsInput>;
  orgCalendarInviteMetadata?: InputMaybe<OrgCalendarInviteMetadataUpdateManyWithoutOrganizationInput>;
  OrgDefaultPipelinePerecentValue?: InputMaybe<OrgDefaultPipelinePerecentValueUpdateOneWithoutOrganizationInput>;
  OrgHubSpotSync?: InputMaybe<OrgHubSpotSyncUpdateOneWithoutOrganizationInput>;
  OrgNoEventDate?: InputMaybe<OrgNoEventDateUpdateManyWithoutOrganizationInput>;
  OrgSalesforceSync?: InputMaybe<OrgSalesforceSyncUpdateOneWithoutOrganizationInput>;
  OrgTimeslotAvailability?: InputMaybe<OrgTimeslotAvailabilityUpdateManyWithoutOrganizationInput>;
  outbound_close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_dm_connect_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_lt_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_revenue_value_of_sale?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_scheduled_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_set_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  PandadocData?: InputMaybe<PandadocDataUpdateOneWithoutOrganizationInput>;
  PandaDocTemplate?: InputMaybe<PandaDocTemplateUpdateManyWithoutOrganizationInput>;
  participant_jitter_buffer_size?: InputMaybe<EnumJitterBufferSizeFieldUpdateOperationsInput>;
  preferred_goal_setting?: InputMaybe<NullableEnumGoalsettingFieldUpdateOperationsInput>;
  prepayment?: InputMaybe<BoolFieldUpdateOperationsInput>;
  ProductChangeLog?: InputMaybe<ProductChangeLogUpdateManyWithoutOrganizationInput>;
  products?: InputMaybe<ProductUpdateManyWithoutOrganizationInput>;
  re_cold_call_hour_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  recordingStatus?: InputMaybe<EnumRecordingStatusFieldUpdateOperationsInput>;
  release_lead_resting?: InputMaybe<IntFieldUpdateOperationsInput>;
  rep_claim_limit?: InputMaybe<IntFieldUpdateOperationsInput>;
  rep_lead_allotment?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  rep_level_call_transfer?: InputMaybe<BoolFieldUpdateOperationsInput>;
  reps_can_bulk_unassign?: InputMaybe<BoolFieldUpdateOperationsInput>;
  reps_can_view_all_notes?: InputMaybe<BoolFieldUpdateOperationsInput>;
  require_sale_notes?: InputMaybe<BoolFieldUpdateOperationsInput>;
  resting_after_skipped_CC?: InputMaybe<IntFieldUpdateOperationsInput>;
  resting_period?: InputMaybe<IntFieldUpdateOperationsInput>;
  resting_period_after_unsuccessful_cold_call?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  resting_period_after_unsuccessful_demo?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  revenue_plan?: InputMaybe<RevenuePlanItemUpdateManyWithoutOrganizationInput>;
  Routings?: InputMaybe<RoutingUpdateManyWithoutOrganizationInput>;
  sale_field_options?: InputMaybe<SaleFieldOptionUpdateManyWithoutOrganizationInput>;
  sale_notification_email_list?: InputMaybe<OrganizationUpdatesale_Notification_Email_ListInput>;
  SaleConfig?: InputMaybe<SaleConfigUpdateOneWithoutOrganizationInput>;
  SaleConfigPage?: InputMaybe<SaleConfigPageUpdateManyWithoutOrganizationInput>;
  SaleConfigSection?: InputMaybe<SaleConfigSectionUpdateManyWithoutOrganizationInput>;
  SaleCycles?: InputMaybe<SaleCycleUpdateManyWithoutOrganizationInput>;
  SaleItems?: InputMaybe<SaleItemUpdateManyWithoutOrganizationInput>;
  sales?: InputMaybe<SaleUpdateManyWithoutOrganizationInput>;
  salesforce_domain?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  salesforce_last_sync_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  SalesForceColumn?: InputMaybe<SalesForceColumnUpdateManyWithoutOrganizationInput>;
  salesForceData?: InputMaybe<SalesForceDataUpdateOneWithoutOrganizationInput>;
  ScheduleItems?: InputMaybe<ScheduleItemUpdateManyWithoutOrganizationInput>;
  self_sourcing_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  send_error_log_email?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  send_lead_routing_email?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sendgrid_email_domain?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sendgrid_email_domain_id?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  sequence?: InputMaybe<SequenceUpdateManyWithoutOrganzationInput>;
  SequenceCriteriaConditions?: InputMaybe<SequenceCriteriaConditionUpdateManyWithoutOrganizationInput>;
  SequenceDashboardViews?: InputMaybe<SequenceDashboardViewUpdateManyWithoutOrganizationInput>;
  SequenceEntryCriterias?: InputMaybe<SequenceEntryCriteriaUpdateManyWithoutOrganizationInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutOrganizationInput>;
  SequenceSteps?: InputMaybe<SequenceStepUpdateManyWithoutOrganizationInput>;
  set_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
  short_resting?: InputMaybe<IntFieldUpdateOperationsInput>;
  show_calendar_options?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_contract_duration_sales?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_lead_source_to_rep?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_nocontact_button?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_payment_terms_sales?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_rep_all_leads?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_rep_my_leads?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_system_view?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sms_opt_in_dialog?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SMSItems?: InputMaybe<SmsItemUpdateManyWithoutOrganizationInput>;
  SnoozeTrackings?: InputMaybe<SnoozeTrackingUpdateManyWithoutOrganizationInput>;
  StepActionLogs?: InputMaybe<StepActionLogUpdateManyWithoutOrganizationInput>;
  StepActions?: InputMaybe<StepActionUpdateManyWithoutOrganizationInput>;
  StepConditions?: InputMaybe<StepConditionUpdateManyWithoutOrganizationInput>;
  teams?: InputMaybe<TeamUpdateManyWithoutOrganizationInput>;
  Template?: InputMaybe<TemplateUpdateManyWithoutOrganizationInput>;
  TemplateVersions?: InputMaybe<TemplateVersionUpdateManyWithoutOrganizationInput>;
  timekit_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timezone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transfer_timeout?: InputMaybe<IntFieldUpdateOperationsInput>;
  TransferAttempts?: InputMaybe<TransferAttemptUpdateManyWithoutOrganizationInput>;
  twilio_numbers?: InputMaybe<TwilioNumberUpdateManyWithoutOrganizationInput>;
  TwilioFeedbacks?: InputMaybe<TwilioFeedbackUpdateManyWithoutOrganizationInput>;
  TwilioInfo?: InputMaybe<TwilioInfoUpdateOneWithoutOrganzationInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  use_revenue_planning?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  UserCustomFieldFilters?: InputMaybe<UserCustomFieldFilterUpdateManyWithoutOrganizationInput>;
  users?: InputMaybe<UserUpdateManyWithoutOrganizationInput>;
  win_rep_day_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
};

export type OrganizationUpdateWithoutSmsItemsInput = {
  ActivityMonitorMultiplier?: InputMaybe<ActivityMonitorMultiplierUpdateManyWithoutOrganizationInput>;
  algoFactor?: InputMaybe<AlgoFactorUpdateManyWithoutOrganizationInput>;
  allow_calendar_invite?: InputMaybe<BoolFieldUpdateOperationsInput>;
  allow_remove_step_action?: InputMaybe<BoolFieldUpdateOperationsInput>;
  allow_snooze_step_action?: InputMaybe<BoolFieldUpdateOperationsInput>;
  always_record?: InputMaybe<BoolFieldUpdateOperationsInput>;
  arv_initial_held_demos_generated_by_sdr_weight?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  AssociationHistories?: InputMaybe<AssociationHistoryUpdateManyWithoutOrganizationInput>;
  auto_call_interval?: InputMaybe<IntFieldUpdateOperationsInput>;
  auto_dial?: InputMaybe<BoolFieldUpdateOperationsInput>;
  automated_campaigns?: InputMaybe<AutomatedCampaignUpdateManyWithoutOrganizationInput>;
  automatic_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  AutoTransferSelections?: InputMaybe<AutoTransferSelectionUpdateManyWithoutOrganizationInput>;
  Availablities?: InputMaybe<AvailabilityUpdateManyWithoutOrganizationInput>;
  AverageOrderValue?: InputMaybe<AverageOrderValueUpdateManyWithoutOrganizationInput>;
  best_action_ever_multiplier?: InputMaybe<FloatFieldUpdateOperationsInput>;
  best_metrics?: InputMaybe<BestMetricRecordUpdateManyWithoutOrganizationInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptUpdateManyWithoutOrganizationInput>;
  callMeNows?: InputMaybe<CallMeNowUpdateManyWithoutOrganizationInput>;
  CallMultiplier?: InputMaybe<CallMultiplierUpdateManyWithoutOrganizationInput>;
  CallSharings?: InputMaybe<CallSharingUpdateManyWithoutOrganizationInput>;
  can_edit_sdr_transfer_rules?: InputMaybe<BoolFieldUpdateOperationsInput>;
  claim_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  concession?: InputMaybe<ConcessionUpdateManyWithoutOrganizationInput>;
  conference_region?: InputMaybe<EnumConferenceRegionFieldUpdateOperationsInput>;
  Conferences?: InputMaybe<ConferenceUpdateManyWithoutOrganizationInput>;
  ContactRequests?: InputMaybe<ContactRequestUpdateManyWithoutOrganizationInput>;
  Contacts?: InputMaybe<ContactUpdateManyWithoutOrganizationInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  custom_fields?: InputMaybe<CustomFieldUpdateManyWithoutOrganizationInput>;
  CustomObject?: InputMaybe<CustomObjectUpdateManyWithoutOrganizationInput>;
  CustomObjectField?: InputMaybe<CustomObjectFieldUpdateManyWithoutOrganizationInput>;
  CustomObjectFieldForeignKey?: InputMaybe<CustomObjectFieldForeignKeyUpdateManyWithoutOrganizationInput>;
  CustomObjectRecord?: InputMaybe<CustomObjectRecordUpdateManyWithoutOrganizationInput>;
  CustomObjectRow?: InputMaybe<CustomObjectRowUpdateManyWithoutOrganizationInput>;
  CustomQueueItems?: InputMaybe<CustomQueueItemUpdateManyWithoutOrganizationInput>;
  DashboardColumnOptions?: InputMaybe<DashboardColumnOptionUpdateManyWithoutOrganizationInput>;
  DashboardLeadFilters?: InputMaybe<DashboardLeadFilterUpdateManyWithoutOrganizationInput>;
  DashboardRepFilters?: InputMaybe<DashboardRepFilterUpdateManyWithoutOrganizationInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewUpdateManyWithoutOrganizationInput>;
  day_to_close?: InputMaybe<IntFieldUpdateOperationsInput>;
  default_demo_time_duration?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  demo_held_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_not_held_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_not_held_call_back_short_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_prep_time_minutes?: InputMaybe<IntFieldUpdateOperationsInput>;
  dev_account?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  dial_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  DispositionMultiplier?: InputMaybe<DispositionMultiplierUpdateManyWithoutOrganizationInput>;
  dispositions?: InputMaybe<DispositionUpdateManyWithoutOrganizationInput>;
  double_booking?: InputMaybe<BoolFieldUpdateOperationsInput>;
  dynamic_date_operators?: InputMaybe<BoolFieldUpdateOperationsInput>;
  edge_servers?: InputMaybe<OrganizationUpdateedge_ServersInput>;
  email_conflict_notification_email_list?: InputMaybe<OrganizationUpdateemail_Conflict_Notification_Email_ListInput>;
  EmailItems?: InputMaybe<EmailItemUpdateManyWithoutOrganizationInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordUpdateManyWithoutOrganizationInput>;
  EmailThreads?: InputMaybe<EmailThreadUpdateManyWithoutOrganizationInput>;
  enable_modified_metrics?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  enable_pay_period_view?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  enable_time_constraint?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  error_log_email_address?: InputMaybe<OrganizationUpdateerror_Log_Email_AddressInput>;
  estimated_average_num_of_weekly_overbooked_demo_slots?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  FolderPermissions?: InputMaybe<FolderPermissionUpdateManyWithoutOrganizationInput>;
  Folders?: InputMaybe<FolderUpdateManyWithoutOrganizationInput>;
  FutureBookingSetting?: InputMaybe<FutureBookingSettingUpdateOneWithoutOrganizationInput>;
  GlobalTransferRule?: InputMaybe<GlobalTransferRuleUpdateManyWithoutOrganizationInput>;
  held_demos_per_cc_connect?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  helpful_links?: InputMaybe<OrganizationUpdatehelpful_LinksInput>;
  HingePointMultiplier?: InputMaybe<HingePointMultiplierUpdateManyWithoutOrganizationInput>;
  hold_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
  hubspot_credential?: InputMaybe<HubSpotCredentialUpdateOneWithoutOrganizationInput>;
  hubspot_last_sync_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  hubspot_white_list?: InputMaybe<HubSpotWhitelistUpdateManyWithoutOrganizationInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inbound_close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_dm_connect_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_lt_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_revenue_value_of_sale?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_scheduled_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_set_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventUpdateManyWithoutOrganizationInput>;
  InboundConciergeWidget?: InputMaybe<InboundConciergeWidgetUpdateManyWithoutOrganizationInput>;
  industry_options?: InputMaybe<IndustryOptionUpdateManyWithoutOrganizationInput>;
  initial_sdr_sets_reps_day?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  integration_error_logs?: InputMaybe<IntegrationErrorLogUpdateManyWithoutOrganizationInput>;
  integration_mappings?: InputMaybe<IntegrationMappingUpdateManyWithoutOrganizationInput>;
  IntegrationMappingCustomObject?: InputMaybe<IntegrationMappingCustomObjectUpdateManyWithoutOrganizationInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogUpdateManyWithoutOrganizationInput>;
  IntegrationReadOnlyObject?: InputMaybe<IntegrationReadOnlyObjectUpdateManyWithoutOrganizationInput>;
  international?: InputMaybe<BoolFieldUpdateOperationsInput>;
  is_email_domain_verified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  is_salesforce_sandbox?: InputMaybe<BoolFieldUpdateOperationsInput>;
  last_lead_value_compute_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutOrganizationInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryUpdateManyWithoutOrganizationInput>;
  lead_intent?: InputMaybe<LeadIntentUpdateManyWithoutOrganizationInput>;
  lead_release_same_rep_delay_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  lead_routing_email_list?: InputMaybe<OrganizationUpdatelead_Routing_Email_ListInput>;
  lead_routing_rules?: InputMaybe<RuleUpdateManyWithoutOrganizationInput>;
  LeadCustomObjectRowAssociation?: InputMaybe<LeadCustomObjectRowAssociationUpdateManyWithoutOrganizationInput>;
  LeadFieldMultiplier?: InputMaybe<LeadFieldMultiplierUpdateManyWithoutOrganizationInput>;
  LeadFieldMultiplierCustom?: InputMaybe<LeadFieldMultiplierCustomUpdateManyWithoutOrganizationInput>;
  leads?: InputMaybe<LeadUpdateManyWithoutOrganizationInput>;
  leadsource_options?: InputMaybe<LeadSourceOptionUpdateManyWithoutOrganizationInput>;
  LeadValue?: InputMaybe<LeadValueUpdateManyWithoutOrganizationInput>;
  LifeTimeValueMultiplier?: InputMaybe<LifeTimeValueMultiplierUpdateManyWithoutOrganizationInput>;
  LiveTransferSetting?: InputMaybe<LiveTransferSettingUpdateOneWithoutOrganizationInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusUpdateManyWithoutOrganizationInput>;
  ltv_multiplier_custom_fields?: InputMaybe<LifeTimeValueMultiplierCustomUpdateManyWithoutOrganizationInput>;
  made_contact_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  make_sale_address_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_business_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_city_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_country_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_first_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_industry_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_last_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_lead_source_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_primary_email_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_primary_phone_number_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_state_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_sub_industry_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_zip_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  maxium_day_lag_set_to_schedule?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  mrr?: InputMaybe<IntFieldUpdateOperationsInput>;
  mrr_label?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  mrr_required_on_sale?: InputMaybe<BoolFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  NoteItems?: InputMaybe<NoteItemUpdateManyWithoutOrganizationInput>;
  Notifications?: InputMaybe<NotificationUpdateManyWithoutOrganizationInput>;
  onboardComplete?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  Opportunity?: InputMaybe<SfOpportunityUpdateManyWithoutOrganizationInput>;
  organization_holidays?: InputMaybe<OrganizationHolidayUpdateManyWithoutOrganizationInput>;
  organization_hours?: InputMaybe<OrganizationHourUpdateManyWithoutOrganizationInput>;
  OrganizationPaymentSetting?: InputMaybe<OrganizationPaymentSettingsUpdateOneWithoutOrganizationInput>;
  OrganizationPaymentSettings?: InputMaybe<OrganizationPaymentSettingsUpdateOneWithoutOrganizationsInput>;
  orgCalendarInviteMetadata?: InputMaybe<OrgCalendarInviteMetadataUpdateManyWithoutOrganizationInput>;
  OrgDefaultPipelinePerecentValue?: InputMaybe<OrgDefaultPipelinePerecentValueUpdateOneWithoutOrganizationInput>;
  OrgHubSpotSync?: InputMaybe<OrgHubSpotSyncUpdateOneWithoutOrganizationInput>;
  OrgNoEventDate?: InputMaybe<OrgNoEventDateUpdateManyWithoutOrganizationInput>;
  OrgSalesforceSync?: InputMaybe<OrgSalesforceSyncUpdateOneWithoutOrganizationInput>;
  OrgTimeslotAvailability?: InputMaybe<OrgTimeslotAvailabilityUpdateManyWithoutOrganizationInput>;
  outbound_close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_dm_connect_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_lt_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_revenue_value_of_sale?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_scheduled_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_set_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  PandadocData?: InputMaybe<PandadocDataUpdateOneWithoutOrganizationInput>;
  PandaDocTemplate?: InputMaybe<PandaDocTemplateUpdateManyWithoutOrganizationInput>;
  participant_jitter_buffer_size?: InputMaybe<EnumJitterBufferSizeFieldUpdateOperationsInput>;
  preferred_goal_setting?: InputMaybe<NullableEnumGoalsettingFieldUpdateOperationsInput>;
  prepayment?: InputMaybe<BoolFieldUpdateOperationsInput>;
  ProductChangeLog?: InputMaybe<ProductChangeLogUpdateManyWithoutOrganizationInput>;
  products?: InputMaybe<ProductUpdateManyWithoutOrganizationInput>;
  re_cold_call_hour_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  recordingStatus?: InputMaybe<EnumRecordingStatusFieldUpdateOperationsInput>;
  release_lead_resting?: InputMaybe<IntFieldUpdateOperationsInput>;
  rep_claim_limit?: InputMaybe<IntFieldUpdateOperationsInput>;
  rep_lead_allotment?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  rep_level_call_transfer?: InputMaybe<BoolFieldUpdateOperationsInput>;
  reps_can_bulk_unassign?: InputMaybe<BoolFieldUpdateOperationsInput>;
  reps_can_view_all_notes?: InputMaybe<BoolFieldUpdateOperationsInput>;
  require_sale_notes?: InputMaybe<BoolFieldUpdateOperationsInput>;
  resting_after_skipped_CC?: InputMaybe<IntFieldUpdateOperationsInput>;
  resting_period?: InputMaybe<IntFieldUpdateOperationsInput>;
  resting_period_after_unsuccessful_cold_call?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  resting_period_after_unsuccessful_demo?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  revenue_plan?: InputMaybe<RevenuePlanItemUpdateManyWithoutOrganizationInput>;
  Routings?: InputMaybe<RoutingUpdateManyWithoutOrganizationInput>;
  sale_field_options?: InputMaybe<SaleFieldOptionUpdateManyWithoutOrganizationInput>;
  sale_notification_email_list?: InputMaybe<OrganizationUpdatesale_Notification_Email_ListInput>;
  SaleConfig?: InputMaybe<SaleConfigUpdateOneWithoutOrganizationInput>;
  SaleConfigPage?: InputMaybe<SaleConfigPageUpdateManyWithoutOrganizationInput>;
  SaleConfigSection?: InputMaybe<SaleConfigSectionUpdateManyWithoutOrganizationInput>;
  SaleCycles?: InputMaybe<SaleCycleUpdateManyWithoutOrganizationInput>;
  SaleItems?: InputMaybe<SaleItemUpdateManyWithoutOrganizationInput>;
  sales?: InputMaybe<SaleUpdateManyWithoutOrganizationInput>;
  salesforce_domain?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  salesforce_last_sync_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  SalesForceColumn?: InputMaybe<SalesForceColumnUpdateManyWithoutOrganizationInput>;
  salesForceData?: InputMaybe<SalesForceDataUpdateOneWithoutOrganizationInput>;
  ScheduleItems?: InputMaybe<ScheduleItemUpdateManyWithoutOrganizationInput>;
  self_sourcing_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  send_error_log_email?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  send_lead_routing_email?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sendgrid_email_domain?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sendgrid_email_domain_id?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  sequence?: InputMaybe<SequenceUpdateManyWithoutOrganzationInput>;
  SequenceCriteriaConditions?: InputMaybe<SequenceCriteriaConditionUpdateManyWithoutOrganizationInput>;
  SequenceDashboardViews?: InputMaybe<SequenceDashboardViewUpdateManyWithoutOrganizationInput>;
  SequenceEntryCriterias?: InputMaybe<SequenceEntryCriteriaUpdateManyWithoutOrganizationInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutOrganizationInput>;
  SequenceSteps?: InputMaybe<SequenceStepUpdateManyWithoutOrganizationInput>;
  set_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
  short_resting?: InputMaybe<IntFieldUpdateOperationsInput>;
  show_calendar_options?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_contract_duration_sales?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_lead_source_to_rep?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_nocontact_button?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_payment_terms_sales?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_rep_all_leads?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_rep_my_leads?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_system_view?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sites?: InputMaybe<SiteUpdateManyWithoutOrganizationInput>;
  sms_opt_in_dialog?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SnoozeTrackings?: InputMaybe<SnoozeTrackingUpdateManyWithoutOrganizationInput>;
  StepActionLogs?: InputMaybe<StepActionLogUpdateManyWithoutOrganizationInput>;
  StepActions?: InputMaybe<StepActionUpdateManyWithoutOrganizationInput>;
  StepConditions?: InputMaybe<StepConditionUpdateManyWithoutOrganizationInput>;
  teams?: InputMaybe<TeamUpdateManyWithoutOrganizationInput>;
  Template?: InputMaybe<TemplateUpdateManyWithoutOrganizationInput>;
  TemplateVersions?: InputMaybe<TemplateVersionUpdateManyWithoutOrganizationInput>;
  timekit_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timezone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transfer_timeout?: InputMaybe<IntFieldUpdateOperationsInput>;
  TransferAttempts?: InputMaybe<TransferAttemptUpdateManyWithoutOrganizationInput>;
  twilio_numbers?: InputMaybe<TwilioNumberUpdateManyWithoutOrganizationInput>;
  TwilioFeedbacks?: InputMaybe<TwilioFeedbackUpdateManyWithoutOrganizationInput>;
  TwilioInfo?: InputMaybe<TwilioInfoUpdateOneWithoutOrganzationInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  use_revenue_planning?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  UserCustomFieldFilters?: InputMaybe<UserCustomFieldFilterUpdateManyWithoutOrganizationInput>;
  users?: InputMaybe<UserUpdateManyWithoutOrganizationInput>;
  win_rep_day_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
};

export type OrganizationUpdateWithoutSnoozeTrackingsInput = {
  ActivityMonitorMultiplier?: InputMaybe<ActivityMonitorMultiplierUpdateManyWithoutOrganizationInput>;
  algoFactor?: InputMaybe<AlgoFactorUpdateManyWithoutOrganizationInput>;
  allow_calendar_invite?: InputMaybe<BoolFieldUpdateOperationsInput>;
  allow_remove_step_action?: InputMaybe<BoolFieldUpdateOperationsInput>;
  allow_snooze_step_action?: InputMaybe<BoolFieldUpdateOperationsInput>;
  always_record?: InputMaybe<BoolFieldUpdateOperationsInput>;
  arv_initial_held_demos_generated_by_sdr_weight?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  AssociationHistories?: InputMaybe<AssociationHistoryUpdateManyWithoutOrganizationInput>;
  auto_call_interval?: InputMaybe<IntFieldUpdateOperationsInput>;
  auto_dial?: InputMaybe<BoolFieldUpdateOperationsInput>;
  automated_campaigns?: InputMaybe<AutomatedCampaignUpdateManyWithoutOrganizationInput>;
  automatic_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  AutoTransferSelections?: InputMaybe<AutoTransferSelectionUpdateManyWithoutOrganizationInput>;
  Availablities?: InputMaybe<AvailabilityUpdateManyWithoutOrganizationInput>;
  AverageOrderValue?: InputMaybe<AverageOrderValueUpdateManyWithoutOrganizationInput>;
  best_action_ever_multiplier?: InputMaybe<FloatFieldUpdateOperationsInput>;
  best_metrics?: InputMaybe<BestMetricRecordUpdateManyWithoutOrganizationInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptUpdateManyWithoutOrganizationInput>;
  callMeNows?: InputMaybe<CallMeNowUpdateManyWithoutOrganizationInput>;
  CallMultiplier?: InputMaybe<CallMultiplierUpdateManyWithoutOrganizationInput>;
  CallSharings?: InputMaybe<CallSharingUpdateManyWithoutOrganizationInput>;
  can_edit_sdr_transfer_rules?: InputMaybe<BoolFieldUpdateOperationsInput>;
  claim_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  concession?: InputMaybe<ConcessionUpdateManyWithoutOrganizationInput>;
  conference_region?: InputMaybe<EnumConferenceRegionFieldUpdateOperationsInput>;
  Conferences?: InputMaybe<ConferenceUpdateManyWithoutOrganizationInput>;
  ContactRequests?: InputMaybe<ContactRequestUpdateManyWithoutOrganizationInput>;
  Contacts?: InputMaybe<ContactUpdateManyWithoutOrganizationInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  custom_fields?: InputMaybe<CustomFieldUpdateManyWithoutOrganizationInput>;
  CustomObject?: InputMaybe<CustomObjectUpdateManyWithoutOrganizationInput>;
  CustomObjectField?: InputMaybe<CustomObjectFieldUpdateManyWithoutOrganizationInput>;
  CustomObjectFieldForeignKey?: InputMaybe<CustomObjectFieldForeignKeyUpdateManyWithoutOrganizationInput>;
  CustomObjectRecord?: InputMaybe<CustomObjectRecordUpdateManyWithoutOrganizationInput>;
  CustomObjectRow?: InputMaybe<CustomObjectRowUpdateManyWithoutOrganizationInput>;
  CustomQueueItems?: InputMaybe<CustomQueueItemUpdateManyWithoutOrganizationInput>;
  DashboardColumnOptions?: InputMaybe<DashboardColumnOptionUpdateManyWithoutOrganizationInput>;
  DashboardLeadFilters?: InputMaybe<DashboardLeadFilterUpdateManyWithoutOrganizationInput>;
  DashboardRepFilters?: InputMaybe<DashboardRepFilterUpdateManyWithoutOrganizationInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewUpdateManyWithoutOrganizationInput>;
  day_to_close?: InputMaybe<IntFieldUpdateOperationsInput>;
  default_demo_time_duration?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  demo_held_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_not_held_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_not_held_call_back_short_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_prep_time_minutes?: InputMaybe<IntFieldUpdateOperationsInput>;
  dev_account?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  dial_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  DispositionMultiplier?: InputMaybe<DispositionMultiplierUpdateManyWithoutOrganizationInput>;
  dispositions?: InputMaybe<DispositionUpdateManyWithoutOrganizationInput>;
  double_booking?: InputMaybe<BoolFieldUpdateOperationsInput>;
  dynamic_date_operators?: InputMaybe<BoolFieldUpdateOperationsInput>;
  edge_servers?: InputMaybe<OrganizationUpdateedge_ServersInput>;
  email_conflict_notification_email_list?: InputMaybe<OrganizationUpdateemail_Conflict_Notification_Email_ListInput>;
  EmailItems?: InputMaybe<EmailItemUpdateManyWithoutOrganizationInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordUpdateManyWithoutOrganizationInput>;
  EmailThreads?: InputMaybe<EmailThreadUpdateManyWithoutOrganizationInput>;
  enable_modified_metrics?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  enable_pay_period_view?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  enable_time_constraint?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  error_log_email_address?: InputMaybe<OrganizationUpdateerror_Log_Email_AddressInput>;
  estimated_average_num_of_weekly_overbooked_demo_slots?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  FolderPermissions?: InputMaybe<FolderPermissionUpdateManyWithoutOrganizationInput>;
  Folders?: InputMaybe<FolderUpdateManyWithoutOrganizationInput>;
  FutureBookingSetting?: InputMaybe<FutureBookingSettingUpdateOneWithoutOrganizationInput>;
  GlobalTransferRule?: InputMaybe<GlobalTransferRuleUpdateManyWithoutOrganizationInput>;
  held_demos_per_cc_connect?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  helpful_links?: InputMaybe<OrganizationUpdatehelpful_LinksInput>;
  HingePointMultiplier?: InputMaybe<HingePointMultiplierUpdateManyWithoutOrganizationInput>;
  hold_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
  hubspot_credential?: InputMaybe<HubSpotCredentialUpdateOneWithoutOrganizationInput>;
  hubspot_last_sync_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  hubspot_white_list?: InputMaybe<HubSpotWhitelistUpdateManyWithoutOrganizationInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inbound_close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_dm_connect_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_lt_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_revenue_value_of_sale?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_scheduled_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_set_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventUpdateManyWithoutOrganizationInput>;
  InboundConciergeWidget?: InputMaybe<InboundConciergeWidgetUpdateManyWithoutOrganizationInput>;
  industry_options?: InputMaybe<IndustryOptionUpdateManyWithoutOrganizationInput>;
  initial_sdr_sets_reps_day?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  integration_error_logs?: InputMaybe<IntegrationErrorLogUpdateManyWithoutOrganizationInput>;
  integration_mappings?: InputMaybe<IntegrationMappingUpdateManyWithoutOrganizationInput>;
  IntegrationMappingCustomObject?: InputMaybe<IntegrationMappingCustomObjectUpdateManyWithoutOrganizationInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogUpdateManyWithoutOrganizationInput>;
  IntegrationReadOnlyObject?: InputMaybe<IntegrationReadOnlyObjectUpdateManyWithoutOrganizationInput>;
  international?: InputMaybe<BoolFieldUpdateOperationsInput>;
  is_email_domain_verified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  is_salesforce_sandbox?: InputMaybe<BoolFieldUpdateOperationsInput>;
  last_lead_value_compute_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutOrganizationInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryUpdateManyWithoutOrganizationInput>;
  lead_intent?: InputMaybe<LeadIntentUpdateManyWithoutOrganizationInput>;
  lead_release_same_rep_delay_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  lead_routing_email_list?: InputMaybe<OrganizationUpdatelead_Routing_Email_ListInput>;
  lead_routing_rules?: InputMaybe<RuleUpdateManyWithoutOrganizationInput>;
  LeadCustomObjectRowAssociation?: InputMaybe<LeadCustomObjectRowAssociationUpdateManyWithoutOrganizationInput>;
  LeadFieldMultiplier?: InputMaybe<LeadFieldMultiplierUpdateManyWithoutOrganizationInput>;
  LeadFieldMultiplierCustom?: InputMaybe<LeadFieldMultiplierCustomUpdateManyWithoutOrganizationInput>;
  leads?: InputMaybe<LeadUpdateManyWithoutOrganizationInput>;
  leadsource_options?: InputMaybe<LeadSourceOptionUpdateManyWithoutOrganizationInput>;
  LeadValue?: InputMaybe<LeadValueUpdateManyWithoutOrganizationInput>;
  LifeTimeValueMultiplier?: InputMaybe<LifeTimeValueMultiplierUpdateManyWithoutOrganizationInput>;
  LiveTransferSetting?: InputMaybe<LiveTransferSettingUpdateOneWithoutOrganizationInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusUpdateManyWithoutOrganizationInput>;
  ltv_multiplier_custom_fields?: InputMaybe<LifeTimeValueMultiplierCustomUpdateManyWithoutOrganizationInput>;
  made_contact_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  make_sale_address_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_business_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_city_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_country_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_first_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_industry_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_last_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_lead_source_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_primary_email_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_primary_phone_number_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_state_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_sub_industry_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_zip_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  maxium_day_lag_set_to_schedule?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  mrr?: InputMaybe<IntFieldUpdateOperationsInput>;
  mrr_label?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  mrr_required_on_sale?: InputMaybe<BoolFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  NoteItems?: InputMaybe<NoteItemUpdateManyWithoutOrganizationInput>;
  Notifications?: InputMaybe<NotificationUpdateManyWithoutOrganizationInput>;
  onboardComplete?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  Opportunity?: InputMaybe<SfOpportunityUpdateManyWithoutOrganizationInput>;
  organization_holidays?: InputMaybe<OrganizationHolidayUpdateManyWithoutOrganizationInput>;
  organization_hours?: InputMaybe<OrganizationHourUpdateManyWithoutOrganizationInput>;
  OrganizationPaymentSetting?: InputMaybe<OrganizationPaymentSettingsUpdateOneWithoutOrganizationInput>;
  OrganizationPaymentSettings?: InputMaybe<OrganizationPaymentSettingsUpdateOneWithoutOrganizationsInput>;
  orgCalendarInviteMetadata?: InputMaybe<OrgCalendarInviteMetadataUpdateManyWithoutOrganizationInput>;
  OrgDefaultPipelinePerecentValue?: InputMaybe<OrgDefaultPipelinePerecentValueUpdateOneWithoutOrganizationInput>;
  OrgHubSpotSync?: InputMaybe<OrgHubSpotSyncUpdateOneWithoutOrganizationInput>;
  OrgNoEventDate?: InputMaybe<OrgNoEventDateUpdateManyWithoutOrganizationInput>;
  OrgSalesforceSync?: InputMaybe<OrgSalesforceSyncUpdateOneWithoutOrganizationInput>;
  OrgTimeslotAvailability?: InputMaybe<OrgTimeslotAvailabilityUpdateManyWithoutOrganizationInput>;
  outbound_close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_dm_connect_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_lt_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_revenue_value_of_sale?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_scheduled_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_set_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  PandadocData?: InputMaybe<PandadocDataUpdateOneWithoutOrganizationInput>;
  PandaDocTemplate?: InputMaybe<PandaDocTemplateUpdateManyWithoutOrganizationInput>;
  participant_jitter_buffer_size?: InputMaybe<EnumJitterBufferSizeFieldUpdateOperationsInput>;
  preferred_goal_setting?: InputMaybe<NullableEnumGoalsettingFieldUpdateOperationsInput>;
  prepayment?: InputMaybe<BoolFieldUpdateOperationsInput>;
  ProductChangeLog?: InputMaybe<ProductChangeLogUpdateManyWithoutOrganizationInput>;
  products?: InputMaybe<ProductUpdateManyWithoutOrganizationInput>;
  re_cold_call_hour_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  recordingStatus?: InputMaybe<EnumRecordingStatusFieldUpdateOperationsInput>;
  release_lead_resting?: InputMaybe<IntFieldUpdateOperationsInput>;
  rep_claim_limit?: InputMaybe<IntFieldUpdateOperationsInput>;
  rep_lead_allotment?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  rep_level_call_transfer?: InputMaybe<BoolFieldUpdateOperationsInput>;
  reps_can_bulk_unassign?: InputMaybe<BoolFieldUpdateOperationsInput>;
  reps_can_view_all_notes?: InputMaybe<BoolFieldUpdateOperationsInput>;
  require_sale_notes?: InputMaybe<BoolFieldUpdateOperationsInput>;
  resting_after_skipped_CC?: InputMaybe<IntFieldUpdateOperationsInput>;
  resting_period?: InputMaybe<IntFieldUpdateOperationsInput>;
  resting_period_after_unsuccessful_cold_call?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  resting_period_after_unsuccessful_demo?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  revenue_plan?: InputMaybe<RevenuePlanItemUpdateManyWithoutOrganizationInput>;
  Routings?: InputMaybe<RoutingUpdateManyWithoutOrganizationInput>;
  sale_field_options?: InputMaybe<SaleFieldOptionUpdateManyWithoutOrganizationInput>;
  sale_notification_email_list?: InputMaybe<OrganizationUpdatesale_Notification_Email_ListInput>;
  SaleConfig?: InputMaybe<SaleConfigUpdateOneWithoutOrganizationInput>;
  SaleConfigPage?: InputMaybe<SaleConfigPageUpdateManyWithoutOrganizationInput>;
  SaleConfigSection?: InputMaybe<SaleConfigSectionUpdateManyWithoutOrganizationInput>;
  SaleCycles?: InputMaybe<SaleCycleUpdateManyWithoutOrganizationInput>;
  SaleItems?: InputMaybe<SaleItemUpdateManyWithoutOrganizationInput>;
  sales?: InputMaybe<SaleUpdateManyWithoutOrganizationInput>;
  salesforce_domain?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  salesforce_last_sync_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  SalesForceColumn?: InputMaybe<SalesForceColumnUpdateManyWithoutOrganizationInput>;
  salesForceData?: InputMaybe<SalesForceDataUpdateOneWithoutOrganizationInput>;
  ScheduleItems?: InputMaybe<ScheduleItemUpdateManyWithoutOrganizationInput>;
  self_sourcing_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  send_error_log_email?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  send_lead_routing_email?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sendgrid_email_domain?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sendgrid_email_domain_id?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  sequence?: InputMaybe<SequenceUpdateManyWithoutOrganzationInput>;
  SequenceCriteriaConditions?: InputMaybe<SequenceCriteriaConditionUpdateManyWithoutOrganizationInput>;
  SequenceDashboardViews?: InputMaybe<SequenceDashboardViewUpdateManyWithoutOrganizationInput>;
  SequenceEntryCriterias?: InputMaybe<SequenceEntryCriteriaUpdateManyWithoutOrganizationInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutOrganizationInput>;
  SequenceSteps?: InputMaybe<SequenceStepUpdateManyWithoutOrganizationInput>;
  set_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
  short_resting?: InputMaybe<IntFieldUpdateOperationsInput>;
  show_calendar_options?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_contract_duration_sales?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_lead_source_to_rep?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_nocontact_button?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_payment_terms_sales?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_rep_all_leads?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_rep_my_leads?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_system_view?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sites?: InputMaybe<SiteUpdateManyWithoutOrganizationInput>;
  sms_opt_in_dialog?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SMSItems?: InputMaybe<SmsItemUpdateManyWithoutOrganizationInput>;
  StepActionLogs?: InputMaybe<StepActionLogUpdateManyWithoutOrganizationInput>;
  StepActions?: InputMaybe<StepActionUpdateManyWithoutOrganizationInput>;
  StepConditions?: InputMaybe<StepConditionUpdateManyWithoutOrganizationInput>;
  teams?: InputMaybe<TeamUpdateManyWithoutOrganizationInput>;
  Template?: InputMaybe<TemplateUpdateManyWithoutOrganizationInput>;
  TemplateVersions?: InputMaybe<TemplateVersionUpdateManyWithoutOrganizationInput>;
  timekit_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timezone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transfer_timeout?: InputMaybe<IntFieldUpdateOperationsInput>;
  TransferAttempts?: InputMaybe<TransferAttemptUpdateManyWithoutOrganizationInput>;
  twilio_numbers?: InputMaybe<TwilioNumberUpdateManyWithoutOrganizationInput>;
  TwilioFeedbacks?: InputMaybe<TwilioFeedbackUpdateManyWithoutOrganizationInput>;
  TwilioInfo?: InputMaybe<TwilioInfoUpdateOneWithoutOrganzationInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  use_revenue_planning?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  UserCustomFieldFilters?: InputMaybe<UserCustomFieldFilterUpdateManyWithoutOrganizationInput>;
  users?: InputMaybe<UserUpdateManyWithoutOrganizationInput>;
  win_rep_day_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
};

export type OrganizationUpdateWithoutStepActionLogsInput = {
  ActivityMonitorMultiplier?: InputMaybe<ActivityMonitorMultiplierUpdateManyWithoutOrganizationInput>;
  algoFactor?: InputMaybe<AlgoFactorUpdateManyWithoutOrganizationInput>;
  allow_calendar_invite?: InputMaybe<BoolFieldUpdateOperationsInput>;
  allow_remove_step_action?: InputMaybe<BoolFieldUpdateOperationsInput>;
  allow_snooze_step_action?: InputMaybe<BoolFieldUpdateOperationsInput>;
  always_record?: InputMaybe<BoolFieldUpdateOperationsInput>;
  arv_initial_held_demos_generated_by_sdr_weight?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  AssociationHistories?: InputMaybe<AssociationHistoryUpdateManyWithoutOrganizationInput>;
  auto_call_interval?: InputMaybe<IntFieldUpdateOperationsInput>;
  auto_dial?: InputMaybe<BoolFieldUpdateOperationsInput>;
  automated_campaigns?: InputMaybe<AutomatedCampaignUpdateManyWithoutOrganizationInput>;
  automatic_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  AutoTransferSelections?: InputMaybe<AutoTransferSelectionUpdateManyWithoutOrganizationInput>;
  Availablities?: InputMaybe<AvailabilityUpdateManyWithoutOrganizationInput>;
  AverageOrderValue?: InputMaybe<AverageOrderValueUpdateManyWithoutOrganizationInput>;
  best_action_ever_multiplier?: InputMaybe<FloatFieldUpdateOperationsInput>;
  best_metrics?: InputMaybe<BestMetricRecordUpdateManyWithoutOrganizationInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptUpdateManyWithoutOrganizationInput>;
  callMeNows?: InputMaybe<CallMeNowUpdateManyWithoutOrganizationInput>;
  CallMultiplier?: InputMaybe<CallMultiplierUpdateManyWithoutOrganizationInput>;
  CallSharings?: InputMaybe<CallSharingUpdateManyWithoutOrganizationInput>;
  can_edit_sdr_transfer_rules?: InputMaybe<BoolFieldUpdateOperationsInput>;
  claim_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  concession?: InputMaybe<ConcessionUpdateManyWithoutOrganizationInput>;
  conference_region?: InputMaybe<EnumConferenceRegionFieldUpdateOperationsInput>;
  Conferences?: InputMaybe<ConferenceUpdateManyWithoutOrganizationInput>;
  ContactRequests?: InputMaybe<ContactRequestUpdateManyWithoutOrganizationInput>;
  Contacts?: InputMaybe<ContactUpdateManyWithoutOrganizationInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  custom_fields?: InputMaybe<CustomFieldUpdateManyWithoutOrganizationInput>;
  CustomObject?: InputMaybe<CustomObjectUpdateManyWithoutOrganizationInput>;
  CustomObjectField?: InputMaybe<CustomObjectFieldUpdateManyWithoutOrganizationInput>;
  CustomObjectFieldForeignKey?: InputMaybe<CustomObjectFieldForeignKeyUpdateManyWithoutOrganizationInput>;
  CustomObjectRecord?: InputMaybe<CustomObjectRecordUpdateManyWithoutOrganizationInput>;
  CustomObjectRow?: InputMaybe<CustomObjectRowUpdateManyWithoutOrganizationInput>;
  CustomQueueItems?: InputMaybe<CustomQueueItemUpdateManyWithoutOrganizationInput>;
  DashboardColumnOptions?: InputMaybe<DashboardColumnOptionUpdateManyWithoutOrganizationInput>;
  DashboardLeadFilters?: InputMaybe<DashboardLeadFilterUpdateManyWithoutOrganizationInput>;
  DashboardRepFilters?: InputMaybe<DashboardRepFilterUpdateManyWithoutOrganizationInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewUpdateManyWithoutOrganizationInput>;
  day_to_close?: InputMaybe<IntFieldUpdateOperationsInput>;
  default_demo_time_duration?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  demo_held_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_not_held_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_not_held_call_back_short_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_prep_time_minutes?: InputMaybe<IntFieldUpdateOperationsInput>;
  dev_account?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  dial_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  DispositionMultiplier?: InputMaybe<DispositionMultiplierUpdateManyWithoutOrganizationInput>;
  dispositions?: InputMaybe<DispositionUpdateManyWithoutOrganizationInput>;
  double_booking?: InputMaybe<BoolFieldUpdateOperationsInput>;
  dynamic_date_operators?: InputMaybe<BoolFieldUpdateOperationsInput>;
  edge_servers?: InputMaybe<OrganizationUpdateedge_ServersInput>;
  email_conflict_notification_email_list?: InputMaybe<OrganizationUpdateemail_Conflict_Notification_Email_ListInput>;
  EmailItems?: InputMaybe<EmailItemUpdateManyWithoutOrganizationInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordUpdateManyWithoutOrganizationInput>;
  EmailThreads?: InputMaybe<EmailThreadUpdateManyWithoutOrganizationInput>;
  enable_modified_metrics?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  enable_pay_period_view?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  enable_time_constraint?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  error_log_email_address?: InputMaybe<OrganizationUpdateerror_Log_Email_AddressInput>;
  estimated_average_num_of_weekly_overbooked_demo_slots?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  FolderPermissions?: InputMaybe<FolderPermissionUpdateManyWithoutOrganizationInput>;
  Folders?: InputMaybe<FolderUpdateManyWithoutOrganizationInput>;
  FutureBookingSetting?: InputMaybe<FutureBookingSettingUpdateOneWithoutOrganizationInput>;
  GlobalTransferRule?: InputMaybe<GlobalTransferRuleUpdateManyWithoutOrganizationInput>;
  held_demos_per_cc_connect?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  helpful_links?: InputMaybe<OrganizationUpdatehelpful_LinksInput>;
  HingePointMultiplier?: InputMaybe<HingePointMultiplierUpdateManyWithoutOrganizationInput>;
  hold_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
  hubspot_credential?: InputMaybe<HubSpotCredentialUpdateOneWithoutOrganizationInput>;
  hubspot_last_sync_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  hubspot_white_list?: InputMaybe<HubSpotWhitelistUpdateManyWithoutOrganizationInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inbound_close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_dm_connect_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_lt_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_revenue_value_of_sale?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_scheduled_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_set_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventUpdateManyWithoutOrganizationInput>;
  InboundConciergeWidget?: InputMaybe<InboundConciergeWidgetUpdateManyWithoutOrganizationInput>;
  industry_options?: InputMaybe<IndustryOptionUpdateManyWithoutOrganizationInput>;
  initial_sdr_sets_reps_day?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  integration_error_logs?: InputMaybe<IntegrationErrorLogUpdateManyWithoutOrganizationInput>;
  integration_mappings?: InputMaybe<IntegrationMappingUpdateManyWithoutOrganizationInput>;
  IntegrationMappingCustomObject?: InputMaybe<IntegrationMappingCustomObjectUpdateManyWithoutOrganizationInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogUpdateManyWithoutOrganizationInput>;
  IntegrationReadOnlyObject?: InputMaybe<IntegrationReadOnlyObjectUpdateManyWithoutOrganizationInput>;
  international?: InputMaybe<BoolFieldUpdateOperationsInput>;
  is_email_domain_verified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  is_salesforce_sandbox?: InputMaybe<BoolFieldUpdateOperationsInput>;
  last_lead_value_compute_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutOrganizationInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryUpdateManyWithoutOrganizationInput>;
  lead_intent?: InputMaybe<LeadIntentUpdateManyWithoutOrganizationInput>;
  lead_release_same_rep_delay_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  lead_routing_email_list?: InputMaybe<OrganizationUpdatelead_Routing_Email_ListInput>;
  lead_routing_rules?: InputMaybe<RuleUpdateManyWithoutOrganizationInput>;
  LeadCustomObjectRowAssociation?: InputMaybe<LeadCustomObjectRowAssociationUpdateManyWithoutOrganizationInput>;
  LeadFieldMultiplier?: InputMaybe<LeadFieldMultiplierUpdateManyWithoutOrganizationInput>;
  LeadFieldMultiplierCustom?: InputMaybe<LeadFieldMultiplierCustomUpdateManyWithoutOrganizationInput>;
  leads?: InputMaybe<LeadUpdateManyWithoutOrganizationInput>;
  leadsource_options?: InputMaybe<LeadSourceOptionUpdateManyWithoutOrganizationInput>;
  LeadValue?: InputMaybe<LeadValueUpdateManyWithoutOrganizationInput>;
  LifeTimeValueMultiplier?: InputMaybe<LifeTimeValueMultiplierUpdateManyWithoutOrganizationInput>;
  LiveTransferSetting?: InputMaybe<LiveTransferSettingUpdateOneWithoutOrganizationInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusUpdateManyWithoutOrganizationInput>;
  ltv_multiplier_custom_fields?: InputMaybe<LifeTimeValueMultiplierCustomUpdateManyWithoutOrganizationInput>;
  made_contact_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  make_sale_address_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_business_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_city_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_country_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_first_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_industry_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_last_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_lead_source_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_primary_email_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_primary_phone_number_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_state_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_sub_industry_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_zip_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  maxium_day_lag_set_to_schedule?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  mrr?: InputMaybe<IntFieldUpdateOperationsInput>;
  mrr_label?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  mrr_required_on_sale?: InputMaybe<BoolFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  NoteItems?: InputMaybe<NoteItemUpdateManyWithoutOrganizationInput>;
  Notifications?: InputMaybe<NotificationUpdateManyWithoutOrganizationInput>;
  onboardComplete?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  Opportunity?: InputMaybe<SfOpportunityUpdateManyWithoutOrganizationInput>;
  organization_holidays?: InputMaybe<OrganizationHolidayUpdateManyWithoutOrganizationInput>;
  organization_hours?: InputMaybe<OrganizationHourUpdateManyWithoutOrganizationInput>;
  OrganizationPaymentSetting?: InputMaybe<OrganizationPaymentSettingsUpdateOneWithoutOrganizationInput>;
  OrganizationPaymentSettings?: InputMaybe<OrganizationPaymentSettingsUpdateOneWithoutOrganizationsInput>;
  orgCalendarInviteMetadata?: InputMaybe<OrgCalendarInviteMetadataUpdateManyWithoutOrganizationInput>;
  OrgDefaultPipelinePerecentValue?: InputMaybe<OrgDefaultPipelinePerecentValueUpdateOneWithoutOrganizationInput>;
  OrgHubSpotSync?: InputMaybe<OrgHubSpotSyncUpdateOneWithoutOrganizationInput>;
  OrgNoEventDate?: InputMaybe<OrgNoEventDateUpdateManyWithoutOrganizationInput>;
  OrgSalesforceSync?: InputMaybe<OrgSalesforceSyncUpdateOneWithoutOrganizationInput>;
  OrgTimeslotAvailability?: InputMaybe<OrgTimeslotAvailabilityUpdateManyWithoutOrganizationInput>;
  outbound_close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_dm_connect_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_lt_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_revenue_value_of_sale?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_scheduled_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_set_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  PandadocData?: InputMaybe<PandadocDataUpdateOneWithoutOrganizationInput>;
  PandaDocTemplate?: InputMaybe<PandaDocTemplateUpdateManyWithoutOrganizationInput>;
  participant_jitter_buffer_size?: InputMaybe<EnumJitterBufferSizeFieldUpdateOperationsInput>;
  preferred_goal_setting?: InputMaybe<NullableEnumGoalsettingFieldUpdateOperationsInput>;
  prepayment?: InputMaybe<BoolFieldUpdateOperationsInput>;
  ProductChangeLog?: InputMaybe<ProductChangeLogUpdateManyWithoutOrganizationInput>;
  products?: InputMaybe<ProductUpdateManyWithoutOrganizationInput>;
  re_cold_call_hour_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  recordingStatus?: InputMaybe<EnumRecordingStatusFieldUpdateOperationsInput>;
  release_lead_resting?: InputMaybe<IntFieldUpdateOperationsInput>;
  rep_claim_limit?: InputMaybe<IntFieldUpdateOperationsInput>;
  rep_lead_allotment?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  rep_level_call_transfer?: InputMaybe<BoolFieldUpdateOperationsInput>;
  reps_can_bulk_unassign?: InputMaybe<BoolFieldUpdateOperationsInput>;
  reps_can_view_all_notes?: InputMaybe<BoolFieldUpdateOperationsInput>;
  require_sale_notes?: InputMaybe<BoolFieldUpdateOperationsInput>;
  resting_after_skipped_CC?: InputMaybe<IntFieldUpdateOperationsInput>;
  resting_period?: InputMaybe<IntFieldUpdateOperationsInput>;
  resting_period_after_unsuccessful_cold_call?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  resting_period_after_unsuccessful_demo?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  revenue_plan?: InputMaybe<RevenuePlanItemUpdateManyWithoutOrganizationInput>;
  Routings?: InputMaybe<RoutingUpdateManyWithoutOrganizationInput>;
  sale_field_options?: InputMaybe<SaleFieldOptionUpdateManyWithoutOrganizationInput>;
  sale_notification_email_list?: InputMaybe<OrganizationUpdatesale_Notification_Email_ListInput>;
  SaleConfig?: InputMaybe<SaleConfigUpdateOneWithoutOrganizationInput>;
  SaleConfigPage?: InputMaybe<SaleConfigPageUpdateManyWithoutOrganizationInput>;
  SaleConfigSection?: InputMaybe<SaleConfigSectionUpdateManyWithoutOrganizationInput>;
  SaleCycles?: InputMaybe<SaleCycleUpdateManyWithoutOrganizationInput>;
  SaleItems?: InputMaybe<SaleItemUpdateManyWithoutOrganizationInput>;
  sales?: InputMaybe<SaleUpdateManyWithoutOrganizationInput>;
  salesforce_domain?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  salesforce_last_sync_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  SalesForceColumn?: InputMaybe<SalesForceColumnUpdateManyWithoutOrganizationInput>;
  salesForceData?: InputMaybe<SalesForceDataUpdateOneWithoutOrganizationInput>;
  ScheduleItems?: InputMaybe<ScheduleItemUpdateManyWithoutOrganizationInput>;
  self_sourcing_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  send_error_log_email?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  send_lead_routing_email?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sendgrid_email_domain?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sendgrid_email_domain_id?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  sequence?: InputMaybe<SequenceUpdateManyWithoutOrganzationInput>;
  SequenceCriteriaConditions?: InputMaybe<SequenceCriteriaConditionUpdateManyWithoutOrganizationInput>;
  SequenceDashboardViews?: InputMaybe<SequenceDashboardViewUpdateManyWithoutOrganizationInput>;
  SequenceEntryCriterias?: InputMaybe<SequenceEntryCriteriaUpdateManyWithoutOrganizationInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutOrganizationInput>;
  SequenceSteps?: InputMaybe<SequenceStepUpdateManyWithoutOrganizationInput>;
  set_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
  short_resting?: InputMaybe<IntFieldUpdateOperationsInput>;
  show_calendar_options?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_contract_duration_sales?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_lead_source_to_rep?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_nocontact_button?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_payment_terms_sales?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_rep_all_leads?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_rep_my_leads?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_system_view?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sites?: InputMaybe<SiteUpdateManyWithoutOrganizationInput>;
  sms_opt_in_dialog?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SMSItems?: InputMaybe<SmsItemUpdateManyWithoutOrganizationInput>;
  SnoozeTrackings?: InputMaybe<SnoozeTrackingUpdateManyWithoutOrganizationInput>;
  StepActions?: InputMaybe<StepActionUpdateManyWithoutOrganizationInput>;
  StepConditions?: InputMaybe<StepConditionUpdateManyWithoutOrganizationInput>;
  teams?: InputMaybe<TeamUpdateManyWithoutOrganizationInput>;
  Template?: InputMaybe<TemplateUpdateManyWithoutOrganizationInput>;
  TemplateVersions?: InputMaybe<TemplateVersionUpdateManyWithoutOrganizationInput>;
  timekit_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timezone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transfer_timeout?: InputMaybe<IntFieldUpdateOperationsInput>;
  TransferAttempts?: InputMaybe<TransferAttemptUpdateManyWithoutOrganizationInput>;
  twilio_numbers?: InputMaybe<TwilioNumberUpdateManyWithoutOrganizationInput>;
  TwilioFeedbacks?: InputMaybe<TwilioFeedbackUpdateManyWithoutOrganizationInput>;
  TwilioInfo?: InputMaybe<TwilioInfoUpdateOneWithoutOrganzationInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  use_revenue_planning?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  UserCustomFieldFilters?: InputMaybe<UserCustomFieldFilterUpdateManyWithoutOrganizationInput>;
  users?: InputMaybe<UserUpdateManyWithoutOrganizationInput>;
  win_rep_day_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
};

export type OrganizationUpdateWithoutStepActionsInput = {
  ActivityMonitorMultiplier?: InputMaybe<ActivityMonitorMultiplierUpdateManyWithoutOrganizationInput>;
  algoFactor?: InputMaybe<AlgoFactorUpdateManyWithoutOrganizationInput>;
  allow_calendar_invite?: InputMaybe<BoolFieldUpdateOperationsInput>;
  allow_remove_step_action?: InputMaybe<BoolFieldUpdateOperationsInput>;
  allow_snooze_step_action?: InputMaybe<BoolFieldUpdateOperationsInput>;
  always_record?: InputMaybe<BoolFieldUpdateOperationsInput>;
  arv_initial_held_demos_generated_by_sdr_weight?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  AssociationHistories?: InputMaybe<AssociationHistoryUpdateManyWithoutOrganizationInput>;
  auto_call_interval?: InputMaybe<IntFieldUpdateOperationsInput>;
  auto_dial?: InputMaybe<BoolFieldUpdateOperationsInput>;
  automated_campaigns?: InputMaybe<AutomatedCampaignUpdateManyWithoutOrganizationInput>;
  automatic_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  AutoTransferSelections?: InputMaybe<AutoTransferSelectionUpdateManyWithoutOrganizationInput>;
  Availablities?: InputMaybe<AvailabilityUpdateManyWithoutOrganizationInput>;
  AverageOrderValue?: InputMaybe<AverageOrderValueUpdateManyWithoutOrganizationInput>;
  best_action_ever_multiplier?: InputMaybe<FloatFieldUpdateOperationsInput>;
  best_metrics?: InputMaybe<BestMetricRecordUpdateManyWithoutOrganizationInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptUpdateManyWithoutOrganizationInput>;
  callMeNows?: InputMaybe<CallMeNowUpdateManyWithoutOrganizationInput>;
  CallMultiplier?: InputMaybe<CallMultiplierUpdateManyWithoutOrganizationInput>;
  CallSharings?: InputMaybe<CallSharingUpdateManyWithoutOrganizationInput>;
  can_edit_sdr_transfer_rules?: InputMaybe<BoolFieldUpdateOperationsInput>;
  claim_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  concession?: InputMaybe<ConcessionUpdateManyWithoutOrganizationInput>;
  conference_region?: InputMaybe<EnumConferenceRegionFieldUpdateOperationsInput>;
  Conferences?: InputMaybe<ConferenceUpdateManyWithoutOrganizationInput>;
  ContactRequests?: InputMaybe<ContactRequestUpdateManyWithoutOrganizationInput>;
  Contacts?: InputMaybe<ContactUpdateManyWithoutOrganizationInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  custom_fields?: InputMaybe<CustomFieldUpdateManyWithoutOrganizationInput>;
  CustomObject?: InputMaybe<CustomObjectUpdateManyWithoutOrganizationInput>;
  CustomObjectField?: InputMaybe<CustomObjectFieldUpdateManyWithoutOrganizationInput>;
  CustomObjectFieldForeignKey?: InputMaybe<CustomObjectFieldForeignKeyUpdateManyWithoutOrganizationInput>;
  CustomObjectRecord?: InputMaybe<CustomObjectRecordUpdateManyWithoutOrganizationInput>;
  CustomObjectRow?: InputMaybe<CustomObjectRowUpdateManyWithoutOrganizationInput>;
  CustomQueueItems?: InputMaybe<CustomQueueItemUpdateManyWithoutOrganizationInput>;
  DashboardColumnOptions?: InputMaybe<DashboardColumnOptionUpdateManyWithoutOrganizationInput>;
  DashboardLeadFilters?: InputMaybe<DashboardLeadFilterUpdateManyWithoutOrganizationInput>;
  DashboardRepFilters?: InputMaybe<DashboardRepFilterUpdateManyWithoutOrganizationInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewUpdateManyWithoutOrganizationInput>;
  day_to_close?: InputMaybe<IntFieldUpdateOperationsInput>;
  default_demo_time_duration?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  demo_held_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_not_held_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_not_held_call_back_short_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_prep_time_minutes?: InputMaybe<IntFieldUpdateOperationsInput>;
  dev_account?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  dial_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  DispositionMultiplier?: InputMaybe<DispositionMultiplierUpdateManyWithoutOrganizationInput>;
  dispositions?: InputMaybe<DispositionUpdateManyWithoutOrganizationInput>;
  double_booking?: InputMaybe<BoolFieldUpdateOperationsInput>;
  dynamic_date_operators?: InputMaybe<BoolFieldUpdateOperationsInput>;
  edge_servers?: InputMaybe<OrganizationUpdateedge_ServersInput>;
  email_conflict_notification_email_list?: InputMaybe<OrganizationUpdateemail_Conflict_Notification_Email_ListInput>;
  EmailItems?: InputMaybe<EmailItemUpdateManyWithoutOrganizationInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordUpdateManyWithoutOrganizationInput>;
  EmailThreads?: InputMaybe<EmailThreadUpdateManyWithoutOrganizationInput>;
  enable_modified_metrics?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  enable_pay_period_view?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  enable_time_constraint?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  error_log_email_address?: InputMaybe<OrganizationUpdateerror_Log_Email_AddressInput>;
  estimated_average_num_of_weekly_overbooked_demo_slots?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  FolderPermissions?: InputMaybe<FolderPermissionUpdateManyWithoutOrganizationInput>;
  Folders?: InputMaybe<FolderUpdateManyWithoutOrganizationInput>;
  FutureBookingSetting?: InputMaybe<FutureBookingSettingUpdateOneWithoutOrganizationInput>;
  GlobalTransferRule?: InputMaybe<GlobalTransferRuleUpdateManyWithoutOrganizationInput>;
  held_demos_per_cc_connect?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  helpful_links?: InputMaybe<OrganizationUpdatehelpful_LinksInput>;
  HingePointMultiplier?: InputMaybe<HingePointMultiplierUpdateManyWithoutOrganizationInput>;
  hold_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
  hubspot_credential?: InputMaybe<HubSpotCredentialUpdateOneWithoutOrganizationInput>;
  hubspot_last_sync_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  hubspot_white_list?: InputMaybe<HubSpotWhitelistUpdateManyWithoutOrganizationInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inbound_close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_dm_connect_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_lt_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_revenue_value_of_sale?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_scheduled_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_set_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventUpdateManyWithoutOrganizationInput>;
  InboundConciergeWidget?: InputMaybe<InboundConciergeWidgetUpdateManyWithoutOrganizationInput>;
  industry_options?: InputMaybe<IndustryOptionUpdateManyWithoutOrganizationInput>;
  initial_sdr_sets_reps_day?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  integration_error_logs?: InputMaybe<IntegrationErrorLogUpdateManyWithoutOrganizationInput>;
  integration_mappings?: InputMaybe<IntegrationMappingUpdateManyWithoutOrganizationInput>;
  IntegrationMappingCustomObject?: InputMaybe<IntegrationMappingCustomObjectUpdateManyWithoutOrganizationInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogUpdateManyWithoutOrganizationInput>;
  IntegrationReadOnlyObject?: InputMaybe<IntegrationReadOnlyObjectUpdateManyWithoutOrganizationInput>;
  international?: InputMaybe<BoolFieldUpdateOperationsInput>;
  is_email_domain_verified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  is_salesforce_sandbox?: InputMaybe<BoolFieldUpdateOperationsInput>;
  last_lead_value_compute_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutOrganizationInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryUpdateManyWithoutOrganizationInput>;
  lead_intent?: InputMaybe<LeadIntentUpdateManyWithoutOrganizationInput>;
  lead_release_same_rep_delay_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  lead_routing_email_list?: InputMaybe<OrganizationUpdatelead_Routing_Email_ListInput>;
  lead_routing_rules?: InputMaybe<RuleUpdateManyWithoutOrganizationInput>;
  LeadCustomObjectRowAssociation?: InputMaybe<LeadCustomObjectRowAssociationUpdateManyWithoutOrganizationInput>;
  LeadFieldMultiplier?: InputMaybe<LeadFieldMultiplierUpdateManyWithoutOrganizationInput>;
  LeadFieldMultiplierCustom?: InputMaybe<LeadFieldMultiplierCustomUpdateManyWithoutOrganizationInput>;
  leads?: InputMaybe<LeadUpdateManyWithoutOrganizationInput>;
  leadsource_options?: InputMaybe<LeadSourceOptionUpdateManyWithoutOrganizationInput>;
  LeadValue?: InputMaybe<LeadValueUpdateManyWithoutOrganizationInput>;
  LifeTimeValueMultiplier?: InputMaybe<LifeTimeValueMultiplierUpdateManyWithoutOrganizationInput>;
  LiveTransferSetting?: InputMaybe<LiveTransferSettingUpdateOneWithoutOrganizationInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusUpdateManyWithoutOrganizationInput>;
  ltv_multiplier_custom_fields?: InputMaybe<LifeTimeValueMultiplierCustomUpdateManyWithoutOrganizationInput>;
  made_contact_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  make_sale_address_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_business_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_city_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_country_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_first_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_industry_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_last_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_lead_source_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_primary_email_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_primary_phone_number_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_state_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_sub_industry_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_zip_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  maxium_day_lag_set_to_schedule?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  mrr?: InputMaybe<IntFieldUpdateOperationsInput>;
  mrr_label?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  mrr_required_on_sale?: InputMaybe<BoolFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  NoteItems?: InputMaybe<NoteItemUpdateManyWithoutOrganizationInput>;
  Notifications?: InputMaybe<NotificationUpdateManyWithoutOrganizationInput>;
  onboardComplete?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  Opportunity?: InputMaybe<SfOpportunityUpdateManyWithoutOrganizationInput>;
  organization_holidays?: InputMaybe<OrganizationHolidayUpdateManyWithoutOrganizationInput>;
  organization_hours?: InputMaybe<OrganizationHourUpdateManyWithoutOrganizationInput>;
  OrganizationPaymentSetting?: InputMaybe<OrganizationPaymentSettingsUpdateOneWithoutOrganizationInput>;
  OrganizationPaymentSettings?: InputMaybe<OrganizationPaymentSettingsUpdateOneWithoutOrganizationsInput>;
  orgCalendarInviteMetadata?: InputMaybe<OrgCalendarInviteMetadataUpdateManyWithoutOrganizationInput>;
  OrgDefaultPipelinePerecentValue?: InputMaybe<OrgDefaultPipelinePerecentValueUpdateOneWithoutOrganizationInput>;
  OrgHubSpotSync?: InputMaybe<OrgHubSpotSyncUpdateOneWithoutOrganizationInput>;
  OrgNoEventDate?: InputMaybe<OrgNoEventDateUpdateManyWithoutOrganizationInput>;
  OrgSalesforceSync?: InputMaybe<OrgSalesforceSyncUpdateOneWithoutOrganizationInput>;
  OrgTimeslotAvailability?: InputMaybe<OrgTimeslotAvailabilityUpdateManyWithoutOrganizationInput>;
  outbound_close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_dm_connect_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_lt_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_revenue_value_of_sale?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_scheduled_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_set_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  PandadocData?: InputMaybe<PandadocDataUpdateOneWithoutOrganizationInput>;
  PandaDocTemplate?: InputMaybe<PandaDocTemplateUpdateManyWithoutOrganizationInput>;
  participant_jitter_buffer_size?: InputMaybe<EnumJitterBufferSizeFieldUpdateOperationsInput>;
  preferred_goal_setting?: InputMaybe<NullableEnumGoalsettingFieldUpdateOperationsInput>;
  prepayment?: InputMaybe<BoolFieldUpdateOperationsInput>;
  ProductChangeLog?: InputMaybe<ProductChangeLogUpdateManyWithoutOrganizationInput>;
  products?: InputMaybe<ProductUpdateManyWithoutOrganizationInput>;
  re_cold_call_hour_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  recordingStatus?: InputMaybe<EnumRecordingStatusFieldUpdateOperationsInput>;
  release_lead_resting?: InputMaybe<IntFieldUpdateOperationsInput>;
  rep_claim_limit?: InputMaybe<IntFieldUpdateOperationsInput>;
  rep_lead_allotment?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  rep_level_call_transfer?: InputMaybe<BoolFieldUpdateOperationsInput>;
  reps_can_bulk_unassign?: InputMaybe<BoolFieldUpdateOperationsInput>;
  reps_can_view_all_notes?: InputMaybe<BoolFieldUpdateOperationsInput>;
  require_sale_notes?: InputMaybe<BoolFieldUpdateOperationsInput>;
  resting_after_skipped_CC?: InputMaybe<IntFieldUpdateOperationsInput>;
  resting_period?: InputMaybe<IntFieldUpdateOperationsInput>;
  resting_period_after_unsuccessful_cold_call?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  resting_period_after_unsuccessful_demo?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  revenue_plan?: InputMaybe<RevenuePlanItemUpdateManyWithoutOrganizationInput>;
  Routings?: InputMaybe<RoutingUpdateManyWithoutOrganizationInput>;
  sale_field_options?: InputMaybe<SaleFieldOptionUpdateManyWithoutOrganizationInput>;
  sale_notification_email_list?: InputMaybe<OrganizationUpdatesale_Notification_Email_ListInput>;
  SaleConfig?: InputMaybe<SaleConfigUpdateOneWithoutOrganizationInput>;
  SaleConfigPage?: InputMaybe<SaleConfigPageUpdateManyWithoutOrganizationInput>;
  SaleConfigSection?: InputMaybe<SaleConfigSectionUpdateManyWithoutOrganizationInput>;
  SaleCycles?: InputMaybe<SaleCycleUpdateManyWithoutOrganizationInput>;
  SaleItems?: InputMaybe<SaleItemUpdateManyWithoutOrganizationInput>;
  sales?: InputMaybe<SaleUpdateManyWithoutOrganizationInput>;
  salesforce_domain?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  salesforce_last_sync_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  SalesForceColumn?: InputMaybe<SalesForceColumnUpdateManyWithoutOrganizationInput>;
  salesForceData?: InputMaybe<SalesForceDataUpdateOneWithoutOrganizationInput>;
  ScheduleItems?: InputMaybe<ScheduleItemUpdateManyWithoutOrganizationInput>;
  self_sourcing_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  send_error_log_email?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  send_lead_routing_email?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sendgrid_email_domain?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sendgrid_email_domain_id?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  sequence?: InputMaybe<SequenceUpdateManyWithoutOrganzationInput>;
  SequenceCriteriaConditions?: InputMaybe<SequenceCriteriaConditionUpdateManyWithoutOrganizationInput>;
  SequenceDashboardViews?: InputMaybe<SequenceDashboardViewUpdateManyWithoutOrganizationInput>;
  SequenceEntryCriterias?: InputMaybe<SequenceEntryCriteriaUpdateManyWithoutOrganizationInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutOrganizationInput>;
  SequenceSteps?: InputMaybe<SequenceStepUpdateManyWithoutOrganizationInput>;
  set_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
  short_resting?: InputMaybe<IntFieldUpdateOperationsInput>;
  show_calendar_options?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_contract_duration_sales?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_lead_source_to_rep?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_nocontact_button?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_payment_terms_sales?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_rep_all_leads?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_rep_my_leads?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_system_view?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sites?: InputMaybe<SiteUpdateManyWithoutOrganizationInput>;
  sms_opt_in_dialog?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SMSItems?: InputMaybe<SmsItemUpdateManyWithoutOrganizationInput>;
  SnoozeTrackings?: InputMaybe<SnoozeTrackingUpdateManyWithoutOrganizationInput>;
  StepActionLogs?: InputMaybe<StepActionLogUpdateManyWithoutOrganizationInput>;
  StepConditions?: InputMaybe<StepConditionUpdateManyWithoutOrganizationInput>;
  teams?: InputMaybe<TeamUpdateManyWithoutOrganizationInput>;
  Template?: InputMaybe<TemplateUpdateManyWithoutOrganizationInput>;
  TemplateVersions?: InputMaybe<TemplateVersionUpdateManyWithoutOrganizationInput>;
  timekit_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timezone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transfer_timeout?: InputMaybe<IntFieldUpdateOperationsInput>;
  TransferAttempts?: InputMaybe<TransferAttemptUpdateManyWithoutOrganizationInput>;
  twilio_numbers?: InputMaybe<TwilioNumberUpdateManyWithoutOrganizationInput>;
  TwilioFeedbacks?: InputMaybe<TwilioFeedbackUpdateManyWithoutOrganizationInput>;
  TwilioInfo?: InputMaybe<TwilioInfoUpdateOneWithoutOrganzationInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  use_revenue_planning?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  UserCustomFieldFilters?: InputMaybe<UserCustomFieldFilterUpdateManyWithoutOrganizationInput>;
  users?: InputMaybe<UserUpdateManyWithoutOrganizationInput>;
  win_rep_day_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
};

export type OrganizationUpdateWithoutStepConditionsInput = {
  ActivityMonitorMultiplier?: InputMaybe<ActivityMonitorMultiplierUpdateManyWithoutOrganizationInput>;
  algoFactor?: InputMaybe<AlgoFactorUpdateManyWithoutOrganizationInput>;
  allow_calendar_invite?: InputMaybe<BoolFieldUpdateOperationsInput>;
  allow_remove_step_action?: InputMaybe<BoolFieldUpdateOperationsInput>;
  allow_snooze_step_action?: InputMaybe<BoolFieldUpdateOperationsInput>;
  always_record?: InputMaybe<BoolFieldUpdateOperationsInput>;
  arv_initial_held_demos_generated_by_sdr_weight?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  AssociationHistories?: InputMaybe<AssociationHistoryUpdateManyWithoutOrganizationInput>;
  auto_call_interval?: InputMaybe<IntFieldUpdateOperationsInput>;
  auto_dial?: InputMaybe<BoolFieldUpdateOperationsInput>;
  automated_campaigns?: InputMaybe<AutomatedCampaignUpdateManyWithoutOrganizationInput>;
  automatic_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  AutoTransferSelections?: InputMaybe<AutoTransferSelectionUpdateManyWithoutOrganizationInput>;
  Availablities?: InputMaybe<AvailabilityUpdateManyWithoutOrganizationInput>;
  AverageOrderValue?: InputMaybe<AverageOrderValueUpdateManyWithoutOrganizationInput>;
  best_action_ever_multiplier?: InputMaybe<FloatFieldUpdateOperationsInput>;
  best_metrics?: InputMaybe<BestMetricRecordUpdateManyWithoutOrganizationInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptUpdateManyWithoutOrganizationInput>;
  callMeNows?: InputMaybe<CallMeNowUpdateManyWithoutOrganizationInput>;
  CallMultiplier?: InputMaybe<CallMultiplierUpdateManyWithoutOrganizationInput>;
  CallSharings?: InputMaybe<CallSharingUpdateManyWithoutOrganizationInput>;
  can_edit_sdr_transfer_rules?: InputMaybe<BoolFieldUpdateOperationsInput>;
  claim_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  concession?: InputMaybe<ConcessionUpdateManyWithoutOrganizationInput>;
  conference_region?: InputMaybe<EnumConferenceRegionFieldUpdateOperationsInput>;
  Conferences?: InputMaybe<ConferenceUpdateManyWithoutOrganizationInput>;
  ContactRequests?: InputMaybe<ContactRequestUpdateManyWithoutOrganizationInput>;
  Contacts?: InputMaybe<ContactUpdateManyWithoutOrganizationInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  custom_fields?: InputMaybe<CustomFieldUpdateManyWithoutOrganizationInput>;
  CustomObject?: InputMaybe<CustomObjectUpdateManyWithoutOrganizationInput>;
  CustomObjectField?: InputMaybe<CustomObjectFieldUpdateManyWithoutOrganizationInput>;
  CustomObjectFieldForeignKey?: InputMaybe<CustomObjectFieldForeignKeyUpdateManyWithoutOrganizationInput>;
  CustomObjectRecord?: InputMaybe<CustomObjectRecordUpdateManyWithoutOrganizationInput>;
  CustomObjectRow?: InputMaybe<CustomObjectRowUpdateManyWithoutOrganizationInput>;
  CustomQueueItems?: InputMaybe<CustomQueueItemUpdateManyWithoutOrganizationInput>;
  DashboardColumnOptions?: InputMaybe<DashboardColumnOptionUpdateManyWithoutOrganizationInput>;
  DashboardLeadFilters?: InputMaybe<DashboardLeadFilterUpdateManyWithoutOrganizationInput>;
  DashboardRepFilters?: InputMaybe<DashboardRepFilterUpdateManyWithoutOrganizationInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewUpdateManyWithoutOrganizationInput>;
  day_to_close?: InputMaybe<IntFieldUpdateOperationsInput>;
  default_demo_time_duration?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  demo_held_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_not_held_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_not_held_call_back_short_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_prep_time_minutes?: InputMaybe<IntFieldUpdateOperationsInput>;
  dev_account?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  dial_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  DispositionMultiplier?: InputMaybe<DispositionMultiplierUpdateManyWithoutOrganizationInput>;
  dispositions?: InputMaybe<DispositionUpdateManyWithoutOrganizationInput>;
  double_booking?: InputMaybe<BoolFieldUpdateOperationsInput>;
  dynamic_date_operators?: InputMaybe<BoolFieldUpdateOperationsInput>;
  edge_servers?: InputMaybe<OrganizationUpdateedge_ServersInput>;
  email_conflict_notification_email_list?: InputMaybe<OrganizationUpdateemail_Conflict_Notification_Email_ListInput>;
  EmailItems?: InputMaybe<EmailItemUpdateManyWithoutOrganizationInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordUpdateManyWithoutOrganizationInput>;
  EmailThreads?: InputMaybe<EmailThreadUpdateManyWithoutOrganizationInput>;
  enable_modified_metrics?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  enable_pay_period_view?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  enable_time_constraint?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  error_log_email_address?: InputMaybe<OrganizationUpdateerror_Log_Email_AddressInput>;
  estimated_average_num_of_weekly_overbooked_demo_slots?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  FolderPermissions?: InputMaybe<FolderPermissionUpdateManyWithoutOrganizationInput>;
  Folders?: InputMaybe<FolderUpdateManyWithoutOrganizationInput>;
  FutureBookingSetting?: InputMaybe<FutureBookingSettingUpdateOneWithoutOrganizationInput>;
  GlobalTransferRule?: InputMaybe<GlobalTransferRuleUpdateManyWithoutOrganizationInput>;
  held_demos_per_cc_connect?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  helpful_links?: InputMaybe<OrganizationUpdatehelpful_LinksInput>;
  HingePointMultiplier?: InputMaybe<HingePointMultiplierUpdateManyWithoutOrganizationInput>;
  hold_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
  hubspot_credential?: InputMaybe<HubSpotCredentialUpdateOneWithoutOrganizationInput>;
  hubspot_last_sync_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  hubspot_white_list?: InputMaybe<HubSpotWhitelistUpdateManyWithoutOrganizationInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inbound_close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_dm_connect_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_lt_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_revenue_value_of_sale?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_scheduled_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_set_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventUpdateManyWithoutOrganizationInput>;
  InboundConciergeWidget?: InputMaybe<InboundConciergeWidgetUpdateManyWithoutOrganizationInput>;
  industry_options?: InputMaybe<IndustryOptionUpdateManyWithoutOrganizationInput>;
  initial_sdr_sets_reps_day?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  integration_error_logs?: InputMaybe<IntegrationErrorLogUpdateManyWithoutOrganizationInput>;
  integration_mappings?: InputMaybe<IntegrationMappingUpdateManyWithoutOrganizationInput>;
  IntegrationMappingCustomObject?: InputMaybe<IntegrationMappingCustomObjectUpdateManyWithoutOrganizationInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogUpdateManyWithoutOrganizationInput>;
  IntegrationReadOnlyObject?: InputMaybe<IntegrationReadOnlyObjectUpdateManyWithoutOrganizationInput>;
  international?: InputMaybe<BoolFieldUpdateOperationsInput>;
  is_email_domain_verified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  is_salesforce_sandbox?: InputMaybe<BoolFieldUpdateOperationsInput>;
  last_lead_value_compute_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutOrganizationInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryUpdateManyWithoutOrganizationInput>;
  lead_intent?: InputMaybe<LeadIntentUpdateManyWithoutOrganizationInput>;
  lead_release_same_rep_delay_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  lead_routing_email_list?: InputMaybe<OrganizationUpdatelead_Routing_Email_ListInput>;
  lead_routing_rules?: InputMaybe<RuleUpdateManyWithoutOrganizationInput>;
  LeadCustomObjectRowAssociation?: InputMaybe<LeadCustomObjectRowAssociationUpdateManyWithoutOrganizationInput>;
  LeadFieldMultiplier?: InputMaybe<LeadFieldMultiplierUpdateManyWithoutOrganizationInput>;
  LeadFieldMultiplierCustom?: InputMaybe<LeadFieldMultiplierCustomUpdateManyWithoutOrganizationInput>;
  leads?: InputMaybe<LeadUpdateManyWithoutOrganizationInput>;
  leadsource_options?: InputMaybe<LeadSourceOptionUpdateManyWithoutOrganizationInput>;
  LeadValue?: InputMaybe<LeadValueUpdateManyWithoutOrganizationInput>;
  LifeTimeValueMultiplier?: InputMaybe<LifeTimeValueMultiplierUpdateManyWithoutOrganizationInput>;
  LiveTransferSetting?: InputMaybe<LiveTransferSettingUpdateOneWithoutOrganizationInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusUpdateManyWithoutOrganizationInput>;
  ltv_multiplier_custom_fields?: InputMaybe<LifeTimeValueMultiplierCustomUpdateManyWithoutOrganizationInput>;
  made_contact_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  make_sale_address_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_business_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_city_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_country_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_first_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_industry_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_last_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_lead_source_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_primary_email_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_primary_phone_number_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_state_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_sub_industry_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_zip_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  maxium_day_lag_set_to_schedule?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  mrr?: InputMaybe<IntFieldUpdateOperationsInput>;
  mrr_label?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  mrr_required_on_sale?: InputMaybe<BoolFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  NoteItems?: InputMaybe<NoteItemUpdateManyWithoutOrganizationInput>;
  Notifications?: InputMaybe<NotificationUpdateManyWithoutOrganizationInput>;
  onboardComplete?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  Opportunity?: InputMaybe<SfOpportunityUpdateManyWithoutOrganizationInput>;
  organization_holidays?: InputMaybe<OrganizationHolidayUpdateManyWithoutOrganizationInput>;
  organization_hours?: InputMaybe<OrganizationHourUpdateManyWithoutOrganizationInput>;
  OrganizationPaymentSetting?: InputMaybe<OrganizationPaymentSettingsUpdateOneWithoutOrganizationInput>;
  OrganizationPaymentSettings?: InputMaybe<OrganizationPaymentSettingsUpdateOneWithoutOrganizationsInput>;
  orgCalendarInviteMetadata?: InputMaybe<OrgCalendarInviteMetadataUpdateManyWithoutOrganizationInput>;
  OrgDefaultPipelinePerecentValue?: InputMaybe<OrgDefaultPipelinePerecentValueUpdateOneWithoutOrganizationInput>;
  OrgHubSpotSync?: InputMaybe<OrgHubSpotSyncUpdateOneWithoutOrganizationInput>;
  OrgNoEventDate?: InputMaybe<OrgNoEventDateUpdateManyWithoutOrganizationInput>;
  OrgSalesforceSync?: InputMaybe<OrgSalesforceSyncUpdateOneWithoutOrganizationInput>;
  OrgTimeslotAvailability?: InputMaybe<OrgTimeslotAvailabilityUpdateManyWithoutOrganizationInput>;
  outbound_close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_dm_connect_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_lt_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_revenue_value_of_sale?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_scheduled_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_set_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  PandadocData?: InputMaybe<PandadocDataUpdateOneWithoutOrganizationInput>;
  PandaDocTemplate?: InputMaybe<PandaDocTemplateUpdateManyWithoutOrganizationInput>;
  participant_jitter_buffer_size?: InputMaybe<EnumJitterBufferSizeFieldUpdateOperationsInput>;
  preferred_goal_setting?: InputMaybe<NullableEnumGoalsettingFieldUpdateOperationsInput>;
  prepayment?: InputMaybe<BoolFieldUpdateOperationsInput>;
  ProductChangeLog?: InputMaybe<ProductChangeLogUpdateManyWithoutOrganizationInput>;
  products?: InputMaybe<ProductUpdateManyWithoutOrganizationInput>;
  re_cold_call_hour_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  recordingStatus?: InputMaybe<EnumRecordingStatusFieldUpdateOperationsInput>;
  release_lead_resting?: InputMaybe<IntFieldUpdateOperationsInput>;
  rep_claim_limit?: InputMaybe<IntFieldUpdateOperationsInput>;
  rep_lead_allotment?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  rep_level_call_transfer?: InputMaybe<BoolFieldUpdateOperationsInput>;
  reps_can_bulk_unassign?: InputMaybe<BoolFieldUpdateOperationsInput>;
  reps_can_view_all_notes?: InputMaybe<BoolFieldUpdateOperationsInput>;
  require_sale_notes?: InputMaybe<BoolFieldUpdateOperationsInput>;
  resting_after_skipped_CC?: InputMaybe<IntFieldUpdateOperationsInput>;
  resting_period?: InputMaybe<IntFieldUpdateOperationsInput>;
  resting_period_after_unsuccessful_cold_call?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  resting_period_after_unsuccessful_demo?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  revenue_plan?: InputMaybe<RevenuePlanItemUpdateManyWithoutOrganizationInput>;
  Routings?: InputMaybe<RoutingUpdateManyWithoutOrganizationInput>;
  sale_field_options?: InputMaybe<SaleFieldOptionUpdateManyWithoutOrganizationInput>;
  sale_notification_email_list?: InputMaybe<OrganizationUpdatesale_Notification_Email_ListInput>;
  SaleConfig?: InputMaybe<SaleConfigUpdateOneWithoutOrganizationInput>;
  SaleConfigPage?: InputMaybe<SaleConfigPageUpdateManyWithoutOrganizationInput>;
  SaleConfigSection?: InputMaybe<SaleConfigSectionUpdateManyWithoutOrganizationInput>;
  SaleCycles?: InputMaybe<SaleCycleUpdateManyWithoutOrganizationInput>;
  SaleItems?: InputMaybe<SaleItemUpdateManyWithoutOrganizationInput>;
  sales?: InputMaybe<SaleUpdateManyWithoutOrganizationInput>;
  salesforce_domain?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  salesforce_last_sync_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  SalesForceColumn?: InputMaybe<SalesForceColumnUpdateManyWithoutOrganizationInput>;
  salesForceData?: InputMaybe<SalesForceDataUpdateOneWithoutOrganizationInput>;
  ScheduleItems?: InputMaybe<ScheduleItemUpdateManyWithoutOrganizationInput>;
  self_sourcing_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  send_error_log_email?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  send_lead_routing_email?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sendgrid_email_domain?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sendgrid_email_domain_id?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  sequence?: InputMaybe<SequenceUpdateManyWithoutOrganzationInput>;
  SequenceCriteriaConditions?: InputMaybe<SequenceCriteriaConditionUpdateManyWithoutOrganizationInput>;
  SequenceDashboardViews?: InputMaybe<SequenceDashboardViewUpdateManyWithoutOrganizationInput>;
  SequenceEntryCriterias?: InputMaybe<SequenceEntryCriteriaUpdateManyWithoutOrganizationInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutOrganizationInput>;
  SequenceSteps?: InputMaybe<SequenceStepUpdateManyWithoutOrganizationInput>;
  set_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
  short_resting?: InputMaybe<IntFieldUpdateOperationsInput>;
  show_calendar_options?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_contract_duration_sales?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_lead_source_to_rep?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_nocontact_button?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_payment_terms_sales?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_rep_all_leads?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_rep_my_leads?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_system_view?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sites?: InputMaybe<SiteUpdateManyWithoutOrganizationInput>;
  sms_opt_in_dialog?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SMSItems?: InputMaybe<SmsItemUpdateManyWithoutOrganizationInput>;
  SnoozeTrackings?: InputMaybe<SnoozeTrackingUpdateManyWithoutOrganizationInput>;
  StepActionLogs?: InputMaybe<StepActionLogUpdateManyWithoutOrganizationInput>;
  StepActions?: InputMaybe<StepActionUpdateManyWithoutOrganizationInput>;
  teams?: InputMaybe<TeamUpdateManyWithoutOrganizationInput>;
  Template?: InputMaybe<TemplateUpdateManyWithoutOrganizationInput>;
  TemplateVersions?: InputMaybe<TemplateVersionUpdateManyWithoutOrganizationInput>;
  timekit_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timezone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transfer_timeout?: InputMaybe<IntFieldUpdateOperationsInput>;
  TransferAttempts?: InputMaybe<TransferAttemptUpdateManyWithoutOrganizationInput>;
  twilio_numbers?: InputMaybe<TwilioNumberUpdateManyWithoutOrganizationInput>;
  TwilioFeedbacks?: InputMaybe<TwilioFeedbackUpdateManyWithoutOrganizationInput>;
  TwilioInfo?: InputMaybe<TwilioInfoUpdateOneWithoutOrganzationInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  use_revenue_planning?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  UserCustomFieldFilters?: InputMaybe<UserCustomFieldFilterUpdateManyWithoutOrganizationInput>;
  users?: InputMaybe<UserUpdateManyWithoutOrganizationInput>;
  win_rep_day_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
};

export type OrganizationUpdateWithoutTeamsInput = {
  ActivityMonitorMultiplier?: InputMaybe<ActivityMonitorMultiplierUpdateManyWithoutOrganizationInput>;
  algoFactor?: InputMaybe<AlgoFactorUpdateManyWithoutOrganizationInput>;
  allow_calendar_invite?: InputMaybe<BoolFieldUpdateOperationsInput>;
  allow_remove_step_action?: InputMaybe<BoolFieldUpdateOperationsInput>;
  allow_snooze_step_action?: InputMaybe<BoolFieldUpdateOperationsInput>;
  always_record?: InputMaybe<BoolFieldUpdateOperationsInput>;
  arv_initial_held_demos_generated_by_sdr_weight?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  AssociationHistories?: InputMaybe<AssociationHistoryUpdateManyWithoutOrganizationInput>;
  auto_call_interval?: InputMaybe<IntFieldUpdateOperationsInput>;
  auto_dial?: InputMaybe<BoolFieldUpdateOperationsInput>;
  automated_campaigns?: InputMaybe<AutomatedCampaignUpdateManyWithoutOrganizationInput>;
  automatic_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  AutoTransferSelections?: InputMaybe<AutoTransferSelectionUpdateManyWithoutOrganizationInput>;
  Availablities?: InputMaybe<AvailabilityUpdateManyWithoutOrganizationInput>;
  AverageOrderValue?: InputMaybe<AverageOrderValueUpdateManyWithoutOrganizationInput>;
  best_action_ever_multiplier?: InputMaybe<FloatFieldUpdateOperationsInput>;
  best_metrics?: InputMaybe<BestMetricRecordUpdateManyWithoutOrganizationInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptUpdateManyWithoutOrganizationInput>;
  callMeNows?: InputMaybe<CallMeNowUpdateManyWithoutOrganizationInput>;
  CallMultiplier?: InputMaybe<CallMultiplierUpdateManyWithoutOrganizationInput>;
  CallSharings?: InputMaybe<CallSharingUpdateManyWithoutOrganizationInput>;
  can_edit_sdr_transfer_rules?: InputMaybe<BoolFieldUpdateOperationsInput>;
  claim_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  concession?: InputMaybe<ConcessionUpdateManyWithoutOrganizationInput>;
  conference_region?: InputMaybe<EnumConferenceRegionFieldUpdateOperationsInput>;
  Conferences?: InputMaybe<ConferenceUpdateManyWithoutOrganizationInput>;
  ContactRequests?: InputMaybe<ContactRequestUpdateManyWithoutOrganizationInput>;
  Contacts?: InputMaybe<ContactUpdateManyWithoutOrganizationInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  custom_fields?: InputMaybe<CustomFieldUpdateManyWithoutOrganizationInput>;
  CustomObject?: InputMaybe<CustomObjectUpdateManyWithoutOrganizationInput>;
  CustomObjectField?: InputMaybe<CustomObjectFieldUpdateManyWithoutOrganizationInput>;
  CustomObjectFieldForeignKey?: InputMaybe<CustomObjectFieldForeignKeyUpdateManyWithoutOrganizationInput>;
  CustomObjectRecord?: InputMaybe<CustomObjectRecordUpdateManyWithoutOrganizationInput>;
  CustomObjectRow?: InputMaybe<CustomObjectRowUpdateManyWithoutOrganizationInput>;
  CustomQueueItems?: InputMaybe<CustomQueueItemUpdateManyWithoutOrganizationInput>;
  DashboardColumnOptions?: InputMaybe<DashboardColumnOptionUpdateManyWithoutOrganizationInput>;
  DashboardLeadFilters?: InputMaybe<DashboardLeadFilterUpdateManyWithoutOrganizationInput>;
  DashboardRepFilters?: InputMaybe<DashboardRepFilterUpdateManyWithoutOrganizationInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewUpdateManyWithoutOrganizationInput>;
  day_to_close?: InputMaybe<IntFieldUpdateOperationsInput>;
  default_demo_time_duration?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  demo_held_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_not_held_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_not_held_call_back_short_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_prep_time_minutes?: InputMaybe<IntFieldUpdateOperationsInput>;
  dev_account?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  dial_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  DispositionMultiplier?: InputMaybe<DispositionMultiplierUpdateManyWithoutOrganizationInput>;
  dispositions?: InputMaybe<DispositionUpdateManyWithoutOrganizationInput>;
  double_booking?: InputMaybe<BoolFieldUpdateOperationsInput>;
  dynamic_date_operators?: InputMaybe<BoolFieldUpdateOperationsInput>;
  edge_servers?: InputMaybe<OrganizationUpdateedge_ServersInput>;
  email_conflict_notification_email_list?: InputMaybe<OrganizationUpdateemail_Conflict_Notification_Email_ListInput>;
  EmailItems?: InputMaybe<EmailItemUpdateManyWithoutOrganizationInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordUpdateManyWithoutOrganizationInput>;
  EmailThreads?: InputMaybe<EmailThreadUpdateManyWithoutOrganizationInput>;
  enable_modified_metrics?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  enable_pay_period_view?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  enable_time_constraint?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  error_log_email_address?: InputMaybe<OrganizationUpdateerror_Log_Email_AddressInput>;
  estimated_average_num_of_weekly_overbooked_demo_slots?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  FolderPermissions?: InputMaybe<FolderPermissionUpdateManyWithoutOrganizationInput>;
  Folders?: InputMaybe<FolderUpdateManyWithoutOrganizationInput>;
  FutureBookingSetting?: InputMaybe<FutureBookingSettingUpdateOneWithoutOrganizationInput>;
  GlobalTransferRule?: InputMaybe<GlobalTransferRuleUpdateManyWithoutOrganizationInput>;
  held_demos_per_cc_connect?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  helpful_links?: InputMaybe<OrganizationUpdatehelpful_LinksInput>;
  HingePointMultiplier?: InputMaybe<HingePointMultiplierUpdateManyWithoutOrganizationInput>;
  hold_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
  hubspot_credential?: InputMaybe<HubSpotCredentialUpdateOneWithoutOrganizationInput>;
  hubspot_last_sync_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  hubspot_white_list?: InputMaybe<HubSpotWhitelistUpdateManyWithoutOrganizationInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inbound_close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_dm_connect_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_lt_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_revenue_value_of_sale?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_scheduled_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_set_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventUpdateManyWithoutOrganizationInput>;
  InboundConciergeWidget?: InputMaybe<InboundConciergeWidgetUpdateManyWithoutOrganizationInput>;
  industry_options?: InputMaybe<IndustryOptionUpdateManyWithoutOrganizationInput>;
  initial_sdr_sets_reps_day?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  integration_error_logs?: InputMaybe<IntegrationErrorLogUpdateManyWithoutOrganizationInput>;
  integration_mappings?: InputMaybe<IntegrationMappingUpdateManyWithoutOrganizationInput>;
  IntegrationMappingCustomObject?: InputMaybe<IntegrationMappingCustomObjectUpdateManyWithoutOrganizationInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogUpdateManyWithoutOrganizationInput>;
  IntegrationReadOnlyObject?: InputMaybe<IntegrationReadOnlyObjectUpdateManyWithoutOrganizationInput>;
  international?: InputMaybe<BoolFieldUpdateOperationsInput>;
  is_email_domain_verified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  is_salesforce_sandbox?: InputMaybe<BoolFieldUpdateOperationsInput>;
  last_lead_value_compute_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutOrganizationInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryUpdateManyWithoutOrganizationInput>;
  lead_intent?: InputMaybe<LeadIntentUpdateManyWithoutOrganizationInput>;
  lead_release_same_rep_delay_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  lead_routing_email_list?: InputMaybe<OrganizationUpdatelead_Routing_Email_ListInput>;
  lead_routing_rules?: InputMaybe<RuleUpdateManyWithoutOrganizationInput>;
  LeadCustomObjectRowAssociation?: InputMaybe<LeadCustomObjectRowAssociationUpdateManyWithoutOrganizationInput>;
  LeadFieldMultiplier?: InputMaybe<LeadFieldMultiplierUpdateManyWithoutOrganizationInput>;
  LeadFieldMultiplierCustom?: InputMaybe<LeadFieldMultiplierCustomUpdateManyWithoutOrganizationInput>;
  leads?: InputMaybe<LeadUpdateManyWithoutOrganizationInput>;
  leadsource_options?: InputMaybe<LeadSourceOptionUpdateManyWithoutOrganizationInput>;
  LeadValue?: InputMaybe<LeadValueUpdateManyWithoutOrganizationInput>;
  LifeTimeValueMultiplier?: InputMaybe<LifeTimeValueMultiplierUpdateManyWithoutOrganizationInput>;
  LiveTransferSetting?: InputMaybe<LiveTransferSettingUpdateOneWithoutOrganizationInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusUpdateManyWithoutOrganizationInput>;
  ltv_multiplier_custom_fields?: InputMaybe<LifeTimeValueMultiplierCustomUpdateManyWithoutOrganizationInput>;
  made_contact_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  make_sale_address_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_business_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_city_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_country_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_first_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_industry_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_last_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_lead_source_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_primary_email_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_primary_phone_number_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_state_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_sub_industry_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_zip_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  maxium_day_lag_set_to_schedule?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  mrr?: InputMaybe<IntFieldUpdateOperationsInput>;
  mrr_label?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  mrr_required_on_sale?: InputMaybe<BoolFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  NoteItems?: InputMaybe<NoteItemUpdateManyWithoutOrganizationInput>;
  Notifications?: InputMaybe<NotificationUpdateManyWithoutOrganizationInput>;
  onboardComplete?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  Opportunity?: InputMaybe<SfOpportunityUpdateManyWithoutOrganizationInput>;
  organization_holidays?: InputMaybe<OrganizationHolidayUpdateManyWithoutOrganizationInput>;
  organization_hours?: InputMaybe<OrganizationHourUpdateManyWithoutOrganizationInput>;
  OrganizationPaymentSetting?: InputMaybe<OrganizationPaymentSettingsUpdateOneWithoutOrganizationInput>;
  OrganizationPaymentSettings?: InputMaybe<OrganizationPaymentSettingsUpdateOneWithoutOrganizationsInput>;
  orgCalendarInviteMetadata?: InputMaybe<OrgCalendarInviteMetadataUpdateManyWithoutOrganizationInput>;
  OrgDefaultPipelinePerecentValue?: InputMaybe<OrgDefaultPipelinePerecentValueUpdateOneWithoutOrganizationInput>;
  OrgHubSpotSync?: InputMaybe<OrgHubSpotSyncUpdateOneWithoutOrganizationInput>;
  OrgNoEventDate?: InputMaybe<OrgNoEventDateUpdateManyWithoutOrganizationInput>;
  OrgSalesforceSync?: InputMaybe<OrgSalesforceSyncUpdateOneWithoutOrganizationInput>;
  OrgTimeslotAvailability?: InputMaybe<OrgTimeslotAvailabilityUpdateManyWithoutOrganizationInput>;
  outbound_close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_dm_connect_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_lt_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_revenue_value_of_sale?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_scheduled_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_set_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  PandadocData?: InputMaybe<PandadocDataUpdateOneWithoutOrganizationInput>;
  PandaDocTemplate?: InputMaybe<PandaDocTemplateUpdateManyWithoutOrganizationInput>;
  participant_jitter_buffer_size?: InputMaybe<EnumJitterBufferSizeFieldUpdateOperationsInput>;
  preferred_goal_setting?: InputMaybe<NullableEnumGoalsettingFieldUpdateOperationsInput>;
  prepayment?: InputMaybe<BoolFieldUpdateOperationsInput>;
  ProductChangeLog?: InputMaybe<ProductChangeLogUpdateManyWithoutOrganizationInput>;
  products?: InputMaybe<ProductUpdateManyWithoutOrganizationInput>;
  re_cold_call_hour_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  recordingStatus?: InputMaybe<EnumRecordingStatusFieldUpdateOperationsInput>;
  release_lead_resting?: InputMaybe<IntFieldUpdateOperationsInput>;
  rep_claim_limit?: InputMaybe<IntFieldUpdateOperationsInput>;
  rep_lead_allotment?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  rep_level_call_transfer?: InputMaybe<BoolFieldUpdateOperationsInput>;
  reps_can_bulk_unassign?: InputMaybe<BoolFieldUpdateOperationsInput>;
  reps_can_view_all_notes?: InputMaybe<BoolFieldUpdateOperationsInput>;
  require_sale_notes?: InputMaybe<BoolFieldUpdateOperationsInput>;
  resting_after_skipped_CC?: InputMaybe<IntFieldUpdateOperationsInput>;
  resting_period?: InputMaybe<IntFieldUpdateOperationsInput>;
  resting_period_after_unsuccessful_cold_call?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  resting_period_after_unsuccessful_demo?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  revenue_plan?: InputMaybe<RevenuePlanItemUpdateManyWithoutOrganizationInput>;
  Routings?: InputMaybe<RoutingUpdateManyWithoutOrganizationInput>;
  sale_field_options?: InputMaybe<SaleFieldOptionUpdateManyWithoutOrganizationInput>;
  sale_notification_email_list?: InputMaybe<OrganizationUpdatesale_Notification_Email_ListInput>;
  SaleConfig?: InputMaybe<SaleConfigUpdateOneWithoutOrganizationInput>;
  SaleConfigPage?: InputMaybe<SaleConfigPageUpdateManyWithoutOrganizationInput>;
  SaleConfigSection?: InputMaybe<SaleConfigSectionUpdateManyWithoutOrganizationInput>;
  SaleCycles?: InputMaybe<SaleCycleUpdateManyWithoutOrganizationInput>;
  SaleItems?: InputMaybe<SaleItemUpdateManyWithoutOrganizationInput>;
  sales?: InputMaybe<SaleUpdateManyWithoutOrganizationInput>;
  salesforce_domain?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  salesforce_last_sync_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  SalesForceColumn?: InputMaybe<SalesForceColumnUpdateManyWithoutOrganizationInput>;
  salesForceData?: InputMaybe<SalesForceDataUpdateOneWithoutOrganizationInput>;
  ScheduleItems?: InputMaybe<ScheduleItemUpdateManyWithoutOrganizationInput>;
  self_sourcing_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  send_error_log_email?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  send_lead_routing_email?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sendgrid_email_domain?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sendgrid_email_domain_id?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  sequence?: InputMaybe<SequenceUpdateManyWithoutOrganzationInput>;
  SequenceCriteriaConditions?: InputMaybe<SequenceCriteriaConditionUpdateManyWithoutOrganizationInput>;
  SequenceDashboardViews?: InputMaybe<SequenceDashboardViewUpdateManyWithoutOrganizationInput>;
  SequenceEntryCriterias?: InputMaybe<SequenceEntryCriteriaUpdateManyWithoutOrganizationInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutOrganizationInput>;
  SequenceSteps?: InputMaybe<SequenceStepUpdateManyWithoutOrganizationInput>;
  set_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
  short_resting?: InputMaybe<IntFieldUpdateOperationsInput>;
  show_calendar_options?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_contract_duration_sales?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_lead_source_to_rep?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_nocontact_button?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_payment_terms_sales?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_rep_all_leads?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_rep_my_leads?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_system_view?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sites?: InputMaybe<SiteUpdateManyWithoutOrganizationInput>;
  sms_opt_in_dialog?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SMSItems?: InputMaybe<SmsItemUpdateManyWithoutOrganizationInput>;
  SnoozeTrackings?: InputMaybe<SnoozeTrackingUpdateManyWithoutOrganizationInput>;
  StepActionLogs?: InputMaybe<StepActionLogUpdateManyWithoutOrganizationInput>;
  StepActions?: InputMaybe<StepActionUpdateManyWithoutOrganizationInput>;
  StepConditions?: InputMaybe<StepConditionUpdateManyWithoutOrganizationInput>;
  Template?: InputMaybe<TemplateUpdateManyWithoutOrganizationInput>;
  TemplateVersions?: InputMaybe<TemplateVersionUpdateManyWithoutOrganizationInput>;
  timekit_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timezone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transfer_timeout?: InputMaybe<IntFieldUpdateOperationsInput>;
  TransferAttempts?: InputMaybe<TransferAttemptUpdateManyWithoutOrganizationInput>;
  twilio_numbers?: InputMaybe<TwilioNumberUpdateManyWithoutOrganizationInput>;
  TwilioFeedbacks?: InputMaybe<TwilioFeedbackUpdateManyWithoutOrganizationInput>;
  TwilioInfo?: InputMaybe<TwilioInfoUpdateOneWithoutOrganzationInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  use_revenue_planning?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  UserCustomFieldFilters?: InputMaybe<UserCustomFieldFilterUpdateManyWithoutOrganizationInput>;
  users?: InputMaybe<UserUpdateManyWithoutOrganizationInput>;
  win_rep_day_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
};

export type OrganizationUpdateWithoutTemplateInput = {
  ActivityMonitorMultiplier?: InputMaybe<ActivityMonitorMultiplierUpdateManyWithoutOrganizationInput>;
  algoFactor?: InputMaybe<AlgoFactorUpdateManyWithoutOrganizationInput>;
  allow_calendar_invite?: InputMaybe<BoolFieldUpdateOperationsInput>;
  allow_remove_step_action?: InputMaybe<BoolFieldUpdateOperationsInput>;
  allow_snooze_step_action?: InputMaybe<BoolFieldUpdateOperationsInput>;
  always_record?: InputMaybe<BoolFieldUpdateOperationsInput>;
  arv_initial_held_demos_generated_by_sdr_weight?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  AssociationHistories?: InputMaybe<AssociationHistoryUpdateManyWithoutOrganizationInput>;
  auto_call_interval?: InputMaybe<IntFieldUpdateOperationsInput>;
  auto_dial?: InputMaybe<BoolFieldUpdateOperationsInput>;
  automated_campaigns?: InputMaybe<AutomatedCampaignUpdateManyWithoutOrganizationInput>;
  automatic_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  AutoTransferSelections?: InputMaybe<AutoTransferSelectionUpdateManyWithoutOrganizationInput>;
  Availablities?: InputMaybe<AvailabilityUpdateManyWithoutOrganizationInput>;
  AverageOrderValue?: InputMaybe<AverageOrderValueUpdateManyWithoutOrganizationInput>;
  best_action_ever_multiplier?: InputMaybe<FloatFieldUpdateOperationsInput>;
  best_metrics?: InputMaybe<BestMetricRecordUpdateManyWithoutOrganizationInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptUpdateManyWithoutOrganizationInput>;
  callMeNows?: InputMaybe<CallMeNowUpdateManyWithoutOrganizationInput>;
  CallMultiplier?: InputMaybe<CallMultiplierUpdateManyWithoutOrganizationInput>;
  CallSharings?: InputMaybe<CallSharingUpdateManyWithoutOrganizationInput>;
  can_edit_sdr_transfer_rules?: InputMaybe<BoolFieldUpdateOperationsInput>;
  claim_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  concession?: InputMaybe<ConcessionUpdateManyWithoutOrganizationInput>;
  conference_region?: InputMaybe<EnumConferenceRegionFieldUpdateOperationsInput>;
  Conferences?: InputMaybe<ConferenceUpdateManyWithoutOrganizationInput>;
  ContactRequests?: InputMaybe<ContactRequestUpdateManyWithoutOrganizationInput>;
  Contacts?: InputMaybe<ContactUpdateManyWithoutOrganizationInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  custom_fields?: InputMaybe<CustomFieldUpdateManyWithoutOrganizationInput>;
  CustomObject?: InputMaybe<CustomObjectUpdateManyWithoutOrganizationInput>;
  CustomObjectField?: InputMaybe<CustomObjectFieldUpdateManyWithoutOrganizationInput>;
  CustomObjectFieldForeignKey?: InputMaybe<CustomObjectFieldForeignKeyUpdateManyWithoutOrganizationInput>;
  CustomObjectRecord?: InputMaybe<CustomObjectRecordUpdateManyWithoutOrganizationInput>;
  CustomObjectRow?: InputMaybe<CustomObjectRowUpdateManyWithoutOrganizationInput>;
  CustomQueueItems?: InputMaybe<CustomQueueItemUpdateManyWithoutOrganizationInput>;
  DashboardColumnOptions?: InputMaybe<DashboardColumnOptionUpdateManyWithoutOrganizationInput>;
  DashboardLeadFilters?: InputMaybe<DashboardLeadFilterUpdateManyWithoutOrganizationInput>;
  DashboardRepFilters?: InputMaybe<DashboardRepFilterUpdateManyWithoutOrganizationInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewUpdateManyWithoutOrganizationInput>;
  day_to_close?: InputMaybe<IntFieldUpdateOperationsInput>;
  default_demo_time_duration?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  demo_held_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_not_held_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_not_held_call_back_short_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_prep_time_minutes?: InputMaybe<IntFieldUpdateOperationsInput>;
  dev_account?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  dial_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  DispositionMultiplier?: InputMaybe<DispositionMultiplierUpdateManyWithoutOrganizationInput>;
  dispositions?: InputMaybe<DispositionUpdateManyWithoutOrganizationInput>;
  double_booking?: InputMaybe<BoolFieldUpdateOperationsInput>;
  dynamic_date_operators?: InputMaybe<BoolFieldUpdateOperationsInput>;
  edge_servers?: InputMaybe<OrganizationUpdateedge_ServersInput>;
  email_conflict_notification_email_list?: InputMaybe<OrganizationUpdateemail_Conflict_Notification_Email_ListInput>;
  EmailItems?: InputMaybe<EmailItemUpdateManyWithoutOrganizationInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordUpdateManyWithoutOrganizationInput>;
  EmailThreads?: InputMaybe<EmailThreadUpdateManyWithoutOrganizationInput>;
  enable_modified_metrics?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  enable_pay_period_view?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  enable_time_constraint?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  error_log_email_address?: InputMaybe<OrganizationUpdateerror_Log_Email_AddressInput>;
  estimated_average_num_of_weekly_overbooked_demo_slots?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  FolderPermissions?: InputMaybe<FolderPermissionUpdateManyWithoutOrganizationInput>;
  Folders?: InputMaybe<FolderUpdateManyWithoutOrganizationInput>;
  FutureBookingSetting?: InputMaybe<FutureBookingSettingUpdateOneWithoutOrganizationInput>;
  GlobalTransferRule?: InputMaybe<GlobalTransferRuleUpdateManyWithoutOrganizationInput>;
  held_demos_per_cc_connect?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  helpful_links?: InputMaybe<OrganizationUpdatehelpful_LinksInput>;
  HingePointMultiplier?: InputMaybe<HingePointMultiplierUpdateManyWithoutOrganizationInput>;
  hold_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
  hubspot_credential?: InputMaybe<HubSpotCredentialUpdateOneWithoutOrganizationInput>;
  hubspot_last_sync_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  hubspot_white_list?: InputMaybe<HubSpotWhitelistUpdateManyWithoutOrganizationInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inbound_close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_dm_connect_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_lt_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_revenue_value_of_sale?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_scheduled_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_set_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventUpdateManyWithoutOrganizationInput>;
  InboundConciergeWidget?: InputMaybe<InboundConciergeWidgetUpdateManyWithoutOrganizationInput>;
  industry_options?: InputMaybe<IndustryOptionUpdateManyWithoutOrganizationInput>;
  initial_sdr_sets_reps_day?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  integration_error_logs?: InputMaybe<IntegrationErrorLogUpdateManyWithoutOrganizationInput>;
  integration_mappings?: InputMaybe<IntegrationMappingUpdateManyWithoutOrganizationInput>;
  IntegrationMappingCustomObject?: InputMaybe<IntegrationMappingCustomObjectUpdateManyWithoutOrganizationInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogUpdateManyWithoutOrganizationInput>;
  IntegrationReadOnlyObject?: InputMaybe<IntegrationReadOnlyObjectUpdateManyWithoutOrganizationInput>;
  international?: InputMaybe<BoolFieldUpdateOperationsInput>;
  is_email_domain_verified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  is_salesforce_sandbox?: InputMaybe<BoolFieldUpdateOperationsInput>;
  last_lead_value_compute_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutOrganizationInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryUpdateManyWithoutOrganizationInput>;
  lead_intent?: InputMaybe<LeadIntentUpdateManyWithoutOrganizationInput>;
  lead_release_same_rep_delay_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  lead_routing_email_list?: InputMaybe<OrganizationUpdatelead_Routing_Email_ListInput>;
  lead_routing_rules?: InputMaybe<RuleUpdateManyWithoutOrganizationInput>;
  LeadCustomObjectRowAssociation?: InputMaybe<LeadCustomObjectRowAssociationUpdateManyWithoutOrganizationInput>;
  LeadFieldMultiplier?: InputMaybe<LeadFieldMultiplierUpdateManyWithoutOrganizationInput>;
  LeadFieldMultiplierCustom?: InputMaybe<LeadFieldMultiplierCustomUpdateManyWithoutOrganizationInput>;
  leads?: InputMaybe<LeadUpdateManyWithoutOrganizationInput>;
  leadsource_options?: InputMaybe<LeadSourceOptionUpdateManyWithoutOrganizationInput>;
  LeadValue?: InputMaybe<LeadValueUpdateManyWithoutOrganizationInput>;
  LifeTimeValueMultiplier?: InputMaybe<LifeTimeValueMultiplierUpdateManyWithoutOrganizationInput>;
  LiveTransferSetting?: InputMaybe<LiveTransferSettingUpdateOneWithoutOrganizationInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusUpdateManyWithoutOrganizationInput>;
  ltv_multiplier_custom_fields?: InputMaybe<LifeTimeValueMultiplierCustomUpdateManyWithoutOrganizationInput>;
  made_contact_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  make_sale_address_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_business_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_city_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_country_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_first_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_industry_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_last_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_lead_source_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_primary_email_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_primary_phone_number_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_state_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_sub_industry_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_zip_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  maxium_day_lag_set_to_schedule?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  mrr?: InputMaybe<IntFieldUpdateOperationsInput>;
  mrr_label?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  mrr_required_on_sale?: InputMaybe<BoolFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  NoteItems?: InputMaybe<NoteItemUpdateManyWithoutOrganizationInput>;
  Notifications?: InputMaybe<NotificationUpdateManyWithoutOrganizationInput>;
  onboardComplete?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  Opportunity?: InputMaybe<SfOpportunityUpdateManyWithoutOrganizationInput>;
  organization_holidays?: InputMaybe<OrganizationHolidayUpdateManyWithoutOrganizationInput>;
  organization_hours?: InputMaybe<OrganizationHourUpdateManyWithoutOrganizationInput>;
  OrganizationPaymentSetting?: InputMaybe<OrganizationPaymentSettingsUpdateOneWithoutOrganizationInput>;
  OrganizationPaymentSettings?: InputMaybe<OrganizationPaymentSettingsUpdateOneWithoutOrganizationsInput>;
  orgCalendarInviteMetadata?: InputMaybe<OrgCalendarInviteMetadataUpdateManyWithoutOrganizationInput>;
  OrgDefaultPipelinePerecentValue?: InputMaybe<OrgDefaultPipelinePerecentValueUpdateOneWithoutOrganizationInput>;
  OrgHubSpotSync?: InputMaybe<OrgHubSpotSyncUpdateOneWithoutOrganizationInput>;
  OrgNoEventDate?: InputMaybe<OrgNoEventDateUpdateManyWithoutOrganizationInput>;
  OrgSalesforceSync?: InputMaybe<OrgSalesforceSyncUpdateOneWithoutOrganizationInput>;
  OrgTimeslotAvailability?: InputMaybe<OrgTimeslotAvailabilityUpdateManyWithoutOrganizationInput>;
  outbound_close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_dm_connect_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_lt_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_revenue_value_of_sale?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_scheduled_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_set_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  PandadocData?: InputMaybe<PandadocDataUpdateOneWithoutOrganizationInput>;
  PandaDocTemplate?: InputMaybe<PandaDocTemplateUpdateManyWithoutOrganizationInput>;
  participant_jitter_buffer_size?: InputMaybe<EnumJitterBufferSizeFieldUpdateOperationsInput>;
  preferred_goal_setting?: InputMaybe<NullableEnumGoalsettingFieldUpdateOperationsInput>;
  prepayment?: InputMaybe<BoolFieldUpdateOperationsInput>;
  ProductChangeLog?: InputMaybe<ProductChangeLogUpdateManyWithoutOrganizationInput>;
  products?: InputMaybe<ProductUpdateManyWithoutOrganizationInput>;
  re_cold_call_hour_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  recordingStatus?: InputMaybe<EnumRecordingStatusFieldUpdateOperationsInput>;
  release_lead_resting?: InputMaybe<IntFieldUpdateOperationsInput>;
  rep_claim_limit?: InputMaybe<IntFieldUpdateOperationsInput>;
  rep_lead_allotment?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  rep_level_call_transfer?: InputMaybe<BoolFieldUpdateOperationsInput>;
  reps_can_bulk_unassign?: InputMaybe<BoolFieldUpdateOperationsInput>;
  reps_can_view_all_notes?: InputMaybe<BoolFieldUpdateOperationsInput>;
  require_sale_notes?: InputMaybe<BoolFieldUpdateOperationsInput>;
  resting_after_skipped_CC?: InputMaybe<IntFieldUpdateOperationsInput>;
  resting_period?: InputMaybe<IntFieldUpdateOperationsInput>;
  resting_period_after_unsuccessful_cold_call?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  resting_period_after_unsuccessful_demo?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  revenue_plan?: InputMaybe<RevenuePlanItemUpdateManyWithoutOrganizationInput>;
  Routings?: InputMaybe<RoutingUpdateManyWithoutOrganizationInput>;
  sale_field_options?: InputMaybe<SaleFieldOptionUpdateManyWithoutOrganizationInput>;
  sale_notification_email_list?: InputMaybe<OrganizationUpdatesale_Notification_Email_ListInput>;
  SaleConfig?: InputMaybe<SaleConfigUpdateOneWithoutOrganizationInput>;
  SaleConfigPage?: InputMaybe<SaleConfigPageUpdateManyWithoutOrganizationInput>;
  SaleConfigSection?: InputMaybe<SaleConfigSectionUpdateManyWithoutOrganizationInput>;
  SaleCycles?: InputMaybe<SaleCycleUpdateManyWithoutOrganizationInput>;
  SaleItems?: InputMaybe<SaleItemUpdateManyWithoutOrganizationInput>;
  sales?: InputMaybe<SaleUpdateManyWithoutOrganizationInput>;
  salesforce_domain?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  salesforce_last_sync_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  SalesForceColumn?: InputMaybe<SalesForceColumnUpdateManyWithoutOrganizationInput>;
  salesForceData?: InputMaybe<SalesForceDataUpdateOneWithoutOrganizationInput>;
  ScheduleItems?: InputMaybe<ScheduleItemUpdateManyWithoutOrganizationInput>;
  self_sourcing_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  send_error_log_email?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  send_lead_routing_email?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sendgrid_email_domain?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sendgrid_email_domain_id?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  sequence?: InputMaybe<SequenceUpdateManyWithoutOrganzationInput>;
  SequenceCriteriaConditions?: InputMaybe<SequenceCriteriaConditionUpdateManyWithoutOrganizationInput>;
  SequenceDashboardViews?: InputMaybe<SequenceDashboardViewUpdateManyWithoutOrganizationInput>;
  SequenceEntryCriterias?: InputMaybe<SequenceEntryCriteriaUpdateManyWithoutOrganizationInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutOrganizationInput>;
  SequenceSteps?: InputMaybe<SequenceStepUpdateManyWithoutOrganizationInput>;
  set_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
  short_resting?: InputMaybe<IntFieldUpdateOperationsInput>;
  show_calendar_options?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_contract_duration_sales?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_lead_source_to_rep?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_nocontact_button?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_payment_terms_sales?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_rep_all_leads?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_rep_my_leads?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_system_view?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sites?: InputMaybe<SiteUpdateManyWithoutOrganizationInput>;
  sms_opt_in_dialog?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SMSItems?: InputMaybe<SmsItemUpdateManyWithoutOrganizationInput>;
  SnoozeTrackings?: InputMaybe<SnoozeTrackingUpdateManyWithoutOrganizationInput>;
  StepActionLogs?: InputMaybe<StepActionLogUpdateManyWithoutOrganizationInput>;
  StepActions?: InputMaybe<StepActionUpdateManyWithoutOrganizationInput>;
  StepConditions?: InputMaybe<StepConditionUpdateManyWithoutOrganizationInput>;
  teams?: InputMaybe<TeamUpdateManyWithoutOrganizationInput>;
  TemplateVersions?: InputMaybe<TemplateVersionUpdateManyWithoutOrganizationInput>;
  timekit_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timezone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transfer_timeout?: InputMaybe<IntFieldUpdateOperationsInput>;
  TransferAttempts?: InputMaybe<TransferAttemptUpdateManyWithoutOrganizationInput>;
  twilio_numbers?: InputMaybe<TwilioNumberUpdateManyWithoutOrganizationInput>;
  TwilioFeedbacks?: InputMaybe<TwilioFeedbackUpdateManyWithoutOrganizationInput>;
  TwilioInfo?: InputMaybe<TwilioInfoUpdateOneWithoutOrganzationInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  use_revenue_planning?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  UserCustomFieldFilters?: InputMaybe<UserCustomFieldFilterUpdateManyWithoutOrganizationInput>;
  users?: InputMaybe<UserUpdateManyWithoutOrganizationInput>;
  win_rep_day_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
};

export type OrganizationUpdateWithoutTemplateVersionsInput = {
  ActivityMonitorMultiplier?: InputMaybe<ActivityMonitorMultiplierUpdateManyWithoutOrganizationInput>;
  algoFactor?: InputMaybe<AlgoFactorUpdateManyWithoutOrganizationInput>;
  allow_calendar_invite?: InputMaybe<BoolFieldUpdateOperationsInput>;
  allow_remove_step_action?: InputMaybe<BoolFieldUpdateOperationsInput>;
  allow_snooze_step_action?: InputMaybe<BoolFieldUpdateOperationsInput>;
  always_record?: InputMaybe<BoolFieldUpdateOperationsInput>;
  arv_initial_held_demos_generated_by_sdr_weight?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  AssociationHistories?: InputMaybe<AssociationHistoryUpdateManyWithoutOrganizationInput>;
  auto_call_interval?: InputMaybe<IntFieldUpdateOperationsInput>;
  auto_dial?: InputMaybe<BoolFieldUpdateOperationsInput>;
  automated_campaigns?: InputMaybe<AutomatedCampaignUpdateManyWithoutOrganizationInput>;
  automatic_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  AutoTransferSelections?: InputMaybe<AutoTransferSelectionUpdateManyWithoutOrganizationInput>;
  Availablities?: InputMaybe<AvailabilityUpdateManyWithoutOrganizationInput>;
  AverageOrderValue?: InputMaybe<AverageOrderValueUpdateManyWithoutOrganizationInput>;
  best_action_ever_multiplier?: InputMaybe<FloatFieldUpdateOperationsInput>;
  best_metrics?: InputMaybe<BestMetricRecordUpdateManyWithoutOrganizationInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptUpdateManyWithoutOrganizationInput>;
  callMeNows?: InputMaybe<CallMeNowUpdateManyWithoutOrganizationInput>;
  CallMultiplier?: InputMaybe<CallMultiplierUpdateManyWithoutOrganizationInput>;
  CallSharings?: InputMaybe<CallSharingUpdateManyWithoutOrganizationInput>;
  can_edit_sdr_transfer_rules?: InputMaybe<BoolFieldUpdateOperationsInput>;
  claim_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  concession?: InputMaybe<ConcessionUpdateManyWithoutOrganizationInput>;
  conference_region?: InputMaybe<EnumConferenceRegionFieldUpdateOperationsInput>;
  Conferences?: InputMaybe<ConferenceUpdateManyWithoutOrganizationInput>;
  ContactRequests?: InputMaybe<ContactRequestUpdateManyWithoutOrganizationInput>;
  Contacts?: InputMaybe<ContactUpdateManyWithoutOrganizationInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  custom_fields?: InputMaybe<CustomFieldUpdateManyWithoutOrganizationInput>;
  CustomObject?: InputMaybe<CustomObjectUpdateManyWithoutOrganizationInput>;
  CustomObjectField?: InputMaybe<CustomObjectFieldUpdateManyWithoutOrganizationInput>;
  CustomObjectFieldForeignKey?: InputMaybe<CustomObjectFieldForeignKeyUpdateManyWithoutOrganizationInput>;
  CustomObjectRecord?: InputMaybe<CustomObjectRecordUpdateManyWithoutOrganizationInput>;
  CustomObjectRow?: InputMaybe<CustomObjectRowUpdateManyWithoutOrganizationInput>;
  CustomQueueItems?: InputMaybe<CustomQueueItemUpdateManyWithoutOrganizationInput>;
  DashboardColumnOptions?: InputMaybe<DashboardColumnOptionUpdateManyWithoutOrganizationInput>;
  DashboardLeadFilters?: InputMaybe<DashboardLeadFilterUpdateManyWithoutOrganizationInput>;
  DashboardRepFilters?: InputMaybe<DashboardRepFilterUpdateManyWithoutOrganizationInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewUpdateManyWithoutOrganizationInput>;
  day_to_close?: InputMaybe<IntFieldUpdateOperationsInput>;
  default_demo_time_duration?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  demo_held_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_not_held_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_not_held_call_back_short_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_prep_time_minutes?: InputMaybe<IntFieldUpdateOperationsInput>;
  dev_account?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  dial_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  DispositionMultiplier?: InputMaybe<DispositionMultiplierUpdateManyWithoutOrganizationInput>;
  dispositions?: InputMaybe<DispositionUpdateManyWithoutOrganizationInput>;
  double_booking?: InputMaybe<BoolFieldUpdateOperationsInput>;
  dynamic_date_operators?: InputMaybe<BoolFieldUpdateOperationsInput>;
  edge_servers?: InputMaybe<OrganizationUpdateedge_ServersInput>;
  email_conflict_notification_email_list?: InputMaybe<OrganizationUpdateemail_Conflict_Notification_Email_ListInput>;
  EmailItems?: InputMaybe<EmailItemUpdateManyWithoutOrganizationInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordUpdateManyWithoutOrganizationInput>;
  EmailThreads?: InputMaybe<EmailThreadUpdateManyWithoutOrganizationInput>;
  enable_modified_metrics?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  enable_pay_period_view?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  enable_time_constraint?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  error_log_email_address?: InputMaybe<OrganizationUpdateerror_Log_Email_AddressInput>;
  estimated_average_num_of_weekly_overbooked_demo_slots?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  FolderPermissions?: InputMaybe<FolderPermissionUpdateManyWithoutOrganizationInput>;
  Folders?: InputMaybe<FolderUpdateManyWithoutOrganizationInput>;
  FutureBookingSetting?: InputMaybe<FutureBookingSettingUpdateOneWithoutOrganizationInput>;
  GlobalTransferRule?: InputMaybe<GlobalTransferRuleUpdateManyWithoutOrganizationInput>;
  held_demos_per_cc_connect?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  helpful_links?: InputMaybe<OrganizationUpdatehelpful_LinksInput>;
  HingePointMultiplier?: InputMaybe<HingePointMultiplierUpdateManyWithoutOrganizationInput>;
  hold_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
  hubspot_credential?: InputMaybe<HubSpotCredentialUpdateOneWithoutOrganizationInput>;
  hubspot_last_sync_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  hubspot_white_list?: InputMaybe<HubSpotWhitelistUpdateManyWithoutOrganizationInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inbound_close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_dm_connect_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_lt_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_revenue_value_of_sale?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_scheduled_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_set_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventUpdateManyWithoutOrganizationInput>;
  InboundConciergeWidget?: InputMaybe<InboundConciergeWidgetUpdateManyWithoutOrganizationInput>;
  industry_options?: InputMaybe<IndustryOptionUpdateManyWithoutOrganizationInput>;
  initial_sdr_sets_reps_day?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  integration_error_logs?: InputMaybe<IntegrationErrorLogUpdateManyWithoutOrganizationInput>;
  integration_mappings?: InputMaybe<IntegrationMappingUpdateManyWithoutOrganizationInput>;
  IntegrationMappingCustomObject?: InputMaybe<IntegrationMappingCustomObjectUpdateManyWithoutOrganizationInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogUpdateManyWithoutOrganizationInput>;
  IntegrationReadOnlyObject?: InputMaybe<IntegrationReadOnlyObjectUpdateManyWithoutOrganizationInput>;
  international?: InputMaybe<BoolFieldUpdateOperationsInput>;
  is_email_domain_verified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  is_salesforce_sandbox?: InputMaybe<BoolFieldUpdateOperationsInput>;
  last_lead_value_compute_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutOrganizationInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryUpdateManyWithoutOrganizationInput>;
  lead_intent?: InputMaybe<LeadIntentUpdateManyWithoutOrganizationInput>;
  lead_release_same_rep_delay_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  lead_routing_email_list?: InputMaybe<OrganizationUpdatelead_Routing_Email_ListInput>;
  lead_routing_rules?: InputMaybe<RuleUpdateManyWithoutOrganizationInput>;
  LeadCustomObjectRowAssociation?: InputMaybe<LeadCustomObjectRowAssociationUpdateManyWithoutOrganizationInput>;
  LeadFieldMultiplier?: InputMaybe<LeadFieldMultiplierUpdateManyWithoutOrganizationInput>;
  LeadFieldMultiplierCustom?: InputMaybe<LeadFieldMultiplierCustomUpdateManyWithoutOrganizationInput>;
  leads?: InputMaybe<LeadUpdateManyWithoutOrganizationInput>;
  leadsource_options?: InputMaybe<LeadSourceOptionUpdateManyWithoutOrganizationInput>;
  LeadValue?: InputMaybe<LeadValueUpdateManyWithoutOrganizationInput>;
  LifeTimeValueMultiplier?: InputMaybe<LifeTimeValueMultiplierUpdateManyWithoutOrganizationInput>;
  LiveTransferSetting?: InputMaybe<LiveTransferSettingUpdateOneWithoutOrganizationInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusUpdateManyWithoutOrganizationInput>;
  ltv_multiplier_custom_fields?: InputMaybe<LifeTimeValueMultiplierCustomUpdateManyWithoutOrganizationInput>;
  made_contact_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  make_sale_address_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_business_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_city_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_country_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_first_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_industry_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_last_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_lead_source_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_primary_email_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_primary_phone_number_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_state_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_sub_industry_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_zip_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  maxium_day_lag_set_to_schedule?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  mrr?: InputMaybe<IntFieldUpdateOperationsInput>;
  mrr_label?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  mrr_required_on_sale?: InputMaybe<BoolFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  NoteItems?: InputMaybe<NoteItemUpdateManyWithoutOrganizationInput>;
  Notifications?: InputMaybe<NotificationUpdateManyWithoutOrganizationInput>;
  onboardComplete?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  Opportunity?: InputMaybe<SfOpportunityUpdateManyWithoutOrganizationInput>;
  organization_holidays?: InputMaybe<OrganizationHolidayUpdateManyWithoutOrganizationInput>;
  organization_hours?: InputMaybe<OrganizationHourUpdateManyWithoutOrganizationInput>;
  OrganizationPaymentSetting?: InputMaybe<OrganizationPaymentSettingsUpdateOneWithoutOrganizationInput>;
  OrganizationPaymentSettings?: InputMaybe<OrganizationPaymentSettingsUpdateOneWithoutOrganizationsInput>;
  orgCalendarInviteMetadata?: InputMaybe<OrgCalendarInviteMetadataUpdateManyWithoutOrganizationInput>;
  OrgDefaultPipelinePerecentValue?: InputMaybe<OrgDefaultPipelinePerecentValueUpdateOneWithoutOrganizationInput>;
  OrgHubSpotSync?: InputMaybe<OrgHubSpotSyncUpdateOneWithoutOrganizationInput>;
  OrgNoEventDate?: InputMaybe<OrgNoEventDateUpdateManyWithoutOrganizationInput>;
  OrgSalesforceSync?: InputMaybe<OrgSalesforceSyncUpdateOneWithoutOrganizationInput>;
  OrgTimeslotAvailability?: InputMaybe<OrgTimeslotAvailabilityUpdateManyWithoutOrganizationInput>;
  outbound_close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_dm_connect_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_lt_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_revenue_value_of_sale?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_scheduled_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_set_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  PandadocData?: InputMaybe<PandadocDataUpdateOneWithoutOrganizationInput>;
  PandaDocTemplate?: InputMaybe<PandaDocTemplateUpdateManyWithoutOrganizationInput>;
  participant_jitter_buffer_size?: InputMaybe<EnumJitterBufferSizeFieldUpdateOperationsInput>;
  preferred_goal_setting?: InputMaybe<NullableEnumGoalsettingFieldUpdateOperationsInput>;
  prepayment?: InputMaybe<BoolFieldUpdateOperationsInput>;
  ProductChangeLog?: InputMaybe<ProductChangeLogUpdateManyWithoutOrganizationInput>;
  products?: InputMaybe<ProductUpdateManyWithoutOrganizationInput>;
  re_cold_call_hour_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  recordingStatus?: InputMaybe<EnumRecordingStatusFieldUpdateOperationsInput>;
  release_lead_resting?: InputMaybe<IntFieldUpdateOperationsInput>;
  rep_claim_limit?: InputMaybe<IntFieldUpdateOperationsInput>;
  rep_lead_allotment?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  rep_level_call_transfer?: InputMaybe<BoolFieldUpdateOperationsInput>;
  reps_can_bulk_unassign?: InputMaybe<BoolFieldUpdateOperationsInput>;
  reps_can_view_all_notes?: InputMaybe<BoolFieldUpdateOperationsInput>;
  require_sale_notes?: InputMaybe<BoolFieldUpdateOperationsInput>;
  resting_after_skipped_CC?: InputMaybe<IntFieldUpdateOperationsInput>;
  resting_period?: InputMaybe<IntFieldUpdateOperationsInput>;
  resting_period_after_unsuccessful_cold_call?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  resting_period_after_unsuccessful_demo?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  revenue_plan?: InputMaybe<RevenuePlanItemUpdateManyWithoutOrganizationInput>;
  Routings?: InputMaybe<RoutingUpdateManyWithoutOrganizationInput>;
  sale_field_options?: InputMaybe<SaleFieldOptionUpdateManyWithoutOrganizationInput>;
  sale_notification_email_list?: InputMaybe<OrganizationUpdatesale_Notification_Email_ListInput>;
  SaleConfig?: InputMaybe<SaleConfigUpdateOneWithoutOrganizationInput>;
  SaleConfigPage?: InputMaybe<SaleConfigPageUpdateManyWithoutOrganizationInput>;
  SaleConfigSection?: InputMaybe<SaleConfigSectionUpdateManyWithoutOrganizationInput>;
  SaleCycles?: InputMaybe<SaleCycleUpdateManyWithoutOrganizationInput>;
  SaleItems?: InputMaybe<SaleItemUpdateManyWithoutOrganizationInput>;
  sales?: InputMaybe<SaleUpdateManyWithoutOrganizationInput>;
  salesforce_domain?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  salesforce_last_sync_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  SalesForceColumn?: InputMaybe<SalesForceColumnUpdateManyWithoutOrganizationInput>;
  salesForceData?: InputMaybe<SalesForceDataUpdateOneWithoutOrganizationInput>;
  ScheduleItems?: InputMaybe<ScheduleItemUpdateManyWithoutOrganizationInput>;
  self_sourcing_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  send_error_log_email?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  send_lead_routing_email?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sendgrid_email_domain?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sendgrid_email_domain_id?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  sequence?: InputMaybe<SequenceUpdateManyWithoutOrganzationInput>;
  SequenceCriteriaConditions?: InputMaybe<SequenceCriteriaConditionUpdateManyWithoutOrganizationInput>;
  SequenceDashboardViews?: InputMaybe<SequenceDashboardViewUpdateManyWithoutOrganizationInput>;
  SequenceEntryCriterias?: InputMaybe<SequenceEntryCriteriaUpdateManyWithoutOrganizationInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutOrganizationInput>;
  SequenceSteps?: InputMaybe<SequenceStepUpdateManyWithoutOrganizationInput>;
  set_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
  short_resting?: InputMaybe<IntFieldUpdateOperationsInput>;
  show_calendar_options?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_contract_duration_sales?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_lead_source_to_rep?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_nocontact_button?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_payment_terms_sales?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_rep_all_leads?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_rep_my_leads?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_system_view?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sites?: InputMaybe<SiteUpdateManyWithoutOrganizationInput>;
  sms_opt_in_dialog?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SMSItems?: InputMaybe<SmsItemUpdateManyWithoutOrganizationInput>;
  SnoozeTrackings?: InputMaybe<SnoozeTrackingUpdateManyWithoutOrganizationInput>;
  StepActionLogs?: InputMaybe<StepActionLogUpdateManyWithoutOrganizationInput>;
  StepActions?: InputMaybe<StepActionUpdateManyWithoutOrganizationInput>;
  StepConditions?: InputMaybe<StepConditionUpdateManyWithoutOrganizationInput>;
  teams?: InputMaybe<TeamUpdateManyWithoutOrganizationInput>;
  Template?: InputMaybe<TemplateUpdateManyWithoutOrganizationInput>;
  timekit_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timezone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transfer_timeout?: InputMaybe<IntFieldUpdateOperationsInput>;
  TransferAttempts?: InputMaybe<TransferAttemptUpdateManyWithoutOrganizationInput>;
  twilio_numbers?: InputMaybe<TwilioNumberUpdateManyWithoutOrganizationInput>;
  TwilioFeedbacks?: InputMaybe<TwilioFeedbackUpdateManyWithoutOrganizationInput>;
  TwilioInfo?: InputMaybe<TwilioInfoUpdateOneWithoutOrganzationInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  use_revenue_planning?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  UserCustomFieldFilters?: InputMaybe<UserCustomFieldFilterUpdateManyWithoutOrganizationInput>;
  users?: InputMaybe<UserUpdateManyWithoutOrganizationInput>;
  win_rep_day_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
};

export type OrganizationUpdateWithoutTransferAttemptsInput = {
  ActivityMonitorMultiplier?: InputMaybe<ActivityMonitorMultiplierUpdateManyWithoutOrganizationInput>;
  algoFactor?: InputMaybe<AlgoFactorUpdateManyWithoutOrganizationInput>;
  allow_calendar_invite?: InputMaybe<BoolFieldUpdateOperationsInput>;
  allow_remove_step_action?: InputMaybe<BoolFieldUpdateOperationsInput>;
  allow_snooze_step_action?: InputMaybe<BoolFieldUpdateOperationsInput>;
  always_record?: InputMaybe<BoolFieldUpdateOperationsInput>;
  arv_initial_held_demos_generated_by_sdr_weight?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  AssociationHistories?: InputMaybe<AssociationHistoryUpdateManyWithoutOrganizationInput>;
  auto_call_interval?: InputMaybe<IntFieldUpdateOperationsInput>;
  auto_dial?: InputMaybe<BoolFieldUpdateOperationsInput>;
  automated_campaigns?: InputMaybe<AutomatedCampaignUpdateManyWithoutOrganizationInput>;
  automatic_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  AutoTransferSelections?: InputMaybe<AutoTransferSelectionUpdateManyWithoutOrganizationInput>;
  Availablities?: InputMaybe<AvailabilityUpdateManyWithoutOrganizationInput>;
  AverageOrderValue?: InputMaybe<AverageOrderValueUpdateManyWithoutOrganizationInput>;
  best_action_ever_multiplier?: InputMaybe<FloatFieldUpdateOperationsInput>;
  best_metrics?: InputMaybe<BestMetricRecordUpdateManyWithoutOrganizationInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptUpdateManyWithoutOrganizationInput>;
  callMeNows?: InputMaybe<CallMeNowUpdateManyWithoutOrganizationInput>;
  CallMultiplier?: InputMaybe<CallMultiplierUpdateManyWithoutOrganizationInput>;
  CallSharings?: InputMaybe<CallSharingUpdateManyWithoutOrganizationInput>;
  can_edit_sdr_transfer_rules?: InputMaybe<BoolFieldUpdateOperationsInput>;
  claim_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  concession?: InputMaybe<ConcessionUpdateManyWithoutOrganizationInput>;
  conference_region?: InputMaybe<EnumConferenceRegionFieldUpdateOperationsInput>;
  Conferences?: InputMaybe<ConferenceUpdateManyWithoutOrganizationInput>;
  ContactRequests?: InputMaybe<ContactRequestUpdateManyWithoutOrganizationInput>;
  Contacts?: InputMaybe<ContactUpdateManyWithoutOrganizationInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  custom_fields?: InputMaybe<CustomFieldUpdateManyWithoutOrganizationInput>;
  CustomObject?: InputMaybe<CustomObjectUpdateManyWithoutOrganizationInput>;
  CustomObjectField?: InputMaybe<CustomObjectFieldUpdateManyWithoutOrganizationInput>;
  CustomObjectFieldForeignKey?: InputMaybe<CustomObjectFieldForeignKeyUpdateManyWithoutOrganizationInput>;
  CustomObjectRecord?: InputMaybe<CustomObjectRecordUpdateManyWithoutOrganizationInput>;
  CustomObjectRow?: InputMaybe<CustomObjectRowUpdateManyWithoutOrganizationInput>;
  CustomQueueItems?: InputMaybe<CustomQueueItemUpdateManyWithoutOrganizationInput>;
  DashboardColumnOptions?: InputMaybe<DashboardColumnOptionUpdateManyWithoutOrganizationInput>;
  DashboardLeadFilters?: InputMaybe<DashboardLeadFilterUpdateManyWithoutOrganizationInput>;
  DashboardRepFilters?: InputMaybe<DashboardRepFilterUpdateManyWithoutOrganizationInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewUpdateManyWithoutOrganizationInput>;
  day_to_close?: InputMaybe<IntFieldUpdateOperationsInput>;
  default_demo_time_duration?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  demo_held_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_not_held_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_not_held_call_back_short_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_prep_time_minutes?: InputMaybe<IntFieldUpdateOperationsInput>;
  dev_account?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  dial_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  DispositionMultiplier?: InputMaybe<DispositionMultiplierUpdateManyWithoutOrganizationInput>;
  dispositions?: InputMaybe<DispositionUpdateManyWithoutOrganizationInput>;
  double_booking?: InputMaybe<BoolFieldUpdateOperationsInput>;
  dynamic_date_operators?: InputMaybe<BoolFieldUpdateOperationsInput>;
  edge_servers?: InputMaybe<OrganizationUpdateedge_ServersInput>;
  email_conflict_notification_email_list?: InputMaybe<OrganizationUpdateemail_Conflict_Notification_Email_ListInput>;
  EmailItems?: InputMaybe<EmailItemUpdateManyWithoutOrganizationInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordUpdateManyWithoutOrganizationInput>;
  EmailThreads?: InputMaybe<EmailThreadUpdateManyWithoutOrganizationInput>;
  enable_modified_metrics?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  enable_pay_period_view?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  enable_time_constraint?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  error_log_email_address?: InputMaybe<OrganizationUpdateerror_Log_Email_AddressInput>;
  estimated_average_num_of_weekly_overbooked_demo_slots?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  FolderPermissions?: InputMaybe<FolderPermissionUpdateManyWithoutOrganizationInput>;
  Folders?: InputMaybe<FolderUpdateManyWithoutOrganizationInput>;
  FutureBookingSetting?: InputMaybe<FutureBookingSettingUpdateOneWithoutOrganizationInput>;
  GlobalTransferRule?: InputMaybe<GlobalTransferRuleUpdateManyWithoutOrganizationInput>;
  held_demos_per_cc_connect?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  helpful_links?: InputMaybe<OrganizationUpdatehelpful_LinksInput>;
  HingePointMultiplier?: InputMaybe<HingePointMultiplierUpdateManyWithoutOrganizationInput>;
  hold_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
  hubspot_credential?: InputMaybe<HubSpotCredentialUpdateOneWithoutOrganizationInput>;
  hubspot_last_sync_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  hubspot_white_list?: InputMaybe<HubSpotWhitelistUpdateManyWithoutOrganizationInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inbound_close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_dm_connect_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_lt_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_revenue_value_of_sale?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_scheduled_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_set_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventUpdateManyWithoutOrganizationInput>;
  InboundConciergeWidget?: InputMaybe<InboundConciergeWidgetUpdateManyWithoutOrganizationInput>;
  industry_options?: InputMaybe<IndustryOptionUpdateManyWithoutOrganizationInput>;
  initial_sdr_sets_reps_day?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  integration_error_logs?: InputMaybe<IntegrationErrorLogUpdateManyWithoutOrganizationInput>;
  integration_mappings?: InputMaybe<IntegrationMappingUpdateManyWithoutOrganizationInput>;
  IntegrationMappingCustomObject?: InputMaybe<IntegrationMappingCustomObjectUpdateManyWithoutOrganizationInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogUpdateManyWithoutOrganizationInput>;
  IntegrationReadOnlyObject?: InputMaybe<IntegrationReadOnlyObjectUpdateManyWithoutOrganizationInput>;
  international?: InputMaybe<BoolFieldUpdateOperationsInput>;
  is_email_domain_verified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  is_salesforce_sandbox?: InputMaybe<BoolFieldUpdateOperationsInput>;
  last_lead_value_compute_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutOrganizationInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryUpdateManyWithoutOrganizationInput>;
  lead_intent?: InputMaybe<LeadIntentUpdateManyWithoutOrganizationInput>;
  lead_release_same_rep_delay_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  lead_routing_email_list?: InputMaybe<OrganizationUpdatelead_Routing_Email_ListInput>;
  lead_routing_rules?: InputMaybe<RuleUpdateManyWithoutOrganizationInput>;
  LeadCustomObjectRowAssociation?: InputMaybe<LeadCustomObjectRowAssociationUpdateManyWithoutOrganizationInput>;
  LeadFieldMultiplier?: InputMaybe<LeadFieldMultiplierUpdateManyWithoutOrganizationInput>;
  LeadFieldMultiplierCustom?: InputMaybe<LeadFieldMultiplierCustomUpdateManyWithoutOrganizationInput>;
  leads?: InputMaybe<LeadUpdateManyWithoutOrganizationInput>;
  leadsource_options?: InputMaybe<LeadSourceOptionUpdateManyWithoutOrganizationInput>;
  LeadValue?: InputMaybe<LeadValueUpdateManyWithoutOrganizationInput>;
  LifeTimeValueMultiplier?: InputMaybe<LifeTimeValueMultiplierUpdateManyWithoutOrganizationInput>;
  LiveTransferSetting?: InputMaybe<LiveTransferSettingUpdateOneWithoutOrganizationInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusUpdateManyWithoutOrganizationInput>;
  ltv_multiplier_custom_fields?: InputMaybe<LifeTimeValueMultiplierCustomUpdateManyWithoutOrganizationInput>;
  made_contact_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  make_sale_address_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_business_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_city_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_country_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_first_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_industry_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_last_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_lead_source_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_primary_email_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_primary_phone_number_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_state_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_sub_industry_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_zip_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  maxium_day_lag_set_to_schedule?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  mrr?: InputMaybe<IntFieldUpdateOperationsInput>;
  mrr_label?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  mrr_required_on_sale?: InputMaybe<BoolFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  NoteItems?: InputMaybe<NoteItemUpdateManyWithoutOrganizationInput>;
  Notifications?: InputMaybe<NotificationUpdateManyWithoutOrganizationInput>;
  onboardComplete?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  Opportunity?: InputMaybe<SfOpportunityUpdateManyWithoutOrganizationInput>;
  organization_holidays?: InputMaybe<OrganizationHolidayUpdateManyWithoutOrganizationInput>;
  organization_hours?: InputMaybe<OrganizationHourUpdateManyWithoutOrganizationInput>;
  OrganizationPaymentSetting?: InputMaybe<OrganizationPaymentSettingsUpdateOneWithoutOrganizationInput>;
  OrganizationPaymentSettings?: InputMaybe<OrganizationPaymentSettingsUpdateOneWithoutOrganizationsInput>;
  orgCalendarInviteMetadata?: InputMaybe<OrgCalendarInviteMetadataUpdateManyWithoutOrganizationInput>;
  OrgDefaultPipelinePerecentValue?: InputMaybe<OrgDefaultPipelinePerecentValueUpdateOneWithoutOrganizationInput>;
  OrgHubSpotSync?: InputMaybe<OrgHubSpotSyncUpdateOneWithoutOrganizationInput>;
  OrgNoEventDate?: InputMaybe<OrgNoEventDateUpdateManyWithoutOrganizationInput>;
  OrgSalesforceSync?: InputMaybe<OrgSalesforceSyncUpdateOneWithoutOrganizationInput>;
  OrgTimeslotAvailability?: InputMaybe<OrgTimeslotAvailabilityUpdateManyWithoutOrganizationInput>;
  outbound_close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_dm_connect_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_lt_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_revenue_value_of_sale?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_scheduled_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_set_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  PandadocData?: InputMaybe<PandadocDataUpdateOneWithoutOrganizationInput>;
  PandaDocTemplate?: InputMaybe<PandaDocTemplateUpdateManyWithoutOrganizationInput>;
  participant_jitter_buffer_size?: InputMaybe<EnumJitterBufferSizeFieldUpdateOperationsInput>;
  preferred_goal_setting?: InputMaybe<NullableEnumGoalsettingFieldUpdateOperationsInput>;
  prepayment?: InputMaybe<BoolFieldUpdateOperationsInput>;
  ProductChangeLog?: InputMaybe<ProductChangeLogUpdateManyWithoutOrganizationInput>;
  products?: InputMaybe<ProductUpdateManyWithoutOrganizationInput>;
  re_cold_call_hour_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  recordingStatus?: InputMaybe<EnumRecordingStatusFieldUpdateOperationsInput>;
  release_lead_resting?: InputMaybe<IntFieldUpdateOperationsInput>;
  rep_claim_limit?: InputMaybe<IntFieldUpdateOperationsInput>;
  rep_lead_allotment?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  rep_level_call_transfer?: InputMaybe<BoolFieldUpdateOperationsInput>;
  reps_can_bulk_unassign?: InputMaybe<BoolFieldUpdateOperationsInput>;
  reps_can_view_all_notes?: InputMaybe<BoolFieldUpdateOperationsInput>;
  require_sale_notes?: InputMaybe<BoolFieldUpdateOperationsInput>;
  resting_after_skipped_CC?: InputMaybe<IntFieldUpdateOperationsInput>;
  resting_period?: InputMaybe<IntFieldUpdateOperationsInput>;
  resting_period_after_unsuccessful_cold_call?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  resting_period_after_unsuccessful_demo?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  revenue_plan?: InputMaybe<RevenuePlanItemUpdateManyWithoutOrganizationInput>;
  Routings?: InputMaybe<RoutingUpdateManyWithoutOrganizationInput>;
  sale_field_options?: InputMaybe<SaleFieldOptionUpdateManyWithoutOrganizationInput>;
  sale_notification_email_list?: InputMaybe<OrganizationUpdatesale_Notification_Email_ListInput>;
  SaleConfig?: InputMaybe<SaleConfigUpdateOneWithoutOrganizationInput>;
  SaleConfigPage?: InputMaybe<SaleConfigPageUpdateManyWithoutOrganizationInput>;
  SaleConfigSection?: InputMaybe<SaleConfigSectionUpdateManyWithoutOrganizationInput>;
  SaleCycles?: InputMaybe<SaleCycleUpdateManyWithoutOrganizationInput>;
  SaleItems?: InputMaybe<SaleItemUpdateManyWithoutOrganizationInput>;
  sales?: InputMaybe<SaleUpdateManyWithoutOrganizationInput>;
  salesforce_domain?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  salesforce_last_sync_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  SalesForceColumn?: InputMaybe<SalesForceColumnUpdateManyWithoutOrganizationInput>;
  salesForceData?: InputMaybe<SalesForceDataUpdateOneWithoutOrganizationInput>;
  ScheduleItems?: InputMaybe<ScheduleItemUpdateManyWithoutOrganizationInput>;
  self_sourcing_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  send_error_log_email?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  send_lead_routing_email?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sendgrid_email_domain?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sendgrid_email_domain_id?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  sequence?: InputMaybe<SequenceUpdateManyWithoutOrganzationInput>;
  SequenceCriteriaConditions?: InputMaybe<SequenceCriteriaConditionUpdateManyWithoutOrganizationInput>;
  SequenceDashboardViews?: InputMaybe<SequenceDashboardViewUpdateManyWithoutOrganizationInput>;
  SequenceEntryCriterias?: InputMaybe<SequenceEntryCriteriaUpdateManyWithoutOrganizationInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutOrganizationInput>;
  SequenceSteps?: InputMaybe<SequenceStepUpdateManyWithoutOrganizationInput>;
  set_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
  short_resting?: InputMaybe<IntFieldUpdateOperationsInput>;
  show_calendar_options?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_contract_duration_sales?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_lead_source_to_rep?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_nocontact_button?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_payment_terms_sales?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_rep_all_leads?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_rep_my_leads?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_system_view?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sites?: InputMaybe<SiteUpdateManyWithoutOrganizationInput>;
  sms_opt_in_dialog?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SMSItems?: InputMaybe<SmsItemUpdateManyWithoutOrganizationInput>;
  SnoozeTrackings?: InputMaybe<SnoozeTrackingUpdateManyWithoutOrganizationInput>;
  StepActionLogs?: InputMaybe<StepActionLogUpdateManyWithoutOrganizationInput>;
  StepActions?: InputMaybe<StepActionUpdateManyWithoutOrganizationInput>;
  StepConditions?: InputMaybe<StepConditionUpdateManyWithoutOrganizationInput>;
  teams?: InputMaybe<TeamUpdateManyWithoutOrganizationInput>;
  Template?: InputMaybe<TemplateUpdateManyWithoutOrganizationInput>;
  TemplateVersions?: InputMaybe<TemplateVersionUpdateManyWithoutOrganizationInput>;
  timekit_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timezone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transfer_timeout?: InputMaybe<IntFieldUpdateOperationsInput>;
  twilio_numbers?: InputMaybe<TwilioNumberUpdateManyWithoutOrganizationInput>;
  TwilioFeedbacks?: InputMaybe<TwilioFeedbackUpdateManyWithoutOrganizationInput>;
  TwilioInfo?: InputMaybe<TwilioInfoUpdateOneWithoutOrganzationInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  use_revenue_planning?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  UserCustomFieldFilters?: InputMaybe<UserCustomFieldFilterUpdateManyWithoutOrganizationInput>;
  users?: InputMaybe<UserUpdateManyWithoutOrganizationInput>;
  win_rep_day_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
};

export type OrganizationUpdateWithoutTwilio_NumbersInput = {
  ActivityMonitorMultiplier?: InputMaybe<ActivityMonitorMultiplierUpdateManyWithoutOrganizationInput>;
  algoFactor?: InputMaybe<AlgoFactorUpdateManyWithoutOrganizationInput>;
  allow_calendar_invite?: InputMaybe<BoolFieldUpdateOperationsInput>;
  allow_remove_step_action?: InputMaybe<BoolFieldUpdateOperationsInput>;
  allow_snooze_step_action?: InputMaybe<BoolFieldUpdateOperationsInput>;
  always_record?: InputMaybe<BoolFieldUpdateOperationsInput>;
  arv_initial_held_demos_generated_by_sdr_weight?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  AssociationHistories?: InputMaybe<AssociationHistoryUpdateManyWithoutOrganizationInput>;
  auto_call_interval?: InputMaybe<IntFieldUpdateOperationsInput>;
  auto_dial?: InputMaybe<BoolFieldUpdateOperationsInput>;
  automated_campaigns?: InputMaybe<AutomatedCampaignUpdateManyWithoutOrganizationInput>;
  automatic_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  AutoTransferSelections?: InputMaybe<AutoTransferSelectionUpdateManyWithoutOrganizationInput>;
  Availablities?: InputMaybe<AvailabilityUpdateManyWithoutOrganizationInput>;
  AverageOrderValue?: InputMaybe<AverageOrderValueUpdateManyWithoutOrganizationInput>;
  best_action_ever_multiplier?: InputMaybe<FloatFieldUpdateOperationsInput>;
  best_metrics?: InputMaybe<BestMetricRecordUpdateManyWithoutOrganizationInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptUpdateManyWithoutOrganizationInput>;
  callMeNows?: InputMaybe<CallMeNowUpdateManyWithoutOrganizationInput>;
  CallMultiplier?: InputMaybe<CallMultiplierUpdateManyWithoutOrganizationInput>;
  CallSharings?: InputMaybe<CallSharingUpdateManyWithoutOrganizationInput>;
  can_edit_sdr_transfer_rules?: InputMaybe<BoolFieldUpdateOperationsInput>;
  claim_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  concession?: InputMaybe<ConcessionUpdateManyWithoutOrganizationInput>;
  conference_region?: InputMaybe<EnumConferenceRegionFieldUpdateOperationsInput>;
  Conferences?: InputMaybe<ConferenceUpdateManyWithoutOrganizationInput>;
  ContactRequests?: InputMaybe<ContactRequestUpdateManyWithoutOrganizationInput>;
  Contacts?: InputMaybe<ContactUpdateManyWithoutOrganizationInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  custom_fields?: InputMaybe<CustomFieldUpdateManyWithoutOrganizationInput>;
  CustomObject?: InputMaybe<CustomObjectUpdateManyWithoutOrganizationInput>;
  CustomObjectField?: InputMaybe<CustomObjectFieldUpdateManyWithoutOrganizationInput>;
  CustomObjectFieldForeignKey?: InputMaybe<CustomObjectFieldForeignKeyUpdateManyWithoutOrganizationInput>;
  CustomObjectRecord?: InputMaybe<CustomObjectRecordUpdateManyWithoutOrganizationInput>;
  CustomObjectRow?: InputMaybe<CustomObjectRowUpdateManyWithoutOrganizationInput>;
  CustomQueueItems?: InputMaybe<CustomQueueItemUpdateManyWithoutOrganizationInput>;
  DashboardColumnOptions?: InputMaybe<DashboardColumnOptionUpdateManyWithoutOrganizationInput>;
  DashboardLeadFilters?: InputMaybe<DashboardLeadFilterUpdateManyWithoutOrganizationInput>;
  DashboardRepFilters?: InputMaybe<DashboardRepFilterUpdateManyWithoutOrganizationInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewUpdateManyWithoutOrganizationInput>;
  day_to_close?: InputMaybe<IntFieldUpdateOperationsInput>;
  default_demo_time_duration?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  demo_held_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_not_held_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_not_held_call_back_short_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_prep_time_minutes?: InputMaybe<IntFieldUpdateOperationsInput>;
  dev_account?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  dial_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  DispositionMultiplier?: InputMaybe<DispositionMultiplierUpdateManyWithoutOrganizationInput>;
  dispositions?: InputMaybe<DispositionUpdateManyWithoutOrganizationInput>;
  double_booking?: InputMaybe<BoolFieldUpdateOperationsInput>;
  dynamic_date_operators?: InputMaybe<BoolFieldUpdateOperationsInput>;
  edge_servers?: InputMaybe<OrganizationUpdateedge_ServersInput>;
  email_conflict_notification_email_list?: InputMaybe<OrganizationUpdateemail_Conflict_Notification_Email_ListInput>;
  EmailItems?: InputMaybe<EmailItemUpdateManyWithoutOrganizationInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordUpdateManyWithoutOrganizationInput>;
  EmailThreads?: InputMaybe<EmailThreadUpdateManyWithoutOrganizationInput>;
  enable_modified_metrics?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  enable_pay_period_view?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  enable_time_constraint?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  error_log_email_address?: InputMaybe<OrganizationUpdateerror_Log_Email_AddressInput>;
  estimated_average_num_of_weekly_overbooked_demo_slots?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  FolderPermissions?: InputMaybe<FolderPermissionUpdateManyWithoutOrganizationInput>;
  Folders?: InputMaybe<FolderUpdateManyWithoutOrganizationInput>;
  FutureBookingSetting?: InputMaybe<FutureBookingSettingUpdateOneWithoutOrganizationInput>;
  GlobalTransferRule?: InputMaybe<GlobalTransferRuleUpdateManyWithoutOrganizationInput>;
  held_demos_per_cc_connect?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  helpful_links?: InputMaybe<OrganizationUpdatehelpful_LinksInput>;
  HingePointMultiplier?: InputMaybe<HingePointMultiplierUpdateManyWithoutOrganizationInput>;
  hold_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
  hubspot_credential?: InputMaybe<HubSpotCredentialUpdateOneWithoutOrganizationInput>;
  hubspot_last_sync_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  hubspot_white_list?: InputMaybe<HubSpotWhitelistUpdateManyWithoutOrganizationInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inbound_close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_dm_connect_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_lt_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_revenue_value_of_sale?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_scheduled_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_set_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventUpdateManyWithoutOrganizationInput>;
  InboundConciergeWidget?: InputMaybe<InboundConciergeWidgetUpdateManyWithoutOrganizationInput>;
  industry_options?: InputMaybe<IndustryOptionUpdateManyWithoutOrganizationInput>;
  initial_sdr_sets_reps_day?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  integration_error_logs?: InputMaybe<IntegrationErrorLogUpdateManyWithoutOrganizationInput>;
  integration_mappings?: InputMaybe<IntegrationMappingUpdateManyWithoutOrganizationInput>;
  IntegrationMappingCustomObject?: InputMaybe<IntegrationMappingCustomObjectUpdateManyWithoutOrganizationInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogUpdateManyWithoutOrganizationInput>;
  IntegrationReadOnlyObject?: InputMaybe<IntegrationReadOnlyObjectUpdateManyWithoutOrganizationInput>;
  international?: InputMaybe<BoolFieldUpdateOperationsInput>;
  is_email_domain_verified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  is_salesforce_sandbox?: InputMaybe<BoolFieldUpdateOperationsInput>;
  last_lead_value_compute_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutOrganizationInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryUpdateManyWithoutOrganizationInput>;
  lead_intent?: InputMaybe<LeadIntentUpdateManyWithoutOrganizationInput>;
  lead_release_same_rep_delay_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  lead_routing_email_list?: InputMaybe<OrganizationUpdatelead_Routing_Email_ListInput>;
  lead_routing_rules?: InputMaybe<RuleUpdateManyWithoutOrganizationInput>;
  LeadCustomObjectRowAssociation?: InputMaybe<LeadCustomObjectRowAssociationUpdateManyWithoutOrganizationInput>;
  LeadFieldMultiplier?: InputMaybe<LeadFieldMultiplierUpdateManyWithoutOrganizationInput>;
  LeadFieldMultiplierCustom?: InputMaybe<LeadFieldMultiplierCustomUpdateManyWithoutOrganizationInput>;
  leads?: InputMaybe<LeadUpdateManyWithoutOrganizationInput>;
  leadsource_options?: InputMaybe<LeadSourceOptionUpdateManyWithoutOrganizationInput>;
  LeadValue?: InputMaybe<LeadValueUpdateManyWithoutOrganizationInput>;
  LifeTimeValueMultiplier?: InputMaybe<LifeTimeValueMultiplierUpdateManyWithoutOrganizationInput>;
  LiveTransferSetting?: InputMaybe<LiveTransferSettingUpdateOneWithoutOrganizationInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusUpdateManyWithoutOrganizationInput>;
  ltv_multiplier_custom_fields?: InputMaybe<LifeTimeValueMultiplierCustomUpdateManyWithoutOrganizationInput>;
  made_contact_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  make_sale_address_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_business_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_city_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_country_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_first_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_industry_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_last_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_lead_source_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_primary_email_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_primary_phone_number_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_state_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_sub_industry_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_zip_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  maxium_day_lag_set_to_schedule?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  mrr?: InputMaybe<IntFieldUpdateOperationsInput>;
  mrr_label?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  mrr_required_on_sale?: InputMaybe<BoolFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  NoteItems?: InputMaybe<NoteItemUpdateManyWithoutOrganizationInput>;
  Notifications?: InputMaybe<NotificationUpdateManyWithoutOrganizationInput>;
  onboardComplete?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  Opportunity?: InputMaybe<SfOpportunityUpdateManyWithoutOrganizationInput>;
  organization_holidays?: InputMaybe<OrganizationHolidayUpdateManyWithoutOrganizationInput>;
  organization_hours?: InputMaybe<OrganizationHourUpdateManyWithoutOrganizationInput>;
  OrganizationPaymentSetting?: InputMaybe<OrganizationPaymentSettingsUpdateOneWithoutOrganizationInput>;
  OrganizationPaymentSettings?: InputMaybe<OrganizationPaymentSettingsUpdateOneWithoutOrganizationsInput>;
  orgCalendarInviteMetadata?: InputMaybe<OrgCalendarInviteMetadataUpdateManyWithoutOrganizationInput>;
  OrgDefaultPipelinePerecentValue?: InputMaybe<OrgDefaultPipelinePerecentValueUpdateOneWithoutOrganizationInput>;
  OrgHubSpotSync?: InputMaybe<OrgHubSpotSyncUpdateOneWithoutOrganizationInput>;
  OrgNoEventDate?: InputMaybe<OrgNoEventDateUpdateManyWithoutOrganizationInput>;
  OrgSalesforceSync?: InputMaybe<OrgSalesforceSyncUpdateOneWithoutOrganizationInput>;
  OrgTimeslotAvailability?: InputMaybe<OrgTimeslotAvailabilityUpdateManyWithoutOrganizationInput>;
  outbound_close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_dm_connect_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_lt_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_revenue_value_of_sale?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_scheduled_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_set_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  PandadocData?: InputMaybe<PandadocDataUpdateOneWithoutOrganizationInput>;
  PandaDocTemplate?: InputMaybe<PandaDocTemplateUpdateManyWithoutOrganizationInput>;
  participant_jitter_buffer_size?: InputMaybe<EnumJitterBufferSizeFieldUpdateOperationsInput>;
  preferred_goal_setting?: InputMaybe<NullableEnumGoalsettingFieldUpdateOperationsInput>;
  prepayment?: InputMaybe<BoolFieldUpdateOperationsInput>;
  ProductChangeLog?: InputMaybe<ProductChangeLogUpdateManyWithoutOrganizationInput>;
  products?: InputMaybe<ProductUpdateManyWithoutOrganizationInput>;
  re_cold_call_hour_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  recordingStatus?: InputMaybe<EnumRecordingStatusFieldUpdateOperationsInput>;
  release_lead_resting?: InputMaybe<IntFieldUpdateOperationsInput>;
  rep_claim_limit?: InputMaybe<IntFieldUpdateOperationsInput>;
  rep_lead_allotment?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  rep_level_call_transfer?: InputMaybe<BoolFieldUpdateOperationsInput>;
  reps_can_bulk_unassign?: InputMaybe<BoolFieldUpdateOperationsInput>;
  reps_can_view_all_notes?: InputMaybe<BoolFieldUpdateOperationsInput>;
  require_sale_notes?: InputMaybe<BoolFieldUpdateOperationsInput>;
  resting_after_skipped_CC?: InputMaybe<IntFieldUpdateOperationsInput>;
  resting_period?: InputMaybe<IntFieldUpdateOperationsInput>;
  resting_period_after_unsuccessful_cold_call?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  resting_period_after_unsuccessful_demo?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  revenue_plan?: InputMaybe<RevenuePlanItemUpdateManyWithoutOrganizationInput>;
  Routings?: InputMaybe<RoutingUpdateManyWithoutOrganizationInput>;
  sale_field_options?: InputMaybe<SaleFieldOptionUpdateManyWithoutOrganizationInput>;
  sale_notification_email_list?: InputMaybe<OrganizationUpdatesale_Notification_Email_ListInput>;
  SaleConfig?: InputMaybe<SaleConfigUpdateOneWithoutOrganizationInput>;
  SaleConfigPage?: InputMaybe<SaleConfigPageUpdateManyWithoutOrganizationInput>;
  SaleConfigSection?: InputMaybe<SaleConfigSectionUpdateManyWithoutOrganizationInput>;
  SaleCycles?: InputMaybe<SaleCycleUpdateManyWithoutOrganizationInput>;
  SaleItems?: InputMaybe<SaleItemUpdateManyWithoutOrganizationInput>;
  sales?: InputMaybe<SaleUpdateManyWithoutOrganizationInput>;
  salesforce_domain?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  salesforce_last_sync_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  SalesForceColumn?: InputMaybe<SalesForceColumnUpdateManyWithoutOrganizationInput>;
  salesForceData?: InputMaybe<SalesForceDataUpdateOneWithoutOrganizationInput>;
  ScheduleItems?: InputMaybe<ScheduleItemUpdateManyWithoutOrganizationInput>;
  self_sourcing_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  send_error_log_email?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  send_lead_routing_email?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sendgrid_email_domain?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sendgrid_email_domain_id?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  sequence?: InputMaybe<SequenceUpdateManyWithoutOrganzationInput>;
  SequenceCriteriaConditions?: InputMaybe<SequenceCriteriaConditionUpdateManyWithoutOrganizationInput>;
  SequenceDashboardViews?: InputMaybe<SequenceDashboardViewUpdateManyWithoutOrganizationInput>;
  SequenceEntryCriterias?: InputMaybe<SequenceEntryCriteriaUpdateManyWithoutOrganizationInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutOrganizationInput>;
  SequenceSteps?: InputMaybe<SequenceStepUpdateManyWithoutOrganizationInput>;
  set_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
  short_resting?: InputMaybe<IntFieldUpdateOperationsInput>;
  show_calendar_options?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_contract_duration_sales?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_lead_source_to_rep?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_nocontact_button?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_payment_terms_sales?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_rep_all_leads?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_rep_my_leads?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_system_view?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sites?: InputMaybe<SiteUpdateManyWithoutOrganizationInput>;
  sms_opt_in_dialog?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SMSItems?: InputMaybe<SmsItemUpdateManyWithoutOrganizationInput>;
  SnoozeTrackings?: InputMaybe<SnoozeTrackingUpdateManyWithoutOrganizationInput>;
  StepActionLogs?: InputMaybe<StepActionLogUpdateManyWithoutOrganizationInput>;
  StepActions?: InputMaybe<StepActionUpdateManyWithoutOrganizationInput>;
  StepConditions?: InputMaybe<StepConditionUpdateManyWithoutOrganizationInput>;
  teams?: InputMaybe<TeamUpdateManyWithoutOrganizationInput>;
  Template?: InputMaybe<TemplateUpdateManyWithoutOrganizationInput>;
  TemplateVersions?: InputMaybe<TemplateVersionUpdateManyWithoutOrganizationInput>;
  timekit_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timezone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transfer_timeout?: InputMaybe<IntFieldUpdateOperationsInput>;
  TransferAttempts?: InputMaybe<TransferAttemptUpdateManyWithoutOrganizationInput>;
  TwilioFeedbacks?: InputMaybe<TwilioFeedbackUpdateManyWithoutOrganizationInput>;
  TwilioInfo?: InputMaybe<TwilioInfoUpdateOneWithoutOrganzationInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  use_revenue_planning?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  UserCustomFieldFilters?: InputMaybe<UserCustomFieldFilterUpdateManyWithoutOrganizationInput>;
  users?: InputMaybe<UserUpdateManyWithoutOrganizationInput>;
  win_rep_day_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
};

export type OrganizationUpdateWithoutTwilioFeedbacksInput = {
  ActivityMonitorMultiplier?: InputMaybe<ActivityMonitorMultiplierUpdateManyWithoutOrganizationInput>;
  algoFactor?: InputMaybe<AlgoFactorUpdateManyWithoutOrganizationInput>;
  allow_calendar_invite?: InputMaybe<BoolFieldUpdateOperationsInput>;
  allow_remove_step_action?: InputMaybe<BoolFieldUpdateOperationsInput>;
  allow_snooze_step_action?: InputMaybe<BoolFieldUpdateOperationsInput>;
  always_record?: InputMaybe<BoolFieldUpdateOperationsInput>;
  arv_initial_held_demos_generated_by_sdr_weight?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  AssociationHistories?: InputMaybe<AssociationHistoryUpdateManyWithoutOrganizationInput>;
  auto_call_interval?: InputMaybe<IntFieldUpdateOperationsInput>;
  auto_dial?: InputMaybe<BoolFieldUpdateOperationsInput>;
  automated_campaigns?: InputMaybe<AutomatedCampaignUpdateManyWithoutOrganizationInput>;
  automatic_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  AutoTransferSelections?: InputMaybe<AutoTransferSelectionUpdateManyWithoutOrganizationInput>;
  Availablities?: InputMaybe<AvailabilityUpdateManyWithoutOrganizationInput>;
  AverageOrderValue?: InputMaybe<AverageOrderValueUpdateManyWithoutOrganizationInput>;
  best_action_ever_multiplier?: InputMaybe<FloatFieldUpdateOperationsInput>;
  best_metrics?: InputMaybe<BestMetricRecordUpdateManyWithoutOrganizationInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptUpdateManyWithoutOrganizationInput>;
  callMeNows?: InputMaybe<CallMeNowUpdateManyWithoutOrganizationInput>;
  CallMultiplier?: InputMaybe<CallMultiplierUpdateManyWithoutOrganizationInput>;
  CallSharings?: InputMaybe<CallSharingUpdateManyWithoutOrganizationInput>;
  can_edit_sdr_transfer_rules?: InputMaybe<BoolFieldUpdateOperationsInput>;
  claim_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  concession?: InputMaybe<ConcessionUpdateManyWithoutOrganizationInput>;
  conference_region?: InputMaybe<EnumConferenceRegionFieldUpdateOperationsInput>;
  Conferences?: InputMaybe<ConferenceUpdateManyWithoutOrganizationInput>;
  ContactRequests?: InputMaybe<ContactRequestUpdateManyWithoutOrganizationInput>;
  Contacts?: InputMaybe<ContactUpdateManyWithoutOrganizationInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  custom_fields?: InputMaybe<CustomFieldUpdateManyWithoutOrganizationInput>;
  CustomObject?: InputMaybe<CustomObjectUpdateManyWithoutOrganizationInput>;
  CustomObjectField?: InputMaybe<CustomObjectFieldUpdateManyWithoutOrganizationInput>;
  CustomObjectFieldForeignKey?: InputMaybe<CustomObjectFieldForeignKeyUpdateManyWithoutOrganizationInput>;
  CustomObjectRecord?: InputMaybe<CustomObjectRecordUpdateManyWithoutOrganizationInput>;
  CustomObjectRow?: InputMaybe<CustomObjectRowUpdateManyWithoutOrganizationInput>;
  CustomQueueItems?: InputMaybe<CustomQueueItemUpdateManyWithoutOrganizationInput>;
  DashboardColumnOptions?: InputMaybe<DashboardColumnOptionUpdateManyWithoutOrganizationInput>;
  DashboardLeadFilters?: InputMaybe<DashboardLeadFilterUpdateManyWithoutOrganizationInput>;
  DashboardRepFilters?: InputMaybe<DashboardRepFilterUpdateManyWithoutOrganizationInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewUpdateManyWithoutOrganizationInput>;
  day_to_close?: InputMaybe<IntFieldUpdateOperationsInput>;
  default_demo_time_duration?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  demo_held_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_not_held_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_not_held_call_back_short_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_prep_time_minutes?: InputMaybe<IntFieldUpdateOperationsInput>;
  dev_account?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  dial_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  DispositionMultiplier?: InputMaybe<DispositionMultiplierUpdateManyWithoutOrganizationInput>;
  dispositions?: InputMaybe<DispositionUpdateManyWithoutOrganizationInput>;
  double_booking?: InputMaybe<BoolFieldUpdateOperationsInput>;
  dynamic_date_operators?: InputMaybe<BoolFieldUpdateOperationsInput>;
  edge_servers?: InputMaybe<OrganizationUpdateedge_ServersInput>;
  email_conflict_notification_email_list?: InputMaybe<OrganizationUpdateemail_Conflict_Notification_Email_ListInput>;
  EmailItems?: InputMaybe<EmailItemUpdateManyWithoutOrganizationInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordUpdateManyWithoutOrganizationInput>;
  EmailThreads?: InputMaybe<EmailThreadUpdateManyWithoutOrganizationInput>;
  enable_modified_metrics?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  enable_pay_period_view?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  enable_time_constraint?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  error_log_email_address?: InputMaybe<OrganizationUpdateerror_Log_Email_AddressInput>;
  estimated_average_num_of_weekly_overbooked_demo_slots?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  FolderPermissions?: InputMaybe<FolderPermissionUpdateManyWithoutOrganizationInput>;
  Folders?: InputMaybe<FolderUpdateManyWithoutOrganizationInput>;
  FutureBookingSetting?: InputMaybe<FutureBookingSettingUpdateOneWithoutOrganizationInput>;
  GlobalTransferRule?: InputMaybe<GlobalTransferRuleUpdateManyWithoutOrganizationInput>;
  held_demos_per_cc_connect?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  helpful_links?: InputMaybe<OrganizationUpdatehelpful_LinksInput>;
  HingePointMultiplier?: InputMaybe<HingePointMultiplierUpdateManyWithoutOrganizationInput>;
  hold_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
  hubspot_credential?: InputMaybe<HubSpotCredentialUpdateOneWithoutOrganizationInput>;
  hubspot_last_sync_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  hubspot_white_list?: InputMaybe<HubSpotWhitelistUpdateManyWithoutOrganizationInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inbound_close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_dm_connect_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_lt_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_revenue_value_of_sale?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_scheduled_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_set_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventUpdateManyWithoutOrganizationInput>;
  InboundConciergeWidget?: InputMaybe<InboundConciergeWidgetUpdateManyWithoutOrganizationInput>;
  industry_options?: InputMaybe<IndustryOptionUpdateManyWithoutOrganizationInput>;
  initial_sdr_sets_reps_day?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  integration_error_logs?: InputMaybe<IntegrationErrorLogUpdateManyWithoutOrganizationInput>;
  integration_mappings?: InputMaybe<IntegrationMappingUpdateManyWithoutOrganizationInput>;
  IntegrationMappingCustomObject?: InputMaybe<IntegrationMappingCustomObjectUpdateManyWithoutOrganizationInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogUpdateManyWithoutOrganizationInput>;
  IntegrationReadOnlyObject?: InputMaybe<IntegrationReadOnlyObjectUpdateManyWithoutOrganizationInput>;
  international?: InputMaybe<BoolFieldUpdateOperationsInput>;
  is_email_domain_verified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  is_salesforce_sandbox?: InputMaybe<BoolFieldUpdateOperationsInput>;
  last_lead_value_compute_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutOrganizationInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryUpdateManyWithoutOrganizationInput>;
  lead_intent?: InputMaybe<LeadIntentUpdateManyWithoutOrganizationInput>;
  lead_release_same_rep_delay_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  lead_routing_email_list?: InputMaybe<OrganizationUpdatelead_Routing_Email_ListInput>;
  lead_routing_rules?: InputMaybe<RuleUpdateManyWithoutOrganizationInput>;
  LeadCustomObjectRowAssociation?: InputMaybe<LeadCustomObjectRowAssociationUpdateManyWithoutOrganizationInput>;
  LeadFieldMultiplier?: InputMaybe<LeadFieldMultiplierUpdateManyWithoutOrganizationInput>;
  LeadFieldMultiplierCustom?: InputMaybe<LeadFieldMultiplierCustomUpdateManyWithoutOrganizationInput>;
  leads?: InputMaybe<LeadUpdateManyWithoutOrganizationInput>;
  leadsource_options?: InputMaybe<LeadSourceOptionUpdateManyWithoutOrganizationInput>;
  LeadValue?: InputMaybe<LeadValueUpdateManyWithoutOrganizationInput>;
  LifeTimeValueMultiplier?: InputMaybe<LifeTimeValueMultiplierUpdateManyWithoutOrganizationInput>;
  LiveTransferSetting?: InputMaybe<LiveTransferSettingUpdateOneWithoutOrganizationInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusUpdateManyWithoutOrganizationInput>;
  ltv_multiplier_custom_fields?: InputMaybe<LifeTimeValueMultiplierCustomUpdateManyWithoutOrganizationInput>;
  made_contact_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  make_sale_address_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_business_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_city_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_country_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_first_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_industry_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_last_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_lead_source_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_primary_email_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_primary_phone_number_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_state_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_sub_industry_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_zip_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  maxium_day_lag_set_to_schedule?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  mrr?: InputMaybe<IntFieldUpdateOperationsInput>;
  mrr_label?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  mrr_required_on_sale?: InputMaybe<BoolFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  NoteItems?: InputMaybe<NoteItemUpdateManyWithoutOrganizationInput>;
  Notifications?: InputMaybe<NotificationUpdateManyWithoutOrganizationInput>;
  onboardComplete?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  Opportunity?: InputMaybe<SfOpportunityUpdateManyWithoutOrganizationInput>;
  organization_holidays?: InputMaybe<OrganizationHolidayUpdateManyWithoutOrganizationInput>;
  organization_hours?: InputMaybe<OrganizationHourUpdateManyWithoutOrganizationInput>;
  OrganizationPaymentSetting?: InputMaybe<OrganizationPaymentSettingsUpdateOneWithoutOrganizationInput>;
  OrganizationPaymentSettings?: InputMaybe<OrganizationPaymentSettingsUpdateOneWithoutOrganizationsInput>;
  orgCalendarInviteMetadata?: InputMaybe<OrgCalendarInviteMetadataUpdateManyWithoutOrganizationInput>;
  OrgDefaultPipelinePerecentValue?: InputMaybe<OrgDefaultPipelinePerecentValueUpdateOneWithoutOrganizationInput>;
  OrgHubSpotSync?: InputMaybe<OrgHubSpotSyncUpdateOneWithoutOrganizationInput>;
  OrgNoEventDate?: InputMaybe<OrgNoEventDateUpdateManyWithoutOrganizationInput>;
  OrgSalesforceSync?: InputMaybe<OrgSalesforceSyncUpdateOneWithoutOrganizationInput>;
  OrgTimeslotAvailability?: InputMaybe<OrgTimeslotAvailabilityUpdateManyWithoutOrganizationInput>;
  outbound_close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_dm_connect_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_lt_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_revenue_value_of_sale?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_scheduled_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_set_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  PandadocData?: InputMaybe<PandadocDataUpdateOneWithoutOrganizationInput>;
  PandaDocTemplate?: InputMaybe<PandaDocTemplateUpdateManyWithoutOrganizationInput>;
  participant_jitter_buffer_size?: InputMaybe<EnumJitterBufferSizeFieldUpdateOperationsInput>;
  preferred_goal_setting?: InputMaybe<NullableEnumGoalsettingFieldUpdateOperationsInput>;
  prepayment?: InputMaybe<BoolFieldUpdateOperationsInput>;
  ProductChangeLog?: InputMaybe<ProductChangeLogUpdateManyWithoutOrganizationInput>;
  products?: InputMaybe<ProductUpdateManyWithoutOrganizationInput>;
  re_cold_call_hour_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  recordingStatus?: InputMaybe<EnumRecordingStatusFieldUpdateOperationsInput>;
  release_lead_resting?: InputMaybe<IntFieldUpdateOperationsInput>;
  rep_claim_limit?: InputMaybe<IntFieldUpdateOperationsInput>;
  rep_lead_allotment?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  rep_level_call_transfer?: InputMaybe<BoolFieldUpdateOperationsInput>;
  reps_can_bulk_unassign?: InputMaybe<BoolFieldUpdateOperationsInput>;
  reps_can_view_all_notes?: InputMaybe<BoolFieldUpdateOperationsInput>;
  require_sale_notes?: InputMaybe<BoolFieldUpdateOperationsInput>;
  resting_after_skipped_CC?: InputMaybe<IntFieldUpdateOperationsInput>;
  resting_period?: InputMaybe<IntFieldUpdateOperationsInput>;
  resting_period_after_unsuccessful_cold_call?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  resting_period_after_unsuccessful_demo?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  revenue_plan?: InputMaybe<RevenuePlanItemUpdateManyWithoutOrganizationInput>;
  Routings?: InputMaybe<RoutingUpdateManyWithoutOrganizationInput>;
  sale_field_options?: InputMaybe<SaleFieldOptionUpdateManyWithoutOrganizationInput>;
  sale_notification_email_list?: InputMaybe<OrganizationUpdatesale_Notification_Email_ListInput>;
  SaleConfig?: InputMaybe<SaleConfigUpdateOneWithoutOrganizationInput>;
  SaleConfigPage?: InputMaybe<SaleConfigPageUpdateManyWithoutOrganizationInput>;
  SaleConfigSection?: InputMaybe<SaleConfigSectionUpdateManyWithoutOrganizationInput>;
  SaleCycles?: InputMaybe<SaleCycleUpdateManyWithoutOrganizationInput>;
  SaleItems?: InputMaybe<SaleItemUpdateManyWithoutOrganizationInput>;
  sales?: InputMaybe<SaleUpdateManyWithoutOrganizationInput>;
  salesforce_domain?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  salesforce_last_sync_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  SalesForceColumn?: InputMaybe<SalesForceColumnUpdateManyWithoutOrganizationInput>;
  salesForceData?: InputMaybe<SalesForceDataUpdateOneWithoutOrganizationInput>;
  ScheduleItems?: InputMaybe<ScheduleItemUpdateManyWithoutOrganizationInput>;
  self_sourcing_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  send_error_log_email?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  send_lead_routing_email?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sendgrid_email_domain?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sendgrid_email_domain_id?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  sequence?: InputMaybe<SequenceUpdateManyWithoutOrganzationInput>;
  SequenceCriteriaConditions?: InputMaybe<SequenceCriteriaConditionUpdateManyWithoutOrganizationInput>;
  SequenceDashboardViews?: InputMaybe<SequenceDashboardViewUpdateManyWithoutOrganizationInput>;
  SequenceEntryCriterias?: InputMaybe<SequenceEntryCriteriaUpdateManyWithoutOrganizationInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutOrganizationInput>;
  SequenceSteps?: InputMaybe<SequenceStepUpdateManyWithoutOrganizationInput>;
  set_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
  short_resting?: InputMaybe<IntFieldUpdateOperationsInput>;
  show_calendar_options?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_contract_duration_sales?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_lead_source_to_rep?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_nocontact_button?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_payment_terms_sales?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_rep_all_leads?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_rep_my_leads?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_system_view?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sites?: InputMaybe<SiteUpdateManyWithoutOrganizationInput>;
  sms_opt_in_dialog?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SMSItems?: InputMaybe<SmsItemUpdateManyWithoutOrganizationInput>;
  SnoozeTrackings?: InputMaybe<SnoozeTrackingUpdateManyWithoutOrganizationInput>;
  StepActionLogs?: InputMaybe<StepActionLogUpdateManyWithoutOrganizationInput>;
  StepActions?: InputMaybe<StepActionUpdateManyWithoutOrganizationInput>;
  StepConditions?: InputMaybe<StepConditionUpdateManyWithoutOrganizationInput>;
  teams?: InputMaybe<TeamUpdateManyWithoutOrganizationInput>;
  Template?: InputMaybe<TemplateUpdateManyWithoutOrganizationInput>;
  TemplateVersions?: InputMaybe<TemplateVersionUpdateManyWithoutOrganizationInput>;
  timekit_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timezone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transfer_timeout?: InputMaybe<IntFieldUpdateOperationsInput>;
  TransferAttempts?: InputMaybe<TransferAttemptUpdateManyWithoutOrganizationInput>;
  twilio_numbers?: InputMaybe<TwilioNumberUpdateManyWithoutOrganizationInput>;
  TwilioInfo?: InputMaybe<TwilioInfoUpdateOneWithoutOrganzationInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  use_revenue_planning?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  UserCustomFieldFilters?: InputMaybe<UserCustomFieldFilterUpdateManyWithoutOrganizationInput>;
  users?: InputMaybe<UserUpdateManyWithoutOrganizationInput>;
  win_rep_day_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
};

export type OrganizationUpdateWithoutUserCustomFieldFiltersInput = {
  ActivityMonitorMultiplier?: InputMaybe<ActivityMonitorMultiplierUpdateManyWithoutOrganizationInput>;
  algoFactor?: InputMaybe<AlgoFactorUpdateManyWithoutOrganizationInput>;
  allow_calendar_invite?: InputMaybe<BoolFieldUpdateOperationsInput>;
  allow_remove_step_action?: InputMaybe<BoolFieldUpdateOperationsInput>;
  allow_snooze_step_action?: InputMaybe<BoolFieldUpdateOperationsInput>;
  always_record?: InputMaybe<BoolFieldUpdateOperationsInput>;
  arv_initial_held_demos_generated_by_sdr_weight?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  AssociationHistories?: InputMaybe<AssociationHistoryUpdateManyWithoutOrganizationInput>;
  auto_call_interval?: InputMaybe<IntFieldUpdateOperationsInput>;
  auto_dial?: InputMaybe<BoolFieldUpdateOperationsInput>;
  automated_campaigns?: InputMaybe<AutomatedCampaignUpdateManyWithoutOrganizationInput>;
  automatic_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  AutoTransferSelections?: InputMaybe<AutoTransferSelectionUpdateManyWithoutOrganizationInput>;
  Availablities?: InputMaybe<AvailabilityUpdateManyWithoutOrganizationInput>;
  AverageOrderValue?: InputMaybe<AverageOrderValueUpdateManyWithoutOrganizationInput>;
  best_action_ever_multiplier?: InputMaybe<FloatFieldUpdateOperationsInput>;
  best_metrics?: InputMaybe<BestMetricRecordUpdateManyWithoutOrganizationInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptUpdateManyWithoutOrganizationInput>;
  callMeNows?: InputMaybe<CallMeNowUpdateManyWithoutOrganizationInput>;
  CallMultiplier?: InputMaybe<CallMultiplierUpdateManyWithoutOrganizationInput>;
  CallSharings?: InputMaybe<CallSharingUpdateManyWithoutOrganizationInput>;
  can_edit_sdr_transfer_rules?: InputMaybe<BoolFieldUpdateOperationsInput>;
  claim_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  concession?: InputMaybe<ConcessionUpdateManyWithoutOrganizationInput>;
  conference_region?: InputMaybe<EnumConferenceRegionFieldUpdateOperationsInput>;
  Conferences?: InputMaybe<ConferenceUpdateManyWithoutOrganizationInput>;
  ContactRequests?: InputMaybe<ContactRequestUpdateManyWithoutOrganizationInput>;
  Contacts?: InputMaybe<ContactUpdateManyWithoutOrganizationInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  custom_fields?: InputMaybe<CustomFieldUpdateManyWithoutOrganizationInput>;
  CustomObject?: InputMaybe<CustomObjectUpdateManyWithoutOrganizationInput>;
  CustomObjectField?: InputMaybe<CustomObjectFieldUpdateManyWithoutOrganizationInput>;
  CustomObjectFieldForeignKey?: InputMaybe<CustomObjectFieldForeignKeyUpdateManyWithoutOrganizationInput>;
  CustomObjectRecord?: InputMaybe<CustomObjectRecordUpdateManyWithoutOrganizationInput>;
  CustomObjectRow?: InputMaybe<CustomObjectRowUpdateManyWithoutOrganizationInput>;
  CustomQueueItems?: InputMaybe<CustomQueueItemUpdateManyWithoutOrganizationInput>;
  DashboardColumnOptions?: InputMaybe<DashboardColumnOptionUpdateManyWithoutOrganizationInput>;
  DashboardLeadFilters?: InputMaybe<DashboardLeadFilterUpdateManyWithoutOrganizationInput>;
  DashboardRepFilters?: InputMaybe<DashboardRepFilterUpdateManyWithoutOrganizationInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewUpdateManyWithoutOrganizationInput>;
  day_to_close?: InputMaybe<IntFieldUpdateOperationsInput>;
  default_demo_time_duration?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  demo_held_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_not_held_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_not_held_call_back_short_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_prep_time_minutes?: InputMaybe<IntFieldUpdateOperationsInput>;
  dev_account?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  dial_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  DispositionMultiplier?: InputMaybe<DispositionMultiplierUpdateManyWithoutOrganizationInput>;
  dispositions?: InputMaybe<DispositionUpdateManyWithoutOrganizationInput>;
  double_booking?: InputMaybe<BoolFieldUpdateOperationsInput>;
  dynamic_date_operators?: InputMaybe<BoolFieldUpdateOperationsInput>;
  edge_servers?: InputMaybe<OrganizationUpdateedge_ServersInput>;
  email_conflict_notification_email_list?: InputMaybe<OrganizationUpdateemail_Conflict_Notification_Email_ListInput>;
  EmailItems?: InputMaybe<EmailItemUpdateManyWithoutOrganizationInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordUpdateManyWithoutOrganizationInput>;
  EmailThreads?: InputMaybe<EmailThreadUpdateManyWithoutOrganizationInput>;
  enable_modified_metrics?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  enable_pay_period_view?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  enable_time_constraint?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  error_log_email_address?: InputMaybe<OrganizationUpdateerror_Log_Email_AddressInput>;
  estimated_average_num_of_weekly_overbooked_demo_slots?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  FolderPermissions?: InputMaybe<FolderPermissionUpdateManyWithoutOrganizationInput>;
  Folders?: InputMaybe<FolderUpdateManyWithoutOrganizationInput>;
  FutureBookingSetting?: InputMaybe<FutureBookingSettingUpdateOneWithoutOrganizationInput>;
  GlobalTransferRule?: InputMaybe<GlobalTransferRuleUpdateManyWithoutOrganizationInput>;
  held_demos_per_cc_connect?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  helpful_links?: InputMaybe<OrganizationUpdatehelpful_LinksInput>;
  HingePointMultiplier?: InputMaybe<HingePointMultiplierUpdateManyWithoutOrganizationInput>;
  hold_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
  hubspot_credential?: InputMaybe<HubSpotCredentialUpdateOneWithoutOrganizationInput>;
  hubspot_last_sync_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  hubspot_white_list?: InputMaybe<HubSpotWhitelistUpdateManyWithoutOrganizationInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inbound_close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_dm_connect_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_lt_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_revenue_value_of_sale?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_scheduled_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_set_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventUpdateManyWithoutOrganizationInput>;
  InboundConciergeWidget?: InputMaybe<InboundConciergeWidgetUpdateManyWithoutOrganizationInput>;
  industry_options?: InputMaybe<IndustryOptionUpdateManyWithoutOrganizationInput>;
  initial_sdr_sets_reps_day?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  integration_error_logs?: InputMaybe<IntegrationErrorLogUpdateManyWithoutOrganizationInput>;
  integration_mappings?: InputMaybe<IntegrationMappingUpdateManyWithoutOrganizationInput>;
  IntegrationMappingCustomObject?: InputMaybe<IntegrationMappingCustomObjectUpdateManyWithoutOrganizationInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogUpdateManyWithoutOrganizationInput>;
  IntegrationReadOnlyObject?: InputMaybe<IntegrationReadOnlyObjectUpdateManyWithoutOrganizationInput>;
  international?: InputMaybe<BoolFieldUpdateOperationsInput>;
  is_email_domain_verified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  is_salesforce_sandbox?: InputMaybe<BoolFieldUpdateOperationsInput>;
  last_lead_value_compute_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutOrganizationInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryUpdateManyWithoutOrganizationInput>;
  lead_intent?: InputMaybe<LeadIntentUpdateManyWithoutOrganizationInput>;
  lead_release_same_rep_delay_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  lead_routing_email_list?: InputMaybe<OrganizationUpdatelead_Routing_Email_ListInput>;
  lead_routing_rules?: InputMaybe<RuleUpdateManyWithoutOrganizationInput>;
  LeadCustomObjectRowAssociation?: InputMaybe<LeadCustomObjectRowAssociationUpdateManyWithoutOrganizationInput>;
  LeadFieldMultiplier?: InputMaybe<LeadFieldMultiplierUpdateManyWithoutOrganizationInput>;
  LeadFieldMultiplierCustom?: InputMaybe<LeadFieldMultiplierCustomUpdateManyWithoutOrganizationInput>;
  leads?: InputMaybe<LeadUpdateManyWithoutOrganizationInput>;
  leadsource_options?: InputMaybe<LeadSourceOptionUpdateManyWithoutOrganizationInput>;
  LeadValue?: InputMaybe<LeadValueUpdateManyWithoutOrganizationInput>;
  LifeTimeValueMultiplier?: InputMaybe<LifeTimeValueMultiplierUpdateManyWithoutOrganizationInput>;
  LiveTransferSetting?: InputMaybe<LiveTransferSettingUpdateOneWithoutOrganizationInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusUpdateManyWithoutOrganizationInput>;
  ltv_multiplier_custom_fields?: InputMaybe<LifeTimeValueMultiplierCustomUpdateManyWithoutOrganizationInput>;
  made_contact_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  make_sale_address_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_business_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_city_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_country_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_first_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_industry_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_last_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_lead_source_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_primary_email_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_primary_phone_number_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_state_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_sub_industry_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_zip_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  maxium_day_lag_set_to_schedule?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  mrr?: InputMaybe<IntFieldUpdateOperationsInput>;
  mrr_label?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  mrr_required_on_sale?: InputMaybe<BoolFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  NoteItems?: InputMaybe<NoteItemUpdateManyWithoutOrganizationInput>;
  Notifications?: InputMaybe<NotificationUpdateManyWithoutOrganizationInput>;
  onboardComplete?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  Opportunity?: InputMaybe<SfOpportunityUpdateManyWithoutOrganizationInput>;
  organization_holidays?: InputMaybe<OrganizationHolidayUpdateManyWithoutOrganizationInput>;
  organization_hours?: InputMaybe<OrganizationHourUpdateManyWithoutOrganizationInput>;
  OrganizationPaymentSetting?: InputMaybe<OrganizationPaymentSettingsUpdateOneWithoutOrganizationInput>;
  OrganizationPaymentSettings?: InputMaybe<OrganizationPaymentSettingsUpdateOneWithoutOrganizationsInput>;
  orgCalendarInviteMetadata?: InputMaybe<OrgCalendarInviteMetadataUpdateManyWithoutOrganizationInput>;
  OrgDefaultPipelinePerecentValue?: InputMaybe<OrgDefaultPipelinePerecentValueUpdateOneWithoutOrganizationInput>;
  OrgHubSpotSync?: InputMaybe<OrgHubSpotSyncUpdateOneWithoutOrganizationInput>;
  OrgNoEventDate?: InputMaybe<OrgNoEventDateUpdateManyWithoutOrganizationInput>;
  OrgSalesforceSync?: InputMaybe<OrgSalesforceSyncUpdateOneWithoutOrganizationInput>;
  OrgTimeslotAvailability?: InputMaybe<OrgTimeslotAvailabilityUpdateManyWithoutOrganizationInput>;
  outbound_close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_dm_connect_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_lt_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_revenue_value_of_sale?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_scheduled_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_set_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  PandadocData?: InputMaybe<PandadocDataUpdateOneWithoutOrganizationInput>;
  PandaDocTemplate?: InputMaybe<PandaDocTemplateUpdateManyWithoutOrganizationInput>;
  participant_jitter_buffer_size?: InputMaybe<EnumJitterBufferSizeFieldUpdateOperationsInput>;
  preferred_goal_setting?: InputMaybe<NullableEnumGoalsettingFieldUpdateOperationsInput>;
  prepayment?: InputMaybe<BoolFieldUpdateOperationsInput>;
  ProductChangeLog?: InputMaybe<ProductChangeLogUpdateManyWithoutOrganizationInput>;
  products?: InputMaybe<ProductUpdateManyWithoutOrganizationInput>;
  re_cold_call_hour_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  recordingStatus?: InputMaybe<EnumRecordingStatusFieldUpdateOperationsInput>;
  release_lead_resting?: InputMaybe<IntFieldUpdateOperationsInput>;
  rep_claim_limit?: InputMaybe<IntFieldUpdateOperationsInput>;
  rep_lead_allotment?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  rep_level_call_transfer?: InputMaybe<BoolFieldUpdateOperationsInput>;
  reps_can_bulk_unassign?: InputMaybe<BoolFieldUpdateOperationsInput>;
  reps_can_view_all_notes?: InputMaybe<BoolFieldUpdateOperationsInput>;
  require_sale_notes?: InputMaybe<BoolFieldUpdateOperationsInput>;
  resting_after_skipped_CC?: InputMaybe<IntFieldUpdateOperationsInput>;
  resting_period?: InputMaybe<IntFieldUpdateOperationsInput>;
  resting_period_after_unsuccessful_cold_call?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  resting_period_after_unsuccessful_demo?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  revenue_plan?: InputMaybe<RevenuePlanItemUpdateManyWithoutOrganizationInput>;
  Routings?: InputMaybe<RoutingUpdateManyWithoutOrganizationInput>;
  sale_field_options?: InputMaybe<SaleFieldOptionUpdateManyWithoutOrganizationInput>;
  sale_notification_email_list?: InputMaybe<OrganizationUpdatesale_Notification_Email_ListInput>;
  SaleConfig?: InputMaybe<SaleConfigUpdateOneWithoutOrganizationInput>;
  SaleConfigPage?: InputMaybe<SaleConfigPageUpdateManyWithoutOrganizationInput>;
  SaleConfigSection?: InputMaybe<SaleConfigSectionUpdateManyWithoutOrganizationInput>;
  SaleCycles?: InputMaybe<SaleCycleUpdateManyWithoutOrganizationInput>;
  SaleItems?: InputMaybe<SaleItemUpdateManyWithoutOrganizationInput>;
  sales?: InputMaybe<SaleUpdateManyWithoutOrganizationInput>;
  salesforce_domain?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  salesforce_last_sync_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  SalesForceColumn?: InputMaybe<SalesForceColumnUpdateManyWithoutOrganizationInput>;
  salesForceData?: InputMaybe<SalesForceDataUpdateOneWithoutOrganizationInput>;
  ScheduleItems?: InputMaybe<ScheduleItemUpdateManyWithoutOrganizationInput>;
  self_sourcing_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  send_error_log_email?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  send_lead_routing_email?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sendgrid_email_domain?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sendgrid_email_domain_id?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  sequence?: InputMaybe<SequenceUpdateManyWithoutOrganzationInput>;
  SequenceCriteriaConditions?: InputMaybe<SequenceCriteriaConditionUpdateManyWithoutOrganizationInput>;
  SequenceDashboardViews?: InputMaybe<SequenceDashboardViewUpdateManyWithoutOrganizationInput>;
  SequenceEntryCriterias?: InputMaybe<SequenceEntryCriteriaUpdateManyWithoutOrganizationInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutOrganizationInput>;
  SequenceSteps?: InputMaybe<SequenceStepUpdateManyWithoutOrganizationInput>;
  set_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
  short_resting?: InputMaybe<IntFieldUpdateOperationsInput>;
  show_calendar_options?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_contract_duration_sales?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_lead_source_to_rep?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_nocontact_button?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_payment_terms_sales?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_rep_all_leads?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_rep_my_leads?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_system_view?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sites?: InputMaybe<SiteUpdateManyWithoutOrganizationInput>;
  sms_opt_in_dialog?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SMSItems?: InputMaybe<SmsItemUpdateManyWithoutOrganizationInput>;
  SnoozeTrackings?: InputMaybe<SnoozeTrackingUpdateManyWithoutOrganizationInput>;
  StepActionLogs?: InputMaybe<StepActionLogUpdateManyWithoutOrganizationInput>;
  StepActions?: InputMaybe<StepActionUpdateManyWithoutOrganizationInput>;
  StepConditions?: InputMaybe<StepConditionUpdateManyWithoutOrganizationInput>;
  teams?: InputMaybe<TeamUpdateManyWithoutOrganizationInput>;
  Template?: InputMaybe<TemplateUpdateManyWithoutOrganizationInput>;
  TemplateVersions?: InputMaybe<TemplateVersionUpdateManyWithoutOrganizationInput>;
  timekit_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timezone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transfer_timeout?: InputMaybe<IntFieldUpdateOperationsInput>;
  TransferAttempts?: InputMaybe<TransferAttemptUpdateManyWithoutOrganizationInput>;
  twilio_numbers?: InputMaybe<TwilioNumberUpdateManyWithoutOrganizationInput>;
  TwilioFeedbacks?: InputMaybe<TwilioFeedbackUpdateManyWithoutOrganizationInput>;
  TwilioInfo?: InputMaybe<TwilioInfoUpdateOneWithoutOrganzationInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  use_revenue_planning?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  users?: InputMaybe<UserUpdateManyWithoutOrganizationInput>;
  win_rep_day_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
};

export type OrganizationUpdateWithoutUsersInput = {
  ActivityMonitorMultiplier?: InputMaybe<ActivityMonitorMultiplierUpdateManyWithoutOrganizationInput>;
  algoFactor?: InputMaybe<AlgoFactorUpdateManyWithoutOrganizationInput>;
  allow_calendar_invite?: InputMaybe<BoolFieldUpdateOperationsInput>;
  allow_remove_step_action?: InputMaybe<BoolFieldUpdateOperationsInput>;
  allow_snooze_step_action?: InputMaybe<BoolFieldUpdateOperationsInput>;
  always_record?: InputMaybe<BoolFieldUpdateOperationsInput>;
  arv_initial_held_demos_generated_by_sdr_weight?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  AssociationHistories?: InputMaybe<AssociationHistoryUpdateManyWithoutOrganizationInput>;
  auto_call_interval?: InputMaybe<IntFieldUpdateOperationsInput>;
  auto_dial?: InputMaybe<BoolFieldUpdateOperationsInput>;
  automated_campaigns?: InputMaybe<AutomatedCampaignUpdateManyWithoutOrganizationInput>;
  automatic_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  AutoTransferSelections?: InputMaybe<AutoTransferSelectionUpdateManyWithoutOrganizationInput>;
  Availablities?: InputMaybe<AvailabilityUpdateManyWithoutOrganizationInput>;
  AverageOrderValue?: InputMaybe<AverageOrderValueUpdateManyWithoutOrganizationInput>;
  best_action_ever_multiplier?: InputMaybe<FloatFieldUpdateOperationsInput>;
  best_metrics?: InputMaybe<BestMetricRecordUpdateManyWithoutOrganizationInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptUpdateManyWithoutOrganizationInput>;
  callMeNows?: InputMaybe<CallMeNowUpdateManyWithoutOrganizationInput>;
  CallMultiplier?: InputMaybe<CallMultiplierUpdateManyWithoutOrganizationInput>;
  CallSharings?: InputMaybe<CallSharingUpdateManyWithoutOrganizationInput>;
  can_edit_sdr_transfer_rules?: InputMaybe<BoolFieldUpdateOperationsInput>;
  claim_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  concession?: InputMaybe<ConcessionUpdateManyWithoutOrganizationInput>;
  conference_region?: InputMaybe<EnumConferenceRegionFieldUpdateOperationsInput>;
  Conferences?: InputMaybe<ConferenceUpdateManyWithoutOrganizationInput>;
  ContactRequests?: InputMaybe<ContactRequestUpdateManyWithoutOrganizationInput>;
  Contacts?: InputMaybe<ContactUpdateManyWithoutOrganizationInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  custom_fields?: InputMaybe<CustomFieldUpdateManyWithoutOrganizationInput>;
  CustomObject?: InputMaybe<CustomObjectUpdateManyWithoutOrganizationInput>;
  CustomObjectField?: InputMaybe<CustomObjectFieldUpdateManyWithoutOrganizationInput>;
  CustomObjectFieldForeignKey?: InputMaybe<CustomObjectFieldForeignKeyUpdateManyWithoutOrganizationInput>;
  CustomObjectRecord?: InputMaybe<CustomObjectRecordUpdateManyWithoutOrganizationInput>;
  CustomObjectRow?: InputMaybe<CustomObjectRowUpdateManyWithoutOrganizationInput>;
  CustomQueueItems?: InputMaybe<CustomQueueItemUpdateManyWithoutOrganizationInput>;
  DashboardColumnOptions?: InputMaybe<DashboardColumnOptionUpdateManyWithoutOrganizationInput>;
  DashboardLeadFilters?: InputMaybe<DashboardLeadFilterUpdateManyWithoutOrganizationInput>;
  DashboardRepFilters?: InputMaybe<DashboardRepFilterUpdateManyWithoutOrganizationInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewUpdateManyWithoutOrganizationInput>;
  day_to_close?: InputMaybe<IntFieldUpdateOperationsInput>;
  default_demo_time_duration?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  demo_held_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_not_held_call_back_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_not_held_call_back_short_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  demo_prep_time_minutes?: InputMaybe<IntFieldUpdateOperationsInput>;
  dev_account?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  dial_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  DispositionMultiplier?: InputMaybe<DispositionMultiplierUpdateManyWithoutOrganizationInput>;
  dispositions?: InputMaybe<DispositionUpdateManyWithoutOrganizationInput>;
  double_booking?: InputMaybe<BoolFieldUpdateOperationsInput>;
  dynamic_date_operators?: InputMaybe<BoolFieldUpdateOperationsInput>;
  edge_servers?: InputMaybe<OrganizationUpdateedge_ServersInput>;
  email_conflict_notification_email_list?: InputMaybe<OrganizationUpdateemail_Conflict_Notification_Email_ListInput>;
  EmailItems?: InputMaybe<EmailItemUpdateManyWithoutOrganizationInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordUpdateManyWithoutOrganizationInput>;
  EmailThreads?: InputMaybe<EmailThreadUpdateManyWithoutOrganizationInput>;
  enable_modified_metrics?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  enable_pay_period_view?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  enable_time_constraint?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  error_log_email_address?: InputMaybe<OrganizationUpdateerror_Log_Email_AddressInput>;
  estimated_average_num_of_weekly_overbooked_demo_slots?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  FolderPermissions?: InputMaybe<FolderPermissionUpdateManyWithoutOrganizationInput>;
  Folders?: InputMaybe<FolderUpdateManyWithoutOrganizationInput>;
  FutureBookingSetting?: InputMaybe<FutureBookingSettingUpdateOneWithoutOrganizationInput>;
  GlobalTransferRule?: InputMaybe<GlobalTransferRuleUpdateManyWithoutOrganizationInput>;
  held_demos_per_cc_connect?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  helpful_links?: InputMaybe<OrganizationUpdatehelpful_LinksInput>;
  HingePointMultiplier?: InputMaybe<HingePointMultiplierUpdateManyWithoutOrganizationInput>;
  hold_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
  hubspot_credential?: InputMaybe<HubSpotCredentialUpdateOneWithoutOrganizationInput>;
  hubspot_last_sync_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  hubspot_white_list?: InputMaybe<HubSpotWhitelistUpdateManyWithoutOrganizationInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inbound_close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_dm_connect_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_lt_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_revenue_value_of_sale?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_scheduled_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  inbound_set_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventUpdateManyWithoutOrganizationInput>;
  InboundConciergeWidget?: InputMaybe<InboundConciergeWidgetUpdateManyWithoutOrganizationInput>;
  industry_options?: InputMaybe<IndustryOptionUpdateManyWithoutOrganizationInput>;
  initial_sdr_sets_reps_day?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  integration_error_logs?: InputMaybe<IntegrationErrorLogUpdateManyWithoutOrganizationInput>;
  integration_mappings?: InputMaybe<IntegrationMappingUpdateManyWithoutOrganizationInput>;
  IntegrationMappingCustomObject?: InputMaybe<IntegrationMappingCustomObjectUpdateManyWithoutOrganizationInput>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogUpdateManyWithoutOrganizationInput>;
  IntegrationReadOnlyObject?: InputMaybe<IntegrationReadOnlyObjectUpdateManyWithoutOrganizationInput>;
  international?: InputMaybe<BoolFieldUpdateOperationsInput>;
  is_email_domain_verified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  is_salesforce_sandbox?: InputMaybe<BoolFieldUpdateOperationsInput>;
  last_lead_value_compute_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutOrganizationInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryUpdateManyWithoutOrganizationInput>;
  lead_intent?: InputMaybe<LeadIntentUpdateManyWithoutOrganizationInput>;
  lead_release_same_rep_delay_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  lead_routing_email_list?: InputMaybe<OrganizationUpdatelead_Routing_Email_ListInput>;
  lead_routing_rules?: InputMaybe<RuleUpdateManyWithoutOrganizationInput>;
  LeadCustomObjectRowAssociation?: InputMaybe<LeadCustomObjectRowAssociationUpdateManyWithoutOrganizationInput>;
  LeadFieldMultiplier?: InputMaybe<LeadFieldMultiplierUpdateManyWithoutOrganizationInput>;
  LeadFieldMultiplierCustom?: InputMaybe<LeadFieldMultiplierCustomUpdateManyWithoutOrganizationInput>;
  leads?: InputMaybe<LeadUpdateManyWithoutOrganizationInput>;
  leadsource_options?: InputMaybe<LeadSourceOptionUpdateManyWithoutOrganizationInput>;
  LeadValue?: InputMaybe<LeadValueUpdateManyWithoutOrganizationInput>;
  LifeTimeValueMultiplier?: InputMaybe<LifeTimeValueMultiplierUpdateManyWithoutOrganizationInput>;
  LiveTransferSetting?: InputMaybe<LiveTransferSettingUpdateOneWithoutOrganizationInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusUpdateManyWithoutOrganizationInput>;
  ltv_multiplier_custom_fields?: InputMaybe<LifeTimeValueMultiplierCustomUpdateManyWithoutOrganizationInput>;
  made_contact_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  make_sale_address_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_business_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_city_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_country_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_first_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_industry_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_last_name_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_lead_source_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_primary_email_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_primary_phone_number_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_state_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_sub_industry_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  make_sale_zip_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  maxium_day_lag_set_to_schedule?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  mrr?: InputMaybe<IntFieldUpdateOperationsInput>;
  mrr_label?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  mrr_required_on_sale?: InputMaybe<BoolFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  NoteItems?: InputMaybe<NoteItemUpdateManyWithoutOrganizationInput>;
  Notifications?: InputMaybe<NotificationUpdateManyWithoutOrganizationInput>;
  onboardComplete?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  Opportunity?: InputMaybe<SfOpportunityUpdateManyWithoutOrganizationInput>;
  organization_holidays?: InputMaybe<OrganizationHolidayUpdateManyWithoutOrganizationInput>;
  organization_hours?: InputMaybe<OrganizationHourUpdateManyWithoutOrganizationInput>;
  OrganizationPaymentSetting?: InputMaybe<OrganizationPaymentSettingsUpdateOneWithoutOrganizationInput>;
  OrganizationPaymentSettings?: InputMaybe<OrganizationPaymentSettingsUpdateOneWithoutOrganizationsInput>;
  orgCalendarInviteMetadata?: InputMaybe<OrgCalendarInviteMetadataUpdateManyWithoutOrganizationInput>;
  OrgDefaultPipelinePerecentValue?: InputMaybe<OrgDefaultPipelinePerecentValueUpdateOneWithoutOrganizationInput>;
  OrgHubSpotSync?: InputMaybe<OrgHubSpotSyncUpdateOneWithoutOrganizationInput>;
  OrgNoEventDate?: InputMaybe<OrgNoEventDateUpdateManyWithoutOrganizationInput>;
  OrgSalesforceSync?: InputMaybe<OrgSalesforceSyncUpdateOneWithoutOrganizationInput>;
  OrgTimeslotAvailability?: InputMaybe<OrgTimeslotAvailabilityUpdateManyWithoutOrganizationInput>;
  outbound_close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_dm_connect_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_lt_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_revenue_value_of_sale?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_scheduled_hold_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  outbound_set_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  PandadocData?: InputMaybe<PandadocDataUpdateOneWithoutOrganizationInput>;
  PandaDocTemplate?: InputMaybe<PandaDocTemplateUpdateManyWithoutOrganizationInput>;
  participant_jitter_buffer_size?: InputMaybe<EnumJitterBufferSizeFieldUpdateOperationsInput>;
  preferred_goal_setting?: InputMaybe<NullableEnumGoalsettingFieldUpdateOperationsInput>;
  prepayment?: InputMaybe<BoolFieldUpdateOperationsInput>;
  ProductChangeLog?: InputMaybe<ProductChangeLogUpdateManyWithoutOrganizationInput>;
  products?: InputMaybe<ProductUpdateManyWithoutOrganizationInput>;
  re_cold_call_hour_delay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  recordingStatus?: InputMaybe<EnumRecordingStatusFieldUpdateOperationsInput>;
  release_lead_resting?: InputMaybe<IntFieldUpdateOperationsInput>;
  rep_claim_limit?: InputMaybe<IntFieldUpdateOperationsInput>;
  rep_lead_allotment?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  rep_level_call_transfer?: InputMaybe<BoolFieldUpdateOperationsInput>;
  reps_can_bulk_unassign?: InputMaybe<BoolFieldUpdateOperationsInput>;
  reps_can_view_all_notes?: InputMaybe<BoolFieldUpdateOperationsInput>;
  require_sale_notes?: InputMaybe<BoolFieldUpdateOperationsInput>;
  resting_after_skipped_CC?: InputMaybe<IntFieldUpdateOperationsInput>;
  resting_period?: InputMaybe<IntFieldUpdateOperationsInput>;
  resting_period_after_unsuccessful_cold_call?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  resting_period_after_unsuccessful_demo?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  revenue_plan?: InputMaybe<RevenuePlanItemUpdateManyWithoutOrganizationInput>;
  Routings?: InputMaybe<RoutingUpdateManyWithoutOrganizationInput>;
  sale_field_options?: InputMaybe<SaleFieldOptionUpdateManyWithoutOrganizationInput>;
  sale_notification_email_list?: InputMaybe<OrganizationUpdatesale_Notification_Email_ListInput>;
  SaleConfig?: InputMaybe<SaleConfigUpdateOneWithoutOrganizationInput>;
  SaleConfigPage?: InputMaybe<SaleConfigPageUpdateManyWithoutOrganizationInput>;
  SaleConfigSection?: InputMaybe<SaleConfigSectionUpdateManyWithoutOrganizationInput>;
  SaleCycles?: InputMaybe<SaleCycleUpdateManyWithoutOrganizationInput>;
  SaleItems?: InputMaybe<SaleItemUpdateManyWithoutOrganizationInput>;
  sales?: InputMaybe<SaleUpdateManyWithoutOrganizationInput>;
  salesforce_domain?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  salesforce_last_sync_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  SalesForceColumn?: InputMaybe<SalesForceColumnUpdateManyWithoutOrganizationInput>;
  salesForceData?: InputMaybe<SalesForceDataUpdateOneWithoutOrganizationInput>;
  ScheduleItems?: InputMaybe<ScheduleItemUpdateManyWithoutOrganizationInput>;
  self_sourcing_rule?: InputMaybe<IntFieldUpdateOperationsInput>;
  send_error_log_email?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  send_lead_routing_email?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sendgrid_email_domain?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sendgrid_email_domain_id?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  sequence?: InputMaybe<SequenceUpdateManyWithoutOrganzationInput>;
  SequenceCriteriaConditions?: InputMaybe<SequenceCriteriaConditionUpdateManyWithoutOrganizationInput>;
  SequenceDashboardViews?: InputMaybe<SequenceDashboardViewUpdateManyWithoutOrganizationInput>;
  SequenceEntryCriterias?: InputMaybe<SequenceEntryCriteriaUpdateManyWithoutOrganizationInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutOrganizationInput>;
  SequenceSteps?: InputMaybe<SequenceStepUpdateManyWithoutOrganizationInput>;
  set_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
  short_resting?: InputMaybe<IntFieldUpdateOperationsInput>;
  show_calendar_options?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_contract_duration_sales?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_lead_source_to_rep?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_nocontact_button?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_payment_terms_sales?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_rep_all_leads?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_rep_my_leads?: InputMaybe<BoolFieldUpdateOperationsInput>;
  show_system_view?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sites?: InputMaybe<SiteUpdateManyWithoutOrganizationInput>;
  sms_opt_in_dialog?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SMSItems?: InputMaybe<SmsItemUpdateManyWithoutOrganizationInput>;
  SnoozeTrackings?: InputMaybe<SnoozeTrackingUpdateManyWithoutOrganizationInput>;
  StepActionLogs?: InputMaybe<StepActionLogUpdateManyWithoutOrganizationInput>;
  StepActions?: InputMaybe<StepActionUpdateManyWithoutOrganizationInput>;
  StepConditions?: InputMaybe<StepConditionUpdateManyWithoutOrganizationInput>;
  teams?: InputMaybe<TeamUpdateManyWithoutOrganizationInput>;
  Template?: InputMaybe<TemplateUpdateManyWithoutOrganizationInput>;
  TemplateVersions?: InputMaybe<TemplateVersionUpdateManyWithoutOrganizationInput>;
  timekit_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timezone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transfer_timeout?: InputMaybe<IntFieldUpdateOperationsInput>;
  TransferAttempts?: InputMaybe<TransferAttemptUpdateManyWithoutOrganizationInput>;
  twilio_numbers?: InputMaybe<TwilioNumberUpdateManyWithoutOrganizationInput>;
  TwilioFeedbacks?: InputMaybe<TwilioFeedbackUpdateManyWithoutOrganizationInput>;
  TwilioInfo?: InputMaybe<TwilioInfoUpdateOneWithoutOrganzationInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  use_revenue_planning?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  UserCustomFieldFilters?: InputMaybe<UserCustomFieldFilterUpdateManyWithoutOrganizationInput>;
  win_rep_day_goal?: InputMaybe<FloatFieldUpdateOperationsInput>;
};

export type OrganizationUpdateWithWhereUniqueWithoutOrganizationPaymentSettingsInput = {
  data: OrganizationUpdateWithoutOrganizationPaymentSettingsInput;
  where: OrganizationWhereUniqueInput;
};

export type OrganizationUpdateWithWhereUniqueWithoutSaleConfigInput = {
  data: OrganizationUpdateWithoutSaleConfigInput;
  where: OrganizationWhereUniqueInput;
};

export type OrganizationUpsertWithoutAssociationHistoriesInput = {
  create: OrganizationCreateWithoutAssociationHistoriesInput;
  update: OrganizationUpdateWithoutAssociationHistoriesInput;
};

export type OrganizationUpsertWithoutAutomated_CampaignsInput = {
  create: OrganizationCreateWithoutAutomated_CampaignsInput;
  update: OrganizationUpdateWithoutAutomated_CampaignsInput;
};

export type OrganizationUpsertWithoutAutoTransferSelectionsInput = {
  create: OrganizationCreateWithoutAutoTransferSelectionsInput;
  update: OrganizationUpdateWithoutAutoTransferSelectionsInput;
};

export type OrganizationUpsertWithoutAvailablitiesInput = {
  create: OrganizationCreateWithoutAvailablitiesInput;
  update: OrganizationUpdateWithoutAvailablitiesInput;
};

export type OrganizationUpsertWithoutBest_MetricsInput = {
  create: OrganizationCreateWithoutBest_MetricsInput;
  update: OrganizationUpdateWithoutBest_MetricsInput;
};

export type OrganizationUpsertWithoutCallMeNowAttemptsInput = {
  create: OrganizationCreateWithoutCallMeNowAttemptsInput;
  update: OrganizationUpdateWithoutCallMeNowAttemptsInput;
};

export type OrganizationUpsertWithoutCallMeNowsInput = {
  create: OrganizationCreateWithoutCallMeNowsInput;
  update: OrganizationUpdateWithoutCallMeNowsInput;
};

export type OrganizationUpsertWithoutCallSharingsInput = {
  create: OrganizationCreateWithoutCallSharingsInput;
  update: OrganizationUpdateWithoutCallSharingsInput;
};

export type OrganizationUpsertWithoutConcessionInput = {
  create: OrganizationCreateWithoutConcessionInput;
  update: OrganizationUpdateWithoutConcessionInput;
};

export type OrganizationUpsertWithoutConferencesInput = {
  create: OrganizationCreateWithoutConferencesInput;
  update: OrganizationUpdateWithoutConferencesInput;
};

export type OrganizationUpsertWithoutContactRequestsInput = {
  create: OrganizationCreateWithoutContactRequestsInput;
  update: OrganizationUpdateWithoutContactRequestsInput;
};

export type OrganizationUpsertWithoutContactsInput = {
  create: OrganizationCreateWithoutContactsInput;
  update: OrganizationUpdateWithoutContactsInput;
};

export type OrganizationUpsertWithoutCustom_FieldsInput = {
  create: OrganizationCreateWithoutCustom_FieldsInput;
  update: OrganizationUpdateWithoutCustom_FieldsInput;
};

export type OrganizationUpsertWithoutCustomObjectFieldForeignKeyInput = {
  create: OrganizationCreateWithoutCustomObjectFieldForeignKeyInput;
  update: OrganizationUpdateWithoutCustomObjectFieldForeignKeyInput;
};

export type OrganizationUpsertWithoutCustomObjectFieldInput = {
  create: OrganizationCreateWithoutCustomObjectFieldInput;
  update: OrganizationUpdateWithoutCustomObjectFieldInput;
};

export type OrganizationUpsertWithoutCustomObjectInput = {
  create: OrganizationCreateWithoutCustomObjectInput;
  update: OrganizationUpdateWithoutCustomObjectInput;
};

export type OrganizationUpsertWithoutCustomObjectRecordInput = {
  create: OrganizationCreateWithoutCustomObjectRecordInput;
  update: OrganizationUpdateWithoutCustomObjectRecordInput;
};

export type OrganizationUpsertWithoutCustomObjectRowInput = {
  create: OrganizationCreateWithoutCustomObjectRowInput;
  update: OrganizationUpdateWithoutCustomObjectRowInput;
};

export type OrganizationUpsertWithoutCustomQueueItemsInput = {
  create: OrganizationCreateWithoutCustomQueueItemsInput;
  update: OrganizationUpdateWithoutCustomQueueItemsInput;
};

export type OrganizationUpsertWithoutDashboardColumnOptionsInput = {
  create: OrganizationCreateWithoutDashboardColumnOptionsInput;
  update: OrganizationUpdateWithoutDashboardColumnOptionsInput;
};

export type OrganizationUpsertWithoutDashboardLeadFiltersInput = {
  create: OrganizationCreateWithoutDashboardLeadFiltersInput;
  update: OrganizationUpdateWithoutDashboardLeadFiltersInput;
};

export type OrganizationUpsertWithoutDashboardRepFiltersInput = {
  create: OrganizationCreateWithoutDashboardRepFiltersInput;
  update: OrganizationUpdateWithoutDashboardRepFiltersInput;
};

export type OrganizationUpsertWithoutDashboardSavedViewInput = {
  create: OrganizationCreateWithoutDashboardSavedViewInput;
  update: OrganizationUpdateWithoutDashboardSavedViewInput;
};

export type OrganizationUpsertWithoutDispositionMultiplierInput = {
  create: OrganizationCreateWithoutDispositionMultiplierInput;
  update: OrganizationUpdateWithoutDispositionMultiplierInput;
};

export type OrganizationUpsertWithoutDispositionsInput = {
  create: OrganizationCreateWithoutDispositionsInput;
  update: OrganizationUpdateWithoutDispositionsInput;
};

export type OrganizationUpsertWithoutEmailItemsInput = {
  create: OrganizationCreateWithoutEmailItemsInput;
  update: OrganizationUpdateWithoutEmailItemsInput;
};

export type OrganizationUpsertWithoutEmailSyncRecordInput = {
  create: OrganizationCreateWithoutEmailSyncRecordInput;
  update: OrganizationUpdateWithoutEmailSyncRecordInput;
};

export type OrganizationUpsertWithoutEmailThreadsInput = {
  create: OrganizationCreateWithoutEmailThreadsInput;
  update: OrganizationUpdateWithoutEmailThreadsInput;
};

export type OrganizationUpsertWithoutFolderPermissionsInput = {
  create: OrganizationCreateWithoutFolderPermissionsInput;
  update: OrganizationUpdateWithoutFolderPermissionsInput;
};

export type OrganizationUpsertWithoutFoldersInput = {
  create: OrganizationCreateWithoutFoldersInput;
  update: OrganizationUpdateWithoutFoldersInput;
};

export type OrganizationUpsertWithoutGlobalTransferRuleInput = {
  create: OrganizationCreateWithoutGlobalTransferRuleInput;
  update: OrganizationUpdateWithoutGlobalTransferRuleInput;
};

export type OrganizationUpsertWithoutHingePointMultiplierInput = {
  create: OrganizationCreateWithoutHingePointMultiplierInput;
  update: OrganizationUpdateWithoutHingePointMultiplierInput;
};

export type OrganizationUpsertWithoutInboundConciergeEventsInput = {
  create: OrganizationCreateWithoutInboundConciergeEventsInput;
  update: OrganizationUpdateWithoutInboundConciergeEventsInput;
};

export type OrganizationUpsertWithoutIntegration_Error_LogsInput = {
  create: OrganizationCreateWithoutIntegration_Error_LogsInput;
  update: OrganizationUpdateWithoutIntegration_Error_LogsInput;
};

export type OrganizationUpsertWithoutIntegrationOperationLogInput = {
  create: OrganizationCreateWithoutIntegrationOperationLogInput;
  update: OrganizationUpdateWithoutIntegrationOperationLogInput;
};

export type OrganizationUpsertWithoutLead_ActivitiesInput = {
  create: OrganizationCreateWithoutLead_ActivitiesInput;
  update: OrganizationUpdateWithoutLead_ActivitiesInput;
};

export type OrganizationUpsertWithoutLead_Import_HistoryInput = {
  create: OrganizationCreateWithoutLead_Import_HistoryInput;
  update: OrganizationUpdateWithoutLead_Import_HistoryInput;
};

export type OrganizationUpsertWithoutLead_IntentInput = {
  create: OrganizationCreateWithoutLead_IntentInput;
  update: OrganizationUpdateWithoutLead_IntentInput;
};

export type OrganizationUpsertWithoutLead_Routing_RulesInput = {
  create: OrganizationCreateWithoutLead_Routing_RulesInput;
  update: OrganizationUpdateWithoutLead_Routing_RulesInput;
};

export type OrganizationUpsertWithoutLeadCustomObjectRowAssociationInput = {
  create: OrganizationCreateWithoutLeadCustomObjectRowAssociationInput;
  update: OrganizationUpdateWithoutLeadCustomObjectRowAssociationInput;
};

export type OrganizationUpsertWithoutLeadFieldMultiplierCustomInput = {
  create: OrganizationCreateWithoutLeadFieldMultiplierCustomInput;
  update: OrganizationUpdateWithoutLeadFieldMultiplierCustomInput;
};

export type OrganizationUpsertWithoutLeadFieldMultiplierInput = {
  create: OrganizationCreateWithoutLeadFieldMultiplierInput;
  update: OrganizationUpdateWithoutLeadFieldMultiplierInput;
};

export type OrganizationUpsertWithoutLeadsInput = {
  create: OrganizationCreateWithoutLeadsInput;
  update: OrganizationUpdateWithoutLeadsInput;
};

export type OrganizationUpsertWithoutLeadValueInput = {
  create: OrganizationCreateWithoutLeadValueInput;
  update: OrganizationUpdateWithoutLeadValueInput;
};

export type OrganizationUpsertWithoutLiveUserStatusInput = {
  create: OrganizationCreateWithoutLiveUserStatusInput;
  update: OrganizationUpdateWithoutLiveUserStatusInput;
};

export type OrganizationUpsertWithoutNoteItemsInput = {
  create: OrganizationCreateWithoutNoteItemsInput;
  update: OrganizationUpdateWithoutNoteItemsInput;
};

export type OrganizationUpsertWithoutNotificationsInput = {
  create: OrganizationCreateWithoutNotificationsInput;
  update: OrganizationUpdateWithoutNotificationsInput;
};

export type OrganizationUpsertWithoutOrganizationPaymentSettingInput = {
  create: OrganizationCreateWithoutOrganizationPaymentSettingInput;
  update: OrganizationUpdateWithoutOrganizationPaymentSettingInput;
};

export type OrganizationUpsertWithoutProductChangeLogInput = {
  create: OrganizationCreateWithoutProductChangeLogInput;
  update: OrganizationUpdateWithoutProductChangeLogInput;
};

export type OrganizationUpsertWithoutProductsInput = {
  create: OrganizationCreateWithoutProductsInput;
  update: OrganizationUpdateWithoutProductsInput;
};

export type OrganizationUpsertWithoutRoutingsInput = {
  create: OrganizationCreateWithoutRoutingsInput;
  update: OrganizationUpdateWithoutRoutingsInput;
};

export type OrganizationUpsertWithoutSaleConfigPageInput = {
  create: OrganizationCreateWithoutSaleConfigPageInput;
  update: OrganizationUpdateWithoutSaleConfigPageInput;
};

export type OrganizationUpsertWithoutSaleConfigSectionInput = {
  create: OrganizationCreateWithoutSaleConfigSectionInput;
  update: OrganizationUpdateWithoutSaleConfigSectionInput;
};

export type OrganizationUpsertWithoutSaleCyclesInput = {
  create: OrganizationCreateWithoutSaleCyclesInput;
  update: OrganizationUpdateWithoutSaleCyclesInput;
};

export type OrganizationUpsertWithoutSaleItemsInput = {
  create: OrganizationCreateWithoutSaleItemsInput;
  update: OrganizationUpdateWithoutSaleItemsInput;
};

export type OrganizationUpsertWithoutSalesInput = {
  create: OrganizationCreateWithoutSalesInput;
  update: OrganizationUpdateWithoutSalesInput;
};

export type OrganizationUpsertWithoutScheduleItemsInput = {
  create: OrganizationCreateWithoutScheduleItemsInput;
  update: OrganizationUpdateWithoutScheduleItemsInput;
};

export type OrganizationUpsertWithoutSequenceCriteriaConditionsInput = {
  create: OrganizationCreateWithoutSequenceCriteriaConditionsInput;
  update: OrganizationUpdateWithoutSequenceCriteriaConditionsInput;
};

export type OrganizationUpsertWithoutSequenceDashboardViewsInput = {
  create: OrganizationCreateWithoutSequenceDashboardViewsInput;
  update: OrganizationUpdateWithoutSequenceDashboardViewsInput;
};

export type OrganizationUpsertWithoutSequenceEntryCriteriasInput = {
  create: OrganizationCreateWithoutSequenceEntryCriteriasInput;
  update: OrganizationUpdateWithoutSequenceEntryCriteriasInput;
};

export type OrganizationUpsertWithoutSequenceEntryExitLeadInput = {
  create: OrganizationCreateWithoutSequenceEntryExitLeadInput;
  update: OrganizationUpdateWithoutSequenceEntryExitLeadInput;
};

export type OrganizationUpsertWithoutSequenceInput = {
  create: OrganizationCreateWithoutSequenceInput;
  update: OrganizationUpdateWithoutSequenceInput;
};

export type OrganizationUpsertWithoutSequenceStepsInput = {
  create: OrganizationCreateWithoutSequenceStepsInput;
  update: OrganizationUpdateWithoutSequenceStepsInput;
};

export type OrganizationUpsertWithoutSitesInput = {
  create: OrganizationCreateWithoutSitesInput;
  update: OrganizationUpdateWithoutSitesInput;
};

export type OrganizationUpsertWithoutSmsItemsInput = {
  create: OrganizationCreateWithoutSmsItemsInput;
  update: OrganizationUpdateWithoutSmsItemsInput;
};

export type OrganizationUpsertWithoutSnoozeTrackingsInput = {
  create: OrganizationCreateWithoutSnoozeTrackingsInput;
  update: OrganizationUpdateWithoutSnoozeTrackingsInput;
};

export type OrganizationUpsertWithoutStepActionLogsInput = {
  create: OrganizationCreateWithoutStepActionLogsInput;
  update: OrganizationUpdateWithoutStepActionLogsInput;
};

export type OrganizationUpsertWithoutStepActionsInput = {
  create: OrganizationCreateWithoutStepActionsInput;
  update: OrganizationUpdateWithoutStepActionsInput;
};

export type OrganizationUpsertWithoutStepConditionsInput = {
  create: OrganizationCreateWithoutStepConditionsInput;
  update: OrganizationUpdateWithoutStepConditionsInput;
};

export type OrganizationUpsertWithoutTeamsInput = {
  create: OrganizationCreateWithoutTeamsInput;
  update: OrganizationUpdateWithoutTeamsInput;
};

export type OrganizationUpsertWithoutTemplateInput = {
  create: OrganizationCreateWithoutTemplateInput;
  update: OrganizationUpdateWithoutTemplateInput;
};

export type OrganizationUpsertWithoutTemplateVersionsInput = {
  create: OrganizationCreateWithoutTemplateVersionsInput;
  update: OrganizationUpdateWithoutTemplateVersionsInput;
};

export type OrganizationUpsertWithoutTransferAttemptsInput = {
  create: OrganizationCreateWithoutTransferAttemptsInput;
  update: OrganizationUpdateWithoutTransferAttemptsInput;
};

export type OrganizationUpsertWithoutTwilio_NumbersInput = {
  create: OrganizationCreateWithoutTwilio_NumbersInput;
  update: OrganizationUpdateWithoutTwilio_NumbersInput;
};

export type OrganizationUpsertWithoutTwilioFeedbacksInput = {
  create: OrganizationCreateWithoutTwilioFeedbacksInput;
  update: OrganizationUpdateWithoutTwilioFeedbacksInput;
};

export type OrganizationUpsertWithoutUserCustomFieldFiltersInput = {
  create: OrganizationCreateWithoutUserCustomFieldFiltersInput;
  update: OrganizationUpdateWithoutUserCustomFieldFiltersInput;
};

export type OrganizationUpsertWithoutUsersInput = {
  create: OrganizationCreateWithoutUsersInput;
  update: OrganizationUpdateWithoutUsersInput;
};

export type OrganizationUpsertWithWhereUniqueWithoutOrganizationPaymentSettingsInput = {
  create: OrganizationCreateWithoutOrganizationPaymentSettingsInput;
  update: OrganizationUpdateWithoutOrganizationPaymentSettingsInput;
  where: OrganizationWhereUniqueInput;
};

export type OrganizationUpsertWithWhereUniqueWithoutSaleConfigInput = {
  create: OrganizationCreateWithoutSaleConfigInput;
  update: OrganizationUpdateWithoutSaleConfigInput;
  where: OrganizationWhereUniqueInput;
};

export type OrganizationWhereInput = {
  ActivityMonitorMultiplier?: InputMaybe<ActivityMonitorMultiplierListRelationFilter>;
  algoFactor?: InputMaybe<AlgoFactorListRelationFilter>;
  allow_calendar_invite?: InputMaybe<BoolFilter>;
  allow_remove_step_action?: InputMaybe<BoolFilter>;
  allow_snooze_step_action?: InputMaybe<BoolFilter>;
  always_record?: InputMaybe<BoolFilter>;
  AND?: InputMaybe<Array<OrganizationWhereInput>>;
  arv_initial_held_demos_generated_by_sdr_weight?: InputMaybe<FloatNullableFilter>;
  AssociationHistories?: InputMaybe<AssociationHistoryListRelationFilter>;
  auto_call_interval?: InputMaybe<IntFilter>;
  auto_dial?: InputMaybe<BoolFilter>;
  automated_campaigns?: InputMaybe<AutomatedCampaignListRelationFilter>;
  automatic_call_back_delay?: InputMaybe<FloatNullableFilter>;
  AutoTransferSelections?: InputMaybe<AutoTransferSelectionListRelationFilter>;
  Availablities?: InputMaybe<AvailabilityListRelationFilter>;
  AverageOrderValue?: InputMaybe<AverageOrderValueListRelationFilter>;
  best_action_ever_multiplier?: InputMaybe<FloatFilter>;
  best_metrics?: InputMaybe<BestMetricRecordListRelationFilter>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptListRelationFilter>;
  callMeNows?: InputMaybe<CallMeNowListRelationFilter>;
  CallMultiplier?: InputMaybe<CallMultiplierListRelationFilter>;
  CallSharings?: InputMaybe<CallSharingListRelationFilter>;
  can_edit_sdr_transfer_rules?: InputMaybe<BoolFilter>;
  claim_rule?: InputMaybe<IntFilter>;
  concession?: InputMaybe<ConcessionListRelationFilter>;
  conference_region?: InputMaybe<EnumConferenceRegionFilter>;
  Conferences?: InputMaybe<ConferenceListRelationFilter>;
  ContactRequests?: InputMaybe<ContactRequestListRelationFilter>;
  Contacts?: InputMaybe<ContactListRelationFilter>;
  created_at?: InputMaybe<DateTimeFilter>;
  custom_fields?: InputMaybe<CustomFieldListRelationFilter>;
  CustomObject?: InputMaybe<CustomObjectListRelationFilter>;
  CustomObjectField?: InputMaybe<CustomObjectFieldListRelationFilter>;
  CustomObjectFieldForeignKey?: InputMaybe<CustomObjectFieldForeignKeyListRelationFilter>;
  CustomObjectRecord?: InputMaybe<CustomObjectRecordListRelationFilter>;
  CustomObjectRow?: InputMaybe<CustomObjectRowListRelationFilter>;
  CustomQueueItems?: InputMaybe<CustomQueueItemListRelationFilter>;
  DashboardColumnOptions?: InputMaybe<DashboardColumnOptionListRelationFilter>;
  DashboardLeadFilters?: InputMaybe<DashboardLeadFilterListRelationFilter>;
  DashboardRepFilters?: InputMaybe<DashboardRepFilterListRelationFilter>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewListRelationFilter>;
  day_to_close?: InputMaybe<IntFilter>;
  default_demo_time_duration?: InputMaybe<IntNullableFilter>;
  demo_held_call_back_delay?: InputMaybe<FloatNullableFilter>;
  demo_not_held_call_back_delay?: InputMaybe<FloatNullableFilter>;
  demo_not_held_call_back_short_delay?: InputMaybe<FloatNullableFilter>;
  demo_prep_time_minutes?: InputMaybe<IntFilter>;
  dev_account?: InputMaybe<BoolNullableFilter>;
  dial_rule?: InputMaybe<IntFilter>;
  DispositionMultiplier?: InputMaybe<DispositionMultiplierListRelationFilter>;
  dispositions?: InputMaybe<DispositionListRelationFilter>;
  double_booking?: InputMaybe<BoolFilter>;
  dynamic_date_operators?: InputMaybe<BoolFilter>;
  edge_servers?: InputMaybe<StringNullableListFilter>;
  email_conflict_notification_email_list?: InputMaybe<StringNullableListFilter>;
  EmailItems?: InputMaybe<EmailItemListRelationFilter>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordListRelationFilter>;
  EmailThreads?: InputMaybe<EmailThreadListRelationFilter>;
  enable_modified_metrics?: InputMaybe<BoolNullableFilter>;
  enable_pay_period_view?: InputMaybe<BoolNullableFilter>;
  enable_time_constraint?: InputMaybe<BoolNullableFilter>;
  error_log_email_address?: InputMaybe<StringNullableListFilter>;
  estimated_average_num_of_weekly_overbooked_demo_slots?: InputMaybe<IntNullableFilter>;
  FolderPermissions?: InputMaybe<FolderPermissionListRelationFilter>;
  Folders?: InputMaybe<FolderListRelationFilter>;
  FutureBookingSetting?: InputMaybe<FutureBookingSettingWhereInput>;
  GlobalTransferRule?: InputMaybe<GlobalTransferRuleListRelationFilter>;
  held_demos_per_cc_connect?: InputMaybe<FloatNullableFilter>;
  helpful_links?: InputMaybe<StringNullableListFilter>;
  HingePointMultiplier?: InputMaybe<HingePointMultiplierListRelationFilter>;
  hold_goal?: InputMaybe<FloatFilter>;
  hubspot_credential?: InputMaybe<HubSpotCredentialWhereInput>;
  hubspot_last_sync_date?: InputMaybe<DateTimeNullableFilter>;
  hubspot_white_list?: InputMaybe<HubSpotWhitelistListRelationFilter>;
  id?: InputMaybe<StringFilter>;
  inbound_close_rate?: InputMaybe<FloatNullableFilter>;
  inbound_dm_connect_rate?: InputMaybe<FloatNullableFilter>;
  inbound_lt_hold_rate?: InputMaybe<FloatNullableFilter>;
  inbound_revenue_value_of_sale?: InputMaybe<FloatNullableFilter>;
  inbound_scheduled_hold_rate?: InputMaybe<FloatNullableFilter>;
  inbound_set_rate?: InputMaybe<FloatNullableFilter>;
  InboundConciergeEvents?: InputMaybe<InboundConciergeEventListRelationFilter>;
  InboundConciergeWidget?: InputMaybe<InboundConciergeWidgetListRelationFilter>;
  industry_options?: InputMaybe<IndustryOptionListRelationFilter>;
  initial_sdr_sets_reps_day?: InputMaybe<FloatNullableFilter>;
  integration_error_logs?: InputMaybe<IntegrationErrorLogListRelationFilter>;
  integration_mappings?: InputMaybe<IntegrationMappingListRelationFilter>;
  IntegrationMappingCustomObject?: InputMaybe<IntegrationMappingCustomObjectListRelationFilter>;
  IntegrationOperationLog?: InputMaybe<IntegrationOperationLogListRelationFilter>;
  IntegrationReadOnlyObject?: InputMaybe<IntegrationReadOnlyObjectListRelationFilter>;
  international?: InputMaybe<BoolFilter>;
  is_email_domain_verified?: InputMaybe<BoolFilter>;
  is_salesforce_sandbox?: InputMaybe<BoolFilter>;
  last_lead_value_compute_time?: InputMaybe<DateTimeNullableFilter>;
  lead_activities?: InputMaybe<LeadActivityListRelationFilter>;
  lead_import_history?: InputMaybe<LeadImportHistoryListRelationFilter>;
  lead_intent?: InputMaybe<LeadIntentListRelationFilter>;
  lead_release_same_rep_delay_rule?: InputMaybe<IntFilter>;
  lead_routing_email_list?: InputMaybe<StringNullableListFilter>;
  lead_routing_rules?: InputMaybe<RuleListRelationFilter>;
  LeadCustomObjectRowAssociation?: InputMaybe<LeadCustomObjectRowAssociationListRelationFilter>;
  LeadFieldMultiplier?: InputMaybe<LeadFieldMultiplierListRelationFilter>;
  LeadFieldMultiplierCustom?: InputMaybe<LeadFieldMultiplierCustomListRelationFilter>;
  leads?: InputMaybe<LeadListRelationFilter>;
  leadsource_options?: InputMaybe<LeadSourceOptionListRelationFilter>;
  LeadValue?: InputMaybe<LeadValueListRelationFilter>;
  LifeTimeValueMultiplier?: InputMaybe<LifeTimeValueMultiplierListRelationFilter>;
  LiveTransferSetting?: InputMaybe<LiveTransferSettingWhereInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusListRelationFilter>;
  ltv_multiplier_custom_fields?: InputMaybe<LifeTimeValueMultiplierCustomListRelationFilter>;
  made_contact_rule?: InputMaybe<IntFilter>;
  make_sale_address_required?: InputMaybe<BoolFilter>;
  make_sale_business_name_required?: InputMaybe<BoolFilter>;
  make_sale_city_required?: InputMaybe<BoolFilter>;
  make_sale_country_required?: InputMaybe<BoolFilter>;
  make_sale_first_name_required?: InputMaybe<BoolFilter>;
  make_sale_industry_required?: InputMaybe<BoolFilter>;
  make_sale_last_name_required?: InputMaybe<BoolFilter>;
  make_sale_lead_source_required?: InputMaybe<BoolFilter>;
  make_sale_primary_email_required?: InputMaybe<BoolFilter>;
  make_sale_primary_phone_number_required?: InputMaybe<BoolFilter>;
  make_sale_state_required?: InputMaybe<BoolFilter>;
  make_sale_sub_industry_required?: InputMaybe<BoolFilter>;
  make_sale_zip_required?: InputMaybe<BoolFilter>;
  maxium_day_lag_set_to_schedule?: InputMaybe<IntNullableFilter>;
  mrr?: InputMaybe<IntFilter>;
  mrr_label?: InputMaybe<StringNullableFilter>;
  mrr_required_on_sale?: InputMaybe<BoolFilter>;
  name?: InputMaybe<StringNullableFilter>;
  NOT?: InputMaybe<Array<OrganizationWhereInput>>;
  NoteItems?: InputMaybe<NoteItemListRelationFilter>;
  Notifications?: InputMaybe<NotificationListRelationFilter>;
  onboardComplete?: InputMaybe<BoolNullableFilter>;
  Opportunity?: InputMaybe<SfOpportunityListRelationFilter>;
  OR?: InputMaybe<Array<OrganizationWhereInput>>;
  organization_holidays?: InputMaybe<OrganizationHolidayListRelationFilter>;
  organization_hours?: InputMaybe<OrganizationHourListRelationFilter>;
  OrganizationPaymentSetting?: InputMaybe<OrganizationPaymentSettingsWhereInput>;
  OrganizationPaymentSettings?: InputMaybe<OrganizationPaymentSettingsWhereInput>;
  orgCalendarInviteMetadata?: InputMaybe<OrgCalendarInviteMetadataListRelationFilter>;
  OrgDefaultPipelinePerecentValue?: InputMaybe<OrgDefaultPipelinePerecentValueWhereInput>;
  OrgHubSpotSync?: InputMaybe<OrgHubSpotSyncWhereInput>;
  OrgNoEventDate?: InputMaybe<OrgNoEventDateListRelationFilter>;
  OrgSalesforceSync?: InputMaybe<OrgSalesforceSyncWhereInput>;
  OrgTimeslotAvailability?: InputMaybe<OrgTimeslotAvailabilityListRelationFilter>;
  outbound_close_rate?: InputMaybe<FloatNullableFilter>;
  outbound_dm_connect_rate?: InputMaybe<FloatNullableFilter>;
  outbound_lt_hold_rate?: InputMaybe<FloatNullableFilter>;
  outbound_revenue_value_of_sale?: InputMaybe<FloatNullableFilter>;
  outbound_scheduled_hold_rate?: InputMaybe<FloatNullableFilter>;
  outbound_set_rate?: InputMaybe<FloatNullableFilter>;
  PandadocData?: InputMaybe<PandadocDataWhereInput>;
  PandaDocTemplate?: InputMaybe<PandaDocTemplateListRelationFilter>;
  participant_jitter_buffer_size?: InputMaybe<EnumJitterBufferSizeFilter>;
  payment_config_id?: InputMaybe<StringNullableFilter>;
  preferred_goal_setting?: InputMaybe<EnumGoalsettingNullableFilter>;
  prepayment?: InputMaybe<BoolFilter>;
  ProductChangeLog?: InputMaybe<ProductChangeLogListRelationFilter>;
  products?: InputMaybe<ProductListRelationFilter>;
  re_cold_call_hour_delay?: InputMaybe<FloatNullableFilter>;
  recordingStatus?: InputMaybe<EnumRecordingStatusFilter>;
  release_lead_resting?: InputMaybe<IntFilter>;
  rep_claim_limit?: InputMaybe<IntFilter>;
  rep_lead_allotment?: InputMaybe<IntNullableFilter>;
  rep_level_call_transfer?: InputMaybe<BoolFilter>;
  reps_can_bulk_unassign?: InputMaybe<BoolFilter>;
  reps_can_view_all_notes?: InputMaybe<BoolFilter>;
  require_sale_notes?: InputMaybe<BoolFilter>;
  resting_after_skipped_CC?: InputMaybe<IntFilter>;
  resting_period?: InputMaybe<IntFilter>;
  resting_period_after_unsuccessful_cold_call?: InputMaybe<FloatNullableFilter>;
  resting_period_after_unsuccessful_demo?: InputMaybe<FloatNullableFilter>;
  revenue_plan?: InputMaybe<RevenuePlanItemListRelationFilter>;
  Routings?: InputMaybe<RoutingListRelationFilter>;
  sale_config_id?: InputMaybe<StringNullableFilter>;
  sale_field_options?: InputMaybe<SaleFieldOptionListRelationFilter>;
  sale_notification_email_list?: InputMaybe<StringNullableListFilter>;
  SaleConfig?: InputMaybe<SaleConfigWhereInput>;
  SaleConfigPage?: InputMaybe<SaleConfigPageListRelationFilter>;
  SaleConfigSection?: InputMaybe<SaleConfigSectionListRelationFilter>;
  SaleCycles?: InputMaybe<SaleCycleListRelationFilter>;
  SaleItems?: InputMaybe<SaleItemListRelationFilter>;
  sales?: InputMaybe<SaleListRelationFilter>;
  salesforce_domain?: InputMaybe<StringNullableFilter>;
  salesforce_last_sync_date?: InputMaybe<DateTimeNullableFilter>;
  SalesForceColumn?: InputMaybe<SalesForceColumnListRelationFilter>;
  salesForceData?: InputMaybe<SalesForceDataWhereInput>;
  ScheduleItems?: InputMaybe<ScheduleItemListRelationFilter>;
  self_sourcing_rule?: InputMaybe<IntFilter>;
  send_error_log_email?: InputMaybe<BoolNullableFilter>;
  send_lead_routing_email?: InputMaybe<BoolFilter>;
  sendgrid_email_domain?: InputMaybe<StringNullableFilter>;
  sendgrid_email_domain_id?: InputMaybe<IntNullableFilter>;
  sequence?: InputMaybe<SequenceListRelationFilter>;
  SequenceCriteriaConditions?: InputMaybe<SequenceCriteriaConditionListRelationFilter>;
  SequenceDashboardViews?: InputMaybe<SequenceDashboardViewListRelationFilter>;
  SequenceEntryCriterias?: InputMaybe<SequenceEntryCriteriaListRelationFilter>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadListRelationFilter>;
  SequenceSteps?: InputMaybe<SequenceStepListRelationFilter>;
  set_goal?: InputMaybe<FloatFilter>;
  short_resting?: InputMaybe<IntFilter>;
  show_calendar_options?: InputMaybe<BoolFilter>;
  show_contract_duration_sales?: InputMaybe<BoolFilter>;
  show_lead_source_to_rep?: InputMaybe<BoolFilter>;
  show_nocontact_button?: InputMaybe<BoolFilter>;
  show_payment_terms_sales?: InputMaybe<BoolFilter>;
  show_rep_all_leads?: InputMaybe<BoolFilter>;
  show_rep_my_leads?: InputMaybe<BoolFilter>;
  show_system_view?: InputMaybe<BoolFilter>;
  sites?: InputMaybe<SiteListRelationFilter>;
  sms_opt_in_dialog?: InputMaybe<StringNullableFilter>;
  SMSItems?: InputMaybe<SmsItemListRelationFilter>;
  SnoozeTrackings?: InputMaybe<SnoozeTrackingListRelationFilter>;
  StepActionLogs?: InputMaybe<StepActionLogListRelationFilter>;
  StepActions?: InputMaybe<StepActionListRelationFilter>;
  StepConditions?: InputMaybe<StepConditionListRelationFilter>;
  teams?: InputMaybe<TeamListRelationFilter>;
  Template?: InputMaybe<TemplateListRelationFilter>;
  TemplateVersions?: InputMaybe<TemplateVersionListRelationFilter>;
  timekit_id?: InputMaybe<StringNullableFilter>;
  timezone?: InputMaybe<StringNullableFilter>;
  transfer_timeout?: InputMaybe<IntFilter>;
  TransferAttempts?: InputMaybe<TransferAttemptListRelationFilter>;
  twilio_numbers?: InputMaybe<TwilioNumberListRelationFilter>;
  TwilioFeedbacks?: InputMaybe<TwilioFeedbackListRelationFilter>;
  TwilioInfo?: InputMaybe<TwilioInfoWhereInput>;
  updated_at?: InputMaybe<DateTimeFilter>;
  use_revenue_planning?: InputMaybe<BoolNullableFilter>;
  UserCustomFieldFilters?: InputMaybe<UserCustomFieldFilterListRelationFilter>;
  users?: InputMaybe<UserListRelationFilter>;
  win_rep_day_goal?: InputMaybe<FloatFilter>;
};

export type OrganizationWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']['input']>;
};

/** OrgTimeslotAvailability Input for create / update */
export type OrgAvailabilityUpdateInput = {
  day: Weekday;
  id: Scalars['String']['input'];
  max_booking?: InputMaybe<Scalars['Int']['input']>;
};

export type OrgCalendarInviteMetadata = {
  __typename?: 'OrgCalendarInviteMetadata';
  active?: Maybe<Scalars['Boolean']['output']>;
  created_at: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  duration?: Maybe<Scalars['Int']['output']>;
  id: Scalars['String']['output'];
  location?: Maybe<Scalars['String']['output']>;
  meeting_type: Scalars['String']['output'];
  organization?: Maybe<Organization>;
  organization_id?: Maybe<Scalars['String']['output']>;
  overflow?: Maybe<Scalars['Int']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updated_at: Scalars['DateTime']['output'];
};

export type OrgCalendarInviteMetadataCreateManyOrganizationInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  duration?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  meeting_type: Scalars['String']['input'];
  overflow?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type OrgCalendarInviteMetadataCreateManyOrganizationInputEnvelope = {
  data?: InputMaybe<Array<OrgCalendarInviteMetadataCreateManyOrganizationInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type OrgCalendarInviteMetadataCreateNestedManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<OrgCalendarInviteMetadataWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<OrgCalendarInviteMetadataCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<OrgCalendarInviteMetadataCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<OrgCalendarInviteMetadataCreateManyOrganizationInputEnvelope>;
};

export type OrgCalendarInviteMetadataCreateOrConnectWithoutOrganizationInput = {
  create: OrgCalendarInviteMetadataCreateWithoutOrganizationInput;
  where: OrgCalendarInviteMetadataWhereUniqueInput;
};

export type OrgCalendarInviteMetadataCreateWithoutOrganizationInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  duration?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  meeting_type: Scalars['String']['input'];
  overflow?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type OrgCalendarInviteMetadataListRelationFilter = {
  every?: InputMaybe<OrgCalendarInviteMetadataWhereInput>;
  none?: InputMaybe<OrgCalendarInviteMetadataWhereInput>;
  some?: InputMaybe<OrgCalendarInviteMetadataWhereInput>;
};

export type OrgCalendarInviteMetadataOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
  count?: InputMaybe<SortOrder>;
};

export type OrgCalendarInviteMetadataScalarWhereInput = {
  active?: InputMaybe<BoolNullableFilter>;
  AND?: InputMaybe<Array<OrgCalendarInviteMetadataScalarWhereInput>>;
  created_at?: InputMaybe<DateTimeFilter>;
  description?: InputMaybe<StringNullableFilter>;
  duration?: InputMaybe<IntNullableFilter>;
  id?: InputMaybe<StringFilter>;
  location?: InputMaybe<StringNullableFilter>;
  meeting_type?: InputMaybe<StringFilter>;
  NOT?: InputMaybe<Array<OrgCalendarInviteMetadataScalarWhereInput>>;
  OR?: InputMaybe<Array<OrgCalendarInviteMetadataScalarWhereInput>>;
  organization_id?: InputMaybe<StringNullableFilter>;
  overflow?: InputMaybe<IntNullableFilter>;
  title?: InputMaybe<StringNullableFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
};

export type OrgCalendarInviteMetadataUpdateManyMutationInput = {
  active?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  duration?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  location?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  meeting_type?: InputMaybe<StringFieldUpdateOperationsInput>;
  overflow?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type OrgCalendarInviteMetadataUpdateManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<OrgCalendarInviteMetadataWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<OrgCalendarInviteMetadataCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<OrgCalendarInviteMetadataCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<OrgCalendarInviteMetadataCreateManyOrganizationInputEnvelope>;
  delete?: InputMaybe<Array<OrgCalendarInviteMetadataWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<OrgCalendarInviteMetadataScalarWhereInput>>;
  disconnect?: InputMaybe<Array<OrgCalendarInviteMetadataWhereUniqueInput>>;
  set?: InputMaybe<Array<OrgCalendarInviteMetadataWhereUniqueInput>>;
  update?: InputMaybe<Array<OrgCalendarInviteMetadataUpdateWithWhereUniqueWithoutOrganizationInput>>;
  updateMany?: InputMaybe<Array<OrgCalendarInviteMetadataUpdateManyWithWhereWithoutOrganizationInput>>;
  upsert?: InputMaybe<Array<OrgCalendarInviteMetadataUpsertWithWhereUniqueWithoutOrganizationInput>>;
};

export type OrgCalendarInviteMetadataUpdateManyWithWhereWithoutOrganizationInput = {
  data: OrgCalendarInviteMetadataUpdateManyMutationInput;
  where: OrgCalendarInviteMetadataScalarWhereInput;
};

export type OrgCalendarInviteMetadataUpdateWithoutOrganizationInput = {
  active?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  duration?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  location?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  meeting_type?: InputMaybe<StringFieldUpdateOperationsInput>;
  overflow?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type OrgCalendarInviteMetadataUpdateWithWhereUniqueWithoutOrganizationInput = {
  data: OrgCalendarInviteMetadataUpdateWithoutOrganizationInput;
  where: OrgCalendarInviteMetadataWhereUniqueInput;
};

export type OrgCalendarInviteMetadataUpsertWithWhereUniqueWithoutOrganizationInput = {
  create: OrgCalendarInviteMetadataCreateWithoutOrganizationInput;
  update: OrgCalendarInviteMetadataUpdateWithoutOrganizationInput;
  where: OrgCalendarInviteMetadataWhereUniqueInput;
};

export type OrgCalendarInviteMetadataWhereInput = {
  active?: InputMaybe<BoolNullableFilter>;
  AND?: InputMaybe<Array<OrgCalendarInviteMetadataWhereInput>>;
  created_at?: InputMaybe<DateTimeFilter>;
  description?: InputMaybe<StringNullableFilter>;
  duration?: InputMaybe<IntNullableFilter>;
  id?: InputMaybe<StringFilter>;
  location?: InputMaybe<StringNullableFilter>;
  meeting_type?: InputMaybe<StringFilter>;
  NOT?: InputMaybe<Array<OrgCalendarInviteMetadataWhereInput>>;
  OR?: InputMaybe<Array<OrgCalendarInviteMetadataWhereInput>>;
  organization?: InputMaybe<OrganizationWhereInput>;
  organization_id?: InputMaybe<StringNullableFilter>;
  overflow?: InputMaybe<IntNullableFilter>;
  title?: InputMaybe<StringNullableFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
};

export type OrgCalendarInviteMetadataWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']['input']>;
};

export type OrgDefaultPipelinePerecentValue = {
  __typename?: 'OrgDefaultPipelinePerecentValue';
  DecisionCallHeld: Scalars['Float']['output'];
  DemoHeld: Scalars['Float']['output'];
  DemoSet: Scalars['Float']['output'];
  DemoSetFlaked: Scalars['Float']['output'];
  DMContact: Scalars['Float']['output'];
  DMContactInterested: Scalars['Float']['output'];
  FollowUpDemoHeld: Scalars['Float']['output'];
  NDMContact: Scalars['Float']['output'];
  NDMContactInterested: Scalars['Float']['output'];
  organization: Organization;
  organization_id: Scalars['String']['output'];
};

export type OrgDefaultPipelinePerecentValueCreateNestedOneWithoutOrganizationInput = {
  connect?: InputMaybe<OrgDefaultPipelinePerecentValueWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrgDefaultPipelinePerecentValueCreateOrConnectWithoutOrganizationInput>;
  create?: InputMaybe<OrgDefaultPipelinePerecentValueCreateWithoutOrganizationInput>;
};

export type OrgDefaultPipelinePerecentValueCreateOrConnectWithoutOrganizationInput = {
  create: OrgDefaultPipelinePerecentValueCreateWithoutOrganizationInput;
  where: OrgDefaultPipelinePerecentValueWhereUniqueInput;
};

export type OrgDefaultPipelinePerecentValueCreateWithoutOrganizationInput = {
  DecisionCallHeld?: InputMaybe<Scalars['Float']['input']>;
  DemoHeld?: InputMaybe<Scalars['Float']['input']>;
  DemoSet?: InputMaybe<Scalars['Float']['input']>;
  DemoSetFlaked?: InputMaybe<Scalars['Float']['input']>;
  DMContact?: InputMaybe<Scalars['Float']['input']>;
  DMContactInterested?: InputMaybe<Scalars['Float']['input']>;
  FollowUpDemoHeld?: InputMaybe<Scalars['Float']['input']>;
  NDMContact?: InputMaybe<Scalars['Float']['input']>;
  NDMContactInterested?: InputMaybe<Scalars['Float']['input']>;
  Sale?: InputMaybe<Scalars['Float']['input']>;
};

export type OrgDefaultPipelinePerecentValueOrderByWithRelationInput = {
  DecisionCallHeld?: InputMaybe<SortOrder>;
  DemoHeld?: InputMaybe<SortOrder>;
  DemoSet?: InputMaybe<SortOrder>;
  DemoSetFlaked?: InputMaybe<SortOrder>;
  DMContact?: InputMaybe<SortOrder>;
  DMContactInterested?: InputMaybe<SortOrder>;
  FollowUpDemoHeld?: InputMaybe<SortOrder>;
  NDMContact?: InputMaybe<SortOrder>;
  NDMContactInterested?: InputMaybe<SortOrder>;
  organization?: InputMaybe<OrganizationOrderByWithRelationInput>;
  organization_id?: InputMaybe<SortOrder>;
  Sale?: InputMaybe<SortOrder>;
};

export type OrgDefaultPipelinePerecentValueUpdateOneWithoutOrganizationInput = {
  connect?: InputMaybe<OrgDefaultPipelinePerecentValueWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrgDefaultPipelinePerecentValueCreateOrConnectWithoutOrganizationInput>;
  create?: InputMaybe<OrgDefaultPipelinePerecentValueCreateWithoutOrganizationInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<OrgDefaultPipelinePerecentValueUpdateWithoutOrganizationInput>;
  upsert?: InputMaybe<OrgDefaultPipelinePerecentValueUpsertWithoutOrganizationInput>;
};

export type OrgDefaultPipelinePerecentValueUpdateWithoutOrganizationInput = {
  DecisionCallHeld?: InputMaybe<FloatFieldUpdateOperationsInput>;
  DemoHeld?: InputMaybe<FloatFieldUpdateOperationsInput>;
  DemoSet?: InputMaybe<FloatFieldUpdateOperationsInput>;
  DemoSetFlaked?: InputMaybe<FloatFieldUpdateOperationsInput>;
  DMContact?: InputMaybe<FloatFieldUpdateOperationsInput>;
  DMContactInterested?: InputMaybe<FloatFieldUpdateOperationsInput>;
  FollowUpDemoHeld?: InputMaybe<FloatFieldUpdateOperationsInput>;
  NDMContact?: InputMaybe<FloatFieldUpdateOperationsInput>;
  NDMContactInterested?: InputMaybe<FloatFieldUpdateOperationsInput>;
  Sale?: InputMaybe<FloatFieldUpdateOperationsInput>;
};

export type OrgDefaultPipelinePerecentValueUpsertWithoutOrganizationInput = {
  create: OrgDefaultPipelinePerecentValueCreateWithoutOrganizationInput;
  update: OrgDefaultPipelinePerecentValueUpdateWithoutOrganizationInput;
};

export type OrgDefaultPipelinePerecentValueWhereInput = {
  AND?: InputMaybe<Array<OrgDefaultPipelinePerecentValueWhereInput>>;
  DecisionCallHeld?: InputMaybe<FloatFilter>;
  DemoHeld?: InputMaybe<FloatFilter>;
  DemoSet?: InputMaybe<FloatFilter>;
  DemoSetFlaked?: InputMaybe<FloatFilter>;
  DMContact?: InputMaybe<FloatFilter>;
  DMContactInterested?: InputMaybe<FloatFilter>;
  FollowUpDemoHeld?: InputMaybe<FloatFilter>;
  NDMContact?: InputMaybe<FloatFilter>;
  NDMContactInterested?: InputMaybe<FloatFilter>;
  NOT?: InputMaybe<Array<OrgDefaultPipelinePerecentValueWhereInput>>;
  OR?: InputMaybe<Array<OrgDefaultPipelinePerecentValueWhereInput>>;
  organization?: InputMaybe<OrganizationWhereInput>;
  organization_id?: InputMaybe<StringFilter>;
  Sale?: InputMaybe<FloatFilter>;
};

export type OrgDefaultPipelinePerecentValueWhereUniqueInput = {
  organization_id?: InputMaybe<Scalars['String']['input']>;
};

export type OrgHubSpotSync = {
  __typename?: 'OrgHubSpotSync';
  hubspot_inbound_activity_sync: Scalars['Boolean']['output'];
  hubspot_inbound_creation_sync: Scalars['Boolean']['output'];
  hubspot_inbound_update_sync: Scalars['Boolean']['output'];
  hubspot_last_activity_backfill?: Maybe<Scalars['DateTime']['output']>;
  hubspot_outbound_activity_sync: Scalars['Boolean']['output'];
  hubspot_outbound_creation_sync: Scalars['Boolean']['output'];
  hubspot_outbound_update_sync: Scalars['Boolean']['output'];
  hubspot_sale_create_outbound?: Maybe<Scalars['Boolean']['output']>;
  organization: Organization;
  organization_id: Scalars['String']['output'];
};

export type OrgHubSpotSyncCreateNestedOneWithoutOrganizationInput = {
  connect?: InputMaybe<OrgHubSpotSyncWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrgHubSpotSyncCreateOrConnectWithoutOrganizationInput>;
  create?: InputMaybe<OrgHubSpotSyncCreateWithoutOrganizationInput>;
};

export type OrgHubSpotSyncCreateOrConnectWithoutOrganizationInput = {
  create: OrgHubSpotSyncCreateWithoutOrganizationInput;
  where: OrgHubSpotSyncWhereUniqueInput;
};

export type OrgHubSpotSyncCreateWithoutOrganizationInput = {
  hubspot_inbound_activity_sync?: InputMaybe<Scalars['Boolean']['input']>;
  hubspot_inbound_creation_sync?: InputMaybe<Scalars['Boolean']['input']>;
  hubspot_inbound_update_sync?: InputMaybe<Scalars['Boolean']['input']>;
  hubspot_last_activity_backfill?: InputMaybe<Scalars['DateTime']['input']>;
  hubspot_outbound_activity_sync?: InputMaybe<Scalars['Boolean']['input']>;
  hubspot_outbound_creation_sync?: InputMaybe<Scalars['Boolean']['input']>;
  hubspot_outbound_update_sync?: InputMaybe<Scalars['Boolean']['input']>;
  hubspot_sale_create_outbound?: InputMaybe<Scalars['Boolean']['input']>;
};

export type OrgHubSpotSyncInput = {
  hubspot_inbound_creation_sync?: InputMaybe<Scalars['Boolean']['input']>;
  hubspot_inbound_update_sync?: InputMaybe<Scalars['Boolean']['input']>;
  hubspot_outbound_activity_sync?: InputMaybe<Scalars['Boolean']['input']>;
  hubspot_outbound_creation_sync?: InputMaybe<Scalars['Boolean']['input']>;
  hubspot_outbound_update_sync?: InputMaybe<Scalars['Boolean']['input']>;
};

export type OrgHubSpotSyncOrderByWithRelationInput = {
  hubspot_inbound_activity_sync?: InputMaybe<SortOrder>;
  hubspot_inbound_creation_sync?: InputMaybe<SortOrder>;
  hubspot_inbound_update_sync?: InputMaybe<SortOrder>;
  hubspot_last_activity_backfill?: InputMaybe<SortOrder>;
  hubspot_outbound_activity_sync?: InputMaybe<SortOrder>;
  hubspot_outbound_creation_sync?: InputMaybe<SortOrder>;
  hubspot_outbound_update_sync?: InputMaybe<SortOrder>;
  hubspot_sale_create_outbound?: InputMaybe<SortOrder>;
  organization?: InputMaybe<OrganizationOrderByWithRelationInput>;
  organization_id?: InputMaybe<SortOrder>;
};

export type OrgHubSpotSyncUpdateOneWithoutOrganizationInput = {
  connect?: InputMaybe<OrgHubSpotSyncWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrgHubSpotSyncCreateOrConnectWithoutOrganizationInput>;
  create?: InputMaybe<OrgHubSpotSyncCreateWithoutOrganizationInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<OrgHubSpotSyncUpdateWithoutOrganizationInput>;
  upsert?: InputMaybe<OrgHubSpotSyncUpsertWithoutOrganizationInput>;
};

export type OrgHubSpotSyncUpdateWithoutOrganizationInput = {
  hubspot_inbound_activity_sync?: InputMaybe<BoolFieldUpdateOperationsInput>;
  hubspot_inbound_creation_sync?: InputMaybe<BoolFieldUpdateOperationsInput>;
  hubspot_inbound_update_sync?: InputMaybe<BoolFieldUpdateOperationsInput>;
  hubspot_last_activity_backfill?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  hubspot_outbound_activity_sync?: InputMaybe<BoolFieldUpdateOperationsInput>;
  hubspot_outbound_creation_sync?: InputMaybe<BoolFieldUpdateOperationsInput>;
  hubspot_outbound_update_sync?: InputMaybe<BoolFieldUpdateOperationsInput>;
  hubspot_sale_create_outbound?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
};

export type OrgHubSpotSyncUpsertWithoutOrganizationInput = {
  create: OrgHubSpotSyncCreateWithoutOrganizationInput;
  update: OrgHubSpotSyncUpdateWithoutOrganizationInput;
};

export type OrgHubSpotSyncWhereInput = {
  AND?: InputMaybe<Array<OrgHubSpotSyncWhereInput>>;
  hubspot_inbound_activity_sync?: InputMaybe<BoolFilter>;
  hubspot_inbound_creation_sync?: InputMaybe<BoolFilter>;
  hubspot_inbound_update_sync?: InputMaybe<BoolFilter>;
  hubspot_last_activity_backfill?: InputMaybe<DateTimeNullableFilter>;
  hubspot_outbound_activity_sync?: InputMaybe<BoolFilter>;
  hubspot_outbound_creation_sync?: InputMaybe<BoolFilter>;
  hubspot_outbound_update_sync?: InputMaybe<BoolFilter>;
  hubspot_sale_create_outbound?: InputMaybe<BoolNullableFilter>;
  NOT?: InputMaybe<Array<OrgHubSpotSyncWhereInput>>;
  OR?: InputMaybe<Array<OrgHubSpotSyncWhereInput>>;
  organization?: InputMaybe<OrganizationWhereInput>;
  organization_id?: InputMaybe<StringFilter>;
};

export type OrgHubSpotSyncWhereUniqueInput = {
  organization_id?: InputMaybe<Scalars['String']['input']>;
};

export type OrgNoEventDate = {
  __typename?: 'OrgNoEventDate';
  created_at: Scalars['DateTime']['output'];
  date: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  is_federal_holiday: Scalars['Boolean']['output'];
  observe: Scalars['Boolean']['output'];
  organization: Organization;
  organization_id: Scalars['String']['output'];
  title: Scalars['String']['output'];
  updated_at: Scalars['DateTime']['output'];
};

export type OrgNoEventDateCreateManyOrganizationInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  date: Scalars['DateTime']['input'];
  date_validation: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  is_federal_holiday?: InputMaybe<Scalars['Boolean']['input']>;
  observe?: InputMaybe<Scalars['Boolean']['input']>;
  title: Scalars['String']['input'];
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type OrgNoEventDateCreateManyOrganizationInputEnvelope = {
  data?: InputMaybe<Array<OrgNoEventDateCreateManyOrganizationInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type OrgNoEventDateCreateNestedManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<OrgNoEventDateWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<OrgNoEventDateCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<OrgNoEventDateCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<OrgNoEventDateCreateManyOrganizationInputEnvelope>;
};

export type OrgNoEventDateCreateOrConnectWithoutOrganizationInput = {
  create: OrgNoEventDateCreateWithoutOrganizationInput;
  where: OrgNoEventDateWhereUniqueInput;
};

export type OrgNoEventDateCreateWithoutOrganizationInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  date: Scalars['DateTime']['input'];
  date_validation: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  is_federal_holiday?: InputMaybe<Scalars['Boolean']['input']>;
  observe?: InputMaybe<Scalars['Boolean']['input']>;
  title: Scalars['String']['input'];
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

/** OrgNoEventDate Input for create / update */
export type OrgNoEventDateInput = {
  date: Scalars['Date']['input'];
  is_federal_holiday: Scalars['Boolean']['input'];
  observe?: InputMaybe<Scalars['Boolean']['input']>;
  title: Scalars['String']['input'];
};

export type OrgNoEventDateListRelationFilter = {
  every?: InputMaybe<OrgNoEventDateWhereInput>;
  none?: InputMaybe<OrgNoEventDateWhereInput>;
  some?: InputMaybe<OrgNoEventDateWhereInput>;
};

export type OrgNoEventDateOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
  count?: InputMaybe<SortOrder>;
};

export type OrgNoEventDateOrganization_IdDate_ValidationCompoundUniqueInput = {
  date_validation: Scalars['String']['input'];
  organization_id: Scalars['String']['input'];
};

export type OrgNoEventDateScalarWhereInput = {
  AND?: InputMaybe<Array<OrgNoEventDateScalarWhereInput>>;
  created_at?: InputMaybe<DateTimeFilter>;
  date?: InputMaybe<DateTimeFilter>;
  date_validation?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  is_federal_holiday?: InputMaybe<BoolFilter>;
  NOT?: InputMaybe<Array<OrgNoEventDateScalarWhereInput>>;
  observe?: InputMaybe<BoolFilter>;
  OR?: InputMaybe<Array<OrgNoEventDateScalarWhereInput>>;
  organization_id?: InputMaybe<StringFilter>;
  title?: InputMaybe<StringFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
};

export type OrgNoEventDateUpdateManyMutationInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  date?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  date_validation?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  is_federal_holiday?: InputMaybe<BoolFieldUpdateOperationsInput>;
  observe?: InputMaybe<BoolFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type OrgNoEventDateUpdateManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<OrgNoEventDateWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<OrgNoEventDateCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<OrgNoEventDateCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<OrgNoEventDateCreateManyOrganizationInputEnvelope>;
  delete?: InputMaybe<Array<OrgNoEventDateWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<OrgNoEventDateScalarWhereInput>>;
  disconnect?: InputMaybe<Array<OrgNoEventDateWhereUniqueInput>>;
  set?: InputMaybe<Array<OrgNoEventDateWhereUniqueInput>>;
  update?: InputMaybe<Array<OrgNoEventDateUpdateWithWhereUniqueWithoutOrganizationInput>>;
  updateMany?: InputMaybe<Array<OrgNoEventDateUpdateManyWithWhereWithoutOrganizationInput>>;
  upsert?: InputMaybe<Array<OrgNoEventDateUpsertWithWhereUniqueWithoutOrganizationInput>>;
};

export type OrgNoEventDateUpdateManyWithWhereWithoutOrganizationInput = {
  data: OrgNoEventDateUpdateManyMutationInput;
  where: OrgNoEventDateScalarWhereInput;
};

export type OrgNoEventDateUpdateWithoutOrganizationInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  date?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  date_validation?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  is_federal_holiday?: InputMaybe<BoolFieldUpdateOperationsInput>;
  observe?: InputMaybe<BoolFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type OrgNoEventDateUpdateWithWhereUniqueWithoutOrganizationInput = {
  data: OrgNoEventDateUpdateWithoutOrganizationInput;
  where: OrgNoEventDateWhereUniqueInput;
};

export type OrgNoEventDateUpsertWithWhereUniqueWithoutOrganizationInput = {
  create: OrgNoEventDateCreateWithoutOrganizationInput;
  update: OrgNoEventDateUpdateWithoutOrganizationInput;
  where: OrgNoEventDateWhereUniqueInput;
};

export type OrgNoEventDateWhereInput = {
  AND?: InputMaybe<Array<OrgNoEventDateWhereInput>>;
  created_at?: InputMaybe<DateTimeFilter>;
  date?: InputMaybe<DateTimeFilter>;
  date_validation?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  is_federal_holiday?: InputMaybe<BoolFilter>;
  NOT?: InputMaybe<Array<OrgNoEventDateWhereInput>>;
  observe?: InputMaybe<BoolFilter>;
  OR?: InputMaybe<Array<OrgNoEventDateWhereInput>>;
  organization?: InputMaybe<OrganizationWhereInput>;
  organization_id?: InputMaybe<StringFilter>;
  title?: InputMaybe<StringFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
};

export type OrgNoEventDateWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  organization_id_date_validation?: InputMaybe<OrgNoEventDateOrganization_IdDate_ValidationCompoundUniqueInput>;
};

export type OrgSalesforceSync = {
  __typename?: 'OrgSalesforceSync';
  organization: Organization;
  organization_id: Scalars['String']['output'];
  sfdc_inbound_activity_sync: Scalars['Boolean']['output'];
  sfdc_inbound_creation_sync: Scalars['Boolean']['output'];
  sfdc_inbound_update_sync: Scalars['Boolean']['output'];
  sfdc_lead_contact_conversion: Scalars['Boolean']['output'];
  sfdc_outbound_activity_sync: Scalars['Boolean']['output'];
  sfdc_outbound_creation_sync: Scalars['Boolean']['output'];
  sfdc_outbound_update_sync: Scalars['Boolean']['output'];
};

export type OrgSalesforceSyncCreateNestedOneWithoutOrganizationInput = {
  connect?: InputMaybe<OrgSalesforceSyncWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrgSalesforceSyncCreateOrConnectWithoutOrganizationInput>;
  create?: InputMaybe<OrgSalesforceSyncCreateWithoutOrganizationInput>;
};

export type OrgSalesforceSyncCreateOrConnectWithoutOrganizationInput = {
  create: OrgSalesforceSyncCreateWithoutOrganizationInput;
  where: OrgSalesforceSyncWhereUniqueInput;
};

export type OrgSalesforceSyncCreateWithoutOrganizationInput = {
  sfdc_demo_opportunity_sync?: InputMaybe<Scalars['Boolean']['input']>;
  sfdc_inbound_activity_sync?: InputMaybe<Scalars['Boolean']['input']>;
  sfdc_inbound_creation_sync?: InputMaybe<Scalars['Boolean']['input']>;
  sfdc_inbound_update_sync?: InputMaybe<Scalars['Boolean']['input']>;
  sfdc_lead_contact_conversion?: InputMaybe<Scalars['Boolean']['input']>;
  sfdc_outbound_activity_sync?: InputMaybe<Scalars['Boolean']['input']>;
  sfdc_outbound_creation_sync?: InputMaybe<Scalars['Boolean']['input']>;
  sfdc_outbound_update_sync?: InputMaybe<Scalars['Boolean']['input']>;
};

export type OrgSalesforceSyncInput = {
  sfdc_inbound_creation_sync?: InputMaybe<Scalars['Boolean']['input']>;
  sfdc_inbound_update_sync?: InputMaybe<Scalars['Boolean']['input']>;
  sfdc_outbound_activity_sync?: InputMaybe<Scalars['Boolean']['input']>;
  sfdc_outbound_creation_sync?: InputMaybe<Scalars['Boolean']['input']>;
  sfdc_outbound_update_sync?: InputMaybe<Scalars['Boolean']['input']>;
};

export type OrgSalesforceSyncOrderByWithRelationInput = {
  organization?: InputMaybe<OrganizationOrderByWithRelationInput>;
  organization_id?: InputMaybe<SortOrder>;
  sfdc_demo_opportunity_sync?: InputMaybe<SortOrder>;
  sfdc_inbound_activity_sync?: InputMaybe<SortOrder>;
  sfdc_inbound_creation_sync?: InputMaybe<SortOrder>;
  sfdc_inbound_update_sync?: InputMaybe<SortOrder>;
  sfdc_lead_contact_conversion?: InputMaybe<SortOrder>;
  sfdc_outbound_activity_sync?: InputMaybe<SortOrder>;
  sfdc_outbound_creation_sync?: InputMaybe<SortOrder>;
  sfdc_outbound_update_sync?: InputMaybe<SortOrder>;
};

export type OrgSalesforceSyncUpdateOneWithoutOrganizationInput = {
  connect?: InputMaybe<OrgSalesforceSyncWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrgSalesforceSyncCreateOrConnectWithoutOrganizationInput>;
  create?: InputMaybe<OrgSalesforceSyncCreateWithoutOrganizationInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<OrgSalesforceSyncUpdateWithoutOrganizationInput>;
  upsert?: InputMaybe<OrgSalesforceSyncUpsertWithoutOrganizationInput>;
};

export type OrgSalesforceSyncUpdateWithoutOrganizationInput = {
  sfdc_demo_opportunity_sync?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sfdc_inbound_activity_sync?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sfdc_inbound_creation_sync?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sfdc_inbound_update_sync?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sfdc_lead_contact_conversion?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sfdc_outbound_activity_sync?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sfdc_outbound_creation_sync?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sfdc_outbound_update_sync?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type OrgSalesforceSyncUpsertWithoutOrganizationInput = {
  create: OrgSalesforceSyncCreateWithoutOrganizationInput;
  update: OrgSalesforceSyncUpdateWithoutOrganizationInput;
};

export type OrgSalesforceSyncWhereInput = {
  AND?: InputMaybe<Array<OrgSalesforceSyncWhereInput>>;
  NOT?: InputMaybe<Array<OrgSalesforceSyncWhereInput>>;
  OR?: InputMaybe<Array<OrgSalesforceSyncWhereInput>>;
  organization?: InputMaybe<OrganizationWhereInput>;
  organization_id?: InputMaybe<StringFilter>;
  sfdc_demo_opportunity_sync?: InputMaybe<BoolFilter>;
  sfdc_inbound_activity_sync?: InputMaybe<BoolFilter>;
  sfdc_inbound_creation_sync?: InputMaybe<BoolFilter>;
  sfdc_inbound_update_sync?: InputMaybe<BoolFilter>;
  sfdc_lead_contact_conversion?: InputMaybe<BoolFilter>;
  sfdc_outbound_activity_sync?: InputMaybe<BoolFilter>;
  sfdc_outbound_creation_sync?: InputMaybe<BoolFilter>;
  sfdc_outbound_update_sync?: InputMaybe<BoolFilter>;
};

export type OrgSalesforceSyncWhereUniqueInput = {
  organization_id?: InputMaybe<Scalars['String']['input']>;
};

export type OrgTimeslotAvailability = {
  __typename?: 'OrgTimeslotAvailability';
  created_at: Scalars['DateTime']['output'];
  day: Weekday;
  id: Scalars['String']['output'];
  max_booking?: Maybe<Scalars['Int']['output']>;
  organization: Organization;
  organization_id: Scalars['String']['output'];
  updated_at: Scalars['DateTime']['output'];
};

export type OrgTimeslotAvailabilityCreateManyOrganizationInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  day: Weekday;
  id?: InputMaybe<Scalars['String']['input']>;
  max_booking?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type OrgTimeslotAvailabilityCreateManyOrganizationInputEnvelope = {
  data?: InputMaybe<Array<OrgTimeslotAvailabilityCreateManyOrganizationInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type OrgTimeslotAvailabilityCreateNestedManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<OrgTimeslotAvailabilityWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<OrgTimeslotAvailabilityCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<OrgTimeslotAvailabilityCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<OrgTimeslotAvailabilityCreateManyOrganizationInputEnvelope>;
};

export type OrgTimeslotAvailabilityCreateOrConnectWithoutOrganizationInput = {
  create: OrgTimeslotAvailabilityCreateWithoutOrganizationInput;
  where: OrgTimeslotAvailabilityWhereUniqueInput;
};

export type OrgTimeslotAvailabilityCreateWithoutOrganizationInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  day: Weekday;
  id?: InputMaybe<Scalars['String']['input']>;
  max_booking?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

/** OrgTimeslotAvailability Input for create / update */
export type OrgTimeslotAvailabilityInput = {
  day: Weekday;
  max_booking?: InputMaybe<Scalars['Int']['input']>;
};

export type OrgTimeslotAvailabilityListRelationFilter = {
  every?: InputMaybe<OrgTimeslotAvailabilityWhereInput>;
  none?: InputMaybe<OrgTimeslotAvailabilityWhereInput>;
  some?: InputMaybe<OrgTimeslotAvailabilityWhereInput>;
};

export type OrgTimeslotAvailabilityOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
  count?: InputMaybe<SortOrder>;
};

export type OrgTimeslotAvailabilityOrganization_IdDayCompoundUniqueInput = {
  day: Weekday;
  organization_id: Scalars['String']['input'];
};

export type OrgTimeslotAvailabilityScalarWhereInput = {
  AND?: InputMaybe<Array<OrgTimeslotAvailabilityScalarWhereInput>>;
  created_at?: InputMaybe<DateTimeFilter>;
  day?: InputMaybe<EnumWeekdayFilter>;
  id?: InputMaybe<StringFilter>;
  max_booking?: InputMaybe<IntNullableFilter>;
  NOT?: InputMaybe<Array<OrgTimeslotAvailabilityScalarWhereInput>>;
  OR?: InputMaybe<Array<OrgTimeslotAvailabilityScalarWhereInput>>;
  organization_id?: InputMaybe<StringFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
};

export type OrgTimeslotAvailabilityUpdateManyMutationInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  day?: InputMaybe<EnumWeekdayFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  max_booking?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type OrgTimeslotAvailabilityUpdateManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<OrgTimeslotAvailabilityWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<OrgTimeslotAvailabilityCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<OrgTimeslotAvailabilityCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<OrgTimeslotAvailabilityCreateManyOrganizationInputEnvelope>;
  delete?: InputMaybe<Array<OrgTimeslotAvailabilityWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<OrgTimeslotAvailabilityScalarWhereInput>>;
  disconnect?: InputMaybe<Array<OrgTimeslotAvailabilityWhereUniqueInput>>;
  set?: InputMaybe<Array<OrgTimeslotAvailabilityWhereUniqueInput>>;
  update?: InputMaybe<Array<OrgTimeslotAvailabilityUpdateWithWhereUniqueWithoutOrganizationInput>>;
  updateMany?: InputMaybe<Array<OrgTimeslotAvailabilityUpdateManyWithWhereWithoutOrganizationInput>>;
  upsert?: InputMaybe<Array<OrgTimeslotAvailabilityUpsertWithWhereUniqueWithoutOrganizationInput>>;
};

export type OrgTimeslotAvailabilityUpdateManyWithWhereWithoutOrganizationInput = {
  data: OrgTimeslotAvailabilityUpdateManyMutationInput;
  where: OrgTimeslotAvailabilityScalarWhereInput;
};

export type OrgTimeslotAvailabilityUpdateWithoutOrganizationInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  day?: InputMaybe<EnumWeekdayFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  max_booking?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type OrgTimeslotAvailabilityUpdateWithWhereUniqueWithoutOrganizationInput = {
  data: OrgTimeslotAvailabilityUpdateWithoutOrganizationInput;
  where: OrgTimeslotAvailabilityWhereUniqueInput;
};

export type OrgTimeslotAvailabilityUpsertWithWhereUniqueWithoutOrganizationInput = {
  create: OrgTimeslotAvailabilityCreateWithoutOrganizationInput;
  update: OrgTimeslotAvailabilityUpdateWithoutOrganizationInput;
  where: OrgTimeslotAvailabilityWhereUniqueInput;
};

export type OrgTimeslotAvailabilityWhereInput = {
  AND?: InputMaybe<Array<OrgTimeslotAvailabilityWhereInput>>;
  created_at?: InputMaybe<DateTimeFilter>;
  day?: InputMaybe<EnumWeekdayFilter>;
  id?: InputMaybe<StringFilter>;
  max_booking?: InputMaybe<IntNullableFilter>;
  NOT?: InputMaybe<Array<OrgTimeslotAvailabilityWhereInput>>;
  OR?: InputMaybe<Array<OrgTimeslotAvailabilityWhereInput>>;
  organization?: InputMaybe<OrganizationWhereInput>;
  organization_id?: InputMaybe<StringFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
};

export type OrgTimeslotAvailabilityWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  organization_id_day?: InputMaybe<OrgTimeslotAvailabilityOrganization_IdDayCompoundUniqueInput>;
};

export type PagesInput = {
  /** List of pages to add. */
  pages: Array<PagesInputType>;
};

export type PagesInputType = {
  /** Page position. */
  order: Scalars['Int']['input'];
  /** List of pages to add in this section. */
  sections: Array<SaleConfigSectionTypeInput>;
  /** page title */
  title?: InputMaybe<Scalars['String']['input']>;
  /** set the page as visible. */
  visible: Scalars['Boolean']['input'];
};

export type PandadocDataCreateNestedOneWithoutOrganizationInput = {
  connect?: InputMaybe<PandadocDataWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PandadocDataCreateOrConnectWithoutOrganizationInput>;
  create?: InputMaybe<PandadocDataCreateWithoutOrganizationInput>;
};

export type PandadocDataCreateOrConnectWithoutOrganizationInput = {
  create: PandadocDataCreateWithoutOrganizationInput;
  where: PandadocDataWhereUniqueInput;
};

export type PandadocDataCreateWithoutOrganizationInput = {
  access_token: Scalars['String']['input'];
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  refresh_token: Scalars['String']['input'];
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type PandadocDataOrderByWithRelationInput = {
  access_token?: InputMaybe<SortOrder>;
  created_at?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  organization?: InputMaybe<OrganizationOrderByWithRelationInput>;
  organization_id?: InputMaybe<SortOrder>;
  refresh_token?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
};

export type PandadocDataUpdateOneWithoutOrganizationInput = {
  connect?: InputMaybe<PandadocDataWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PandadocDataCreateOrConnectWithoutOrganizationInput>;
  create?: InputMaybe<PandadocDataCreateWithoutOrganizationInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<PandadocDataUpdateWithoutOrganizationInput>;
  upsert?: InputMaybe<PandadocDataUpsertWithoutOrganizationInput>;
};

export type PandadocDataUpdateWithoutOrganizationInput = {
  access_token?: InputMaybe<StringFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  refresh_token?: InputMaybe<StringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type PandadocDataUpsertWithoutOrganizationInput = {
  create: PandadocDataCreateWithoutOrganizationInput;
  update: PandadocDataUpdateWithoutOrganizationInput;
};

export type PandadocDataWhereInput = {
  access_token?: InputMaybe<StringFilter>;
  AND?: InputMaybe<Array<PandadocDataWhereInput>>;
  created_at?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  NOT?: InputMaybe<Array<PandadocDataWhereInput>>;
  OR?: InputMaybe<Array<PandadocDataWhereInput>>;
  organization?: InputMaybe<OrganizationWhereInput>;
  organization_id?: InputMaybe<StringNullableFilter>;
  refresh_token?: InputMaybe<StringFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
};

export type PandadocDataWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  organization_id?: InputMaybe<Scalars['String']['input']>;
};

export type PandaDocMappingInput = {
  price_tables: Array<PandaDocPriceTable>;
  recipients: Array<PandaDocRecipient>;
  tokens: Array<PandaDocToken>;
};

export type PandaDocOption = {
  discount?: InputMaybe<PandaDocOptionDiscount>;
};

export type PandaDocOptionDiscount = {
  is_global: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  type: Scalars['String']['input'];
  value: Scalars['Int']['input'];
};

export type PandaDocPriceTable = {
  name: Scalars['String']['input'];
  options: PandaDocOption;
  sections: Array<PandaDocSection>;
};

export type PandaDocRecipient = {
  email: Scalars['String']['input'];
  first_name: Scalars['String']['input'];
  last_name: Scalars['String']['input'];
  role?: InputMaybe<Scalars['String']['input']>;
};

export type PandaDocRow = {
  data: PandaDocRowData;
  options: PandaDocRowOption;
};

export type PandaDocRowData = {
  description: Scalars['String']['input'];
  name: Scalars['String']['input'];
  price: Scalars['Float']['input'];
  qty: Scalars['Int']['input'];
};

export type PandaDocRowOption = {
  multichoice_selected: Scalars['Boolean']['input'];
  optional: Scalars['Boolean']['input'];
  optional_selected: Scalars['Boolean']['input'];
  qty_editable: Scalars['Boolean']['input'];
};

export type PandaDocSection = {
  default: Scalars['Boolean']['input'];
  rows: Array<PandaDocRow>;
  title: Scalars['String']['input'];
};

export type PandaDocTemplate = {
  __typename?: 'PandaDocTemplate';
  active: Scalars['Boolean']['output'];
  created_at: Scalars['DateTime']['output'];
  organization: Organization;
  organization_id: Scalars['String']['output'];
  template_id: Scalars['String']['output'];
  template_name: Scalars['String']['output'];
  updated_at: Scalars['DateTime']['output'];
};

export type PandaDocTemplateCreateManyOrganizationInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  template_id: Scalars['String']['input'];
  template_name: Scalars['String']['input'];
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type PandaDocTemplateCreateManyOrganizationInputEnvelope = {
  data?: InputMaybe<Array<PandaDocTemplateCreateManyOrganizationInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type PandaDocTemplateCreateNestedManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<PandaDocTemplateWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PandaDocTemplateCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<PandaDocTemplateCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<PandaDocTemplateCreateManyOrganizationInputEnvelope>;
};

export type PandaDocTemplateCreateOrConnectWithoutOrganizationInput = {
  create: PandaDocTemplateCreateWithoutOrganizationInput;
  where: PandaDocTemplateWhereUniqueInput;
};

export type PandaDocTemplateCreateWithoutOrganizationInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  template_id: Scalars['String']['input'];
  template_name: Scalars['String']['input'];
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type PandaDocTemplateListRelationFilter = {
  every?: InputMaybe<PandaDocTemplateWhereInput>;
  none?: InputMaybe<PandaDocTemplateWhereInput>;
  some?: InputMaybe<PandaDocTemplateWhereInput>;
};

export type PandaDocTemplateOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
  count?: InputMaybe<SortOrder>;
};

export type PandaDocTemplateScalarWhereInput = {
  active?: InputMaybe<BoolFilter>;
  AND?: InputMaybe<Array<PandaDocTemplateScalarWhereInput>>;
  created_at?: InputMaybe<DateTimeFilter>;
  NOT?: InputMaybe<Array<PandaDocTemplateScalarWhereInput>>;
  OR?: InputMaybe<Array<PandaDocTemplateScalarWhereInput>>;
  organization_id?: InputMaybe<StringFilter>;
  template_id?: InputMaybe<StringFilter>;
  template_name?: InputMaybe<StringFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
};

export type PandaDocTemplateTemplate_IdOrganization_IdCompoundUniqueInput = {
  organization_id: Scalars['String']['input'];
  template_id: Scalars['String']['input'];
};

export type PandaDocTemplateUpdateManyMutationInput = {
  active?: InputMaybe<BoolFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  template_id?: InputMaybe<StringFieldUpdateOperationsInput>;
  template_name?: InputMaybe<StringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type PandaDocTemplateUpdateManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<PandaDocTemplateWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PandaDocTemplateCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<PandaDocTemplateCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<PandaDocTemplateCreateManyOrganizationInputEnvelope>;
  delete?: InputMaybe<Array<PandaDocTemplateWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<PandaDocTemplateScalarWhereInput>>;
  disconnect?: InputMaybe<Array<PandaDocTemplateWhereUniqueInput>>;
  set?: InputMaybe<Array<PandaDocTemplateWhereUniqueInput>>;
  update?: InputMaybe<Array<PandaDocTemplateUpdateWithWhereUniqueWithoutOrganizationInput>>;
  updateMany?: InputMaybe<Array<PandaDocTemplateUpdateManyWithWhereWithoutOrganizationInput>>;
  upsert?: InputMaybe<Array<PandaDocTemplateUpsertWithWhereUniqueWithoutOrganizationInput>>;
};

export type PandaDocTemplateUpdateManyWithWhereWithoutOrganizationInput = {
  data: PandaDocTemplateUpdateManyMutationInput;
  where: PandaDocTemplateScalarWhereInput;
};

export type PandaDocTemplateUpdateWithoutOrganizationInput = {
  active?: InputMaybe<BoolFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  template_id?: InputMaybe<StringFieldUpdateOperationsInput>;
  template_name?: InputMaybe<StringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type PandaDocTemplateUpdateWithWhereUniqueWithoutOrganizationInput = {
  data: PandaDocTemplateUpdateWithoutOrganizationInput;
  where: PandaDocTemplateWhereUniqueInput;
};

export type PandaDocTemplateUpsertWithWhereUniqueWithoutOrganizationInput = {
  create: PandaDocTemplateCreateWithoutOrganizationInput;
  update: PandaDocTemplateUpdateWithoutOrganizationInput;
  where: PandaDocTemplateWhereUniqueInput;
};

export type PandaDocTemplateWhereInput = {
  active?: InputMaybe<BoolFilter>;
  AND?: InputMaybe<Array<PandaDocTemplateWhereInput>>;
  created_at?: InputMaybe<DateTimeFilter>;
  NOT?: InputMaybe<Array<PandaDocTemplateWhereInput>>;
  OR?: InputMaybe<Array<PandaDocTemplateWhereInput>>;
  organization?: InputMaybe<OrganizationWhereInput>;
  organization_id?: InputMaybe<StringFilter>;
  template_id?: InputMaybe<StringFilter>;
  template_name?: InputMaybe<StringFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
};

export type PandaDocTemplateWhereUniqueInput = {
  template_id_organization_id?: InputMaybe<PandaDocTemplateTemplate_IdOrganization_IdCompoundUniqueInput>;
};

export type PandaDocToken = {
  name: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export enum Phase {
  ColdCallCold = 'ColdCallCold',
  ColdCallDm = 'ColdCallDM',
  ColdCallNdm = 'ColdCallNDM',
  ColdCallWorkingNum = 'ColdCallWorkingNum',
  ConfirmationPhase = 'ConfirmationPhase',
  Customer = 'Customer',
  HeldPhase = 'HeldPhase',
  LongResting = 'LongResting',
  LongRestingColdCall = 'LongRestingColdCall',
  PostHoldPhase = 'PostHoldPhase',
  PostInitialHelPhaseDemoNotHeld = 'PostInitialHelPhaseDemoNotHeld',
  Resting = 'Resting',
  RestingColdCall = 'RestingColdCall',
  Retired = 'Retired',
  RetiredColdCall = 'RetiredColdCall'
}

export type PipelineAvgFieldInput = {
  date_filter?: InputMaybe<DashboardDateOption>;
  lead_filter: DashboardLeadFilterOption;
  METRICS: Array<PipelineColumn>;
  rep_filter: DashboardRepFilterOption;
};

export type PipelineAvgFields = {
  __typename?: 'PipelineAvgFields';
  avg_day_set_out?: Maybe<Scalars['Float']['output']>;
  avg_time_in_pipeline?: Maybe<Scalars['Float']['output']>;
};

export enum PipelineColumn {
  CustomerNonSale = 'CustomerNonSale',
  DecisionCallHeld = 'DecisionCallHeld',
  DemoHeld = 'DemoHeld',
  DemoSet = 'DemoSet',
  DemoSetFlaked = 'DemoSetFlaked',
  DmContact = 'DMContact',
  DmContactInterested = 'DMContactInterested',
  DoNotContact = 'DoNotContact',
  FollowUpDemoHeld = 'FollowUpDemoHeld',
  NdmContact = 'NDMContact',
  NdmContactInterested = 'NDMContactInterested',
  NoContactMade = 'NoContactMade',
  Sale = 'Sale',
  Undialed = 'Undialed'
}

/** Pipeline Export Arg */
export type PipelineExportArg = {
  computed_id?: InputMaybe<Scalars['String']['input']>;
  date_filter: DashboardDateOption;
  desc?: InputMaybe<Scalars['Boolean']['input']>;
  lead_filter: DashboardLeadFilterOption;
  measure_by?: InputMaybe<MeasureByFilter>;
  metric?: InputMaybe<Metric>;
  order_by?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Position>;
  rep_filter: DashboardRepFilterOption;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};

/** Summarized Dashboard Input */
export type PipelineManagementArg = {
  date_filter?: InputMaybe<DashboardDateOption>;
  lead_filter: DashboardLeadFilterOption;
  rep_filter: DashboardRepFilterOption;
};

/** Summarized Dashboard Input */
export type PipelineManagementLeadArg = {
  date_filter?: InputMaybe<DashboardDateOption>;
  desc?: InputMaybe<Scalars['Boolean']['input']>;
  lead_filter: DashboardLeadFilterOption;
  order_by?: InputMaybe<Scalars['String']['input']>;
  rep_filter: DashboardRepFilterOption;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};

/** Summarized Dashboard Input */
export type PipelineMetricData = {
  __typename?: 'PipelineMetricData';
  metric: Scalars['String']['output'];
  metric_label: Scalars['String']['output'];
};

/** Pipeline metrc type */
export enum PipeLineMetricType {
  LeadCount = 'LeadCount',
  Mrr = 'MRR'
}

/** available option for sorting Pipeline Report List in pipeline view */
export enum PipelineReportListSortOption {
  BusinessName = 'BusinessName',
  Close = 'Close',
  CloseDate = 'CloseDate',
  CloseUser = 'CloseUser',
  Held = 'Held',
  Industry = 'Industry',
  LeadName = 'LeadName',
  LeadSource = 'LeadSource',
  MostRecentCallTime = 'MostRecentCallTime',
  Mrr = 'MRR',
  NextScheduledEvent = 'nextScheduledEvent',
  RepName = 'RepName',
  Set = 'Set',
  SetUser = 'SetUser',
  SubIndustry = 'SubIndustry',
  TimeInPipeline = 'TimeInPipeline',
  TimeOfFirstHold = 'TimeOfFirstHold',
  TimeOfFirstSale = 'TimeOfFirstSale',
  TimeOfFirstSet = 'TimeOfFirstSet',
  TypeOfNextScheduledEvent = 'TypeOfNextScheduledEvent'
}

export enum Position {
  Lead = 'Lead',
  Organization = 'Organization',
  Site = 'Site',
  Team = 'Team',
  User = 'User'
}

export type Post = {
  __typename?: 'Post';
  content?: Maybe<Scalars['String']['output']>;
  dummy?: Maybe<Metric>;
  id: Scalars['Int']['output'];
  published: Scalars['Boolean']['output'];
  title: Scalars['String']['output'];
};

export enum PostAction {
  MoveToLongResting = 'MoveToLongResting',
  MoveToResting = 'MoveToResting',
  MoveToRetired = 'MoveToRetired',
  None = 'None',
  ReleaseLead = 'ReleaseLead',
  RepeatSequence = 'RepeatSequence',
  TransferAnotherSequence = 'TransferAnotherSequence'
}

export type PostCreateManyAuthorInput = {
  content?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  dummy?: InputMaybe<Metric>;
  dummy2?: InputMaybe<Position>;
  dummy3?: InputMaybe<Metrictype>;
  id?: InputMaybe<Scalars['Int']['input']>;
  published?: InputMaybe<Scalars['Boolean']['input']>;
  title: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type PostCreateManyAuthorInputEnvelope = {
  data?: InputMaybe<Array<PostCreateManyAuthorInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type PostCreateNestedManyWithoutAuthorInput = {
  connect?: InputMaybe<Array<PostWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PostCreateOrConnectWithoutAuthorInput>>;
  create?: InputMaybe<Array<PostCreateWithoutAuthorInput>>;
  createMany?: InputMaybe<PostCreateManyAuthorInputEnvelope>;
};

export type PostCreateOrConnectWithoutAuthorInput = {
  create: PostCreateWithoutAuthorInput;
  where: PostWhereUniqueInput;
};

export type PostCreateWithoutAuthorInput = {
  content?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  dummy?: InputMaybe<Metric>;
  dummy2?: InputMaybe<Position>;
  dummy3?: InputMaybe<Metrictype>;
  published?: InputMaybe<Scalars['Boolean']['input']>;
  title: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type PostListRelationFilter = {
  every?: InputMaybe<PostWhereInput>;
  none?: InputMaybe<PostWhereInput>;
  some?: InputMaybe<PostWhereInput>;
};

export type PostOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
  count?: InputMaybe<SortOrder>;
};

export type PostScalarWhereInput = {
  AND?: InputMaybe<Array<PostScalarWhereInput>>;
  author_id?: InputMaybe<StringNullableFilter>;
  content?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  dummy?: InputMaybe<EnumMetricNullableFilter>;
  dummy2?: InputMaybe<EnumPositionNullableFilter>;
  dummy3?: InputMaybe<EnumMetrictypeNullableFilter>;
  id?: InputMaybe<IntFilter>;
  NOT?: InputMaybe<Array<PostScalarWhereInput>>;
  OR?: InputMaybe<Array<PostScalarWhereInput>>;
  published?: InputMaybe<BoolFilter>;
  title?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type PostUpdateManyMutationInput = {
  content?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  dummy?: InputMaybe<NullableEnumMetricFieldUpdateOperationsInput>;
  dummy2?: InputMaybe<NullableEnumPositionFieldUpdateOperationsInput>;
  dummy3?: InputMaybe<NullableEnumMetrictypeFieldUpdateOperationsInput>;
  published?: InputMaybe<BoolFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type PostUpdateManyWithoutAuthorInput = {
  connect?: InputMaybe<Array<PostWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PostCreateOrConnectWithoutAuthorInput>>;
  create?: InputMaybe<Array<PostCreateWithoutAuthorInput>>;
  createMany?: InputMaybe<PostCreateManyAuthorInputEnvelope>;
  delete?: InputMaybe<Array<PostWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<PostScalarWhereInput>>;
  disconnect?: InputMaybe<Array<PostWhereUniqueInput>>;
  set?: InputMaybe<Array<PostWhereUniqueInput>>;
  update?: InputMaybe<Array<PostUpdateWithWhereUniqueWithoutAuthorInput>>;
  updateMany?: InputMaybe<Array<PostUpdateManyWithWhereWithoutAuthorInput>>;
  upsert?: InputMaybe<Array<PostUpsertWithWhereUniqueWithoutAuthorInput>>;
};

export type PostUpdateManyWithWhereWithoutAuthorInput = {
  data: PostUpdateManyMutationInput;
  where: PostScalarWhereInput;
};

export type PostUpdateWithoutAuthorInput = {
  content?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  dummy?: InputMaybe<NullableEnumMetricFieldUpdateOperationsInput>;
  dummy2?: InputMaybe<NullableEnumPositionFieldUpdateOperationsInput>;
  dummy3?: InputMaybe<NullableEnumMetrictypeFieldUpdateOperationsInput>;
  published?: InputMaybe<BoolFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type PostUpdateWithWhereUniqueWithoutAuthorInput = {
  data: PostUpdateWithoutAuthorInput;
  where: PostWhereUniqueInput;
};

export type PostUpsertWithWhereUniqueWithoutAuthorInput = {
  create: PostCreateWithoutAuthorInput;
  update: PostUpdateWithoutAuthorInput;
  where: PostWhereUniqueInput;
};

export type PostWhereInput = {
  AND?: InputMaybe<Array<PostWhereInput>>;
  author?: InputMaybe<UserWhereInput>;
  author_id?: InputMaybe<StringNullableFilter>;
  content?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  dummy?: InputMaybe<EnumMetricNullableFilter>;
  dummy2?: InputMaybe<EnumPositionNullableFilter>;
  dummy3?: InputMaybe<EnumMetrictypeNullableFilter>;
  id?: InputMaybe<IntFilter>;
  NOT?: InputMaybe<Array<PostWhereInput>>;
  OR?: InputMaybe<Array<PostWhereInput>>;
  published?: InputMaybe<BoolFilter>;
  title?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type PostWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type Product = {
  __typename?: 'Product';
  active: Scalars['Boolean']['output'];
  ae_commission?: Maybe<Scalars['Float']['output']>;
  available_concessions: Array<Concession>;
  commission_rate: Array<CommissionRate>;
  created_at: Scalars['DateTime']['output'];
  fee_type: Fee_Type;
  id: Scalars['String']['output'];
  last_updated_by?: Maybe<Scalars['String']['output']>;
  organization: Organization;
  organization_id: Scalars['String']['output'];
  prepayment_options: Array<Scalars['Int']['output']>;
  pricebook_entry_id?: Maybe<Scalars['String']['output']>;
  pricing: Scalars['Float']['output'];
  pricing_floor: Scalars['Float']['output'];
  product_title: Scalars['String']['output'];
  quantity_lock?: Maybe<Scalars['Int']['output']>;
  salesforce_id?: Maybe<Scalars['String']['output']>;
  sdr_commission?: Maybe<Scalars['Float']['output']>;
  show_override_price_option: Scalars['Boolean']['output'];
  updated_at: Scalars['DateTime']['output'];
};


export type ProductAvailable_ConcessionsArgs = {
  after?: InputMaybe<ConcessionWhereUniqueInput>;
  before?: InputMaybe<ConcessionWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ConcessionOrderByWithRelationInput>>;
};


export type ProductCommission_RateArgs = {
  after?: InputMaybe<CommissionRateWhereUniqueInput>;
  before?: InputMaybe<CommissionRateWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type ProductChangeLogCreateManyOrganizationInput = {
  after: Scalars['Json']['input'];
  before?: InputMaybe<Scalars['Json']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  product_id: Scalars['String']['input'];
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  updated_by_user_id: Scalars['String']['input'];
};

export type ProductChangeLogCreateManyOrganizationInputEnvelope = {
  data?: InputMaybe<Array<ProductChangeLogCreateManyOrganizationInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ProductChangeLogCreateManyProductInput = {
  after: Scalars['Json']['input'];
  before?: InputMaybe<Scalars['Json']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  organization_id: Scalars['String']['input'];
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  updated_by_user_id: Scalars['String']['input'];
};

export type ProductChangeLogCreateManyProductInputEnvelope = {
  data?: InputMaybe<Array<ProductChangeLogCreateManyProductInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ProductChangeLogCreateManyUpdated_ByInput = {
  after: Scalars['Json']['input'];
  before?: InputMaybe<Scalars['Json']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  organization_id: Scalars['String']['input'];
  product_id: Scalars['String']['input'];
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type ProductChangeLogCreateManyUpdated_ByInputEnvelope = {
  data?: InputMaybe<Array<ProductChangeLogCreateManyUpdated_ByInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ProductChangeLogCreateNestedManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<ProductChangeLogWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ProductChangeLogCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<ProductChangeLogCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<ProductChangeLogCreateManyOrganizationInputEnvelope>;
};

export type ProductChangeLogCreateNestedManyWithoutProductInput = {
  connect?: InputMaybe<Array<ProductChangeLogWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ProductChangeLogCreateOrConnectWithoutProductInput>>;
  create?: InputMaybe<Array<ProductChangeLogCreateWithoutProductInput>>;
  createMany?: InputMaybe<ProductChangeLogCreateManyProductInputEnvelope>;
};

export type ProductChangeLogCreateNestedManyWithoutUpdated_ByInput = {
  connect?: InputMaybe<Array<ProductChangeLogWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ProductChangeLogCreateOrConnectWithoutUpdated_ByInput>>;
  create?: InputMaybe<Array<ProductChangeLogCreateWithoutUpdated_ByInput>>;
  createMany?: InputMaybe<ProductChangeLogCreateManyUpdated_ByInputEnvelope>;
};

export type ProductChangeLogCreateOrConnectWithoutOrganizationInput = {
  create: ProductChangeLogCreateWithoutOrganizationInput;
  where: ProductChangeLogWhereUniqueInput;
};

export type ProductChangeLogCreateOrConnectWithoutProductInput = {
  create: ProductChangeLogCreateWithoutProductInput;
  where: ProductChangeLogWhereUniqueInput;
};

export type ProductChangeLogCreateOrConnectWithoutUpdated_ByInput = {
  create: ProductChangeLogCreateWithoutUpdated_ByInput;
  where: ProductChangeLogWhereUniqueInput;
};

export type ProductChangeLogCreateWithoutOrganizationInput = {
  after: Scalars['Json']['input'];
  before?: InputMaybe<Scalars['Json']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  product: ProductCreateNestedOneWithoutChangesInput;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  updated_by: UserCreateNestedOneWithoutProductsCreatedInput;
};

export type ProductChangeLogCreateWithoutProductInput = {
  after: Scalars['Json']['input'];
  before?: InputMaybe<Scalars['Json']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  organization: OrganizationCreateNestedOneWithoutProductChangeLogInput;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  updated_by: UserCreateNestedOneWithoutProductsCreatedInput;
};

export type ProductChangeLogCreateWithoutUpdated_ByInput = {
  after: Scalars['Json']['input'];
  before?: InputMaybe<Scalars['Json']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  organization: OrganizationCreateNestedOneWithoutProductChangeLogInput;
  product: ProductCreateNestedOneWithoutChangesInput;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type ProductChangeLogListRelationFilter = {
  every?: InputMaybe<ProductChangeLogWhereInput>;
  none?: InputMaybe<ProductChangeLogWhereInput>;
  some?: InputMaybe<ProductChangeLogWhereInput>;
};

export type ProductChangeLogOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
  count?: InputMaybe<SortOrder>;
};

export type ProductChangeLogScalarWhereInput = {
  AND?: InputMaybe<Array<ProductChangeLogScalarWhereInput>>;
  before?: InputMaybe<JsonNullableFilter>;
  created_at?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  NOT?: InputMaybe<Array<ProductChangeLogScalarWhereInput>>;
  OR?: InputMaybe<Array<ProductChangeLogScalarWhereInput>>;
  organization_id?: InputMaybe<StringFilter>;
  product_id?: InputMaybe<StringFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
  updated_by_user_id?: InputMaybe<StringFilter>;
};

export type ProductChangeLogUpdateManyMutationInput = {
  after?: InputMaybe<Scalars['Json']['input']>;
  before?: InputMaybe<Scalars['Json']['input']>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ProductChangeLogUpdateManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<ProductChangeLogWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ProductChangeLogCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<ProductChangeLogCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<ProductChangeLogCreateManyOrganizationInputEnvelope>;
  delete?: InputMaybe<Array<ProductChangeLogWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ProductChangeLogScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ProductChangeLogWhereUniqueInput>>;
  set?: InputMaybe<Array<ProductChangeLogWhereUniqueInput>>;
  update?: InputMaybe<Array<ProductChangeLogUpdateWithWhereUniqueWithoutOrganizationInput>>;
  updateMany?: InputMaybe<Array<ProductChangeLogUpdateManyWithWhereWithoutOrganizationInput>>;
  upsert?: InputMaybe<Array<ProductChangeLogUpsertWithWhereUniqueWithoutOrganizationInput>>;
};

export type ProductChangeLogUpdateManyWithoutProductInput = {
  connect?: InputMaybe<Array<ProductChangeLogWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ProductChangeLogCreateOrConnectWithoutProductInput>>;
  create?: InputMaybe<Array<ProductChangeLogCreateWithoutProductInput>>;
  createMany?: InputMaybe<ProductChangeLogCreateManyProductInputEnvelope>;
  delete?: InputMaybe<Array<ProductChangeLogWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ProductChangeLogScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ProductChangeLogWhereUniqueInput>>;
  set?: InputMaybe<Array<ProductChangeLogWhereUniqueInput>>;
  update?: InputMaybe<Array<ProductChangeLogUpdateWithWhereUniqueWithoutProductInput>>;
  updateMany?: InputMaybe<Array<ProductChangeLogUpdateManyWithWhereWithoutProductInput>>;
  upsert?: InputMaybe<Array<ProductChangeLogUpsertWithWhereUniqueWithoutProductInput>>;
};

export type ProductChangeLogUpdateManyWithoutUpdated_ByInput = {
  connect?: InputMaybe<Array<ProductChangeLogWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ProductChangeLogCreateOrConnectWithoutUpdated_ByInput>>;
  create?: InputMaybe<Array<ProductChangeLogCreateWithoutUpdated_ByInput>>;
  createMany?: InputMaybe<ProductChangeLogCreateManyUpdated_ByInputEnvelope>;
  delete?: InputMaybe<Array<ProductChangeLogWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ProductChangeLogScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ProductChangeLogWhereUniqueInput>>;
  set?: InputMaybe<Array<ProductChangeLogWhereUniqueInput>>;
  update?: InputMaybe<Array<ProductChangeLogUpdateWithWhereUniqueWithoutUpdated_ByInput>>;
  updateMany?: InputMaybe<Array<ProductChangeLogUpdateManyWithWhereWithoutUpdated_ByInput>>;
  upsert?: InputMaybe<Array<ProductChangeLogUpsertWithWhereUniqueWithoutUpdated_ByInput>>;
};

export type ProductChangeLogUpdateManyWithWhereWithoutOrganizationInput = {
  data: ProductChangeLogUpdateManyMutationInput;
  where: ProductChangeLogScalarWhereInput;
};

export type ProductChangeLogUpdateManyWithWhereWithoutProductInput = {
  data: ProductChangeLogUpdateManyMutationInput;
  where: ProductChangeLogScalarWhereInput;
};

export type ProductChangeLogUpdateManyWithWhereWithoutUpdated_ByInput = {
  data: ProductChangeLogUpdateManyMutationInput;
  where: ProductChangeLogScalarWhereInput;
};

export type ProductChangeLogUpdateWithoutOrganizationInput = {
  after?: InputMaybe<Scalars['Json']['input']>;
  before?: InputMaybe<Scalars['Json']['input']>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  product?: InputMaybe<ProductUpdateOneRequiredWithoutChangesInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  updated_by?: InputMaybe<UserUpdateOneRequiredWithoutProductsCreatedInput>;
};

export type ProductChangeLogUpdateWithoutProductInput = {
  after?: InputMaybe<Scalars['Json']['input']>;
  before?: InputMaybe<Scalars['Json']['input']>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutProductChangeLogInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  updated_by?: InputMaybe<UserUpdateOneRequiredWithoutProductsCreatedInput>;
};

export type ProductChangeLogUpdateWithoutUpdated_ByInput = {
  after?: InputMaybe<Scalars['Json']['input']>;
  before?: InputMaybe<Scalars['Json']['input']>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutProductChangeLogInput>;
  product?: InputMaybe<ProductUpdateOneRequiredWithoutChangesInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ProductChangeLogUpdateWithWhereUniqueWithoutOrganizationInput = {
  data: ProductChangeLogUpdateWithoutOrganizationInput;
  where: ProductChangeLogWhereUniqueInput;
};

export type ProductChangeLogUpdateWithWhereUniqueWithoutProductInput = {
  data: ProductChangeLogUpdateWithoutProductInput;
  where: ProductChangeLogWhereUniqueInput;
};

export type ProductChangeLogUpdateWithWhereUniqueWithoutUpdated_ByInput = {
  data: ProductChangeLogUpdateWithoutUpdated_ByInput;
  where: ProductChangeLogWhereUniqueInput;
};

export type ProductChangeLogUpsertWithWhereUniqueWithoutOrganizationInput = {
  create: ProductChangeLogCreateWithoutOrganizationInput;
  update: ProductChangeLogUpdateWithoutOrganizationInput;
  where: ProductChangeLogWhereUniqueInput;
};

export type ProductChangeLogUpsertWithWhereUniqueWithoutProductInput = {
  create: ProductChangeLogCreateWithoutProductInput;
  update: ProductChangeLogUpdateWithoutProductInput;
  where: ProductChangeLogWhereUniqueInput;
};

export type ProductChangeLogUpsertWithWhereUniqueWithoutUpdated_ByInput = {
  create: ProductChangeLogCreateWithoutUpdated_ByInput;
  update: ProductChangeLogUpdateWithoutUpdated_ByInput;
  where: ProductChangeLogWhereUniqueInput;
};

export type ProductChangeLogWhereInput = {
  AND?: InputMaybe<Array<ProductChangeLogWhereInput>>;
  before?: InputMaybe<JsonNullableFilter>;
  created_at?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  NOT?: InputMaybe<Array<ProductChangeLogWhereInput>>;
  OR?: InputMaybe<Array<ProductChangeLogWhereInput>>;
  organization?: InputMaybe<OrganizationWhereInput>;
  organization_id?: InputMaybe<StringFilter>;
  product?: InputMaybe<ProductWhereInput>;
  product_id?: InputMaybe<StringFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
  updated_by?: InputMaybe<UserWhereInput>;
  updated_by_user_id?: InputMaybe<StringFilter>;
};

export type ProductChangeLogWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']['input']>;
};

export type ProductCreateInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  available_concessions?: InputMaybe<ConcessionCreateNestedManyWithoutApplicable_ProductsInput>;
  Changes?: InputMaybe<ProductChangeLogCreateNestedManyWithoutProductInput>;
  commission_rate?: InputMaybe<CommissionRateCreateNestedManyWithoutProductInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  deleted_at?: InputMaybe<Scalars['DateTime']['input']>;
  fee_type?: InputMaybe<Fee_Type>;
  id?: InputMaybe<Scalars['String']['input']>;
  organization: OrganizationCreateNestedOneWithoutProductsInput;
  prepayment_options?: InputMaybe<ProductCreateprepayment_OptionsInput>;
  pricebook_entry_id?: InputMaybe<Scalars['String']['input']>;
  pricing: Scalars['Float']['input'];
  pricing_floor?: InputMaybe<Scalars['Float']['input']>;
  product_title: Scalars['String']['input'];
  quantity_lock?: InputMaybe<Scalars['Int']['input']>;
  sale_items?: InputMaybe<SaleItemCreateNestedManyWithoutProductInput>;
  salesforce_id?: InputMaybe<Scalars['String']['input']>;
  show_override_price_option?: InputMaybe<Scalars['Boolean']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type ProductCreateManyOrganizationInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  deleted_at?: InputMaybe<Scalars['DateTime']['input']>;
  fee_type?: InputMaybe<Fee_Type>;
  id?: InputMaybe<Scalars['String']['input']>;
  prepayment_options?: InputMaybe<ProductCreateManyprepayment_OptionsInput>;
  pricebook_entry_id?: InputMaybe<Scalars['String']['input']>;
  pricing: Scalars['Float']['input'];
  pricing_floor?: InputMaybe<Scalars['Float']['input']>;
  product_title: Scalars['String']['input'];
  quantity_lock?: InputMaybe<Scalars['Int']['input']>;
  salesforce_id?: InputMaybe<Scalars['String']['input']>;
  show_override_price_option?: InputMaybe<Scalars['Boolean']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type ProductCreateManyOrganizationInputEnvelope = {
  data?: InputMaybe<Array<ProductCreateManyOrganizationInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ProductCreateManyprepayment_OptionsInput = {
  set?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type ProductCreateNestedManyWithoutAvailable_ConcessionsInput = {
  connect?: InputMaybe<Array<ProductWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ProductCreateOrConnectWithoutAvailable_ConcessionsInput>>;
  create?: InputMaybe<Array<ProductCreateWithoutAvailable_ConcessionsInput>>;
};

export type ProductCreateNestedManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<ProductWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ProductCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<ProductCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<ProductCreateManyOrganizationInputEnvelope>;
};

export type ProductCreateNestedOneWithoutChangesInput = {
  connect?: InputMaybe<ProductWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProductCreateOrConnectWithoutChangesInput>;
  create?: InputMaybe<ProductCreateWithoutChangesInput>;
};

export type ProductCreateNestedOneWithoutSale_ItemsInput = {
  connect?: InputMaybe<ProductWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProductCreateOrConnectWithoutSale_ItemsInput>;
  create?: InputMaybe<ProductCreateWithoutSale_ItemsInput>;
};

export type ProductCreateOrConnectWithoutAvailable_ConcessionsInput = {
  create: ProductCreateWithoutAvailable_ConcessionsInput;
  where: ProductWhereUniqueInput;
};

export type ProductCreateOrConnectWithoutChangesInput = {
  create: ProductCreateWithoutChangesInput;
  where: ProductWhereUniqueInput;
};

export type ProductCreateOrConnectWithoutOrganizationInput = {
  create: ProductCreateWithoutOrganizationInput;
  where: ProductWhereUniqueInput;
};

export type ProductCreateOrConnectWithoutSale_ItemsInput = {
  create: ProductCreateWithoutSale_ItemsInput;
  where: ProductWhereUniqueInput;
};

export type ProductCreateprepayment_OptionsInput = {
  set?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type ProductCreateWithoutAvailable_ConcessionsInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  Changes?: InputMaybe<ProductChangeLogCreateNestedManyWithoutProductInput>;
  commission_rate?: InputMaybe<CommissionRateCreateNestedManyWithoutProductInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  deleted_at?: InputMaybe<Scalars['DateTime']['input']>;
  fee_type?: InputMaybe<Fee_Type>;
  id?: InputMaybe<Scalars['String']['input']>;
  organization: OrganizationCreateNestedOneWithoutProductsInput;
  prepayment_options?: InputMaybe<ProductCreateprepayment_OptionsInput>;
  pricebook_entry_id?: InputMaybe<Scalars['String']['input']>;
  pricing: Scalars['Float']['input'];
  pricing_floor?: InputMaybe<Scalars['Float']['input']>;
  product_title: Scalars['String']['input'];
  quantity_lock?: InputMaybe<Scalars['Int']['input']>;
  sale_items?: InputMaybe<SaleItemCreateNestedManyWithoutProductInput>;
  salesforce_id?: InputMaybe<Scalars['String']['input']>;
  show_override_price_option?: InputMaybe<Scalars['Boolean']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type ProductCreateWithoutChangesInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  available_concessions?: InputMaybe<ConcessionCreateNestedManyWithoutApplicable_ProductsInput>;
  commission_rate?: InputMaybe<CommissionRateCreateNestedManyWithoutProductInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  deleted_at?: InputMaybe<Scalars['DateTime']['input']>;
  fee_type?: InputMaybe<Fee_Type>;
  id?: InputMaybe<Scalars['String']['input']>;
  organization: OrganizationCreateNestedOneWithoutProductsInput;
  prepayment_options?: InputMaybe<ProductCreateprepayment_OptionsInput>;
  pricebook_entry_id?: InputMaybe<Scalars['String']['input']>;
  pricing: Scalars['Float']['input'];
  pricing_floor?: InputMaybe<Scalars['Float']['input']>;
  product_title: Scalars['String']['input'];
  quantity_lock?: InputMaybe<Scalars['Int']['input']>;
  sale_items?: InputMaybe<SaleItemCreateNestedManyWithoutProductInput>;
  salesforce_id?: InputMaybe<Scalars['String']['input']>;
  show_override_price_option?: InputMaybe<Scalars['Boolean']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type ProductCreateWithoutOrganizationInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  available_concessions?: InputMaybe<ConcessionCreateNestedManyWithoutApplicable_ProductsInput>;
  Changes?: InputMaybe<ProductChangeLogCreateNestedManyWithoutProductInput>;
  commission_rate?: InputMaybe<CommissionRateCreateNestedManyWithoutProductInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  deleted_at?: InputMaybe<Scalars['DateTime']['input']>;
  fee_type?: InputMaybe<Fee_Type>;
  id?: InputMaybe<Scalars['String']['input']>;
  prepayment_options?: InputMaybe<ProductCreateprepayment_OptionsInput>;
  pricebook_entry_id?: InputMaybe<Scalars['String']['input']>;
  pricing: Scalars['Float']['input'];
  pricing_floor?: InputMaybe<Scalars['Float']['input']>;
  product_title: Scalars['String']['input'];
  quantity_lock?: InputMaybe<Scalars['Int']['input']>;
  sale_items?: InputMaybe<SaleItemCreateNestedManyWithoutProductInput>;
  salesforce_id?: InputMaybe<Scalars['String']['input']>;
  show_override_price_option?: InputMaybe<Scalars['Boolean']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type ProductCreateWithoutSale_ItemsInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  available_concessions?: InputMaybe<ConcessionCreateNestedManyWithoutApplicable_ProductsInput>;
  Changes?: InputMaybe<ProductChangeLogCreateNestedManyWithoutProductInput>;
  commission_rate?: InputMaybe<CommissionRateCreateNestedManyWithoutProductInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  deleted_at?: InputMaybe<Scalars['DateTime']['input']>;
  fee_type?: InputMaybe<Fee_Type>;
  id?: InputMaybe<Scalars['String']['input']>;
  organization: OrganizationCreateNestedOneWithoutProductsInput;
  prepayment_options?: InputMaybe<ProductCreateprepayment_OptionsInput>;
  pricebook_entry_id?: InputMaybe<Scalars['String']['input']>;
  pricing: Scalars['Float']['input'];
  pricing_floor?: InputMaybe<Scalars['Float']['input']>;
  product_title: Scalars['String']['input'];
  quantity_lock?: InputMaybe<Scalars['Int']['input']>;
  salesforce_id?: InputMaybe<Scalars['String']['input']>;
  show_override_price_option?: InputMaybe<Scalars['Boolean']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type ProductDataType = {
  contract_duration?: InputMaybe<Scalars['String']['input']>;
  payment_terms?: InputMaybe<Scalars['String']['input']>;
  prepayment?: InputMaybe<Scalars['String']['input']>;
};

export type ProductInput = {
  products?: InputMaybe<Array<InputMaybe<ProductUpdateInput>>>;
};

export type ProductInputType = {
  /** id of a concession */
  concession_id?: InputMaybe<Scalars['String']['input']>;
  /** A number 1-12 for prepayment. */
  prepayment?: InputMaybe<Scalars['Int']['input']>;
  /** If it is set to a value, this is used instead of the product price. */
  price_override?: InputMaybe<Scalars['Float']['input']>;
  /** id of a product */
  product_id: Scalars['String']['input'];
  /** The number of units being purchased. */
  quantity: Scalars['Int']['input'];
};

export type ProductListRelationFilter = {
  every?: InputMaybe<ProductWhereInput>;
  none?: InputMaybe<ProductWhereInput>;
  some?: InputMaybe<ProductWhereInput>;
};

export type ProductLog = {
  __typename?: 'ProductLog';
  dml_created_by?: Maybe<Scalars['String']['output']>;
  dml_timestamp: Scalars['DateTime']['output'];
  dml_type: Dml_Type;
  new_row_data?: Maybe<Scalars['Json']['output']>;
  old_row_data?: Maybe<Scalars['Json']['output']>;
  product_id: Scalars['String']['output'];
};

export type ProductOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
  count?: InputMaybe<SortOrder>;
};

export type ProductOrderByWithRelationInput = {
  active?: InputMaybe<SortOrder>;
  available_concessions?: InputMaybe<ConcessionOrderByRelationAggregateInput>;
  Changes?: InputMaybe<ProductChangeLogOrderByRelationAggregateInput>;
  commission_rate?: InputMaybe<CommissionRateOrderByRelationAggregateInput>;
  created_at?: InputMaybe<SortOrder>;
  deleted_at?: InputMaybe<SortOrder>;
  fee_type?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  organization?: InputMaybe<OrganizationOrderByWithRelationInput>;
  organization_id?: InputMaybe<SortOrder>;
  prepayment_options?: InputMaybe<SortOrder>;
  pricebook_entry_id?: InputMaybe<SortOrder>;
  pricing?: InputMaybe<SortOrder>;
  pricing_floor?: InputMaybe<SortOrder>;
  product_title?: InputMaybe<SortOrder>;
  quantity_lock?: InputMaybe<SortOrder>;
  sale_items?: InputMaybe<SaleItemOrderByRelationAggregateInput>;
  salesforce_id?: InputMaybe<SortOrder>;
  show_override_price_option?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
};

export type ProductScalarWhereInput = {
  active?: InputMaybe<BoolFilter>;
  AND?: InputMaybe<Array<ProductScalarWhereInput>>;
  created_at?: InputMaybe<DateTimeFilter>;
  deleted_at?: InputMaybe<DateTimeNullableFilter>;
  fee_type?: InputMaybe<EnumFee_TypeFilter>;
  id?: InputMaybe<StringFilter>;
  NOT?: InputMaybe<Array<ProductScalarWhereInput>>;
  OR?: InputMaybe<Array<ProductScalarWhereInput>>;
  organization_id?: InputMaybe<StringFilter>;
  prepayment_options?: InputMaybe<IntNullableListFilter>;
  pricebook_entry_id?: InputMaybe<StringNullableFilter>;
  pricing?: InputMaybe<FloatFilter>;
  pricing_floor?: InputMaybe<FloatFilter>;
  product_title?: InputMaybe<StringFilter>;
  quantity_lock?: InputMaybe<IntNullableFilter>;
  salesforce_id?: InputMaybe<StringNullableFilter>;
  show_override_price_option?: InputMaybe<BoolFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
};

export type ProductsInput = {
  ae_commission?: InputMaybe<Scalars['Float']['input']>;
  /** pass in if product already exists */
  id?: InputMaybe<Scalars['String']['input']>;
  pricing?: InputMaybe<Scalars['Float']['input']>;
  product_title?: InputMaybe<Scalars['String']['input']>;
  quantity_lock?: InputMaybe<Scalars['Int']['input']>;
  sdr_commission?: InputMaybe<Scalars['Float']['input']>;
};

export type ProductsInputNew = {
  active: Scalars['Boolean']['input'];
  available_concessions?: InputMaybe<Array<InputMaybe<ConcessionInput>>>;
  fee_type?: InputMaybe<FeeType>;
  /** pass in if product already exists */
  id?: InputMaybe<Scalars['String']['input']>;
  prepayment_options: Array<Scalars['Int']['input']>;
  pricebook_entry_id?: InputMaybe<Scalars['String']['input']>;
  pricing?: Scalars['Float']['input'];
  pricing_floor: Scalars['Float']['input'];
  product_title?: InputMaybe<Scalars['String']['input']>;
  quantity_lock?: InputMaybe<Scalars['Int']['input']>;
  salesforce_id?: InputMaybe<Scalars['String']['input']>;
  show_override_price_option: Scalars['Boolean']['input'];
};

export type ProductUpdateInput = {
  active?: InputMaybe<BoolFieldUpdateOperationsInput>;
  available_concessions?: InputMaybe<ConcessionUpdateManyWithoutApplicable_ProductsInput>;
  Changes?: InputMaybe<ProductChangeLogUpdateManyWithoutProductInput>;
  commission_rate?: InputMaybe<CommissionRateUpdateManyWithoutProductInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deleted_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  fee_type?: InputMaybe<EnumFee_TypeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutProductsInput>;
  prepayment_options?: InputMaybe<ProductUpdateprepayment_OptionsInput>;
  pricebook_entry_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  pricing?: InputMaybe<FloatFieldUpdateOperationsInput>;
  pricing_floor?: InputMaybe<FloatFieldUpdateOperationsInput>;
  product_title?: InputMaybe<StringFieldUpdateOperationsInput>;
  quantity_lock?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  sale_items?: InputMaybe<SaleItemUpdateManyWithoutProductInput>;
  salesforce_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  show_override_price_option?: InputMaybe<BoolFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ProductUpdateManyMutationInput = {
  active?: InputMaybe<BoolFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deleted_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  fee_type?: InputMaybe<EnumFee_TypeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  prepayment_options?: InputMaybe<ProductUpdateprepayment_OptionsInput>;
  pricebook_entry_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  pricing?: InputMaybe<FloatFieldUpdateOperationsInput>;
  pricing_floor?: InputMaybe<FloatFieldUpdateOperationsInput>;
  product_title?: InputMaybe<StringFieldUpdateOperationsInput>;
  quantity_lock?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  salesforce_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  show_override_price_option?: InputMaybe<BoolFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ProductUpdateManyWithoutAvailable_ConcessionsInput = {
  connect?: InputMaybe<Array<ProductWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ProductCreateOrConnectWithoutAvailable_ConcessionsInput>>;
  create?: InputMaybe<Array<ProductCreateWithoutAvailable_ConcessionsInput>>;
  delete?: InputMaybe<Array<ProductWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ProductScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ProductWhereUniqueInput>>;
  set?: InputMaybe<Array<ProductWhereUniqueInput>>;
  update?: InputMaybe<Array<ProductUpdateWithWhereUniqueWithoutAvailable_ConcessionsInput>>;
  updateMany?: InputMaybe<Array<ProductUpdateManyWithWhereWithoutAvailable_ConcessionsInput>>;
  upsert?: InputMaybe<Array<ProductUpsertWithWhereUniqueWithoutAvailable_ConcessionsInput>>;
};

export type ProductUpdateManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<ProductWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ProductCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<ProductCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<ProductCreateManyOrganizationInputEnvelope>;
  delete?: InputMaybe<Array<ProductWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ProductScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ProductWhereUniqueInput>>;
  set?: InputMaybe<Array<ProductWhereUniqueInput>>;
  update?: InputMaybe<Array<ProductUpdateWithWhereUniqueWithoutOrganizationInput>>;
  updateMany?: InputMaybe<Array<ProductUpdateManyWithWhereWithoutOrganizationInput>>;
  upsert?: InputMaybe<Array<ProductUpsertWithWhereUniqueWithoutOrganizationInput>>;
};

export type ProductUpdateManyWithWhereWithoutAvailable_ConcessionsInput = {
  data: ProductUpdateManyMutationInput;
  where: ProductScalarWhereInput;
};

export type ProductUpdateManyWithWhereWithoutOrganizationInput = {
  data: ProductUpdateManyMutationInput;
  where: ProductScalarWhereInput;
};

export type ProductUpdateOneRequiredWithoutChangesInput = {
  connect?: InputMaybe<ProductWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProductCreateOrConnectWithoutChangesInput>;
  create?: InputMaybe<ProductCreateWithoutChangesInput>;
  update?: InputMaybe<ProductUpdateWithoutChangesInput>;
  upsert?: InputMaybe<ProductUpsertWithoutChangesInput>;
};

export type ProductUpdateOneWithoutSale_ItemsInput = {
  connect?: InputMaybe<ProductWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProductCreateOrConnectWithoutSale_ItemsInput>;
  create?: InputMaybe<ProductCreateWithoutSale_ItemsInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<ProductUpdateWithoutSale_ItemsInput>;
  upsert?: InputMaybe<ProductUpsertWithoutSale_ItemsInput>;
};

export type ProductUpdateprepayment_OptionsInput = {
  push?: InputMaybe<Scalars['Int']['input']>;
  set?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type ProductUpdateWithoutAvailable_ConcessionsInput = {
  active?: InputMaybe<BoolFieldUpdateOperationsInput>;
  Changes?: InputMaybe<ProductChangeLogUpdateManyWithoutProductInput>;
  commission_rate?: InputMaybe<CommissionRateUpdateManyWithoutProductInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deleted_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  fee_type?: InputMaybe<EnumFee_TypeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutProductsInput>;
  prepayment_options?: InputMaybe<ProductUpdateprepayment_OptionsInput>;
  pricebook_entry_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  pricing?: InputMaybe<FloatFieldUpdateOperationsInput>;
  pricing_floor?: InputMaybe<FloatFieldUpdateOperationsInput>;
  product_title?: InputMaybe<StringFieldUpdateOperationsInput>;
  quantity_lock?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  sale_items?: InputMaybe<SaleItemUpdateManyWithoutProductInput>;
  salesforce_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  show_override_price_option?: InputMaybe<BoolFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ProductUpdateWithoutChangesInput = {
  active?: InputMaybe<BoolFieldUpdateOperationsInput>;
  available_concessions?: InputMaybe<ConcessionUpdateManyWithoutApplicable_ProductsInput>;
  commission_rate?: InputMaybe<CommissionRateUpdateManyWithoutProductInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deleted_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  fee_type?: InputMaybe<EnumFee_TypeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutProductsInput>;
  prepayment_options?: InputMaybe<ProductUpdateprepayment_OptionsInput>;
  pricebook_entry_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  pricing?: InputMaybe<FloatFieldUpdateOperationsInput>;
  pricing_floor?: InputMaybe<FloatFieldUpdateOperationsInput>;
  product_title?: InputMaybe<StringFieldUpdateOperationsInput>;
  quantity_lock?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  sale_items?: InputMaybe<SaleItemUpdateManyWithoutProductInput>;
  salesforce_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  show_override_price_option?: InputMaybe<BoolFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ProductUpdateWithoutOrganizationInput = {
  active?: InputMaybe<BoolFieldUpdateOperationsInput>;
  available_concessions?: InputMaybe<ConcessionUpdateManyWithoutApplicable_ProductsInput>;
  Changes?: InputMaybe<ProductChangeLogUpdateManyWithoutProductInput>;
  commission_rate?: InputMaybe<CommissionRateUpdateManyWithoutProductInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deleted_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  fee_type?: InputMaybe<EnumFee_TypeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  prepayment_options?: InputMaybe<ProductUpdateprepayment_OptionsInput>;
  pricebook_entry_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  pricing?: InputMaybe<FloatFieldUpdateOperationsInput>;
  pricing_floor?: InputMaybe<FloatFieldUpdateOperationsInput>;
  product_title?: InputMaybe<StringFieldUpdateOperationsInput>;
  quantity_lock?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  sale_items?: InputMaybe<SaleItemUpdateManyWithoutProductInput>;
  salesforce_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  show_override_price_option?: InputMaybe<BoolFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ProductUpdateWithoutSale_ItemsInput = {
  active?: InputMaybe<BoolFieldUpdateOperationsInput>;
  available_concessions?: InputMaybe<ConcessionUpdateManyWithoutApplicable_ProductsInput>;
  Changes?: InputMaybe<ProductChangeLogUpdateManyWithoutProductInput>;
  commission_rate?: InputMaybe<CommissionRateUpdateManyWithoutProductInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deleted_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  fee_type?: InputMaybe<EnumFee_TypeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutProductsInput>;
  prepayment_options?: InputMaybe<ProductUpdateprepayment_OptionsInput>;
  pricebook_entry_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  pricing?: InputMaybe<FloatFieldUpdateOperationsInput>;
  pricing_floor?: InputMaybe<FloatFieldUpdateOperationsInput>;
  product_title?: InputMaybe<StringFieldUpdateOperationsInput>;
  quantity_lock?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  salesforce_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  show_override_price_option?: InputMaybe<BoolFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ProductUpdateWithWhereUniqueWithoutAvailable_ConcessionsInput = {
  data: ProductUpdateWithoutAvailable_ConcessionsInput;
  where: ProductWhereUniqueInput;
};

export type ProductUpdateWithWhereUniqueWithoutOrganizationInput = {
  data: ProductUpdateWithoutOrganizationInput;
  where: ProductWhereUniqueInput;
};

export type ProductUpsertWithoutChangesInput = {
  create: ProductCreateWithoutChangesInput;
  update: ProductUpdateWithoutChangesInput;
};

export type ProductUpsertWithoutSale_ItemsInput = {
  create: ProductCreateWithoutSale_ItemsInput;
  update: ProductUpdateWithoutSale_ItemsInput;
};

export type ProductUpsertWithWhereUniqueWithoutAvailable_ConcessionsInput = {
  create: ProductCreateWithoutAvailable_ConcessionsInput;
  update: ProductUpdateWithoutAvailable_ConcessionsInput;
  where: ProductWhereUniqueInput;
};

export type ProductUpsertWithWhereUniqueWithoutOrganizationInput = {
  create: ProductCreateWithoutOrganizationInput;
  update: ProductUpdateWithoutOrganizationInput;
  where: ProductWhereUniqueInput;
};

export type ProductWhereInput = {
  active?: InputMaybe<BoolFilter>;
  AND?: InputMaybe<Array<ProductWhereInput>>;
  available_concessions?: InputMaybe<ConcessionListRelationFilter>;
  Changes?: InputMaybe<ProductChangeLogListRelationFilter>;
  commission_rate?: InputMaybe<CommissionRateListRelationFilter>;
  created_at?: InputMaybe<DateTimeFilter>;
  deleted_at?: InputMaybe<DateTimeNullableFilter>;
  fee_type?: InputMaybe<EnumFee_TypeFilter>;
  id?: InputMaybe<StringFilter>;
  NOT?: InputMaybe<Array<ProductWhereInput>>;
  OR?: InputMaybe<Array<ProductWhereInput>>;
  organization?: InputMaybe<OrganizationWhereInput>;
  organization_id?: InputMaybe<StringFilter>;
  prepayment_options?: InputMaybe<IntNullableListFilter>;
  pricebook_entry_id?: InputMaybe<StringNullableFilter>;
  pricing?: InputMaybe<FloatFilter>;
  pricing_floor?: InputMaybe<FloatFilter>;
  product_title?: InputMaybe<StringFilter>;
  quantity_lock?: InputMaybe<IntNullableFilter>;
  sale_items?: InputMaybe<SaleItemListRelationFilter>;
  salesforce_id?: InputMaybe<StringNullableFilter>;
  show_override_price_option?: InputMaybe<BoolFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
};

export type ProductWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']['input']>;
};

export type Query = {
  __typename?: 'Query';
  aggregationTest?: Maybe<Scalars['Int']['output']>;
  /** organization id in association history model backfill */
  associationHistoriesBackfill?: Maybe<Scalars['String']['output']>;
  /** similar to autoTransferCall, but it does not consider live user status. */
  autoScheduleSelectionRep?: Maybe<Scalars['Json']['output']>;
  autoTransferCall?: Maybe<Scalars['Json']['output']>;
  /** organization id in auto transfer selection model backfill */
  autoTransferSelectionsBackfill?: Maybe<Scalars['String']['output']>;
  /** organization id in availability model backfill */
  availabilitiesBackfill?: Maybe<Scalars['String']['output']>;
  bestMetricRecord?: Maybe<BestMetricRecord>;
  bestMetricRecords: Array<BestMetricRecord>;
  callMeNowStatus?: Maybe<CallMeNow>;
  /** organization id in call sharing model backfill */
  callSharingsBackfill?: Maybe<Scalars['String']['output']>;
  checkIntegrationStatus?: Maybe<Scalars['Json']['output']>;
  checkLeadPhoneNumber?: Maybe<Scalars['Json']['output']>;
  computeRepCloseRate?: Maybe<Array<Maybe<Scalars['Json']['output']>>>;
  /** organization id in conference model backfill */
  conferencesBackFill?: Maybe<Scalars['String']['output']>;
  /** organization id in contact request model backfill */
  contactRequestsBackfill?: Maybe<Scalars['String']['output']>;
  /** organization id in contact model backfill */
  contactsBackfill?: Maybe<Scalars['String']['output']>;
  /** Returns a conference based on id */
  countDoubleIntents?: Maybe<Scalars['Json']['output']>;
  /** organization id in dashboard column option model backfill */
  dashboardColumnOptionsBackfill?: Maybe<Scalars['String']['output']>;
  /** organization id in dashboard lead filter model backfill */
  dashboardLeadFiltersBackfill?: Maybe<Scalars['String']['output']>;
  /** organization id in dashboard rep filter model backfill */
  dashboardRepFiltersBackfill?: Maybe<Scalars['String']['output']>;
  /** organization id in dashboard saved view model backfill */
  dashboardSavedViewsBackfill?: Maybe<Scalars['String']['output']>;
  dispoClickReport?: Maybe<Scalars['Json']['output']>;
  /** organization id in email item model backfill */
  emailItemsBackfill?: Maybe<Scalars['String']['output']>;
  /** organization id in email thread model backfill */
  emailThreadsBackfill?: Maybe<Scalars['String']['output']>;
  /** Used for the drilldown chart where the metric is plotted for 30 days by default */
  fetch30DayGraphData?: Maybe<StatItemGraph>;
  /** Used for the drilldown chart where the metric is plotted for 30 days by default */
  fetch30DayGraphDataV2?: Maybe<StatItemGraph>;
  fetchAdmins?: Maybe<Array<Maybe<User>>>;
  fetchAllAvailableCondition?: Maybe<Scalars['Json']['output']>;
  fetchAllCustomObject?: Maybe<Array<Maybe<CustomObject>>>;
  fetchAllCustomObjectFields?: Maybe<Array<Maybe<CustomObjectField>>>;
  fetchAllCustomTransferRule?: Maybe<Array<Maybe<Rule>>>;
  fetchAllIntegrationROObject?: Maybe<Array<Maybe<IntegrationReadOnlyObject>>>;
  fetchAllPandaDocTemplateForAdmin?: Maybe<Array<Maybe<PandaDocTemplate>>>;
  fetchAllPandaDocTemplateForRep?: Maybe<Array<Maybe<PandaDocTemplate>>>;
  /** it return sale data, total price, list of products */
  fetchAllSales?: Maybe<Array<Maybe<Sale>>>;
  fetchAllSavedViews?: Maybe<Array<Maybe<DashboardSavedView>>>;
  /** Fetch follow up demos per sequence */
  fetchAnswerRatePerSequence?: Maybe<Scalars['Json']['output']>;
  fetchAssociateContact?: Maybe<Array<Maybe<Lead>>>;
  fetchAssociateContactHistory?: Maybe<Array<Maybe<AssociationHistory>>>;
  fetchAssociatedCustomObjects?: Maybe<Array<Maybe<CustomObjectSummary>>>;
  fetchAutomatedCampaigns?: Maybe<Array<Maybe<AutomatedCampaign>>>;
  /** Return of possible actions a user can take based on lead. Does not include Sale at the moment. Sale is manually filtered out. It will be included later. */
  fetchAvailableActions?: Maybe<Array<Maybe<DialerActions>>>;
  /** Returns available mapping order for a given contact */
  fetchAvailableMappingOrder?: Maybe<Array<Maybe<ValueAndLabelObject>>>;
  fetchAvailableRep?: Maybe<Array<Maybe<ManualTransferRepData>>>;
  fetchAvailableTimes?: Maybe<Array<Maybe<Scalars['Json']['output']>>>;
  fetchAvailableTimesV2?: Maybe<Array<Maybe<Scalars['Json']['output']>>>;
  fetchAvailableTimesV3?: Maybe<Array<Maybe<Scalars['Json']['output']>>>;
  fetchAvaliableConditionOption?: Maybe<Scalars['Json']['output']>;
  /** Fetch available user for event transfer. Currently only fetch AE user */
  fetchAvaliableUserForLeadTransfer?: Maybe<Array<Maybe<User>>>;
  /** Fetch average time in phase per sequence per sequence */
  fetchAverageTimeInPhase?: Maybe<Scalars['Json']['output']>;
  /** Fetch number of bad number calls per sequence */
  fetchBadNumberCountPerSequence?: Maybe<Scalars['Json']['output']>;
  /** fetching bottom funnel summary that returns total leads and forecasted revenue */
  fetchBottomFunnelLeadCount?: Maybe<Scalars['Int']['output']>;
  /** fetching bottom funnel summary that returns total leads and forecasted revenue */
  fetchBottomFunnelLeads?: Maybe<Array<Maybe<Lead>>>;
  /** Used in the Manage Schedule section */
  fetchCalendarSchedule?: Maybe<Array<Maybe<ScheduleItem>>>;
  /** Returns a list of call related activities for lead */
  fetchCallReportForLead?: Maybe<Array<Maybe<LeadActivity>>>;
  /** Returns a list of call related activities. Must pass in a user_id, lead_id, or a team_id. Cannot pass in more than 1. */
  fetchCallReportList?: Maybe<Array<Maybe<LeadActivity>>>;
  /** Returns a list of call related activities. Must pass in a user_id, lead_id, or a team_id. Cannot pass in more than 1. */
  fetchCallReportListV2?: Maybe<Array<Maybe<LeadActivity>>>;
  /** Returns a list of call related activities. Must pass in a user_id, lead_id, or a team_id. Cannot pass in more than 1. */
  fetchCallReportSetHoldSale?: Maybe<CallDetailStatSummary>;
  fetchChooseUserRepList?: Maybe<Array<Maybe<User>>>;
  /** Fetch close rate per sequence */
  fetchCloseRatePerSequence?: Maybe<Scalars['Json']['output']>;
  /** Returns a conference based on id */
  fetchConference?: Maybe<Conference>;
  /** Returns a list of call related activities. Must pass in a conference_id. */
  fetchConferenceCallReportList?: Maybe<Array<Maybe<LeadActivity>>>;
  fetchConferenceJoinInfo?: Maybe<ConferenceJoinInfoObject>;
  fetchConferenceParticipants?: Maybe<Scalars['Json']['output']>;
  /** Fetch average time in phase per sequence per sequence */
  fetchConversionRateFromPhaseToPhase?: Maybe<Scalars['Json']['output']>;
  fetchCurrentAssociateStats?: Maybe<Scalars['Json']['output']>;
  /** Fetches the current lead assigned to the user. This shows up in the lead card. This is being replace by fetchNextDial */
  fetchCurrentLead?: Maybe<LeadIntent>;
  fetchCustomFields?: Maybe<Array<Maybe<CustomField>>>;
  fetchCustomObjectRecord?: Maybe<Array<Maybe<CustomObjectRecord>>>;
  fetchCustomObjectRow?: Maybe<Array<Maybe<CustomObjectRow>>>;
  fetchCustomObjectRowsFromLead?: Maybe<Array<Maybe<CustomObjectRow>>>;
  /** Return's users favorited leads */
  fetchCustomQueueLeads?: Maybe<Array<Maybe<CustomQueueItem>>>;
  fetchCustomRuleSettings?: Maybe<GlobalTransferRule>;
  fetchDashboardColumnOption?: Maybe<DashboardColumnOption>;
  fetchDashboardSavedView?: Maybe<DashboardSavedView>;
  fetchDashboardSharedView?: Maybe<SharedViewObjectType>;
  /** Fetch saved dashboards */
  fetchDashboardsViews?: Maybe<Array<Maybe<SequenceDashboardView>>>;
  fetchDefaultColumnOption?: Maybe<DashboardColumnOption>;
  fetchDefaultHolidays?: Maybe<Array<Maybe<FederalHoliday>>>;
  /** Update default audio input and output */
  fetchDefaultSalesTeamColumnOptions: Array<SalesTeamColumnOptions>;
  fetchDefaultSavedView?: Maybe<DashboardSavedView>;
  /** Returns a list of dispositions */
  fetchDeleteEventDispositionOptions?: Maybe<Array<Maybe<Disposition>>>;
  /** Fetch phone number that can be use for dialing this lead. */
  fetchDialNumbers?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** Returns sort options for lead list sort */
  fetchDialSortOptions?: Maybe<Scalars['Json']['output']>;
  /** Fetch follow up demos per sequence */
  fetchDialsPerSequence?: Maybe<Scalars['Json']['output']>;
  /** Fetch direct revenue */
  fetchDirectRevenue?: Maybe<Scalars['Json']['output']>;
  /** Returns a list of dispositions */
  fetchDispositionOptions?: Maybe<Array<Maybe<Disposition>>>;
  fetchDispositionsTypes?: Maybe<Scalars['Json']['output']>;
  /** Fetch follow dm connect rate per sequence */
  fetchDMRatePerSequence?: Maybe<Scalars['Json']['output']>;
  /** Updates leads in hubspot */
  fetchEligibleEventType?: Maybe<Array<Maybe<System_Event>>>;
  /** Fetch email template preview */
  fetchEmailTemplatePreview?: Maybe<EmailPreview>;
  /** Returns a list of future events */
  fetchEventList?: Maybe<Array<Maybe<LeadIntent>>>;
  /** Returns a list of future events */
  fetchEventListV2?: Maybe<Array<Maybe<LeadIntent>>>;
  /**
   * Used on the Events Manager section. Can only be used by
   *       Admins or Sales Managers. If you pass in dev=true, it will assume the
   *       user is an Admin and return all events for the organization.
   */
  fetchEventManagerEvents?: Maybe<Array<Maybe<Scalars['Json']['output']>>>;
  /** Return's users favorited leads */
  fetchFavoritedLeads?: Maybe<Array<Maybe<Lead>>>;
  /** Get filtered dispositions based on sequence entry criteria selection */
  fetchFilteredCallResultDispositions?: Maybe<Scalars['Json']['output']>;
  /** Fetch the total number of leads based on the filter */
  fetchFilteredLeadCount?: Maybe<Scalars['Int']['output']>;
  /** Retuns a list of CC intents giving the filter constraints */
  fetchFilteredLeadsInQueue?: Maybe<Array<Maybe<LeadIntent>>>;
  /** Returns a folder base on an id */
  fetchFolder?: Maybe<Folder>;
  /** Fetch general call backs. Allow team, org filter. If no filter selected, default to filter by user */
  fetchGeneralCallBacks?: Maybe<Scalars['Json']['output']>;
  fetchGeneralCallbacksForSpecificDate?: Maybe<Array<Maybe<Callback>>>;
  /** @deprecated Deprecated dued to Transfer Tab 2.0 */
  fetchGlobalTransferRule?: Maybe<GlobalTransferRule>;
  /** Fetch direct revenue */
  fetchHoldRatePerSequence?: Maybe<Scalars['Json']['output']>;
  fetchHubSpotSyncRule?: Maybe<OrgHubSpotSync>;
  /** Gets industry options. If no primaryIndustry provided, return all options */
  fetchIndustryOptions?: Maybe<Array<Maybe<IndustryOption>>>;
  /** Fetch sets per sequence */
  fetchInfluencedRevenue?: Maybe<Scalars['Json']['output']>;
  /** Count how many intent on the rep */
  fetchInprogressLead?: Maybe<Scalars['Int']['output']>;
  fetchInProgressLeadActivityImport?: Maybe<LeadImportProgress>;
  fetchInProgressLeadImport?: Maybe<LeadImportProgress>;
  fetchIntegrationError?: Maybe<Array<Maybe<IntegrationErrorLog>>>;
  fetchIntegrationErrorV2?: Maybe<Array<Maybe<IntegrationErrorLog>>>;
  fetchIntegrationErrorV2Count?: Maybe<Scalars['Int']['output']>;
  fetchIntegrationForeignKeys: Array<RoForeignKeySelectOption>;
  fetchIntegrationMappingData?: Maybe<Array<Maybe<ExternalFieldDataType>>>;
  fetchIntegrationMappingOptions?: Maybe<Scalars['Json']['output']>;
  fetchIntegrationMappings?: Maybe<Array<Maybe<IntegrationMapping>>>;
  fetchIntegrationOperationType?: Maybe<Scalars['Json']['output']>;
  fetchIntegrationROFieldOptions?: Maybe<Array<Maybe<RoFieldDetail>>>;
  /** Fetches lead based on id, returns null if lead does not exist */
  fetchLead?: Maybe<Lead>;
  fetchLeadActivityImportHistory?: Maybe<Array<Maybe<LeadImportHistory>>>;
  /** fetches the primary lead and all associates based from a single primary or associate lead id */
  fetchLeadAllAssociatedGroup?: Maybe<Array<Maybe<Lead>>>;
  /** Fetches lead based on id, returns null if lead does not exist */
  fetchLeadAndOwnedRep?: Maybe<LeadAndOwnedRep>;
  /** return list of lead assignment history */
  fetchLeadAssignmentHistory?: Maybe<Array<Maybe<LeadAssignmentHistory>>>;
  fetchLeadCallHistory?: Maybe<Array<Maybe<LeadActivity>>>;
  /** return list of lead call note history */
  fetchLeadCallNoteHistory?: Maybe<Array<Maybe<LeadActivity>>>;
  fetchLeadCustomObjectRecords?: Maybe<Array<Maybe<CustomObjectRecord>>>;
  fetchLeadEmails?: Maybe<Array<Maybe<EmailItem>>>;
  /** Fetches lead based on phone number, returns null if lead does not exist */
  fetchLeadFromNumber?: Maybe<Lead>;
  /** Returns list of full names for the leads */
  fetchLeadFullNames?: Maybe<Array<Maybe<Lead>>>;
  fetchLeadFunnel?: Maybe<Array<Maybe<LeadFunnelItem>>>;
  fetchLeadFunnelDetails?: Maybe<Array<Maybe<Lead>>>;
  /** Fetch past activities of a lead */
  fetchLeadHistory?: Maybe<Array<Maybe<LeadActivity>>>;
  /** Fetch total number of past activities of a lead */
  fetchLeadHistoryCount?: Maybe<Scalars['Int']['output']>;
  fetchLeadImportHistory?: Maybe<Array<Maybe<LeadImportHistory>>>;
  fetchLeadIntegrationROFields?: Maybe<RoLeadFieldData>;
  fetchLeadPoolDetails?: Maybe<Array<Maybe<Lead>>>;
  /** Use to fetch LeadPool table */
  fetchLeadPools?: Maybe<Array<Maybe<LeadPoolItem>>>;
  fetchLeads?: Maybe<Array<Maybe<Lead>>>;
  fetchLeadsFromCustomObjectRow?: Maybe<Array<Maybe<Lead>>>;
  /** Fetch leads in sequence */
  fetchLeadsInSequence?: Maybe<Array<Maybe<Scalars['Json']['output']>>>;
  /** Gets lead source options */
  fetchLeadSourceOptions?: Maybe<Array<Maybe<LeadSourceOption>>>;
  fetchLiveUserStatus?: Maybe<Array<Maybe<LiveUserStatus>>>;
  fetchManualTransferRepList?: Maybe<Array<Maybe<ManualTransferRepData>>>;
  fetchManualTransferRepListV2?: Maybe<Array<Maybe<ManualTransferRepData>>>;
  fetchMappingFieldSelectOption?: Maybe<Array<Maybe<OpsIqExternalMappingOption>>>;
  /** Starts with the start of the current day */
  fetchMissedItemsList?: Maybe<Array<Maybe<LeadIntent>>>;
  /** Returns the available columns that can be chosen in the my leads section */
  fetchMyLeadsColumnOptions?: Maybe<Scalars['Json']['output']>;
  /** Fetch the scheduled events within a certain timeframe */
  fetchMySchedule?: Maybe<MySchedule>;
  /** Fetch the next lead intent in queue */
  fetchNextDial?: Maybe<Array<Maybe<LeadIntent>>>;
  fetchNoEventDate?: Maybe<OrgNoEventDate>;
  fetchNoEventDates?: Maybe<Array<Maybe<OrgNoEventDate>>>;
  fetchNotifications?: Maybe<Array<Maybe<Notification>>>;
  /** Fetch number of tasks completed per sequence */
  fetchNumberOfTasksPerLead?: Maybe<Scalars['Json']['output']>;
  fetchOneCustomObject?: Maybe<CustomObject>;
  fetchOneCustomObjectRow?: Maybe<CustomObjectRow>;
  fetchOneCustomTransferRule?: Maybe<Rule>;
  fetchOneIntegrationROObject?: Maybe<IntegrationReadOnlyObject>;
  fetchOneLeadRoutingRule?: Maybe<Rule>;
  fetchOrganization?: Maybe<Organization>;
  /** Fetch domain on sendgrid */
  fetchOrganizationDomain?: Maybe<Scalars['Json']['output']>;
  fetchOrganizationHolidays?: Maybe<Array<Maybe<OrganizationHoliday>>>;
  fetchOrganizationHours?: Maybe<Array<Maybe<OrganizationHour>>>;
  fetchOrgFutureBookingSetting?: Maybe<FutureBookingSetting>;
  fetchOrgLiveTransferSetting?: Maybe<LiveTransferSetting>;
  fetchOrgPaymentSettings?: Maybe<OrganizationPaymentSettings>;
  fetchOrgTimeslotAvailability?: Maybe<Array<Maybe<OrgTimeslotAvailability>>>;
  fetchPerformanceBySVId?: Maybe<Array<Maybe<StatItem>>>;
  fetchPerformanceChart?: Maybe<Array<Maybe<StatItem>>>;
  fetchPerformanceChartV2?: Maybe<Array<Maybe<StatItem>>>;
  /** Returns a list of dispositions */
  fetchPersonSpokeToOptions?: Maybe<Array<Maybe<Callresult>>>;
  /** fetching top funnel summary that returns total leads and forecasted revenue */
  fetchPipelineAverageField?: Maybe<PipelineAvgFields>;
  /** return pipeline lead available fields  */
  fetchPipelineColumn?: Maybe<Array<Maybe<PipelineMetricData>>>;
  /** Returns a list of future events */
  fetchPipelineList?: Maybe<Array<Maybe<Lead>>>;
  /** Returns a list of future events */
  fetchPipelineListV2?: Maybe<Array<Maybe<Lead>>>;
  /** Gets available primary industry options */
  fetchPrimaryIndustries?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  fetchProducts?: Maybe<Array<Maybe<Product>>>;
  /** Returns the list of recent calls */
  fetchRecentCalls?: Maybe<Array<Maybe<LeadActivity>>>;
  fetchRecentEmailSyncRecords?: Maybe<Array<Maybe<EmailSyncRecord>>>;
  /** fetch OPSIQ's next recommended action base on lead value */
  fetchRecommendedAction?: Maybe<LeadIntent>;
  /** fetch OPSIQ's next recommended dials base on lead value */
  fetchRecommendedDial?: Maybe<LeadIntent>;
  /** Reorder the given user list to OPSIQ order based on close rate */
  fetchRepOrder?: Maybe<Array<Maybe<User>>>;
  fetchRepPreview?: Maybe<UserPreview>;
  fetchReps?: Maybe<Array<Maybe<User>>>;
  fetchRepsAsOptions?: Maybe<Scalars['Json']['output']>;
  /** Returns the columns selected by the rep in the my leads section */
  fetchRepSelectedColumns?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** Return reps from the org with owner of call first */
  fetchRepsFromConference?: Maybe<Array<Maybe<User>>>;
  fetchRequiredFieldsMakeSaleConfig?: Maybe<Scalars['Json']['output']>;
  /** fetching revenue forecastsummary that returns total MRR ,Close % Forcast Value */
  fetchRevenueForcast?: Maybe<Array<Maybe<RevenueSummary>>>;
  fetchRevenuePlan?: Maybe<Array<Maybe<RevenuePlanItem>>>;
  fetchROIntegrationObjectOptions?: Maybe<Array<Maybe<SelectOption>>>;
  fetchRules?: Maybe<Array<Maybe<Rule>>>;
  /** it return sale data, total price, list of products */
  fetchSaleDetail?: Maybe<Sale>;
  fetchSalesforceSyncRule?: Maybe<OrgSalesforceSync>;
  /** Used to retrieve data for the Sales Improvement Box */
  fetchSalesImprovements?: Maybe<SaleImprovement>;
  /** Update default audio input and output */
  fetchSalesTeamColumnOptions: Array<SalesTeamColumnOptions>;
  fetchScheduledItems?: Maybe<Array<Maybe<ScheduleItem>>>;
  fetchScheduleItems?: Maybe<Array<Maybe<ScheduleItem>>>;
  /** Starts with the start of the current day */
  fetchScheduleItemsList?: Maybe<Array<Maybe<ScheduleItem>>>;
  /** Returns a DateTime value for each day that has a scheduled event on it */
  fetchScheduleItemsMonth?: Maybe<Array<Maybe<Scalars['DateTime']['output']>>>;
  /** Fetch Email bounce rate per sequence */
  fetchSequenceEmailBounceRate?: Maybe<Scalars['Json']['output']>;
  /** Fetch Email click rate per sequence */
  fetchSequenceEmailClickRate?: Maybe<Scalars['Json']['output']>;
  /** Fetch Email metrics for all sequences sequence */
  fetchSequenceEmailMetrics?: Maybe<Scalars['Json']['output']>;
  /** Fetch Email open rate per sequence */
  fetchSequenceEmailOpenRate?: Maybe<Scalars['Json']['output']>;
  /** Fetch Email reply rate per sequence */
  fetchSequenceEmailReplyRate?: Maybe<Scalars['Json']['output']>;
  /** Fetch Email unsubscribed rate per sequence */
  fetchSequenceEmailUnsubscribeRate?: Maybe<Scalars['Json']['output']>;
  fetchSequenceReps?: Maybe<Array<Maybe<Scalars['Json']['output']>>>;
  /** Fetch sequences */
  fetchSequences?: Maybe<Array<Maybe<Sequence>>>;
  /** Fetch Email reply rate per sequence */
  fetchSequenceSmsDeliveredRate?: Maybe<Scalars['Json']['output']>;
  /** Fetch sms failed rate per sequence */
  fetchSequenceSmsFailedRate?: Maybe<Scalars['Json']['output']>;
  /** Fetch SMS metrics per sequence */
  fetchSequenceSmsMetrics?: Maybe<Scalars['Json']['output']>;
  /** Fetch sets per sequence */
  fetchSetsPerSequence?: Maybe<Scalars['Json']['output']>;
  /** Returns users who the folder has been shared with */
  fetchSharedUsersFromFolder?: Maybe<Array<Maybe<User>>>;
  fetchSMSItems?: Maybe<Array<Maybe<SmsItem>>>;
  /** Fetch SMS template preview */
  fetchSMSTemplatePreview?: Maybe<SmsPreview>;
  /** Just look at the metric arrays in memory */
  fetchStaticMetrics?: Maybe<Scalars['Json']['output']>;
  /** Fetch sequence step metrics */
  fetchStepMetrics?: Maybe<Scalars['Json']['output']>;
  fetchSuccessRatePerStepType?: Maybe<Array<Maybe<Scalars['Json']['output']>>>;
  /**
   * If the user is in the middle of a scheduled event,
   *       this returns a string indicating what type of scheduled event. Otherwise,
   *       returns null. This gives the user the ability to switch back to the event
   */
  fetchSwitchLeadCardOption?: Maybe<IntentType>;
  /** Returns a DateTime value for each day has a scheduled event on it */
  fetchTakenDays?: Maybe<Array<Maybe<Scalars['DateTime']['output']>>>;
  /** Fetch Email bounce rate per sequence */
  fetchTaskCompletionAvgSpeedBySequence?: Maybe<Scalars['Json']['output']>;
  /** Fetch Email unsubscribed rate per sequence */
  fetchTaskCompletionRateBySequence?: Maybe<Scalars['Json']['output']>;
  fetchTasksSnoozedPerSequence?: Maybe<Array<Maybe<Scalars['Json']['output']>>>;
  fetchTeamNames?: Maybe<Array<Maybe<Team>>>;
  fetchTeams?: Maybe<Array<Maybe<Team>>>;
  /** Fetch templates */
  fetchTemplates?: Maybe<Array<Maybe<Template>>>;
  /** Fetch templates count */
  fetchTemplatesCount?: Maybe<Scalars['Int']['output']>;
  /** Fetch tokens and their fallbacks for SMS or email template creation */
  fetchTokensForTemplate?: Maybe<Scalars['Json']['output']>;
  /** fetching top funnel summary that returns total leads and forecasted revenue */
  fetchTopFunnelLeadCount?: Maybe<Scalars['Int']['output']>;
  /** fetching top funnel summary that returns total leads and forecasted revenue */
  fetchTopFunnelLeads?: Maybe<Array<Maybe<Lead>>>;
  fetchTouchedLeads?: Maybe<Array<Maybe<Lead>>>;
  fetchTransferAttempt?: Maybe<TransferAttempt>;
  /** Returns a list of call related activities. Must pass in a user_id, lead_id, or a team_id. Cannot pass in more than 1. */
  fetchTransferCallListV2?: Maybe<Array<Maybe<LeadActivity>>>;
  fetchTransferRepList?: Maybe<Array<Maybe<LiveUserStatus>>>;
  fetchUnassignedUsers?: Maybe<Array<Maybe<User>>>;
  /** Fetch number of tasks completed per sequence */
  fetchUncompletedTasksPerSequence?: Maybe<Scalars['Json']['output']>;
  fetchUser?: Maybe<User>;
  /** Returns all possible users that the folder can be shared with. */
  fetchUserForSharing?: Maybe<Array<Maybe<User>>>;
  fetchUsers?: Maybe<Array<Maybe<User>>>;
  fetchUsersAndPreviews?: Maybe<Array<Maybe<UserWithUserPreview>>>;
  /** Fetch the team members if user is SM and fetch the organization members if user is ADMIN. Return only AE and SDR. */
  fetchUsersUnder?: Maybe<Array<Maybe<User>>>;
  /** Returns folders that are viewable by the user. */
  fetchViewableFolders?: Maybe<Array<Maybe<Folder>>>;
  /** Fetch follow up demos per sequence */
  fetchVoicemailRatePerSequence?: Maybe<Scalars['Json']['output']>;
  /** Fetch leads based on a filter */
  filterLeads?: Maybe<Scalars['Json']['output']>;
  findFirstSalesForceData?: Maybe<SalesForceData>;
  findFirstSFOpportunity?: Maybe<SfOpportunity>;
  /** organization id in folder permission model backfill */
  folderPermissionsBackfill?: Maybe<Scalars['String']['output']>;
  /** organization id in folder model backfill */
  foldersBackfill?: Maybe<Scalars['String']['output']>;
  /** return all assignable leads (for Cold Call LeadIntent ) per industry for organization */
  getAllAssignableLeadsPerIndustry?: Maybe<LeadIntentStats>;
  getCallResultOptions?: Maybe<Scalars['Json']['output']>;
  getCityOptions?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  getCompanyBestAllTime?: Maybe<Scalars['Json']['output']>;
  /** gets options for custom field */
  getCustomFieldOptions?: Maybe<Scalars['Json']['output']>;
  getDispositionChangeOptions?: Maybe<Array<Maybe<DispositionChangeResponse>>>;
  /** Get dispositions by phase */
  getDispositionsByPhase?: Maybe<Scalars['Json']['output']>;
  getDispositionTypeOptions?: Maybe<Scalars['Json']['output']>;
  getHubSpotSyncSettings?: Maybe<HubSpotCredential>;
  getInboundConciergeWidgetSettings?: Maybe<InboundConciergeWidget>;
  /** Returns list of Assignment Options */
  getLeadAssignmentOptions?: Maybe<Scalars['Json']['output']>;
  getLeadPhase?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** Returns details about how the LV is calculated by lead */
  getLeadValueAudit?: Maybe<Scalars['Json']['output']>;
  /** return each users in progress/contacted (that had more than on Lead Activity) per industry */
  getOrgLeadActivityStatsByIndustry?: Maybe<Array<Maybe<LeadIntentStats>>>;
  /** return each users ColdCall LeadIntents per industry */
  getOrgLeadIntentStatsByIndustry?: Maybe<Array<Maybe<LeadIntentStats>>>;
  getProgress?: Maybe<Scalars['Json']['output']>;
  getStateOptions?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  getTZOptions?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** Returns list of unique industries */
  getUniqueIndustries?: Maybe<Scalars['Json']['output']>;
  /** Returns list of unique lead sources */
  getUniqueLeadSources?: Maybe<Scalars['Json']['output']>;
  /** return one user's ColdCall LeadIntents per industry */
  getUserLeadIntentStatsByIndustry?: Maybe<LeadIntentStats>;
  internalTest?: Maybe<Scalars['Boolean']['output']>;
  /** Returns count of leads */
  leadCountByOrg?: Maybe<Scalars['Json']['output']>;
  leadValuesCount?: Maybe<Scalars['Int']['output']>;
  /** organization id in note item model backfill */
  noteItemsBackfill?: Maybe<Scalars['String']['output']>;
  /** organization id in notification model backfill */
  notificationsbackfill?: Maybe<Scalars['String']['output']>;
  orgPhoneNumbers?: Maybe<Array<Maybe<Scalars['Json']['output']>>>;
  previewPandaDocData?: Maybe<Scalars['Json']['output']>;
  product?: Maybe<Product>;
  products: Array<Product>;
  /** conference recording track backfill */
  recordingTrackBackFill?: Maybe<Scalars['Boolean']['output']>;
  /** uptime.com check to see if there was a routing in the last 24 hours. */
  routingHistoryCheck?: Maybe<Scalars['Boolean']['output']>;
  /** organization id in routing model backfill */
  routingsBackfill?: Maybe<Scalars['String']['output']>;
  /** organization id in sale cycle model backfill */
  saleCyclesBackfill?: Maybe<Scalars['String']['output']>;
  /** organization id in sale item model backfill */
  saleItemsBackfill?: Maybe<Scalars['String']['output']>;
  salesForceDataCount?: Maybe<Scalars['Int']['output']>;
  scheduleItem?: Maybe<ScheduleItem>;
  /** organization id in schedule item model backfill */
  scheduleItemsBackFill?: Maybe<Scalars['String']['output']>;
  searchIndustry?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** organization id in sequence criteria condition model backfill */
  sequenceCriteriaConditionsBackfill?: Maybe<Scalars['String']['output']>;
  sequenceCriteriaConditionsCount?: Maybe<Scalars['Int']['output']>;
  /** organization id in sequence dashboard view model backfill */
  sequenceDashboardViewsBackfill?: Maybe<Scalars['String']['output']>;
  /** organization id in sequence entry criteria model backfill */
  sequenceEntryCriteriaBackfill?: Maybe<Scalars['String']['output']>;
  sequenceEntryCriteriaCount?: Maybe<Scalars['Int']['output']>;
  sequencesCount?: Maybe<Scalars['Int']['output']>;
  /** organization id in sequence step model backfill */
  sequenceStepsBackfill?: Maybe<Scalars['String']['output']>;
  sfOpportunities: Array<SfOpportunity>;
  sFOpportunitiesCount?: Maybe<Scalars['Int']['output']>;
  sfOpportunity?: Maybe<SfOpportunity>;
  /** organization id in sms item model backfill */
  smsItemsBackfill?: Maybe<Scalars['String']['output']>;
  /** organization id in snooze tracking model backfill */
  snoozeTrackingsBackfill?: Maybe<Scalars['String']['output']>;
  /** Used for the Stack Rank chart on the dashboard */
  stackRankList?: Maybe<Array<Maybe<StatItem>>>;
  /** Used for the Stack Rank chart on the dashboard */
  stackRankListV2?: Maybe<Array<Maybe<StatItem>>>;
  statItemAggregationsCount?: Maybe<Scalars['Int']['output']>;
  statLeadAggregationsCount?: Maybe<Scalars['Int']['output']>;
  /** organization id in step action log model backfill */
  stepActionLogsBackfill?: Maybe<Scalars['String']['output']>;
  /** organization id in step action model backfill */
  stepActionsBackfill?: Maybe<Scalars['String']['output']>;
  stepActionsCount?: Maybe<Scalars['Int']['output']>;
  /** organization id in step condition model backfill */
  stepConditionsBackfill?: Maybe<Scalars['String']['output']>;
  stepConditionsCount?: Maybe<Scalars['Int']['output']>;
  team?: Maybe<Team>;
  /** organization id in template version model backfill */
  templateVersionBackfill?: Maybe<Scalars['String']['output']>;
  /** Used for the drilldown chart where the metric is plotted for 30 days by default */
  testDashboardData?: Maybe<Scalars['Json']['output']>;
  /** create new lead for algo purposes */
  testFaker?: Maybe<Scalars['String']['output']>;
  /** Request from Tom K */
  tomKReport?: Maybe<Scalars['Json']['output']>;
  /** organization id in transfer attempt model backfill */
  transferAttemptsBackfill?: Maybe<Scalars['String']['output']>;
  triggeringEvents?: Maybe<Array<Maybe<SelectOption>>>;
  /** organization id in twilio feedback model backfill */
  twilioFeedbackBackfill?: Maybe<Scalars['String']['output']>;
  twilioInfo?: Maybe<TwilioInfo>;
  twilioInfos: Array<TwilioInfo>;
  twilioInfosCount?: Maybe<Scalars['Int']['output']>;
  user?: Maybe<User>;
  /** organization id in user custom field filter model backfill */
  userCustomFieldFiltersBackfill?: Maybe<Scalars['String']['output']>;
};


export type QueryAutoScheduleSelectionRepArgs = {
  lead_id: Scalars['String']['input'];
};


export type QueryAutoTransferCallArgs = {
  lead_id: Scalars['String']['input'];
  multiplier: Scalars['Int']['input'];
};


export type QueryBestMetricRecordArgs = {
  where: BestMetricRecordWhereUniqueInput;
};


export type QueryBestMetricRecordsArgs = {
  after?: InputMaybe<BestMetricRecordWhereUniqueInput>;
  before?: InputMaybe<BestMetricRecordWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<BestMetricRecordOrderByWithRelationInput>>;
  where?: InputMaybe<BestMetricRecordWhereInput>;
};


export type QueryCallMeNowStatusArgs = {
  conciergeEventId: Scalars['String']['input'];
};


export type QueryCheckLeadPhoneNumberArgs = {
  country_code?: InputMaybe<Scalars['String']['input']>;
  phone_number: Scalars['String']['input'];
};


export type QueryComputeRepCloseRateArgs = {
  data: RepRatingRuleInput;
};


export type QueryDispoClickReportArgs = {
  organization_id: Scalars['String']['input'];
};


export type QueryFetch30DayGraphDataArgs = {
  channel_filter?: InputMaybe<Channel>;
  computed_id: Scalars['String']['input'];
  dashboard_filter?: InputMaybe<DashboardFilter>;
  lowerbound_date?: InputMaybe<Scalars['DateTime']['input']>;
  metric: Metric;
  position: Position;
  product_ids_filter?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  upperbound_date?: InputMaybe<Scalars['DateTime']['input']>;
};


export type QueryFetch30DayGraphDataV2Args = {
  dashboard_detail_args: DashboardDetailArgument;
};


export type QueryFetchAllCustomObjectFieldsArgs = {
  custom_object_id: Scalars['String']['input'];
};


export type QueryFetchAllCustomTransferRuleArgs = {
  rule_type?: InputMaybe<RuleType>;
};


export type QueryFetchAllIntegrationRoObjectArgs = {
  integration: Integration_Type;
};


export type QueryFetchAllSalesArgs = {
  associate_lead_ids: Array<Scalars['String']['input']>;
  lead_id: Scalars['String']['input'];
};


export type QueryFetchAnswerRatePerSequenceArgs = {
  SequenceDashboardInput: SequenceDashboardInput;
};


export type QueryFetchAssociateContactArgs = {
  primary_lead_id: Scalars['String']['input'];
};


export type QueryFetchAssociateContactHistoryArgs = {
  lead_id: Scalars['String']['input'];
};


export type QueryFetchAssociatedCustomObjectsArgs = {
  lead_id: Scalars['String']['input'];
};


export type QueryFetchAvailableActionsArgs = {
  lead_id: Scalars['String']['input'];
  person_spoke_to?: InputMaybe<Callresult>;
};


export type QueryFetchAvailableMappingOrderArgs = {
  channel: ContactType;
  current_mapping_order?: InputMaybe<Scalars['Int']['input']>;
  lead_id: Scalars['String']['input'];
};


export type QueryFetchAvailableRepArgs = {
  rep_ids: Array<Scalars['String']['input']>;
};


export type QueryFetchAvailableTimesArgs = {
  action?: InputMaybe<Scalars['String']['input']>;
  callback?: InputMaybe<Scalars['Boolean']['input']>;
  day?: InputMaybe<Scalars['DateTime']['input']>;
  lead_id?: InputMaybe<Scalars['String']['input']>;
  reschedule_own_event?: InputMaybe<Scalars['Boolean']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
};


export type QueryFetchAvailableTimesV2Args = {
  action?: InputMaybe<Scalars['String']['input']>;
  concierge_event_id?: InputMaybe<Scalars['String']['input']>;
  day?: InputMaybe<Scalars['DateTime']['input']>;
  lead_id?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
};


export type QueryFetchAvailableTimesV3Args = {
  action?: InputMaybe<Scalars['String']['input']>;
  day?: InputMaybe<Scalars['DateTime']['input']>;
  lead_id?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
};


export type QueryFetchAvaliableUserForLeadTransferArgs = {
  lead_id: Scalars['String']['input'];
};


export type QueryFetchAverageTimeInPhaseArgs = {
  SequenceDashboardInput: SequenceDashboardInput;
};


export type QueryFetchBadNumberCountPerSequenceArgs = {
  SequenceDashboardInput: SequenceDashboardInput;
};


export type QueryFetchBottomFunnelLeadCountArgs = {
  pipeline_management_arg: PipelineManagementArg;
};


export type QueryFetchBottomFunnelLeadsArgs = {
  organization_id?: InputMaybe<Scalars['String']['input']>;
  pipeline_management_lead_args: PipelineManagementLeadArg;
};


export type QueryFetchCalendarScheduleArgs = {
  month?: InputMaybe<Month>;
};


export type QueryFetchCallReportForLeadArgs = {
  call_report_lead_args: CallReportLeadArgument;
};


export type QueryFetchCallReportListArgs = {
  channel_filter?: InputMaybe<Channel>;
  dashboard_filter?: InputMaybe<DashboardFilter>;
  desc?: InputMaybe<Scalars['Boolean']['input']>;
  lead_id?: InputMaybe<Scalars['String']['input']>;
  lowerbound_date?: InputMaybe<Scalars['Date']['input']>;
  metric?: InputMaybe<Metric>;
  order_by?: InputMaybe<CallReportListSortOption>;
  organization_id?: InputMaybe<Scalars['String']['input']>;
  product_ids_filter?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  team_id?: InputMaybe<Scalars['String']['input']>;
  upperbound_date?: InputMaybe<Scalars['Date']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
};


export type QueryFetchCallReportListV2Args = {
  dashboard_detail_args: DashboardDetailArgument;
};


export type QueryFetchCallReportSetHoldSaleArgs = {
  dashboard_detail_args: DashboardDetailArgument;
};


export type QueryFetchCloseRatePerSequenceArgs = {
  SequenceDashboardInput: SequenceDashboardInput;
};


export type QueryFetchConferenceArgs = {
  conference_id: Scalars['String']['input'];
};


export type QueryFetchConferenceCallReportListArgs = {
  conference_id: Scalars['String']['input'];
};


export type QueryFetchConversionRateFromPhaseToPhaseArgs = {
  SequenceDashboardInput: SequenceDashboardInput;
};


export type QueryFetchCurrentAssociateStatsArgs = {
  lead_id: Scalars['String']['input'];
};


export type QueryFetchCurrentLeadArgs = {
  override_timezone_contraints?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryFetchCustomObjectRecordArgs = {
  custom_object_id: Scalars['String']['input'];
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryFetchCustomObjectRowArgs = {
  custom_object_id: Scalars['String']['input'];
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryFetchCustomObjectRowsFromLeadArgs = {
  custom_object_id: Scalars['String']['input'];
  lead_id: Scalars['String']['input'];
};


export type QueryFetchCustomQueueLeadsArgs = {
  lead_filter?: InputMaybe<LeadFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sort_option?: InputMaybe<DialSortOption>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryFetchCustomRuleSettingsArgs = {
  transfer_rule_type?: InputMaybe<TransferRuleType>;
};


export type QueryFetchDashboardColumnOptionArgs = {
  saved_view_id: Scalars['String']['input'];
};


export type QueryFetchDashboardSavedViewArgs = {
  saved_view_id: Scalars['String']['input'];
};


export type QueryFetchDashboardSharedViewArgs = {
  dashboard_type: DashboardType;
  saved_view_id: Scalars['String']['input'];
};


export type QueryFetchDashboardsViewsArgs = {
  view_id?: InputMaybe<Scalars['String']['input']>;
};


export type QueryFetchDefaultHolidaysArgs = {
  year: Scalars['Int']['input'];
};


export type QueryFetchDeleteEventDispositionOptionsArgs = {
  lead_id: Scalars['String']['input'];
};


export type QueryFetchDialNumbersArgs = {
  lead_id?: InputMaybe<Scalars['String']['input']>;
  lead_phone_number?: InputMaybe<Scalars['String']['input']>;
};


export type QueryFetchDialsPerSequenceArgs = {
  SequenceDashboardInput: SequenceDashboardInput;
};


export type QueryFetchDirectRevenueArgs = {
  SequenceDashboardInput: SequenceDashboardInput;
};


export type QueryFetchDispositionOptionsArgs = {
  associated_action?: InputMaybe<Scalars['String']['input']>;
  lead_id: Scalars['String']['input'];
  person_spoke_to?: InputMaybe<Callresult>;
};


export type QueryFetchDispositionsTypesArgs = {
  show_label?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryFetchDmRatePerSequenceArgs = {
  SequenceDashboardInput: SequenceDashboardInput;
};


export type QueryFetchEligibleEventTypeArgs = {
  integration_type: Integration_Type;
};


export type QueryFetchEmailTemplatePreviewArgs = {
  lead_id?: InputMaybe<Scalars['String']['input']>;
  template_id: Scalars['String']['input'];
};


export type QueryFetchEventListArgs = {
  dashboard_filter?: InputMaybe<DashboardFilter>;
  lead_id?: InputMaybe<Scalars['String']['input']>;
  lowerbound_date?: InputMaybe<Scalars['Date']['input']>;
  metric?: InputMaybe<Scalars['String']['input']>;
  organization_id?: InputMaybe<Scalars['String']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  team_id?: InputMaybe<Scalars['String']['input']>;
  upperbound_date?: InputMaybe<Scalars['Date']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
};


export type QueryFetchEventListV2Args = {
  dashboard_detail_args: EventDetailArgument;
};


export type QueryFetchEventManagerEventsArgs = {
  dev?: InputMaybe<Scalars['Boolean']['input']>;
  rep_id?: InputMaybe<Scalars['String']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryFetchFavoritedLeadsArgs = {
  lead_filter?: InputMaybe<LeadFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sort_option?: InputMaybe<DialSortOption>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryFetchFilteredCallResultDispositionsArgs = {
  cold_call_aggregate?: InputMaybe<Scalars['Boolean']['input']>;
  phase: Scalars['String']['input'];
};


export type QueryFetchFilteredLeadCountArgs = {
  lead_filter?: InputMaybe<LeadFilter>;
  lead_system_operators?: InputMaybe<LeadSystemOperators>;
  my_leads?: Scalars['Boolean']['input'];
  search_text?: InputMaybe<Scalars['String']['input']>;
};


export type QueryFetchFilteredLeadsInQueueArgs = {
  lead_filter?: InputMaybe<LeadFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sort_option?: InputMaybe<DialSortOption>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryFetchFolderArgs = {
  folder_id: Scalars['String']['input'];
};


export type QueryFetchGeneralCallBacksArgs = {
  month?: InputMaybe<Month>;
  org_filter?: InputMaybe<Scalars['Boolean']['input']>;
  team_filter?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryFetchGeneralCallbacksForSpecificDateArgs = {
  date: Scalars['DateTime']['input'];
};


export type QueryFetchHoldRatePerSequenceArgs = {
  SequenceDashboardInput: SequenceDashboardInput;
};


export type QueryFetchIndustryOptionsArgs = {
  include_others?: InputMaybe<Scalars['Boolean']['input']>;
  primaryIndustry?: InputMaybe<Scalars['String']['input']>;
};


export type QueryFetchInfluencedRevenueArgs = {
  SequenceDashboardInput: SequenceDashboardInput;
};


export type QueryFetchInprogressLeadArgs = {
  user_id: Scalars['String']['input'];
};


export type QueryFetchIntegrationErrorArgs = {
  integration_type: Integration_Type;
  skip?: Scalars['Int']['input'];
  take?: Scalars['Int']['input'];
};


export type QueryFetchIntegrationErrorV2Args = {
  intergration_args: IntegrationLogArgument;
};


export type QueryFetchIntegrationErrorV2CountArgs = {
  intergration_args: IntegrationLogArgument;
};


export type QueryFetchIntegrationForeignKeysArgs = {
  external_object: Scalars['String']['input'];
  integration: Integration_Type;
};


export type QueryFetchIntegrationMappingDataArgs = {
  external_object: Scalars['String']['input'];
  integration: Integration_Type;
};


export type QueryFetchIntegrationMappingOptionsArgs = {
  event_types: Array<System_Event>;
  integration: Integration_Type;
  template_id?: InputMaybe<Scalars['String']['input']>;
};


export type QueryFetchIntegrationMappingsArgs = {
  integration_type: Integration_Type;
  template_id?: InputMaybe<Scalars['String']['input']>;
};


export type QueryFetchIntegrationOperationTypeArgs = {
  integration_type: Integration_Type;
};


export type QueryFetchIntegrationRoFieldOptionsArgs = {
  external_object: Scalars['String']['input'];
  integration: Integration_Type;
};


export type QueryFetchLeadArgs = {
  always_return_primary?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
};


export type QueryFetchLeadActivityImportHistoryArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryFetchLeadAllAssociatedGroupArgs = {
  id: Scalars['String']['input'];
};


export type QueryFetchLeadAndOwnedRepArgs = {
  id: Scalars['String']['input'];
};


export type QueryFetchLeadAssignmentHistoryArgs = {
  lead_id: Scalars['String']['input'];
  show_all_lead?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryFetchLeadCallHistoryArgs = {
  lead_id: Scalars['String']['input'];
};


export type QueryFetchLeadCallNoteHistoryArgs = {
  lead_id: Scalars['String']['input'];
  show_all_lead?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryFetchLeadCustomObjectRecordsArgs = {
  custom_object_id: Scalars['String']['input'];
  lead_id: Scalars['String']['input'];
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryFetchLeadEmailsArgs = {
  lead_id: Scalars['String']['input'];
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryFetchLeadFromNumberArgs = {
  phone_number?: InputMaybe<Scalars['String']['input']>;
};


export type QueryFetchLeadFullNamesArgs = {
  lead_ids: Array<Scalars['String']['input']>;
};


export type QueryFetchLeadFunnelArgs = {
  show_teams?: InputMaybe<Scalars['Boolean']['input']>;
  team_id?: InputMaybe<Scalars['String']['input']>;
  view_filter?: InputMaybe<LeadPoolViewFilter>;
};


export type QueryFetchLeadFunnelDetailsArgs = {
  ascending?: Scalars['Boolean']['input'];
  industry?: InputMaybe<Scalars['String']['input']>;
  lead_creation_source?: InputMaybe<Scalars['String']['input']>;
  lead_source?: InputMaybe<Scalars['String']['input']>;
  metric?: InputMaybe<LeadFunnelMetrics>;
  organization_id?: InputMaybe<Scalars['String']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sort_option?: InputMaybe<LeadFunnelSortOption>;
  take?: InputMaybe<Scalars['Int']['input']>;
  team_id?: InputMaybe<Scalars['String']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
};


export type QueryFetchLeadHistoryArgs = {
  event_filter?: InputMaybe<HistoryEventTypeOption>;
  lead_id: Scalars['String']['input'];
  show_all_lead?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryFetchLeadHistoryCountArgs = {
  event_filter?: InputMaybe<HistoryEventTypeOption>;
  lead_id: Scalars['String']['input'];
  show_all_lead?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryFetchLeadImportHistoryArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryFetchLeadIntegrationRoFieldsArgs = {
  integration: Integration_Type;
  lead_id: Scalars['String']['input'];
};


export type QueryFetchLeadPoolDetailsArgs = {
  ascending?: Scalars['Boolean']['input'];
  industry?: InputMaybe<Scalars['String']['input']>;
  lead_creation_source?: InputMaybe<Scalars['String']['input']>;
  lead_source?: InputMaybe<Scalars['String']['input']>;
  metric?: InputMaybe<LeadPoolMetrics>;
  organization_id?: InputMaybe<Scalars['String']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sort_option?: InputMaybe<LeadPoolSortOption>;
  take?: InputMaybe<Scalars['Int']['input']>;
  team_id?: InputMaybe<Scalars['String']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
};


export type QueryFetchLeadPoolsArgs = {
  show_teams?: InputMaybe<Scalars['Boolean']['input']>;
  team_id?: InputMaybe<Scalars['String']['input']>;
  view_filter?: InputMaybe<LeadPoolViewFilter>;
};


export type QueryFetchLeadsArgs = {
  searchText?: InputMaybe<Scalars['String']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryFetchLeadsFromCustomObjectRowArgs = {
  row_id: Scalars['String']['input'];
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryFetchLeadsInSequenceArgs = {
  query_type: LeadInSequenceQuery;
  sequence_id: Scalars['String']['input'];
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryFetchLiveUserStatusArgs = {
  statuses?: InputMaybe<Array<Scalars['String']['input']>>;
  team_ids?: InputMaybe<Array<Scalars['String']['input']>>;
  user_ids?: InputMaybe<Array<Scalars['String']['input']>>;
};


export type QueryFetchManualTransferRepListArgs = {
  ascending: Scalars['Boolean']['input'];
  lead_id: Scalars['String']['input'];
  sort_option: TransferSortOption;
};


export type QueryFetchManualTransferRepListV2Args = {
  ascending: Scalars['Boolean']['input'];
  lead_id: Scalars['String']['input'];
  sort_option: TransferSortOption;
};


export type QueryFetchMappingFieldSelectOptionArgs = {
  integration_mapping_id: Scalars['String']['input'];
};


export type QueryFetchMissedItemsListArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
};


export type QueryFetchMyScheduleArgs = {
  date: Scalars['DateTime']['input'];
};


export type QueryFetchNextDialArgs = {
  lead_filter?: InputMaybe<LeadFilter>;
  override_timezone_contraints?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryFetchNoEventDateArgs = {
  id: Scalars['String']['input'];
};


export type QueryFetchNotificationsArgs = {
  dev?: InputMaybe<Scalars['String']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryFetchNumberOfTasksPerLeadArgs = {
  SequenceDashboardInput: SequenceDashboardInput;
};


export type QueryFetchOneCustomObjectArgs = {
  custom_object_id: Scalars['String']['input'];
};


export type QueryFetchOneCustomObjectRowArgs = {
  custom_object_row_id: Scalars['String']['input'];
};


export type QueryFetchOneCustomTransferRuleArgs = {
  rule_id: Scalars['String']['input'];
};


export type QueryFetchOneIntegrationRoObjectArgs = {
  id: Scalars['String']['input'];
};


export type QueryFetchOneLeadRoutingRuleArgs = {
  rule_id: Scalars['String']['input'];
};


export type QueryFetchPerformanceBySvIdArgs = {
  dashboard_type: DashboardType;
  saved_view_id: Scalars['String']['input'];
};


export type QueryFetchPerformanceChartArgs = {
  channel_filter?: InputMaybe<Channel>;
  coaching_view?: InputMaybe<Scalars['Boolean']['input']>;
  dashboard_filter?: InputMaybe<DashboardFilter>;
  dashboard_type?: InputMaybe<DashboardType>;
  lowerbound_date?: InputMaybe<Scalars['Date']['input']>;
  new_version?: InputMaybe<Scalars['Boolean']['input']>;
  product_ids_filter?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  show_teams?: InputMaybe<Scalars['Boolean']['input']>;
  team_id?: InputMaybe<Scalars['String']['input']>;
  team_id_stack_rank?: InputMaybe<Scalars['String']['input']>;
  upperbound_date?: InputMaybe<Scalars['Date']['input']>;
  user_id_stack_rank?: InputMaybe<Scalars['String']['input']>;
};


export type QueryFetchPerformanceChartV2Args = {
  dashboard_args: DashBoardSavedViewArgument;
  dashboard_type: DashboardType;
};


export type QueryFetchPersonSpokeToOptionsArgs = {
  associated_action?: InputMaybe<Scalars['String']['input']>;
  lead_id: Scalars['String']['input'];
};


export type QueryFetchPipelineAverageFieldArgs = {
  pipeline_arg: PipelineAvgFieldInput;
};


export type QueryFetchPipelineListArgs = {
  dashboard_filter?: InputMaybe<DashboardFilter>;
  lead_id?: InputMaybe<Scalars['String']['input']>;
  lowerbound_date?: InputMaybe<Scalars['Date']['input']>;
  metric?: InputMaybe<Scalars['String']['input']>;
  organization_id?: InputMaybe<Scalars['String']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  team_id?: InputMaybe<Scalars['String']['input']>;
  upperbound_date?: InputMaybe<Scalars['Date']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
};


export type QueryFetchPipelineListV2Args = {
  dashboard_detail_args: LeadDetailArgument;
};


export type QueryFetchProductsArgs = {
  include_inactive?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryFetchRecentCallsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryFetchRecentEmailSyncRecordsArgs = {
  lead_id: Scalars['String']['input'];
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryFetchRecommendedActionArgs = {
  lead_filter?: InputMaybe<LeadFilter>;
};


export type QueryFetchRepOrderArgs = {
  user_ids: Array<Scalars['String']['input']>;
};


export type QueryFetchRepPreviewArgs = {
  user_id: Scalars['String']['input'];
};


export type QueryFetchRepsFromConferenceArgs = {
  conference_id: Scalars['String']['input'];
};


export type QueryFetchRevenueForcastArgs = {
  organization_id?: InputMaybe<Scalars['String']['input']>;
  pipeline_management_arg: PipelineManagementArg;
};


export type QueryFetchRoIntegrationObjectOptionsArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
  integration: Integration_Type;
};


export type QueryFetchRulesArgs = {
  rule_type?: InputMaybe<RuleType>;
};


export type QueryFetchSaleDetailArgs = {
  sale_id: Scalars['String']['input'];
};


export type QueryFetchSalesImprovementsArgs = {
  level?: InputMaybe<Position>;
  team_id?: InputMaybe<Scalars['String']['input']>;
};


export type QueryFetchScheduledItemsArgs = {
  lead_id: Scalars['String']['input'];
  show_all_lead?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryFetchScheduleItemsArgs = {
  day?: InputMaybe<Scalars['DateTime']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
};


export type QueryFetchScheduleItemsListArgs = {
  day?: InputMaybe<Scalars['DateTime']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
};


export type QueryFetchScheduleItemsMonthArgs = {
  action?: InputMaybe<Scalars['String']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
  yearmonth?: InputMaybe<Scalars['String']['input']>;
};


export type QueryFetchSequenceEmailBounceRateArgs = {
  SequenceDashboardInput: SequenceDashboardInput;
};


export type QueryFetchSequenceEmailClickRateArgs = {
  SequenceDashboardInput: SequenceDashboardInput;
};


export type QueryFetchSequenceEmailMetricsArgs = {
  SequenceDashboardInput: SequenceDashboardInput;
};


export type QueryFetchSequenceEmailOpenRateArgs = {
  SequenceDashboardInput: SequenceDashboardInput;
};


export type QueryFetchSequenceEmailReplyRateArgs = {
  SequenceDashboardInput: SequenceDashboardInput;
};


export type QueryFetchSequenceEmailUnsubscribeRateArgs = {
  SequenceDashboardInput: SequenceDashboardInput;
};


export type QueryFetchSequenceRepsArgs = {
  SequenceDashboardInput: SequenceDashboardInput;
  sortBy: RepsSortOrderSequenceQuery;
};


export type QueryFetchSequencesArgs = {
  SequencesFilter: SequencesFilter;
};


export type QueryFetchSequenceSmsDeliveredRateArgs = {
  SequenceDashboardInput: SequenceDashboardInput;
};


export type QueryFetchSequenceSmsFailedRateArgs = {
  SequenceDashboardInput: SequenceDashboardInput;
};


export type QueryFetchSequenceSmsMetricsArgs = {
  SequenceDashboardInput: SequenceDashboardInput;
};


export type QueryFetchSetsPerSequenceArgs = {
  SequenceDashboardInput: SequenceDashboardInput;
};


export type QueryFetchSharedUsersFromFolderArgs = {
  folder_id: Scalars['String']['input'];
};


export type QueryFetchSmsItemsArgs = {
  all_reps_visible?: InputMaybe<Scalars['Boolean']['input']>;
  lead_id?: InputMaybe<Scalars['String']['input']>;
  lead_phone_number?: InputMaybe<Scalars['String']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryFetchSmsTemplatePreviewArgs = {
  lead_id?: InputMaybe<Scalars['String']['input']>;
  template_id: Scalars['String']['input'];
};


export type QueryFetchStepMetricsArgs = {
  step_id: Scalars['String']['input'];
};


export type QueryFetchSuccessRatePerStepTypeArgs = {
  SequenceDashboardInput: SequenceDashboardInput;
};


export type QueryFetchTakenDaysArgs = {
  user_id?: InputMaybe<Scalars['String']['input']>;
};


export type QueryFetchTaskCompletionAvgSpeedBySequenceArgs = {
  SequenceDashboardInput: SequenceDashboardInput;
};


export type QueryFetchTaskCompletionRateBySequenceArgs = {
  SequenceDashboardInput: SequenceDashboardInput;
};


export type QueryFetchTasksSnoozedPerSequenceArgs = {
  SequenceDashboardInput: SequenceDashboardInput;
};


export type QueryFetchTemplatesArgs = {
  TemplateFilter: TemplateFilter;
};


export type QueryFetchTemplatesCountArgs = {
  TemplateFilter: TemplateFilter;
};


export type QueryFetchTopFunnelLeadCountArgs = {
  pipeline_management_arg: PipelineManagementArg;
};


export type QueryFetchTopFunnelLeadsArgs = {
  organization_id?: InputMaybe<Scalars['String']['input']>;
  pipeline_management_lead_args: PipelineManagementLeadArg;
};


export type QueryFetchTouchedLeadsArgs = {
  searchText?: InputMaybe<Scalars['String']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryFetchTransferAttemptArgs = {
  transfer_attempt_id: Scalars['String']['input'];
};


export type QueryFetchTransferCallListV2Args = {
  dashboard_detail_args: DashboardDetailArgument;
};


export type QueryFetchUncompletedTasksPerSequenceArgs = {
  SequenceDashboardInput: SequenceDashboardInput;
};


export type QueryFetchUserArgs = {
  user_id?: InputMaybe<Scalars['String']['input']>;
};


export type QueryFetchUsersAndPreviewsArgs = {
  user_ids: Array<Scalars['String']['input']>;
};


export type QueryFetchVoicemailRatePerSequenceArgs = {
  SequenceDashboardInput: SequenceDashboardInput;
};


export type QueryFilterLeadsArgs = {
  ascending?: Scalars['Boolean']['input'];
  lead_filter?: InputMaybe<LeadFilter>;
  lead_system_operators?: InputMaybe<LeadSystemOperators>;
  my_leads?: Scalars['Boolean']['input'];
  order_by?: InputMaybe<Scalars['String']['input']>;
  search_text?: InputMaybe<Scalars['String']['input']>;
  skip?: Scalars['Int']['input'];
  take?: Scalars['Int']['input'];
};


export type QueryFindFirstSalesForceDataArgs = {
  cursor?: InputMaybe<SalesForceDataWhereUniqueInput>;
  orderBy?: InputMaybe<SalesForceDataOrderByWithRelationInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SalesForceDataWhereInput>;
};


export type QueryFindFirstSfOpportunityArgs = {
  cursor?: InputMaybe<SfOpportunityWhereUniqueInput>;
  orderBy?: InputMaybe<SfOpportunityOrderByWithRelationInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SfOpportunityWhereInput>;
};


export type QueryGetAllAssignableLeadsPerIndustryArgs = {
  user_email: Scalars['String']['input'];
};


export type QueryGetCompanyBestAllTimeArgs = {
  metric: Metric;
  position: Scalars['String']['input'];
  record_type: Best_Metric_Record_Type;
};


export type QueryGetCustomFieldOptionsArgs = {
  key: Scalars['String']['input'];
};


export type QueryGetDispositionChangeOptionsArgs = {
  lead_activity_id: Scalars['String']['input'];
};


export type QueryGetDispositionsByPhaseArgs = {
  phase: Scalars['String']['input'];
};


export type QueryGetLeadValueAuditArgs = {
  lead_activity_id?: InputMaybe<Scalars['String']['input']>;
  lead_id: Scalars['String']['input'];
};


export type QueryGetOrgLeadActivityStatsByIndustryArgs = {
  user_email: Scalars['String']['input'];
};


export type QueryGetOrgLeadIntentStatsByIndustryArgs = {
  user_email: Scalars['String']['input'];
};


export type QueryGetUserLeadIntentStatsByIndustryArgs = {
  user_email: Scalars['String']['input'];
};


export type QueryLeadCountByOrgArgs = {
  organization_id: Scalars['String']['input'];
};


export type QueryLeadValuesCountArgs = {
  where?: InputMaybe<LeadValueWhereInput>;
};


export type QueryPreviewPandaDocDataArgs = {
  lead_id: Scalars['String']['input'];
  product_data: ProductDataType;
  products: Array<ProductInputType>;
  sale_id?: InputMaybe<Scalars['String']['input']>;
  template_id: Scalars['String']['input'];
};


export type QueryProductArgs = {
  where: ProductWhereUniqueInput;
};


export type QueryProductsArgs = {
  after?: InputMaybe<ProductWhereUniqueInput>;
  before?: InputMaybe<ProductWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProductOrderByWithRelationInput>>;
  where?: InputMaybe<ProductWhereInput>;
};


export type QueryRecordingTrackBackFillArgs = {
  lower_range: Scalars['DateTime']['input'];
  track?: InputMaybe<Scalars['String']['input']>;
  upper_range: Scalars['DateTime']['input'];
};


export type QuerySalesForceDataCountArgs = {
  where?: InputMaybe<SalesForceDataWhereInput>;
};


export type QueryScheduleItemArgs = {
  where: ScheduleItemWhereUniqueInput;
};


export type QuerySearchIndustryArgs = {
  primary_industry?: InputMaybe<Scalars['String']['input']>;
  search_text: Scalars['String']['input'];
};


export type QuerySequenceCriteriaConditionsCountArgs = {
  where?: InputMaybe<SequenceCriteriaConditionWhereInput>;
};


export type QuerySequenceEntryCriteriaCountArgs = {
  where?: InputMaybe<SequenceEntryCriteriaWhereInput>;
};


export type QuerySequencesCountArgs = {
  where?: InputMaybe<SequenceWhereInput>;
};


export type QuerySfOpportunitiesArgs = {
  after?: InputMaybe<SfOpportunityWhereUniqueInput>;
  before?: InputMaybe<SfOpportunityWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<SfOpportunityOrderByWithRelationInput>>;
  where?: InputMaybe<SfOpportunityWhereInput>;
};


export type QuerySfOpportunitiesCountArgs = {
  where?: InputMaybe<SfOpportunityWhereInput>;
};


export type QuerySfOpportunityArgs = {
  where: SfOpportunityWhereUniqueInput;
};


export type QueryStackRankListArgs = {
  channel_filter?: InputMaybe<Channel>;
  computed_id: Scalars['String']['input'];
  dashboard_filter?: InputMaybe<DashboardFilter>;
  dashboard_type?: InputMaybe<DashboardType>;
  lowerbound_date?: InputMaybe<Scalars['DateTime']['input']>;
  metric: Metric;
  only_team?: InputMaybe<Scalars['Boolean']['input']>;
  position?: InputMaybe<Position>;
  product_ids_filter?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  upperbound_date?: InputMaybe<Scalars['DateTime']['input']>;
};


export type QueryStackRankListV2Args = {
  dashboard_detail_args: DashboardDetailArgument;
  only_team?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryStatItemAggregationsCountArgs = {
  where?: InputMaybe<StatItemAggregationWhereInput>;
};


export type QueryStatLeadAggregationsCountArgs = {
  where?: InputMaybe<StatLeadAggregationWhereInput>;
};


export type QueryStepActionsCountArgs = {
  where?: InputMaybe<StepActionWhereInput>;
};


export type QueryStepConditionsCountArgs = {
  where?: InputMaybe<StepConditionWhereInput>;
};


export type QueryTeamArgs = {
  where: TeamWhereUniqueInput;
};


export type QueryTestDashboardDataArgs = {
  computed_id: Scalars['String']['input'];
  dashboard_type: DashboardType;
  metric: Metric;
  position?: InputMaybe<Position>;
};


export type QueryTomKReportArgs = {
  end_date: Scalars['DateTime']['input'];
  organization_id: Scalars['String']['input'];
  start_date: Scalars['DateTime']['input'];
};


export type QueryTwilioInfoArgs = {
  where: TwilioInfoWhereUniqueInput;
};


export type QueryTwilioInfosArgs = {
  after?: InputMaybe<TwilioInfoWhereUniqueInput>;
  before?: InputMaybe<TwilioInfoWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TwilioInfoOrderByWithRelationInput>>;
  where?: InputMaybe<TwilioInfoWhereInput>;
};


export type QueryTwilioInfosCountArgs = {
  where?: InputMaybe<TwilioInfoWhereInput>;
};


export type QueryUserArgs = {
  where: UserWhereUniqueInput;
};

export enum QueryMode {
  Default = 'default',
  Insensitive = 'insensitive'
}

export enum RankSortType {
  Dynamically = 'Dynamically',
  Manually = 'Manually'
}

export type RecordingStateLaw = {
  __typename?: 'RecordingStateLaw';
  created_at: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  recording_law: Consentlaw;
  state: State;
  updated_at: Scalars['DateTime']['output'];
};

export enum RecordingStatus {
  Default = 'Default',
  Disabled = 'Disabled',
  Enabled = 'Enabled'
}

/** User Batch Edit Input */
export type RegionBatchEditInput = {
  assign?: InputMaybe<AssignType>;
  regions_white_list_array?: InputMaybe<Array<Scalars['String']['input']>>;
};

export enum Rep_Selection_Method {
  BestRep = 'BestRep',
  FirstRep = 'FirstRep',
  None = 'None'
}

/** Filter inputs for call result filter */
export type RepActivityFilter = {
  rep_lowerbound_date?: InputMaybe<Scalars['DateTime']['input']>;
  rep_upperbound_date?: InputMaybe<Scalars['DateTime']['input']>;
  user_ids?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export enum RepPriorityRule {
  NotOnCall = 'NotOnCall',
  NotOnDemo = 'NotOnDemo'
}

/** RepRatingRuleInput to compute the rep rate before saving as entity */
export type RepRatingRuleInput = {
  date_range: DateOption;
  rank_sort_type: RankSortType;
  rep_ids: Array<Scalars['String']['input']>;
};

/** Reps history in sequence sorting order */
export enum RepsSortOrderSequenceQuery {
  FastestTasksCompleted = 'fastest_tasks_completed',
  FewestTasksDeleted = 'fewest_tasks_deleted',
  MostTasksDeleted = 'most_tasks_deleted',
  SequenceCompletionRate = 'sequence_completion_rate',
  TasksCompleted = 'tasks_completed'
}

export enum Restingtype {
  Release = 'Release',
  Short = 'Short',
  Skipped = 'Skipped',
  Standard = 'Standard'
}

export enum ResultType {
  Failure = 'Failure',
  InProgress = 'InProgress',
  Success = 'Success'
}

/** Used to input availability into a mutation/query */
export type RevenueItemInput = {
  /** Id of the availability day. Not required. */
  id: Scalars['String']['input'];
  month: Month;
  revenue_goal: Scalars['Float']['input'];
  year: Scalars['String']['input'];
};

export type RevenuePlanItem = {
  __typename?: 'RevenuePlanItem';
  /** Returns the actual revenue of the company. */
  actual_revenue?: Maybe<Scalars['Float']['output']>;
  id: Scalars['String']['output'];
  month?: Maybe<Month>;
  organization?: Maybe<Organization>;
  organization_id?: Maybe<Scalars['String']['output']>;
  revenue_goal?: Maybe<Scalars['Float']['output']>;
  /** number of working days in the month */
  working_days?: Maybe<Scalars['Int']['output']>;
  year?: Maybe<Scalars['String']['output']>;
};

export type RevenuePlanItemCreateManyOrganizationInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  month?: InputMaybe<Month>;
  revenue_goal?: InputMaybe<Scalars['Float']['input']>;
  year?: InputMaybe<Scalars['String']['input']>;
};

export type RevenuePlanItemCreateManyOrganizationInputEnvelope = {
  data?: InputMaybe<Array<RevenuePlanItemCreateManyOrganizationInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type RevenuePlanItemCreateNestedManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<RevenuePlanItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<RevenuePlanItemCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<RevenuePlanItemCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<RevenuePlanItemCreateManyOrganizationInputEnvelope>;
};

export type RevenuePlanItemCreateOrConnectWithoutOrganizationInput = {
  create: RevenuePlanItemCreateWithoutOrganizationInput;
  where: RevenuePlanItemWhereUniqueInput;
};

export type RevenuePlanItemCreateWithoutOrganizationInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  month?: InputMaybe<Month>;
  revenue_goal?: InputMaybe<Scalars['Float']['input']>;
  year?: InputMaybe<Scalars['String']['input']>;
};

export type RevenuePlanItemListRelationFilter = {
  every?: InputMaybe<RevenuePlanItemWhereInput>;
  none?: InputMaybe<RevenuePlanItemWhereInput>;
  some?: InputMaybe<RevenuePlanItemWhereInput>;
};

export type RevenuePlanItemOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
  count?: InputMaybe<SortOrder>;
};

export type RevenuePlanItemScalarWhereInput = {
  AND?: InputMaybe<Array<RevenuePlanItemScalarWhereInput>>;
  id?: InputMaybe<StringFilter>;
  month?: InputMaybe<EnumMonthNullableFilter>;
  NOT?: InputMaybe<Array<RevenuePlanItemScalarWhereInput>>;
  OR?: InputMaybe<Array<RevenuePlanItemScalarWhereInput>>;
  organization_id?: InputMaybe<StringNullableFilter>;
  revenue_goal?: InputMaybe<FloatNullableFilter>;
  year?: InputMaybe<StringNullableFilter>;
};

export type RevenuePlanItemUpdateManyMutationInput = {
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  month?: InputMaybe<NullableEnumMonthFieldUpdateOperationsInput>;
  revenue_goal?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  year?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type RevenuePlanItemUpdateManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<RevenuePlanItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<RevenuePlanItemCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<RevenuePlanItemCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<RevenuePlanItemCreateManyOrganizationInputEnvelope>;
  delete?: InputMaybe<Array<RevenuePlanItemWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<RevenuePlanItemScalarWhereInput>>;
  disconnect?: InputMaybe<Array<RevenuePlanItemWhereUniqueInput>>;
  set?: InputMaybe<Array<RevenuePlanItemWhereUniqueInput>>;
  update?: InputMaybe<Array<RevenuePlanItemUpdateWithWhereUniqueWithoutOrganizationInput>>;
  updateMany?: InputMaybe<Array<RevenuePlanItemUpdateManyWithWhereWithoutOrganizationInput>>;
  upsert?: InputMaybe<Array<RevenuePlanItemUpsertWithWhereUniqueWithoutOrganizationInput>>;
};

export type RevenuePlanItemUpdateManyWithWhereWithoutOrganizationInput = {
  data: RevenuePlanItemUpdateManyMutationInput;
  where: RevenuePlanItemScalarWhereInput;
};

export type RevenuePlanItemUpdateWithoutOrganizationInput = {
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  month?: InputMaybe<NullableEnumMonthFieldUpdateOperationsInput>;
  revenue_goal?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  year?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type RevenuePlanItemUpdateWithWhereUniqueWithoutOrganizationInput = {
  data: RevenuePlanItemUpdateWithoutOrganizationInput;
  where: RevenuePlanItemWhereUniqueInput;
};

export type RevenuePlanItemUpsertWithWhereUniqueWithoutOrganizationInput = {
  create: RevenuePlanItemCreateWithoutOrganizationInput;
  update: RevenuePlanItemUpdateWithoutOrganizationInput;
  where: RevenuePlanItemWhereUniqueInput;
};

export type RevenuePlanItemWhereInput = {
  AND?: InputMaybe<Array<RevenuePlanItemWhereInput>>;
  id?: InputMaybe<StringFilter>;
  month?: InputMaybe<EnumMonthNullableFilter>;
  NOT?: InputMaybe<Array<RevenuePlanItemWhereInput>>;
  OR?: InputMaybe<Array<RevenuePlanItemWhereInput>>;
  organization?: InputMaybe<OrganizationWhereInput>;
  organization_id?: InputMaybe<StringNullableFilter>;
  revenue_goal?: InputMaybe<FloatNullableFilter>;
  year?: InputMaybe<StringNullableFilter>;
};

export type RevenuePlanItemWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']['input']>;
};

export type RevenueSummary = {
  __typename?: 'RevenueSummary';
  forcasted_revenue?: Maybe<Scalars['Float']['output']>;
  pipeline_metric?: Maybe<PipelineColumn>;
  probability?: Maybe<Scalars['Float']['output']>;
  total_mrr?: Maybe<Scalars['Float']['output']>;
};

export type RoFieldDetail = {
  __typename?: 'ROFieldDetail';
  data_type?: Maybe<Scalars['String']['output']>;
  display?: Maybe<Scalars['Boolean']['output']>;
  field_label?: Maybe<Scalars['String']['output']>;
  field_name?: Maybe<Scalars['String']['output']>;
};

export type RoFieldDetailInput = {
  data_type: Scalars['String']['input'];
  display: Scalars['Boolean']['input'];
  field_label: Scalars['String']['input'];
  field_name: Scalars['String']['input'];
};

/** Used for select fields */
export type RoForeignKeySelectOption = {
  __typename?: 'ROForeignKeySelectOption';
  integration_fk_field?: Maybe<Scalars['String']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export enum Role {
  Admin = 'ADMIN',
  Ae = 'AE',
  Dataops = 'DATAOPS',
  Ps = 'PS',
  Sdr = 'SDR',
  Sm = 'SM',
  Superadmin = 'SUPERADMIN'
}

export type RoLeadFieldData = {
  __typename?: 'ROLeadFieldData';
  data?: Maybe<Scalars['Json']['output']>;
  ops_status?: Maybe<Scalars['String']['output']>;
  ops_status_boolean?: Maybe<Scalars['Boolean']['output']>;
};

export type Routing = {
  __typename?: 'Routing';
  close_rate?: Maybe<Scalars['Float']['output']>;
  created_at: Scalars['DateTime']['output'];
  rank: Scalars['Int']['output'];
  rep: User;
  rep_id: Scalars['String']['output'];
  route_count: Scalars['Int']['output'];
  routing_history: Array<RoutingHistory>;
  rule: Rule;
  rule_id: Scalars['String']['output'];
};


export type RoutingRouting_HistoryArgs = {
  after?: InputMaybe<RoutingHistoryWhereUniqueInput>;
  before?: InputMaybe<RoutingHistoryWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** Filter inputs for lead intents */
export type RoutingCondition = {
  cadence?: InputMaybe<Cadence>;
  entity?: InputMaybe<EntityType>;
  field: Scalars['String']['input'];
  field_label?: InputMaybe<Scalars['String']['input']>;
  object?: InputMaybe<Scalars['String']['input']>;
  operator: Operator;
  type: ConditionType;
  value: Scalars['String']['input'];
};

export type RoutingCreateManyOrganizationInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  rank?: InputMaybe<Scalars['Int']['input']>;
  rep_id: Scalars['String']['input'];
  route_count?: InputMaybe<Scalars['Int']['input']>;
  rule_id: Scalars['String']['input'];
};

export type RoutingCreateManyOrganizationInputEnvelope = {
  data?: InputMaybe<Array<RoutingCreateManyOrganizationInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type RoutingCreateManyRepInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  organization_id?: InputMaybe<Scalars['String']['input']>;
  rank?: InputMaybe<Scalars['Int']['input']>;
  route_count?: InputMaybe<Scalars['Int']['input']>;
  rule_id: Scalars['String']['input'];
};

export type RoutingCreateManyRepInputEnvelope = {
  data?: InputMaybe<Array<RoutingCreateManyRepInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type RoutingCreateManyRuleInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  organization_id?: InputMaybe<Scalars['String']['input']>;
  rank?: InputMaybe<Scalars['Int']['input']>;
  rep_id: Scalars['String']['input'];
  route_count?: InputMaybe<Scalars['Int']['input']>;
};

export type RoutingCreateManyRuleInputEnvelope = {
  data?: InputMaybe<Array<RoutingCreateManyRuleInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type RoutingCreateNestedManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<RoutingWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<RoutingCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<RoutingCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<RoutingCreateManyOrganizationInputEnvelope>;
};

export type RoutingCreateNestedManyWithoutRepInput = {
  connect?: InputMaybe<Array<RoutingWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<RoutingCreateOrConnectWithoutRepInput>>;
  create?: InputMaybe<Array<RoutingCreateWithoutRepInput>>;
  createMany?: InputMaybe<RoutingCreateManyRepInputEnvelope>;
};

export type RoutingCreateNestedManyWithoutRuleInput = {
  connect?: InputMaybe<Array<RoutingWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<RoutingCreateOrConnectWithoutRuleInput>>;
  create?: InputMaybe<Array<RoutingCreateWithoutRuleInput>>;
  createMany?: InputMaybe<RoutingCreateManyRuleInputEnvelope>;
};

export type RoutingCreateNestedOneWithoutRouting_HistoryInput = {
  connect?: InputMaybe<RoutingWhereUniqueInput>;
  connectOrCreate?: InputMaybe<RoutingCreateOrConnectWithoutRouting_HistoryInput>;
  create?: InputMaybe<RoutingCreateWithoutRouting_HistoryInput>;
};

export type RoutingCreateOrConnectWithoutOrganizationInput = {
  create: RoutingCreateWithoutOrganizationInput;
  where: RoutingWhereUniqueInput;
};

export type RoutingCreateOrConnectWithoutRepInput = {
  create: RoutingCreateWithoutRepInput;
  where: RoutingWhereUniqueInput;
};

export type RoutingCreateOrConnectWithoutRouting_HistoryInput = {
  create: RoutingCreateWithoutRouting_HistoryInput;
  where: RoutingWhereUniqueInput;
};

export type RoutingCreateOrConnectWithoutRuleInput = {
  create: RoutingCreateWithoutRuleInput;
  where: RoutingWhereUniqueInput;
};

export type RoutingCreateWithoutOrganizationInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  rank?: InputMaybe<Scalars['Int']['input']>;
  rep: UserCreateNestedOneWithoutRoutingsInput;
  route_count?: InputMaybe<Scalars['Int']['input']>;
  routing_history?: InputMaybe<RoutingHistoryCreateNestedManyWithoutRouteInput>;
  rule: RuleCreateNestedOneWithoutRoutingsInput;
};

export type RoutingCreateWithoutRepInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  Organization?: InputMaybe<OrganizationCreateNestedOneWithoutRoutingsInput>;
  rank?: InputMaybe<Scalars['Int']['input']>;
  route_count?: InputMaybe<Scalars['Int']['input']>;
  routing_history?: InputMaybe<RoutingHistoryCreateNestedManyWithoutRouteInput>;
  rule: RuleCreateNestedOneWithoutRoutingsInput;
};

export type RoutingCreateWithoutRouting_HistoryInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  Organization?: InputMaybe<OrganizationCreateNestedOneWithoutRoutingsInput>;
  rank?: InputMaybe<Scalars['Int']['input']>;
  rep: UserCreateNestedOneWithoutRoutingsInput;
  route_count?: InputMaybe<Scalars['Int']['input']>;
  rule: RuleCreateNestedOneWithoutRoutingsInput;
};

export type RoutingCreateWithoutRuleInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  Organization?: InputMaybe<OrganizationCreateNestedOneWithoutRoutingsInput>;
  rank?: InputMaybe<Scalars['Int']['input']>;
  rep: UserCreateNestedOneWithoutRoutingsInput;
  route_count?: InputMaybe<Scalars['Int']['input']>;
  routing_history?: InputMaybe<RoutingHistoryCreateNestedManyWithoutRouteInput>;
};

export type RoutingHistory = {
  __typename?: 'RoutingHistory';
  created_at: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  lead: Lead;
  lead_id: Scalars['String']['output'];
  rep_id: Scalars['String']['output'];
  route?: Maybe<Routing>;
  rule_id: Scalars['String']['output'];
};

export type RoutingHistoryCreateManyLeadInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  rep_id: Scalars['String']['input'];
  rule_id: Scalars['String']['input'];
};

export type RoutingHistoryCreateManyLeadInputEnvelope = {
  data?: InputMaybe<Array<RoutingHistoryCreateManyLeadInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type RoutingHistoryCreateManyRouteInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  lead_id: Scalars['String']['input'];
};

export type RoutingHistoryCreateManyRouteInputEnvelope = {
  data?: InputMaybe<Array<RoutingHistoryCreateManyRouteInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type RoutingHistoryCreateNestedManyWithoutLeadInput = {
  connect?: InputMaybe<Array<RoutingHistoryWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<RoutingHistoryCreateOrConnectWithoutLeadInput>>;
  create?: InputMaybe<Array<RoutingHistoryCreateWithoutLeadInput>>;
  createMany?: InputMaybe<RoutingHistoryCreateManyLeadInputEnvelope>;
};

export type RoutingHistoryCreateNestedManyWithoutRouteInput = {
  connect?: InputMaybe<Array<RoutingHistoryWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<RoutingHistoryCreateOrConnectWithoutRouteInput>>;
  create?: InputMaybe<Array<RoutingHistoryCreateWithoutRouteInput>>;
  createMany?: InputMaybe<RoutingHistoryCreateManyRouteInputEnvelope>;
};

export type RoutingHistoryCreateNestedOneWithoutInboundConciergeEventInput = {
  connect?: InputMaybe<RoutingHistoryWhereUniqueInput>;
  connectOrCreate?: InputMaybe<RoutingHistoryCreateOrConnectWithoutInboundConciergeEventInput>;
  create?: InputMaybe<RoutingHistoryCreateWithoutInboundConciergeEventInput>;
};

export type RoutingHistoryCreateOrConnectWithoutInboundConciergeEventInput = {
  create: RoutingHistoryCreateWithoutInboundConciergeEventInput;
  where: RoutingHistoryWhereUniqueInput;
};

export type RoutingHistoryCreateOrConnectWithoutLeadInput = {
  create: RoutingHistoryCreateWithoutLeadInput;
  where: RoutingHistoryWhereUniqueInput;
};

export type RoutingHistoryCreateOrConnectWithoutRouteInput = {
  create: RoutingHistoryCreateWithoutRouteInput;
  where: RoutingHistoryWhereUniqueInput;
};

export type RoutingHistoryCreateWithoutInboundConciergeEventInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  lead: LeadCreateNestedOneWithoutRouting_HistoryInput;
  route?: InputMaybe<RoutingCreateNestedOneWithoutRouting_HistoryInput>;
};

export type RoutingHistoryCreateWithoutLeadInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  inboundConciergeEvent?: InputMaybe<InboundConciergeEventCreateNestedOneWithoutRouting_HistoryInput>;
  route?: InputMaybe<RoutingCreateNestedOneWithoutRouting_HistoryInput>;
};

export type RoutingHistoryCreateWithoutRouteInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  inboundConciergeEvent?: InputMaybe<InboundConciergeEventCreateNestedOneWithoutRouting_HistoryInput>;
  lead: LeadCreateNestedOneWithoutRouting_HistoryInput;
};

export type RoutingHistoryListRelationFilter = {
  every?: InputMaybe<RoutingHistoryWhereInput>;
  none?: InputMaybe<RoutingHistoryWhereInput>;
  some?: InputMaybe<RoutingHistoryWhereInput>;
};

export type RoutingHistoryOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
  count?: InputMaybe<SortOrder>;
};

export type RoutingHistoryOrderByWithRelationInput = {
  created_at?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  inboundConciergeEvent?: InputMaybe<InboundConciergeEventOrderByWithRelationInput>;
  lead?: InputMaybe<LeadOrderByWithRelationInput>;
  lead_id?: InputMaybe<SortOrder>;
  rep_id?: InputMaybe<SortOrder>;
  route?: InputMaybe<RoutingOrderByWithRelationInput>;
  rule_id?: InputMaybe<SortOrder>;
};

export type RoutingHistoryScalarWhereInput = {
  AND?: InputMaybe<Array<RoutingHistoryScalarWhereInput>>;
  created_at?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  lead_id?: InputMaybe<StringFilter>;
  NOT?: InputMaybe<Array<RoutingHistoryScalarWhereInput>>;
  OR?: InputMaybe<Array<RoutingHistoryScalarWhereInput>>;
  rep_id?: InputMaybe<StringFilter>;
  rule_id?: InputMaybe<StringFilter>;
};

export type RoutingHistoryUpdateManyMutationInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type RoutingHistoryUpdateManyWithoutLeadInput = {
  connect?: InputMaybe<Array<RoutingHistoryWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<RoutingHistoryCreateOrConnectWithoutLeadInput>>;
  create?: InputMaybe<Array<RoutingHistoryCreateWithoutLeadInput>>;
  createMany?: InputMaybe<RoutingHistoryCreateManyLeadInputEnvelope>;
  delete?: InputMaybe<Array<RoutingHistoryWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<RoutingHistoryScalarWhereInput>>;
  disconnect?: InputMaybe<Array<RoutingHistoryWhereUniqueInput>>;
  set?: InputMaybe<Array<RoutingHistoryWhereUniqueInput>>;
  update?: InputMaybe<Array<RoutingHistoryUpdateWithWhereUniqueWithoutLeadInput>>;
  updateMany?: InputMaybe<Array<RoutingHistoryUpdateManyWithWhereWithoutLeadInput>>;
  upsert?: InputMaybe<Array<RoutingHistoryUpsertWithWhereUniqueWithoutLeadInput>>;
};

export type RoutingHistoryUpdateManyWithoutRouteInput = {
  connect?: InputMaybe<Array<RoutingHistoryWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<RoutingHistoryCreateOrConnectWithoutRouteInput>>;
  create?: InputMaybe<Array<RoutingHistoryCreateWithoutRouteInput>>;
  createMany?: InputMaybe<RoutingHistoryCreateManyRouteInputEnvelope>;
  delete?: InputMaybe<Array<RoutingHistoryWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<RoutingHistoryScalarWhereInput>>;
  disconnect?: InputMaybe<Array<RoutingHistoryWhereUniqueInput>>;
  set?: InputMaybe<Array<RoutingHistoryWhereUniqueInput>>;
  update?: InputMaybe<Array<RoutingHistoryUpdateWithWhereUniqueWithoutRouteInput>>;
  updateMany?: InputMaybe<Array<RoutingHistoryUpdateManyWithWhereWithoutRouteInput>>;
  upsert?: InputMaybe<Array<RoutingHistoryUpsertWithWhereUniqueWithoutRouteInput>>;
};

export type RoutingHistoryUpdateManyWithWhereWithoutLeadInput = {
  data: RoutingHistoryUpdateManyMutationInput;
  where: RoutingHistoryScalarWhereInput;
};

export type RoutingHistoryUpdateManyWithWhereWithoutRouteInput = {
  data: RoutingHistoryUpdateManyMutationInput;
  where: RoutingHistoryScalarWhereInput;
};

export type RoutingHistoryUpdateOneWithoutInboundConciergeEventInput = {
  connect?: InputMaybe<RoutingHistoryWhereUniqueInput>;
  connectOrCreate?: InputMaybe<RoutingHistoryCreateOrConnectWithoutInboundConciergeEventInput>;
  create?: InputMaybe<RoutingHistoryCreateWithoutInboundConciergeEventInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<RoutingHistoryUpdateWithoutInboundConciergeEventInput>;
  upsert?: InputMaybe<RoutingHistoryUpsertWithoutInboundConciergeEventInput>;
};

export type RoutingHistoryUpdateWithoutInboundConciergeEventInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lead?: InputMaybe<LeadUpdateOneRequiredWithoutRouting_HistoryInput>;
  route?: InputMaybe<RoutingUpdateOneWithoutRouting_HistoryInput>;
};

export type RoutingHistoryUpdateWithoutLeadInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inboundConciergeEvent?: InputMaybe<InboundConciergeEventUpdateOneWithoutRouting_HistoryInput>;
  route?: InputMaybe<RoutingUpdateOneWithoutRouting_HistoryInput>;
};

export type RoutingHistoryUpdateWithoutRouteInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inboundConciergeEvent?: InputMaybe<InboundConciergeEventUpdateOneWithoutRouting_HistoryInput>;
  lead?: InputMaybe<LeadUpdateOneRequiredWithoutRouting_HistoryInput>;
};

export type RoutingHistoryUpdateWithWhereUniqueWithoutLeadInput = {
  data: RoutingHistoryUpdateWithoutLeadInput;
  where: RoutingHistoryWhereUniqueInput;
};

export type RoutingHistoryUpdateWithWhereUniqueWithoutRouteInput = {
  data: RoutingHistoryUpdateWithoutRouteInput;
  where: RoutingHistoryWhereUniqueInput;
};

export type RoutingHistoryUpsertWithoutInboundConciergeEventInput = {
  create: RoutingHistoryCreateWithoutInboundConciergeEventInput;
  update: RoutingHistoryUpdateWithoutInboundConciergeEventInput;
};

export type RoutingHistoryUpsertWithWhereUniqueWithoutLeadInput = {
  create: RoutingHistoryCreateWithoutLeadInput;
  update: RoutingHistoryUpdateWithoutLeadInput;
  where: RoutingHistoryWhereUniqueInput;
};

export type RoutingHistoryUpsertWithWhereUniqueWithoutRouteInput = {
  create: RoutingHistoryCreateWithoutRouteInput;
  update: RoutingHistoryUpdateWithoutRouteInput;
  where: RoutingHistoryWhereUniqueInput;
};

export type RoutingHistoryWhereInput = {
  AND?: InputMaybe<Array<RoutingHistoryWhereInput>>;
  created_at?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  inboundConciergeEvent?: InputMaybe<InboundConciergeEventWhereInput>;
  lead?: InputMaybe<LeadWhereInput>;
  lead_id?: InputMaybe<StringFilter>;
  NOT?: InputMaybe<Array<RoutingHistoryWhereInput>>;
  OR?: InputMaybe<Array<RoutingHistoryWhereInput>>;
  rep_id?: InputMaybe<StringFilter>;
  route?: InputMaybe<RoutingWhereInput>;
  rule_id?: InputMaybe<StringFilter>;
};

export type RoutingHistoryWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']['input']>;
};

export type RoutingListRelationFilter = {
  every?: InputMaybe<RoutingWhereInput>;
  none?: InputMaybe<RoutingWhereInput>;
  some?: InputMaybe<RoutingWhereInput>;
};

export type RoutingOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
  count?: InputMaybe<SortOrder>;
};

export type RoutingOrderByWithRelationInput = {
  created_at?: InputMaybe<SortOrder>;
  Organization?: InputMaybe<OrganizationOrderByWithRelationInput>;
  organization_id?: InputMaybe<SortOrder>;
  rank?: InputMaybe<SortOrder>;
  rep?: InputMaybe<UserOrderByWithRelationInput>;
  rep_id?: InputMaybe<SortOrder>;
  route_count?: InputMaybe<SortOrder>;
  routing_history?: InputMaybe<RoutingHistoryOrderByRelationAggregateInput>;
  rule?: InputMaybe<RuleOrderByWithRelationInput>;
  rule_id?: InputMaybe<SortOrder>;
};

export type RoutingRep_IdRule_IdCompoundUniqueInput = {
  rep_id: Scalars['String']['input'];
  rule_id: Scalars['String']['input'];
};

export type RoutingScalarWhereInput = {
  AND?: InputMaybe<Array<RoutingScalarWhereInput>>;
  created_at?: InputMaybe<DateTimeFilter>;
  NOT?: InputMaybe<Array<RoutingScalarWhereInput>>;
  OR?: InputMaybe<Array<RoutingScalarWhereInput>>;
  organization_id?: InputMaybe<StringNullableFilter>;
  rank?: InputMaybe<IntFilter>;
  rep_id?: InputMaybe<StringFilter>;
  route_count?: InputMaybe<IntFilter>;
  rule_id?: InputMaybe<StringFilter>;
};

export type RoutingUpdateManyMutationInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  rank?: InputMaybe<IntFieldUpdateOperationsInput>;
  route_count?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type RoutingUpdateManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<RoutingWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<RoutingCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<RoutingCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<RoutingCreateManyOrganizationInputEnvelope>;
  delete?: InputMaybe<Array<RoutingWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<RoutingScalarWhereInput>>;
  disconnect?: InputMaybe<Array<RoutingWhereUniqueInput>>;
  set?: InputMaybe<Array<RoutingWhereUniqueInput>>;
  update?: InputMaybe<Array<RoutingUpdateWithWhereUniqueWithoutOrganizationInput>>;
  updateMany?: InputMaybe<Array<RoutingUpdateManyWithWhereWithoutOrganizationInput>>;
  upsert?: InputMaybe<Array<RoutingUpsertWithWhereUniqueWithoutOrganizationInput>>;
};

export type RoutingUpdateManyWithoutRepInput = {
  connect?: InputMaybe<Array<RoutingWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<RoutingCreateOrConnectWithoutRepInput>>;
  create?: InputMaybe<Array<RoutingCreateWithoutRepInput>>;
  createMany?: InputMaybe<RoutingCreateManyRepInputEnvelope>;
  delete?: InputMaybe<Array<RoutingWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<RoutingScalarWhereInput>>;
  disconnect?: InputMaybe<Array<RoutingWhereUniqueInput>>;
  set?: InputMaybe<Array<RoutingWhereUniqueInput>>;
  update?: InputMaybe<Array<RoutingUpdateWithWhereUniqueWithoutRepInput>>;
  updateMany?: InputMaybe<Array<RoutingUpdateManyWithWhereWithoutRepInput>>;
  upsert?: InputMaybe<Array<RoutingUpsertWithWhereUniqueWithoutRepInput>>;
};

export type RoutingUpdateManyWithoutRuleInput = {
  connect?: InputMaybe<Array<RoutingWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<RoutingCreateOrConnectWithoutRuleInput>>;
  create?: InputMaybe<Array<RoutingCreateWithoutRuleInput>>;
  createMany?: InputMaybe<RoutingCreateManyRuleInputEnvelope>;
  delete?: InputMaybe<Array<RoutingWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<RoutingScalarWhereInput>>;
  disconnect?: InputMaybe<Array<RoutingWhereUniqueInput>>;
  set?: InputMaybe<Array<RoutingWhereUniqueInput>>;
  update?: InputMaybe<Array<RoutingUpdateWithWhereUniqueWithoutRuleInput>>;
  updateMany?: InputMaybe<Array<RoutingUpdateManyWithWhereWithoutRuleInput>>;
  upsert?: InputMaybe<Array<RoutingUpsertWithWhereUniqueWithoutRuleInput>>;
};

export type RoutingUpdateManyWithWhereWithoutOrganizationInput = {
  data: RoutingUpdateManyMutationInput;
  where: RoutingScalarWhereInput;
};

export type RoutingUpdateManyWithWhereWithoutRepInput = {
  data: RoutingUpdateManyMutationInput;
  where: RoutingScalarWhereInput;
};

export type RoutingUpdateManyWithWhereWithoutRuleInput = {
  data: RoutingUpdateManyMutationInput;
  where: RoutingScalarWhereInput;
};

export type RoutingUpdateOneWithoutRouting_HistoryInput = {
  connect?: InputMaybe<RoutingWhereUniqueInput>;
  connectOrCreate?: InputMaybe<RoutingCreateOrConnectWithoutRouting_HistoryInput>;
  create?: InputMaybe<RoutingCreateWithoutRouting_HistoryInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<RoutingUpdateWithoutRouting_HistoryInput>;
  upsert?: InputMaybe<RoutingUpsertWithoutRouting_HistoryInput>;
};

export type RoutingUpdateWithoutOrganizationInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  rank?: InputMaybe<IntFieldUpdateOperationsInput>;
  rep?: InputMaybe<UserUpdateOneRequiredWithoutRoutingsInput>;
  route_count?: InputMaybe<IntFieldUpdateOperationsInput>;
  routing_history?: InputMaybe<RoutingHistoryUpdateManyWithoutRouteInput>;
  rule?: InputMaybe<RuleUpdateOneRequiredWithoutRoutingsInput>;
};

export type RoutingUpdateWithoutRepInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  Organization?: InputMaybe<OrganizationUpdateOneWithoutRoutingsInput>;
  rank?: InputMaybe<IntFieldUpdateOperationsInput>;
  route_count?: InputMaybe<IntFieldUpdateOperationsInput>;
  routing_history?: InputMaybe<RoutingHistoryUpdateManyWithoutRouteInput>;
  rule?: InputMaybe<RuleUpdateOneRequiredWithoutRoutingsInput>;
};

export type RoutingUpdateWithoutRouting_HistoryInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  Organization?: InputMaybe<OrganizationUpdateOneWithoutRoutingsInput>;
  rank?: InputMaybe<IntFieldUpdateOperationsInput>;
  rep?: InputMaybe<UserUpdateOneRequiredWithoutRoutingsInput>;
  route_count?: InputMaybe<IntFieldUpdateOperationsInput>;
  rule?: InputMaybe<RuleUpdateOneRequiredWithoutRoutingsInput>;
};

export type RoutingUpdateWithoutRuleInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  Organization?: InputMaybe<OrganizationUpdateOneWithoutRoutingsInput>;
  rank?: InputMaybe<IntFieldUpdateOperationsInput>;
  rep?: InputMaybe<UserUpdateOneRequiredWithoutRoutingsInput>;
  route_count?: InputMaybe<IntFieldUpdateOperationsInput>;
  routing_history?: InputMaybe<RoutingHistoryUpdateManyWithoutRouteInput>;
};

export type RoutingUpdateWithWhereUniqueWithoutOrganizationInput = {
  data: RoutingUpdateWithoutOrganizationInput;
  where: RoutingWhereUniqueInput;
};

export type RoutingUpdateWithWhereUniqueWithoutRepInput = {
  data: RoutingUpdateWithoutRepInput;
  where: RoutingWhereUniqueInput;
};

export type RoutingUpdateWithWhereUniqueWithoutRuleInput = {
  data: RoutingUpdateWithoutRuleInput;
  where: RoutingWhereUniqueInput;
};

export type RoutingUpsertWithoutRouting_HistoryInput = {
  create: RoutingCreateWithoutRouting_HistoryInput;
  update: RoutingUpdateWithoutRouting_HistoryInput;
};

export type RoutingUpsertWithWhereUniqueWithoutOrganizationInput = {
  create: RoutingCreateWithoutOrganizationInput;
  update: RoutingUpdateWithoutOrganizationInput;
  where: RoutingWhereUniqueInput;
};

export type RoutingUpsertWithWhereUniqueWithoutRepInput = {
  create: RoutingCreateWithoutRepInput;
  update: RoutingUpdateWithoutRepInput;
  where: RoutingWhereUniqueInput;
};

export type RoutingUpsertWithWhereUniqueWithoutRuleInput = {
  create: RoutingCreateWithoutRuleInput;
  update: RoutingUpdateWithoutRuleInput;
  where: RoutingWhereUniqueInput;
};

export type RoutingWhereInput = {
  AND?: InputMaybe<Array<RoutingWhereInput>>;
  created_at?: InputMaybe<DateTimeFilter>;
  NOT?: InputMaybe<Array<RoutingWhereInput>>;
  OR?: InputMaybe<Array<RoutingWhereInput>>;
  Organization?: InputMaybe<OrganizationWhereInput>;
  organization_id?: InputMaybe<StringNullableFilter>;
  rank?: InputMaybe<IntFilter>;
  rep?: InputMaybe<UserWhereInput>;
  rep_id?: InputMaybe<StringFilter>;
  route_count?: InputMaybe<IntFilter>;
  routing_history?: InputMaybe<RoutingHistoryListRelationFilter>;
  rule?: InputMaybe<RuleWhereInput>;
  rule_id?: InputMaybe<StringFilter>;
};

export type RoutingWhereUniqueInput = {
  rep_id_rule_id?: InputMaybe<RoutingRep_IdRule_IdCompoundUniqueInput>;
};

export type Rule = {
  __typename?: 'Rule';
  active: Scalars['Boolean']['output'];
  applyToInboundConcierge: Scalars['Boolean']['output'];
  applyToInboundConciergeCallMeNow: Scalars['Boolean']['output'];
  applyToInboundConciergeSchedule: Scalars['Boolean']['output'];
  conditions: Array<Condition>;
  created_at: Scalars['DateTime']['output'];
  date_range?: Maybe<DateOption>;
  deferral_date_time?: Maybe<Scalars['DateTime']['output']>;
  distribution_method: Distribution_Method;
  entity: EntityType;
  entity_type?: Maybe<EntityType>;
  id: Scalars['String']['output'];
  lead: Array<Lead>;
  lead_creation_status: LeadCreationStatus;
  los_rules: Array<Los>;
  metric?: Maybe<Metric>;
  name: Scalars['String']['output'];
  organization: Organization;
  organization_id: Scalars['String']['output'];
  priority: Scalars['Int']['output'];
  rank_sort_type: RankSortType;
  rep_priority_rule: RepPriorityRule;
  rep_selection_method: Rep_Selection_Method;
  routings: Array<Routing>;
  rule_type: RuleType;
  updated_at: Scalars['DateTime']['output'];
};


export type RuleConditionsArgs = {
  after?: InputMaybe<ConditionWhereUniqueInput>;
  before?: InputMaybe<ConditionWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type RuleLeadArgs = {
  after?: InputMaybe<LeadWhereUniqueInput>;
  before?: InputMaybe<LeadWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type RuleRoutingsArgs = {
  after?: InputMaybe<RoutingWhereUniqueInput>;
  before?: InputMaybe<RoutingWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type RuleCreatelos_RulesInput = {
  set?: InputMaybe<Array<Los>>;
};

export type RuleCreateManylos_RulesInput = {
  set?: InputMaybe<Array<Los>>;
};

export type RuleCreateManyOrganizationInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  applyToInboundConcierge?: InputMaybe<Scalars['Boolean']['input']>;
  applyToInboundConciergeCallMeNow?: InputMaybe<Scalars['Boolean']['input']>;
  applyToInboundConciergeSchedule?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  date_range?: InputMaybe<DateOption>;
  deferral_date_time?: InputMaybe<Scalars['DateTime']['input']>;
  distribution_method: Distribution_Method;
  entity?: InputMaybe<EntityType>;
  id?: InputMaybe<Scalars['String']['input']>;
  lead_creation_status?: InputMaybe<LeadCreationStatus>;
  los_rules?: InputMaybe<RuleCreateManylos_RulesInput>;
  metric?: InputMaybe<Metric>;
  name?: InputMaybe<Scalars['String']['input']>;
  priority: Scalars['Int']['input'];
  rank_sort_type?: InputMaybe<RankSortType>;
  rep_priority_rule?: InputMaybe<RepPriorityRule>;
  rep_selection_method: Rep_Selection_Method;
  rule_type?: InputMaybe<RuleType>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type RuleCreateManyOrganizationInputEnvelope = {
  data?: InputMaybe<Array<RuleCreateManyOrganizationInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type RuleCreateNestedManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<RuleWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<RuleCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<RuleCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<RuleCreateManyOrganizationInputEnvelope>;
};

export type RuleCreateNestedOneWithoutAuto_Transfer_SelectionInput = {
  connect?: InputMaybe<RuleWhereUniqueInput>;
  connectOrCreate?: InputMaybe<RuleCreateOrConnectWithoutAuto_Transfer_SelectionInput>;
  create?: InputMaybe<RuleCreateWithoutAuto_Transfer_SelectionInput>;
};

export type RuleCreateNestedOneWithoutLeadInput = {
  connect?: InputMaybe<RuleWhereUniqueInput>;
  connectOrCreate?: InputMaybe<RuleCreateOrConnectWithoutLeadInput>;
  create?: InputMaybe<RuleCreateWithoutLeadInput>;
};

export type RuleCreateNestedOneWithoutRoutingsInput = {
  connect?: InputMaybe<RuleWhereUniqueInput>;
  connectOrCreate?: InputMaybe<RuleCreateOrConnectWithoutRoutingsInput>;
  create?: InputMaybe<RuleCreateWithoutRoutingsInput>;
};

export type RuleCreateOrConnectWithoutAuto_Transfer_SelectionInput = {
  create: RuleCreateWithoutAuto_Transfer_SelectionInput;
  where: RuleWhereUniqueInput;
};

export type RuleCreateOrConnectWithoutLeadInput = {
  create: RuleCreateWithoutLeadInput;
  where: RuleWhereUniqueInput;
};

export type RuleCreateOrConnectWithoutOrganizationInput = {
  create: RuleCreateWithoutOrganizationInput;
  where: RuleWhereUniqueInput;
};

export type RuleCreateOrConnectWithoutRoutingsInput = {
  create: RuleCreateWithoutRoutingsInput;
  where: RuleWhereUniqueInput;
};

export type RuleCreateWithoutAuto_Transfer_SelectionInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  applyToInboundConcierge?: InputMaybe<Scalars['Boolean']['input']>;
  applyToInboundConciergeCallMeNow?: InputMaybe<Scalars['Boolean']['input']>;
  applyToInboundConciergeSchedule?: InputMaybe<Scalars['Boolean']['input']>;
  conditions?: InputMaybe<ConditionCreateNestedManyWithoutRuleInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  date_range?: InputMaybe<DateOption>;
  deferral_date_time?: InputMaybe<Scalars['DateTime']['input']>;
  distribution_method: Distribution_Method;
  entity?: InputMaybe<EntityType>;
  id?: InputMaybe<Scalars['String']['input']>;
  lead?: InputMaybe<LeadCreateNestedManyWithoutRouting_RuleInput>;
  lead_creation_status?: InputMaybe<LeadCreationStatus>;
  los_rules?: InputMaybe<RuleCreatelos_RulesInput>;
  metric?: InputMaybe<Metric>;
  name?: InputMaybe<Scalars['String']['input']>;
  organization: OrganizationCreateNestedOneWithoutLead_Routing_RulesInput;
  priority: Scalars['Int']['input'];
  rank_sort_type?: InputMaybe<RankSortType>;
  rep_priority_rule?: InputMaybe<RepPriorityRule>;
  rep_selection_method: Rep_Selection_Method;
  routings?: InputMaybe<RoutingCreateNestedManyWithoutRuleInput>;
  rule_type?: InputMaybe<RuleType>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type RuleCreateWithoutLeadInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  applyToInboundConcierge?: InputMaybe<Scalars['Boolean']['input']>;
  applyToInboundConciergeCallMeNow?: InputMaybe<Scalars['Boolean']['input']>;
  applyToInboundConciergeSchedule?: InputMaybe<Scalars['Boolean']['input']>;
  auto_transfer_selection?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutMatched_RuleInput>;
  conditions?: InputMaybe<ConditionCreateNestedManyWithoutRuleInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  date_range?: InputMaybe<DateOption>;
  deferral_date_time?: InputMaybe<Scalars['DateTime']['input']>;
  distribution_method: Distribution_Method;
  entity?: InputMaybe<EntityType>;
  id?: InputMaybe<Scalars['String']['input']>;
  lead_creation_status?: InputMaybe<LeadCreationStatus>;
  los_rules?: InputMaybe<RuleCreatelos_RulesInput>;
  metric?: InputMaybe<Metric>;
  name?: InputMaybe<Scalars['String']['input']>;
  organization: OrganizationCreateNestedOneWithoutLead_Routing_RulesInput;
  priority: Scalars['Int']['input'];
  rank_sort_type?: InputMaybe<RankSortType>;
  rep_priority_rule?: InputMaybe<RepPriorityRule>;
  rep_selection_method: Rep_Selection_Method;
  routings?: InputMaybe<RoutingCreateNestedManyWithoutRuleInput>;
  rule_type?: InputMaybe<RuleType>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type RuleCreateWithoutOrganizationInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  applyToInboundConcierge?: InputMaybe<Scalars['Boolean']['input']>;
  applyToInboundConciergeCallMeNow?: InputMaybe<Scalars['Boolean']['input']>;
  applyToInboundConciergeSchedule?: InputMaybe<Scalars['Boolean']['input']>;
  auto_transfer_selection?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutMatched_RuleInput>;
  conditions?: InputMaybe<ConditionCreateNestedManyWithoutRuleInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  date_range?: InputMaybe<DateOption>;
  deferral_date_time?: InputMaybe<Scalars['DateTime']['input']>;
  distribution_method: Distribution_Method;
  entity?: InputMaybe<EntityType>;
  id?: InputMaybe<Scalars['String']['input']>;
  lead?: InputMaybe<LeadCreateNestedManyWithoutRouting_RuleInput>;
  lead_creation_status?: InputMaybe<LeadCreationStatus>;
  los_rules?: InputMaybe<RuleCreatelos_RulesInput>;
  metric?: InputMaybe<Metric>;
  name?: InputMaybe<Scalars['String']['input']>;
  priority: Scalars['Int']['input'];
  rank_sort_type?: InputMaybe<RankSortType>;
  rep_priority_rule?: InputMaybe<RepPriorityRule>;
  rep_selection_method: Rep_Selection_Method;
  routings?: InputMaybe<RoutingCreateNestedManyWithoutRuleInput>;
  rule_type?: InputMaybe<RuleType>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type RuleCreateWithoutRoutingsInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  applyToInboundConcierge?: InputMaybe<Scalars['Boolean']['input']>;
  applyToInboundConciergeCallMeNow?: InputMaybe<Scalars['Boolean']['input']>;
  applyToInboundConciergeSchedule?: InputMaybe<Scalars['Boolean']['input']>;
  auto_transfer_selection?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutMatched_RuleInput>;
  conditions?: InputMaybe<ConditionCreateNestedManyWithoutRuleInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  date_range?: InputMaybe<DateOption>;
  deferral_date_time?: InputMaybe<Scalars['DateTime']['input']>;
  distribution_method: Distribution_Method;
  entity?: InputMaybe<EntityType>;
  id?: InputMaybe<Scalars['String']['input']>;
  lead?: InputMaybe<LeadCreateNestedManyWithoutRouting_RuleInput>;
  lead_creation_status?: InputMaybe<LeadCreationStatus>;
  los_rules?: InputMaybe<RuleCreatelos_RulesInput>;
  metric?: InputMaybe<Metric>;
  name?: InputMaybe<Scalars['String']['input']>;
  organization: OrganizationCreateNestedOneWithoutLead_Routing_RulesInput;
  priority: Scalars['Int']['input'];
  rank_sort_type?: InputMaybe<RankSortType>;
  rep_priority_rule?: InputMaybe<RepPriorityRule>;
  rep_selection_method: Rep_Selection_Method;
  rule_type?: InputMaybe<RuleType>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type RuleListRelationFilter = {
  every?: InputMaybe<RuleWhereInput>;
  none?: InputMaybe<RuleWhereInput>;
  some?: InputMaybe<RuleWhereInput>;
};

export type RuleLog = {
  __typename?: 'RuleLog';
  dml_created_by?: Maybe<Scalars['String']['output']>;
  dml_timestamp: Scalars['DateTime']['output'];
  dml_type: Dml_Type;
  new_row_data?: Maybe<Scalars['Json']['output']>;
  old_row_data?: Maybe<Scalars['Json']['output']>;
  rule_id: Scalars['String']['output'];
};

export type RuleOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
  count?: InputMaybe<SortOrder>;
};

export type RuleOrderByWithRelationInput = {
  active?: InputMaybe<SortOrder>;
  applyToInboundConcierge?: InputMaybe<SortOrder>;
  applyToInboundConciergeCallMeNow?: InputMaybe<SortOrder>;
  applyToInboundConciergeSchedule?: InputMaybe<SortOrder>;
  auto_transfer_selection?: InputMaybe<AutoTransferSelectionOrderByRelationAggregateInput>;
  conditions?: InputMaybe<ConditionOrderByRelationAggregateInput>;
  created_at?: InputMaybe<SortOrder>;
  date_range?: InputMaybe<SortOrder>;
  deferral_date_time?: InputMaybe<SortOrder>;
  distribution_method?: InputMaybe<SortOrder>;
  entity?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  lead?: InputMaybe<LeadOrderByRelationAggregateInput>;
  lead_creation_status?: InputMaybe<SortOrder>;
  los_rules?: InputMaybe<SortOrder>;
  metric?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  organization?: InputMaybe<OrganizationOrderByWithRelationInput>;
  organization_id?: InputMaybe<SortOrder>;
  priority?: InputMaybe<SortOrder>;
  rank_sort_type?: InputMaybe<SortOrder>;
  rep_priority_rule?: InputMaybe<SortOrder>;
  rep_selection_method?: InputMaybe<SortOrder>;
  routings?: InputMaybe<RoutingOrderByRelationAggregateInput>;
  rule_type?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
};

export type RuleScalarWhereInput = {
  active?: InputMaybe<BoolFilter>;
  AND?: InputMaybe<Array<RuleScalarWhereInput>>;
  applyToInboundConcierge?: InputMaybe<BoolFilter>;
  applyToInboundConciergeCallMeNow?: InputMaybe<BoolFilter>;
  applyToInboundConciergeSchedule?: InputMaybe<BoolFilter>;
  created_at?: InputMaybe<DateTimeFilter>;
  date_range?: InputMaybe<EnumDateOptionNullableFilter>;
  deferral_date_time?: InputMaybe<DateTimeNullableFilter>;
  distribution_method?: InputMaybe<EnumDistribution_MethodFilter>;
  entity?: InputMaybe<EnumEntityTypeFilter>;
  id?: InputMaybe<StringFilter>;
  lead_creation_status?: InputMaybe<EnumLeadCreationStatusFilter>;
  los_rules?: InputMaybe<EnumLosNullableListFilter>;
  metric?: InputMaybe<EnumMetricNullableFilter>;
  name?: InputMaybe<StringFilter>;
  NOT?: InputMaybe<Array<RuleScalarWhereInput>>;
  OR?: InputMaybe<Array<RuleScalarWhereInput>>;
  organization_id?: InputMaybe<StringFilter>;
  priority?: InputMaybe<IntFilter>;
  rank_sort_type?: InputMaybe<EnumRankSortTypeFilter>;
  rep_priority_rule?: InputMaybe<EnumRepPriorityRuleFilter>;
  rep_selection_method?: InputMaybe<EnumRep_Selection_MethodFilter>;
  rule_type?: InputMaybe<EnumRuleTypeFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
};

export enum RuleType {
  Booking = 'Booking',
  Routing = 'Routing',
  Transfer = 'Transfer',
  Widget = 'Widget'
}

export type RuleUpdatelos_RulesInput = {
  push?: InputMaybe<Los>;
  set?: InputMaybe<Array<Los>>;
};

export type RuleUpdateManyMutationInput = {
  active?: InputMaybe<BoolFieldUpdateOperationsInput>;
  applyToInboundConcierge?: InputMaybe<BoolFieldUpdateOperationsInput>;
  applyToInboundConciergeCallMeNow?: InputMaybe<BoolFieldUpdateOperationsInput>;
  applyToInboundConciergeSchedule?: InputMaybe<BoolFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  date_range?: InputMaybe<NullableEnumDateOptionFieldUpdateOperationsInput>;
  deferral_date_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  distribution_method?: InputMaybe<EnumDistribution_MethodFieldUpdateOperationsInput>;
  entity?: InputMaybe<EnumEntityTypeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lead_creation_status?: InputMaybe<EnumLeadCreationStatusFieldUpdateOperationsInput>;
  los_rules?: InputMaybe<RuleUpdatelos_RulesInput>;
  metric?: InputMaybe<NullableEnumMetricFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  priority?: InputMaybe<IntFieldUpdateOperationsInput>;
  rank_sort_type?: InputMaybe<EnumRankSortTypeFieldUpdateOperationsInput>;
  rep_priority_rule?: InputMaybe<EnumRepPriorityRuleFieldUpdateOperationsInput>;
  rep_selection_method?: InputMaybe<EnumRep_Selection_MethodFieldUpdateOperationsInput>;
  rule_type?: InputMaybe<EnumRuleTypeFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type RuleUpdateManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<RuleWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<RuleCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<RuleCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<RuleCreateManyOrganizationInputEnvelope>;
  delete?: InputMaybe<Array<RuleWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<RuleScalarWhereInput>>;
  disconnect?: InputMaybe<Array<RuleWhereUniqueInput>>;
  set?: InputMaybe<Array<RuleWhereUniqueInput>>;
  update?: InputMaybe<Array<RuleUpdateWithWhereUniqueWithoutOrganizationInput>>;
  updateMany?: InputMaybe<Array<RuleUpdateManyWithWhereWithoutOrganizationInput>>;
  upsert?: InputMaybe<Array<RuleUpsertWithWhereUniqueWithoutOrganizationInput>>;
};

export type RuleUpdateManyWithWhereWithoutOrganizationInput = {
  data: RuleUpdateManyMutationInput;
  where: RuleScalarWhereInput;
};

export type RuleUpdateOneRequiredWithoutRoutingsInput = {
  connect?: InputMaybe<RuleWhereUniqueInput>;
  connectOrCreate?: InputMaybe<RuleCreateOrConnectWithoutRoutingsInput>;
  create?: InputMaybe<RuleCreateWithoutRoutingsInput>;
  update?: InputMaybe<RuleUpdateWithoutRoutingsInput>;
  upsert?: InputMaybe<RuleUpsertWithoutRoutingsInput>;
};

export type RuleUpdateOneWithoutAuto_Transfer_SelectionInput = {
  connect?: InputMaybe<RuleWhereUniqueInput>;
  connectOrCreate?: InputMaybe<RuleCreateOrConnectWithoutAuto_Transfer_SelectionInput>;
  create?: InputMaybe<RuleCreateWithoutAuto_Transfer_SelectionInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<RuleUpdateWithoutAuto_Transfer_SelectionInput>;
  upsert?: InputMaybe<RuleUpsertWithoutAuto_Transfer_SelectionInput>;
};

export type RuleUpdateOneWithoutLeadInput = {
  connect?: InputMaybe<RuleWhereUniqueInput>;
  connectOrCreate?: InputMaybe<RuleCreateOrConnectWithoutLeadInput>;
  create?: InputMaybe<RuleCreateWithoutLeadInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<RuleUpdateWithoutLeadInput>;
  upsert?: InputMaybe<RuleUpsertWithoutLeadInput>;
};

export type RuleUpdateWithoutAuto_Transfer_SelectionInput = {
  active?: InputMaybe<BoolFieldUpdateOperationsInput>;
  applyToInboundConcierge?: InputMaybe<BoolFieldUpdateOperationsInput>;
  applyToInboundConciergeCallMeNow?: InputMaybe<BoolFieldUpdateOperationsInput>;
  applyToInboundConciergeSchedule?: InputMaybe<BoolFieldUpdateOperationsInput>;
  conditions?: InputMaybe<ConditionUpdateManyWithoutRuleInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  date_range?: InputMaybe<NullableEnumDateOptionFieldUpdateOperationsInput>;
  deferral_date_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  distribution_method?: InputMaybe<EnumDistribution_MethodFieldUpdateOperationsInput>;
  entity?: InputMaybe<EnumEntityTypeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lead?: InputMaybe<LeadUpdateManyWithoutRouting_RuleInput>;
  lead_creation_status?: InputMaybe<EnumLeadCreationStatusFieldUpdateOperationsInput>;
  los_rules?: InputMaybe<RuleUpdatelos_RulesInput>;
  metric?: InputMaybe<NullableEnumMetricFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutLead_Routing_RulesInput>;
  priority?: InputMaybe<IntFieldUpdateOperationsInput>;
  rank_sort_type?: InputMaybe<EnumRankSortTypeFieldUpdateOperationsInput>;
  rep_priority_rule?: InputMaybe<EnumRepPriorityRuleFieldUpdateOperationsInput>;
  rep_selection_method?: InputMaybe<EnumRep_Selection_MethodFieldUpdateOperationsInput>;
  routings?: InputMaybe<RoutingUpdateManyWithoutRuleInput>;
  rule_type?: InputMaybe<EnumRuleTypeFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type RuleUpdateWithoutLeadInput = {
  active?: InputMaybe<BoolFieldUpdateOperationsInput>;
  applyToInboundConcierge?: InputMaybe<BoolFieldUpdateOperationsInput>;
  applyToInboundConciergeCallMeNow?: InputMaybe<BoolFieldUpdateOperationsInput>;
  applyToInboundConciergeSchedule?: InputMaybe<BoolFieldUpdateOperationsInput>;
  auto_transfer_selection?: InputMaybe<AutoTransferSelectionUpdateManyWithoutMatched_RuleInput>;
  conditions?: InputMaybe<ConditionUpdateManyWithoutRuleInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  date_range?: InputMaybe<NullableEnumDateOptionFieldUpdateOperationsInput>;
  deferral_date_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  distribution_method?: InputMaybe<EnumDistribution_MethodFieldUpdateOperationsInput>;
  entity?: InputMaybe<EnumEntityTypeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lead_creation_status?: InputMaybe<EnumLeadCreationStatusFieldUpdateOperationsInput>;
  los_rules?: InputMaybe<RuleUpdatelos_RulesInput>;
  metric?: InputMaybe<NullableEnumMetricFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutLead_Routing_RulesInput>;
  priority?: InputMaybe<IntFieldUpdateOperationsInput>;
  rank_sort_type?: InputMaybe<EnumRankSortTypeFieldUpdateOperationsInput>;
  rep_priority_rule?: InputMaybe<EnumRepPriorityRuleFieldUpdateOperationsInput>;
  rep_selection_method?: InputMaybe<EnumRep_Selection_MethodFieldUpdateOperationsInput>;
  routings?: InputMaybe<RoutingUpdateManyWithoutRuleInput>;
  rule_type?: InputMaybe<EnumRuleTypeFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type RuleUpdateWithoutOrganizationInput = {
  active?: InputMaybe<BoolFieldUpdateOperationsInput>;
  applyToInboundConcierge?: InputMaybe<BoolFieldUpdateOperationsInput>;
  applyToInboundConciergeCallMeNow?: InputMaybe<BoolFieldUpdateOperationsInput>;
  applyToInboundConciergeSchedule?: InputMaybe<BoolFieldUpdateOperationsInput>;
  auto_transfer_selection?: InputMaybe<AutoTransferSelectionUpdateManyWithoutMatched_RuleInput>;
  conditions?: InputMaybe<ConditionUpdateManyWithoutRuleInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  date_range?: InputMaybe<NullableEnumDateOptionFieldUpdateOperationsInput>;
  deferral_date_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  distribution_method?: InputMaybe<EnumDistribution_MethodFieldUpdateOperationsInput>;
  entity?: InputMaybe<EnumEntityTypeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lead?: InputMaybe<LeadUpdateManyWithoutRouting_RuleInput>;
  lead_creation_status?: InputMaybe<EnumLeadCreationStatusFieldUpdateOperationsInput>;
  los_rules?: InputMaybe<RuleUpdatelos_RulesInput>;
  metric?: InputMaybe<NullableEnumMetricFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  priority?: InputMaybe<IntFieldUpdateOperationsInput>;
  rank_sort_type?: InputMaybe<EnumRankSortTypeFieldUpdateOperationsInput>;
  rep_priority_rule?: InputMaybe<EnumRepPriorityRuleFieldUpdateOperationsInput>;
  rep_selection_method?: InputMaybe<EnumRep_Selection_MethodFieldUpdateOperationsInput>;
  routings?: InputMaybe<RoutingUpdateManyWithoutRuleInput>;
  rule_type?: InputMaybe<EnumRuleTypeFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type RuleUpdateWithoutRoutingsInput = {
  active?: InputMaybe<BoolFieldUpdateOperationsInput>;
  applyToInboundConcierge?: InputMaybe<BoolFieldUpdateOperationsInput>;
  applyToInboundConciergeCallMeNow?: InputMaybe<BoolFieldUpdateOperationsInput>;
  applyToInboundConciergeSchedule?: InputMaybe<BoolFieldUpdateOperationsInput>;
  auto_transfer_selection?: InputMaybe<AutoTransferSelectionUpdateManyWithoutMatched_RuleInput>;
  conditions?: InputMaybe<ConditionUpdateManyWithoutRuleInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  date_range?: InputMaybe<NullableEnumDateOptionFieldUpdateOperationsInput>;
  deferral_date_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  distribution_method?: InputMaybe<EnumDistribution_MethodFieldUpdateOperationsInput>;
  entity?: InputMaybe<EnumEntityTypeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lead?: InputMaybe<LeadUpdateManyWithoutRouting_RuleInput>;
  lead_creation_status?: InputMaybe<EnumLeadCreationStatusFieldUpdateOperationsInput>;
  los_rules?: InputMaybe<RuleUpdatelos_RulesInput>;
  metric?: InputMaybe<NullableEnumMetricFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutLead_Routing_RulesInput>;
  priority?: InputMaybe<IntFieldUpdateOperationsInput>;
  rank_sort_type?: InputMaybe<EnumRankSortTypeFieldUpdateOperationsInput>;
  rep_priority_rule?: InputMaybe<EnumRepPriorityRuleFieldUpdateOperationsInput>;
  rep_selection_method?: InputMaybe<EnumRep_Selection_MethodFieldUpdateOperationsInput>;
  rule_type?: InputMaybe<EnumRuleTypeFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type RuleUpdateWithWhereUniqueWithoutOrganizationInput = {
  data: RuleUpdateWithoutOrganizationInput;
  where: RuleWhereUniqueInput;
};

export type RuleUpsertWithoutAuto_Transfer_SelectionInput = {
  create: RuleCreateWithoutAuto_Transfer_SelectionInput;
  update: RuleUpdateWithoutAuto_Transfer_SelectionInput;
};

export type RuleUpsertWithoutLeadInput = {
  create: RuleCreateWithoutLeadInput;
  update: RuleUpdateWithoutLeadInput;
};

export type RuleUpsertWithoutRoutingsInput = {
  create: RuleCreateWithoutRoutingsInput;
  update: RuleUpdateWithoutRoutingsInput;
};

export type RuleUpsertWithWhereUniqueWithoutOrganizationInput = {
  create: RuleCreateWithoutOrganizationInput;
  update: RuleUpdateWithoutOrganizationInput;
  where: RuleWhereUniqueInput;
};

export type RuleWhereInput = {
  active?: InputMaybe<BoolFilter>;
  AND?: InputMaybe<Array<RuleWhereInput>>;
  applyToInboundConcierge?: InputMaybe<BoolFilter>;
  applyToInboundConciergeCallMeNow?: InputMaybe<BoolFilter>;
  applyToInboundConciergeSchedule?: InputMaybe<BoolFilter>;
  auto_transfer_selection?: InputMaybe<AutoTransferSelectionListRelationFilter>;
  conditions?: InputMaybe<ConditionListRelationFilter>;
  created_at?: InputMaybe<DateTimeFilter>;
  date_range?: InputMaybe<EnumDateOptionNullableFilter>;
  deferral_date_time?: InputMaybe<DateTimeNullableFilter>;
  distribution_method?: InputMaybe<EnumDistribution_MethodFilter>;
  entity?: InputMaybe<EnumEntityTypeFilter>;
  id?: InputMaybe<StringFilter>;
  lead?: InputMaybe<LeadListRelationFilter>;
  lead_creation_status?: InputMaybe<EnumLeadCreationStatusFilter>;
  los_rules?: InputMaybe<EnumLosNullableListFilter>;
  metric?: InputMaybe<EnumMetricNullableFilter>;
  name?: InputMaybe<StringFilter>;
  NOT?: InputMaybe<Array<RuleWhereInput>>;
  OR?: InputMaybe<Array<RuleWhereInput>>;
  organization?: InputMaybe<OrganizationWhereInput>;
  organization_id?: InputMaybe<StringFilter>;
  priority?: InputMaybe<IntFilter>;
  rank_sort_type?: InputMaybe<EnumRankSortTypeFilter>;
  rep_priority_rule?: InputMaybe<EnumRepPriorityRuleFilter>;
  rep_selection_method?: InputMaybe<EnumRep_Selection_MethodFilter>;
  routings?: InputMaybe<RoutingListRelationFilter>;
  rule_type?: InputMaybe<EnumRuleTypeFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
};

export type RuleWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']['input']>;
};

export type Sale = {
  __typename?: 'Sale';
  commissions: Array<Commission>;
  contract_duration?: Maybe<Scalars['String']['output']>;
  converted_sequence?: Maybe<Sequence>;
  converted_sequence_id?: Maybe<Scalars['String']['output']>;
  created_at: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  lead?: Maybe<Lead>;
  lead_activity?: Maybe<LeadActivity>;
  lead_activity_id?: Maybe<Scalars['String']['output']>;
  lead_id?: Maybe<Scalars['String']['output']>;
  notes?: Maybe<Scalars['String']['output']>;
  organization?: Maybe<Organization>;
  organization_id?: Maybe<Scalars['String']['output']>;
  pandadoc_sale_id?: Maybe<Scalars['String']['output']>;
  payment_terms?: Maybe<Scalars['String']['output']>;
  prepayment?: Maybe<Scalars['Int']['output']>;
  products?: Maybe<Scalars['String']['output']>;
  sale_items: Array<SaleItem>;
  total_discounts?: Maybe<Scalars['Float']['output']>;
  total_price?: Maybe<Scalars['Float']['output']>;
  total_price_before_discounts?: Maybe<Scalars['Float']['output']>;
  updated_at: Scalars['DateTime']['output'];
};


export type SaleCommissionsArgs = {
  after?: InputMaybe<CommissionWhereUniqueInput>;
  before?: InputMaybe<CommissionWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type SaleSale_ItemsArgs = {
  after?: InputMaybe<SaleItemWhereUniqueInput>;
  before?: InputMaybe<SaleItemWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type SaleConfig = {
  __typename?: 'SaleConfig';
  created_at: Scalars['DateTime']['output'];
  created_by_user_id?: Maybe<Scalars['String']['output']>;
  deleted_at?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['String']['output'];
  last_updated_by?: Maybe<User>;
  SaleConfigPages?: Maybe<Array<Maybe<SaleConfigPage>>>;
  saleConfigPagesVisibleOnly?: Maybe<Array<Maybe<SaleConfigPage>>>;
  updated_at: Scalars['DateTime']['output'];
  updated_by_user_id?: Maybe<Scalars['String']['output']>;
};

export type SaleConfigCreateManyCreated_ByInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  deleted_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  updated_by_user_id?: InputMaybe<Scalars['String']['input']>;
};

export type SaleConfigCreateManyCreated_ByInputEnvelope = {
  data?: InputMaybe<Array<SaleConfigCreateManyCreated_ByInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SaleConfigCreateManyLast_Updated_ByInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  created_by_user_id?: InputMaybe<Scalars['String']['input']>;
  deleted_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type SaleConfigCreateManyLast_Updated_ByInputEnvelope = {
  data?: InputMaybe<Array<SaleConfigCreateManyLast_Updated_ByInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SaleConfigCreateNestedManyWithoutCreated_ByInput = {
  connect?: InputMaybe<Array<SaleConfigWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SaleConfigCreateOrConnectWithoutCreated_ByInput>>;
  create?: InputMaybe<Array<SaleConfigCreateWithoutCreated_ByInput>>;
  createMany?: InputMaybe<SaleConfigCreateManyCreated_ByInputEnvelope>;
};

export type SaleConfigCreateNestedManyWithoutLast_Updated_ByInput = {
  connect?: InputMaybe<Array<SaleConfigWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SaleConfigCreateOrConnectWithoutLast_Updated_ByInput>>;
  create?: InputMaybe<Array<SaleConfigCreateWithoutLast_Updated_ByInput>>;
  createMany?: InputMaybe<SaleConfigCreateManyLast_Updated_ByInputEnvelope>;
};

export type SaleConfigCreateNestedOneWithoutOrganizationInput = {
  connect?: InputMaybe<SaleConfigWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SaleConfigCreateOrConnectWithoutOrganizationInput>;
  create?: InputMaybe<SaleConfigCreateWithoutOrganizationInput>;
};

export type SaleConfigCreateNestedOneWithoutSaleConfigPagesInput = {
  connect?: InputMaybe<SaleConfigWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SaleConfigCreateOrConnectWithoutSaleConfigPagesInput>;
  create?: InputMaybe<SaleConfigCreateWithoutSaleConfigPagesInput>;
};

export type SaleConfigCreateOrConnectWithoutCreated_ByInput = {
  create: SaleConfigCreateWithoutCreated_ByInput;
  where: SaleConfigWhereUniqueInput;
};

export type SaleConfigCreateOrConnectWithoutLast_Updated_ByInput = {
  create: SaleConfigCreateWithoutLast_Updated_ByInput;
  where: SaleConfigWhereUniqueInput;
};

export type SaleConfigCreateOrConnectWithoutOrganizationInput = {
  create: SaleConfigCreateWithoutOrganizationInput;
  where: SaleConfigWhereUniqueInput;
};

export type SaleConfigCreateOrConnectWithoutSaleConfigPagesInput = {
  create: SaleConfigCreateWithoutSaleConfigPagesInput;
  where: SaleConfigWhereUniqueInput;
};

export type SaleConfigCreateWithoutCreated_ByInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  deleted_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  last_updated_by?: InputMaybe<UserCreateNestedOneWithoutSale_Config_UpdatedInput>;
  Organization?: InputMaybe<OrganizationCreateNestedManyWithoutSaleConfigInput>;
  SaleConfigPages?: InputMaybe<SaleConfigPageCreateNestedManyWithoutSale_ConfigInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type SaleConfigCreateWithoutLast_Updated_ByInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  created_by?: InputMaybe<UserCreateNestedOneWithoutSale_ConfigInput>;
  deleted_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  Organization?: InputMaybe<OrganizationCreateNestedManyWithoutSaleConfigInput>;
  SaleConfigPages?: InputMaybe<SaleConfigPageCreateNestedManyWithoutSale_ConfigInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type SaleConfigCreateWithoutOrganizationInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  created_by?: InputMaybe<UserCreateNestedOneWithoutSale_ConfigInput>;
  deleted_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  last_updated_by?: InputMaybe<UserCreateNestedOneWithoutSale_Config_UpdatedInput>;
  SaleConfigPages?: InputMaybe<SaleConfigPageCreateNestedManyWithoutSale_ConfigInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type SaleConfigCreateWithoutSaleConfigPagesInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  created_by?: InputMaybe<UserCreateNestedOneWithoutSale_ConfigInput>;
  deleted_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  last_updated_by?: InputMaybe<UserCreateNestedOneWithoutSale_Config_UpdatedInput>;
  Organization?: InputMaybe<OrganizationCreateNestedManyWithoutSaleConfigInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type SaleConfigListRelationFilter = {
  every?: InputMaybe<SaleConfigWhereInput>;
  none?: InputMaybe<SaleConfigWhereInput>;
  some?: InputMaybe<SaleConfigWhereInput>;
};

export type SaleConfigOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
  count?: InputMaybe<SortOrder>;
};

export type SaleConfigOrderByWithRelationInput = {
  created_at?: InputMaybe<SortOrder>;
  created_by?: InputMaybe<UserOrderByWithRelationInput>;
  created_by_user_id?: InputMaybe<SortOrder>;
  deleted_at?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  last_updated_by?: InputMaybe<UserOrderByWithRelationInput>;
  Organization?: InputMaybe<OrganizationOrderByRelationAggregateInput>;
  SaleConfigPages?: InputMaybe<SaleConfigPageOrderByRelationAggregateInput>;
  updated_at?: InputMaybe<SortOrder>;
  updated_by_user_id?: InputMaybe<SortOrder>;
};

export type SaleConfigPage = {
  __typename?: 'SaleConfigPage';
  created_at: Scalars['DateTime']['output'];
  deleted_at?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['String']['output'];
  order: Scalars['Int']['output'];
  sections?: Maybe<Array<Maybe<SaleConfigSection>>>;
  title: Scalars['String']['output'];
  updated_at: Scalars['DateTime']['output'];
};

export type SaleConfigPageCreateManyOrganizationInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  deleted_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  order: Scalars['Int']['input'];
  sale_config_id: Scalars['String']['input'];
  title: Scalars['String']['input'];
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  visible?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SaleConfigPageCreateManyOrganizationInputEnvelope = {
  data?: InputMaybe<Array<SaleConfigPageCreateManyOrganizationInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SaleConfigPageCreateManySale_ConfigInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  deleted_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  order: Scalars['Int']['input'];
  organization_id: Scalars['String']['input'];
  title: Scalars['String']['input'];
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  visible?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SaleConfigPageCreateManySale_ConfigInputEnvelope = {
  data?: InputMaybe<Array<SaleConfigPageCreateManySale_ConfigInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SaleConfigPageCreateNestedManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<SaleConfigPageWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SaleConfigPageCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<SaleConfigPageCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<SaleConfigPageCreateManyOrganizationInputEnvelope>;
};

export type SaleConfigPageCreateNestedManyWithoutSale_ConfigInput = {
  connect?: InputMaybe<Array<SaleConfigPageWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SaleConfigPageCreateOrConnectWithoutSale_ConfigInput>>;
  create?: InputMaybe<Array<SaleConfigPageCreateWithoutSale_ConfigInput>>;
  createMany?: InputMaybe<SaleConfigPageCreateManySale_ConfigInputEnvelope>;
};

export type SaleConfigPageCreateNestedOneWithoutSectionsInput = {
  connect?: InputMaybe<SaleConfigPageWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SaleConfigPageCreateOrConnectWithoutSectionsInput>;
  create?: InputMaybe<SaleConfigPageCreateWithoutSectionsInput>;
};

export type SaleConfigPageCreateOrConnectWithoutOrganizationInput = {
  create: SaleConfigPageCreateWithoutOrganizationInput;
  where: SaleConfigPageWhereUniqueInput;
};

export type SaleConfigPageCreateOrConnectWithoutSale_ConfigInput = {
  create: SaleConfigPageCreateWithoutSale_ConfigInput;
  where: SaleConfigPageWhereUniqueInput;
};

export type SaleConfigPageCreateOrConnectWithoutSectionsInput = {
  create: SaleConfigPageCreateWithoutSectionsInput;
  where: SaleConfigPageWhereUniqueInput;
};

export type SaleConfigPageCreateWithoutOrganizationInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  deleted_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  order: Scalars['Int']['input'];
  sale_config: SaleConfigCreateNestedOneWithoutSaleConfigPagesInput;
  sections?: InputMaybe<SaleConfigSectionCreateNestedManyWithoutSale_Config_PageInput>;
  title: Scalars['String']['input'];
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  visible?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SaleConfigPageCreateWithoutSale_ConfigInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  deleted_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  order: Scalars['Int']['input'];
  organization: OrganizationCreateNestedOneWithoutSaleConfigPageInput;
  sections?: InputMaybe<SaleConfigSectionCreateNestedManyWithoutSale_Config_PageInput>;
  title: Scalars['String']['input'];
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  visible?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SaleConfigPageCreateWithoutSectionsInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  deleted_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  order: Scalars['Int']['input'];
  organization: OrganizationCreateNestedOneWithoutSaleConfigPageInput;
  sale_config: SaleConfigCreateNestedOneWithoutSaleConfigPagesInput;
  title: Scalars['String']['input'];
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  visible?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SaleConfigPageListRelationFilter = {
  every?: InputMaybe<SaleConfigPageWhereInput>;
  none?: InputMaybe<SaleConfigPageWhereInput>;
  some?: InputMaybe<SaleConfigPageWhereInput>;
};

export type SaleConfigPageOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
  count?: InputMaybe<SortOrder>;
};

export type SaleConfigPageScalarWhereInput = {
  AND?: InputMaybe<Array<SaleConfigPageScalarWhereInput>>;
  created_at?: InputMaybe<DateTimeFilter>;
  deleted_at?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<StringFilter>;
  NOT?: InputMaybe<Array<SaleConfigPageScalarWhereInput>>;
  OR?: InputMaybe<Array<SaleConfigPageScalarWhereInput>>;
  order?: InputMaybe<IntFilter>;
  organization_id?: InputMaybe<StringFilter>;
  sale_config_id?: InputMaybe<StringFilter>;
  title?: InputMaybe<StringFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
  visible?: InputMaybe<BoolFilter>;
};

export type SaleConfigPageUpdateManyMutationInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deleted_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  visible?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type SaleConfigPageUpdateManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<SaleConfigPageWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SaleConfigPageCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<SaleConfigPageCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<SaleConfigPageCreateManyOrganizationInputEnvelope>;
  delete?: InputMaybe<Array<SaleConfigPageWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<SaleConfigPageScalarWhereInput>>;
  disconnect?: InputMaybe<Array<SaleConfigPageWhereUniqueInput>>;
  set?: InputMaybe<Array<SaleConfigPageWhereUniqueInput>>;
  update?: InputMaybe<Array<SaleConfigPageUpdateWithWhereUniqueWithoutOrganizationInput>>;
  updateMany?: InputMaybe<Array<SaleConfigPageUpdateManyWithWhereWithoutOrganizationInput>>;
  upsert?: InputMaybe<Array<SaleConfigPageUpsertWithWhereUniqueWithoutOrganizationInput>>;
};

export type SaleConfigPageUpdateManyWithoutSale_ConfigInput = {
  connect?: InputMaybe<Array<SaleConfigPageWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SaleConfigPageCreateOrConnectWithoutSale_ConfigInput>>;
  create?: InputMaybe<Array<SaleConfigPageCreateWithoutSale_ConfigInput>>;
  createMany?: InputMaybe<SaleConfigPageCreateManySale_ConfigInputEnvelope>;
  delete?: InputMaybe<Array<SaleConfigPageWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<SaleConfigPageScalarWhereInput>>;
  disconnect?: InputMaybe<Array<SaleConfigPageWhereUniqueInput>>;
  set?: InputMaybe<Array<SaleConfigPageWhereUniqueInput>>;
  update?: InputMaybe<Array<SaleConfigPageUpdateWithWhereUniqueWithoutSale_ConfigInput>>;
  updateMany?: InputMaybe<Array<SaleConfigPageUpdateManyWithWhereWithoutSale_ConfigInput>>;
  upsert?: InputMaybe<Array<SaleConfigPageUpsertWithWhereUniqueWithoutSale_ConfigInput>>;
};

export type SaleConfigPageUpdateManyWithWhereWithoutOrganizationInput = {
  data: SaleConfigPageUpdateManyMutationInput;
  where: SaleConfigPageScalarWhereInput;
};

export type SaleConfigPageUpdateManyWithWhereWithoutSale_ConfigInput = {
  data: SaleConfigPageUpdateManyMutationInput;
  where: SaleConfigPageScalarWhereInput;
};

export type SaleConfigPageUpdateOneRequiredWithoutSectionsInput = {
  connect?: InputMaybe<SaleConfigPageWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SaleConfigPageCreateOrConnectWithoutSectionsInput>;
  create?: InputMaybe<SaleConfigPageCreateWithoutSectionsInput>;
  update?: InputMaybe<SaleConfigPageUpdateWithoutSectionsInput>;
  upsert?: InputMaybe<SaleConfigPageUpsertWithoutSectionsInput>;
};

export type SaleConfigPageUpdateWithoutOrganizationInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deleted_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  sale_config?: InputMaybe<SaleConfigUpdateOneRequiredWithoutSaleConfigPagesInput>;
  sections?: InputMaybe<SaleConfigSectionUpdateManyWithoutSale_Config_PageInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  visible?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type SaleConfigPageUpdateWithoutSale_ConfigInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deleted_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutSaleConfigPageInput>;
  sections?: InputMaybe<SaleConfigSectionUpdateManyWithoutSale_Config_PageInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  visible?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type SaleConfigPageUpdateWithoutSectionsInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deleted_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutSaleConfigPageInput>;
  sale_config?: InputMaybe<SaleConfigUpdateOneRequiredWithoutSaleConfigPagesInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  visible?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type SaleConfigPageUpdateWithWhereUniqueWithoutOrganizationInput = {
  data: SaleConfigPageUpdateWithoutOrganizationInput;
  where: SaleConfigPageWhereUniqueInput;
};

export type SaleConfigPageUpdateWithWhereUniqueWithoutSale_ConfigInput = {
  data: SaleConfigPageUpdateWithoutSale_ConfigInput;
  where: SaleConfigPageWhereUniqueInput;
};

export type SaleConfigPageUpsertWithoutSectionsInput = {
  create: SaleConfigPageCreateWithoutSectionsInput;
  update: SaleConfigPageUpdateWithoutSectionsInput;
};

export type SaleConfigPageUpsertWithWhereUniqueWithoutOrganizationInput = {
  create: SaleConfigPageCreateWithoutOrganizationInput;
  update: SaleConfigPageUpdateWithoutOrganizationInput;
  where: SaleConfigPageWhereUniqueInput;
};

export type SaleConfigPageUpsertWithWhereUniqueWithoutSale_ConfigInput = {
  create: SaleConfigPageCreateWithoutSale_ConfigInput;
  update: SaleConfigPageUpdateWithoutSale_ConfigInput;
  where: SaleConfigPageWhereUniqueInput;
};

export type SaleConfigPageWhereInput = {
  AND?: InputMaybe<Array<SaleConfigPageWhereInput>>;
  created_at?: InputMaybe<DateTimeFilter>;
  deleted_at?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<StringFilter>;
  NOT?: InputMaybe<Array<SaleConfigPageWhereInput>>;
  OR?: InputMaybe<Array<SaleConfigPageWhereInput>>;
  order?: InputMaybe<IntFilter>;
  organization?: InputMaybe<OrganizationWhereInput>;
  organization_id?: InputMaybe<StringFilter>;
  sale_config?: InputMaybe<SaleConfigWhereInput>;
  sale_config_id?: InputMaybe<StringFilter>;
  sections?: InputMaybe<SaleConfigSectionListRelationFilter>;
  title?: InputMaybe<StringFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
  visible?: InputMaybe<BoolFilter>;
};

export type SaleConfigPageWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']['input']>;
};

export type SaleConfigScalarWhereInput = {
  AND?: InputMaybe<Array<SaleConfigScalarWhereInput>>;
  created_at?: InputMaybe<DateTimeFilter>;
  created_by_user_id?: InputMaybe<StringNullableFilter>;
  deleted_at?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<StringFilter>;
  NOT?: InputMaybe<Array<SaleConfigScalarWhereInput>>;
  OR?: InputMaybe<Array<SaleConfigScalarWhereInput>>;
  updated_at?: InputMaybe<DateTimeFilter>;
  updated_by_user_id?: InputMaybe<StringNullableFilter>;
};

export type SaleConfigSection = {
  __typename?: 'SaleConfigSection';
  content?: Maybe<Scalars['String']['output']>;
  created_at: Scalars['DateTime']['output'];
  custom_field?: Maybe<CustomField>;
  custom_field_id?: Maybe<Scalars['String']['output']>;
  deleted_at?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['String']['output'];
  required?: Maybe<Scalars['Boolean']['output']>;
  system_field?: Maybe<SystemFieldTypes>;
  type: SaleSectionType;
  updated_at: Scalars['DateTime']['output'];
};

export type SaleConfigSectionCreateManyCustom_FieldInput = {
  content?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  deleted_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  order: Scalars['Int']['input'];
  organization_id: Scalars['String']['input'];
  required?: InputMaybe<Scalars['Boolean']['input']>;
  sale_config_page_id: Scalars['String']['input'];
  system_field?: InputMaybe<SystemFieldTypes>;
  type: SaleSectionType;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type SaleConfigSectionCreateManyCustom_FieldInputEnvelope = {
  data?: InputMaybe<Array<SaleConfigSectionCreateManyCustom_FieldInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SaleConfigSectionCreateManyOrganizationInput = {
  content?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  custom_field_id?: InputMaybe<Scalars['String']['input']>;
  deleted_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  order: Scalars['Int']['input'];
  required?: InputMaybe<Scalars['Boolean']['input']>;
  sale_config_page_id: Scalars['String']['input'];
  system_field?: InputMaybe<SystemFieldTypes>;
  type: SaleSectionType;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type SaleConfigSectionCreateManyOrganizationInputEnvelope = {
  data?: InputMaybe<Array<SaleConfigSectionCreateManyOrganizationInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SaleConfigSectionCreateManySale_Config_PageInput = {
  content?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  custom_field_id?: InputMaybe<Scalars['String']['input']>;
  deleted_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  order: Scalars['Int']['input'];
  organization_id: Scalars['String']['input'];
  required?: InputMaybe<Scalars['Boolean']['input']>;
  system_field?: InputMaybe<SystemFieldTypes>;
  type: SaleSectionType;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type SaleConfigSectionCreateManySale_Config_PageInputEnvelope = {
  data?: InputMaybe<Array<SaleConfigSectionCreateManySale_Config_PageInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SaleConfigSectionCreateNestedManyWithoutCustom_FieldInput = {
  connect?: InputMaybe<Array<SaleConfigSectionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SaleConfigSectionCreateOrConnectWithoutCustom_FieldInput>>;
  create?: InputMaybe<Array<SaleConfigSectionCreateWithoutCustom_FieldInput>>;
  createMany?: InputMaybe<SaleConfigSectionCreateManyCustom_FieldInputEnvelope>;
};

export type SaleConfigSectionCreateNestedManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<SaleConfigSectionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SaleConfigSectionCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<SaleConfigSectionCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<SaleConfigSectionCreateManyOrganizationInputEnvelope>;
};

export type SaleConfigSectionCreateNestedManyWithoutSale_Config_PageInput = {
  connect?: InputMaybe<Array<SaleConfigSectionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SaleConfigSectionCreateOrConnectWithoutSale_Config_PageInput>>;
  create?: InputMaybe<Array<SaleConfigSectionCreateWithoutSale_Config_PageInput>>;
  createMany?: InputMaybe<SaleConfigSectionCreateManySale_Config_PageInputEnvelope>;
};

export type SaleConfigSectionCreateOrConnectWithoutCustom_FieldInput = {
  create: SaleConfigSectionCreateWithoutCustom_FieldInput;
  where: SaleConfigSectionWhereUniqueInput;
};

export type SaleConfigSectionCreateOrConnectWithoutOrganizationInput = {
  create: SaleConfigSectionCreateWithoutOrganizationInput;
  where: SaleConfigSectionWhereUniqueInput;
};

export type SaleConfigSectionCreateOrConnectWithoutSale_Config_PageInput = {
  create: SaleConfigSectionCreateWithoutSale_Config_PageInput;
  where: SaleConfigSectionWhereUniqueInput;
};

export type SaleConfigSectionCreateWithoutCustom_FieldInput = {
  content?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  deleted_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  order: Scalars['Int']['input'];
  organization: OrganizationCreateNestedOneWithoutSaleConfigSectionInput;
  required?: InputMaybe<Scalars['Boolean']['input']>;
  sale_config_page: SaleConfigPageCreateNestedOneWithoutSectionsInput;
  system_field?: InputMaybe<SystemFieldTypes>;
  type: SaleSectionType;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type SaleConfigSectionCreateWithoutOrganizationInput = {
  content?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  custom_field?: InputMaybe<CustomFieldCreateNestedOneWithoutSaleConfigSectionInput>;
  deleted_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  order: Scalars['Int']['input'];
  required?: InputMaybe<Scalars['Boolean']['input']>;
  sale_config_page: SaleConfigPageCreateNestedOneWithoutSectionsInput;
  system_field?: InputMaybe<SystemFieldTypes>;
  type: SaleSectionType;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type SaleConfigSectionCreateWithoutSale_Config_PageInput = {
  content?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  custom_field?: InputMaybe<CustomFieldCreateNestedOneWithoutSaleConfigSectionInput>;
  deleted_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  order: Scalars['Int']['input'];
  organization: OrganizationCreateNestedOneWithoutSaleConfigSectionInput;
  required?: InputMaybe<Scalars['Boolean']['input']>;
  system_field?: InputMaybe<SystemFieldTypes>;
  type: SaleSectionType;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type SaleConfigSectionListRelationFilter = {
  every?: InputMaybe<SaleConfigSectionWhereInput>;
  none?: InputMaybe<SaleConfigSectionWhereInput>;
  some?: InputMaybe<SaleConfigSectionWhereInput>;
};

export type SaleConfigSectionOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
  count?: InputMaybe<SortOrder>;
};

export type SaleConfigSectionOrderOrganization_IdSale_Config_Page_IdCompoundUniqueInput = {
  order: Scalars['Int']['input'];
  organization_id: Scalars['String']['input'];
  sale_config_page_id: Scalars['String']['input'];
};

export type SaleConfigSectionScalarWhereInput = {
  AND?: InputMaybe<Array<SaleConfigSectionScalarWhereInput>>;
  content?: InputMaybe<StringNullableFilter>;
  created_at?: InputMaybe<DateTimeFilter>;
  custom_field_id?: InputMaybe<StringNullableFilter>;
  deleted_at?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<StringFilter>;
  NOT?: InputMaybe<Array<SaleConfigSectionScalarWhereInput>>;
  OR?: InputMaybe<Array<SaleConfigSectionScalarWhereInput>>;
  order?: InputMaybe<IntFilter>;
  organization_id?: InputMaybe<StringFilter>;
  required?: InputMaybe<BoolNullableFilter>;
  sale_config_page_id?: InputMaybe<StringFilter>;
  system_field?: InputMaybe<EnumSystemFieldTypesNullableFilter>;
  type?: InputMaybe<EnumSaleSectionTypeFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
};

export type SaleConfigSectionTypeInput = {
  /** id of a product */
  content: Scalars['String']['input'];
  custom_field_id?: InputMaybe<Scalars['String']['input']>;
  /** Page position. */
  order: Scalars['Int']['input'];
  /** set the field as required. */
  required: Scalars['Boolean']['input'];
  system_field?: InputMaybe<SystemFieldTypeInput>;
  type: SaleSectionEnumType;
};

export type SaleConfigSectionUpdateManyMutationInput = {
  content?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deleted_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  required?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  system_field?: InputMaybe<NullableEnumSystemFieldTypesFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumSaleSectionTypeFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type SaleConfigSectionUpdateManyWithoutCustom_FieldInput = {
  connect?: InputMaybe<Array<SaleConfigSectionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SaleConfigSectionCreateOrConnectWithoutCustom_FieldInput>>;
  create?: InputMaybe<Array<SaleConfigSectionCreateWithoutCustom_FieldInput>>;
  createMany?: InputMaybe<SaleConfigSectionCreateManyCustom_FieldInputEnvelope>;
  delete?: InputMaybe<Array<SaleConfigSectionWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<SaleConfigSectionScalarWhereInput>>;
  disconnect?: InputMaybe<Array<SaleConfigSectionWhereUniqueInput>>;
  set?: InputMaybe<Array<SaleConfigSectionWhereUniqueInput>>;
  update?: InputMaybe<Array<SaleConfigSectionUpdateWithWhereUniqueWithoutCustom_FieldInput>>;
  updateMany?: InputMaybe<Array<SaleConfigSectionUpdateManyWithWhereWithoutCustom_FieldInput>>;
  upsert?: InputMaybe<Array<SaleConfigSectionUpsertWithWhereUniqueWithoutCustom_FieldInput>>;
};

export type SaleConfigSectionUpdateManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<SaleConfigSectionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SaleConfigSectionCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<SaleConfigSectionCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<SaleConfigSectionCreateManyOrganizationInputEnvelope>;
  delete?: InputMaybe<Array<SaleConfigSectionWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<SaleConfigSectionScalarWhereInput>>;
  disconnect?: InputMaybe<Array<SaleConfigSectionWhereUniqueInput>>;
  set?: InputMaybe<Array<SaleConfigSectionWhereUniqueInput>>;
  update?: InputMaybe<Array<SaleConfigSectionUpdateWithWhereUniqueWithoutOrganizationInput>>;
  updateMany?: InputMaybe<Array<SaleConfigSectionUpdateManyWithWhereWithoutOrganizationInput>>;
  upsert?: InputMaybe<Array<SaleConfigSectionUpsertWithWhereUniqueWithoutOrganizationInput>>;
};

export type SaleConfigSectionUpdateManyWithoutSale_Config_PageInput = {
  connect?: InputMaybe<Array<SaleConfigSectionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SaleConfigSectionCreateOrConnectWithoutSale_Config_PageInput>>;
  create?: InputMaybe<Array<SaleConfigSectionCreateWithoutSale_Config_PageInput>>;
  createMany?: InputMaybe<SaleConfigSectionCreateManySale_Config_PageInputEnvelope>;
  delete?: InputMaybe<Array<SaleConfigSectionWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<SaleConfigSectionScalarWhereInput>>;
  disconnect?: InputMaybe<Array<SaleConfigSectionWhereUniqueInput>>;
  set?: InputMaybe<Array<SaleConfigSectionWhereUniqueInput>>;
  update?: InputMaybe<Array<SaleConfigSectionUpdateWithWhereUniqueWithoutSale_Config_PageInput>>;
  updateMany?: InputMaybe<Array<SaleConfigSectionUpdateManyWithWhereWithoutSale_Config_PageInput>>;
  upsert?: InputMaybe<Array<SaleConfigSectionUpsertWithWhereUniqueWithoutSale_Config_PageInput>>;
};

export type SaleConfigSectionUpdateManyWithWhereWithoutCustom_FieldInput = {
  data: SaleConfigSectionUpdateManyMutationInput;
  where: SaleConfigSectionScalarWhereInput;
};

export type SaleConfigSectionUpdateManyWithWhereWithoutOrganizationInput = {
  data: SaleConfigSectionUpdateManyMutationInput;
  where: SaleConfigSectionScalarWhereInput;
};

export type SaleConfigSectionUpdateManyWithWhereWithoutSale_Config_PageInput = {
  data: SaleConfigSectionUpdateManyMutationInput;
  where: SaleConfigSectionScalarWhereInput;
};

export type SaleConfigSectionUpdateWithoutCustom_FieldInput = {
  content?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deleted_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutSaleConfigSectionInput>;
  required?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  sale_config_page?: InputMaybe<SaleConfigPageUpdateOneRequiredWithoutSectionsInput>;
  system_field?: InputMaybe<NullableEnumSystemFieldTypesFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumSaleSectionTypeFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type SaleConfigSectionUpdateWithoutOrganizationInput = {
  content?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  custom_field?: InputMaybe<CustomFieldUpdateOneWithoutSaleConfigSectionInput>;
  deleted_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  required?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  sale_config_page?: InputMaybe<SaleConfigPageUpdateOneRequiredWithoutSectionsInput>;
  system_field?: InputMaybe<NullableEnumSystemFieldTypesFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumSaleSectionTypeFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type SaleConfigSectionUpdateWithoutSale_Config_PageInput = {
  content?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  custom_field?: InputMaybe<CustomFieldUpdateOneWithoutSaleConfigSectionInput>;
  deleted_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutSaleConfigSectionInput>;
  required?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  system_field?: InputMaybe<NullableEnumSystemFieldTypesFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumSaleSectionTypeFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type SaleConfigSectionUpdateWithWhereUniqueWithoutCustom_FieldInput = {
  data: SaleConfigSectionUpdateWithoutCustom_FieldInput;
  where: SaleConfigSectionWhereUniqueInput;
};

export type SaleConfigSectionUpdateWithWhereUniqueWithoutOrganizationInput = {
  data: SaleConfigSectionUpdateWithoutOrganizationInput;
  where: SaleConfigSectionWhereUniqueInput;
};

export type SaleConfigSectionUpdateWithWhereUniqueWithoutSale_Config_PageInput = {
  data: SaleConfigSectionUpdateWithoutSale_Config_PageInput;
  where: SaleConfigSectionWhereUniqueInput;
};

export type SaleConfigSectionUpsertWithWhereUniqueWithoutCustom_FieldInput = {
  create: SaleConfigSectionCreateWithoutCustom_FieldInput;
  update: SaleConfigSectionUpdateWithoutCustom_FieldInput;
  where: SaleConfigSectionWhereUniqueInput;
};

export type SaleConfigSectionUpsertWithWhereUniqueWithoutOrganizationInput = {
  create: SaleConfigSectionCreateWithoutOrganizationInput;
  update: SaleConfigSectionUpdateWithoutOrganizationInput;
  where: SaleConfigSectionWhereUniqueInput;
};

export type SaleConfigSectionUpsertWithWhereUniqueWithoutSale_Config_PageInput = {
  create: SaleConfigSectionCreateWithoutSale_Config_PageInput;
  update: SaleConfigSectionUpdateWithoutSale_Config_PageInput;
  where: SaleConfigSectionWhereUniqueInput;
};

export type SaleConfigSectionWhereInput = {
  AND?: InputMaybe<Array<SaleConfigSectionWhereInput>>;
  content?: InputMaybe<StringNullableFilter>;
  created_at?: InputMaybe<DateTimeFilter>;
  custom_field?: InputMaybe<CustomFieldWhereInput>;
  custom_field_id?: InputMaybe<StringNullableFilter>;
  deleted_at?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<StringFilter>;
  NOT?: InputMaybe<Array<SaleConfigSectionWhereInput>>;
  OR?: InputMaybe<Array<SaleConfigSectionWhereInput>>;
  order?: InputMaybe<IntFilter>;
  organization?: InputMaybe<OrganizationWhereInput>;
  organization_id?: InputMaybe<StringFilter>;
  required?: InputMaybe<BoolNullableFilter>;
  sale_config_page?: InputMaybe<SaleConfigPageWhereInput>;
  sale_config_page_id?: InputMaybe<StringFilter>;
  system_field?: InputMaybe<EnumSystemFieldTypesNullableFilter>;
  type?: InputMaybe<EnumSaleSectionTypeFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
};

export type SaleConfigSectionWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  order_organization_id_sale_config_page_id?: InputMaybe<SaleConfigSectionOrderOrganization_IdSale_Config_Page_IdCompoundUniqueInput>;
};

export type SaleConfigUpdateManyMutationInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deleted_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type SaleConfigUpdateManyWithoutCreated_ByInput = {
  connect?: InputMaybe<Array<SaleConfigWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SaleConfigCreateOrConnectWithoutCreated_ByInput>>;
  create?: InputMaybe<Array<SaleConfigCreateWithoutCreated_ByInput>>;
  createMany?: InputMaybe<SaleConfigCreateManyCreated_ByInputEnvelope>;
  delete?: InputMaybe<Array<SaleConfigWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<SaleConfigScalarWhereInput>>;
  disconnect?: InputMaybe<Array<SaleConfigWhereUniqueInput>>;
  set?: InputMaybe<Array<SaleConfigWhereUniqueInput>>;
  update?: InputMaybe<Array<SaleConfigUpdateWithWhereUniqueWithoutCreated_ByInput>>;
  updateMany?: InputMaybe<Array<SaleConfigUpdateManyWithWhereWithoutCreated_ByInput>>;
  upsert?: InputMaybe<Array<SaleConfigUpsertWithWhereUniqueWithoutCreated_ByInput>>;
};

export type SaleConfigUpdateManyWithoutLast_Updated_ByInput = {
  connect?: InputMaybe<Array<SaleConfigWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SaleConfigCreateOrConnectWithoutLast_Updated_ByInput>>;
  create?: InputMaybe<Array<SaleConfigCreateWithoutLast_Updated_ByInput>>;
  createMany?: InputMaybe<SaleConfigCreateManyLast_Updated_ByInputEnvelope>;
  delete?: InputMaybe<Array<SaleConfigWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<SaleConfigScalarWhereInput>>;
  disconnect?: InputMaybe<Array<SaleConfigWhereUniqueInput>>;
  set?: InputMaybe<Array<SaleConfigWhereUniqueInput>>;
  update?: InputMaybe<Array<SaleConfigUpdateWithWhereUniqueWithoutLast_Updated_ByInput>>;
  updateMany?: InputMaybe<Array<SaleConfigUpdateManyWithWhereWithoutLast_Updated_ByInput>>;
  upsert?: InputMaybe<Array<SaleConfigUpsertWithWhereUniqueWithoutLast_Updated_ByInput>>;
};

export type SaleConfigUpdateManyWithWhereWithoutCreated_ByInput = {
  data: SaleConfigUpdateManyMutationInput;
  where: SaleConfigScalarWhereInput;
};

export type SaleConfigUpdateManyWithWhereWithoutLast_Updated_ByInput = {
  data: SaleConfigUpdateManyMutationInput;
  where: SaleConfigScalarWhereInput;
};

export type SaleConfigUpdateOneRequiredWithoutSaleConfigPagesInput = {
  connect?: InputMaybe<SaleConfigWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SaleConfigCreateOrConnectWithoutSaleConfigPagesInput>;
  create?: InputMaybe<SaleConfigCreateWithoutSaleConfigPagesInput>;
  update?: InputMaybe<SaleConfigUpdateWithoutSaleConfigPagesInput>;
  upsert?: InputMaybe<SaleConfigUpsertWithoutSaleConfigPagesInput>;
};

export type SaleConfigUpdateOneWithoutOrganizationInput = {
  connect?: InputMaybe<SaleConfigWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SaleConfigCreateOrConnectWithoutOrganizationInput>;
  create?: InputMaybe<SaleConfigCreateWithoutOrganizationInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<SaleConfigUpdateWithoutOrganizationInput>;
  upsert?: InputMaybe<SaleConfigUpsertWithoutOrganizationInput>;
};

export type SaleConfigUpdateWithoutCreated_ByInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deleted_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  last_updated_by?: InputMaybe<UserUpdateOneWithoutSale_Config_UpdatedInput>;
  Organization?: InputMaybe<OrganizationUpdateManyWithoutSaleConfigInput>;
  SaleConfigPages?: InputMaybe<SaleConfigPageUpdateManyWithoutSale_ConfigInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type SaleConfigUpdateWithoutLast_Updated_ByInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  created_by?: InputMaybe<UserUpdateOneWithoutSale_ConfigInput>;
  deleted_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  Organization?: InputMaybe<OrganizationUpdateManyWithoutSaleConfigInput>;
  SaleConfigPages?: InputMaybe<SaleConfigPageUpdateManyWithoutSale_ConfigInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type SaleConfigUpdateWithoutOrganizationInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  created_by?: InputMaybe<UserUpdateOneWithoutSale_ConfigInput>;
  deleted_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  last_updated_by?: InputMaybe<UserUpdateOneWithoutSale_Config_UpdatedInput>;
  SaleConfigPages?: InputMaybe<SaleConfigPageUpdateManyWithoutSale_ConfigInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type SaleConfigUpdateWithoutSaleConfigPagesInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  created_by?: InputMaybe<UserUpdateOneWithoutSale_ConfigInput>;
  deleted_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  last_updated_by?: InputMaybe<UserUpdateOneWithoutSale_Config_UpdatedInput>;
  Organization?: InputMaybe<OrganizationUpdateManyWithoutSaleConfigInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type SaleConfigUpdateWithWhereUniqueWithoutCreated_ByInput = {
  data: SaleConfigUpdateWithoutCreated_ByInput;
  where: SaleConfigWhereUniqueInput;
};

export type SaleConfigUpdateWithWhereUniqueWithoutLast_Updated_ByInput = {
  data: SaleConfigUpdateWithoutLast_Updated_ByInput;
  where: SaleConfigWhereUniqueInput;
};

export type SaleConfigUpsertWithoutOrganizationInput = {
  create: SaleConfigCreateWithoutOrganizationInput;
  update: SaleConfigUpdateWithoutOrganizationInput;
};

export type SaleConfigUpsertWithoutSaleConfigPagesInput = {
  create: SaleConfigCreateWithoutSaleConfigPagesInput;
  update: SaleConfigUpdateWithoutSaleConfigPagesInput;
};

export type SaleConfigUpsertWithWhereUniqueWithoutCreated_ByInput = {
  create: SaleConfigCreateWithoutCreated_ByInput;
  update: SaleConfigUpdateWithoutCreated_ByInput;
  where: SaleConfigWhereUniqueInput;
};

export type SaleConfigUpsertWithWhereUniqueWithoutLast_Updated_ByInput = {
  create: SaleConfigCreateWithoutLast_Updated_ByInput;
  update: SaleConfigUpdateWithoutLast_Updated_ByInput;
  where: SaleConfigWhereUniqueInput;
};

export type SaleConfigWhereInput = {
  AND?: InputMaybe<Array<SaleConfigWhereInput>>;
  created_at?: InputMaybe<DateTimeFilter>;
  created_by?: InputMaybe<UserWhereInput>;
  created_by_user_id?: InputMaybe<StringNullableFilter>;
  deleted_at?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<StringFilter>;
  last_updated_by?: InputMaybe<UserWhereInput>;
  NOT?: InputMaybe<Array<SaleConfigWhereInput>>;
  OR?: InputMaybe<Array<SaleConfigWhereInput>>;
  Organization?: InputMaybe<OrganizationListRelationFilter>;
  SaleConfigPages?: InputMaybe<SaleConfigPageListRelationFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
  updated_by_user_id?: InputMaybe<StringNullableFilter>;
};

export type SaleConfigWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']['input']>;
};

export type SaleCreateManyConverted_SequenceInput = {
  contract_duration?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  hubspot_sale_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  lead_activity_id?: InputMaybe<Scalars['String']['input']>;
  lead_id?: InputMaybe<Scalars['String']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  organization_id?: InputMaybe<Scalars['String']['input']>;
  pandadoc_sale_id?: InputMaybe<Scalars['String']['input']>;
  payment_terms?: InputMaybe<Scalars['String']['input']>;
  prepayment?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type SaleCreateManyConverted_SequenceInputEnvelope = {
  data?: InputMaybe<Array<SaleCreateManyConverted_SequenceInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SaleCreateManyLeadInput = {
  contract_duration?: InputMaybe<Scalars['String']['input']>;
  converted_sequence_id?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  hubspot_sale_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  lead_activity_id?: InputMaybe<Scalars['String']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  organization_id?: InputMaybe<Scalars['String']['input']>;
  pandadoc_sale_id?: InputMaybe<Scalars['String']['input']>;
  payment_terms?: InputMaybe<Scalars['String']['input']>;
  prepayment?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type SaleCreateManyLeadInputEnvelope = {
  data?: InputMaybe<Array<SaleCreateManyLeadInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SaleCreateManyOrganizationInput = {
  contract_duration?: InputMaybe<Scalars['String']['input']>;
  converted_sequence_id?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  hubspot_sale_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  lead_activity_id?: InputMaybe<Scalars['String']['input']>;
  lead_id?: InputMaybe<Scalars['String']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  pandadoc_sale_id?: InputMaybe<Scalars['String']['input']>;
  payment_terms?: InputMaybe<Scalars['String']['input']>;
  prepayment?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type SaleCreateManyOrganizationInputEnvelope = {
  data?: InputMaybe<Array<SaleCreateManyOrganizationInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SaleCreateNestedManyWithoutConverted_SequenceInput = {
  connect?: InputMaybe<Array<SaleWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SaleCreateOrConnectWithoutConverted_SequenceInput>>;
  create?: InputMaybe<Array<SaleCreateWithoutConverted_SequenceInput>>;
  createMany?: InputMaybe<SaleCreateManyConverted_SequenceInputEnvelope>;
};

export type SaleCreateNestedManyWithoutLeadInput = {
  connect?: InputMaybe<Array<SaleWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SaleCreateOrConnectWithoutLeadInput>>;
  create?: InputMaybe<Array<SaleCreateWithoutLeadInput>>;
  createMany?: InputMaybe<SaleCreateManyLeadInputEnvelope>;
};

export type SaleCreateNestedManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<SaleWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SaleCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<SaleCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<SaleCreateManyOrganizationInputEnvelope>;
};

export type SaleCreateNestedOneWithoutCommissionsInput = {
  connect?: InputMaybe<SaleWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SaleCreateOrConnectWithoutCommissionsInput>;
  create?: InputMaybe<SaleCreateWithoutCommissionsInput>;
};

export type SaleCreateNestedOneWithoutLead_ActivityInput = {
  connect?: InputMaybe<SaleWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SaleCreateOrConnectWithoutLead_ActivityInput>;
  create?: InputMaybe<SaleCreateWithoutLead_ActivityInput>;
};

export type SaleCreateNestedOneWithoutSale_ItemsInput = {
  connect?: InputMaybe<SaleWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SaleCreateOrConnectWithoutSale_ItemsInput>;
  create?: InputMaybe<SaleCreateWithoutSale_ItemsInput>;
};

export type SaleCreateOrConnectWithoutCommissionsInput = {
  create: SaleCreateWithoutCommissionsInput;
  where: SaleWhereUniqueInput;
};

export type SaleCreateOrConnectWithoutConverted_SequenceInput = {
  create: SaleCreateWithoutConverted_SequenceInput;
  where: SaleWhereUniqueInput;
};

export type SaleCreateOrConnectWithoutLead_ActivityInput = {
  create: SaleCreateWithoutLead_ActivityInput;
  where: SaleWhereUniqueInput;
};

export type SaleCreateOrConnectWithoutLeadInput = {
  create: SaleCreateWithoutLeadInput;
  where: SaleWhereUniqueInput;
};

export type SaleCreateOrConnectWithoutOrganizationInput = {
  create: SaleCreateWithoutOrganizationInput;
  where: SaleWhereUniqueInput;
};

export type SaleCreateOrConnectWithoutSale_ItemsInput = {
  create: SaleCreateWithoutSale_ItemsInput;
  where: SaleWhereUniqueInput;
};

export type SaleCreateWithoutCommissionsInput = {
  contract_duration?: InputMaybe<Scalars['String']['input']>;
  converted_sequence?: InputMaybe<SequenceCreateNestedOneWithoutSaleInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  hubspot_sale_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  lead?: InputMaybe<LeadCreateNestedOneWithoutSaleInput>;
  lead_activity?: InputMaybe<LeadActivityCreateNestedOneWithoutSaleInput>;
  notes?: InputMaybe<Scalars['String']['input']>;
  organization?: InputMaybe<OrganizationCreateNestedOneWithoutSalesInput>;
  pandadoc_sale_id?: InputMaybe<Scalars['String']['input']>;
  payment_terms?: InputMaybe<Scalars['String']['input']>;
  prepayment?: InputMaybe<Scalars['Int']['input']>;
  sale_items?: InputMaybe<SaleItemCreateNestedManyWithoutSaleInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type SaleCreateWithoutConverted_SequenceInput = {
  commissions?: InputMaybe<CommissionCreateNestedManyWithoutSaleInput>;
  contract_duration?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  hubspot_sale_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  lead?: InputMaybe<LeadCreateNestedOneWithoutSaleInput>;
  lead_activity?: InputMaybe<LeadActivityCreateNestedOneWithoutSaleInput>;
  notes?: InputMaybe<Scalars['String']['input']>;
  organization?: InputMaybe<OrganizationCreateNestedOneWithoutSalesInput>;
  pandadoc_sale_id?: InputMaybe<Scalars['String']['input']>;
  payment_terms?: InputMaybe<Scalars['String']['input']>;
  prepayment?: InputMaybe<Scalars['Int']['input']>;
  sale_items?: InputMaybe<SaleItemCreateNestedManyWithoutSaleInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type SaleCreateWithoutLead_ActivityInput = {
  commissions?: InputMaybe<CommissionCreateNestedManyWithoutSaleInput>;
  contract_duration?: InputMaybe<Scalars['String']['input']>;
  converted_sequence?: InputMaybe<SequenceCreateNestedOneWithoutSaleInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  hubspot_sale_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  lead?: InputMaybe<LeadCreateNestedOneWithoutSaleInput>;
  notes?: InputMaybe<Scalars['String']['input']>;
  organization?: InputMaybe<OrganizationCreateNestedOneWithoutSalesInput>;
  pandadoc_sale_id?: InputMaybe<Scalars['String']['input']>;
  payment_terms?: InputMaybe<Scalars['String']['input']>;
  prepayment?: InputMaybe<Scalars['Int']['input']>;
  sale_items?: InputMaybe<SaleItemCreateNestedManyWithoutSaleInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type SaleCreateWithoutLeadInput = {
  commissions?: InputMaybe<CommissionCreateNestedManyWithoutSaleInput>;
  contract_duration?: InputMaybe<Scalars['String']['input']>;
  converted_sequence?: InputMaybe<SequenceCreateNestedOneWithoutSaleInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  hubspot_sale_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  lead_activity?: InputMaybe<LeadActivityCreateNestedOneWithoutSaleInput>;
  notes?: InputMaybe<Scalars['String']['input']>;
  organization?: InputMaybe<OrganizationCreateNestedOneWithoutSalesInput>;
  pandadoc_sale_id?: InputMaybe<Scalars['String']['input']>;
  payment_terms?: InputMaybe<Scalars['String']['input']>;
  prepayment?: InputMaybe<Scalars['Int']['input']>;
  sale_items?: InputMaybe<SaleItemCreateNestedManyWithoutSaleInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type SaleCreateWithoutOrganizationInput = {
  commissions?: InputMaybe<CommissionCreateNestedManyWithoutSaleInput>;
  contract_duration?: InputMaybe<Scalars['String']['input']>;
  converted_sequence?: InputMaybe<SequenceCreateNestedOneWithoutSaleInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  hubspot_sale_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  lead?: InputMaybe<LeadCreateNestedOneWithoutSaleInput>;
  lead_activity?: InputMaybe<LeadActivityCreateNestedOneWithoutSaleInput>;
  notes?: InputMaybe<Scalars['String']['input']>;
  pandadoc_sale_id?: InputMaybe<Scalars['String']['input']>;
  payment_terms?: InputMaybe<Scalars['String']['input']>;
  prepayment?: InputMaybe<Scalars['Int']['input']>;
  sale_items?: InputMaybe<SaleItemCreateNestedManyWithoutSaleInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type SaleCreateWithoutSale_ItemsInput = {
  commissions?: InputMaybe<CommissionCreateNestedManyWithoutSaleInput>;
  contract_duration?: InputMaybe<Scalars['String']['input']>;
  converted_sequence?: InputMaybe<SequenceCreateNestedOneWithoutSaleInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  hubspot_sale_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  lead?: InputMaybe<LeadCreateNestedOneWithoutSaleInput>;
  lead_activity?: InputMaybe<LeadActivityCreateNestedOneWithoutSaleInput>;
  notes?: InputMaybe<Scalars['String']['input']>;
  organization?: InputMaybe<OrganizationCreateNestedOneWithoutSalesInput>;
  pandadoc_sale_id?: InputMaybe<Scalars['String']['input']>;
  payment_terms?: InputMaybe<Scalars['String']['input']>;
  prepayment?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type SaleCycle = {
  __typename?: 'SaleCycle';
  close: Scalars['Int']['output'];
  close_user?: Maybe<User>;
  close_user_id?: Maybe<Scalars['String']['output']>;
  created_at: Scalars['DateTime']['output'];
  cycle_num: Scalars['Int']['output'];
  days_set_out?: Maybe<Scalars['Float']['output']>;
  hold: Scalars['Int']['output'];
  lead: Lead;
  lead_activities: Array<LeadActivity>;
  lead_id: Scalars['String']['output'];
  num_dials: Scalars['Int']['output'];
  set: Scalars['Int']['output'];
  set_user?: Maybe<User>;
  set_user_id?: Maybe<Scalars['String']['output']>;
  time_from_first_hold_to_close?: Maybe<Scalars['String']['output']>;
  time_from_first_set_to_close?: Maybe<Scalars['String']['output']>;
  time_of_first_contact?: Maybe<Scalars['DateTime']['output']>;
  time_of_first_hold?: Maybe<Scalars['DateTime']['output']>;
  time_of_first_sale?: Maybe<Scalars['DateTime']['output']>;
  time_of_first_set?: Maybe<Scalars['DateTime']['output']>;
  updated_at: Scalars['DateTime']['output'];
};


export type SaleCycleLead_ActivitiesArgs = {
  after?: InputMaybe<LeadActivityWhereUniqueInput>;
  before?: InputMaybe<LeadActivityWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type SaleCycleCreateManyClose_SiteInput = {
  close?: InputMaybe<Scalars['Int']['input']>;
  close_team_id?: InputMaybe<Scalars['String']['input']>;
  close_user_id?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  cycle_num: Scalars['Int']['input'];
  days_set_out?: InputMaybe<Scalars['Float']['input']>;
  hold?: InputMaybe<Scalars['Int']['input']>;
  lead_id: Scalars['String']['input'];
  live_transfer?: InputMaybe<Scalars['Int']['input']>;
  num_dials?: InputMaybe<Scalars['Int']['input']>;
  organization_id?: InputMaybe<Scalars['String']['input']>;
  sdr_transfer?: InputMaybe<Scalars['Int']['input']>;
  set?: InputMaybe<Scalars['Int']['input']>;
  set_site_id?: InputMaybe<Scalars['String']['input']>;
  set_team_id?: InputMaybe<Scalars['String']['input']>;
  set_user_id?: InputMaybe<Scalars['String']['input']>;
  sf_opportunity_id?: InputMaybe<Scalars['String']['input']>;
  time_of_first_contact?: InputMaybe<Scalars['DateTime']['input']>;
  time_of_first_hold?: InputMaybe<Scalars['DateTime']['input']>;
  time_of_first_sale?: InputMaybe<Scalars['DateTime']['input']>;
  time_of_first_set?: InputMaybe<Scalars['DateTime']['input']>;
  transfer_site_id?: InputMaybe<Scalars['String']['input']>;
  transfer_team_id?: InputMaybe<Scalars['String']['input']>;
  transfer_user_id?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type SaleCycleCreateManyClose_SiteInputEnvelope = {
  data?: InputMaybe<Array<SaleCycleCreateManyClose_SiteInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SaleCycleCreateManyClose_TeamInput = {
  close?: InputMaybe<Scalars['Int']['input']>;
  close_site_id?: InputMaybe<Scalars['String']['input']>;
  close_user_id?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  cycle_num: Scalars['Int']['input'];
  days_set_out?: InputMaybe<Scalars['Float']['input']>;
  hold?: InputMaybe<Scalars['Int']['input']>;
  lead_id: Scalars['String']['input'];
  live_transfer?: InputMaybe<Scalars['Int']['input']>;
  num_dials?: InputMaybe<Scalars['Int']['input']>;
  organization_id?: InputMaybe<Scalars['String']['input']>;
  sdr_transfer?: InputMaybe<Scalars['Int']['input']>;
  set?: InputMaybe<Scalars['Int']['input']>;
  set_site_id?: InputMaybe<Scalars['String']['input']>;
  set_team_id?: InputMaybe<Scalars['String']['input']>;
  set_user_id?: InputMaybe<Scalars['String']['input']>;
  sf_opportunity_id?: InputMaybe<Scalars['String']['input']>;
  time_of_first_contact?: InputMaybe<Scalars['DateTime']['input']>;
  time_of_first_hold?: InputMaybe<Scalars['DateTime']['input']>;
  time_of_first_sale?: InputMaybe<Scalars['DateTime']['input']>;
  time_of_first_set?: InputMaybe<Scalars['DateTime']['input']>;
  transfer_site_id?: InputMaybe<Scalars['String']['input']>;
  transfer_team_id?: InputMaybe<Scalars['String']['input']>;
  transfer_user_id?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type SaleCycleCreateManyClose_TeamInputEnvelope = {
  data?: InputMaybe<Array<SaleCycleCreateManyClose_TeamInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SaleCycleCreateManyClose_UserInput = {
  close?: InputMaybe<Scalars['Int']['input']>;
  close_site_id?: InputMaybe<Scalars['String']['input']>;
  close_team_id?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  cycle_num: Scalars['Int']['input'];
  days_set_out?: InputMaybe<Scalars['Float']['input']>;
  hold?: InputMaybe<Scalars['Int']['input']>;
  lead_id: Scalars['String']['input'];
  live_transfer?: InputMaybe<Scalars['Int']['input']>;
  num_dials?: InputMaybe<Scalars['Int']['input']>;
  organization_id?: InputMaybe<Scalars['String']['input']>;
  sdr_transfer?: InputMaybe<Scalars['Int']['input']>;
  set?: InputMaybe<Scalars['Int']['input']>;
  set_site_id?: InputMaybe<Scalars['String']['input']>;
  set_team_id?: InputMaybe<Scalars['String']['input']>;
  set_user_id?: InputMaybe<Scalars['String']['input']>;
  sf_opportunity_id?: InputMaybe<Scalars['String']['input']>;
  time_of_first_contact?: InputMaybe<Scalars['DateTime']['input']>;
  time_of_first_hold?: InputMaybe<Scalars['DateTime']['input']>;
  time_of_first_sale?: InputMaybe<Scalars['DateTime']['input']>;
  time_of_first_set?: InputMaybe<Scalars['DateTime']['input']>;
  transfer_site_id?: InputMaybe<Scalars['String']['input']>;
  transfer_team_id?: InputMaybe<Scalars['String']['input']>;
  transfer_user_id?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type SaleCycleCreateManyClose_UserInputEnvelope = {
  data?: InputMaybe<Array<SaleCycleCreateManyClose_UserInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SaleCycleCreateManyLeadInput = {
  close?: InputMaybe<Scalars['Int']['input']>;
  close_site_id?: InputMaybe<Scalars['String']['input']>;
  close_team_id?: InputMaybe<Scalars['String']['input']>;
  close_user_id?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  cycle_num: Scalars['Int']['input'];
  days_set_out?: InputMaybe<Scalars['Float']['input']>;
  hold?: InputMaybe<Scalars['Int']['input']>;
  live_transfer?: InputMaybe<Scalars['Int']['input']>;
  num_dials?: InputMaybe<Scalars['Int']['input']>;
  organization_id?: InputMaybe<Scalars['String']['input']>;
  sdr_transfer?: InputMaybe<Scalars['Int']['input']>;
  set?: InputMaybe<Scalars['Int']['input']>;
  set_site_id?: InputMaybe<Scalars['String']['input']>;
  set_team_id?: InputMaybe<Scalars['String']['input']>;
  set_user_id?: InputMaybe<Scalars['String']['input']>;
  sf_opportunity_id?: InputMaybe<Scalars['String']['input']>;
  time_of_first_contact?: InputMaybe<Scalars['DateTime']['input']>;
  time_of_first_hold?: InputMaybe<Scalars['DateTime']['input']>;
  time_of_first_sale?: InputMaybe<Scalars['DateTime']['input']>;
  time_of_first_set?: InputMaybe<Scalars['DateTime']['input']>;
  transfer_site_id?: InputMaybe<Scalars['String']['input']>;
  transfer_team_id?: InputMaybe<Scalars['String']['input']>;
  transfer_user_id?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type SaleCycleCreateManyLeadInputEnvelope = {
  data?: InputMaybe<Array<SaleCycleCreateManyLeadInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SaleCycleCreateManyOrganizationInput = {
  close?: InputMaybe<Scalars['Int']['input']>;
  close_site_id?: InputMaybe<Scalars['String']['input']>;
  close_team_id?: InputMaybe<Scalars['String']['input']>;
  close_user_id?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  cycle_num: Scalars['Int']['input'];
  days_set_out?: InputMaybe<Scalars['Float']['input']>;
  hold?: InputMaybe<Scalars['Int']['input']>;
  lead_id: Scalars['String']['input'];
  live_transfer?: InputMaybe<Scalars['Int']['input']>;
  num_dials?: InputMaybe<Scalars['Int']['input']>;
  sdr_transfer?: InputMaybe<Scalars['Int']['input']>;
  set?: InputMaybe<Scalars['Int']['input']>;
  set_site_id?: InputMaybe<Scalars['String']['input']>;
  set_team_id?: InputMaybe<Scalars['String']['input']>;
  set_user_id?: InputMaybe<Scalars['String']['input']>;
  sf_opportunity_id?: InputMaybe<Scalars['String']['input']>;
  time_of_first_contact?: InputMaybe<Scalars['DateTime']['input']>;
  time_of_first_hold?: InputMaybe<Scalars['DateTime']['input']>;
  time_of_first_sale?: InputMaybe<Scalars['DateTime']['input']>;
  time_of_first_set?: InputMaybe<Scalars['DateTime']['input']>;
  transfer_site_id?: InputMaybe<Scalars['String']['input']>;
  transfer_team_id?: InputMaybe<Scalars['String']['input']>;
  transfer_user_id?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type SaleCycleCreateManyOrganizationInputEnvelope = {
  data?: InputMaybe<Array<SaleCycleCreateManyOrganizationInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SaleCycleCreateManySet_SiteInput = {
  close?: InputMaybe<Scalars['Int']['input']>;
  close_site_id?: InputMaybe<Scalars['String']['input']>;
  close_team_id?: InputMaybe<Scalars['String']['input']>;
  close_user_id?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  cycle_num: Scalars['Int']['input'];
  days_set_out?: InputMaybe<Scalars['Float']['input']>;
  hold?: InputMaybe<Scalars['Int']['input']>;
  lead_id: Scalars['String']['input'];
  live_transfer?: InputMaybe<Scalars['Int']['input']>;
  num_dials?: InputMaybe<Scalars['Int']['input']>;
  organization_id?: InputMaybe<Scalars['String']['input']>;
  sdr_transfer?: InputMaybe<Scalars['Int']['input']>;
  set?: InputMaybe<Scalars['Int']['input']>;
  set_team_id?: InputMaybe<Scalars['String']['input']>;
  set_user_id?: InputMaybe<Scalars['String']['input']>;
  sf_opportunity_id?: InputMaybe<Scalars['String']['input']>;
  time_of_first_contact?: InputMaybe<Scalars['DateTime']['input']>;
  time_of_first_hold?: InputMaybe<Scalars['DateTime']['input']>;
  time_of_first_sale?: InputMaybe<Scalars['DateTime']['input']>;
  time_of_first_set?: InputMaybe<Scalars['DateTime']['input']>;
  transfer_site_id?: InputMaybe<Scalars['String']['input']>;
  transfer_team_id?: InputMaybe<Scalars['String']['input']>;
  transfer_user_id?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type SaleCycleCreateManySet_SiteInputEnvelope = {
  data?: InputMaybe<Array<SaleCycleCreateManySet_SiteInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SaleCycleCreateManySet_TeamInput = {
  close?: InputMaybe<Scalars['Int']['input']>;
  close_site_id?: InputMaybe<Scalars['String']['input']>;
  close_team_id?: InputMaybe<Scalars['String']['input']>;
  close_user_id?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  cycle_num: Scalars['Int']['input'];
  days_set_out?: InputMaybe<Scalars['Float']['input']>;
  hold?: InputMaybe<Scalars['Int']['input']>;
  lead_id: Scalars['String']['input'];
  live_transfer?: InputMaybe<Scalars['Int']['input']>;
  num_dials?: InputMaybe<Scalars['Int']['input']>;
  organization_id?: InputMaybe<Scalars['String']['input']>;
  sdr_transfer?: InputMaybe<Scalars['Int']['input']>;
  set?: InputMaybe<Scalars['Int']['input']>;
  set_site_id?: InputMaybe<Scalars['String']['input']>;
  set_user_id?: InputMaybe<Scalars['String']['input']>;
  sf_opportunity_id?: InputMaybe<Scalars['String']['input']>;
  time_of_first_contact?: InputMaybe<Scalars['DateTime']['input']>;
  time_of_first_hold?: InputMaybe<Scalars['DateTime']['input']>;
  time_of_first_sale?: InputMaybe<Scalars['DateTime']['input']>;
  time_of_first_set?: InputMaybe<Scalars['DateTime']['input']>;
  transfer_site_id?: InputMaybe<Scalars['String']['input']>;
  transfer_team_id?: InputMaybe<Scalars['String']['input']>;
  transfer_user_id?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type SaleCycleCreateManySet_TeamInputEnvelope = {
  data?: InputMaybe<Array<SaleCycleCreateManySet_TeamInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SaleCycleCreateManySet_UserInput = {
  close?: InputMaybe<Scalars['Int']['input']>;
  close_site_id?: InputMaybe<Scalars['String']['input']>;
  close_team_id?: InputMaybe<Scalars['String']['input']>;
  close_user_id?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  cycle_num: Scalars['Int']['input'];
  days_set_out?: InputMaybe<Scalars['Float']['input']>;
  hold?: InputMaybe<Scalars['Int']['input']>;
  lead_id: Scalars['String']['input'];
  live_transfer?: InputMaybe<Scalars['Int']['input']>;
  num_dials?: InputMaybe<Scalars['Int']['input']>;
  organization_id?: InputMaybe<Scalars['String']['input']>;
  sdr_transfer?: InputMaybe<Scalars['Int']['input']>;
  set?: InputMaybe<Scalars['Int']['input']>;
  set_site_id?: InputMaybe<Scalars['String']['input']>;
  set_team_id?: InputMaybe<Scalars['String']['input']>;
  sf_opportunity_id?: InputMaybe<Scalars['String']['input']>;
  time_of_first_contact?: InputMaybe<Scalars['DateTime']['input']>;
  time_of_first_hold?: InputMaybe<Scalars['DateTime']['input']>;
  time_of_first_sale?: InputMaybe<Scalars['DateTime']['input']>;
  time_of_first_set?: InputMaybe<Scalars['DateTime']['input']>;
  transfer_site_id?: InputMaybe<Scalars['String']['input']>;
  transfer_team_id?: InputMaybe<Scalars['String']['input']>;
  transfer_user_id?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type SaleCycleCreateManySet_UserInputEnvelope = {
  data?: InputMaybe<Array<SaleCycleCreateManySet_UserInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SaleCycleCreateManyTransfer_SiteInput = {
  close?: InputMaybe<Scalars['Int']['input']>;
  close_site_id?: InputMaybe<Scalars['String']['input']>;
  close_team_id?: InputMaybe<Scalars['String']['input']>;
  close_user_id?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  cycle_num: Scalars['Int']['input'];
  days_set_out?: InputMaybe<Scalars['Float']['input']>;
  hold?: InputMaybe<Scalars['Int']['input']>;
  lead_id: Scalars['String']['input'];
  live_transfer?: InputMaybe<Scalars['Int']['input']>;
  num_dials?: InputMaybe<Scalars['Int']['input']>;
  organization_id?: InputMaybe<Scalars['String']['input']>;
  sdr_transfer?: InputMaybe<Scalars['Int']['input']>;
  set?: InputMaybe<Scalars['Int']['input']>;
  set_site_id?: InputMaybe<Scalars['String']['input']>;
  set_team_id?: InputMaybe<Scalars['String']['input']>;
  set_user_id?: InputMaybe<Scalars['String']['input']>;
  sf_opportunity_id?: InputMaybe<Scalars['String']['input']>;
  time_of_first_contact?: InputMaybe<Scalars['DateTime']['input']>;
  time_of_first_hold?: InputMaybe<Scalars['DateTime']['input']>;
  time_of_first_sale?: InputMaybe<Scalars['DateTime']['input']>;
  time_of_first_set?: InputMaybe<Scalars['DateTime']['input']>;
  transfer_team_id?: InputMaybe<Scalars['String']['input']>;
  transfer_user_id?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type SaleCycleCreateManyTransfer_SiteInputEnvelope = {
  data?: InputMaybe<Array<SaleCycleCreateManyTransfer_SiteInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SaleCycleCreateManyTransfer_TeamInput = {
  close?: InputMaybe<Scalars['Int']['input']>;
  close_site_id?: InputMaybe<Scalars['String']['input']>;
  close_team_id?: InputMaybe<Scalars['String']['input']>;
  close_user_id?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  cycle_num: Scalars['Int']['input'];
  days_set_out?: InputMaybe<Scalars['Float']['input']>;
  hold?: InputMaybe<Scalars['Int']['input']>;
  lead_id: Scalars['String']['input'];
  live_transfer?: InputMaybe<Scalars['Int']['input']>;
  num_dials?: InputMaybe<Scalars['Int']['input']>;
  organization_id?: InputMaybe<Scalars['String']['input']>;
  sdr_transfer?: InputMaybe<Scalars['Int']['input']>;
  set?: InputMaybe<Scalars['Int']['input']>;
  set_site_id?: InputMaybe<Scalars['String']['input']>;
  set_team_id?: InputMaybe<Scalars['String']['input']>;
  set_user_id?: InputMaybe<Scalars['String']['input']>;
  sf_opportunity_id?: InputMaybe<Scalars['String']['input']>;
  time_of_first_contact?: InputMaybe<Scalars['DateTime']['input']>;
  time_of_first_hold?: InputMaybe<Scalars['DateTime']['input']>;
  time_of_first_sale?: InputMaybe<Scalars['DateTime']['input']>;
  time_of_first_set?: InputMaybe<Scalars['DateTime']['input']>;
  transfer_site_id?: InputMaybe<Scalars['String']['input']>;
  transfer_user_id?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type SaleCycleCreateManyTransfer_TeamInputEnvelope = {
  data?: InputMaybe<Array<SaleCycleCreateManyTransfer_TeamInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SaleCycleCreateManyTransfer_UserInput = {
  close?: InputMaybe<Scalars['Int']['input']>;
  close_site_id?: InputMaybe<Scalars['String']['input']>;
  close_team_id?: InputMaybe<Scalars['String']['input']>;
  close_user_id?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  cycle_num: Scalars['Int']['input'];
  days_set_out?: InputMaybe<Scalars['Float']['input']>;
  hold?: InputMaybe<Scalars['Int']['input']>;
  lead_id: Scalars['String']['input'];
  live_transfer?: InputMaybe<Scalars['Int']['input']>;
  num_dials?: InputMaybe<Scalars['Int']['input']>;
  organization_id?: InputMaybe<Scalars['String']['input']>;
  sdr_transfer?: InputMaybe<Scalars['Int']['input']>;
  set?: InputMaybe<Scalars['Int']['input']>;
  set_site_id?: InputMaybe<Scalars['String']['input']>;
  set_team_id?: InputMaybe<Scalars['String']['input']>;
  set_user_id?: InputMaybe<Scalars['String']['input']>;
  sf_opportunity_id?: InputMaybe<Scalars['String']['input']>;
  time_of_first_contact?: InputMaybe<Scalars['DateTime']['input']>;
  time_of_first_hold?: InputMaybe<Scalars['DateTime']['input']>;
  time_of_first_sale?: InputMaybe<Scalars['DateTime']['input']>;
  time_of_first_set?: InputMaybe<Scalars['DateTime']['input']>;
  transfer_site_id?: InputMaybe<Scalars['String']['input']>;
  transfer_team_id?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type SaleCycleCreateManyTransfer_UserInputEnvelope = {
  data?: InputMaybe<Array<SaleCycleCreateManyTransfer_UserInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SaleCycleCreateNestedManyWithoutClose_SiteInput = {
  connect?: InputMaybe<Array<SaleCycleWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SaleCycleCreateOrConnectWithoutClose_SiteInput>>;
  create?: InputMaybe<Array<SaleCycleCreateWithoutClose_SiteInput>>;
  createMany?: InputMaybe<SaleCycleCreateManyClose_SiteInputEnvelope>;
};

export type SaleCycleCreateNestedManyWithoutClose_TeamInput = {
  connect?: InputMaybe<Array<SaleCycleWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SaleCycleCreateOrConnectWithoutClose_TeamInput>>;
  create?: InputMaybe<Array<SaleCycleCreateWithoutClose_TeamInput>>;
  createMany?: InputMaybe<SaleCycleCreateManyClose_TeamInputEnvelope>;
};

export type SaleCycleCreateNestedManyWithoutClose_UserInput = {
  connect?: InputMaybe<Array<SaleCycleWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SaleCycleCreateOrConnectWithoutClose_UserInput>>;
  create?: InputMaybe<Array<SaleCycleCreateWithoutClose_UserInput>>;
  createMany?: InputMaybe<SaleCycleCreateManyClose_UserInputEnvelope>;
};

export type SaleCycleCreateNestedManyWithoutLeadInput = {
  connect?: InputMaybe<Array<SaleCycleWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SaleCycleCreateOrConnectWithoutLeadInput>>;
  create?: InputMaybe<Array<SaleCycleCreateWithoutLeadInput>>;
  createMany?: InputMaybe<SaleCycleCreateManyLeadInputEnvelope>;
};

export type SaleCycleCreateNestedManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<SaleCycleWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SaleCycleCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<SaleCycleCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<SaleCycleCreateManyOrganizationInputEnvelope>;
};

export type SaleCycleCreateNestedManyWithoutSet_SiteInput = {
  connect?: InputMaybe<Array<SaleCycleWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SaleCycleCreateOrConnectWithoutSet_SiteInput>>;
  create?: InputMaybe<Array<SaleCycleCreateWithoutSet_SiteInput>>;
  createMany?: InputMaybe<SaleCycleCreateManySet_SiteInputEnvelope>;
};

export type SaleCycleCreateNestedManyWithoutSet_TeamInput = {
  connect?: InputMaybe<Array<SaleCycleWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SaleCycleCreateOrConnectWithoutSet_TeamInput>>;
  create?: InputMaybe<Array<SaleCycleCreateWithoutSet_TeamInput>>;
  createMany?: InputMaybe<SaleCycleCreateManySet_TeamInputEnvelope>;
};

export type SaleCycleCreateNestedManyWithoutSet_UserInput = {
  connect?: InputMaybe<Array<SaleCycleWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SaleCycleCreateOrConnectWithoutSet_UserInput>>;
  create?: InputMaybe<Array<SaleCycleCreateWithoutSet_UserInput>>;
  createMany?: InputMaybe<SaleCycleCreateManySet_UserInputEnvelope>;
};

export type SaleCycleCreateNestedManyWithoutTransfer_SiteInput = {
  connect?: InputMaybe<Array<SaleCycleWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SaleCycleCreateOrConnectWithoutTransfer_SiteInput>>;
  create?: InputMaybe<Array<SaleCycleCreateWithoutTransfer_SiteInput>>;
  createMany?: InputMaybe<SaleCycleCreateManyTransfer_SiteInputEnvelope>;
};

export type SaleCycleCreateNestedManyWithoutTransfer_TeamInput = {
  connect?: InputMaybe<Array<SaleCycleWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SaleCycleCreateOrConnectWithoutTransfer_TeamInput>>;
  create?: InputMaybe<Array<SaleCycleCreateWithoutTransfer_TeamInput>>;
  createMany?: InputMaybe<SaleCycleCreateManyTransfer_TeamInputEnvelope>;
};

export type SaleCycleCreateNestedManyWithoutTransfer_UserInput = {
  connect?: InputMaybe<Array<SaleCycleWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SaleCycleCreateOrConnectWithoutTransfer_UserInput>>;
  create?: InputMaybe<Array<SaleCycleCreateWithoutTransfer_UserInput>>;
  createMany?: InputMaybe<SaleCycleCreateManyTransfer_UserInputEnvelope>;
};

export type SaleCycleCreateNestedOneWithoutLead_ActivitiesInput = {
  connect?: InputMaybe<SaleCycleWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SaleCycleCreateOrConnectWithoutLead_ActivitiesInput>;
  create?: InputMaybe<SaleCycleCreateWithoutLead_ActivitiesInput>;
};

export type SaleCycleCreateNestedOneWithoutLead_If_CurrentInput = {
  connect?: InputMaybe<SaleCycleWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SaleCycleCreateOrConnectWithoutLead_If_CurrentInput>;
  create?: InputMaybe<SaleCycleCreateWithoutLead_If_CurrentInput>;
};

export type SaleCycleCreateOrConnectWithoutClose_SiteInput = {
  create: SaleCycleCreateWithoutClose_SiteInput;
  where: SaleCycleWhereUniqueInput;
};

export type SaleCycleCreateOrConnectWithoutClose_TeamInput = {
  create: SaleCycleCreateWithoutClose_TeamInput;
  where: SaleCycleWhereUniqueInput;
};

export type SaleCycleCreateOrConnectWithoutClose_UserInput = {
  create: SaleCycleCreateWithoutClose_UserInput;
  where: SaleCycleWhereUniqueInput;
};

export type SaleCycleCreateOrConnectWithoutLead_ActivitiesInput = {
  create: SaleCycleCreateWithoutLead_ActivitiesInput;
  where: SaleCycleWhereUniqueInput;
};

export type SaleCycleCreateOrConnectWithoutLead_If_CurrentInput = {
  create: SaleCycleCreateWithoutLead_If_CurrentInput;
  where: SaleCycleWhereUniqueInput;
};

export type SaleCycleCreateOrConnectWithoutLeadInput = {
  create: SaleCycleCreateWithoutLeadInput;
  where: SaleCycleWhereUniqueInput;
};

export type SaleCycleCreateOrConnectWithoutOrganizationInput = {
  create: SaleCycleCreateWithoutOrganizationInput;
  where: SaleCycleWhereUniqueInput;
};

export type SaleCycleCreateOrConnectWithoutSet_SiteInput = {
  create: SaleCycleCreateWithoutSet_SiteInput;
  where: SaleCycleWhereUniqueInput;
};

export type SaleCycleCreateOrConnectWithoutSet_TeamInput = {
  create: SaleCycleCreateWithoutSet_TeamInput;
  where: SaleCycleWhereUniqueInput;
};

export type SaleCycleCreateOrConnectWithoutSet_UserInput = {
  create: SaleCycleCreateWithoutSet_UserInput;
  where: SaleCycleWhereUniqueInput;
};

export type SaleCycleCreateOrConnectWithoutTransfer_SiteInput = {
  create: SaleCycleCreateWithoutTransfer_SiteInput;
  where: SaleCycleWhereUniqueInput;
};

export type SaleCycleCreateOrConnectWithoutTransfer_TeamInput = {
  create: SaleCycleCreateWithoutTransfer_TeamInput;
  where: SaleCycleWhereUniqueInput;
};

export type SaleCycleCreateOrConnectWithoutTransfer_UserInput = {
  create: SaleCycleCreateWithoutTransfer_UserInput;
  where: SaleCycleWhereUniqueInput;
};

export type SaleCycleCreateWithoutClose_SiteInput = {
  close?: InputMaybe<Scalars['Int']['input']>;
  close_team?: InputMaybe<TeamCreateNestedOneWithoutClose_Team_CycleInput>;
  close_user?: InputMaybe<UserCreateNestedOneWithoutClose_User_CycleInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  cycle_num: Scalars['Int']['input'];
  days_set_out?: InputMaybe<Scalars['Float']['input']>;
  hold?: InputMaybe<Scalars['Int']['input']>;
  lead: LeadCreateNestedOneWithoutSale_CyclesInput;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutSale_CycleInput>;
  lead_if_current?: InputMaybe<LeadCreateNestedOneWithoutCurrent_Sale_CycleInput>;
  live_transfer?: InputMaybe<Scalars['Int']['input']>;
  num_dials?: InputMaybe<Scalars['Int']['input']>;
  Organization?: InputMaybe<OrganizationCreateNestedOneWithoutSaleCyclesInput>;
  sdr_transfer?: InputMaybe<Scalars['Int']['input']>;
  set?: InputMaybe<Scalars['Int']['input']>;
  set_site?: InputMaybe<SiteCreateNestedOneWithoutSet_Site_CycleInput>;
  set_team?: InputMaybe<TeamCreateNestedOneWithoutSet_Team_CycleInput>;
  set_user?: InputMaybe<UserCreateNestedOneWithoutSet_User_CycleInput>;
  sf_opportunity_id?: InputMaybe<Scalars['String']['input']>;
  time_of_first_contact?: InputMaybe<Scalars['DateTime']['input']>;
  time_of_first_hold?: InputMaybe<Scalars['DateTime']['input']>;
  time_of_first_sale?: InputMaybe<Scalars['DateTime']['input']>;
  time_of_first_set?: InputMaybe<Scalars['DateTime']['input']>;
  transfer_site?: InputMaybe<SiteCreateNestedOneWithoutTransfer_Site_CycleInput>;
  transfer_team?: InputMaybe<TeamCreateNestedOneWithoutTransfer_Team_CycleInput>;
  transfer_user?: InputMaybe<UserCreateNestedOneWithoutTransfer_User_CycleInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type SaleCycleCreateWithoutClose_TeamInput = {
  close?: InputMaybe<Scalars['Int']['input']>;
  close_site?: InputMaybe<SiteCreateNestedOneWithoutClose_Site_CycleInput>;
  close_user?: InputMaybe<UserCreateNestedOneWithoutClose_User_CycleInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  cycle_num: Scalars['Int']['input'];
  days_set_out?: InputMaybe<Scalars['Float']['input']>;
  hold?: InputMaybe<Scalars['Int']['input']>;
  lead: LeadCreateNestedOneWithoutSale_CyclesInput;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutSale_CycleInput>;
  lead_if_current?: InputMaybe<LeadCreateNestedOneWithoutCurrent_Sale_CycleInput>;
  live_transfer?: InputMaybe<Scalars['Int']['input']>;
  num_dials?: InputMaybe<Scalars['Int']['input']>;
  Organization?: InputMaybe<OrganizationCreateNestedOneWithoutSaleCyclesInput>;
  sdr_transfer?: InputMaybe<Scalars['Int']['input']>;
  set?: InputMaybe<Scalars['Int']['input']>;
  set_site?: InputMaybe<SiteCreateNestedOneWithoutSet_Site_CycleInput>;
  set_team?: InputMaybe<TeamCreateNestedOneWithoutSet_Team_CycleInput>;
  set_user?: InputMaybe<UserCreateNestedOneWithoutSet_User_CycleInput>;
  sf_opportunity_id?: InputMaybe<Scalars['String']['input']>;
  time_of_first_contact?: InputMaybe<Scalars['DateTime']['input']>;
  time_of_first_hold?: InputMaybe<Scalars['DateTime']['input']>;
  time_of_first_sale?: InputMaybe<Scalars['DateTime']['input']>;
  time_of_first_set?: InputMaybe<Scalars['DateTime']['input']>;
  transfer_site?: InputMaybe<SiteCreateNestedOneWithoutTransfer_Site_CycleInput>;
  transfer_team?: InputMaybe<TeamCreateNestedOneWithoutTransfer_Team_CycleInput>;
  transfer_user?: InputMaybe<UserCreateNestedOneWithoutTransfer_User_CycleInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type SaleCycleCreateWithoutClose_UserInput = {
  close?: InputMaybe<Scalars['Int']['input']>;
  close_site?: InputMaybe<SiteCreateNestedOneWithoutClose_Site_CycleInput>;
  close_team?: InputMaybe<TeamCreateNestedOneWithoutClose_Team_CycleInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  cycle_num: Scalars['Int']['input'];
  days_set_out?: InputMaybe<Scalars['Float']['input']>;
  hold?: InputMaybe<Scalars['Int']['input']>;
  lead: LeadCreateNestedOneWithoutSale_CyclesInput;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutSale_CycleInput>;
  lead_if_current?: InputMaybe<LeadCreateNestedOneWithoutCurrent_Sale_CycleInput>;
  live_transfer?: InputMaybe<Scalars['Int']['input']>;
  num_dials?: InputMaybe<Scalars['Int']['input']>;
  Organization?: InputMaybe<OrganizationCreateNestedOneWithoutSaleCyclesInput>;
  sdr_transfer?: InputMaybe<Scalars['Int']['input']>;
  set?: InputMaybe<Scalars['Int']['input']>;
  set_site?: InputMaybe<SiteCreateNestedOneWithoutSet_Site_CycleInput>;
  set_team?: InputMaybe<TeamCreateNestedOneWithoutSet_Team_CycleInput>;
  set_user?: InputMaybe<UserCreateNestedOneWithoutSet_User_CycleInput>;
  sf_opportunity_id?: InputMaybe<Scalars['String']['input']>;
  time_of_first_contact?: InputMaybe<Scalars['DateTime']['input']>;
  time_of_first_hold?: InputMaybe<Scalars['DateTime']['input']>;
  time_of_first_sale?: InputMaybe<Scalars['DateTime']['input']>;
  time_of_first_set?: InputMaybe<Scalars['DateTime']['input']>;
  transfer_site?: InputMaybe<SiteCreateNestedOneWithoutTransfer_Site_CycleInput>;
  transfer_team?: InputMaybe<TeamCreateNestedOneWithoutTransfer_Team_CycleInput>;
  transfer_user?: InputMaybe<UserCreateNestedOneWithoutTransfer_User_CycleInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type SaleCycleCreateWithoutLead_ActivitiesInput = {
  close?: InputMaybe<Scalars['Int']['input']>;
  close_site?: InputMaybe<SiteCreateNestedOneWithoutClose_Site_CycleInput>;
  close_team?: InputMaybe<TeamCreateNestedOneWithoutClose_Team_CycleInput>;
  close_user?: InputMaybe<UserCreateNestedOneWithoutClose_User_CycleInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  cycle_num: Scalars['Int']['input'];
  days_set_out?: InputMaybe<Scalars['Float']['input']>;
  hold?: InputMaybe<Scalars['Int']['input']>;
  lead: LeadCreateNestedOneWithoutSale_CyclesInput;
  lead_if_current?: InputMaybe<LeadCreateNestedOneWithoutCurrent_Sale_CycleInput>;
  live_transfer?: InputMaybe<Scalars['Int']['input']>;
  num_dials?: InputMaybe<Scalars['Int']['input']>;
  Organization?: InputMaybe<OrganizationCreateNestedOneWithoutSaleCyclesInput>;
  sdr_transfer?: InputMaybe<Scalars['Int']['input']>;
  set?: InputMaybe<Scalars['Int']['input']>;
  set_site?: InputMaybe<SiteCreateNestedOneWithoutSet_Site_CycleInput>;
  set_team?: InputMaybe<TeamCreateNestedOneWithoutSet_Team_CycleInput>;
  set_user?: InputMaybe<UserCreateNestedOneWithoutSet_User_CycleInput>;
  sf_opportunity_id?: InputMaybe<Scalars['String']['input']>;
  time_of_first_contact?: InputMaybe<Scalars['DateTime']['input']>;
  time_of_first_hold?: InputMaybe<Scalars['DateTime']['input']>;
  time_of_first_sale?: InputMaybe<Scalars['DateTime']['input']>;
  time_of_first_set?: InputMaybe<Scalars['DateTime']['input']>;
  transfer_site?: InputMaybe<SiteCreateNestedOneWithoutTransfer_Site_CycleInput>;
  transfer_team?: InputMaybe<TeamCreateNestedOneWithoutTransfer_Team_CycleInput>;
  transfer_user?: InputMaybe<UserCreateNestedOneWithoutTransfer_User_CycleInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type SaleCycleCreateWithoutLead_If_CurrentInput = {
  close?: InputMaybe<Scalars['Int']['input']>;
  close_site?: InputMaybe<SiteCreateNestedOneWithoutClose_Site_CycleInput>;
  close_team?: InputMaybe<TeamCreateNestedOneWithoutClose_Team_CycleInput>;
  close_user?: InputMaybe<UserCreateNestedOneWithoutClose_User_CycleInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  cycle_num: Scalars['Int']['input'];
  days_set_out?: InputMaybe<Scalars['Float']['input']>;
  hold?: InputMaybe<Scalars['Int']['input']>;
  lead: LeadCreateNestedOneWithoutSale_CyclesInput;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutSale_CycleInput>;
  live_transfer?: InputMaybe<Scalars['Int']['input']>;
  num_dials?: InputMaybe<Scalars['Int']['input']>;
  Organization?: InputMaybe<OrganizationCreateNestedOneWithoutSaleCyclesInput>;
  sdr_transfer?: InputMaybe<Scalars['Int']['input']>;
  set?: InputMaybe<Scalars['Int']['input']>;
  set_site?: InputMaybe<SiteCreateNestedOneWithoutSet_Site_CycleInput>;
  set_team?: InputMaybe<TeamCreateNestedOneWithoutSet_Team_CycleInput>;
  set_user?: InputMaybe<UserCreateNestedOneWithoutSet_User_CycleInput>;
  sf_opportunity_id?: InputMaybe<Scalars['String']['input']>;
  time_of_first_contact?: InputMaybe<Scalars['DateTime']['input']>;
  time_of_first_hold?: InputMaybe<Scalars['DateTime']['input']>;
  time_of_first_sale?: InputMaybe<Scalars['DateTime']['input']>;
  time_of_first_set?: InputMaybe<Scalars['DateTime']['input']>;
  transfer_site?: InputMaybe<SiteCreateNestedOneWithoutTransfer_Site_CycleInput>;
  transfer_team?: InputMaybe<TeamCreateNestedOneWithoutTransfer_Team_CycleInput>;
  transfer_user?: InputMaybe<UserCreateNestedOneWithoutTransfer_User_CycleInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type SaleCycleCreateWithoutLeadInput = {
  close?: InputMaybe<Scalars['Int']['input']>;
  close_site?: InputMaybe<SiteCreateNestedOneWithoutClose_Site_CycleInput>;
  close_team?: InputMaybe<TeamCreateNestedOneWithoutClose_Team_CycleInput>;
  close_user?: InputMaybe<UserCreateNestedOneWithoutClose_User_CycleInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  cycle_num: Scalars['Int']['input'];
  days_set_out?: InputMaybe<Scalars['Float']['input']>;
  hold?: InputMaybe<Scalars['Int']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutSale_CycleInput>;
  lead_if_current?: InputMaybe<LeadCreateNestedOneWithoutCurrent_Sale_CycleInput>;
  live_transfer?: InputMaybe<Scalars['Int']['input']>;
  num_dials?: InputMaybe<Scalars['Int']['input']>;
  Organization?: InputMaybe<OrganizationCreateNestedOneWithoutSaleCyclesInput>;
  sdr_transfer?: InputMaybe<Scalars['Int']['input']>;
  set?: InputMaybe<Scalars['Int']['input']>;
  set_site?: InputMaybe<SiteCreateNestedOneWithoutSet_Site_CycleInput>;
  set_team?: InputMaybe<TeamCreateNestedOneWithoutSet_Team_CycleInput>;
  set_user?: InputMaybe<UserCreateNestedOneWithoutSet_User_CycleInput>;
  sf_opportunity_id?: InputMaybe<Scalars['String']['input']>;
  time_of_first_contact?: InputMaybe<Scalars['DateTime']['input']>;
  time_of_first_hold?: InputMaybe<Scalars['DateTime']['input']>;
  time_of_first_sale?: InputMaybe<Scalars['DateTime']['input']>;
  time_of_first_set?: InputMaybe<Scalars['DateTime']['input']>;
  transfer_site?: InputMaybe<SiteCreateNestedOneWithoutTransfer_Site_CycleInput>;
  transfer_team?: InputMaybe<TeamCreateNestedOneWithoutTransfer_Team_CycleInput>;
  transfer_user?: InputMaybe<UserCreateNestedOneWithoutTransfer_User_CycleInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type SaleCycleCreateWithoutOrganizationInput = {
  close?: InputMaybe<Scalars['Int']['input']>;
  close_site?: InputMaybe<SiteCreateNestedOneWithoutClose_Site_CycleInput>;
  close_team?: InputMaybe<TeamCreateNestedOneWithoutClose_Team_CycleInput>;
  close_user?: InputMaybe<UserCreateNestedOneWithoutClose_User_CycleInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  cycle_num: Scalars['Int']['input'];
  days_set_out?: InputMaybe<Scalars['Float']['input']>;
  hold?: InputMaybe<Scalars['Int']['input']>;
  lead: LeadCreateNestedOneWithoutSale_CyclesInput;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutSale_CycleInput>;
  lead_if_current?: InputMaybe<LeadCreateNestedOneWithoutCurrent_Sale_CycleInput>;
  live_transfer?: InputMaybe<Scalars['Int']['input']>;
  num_dials?: InputMaybe<Scalars['Int']['input']>;
  sdr_transfer?: InputMaybe<Scalars['Int']['input']>;
  set?: InputMaybe<Scalars['Int']['input']>;
  set_site?: InputMaybe<SiteCreateNestedOneWithoutSet_Site_CycleInput>;
  set_team?: InputMaybe<TeamCreateNestedOneWithoutSet_Team_CycleInput>;
  set_user?: InputMaybe<UserCreateNestedOneWithoutSet_User_CycleInput>;
  sf_opportunity_id?: InputMaybe<Scalars['String']['input']>;
  time_of_first_contact?: InputMaybe<Scalars['DateTime']['input']>;
  time_of_first_hold?: InputMaybe<Scalars['DateTime']['input']>;
  time_of_first_sale?: InputMaybe<Scalars['DateTime']['input']>;
  time_of_first_set?: InputMaybe<Scalars['DateTime']['input']>;
  transfer_site?: InputMaybe<SiteCreateNestedOneWithoutTransfer_Site_CycleInput>;
  transfer_team?: InputMaybe<TeamCreateNestedOneWithoutTransfer_Team_CycleInput>;
  transfer_user?: InputMaybe<UserCreateNestedOneWithoutTransfer_User_CycleInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type SaleCycleCreateWithoutSet_SiteInput = {
  close?: InputMaybe<Scalars['Int']['input']>;
  close_site?: InputMaybe<SiteCreateNestedOneWithoutClose_Site_CycleInput>;
  close_team?: InputMaybe<TeamCreateNestedOneWithoutClose_Team_CycleInput>;
  close_user?: InputMaybe<UserCreateNestedOneWithoutClose_User_CycleInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  cycle_num: Scalars['Int']['input'];
  days_set_out?: InputMaybe<Scalars['Float']['input']>;
  hold?: InputMaybe<Scalars['Int']['input']>;
  lead: LeadCreateNestedOneWithoutSale_CyclesInput;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutSale_CycleInput>;
  lead_if_current?: InputMaybe<LeadCreateNestedOneWithoutCurrent_Sale_CycleInput>;
  live_transfer?: InputMaybe<Scalars['Int']['input']>;
  num_dials?: InputMaybe<Scalars['Int']['input']>;
  Organization?: InputMaybe<OrganizationCreateNestedOneWithoutSaleCyclesInput>;
  sdr_transfer?: InputMaybe<Scalars['Int']['input']>;
  set?: InputMaybe<Scalars['Int']['input']>;
  set_team?: InputMaybe<TeamCreateNestedOneWithoutSet_Team_CycleInput>;
  set_user?: InputMaybe<UserCreateNestedOneWithoutSet_User_CycleInput>;
  sf_opportunity_id?: InputMaybe<Scalars['String']['input']>;
  time_of_first_contact?: InputMaybe<Scalars['DateTime']['input']>;
  time_of_first_hold?: InputMaybe<Scalars['DateTime']['input']>;
  time_of_first_sale?: InputMaybe<Scalars['DateTime']['input']>;
  time_of_first_set?: InputMaybe<Scalars['DateTime']['input']>;
  transfer_site?: InputMaybe<SiteCreateNestedOneWithoutTransfer_Site_CycleInput>;
  transfer_team?: InputMaybe<TeamCreateNestedOneWithoutTransfer_Team_CycleInput>;
  transfer_user?: InputMaybe<UserCreateNestedOneWithoutTransfer_User_CycleInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type SaleCycleCreateWithoutSet_TeamInput = {
  close?: InputMaybe<Scalars['Int']['input']>;
  close_site?: InputMaybe<SiteCreateNestedOneWithoutClose_Site_CycleInput>;
  close_team?: InputMaybe<TeamCreateNestedOneWithoutClose_Team_CycleInput>;
  close_user?: InputMaybe<UserCreateNestedOneWithoutClose_User_CycleInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  cycle_num: Scalars['Int']['input'];
  days_set_out?: InputMaybe<Scalars['Float']['input']>;
  hold?: InputMaybe<Scalars['Int']['input']>;
  lead: LeadCreateNestedOneWithoutSale_CyclesInput;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutSale_CycleInput>;
  lead_if_current?: InputMaybe<LeadCreateNestedOneWithoutCurrent_Sale_CycleInput>;
  live_transfer?: InputMaybe<Scalars['Int']['input']>;
  num_dials?: InputMaybe<Scalars['Int']['input']>;
  Organization?: InputMaybe<OrganizationCreateNestedOneWithoutSaleCyclesInput>;
  sdr_transfer?: InputMaybe<Scalars['Int']['input']>;
  set?: InputMaybe<Scalars['Int']['input']>;
  set_site?: InputMaybe<SiteCreateNestedOneWithoutSet_Site_CycleInput>;
  set_user?: InputMaybe<UserCreateNestedOneWithoutSet_User_CycleInput>;
  sf_opportunity_id?: InputMaybe<Scalars['String']['input']>;
  time_of_first_contact?: InputMaybe<Scalars['DateTime']['input']>;
  time_of_first_hold?: InputMaybe<Scalars['DateTime']['input']>;
  time_of_first_sale?: InputMaybe<Scalars['DateTime']['input']>;
  time_of_first_set?: InputMaybe<Scalars['DateTime']['input']>;
  transfer_site?: InputMaybe<SiteCreateNestedOneWithoutTransfer_Site_CycleInput>;
  transfer_team?: InputMaybe<TeamCreateNestedOneWithoutTransfer_Team_CycleInput>;
  transfer_user?: InputMaybe<UserCreateNestedOneWithoutTransfer_User_CycleInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type SaleCycleCreateWithoutSet_UserInput = {
  close?: InputMaybe<Scalars['Int']['input']>;
  close_site?: InputMaybe<SiteCreateNestedOneWithoutClose_Site_CycleInput>;
  close_team?: InputMaybe<TeamCreateNestedOneWithoutClose_Team_CycleInput>;
  close_user?: InputMaybe<UserCreateNestedOneWithoutClose_User_CycleInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  cycle_num: Scalars['Int']['input'];
  days_set_out?: InputMaybe<Scalars['Float']['input']>;
  hold?: InputMaybe<Scalars['Int']['input']>;
  lead: LeadCreateNestedOneWithoutSale_CyclesInput;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutSale_CycleInput>;
  lead_if_current?: InputMaybe<LeadCreateNestedOneWithoutCurrent_Sale_CycleInput>;
  live_transfer?: InputMaybe<Scalars['Int']['input']>;
  num_dials?: InputMaybe<Scalars['Int']['input']>;
  Organization?: InputMaybe<OrganizationCreateNestedOneWithoutSaleCyclesInput>;
  sdr_transfer?: InputMaybe<Scalars['Int']['input']>;
  set?: InputMaybe<Scalars['Int']['input']>;
  set_site?: InputMaybe<SiteCreateNestedOneWithoutSet_Site_CycleInput>;
  set_team?: InputMaybe<TeamCreateNestedOneWithoutSet_Team_CycleInput>;
  sf_opportunity_id?: InputMaybe<Scalars['String']['input']>;
  time_of_first_contact?: InputMaybe<Scalars['DateTime']['input']>;
  time_of_first_hold?: InputMaybe<Scalars['DateTime']['input']>;
  time_of_first_sale?: InputMaybe<Scalars['DateTime']['input']>;
  time_of_first_set?: InputMaybe<Scalars['DateTime']['input']>;
  transfer_site?: InputMaybe<SiteCreateNestedOneWithoutTransfer_Site_CycleInput>;
  transfer_team?: InputMaybe<TeamCreateNestedOneWithoutTransfer_Team_CycleInput>;
  transfer_user?: InputMaybe<UserCreateNestedOneWithoutTransfer_User_CycleInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type SaleCycleCreateWithoutTransfer_SiteInput = {
  close?: InputMaybe<Scalars['Int']['input']>;
  close_site?: InputMaybe<SiteCreateNestedOneWithoutClose_Site_CycleInput>;
  close_team?: InputMaybe<TeamCreateNestedOneWithoutClose_Team_CycleInput>;
  close_user?: InputMaybe<UserCreateNestedOneWithoutClose_User_CycleInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  cycle_num: Scalars['Int']['input'];
  days_set_out?: InputMaybe<Scalars['Float']['input']>;
  hold?: InputMaybe<Scalars['Int']['input']>;
  lead: LeadCreateNestedOneWithoutSale_CyclesInput;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutSale_CycleInput>;
  lead_if_current?: InputMaybe<LeadCreateNestedOneWithoutCurrent_Sale_CycleInput>;
  live_transfer?: InputMaybe<Scalars['Int']['input']>;
  num_dials?: InputMaybe<Scalars['Int']['input']>;
  Organization?: InputMaybe<OrganizationCreateNestedOneWithoutSaleCyclesInput>;
  sdr_transfer?: InputMaybe<Scalars['Int']['input']>;
  set?: InputMaybe<Scalars['Int']['input']>;
  set_site?: InputMaybe<SiteCreateNestedOneWithoutSet_Site_CycleInput>;
  set_team?: InputMaybe<TeamCreateNestedOneWithoutSet_Team_CycleInput>;
  set_user?: InputMaybe<UserCreateNestedOneWithoutSet_User_CycleInput>;
  sf_opportunity_id?: InputMaybe<Scalars['String']['input']>;
  time_of_first_contact?: InputMaybe<Scalars['DateTime']['input']>;
  time_of_first_hold?: InputMaybe<Scalars['DateTime']['input']>;
  time_of_first_sale?: InputMaybe<Scalars['DateTime']['input']>;
  time_of_first_set?: InputMaybe<Scalars['DateTime']['input']>;
  transfer_team?: InputMaybe<TeamCreateNestedOneWithoutTransfer_Team_CycleInput>;
  transfer_user?: InputMaybe<UserCreateNestedOneWithoutTransfer_User_CycleInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type SaleCycleCreateWithoutTransfer_TeamInput = {
  close?: InputMaybe<Scalars['Int']['input']>;
  close_site?: InputMaybe<SiteCreateNestedOneWithoutClose_Site_CycleInput>;
  close_team?: InputMaybe<TeamCreateNestedOneWithoutClose_Team_CycleInput>;
  close_user?: InputMaybe<UserCreateNestedOneWithoutClose_User_CycleInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  cycle_num: Scalars['Int']['input'];
  days_set_out?: InputMaybe<Scalars['Float']['input']>;
  hold?: InputMaybe<Scalars['Int']['input']>;
  lead: LeadCreateNestedOneWithoutSale_CyclesInput;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutSale_CycleInput>;
  lead_if_current?: InputMaybe<LeadCreateNestedOneWithoutCurrent_Sale_CycleInput>;
  live_transfer?: InputMaybe<Scalars['Int']['input']>;
  num_dials?: InputMaybe<Scalars['Int']['input']>;
  Organization?: InputMaybe<OrganizationCreateNestedOneWithoutSaleCyclesInput>;
  sdr_transfer?: InputMaybe<Scalars['Int']['input']>;
  set?: InputMaybe<Scalars['Int']['input']>;
  set_site?: InputMaybe<SiteCreateNestedOneWithoutSet_Site_CycleInput>;
  set_team?: InputMaybe<TeamCreateNestedOneWithoutSet_Team_CycleInput>;
  set_user?: InputMaybe<UserCreateNestedOneWithoutSet_User_CycleInput>;
  sf_opportunity_id?: InputMaybe<Scalars['String']['input']>;
  time_of_first_contact?: InputMaybe<Scalars['DateTime']['input']>;
  time_of_first_hold?: InputMaybe<Scalars['DateTime']['input']>;
  time_of_first_sale?: InputMaybe<Scalars['DateTime']['input']>;
  time_of_first_set?: InputMaybe<Scalars['DateTime']['input']>;
  transfer_site?: InputMaybe<SiteCreateNestedOneWithoutTransfer_Site_CycleInput>;
  transfer_user?: InputMaybe<UserCreateNestedOneWithoutTransfer_User_CycleInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type SaleCycleCreateWithoutTransfer_UserInput = {
  close?: InputMaybe<Scalars['Int']['input']>;
  close_site?: InputMaybe<SiteCreateNestedOneWithoutClose_Site_CycleInput>;
  close_team?: InputMaybe<TeamCreateNestedOneWithoutClose_Team_CycleInput>;
  close_user?: InputMaybe<UserCreateNestedOneWithoutClose_User_CycleInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  cycle_num: Scalars['Int']['input'];
  days_set_out?: InputMaybe<Scalars['Float']['input']>;
  hold?: InputMaybe<Scalars['Int']['input']>;
  lead: LeadCreateNestedOneWithoutSale_CyclesInput;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutSale_CycleInput>;
  lead_if_current?: InputMaybe<LeadCreateNestedOneWithoutCurrent_Sale_CycleInput>;
  live_transfer?: InputMaybe<Scalars['Int']['input']>;
  num_dials?: InputMaybe<Scalars['Int']['input']>;
  Organization?: InputMaybe<OrganizationCreateNestedOneWithoutSaleCyclesInput>;
  sdr_transfer?: InputMaybe<Scalars['Int']['input']>;
  set?: InputMaybe<Scalars['Int']['input']>;
  set_site?: InputMaybe<SiteCreateNestedOneWithoutSet_Site_CycleInput>;
  set_team?: InputMaybe<TeamCreateNestedOneWithoutSet_Team_CycleInput>;
  set_user?: InputMaybe<UserCreateNestedOneWithoutSet_User_CycleInput>;
  sf_opportunity_id?: InputMaybe<Scalars['String']['input']>;
  time_of_first_contact?: InputMaybe<Scalars['DateTime']['input']>;
  time_of_first_hold?: InputMaybe<Scalars['DateTime']['input']>;
  time_of_first_sale?: InputMaybe<Scalars['DateTime']['input']>;
  time_of_first_set?: InputMaybe<Scalars['DateTime']['input']>;
  transfer_site?: InputMaybe<SiteCreateNestedOneWithoutTransfer_Site_CycleInput>;
  transfer_team?: InputMaybe<TeamCreateNestedOneWithoutTransfer_Team_CycleInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type SaleCycleCycle_NumLead_IdCompoundUniqueInput = {
  cycle_num: Scalars['Int']['input'];
  lead_id: Scalars['String']['input'];
};

export type SaleCycleListRelationFilter = {
  every?: InputMaybe<SaleCycleWhereInput>;
  none?: InputMaybe<SaleCycleWhereInput>;
  some?: InputMaybe<SaleCycleWhereInput>;
};

export type SaleCycleOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
  count?: InputMaybe<SortOrder>;
};

export type SaleCycleOrderByWithRelationInput = {
  close?: InputMaybe<SortOrder>;
  close_site?: InputMaybe<SiteOrderByWithRelationInput>;
  close_site_id?: InputMaybe<SortOrder>;
  close_team?: InputMaybe<TeamOrderByWithRelationInput>;
  close_team_id?: InputMaybe<SortOrder>;
  close_user?: InputMaybe<UserOrderByWithRelationInput>;
  close_user_id?: InputMaybe<SortOrder>;
  created_at?: InputMaybe<SortOrder>;
  cycle_num?: InputMaybe<SortOrder>;
  days_set_out?: InputMaybe<SortOrder>;
  hold?: InputMaybe<SortOrder>;
  lead?: InputMaybe<LeadOrderByWithRelationInput>;
  lead_activities?: InputMaybe<LeadActivityOrderByRelationAggregateInput>;
  lead_id?: InputMaybe<SortOrder>;
  lead_if_current?: InputMaybe<LeadOrderByWithRelationInput>;
  live_transfer?: InputMaybe<SortOrder>;
  num_dials?: InputMaybe<SortOrder>;
  Organization?: InputMaybe<OrganizationOrderByWithRelationInput>;
  organization_id?: InputMaybe<SortOrder>;
  sdr_transfer?: InputMaybe<SortOrder>;
  set?: InputMaybe<SortOrder>;
  set_site?: InputMaybe<SiteOrderByWithRelationInput>;
  set_site_id?: InputMaybe<SortOrder>;
  set_team?: InputMaybe<TeamOrderByWithRelationInput>;
  set_team_id?: InputMaybe<SortOrder>;
  set_user?: InputMaybe<UserOrderByWithRelationInput>;
  set_user_id?: InputMaybe<SortOrder>;
  sf_opportunity_id?: InputMaybe<SortOrder>;
  time_of_first_contact?: InputMaybe<SortOrder>;
  time_of_first_hold?: InputMaybe<SortOrder>;
  time_of_first_sale?: InputMaybe<SortOrder>;
  time_of_first_set?: InputMaybe<SortOrder>;
  transfer_site?: InputMaybe<SiteOrderByWithRelationInput>;
  transfer_site_id?: InputMaybe<SortOrder>;
  transfer_team?: InputMaybe<TeamOrderByWithRelationInput>;
  transfer_team_id?: InputMaybe<SortOrder>;
  transfer_user?: InputMaybe<UserOrderByWithRelationInput>;
  transfer_user_id?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
};

export type SaleCycleScalarWhereInput = {
  AND?: InputMaybe<Array<SaleCycleScalarWhereInput>>;
  close?: InputMaybe<IntFilter>;
  close_site_id?: InputMaybe<StringNullableFilter>;
  close_team_id?: InputMaybe<StringNullableFilter>;
  close_user_id?: InputMaybe<StringNullableFilter>;
  created_at?: InputMaybe<DateTimeFilter>;
  cycle_num?: InputMaybe<IntFilter>;
  days_set_out?: InputMaybe<FloatNullableFilter>;
  hold?: InputMaybe<IntFilter>;
  lead_id?: InputMaybe<StringFilter>;
  live_transfer?: InputMaybe<IntFilter>;
  NOT?: InputMaybe<Array<SaleCycleScalarWhereInput>>;
  num_dials?: InputMaybe<IntFilter>;
  OR?: InputMaybe<Array<SaleCycleScalarWhereInput>>;
  organization_id?: InputMaybe<StringNullableFilter>;
  sdr_transfer?: InputMaybe<IntFilter>;
  set?: InputMaybe<IntFilter>;
  set_site_id?: InputMaybe<StringNullableFilter>;
  set_team_id?: InputMaybe<StringNullableFilter>;
  set_user_id?: InputMaybe<StringNullableFilter>;
  sf_opportunity_id?: InputMaybe<StringNullableFilter>;
  time_of_first_contact?: InputMaybe<DateTimeNullableFilter>;
  time_of_first_hold?: InputMaybe<DateTimeNullableFilter>;
  time_of_first_sale?: InputMaybe<DateTimeNullableFilter>;
  time_of_first_set?: InputMaybe<DateTimeNullableFilter>;
  transfer_site_id?: InputMaybe<StringNullableFilter>;
  transfer_team_id?: InputMaybe<StringNullableFilter>;
  transfer_user_id?: InputMaybe<StringNullableFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
};

export type SaleCycleUpdateManyMutationInput = {
  close?: InputMaybe<IntFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  cycle_num?: InputMaybe<IntFieldUpdateOperationsInput>;
  days_set_out?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  hold?: InputMaybe<IntFieldUpdateOperationsInput>;
  live_transfer?: InputMaybe<IntFieldUpdateOperationsInput>;
  num_dials?: InputMaybe<IntFieldUpdateOperationsInput>;
  sdr_transfer?: InputMaybe<IntFieldUpdateOperationsInput>;
  set?: InputMaybe<IntFieldUpdateOperationsInput>;
  sf_opportunity_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  time_of_first_contact?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  time_of_first_hold?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  time_of_first_sale?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  time_of_first_set?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type SaleCycleUpdateManyWithoutClose_SiteInput = {
  connect?: InputMaybe<Array<SaleCycleWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SaleCycleCreateOrConnectWithoutClose_SiteInput>>;
  create?: InputMaybe<Array<SaleCycleCreateWithoutClose_SiteInput>>;
  createMany?: InputMaybe<SaleCycleCreateManyClose_SiteInputEnvelope>;
  delete?: InputMaybe<Array<SaleCycleWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<SaleCycleScalarWhereInput>>;
  disconnect?: InputMaybe<Array<SaleCycleWhereUniqueInput>>;
  set?: InputMaybe<Array<SaleCycleWhereUniqueInput>>;
  update?: InputMaybe<Array<SaleCycleUpdateWithWhereUniqueWithoutClose_SiteInput>>;
  updateMany?: InputMaybe<Array<SaleCycleUpdateManyWithWhereWithoutClose_SiteInput>>;
  upsert?: InputMaybe<Array<SaleCycleUpsertWithWhereUniqueWithoutClose_SiteInput>>;
};

export type SaleCycleUpdateManyWithoutClose_TeamInput = {
  connect?: InputMaybe<Array<SaleCycleWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SaleCycleCreateOrConnectWithoutClose_TeamInput>>;
  create?: InputMaybe<Array<SaleCycleCreateWithoutClose_TeamInput>>;
  createMany?: InputMaybe<SaleCycleCreateManyClose_TeamInputEnvelope>;
  delete?: InputMaybe<Array<SaleCycleWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<SaleCycleScalarWhereInput>>;
  disconnect?: InputMaybe<Array<SaleCycleWhereUniqueInput>>;
  set?: InputMaybe<Array<SaleCycleWhereUniqueInput>>;
  update?: InputMaybe<Array<SaleCycleUpdateWithWhereUniqueWithoutClose_TeamInput>>;
  updateMany?: InputMaybe<Array<SaleCycleUpdateManyWithWhereWithoutClose_TeamInput>>;
  upsert?: InputMaybe<Array<SaleCycleUpsertWithWhereUniqueWithoutClose_TeamInput>>;
};

export type SaleCycleUpdateManyWithoutClose_UserInput = {
  connect?: InputMaybe<Array<SaleCycleWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SaleCycleCreateOrConnectWithoutClose_UserInput>>;
  create?: InputMaybe<Array<SaleCycleCreateWithoutClose_UserInput>>;
  createMany?: InputMaybe<SaleCycleCreateManyClose_UserInputEnvelope>;
  delete?: InputMaybe<Array<SaleCycleWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<SaleCycleScalarWhereInput>>;
  disconnect?: InputMaybe<Array<SaleCycleWhereUniqueInput>>;
  set?: InputMaybe<Array<SaleCycleWhereUniqueInput>>;
  update?: InputMaybe<Array<SaleCycleUpdateWithWhereUniqueWithoutClose_UserInput>>;
  updateMany?: InputMaybe<Array<SaleCycleUpdateManyWithWhereWithoutClose_UserInput>>;
  upsert?: InputMaybe<Array<SaleCycleUpsertWithWhereUniqueWithoutClose_UserInput>>;
};

export type SaleCycleUpdateManyWithoutLeadInput = {
  connect?: InputMaybe<Array<SaleCycleWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SaleCycleCreateOrConnectWithoutLeadInput>>;
  create?: InputMaybe<Array<SaleCycleCreateWithoutLeadInput>>;
  createMany?: InputMaybe<SaleCycleCreateManyLeadInputEnvelope>;
  delete?: InputMaybe<Array<SaleCycleWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<SaleCycleScalarWhereInput>>;
  disconnect?: InputMaybe<Array<SaleCycleWhereUniqueInput>>;
  set?: InputMaybe<Array<SaleCycleWhereUniqueInput>>;
  update?: InputMaybe<Array<SaleCycleUpdateWithWhereUniqueWithoutLeadInput>>;
  updateMany?: InputMaybe<Array<SaleCycleUpdateManyWithWhereWithoutLeadInput>>;
  upsert?: InputMaybe<Array<SaleCycleUpsertWithWhereUniqueWithoutLeadInput>>;
};

export type SaleCycleUpdateManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<SaleCycleWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SaleCycleCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<SaleCycleCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<SaleCycleCreateManyOrganizationInputEnvelope>;
  delete?: InputMaybe<Array<SaleCycleWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<SaleCycleScalarWhereInput>>;
  disconnect?: InputMaybe<Array<SaleCycleWhereUniqueInput>>;
  set?: InputMaybe<Array<SaleCycleWhereUniqueInput>>;
  update?: InputMaybe<Array<SaleCycleUpdateWithWhereUniqueWithoutOrganizationInput>>;
  updateMany?: InputMaybe<Array<SaleCycleUpdateManyWithWhereWithoutOrganizationInput>>;
  upsert?: InputMaybe<Array<SaleCycleUpsertWithWhereUniqueWithoutOrganizationInput>>;
};

export type SaleCycleUpdateManyWithoutSet_SiteInput = {
  connect?: InputMaybe<Array<SaleCycleWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SaleCycleCreateOrConnectWithoutSet_SiteInput>>;
  create?: InputMaybe<Array<SaleCycleCreateWithoutSet_SiteInput>>;
  createMany?: InputMaybe<SaleCycleCreateManySet_SiteInputEnvelope>;
  delete?: InputMaybe<Array<SaleCycleWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<SaleCycleScalarWhereInput>>;
  disconnect?: InputMaybe<Array<SaleCycleWhereUniqueInput>>;
  set?: InputMaybe<Array<SaleCycleWhereUniqueInput>>;
  update?: InputMaybe<Array<SaleCycleUpdateWithWhereUniqueWithoutSet_SiteInput>>;
  updateMany?: InputMaybe<Array<SaleCycleUpdateManyWithWhereWithoutSet_SiteInput>>;
  upsert?: InputMaybe<Array<SaleCycleUpsertWithWhereUniqueWithoutSet_SiteInput>>;
};

export type SaleCycleUpdateManyWithoutSet_TeamInput = {
  connect?: InputMaybe<Array<SaleCycleWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SaleCycleCreateOrConnectWithoutSet_TeamInput>>;
  create?: InputMaybe<Array<SaleCycleCreateWithoutSet_TeamInput>>;
  createMany?: InputMaybe<SaleCycleCreateManySet_TeamInputEnvelope>;
  delete?: InputMaybe<Array<SaleCycleWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<SaleCycleScalarWhereInput>>;
  disconnect?: InputMaybe<Array<SaleCycleWhereUniqueInput>>;
  set?: InputMaybe<Array<SaleCycleWhereUniqueInput>>;
  update?: InputMaybe<Array<SaleCycleUpdateWithWhereUniqueWithoutSet_TeamInput>>;
  updateMany?: InputMaybe<Array<SaleCycleUpdateManyWithWhereWithoutSet_TeamInput>>;
  upsert?: InputMaybe<Array<SaleCycleUpsertWithWhereUniqueWithoutSet_TeamInput>>;
};

export type SaleCycleUpdateManyWithoutSet_UserInput = {
  connect?: InputMaybe<Array<SaleCycleWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SaleCycleCreateOrConnectWithoutSet_UserInput>>;
  create?: InputMaybe<Array<SaleCycleCreateWithoutSet_UserInput>>;
  createMany?: InputMaybe<SaleCycleCreateManySet_UserInputEnvelope>;
  delete?: InputMaybe<Array<SaleCycleWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<SaleCycleScalarWhereInput>>;
  disconnect?: InputMaybe<Array<SaleCycleWhereUniqueInput>>;
  set?: InputMaybe<Array<SaleCycleWhereUniqueInput>>;
  update?: InputMaybe<Array<SaleCycleUpdateWithWhereUniqueWithoutSet_UserInput>>;
  updateMany?: InputMaybe<Array<SaleCycleUpdateManyWithWhereWithoutSet_UserInput>>;
  upsert?: InputMaybe<Array<SaleCycleUpsertWithWhereUniqueWithoutSet_UserInput>>;
};

export type SaleCycleUpdateManyWithoutTransfer_SiteInput = {
  connect?: InputMaybe<Array<SaleCycleWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SaleCycleCreateOrConnectWithoutTransfer_SiteInput>>;
  create?: InputMaybe<Array<SaleCycleCreateWithoutTransfer_SiteInput>>;
  createMany?: InputMaybe<SaleCycleCreateManyTransfer_SiteInputEnvelope>;
  delete?: InputMaybe<Array<SaleCycleWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<SaleCycleScalarWhereInput>>;
  disconnect?: InputMaybe<Array<SaleCycleWhereUniqueInput>>;
  set?: InputMaybe<Array<SaleCycleWhereUniqueInput>>;
  update?: InputMaybe<Array<SaleCycleUpdateWithWhereUniqueWithoutTransfer_SiteInput>>;
  updateMany?: InputMaybe<Array<SaleCycleUpdateManyWithWhereWithoutTransfer_SiteInput>>;
  upsert?: InputMaybe<Array<SaleCycleUpsertWithWhereUniqueWithoutTransfer_SiteInput>>;
};

export type SaleCycleUpdateManyWithoutTransfer_TeamInput = {
  connect?: InputMaybe<Array<SaleCycleWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SaleCycleCreateOrConnectWithoutTransfer_TeamInput>>;
  create?: InputMaybe<Array<SaleCycleCreateWithoutTransfer_TeamInput>>;
  createMany?: InputMaybe<SaleCycleCreateManyTransfer_TeamInputEnvelope>;
  delete?: InputMaybe<Array<SaleCycleWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<SaleCycleScalarWhereInput>>;
  disconnect?: InputMaybe<Array<SaleCycleWhereUniqueInput>>;
  set?: InputMaybe<Array<SaleCycleWhereUniqueInput>>;
  update?: InputMaybe<Array<SaleCycleUpdateWithWhereUniqueWithoutTransfer_TeamInput>>;
  updateMany?: InputMaybe<Array<SaleCycleUpdateManyWithWhereWithoutTransfer_TeamInput>>;
  upsert?: InputMaybe<Array<SaleCycleUpsertWithWhereUniqueWithoutTransfer_TeamInput>>;
};

export type SaleCycleUpdateManyWithoutTransfer_UserInput = {
  connect?: InputMaybe<Array<SaleCycleWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SaleCycleCreateOrConnectWithoutTransfer_UserInput>>;
  create?: InputMaybe<Array<SaleCycleCreateWithoutTransfer_UserInput>>;
  createMany?: InputMaybe<SaleCycleCreateManyTransfer_UserInputEnvelope>;
  delete?: InputMaybe<Array<SaleCycleWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<SaleCycleScalarWhereInput>>;
  disconnect?: InputMaybe<Array<SaleCycleWhereUniqueInput>>;
  set?: InputMaybe<Array<SaleCycleWhereUniqueInput>>;
  update?: InputMaybe<Array<SaleCycleUpdateWithWhereUniqueWithoutTransfer_UserInput>>;
  updateMany?: InputMaybe<Array<SaleCycleUpdateManyWithWhereWithoutTransfer_UserInput>>;
  upsert?: InputMaybe<Array<SaleCycleUpsertWithWhereUniqueWithoutTransfer_UserInput>>;
};

export type SaleCycleUpdateManyWithWhereWithoutClose_SiteInput = {
  data: SaleCycleUpdateManyMutationInput;
  where: SaleCycleScalarWhereInput;
};

export type SaleCycleUpdateManyWithWhereWithoutClose_TeamInput = {
  data: SaleCycleUpdateManyMutationInput;
  where: SaleCycleScalarWhereInput;
};

export type SaleCycleUpdateManyWithWhereWithoutClose_UserInput = {
  data: SaleCycleUpdateManyMutationInput;
  where: SaleCycleScalarWhereInput;
};

export type SaleCycleUpdateManyWithWhereWithoutLeadInput = {
  data: SaleCycleUpdateManyMutationInput;
  where: SaleCycleScalarWhereInput;
};

export type SaleCycleUpdateManyWithWhereWithoutOrganizationInput = {
  data: SaleCycleUpdateManyMutationInput;
  where: SaleCycleScalarWhereInput;
};

export type SaleCycleUpdateManyWithWhereWithoutSet_SiteInput = {
  data: SaleCycleUpdateManyMutationInput;
  where: SaleCycleScalarWhereInput;
};

export type SaleCycleUpdateManyWithWhereWithoutSet_TeamInput = {
  data: SaleCycleUpdateManyMutationInput;
  where: SaleCycleScalarWhereInput;
};

export type SaleCycleUpdateManyWithWhereWithoutSet_UserInput = {
  data: SaleCycleUpdateManyMutationInput;
  where: SaleCycleScalarWhereInput;
};

export type SaleCycleUpdateManyWithWhereWithoutTransfer_SiteInput = {
  data: SaleCycleUpdateManyMutationInput;
  where: SaleCycleScalarWhereInput;
};

export type SaleCycleUpdateManyWithWhereWithoutTransfer_TeamInput = {
  data: SaleCycleUpdateManyMutationInput;
  where: SaleCycleScalarWhereInput;
};

export type SaleCycleUpdateManyWithWhereWithoutTransfer_UserInput = {
  data: SaleCycleUpdateManyMutationInput;
  where: SaleCycleScalarWhereInput;
};

export type SaleCycleUpdateOneWithoutLead_ActivitiesInput = {
  connect?: InputMaybe<SaleCycleWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SaleCycleCreateOrConnectWithoutLead_ActivitiesInput>;
  create?: InputMaybe<SaleCycleCreateWithoutLead_ActivitiesInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<SaleCycleUpdateWithoutLead_ActivitiesInput>;
  upsert?: InputMaybe<SaleCycleUpsertWithoutLead_ActivitiesInput>;
};

export type SaleCycleUpdateOneWithoutLead_If_CurrentInput = {
  connect?: InputMaybe<SaleCycleWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SaleCycleCreateOrConnectWithoutLead_If_CurrentInput>;
  create?: InputMaybe<SaleCycleCreateWithoutLead_If_CurrentInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<SaleCycleUpdateWithoutLead_If_CurrentInput>;
  upsert?: InputMaybe<SaleCycleUpsertWithoutLead_If_CurrentInput>;
};

export type SaleCycleUpdateWithoutClose_SiteInput = {
  close?: InputMaybe<IntFieldUpdateOperationsInput>;
  close_team?: InputMaybe<TeamUpdateOneWithoutClose_Team_CycleInput>;
  close_user?: InputMaybe<UserUpdateOneWithoutClose_User_CycleInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  cycle_num?: InputMaybe<IntFieldUpdateOperationsInput>;
  days_set_out?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  hold?: InputMaybe<IntFieldUpdateOperationsInput>;
  lead?: InputMaybe<LeadUpdateOneRequiredWithoutSale_CyclesInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutSale_CycleInput>;
  lead_if_current?: InputMaybe<LeadUpdateOneWithoutCurrent_Sale_CycleInput>;
  live_transfer?: InputMaybe<IntFieldUpdateOperationsInput>;
  num_dials?: InputMaybe<IntFieldUpdateOperationsInput>;
  Organization?: InputMaybe<OrganizationUpdateOneWithoutSaleCyclesInput>;
  sdr_transfer?: InputMaybe<IntFieldUpdateOperationsInput>;
  set?: InputMaybe<IntFieldUpdateOperationsInput>;
  set_site?: InputMaybe<SiteUpdateOneWithoutSet_Site_CycleInput>;
  set_team?: InputMaybe<TeamUpdateOneWithoutSet_Team_CycleInput>;
  set_user?: InputMaybe<UserUpdateOneWithoutSet_User_CycleInput>;
  sf_opportunity_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  time_of_first_contact?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  time_of_first_hold?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  time_of_first_sale?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  time_of_first_set?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  transfer_site?: InputMaybe<SiteUpdateOneWithoutTransfer_Site_CycleInput>;
  transfer_team?: InputMaybe<TeamUpdateOneWithoutTransfer_Team_CycleInput>;
  transfer_user?: InputMaybe<UserUpdateOneWithoutTransfer_User_CycleInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type SaleCycleUpdateWithoutClose_TeamInput = {
  close?: InputMaybe<IntFieldUpdateOperationsInput>;
  close_site?: InputMaybe<SiteUpdateOneWithoutClose_Site_CycleInput>;
  close_user?: InputMaybe<UserUpdateOneWithoutClose_User_CycleInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  cycle_num?: InputMaybe<IntFieldUpdateOperationsInput>;
  days_set_out?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  hold?: InputMaybe<IntFieldUpdateOperationsInput>;
  lead?: InputMaybe<LeadUpdateOneRequiredWithoutSale_CyclesInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutSale_CycleInput>;
  lead_if_current?: InputMaybe<LeadUpdateOneWithoutCurrent_Sale_CycleInput>;
  live_transfer?: InputMaybe<IntFieldUpdateOperationsInput>;
  num_dials?: InputMaybe<IntFieldUpdateOperationsInput>;
  Organization?: InputMaybe<OrganizationUpdateOneWithoutSaleCyclesInput>;
  sdr_transfer?: InputMaybe<IntFieldUpdateOperationsInput>;
  set?: InputMaybe<IntFieldUpdateOperationsInput>;
  set_site?: InputMaybe<SiteUpdateOneWithoutSet_Site_CycleInput>;
  set_team?: InputMaybe<TeamUpdateOneWithoutSet_Team_CycleInput>;
  set_user?: InputMaybe<UserUpdateOneWithoutSet_User_CycleInput>;
  sf_opportunity_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  time_of_first_contact?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  time_of_first_hold?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  time_of_first_sale?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  time_of_first_set?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  transfer_site?: InputMaybe<SiteUpdateOneWithoutTransfer_Site_CycleInput>;
  transfer_team?: InputMaybe<TeamUpdateOneWithoutTransfer_Team_CycleInput>;
  transfer_user?: InputMaybe<UserUpdateOneWithoutTransfer_User_CycleInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type SaleCycleUpdateWithoutClose_UserInput = {
  close?: InputMaybe<IntFieldUpdateOperationsInput>;
  close_site?: InputMaybe<SiteUpdateOneWithoutClose_Site_CycleInput>;
  close_team?: InputMaybe<TeamUpdateOneWithoutClose_Team_CycleInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  cycle_num?: InputMaybe<IntFieldUpdateOperationsInput>;
  days_set_out?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  hold?: InputMaybe<IntFieldUpdateOperationsInput>;
  lead?: InputMaybe<LeadUpdateOneRequiredWithoutSale_CyclesInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutSale_CycleInput>;
  lead_if_current?: InputMaybe<LeadUpdateOneWithoutCurrent_Sale_CycleInput>;
  live_transfer?: InputMaybe<IntFieldUpdateOperationsInput>;
  num_dials?: InputMaybe<IntFieldUpdateOperationsInput>;
  Organization?: InputMaybe<OrganizationUpdateOneWithoutSaleCyclesInput>;
  sdr_transfer?: InputMaybe<IntFieldUpdateOperationsInput>;
  set?: InputMaybe<IntFieldUpdateOperationsInput>;
  set_site?: InputMaybe<SiteUpdateOneWithoutSet_Site_CycleInput>;
  set_team?: InputMaybe<TeamUpdateOneWithoutSet_Team_CycleInput>;
  set_user?: InputMaybe<UserUpdateOneWithoutSet_User_CycleInput>;
  sf_opportunity_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  time_of_first_contact?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  time_of_first_hold?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  time_of_first_sale?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  time_of_first_set?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  transfer_site?: InputMaybe<SiteUpdateOneWithoutTransfer_Site_CycleInput>;
  transfer_team?: InputMaybe<TeamUpdateOneWithoutTransfer_Team_CycleInput>;
  transfer_user?: InputMaybe<UserUpdateOneWithoutTransfer_User_CycleInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type SaleCycleUpdateWithoutLead_ActivitiesInput = {
  close?: InputMaybe<IntFieldUpdateOperationsInput>;
  close_site?: InputMaybe<SiteUpdateOneWithoutClose_Site_CycleInput>;
  close_team?: InputMaybe<TeamUpdateOneWithoutClose_Team_CycleInput>;
  close_user?: InputMaybe<UserUpdateOneWithoutClose_User_CycleInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  cycle_num?: InputMaybe<IntFieldUpdateOperationsInput>;
  days_set_out?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  hold?: InputMaybe<IntFieldUpdateOperationsInput>;
  lead?: InputMaybe<LeadUpdateOneRequiredWithoutSale_CyclesInput>;
  lead_if_current?: InputMaybe<LeadUpdateOneWithoutCurrent_Sale_CycleInput>;
  live_transfer?: InputMaybe<IntFieldUpdateOperationsInput>;
  num_dials?: InputMaybe<IntFieldUpdateOperationsInput>;
  Organization?: InputMaybe<OrganizationUpdateOneWithoutSaleCyclesInput>;
  sdr_transfer?: InputMaybe<IntFieldUpdateOperationsInput>;
  set?: InputMaybe<IntFieldUpdateOperationsInput>;
  set_site?: InputMaybe<SiteUpdateOneWithoutSet_Site_CycleInput>;
  set_team?: InputMaybe<TeamUpdateOneWithoutSet_Team_CycleInput>;
  set_user?: InputMaybe<UserUpdateOneWithoutSet_User_CycleInput>;
  sf_opportunity_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  time_of_first_contact?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  time_of_first_hold?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  time_of_first_sale?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  time_of_first_set?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  transfer_site?: InputMaybe<SiteUpdateOneWithoutTransfer_Site_CycleInput>;
  transfer_team?: InputMaybe<TeamUpdateOneWithoutTransfer_Team_CycleInput>;
  transfer_user?: InputMaybe<UserUpdateOneWithoutTransfer_User_CycleInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type SaleCycleUpdateWithoutLead_If_CurrentInput = {
  close?: InputMaybe<IntFieldUpdateOperationsInput>;
  close_site?: InputMaybe<SiteUpdateOneWithoutClose_Site_CycleInput>;
  close_team?: InputMaybe<TeamUpdateOneWithoutClose_Team_CycleInput>;
  close_user?: InputMaybe<UserUpdateOneWithoutClose_User_CycleInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  cycle_num?: InputMaybe<IntFieldUpdateOperationsInput>;
  days_set_out?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  hold?: InputMaybe<IntFieldUpdateOperationsInput>;
  lead?: InputMaybe<LeadUpdateOneRequiredWithoutSale_CyclesInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutSale_CycleInput>;
  live_transfer?: InputMaybe<IntFieldUpdateOperationsInput>;
  num_dials?: InputMaybe<IntFieldUpdateOperationsInput>;
  Organization?: InputMaybe<OrganizationUpdateOneWithoutSaleCyclesInput>;
  sdr_transfer?: InputMaybe<IntFieldUpdateOperationsInput>;
  set?: InputMaybe<IntFieldUpdateOperationsInput>;
  set_site?: InputMaybe<SiteUpdateOneWithoutSet_Site_CycleInput>;
  set_team?: InputMaybe<TeamUpdateOneWithoutSet_Team_CycleInput>;
  set_user?: InputMaybe<UserUpdateOneWithoutSet_User_CycleInput>;
  sf_opportunity_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  time_of_first_contact?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  time_of_first_hold?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  time_of_first_sale?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  time_of_first_set?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  transfer_site?: InputMaybe<SiteUpdateOneWithoutTransfer_Site_CycleInput>;
  transfer_team?: InputMaybe<TeamUpdateOneWithoutTransfer_Team_CycleInput>;
  transfer_user?: InputMaybe<UserUpdateOneWithoutTransfer_User_CycleInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type SaleCycleUpdateWithoutLeadInput = {
  close?: InputMaybe<IntFieldUpdateOperationsInput>;
  close_site?: InputMaybe<SiteUpdateOneWithoutClose_Site_CycleInput>;
  close_team?: InputMaybe<TeamUpdateOneWithoutClose_Team_CycleInput>;
  close_user?: InputMaybe<UserUpdateOneWithoutClose_User_CycleInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  cycle_num?: InputMaybe<IntFieldUpdateOperationsInput>;
  days_set_out?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  hold?: InputMaybe<IntFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutSale_CycleInput>;
  lead_if_current?: InputMaybe<LeadUpdateOneWithoutCurrent_Sale_CycleInput>;
  live_transfer?: InputMaybe<IntFieldUpdateOperationsInput>;
  num_dials?: InputMaybe<IntFieldUpdateOperationsInput>;
  Organization?: InputMaybe<OrganizationUpdateOneWithoutSaleCyclesInput>;
  sdr_transfer?: InputMaybe<IntFieldUpdateOperationsInput>;
  set?: InputMaybe<IntFieldUpdateOperationsInput>;
  set_site?: InputMaybe<SiteUpdateOneWithoutSet_Site_CycleInput>;
  set_team?: InputMaybe<TeamUpdateOneWithoutSet_Team_CycleInput>;
  set_user?: InputMaybe<UserUpdateOneWithoutSet_User_CycleInput>;
  sf_opportunity_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  time_of_first_contact?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  time_of_first_hold?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  time_of_first_sale?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  time_of_first_set?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  transfer_site?: InputMaybe<SiteUpdateOneWithoutTransfer_Site_CycleInput>;
  transfer_team?: InputMaybe<TeamUpdateOneWithoutTransfer_Team_CycleInput>;
  transfer_user?: InputMaybe<UserUpdateOneWithoutTransfer_User_CycleInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type SaleCycleUpdateWithoutOrganizationInput = {
  close?: InputMaybe<IntFieldUpdateOperationsInput>;
  close_site?: InputMaybe<SiteUpdateOneWithoutClose_Site_CycleInput>;
  close_team?: InputMaybe<TeamUpdateOneWithoutClose_Team_CycleInput>;
  close_user?: InputMaybe<UserUpdateOneWithoutClose_User_CycleInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  cycle_num?: InputMaybe<IntFieldUpdateOperationsInput>;
  days_set_out?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  hold?: InputMaybe<IntFieldUpdateOperationsInput>;
  lead?: InputMaybe<LeadUpdateOneRequiredWithoutSale_CyclesInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutSale_CycleInput>;
  lead_if_current?: InputMaybe<LeadUpdateOneWithoutCurrent_Sale_CycleInput>;
  live_transfer?: InputMaybe<IntFieldUpdateOperationsInput>;
  num_dials?: InputMaybe<IntFieldUpdateOperationsInput>;
  sdr_transfer?: InputMaybe<IntFieldUpdateOperationsInput>;
  set?: InputMaybe<IntFieldUpdateOperationsInput>;
  set_site?: InputMaybe<SiteUpdateOneWithoutSet_Site_CycleInput>;
  set_team?: InputMaybe<TeamUpdateOneWithoutSet_Team_CycleInput>;
  set_user?: InputMaybe<UserUpdateOneWithoutSet_User_CycleInput>;
  sf_opportunity_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  time_of_first_contact?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  time_of_first_hold?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  time_of_first_sale?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  time_of_first_set?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  transfer_site?: InputMaybe<SiteUpdateOneWithoutTransfer_Site_CycleInput>;
  transfer_team?: InputMaybe<TeamUpdateOneWithoutTransfer_Team_CycleInput>;
  transfer_user?: InputMaybe<UserUpdateOneWithoutTransfer_User_CycleInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type SaleCycleUpdateWithoutSet_SiteInput = {
  close?: InputMaybe<IntFieldUpdateOperationsInput>;
  close_site?: InputMaybe<SiteUpdateOneWithoutClose_Site_CycleInput>;
  close_team?: InputMaybe<TeamUpdateOneWithoutClose_Team_CycleInput>;
  close_user?: InputMaybe<UserUpdateOneWithoutClose_User_CycleInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  cycle_num?: InputMaybe<IntFieldUpdateOperationsInput>;
  days_set_out?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  hold?: InputMaybe<IntFieldUpdateOperationsInput>;
  lead?: InputMaybe<LeadUpdateOneRequiredWithoutSale_CyclesInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutSale_CycleInput>;
  lead_if_current?: InputMaybe<LeadUpdateOneWithoutCurrent_Sale_CycleInput>;
  live_transfer?: InputMaybe<IntFieldUpdateOperationsInput>;
  num_dials?: InputMaybe<IntFieldUpdateOperationsInput>;
  Organization?: InputMaybe<OrganizationUpdateOneWithoutSaleCyclesInput>;
  sdr_transfer?: InputMaybe<IntFieldUpdateOperationsInput>;
  set?: InputMaybe<IntFieldUpdateOperationsInput>;
  set_team?: InputMaybe<TeamUpdateOneWithoutSet_Team_CycleInput>;
  set_user?: InputMaybe<UserUpdateOneWithoutSet_User_CycleInput>;
  sf_opportunity_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  time_of_first_contact?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  time_of_first_hold?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  time_of_first_sale?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  time_of_first_set?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  transfer_site?: InputMaybe<SiteUpdateOneWithoutTransfer_Site_CycleInput>;
  transfer_team?: InputMaybe<TeamUpdateOneWithoutTransfer_Team_CycleInput>;
  transfer_user?: InputMaybe<UserUpdateOneWithoutTransfer_User_CycleInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type SaleCycleUpdateWithoutSet_TeamInput = {
  close?: InputMaybe<IntFieldUpdateOperationsInput>;
  close_site?: InputMaybe<SiteUpdateOneWithoutClose_Site_CycleInput>;
  close_team?: InputMaybe<TeamUpdateOneWithoutClose_Team_CycleInput>;
  close_user?: InputMaybe<UserUpdateOneWithoutClose_User_CycleInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  cycle_num?: InputMaybe<IntFieldUpdateOperationsInput>;
  days_set_out?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  hold?: InputMaybe<IntFieldUpdateOperationsInput>;
  lead?: InputMaybe<LeadUpdateOneRequiredWithoutSale_CyclesInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutSale_CycleInput>;
  lead_if_current?: InputMaybe<LeadUpdateOneWithoutCurrent_Sale_CycleInput>;
  live_transfer?: InputMaybe<IntFieldUpdateOperationsInput>;
  num_dials?: InputMaybe<IntFieldUpdateOperationsInput>;
  Organization?: InputMaybe<OrganizationUpdateOneWithoutSaleCyclesInput>;
  sdr_transfer?: InputMaybe<IntFieldUpdateOperationsInput>;
  set?: InputMaybe<IntFieldUpdateOperationsInput>;
  set_site?: InputMaybe<SiteUpdateOneWithoutSet_Site_CycleInput>;
  set_user?: InputMaybe<UserUpdateOneWithoutSet_User_CycleInput>;
  sf_opportunity_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  time_of_first_contact?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  time_of_first_hold?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  time_of_first_sale?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  time_of_first_set?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  transfer_site?: InputMaybe<SiteUpdateOneWithoutTransfer_Site_CycleInput>;
  transfer_team?: InputMaybe<TeamUpdateOneWithoutTransfer_Team_CycleInput>;
  transfer_user?: InputMaybe<UserUpdateOneWithoutTransfer_User_CycleInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type SaleCycleUpdateWithoutSet_UserInput = {
  close?: InputMaybe<IntFieldUpdateOperationsInput>;
  close_site?: InputMaybe<SiteUpdateOneWithoutClose_Site_CycleInput>;
  close_team?: InputMaybe<TeamUpdateOneWithoutClose_Team_CycleInput>;
  close_user?: InputMaybe<UserUpdateOneWithoutClose_User_CycleInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  cycle_num?: InputMaybe<IntFieldUpdateOperationsInput>;
  days_set_out?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  hold?: InputMaybe<IntFieldUpdateOperationsInput>;
  lead?: InputMaybe<LeadUpdateOneRequiredWithoutSale_CyclesInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutSale_CycleInput>;
  lead_if_current?: InputMaybe<LeadUpdateOneWithoutCurrent_Sale_CycleInput>;
  live_transfer?: InputMaybe<IntFieldUpdateOperationsInput>;
  num_dials?: InputMaybe<IntFieldUpdateOperationsInput>;
  Organization?: InputMaybe<OrganizationUpdateOneWithoutSaleCyclesInput>;
  sdr_transfer?: InputMaybe<IntFieldUpdateOperationsInput>;
  set?: InputMaybe<IntFieldUpdateOperationsInput>;
  set_site?: InputMaybe<SiteUpdateOneWithoutSet_Site_CycleInput>;
  set_team?: InputMaybe<TeamUpdateOneWithoutSet_Team_CycleInput>;
  sf_opportunity_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  time_of_first_contact?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  time_of_first_hold?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  time_of_first_sale?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  time_of_first_set?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  transfer_site?: InputMaybe<SiteUpdateOneWithoutTransfer_Site_CycleInput>;
  transfer_team?: InputMaybe<TeamUpdateOneWithoutTransfer_Team_CycleInput>;
  transfer_user?: InputMaybe<UserUpdateOneWithoutTransfer_User_CycleInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type SaleCycleUpdateWithoutTransfer_SiteInput = {
  close?: InputMaybe<IntFieldUpdateOperationsInput>;
  close_site?: InputMaybe<SiteUpdateOneWithoutClose_Site_CycleInput>;
  close_team?: InputMaybe<TeamUpdateOneWithoutClose_Team_CycleInput>;
  close_user?: InputMaybe<UserUpdateOneWithoutClose_User_CycleInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  cycle_num?: InputMaybe<IntFieldUpdateOperationsInput>;
  days_set_out?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  hold?: InputMaybe<IntFieldUpdateOperationsInput>;
  lead?: InputMaybe<LeadUpdateOneRequiredWithoutSale_CyclesInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutSale_CycleInput>;
  lead_if_current?: InputMaybe<LeadUpdateOneWithoutCurrent_Sale_CycleInput>;
  live_transfer?: InputMaybe<IntFieldUpdateOperationsInput>;
  num_dials?: InputMaybe<IntFieldUpdateOperationsInput>;
  Organization?: InputMaybe<OrganizationUpdateOneWithoutSaleCyclesInput>;
  sdr_transfer?: InputMaybe<IntFieldUpdateOperationsInput>;
  set?: InputMaybe<IntFieldUpdateOperationsInput>;
  set_site?: InputMaybe<SiteUpdateOneWithoutSet_Site_CycleInput>;
  set_team?: InputMaybe<TeamUpdateOneWithoutSet_Team_CycleInput>;
  set_user?: InputMaybe<UserUpdateOneWithoutSet_User_CycleInput>;
  sf_opportunity_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  time_of_first_contact?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  time_of_first_hold?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  time_of_first_sale?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  time_of_first_set?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  transfer_team?: InputMaybe<TeamUpdateOneWithoutTransfer_Team_CycleInput>;
  transfer_user?: InputMaybe<UserUpdateOneWithoutTransfer_User_CycleInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type SaleCycleUpdateWithoutTransfer_TeamInput = {
  close?: InputMaybe<IntFieldUpdateOperationsInput>;
  close_site?: InputMaybe<SiteUpdateOneWithoutClose_Site_CycleInput>;
  close_team?: InputMaybe<TeamUpdateOneWithoutClose_Team_CycleInput>;
  close_user?: InputMaybe<UserUpdateOneWithoutClose_User_CycleInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  cycle_num?: InputMaybe<IntFieldUpdateOperationsInput>;
  days_set_out?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  hold?: InputMaybe<IntFieldUpdateOperationsInput>;
  lead?: InputMaybe<LeadUpdateOneRequiredWithoutSale_CyclesInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutSale_CycleInput>;
  lead_if_current?: InputMaybe<LeadUpdateOneWithoutCurrent_Sale_CycleInput>;
  live_transfer?: InputMaybe<IntFieldUpdateOperationsInput>;
  num_dials?: InputMaybe<IntFieldUpdateOperationsInput>;
  Organization?: InputMaybe<OrganizationUpdateOneWithoutSaleCyclesInput>;
  sdr_transfer?: InputMaybe<IntFieldUpdateOperationsInput>;
  set?: InputMaybe<IntFieldUpdateOperationsInput>;
  set_site?: InputMaybe<SiteUpdateOneWithoutSet_Site_CycleInput>;
  set_team?: InputMaybe<TeamUpdateOneWithoutSet_Team_CycleInput>;
  set_user?: InputMaybe<UserUpdateOneWithoutSet_User_CycleInput>;
  sf_opportunity_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  time_of_first_contact?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  time_of_first_hold?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  time_of_first_sale?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  time_of_first_set?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  transfer_site?: InputMaybe<SiteUpdateOneWithoutTransfer_Site_CycleInput>;
  transfer_user?: InputMaybe<UserUpdateOneWithoutTransfer_User_CycleInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type SaleCycleUpdateWithoutTransfer_UserInput = {
  close?: InputMaybe<IntFieldUpdateOperationsInput>;
  close_site?: InputMaybe<SiteUpdateOneWithoutClose_Site_CycleInput>;
  close_team?: InputMaybe<TeamUpdateOneWithoutClose_Team_CycleInput>;
  close_user?: InputMaybe<UserUpdateOneWithoutClose_User_CycleInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  cycle_num?: InputMaybe<IntFieldUpdateOperationsInput>;
  days_set_out?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  hold?: InputMaybe<IntFieldUpdateOperationsInput>;
  lead?: InputMaybe<LeadUpdateOneRequiredWithoutSale_CyclesInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutSale_CycleInput>;
  lead_if_current?: InputMaybe<LeadUpdateOneWithoutCurrent_Sale_CycleInput>;
  live_transfer?: InputMaybe<IntFieldUpdateOperationsInput>;
  num_dials?: InputMaybe<IntFieldUpdateOperationsInput>;
  Organization?: InputMaybe<OrganizationUpdateOneWithoutSaleCyclesInput>;
  sdr_transfer?: InputMaybe<IntFieldUpdateOperationsInput>;
  set?: InputMaybe<IntFieldUpdateOperationsInput>;
  set_site?: InputMaybe<SiteUpdateOneWithoutSet_Site_CycleInput>;
  set_team?: InputMaybe<TeamUpdateOneWithoutSet_Team_CycleInput>;
  set_user?: InputMaybe<UserUpdateOneWithoutSet_User_CycleInput>;
  sf_opportunity_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  time_of_first_contact?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  time_of_first_hold?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  time_of_first_sale?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  time_of_first_set?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  transfer_site?: InputMaybe<SiteUpdateOneWithoutTransfer_Site_CycleInput>;
  transfer_team?: InputMaybe<TeamUpdateOneWithoutTransfer_Team_CycleInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type SaleCycleUpdateWithWhereUniqueWithoutClose_SiteInput = {
  data: SaleCycleUpdateWithoutClose_SiteInput;
  where: SaleCycleWhereUniqueInput;
};

export type SaleCycleUpdateWithWhereUniqueWithoutClose_TeamInput = {
  data: SaleCycleUpdateWithoutClose_TeamInput;
  where: SaleCycleWhereUniqueInput;
};

export type SaleCycleUpdateWithWhereUniqueWithoutClose_UserInput = {
  data: SaleCycleUpdateWithoutClose_UserInput;
  where: SaleCycleWhereUniqueInput;
};

export type SaleCycleUpdateWithWhereUniqueWithoutLeadInput = {
  data: SaleCycleUpdateWithoutLeadInput;
  where: SaleCycleWhereUniqueInput;
};

export type SaleCycleUpdateWithWhereUniqueWithoutOrganizationInput = {
  data: SaleCycleUpdateWithoutOrganizationInput;
  where: SaleCycleWhereUniqueInput;
};

export type SaleCycleUpdateWithWhereUniqueWithoutSet_SiteInput = {
  data: SaleCycleUpdateWithoutSet_SiteInput;
  where: SaleCycleWhereUniqueInput;
};

export type SaleCycleUpdateWithWhereUniqueWithoutSet_TeamInput = {
  data: SaleCycleUpdateWithoutSet_TeamInput;
  where: SaleCycleWhereUniqueInput;
};

export type SaleCycleUpdateWithWhereUniqueWithoutSet_UserInput = {
  data: SaleCycleUpdateWithoutSet_UserInput;
  where: SaleCycleWhereUniqueInput;
};

export type SaleCycleUpdateWithWhereUniqueWithoutTransfer_SiteInput = {
  data: SaleCycleUpdateWithoutTransfer_SiteInput;
  where: SaleCycleWhereUniqueInput;
};

export type SaleCycleUpdateWithWhereUniqueWithoutTransfer_TeamInput = {
  data: SaleCycleUpdateWithoutTransfer_TeamInput;
  where: SaleCycleWhereUniqueInput;
};

export type SaleCycleUpdateWithWhereUniqueWithoutTransfer_UserInput = {
  data: SaleCycleUpdateWithoutTransfer_UserInput;
  where: SaleCycleWhereUniqueInput;
};

export type SaleCycleUpsertWithoutLead_ActivitiesInput = {
  create: SaleCycleCreateWithoutLead_ActivitiesInput;
  update: SaleCycleUpdateWithoutLead_ActivitiesInput;
};

export type SaleCycleUpsertWithoutLead_If_CurrentInput = {
  create: SaleCycleCreateWithoutLead_If_CurrentInput;
  update: SaleCycleUpdateWithoutLead_If_CurrentInput;
};

export type SaleCycleUpsertWithWhereUniqueWithoutClose_SiteInput = {
  create: SaleCycleCreateWithoutClose_SiteInput;
  update: SaleCycleUpdateWithoutClose_SiteInput;
  where: SaleCycleWhereUniqueInput;
};

export type SaleCycleUpsertWithWhereUniqueWithoutClose_TeamInput = {
  create: SaleCycleCreateWithoutClose_TeamInput;
  update: SaleCycleUpdateWithoutClose_TeamInput;
  where: SaleCycleWhereUniqueInput;
};

export type SaleCycleUpsertWithWhereUniqueWithoutClose_UserInput = {
  create: SaleCycleCreateWithoutClose_UserInput;
  update: SaleCycleUpdateWithoutClose_UserInput;
  where: SaleCycleWhereUniqueInput;
};

export type SaleCycleUpsertWithWhereUniqueWithoutLeadInput = {
  create: SaleCycleCreateWithoutLeadInput;
  update: SaleCycleUpdateWithoutLeadInput;
  where: SaleCycleWhereUniqueInput;
};

export type SaleCycleUpsertWithWhereUniqueWithoutOrganizationInput = {
  create: SaleCycleCreateWithoutOrganizationInput;
  update: SaleCycleUpdateWithoutOrganizationInput;
  where: SaleCycleWhereUniqueInput;
};

export type SaleCycleUpsertWithWhereUniqueWithoutSet_SiteInput = {
  create: SaleCycleCreateWithoutSet_SiteInput;
  update: SaleCycleUpdateWithoutSet_SiteInput;
  where: SaleCycleWhereUniqueInput;
};

export type SaleCycleUpsertWithWhereUniqueWithoutSet_TeamInput = {
  create: SaleCycleCreateWithoutSet_TeamInput;
  update: SaleCycleUpdateWithoutSet_TeamInput;
  where: SaleCycleWhereUniqueInput;
};

export type SaleCycleUpsertWithWhereUniqueWithoutSet_UserInput = {
  create: SaleCycleCreateWithoutSet_UserInput;
  update: SaleCycleUpdateWithoutSet_UserInput;
  where: SaleCycleWhereUniqueInput;
};

export type SaleCycleUpsertWithWhereUniqueWithoutTransfer_SiteInput = {
  create: SaleCycleCreateWithoutTransfer_SiteInput;
  update: SaleCycleUpdateWithoutTransfer_SiteInput;
  where: SaleCycleWhereUniqueInput;
};

export type SaleCycleUpsertWithWhereUniqueWithoutTransfer_TeamInput = {
  create: SaleCycleCreateWithoutTransfer_TeamInput;
  update: SaleCycleUpdateWithoutTransfer_TeamInput;
  where: SaleCycleWhereUniqueInput;
};

export type SaleCycleUpsertWithWhereUniqueWithoutTransfer_UserInput = {
  create: SaleCycleCreateWithoutTransfer_UserInput;
  update: SaleCycleUpdateWithoutTransfer_UserInput;
  where: SaleCycleWhereUniqueInput;
};

export type SaleCycleWhereInput = {
  AND?: InputMaybe<Array<SaleCycleWhereInput>>;
  close?: InputMaybe<IntFilter>;
  close_site?: InputMaybe<SiteWhereInput>;
  close_site_id?: InputMaybe<StringNullableFilter>;
  close_team?: InputMaybe<TeamWhereInput>;
  close_team_id?: InputMaybe<StringNullableFilter>;
  close_user?: InputMaybe<UserWhereInput>;
  close_user_id?: InputMaybe<StringNullableFilter>;
  created_at?: InputMaybe<DateTimeFilter>;
  cycle_num?: InputMaybe<IntFilter>;
  days_set_out?: InputMaybe<FloatNullableFilter>;
  hold?: InputMaybe<IntFilter>;
  lead?: InputMaybe<LeadWhereInput>;
  lead_activities?: InputMaybe<LeadActivityListRelationFilter>;
  lead_id?: InputMaybe<StringFilter>;
  lead_if_current?: InputMaybe<LeadWhereInput>;
  live_transfer?: InputMaybe<IntFilter>;
  NOT?: InputMaybe<Array<SaleCycleWhereInput>>;
  num_dials?: InputMaybe<IntFilter>;
  OR?: InputMaybe<Array<SaleCycleWhereInput>>;
  Organization?: InputMaybe<OrganizationWhereInput>;
  organization_id?: InputMaybe<StringNullableFilter>;
  sdr_transfer?: InputMaybe<IntFilter>;
  set?: InputMaybe<IntFilter>;
  set_site?: InputMaybe<SiteWhereInput>;
  set_site_id?: InputMaybe<StringNullableFilter>;
  set_team?: InputMaybe<TeamWhereInput>;
  set_team_id?: InputMaybe<StringNullableFilter>;
  set_user?: InputMaybe<UserWhereInput>;
  set_user_id?: InputMaybe<StringNullableFilter>;
  sf_opportunity_id?: InputMaybe<StringNullableFilter>;
  time_of_first_contact?: InputMaybe<DateTimeNullableFilter>;
  time_of_first_hold?: InputMaybe<DateTimeNullableFilter>;
  time_of_first_sale?: InputMaybe<DateTimeNullableFilter>;
  time_of_first_set?: InputMaybe<DateTimeNullableFilter>;
  transfer_site?: InputMaybe<SiteWhereInput>;
  transfer_site_id?: InputMaybe<StringNullableFilter>;
  transfer_team?: InputMaybe<TeamWhereInput>;
  transfer_team_id?: InputMaybe<StringNullableFilter>;
  transfer_user?: InputMaybe<UserWhereInput>;
  transfer_user_id?: InputMaybe<StringNullableFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
};

export type SaleCycleWhereUniqueInput = {
  cycle_num_lead_id?: InputMaybe<SaleCycleCycle_NumLead_IdCompoundUniqueInput>;
};

export type SaleFieldOption = {
  __typename?: 'SaleFieldOption';
  created_at: Scalars['DateTime']['output'];
  data?: Maybe<Scalars['Json']['output']>;
  default: Scalars['Boolean']['output'];
  field: Scalars['String']['output'];
  id: Scalars['String']['output'];
  label: Scalars['String']['output'];
  ordinal: Scalars['Int']['output'];
  organization?: Maybe<Organization>;
  organization_id?: Maybe<Scalars['String']['output']>;
  updated_at: Scalars['DateTime']['output'];
  value: Scalars['String']['output'];
};

export type SaleFieldOptionCreateManyOrganizationInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  data?: InputMaybe<Scalars['Json']['input']>;
  default?: InputMaybe<Scalars['Boolean']['input']>;
  field: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  label: Scalars['String']['input'];
  ordinal?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  value: Scalars['String']['input'];
};

export type SaleFieldOptionCreateManyOrganizationInputEnvelope = {
  data?: InputMaybe<Array<SaleFieldOptionCreateManyOrganizationInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SaleFieldOptionCreateNestedManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<SaleFieldOptionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SaleFieldOptionCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<SaleFieldOptionCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<SaleFieldOptionCreateManyOrganizationInputEnvelope>;
};

export type SaleFieldOptionCreateOrConnectWithoutOrganizationInput = {
  create: SaleFieldOptionCreateWithoutOrganizationInput;
  where: SaleFieldOptionWhereUniqueInput;
};

export type SaleFieldOptionCreateWithoutOrganizationInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  data?: InputMaybe<Scalars['Json']['input']>;
  default?: InputMaybe<Scalars['Boolean']['input']>;
  field: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  label: Scalars['String']['input'];
  ordinal?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  value: Scalars['String']['input'];
};

export type SaleFieldOptionListRelationFilter = {
  every?: InputMaybe<SaleFieldOptionWhereInput>;
  none?: InputMaybe<SaleFieldOptionWhereInput>;
  some?: InputMaybe<SaleFieldOptionWhereInput>;
};

export type SaleFieldOptionOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
  count?: InputMaybe<SortOrder>;
};

export type SaleFieldOptionScalarWhereInput = {
  AND?: InputMaybe<Array<SaleFieldOptionScalarWhereInput>>;
  created_at?: InputMaybe<DateTimeFilter>;
  data?: InputMaybe<JsonNullableFilter>;
  default?: InputMaybe<BoolFilter>;
  field?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  label?: InputMaybe<StringFilter>;
  NOT?: InputMaybe<Array<SaleFieldOptionScalarWhereInput>>;
  OR?: InputMaybe<Array<SaleFieldOptionScalarWhereInput>>;
  ordinal?: InputMaybe<IntFilter>;
  organization_id?: InputMaybe<StringNullableFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
  value?: InputMaybe<StringFilter>;
};

export type SaleFieldOptionUpdateManyMutationInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  data?: InputMaybe<Scalars['Json']['input']>;
  default?: InputMaybe<BoolFieldUpdateOperationsInput>;
  field?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  label?: InputMaybe<StringFieldUpdateOperationsInput>;
  ordinal?: InputMaybe<IntFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  value?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type SaleFieldOptionUpdateManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<SaleFieldOptionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SaleFieldOptionCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<SaleFieldOptionCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<SaleFieldOptionCreateManyOrganizationInputEnvelope>;
  delete?: InputMaybe<Array<SaleFieldOptionWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<SaleFieldOptionScalarWhereInput>>;
  disconnect?: InputMaybe<Array<SaleFieldOptionWhereUniqueInput>>;
  set?: InputMaybe<Array<SaleFieldOptionWhereUniqueInput>>;
  update?: InputMaybe<Array<SaleFieldOptionUpdateWithWhereUniqueWithoutOrganizationInput>>;
  updateMany?: InputMaybe<Array<SaleFieldOptionUpdateManyWithWhereWithoutOrganizationInput>>;
  upsert?: InputMaybe<Array<SaleFieldOptionUpsertWithWhereUniqueWithoutOrganizationInput>>;
};

export type SaleFieldOptionUpdateManyWithWhereWithoutOrganizationInput = {
  data: SaleFieldOptionUpdateManyMutationInput;
  where: SaleFieldOptionScalarWhereInput;
};

export type SaleFieldOptionUpdateWithoutOrganizationInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  data?: InputMaybe<Scalars['Json']['input']>;
  default?: InputMaybe<BoolFieldUpdateOperationsInput>;
  field?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  label?: InputMaybe<StringFieldUpdateOperationsInput>;
  ordinal?: InputMaybe<IntFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  value?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type SaleFieldOptionUpdateWithWhereUniqueWithoutOrganizationInput = {
  data: SaleFieldOptionUpdateWithoutOrganizationInput;
  where: SaleFieldOptionWhereUniqueInput;
};

export type SaleFieldOptionUpsertWithWhereUniqueWithoutOrganizationInput = {
  create: SaleFieldOptionCreateWithoutOrganizationInput;
  update: SaleFieldOptionUpdateWithoutOrganizationInput;
  where: SaleFieldOptionWhereUniqueInput;
};

export type SaleFieldOptionWhereInput = {
  AND?: InputMaybe<Array<SaleFieldOptionWhereInput>>;
  created_at?: InputMaybe<DateTimeFilter>;
  data?: InputMaybe<JsonNullableFilter>;
  default?: InputMaybe<BoolFilter>;
  field?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  label?: InputMaybe<StringFilter>;
  NOT?: InputMaybe<Array<SaleFieldOptionWhereInput>>;
  OR?: InputMaybe<Array<SaleFieldOptionWhereInput>>;
  ordinal?: InputMaybe<IntFilter>;
  organization?: InputMaybe<OrganizationWhereInput>;
  organization_id?: InputMaybe<StringNullableFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
  value?: InputMaybe<StringFilter>;
};

export type SaleFieldOptionWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']['input']>;
};

/** Use for sale improvement box */
export type SaleImprovement = {
  __typename?: 'SaleImprovement';
  cr_result?: Maybe<Scalars['String']['output']>;
  metric?: Maybe<Metric>;
  metric_label?: Maybe<Scalars['String']['output']>;
  metric_tooltip: Scalars['String']['output'];
  metric_type?: Maybe<Metrictype>;
  rs_result?: Maybe<Scalars['String']['output']>;
  sale_improvement?: Maybe<Scalars['Float']['output']>;
  shr_result?: Maybe<Scalars['String']['output']>;
  sr_result?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
  wrd_result?: Maybe<Scalars['String']['output']>;
};

export type SaleItem = {
  __typename?: 'SaleItem';
  concession: Array<Concession>;
  created_at: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  prepayment?: Maybe<Scalars['Int']['output']>;
  price_override?: Maybe<Scalars['Float']['output']>;
  product?: Maybe<Product>;
  product_id?: Maybe<Scalars['String']['output']>;
  quantity: Scalars['Int']['output'];
  sale?: Maybe<Sale>;
  sale_id?: Maybe<Scalars['String']['output']>;
  updated_at: Scalars['DateTime']['output'];
};


export type SaleItemConcessionArgs = {
  after?: InputMaybe<ConcessionWhereUniqueInput>;
  before?: InputMaybe<ConcessionWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type SaleItemCreateManyOrganizationInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  prepayment?: InputMaybe<Scalars['Int']['input']>;
  price_override?: InputMaybe<Scalars['Float']['input']>;
  product_id?: InputMaybe<Scalars['String']['input']>;
  quantity: Scalars['Int']['input'];
  sale_id?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type SaleItemCreateManyOrganizationInputEnvelope = {
  data?: InputMaybe<Array<SaleItemCreateManyOrganizationInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SaleItemCreateManyProductInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  organization_id?: InputMaybe<Scalars['String']['input']>;
  prepayment?: InputMaybe<Scalars['Int']['input']>;
  price_override?: InputMaybe<Scalars['Float']['input']>;
  quantity: Scalars['Int']['input'];
  sale_id?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type SaleItemCreateManyProductInputEnvelope = {
  data?: InputMaybe<Array<SaleItemCreateManyProductInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SaleItemCreateManySaleInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  organization_id?: InputMaybe<Scalars['String']['input']>;
  prepayment?: InputMaybe<Scalars['Int']['input']>;
  price_override?: InputMaybe<Scalars['Float']['input']>;
  product_id?: InputMaybe<Scalars['String']['input']>;
  quantity: Scalars['Int']['input'];
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type SaleItemCreateManySaleInputEnvelope = {
  data?: InputMaybe<Array<SaleItemCreateManySaleInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SaleItemCreateNestedManyWithoutConcessionInput = {
  connect?: InputMaybe<Array<SaleItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SaleItemCreateOrConnectWithoutConcessionInput>>;
  create?: InputMaybe<Array<SaleItemCreateWithoutConcessionInput>>;
};

export type SaleItemCreateNestedManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<SaleItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SaleItemCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<SaleItemCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<SaleItemCreateManyOrganizationInputEnvelope>;
};

export type SaleItemCreateNestedManyWithoutProductInput = {
  connect?: InputMaybe<Array<SaleItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SaleItemCreateOrConnectWithoutProductInput>>;
  create?: InputMaybe<Array<SaleItemCreateWithoutProductInput>>;
  createMany?: InputMaybe<SaleItemCreateManyProductInputEnvelope>;
};

export type SaleItemCreateNestedManyWithoutSaleInput = {
  connect?: InputMaybe<Array<SaleItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SaleItemCreateOrConnectWithoutSaleInput>>;
  create?: InputMaybe<Array<SaleItemCreateWithoutSaleInput>>;
  createMany?: InputMaybe<SaleItemCreateManySaleInputEnvelope>;
};

export type SaleItemCreateOrConnectWithoutConcessionInput = {
  create: SaleItemCreateWithoutConcessionInput;
  where: SaleItemWhereUniqueInput;
};

export type SaleItemCreateOrConnectWithoutOrganizationInput = {
  create: SaleItemCreateWithoutOrganizationInput;
  where: SaleItemWhereUniqueInput;
};

export type SaleItemCreateOrConnectWithoutProductInput = {
  create: SaleItemCreateWithoutProductInput;
  where: SaleItemWhereUniqueInput;
};

export type SaleItemCreateOrConnectWithoutSaleInput = {
  create: SaleItemCreateWithoutSaleInput;
  where: SaleItemWhereUniqueInput;
};

export type SaleItemCreateWithoutConcessionInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  Organization?: InputMaybe<OrganizationCreateNestedOneWithoutSaleItemsInput>;
  prepayment?: InputMaybe<Scalars['Int']['input']>;
  price_override?: InputMaybe<Scalars['Float']['input']>;
  product?: InputMaybe<ProductCreateNestedOneWithoutSale_ItemsInput>;
  quantity: Scalars['Int']['input'];
  sale?: InputMaybe<SaleCreateNestedOneWithoutSale_ItemsInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type SaleItemCreateWithoutOrganizationInput = {
  concession?: InputMaybe<ConcessionCreateNestedManyWithoutSale_ItemsInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  prepayment?: InputMaybe<Scalars['Int']['input']>;
  price_override?: InputMaybe<Scalars['Float']['input']>;
  product?: InputMaybe<ProductCreateNestedOneWithoutSale_ItemsInput>;
  quantity: Scalars['Int']['input'];
  sale?: InputMaybe<SaleCreateNestedOneWithoutSale_ItemsInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type SaleItemCreateWithoutProductInput = {
  concession?: InputMaybe<ConcessionCreateNestedManyWithoutSale_ItemsInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  Organization?: InputMaybe<OrganizationCreateNestedOneWithoutSaleItemsInput>;
  prepayment?: InputMaybe<Scalars['Int']['input']>;
  price_override?: InputMaybe<Scalars['Float']['input']>;
  quantity: Scalars['Int']['input'];
  sale?: InputMaybe<SaleCreateNestedOneWithoutSale_ItemsInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type SaleItemCreateWithoutSaleInput = {
  concession?: InputMaybe<ConcessionCreateNestedManyWithoutSale_ItemsInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  Organization?: InputMaybe<OrganizationCreateNestedOneWithoutSaleItemsInput>;
  prepayment?: InputMaybe<Scalars['Int']['input']>;
  price_override?: InputMaybe<Scalars['Float']['input']>;
  product?: InputMaybe<ProductCreateNestedOneWithoutSale_ItemsInput>;
  quantity: Scalars['Int']['input'];
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type SaleItemListRelationFilter = {
  every?: InputMaybe<SaleItemWhereInput>;
  none?: InputMaybe<SaleItemWhereInput>;
  some?: InputMaybe<SaleItemWhereInput>;
};

export type SaleItemOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
  count?: InputMaybe<SortOrder>;
};

export type SaleItemScalarWhereInput = {
  AND?: InputMaybe<Array<SaleItemScalarWhereInput>>;
  created_at?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  NOT?: InputMaybe<Array<SaleItemScalarWhereInput>>;
  OR?: InputMaybe<Array<SaleItemScalarWhereInput>>;
  organization_id?: InputMaybe<StringNullableFilter>;
  prepayment?: InputMaybe<IntNullableFilter>;
  price_override?: InputMaybe<FloatNullableFilter>;
  product_id?: InputMaybe<StringNullableFilter>;
  quantity?: InputMaybe<IntFilter>;
  sale_id?: InputMaybe<StringNullableFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
};

export type SaleItemUpdateManyMutationInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  prepayment?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  price_override?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  quantity?: InputMaybe<IntFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type SaleItemUpdateManyWithoutConcessionInput = {
  connect?: InputMaybe<Array<SaleItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SaleItemCreateOrConnectWithoutConcessionInput>>;
  create?: InputMaybe<Array<SaleItemCreateWithoutConcessionInput>>;
  delete?: InputMaybe<Array<SaleItemWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<SaleItemScalarWhereInput>>;
  disconnect?: InputMaybe<Array<SaleItemWhereUniqueInput>>;
  set?: InputMaybe<Array<SaleItemWhereUniqueInput>>;
  update?: InputMaybe<Array<SaleItemUpdateWithWhereUniqueWithoutConcessionInput>>;
  updateMany?: InputMaybe<Array<SaleItemUpdateManyWithWhereWithoutConcessionInput>>;
  upsert?: InputMaybe<Array<SaleItemUpsertWithWhereUniqueWithoutConcessionInput>>;
};

export type SaleItemUpdateManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<SaleItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SaleItemCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<SaleItemCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<SaleItemCreateManyOrganizationInputEnvelope>;
  delete?: InputMaybe<Array<SaleItemWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<SaleItemScalarWhereInput>>;
  disconnect?: InputMaybe<Array<SaleItemWhereUniqueInput>>;
  set?: InputMaybe<Array<SaleItemWhereUniqueInput>>;
  update?: InputMaybe<Array<SaleItemUpdateWithWhereUniqueWithoutOrganizationInput>>;
  updateMany?: InputMaybe<Array<SaleItemUpdateManyWithWhereWithoutOrganizationInput>>;
  upsert?: InputMaybe<Array<SaleItemUpsertWithWhereUniqueWithoutOrganizationInput>>;
};

export type SaleItemUpdateManyWithoutProductInput = {
  connect?: InputMaybe<Array<SaleItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SaleItemCreateOrConnectWithoutProductInput>>;
  create?: InputMaybe<Array<SaleItemCreateWithoutProductInput>>;
  createMany?: InputMaybe<SaleItemCreateManyProductInputEnvelope>;
  delete?: InputMaybe<Array<SaleItemWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<SaleItemScalarWhereInput>>;
  disconnect?: InputMaybe<Array<SaleItemWhereUniqueInput>>;
  set?: InputMaybe<Array<SaleItemWhereUniqueInput>>;
  update?: InputMaybe<Array<SaleItemUpdateWithWhereUniqueWithoutProductInput>>;
  updateMany?: InputMaybe<Array<SaleItemUpdateManyWithWhereWithoutProductInput>>;
  upsert?: InputMaybe<Array<SaleItemUpsertWithWhereUniqueWithoutProductInput>>;
};

export type SaleItemUpdateManyWithoutSaleInput = {
  connect?: InputMaybe<Array<SaleItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SaleItemCreateOrConnectWithoutSaleInput>>;
  create?: InputMaybe<Array<SaleItemCreateWithoutSaleInput>>;
  createMany?: InputMaybe<SaleItemCreateManySaleInputEnvelope>;
  delete?: InputMaybe<Array<SaleItemWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<SaleItemScalarWhereInput>>;
  disconnect?: InputMaybe<Array<SaleItemWhereUniqueInput>>;
  set?: InputMaybe<Array<SaleItemWhereUniqueInput>>;
  update?: InputMaybe<Array<SaleItemUpdateWithWhereUniqueWithoutSaleInput>>;
  updateMany?: InputMaybe<Array<SaleItemUpdateManyWithWhereWithoutSaleInput>>;
  upsert?: InputMaybe<Array<SaleItemUpsertWithWhereUniqueWithoutSaleInput>>;
};

export type SaleItemUpdateManyWithWhereWithoutConcessionInput = {
  data: SaleItemUpdateManyMutationInput;
  where: SaleItemScalarWhereInput;
};

export type SaleItemUpdateManyWithWhereWithoutOrganizationInput = {
  data: SaleItemUpdateManyMutationInput;
  where: SaleItemScalarWhereInput;
};

export type SaleItemUpdateManyWithWhereWithoutProductInput = {
  data: SaleItemUpdateManyMutationInput;
  where: SaleItemScalarWhereInput;
};

export type SaleItemUpdateManyWithWhereWithoutSaleInput = {
  data: SaleItemUpdateManyMutationInput;
  where: SaleItemScalarWhereInput;
};

export type SaleItemUpdateWithoutConcessionInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  Organization?: InputMaybe<OrganizationUpdateOneWithoutSaleItemsInput>;
  prepayment?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  price_override?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  product?: InputMaybe<ProductUpdateOneWithoutSale_ItemsInput>;
  quantity?: InputMaybe<IntFieldUpdateOperationsInput>;
  sale?: InputMaybe<SaleUpdateOneWithoutSale_ItemsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type SaleItemUpdateWithoutOrganizationInput = {
  concession?: InputMaybe<ConcessionUpdateManyWithoutSale_ItemsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  prepayment?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  price_override?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  product?: InputMaybe<ProductUpdateOneWithoutSale_ItemsInput>;
  quantity?: InputMaybe<IntFieldUpdateOperationsInput>;
  sale?: InputMaybe<SaleUpdateOneWithoutSale_ItemsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type SaleItemUpdateWithoutProductInput = {
  concession?: InputMaybe<ConcessionUpdateManyWithoutSale_ItemsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  Organization?: InputMaybe<OrganizationUpdateOneWithoutSaleItemsInput>;
  prepayment?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  price_override?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  quantity?: InputMaybe<IntFieldUpdateOperationsInput>;
  sale?: InputMaybe<SaleUpdateOneWithoutSale_ItemsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type SaleItemUpdateWithoutSaleInput = {
  concession?: InputMaybe<ConcessionUpdateManyWithoutSale_ItemsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  Organization?: InputMaybe<OrganizationUpdateOneWithoutSaleItemsInput>;
  prepayment?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  price_override?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  product?: InputMaybe<ProductUpdateOneWithoutSale_ItemsInput>;
  quantity?: InputMaybe<IntFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type SaleItemUpdateWithWhereUniqueWithoutConcessionInput = {
  data: SaleItemUpdateWithoutConcessionInput;
  where: SaleItemWhereUniqueInput;
};

export type SaleItemUpdateWithWhereUniqueWithoutOrganizationInput = {
  data: SaleItemUpdateWithoutOrganizationInput;
  where: SaleItemWhereUniqueInput;
};

export type SaleItemUpdateWithWhereUniqueWithoutProductInput = {
  data: SaleItemUpdateWithoutProductInput;
  where: SaleItemWhereUniqueInput;
};

export type SaleItemUpdateWithWhereUniqueWithoutSaleInput = {
  data: SaleItemUpdateWithoutSaleInput;
  where: SaleItemWhereUniqueInput;
};

export type SaleItemUpsertWithWhereUniqueWithoutConcessionInput = {
  create: SaleItemCreateWithoutConcessionInput;
  update: SaleItemUpdateWithoutConcessionInput;
  where: SaleItemWhereUniqueInput;
};

export type SaleItemUpsertWithWhereUniqueWithoutOrganizationInput = {
  create: SaleItemCreateWithoutOrganizationInput;
  update: SaleItemUpdateWithoutOrganizationInput;
  where: SaleItemWhereUniqueInput;
};

export type SaleItemUpsertWithWhereUniqueWithoutProductInput = {
  create: SaleItemCreateWithoutProductInput;
  update: SaleItemUpdateWithoutProductInput;
  where: SaleItemWhereUniqueInput;
};

export type SaleItemUpsertWithWhereUniqueWithoutSaleInput = {
  create: SaleItemCreateWithoutSaleInput;
  update: SaleItemUpdateWithoutSaleInput;
  where: SaleItemWhereUniqueInput;
};

export type SaleItemWhereInput = {
  AND?: InputMaybe<Array<SaleItemWhereInput>>;
  concession?: InputMaybe<ConcessionListRelationFilter>;
  created_at?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  NOT?: InputMaybe<Array<SaleItemWhereInput>>;
  OR?: InputMaybe<Array<SaleItemWhereInput>>;
  Organization?: InputMaybe<OrganizationWhereInput>;
  organization_id?: InputMaybe<StringNullableFilter>;
  prepayment?: InputMaybe<IntNullableFilter>;
  price_override?: InputMaybe<FloatNullableFilter>;
  product?: InputMaybe<ProductWhereInput>;
  product_id?: InputMaybe<StringNullableFilter>;
  quantity?: InputMaybe<IntFilter>;
  sale?: InputMaybe<SaleWhereInput>;
  sale_id?: InputMaybe<StringNullableFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
};

export type SaleItemWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']['input']>;
};

export type SaleListRelationFilter = {
  every?: InputMaybe<SaleWhereInput>;
  none?: InputMaybe<SaleWhereInput>;
  some?: InputMaybe<SaleWhereInput>;
};

export type SaleOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
  count?: InputMaybe<SortOrder>;
};

export type SaleOrderByWithRelationInput = {
  commissions?: InputMaybe<CommissionOrderByRelationAggregateInput>;
  contract_duration?: InputMaybe<SortOrder>;
  converted_sequence?: InputMaybe<SequenceOrderByWithRelationInput>;
  converted_sequence_id?: InputMaybe<SortOrder>;
  created_at?: InputMaybe<SortOrder>;
  hubspot_sale_id?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  lead?: InputMaybe<LeadOrderByWithRelationInput>;
  lead_activity?: InputMaybe<LeadActivityOrderByWithRelationInput>;
  lead_activity_id?: InputMaybe<SortOrder>;
  lead_id?: InputMaybe<SortOrder>;
  notes?: InputMaybe<SortOrder>;
  organization?: InputMaybe<OrganizationOrderByWithRelationInput>;
  organization_id?: InputMaybe<SortOrder>;
  pandadoc_sale_id?: InputMaybe<SortOrder>;
  payment_terms?: InputMaybe<SortOrder>;
  prepayment?: InputMaybe<SortOrder>;
  sale_items?: InputMaybe<SaleItemOrderByRelationAggregateInput>;
  updated_at?: InputMaybe<SortOrder>;
};

export type SaleScalarWhereInput = {
  AND?: InputMaybe<Array<SaleScalarWhereInput>>;
  contract_duration?: InputMaybe<StringNullableFilter>;
  converted_sequence_id?: InputMaybe<StringNullableFilter>;
  created_at?: InputMaybe<DateTimeFilter>;
  hubspot_sale_id?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  lead_activity_id?: InputMaybe<StringNullableFilter>;
  lead_id?: InputMaybe<StringNullableFilter>;
  NOT?: InputMaybe<Array<SaleScalarWhereInput>>;
  notes?: InputMaybe<StringNullableFilter>;
  OR?: InputMaybe<Array<SaleScalarWhereInput>>;
  organization_id?: InputMaybe<StringNullableFilter>;
  pandadoc_sale_id?: InputMaybe<StringNullableFilter>;
  payment_terms?: InputMaybe<StringNullableFilter>;
  prepayment?: InputMaybe<IntNullableFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
};

/** SaleSectionEnumType */
export enum SaleSectionEnumType {
  CustomField = 'CUSTOM_FIELD',
  Header = 'HEADER',
  Link = 'LINK',
  Note = 'NOTE',
  Pandadoc = 'PANDADOC',
  SystemField = 'SYSTEM_FIELD',
  Warning = 'WARNING'
}

export enum SaleSectionType {
  CustomField = 'CUSTOM_FIELD',
  Header = 'HEADER',
  Link = 'LINK',
  Note = 'NOTE',
  Pandadoc = 'PANDADOC',
  SystemField = 'SYSTEM_FIELD',
  Warning = 'WARNING'
}

export type SalesForceColumn = {
  __typename?: 'SalesForceColumn';
  created_at: Scalars['DateTime']['output'];
  is_custom_field: Scalars['Boolean']['output'];
  opsiq_label: Scalars['String']['output'];
  opsiq_object: Scalars['String']['output'];
  organization: Organization;
  organization_id: Scalars['String']['output'];
  updated_at: Scalars['DateTime']['output'];
  use_opsiq_label_as_value: Scalars['Boolean']['output'];
};

export type SalesForceColumnCreateManyOrganizationInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  is_custom_field?: InputMaybe<Scalars['Boolean']['input']>;
  opsiq_label: Scalars['String']['input'];
  opsiq_object: Scalars['String']['input'];
  salesforce_field_label: Scalars['String']['input'];
  salesforce_field_type: Scalars['String']['input'];
  salesforce_object: Scalars['String']['input'];
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  use_opsiq_label_as_value?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SalesForceColumnCreateManyOrganizationInputEnvelope = {
  data?: InputMaybe<Array<SalesForceColumnCreateManyOrganizationInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SalesForceColumnCreateNestedManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<SalesForceColumnWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SalesForceColumnCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<SalesForceColumnCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<SalesForceColumnCreateManyOrganizationInputEnvelope>;
};

export type SalesForceColumnCreateOrConnectWithoutOrganizationInput = {
  create: SalesForceColumnCreateWithoutOrganizationInput;
  where: SalesForceColumnWhereUniqueInput;
};

export type SalesForceColumnCreateWithoutOrganizationInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  is_custom_field?: InputMaybe<Scalars['Boolean']['input']>;
  opsiq_label: Scalars['String']['input'];
  opsiq_object: Scalars['String']['input'];
  salesforce_field_label: Scalars['String']['input'];
  salesforce_field_type: Scalars['String']['input'];
  salesforce_object: Scalars['String']['input'];
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  use_opsiq_label_as_value?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SalesForceColumnListRelationFilter = {
  every?: InputMaybe<SalesForceColumnWhereInput>;
  none?: InputMaybe<SalesForceColumnWhereInput>;
  some?: InputMaybe<SalesForceColumnWhereInput>;
};

export type SalesForceColumnOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
  count?: InputMaybe<SortOrder>;
};

export type SalesForceColumnOrganization_IdSalesforce_ObjectSalesforce_Field_LabelCompoundUniqueInput = {
  organization_id: Scalars['String']['input'];
  salesforce_field_label: Scalars['String']['input'];
  salesforce_object: Scalars['String']['input'];
};

export type SalesForceColumnScalarWhereInput = {
  AND?: InputMaybe<Array<SalesForceColumnScalarWhereInput>>;
  created_at?: InputMaybe<DateTimeFilter>;
  is_custom_field?: InputMaybe<BoolFilter>;
  NOT?: InputMaybe<Array<SalesForceColumnScalarWhereInput>>;
  opsiq_label?: InputMaybe<StringFilter>;
  opsiq_object?: InputMaybe<StringFilter>;
  OR?: InputMaybe<Array<SalesForceColumnScalarWhereInput>>;
  organization_id?: InputMaybe<StringFilter>;
  salesforce_field_label?: InputMaybe<StringFilter>;
  salesforce_field_type?: InputMaybe<StringFilter>;
  salesforce_object?: InputMaybe<StringFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
  use_opsiq_label_as_value?: InputMaybe<BoolFilter>;
};

export type SalesForceColumnUpdateManyMutationInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  is_custom_field?: InputMaybe<BoolFieldUpdateOperationsInput>;
  opsiq_label?: InputMaybe<StringFieldUpdateOperationsInput>;
  opsiq_object?: InputMaybe<StringFieldUpdateOperationsInput>;
  salesforce_field_label?: InputMaybe<StringFieldUpdateOperationsInput>;
  salesforce_field_type?: InputMaybe<StringFieldUpdateOperationsInput>;
  salesforce_object?: InputMaybe<StringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  use_opsiq_label_as_value?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type SalesForceColumnUpdateManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<SalesForceColumnWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SalesForceColumnCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<SalesForceColumnCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<SalesForceColumnCreateManyOrganizationInputEnvelope>;
  delete?: InputMaybe<Array<SalesForceColumnWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<SalesForceColumnScalarWhereInput>>;
  disconnect?: InputMaybe<Array<SalesForceColumnWhereUniqueInput>>;
  set?: InputMaybe<Array<SalesForceColumnWhereUniqueInput>>;
  update?: InputMaybe<Array<SalesForceColumnUpdateWithWhereUniqueWithoutOrganizationInput>>;
  updateMany?: InputMaybe<Array<SalesForceColumnUpdateManyWithWhereWithoutOrganizationInput>>;
  upsert?: InputMaybe<Array<SalesForceColumnUpsertWithWhereUniqueWithoutOrganizationInput>>;
};

export type SalesForceColumnUpdateManyWithWhereWithoutOrganizationInput = {
  data: SalesForceColumnUpdateManyMutationInput;
  where: SalesForceColumnScalarWhereInput;
};

export type SalesForceColumnUpdateWithoutOrganizationInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  is_custom_field?: InputMaybe<BoolFieldUpdateOperationsInput>;
  opsiq_label?: InputMaybe<StringFieldUpdateOperationsInput>;
  opsiq_object?: InputMaybe<StringFieldUpdateOperationsInput>;
  salesforce_field_label?: InputMaybe<StringFieldUpdateOperationsInput>;
  salesforce_field_type?: InputMaybe<StringFieldUpdateOperationsInput>;
  salesforce_object?: InputMaybe<StringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  use_opsiq_label_as_value?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type SalesForceColumnUpdateWithWhereUniqueWithoutOrganizationInput = {
  data: SalesForceColumnUpdateWithoutOrganizationInput;
  where: SalesForceColumnWhereUniqueInput;
};

export type SalesForceColumnUpsertWithWhereUniqueWithoutOrganizationInput = {
  create: SalesForceColumnCreateWithoutOrganizationInput;
  update: SalesForceColumnUpdateWithoutOrganizationInput;
  where: SalesForceColumnWhereUniqueInput;
};

export type SalesForceColumnWhereInput = {
  AND?: InputMaybe<Array<SalesForceColumnWhereInput>>;
  created_at?: InputMaybe<DateTimeFilter>;
  is_custom_field?: InputMaybe<BoolFilter>;
  NOT?: InputMaybe<Array<SalesForceColumnWhereInput>>;
  opsiq_label?: InputMaybe<StringFilter>;
  opsiq_object?: InputMaybe<StringFilter>;
  OR?: InputMaybe<Array<SalesForceColumnWhereInput>>;
  organization?: InputMaybe<OrganizationWhereInput>;
  organization_id?: InputMaybe<StringFilter>;
  salesforce_field_label?: InputMaybe<StringFilter>;
  salesforce_field_type?: InputMaybe<StringFilter>;
  salesforce_object?: InputMaybe<StringFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
  use_opsiq_label_as_value?: InputMaybe<BoolFilter>;
};

export type SalesForceColumnWhereUniqueInput = {
  organization_id_salesforce_object_salesforce_field_label?: InputMaybe<SalesForceColumnOrganization_IdSalesforce_ObjectSalesforce_Field_LabelCompoundUniqueInput>;
};

export type SalesForceData = {
  __typename?: 'SalesForceData';
  access_token: Scalars['String']['output'];
  created_at: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  instance_url: Scalars['String']['output'];
  organization?: Maybe<Organization>;
  organization_id?: Maybe<Scalars['String']['output']>;
  price_book_entry_required: Scalars['Boolean']['output'];
  refresh_token: Scalars['String']['output'];
  updated_at: Scalars['DateTime']['output'];
};

export type SalesForceDataCreateNestedOneWithoutOrganizationInput = {
  connect?: InputMaybe<SalesForceDataWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SalesForceDataCreateOrConnectWithoutOrganizationInput>;
  create?: InputMaybe<SalesForceDataCreateWithoutOrganizationInput>;
};

export type SalesForceDataCreateOrConnectWithoutOrganizationInput = {
  create: SalesForceDataCreateWithoutOrganizationInput;
  where: SalesForceDataWhereUniqueInput;
};

export type SalesForceDataCreateWithoutOrganizationInput = {
  access_token: Scalars['String']['input'];
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  instance_url: Scalars['String']['input'];
  price_book_entry_required?: InputMaybe<Scalars['Boolean']['input']>;
  pricebook_id?: InputMaybe<Scalars['String']['input']>;
  refresh_token: Scalars['String']['input'];
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type SalesForceDataOrderByWithRelationInput = {
  access_token?: InputMaybe<SortOrder>;
  created_at?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  instance_url?: InputMaybe<SortOrder>;
  organization?: InputMaybe<OrganizationOrderByWithRelationInput>;
  organization_id?: InputMaybe<SortOrder>;
  price_book_entry_required?: InputMaybe<SortOrder>;
  pricebook_id?: InputMaybe<SortOrder>;
  refresh_token?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
};

export type SalesForceDataUpdateOneWithoutOrganizationInput = {
  connect?: InputMaybe<SalesForceDataWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SalesForceDataCreateOrConnectWithoutOrganizationInput>;
  create?: InputMaybe<SalesForceDataCreateWithoutOrganizationInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<SalesForceDataUpdateWithoutOrganizationInput>;
  upsert?: InputMaybe<SalesForceDataUpsertWithoutOrganizationInput>;
};

export type SalesForceDataUpdateWithoutOrganizationInput = {
  access_token?: InputMaybe<StringFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  instance_url?: InputMaybe<StringFieldUpdateOperationsInput>;
  price_book_entry_required?: InputMaybe<BoolFieldUpdateOperationsInput>;
  pricebook_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  refresh_token?: InputMaybe<StringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type SalesForceDataUpsertWithoutOrganizationInput = {
  create: SalesForceDataCreateWithoutOrganizationInput;
  update: SalesForceDataUpdateWithoutOrganizationInput;
};

export type SalesForceDataWhereInput = {
  access_token?: InputMaybe<StringFilter>;
  AND?: InputMaybe<Array<SalesForceDataWhereInput>>;
  created_at?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  instance_url?: InputMaybe<StringFilter>;
  NOT?: InputMaybe<Array<SalesForceDataWhereInput>>;
  OR?: InputMaybe<Array<SalesForceDataWhereInput>>;
  organization?: InputMaybe<OrganizationWhereInput>;
  organization_id?: InputMaybe<StringNullableFilter>;
  price_book_entry_required?: InputMaybe<BoolFilter>;
  pricebook_id?: InputMaybe<StringNullableFilter>;
  refresh_token?: InputMaybe<StringFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
};

export type SalesForceDataWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  organization_id?: InputMaybe<Scalars['String']['input']>;
};

export type SalesTeamColumnOptions = {
  __typename?: 'SalesTeamColumnOptions';
  label: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type SaleUpdateManyMutationInput = {
  contract_duration?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  hubspot_sale_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  notes?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  pandadoc_sale_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  payment_terms?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  prepayment?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type SaleUpdateManyWithoutConverted_SequenceInput = {
  connect?: InputMaybe<Array<SaleWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SaleCreateOrConnectWithoutConverted_SequenceInput>>;
  create?: InputMaybe<Array<SaleCreateWithoutConverted_SequenceInput>>;
  createMany?: InputMaybe<SaleCreateManyConverted_SequenceInputEnvelope>;
  delete?: InputMaybe<Array<SaleWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<SaleScalarWhereInput>>;
  disconnect?: InputMaybe<Array<SaleWhereUniqueInput>>;
  set?: InputMaybe<Array<SaleWhereUniqueInput>>;
  update?: InputMaybe<Array<SaleUpdateWithWhereUniqueWithoutConverted_SequenceInput>>;
  updateMany?: InputMaybe<Array<SaleUpdateManyWithWhereWithoutConverted_SequenceInput>>;
  upsert?: InputMaybe<Array<SaleUpsertWithWhereUniqueWithoutConverted_SequenceInput>>;
};

export type SaleUpdateManyWithoutLeadInput = {
  connect?: InputMaybe<Array<SaleWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SaleCreateOrConnectWithoutLeadInput>>;
  create?: InputMaybe<Array<SaleCreateWithoutLeadInput>>;
  createMany?: InputMaybe<SaleCreateManyLeadInputEnvelope>;
  delete?: InputMaybe<Array<SaleWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<SaleScalarWhereInput>>;
  disconnect?: InputMaybe<Array<SaleWhereUniqueInput>>;
  set?: InputMaybe<Array<SaleWhereUniqueInput>>;
  update?: InputMaybe<Array<SaleUpdateWithWhereUniqueWithoutLeadInput>>;
  updateMany?: InputMaybe<Array<SaleUpdateManyWithWhereWithoutLeadInput>>;
  upsert?: InputMaybe<Array<SaleUpsertWithWhereUniqueWithoutLeadInput>>;
};

export type SaleUpdateManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<SaleWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SaleCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<SaleCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<SaleCreateManyOrganizationInputEnvelope>;
  delete?: InputMaybe<Array<SaleWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<SaleScalarWhereInput>>;
  disconnect?: InputMaybe<Array<SaleWhereUniqueInput>>;
  set?: InputMaybe<Array<SaleWhereUniqueInput>>;
  update?: InputMaybe<Array<SaleUpdateWithWhereUniqueWithoutOrganizationInput>>;
  updateMany?: InputMaybe<Array<SaleUpdateManyWithWhereWithoutOrganizationInput>>;
  upsert?: InputMaybe<Array<SaleUpsertWithWhereUniqueWithoutOrganizationInput>>;
};

export type SaleUpdateManyWithWhereWithoutConverted_SequenceInput = {
  data: SaleUpdateManyMutationInput;
  where: SaleScalarWhereInput;
};

export type SaleUpdateManyWithWhereWithoutLeadInput = {
  data: SaleUpdateManyMutationInput;
  where: SaleScalarWhereInput;
};

export type SaleUpdateManyWithWhereWithoutOrganizationInput = {
  data: SaleUpdateManyMutationInput;
  where: SaleScalarWhereInput;
};

export type SaleUpdateOneWithoutCommissionsInput = {
  connect?: InputMaybe<SaleWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SaleCreateOrConnectWithoutCommissionsInput>;
  create?: InputMaybe<SaleCreateWithoutCommissionsInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<SaleUpdateWithoutCommissionsInput>;
  upsert?: InputMaybe<SaleUpsertWithoutCommissionsInput>;
};

export type SaleUpdateOneWithoutLead_ActivityInput = {
  connect?: InputMaybe<SaleWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SaleCreateOrConnectWithoutLead_ActivityInput>;
  create?: InputMaybe<SaleCreateWithoutLead_ActivityInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<SaleUpdateWithoutLead_ActivityInput>;
  upsert?: InputMaybe<SaleUpsertWithoutLead_ActivityInput>;
};

export type SaleUpdateOneWithoutSale_ItemsInput = {
  connect?: InputMaybe<SaleWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SaleCreateOrConnectWithoutSale_ItemsInput>;
  create?: InputMaybe<SaleCreateWithoutSale_ItemsInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<SaleUpdateWithoutSale_ItemsInput>;
  upsert?: InputMaybe<SaleUpsertWithoutSale_ItemsInput>;
};

export type SaleUpdateWithoutCommissionsInput = {
  contract_duration?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  converted_sequence?: InputMaybe<SequenceUpdateOneWithoutSaleInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  hubspot_sale_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lead?: InputMaybe<LeadUpdateOneWithoutSaleInput>;
  lead_activity?: InputMaybe<LeadActivityUpdateOneWithoutSaleInput>;
  notes?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneWithoutSalesInput>;
  pandadoc_sale_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  payment_terms?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  prepayment?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  sale_items?: InputMaybe<SaleItemUpdateManyWithoutSaleInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type SaleUpdateWithoutConverted_SequenceInput = {
  commissions?: InputMaybe<CommissionUpdateManyWithoutSaleInput>;
  contract_duration?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  hubspot_sale_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lead?: InputMaybe<LeadUpdateOneWithoutSaleInput>;
  lead_activity?: InputMaybe<LeadActivityUpdateOneWithoutSaleInput>;
  notes?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneWithoutSalesInput>;
  pandadoc_sale_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  payment_terms?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  prepayment?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  sale_items?: InputMaybe<SaleItemUpdateManyWithoutSaleInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type SaleUpdateWithoutLead_ActivityInput = {
  commissions?: InputMaybe<CommissionUpdateManyWithoutSaleInput>;
  contract_duration?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  converted_sequence?: InputMaybe<SequenceUpdateOneWithoutSaleInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  hubspot_sale_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lead?: InputMaybe<LeadUpdateOneWithoutSaleInput>;
  notes?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneWithoutSalesInput>;
  pandadoc_sale_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  payment_terms?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  prepayment?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  sale_items?: InputMaybe<SaleItemUpdateManyWithoutSaleInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type SaleUpdateWithoutLeadInput = {
  commissions?: InputMaybe<CommissionUpdateManyWithoutSaleInput>;
  contract_duration?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  converted_sequence?: InputMaybe<SequenceUpdateOneWithoutSaleInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  hubspot_sale_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lead_activity?: InputMaybe<LeadActivityUpdateOneWithoutSaleInput>;
  notes?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneWithoutSalesInput>;
  pandadoc_sale_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  payment_terms?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  prepayment?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  sale_items?: InputMaybe<SaleItemUpdateManyWithoutSaleInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type SaleUpdateWithoutOrganizationInput = {
  commissions?: InputMaybe<CommissionUpdateManyWithoutSaleInput>;
  contract_duration?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  converted_sequence?: InputMaybe<SequenceUpdateOneWithoutSaleInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  hubspot_sale_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lead?: InputMaybe<LeadUpdateOneWithoutSaleInput>;
  lead_activity?: InputMaybe<LeadActivityUpdateOneWithoutSaleInput>;
  notes?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  pandadoc_sale_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  payment_terms?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  prepayment?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  sale_items?: InputMaybe<SaleItemUpdateManyWithoutSaleInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type SaleUpdateWithoutSale_ItemsInput = {
  commissions?: InputMaybe<CommissionUpdateManyWithoutSaleInput>;
  contract_duration?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  converted_sequence?: InputMaybe<SequenceUpdateOneWithoutSaleInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  hubspot_sale_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lead?: InputMaybe<LeadUpdateOneWithoutSaleInput>;
  lead_activity?: InputMaybe<LeadActivityUpdateOneWithoutSaleInput>;
  notes?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneWithoutSalesInput>;
  pandadoc_sale_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  payment_terms?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  prepayment?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type SaleUpdateWithWhereUniqueWithoutConverted_SequenceInput = {
  data: SaleUpdateWithoutConverted_SequenceInput;
  where: SaleWhereUniqueInput;
};

export type SaleUpdateWithWhereUniqueWithoutLeadInput = {
  data: SaleUpdateWithoutLeadInput;
  where: SaleWhereUniqueInput;
};

export type SaleUpdateWithWhereUniqueWithoutOrganizationInput = {
  data: SaleUpdateWithoutOrganizationInput;
  where: SaleWhereUniqueInput;
};

export type SaleUpsertWithoutCommissionsInput = {
  create: SaleCreateWithoutCommissionsInput;
  update: SaleUpdateWithoutCommissionsInput;
};

export type SaleUpsertWithoutLead_ActivityInput = {
  create: SaleCreateWithoutLead_ActivityInput;
  update: SaleUpdateWithoutLead_ActivityInput;
};

export type SaleUpsertWithoutSale_ItemsInput = {
  create: SaleCreateWithoutSale_ItemsInput;
  update: SaleUpdateWithoutSale_ItemsInput;
};

export type SaleUpsertWithWhereUniqueWithoutConverted_SequenceInput = {
  create: SaleCreateWithoutConverted_SequenceInput;
  update: SaleUpdateWithoutConverted_SequenceInput;
  where: SaleWhereUniqueInput;
};

export type SaleUpsertWithWhereUniqueWithoutLeadInput = {
  create: SaleCreateWithoutLeadInput;
  update: SaleUpdateWithoutLeadInput;
  where: SaleWhereUniqueInput;
};

export type SaleUpsertWithWhereUniqueWithoutOrganizationInput = {
  create: SaleCreateWithoutOrganizationInput;
  update: SaleUpdateWithoutOrganizationInput;
  where: SaleWhereUniqueInput;
};

export type SaleWhereInput = {
  AND?: InputMaybe<Array<SaleWhereInput>>;
  commissions?: InputMaybe<CommissionListRelationFilter>;
  contract_duration?: InputMaybe<StringNullableFilter>;
  converted_sequence?: InputMaybe<SequenceWhereInput>;
  converted_sequence_id?: InputMaybe<StringNullableFilter>;
  created_at?: InputMaybe<DateTimeFilter>;
  hubspot_sale_id?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  lead?: InputMaybe<LeadWhereInput>;
  lead_activity?: InputMaybe<LeadActivityWhereInput>;
  lead_activity_id?: InputMaybe<StringNullableFilter>;
  lead_id?: InputMaybe<StringNullableFilter>;
  NOT?: InputMaybe<Array<SaleWhereInput>>;
  notes?: InputMaybe<StringNullableFilter>;
  OR?: InputMaybe<Array<SaleWhereInput>>;
  organization?: InputMaybe<OrganizationWhereInput>;
  organization_id?: InputMaybe<StringNullableFilter>;
  pandadoc_sale_id?: InputMaybe<StringNullableFilter>;
  payment_terms?: InputMaybe<StringNullableFilter>;
  prepayment?: InputMaybe<IntNullableFilter>;
  sale_items?: InputMaybe<SaleItemListRelationFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
};

export type SaleWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  lead_activity_id?: InputMaybe<Scalars['String']['input']>;
};

export type ScheduleItem = {
  __typename?: 'ScheduleItem';
  action_label?: Maybe<Scalars['String']['output']>;
  automated_campaigns: Array<AutomatedCampaign>;
  available_actions: Array<Scalars['String']['output']>;
  booking_id?: Maybe<Scalars['String']['output']>;
  campaign_activities: Array<LeadActivity>;
  deleted_at?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  end_time?: Maybe<Scalars['DateTime']['output']>;
  graph?: Maybe<Scalars['String']['output']>;
  guests: Array<InvitationMetadata>;
  id: Scalars['String']['output'];
  join_me_id?: Maybe<Scalars['String']['output']>;
  join_me_item?: Maybe<JoinMeItem>;
  Lead: Array<Lead>;
  lead_activity?: Maybe<LeadActivity>;
  lead_activity_id?: Maybe<Scalars['String']['output']>;
  lead_intent?: Maybe<LeadIntent>;
  movable: Scalars['Boolean']['output'];
  overflow_booking_id?: Maybe<Scalars['String']['output']>;
  resulting_activity?: Maybe<LeadActivity>;
  resulting_activity_id?: Maybe<Scalars['String']['output']>;
  start_time?: Maybe<Scalars['DateTime']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  timeoff_request?: Maybe<TimeOffRequest>;
  timezone?: Maybe<Scalars['String']['output']>;
  type?: Maybe<ScheduleItemType>;
  user?: Maybe<User>;
  user_id?: Maybe<Scalars['String']['output']>;
  what?: Maybe<Scalars['String']['output']>;
  where?: Maybe<Scalars['String']['output']>;
};


export type ScheduleItemAutomated_CampaignsArgs = {
  after?: InputMaybe<AutomatedCampaignWhereUniqueInput>;
  before?: InputMaybe<AutomatedCampaignWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type ScheduleItemCampaign_ActivitiesArgs = {
  after?: InputMaybe<LeadActivityWhereUniqueInput>;
  before?: InputMaybe<LeadActivityWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type ScheduleItemGuestsArgs = {
  after?: InputMaybe<InvitationMetadataWhereUniqueInput>;
  before?: InputMaybe<InvitationMetadataWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type ScheduleItemLeadArgs = {
  after?: InputMaybe<LeadWhereUniqueInput>;
  before?: InputMaybe<LeadWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type ScheduleItemCreateavailable_ActionsInput = {
  set?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type ScheduleItemCreateManyavailable_ActionsInput = {
  set?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type ScheduleItemCreateManyOrganizationInput = {
  action_label?: InputMaybe<Scalars['String']['input']>;
  available_actions?: InputMaybe<ScheduleItemCreateManyavailable_ActionsInput>;
  booking_id?: InputMaybe<Scalars['String']['input']>;
  calendar_invite?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  deleted_at?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  end_time?: InputMaybe<Scalars['DateTime']['input']>;
  graph?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  join_me_id?: InputMaybe<Scalars['String']['input']>;
  lead_activity_id?: InputMaybe<Scalars['String']['input']>;
  movable?: InputMaybe<Scalars['Boolean']['input']>;
  overflow_booking_id?: InputMaybe<Scalars['String']['input']>;
  resulting_activity_id?: InputMaybe<Scalars['String']['input']>;
  start_time?: InputMaybe<Scalars['DateTime']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<InvitationResult>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<ScheduleItemType>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
  what?: InputMaybe<Scalars['String']['input']>;
  where?: InputMaybe<Scalars['String']['input']>;
};

export type ScheduleItemCreateManyOrganizationInputEnvelope = {
  data?: InputMaybe<Array<ScheduleItemCreateManyOrganizationInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ScheduleItemCreateManyUserInput = {
  action_label?: InputMaybe<Scalars['String']['input']>;
  available_actions?: InputMaybe<ScheduleItemCreateManyavailable_ActionsInput>;
  booking_id?: InputMaybe<Scalars['String']['input']>;
  calendar_invite?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  deleted_at?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  end_time?: InputMaybe<Scalars['DateTime']['input']>;
  graph?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  join_me_id?: InputMaybe<Scalars['String']['input']>;
  lead_activity_id?: InputMaybe<Scalars['String']['input']>;
  movable?: InputMaybe<Scalars['Boolean']['input']>;
  organization_id?: InputMaybe<Scalars['String']['input']>;
  overflow_booking_id?: InputMaybe<Scalars['String']['input']>;
  resulting_activity_id?: InputMaybe<Scalars['String']['input']>;
  start_time?: InputMaybe<Scalars['DateTime']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<InvitationResult>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<ScheduleItemType>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  what?: InputMaybe<Scalars['String']['input']>;
  where?: InputMaybe<Scalars['String']['input']>;
};

export type ScheduleItemCreateManyUserInputEnvelope = {
  data?: InputMaybe<Array<ScheduleItemCreateManyUserInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ScheduleItemCreateNestedManyWithoutAutomated_CampaignsInput = {
  connect?: InputMaybe<Array<ScheduleItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ScheduleItemCreateOrConnectWithoutAutomated_CampaignsInput>>;
  create?: InputMaybe<Array<ScheduleItemCreateWithoutAutomated_CampaignsInput>>;
};

export type ScheduleItemCreateNestedManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<ScheduleItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ScheduleItemCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<ScheduleItemCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<ScheduleItemCreateManyOrganizationInputEnvelope>;
};

export type ScheduleItemCreateNestedManyWithoutUserInput = {
  connect?: InputMaybe<Array<ScheduleItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ScheduleItemCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<ScheduleItemCreateWithoutUserInput>>;
  createMany?: InputMaybe<ScheduleItemCreateManyUserInputEnvelope>;
};

export type ScheduleItemCreateNestedOneWithoutCampaign_ActivitiesInput = {
  connect?: InputMaybe<ScheduleItemWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ScheduleItemCreateOrConnectWithoutCampaign_ActivitiesInput>;
  create?: InputMaybe<ScheduleItemCreateWithoutCampaign_ActivitiesInput>;
};

export type ScheduleItemCreateNestedOneWithoutLead_ActivityInput = {
  connect?: InputMaybe<ScheduleItemWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ScheduleItemCreateOrConnectWithoutLead_ActivityInput>;
  create?: InputMaybe<ScheduleItemCreateWithoutLead_ActivityInput>;
};

export type ScheduleItemCreateNestedOneWithoutLead_IntentInput = {
  connect?: InputMaybe<ScheduleItemWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ScheduleItemCreateOrConnectWithoutLead_IntentInput>;
  create?: InputMaybe<ScheduleItemCreateWithoutLead_IntentInput>;
};

export type ScheduleItemCreateNestedOneWithoutLeadInput = {
  connect?: InputMaybe<ScheduleItemWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ScheduleItemCreateOrConnectWithoutLeadInput>;
  create?: InputMaybe<ScheduleItemCreateWithoutLeadInput>;
};

export type ScheduleItemCreateNestedOneWithoutResulting_ActivityInput = {
  connect?: InputMaybe<ScheduleItemWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ScheduleItemCreateOrConnectWithoutResulting_ActivityInput>;
  create?: InputMaybe<ScheduleItemCreateWithoutResulting_ActivityInput>;
};

export type ScheduleItemCreateNestedOneWithoutStat_Item_AggregationInput = {
  connect?: InputMaybe<ScheduleItemWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ScheduleItemCreateOrConnectWithoutStat_Item_AggregationInput>;
  create?: InputMaybe<ScheduleItemCreateWithoutStat_Item_AggregationInput>;
};

export type ScheduleItemCreateNestedOneWithoutTimeoff_RequestInput = {
  connect?: InputMaybe<ScheduleItemWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ScheduleItemCreateOrConnectWithoutTimeoff_RequestInput>;
  create?: InputMaybe<ScheduleItemCreateWithoutTimeoff_RequestInput>;
};

export type ScheduleItemCreateOrConnectWithoutAutomated_CampaignsInput = {
  create: ScheduleItemCreateWithoutAutomated_CampaignsInput;
  where: ScheduleItemWhereUniqueInput;
};

export type ScheduleItemCreateOrConnectWithoutCampaign_ActivitiesInput = {
  create: ScheduleItemCreateWithoutCampaign_ActivitiesInput;
  where: ScheduleItemWhereUniqueInput;
};

export type ScheduleItemCreateOrConnectWithoutLead_ActivityInput = {
  create: ScheduleItemCreateWithoutLead_ActivityInput;
  where: ScheduleItemWhereUniqueInput;
};

export type ScheduleItemCreateOrConnectWithoutLead_IntentInput = {
  create: ScheduleItemCreateWithoutLead_IntentInput;
  where: ScheduleItemWhereUniqueInput;
};

export type ScheduleItemCreateOrConnectWithoutLeadInput = {
  create: ScheduleItemCreateWithoutLeadInput;
  where: ScheduleItemWhereUniqueInput;
};

export type ScheduleItemCreateOrConnectWithoutOrganizationInput = {
  create: ScheduleItemCreateWithoutOrganizationInput;
  where: ScheduleItemWhereUniqueInput;
};

export type ScheduleItemCreateOrConnectWithoutResulting_ActivityInput = {
  create: ScheduleItemCreateWithoutResulting_ActivityInput;
  where: ScheduleItemWhereUniqueInput;
};

export type ScheduleItemCreateOrConnectWithoutStat_Item_AggregationInput = {
  create: ScheduleItemCreateWithoutStat_Item_AggregationInput;
  where: ScheduleItemWhereUniqueInput;
};

export type ScheduleItemCreateOrConnectWithoutTimeoff_RequestInput = {
  create: ScheduleItemCreateWithoutTimeoff_RequestInput;
  where: ScheduleItemWhereUniqueInput;
};

export type ScheduleItemCreateOrConnectWithoutUserInput = {
  create: ScheduleItemCreateWithoutUserInput;
  where: ScheduleItemWhereUniqueInput;
};

export type ScheduleItemCreateWithoutAutomated_CampaignsInput = {
  action_label?: InputMaybe<Scalars['String']['input']>;
  available_actions?: InputMaybe<ScheduleItemCreateavailable_ActionsInput>;
  booking_id?: InputMaybe<Scalars['String']['input']>;
  calendar_invite?: InputMaybe<Scalars['Boolean']['input']>;
  campaign_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutCampaign_EventInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  deleted_at?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  end_time?: InputMaybe<Scalars['DateTime']['input']>;
  graph?: InputMaybe<Scalars['String']['input']>;
  guests?: InputMaybe<InvitationMetadataCreateNestedManyWithoutScheduled_ItemInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  join_me_id?: InputMaybe<Scalars['String']['input']>;
  join_me_item?: InputMaybe<JoinMeItemCreateNestedOneWithoutSchedule_ItemInput>;
  Lead?: InputMaybe<LeadCreateNestedManyWithoutNext_Scheduled_EventInput>;
  lead_activity?: InputMaybe<LeadActivityCreateNestedOneWithoutSchedule_ItemInput>;
  lead_activity_id?: InputMaybe<Scalars['String']['input']>;
  lead_intent?: InputMaybe<LeadIntentCreateNestedOneWithoutSchedule_ItemInput>;
  movable?: InputMaybe<Scalars['Boolean']['input']>;
  Organization?: InputMaybe<OrganizationCreateNestedOneWithoutScheduleItemsInput>;
  overflow_booking_id?: InputMaybe<Scalars['String']['input']>;
  resulting_activity?: InputMaybe<LeadActivityCreateNestedOneWithoutOriginating_Schedule_ItemInput>;
  start_time?: InputMaybe<Scalars['DateTime']['input']>;
  stat_item_aggregation?: InputMaybe<StatItemAggregationCreateNestedOneWithoutScheduled_ItemInput>;
  state?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<InvitationResult>;
  timeoff_request?: InputMaybe<TimeOffRequestCreateNestedOneWithoutSchedule_ItemInput>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<ScheduleItemType>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user?: InputMaybe<UserCreateNestedOneWithoutSchedule_ItemsInput>;
  what?: InputMaybe<Scalars['String']['input']>;
  where?: InputMaybe<Scalars['String']['input']>;
};

export type ScheduleItemCreateWithoutCampaign_ActivitiesInput = {
  action_label?: InputMaybe<Scalars['String']['input']>;
  automated_campaigns?: InputMaybe<AutomatedCampaignCreateNestedManyWithoutSchedule_ItemsInput>;
  available_actions?: InputMaybe<ScheduleItemCreateavailable_ActionsInput>;
  booking_id?: InputMaybe<Scalars['String']['input']>;
  calendar_invite?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  deleted_at?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  end_time?: InputMaybe<Scalars['DateTime']['input']>;
  graph?: InputMaybe<Scalars['String']['input']>;
  guests?: InputMaybe<InvitationMetadataCreateNestedManyWithoutScheduled_ItemInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  join_me_id?: InputMaybe<Scalars['String']['input']>;
  join_me_item?: InputMaybe<JoinMeItemCreateNestedOneWithoutSchedule_ItemInput>;
  Lead?: InputMaybe<LeadCreateNestedManyWithoutNext_Scheduled_EventInput>;
  lead_activity?: InputMaybe<LeadActivityCreateNestedOneWithoutSchedule_ItemInput>;
  lead_activity_id?: InputMaybe<Scalars['String']['input']>;
  lead_intent?: InputMaybe<LeadIntentCreateNestedOneWithoutSchedule_ItemInput>;
  movable?: InputMaybe<Scalars['Boolean']['input']>;
  Organization?: InputMaybe<OrganizationCreateNestedOneWithoutScheduleItemsInput>;
  overflow_booking_id?: InputMaybe<Scalars['String']['input']>;
  resulting_activity?: InputMaybe<LeadActivityCreateNestedOneWithoutOriginating_Schedule_ItemInput>;
  start_time?: InputMaybe<Scalars['DateTime']['input']>;
  stat_item_aggregation?: InputMaybe<StatItemAggregationCreateNestedOneWithoutScheduled_ItemInput>;
  state?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<InvitationResult>;
  timeoff_request?: InputMaybe<TimeOffRequestCreateNestedOneWithoutSchedule_ItemInput>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<ScheduleItemType>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user?: InputMaybe<UserCreateNestedOneWithoutSchedule_ItemsInput>;
  what?: InputMaybe<Scalars['String']['input']>;
  where?: InputMaybe<Scalars['String']['input']>;
};

export type ScheduleItemCreateWithoutLead_ActivityInput = {
  action_label?: InputMaybe<Scalars['String']['input']>;
  automated_campaigns?: InputMaybe<AutomatedCampaignCreateNestedManyWithoutSchedule_ItemsInput>;
  available_actions?: InputMaybe<ScheduleItemCreateavailable_ActionsInput>;
  booking_id?: InputMaybe<Scalars['String']['input']>;
  calendar_invite?: InputMaybe<Scalars['Boolean']['input']>;
  campaign_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutCampaign_EventInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  deleted_at?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  end_time?: InputMaybe<Scalars['DateTime']['input']>;
  graph?: InputMaybe<Scalars['String']['input']>;
  guests?: InputMaybe<InvitationMetadataCreateNestedManyWithoutScheduled_ItemInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  join_me_id?: InputMaybe<Scalars['String']['input']>;
  join_me_item?: InputMaybe<JoinMeItemCreateNestedOneWithoutSchedule_ItemInput>;
  Lead?: InputMaybe<LeadCreateNestedManyWithoutNext_Scheduled_EventInput>;
  lead_activity_id?: InputMaybe<Scalars['String']['input']>;
  lead_intent?: InputMaybe<LeadIntentCreateNestedOneWithoutSchedule_ItemInput>;
  movable?: InputMaybe<Scalars['Boolean']['input']>;
  Organization?: InputMaybe<OrganizationCreateNestedOneWithoutScheduleItemsInput>;
  overflow_booking_id?: InputMaybe<Scalars['String']['input']>;
  resulting_activity?: InputMaybe<LeadActivityCreateNestedOneWithoutOriginating_Schedule_ItemInput>;
  start_time?: InputMaybe<Scalars['DateTime']['input']>;
  stat_item_aggregation?: InputMaybe<StatItemAggregationCreateNestedOneWithoutScheduled_ItemInput>;
  state?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<InvitationResult>;
  timeoff_request?: InputMaybe<TimeOffRequestCreateNestedOneWithoutSchedule_ItemInput>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<ScheduleItemType>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user?: InputMaybe<UserCreateNestedOneWithoutSchedule_ItemsInput>;
  what?: InputMaybe<Scalars['String']['input']>;
  where?: InputMaybe<Scalars['String']['input']>;
};

export type ScheduleItemCreateWithoutLead_IntentInput = {
  action_label?: InputMaybe<Scalars['String']['input']>;
  automated_campaigns?: InputMaybe<AutomatedCampaignCreateNestedManyWithoutSchedule_ItemsInput>;
  available_actions?: InputMaybe<ScheduleItemCreateavailable_ActionsInput>;
  booking_id?: InputMaybe<Scalars['String']['input']>;
  calendar_invite?: InputMaybe<Scalars['Boolean']['input']>;
  campaign_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutCampaign_EventInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  deleted_at?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  end_time?: InputMaybe<Scalars['DateTime']['input']>;
  graph?: InputMaybe<Scalars['String']['input']>;
  guests?: InputMaybe<InvitationMetadataCreateNestedManyWithoutScheduled_ItemInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  join_me_id?: InputMaybe<Scalars['String']['input']>;
  join_me_item?: InputMaybe<JoinMeItemCreateNestedOneWithoutSchedule_ItemInput>;
  Lead?: InputMaybe<LeadCreateNestedManyWithoutNext_Scheduled_EventInput>;
  lead_activity?: InputMaybe<LeadActivityCreateNestedOneWithoutSchedule_ItemInput>;
  lead_activity_id?: InputMaybe<Scalars['String']['input']>;
  movable?: InputMaybe<Scalars['Boolean']['input']>;
  Organization?: InputMaybe<OrganizationCreateNestedOneWithoutScheduleItemsInput>;
  overflow_booking_id?: InputMaybe<Scalars['String']['input']>;
  resulting_activity?: InputMaybe<LeadActivityCreateNestedOneWithoutOriginating_Schedule_ItemInput>;
  start_time?: InputMaybe<Scalars['DateTime']['input']>;
  stat_item_aggregation?: InputMaybe<StatItemAggregationCreateNestedOneWithoutScheduled_ItemInput>;
  state?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<InvitationResult>;
  timeoff_request?: InputMaybe<TimeOffRequestCreateNestedOneWithoutSchedule_ItemInput>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<ScheduleItemType>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user?: InputMaybe<UserCreateNestedOneWithoutSchedule_ItemsInput>;
  what?: InputMaybe<Scalars['String']['input']>;
  where?: InputMaybe<Scalars['String']['input']>;
};

export type ScheduleItemCreateWithoutLeadInput = {
  action_label?: InputMaybe<Scalars['String']['input']>;
  automated_campaigns?: InputMaybe<AutomatedCampaignCreateNestedManyWithoutSchedule_ItemsInput>;
  available_actions?: InputMaybe<ScheduleItemCreateavailable_ActionsInput>;
  booking_id?: InputMaybe<Scalars['String']['input']>;
  calendar_invite?: InputMaybe<Scalars['Boolean']['input']>;
  campaign_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutCampaign_EventInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  deleted_at?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  end_time?: InputMaybe<Scalars['DateTime']['input']>;
  graph?: InputMaybe<Scalars['String']['input']>;
  guests?: InputMaybe<InvitationMetadataCreateNestedManyWithoutScheduled_ItemInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  join_me_id?: InputMaybe<Scalars['String']['input']>;
  join_me_item?: InputMaybe<JoinMeItemCreateNestedOneWithoutSchedule_ItemInput>;
  lead_activity?: InputMaybe<LeadActivityCreateNestedOneWithoutSchedule_ItemInput>;
  lead_activity_id?: InputMaybe<Scalars['String']['input']>;
  lead_intent?: InputMaybe<LeadIntentCreateNestedOneWithoutSchedule_ItemInput>;
  movable?: InputMaybe<Scalars['Boolean']['input']>;
  Organization?: InputMaybe<OrganizationCreateNestedOneWithoutScheduleItemsInput>;
  overflow_booking_id?: InputMaybe<Scalars['String']['input']>;
  resulting_activity?: InputMaybe<LeadActivityCreateNestedOneWithoutOriginating_Schedule_ItemInput>;
  start_time?: InputMaybe<Scalars['DateTime']['input']>;
  stat_item_aggregation?: InputMaybe<StatItemAggregationCreateNestedOneWithoutScheduled_ItemInput>;
  state?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<InvitationResult>;
  timeoff_request?: InputMaybe<TimeOffRequestCreateNestedOneWithoutSchedule_ItemInput>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<ScheduleItemType>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user?: InputMaybe<UserCreateNestedOneWithoutSchedule_ItemsInput>;
  what?: InputMaybe<Scalars['String']['input']>;
  where?: InputMaybe<Scalars['String']['input']>;
};

export type ScheduleItemCreateWithoutOrganizationInput = {
  action_label?: InputMaybe<Scalars['String']['input']>;
  automated_campaigns?: InputMaybe<AutomatedCampaignCreateNestedManyWithoutSchedule_ItemsInput>;
  available_actions?: InputMaybe<ScheduleItemCreateavailable_ActionsInput>;
  booking_id?: InputMaybe<Scalars['String']['input']>;
  calendar_invite?: InputMaybe<Scalars['Boolean']['input']>;
  campaign_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutCampaign_EventInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  deleted_at?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  end_time?: InputMaybe<Scalars['DateTime']['input']>;
  graph?: InputMaybe<Scalars['String']['input']>;
  guests?: InputMaybe<InvitationMetadataCreateNestedManyWithoutScheduled_ItemInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  join_me_id?: InputMaybe<Scalars['String']['input']>;
  join_me_item?: InputMaybe<JoinMeItemCreateNestedOneWithoutSchedule_ItemInput>;
  Lead?: InputMaybe<LeadCreateNestedManyWithoutNext_Scheduled_EventInput>;
  lead_activity?: InputMaybe<LeadActivityCreateNestedOneWithoutSchedule_ItemInput>;
  lead_activity_id?: InputMaybe<Scalars['String']['input']>;
  lead_intent?: InputMaybe<LeadIntentCreateNestedOneWithoutSchedule_ItemInput>;
  movable?: InputMaybe<Scalars['Boolean']['input']>;
  overflow_booking_id?: InputMaybe<Scalars['String']['input']>;
  resulting_activity?: InputMaybe<LeadActivityCreateNestedOneWithoutOriginating_Schedule_ItemInput>;
  start_time?: InputMaybe<Scalars['DateTime']['input']>;
  stat_item_aggregation?: InputMaybe<StatItemAggregationCreateNestedOneWithoutScheduled_ItemInput>;
  state?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<InvitationResult>;
  timeoff_request?: InputMaybe<TimeOffRequestCreateNestedOneWithoutSchedule_ItemInput>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<ScheduleItemType>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user?: InputMaybe<UserCreateNestedOneWithoutSchedule_ItemsInput>;
  what?: InputMaybe<Scalars['String']['input']>;
  where?: InputMaybe<Scalars['String']['input']>;
};

export type ScheduleItemCreateWithoutResulting_ActivityInput = {
  action_label?: InputMaybe<Scalars['String']['input']>;
  automated_campaigns?: InputMaybe<AutomatedCampaignCreateNestedManyWithoutSchedule_ItemsInput>;
  available_actions?: InputMaybe<ScheduleItemCreateavailable_ActionsInput>;
  booking_id?: InputMaybe<Scalars['String']['input']>;
  calendar_invite?: InputMaybe<Scalars['Boolean']['input']>;
  campaign_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutCampaign_EventInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  deleted_at?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  end_time?: InputMaybe<Scalars['DateTime']['input']>;
  graph?: InputMaybe<Scalars['String']['input']>;
  guests?: InputMaybe<InvitationMetadataCreateNestedManyWithoutScheduled_ItemInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  join_me_id?: InputMaybe<Scalars['String']['input']>;
  join_me_item?: InputMaybe<JoinMeItemCreateNestedOneWithoutSchedule_ItemInput>;
  Lead?: InputMaybe<LeadCreateNestedManyWithoutNext_Scheduled_EventInput>;
  lead_activity?: InputMaybe<LeadActivityCreateNestedOneWithoutSchedule_ItemInput>;
  lead_activity_id?: InputMaybe<Scalars['String']['input']>;
  lead_intent?: InputMaybe<LeadIntentCreateNestedOneWithoutSchedule_ItemInput>;
  movable?: InputMaybe<Scalars['Boolean']['input']>;
  Organization?: InputMaybe<OrganizationCreateNestedOneWithoutScheduleItemsInput>;
  overflow_booking_id?: InputMaybe<Scalars['String']['input']>;
  start_time?: InputMaybe<Scalars['DateTime']['input']>;
  stat_item_aggregation?: InputMaybe<StatItemAggregationCreateNestedOneWithoutScheduled_ItemInput>;
  state?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<InvitationResult>;
  timeoff_request?: InputMaybe<TimeOffRequestCreateNestedOneWithoutSchedule_ItemInput>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<ScheduleItemType>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user?: InputMaybe<UserCreateNestedOneWithoutSchedule_ItemsInput>;
  what?: InputMaybe<Scalars['String']['input']>;
  where?: InputMaybe<Scalars['String']['input']>;
};

export type ScheduleItemCreateWithoutStat_Item_AggregationInput = {
  action_label?: InputMaybe<Scalars['String']['input']>;
  automated_campaigns?: InputMaybe<AutomatedCampaignCreateNestedManyWithoutSchedule_ItemsInput>;
  available_actions?: InputMaybe<ScheduleItemCreateavailable_ActionsInput>;
  booking_id?: InputMaybe<Scalars['String']['input']>;
  calendar_invite?: InputMaybe<Scalars['Boolean']['input']>;
  campaign_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutCampaign_EventInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  deleted_at?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  end_time?: InputMaybe<Scalars['DateTime']['input']>;
  graph?: InputMaybe<Scalars['String']['input']>;
  guests?: InputMaybe<InvitationMetadataCreateNestedManyWithoutScheduled_ItemInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  join_me_id?: InputMaybe<Scalars['String']['input']>;
  join_me_item?: InputMaybe<JoinMeItemCreateNestedOneWithoutSchedule_ItemInput>;
  Lead?: InputMaybe<LeadCreateNestedManyWithoutNext_Scheduled_EventInput>;
  lead_activity?: InputMaybe<LeadActivityCreateNestedOneWithoutSchedule_ItemInput>;
  lead_activity_id?: InputMaybe<Scalars['String']['input']>;
  lead_intent?: InputMaybe<LeadIntentCreateNestedOneWithoutSchedule_ItemInput>;
  movable?: InputMaybe<Scalars['Boolean']['input']>;
  Organization?: InputMaybe<OrganizationCreateNestedOneWithoutScheduleItemsInput>;
  overflow_booking_id?: InputMaybe<Scalars['String']['input']>;
  resulting_activity?: InputMaybe<LeadActivityCreateNestedOneWithoutOriginating_Schedule_ItemInput>;
  start_time?: InputMaybe<Scalars['DateTime']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<InvitationResult>;
  timeoff_request?: InputMaybe<TimeOffRequestCreateNestedOneWithoutSchedule_ItemInput>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<ScheduleItemType>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user?: InputMaybe<UserCreateNestedOneWithoutSchedule_ItemsInput>;
  what?: InputMaybe<Scalars['String']['input']>;
  where?: InputMaybe<Scalars['String']['input']>;
};

export type ScheduleItemCreateWithoutTimeoff_RequestInput = {
  action_label?: InputMaybe<Scalars['String']['input']>;
  automated_campaigns?: InputMaybe<AutomatedCampaignCreateNestedManyWithoutSchedule_ItemsInput>;
  available_actions?: InputMaybe<ScheduleItemCreateavailable_ActionsInput>;
  booking_id?: InputMaybe<Scalars['String']['input']>;
  calendar_invite?: InputMaybe<Scalars['Boolean']['input']>;
  campaign_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutCampaign_EventInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  deleted_at?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  end_time?: InputMaybe<Scalars['DateTime']['input']>;
  graph?: InputMaybe<Scalars['String']['input']>;
  guests?: InputMaybe<InvitationMetadataCreateNestedManyWithoutScheduled_ItemInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  join_me_id?: InputMaybe<Scalars['String']['input']>;
  join_me_item?: InputMaybe<JoinMeItemCreateNestedOneWithoutSchedule_ItemInput>;
  Lead?: InputMaybe<LeadCreateNestedManyWithoutNext_Scheduled_EventInput>;
  lead_activity?: InputMaybe<LeadActivityCreateNestedOneWithoutSchedule_ItemInput>;
  lead_activity_id?: InputMaybe<Scalars['String']['input']>;
  lead_intent?: InputMaybe<LeadIntentCreateNestedOneWithoutSchedule_ItemInput>;
  movable?: InputMaybe<Scalars['Boolean']['input']>;
  Organization?: InputMaybe<OrganizationCreateNestedOneWithoutScheduleItemsInput>;
  overflow_booking_id?: InputMaybe<Scalars['String']['input']>;
  resulting_activity?: InputMaybe<LeadActivityCreateNestedOneWithoutOriginating_Schedule_ItemInput>;
  start_time?: InputMaybe<Scalars['DateTime']['input']>;
  stat_item_aggregation?: InputMaybe<StatItemAggregationCreateNestedOneWithoutScheduled_ItemInput>;
  state?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<InvitationResult>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<ScheduleItemType>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user?: InputMaybe<UserCreateNestedOneWithoutSchedule_ItemsInput>;
  what?: InputMaybe<Scalars['String']['input']>;
  where?: InputMaybe<Scalars['String']['input']>;
};

export type ScheduleItemCreateWithoutUserInput = {
  action_label?: InputMaybe<Scalars['String']['input']>;
  automated_campaigns?: InputMaybe<AutomatedCampaignCreateNestedManyWithoutSchedule_ItemsInput>;
  available_actions?: InputMaybe<ScheduleItemCreateavailable_ActionsInput>;
  booking_id?: InputMaybe<Scalars['String']['input']>;
  calendar_invite?: InputMaybe<Scalars['Boolean']['input']>;
  campaign_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutCampaign_EventInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  deleted_at?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  end_time?: InputMaybe<Scalars['DateTime']['input']>;
  graph?: InputMaybe<Scalars['String']['input']>;
  guests?: InputMaybe<InvitationMetadataCreateNestedManyWithoutScheduled_ItemInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  join_me_id?: InputMaybe<Scalars['String']['input']>;
  join_me_item?: InputMaybe<JoinMeItemCreateNestedOneWithoutSchedule_ItemInput>;
  Lead?: InputMaybe<LeadCreateNestedManyWithoutNext_Scheduled_EventInput>;
  lead_activity?: InputMaybe<LeadActivityCreateNestedOneWithoutSchedule_ItemInput>;
  lead_activity_id?: InputMaybe<Scalars['String']['input']>;
  lead_intent?: InputMaybe<LeadIntentCreateNestedOneWithoutSchedule_ItemInput>;
  movable?: InputMaybe<Scalars['Boolean']['input']>;
  Organization?: InputMaybe<OrganizationCreateNestedOneWithoutScheduleItemsInput>;
  overflow_booking_id?: InputMaybe<Scalars['String']['input']>;
  resulting_activity?: InputMaybe<LeadActivityCreateNestedOneWithoutOriginating_Schedule_ItemInput>;
  start_time?: InputMaybe<Scalars['DateTime']['input']>;
  stat_item_aggregation?: InputMaybe<StatItemAggregationCreateNestedOneWithoutScheduled_ItemInput>;
  state?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<InvitationResult>;
  timeoff_request?: InputMaybe<TimeOffRequestCreateNestedOneWithoutSchedule_ItemInput>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<ScheduleItemType>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  what?: InputMaybe<Scalars['String']['input']>;
  where?: InputMaybe<Scalars['String']['input']>;
};

export type ScheduleItemHistory = {
  __typename?: 'ScheduleItemHistory';
  dml_timestamp: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  new_end_time?: Maybe<Scalars['DateTime']['output']>;
  new_start_time?: Maybe<Scalars['DateTime']['output']>;
  old_end_time?: Maybe<Scalars['DateTime']['output']>;
  old_start_time?: Maybe<Scalars['DateTime']['output']>;
  schedule_item_id: Scalars['String']['output'];
  user_id?: Maybe<Scalars['String']['output']>;
};

export type ScheduleItemListRelationFilter = {
  every?: InputMaybe<ScheduleItemWhereInput>;
  none?: InputMaybe<ScheduleItemWhereInput>;
  some?: InputMaybe<ScheduleItemWhereInput>;
};

export type ScheduleItemOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
  count?: InputMaybe<SortOrder>;
};

export type ScheduleItemOrderByWithRelationInput = {
  action_label?: InputMaybe<SortOrder>;
  automated_campaigns?: InputMaybe<AutomatedCampaignOrderByRelationAggregateInput>;
  available_actions?: InputMaybe<SortOrder>;
  booking_id?: InputMaybe<SortOrder>;
  calendar_invite?: InputMaybe<SortOrder>;
  campaign_activities?: InputMaybe<LeadActivityOrderByRelationAggregateInput>;
  created_at?: InputMaybe<SortOrder>;
  deleted_at?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  end_time?: InputMaybe<SortOrder>;
  graph?: InputMaybe<SortOrder>;
  guests?: InputMaybe<InvitationMetadataOrderByRelationAggregateInput>;
  id?: InputMaybe<SortOrder>;
  join_me_id?: InputMaybe<SortOrder>;
  join_me_item?: InputMaybe<JoinMeItemOrderByWithRelationInput>;
  Lead?: InputMaybe<LeadOrderByRelationAggregateInput>;
  lead_activity?: InputMaybe<LeadActivityOrderByWithRelationInput>;
  lead_activity_id?: InputMaybe<SortOrder>;
  lead_intent?: InputMaybe<LeadIntentOrderByWithRelationInput>;
  movable?: InputMaybe<SortOrder>;
  Organization?: InputMaybe<OrganizationOrderByWithRelationInput>;
  organization_id?: InputMaybe<SortOrder>;
  overflow_booking_id?: InputMaybe<SortOrder>;
  resulting_activity?: InputMaybe<LeadActivityOrderByWithRelationInput>;
  resulting_activity_id?: InputMaybe<SortOrder>;
  start_time?: InputMaybe<SortOrder>;
  stat_item_aggregation?: InputMaybe<StatItemAggregationOrderByWithRelationInput>;
  state?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  timeoff_request?: InputMaybe<TimeOffRequestOrderByWithRelationInput>;
  timezone?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserOrderByWithRelationInput>;
  user_id?: InputMaybe<SortOrder>;
  what?: InputMaybe<SortOrder>;
  where?: InputMaybe<SortOrder>;
};

export type ScheduleItemScalarWhereInput = {
  action_label?: InputMaybe<StringNullableFilter>;
  AND?: InputMaybe<Array<ScheduleItemScalarWhereInput>>;
  available_actions?: InputMaybe<StringNullableListFilter>;
  booking_id?: InputMaybe<StringNullableFilter>;
  calendar_invite?: InputMaybe<BoolNullableFilter>;
  created_at?: InputMaybe<DateTimeFilter>;
  deleted_at?: InputMaybe<DateTimeNullableFilter>;
  description?: InputMaybe<StringNullableFilter>;
  end_time?: InputMaybe<DateTimeNullableFilter>;
  graph?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  join_me_id?: InputMaybe<StringNullableFilter>;
  lead_activity_id?: InputMaybe<StringNullableFilter>;
  movable?: InputMaybe<BoolFilter>;
  NOT?: InputMaybe<Array<ScheduleItemScalarWhereInput>>;
  OR?: InputMaybe<Array<ScheduleItemScalarWhereInput>>;
  organization_id?: InputMaybe<StringNullableFilter>;
  overflow_booking_id?: InputMaybe<StringNullableFilter>;
  resulting_activity_id?: InputMaybe<StringNullableFilter>;
  start_time?: InputMaybe<DateTimeNullableFilter>;
  state?: InputMaybe<StringNullableFilter>;
  status?: InputMaybe<EnumInvitationResultFilter>;
  timezone?: InputMaybe<StringNullableFilter>;
  type?: InputMaybe<EnumScheduleItemTypeNullableFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
  user_id?: InputMaybe<StringNullableFilter>;
  what?: InputMaybe<StringNullableFilter>;
  where?: InputMaybe<StringNullableFilter>;
};

export enum ScheduleItemType {
  DecisionCall = 'DecisionCall',
  Demo = 'Demo',
  FollowUpDemo = 'FollowUpDemo',
  RescheduleDemo = 'RescheduleDemo',
  ScheduleCallback = 'ScheduleCallback',
  ScheduledEvent = 'ScheduledEvent'
}

export type ScheduleItemUpdateavailable_ActionsInput = {
  push?: InputMaybe<Scalars['String']['input']>;
  set?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type ScheduleItemUpdateManyMutationInput = {
  action_label?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  available_actions?: InputMaybe<ScheduleItemUpdateavailable_ActionsInput>;
  booking_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  calendar_invite?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deleted_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  end_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  graph?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  join_me_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  lead_activity_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  movable?: InputMaybe<BoolFieldUpdateOperationsInput>;
  overflow_booking_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  start_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  state?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumInvitationResultFieldUpdateOperationsInput>;
  timezone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  type?: InputMaybe<NullableEnumScheduleItemTypeFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  what?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  where?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type ScheduleItemUpdateManyWithoutAutomated_CampaignsInput = {
  connect?: InputMaybe<Array<ScheduleItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ScheduleItemCreateOrConnectWithoutAutomated_CampaignsInput>>;
  create?: InputMaybe<Array<ScheduleItemCreateWithoutAutomated_CampaignsInput>>;
  delete?: InputMaybe<Array<ScheduleItemWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ScheduleItemScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ScheduleItemWhereUniqueInput>>;
  set?: InputMaybe<Array<ScheduleItemWhereUniqueInput>>;
  update?: InputMaybe<Array<ScheduleItemUpdateWithWhereUniqueWithoutAutomated_CampaignsInput>>;
  updateMany?: InputMaybe<Array<ScheduleItemUpdateManyWithWhereWithoutAutomated_CampaignsInput>>;
  upsert?: InputMaybe<Array<ScheduleItemUpsertWithWhereUniqueWithoutAutomated_CampaignsInput>>;
};

export type ScheduleItemUpdateManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<ScheduleItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ScheduleItemCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<ScheduleItemCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<ScheduleItemCreateManyOrganizationInputEnvelope>;
  delete?: InputMaybe<Array<ScheduleItemWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ScheduleItemScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ScheduleItemWhereUniqueInput>>;
  set?: InputMaybe<Array<ScheduleItemWhereUniqueInput>>;
  update?: InputMaybe<Array<ScheduleItemUpdateWithWhereUniqueWithoutOrganizationInput>>;
  updateMany?: InputMaybe<Array<ScheduleItemUpdateManyWithWhereWithoutOrganizationInput>>;
  upsert?: InputMaybe<Array<ScheduleItemUpsertWithWhereUniqueWithoutOrganizationInput>>;
};

export type ScheduleItemUpdateManyWithoutUserInput = {
  connect?: InputMaybe<Array<ScheduleItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ScheduleItemCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<ScheduleItemCreateWithoutUserInput>>;
  createMany?: InputMaybe<ScheduleItemCreateManyUserInputEnvelope>;
  delete?: InputMaybe<Array<ScheduleItemWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ScheduleItemScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ScheduleItemWhereUniqueInput>>;
  set?: InputMaybe<Array<ScheduleItemWhereUniqueInput>>;
  update?: InputMaybe<Array<ScheduleItemUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: InputMaybe<Array<ScheduleItemUpdateManyWithWhereWithoutUserInput>>;
  upsert?: InputMaybe<Array<ScheduleItemUpsertWithWhereUniqueWithoutUserInput>>;
};

export type ScheduleItemUpdateManyWithWhereWithoutAutomated_CampaignsInput = {
  data: ScheduleItemUpdateManyMutationInput;
  where: ScheduleItemScalarWhereInput;
};

export type ScheduleItemUpdateManyWithWhereWithoutOrganizationInput = {
  data: ScheduleItemUpdateManyMutationInput;
  where: ScheduleItemScalarWhereInput;
};

export type ScheduleItemUpdateManyWithWhereWithoutUserInput = {
  data: ScheduleItemUpdateManyMutationInput;
  where: ScheduleItemScalarWhereInput;
};

export type ScheduleItemUpdateOneWithoutCampaign_ActivitiesInput = {
  connect?: InputMaybe<ScheduleItemWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ScheduleItemCreateOrConnectWithoutCampaign_ActivitiesInput>;
  create?: InputMaybe<ScheduleItemCreateWithoutCampaign_ActivitiesInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<ScheduleItemUpdateWithoutCampaign_ActivitiesInput>;
  upsert?: InputMaybe<ScheduleItemUpsertWithoutCampaign_ActivitiesInput>;
};

export type ScheduleItemUpdateOneWithoutLead_ActivityInput = {
  connect?: InputMaybe<ScheduleItemWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ScheduleItemCreateOrConnectWithoutLead_ActivityInput>;
  create?: InputMaybe<ScheduleItemCreateWithoutLead_ActivityInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<ScheduleItemUpdateWithoutLead_ActivityInput>;
  upsert?: InputMaybe<ScheduleItemUpsertWithoutLead_ActivityInput>;
};

export type ScheduleItemUpdateOneWithoutLead_IntentInput = {
  connect?: InputMaybe<ScheduleItemWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ScheduleItemCreateOrConnectWithoutLead_IntentInput>;
  create?: InputMaybe<ScheduleItemCreateWithoutLead_IntentInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<ScheduleItemUpdateWithoutLead_IntentInput>;
  upsert?: InputMaybe<ScheduleItemUpsertWithoutLead_IntentInput>;
};

export type ScheduleItemUpdateOneWithoutLeadInput = {
  connect?: InputMaybe<ScheduleItemWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ScheduleItemCreateOrConnectWithoutLeadInput>;
  create?: InputMaybe<ScheduleItemCreateWithoutLeadInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<ScheduleItemUpdateWithoutLeadInput>;
  upsert?: InputMaybe<ScheduleItemUpsertWithoutLeadInput>;
};

export type ScheduleItemUpdateOneWithoutResulting_ActivityInput = {
  connect?: InputMaybe<ScheduleItemWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ScheduleItemCreateOrConnectWithoutResulting_ActivityInput>;
  create?: InputMaybe<ScheduleItemCreateWithoutResulting_ActivityInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<ScheduleItemUpdateWithoutResulting_ActivityInput>;
  upsert?: InputMaybe<ScheduleItemUpsertWithoutResulting_ActivityInput>;
};

export type ScheduleItemUpdateOneWithoutStat_Item_AggregationInput = {
  connect?: InputMaybe<ScheduleItemWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ScheduleItemCreateOrConnectWithoutStat_Item_AggregationInput>;
  create?: InputMaybe<ScheduleItemCreateWithoutStat_Item_AggregationInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<ScheduleItemUpdateWithoutStat_Item_AggregationInput>;
  upsert?: InputMaybe<ScheduleItemUpsertWithoutStat_Item_AggregationInput>;
};

export type ScheduleItemUpdateOneWithoutTimeoff_RequestInput = {
  connect?: InputMaybe<ScheduleItemWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ScheduleItemCreateOrConnectWithoutTimeoff_RequestInput>;
  create?: InputMaybe<ScheduleItemCreateWithoutTimeoff_RequestInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<ScheduleItemUpdateWithoutTimeoff_RequestInput>;
  upsert?: InputMaybe<ScheduleItemUpsertWithoutTimeoff_RequestInput>;
};

export type ScheduleItemUpdateWithoutAutomated_CampaignsInput = {
  action_label?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  available_actions?: InputMaybe<ScheduleItemUpdateavailable_ActionsInput>;
  booking_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  calendar_invite?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  campaign_activities?: InputMaybe<LeadActivityUpdateManyWithoutCampaign_EventInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deleted_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  end_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  graph?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  guests?: InputMaybe<InvitationMetadataUpdateManyWithoutScheduled_ItemInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  join_me_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  join_me_item?: InputMaybe<JoinMeItemUpdateOneWithoutSchedule_ItemInput>;
  Lead?: InputMaybe<LeadUpdateManyWithoutNext_Scheduled_EventInput>;
  lead_activity?: InputMaybe<LeadActivityUpdateOneWithoutSchedule_ItemInput>;
  lead_activity_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  lead_intent?: InputMaybe<LeadIntentUpdateOneWithoutSchedule_ItemInput>;
  movable?: InputMaybe<BoolFieldUpdateOperationsInput>;
  Organization?: InputMaybe<OrganizationUpdateOneWithoutScheduleItemsInput>;
  overflow_booking_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  resulting_activity?: InputMaybe<LeadActivityUpdateOneWithoutOriginating_Schedule_ItemInput>;
  start_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  stat_item_aggregation?: InputMaybe<StatItemAggregationUpdateOneWithoutScheduled_ItemInput>;
  state?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumInvitationResultFieldUpdateOperationsInput>;
  timeoff_request?: InputMaybe<TimeOffRequestUpdateOneWithoutSchedule_ItemInput>;
  timezone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  type?: InputMaybe<NullableEnumScheduleItemTypeFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneWithoutSchedule_ItemsInput>;
  what?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  where?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type ScheduleItemUpdateWithoutCampaign_ActivitiesInput = {
  action_label?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  automated_campaigns?: InputMaybe<AutomatedCampaignUpdateManyWithoutSchedule_ItemsInput>;
  available_actions?: InputMaybe<ScheduleItemUpdateavailable_ActionsInput>;
  booking_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  calendar_invite?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deleted_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  end_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  graph?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  guests?: InputMaybe<InvitationMetadataUpdateManyWithoutScheduled_ItemInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  join_me_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  join_me_item?: InputMaybe<JoinMeItemUpdateOneWithoutSchedule_ItemInput>;
  Lead?: InputMaybe<LeadUpdateManyWithoutNext_Scheduled_EventInput>;
  lead_activity?: InputMaybe<LeadActivityUpdateOneWithoutSchedule_ItemInput>;
  lead_activity_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  lead_intent?: InputMaybe<LeadIntentUpdateOneWithoutSchedule_ItemInput>;
  movable?: InputMaybe<BoolFieldUpdateOperationsInput>;
  Organization?: InputMaybe<OrganizationUpdateOneWithoutScheduleItemsInput>;
  overflow_booking_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  resulting_activity?: InputMaybe<LeadActivityUpdateOneWithoutOriginating_Schedule_ItemInput>;
  start_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  stat_item_aggregation?: InputMaybe<StatItemAggregationUpdateOneWithoutScheduled_ItemInput>;
  state?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumInvitationResultFieldUpdateOperationsInput>;
  timeoff_request?: InputMaybe<TimeOffRequestUpdateOneWithoutSchedule_ItemInput>;
  timezone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  type?: InputMaybe<NullableEnumScheduleItemTypeFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneWithoutSchedule_ItemsInput>;
  what?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  where?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type ScheduleItemUpdateWithoutLead_ActivityInput = {
  action_label?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  automated_campaigns?: InputMaybe<AutomatedCampaignUpdateManyWithoutSchedule_ItemsInput>;
  available_actions?: InputMaybe<ScheduleItemUpdateavailable_ActionsInput>;
  booking_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  calendar_invite?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  campaign_activities?: InputMaybe<LeadActivityUpdateManyWithoutCampaign_EventInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deleted_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  end_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  graph?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  guests?: InputMaybe<InvitationMetadataUpdateManyWithoutScheduled_ItemInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  join_me_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  join_me_item?: InputMaybe<JoinMeItemUpdateOneWithoutSchedule_ItemInput>;
  Lead?: InputMaybe<LeadUpdateManyWithoutNext_Scheduled_EventInput>;
  lead_activity_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  lead_intent?: InputMaybe<LeadIntentUpdateOneWithoutSchedule_ItemInput>;
  movable?: InputMaybe<BoolFieldUpdateOperationsInput>;
  Organization?: InputMaybe<OrganizationUpdateOneWithoutScheduleItemsInput>;
  overflow_booking_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  resulting_activity?: InputMaybe<LeadActivityUpdateOneWithoutOriginating_Schedule_ItemInput>;
  start_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  stat_item_aggregation?: InputMaybe<StatItemAggregationUpdateOneWithoutScheduled_ItemInput>;
  state?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumInvitationResultFieldUpdateOperationsInput>;
  timeoff_request?: InputMaybe<TimeOffRequestUpdateOneWithoutSchedule_ItemInput>;
  timezone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  type?: InputMaybe<NullableEnumScheduleItemTypeFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneWithoutSchedule_ItemsInput>;
  what?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  where?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type ScheduleItemUpdateWithoutLead_IntentInput = {
  action_label?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  automated_campaigns?: InputMaybe<AutomatedCampaignUpdateManyWithoutSchedule_ItemsInput>;
  available_actions?: InputMaybe<ScheduleItemUpdateavailable_ActionsInput>;
  booking_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  calendar_invite?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  campaign_activities?: InputMaybe<LeadActivityUpdateManyWithoutCampaign_EventInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deleted_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  end_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  graph?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  guests?: InputMaybe<InvitationMetadataUpdateManyWithoutScheduled_ItemInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  join_me_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  join_me_item?: InputMaybe<JoinMeItemUpdateOneWithoutSchedule_ItemInput>;
  Lead?: InputMaybe<LeadUpdateManyWithoutNext_Scheduled_EventInput>;
  lead_activity?: InputMaybe<LeadActivityUpdateOneWithoutSchedule_ItemInput>;
  lead_activity_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  movable?: InputMaybe<BoolFieldUpdateOperationsInput>;
  Organization?: InputMaybe<OrganizationUpdateOneWithoutScheduleItemsInput>;
  overflow_booking_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  resulting_activity?: InputMaybe<LeadActivityUpdateOneWithoutOriginating_Schedule_ItemInput>;
  start_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  stat_item_aggregation?: InputMaybe<StatItemAggregationUpdateOneWithoutScheduled_ItemInput>;
  state?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumInvitationResultFieldUpdateOperationsInput>;
  timeoff_request?: InputMaybe<TimeOffRequestUpdateOneWithoutSchedule_ItemInput>;
  timezone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  type?: InputMaybe<NullableEnumScheduleItemTypeFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneWithoutSchedule_ItemsInput>;
  what?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  where?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type ScheduleItemUpdateWithoutLeadInput = {
  action_label?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  automated_campaigns?: InputMaybe<AutomatedCampaignUpdateManyWithoutSchedule_ItemsInput>;
  available_actions?: InputMaybe<ScheduleItemUpdateavailable_ActionsInput>;
  booking_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  calendar_invite?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  campaign_activities?: InputMaybe<LeadActivityUpdateManyWithoutCampaign_EventInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deleted_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  end_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  graph?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  guests?: InputMaybe<InvitationMetadataUpdateManyWithoutScheduled_ItemInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  join_me_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  join_me_item?: InputMaybe<JoinMeItemUpdateOneWithoutSchedule_ItemInput>;
  lead_activity?: InputMaybe<LeadActivityUpdateOneWithoutSchedule_ItemInput>;
  lead_activity_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  lead_intent?: InputMaybe<LeadIntentUpdateOneWithoutSchedule_ItemInput>;
  movable?: InputMaybe<BoolFieldUpdateOperationsInput>;
  Organization?: InputMaybe<OrganizationUpdateOneWithoutScheduleItemsInput>;
  overflow_booking_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  resulting_activity?: InputMaybe<LeadActivityUpdateOneWithoutOriginating_Schedule_ItemInput>;
  start_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  stat_item_aggregation?: InputMaybe<StatItemAggregationUpdateOneWithoutScheduled_ItemInput>;
  state?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumInvitationResultFieldUpdateOperationsInput>;
  timeoff_request?: InputMaybe<TimeOffRequestUpdateOneWithoutSchedule_ItemInput>;
  timezone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  type?: InputMaybe<NullableEnumScheduleItemTypeFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneWithoutSchedule_ItemsInput>;
  what?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  where?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type ScheduleItemUpdateWithoutOrganizationInput = {
  action_label?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  automated_campaigns?: InputMaybe<AutomatedCampaignUpdateManyWithoutSchedule_ItemsInput>;
  available_actions?: InputMaybe<ScheduleItemUpdateavailable_ActionsInput>;
  booking_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  calendar_invite?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  campaign_activities?: InputMaybe<LeadActivityUpdateManyWithoutCampaign_EventInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deleted_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  end_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  graph?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  guests?: InputMaybe<InvitationMetadataUpdateManyWithoutScheduled_ItemInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  join_me_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  join_me_item?: InputMaybe<JoinMeItemUpdateOneWithoutSchedule_ItemInput>;
  Lead?: InputMaybe<LeadUpdateManyWithoutNext_Scheduled_EventInput>;
  lead_activity?: InputMaybe<LeadActivityUpdateOneWithoutSchedule_ItemInput>;
  lead_activity_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  lead_intent?: InputMaybe<LeadIntentUpdateOneWithoutSchedule_ItemInput>;
  movable?: InputMaybe<BoolFieldUpdateOperationsInput>;
  overflow_booking_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  resulting_activity?: InputMaybe<LeadActivityUpdateOneWithoutOriginating_Schedule_ItemInput>;
  start_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  stat_item_aggregation?: InputMaybe<StatItemAggregationUpdateOneWithoutScheduled_ItemInput>;
  state?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumInvitationResultFieldUpdateOperationsInput>;
  timeoff_request?: InputMaybe<TimeOffRequestUpdateOneWithoutSchedule_ItemInput>;
  timezone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  type?: InputMaybe<NullableEnumScheduleItemTypeFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneWithoutSchedule_ItemsInput>;
  what?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  where?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type ScheduleItemUpdateWithoutResulting_ActivityInput = {
  action_label?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  automated_campaigns?: InputMaybe<AutomatedCampaignUpdateManyWithoutSchedule_ItemsInput>;
  available_actions?: InputMaybe<ScheduleItemUpdateavailable_ActionsInput>;
  booking_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  calendar_invite?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  campaign_activities?: InputMaybe<LeadActivityUpdateManyWithoutCampaign_EventInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deleted_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  end_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  graph?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  guests?: InputMaybe<InvitationMetadataUpdateManyWithoutScheduled_ItemInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  join_me_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  join_me_item?: InputMaybe<JoinMeItemUpdateOneWithoutSchedule_ItemInput>;
  Lead?: InputMaybe<LeadUpdateManyWithoutNext_Scheduled_EventInput>;
  lead_activity?: InputMaybe<LeadActivityUpdateOneWithoutSchedule_ItemInput>;
  lead_activity_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  lead_intent?: InputMaybe<LeadIntentUpdateOneWithoutSchedule_ItemInput>;
  movable?: InputMaybe<BoolFieldUpdateOperationsInput>;
  Organization?: InputMaybe<OrganizationUpdateOneWithoutScheduleItemsInput>;
  overflow_booking_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  start_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  stat_item_aggregation?: InputMaybe<StatItemAggregationUpdateOneWithoutScheduled_ItemInput>;
  state?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumInvitationResultFieldUpdateOperationsInput>;
  timeoff_request?: InputMaybe<TimeOffRequestUpdateOneWithoutSchedule_ItemInput>;
  timezone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  type?: InputMaybe<NullableEnumScheduleItemTypeFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneWithoutSchedule_ItemsInput>;
  what?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  where?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type ScheduleItemUpdateWithoutStat_Item_AggregationInput = {
  action_label?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  automated_campaigns?: InputMaybe<AutomatedCampaignUpdateManyWithoutSchedule_ItemsInput>;
  available_actions?: InputMaybe<ScheduleItemUpdateavailable_ActionsInput>;
  booking_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  calendar_invite?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  campaign_activities?: InputMaybe<LeadActivityUpdateManyWithoutCampaign_EventInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deleted_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  end_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  graph?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  guests?: InputMaybe<InvitationMetadataUpdateManyWithoutScheduled_ItemInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  join_me_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  join_me_item?: InputMaybe<JoinMeItemUpdateOneWithoutSchedule_ItemInput>;
  Lead?: InputMaybe<LeadUpdateManyWithoutNext_Scheduled_EventInput>;
  lead_activity?: InputMaybe<LeadActivityUpdateOneWithoutSchedule_ItemInput>;
  lead_activity_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  lead_intent?: InputMaybe<LeadIntentUpdateOneWithoutSchedule_ItemInput>;
  movable?: InputMaybe<BoolFieldUpdateOperationsInput>;
  Organization?: InputMaybe<OrganizationUpdateOneWithoutScheduleItemsInput>;
  overflow_booking_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  resulting_activity?: InputMaybe<LeadActivityUpdateOneWithoutOriginating_Schedule_ItemInput>;
  start_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  state?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumInvitationResultFieldUpdateOperationsInput>;
  timeoff_request?: InputMaybe<TimeOffRequestUpdateOneWithoutSchedule_ItemInput>;
  timezone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  type?: InputMaybe<NullableEnumScheduleItemTypeFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneWithoutSchedule_ItemsInput>;
  what?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  where?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type ScheduleItemUpdateWithoutTimeoff_RequestInput = {
  action_label?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  automated_campaigns?: InputMaybe<AutomatedCampaignUpdateManyWithoutSchedule_ItemsInput>;
  available_actions?: InputMaybe<ScheduleItemUpdateavailable_ActionsInput>;
  booking_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  calendar_invite?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  campaign_activities?: InputMaybe<LeadActivityUpdateManyWithoutCampaign_EventInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deleted_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  end_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  graph?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  guests?: InputMaybe<InvitationMetadataUpdateManyWithoutScheduled_ItemInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  join_me_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  join_me_item?: InputMaybe<JoinMeItemUpdateOneWithoutSchedule_ItemInput>;
  Lead?: InputMaybe<LeadUpdateManyWithoutNext_Scheduled_EventInput>;
  lead_activity?: InputMaybe<LeadActivityUpdateOneWithoutSchedule_ItemInput>;
  lead_activity_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  lead_intent?: InputMaybe<LeadIntentUpdateOneWithoutSchedule_ItemInput>;
  movable?: InputMaybe<BoolFieldUpdateOperationsInput>;
  Organization?: InputMaybe<OrganizationUpdateOneWithoutScheduleItemsInput>;
  overflow_booking_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  resulting_activity?: InputMaybe<LeadActivityUpdateOneWithoutOriginating_Schedule_ItemInput>;
  start_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  stat_item_aggregation?: InputMaybe<StatItemAggregationUpdateOneWithoutScheduled_ItemInput>;
  state?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumInvitationResultFieldUpdateOperationsInput>;
  timezone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  type?: InputMaybe<NullableEnumScheduleItemTypeFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneWithoutSchedule_ItemsInput>;
  what?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  where?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type ScheduleItemUpdateWithoutUserInput = {
  action_label?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  automated_campaigns?: InputMaybe<AutomatedCampaignUpdateManyWithoutSchedule_ItemsInput>;
  available_actions?: InputMaybe<ScheduleItemUpdateavailable_ActionsInput>;
  booking_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  calendar_invite?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  campaign_activities?: InputMaybe<LeadActivityUpdateManyWithoutCampaign_EventInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deleted_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  end_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  graph?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  guests?: InputMaybe<InvitationMetadataUpdateManyWithoutScheduled_ItemInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  join_me_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  join_me_item?: InputMaybe<JoinMeItemUpdateOneWithoutSchedule_ItemInput>;
  Lead?: InputMaybe<LeadUpdateManyWithoutNext_Scheduled_EventInput>;
  lead_activity?: InputMaybe<LeadActivityUpdateOneWithoutSchedule_ItemInput>;
  lead_activity_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  lead_intent?: InputMaybe<LeadIntentUpdateOneWithoutSchedule_ItemInput>;
  movable?: InputMaybe<BoolFieldUpdateOperationsInput>;
  Organization?: InputMaybe<OrganizationUpdateOneWithoutScheduleItemsInput>;
  overflow_booking_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  resulting_activity?: InputMaybe<LeadActivityUpdateOneWithoutOriginating_Schedule_ItemInput>;
  start_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  stat_item_aggregation?: InputMaybe<StatItemAggregationUpdateOneWithoutScheduled_ItemInput>;
  state?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumInvitationResultFieldUpdateOperationsInput>;
  timeoff_request?: InputMaybe<TimeOffRequestUpdateOneWithoutSchedule_ItemInput>;
  timezone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  type?: InputMaybe<NullableEnumScheduleItemTypeFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  what?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  where?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type ScheduleItemUpdateWithWhereUniqueWithoutAutomated_CampaignsInput = {
  data: ScheduleItemUpdateWithoutAutomated_CampaignsInput;
  where: ScheduleItemWhereUniqueInput;
};

export type ScheduleItemUpdateWithWhereUniqueWithoutOrganizationInput = {
  data: ScheduleItemUpdateWithoutOrganizationInput;
  where: ScheduleItemWhereUniqueInput;
};

export type ScheduleItemUpdateWithWhereUniqueWithoutUserInput = {
  data: ScheduleItemUpdateWithoutUserInput;
  where: ScheduleItemWhereUniqueInput;
};

export type ScheduleItemUpsertWithoutCampaign_ActivitiesInput = {
  create: ScheduleItemCreateWithoutCampaign_ActivitiesInput;
  update: ScheduleItemUpdateWithoutCampaign_ActivitiesInput;
};

export type ScheduleItemUpsertWithoutLead_ActivityInput = {
  create: ScheduleItemCreateWithoutLead_ActivityInput;
  update: ScheduleItemUpdateWithoutLead_ActivityInput;
};

export type ScheduleItemUpsertWithoutLead_IntentInput = {
  create: ScheduleItemCreateWithoutLead_IntentInput;
  update: ScheduleItemUpdateWithoutLead_IntentInput;
};

export type ScheduleItemUpsertWithoutLeadInput = {
  create: ScheduleItemCreateWithoutLeadInput;
  update: ScheduleItemUpdateWithoutLeadInput;
};

export type ScheduleItemUpsertWithoutResulting_ActivityInput = {
  create: ScheduleItemCreateWithoutResulting_ActivityInput;
  update: ScheduleItemUpdateWithoutResulting_ActivityInput;
};

export type ScheduleItemUpsertWithoutStat_Item_AggregationInput = {
  create: ScheduleItemCreateWithoutStat_Item_AggregationInput;
  update: ScheduleItemUpdateWithoutStat_Item_AggregationInput;
};

export type ScheduleItemUpsertWithoutTimeoff_RequestInput = {
  create: ScheduleItemCreateWithoutTimeoff_RequestInput;
  update: ScheduleItemUpdateWithoutTimeoff_RequestInput;
};

export type ScheduleItemUpsertWithWhereUniqueWithoutAutomated_CampaignsInput = {
  create: ScheduleItemCreateWithoutAutomated_CampaignsInput;
  update: ScheduleItemUpdateWithoutAutomated_CampaignsInput;
  where: ScheduleItemWhereUniqueInput;
};

export type ScheduleItemUpsertWithWhereUniqueWithoutOrganizationInput = {
  create: ScheduleItemCreateWithoutOrganizationInput;
  update: ScheduleItemUpdateWithoutOrganizationInput;
  where: ScheduleItemWhereUniqueInput;
};

export type ScheduleItemUpsertWithWhereUniqueWithoutUserInput = {
  create: ScheduleItemCreateWithoutUserInput;
  update: ScheduleItemUpdateWithoutUserInput;
  where: ScheduleItemWhereUniqueInput;
};

export type ScheduleItemWhereInput = {
  action_label?: InputMaybe<StringNullableFilter>;
  AND?: InputMaybe<Array<ScheduleItemWhereInput>>;
  automated_campaigns?: InputMaybe<AutomatedCampaignListRelationFilter>;
  available_actions?: InputMaybe<StringNullableListFilter>;
  booking_id?: InputMaybe<StringNullableFilter>;
  calendar_invite?: InputMaybe<BoolNullableFilter>;
  campaign_activities?: InputMaybe<LeadActivityListRelationFilter>;
  created_at?: InputMaybe<DateTimeFilter>;
  deleted_at?: InputMaybe<DateTimeNullableFilter>;
  description?: InputMaybe<StringNullableFilter>;
  end_time?: InputMaybe<DateTimeNullableFilter>;
  graph?: InputMaybe<StringNullableFilter>;
  guests?: InputMaybe<InvitationMetadataListRelationFilter>;
  id?: InputMaybe<StringFilter>;
  join_me_id?: InputMaybe<StringNullableFilter>;
  join_me_item?: InputMaybe<JoinMeItemWhereInput>;
  Lead?: InputMaybe<LeadListRelationFilter>;
  lead_activity?: InputMaybe<LeadActivityWhereInput>;
  lead_activity_id?: InputMaybe<StringNullableFilter>;
  lead_intent?: InputMaybe<LeadIntentWhereInput>;
  movable?: InputMaybe<BoolFilter>;
  NOT?: InputMaybe<Array<ScheduleItemWhereInput>>;
  OR?: InputMaybe<Array<ScheduleItemWhereInput>>;
  Organization?: InputMaybe<OrganizationWhereInput>;
  organization_id?: InputMaybe<StringNullableFilter>;
  overflow_booking_id?: InputMaybe<StringNullableFilter>;
  resulting_activity?: InputMaybe<LeadActivityWhereInput>;
  resulting_activity_id?: InputMaybe<StringNullableFilter>;
  start_time?: InputMaybe<DateTimeNullableFilter>;
  stat_item_aggregation?: InputMaybe<StatItemAggregationWhereInput>;
  state?: InputMaybe<StringNullableFilter>;
  status?: InputMaybe<EnumInvitationResultFilter>;
  timeoff_request?: InputMaybe<TimeOffRequestWhereInput>;
  timezone?: InputMaybe<StringNullableFilter>;
  type?: InputMaybe<EnumScheduleItemTypeNullableFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserWhereInput>;
  user_id?: InputMaybe<StringNullableFilter>;
  what?: InputMaybe<StringNullableFilter>;
  where?: InputMaybe<StringNullableFilter>;
};

export type ScheduleItemWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  resulting_activity_id?: InputMaybe<Scalars['String']['input']>;
};

/** Used for select fields */
export type SelectOption = {
  __typename?: 'SelectOption';
  label?: Maybe<Scalars['String']['output']>;
  /** optional. Used for certain section select fields */
  type?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type Sequence = {
  __typename?: 'Sequence';
  /** computed field that returns not deleted steps */
  active_steps?: Maybe<Array<Maybe<SequenceStep>>>;
  /** all leads count */
  all_leads_count?: Maybe<Scalars['Int']['output']>;
  completed_count: Scalars['Int']['output'];
  conversion_count: Scalars['Int']['output'];
  created_at: Scalars['DateTime']['output'];
  created_by: User;
  created_by_user_id: Scalars['String']['output'];
  /** computed field that the sequence is ready to be used */
  custom_task_action_count?: Maybe<Scalars['Int']['output']>;
  deleted_at?: Maybe<Scalars['DateTime']['output']>;
  /** computed field that the sequence is ready to be used */
  email_action_count?: Maybe<Scalars['Int']['output']>;
  email_bounced_perc: Scalars['Float']['output'];
  email_clicked_perc: Scalars['Float']['output'];
  email_delivered_perc: Scalars['Float']['output'];
  email_opened_perc: Scalars['Float']['output'];
  email_replied_perc: Scalars['Float']['output'];
  email_unsubscribed_perc: Scalars['Float']['output'];
  /** computed field that the entry criteria is complete */
  entry_criteria_complete?: Maybe<Scalars['Boolean']['output']>;
  exit_count: Scalars['Int']['output'];
  id: Scalars['String']['output'];
  is_paused: Scalars['Boolean']['output'];
  /** computed field that the sequence is ready to be used */
  is_ready?: Maybe<Scalars['Boolean']['output']>;
  last_updated_by: User;
  leads: Array<Lead>;
  /** computed field that the sequence is ready to be used */
  leads_count?: Maybe<Scalars['Int']['output']>;
  /** computed field that the sequence is ready to be used */
  manager_feedback_action_count?: Maybe<Scalars['Int']['output']>;
  manager_feedback_count: Scalars['Int']['output'];
  /** Module metrics for sequence */
  moduleMetrics?: Maybe<Scalars['Json']['output']>;
  name: Scalars['String']['output'];
  not_interested_count: Scalars['Int']['output'];
  organization_id?: Maybe<Scalars['String']['output']>;
  organzation?: Maybe<Organization>;
  /** computed field that the sequence is ready to be used */
  phone_action_count?: Maybe<Scalars['Int']['output']>;
  priority: Scalars['Int']['output'];
  Sale: Array<Sale>;
  sequence_entry_criteria?: Maybe<SequenceEntryCriteria>;
  sequence_entry_criteria_id?: Maybe<Scalars['String']['output']>;
  sequence_steps: Array<SequenceStep>;
  /** computed field that the sequence is ready to be used */
  sms_action_count?: Maybe<Scalars['Int']['output']>;
  sms_bounced_perc: Scalars['Float']['output'];
  sms_delivered_perc: Scalars['Float']['output'];
  sms_unsubscribed_perc: Scalars['Float']['output'];
  steps_count: Scalars['Int']['output'];
  updated_at: Scalars['DateTime']['output'];
};


export type SequenceLeadsArgs = {
  after?: InputMaybe<LeadWhereUniqueInput>;
  before?: InputMaybe<LeadWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type SequenceModuleMetricsArgs = {
  SequenceDashboardInput: SequenceDashboardInput;
};


export type SequenceSaleArgs = {
  after?: InputMaybe<SaleWhereUniqueInput>;
  before?: InputMaybe<SaleWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type SequenceSequence_StepsArgs = {
  after?: InputMaybe<SequenceStepWhereUniqueInput>;
  before?: InputMaybe<SequenceStepWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type SequenceCreateManyCreated_ByInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  completed_count?: InputMaybe<Scalars['Int']['input']>;
  conversion_count?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  deleted_at?: InputMaybe<Scalars['DateTime']['input']>;
  email_bounced_perc?: InputMaybe<Scalars['Float']['input']>;
  email_clicked_perc?: InputMaybe<Scalars['Float']['input']>;
  email_delivered_perc?: InputMaybe<Scalars['Float']['input']>;
  email_opened_perc?: InputMaybe<Scalars['Float']['input']>;
  email_replied_perc?: InputMaybe<Scalars['Float']['input']>;
  email_unsubscribed_perc?: InputMaybe<Scalars['Float']['input']>;
  exit_count?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  is_paused?: InputMaybe<Scalars['Boolean']['input']>;
  manager_feedback_count?: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
  not_interested_count?: InputMaybe<Scalars['Int']['input']>;
  organization_id?: InputMaybe<Scalars['String']['input']>;
  priority: Scalars['Int']['input'];
  sequence_entry_criteria_id?: InputMaybe<Scalars['String']['input']>;
  sms_bounced_perc?: InputMaybe<Scalars['Float']['input']>;
  sms_delivered_perc?: InputMaybe<Scalars['Float']['input']>;
  sms_unsubscribed_perc?: InputMaybe<Scalars['Float']['input']>;
  steps_count?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  updated_by_user_id: Scalars['String']['input'];
};

export type SequenceCreateManyCreated_ByInputEnvelope = {
  data?: InputMaybe<Array<SequenceCreateManyCreated_ByInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SequenceCreateManyLast_Updated_ByInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  completed_count?: InputMaybe<Scalars['Int']['input']>;
  conversion_count?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  created_by_user_id: Scalars['String']['input'];
  deleted_at?: InputMaybe<Scalars['DateTime']['input']>;
  email_bounced_perc?: InputMaybe<Scalars['Float']['input']>;
  email_clicked_perc?: InputMaybe<Scalars['Float']['input']>;
  email_delivered_perc?: InputMaybe<Scalars['Float']['input']>;
  email_opened_perc?: InputMaybe<Scalars['Float']['input']>;
  email_replied_perc?: InputMaybe<Scalars['Float']['input']>;
  email_unsubscribed_perc?: InputMaybe<Scalars['Float']['input']>;
  exit_count?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  is_paused?: InputMaybe<Scalars['Boolean']['input']>;
  manager_feedback_count?: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
  not_interested_count?: InputMaybe<Scalars['Int']['input']>;
  organization_id?: InputMaybe<Scalars['String']['input']>;
  priority: Scalars['Int']['input'];
  sequence_entry_criteria_id?: InputMaybe<Scalars['String']['input']>;
  sms_bounced_perc?: InputMaybe<Scalars['Float']['input']>;
  sms_delivered_perc?: InputMaybe<Scalars['Float']['input']>;
  sms_unsubscribed_perc?: InputMaybe<Scalars['Float']['input']>;
  steps_count?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type SequenceCreateManyLast_Updated_ByInputEnvelope = {
  data?: InputMaybe<Array<SequenceCreateManyLast_Updated_ByInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SequenceCreateManyOrganzationInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  completed_count?: InputMaybe<Scalars['Int']['input']>;
  conversion_count?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  created_by_user_id: Scalars['String']['input'];
  deleted_at?: InputMaybe<Scalars['DateTime']['input']>;
  email_bounced_perc?: InputMaybe<Scalars['Float']['input']>;
  email_clicked_perc?: InputMaybe<Scalars['Float']['input']>;
  email_delivered_perc?: InputMaybe<Scalars['Float']['input']>;
  email_opened_perc?: InputMaybe<Scalars['Float']['input']>;
  email_replied_perc?: InputMaybe<Scalars['Float']['input']>;
  email_unsubscribed_perc?: InputMaybe<Scalars['Float']['input']>;
  exit_count?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  is_paused?: InputMaybe<Scalars['Boolean']['input']>;
  manager_feedback_count?: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
  not_interested_count?: InputMaybe<Scalars['Int']['input']>;
  priority: Scalars['Int']['input'];
  sequence_entry_criteria_id?: InputMaybe<Scalars['String']['input']>;
  sms_bounced_perc?: InputMaybe<Scalars['Float']['input']>;
  sms_delivered_perc?: InputMaybe<Scalars['Float']['input']>;
  sms_unsubscribed_perc?: InputMaybe<Scalars['Float']['input']>;
  steps_count?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  updated_by_user_id: Scalars['String']['input'];
};

export type SequenceCreateManyOrganzationInputEnvelope = {
  data?: InputMaybe<Array<SequenceCreateManyOrganzationInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SequenceCreateNestedManyWithoutCreated_ByInput = {
  connect?: InputMaybe<Array<SequenceWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SequenceCreateOrConnectWithoutCreated_ByInput>>;
  create?: InputMaybe<Array<SequenceCreateWithoutCreated_ByInput>>;
  createMany?: InputMaybe<SequenceCreateManyCreated_ByInputEnvelope>;
};

export type SequenceCreateNestedManyWithoutLast_Updated_ByInput = {
  connect?: InputMaybe<Array<SequenceWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SequenceCreateOrConnectWithoutLast_Updated_ByInput>>;
  create?: InputMaybe<Array<SequenceCreateWithoutLast_Updated_ByInput>>;
  createMany?: InputMaybe<SequenceCreateManyLast_Updated_ByInputEnvelope>;
};

export type SequenceCreateNestedManyWithoutOrganzationInput = {
  connect?: InputMaybe<Array<SequenceWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SequenceCreateOrConnectWithoutOrganzationInput>>;
  create?: InputMaybe<Array<SequenceCreateWithoutOrganzationInput>>;
  createMany?: InputMaybe<SequenceCreateManyOrganzationInputEnvelope>;
};

export type SequenceCreateNestedOneWithoutEmailItemInput = {
  connect?: InputMaybe<SequenceWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SequenceCreateOrConnectWithoutEmailItemInput>;
  create?: InputMaybe<SequenceCreateWithoutEmailItemInput>;
};

export type SequenceCreateNestedOneWithoutEndStepsTransferInput = {
  connect?: InputMaybe<SequenceWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SequenceCreateOrConnectWithoutEndStepsTransferInput>;
  create?: InputMaybe<SequenceCreateWithoutEndStepsTransferInput>;
};

export type SequenceCreateNestedOneWithoutLeadActivitiesInput = {
  connect?: InputMaybe<SequenceWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SequenceCreateOrConnectWithoutLeadActivitiesInput>;
  create?: InputMaybe<SequenceCreateWithoutLeadActivitiesInput>;
};

export type SequenceCreateNestedOneWithoutLeadAssignmentHistoryInput = {
  connect?: InputMaybe<SequenceWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SequenceCreateOrConnectWithoutLeadAssignmentHistoryInput>;
  create?: InputMaybe<SequenceCreateWithoutLeadAssignmentHistoryInput>;
};

export type SequenceCreateNestedOneWithoutLeadsInput = {
  connect?: InputMaybe<SequenceWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SequenceCreateOrConnectWithoutLeadsInput>;
  create?: InputMaybe<SequenceCreateWithoutLeadsInput>;
};

export type SequenceCreateNestedOneWithoutSaleInput = {
  connect?: InputMaybe<SequenceWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SequenceCreateOrConnectWithoutSaleInput>;
  create?: InputMaybe<SequenceCreateWithoutSaleInput>;
};

export type SequenceCreateNestedOneWithoutSequence_Entry_CriteriaInput = {
  connect?: InputMaybe<SequenceWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SequenceCreateOrConnectWithoutSequence_Entry_CriteriaInput>;
  create?: InputMaybe<SequenceCreateWithoutSequence_Entry_CriteriaInput>;
};

export type SequenceCreateNestedOneWithoutSequence_StepsInput = {
  connect?: InputMaybe<SequenceWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SequenceCreateOrConnectWithoutSequence_StepsInput>;
  create?: InputMaybe<SequenceCreateWithoutSequence_StepsInput>;
};

export type SequenceCreateNestedOneWithoutSequenceEntryExitLeadInput = {
  connect?: InputMaybe<SequenceWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SequenceCreateOrConnectWithoutSequenceEntryExitLeadInput>;
  create?: InputMaybe<SequenceCreateWithoutSequenceEntryExitLeadInput>;
};

export type SequenceCreateNestedOneWithoutSmsItemInput = {
  connect?: InputMaybe<SequenceWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SequenceCreateOrConnectWithoutSmsItemInput>;
  create?: InputMaybe<SequenceCreateWithoutSmsItemInput>;
};

export type SequenceCreateNestedOneWithoutSnoozeTrackingInput = {
  connect?: InputMaybe<SequenceWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SequenceCreateOrConnectWithoutSnoozeTrackingInput>;
  create?: InputMaybe<SequenceCreateWithoutSnoozeTrackingInput>;
};

export type SequenceCreateNestedOneWithoutStatItemsInput = {
  connect?: InputMaybe<SequenceWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SequenceCreateOrConnectWithoutStatItemsInput>;
  create?: InputMaybe<SequenceCreateWithoutStatItemsInput>;
};

export type SequenceCreateNestedOneWithoutStepActionLogInput = {
  connect?: InputMaybe<SequenceWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SequenceCreateOrConnectWithoutStepActionLogInput>;
  create?: InputMaybe<SequenceCreateWithoutStepActionLogInput>;
};

export type SequenceCreateOrConnectWithoutCreated_ByInput = {
  create: SequenceCreateWithoutCreated_ByInput;
  where: SequenceWhereUniqueInput;
};

export type SequenceCreateOrConnectWithoutEmailItemInput = {
  create: SequenceCreateWithoutEmailItemInput;
  where: SequenceWhereUniqueInput;
};

export type SequenceCreateOrConnectWithoutEndStepsTransferInput = {
  create: SequenceCreateWithoutEndStepsTransferInput;
  where: SequenceWhereUniqueInput;
};

export type SequenceCreateOrConnectWithoutLast_Updated_ByInput = {
  create: SequenceCreateWithoutLast_Updated_ByInput;
  where: SequenceWhereUniqueInput;
};

export type SequenceCreateOrConnectWithoutLeadActivitiesInput = {
  create: SequenceCreateWithoutLeadActivitiesInput;
  where: SequenceWhereUniqueInput;
};

export type SequenceCreateOrConnectWithoutLeadAssignmentHistoryInput = {
  create: SequenceCreateWithoutLeadAssignmentHistoryInput;
  where: SequenceWhereUniqueInput;
};

export type SequenceCreateOrConnectWithoutLeadsInput = {
  create: SequenceCreateWithoutLeadsInput;
  where: SequenceWhereUniqueInput;
};

export type SequenceCreateOrConnectWithoutOrganzationInput = {
  create: SequenceCreateWithoutOrganzationInput;
  where: SequenceWhereUniqueInput;
};

export type SequenceCreateOrConnectWithoutSaleInput = {
  create: SequenceCreateWithoutSaleInput;
  where: SequenceWhereUniqueInput;
};

export type SequenceCreateOrConnectWithoutSequence_Entry_CriteriaInput = {
  create: SequenceCreateWithoutSequence_Entry_CriteriaInput;
  where: SequenceWhereUniqueInput;
};

export type SequenceCreateOrConnectWithoutSequence_StepsInput = {
  create: SequenceCreateWithoutSequence_StepsInput;
  where: SequenceWhereUniqueInput;
};

export type SequenceCreateOrConnectWithoutSequenceEntryExitLeadInput = {
  create: SequenceCreateWithoutSequenceEntryExitLeadInput;
  where: SequenceWhereUniqueInput;
};

export type SequenceCreateOrConnectWithoutSmsItemInput = {
  create: SequenceCreateWithoutSmsItemInput;
  where: SequenceWhereUniqueInput;
};

export type SequenceCreateOrConnectWithoutSnoozeTrackingInput = {
  create: SequenceCreateWithoutSnoozeTrackingInput;
  where: SequenceWhereUniqueInput;
};

export type SequenceCreateOrConnectWithoutStatItemsInput = {
  create: SequenceCreateWithoutStatItemsInput;
  where: SequenceWhereUniqueInput;
};

export type SequenceCreateOrConnectWithoutStepActionLogInput = {
  create: SequenceCreateWithoutStepActionLogInput;
  where: SequenceWhereUniqueInput;
};

export type SequenceCreateWithoutCreated_ByInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  completed_count?: InputMaybe<Scalars['Int']['input']>;
  conversion_count?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  deleted_at?: InputMaybe<Scalars['DateTime']['input']>;
  email_bounced_perc?: InputMaybe<Scalars['Float']['input']>;
  email_clicked_perc?: InputMaybe<Scalars['Float']['input']>;
  email_delivered_perc?: InputMaybe<Scalars['Float']['input']>;
  email_opened_perc?: InputMaybe<Scalars['Float']['input']>;
  email_replied_perc?: InputMaybe<Scalars['Float']['input']>;
  email_unsubscribed_perc?: InputMaybe<Scalars['Float']['input']>;
  EmailItem?: InputMaybe<EmailItemCreateNestedManyWithoutSequenceInput>;
  EndStepsTransfer?: InputMaybe<SequenceStepCreateNestedManyWithoutTransferToSequenceInput>;
  exit_count?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  is_paused?: InputMaybe<Scalars['Boolean']['input']>;
  last_updated_by: UserCreateNestedOneWithoutSequences_Modified_By_UserInput;
  leadActivities?: InputMaybe<LeadActivityCreateNestedManyWithoutLead_Current_SequenceInput>;
  LeadAssignmentHistory?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutSequence_Not_InterestedInput>;
  leads?: InputMaybe<LeadCreateNestedManyWithoutSequenceInput>;
  manager_feedback_count?: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
  not_interested_count?: InputMaybe<Scalars['Int']['input']>;
  organzation?: InputMaybe<OrganizationCreateNestedOneWithoutSequenceInput>;
  priority: Scalars['Int']['input'];
  Sale?: InputMaybe<SaleCreateNestedManyWithoutConverted_SequenceInput>;
  sequence_entry_criteria?: InputMaybe<SequenceEntryCriteriaCreateNestedOneWithoutSequenceInput>;
  sequence_steps?: InputMaybe<SequenceStepCreateNestedManyWithoutSequenceInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutSequenceInput>;
  sms_bounced_perc?: InputMaybe<Scalars['Float']['input']>;
  sms_delivered_perc?: InputMaybe<Scalars['Float']['input']>;
  sms_unsubscribed_perc?: InputMaybe<Scalars['Float']['input']>;
  SMSItem?: InputMaybe<SmsItemCreateNestedManyWithoutSequenceInput>;
  SnoozeTracking?: InputMaybe<SnoozeTrackingCreateNestedManyWithoutSequenceInput>;
  StatItems?: InputMaybe<StatItemAggregationCreateNestedManyWithoutSequenceInput>;
  StepActionLog?: InputMaybe<StepActionLogCreateNestedManyWithoutSequenceInput>;
  steps_count?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type SequenceCreateWithoutEmailItemInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  completed_count?: InputMaybe<Scalars['Int']['input']>;
  conversion_count?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  created_by: UserCreateNestedOneWithoutSequences_CreatedInput;
  deleted_at?: InputMaybe<Scalars['DateTime']['input']>;
  email_bounced_perc?: InputMaybe<Scalars['Float']['input']>;
  email_clicked_perc?: InputMaybe<Scalars['Float']['input']>;
  email_delivered_perc?: InputMaybe<Scalars['Float']['input']>;
  email_opened_perc?: InputMaybe<Scalars['Float']['input']>;
  email_replied_perc?: InputMaybe<Scalars['Float']['input']>;
  email_unsubscribed_perc?: InputMaybe<Scalars['Float']['input']>;
  EndStepsTransfer?: InputMaybe<SequenceStepCreateNestedManyWithoutTransferToSequenceInput>;
  exit_count?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  is_paused?: InputMaybe<Scalars['Boolean']['input']>;
  last_updated_by: UserCreateNestedOneWithoutSequences_Modified_By_UserInput;
  leadActivities?: InputMaybe<LeadActivityCreateNestedManyWithoutLead_Current_SequenceInput>;
  LeadAssignmentHistory?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutSequence_Not_InterestedInput>;
  leads?: InputMaybe<LeadCreateNestedManyWithoutSequenceInput>;
  manager_feedback_count?: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
  not_interested_count?: InputMaybe<Scalars['Int']['input']>;
  organzation?: InputMaybe<OrganizationCreateNestedOneWithoutSequenceInput>;
  priority: Scalars['Int']['input'];
  Sale?: InputMaybe<SaleCreateNestedManyWithoutConverted_SequenceInput>;
  sequence_entry_criteria?: InputMaybe<SequenceEntryCriteriaCreateNestedOneWithoutSequenceInput>;
  sequence_steps?: InputMaybe<SequenceStepCreateNestedManyWithoutSequenceInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutSequenceInput>;
  sms_bounced_perc?: InputMaybe<Scalars['Float']['input']>;
  sms_delivered_perc?: InputMaybe<Scalars['Float']['input']>;
  sms_unsubscribed_perc?: InputMaybe<Scalars['Float']['input']>;
  SMSItem?: InputMaybe<SmsItemCreateNestedManyWithoutSequenceInput>;
  SnoozeTracking?: InputMaybe<SnoozeTrackingCreateNestedManyWithoutSequenceInput>;
  StatItems?: InputMaybe<StatItemAggregationCreateNestedManyWithoutSequenceInput>;
  StepActionLog?: InputMaybe<StepActionLogCreateNestedManyWithoutSequenceInput>;
  steps_count?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type SequenceCreateWithoutEndStepsTransferInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  completed_count?: InputMaybe<Scalars['Int']['input']>;
  conversion_count?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  created_by: UserCreateNestedOneWithoutSequences_CreatedInput;
  deleted_at?: InputMaybe<Scalars['DateTime']['input']>;
  email_bounced_perc?: InputMaybe<Scalars['Float']['input']>;
  email_clicked_perc?: InputMaybe<Scalars['Float']['input']>;
  email_delivered_perc?: InputMaybe<Scalars['Float']['input']>;
  email_opened_perc?: InputMaybe<Scalars['Float']['input']>;
  email_replied_perc?: InputMaybe<Scalars['Float']['input']>;
  email_unsubscribed_perc?: InputMaybe<Scalars['Float']['input']>;
  EmailItem?: InputMaybe<EmailItemCreateNestedManyWithoutSequenceInput>;
  exit_count?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  is_paused?: InputMaybe<Scalars['Boolean']['input']>;
  last_updated_by: UserCreateNestedOneWithoutSequences_Modified_By_UserInput;
  leadActivities?: InputMaybe<LeadActivityCreateNestedManyWithoutLead_Current_SequenceInput>;
  LeadAssignmentHistory?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutSequence_Not_InterestedInput>;
  leads?: InputMaybe<LeadCreateNestedManyWithoutSequenceInput>;
  manager_feedback_count?: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
  not_interested_count?: InputMaybe<Scalars['Int']['input']>;
  organzation?: InputMaybe<OrganizationCreateNestedOneWithoutSequenceInput>;
  priority: Scalars['Int']['input'];
  Sale?: InputMaybe<SaleCreateNestedManyWithoutConverted_SequenceInput>;
  sequence_entry_criteria?: InputMaybe<SequenceEntryCriteriaCreateNestedOneWithoutSequenceInput>;
  sequence_steps?: InputMaybe<SequenceStepCreateNestedManyWithoutSequenceInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutSequenceInput>;
  sms_bounced_perc?: InputMaybe<Scalars['Float']['input']>;
  sms_delivered_perc?: InputMaybe<Scalars['Float']['input']>;
  sms_unsubscribed_perc?: InputMaybe<Scalars['Float']['input']>;
  SMSItem?: InputMaybe<SmsItemCreateNestedManyWithoutSequenceInput>;
  SnoozeTracking?: InputMaybe<SnoozeTrackingCreateNestedManyWithoutSequenceInput>;
  StatItems?: InputMaybe<StatItemAggregationCreateNestedManyWithoutSequenceInput>;
  StepActionLog?: InputMaybe<StepActionLogCreateNestedManyWithoutSequenceInput>;
  steps_count?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type SequenceCreateWithoutLast_Updated_ByInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  completed_count?: InputMaybe<Scalars['Int']['input']>;
  conversion_count?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  created_by: UserCreateNestedOneWithoutSequences_CreatedInput;
  deleted_at?: InputMaybe<Scalars['DateTime']['input']>;
  email_bounced_perc?: InputMaybe<Scalars['Float']['input']>;
  email_clicked_perc?: InputMaybe<Scalars['Float']['input']>;
  email_delivered_perc?: InputMaybe<Scalars['Float']['input']>;
  email_opened_perc?: InputMaybe<Scalars['Float']['input']>;
  email_replied_perc?: InputMaybe<Scalars['Float']['input']>;
  email_unsubscribed_perc?: InputMaybe<Scalars['Float']['input']>;
  EmailItem?: InputMaybe<EmailItemCreateNestedManyWithoutSequenceInput>;
  EndStepsTransfer?: InputMaybe<SequenceStepCreateNestedManyWithoutTransferToSequenceInput>;
  exit_count?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  is_paused?: InputMaybe<Scalars['Boolean']['input']>;
  leadActivities?: InputMaybe<LeadActivityCreateNestedManyWithoutLead_Current_SequenceInput>;
  LeadAssignmentHistory?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutSequence_Not_InterestedInput>;
  leads?: InputMaybe<LeadCreateNestedManyWithoutSequenceInput>;
  manager_feedback_count?: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
  not_interested_count?: InputMaybe<Scalars['Int']['input']>;
  organzation?: InputMaybe<OrganizationCreateNestedOneWithoutSequenceInput>;
  priority: Scalars['Int']['input'];
  Sale?: InputMaybe<SaleCreateNestedManyWithoutConverted_SequenceInput>;
  sequence_entry_criteria?: InputMaybe<SequenceEntryCriteriaCreateNestedOneWithoutSequenceInput>;
  sequence_steps?: InputMaybe<SequenceStepCreateNestedManyWithoutSequenceInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutSequenceInput>;
  sms_bounced_perc?: InputMaybe<Scalars['Float']['input']>;
  sms_delivered_perc?: InputMaybe<Scalars['Float']['input']>;
  sms_unsubscribed_perc?: InputMaybe<Scalars['Float']['input']>;
  SMSItem?: InputMaybe<SmsItemCreateNestedManyWithoutSequenceInput>;
  SnoozeTracking?: InputMaybe<SnoozeTrackingCreateNestedManyWithoutSequenceInput>;
  StatItems?: InputMaybe<StatItemAggregationCreateNestedManyWithoutSequenceInput>;
  StepActionLog?: InputMaybe<StepActionLogCreateNestedManyWithoutSequenceInput>;
  steps_count?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type SequenceCreateWithoutLeadActivitiesInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  completed_count?: InputMaybe<Scalars['Int']['input']>;
  conversion_count?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  created_by: UserCreateNestedOneWithoutSequences_CreatedInput;
  deleted_at?: InputMaybe<Scalars['DateTime']['input']>;
  email_bounced_perc?: InputMaybe<Scalars['Float']['input']>;
  email_clicked_perc?: InputMaybe<Scalars['Float']['input']>;
  email_delivered_perc?: InputMaybe<Scalars['Float']['input']>;
  email_opened_perc?: InputMaybe<Scalars['Float']['input']>;
  email_replied_perc?: InputMaybe<Scalars['Float']['input']>;
  email_unsubscribed_perc?: InputMaybe<Scalars['Float']['input']>;
  EmailItem?: InputMaybe<EmailItemCreateNestedManyWithoutSequenceInput>;
  EndStepsTransfer?: InputMaybe<SequenceStepCreateNestedManyWithoutTransferToSequenceInput>;
  exit_count?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  is_paused?: InputMaybe<Scalars['Boolean']['input']>;
  last_updated_by: UserCreateNestedOneWithoutSequences_Modified_By_UserInput;
  LeadAssignmentHistory?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutSequence_Not_InterestedInput>;
  leads?: InputMaybe<LeadCreateNestedManyWithoutSequenceInput>;
  manager_feedback_count?: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
  not_interested_count?: InputMaybe<Scalars['Int']['input']>;
  organzation?: InputMaybe<OrganizationCreateNestedOneWithoutSequenceInput>;
  priority: Scalars['Int']['input'];
  Sale?: InputMaybe<SaleCreateNestedManyWithoutConverted_SequenceInput>;
  sequence_entry_criteria?: InputMaybe<SequenceEntryCriteriaCreateNestedOneWithoutSequenceInput>;
  sequence_steps?: InputMaybe<SequenceStepCreateNestedManyWithoutSequenceInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutSequenceInput>;
  sms_bounced_perc?: InputMaybe<Scalars['Float']['input']>;
  sms_delivered_perc?: InputMaybe<Scalars['Float']['input']>;
  sms_unsubscribed_perc?: InputMaybe<Scalars['Float']['input']>;
  SMSItem?: InputMaybe<SmsItemCreateNestedManyWithoutSequenceInput>;
  SnoozeTracking?: InputMaybe<SnoozeTrackingCreateNestedManyWithoutSequenceInput>;
  StatItems?: InputMaybe<StatItemAggregationCreateNestedManyWithoutSequenceInput>;
  StepActionLog?: InputMaybe<StepActionLogCreateNestedManyWithoutSequenceInput>;
  steps_count?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type SequenceCreateWithoutLeadAssignmentHistoryInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  completed_count?: InputMaybe<Scalars['Int']['input']>;
  conversion_count?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  created_by: UserCreateNestedOneWithoutSequences_CreatedInput;
  deleted_at?: InputMaybe<Scalars['DateTime']['input']>;
  email_bounced_perc?: InputMaybe<Scalars['Float']['input']>;
  email_clicked_perc?: InputMaybe<Scalars['Float']['input']>;
  email_delivered_perc?: InputMaybe<Scalars['Float']['input']>;
  email_opened_perc?: InputMaybe<Scalars['Float']['input']>;
  email_replied_perc?: InputMaybe<Scalars['Float']['input']>;
  email_unsubscribed_perc?: InputMaybe<Scalars['Float']['input']>;
  EmailItem?: InputMaybe<EmailItemCreateNestedManyWithoutSequenceInput>;
  EndStepsTransfer?: InputMaybe<SequenceStepCreateNestedManyWithoutTransferToSequenceInput>;
  exit_count?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  is_paused?: InputMaybe<Scalars['Boolean']['input']>;
  last_updated_by: UserCreateNestedOneWithoutSequences_Modified_By_UserInput;
  leadActivities?: InputMaybe<LeadActivityCreateNestedManyWithoutLead_Current_SequenceInput>;
  leads?: InputMaybe<LeadCreateNestedManyWithoutSequenceInput>;
  manager_feedback_count?: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
  not_interested_count?: InputMaybe<Scalars['Int']['input']>;
  organzation?: InputMaybe<OrganizationCreateNestedOneWithoutSequenceInput>;
  priority: Scalars['Int']['input'];
  Sale?: InputMaybe<SaleCreateNestedManyWithoutConverted_SequenceInput>;
  sequence_entry_criteria?: InputMaybe<SequenceEntryCriteriaCreateNestedOneWithoutSequenceInput>;
  sequence_steps?: InputMaybe<SequenceStepCreateNestedManyWithoutSequenceInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutSequenceInput>;
  sms_bounced_perc?: InputMaybe<Scalars['Float']['input']>;
  sms_delivered_perc?: InputMaybe<Scalars['Float']['input']>;
  sms_unsubscribed_perc?: InputMaybe<Scalars['Float']['input']>;
  SMSItem?: InputMaybe<SmsItemCreateNestedManyWithoutSequenceInput>;
  SnoozeTracking?: InputMaybe<SnoozeTrackingCreateNestedManyWithoutSequenceInput>;
  StatItems?: InputMaybe<StatItemAggregationCreateNestedManyWithoutSequenceInput>;
  StepActionLog?: InputMaybe<StepActionLogCreateNestedManyWithoutSequenceInput>;
  steps_count?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type SequenceCreateWithoutLeadsInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  completed_count?: InputMaybe<Scalars['Int']['input']>;
  conversion_count?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  created_by: UserCreateNestedOneWithoutSequences_CreatedInput;
  deleted_at?: InputMaybe<Scalars['DateTime']['input']>;
  email_bounced_perc?: InputMaybe<Scalars['Float']['input']>;
  email_clicked_perc?: InputMaybe<Scalars['Float']['input']>;
  email_delivered_perc?: InputMaybe<Scalars['Float']['input']>;
  email_opened_perc?: InputMaybe<Scalars['Float']['input']>;
  email_replied_perc?: InputMaybe<Scalars['Float']['input']>;
  email_unsubscribed_perc?: InputMaybe<Scalars['Float']['input']>;
  EmailItem?: InputMaybe<EmailItemCreateNestedManyWithoutSequenceInput>;
  EndStepsTransfer?: InputMaybe<SequenceStepCreateNestedManyWithoutTransferToSequenceInput>;
  exit_count?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  is_paused?: InputMaybe<Scalars['Boolean']['input']>;
  last_updated_by: UserCreateNestedOneWithoutSequences_Modified_By_UserInput;
  leadActivities?: InputMaybe<LeadActivityCreateNestedManyWithoutLead_Current_SequenceInput>;
  LeadAssignmentHistory?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutSequence_Not_InterestedInput>;
  manager_feedback_count?: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
  not_interested_count?: InputMaybe<Scalars['Int']['input']>;
  organzation?: InputMaybe<OrganizationCreateNestedOneWithoutSequenceInput>;
  priority: Scalars['Int']['input'];
  Sale?: InputMaybe<SaleCreateNestedManyWithoutConverted_SequenceInput>;
  sequence_entry_criteria?: InputMaybe<SequenceEntryCriteriaCreateNestedOneWithoutSequenceInput>;
  sequence_steps?: InputMaybe<SequenceStepCreateNestedManyWithoutSequenceInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutSequenceInput>;
  sms_bounced_perc?: InputMaybe<Scalars['Float']['input']>;
  sms_delivered_perc?: InputMaybe<Scalars['Float']['input']>;
  sms_unsubscribed_perc?: InputMaybe<Scalars['Float']['input']>;
  SMSItem?: InputMaybe<SmsItemCreateNestedManyWithoutSequenceInput>;
  SnoozeTracking?: InputMaybe<SnoozeTrackingCreateNestedManyWithoutSequenceInput>;
  StatItems?: InputMaybe<StatItemAggregationCreateNestedManyWithoutSequenceInput>;
  StepActionLog?: InputMaybe<StepActionLogCreateNestedManyWithoutSequenceInput>;
  steps_count?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type SequenceCreateWithoutOrganzationInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  completed_count?: InputMaybe<Scalars['Int']['input']>;
  conversion_count?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  created_by: UserCreateNestedOneWithoutSequences_CreatedInput;
  deleted_at?: InputMaybe<Scalars['DateTime']['input']>;
  email_bounced_perc?: InputMaybe<Scalars['Float']['input']>;
  email_clicked_perc?: InputMaybe<Scalars['Float']['input']>;
  email_delivered_perc?: InputMaybe<Scalars['Float']['input']>;
  email_opened_perc?: InputMaybe<Scalars['Float']['input']>;
  email_replied_perc?: InputMaybe<Scalars['Float']['input']>;
  email_unsubscribed_perc?: InputMaybe<Scalars['Float']['input']>;
  EmailItem?: InputMaybe<EmailItemCreateNestedManyWithoutSequenceInput>;
  EndStepsTransfer?: InputMaybe<SequenceStepCreateNestedManyWithoutTransferToSequenceInput>;
  exit_count?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  is_paused?: InputMaybe<Scalars['Boolean']['input']>;
  last_updated_by: UserCreateNestedOneWithoutSequences_Modified_By_UserInput;
  leadActivities?: InputMaybe<LeadActivityCreateNestedManyWithoutLead_Current_SequenceInput>;
  LeadAssignmentHistory?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutSequence_Not_InterestedInput>;
  leads?: InputMaybe<LeadCreateNestedManyWithoutSequenceInput>;
  manager_feedback_count?: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
  not_interested_count?: InputMaybe<Scalars['Int']['input']>;
  priority: Scalars['Int']['input'];
  Sale?: InputMaybe<SaleCreateNestedManyWithoutConverted_SequenceInput>;
  sequence_entry_criteria?: InputMaybe<SequenceEntryCriteriaCreateNestedOneWithoutSequenceInput>;
  sequence_steps?: InputMaybe<SequenceStepCreateNestedManyWithoutSequenceInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutSequenceInput>;
  sms_bounced_perc?: InputMaybe<Scalars['Float']['input']>;
  sms_delivered_perc?: InputMaybe<Scalars['Float']['input']>;
  sms_unsubscribed_perc?: InputMaybe<Scalars['Float']['input']>;
  SMSItem?: InputMaybe<SmsItemCreateNestedManyWithoutSequenceInput>;
  SnoozeTracking?: InputMaybe<SnoozeTrackingCreateNestedManyWithoutSequenceInput>;
  StatItems?: InputMaybe<StatItemAggregationCreateNestedManyWithoutSequenceInput>;
  StepActionLog?: InputMaybe<StepActionLogCreateNestedManyWithoutSequenceInput>;
  steps_count?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type SequenceCreateWithoutSaleInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  completed_count?: InputMaybe<Scalars['Int']['input']>;
  conversion_count?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  created_by: UserCreateNestedOneWithoutSequences_CreatedInput;
  deleted_at?: InputMaybe<Scalars['DateTime']['input']>;
  email_bounced_perc?: InputMaybe<Scalars['Float']['input']>;
  email_clicked_perc?: InputMaybe<Scalars['Float']['input']>;
  email_delivered_perc?: InputMaybe<Scalars['Float']['input']>;
  email_opened_perc?: InputMaybe<Scalars['Float']['input']>;
  email_replied_perc?: InputMaybe<Scalars['Float']['input']>;
  email_unsubscribed_perc?: InputMaybe<Scalars['Float']['input']>;
  EmailItem?: InputMaybe<EmailItemCreateNestedManyWithoutSequenceInput>;
  EndStepsTransfer?: InputMaybe<SequenceStepCreateNestedManyWithoutTransferToSequenceInput>;
  exit_count?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  is_paused?: InputMaybe<Scalars['Boolean']['input']>;
  last_updated_by: UserCreateNestedOneWithoutSequences_Modified_By_UserInput;
  leadActivities?: InputMaybe<LeadActivityCreateNestedManyWithoutLead_Current_SequenceInput>;
  LeadAssignmentHistory?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutSequence_Not_InterestedInput>;
  leads?: InputMaybe<LeadCreateNestedManyWithoutSequenceInput>;
  manager_feedback_count?: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
  not_interested_count?: InputMaybe<Scalars['Int']['input']>;
  organzation?: InputMaybe<OrganizationCreateNestedOneWithoutSequenceInput>;
  priority: Scalars['Int']['input'];
  sequence_entry_criteria?: InputMaybe<SequenceEntryCriteriaCreateNestedOneWithoutSequenceInput>;
  sequence_steps?: InputMaybe<SequenceStepCreateNestedManyWithoutSequenceInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutSequenceInput>;
  sms_bounced_perc?: InputMaybe<Scalars['Float']['input']>;
  sms_delivered_perc?: InputMaybe<Scalars['Float']['input']>;
  sms_unsubscribed_perc?: InputMaybe<Scalars['Float']['input']>;
  SMSItem?: InputMaybe<SmsItemCreateNestedManyWithoutSequenceInput>;
  SnoozeTracking?: InputMaybe<SnoozeTrackingCreateNestedManyWithoutSequenceInput>;
  StatItems?: InputMaybe<StatItemAggregationCreateNestedManyWithoutSequenceInput>;
  StepActionLog?: InputMaybe<StepActionLogCreateNestedManyWithoutSequenceInput>;
  steps_count?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type SequenceCreateWithoutSequence_Entry_CriteriaInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  completed_count?: InputMaybe<Scalars['Int']['input']>;
  conversion_count?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  created_by: UserCreateNestedOneWithoutSequences_CreatedInput;
  deleted_at?: InputMaybe<Scalars['DateTime']['input']>;
  email_bounced_perc?: InputMaybe<Scalars['Float']['input']>;
  email_clicked_perc?: InputMaybe<Scalars['Float']['input']>;
  email_delivered_perc?: InputMaybe<Scalars['Float']['input']>;
  email_opened_perc?: InputMaybe<Scalars['Float']['input']>;
  email_replied_perc?: InputMaybe<Scalars['Float']['input']>;
  email_unsubscribed_perc?: InputMaybe<Scalars['Float']['input']>;
  EmailItem?: InputMaybe<EmailItemCreateNestedManyWithoutSequenceInput>;
  EndStepsTransfer?: InputMaybe<SequenceStepCreateNestedManyWithoutTransferToSequenceInput>;
  exit_count?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  is_paused?: InputMaybe<Scalars['Boolean']['input']>;
  last_updated_by: UserCreateNestedOneWithoutSequences_Modified_By_UserInput;
  leadActivities?: InputMaybe<LeadActivityCreateNestedManyWithoutLead_Current_SequenceInput>;
  LeadAssignmentHistory?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutSequence_Not_InterestedInput>;
  leads?: InputMaybe<LeadCreateNestedManyWithoutSequenceInput>;
  manager_feedback_count?: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
  not_interested_count?: InputMaybe<Scalars['Int']['input']>;
  organzation?: InputMaybe<OrganizationCreateNestedOneWithoutSequenceInput>;
  priority: Scalars['Int']['input'];
  Sale?: InputMaybe<SaleCreateNestedManyWithoutConverted_SequenceInput>;
  sequence_steps?: InputMaybe<SequenceStepCreateNestedManyWithoutSequenceInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutSequenceInput>;
  sms_bounced_perc?: InputMaybe<Scalars['Float']['input']>;
  sms_delivered_perc?: InputMaybe<Scalars['Float']['input']>;
  sms_unsubscribed_perc?: InputMaybe<Scalars['Float']['input']>;
  SMSItem?: InputMaybe<SmsItemCreateNestedManyWithoutSequenceInput>;
  SnoozeTracking?: InputMaybe<SnoozeTrackingCreateNestedManyWithoutSequenceInput>;
  StatItems?: InputMaybe<StatItemAggregationCreateNestedManyWithoutSequenceInput>;
  StepActionLog?: InputMaybe<StepActionLogCreateNestedManyWithoutSequenceInput>;
  steps_count?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type SequenceCreateWithoutSequence_StepsInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  completed_count?: InputMaybe<Scalars['Int']['input']>;
  conversion_count?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  created_by: UserCreateNestedOneWithoutSequences_CreatedInput;
  deleted_at?: InputMaybe<Scalars['DateTime']['input']>;
  email_bounced_perc?: InputMaybe<Scalars['Float']['input']>;
  email_clicked_perc?: InputMaybe<Scalars['Float']['input']>;
  email_delivered_perc?: InputMaybe<Scalars['Float']['input']>;
  email_opened_perc?: InputMaybe<Scalars['Float']['input']>;
  email_replied_perc?: InputMaybe<Scalars['Float']['input']>;
  email_unsubscribed_perc?: InputMaybe<Scalars['Float']['input']>;
  EmailItem?: InputMaybe<EmailItemCreateNestedManyWithoutSequenceInput>;
  EndStepsTransfer?: InputMaybe<SequenceStepCreateNestedManyWithoutTransferToSequenceInput>;
  exit_count?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  is_paused?: InputMaybe<Scalars['Boolean']['input']>;
  last_updated_by: UserCreateNestedOneWithoutSequences_Modified_By_UserInput;
  leadActivities?: InputMaybe<LeadActivityCreateNestedManyWithoutLead_Current_SequenceInput>;
  LeadAssignmentHistory?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutSequence_Not_InterestedInput>;
  leads?: InputMaybe<LeadCreateNestedManyWithoutSequenceInput>;
  manager_feedback_count?: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
  not_interested_count?: InputMaybe<Scalars['Int']['input']>;
  organzation?: InputMaybe<OrganizationCreateNestedOneWithoutSequenceInput>;
  priority: Scalars['Int']['input'];
  Sale?: InputMaybe<SaleCreateNestedManyWithoutConverted_SequenceInput>;
  sequence_entry_criteria?: InputMaybe<SequenceEntryCriteriaCreateNestedOneWithoutSequenceInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutSequenceInput>;
  sms_bounced_perc?: InputMaybe<Scalars['Float']['input']>;
  sms_delivered_perc?: InputMaybe<Scalars['Float']['input']>;
  sms_unsubscribed_perc?: InputMaybe<Scalars['Float']['input']>;
  SMSItem?: InputMaybe<SmsItemCreateNestedManyWithoutSequenceInput>;
  SnoozeTracking?: InputMaybe<SnoozeTrackingCreateNestedManyWithoutSequenceInput>;
  StatItems?: InputMaybe<StatItemAggregationCreateNestedManyWithoutSequenceInput>;
  StepActionLog?: InputMaybe<StepActionLogCreateNestedManyWithoutSequenceInput>;
  steps_count?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type SequenceCreateWithoutSequenceEntryExitLeadInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  completed_count?: InputMaybe<Scalars['Int']['input']>;
  conversion_count?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  created_by: UserCreateNestedOneWithoutSequences_CreatedInput;
  deleted_at?: InputMaybe<Scalars['DateTime']['input']>;
  email_bounced_perc?: InputMaybe<Scalars['Float']['input']>;
  email_clicked_perc?: InputMaybe<Scalars['Float']['input']>;
  email_delivered_perc?: InputMaybe<Scalars['Float']['input']>;
  email_opened_perc?: InputMaybe<Scalars['Float']['input']>;
  email_replied_perc?: InputMaybe<Scalars['Float']['input']>;
  email_unsubscribed_perc?: InputMaybe<Scalars['Float']['input']>;
  EmailItem?: InputMaybe<EmailItemCreateNestedManyWithoutSequenceInput>;
  EndStepsTransfer?: InputMaybe<SequenceStepCreateNestedManyWithoutTransferToSequenceInput>;
  exit_count?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  is_paused?: InputMaybe<Scalars['Boolean']['input']>;
  last_updated_by: UserCreateNestedOneWithoutSequences_Modified_By_UserInput;
  leadActivities?: InputMaybe<LeadActivityCreateNestedManyWithoutLead_Current_SequenceInput>;
  LeadAssignmentHistory?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutSequence_Not_InterestedInput>;
  leads?: InputMaybe<LeadCreateNestedManyWithoutSequenceInput>;
  manager_feedback_count?: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
  not_interested_count?: InputMaybe<Scalars['Int']['input']>;
  organzation?: InputMaybe<OrganizationCreateNestedOneWithoutSequenceInput>;
  priority: Scalars['Int']['input'];
  Sale?: InputMaybe<SaleCreateNestedManyWithoutConverted_SequenceInput>;
  sequence_entry_criteria?: InputMaybe<SequenceEntryCriteriaCreateNestedOneWithoutSequenceInput>;
  sequence_steps?: InputMaybe<SequenceStepCreateNestedManyWithoutSequenceInput>;
  sms_bounced_perc?: InputMaybe<Scalars['Float']['input']>;
  sms_delivered_perc?: InputMaybe<Scalars['Float']['input']>;
  sms_unsubscribed_perc?: InputMaybe<Scalars['Float']['input']>;
  SMSItem?: InputMaybe<SmsItemCreateNestedManyWithoutSequenceInput>;
  SnoozeTracking?: InputMaybe<SnoozeTrackingCreateNestedManyWithoutSequenceInput>;
  StatItems?: InputMaybe<StatItemAggregationCreateNestedManyWithoutSequenceInput>;
  StepActionLog?: InputMaybe<StepActionLogCreateNestedManyWithoutSequenceInput>;
  steps_count?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type SequenceCreateWithoutSmsItemInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  completed_count?: InputMaybe<Scalars['Int']['input']>;
  conversion_count?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  created_by: UserCreateNestedOneWithoutSequences_CreatedInput;
  deleted_at?: InputMaybe<Scalars['DateTime']['input']>;
  email_bounced_perc?: InputMaybe<Scalars['Float']['input']>;
  email_clicked_perc?: InputMaybe<Scalars['Float']['input']>;
  email_delivered_perc?: InputMaybe<Scalars['Float']['input']>;
  email_opened_perc?: InputMaybe<Scalars['Float']['input']>;
  email_replied_perc?: InputMaybe<Scalars['Float']['input']>;
  email_unsubscribed_perc?: InputMaybe<Scalars['Float']['input']>;
  EmailItem?: InputMaybe<EmailItemCreateNestedManyWithoutSequenceInput>;
  EndStepsTransfer?: InputMaybe<SequenceStepCreateNestedManyWithoutTransferToSequenceInput>;
  exit_count?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  is_paused?: InputMaybe<Scalars['Boolean']['input']>;
  last_updated_by: UserCreateNestedOneWithoutSequences_Modified_By_UserInput;
  leadActivities?: InputMaybe<LeadActivityCreateNestedManyWithoutLead_Current_SequenceInput>;
  LeadAssignmentHistory?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutSequence_Not_InterestedInput>;
  leads?: InputMaybe<LeadCreateNestedManyWithoutSequenceInput>;
  manager_feedback_count?: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
  not_interested_count?: InputMaybe<Scalars['Int']['input']>;
  organzation?: InputMaybe<OrganizationCreateNestedOneWithoutSequenceInput>;
  priority: Scalars['Int']['input'];
  Sale?: InputMaybe<SaleCreateNestedManyWithoutConverted_SequenceInput>;
  sequence_entry_criteria?: InputMaybe<SequenceEntryCriteriaCreateNestedOneWithoutSequenceInput>;
  sequence_steps?: InputMaybe<SequenceStepCreateNestedManyWithoutSequenceInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutSequenceInput>;
  sms_bounced_perc?: InputMaybe<Scalars['Float']['input']>;
  sms_delivered_perc?: InputMaybe<Scalars['Float']['input']>;
  sms_unsubscribed_perc?: InputMaybe<Scalars['Float']['input']>;
  SnoozeTracking?: InputMaybe<SnoozeTrackingCreateNestedManyWithoutSequenceInput>;
  StatItems?: InputMaybe<StatItemAggregationCreateNestedManyWithoutSequenceInput>;
  StepActionLog?: InputMaybe<StepActionLogCreateNestedManyWithoutSequenceInput>;
  steps_count?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type SequenceCreateWithoutSnoozeTrackingInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  completed_count?: InputMaybe<Scalars['Int']['input']>;
  conversion_count?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  created_by: UserCreateNestedOneWithoutSequences_CreatedInput;
  deleted_at?: InputMaybe<Scalars['DateTime']['input']>;
  email_bounced_perc?: InputMaybe<Scalars['Float']['input']>;
  email_clicked_perc?: InputMaybe<Scalars['Float']['input']>;
  email_delivered_perc?: InputMaybe<Scalars['Float']['input']>;
  email_opened_perc?: InputMaybe<Scalars['Float']['input']>;
  email_replied_perc?: InputMaybe<Scalars['Float']['input']>;
  email_unsubscribed_perc?: InputMaybe<Scalars['Float']['input']>;
  EmailItem?: InputMaybe<EmailItemCreateNestedManyWithoutSequenceInput>;
  EndStepsTransfer?: InputMaybe<SequenceStepCreateNestedManyWithoutTransferToSequenceInput>;
  exit_count?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  is_paused?: InputMaybe<Scalars['Boolean']['input']>;
  last_updated_by: UserCreateNestedOneWithoutSequences_Modified_By_UserInput;
  leadActivities?: InputMaybe<LeadActivityCreateNestedManyWithoutLead_Current_SequenceInput>;
  LeadAssignmentHistory?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutSequence_Not_InterestedInput>;
  leads?: InputMaybe<LeadCreateNestedManyWithoutSequenceInput>;
  manager_feedback_count?: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
  not_interested_count?: InputMaybe<Scalars['Int']['input']>;
  organzation?: InputMaybe<OrganizationCreateNestedOneWithoutSequenceInput>;
  priority: Scalars['Int']['input'];
  Sale?: InputMaybe<SaleCreateNestedManyWithoutConverted_SequenceInput>;
  sequence_entry_criteria?: InputMaybe<SequenceEntryCriteriaCreateNestedOneWithoutSequenceInput>;
  sequence_steps?: InputMaybe<SequenceStepCreateNestedManyWithoutSequenceInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutSequenceInput>;
  sms_bounced_perc?: InputMaybe<Scalars['Float']['input']>;
  sms_delivered_perc?: InputMaybe<Scalars['Float']['input']>;
  sms_unsubscribed_perc?: InputMaybe<Scalars['Float']['input']>;
  SMSItem?: InputMaybe<SmsItemCreateNestedManyWithoutSequenceInput>;
  StatItems?: InputMaybe<StatItemAggregationCreateNestedManyWithoutSequenceInput>;
  StepActionLog?: InputMaybe<StepActionLogCreateNestedManyWithoutSequenceInput>;
  steps_count?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type SequenceCreateWithoutStatItemsInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  completed_count?: InputMaybe<Scalars['Int']['input']>;
  conversion_count?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  created_by: UserCreateNestedOneWithoutSequences_CreatedInput;
  deleted_at?: InputMaybe<Scalars['DateTime']['input']>;
  email_bounced_perc?: InputMaybe<Scalars['Float']['input']>;
  email_clicked_perc?: InputMaybe<Scalars['Float']['input']>;
  email_delivered_perc?: InputMaybe<Scalars['Float']['input']>;
  email_opened_perc?: InputMaybe<Scalars['Float']['input']>;
  email_replied_perc?: InputMaybe<Scalars['Float']['input']>;
  email_unsubscribed_perc?: InputMaybe<Scalars['Float']['input']>;
  EmailItem?: InputMaybe<EmailItemCreateNestedManyWithoutSequenceInput>;
  EndStepsTransfer?: InputMaybe<SequenceStepCreateNestedManyWithoutTransferToSequenceInput>;
  exit_count?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  is_paused?: InputMaybe<Scalars['Boolean']['input']>;
  last_updated_by: UserCreateNestedOneWithoutSequences_Modified_By_UserInput;
  leadActivities?: InputMaybe<LeadActivityCreateNestedManyWithoutLead_Current_SequenceInput>;
  LeadAssignmentHistory?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutSequence_Not_InterestedInput>;
  leads?: InputMaybe<LeadCreateNestedManyWithoutSequenceInput>;
  manager_feedback_count?: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
  not_interested_count?: InputMaybe<Scalars['Int']['input']>;
  organzation?: InputMaybe<OrganizationCreateNestedOneWithoutSequenceInput>;
  priority: Scalars['Int']['input'];
  Sale?: InputMaybe<SaleCreateNestedManyWithoutConverted_SequenceInput>;
  sequence_entry_criteria?: InputMaybe<SequenceEntryCriteriaCreateNestedOneWithoutSequenceInput>;
  sequence_steps?: InputMaybe<SequenceStepCreateNestedManyWithoutSequenceInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutSequenceInput>;
  sms_bounced_perc?: InputMaybe<Scalars['Float']['input']>;
  sms_delivered_perc?: InputMaybe<Scalars['Float']['input']>;
  sms_unsubscribed_perc?: InputMaybe<Scalars['Float']['input']>;
  SMSItem?: InputMaybe<SmsItemCreateNestedManyWithoutSequenceInput>;
  SnoozeTracking?: InputMaybe<SnoozeTrackingCreateNestedManyWithoutSequenceInput>;
  StepActionLog?: InputMaybe<StepActionLogCreateNestedManyWithoutSequenceInput>;
  steps_count?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type SequenceCreateWithoutStepActionLogInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  completed_count?: InputMaybe<Scalars['Int']['input']>;
  conversion_count?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  created_by: UserCreateNestedOneWithoutSequences_CreatedInput;
  deleted_at?: InputMaybe<Scalars['DateTime']['input']>;
  email_bounced_perc?: InputMaybe<Scalars['Float']['input']>;
  email_clicked_perc?: InputMaybe<Scalars['Float']['input']>;
  email_delivered_perc?: InputMaybe<Scalars['Float']['input']>;
  email_opened_perc?: InputMaybe<Scalars['Float']['input']>;
  email_replied_perc?: InputMaybe<Scalars['Float']['input']>;
  email_unsubscribed_perc?: InputMaybe<Scalars['Float']['input']>;
  EmailItem?: InputMaybe<EmailItemCreateNestedManyWithoutSequenceInput>;
  EndStepsTransfer?: InputMaybe<SequenceStepCreateNestedManyWithoutTransferToSequenceInput>;
  exit_count?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  is_paused?: InputMaybe<Scalars['Boolean']['input']>;
  last_updated_by: UserCreateNestedOneWithoutSequences_Modified_By_UserInput;
  leadActivities?: InputMaybe<LeadActivityCreateNestedManyWithoutLead_Current_SequenceInput>;
  LeadAssignmentHistory?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutSequence_Not_InterestedInput>;
  leads?: InputMaybe<LeadCreateNestedManyWithoutSequenceInput>;
  manager_feedback_count?: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
  not_interested_count?: InputMaybe<Scalars['Int']['input']>;
  organzation?: InputMaybe<OrganizationCreateNestedOneWithoutSequenceInput>;
  priority: Scalars['Int']['input'];
  Sale?: InputMaybe<SaleCreateNestedManyWithoutConverted_SequenceInput>;
  sequence_entry_criteria?: InputMaybe<SequenceEntryCriteriaCreateNestedOneWithoutSequenceInput>;
  sequence_steps?: InputMaybe<SequenceStepCreateNestedManyWithoutSequenceInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutSequenceInput>;
  sms_bounced_perc?: InputMaybe<Scalars['Float']['input']>;
  sms_delivered_perc?: InputMaybe<Scalars['Float']['input']>;
  sms_unsubscribed_perc?: InputMaybe<Scalars['Float']['input']>;
  SMSItem?: InputMaybe<SmsItemCreateNestedManyWithoutSequenceInput>;
  SnoozeTracking?: InputMaybe<SnoozeTrackingCreateNestedManyWithoutSequenceInput>;
  StatItems?: InputMaybe<StatItemAggregationCreateNestedManyWithoutSequenceInput>;
  steps_count?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type SequenceCriteriaCondition = {
  __typename?: 'SequenceCriteriaCondition';
  condition_type: ConditionType;
  created_at: Scalars['DateTime']['output'];
  custom_field_type?: Maybe<CustomFieldType>;
  field?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  operator: Operator;
  sequence_criteria: SequenceEntryCriteria;
  sequence_criteria_id: Scalars['String']['output'];
  value: Array<Scalars['String']['output']>;
};

export type SequenceCriteriaConditionCreateManyOrganizationInput = {
  condition_type: ConditionType;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  custom_field_type?: InputMaybe<CustomFieldType>;
  field?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  operator: Operator;
  sequence_criteria_id: Scalars['String']['input'];
  value?: InputMaybe<SequenceCriteriaConditionCreateManyvalueInput>;
};

export type SequenceCriteriaConditionCreateManyOrganizationInputEnvelope = {
  data?: InputMaybe<Array<SequenceCriteriaConditionCreateManyOrganizationInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SequenceCriteriaConditionCreateManySequence_CriteriaInput = {
  condition_type: ConditionType;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  custom_field_type?: InputMaybe<CustomFieldType>;
  field?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  operator: Operator;
  organization_id?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<SequenceCriteriaConditionCreateManyvalueInput>;
};

export type SequenceCriteriaConditionCreateManySequence_CriteriaInputEnvelope = {
  data?: InputMaybe<Array<SequenceCriteriaConditionCreateManySequence_CriteriaInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SequenceCriteriaConditionCreateManyvalueInput = {
  set?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type SequenceCriteriaConditionCreateNestedManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<SequenceCriteriaConditionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SequenceCriteriaConditionCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<SequenceCriteriaConditionCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<SequenceCriteriaConditionCreateManyOrganizationInputEnvelope>;
};

export type SequenceCriteriaConditionCreateNestedManyWithoutSequence_CriteriaInput = {
  connect?: InputMaybe<Array<SequenceCriteriaConditionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SequenceCriteriaConditionCreateOrConnectWithoutSequence_CriteriaInput>>;
  create?: InputMaybe<Array<SequenceCriteriaConditionCreateWithoutSequence_CriteriaInput>>;
  createMany?: InputMaybe<SequenceCriteriaConditionCreateManySequence_CriteriaInputEnvelope>;
};

export type SequenceCriteriaConditionCreateOrConnectWithoutOrganizationInput = {
  create: SequenceCriteriaConditionCreateWithoutOrganizationInput;
  where: SequenceCriteriaConditionWhereUniqueInput;
};

export type SequenceCriteriaConditionCreateOrConnectWithoutSequence_CriteriaInput = {
  create: SequenceCriteriaConditionCreateWithoutSequence_CriteriaInput;
  where: SequenceCriteriaConditionWhereUniqueInput;
};

export type SequenceCriteriaConditionCreatevalueInput = {
  set?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type SequenceCriteriaConditionCreateWithoutOrganizationInput = {
  condition_type: ConditionType;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  custom_field_type?: InputMaybe<CustomFieldType>;
  field?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  operator: Operator;
  sequence_criteria: SequenceEntryCriteriaCreateNestedOneWithoutSequence_Criteria_ConditionInput;
  value?: InputMaybe<SequenceCriteriaConditionCreatevalueInput>;
};

export type SequenceCriteriaConditionCreateWithoutSequence_CriteriaInput = {
  condition_type: ConditionType;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  custom_field_type?: InputMaybe<CustomFieldType>;
  field?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  operator: Operator;
  Organization?: InputMaybe<OrganizationCreateNestedOneWithoutSequenceCriteriaConditionsInput>;
  value?: InputMaybe<SequenceCriteriaConditionCreatevalueInput>;
};

/** Condition input holder */
export type SequenceCriteriaConditionInput = {
  conditionType: ConditionType;
  operator: Operator;
  value?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type SequenceCriteriaConditionListRelationFilter = {
  every?: InputMaybe<SequenceCriteriaConditionWhereInput>;
  none?: InputMaybe<SequenceCriteriaConditionWhereInput>;
  some?: InputMaybe<SequenceCriteriaConditionWhereInput>;
};

export type SequenceCriteriaConditionOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
  count?: InputMaybe<SortOrder>;
};

export type SequenceCriteriaConditionScalarWhereInput = {
  AND?: InputMaybe<Array<SequenceCriteriaConditionScalarWhereInput>>;
  condition_type?: InputMaybe<EnumconditionTypeFilter>;
  created_at?: InputMaybe<DateTimeFilter>;
  custom_field_type?: InputMaybe<EnumCustomFieldTypeNullableFilter>;
  field?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  NOT?: InputMaybe<Array<SequenceCriteriaConditionScalarWhereInput>>;
  operator?: InputMaybe<EnumOperatorFilter>;
  OR?: InputMaybe<Array<SequenceCriteriaConditionScalarWhereInput>>;
  organization_id?: InputMaybe<StringNullableFilter>;
  sequence_criteria_id?: InputMaybe<StringFilter>;
  value?: InputMaybe<StringNullableListFilter>;
};

export type SequenceCriteriaConditionUpdateManyMutationInput = {
  condition_type?: InputMaybe<EnumconditionTypeFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  custom_field_type?: InputMaybe<NullableEnumCustomFieldTypeFieldUpdateOperationsInput>;
  field?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  operator?: InputMaybe<EnumOperatorFieldUpdateOperationsInput>;
  value?: InputMaybe<SequenceCriteriaConditionUpdatevalueInput>;
};

export type SequenceCriteriaConditionUpdateManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<SequenceCriteriaConditionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SequenceCriteriaConditionCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<SequenceCriteriaConditionCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<SequenceCriteriaConditionCreateManyOrganizationInputEnvelope>;
  delete?: InputMaybe<Array<SequenceCriteriaConditionWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<SequenceCriteriaConditionScalarWhereInput>>;
  disconnect?: InputMaybe<Array<SequenceCriteriaConditionWhereUniqueInput>>;
  set?: InputMaybe<Array<SequenceCriteriaConditionWhereUniqueInput>>;
  update?: InputMaybe<Array<SequenceCriteriaConditionUpdateWithWhereUniqueWithoutOrganizationInput>>;
  updateMany?: InputMaybe<Array<SequenceCriteriaConditionUpdateManyWithWhereWithoutOrganizationInput>>;
  upsert?: InputMaybe<Array<SequenceCriteriaConditionUpsertWithWhereUniqueWithoutOrganizationInput>>;
};

export type SequenceCriteriaConditionUpdateManyWithoutSequence_CriteriaInput = {
  connect?: InputMaybe<Array<SequenceCriteriaConditionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SequenceCriteriaConditionCreateOrConnectWithoutSequence_CriteriaInput>>;
  create?: InputMaybe<Array<SequenceCriteriaConditionCreateWithoutSequence_CriteriaInput>>;
  createMany?: InputMaybe<SequenceCriteriaConditionCreateManySequence_CriteriaInputEnvelope>;
  delete?: InputMaybe<Array<SequenceCriteriaConditionWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<SequenceCriteriaConditionScalarWhereInput>>;
  disconnect?: InputMaybe<Array<SequenceCriteriaConditionWhereUniqueInput>>;
  set?: InputMaybe<Array<SequenceCriteriaConditionWhereUniqueInput>>;
  update?: InputMaybe<Array<SequenceCriteriaConditionUpdateWithWhereUniqueWithoutSequence_CriteriaInput>>;
  updateMany?: InputMaybe<Array<SequenceCriteriaConditionUpdateManyWithWhereWithoutSequence_CriteriaInput>>;
  upsert?: InputMaybe<Array<SequenceCriteriaConditionUpsertWithWhereUniqueWithoutSequence_CriteriaInput>>;
};

export type SequenceCriteriaConditionUpdateManyWithWhereWithoutOrganizationInput = {
  data: SequenceCriteriaConditionUpdateManyMutationInput;
  where: SequenceCriteriaConditionScalarWhereInput;
};

export type SequenceCriteriaConditionUpdateManyWithWhereWithoutSequence_CriteriaInput = {
  data: SequenceCriteriaConditionUpdateManyMutationInput;
  where: SequenceCriteriaConditionScalarWhereInput;
};

export type SequenceCriteriaConditionUpdatevalueInput = {
  push?: InputMaybe<Scalars['String']['input']>;
  set?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type SequenceCriteriaConditionUpdateWithoutOrganizationInput = {
  condition_type?: InputMaybe<EnumconditionTypeFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  custom_field_type?: InputMaybe<NullableEnumCustomFieldTypeFieldUpdateOperationsInput>;
  field?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  operator?: InputMaybe<EnumOperatorFieldUpdateOperationsInput>;
  sequence_criteria?: InputMaybe<SequenceEntryCriteriaUpdateOneRequiredWithoutSequence_Criteria_ConditionInput>;
  value?: InputMaybe<SequenceCriteriaConditionUpdatevalueInput>;
};

export type SequenceCriteriaConditionUpdateWithoutSequence_CriteriaInput = {
  condition_type?: InputMaybe<EnumconditionTypeFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  custom_field_type?: InputMaybe<NullableEnumCustomFieldTypeFieldUpdateOperationsInput>;
  field?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  operator?: InputMaybe<EnumOperatorFieldUpdateOperationsInput>;
  Organization?: InputMaybe<OrganizationUpdateOneWithoutSequenceCriteriaConditionsInput>;
  value?: InputMaybe<SequenceCriteriaConditionUpdatevalueInput>;
};

export type SequenceCriteriaConditionUpdateWithWhereUniqueWithoutOrganizationInput = {
  data: SequenceCriteriaConditionUpdateWithoutOrganizationInput;
  where: SequenceCriteriaConditionWhereUniqueInput;
};

export type SequenceCriteriaConditionUpdateWithWhereUniqueWithoutSequence_CriteriaInput = {
  data: SequenceCriteriaConditionUpdateWithoutSequence_CriteriaInput;
  where: SequenceCriteriaConditionWhereUniqueInput;
};

export type SequenceCriteriaConditionUpsertWithWhereUniqueWithoutOrganizationInput = {
  create: SequenceCriteriaConditionCreateWithoutOrganizationInput;
  update: SequenceCriteriaConditionUpdateWithoutOrganizationInput;
  where: SequenceCriteriaConditionWhereUniqueInput;
};

export type SequenceCriteriaConditionUpsertWithWhereUniqueWithoutSequence_CriteriaInput = {
  create: SequenceCriteriaConditionCreateWithoutSequence_CriteriaInput;
  update: SequenceCriteriaConditionUpdateWithoutSequence_CriteriaInput;
  where: SequenceCriteriaConditionWhereUniqueInput;
};

export type SequenceCriteriaConditionWhereInput = {
  AND?: InputMaybe<Array<SequenceCriteriaConditionWhereInput>>;
  condition_type?: InputMaybe<EnumconditionTypeFilter>;
  created_at?: InputMaybe<DateTimeFilter>;
  custom_field_type?: InputMaybe<EnumCustomFieldTypeNullableFilter>;
  field?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  NOT?: InputMaybe<Array<SequenceCriteriaConditionWhereInput>>;
  operator?: InputMaybe<EnumOperatorFilter>;
  OR?: InputMaybe<Array<SequenceCriteriaConditionWhereInput>>;
  Organization?: InputMaybe<OrganizationWhereInput>;
  organization_id?: InputMaybe<StringNullableFilter>;
  sequence_criteria?: InputMaybe<SequenceEntryCriteriaWhereInput>;
  sequence_criteria_id?: InputMaybe<StringFilter>;
  value?: InputMaybe<StringNullableListFilter>;
};

export type SequenceCriteriaConditionWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']['input']>;
};

/** SequenceCriteriaInput for creation and update of sequence criteria */
export type SequenceCriteriaInput = {
  channels?: InputMaybe<Array<Channel>>;
  conditions?: InputMaybe<Array<SequenceCriteriaConditionInput>>;
  custom_fields?: InputMaybe<Array<SequenceCustomFieldsConditionInput>>;
  entry_phases?: InputMaybe<Array<Phase>>;
  person_spoke_to?: InputMaybe<Array<InputMaybe<Callresult>>>;
  sequence_id: Scalars['String']['input'];
  types?: InputMaybe<Array<InputMaybe<Dispositiontype>>>;
};

/** Custom fields input holder */
export type SequenceCustomFieldsConditionInput = {
  conditionType?: InputMaybe<ConditionType>;
  customFieldType: CustomFieldType;
  field?: InputMaybe<Scalars['String']['input']>;
  operator: Operator;
  value?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** saved sequence dashboard for creation and update */
export type SequenceDashboardInput = {
  lowerbound_date?: InputMaybe<Scalars['Date']['input']>;
  sequence_ids?: InputMaybe<Array<Scalars['String']['input']>>;
  site_ids?: InputMaybe<Array<Scalars['String']['input']>>;
  team_ids?: InputMaybe<Array<Scalars['String']['input']>>;
  upperbound_date?: InputMaybe<Scalars['Date']['input']>;
  user_ids?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type SequenceDashboardView = {
  __typename?: 'SequenceDashboardView';
  created_user_id: Scalars['String']['output'];
  deleted_at?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['String']['output'];
  owned_by: User;
  sequence_reporting_filters?: Maybe<Scalars['Json']['output']>;
  sequence_reporting_segments?: Maybe<Scalars['Json']['output']>;
  user: Array<User>;
  view_name: Scalars['String']['output'];
};


export type SequenceDashboardViewUserArgs = {
  after?: InputMaybe<UserWhereUniqueInput>;
  before?: InputMaybe<UserWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type SequenceDashboardViewCreateManyOrganizationInput = {
  created_user_id: Scalars['String']['input'];
  deleted_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  sequence_reporting_filters?: InputMaybe<Scalars['Json']['input']>;
  sequence_reporting_segments?: InputMaybe<Scalars['Json']['input']>;
  view_name: Scalars['String']['input'];
};

export type SequenceDashboardViewCreateManyOrganizationInputEnvelope = {
  data?: InputMaybe<Array<SequenceDashboardViewCreateManyOrganizationInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SequenceDashboardViewCreateManyOwned_ByInput = {
  deleted_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  organization_id?: InputMaybe<Scalars['String']['input']>;
  sequence_reporting_filters?: InputMaybe<Scalars['Json']['input']>;
  sequence_reporting_segments?: InputMaybe<Scalars['Json']['input']>;
  view_name: Scalars['String']['input'];
};

export type SequenceDashboardViewCreateManyOwned_ByInputEnvelope = {
  data?: InputMaybe<Array<SequenceDashboardViewCreateManyOwned_ByInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SequenceDashboardViewCreateNestedManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<SequenceDashboardViewWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SequenceDashboardViewCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<SequenceDashboardViewCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<SequenceDashboardViewCreateManyOrganizationInputEnvelope>;
};

export type SequenceDashboardViewCreateNestedManyWithoutOwned_ByInput = {
  connect?: InputMaybe<Array<SequenceDashboardViewWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SequenceDashboardViewCreateOrConnectWithoutOwned_ByInput>>;
  create?: InputMaybe<Array<SequenceDashboardViewCreateWithoutOwned_ByInput>>;
  createMany?: InputMaybe<SequenceDashboardViewCreateManyOwned_ByInputEnvelope>;
};

export type SequenceDashboardViewCreateNestedOneWithoutUserInput = {
  connect?: InputMaybe<SequenceDashboardViewWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SequenceDashboardViewCreateOrConnectWithoutUserInput>;
  create?: InputMaybe<SequenceDashboardViewCreateWithoutUserInput>;
};

export type SequenceDashboardViewCreateOrConnectWithoutOrganizationInput = {
  create: SequenceDashboardViewCreateWithoutOrganizationInput;
  where: SequenceDashboardViewWhereUniqueInput;
};

export type SequenceDashboardViewCreateOrConnectWithoutOwned_ByInput = {
  create: SequenceDashboardViewCreateWithoutOwned_ByInput;
  where: SequenceDashboardViewWhereUniqueInput;
};

export type SequenceDashboardViewCreateOrConnectWithoutUserInput = {
  create: SequenceDashboardViewCreateWithoutUserInput;
  where: SequenceDashboardViewWhereUniqueInput;
};

export type SequenceDashboardViewCreateWithoutOrganizationInput = {
  deleted_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  owned_by: UserCreateNestedOneWithoutSequenceDashboardViewInput;
  sequence_reporting_filters?: InputMaybe<Scalars['Json']['input']>;
  sequence_reporting_segments?: InputMaybe<Scalars['Json']['input']>;
  user?: InputMaybe<UserCreateNestedManyWithoutCurrent_Sequence_ViewInput>;
  view_name: Scalars['String']['input'];
};

export type SequenceDashboardViewCreateWithoutOwned_ByInput = {
  deleted_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  Organization?: InputMaybe<OrganizationCreateNestedOneWithoutSequenceDashboardViewsInput>;
  sequence_reporting_filters?: InputMaybe<Scalars['Json']['input']>;
  sequence_reporting_segments?: InputMaybe<Scalars['Json']['input']>;
  user?: InputMaybe<UserCreateNestedManyWithoutCurrent_Sequence_ViewInput>;
  view_name: Scalars['String']['input'];
};

export type SequenceDashboardViewCreateWithoutUserInput = {
  deleted_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  Organization?: InputMaybe<OrganizationCreateNestedOneWithoutSequenceDashboardViewsInput>;
  owned_by: UserCreateNestedOneWithoutSequenceDashboardViewInput;
  sequence_reporting_filters?: InputMaybe<Scalars['Json']['input']>;
  sequence_reporting_segments?: InputMaybe<Scalars['Json']['input']>;
  view_name: Scalars['String']['input'];
};

/** saved sequence dashboard for creation and update */
export type SequenceDashboardViewInput = {
  sequence_reporting_filters: Scalars['Json']['input'];
  sequence_reporting_segments: Scalars['Json']['input'];
  view_id?: InputMaybe<Scalars['String']['input']>;
  view_name: Scalars['String']['input'];
};

export type SequenceDashboardViewListRelationFilter = {
  every?: InputMaybe<SequenceDashboardViewWhereInput>;
  none?: InputMaybe<SequenceDashboardViewWhereInput>;
  some?: InputMaybe<SequenceDashboardViewWhereInput>;
};

export type SequenceDashboardViewOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
  count?: InputMaybe<SortOrder>;
};

export type SequenceDashboardViewOrderByWithRelationInput = {
  created_user_id?: InputMaybe<SortOrder>;
  deleted_at?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  Organization?: InputMaybe<OrganizationOrderByWithRelationInput>;
  organization_id?: InputMaybe<SortOrder>;
  owned_by?: InputMaybe<UserOrderByWithRelationInput>;
  sequence_reporting_filters?: InputMaybe<SortOrder>;
  sequence_reporting_segments?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserOrderByRelationAggregateInput>;
  view_name?: InputMaybe<SortOrder>;
};

export type SequenceDashboardViewScalarWhereInput = {
  AND?: InputMaybe<Array<SequenceDashboardViewScalarWhereInput>>;
  created_user_id?: InputMaybe<StringFilter>;
  deleted_at?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<StringFilter>;
  NOT?: InputMaybe<Array<SequenceDashboardViewScalarWhereInput>>;
  OR?: InputMaybe<Array<SequenceDashboardViewScalarWhereInput>>;
  organization_id?: InputMaybe<StringNullableFilter>;
  sequence_reporting_filters?: InputMaybe<JsonNullableFilter>;
  sequence_reporting_segments?: InputMaybe<JsonNullableFilter>;
  view_name?: InputMaybe<StringFilter>;
};

export type SequenceDashboardViewUpdateManyMutationInput = {
  deleted_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  sequence_reporting_filters?: InputMaybe<Scalars['Json']['input']>;
  sequence_reporting_segments?: InputMaybe<Scalars['Json']['input']>;
  view_name?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type SequenceDashboardViewUpdateManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<SequenceDashboardViewWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SequenceDashboardViewCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<SequenceDashboardViewCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<SequenceDashboardViewCreateManyOrganizationInputEnvelope>;
  delete?: InputMaybe<Array<SequenceDashboardViewWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<SequenceDashboardViewScalarWhereInput>>;
  disconnect?: InputMaybe<Array<SequenceDashboardViewWhereUniqueInput>>;
  set?: InputMaybe<Array<SequenceDashboardViewWhereUniqueInput>>;
  update?: InputMaybe<Array<SequenceDashboardViewUpdateWithWhereUniqueWithoutOrganizationInput>>;
  updateMany?: InputMaybe<Array<SequenceDashboardViewUpdateManyWithWhereWithoutOrganizationInput>>;
  upsert?: InputMaybe<Array<SequenceDashboardViewUpsertWithWhereUniqueWithoutOrganizationInput>>;
};

export type SequenceDashboardViewUpdateManyWithoutOwned_ByInput = {
  connect?: InputMaybe<Array<SequenceDashboardViewWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SequenceDashboardViewCreateOrConnectWithoutOwned_ByInput>>;
  create?: InputMaybe<Array<SequenceDashboardViewCreateWithoutOwned_ByInput>>;
  createMany?: InputMaybe<SequenceDashboardViewCreateManyOwned_ByInputEnvelope>;
  delete?: InputMaybe<Array<SequenceDashboardViewWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<SequenceDashboardViewScalarWhereInput>>;
  disconnect?: InputMaybe<Array<SequenceDashboardViewWhereUniqueInput>>;
  set?: InputMaybe<Array<SequenceDashboardViewWhereUniqueInput>>;
  update?: InputMaybe<Array<SequenceDashboardViewUpdateWithWhereUniqueWithoutOwned_ByInput>>;
  updateMany?: InputMaybe<Array<SequenceDashboardViewUpdateManyWithWhereWithoutOwned_ByInput>>;
  upsert?: InputMaybe<Array<SequenceDashboardViewUpsertWithWhereUniqueWithoutOwned_ByInput>>;
};

export type SequenceDashboardViewUpdateManyWithWhereWithoutOrganizationInput = {
  data: SequenceDashboardViewUpdateManyMutationInput;
  where: SequenceDashboardViewScalarWhereInput;
};

export type SequenceDashboardViewUpdateManyWithWhereWithoutOwned_ByInput = {
  data: SequenceDashboardViewUpdateManyMutationInput;
  where: SequenceDashboardViewScalarWhereInput;
};

export type SequenceDashboardViewUpdateOneWithoutUserInput = {
  connect?: InputMaybe<SequenceDashboardViewWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SequenceDashboardViewCreateOrConnectWithoutUserInput>;
  create?: InputMaybe<SequenceDashboardViewCreateWithoutUserInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<SequenceDashboardViewUpdateWithoutUserInput>;
  upsert?: InputMaybe<SequenceDashboardViewUpsertWithoutUserInput>;
};

export type SequenceDashboardViewUpdateWithoutOrganizationInput = {
  deleted_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  owned_by?: InputMaybe<UserUpdateOneRequiredWithoutSequenceDashboardViewInput>;
  sequence_reporting_filters?: InputMaybe<Scalars['Json']['input']>;
  sequence_reporting_segments?: InputMaybe<Scalars['Json']['input']>;
  user?: InputMaybe<UserUpdateManyWithoutCurrent_Sequence_ViewInput>;
  view_name?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type SequenceDashboardViewUpdateWithoutOwned_ByInput = {
  deleted_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  Organization?: InputMaybe<OrganizationUpdateOneWithoutSequenceDashboardViewsInput>;
  sequence_reporting_filters?: InputMaybe<Scalars['Json']['input']>;
  sequence_reporting_segments?: InputMaybe<Scalars['Json']['input']>;
  user?: InputMaybe<UserUpdateManyWithoutCurrent_Sequence_ViewInput>;
  view_name?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type SequenceDashboardViewUpdateWithoutUserInput = {
  deleted_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  Organization?: InputMaybe<OrganizationUpdateOneWithoutSequenceDashboardViewsInput>;
  owned_by?: InputMaybe<UserUpdateOneRequiredWithoutSequenceDashboardViewInput>;
  sequence_reporting_filters?: InputMaybe<Scalars['Json']['input']>;
  sequence_reporting_segments?: InputMaybe<Scalars['Json']['input']>;
  view_name?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type SequenceDashboardViewUpdateWithWhereUniqueWithoutOrganizationInput = {
  data: SequenceDashboardViewUpdateWithoutOrganizationInput;
  where: SequenceDashboardViewWhereUniqueInput;
};

export type SequenceDashboardViewUpdateWithWhereUniqueWithoutOwned_ByInput = {
  data: SequenceDashboardViewUpdateWithoutOwned_ByInput;
  where: SequenceDashboardViewWhereUniqueInput;
};

export type SequenceDashboardViewUpsertWithoutUserInput = {
  create: SequenceDashboardViewCreateWithoutUserInput;
  update: SequenceDashboardViewUpdateWithoutUserInput;
};

export type SequenceDashboardViewUpsertWithWhereUniqueWithoutOrganizationInput = {
  create: SequenceDashboardViewCreateWithoutOrganizationInput;
  update: SequenceDashboardViewUpdateWithoutOrganizationInput;
  where: SequenceDashboardViewWhereUniqueInput;
};

export type SequenceDashboardViewUpsertWithWhereUniqueWithoutOwned_ByInput = {
  create: SequenceDashboardViewCreateWithoutOwned_ByInput;
  update: SequenceDashboardViewUpdateWithoutOwned_ByInput;
  where: SequenceDashboardViewWhereUniqueInput;
};

export type SequenceDashboardViewWhereInput = {
  AND?: InputMaybe<Array<SequenceDashboardViewWhereInput>>;
  created_user_id?: InputMaybe<StringFilter>;
  deleted_at?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<StringFilter>;
  NOT?: InputMaybe<Array<SequenceDashboardViewWhereInput>>;
  OR?: InputMaybe<Array<SequenceDashboardViewWhereInput>>;
  Organization?: InputMaybe<OrganizationWhereInput>;
  organization_id?: InputMaybe<StringNullableFilter>;
  owned_by?: InputMaybe<UserWhereInput>;
  sequence_reporting_filters?: InputMaybe<JsonNullableFilter>;
  sequence_reporting_segments?: InputMaybe<JsonNullableFilter>;
  user?: InputMaybe<UserListRelationFilter>;
  view_name?: InputMaybe<StringFilter>;
};

export type SequenceDashboardViewWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']['input']>;
};

export type SequenceEntryCriteria = {
  __typename?: 'SequenceEntryCriteria';
  channels: Array<Channel>;
  created_at: Scalars['DateTime']['output'];
  current_phase: Array<Phase>;
  id: Scalars['String']['output'];
  person_spoke_to: Array<Callresult>;
  sequence?: Maybe<Sequence>;
  sequence_criteria_condition: Array<SequenceCriteriaCondition>;
  types: Array<Dispositiontype>;
  updated_at: Scalars['DateTime']['output'];
};


export type SequenceEntryCriteriaSequence_Criteria_ConditionArgs = {
  after?: InputMaybe<SequenceCriteriaConditionWhereUniqueInput>;
  before?: InputMaybe<SequenceCriteriaConditionWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type SequenceEntryCriteriaCreatechannelsInput = {
  set?: InputMaybe<Array<Channel>>;
};

export type SequenceEntryCriteriaCreatecurrent_PhaseInput = {
  set?: InputMaybe<Array<Phase>>;
};

export type SequenceEntryCriteriaCreateManychannelsInput = {
  set?: InputMaybe<Array<Channel>>;
};

export type SequenceEntryCriteriaCreateManycurrent_PhaseInput = {
  set?: InputMaybe<Array<Phase>>;
};

export type SequenceEntryCriteriaCreateManyOrganizationInput = {
  channels?: InputMaybe<SequenceEntryCriteriaCreateManychannelsInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  current_phase?: InputMaybe<SequenceEntryCriteriaCreateManycurrent_PhaseInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  person_spoke_to?: InputMaybe<SequenceEntryCriteriaCreateManyperson_Spoke_ToInput>;
  types?: InputMaybe<SequenceEntryCriteriaCreateManytypesInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type SequenceEntryCriteriaCreateManyOrganizationInputEnvelope = {
  data?: InputMaybe<Array<SequenceEntryCriteriaCreateManyOrganizationInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SequenceEntryCriteriaCreateManyperson_Spoke_ToInput = {
  set?: InputMaybe<Array<Callresult>>;
};

export type SequenceEntryCriteriaCreateManytypesInput = {
  set?: InputMaybe<Array<Dispositiontype>>;
};

export type SequenceEntryCriteriaCreateNestedManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<SequenceEntryCriteriaWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SequenceEntryCriteriaCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<SequenceEntryCriteriaCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<SequenceEntryCriteriaCreateManyOrganizationInputEnvelope>;
};

export type SequenceEntryCriteriaCreateNestedOneWithoutSequence_Criteria_ConditionInput = {
  connect?: InputMaybe<SequenceEntryCriteriaWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SequenceEntryCriteriaCreateOrConnectWithoutSequence_Criteria_ConditionInput>;
  create?: InputMaybe<SequenceEntryCriteriaCreateWithoutSequence_Criteria_ConditionInput>;
};

export type SequenceEntryCriteriaCreateNestedOneWithoutSequenceInput = {
  connect?: InputMaybe<SequenceEntryCriteriaWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SequenceEntryCriteriaCreateOrConnectWithoutSequenceInput>;
  create?: InputMaybe<SequenceEntryCriteriaCreateWithoutSequenceInput>;
};

export type SequenceEntryCriteriaCreateOrConnectWithoutOrganizationInput = {
  create: SequenceEntryCriteriaCreateWithoutOrganizationInput;
  where: SequenceEntryCriteriaWhereUniqueInput;
};

export type SequenceEntryCriteriaCreateOrConnectWithoutSequence_Criteria_ConditionInput = {
  create: SequenceEntryCriteriaCreateWithoutSequence_Criteria_ConditionInput;
  where: SequenceEntryCriteriaWhereUniqueInput;
};

export type SequenceEntryCriteriaCreateOrConnectWithoutSequenceInput = {
  create: SequenceEntryCriteriaCreateWithoutSequenceInput;
  where: SequenceEntryCriteriaWhereUniqueInput;
};

export type SequenceEntryCriteriaCreateperson_Spoke_ToInput = {
  set?: InputMaybe<Array<Callresult>>;
};

export type SequenceEntryCriteriaCreatetypesInput = {
  set?: InputMaybe<Array<Dispositiontype>>;
};

export type SequenceEntryCriteriaCreateWithoutOrganizationInput = {
  channels?: InputMaybe<SequenceEntryCriteriaCreatechannelsInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  current_phase?: InputMaybe<SequenceEntryCriteriaCreatecurrent_PhaseInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  person_spoke_to?: InputMaybe<SequenceEntryCriteriaCreateperson_Spoke_ToInput>;
  sequence?: InputMaybe<SequenceCreateNestedOneWithoutSequence_Entry_CriteriaInput>;
  sequence_criteria_condition?: InputMaybe<SequenceCriteriaConditionCreateNestedManyWithoutSequence_CriteriaInput>;
  types?: InputMaybe<SequenceEntryCriteriaCreatetypesInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type SequenceEntryCriteriaCreateWithoutSequence_Criteria_ConditionInput = {
  channels?: InputMaybe<SequenceEntryCriteriaCreatechannelsInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  current_phase?: InputMaybe<SequenceEntryCriteriaCreatecurrent_PhaseInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  Organization?: InputMaybe<OrganizationCreateNestedOneWithoutSequenceEntryCriteriasInput>;
  person_spoke_to?: InputMaybe<SequenceEntryCriteriaCreateperson_Spoke_ToInput>;
  sequence?: InputMaybe<SequenceCreateNestedOneWithoutSequence_Entry_CriteriaInput>;
  types?: InputMaybe<SequenceEntryCriteriaCreatetypesInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type SequenceEntryCriteriaCreateWithoutSequenceInput = {
  channels?: InputMaybe<SequenceEntryCriteriaCreatechannelsInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  current_phase?: InputMaybe<SequenceEntryCriteriaCreatecurrent_PhaseInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  Organization?: InputMaybe<OrganizationCreateNestedOneWithoutSequenceEntryCriteriasInput>;
  person_spoke_to?: InputMaybe<SequenceEntryCriteriaCreateperson_Spoke_ToInput>;
  sequence_criteria_condition?: InputMaybe<SequenceCriteriaConditionCreateNestedManyWithoutSequence_CriteriaInput>;
  types?: InputMaybe<SequenceEntryCriteriaCreatetypesInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type SequenceEntryCriteriaListRelationFilter = {
  every?: InputMaybe<SequenceEntryCriteriaWhereInput>;
  none?: InputMaybe<SequenceEntryCriteriaWhereInput>;
  some?: InputMaybe<SequenceEntryCriteriaWhereInput>;
};

export type SequenceEntryCriteriaOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
  count?: InputMaybe<SortOrder>;
};

export type SequenceEntryCriteriaOrderByWithRelationInput = {
  channels?: InputMaybe<SortOrder>;
  created_at?: InputMaybe<SortOrder>;
  current_phase?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  Organization?: InputMaybe<OrganizationOrderByWithRelationInput>;
  organization_id?: InputMaybe<SortOrder>;
  person_spoke_to?: InputMaybe<SortOrder>;
  sequence?: InputMaybe<SequenceOrderByWithRelationInput>;
  sequence_criteria_condition?: InputMaybe<SequenceCriteriaConditionOrderByRelationAggregateInput>;
  types?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
};

export type SequenceEntryCriteriaScalarWhereInput = {
  AND?: InputMaybe<Array<SequenceEntryCriteriaScalarWhereInput>>;
  channels?: InputMaybe<EnumChannelNullableListFilter>;
  created_at?: InputMaybe<DateTimeFilter>;
  current_phase?: InputMaybe<EnumPhaseNullableListFilter>;
  id?: InputMaybe<StringFilter>;
  NOT?: InputMaybe<Array<SequenceEntryCriteriaScalarWhereInput>>;
  OR?: InputMaybe<Array<SequenceEntryCriteriaScalarWhereInput>>;
  organization_id?: InputMaybe<StringNullableFilter>;
  person_spoke_to?: InputMaybe<EnumCallresultNullableListFilter>;
  types?: InputMaybe<EnumDispositiontypeNullableListFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
};

export type SequenceEntryCriteriaUpdatechannelsInput = {
  push?: InputMaybe<Channel>;
  set?: InputMaybe<Array<Channel>>;
};

export type SequenceEntryCriteriaUpdatecurrent_PhaseInput = {
  push?: InputMaybe<Phase>;
  set?: InputMaybe<Array<Phase>>;
};

export type SequenceEntryCriteriaUpdateManyMutationInput = {
  channels?: InputMaybe<SequenceEntryCriteriaUpdatechannelsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  current_phase?: InputMaybe<SequenceEntryCriteriaUpdatecurrent_PhaseInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  person_spoke_to?: InputMaybe<SequenceEntryCriteriaUpdateperson_Spoke_ToInput>;
  types?: InputMaybe<SequenceEntryCriteriaUpdatetypesInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type SequenceEntryCriteriaUpdateManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<SequenceEntryCriteriaWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SequenceEntryCriteriaCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<SequenceEntryCriteriaCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<SequenceEntryCriteriaCreateManyOrganizationInputEnvelope>;
  delete?: InputMaybe<Array<SequenceEntryCriteriaWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<SequenceEntryCriteriaScalarWhereInput>>;
  disconnect?: InputMaybe<Array<SequenceEntryCriteriaWhereUniqueInput>>;
  set?: InputMaybe<Array<SequenceEntryCriteriaWhereUniqueInput>>;
  update?: InputMaybe<Array<SequenceEntryCriteriaUpdateWithWhereUniqueWithoutOrganizationInput>>;
  updateMany?: InputMaybe<Array<SequenceEntryCriteriaUpdateManyWithWhereWithoutOrganizationInput>>;
  upsert?: InputMaybe<Array<SequenceEntryCriteriaUpsertWithWhereUniqueWithoutOrganizationInput>>;
};

export type SequenceEntryCriteriaUpdateManyWithWhereWithoutOrganizationInput = {
  data: SequenceEntryCriteriaUpdateManyMutationInput;
  where: SequenceEntryCriteriaScalarWhereInput;
};

export type SequenceEntryCriteriaUpdateOneRequiredWithoutSequence_Criteria_ConditionInput = {
  connect?: InputMaybe<SequenceEntryCriteriaWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SequenceEntryCriteriaCreateOrConnectWithoutSequence_Criteria_ConditionInput>;
  create?: InputMaybe<SequenceEntryCriteriaCreateWithoutSequence_Criteria_ConditionInput>;
  update?: InputMaybe<SequenceEntryCriteriaUpdateWithoutSequence_Criteria_ConditionInput>;
  upsert?: InputMaybe<SequenceEntryCriteriaUpsertWithoutSequence_Criteria_ConditionInput>;
};

export type SequenceEntryCriteriaUpdateOneWithoutSequenceInput = {
  connect?: InputMaybe<SequenceEntryCriteriaWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SequenceEntryCriteriaCreateOrConnectWithoutSequenceInput>;
  create?: InputMaybe<SequenceEntryCriteriaCreateWithoutSequenceInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<SequenceEntryCriteriaUpdateWithoutSequenceInput>;
  upsert?: InputMaybe<SequenceEntryCriteriaUpsertWithoutSequenceInput>;
};

export type SequenceEntryCriteriaUpdateperson_Spoke_ToInput = {
  push?: InputMaybe<Callresult>;
  set?: InputMaybe<Array<Callresult>>;
};

export type SequenceEntryCriteriaUpdatetypesInput = {
  push?: InputMaybe<Dispositiontype>;
  set?: InputMaybe<Array<Dispositiontype>>;
};

export type SequenceEntryCriteriaUpdateWithoutOrganizationInput = {
  channels?: InputMaybe<SequenceEntryCriteriaUpdatechannelsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  current_phase?: InputMaybe<SequenceEntryCriteriaUpdatecurrent_PhaseInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  person_spoke_to?: InputMaybe<SequenceEntryCriteriaUpdateperson_Spoke_ToInput>;
  sequence?: InputMaybe<SequenceUpdateOneWithoutSequence_Entry_CriteriaInput>;
  sequence_criteria_condition?: InputMaybe<SequenceCriteriaConditionUpdateManyWithoutSequence_CriteriaInput>;
  types?: InputMaybe<SequenceEntryCriteriaUpdatetypesInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type SequenceEntryCriteriaUpdateWithoutSequence_Criteria_ConditionInput = {
  channels?: InputMaybe<SequenceEntryCriteriaUpdatechannelsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  current_phase?: InputMaybe<SequenceEntryCriteriaUpdatecurrent_PhaseInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  Organization?: InputMaybe<OrganizationUpdateOneWithoutSequenceEntryCriteriasInput>;
  person_spoke_to?: InputMaybe<SequenceEntryCriteriaUpdateperson_Spoke_ToInput>;
  sequence?: InputMaybe<SequenceUpdateOneWithoutSequence_Entry_CriteriaInput>;
  types?: InputMaybe<SequenceEntryCriteriaUpdatetypesInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type SequenceEntryCriteriaUpdateWithoutSequenceInput = {
  channels?: InputMaybe<SequenceEntryCriteriaUpdatechannelsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  current_phase?: InputMaybe<SequenceEntryCriteriaUpdatecurrent_PhaseInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  Organization?: InputMaybe<OrganizationUpdateOneWithoutSequenceEntryCriteriasInput>;
  person_spoke_to?: InputMaybe<SequenceEntryCriteriaUpdateperson_Spoke_ToInput>;
  sequence_criteria_condition?: InputMaybe<SequenceCriteriaConditionUpdateManyWithoutSequence_CriteriaInput>;
  types?: InputMaybe<SequenceEntryCriteriaUpdatetypesInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type SequenceEntryCriteriaUpdateWithWhereUniqueWithoutOrganizationInput = {
  data: SequenceEntryCriteriaUpdateWithoutOrganizationInput;
  where: SequenceEntryCriteriaWhereUniqueInput;
};

export type SequenceEntryCriteriaUpsertWithoutSequence_Criteria_ConditionInput = {
  create: SequenceEntryCriteriaCreateWithoutSequence_Criteria_ConditionInput;
  update: SequenceEntryCriteriaUpdateWithoutSequence_Criteria_ConditionInput;
};

export type SequenceEntryCriteriaUpsertWithoutSequenceInput = {
  create: SequenceEntryCriteriaCreateWithoutSequenceInput;
  update: SequenceEntryCriteriaUpdateWithoutSequenceInput;
};

export type SequenceEntryCriteriaUpsertWithWhereUniqueWithoutOrganizationInput = {
  create: SequenceEntryCriteriaCreateWithoutOrganizationInput;
  update: SequenceEntryCriteriaUpdateWithoutOrganizationInput;
  where: SequenceEntryCriteriaWhereUniqueInput;
};

export type SequenceEntryCriteriaWhereInput = {
  AND?: InputMaybe<Array<SequenceEntryCriteriaWhereInput>>;
  channels?: InputMaybe<EnumChannelNullableListFilter>;
  created_at?: InputMaybe<DateTimeFilter>;
  current_phase?: InputMaybe<EnumPhaseNullableListFilter>;
  id?: InputMaybe<StringFilter>;
  NOT?: InputMaybe<Array<SequenceEntryCriteriaWhereInput>>;
  OR?: InputMaybe<Array<SequenceEntryCriteriaWhereInput>>;
  Organization?: InputMaybe<OrganizationWhereInput>;
  organization_id?: InputMaybe<StringNullableFilter>;
  person_spoke_to?: InputMaybe<EnumCallresultNullableListFilter>;
  sequence?: InputMaybe<SequenceWhereInput>;
  sequence_criteria_condition?: InputMaybe<SequenceCriteriaConditionListRelationFilter>;
  types?: InputMaybe<EnumDispositiontypeNullableListFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
};

export type SequenceEntryCriteriaWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']['input']>;
};

export type SequenceEntryExitLeadCreateManyLead_ActivityInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  exit_type?: InputMaybe<SequenceExitType>;
  id?: InputMaybe<Scalars['String']['input']>;
  lead_id: Scalars['String']['input'];
  null_lead_activity_reason?: InputMaybe<Scalars['String']['input']>;
  organization_id: Scalars['String']['input'];
  sequence_id: Scalars['String']['input'];
  type: SequenceEntryExitType;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
};

export type SequenceEntryExitLeadCreateManyLead_ActivityInputEnvelope = {
  data?: InputMaybe<Array<SequenceEntryExitLeadCreateManyLead_ActivityInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SequenceEntryExitLeadCreateManyLeadInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  exit_type?: InputMaybe<SequenceExitType>;
  id?: InputMaybe<Scalars['String']['input']>;
  lead_activity_id?: InputMaybe<Scalars['String']['input']>;
  null_lead_activity_reason?: InputMaybe<Scalars['String']['input']>;
  organization_id: Scalars['String']['input'];
  sequence_id: Scalars['String']['input'];
  type: SequenceEntryExitType;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
};

export type SequenceEntryExitLeadCreateManyLeadInputEnvelope = {
  data?: InputMaybe<Array<SequenceEntryExitLeadCreateManyLeadInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SequenceEntryExitLeadCreateManyOrganizationInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  exit_type?: InputMaybe<SequenceExitType>;
  id?: InputMaybe<Scalars['String']['input']>;
  lead_activity_id?: InputMaybe<Scalars['String']['input']>;
  lead_id: Scalars['String']['input'];
  null_lead_activity_reason?: InputMaybe<Scalars['String']['input']>;
  sequence_id: Scalars['String']['input'];
  type: SequenceEntryExitType;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
};

export type SequenceEntryExitLeadCreateManyOrganizationInputEnvelope = {
  data?: InputMaybe<Array<SequenceEntryExitLeadCreateManyOrganizationInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SequenceEntryExitLeadCreateManySequenceInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  exit_type?: InputMaybe<SequenceExitType>;
  id?: InputMaybe<Scalars['String']['input']>;
  lead_activity_id?: InputMaybe<Scalars['String']['input']>;
  lead_id: Scalars['String']['input'];
  null_lead_activity_reason?: InputMaybe<Scalars['String']['input']>;
  organization_id: Scalars['String']['input'];
  type: SequenceEntryExitType;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
};

export type SequenceEntryExitLeadCreateManySequenceInputEnvelope = {
  data?: InputMaybe<Array<SequenceEntryExitLeadCreateManySequenceInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SequenceEntryExitLeadCreateManyUserInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  exit_type?: InputMaybe<SequenceExitType>;
  id?: InputMaybe<Scalars['String']['input']>;
  lead_activity_id?: InputMaybe<Scalars['String']['input']>;
  lead_id: Scalars['String']['input'];
  null_lead_activity_reason?: InputMaybe<Scalars['String']['input']>;
  organization_id: Scalars['String']['input'];
  sequence_id: Scalars['String']['input'];
  type: SequenceEntryExitType;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type SequenceEntryExitLeadCreateManyUserInputEnvelope = {
  data?: InputMaybe<Array<SequenceEntryExitLeadCreateManyUserInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SequenceEntryExitLeadCreateNestedManyWithoutLead_ActivityInput = {
  connect?: InputMaybe<Array<SequenceEntryExitLeadWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SequenceEntryExitLeadCreateOrConnectWithoutLead_ActivityInput>>;
  create?: InputMaybe<Array<SequenceEntryExitLeadCreateWithoutLead_ActivityInput>>;
  createMany?: InputMaybe<SequenceEntryExitLeadCreateManyLead_ActivityInputEnvelope>;
};

export type SequenceEntryExitLeadCreateNestedManyWithoutLeadInput = {
  connect?: InputMaybe<Array<SequenceEntryExitLeadWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SequenceEntryExitLeadCreateOrConnectWithoutLeadInput>>;
  create?: InputMaybe<Array<SequenceEntryExitLeadCreateWithoutLeadInput>>;
  createMany?: InputMaybe<SequenceEntryExitLeadCreateManyLeadInputEnvelope>;
};

export type SequenceEntryExitLeadCreateNestedManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<SequenceEntryExitLeadWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SequenceEntryExitLeadCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<SequenceEntryExitLeadCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<SequenceEntryExitLeadCreateManyOrganizationInputEnvelope>;
};

export type SequenceEntryExitLeadCreateNestedManyWithoutSequenceInput = {
  connect?: InputMaybe<Array<SequenceEntryExitLeadWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SequenceEntryExitLeadCreateOrConnectWithoutSequenceInput>>;
  create?: InputMaybe<Array<SequenceEntryExitLeadCreateWithoutSequenceInput>>;
  createMany?: InputMaybe<SequenceEntryExitLeadCreateManySequenceInputEnvelope>;
};

export type SequenceEntryExitLeadCreateNestedManyWithoutUserInput = {
  connect?: InputMaybe<Array<SequenceEntryExitLeadWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SequenceEntryExitLeadCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<SequenceEntryExitLeadCreateWithoutUserInput>>;
  createMany?: InputMaybe<SequenceEntryExitLeadCreateManyUserInputEnvelope>;
};

export type SequenceEntryExitLeadCreateOrConnectWithoutLead_ActivityInput = {
  create: SequenceEntryExitLeadCreateWithoutLead_ActivityInput;
  where: SequenceEntryExitLeadWhereUniqueInput;
};

export type SequenceEntryExitLeadCreateOrConnectWithoutLeadInput = {
  create: SequenceEntryExitLeadCreateWithoutLeadInput;
  where: SequenceEntryExitLeadWhereUniqueInput;
};

export type SequenceEntryExitLeadCreateOrConnectWithoutOrganizationInput = {
  create: SequenceEntryExitLeadCreateWithoutOrganizationInput;
  where: SequenceEntryExitLeadWhereUniqueInput;
};

export type SequenceEntryExitLeadCreateOrConnectWithoutSequenceInput = {
  create: SequenceEntryExitLeadCreateWithoutSequenceInput;
  where: SequenceEntryExitLeadWhereUniqueInput;
};

export type SequenceEntryExitLeadCreateOrConnectWithoutUserInput = {
  create: SequenceEntryExitLeadCreateWithoutUserInput;
  where: SequenceEntryExitLeadWhereUniqueInput;
};

export type SequenceEntryExitLeadCreateWithoutLead_ActivityInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  exit_type?: InputMaybe<SequenceExitType>;
  id?: InputMaybe<Scalars['String']['input']>;
  lead: LeadCreateNestedOneWithoutSequenceEntryExitLeadInput;
  null_lead_activity_reason?: InputMaybe<Scalars['String']['input']>;
  organization: OrganizationCreateNestedOneWithoutSequenceEntryExitLeadInput;
  sequence: SequenceCreateNestedOneWithoutSequenceEntryExitLeadInput;
  type: SequenceEntryExitType;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user?: InputMaybe<UserCreateNestedOneWithoutSequenceEntryExitLeadInput>;
};

export type SequenceEntryExitLeadCreateWithoutLeadInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  exit_type?: InputMaybe<SequenceExitType>;
  id?: InputMaybe<Scalars['String']['input']>;
  lead_activity?: InputMaybe<LeadActivityCreateNestedOneWithoutSequenceEntryExitLeadInput>;
  null_lead_activity_reason?: InputMaybe<Scalars['String']['input']>;
  organization: OrganizationCreateNestedOneWithoutSequenceEntryExitLeadInput;
  sequence: SequenceCreateNestedOneWithoutSequenceEntryExitLeadInput;
  type: SequenceEntryExitType;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user?: InputMaybe<UserCreateNestedOneWithoutSequenceEntryExitLeadInput>;
};

export type SequenceEntryExitLeadCreateWithoutOrganizationInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  exit_type?: InputMaybe<SequenceExitType>;
  id?: InputMaybe<Scalars['String']['input']>;
  lead: LeadCreateNestedOneWithoutSequenceEntryExitLeadInput;
  lead_activity?: InputMaybe<LeadActivityCreateNestedOneWithoutSequenceEntryExitLeadInput>;
  null_lead_activity_reason?: InputMaybe<Scalars['String']['input']>;
  sequence: SequenceCreateNestedOneWithoutSequenceEntryExitLeadInput;
  type: SequenceEntryExitType;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user?: InputMaybe<UserCreateNestedOneWithoutSequenceEntryExitLeadInput>;
};

export type SequenceEntryExitLeadCreateWithoutSequenceInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  exit_type?: InputMaybe<SequenceExitType>;
  id?: InputMaybe<Scalars['String']['input']>;
  lead: LeadCreateNestedOneWithoutSequenceEntryExitLeadInput;
  lead_activity?: InputMaybe<LeadActivityCreateNestedOneWithoutSequenceEntryExitLeadInput>;
  null_lead_activity_reason?: InputMaybe<Scalars['String']['input']>;
  organization: OrganizationCreateNestedOneWithoutSequenceEntryExitLeadInput;
  type: SequenceEntryExitType;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user?: InputMaybe<UserCreateNestedOneWithoutSequenceEntryExitLeadInput>;
};

export type SequenceEntryExitLeadCreateWithoutUserInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  exit_type?: InputMaybe<SequenceExitType>;
  id?: InputMaybe<Scalars['String']['input']>;
  lead: LeadCreateNestedOneWithoutSequenceEntryExitLeadInput;
  lead_activity?: InputMaybe<LeadActivityCreateNestedOneWithoutSequenceEntryExitLeadInput>;
  null_lead_activity_reason?: InputMaybe<Scalars['String']['input']>;
  organization: OrganizationCreateNestedOneWithoutSequenceEntryExitLeadInput;
  sequence: SequenceCreateNestedOneWithoutSequenceEntryExitLeadInput;
  type: SequenceEntryExitType;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type SequenceEntryExitLeadListRelationFilter = {
  every?: InputMaybe<SequenceEntryExitLeadWhereInput>;
  none?: InputMaybe<SequenceEntryExitLeadWhereInput>;
  some?: InputMaybe<SequenceEntryExitLeadWhereInput>;
};

export type SequenceEntryExitLeadOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
  count?: InputMaybe<SortOrder>;
};

export type SequenceEntryExitLeadScalarWhereInput = {
  AND?: InputMaybe<Array<SequenceEntryExitLeadScalarWhereInput>>;
  created_at?: InputMaybe<DateTimeFilter>;
  exit_type?: InputMaybe<EnumSequenceExitTypeNullableFilter>;
  id?: InputMaybe<StringFilter>;
  lead_activity_id?: InputMaybe<StringNullableFilter>;
  lead_id?: InputMaybe<StringFilter>;
  NOT?: InputMaybe<Array<SequenceEntryExitLeadScalarWhereInput>>;
  null_lead_activity_reason?: InputMaybe<StringNullableFilter>;
  OR?: InputMaybe<Array<SequenceEntryExitLeadScalarWhereInput>>;
  organization_id?: InputMaybe<StringFilter>;
  sequence_id?: InputMaybe<StringFilter>;
  type?: InputMaybe<EnumSequenceEntryExitTypeFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
  user_id?: InputMaybe<StringNullableFilter>;
};

export type SequenceEntryExitLeadUpdateManyMutationInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  exit_type?: InputMaybe<NullableEnumSequenceExitTypeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  null_lead_activity_reason?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumSequenceEntryExitTypeFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type SequenceEntryExitLeadUpdateManyWithoutLead_ActivityInput = {
  connect?: InputMaybe<Array<SequenceEntryExitLeadWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SequenceEntryExitLeadCreateOrConnectWithoutLead_ActivityInput>>;
  create?: InputMaybe<Array<SequenceEntryExitLeadCreateWithoutLead_ActivityInput>>;
  createMany?: InputMaybe<SequenceEntryExitLeadCreateManyLead_ActivityInputEnvelope>;
  delete?: InputMaybe<Array<SequenceEntryExitLeadWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<SequenceEntryExitLeadScalarWhereInput>>;
  disconnect?: InputMaybe<Array<SequenceEntryExitLeadWhereUniqueInput>>;
  set?: InputMaybe<Array<SequenceEntryExitLeadWhereUniqueInput>>;
  update?: InputMaybe<Array<SequenceEntryExitLeadUpdateWithWhereUniqueWithoutLead_ActivityInput>>;
  updateMany?: InputMaybe<Array<SequenceEntryExitLeadUpdateManyWithWhereWithoutLead_ActivityInput>>;
  upsert?: InputMaybe<Array<SequenceEntryExitLeadUpsertWithWhereUniqueWithoutLead_ActivityInput>>;
};

export type SequenceEntryExitLeadUpdateManyWithoutLeadInput = {
  connect?: InputMaybe<Array<SequenceEntryExitLeadWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SequenceEntryExitLeadCreateOrConnectWithoutLeadInput>>;
  create?: InputMaybe<Array<SequenceEntryExitLeadCreateWithoutLeadInput>>;
  createMany?: InputMaybe<SequenceEntryExitLeadCreateManyLeadInputEnvelope>;
  delete?: InputMaybe<Array<SequenceEntryExitLeadWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<SequenceEntryExitLeadScalarWhereInput>>;
  disconnect?: InputMaybe<Array<SequenceEntryExitLeadWhereUniqueInput>>;
  set?: InputMaybe<Array<SequenceEntryExitLeadWhereUniqueInput>>;
  update?: InputMaybe<Array<SequenceEntryExitLeadUpdateWithWhereUniqueWithoutLeadInput>>;
  updateMany?: InputMaybe<Array<SequenceEntryExitLeadUpdateManyWithWhereWithoutLeadInput>>;
  upsert?: InputMaybe<Array<SequenceEntryExitLeadUpsertWithWhereUniqueWithoutLeadInput>>;
};

export type SequenceEntryExitLeadUpdateManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<SequenceEntryExitLeadWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SequenceEntryExitLeadCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<SequenceEntryExitLeadCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<SequenceEntryExitLeadCreateManyOrganizationInputEnvelope>;
  delete?: InputMaybe<Array<SequenceEntryExitLeadWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<SequenceEntryExitLeadScalarWhereInput>>;
  disconnect?: InputMaybe<Array<SequenceEntryExitLeadWhereUniqueInput>>;
  set?: InputMaybe<Array<SequenceEntryExitLeadWhereUniqueInput>>;
  update?: InputMaybe<Array<SequenceEntryExitLeadUpdateWithWhereUniqueWithoutOrganizationInput>>;
  updateMany?: InputMaybe<Array<SequenceEntryExitLeadUpdateManyWithWhereWithoutOrganizationInput>>;
  upsert?: InputMaybe<Array<SequenceEntryExitLeadUpsertWithWhereUniqueWithoutOrganizationInput>>;
};

export type SequenceEntryExitLeadUpdateManyWithoutSequenceInput = {
  connect?: InputMaybe<Array<SequenceEntryExitLeadWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SequenceEntryExitLeadCreateOrConnectWithoutSequenceInput>>;
  create?: InputMaybe<Array<SequenceEntryExitLeadCreateWithoutSequenceInput>>;
  createMany?: InputMaybe<SequenceEntryExitLeadCreateManySequenceInputEnvelope>;
  delete?: InputMaybe<Array<SequenceEntryExitLeadWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<SequenceEntryExitLeadScalarWhereInput>>;
  disconnect?: InputMaybe<Array<SequenceEntryExitLeadWhereUniqueInput>>;
  set?: InputMaybe<Array<SequenceEntryExitLeadWhereUniqueInput>>;
  update?: InputMaybe<Array<SequenceEntryExitLeadUpdateWithWhereUniqueWithoutSequenceInput>>;
  updateMany?: InputMaybe<Array<SequenceEntryExitLeadUpdateManyWithWhereWithoutSequenceInput>>;
  upsert?: InputMaybe<Array<SequenceEntryExitLeadUpsertWithWhereUniqueWithoutSequenceInput>>;
};

export type SequenceEntryExitLeadUpdateManyWithoutUserInput = {
  connect?: InputMaybe<Array<SequenceEntryExitLeadWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SequenceEntryExitLeadCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<SequenceEntryExitLeadCreateWithoutUserInput>>;
  createMany?: InputMaybe<SequenceEntryExitLeadCreateManyUserInputEnvelope>;
  delete?: InputMaybe<Array<SequenceEntryExitLeadWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<SequenceEntryExitLeadScalarWhereInput>>;
  disconnect?: InputMaybe<Array<SequenceEntryExitLeadWhereUniqueInput>>;
  set?: InputMaybe<Array<SequenceEntryExitLeadWhereUniqueInput>>;
  update?: InputMaybe<Array<SequenceEntryExitLeadUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: InputMaybe<Array<SequenceEntryExitLeadUpdateManyWithWhereWithoutUserInput>>;
  upsert?: InputMaybe<Array<SequenceEntryExitLeadUpsertWithWhereUniqueWithoutUserInput>>;
};

export type SequenceEntryExitLeadUpdateManyWithWhereWithoutLead_ActivityInput = {
  data: SequenceEntryExitLeadUpdateManyMutationInput;
  where: SequenceEntryExitLeadScalarWhereInput;
};

export type SequenceEntryExitLeadUpdateManyWithWhereWithoutLeadInput = {
  data: SequenceEntryExitLeadUpdateManyMutationInput;
  where: SequenceEntryExitLeadScalarWhereInput;
};

export type SequenceEntryExitLeadUpdateManyWithWhereWithoutOrganizationInput = {
  data: SequenceEntryExitLeadUpdateManyMutationInput;
  where: SequenceEntryExitLeadScalarWhereInput;
};

export type SequenceEntryExitLeadUpdateManyWithWhereWithoutSequenceInput = {
  data: SequenceEntryExitLeadUpdateManyMutationInput;
  where: SequenceEntryExitLeadScalarWhereInput;
};

export type SequenceEntryExitLeadUpdateManyWithWhereWithoutUserInput = {
  data: SequenceEntryExitLeadUpdateManyMutationInput;
  where: SequenceEntryExitLeadScalarWhereInput;
};

export type SequenceEntryExitLeadUpdateWithoutLead_ActivityInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  exit_type?: InputMaybe<NullableEnumSequenceExitTypeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lead?: InputMaybe<LeadUpdateOneRequiredWithoutSequenceEntryExitLeadInput>;
  null_lead_activity_reason?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutSequenceEntryExitLeadInput>;
  sequence?: InputMaybe<SequenceUpdateOneRequiredWithoutSequenceEntryExitLeadInput>;
  type?: InputMaybe<EnumSequenceEntryExitTypeFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneWithoutSequenceEntryExitLeadInput>;
};

export type SequenceEntryExitLeadUpdateWithoutLeadInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  exit_type?: InputMaybe<NullableEnumSequenceExitTypeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lead_activity?: InputMaybe<LeadActivityUpdateOneWithoutSequenceEntryExitLeadInput>;
  null_lead_activity_reason?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutSequenceEntryExitLeadInput>;
  sequence?: InputMaybe<SequenceUpdateOneRequiredWithoutSequenceEntryExitLeadInput>;
  type?: InputMaybe<EnumSequenceEntryExitTypeFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneWithoutSequenceEntryExitLeadInput>;
};

export type SequenceEntryExitLeadUpdateWithoutOrganizationInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  exit_type?: InputMaybe<NullableEnumSequenceExitTypeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lead?: InputMaybe<LeadUpdateOneRequiredWithoutSequenceEntryExitLeadInput>;
  lead_activity?: InputMaybe<LeadActivityUpdateOneWithoutSequenceEntryExitLeadInput>;
  null_lead_activity_reason?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sequence?: InputMaybe<SequenceUpdateOneRequiredWithoutSequenceEntryExitLeadInput>;
  type?: InputMaybe<EnumSequenceEntryExitTypeFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneWithoutSequenceEntryExitLeadInput>;
};

export type SequenceEntryExitLeadUpdateWithoutSequenceInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  exit_type?: InputMaybe<NullableEnumSequenceExitTypeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lead?: InputMaybe<LeadUpdateOneRequiredWithoutSequenceEntryExitLeadInput>;
  lead_activity?: InputMaybe<LeadActivityUpdateOneWithoutSequenceEntryExitLeadInput>;
  null_lead_activity_reason?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutSequenceEntryExitLeadInput>;
  type?: InputMaybe<EnumSequenceEntryExitTypeFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneWithoutSequenceEntryExitLeadInput>;
};

export type SequenceEntryExitLeadUpdateWithoutUserInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  exit_type?: InputMaybe<NullableEnumSequenceExitTypeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lead?: InputMaybe<LeadUpdateOneRequiredWithoutSequenceEntryExitLeadInput>;
  lead_activity?: InputMaybe<LeadActivityUpdateOneWithoutSequenceEntryExitLeadInput>;
  null_lead_activity_reason?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutSequenceEntryExitLeadInput>;
  sequence?: InputMaybe<SequenceUpdateOneRequiredWithoutSequenceEntryExitLeadInput>;
  type?: InputMaybe<EnumSequenceEntryExitTypeFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type SequenceEntryExitLeadUpdateWithWhereUniqueWithoutLead_ActivityInput = {
  data: SequenceEntryExitLeadUpdateWithoutLead_ActivityInput;
  where: SequenceEntryExitLeadWhereUniqueInput;
};

export type SequenceEntryExitLeadUpdateWithWhereUniqueWithoutLeadInput = {
  data: SequenceEntryExitLeadUpdateWithoutLeadInput;
  where: SequenceEntryExitLeadWhereUniqueInput;
};

export type SequenceEntryExitLeadUpdateWithWhereUniqueWithoutOrganizationInput = {
  data: SequenceEntryExitLeadUpdateWithoutOrganizationInput;
  where: SequenceEntryExitLeadWhereUniqueInput;
};

export type SequenceEntryExitLeadUpdateWithWhereUniqueWithoutSequenceInput = {
  data: SequenceEntryExitLeadUpdateWithoutSequenceInput;
  where: SequenceEntryExitLeadWhereUniqueInput;
};

export type SequenceEntryExitLeadUpdateWithWhereUniqueWithoutUserInput = {
  data: SequenceEntryExitLeadUpdateWithoutUserInput;
  where: SequenceEntryExitLeadWhereUniqueInput;
};

export type SequenceEntryExitLeadUpsertWithWhereUniqueWithoutLead_ActivityInput = {
  create: SequenceEntryExitLeadCreateWithoutLead_ActivityInput;
  update: SequenceEntryExitLeadUpdateWithoutLead_ActivityInput;
  where: SequenceEntryExitLeadWhereUniqueInput;
};

export type SequenceEntryExitLeadUpsertWithWhereUniqueWithoutLeadInput = {
  create: SequenceEntryExitLeadCreateWithoutLeadInput;
  update: SequenceEntryExitLeadUpdateWithoutLeadInput;
  where: SequenceEntryExitLeadWhereUniqueInput;
};

export type SequenceEntryExitLeadUpsertWithWhereUniqueWithoutOrganizationInput = {
  create: SequenceEntryExitLeadCreateWithoutOrganizationInput;
  update: SequenceEntryExitLeadUpdateWithoutOrganizationInput;
  where: SequenceEntryExitLeadWhereUniqueInput;
};

export type SequenceEntryExitLeadUpsertWithWhereUniqueWithoutSequenceInput = {
  create: SequenceEntryExitLeadCreateWithoutSequenceInput;
  update: SequenceEntryExitLeadUpdateWithoutSequenceInput;
  where: SequenceEntryExitLeadWhereUniqueInput;
};

export type SequenceEntryExitLeadUpsertWithWhereUniqueWithoutUserInput = {
  create: SequenceEntryExitLeadCreateWithoutUserInput;
  update: SequenceEntryExitLeadUpdateWithoutUserInput;
  where: SequenceEntryExitLeadWhereUniqueInput;
};

export type SequenceEntryExitLeadWhereInput = {
  AND?: InputMaybe<Array<SequenceEntryExitLeadWhereInput>>;
  created_at?: InputMaybe<DateTimeFilter>;
  exit_type?: InputMaybe<EnumSequenceExitTypeNullableFilter>;
  id?: InputMaybe<StringFilter>;
  lead?: InputMaybe<LeadWhereInput>;
  lead_activity?: InputMaybe<LeadActivityWhereInput>;
  lead_activity_id?: InputMaybe<StringNullableFilter>;
  lead_id?: InputMaybe<StringFilter>;
  NOT?: InputMaybe<Array<SequenceEntryExitLeadWhereInput>>;
  null_lead_activity_reason?: InputMaybe<StringNullableFilter>;
  OR?: InputMaybe<Array<SequenceEntryExitLeadWhereInput>>;
  organization?: InputMaybe<OrganizationWhereInput>;
  organization_id?: InputMaybe<StringFilter>;
  sequence?: InputMaybe<SequenceWhereInput>;
  sequence_id?: InputMaybe<StringFilter>;
  type?: InputMaybe<EnumSequenceEntryExitTypeFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserWhereInput>;
  user_id?: InputMaybe<StringNullableFilter>;
};

export type SequenceEntryExitLeadWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']['input']>;
};

export enum SequenceEntryExitType {
  Entry = 'Entry',
  Exit = 'Exit'
}

export enum SequenceExitType {
  EndOfSequence = 'END_OF_SEQUENCE',
  NotInterested = 'NOT_INTERESTED',
  Sale = 'SALE',
  SystemSettingExit = 'SYSTEM_SETTING_EXIT'
}

/** SequenceInput for creation and update */
export type SequenceInput = {
  is_paused?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  priority: Scalars['Int']['input'];
  sequence_id?: InputMaybe<Scalars['String']['input']>;
  steps_count?: InputMaybe<Scalars['Int']['input']>;
};

export type SequenceListRelationFilter = {
  every?: InputMaybe<SequenceWhereInput>;
  none?: InputMaybe<SequenceWhereInput>;
  some?: InputMaybe<SequenceWhereInput>;
};

export type SequenceOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
  count?: InputMaybe<SortOrder>;
};

export type SequenceOrderByWithRelationInput = {
  active?: InputMaybe<SortOrder>;
  completed_count?: InputMaybe<SortOrder>;
  conversion_count?: InputMaybe<SortOrder>;
  created_at?: InputMaybe<SortOrder>;
  created_by?: InputMaybe<UserOrderByWithRelationInput>;
  created_by_user_id?: InputMaybe<SortOrder>;
  deleted_at?: InputMaybe<SortOrder>;
  email_bounced_perc?: InputMaybe<SortOrder>;
  email_clicked_perc?: InputMaybe<SortOrder>;
  email_delivered_perc?: InputMaybe<SortOrder>;
  email_opened_perc?: InputMaybe<SortOrder>;
  email_replied_perc?: InputMaybe<SortOrder>;
  email_unsubscribed_perc?: InputMaybe<SortOrder>;
  EmailItem?: InputMaybe<EmailItemOrderByRelationAggregateInput>;
  EndStepsTransfer?: InputMaybe<SequenceStepOrderByRelationAggregateInput>;
  exit_count?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  is_paused?: InputMaybe<SortOrder>;
  last_updated_by?: InputMaybe<UserOrderByWithRelationInput>;
  leadActivities?: InputMaybe<LeadActivityOrderByRelationAggregateInput>;
  LeadAssignmentHistory?: InputMaybe<LeadAssignmentHistoryOrderByRelationAggregateInput>;
  leads?: InputMaybe<LeadOrderByRelationAggregateInput>;
  manager_feedback_count?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  not_interested_count?: InputMaybe<SortOrder>;
  organization_id?: InputMaybe<SortOrder>;
  organzation?: InputMaybe<OrganizationOrderByWithRelationInput>;
  priority?: InputMaybe<SortOrder>;
  Sale?: InputMaybe<SaleOrderByRelationAggregateInput>;
  sequence_entry_criteria?: InputMaybe<SequenceEntryCriteriaOrderByWithRelationInput>;
  sequence_entry_criteria_id?: InputMaybe<SortOrder>;
  sequence_steps?: InputMaybe<SequenceStepOrderByRelationAggregateInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadOrderByRelationAggregateInput>;
  sms_bounced_perc?: InputMaybe<SortOrder>;
  sms_delivered_perc?: InputMaybe<SortOrder>;
  sms_unsubscribed_perc?: InputMaybe<SortOrder>;
  SMSItem?: InputMaybe<SmsItemOrderByRelationAggregateInput>;
  SnoozeTracking?: InputMaybe<SnoozeTrackingOrderByRelationAggregateInput>;
  StatItems?: InputMaybe<StatItemAggregationOrderByRelationAggregateInput>;
  StepActionLog?: InputMaybe<StepActionLogOrderByRelationAggregateInput>;
  steps_count?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
  updated_by_user_id?: InputMaybe<SortOrder>;
};

export type SequenceScalarWhereInput = {
  active?: InputMaybe<BoolFilter>;
  AND?: InputMaybe<Array<SequenceScalarWhereInput>>;
  completed_count?: InputMaybe<IntFilter>;
  conversion_count?: InputMaybe<IntFilter>;
  created_at?: InputMaybe<DateTimeFilter>;
  created_by_user_id?: InputMaybe<StringFilter>;
  deleted_at?: InputMaybe<DateTimeNullableFilter>;
  email_bounced_perc?: InputMaybe<FloatFilter>;
  email_clicked_perc?: InputMaybe<FloatFilter>;
  email_delivered_perc?: InputMaybe<FloatFilter>;
  email_opened_perc?: InputMaybe<FloatFilter>;
  email_replied_perc?: InputMaybe<FloatFilter>;
  email_unsubscribed_perc?: InputMaybe<FloatFilter>;
  exit_count?: InputMaybe<IntFilter>;
  id?: InputMaybe<StringFilter>;
  is_paused?: InputMaybe<BoolFilter>;
  manager_feedback_count?: InputMaybe<IntFilter>;
  name?: InputMaybe<StringFilter>;
  NOT?: InputMaybe<Array<SequenceScalarWhereInput>>;
  not_interested_count?: InputMaybe<IntFilter>;
  OR?: InputMaybe<Array<SequenceScalarWhereInput>>;
  organization_id?: InputMaybe<StringNullableFilter>;
  priority?: InputMaybe<IntFilter>;
  sequence_entry_criteria_id?: InputMaybe<StringNullableFilter>;
  sms_bounced_perc?: InputMaybe<FloatFilter>;
  sms_delivered_perc?: InputMaybe<FloatFilter>;
  sms_unsubscribed_perc?: InputMaybe<FloatFilter>;
  steps_count?: InputMaybe<IntFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
  updated_by_user_id?: InputMaybe<StringFilter>;
};

/** sequences filter */
export type SequencesDashFilter = {
  label: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

/** SequencesFilter is the type used to filter which sequences to fetch */
export type SequencesFilter = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  entry_phases?: InputMaybe<Array<InputMaybe<Phase>>>;
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  ready?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SequenceStep = {
  __typename?: 'SequenceStep';
  actions: Array<StepAction>;
  /** computed field that indicates if step is the last step before branches. */
  answer_rate?: Maybe<Scalars['Float']['output']>;
  /** computed field that indicates if step is the last step before branches. */
  average_call_duration?: Maybe<Scalars['Float']['output']>;
  /** computed field that indicates if step is the last step before branches. */
  average_time_to_complete?: Maybe<Scalars['Float']['output']>;
  /** computed field that indicates if step is the last step before branches. */
  bad_number_rate?: Maybe<Scalars['Float']['output']>;
  conditions: Array<StepCondition>;
  /** computed field that indicates if step is the last step before branches. */
  conversion_rate?: Maybe<Scalars['Float']['output']>;
  created_at: Scalars['DateTime']['output'];
  /** computed field that indicates if step is the last step before branches. */
  dm_connect_rate?: Maybe<Scalars['Float']['output']>;
  id: Scalars['String']['output'];
  /** computed field that indicates if step is the last step before branches. */
  is_host_step?: Maybe<Scalars['Boolean']['output']>;
  /** computed field that the sequence step is the last step */
  is_last_step?: Maybe<Scalars['Boolean']['output']>;
  is_no_branch: Scalars['Boolean']['output'];
  /** computed field that the sequence step is the last step */
  lead_count?: Maybe<Scalars['Int']['output']>;
  leads: Array<Lead>;
  /** computed field that indicates if step is the last step before branches. */
  number_of_dials?: Maybe<Scalars['Float']['output']>;
  origin?: Maybe<SequenceStep>;
  origin_id?: Maybe<Scalars['String']['output']>;
  post_step_action: PostAction;
  sequence: Sequence;
  sequence_id: Scalars['String']['output'];
  sequence_steps: Array<SequenceStep>;
  /** computed field that indicates if step is the last step before branches. */
  snoozed_or_skipped_count?: Maybe<Scalars['Float']['output']>;
  /** computed field that indicates if step is the last step before branches. */
  tasks_completion_rate?: Maybe<Scalars['Float']['output']>;
  transfer_to_sequence_id?: Maybe<Scalars['String']['output']>;
  updated_at: Scalars['DateTime']['output'];
  /** computed field that indicates if step is the last step before branches. */
  voicemail_or_no_contact_rate?: Maybe<Scalars['Float']['output']>;
};


export type SequenceStepActionsArgs = {
  after?: InputMaybe<StepActionWhereUniqueInput>;
  before?: InputMaybe<StepActionWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type SequenceStepConditionsArgs = {
  after?: InputMaybe<StepConditionWhereUniqueInput>;
  before?: InputMaybe<StepConditionWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type SequenceStepLeadsArgs = {
  after?: InputMaybe<LeadWhereUniqueInput>;
  before?: InputMaybe<LeadWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type SequenceStepSequence_StepsArgs = {
  after?: InputMaybe<SequenceStepWhereUniqueInput>;
  before?: InputMaybe<SequenceStepWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type SequenceStepCreateManyOrganizationInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  deleted_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  is_no_branch?: InputMaybe<Scalars['Boolean']['input']>;
  origin_id?: InputMaybe<Scalars['String']['input']>;
  post_step_action?: InputMaybe<PostAction>;
  sequence_id: Scalars['String']['input'];
  transfer_to_sequence_id?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type SequenceStepCreateManyOrganizationInputEnvelope = {
  data?: InputMaybe<Array<SequenceStepCreateManyOrganizationInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SequenceStepCreateManyOriginInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  deleted_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  is_no_branch?: InputMaybe<Scalars['Boolean']['input']>;
  organization_id?: InputMaybe<Scalars['String']['input']>;
  post_step_action?: InputMaybe<PostAction>;
  sequence_id: Scalars['String']['input'];
  transfer_to_sequence_id?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type SequenceStepCreateManyOriginInputEnvelope = {
  data?: InputMaybe<Array<SequenceStepCreateManyOriginInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SequenceStepCreateManySequenceInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  deleted_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  is_no_branch?: InputMaybe<Scalars['Boolean']['input']>;
  organization_id?: InputMaybe<Scalars['String']['input']>;
  origin_id?: InputMaybe<Scalars['String']['input']>;
  post_step_action?: InputMaybe<PostAction>;
  transfer_to_sequence_id?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type SequenceStepCreateManySequenceInputEnvelope = {
  data?: InputMaybe<Array<SequenceStepCreateManySequenceInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SequenceStepCreateManyTransferToSequenceInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  deleted_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  is_no_branch?: InputMaybe<Scalars['Boolean']['input']>;
  organization_id?: InputMaybe<Scalars['String']['input']>;
  origin_id?: InputMaybe<Scalars['String']['input']>;
  post_step_action?: InputMaybe<PostAction>;
  sequence_id: Scalars['String']['input'];
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type SequenceStepCreateManyTransferToSequenceInputEnvelope = {
  data?: InputMaybe<Array<SequenceStepCreateManyTransferToSequenceInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SequenceStepCreateNestedManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<SequenceStepWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SequenceStepCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<SequenceStepCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<SequenceStepCreateManyOrganizationInputEnvelope>;
};

export type SequenceStepCreateNestedManyWithoutOriginInput = {
  connect?: InputMaybe<Array<SequenceStepWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SequenceStepCreateOrConnectWithoutOriginInput>>;
  create?: InputMaybe<Array<SequenceStepCreateWithoutOriginInput>>;
  createMany?: InputMaybe<SequenceStepCreateManyOriginInputEnvelope>;
};

export type SequenceStepCreateNestedManyWithoutSequenceInput = {
  connect?: InputMaybe<Array<SequenceStepWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SequenceStepCreateOrConnectWithoutSequenceInput>>;
  create?: InputMaybe<Array<SequenceStepCreateWithoutSequenceInput>>;
  createMany?: InputMaybe<SequenceStepCreateManySequenceInputEnvelope>;
};

export type SequenceStepCreateNestedManyWithoutTransferToSequenceInput = {
  connect?: InputMaybe<Array<SequenceStepWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SequenceStepCreateOrConnectWithoutTransferToSequenceInput>>;
  create?: InputMaybe<Array<SequenceStepCreateWithoutTransferToSequenceInput>>;
  createMany?: InputMaybe<SequenceStepCreateManyTransferToSequenceInputEnvelope>;
};

export type SequenceStepCreateNestedOneWithoutActionsInput = {
  connect?: InputMaybe<SequenceStepWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SequenceStepCreateOrConnectWithoutActionsInput>;
  create?: InputMaybe<SequenceStepCreateWithoutActionsInput>;
};

export type SequenceStepCreateNestedOneWithoutConditionsInput = {
  connect?: InputMaybe<SequenceStepWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SequenceStepCreateOrConnectWithoutConditionsInput>;
  create?: InputMaybe<SequenceStepCreateWithoutConditionsInput>;
};

export type SequenceStepCreateNestedOneWithoutLeadActivityInput = {
  connect?: InputMaybe<SequenceStepWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SequenceStepCreateOrConnectWithoutLeadActivityInput>;
  create?: InputMaybe<SequenceStepCreateWithoutLeadActivityInput>;
};

export type SequenceStepCreateNestedOneWithoutLeadsInput = {
  connect?: InputMaybe<SequenceStepWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SequenceStepCreateOrConnectWithoutLeadsInput>;
  create?: InputMaybe<SequenceStepCreateWithoutLeadsInput>;
};

export type SequenceStepCreateNestedOneWithoutSequence_StepsInput = {
  connect?: InputMaybe<SequenceStepWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SequenceStepCreateOrConnectWithoutSequence_StepsInput>;
  create?: InputMaybe<SequenceStepCreateWithoutSequence_StepsInput>;
};

export type SequenceStepCreateNestedOneWithoutSnoozeTrackingInput = {
  connect?: InputMaybe<SequenceStepWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SequenceStepCreateOrConnectWithoutSnoozeTrackingInput>;
  create?: InputMaybe<SequenceStepCreateWithoutSnoozeTrackingInput>;
};

export type SequenceStepCreateNestedOneWithoutStatItemAggregationInput = {
  connect?: InputMaybe<SequenceStepWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SequenceStepCreateOrConnectWithoutStatItemAggregationInput>;
  create?: InputMaybe<SequenceStepCreateWithoutStatItemAggregationInput>;
};

export type SequenceStepCreateNestedOneWithoutStepActionLogInput = {
  connect?: InputMaybe<SequenceStepWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SequenceStepCreateOrConnectWithoutStepActionLogInput>;
  create?: InputMaybe<SequenceStepCreateWithoutStepActionLogInput>;
};

export type SequenceStepCreateOrConnectWithoutActionsInput = {
  create: SequenceStepCreateWithoutActionsInput;
  where: SequenceStepWhereUniqueInput;
};

export type SequenceStepCreateOrConnectWithoutConditionsInput = {
  create: SequenceStepCreateWithoutConditionsInput;
  where: SequenceStepWhereUniqueInput;
};

export type SequenceStepCreateOrConnectWithoutLeadActivityInput = {
  create: SequenceStepCreateWithoutLeadActivityInput;
  where: SequenceStepWhereUniqueInput;
};

export type SequenceStepCreateOrConnectWithoutLeadsInput = {
  create: SequenceStepCreateWithoutLeadsInput;
  where: SequenceStepWhereUniqueInput;
};

export type SequenceStepCreateOrConnectWithoutOrganizationInput = {
  create: SequenceStepCreateWithoutOrganizationInput;
  where: SequenceStepWhereUniqueInput;
};

export type SequenceStepCreateOrConnectWithoutOriginInput = {
  create: SequenceStepCreateWithoutOriginInput;
  where: SequenceStepWhereUniqueInput;
};

export type SequenceStepCreateOrConnectWithoutSequence_StepsInput = {
  create: SequenceStepCreateWithoutSequence_StepsInput;
  where: SequenceStepWhereUniqueInput;
};

export type SequenceStepCreateOrConnectWithoutSequenceInput = {
  create: SequenceStepCreateWithoutSequenceInput;
  where: SequenceStepWhereUniqueInput;
};

export type SequenceStepCreateOrConnectWithoutSnoozeTrackingInput = {
  create: SequenceStepCreateWithoutSnoozeTrackingInput;
  where: SequenceStepWhereUniqueInput;
};

export type SequenceStepCreateOrConnectWithoutStatItemAggregationInput = {
  create: SequenceStepCreateWithoutStatItemAggregationInput;
  where: SequenceStepWhereUniqueInput;
};

export type SequenceStepCreateOrConnectWithoutStepActionLogInput = {
  create: SequenceStepCreateWithoutStepActionLogInput;
  where: SequenceStepWhereUniqueInput;
};

export type SequenceStepCreateOrConnectWithoutTransferToSequenceInput = {
  create: SequenceStepCreateWithoutTransferToSequenceInput;
  where: SequenceStepWhereUniqueInput;
};

export type SequenceStepCreateWithoutActionsInput = {
  conditions?: InputMaybe<StepConditionCreateNestedManyWithoutStepInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  deleted_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  is_no_branch?: InputMaybe<Scalars['Boolean']['input']>;
  LeadActivity?: InputMaybe<LeadActivityCreateNestedManyWithoutLead_Current_StepInput>;
  leads?: InputMaybe<LeadCreateNestedManyWithoutSequence_StepInput>;
  Organization?: InputMaybe<OrganizationCreateNestedOneWithoutSequenceStepsInput>;
  origin?: InputMaybe<SequenceStepCreateNestedOneWithoutSequence_StepsInput>;
  post_step_action?: InputMaybe<PostAction>;
  sequence: SequenceCreateNestedOneWithoutSequence_StepsInput;
  sequence_steps?: InputMaybe<SequenceStepCreateNestedManyWithoutOriginInput>;
  SnoozeTracking?: InputMaybe<SnoozeTrackingCreateNestedManyWithoutStepInput>;
  StatItemAggregation?: InputMaybe<StatItemAggregationCreateNestedManyWithoutStepInput>;
  StepActionLog?: InputMaybe<StepActionLogCreateNestedManyWithoutStepInput>;
  transferToSequence?: InputMaybe<SequenceCreateNestedOneWithoutEndStepsTransferInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type SequenceStepCreateWithoutConditionsInput = {
  actions?: InputMaybe<StepActionCreateNestedManyWithoutStepInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  deleted_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  is_no_branch?: InputMaybe<Scalars['Boolean']['input']>;
  LeadActivity?: InputMaybe<LeadActivityCreateNestedManyWithoutLead_Current_StepInput>;
  leads?: InputMaybe<LeadCreateNestedManyWithoutSequence_StepInput>;
  Organization?: InputMaybe<OrganizationCreateNestedOneWithoutSequenceStepsInput>;
  origin?: InputMaybe<SequenceStepCreateNestedOneWithoutSequence_StepsInput>;
  post_step_action?: InputMaybe<PostAction>;
  sequence: SequenceCreateNestedOneWithoutSequence_StepsInput;
  sequence_steps?: InputMaybe<SequenceStepCreateNestedManyWithoutOriginInput>;
  SnoozeTracking?: InputMaybe<SnoozeTrackingCreateNestedManyWithoutStepInput>;
  StatItemAggregation?: InputMaybe<StatItemAggregationCreateNestedManyWithoutStepInput>;
  StepActionLog?: InputMaybe<StepActionLogCreateNestedManyWithoutStepInput>;
  transferToSequence?: InputMaybe<SequenceCreateNestedOneWithoutEndStepsTransferInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type SequenceStepCreateWithoutLeadActivityInput = {
  actions?: InputMaybe<StepActionCreateNestedManyWithoutStepInput>;
  conditions?: InputMaybe<StepConditionCreateNestedManyWithoutStepInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  deleted_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  is_no_branch?: InputMaybe<Scalars['Boolean']['input']>;
  leads?: InputMaybe<LeadCreateNestedManyWithoutSequence_StepInput>;
  Organization?: InputMaybe<OrganizationCreateNestedOneWithoutSequenceStepsInput>;
  origin?: InputMaybe<SequenceStepCreateNestedOneWithoutSequence_StepsInput>;
  post_step_action?: InputMaybe<PostAction>;
  sequence: SequenceCreateNestedOneWithoutSequence_StepsInput;
  sequence_steps?: InputMaybe<SequenceStepCreateNestedManyWithoutOriginInput>;
  SnoozeTracking?: InputMaybe<SnoozeTrackingCreateNestedManyWithoutStepInput>;
  StatItemAggregation?: InputMaybe<StatItemAggregationCreateNestedManyWithoutStepInput>;
  StepActionLog?: InputMaybe<StepActionLogCreateNestedManyWithoutStepInput>;
  transferToSequence?: InputMaybe<SequenceCreateNestedOneWithoutEndStepsTransferInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type SequenceStepCreateWithoutLeadsInput = {
  actions?: InputMaybe<StepActionCreateNestedManyWithoutStepInput>;
  conditions?: InputMaybe<StepConditionCreateNestedManyWithoutStepInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  deleted_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  is_no_branch?: InputMaybe<Scalars['Boolean']['input']>;
  LeadActivity?: InputMaybe<LeadActivityCreateNestedManyWithoutLead_Current_StepInput>;
  Organization?: InputMaybe<OrganizationCreateNestedOneWithoutSequenceStepsInput>;
  origin?: InputMaybe<SequenceStepCreateNestedOneWithoutSequence_StepsInput>;
  post_step_action?: InputMaybe<PostAction>;
  sequence: SequenceCreateNestedOneWithoutSequence_StepsInput;
  sequence_steps?: InputMaybe<SequenceStepCreateNestedManyWithoutOriginInput>;
  SnoozeTracking?: InputMaybe<SnoozeTrackingCreateNestedManyWithoutStepInput>;
  StatItemAggregation?: InputMaybe<StatItemAggregationCreateNestedManyWithoutStepInput>;
  StepActionLog?: InputMaybe<StepActionLogCreateNestedManyWithoutStepInput>;
  transferToSequence?: InputMaybe<SequenceCreateNestedOneWithoutEndStepsTransferInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type SequenceStepCreateWithoutOrganizationInput = {
  actions?: InputMaybe<StepActionCreateNestedManyWithoutStepInput>;
  conditions?: InputMaybe<StepConditionCreateNestedManyWithoutStepInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  deleted_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  is_no_branch?: InputMaybe<Scalars['Boolean']['input']>;
  LeadActivity?: InputMaybe<LeadActivityCreateNestedManyWithoutLead_Current_StepInput>;
  leads?: InputMaybe<LeadCreateNestedManyWithoutSequence_StepInput>;
  origin?: InputMaybe<SequenceStepCreateNestedOneWithoutSequence_StepsInput>;
  post_step_action?: InputMaybe<PostAction>;
  sequence: SequenceCreateNestedOneWithoutSequence_StepsInput;
  sequence_steps?: InputMaybe<SequenceStepCreateNestedManyWithoutOriginInput>;
  SnoozeTracking?: InputMaybe<SnoozeTrackingCreateNestedManyWithoutStepInput>;
  StatItemAggregation?: InputMaybe<StatItemAggregationCreateNestedManyWithoutStepInput>;
  StepActionLog?: InputMaybe<StepActionLogCreateNestedManyWithoutStepInput>;
  transferToSequence?: InputMaybe<SequenceCreateNestedOneWithoutEndStepsTransferInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type SequenceStepCreateWithoutOriginInput = {
  actions?: InputMaybe<StepActionCreateNestedManyWithoutStepInput>;
  conditions?: InputMaybe<StepConditionCreateNestedManyWithoutStepInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  deleted_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  is_no_branch?: InputMaybe<Scalars['Boolean']['input']>;
  LeadActivity?: InputMaybe<LeadActivityCreateNestedManyWithoutLead_Current_StepInput>;
  leads?: InputMaybe<LeadCreateNestedManyWithoutSequence_StepInput>;
  Organization?: InputMaybe<OrganizationCreateNestedOneWithoutSequenceStepsInput>;
  post_step_action?: InputMaybe<PostAction>;
  sequence: SequenceCreateNestedOneWithoutSequence_StepsInput;
  sequence_steps?: InputMaybe<SequenceStepCreateNestedManyWithoutOriginInput>;
  SnoozeTracking?: InputMaybe<SnoozeTrackingCreateNestedManyWithoutStepInput>;
  StatItemAggregation?: InputMaybe<StatItemAggregationCreateNestedManyWithoutStepInput>;
  StepActionLog?: InputMaybe<StepActionLogCreateNestedManyWithoutStepInput>;
  transferToSequence?: InputMaybe<SequenceCreateNestedOneWithoutEndStepsTransferInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type SequenceStepCreateWithoutSequence_StepsInput = {
  actions?: InputMaybe<StepActionCreateNestedManyWithoutStepInput>;
  conditions?: InputMaybe<StepConditionCreateNestedManyWithoutStepInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  deleted_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  is_no_branch?: InputMaybe<Scalars['Boolean']['input']>;
  LeadActivity?: InputMaybe<LeadActivityCreateNestedManyWithoutLead_Current_StepInput>;
  leads?: InputMaybe<LeadCreateNestedManyWithoutSequence_StepInput>;
  Organization?: InputMaybe<OrganizationCreateNestedOneWithoutSequenceStepsInput>;
  origin?: InputMaybe<SequenceStepCreateNestedOneWithoutSequence_StepsInput>;
  post_step_action?: InputMaybe<PostAction>;
  sequence: SequenceCreateNestedOneWithoutSequence_StepsInput;
  SnoozeTracking?: InputMaybe<SnoozeTrackingCreateNestedManyWithoutStepInput>;
  StatItemAggregation?: InputMaybe<StatItemAggregationCreateNestedManyWithoutStepInput>;
  StepActionLog?: InputMaybe<StepActionLogCreateNestedManyWithoutStepInput>;
  transferToSequence?: InputMaybe<SequenceCreateNestedOneWithoutEndStepsTransferInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type SequenceStepCreateWithoutSequenceInput = {
  actions?: InputMaybe<StepActionCreateNestedManyWithoutStepInput>;
  conditions?: InputMaybe<StepConditionCreateNestedManyWithoutStepInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  deleted_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  is_no_branch?: InputMaybe<Scalars['Boolean']['input']>;
  LeadActivity?: InputMaybe<LeadActivityCreateNestedManyWithoutLead_Current_StepInput>;
  leads?: InputMaybe<LeadCreateNestedManyWithoutSequence_StepInput>;
  Organization?: InputMaybe<OrganizationCreateNestedOneWithoutSequenceStepsInput>;
  origin?: InputMaybe<SequenceStepCreateNestedOneWithoutSequence_StepsInput>;
  post_step_action?: InputMaybe<PostAction>;
  sequence_steps?: InputMaybe<SequenceStepCreateNestedManyWithoutOriginInput>;
  SnoozeTracking?: InputMaybe<SnoozeTrackingCreateNestedManyWithoutStepInput>;
  StatItemAggregation?: InputMaybe<StatItemAggregationCreateNestedManyWithoutStepInput>;
  StepActionLog?: InputMaybe<StepActionLogCreateNestedManyWithoutStepInput>;
  transferToSequence?: InputMaybe<SequenceCreateNestedOneWithoutEndStepsTransferInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type SequenceStepCreateWithoutSnoozeTrackingInput = {
  actions?: InputMaybe<StepActionCreateNestedManyWithoutStepInput>;
  conditions?: InputMaybe<StepConditionCreateNestedManyWithoutStepInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  deleted_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  is_no_branch?: InputMaybe<Scalars['Boolean']['input']>;
  LeadActivity?: InputMaybe<LeadActivityCreateNestedManyWithoutLead_Current_StepInput>;
  leads?: InputMaybe<LeadCreateNestedManyWithoutSequence_StepInput>;
  Organization?: InputMaybe<OrganizationCreateNestedOneWithoutSequenceStepsInput>;
  origin?: InputMaybe<SequenceStepCreateNestedOneWithoutSequence_StepsInput>;
  post_step_action?: InputMaybe<PostAction>;
  sequence: SequenceCreateNestedOneWithoutSequence_StepsInput;
  sequence_steps?: InputMaybe<SequenceStepCreateNestedManyWithoutOriginInput>;
  StatItemAggregation?: InputMaybe<StatItemAggregationCreateNestedManyWithoutStepInput>;
  StepActionLog?: InputMaybe<StepActionLogCreateNestedManyWithoutStepInput>;
  transferToSequence?: InputMaybe<SequenceCreateNestedOneWithoutEndStepsTransferInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type SequenceStepCreateWithoutStatItemAggregationInput = {
  actions?: InputMaybe<StepActionCreateNestedManyWithoutStepInput>;
  conditions?: InputMaybe<StepConditionCreateNestedManyWithoutStepInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  deleted_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  is_no_branch?: InputMaybe<Scalars['Boolean']['input']>;
  LeadActivity?: InputMaybe<LeadActivityCreateNestedManyWithoutLead_Current_StepInput>;
  leads?: InputMaybe<LeadCreateNestedManyWithoutSequence_StepInput>;
  Organization?: InputMaybe<OrganizationCreateNestedOneWithoutSequenceStepsInput>;
  origin?: InputMaybe<SequenceStepCreateNestedOneWithoutSequence_StepsInput>;
  post_step_action?: InputMaybe<PostAction>;
  sequence: SequenceCreateNestedOneWithoutSequence_StepsInput;
  sequence_steps?: InputMaybe<SequenceStepCreateNestedManyWithoutOriginInput>;
  SnoozeTracking?: InputMaybe<SnoozeTrackingCreateNestedManyWithoutStepInput>;
  StepActionLog?: InputMaybe<StepActionLogCreateNestedManyWithoutStepInput>;
  transferToSequence?: InputMaybe<SequenceCreateNestedOneWithoutEndStepsTransferInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type SequenceStepCreateWithoutStepActionLogInput = {
  actions?: InputMaybe<StepActionCreateNestedManyWithoutStepInput>;
  conditions?: InputMaybe<StepConditionCreateNestedManyWithoutStepInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  deleted_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  is_no_branch?: InputMaybe<Scalars['Boolean']['input']>;
  LeadActivity?: InputMaybe<LeadActivityCreateNestedManyWithoutLead_Current_StepInput>;
  leads?: InputMaybe<LeadCreateNestedManyWithoutSequence_StepInput>;
  Organization?: InputMaybe<OrganizationCreateNestedOneWithoutSequenceStepsInput>;
  origin?: InputMaybe<SequenceStepCreateNestedOneWithoutSequence_StepsInput>;
  post_step_action?: InputMaybe<PostAction>;
  sequence: SequenceCreateNestedOneWithoutSequence_StepsInput;
  sequence_steps?: InputMaybe<SequenceStepCreateNestedManyWithoutOriginInput>;
  SnoozeTracking?: InputMaybe<SnoozeTrackingCreateNestedManyWithoutStepInput>;
  StatItemAggregation?: InputMaybe<StatItemAggregationCreateNestedManyWithoutStepInput>;
  transferToSequence?: InputMaybe<SequenceCreateNestedOneWithoutEndStepsTransferInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type SequenceStepCreateWithoutTransferToSequenceInput = {
  actions?: InputMaybe<StepActionCreateNestedManyWithoutStepInput>;
  conditions?: InputMaybe<StepConditionCreateNestedManyWithoutStepInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  deleted_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  is_no_branch?: InputMaybe<Scalars['Boolean']['input']>;
  LeadActivity?: InputMaybe<LeadActivityCreateNestedManyWithoutLead_Current_StepInput>;
  leads?: InputMaybe<LeadCreateNestedManyWithoutSequence_StepInput>;
  Organization?: InputMaybe<OrganizationCreateNestedOneWithoutSequenceStepsInput>;
  origin?: InputMaybe<SequenceStepCreateNestedOneWithoutSequence_StepsInput>;
  post_step_action?: InputMaybe<PostAction>;
  sequence: SequenceCreateNestedOneWithoutSequence_StepsInput;
  sequence_steps?: InputMaybe<SequenceStepCreateNestedManyWithoutOriginInput>;
  SnoozeTracking?: InputMaybe<SnoozeTrackingCreateNestedManyWithoutStepInput>;
  StatItemAggregation?: InputMaybe<StatItemAggregationCreateNestedManyWithoutStepInput>;
  StepActionLog?: InputMaybe<StepActionLogCreateNestedManyWithoutStepInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

/** SequenceStepInput for creation and update */
export type SequenceStepInput = {
  conditions?: InputMaybe<Array<InputMaybe<Dispositiontype>>>;
  is_branch?: Scalars['Boolean']['input'];
  is_no_branch?: InputMaybe<Scalars['Boolean']['input']>;
  is_origin_update?: Scalars['Boolean']['input'];
  origin_id?: InputMaybe<Scalars['String']['input']>;
  sequence_id: Scalars['String']['input'];
  step_id?: InputMaybe<Scalars['String']['input']>;
  tasks?: InputMaybe<Array<SequenceTaskInput>>;
};

export type SequenceStepListRelationFilter = {
  every?: InputMaybe<SequenceStepWhereInput>;
  none?: InputMaybe<SequenceStepWhereInput>;
  some?: InputMaybe<SequenceStepWhereInput>;
};

export type SequenceStepOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
  count?: InputMaybe<SortOrder>;
};

export type SequenceStepOrderByWithRelationInput = {
  actions?: InputMaybe<StepActionOrderByRelationAggregateInput>;
  conditions?: InputMaybe<StepConditionOrderByRelationAggregateInput>;
  created_at?: InputMaybe<SortOrder>;
  deleted_at?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  is_no_branch?: InputMaybe<SortOrder>;
  LeadActivity?: InputMaybe<LeadActivityOrderByRelationAggregateInput>;
  leads?: InputMaybe<LeadOrderByRelationAggregateInput>;
  Organization?: InputMaybe<OrganizationOrderByWithRelationInput>;
  organization_id?: InputMaybe<SortOrder>;
  origin?: InputMaybe<SequenceStepOrderByWithRelationInput>;
  origin_id?: InputMaybe<SortOrder>;
  post_step_action?: InputMaybe<SortOrder>;
  sequence?: InputMaybe<SequenceOrderByWithRelationInput>;
  sequence_id?: InputMaybe<SortOrder>;
  sequence_steps?: InputMaybe<SequenceStepOrderByRelationAggregateInput>;
  SnoozeTracking?: InputMaybe<SnoozeTrackingOrderByRelationAggregateInput>;
  StatItemAggregation?: InputMaybe<StatItemAggregationOrderByRelationAggregateInput>;
  StepActionLog?: InputMaybe<StepActionLogOrderByRelationAggregateInput>;
  transfer_to_sequence_id?: InputMaybe<SortOrder>;
  transferToSequence?: InputMaybe<SequenceOrderByWithRelationInput>;
  updated_at?: InputMaybe<SortOrder>;
};

export type SequenceStepScalarWhereInput = {
  AND?: InputMaybe<Array<SequenceStepScalarWhereInput>>;
  created_at?: InputMaybe<DateTimeFilter>;
  deleted_at?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<StringFilter>;
  is_no_branch?: InputMaybe<BoolFilter>;
  NOT?: InputMaybe<Array<SequenceStepScalarWhereInput>>;
  OR?: InputMaybe<Array<SequenceStepScalarWhereInput>>;
  organization_id?: InputMaybe<StringNullableFilter>;
  origin_id?: InputMaybe<StringNullableFilter>;
  post_step_action?: InputMaybe<EnumpostActionFilter>;
  sequence_id?: InputMaybe<StringFilter>;
  transfer_to_sequence_id?: InputMaybe<StringNullableFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
};

export enum SequenceStepScheduledEventBehavior {
  Exit = 'Exit',
  Neutral = 'Neutral',
  Replacement = 'Replacement'
}

export type SequenceStepUpdateManyMutationInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deleted_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  is_no_branch?: InputMaybe<BoolFieldUpdateOperationsInput>;
  post_step_action?: InputMaybe<EnumpostActionFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type SequenceStepUpdateManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<SequenceStepWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SequenceStepCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<SequenceStepCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<SequenceStepCreateManyOrganizationInputEnvelope>;
  delete?: InputMaybe<Array<SequenceStepWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<SequenceStepScalarWhereInput>>;
  disconnect?: InputMaybe<Array<SequenceStepWhereUniqueInput>>;
  set?: InputMaybe<Array<SequenceStepWhereUniqueInput>>;
  update?: InputMaybe<Array<SequenceStepUpdateWithWhereUniqueWithoutOrganizationInput>>;
  updateMany?: InputMaybe<Array<SequenceStepUpdateManyWithWhereWithoutOrganizationInput>>;
  upsert?: InputMaybe<Array<SequenceStepUpsertWithWhereUniqueWithoutOrganizationInput>>;
};

export type SequenceStepUpdateManyWithoutOriginInput = {
  connect?: InputMaybe<Array<SequenceStepWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SequenceStepCreateOrConnectWithoutOriginInput>>;
  create?: InputMaybe<Array<SequenceStepCreateWithoutOriginInput>>;
  createMany?: InputMaybe<SequenceStepCreateManyOriginInputEnvelope>;
  delete?: InputMaybe<Array<SequenceStepWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<SequenceStepScalarWhereInput>>;
  disconnect?: InputMaybe<Array<SequenceStepWhereUniqueInput>>;
  set?: InputMaybe<Array<SequenceStepWhereUniqueInput>>;
  update?: InputMaybe<Array<SequenceStepUpdateWithWhereUniqueWithoutOriginInput>>;
  updateMany?: InputMaybe<Array<SequenceStepUpdateManyWithWhereWithoutOriginInput>>;
  upsert?: InputMaybe<Array<SequenceStepUpsertWithWhereUniqueWithoutOriginInput>>;
};

export type SequenceStepUpdateManyWithoutSequenceInput = {
  connect?: InputMaybe<Array<SequenceStepWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SequenceStepCreateOrConnectWithoutSequenceInput>>;
  create?: InputMaybe<Array<SequenceStepCreateWithoutSequenceInput>>;
  createMany?: InputMaybe<SequenceStepCreateManySequenceInputEnvelope>;
  delete?: InputMaybe<Array<SequenceStepWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<SequenceStepScalarWhereInput>>;
  disconnect?: InputMaybe<Array<SequenceStepWhereUniqueInput>>;
  set?: InputMaybe<Array<SequenceStepWhereUniqueInput>>;
  update?: InputMaybe<Array<SequenceStepUpdateWithWhereUniqueWithoutSequenceInput>>;
  updateMany?: InputMaybe<Array<SequenceStepUpdateManyWithWhereWithoutSequenceInput>>;
  upsert?: InputMaybe<Array<SequenceStepUpsertWithWhereUniqueWithoutSequenceInput>>;
};

export type SequenceStepUpdateManyWithoutTransferToSequenceInput = {
  connect?: InputMaybe<Array<SequenceStepWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SequenceStepCreateOrConnectWithoutTransferToSequenceInput>>;
  create?: InputMaybe<Array<SequenceStepCreateWithoutTransferToSequenceInput>>;
  createMany?: InputMaybe<SequenceStepCreateManyTransferToSequenceInputEnvelope>;
  delete?: InputMaybe<Array<SequenceStepWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<SequenceStepScalarWhereInput>>;
  disconnect?: InputMaybe<Array<SequenceStepWhereUniqueInput>>;
  set?: InputMaybe<Array<SequenceStepWhereUniqueInput>>;
  update?: InputMaybe<Array<SequenceStepUpdateWithWhereUniqueWithoutTransferToSequenceInput>>;
  updateMany?: InputMaybe<Array<SequenceStepUpdateManyWithWhereWithoutTransferToSequenceInput>>;
  upsert?: InputMaybe<Array<SequenceStepUpsertWithWhereUniqueWithoutTransferToSequenceInput>>;
};

export type SequenceStepUpdateManyWithWhereWithoutOrganizationInput = {
  data: SequenceStepUpdateManyMutationInput;
  where: SequenceStepScalarWhereInput;
};

export type SequenceStepUpdateManyWithWhereWithoutOriginInput = {
  data: SequenceStepUpdateManyMutationInput;
  where: SequenceStepScalarWhereInput;
};

export type SequenceStepUpdateManyWithWhereWithoutSequenceInput = {
  data: SequenceStepUpdateManyMutationInput;
  where: SequenceStepScalarWhereInput;
};

export type SequenceStepUpdateManyWithWhereWithoutTransferToSequenceInput = {
  data: SequenceStepUpdateManyMutationInput;
  where: SequenceStepScalarWhereInput;
};

export type SequenceStepUpdateOneRequiredWithoutActionsInput = {
  connect?: InputMaybe<SequenceStepWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SequenceStepCreateOrConnectWithoutActionsInput>;
  create?: InputMaybe<SequenceStepCreateWithoutActionsInput>;
  update?: InputMaybe<SequenceStepUpdateWithoutActionsInput>;
  upsert?: InputMaybe<SequenceStepUpsertWithoutActionsInput>;
};

export type SequenceStepUpdateOneRequiredWithoutSnoozeTrackingInput = {
  connect?: InputMaybe<SequenceStepWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SequenceStepCreateOrConnectWithoutSnoozeTrackingInput>;
  create?: InputMaybe<SequenceStepCreateWithoutSnoozeTrackingInput>;
  update?: InputMaybe<SequenceStepUpdateWithoutSnoozeTrackingInput>;
  upsert?: InputMaybe<SequenceStepUpsertWithoutSnoozeTrackingInput>;
};

export type SequenceStepUpdateOneWithoutConditionsInput = {
  connect?: InputMaybe<SequenceStepWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SequenceStepCreateOrConnectWithoutConditionsInput>;
  create?: InputMaybe<SequenceStepCreateWithoutConditionsInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<SequenceStepUpdateWithoutConditionsInput>;
  upsert?: InputMaybe<SequenceStepUpsertWithoutConditionsInput>;
};

export type SequenceStepUpdateOneWithoutLeadActivityInput = {
  connect?: InputMaybe<SequenceStepWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SequenceStepCreateOrConnectWithoutLeadActivityInput>;
  create?: InputMaybe<SequenceStepCreateWithoutLeadActivityInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<SequenceStepUpdateWithoutLeadActivityInput>;
  upsert?: InputMaybe<SequenceStepUpsertWithoutLeadActivityInput>;
};

export type SequenceStepUpdateOneWithoutLeadsInput = {
  connect?: InputMaybe<SequenceStepWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SequenceStepCreateOrConnectWithoutLeadsInput>;
  create?: InputMaybe<SequenceStepCreateWithoutLeadsInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<SequenceStepUpdateWithoutLeadsInput>;
  upsert?: InputMaybe<SequenceStepUpsertWithoutLeadsInput>;
};

export type SequenceStepUpdateOneWithoutSequence_StepsInput = {
  connect?: InputMaybe<SequenceStepWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SequenceStepCreateOrConnectWithoutSequence_StepsInput>;
  create?: InputMaybe<SequenceStepCreateWithoutSequence_StepsInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<SequenceStepUpdateWithoutSequence_StepsInput>;
  upsert?: InputMaybe<SequenceStepUpsertWithoutSequence_StepsInput>;
};

export type SequenceStepUpdateOneWithoutStatItemAggregationInput = {
  connect?: InputMaybe<SequenceStepWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SequenceStepCreateOrConnectWithoutStatItemAggregationInput>;
  create?: InputMaybe<SequenceStepCreateWithoutStatItemAggregationInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<SequenceStepUpdateWithoutStatItemAggregationInput>;
  upsert?: InputMaybe<SequenceStepUpsertWithoutStatItemAggregationInput>;
};

export type SequenceStepUpdateOneWithoutStepActionLogInput = {
  connect?: InputMaybe<SequenceStepWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SequenceStepCreateOrConnectWithoutStepActionLogInput>;
  create?: InputMaybe<SequenceStepCreateWithoutStepActionLogInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<SequenceStepUpdateWithoutStepActionLogInput>;
  upsert?: InputMaybe<SequenceStepUpsertWithoutStepActionLogInput>;
};

export type SequenceStepUpdateWithoutActionsInput = {
  conditions?: InputMaybe<StepConditionUpdateManyWithoutStepInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deleted_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  is_no_branch?: InputMaybe<BoolFieldUpdateOperationsInput>;
  LeadActivity?: InputMaybe<LeadActivityUpdateManyWithoutLead_Current_StepInput>;
  leads?: InputMaybe<LeadUpdateManyWithoutSequence_StepInput>;
  Organization?: InputMaybe<OrganizationUpdateOneWithoutSequenceStepsInput>;
  origin?: InputMaybe<SequenceStepUpdateOneWithoutSequence_StepsInput>;
  post_step_action?: InputMaybe<EnumpostActionFieldUpdateOperationsInput>;
  sequence?: InputMaybe<SequenceUpdateOneRequiredWithoutSequence_StepsInput>;
  sequence_steps?: InputMaybe<SequenceStepUpdateManyWithoutOriginInput>;
  SnoozeTracking?: InputMaybe<SnoozeTrackingUpdateManyWithoutStepInput>;
  StatItemAggregation?: InputMaybe<StatItemAggregationUpdateManyWithoutStepInput>;
  StepActionLog?: InputMaybe<StepActionLogUpdateManyWithoutStepInput>;
  transferToSequence?: InputMaybe<SequenceUpdateOneWithoutEndStepsTransferInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type SequenceStepUpdateWithoutConditionsInput = {
  actions?: InputMaybe<StepActionUpdateManyWithoutStepInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deleted_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  is_no_branch?: InputMaybe<BoolFieldUpdateOperationsInput>;
  LeadActivity?: InputMaybe<LeadActivityUpdateManyWithoutLead_Current_StepInput>;
  leads?: InputMaybe<LeadUpdateManyWithoutSequence_StepInput>;
  Organization?: InputMaybe<OrganizationUpdateOneWithoutSequenceStepsInput>;
  origin?: InputMaybe<SequenceStepUpdateOneWithoutSequence_StepsInput>;
  post_step_action?: InputMaybe<EnumpostActionFieldUpdateOperationsInput>;
  sequence?: InputMaybe<SequenceUpdateOneRequiredWithoutSequence_StepsInput>;
  sequence_steps?: InputMaybe<SequenceStepUpdateManyWithoutOriginInput>;
  SnoozeTracking?: InputMaybe<SnoozeTrackingUpdateManyWithoutStepInput>;
  StatItemAggregation?: InputMaybe<StatItemAggregationUpdateManyWithoutStepInput>;
  StepActionLog?: InputMaybe<StepActionLogUpdateManyWithoutStepInput>;
  transferToSequence?: InputMaybe<SequenceUpdateOneWithoutEndStepsTransferInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type SequenceStepUpdateWithoutLeadActivityInput = {
  actions?: InputMaybe<StepActionUpdateManyWithoutStepInput>;
  conditions?: InputMaybe<StepConditionUpdateManyWithoutStepInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deleted_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  is_no_branch?: InputMaybe<BoolFieldUpdateOperationsInput>;
  leads?: InputMaybe<LeadUpdateManyWithoutSequence_StepInput>;
  Organization?: InputMaybe<OrganizationUpdateOneWithoutSequenceStepsInput>;
  origin?: InputMaybe<SequenceStepUpdateOneWithoutSequence_StepsInput>;
  post_step_action?: InputMaybe<EnumpostActionFieldUpdateOperationsInput>;
  sequence?: InputMaybe<SequenceUpdateOneRequiredWithoutSequence_StepsInput>;
  sequence_steps?: InputMaybe<SequenceStepUpdateManyWithoutOriginInput>;
  SnoozeTracking?: InputMaybe<SnoozeTrackingUpdateManyWithoutStepInput>;
  StatItemAggregation?: InputMaybe<StatItemAggregationUpdateManyWithoutStepInput>;
  StepActionLog?: InputMaybe<StepActionLogUpdateManyWithoutStepInput>;
  transferToSequence?: InputMaybe<SequenceUpdateOneWithoutEndStepsTransferInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type SequenceStepUpdateWithoutLeadsInput = {
  actions?: InputMaybe<StepActionUpdateManyWithoutStepInput>;
  conditions?: InputMaybe<StepConditionUpdateManyWithoutStepInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deleted_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  is_no_branch?: InputMaybe<BoolFieldUpdateOperationsInput>;
  LeadActivity?: InputMaybe<LeadActivityUpdateManyWithoutLead_Current_StepInput>;
  Organization?: InputMaybe<OrganizationUpdateOneWithoutSequenceStepsInput>;
  origin?: InputMaybe<SequenceStepUpdateOneWithoutSequence_StepsInput>;
  post_step_action?: InputMaybe<EnumpostActionFieldUpdateOperationsInput>;
  sequence?: InputMaybe<SequenceUpdateOneRequiredWithoutSequence_StepsInput>;
  sequence_steps?: InputMaybe<SequenceStepUpdateManyWithoutOriginInput>;
  SnoozeTracking?: InputMaybe<SnoozeTrackingUpdateManyWithoutStepInput>;
  StatItemAggregation?: InputMaybe<StatItemAggregationUpdateManyWithoutStepInput>;
  StepActionLog?: InputMaybe<StepActionLogUpdateManyWithoutStepInput>;
  transferToSequence?: InputMaybe<SequenceUpdateOneWithoutEndStepsTransferInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type SequenceStepUpdateWithoutOrganizationInput = {
  actions?: InputMaybe<StepActionUpdateManyWithoutStepInput>;
  conditions?: InputMaybe<StepConditionUpdateManyWithoutStepInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deleted_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  is_no_branch?: InputMaybe<BoolFieldUpdateOperationsInput>;
  LeadActivity?: InputMaybe<LeadActivityUpdateManyWithoutLead_Current_StepInput>;
  leads?: InputMaybe<LeadUpdateManyWithoutSequence_StepInput>;
  origin?: InputMaybe<SequenceStepUpdateOneWithoutSequence_StepsInput>;
  post_step_action?: InputMaybe<EnumpostActionFieldUpdateOperationsInput>;
  sequence?: InputMaybe<SequenceUpdateOneRequiredWithoutSequence_StepsInput>;
  sequence_steps?: InputMaybe<SequenceStepUpdateManyWithoutOriginInput>;
  SnoozeTracking?: InputMaybe<SnoozeTrackingUpdateManyWithoutStepInput>;
  StatItemAggregation?: InputMaybe<StatItemAggregationUpdateManyWithoutStepInput>;
  StepActionLog?: InputMaybe<StepActionLogUpdateManyWithoutStepInput>;
  transferToSequence?: InputMaybe<SequenceUpdateOneWithoutEndStepsTransferInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type SequenceStepUpdateWithoutOriginInput = {
  actions?: InputMaybe<StepActionUpdateManyWithoutStepInput>;
  conditions?: InputMaybe<StepConditionUpdateManyWithoutStepInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deleted_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  is_no_branch?: InputMaybe<BoolFieldUpdateOperationsInput>;
  LeadActivity?: InputMaybe<LeadActivityUpdateManyWithoutLead_Current_StepInput>;
  leads?: InputMaybe<LeadUpdateManyWithoutSequence_StepInput>;
  Organization?: InputMaybe<OrganizationUpdateOneWithoutSequenceStepsInput>;
  post_step_action?: InputMaybe<EnumpostActionFieldUpdateOperationsInput>;
  sequence?: InputMaybe<SequenceUpdateOneRequiredWithoutSequence_StepsInput>;
  sequence_steps?: InputMaybe<SequenceStepUpdateManyWithoutOriginInput>;
  SnoozeTracking?: InputMaybe<SnoozeTrackingUpdateManyWithoutStepInput>;
  StatItemAggregation?: InputMaybe<StatItemAggregationUpdateManyWithoutStepInput>;
  StepActionLog?: InputMaybe<StepActionLogUpdateManyWithoutStepInput>;
  transferToSequence?: InputMaybe<SequenceUpdateOneWithoutEndStepsTransferInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type SequenceStepUpdateWithoutSequence_StepsInput = {
  actions?: InputMaybe<StepActionUpdateManyWithoutStepInput>;
  conditions?: InputMaybe<StepConditionUpdateManyWithoutStepInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deleted_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  is_no_branch?: InputMaybe<BoolFieldUpdateOperationsInput>;
  LeadActivity?: InputMaybe<LeadActivityUpdateManyWithoutLead_Current_StepInput>;
  leads?: InputMaybe<LeadUpdateManyWithoutSequence_StepInput>;
  Organization?: InputMaybe<OrganizationUpdateOneWithoutSequenceStepsInput>;
  origin?: InputMaybe<SequenceStepUpdateOneWithoutSequence_StepsInput>;
  post_step_action?: InputMaybe<EnumpostActionFieldUpdateOperationsInput>;
  sequence?: InputMaybe<SequenceUpdateOneRequiredWithoutSequence_StepsInput>;
  SnoozeTracking?: InputMaybe<SnoozeTrackingUpdateManyWithoutStepInput>;
  StatItemAggregation?: InputMaybe<StatItemAggregationUpdateManyWithoutStepInput>;
  StepActionLog?: InputMaybe<StepActionLogUpdateManyWithoutStepInput>;
  transferToSequence?: InputMaybe<SequenceUpdateOneWithoutEndStepsTransferInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type SequenceStepUpdateWithoutSequenceInput = {
  actions?: InputMaybe<StepActionUpdateManyWithoutStepInput>;
  conditions?: InputMaybe<StepConditionUpdateManyWithoutStepInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deleted_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  is_no_branch?: InputMaybe<BoolFieldUpdateOperationsInput>;
  LeadActivity?: InputMaybe<LeadActivityUpdateManyWithoutLead_Current_StepInput>;
  leads?: InputMaybe<LeadUpdateManyWithoutSequence_StepInput>;
  Organization?: InputMaybe<OrganizationUpdateOneWithoutSequenceStepsInput>;
  origin?: InputMaybe<SequenceStepUpdateOneWithoutSequence_StepsInput>;
  post_step_action?: InputMaybe<EnumpostActionFieldUpdateOperationsInput>;
  sequence_steps?: InputMaybe<SequenceStepUpdateManyWithoutOriginInput>;
  SnoozeTracking?: InputMaybe<SnoozeTrackingUpdateManyWithoutStepInput>;
  StatItemAggregation?: InputMaybe<StatItemAggregationUpdateManyWithoutStepInput>;
  StepActionLog?: InputMaybe<StepActionLogUpdateManyWithoutStepInput>;
  transferToSequence?: InputMaybe<SequenceUpdateOneWithoutEndStepsTransferInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type SequenceStepUpdateWithoutSnoozeTrackingInput = {
  actions?: InputMaybe<StepActionUpdateManyWithoutStepInput>;
  conditions?: InputMaybe<StepConditionUpdateManyWithoutStepInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deleted_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  is_no_branch?: InputMaybe<BoolFieldUpdateOperationsInput>;
  LeadActivity?: InputMaybe<LeadActivityUpdateManyWithoutLead_Current_StepInput>;
  leads?: InputMaybe<LeadUpdateManyWithoutSequence_StepInput>;
  Organization?: InputMaybe<OrganizationUpdateOneWithoutSequenceStepsInput>;
  origin?: InputMaybe<SequenceStepUpdateOneWithoutSequence_StepsInput>;
  post_step_action?: InputMaybe<EnumpostActionFieldUpdateOperationsInput>;
  sequence?: InputMaybe<SequenceUpdateOneRequiredWithoutSequence_StepsInput>;
  sequence_steps?: InputMaybe<SequenceStepUpdateManyWithoutOriginInput>;
  StatItemAggregation?: InputMaybe<StatItemAggregationUpdateManyWithoutStepInput>;
  StepActionLog?: InputMaybe<StepActionLogUpdateManyWithoutStepInput>;
  transferToSequence?: InputMaybe<SequenceUpdateOneWithoutEndStepsTransferInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type SequenceStepUpdateWithoutStatItemAggregationInput = {
  actions?: InputMaybe<StepActionUpdateManyWithoutStepInput>;
  conditions?: InputMaybe<StepConditionUpdateManyWithoutStepInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deleted_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  is_no_branch?: InputMaybe<BoolFieldUpdateOperationsInput>;
  LeadActivity?: InputMaybe<LeadActivityUpdateManyWithoutLead_Current_StepInput>;
  leads?: InputMaybe<LeadUpdateManyWithoutSequence_StepInput>;
  Organization?: InputMaybe<OrganizationUpdateOneWithoutSequenceStepsInput>;
  origin?: InputMaybe<SequenceStepUpdateOneWithoutSequence_StepsInput>;
  post_step_action?: InputMaybe<EnumpostActionFieldUpdateOperationsInput>;
  sequence?: InputMaybe<SequenceUpdateOneRequiredWithoutSequence_StepsInput>;
  sequence_steps?: InputMaybe<SequenceStepUpdateManyWithoutOriginInput>;
  SnoozeTracking?: InputMaybe<SnoozeTrackingUpdateManyWithoutStepInput>;
  StepActionLog?: InputMaybe<StepActionLogUpdateManyWithoutStepInput>;
  transferToSequence?: InputMaybe<SequenceUpdateOneWithoutEndStepsTransferInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type SequenceStepUpdateWithoutStepActionLogInput = {
  actions?: InputMaybe<StepActionUpdateManyWithoutStepInput>;
  conditions?: InputMaybe<StepConditionUpdateManyWithoutStepInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deleted_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  is_no_branch?: InputMaybe<BoolFieldUpdateOperationsInput>;
  LeadActivity?: InputMaybe<LeadActivityUpdateManyWithoutLead_Current_StepInput>;
  leads?: InputMaybe<LeadUpdateManyWithoutSequence_StepInput>;
  Organization?: InputMaybe<OrganizationUpdateOneWithoutSequenceStepsInput>;
  origin?: InputMaybe<SequenceStepUpdateOneWithoutSequence_StepsInput>;
  post_step_action?: InputMaybe<EnumpostActionFieldUpdateOperationsInput>;
  sequence?: InputMaybe<SequenceUpdateOneRequiredWithoutSequence_StepsInput>;
  sequence_steps?: InputMaybe<SequenceStepUpdateManyWithoutOriginInput>;
  SnoozeTracking?: InputMaybe<SnoozeTrackingUpdateManyWithoutStepInput>;
  StatItemAggregation?: InputMaybe<StatItemAggregationUpdateManyWithoutStepInput>;
  transferToSequence?: InputMaybe<SequenceUpdateOneWithoutEndStepsTransferInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type SequenceStepUpdateWithoutTransferToSequenceInput = {
  actions?: InputMaybe<StepActionUpdateManyWithoutStepInput>;
  conditions?: InputMaybe<StepConditionUpdateManyWithoutStepInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deleted_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  is_no_branch?: InputMaybe<BoolFieldUpdateOperationsInput>;
  LeadActivity?: InputMaybe<LeadActivityUpdateManyWithoutLead_Current_StepInput>;
  leads?: InputMaybe<LeadUpdateManyWithoutSequence_StepInput>;
  Organization?: InputMaybe<OrganizationUpdateOneWithoutSequenceStepsInput>;
  origin?: InputMaybe<SequenceStepUpdateOneWithoutSequence_StepsInput>;
  post_step_action?: InputMaybe<EnumpostActionFieldUpdateOperationsInput>;
  sequence?: InputMaybe<SequenceUpdateOneRequiredWithoutSequence_StepsInput>;
  sequence_steps?: InputMaybe<SequenceStepUpdateManyWithoutOriginInput>;
  SnoozeTracking?: InputMaybe<SnoozeTrackingUpdateManyWithoutStepInput>;
  StatItemAggregation?: InputMaybe<StatItemAggregationUpdateManyWithoutStepInput>;
  StepActionLog?: InputMaybe<StepActionLogUpdateManyWithoutStepInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type SequenceStepUpdateWithWhereUniqueWithoutOrganizationInput = {
  data: SequenceStepUpdateWithoutOrganizationInput;
  where: SequenceStepWhereUniqueInput;
};

export type SequenceStepUpdateWithWhereUniqueWithoutOriginInput = {
  data: SequenceStepUpdateWithoutOriginInput;
  where: SequenceStepWhereUniqueInput;
};

export type SequenceStepUpdateWithWhereUniqueWithoutSequenceInput = {
  data: SequenceStepUpdateWithoutSequenceInput;
  where: SequenceStepWhereUniqueInput;
};

export type SequenceStepUpdateWithWhereUniqueWithoutTransferToSequenceInput = {
  data: SequenceStepUpdateWithoutTransferToSequenceInput;
  where: SequenceStepWhereUniqueInput;
};

export type SequenceStepUpsertWithoutActionsInput = {
  create: SequenceStepCreateWithoutActionsInput;
  update: SequenceStepUpdateWithoutActionsInput;
};

export type SequenceStepUpsertWithoutConditionsInput = {
  create: SequenceStepCreateWithoutConditionsInput;
  update: SequenceStepUpdateWithoutConditionsInput;
};

export type SequenceStepUpsertWithoutLeadActivityInput = {
  create: SequenceStepCreateWithoutLeadActivityInput;
  update: SequenceStepUpdateWithoutLeadActivityInput;
};

export type SequenceStepUpsertWithoutLeadsInput = {
  create: SequenceStepCreateWithoutLeadsInput;
  update: SequenceStepUpdateWithoutLeadsInput;
};

export type SequenceStepUpsertWithoutSequence_StepsInput = {
  create: SequenceStepCreateWithoutSequence_StepsInput;
  update: SequenceStepUpdateWithoutSequence_StepsInput;
};

export type SequenceStepUpsertWithoutSnoozeTrackingInput = {
  create: SequenceStepCreateWithoutSnoozeTrackingInput;
  update: SequenceStepUpdateWithoutSnoozeTrackingInput;
};

export type SequenceStepUpsertWithoutStatItemAggregationInput = {
  create: SequenceStepCreateWithoutStatItemAggregationInput;
  update: SequenceStepUpdateWithoutStatItemAggregationInput;
};

export type SequenceStepUpsertWithoutStepActionLogInput = {
  create: SequenceStepCreateWithoutStepActionLogInput;
  update: SequenceStepUpdateWithoutStepActionLogInput;
};

export type SequenceStepUpsertWithWhereUniqueWithoutOrganizationInput = {
  create: SequenceStepCreateWithoutOrganizationInput;
  update: SequenceStepUpdateWithoutOrganizationInput;
  where: SequenceStepWhereUniqueInput;
};

export type SequenceStepUpsertWithWhereUniqueWithoutOriginInput = {
  create: SequenceStepCreateWithoutOriginInput;
  update: SequenceStepUpdateWithoutOriginInput;
  where: SequenceStepWhereUniqueInput;
};

export type SequenceStepUpsertWithWhereUniqueWithoutSequenceInput = {
  create: SequenceStepCreateWithoutSequenceInput;
  update: SequenceStepUpdateWithoutSequenceInput;
  where: SequenceStepWhereUniqueInput;
};

export type SequenceStepUpsertWithWhereUniqueWithoutTransferToSequenceInput = {
  create: SequenceStepCreateWithoutTransferToSequenceInput;
  update: SequenceStepUpdateWithoutTransferToSequenceInput;
  where: SequenceStepWhereUniqueInput;
};

export type SequenceStepWhereInput = {
  actions?: InputMaybe<StepActionListRelationFilter>;
  AND?: InputMaybe<Array<SequenceStepWhereInput>>;
  conditions?: InputMaybe<StepConditionListRelationFilter>;
  created_at?: InputMaybe<DateTimeFilter>;
  deleted_at?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<StringFilter>;
  is_no_branch?: InputMaybe<BoolFilter>;
  LeadActivity?: InputMaybe<LeadActivityListRelationFilter>;
  leads?: InputMaybe<LeadListRelationFilter>;
  NOT?: InputMaybe<Array<SequenceStepWhereInput>>;
  OR?: InputMaybe<Array<SequenceStepWhereInput>>;
  Organization?: InputMaybe<OrganizationWhereInput>;
  organization_id?: InputMaybe<StringNullableFilter>;
  origin?: InputMaybe<SequenceStepWhereInput>;
  origin_id?: InputMaybe<StringNullableFilter>;
  post_step_action?: InputMaybe<EnumpostActionFilter>;
  sequence?: InputMaybe<SequenceWhereInput>;
  sequence_id?: InputMaybe<StringFilter>;
  sequence_steps?: InputMaybe<SequenceStepListRelationFilter>;
  SnoozeTracking?: InputMaybe<SnoozeTrackingListRelationFilter>;
  StatItemAggregation?: InputMaybe<StatItemAggregationListRelationFilter>;
  StepActionLog?: InputMaybe<StepActionLogListRelationFilter>;
  transfer_to_sequence_id?: InputMaybe<StringNullableFilter>;
  transferToSequence?: InputMaybe<SequenceWhereInput>;
  updated_at?: InputMaybe<DateTimeFilter>;
};

export type SequenceStepWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']['input']>;
};

export enum SequenceTask {
  AutomatedEmail = 'automatedEmail',
  AutomatedSms = 'automatedSms',
  CustomTask = 'customTask',
  ManualCall = 'manualCall',
  ManualEmail = 'manualEmail',
  ManualSms = 'manualSms',
  RequestManagerFeedback = 'requestManagerFeedback'
}

/** Input type for SequenceTask */
export type SequenceTaskInput = {
  custom_task_note?: InputMaybe<Scalars['String']['input']>;
  delay_days?: InputMaybe<Scalars['Int']['input']>;
  delay_hours?: InputMaybe<Scalars['Int']['input']>;
  delay_minutes?: InputMaybe<Scalars['Int']['input']>;
  /** pick 1 replacement, neutral, exit. if nothing passed, then neutral is default */
  scheduled_event_behavior?: InputMaybe<SequenceStepScheduledEventBehavior>;
  task: SequenceTask;
  template_id?: InputMaybe<Scalars['String']['input']>;
};

export type SequenceUpdateManyMutationInput = {
  active?: InputMaybe<BoolFieldUpdateOperationsInput>;
  completed_count?: InputMaybe<IntFieldUpdateOperationsInput>;
  conversion_count?: InputMaybe<IntFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deleted_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  email_bounced_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  email_clicked_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  email_delivered_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  email_opened_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  email_replied_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  email_unsubscribed_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  exit_count?: InputMaybe<IntFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  is_paused?: InputMaybe<BoolFieldUpdateOperationsInput>;
  manager_feedback_count?: InputMaybe<IntFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  not_interested_count?: InputMaybe<IntFieldUpdateOperationsInput>;
  priority?: InputMaybe<IntFieldUpdateOperationsInput>;
  sms_bounced_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  sms_delivered_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  sms_unsubscribed_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  steps_count?: InputMaybe<IntFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type SequenceUpdateManyWithoutCreated_ByInput = {
  connect?: InputMaybe<Array<SequenceWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SequenceCreateOrConnectWithoutCreated_ByInput>>;
  create?: InputMaybe<Array<SequenceCreateWithoutCreated_ByInput>>;
  createMany?: InputMaybe<SequenceCreateManyCreated_ByInputEnvelope>;
  delete?: InputMaybe<Array<SequenceWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<SequenceScalarWhereInput>>;
  disconnect?: InputMaybe<Array<SequenceWhereUniqueInput>>;
  set?: InputMaybe<Array<SequenceWhereUniqueInput>>;
  update?: InputMaybe<Array<SequenceUpdateWithWhereUniqueWithoutCreated_ByInput>>;
  updateMany?: InputMaybe<Array<SequenceUpdateManyWithWhereWithoutCreated_ByInput>>;
  upsert?: InputMaybe<Array<SequenceUpsertWithWhereUniqueWithoutCreated_ByInput>>;
};

export type SequenceUpdateManyWithoutLast_Updated_ByInput = {
  connect?: InputMaybe<Array<SequenceWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SequenceCreateOrConnectWithoutLast_Updated_ByInput>>;
  create?: InputMaybe<Array<SequenceCreateWithoutLast_Updated_ByInput>>;
  createMany?: InputMaybe<SequenceCreateManyLast_Updated_ByInputEnvelope>;
  delete?: InputMaybe<Array<SequenceWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<SequenceScalarWhereInput>>;
  disconnect?: InputMaybe<Array<SequenceWhereUniqueInput>>;
  set?: InputMaybe<Array<SequenceWhereUniqueInput>>;
  update?: InputMaybe<Array<SequenceUpdateWithWhereUniqueWithoutLast_Updated_ByInput>>;
  updateMany?: InputMaybe<Array<SequenceUpdateManyWithWhereWithoutLast_Updated_ByInput>>;
  upsert?: InputMaybe<Array<SequenceUpsertWithWhereUniqueWithoutLast_Updated_ByInput>>;
};

export type SequenceUpdateManyWithoutOrganzationInput = {
  connect?: InputMaybe<Array<SequenceWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SequenceCreateOrConnectWithoutOrganzationInput>>;
  create?: InputMaybe<Array<SequenceCreateWithoutOrganzationInput>>;
  createMany?: InputMaybe<SequenceCreateManyOrganzationInputEnvelope>;
  delete?: InputMaybe<Array<SequenceWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<SequenceScalarWhereInput>>;
  disconnect?: InputMaybe<Array<SequenceWhereUniqueInput>>;
  set?: InputMaybe<Array<SequenceWhereUniqueInput>>;
  update?: InputMaybe<Array<SequenceUpdateWithWhereUniqueWithoutOrganzationInput>>;
  updateMany?: InputMaybe<Array<SequenceUpdateManyWithWhereWithoutOrganzationInput>>;
  upsert?: InputMaybe<Array<SequenceUpsertWithWhereUniqueWithoutOrganzationInput>>;
};

export type SequenceUpdateManyWithWhereWithoutCreated_ByInput = {
  data: SequenceUpdateManyMutationInput;
  where: SequenceScalarWhereInput;
};

export type SequenceUpdateManyWithWhereWithoutLast_Updated_ByInput = {
  data: SequenceUpdateManyMutationInput;
  where: SequenceScalarWhereInput;
};

export type SequenceUpdateManyWithWhereWithoutOrganzationInput = {
  data: SequenceUpdateManyMutationInput;
  where: SequenceScalarWhereInput;
};

export type SequenceUpdateOneRequiredWithoutSequence_StepsInput = {
  connect?: InputMaybe<SequenceWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SequenceCreateOrConnectWithoutSequence_StepsInput>;
  create?: InputMaybe<SequenceCreateWithoutSequence_StepsInput>;
  update?: InputMaybe<SequenceUpdateWithoutSequence_StepsInput>;
  upsert?: InputMaybe<SequenceUpsertWithoutSequence_StepsInput>;
};

export type SequenceUpdateOneRequiredWithoutSequenceEntryExitLeadInput = {
  connect?: InputMaybe<SequenceWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SequenceCreateOrConnectWithoutSequenceEntryExitLeadInput>;
  create?: InputMaybe<SequenceCreateWithoutSequenceEntryExitLeadInput>;
  update?: InputMaybe<SequenceUpdateWithoutSequenceEntryExitLeadInput>;
  upsert?: InputMaybe<SequenceUpsertWithoutSequenceEntryExitLeadInput>;
};

export type SequenceUpdateOneRequiredWithoutSnoozeTrackingInput = {
  connect?: InputMaybe<SequenceWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SequenceCreateOrConnectWithoutSnoozeTrackingInput>;
  create?: InputMaybe<SequenceCreateWithoutSnoozeTrackingInput>;
  update?: InputMaybe<SequenceUpdateWithoutSnoozeTrackingInput>;
  upsert?: InputMaybe<SequenceUpsertWithoutSnoozeTrackingInput>;
};

export type SequenceUpdateOneRequiredWithoutStepActionLogInput = {
  connect?: InputMaybe<SequenceWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SequenceCreateOrConnectWithoutStepActionLogInput>;
  create?: InputMaybe<SequenceCreateWithoutStepActionLogInput>;
  update?: InputMaybe<SequenceUpdateWithoutStepActionLogInput>;
  upsert?: InputMaybe<SequenceUpsertWithoutStepActionLogInput>;
};

export type SequenceUpdateOneWithoutEmailItemInput = {
  connect?: InputMaybe<SequenceWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SequenceCreateOrConnectWithoutEmailItemInput>;
  create?: InputMaybe<SequenceCreateWithoutEmailItemInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<SequenceUpdateWithoutEmailItemInput>;
  upsert?: InputMaybe<SequenceUpsertWithoutEmailItemInput>;
};

export type SequenceUpdateOneWithoutEndStepsTransferInput = {
  connect?: InputMaybe<SequenceWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SequenceCreateOrConnectWithoutEndStepsTransferInput>;
  create?: InputMaybe<SequenceCreateWithoutEndStepsTransferInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<SequenceUpdateWithoutEndStepsTransferInput>;
  upsert?: InputMaybe<SequenceUpsertWithoutEndStepsTransferInput>;
};

export type SequenceUpdateOneWithoutLeadActivitiesInput = {
  connect?: InputMaybe<SequenceWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SequenceCreateOrConnectWithoutLeadActivitiesInput>;
  create?: InputMaybe<SequenceCreateWithoutLeadActivitiesInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<SequenceUpdateWithoutLeadActivitiesInput>;
  upsert?: InputMaybe<SequenceUpsertWithoutLeadActivitiesInput>;
};

export type SequenceUpdateOneWithoutLeadAssignmentHistoryInput = {
  connect?: InputMaybe<SequenceWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SequenceCreateOrConnectWithoutLeadAssignmentHistoryInput>;
  create?: InputMaybe<SequenceCreateWithoutLeadAssignmentHistoryInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<SequenceUpdateWithoutLeadAssignmentHistoryInput>;
  upsert?: InputMaybe<SequenceUpsertWithoutLeadAssignmentHistoryInput>;
};

export type SequenceUpdateOneWithoutLeadsInput = {
  connect?: InputMaybe<SequenceWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SequenceCreateOrConnectWithoutLeadsInput>;
  create?: InputMaybe<SequenceCreateWithoutLeadsInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<SequenceUpdateWithoutLeadsInput>;
  upsert?: InputMaybe<SequenceUpsertWithoutLeadsInput>;
};

export type SequenceUpdateOneWithoutSaleInput = {
  connect?: InputMaybe<SequenceWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SequenceCreateOrConnectWithoutSaleInput>;
  create?: InputMaybe<SequenceCreateWithoutSaleInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<SequenceUpdateWithoutSaleInput>;
  upsert?: InputMaybe<SequenceUpsertWithoutSaleInput>;
};

export type SequenceUpdateOneWithoutSequence_Entry_CriteriaInput = {
  connect?: InputMaybe<SequenceWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SequenceCreateOrConnectWithoutSequence_Entry_CriteriaInput>;
  create?: InputMaybe<SequenceCreateWithoutSequence_Entry_CriteriaInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<SequenceUpdateWithoutSequence_Entry_CriteriaInput>;
  upsert?: InputMaybe<SequenceUpsertWithoutSequence_Entry_CriteriaInput>;
};

export type SequenceUpdateOneWithoutSmsItemInput = {
  connect?: InputMaybe<SequenceWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SequenceCreateOrConnectWithoutSmsItemInput>;
  create?: InputMaybe<SequenceCreateWithoutSmsItemInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<SequenceUpdateWithoutSmsItemInput>;
  upsert?: InputMaybe<SequenceUpsertWithoutSmsItemInput>;
};

export type SequenceUpdateOneWithoutStatItemsInput = {
  connect?: InputMaybe<SequenceWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SequenceCreateOrConnectWithoutStatItemsInput>;
  create?: InputMaybe<SequenceCreateWithoutStatItemsInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<SequenceUpdateWithoutStatItemsInput>;
  upsert?: InputMaybe<SequenceUpsertWithoutStatItemsInput>;
};

export type SequenceUpdateWithoutCreated_ByInput = {
  active?: InputMaybe<BoolFieldUpdateOperationsInput>;
  completed_count?: InputMaybe<IntFieldUpdateOperationsInput>;
  conversion_count?: InputMaybe<IntFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deleted_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  email_bounced_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  email_clicked_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  email_delivered_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  email_opened_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  email_replied_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  email_unsubscribed_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  EmailItem?: InputMaybe<EmailItemUpdateManyWithoutSequenceInput>;
  EndStepsTransfer?: InputMaybe<SequenceStepUpdateManyWithoutTransferToSequenceInput>;
  exit_count?: InputMaybe<IntFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  is_paused?: InputMaybe<BoolFieldUpdateOperationsInput>;
  last_updated_by?: InputMaybe<UserUpdateOneRequiredWithoutSequences_Modified_By_UserInput>;
  leadActivities?: InputMaybe<LeadActivityUpdateManyWithoutLead_Current_SequenceInput>;
  LeadAssignmentHistory?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutSequence_Not_InterestedInput>;
  leads?: InputMaybe<LeadUpdateManyWithoutSequenceInput>;
  manager_feedback_count?: InputMaybe<IntFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  not_interested_count?: InputMaybe<IntFieldUpdateOperationsInput>;
  organzation?: InputMaybe<OrganizationUpdateOneWithoutSequenceInput>;
  priority?: InputMaybe<IntFieldUpdateOperationsInput>;
  Sale?: InputMaybe<SaleUpdateManyWithoutConverted_SequenceInput>;
  sequence_entry_criteria?: InputMaybe<SequenceEntryCriteriaUpdateOneWithoutSequenceInput>;
  sequence_steps?: InputMaybe<SequenceStepUpdateManyWithoutSequenceInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutSequenceInput>;
  sms_bounced_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  sms_delivered_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  sms_unsubscribed_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  SMSItem?: InputMaybe<SmsItemUpdateManyWithoutSequenceInput>;
  SnoozeTracking?: InputMaybe<SnoozeTrackingUpdateManyWithoutSequenceInput>;
  StatItems?: InputMaybe<StatItemAggregationUpdateManyWithoutSequenceInput>;
  StepActionLog?: InputMaybe<StepActionLogUpdateManyWithoutSequenceInput>;
  steps_count?: InputMaybe<IntFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type SequenceUpdateWithoutEmailItemInput = {
  active?: InputMaybe<BoolFieldUpdateOperationsInput>;
  completed_count?: InputMaybe<IntFieldUpdateOperationsInput>;
  conversion_count?: InputMaybe<IntFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  created_by?: InputMaybe<UserUpdateOneRequiredWithoutSequences_CreatedInput>;
  deleted_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  email_bounced_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  email_clicked_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  email_delivered_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  email_opened_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  email_replied_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  email_unsubscribed_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  EndStepsTransfer?: InputMaybe<SequenceStepUpdateManyWithoutTransferToSequenceInput>;
  exit_count?: InputMaybe<IntFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  is_paused?: InputMaybe<BoolFieldUpdateOperationsInput>;
  last_updated_by?: InputMaybe<UserUpdateOneRequiredWithoutSequences_Modified_By_UserInput>;
  leadActivities?: InputMaybe<LeadActivityUpdateManyWithoutLead_Current_SequenceInput>;
  LeadAssignmentHistory?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutSequence_Not_InterestedInput>;
  leads?: InputMaybe<LeadUpdateManyWithoutSequenceInput>;
  manager_feedback_count?: InputMaybe<IntFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  not_interested_count?: InputMaybe<IntFieldUpdateOperationsInput>;
  organzation?: InputMaybe<OrganizationUpdateOneWithoutSequenceInput>;
  priority?: InputMaybe<IntFieldUpdateOperationsInput>;
  Sale?: InputMaybe<SaleUpdateManyWithoutConverted_SequenceInput>;
  sequence_entry_criteria?: InputMaybe<SequenceEntryCriteriaUpdateOneWithoutSequenceInput>;
  sequence_steps?: InputMaybe<SequenceStepUpdateManyWithoutSequenceInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutSequenceInput>;
  sms_bounced_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  sms_delivered_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  sms_unsubscribed_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  SMSItem?: InputMaybe<SmsItemUpdateManyWithoutSequenceInput>;
  SnoozeTracking?: InputMaybe<SnoozeTrackingUpdateManyWithoutSequenceInput>;
  StatItems?: InputMaybe<StatItemAggregationUpdateManyWithoutSequenceInput>;
  StepActionLog?: InputMaybe<StepActionLogUpdateManyWithoutSequenceInput>;
  steps_count?: InputMaybe<IntFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type SequenceUpdateWithoutEndStepsTransferInput = {
  active?: InputMaybe<BoolFieldUpdateOperationsInput>;
  completed_count?: InputMaybe<IntFieldUpdateOperationsInput>;
  conversion_count?: InputMaybe<IntFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  created_by?: InputMaybe<UserUpdateOneRequiredWithoutSequences_CreatedInput>;
  deleted_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  email_bounced_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  email_clicked_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  email_delivered_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  email_opened_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  email_replied_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  email_unsubscribed_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  EmailItem?: InputMaybe<EmailItemUpdateManyWithoutSequenceInput>;
  exit_count?: InputMaybe<IntFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  is_paused?: InputMaybe<BoolFieldUpdateOperationsInput>;
  last_updated_by?: InputMaybe<UserUpdateOneRequiredWithoutSequences_Modified_By_UserInput>;
  leadActivities?: InputMaybe<LeadActivityUpdateManyWithoutLead_Current_SequenceInput>;
  LeadAssignmentHistory?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutSequence_Not_InterestedInput>;
  leads?: InputMaybe<LeadUpdateManyWithoutSequenceInput>;
  manager_feedback_count?: InputMaybe<IntFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  not_interested_count?: InputMaybe<IntFieldUpdateOperationsInput>;
  organzation?: InputMaybe<OrganizationUpdateOneWithoutSequenceInput>;
  priority?: InputMaybe<IntFieldUpdateOperationsInput>;
  Sale?: InputMaybe<SaleUpdateManyWithoutConverted_SequenceInput>;
  sequence_entry_criteria?: InputMaybe<SequenceEntryCriteriaUpdateOneWithoutSequenceInput>;
  sequence_steps?: InputMaybe<SequenceStepUpdateManyWithoutSequenceInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutSequenceInput>;
  sms_bounced_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  sms_delivered_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  sms_unsubscribed_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  SMSItem?: InputMaybe<SmsItemUpdateManyWithoutSequenceInput>;
  SnoozeTracking?: InputMaybe<SnoozeTrackingUpdateManyWithoutSequenceInput>;
  StatItems?: InputMaybe<StatItemAggregationUpdateManyWithoutSequenceInput>;
  StepActionLog?: InputMaybe<StepActionLogUpdateManyWithoutSequenceInput>;
  steps_count?: InputMaybe<IntFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type SequenceUpdateWithoutLast_Updated_ByInput = {
  active?: InputMaybe<BoolFieldUpdateOperationsInput>;
  completed_count?: InputMaybe<IntFieldUpdateOperationsInput>;
  conversion_count?: InputMaybe<IntFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  created_by?: InputMaybe<UserUpdateOneRequiredWithoutSequences_CreatedInput>;
  deleted_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  email_bounced_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  email_clicked_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  email_delivered_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  email_opened_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  email_replied_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  email_unsubscribed_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  EmailItem?: InputMaybe<EmailItemUpdateManyWithoutSequenceInput>;
  EndStepsTransfer?: InputMaybe<SequenceStepUpdateManyWithoutTransferToSequenceInput>;
  exit_count?: InputMaybe<IntFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  is_paused?: InputMaybe<BoolFieldUpdateOperationsInput>;
  leadActivities?: InputMaybe<LeadActivityUpdateManyWithoutLead_Current_SequenceInput>;
  LeadAssignmentHistory?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutSequence_Not_InterestedInput>;
  leads?: InputMaybe<LeadUpdateManyWithoutSequenceInput>;
  manager_feedback_count?: InputMaybe<IntFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  not_interested_count?: InputMaybe<IntFieldUpdateOperationsInput>;
  organzation?: InputMaybe<OrganizationUpdateOneWithoutSequenceInput>;
  priority?: InputMaybe<IntFieldUpdateOperationsInput>;
  Sale?: InputMaybe<SaleUpdateManyWithoutConverted_SequenceInput>;
  sequence_entry_criteria?: InputMaybe<SequenceEntryCriteriaUpdateOneWithoutSequenceInput>;
  sequence_steps?: InputMaybe<SequenceStepUpdateManyWithoutSequenceInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutSequenceInput>;
  sms_bounced_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  sms_delivered_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  sms_unsubscribed_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  SMSItem?: InputMaybe<SmsItemUpdateManyWithoutSequenceInput>;
  SnoozeTracking?: InputMaybe<SnoozeTrackingUpdateManyWithoutSequenceInput>;
  StatItems?: InputMaybe<StatItemAggregationUpdateManyWithoutSequenceInput>;
  StepActionLog?: InputMaybe<StepActionLogUpdateManyWithoutSequenceInput>;
  steps_count?: InputMaybe<IntFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type SequenceUpdateWithoutLeadActivitiesInput = {
  active?: InputMaybe<BoolFieldUpdateOperationsInput>;
  completed_count?: InputMaybe<IntFieldUpdateOperationsInput>;
  conversion_count?: InputMaybe<IntFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  created_by?: InputMaybe<UserUpdateOneRequiredWithoutSequences_CreatedInput>;
  deleted_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  email_bounced_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  email_clicked_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  email_delivered_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  email_opened_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  email_replied_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  email_unsubscribed_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  EmailItem?: InputMaybe<EmailItemUpdateManyWithoutSequenceInput>;
  EndStepsTransfer?: InputMaybe<SequenceStepUpdateManyWithoutTransferToSequenceInput>;
  exit_count?: InputMaybe<IntFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  is_paused?: InputMaybe<BoolFieldUpdateOperationsInput>;
  last_updated_by?: InputMaybe<UserUpdateOneRequiredWithoutSequences_Modified_By_UserInput>;
  LeadAssignmentHistory?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutSequence_Not_InterestedInput>;
  leads?: InputMaybe<LeadUpdateManyWithoutSequenceInput>;
  manager_feedback_count?: InputMaybe<IntFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  not_interested_count?: InputMaybe<IntFieldUpdateOperationsInput>;
  organzation?: InputMaybe<OrganizationUpdateOneWithoutSequenceInput>;
  priority?: InputMaybe<IntFieldUpdateOperationsInput>;
  Sale?: InputMaybe<SaleUpdateManyWithoutConverted_SequenceInput>;
  sequence_entry_criteria?: InputMaybe<SequenceEntryCriteriaUpdateOneWithoutSequenceInput>;
  sequence_steps?: InputMaybe<SequenceStepUpdateManyWithoutSequenceInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutSequenceInput>;
  sms_bounced_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  sms_delivered_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  sms_unsubscribed_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  SMSItem?: InputMaybe<SmsItemUpdateManyWithoutSequenceInput>;
  SnoozeTracking?: InputMaybe<SnoozeTrackingUpdateManyWithoutSequenceInput>;
  StatItems?: InputMaybe<StatItemAggregationUpdateManyWithoutSequenceInput>;
  StepActionLog?: InputMaybe<StepActionLogUpdateManyWithoutSequenceInput>;
  steps_count?: InputMaybe<IntFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type SequenceUpdateWithoutLeadAssignmentHistoryInput = {
  active?: InputMaybe<BoolFieldUpdateOperationsInput>;
  completed_count?: InputMaybe<IntFieldUpdateOperationsInput>;
  conversion_count?: InputMaybe<IntFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  created_by?: InputMaybe<UserUpdateOneRequiredWithoutSequences_CreatedInput>;
  deleted_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  email_bounced_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  email_clicked_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  email_delivered_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  email_opened_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  email_replied_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  email_unsubscribed_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  EmailItem?: InputMaybe<EmailItemUpdateManyWithoutSequenceInput>;
  EndStepsTransfer?: InputMaybe<SequenceStepUpdateManyWithoutTransferToSequenceInput>;
  exit_count?: InputMaybe<IntFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  is_paused?: InputMaybe<BoolFieldUpdateOperationsInput>;
  last_updated_by?: InputMaybe<UserUpdateOneRequiredWithoutSequences_Modified_By_UserInput>;
  leadActivities?: InputMaybe<LeadActivityUpdateManyWithoutLead_Current_SequenceInput>;
  leads?: InputMaybe<LeadUpdateManyWithoutSequenceInput>;
  manager_feedback_count?: InputMaybe<IntFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  not_interested_count?: InputMaybe<IntFieldUpdateOperationsInput>;
  organzation?: InputMaybe<OrganizationUpdateOneWithoutSequenceInput>;
  priority?: InputMaybe<IntFieldUpdateOperationsInput>;
  Sale?: InputMaybe<SaleUpdateManyWithoutConverted_SequenceInput>;
  sequence_entry_criteria?: InputMaybe<SequenceEntryCriteriaUpdateOneWithoutSequenceInput>;
  sequence_steps?: InputMaybe<SequenceStepUpdateManyWithoutSequenceInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutSequenceInput>;
  sms_bounced_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  sms_delivered_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  sms_unsubscribed_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  SMSItem?: InputMaybe<SmsItemUpdateManyWithoutSequenceInput>;
  SnoozeTracking?: InputMaybe<SnoozeTrackingUpdateManyWithoutSequenceInput>;
  StatItems?: InputMaybe<StatItemAggregationUpdateManyWithoutSequenceInput>;
  StepActionLog?: InputMaybe<StepActionLogUpdateManyWithoutSequenceInput>;
  steps_count?: InputMaybe<IntFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type SequenceUpdateWithoutLeadsInput = {
  active?: InputMaybe<BoolFieldUpdateOperationsInput>;
  completed_count?: InputMaybe<IntFieldUpdateOperationsInput>;
  conversion_count?: InputMaybe<IntFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  created_by?: InputMaybe<UserUpdateOneRequiredWithoutSequences_CreatedInput>;
  deleted_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  email_bounced_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  email_clicked_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  email_delivered_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  email_opened_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  email_replied_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  email_unsubscribed_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  EmailItem?: InputMaybe<EmailItemUpdateManyWithoutSequenceInput>;
  EndStepsTransfer?: InputMaybe<SequenceStepUpdateManyWithoutTransferToSequenceInput>;
  exit_count?: InputMaybe<IntFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  is_paused?: InputMaybe<BoolFieldUpdateOperationsInput>;
  last_updated_by?: InputMaybe<UserUpdateOneRequiredWithoutSequences_Modified_By_UserInput>;
  leadActivities?: InputMaybe<LeadActivityUpdateManyWithoutLead_Current_SequenceInput>;
  LeadAssignmentHistory?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutSequence_Not_InterestedInput>;
  manager_feedback_count?: InputMaybe<IntFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  not_interested_count?: InputMaybe<IntFieldUpdateOperationsInput>;
  organzation?: InputMaybe<OrganizationUpdateOneWithoutSequenceInput>;
  priority?: InputMaybe<IntFieldUpdateOperationsInput>;
  Sale?: InputMaybe<SaleUpdateManyWithoutConverted_SequenceInput>;
  sequence_entry_criteria?: InputMaybe<SequenceEntryCriteriaUpdateOneWithoutSequenceInput>;
  sequence_steps?: InputMaybe<SequenceStepUpdateManyWithoutSequenceInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutSequenceInput>;
  sms_bounced_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  sms_delivered_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  sms_unsubscribed_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  SMSItem?: InputMaybe<SmsItemUpdateManyWithoutSequenceInput>;
  SnoozeTracking?: InputMaybe<SnoozeTrackingUpdateManyWithoutSequenceInput>;
  StatItems?: InputMaybe<StatItemAggregationUpdateManyWithoutSequenceInput>;
  StepActionLog?: InputMaybe<StepActionLogUpdateManyWithoutSequenceInput>;
  steps_count?: InputMaybe<IntFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type SequenceUpdateWithoutOrganzationInput = {
  active?: InputMaybe<BoolFieldUpdateOperationsInput>;
  completed_count?: InputMaybe<IntFieldUpdateOperationsInput>;
  conversion_count?: InputMaybe<IntFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  created_by?: InputMaybe<UserUpdateOneRequiredWithoutSequences_CreatedInput>;
  deleted_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  email_bounced_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  email_clicked_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  email_delivered_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  email_opened_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  email_replied_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  email_unsubscribed_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  EmailItem?: InputMaybe<EmailItemUpdateManyWithoutSequenceInput>;
  EndStepsTransfer?: InputMaybe<SequenceStepUpdateManyWithoutTransferToSequenceInput>;
  exit_count?: InputMaybe<IntFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  is_paused?: InputMaybe<BoolFieldUpdateOperationsInput>;
  last_updated_by?: InputMaybe<UserUpdateOneRequiredWithoutSequences_Modified_By_UserInput>;
  leadActivities?: InputMaybe<LeadActivityUpdateManyWithoutLead_Current_SequenceInput>;
  LeadAssignmentHistory?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutSequence_Not_InterestedInput>;
  leads?: InputMaybe<LeadUpdateManyWithoutSequenceInput>;
  manager_feedback_count?: InputMaybe<IntFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  not_interested_count?: InputMaybe<IntFieldUpdateOperationsInput>;
  priority?: InputMaybe<IntFieldUpdateOperationsInput>;
  Sale?: InputMaybe<SaleUpdateManyWithoutConverted_SequenceInput>;
  sequence_entry_criteria?: InputMaybe<SequenceEntryCriteriaUpdateOneWithoutSequenceInput>;
  sequence_steps?: InputMaybe<SequenceStepUpdateManyWithoutSequenceInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutSequenceInput>;
  sms_bounced_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  sms_delivered_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  sms_unsubscribed_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  SMSItem?: InputMaybe<SmsItemUpdateManyWithoutSequenceInput>;
  SnoozeTracking?: InputMaybe<SnoozeTrackingUpdateManyWithoutSequenceInput>;
  StatItems?: InputMaybe<StatItemAggregationUpdateManyWithoutSequenceInput>;
  StepActionLog?: InputMaybe<StepActionLogUpdateManyWithoutSequenceInput>;
  steps_count?: InputMaybe<IntFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type SequenceUpdateWithoutSaleInput = {
  active?: InputMaybe<BoolFieldUpdateOperationsInput>;
  completed_count?: InputMaybe<IntFieldUpdateOperationsInput>;
  conversion_count?: InputMaybe<IntFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  created_by?: InputMaybe<UserUpdateOneRequiredWithoutSequences_CreatedInput>;
  deleted_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  email_bounced_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  email_clicked_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  email_delivered_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  email_opened_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  email_replied_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  email_unsubscribed_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  EmailItem?: InputMaybe<EmailItemUpdateManyWithoutSequenceInput>;
  EndStepsTransfer?: InputMaybe<SequenceStepUpdateManyWithoutTransferToSequenceInput>;
  exit_count?: InputMaybe<IntFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  is_paused?: InputMaybe<BoolFieldUpdateOperationsInput>;
  last_updated_by?: InputMaybe<UserUpdateOneRequiredWithoutSequences_Modified_By_UserInput>;
  leadActivities?: InputMaybe<LeadActivityUpdateManyWithoutLead_Current_SequenceInput>;
  LeadAssignmentHistory?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutSequence_Not_InterestedInput>;
  leads?: InputMaybe<LeadUpdateManyWithoutSequenceInput>;
  manager_feedback_count?: InputMaybe<IntFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  not_interested_count?: InputMaybe<IntFieldUpdateOperationsInput>;
  organzation?: InputMaybe<OrganizationUpdateOneWithoutSequenceInput>;
  priority?: InputMaybe<IntFieldUpdateOperationsInput>;
  sequence_entry_criteria?: InputMaybe<SequenceEntryCriteriaUpdateOneWithoutSequenceInput>;
  sequence_steps?: InputMaybe<SequenceStepUpdateManyWithoutSequenceInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutSequenceInput>;
  sms_bounced_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  sms_delivered_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  sms_unsubscribed_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  SMSItem?: InputMaybe<SmsItemUpdateManyWithoutSequenceInput>;
  SnoozeTracking?: InputMaybe<SnoozeTrackingUpdateManyWithoutSequenceInput>;
  StatItems?: InputMaybe<StatItemAggregationUpdateManyWithoutSequenceInput>;
  StepActionLog?: InputMaybe<StepActionLogUpdateManyWithoutSequenceInput>;
  steps_count?: InputMaybe<IntFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type SequenceUpdateWithoutSequence_Entry_CriteriaInput = {
  active?: InputMaybe<BoolFieldUpdateOperationsInput>;
  completed_count?: InputMaybe<IntFieldUpdateOperationsInput>;
  conversion_count?: InputMaybe<IntFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  created_by?: InputMaybe<UserUpdateOneRequiredWithoutSequences_CreatedInput>;
  deleted_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  email_bounced_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  email_clicked_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  email_delivered_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  email_opened_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  email_replied_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  email_unsubscribed_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  EmailItem?: InputMaybe<EmailItemUpdateManyWithoutSequenceInput>;
  EndStepsTransfer?: InputMaybe<SequenceStepUpdateManyWithoutTransferToSequenceInput>;
  exit_count?: InputMaybe<IntFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  is_paused?: InputMaybe<BoolFieldUpdateOperationsInput>;
  last_updated_by?: InputMaybe<UserUpdateOneRequiredWithoutSequences_Modified_By_UserInput>;
  leadActivities?: InputMaybe<LeadActivityUpdateManyWithoutLead_Current_SequenceInput>;
  LeadAssignmentHistory?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutSequence_Not_InterestedInput>;
  leads?: InputMaybe<LeadUpdateManyWithoutSequenceInput>;
  manager_feedback_count?: InputMaybe<IntFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  not_interested_count?: InputMaybe<IntFieldUpdateOperationsInput>;
  organzation?: InputMaybe<OrganizationUpdateOneWithoutSequenceInput>;
  priority?: InputMaybe<IntFieldUpdateOperationsInput>;
  Sale?: InputMaybe<SaleUpdateManyWithoutConverted_SequenceInput>;
  sequence_steps?: InputMaybe<SequenceStepUpdateManyWithoutSequenceInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutSequenceInput>;
  sms_bounced_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  sms_delivered_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  sms_unsubscribed_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  SMSItem?: InputMaybe<SmsItemUpdateManyWithoutSequenceInput>;
  SnoozeTracking?: InputMaybe<SnoozeTrackingUpdateManyWithoutSequenceInput>;
  StatItems?: InputMaybe<StatItemAggregationUpdateManyWithoutSequenceInput>;
  StepActionLog?: InputMaybe<StepActionLogUpdateManyWithoutSequenceInput>;
  steps_count?: InputMaybe<IntFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type SequenceUpdateWithoutSequence_StepsInput = {
  active?: InputMaybe<BoolFieldUpdateOperationsInput>;
  completed_count?: InputMaybe<IntFieldUpdateOperationsInput>;
  conversion_count?: InputMaybe<IntFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  created_by?: InputMaybe<UserUpdateOneRequiredWithoutSequences_CreatedInput>;
  deleted_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  email_bounced_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  email_clicked_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  email_delivered_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  email_opened_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  email_replied_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  email_unsubscribed_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  EmailItem?: InputMaybe<EmailItemUpdateManyWithoutSequenceInput>;
  EndStepsTransfer?: InputMaybe<SequenceStepUpdateManyWithoutTransferToSequenceInput>;
  exit_count?: InputMaybe<IntFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  is_paused?: InputMaybe<BoolFieldUpdateOperationsInput>;
  last_updated_by?: InputMaybe<UserUpdateOneRequiredWithoutSequences_Modified_By_UserInput>;
  leadActivities?: InputMaybe<LeadActivityUpdateManyWithoutLead_Current_SequenceInput>;
  LeadAssignmentHistory?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutSequence_Not_InterestedInput>;
  leads?: InputMaybe<LeadUpdateManyWithoutSequenceInput>;
  manager_feedback_count?: InputMaybe<IntFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  not_interested_count?: InputMaybe<IntFieldUpdateOperationsInput>;
  organzation?: InputMaybe<OrganizationUpdateOneWithoutSequenceInput>;
  priority?: InputMaybe<IntFieldUpdateOperationsInput>;
  Sale?: InputMaybe<SaleUpdateManyWithoutConverted_SequenceInput>;
  sequence_entry_criteria?: InputMaybe<SequenceEntryCriteriaUpdateOneWithoutSequenceInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutSequenceInput>;
  sms_bounced_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  sms_delivered_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  sms_unsubscribed_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  SMSItem?: InputMaybe<SmsItemUpdateManyWithoutSequenceInput>;
  SnoozeTracking?: InputMaybe<SnoozeTrackingUpdateManyWithoutSequenceInput>;
  StatItems?: InputMaybe<StatItemAggregationUpdateManyWithoutSequenceInput>;
  StepActionLog?: InputMaybe<StepActionLogUpdateManyWithoutSequenceInput>;
  steps_count?: InputMaybe<IntFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type SequenceUpdateWithoutSequenceEntryExitLeadInput = {
  active?: InputMaybe<BoolFieldUpdateOperationsInput>;
  completed_count?: InputMaybe<IntFieldUpdateOperationsInput>;
  conversion_count?: InputMaybe<IntFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  created_by?: InputMaybe<UserUpdateOneRequiredWithoutSequences_CreatedInput>;
  deleted_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  email_bounced_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  email_clicked_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  email_delivered_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  email_opened_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  email_replied_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  email_unsubscribed_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  EmailItem?: InputMaybe<EmailItemUpdateManyWithoutSequenceInput>;
  EndStepsTransfer?: InputMaybe<SequenceStepUpdateManyWithoutTransferToSequenceInput>;
  exit_count?: InputMaybe<IntFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  is_paused?: InputMaybe<BoolFieldUpdateOperationsInput>;
  last_updated_by?: InputMaybe<UserUpdateOneRequiredWithoutSequences_Modified_By_UserInput>;
  leadActivities?: InputMaybe<LeadActivityUpdateManyWithoutLead_Current_SequenceInput>;
  LeadAssignmentHistory?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutSequence_Not_InterestedInput>;
  leads?: InputMaybe<LeadUpdateManyWithoutSequenceInput>;
  manager_feedback_count?: InputMaybe<IntFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  not_interested_count?: InputMaybe<IntFieldUpdateOperationsInput>;
  organzation?: InputMaybe<OrganizationUpdateOneWithoutSequenceInput>;
  priority?: InputMaybe<IntFieldUpdateOperationsInput>;
  Sale?: InputMaybe<SaleUpdateManyWithoutConverted_SequenceInput>;
  sequence_entry_criteria?: InputMaybe<SequenceEntryCriteriaUpdateOneWithoutSequenceInput>;
  sequence_steps?: InputMaybe<SequenceStepUpdateManyWithoutSequenceInput>;
  sms_bounced_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  sms_delivered_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  sms_unsubscribed_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  SMSItem?: InputMaybe<SmsItemUpdateManyWithoutSequenceInput>;
  SnoozeTracking?: InputMaybe<SnoozeTrackingUpdateManyWithoutSequenceInput>;
  StatItems?: InputMaybe<StatItemAggregationUpdateManyWithoutSequenceInput>;
  StepActionLog?: InputMaybe<StepActionLogUpdateManyWithoutSequenceInput>;
  steps_count?: InputMaybe<IntFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type SequenceUpdateWithoutSmsItemInput = {
  active?: InputMaybe<BoolFieldUpdateOperationsInput>;
  completed_count?: InputMaybe<IntFieldUpdateOperationsInput>;
  conversion_count?: InputMaybe<IntFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  created_by?: InputMaybe<UserUpdateOneRequiredWithoutSequences_CreatedInput>;
  deleted_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  email_bounced_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  email_clicked_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  email_delivered_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  email_opened_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  email_replied_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  email_unsubscribed_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  EmailItem?: InputMaybe<EmailItemUpdateManyWithoutSequenceInput>;
  EndStepsTransfer?: InputMaybe<SequenceStepUpdateManyWithoutTransferToSequenceInput>;
  exit_count?: InputMaybe<IntFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  is_paused?: InputMaybe<BoolFieldUpdateOperationsInput>;
  last_updated_by?: InputMaybe<UserUpdateOneRequiredWithoutSequences_Modified_By_UserInput>;
  leadActivities?: InputMaybe<LeadActivityUpdateManyWithoutLead_Current_SequenceInput>;
  LeadAssignmentHistory?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutSequence_Not_InterestedInput>;
  leads?: InputMaybe<LeadUpdateManyWithoutSequenceInput>;
  manager_feedback_count?: InputMaybe<IntFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  not_interested_count?: InputMaybe<IntFieldUpdateOperationsInput>;
  organzation?: InputMaybe<OrganizationUpdateOneWithoutSequenceInput>;
  priority?: InputMaybe<IntFieldUpdateOperationsInput>;
  Sale?: InputMaybe<SaleUpdateManyWithoutConverted_SequenceInput>;
  sequence_entry_criteria?: InputMaybe<SequenceEntryCriteriaUpdateOneWithoutSequenceInput>;
  sequence_steps?: InputMaybe<SequenceStepUpdateManyWithoutSequenceInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutSequenceInput>;
  sms_bounced_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  sms_delivered_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  sms_unsubscribed_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  SnoozeTracking?: InputMaybe<SnoozeTrackingUpdateManyWithoutSequenceInput>;
  StatItems?: InputMaybe<StatItemAggregationUpdateManyWithoutSequenceInput>;
  StepActionLog?: InputMaybe<StepActionLogUpdateManyWithoutSequenceInput>;
  steps_count?: InputMaybe<IntFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type SequenceUpdateWithoutSnoozeTrackingInput = {
  active?: InputMaybe<BoolFieldUpdateOperationsInput>;
  completed_count?: InputMaybe<IntFieldUpdateOperationsInput>;
  conversion_count?: InputMaybe<IntFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  created_by?: InputMaybe<UserUpdateOneRequiredWithoutSequences_CreatedInput>;
  deleted_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  email_bounced_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  email_clicked_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  email_delivered_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  email_opened_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  email_replied_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  email_unsubscribed_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  EmailItem?: InputMaybe<EmailItemUpdateManyWithoutSequenceInput>;
  EndStepsTransfer?: InputMaybe<SequenceStepUpdateManyWithoutTransferToSequenceInput>;
  exit_count?: InputMaybe<IntFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  is_paused?: InputMaybe<BoolFieldUpdateOperationsInput>;
  last_updated_by?: InputMaybe<UserUpdateOneRequiredWithoutSequences_Modified_By_UserInput>;
  leadActivities?: InputMaybe<LeadActivityUpdateManyWithoutLead_Current_SequenceInput>;
  LeadAssignmentHistory?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutSequence_Not_InterestedInput>;
  leads?: InputMaybe<LeadUpdateManyWithoutSequenceInput>;
  manager_feedback_count?: InputMaybe<IntFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  not_interested_count?: InputMaybe<IntFieldUpdateOperationsInput>;
  organzation?: InputMaybe<OrganizationUpdateOneWithoutSequenceInput>;
  priority?: InputMaybe<IntFieldUpdateOperationsInput>;
  Sale?: InputMaybe<SaleUpdateManyWithoutConverted_SequenceInput>;
  sequence_entry_criteria?: InputMaybe<SequenceEntryCriteriaUpdateOneWithoutSequenceInput>;
  sequence_steps?: InputMaybe<SequenceStepUpdateManyWithoutSequenceInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutSequenceInput>;
  sms_bounced_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  sms_delivered_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  sms_unsubscribed_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  SMSItem?: InputMaybe<SmsItemUpdateManyWithoutSequenceInput>;
  StatItems?: InputMaybe<StatItemAggregationUpdateManyWithoutSequenceInput>;
  StepActionLog?: InputMaybe<StepActionLogUpdateManyWithoutSequenceInput>;
  steps_count?: InputMaybe<IntFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type SequenceUpdateWithoutStatItemsInput = {
  active?: InputMaybe<BoolFieldUpdateOperationsInput>;
  completed_count?: InputMaybe<IntFieldUpdateOperationsInput>;
  conversion_count?: InputMaybe<IntFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  created_by?: InputMaybe<UserUpdateOneRequiredWithoutSequences_CreatedInput>;
  deleted_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  email_bounced_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  email_clicked_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  email_delivered_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  email_opened_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  email_replied_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  email_unsubscribed_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  EmailItem?: InputMaybe<EmailItemUpdateManyWithoutSequenceInput>;
  EndStepsTransfer?: InputMaybe<SequenceStepUpdateManyWithoutTransferToSequenceInput>;
  exit_count?: InputMaybe<IntFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  is_paused?: InputMaybe<BoolFieldUpdateOperationsInput>;
  last_updated_by?: InputMaybe<UserUpdateOneRequiredWithoutSequences_Modified_By_UserInput>;
  leadActivities?: InputMaybe<LeadActivityUpdateManyWithoutLead_Current_SequenceInput>;
  LeadAssignmentHistory?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutSequence_Not_InterestedInput>;
  leads?: InputMaybe<LeadUpdateManyWithoutSequenceInput>;
  manager_feedback_count?: InputMaybe<IntFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  not_interested_count?: InputMaybe<IntFieldUpdateOperationsInput>;
  organzation?: InputMaybe<OrganizationUpdateOneWithoutSequenceInput>;
  priority?: InputMaybe<IntFieldUpdateOperationsInput>;
  Sale?: InputMaybe<SaleUpdateManyWithoutConverted_SequenceInput>;
  sequence_entry_criteria?: InputMaybe<SequenceEntryCriteriaUpdateOneWithoutSequenceInput>;
  sequence_steps?: InputMaybe<SequenceStepUpdateManyWithoutSequenceInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutSequenceInput>;
  sms_bounced_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  sms_delivered_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  sms_unsubscribed_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  SMSItem?: InputMaybe<SmsItemUpdateManyWithoutSequenceInput>;
  SnoozeTracking?: InputMaybe<SnoozeTrackingUpdateManyWithoutSequenceInput>;
  StepActionLog?: InputMaybe<StepActionLogUpdateManyWithoutSequenceInput>;
  steps_count?: InputMaybe<IntFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type SequenceUpdateWithoutStepActionLogInput = {
  active?: InputMaybe<BoolFieldUpdateOperationsInput>;
  completed_count?: InputMaybe<IntFieldUpdateOperationsInput>;
  conversion_count?: InputMaybe<IntFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  created_by?: InputMaybe<UserUpdateOneRequiredWithoutSequences_CreatedInput>;
  deleted_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  email_bounced_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  email_clicked_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  email_delivered_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  email_opened_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  email_replied_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  email_unsubscribed_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  EmailItem?: InputMaybe<EmailItemUpdateManyWithoutSequenceInput>;
  EndStepsTransfer?: InputMaybe<SequenceStepUpdateManyWithoutTransferToSequenceInput>;
  exit_count?: InputMaybe<IntFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  is_paused?: InputMaybe<BoolFieldUpdateOperationsInput>;
  last_updated_by?: InputMaybe<UserUpdateOneRequiredWithoutSequences_Modified_By_UserInput>;
  leadActivities?: InputMaybe<LeadActivityUpdateManyWithoutLead_Current_SequenceInput>;
  LeadAssignmentHistory?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutSequence_Not_InterestedInput>;
  leads?: InputMaybe<LeadUpdateManyWithoutSequenceInput>;
  manager_feedback_count?: InputMaybe<IntFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  not_interested_count?: InputMaybe<IntFieldUpdateOperationsInput>;
  organzation?: InputMaybe<OrganizationUpdateOneWithoutSequenceInput>;
  priority?: InputMaybe<IntFieldUpdateOperationsInput>;
  Sale?: InputMaybe<SaleUpdateManyWithoutConverted_SequenceInput>;
  sequence_entry_criteria?: InputMaybe<SequenceEntryCriteriaUpdateOneWithoutSequenceInput>;
  sequence_steps?: InputMaybe<SequenceStepUpdateManyWithoutSequenceInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutSequenceInput>;
  sms_bounced_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  sms_delivered_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  sms_unsubscribed_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  SMSItem?: InputMaybe<SmsItemUpdateManyWithoutSequenceInput>;
  SnoozeTracking?: InputMaybe<SnoozeTrackingUpdateManyWithoutSequenceInput>;
  StatItems?: InputMaybe<StatItemAggregationUpdateManyWithoutSequenceInput>;
  steps_count?: InputMaybe<IntFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type SequenceUpdateWithWhereUniqueWithoutCreated_ByInput = {
  data: SequenceUpdateWithoutCreated_ByInput;
  where: SequenceWhereUniqueInput;
};

export type SequenceUpdateWithWhereUniqueWithoutLast_Updated_ByInput = {
  data: SequenceUpdateWithoutLast_Updated_ByInput;
  where: SequenceWhereUniqueInput;
};

export type SequenceUpdateWithWhereUniqueWithoutOrganzationInput = {
  data: SequenceUpdateWithoutOrganzationInput;
  where: SequenceWhereUniqueInput;
};

export type SequenceUpsertWithoutEmailItemInput = {
  create: SequenceCreateWithoutEmailItemInput;
  update: SequenceUpdateWithoutEmailItemInput;
};

export type SequenceUpsertWithoutEndStepsTransferInput = {
  create: SequenceCreateWithoutEndStepsTransferInput;
  update: SequenceUpdateWithoutEndStepsTransferInput;
};

export type SequenceUpsertWithoutLeadActivitiesInput = {
  create: SequenceCreateWithoutLeadActivitiesInput;
  update: SequenceUpdateWithoutLeadActivitiesInput;
};

export type SequenceUpsertWithoutLeadAssignmentHistoryInput = {
  create: SequenceCreateWithoutLeadAssignmentHistoryInput;
  update: SequenceUpdateWithoutLeadAssignmentHistoryInput;
};

export type SequenceUpsertWithoutLeadsInput = {
  create: SequenceCreateWithoutLeadsInput;
  update: SequenceUpdateWithoutLeadsInput;
};

export type SequenceUpsertWithoutSaleInput = {
  create: SequenceCreateWithoutSaleInput;
  update: SequenceUpdateWithoutSaleInput;
};

export type SequenceUpsertWithoutSequence_Entry_CriteriaInput = {
  create: SequenceCreateWithoutSequence_Entry_CriteriaInput;
  update: SequenceUpdateWithoutSequence_Entry_CriteriaInput;
};

export type SequenceUpsertWithoutSequence_StepsInput = {
  create: SequenceCreateWithoutSequence_StepsInput;
  update: SequenceUpdateWithoutSequence_StepsInput;
};

export type SequenceUpsertWithoutSequenceEntryExitLeadInput = {
  create: SequenceCreateWithoutSequenceEntryExitLeadInput;
  update: SequenceUpdateWithoutSequenceEntryExitLeadInput;
};

export type SequenceUpsertWithoutSmsItemInput = {
  create: SequenceCreateWithoutSmsItemInput;
  update: SequenceUpdateWithoutSmsItemInput;
};

export type SequenceUpsertWithoutSnoozeTrackingInput = {
  create: SequenceCreateWithoutSnoozeTrackingInput;
  update: SequenceUpdateWithoutSnoozeTrackingInput;
};

export type SequenceUpsertWithoutStatItemsInput = {
  create: SequenceCreateWithoutStatItemsInput;
  update: SequenceUpdateWithoutStatItemsInput;
};

export type SequenceUpsertWithoutStepActionLogInput = {
  create: SequenceCreateWithoutStepActionLogInput;
  update: SequenceUpdateWithoutStepActionLogInput;
};

export type SequenceUpsertWithWhereUniqueWithoutCreated_ByInput = {
  create: SequenceCreateWithoutCreated_ByInput;
  update: SequenceUpdateWithoutCreated_ByInput;
  where: SequenceWhereUniqueInput;
};

export type SequenceUpsertWithWhereUniqueWithoutLast_Updated_ByInput = {
  create: SequenceCreateWithoutLast_Updated_ByInput;
  update: SequenceUpdateWithoutLast_Updated_ByInput;
  where: SequenceWhereUniqueInput;
};

export type SequenceUpsertWithWhereUniqueWithoutOrganzationInput = {
  create: SequenceCreateWithoutOrganzationInput;
  update: SequenceUpdateWithoutOrganzationInput;
  where: SequenceWhereUniqueInput;
};

export type SequenceWhereInput = {
  active?: InputMaybe<BoolFilter>;
  AND?: InputMaybe<Array<SequenceWhereInput>>;
  completed_count?: InputMaybe<IntFilter>;
  conversion_count?: InputMaybe<IntFilter>;
  created_at?: InputMaybe<DateTimeFilter>;
  created_by?: InputMaybe<UserWhereInput>;
  created_by_user_id?: InputMaybe<StringFilter>;
  deleted_at?: InputMaybe<DateTimeNullableFilter>;
  email_bounced_perc?: InputMaybe<FloatFilter>;
  email_clicked_perc?: InputMaybe<FloatFilter>;
  email_delivered_perc?: InputMaybe<FloatFilter>;
  email_opened_perc?: InputMaybe<FloatFilter>;
  email_replied_perc?: InputMaybe<FloatFilter>;
  email_unsubscribed_perc?: InputMaybe<FloatFilter>;
  EmailItem?: InputMaybe<EmailItemListRelationFilter>;
  EndStepsTransfer?: InputMaybe<SequenceStepListRelationFilter>;
  exit_count?: InputMaybe<IntFilter>;
  id?: InputMaybe<StringFilter>;
  is_paused?: InputMaybe<BoolFilter>;
  last_updated_by?: InputMaybe<UserWhereInput>;
  leadActivities?: InputMaybe<LeadActivityListRelationFilter>;
  LeadAssignmentHistory?: InputMaybe<LeadAssignmentHistoryListRelationFilter>;
  leads?: InputMaybe<LeadListRelationFilter>;
  manager_feedback_count?: InputMaybe<IntFilter>;
  name?: InputMaybe<StringFilter>;
  NOT?: InputMaybe<Array<SequenceWhereInput>>;
  not_interested_count?: InputMaybe<IntFilter>;
  OR?: InputMaybe<Array<SequenceWhereInput>>;
  organization_id?: InputMaybe<StringNullableFilter>;
  organzation?: InputMaybe<OrganizationWhereInput>;
  priority?: InputMaybe<IntFilter>;
  Sale?: InputMaybe<SaleListRelationFilter>;
  sequence_entry_criteria?: InputMaybe<SequenceEntryCriteriaWhereInput>;
  sequence_entry_criteria_id?: InputMaybe<StringNullableFilter>;
  sequence_steps?: InputMaybe<SequenceStepListRelationFilter>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadListRelationFilter>;
  sms_bounced_perc?: InputMaybe<FloatFilter>;
  sms_delivered_perc?: InputMaybe<FloatFilter>;
  sms_unsubscribed_perc?: InputMaybe<FloatFilter>;
  SMSItem?: InputMaybe<SmsItemListRelationFilter>;
  SnoozeTracking?: InputMaybe<SnoozeTrackingListRelationFilter>;
  StatItems?: InputMaybe<StatItemAggregationListRelationFilter>;
  StepActionLog?: InputMaybe<StepActionLogListRelationFilter>;
  steps_count?: InputMaybe<IntFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
  updated_by_user_id?: InputMaybe<StringFilter>;
};

export type SequenceWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  sequence_entry_criteria_id?: InputMaybe<Scalars['String']['input']>;
};

export type SfOpportunity = {
  __typename?: 'SFOpportunity';
  created_at: Scalars['DateTime']['output'];
  opportunity_id: Scalars['String']['output'];
  organization: Organization;
  organization_id: Scalars['String']['output'];
  updated_at: Scalars['DateTime']['output'];
};

export type SfOpportunityCreateManyOrganizationInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  opportunity_id: Scalars['String']['input'];
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type SfOpportunityCreateManyOrganizationInputEnvelope = {
  data?: InputMaybe<Array<SfOpportunityCreateManyOrganizationInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SfOpportunityCreateNestedManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<SfOpportunityWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SfOpportunityCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<SfOpportunityCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<SfOpportunityCreateManyOrganizationInputEnvelope>;
};

export type SfOpportunityCreateOrConnectWithoutOrganizationInput = {
  create: SfOpportunityCreateWithoutOrganizationInput;
  where: SfOpportunityWhereUniqueInput;
};

export type SfOpportunityCreateWithoutOrganizationInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  opportunity_id: Scalars['String']['input'];
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type SfOpportunityListRelationFilter = {
  every?: InputMaybe<SfOpportunityWhereInput>;
  none?: InputMaybe<SfOpportunityWhereInput>;
  some?: InputMaybe<SfOpportunityWhereInput>;
};

export type SfOpportunityOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
  count?: InputMaybe<SortOrder>;
};

export type SfOpportunityOrderByWithRelationInput = {
  created_at?: InputMaybe<SortOrder>;
  opportunity_id?: InputMaybe<SortOrder>;
  organization?: InputMaybe<OrganizationOrderByWithRelationInput>;
  organization_id?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
};

export type SfOpportunityScalarWhereInput = {
  AND?: InputMaybe<Array<SfOpportunityScalarWhereInput>>;
  created_at?: InputMaybe<DateTimeFilter>;
  NOT?: InputMaybe<Array<SfOpportunityScalarWhereInput>>;
  opportunity_id?: InputMaybe<StringFilter>;
  OR?: InputMaybe<Array<SfOpportunityScalarWhereInput>>;
  organization_id?: InputMaybe<StringFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
};

export type SfOpportunityUpdateManyMutationInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  opportunity_id?: InputMaybe<StringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type SfOpportunityUpdateManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<SfOpportunityWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SfOpportunityCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<SfOpportunityCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<SfOpportunityCreateManyOrganizationInputEnvelope>;
  delete?: InputMaybe<Array<SfOpportunityWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<SfOpportunityScalarWhereInput>>;
  disconnect?: InputMaybe<Array<SfOpportunityWhereUniqueInput>>;
  set?: InputMaybe<Array<SfOpportunityWhereUniqueInput>>;
  update?: InputMaybe<Array<SfOpportunityUpdateWithWhereUniqueWithoutOrganizationInput>>;
  updateMany?: InputMaybe<Array<SfOpportunityUpdateManyWithWhereWithoutOrganizationInput>>;
  upsert?: InputMaybe<Array<SfOpportunityUpsertWithWhereUniqueWithoutOrganizationInput>>;
};

export type SfOpportunityUpdateManyWithWhereWithoutOrganizationInput = {
  data: SfOpportunityUpdateManyMutationInput;
  where: SfOpportunityScalarWhereInput;
};

export type SfOpportunityUpdateWithoutOrganizationInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  opportunity_id?: InputMaybe<StringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type SfOpportunityUpdateWithWhereUniqueWithoutOrganizationInput = {
  data: SfOpportunityUpdateWithoutOrganizationInput;
  where: SfOpportunityWhereUniqueInput;
};

export type SfOpportunityUpsertWithWhereUniqueWithoutOrganizationInput = {
  create: SfOpportunityCreateWithoutOrganizationInput;
  update: SfOpportunityUpdateWithoutOrganizationInput;
  where: SfOpportunityWhereUniqueInput;
};

export type SfOpportunityWhereInput = {
  AND?: InputMaybe<Array<SfOpportunityWhereInput>>;
  created_at?: InputMaybe<DateTimeFilter>;
  NOT?: InputMaybe<Array<SfOpportunityWhereInput>>;
  opportunity_id?: InputMaybe<StringFilter>;
  OR?: InputMaybe<Array<SfOpportunityWhereInput>>;
  organization?: InputMaybe<OrganizationWhereInput>;
  organization_id?: InputMaybe<StringFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
};

export type SfOpportunityWhereUniqueInput = {
  opportunity_id?: InputMaybe<Scalars['String']['input']>;
};

/** SharedViewObjectType include w/ saved view group by option */
export type SharedViewObjectType = {
  __typename?: 'SharedViewObjectType';
  group_option: Dashboard_Group_Option;
  saved_view_data?: Maybe<DashboardSavedView>;
  stat_items: Array<StatItem>;
};

export type Site = {
  __typename?: 'Site';
  close_site_cycle: Array<SaleCycle>;
  created_at: Scalars['DateTime']['output'];
  deleted_at?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['String']['output'];
  lead_activities: Array<LeadActivity>;
  name: Scalars['String']['output'];
  organization: Organization;
  organization_id: Scalars['String']['output'];
  set_site_cycle: Array<SaleCycle>;
  Team: Array<Team>;
  updated_at: Scalars['DateTime']['output'];
};


export type SiteClose_Site_CycleArgs = {
  after?: InputMaybe<SaleCycleWhereUniqueInput>;
  before?: InputMaybe<SaleCycleWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type SiteLead_ActivitiesArgs = {
  after?: InputMaybe<LeadActivityWhereUniqueInput>;
  before?: InputMaybe<LeadActivityWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type SiteSet_Site_CycleArgs = {
  after?: InputMaybe<SaleCycleWhereUniqueInput>;
  before?: InputMaybe<SaleCycleWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type SiteTeamArgs = {
  after?: InputMaybe<TeamWhereUniqueInput>;
  before?: InputMaybe<TeamWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type SiteCreateManyOrganizationInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  deleted_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type SiteCreateManyOrganizationInputEnvelope = {
  data?: InputMaybe<Array<SiteCreateManyOrganizationInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SiteCreateNestedManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<SiteWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SiteCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<SiteCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<SiteCreateManyOrganizationInputEnvelope>;
};

export type SiteCreateNestedOneWithoutClose_Site_CycleInput = {
  connect?: InputMaybe<SiteWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SiteCreateOrConnectWithoutClose_Site_CycleInput>;
  create?: InputMaybe<SiteCreateWithoutClose_Site_CycleInput>;
};

export type SiteCreateNestedOneWithoutLead_ActivitiesInput = {
  connect?: InputMaybe<SiteWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SiteCreateOrConnectWithoutLead_ActivitiesInput>;
  create?: InputMaybe<SiteCreateWithoutLead_ActivitiesInput>;
};

export type SiteCreateNestedOneWithoutSet_Site_CycleInput = {
  connect?: InputMaybe<SiteWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SiteCreateOrConnectWithoutSet_Site_CycleInput>;
  create?: InputMaybe<SiteCreateWithoutSet_Site_CycleInput>;
};

export type SiteCreateNestedOneWithoutStepActionLogInput = {
  connect?: InputMaybe<SiteWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SiteCreateOrConnectWithoutStepActionLogInput>;
  create?: InputMaybe<SiteCreateWithoutStepActionLogInput>;
};

export type SiteCreateNestedOneWithoutTeamInput = {
  connect?: InputMaybe<SiteWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SiteCreateOrConnectWithoutTeamInput>;
  create?: InputMaybe<SiteCreateWithoutTeamInput>;
};

export type SiteCreateNestedOneWithoutTransfer_Site_CycleInput = {
  connect?: InputMaybe<SiteWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SiteCreateOrConnectWithoutTransfer_Site_CycleInput>;
  create?: InputMaybe<SiteCreateWithoutTransfer_Site_CycleInput>;
};

export type SiteCreateOrConnectWithoutClose_Site_CycleInput = {
  create: SiteCreateWithoutClose_Site_CycleInput;
  where: SiteWhereUniqueInput;
};

export type SiteCreateOrConnectWithoutLead_ActivitiesInput = {
  create: SiteCreateWithoutLead_ActivitiesInput;
  where: SiteWhereUniqueInput;
};

export type SiteCreateOrConnectWithoutOrganizationInput = {
  create: SiteCreateWithoutOrganizationInput;
  where: SiteWhereUniqueInput;
};

export type SiteCreateOrConnectWithoutSet_Site_CycleInput = {
  create: SiteCreateWithoutSet_Site_CycleInput;
  where: SiteWhereUniqueInput;
};

export type SiteCreateOrConnectWithoutStepActionLogInput = {
  create: SiteCreateWithoutStepActionLogInput;
  where: SiteWhereUniqueInput;
};

export type SiteCreateOrConnectWithoutTeamInput = {
  create: SiteCreateWithoutTeamInput;
  where: SiteWhereUniqueInput;
};

export type SiteCreateOrConnectWithoutTransfer_Site_CycleInput = {
  create: SiteCreateWithoutTransfer_Site_CycleInput;
  where: SiteWhereUniqueInput;
};

export type SiteCreateWithoutClose_Site_CycleInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  deleted_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutSiteInput>;
  name: Scalars['String']['input'];
  organization: OrganizationCreateNestedOneWithoutSitesInput;
  set_site_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutSet_SiteInput>;
  StepActionLog?: InputMaybe<StepActionLogCreateNestedManyWithoutSiteInput>;
  Team?: InputMaybe<TeamCreateNestedManyWithoutSiteInput>;
  transfer_site_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutTransfer_SiteInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type SiteCreateWithoutLead_ActivitiesInput = {
  close_site_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutClose_SiteInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  deleted_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  organization: OrganizationCreateNestedOneWithoutSitesInput;
  set_site_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutSet_SiteInput>;
  StepActionLog?: InputMaybe<StepActionLogCreateNestedManyWithoutSiteInput>;
  Team?: InputMaybe<TeamCreateNestedManyWithoutSiteInput>;
  transfer_site_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutTransfer_SiteInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type SiteCreateWithoutOrganizationInput = {
  close_site_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutClose_SiteInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  deleted_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutSiteInput>;
  name: Scalars['String']['input'];
  set_site_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutSet_SiteInput>;
  StepActionLog?: InputMaybe<StepActionLogCreateNestedManyWithoutSiteInput>;
  Team?: InputMaybe<TeamCreateNestedManyWithoutSiteInput>;
  transfer_site_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutTransfer_SiteInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type SiteCreateWithoutSet_Site_CycleInput = {
  close_site_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutClose_SiteInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  deleted_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutSiteInput>;
  name: Scalars['String']['input'];
  organization: OrganizationCreateNestedOneWithoutSitesInput;
  StepActionLog?: InputMaybe<StepActionLogCreateNestedManyWithoutSiteInput>;
  Team?: InputMaybe<TeamCreateNestedManyWithoutSiteInput>;
  transfer_site_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutTransfer_SiteInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type SiteCreateWithoutStepActionLogInput = {
  close_site_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutClose_SiteInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  deleted_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutSiteInput>;
  name: Scalars['String']['input'];
  organization: OrganizationCreateNestedOneWithoutSitesInput;
  set_site_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutSet_SiteInput>;
  Team?: InputMaybe<TeamCreateNestedManyWithoutSiteInput>;
  transfer_site_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutTransfer_SiteInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type SiteCreateWithoutTeamInput = {
  close_site_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutClose_SiteInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  deleted_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutSiteInput>;
  name: Scalars['String']['input'];
  organization: OrganizationCreateNestedOneWithoutSitesInput;
  set_site_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutSet_SiteInput>;
  StepActionLog?: InputMaybe<StepActionLogCreateNestedManyWithoutSiteInput>;
  transfer_site_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutTransfer_SiteInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type SiteCreateWithoutTransfer_Site_CycleInput = {
  close_site_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutClose_SiteInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  deleted_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutSiteInput>;
  name: Scalars['String']['input'];
  organization: OrganizationCreateNestedOneWithoutSitesInput;
  set_site_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutSet_SiteInput>;
  StepActionLog?: InputMaybe<StepActionLogCreateNestedManyWithoutSiteInput>;
  Team?: InputMaybe<TeamCreateNestedManyWithoutSiteInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type SiteListRelationFilter = {
  every?: InputMaybe<SiteWhereInput>;
  none?: InputMaybe<SiteWhereInput>;
  some?: InputMaybe<SiteWhereInput>;
};

export type SiteNameOrganization_IdCompoundUniqueInput = {
  name: Scalars['String']['input'];
  organization_id: Scalars['String']['input'];
};

export type SiteOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
  count?: InputMaybe<SortOrder>;
};

export type SiteOrderByWithRelationInput = {
  close_site_cycle?: InputMaybe<SaleCycleOrderByRelationAggregateInput>;
  created_at?: InputMaybe<SortOrder>;
  deleted_at?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  lead_activities?: InputMaybe<LeadActivityOrderByRelationAggregateInput>;
  name?: InputMaybe<SortOrder>;
  organization?: InputMaybe<OrganizationOrderByWithRelationInput>;
  organization_id?: InputMaybe<SortOrder>;
  set_site_cycle?: InputMaybe<SaleCycleOrderByRelationAggregateInput>;
  StepActionLog?: InputMaybe<StepActionLogOrderByRelationAggregateInput>;
  Team?: InputMaybe<TeamOrderByRelationAggregateInput>;
  transfer_site_cycle?: InputMaybe<SaleCycleOrderByRelationAggregateInput>;
  updated_at?: InputMaybe<SortOrder>;
};

export type SiteScalarWhereInput = {
  AND?: InputMaybe<Array<SiteScalarWhereInput>>;
  created_at?: InputMaybe<DateTimeFilter>;
  deleted_at?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  NOT?: InputMaybe<Array<SiteScalarWhereInput>>;
  OR?: InputMaybe<Array<SiteScalarWhereInput>>;
  organization_id?: InputMaybe<StringFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
};

export type SiteUpdateManyMutationInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deleted_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type SiteUpdateManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<SiteWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SiteCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<SiteCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<SiteCreateManyOrganizationInputEnvelope>;
  delete?: InputMaybe<Array<SiteWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<SiteScalarWhereInput>>;
  disconnect?: InputMaybe<Array<SiteWhereUniqueInput>>;
  set?: InputMaybe<Array<SiteWhereUniqueInput>>;
  update?: InputMaybe<Array<SiteUpdateWithWhereUniqueWithoutOrganizationInput>>;
  updateMany?: InputMaybe<Array<SiteUpdateManyWithWhereWithoutOrganizationInput>>;
  upsert?: InputMaybe<Array<SiteUpsertWithWhereUniqueWithoutOrganizationInput>>;
};

export type SiteUpdateManyWithWhereWithoutOrganizationInput = {
  data: SiteUpdateManyMutationInput;
  where: SiteScalarWhereInput;
};

export type SiteUpdateOneWithoutClose_Site_CycleInput = {
  connect?: InputMaybe<SiteWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SiteCreateOrConnectWithoutClose_Site_CycleInput>;
  create?: InputMaybe<SiteCreateWithoutClose_Site_CycleInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<SiteUpdateWithoutClose_Site_CycleInput>;
  upsert?: InputMaybe<SiteUpsertWithoutClose_Site_CycleInput>;
};

export type SiteUpdateOneWithoutLead_ActivitiesInput = {
  connect?: InputMaybe<SiteWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SiteCreateOrConnectWithoutLead_ActivitiesInput>;
  create?: InputMaybe<SiteCreateWithoutLead_ActivitiesInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<SiteUpdateWithoutLead_ActivitiesInput>;
  upsert?: InputMaybe<SiteUpsertWithoutLead_ActivitiesInput>;
};

export type SiteUpdateOneWithoutSet_Site_CycleInput = {
  connect?: InputMaybe<SiteWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SiteCreateOrConnectWithoutSet_Site_CycleInput>;
  create?: InputMaybe<SiteCreateWithoutSet_Site_CycleInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<SiteUpdateWithoutSet_Site_CycleInput>;
  upsert?: InputMaybe<SiteUpsertWithoutSet_Site_CycleInput>;
};

export type SiteUpdateOneWithoutStepActionLogInput = {
  connect?: InputMaybe<SiteWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SiteCreateOrConnectWithoutStepActionLogInput>;
  create?: InputMaybe<SiteCreateWithoutStepActionLogInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<SiteUpdateWithoutStepActionLogInput>;
  upsert?: InputMaybe<SiteUpsertWithoutStepActionLogInput>;
};

export type SiteUpdateOneWithoutTeamInput = {
  connect?: InputMaybe<SiteWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SiteCreateOrConnectWithoutTeamInput>;
  create?: InputMaybe<SiteCreateWithoutTeamInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<SiteUpdateWithoutTeamInput>;
  upsert?: InputMaybe<SiteUpsertWithoutTeamInput>;
};

export type SiteUpdateOneWithoutTransfer_Site_CycleInput = {
  connect?: InputMaybe<SiteWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SiteCreateOrConnectWithoutTransfer_Site_CycleInput>;
  create?: InputMaybe<SiteCreateWithoutTransfer_Site_CycleInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<SiteUpdateWithoutTransfer_Site_CycleInput>;
  upsert?: InputMaybe<SiteUpsertWithoutTransfer_Site_CycleInput>;
};

export type SiteUpdateWithoutClose_Site_CycleInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deleted_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutSiteInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutSitesInput>;
  set_site_cycle?: InputMaybe<SaleCycleUpdateManyWithoutSet_SiteInput>;
  StepActionLog?: InputMaybe<StepActionLogUpdateManyWithoutSiteInput>;
  Team?: InputMaybe<TeamUpdateManyWithoutSiteInput>;
  transfer_site_cycle?: InputMaybe<SaleCycleUpdateManyWithoutTransfer_SiteInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type SiteUpdateWithoutLead_ActivitiesInput = {
  close_site_cycle?: InputMaybe<SaleCycleUpdateManyWithoutClose_SiteInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deleted_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutSitesInput>;
  set_site_cycle?: InputMaybe<SaleCycleUpdateManyWithoutSet_SiteInput>;
  StepActionLog?: InputMaybe<StepActionLogUpdateManyWithoutSiteInput>;
  Team?: InputMaybe<TeamUpdateManyWithoutSiteInput>;
  transfer_site_cycle?: InputMaybe<SaleCycleUpdateManyWithoutTransfer_SiteInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type SiteUpdateWithoutOrganizationInput = {
  close_site_cycle?: InputMaybe<SaleCycleUpdateManyWithoutClose_SiteInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deleted_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutSiteInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  set_site_cycle?: InputMaybe<SaleCycleUpdateManyWithoutSet_SiteInput>;
  StepActionLog?: InputMaybe<StepActionLogUpdateManyWithoutSiteInput>;
  Team?: InputMaybe<TeamUpdateManyWithoutSiteInput>;
  transfer_site_cycle?: InputMaybe<SaleCycleUpdateManyWithoutTransfer_SiteInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type SiteUpdateWithoutSet_Site_CycleInput = {
  close_site_cycle?: InputMaybe<SaleCycleUpdateManyWithoutClose_SiteInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deleted_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutSiteInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutSitesInput>;
  StepActionLog?: InputMaybe<StepActionLogUpdateManyWithoutSiteInput>;
  Team?: InputMaybe<TeamUpdateManyWithoutSiteInput>;
  transfer_site_cycle?: InputMaybe<SaleCycleUpdateManyWithoutTransfer_SiteInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type SiteUpdateWithoutStepActionLogInput = {
  close_site_cycle?: InputMaybe<SaleCycleUpdateManyWithoutClose_SiteInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deleted_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutSiteInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutSitesInput>;
  set_site_cycle?: InputMaybe<SaleCycleUpdateManyWithoutSet_SiteInput>;
  Team?: InputMaybe<TeamUpdateManyWithoutSiteInput>;
  transfer_site_cycle?: InputMaybe<SaleCycleUpdateManyWithoutTransfer_SiteInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type SiteUpdateWithoutTeamInput = {
  close_site_cycle?: InputMaybe<SaleCycleUpdateManyWithoutClose_SiteInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deleted_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutSiteInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutSitesInput>;
  set_site_cycle?: InputMaybe<SaleCycleUpdateManyWithoutSet_SiteInput>;
  StepActionLog?: InputMaybe<StepActionLogUpdateManyWithoutSiteInput>;
  transfer_site_cycle?: InputMaybe<SaleCycleUpdateManyWithoutTransfer_SiteInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type SiteUpdateWithoutTransfer_Site_CycleInput = {
  close_site_cycle?: InputMaybe<SaleCycleUpdateManyWithoutClose_SiteInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deleted_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutSiteInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutSitesInput>;
  set_site_cycle?: InputMaybe<SaleCycleUpdateManyWithoutSet_SiteInput>;
  StepActionLog?: InputMaybe<StepActionLogUpdateManyWithoutSiteInput>;
  Team?: InputMaybe<TeamUpdateManyWithoutSiteInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type SiteUpdateWithWhereUniqueWithoutOrganizationInput = {
  data: SiteUpdateWithoutOrganizationInput;
  where: SiteWhereUniqueInput;
};

export type SiteUpsertWithoutClose_Site_CycleInput = {
  create: SiteCreateWithoutClose_Site_CycleInput;
  update: SiteUpdateWithoutClose_Site_CycleInput;
};

export type SiteUpsertWithoutLead_ActivitiesInput = {
  create: SiteCreateWithoutLead_ActivitiesInput;
  update: SiteUpdateWithoutLead_ActivitiesInput;
};

export type SiteUpsertWithoutSet_Site_CycleInput = {
  create: SiteCreateWithoutSet_Site_CycleInput;
  update: SiteUpdateWithoutSet_Site_CycleInput;
};

export type SiteUpsertWithoutStepActionLogInput = {
  create: SiteCreateWithoutStepActionLogInput;
  update: SiteUpdateWithoutStepActionLogInput;
};

export type SiteUpsertWithoutTeamInput = {
  create: SiteCreateWithoutTeamInput;
  update: SiteUpdateWithoutTeamInput;
};

export type SiteUpsertWithoutTransfer_Site_CycleInput = {
  create: SiteCreateWithoutTransfer_Site_CycleInput;
  update: SiteUpdateWithoutTransfer_Site_CycleInput;
};

export type SiteUpsertWithWhereUniqueWithoutOrganizationInput = {
  create: SiteCreateWithoutOrganizationInput;
  update: SiteUpdateWithoutOrganizationInput;
  where: SiteWhereUniqueInput;
};

export type SiteWhereInput = {
  AND?: InputMaybe<Array<SiteWhereInput>>;
  close_site_cycle?: InputMaybe<SaleCycleListRelationFilter>;
  created_at?: InputMaybe<DateTimeFilter>;
  deleted_at?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<StringFilter>;
  lead_activities?: InputMaybe<LeadActivityListRelationFilter>;
  name?: InputMaybe<StringFilter>;
  NOT?: InputMaybe<Array<SiteWhereInput>>;
  OR?: InputMaybe<Array<SiteWhereInput>>;
  organization?: InputMaybe<OrganizationWhereInput>;
  organization_id?: InputMaybe<StringFilter>;
  set_site_cycle?: InputMaybe<SaleCycleListRelationFilter>;
  StepActionLog?: InputMaybe<StepActionLogListRelationFilter>;
  Team?: InputMaybe<TeamListRelationFilter>;
  transfer_site_cycle?: InputMaybe<SaleCycleListRelationFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
};

export type SiteWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  name_organization_id?: InputMaybe<SiteNameOrganization_IdCompoundUniqueInput>;
};

export type SmsItem = {
  __typename?: 'SMSItem';
  channel?: Maybe<Channel>;
  created_at: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  /** Returns true if the message is from the lead */
  isFromLead?: Maybe<Scalars['Boolean']['output']>;
  /** True if the message is from a rep */
  isFromRep?: Maybe<Scalars['Boolean']['output']>;
  /** True if the message is from the currently logged in user. */
  isFromUser?: Maybe<Scalars['Boolean']['output']>;
  lead_activity?: Maybe<LeadActivity>;
  lead_activity_id?: Maybe<Scalars['String']['output']>;
  lead_number_used?: Maybe<Scalars['String']['output']>;
  read?: Maybe<Scalars['Boolean']['output']>;
  read_at?: Maybe<Scalars['DateTime']['output']>;
  sender_id: Scalars['String']['output'];
  /** The label of the sender. Could be rep or lead. */
  sender_label?: Maybe<Scalars['String']['output']>;
  sent_at: Scalars['DateTime']['output'];
  sequence_id?: Maybe<Scalars['String']['output']>;
  step_action?: Maybe<StepAction>;
  step_action_id?: Maybe<Scalars['String']['output']>;
  text?: Maybe<Scalars['String']['output']>;
  twilio_delivered_at?: Maybe<Scalars['DateTime']['output']>;
  twilio_failed_at?: Maybe<Scalars['DateTime']['output']>;
  updated_at: Scalars['DateTime']['output'];
};

export type SmsItemCreateManyOrganizationInput = {
  channel?: InputMaybe<Channel>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  lead_activity_id?: InputMaybe<Scalars['String']['input']>;
  lead_number_used?: InputMaybe<Scalars['String']['input']>;
  number_sent_to?: InputMaybe<Scalars['String']['input']>;
  read?: InputMaybe<Scalars['Boolean']['input']>;
  read_at?: InputMaybe<Scalars['DateTime']['input']>;
  rep_id?: InputMaybe<Scalars['String']['input']>;
  sender_id: Scalars['String']['input'];
  sent_at?: InputMaybe<Scalars['DateTime']['input']>;
  sequence_id?: InputMaybe<Scalars['String']['input']>;
  step_action_id?: InputMaybe<Scalars['String']['input']>;
  template_version_id?: InputMaybe<Scalars['String']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
  twilio_delivered_at?: InputMaybe<Scalars['DateTime']['input']>;
  twilio_failed_at?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type SmsItemCreateManyOrganizationInputEnvelope = {
  data?: InputMaybe<Array<SmsItemCreateManyOrganizationInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SmsItemCreateManyRepInput = {
  channel?: InputMaybe<Channel>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  lead_activity_id?: InputMaybe<Scalars['String']['input']>;
  lead_number_used?: InputMaybe<Scalars['String']['input']>;
  number_sent_to?: InputMaybe<Scalars['String']['input']>;
  organization_id?: InputMaybe<Scalars['String']['input']>;
  read?: InputMaybe<Scalars['Boolean']['input']>;
  read_at?: InputMaybe<Scalars['DateTime']['input']>;
  sender_id: Scalars['String']['input'];
  sent_at?: InputMaybe<Scalars['DateTime']['input']>;
  sequence_id?: InputMaybe<Scalars['String']['input']>;
  step_action_id?: InputMaybe<Scalars['String']['input']>;
  template_version_id?: InputMaybe<Scalars['String']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
  twilio_delivered_at?: InputMaybe<Scalars['DateTime']['input']>;
  twilio_failed_at?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type SmsItemCreateManyRepInputEnvelope = {
  data?: InputMaybe<Array<SmsItemCreateManyRepInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SmsItemCreateManySequenceInput = {
  channel?: InputMaybe<Channel>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  lead_activity_id?: InputMaybe<Scalars['String']['input']>;
  lead_number_used?: InputMaybe<Scalars['String']['input']>;
  number_sent_to?: InputMaybe<Scalars['String']['input']>;
  organization_id?: InputMaybe<Scalars['String']['input']>;
  read?: InputMaybe<Scalars['Boolean']['input']>;
  read_at?: InputMaybe<Scalars['DateTime']['input']>;
  rep_id?: InputMaybe<Scalars['String']['input']>;
  sender_id: Scalars['String']['input'];
  sent_at?: InputMaybe<Scalars['DateTime']['input']>;
  step_action_id?: InputMaybe<Scalars['String']['input']>;
  template_version_id?: InputMaybe<Scalars['String']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
  twilio_delivered_at?: InputMaybe<Scalars['DateTime']['input']>;
  twilio_failed_at?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type SmsItemCreateManySequenceInputEnvelope = {
  data?: InputMaybe<Array<SmsItemCreateManySequenceInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SmsItemCreateManyStep_ActionInput = {
  channel?: InputMaybe<Channel>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  lead_activity_id?: InputMaybe<Scalars['String']['input']>;
  lead_number_used?: InputMaybe<Scalars['String']['input']>;
  number_sent_to?: InputMaybe<Scalars['String']['input']>;
  organization_id?: InputMaybe<Scalars['String']['input']>;
  read?: InputMaybe<Scalars['Boolean']['input']>;
  read_at?: InputMaybe<Scalars['DateTime']['input']>;
  rep_id?: InputMaybe<Scalars['String']['input']>;
  sender_id: Scalars['String']['input'];
  sent_at?: InputMaybe<Scalars['DateTime']['input']>;
  sequence_id?: InputMaybe<Scalars['String']['input']>;
  template_version_id?: InputMaybe<Scalars['String']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
  twilio_delivered_at?: InputMaybe<Scalars['DateTime']['input']>;
  twilio_failed_at?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type SmsItemCreateManyStep_ActionInputEnvelope = {
  data?: InputMaybe<Array<SmsItemCreateManyStep_ActionInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SmsItemCreateManyTemplate_VersionInput = {
  channel?: InputMaybe<Channel>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  lead_activity_id?: InputMaybe<Scalars['String']['input']>;
  lead_number_used?: InputMaybe<Scalars['String']['input']>;
  number_sent_to?: InputMaybe<Scalars['String']['input']>;
  organization_id?: InputMaybe<Scalars['String']['input']>;
  read?: InputMaybe<Scalars['Boolean']['input']>;
  read_at?: InputMaybe<Scalars['DateTime']['input']>;
  rep_id?: InputMaybe<Scalars['String']['input']>;
  sender_id: Scalars['String']['input'];
  sent_at?: InputMaybe<Scalars['DateTime']['input']>;
  sequence_id?: InputMaybe<Scalars['String']['input']>;
  step_action_id?: InputMaybe<Scalars['String']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
  twilio_delivered_at?: InputMaybe<Scalars['DateTime']['input']>;
  twilio_failed_at?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type SmsItemCreateManyTemplate_VersionInputEnvelope = {
  data?: InputMaybe<Array<SmsItemCreateManyTemplate_VersionInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SmsItemCreateNestedManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<SmsItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SmsItemCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<SmsItemCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<SmsItemCreateManyOrganizationInputEnvelope>;
};

export type SmsItemCreateNestedManyWithoutRepInput = {
  connect?: InputMaybe<Array<SmsItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SmsItemCreateOrConnectWithoutRepInput>>;
  create?: InputMaybe<Array<SmsItemCreateWithoutRepInput>>;
  createMany?: InputMaybe<SmsItemCreateManyRepInputEnvelope>;
};

export type SmsItemCreateNestedManyWithoutSequenceInput = {
  connect?: InputMaybe<Array<SmsItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SmsItemCreateOrConnectWithoutSequenceInput>>;
  create?: InputMaybe<Array<SmsItemCreateWithoutSequenceInput>>;
  createMany?: InputMaybe<SmsItemCreateManySequenceInputEnvelope>;
};

export type SmsItemCreateNestedManyWithoutStep_ActionInput = {
  connect?: InputMaybe<Array<SmsItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SmsItemCreateOrConnectWithoutStep_ActionInput>>;
  create?: InputMaybe<Array<SmsItemCreateWithoutStep_ActionInput>>;
  createMany?: InputMaybe<SmsItemCreateManyStep_ActionInputEnvelope>;
};

export type SmsItemCreateNestedManyWithoutTemplate_VersionInput = {
  connect?: InputMaybe<Array<SmsItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SmsItemCreateOrConnectWithoutTemplate_VersionInput>>;
  create?: InputMaybe<Array<SmsItemCreateWithoutTemplate_VersionInput>>;
  createMany?: InputMaybe<SmsItemCreateManyTemplate_VersionInputEnvelope>;
};

export type SmsItemCreateNestedOneWithoutLead_ActivityInput = {
  connect?: InputMaybe<SmsItemWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SmsItemCreateOrConnectWithoutLead_ActivityInput>;
  create?: InputMaybe<SmsItemCreateWithoutLead_ActivityInput>;
};

export type SmsItemCreateOrConnectWithoutLead_ActivityInput = {
  create: SmsItemCreateWithoutLead_ActivityInput;
  where: SmsItemWhereUniqueInput;
};

export type SmsItemCreateOrConnectWithoutOrganizationInput = {
  create: SmsItemCreateWithoutOrganizationInput;
  where: SmsItemWhereUniqueInput;
};

export type SmsItemCreateOrConnectWithoutRepInput = {
  create: SmsItemCreateWithoutRepInput;
  where: SmsItemWhereUniqueInput;
};

export type SmsItemCreateOrConnectWithoutSequenceInput = {
  create: SmsItemCreateWithoutSequenceInput;
  where: SmsItemWhereUniqueInput;
};

export type SmsItemCreateOrConnectWithoutStep_ActionInput = {
  create: SmsItemCreateWithoutStep_ActionInput;
  where: SmsItemWhereUniqueInput;
};

export type SmsItemCreateOrConnectWithoutTemplate_VersionInput = {
  create: SmsItemCreateWithoutTemplate_VersionInput;
  where: SmsItemWhereUniqueInput;
};

export type SmsItemCreateWithoutLead_ActivityInput = {
  channel?: InputMaybe<Channel>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  lead_number_used?: InputMaybe<Scalars['String']['input']>;
  number_sent_to?: InputMaybe<Scalars['String']['input']>;
  Organization?: InputMaybe<OrganizationCreateNestedOneWithoutSmsItemsInput>;
  read?: InputMaybe<Scalars['Boolean']['input']>;
  read_at?: InputMaybe<Scalars['DateTime']['input']>;
  rep?: InputMaybe<UserCreateNestedOneWithoutSmsItemsInput>;
  sender_id: Scalars['String']['input'];
  sent_at?: InputMaybe<Scalars['DateTime']['input']>;
  sequence?: InputMaybe<SequenceCreateNestedOneWithoutSmsItemInput>;
  step_action?: InputMaybe<StepActionCreateNestedOneWithoutSmsItemInput>;
  template_version?: InputMaybe<TemplateVersionCreateNestedOneWithoutSmsItemInput>;
  text?: InputMaybe<Scalars['String']['input']>;
  twilio_delivered_at?: InputMaybe<Scalars['DateTime']['input']>;
  twilio_failed_at?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type SmsItemCreateWithoutOrganizationInput = {
  channel?: InputMaybe<Channel>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  lead_activity?: InputMaybe<LeadActivityCreateNestedOneWithoutSmsItemInput>;
  lead_number_used?: InputMaybe<Scalars['String']['input']>;
  number_sent_to?: InputMaybe<Scalars['String']['input']>;
  read?: InputMaybe<Scalars['Boolean']['input']>;
  read_at?: InputMaybe<Scalars['DateTime']['input']>;
  rep?: InputMaybe<UserCreateNestedOneWithoutSmsItemsInput>;
  sender_id: Scalars['String']['input'];
  sent_at?: InputMaybe<Scalars['DateTime']['input']>;
  sequence?: InputMaybe<SequenceCreateNestedOneWithoutSmsItemInput>;
  step_action?: InputMaybe<StepActionCreateNestedOneWithoutSmsItemInput>;
  template_version?: InputMaybe<TemplateVersionCreateNestedOneWithoutSmsItemInput>;
  text?: InputMaybe<Scalars['String']['input']>;
  twilio_delivered_at?: InputMaybe<Scalars['DateTime']['input']>;
  twilio_failed_at?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type SmsItemCreateWithoutRepInput = {
  channel?: InputMaybe<Channel>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  lead_activity?: InputMaybe<LeadActivityCreateNestedOneWithoutSmsItemInput>;
  lead_number_used?: InputMaybe<Scalars['String']['input']>;
  number_sent_to?: InputMaybe<Scalars['String']['input']>;
  Organization?: InputMaybe<OrganizationCreateNestedOneWithoutSmsItemsInput>;
  read?: InputMaybe<Scalars['Boolean']['input']>;
  read_at?: InputMaybe<Scalars['DateTime']['input']>;
  sender_id: Scalars['String']['input'];
  sent_at?: InputMaybe<Scalars['DateTime']['input']>;
  sequence?: InputMaybe<SequenceCreateNestedOneWithoutSmsItemInput>;
  step_action?: InputMaybe<StepActionCreateNestedOneWithoutSmsItemInput>;
  template_version?: InputMaybe<TemplateVersionCreateNestedOneWithoutSmsItemInput>;
  text?: InputMaybe<Scalars['String']['input']>;
  twilio_delivered_at?: InputMaybe<Scalars['DateTime']['input']>;
  twilio_failed_at?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type SmsItemCreateWithoutSequenceInput = {
  channel?: InputMaybe<Channel>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  lead_activity?: InputMaybe<LeadActivityCreateNestedOneWithoutSmsItemInput>;
  lead_number_used?: InputMaybe<Scalars['String']['input']>;
  number_sent_to?: InputMaybe<Scalars['String']['input']>;
  Organization?: InputMaybe<OrganizationCreateNestedOneWithoutSmsItemsInput>;
  read?: InputMaybe<Scalars['Boolean']['input']>;
  read_at?: InputMaybe<Scalars['DateTime']['input']>;
  rep?: InputMaybe<UserCreateNestedOneWithoutSmsItemsInput>;
  sender_id: Scalars['String']['input'];
  sent_at?: InputMaybe<Scalars['DateTime']['input']>;
  step_action?: InputMaybe<StepActionCreateNestedOneWithoutSmsItemInput>;
  template_version?: InputMaybe<TemplateVersionCreateNestedOneWithoutSmsItemInput>;
  text?: InputMaybe<Scalars['String']['input']>;
  twilio_delivered_at?: InputMaybe<Scalars['DateTime']['input']>;
  twilio_failed_at?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type SmsItemCreateWithoutStep_ActionInput = {
  channel?: InputMaybe<Channel>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  lead_activity?: InputMaybe<LeadActivityCreateNestedOneWithoutSmsItemInput>;
  lead_number_used?: InputMaybe<Scalars['String']['input']>;
  number_sent_to?: InputMaybe<Scalars['String']['input']>;
  Organization?: InputMaybe<OrganizationCreateNestedOneWithoutSmsItemsInput>;
  read?: InputMaybe<Scalars['Boolean']['input']>;
  read_at?: InputMaybe<Scalars['DateTime']['input']>;
  rep?: InputMaybe<UserCreateNestedOneWithoutSmsItemsInput>;
  sender_id: Scalars['String']['input'];
  sent_at?: InputMaybe<Scalars['DateTime']['input']>;
  sequence?: InputMaybe<SequenceCreateNestedOneWithoutSmsItemInput>;
  template_version?: InputMaybe<TemplateVersionCreateNestedOneWithoutSmsItemInput>;
  text?: InputMaybe<Scalars['String']['input']>;
  twilio_delivered_at?: InputMaybe<Scalars['DateTime']['input']>;
  twilio_failed_at?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type SmsItemCreateWithoutTemplate_VersionInput = {
  channel?: InputMaybe<Channel>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  lead_activity?: InputMaybe<LeadActivityCreateNestedOneWithoutSmsItemInput>;
  lead_number_used?: InputMaybe<Scalars['String']['input']>;
  number_sent_to?: InputMaybe<Scalars['String']['input']>;
  Organization?: InputMaybe<OrganizationCreateNestedOneWithoutSmsItemsInput>;
  read?: InputMaybe<Scalars['Boolean']['input']>;
  read_at?: InputMaybe<Scalars['DateTime']['input']>;
  rep?: InputMaybe<UserCreateNestedOneWithoutSmsItemsInput>;
  sender_id: Scalars['String']['input'];
  sent_at?: InputMaybe<Scalars['DateTime']['input']>;
  sequence?: InputMaybe<SequenceCreateNestedOneWithoutSmsItemInput>;
  step_action?: InputMaybe<StepActionCreateNestedOneWithoutSmsItemInput>;
  text?: InputMaybe<Scalars['String']['input']>;
  twilio_delivered_at?: InputMaybe<Scalars['DateTime']['input']>;
  twilio_failed_at?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type SmsItemListRelationFilter = {
  every?: InputMaybe<SmsItemWhereInput>;
  none?: InputMaybe<SmsItemWhereInput>;
  some?: InputMaybe<SmsItemWhereInput>;
};

export type SmsItemOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
  count?: InputMaybe<SortOrder>;
};

export type SmsItemOrderByWithRelationInput = {
  channel?: InputMaybe<SortOrder>;
  created_at?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  lead_activity?: InputMaybe<LeadActivityOrderByWithRelationInput>;
  lead_activity_id?: InputMaybe<SortOrder>;
  lead_number_used?: InputMaybe<SortOrder>;
  number_sent_to?: InputMaybe<SortOrder>;
  Organization?: InputMaybe<OrganizationOrderByWithRelationInput>;
  organization_id?: InputMaybe<SortOrder>;
  read?: InputMaybe<SortOrder>;
  read_at?: InputMaybe<SortOrder>;
  rep?: InputMaybe<UserOrderByWithRelationInput>;
  rep_id?: InputMaybe<SortOrder>;
  sender_id?: InputMaybe<SortOrder>;
  sent_at?: InputMaybe<SortOrder>;
  sequence?: InputMaybe<SequenceOrderByWithRelationInput>;
  sequence_id?: InputMaybe<SortOrder>;
  step_action?: InputMaybe<StepActionOrderByWithRelationInput>;
  step_action_id?: InputMaybe<SortOrder>;
  template_version?: InputMaybe<TemplateVersionOrderByWithRelationInput>;
  template_version_id?: InputMaybe<SortOrder>;
  text?: InputMaybe<SortOrder>;
  twilio_delivered_at?: InputMaybe<SortOrder>;
  twilio_failed_at?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
};

export type SmsItemScalarWhereInput = {
  AND?: InputMaybe<Array<SmsItemScalarWhereInput>>;
  channel?: InputMaybe<EnumChannelNullableFilter>;
  created_at?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  lead_activity_id?: InputMaybe<StringNullableFilter>;
  lead_number_used?: InputMaybe<StringNullableFilter>;
  NOT?: InputMaybe<Array<SmsItemScalarWhereInput>>;
  number_sent_to?: InputMaybe<StringNullableFilter>;
  OR?: InputMaybe<Array<SmsItemScalarWhereInput>>;
  organization_id?: InputMaybe<StringNullableFilter>;
  read?: InputMaybe<BoolFilter>;
  read_at?: InputMaybe<DateTimeNullableFilter>;
  rep_id?: InputMaybe<StringNullableFilter>;
  sender_id?: InputMaybe<StringFilter>;
  sent_at?: InputMaybe<DateTimeFilter>;
  sequence_id?: InputMaybe<StringNullableFilter>;
  step_action_id?: InputMaybe<StringNullableFilter>;
  template_version_id?: InputMaybe<StringNullableFilter>;
  text?: InputMaybe<StringNullableFilter>;
  twilio_delivered_at?: InputMaybe<DateTimeNullableFilter>;
  twilio_failed_at?: InputMaybe<DateTimeNullableFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
};

export type SmsItemUpdateManyMutationInput = {
  channel?: InputMaybe<NullableEnumChannelFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lead_number_used?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  number_sent_to?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  read?: InputMaybe<BoolFieldUpdateOperationsInput>;
  read_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  sender_id?: InputMaybe<StringFieldUpdateOperationsInput>;
  sent_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  text?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  twilio_delivered_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  twilio_failed_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type SmsItemUpdateManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<SmsItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SmsItemCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<SmsItemCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<SmsItemCreateManyOrganizationInputEnvelope>;
  delete?: InputMaybe<Array<SmsItemWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<SmsItemScalarWhereInput>>;
  disconnect?: InputMaybe<Array<SmsItemWhereUniqueInput>>;
  set?: InputMaybe<Array<SmsItemWhereUniqueInput>>;
  update?: InputMaybe<Array<SmsItemUpdateWithWhereUniqueWithoutOrganizationInput>>;
  updateMany?: InputMaybe<Array<SmsItemUpdateManyWithWhereWithoutOrganizationInput>>;
  upsert?: InputMaybe<Array<SmsItemUpsertWithWhereUniqueWithoutOrganizationInput>>;
};

export type SmsItemUpdateManyWithoutRepInput = {
  connect?: InputMaybe<Array<SmsItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SmsItemCreateOrConnectWithoutRepInput>>;
  create?: InputMaybe<Array<SmsItemCreateWithoutRepInput>>;
  createMany?: InputMaybe<SmsItemCreateManyRepInputEnvelope>;
  delete?: InputMaybe<Array<SmsItemWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<SmsItemScalarWhereInput>>;
  disconnect?: InputMaybe<Array<SmsItemWhereUniqueInput>>;
  set?: InputMaybe<Array<SmsItemWhereUniqueInput>>;
  update?: InputMaybe<Array<SmsItemUpdateWithWhereUniqueWithoutRepInput>>;
  updateMany?: InputMaybe<Array<SmsItemUpdateManyWithWhereWithoutRepInput>>;
  upsert?: InputMaybe<Array<SmsItemUpsertWithWhereUniqueWithoutRepInput>>;
};

export type SmsItemUpdateManyWithoutSequenceInput = {
  connect?: InputMaybe<Array<SmsItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SmsItemCreateOrConnectWithoutSequenceInput>>;
  create?: InputMaybe<Array<SmsItemCreateWithoutSequenceInput>>;
  createMany?: InputMaybe<SmsItemCreateManySequenceInputEnvelope>;
  delete?: InputMaybe<Array<SmsItemWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<SmsItemScalarWhereInput>>;
  disconnect?: InputMaybe<Array<SmsItemWhereUniqueInput>>;
  set?: InputMaybe<Array<SmsItemWhereUniqueInput>>;
  update?: InputMaybe<Array<SmsItemUpdateWithWhereUniqueWithoutSequenceInput>>;
  updateMany?: InputMaybe<Array<SmsItemUpdateManyWithWhereWithoutSequenceInput>>;
  upsert?: InputMaybe<Array<SmsItemUpsertWithWhereUniqueWithoutSequenceInput>>;
};

export type SmsItemUpdateManyWithoutStep_ActionInput = {
  connect?: InputMaybe<Array<SmsItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SmsItemCreateOrConnectWithoutStep_ActionInput>>;
  create?: InputMaybe<Array<SmsItemCreateWithoutStep_ActionInput>>;
  createMany?: InputMaybe<SmsItemCreateManyStep_ActionInputEnvelope>;
  delete?: InputMaybe<Array<SmsItemWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<SmsItemScalarWhereInput>>;
  disconnect?: InputMaybe<Array<SmsItemWhereUniqueInput>>;
  set?: InputMaybe<Array<SmsItemWhereUniqueInput>>;
  update?: InputMaybe<Array<SmsItemUpdateWithWhereUniqueWithoutStep_ActionInput>>;
  updateMany?: InputMaybe<Array<SmsItemUpdateManyWithWhereWithoutStep_ActionInput>>;
  upsert?: InputMaybe<Array<SmsItemUpsertWithWhereUniqueWithoutStep_ActionInput>>;
};

export type SmsItemUpdateManyWithoutTemplate_VersionInput = {
  connect?: InputMaybe<Array<SmsItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SmsItemCreateOrConnectWithoutTemplate_VersionInput>>;
  create?: InputMaybe<Array<SmsItemCreateWithoutTemplate_VersionInput>>;
  createMany?: InputMaybe<SmsItemCreateManyTemplate_VersionInputEnvelope>;
  delete?: InputMaybe<Array<SmsItemWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<SmsItemScalarWhereInput>>;
  disconnect?: InputMaybe<Array<SmsItemWhereUniqueInput>>;
  set?: InputMaybe<Array<SmsItemWhereUniqueInput>>;
  update?: InputMaybe<Array<SmsItemUpdateWithWhereUniqueWithoutTemplate_VersionInput>>;
  updateMany?: InputMaybe<Array<SmsItemUpdateManyWithWhereWithoutTemplate_VersionInput>>;
  upsert?: InputMaybe<Array<SmsItemUpsertWithWhereUniqueWithoutTemplate_VersionInput>>;
};

export type SmsItemUpdateManyWithWhereWithoutOrganizationInput = {
  data: SmsItemUpdateManyMutationInput;
  where: SmsItemScalarWhereInput;
};

export type SmsItemUpdateManyWithWhereWithoutRepInput = {
  data: SmsItemUpdateManyMutationInput;
  where: SmsItemScalarWhereInput;
};

export type SmsItemUpdateManyWithWhereWithoutSequenceInput = {
  data: SmsItemUpdateManyMutationInput;
  where: SmsItemScalarWhereInput;
};

export type SmsItemUpdateManyWithWhereWithoutStep_ActionInput = {
  data: SmsItemUpdateManyMutationInput;
  where: SmsItemScalarWhereInput;
};

export type SmsItemUpdateManyWithWhereWithoutTemplate_VersionInput = {
  data: SmsItemUpdateManyMutationInput;
  where: SmsItemScalarWhereInput;
};

export type SmsItemUpdateOneWithoutLead_ActivityInput = {
  connect?: InputMaybe<SmsItemWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SmsItemCreateOrConnectWithoutLead_ActivityInput>;
  create?: InputMaybe<SmsItemCreateWithoutLead_ActivityInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<SmsItemUpdateWithoutLead_ActivityInput>;
  upsert?: InputMaybe<SmsItemUpsertWithoutLead_ActivityInput>;
};

export type SmsItemUpdateWithoutLead_ActivityInput = {
  channel?: InputMaybe<NullableEnumChannelFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lead_number_used?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  number_sent_to?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  Organization?: InputMaybe<OrganizationUpdateOneWithoutSmsItemsInput>;
  read?: InputMaybe<BoolFieldUpdateOperationsInput>;
  read_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  rep?: InputMaybe<UserUpdateOneWithoutSmsItemsInput>;
  sender_id?: InputMaybe<StringFieldUpdateOperationsInput>;
  sent_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  sequence?: InputMaybe<SequenceUpdateOneWithoutSmsItemInput>;
  step_action?: InputMaybe<StepActionUpdateOneWithoutSmsItemInput>;
  template_version?: InputMaybe<TemplateVersionUpdateOneWithoutSmsItemInput>;
  text?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  twilio_delivered_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  twilio_failed_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type SmsItemUpdateWithoutOrganizationInput = {
  channel?: InputMaybe<NullableEnumChannelFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lead_activity?: InputMaybe<LeadActivityUpdateOneWithoutSmsItemInput>;
  lead_number_used?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  number_sent_to?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  read?: InputMaybe<BoolFieldUpdateOperationsInput>;
  read_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  rep?: InputMaybe<UserUpdateOneWithoutSmsItemsInput>;
  sender_id?: InputMaybe<StringFieldUpdateOperationsInput>;
  sent_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  sequence?: InputMaybe<SequenceUpdateOneWithoutSmsItemInput>;
  step_action?: InputMaybe<StepActionUpdateOneWithoutSmsItemInput>;
  template_version?: InputMaybe<TemplateVersionUpdateOneWithoutSmsItemInput>;
  text?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  twilio_delivered_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  twilio_failed_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type SmsItemUpdateWithoutRepInput = {
  channel?: InputMaybe<NullableEnumChannelFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lead_activity?: InputMaybe<LeadActivityUpdateOneWithoutSmsItemInput>;
  lead_number_used?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  number_sent_to?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  Organization?: InputMaybe<OrganizationUpdateOneWithoutSmsItemsInput>;
  read?: InputMaybe<BoolFieldUpdateOperationsInput>;
  read_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  sender_id?: InputMaybe<StringFieldUpdateOperationsInput>;
  sent_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  sequence?: InputMaybe<SequenceUpdateOneWithoutSmsItemInput>;
  step_action?: InputMaybe<StepActionUpdateOneWithoutSmsItemInput>;
  template_version?: InputMaybe<TemplateVersionUpdateOneWithoutSmsItemInput>;
  text?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  twilio_delivered_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  twilio_failed_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type SmsItemUpdateWithoutSequenceInput = {
  channel?: InputMaybe<NullableEnumChannelFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lead_activity?: InputMaybe<LeadActivityUpdateOneWithoutSmsItemInput>;
  lead_number_used?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  number_sent_to?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  Organization?: InputMaybe<OrganizationUpdateOneWithoutSmsItemsInput>;
  read?: InputMaybe<BoolFieldUpdateOperationsInput>;
  read_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  rep?: InputMaybe<UserUpdateOneWithoutSmsItemsInput>;
  sender_id?: InputMaybe<StringFieldUpdateOperationsInput>;
  sent_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  step_action?: InputMaybe<StepActionUpdateOneWithoutSmsItemInput>;
  template_version?: InputMaybe<TemplateVersionUpdateOneWithoutSmsItemInput>;
  text?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  twilio_delivered_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  twilio_failed_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type SmsItemUpdateWithoutStep_ActionInput = {
  channel?: InputMaybe<NullableEnumChannelFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lead_activity?: InputMaybe<LeadActivityUpdateOneWithoutSmsItemInput>;
  lead_number_used?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  number_sent_to?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  Organization?: InputMaybe<OrganizationUpdateOneWithoutSmsItemsInput>;
  read?: InputMaybe<BoolFieldUpdateOperationsInput>;
  read_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  rep?: InputMaybe<UserUpdateOneWithoutSmsItemsInput>;
  sender_id?: InputMaybe<StringFieldUpdateOperationsInput>;
  sent_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  sequence?: InputMaybe<SequenceUpdateOneWithoutSmsItemInput>;
  template_version?: InputMaybe<TemplateVersionUpdateOneWithoutSmsItemInput>;
  text?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  twilio_delivered_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  twilio_failed_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type SmsItemUpdateWithoutTemplate_VersionInput = {
  channel?: InputMaybe<NullableEnumChannelFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lead_activity?: InputMaybe<LeadActivityUpdateOneWithoutSmsItemInput>;
  lead_number_used?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  number_sent_to?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  Organization?: InputMaybe<OrganizationUpdateOneWithoutSmsItemsInput>;
  read?: InputMaybe<BoolFieldUpdateOperationsInput>;
  read_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  rep?: InputMaybe<UserUpdateOneWithoutSmsItemsInput>;
  sender_id?: InputMaybe<StringFieldUpdateOperationsInput>;
  sent_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  sequence?: InputMaybe<SequenceUpdateOneWithoutSmsItemInput>;
  step_action?: InputMaybe<StepActionUpdateOneWithoutSmsItemInput>;
  text?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  twilio_delivered_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  twilio_failed_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type SmsItemUpdateWithWhereUniqueWithoutOrganizationInput = {
  data: SmsItemUpdateWithoutOrganizationInput;
  where: SmsItemWhereUniqueInput;
};

export type SmsItemUpdateWithWhereUniqueWithoutRepInput = {
  data: SmsItemUpdateWithoutRepInput;
  where: SmsItemWhereUniqueInput;
};

export type SmsItemUpdateWithWhereUniqueWithoutSequenceInput = {
  data: SmsItemUpdateWithoutSequenceInput;
  where: SmsItemWhereUniqueInput;
};

export type SmsItemUpdateWithWhereUniqueWithoutStep_ActionInput = {
  data: SmsItemUpdateWithoutStep_ActionInput;
  where: SmsItemWhereUniqueInput;
};

export type SmsItemUpdateWithWhereUniqueWithoutTemplate_VersionInput = {
  data: SmsItemUpdateWithoutTemplate_VersionInput;
  where: SmsItemWhereUniqueInput;
};

export type SmsItemUpsertWithoutLead_ActivityInput = {
  create: SmsItemCreateWithoutLead_ActivityInput;
  update: SmsItemUpdateWithoutLead_ActivityInput;
};

export type SmsItemUpsertWithWhereUniqueWithoutOrganizationInput = {
  create: SmsItemCreateWithoutOrganizationInput;
  update: SmsItemUpdateWithoutOrganizationInput;
  where: SmsItemWhereUniqueInput;
};

export type SmsItemUpsertWithWhereUniqueWithoutRepInput = {
  create: SmsItemCreateWithoutRepInput;
  update: SmsItemUpdateWithoutRepInput;
  where: SmsItemWhereUniqueInput;
};

export type SmsItemUpsertWithWhereUniqueWithoutSequenceInput = {
  create: SmsItemCreateWithoutSequenceInput;
  update: SmsItemUpdateWithoutSequenceInput;
  where: SmsItemWhereUniqueInput;
};

export type SmsItemUpsertWithWhereUniqueWithoutStep_ActionInput = {
  create: SmsItemCreateWithoutStep_ActionInput;
  update: SmsItemUpdateWithoutStep_ActionInput;
  where: SmsItemWhereUniqueInput;
};

export type SmsItemUpsertWithWhereUniqueWithoutTemplate_VersionInput = {
  create: SmsItemCreateWithoutTemplate_VersionInput;
  update: SmsItemUpdateWithoutTemplate_VersionInput;
  where: SmsItemWhereUniqueInput;
};

export type SmsItemWhereInput = {
  AND?: InputMaybe<Array<SmsItemWhereInput>>;
  channel?: InputMaybe<EnumChannelNullableFilter>;
  created_at?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  lead_activity?: InputMaybe<LeadActivityWhereInput>;
  lead_activity_id?: InputMaybe<StringNullableFilter>;
  lead_number_used?: InputMaybe<StringNullableFilter>;
  NOT?: InputMaybe<Array<SmsItemWhereInput>>;
  number_sent_to?: InputMaybe<StringNullableFilter>;
  OR?: InputMaybe<Array<SmsItemWhereInput>>;
  Organization?: InputMaybe<OrganizationWhereInput>;
  organization_id?: InputMaybe<StringNullableFilter>;
  read?: InputMaybe<BoolFilter>;
  read_at?: InputMaybe<DateTimeNullableFilter>;
  rep?: InputMaybe<UserWhereInput>;
  rep_id?: InputMaybe<StringNullableFilter>;
  sender_id?: InputMaybe<StringFilter>;
  sent_at?: InputMaybe<DateTimeFilter>;
  sequence?: InputMaybe<SequenceWhereInput>;
  sequence_id?: InputMaybe<StringNullableFilter>;
  step_action?: InputMaybe<StepActionWhereInput>;
  step_action_id?: InputMaybe<StringNullableFilter>;
  template_version?: InputMaybe<TemplateVersionWhereInput>;
  template_version_id?: InputMaybe<StringNullableFilter>;
  text?: InputMaybe<StringNullableFilter>;
  twilio_delivered_at?: InputMaybe<DateTimeNullableFilter>;
  twilio_failed_at?: InputMaybe<DateTimeNullableFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
};

export type SmsItemWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  lead_activity_id?: InputMaybe<Scalars['String']['input']>;
};

/** Email preview for a given template */
export type SmsPreview = {
  __typename?: 'SMSPreview';
  template_id: Scalars['String']['output'];
  text: Scalars['String']['output'];
};

export type SnoozeTrackingCreateManyOrganizationInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  delay_amount: Scalars['Int']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  rep_id: Scalars['String']['input'];
  sequence_id: Scalars['String']['input'];
  step_action_log_id: Scalars['String']['input'];
  step_id: Scalars['String']['input'];
};

export type SnoozeTrackingCreateManyOrganizationInputEnvelope = {
  data?: InputMaybe<Array<SnoozeTrackingCreateManyOrganizationInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SnoozeTrackingCreateManyRepInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  delay_amount: Scalars['Int']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  organization_id?: InputMaybe<Scalars['String']['input']>;
  sequence_id: Scalars['String']['input'];
  step_action_log_id: Scalars['String']['input'];
  step_id: Scalars['String']['input'];
};

export type SnoozeTrackingCreateManyRepInputEnvelope = {
  data?: InputMaybe<Array<SnoozeTrackingCreateManyRepInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SnoozeTrackingCreateManySequenceInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  delay_amount: Scalars['Int']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  organization_id?: InputMaybe<Scalars['String']['input']>;
  rep_id: Scalars['String']['input'];
  step_action_log_id: Scalars['String']['input'];
  step_id: Scalars['String']['input'];
};

export type SnoozeTrackingCreateManySequenceInputEnvelope = {
  data?: InputMaybe<Array<SnoozeTrackingCreateManySequenceInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SnoozeTrackingCreateManyStepActionLogInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  delay_amount: Scalars['Int']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  organization_id?: InputMaybe<Scalars['String']['input']>;
  rep_id: Scalars['String']['input'];
  sequence_id: Scalars['String']['input'];
  step_id: Scalars['String']['input'];
};

export type SnoozeTrackingCreateManyStepActionLogInputEnvelope = {
  data?: InputMaybe<Array<SnoozeTrackingCreateManyStepActionLogInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SnoozeTrackingCreateManyStepInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  delay_amount: Scalars['Int']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  organization_id?: InputMaybe<Scalars['String']['input']>;
  rep_id: Scalars['String']['input'];
  sequence_id: Scalars['String']['input'];
  step_action_log_id: Scalars['String']['input'];
};

export type SnoozeTrackingCreateManyStepInputEnvelope = {
  data?: InputMaybe<Array<SnoozeTrackingCreateManyStepInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SnoozeTrackingCreateNestedManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<SnoozeTrackingWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SnoozeTrackingCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<SnoozeTrackingCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<SnoozeTrackingCreateManyOrganizationInputEnvelope>;
};

export type SnoozeTrackingCreateNestedManyWithoutRepInput = {
  connect?: InputMaybe<Array<SnoozeTrackingWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SnoozeTrackingCreateOrConnectWithoutRepInput>>;
  create?: InputMaybe<Array<SnoozeTrackingCreateWithoutRepInput>>;
  createMany?: InputMaybe<SnoozeTrackingCreateManyRepInputEnvelope>;
};

export type SnoozeTrackingCreateNestedManyWithoutSequenceInput = {
  connect?: InputMaybe<Array<SnoozeTrackingWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SnoozeTrackingCreateOrConnectWithoutSequenceInput>>;
  create?: InputMaybe<Array<SnoozeTrackingCreateWithoutSequenceInput>>;
  createMany?: InputMaybe<SnoozeTrackingCreateManySequenceInputEnvelope>;
};

export type SnoozeTrackingCreateNestedManyWithoutStepActionLogInput = {
  connect?: InputMaybe<Array<SnoozeTrackingWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SnoozeTrackingCreateOrConnectWithoutStepActionLogInput>>;
  create?: InputMaybe<Array<SnoozeTrackingCreateWithoutStepActionLogInput>>;
  createMany?: InputMaybe<SnoozeTrackingCreateManyStepActionLogInputEnvelope>;
};

export type SnoozeTrackingCreateNestedManyWithoutStepInput = {
  connect?: InputMaybe<Array<SnoozeTrackingWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SnoozeTrackingCreateOrConnectWithoutStepInput>>;
  create?: InputMaybe<Array<SnoozeTrackingCreateWithoutStepInput>>;
  createMany?: InputMaybe<SnoozeTrackingCreateManyStepInputEnvelope>;
};

export type SnoozeTrackingCreateOrConnectWithoutOrganizationInput = {
  create: SnoozeTrackingCreateWithoutOrganizationInput;
  where: SnoozeTrackingWhereUniqueInput;
};

export type SnoozeTrackingCreateOrConnectWithoutRepInput = {
  create: SnoozeTrackingCreateWithoutRepInput;
  where: SnoozeTrackingWhereUniqueInput;
};

export type SnoozeTrackingCreateOrConnectWithoutSequenceInput = {
  create: SnoozeTrackingCreateWithoutSequenceInput;
  where: SnoozeTrackingWhereUniqueInput;
};

export type SnoozeTrackingCreateOrConnectWithoutStepActionLogInput = {
  create: SnoozeTrackingCreateWithoutStepActionLogInput;
  where: SnoozeTrackingWhereUniqueInput;
};

export type SnoozeTrackingCreateOrConnectWithoutStepInput = {
  create: SnoozeTrackingCreateWithoutStepInput;
  where: SnoozeTrackingWhereUniqueInput;
};

export type SnoozeTrackingCreateWithoutOrganizationInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  delay_amount: Scalars['Int']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  rep: UserCreateNestedOneWithoutSnoozeTrackingInput;
  sequence: SequenceCreateNestedOneWithoutSnoozeTrackingInput;
  step: SequenceStepCreateNestedOneWithoutSnoozeTrackingInput;
  stepActionLog: StepActionLogCreateNestedOneWithoutSnooze_LogsInput;
};

export type SnoozeTrackingCreateWithoutRepInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  delay_amount: Scalars['Int']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  Organization?: InputMaybe<OrganizationCreateNestedOneWithoutSnoozeTrackingsInput>;
  sequence: SequenceCreateNestedOneWithoutSnoozeTrackingInput;
  step: SequenceStepCreateNestedOneWithoutSnoozeTrackingInput;
  stepActionLog: StepActionLogCreateNestedOneWithoutSnooze_LogsInput;
};

export type SnoozeTrackingCreateWithoutSequenceInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  delay_amount: Scalars['Int']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  Organization?: InputMaybe<OrganizationCreateNestedOneWithoutSnoozeTrackingsInput>;
  rep: UserCreateNestedOneWithoutSnoozeTrackingInput;
  step: SequenceStepCreateNestedOneWithoutSnoozeTrackingInput;
  stepActionLog: StepActionLogCreateNestedOneWithoutSnooze_LogsInput;
};

export type SnoozeTrackingCreateWithoutStepActionLogInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  delay_amount: Scalars['Int']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  Organization?: InputMaybe<OrganizationCreateNestedOneWithoutSnoozeTrackingsInput>;
  rep: UserCreateNestedOneWithoutSnoozeTrackingInput;
  sequence: SequenceCreateNestedOneWithoutSnoozeTrackingInput;
  step: SequenceStepCreateNestedOneWithoutSnoozeTrackingInput;
};

export type SnoozeTrackingCreateWithoutStepInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  delay_amount: Scalars['Int']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  Organization?: InputMaybe<OrganizationCreateNestedOneWithoutSnoozeTrackingsInput>;
  rep: UserCreateNestedOneWithoutSnoozeTrackingInput;
  sequence: SequenceCreateNestedOneWithoutSnoozeTrackingInput;
  stepActionLog: StepActionLogCreateNestedOneWithoutSnooze_LogsInput;
};

export type SnoozeTrackingListRelationFilter = {
  every?: InputMaybe<SnoozeTrackingWhereInput>;
  none?: InputMaybe<SnoozeTrackingWhereInput>;
  some?: InputMaybe<SnoozeTrackingWhereInput>;
};

export type SnoozeTrackingOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
  count?: InputMaybe<SortOrder>;
};

export type SnoozeTrackingScalarWhereInput = {
  AND?: InputMaybe<Array<SnoozeTrackingScalarWhereInput>>;
  created_at?: InputMaybe<DateTimeFilter>;
  delay_amount?: InputMaybe<IntFilter>;
  id?: InputMaybe<StringFilter>;
  NOT?: InputMaybe<Array<SnoozeTrackingScalarWhereInput>>;
  OR?: InputMaybe<Array<SnoozeTrackingScalarWhereInput>>;
  organization_id?: InputMaybe<StringNullableFilter>;
  rep_id?: InputMaybe<StringFilter>;
  sequence_id?: InputMaybe<StringFilter>;
  step_action_log_id?: InputMaybe<StringFilter>;
  step_id?: InputMaybe<StringFilter>;
};

export type SnoozeTrackingUpdateManyMutationInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  delay_amount?: InputMaybe<IntFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type SnoozeTrackingUpdateManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<SnoozeTrackingWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SnoozeTrackingCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<SnoozeTrackingCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<SnoozeTrackingCreateManyOrganizationInputEnvelope>;
  delete?: InputMaybe<Array<SnoozeTrackingWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<SnoozeTrackingScalarWhereInput>>;
  disconnect?: InputMaybe<Array<SnoozeTrackingWhereUniqueInput>>;
  set?: InputMaybe<Array<SnoozeTrackingWhereUniqueInput>>;
  update?: InputMaybe<Array<SnoozeTrackingUpdateWithWhereUniqueWithoutOrganizationInput>>;
  updateMany?: InputMaybe<Array<SnoozeTrackingUpdateManyWithWhereWithoutOrganizationInput>>;
  upsert?: InputMaybe<Array<SnoozeTrackingUpsertWithWhereUniqueWithoutOrganizationInput>>;
};

export type SnoozeTrackingUpdateManyWithoutRepInput = {
  connect?: InputMaybe<Array<SnoozeTrackingWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SnoozeTrackingCreateOrConnectWithoutRepInput>>;
  create?: InputMaybe<Array<SnoozeTrackingCreateWithoutRepInput>>;
  createMany?: InputMaybe<SnoozeTrackingCreateManyRepInputEnvelope>;
  delete?: InputMaybe<Array<SnoozeTrackingWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<SnoozeTrackingScalarWhereInput>>;
  disconnect?: InputMaybe<Array<SnoozeTrackingWhereUniqueInput>>;
  set?: InputMaybe<Array<SnoozeTrackingWhereUniqueInput>>;
  update?: InputMaybe<Array<SnoozeTrackingUpdateWithWhereUniqueWithoutRepInput>>;
  updateMany?: InputMaybe<Array<SnoozeTrackingUpdateManyWithWhereWithoutRepInput>>;
  upsert?: InputMaybe<Array<SnoozeTrackingUpsertWithWhereUniqueWithoutRepInput>>;
};

export type SnoozeTrackingUpdateManyWithoutSequenceInput = {
  connect?: InputMaybe<Array<SnoozeTrackingWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SnoozeTrackingCreateOrConnectWithoutSequenceInput>>;
  create?: InputMaybe<Array<SnoozeTrackingCreateWithoutSequenceInput>>;
  createMany?: InputMaybe<SnoozeTrackingCreateManySequenceInputEnvelope>;
  delete?: InputMaybe<Array<SnoozeTrackingWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<SnoozeTrackingScalarWhereInput>>;
  disconnect?: InputMaybe<Array<SnoozeTrackingWhereUniqueInput>>;
  set?: InputMaybe<Array<SnoozeTrackingWhereUniqueInput>>;
  update?: InputMaybe<Array<SnoozeTrackingUpdateWithWhereUniqueWithoutSequenceInput>>;
  updateMany?: InputMaybe<Array<SnoozeTrackingUpdateManyWithWhereWithoutSequenceInput>>;
  upsert?: InputMaybe<Array<SnoozeTrackingUpsertWithWhereUniqueWithoutSequenceInput>>;
};

export type SnoozeTrackingUpdateManyWithoutStepActionLogInput = {
  connect?: InputMaybe<Array<SnoozeTrackingWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SnoozeTrackingCreateOrConnectWithoutStepActionLogInput>>;
  create?: InputMaybe<Array<SnoozeTrackingCreateWithoutStepActionLogInput>>;
  createMany?: InputMaybe<SnoozeTrackingCreateManyStepActionLogInputEnvelope>;
  delete?: InputMaybe<Array<SnoozeTrackingWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<SnoozeTrackingScalarWhereInput>>;
  disconnect?: InputMaybe<Array<SnoozeTrackingWhereUniqueInput>>;
  set?: InputMaybe<Array<SnoozeTrackingWhereUniqueInput>>;
  update?: InputMaybe<Array<SnoozeTrackingUpdateWithWhereUniqueWithoutStepActionLogInput>>;
  updateMany?: InputMaybe<Array<SnoozeTrackingUpdateManyWithWhereWithoutStepActionLogInput>>;
  upsert?: InputMaybe<Array<SnoozeTrackingUpsertWithWhereUniqueWithoutStepActionLogInput>>;
};

export type SnoozeTrackingUpdateManyWithoutStepInput = {
  connect?: InputMaybe<Array<SnoozeTrackingWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SnoozeTrackingCreateOrConnectWithoutStepInput>>;
  create?: InputMaybe<Array<SnoozeTrackingCreateWithoutStepInput>>;
  createMany?: InputMaybe<SnoozeTrackingCreateManyStepInputEnvelope>;
  delete?: InputMaybe<Array<SnoozeTrackingWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<SnoozeTrackingScalarWhereInput>>;
  disconnect?: InputMaybe<Array<SnoozeTrackingWhereUniqueInput>>;
  set?: InputMaybe<Array<SnoozeTrackingWhereUniqueInput>>;
  update?: InputMaybe<Array<SnoozeTrackingUpdateWithWhereUniqueWithoutStepInput>>;
  updateMany?: InputMaybe<Array<SnoozeTrackingUpdateManyWithWhereWithoutStepInput>>;
  upsert?: InputMaybe<Array<SnoozeTrackingUpsertWithWhereUniqueWithoutStepInput>>;
};

export type SnoozeTrackingUpdateManyWithWhereWithoutOrganizationInput = {
  data: SnoozeTrackingUpdateManyMutationInput;
  where: SnoozeTrackingScalarWhereInput;
};

export type SnoozeTrackingUpdateManyWithWhereWithoutRepInput = {
  data: SnoozeTrackingUpdateManyMutationInput;
  where: SnoozeTrackingScalarWhereInput;
};

export type SnoozeTrackingUpdateManyWithWhereWithoutSequenceInput = {
  data: SnoozeTrackingUpdateManyMutationInput;
  where: SnoozeTrackingScalarWhereInput;
};

export type SnoozeTrackingUpdateManyWithWhereWithoutStepActionLogInput = {
  data: SnoozeTrackingUpdateManyMutationInput;
  where: SnoozeTrackingScalarWhereInput;
};

export type SnoozeTrackingUpdateManyWithWhereWithoutStepInput = {
  data: SnoozeTrackingUpdateManyMutationInput;
  where: SnoozeTrackingScalarWhereInput;
};

export type SnoozeTrackingUpdateWithoutOrganizationInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  delay_amount?: InputMaybe<IntFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  rep?: InputMaybe<UserUpdateOneRequiredWithoutSnoozeTrackingInput>;
  sequence?: InputMaybe<SequenceUpdateOneRequiredWithoutSnoozeTrackingInput>;
  step?: InputMaybe<SequenceStepUpdateOneRequiredWithoutSnoozeTrackingInput>;
  stepActionLog?: InputMaybe<StepActionLogUpdateOneRequiredWithoutSnooze_LogsInput>;
};

export type SnoozeTrackingUpdateWithoutRepInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  delay_amount?: InputMaybe<IntFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  Organization?: InputMaybe<OrganizationUpdateOneWithoutSnoozeTrackingsInput>;
  sequence?: InputMaybe<SequenceUpdateOneRequiredWithoutSnoozeTrackingInput>;
  step?: InputMaybe<SequenceStepUpdateOneRequiredWithoutSnoozeTrackingInput>;
  stepActionLog?: InputMaybe<StepActionLogUpdateOneRequiredWithoutSnooze_LogsInput>;
};

export type SnoozeTrackingUpdateWithoutSequenceInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  delay_amount?: InputMaybe<IntFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  Organization?: InputMaybe<OrganizationUpdateOneWithoutSnoozeTrackingsInput>;
  rep?: InputMaybe<UserUpdateOneRequiredWithoutSnoozeTrackingInput>;
  step?: InputMaybe<SequenceStepUpdateOneRequiredWithoutSnoozeTrackingInput>;
  stepActionLog?: InputMaybe<StepActionLogUpdateOneRequiredWithoutSnooze_LogsInput>;
};

export type SnoozeTrackingUpdateWithoutStepActionLogInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  delay_amount?: InputMaybe<IntFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  Organization?: InputMaybe<OrganizationUpdateOneWithoutSnoozeTrackingsInput>;
  rep?: InputMaybe<UserUpdateOneRequiredWithoutSnoozeTrackingInput>;
  sequence?: InputMaybe<SequenceUpdateOneRequiredWithoutSnoozeTrackingInput>;
  step?: InputMaybe<SequenceStepUpdateOneRequiredWithoutSnoozeTrackingInput>;
};

export type SnoozeTrackingUpdateWithoutStepInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  delay_amount?: InputMaybe<IntFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  Organization?: InputMaybe<OrganizationUpdateOneWithoutSnoozeTrackingsInput>;
  rep?: InputMaybe<UserUpdateOneRequiredWithoutSnoozeTrackingInput>;
  sequence?: InputMaybe<SequenceUpdateOneRequiredWithoutSnoozeTrackingInput>;
  stepActionLog?: InputMaybe<StepActionLogUpdateOneRequiredWithoutSnooze_LogsInput>;
};

export type SnoozeTrackingUpdateWithWhereUniqueWithoutOrganizationInput = {
  data: SnoozeTrackingUpdateWithoutOrganizationInput;
  where: SnoozeTrackingWhereUniqueInput;
};

export type SnoozeTrackingUpdateWithWhereUniqueWithoutRepInput = {
  data: SnoozeTrackingUpdateWithoutRepInput;
  where: SnoozeTrackingWhereUniqueInput;
};

export type SnoozeTrackingUpdateWithWhereUniqueWithoutSequenceInput = {
  data: SnoozeTrackingUpdateWithoutSequenceInput;
  where: SnoozeTrackingWhereUniqueInput;
};

export type SnoozeTrackingUpdateWithWhereUniqueWithoutStepActionLogInput = {
  data: SnoozeTrackingUpdateWithoutStepActionLogInput;
  where: SnoozeTrackingWhereUniqueInput;
};

export type SnoozeTrackingUpdateWithWhereUniqueWithoutStepInput = {
  data: SnoozeTrackingUpdateWithoutStepInput;
  where: SnoozeTrackingWhereUniqueInput;
};

export type SnoozeTrackingUpsertWithWhereUniqueWithoutOrganizationInput = {
  create: SnoozeTrackingCreateWithoutOrganizationInput;
  update: SnoozeTrackingUpdateWithoutOrganizationInput;
  where: SnoozeTrackingWhereUniqueInput;
};

export type SnoozeTrackingUpsertWithWhereUniqueWithoutRepInput = {
  create: SnoozeTrackingCreateWithoutRepInput;
  update: SnoozeTrackingUpdateWithoutRepInput;
  where: SnoozeTrackingWhereUniqueInput;
};

export type SnoozeTrackingUpsertWithWhereUniqueWithoutSequenceInput = {
  create: SnoozeTrackingCreateWithoutSequenceInput;
  update: SnoozeTrackingUpdateWithoutSequenceInput;
  where: SnoozeTrackingWhereUniqueInput;
};

export type SnoozeTrackingUpsertWithWhereUniqueWithoutStepActionLogInput = {
  create: SnoozeTrackingCreateWithoutStepActionLogInput;
  update: SnoozeTrackingUpdateWithoutStepActionLogInput;
  where: SnoozeTrackingWhereUniqueInput;
};

export type SnoozeTrackingUpsertWithWhereUniqueWithoutStepInput = {
  create: SnoozeTrackingCreateWithoutStepInput;
  update: SnoozeTrackingUpdateWithoutStepInput;
  where: SnoozeTrackingWhereUniqueInput;
};

export type SnoozeTrackingWhereInput = {
  AND?: InputMaybe<Array<SnoozeTrackingWhereInput>>;
  created_at?: InputMaybe<DateTimeFilter>;
  delay_amount?: InputMaybe<IntFilter>;
  id?: InputMaybe<StringFilter>;
  NOT?: InputMaybe<Array<SnoozeTrackingWhereInput>>;
  OR?: InputMaybe<Array<SnoozeTrackingWhereInput>>;
  Organization?: InputMaybe<OrganizationWhereInput>;
  organization_id?: InputMaybe<StringNullableFilter>;
  rep?: InputMaybe<UserWhereInput>;
  rep_id?: InputMaybe<StringFilter>;
  sequence?: InputMaybe<SequenceWhereInput>;
  sequence_id?: InputMaybe<StringFilter>;
  step?: InputMaybe<SequenceStepWhereInput>;
  step_action_log_id?: InputMaybe<StringFilter>;
  step_id?: InputMaybe<StringFilter>;
  stepActionLog?: InputMaybe<StepActionLogWhereInput>;
};

export type SnoozeTrackingWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']['input']>;
};

export enum SortOrder {
  Asc = 'asc',
  Desc = 'desc'
}

export enum State {
  Aa = 'AA',
  Ab = 'AB',
  Ae = 'AE',
  Ak = 'AK',
  Al = 'AL',
  Ap = 'AP',
  Ar = 'AR',
  As = 'AS',
  Az = 'AZ',
  Bc = 'BC',
  Ca = 'CA',
  Co = 'CO',
  Ct = 'CT',
  Dc = 'DC',
  De = 'DE',
  Fl = 'FL',
  Ga = 'GA',
  Gu = 'GU',
  Hi = 'HI',
  Ia = 'IA',
  Id = 'ID',
  Il = 'IL',
  In = 'IN',
  Ks = 'KS',
  Ky = 'KY',
  La = 'LA',
  Ma = 'MA',
  Mb = 'MB',
  Md = 'MD',
  Me = 'ME',
  Mi = 'MI',
  Mn = 'MN',
  Mo = 'MO',
  Ms = 'MS',
  Mt = 'MT',
  Nb = 'NB',
  Nc = 'NC',
  Nd = 'ND',
  Ne = 'NE',
  Nf = 'NF',
  Nh = 'NH',
  Nj = 'NJ',
  Nm = 'NM',
  Ns = 'NS',
  Nt = 'NT',
  Nu = 'NU',
  Nv = 'NV',
  Ny = 'NY',
  Oh = 'OH',
  Ok = 'OK',
  On = 'ON',
  Or = 'OR',
  Pa = 'PA',
  Pe = 'PE',
  Pr = 'PR',
  Qc = 'QC',
  Ri = 'RI',
  Sc = 'SC',
  Sd = 'SD',
  Sk = 'SK',
  Tn = 'TN',
  Tx = 'TX',
  Ut = 'UT',
  Va = 'VA',
  Vi = 'VI',
  Vt = 'VT',
  Wa = 'WA',
  Wi = 'WI',
  Wv = 'WV',
  Wy = 'WY',
  Yt = 'YT'
}

/**
 * Used to calculate statistics, such as connect rate, set rates, etc.
 *   This is commonly used for the drilldown UI. Make sure to only specify one of the following:
 *   user_id, organization_id, or team_id. You must specify exactly one of them.
 *   In addition, specify a valid metric as per the METRIC enum.
 *   Comming soon: Support for day.
 *
 */
export type StatItem = {
  __typename?: 'StatItem';
  best_all_time_value?: Maybe<Scalars['Float']['output']>;
  /**
   * A channel to filter the metrics on
   * @deprecated Channel filter will no longer be use
   */
  channel_filter?: Maybe<Channel>;
  /** Represents one of the following: user_id, team_id, organization_id. Which ever is used. */
  computed_id?: Maybe<Scalars['String']['output']>;
  day?: Maybe<Scalars['Date']['output']>;
  formatted_day?: Maybe<Scalars['String']['output']>;
  /** Use this if you want to override the default x-axis label on the 30 day graph */
  graph_x_label_override?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  is_ordinal?: Maybe<Scalars['Boolean']['output']>;
  list_rank?: Maybe<Scalars['Int']['output']>;
  list_value?: Maybe<Scalars['Float']['output']>;
  lowerbound_date?: Maybe<Scalars['Date']['output']>;
  metric?: Maybe<Scalars['String']['output']>;
  metric_label?: Maybe<Scalars['String']['output']>;
  metric_tooltip?: Maybe<Scalars['String']['output']>;
  /** Used for formatting on the front-end. Possible values: Number, Percentage, or Dollar */
  metric_type?: Maybe<Scalars['String']['output']>;
  metric_type_override?: Maybe<Scalars['String']['output']>;
  organization_id?: Maybe<Scalars['String']['output']>;
  position?: Maybe<Scalars['String']['output']>;
  /** A list of product_ids to filter on if applicable. */
  product_ids_filter?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  profile_image?: Maybe<Scalars['String']['output']>;
  /** Used if a rank is to be specified. Otherwise, a metric is calculated. */
  rank?: Maybe<Scalars['Boolean']['output']>;
  role?: Maybe<Scalars['String']['output']>;
  row_label?: Maybe<Scalars['String']['output']>;
  /** Use this if you want to override the default row_label */
  row_label_override?: Maybe<Scalars['String']['output']>;
  /** If selected in the stack rank list ranking */
  selected?: Maybe<Scalars['Boolean']['output']>;
  site_id?: Maybe<Scalars['String']['output']>;
  team_id?: Maybe<Scalars['String']['output']>;
  upperbound_date?: Maybe<Scalars['Date']['output']>;
  user_id?: Maybe<Scalars['String']['output']>;
  /** Calculates the metric based on user, team, or organization. If rank is true, value returns the rank rather than the metric. */
  value?: Maybe<Scalars['Float']['output']>;
};

export type StatItemAggregation = {
  __typename?: 'StatItemAggregation';
  bad_number: Scalars['Int']['output'];
  channel?: Maybe<Channel>;
  closing_opp_held: Scalars['Int']['output'];
  closing_opp_scheduled: Scalars['Int']['output'];
  closing_opps_pipeline: Scalars['Int']['output'];
  cold_call: Scalars['Int']['output'];
  connect: Scalars['Int']['output'];
  connect_talk_time: Scalars['Int']['output'];
  created_at: Scalars['DateTime']['output'];
  date: Scalars['DateTime']['output'];
  date_group_key: Scalars['String']['output'];
  day: Scalars['Int']['output'];
  days_set_out?: Maybe<Scalars['Float']['output']>;
  demo_talk_time: Scalars['Int']['output'];
  dial: Scalars['Int']['output'];
  dm_cold_call: Scalars['Int']['output'];
  dm_connect: Scalars['Int']['output'];
  dm_connect_date?: Maybe<Scalars['DateTime']['output']>;
  followup_hold: Scalars['Int']['output'];
  hold: Scalars['Int']['output'];
  hold_date?: Maybe<Scalars['DateTime']['output']>;
  hour: Scalars['Int']['output'];
  hour_minute: Scalars['Int']['output'];
  id: Scalars['String']['output'];
  initial_hold: Scalars['Int']['output'];
  initial_pipeline: Scalars['Int']['output'];
  initial_set: Scalars['Int']['output'];
  is_stat_from_transfer: Scalars['Int']['output'];
  lead?: Maybe<Lead>;
  lead_activity?: Maybe<LeadActivity>;
  lead_activity_id?: Maybe<Scalars['String']['output']>;
  lead_activity_pipeline?: Maybe<LeadActivity>;
  lead_activity_pipeline_id?: Maybe<Scalars['String']['output']>;
  lead_id?: Maybe<Scalars['String']['output']>;
  month_group_key: Scalars['String']['output'];
  ndm_cold_call: Scalars['Int']['output'];
  ndm_connect: Scalars['Int']['output'];
  organization_id?: Maybe<Scalars['String']['output']>;
  otf: Scalars['Int']['output'];
  pipeline: Scalars['Int']['output'];
  pipeline_needs_decision: Scalars['Int']['output'];
  recurring_revenue: Scalars['Float']['output'];
  rescheduled_hold: Scalars['Int']['output'];
  rescheduled_pipeline: Scalars['Int']['output'];
  rescheduled_set: Scalars['Int']['output'];
  revenue: Scalars['Float']['output'];
  revenue_value: Scalars['Float']['output'];
  sale: Scalars['Int']['output'];
  sale_closing_opportunity: Scalars['Int']['output'];
  sale_date?: Maybe<Scalars['DateTime']['output']>;
  schedule_item_id?: Maybe<Scalars['String']['output']>;
  scheduled_item?: Maybe<ScheduleItem>;
  set: Scalars['Int']['output'];
  set_date?: Maybe<Scalars['DateTime']['output']>;
  set_site_id?: Maybe<Scalars['String']['output']>;
  set_team_id?: Maybe<Scalars['String']['output']>;
  set_user_id?: Maybe<Scalars['String']['output']>;
  site_id?: Maybe<Scalars['String']['output']>;
  talk_time: Scalars['Int']['output'];
  team_id?: Maybe<Scalars['String']['output']>;
  transfer_answered: Scalars['Int']['output'];
  transfer_ignored: Scalars['Int']['output'];
  type: StatItemAggregationType;
  unique_lead_pipeline: Scalars['Int']['output'];
  unique_rescheduled_set: Scalars['Int']['output'];
  updated_at: Scalars['DateTime']['output'];
  user_id?: Maybe<Scalars['String']['output']>;
  user_role?: Maybe<Role>;
  value: Scalars['Float']['output'];
  week_group_key: Scalars['String']['output'];
};

export type StatItemAggregationCreateManyLeadInput = {
  bad_number?: InputMaybe<Scalars['Int']['input']>;
  call_followup?: InputMaybe<Scalars['Int']['input']>;
  channel?: InputMaybe<Channel>;
  closing_opp_held?: InputMaybe<Scalars['Int']['input']>;
  closing_opp_scheduled?: InputMaybe<Scalars['Int']['input']>;
  closing_opps_pipeline?: InputMaybe<Scalars['Int']['input']>;
  cold_call?: InputMaybe<Scalars['Int']['input']>;
  connect?: InputMaybe<Scalars['Int']['input']>;
  connect_talk_time?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  date: Scalars['DateTime']['input'];
  date_group_key?: InputMaybe<Scalars['String']['input']>;
  day: Scalars['Int']['input'];
  days_contact_to_set?: InputMaybe<Scalars['Int']['input']>;
  days_decision_call_to_sale?: InputMaybe<Scalars['Int']['input']>;
  days_first_dial_to_contact?: InputMaybe<Scalars['Int']['input']>;
  days_hold_to_decision_call?: InputMaybe<Scalars['Int']['input']>;
  days_hold_to_sale?: InputMaybe<Scalars['Int']['input']>;
  days_set_out?: InputMaybe<Scalars['Float']['input']>;
  days_set_to_hold?: InputMaybe<Scalars['Int']['input']>;
  days_to_close?: InputMaybe<Scalars['Int']['input']>;
  decision_call?: InputMaybe<Scalars['Int']['input']>;
  decision_call_held?: InputMaybe<Scalars['Int']['input']>;
  decision_call_set?: InputMaybe<Scalars['Int']['input']>;
  decision_call_set_not_held?: InputMaybe<Scalars['Int']['input']>;
  demo_held?: InputMaybe<Scalars['Int']['input']>;
  demo_set_not_held?: InputMaybe<Scalars['Int']['input']>;
  demo_talk_time?: InputMaybe<Scalars['Int']['input']>;
  dial?: InputMaybe<Scalars['Int']['input']>;
  dials_to_set?: InputMaybe<Scalars['Int']['input']>;
  dm_cold_call?: InputMaybe<Scalars['Int']['input']>;
  dm_connect?: InputMaybe<Scalars['Int']['input']>;
  dm_connect_date?: InputMaybe<Scalars['DateTime']['input']>;
  dm_set?: InputMaybe<Scalars['Int']['input']>;
  downtime_before_activity?: InputMaybe<Scalars['Int']['input']>;
  downtime_before_call?: InputMaybe<Scalars['Int']['input']>;
  email_received?: InputMaybe<Scalars['Int']['input']>;
  email_sent?: InputMaybe<Scalars['Int']['input']>;
  first_dial_hour_minute?: InputMaybe<Scalars['Int']['input']>;
  followup_hold?: InputMaybe<Scalars['Int']['input']>;
  general_callback_held?: InputMaybe<Scalars['Int']['input']>;
  general_callback_set_not_held?: InputMaybe<Scalars['Int']['input']>;
  hold?: InputMaybe<Scalars['Int']['input']>;
  hold_date?: InputMaybe<Scalars['DateTime']['input']>;
  hour: Scalars['Int']['input'];
  hour_minute?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  inbound_time_to_first_dial?: InputMaybe<Scalars['Int']['input']>;
  initial_hold?: InputMaybe<Scalars['Int']['input']>;
  initial_pipeline?: InputMaybe<Scalars['Int']['input']>;
  initial_set?: InputMaybe<Scalars['Int']['input']>;
  is_stat_from_transfer?: InputMaybe<Scalars['Int']['input']>;
  lead_activity_id?: InputMaybe<Scalars['String']['input']>;
  lead_activity_pipeline_id?: InputMaybe<Scalars['String']['input']>;
  live_transfer?: InputMaybe<Scalars['Int']['input']>;
  live_transfer_hold?: InputMaybe<Scalars['Int']['input']>;
  live_transfer_sale?: InputMaybe<Scalars['Int']['input']>;
  month_group_key?: InputMaybe<Scalars['String']['input']>;
  ndm_cold_call?: InputMaybe<Scalars['Int']['input']>;
  ndm_connect?: InputMaybe<Scalars['Int']['input']>;
  ndm_set?: InputMaybe<Scalars['Int']['input']>;
  next_phase?: InputMaybe<Phase>;
  organization_id?: InputMaybe<Scalars['String']['input']>;
  otf?: InputMaybe<Scalars['Int']['input']>;
  pipeline?: InputMaybe<Scalars['Int']['input']>;
  pipeline_needs_decision?: InputMaybe<Scalars['Int']['input']>;
  previous_phase?: InputMaybe<Phase>;
  recurring_revenue?: InputMaybe<Scalars['Float']['input']>;
  release?: InputMaybe<Scalars['Int']['input']>;
  rescheduled_hold?: InputMaybe<Scalars['Int']['input']>;
  rescheduled_pipeline?: InputMaybe<Scalars['Int']['input']>;
  rescheduled_set?: InputMaybe<Scalars['Int']['input']>;
  revenue?: InputMaybe<Scalars['Float']['input']>;
  revenue_value?: InputMaybe<Scalars['Float']['input']>;
  sale?: InputMaybe<Scalars['Int']['input']>;
  sale_closing_opportunity?: InputMaybe<Scalars['Int']['input']>;
  sale_date?: InputMaybe<Scalars['DateTime']['input']>;
  sales_cycle_num?: InputMaybe<Scalars['Int']['input']>;
  schedule_callback_held?: InputMaybe<Scalars['Int']['input']>;
  schedule_callback_set_not_held?: InputMaybe<Scalars['Int']['input']>;
  schedule_item_id?: InputMaybe<Scalars['String']['input']>;
  sdr_hold?: InputMaybe<Scalars['Int']['input']>;
  sdr_revenue?: InputMaybe<Scalars['Int']['input']>;
  sdr_sale?: InputMaybe<Scalars['Int']['input']>;
  sdr_transfer?: InputMaybe<Scalars['Int']['input']>;
  sequence_advance?: InputMaybe<Scalars['Int']['input']>;
  sequence_attributed_hold?: InputMaybe<Scalars['Int']['input']>;
  sequence_id?: InputMaybe<Scalars['String']['input']>;
  sequence_step_id?: InputMaybe<Scalars['String']['input']>;
  set?: InputMaybe<Scalars['Int']['input']>;
  set_date?: InputMaybe<Scalars['DateTime']['input']>;
  set_site_id?: InputMaybe<Scalars['String']['input']>;
  set_team_id?: InputMaybe<Scalars['String']['input']>;
  set_user_id?: InputMaybe<Scalars['String']['input']>;
  site_id?: InputMaybe<Scalars['String']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sms_received?: InputMaybe<Scalars['Int']['input']>;
  sms_sent?: InputMaybe<Scalars['Int']['input']>;
  talk_time?: InputMaybe<Scalars['Int']['input']>;
  team_id?: InputMaybe<Scalars['String']['input']>;
  ten_minute_downtime_before_activity?: InputMaybe<Scalars['Int']['input']>;
  ten_minute_downtime_before_call?: InputMaybe<Scalars['Int']['input']>;
  time_in_last_phase_sec?: InputMaybe<Scalars['Int']['input']>;
  transfer_answered?: InputMaybe<Scalars['Int']['input']>;
  transfer_answered_ae?: InputMaybe<Scalars['Int']['input']>;
  transfer_answered_ae_unique?: InputMaybe<Scalars['Int']['input']>;
  transfer_answered_sdr?: InputMaybe<Scalars['Int']['input']>;
  transfer_answered_sdr_unique?: InputMaybe<Scalars['Int']['input']>;
  transfer_answered_unique?: InputMaybe<Scalars['Int']['input']>;
  transfer_call_duration_over_x_min?: InputMaybe<Scalars['Int']['input']>;
  transfer_call_duration_over_x_min_ae?: InputMaybe<Scalars['Int']['input']>;
  transfer_call_duration_over_x_min_ae_unique?: InputMaybe<Scalars['Int']['input']>;
  transfer_call_duration_over_x_min_sdr?: InputMaybe<Scalars['Int']['input']>;
  transfer_call_duration_over_x_min_sdr_unique?: InputMaybe<Scalars['Int']['input']>;
  transfer_call_duration_over_x_min_unique?: InputMaybe<Scalars['Int']['input']>;
  transfer_ignored?: InputMaybe<Scalars['Int']['input']>;
  transfer_ignored_ae?: InputMaybe<Scalars['Int']['input']>;
  transfer_ignored_ae_unique?: InputMaybe<Scalars['Int']['input']>;
  transfer_ignored_sdr?: InputMaybe<Scalars['Int']['input']>;
  transfer_ignored_sdr_unique?: InputMaybe<Scalars['Int']['input']>;
  transfer_ignored_unique?: InputMaybe<Scalars['Int']['input']>;
  transfer_ring_time?: InputMaybe<Scalars['Int']['input']>;
  transfer_ring_time_ae?: InputMaybe<Scalars['Int']['input']>;
  transfer_site_id?: InputMaybe<Scalars['String']['input']>;
  transfer_site_to_id?: InputMaybe<Scalars['String']['input']>;
  transfer_team_id?: InputMaybe<Scalars['String']['input']>;
  transfer_team_to_id?: InputMaybe<Scalars['String']['input']>;
  transfer_user_id?: InputMaybe<Scalars['String']['input']>;
  transfer_user_to_id?: InputMaybe<Scalars['String']['input']>;
  transfered_leads?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<StatItemAggregationType>;
  unique_lead_pipeline?: InputMaybe<Scalars['Int']['input']>;
  unique_rescheduled_set?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
  user_role?: InputMaybe<Role>;
  value?: InputMaybe<Scalars['Float']['input']>;
  voicemail_or_no_contact?: InputMaybe<Scalars['Int']['input']>;
  week_group_key?: InputMaybe<Scalars['String']['input']>;
};

export type StatItemAggregationCreateManyLeadInputEnvelope = {
  data?: InputMaybe<Array<StatItemAggregationCreateManyLeadInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type StatItemAggregationCreateManySequenceInput = {
  bad_number?: InputMaybe<Scalars['Int']['input']>;
  call_followup?: InputMaybe<Scalars['Int']['input']>;
  channel?: InputMaybe<Channel>;
  closing_opp_held?: InputMaybe<Scalars['Int']['input']>;
  closing_opp_scheduled?: InputMaybe<Scalars['Int']['input']>;
  closing_opps_pipeline?: InputMaybe<Scalars['Int']['input']>;
  cold_call?: InputMaybe<Scalars['Int']['input']>;
  connect?: InputMaybe<Scalars['Int']['input']>;
  connect_talk_time?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  date: Scalars['DateTime']['input'];
  date_group_key?: InputMaybe<Scalars['String']['input']>;
  day: Scalars['Int']['input'];
  days_contact_to_set?: InputMaybe<Scalars['Int']['input']>;
  days_decision_call_to_sale?: InputMaybe<Scalars['Int']['input']>;
  days_first_dial_to_contact?: InputMaybe<Scalars['Int']['input']>;
  days_hold_to_decision_call?: InputMaybe<Scalars['Int']['input']>;
  days_hold_to_sale?: InputMaybe<Scalars['Int']['input']>;
  days_set_out?: InputMaybe<Scalars['Float']['input']>;
  days_set_to_hold?: InputMaybe<Scalars['Int']['input']>;
  days_to_close?: InputMaybe<Scalars['Int']['input']>;
  decision_call?: InputMaybe<Scalars['Int']['input']>;
  decision_call_held?: InputMaybe<Scalars['Int']['input']>;
  decision_call_set?: InputMaybe<Scalars['Int']['input']>;
  decision_call_set_not_held?: InputMaybe<Scalars['Int']['input']>;
  demo_held?: InputMaybe<Scalars['Int']['input']>;
  demo_set_not_held?: InputMaybe<Scalars['Int']['input']>;
  demo_talk_time?: InputMaybe<Scalars['Int']['input']>;
  dial?: InputMaybe<Scalars['Int']['input']>;
  dials_to_set?: InputMaybe<Scalars['Int']['input']>;
  dm_cold_call?: InputMaybe<Scalars['Int']['input']>;
  dm_connect?: InputMaybe<Scalars['Int']['input']>;
  dm_connect_date?: InputMaybe<Scalars['DateTime']['input']>;
  dm_set?: InputMaybe<Scalars['Int']['input']>;
  downtime_before_activity?: InputMaybe<Scalars['Int']['input']>;
  downtime_before_call?: InputMaybe<Scalars['Int']['input']>;
  email_received?: InputMaybe<Scalars['Int']['input']>;
  email_sent?: InputMaybe<Scalars['Int']['input']>;
  first_dial_hour_minute?: InputMaybe<Scalars['Int']['input']>;
  followup_hold?: InputMaybe<Scalars['Int']['input']>;
  general_callback_held?: InputMaybe<Scalars['Int']['input']>;
  general_callback_set_not_held?: InputMaybe<Scalars['Int']['input']>;
  hold?: InputMaybe<Scalars['Int']['input']>;
  hold_date?: InputMaybe<Scalars['DateTime']['input']>;
  hour: Scalars['Int']['input'];
  hour_minute?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  inbound_time_to_first_dial?: InputMaybe<Scalars['Int']['input']>;
  initial_hold?: InputMaybe<Scalars['Int']['input']>;
  initial_pipeline?: InputMaybe<Scalars['Int']['input']>;
  initial_set?: InputMaybe<Scalars['Int']['input']>;
  is_stat_from_transfer?: InputMaybe<Scalars['Int']['input']>;
  lead_activity_id?: InputMaybe<Scalars['String']['input']>;
  lead_activity_pipeline_id?: InputMaybe<Scalars['String']['input']>;
  lead_id?: InputMaybe<Scalars['String']['input']>;
  live_transfer?: InputMaybe<Scalars['Int']['input']>;
  live_transfer_hold?: InputMaybe<Scalars['Int']['input']>;
  live_transfer_sale?: InputMaybe<Scalars['Int']['input']>;
  month_group_key?: InputMaybe<Scalars['String']['input']>;
  ndm_cold_call?: InputMaybe<Scalars['Int']['input']>;
  ndm_connect?: InputMaybe<Scalars['Int']['input']>;
  ndm_set?: InputMaybe<Scalars['Int']['input']>;
  next_phase?: InputMaybe<Phase>;
  organization_id?: InputMaybe<Scalars['String']['input']>;
  otf?: InputMaybe<Scalars['Int']['input']>;
  pipeline?: InputMaybe<Scalars['Int']['input']>;
  pipeline_needs_decision?: InputMaybe<Scalars['Int']['input']>;
  previous_phase?: InputMaybe<Phase>;
  recurring_revenue?: InputMaybe<Scalars['Float']['input']>;
  release?: InputMaybe<Scalars['Int']['input']>;
  rescheduled_hold?: InputMaybe<Scalars['Int']['input']>;
  rescheduled_pipeline?: InputMaybe<Scalars['Int']['input']>;
  rescheduled_set?: InputMaybe<Scalars['Int']['input']>;
  revenue?: InputMaybe<Scalars['Float']['input']>;
  revenue_value?: InputMaybe<Scalars['Float']['input']>;
  sale?: InputMaybe<Scalars['Int']['input']>;
  sale_closing_opportunity?: InputMaybe<Scalars['Int']['input']>;
  sale_date?: InputMaybe<Scalars['DateTime']['input']>;
  sales_cycle_num?: InputMaybe<Scalars['Int']['input']>;
  schedule_callback_held?: InputMaybe<Scalars['Int']['input']>;
  schedule_callback_set_not_held?: InputMaybe<Scalars['Int']['input']>;
  schedule_item_id?: InputMaybe<Scalars['String']['input']>;
  sdr_hold?: InputMaybe<Scalars['Int']['input']>;
  sdr_revenue?: InputMaybe<Scalars['Int']['input']>;
  sdr_sale?: InputMaybe<Scalars['Int']['input']>;
  sdr_transfer?: InputMaybe<Scalars['Int']['input']>;
  sequence_advance?: InputMaybe<Scalars['Int']['input']>;
  sequence_attributed_hold?: InputMaybe<Scalars['Int']['input']>;
  sequence_step_id?: InputMaybe<Scalars['String']['input']>;
  set?: InputMaybe<Scalars['Int']['input']>;
  set_date?: InputMaybe<Scalars['DateTime']['input']>;
  set_site_id?: InputMaybe<Scalars['String']['input']>;
  set_team_id?: InputMaybe<Scalars['String']['input']>;
  set_user_id?: InputMaybe<Scalars['String']['input']>;
  site_id?: InputMaybe<Scalars['String']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sms_received?: InputMaybe<Scalars['Int']['input']>;
  sms_sent?: InputMaybe<Scalars['Int']['input']>;
  talk_time?: InputMaybe<Scalars['Int']['input']>;
  team_id?: InputMaybe<Scalars['String']['input']>;
  ten_minute_downtime_before_activity?: InputMaybe<Scalars['Int']['input']>;
  ten_minute_downtime_before_call?: InputMaybe<Scalars['Int']['input']>;
  time_in_last_phase_sec?: InputMaybe<Scalars['Int']['input']>;
  transfer_answered?: InputMaybe<Scalars['Int']['input']>;
  transfer_answered_ae?: InputMaybe<Scalars['Int']['input']>;
  transfer_answered_ae_unique?: InputMaybe<Scalars['Int']['input']>;
  transfer_answered_sdr?: InputMaybe<Scalars['Int']['input']>;
  transfer_answered_sdr_unique?: InputMaybe<Scalars['Int']['input']>;
  transfer_answered_unique?: InputMaybe<Scalars['Int']['input']>;
  transfer_call_duration_over_x_min?: InputMaybe<Scalars['Int']['input']>;
  transfer_call_duration_over_x_min_ae?: InputMaybe<Scalars['Int']['input']>;
  transfer_call_duration_over_x_min_ae_unique?: InputMaybe<Scalars['Int']['input']>;
  transfer_call_duration_over_x_min_sdr?: InputMaybe<Scalars['Int']['input']>;
  transfer_call_duration_over_x_min_sdr_unique?: InputMaybe<Scalars['Int']['input']>;
  transfer_call_duration_over_x_min_unique?: InputMaybe<Scalars['Int']['input']>;
  transfer_ignored?: InputMaybe<Scalars['Int']['input']>;
  transfer_ignored_ae?: InputMaybe<Scalars['Int']['input']>;
  transfer_ignored_ae_unique?: InputMaybe<Scalars['Int']['input']>;
  transfer_ignored_sdr?: InputMaybe<Scalars['Int']['input']>;
  transfer_ignored_sdr_unique?: InputMaybe<Scalars['Int']['input']>;
  transfer_ignored_unique?: InputMaybe<Scalars['Int']['input']>;
  transfer_ring_time?: InputMaybe<Scalars['Int']['input']>;
  transfer_ring_time_ae?: InputMaybe<Scalars['Int']['input']>;
  transfer_site_id?: InputMaybe<Scalars['String']['input']>;
  transfer_site_to_id?: InputMaybe<Scalars['String']['input']>;
  transfer_team_id?: InputMaybe<Scalars['String']['input']>;
  transfer_team_to_id?: InputMaybe<Scalars['String']['input']>;
  transfer_user_id?: InputMaybe<Scalars['String']['input']>;
  transfer_user_to_id?: InputMaybe<Scalars['String']['input']>;
  transfered_leads?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<StatItemAggregationType>;
  unique_lead_pipeline?: InputMaybe<Scalars['Int']['input']>;
  unique_rescheduled_set?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
  user_role?: InputMaybe<Role>;
  value?: InputMaybe<Scalars['Float']['input']>;
  voicemail_or_no_contact?: InputMaybe<Scalars['Int']['input']>;
  week_group_key?: InputMaybe<Scalars['String']['input']>;
};

export type StatItemAggregationCreateManySequenceInputEnvelope = {
  data?: InputMaybe<Array<StatItemAggregationCreateManySequenceInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type StatItemAggregationCreateManyStepInput = {
  bad_number?: InputMaybe<Scalars['Int']['input']>;
  call_followup?: InputMaybe<Scalars['Int']['input']>;
  channel?: InputMaybe<Channel>;
  closing_opp_held?: InputMaybe<Scalars['Int']['input']>;
  closing_opp_scheduled?: InputMaybe<Scalars['Int']['input']>;
  closing_opps_pipeline?: InputMaybe<Scalars['Int']['input']>;
  cold_call?: InputMaybe<Scalars['Int']['input']>;
  connect?: InputMaybe<Scalars['Int']['input']>;
  connect_talk_time?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  date: Scalars['DateTime']['input'];
  date_group_key?: InputMaybe<Scalars['String']['input']>;
  day: Scalars['Int']['input'];
  days_contact_to_set?: InputMaybe<Scalars['Int']['input']>;
  days_decision_call_to_sale?: InputMaybe<Scalars['Int']['input']>;
  days_first_dial_to_contact?: InputMaybe<Scalars['Int']['input']>;
  days_hold_to_decision_call?: InputMaybe<Scalars['Int']['input']>;
  days_hold_to_sale?: InputMaybe<Scalars['Int']['input']>;
  days_set_out?: InputMaybe<Scalars['Float']['input']>;
  days_set_to_hold?: InputMaybe<Scalars['Int']['input']>;
  days_to_close?: InputMaybe<Scalars['Int']['input']>;
  decision_call?: InputMaybe<Scalars['Int']['input']>;
  decision_call_held?: InputMaybe<Scalars['Int']['input']>;
  decision_call_set?: InputMaybe<Scalars['Int']['input']>;
  decision_call_set_not_held?: InputMaybe<Scalars['Int']['input']>;
  demo_held?: InputMaybe<Scalars['Int']['input']>;
  demo_set_not_held?: InputMaybe<Scalars['Int']['input']>;
  demo_talk_time?: InputMaybe<Scalars['Int']['input']>;
  dial?: InputMaybe<Scalars['Int']['input']>;
  dials_to_set?: InputMaybe<Scalars['Int']['input']>;
  dm_cold_call?: InputMaybe<Scalars['Int']['input']>;
  dm_connect?: InputMaybe<Scalars['Int']['input']>;
  dm_connect_date?: InputMaybe<Scalars['DateTime']['input']>;
  dm_set?: InputMaybe<Scalars['Int']['input']>;
  downtime_before_activity?: InputMaybe<Scalars['Int']['input']>;
  downtime_before_call?: InputMaybe<Scalars['Int']['input']>;
  email_received?: InputMaybe<Scalars['Int']['input']>;
  email_sent?: InputMaybe<Scalars['Int']['input']>;
  first_dial_hour_minute?: InputMaybe<Scalars['Int']['input']>;
  followup_hold?: InputMaybe<Scalars['Int']['input']>;
  general_callback_held?: InputMaybe<Scalars['Int']['input']>;
  general_callback_set_not_held?: InputMaybe<Scalars['Int']['input']>;
  hold?: InputMaybe<Scalars['Int']['input']>;
  hold_date?: InputMaybe<Scalars['DateTime']['input']>;
  hour: Scalars['Int']['input'];
  hour_minute?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  inbound_time_to_first_dial?: InputMaybe<Scalars['Int']['input']>;
  initial_hold?: InputMaybe<Scalars['Int']['input']>;
  initial_pipeline?: InputMaybe<Scalars['Int']['input']>;
  initial_set?: InputMaybe<Scalars['Int']['input']>;
  is_stat_from_transfer?: InputMaybe<Scalars['Int']['input']>;
  lead_activity_id?: InputMaybe<Scalars['String']['input']>;
  lead_activity_pipeline_id?: InputMaybe<Scalars['String']['input']>;
  lead_id?: InputMaybe<Scalars['String']['input']>;
  live_transfer?: InputMaybe<Scalars['Int']['input']>;
  live_transfer_hold?: InputMaybe<Scalars['Int']['input']>;
  live_transfer_sale?: InputMaybe<Scalars['Int']['input']>;
  month_group_key?: InputMaybe<Scalars['String']['input']>;
  ndm_cold_call?: InputMaybe<Scalars['Int']['input']>;
  ndm_connect?: InputMaybe<Scalars['Int']['input']>;
  ndm_set?: InputMaybe<Scalars['Int']['input']>;
  next_phase?: InputMaybe<Phase>;
  organization_id?: InputMaybe<Scalars['String']['input']>;
  otf?: InputMaybe<Scalars['Int']['input']>;
  pipeline?: InputMaybe<Scalars['Int']['input']>;
  pipeline_needs_decision?: InputMaybe<Scalars['Int']['input']>;
  previous_phase?: InputMaybe<Phase>;
  recurring_revenue?: InputMaybe<Scalars['Float']['input']>;
  release?: InputMaybe<Scalars['Int']['input']>;
  rescheduled_hold?: InputMaybe<Scalars['Int']['input']>;
  rescheduled_pipeline?: InputMaybe<Scalars['Int']['input']>;
  rescheduled_set?: InputMaybe<Scalars['Int']['input']>;
  revenue?: InputMaybe<Scalars['Float']['input']>;
  revenue_value?: InputMaybe<Scalars['Float']['input']>;
  sale?: InputMaybe<Scalars['Int']['input']>;
  sale_closing_opportunity?: InputMaybe<Scalars['Int']['input']>;
  sale_date?: InputMaybe<Scalars['DateTime']['input']>;
  sales_cycle_num?: InputMaybe<Scalars['Int']['input']>;
  schedule_callback_held?: InputMaybe<Scalars['Int']['input']>;
  schedule_callback_set_not_held?: InputMaybe<Scalars['Int']['input']>;
  schedule_item_id?: InputMaybe<Scalars['String']['input']>;
  sdr_hold?: InputMaybe<Scalars['Int']['input']>;
  sdr_revenue?: InputMaybe<Scalars['Int']['input']>;
  sdr_sale?: InputMaybe<Scalars['Int']['input']>;
  sdr_transfer?: InputMaybe<Scalars['Int']['input']>;
  sequence_advance?: InputMaybe<Scalars['Int']['input']>;
  sequence_attributed_hold?: InputMaybe<Scalars['Int']['input']>;
  sequence_id?: InputMaybe<Scalars['String']['input']>;
  set?: InputMaybe<Scalars['Int']['input']>;
  set_date?: InputMaybe<Scalars['DateTime']['input']>;
  set_site_id?: InputMaybe<Scalars['String']['input']>;
  set_team_id?: InputMaybe<Scalars['String']['input']>;
  set_user_id?: InputMaybe<Scalars['String']['input']>;
  site_id?: InputMaybe<Scalars['String']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sms_received?: InputMaybe<Scalars['Int']['input']>;
  sms_sent?: InputMaybe<Scalars['Int']['input']>;
  talk_time?: InputMaybe<Scalars['Int']['input']>;
  team_id?: InputMaybe<Scalars['String']['input']>;
  ten_minute_downtime_before_activity?: InputMaybe<Scalars['Int']['input']>;
  ten_minute_downtime_before_call?: InputMaybe<Scalars['Int']['input']>;
  time_in_last_phase_sec?: InputMaybe<Scalars['Int']['input']>;
  transfer_answered?: InputMaybe<Scalars['Int']['input']>;
  transfer_answered_ae?: InputMaybe<Scalars['Int']['input']>;
  transfer_answered_ae_unique?: InputMaybe<Scalars['Int']['input']>;
  transfer_answered_sdr?: InputMaybe<Scalars['Int']['input']>;
  transfer_answered_sdr_unique?: InputMaybe<Scalars['Int']['input']>;
  transfer_answered_unique?: InputMaybe<Scalars['Int']['input']>;
  transfer_call_duration_over_x_min?: InputMaybe<Scalars['Int']['input']>;
  transfer_call_duration_over_x_min_ae?: InputMaybe<Scalars['Int']['input']>;
  transfer_call_duration_over_x_min_ae_unique?: InputMaybe<Scalars['Int']['input']>;
  transfer_call_duration_over_x_min_sdr?: InputMaybe<Scalars['Int']['input']>;
  transfer_call_duration_over_x_min_sdr_unique?: InputMaybe<Scalars['Int']['input']>;
  transfer_call_duration_over_x_min_unique?: InputMaybe<Scalars['Int']['input']>;
  transfer_ignored?: InputMaybe<Scalars['Int']['input']>;
  transfer_ignored_ae?: InputMaybe<Scalars['Int']['input']>;
  transfer_ignored_ae_unique?: InputMaybe<Scalars['Int']['input']>;
  transfer_ignored_sdr?: InputMaybe<Scalars['Int']['input']>;
  transfer_ignored_sdr_unique?: InputMaybe<Scalars['Int']['input']>;
  transfer_ignored_unique?: InputMaybe<Scalars['Int']['input']>;
  transfer_ring_time?: InputMaybe<Scalars['Int']['input']>;
  transfer_ring_time_ae?: InputMaybe<Scalars['Int']['input']>;
  transfer_site_id?: InputMaybe<Scalars['String']['input']>;
  transfer_site_to_id?: InputMaybe<Scalars['String']['input']>;
  transfer_team_id?: InputMaybe<Scalars['String']['input']>;
  transfer_team_to_id?: InputMaybe<Scalars['String']['input']>;
  transfer_user_id?: InputMaybe<Scalars['String']['input']>;
  transfer_user_to_id?: InputMaybe<Scalars['String']['input']>;
  transfered_leads?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<StatItemAggregationType>;
  unique_lead_pipeline?: InputMaybe<Scalars['Int']['input']>;
  unique_rescheduled_set?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
  user_role?: InputMaybe<Role>;
  value?: InputMaybe<Scalars['Float']['input']>;
  voicemail_or_no_contact?: InputMaybe<Scalars['Int']['input']>;
  week_group_key?: InputMaybe<Scalars['String']['input']>;
};

export type StatItemAggregationCreateManyStepInputEnvelope = {
  data?: InputMaybe<Array<StatItemAggregationCreateManyStepInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type StatItemAggregationCreateNestedManyWithoutLeadInput = {
  connect?: InputMaybe<Array<StatItemAggregationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StatItemAggregationCreateOrConnectWithoutLeadInput>>;
  create?: InputMaybe<Array<StatItemAggregationCreateWithoutLeadInput>>;
  createMany?: InputMaybe<StatItemAggregationCreateManyLeadInputEnvelope>;
};

export type StatItemAggregationCreateNestedManyWithoutSequenceInput = {
  connect?: InputMaybe<Array<StatItemAggregationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StatItemAggregationCreateOrConnectWithoutSequenceInput>>;
  create?: InputMaybe<Array<StatItemAggregationCreateWithoutSequenceInput>>;
  createMany?: InputMaybe<StatItemAggregationCreateManySequenceInputEnvelope>;
};

export type StatItemAggregationCreateNestedManyWithoutStepInput = {
  connect?: InputMaybe<Array<StatItemAggregationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StatItemAggregationCreateOrConnectWithoutStepInput>>;
  create?: InputMaybe<Array<StatItemAggregationCreateWithoutStepInput>>;
  createMany?: InputMaybe<StatItemAggregationCreateManyStepInputEnvelope>;
};

export type StatItemAggregationCreateNestedOneWithoutLead_Activity_PipelineInput = {
  connect?: InputMaybe<StatItemAggregationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<StatItemAggregationCreateOrConnectWithoutLead_Activity_PipelineInput>;
  create?: InputMaybe<StatItemAggregationCreateWithoutLead_Activity_PipelineInput>;
};

export type StatItemAggregationCreateNestedOneWithoutLead_ActivityInput = {
  connect?: InputMaybe<StatItemAggregationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<StatItemAggregationCreateOrConnectWithoutLead_ActivityInput>;
  create?: InputMaybe<StatItemAggregationCreateWithoutLead_ActivityInput>;
};

export type StatItemAggregationCreateNestedOneWithoutScheduled_ItemInput = {
  connect?: InputMaybe<StatItemAggregationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<StatItemAggregationCreateOrConnectWithoutScheduled_ItemInput>;
  create?: InputMaybe<StatItemAggregationCreateWithoutScheduled_ItemInput>;
};

export type StatItemAggregationCreateOrConnectWithoutLead_Activity_PipelineInput = {
  create: StatItemAggregationCreateWithoutLead_Activity_PipelineInput;
  where: StatItemAggregationWhereUniqueInput;
};

export type StatItemAggregationCreateOrConnectWithoutLead_ActivityInput = {
  create: StatItemAggregationCreateWithoutLead_ActivityInput;
  where: StatItemAggregationWhereUniqueInput;
};

export type StatItemAggregationCreateOrConnectWithoutLeadInput = {
  create: StatItemAggregationCreateWithoutLeadInput;
  where: StatItemAggregationWhereUniqueInput;
};

export type StatItemAggregationCreateOrConnectWithoutScheduled_ItemInput = {
  create: StatItemAggregationCreateWithoutScheduled_ItemInput;
  where: StatItemAggregationWhereUniqueInput;
};

export type StatItemAggregationCreateOrConnectWithoutSequenceInput = {
  create: StatItemAggregationCreateWithoutSequenceInput;
  where: StatItemAggregationWhereUniqueInput;
};

export type StatItemAggregationCreateOrConnectWithoutStepInput = {
  create: StatItemAggregationCreateWithoutStepInput;
  where: StatItemAggregationWhereUniqueInput;
};

export type StatItemAggregationCreateWithoutLead_Activity_PipelineInput = {
  bad_number?: InputMaybe<Scalars['Int']['input']>;
  call_followup?: InputMaybe<Scalars['Int']['input']>;
  channel?: InputMaybe<Channel>;
  closing_opp_held?: InputMaybe<Scalars['Int']['input']>;
  closing_opp_scheduled?: InputMaybe<Scalars['Int']['input']>;
  closing_opps_pipeline?: InputMaybe<Scalars['Int']['input']>;
  cold_call?: InputMaybe<Scalars['Int']['input']>;
  connect?: InputMaybe<Scalars['Int']['input']>;
  connect_talk_time?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  date: Scalars['DateTime']['input'];
  date_group_key?: InputMaybe<Scalars['String']['input']>;
  day: Scalars['Int']['input'];
  days_contact_to_set?: InputMaybe<Scalars['Int']['input']>;
  days_decision_call_to_sale?: InputMaybe<Scalars['Int']['input']>;
  days_first_dial_to_contact?: InputMaybe<Scalars['Int']['input']>;
  days_hold_to_decision_call?: InputMaybe<Scalars['Int']['input']>;
  days_hold_to_sale?: InputMaybe<Scalars['Int']['input']>;
  days_set_out?: InputMaybe<Scalars['Float']['input']>;
  days_set_to_hold?: InputMaybe<Scalars['Int']['input']>;
  days_to_close?: InputMaybe<Scalars['Int']['input']>;
  decision_call?: InputMaybe<Scalars['Int']['input']>;
  decision_call_held?: InputMaybe<Scalars['Int']['input']>;
  decision_call_set?: InputMaybe<Scalars['Int']['input']>;
  decision_call_set_not_held?: InputMaybe<Scalars['Int']['input']>;
  demo_held?: InputMaybe<Scalars['Int']['input']>;
  demo_set_not_held?: InputMaybe<Scalars['Int']['input']>;
  demo_talk_time?: InputMaybe<Scalars['Int']['input']>;
  dial?: InputMaybe<Scalars['Int']['input']>;
  dials_to_set?: InputMaybe<Scalars['Int']['input']>;
  dm_cold_call?: InputMaybe<Scalars['Int']['input']>;
  dm_connect?: InputMaybe<Scalars['Int']['input']>;
  dm_connect_date?: InputMaybe<Scalars['DateTime']['input']>;
  dm_set?: InputMaybe<Scalars['Int']['input']>;
  downtime_before_activity?: InputMaybe<Scalars['Int']['input']>;
  downtime_before_call?: InputMaybe<Scalars['Int']['input']>;
  email_received?: InputMaybe<Scalars['Int']['input']>;
  email_sent?: InputMaybe<Scalars['Int']['input']>;
  first_dial_hour_minute?: InputMaybe<Scalars['Int']['input']>;
  followup_hold?: InputMaybe<Scalars['Int']['input']>;
  general_callback_held?: InputMaybe<Scalars['Int']['input']>;
  general_callback_set_not_held?: InputMaybe<Scalars['Int']['input']>;
  hold?: InputMaybe<Scalars['Int']['input']>;
  hold_date?: InputMaybe<Scalars['DateTime']['input']>;
  hour: Scalars['Int']['input'];
  hour_minute?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  inbound_time_to_first_dial?: InputMaybe<Scalars['Int']['input']>;
  initial_hold?: InputMaybe<Scalars['Int']['input']>;
  initial_pipeline?: InputMaybe<Scalars['Int']['input']>;
  initial_set?: InputMaybe<Scalars['Int']['input']>;
  is_stat_from_transfer?: InputMaybe<Scalars['Int']['input']>;
  lead?: InputMaybe<LeadCreateNestedOneWithoutStat_ItemsInput>;
  lead_activity?: InputMaybe<LeadActivityCreateNestedOneWithoutStat_Item_AggregationInput>;
  live_transfer?: InputMaybe<Scalars['Int']['input']>;
  live_transfer_hold?: InputMaybe<Scalars['Int']['input']>;
  live_transfer_sale?: InputMaybe<Scalars['Int']['input']>;
  month_group_key?: InputMaybe<Scalars['String']['input']>;
  ndm_cold_call?: InputMaybe<Scalars['Int']['input']>;
  ndm_connect?: InputMaybe<Scalars['Int']['input']>;
  ndm_set?: InputMaybe<Scalars['Int']['input']>;
  next_phase?: InputMaybe<Phase>;
  organization_id?: InputMaybe<Scalars['String']['input']>;
  otf?: InputMaybe<Scalars['Int']['input']>;
  pipeline?: InputMaybe<Scalars['Int']['input']>;
  pipeline_needs_decision?: InputMaybe<Scalars['Int']['input']>;
  previous_phase?: InputMaybe<Phase>;
  recurring_revenue?: InputMaybe<Scalars['Float']['input']>;
  release?: InputMaybe<Scalars['Int']['input']>;
  rescheduled_hold?: InputMaybe<Scalars['Int']['input']>;
  rescheduled_pipeline?: InputMaybe<Scalars['Int']['input']>;
  rescheduled_set?: InputMaybe<Scalars['Int']['input']>;
  revenue?: InputMaybe<Scalars['Float']['input']>;
  revenue_value?: InputMaybe<Scalars['Float']['input']>;
  sale?: InputMaybe<Scalars['Int']['input']>;
  sale_closing_opportunity?: InputMaybe<Scalars['Int']['input']>;
  sale_date?: InputMaybe<Scalars['DateTime']['input']>;
  sales_cycle_num?: InputMaybe<Scalars['Int']['input']>;
  schedule_callback_held?: InputMaybe<Scalars['Int']['input']>;
  schedule_callback_set_not_held?: InputMaybe<Scalars['Int']['input']>;
  scheduled_item?: InputMaybe<ScheduleItemCreateNestedOneWithoutStat_Item_AggregationInput>;
  sdr_hold?: InputMaybe<Scalars['Int']['input']>;
  sdr_revenue?: InputMaybe<Scalars['Int']['input']>;
  sdr_sale?: InputMaybe<Scalars['Int']['input']>;
  sdr_transfer?: InputMaybe<Scalars['Int']['input']>;
  sequence?: InputMaybe<SequenceCreateNestedOneWithoutStatItemsInput>;
  sequence_advance?: InputMaybe<Scalars['Int']['input']>;
  sequence_attributed_hold?: InputMaybe<Scalars['Int']['input']>;
  set?: InputMaybe<Scalars['Int']['input']>;
  set_date?: InputMaybe<Scalars['DateTime']['input']>;
  set_site_id?: InputMaybe<Scalars['String']['input']>;
  set_team_id?: InputMaybe<Scalars['String']['input']>;
  set_user_id?: InputMaybe<Scalars['String']['input']>;
  site_id?: InputMaybe<Scalars['String']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sms_received?: InputMaybe<Scalars['Int']['input']>;
  sms_sent?: InputMaybe<Scalars['Int']['input']>;
  step?: InputMaybe<SequenceStepCreateNestedOneWithoutStatItemAggregationInput>;
  talk_time?: InputMaybe<Scalars['Int']['input']>;
  team_id?: InputMaybe<Scalars['String']['input']>;
  ten_minute_downtime_before_activity?: InputMaybe<Scalars['Int']['input']>;
  ten_minute_downtime_before_call?: InputMaybe<Scalars['Int']['input']>;
  time_in_last_phase_sec?: InputMaybe<Scalars['Int']['input']>;
  transfer_answered?: InputMaybe<Scalars['Int']['input']>;
  transfer_answered_ae?: InputMaybe<Scalars['Int']['input']>;
  transfer_answered_ae_unique?: InputMaybe<Scalars['Int']['input']>;
  transfer_answered_sdr?: InputMaybe<Scalars['Int']['input']>;
  transfer_answered_sdr_unique?: InputMaybe<Scalars['Int']['input']>;
  transfer_answered_unique?: InputMaybe<Scalars['Int']['input']>;
  transfer_call_duration_over_x_min?: InputMaybe<Scalars['Int']['input']>;
  transfer_call_duration_over_x_min_ae?: InputMaybe<Scalars['Int']['input']>;
  transfer_call_duration_over_x_min_ae_unique?: InputMaybe<Scalars['Int']['input']>;
  transfer_call_duration_over_x_min_sdr?: InputMaybe<Scalars['Int']['input']>;
  transfer_call_duration_over_x_min_sdr_unique?: InputMaybe<Scalars['Int']['input']>;
  transfer_call_duration_over_x_min_unique?: InputMaybe<Scalars['Int']['input']>;
  transfer_ignored?: InputMaybe<Scalars['Int']['input']>;
  transfer_ignored_ae?: InputMaybe<Scalars['Int']['input']>;
  transfer_ignored_ae_unique?: InputMaybe<Scalars['Int']['input']>;
  transfer_ignored_sdr?: InputMaybe<Scalars['Int']['input']>;
  transfer_ignored_sdr_unique?: InputMaybe<Scalars['Int']['input']>;
  transfer_ignored_unique?: InputMaybe<Scalars['Int']['input']>;
  transfer_ring_time?: InputMaybe<Scalars['Int']['input']>;
  transfer_ring_time_ae?: InputMaybe<Scalars['Int']['input']>;
  transfer_site_id?: InputMaybe<Scalars['String']['input']>;
  transfer_site_to_id?: InputMaybe<Scalars['String']['input']>;
  transfer_team_id?: InputMaybe<Scalars['String']['input']>;
  transfer_team_to_id?: InputMaybe<Scalars['String']['input']>;
  transfer_user_id?: InputMaybe<Scalars['String']['input']>;
  transfer_user_to_id?: InputMaybe<Scalars['String']['input']>;
  transfered_leads?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<StatItemAggregationType>;
  unique_lead_pipeline?: InputMaybe<Scalars['Int']['input']>;
  unique_rescheduled_set?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
  user_role?: InputMaybe<Role>;
  value?: InputMaybe<Scalars['Float']['input']>;
  voicemail_or_no_contact?: InputMaybe<Scalars['Int']['input']>;
  week_group_key?: InputMaybe<Scalars['String']['input']>;
};

export type StatItemAggregationCreateWithoutLead_ActivityInput = {
  bad_number?: InputMaybe<Scalars['Int']['input']>;
  call_followup?: InputMaybe<Scalars['Int']['input']>;
  channel?: InputMaybe<Channel>;
  closing_opp_held?: InputMaybe<Scalars['Int']['input']>;
  closing_opp_scheduled?: InputMaybe<Scalars['Int']['input']>;
  closing_opps_pipeline?: InputMaybe<Scalars['Int']['input']>;
  cold_call?: InputMaybe<Scalars['Int']['input']>;
  connect?: InputMaybe<Scalars['Int']['input']>;
  connect_talk_time?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  date: Scalars['DateTime']['input'];
  date_group_key?: InputMaybe<Scalars['String']['input']>;
  day: Scalars['Int']['input'];
  days_contact_to_set?: InputMaybe<Scalars['Int']['input']>;
  days_decision_call_to_sale?: InputMaybe<Scalars['Int']['input']>;
  days_first_dial_to_contact?: InputMaybe<Scalars['Int']['input']>;
  days_hold_to_decision_call?: InputMaybe<Scalars['Int']['input']>;
  days_hold_to_sale?: InputMaybe<Scalars['Int']['input']>;
  days_set_out?: InputMaybe<Scalars['Float']['input']>;
  days_set_to_hold?: InputMaybe<Scalars['Int']['input']>;
  days_to_close?: InputMaybe<Scalars['Int']['input']>;
  decision_call?: InputMaybe<Scalars['Int']['input']>;
  decision_call_held?: InputMaybe<Scalars['Int']['input']>;
  decision_call_set?: InputMaybe<Scalars['Int']['input']>;
  decision_call_set_not_held?: InputMaybe<Scalars['Int']['input']>;
  demo_held?: InputMaybe<Scalars['Int']['input']>;
  demo_set_not_held?: InputMaybe<Scalars['Int']['input']>;
  demo_talk_time?: InputMaybe<Scalars['Int']['input']>;
  dial?: InputMaybe<Scalars['Int']['input']>;
  dials_to_set?: InputMaybe<Scalars['Int']['input']>;
  dm_cold_call?: InputMaybe<Scalars['Int']['input']>;
  dm_connect?: InputMaybe<Scalars['Int']['input']>;
  dm_connect_date?: InputMaybe<Scalars['DateTime']['input']>;
  dm_set?: InputMaybe<Scalars['Int']['input']>;
  downtime_before_activity?: InputMaybe<Scalars['Int']['input']>;
  downtime_before_call?: InputMaybe<Scalars['Int']['input']>;
  email_received?: InputMaybe<Scalars['Int']['input']>;
  email_sent?: InputMaybe<Scalars['Int']['input']>;
  first_dial_hour_minute?: InputMaybe<Scalars['Int']['input']>;
  followup_hold?: InputMaybe<Scalars['Int']['input']>;
  general_callback_held?: InputMaybe<Scalars['Int']['input']>;
  general_callback_set_not_held?: InputMaybe<Scalars['Int']['input']>;
  hold?: InputMaybe<Scalars['Int']['input']>;
  hold_date?: InputMaybe<Scalars['DateTime']['input']>;
  hour: Scalars['Int']['input'];
  hour_minute?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  inbound_time_to_first_dial?: InputMaybe<Scalars['Int']['input']>;
  initial_hold?: InputMaybe<Scalars['Int']['input']>;
  initial_pipeline?: InputMaybe<Scalars['Int']['input']>;
  initial_set?: InputMaybe<Scalars['Int']['input']>;
  is_stat_from_transfer?: InputMaybe<Scalars['Int']['input']>;
  lead?: InputMaybe<LeadCreateNestedOneWithoutStat_ItemsInput>;
  lead_activity_pipeline?: InputMaybe<LeadActivityCreateNestedOneWithoutStat_Item_Aggregation_PipelineInput>;
  live_transfer?: InputMaybe<Scalars['Int']['input']>;
  live_transfer_hold?: InputMaybe<Scalars['Int']['input']>;
  live_transfer_sale?: InputMaybe<Scalars['Int']['input']>;
  month_group_key?: InputMaybe<Scalars['String']['input']>;
  ndm_cold_call?: InputMaybe<Scalars['Int']['input']>;
  ndm_connect?: InputMaybe<Scalars['Int']['input']>;
  ndm_set?: InputMaybe<Scalars['Int']['input']>;
  next_phase?: InputMaybe<Phase>;
  organization_id?: InputMaybe<Scalars['String']['input']>;
  otf?: InputMaybe<Scalars['Int']['input']>;
  pipeline?: InputMaybe<Scalars['Int']['input']>;
  pipeline_needs_decision?: InputMaybe<Scalars['Int']['input']>;
  previous_phase?: InputMaybe<Phase>;
  recurring_revenue?: InputMaybe<Scalars['Float']['input']>;
  release?: InputMaybe<Scalars['Int']['input']>;
  rescheduled_hold?: InputMaybe<Scalars['Int']['input']>;
  rescheduled_pipeline?: InputMaybe<Scalars['Int']['input']>;
  rescheduled_set?: InputMaybe<Scalars['Int']['input']>;
  revenue?: InputMaybe<Scalars['Float']['input']>;
  revenue_value?: InputMaybe<Scalars['Float']['input']>;
  sale?: InputMaybe<Scalars['Int']['input']>;
  sale_closing_opportunity?: InputMaybe<Scalars['Int']['input']>;
  sale_date?: InputMaybe<Scalars['DateTime']['input']>;
  sales_cycle_num?: InputMaybe<Scalars['Int']['input']>;
  schedule_callback_held?: InputMaybe<Scalars['Int']['input']>;
  schedule_callback_set_not_held?: InputMaybe<Scalars['Int']['input']>;
  scheduled_item?: InputMaybe<ScheduleItemCreateNestedOneWithoutStat_Item_AggregationInput>;
  sdr_hold?: InputMaybe<Scalars['Int']['input']>;
  sdr_revenue?: InputMaybe<Scalars['Int']['input']>;
  sdr_sale?: InputMaybe<Scalars['Int']['input']>;
  sdr_transfer?: InputMaybe<Scalars['Int']['input']>;
  sequence?: InputMaybe<SequenceCreateNestedOneWithoutStatItemsInput>;
  sequence_advance?: InputMaybe<Scalars['Int']['input']>;
  sequence_attributed_hold?: InputMaybe<Scalars['Int']['input']>;
  set?: InputMaybe<Scalars['Int']['input']>;
  set_date?: InputMaybe<Scalars['DateTime']['input']>;
  set_site_id?: InputMaybe<Scalars['String']['input']>;
  set_team_id?: InputMaybe<Scalars['String']['input']>;
  set_user_id?: InputMaybe<Scalars['String']['input']>;
  site_id?: InputMaybe<Scalars['String']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sms_received?: InputMaybe<Scalars['Int']['input']>;
  sms_sent?: InputMaybe<Scalars['Int']['input']>;
  step?: InputMaybe<SequenceStepCreateNestedOneWithoutStatItemAggregationInput>;
  talk_time?: InputMaybe<Scalars['Int']['input']>;
  team_id?: InputMaybe<Scalars['String']['input']>;
  ten_minute_downtime_before_activity?: InputMaybe<Scalars['Int']['input']>;
  ten_minute_downtime_before_call?: InputMaybe<Scalars['Int']['input']>;
  time_in_last_phase_sec?: InputMaybe<Scalars['Int']['input']>;
  transfer_answered?: InputMaybe<Scalars['Int']['input']>;
  transfer_answered_ae?: InputMaybe<Scalars['Int']['input']>;
  transfer_answered_ae_unique?: InputMaybe<Scalars['Int']['input']>;
  transfer_answered_sdr?: InputMaybe<Scalars['Int']['input']>;
  transfer_answered_sdr_unique?: InputMaybe<Scalars['Int']['input']>;
  transfer_answered_unique?: InputMaybe<Scalars['Int']['input']>;
  transfer_call_duration_over_x_min?: InputMaybe<Scalars['Int']['input']>;
  transfer_call_duration_over_x_min_ae?: InputMaybe<Scalars['Int']['input']>;
  transfer_call_duration_over_x_min_ae_unique?: InputMaybe<Scalars['Int']['input']>;
  transfer_call_duration_over_x_min_sdr?: InputMaybe<Scalars['Int']['input']>;
  transfer_call_duration_over_x_min_sdr_unique?: InputMaybe<Scalars['Int']['input']>;
  transfer_call_duration_over_x_min_unique?: InputMaybe<Scalars['Int']['input']>;
  transfer_ignored?: InputMaybe<Scalars['Int']['input']>;
  transfer_ignored_ae?: InputMaybe<Scalars['Int']['input']>;
  transfer_ignored_ae_unique?: InputMaybe<Scalars['Int']['input']>;
  transfer_ignored_sdr?: InputMaybe<Scalars['Int']['input']>;
  transfer_ignored_sdr_unique?: InputMaybe<Scalars['Int']['input']>;
  transfer_ignored_unique?: InputMaybe<Scalars['Int']['input']>;
  transfer_ring_time?: InputMaybe<Scalars['Int']['input']>;
  transfer_ring_time_ae?: InputMaybe<Scalars['Int']['input']>;
  transfer_site_id?: InputMaybe<Scalars['String']['input']>;
  transfer_site_to_id?: InputMaybe<Scalars['String']['input']>;
  transfer_team_id?: InputMaybe<Scalars['String']['input']>;
  transfer_team_to_id?: InputMaybe<Scalars['String']['input']>;
  transfer_user_id?: InputMaybe<Scalars['String']['input']>;
  transfer_user_to_id?: InputMaybe<Scalars['String']['input']>;
  transfered_leads?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<StatItemAggregationType>;
  unique_lead_pipeline?: InputMaybe<Scalars['Int']['input']>;
  unique_rescheduled_set?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
  user_role?: InputMaybe<Role>;
  value?: InputMaybe<Scalars['Float']['input']>;
  voicemail_or_no_contact?: InputMaybe<Scalars['Int']['input']>;
  week_group_key?: InputMaybe<Scalars['String']['input']>;
};

export type StatItemAggregationCreateWithoutLeadInput = {
  bad_number?: InputMaybe<Scalars['Int']['input']>;
  call_followup?: InputMaybe<Scalars['Int']['input']>;
  channel?: InputMaybe<Channel>;
  closing_opp_held?: InputMaybe<Scalars['Int']['input']>;
  closing_opp_scheduled?: InputMaybe<Scalars['Int']['input']>;
  closing_opps_pipeline?: InputMaybe<Scalars['Int']['input']>;
  cold_call?: InputMaybe<Scalars['Int']['input']>;
  connect?: InputMaybe<Scalars['Int']['input']>;
  connect_talk_time?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  date: Scalars['DateTime']['input'];
  date_group_key?: InputMaybe<Scalars['String']['input']>;
  day: Scalars['Int']['input'];
  days_contact_to_set?: InputMaybe<Scalars['Int']['input']>;
  days_decision_call_to_sale?: InputMaybe<Scalars['Int']['input']>;
  days_first_dial_to_contact?: InputMaybe<Scalars['Int']['input']>;
  days_hold_to_decision_call?: InputMaybe<Scalars['Int']['input']>;
  days_hold_to_sale?: InputMaybe<Scalars['Int']['input']>;
  days_set_out?: InputMaybe<Scalars['Float']['input']>;
  days_set_to_hold?: InputMaybe<Scalars['Int']['input']>;
  days_to_close?: InputMaybe<Scalars['Int']['input']>;
  decision_call?: InputMaybe<Scalars['Int']['input']>;
  decision_call_held?: InputMaybe<Scalars['Int']['input']>;
  decision_call_set?: InputMaybe<Scalars['Int']['input']>;
  decision_call_set_not_held?: InputMaybe<Scalars['Int']['input']>;
  demo_held?: InputMaybe<Scalars['Int']['input']>;
  demo_set_not_held?: InputMaybe<Scalars['Int']['input']>;
  demo_talk_time?: InputMaybe<Scalars['Int']['input']>;
  dial?: InputMaybe<Scalars['Int']['input']>;
  dials_to_set?: InputMaybe<Scalars['Int']['input']>;
  dm_cold_call?: InputMaybe<Scalars['Int']['input']>;
  dm_connect?: InputMaybe<Scalars['Int']['input']>;
  dm_connect_date?: InputMaybe<Scalars['DateTime']['input']>;
  dm_set?: InputMaybe<Scalars['Int']['input']>;
  downtime_before_activity?: InputMaybe<Scalars['Int']['input']>;
  downtime_before_call?: InputMaybe<Scalars['Int']['input']>;
  email_received?: InputMaybe<Scalars['Int']['input']>;
  email_sent?: InputMaybe<Scalars['Int']['input']>;
  first_dial_hour_minute?: InputMaybe<Scalars['Int']['input']>;
  followup_hold?: InputMaybe<Scalars['Int']['input']>;
  general_callback_held?: InputMaybe<Scalars['Int']['input']>;
  general_callback_set_not_held?: InputMaybe<Scalars['Int']['input']>;
  hold?: InputMaybe<Scalars['Int']['input']>;
  hold_date?: InputMaybe<Scalars['DateTime']['input']>;
  hour: Scalars['Int']['input'];
  hour_minute?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  inbound_time_to_first_dial?: InputMaybe<Scalars['Int']['input']>;
  initial_hold?: InputMaybe<Scalars['Int']['input']>;
  initial_pipeline?: InputMaybe<Scalars['Int']['input']>;
  initial_set?: InputMaybe<Scalars['Int']['input']>;
  is_stat_from_transfer?: InputMaybe<Scalars['Int']['input']>;
  lead_activity?: InputMaybe<LeadActivityCreateNestedOneWithoutStat_Item_AggregationInput>;
  lead_activity_pipeline?: InputMaybe<LeadActivityCreateNestedOneWithoutStat_Item_Aggregation_PipelineInput>;
  live_transfer?: InputMaybe<Scalars['Int']['input']>;
  live_transfer_hold?: InputMaybe<Scalars['Int']['input']>;
  live_transfer_sale?: InputMaybe<Scalars['Int']['input']>;
  month_group_key?: InputMaybe<Scalars['String']['input']>;
  ndm_cold_call?: InputMaybe<Scalars['Int']['input']>;
  ndm_connect?: InputMaybe<Scalars['Int']['input']>;
  ndm_set?: InputMaybe<Scalars['Int']['input']>;
  next_phase?: InputMaybe<Phase>;
  organization_id?: InputMaybe<Scalars['String']['input']>;
  otf?: InputMaybe<Scalars['Int']['input']>;
  pipeline?: InputMaybe<Scalars['Int']['input']>;
  pipeline_needs_decision?: InputMaybe<Scalars['Int']['input']>;
  previous_phase?: InputMaybe<Phase>;
  recurring_revenue?: InputMaybe<Scalars['Float']['input']>;
  release?: InputMaybe<Scalars['Int']['input']>;
  rescheduled_hold?: InputMaybe<Scalars['Int']['input']>;
  rescheduled_pipeline?: InputMaybe<Scalars['Int']['input']>;
  rescheduled_set?: InputMaybe<Scalars['Int']['input']>;
  revenue?: InputMaybe<Scalars['Float']['input']>;
  revenue_value?: InputMaybe<Scalars['Float']['input']>;
  sale?: InputMaybe<Scalars['Int']['input']>;
  sale_closing_opportunity?: InputMaybe<Scalars['Int']['input']>;
  sale_date?: InputMaybe<Scalars['DateTime']['input']>;
  sales_cycle_num?: InputMaybe<Scalars['Int']['input']>;
  schedule_callback_held?: InputMaybe<Scalars['Int']['input']>;
  schedule_callback_set_not_held?: InputMaybe<Scalars['Int']['input']>;
  scheduled_item?: InputMaybe<ScheduleItemCreateNestedOneWithoutStat_Item_AggregationInput>;
  sdr_hold?: InputMaybe<Scalars['Int']['input']>;
  sdr_revenue?: InputMaybe<Scalars['Int']['input']>;
  sdr_sale?: InputMaybe<Scalars['Int']['input']>;
  sdr_transfer?: InputMaybe<Scalars['Int']['input']>;
  sequence?: InputMaybe<SequenceCreateNestedOneWithoutStatItemsInput>;
  sequence_advance?: InputMaybe<Scalars['Int']['input']>;
  sequence_attributed_hold?: InputMaybe<Scalars['Int']['input']>;
  set?: InputMaybe<Scalars['Int']['input']>;
  set_date?: InputMaybe<Scalars['DateTime']['input']>;
  set_site_id?: InputMaybe<Scalars['String']['input']>;
  set_team_id?: InputMaybe<Scalars['String']['input']>;
  set_user_id?: InputMaybe<Scalars['String']['input']>;
  site_id?: InputMaybe<Scalars['String']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sms_received?: InputMaybe<Scalars['Int']['input']>;
  sms_sent?: InputMaybe<Scalars['Int']['input']>;
  step?: InputMaybe<SequenceStepCreateNestedOneWithoutStatItemAggregationInput>;
  talk_time?: InputMaybe<Scalars['Int']['input']>;
  team_id?: InputMaybe<Scalars['String']['input']>;
  ten_minute_downtime_before_activity?: InputMaybe<Scalars['Int']['input']>;
  ten_minute_downtime_before_call?: InputMaybe<Scalars['Int']['input']>;
  time_in_last_phase_sec?: InputMaybe<Scalars['Int']['input']>;
  transfer_answered?: InputMaybe<Scalars['Int']['input']>;
  transfer_answered_ae?: InputMaybe<Scalars['Int']['input']>;
  transfer_answered_ae_unique?: InputMaybe<Scalars['Int']['input']>;
  transfer_answered_sdr?: InputMaybe<Scalars['Int']['input']>;
  transfer_answered_sdr_unique?: InputMaybe<Scalars['Int']['input']>;
  transfer_answered_unique?: InputMaybe<Scalars['Int']['input']>;
  transfer_call_duration_over_x_min?: InputMaybe<Scalars['Int']['input']>;
  transfer_call_duration_over_x_min_ae?: InputMaybe<Scalars['Int']['input']>;
  transfer_call_duration_over_x_min_ae_unique?: InputMaybe<Scalars['Int']['input']>;
  transfer_call_duration_over_x_min_sdr?: InputMaybe<Scalars['Int']['input']>;
  transfer_call_duration_over_x_min_sdr_unique?: InputMaybe<Scalars['Int']['input']>;
  transfer_call_duration_over_x_min_unique?: InputMaybe<Scalars['Int']['input']>;
  transfer_ignored?: InputMaybe<Scalars['Int']['input']>;
  transfer_ignored_ae?: InputMaybe<Scalars['Int']['input']>;
  transfer_ignored_ae_unique?: InputMaybe<Scalars['Int']['input']>;
  transfer_ignored_sdr?: InputMaybe<Scalars['Int']['input']>;
  transfer_ignored_sdr_unique?: InputMaybe<Scalars['Int']['input']>;
  transfer_ignored_unique?: InputMaybe<Scalars['Int']['input']>;
  transfer_ring_time?: InputMaybe<Scalars['Int']['input']>;
  transfer_ring_time_ae?: InputMaybe<Scalars['Int']['input']>;
  transfer_site_id?: InputMaybe<Scalars['String']['input']>;
  transfer_site_to_id?: InputMaybe<Scalars['String']['input']>;
  transfer_team_id?: InputMaybe<Scalars['String']['input']>;
  transfer_team_to_id?: InputMaybe<Scalars['String']['input']>;
  transfer_user_id?: InputMaybe<Scalars['String']['input']>;
  transfer_user_to_id?: InputMaybe<Scalars['String']['input']>;
  transfered_leads?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<StatItemAggregationType>;
  unique_lead_pipeline?: InputMaybe<Scalars['Int']['input']>;
  unique_rescheduled_set?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
  user_role?: InputMaybe<Role>;
  value?: InputMaybe<Scalars['Float']['input']>;
  voicemail_or_no_contact?: InputMaybe<Scalars['Int']['input']>;
  week_group_key?: InputMaybe<Scalars['String']['input']>;
};

export type StatItemAggregationCreateWithoutScheduled_ItemInput = {
  bad_number?: InputMaybe<Scalars['Int']['input']>;
  call_followup?: InputMaybe<Scalars['Int']['input']>;
  channel?: InputMaybe<Channel>;
  closing_opp_held?: InputMaybe<Scalars['Int']['input']>;
  closing_opp_scheduled?: InputMaybe<Scalars['Int']['input']>;
  closing_opps_pipeline?: InputMaybe<Scalars['Int']['input']>;
  cold_call?: InputMaybe<Scalars['Int']['input']>;
  connect?: InputMaybe<Scalars['Int']['input']>;
  connect_talk_time?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  date: Scalars['DateTime']['input'];
  date_group_key?: InputMaybe<Scalars['String']['input']>;
  day: Scalars['Int']['input'];
  days_contact_to_set?: InputMaybe<Scalars['Int']['input']>;
  days_decision_call_to_sale?: InputMaybe<Scalars['Int']['input']>;
  days_first_dial_to_contact?: InputMaybe<Scalars['Int']['input']>;
  days_hold_to_decision_call?: InputMaybe<Scalars['Int']['input']>;
  days_hold_to_sale?: InputMaybe<Scalars['Int']['input']>;
  days_set_out?: InputMaybe<Scalars['Float']['input']>;
  days_set_to_hold?: InputMaybe<Scalars['Int']['input']>;
  days_to_close?: InputMaybe<Scalars['Int']['input']>;
  decision_call?: InputMaybe<Scalars['Int']['input']>;
  decision_call_held?: InputMaybe<Scalars['Int']['input']>;
  decision_call_set?: InputMaybe<Scalars['Int']['input']>;
  decision_call_set_not_held?: InputMaybe<Scalars['Int']['input']>;
  demo_held?: InputMaybe<Scalars['Int']['input']>;
  demo_set_not_held?: InputMaybe<Scalars['Int']['input']>;
  demo_talk_time?: InputMaybe<Scalars['Int']['input']>;
  dial?: InputMaybe<Scalars['Int']['input']>;
  dials_to_set?: InputMaybe<Scalars['Int']['input']>;
  dm_cold_call?: InputMaybe<Scalars['Int']['input']>;
  dm_connect?: InputMaybe<Scalars['Int']['input']>;
  dm_connect_date?: InputMaybe<Scalars['DateTime']['input']>;
  dm_set?: InputMaybe<Scalars['Int']['input']>;
  downtime_before_activity?: InputMaybe<Scalars['Int']['input']>;
  downtime_before_call?: InputMaybe<Scalars['Int']['input']>;
  email_received?: InputMaybe<Scalars['Int']['input']>;
  email_sent?: InputMaybe<Scalars['Int']['input']>;
  first_dial_hour_minute?: InputMaybe<Scalars['Int']['input']>;
  followup_hold?: InputMaybe<Scalars['Int']['input']>;
  general_callback_held?: InputMaybe<Scalars['Int']['input']>;
  general_callback_set_not_held?: InputMaybe<Scalars['Int']['input']>;
  hold?: InputMaybe<Scalars['Int']['input']>;
  hold_date?: InputMaybe<Scalars['DateTime']['input']>;
  hour: Scalars['Int']['input'];
  hour_minute?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  inbound_time_to_first_dial?: InputMaybe<Scalars['Int']['input']>;
  initial_hold?: InputMaybe<Scalars['Int']['input']>;
  initial_pipeline?: InputMaybe<Scalars['Int']['input']>;
  initial_set?: InputMaybe<Scalars['Int']['input']>;
  is_stat_from_transfer?: InputMaybe<Scalars['Int']['input']>;
  lead?: InputMaybe<LeadCreateNestedOneWithoutStat_ItemsInput>;
  lead_activity?: InputMaybe<LeadActivityCreateNestedOneWithoutStat_Item_AggregationInput>;
  lead_activity_pipeline?: InputMaybe<LeadActivityCreateNestedOneWithoutStat_Item_Aggregation_PipelineInput>;
  live_transfer?: InputMaybe<Scalars['Int']['input']>;
  live_transfer_hold?: InputMaybe<Scalars['Int']['input']>;
  live_transfer_sale?: InputMaybe<Scalars['Int']['input']>;
  month_group_key?: InputMaybe<Scalars['String']['input']>;
  ndm_cold_call?: InputMaybe<Scalars['Int']['input']>;
  ndm_connect?: InputMaybe<Scalars['Int']['input']>;
  ndm_set?: InputMaybe<Scalars['Int']['input']>;
  next_phase?: InputMaybe<Phase>;
  organization_id?: InputMaybe<Scalars['String']['input']>;
  otf?: InputMaybe<Scalars['Int']['input']>;
  pipeline?: InputMaybe<Scalars['Int']['input']>;
  pipeline_needs_decision?: InputMaybe<Scalars['Int']['input']>;
  previous_phase?: InputMaybe<Phase>;
  recurring_revenue?: InputMaybe<Scalars['Float']['input']>;
  release?: InputMaybe<Scalars['Int']['input']>;
  rescheduled_hold?: InputMaybe<Scalars['Int']['input']>;
  rescheduled_pipeline?: InputMaybe<Scalars['Int']['input']>;
  rescheduled_set?: InputMaybe<Scalars['Int']['input']>;
  revenue?: InputMaybe<Scalars['Float']['input']>;
  revenue_value?: InputMaybe<Scalars['Float']['input']>;
  sale?: InputMaybe<Scalars['Int']['input']>;
  sale_closing_opportunity?: InputMaybe<Scalars['Int']['input']>;
  sale_date?: InputMaybe<Scalars['DateTime']['input']>;
  sales_cycle_num?: InputMaybe<Scalars['Int']['input']>;
  schedule_callback_held?: InputMaybe<Scalars['Int']['input']>;
  schedule_callback_set_not_held?: InputMaybe<Scalars['Int']['input']>;
  sdr_hold?: InputMaybe<Scalars['Int']['input']>;
  sdr_revenue?: InputMaybe<Scalars['Int']['input']>;
  sdr_sale?: InputMaybe<Scalars['Int']['input']>;
  sdr_transfer?: InputMaybe<Scalars['Int']['input']>;
  sequence?: InputMaybe<SequenceCreateNestedOneWithoutStatItemsInput>;
  sequence_advance?: InputMaybe<Scalars['Int']['input']>;
  sequence_attributed_hold?: InputMaybe<Scalars['Int']['input']>;
  set?: InputMaybe<Scalars['Int']['input']>;
  set_date?: InputMaybe<Scalars['DateTime']['input']>;
  set_site_id?: InputMaybe<Scalars['String']['input']>;
  set_team_id?: InputMaybe<Scalars['String']['input']>;
  set_user_id?: InputMaybe<Scalars['String']['input']>;
  site_id?: InputMaybe<Scalars['String']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sms_received?: InputMaybe<Scalars['Int']['input']>;
  sms_sent?: InputMaybe<Scalars['Int']['input']>;
  step?: InputMaybe<SequenceStepCreateNestedOneWithoutStatItemAggregationInput>;
  talk_time?: InputMaybe<Scalars['Int']['input']>;
  team_id?: InputMaybe<Scalars['String']['input']>;
  ten_minute_downtime_before_activity?: InputMaybe<Scalars['Int']['input']>;
  ten_minute_downtime_before_call?: InputMaybe<Scalars['Int']['input']>;
  time_in_last_phase_sec?: InputMaybe<Scalars['Int']['input']>;
  transfer_answered?: InputMaybe<Scalars['Int']['input']>;
  transfer_answered_ae?: InputMaybe<Scalars['Int']['input']>;
  transfer_answered_ae_unique?: InputMaybe<Scalars['Int']['input']>;
  transfer_answered_sdr?: InputMaybe<Scalars['Int']['input']>;
  transfer_answered_sdr_unique?: InputMaybe<Scalars['Int']['input']>;
  transfer_answered_unique?: InputMaybe<Scalars['Int']['input']>;
  transfer_call_duration_over_x_min?: InputMaybe<Scalars['Int']['input']>;
  transfer_call_duration_over_x_min_ae?: InputMaybe<Scalars['Int']['input']>;
  transfer_call_duration_over_x_min_ae_unique?: InputMaybe<Scalars['Int']['input']>;
  transfer_call_duration_over_x_min_sdr?: InputMaybe<Scalars['Int']['input']>;
  transfer_call_duration_over_x_min_sdr_unique?: InputMaybe<Scalars['Int']['input']>;
  transfer_call_duration_over_x_min_unique?: InputMaybe<Scalars['Int']['input']>;
  transfer_ignored?: InputMaybe<Scalars['Int']['input']>;
  transfer_ignored_ae?: InputMaybe<Scalars['Int']['input']>;
  transfer_ignored_ae_unique?: InputMaybe<Scalars['Int']['input']>;
  transfer_ignored_sdr?: InputMaybe<Scalars['Int']['input']>;
  transfer_ignored_sdr_unique?: InputMaybe<Scalars['Int']['input']>;
  transfer_ignored_unique?: InputMaybe<Scalars['Int']['input']>;
  transfer_ring_time?: InputMaybe<Scalars['Int']['input']>;
  transfer_ring_time_ae?: InputMaybe<Scalars['Int']['input']>;
  transfer_site_id?: InputMaybe<Scalars['String']['input']>;
  transfer_site_to_id?: InputMaybe<Scalars['String']['input']>;
  transfer_team_id?: InputMaybe<Scalars['String']['input']>;
  transfer_team_to_id?: InputMaybe<Scalars['String']['input']>;
  transfer_user_id?: InputMaybe<Scalars['String']['input']>;
  transfer_user_to_id?: InputMaybe<Scalars['String']['input']>;
  transfered_leads?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<StatItemAggregationType>;
  unique_lead_pipeline?: InputMaybe<Scalars['Int']['input']>;
  unique_rescheduled_set?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
  user_role?: InputMaybe<Role>;
  value?: InputMaybe<Scalars['Float']['input']>;
  voicemail_or_no_contact?: InputMaybe<Scalars['Int']['input']>;
  week_group_key?: InputMaybe<Scalars['String']['input']>;
};

export type StatItemAggregationCreateWithoutSequenceInput = {
  bad_number?: InputMaybe<Scalars['Int']['input']>;
  call_followup?: InputMaybe<Scalars['Int']['input']>;
  channel?: InputMaybe<Channel>;
  closing_opp_held?: InputMaybe<Scalars['Int']['input']>;
  closing_opp_scheduled?: InputMaybe<Scalars['Int']['input']>;
  closing_opps_pipeline?: InputMaybe<Scalars['Int']['input']>;
  cold_call?: InputMaybe<Scalars['Int']['input']>;
  connect?: InputMaybe<Scalars['Int']['input']>;
  connect_talk_time?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  date: Scalars['DateTime']['input'];
  date_group_key?: InputMaybe<Scalars['String']['input']>;
  day: Scalars['Int']['input'];
  days_contact_to_set?: InputMaybe<Scalars['Int']['input']>;
  days_decision_call_to_sale?: InputMaybe<Scalars['Int']['input']>;
  days_first_dial_to_contact?: InputMaybe<Scalars['Int']['input']>;
  days_hold_to_decision_call?: InputMaybe<Scalars['Int']['input']>;
  days_hold_to_sale?: InputMaybe<Scalars['Int']['input']>;
  days_set_out?: InputMaybe<Scalars['Float']['input']>;
  days_set_to_hold?: InputMaybe<Scalars['Int']['input']>;
  days_to_close?: InputMaybe<Scalars['Int']['input']>;
  decision_call?: InputMaybe<Scalars['Int']['input']>;
  decision_call_held?: InputMaybe<Scalars['Int']['input']>;
  decision_call_set?: InputMaybe<Scalars['Int']['input']>;
  decision_call_set_not_held?: InputMaybe<Scalars['Int']['input']>;
  demo_held?: InputMaybe<Scalars['Int']['input']>;
  demo_set_not_held?: InputMaybe<Scalars['Int']['input']>;
  demo_talk_time?: InputMaybe<Scalars['Int']['input']>;
  dial?: InputMaybe<Scalars['Int']['input']>;
  dials_to_set?: InputMaybe<Scalars['Int']['input']>;
  dm_cold_call?: InputMaybe<Scalars['Int']['input']>;
  dm_connect?: InputMaybe<Scalars['Int']['input']>;
  dm_connect_date?: InputMaybe<Scalars['DateTime']['input']>;
  dm_set?: InputMaybe<Scalars['Int']['input']>;
  downtime_before_activity?: InputMaybe<Scalars['Int']['input']>;
  downtime_before_call?: InputMaybe<Scalars['Int']['input']>;
  email_received?: InputMaybe<Scalars['Int']['input']>;
  email_sent?: InputMaybe<Scalars['Int']['input']>;
  first_dial_hour_minute?: InputMaybe<Scalars['Int']['input']>;
  followup_hold?: InputMaybe<Scalars['Int']['input']>;
  general_callback_held?: InputMaybe<Scalars['Int']['input']>;
  general_callback_set_not_held?: InputMaybe<Scalars['Int']['input']>;
  hold?: InputMaybe<Scalars['Int']['input']>;
  hold_date?: InputMaybe<Scalars['DateTime']['input']>;
  hour: Scalars['Int']['input'];
  hour_minute?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  inbound_time_to_first_dial?: InputMaybe<Scalars['Int']['input']>;
  initial_hold?: InputMaybe<Scalars['Int']['input']>;
  initial_pipeline?: InputMaybe<Scalars['Int']['input']>;
  initial_set?: InputMaybe<Scalars['Int']['input']>;
  is_stat_from_transfer?: InputMaybe<Scalars['Int']['input']>;
  lead?: InputMaybe<LeadCreateNestedOneWithoutStat_ItemsInput>;
  lead_activity?: InputMaybe<LeadActivityCreateNestedOneWithoutStat_Item_AggregationInput>;
  lead_activity_pipeline?: InputMaybe<LeadActivityCreateNestedOneWithoutStat_Item_Aggregation_PipelineInput>;
  live_transfer?: InputMaybe<Scalars['Int']['input']>;
  live_transfer_hold?: InputMaybe<Scalars['Int']['input']>;
  live_transfer_sale?: InputMaybe<Scalars['Int']['input']>;
  month_group_key?: InputMaybe<Scalars['String']['input']>;
  ndm_cold_call?: InputMaybe<Scalars['Int']['input']>;
  ndm_connect?: InputMaybe<Scalars['Int']['input']>;
  ndm_set?: InputMaybe<Scalars['Int']['input']>;
  next_phase?: InputMaybe<Phase>;
  organization_id?: InputMaybe<Scalars['String']['input']>;
  otf?: InputMaybe<Scalars['Int']['input']>;
  pipeline?: InputMaybe<Scalars['Int']['input']>;
  pipeline_needs_decision?: InputMaybe<Scalars['Int']['input']>;
  previous_phase?: InputMaybe<Phase>;
  recurring_revenue?: InputMaybe<Scalars['Float']['input']>;
  release?: InputMaybe<Scalars['Int']['input']>;
  rescheduled_hold?: InputMaybe<Scalars['Int']['input']>;
  rescheduled_pipeline?: InputMaybe<Scalars['Int']['input']>;
  rescheduled_set?: InputMaybe<Scalars['Int']['input']>;
  revenue?: InputMaybe<Scalars['Float']['input']>;
  revenue_value?: InputMaybe<Scalars['Float']['input']>;
  sale?: InputMaybe<Scalars['Int']['input']>;
  sale_closing_opportunity?: InputMaybe<Scalars['Int']['input']>;
  sale_date?: InputMaybe<Scalars['DateTime']['input']>;
  sales_cycle_num?: InputMaybe<Scalars['Int']['input']>;
  schedule_callback_held?: InputMaybe<Scalars['Int']['input']>;
  schedule_callback_set_not_held?: InputMaybe<Scalars['Int']['input']>;
  scheduled_item?: InputMaybe<ScheduleItemCreateNestedOneWithoutStat_Item_AggregationInput>;
  sdr_hold?: InputMaybe<Scalars['Int']['input']>;
  sdr_revenue?: InputMaybe<Scalars['Int']['input']>;
  sdr_sale?: InputMaybe<Scalars['Int']['input']>;
  sdr_transfer?: InputMaybe<Scalars['Int']['input']>;
  sequence_advance?: InputMaybe<Scalars['Int']['input']>;
  sequence_attributed_hold?: InputMaybe<Scalars['Int']['input']>;
  set?: InputMaybe<Scalars['Int']['input']>;
  set_date?: InputMaybe<Scalars['DateTime']['input']>;
  set_site_id?: InputMaybe<Scalars['String']['input']>;
  set_team_id?: InputMaybe<Scalars['String']['input']>;
  set_user_id?: InputMaybe<Scalars['String']['input']>;
  site_id?: InputMaybe<Scalars['String']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sms_received?: InputMaybe<Scalars['Int']['input']>;
  sms_sent?: InputMaybe<Scalars['Int']['input']>;
  step?: InputMaybe<SequenceStepCreateNestedOneWithoutStatItemAggregationInput>;
  talk_time?: InputMaybe<Scalars['Int']['input']>;
  team_id?: InputMaybe<Scalars['String']['input']>;
  ten_minute_downtime_before_activity?: InputMaybe<Scalars['Int']['input']>;
  ten_minute_downtime_before_call?: InputMaybe<Scalars['Int']['input']>;
  time_in_last_phase_sec?: InputMaybe<Scalars['Int']['input']>;
  transfer_answered?: InputMaybe<Scalars['Int']['input']>;
  transfer_answered_ae?: InputMaybe<Scalars['Int']['input']>;
  transfer_answered_ae_unique?: InputMaybe<Scalars['Int']['input']>;
  transfer_answered_sdr?: InputMaybe<Scalars['Int']['input']>;
  transfer_answered_sdr_unique?: InputMaybe<Scalars['Int']['input']>;
  transfer_answered_unique?: InputMaybe<Scalars['Int']['input']>;
  transfer_call_duration_over_x_min?: InputMaybe<Scalars['Int']['input']>;
  transfer_call_duration_over_x_min_ae?: InputMaybe<Scalars['Int']['input']>;
  transfer_call_duration_over_x_min_ae_unique?: InputMaybe<Scalars['Int']['input']>;
  transfer_call_duration_over_x_min_sdr?: InputMaybe<Scalars['Int']['input']>;
  transfer_call_duration_over_x_min_sdr_unique?: InputMaybe<Scalars['Int']['input']>;
  transfer_call_duration_over_x_min_unique?: InputMaybe<Scalars['Int']['input']>;
  transfer_ignored?: InputMaybe<Scalars['Int']['input']>;
  transfer_ignored_ae?: InputMaybe<Scalars['Int']['input']>;
  transfer_ignored_ae_unique?: InputMaybe<Scalars['Int']['input']>;
  transfer_ignored_sdr?: InputMaybe<Scalars['Int']['input']>;
  transfer_ignored_sdr_unique?: InputMaybe<Scalars['Int']['input']>;
  transfer_ignored_unique?: InputMaybe<Scalars['Int']['input']>;
  transfer_ring_time?: InputMaybe<Scalars['Int']['input']>;
  transfer_ring_time_ae?: InputMaybe<Scalars['Int']['input']>;
  transfer_site_id?: InputMaybe<Scalars['String']['input']>;
  transfer_site_to_id?: InputMaybe<Scalars['String']['input']>;
  transfer_team_id?: InputMaybe<Scalars['String']['input']>;
  transfer_team_to_id?: InputMaybe<Scalars['String']['input']>;
  transfer_user_id?: InputMaybe<Scalars['String']['input']>;
  transfer_user_to_id?: InputMaybe<Scalars['String']['input']>;
  transfered_leads?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<StatItemAggregationType>;
  unique_lead_pipeline?: InputMaybe<Scalars['Int']['input']>;
  unique_rescheduled_set?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
  user_role?: InputMaybe<Role>;
  value?: InputMaybe<Scalars['Float']['input']>;
  voicemail_or_no_contact?: InputMaybe<Scalars['Int']['input']>;
  week_group_key?: InputMaybe<Scalars['String']['input']>;
};

export type StatItemAggregationCreateWithoutStepInput = {
  bad_number?: InputMaybe<Scalars['Int']['input']>;
  call_followup?: InputMaybe<Scalars['Int']['input']>;
  channel?: InputMaybe<Channel>;
  closing_opp_held?: InputMaybe<Scalars['Int']['input']>;
  closing_opp_scheduled?: InputMaybe<Scalars['Int']['input']>;
  closing_opps_pipeline?: InputMaybe<Scalars['Int']['input']>;
  cold_call?: InputMaybe<Scalars['Int']['input']>;
  connect?: InputMaybe<Scalars['Int']['input']>;
  connect_talk_time?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  date: Scalars['DateTime']['input'];
  date_group_key?: InputMaybe<Scalars['String']['input']>;
  day: Scalars['Int']['input'];
  days_contact_to_set?: InputMaybe<Scalars['Int']['input']>;
  days_decision_call_to_sale?: InputMaybe<Scalars['Int']['input']>;
  days_first_dial_to_contact?: InputMaybe<Scalars['Int']['input']>;
  days_hold_to_decision_call?: InputMaybe<Scalars['Int']['input']>;
  days_hold_to_sale?: InputMaybe<Scalars['Int']['input']>;
  days_set_out?: InputMaybe<Scalars['Float']['input']>;
  days_set_to_hold?: InputMaybe<Scalars['Int']['input']>;
  days_to_close?: InputMaybe<Scalars['Int']['input']>;
  decision_call?: InputMaybe<Scalars['Int']['input']>;
  decision_call_held?: InputMaybe<Scalars['Int']['input']>;
  decision_call_set?: InputMaybe<Scalars['Int']['input']>;
  decision_call_set_not_held?: InputMaybe<Scalars['Int']['input']>;
  demo_held?: InputMaybe<Scalars['Int']['input']>;
  demo_set_not_held?: InputMaybe<Scalars['Int']['input']>;
  demo_talk_time?: InputMaybe<Scalars['Int']['input']>;
  dial?: InputMaybe<Scalars['Int']['input']>;
  dials_to_set?: InputMaybe<Scalars['Int']['input']>;
  dm_cold_call?: InputMaybe<Scalars['Int']['input']>;
  dm_connect?: InputMaybe<Scalars['Int']['input']>;
  dm_connect_date?: InputMaybe<Scalars['DateTime']['input']>;
  dm_set?: InputMaybe<Scalars['Int']['input']>;
  downtime_before_activity?: InputMaybe<Scalars['Int']['input']>;
  downtime_before_call?: InputMaybe<Scalars['Int']['input']>;
  email_received?: InputMaybe<Scalars['Int']['input']>;
  email_sent?: InputMaybe<Scalars['Int']['input']>;
  first_dial_hour_minute?: InputMaybe<Scalars['Int']['input']>;
  followup_hold?: InputMaybe<Scalars['Int']['input']>;
  general_callback_held?: InputMaybe<Scalars['Int']['input']>;
  general_callback_set_not_held?: InputMaybe<Scalars['Int']['input']>;
  hold?: InputMaybe<Scalars['Int']['input']>;
  hold_date?: InputMaybe<Scalars['DateTime']['input']>;
  hour: Scalars['Int']['input'];
  hour_minute?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  inbound_time_to_first_dial?: InputMaybe<Scalars['Int']['input']>;
  initial_hold?: InputMaybe<Scalars['Int']['input']>;
  initial_pipeline?: InputMaybe<Scalars['Int']['input']>;
  initial_set?: InputMaybe<Scalars['Int']['input']>;
  is_stat_from_transfer?: InputMaybe<Scalars['Int']['input']>;
  lead?: InputMaybe<LeadCreateNestedOneWithoutStat_ItemsInput>;
  lead_activity?: InputMaybe<LeadActivityCreateNestedOneWithoutStat_Item_AggregationInput>;
  lead_activity_pipeline?: InputMaybe<LeadActivityCreateNestedOneWithoutStat_Item_Aggregation_PipelineInput>;
  live_transfer?: InputMaybe<Scalars['Int']['input']>;
  live_transfer_hold?: InputMaybe<Scalars['Int']['input']>;
  live_transfer_sale?: InputMaybe<Scalars['Int']['input']>;
  month_group_key?: InputMaybe<Scalars['String']['input']>;
  ndm_cold_call?: InputMaybe<Scalars['Int']['input']>;
  ndm_connect?: InputMaybe<Scalars['Int']['input']>;
  ndm_set?: InputMaybe<Scalars['Int']['input']>;
  next_phase?: InputMaybe<Phase>;
  organization_id?: InputMaybe<Scalars['String']['input']>;
  otf?: InputMaybe<Scalars['Int']['input']>;
  pipeline?: InputMaybe<Scalars['Int']['input']>;
  pipeline_needs_decision?: InputMaybe<Scalars['Int']['input']>;
  previous_phase?: InputMaybe<Phase>;
  recurring_revenue?: InputMaybe<Scalars['Float']['input']>;
  release?: InputMaybe<Scalars['Int']['input']>;
  rescheduled_hold?: InputMaybe<Scalars['Int']['input']>;
  rescheduled_pipeline?: InputMaybe<Scalars['Int']['input']>;
  rescheduled_set?: InputMaybe<Scalars['Int']['input']>;
  revenue?: InputMaybe<Scalars['Float']['input']>;
  revenue_value?: InputMaybe<Scalars['Float']['input']>;
  sale?: InputMaybe<Scalars['Int']['input']>;
  sale_closing_opportunity?: InputMaybe<Scalars['Int']['input']>;
  sale_date?: InputMaybe<Scalars['DateTime']['input']>;
  sales_cycle_num?: InputMaybe<Scalars['Int']['input']>;
  schedule_callback_held?: InputMaybe<Scalars['Int']['input']>;
  schedule_callback_set_not_held?: InputMaybe<Scalars['Int']['input']>;
  scheduled_item?: InputMaybe<ScheduleItemCreateNestedOneWithoutStat_Item_AggregationInput>;
  sdr_hold?: InputMaybe<Scalars['Int']['input']>;
  sdr_revenue?: InputMaybe<Scalars['Int']['input']>;
  sdr_sale?: InputMaybe<Scalars['Int']['input']>;
  sdr_transfer?: InputMaybe<Scalars['Int']['input']>;
  sequence?: InputMaybe<SequenceCreateNestedOneWithoutStatItemsInput>;
  sequence_advance?: InputMaybe<Scalars['Int']['input']>;
  sequence_attributed_hold?: InputMaybe<Scalars['Int']['input']>;
  set?: InputMaybe<Scalars['Int']['input']>;
  set_date?: InputMaybe<Scalars['DateTime']['input']>;
  set_site_id?: InputMaybe<Scalars['String']['input']>;
  set_team_id?: InputMaybe<Scalars['String']['input']>;
  set_user_id?: InputMaybe<Scalars['String']['input']>;
  site_id?: InputMaybe<Scalars['String']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sms_received?: InputMaybe<Scalars['Int']['input']>;
  sms_sent?: InputMaybe<Scalars['Int']['input']>;
  talk_time?: InputMaybe<Scalars['Int']['input']>;
  team_id?: InputMaybe<Scalars['String']['input']>;
  ten_minute_downtime_before_activity?: InputMaybe<Scalars['Int']['input']>;
  ten_minute_downtime_before_call?: InputMaybe<Scalars['Int']['input']>;
  time_in_last_phase_sec?: InputMaybe<Scalars['Int']['input']>;
  transfer_answered?: InputMaybe<Scalars['Int']['input']>;
  transfer_answered_ae?: InputMaybe<Scalars['Int']['input']>;
  transfer_answered_ae_unique?: InputMaybe<Scalars['Int']['input']>;
  transfer_answered_sdr?: InputMaybe<Scalars['Int']['input']>;
  transfer_answered_sdr_unique?: InputMaybe<Scalars['Int']['input']>;
  transfer_answered_unique?: InputMaybe<Scalars['Int']['input']>;
  transfer_call_duration_over_x_min?: InputMaybe<Scalars['Int']['input']>;
  transfer_call_duration_over_x_min_ae?: InputMaybe<Scalars['Int']['input']>;
  transfer_call_duration_over_x_min_ae_unique?: InputMaybe<Scalars['Int']['input']>;
  transfer_call_duration_over_x_min_sdr?: InputMaybe<Scalars['Int']['input']>;
  transfer_call_duration_over_x_min_sdr_unique?: InputMaybe<Scalars['Int']['input']>;
  transfer_call_duration_over_x_min_unique?: InputMaybe<Scalars['Int']['input']>;
  transfer_ignored?: InputMaybe<Scalars['Int']['input']>;
  transfer_ignored_ae?: InputMaybe<Scalars['Int']['input']>;
  transfer_ignored_ae_unique?: InputMaybe<Scalars['Int']['input']>;
  transfer_ignored_sdr?: InputMaybe<Scalars['Int']['input']>;
  transfer_ignored_sdr_unique?: InputMaybe<Scalars['Int']['input']>;
  transfer_ignored_unique?: InputMaybe<Scalars['Int']['input']>;
  transfer_ring_time?: InputMaybe<Scalars['Int']['input']>;
  transfer_ring_time_ae?: InputMaybe<Scalars['Int']['input']>;
  transfer_site_id?: InputMaybe<Scalars['String']['input']>;
  transfer_site_to_id?: InputMaybe<Scalars['String']['input']>;
  transfer_team_id?: InputMaybe<Scalars['String']['input']>;
  transfer_team_to_id?: InputMaybe<Scalars['String']['input']>;
  transfer_user_id?: InputMaybe<Scalars['String']['input']>;
  transfer_user_to_id?: InputMaybe<Scalars['String']['input']>;
  transfered_leads?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<StatItemAggregationType>;
  unique_lead_pipeline?: InputMaybe<Scalars['Int']['input']>;
  unique_rescheduled_set?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
  user_role?: InputMaybe<Role>;
  value?: InputMaybe<Scalars['Float']['input']>;
  voicemail_or_no_contact?: InputMaybe<Scalars['Int']['input']>;
  week_group_key?: InputMaybe<Scalars['String']['input']>;
};

export type StatItemAggregationListRelationFilter = {
  every?: InputMaybe<StatItemAggregationWhereInput>;
  none?: InputMaybe<StatItemAggregationWhereInput>;
  some?: InputMaybe<StatItemAggregationWhereInput>;
};

export type StatItemAggregationOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
  count?: InputMaybe<SortOrder>;
};

export type StatItemAggregationOrderByWithRelationInput = {
  bad_number?: InputMaybe<SortOrder>;
  call_followup?: InputMaybe<SortOrder>;
  channel?: InputMaybe<SortOrder>;
  closing_opp_held?: InputMaybe<SortOrder>;
  closing_opp_scheduled?: InputMaybe<SortOrder>;
  closing_opps_pipeline?: InputMaybe<SortOrder>;
  cold_call?: InputMaybe<SortOrder>;
  connect?: InputMaybe<SortOrder>;
  connect_talk_time?: InputMaybe<SortOrder>;
  created_at?: InputMaybe<SortOrder>;
  date?: InputMaybe<SortOrder>;
  date_group_key?: InputMaybe<SortOrder>;
  day?: InputMaybe<SortOrder>;
  days_contact_to_set?: InputMaybe<SortOrder>;
  days_decision_call_to_sale?: InputMaybe<SortOrder>;
  days_first_dial_to_contact?: InputMaybe<SortOrder>;
  days_hold_to_decision_call?: InputMaybe<SortOrder>;
  days_hold_to_sale?: InputMaybe<SortOrder>;
  days_set_out?: InputMaybe<SortOrder>;
  days_set_to_hold?: InputMaybe<SortOrder>;
  days_to_close?: InputMaybe<SortOrder>;
  decision_call?: InputMaybe<SortOrder>;
  decision_call_held?: InputMaybe<SortOrder>;
  decision_call_set?: InputMaybe<SortOrder>;
  decision_call_set_not_held?: InputMaybe<SortOrder>;
  demo_held?: InputMaybe<SortOrder>;
  demo_set_not_held?: InputMaybe<SortOrder>;
  demo_talk_time?: InputMaybe<SortOrder>;
  dial?: InputMaybe<SortOrder>;
  dials_to_set?: InputMaybe<SortOrder>;
  dm_cold_call?: InputMaybe<SortOrder>;
  dm_connect?: InputMaybe<SortOrder>;
  dm_connect_date?: InputMaybe<SortOrder>;
  dm_set?: InputMaybe<SortOrder>;
  downtime_before_activity?: InputMaybe<SortOrder>;
  downtime_before_call?: InputMaybe<SortOrder>;
  email_received?: InputMaybe<SortOrder>;
  email_sent?: InputMaybe<SortOrder>;
  first_dial_hour_minute?: InputMaybe<SortOrder>;
  followup_hold?: InputMaybe<SortOrder>;
  general_callback_held?: InputMaybe<SortOrder>;
  general_callback_set_not_held?: InputMaybe<SortOrder>;
  hold?: InputMaybe<SortOrder>;
  hold_date?: InputMaybe<SortOrder>;
  hour?: InputMaybe<SortOrder>;
  hour_minute?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  inbound_time_to_first_dial?: InputMaybe<SortOrder>;
  initial_hold?: InputMaybe<SortOrder>;
  initial_pipeline?: InputMaybe<SortOrder>;
  initial_set?: InputMaybe<SortOrder>;
  is_stat_from_transfer?: InputMaybe<SortOrder>;
  lead?: InputMaybe<LeadOrderByWithRelationInput>;
  lead_activity?: InputMaybe<LeadActivityOrderByWithRelationInput>;
  lead_activity_id?: InputMaybe<SortOrder>;
  lead_activity_pipeline?: InputMaybe<LeadActivityOrderByWithRelationInput>;
  lead_activity_pipeline_id?: InputMaybe<SortOrder>;
  lead_id?: InputMaybe<SortOrder>;
  live_transfer?: InputMaybe<SortOrder>;
  live_transfer_hold?: InputMaybe<SortOrder>;
  live_transfer_sale?: InputMaybe<SortOrder>;
  month_group_key?: InputMaybe<SortOrder>;
  ndm_cold_call?: InputMaybe<SortOrder>;
  ndm_connect?: InputMaybe<SortOrder>;
  ndm_set?: InputMaybe<SortOrder>;
  next_phase?: InputMaybe<SortOrder>;
  organization_id?: InputMaybe<SortOrder>;
  otf?: InputMaybe<SortOrder>;
  pipeline?: InputMaybe<SortOrder>;
  pipeline_needs_decision?: InputMaybe<SortOrder>;
  previous_phase?: InputMaybe<SortOrder>;
  recurring_revenue?: InputMaybe<SortOrder>;
  release?: InputMaybe<SortOrder>;
  rescheduled_hold?: InputMaybe<SortOrder>;
  rescheduled_pipeline?: InputMaybe<SortOrder>;
  rescheduled_set?: InputMaybe<SortOrder>;
  revenue?: InputMaybe<SortOrder>;
  revenue_value?: InputMaybe<SortOrder>;
  sale?: InputMaybe<SortOrder>;
  sale_closing_opportunity?: InputMaybe<SortOrder>;
  sale_date?: InputMaybe<SortOrder>;
  sales_cycle_num?: InputMaybe<SortOrder>;
  schedule_callback_held?: InputMaybe<SortOrder>;
  schedule_callback_set_not_held?: InputMaybe<SortOrder>;
  schedule_item_id?: InputMaybe<SortOrder>;
  scheduled_item?: InputMaybe<ScheduleItemOrderByWithRelationInput>;
  sdr_hold?: InputMaybe<SortOrder>;
  sdr_revenue?: InputMaybe<SortOrder>;
  sdr_sale?: InputMaybe<SortOrder>;
  sdr_transfer?: InputMaybe<SortOrder>;
  sequence?: InputMaybe<SequenceOrderByWithRelationInput>;
  sequence_advance?: InputMaybe<SortOrder>;
  sequence_attributed_hold?: InputMaybe<SortOrder>;
  sequence_id?: InputMaybe<SortOrder>;
  sequence_step_id?: InputMaybe<SortOrder>;
  set?: InputMaybe<SortOrder>;
  set_date?: InputMaybe<SortOrder>;
  set_site_id?: InputMaybe<SortOrder>;
  set_team_id?: InputMaybe<SortOrder>;
  set_user_id?: InputMaybe<SortOrder>;
  site_id?: InputMaybe<SortOrder>;
  skip?: InputMaybe<SortOrder>;
  sms_received?: InputMaybe<SortOrder>;
  sms_sent?: InputMaybe<SortOrder>;
  step?: InputMaybe<SequenceStepOrderByWithRelationInput>;
  talk_time?: InputMaybe<SortOrder>;
  team_id?: InputMaybe<SortOrder>;
  ten_minute_downtime_before_activity?: InputMaybe<SortOrder>;
  ten_minute_downtime_before_call?: InputMaybe<SortOrder>;
  time_in_last_phase_sec?: InputMaybe<SortOrder>;
  transfer_answered?: InputMaybe<SortOrder>;
  transfer_answered_ae?: InputMaybe<SortOrder>;
  transfer_answered_ae_unique?: InputMaybe<SortOrder>;
  transfer_answered_sdr?: InputMaybe<SortOrder>;
  transfer_answered_sdr_unique?: InputMaybe<SortOrder>;
  transfer_answered_unique?: InputMaybe<SortOrder>;
  transfer_call_duration_over_x_min?: InputMaybe<SortOrder>;
  transfer_call_duration_over_x_min_ae?: InputMaybe<SortOrder>;
  transfer_call_duration_over_x_min_ae_unique?: InputMaybe<SortOrder>;
  transfer_call_duration_over_x_min_sdr?: InputMaybe<SortOrder>;
  transfer_call_duration_over_x_min_sdr_unique?: InputMaybe<SortOrder>;
  transfer_call_duration_over_x_min_unique?: InputMaybe<SortOrder>;
  transfer_ignored?: InputMaybe<SortOrder>;
  transfer_ignored_ae?: InputMaybe<SortOrder>;
  transfer_ignored_ae_unique?: InputMaybe<SortOrder>;
  transfer_ignored_sdr?: InputMaybe<SortOrder>;
  transfer_ignored_sdr_unique?: InputMaybe<SortOrder>;
  transfer_ignored_unique?: InputMaybe<SortOrder>;
  transfer_ring_time?: InputMaybe<SortOrder>;
  transfer_ring_time_ae?: InputMaybe<SortOrder>;
  transfer_site_id?: InputMaybe<SortOrder>;
  transfer_site_to_id?: InputMaybe<SortOrder>;
  transfer_team_id?: InputMaybe<SortOrder>;
  transfer_team_to_id?: InputMaybe<SortOrder>;
  transfer_user_id?: InputMaybe<SortOrder>;
  transfer_user_to_id?: InputMaybe<SortOrder>;
  transfered_leads?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  unique_lead_pipeline?: InputMaybe<SortOrder>;
  unique_rescheduled_set?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
  user_id?: InputMaybe<SortOrder>;
  user_role?: InputMaybe<SortOrder>;
  value?: InputMaybe<SortOrder>;
  voicemail_or_no_contact?: InputMaybe<SortOrder>;
  week_group_key?: InputMaybe<SortOrder>;
};

export type StatItemAggregationScalarWhereInput = {
  AND?: InputMaybe<Array<StatItemAggregationScalarWhereInput>>;
  bad_number?: InputMaybe<IntFilter>;
  call_followup?: InputMaybe<IntFilter>;
  channel?: InputMaybe<EnumChannelNullableFilter>;
  closing_opp_held?: InputMaybe<IntFilter>;
  closing_opp_scheduled?: InputMaybe<IntFilter>;
  closing_opps_pipeline?: InputMaybe<IntFilter>;
  cold_call?: InputMaybe<IntFilter>;
  connect?: InputMaybe<IntFilter>;
  connect_talk_time?: InputMaybe<IntFilter>;
  created_at?: InputMaybe<DateTimeFilter>;
  date?: InputMaybe<DateTimeFilter>;
  date_group_key?: InputMaybe<StringFilter>;
  day?: InputMaybe<IntFilter>;
  days_contact_to_set?: InputMaybe<IntNullableFilter>;
  days_decision_call_to_sale?: InputMaybe<IntNullableFilter>;
  days_first_dial_to_contact?: InputMaybe<IntNullableFilter>;
  days_hold_to_decision_call?: InputMaybe<IntNullableFilter>;
  days_hold_to_sale?: InputMaybe<IntNullableFilter>;
  days_set_out?: InputMaybe<FloatNullableFilter>;
  days_set_to_hold?: InputMaybe<IntNullableFilter>;
  days_to_close?: InputMaybe<IntNullableFilter>;
  decision_call?: InputMaybe<IntFilter>;
  decision_call_held?: InputMaybe<IntFilter>;
  decision_call_set?: InputMaybe<IntFilter>;
  decision_call_set_not_held?: InputMaybe<IntFilter>;
  demo_held?: InputMaybe<IntFilter>;
  demo_set_not_held?: InputMaybe<IntFilter>;
  demo_talk_time?: InputMaybe<IntFilter>;
  dial?: InputMaybe<IntFilter>;
  dials_to_set?: InputMaybe<IntNullableFilter>;
  dm_cold_call?: InputMaybe<IntFilter>;
  dm_connect?: InputMaybe<IntFilter>;
  dm_connect_date?: InputMaybe<DateTimeNullableFilter>;
  dm_set?: InputMaybe<IntFilter>;
  downtime_before_activity?: InputMaybe<IntNullableFilter>;
  downtime_before_call?: InputMaybe<IntNullableFilter>;
  email_received?: InputMaybe<IntFilter>;
  email_sent?: InputMaybe<IntFilter>;
  first_dial_hour_minute?: InputMaybe<IntNullableFilter>;
  followup_hold?: InputMaybe<IntFilter>;
  general_callback_held?: InputMaybe<IntFilter>;
  general_callback_set_not_held?: InputMaybe<IntFilter>;
  hold?: InputMaybe<IntFilter>;
  hold_date?: InputMaybe<DateTimeNullableFilter>;
  hour?: InputMaybe<IntFilter>;
  hour_minute?: InputMaybe<IntFilter>;
  id?: InputMaybe<StringFilter>;
  inbound_time_to_first_dial?: InputMaybe<IntNullableFilter>;
  initial_hold?: InputMaybe<IntFilter>;
  initial_pipeline?: InputMaybe<IntFilter>;
  initial_set?: InputMaybe<IntFilter>;
  is_stat_from_transfer?: InputMaybe<IntFilter>;
  lead_activity_id?: InputMaybe<StringNullableFilter>;
  lead_activity_pipeline_id?: InputMaybe<StringNullableFilter>;
  lead_id?: InputMaybe<StringNullableFilter>;
  live_transfer?: InputMaybe<IntNullableFilter>;
  live_transfer_hold?: InputMaybe<IntNullableFilter>;
  live_transfer_sale?: InputMaybe<IntNullableFilter>;
  month_group_key?: InputMaybe<StringFilter>;
  ndm_cold_call?: InputMaybe<IntFilter>;
  ndm_connect?: InputMaybe<IntFilter>;
  ndm_set?: InputMaybe<IntFilter>;
  next_phase?: InputMaybe<EnumPhaseNullableFilter>;
  NOT?: InputMaybe<Array<StatItemAggregationScalarWhereInput>>;
  OR?: InputMaybe<Array<StatItemAggregationScalarWhereInput>>;
  organization_id?: InputMaybe<StringNullableFilter>;
  otf?: InputMaybe<IntFilter>;
  pipeline?: InputMaybe<IntFilter>;
  pipeline_needs_decision?: InputMaybe<IntFilter>;
  previous_phase?: InputMaybe<EnumPhaseNullableFilter>;
  recurring_revenue?: InputMaybe<FloatFilter>;
  release?: InputMaybe<IntFilter>;
  rescheduled_hold?: InputMaybe<IntFilter>;
  rescheduled_pipeline?: InputMaybe<IntFilter>;
  rescheduled_set?: InputMaybe<IntFilter>;
  revenue?: InputMaybe<FloatFilter>;
  revenue_value?: InputMaybe<FloatFilter>;
  sale?: InputMaybe<IntFilter>;
  sale_closing_opportunity?: InputMaybe<IntFilter>;
  sale_date?: InputMaybe<DateTimeNullableFilter>;
  sales_cycle_num?: InputMaybe<IntFilter>;
  schedule_callback_held?: InputMaybe<IntFilter>;
  schedule_callback_set_not_held?: InputMaybe<IntFilter>;
  schedule_item_id?: InputMaybe<StringNullableFilter>;
  sdr_hold?: InputMaybe<IntFilter>;
  sdr_revenue?: InputMaybe<IntFilter>;
  sdr_sale?: InputMaybe<IntFilter>;
  sdr_transfer?: InputMaybe<IntNullableFilter>;
  sequence_advance?: InputMaybe<IntNullableFilter>;
  sequence_attributed_hold?: InputMaybe<IntFilter>;
  sequence_id?: InputMaybe<StringNullableFilter>;
  sequence_step_id?: InputMaybe<StringNullableFilter>;
  set?: InputMaybe<IntFilter>;
  set_date?: InputMaybe<DateTimeNullableFilter>;
  set_site_id?: InputMaybe<StringNullableFilter>;
  set_team_id?: InputMaybe<StringNullableFilter>;
  set_user_id?: InputMaybe<StringNullableFilter>;
  site_id?: InputMaybe<StringNullableFilter>;
  skip?: InputMaybe<IntFilter>;
  sms_received?: InputMaybe<IntFilter>;
  sms_sent?: InputMaybe<IntFilter>;
  talk_time?: InputMaybe<IntFilter>;
  team_id?: InputMaybe<StringNullableFilter>;
  ten_minute_downtime_before_activity?: InputMaybe<IntNullableFilter>;
  ten_minute_downtime_before_call?: InputMaybe<IntNullableFilter>;
  time_in_last_phase_sec?: InputMaybe<IntNullableFilter>;
  transfer_answered?: InputMaybe<IntFilter>;
  transfer_answered_ae?: InputMaybe<IntFilter>;
  transfer_answered_ae_unique?: InputMaybe<IntFilter>;
  transfer_answered_sdr?: InputMaybe<IntFilter>;
  transfer_answered_sdr_unique?: InputMaybe<IntFilter>;
  transfer_answered_unique?: InputMaybe<IntFilter>;
  transfer_call_duration_over_x_min?: InputMaybe<IntFilter>;
  transfer_call_duration_over_x_min_ae?: InputMaybe<IntFilter>;
  transfer_call_duration_over_x_min_ae_unique?: InputMaybe<IntFilter>;
  transfer_call_duration_over_x_min_sdr?: InputMaybe<IntFilter>;
  transfer_call_duration_over_x_min_sdr_unique?: InputMaybe<IntFilter>;
  transfer_call_duration_over_x_min_unique?: InputMaybe<IntFilter>;
  transfer_ignored?: InputMaybe<IntFilter>;
  transfer_ignored_ae?: InputMaybe<IntFilter>;
  transfer_ignored_ae_unique?: InputMaybe<IntFilter>;
  transfer_ignored_sdr?: InputMaybe<IntFilter>;
  transfer_ignored_sdr_unique?: InputMaybe<IntFilter>;
  transfer_ignored_unique?: InputMaybe<IntFilter>;
  transfer_ring_time?: InputMaybe<IntNullableFilter>;
  transfer_ring_time_ae?: InputMaybe<IntNullableFilter>;
  transfer_site_id?: InputMaybe<StringNullableFilter>;
  transfer_site_to_id?: InputMaybe<StringNullableFilter>;
  transfer_team_id?: InputMaybe<StringNullableFilter>;
  transfer_team_to_id?: InputMaybe<StringNullableFilter>;
  transfer_user_id?: InputMaybe<StringNullableFilter>;
  transfer_user_to_id?: InputMaybe<StringNullableFilter>;
  transfered_leads?: InputMaybe<IntFilter>;
  type?: InputMaybe<EnumStatItemAggregationTypeFilter>;
  unique_lead_pipeline?: InputMaybe<IntFilter>;
  unique_rescheduled_set?: InputMaybe<IntFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
  user_id?: InputMaybe<StringNullableFilter>;
  user_role?: InputMaybe<EnumRoleNullableFilter>;
  value?: InputMaybe<FloatFilter>;
  voicemail_or_no_contact?: InputMaybe<IntNullableFilter>;
  week_group_key?: InputMaybe<StringFilter>;
};

export enum StatItemAggregationType {
  Pipeline = 'Pipeline',
  Standard = 'Standard'
}

export type StatItemAggregationUpdateManyMutationInput = {
  bad_number?: InputMaybe<IntFieldUpdateOperationsInput>;
  call_followup?: InputMaybe<IntFieldUpdateOperationsInput>;
  channel?: InputMaybe<NullableEnumChannelFieldUpdateOperationsInput>;
  closing_opp_held?: InputMaybe<IntFieldUpdateOperationsInput>;
  closing_opp_scheduled?: InputMaybe<IntFieldUpdateOperationsInput>;
  closing_opps_pipeline?: InputMaybe<IntFieldUpdateOperationsInput>;
  cold_call?: InputMaybe<IntFieldUpdateOperationsInput>;
  connect?: InputMaybe<IntFieldUpdateOperationsInput>;
  connect_talk_time?: InputMaybe<IntFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  date?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  date_group_key?: InputMaybe<StringFieldUpdateOperationsInput>;
  day?: InputMaybe<IntFieldUpdateOperationsInput>;
  days_contact_to_set?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  days_decision_call_to_sale?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  days_first_dial_to_contact?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  days_hold_to_decision_call?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  days_hold_to_sale?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  days_set_out?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  days_set_to_hold?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  days_to_close?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  decision_call?: InputMaybe<IntFieldUpdateOperationsInput>;
  decision_call_held?: InputMaybe<IntFieldUpdateOperationsInput>;
  decision_call_set?: InputMaybe<IntFieldUpdateOperationsInput>;
  decision_call_set_not_held?: InputMaybe<IntFieldUpdateOperationsInput>;
  demo_held?: InputMaybe<IntFieldUpdateOperationsInput>;
  demo_set_not_held?: InputMaybe<IntFieldUpdateOperationsInput>;
  demo_talk_time?: InputMaybe<IntFieldUpdateOperationsInput>;
  dial?: InputMaybe<IntFieldUpdateOperationsInput>;
  dials_to_set?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  dm_cold_call?: InputMaybe<IntFieldUpdateOperationsInput>;
  dm_connect?: InputMaybe<IntFieldUpdateOperationsInput>;
  dm_connect_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  dm_set?: InputMaybe<IntFieldUpdateOperationsInput>;
  downtime_before_activity?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  downtime_before_call?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  email_received?: InputMaybe<IntFieldUpdateOperationsInput>;
  email_sent?: InputMaybe<IntFieldUpdateOperationsInput>;
  first_dial_hour_minute?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  followup_hold?: InputMaybe<IntFieldUpdateOperationsInput>;
  general_callback_held?: InputMaybe<IntFieldUpdateOperationsInput>;
  general_callback_set_not_held?: InputMaybe<IntFieldUpdateOperationsInput>;
  hold?: InputMaybe<IntFieldUpdateOperationsInput>;
  hold_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  hour?: InputMaybe<IntFieldUpdateOperationsInput>;
  hour_minute?: InputMaybe<IntFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inbound_time_to_first_dial?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  initial_hold?: InputMaybe<IntFieldUpdateOperationsInput>;
  initial_pipeline?: InputMaybe<IntFieldUpdateOperationsInput>;
  initial_set?: InputMaybe<IntFieldUpdateOperationsInput>;
  is_stat_from_transfer?: InputMaybe<IntFieldUpdateOperationsInput>;
  live_transfer?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  live_transfer_hold?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  live_transfer_sale?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  month_group_key?: InputMaybe<StringFieldUpdateOperationsInput>;
  ndm_cold_call?: InputMaybe<IntFieldUpdateOperationsInput>;
  ndm_connect?: InputMaybe<IntFieldUpdateOperationsInput>;
  ndm_set?: InputMaybe<IntFieldUpdateOperationsInput>;
  next_phase?: InputMaybe<NullableEnumPhaseFieldUpdateOperationsInput>;
  organization_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  otf?: InputMaybe<IntFieldUpdateOperationsInput>;
  pipeline?: InputMaybe<IntFieldUpdateOperationsInput>;
  pipeline_needs_decision?: InputMaybe<IntFieldUpdateOperationsInput>;
  previous_phase?: InputMaybe<NullableEnumPhaseFieldUpdateOperationsInput>;
  recurring_revenue?: InputMaybe<FloatFieldUpdateOperationsInput>;
  release?: InputMaybe<IntFieldUpdateOperationsInput>;
  rescheduled_hold?: InputMaybe<IntFieldUpdateOperationsInput>;
  rescheduled_pipeline?: InputMaybe<IntFieldUpdateOperationsInput>;
  rescheduled_set?: InputMaybe<IntFieldUpdateOperationsInput>;
  revenue?: InputMaybe<FloatFieldUpdateOperationsInput>;
  revenue_value?: InputMaybe<FloatFieldUpdateOperationsInput>;
  sale?: InputMaybe<IntFieldUpdateOperationsInput>;
  sale_closing_opportunity?: InputMaybe<IntFieldUpdateOperationsInput>;
  sale_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  sales_cycle_num?: InputMaybe<IntFieldUpdateOperationsInput>;
  schedule_callback_held?: InputMaybe<IntFieldUpdateOperationsInput>;
  schedule_callback_set_not_held?: InputMaybe<IntFieldUpdateOperationsInput>;
  sdr_hold?: InputMaybe<IntFieldUpdateOperationsInput>;
  sdr_revenue?: InputMaybe<IntFieldUpdateOperationsInput>;
  sdr_sale?: InputMaybe<IntFieldUpdateOperationsInput>;
  sdr_transfer?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  sequence_advance?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  sequence_attributed_hold?: InputMaybe<IntFieldUpdateOperationsInput>;
  set?: InputMaybe<IntFieldUpdateOperationsInput>;
  set_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  set_site_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  set_team_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  set_user_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  site_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  skip?: InputMaybe<IntFieldUpdateOperationsInput>;
  sms_received?: InputMaybe<IntFieldUpdateOperationsInput>;
  sms_sent?: InputMaybe<IntFieldUpdateOperationsInput>;
  talk_time?: InputMaybe<IntFieldUpdateOperationsInput>;
  team_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  ten_minute_downtime_before_activity?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  ten_minute_downtime_before_call?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  time_in_last_phase_sec?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  transfer_answered?: InputMaybe<IntFieldUpdateOperationsInput>;
  transfer_answered_ae?: InputMaybe<IntFieldUpdateOperationsInput>;
  transfer_answered_ae_unique?: InputMaybe<IntFieldUpdateOperationsInput>;
  transfer_answered_sdr?: InputMaybe<IntFieldUpdateOperationsInput>;
  transfer_answered_sdr_unique?: InputMaybe<IntFieldUpdateOperationsInput>;
  transfer_answered_unique?: InputMaybe<IntFieldUpdateOperationsInput>;
  transfer_call_duration_over_x_min?: InputMaybe<IntFieldUpdateOperationsInput>;
  transfer_call_duration_over_x_min_ae?: InputMaybe<IntFieldUpdateOperationsInput>;
  transfer_call_duration_over_x_min_ae_unique?: InputMaybe<IntFieldUpdateOperationsInput>;
  transfer_call_duration_over_x_min_sdr?: InputMaybe<IntFieldUpdateOperationsInput>;
  transfer_call_duration_over_x_min_sdr_unique?: InputMaybe<IntFieldUpdateOperationsInput>;
  transfer_call_duration_over_x_min_unique?: InputMaybe<IntFieldUpdateOperationsInput>;
  transfer_ignored?: InputMaybe<IntFieldUpdateOperationsInput>;
  transfer_ignored_ae?: InputMaybe<IntFieldUpdateOperationsInput>;
  transfer_ignored_ae_unique?: InputMaybe<IntFieldUpdateOperationsInput>;
  transfer_ignored_sdr?: InputMaybe<IntFieldUpdateOperationsInput>;
  transfer_ignored_sdr_unique?: InputMaybe<IntFieldUpdateOperationsInput>;
  transfer_ignored_unique?: InputMaybe<IntFieldUpdateOperationsInput>;
  transfer_ring_time?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  transfer_ring_time_ae?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  transfer_site_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transfer_site_to_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transfer_team_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transfer_team_to_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transfer_user_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transfer_user_to_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transfered_leads?: InputMaybe<IntFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumStatItemAggregationTypeFieldUpdateOperationsInput>;
  unique_lead_pipeline?: InputMaybe<IntFieldUpdateOperationsInput>;
  unique_rescheduled_set?: InputMaybe<IntFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  user_role?: InputMaybe<NullableEnumRoleFieldUpdateOperationsInput>;
  value?: InputMaybe<FloatFieldUpdateOperationsInput>;
  voicemail_or_no_contact?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  week_group_key?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type StatItemAggregationUpdateManyWithoutLeadInput = {
  connect?: InputMaybe<Array<StatItemAggregationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StatItemAggregationCreateOrConnectWithoutLeadInput>>;
  create?: InputMaybe<Array<StatItemAggregationCreateWithoutLeadInput>>;
  createMany?: InputMaybe<StatItemAggregationCreateManyLeadInputEnvelope>;
  delete?: InputMaybe<Array<StatItemAggregationWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<StatItemAggregationScalarWhereInput>>;
  disconnect?: InputMaybe<Array<StatItemAggregationWhereUniqueInput>>;
  set?: InputMaybe<Array<StatItemAggregationWhereUniqueInput>>;
  update?: InputMaybe<Array<StatItemAggregationUpdateWithWhereUniqueWithoutLeadInput>>;
  updateMany?: InputMaybe<Array<StatItemAggregationUpdateManyWithWhereWithoutLeadInput>>;
  upsert?: InputMaybe<Array<StatItemAggregationUpsertWithWhereUniqueWithoutLeadInput>>;
};

export type StatItemAggregationUpdateManyWithoutSequenceInput = {
  connect?: InputMaybe<Array<StatItemAggregationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StatItemAggregationCreateOrConnectWithoutSequenceInput>>;
  create?: InputMaybe<Array<StatItemAggregationCreateWithoutSequenceInput>>;
  createMany?: InputMaybe<StatItemAggregationCreateManySequenceInputEnvelope>;
  delete?: InputMaybe<Array<StatItemAggregationWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<StatItemAggregationScalarWhereInput>>;
  disconnect?: InputMaybe<Array<StatItemAggregationWhereUniqueInput>>;
  set?: InputMaybe<Array<StatItemAggregationWhereUniqueInput>>;
  update?: InputMaybe<Array<StatItemAggregationUpdateWithWhereUniqueWithoutSequenceInput>>;
  updateMany?: InputMaybe<Array<StatItemAggregationUpdateManyWithWhereWithoutSequenceInput>>;
  upsert?: InputMaybe<Array<StatItemAggregationUpsertWithWhereUniqueWithoutSequenceInput>>;
};

export type StatItemAggregationUpdateManyWithoutStepInput = {
  connect?: InputMaybe<Array<StatItemAggregationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StatItemAggregationCreateOrConnectWithoutStepInput>>;
  create?: InputMaybe<Array<StatItemAggregationCreateWithoutStepInput>>;
  createMany?: InputMaybe<StatItemAggregationCreateManyStepInputEnvelope>;
  delete?: InputMaybe<Array<StatItemAggregationWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<StatItemAggregationScalarWhereInput>>;
  disconnect?: InputMaybe<Array<StatItemAggregationWhereUniqueInput>>;
  set?: InputMaybe<Array<StatItemAggregationWhereUniqueInput>>;
  update?: InputMaybe<Array<StatItemAggregationUpdateWithWhereUniqueWithoutStepInput>>;
  updateMany?: InputMaybe<Array<StatItemAggregationUpdateManyWithWhereWithoutStepInput>>;
  upsert?: InputMaybe<Array<StatItemAggregationUpsertWithWhereUniqueWithoutStepInput>>;
};

export type StatItemAggregationUpdateManyWithWhereWithoutLeadInput = {
  data: StatItemAggregationUpdateManyMutationInput;
  where: StatItemAggregationScalarWhereInput;
};

export type StatItemAggregationUpdateManyWithWhereWithoutSequenceInput = {
  data: StatItemAggregationUpdateManyMutationInput;
  where: StatItemAggregationScalarWhereInput;
};

export type StatItemAggregationUpdateManyWithWhereWithoutStepInput = {
  data: StatItemAggregationUpdateManyMutationInput;
  where: StatItemAggregationScalarWhereInput;
};

export type StatItemAggregationUpdateOneWithoutLead_Activity_PipelineInput = {
  connect?: InputMaybe<StatItemAggregationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<StatItemAggregationCreateOrConnectWithoutLead_Activity_PipelineInput>;
  create?: InputMaybe<StatItemAggregationCreateWithoutLead_Activity_PipelineInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<StatItemAggregationUpdateWithoutLead_Activity_PipelineInput>;
  upsert?: InputMaybe<StatItemAggregationUpsertWithoutLead_Activity_PipelineInput>;
};

export type StatItemAggregationUpdateOneWithoutLead_ActivityInput = {
  connect?: InputMaybe<StatItemAggregationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<StatItemAggregationCreateOrConnectWithoutLead_ActivityInput>;
  create?: InputMaybe<StatItemAggregationCreateWithoutLead_ActivityInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<StatItemAggregationUpdateWithoutLead_ActivityInput>;
  upsert?: InputMaybe<StatItemAggregationUpsertWithoutLead_ActivityInput>;
};

export type StatItemAggregationUpdateOneWithoutScheduled_ItemInput = {
  connect?: InputMaybe<StatItemAggregationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<StatItemAggregationCreateOrConnectWithoutScheduled_ItemInput>;
  create?: InputMaybe<StatItemAggregationCreateWithoutScheduled_ItemInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<StatItemAggregationUpdateWithoutScheduled_ItemInput>;
  upsert?: InputMaybe<StatItemAggregationUpsertWithoutScheduled_ItemInput>;
};

export type StatItemAggregationUpdateWithoutLead_Activity_PipelineInput = {
  bad_number?: InputMaybe<IntFieldUpdateOperationsInput>;
  call_followup?: InputMaybe<IntFieldUpdateOperationsInput>;
  channel?: InputMaybe<NullableEnumChannelFieldUpdateOperationsInput>;
  closing_opp_held?: InputMaybe<IntFieldUpdateOperationsInput>;
  closing_opp_scheduled?: InputMaybe<IntFieldUpdateOperationsInput>;
  closing_opps_pipeline?: InputMaybe<IntFieldUpdateOperationsInput>;
  cold_call?: InputMaybe<IntFieldUpdateOperationsInput>;
  connect?: InputMaybe<IntFieldUpdateOperationsInput>;
  connect_talk_time?: InputMaybe<IntFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  date?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  date_group_key?: InputMaybe<StringFieldUpdateOperationsInput>;
  day?: InputMaybe<IntFieldUpdateOperationsInput>;
  days_contact_to_set?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  days_decision_call_to_sale?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  days_first_dial_to_contact?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  days_hold_to_decision_call?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  days_hold_to_sale?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  days_set_out?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  days_set_to_hold?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  days_to_close?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  decision_call?: InputMaybe<IntFieldUpdateOperationsInput>;
  decision_call_held?: InputMaybe<IntFieldUpdateOperationsInput>;
  decision_call_set?: InputMaybe<IntFieldUpdateOperationsInput>;
  decision_call_set_not_held?: InputMaybe<IntFieldUpdateOperationsInput>;
  demo_held?: InputMaybe<IntFieldUpdateOperationsInput>;
  demo_set_not_held?: InputMaybe<IntFieldUpdateOperationsInput>;
  demo_talk_time?: InputMaybe<IntFieldUpdateOperationsInput>;
  dial?: InputMaybe<IntFieldUpdateOperationsInput>;
  dials_to_set?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  dm_cold_call?: InputMaybe<IntFieldUpdateOperationsInput>;
  dm_connect?: InputMaybe<IntFieldUpdateOperationsInput>;
  dm_connect_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  dm_set?: InputMaybe<IntFieldUpdateOperationsInput>;
  downtime_before_activity?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  downtime_before_call?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  email_received?: InputMaybe<IntFieldUpdateOperationsInput>;
  email_sent?: InputMaybe<IntFieldUpdateOperationsInput>;
  first_dial_hour_minute?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  followup_hold?: InputMaybe<IntFieldUpdateOperationsInput>;
  general_callback_held?: InputMaybe<IntFieldUpdateOperationsInput>;
  general_callback_set_not_held?: InputMaybe<IntFieldUpdateOperationsInput>;
  hold?: InputMaybe<IntFieldUpdateOperationsInput>;
  hold_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  hour?: InputMaybe<IntFieldUpdateOperationsInput>;
  hour_minute?: InputMaybe<IntFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inbound_time_to_first_dial?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  initial_hold?: InputMaybe<IntFieldUpdateOperationsInput>;
  initial_pipeline?: InputMaybe<IntFieldUpdateOperationsInput>;
  initial_set?: InputMaybe<IntFieldUpdateOperationsInput>;
  is_stat_from_transfer?: InputMaybe<IntFieldUpdateOperationsInput>;
  lead?: InputMaybe<LeadUpdateOneWithoutStat_ItemsInput>;
  lead_activity?: InputMaybe<LeadActivityUpdateOneWithoutStat_Item_AggregationInput>;
  live_transfer?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  live_transfer_hold?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  live_transfer_sale?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  month_group_key?: InputMaybe<StringFieldUpdateOperationsInput>;
  ndm_cold_call?: InputMaybe<IntFieldUpdateOperationsInput>;
  ndm_connect?: InputMaybe<IntFieldUpdateOperationsInput>;
  ndm_set?: InputMaybe<IntFieldUpdateOperationsInput>;
  next_phase?: InputMaybe<NullableEnumPhaseFieldUpdateOperationsInput>;
  organization_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  otf?: InputMaybe<IntFieldUpdateOperationsInput>;
  pipeline?: InputMaybe<IntFieldUpdateOperationsInput>;
  pipeline_needs_decision?: InputMaybe<IntFieldUpdateOperationsInput>;
  previous_phase?: InputMaybe<NullableEnumPhaseFieldUpdateOperationsInput>;
  recurring_revenue?: InputMaybe<FloatFieldUpdateOperationsInput>;
  release?: InputMaybe<IntFieldUpdateOperationsInput>;
  rescheduled_hold?: InputMaybe<IntFieldUpdateOperationsInput>;
  rescheduled_pipeline?: InputMaybe<IntFieldUpdateOperationsInput>;
  rescheduled_set?: InputMaybe<IntFieldUpdateOperationsInput>;
  revenue?: InputMaybe<FloatFieldUpdateOperationsInput>;
  revenue_value?: InputMaybe<FloatFieldUpdateOperationsInput>;
  sale?: InputMaybe<IntFieldUpdateOperationsInput>;
  sale_closing_opportunity?: InputMaybe<IntFieldUpdateOperationsInput>;
  sale_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  sales_cycle_num?: InputMaybe<IntFieldUpdateOperationsInput>;
  schedule_callback_held?: InputMaybe<IntFieldUpdateOperationsInput>;
  schedule_callback_set_not_held?: InputMaybe<IntFieldUpdateOperationsInput>;
  scheduled_item?: InputMaybe<ScheduleItemUpdateOneWithoutStat_Item_AggregationInput>;
  sdr_hold?: InputMaybe<IntFieldUpdateOperationsInput>;
  sdr_revenue?: InputMaybe<IntFieldUpdateOperationsInput>;
  sdr_sale?: InputMaybe<IntFieldUpdateOperationsInput>;
  sdr_transfer?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  sequence?: InputMaybe<SequenceUpdateOneWithoutStatItemsInput>;
  sequence_advance?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  sequence_attributed_hold?: InputMaybe<IntFieldUpdateOperationsInput>;
  set?: InputMaybe<IntFieldUpdateOperationsInput>;
  set_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  set_site_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  set_team_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  set_user_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  site_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  skip?: InputMaybe<IntFieldUpdateOperationsInput>;
  sms_received?: InputMaybe<IntFieldUpdateOperationsInput>;
  sms_sent?: InputMaybe<IntFieldUpdateOperationsInput>;
  step?: InputMaybe<SequenceStepUpdateOneWithoutStatItemAggregationInput>;
  talk_time?: InputMaybe<IntFieldUpdateOperationsInput>;
  team_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  ten_minute_downtime_before_activity?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  ten_minute_downtime_before_call?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  time_in_last_phase_sec?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  transfer_answered?: InputMaybe<IntFieldUpdateOperationsInput>;
  transfer_answered_ae?: InputMaybe<IntFieldUpdateOperationsInput>;
  transfer_answered_ae_unique?: InputMaybe<IntFieldUpdateOperationsInput>;
  transfer_answered_sdr?: InputMaybe<IntFieldUpdateOperationsInput>;
  transfer_answered_sdr_unique?: InputMaybe<IntFieldUpdateOperationsInput>;
  transfer_answered_unique?: InputMaybe<IntFieldUpdateOperationsInput>;
  transfer_call_duration_over_x_min?: InputMaybe<IntFieldUpdateOperationsInput>;
  transfer_call_duration_over_x_min_ae?: InputMaybe<IntFieldUpdateOperationsInput>;
  transfer_call_duration_over_x_min_ae_unique?: InputMaybe<IntFieldUpdateOperationsInput>;
  transfer_call_duration_over_x_min_sdr?: InputMaybe<IntFieldUpdateOperationsInput>;
  transfer_call_duration_over_x_min_sdr_unique?: InputMaybe<IntFieldUpdateOperationsInput>;
  transfer_call_duration_over_x_min_unique?: InputMaybe<IntFieldUpdateOperationsInput>;
  transfer_ignored?: InputMaybe<IntFieldUpdateOperationsInput>;
  transfer_ignored_ae?: InputMaybe<IntFieldUpdateOperationsInput>;
  transfer_ignored_ae_unique?: InputMaybe<IntFieldUpdateOperationsInput>;
  transfer_ignored_sdr?: InputMaybe<IntFieldUpdateOperationsInput>;
  transfer_ignored_sdr_unique?: InputMaybe<IntFieldUpdateOperationsInput>;
  transfer_ignored_unique?: InputMaybe<IntFieldUpdateOperationsInput>;
  transfer_ring_time?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  transfer_ring_time_ae?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  transfer_site_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transfer_site_to_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transfer_team_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transfer_team_to_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transfer_user_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transfer_user_to_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transfered_leads?: InputMaybe<IntFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumStatItemAggregationTypeFieldUpdateOperationsInput>;
  unique_lead_pipeline?: InputMaybe<IntFieldUpdateOperationsInput>;
  unique_rescheduled_set?: InputMaybe<IntFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  user_role?: InputMaybe<NullableEnumRoleFieldUpdateOperationsInput>;
  value?: InputMaybe<FloatFieldUpdateOperationsInput>;
  voicemail_or_no_contact?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  week_group_key?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type StatItemAggregationUpdateWithoutLead_ActivityInput = {
  bad_number?: InputMaybe<IntFieldUpdateOperationsInput>;
  call_followup?: InputMaybe<IntFieldUpdateOperationsInput>;
  channel?: InputMaybe<NullableEnumChannelFieldUpdateOperationsInput>;
  closing_opp_held?: InputMaybe<IntFieldUpdateOperationsInput>;
  closing_opp_scheduled?: InputMaybe<IntFieldUpdateOperationsInput>;
  closing_opps_pipeline?: InputMaybe<IntFieldUpdateOperationsInput>;
  cold_call?: InputMaybe<IntFieldUpdateOperationsInput>;
  connect?: InputMaybe<IntFieldUpdateOperationsInput>;
  connect_talk_time?: InputMaybe<IntFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  date?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  date_group_key?: InputMaybe<StringFieldUpdateOperationsInput>;
  day?: InputMaybe<IntFieldUpdateOperationsInput>;
  days_contact_to_set?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  days_decision_call_to_sale?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  days_first_dial_to_contact?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  days_hold_to_decision_call?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  days_hold_to_sale?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  days_set_out?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  days_set_to_hold?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  days_to_close?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  decision_call?: InputMaybe<IntFieldUpdateOperationsInput>;
  decision_call_held?: InputMaybe<IntFieldUpdateOperationsInput>;
  decision_call_set?: InputMaybe<IntFieldUpdateOperationsInput>;
  decision_call_set_not_held?: InputMaybe<IntFieldUpdateOperationsInput>;
  demo_held?: InputMaybe<IntFieldUpdateOperationsInput>;
  demo_set_not_held?: InputMaybe<IntFieldUpdateOperationsInput>;
  demo_talk_time?: InputMaybe<IntFieldUpdateOperationsInput>;
  dial?: InputMaybe<IntFieldUpdateOperationsInput>;
  dials_to_set?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  dm_cold_call?: InputMaybe<IntFieldUpdateOperationsInput>;
  dm_connect?: InputMaybe<IntFieldUpdateOperationsInput>;
  dm_connect_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  dm_set?: InputMaybe<IntFieldUpdateOperationsInput>;
  downtime_before_activity?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  downtime_before_call?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  email_received?: InputMaybe<IntFieldUpdateOperationsInput>;
  email_sent?: InputMaybe<IntFieldUpdateOperationsInput>;
  first_dial_hour_minute?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  followup_hold?: InputMaybe<IntFieldUpdateOperationsInput>;
  general_callback_held?: InputMaybe<IntFieldUpdateOperationsInput>;
  general_callback_set_not_held?: InputMaybe<IntFieldUpdateOperationsInput>;
  hold?: InputMaybe<IntFieldUpdateOperationsInput>;
  hold_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  hour?: InputMaybe<IntFieldUpdateOperationsInput>;
  hour_minute?: InputMaybe<IntFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inbound_time_to_first_dial?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  initial_hold?: InputMaybe<IntFieldUpdateOperationsInput>;
  initial_pipeline?: InputMaybe<IntFieldUpdateOperationsInput>;
  initial_set?: InputMaybe<IntFieldUpdateOperationsInput>;
  is_stat_from_transfer?: InputMaybe<IntFieldUpdateOperationsInput>;
  lead?: InputMaybe<LeadUpdateOneWithoutStat_ItemsInput>;
  lead_activity_pipeline?: InputMaybe<LeadActivityUpdateOneWithoutStat_Item_Aggregation_PipelineInput>;
  live_transfer?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  live_transfer_hold?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  live_transfer_sale?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  month_group_key?: InputMaybe<StringFieldUpdateOperationsInput>;
  ndm_cold_call?: InputMaybe<IntFieldUpdateOperationsInput>;
  ndm_connect?: InputMaybe<IntFieldUpdateOperationsInput>;
  ndm_set?: InputMaybe<IntFieldUpdateOperationsInput>;
  next_phase?: InputMaybe<NullableEnumPhaseFieldUpdateOperationsInput>;
  organization_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  otf?: InputMaybe<IntFieldUpdateOperationsInput>;
  pipeline?: InputMaybe<IntFieldUpdateOperationsInput>;
  pipeline_needs_decision?: InputMaybe<IntFieldUpdateOperationsInput>;
  previous_phase?: InputMaybe<NullableEnumPhaseFieldUpdateOperationsInput>;
  recurring_revenue?: InputMaybe<FloatFieldUpdateOperationsInput>;
  release?: InputMaybe<IntFieldUpdateOperationsInput>;
  rescheduled_hold?: InputMaybe<IntFieldUpdateOperationsInput>;
  rescheduled_pipeline?: InputMaybe<IntFieldUpdateOperationsInput>;
  rescheduled_set?: InputMaybe<IntFieldUpdateOperationsInput>;
  revenue?: InputMaybe<FloatFieldUpdateOperationsInput>;
  revenue_value?: InputMaybe<FloatFieldUpdateOperationsInput>;
  sale?: InputMaybe<IntFieldUpdateOperationsInput>;
  sale_closing_opportunity?: InputMaybe<IntFieldUpdateOperationsInput>;
  sale_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  sales_cycle_num?: InputMaybe<IntFieldUpdateOperationsInput>;
  schedule_callback_held?: InputMaybe<IntFieldUpdateOperationsInput>;
  schedule_callback_set_not_held?: InputMaybe<IntFieldUpdateOperationsInput>;
  scheduled_item?: InputMaybe<ScheduleItemUpdateOneWithoutStat_Item_AggregationInput>;
  sdr_hold?: InputMaybe<IntFieldUpdateOperationsInput>;
  sdr_revenue?: InputMaybe<IntFieldUpdateOperationsInput>;
  sdr_sale?: InputMaybe<IntFieldUpdateOperationsInput>;
  sdr_transfer?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  sequence?: InputMaybe<SequenceUpdateOneWithoutStatItemsInput>;
  sequence_advance?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  sequence_attributed_hold?: InputMaybe<IntFieldUpdateOperationsInput>;
  set?: InputMaybe<IntFieldUpdateOperationsInput>;
  set_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  set_site_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  set_team_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  set_user_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  site_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  skip?: InputMaybe<IntFieldUpdateOperationsInput>;
  sms_received?: InputMaybe<IntFieldUpdateOperationsInput>;
  sms_sent?: InputMaybe<IntFieldUpdateOperationsInput>;
  step?: InputMaybe<SequenceStepUpdateOneWithoutStatItemAggregationInput>;
  talk_time?: InputMaybe<IntFieldUpdateOperationsInput>;
  team_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  ten_minute_downtime_before_activity?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  ten_minute_downtime_before_call?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  time_in_last_phase_sec?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  transfer_answered?: InputMaybe<IntFieldUpdateOperationsInput>;
  transfer_answered_ae?: InputMaybe<IntFieldUpdateOperationsInput>;
  transfer_answered_ae_unique?: InputMaybe<IntFieldUpdateOperationsInput>;
  transfer_answered_sdr?: InputMaybe<IntFieldUpdateOperationsInput>;
  transfer_answered_sdr_unique?: InputMaybe<IntFieldUpdateOperationsInput>;
  transfer_answered_unique?: InputMaybe<IntFieldUpdateOperationsInput>;
  transfer_call_duration_over_x_min?: InputMaybe<IntFieldUpdateOperationsInput>;
  transfer_call_duration_over_x_min_ae?: InputMaybe<IntFieldUpdateOperationsInput>;
  transfer_call_duration_over_x_min_ae_unique?: InputMaybe<IntFieldUpdateOperationsInput>;
  transfer_call_duration_over_x_min_sdr?: InputMaybe<IntFieldUpdateOperationsInput>;
  transfer_call_duration_over_x_min_sdr_unique?: InputMaybe<IntFieldUpdateOperationsInput>;
  transfer_call_duration_over_x_min_unique?: InputMaybe<IntFieldUpdateOperationsInput>;
  transfer_ignored?: InputMaybe<IntFieldUpdateOperationsInput>;
  transfer_ignored_ae?: InputMaybe<IntFieldUpdateOperationsInput>;
  transfer_ignored_ae_unique?: InputMaybe<IntFieldUpdateOperationsInput>;
  transfer_ignored_sdr?: InputMaybe<IntFieldUpdateOperationsInput>;
  transfer_ignored_sdr_unique?: InputMaybe<IntFieldUpdateOperationsInput>;
  transfer_ignored_unique?: InputMaybe<IntFieldUpdateOperationsInput>;
  transfer_ring_time?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  transfer_ring_time_ae?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  transfer_site_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transfer_site_to_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transfer_team_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transfer_team_to_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transfer_user_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transfer_user_to_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transfered_leads?: InputMaybe<IntFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumStatItemAggregationTypeFieldUpdateOperationsInput>;
  unique_lead_pipeline?: InputMaybe<IntFieldUpdateOperationsInput>;
  unique_rescheduled_set?: InputMaybe<IntFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  user_role?: InputMaybe<NullableEnumRoleFieldUpdateOperationsInput>;
  value?: InputMaybe<FloatFieldUpdateOperationsInput>;
  voicemail_or_no_contact?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  week_group_key?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type StatItemAggregationUpdateWithoutLeadInput = {
  bad_number?: InputMaybe<IntFieldUpdateOperationsInput>;
  call_followup?: InputMaybe<IntFieldUpdateOperationsInput>;
  channel?: InputMaybe<NullableEnumChannelFieldUpdateOperationsInput>;
  closing_opp_held?: InputMaybe<IntFieldUpdateOperationsInput>;
  closing_opp_scheduled?: InputMaybe<IntFieldUpdateOperationsInput>;
  closing_opps_pipeline?: InputMaybe<IntFieldUpdateOperationsInput>;
  cold_call?: InputMaybe<IntFieldUpdateOperationsInput>;
  connect?: InputMaybe<IntFieldUpdateOperationsInput>;
  connect_talk_time?: InputMaybe<IntFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  date?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  date_group_key?: InputMaybe<StringFieldUpdateOperationsInput>;
  day?: InputMaybe<IntFieldUpdateOperationsInput>;
  days_contact_to_set?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  days_decision_call_to_sale?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  days_first_dial_to_contact?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  days_hold_to_decision_call?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  days_hold_to_sale?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  days_set_out?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  days_set_to_hold?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  days_to_close?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  decision_call?: InputMaybe<IntFieldUpdateOperationsInput>;
  decision_call_held?: InputMaybe<IntFieldUpdateOperationsInput>;
  decision_call_set?: InputMaybe<IntFieldUpdateOperationsInput>;
  decision_call_set_not_held?: InputMaybe<IntFieldUpdateOperationsInput>;
  demo_held?: InputMaybe<IntFieldUpdateOperationsInput>;
  demo_set_not_held?: InputMaybe<IntFieldUpdateOperationsInput>;
  demo_talk_time?: InputMaybe<IntFieldUpdateOperationsInput>;
  dial?: InputMaybe<IntFieldUpdateOperationsInput>;
  dials_to_set?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  dm_cold_call?: InputMaybe<IntFieldUpdateOperationsInput>;
  dm_connect?: InputMaybe<IntFieldUpdateOperationsInput>;
  dm_connect_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  dm_set?: InputMaybe<IntFieldUpdateOperationsInput>;
  downtime_before_activity?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  downtime_before_call?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  email_received?: InputMaybe<IntFieldUpdateOperationsInput>;
  email_sent?: InputMaybe<IntFieldUpdateOperationsInput>;
  first_dial_hour_minute?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  followup_hold?: InputMaybe<IntFieldUpdateOperationsInput>;
  general_callback_held?: InputMaybe<IntFieldUpdateOperationsInput>;
  general_callback_set_not_held?: InputMaybe<IntFieldUpdateOperationsInput>;
  hold?: InputMaybe<IntFieldUpdateOperationsInput>;
  hold_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  hour?: InputMaybe<IntFieldUpdateOperationsInput>;
  hour_minute?: InputMaybe<IntFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inbound_time_to_first_dial?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  initial_hold?: InputMaybe<IntFieldUpdateOperationsInput>;
  initial_pipeline?: InputMaybe<IntFieldUpdateOperationsInput>;
  initial_set?: InputMaybe<IntFieldUpdateOperationsInput>;
  is_stat_from_transfer?: InputMaybe<IntFieldUpdateOperationsInput>;
  lead_activity?: InputMaybe<LeadActivityUpdateOneWithoutStat_Item_AggregationInput>;
  lead_activity_pipeline?: InputMaybe<LeadActivityUpdateOneWithoutStat_Item_Aggregation_PipelineInput>;
  live_transfer?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  live_transfer_hold?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  live_transfer_sale?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  month_group_key?: InputMaybe<StringFieldUpdateOperationsInput>;
  ndm_cold_call?: InputMaybe<IntFieldUpdateOperationsInput>;
  ndm_connect?: InputMaybe<IntFieldUpdateOperationsInput>;
  ndm_set?: InputMaybe<IntFieldUpdateOperationsInput>;
  next_phase?: InputMaybe<NullableEnumPhaseFieldUpdateOperationsInput>;
  organization_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  otf?: InputMaybe<IntFieldUpdateOperationsInput>;
  pipeline?: InputMaybe<IntFieldUpdateOperationsInput>;
  pipeline_needs_decision?: InputMaybe<IntFieldUpdateOperationsInput>;
  previous_phase?: InputMaybe<NullableEnumPhaseFieldUpdateOperationsInput>;
  recurring_revenue?: InputMaybe<FloatFieldUpdateOperationsInput>;
  release?: InputMaybe<IntFieldUpdateOperationsInput>;
  rescheduled_hold?: InputMaybe<IntFieldUpdateOperationsInput>;
  rescheduled_pipeline?: InputMaybe<IntFieldUpdateOperationsInput>;
  rescheduled_set?: InputMaybe<IntFieldUpdateOperationsInput>;
  revenue?: InputMaybe<FloatFieldUpdateOperationsInput>;
  revenue_value?: InputMaybe<FloatFieldUpdateOperationsInput>;
  sale?: InputMaybe<IntFieldUpdateOperationsInput>;
  sale_closing_opportunity?: InputMaybe<IntFieldUpdateOperationsInput>;
  sale_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  sales_cycle_num?: InputMaybe<IntFieldUpdateOperationsInput>;
  schedule_callback_held?: InputMaybe<IntFieldUpdateOperationsInput>;
  schedule_callback_set_not_held?: InputMaybe<IntFieldUpdateOperationsInput>;
  scheduled_item?: InputMaybe<ScheduleItemUpdateOneWithoutStat_Item_AggregationInput>;
  sdr_hold?: InputMaybe<IntFieldUpdateOperationsInput>;
  sdr_revenue?: InputMaybe<IntFieldUpdateOperationsInput>;
  sdr_sale?: InputMaybe<IntFieldUpdateOperationsInput>;
  sdr_transfer?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  sequence?: InputMaybe<SequenceUpdateOneWithoutStatItemsInput>;
  sequence_advance?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  sequence_attributed_hold?: InputMaybe<IntFieldUpdateOperationsInput>;
  set?: InputMaybe<IntFieldUpdateOperationsInput>;
  set_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  set_site_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  set_team_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  set_user_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  site_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  skip?: InputMaybe<IntFieldUpdateOperationsInput>;
  sms_received?: InputMaybe<IntFieldUpdateOperationsInput>;
  sms_sent?: InputMaybe<IntFieldUpdateOperationsInput>;
  step?: InputMaybe<SequenceStepUpdateOneWithoutStatItemAggregationInput>;
  talk_time?: InputMaybe<IntFieldUpdateOperationsInput>;
  team_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  ten_minute_downtime_before_activity?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  ten_minute_downtime_before_call?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  time_in_last_phase_sec?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  transfer_answered?: InputMaybe<IntFieldUpdateOperationsInput>;
  transfer_answered_ae?: InputMaybe<IntFieldUpdateOperationsInput>;
  transfer_answered_ae_unique?: InputMaybe<IntFieldUpdateOperationsInput>;
  transfer_answered_sdr?: InputMaybe<IntFieldUpdateOperationsInput>;
  transfer_answered_sdr_unique?: InputMaybe<IntFieldUpdateOperationsInput>;
  transfer_answered_unique?: InputMaybe<IntFieldUpdateOperationsInput>;
  transfer_call_duration_over_x_min?: InputMaybe<IntFieldUpdateOperationsInput>;
  transfer_call_duration_over_x_min_ae?: InputMaybe<IntFieldUpdateOperationsInput>;
  transfer_call_duration_over_x_min_ae_unique?: InputMaybe<IntFieldUpdateOperationsInput>;
  transfer_call_duration_over_x_min_sdr?: InputMaybe<IntFieldUpdateOperationsInput>;
  transfer_call_duration_over_x_min_sdr_unique?: InputMaybe<IntFieldUpdateOperationsInput>;
  transfer_call_duration_over_x_min_unique?: InputMaybe<IntFieldUpdateOperationsInput>;
  transfer_ignored?: InputMaybe<IntFieldUpdateOperationsInput>;
  transfer_ignored_ae?: InputMaybe<IntFieldUpdateOperationsInput>;
  transfer_ignored_ae_unique?: InputMaybe<IntFieldUpdateOperationsInput>;
  transfer_ignored_sdr?: InputMaybe<IntFieldUpdateOperationsInput>;
  transfer_ignored_sdr_unique?: InputMaybe<IntFieldUpdateOperationsInput>;
  transfer_ignored_unique?: InputMaybe<IntFieldUpdateOperationsInput>;
  transfer_ring_time?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  transfer_ring_time_ae?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  transfer_site_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transfer_site_to_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transfer_team_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transfer_team_to_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transfer_user_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transfer_user_to_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transfered_leads?: InputMaybe<IntFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumStatItemAggregationTypeFieldUpdateOperationsInput>;
  unique_lead_pipeline?: InputMaybe<IntFieldUpdateOperationsInput>;
  unique_rescheduled_set?: InputMaybe<IntFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  user_role?: InputMaybe<NullableEnumRoleFieldUpdateOperationsInput>;
  value?: InputMaybe<FloatFieldUpdateOperationsInput>;
  voicemail_or_no_contact?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  week_group_key?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type StatItemAggregationUpdateWithoutScheduled_ItemInput = {
  bad_number?: InputMaybe<IntFieldUpdateOperationsInput>;
  call_followup?: InputMaybe<IntFieldUpdateOperationsInput>;
  channel?: InputMaybe<NullableEnumChannelFieldUpdateOperationsInput>;
  closing_opp_held?: InputMaybe<IntFieldUpdateOperationsInput>;
  closing_opp_scheduled?: InputMaybe<IntFieldUpdateOperationsInput>;
  closing_opps_pipeline?: InputMaybe<IntFieldUpdateOperationsInput>;
  cold_call?: InputMaybe<IntFieldUpdateOperationsInput>;
  connect?: InputMaybe<IntFieldUpdateOperationsInput>;
  connect_talk_time?: InputMaybe<IntFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  date?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  date_group_key?: InputMaybe<StringFieldUpdateOperationsInput>;
  day?: InputMaybe<IntFieldUpdateOperationsInput>;
  days_contact_to_set?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  days_decision_call_to_sale?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  days_first_dial_to_contact?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  days_hold_to_decision_call?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  days_hold_to_sale?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  days_set_out?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  days_set_to_hold?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  days_to_close?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  decision_call?: InputMaybe<IntFieldUpdateOperationsInput>;
  decision_call_held?: InputMaybe<IntFieldUpdateOperationsInput>;
  decision_call_set?: InputMaybe<IntFieldUpdateOperationsInput>;
  decision_call_set_not_held?: InputMaybe<IntFieldUpdateOperationsInput>;
  demo_held?: InputMaybe<IntFieldUpdateOperationsInput>;
  demo_set_not_held?: InputMaybe<IntFieldUpdateOperationsInput>;
  demo_talk_time?: InputMaybe<IntFieldUpdateOperationsInput>;
  dial?: InputMaybe<IntFieldUpdateOperationsInput>;
  dials_to_set?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  dm_cold_call?: InputMaybe<IntFieldUpdateOperationsInput>;
  dm_connect?: InputMaybe<IntFieldUpdateOperationsInput>;
  dm_connect_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  dm_set?: InputMaybe<IntFieldUpdateOperationsInput>;
  downtime_before_activity?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  downtime_before_call?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  email_received?: InputMaybe<IntFieldUpdateOperationsInput>;
  email_sent?: InputMaybe<IntFieldUpdateOperationsInput>;
  first_dial_hour_minute?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  followup_hold?: InputMaybe<IntFieldUpdateOperationsInput>;
  general_callback_held?: InputMaybe<IntFieldUpdateOperationsInput>;
  general_callback_set_not_held?: InputMaybe<IntFieldUpdateOperationsInput>;
  hold?: InputMaybe<IntFieldUpdateOperationsInput>;
  hold_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  hour?: InputMaybe<IntFieldUpdateOperationsInput>;
  hour_minute?: InputMaybe<IntFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inbound_time_to_first_dial?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  initial_hold?: InputMaybe<IntFieldUpdateOperationsInput>;
  initial_pipeline?: InputMaybe<IntFieldUpdateOperationsInput>;
  initial_set?: InputMaybe<IntFieldUpdateOperationsInput>;
  is_stat_from_transfer?: InputMaybe<IntFieldUpdateOperationsInput>;
  lead?: InputMaybe<LeadUpdateOneWithoutStat_ItemsInput>;
  lead_activity?: InputMaybe<LeadActivityUpdateOneWithoutStat_Item_AggregationInput>;
  lead_activity_pipeline?: InputMaybe<LeadActivityUpdateOneWithoutStat_Item_Aggregation_PipelineInput>;
  live_transfer?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  live_transfer_hold?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  live_transfer_sale?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  month_group_key?: InputMaybe<StringFieldUpdateOperationsInput>;
  ndm_cold_call?: InputMaybe<IntFieldUpdateOperationsInput>;
  ndm_connect?: InputMaybe<IntFieldUpdateOperationsInput>;
  ndm_set?: InputMaybe<IntFieldUpdateOperationsInput>;
  next_phase?: InputMaybe<NullableEnumPhaseFieldUpdateOperationsInput>;
  organization_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  otf?: InputMaybe<IntFieldUpdateOperationsInput>;
  pipeline?: InputMaybe<IntFieldUpdateOperationsInput>;
  pipeline_needs_decision?: InputMaybe<IntFieldUpdateOperationsInput>;
  previous_phase?: InputMaybe<NullableEnumPhaseFieldUpdateOperationsInput>;
  recurring_revenue?: InputMaybe<FloatFieldUpdateOperationsInput>;
  release?: InputMaybe<IntFieldUpdateOperationsInput>;
  rescheduled_hold?: InputMaybe<IntFieldUpdateOperationsInput>;
  rescheduled_pipeline?: InputMaybe<IntFieldUpdateOperationsInput>;
  rescheduled_set?: InputMaybe<IntFieldUpdateOperationsInput>;
  revenue?: InputMaybe<FloatFieldUpdateOperationsInput>;
  revenue_value?: InputMaybe<FloatFieldUpdateOperationsInput>;
  sale?: InputMaybe<IntFieldUpdateOperationsInput>;
  sale_closing_opportunity?: InputMaybe<IntFieldUpdateOperationsInput>;
  sale_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  sales_cycle_num?: InputMaybe<IntFieldUpdateOperationsInput>;
  schedule_callback_held?: InputMaybe<IntFieldUpdateOperationsInput>;
  schedule_callback_set_not_held?: InputMaybe<IntFieldUpdateOperationsInput>;
  sdr_hold?: InputMaybe<IntFieldUpdateOperationsInput>;
  sdr_revenue?: InputMaybe<IntFieldUpdateOperationsInput>;
  sdr_sale?: InputMaybe<IntFieldUpdateOperationsInput>;
  sdr_transfer?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  sequence?: InputMaybe<SequenceUpdateOneWithoutStatItemsInput>;
  sequence_advance?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  sequence_attributed_hold?: InputMaybe<IntFieldUpdateOperationsInput>;
  set?: InputMaybe<IntFieldUpdateOperationsInput>;
  set_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  set_site_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  set_team_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  set_user_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  site_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  skip?: InputMaybe<IntFieldUpdateOperationsInput>;
  sms_received?: InputMaybe<IntFieldUpdateOperationsInput>;
  sms_sent?: InputMaybe<IntFieldUpdateOperationsInput>;
  step?: InputMaybe<SequenceStepUpdateOneWithoutStatItemAggregationInput>;
  talk_time?: InputMaybe<IntFieldUpdateOperationsInput>;
  team_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  ten_minute_downtime_before_activity?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  ten_minute_downtime_before_call?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  time_in_last_phase_sec?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  transfer_answered?: InputMaybe<IntFieldUpdateOperationsInput>;
  transfer_answered_ae?: InputMaybe<IntFieldUpdateOperationsInput>;
  transfer_answered_ae_unique?: InputMaybe<IntFieldUpdateOperationsInput>;
  transfer_answered_sdr?: InputMaybe<IntFieldUpdateOperationsInput>;
  transfer_answered_sdr_unique?: InputMaybe<IntFieldUpdateOperationsInput>;
  transfer_answered_unique?: InputMaybe<IntFieldUpdateOperationsInput>;
  transfer_call_duration_over_x_min?: InputMaybe<IntFieldUpdateOperationsInput>;
  transfer_call_duration_over_x_min_ae?: InputMaybe<IntFieldUpdateOperationsInput>;
  transfer_call_duration_over_x_min_ae_unique?: InputMaybe<IntFieldUpdateOperationsInput>;
  transfer_call_duration_over_x_min_sdr?: InputMaybe<IntFieldUpdateOperationsInput>;
  transfer_call_duration_over_x_min_sdr_unique?: InputMaybe<IntFieldUpdateOperationsInput>;
  transfer_call_duration_over_x_min_unique?: InputMaybe<IntFieldUpdateOperationsInput>;
  transfer_ignored?: InputMaybe<IntFieldUpdateOperationsInput>;
  transfer_ignored_ae?: InputMaybe<IntFieldUpdateOperationsInput>;
  transfer_ignored_ae_unique?: InputMaybe<IntFieldUpdateOperationsInput>;
  transfer_ignored_sdr?: InputMaybe<IntFieldUpdateOperationsInput>;
  transfer_ignored_sdr_unique?: InputMaybe<IntFieldUpdateOperationsInput>;
  transfer_ignored_unique?: InputMaybe<IntFieldUpdateOperationsInput>;
  transfer_ring_time?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  transfer_ring_time_ae?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  transfer_site_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transfer_site_to_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transfer_team_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transfer_team_to_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transfer_user_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transfer_user_to_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transfered_leads?: InputMaybe<IntFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumStatItemAggregationTypeFieldUpdateOperationsInput>;
  unique_lead_pipeline?: InputMaybe<IntFieldUpdateOperationsInput>;
  unique_rescheduled_set?: InputMaybe<IntFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  user_role?: InputMaybe<NullableEnumRoleFieldUpdateOperationsInput>;
  value?: InputMaybe<FloatFieldUpdateOperationsInput>;
  voicemail_or_no_contact?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  week_group_key?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type StatItemAggregationUpdateWithoutSequenceInput = {
  bad_number?: InputMaybe<IntFieldUpdateOperationsInput>;
  call_followup?: InputMaybe<IntFieldUpdateOperationsInput>;
  channel?: InputMaybe<NullableEnumChannelFieldUpdateOperationsInput>;
  closing_opp_held?: InputMaybe<IntFieldUpdateOperationsInput>;
  closing_opp_scheduled?: InputMaybe<IntFieldUpdateOperationsInput>;
  closing_opps_pipeline?: InputMaybe<IntFieldUpdateOperationsInput>;
  cold_call?: InputMaybe<IntFieldUpdateOperationsInput>;
  connect?: InputMaybe<IntFieldUpdateOperationsInput>;
  connect_talk_time?: InputMaybe<IntFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  date?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  date_group_key?: InputMaybe<StringFieldUpdateOperationsInput>;
  day?: InputMaybe<IntFieldUpdateOperationsInput>;
  days_contact_to_set?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  days_decision_call_to_sale?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  days_first_dial_to_contact?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  days_hold_to_decision_call?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  days_hold_to_sale?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  days_set_out?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  days_set_to_hold?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  days_to_close?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  decision_call?: InputMaybe<IntFieldUpdateOperationsInput>;
  decision_call_held?: InputMaybe<IntFieldUpdateOperationsInput>;
  decision_call_set?: InputMaybe<IntFieldUpdateOperationsInput>;
  decision_call_set_not_held?: InputMaybe<IntFieldUpdateOperationsInput>;
  demo_held?: InputMaybe<IntFieldUpdateOperationsInput>;
  demo_set_not_held?: InputMaybe<IntFieldUpdateOperationsInput>;
  demo_talk_time?: InputMaybe<IntFieldUpdateOperationsInput>;
  dial?: InputMaybe<IntFieldUpdateOperationsInput>;
  dials_to_set?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  dm_cold_call?: InputMaybe<IntFieldUpdateOperationsInput>;
  dm_connect?: InputMaybe<IntFieldUpdateOperationsInput>;
  dm_connect_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  dm_set?: InputMaybe<IntFieldUpdateOperationsInput>;
  downtime_before_activity?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  downtime_before_call?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  email_received?: InputMaybe<IntFieldUpdateOperationsInput>;
  email_sent?: InputMaybe<IntFieldUpdateOperationsInput>;
  first_dial_hour_minute?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  followup_hold?: InputMaybe<IntFieldUpdateOperationsInput>;
  general_callback_held?: InputMaybe<IntFieldUpdateOperationsInput>;
  general_callback_set_not_held?: InputMaybe<IntFieldUpdateOperationsInput>;
  hold?: InputMaybe<IntFieldUpdateOperationsInput>;
  hold_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  hour?: InputMaybe<IntFieldUpdateOperationsInput>;
  hour_minute?: InputMaybe<IntFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inbound_time_to_first_dial?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  initial_hold?: InputMaybe<IntFieldUpdateOperationsInput>;
  initial_pipeline?: InputMaybe<IntFieldUpdateOperationsInput>;
  initial_set?: InputMaybe<IntFieldUpdateOperationsInput>;
  is_stat_from_transfer?: InputMaybe<IntFieldUpdateOperationsInput>;
  lead?: InputMaybe<LeadUpdateOneWithoutStat_ItemsInput>;
  lead_activity?: InputMaybe<LeadActivityUpdateOneWithoutStat_Item_AggregationInput>;
  lead_activity_pipeline?: InputMaybe<LeadActivityUpdateOneWithoutStat_Item_Aggregation_PipelineInput>;
  live_transfer?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  live_transfer_hold?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  live_transfer_sale?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  month_group_key?: InputMaybe<StringFieldUpdateOperationsInput>;
  ndm_cold_call?: InputMaybe<IntFieldUpdateOperationsInput>;
  ndm_connect?: InputMaybe<IntFieldUpdateOperationsInput>;
  ndm_set?: InputMaybe<IntFieldUpdateOperationsInput>;
  next_phase?: InputMaybe<NullableEnumPhaseFieldUpdateOperationsInput>;
  organization_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  otf?: InputMaybe<IntFieldUpdateOperationsInput>;
  pipeline?: InputMaybe<IntFieldUpdateOperationsInput>;
  pipeline_needs_decision?: InputMaybe<IntFieldUpdateOperationsInput>;
  previous_phase?: InputMaybe<NullableEnumPhaseFieldUpdateOperationsInput>;
  recurring_revenue?: InputMaybe<FloatFieldUpdateOperationsInput>;
  release?: InputMaybe<IntFieldUpdateOperationsInput>;
  rescheduled_hold?: InputMaybe<IntFieldUpdateOperationsInput>;
  rescheduled_pipeline?: InputMaybe<IntFieldUpdateOperationsInput>;
  rescheduled_set?: InputMaybe<IntFieldUpdateOperationsInput>;
  revenue?: InputMaybe<FloatFieldUpdateOperationsInput>;
  revenue_value?: InputMaybe<FloatFieldUpdateOperationsInput>;
  sale?: InputMaybe<IntFieldUpdateOperationsInput>;
  sale_closing_opportunity?: InputMaybe<IntFieldUpdateOperationsInput>;
  sale_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  sales_cycle_num?: InputMaybe<IntFieldUpdateOperationsInput>;
  schedule_callback_held?: InputMaybe<IntFieldUpdateOperationsInput>;
  schedule_callback_set_not_held?: InputMaybe<IntFieldUpdateOperationsInput>;
  scheduled_item?: InputMaybe<ScheduleItemUpdateOneWithoutStat_Item_AggregationInput>;
  sdr_hold?: InputMaybe<IntFieldUpdateOperationsInput>;
  sdr_revenue?: InputMaybe<IntFieldUpdateOperationsInput>;
  sdr_sale?: InputMaybe<IntFieldUpdateOperationsInput>;
  sdr_transfer?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  sequence_advance?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  sequence_attributed_hold?: InputMaybe<IntFieldUpdateOperationsInput>;
  set?: InputMaybe<IntFieldUpdateOperationsInput>;
  set_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  set_site_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  set_team_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  set_user_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  site_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  skip?: InputMaybe<IntFieldUpdateOperationsInput>;
  sms_received?: InputMaybe<IntFieldUpdateOperationsInput>;
  sms_sent?: InputMaybe<IntFieldUpdateOperationsInput>;
  step?: InputMaybe<SequenceStepUpdateOneWithoutStatItemAggregationInput>;
  talk_time?: InputMaybe<IntFieldUpdateOperationsInput>;
  team_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  ten_minute_downtime_before_activity?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  ten_minute_downtime_before_call?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  time_in_last_phase_sec?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  transfer_answered?: InputMaybe<IntFieldUpdateOperationsInput>;
  transfer_answered_ae?: InputMaybe<IntFieldUpdateOperationsInput>;
  transfer_answered_ae_unique?: InputMaybe<IntFieldUpdateOperationsInput>;
  transfer_answered_sdr?: InputMaybe<IntFieldUpdateOperationsInput>;
  transfer_answered_sdr_unique?: InputMaybe<IntFieldUpdateOperationsInput>;
  transfer_answered_unique?: InputMaybe<IntFieldUpdateOperationsInput>;
  transfer_call_duration_over_x_min?: InputMaybe<IntFieldUpdateOperationsInput>;
  transfer_call_duration_over_x_min_ae?: InputMaybe<IntFieldUpdateOperationsInput>;
  transfer_call_duration_over_x_min_ae_unique?: InputMaybe<IntFieldUpdateOperationsInput>;
  transfer_call_duration_over_x_min_sdr?: InputMaybe<IntFieldUpdateOperationsInput>;
  transfer_call_duration_over_x_min_sdr_unique?: InputMaybe<IntFieldUpdateOperationsInput>;
  transfer_call_duration_over_x_min_unique?: InputMaybe<IntFieldUpdateOperationsInput>;
  transfer_ignored?: InputMaybe<IntFieldUpdateOperationsInput>;
  transfer_ignored_ae?: InputMaybe<IntFieldUpdateOperationsInput>;
  transfer_ignored_ae_unique?: InputMaybe<IntFieldUpdateOperationsInput>;
  transfer_ignored_sdr?: InputMaybe<IntFieldUpdateOperationsInput>;
  transfer_ignored_sdr_unique?: InputMaybe<IntFieldUpdateOperationsInput>;
  transfer_ignored_unique?: InputMaybe<IntFieldUpdateOperationsInput>;
  transfer_ring_time?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  transfer_ring_time_ae?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  transfer_site_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transfer_site_to_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transfer_team_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transfer_team_to_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transfer_user_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transfer_user_to_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transfered_leads?: InputMaybe<IntFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumStatItemAggregationTypeFieldUpdateOperationsInput>;
  unique_lead_pipeline?: InputMaybe<IntFieldUpdateOperationsInput>;
  unique_rescheduled_set?: InputMaybe<IntFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  user_role?: InputMaybe<NullableEnumRoleFieldUpdateOperationsInput>;
  value?: InputMaybe<FloatFieldUpdateOperationsInput>;
  voicemail_or_no_contact?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  week_group_key?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type StatItemAggregationUpdateWithoutStepInput = {
  bad_number?: InputMaybe<IntFieldUpdateOperationsInput>;
  call_followup?: InputMaybe<IntFieldUpdateOperationsInput>;
  channel?: InputMaybe<NullableEnumChannelFieldUpdateOperationsInput>;
  closing_opp_held?: InputMaybe<IntFieldUpdateOperationsInput>;
  closing_opp_scheduled?: InputMaybe<IntFieldUpdateOperationsInput>;
  closing_opps_pipeline?: InputMaybe<IntFieldUpdateOperationsInput>;
  cold_call?: InputMaybe<IntFieldUpdateOperationsInput>;
  connect?: InputMaybe<IntFieldUpdateOperationsInput>;
  connect_talk_time?: InputMaybe<IntFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  date?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  date_group_key?: InputMaybe<StringFieldUpdateOperationsInput>;
  day?: InputMaybe<IntFieldUpdateOperationsInput>;
  days_contact_to_set?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  days_decision_call_to_sale?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  days_first_dial_to_contact?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  days_hold_to_decision_call?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  days_hold_to_sale?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  days_set_out?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  days_set_to_hold?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  days_to_close?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  decision_call?: InputMaybe<IntFieldUpdateOperationsInput>;
  decision_call_held?: InputMaybe<IntFieldUpdateOperationsInput>;
  decision_call_set?: InputMaybe<IntFieldUpdateOperationsInput>;
  decision_call_set_not_held?: InputMaybe<IntFieldUpdateOperationsInput>;
  demo_held?: InputMaybe<IntFieldUpdateOperationsInput>;
  demo_set_not_held?: InputMaybe<IntFieldUpdateOperationsInput>;
  demo_talk_time?: InputMaybe<IntFieldUpdateOperationsInput>;
  dial?: InputMaybe<IntFieldUpdateOperationsInput>;
  dials_to_set?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  dm_cold_call?: InputMaybe<IntFieldUpdateOperationsInput>;
  dm_connect?: InputMaybe<IntFieldUpdateOperationsInput>;
  dm_connect_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  dm_set?: InputMaybe<IntFieldUpdateOperationsInput>;
  downtime_before_activity?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  downtime_before_call?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  email_received?: InputMaybe<IntFieldUpdateOperationsInput>;
  email_sent?: InputMaybe<IntFieldUpdateOperationsInput>;
  first_dial_hour_minute?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  followup_hold?: InputMaybe<IntFieldUpdateOperationsInput>;
  general_callback_held?: InputMaybe<IntFieldUpdateOperationsInput>;
  general_callback_set_not_held?: InputMaybe<IntFieldUpdateOperationsInput>;
  hold?: InputMaybe<IntFieldUpdateOperationsInput>;
  hold_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  hour?: InputMaybe<IntFieldUpdateOperationsInput>;
  hour_minute?: InputMaybe<IntFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inbound_time_to_first_dial?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  initial_hold?: InputMaybe<IntFieldUpdateOperationsInput>;
  initial_pipeline?: InputMaybe<IntFieldUpdateOperationsInput>;
  initial_set?: InputMaybe<IntFieldUpdateOperationsInput>;
  is_stat_from_transfer?: InputMaybe<IntFieldUpdateOperationsInput>;
  lead?: InputMaybe<LeadUpdateOneWithoutStat_ItemsInput>;
  lead_activity?: InputMaybe<LeadActivityUpdateOneWithoutStat_Item_AggregationInput>;
  lead_activity_pipeline?: InputMaybe<LeadActivityUpdateOneWithoutStat_Item_Aggregation_PipelineInput>;
  live_transfer?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  live_transfer_hold?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  live_transfer_sale?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  month_group_key?: InputMaybe<StringFieldUpdateOperationsInput>;
  ndm_cold_call?: InputMaybe<IntFieldUpdateOperationsInput>;
  ndm_connect?: InputMaybe<IntFieldUpdateOperationsInput>;
  ndm_set?: InputMaybe<IntFieldUpdateOperationsInput>;
  next_phase?: InputMaybe<NullableEnumPhaseFieldUpdateOperationsInput>;
  organization_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  otf?: InputMaybe<IntFieldUpdateOperationsInput>;
  pipeline?: InputMaybe<IntFieldUpdateOperationsInput>;
  pipeline_needs_decision?: InputMaybe<IntFieldUpdateOperationsInput>;
  previous_phase?: InputMaybe<NullableEnumPhaseFieldUpdateOperationsInput>;
  recurring_revenue?: InputMaybe<FloatFieldUpdateOperationsInput>;
  release?: InputMaybe<IntFieldUpdateOperationsInput>;
  rescheduled_hold?: InputMaybe<IntFieldUpdateOperationsInput>;
  rescheduled_pipeline?: InputMaybe<IntFieldUpdateOperationsInput>;
  rescheduled_set?: InputMaybe<IntFieldUpdateOperationsInput>;
  revenue?: InputMaybe<FloatFieldUpdateOperationsInput>;
  revenue_value?: InputMaybe<FloatFieldUpdateOperationsInput>;
  sale?: InputMaybe<IntFieldUpdateOperationsInput>;
  sale_closing_opportunity?: InputMaybe<IntFieldUpdateOperationsInput>;
  sale_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  sales_cycle_num?: InputMaybe<IntFieldUpdateOperationsInput>;
  schedule_callback_held?: InputMaybe<IntFieldUpdateOperationsInput>;
  schedule_callback_set_not_held?: InputMaybe<IntFieldUpdateOperationsInput>;
  scheduled_item?: InputMaybe<ScheduleItemUpdateOneWithoutStat_Item_AggregationInput>;
  sdr_hold?: InputMaybe<IntFieldUpdateOperationsInput>;
  sdr_revenue?: InputMaybe<IntFieldUpdateOperationsInput>;
  sdr_sale?: InputMaybe<IntFieldUpdateOperationsInput>;
  sdr_transfer?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  sequence?: InputMaybe<SequenceUpdateOneWithoutStatItemsInput>;
  sequence_advance?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  sequence_attributed_hold?: InputMaybe<IntFieldUpdateOperationsInput>;
  set?: InputMaybe<IntFieldUpdateOperationsInput>;
  set_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  set_site_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  set_team_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  set_user_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  site_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  skip?: InputMaybe<IntFieldUpdateOperationsInput>;
  sms_received?: InputMaybe<IntFieldUpdateOperationsInput>;
  sms_sent?: InputMaybe<IntFieldUpdateOperationsInput>;
  talk_time?: InputMaybe<IntFieldUpdateOperationsInput>;
  team_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  ten_minute_downtime_before_activity?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  ten_minute_downtime_before_call?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  time_in_last_phase_sec?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  transfer_answered?: InputMaybe<IntFieldUpdateOperationsInput>;
  transfer_answered_ae?: InputMaybe<IntFieldUpdateOperationsInput>;
  transfer_answered_ae_unique?: InputMaybe<IntFieldUpdateOperationsInput>;
  transfer_answered_sdr?: InputMaybe<IntFieldUpdateOperationsInput>;
  transfer_answered_sdr_unique?: InputMaybe<IntFieldUpdateOperationsInput>;
  transfer_answered_unique?: InputMaybe<IntFieldUpdateOperationsInput>;
  transfer_call_duration_over_x_min?: InputMaybe<IntFieldUpdateOperationsInput>;
  transfer_call_duration_over_x_min_ae?: InputMaybe<IntFieldUpdateOperationsInput>;
  transfer_call_duration_over_x_min_ae_unique?: InputMaybe<IntFieldUpdateOperationsInput>;
  transfer_call_duration_over_x_min_sdr?: InputMaybe<IntFieldUpdateOperationsInput>;
  transfer_call_duration_over_x_min_sdr_unique?: InputMaybe<IntFieldUpdateOperationsInput>;
  transfer_call_duration_over_x_min_unique?: InputMaybe<IntFieldUpdateOperationsInput>;
  transfer_ignored?: InputMaybe<IntFieldUpdateOperationsInput>;
  transfer_ignored_ae?: InputMaybe<IntFieldUpdateOperationsInput>;
  transfer_ignored_ae_unique?: InputMaybe<IntFieldUpdateOperationsInput>;
  transfer_ignored_sdr?: InputMaybe<IntFieldUpdateOperationsInput>;
  transfer_ignored_sdr_unique?: InputMaybe<IntFieldUpdateOperationsInput>;
  transfer_ignored_unique?: InputMaybe<IntFieldUpdateOperationsInput>;
  transfer_ring_time?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  transfer_ring_time_ae?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  transfer_site_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transfer_site_to_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transfer_team_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transfer_team_to_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transfer_user_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transfer_user_to_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transfered_leads?: InputMaybe<IntFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumStatItemAggregationTypeFieldUpdateOperationsInput>;
  unique_lead_pipeline?: InputMaybe<IntFieldUpdateOperationsInput>;
  unique_rescheduled_set?: InputMaybe<IntFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  user_role?: InputMaybe<NullableEnumRoleFieldUpdateOperationsInput>;
  value?: InputMaybe<FloatFieldUpdateOperationsInput>;
  voicemail_or_no_contact?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  week_group_key?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type StatItemAggregationUpdateWithWhereUniqueWithoutLeadInput = {
  data: StatItemAggregationUpdateWithoutLeadInput;
  where: StatItemAggregationWhereUniqueInput;
};

export type StatItemAggregationUpdateWithWhereUniqueWithoutSequenceInput = {
  data: StatItemAggregationUpdateWithoutSequenceInput;
  where: StatItemAggregationWhereUniqueInput;
};

export type StatItemAggregationUpdateWithWhereUniqueWithoutStepInput = {
  data: StatItemAggregationUpdateWithoutStepInput;
  where: StatItemAggregationWhereUniqueInput;
};

export type StatItemAggregationUpsertWithoutLead_Activity_PipelineInput = {
  create: StatItemAggregationCreateWithoutLead_Activity_PipelineInput;
  update: StatItemAggregationUpdateWithoutLead_Activity_PipelineInput;
};

export type StatItemAggregationUpsertWithoutLead_ActivityInput = {
  create: StatItemAggregationCreateWithoutLead_ActivityInput;
  update: StatItemAggregationUpdateWithoutLead_ActivityInput;
};

export type StatItemAggregationUpsertWithoutScheduled_ItemInput = {
  create: StatItemAggregationCreateWithoutScheduled_ItemInput;
  update: StatItemAggregationUpdateWithoutScheduled_ItemInput;
};

export type StatItemAggregationUpsertWithWhereUniqueWithoutLeadInput = {
  create: StatItemAggregationCreateWithoutLeadInput;
  update: StatItemAggregationUpdateWithoutLeadInput;
  where: StatItemAggregationWhereUniqueInput;
};

export type StatItemAggregationUpsertWithWhereUniqueWithoutSequenceInput = {
  create: StatItemAggregationCreateWithoutSequenceInput;
  update: StatItemAggregationUpdateWithoutSequenceInput;
  where: StatItemAggregationWhereUniqueInput;
};

export type StatItemAggregationUpsertWithWhereUniqueWithoutStepInput = {
  create: StatItemAggregationCreateWithoutStepInput;
  update: StatItemAggregationUpdateWithoutStepInput;
  where: StatItemAggregationWhereUniqueInput;
};

export type StatItemAggregationWhereInput = {
  AND?: InputMaybe<Array<StatItemAggregationWhereInput>>;
  bad_number?: InputMaybe<IntFilter>;
  call_followup?: InputMaybe<IntFilter>;
  channel?: InputMaybe<EnumChannelNullableFilter>;
  closing_opp_held?: InputMaybe<IntFilter>;
  closing_opp_scheduled?: InputMaybe<IntFilter>;
  closing_opps_pipeline?: InputMaybe<IntFilter>;
  cold_call?: InputMaybe<IntFilter>;
  connect?: InputMaybe<IntFilter>;
  connect_talk_time?: InputMaybe<IntFilter>;
  created_at?: InputMaybe<DateTimeFilter>;
  date?: InputMaybe<DateTimeFilter>;
  date_group_key?: InputMaybe<StringFilter>;
  day?: InputMaybe<IntFilter>;
  days_contact_to_set?: InputMaybe<IntNullableFilter>;
  days_decision_call_to_sale?: InputMaybe<IntNullableFilter>;
  days_first_dial_to_contact?: InputMaybe<IntNullableFilter>;
  days_hold_to_decision_call?: InputMaybe<IntNullableFilter>;
  days_hold_to_sale?: InputMaybe<IntNullableFilter>;
  days_set_out?: InputMaybe<FloatNullableFilter>;
  days_set_to_hold?: InputMaybe<IntNullableFilter>;
  days_to_close?: InputMaybe<IntNullableFilter>;
  decision_call?: InputMaybe<IntFilter>;
  decision_call_held?: InputMaybe<IntFilter>;
  decision_call_set?: InputMaybe<IntFilter>;
  decision_call_set_not_held?: InputMaybe<IntFilter>;
  demo_held?: InputMaybe<IntFilter>;
  demo_set_not_held?: InputMaybe<IntFilter>;
  demo_talk_time?: InputMaybe<IntFilter>;
  dial?: InputMaybe<IntFilter>;
  dials_to_set?: InputMaybe<IntNullableFilter>;
  dm_cold_call?: InputMaybe<IntFilter>;
  dm_connect?: InputMaybe<IntFilter>;
  dm_connect_date?: InputMaybe<DateTimeNullableFilter>;
  dm_set?: InputMaybe<IntFilter>;
  downtime_before_activity?: InputMaybe<IntNullableFilter>;
  downtime_before_call?: InputMaybe<IntNullableFilter>;
  email_received?: InputMaybe<IntFilter>;
  email_sent?: InputMaybe<IntFilter>;
  first_dial_hour_minute?: InputMaybe<IntNullableFilter>;
  followup_hold?: InputMaybe<IntFilter>;
  general_callback_held?: InputMaybe<IntFilter>;
  general_callback_set_not_held?: InputMaybe<IntFilter>;
  hold?: InputMaybe<IntFilter>;
  hold_date?: InputMaybe<DateTimeNullableFilter>;
  hour?: InputMaybe<IntFilter>;
  hour_minute?: InputMaybe<IntFilter>;
  id?: InputMaybe<StringFilter>;
  inbound_time_to_first_dial?: InputMaybe<IntNullableFilter>;
  initial_hold?: InputMaybe<IntFilter>;
  initial_pipeline?: InputMaybe<IntFilter>;
  initial_set?: InputMaybe<IntFilter>;
  is_stat_from_transfer?: InputMaybe<IntFilter>;
  lead?: InputMaybe<LeadWhereInput>;
  lead_activity?: InputMaybe<LeadActivityWhereInput>;
  lead_activity_id?: InputMaybe<StringNullableFilter>;
  lead_activity_pipeline?: InputMaybe<LeadActivityWhereInput>;
  lead_activity_pipeline_id?: InputMaybe<StringNullableFilter>;
  lead_id?: InputMaybe<StringNullableFilter>;
  live_transfer?: InputMaybe<IntNullableFilter>;
  live_transfer_hold?: InputMaybe<IntNullableFilter>;
  live_transfer_sale?: InputMaybe<IntNullableFilter>;
  month_group_key?: InputMaybe<StringFilter>;
  ndm_cold_call?: InputMaybe<IntFilter>;
  ndm_connect?: InputMaybe<IntFilter>;
  ndm_set?: InputMaybe<IntFilter>;
  next_phase?: InputMaybe<EnumPhaseNullableFilter>;
  NOT?: InputMaybe<Array<StatItemAggregationWhereInput>>;
  OR?: InputMaybe<Array<StatItemAggregationWhereInput>>;
  organization_id?: InputMaybe<StringNullableFilter>;
  otf?: InputMaybe<IntFilter>;
  pipeline?: InputMaybe<IntFilter>;
  pipeline_needs_decision?: InputMaybe<IntFilter>;
  previous_phase?: InputMaybe<EnumPhaseNullableFilter>;
  recurring_revenue?: InputMaybe<FloatFilter>;
  release?: InputMaybe<IntFilter>;
  rescheduled_hold?: InputMaybe<IntFilter>;
  rescheduled_pipeline?: InputMaybe<IntFilter>;
  rescheduled_set?: InputMaybe<IntFilter>;
  revenue?: InputMaybe<FloatFilter>;
  revenue_value?: InputMaybe<FloatFilter>;
  sale?: InputMaybe<IntFilter>;
  sale_closing_opportunity?: InputMaybe<IntFilter>;
  sale_date?: InputMaybe<DateTimeNullableFilter>;
  sales_cycle_num?: InputMaybe<IntFilter>;
  schedule_callback_held?: InputMaybe<IntFilter>;
  schedule_callback_set_not_held?: InputMaybe<IntFilter>;
  schedule_item_id?: InputMaybe<StringNullableFilter>;
  scheduled_item?: InputMaybe<ScheduleItemWhereInput>;
  sdr_hold?: InputMaybe<IntFilter>;
  sdr_revenue?: InputMaybe<IntFilter>;
  sdr_sale?: InputMaybe<IntFilter>;
  sdr_transfer?: InputMaybe<IntNullableFilter>;
  sequence?: InputMaybe<SequenceWhereInput>;
  sequence_advance?: InputMaybe<IntNullableFilter>;
  sequence_attributed_hold?: InputMaybe<IntFilter>;
  sequence_id?: InputMaybe<StringNullableFilter>;
  sequence_step_id?: InputMaybe<StringNullableFilter>;
  set?: InputMaybe<IntFilter>;
  set_date?: InputMaybe<DateTimeNullableFilter>;
  set_site_id?: InputMaybe<StringNullableFilter>;
  set_team_id?: InputMaybe<StringNullableFilter>;
  set_user_id?: InputMaybe<StringNullableFilter>;
  site_id?: InputMaybe<StringNullableFilter>;
  skip?: InputMaybe<IntFilter>;
  sms_received?: InputMaybe<IntFilter>;
  sms_sent?: InputMaybe<IntFilter>;
  step?: InputMaybe<SequenceStepWhereInput>;
  talk_time?: InputMaybe<IntFilter>;
  team_id?: InputMaybe<StringNullableFilter>;
  ten_minute_downtime_before_activity?: InputMaybe<IntNullableFilter>;
  ten_minute_downtime_before_call?: InputMaybe<IntNullableFilter>;
  time_in_last_phase_sec?: InputMaybe<IntNullableFilter>;
  transfer_answered?: InputMaybe<IntFilter>;
  transfer_answered_ae?: InputMaybe<IntFilter>;
  transfer_answered_ae_unique?: InputMaybe<IntFilter>;
  transfer_answered_sdr?: InputMaybe<IntFilter>;
  transfer_answered_sdr_unique?: InputMaybe<IntFilter>;
  transfer_answered_unique?: InputMaybe<IntFilter>;
  transfer_call_duration_over_x_min?: InputMaybe<IntFilter>;
  transfer_call_duration_over_x_min_ae?: InputMaybe<IntFilter>;
  transfer_call_duration_over_x_min_ae_unique?: InputMaybe<IntFilter>;
  transfer_call_duration_over_x_min_sdr?: InputMaybe<IntFilter>;
  transfer_call_duration_over_x_min_sdr_unique?: InputMaybe<IntFilter>;
  transfer_call_duration_over_x_min_unique?: InputMaybe<IntFilter>;
  transfer_ignored?: InputMaybe<IntFilter>;
  transfer_ignored_ae?: InputMaybe<IntFilter>;
  transfer_ignored_ae_unique?: InputMaybe<IntFilter>;
  transfer_ignored_sdr?: InputMaybe<IntFilter>;
  transfer_ignored_sdr_unique?: InputMaybe<IntFilter>;
  transfer_ignored_unique?: InputMaybe<IntFilter>;
  transfer_ring_time?: InputMaybe<IntNullableFilter>;
  transfer_ring_time_ae?: InputMaybe<IntNullableFilter>;
  transfer_site_id?: InputMaybe<StringNullableFilter>;
  transfer_site_to_id?: InputMaybe<StringNullableFilter>;
  transfer_team_id?: InputMaybe<StringNullableFilter>;
  transfer_team_to_id?: InputMaybe<StringNullableFilter>;
  transfer_user_id?: InputMaybe<StringNullableFilter>;
  transfer_user_to_id?: InputMaybe<StringNullableFilter>;
  transfered_leads?: InputMaybe<IntFilter>;
  type?: InputMaybe<EnumStatItemAggregationTypeFilter>;
  unique_lead_pipeline?: InputMaybe<IntFilter>;
  unique_rescheduled_set?: InputMaybe<IntFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
  user_id?: InputMaybe<StringNullableFilter>;
  user_role?: InputMaybe<EnumRoleNullableFilter>;
  value?: InputMaybe<FloatFilter>;
  voicemail_or_no_contact?: InputMaybe<IntNullableFilter>;
  week_group_key?: InputMaybe<StringFilter>;
};

export type StatItemAggregationWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  lead_activity_id?: InputMaybe<Scalars['String']['input']>;
  lead_activity_pipeline_id?: InputMaybe<Scalars['String']['input']>;
  schedule_item_id?: InputMaybe<Scalars['String']['input']>;
};

/** Use on the 30 day graph. Returns the list of stat items along with the best individual performance and org average */
export type StatItemGraph = {
  __typename?: 'StatItemGraph';
  /** Metric for the best rep in the org */
  best_individual?: Maybe<Scalars['Float']['output']>;
  /** Average metric for the company */
  company_average?: Maybe<Scalars['Float']['output']>;
  /** List of data points */
  stat_items?: Maybe<Array<Maybe<StatItem>>>;
};

export type StatItemTable = {
  __typename?: 'StatItemTable';
  channel_filter?: Maybe<Channel>;
  computation_type: Computationtype;
  computed_id?: Maybe<Scalars['String']['output']>;
  date: Scalars['DateTime']['output'];
  group_type?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  metric: Metric;
  organization_id?: Maybe<Scalars['String']['output']>;
  row_label: Scalars['String']['output'];
  team_id?: Maybe<Scalars['String']['output']>;
  user_id?: Maybe<Scalars['String']['output']>;
  value: Scalars['Float']['output'];
};

export type StatLeadAggregation = {
  __typename?: 'StatLeadAggregation';
  assigned_lead: Scalars['Int']['output'];
  cc_days_remaining: Scalars['Float']['output'];
  close_user?: Maybe<User>;
  close_user_id?: Maybe<Scalars['String']['output']>;
  created_at: Scalars['DateTime']['output'];
  customer_lead: Scalars['Int']['output'];
  hold: Scalars['Int']['output'];
  id: Scalars['String']['output'];
  in_progress_lead: Scalars['Int']['output'];
  industry?: Maybe<Scalars['String']['output']>;
  lead?: Maybe<Lead>;
  lead_creation_source?: Maybe<Scalars['String']['output']>;
  lead_id?: Maybe<Scalars['String']['output']>;
  lead_in_queue: Scalars['Int']['output'];
  lead_source?: Maybe<Scalars['String']['output']>;
  lead_value?: Maybe<Scalars['Float']['output']>;
  never_dialed_lead: Scalars['Int']['output'];
  organization_id?: Maybe<Scalars['String']['output']>;
  pipeline_state: PipelineColumn;
  resting_lead: Scalars['Int']['output'];
  retired_lead: Scalars['Int']['output'];
  sale: Scalars['Int']['output'];
  sales_amount?: Maybe<Scalars['Float']['output']>;
  set: Scalars['Int']['output'];
  set_user?: Maybe<User>;
  set_user_id?: Maybe<Scalars['String']['output']>;
  skipped_lead: Scalars['Int']['output'];
  team_id?: Maybe<Scalars['String']['output']>;
  type: StatItemAggregationType;
  unassigned_lead: Scalars['Int']['output'];
  updated_at: Scalars['DateTime']['output'];
  user_id?: Maybe<Scalars['String']['output']>;
};

export type StatLeadAggregationCreateManyClose_UserInput = {
  all_in_resting_post_demo_set?: InputMaybe<Scalars['Int']['input']>;
  assigned_lead?: InputMaybe<Scalars['Int']['input']>;
  associate_parent_id?: InputMaybe<Scalars['String']['input']>;
  cc_days_remaining?: InputMaybe<Scalars['Float']['input']>;
  cc_dm_lead?: InputMaybe<Scalars['Int']['input']>;
  cc_pre_dm_lead?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  customer_lead?: InputMaybe<Scalars['Int']['input']>;
  decision_call?: InputMaybe<Scalars['Int']['input']>;
  demo_flake?: InputMaybe<Scalars['Int']['input']>;
  demo_held_no_action?: InputMaybe<Scalars['Int']['input']>;
  dm_reached_not_scheduled?: InputMaybe<Scalars['Int']['input']>;
  hold?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  in_progress_lead?: InputMaybe<Scalars['Int']['input']>;
  industry?: InputMaybe<Scalars['String']['input']>;
  lead_creation_source?: InputMaybe<Scalars['String']['input']>;
  lead_id?: InputMaybe<Scalars['String']['input']>;
  lead_in_queue?: InputMaybe<Scalars['Int']['input']>;
  lead_source?: InputMaybe<Scalars['String']['input']>;
  lead_value?: InputMaybe<Scalars['Float']['input']>;
  NDM_interested?: InputMaybe<Scalars['Int']['input']>;
  never_dialed_lead?: InputMaybe<Scalars['Int']['input']>;
  num_dials?: InputMaybe<Scalars['Int']['input']>;
  organization_id?: InputMaybe<Scalars['String']['input']>;
  owned_lead?: InputMaybe<Scalars['Int']['input']>;
  pipeline_state?: InputMaybe<PipelineColumn>;
  pipeline_state_int?: InputMaybe<Scalars['Int']['input']>;
  released_lead?: InputMaybe<Scalars['Int']['input']>;
  resting_lead?: InputMaybe<Scalars['Int']['input']>;
  retired_lead?: InputMaybe<Scalars['Int']['input']>;
  sale?: InputMaybe<Scalars['Int']['input']>;
  sales_amount?: InputMaybe<Scalars['Float']['input']>;
  scheduled_callback_after_dm_contacted?: InputMaybe<Scalars['Int']['input']>;
  set?: InputMaybe<Scalars['Int']['input']>;
  set_user_id?: InputMaybe<Scalars['String']['input']>;
  site_id?: InputMaybe<Scalars['String']['input']>;
  skipped_lead?: InputMaybe<Scalars['Int']['input']>;
  team_id?: InputMaybe<Scalars['String']['input']>;
  time_between_inital_hold_and_scheduled_decision?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<StatItemAggregationType>;
  unassigned_lead?: InputMaybe<Scalars['Int']['input']>;
  unclosed_demo?: InputMaybe<Scalars['Int']['input']>;
  upcoming_demo?: InputMaybe<Scalars['Int']['input']>;
  upcoming_follow_up_demo?: InputMaybe<Scalars['Int']['input']>;
  upcoming_scheduled_call?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
  user_role?: InputMaybe<Role>;
};

export type StatLeadAggregationCreateManyClose_UserInputEnvelope = {
  data?: InputMaybe<Array<StatLeadAggregationCreateManyClose_UserInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type StatLeadAggregationCreateManySet_UserInput = {
  all_in_resting_post_demo_set?: InputMaybe<Scalars['Int']['input']>;
  assigned_lead?: InputMaybe<Scalars['Int']['input']>;
  associate_parent_id?: InputMaybe<Scalars['String']['input']>;
  cc_days_remaining?: InputMaybe<Scalars['Float']['input']>;
  cc_dm_lead?: InputMaybe<Scalars['Int']['input']>;
  cc_pre_dm_lead?: InputMaybe<Scalars['Int']['input']>;
  close_user_id?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  customer_lead?: InputMaybe<Scalars['Int']['input']>;
  decision_call?: InputMaybe<Scalars['Int']['input']>;
  demo_flake?: InputMaybe<Scalars['Int']['input']>;
  demo_held_no_action?: InputMaybe<Scalars['Int']['input']>;
  dm_reached_not_scheduled?: InputMaybe<Scalars['Int']['input']>;
  hold?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  in_progress_lead?: InputMaybe<Scalars['Int']['input']>;
  industry?: InputMaybe<Scalars['String']['input']>;
  lead_creation_source?: InputMaybe<Scalars['String']['input']>;
  lead_id?: InputMaybe<Scalars['String']['input']>;
  lead_in_queue?: InputMaybe<Scalars['Int']['input']>;
  lead_source?: InputMaybe<Scalars['String']['input']>;
  lead_value?: InputMaybe<Scalars['Float']['input']>;
  NDM_interested?: InputMaybe<Scalars['Int']['input']>;
  never_dialed_lead?: InputMaybe<Scalars['Int']['input']>;
  num_dials?: InputMaybe<Scalars['Int']['input']>;
  organization_id?: InputMaybe<Scalars['String']['input']>;
  owned_lead?: InputMaybe<Scalars['Int']['input']>;
  pipeline_state?: InputMaybe<PipelineColumn>;
  pipeline_state_int?: InputMaybe<Scalars['Int']['input']>;
  released_lead?: InputMaybe<Scalars['Int']['input']>;
  resting_lead?: InputMaybe<Scalars['Int']['input']>;
  retired_lead?: InputMaybe<Scalars['Int']['input']>;
  sale?: InputMaybe<Scalars['Int']['input']>;
  sales_amount?: InputMaybe<Scalars['Float']['input']>;
  scheduled_callback_after_dm_contacted?: InputMaybe<Scalars['Int']['input']>;
  set?: InputMaybe<Scalars['Int']['input']>;
  site_id?: InputMaybe<Scalars['String']['input']>;
  skipped_lead?: InputMaybe<Scalars['Int']['input']>;
  team_id?: InputMaybe<Scalars['String']['input']>;
  time_between_inital_hold_and_scheduled_decision?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<StatItemAggregationType>;
  unassigned_lead?: InputMaybe<Scalars['Int']['input']>;
  unclosed_demo?: InputMaybe<Scalars['Int']['input']>;
  upcoming_demo?: InputMaybe<Scalars['Int']['input']>;
  upcoming_follow_up_demo?: InputMaybe<Scalars['Int']['input']>;
  upcoming_scheduled_call?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
  user_role?: InputMaybe<Role>;
};

export type StatLeadAggregationCreateManySet_UserInputEnvelope = {
  data?: InputMaybe<Array<StatLeadAggregationCreateManySet_UserInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type StatLeadAggregationCreateManyTeamInput = {
  all_in_resting_post_demo_set?: InputMaybe<Scalars['Int']['input']>;
  assigned_lead?: InputMaybe<Scalars['Int']['input']>;
  associate_parent_id?: InputMaybe<Scalars['String']['input']>;
  cc_days_remaining?: InputMaybe<Scalars['Float']['input']>;
  cc_dm_lead?: InputMaybe<Scalars['Int']['input']>;
  cc_pre_dm_lead?: InputMaybe<Scalars['Int']['input']>;
  close_user_id?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  customer_lead?: InputMaybe<Scalars['Int']['input']>;
  decision_call?: InputMaybe<Scalars['Int']['input']>;
  demo_flake?: InputMaybe<Scalars['Int']['input']>;
  demo_held_no_action?: InputMaybe<Scalars['Int']['input']>;
  dm_reached_not_scheduled?: InputMaybe<Scalars['Int']['input']>;
  hold?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  in_progress_lead?: InputMaybe<Scalars['Int']['input']>;
  industry?: InputMaybe<Scalars['String']['input']>;
  lead_creation_source?: InputMaybe<Scalars['String']['input']>;
  lead_id?: InputMaybe<Scalars['String']['input']>;
  lead_in_queue?: InputMaybe<Scalars['Int']['input']>;
  lead_source?: InputMaybe<Scalars['String']['input']>;
  lead_value?: InputMaybe<Scalars['Float']['input']>;
  NDM_interested?: InputMaybe<Scalars['Int']['input']>;
  never_dialed_lead?: InputMaybe<Scalars['Int']['input']>;
  num_dials?: InputMaybe<Scalars['Int']['input']>;
  organization_id?: InputMaybe<Scalars['String']['input']>;
  owned_lead?: InputMaybe<Scalars['Int']['input']>;
  pipeline_state?: InputMaybe<PipelineColumn>;
  pipeline_state_int?: InputMaybe<Scalars['Int']['input']>;
  released_lead?: InputMaybe<Scalars['Int']['input']>;
  resting_lead?: InputMaybe<Scalars['Int']['input']>;
  retired_lead?: InputMaybe<Scalars['Int']['input']>;
  sale?: InputMaybe<Scalars['Int']['input']>;
  sales_amount?: InputMaybe<Scalars['Float']['input']>;
  scheduled_callback_after_dm_contacted?: InputMaybe<Scalars['Int']['input']>;
  set?: InputMaybe<Scalars['Int']['input']>;
  set_user_id?: InputMaybe<Scalars['String']['input']>;
  site_id?: InputMaybe<Scalars['String']['input']>;
  skipped_lead?: InputMaybe<Scalars['Int']['input']>;
  time_between_inital_hold_and_scheduled_decision?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<StatItemAggregationType>;
  unassigned_lead?: InputMaybe<Scalars['Int']['input']>;
  unclosed_demo?: InputMaybe<Scalars['Int']['input']>;
  upcoming_demo?: InputMaybe<Scalars['Int']['input']>;
  upcoming_follow_up_demo?: InputMaybe<Scalars['Int']['input']>;
  upcoming_scheduled_call?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
  user_role?: InputMaybe<Role>;
};

export type StatLeadAggregationCreateManyTeamInputEnvelope = {
  data?: InputMaybe<Array<StatLeadAggregationCreateManyTeamInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type StatLeadAggregationCreateNestedManyWithoutClose_UserInput = {
  connect?: InputMaybe<Array<StatLeadAggregationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StatLeadAggregationCreateOrConnectWithoutClose_UserInput>>;
  create?: InputMaybe<Array<StatLeadAggregationCreateWithoutClose_UserInput>>;
  createMany?: InputMaybe<StatLeadAggregationCreateManyClose_UserInputEnvelope>;
};

export type StatLeadAggregationCreateNestedManyWithoutSet_UserInput = {
  connect?: InputMaybe<Array<StatLeadAggregationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StatLeadAggregationCreateOrConnectWithoutSet_UserInput>>;
  create?: InputMaybe<Array<StatLeadAggregationCreateWithoutSet_UserInput>>;
  createMany?: InputMaybe<StatLeadAggregationCreateManySet_UserInputEnvelope>;
};

export type StatLeadAggregationCreateNestedManyWithoutTeamInput = {
  connect?: InputMaybe<Array<StatLeadAggregationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StatLeadAggregationCreateOrConnectWithoutTeamInput>>;
  create?: InputMaybe<Array<StatLeadAggregationCreateWithoutTeamInput>>;
  createMany?: InputMaybe<StatLeadAggregationCreateManyTeamInputEnvelope>;
};

export type StatLeadAggregationCreateNestedOneWithoutLeadInput = {
  connect?: InputMaybe<StatLeadAggregationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<StatLeadAggregationCreateOrConnectWithoutLeadInput>;
  create?: InputMaybe<StatLeadAggregationCreateWithoutLeadInput>;
};

export type StatLeadAggregationCreateOrConnectWithoutClose_UserInput = {
  create: StatLeadAggregationCreateWithoutClose_UserInput;
  where: StatLeadAggregationWhereUniqueInput;
};

export type StatLeadAggregationCreateOrConnectWithoutLeadInput = {
  create: StatLeadAggregationCreateWithoutLeadInput;
  where: StatLeadAggregationWhereUniqueInput;
};

export type StatLeadAggregationCreateOrConnectWithoutSet_UserInput = {
  create: StatLeadAggregationCreateWithoutSet_UserInput;
  where: StatLeadAggregationWhereUniqueInput;
};

export type StatLeadAggregationCreateOrConnectWithoutTeamInput = {
  create: StatLeadAggregationCreateWithoutTeamInput;
  where: StatLeadAggregationWhereUniqueInput;
};

export type StatLeadAggregationCreateWithoutClose_UserInput = {
  all_in_resting_post_demo_set?: InputMaybe<Scalars['Int']['input']>;
  assigned_lead?: InputMaybe<Scalars['Int']['input']>;
  associate_parent_id?: InputMaybe<Scalars['String']['input']>;
  cc_days_remaining?: InputMaybe<Scalars['Float']['input']>;
  cc_dm_lead?: InputMaybe<Scalars['Int']['input']>;
  cc_pre_dm_lead?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  customer_lead?: InputMaybe<Scalars['Int']['input']>;
  decision_call?: InputMaybe<Scalars['Int']['input']>;
  demo_flake?: InputMaybe<Scalars['Int']['input']>;
  demo_held_no_action?: InputMaybe<Scalars['Int']['input']>;
  dm_reached_not_scheduled?: InputMaybe<Scalars['Int']['input']>;
  hold?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  in_progress_lead?: InputMaybe<Scalars['Int']['input']>;
  industry?: InputMaybe<Scalars['String']['input']>;
  lead?: InputMaybe<LeadCreateNestedOneWithoutStatLeadAggregationInput>;
  lead_creation_source?: InputMaybe<Scalars['String']['input']>;
  lead_in_queue?: InputMaybe<Scalars['Int']['input']>;
  lead_source?: InputMaybe<Scalars['String']['input']>;
  lead_value?: InputMaybe<Scalars['Float']['input']>;
  NDM_interested?: InputMaybe<Scalars['Int']['input']>;
  never_dialed_lead?: InputMaybe<Scalars['Int']['input']>;
  num_dials?: InputMaybe<Scalars['Int']['input']>;
  organization_id?: InputMaybe<Scalars['String']['input']>;
  owned_lead?: InputMaybe<Scalars['Int']['input']>;
  pipeline_state?: InputMaybe<PipelineColumn>;
  pipeline_state_int?: InputMaybe<Scalars['Int']['input']>;
  released_lead?: InputMaybe<Scalars['Int']['input']>;
  resting_lead?: InputMaybe<Scalars['Int']['input']>;
  retired_lead?: InputMaybe<Scalars['Int']['input']>;
  sale?: InputMaybe<Scalars['Int']['input']>;
  sales_amount?: InputMaybe<Scalars['Float']['input']>;
  scheduled_callback_after_dm_contacted?: InputMaybe<Scalars['Int']['input']>;
  set?: InputMaybe<Scalars['Int']['input']>;
  set_user?: InputMaybe<UserCreateNestedOneWithoutSet_User_StatInput>;
  site_id?: InputMaybe<Scalars['String']['input']>;
  skipped_lead?: InputMaybe<Scalars['Int']['input']>;
  team?: InputMaybe<TeamCreateNestedOneWithoutStatLeadAggregationInput>;
  time_between_inital_hold_and_scheduled_decision?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<StatItemAggregationType>;
  unassigned_lead?: InputMaybe<Scalars['Int']['input']>;
  unclosed_demo?: InputMaybe<Scalars['Int']['input']>;
  upcoming_demo?: InputMaybe<Scalars['Int']['input']>;
  upcoming_follow_up_demo?: InputMaybe<Scalars['Int']['input']>;
  upcoming_scheduled_call?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
  user_role?: InputMaybe<Role>;
};

export type StatLeadAggregationCreateWithoutLeadInput = {
  all_in_resting_post_demo_set?: InputMaybe<Scalars['Int']['input']>;
  assigned_lead?: InputMaybe<Scalars['Int']['input']>;
  associate_parent_id?: InputMaybe<Scalars['String']['input']>;
  cc_days_remaining?: InputMaybe<Scalars['Float']['input']>;
  cc_dm_lead?: InputMaybe<Scalars['Int']['input']>;
  cc_pre_dm_lead?: InputMaybe<Scalars['Int']['input']>;
  close_user?: InputMaybe<UserCreateNestedOneWithoutClose_User_StatInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  customer_lead?: InputMaybe<Scalars['Int']['input']>;
  decision_call?: InputMaybe<Scalars['Int']['input']>;
  demo_flake?: InputMaybe<Scalars['Int']['input']>;
  demo_held_no_action?: InputMaybe<Scalars['Int']['input']>;
  dm_reached_not_scheduled?: InputMaybe<Scalars['Int']['input']>;
  hold?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  in_progress_lead?: InputMaybe<Scalars['Int']['input']>;
  industry?: InputMaybe<Scalars['String']['input']>;
  lead_creation_source?: InputMaybe<Scalars['String']['input']>;
  lead_in_queue?: InputMaybe<Scalars['Int']['input']>;
  lead_source?: InputMaybe<Scalars['String']['input']>;
  lead_value?: InputMaybe<Scalars['Float']['input']>;
  NDM_interested?: InputMaybe<Scalars['Int']['input']>;
  never_dialed_lead?: InputMaybe<Scalars['Int']['input']>;
  num_dials?: InputMaybe<Scalars['Int']['input']>;
  organization_id?: InputMaybe<Scalars['String']['input']>;
  owned_lead?: InputMaybe<Scalars['Int']['input']>;
  pipeline_state?: InputMaybe<PipelineColumn>;
  pipeline_state_int?: InputMaybe<Scalars['Int']['input']>;
  released_lead?: InputMaybe<Scalars['Int']['input']>;
  resting_lead?: InputMaybe<Scalars['Int']['input']>;
  retired_lead?: InputMaybe<Scalars['Int']['input']>;
  sale?: InputMaybe<Scalars['Int']['input']>;
  sales_amount?: InputMaybe<Scalars['Float']['input']>;
  scheduled_callback_after_dm_contacted?: InputMaybe<Scalars['Int']['input']>;
  set?: InputMaybe<Scalars['Int']['input']>;
  set_user?: InputMaybe<UserCreateNestedOneWithoutSet_User_StatInput>;
  site_id?: InputMaybe<Scalars['String']['input']>;
  skipped_lead?: InputMaybe<Scalars['Int']['input']>;
  team?: InputMaybe<TeamCreateNestedOneWithoutStatLeadAggregationInput>;
  time_between_inital_hold_and_scheduled_decision?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<StatItemAggregationType>;
  unassigned_lead?: InputMaybe<Scalars['Int']['input']>;
  unclosed_demo?: InputMaybe<Scalars['Int']['input']>;
  upcoming_demo?: InputMaybe<Scalars['Int']['input']>;
  upcoming_follow_up_demo?: InputMaybe<Scalars['Int']['input']>;
  upcoming_scheduled_call?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
  user_role?: InputMaybe<Role>;
};

export type StatLeadAggregationCreateWithoutSet_UserInput = {
  all_in_resting_post_demo_set?: InputMaybe<Scalars['Int']['input']>;
  assigned_lead?: InputMaybe<Scalars['Int']['input']>;
  associate_parent_id?: InputMaybe<Scalars['String']['input']>;
  cc_days_remaining?: InputMaybe<Scalars['Float']['input']>;
  cc_dm_lead?: InputMaybe<Scalars['Int']['input']>;
  cc_pre_dm_lead?: InputMaybe<Scalars['Int']['input']>;
  close_user?: InputMaybe<UserCreateNestedOneWithoutClose_User_StatInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  customer_lead?: InputMaybe<Scalars['Int']['input']>;
  decision_call?: InputMaybe<Scalars['Int']['input']>;
  demo_flake?: InputMaybe<Scalars['Int']['input']>;
  demo_held_no_action?: InputMaybe<Scalars['Int']['input']>;
  dm_reached_not_scheduled?: InputMaybe<Scalars['Int']['input']>;
  hold?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  in_progress_lead?: InputMaybe<Scalars['Int']['input']>;
  industry?: InputMaybe<Scalars['String']['input']>;
  lead?: InputMaybe<LeadCreateNestedOneWithoutStatLeadAggregationInput>;
  lead_creation_source?: InputMaybe<Scalars['String']['input']>;
  lead_in_queue?: InputMaybe<Scalars['Int']['input']>;
  lead_source?: InputMaybe<Scalars['String']['input']>;
  lead_value?: InputMaybe<Scalars['Float']['input']>;
  NDM_interested?: InputMaybe<Scalars['Int']['input']>;
  never_dialed_lead?: InputMaybe<Scalars['Int']['input']>;
  num_dials?: InputMaybe<Scalars['Int']['input']>;
  organization_id?: InputMaybe<Scalars['String']['input']>;
  owned_lead?: InputMaybe<Scalars['Int']['input']>;
  pipeline_state?: InputMaybe<PipelineColumn>;
  pipeline_state_int?: InputMaybe<Scalars['Int']['input']>;
  released_lead?: InputMaybe<Scalars['Int']['input']>;
  resting_lead?: InputMaybe<Scalars['Int']['input']>;
  retired_lead?: InputMaybe<Scalars['Int']['input']>;
  sale?: InputMaybe<Scalars['Int']['input']>;
  sales_amount?: InputMaybe<Scalars['Float']['input']>;
  scheduled_callback_after_dm_contacted?: InputMaybe<Scalars['Int']['input']>;
  set?: InputMaybe<Scalars['Int']['input']>;
  site_id?: InputMaybe<Scalars['String']['input']>;
  skipped_lead?: InputMaybe<Scalars['Int']['input']>;
  team?: InputMaybe<TeamCreateNestedOneWithoutStatLeadAggregationInput>;
  time_between_inital_hold_and_scheduled_decision?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<StatItemAggregationType>;
  unassigned_lead?: InputMaybe<Scalars['Int']['input']>;
  unclosed_demo?: InputMaybe<Scalars['Int']['input']>;
  upcoming_demo?: InputMaybe<Scalars['Int']['input']>;
  upcoming_follow_up_demo?: InputMaybe<Scalars['Int']['input']>;
  upcoming_scheduled_call?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
  user_role?: InputMaybe<Role>;
};

export type StatLeadAggregationCreateWithoutTeamInput = {
  all_in_resting_post_demo_set?: InputMaybe<Scalars['Int']['input']>;
  assigned_lead?: InputMaybe<Scalars['Int']['input']>;
  associate_parent_id?: InputMaybe<Scalars['String']['input']>;
  cc_days_remaining?: InputMaybe<Scalars['Float']['input']>;
  cc_dm_lead?: InputMaybe<Scalars['Int']['input']>;
  cc_pre_dm_lead?: InputMaybe<Scalars['Int']['input']>;
  close_user?: InputMaybe<UserCreateNestedOneWithoutClose_User_StatInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  customer_lead?: InputMaybe<Scalars['Int']['input']>;
  decision_call?: InputMaybe<Scalars['Int']['input']>;
  demo_flake?: InputMaybe<Scalars['Int']['input']>;
  demo_held_no_action?: InputMaybe<Scalars['Int']['input']>;
  dm_reached_not_scheduled?: InputMaybe<Scalars['Int']['input']>;
  hold?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  in_progress_lead?: InputMaybe<Scalars['Int']['input']>;
  industry?: InputMaybe<Scalars['String']['input']>;
  lead?: InputMaybe<LeadCreateNestedOneWithoutStatLeadAggregationInput>;
  lead_creation_source?: InputMaybe<Scalars['String']['input']>;
  lead_in_queue?: InputMaybe<Scalars['Int']['input']>;
  lead_source?: InputMaybe<Scalars['String']['input']>;
  lead_value?: InputMaybe<Scalars['Float']['input']>;
  NDM_interested?: InputMaybe<Scalars['Int']['input']>;
  never_dialed_lead?: InputMaybe<Scalars['Int']['input']>;
  num_dials?: InputMaybe<Scalars['Int']['input']>;
  organization_id?: InputMaybe<Scalars['String']['input']>;
  owned_lead?: InputMaybe<Scalars['Int']['input']>;
  pipeline_state?: InputMaybe<PipelineColumn>;
  pipeline_state_int?: InputMaybe<Scalars['Int']['input']>;
  released_lead?: InputMaybe<Scalars['Int']['input']>;
  resting_lead?: InputMaybe<Scalars['Int']['input']>;
  retired_lead?: InputMaybe<Scalars['Int']['input']>;
  sale?: InputMaybe<Scalars['Int']['input']>;
  sales_amount?: InputMaybe<Scalars['Float']['input']>;
  scheduled_callback_after_dm_contacted?: InputMaybe<Scalars['Int']['input']>;
  set?: InputMaybe<Scalars['Int']['input']>;
  set_user?: InputMaybe<UserCreateNestedOneWithoutSet_User_StatInput>;
  site_id?: InputMaybe<Scalars['String']['input']>;
  skipped_lead?: InputMaybe<Scalars['Int']['input']>;
  time_between_inital_hold_and_scheduled_decision?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<StatItemAggregationType>;
  unassigned_lead?: InputMaybe<Scalars['Int']['input']>;
  unclosed_demo?: InputMaybe<Scalars['Int']['input']>;
  upcoming_demo?: InputMaybe<Scalars['Int']['input']>;
  upcoming_follow_up_demo?: InputMaybe<Scalars['Int']['input']>;
  upcoming_scheduled_call?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
  user_role?: InputMaybe<Role>;
};

export type StatLeadAggregationListRelationFilter = {
  every?: InputMaybe<StatLeadAggregationWhereInput>;
  none?: InputMaybe<StatLeadAggregationWhereInput>;
  some?: InputMaybe<StatLeadAggregationWhereInput>;
};

export type StatLeadAggregationOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
  count?: InputMaybe<SortOrder>;
};

export type StatLeadAggregationOrderByWithRelationInput = {
  all_in_resting_post_demo_set?: InputMaybe<SortOrder>;
  assigned_lead?: InputMaybe<SortOrder>;
  associate_parent_id?: InputMaybe<SortOrder>;
  cc_days_remaining?: InputMaybe<SortOrder>;
  cc_dm_lead?: InputMaybe<SortOrder>;
  cc_pre_dm_lead?: InputMaybe<SortOrder>;
  close_user?: InputMaybe<UserOrderByWithRelationInput>;
  close_user_id?: InputMaybe<SortOrder>;
  created_at?: InputMaybe<SortOrder>;
  customer_lead?: InputMaybe<SortOrder>;
  decision_call?: InputMaybe<SortOrder>;
  demo_flake?: InputMaybe<SortOrder>;
  demo_held_no_action?: InputMaybe<SortOrder>;
  dm_reached_not_scheduled?: InputMaybe<SortOrder>;
  hold?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  in_progress_lead?: InputMaybe<SortOrder>;
  industry?: InputMaybe<SortOrder>;
  lead?: InputMaybe<LeadOrderByWithRelationInput>;
  lead_creation_source?: InputMaybe<SortOrder>;
  lead_id?: InputMaybe<SortOrder>;
  lead_in_queue?: InputMaybe<SortOrder>;
  lead_source?: InputMaybe<SortOrder>;
  lead_value?: InputMaybe<SortOrder>;
  NDM_interested?: InputMaybe<SortOrder>;
  never_dialed_lead?: InputMaybe<SortOrder>;
  num_dials?: InputMaybe<SortOrder>;
  organization_id?: InputMaybe<SortOrder>;
  owned_lead?: InputMaybe<SortOrder>;
  pipeline_state?: InputMaybe<SortOrder>;
  pipeline_state_int?: InputMaybe<SortOrder>;
  released_lead?: InputMaybe<SortOrder>;
  resting_lead?: InputMaybe<SortOrder>;
  retired_lead?: InputMaybe<SortOrder>;
  sale?: InputMaybe<SortOrder>;
  sales_amount?: InputMaybe<SortOrder>;
  scheduled_callback_after_dm_contacted?: InputMaybe<SortOrder>;
  set?: InputMaybe<SortOrder>;
  set_user?: InputMaybe<UserOrderByWithRelationInput>;
  set_user_id?: InputMaybe<SortOrder>;
  site_id?: InputMaybe<SortOrder>;
  skipped_lead?: InputMaybe<SortOrder>;
  team?: InputMaybe<TeamOrderByWithRelationInput>;
  team_id?: InputMaybe<SortOrder>;
  time_between_inital_hold_and_scheduled_decision?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  unassigned_lead?: InputMaybe<SortOrder>;
  unclosed_demo?: InputMaybe<SortOrder>;
  upcoming_demo?: InputMaybe<SortOrder>;
  upcoming_follow_up_demo?: InputMaybe<SortOrder>;
  upcoming_scheduled_call?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
  user_id?: InputMaybe<SortOrder>;
  user_role?: InputMaybe<SortOrder>;
};

export type StatLeadAggregationScalarWhereInput = {
  all_in_resting_post_demo_set?: InputMaybe<IntFilter>;
  AND?: InputMaybe<Array<StatLeadAggregationScalarWhereInput>>;
  assigned_lead?: InputMaybe<IntFilter>;
  associate_parent_id?: InputMaybe<StringNullableFilter>;
  cc_days_remaining?: InputMaybe<FloatFilter>;
  cc_dm_lead?: InputMaybe<IntFilter>;
  cc_pre_dm_lead?: InputMaybe<IntFilter>;
  close_user_id?: InputMaybe<StringNullableFilter>;
  created_at?: InputMaybe<DateTimeFilter>;
  customer_lead?: InputMaybe<IntFilter>;
  decision_call?: InputMaybe<IntFilter>;
  demo_flake?: InputMaybe<IntFilter>;
  demo_held_no_action?: InputMaybe<IntFilter>;
  dm_reached_not_scheduled?: InputMaybe<IntFilter>;
  hold?: InputMaybe<IntFilter>;
  id?: InputMaybe<StringFilter>;
  in_progress_lead?: InputMaybe<IntFilter>;
  industry?: InputMaybe<StringNullableFilter>;
  lead_creation_source?: InputMaybe<StringNullableFilter>;
  lead_id?: InputMaybe<StringNullableFilter>;
  lead_in_queue?: InputMaybe<IntFilter>;
  lead_source?: InputMaybe<StringNullableFilter>;
  lead_value?: InputMaybe<FloatNullableFilter>;
  NDM_interested?: InputMaybe<IntFilter>;
  never_dialed_lead?: InputMaybe<IntFilter>;
  NOT?: InputMaybe<Array<StatLeadAggregationScalarWhereInput>>;
  num_dials?: InputMaybe<IntFilter>;
  OR?: InputMaybe<Array<StatLeadAggregationScalarWhereInput>>;
  organization_id?: InputMaybe<StringNullableFilter>;
  owned_lead?: InputMaybe<IntFilter>;
  pipeline_state?: InputMaybe<EnumPipelineColumnFilter>;
  pipeline_state_int?: InputMaybe<IntFilter>;
  released_lead?: InputMaybe<IntFilter>;
  resting_lead?: InputMaybe<IntFilter>;
  retired_lead?: InputMaybe<IntFilter>;
  sale?: InputMaybe<IntFilter>;
  sales_amount?: InputMaybe<FloatNullableFilter>;
  scheduled_callback_after_dm_contacted?: InputMaybe<IntFilter>;
  set?: InputMaybe<IntFilter>;
  set_user_id?: InputMaybe<StringNullableFilter>;
  site_id?: InputMaybe<StringNullableFilter>;
  skipped_lead?: InputMaybe<IntFilter>;
  team_id?: InputMaybe<StringNullableFilter>;
  time_between_inital_hold_and_scheduled_decision?: InputMaybe<IntFilter>;
  type?: InputMaybe<EnumStatItemAggregationTypeFilter>;
  unassigned_lead?: InputMaybe<IntFilter>;
  unclosed_demo?: InputMaybe<IntFilter>;
  upcoming_demo?: InputMaybe<IntFilter>;
  upcoming_follow_up_demo?: InputMaybe<IntFilter>;
  upcoming_scheduled_call?: InputMaybe<IntFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
  user_id?: InputMaybe<StringNullableFilter>;
  user_role?: InputMaybe<EnumRoleNullableFilter>;
};

export type StatLeadAggregationUpdateManyMutationInput = {
  all_in_resting_post_demo_set?: InputMaybe<IntFieldUpdateOperationsInput>;
  assigned_lead?: InputMaybe<IntFieldUpdateOperationsInput>;
  associate_parent_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  cc_days_remaining?: InputMaybe<FloatFieldUpdateOperationsInput>;
  cc_dm_lead?: InputMaybe<IntFieldUpdateOperationsInput>;
  cc_pre_dm_lead?: InputMaybe<IntFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  customer_lead?: InputMaybe<IntFieldUpdateOperationsInput>;
  decision_call?: InputMaybe<IntFieldUpdateOperationsInput>;
  demo_flake?: InputMaybe<IntFieldUpdateOperationsInput>;
  demo_held_no_action?: InputMaybe<IntFieldUpdateOperationsInput>;
  dm_reached_not_scheduled?: InputMaybe<IntFieldUpdateOperationsInput>;
  hold?: InputMaybe<IntFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  in_progress_lead?: InputMaybe<IntFieldUpdateOperationsInput>;
  industry?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  lead_creation_source?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  lead_in_queue?: InputMaybe<IntFieldUpdateOperationsInput>;
  lead_source?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  lead_value?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  NDM_interested?: InputMaybe<IntFieldUpdateOperationsInput>;
  never_dialed_lead?: InputMaybe<IntFieldUpdateOperationsInput>;
  num_dials?: InputMaybe<IntFieldUpdateOperationsInput>;
  organization_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  owned_lead?: InputMaybe<IntFieldUpdateOperationsInput>;
  pipeline_state?: InputMaybe<EnumPipelineColumnFieldUpdateOperationsInput>;
  pipeline_state_int?: InputMaybe<IntFieldUpdateOperationsInput>;
  released_lead?: InputMaybe<IntFieldUpdateOperationsInput>;
  resting_lead?: InputMaybe<IntFieldUpdateOperationsInput>;
  retired_lead?: InputMaybe<IntFieldUpdateOperationsInput>;
  sale?: InputMaybe<IntFieldUpdateOperationsInput>;
  sales_amount?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  scheduled_callback_after_dm_contacted?: InputMaybe<IntFieldUpdateOperationsInput>;
  set?: InputMaybe<IntFieldUpdateOperationsInput>;
  site_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  skipped_lead?: InputMaybe<IntFieldUpdateOperationsInput>;
  time_between_inital_hold_and_scheduled_decision?: InputMaybe<IntFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumStatItemAggregationTypeFieldUpdateOperationsInput>;
  unassigned_lead?: InputMaybe<IntFieldUpdateOperationsInput>;
  unclosed_demo?: InputMaybe<IntFieldUpdateOperationsInput>;
  upcoming_demo?: InputMaybe<IntFieldUpdateOperationsInput>;
  upcoming_follow_up_demo?: InputMaybe<IntFieldUpdateOperationsInput>;
  upcoming_scheduled_call?: InputMaybe<IntFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  user_role?: InputMaybe<NullableEnumRoleFieldUpdateOperationsInput>;
};

export type StatLeadAggregationUpdateManyWithoutClose_UserInput = {
  connect?: InputMaybe<Array<StatLeadAggregationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StatLeadAggregationCreateOrConnectWithoutClose_UserInput>>;
  create?: InputMaybe<Array<StatLeadAggregationCreateWithoutClose_UserInput>>;
  createMany?: InputMaybe<StatLeadAggregationCreateManyClose_UserInputEnvelope>;
  delete?: InputMaybe<Array<StatLeadAggregationWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<StatLeadAggregationScalarWhereInput>>;
  disconnect?: InputMaybe<Array<StatLeadAggregationWhereUniqueInput>>;
  set?: InputMaybe<Array<StatLeadAggregationWhereUniqueInput>>;
  update?: InputMaybe<Array<StatLeadAggregationUpdateWithWhereUniqueWithoutClose_UserInput>>;
  updateMany?: InputMaybe<Array<StatLeadAggregationUpdateManyWithWhereWithoutClose_UserInput>>;
  upsert?: InputMaybe<Array<StatLeadAggregationUpsertWithWhereUniqueWithoutClose_UserInput>>;
};

export type StatLeadAggregationUpdateManyWithoutSet_UserInput = {
  connect?: InputMaybe<Array<StatLeadAggregationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StatLeadAggregationCreateOrConnectWithoutSet_UserInput>>;
  create?: InputMaybe<Array<StatLeadAggregationCreateWithoutSet_UserInput>>;
  createMany?: InputMaybe<StatLeadAggregationCreateManySet_UserInputEnvelope>;
  delete?: InputMaybe<Array<StatLeadAggregationWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<StatLeadAggregationScalarWhereInput>>;
  disconnect?: InputMaybe<Array<StatLeadAggregationWhereUniqueInput>>;
  set?: InputMaybe<Array<StatLeadAggregationWhereUniqueInput>>;
  update?: InputMaybe<Array<StatLeadAggregationUpdateWithWhereUniqueWithoutSet_UserInput>>;
  updateMany?: InputMaybe<Array<StatLeadAggregationUpdateManyWithWhereWithoutSet_UserInput>>;
  upsert?: InputMaybe<Array<StatLeadAggregationUpsertWithWhereUniqueWithoutSet_UserInput>>;
};

export type StatLeadAggregationUpdateManyWithoutTeamInput = {
  connect?: InputMaybe<Array<StatLeadAggregationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StatLeadAggregationCreateOrConnectWithoutTeamInput>>;
  create?: InputMaybe<Array<StatLeadAggregationCreateWithoutTeamInput>>;
  createMany?: InputMaybe<StatLeadAggregationCreateManyTeamInputEnvelope>;
  delete?: InputMaybe<Array<StatLeadAggregationWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<StatLeadAggregationScalarWhereInput>>;
  disconnect?: InputMaybe<Array<StatLeadAggregationWhereUniqueInput>>;
  set?: InputMaybe<Array<StatLeadAggregationWhereUniqueInput>>;
  update?: InputMaybe<Array<StatLeadAggregationUpdateWithWhereUniqueWithoutTeamInput>>;
  updateMany?: InputMaybe<Array<StatLeadAggregationUpdateManyWithWhereWithoutTeamInput>>;
  upsert?: InputMaybe<Array<StatLeadAggregationUpsertWithWhereUniqueWithoutTeamInput>>;
};

export type StatLeadAggregationUpdateManyWithWhereWithoutClose_UserInput = {
  data: StatLeadAggregationUpdateManyMutationInput;
  where: StatLeadAggregationScalarWhereInput;
};

export type StatLeadAggregationUpdateManyWithWhereWithoutSet_UserInput = {
  data: StatLeadAggregationUpdateManyMutationInput;
  where: StatLeadAggregationScalarWhereInput;
};

export type StatLeadAggregationUpdateManyWithWhereWithoutTeamInput = {
  data: StatLeadAggregationUpdateManyMutationInput;
  where: StatLeadAggregationScalarWhereInput;
};

export type StatLeadAggregationUpdateOneWithoutLeadInput = {
  connect?: InputMaybe<StatLeadAggregationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<StatLeadAggregationCreateOrConnectWithoutLeadInput>;
  create?: InputMaybe<StatLeadAggregationCreateWithoutLeadInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<StatLeadAggregationUpdateWithoutLeadInput>;
  upsert?: InputMaybe<StatLeadAggregationUpsertWithoutLeadInput>;
};

export type StatLeadAggregationUpdateWithoutClose_UserInput = {
  all_in_resting_post_demo_set?: InputMaybe<IntFieldUpdateOperationsInput>;
  assigned_lead?: InputMaybe<IntFieldUpdateOperationsInput>;
  associate_parent_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  cc_days_remaining?: InputMaybe<FloatFieldUpdateOperationsInput>;
  cc_dm_lead?: InputMaybe<IntFieldUpdateOperationsInput>;
  cc_pre_dm_lead?: InputMaybe<IntFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  customer_lead?: InputMaybe<IntFieldUpdateOperationsInput>;
  decision_call?: InputMaybe<IntFieldUpdateOperationsInput>;
  demo_flake?: InputMaybe<IntFieldUpdateOperationsInput>;
  demo_held_no_action?: InputMaybe<IntFieldUpdateOperationsInput>;
  dm_reached_not_scheduled?: InputMaybe<IntFieldUpdateOperationsInput>;
  hold?: InputMaybe<IntFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  in_progress_lead?: InputMaybe<IntFieldUpdateOperationsInput>;
  industry?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  lead?: InputMaybe<LeadUpdateOneWithoutStatLeadAggregationInput>;
  lead_creation_source?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  lead_in_queue?: InputMaybe<IntFieldUpdateOperationsInput>;
  lead_source?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  lead_value?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  NDM_interested?: InputMaybe<IntFieldUpdateOperationsInput>;
  never_dialed_lead?: InputMaybe<IntFieldUpdateOperationsInput>;
  num_dials?: InputMaybe<IntFieldUpdateOperationsInput>;
  organization_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  owned_lead?: InputMaybe<IntFieldUpdateOperationsInput>;
  pipeline_state?: InputMaybe<EnumPipelineColumnFieldUpdateOperationsInput>;
  pipeline_state_int?: InputMaybe<IntFieldUpdateOperationsInput>;
  released_lead?: InputMaybe<IntFieldUpdateOperationsInput>;
  resting_lead?: InputMaybe<IntFieldUpdateOperationsInput>;
  retired_lead?: InputMaybe<IntFieldUpdateOperationsInput>;
  sale?: InputMaybe<IntFieldUpdateOperationsInput>;
  sales_amount?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  scheduled_callback_after_dm_contacted?: InputMaybe<IntFieldUpdateOperationsInput>;
  set?: InputMaybe<IntFieldUpdateOperationsInput>;
  set_user?: InputMaybe<UserUpdateOneWithoutSet_User_StatInput>;
  site_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  skipped_lead?: InputMaybe<IntFieldUpdateOperationsInput>;
  team?: InputMaybe<TeamUpdateOneWithoutStatLeadAggregationInput>;
  time_between_inital_hold_and_scheduled_decision?: InputMaybe<IntFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumStatItemAggregationTypeFieldUpdateOperationsInput>;
  unassigned_lead?: InputMaybe<IntFieldUpdateOperationsInput>;
  unclosed_demo?: InputMaybe<IntFieldUpdateOperationsInput>;
  upcoming_demo?: InputMaybe<IntFieldUpdateOperationsInput>;
  upcoming_follow_up_demo?: InputMaybe<IntFieldUpdateOperationsInput>;
  upcoming_scheduled_call?: InputMaybe<IntFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  user_role?: InputMaybe<NullableEnumRoleFieldUpdateOperationsInput>;
};

export type StatLeadAggregationUpdateWithoutLeadInput = {
  all_in_resting_post_demo_set?: InputMaybe<IntFieldUpdateOperationsInput>;
  assigned_lead?: InputMaybe<IntFieldUpdateOperationsInput>;
  associate_parent_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  cc_days_remaining?: InputMaybe<FloatFieldUpdateOperationsInput>;
  cc_dm_lead?: InputMaybe<IntFieldUpdateOperationsInput>;
  cc_pre_dm_lead?: InputMaybe<IntFieldUpdateOperationsInput>;
  close_user?: InputMaybe<UserUpdateOneWithoutClose_User_StatInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  customer_lead?: InputMaybe<IntFieldUpdateOperationsInput>;
  decision_call?: InputMaybe<IntFieldUpdateOperationsInput>;
  demo_flake?: InputMaybe<IntFieldUpdateOperationsInput>;
  demo_held_no_action?: InputMaybe<IntFieldUpdateOperationsInput>;
  dm_reached_not_scheduled?: InputMaybe<IntFieldUpdateOperationsInput>;
  hold?: InputMaybe<IntFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  in_progress_lead?: InputMaybe<IntFieldUpdateOperationsInput>;
  industry?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  lead_creation_source?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  lead_in_queue?: InputMaybe<IntFieldUpdateOperationsInput>;
  lead_source?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  lead_value?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  NDM_interested?: InputMaybe<IntFieldUpdateOperationsInput>;
  never_dialed_lead?: InputMaybe<IntFieldUpdateOperationsInput>;
  num_dials?: InputMaybe<IntFieldUpdateOperationsInput>;
  organization_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  owned_lead?: InputMaybe<IntFieldUpdateOperationsInput>;
  pipeline_state?: InputMaybe<EnumPipelineColumnFieldUpdateOperationsInput>;
  pipeline_state_int?: InputMaybe<IntFieldUpdateOperationsInput>;
  released_lead?: InputMaybe<IntFieldUpdateOperationsInput>;
  resting_lead?: InputMaybe<IntFieldUpdateOperationsInput>;
  retired_lead?: InputMaybe<IntFieldUpdateOperationsInput>;
  sale?: InputMaybe<IntFieldUpdateOperationsInput>;
  sales_amount?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  scheduled_callback_after_dm_contacted?: InputMaybe<IntFieldUpdateOperationsInput>;
  set?: InputMaybe<IntFieldUpdateOperationsInput>;
  set_user?: InputMaybe<UserUpdateOneWithoutSet_User_StatInput>;
  site_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  skipped_lead?: InputMaybe<IntFieldUpdateOperationsInput>;
  team?: InputMaybe<TeamUpdateOneWithoutStatLeadAggregationInput>;
  time_between_inital_hold_and_scheduled_decision?: InputMaybe<IntFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumStatItemAggregationTypeFieldUpdateOperationsInput>;
  unassigned_lead?: InputMaybe<IntFieldUpdateOperationsInput>;
  unclosed_demo?: InputMaybe<IntFieldUpdateOperationsInput>;
  upcoming_demo?: InputMaybe<IntFieldUpdateOperationsInput>;
  upcoming_follow_up_demo?: InputMaybe<IntFieldUpdateOperationsInput>;
  upcoming_scheduled_call?: InputMaybe<IntFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  user_role?: InputMaybe<NullableEnumRoleFieldUpdateOperationsInput>;
};

export type StatLeadAggregationUpdateWithoutSet_UserInput = {
  all_in_resting_post_demo_set?: InputMaybe<IntFieldUpdateOperationsInput>;
  assigned_lead?: InputMaybe<IntFieldUpdateOperationsInput>;
  associate_parent_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  cc_days_remaining?: InputMaybe<FloatFieldUpdateOperationsInput>;
  cc_dm_lead?: InputMaybe<IntFieldUpdateOperationsInput>;
  cc_pre_dm_lead?: InputMaybe<IntFieldUpdateOperationsInput>;
  close_user?: InputMaybe<UserUpdateOneWithoutClose_User_StatInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  customer_lead?: InputMaybe<IntFieldUpdateOperationsInput>;
  decision_call?: InputMaybe<IntFieldUpdateOperationsInput>;
  demo_flake?: InputMaybe<IntFieldUpdateOperationsInput>;
  demo_held_no_action?: InputMaybe<IntFieldUpdateOperationsInput>;
  dm_reached_not_scheduled?: InputMaybe<IntFieldUpdateOperationsInput>;
  hold?: InputMaybe<IntFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  in_progress_lead?: InputMaybe<IntFieldUpdateOperationsInput>;
  industry?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  lead?: InputMaybe<LeadUpdateOneWithoutStatLeadAggregationInput>;
  lead_creation_source?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  lead_in_queue?: InputMaybe<IntFieldUpdateOperationsInput>;
  lead_source?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  lead_value?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  NDM_interested?: InputMaybe<IntFieldUpdateOperationsInput>;
  never_dialed_lead?: InputMaybe<IntFieldUpdateOperationsInput>;
  num_dials?: InputMaybe<IntFieldUpdateOperationsInput>;
  organization_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  owned_lead?: InputMaybe<IntFieldUpdateOperationsInput>;
  pipeline_state?: InputMaybe<EnumPipelineColumnFieldUpdateOperationsInput>;
  pipeline_state_int?: InputMaybe<IntFieldUpdateOperationsInput>;
  released_lead?: InputMaybe<IntFieldUpdateOperationsInput>;
  resting_lead?: InputMaybe<IntFieldUpdateOperationsInput>;
  retired_lead?: InputMaybe<IntFieldUpdateOperationsInput>;
  sale?: InputMaybe<IntFieldUpdateOperationsInput>;
  sales_amount?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  scheduled_callback_after_dm_contacted?: InputMaybe<IntFieldUpdateOperationsInput>;
  set?: InputMaybe<IntFieldUpdateOperationsInput>;
  site_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  skipped_lead?: InputMaybe<IntFieldUpdateOperationsInput>;
  team?: InputMaybe<TeamUpdateOneWithoutStatLeadAggregationInput>;
  time_between_inital_hold_and_scheduled_decision?: InputMaybe<IntFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumStatItemAggregationTypeFieldUpdateOperationsInput>;
  unassigned_lead?: InputMaybe<IntFieldUpdateOperationsInput>;
  unclosed_demo?: InputMaybe<IntFieldUpdateOperationsInput>;
  upcoming_demo?: InputMaybe<IntFieldUpdateOperationsInput>;
  upcoming_follow_up_demo?: InputMaybe<IntFieldUpdateOperationsInput>;
  upcoming_scheduled_call?: InputMaybe<IntFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  user_role?: InputMaybe<NullableEnumRoleFieldUpdateOperationsInput>;
};

export type StatLeadAggregationUpdateWithoutTeamInput = {
  all_in_resting_post_demo_set?: InputMaybe<IntFieldUpdateOperationsInput>;
  assigned_lead?: InputMaybe<IntFieldUpdateOperationsInput>;
  associate_parent_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  cc_days_remaining?: InputMaybe<FloatFieldUpdateOperationsInput>;
  cc_dm_lead?: InputMaybe<IntFieldUpdateOperationsInput>;
  cc_pre_dm_lead?: InputMaybe<IntFieldUpdateOperationsInput>;
  close_user?: InputMaybe<UserUpdateOneWithoutClose_User_StatInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  customer_lead?: InputMaybe<IntFieldUpdateOperationsInput>;
  decision_call?: InputMaybe<IntFieldUpdateOperationsInput>;
  demo_flake?: InputMaybe<IntFieldUpdateOperationsInput>;
  demo_held_no_action?: InputMaybe<IntFieldUpdateOperationsInput>;
  dm_reached_not_scheduled?: InputMaybe<IntFieldUpdateOperationsInput>;
  hold?: InputMaybe<IntFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  in_progress_lead?: InputMaybe<IntFieldUpdateOperationsInput>;
  industry?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  lead?: InputMaybe<LeadUpdateOneWithoutStatLeadAggregationInput>;
  lead_creation_source?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  lead_in_queue?: InputMaybe<IntFieldUpdateOperationsInput>;
  lead_source?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  lead_value?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  NDM_interested?: InputMaybe<IntFieldUpdateOperationsInput>;
  never_dialed_lead?: InputMaybe<IntFieldUpdateOperationsInput>;
  num_dials?: InputMaybe<IntFieldUpdateOperationsInput>;
  organization_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  owned_lead?: InputMaybe<IntFieldUpdateOperationsInput>;
  pipeline_state?: InputMaybe<EnumPipelineColumnFieldUpdateOperationsInput>;
  pipeline_state_int?: InputMaybe<IntFieldUpdateOperationsInput>;
  released_lead?: InputMaybe<IntFieldUpdateOperationsInput>;
  resting_lead?: InputMaybe<IntFieldUpdateOperationsInput>;
  retired_lead?: InputMaybe<IntFieldUpdateOperationsInput>;
  sale?: InputMaybe<IntFieldUpdateOperationsInput>;
  sales_amount?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  scheduled_callback_after_dm_contacted?: InputMaybe<IntFieldUpdateOperationsInput>;
  set?: InputMaybe<IntFieldUpdateOperationsInput>;
  set_user?: InputMaybe<UserUpdateOneWithoutSet_User_StatInput>;
  site_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  skipped_lead?: InputMaybe<IntFieldUpdateOperationsInput>;
  time_between_inital_hold_and_scheduled_decision?: InputMaybe<IntFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumStatItemAggregationTypeFieldUpdateOperationsInput>;
  unassigned_lead?: InputMaybe<IntFieldUpdateOperationsInput>;
  unclosed_demo?: InputMaybe<IntFieldUpdateOperationsInput>;
  upcoming_demo?: InputMaybe<IntFieldUpdateOperationsInput>;
  upcoming_follow_up_demo?: InputMaybe<IntFieldUpdateOperationsInput>;
  upcoming_scheduled_call?: InputMaybe<IntFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  user_role?: InputMaybe<NullableEnumRoleFieldUpdateOperationsInput>;
};

export type StatLeadAggregationUpdateWithWhereUniqueWithoutClose_UserInput = {
  data: StatLeadAggregationUpdateWithoutClose_UserInput;
  where: StatLeadAggregationWhereUniqueInput;
};

export type StatLeadAggregationUpdateWithWhereUniqueWithoutSet_UserInput = {
  data: StatLeadAggregationUpdateWithoutSet_UserInput;
  where: StatLeadAggregationWhereUniqueInput;
};

export type StatLeadAggregationUpdateWithWhereUniqueWithoutTeamInput = {
  data: StatLeadAggregationUpdateWithoutTeamInput;
  where: StatLeadAggregationWhereUniqueInput;
};

export type StatLeadAggregationUpsertWithoutLeadInput = {
  create: StatLeadAggregationCreateWithoutLeadInput;
  update: StatLeadAggregationUpdateWithoutLeadInput;
};

export type StatLeadAggregationUpsertWithWhereUniqueWithoutClose_UserInput = {
  create: StatLeadAggregationCreateWithoutClose_UserInput;
  update: StatLeadAggregationUpdateWithoutClose_UserInput;
  where: StatLeadAggregationWhereUniqueInput;
};

export type StatLeadAggregationUpsertWithWhereUniqueWithoutSet_UserInput = {
  create: StatLeadAggregationCreateWithoutSet_UserInput;
  update: StatLeadAggregationUpdateWithoutSet_UserInput;
  where: StatLeadAggregationWhereUniqueInput;
};

export type StatLeadAggregationUpsertWithWhereUniqueWithoutTeamInput = {
  create: StatLeadAggregationCreateWithoutTeamInput;
  update: StatLeadAggregationUpdateWithoutTeamInput;
  where: StatLeadAggregationWhereUniqueInput;
};

export type StatLeadAggregationWhereInput = {
  all_in_resting_post_demo_set?: InputMaybe<IntFilter>;
  AND?: InputMaybe<Array<StatLeadAggregationWhereInput>>;
  assigned_lead?: InputMaybe<IntFilter>;
  associate_parent_id?: InputMaybe<StringNullableFilter>;
  cc_days_remaining?: InputMaybe<FloatFilter>;
  cc_dm_lead?: InputMaybe<IntFilter>;
  cc_pre_dm_lead?: InputMaybe<IntFilter>;
  close_user?: InputMaybe<UserWhereInput>;
  close_user_id?: InputMaybe<StringNullableFilter>;
  created_at?: InputMaybe<DateTimeFilter>;
  customer_lead?: InputMaybe<IntFilter>;
  decision_call?: InputMaybe<IntFilter>;
  demo_flake?: InputMaybe<IntFilter>;
  demo_held_no_action?: InputMaybe<IntFilter>;
  dm_reached_not_scheduled?: InputMaybe<IntFilter>;
  hold?: InputMaybe<IntFilter>;
  id?: InputMaybe<StringFilter>;
  in_progress_lead?: InputMaybe<IntFilter>;
  industry?: InputMaybe<StringNullableFilter>;
  lead?: InputMaybe<LeadWhereInput>;
  lead_creation_source?: InputMaybe<StringNullableFilter>;
  lead_id?: InputMaybe<StringNullableFilter>;
  lead_in_queue?: InputMaybe<IntFilter>;
  lead_source?: InputMaybe<StringNullableFilter>;
  lead_value?: InputMaybe<FloatNullableFilter>;
  NDM_interested?: InputMaybe<IntFilter>;
  never_dialed_lead?: InputMaybe<IntFilter>;
  NOT?: InputMaybe<Array<StatLeadAggregationWhereInput>>;
  num_dials?: InputMaybe<IntFilter>;
  OR?: InputMaybe<Array<StatLeadAggregationWhereInput>>;
  organization_id?: InputMaybe<StringNullableFilter>;
  owned_lead?: InputMaybe<IntFilter>;
  pipeline_state?: InputMaybe<EnumPipelineColumnFilter>;
  pipeline_state_int?: InputMaybe<IntFilter>;
  released_lead?: InputMaybe<IntFilter>;
  resting_lead?: InputMaybe<IntFilter>;
  retired_lead?: InputMaybe<IntFilter>;
  sale?: InputMaybe<IntFilter>;
  sales_amount?: InputMaybe<FloatNullableFilter>;
  scheduled_callback_after_dm_contacted?: InputMaybe<IntFilter>;
  set?: InputMaybe<IntFilter>;
  set_user?: InputMaybe<UserWhereInput>;
  set_user_id?: InputMaybe<StringNullableFilter>;
  site_id?: InputMaybe<StringNullableFilter>;
  skipped_lead?: InputMaybe<IntFilter>;
  team?: InputMaybe<TeamWhereInput>;
  team_id?: InputMaybe<StringNullableFilter>;
  time_between_inital_hold_and_scheduled_decision?: InputMaybe<IntFilter>;
  type?: InputMaybe<EnumStatItemAggregationTypeFilter>;
  unassigned_lead?: InputMaybe<IntFilter>;
  unclosed_demo?: InputMaybe<IntFilter>;
  upcoming_demo?: InputMaybe<IntFilter>;
  upcoming_follow_up_demo?: InputMaybe<IntFilter>;
  upcoming_scheduled_call?: InputMaybe<IntFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
  user_id?: InputMaybe<StringNullableFilter>;
  user_role?: InputMaybe<EnumRoleNullableFilter>;
};

export type StatLeadAggregationWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  lead_id?: InputMaybe<Scalars['String']['input']>;
};

export enum Status {
  Call = 'CALL',
  Demo = 'DEMO',
  Idle = 'IDLE',
  Offline = 'OFFLINE'
}

export type StepAction = {
  __typename?: 'StepAction';
  bounced_perc: Scalars['Float']['output'];
  clicked_perc: Scalars['Float']['output'];
  created_at: Scalars['DateTime']['output'];
  custom_task_note?: Maybe<Scalars['String']['output']>;
  delay_amount?: Maybe<Scalars['Int']['output']>;
  delivered_perc: Scalars['Float']['output'];
  id: Scalars['String']['output'];
  opened_perc: Scalars['Float']['output'];
  replied_perc: Scalars['Float']['output'];
  scheduled_event_behavior: SequenceStepScheduledEventBehavior;
  step: SequenceStep;
  step_id: Scalars['String']['output'];
  task?: Maybe<SequenceTask>;
  template?: Maybe<Template>;
  template_id?: Maybe<Scalars['String']['output']>;
  unsubscribed_perc: Scalars['Float']['output'];
  updated_at: Scalars['DateTime']['output'];
};

export type StepActionCreateManyOrganizationInput = {
  bounced_perc?: InputMaybe<Scalars['Float']['input']>;
  clicked_perc?: InputMaybe<Scalars['Float']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  custom_task_note?: InputMaybe<Scalars['String']['input']>;
  delay_amount?: InputMaybe<Scalars['Int']['input']>;
  delivered_perc?: InputMaybe<Scalars['Float']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  opened_perc?: InputMaybe<Scalars['Float']['input']>;
  replied_perc?: InputMaybe<Scalars['Float']['input']>;
  scheduled_event_behavior?: InputMaybe<SequenceStepScheduledEventBehavior>;
  step_id: Scalars['String']['input'];
  task?: InputMaybe<SequenceTask>;
  template_id?: InputMaybe<Scalars['String']['input']>;
  unsubscribed_perc?: InputMaybe<Scalars['Float']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type StepActionCreateManyOrganizationInputEnvelope = {
  data?: InputMaybe<Array<StepActionCreateManyOrganizationInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type StepActionCreateManyStepInput = {
  bounced_perc?: InputMaybe<Scalars['Float']['input']>;
  clicked_perc?: InputMaybe<Scalars['Float']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  custom_task_note?: InputMaybe<Scalars['String']['input']>;
  delay_amount?: InputMaybe<Scalars['Int']['input']>;
  delivered_perc?: InputMaybe<Scalars['Float']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  opened_perc?: InputMaybe<Scalars['Float']['input']>;
  organization_id?: InputMaybe<Scalars['String']['input']>;
  replied_perc?: InputMaybe<Scalars['Float']['input']>;
  scheduled_event_behavior?: InputMaybe<SequenceStepScheduledEventBehavior>;
  task?: InputMaybe<SequenceTask>;
  template_id?: InputMaybe<Scalars['String']['input']>;
  unsubscribed_perc?: InputMaybe<Scalars['Float']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type StepActionCreateManyStepInputEnvelope = {
  data?: InputMaybe<Array<StepActionCreateManyStepInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type StepActionCreateManyTemplateInput = {
  bounced_perc?: InputMaybe<Scalars['Float']['input']>;
  clicked_perc?: InputMaybe<Scalars['Float']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  custom_task_note?: InputMaybe<Scalars['String']['input']>;
  delay_amount?: InputMaybe<Scalars['Int']['input']>;
  delivered_perc?: InputMaybe<Scalars['Float']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  opened_perc?: InputMaybe<Scalars['Float']['input']>;
  organization_id?: InputMaybe<Scalars['String']['input']>;
  replied_perc?: InputMaybe<Scalars['Float']['input']>;
  scheduled_event_behavior?: InputMaybe<SequenceStepScheduledEventBehavior>;
  step_id: Scalars['String']['input'];
  task?: InputMaybe<SequenceTask>;
  unsubscribed_perc?: InputMaybe<Scalars['Float']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type StepActionCreateManyTemplateInputEnvelope = {
  data?: InputMaybe<Array<StepActionCreateManyTemplateInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type StepActionCreateNestedManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<StepActionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StepActionCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<StepActionCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<StepActionCreateManyOrganizationInputEnvelope>;
};

export type StepActionCreateNestedManyWithoutStepInput = {
  connect?: InputMaybe<Array<StepActionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StepActionCreateOrConnectWithoutStepInput>>;
  create?: InputMaybe<Array<StepActionCreateWithoutStepInput>>;
  createMany?: InputMaybe<StepActionCreateManyStepInputEnvelope>;
};

export type StepActionCreateNestedManyWithoutTemplateInput = {
  connect?: InputMaybe<Array<StepActionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StepActionCreateOrConnectWithoutTemplateInput>>;
  create?: InputMaybe<Array<StepActionCreateWithoutTemplateInput>>;
  createMany?: InputMaybe<StepActionCreateManyTemplateInputEnvelope>;
};

export type StepActionCreateNestedOneWithoutEmailItemInput = {
  connect?: InputMaybe<StepActionWhereUniqueInput>;
  connectOrCreate?: InputMaybe<StepActionCreateOrConnectWithoutEmailItemInput>;
  create?: InputMaybe<StepActionCreateWithoutEmailItemInput>;
};

export type StepActionCreateNestedOneWithoutSmsItemInput = {
  connect?: InputMaybe<StepActionWhereUniqueInput>;
  connectOrCreate?: InputMaybe<StepActionCreateOrConnectWithoutSmsItemInput>;
  create?: InputMaybe<StepActionCreateWithoutSmsItemInput>;
};

export type StepActionCreateNestedOneWithoutStepActionLogInput = {
  connect?: InputMaybe<StepActionWhereUniqueInput>;
  connectOrCreate?: InputMaybe<StepActionCreateOrConnectWithoutStepActionLogInput>;
  create?: InputMaybe<StepActionCreateWithoutStepActionLogInput>;
};

export type StepActionCreateOrConnectWithoutEmailItemInput = {
  create: StepActionCreateWithoutEmailItemInput;
  where: StepActionWhereUniqueInput;
};

export type StepActionCreateOrConnectWithoutOrganizationInput = {
  create: StepActionCreateWithoutOrganizationInput;
  where: StepActionWhereUniqueInput;
};

export type StepActionCreateOrConnectWithoutSmsItemInput = {
  create: StepActionCreateWithoutSmsItemInput;
  where: StepActionWhereUniqueInput;
};

export type StepActionCreateOrConnectWithoutStepActionLogInput = {
  create: StepActionCreateWithoutStepActionLogInput;
  where: StepActionWhereUniqueInput;
};

export type StepActionCreateOrConnectWithoutStepInput = {
  create: StepActionCreateWithoutStepInput;
  where: StepActionWhereUniqueInput;
};

export type StepActionCreateOrConnectWithoutTemplateInput = {
  create: StepActionCreateWithoutTemplateInput;
  where: StepActionWhereUniqueInput;
};

export type StepActionCreateWithoutEmailItemInput = {
  bounced_perc?: InputMaybe<Scalars['Float']['input']>;
  clicked_perc?: InputMaybe<Scalars['Float']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  custom_task_note?: InputMaybe<Scalars['String']['input']>;
  delay_amount?: InputMaybe<Scalars['Int']['input']>;
  delivered_perc?: InputMaybe<Scalars['Float']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  opened_perc?: InputMaybe<Scalars['Float']['input']>;
  Organization?: InputMaybe<OrganizationCreateNestedOneWithoutStepActionsInput>;
  replied_perc?: InputMaybe<Scalars['Float']['input']>;
  scheduled_event_behavior?: InputMaybe<SequenceStepScheduledEventBehavior>;
  SMSItem?: InputMaybe<SmsItemCreateNestedManyWithoutStep_ActionInput>;
  step: SequenceStepCreateNestedOneWithoutActionsInput;
  StepActionLog?: InputMaybe<StepActionLogCreateNestedManyWithoutStepActionInput>;
  task?: InputMaybe<SequenceTask>;
  template?: InputMaybe<TemplateCreateNestedOneWithoutStepActionInput>;
  unsubscribed_perc?: InputMaybe<Scalars['Float']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type StepActionCreateWithoutOrganizationInput = {
  bounced_perc?: InputMaybe<Scalars['Float']['input']>;
  clicked_perc?: InputMaybe<Scalars['Float']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  custom_task_note?: InputMaybe<Scalars['String']['input']>;
  delay_amount?: InputMaybe<Scalars['Int']['input']>;
  delivered_perc?: InputMaybe<Scalars['Float']['input']>;
  EmailItem?: InputMaybe<EmailItemCreateNestedManyWithoutStep_ActionInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  opened_perc?: InputMaybe<Scalars['Float']['input']>;
  replied_perc?: InputMaybe<Scalars['Float']['input']>;
  scheduled_event_behavior?: InputMaybe<SequenceStepScheduledEventBehavior>;
  SMSItem?: InputMaybe<SmsItemCreateNestedManyWithoutStep_ActionInput>;
  step: SequenceStepCreateNestedOneWithoutActionsInput;
  StepActionLog?: InputMaybe<StepActionLogCreateNestedManyWithoutStepActionInput>;
  task?: InputMaybe<SequenceTask>;
  template?: InputMaybe<TemplateCreateNestedOneWithoutStepActionInput>;
  unsubscribed_perc?: InputMaybe<Scalars['Float']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type StepActionCreateWithoutSmsItemInput = {
  bounced_perc?: InputMaybe<Scalars['Float']['input']>;
  clicked_perc?: InputMaybe<Scalars['Float']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  custom_task_note?: InputMaybe<Scalars['String']['input']>;
  delay_amount?: InputMaybe<Scalars['Int']['input']>;
  delivered_perc?: InputMaybe<Scalars['Float']['input']>;
  EmailItem?: InputMaybe<EmailItemCreateNestedManyWithoutStep_ActionInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  opened_perc?: InputMaybe<Scalars['Float']['input']>;
  Organization?: InputMaybe<OrganizationCreateNestedOneWithoutStepActionsInput>;
  replied_perc?: InputMaybe<Scalars['Float']['input']>;
  scheduled_event_behavior?: InputMaybe<SequenceStepScheduledEventBehavior>;
  step: SequenceStepCreateNestedOneWithoutActionsInput;
  StepActionLog?: InputMaybe<StepActionLogCreateNestedManyWithoutStepActionInput>;
  task?: InputMaybe<SequenceTask>;
  template?: InputMaybe<TemplateCreateNestedOneWithoutStepActionInput>;
  unsubscribed_perc?: InputMaybe<Scalars['Float']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type StepActionCreateWithoutStepActionLogInput = {
  bounced_perc?: InputMaybe<Scalars['Float']['input']>;
  clicked_perc?: InputMaybe<Scalars['Float']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  custom_task_note?: InputMaybe<Scalars['String']['input']>;
  delay_amount?: InputMaybe<Scalars['Int']['input']>;
  delivered_perc?: InputMaybe<Scalars['Float']['input']>;
  EmailItem?: InputMaybe<EmailItemCreateNestedManyWithoutStep_ActionInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  opened_perc?: InputMaybe<Scalars['Float']['input']>;
  Organization?: InputMaybe<OrganizationCreateNestedOneWithoutStepActionsInput>;
  replied_perc?: InputMaybe<Scalars['Float']['input']>;
  scheduled_event_behavior?: InputMaybe<SequenceStepScheduledEventBehavior>;
  SMSItem?: InputMaybe<SmsItemCreateNestedManyWithoutStep_ActionInput>;
  step: SequenceStepCreateNestedOneWithoutActionsInput;
  task?: InputMaybe<SequenceTask>;
  template?: InputMaybe<TemplateCreateNestedOneWithoutStepActionInput>;
  unsubscribed_perc?: InputMaybe<Scalars['Float']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type StepActionCreateWithoutStepInput = {
  bounced_perc?: InputMaybe<Scalars['Float']['input']>;
  clicked_perc?: InputMaybe<Scalars['Float']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  custom_task_note?: InputMaybe<Scalars['String']['input']>;
  delay_amount?: InputMaybe<Scalars['Int']['input']>;
  delivered_perc?: InputMaybe<Scalars['Float']['input']>;
  EmailItem?: InputMaybe<EmailItemCreateNestedManyWithoutStep_ActionInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  opened_perc?: InputMaybe<Scalars['Float']['input']>;
  Organization?: InputMaybe<OrganizationCreateNestedOneWithoutStepActionsInput>;
  replied_perc?: InputMaybe<Scalars['Float']['input']>;
  scheduled_event_behavior?: InputMaybe<SequenceStepScheduledEventBehavior>;
  SMSItem?: InputMaybe<SmsItemCreateNestedManyWithoutStep_ActionInput>;
  StepActionLog?: InputMaybe<StepActionLogCreateNestedManyWithoutStepActionInput>;
  task?: InputMaybe<SequenceTask>;
  template?: InputMaybe<TemplateCreateNestedOneWithoutStepActionInput>;
  unsubscribed_perc?: InputMaybe<Scalars['Float']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type StepActionCreateWithoutTemplateInput = {
  bounced_perc?: InputMaybe<Scalars['Float']['input']>;
  clicked_perc?: InputMaybe<Scalars['Float']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  custom_task_note?: InputMaybe<Scalars['String']['input']>;
  delay_amount?: InputMaybe<Scalars['Int']['input']>;
  delivered_perc?: InputMaybe<Scalars['Float']['input']>;
  EmailItem?: InputMaybe<EmailItemCreateNestedManyWithoutStep_ActionInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  opened_perc?: InputMaybe<Scalars['Float']['input']>;
  Organization?: InputMaybe<OrganizationCreateNestedOneWithoutStepActionsInput>;
  replied_perc?: InputMaybe<Scalars['Float']['input']>;
  scheduled_event_behavior?: InputMaybe<SequenceStepScheduledEventBehavior>;
  SMSItem?: InputMaybe<SmsItemCreateNestedManyWithoutStep_ActionInput>;
  step: SequenceStepCreateNestedOneWithoutActionsInput;
  StepActionLog?: InputMaybe<StepActionLogCreateNestedManyWithoutStepActionInput>;
  task?: InputMaybe<SequenceTask>;
  unsubscribed_perc?: InputMaybe<Scalars['Float']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type StepActionListRelationFilter = {
  every?: InputMaybe<StepActionWhereInput>;
  none?: InputMaybe<StepActionWhereInput>;
  some?: InputMaybe<StepActionWhereInput>;
};

export type StepActionLogCreateManyLeadActivityInput = {
  action_completed?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  end_time?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  lead_id: Scalars['String']['input'];
  lead_intent_id?: InputMaybe<Scalars['String']['input']>;
  lead_phase_on_task_completed?: InputMaybe<Phase>;
  nextstep?: InputMaybe<Scalars['Boolean']['input']>;
  organization_id?: InputMaybe<Scalars['String']['input']>;
  reason?: InputMaybe<Scalars['String']['input']>;
  remove?: InputMaybe<Scalars['Boolean']['input']>;
  removed_due_to_scheduled_event?: InputMaybe<Scalars['DateTime']['input']>;
  replaced_by_scheduled_event?: InputMaybe<Scalars['DateTime']['input']>;
  sequence_cycle_num?: InputMaybe<Scalars['Int']['input']>;
  sequence_id: Scalars['String']['input'];
  site_id?: InputMaybe<Scalars['String']['input']>;
  snooze?: InputMaybe<Scalars['Boolean']['input']>;
  snoozed_due_to_scheduled_event?: InputMaybe<Scalars['Int']['input']>;
  start_time?: InputMaybe<Scalars['DateTime']['input']>;
  step_action_id?: InputMaybe<Scalars['String']['input']>;
  step_id?: InputMaybe<Scalars['String']['input']>;
  task?: InputMaybe<SequenceTask>;
  team_id?: InputMaybe<Scalars['String']['input']>;
  time_to_complete?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user_id: Scalars['String']['input'];
};

export type StepActionLogCreateManyLeadActivityInputEnvelope = {
  data?: InputMaybe<Array<StepActionLogCreateManyLeadActivityInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type StepActionLogCreateManyLeadInput = {
  action_completed?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  end_time?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  lead_activity_id?: InputMaybe<Scalars['String']['input']>;
  lead_intent_id?: InputMaybe<Scalars['String']['input']>;
  lead_phase_on_task_completed?: InputMaybe<Phase>;
  nextstep?: InputMaybe<Scalars['Boolean']['input']>;
  organization_id?: InputMaybe<Scalars['String']['input']>;
  reason?: InputMaybe<Scalars['String']['input']>;
  remove?: InputMaybe<Scalars['Boolean']['input']>;
  removed_due_to_scheduled_event?: InputMaybe<Scalars['DateTime']['input']>;
  replaced_by_scheduled_event?: InputMaybe<Scalars['DateTime']['input']>;
  sequence_cycle_num?: InputMaybe<Scalars['Int']['input']>;
  sequence_id: Scalars['String']['input'];
  site_id?: InputMaybe<Scalars['String']['input']>;
  snooze?: InputMaybe<Scalars['Boolean']['input']>;
  snoozed_due_to_scheduled_event?: InputMaybe<Scalars['Int']['input']>;
  start_time?: InputMaybe<Scalars['DateTime']['input']>;
  step_action_id?: InputMaybe<Scalars['String']['input']>;
  step_id?: InputMaybe<Scalars['String']['input']>;
  task?: InputMaybe<SequenceTask>;
  team_id?: InputMaybe<Scalars['String']['input']>;
  time_to_complete?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user_id: Scalars['String']['input'];
};

export type StepActionLogCreateManyLeadInputEnvelope = {
  data?: InputMaybe<Array<StepActionLogCreateManyLeadInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type StepActionLogCreateManyOrganizationInput = {
  action_completed?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  end_time?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  lead_activity_id?: InputMaybe<Scalars['String']['input']>;
  lead_id: Scalars['String']['input'];
  lead_intent_id?: InputMaybe<Scalars['String']['input']>;
  lead_phase_on_task_completed?: InputMaybe<Phase>;
  nextstep?: InputMaybe<Scalars['Boolean']['input']>;
  reason?: InputMaybe<Scalars['String']['input']>;
  remove?: InputMaybe<Scalars['Boolean']['input']>;
  removed_due_to_scheduled_event?: InputMaybe<Scalars['DateTime']['input']>;
  replaced_by_scheduled_event?: InputMaybe<Scalars['DateTime']['input']>;
  sequence_cycle_num?: InputMaybe<Scalars['Int']['input']>;
  sequence_id: Scalars['String']['input'];
  site_id?: InputMaybe<Scalars['String']['input']>;
  snooze?: InputMaybe<Scalars['Boolean']['input']>;
  snoozed_due_to_scheduled_event?: InputMaybe<Scalars['Int']['input']>;
  start_time?: InputMaybe<Scalars['DateTime']['input']>;
  step_action_id?: InputMaybe<Scalars['String']['input']>;
  step_id?: InputMaybe<Scalars['String']['input']>;
  task?: InputMaybe<SequenceTask>;
  team_id?: InputMaybe<Scalars['String']['input']>;
  time_to_complete?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user_id: Scalars['String']['input'];
};

export type StepActionLogCreateManyOrganizationInputEnvelope = {
  data?: InputMaybe<Array<StepActionLogCreateManyOrganizationInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type StepActionLogCreateManyRepInput = {
  action_completed?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  end_time?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  lead_activity_id?: InputMaybe<Scalars['String']['input']>;
  lead_id: Scalars['String']['input'];
  lead_intent_id?: InputMaybe<Scalars['String']['input']>;
  lead_phase_on_task_completed?: InputMaybe<Phase>;
  nextstep?: InputMaybe<Scalars['Boolean']['input']>;
  organization_id?: InputMaybe<Scalars['String']['input']>;
  reason?: InputMaybe<Scalars['String']['input']>;
  remove?: InputMaybe<Scalars['Boolean']['input']>;
  removed_due_to_scheduled_event?: InputMaybe<Scalars['DateTime']['input']>;
  replaced_by_scheduled_event?: InputMaybe<Scalars['DateTime']['input']>;
  sequence_cycle_num?: InputMaybe<Scalars['Int']['input']>;
  sequence_id: Scalars['String']['input'];
  site_id?: InputMaybe<Scalars['String']['input']>;
  snooze?: InputMaybe<Scalars['Boolean']['input']>;
  snoozed_due_to_scheduled_event?: InputMaybe<Scalars['Int']['input']>;
  start_time?: InputMaybe<Scalars['DateTime']['input']>;
  step_action_id?: InputMaybe<Scalars['String']['input']>;
  step_id?: InputMaybe<Scalars['String']['input']>;
  task?: InputMaybe<SequenceTask>;
  team_id?: InputMaybe<Scalars['String']['input']>;
  time_to_complete?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type StepActionLogCreateManyRepInputEnvelope = {
  data?: InputMaybe<Array<StepActionLogCreateManyRepInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type StepActionLogCreateManySequenceInput = {
  action_completed?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  end_time?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  lead_activity_id?: InputMaybe<Scalars['String']['input']>;
  lead_id: Scalars['String']['input'];
  lead_intent_id?: InputMaybe<Scalars['String']['input']>;
  lead_phase_on_task_completed?: InputMaybe<Phase>;
  nextstep?: InputMaybe<Scalars['Boolean']['input']>;
  organization_id?: InputMaybe<Scalars['String']['input']>;
  reason?: InputMaybe<Scalars['String']['input']>;
  remove?: InputMaybe<Scalars['Boolean']['input']>;
  removed_due_to_scheduled_event?: InputMaybe<Scalars['DateTime']['input']>;
  replaced_by_scheduled_event?: InputMaybe<Scalars['DateTime']['input']>;
  sequence_cycle_num?: InputMaybe<Scalars['Int']['input']>;
  site_id?: InputMaybe<Scalars['String']['input']>;
  snooze?: InputMaybe<Scalars['Boolean']['input']>;
  snoozed_due_to_scheduled_event?: InputMaybe<Scalars['Int']['input']>;
  start_time?: InputMaybe<Scalars['DateTime']['input']>;
  step_action_id?: InputMaybe<Scalars['String']['input']>;
  step_id?: InputMaybe<Scalars['String']['input']>;
  task?: InputMaybe<SequenceTask>;
  team_id?: InputMaybe<Scalars['String']['input']>;
  time_to_complete?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user_id: Scalars['String']['input'];
};

export type StepActionLogCreateManySequenceInputEnvelope = {
  data?: InputMaybe<Array<StepActionLogCreateManySequenceInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type StepActionLogCreateManySiteInput = {
  action_completed?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  end_time?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  lead_activity_id?: InputMaybe<Scalars['String']['input']>;
  lead_id: Scalars['String']['input'];
  lead_intent_id?: InputMaybe<Scalars['String']['input']>;
  lead_phase_on_task_completed?: InputMaybe<Phase>;
  nextstep?: InputMaybe<Scalars['Boolean']['input']>;
  organization_id?: InputMaybe<Scalars['String']['input']>;
  reason?: InputMaybe<Scalars['String']['input']>;
  remove?: InputMaybe<Scalars['Boolean']['input']>;
  removed_due_to_scheduled_event?: InputMaybe<Scalars['DateTime']['input']>;
  replaced_by_scheduled_event?: InputMaybe<Scalars['DateTime']['input']>;
  sequence_cycle_num?: InputMaybe<Scalars['Int']['input']>;
  sequence_id: Scalars['String']['input'];
  snooze?: InputMaybe<Scalars['Boolean']['input']>;
  snoozed_due_to_scheduled_event?: InputMaybe<Scalars['Int']['input']>;
  start_time?: InputMaybe<Scalars['DateTime']['input']>;
  step_action_id?: InputMaybe<Scalars['String']['input']>;
  step_id?: InputMaybe<Scalars['String']['input']>;
  task?: InputMaybe<SequenceTask>;
  team_id?: InputMaybe<Scalars['String']['input']>;
  time_to_complete?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user_id: Scalars['String']['input'];
};

export type StepActionLogCreateManySiteInputEnvelope = {
  data?: InputMaybe<Array<StepActionLogCreateManySiteInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type StepActionLogCreateManyStepActionInput = {
  action_completed?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  end_time?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  lead_activity_id?: InputMaybe<Scalars['String']['input']>;
  lead_id: Scalars['String']['input'];
  lead_intent_id?: InputMaybe<Scalars['String']['input']>;
  lead_phase_on_task_completed?: InputMaybe<Phase>;
  nextstep?: InputMaybe<Scalars['Boolean']['input']>;
  organization_id?: InputMaybe<Scalars['String']['input']>;
  reason?: InputMaybe<Scalars['String']['input']>;
  remove?: InputMaybe<Scalars['Boolean']['input']>;
  removed_due_to_scheduled_event?: InputMaybe<Scalars['DateTime']['input']>;
  replaced_by_scheduled_event?: InputMaybe<Scalars['DateTime']['input']>;
  sequence_cycle_num?: InputMaybe<Scalars['Int']['input']>;
  sequence_id: Scalars['String']['input'];
  site_id?: InputMaybe<Scalars['String']['input']>;
  snooze?: InputMaybe<Scalars['Boolean']['input']>;
  snoozed_due_to_scheduled_event?: InputMaybe<Scalars['Int']['input']>;
  start_time?: InputMaybe<Scalars['DateTime']['input']>;
  step_id?: InputMaybe<Scalars['String']['input']>;
  task?: InputMaybe<SequenceTask>;
  team_id?: InputMaybe<Scalars['String']['input']>;
  time_to_complete?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user_id: Scalars['String']['input'];
};

export type StepActionLogCreateManyStepActionInputEnvelope = {
  data?: InputMaybe<Array<StepActionLogCreateManyStepActionInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type StepActionLogCreateManyStepInput = {
  action_completed?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  end_time?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  lead_activity_id?: InputMaybe<Scalars['String']['input']>;
  lead_id: Scalars['String']['input'];
  lead_intent_id?: InputMaybe<Scalars['String']['input']>;
  lead_phase_on_task_completed?: InputMaybe<Phase>;
  nextstep?: InputMaybe<Scalars['Boolean']['input']>;
  organization_id?: InputMaybe<Scalars['String']['input']>;
  reason?: InputMaybe<Scalars['String']['input']>;
  remove?: InputMaybe<Scalars['Boolean']['input']>;
  removed_due_to_scheduled_event?: InputMaybe<Scalars['DateTime']['input']>;
  replaced_by_scheduled_event?: InputMaybe<Scalars['DateTime']['input']>;
  sequence_cycle_num?: InputMaybe<Scalars['Int']['input']>;
  sequence_id: Scalars['String']['input'];
  site_id?: InputMaybe<Scalars['String']['input']>;
  snooze?: InputMaybe<Scalars['Boolean']['input']>;
  snoozed_due_to_scheduled_event?: InputMaybe<Scalars['Int']['input']>;
  start_time?: InputMaybe<Scalars['DateTime']['input']>;
  step_action_id?: InputMaybe<Scalars['String']['input']>;
  task?: InputMaybe<SequenceTask>;
  team_id?: InputMaybe<Scalars['String']['input']>;
  time_to_complete?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user_id: Scalars['String']['input'];
};

export type StepActionLogCreateManyStepInputEnvelope = {
  data?: InputMaybe<Array<StepActionLogCreateManyStepInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type StepActionLogCreateManyTeamInput = {
  action_completed?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  end_time?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  lead_activity_id?: InputMaybe<Scalars['String']['input']>;
  lead_id: Scalars['String']['input'];
  lead_intent_id?: InputMaybe<Scalars['String']['input']>;
  lead_phase_on_task_completed?: InputMaybe<Phase>;
  nextstep?: InputMaybe<Scalars['Boolean']['input']>;
  organization_id?: InputMaybe<Scalars['String']['input']>;
  reason?: InputMaybe<Scalars['String']['input']>;
  remove?: InputMaybe<Scalars['Boolean']['input']>;
  removed_due_to_scheduled_event?: InputMaybe<Scalars['DateTime']['input']>;
  replaced_by_scheduled_event?: InputMaybe<Scalars['DateTime']['input']>;
  sequence_cycle_num?: InputMaybe<Scalars['Int']['input']>;
  sequence_id: Scalars['String']['input'];
  site_id?: InputMaybe<Scalars['String']['input']>;
  snooze?: InputMaybe<Scalars['Boolean']['input']>;
  snoozed_due_to_scheduled_event?: InputMaybe<Scalars['Int']['input']>;
  start_time?: InputMaybe<Scalars['DateTime']['input']>;
  step_action_id?: InputMaybe<Scalars['String']['input']>;
  step_id?: InputMaybe<Scalars['String']['input']>;
  task?: InputMaybe<SequenceTask>;
  time_to_complete?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user_id: Scalars['String']['input'];
};

export type StepActionLogCreateManyTeamInputEnvelope = {
  data?: InputMaybe<Array<StepActionLogCreateManyTeamInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type StepActionLogCreateNestedManyWithoutLeadActivityInput = {
  connect?: InputMaybe<Array<StepActionLogWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StepActionLogCreateOrConnectWithoutLeadActivityInput>>;
  create?: InputMaybe<Array<StepActionLogCreateWithoutLeadActivityInput>>;
  createMany?: InputMaybe<StepActionLogCreateManyLeadActivityInputEnvelope>;
};

export type StepActionLogCreateNestedManyWithoutLeadInput = {
  connect?: InputMaybe<Array<StepActionLogWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StepActionLogCreateOrConnectWithoutLeadInput>>;
  create?: InputMaybe<Array<StepActionLogCreateWithoutLeadInput>>;
  createMany?: InputMaybe<StepActionLogCreateManyLeadInputEnvelope>;
};

export type StepActionLogCreateNestedManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<StepActionLogWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StepActionLogCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<StepActionLogCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<StepActionLogCreateManyOrganizationInputEnvelope>;
};

export type StepActionLogCreateNestedManyWithoutRepInput = {
  connect?: InputMaybe<Array<StepActionLogWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StepActionLogCreateOrConnectWithoutRepInput>>;
  create?: InputMaybe<Array<StepActionLogCreateWithoutRepInput>>;
  createMany?: InputMaybe<StepActionLogCreateManyRepInputEnvelope>;
};

export type StepActionLogCreateNestedManyWithoutSequenceInput = {
  connect?: InputMaybe<Array<StepActionLogWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StepActionLogCreateOrConnectWithoutSequenceInput>>;
  create?: InputMaybe<Array<StepActionLogCreateWithoutSequenceInput>>;
  createMany?: InputMaybe<StepActionLogCreateManySequenceInputEnvelope>;
};

export type StepActionLogCreateNestedManyWithoutSiteInput = {
  connect?: InputMaybe<Array<StepActionLogWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StepActionLogCreateOrConnectWithoutSiteInput>>;
  create?: InputMaybe<Array<StepActionLogCreateWithoutSiteInput>>;
  createMany?: InputMaybe<StepActionLogCreateManySiteInputEnvelope>;
};

export type StepActionLogCreateNestedManyWithoutStepActionInput = {
  connect?: InputMaybe<Array<StepActionLogWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StepActionLogCreateOrConnectWithoutStepActionInput>>;
  create?: InputMaybe<Array<StepActionLogCreateWithoutStepActionInput>>;
  createMany?: InputMaybe<StepActionLogCreateManyStepActionInputEnvelope>;
};

export type StepActionLogCreateNestedManyWithoutStepInput = {
  connect?: InputMaybe<Array<StepActionLogWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StepActionLogCreateOrConnectWithoutStepInput>>;
  create?: InputMaybe<Array<StepActionLogCreateWithoutStepInput>>;
  createMany?: InputMaybe<StepActionLogCreateManyStepInputEnvelope>;
};

export type StepActionLogCreateNestedManyWithoutTeamInput = {
  connect?: InputMaybe<Array<StepActionLogWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StepActionLogCreateOrConnectWithoutTeamInput>>;
  create?: InputMaybe<Array<StepActionLogCreateWithoutTeamInput>>;
  createMany?: InputMaybe<StepActionLogCreateManyTeamInputEnvelope>;
};

export type StepActionLogCreateNestedOneWithoutLeadIntentInput = {
  connect?: InputMaybe<StepActionLogWhereUniqueInput>;
  connectOrCreate?: InputMaybe<StepActionLogCreateOrConnectWithoutLeadIntentInput>;
  create?: InputMaybe<StepActionLogCreateWithoutLeadIntentInput>;
};

export type StepActionLogCreateNestedOneWithoutSnooze_LogsInput = {
  connect?: InputMaybe<StepActionLogWhereUniqueInput>;
  connectOrCreate?: InputMaybe<StepActionLogCreateOrConnectWithoutSnooze_LogsInput>;
  create?: InputMaybe<StepActionLogCreateWithoutSnooze_LogsInput>;
};

export type StepActionLogCreateOrConnectWithoutLeadActivityInput = {
  create: StepActionLogCreateWithoutLeadActivityInput;
  where: StepActionLogWhereUniqueInput;
};

export type StepActionLogCreateOrConnectWithoutLeadInput = {
  create: StepActionLogCreateWithoutLeadInput;
  where: StepActionLogWhereUniqueInput;
};

export type StepActionLogCreateOrConnectWithoutLeadIntentInput = {
  create: StepActionLogCreateWithoutLeadIntentInput;
  where: StepActionLogWhereUniqueInput;
};

export type StepActionLogCreateOrConnectWithoutOrganizationInput = {
  create: StepActionLogCreateWithoutOrganizationInput;
  where: StepActionLogWhereUniqueInput;
};

export type StepActionLogCreateOrConnectWithoutRepInput = {
  create: StepActionLogCreateWithoutRepInput;
  where: StepActionLogWhereUniqueInput;
};

export type StepActionLogCreateOrConnectWithoutSequenceInput = {
  create: StepActionLogCreateWithoutSequenceInput;
  where: StepActionLogWhereUniqueInput;
};

export type StepActionLogCreateOrConnectWithoutSiteInput = {
  create: StepActionLogCreateWithoutSiteInput;
  where: StepActionLogWhereUniqueInput;
};

export type StepActionLogCreateOrConnectWithoutSnooze_LogsInput = {
  create: StepActionLogCreateWithoutSnooze_LogsInput;
  where: StepActionLogWhereUniqueInput;
};

export type StepActionLogCreateOrConnectWithoutStepActionInput = {
  create: StepActionLogCreateWithoutStepActionInput;
  where: StepActionLogWhereUniqueInput;
};

export type StepActionLogCreateOrConnectWithoutStepInput = {
  create: StepActionLogCreateWithoutStepInput;
  where: StepActionLogWhereUniqueInput;
};

export type StepActionLogCreateOrConnectWithoutTeamInput = {
  create: StepActionLogCreateWithoutTeamInput;
  where: StepActionLogWhereUniqueInput;
};

export type StepActionLogCreateWithoutLeadActivityInput = {
  action_completed?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  end_time?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  lead: LeadCreateNestedOneWithoutSequenceActionLogInput;
  lead_phase_on_task_completed?: InputMaybe<Phase>;
  leadIntent?: InputMaybe<LeadIntentCreateNestedOneWithoutStepActionLogInput>;
  nextstep?: InputMaybe<Scalars['Boolean']['input']>;
  Organization?: InputMaybe<OrganizationCreateNestedOneWithoutStepActionLogsInput>;
  reason?: InputMaybe<Scalars['String']['input']>;
  remove?: InputMaybe<Scalars['Boolean']['input']>;
  removed_due_to_scheduled_event?: InputMaybe<Scalars['DateTime']['input']>;
  rep: UserCreateNestedOneWithoutSequenceActionLogInput;
  replaced_by_scheduled_event?: InputMaybe<Scalars['DateTime']['input']>;
  sequence: SequenceCreateNestedOneWithoutStepActionLogInput;
  sequence_cycle_num?: InputMaybe<Scalars['Int']['input']>;
  site?: InputMaybe<SiteCreateNestedOneWithoutStepActionLogInput>;
  snooze?: InputMaybe<Scalars['Boolean']['input']>;
  snooze_logs?: InputMaybe<SnoozeTrackingCreateNestedManyWithoutStepActionLogInput>;
  snoozed_due_to_scheduled_event?: InputMaybe<Scalars['Int']['input']>;
  start_time?: InputMaybe<Scalars['DateTime']['input']>;
  step?: InputMaybe<SequenceStepCreateNestedOneWithoutStepActionLogInput>;
  stepAction?: InputMaybe<StepActionCreateNestedOneWithoutStepActionLogInput>;
  task?: InputMaybe<SequenceTask>;
  team?: InputMaybe<TeamCreateNestedOneWithoutStepActionLogInput>;
  time_to_complete?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type StepActionLogCreateWithoutLeadInput = {
  action_completed?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  end_time?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  lead_phase_on_task_completed?: InputMaybe<Phase>;
  leadActivity?: InputMaybe<LeadActivityCreateNestedOneWithoutStepActionLogInput>;
  leadIntent?: InputMaybe<LeadIntentCreateNestedOneWithoutStepActionLogInput>;
  nextstep?: InputMaybe<Scalars['Boolean']['input']>;
  Organization?: InputMaybe<OrganizationCreateNestedOneWithoutStepActionLogsInput>;
  reason?: InputMaybe<Scalars['String']['input']>;
  remove?: InputMaybe<Scalars['Boolean']['input']>;
  removed_due_to_scheduled_event?: InputMaybe<Scalars['DateTime']['input']>;
  rep: UserCreateNestedOneWithoutSequenceActionLogInput;
  replaced_by_scheduled_event?: InputMaybe<Scalars['DateTime']['input']>;
  sequence: SequenceCreateNestedOneWithoutStepActionLogInput;
  sequence_cycle_num?: InputMaybe<Scalars['Int']['input']>;
  site?: InputMaybe<SiteCreateNestedOneWithoutStepActionLogInput>;
  snooze?: InputMaybe<Scalars['Boolean']['input']>;
  snooze_logs?: InputMaybe<SnoozeTrackingCreateNestedManyWithoutStepActionLogInput>;
  snoozed_due_to_scheduled_event?: InputMaybe<Scalars['Int']['input']>;
  start_time?: InputMaybe<Scalars['DateTime']['input']>;
  step?: InputMaybe<SequenceStepCreateNestedOneWithoutStepActionLogInput>;
  stepAction?: InputMaybe<StepActionCreateNestedOneWithoutStepActionLogInput>;
  task?: InputMaybe<SequenceTask>;
  team?: InputMaybe<TeamCreateNestedOneWithoutStepActionLogInput>;
  time_to_complete?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type StepActionLogCreateWithoutLeadIntentInput = {
  action_completed?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  end_time?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  lead: LeadCreateNestedOneWithoutSequenceActionLogInput;
  lead_phase_on_task_completed?: InputMaybe<Phase>;
  leadActivity?: InputMaybe<LeadActivityCreateNestedOneWithoutStepActionLogInput>;
  nextstep?: InputMaybe<Scalars['Boolean']['input']>;
  Organization?: InputMaybe<OrganizationCreateNestedOneWithoutStepActionLogsInput>;
  reason?: InputMaybe<Scalars['String']['input']>;
  remove?: InputMaybe<Scalars['Boolean']['input']>;
  removed_due_to_scheduled_event?: InputMaybe<Scalars['DateTime']['input']>;
  rep: UserCreateNestedOneWithoutSequenceActionLogInput;
  replaced_by_scheduled_event?: InputMaybe<Scalars['DateTime']['input']>;
  sequence: SequenceCreateNestedOneWithoutStepActionLogInput;
  sequence_cycle_num?: InputMaybe<Scalars['Int']['input']>;
  site?: InputMaybe<SiteCreateNestedOneWithoutStepActionLogInput>;
  snooze?: InputMaybe<Scalars['Boolean']['input']>;
  snooze_logs?: InputMaybe<SnoozeTrackingCreateNestedManyWithoutStepActionLogInput>;
  snoozed_due_to_scheduled_event?: InputMaybe<Scalars['Int']['input']>;
  start_time?: InputMaybe<Scalars['DateTime']['input']>;
  step?: InputMaybe<SequenceStepCreateNestedOneWithoutStepActionLogInput>;
  stepAction?: InputMaybe<StepActionCreateNestedOneWithoutStepActionLogInput>;
  task?: InputMaybe<SequenceTask>;
  team?: InputMaybe<TeamCreateNestedOneWithoutStepActionLogInput>;
  time_to_complete?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type StepActionLogCreateWithoutOrganizationInput = {
  action_completed?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  end_time?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  lead: LeadCreateNestedOneWithoutSequenceActionLogInput;
  lead_phase_on_task_completed?: InputMaybe<Phase>;
  leadActivity?: InputMaybe<LeadActivityCreateNestedOneWithoutStepActionLogInput>;
  leadIntent?: InputMaybe<LeadIntentCreateNestedOneWithoutStepActionLogInput>;
  nextstep?: InputMaybe<Scalars['Boolean']['input']>;
  reason?: InputMaybe<Scalars['String']['input']>;
  remove?: InputMaybe<Scalars['Boolean']['input']>;
  removed_due_to_scheduled_event?: InputMaybe<Scalars['DateTime']['input']>;
  rep: UserCreateNestedOneWithoutSequenceActionLogInput;
  replaced_by_scheduled_event?: InputMaybe<Scalars['DateTime']['input']>;
  sequence: SequenceCreateNestedOneWithoutStepActionLogInput;
  sequence_cycle_num?: InputMaybe<Scalars['Int']['input']>;
  site?: InputMaybe<SiteCreateNestedOneWithoutStepActionLogInput>;
  snooze?: InputMaybe<Scalars['Boolean']['input']>;
  snooze_logs?: InputMaybe<SnoozeTrackingCreateNestedManyWithoutStepActionLogInput>;
  snoozed_due_to_scheduled_event?: InputMaybe<Scalars['Int']['input']>;
  start_time?: InputMaybe<Scalars['DateTime']['input']>;
  step?: InputMaybe<SequenceStepCreateNestedOneWithoutStepActionLogInput>;
  stepAction?: InputMaybe<StepActionCreateNestedOneWithoutStepActionLogInput>;
  task?: InputMaybe<SequenceTask>;
  team?: InputMaybe<TeamCreateNestedOneWithoutStepActionLogInput>;
  time_to_complete?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type StepActionLogCreateWithoutRepInput = {
  action_completed?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  end_time?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  lead: LeadCreateNestedOneWithoutSequenceActionLogInput;
  lead_phase_on_task_completed?: InputMaybe<Phase>;
  leadActivity?: InputMaybe<LeadActivityCreateNestedOneWithoutStepActionLogInput>;
  leadIntent?: InputMaybe<LeadIntentCreateNestedOneWithoutStepActionLogInput>;
  nextstep?: InputMaybe<Scalars['Boolean']['input']>;
  Organization?: InputMaybe<OrganizationCreateNestedOneWithoutStepActionLogsInput>;
  reason?: InputMaybe<Scalars['String']['input']>;
  remove?: InputMaybe<Scalars['Boolean']['input']>;
  removed_due_to_scheduled_event?: InputMaybe<Scalars['DateTime']['input']>;
  replaced_by_scheduled_event?: InputMaybe<Scalars['DateTime']['input']>;
  sequence: SequenceCreateNestedOneWithoutStepActionLogInput;
  sequence_cycle_num?: InputMaybe<Scalars['Int']['input']>;
  site?: InputMaybe<SiteCreateNestedOneWithoutStepActionLogInput>;
  snooze?: InputMaybe<Scalars['Boolean']['input']>;
  snooze_logs?: InputMaybe<SnoozeTrackingCreateNestedManyWithoutStepActionLogInput>;
  snoozed_due_to_scheduled_event?: InputMaybe<Scalars['Int']['input']>;
  start_time?: InputMaybe<Scalars['DateTime']['input']>;
  step?: InputMaybe<SequenceStepCreateNestedOneWithoutStepActionLogInput>;
  stepAction?: InputMaybe<StepActionCreateNestedOneWithoutStepActionLogInput>;
  task?: InputMaybe<SequenceTask>;
  team?: InputMaybe<TeamCreateNestedOneWithoutStepActionLogInput>;
  time_to_complete?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type StepActionLogCreateWithoutSequenceInput = {
  action_completed?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  end_time?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  lead: LeadCreateNestedOneWithoutSequenceActionLogInput;
  lead_phase_on_task_completed?: InputMaybe<Phase>;
  leadActivity?: InputMaybe<LeadActivityCreateNestedOneWithoutStepActionLogInput>;
  leadIntent?: InputMaybe<LeadIntentCreateNestedOneWithoutStepActionLogInput>;
  nextstep?: InputMaybe<Scalars['Boolean']['input']>;
  Organization?: InputMaybe<OrganizationCreateNestedOneWithoutStepActionLogsInput>;
  reason?: InputMaybe<Scalars['String']['input']>;
  remove?: InputMaybe<Scalars['Boolean']['input']>;
  removed_due_to_scheduled_event?: InputMaybe<Scalars['DateTime']['input']>;
  rep: UserCreateNestedOneWithoutSequenceActionLogInput;
  replaced_by_scheduled_event?: InputMaybe<Scalars['DateTime']['input']>;
  sequence_cycle_num?: InputMaybe<Scalars['Int']['input']>;
  site?: InputMaybe<SiteCreateNestedOneWithoutStepActionLogInput>;
  snooze?: InputMaybe<Scalars['Boolean']['input']>;
  snooze_logs?: InputMaybe<SnoozeTrackingCreateNestedManyWithoutStepActionLogInput>;
  snoozed_due_to_scheduled_event?: InputMaybe<Scalars['Int']['input']>;
  start_time?: InputMaybe<Scalars['DateTime']['input']>;
  step?: InputMaybe<SequenceStepCreateNestedOneWithoutStepActionLogInput>;
  stepAction?: InputMaybe<StepActionCreateNestedOneWithoutStepActionLogInput>;
  task?: InputMaybe<SequenceTask>;
  team?: InputMaybe<TeamCreateNestedOneWithoutStepActionLogInput>;
  time_to_complete?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type StepActionLogCreateWithoutSiteInput = {
  action_completed?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  end_time?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  lead: LeadCreateNestedOneWithoutSequenceActionLogInput;
  lead_phase_on_task_completed?: InputMaybe<Phase>;
  leadActivity?: InputMaybe<LeadActivityCreateNestedOneWithoutStepActionLogInput>;
  leadIntent?: InputMaybe<LeadIntentCreateNestedOneWithoutStepActionLogInput>;
  nextstep?: InputMaybe<Scalars['Boolean']['input']>;
  Organization?: InputMaybe<OrganizationCreateNestedOneWithoutStepActionLogsInput>;
  reason?: InputMaybe<Scalars['String']['input']>;
  remove?: InputMaybe<Scalars['Boolean']['input']>;
  removed_due_to_scheduled_event?: InputMaybe<Scalars['DateTime']['input']>;
  rep: UserCreateNestedOneWithoutSequenceActionLogInput;
  replaced_by_scheduled_event?: InputMaybe<Scalars['DateTime']['input']>;
  sequence: SequenceCreateNestedOneWithoutStepActionLogInput;
  sequence_cycle_num?: InputMaybe<Scalars['Int']['input']>;
  snooze?: InputMaybe<Scalars['Boolean']['input']>;
  snooze_logs?: InputMaybe<SnoozeTrackingCreateNestedManyWithoutStepActionLogInput>;
  snoozed_due_to_scheduled_event?: InputMaybe<Scalars['Int']['input']>;
  start_time?: InputMaybe<Scalars['DateTime']['input']>;
  step?: InputMaybe<SequenceStepCreateNestedOneWithoutStepActionLogInput>;
  stepAction?: InputMaybe<StepActionCreateNestedOneWithoutStepActionLogInput>;
  task?: InputMaybe<SequenceTask>;
  team?: InputMaybe<TeamCreateNestedOneWithoutStepActionLogInput>;
  time_to_complete?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type StepActionLogCreateWithoutSnooze_LogsInput = {
  action_completed?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  end_time?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  lead: LeadCreateNestedOneWithoutSequenceActionLogInput;
  lead_phase_on_task_completed?: InputMaybe<Phase>;
  leadActivity?: InputMaybe<LeadActivityCreateNestedOneWithoutStepActionLogInput>;
  leadIntent?: InputMaybe<LeadIntentCreateNestedOneWithoutStepActionLogInput>;
  nextstep?: InputMaybe<Scalars['Boolean']['input']>;
  Organization?: InputMaybe<OrganizationCreateNestedOneWithoutStepActionLogsInput>;
  reason?: InputMaybe<Scalars['String']['input']>;
  remove?: InputMaybe<Scalars['Boolean']['input']>;
  removed_due_to_scheduled_event?: InputMaybe<Scalars['DateTime']['input']>;
  rep: UserCreateNestedOneWithoutSequenceActionLogInput;
  replaced_by_scheduled_event?: InputMaybe<Scalars['DateTime']['input']>;
  sequence: SequenceCreateNestedOneWithoutStepActionLogInput;
  sequence_cycle_num?: InputMaybe<Scalars['Int']['input']>;
  site?: InputMaybe<SiteCreateNestedOneWithoutStepActionLogInput>;
  snooze?: InputMaybe<Scalars['Boolean']['input']>;
  snoozed_due_to_scheduled_event?: InputMaybe<Scalars['Int']['input']>;
  start_time?: InputMaybe<Scalars['DateTime']['input']>;
  step?: InputMaybe<SequenceStepCreateNestedOneWithoutStepActionLogInput>;
  stepAction?: InputMaybe<StepActionCreateNestedOneWithoutStepActionLogInput>;
  task?: InputMaybe<SequenceTask>;
  team?: InputMaybe<TeamCreateNestedOneWithoutStepActionLogInput>;
  time_to_complete?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type StepActionLogCreateWithoutStepActionInput = {
  action_completed?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  end_time?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  lead: LeadCreateNestedOneWithoutSequenceActionLogInput;
  lead_phase_on_task_completed?: InputMaybe<Phase>;
  leadActivity?: InputMaybe<LeadActivityCreateNestedOneWithoutStepActionLogInput>;
  leadIntent?: InputMaybe<LeadIntentCreateNestedOneWithoutStepActionLogInput>;
  nextstep?: InputMaybe<Scalars['Boolean']['input']>;
  Organization?: InputMaybe<OrganizationCreateNestedOneWithoutStepActionLogsInput>;
  reason?: InputMaybe<Scalars['String']['input']>;
  remove?: InputMaybe<Scalars['Boolean']['input']>;
  removed_due_to_scheduled_event?: InputMaybe<Scalars['DateTime']['input']>;
  rep: UserCreateNestedOneWithoutSequenceActionLogInput;
  replaced_by_scheduled_event?: InputMaybe<Scalars['DateTime']['input']>;
  sequence: SequenceCreateNestedOneWithoutStepActionLogInput;
  sequence_cycle_num?: InputMaybe<Scalars['Int']['input']>;
  site?: InputMaybe<SiteCreateNestedOneWithoutStepActionLogInput>;
  snooze?: InputMaybe<Scalars['Boolean']['input']>;
  snooze_logs?: InputMaybe<SnoozeTrackingCreateNestedManyWithoutStepActionLogInput>;
  snoozed_due_to_scheduled_event?: InputMaybe<Scalars['Int']['input']>;
  start_time?: InputMaybe<Scalars['DateTime']['input']>;
  step?: InputMaybe<SequenceStepCreateNestedOneWithoutStepActionLogInput>;
  task?: InputMaybe<SequenceTask>;
  team?: InputMaybe<TeamCreateNestedOneWithoutStepActionLogInput>;
  time_to_complete?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type StepActionLogCreateWithoutStepInput = {
  action_completed?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  end_time?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  lead: LeadCreateNestedOneWithoutSequenceActionLogInput;
  lead_phase_on_task_completed?: InputMaybe<Phase>;
  leadActivity?: InputMaybe<LeadActivityCreateNestedOneWithoutStepActionLogInput>;
  leadIntent?: InputMaybe<LeadIntentCreateNestedOneWithoutStepActionLogInput>;
  nextstep?: InputMaybe<Scalars['Boolean']['input']>;
  Organization?: InputMaybe<OrganizationCreateNestedOneWithoutStepActionLogsInput>;
  reason?: InputMaybe<Scalars['String']['input']>;
  remove?: InputMaybe<Scalars['Boolean']['input']>;
  removed_due_to_scheduled_event?: InputMaybe<Scalars['DateTime']['input']>;
  rep: UserCreateNestedOneWithoutSequenceActionLogInput;
  replaced_by_scheduled_event?: InputMaybe<Scalars['DateTime']['input']>;
  sequence: SequenceCreateNestedOneWithoutStepActionLogInput;
  sequence_cycle_num?: InputMaybe<Scalars['Int']['input']>;
  site?: InputMaybe<SiteCreateNestedOneWithoutStepActionLogInput>;
  snooze?: InputMaybe<Scalars['Boolean']['input']>;
  snooze_logs?: InputMaybe<SnoozeTrackingCreateNestedManyWithoutStepActionLogInput>;
  snoozed_due_to_scheduled_event?: InputMaybe<Scalars['Int']['input']>;
  start_time?: InputMaybe<Scalars['DateTime']['input']>;
  stepAction?: InputMaybe<StepActionCreateNestedOneWithoutStepActionLogInput>;
  task?: InputMaybe<SequenceTask>;
  team?: InputMaybe<TeamCreateNestedOneWithoutStepActionLogInput>;
  time_to_complete?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type StepActionLogCreateWithoutTeamInput = {
  action_completed?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  end_time?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  lead: LeadCreateNestedOneWithoutSequenceActionLogInput;
  lead_phase_on_task_completed?: InputMaybe<Phase>;
  leadActivity?: InputMaybe<LeadActivityCreateNestedOneWithoutStepActionLogInput>;
  leadIntent?: InputMaybe<LeadIntentCreateNestedOneWithoutStepActionLogInput>;
  nextstep?: InputMaybe<Scalars['Boolean']['input']>;
  Organization?: InputMaybe<OrganizationCreateNestedOneWithoutStepActionLogsInput>;
  reason?: InputMaybe<Scalars['String']['input']>;
  remove?: InputMaybe<Scalars['Boolean']['input']>;
  removed_due_to_scheduled_event?: InputMaybe<Scalars['DateTime']['input']>;
  rep: UserCreateNestedOneWithoutSequenceActionLogInput;
  replaced_by_scheduled_event?: InputMaybe<Scalars['DateTime']['input']>;
  sequence: SequenceCreateNestedOneWithoutStepActionLogInput;
  sequence_cycle_num?: InputMaybe<Scalars['Int']['input']>;
  site?: InputMaybe<SiteCreateNestedOneWithoutStepActionLogInput>;
  snooze?: InputMaybe<Scalars['Boolean']['input']>;
  snooze_logs?: InputMaybe<SnoozeTrackingCreateNestedManyWithoutStepActionLogInput>;
  snoozed_due_to_scheduled_event?: InputMaybe<Scalars['Int']['input']>;
  start_time?: InputMaybe<Scalars['DateTime']['input']>;
  step?: InputMaybe<SequenceStepCreateNestedOneWithoutStepActionLogInput>;
  stepAction?: InputMaybe<StepActionCreateNestedOneWithoutStepActionLogInput>;
  task?: InputMaybe<SequenceTask>;
  time_to_complete?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type StepActionLogLead_IdStep_Action_IdSequence_Cycle_NumCompoundUniqueInput = {
  lead_id: Scalars['String']['input'];
  sequence_cycle_num: Scalars['Int']['input'];
  step_action_id: Scalars['String']['input'];
};

export type StepActionLogListRelationFilter = {
  every?: InputMaybe<StepActionLogWhereInput>;
  none?: InputMaybe<StepActionLogWhereInput>;
  some?: InputMaybe<StepActionLogWhereInput>;
};

export type StepActionLogOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
  count?: InputMaybe<SortOrder>;
};

export type StepActionLogOrderByWithRelationInput = {
  action_completed?: InputMaybe<SortOrder>;
  created_at?: InputMaybe<SortOrder>;
  end_time?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  lead?: InputMaybe<LeadOrderByWithRelationInput>;
  lead_activity_id?: InputMaybe<SortOrder>;
  lead_id?: InputMaybe<SortOrder>;
  lead_intent_id?: InputMaybe<SortOrder>;
  lead_phase_on_task_completed?: InputMaybe<SortOrder>;
  leadActivity?: InputMaybe<LeadActivityOrderByWithRelationInput>;
  leadIntent?: InputMaybe<LeadIntentOrderByWithRelationInput>;
  nextstep?: InputMaybe<SortOrder>;
  Organization?: InputMaybe<OrganizationOrderByWithRelationInput>;
  organization_id?: InputMaybe<SortOrder>;
  reason?: InputMaybe<SortOrder>;
  remove?: InputMaybe<SortOrder>;
  removed_due_to_scheduled_event?: InputMaybe<SortOrder>;
  rep?: InputMaybe<UserOrderByWithRelationInput>;
  replaced_by_scheduled_event?: InputMaybe<SortOrder>;
  sequence?: InputMaybe<SequenceOrderByWithRelationInput>;
  sequence_cycle_num?: InputMaybe<SortOrder>;
  sequence_id?: InputMaybe<SortOrder>;
  site?: InputMaybe<SiteOrderByWithRelationInput>;
  site_id?: InputMaybe<SortOrder>;
  snooze?: InputMaybe<SortOrder>;
  snooze_logs?: InputMaybe<SnoozeTrackingOrderByRelationAggregateInput>;
  snoozed_due_to_scheduled_event?: InputMaybe<SortOrder>;
  start_time?: InputMaybe<SortOrder>;
  step?: InputMaybe<SequenceStepOrderByWithRelationInput>;
  step_action_id?: InputMaybe<SortOrder>;
  step_id?: InputMaybe<SortOrder>;
  stepAction?: InputMaybe<StepActionOrderByWithRelationInput>;
  task?: InputMaybe<SortOrder>;
  team?: InputMaybe<TeamOrderByWithRelationInput>;
  team_id?: InputMaybe<SortOrder>;
  time_to_complete?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
  user_id?: InputMaybe<SortOrder>;
};

export type StepActionLogScalarWhereInput = {
  action_completed?: InputMaybe<BoolFilter>;
  AND?: InputMaybe<Array<StepActionLogScalarWhereInput>>;
  created_at?: InputMaybe<DateTimeFilter>;
  end_time?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<StringFilter>;
  lead_activity_id?: InputMaybe<StringNullableFilter>;
  lead_id?: InputMaybe<StringFilter>;
  lead_intent_id?: InputMaybe<StringNullableFilter>;
  lead_phase_on_task_completed?: InputMaybe<EnumPhaseNullableFilter>;
  nextstep?: InputMaybe<BoolFilter>;
  NOT?: InputMaybe<Array<StepActionLogScalarWhereInput>>;
  OR?: InputMaybe<Array<StepActionLogScalarWhereInput>>;
  organization_id?: InputMaybe<StringNullableFilter>;
  reason?: InputMaybe<StringNullableFilter>;
  remove?: InputMaybe<BoolFilter>;
  removed_due_to_scheduled_event?: InputMaybe<DateTimeNullableFilter>;
  replaced_by_scheduled_event?: InputMaybe<DateTimeNullableFilter>;
  sequence_cycle_num?: InputMaybe<IntNullableFilter>;
  sequence_id?: InputMaybe<StringFilter>;
  site_id?: InputMaybe<StringNullableFilter>;
  snooze?: InputMaybe<BoolFilter>;
  snoozed_due_to_scheduled_event?: InputMaybe<IntNullableFilter>;
  start_time?: InputMaybe<DateTimeNullableFilter>;
  step_action_id?: InputMaybe<StringNullableFilter>;
  step_id?: InputMaybe<StringNullableFilter>;
  task?: InputMaybe<EnumSequenceTaskNullableFilter>;
  team_id?: InputMaybe<StringNullableFilter>;
  time_to_complete?: InputMaybe<IntNullableFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
  user_id?: InputMaybe<StringFilter>;
};

export type StepActionLogUpdateManyMutationInput = {
  action_completed?: InputMaybe<BoolFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  end_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lead_phase_on_task_completed?: InputMaybe<NullableEnumPhaseFieldUpdateOperationsInput>;
  nextstep?: InputMaybe<BoolFieldUpdateOperationsInput>;
  reason?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  remove?: InputMaybe<BoolFieldUpdateOperationsInput>;
  removed_due_to_scheduled_event?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  replaced_by_scheduled_event?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  sequence_cycle_num?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  snooze?: InputMaybe<BoolFieldUpdateOperationsInput>;
  snoozed_due_to_scheduled_event?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  start_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  task?: InputMaybe<NullableEnumSequenceTaskFieldUpdateOperationsInput>;
  time_to_complete?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type StepActionLogUpdateManyWithoutLeadActivityInput = {
  connect?: InputMaybe<Array<StepActionLogWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StepActionLogCreateOrConnectWithoutLeadActivityInput>>;
  create?: InputMaybe<Array<StepActionLogCreateWithoutLeadActivityInput>>;
  createMany?: InputMaybe<StepActionLogCreateManyLeadActivityInputEnvelope>;
  delete?: InputMaybe<Array<StepActionLogWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<StepActionLogScalarWhereInput>>;
  disconnect?: InputMaybe<Array<StepActionLogWhereUniqueInput>>;
  set?: InputMaybe<Array<StepActionLogWhereUniqueInput>>;
  update?: InputMaybe<Array<StepActionLogUpdateWithWhereUniqueWithoutLeadActivityInput>>;
  updateMany?: InputMaybe<Array<StepActionLogUpdateManyWithWhereWithoutLeadActivityInput>>;
  upsert?: InputMaybe<Array<StepActionLogUpsertWithWhereUniqueWithoutLeadActivityInput>>;
};

export type StepActionLogUpdateManyWithoutLeadInput = {
  connect?: InputMaybe<Array<StepActionLogWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StepActionLogCreateOrConnectWithoutLeadInput>>;
  create?: InputMaybe<Array<StepActionLogCreateWithoutLeadInput>>;
  createMany?: InputMaybe<StepActionLogCreateManyLeadInputEnvelope>;
  delete?: InputMaybe<Array<StepActionLogWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<StepActionLogScalarWhereInput>>;
  disconnect?: InputMaybe<Array<StepActionLogWhereUniqueInput>>;
  set?: InputMaybe<Array<StepActionLogWhereUniqueInput>>;
  update?: InputMaybe<Array<StepActionLogUpdateWithWhereUniqueWithoutLeadInput>>;
  updateMany?: InputMaybe<Array<StepActionLogUpdateManyWithWhereWithoutLeadInput>>;
  upsert?: InputMaybe<Array<StepActionLogUpsertWithWhereUniqueWithoutLeadInput>>;
};

export type StepActionLogUpdateManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<StepActionLogWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StepActionLogCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<StepActionLogCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<StepActionLogCreateManyOrganizationInputEnvelope>;
  delete?: InputMaybe<Array<StepActionLogWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<StepActionLogScalarWhereInput>>;
  disconnect?: InputMaybe<Array<StepActionLogWhereUniqueInput>>;
  set?: InputMaybe<Array<StepActionLogWhereUniqueInput>>;
  update?: InputMaybe<Array<StepActionLogUpdateWithWhereUniqueWithoutOrganizationInput>>;
  updateMany?: InputMaybe<Array<StepActionLogUpdateManyWithWhereWithoutOrganizationInput>>;
  upsert?: InputMaybe<Array<StepActionLogUpsertWithWhereUniqueWithoutOrganizationInput>>;
};

export type StepActionLogUpdateManyWithoutRepInput = {
  connect?: InputMaybe<Array<StepActionLogWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StepActionLogCreateOrConnectWithoutRepInput>>;
  create?: InputMaybe<Array<StepActionLogCreateWithoutRepInput>>;
  createMany?: InputMaybe<StepActionLogCreateManyRepInputEnvelope>;
  delete?: InputMaybe<Array<StepActionLogWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<StepActionLogScalarWhereInput>>;
  disconnect?: InputMaybe<Array<StepActionLogWhereUniqueInput>>;
  set?: InputMaybe<Array<StepActionLogWhereUniqueInput>>;
  update?: InputMaybe<Array<StepActionLogUpdateWithWhereUniqueWithoutRepInput>>;
  updateMany?: InputMaybe<Array<StepActionLogUpdateManyWithWhereWithoutRepInput>>;
  upsert?: InputMaybe<Array<StepActionLogUpsertWithWhereUniqueWithoutRepInput>>;
};

export type StepActionLogUpdateManyWithoutSequenceInput = {
  connect?: InputMaybe<Array<StepActionLogWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StepActionLogCreateOrConnectWithoutSequenceInput>>;
  create?: InputMaybe<Array<StepActionLogCreateWithoutSequenceInput>>;
  createMany?: InputMaybe<StepActionLogCreateManySequenceInputEnvelope>;
  delete?: InputMaybe<Array<StepActionLogWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<StepActionLogScalarWhereInput>>;
  disconnect?: InputMaybe<Array<StepActionLogWhereUniqueInput>>;
  set?: InputMaybe<Array<StepActionLogWhereUniqueInput>>;
  update?: InputMaybe<Array<StepActionLogUpdateWithWhereUniqueWithoutSequenceInput>>;
  updateMany?: InputMaybe<Array<StepActionLogUpdateManyWithWhereWithoutSequenceInput>>;
  upsert?: InputMaybe<Array<StepActionLogUpsertWithWhereUniqueWithoutSequenceInput>>;
};

export type StepActionLogUpdateManyWithoutSiteInput = {
  connect?: InputMaybe<Array<StepActionLogWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StepActionLogCreateOrConnectWithoutSiteInput>>;
  create?: InputMaybe<Array<StepActionLogCreateWithoutSiteInput>>;
  createMany?: InputMaybe<StepActionLogCreateManySiteInputEnvelope>;
  delete?: InputMaybe<Array<StepActionLogWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<StepActionLogScalarWhereInput>>;
  disconnect?: InputMaybe<Array<StepActionLogWhereUniqueInput>>;
  set?: InputMaybe<Array<StepActionLogWhereUniqueInput>>;
  update?: InputMaybe<Array<StepActionLogUpdateWithWhereUniqueWithoutSiteInput>>;
  updateMany?: InputMaybe<Array<StepActionLogUpdateManyWithWhereWithoutSiteInput>>;
  upsert?: InputMaybe<Array<StepActionLogUpsertWithWhereUniqueWithoutSiteInput>>;
};

export type StepActionLogUpdateManyWithoutStepActionInput = {
  connect?: InputMaybe<Array<StepActionLogWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StepActionLogCreateOrConnectWithoutStepActionInput>>;
  create?: InputMaybe<Array<StepActionLogCreateWithoutStepActionInput>>;
  createMany?: InputMaybe<StepActionLogCreateManyStepActionInputEnvelope>;
  delete?: InputMaybe<Array<StepActionLogWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<StepActionLogScalarWhereInput>>;
  disconnect?: InputMaybe<Array<StepActionLogWhereUniqueInput>>;
  set?: InputMaybe<Array<StepActionLogWhereUniqueInput>>;
  update?: InputMaybe<Array<StepActionLogUpdateWithWhereUniqueWithoutStepActionInput>>;
  updateMany?: InputMaybe<Array<StepActionLogUpdateManyWithWhereWithoutStepActionInput>>;
  upsert?: InputMaybe<Array<StepActionLogUpsertWithWhereUniqueWithoutStepActionInput>>;
};

export type StepActionLogUpdateManyWithoutStepInput = {
  connect?: InputMaybe<Array<StepActionLogWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StepActionLogCreateOrConnectWithoutStepInput>>;
  create?: InputMaybe<Array<StepActionLogCreateWithoutStepInput>>;
  createMany?: InputMaybe<StepActionLogCreateManyStepInputEnvelope>;
  delete?: InputMaybe<Array<StepActionLogWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<StepActionLogScalarWhereInput>>;
  disconnect?: InputMaybe<Array<StepActionLogWhereUniqueInput>>;
  set?: InputMaybe<Array<StepActionLogWhereUniqueInput>>;
  update?: InputMaybe<Array<StepActionLogUpdateWithWhereUniqueWithoutStepInput>>;
  updateMany?: InputMaybe<Array<StepActionLogUpdateManyWithWhereWithoutStepInput>>;
  upsert?: InputMaybe<Array<StepActionLogUpsertWithWhereUniqueWithoutStepInput>>;
};

export type StepActionLogUpdateManyWithoutTeamInput = {
  connect?: InputMaybe<Array<StepActionLogWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StepActionLogCreateOrConnectWithoutTeamInput>>;
  create?: InputMaybe<Array<StepActionLogCreateWithoutTeamInput>>;
  createMany?: InputMaybe<StepActionLogCreateManyTeamInputEnvelope>;
  delete?: InputMaybe<Array<StepActionLogWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<StepActionLogScalarWhereInput>>;
  disconnect?: InputMaybe<Array<StepActionLogWhereUniqueInput>>;
  set?: InputMaybe<Array<StepActionLogWhereUniqueInput>>;
  update?: InputMaybe<Array<StepActionLogUpdateWithWhereUniqueWithoutTeamInput>>;
  updateMany?: InputMaybe<Array<StepActionLogUpdateManyWithWhereWithoutTeamInput>>;
  upsert?: InputMaybe<Array<StepActionLogUpsertWithWhereUniqueWithoutTeamInput>>;
};

export type StepActionLogUpdateManyWithWhereWithoutLeadActivityInput = {
  data: StepActionLogUpdateManyMutationInput;
  where: StepActionLogScalarWhereInput;
};

export type StepActionLogUpdateManyWithWhereWithoutLeadInput = {
  data: StepActionLogUpdateManyMutationInput;
  where: StepActionLogScalarWhereInput;
};

export type StepActionLogUpdateManyWithWhereWithoutOrganizationInput = {
  data: StepActionLogUpdateManyMutationInput;
  where: StepActionLogScalarWhereInput;
};

export type StepActionLogUpdateManyWithWhereWithoutRepInput = {
  data: StepActionLogUpdateManyMutationInput;
  where: StepActionLogScalarWhereInput;
};

export type StepActionLogUpdateManyWithWhereWithoutSequenceInput = {
  data: StepActionLogUpdateManyMutationInput;
  where: StepActionLogScalarWhereInput;
};

export type StepActionLogUpdateManyWithWhereWithoutSiteInput = {
  data: StepActionLogUpdateManyMutationInput;
  where: StepActionLogScalarWhereInput;
};

export type StepActionLogUpdateManyWithWhereWithoutStepActionInput = {
  data: StepActionLogUpdateManyMutationInput;
  where: StepActionLogScalarWhereInput;
};

export type StepActionLogUpdateManyWithWhereWithoutStepInput = {
  data: StepActionLogUpdateManyMutationInput;
  where: StepActionLogScalarWhereInput;
};

export type StepActionLogUpdateManyWithWhereWithoutTeamInput = {
  data: StepActionLogUpdateManyMutationInput;
  where: StepActionLogScalarWhereInput;
};

export type StepActionLogUpdateOneRequiredWithoutSnooze_LogsInput = {
  connect?: InputMaybe<StepActionLogWhereUniqueInput>;
  connectOrCreate?: InputMaybe<StepActionLogCreateOrConnectWithoutSnooze_LogsInput>;
  create?: InputMaybe<StepActionLogCreateWithoutSnooze_LogsInput>;
  update?: InputMaybe<StepActionLogUpdateWithoutSnooze_LogsInput>;
  upsert?: InputMaybe<StepActionLogUpsertWithoutSnooze_LogsInput>;
};

export type StepActionLogUpdateOneWithoutLeadIntentInput = {
  connect?: InputMaybe<StepActionLogWhereUniqueInput>;
  connectOrCreate?: InputMaybe<StepActionLogCreateOrConnectWithoutLeadIntentInput>;
  create?: InputMaybe<StepActionLogCreateWithoutLeadIntentInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<StepActionLogUpdateWithoutLeadIntentInput>;
  upsert?: InputMaybe<StepActionLogUpsertWithoutLeadIntentInput>;
};

export type StepActionLogUpdateWithoutLeadActivityInput = {
  action_completed?: InputMaybe<BoolFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  end_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lead?: InputMaybe<LeadUpdateOneRequiredWithoutSequenceActionLogInput>;
  lead_phase_on_task_completed?: InputMaybe<NullableEnumPhaseFieldUpdateOperationsInput>;
  leadIntent?: InputMaybe<LeadIntentUpdateOneWithoutStepActionLogInput>;
  nextstep?: InputMaybe<BoolFieldUpdateOperationsInput>;
  Organization?: InputMaybe<OrganizationUpdateOneWithoutStepActionLogsInput>;
  reason?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  remove?: InputMaybe<BoolFieldUpdateOperationsInput>;
  removed_due_to_scheduled_event?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  rep?: InputMaybe<UserUpdateOneRequiredWithoutSequenceActionLogInput>;
  replaced_by_scheduled_event?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  sequence?: InputMaybe<SequenceUpdateOneRequiredWithoutStepActionLogInput>;
  sequence_cycle_num?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  site?: InputMaybe<SiteUpdateOneWithoutStepActionLogInput>;
  snooze?: InputMaybe<BoolFieldUpdateOperationsInput>;
  snooze_logs?: InputMaybe<SnoozeTrackingUpdateManyWithoutStepActionLogInput>;
  snoozed_due_to_scheduled_event?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  start_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  step?: InputMaybe<SequenceStepUpdateOneWithoutStepActionLogInput>;
  stepAction?: InputMaybe<StepActionUpdateOneWithoutStepActionLogInput>;
  task?: InputMaybe<NullableEnumSequenceTaskFieldUpdateOperationsInput>;
  team?: InputMaybe<TeamUpdateOneWithoutStepActionLogInput>;
  time_to_complete?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type StepActionLogUpdateWithoutLeadInput = {
  action_completed?: InputMaybe<BoolFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  end_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lead_phase_on_task_completed?: InputMaybe<NullableEnumPhaseFieldUpdateOperationsInput>;
  leadActivity?: InputMaybe<LeadActivityUpdateOneWithoutStepActionLogInput>;
  leadIntent?: InputMaybe<LeadIntentUpdateOneWithoutStepActionLogInput>;
  nextstep?: InputMaybe<BoolFieldUpdateOperationsInput>;
  Organization?: InputMaybe<OrganizationUpdateOneWithoutStepActionLogsInput>;
  reason?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  remove?: InputMaybe<BoolFieldUpdateOperationsInput>;
  removed_due_to_scheduled_event?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  rep?: InputMaybe<UserUpdateOneRequiredWithoutSequenceActionLogInput>;
  replaced_by_scheduled_event?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  sequence?: InputMaybe<SequenceUpdateOneRequiredWithoutStepActionLogInput>;
  sequence_cycle_num?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  site?: InputMaybe<SiteUpdateOneWithoutStepActionLogInput>;
  snooze?: InputMaybe<BoolFieldUpdateOperationsInput>;
  snooze_logs?: InputMaybe<SnoozeTrackingUpdateManyWithoutStepActionLogInput>;
  snoozed_due_to_scheduled_event?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  start_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  step?: InputMaybe<SequenceStepUpdateOneWithoutStepActionLogInput>;
  stepAction?: InputMaybe<StepActionUpdateOneWithoutStepActionLogInput>;
  task?: InputMaybe<NullableEnumSequenceTaskFieldUpdateOperationsInput>;
  team?: InputMaybe<TeamUpdateOneWithoutStepActionLogInput>;
  time_to_complete?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type StepActionLogUpdateWithoutLeadIntentInput = {
  action_completed?: InputMaybe<BoolFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  end_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lead?: InputMaybe<LeadUpdateOneRequiredWithoutSequenceActionLogInput>;
  lead_phase_on_task_completed?: InputMaybe<NullableEnumPhaseFieldUpdateOperationsInput>;
  leadActivity?: InputMaybe<LeadActivityUpdateOneWithoutStepActionLogInput>;
  nextstep?: InputMaybe<BoolFieldUpdateOperationsInput>;
  Organization?: InputMaybe<OrganizationUpdateOneWithoutStepActionLogsInput>;
  reason?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  remove?: InputMaybe<BoolFieldUpdateOperationsInput>;
  removed_due_to_scheduled_event?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  rep?: InputMaybe<UserUpdateOneRequiredWithoutSequenceActionLogInput>;
  replaced_by_scheduled_event?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  sequence?: InputMaybe<SequenceUpdateOneRequiredWithoutStepActionLogInput>;
  sequence_cycle_num?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  site?: InputMaybe<SiteUpdateOneWithoutStepActionLogInput>;
  snooze?: InputMaybe<BoolFieldUpdateOperationsInput>;
  snooze_logs?: InputMaybe<SnoozeTrackingUpdateManyWithoutStepActionLogInput>;
  snoozed_due_to_scheduled_event?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  start_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  step?: InputMaybe<SequenceStepUpdateOneWithoutStepActionLogInput>;
  stepAction?: InputMaybe<StepActionUpdateOneWithoutStepActionLogInput>;
  task?: InputMaybe<NullableEnumSequenceTaskFieldUpdateOperationsInput>;
  team?: InputMaybe<TeamUpdateOneWithoutStepActionLogInput>;
  time_to_complete?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type StepActionLogUpdateWithoutOrganizationInput = {
  action_completed?: InputMaybe<BoolFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  end_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lead?: InputMaybe<LeadUpdateOneRequiredWithoutSequenceActionLogInput>;
  lead_phase_on_task_completed?: InputMaybe<NullableEnumPhaseFieldUpdateOperationsInput>;
  leadActivity?: InputMaybe<LeadActivityUpdateOneWithoutStepActionLogInput>;
  leadIntent?: InputMaybe<LeadIntentUpdateOneWithoutStepActionLogInput>;
  nextstep?: InputMaybe<BoolFieldUpdateOperationsInput>;
  reason?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  remove?: InputMaybe<BoolFieldUpdateOperationsInput>;
  removed_due_to_scheduled_event?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  rep?: InputMaybe<UserUpdateOneRequiredWithoutSequenceActionLogInput>;
  replaced_by_scheduled_event?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  sequence?: InputMaybe<SequenceUpdateOneRequiredWithoutStepActionLogInput>;
  sequence_cycle_num?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  site?: InputMaybe<SiteUpdateOneWithoutStepActionLogInput>;
  snooze?: InputMaybe<BoolFieldUpdateOperationsInput>;
  snooze_logs?: InputMaybe<SnoozeTrackingUpdateManyWithoutStepActionLogInput>;
  snoozed_due_to_scheduled_event?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  start_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  step?: InputMaybe<SequenceStepUpdateOneWithoutStepActionLogInput>;
  stepAction?: InputMaybe<StepActionUpdateOneWithoutStepActionLogInput>;
  task?: InputMaybe<NullableEnumSequenceTaskFieldUpdateOperationsInput>;
  team?: InputMaybe<TeamUpdateOneWithoutStepActionLogInput>;
  time_to_complete?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type StepActionLogUpdateWithoutRepInput = {
  action_completed?: InputMaybe<BoolFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  end_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lead?: InputMaybe<LeadUpdateOneRequiredWithoutSequenceActionLogInput>;
  lead_phase_on_task_completed?: InputMaybe<NullableEnumPhaseFieldUpdateOperationsInput>;
  leadActivity?: InputMaybe<LeadActivityUpdateOneWithoutStepActionLogInput>;
  leadIntent?: InputMaybe<LeadIntentUpdateOneWithoutStepActionLogInput>;
  nextstep?: InputMaybe<BoolFieldUpdateOperationsInput>;
  Organization?: InputMaybe<OrganizationUpdateOneWithoutStepActionLogsInput>;
  reason?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  remove?: InputMaybe<BoolFieldUpdateOperationsInput>;
  removed_due_to_scheduled_event?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  replaced_by_scheduled_event?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  sequence?: InputMaybe<SequenceUpdateOneRequiredWithoutStepActionLogInput>;
  sequence_cycle_num?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  site?: InputMaybe<SiteUpdateOneWithoutStepActionLogInput>;
  snooze?: InputMaybe<BoolFieldUpdateOperationsInput>;
  snooze_logs?: InputMaybe<SnoozeTrackingUpdateManyWithoutStepActionLogInput>;
  snoozed_due_to_scheduled_event?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  start_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  step?: InputMaybe<SequenceStepUpdateOneWithoutStepActionLogInput>;
  stepAction?: InputMaybe<StepActionUpdateOneWithoutStepActionLogInput>;
  task?: InputMaybe<NullableEnumSequenceTaskFieldUpdateOperationsInput>;
  team?: InputMaybe<TeamUpdateOneWithoutStepActionLogInput>;
  time_to_complete?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type StepActionLogUpdateWithoutSequenceInput = {
  action_completed?: InputMaybe<BoolFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  end_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lead?: InputMaybe<LeadUpdateOneRequiredWithoutSequenceActionLogInput>;
  lead_phase_on_task_completed?: InputMaybe<NullableEnumPhaseFieldUpdateOperationsInput>;
  leadActivity?: InputMaybe<LeadActivityUpdateOneWithoutStepActionLogInput>;
  leadIntent?: InputMaybe<LeadIntentUpdateOneWithoutStepActionLogInput>;
  nextstep?: InputMaybe<BoolFieldUpdateOperationsInput>;
  Organization?: InputMaybe<OrganizationUpdateOneWithoutStepActionLogsInput>;
  reason?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  remove?: InputMaybe<BoolFieldUpdateOperationsInput>;
  removed_due_to_scheduled_event?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  rep?: InputMaybe<UserUpdateOneRequiredWithoutSequenceActionLogInput>;
  replaced_by_scheduled_event?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  sequence_cycle_num?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  site?: InputMaybe<SiteUpdateOneWithoutStepActionLogInput>;
  snooze?: InputMaybe<BoolFieldUpdateOperationsInput>;
  snooze_logs?: InputMaybe<SnoozeTrackingUpdateManyWithoutStepActionLogInput>;
  snoozed_due_to_scheduled_event?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  start_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  step?: InputMaybe<SequenceStepUpdateOneWithoutStepActionLogInput>;
  stepAction?: InputMaybe<StepActionUpdateOneWithoutStepActionLogInput>;
  task?: InputMaybe<NullableEnumSequenceTaskFieldUpdateOperationsInput>;
  team?: InputMaybe<TeamUpdateOneWithoutStepActionLogInput>;
  time_to_complete?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type StepActionLogUpdateWithoutSiteInput = {
  action_completed?: InputMaybe<BoolFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  end_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lead?: InputMaybe<LeadUpdateOneRequiredWithoutSequenceActionLogInput>;
  lead_phase_on_task_completed?: InputMaybe<NullableEnumPhaseFieldUpdateOperationsInput>;
  leadActivity?: InputMaybe<LeadActivityUpdateOneWithoutStepActionLogInput>;
  leadIntent?: InputMaybe<LeadIntentUpdateOneWithoutStepActionLogInput>;
  nextstep?: InputMaybe<BoolFieldUpdateOperationsInput>;
  Organization?: InputMaybe<OrganizationUpdateOneWithoutStepActionLogsInput>;
  reason?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  remove?: InputMaybe<BoolFieldUpdateOperationsInput>;
  removed_due_to_scheduled_event?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  rep?: InputMaybe<UserUpdateOneRequiredWithoutSequenceActionLogInput>;
  replaced_by_scheduled_event?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  sequence?: InputMaybe<SequenceUpdateOneRequiredWithoutStepActionLogInput>;
  sequence_cycle_num?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  snooze?: InputMaybe<BoolFieldUpdateOperationsInput>;
  snooze_logs?: InputMaybe<SnoozeTrackingUpdateManyWithoutStepActionLogInput>;
  snoozed_due_to_scheduled_event?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  start_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  step?: InputMaybe<SequenceStepUpdateOneWithoutStepActionLogInput>;
  stepAction?: InputMaybe<StepActionUpdateOneWithoutStepActionLogInput>;
  task?: InputMaybe<NullableEnumSequenceTaskFieldUpdateOperationsInput>;
  team?: InputMaybe<TeamUpdateOneWithoutStepActionLogInput>;
  time_to_complete?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type StepActionLogUpdateWithoutSnooze_LogsInput = {
  action_completed?: InputMaybe<BoolFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  end_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lead?: InputMaybe<LeadUpdateOneRequiredWithoutSequenceActionLogInput>;
  lead_phase_on_task_completed?: InputMaybe<NullableEnumPhaseFieldUpdateOperationsInput>;
  leadActivity?: InputMaybe<LeadActivityUpdateOneWithoutStepActionLogInput>;
  leadIntent?: InputMaybe<LeadIntentUpdateOneWithoutStepActionLogInput>;
  nextstep?: InputMaybe<BoolFieldUpdateOperationsInput>;
  Organization?: InputMaybe<OrganizationUpdateOneWithoutStepActionLogsInput>;
  reason?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  remove?: InputMaybe<BoolFieldUpdateOperationsInput>;
  removed_due_to_scheduled_event?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  rep?: InputMaybe<UserUpdateOneRequiredWithoutSequenceActionLogInput>;
  replaced_by_scheduled_event?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  sequence?: InputMaybe<SequenceUpdateOneRequiredWithoutStepActionLogInput>;
  sequence_cycle_num?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  site?: InputMaybe<SiteUpdateOneWithoutStepActionLogInput>;
  snooze?: InputMaybe<BoolFieldUpdateOperationsInput>;
  snoozed_due_to_scheduled_event?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  start_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  step?: InputMaybe<SequenceStepUpdateOneWithoutStepActionLogInput>;
  stepAction?: InputMaybe<StepActionUpdateOneWithoutStepActionLogInput>;
  task?: InputMaybe<NullableEnumSequenceTaskFieldUpdateOperationsInput>;
  team?: InputMaybe<TeamUpdateOneWithoutStepActionLogInput>;
  time_to_complete?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type StepActionLogUpdateWithoutStepActionInput = {
  action_completed?: InputMaybe<BoolFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  end_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lead?: InputMaybe<LeadUpdateOneRequiredWithoutSequenceActionLogInput>;
  lead_phase_on_task_completed?: InputMaybe<NullableEnumPhaseFieldUpdateOperationsInput>;
  leadActivity?: InputMaybe<LeadActivityUpdateOneWithoutStepActionLogInput>;
  leadIntent?: InputMaybe<LeadIntentUpdateOneWithoutStepActionLogInput>;
  nextstep?: InputMaybe<BoolFieldUpdateOperationsInput>;
  Organization?: InputMaybe<OrganizationUpdateOneWithoutStepActionLogsInput>;
  reason?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  remove?: InputMaybe<BoolFieldUpdateOperationsInput>;
  removed_due_to_scheduled_event?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  rep?: InputMaybe<UserUpdateOneRequiredWithoutSequenceActionLogInput>;
  replaced_by_scheduled_event?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  sequence?: InputMaybe<SequenceUpdateOneRequiredWithoutStepActionLogInput>;
  sequence_cycle_num?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  site?: InputMaybe<SiteUpdateOneWithoutStepActionLogInput>;
  snooze?: InputMaybe<BoolFieldUpdateOperationsInput>;
  snooze_logs?: InputMaybe<SnoozeTrackingUpdateManyWithoutStepActionLogInput>;
  snoozed_due_to_scheduled_event?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  start_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  step?: InputMaybe<SequenceStepUpdateOneWithoutStepActionLogInput>;
  task?: InputMaybe<NullableEnumSequenceTaskFieldUpdateOperationsInput>;
  team?: InputMaybe<TeamUpdateOneWithoutStepActionLogInput>;
  time_to_complete?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type StepActionLogUpdateWithoutStepInput = {
  action_completed?: InputMaybe<BoolFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  end_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lead?: InputMaybe<LeadUpdateOneRequiredWithoutSequenceActionLogInput>;
  lead_phase_on_task_completed?: InputMaybe<NullableEnumPhaseFieldUpdateOperationsInput>;
  leadActivity?: InputMaybe<LeadActivityUpdateOneWithoutStepActionLogInput>;
  leadIntent?: InputMaybe<LeadIntentUpdateOneWithoutStepActionLogInput>;
  nextstep?: InputMaybe<BoolFieldUpdateOperationsInput>;
  Organization?: InputMaybe<OrganizationUpdateOneWithoutStepActionLogsInput>;
  reason?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  remove?: InputMaybe<BoolFieldUpdateOperationsInput>;
  removed_due_to_scheduled_event?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  rep?: InputMaybe<UserUpdateOneRequiredWithoutSequenceActionLogInput>;
  replaced_by_scheduled_event?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  sequence?: InputMaybe<SequenceUpdateOneRequiredWithoutStepActionLogInput>;
  sequence_cycle_num?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  site?: InputMaybe<SiteUpdateOneWithoutStepActionLogInput>;
  snooze?: InputMaybe<BoolFieldUpdateOperationsInput>;
  snooze_logs?: InputMaybe<SnoozeTrackingUpdateManyWithoutStepActionLogInput>;
  snoozed_due_to_scheduled_event?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  start_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  stepAction?: InputMaybe<StepActionUpdateOneWithoutStepActionLogInput>;
  task?: InputMaybe<NullableEnumSequenceTaskFieldUpdateOperationsInput>;
  team?: InputMaybe<TeamUpdateOneWithoutStepActionLogInput>;
  time_to_complete?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type StepActionLogUpdateWithoutTeamInput = {
  action_completed?: InputMaybe<BoolFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  end_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lead?: InputMaybe<LeadUpdateOneRequiredWithoutSequenceActionLogInput>;
  lead_phase_on_task_completed?: InputMaybe<NullableEnumPhaseFieldUpdateOperationsInput>;
  leadActivity?: InputMaybe<LeadActivityUpdateOneWithoutStepActionLogInput>;
  leadIntent?: InputMaybe<LeadIntentUpdateOneWithoutStepActionLogInput>;
  nextstep?: InputMaybe<BoolFieldUpdateOperationsInput>;
  Organization?: InputMaybe<OrganizationUpdateOneWithoutStepActionLogsInput>;
  reason?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  remove?: InputMaybe<BoolFieldUpdateOperationsInput>;
  removed_due_to_scheduled_event?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  rep?: InputMaybe<UserUpdateOneRequiredWithoutSequenceActionLogInput>;
  replaced_by_scheduled_event?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  sequence?: InputMaybe<SequenceUpdateOneRequiredWithoutStepActionLogInput>;
  sequence_cycle_num?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  site?: InputMaybe<SiteUpdateOneWithoutStepActionLogInput>;
  snooze?: InputMaybe<BoolFieldUpdateOperationsInput>;
  snooze_logs?: InputMaybe<SnoozeTrackingUpdateManyWithoutStepActionLogInput>;
  snoozed_due_to_scheduled_event?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  start_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  step?: InputMaybe<SequenceStepUpdateOneWithoutStepActionLogInput>;
  stepAction?: InputMaybe<StepActionUpdateOneWithoutStepActionLogInput>;
  task?: InputMaybe<NullableEnumSequenceTaskFieldUpdateOperationsInput>;
  time_to_complete?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type StepActionLogUpdateWithWhereUniqueWithoutLeadActivityInput = {
  data: StepActionLogUpdateWithoutLeadActivityInput;
  where: StepActionLogWhereUniqueInput;
};

export type StepActionLogUpdateWithWhereUniqueWithoutLeadInput = {
  data: StepActionLogUpdateWithoutLeadInput;
  where: StepActionLogWhereUniqueInput;
};

export type StepActionLogUpdateWithWhereUniqueWithoutOrganizationInput = {
  data: StepActionLogUpdateWithoutOrganizationInput;
  where: StepActionLogWhereUniqueInput;
};

export type StepActionLogUpdateWithWhereUniqueWithoutRepInput = {
  data: StepActionLogUpdateWithoutRepInput;
  where: StepActionLogWhereUniqueInput;
};

export type StepActionLogUpdateWithWhereUniqueWithoutSequenceInput = {
  data: StepActionLogUpdateWithoutSequenceInput;
  where: StepActionLogWhereUniqueInput;
};

export type StepActionLogUpdateWithWhereUniqueWithoutSiteInput = {
  data: StepActionLogUpdateWithoutSiteInput;
  where: StepActionLogWhereUniqueInput;
};

export type StepActionLogUpdateWithWhereUniqueWithoutStepActionInput = {
  data: StepActionLogUpdateWithoutStepActionInput;
  where: StepActionLogWhereUniqueInput;
};

export type StepActionLogUpdateWithWhereUniqueWithoutStepInput = {
  data: StepActionLogUpdateWithoutStepInput;
  where: StepActionLogWhereUniqueInput;
};

export type StepActionLogUpdateWithWhereUniqueWithoutTeamInput = {
  data: StepActionLogUpdateWithoutTeamInput;
  where: StepActionLogWhereUniqueInput;
};

export type StepActionLogUpsertWithoutLeadIntentInput = {
  create: StepActionLogCreateWithoutLeadIntentInput;
  update: StepActionLogUpdateWithoutLeadIntentInput;
};

export type StepActionLogUpsertWithoutSnooze_LogsInput = {
  create: StepActionLogCreateWithoutSnooze_LogsInput;
  update: StepActionLogUpdateWithoutSnooze_LogsInput;
};

export type StepActionLogUpsertWithWhereUniqueWithoutLeadActivityInput = {
  create: StepActionLogCreateWithoutLeadActivityInput;
  update: StepActionLogUpdateWithoutLeadActivityInput;
  where: StepActionLogWhereUniqueInput;
};

export type StepActionLogUpsertWithWhereUniqueWithoutLeadInput = {
  create: StepActionLogCreateWithoutLeadInput;
  update: StepActionLogUpdateWithoutLeadInput;
  where: StepActionLogWhereUniqueInput;
};

export type StepActionLogUpsertWithWhereUniqueWithoutOrganizationInput = {
  create: StepActionLogCreateWithoutOrganizationInput;
  update: StepActionLogUpdateWithoutOrganizationInput;
  where: StepActionLogWhereUniqueInput;
};

export type StepActionLogUpsertWithWhereUniqueWithoutRepInput = {
  create: StepActionLogCreateWithoutRepInput;
  update: StepActionLogUpdateWithoutRepInput;
  where: StepActionLogWhereUniqueInput;
};

export type StepActionLogUpsertWithWhereUniqueWithoutSequenceInput = {
  create: StepActionLogCreateWithoutSequenceInput;
  update: StepActionLogUpdateWithoutSequenceInput;
  where: StepActionLogWhereUniqueInput;
};

export type StepActionLogUpsertWithWhereUniqueWithoutSiteInput = {
  create: StepActionLogCreateWithoutSiteInput;
  update: StepActionLogUpdateWithoutSiteInput;
  where: StepActionLogWhereUniqueInput;
};

export type StepActionLogUpsertWithWhereUniqueWithoutStepActionInput = {
  create: StepActionLogCreateWithoutStepActionInput;
  update: StepActionLogUpdateWithoutStepActionInput;
  where: StepActionLogWhereUniqueInput;
};

export type StepActionLogUpsertWithWhereUniqueWithoutStepInput = {
  create: StepActionLogCreateWithoutStepInput;
  update: StepActionLogUpdateWithoutStepInput;
  where: StepActionLogWhereUniqueInput;
};

export type StepActionLogUpsertWithWhereUniqueWithoutTeamInput = {
  create: StepActionLogCreateWithoutTeamInput;
  update: StepActionLogUpdateWithoutTeamInput;
  where: StepActionLogWhereUniqueInput;
};

export type StepActionLogWhereInput = {
  action_completed?: InputMaybe<BoolFilter>;
  AND?: InputMaybe<Array<StepActionLogWhereInput>>;
  created_at?: InputMaybe<DateTimeFilter>;
  end_time?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<StringFilter>;
  lead?: InputMaybe<LeadWhereInput>;
  lead_activity_id?: InputMaybe<StringNullableFilter>;
  lead_id?: InputMaybe<StringFilter>;
  lead_intent_id?: InputMaybe<StringNullableFilter>;
  lead_phase_on_task_completed?: InputMaybe<EnumPhaseNullableFilter>;
  leadActivity?: InputMaybe<LeadActivityWhereInput>;
  leadIntent?: InputMaybe<LeadIntentWhereInput>;
  nextstep?: InputMaybe<BoolFilter>;
  NOT?: InputMaybe<Array<StepActionLogWhereInput>>;
  OR?: InputMaybe<Array<StepActionLogWhereInput>>;
  Organization?: InputMaybe<OrganizationWhereInput>;
  organization_id?: InputMaybe<StringNullableFilter>;
  reason?: InputMaybe<StringNullableFilter>;
  remove?: InputMaybe<BoolFilter>;
  removed_due_to_scheduled_event?: InputMaybe<DateTimeNullableFilter>;
  rep?: InputMaybe<UserWhereInput>;
  replaced_by_scheduled_event?: InputMaybe<DateTimeNullableFilter>;
  sequence?: InputMaybe<SequenceWhereInput>;
  sequence_cycle_num?: InputMaybe<IntNullableFilter>;
  sequence_id?: InputMaybe<StringFilter>;
  site?: InputMaybe<SiteWhereInput>;
  site_id?: InputMaybe<StringNullableFilter>;
  snooze?: InputMaybe<BoolFilter>;
  snooze_logs?: InputMaybe<SnoozeTrackingListRelationFilter>;
  snoozed_due_to_scheduled_event?: InputMaybe<IntNullableFilter>;
  start_time?: InputMaybe<DateTimeNullableFilter>;
  step?: InputMaybe<SequenceStepWhereInput>;
  step_action_id?: InputMaybe<StringNullableFilter>;
  step_id?: InputMaybe<StringNullableFilter>;
  stepAction?: InputMaybe<StepActionWhereInput>;
  task?: InputMaybe<EnumSequenceTaskNullableFilter>;
  team?: InputMaybe<TeamWhereInput>;
  team_id?: InputMaybe<StringNullableFilter>;
  time_to_complete?: InputMaybe<IntNullableFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
  user_id?: InputMaybe<StringFilter>;
};

export type StepActionLogWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  lead_id_step_action_id_sequence_cycle_num?: InputMaybe<StepActionLogLead_IdStep_Action_IdSequence_Cycle_NumCompoundUniqueInput>;
  lead_intent_id?: InputMaybe<Scalars['String']['input']>;
};

export type StepActionOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
  count?: InputMaybe<SortOrder>;
};

export type StepActionOrderByWithRelationInput = {
  bounced_perc?: InputMaybe<SortOrder>;
  clicked_perc?: InputMaybe<SortOrder>;
  created_at?: InputMaybe<SortOrder>;
  custom_task_note?: InputMaybe<SortOrder>;
  delay_amount?: InputMaybe<SortOrder>;
  delivered_perc?: InputMaybe<SortOrder>;
  EmailItem?: InputMaybe<EmailItemOrderByRelationAggregateInput>;
  id?: InputMaybe<SortOrder>;
  opened_perc?: InputMaybe<SortOrder>;
  Organization?: InputMaybe<OrganizationOrderByWithRelationInput>;
  organization_id?: InputMaybe<SortOrder>;
  replied_perc?: InputMaybe<SortOrder>;
  scheduled_event_behavior?: InputMaybe<SortOrder>;
  SMSItem?: InputMaybe<SmsItemOrderByRelationAggregateInput>;
  step?: InputMaybe<SequenceStepOrderByWithRelationInput>;
  step_id?: InputMaybe<SortOrder>;
  StepActionLog?: InputMaybe<StepActionLogOrderByRelationAggregateInput>;
  task?: InputMaybe<SortOrder>;
  template?: InputMaybe<TemplateOrderByWithRelationInput>;
  template_id?: InputMaybe<SortOrder>;
  unsubscribed_perc?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
};

export type StepActionScalarWhereInput = {
  AND?: InputMaybe<Array<StepActionScalarWhereInput>>;
  bounced_perc?: InputMaybe<FloatFilter>;
  clicked_perc?: InputMaybe<FloatFilter>;
  created_at?: InputMaybe<DateTimeFilter>;
  custom_task_note?: InputMaybe<StringNullableFilter>;
  delay_amount?: InputMaybe<IntNullableFilter>;
  delivered_perc?: InputMaybe<FloatFilter>;
  id?: InputMaybe<StringFilter>;
  NOT?: InputMaybe<Array<StepActionScalarWhereInput>>;
  opened_perc?: InputMaybe<FloatFilter>;
  OR?: InputMaybe<Array<StepActionScalarWhereInput>>;
  organization_id?: InputMaybe<StringNullableFilter>;
  replied_perc?: InputMaybe<FloatFilter>;
  scheduled_event_behavior?: InputMaybe<EnumSequenceStepScheduledEventBehaviorFilter>;
  step_id?: InputMaybe<StringFilter>;
  task?: InputMaybe<EnumSequenceTaskNullableFilter>;
  template_id?: InputMaybe<StringNullableFilter>;
  unsubscribed_perc?: InputMaybe<FloatFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
};

export type StepActionStep_IdTaskCompoundUniqueInput = {
  step_id: Scalars['String']['input'];
  task: SequenceTask;
};

export type StepActionUpdateManyMutationInput = {
  bounced_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  clicked_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  custom_task_note?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  delay_amount?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  delivered_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  opened_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  replied_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  scheduled_event_behavior?: InputMaybe<EnumSequenceStepScheduledEventBehaviorFieldUpdateOperationsInput>;
  task?: InputMaybe<NullableEnumSequenceTaskFieldUpdateOperationsInput>;
  unsubscribed_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type StepActionUpdateManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<StepActionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StepActionCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<StepActionCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<StepActionCreateManyOrganizationInputEnvelope>;
  delete?: InputMaybe<Array<StepActionWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<StepActionScalarWhereInput>>;
  disconnect?: InputMaybe<Array<StepActionWhereUniqueInput>>;
  set?: InputMaybe<Array<StepActionWhereUniqueInput>>;
  update?: InputMaybe<Array<StepActionUpdateWithWhereUniqueWithoutOrganizationInput>>;
  updateMany?: InputMaybe<Array<StepActionUpdateManyWithWhereWithoutOrganizationInput>>;
  upsert?: InputMaybe<Array<StepActionUpsertWithWhereUniqueWithoutOrganizationInput>>;
};

export type StepActionUpdateManyWithoutStepInput = {
  connect?: InputMaybe<Array<StepActionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StepActionCreateOrConnectWithoutStepInput>>;
  create?: InputMaybe<Array<StepActionCreateWithoutStepInput>>;
  createMany?: InputMaybe<StepActionCreateManyStepInputEnvelope>;
  delete?: InputMaybe<Array<StepActionWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<StepActionScalarWhereInput>>;
  disconnect?: InputMaybe<Array<StepActionWhereUniqueInput>>;
  set?: InputMaybe<Array<StepActionWhereUniqueInput>>;
  update?: InputMaybe<Array<StepActionUpdateWithWhereUniqueWithoutStepInput>>;
  updateMany?: InputMaybe<Array<StepActionUpdateManyWithWhereWithoutStepInput>>;
  upsert?: InputMaybe<Array<StepActionUpsertWithWhereUniqueWithoutStepInput>>;
};

export type StepActionUpdateManyWithoutTemplateInput = {
  connect?: InputMaybe<Array<StepActionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StepActionCreateOrConnectWithoutTemplateInput>>;
  create?: InputMaybe<Array<StepActionCreateWithoutTemplateInput>>;
  createMany?: InputMaybe<StepActionCreateManyTemplateInputEnvelope>;
  delete?: InputMaybe<Array<StepActionWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<StepActionScalarWhereInput>>;
  disconnect?: InputMaybe<Array<StepActionWhereUniqueInput>>;
  set?: InputMaybe<Array<StepActionWhereUniqueInput>>;
  update?: InputMaybe<Array<StepActionUpdateWithWhereUniqueWithoutTemplateInput>>;
  updateMany?: InputMaybe<Array<StepActionUpdateManyWithWhereWithoutTemplateInput>>;
  upsert?: InputMaybe<Array<StepActionUpsertWithWhereUniqueWithoutTemplateInput>>;
};

export type StepActionUpdateManyWithWhereWithoutOrganizationInput = {
  data: StepActionUpdateManyMutationInput;
  where: StepActionScalarWhereInput;
};

export type StepActionUpdateManyWithWhereWithoutStepInput = {
  data: StepActionUpdateManyMutationInput;
  where: StepActionScalarWhereInput;
};

export type StepActionUpdateManyWithWhereWithoutTemplateInput = {
  data: StepActionUpdateManyMutationInput;
  where: StepActionScalarWhereInput;
};

export type StepActionUpdateOneWithoutEmailItemInput = {
  connect?: InputMaybe<StepActionWhereUniqueInput>;
  connectOrCreate?: InputMaybe<StepActionCreateOrConnectWithoutEmailItemInput>;
  create?: InputMaybe<StepActionCreateWithoutEmailItemInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<StepActionUpdateWithoutEmailItemInput>;
  upsert?: InputMaybe<StepActionUpsertWithoutEmailItemInput>;
};

export type StepActionUpdateOneWithoutSmsItemInput = {
  connect?: InputMaybe<StepActionWhereUniqueInput>;
  connectOrCreate?: InputMaybe<StepActionCreateOrConnectWithoutSmsItemInput>;
  create?: InputMaybe<StepActionCreateWithoutSmsItemInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<StepActionUpdateWithoutSmsItemInput>;
  upsert?: InputMaybe<StepActionUpsertWithoutSmsItemInput>;
};

export type StepActionUpdateOneWithoutStepActionLogInput = {
  connect?: InputMaybe<StepActionWhereUniqueInput>;
  connectOrCreate?: InputMaybe<StepActionCreateOrConnectWithoutStepActionLogInput>;
  create?: InputMaybe<StepActionCreateWithoutStepActionLogInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<StepActionUpdateWithoutStepActionLogInput>;
  upsert?: InputMaybe<StepActionUpsertWithoutStepActionLogInput>;
};

export type StepActionUpdateWithoutEmailItemInput = {
  bounced_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  clicked_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  custom_task_note?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  delay_amount?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  delivered_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  opened_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  Organization?: InputMaybe<OrganizationUpdateOneWithoutStepActionsInput>;
  replied_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  scheduled_event_behavior?: InputMaybe<EnumSequenceStepScheduledEventBehaviorFieldUpdateOperationsInput>;
  SMSItem?: InputMaybe<SmsItemUpdateManyWithoutStep_ActionInput>;
  step?: InputMaybe<SequenceStepUpdateOneRequiredWithoutActionsInput>;
  StepActionLog?: InputMaybe<StepActionLogUpdateManyWithoutStepActionInput>;
  task?: InputMaybe<NullableEnumSequenceTaskFieldUpdateOperationsInput>;
  template?: InputMaybe<TemplateUpdateOneWithoutStepActionInput>;
  unsubscribed_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type StepActionUpdateWithoutOrganizationInput = {
  bounced_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  clicked_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  custom_task_note?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  delay_amount?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  delivered_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  EmailItem?: InputMaybe<EmailItemUpdateManyWithoutStep_ActionInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  opened_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  replied_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  scheduled_event_behavior?: InputMaybe<EnumSequenceStepScheduledEventBehaviorFieldUpdateOperationsInput>;
  SMSItem?: InputMaybe<SmsItemUpdateManyWithoutStep_ActionInput>;
  step?: InputMaybe<SequenceStepUpdateOneRequiredWithoutActionsInput>;
  StepActionLog?: InputMaybe<StepActionLogUpdateManyWithoutStepActionInput>;
  task?: InputMaybe<NullableEnumSequenceTaskFieldUpdateOperationsInput>;
  template?: InputMaybe<TemplateUpdateOneWithoutStepActionInput>;
  unsubscribed_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type StepActionUpdateWithoutSmsItemInput = {
  bounced_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  clicked_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  custom_task_note?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  delay_amount?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  delivered_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  EmailItem?: InputMaybe<EmailItemUpdateManyWithoutStep_ActionInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  opened_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  Organization?: InputMaybe<OrganizationUpdateOneWithoutStepActionsInput>;
  replied_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  scheduled_event_behavior?: InputMaybe<EnumSequenceStepScheduledEventBehaviorFieldUpdateOperationsInput>;
  step?: InputMaybe<SequenceStepUpdateOneRequiredWithoutActionsInput>;
  StepActionLog?: InputMaybe<StepActionLogUpdateManyWithoutStepActionInput>;
  task?: InputMaybe<NullableEnumSequenceTaskFieldUpdateOperationsInput>;
  template?: InputMaybe<TemplateUpdateOneWithoutStepActionInput>;
  unsubscribed_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type StepActionUpdateWithoutStepActionLogInput = {
  bounced_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  clicked_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  custom_task_note?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  delay_amount?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  delivered_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  EmailItem?: InputMaybe<EmailItemUpdateManyWithoutStep_ActionInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  opened_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  Organization?: InputMaybe<OrganizationUpdateOneWithoutStepActionsInput>;
  replied_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  scheduled_event_behavior?: InputMaybe<EnumSequenceStepScheduledEventBehaviorFieldUpdateOperationsInput>;
  SMSItem?: InputMaybe<SmsItemUpdateManyWithoutStep_ActionInput>;
  step?: InputMaybe<SequenceStepUpdateOneRequiredWithoutActionsInput>;
  task?: InputMaybe<NullableEnumSequenceTaskFieldUpdateOperationsInput>;
  template?: InputMaybe<TemplateUpdateOneWithoutStepActionInput>;
  unsubscribed_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type StepActionUpdateWithoutStepInput = {
  bounced_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  clicked_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  custom_task_note?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  delay_amount?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  delivered_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  EmailItem?: InputMaybe<EmailItemUpdateManyWithoutStep_ActionInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  opened_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  Organization?: InputMaybe<OrganizationUpdateOneWithoutStepActionsInput>;
  replied_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  scheduled_event_behavior?: InputMaybe<EnumSequenceStepScheduledEventBehaviorFieldUpdateOperationsInput>;
  SMSItem?: InputMaybe<SmsItemUpdateManyWithoutStep_ActionInput>;
  StepActionLog?: InputMaybe<StepActionLogUpdateManyWithoutStepActionInput>;
  task?: InputMaybe<NullableEnumSequenceTaskFieldUpdateOperationsInput>;
  template?: InputMaybe<TemplateUpdateOneWithoutStepActionInput>;
  unsubscribed_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type StepActionUpdateWithoutTemplateInput = {
  bounced_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  clicked_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  custom_task_note?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  delay_amount?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  delivered_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  EmailItem?: InputMaybe<EmailItemUpdateManyWithoutStep_ActionInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  opened_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  Organization?: InputMaybe<OrganizationUpdateOneWithoutStepActionsInput>;
  replied_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  scheduled_event_behavior?: InputMaybe<EnumSequenceStepScheduledEventBehaviorFieldUpdateOperationsInput>;
  SMSItem?: InputMaybe<SmsItemUpdateManyWithoutStep_ActionInput>;
  step?: InputMaybe<SequenceStepUpdateOneRequiredWithoutActionsInput>;
  StepActionLog?: InputMaybe<StepActionLogUpdateManyWithoutStepActionInput>;
  task?: InputMaybe<NullableEnumSequenceTaskFieldUpdateOperationsInput>;
  unsubscribed_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type StepActionUpdateWithWhereUniqueWithoutOrganizationInput = {
  data: StepActionUpdateWithoutOrganizationInput;
  where: StepActionWhereUniqueInput;
};

export type StepActionUpdateWithWhereUniqueWithoutStepInput = {
  data: StepActionUpdateWithoutStepInput;
  where: StepActionWhereUniqueInput;
};

export type StepActionUpdateWithWhereUniqueWithoutTemplateInput = {
  data: StepActionUpdateWithoutTemplateInput;
  where: StepActionWhereUniqueInput;
};

export type StepActionUpsertWithoutEmailItemInput = {
  create: StepActionCreateWithoutEmailItemInput;
  update: StepActionUpdateWithoutEmailItemInput;
};

export type StepActionUpsertWithoutSmsItemInput = {
  create: StepActionCreateWithoutSmsItemInput;
  update: StepActionUpdateWithoutSmsItemInput;
};

export type StepActionUpsertWithoutStepActionLogInput = {
  create: StepActionCreateWithoutStepActionLogInput;
  update: StepActionUpdateWithoutStepActionLogInput;
};

export type StepActionUpsertWithWhereUniqueWithoutOrganizationInput = {
  create: StepActionCreateWithoutOrganizationInput;
  update: StepActionUpdateWithoutOrganizationInput;
  where: StepActionWhereUniqueInput;
};

export type StepActionUpsertWithWhereUniqueWithoutStepInput = {
  create: StepActionCreateWithoutStepInput;
  update: StepActionUpdateWithoutStepInput;
  where: StepActionWhereUniqueInput;
};

export type StepActionUpsertWithWhereUniqueWithoutTemplateInput = {
  create: StepActionCreateWithoutTemplateInput;
  update: StepActionUpdateWithoutTemplateInput;
  where: StepActionWhereUniqueInput;
};

export type StepActionWhereInput = {
  AND?: InputMaybe<Array<StepActionWhereInput>>;
  bounced_perc?: InputMaybe<FloatFilter>;
  clicked_perc?: InputMaybe<FloatFilter>;
  created_at?: InputMaybe<DateTimeFilter>;
  custom_task_note?: InputMaybe<StringNullableFilter>;
  delay_amount?: InputMaybe<IntNullableFilter>;
  delivered_perc?: InputMaybe<FloatFilter>;
  EmailItem?: InputMaybe<EmailItemListRelationFilter>;
  id?: InputMaybe<StringFilter>;
  NOT?: InputMaybe<Array<StepActionWhereInput>>;
  opened_perc?: InputMaybe<FloatFilter>;
  OR?: InputMaybe<Array<StepActionWhereInput>>;
  Organization?: InputMaybe<OrganizationWhereInput>;
  organization_id?: InputMaybe<StringNullableFilter>;
  replied_perc?: InputMaybe<FloatFilter>;
  scheduled_event_behavior?: InputMaybe<EnumSequenceStepScheduledEventBehaviorFilter>;
  SMSItem?: InputMaybe<SmsItemListRelationFilter>;
  step?: InputMaybe<SequenceStepWhereInput>;
  step_id?: InputMaybe<StringFilter>;
  StepActionLog?: InputMaybe<StepActionLogListRelationFilter>;
  task?: InputMaybe<EnumSequenceTaskNullableFilter>;
  template?: InputMaybe<TemplateWhereInput>;
  template_id?: InputMaybe<StringNullableFilter>;
  unsubscribed_perc?: InputMaybe<FloatFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
};

export type StepActionWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  step_id_task?: InputMaybe<StepActionStep_IdTaskCompoundUniqueInput>;
};

export type StepCondition = {
  __typename?: 'StepCondition';
  created_at: Scalars['DateTime']['output'];
  dispositionType?: Maybe<Dispositiontype>;
  id: Scalars['String']['output'];
  step?: Maybe<SequenceStep>;
  step_id?: Maybe<Scalars['String']['output']>;
  updated_at: Scalars['DateTime']['output'];
};

export type StepConditionCreateManyOrganizationInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  dispositionType?: InputMaybe<Dispositiontype>;
  id?: InputMaybe<Scalars['String']['input']>;
  step_id?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type StepConditionCreateManyOrganizationInputEnvelope = {
  data?: InputMaybe<Array<StepConditionCreateManyOrganizationInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type StepConditionCreateManyStepInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  dispositionType?: InputMaybe<Dispositiontype>;
  id?: InputMaybe<Scalars['String']['input']>;
  organization_id?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type StepConditionCreateManyStepInputEnvelope = {
  data?: InputMaybe<Array<StepConditionCreateManyStepInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type StepConditionCreateNestedManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<StepConditionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StepConditionCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<StepConditionCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<StepConditionCreateManyOrganizationInputEnvelope>;
};

export type StepConditionCreateNestedManyWithoutStepInput = {
  connect?: InputMaybe<Array<StepConditionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StepConditionCreateOrConnectWithoutStepInput>>;
  create?: InputMaybe<Array<StepConditionCreateWithoutStepInput>>;
  createMany?: InputMaybe<StepConditionCreateManyStepInputEnvelope>;
};

export type StepConditionCreateOrConnectWithoutOrganizationInput = {
  create: StepConditionCreateWithoutOrganizationInput;
  where: StepConditionWhereUniqueInput;
};

export type StepConditionCreateOrConnectWithoutStepInput = {
  create: StepConditionCreateWithoutStepInput;
  where: StepConditionWhereUniqueInput;
};

export type StepConditionCreateWithoutOrganizationInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  dispositionType?: InputMaybe<Dispositiontype>;
  id?: InputMaybe<Scalars['String']['input']>;
  step?: InputMaybe<SequenceStepCreateNestedOneWithoutConditionsInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type StepConditionCreateWithoutStepInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  dispositionType?: InputMaybe<Dispositiontype>;
  id?: InputMaybe<Scalars['String']['input']>;
  Organization?: InputMaybe<OrganizationCreateNestedOneWithoutStepConditionsInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type StepConditionListRelationFilter = {
  every?: InputMaybe<StepConditionWhereInput>;
  none?: InputMaybe<StepConditionWhereInput>;
  some?: InputMaybe<StepConditionWhereInput>;
};

export type StepConditionOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
  count?: InputMaybe<SortOrder>;
};

export type StepConditionScalarWhereInput = {
  AND?: InputMaybe<Array<StepConditionScalarWhereInput>>;
  created_at?: InputMaybe<DateTimeFilter>;
  dispositionType?: InputMaybe<EnumDispositiontypeNullableFilter>;
  id?: InputMaybe<StringFilter>;
  NOT?: InputMaybe<Array<StepConditionScalarWhereInput>>;
  OR?: InputMaybe<Array<StepConditionScalarWhereInput>>;
  organization_id?: InputMaybe<StringNullableFilter>;
  step_id?: InputMaybe<StringNullableFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
};

export type StepConditionStep_IdDispositionTypeCompoundUniqueInput = {
  dispositionType: Dispositiontype;
  step_id: Scalars['String']['input'];
};

export type StepConditionUpdateManyMutationInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  dispositionType?: InputMaybe<NullableEnumDispositiontypeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type StepConditionUpdateManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<StepConditionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StepConditionCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<StepConditionCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<StepConditionCreateManyOrganizationInputEnvelope>;
  delete?: InputMaybe<Array<StepConditionWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<StepConditionScalarWhereInput>>;
  disconnect?: InputMaybe<Array<StepConditionWhereUniqueInput>>;
  set?: InputMaybe<Array<StepConditionWhereUniqueInput>>;
  update?: InputMaybe<Array<StepConditionUpdateWithWhereUniqueWithoutOrganizationInput>>;
  updateMany?: InputMaybe<Array<StepConditionUpdateManyWithWhereWithoutOrganizationInput>>;
  upsert?: InputMaybe<Array<StepConditionUpsertWithWhereUniqueWithoutOrganizationInput>>;
};

export type StepConditionUpdateManyWithoutStepInput = {
  connect?: InputMaybe<Array<StepConditionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StepConditionCreateOrConnectWithoutStepInput>>;
  create?: InputMaybe<Array<StepConditionCreateWithoutStepInput>>;
  createMany?: InputMaybe<StepConditionCreateManyStepInputEnvelope>;
  delete?: InputMaybe<Array<StepConditionWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<StepConditionScalarWhereInput>>;
  disconnect?: InputMaybe<Array<StepConditionWhereUniqueInput>>;
  set?: InputMaybe<Array<StepConditionWhereUniqueInput>>;
  update?: InputMaybe<Array<StepConditionUpdateWithWhereUniqueWithoutStepInput>>;
  updateMany?: InputMaybe<Array<StepConditionUpdateManyWithWhereWithoutStepInput>>;
  upsert?: InputMaybe<Array<StepConditionUpsertWithWhereUniqueWithoutStepInput>>;
};

export type StepConditionUpdateManyWithWhereWithoutOrganizationInput = {
  data: StepConditionUpdateManyMutationInput;
  where: StepConditionScalarWhereInput;
};

export type StepConditionUpdateManyWithWhereWithoutStepInput = {
  data: StepConditionUpdateManyMutationInput;
  where: StepConditionScalarWhereInput;
};

export type StepConditionUpdateWithoutOrganizationInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  dispositionType?: InputMaybe<NullableEnumDispositiontypeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  step?: InputMaybe<SequenceStepUpdateOneWithoutConditionsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type StepConditionUpdateWithoutStepInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  dispositionType?: InputMaybe<NullableEnumDispositiontypeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  Organization?: InputMaybe<OrganizationUpdateOneWithoutStepConditionsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type StepConditionUpdateWithWhereUniqueWithoutOrganizationInput = {
  data: StepConditionUpdateWithoutOrganizationInput;
  where: StepConditionWhereUniqueInput;
};

export type StepConditionUpdateWithWhereUniqueWithoutStepInput = {
  data: StepConditionUpdateWithoutStepInput;
  where: StepConditionWhereUniqueInput;
};

export type StepConditionUpsertWithWhereUniqueWithoutOrganizationInput = {
  create: StepConditionCreateWithoutOrganizationInput;
  update: StepConditionUpdateWithoutOrganizationInput;
  where: StepConditionWhereUniqueInput;
};

export type StepConditionUpsertWithWhereUniqueWithoutStepInput = {
  create: StepConditionCreateWithoutStepInput;
  update: StepConditionUpdateWithoutStepInput;
  where: StepConditionWhereUniqueInput;
};

export type StepConditionWhereInput = {
  AND?: InputMaybe<Array<StepConditionWhereInput>>;
  created_at?: InputMaybe<DateTimeFilter>;
  dispositionType?: InputMaybe<EnumDispositiontypeNullableFilter>;
  id?: InputMaybe<StringFilter>;
  NOT?: InputMaybe<Array<StepConditionWhereInput>>;
  OR?: InputMaybe<Array<StepConditionWhereInput>>;
  Organization?: InputMaybe<OrganizationWhereInput>;
  organization_id?: InputMaybe<StringNullableFilter>;
  step?: InputMaybe<SequenceStepWhereInput>;
  step_id?: InputMaybe<StringNullableFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
};

export type StepConditionWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  step_id_dispositionType?: InputMaybe<StepConditionStep_IdDispositionTypeCompoundUniqueInput>;
};

export type StringFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars['String']['input']>;
};

export type StringFilter = {
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedStringFilter>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type StringNullableFilter = {
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedStringNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type StringNullableListFilter = {
  equals?: InputMaybe<Array<Scalars['String']['input']>>;
  has?: InputMaybe<Scalars['String']['input']>;
  hasEvery?: InputMaybe<Array<Scalars['String']['input']>>;
  hasSome?: InputMaybe<Array<Scalars['String']['input']>>;
  isEmpty?: InputMaybe<Scalars['Boolean']['input']>;
};

/** User Batch Edit Input */
export type SubIndustryBatchEditInput = {
  assign?: InputMaybe<AssignType>;
  sub_industry_white_list_array?: InputMaybe<Array<Scalars['String']['input']>>;
};

export enum Subscriptioneventtype {
  SaleStart = 'SaleStart'
}

export type SuccessResponse = {
  __typename?: 'SuccessResponse';
  message?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  status_boolean?: Maybe<Scalars['Boolean']['output']>;
};

export enum Sync_Rule {
  Both = 'Both',
  ExternalToOpsiq = 'ExternalToOpsiq',
  None = 'None',
  OpsiqToExternal = 'OpsiqToExternal'
}

export enum System_Event {
  Activity = 'Activity',
  CreateDocument = 'CreateDocument',
  Demo = 'Demo',
  EditLead = 'EditLead',
  NewLead = 'NewLead',
  Sale = 'Sale'
}

/** system field type for field manager */
export enum SystemFieldType {
  Industry = 'Industry',
  LeadSource = 'LeadSource'
}

/** System field type enum */
export enum SystemFieldTypeInput {
  Address = 'ADDRESS',
  BusinessName = 'BUSINESS_NAME',
  City = 'CITY',
  ContractDuration = 'CONTRACT_DURATION',
  Country = 'COUNTRY',
  Email = 'EMAIL',
  FirstName = 'FIRST_NAME',
  Industry = 'INDUSTRY',
  LastName = 'LAST_NAME',
  LeadIdLabel = 'LEAD_ID_LABEL',
  LeadSource = 'LEAD_SOURCE',
  Mrr = 'MRR',
  PaymentTerms = 'PAYMENT_TERMS',
  PrimaryPhoneNumber = 'PRIMARY_PHONE_NUMBER',
  ProductSelection = 'PRODUCT_SELECTION',
  SaleNote = 'SALE_NOTE',
  State = 'STATE',
  SubIndustry = 'SUB_INDUSTRY',
  ZipCode = 'ZIP_CODE'
}

export enum SystemFieldTypes {
  Address = 'ADDRESS',
  BusinessName = 'BUSINESS_NAME',
  City = 'CITY',
  ContractDuration = 'CONTRACT_DURATION',
  Country = 'COUNTRY',
  Email = 'EMAIL',
  FirstName = 'FIRST_NAME',
  Industry = 'INDUSTRY',
  LastName = 'LAST_NAME',
  LeadIdLabel = 'LEAD_ID_LABEL',
  LeadSource = 'LEAD_SOURCE',
  Mrr = 'MRR',
  PaymentTerms = 'PAYMENT_TERMS',
  PrimaryPhoneNumber = 'PRIMARY_PHONE_NUMBER',
  ProductSelection = 'PRODUCT_SELECTION',
  SaleNote = 'SALE_NOTE',
  State = 'STATE',
  SubIndustry = 'SUB_INDUSTRY',
  ZipCode = 'ZIP_CODE'
}

export type Team = {
  __typename?: 'Team';
  active_members?: Maybe<Array<Maybe<User>>>;
  average_close_rate?: Maybe<Scalars['Float']['output']>;
  average_holds_per_prospect_vs_held_demo_per_dial?: Maybe<Scalars['Float']['output']>;
  close_team_cycle: Array<SaleCycle>;
  created_at: Scalars['DateTime']['output'];
  deleted_at?: Maybe<Scalars['DateTime']['output']>;
  hold_goal?: Maybe<Scalars['Float']['output']>;
  id: Scalars['String']['output'];
  /** Label as per the build team page */
  label?: Maybe<Scalars['String']['output']>;
  lead_activities: Array<LeadActivity>;
  members: Array<User>;
  name: Scalars['String']['output'];
  organization: Organization;
  organization_id: Scalars['String']['output'];
  set_goal?: Maybe<Scalars['Float']['output']>;
  set_team_cycle: Array<SaleCycle>;
  site?: Maybe<Site>;
  site_id?: Maybe<Scalars['String']['output']>;
  /** The team leader. Typically a Sales Manager (SM) */
  team_leader?: Maybe<User>;
  updated_at: Scalars['DateTime']['output'];
  win_rep_day_goal?: Maybe<Scalars['Float']['output']>;
};


export type TeamClose_Team_CycleArgs = {
  after?: InputMaybe<SaleCycleWhereUniqueInput>;
  before?: InputMaybe<SaleCycleWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type TeamLead_ActivitiesArgs = {
  after?: InputMaybe<LeadActivityWhereUniqueInput>;
  before?: InputMaybe<LeadActivityWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type TeamMembersArgs = {
  after?: InputMaybe<UserWhereUniqueInput>;
  before?: InputMaybe<UserWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type TeamSet_Team_CycleArgs = {
  after?: InputMaybe<SaleCycleWhereUniqueInput>;
  before?: InputMaybe<SaleCycleWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type TeamCreateManyOrganizationInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  deleted_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  inbound_call_allow_last_local?: InputMaybe<Scalars['Boolean']['input']>;
  inbound_call_allow_random_rep?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  office_location?: InputMaybe<Scalars['String']['input']>;
  site_id?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type TeamCreateManyOrganizationInputEnvelope = {
  data?: InputMaybe<Array<TeamCreateManyOrganizationInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type TeamCreateManySiteInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  deleted_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  inbound_call_allow_last_local?: InputMaybe<Scalars['Boolean']['input']>;
  inbound_call_allow_random_rep?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  office_location?: InputMaybe<Scalars['String']['input']>;
  organization_id: Scalars['String']['input'];
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type TeamCreateManySiteInputEnvelope = {
  data?: InputMaybe<Array<TeamCreateManySiteInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type TeamCreateNestedManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<TeamWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TeamCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<TeamCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<TeamCreateManyOrganizationInputEnvelope>;
};

export type TeamCreateNestedManyWithoutSiteInput = {
  connect?: InputMaybe<Array<TeamWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TeamCreateOrConnectWithoutSiteInput>>;
  create?: InputMaybe<Array<TeamCreateWithoutSiteInput>>;
  createMany?: InputMaybe<TeamCreateManySiteInputEnvelope>;
};

export type TeamCreateNestedOneWithoutClose_Team_CycleInput = {
  connect?: InputMaybe<TeamWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TeamCreateOrConnectWithoutClose_Team_CycleInput>;
  create?: InputMaybe<TeamCreateWithoutClose_Team_CycleInput>;
};

export type TeamCreateNestedOneWithoutLead_ActivitiesInput = {
  connect?: InputMaybe<TeamWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TeamCreateOrConnectWithoutLead_ActivitiesInput>;
  create?: InputMaybe<TeamCreateWithoutLead_ActivitiesInput>;
};

export type TeamCreateNestedOneWithoutMembersInput = {
  connect?: InputMaybe<TeamWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TeamCreateOrConnectWithoutMembersInput>;
  create?: InputMaybe<TeamCreateWithoutMembersInput>;
};

export type TeamCreateNestedOneWithoutSet_Team_CycleInput = {
  connect?: InputMaybe<TeamWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TeamCreateOrConnectWithoutSet_Team_CycleInput>;
  create?: InputMaybe<TeamCreateWithoutSet_Team_CycleInput>;
};

export type TeamCreateNestedOneWithoutStatLeadAggregationInput = {
  connect?: InputMaybe<TeamWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TeamCreateOrConnectWithoutStatLeadAggregationInput>;
  create?: InputMaybe<TeamCreateWithoutStatLeadAggregationInput>;
};

export type TeamCreateNestedOneWithoutStepActionLogInput = {
  connect?: InputMaybe<TeamWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TeamCreateOrConnectWithoutStepActionLogInput>;
  create?: InputMaybe<TeamCreateWithoutStepActionLogInput>;
};

export type TeamCreateNestedOneWithoutTransfer_Team_CycleInput = {
  connect?: InputMaybe<TeamWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TeamCreateOrConnectWithoutTransfer_Team_CycleInput>;
  create?: InputMaybe<TeamCreateWithoutTransfer_Team_CycleInput>;
};

export type TeamCreateOrConnectWithoutClose_Team_CycleInput = {
  create: TeamCreateWithoutClose_Team_CycleInput;
  where: TeamWhereUniqueInput;
};

export type TeamCreateOrConnectWithoutLead_ActivitiesInput = {
  create: TeamCreateWithoutLead_ActivitiesInput;
  where: TeamWhereUniqueInput;
};

export type TeamCreateOrConnectWithoutMembersInput = {
  create: TeamCreateWithoutMembersInput;
  where: TeamWhereUniqueInput;
};

export type TeamCreateOrConnectWithoutOrganizationInput = {
  create: TeamCreateWithoutOrganizationInput;
  where: TeamWhereUniqueInput;
};

export type TeamCreateOrConnectWithoutSet_Team_CycleInput = {
  create: TeamCreateWithoutSet_Team_CycleInput;
  where: TeamWhereUniqueInput;
};

export type TeamCreateOrConnectWithoutSiteInput = {
  create: TeamCreateWithoutSiteInput;
  where: TeamWhereUniqueInput;
};

export type TeamCreateOrConnectWithoutStatLeadAggregationInput = {
  create: TeamCreateWithoutStatLeadAggregationInput;
  where: TeamWhereUniqueInput;
};

export type TeamCreateOrConnectWithoutStepActionLogInput = {
  create: TeamCreateWithoutStepActionLogInput;
  where: TeamWhereUniqueInput;
};

export type TeamCreateOrConnectWithoutTransfer_Team_CycleInput = {
  create: TeamCreateWithoutTransfer_Team_CycleInput;
  where: TeamWhereUniqueInput;
};

export type TeamCreateWithoutClose_Team_CycleInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  deleted_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  inbound_call_allow_last_local?: InputMaybe<Scalars['Boolean']['input']>;
  inbound_call_allow_random_rep?: InputMaybe<Scalars['Boolean']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutTeamInput>;
  members?: InputMaybe<UserCreateNestedManyWithoutTeamInput>;
  name: Scalars['String']['input'];
  office_location?: InputMaybe<Scalars['String']['input']>;
  organization: OrganizationCreateNestedOneWithoutTeamsInput;
  set_team_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutSet_TeamInput>;
  site?: InputMaybe<SiteCreateNestedOneWithoutTeamInput>;
  StatLeadAggregation?: InputMaybe<StatLeadAggregationCreateNestedManyWithoutTeamInput>;
  StepActionLog?: InputMaybe<StepActionLogCreateNestedManyWithoutTeamInput>;
  transfer_team_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutTransfer_TeamInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type TeamCreateWithoutLead_ActivitiesInput = {
  close_team_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutClose_TeamInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  deleted_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  inbound_call_allow_last_local?: InputMaybe<Scalars['Boolean']['input']>;
  inbound_call_allow_random_rep?: InputMaybe<Scalars['Boolean']['input']>;
  members?: InputMaybe<UserCreateNestedManyWithoutTeamInput>;
  name: Scalars['String']['input'];
  office_location?: InputMaybe<Scalars['String']['input']>;
  organization: OrganizationCreateNestedOneWithoutTeamsInput;
  set_team_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutSet_TeamInput>;
  site?: InputMaybe<SiteCreateNestedOneWithoutTeamInput>;
  StatLeadAggregation?: InputMaybe<StatLeadAggregationCreateNestedManyWithoutTeamInput>;
  StepActionLog?: InputMaybe<StepActionLogCreateNestedManyWithoutTeamInput>;
  transfer_team_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutTransfer_TeamInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type TeamCreateWithoutMembersInput = {
  close_team_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutClose_TeamInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  deleted_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  inbound_call_allow_last_local?: InputMaybe<Scalars['Boolean']['input']>;
  inbound_call_allow_random_rep?: InputMaybe<Scalars['Boolean']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutTeamInput>;
  name: Scalars['String']['input'];
  office_location?: InputMaybe<Scalars['String']['input']>;
  organization: OrganizationCreateNestedOneWithoutTeamsInput;
  set_team_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutSet_TeamInput>;
  site?: InputMaybe<SiteCreateNestedOneWithoutTeamInput>;
  StatLeadAggregation?: InputMaybe<StatLeadAggregationCreateNestedManyWithoutTeamInput>;
  StepActionLog?: InputMaybe<StepActionLogCreateNestedManyWithoutTeamInput>;
  transfer_team_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutTransfer_TeamInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type TeamCreateWithoutOrganizationInput = {
  close_team_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutClose_TeamInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  deleted_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  inbound_call_allow_last_local?: InputMaybe<Scalars['Boolean']['input']>;
  inbound_call_allow_random_rep?: InputMaybe<Scalars['Boolean']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutTeamInput>;
  members?: InputMaybe<UserCreateNestedManyWithoutTeamInput>;
  name: Scalars['String']['input'];
  office_location?: InputMaybe<Scalars['String']['input']>;
  set_team_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutSet_TeamInput>;
  site?: InputMaybe<SiteCreateNestedOneWithoutTeamInput>;
  StatLeadAggregation?: InputMaybe<StatLeadAggregationCreateNestedManyWithoutTeamInput>;
  StepActionLog?: InputMaybe<StepActionLogCreateNestedManyWithoutTeamInput>;
  transfer_team_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutTransfer_TeamInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type TeamCreateWithoutSet_Team_CycleInput = {
  close_team_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutClose_TeamInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  deleted_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  inbound_call_allow_last_local?: InputMaybe<Scalars['Boolean']['input']>;
  inbound_call_allow_random_rep?: InputMaybe<Scalars['Boolean']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutTeamInput>;
  members?: InputMaybe<UserCreateNestedManyWithoutTeamInput>;
  name: Scalars['String']['input'];
  office_location?: InputMaybe<Scalars['String']['input']>;
  organization: OrganizationCreateNestedOneWithoutTeamsInput;
  site?: InputMaybe<SiteCreateNestedOneWithoutTeamInput>;
  StatLeadAggregation?: InputMaybe<StatLeadAggregationCreateNestedManyWithoutTeamInput>;
  StepActionLog?: InputMaybe<StepActionLogCreateNestedManyWithoutTeamInput>;
  transfer_team_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutTransfer_TeamInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type TeamCreateWithoutSiteInput = {
  close_team_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutClose_TeamInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  deleted_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  inbound_call_allow_last_local?: InputMaybe<Scalars['Boolean']['input']>;
  inbound_call_allow_random_rep?: InputMaybe<Scalars['Boolean']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutTeamInput>;
  members?: InputMaybe<UserCreateNestedManyWithoutTeamInput>;
  name: Scalars['String']['input'];
  office_location?: InputMaybe<Scalars['String']['input']>;
  organization: OrganizationCreateNestedOneWithoutTeamsInput;
  set_team_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutSet_TeamInput>;
  StatLeadAggregation?: InputMaybe<StatLeadAggregationCreateNestedManyWithoutTeamInput>;
  StepActionLog?: InputMaybe<StepActionLogCreateNestedManyWithoutTeamInput>;
  transfer_team_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutTransfer_TeamInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type TeamCreateWithoutStatLeadAggregationInput = {
  close_team_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutClose_TeamInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  deleted_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  inbound_call_allow_last_local?: InputMaybe<Scalars['Boolean']['input']>;
  inbound_call_allow_random_rep?: InputMaybe<Scalars['Boolean']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutTeamInput>;
  members?: InputMaybe<UserCreateNestedManyWithoutTeamInput>;
  name: Scalars['String']['input'];
  office_location?: InputMaybe<Scalars['String']['input']>;
  organization: OrganizationCreateNestedOneWithoutTeamsInput;
  set_team_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutSet_TeamInput>;
  site?: InputMaybe<SiteCreateNestedOneWithoutTeamInput>;
  StepActionLog?: InputMaybe<StepActionLogCreateNestedManyWithoutTeamInput>;
  transfer_team_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutTransfer_TeamInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type TeamCreateWithoutStepActionLogInput = {
  close_team_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutClose_TeamInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  deleted_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  inbound_call_allow_last_local?: InputMaybe<Scalars['Boolean']['input']>;
  inbound_call_allow_random_rep?: InputMaybe<Scalars['Boolean']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutTeamInput>;
  members?: InputMaybe<UserCreateNestedManyWithoutTeamInput>;
  name: Scalars['String']['input'];
  office_location?: InputMaybe<Scalars['String']['input']>;
  organization: OrganizationCreateNestedOneWithoutTeamsInput;
  set_team_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutSet_TeamInput>;
  site?: InputMaybe<SiteCreateNestedOneWithoutTeamInput>;
  StatLeadAggregation?: InputMaybe<StatLeadAggregationCreateNestedManyWithoutTeamInput>;
  transfer_team_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutTransfer_TeamInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type TeamCreateWithoutTransfer_Team_CycleInput = {
  close_team_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutClose_TeamInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  deleted_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  inbound_call_allow_last_local?: InputMaybe<Scalars['Boolean']['input']>;
  inbound_call_allow_random_rep?: InputMaybe<Scalars['Boolean']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutTeamInput>;
  members?: InputMaybe<UserCreateNestedManyWithoutTeamInput>;
  name: Scalars['String']['input'];
  office_location?: InputMaybe<Scalars['String']['input']>;
  organization: OrganizationCreateNestedOneWithoutTeamsInput;
  set_team_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutSet_TeamInput>;
  site?: InputMaybe<SiteCreateNestedOneWithoutTeamInput>;
  StatLeadAggregation?: InputMaybe<StatLeadAggregationCreateNestedManyWithoutTeamInput>;
  StepActionLog?: InputMaybe<StepActionLogCreateNestedManyWithoutTeamInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type TeamListRelationFilter = {
  every?: InputMaybe<TeamWhereInput>;
  none?: InputMaybe<TeamWhereInput>;
  some?: InputMaybe<TeamWhereInput>;
};

export type TeamLog = {
  __typename?: 'TeamLog';
  dml_created_by?: Maybe<Scalars['String']['output']>;
  dml_timestamp: Scalars['DateTime']['output'];
  dml_type: Dml_Type;
  new_row_data?: Maybe<Scalars['Json']['output']>;
  old_row_data?: Maybe<Scalars['Json']['output']>;
  team_id: Scalars['String']['output'];
};

export type TeamOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
  count?: InputMaybe<SortOrder>;
};

export type TeamOrderByWithRelationInput = {
  close_team_cycle?: InputMaybe<SaleCycleOrderByRelationAggregateInput>;
  created_at?: InputMaybe<SortOrder>;
  deleted_at?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  inbound_call_allow_last_local?: InputMaybe<SortOrder>;
  inbound_call_allow_random_rep?: InputMaybe<SortOrder>;
  lead_activities?: InputMaybe<LeadActivityOrderByRelationAggregateInput>;
  members?: InputMaybe<UserOrderByRelationAggregateInput>;
  name?: InputMaybe<SortOrder>;
  office_location?: InputMaybe<SortOrder>;
  organization?: InputMaybe<OrganizationOrderByWithRelationInput>;
  organization_id?: InputMaybe<SortOrder>;
  set_team_cycle?: InputMaybe<SaleCycleOrderByRelationAggregateInput>;
  site?: InputMaybe<SiteOrderByWithRelationInput>;
  site_id?: InputMaybe<SortOrder>;
  StatLeadAggregation?: InputMaybe<StatLeadAggregationOrderByRelationAggregateInput>;
  StepActionLog?: InputMaybe<StepActionLogOrderByRelationAggregateInput>;
  transfer_team_cycle?: InputMaybe<SaleCycleOrderByRelationAggregateInput>;
  updated_at?: InputMaybe<SortOrder>;
};

export type TeamScalarWhereInput = {
  AND?: InputMaybe<Array<TeamScalarWhereInput>>;
  created_at?: InputMaybe<DateTimeFilter>;
  deleted_at?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<StringFilter>;
  inbound_call_allow_last_local?: InputMaybe<BoolFilter>;
  inbound_call_allow_random_rep?: InputMaybe<BoolFilter>;
  name?: InputMaybe<StringFilter>;
  NOT?: InputMaybe<Array<TeamScalarWhereInput>>;
  office_location?: InputMaybe<StringNullableFilter>;
  OR?: InputMaybe<Array<TeamScalarWhereInput>>;
  organization_id?: InputMaybe<StringFilter>;
  site_id?: InputMaybe<StringNullableFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
};

export type TeamUpdateManyMutationInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deleted_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inbound_call_allow_last_local?: InputMaybe<BoolFieldUpdateOperationsInput>;
  inbound_call_allow_random_rep?: InputMaybe<BoolFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  office_location?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type TeamUpdateManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<TeamWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TeamCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<TeamCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<TeamCreateManyOrganizationInputEnvelope>;
  delete?: InputMaybe<Array<TeamWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<TeamScalarWhereInput>>;
  disconnect?: InputMaybe<Array<TeamWhereUniqueInput>>;
  set?: InputMaybe<Array<TeamWhereUniqueInput>>;
  update?: InputMaybe<Array<TeamUpdateWithWhereUniqueWithoutOrganizationInput>>;
  updateMany?: InputMaybe<Array<TeamUpdateManyWithWhereWithoutOrganizationInput>>;
  upsert?: InputMaybe<Array<TeamUpsertWithWhereUniqueWithoutOrganizationInput>>;
};

export type TeamUpdateManyWithoutSiteInput = {
  connect?: InputMaybe<Array<TeamWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TeamCreateOrConnectWithoutSiteInput>>;
  create?: InputMaybe<Array<TeamCreateWithoutSiteInput>>;
  createMany?: InputMaybe<TeamCreateManySiteInputEnvelope>;
  delete?: InputMaybe<Array<TeamWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<TeamScalarWhereInput>>;
  disconnect?: InputMaybe<Array<TeamWhereUniqueInput>>;
  set?: InputMaybe<Array<TeamWhereUniqueInput>>;
  update?: InputMaybe<Array<TeamUpdateWithWhereUniqueWithoutSiteInput>>;
  updateMany?: InputMaybe<Array<TeamUpdateManyWithWhereWithoutSiteInput>>;
  upsert?: InputMaybe<Array<TeamUpsertWithWhereUniqueWithoutSiteInput>>;
};

export type TeamUpdateManyWithWhereWithoutOrganizationInput = {
  data: TeamUpdateManyMutationInput;
  where: TeamScalarWhereInput;
};

export type TeamUpdateManyWithWhereWithoutSiteInput = {
  data: TeamUpdateManyMutationInput;
  where: TeamScalarWhereInput;
};

export type TeamUpdateOneWithoutClose_Team_CycleInput = {
  connect?: InputMaybe<TeamWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TeamCreateOrConnectWithoutClose_Team_CycleInput>;
  create?: InputMaybe<TeamCreateWithoutClose_Team_CycleInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<TeamUpdateWithoutClose_Team_CycleInput>;
  upsert?: InputMaybe<TeamUpsertWithoutClose_Team_CycleInput>;
};

export type TeamUpdateOneWithoutLead_ActivitiesInput = {
  connect?: InputMaybe<TeamWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TeamCreateOrConnectWithoutLead_ActivitiesInput>;
  create?: InputMaybe<TeamCreateWithoutLead_ActivitiesInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<TeamUpdateWithoutLead_ActivitiesInput>;
  upsert?: InputMaybe<TeamUpsertWithoutLead_ActivitiesInput>;
};

export type TeamUpdateOneWithoutMembersInput = {
  connect?: InputMaybe<TeamWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TeamCreateOrConnectWithoutMembersInput>;
  create?: InputMaybe<TeamCreateWithoutMembersInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<TeamUpdateWithoutMembersInput>;
  upsert?: InputMaybe<TeamUpsertWithoutMembersInput>;
};

export type TeamUpdateOneWithoutSet_Team_CycleInput = {
  connect?: InputMaybe<TeamWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TeamCreateOrConnectWithoutSet_Team_CycleInput>;
  create?: InputMaybe<TeamCreateWithoutSet_Team_CycleInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<TeamUpdateWithoutSet_Team_CycleInput>;
  upsert?: InputMaybe<TeamUpsertWithoutSet_Team_CycleInput>;
};

export type TeamUpdateOneWithoutStatLeadAggregationInput = {
  connect?: InputMaybe<TeamWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TeamCreateOrConnectWithoutStatLeadAggregationInput>;
  create?: InputMaybe<TeamCreateWithoutStatLeadAggregationInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<TeamUpdateWithoutStatLeadAggregationInput>;
  upsert?: InputMaybe<TeamUpsertWithoutStatLeadAggregationInput>;
};

export type TeamUpdateOneWithoutStepActionLogInput = {
  connect?: InputMaybe<TeamWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TeamCreateOrConnectWithoutStepActionLogInput>;
  create?: InputMaybe<TeamCreateWithoutStepActionLogInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<TeamUpdateWithoutStepActionLogInput>;
  upsert?: InputMaybe<TeamUpsertWithoutStepActionLogInput>;
};

export type TeamUpdateOneWithoutTransfer_Team_CycleInput = {
  connect?: InputMaybe<TeamWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TeamCreateOrConnectWithoutTransfer_Team_CycleInput>;
  create?: InputMaybe<TeamCreateWithoutTransfer_Team_CycleInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<TeamUpdateWithoutTransfer_Team_CycleInput>;
  upsert?: InputMaybe<TeamUpsertWithoutTransfer_Team_CycleInput>;
};

export type TeamUpdateWithoutClose_Team_CycleInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deleted_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inbound_call_allow_last_local?: InputMaybe<BoolFieldUpdateOperationsInput>;
  inbound_call_allow_random_rep?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutTeamInput>;
  members?: InputMaybe<UserUpdateManyWithoutTeamInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  office_location?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutTeamsInput>;
  set_team_cycle?: InputMaybe<SaleCycleUpdateManyWithoutSet_TeamInput>;
  site?: InputMaybe<SiteUpdateOneWithoutTeamInput>;
  StatLeadAggregation?: InputMaybe<StatLeadAggregationUpdateManyWithoutTeamInput>;
  StepActionLog?: InputMaybe<StepActionLogUpdateManyWithoutTeamInput>;
  transfer_team_cycle?: InputMaybe<SaleCycleUpdateManyWithoutTransfer_TeamInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type TeamUpdateWithoutLead_ActivitiesInput = {
  close_team_cycle?: InputMaybe<SaleCycleUpdateManyWithoutClose_TeamInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deleted_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inbound_call_allow_last_local?: InputMaybe<BoolFieldUpdateOperationsInput>;
  inbound_call_allow_random_rep?: InputMaybe<BoolFieldUpdateOperationsInput>;
  members?: InputMaybe<UserUpdateManyWithoutTeamInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  office_location?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutTeamsInput>;
  set_team_cycle?: InputMaybe<SaleCycleUpdateManyWithoutSet_TeamInput>;
  site?: InputMaybe<SiteUpdateOneWithoutTeamInput>;
  StatLeadAggregation?: InputMaybe<StatLeadAggregationUpdateManyWithoutTeamInput>;
  StepActionLog?: InputMaybe<StepActionLogUpdateManyWithoutTeamInput>;
  transfer_team_cycle?: InputMaybe<SaleCycleUpdateManyWithoutTransfer_TeamInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type TeamUpdateWithoutMembersInput = {
  close_team_cycle?: InputMaybe<SaleCycleUpdateManyWithoutClose_TeamInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deleted_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inbound_call_allow_last_local?: InputMaybe<BoolFieldUpdateOperationsInput>;
  inbound_call_allow_random_rep?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutTeamInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  office_location?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutTeamsInput>;
  set_team_cycle?: InputMaybe<SaleCycleUpdateManyWithoutSet_TeamInput>;
  site?: InputMaybe<SiteUpdateOneWithoutTeamInput>;
  StatLeadAggregation?: InputMaybe<StatLeadAggregationUpdateManyWithoutTeamInput>;
  StepActionLog?: InputMaybe<StepActionLogUpdateManyWithoutTeamInput>;
  transfer_team_cycle?: InputMaybe<SaleCycleUpdateManyWithoutTransfer_TeamInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type TeamUpdateWithoutOrganizationInput = {
  close_team_cycle?: InputMaybe<SaleCycleUpdateManyWithoutClose_TeamInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deleted_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inbound_call_allow_last_local?: InputMaybe<BoolFieldUpdateOperationsInput>;
  inbound_call_allow_random_rep?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutTeamInput>;
  members?: InputMaybe<UserUpdateManyWithoutTeamInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  office_location?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  set_team_cycle?: InputMaybe<SaleCycleUpdateManyWithoutSet_TeamInput>;
  site?: InputMaybe<SiteUpdateOneWithoutTeamInput>;
  StatLeadAggregation?: InputMaybe<StatLeadAggregationUpdateManyWithoutTeamInput>;
  StepActionLog?: InputMaybe<StepActionLogUpdateManyWithoutTeamInput>;
  transfer_team_cycle?: InputMaybe<SaleCycleUpdateManyWithoutTransfer_TeamInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type TeamUpdateWithoutSet_Team_CycleInput = {
  close_team_cycle?: InputMaybe<SaleCycleUpdateManyWithoutClose_TeamInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deleted_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inbound_call_allow_last_local?: InputMaybe<BoolFieldUpdateOperationsInput>;
  inbound_call_allow_random_rep?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutTeamInput>;
  members?: InputMaybe<UserUpdateManyWithoutTeamInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  office_location?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutTeamsInput>;
  site?: InputMaybe<SiteUpdateOneWithoutTeamInput>;
  StatLeadAggregation?: InputMaybe<StatLeadAggregationUpdateManyWithoutTeamInput>;
  StepActionLog?: InputMaybe<StepActionLogUpdateManyWithoutTeamInput>;
  transfer_team_cycle?: InputMaybe<SaleCycleUpdateManyWithoutTransfer_TeamInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type TeamUpdateWithoutSiteInput = {
  close_team_cycle?: InputMaybe<SaleCycleUpdateManyWithoutClose_TeamInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deleted_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inbound_call_allow_last_local?: InputMaybe<BoolFieldUpdateOperationsInput>;
  inbound_call_allow_random_rep?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutTeamInput>;
  members?: InputMaybe<UserUpdateManyWithoutTeamInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  office_location?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutTeamsInput>;
  set_team_cycle?: InputMaybe<SaleCycleUpdateManyWithoutSet_TeamInput>;
  StatLeadAggregation?: InputMaybe<StatLeadAggregationUpdateManyWithoutTeamInput>;
  StepActionLog?: InputMaybe<StepActionLogUpdateManyWithoutTeamInput>;
  transfer_team_cycle?: InputMaybe<SaleCycleUpdateManyWithoutTransfer_TeamInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type TeamUpdateWithoutStatLeadAggregationInput = {
  close_team_cycle?: InputMaybe<SaleCycleUpdateManyWithoutClose_TeamInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deleted_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inbound_call_allow_last_local?: InputMaybe<BoolFieldUpdateOperationsInput>;
  inbound_call_allow_random_rep?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutTeamInput>;
  members?: InputMaybe<UserUpdateManyWithoutTeamInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  office_location?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutTeamsInput>;
  set_team_cycle?: InputMaybe<SaleCycleUpdateManyWithoutSet_TeamInput>;
  site?: InputMaybe<SiteUpdateOneWithoutTeamInput>;
  StepActionLog?: InputMaybe<StepActionLogUpdateManyWithoutTeamInput>;
  transfer_team_cycle?: InputMaybe<SaleCycleUpdateManyWithoutTransfer_TeamInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type TeamUpdateWithoutStepActionLogInput = {
  close_team_cycle?: InputMaybe<SaleCycleUpdateManyWithoutClose_TeamInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deleted_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inbound_call_allow_last_local?: InputMaybe<BoolFieldUpdateOperationsInput>;
  inbound_call_allow_random_rep?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutTeamInput>;
  members?: InputMaybe<UserUpdateManyWithoutTeamInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  office_location?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutTeamsInput>;
  set_team_cycle?: InputMaybe<SaleCycleUpdateManyWithoutSet_TeamInput>;
  site?: InputMaybe<SiteUpdateOneWithoutTeamInput>;
  StatLeadAggregation?: InputMaybe<StatLeadAggregationUpdateManyWithoutTeamInput>;
  transfer_team_cycle?: InputMaybe<SaleCycleUpdateManyWithoutTransfer_TeamInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type TeamUpdateWithoutTransfer_Team_CycleInput = {
  close_team_cycle?: InputMaybe<SaleCycleUpdateManyWithoutClose_TeamInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deleted_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inbound_call_allow_last_local?: InputMaybe<BoolFieldUpdateOperationsInput>;
  inbound_call_allow_random_rep?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutTeamInput>;
  members?: InputMaybe<UserUpdateManyWithoutTeamInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  office_location?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutTeamsInput>;
  set_team_cycle?: InputMaybe<SaleCycleUpdateManyWithoutSet_TeamInput>;
  site?: InputMaybe<SiteUpdateOneWithoutTeamInput>;
  StatLeadAggregation?: InputMaybe<StatLeadAggregationUpdateManyWithoutTeamInput>;
  StepActionLog?: InputMaybe<StepActionLogUpdateManyWithoutTeamInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type TeamUpdateWithWhereUniqueWithoutOrganizationInput = {
  data: TeamUpdateWithoutOrganizationInput;
  where: TeamWhereUniqueInput;
};

export type TeamUpdateWithWhereUniqueWithoutSiteInput = {
  data: TeamUpdateWithoutSiteInput;
  where: TeamWhereUniqueInput;
};

export type TeamUpsertWithoutClose_Team_CycleInput = {
  create: TeamCreateWithoutClose_Team_CycleInput;
  update: TeamUpdateWithoutClose_Team_CycleInput;
};

export type TeamUpsertWithoutLead_ActivitiesInput = {
  create: TeamCreateWithoutLead_ActivitiesInput;
  update: TeamUpdateWithoutLead_ActivitiesInput;
};

export type TeamUpsertWithoutMembersInput = {
  create: TeamCreateWithoutMembersInput;
  update: TeamUpdateWithoutMembersInput;
};

export type TeamUpsertWithoutSet_Team_CycleInput = {
  create: TeamCreateWithoutSet_Team_CycleInput;
  update: TeamUpdateWithoutSet_Team_CycleInput;
};

export type TeamUpsertWithoutStatLeadAggregationInput = {
  create: TeamCreateWithoutStatLeadAggregationInput;
  update: TeamUpdateWithoutStatLeadAggregationInput;
};

export type TeamUpsertWithoutStepActionLogInput = {
  create: TeamCreateWithoutStepActionLogInput;
  update: TeamUpdateWithoutStepActionLogInput;
};

export type TeamUpsertWithoutTransfer_Team_CycleInput = {
  create: TeamCreateWithoutTransfer_Team_CycleInput;
  update: TeamUpdateWithoutTransfer_Team_CycleInput;
};

export type TeamUpsertWithWhereUniqueWithoutOrganizationInput = {
  create: TeamCreateWithoutOrganizationInput;
  update: TeamUpdateWithoutOrganizationInput;
  where: TeamWhereUniqueInput;
};

export type TeamUpsertWithWhereUniqueWithoutSiteInput = {
  create: TeamCreateWithoutSiteInput;
  update: TeamUpdateWithoutSiteInput;
  where: TeamWhereUniqueInput;
};

export type TeamWhereInput = {
  AND?: InputMaybe<Array<TeamWhereInput>>;
  close_team_cycle?: InputMaybe<SaleCycleListRelationFilter>;
  created_at?: InputMaybe<DateTimeFilter>;
  deleted_at?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<StringFilter>;
  inbound_call_allow_last_local?: InputMaybe<BoolFilter>;
  inbound_call_allow_random_rep?: InputMaybe<BoolFilter>;
  lead_activities?: InputMaybe<LeadActivityListRelationFilter>;
  members?: InputMaybe<UserListRelationFilter>;
  name?: InputMaybe<StringFilter>;
  NOT?: InputMaybe<Array<TeamWhereInput>>;
  office_location?: InputMaybe<StringNullableFilter>;
  OR?: InputMaybe<Array<TeamWhereInput>>;
  organization?: InputMaybe<OrganizationWhereInput>;
  organization_id?: InputMaybe<StringFilter>;
  set_team_cycle?: InputMaybe<SaleCycleListRelationFilter>;
  site?: InputMaybe<SiteWhereInput>;
  site_id?: InputMaybe<StringNullableFilter>;
  StatLeadAggregation?: InputMaybe<StatLeadAggregationListRelationFilter>;
  StepActionLog?: InputMaybe<StepActionLogListRelationFilter>;
  transfer_team_cycle?: InputMaybe<SaleCycleListRelationFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
};

export type TeamWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']['input']>;
};

export type Template = {
  __typename?: 'Template';
  bounced_perc: Scalars['Float']['output'];
  clicked_perc: Scalars['Float']['output'];
  content: Scalars['String']['output'];
  created_at: Scalars['DateTime']['output'];
  deleted_at?: Maybe<Scalars['DateTime']['output']>;
  delivered_perc: Scalars['Float']['output'];
  id: Scalars['String']['output'];
  last_modified_by: User;
  name: Scalars['String']['output'];
  opened_perc: Scalars['Float']['output'];
  organization: Organization;
  organization_id: Scalars['String']['output'];
  replied_perc: Scalars['Float']['output'];
  subject?: Maybe<Scalars['String']['output']>;
  TemplateVersion: Array<TemplateVersion>;
  type: TemplateType;
  unsubscribed_perc: Scalars['Float']['output'];
  updated_at: Scalars['DateTime']['output'];
  user_id: Scalars['String']['output'];
  visible_roles: Array<Role>;
  visible_teams: Array<Scalars['String']['output']>;
  visible_users: Array<Scalars['String']['output']>;
};


export type TemplateTemplateVersionArgs = {
  after?: InputMaybe<TemplateVersionWhereUniqueInput>;
  before?: InputMaybe<TemplateVersionWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type TemplateCreateManyLast_Modified_ByInput = {
  bounced_perc?: InputMaybe<Scalars['Float']['input']>;
  clicked_perc?: InputMaybe<Scalars['Float']['input']>;
  content: Scalars['String']['input'];
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  deleted_at?: InputMaybe<Scalars['DateTime']['input']>;
  delivered_perc?: InputMaybe<Scalars['Float']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  opened_perc?: InputMaybe<Scalars['Float']['input']>;
  organization_id: Scalars['String']['input'];
  replied_perc?: InputMaybe<Scalars['Float']['input']>;
  subject?: InputMaybe<Scalars['String']['input']>;
  type: TemplateType;
  unsubscribed_perc?: InputMaybe<Scalars['Float']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  visible_roles?: InputMaybe<TemplateCreateManyvisible_RolesInput>;
  visible_teams?: InputMaybe<TemplateCreateManyvisible_TeamsInput>;
  visible_users?: InputMaybe<TemplateCreateManyvisible_UsersInput>;
};

export type TemplateCreateManyLast_Modified_ByInputEnvelope = {
  data?: InputMaybe<Array<TemplateCreateManyLast_Modified_ByInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type TemplateCreateManyOrganizationInput = {
  bounced_perc?: InputMaybe<Scalars['Float']['input']>;
  clicked_perc?: InputMaybe<Scalars['Float']['input']>;
  content: Scalars['String']['input'];
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  deleted_at?: InputMaybe<Scalars['DateTime']['input']>;
  delivered_perc?: InputMaybe<Scalars['Float']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  opened_perc?: InputMaybe<Scalars['Float']['input']>;
  replied_perc?: InputMaybe<Scalars['Float']['input']>;
  subject?: InputMaybe<Scalars['String']['input']>;
  type: TemplateType;
  unsubscribed_perc?: InputMaybe<Scalars['Float']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user_id: Scalars['String']['input'];
  visible_roles?: InputMaybe<TemplateCreateManyvisible_RolesInput>;
  visible_teams?: InputMaybe<TemplateCreateManyvisible_TeamsInput>;
  visible_users?: InputMaybe<TemplateCreateManyvisible_UsersInput>;
};

export type TemplateCreateManyOrganizationInputEnvelope = {
  data?: InputMaybe<Array<TemplateCreateManyOrganizationInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type TemplateCreateManyvisible_RolesInput = {
  set?: InputMaybe<Array<Role>>;
};

export type TemplateCreateManyvisible_TeamsInput = {
  set?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type TemplateCreateManyvisible_UsersInput = {
  set?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type TemplateCreateNestedManyWithoutLast_Modified_ByInput = {
  connect?: InputMaybe<Array<TemplateWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TemplateCreateOrConnectWithoutLast_Modified_ByInput>>;
  create?: InputMaybe<Array<TemplateCreateWithoutLast_Modified_ByInput>>;
  createMany?: InputMaybe<TemplateCreateManyLast_Modified_ByInputEnvelope>;
};

export type TemplateCreateNestedManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<TemplateWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TemplateCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<TemplateCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<TemplateCreateManyOrganizationInputEnvelope>;
};

export type TemplateCreateNestedOneWithoutAutomatedCampaignInput = {
  connect?: InputMaybe<TemplateWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TemplateCreateOrConnectWithoutAutomatedCampaignInput>;
  create?: InputMaybe<TemplateCreateWithoutAutomatedCampaignInput>;
};

export type TemplateCreateNestedOneWithoutStepActionInput = {
  connect?: InputMaybe<TemplateWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TemplateCreateOrConnectWithoutStepActionInput>;
  create?: InputMaybe<TemplateCreateWithoutStepActionInput>;
};

export type TemplateCreateNestedOneWithoutTemplateVersionInput = {
  connect?: InputMaybe<TemplateWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TemplateCreateOrConnectWithoutTemplateVersionInput>;
  create?: InputMaybe<TemplateCreateWithoutTemplateVersionInput>;
};

export type TemplateCreateOrConnectWithoutAutomatedCampaignInput = {
  create: TemplateCreateWithoutAutomatedCampaignInput;
  where: TemplateWhereUniqueInput;
};

export type TemplateCreateOrConnectWithoutLast_Modified_ByInput = {
  create: TemplateCreateWithoutLast_Modified_ByInput;
  where: TemplateWhereUniqueInput;
};

export type TemplateCreateOrConnectWithoutOrganizationInput = {
  create: TemplateCreateWithoutOrganizationInput;
  where: TemplateWhereUniqueInput;
};

export type TemplateCreateOrConnectWithoutStepActionInput = {
  create: TemplateCreateWithoutStepActionInput;
  where: TemplateWhereUniqueInput;
};

export type TemplateCreateOrConnectWithoutTemplateVersionInput = {
  create: TemplateCreateWithoutTemplateVersionInput;
  where: TemplateWhereUniqueInput;
};

export type TemplateCreatevisible_RolesInput = {
  set?: InputMaybe<Array<Role>>;
};

export type TemplateCreatevisible_TeamsInput = {
  set?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type TemplateCreatevisible_UsersInput = {
  set?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type TemplateCreateWithoutAutomatedCampaignInput = {
  bounced_perc?: InputMaybe<Scalars['Float']['input']>;
  clicked_perc?: InputMaybe<Scalars['Float']['input']>;
  content: Scalars['String']['input'];
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  deleted_at?: InputMaybe<Scalars['DateTime']['input']>;
  delivered_perc?: InputMaybe<Scalars['Float']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  last_modified_by: UserCreateNestedOneWithoutTemplateInput;
  name: Scalars['String']['input'];
  opened_perc?: InputMaybe<Scalars['Float']['input']>;
  organization: OrganizationCreateNestedOneWithoutTemplateInput;
  replied_perc?: InputMaybe<Scalars['Float']['input']>;
  StepAction?: InputMaybe<StepActionCreateNestedManyWithoutTemplateInput>;
  subject?: InputMaybe<Scalars['String']['input']>;
  TemplateVersion?: InputMaybe<TemplateVersionCreateNestedManyWithoutTemplateInput>;
  type: TemplateType;
  unsubscribed_perc?: InputMaybe<Scalars['Float']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  visible_roles?: InputMaybe<TemplateCreatevisible_RolesInput>;
  visible_teams?: InputMaybe<TemplateCreatevisible_TeamsInput>;
  visible_users?: InputMaybe<TemplateCreatevisible_UsersInput>;
};

export type TemplateCreateWithoutLast_Modified_ByInput = {
  AutomatedCampaign?: InputMaybe<AutomatedCampaignCreateNestedManyWithoutTemplateInput>;
  bounced_perc?: InputMaybe<Scalars['Float']['input']>;
  clicked_perc?: InputMaybe<Scalars['Float']['input']>;
  content: Scalars['String']['input'];
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  deleted_at?: InputMaybe<Scalars['DateTime']['input']>;
  delivered_perc?: InputMaybe<Scalars['Float']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  opened_perc?: InputMaybe<Scalars['Float']['input']>;
  organization: OrganizationCreateNestedOneWithoutTemplateInput;
  replied_perc?: InputMaybe<Scalars['Float']['input']>;
  StepAction?: InputMaybe<StepActionCreateNestedManyWithoutTemplateInput>;
  subject?: InputMaybe<Scalars['String']['input']>;
  TemplateVersion?: InputMaybe<TemplateVersionCreateNestedManyWithoutTemplateInput>;
  type: TemplateType;
  unsubscribed_perc?: InputMaybe<Scalars['Float']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  visible_roles?: InputMaybe<TemplateCreatevisible_RolesInput>;
  visible_teams?: InputMaybe<TemplateCreatevisible_TeamsInput>;
  visible_users?: InputMaybe<TemplateCreatevisible_UsersInput>;
};

export type TemplateCreateWithoutOrganizationInput = {
  AutomatedCampaign?: InputMaybe<AutomatedCampaignCreateNestedManyWithoutTemplateInput>;
  bounced_perc?: InputMaybe<Scalars['Float']['input']>;
  clicked_perc?: InputMaybe<Scalars['Float']['input']>;
  content: Scalars['String']['input'];
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  deleted_at?: InputMaybe<Scalars['DateTime']['input']>;
  delivered_perc?: InputMaybe<Scalars['Float']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  last_modified_by: UserCreateNestedOneWithoutTemplateInput;
  name: Scalars['String']['input'];
  opened_perc?: InputMaybe<Scalars['Float']['input']>;
  replied_perc?: InputMaybe<Scalars['Float']['input']>;
  StepAction?: InputMaybe<StepActionCreateNestedManyWithoutTemplateInput>;
  subject?: InputMaybe<Scalars['String']['input']>;
  TemplateVersion?: InputMaybe<TemplateVersionCreateNestedManyWithoutTemplateInput>;
  type: TemplateType;
  unsubscribed_perc?: InputMaybe<Scalars['Float']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  visible_roles?: InputMaybe<TemplateCreatevisible_RolesInput>;
  visible_teams?: InputMaybe<TemplateCreatevisible_TeamsInput>;
  visible_users?: InputMaybe<TemplateCreatevisible_UsersInput>;
};

export type TemplateCreateWithoutStepActionInput = {
  AutomatedCampaign?: InputMaybe<AutomatedCampaignCreateNestedManyWithoutTemplateInput>;
  bounced_perc?: InputMaybe<Scalars['Float']['input']>;
  clicked_perc?: InputMaybe<Scalars['Float']['input']>;
  content: Scalars['String']['input'];
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  deleted_at?: InputMaybe<Scalars['DateTime']['input']>;
  delivered_perc?: InputMaybe<Scalars['Float']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  last_modified_by: UserCreateNestedOneWithoutTemplateInput;
  name: Scalars['String']['input'];
  opened_perc?: InputMaybe<Scalars['Float']['input']>;
  organization: OrganizationCreateNestedOneWithoutTemplateInput;
  replied_perc?: InputMaybe<Scalars['Float']['input']>;
  subject?: InputMaybe<Scalars['String']['input']>;
  TemplateVersion?: InputMaybe<TemplateVersionCreateNestedManyWithoutTemplateInput>;
  type: TemplateType;
  unsubscribed_perc?: InputMaybe<Scalars['Float']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  visible_roles?: InputMaybe<TemplateCreatevisible_RolesInput>;
  visible_teams?: InputMaybe<TemplateCreatevisible_TeamsInput>;
  visible_users?: InputMaybe<TemplateCreatevisible_UsersInput>;
};

export type TemplateCreateWithoutTemplateVersionInput = {
  AutomatedCampaign?: InputMaybe<AutomatedCampaignCreateNestedManyWithoutTemplateInput>;
  bounced_perc?: InputMaybe<Scalars['Float']['input']>;
  clicked_perc?: InputMaybe<Scalars['Float']['input']>;
  content: Scalars['String']['input'];
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  deleted_at?: InputMaybe<Scalars['DateTime']['input']>;
  delivered_perc?: InputMaybe<Scalars['Float']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  last_modified_by: UserCreateNestedOneWithoutTemplateInput;
  name: Scalars['String']['input'];
  opened_perc?: InputMaybe<Scalars['Float']['input']>;
  organization: OrganizationCreateNestedOneWithoutTemplateInput;
  replied_perc?: InputMaybe<Scalars['Float']['input']>;
  StepAction?: InputMaybe<StepActionCreateNestedManyWithoutTemplateInput>;
  subject?: InputMaybe<Scalars['String']['input']>;
  type: TemplateType;
  unsubscribed_perc?: InputMaybe<Scalars['Float']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  visible_roles?: InputMaybe<TemplateCreatevisible_RolesInput>;
  visible_teams?: InputMaybe<TemplateCreatevisible_TeamsInput>;
  visible_users?: InputMaybe<TemplateCreatevisible_UsersInput>;
};

/** TemplateFilter for creation and update */
export type TemplateFilter = {
  roles?: InputMaybe<Array<Role>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  team_ids?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  template_id?: InputMaybe<Scalars['String']['input']>;
  template_name?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<TemplateType>;
  user_ids?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** TemplateInput for creation and update */
export type TemplateInput = {
  content: Scalars['String']['input'];
  subject?: InputMaybe<Scalars['String']['input']>;
  template_id?: InputMaybe<Scalars['String']['input']>;
  template_name: Scalars['String']['input'];
  type: TemplateType;
  visibility: VisibilityInput;
};

export type TemplateListRelationFilter = {
  every?: InputMaybe<TemplateWhereInput>;
  none?: InputMaybe<TemplateWhereInput>;
  some?: InputMaybe<TemplateWhereInput>;
};

export type TemplateOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
  count?: InputMaybe<SortOrder>;
};

export type TemplateOrderByWithRelationInput = {
  AutomatedCampaign?: InputMaybe<AutomatedCampaignOrderByRelationAggregateInput>;
  bounced_perc?: InputMaybe<SortOrder>;
  clicked_perc?: InputMaybe<SortOrder>;
  content?: InputMaybe<SortOrder>;
  created_at?: InputMaybe<SortOrder>;
  deleted_at?: InputMaybe<SortOrder>;
  delivered_perc?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  last_modified_by?: InputMaybe<UserOrderByWithRelationInput>;
  name?: InputMaybe<SortOrder>;
  opened_perc?: InputMaybe<SortOrder>;
  organization?: InputMaybe<OrganizationOrderByWithRelationInput>;
  organization_id?: InputMaybe<SortOrder>;
  replied_perc?: InputMaybe<SortOrder>;
  StepAction?: InputMaybe<StepActionOrderByRelationAggregateInput>;
  subject?: InputMaybe<SortOrder>;
  TemplateVersion?: InputMaybe<TemplateVersionOrderByRelationAggregateInput>;
  type?: InputMaybe<SortOrder>;
  unsubscribed_perc?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
  user_id?: InputMaybe<SortOrder>;
  visible_roles?: InputMaybe<SortOrder>;
  visible_teams?: InputMaybe<SortOrder>;
  visible_users?: InputMaybe<SortOrder>;
};

export type TemplateScalarWhereInput = {
  AND?: InputMaybe<Array<TemplateScalarWhereInput>>;
  bounced_perc?: InputMaybe<FloatFilter>;
  clicked_perc?: InputMaybe<FloatFilter>;
  content?: InputMaybe<StringFilter>;
  created_at?: InputMaybe<DateTimeFilter>;
  deleted_at?: InputMaybe<DateTimeNullableFilter>;
  delivered_perc?: InputMaybe<FloatFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  NOT?: InputMaybe<Array<TemplateScalarWhereInput>>;
  opened_perc?: InputMaybe<FloatFilter>;
  OR?: InputMaybe<Array<TemplateScalarWhereInput>>;
  organization_id?: InputMaybe<StringFilter>;
  replied_perc?: InputMaybe<FloatFilter>;
  subject?: InputMaybe<StringNullableFilter>;
  type?: InputMaybe<EnumTemplateTypeFilter>;
  unsubscribed_perc?: InputMaybe<FloatFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
  user_id?: InputMaybe<StringFilter>;
  visible_roles?: InputMaybe<EnumRoleNullableListFilter>;
  visible_teams?: InputMaybe<StringNullableListFilter>;
  visible_users?: InputMaybe<StringNullableListFilter>;
};

export enum TemplateType {
  Email = 'EMAIL',
  Sms = 'SMS'
}

export type TemplateUpdateManyMutationInput = {
  bounced_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  clicked_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  content?: InputMaybe<StringFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deleted_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  delivered_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  opened_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  replied_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  subject?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumTemplateTypeFieldUpdateOperationsInput>;
  unsubscribed_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  visible_roles?: InputMaybe<TemplateUpdatevisible_RolesInput>;
  visible_teams?: InputMaybe<TemplateUpdatevisible_TeamsInput>;
  visible_users?: InputMaybe<TemplateUpdatevisible_UsersInput>;
};

export type TemplateUpdateManyWithoutLast_Modified_ByInput = {
  connect?: InputMaybe<Array<TemplateWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TemplateCreateOrConnectWithoutLast_Modified_ByInput>>;
  create?: InputMaybe<Array<TemplateCreateWithoutLast_Modified_ByInput>>;
  createMany?: InputMaybe<TemplateCreateManyLast_Modified_ByInputEnvelope>;
  delete?: InputMaybe<Array<TemplateWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<TemplateScalarWhereInput>>;
  disconnect?: InputMaybe<Array<TemplateWhereUniqueInput>>;
  set?: InputMaybe<Array<TemplateWhereUniqueInput>>;
  update?: InputMaybe<Array<TemplateUpdateWithWhereUniqueWithoutLast_Modified_ByInput>>;
  updateMany?: InputMaybe<Array<TemplateUpdateManyWithWhereWithoutLast_Modified_ByInput>>;
  upsert?: InputMaybe<Array<TemplateUpsertWithWhereUniqueWithoutLast_Modified_ByInput>>;
};

export type TemplateUpdateManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<TemplateWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TemplateCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<TemplateCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<TemplateCreateManyOrganizationInputEnvelope>;
  delete?: InputMaybe<Array<TemplateWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<TemplateScalarWhereInput>>;
  disconnect?: InputMaybe<Array<TemplateWhereUniqueInput>>;
  set?: InputMaybe<Array<TemplateWhereUniqueInput>>;
  update?: InputMaybe<Array<TemplateUpdateWithWhereUniqueWithoutOrganizationInput>>;
  updateMany?: InputMaybe<Array<TemplateUpdateManyWithWhereWithoutOrganizationInput>>;
  upsert?: InputMaybe<Array<TemplateUpsertWithWhereUniqueWithoutOrganizationInput>>;
};

export type TemplateUpdateManyWithWhereWithoutLast_Modified_ByInput = {
  data: TemplateUpdateManyMutationInput;
  where: TemplateScalarWhereInput;
};

export type TemplateUpdateManyWithWhereWithoutOrganizationInput = {
  data: TemplateUpdateManyMutationInput;
  where: TemplateScalarWhereInput;
};

export type TemplateUpdateOneRequiredWithoutTemplateVersionInput = {
  connect?: InputMaybe<TemplateWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TemplateCreateOrConnectWithoutTemplateVersionInput>;
  create?: InputMaybe<TemplateCreateWithoutTemplateVersionInput>;
  update?: InputMaybe<TemplateUpdateWithoutTemplateVersionInput>;
  upsert?: InputMaybe<TemplateUpsertWithoutTemplateVersionInput>;
};

export type TemplateUpdateOneWithoutAutomatedCampaignInput = {
  connect?: InputMaybe<TemplateWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TemplateCreateOrConnectWithoutAutomatedCampaignInput>;
  create?: InputMaybe<TemplateCreateWithoutAutomatedCampaignInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<TemplateUpdateWithoutAutomatedCampaignInput>;
  upsert?: InputMaybe<TemplateUpsertWithoutAutomatedCampaignInput>;
};

export type TemplateUpdateOneWithoutStepActionInput = {
  connect?: InputMaybe<TemplateWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TemplateCreateOrConnectWithoutStepActionInput>;
  create?: InputMaybe<TemplateCreateWithoutStepActionInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<TemplateUpdateWithoutStepActionInput>;
  upsert?: InputMaybe<TemplateUpsertWithoutStepActionInput>;
};

export type TemplateUpdatevisible_RolesInput = {
  push?: InputMaybe<Role>;
  set?: InputMaybe<Array<Role>>;
};

export type TemplateUpdatevisible_TeamsInput = {
  push?: InputMaybe<Scalars['String']['input']>;
  set?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type TemplateUpdatevisible_UsersInput = {
  push?: InputMaybe<Scalars['String']['input']>;
  set?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type TemplateUpdateWithoutAutomatedCampaignInput = {
  bounced_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  clicked_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  content?: InputMaybe<StringFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deleted_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  delivered_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  last_modified_by?: InputMaybe<UserUpdateOneRequiredWithoutTemplateInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  opened_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutTemplateInput>;
  replied_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  StepAction?: InputMaybe<StepActionUpdateManyWithoutTemplateInput>;
  subject?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  TemplateVersion?: InputMaybe<TemplateVersionUpdateManyWithoutTemplateInput>;
  type?: InputMaybe<EnumTemplateTypeFieldUpdateOperationsInput>;
  unsubscribed_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  visible_roles?: InputMaybe<TemplateUpdatevisible_RolesInput>;
  visible_teams?: InputMaybe<TemplateUpdatevisible_TeamsInput>;
  visible_users?: InputMaybe<TemplateUpdatevisible_UsersInput>;
};

export type TemplateUpdateWithoutLast_Modified_ByInput = {
  AutomatedCampaign?: InputMaybe<AutomatedCampaignUpdateManyWithoutTemplateInput>;
  bounced_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  clicked_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  content?: InputMaybe<StringFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deleted_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  delivered_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  opened_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutTemplateInput>;
  replied_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  StepAction?: InputMaybe<StepActionUpdateManyWithoutTemplateInput>;
  subject?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  TemplateVersion?: InputMaybe<TemplateVersionUpdateManyWithoutTemplateInput>;
  type?: InputMaybe<EnumTemplateTypeFieldUpdateOperationsInput>;
  unsubscribed_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  visible_roles?: InputMaybe<TemplateUpdatevisible_RolesInput>;
  visible_teams?: InputMaybe<TemplateUpdatevisible_TeamsInput>;
  visible_users?: InputMaybe<TemplateUpdatevisible_UsersInput>;
};

export type TemplateUpdateWithoutOrganizationInput = {
  AutomatedCampaign?: InputMaybe<AutomatedCampaignUpdateManyWithoutTemplateInput>;
  bounced_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  clicked_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  content?: InputMaybe<StringFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deleted_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  delivered_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  last_modified_by?: InputMaybe<UserUpdateOneRequiredWithoutTemplateInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  opened_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  replied_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  StepAction?: InputMaybe<StepActionUpdateManyWithoutTemplateInput>;
  subject?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  TemplateVersion?: InputMaybe<TemplateVersionUpdateManyWithoutTemplateInput>;
  type?: InputMaybe<EnumTemplateTypeFieldUpdateOperationsInput>;
  unsubscribed_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  visible_roles?: InputMaybe<TemplateUpdatevisible_RolesInput>;
  visible_teams?: InputMaybe<TemplateUpdatevisible_TeamsInput>;
  visible_users?: InputMaybe<TemplateUpdatevisible_UsersInput>;
};

export type TemplateUpdateWithoutStepActionInput = {
  AutomatedCampaign?: InputMaybe<AutomatedCampaignUpdateManyWithoutTemplateInput>;
  bounced_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  clicked_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  content?: InputMaybe<StringFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deleted_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  delivered_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  last_modified_by?: InputMaybe<UserUpdateOneRequiredWithoutTemplateInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  opened_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutTemplateInput>;
  replied_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  subject?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  TemplateVersion?: InputMaybe<TemplateVersionUpdateManyWithoutTemplateInput>;
  type?: InputMaybe<EnumTemplateTypeFieldUpdateOperationsInput>;
  unsubscribed_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  visible_roles?: InputMaybe<TemplateUpdatevisible_RolesInput>;
  visible_teams?: InputMaybe<TemplateUpdatevisible_TeamsInput>;
  visible_users?: InputMaybe<TemplateUpdatevisible_UsersInput>;
};

export type TemplateUpdateWithoutTemplateVersionInput = {
  AutomatedCampaign?: InputMaybe<AutomatedCampaignUpdateManyWithoutTemplateInput>;
  bounced_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  clicked_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  content?: InputMaybe<StringFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deleted_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  delivered_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  last_modified_by?: InputMaybe<UserUpdateOneRequiredWithoutTemplateInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  opened_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutTemplateInput>;
  replied_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  StepAction?: InputMaybe<StepActionUpdateManyWithoutTemplateInput>;
  subject?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumTemplateTypeFieldUpdateOperationsInput>;
  unsubscribed_perc?: InputMaybe<FloatFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  visible_roles?: InputMaybe<TemplateUpdatevisible_RolesInput>;
  visible_teams?: InputMaybe<TemplateUpdatevisible_TeamsInput>;
  visible_users?: InputMaybe<TemplateUpdatevisible_UsersInput>;
};

export type TemplateUpdateWithWhereUniqueWithoutLast_Modified_ByInput = {
  data: TemplateUpdateWithoutLast_Modified_ByInput;
  where: TemplateWhereUniqueInput;
};

export type TemplateUpdateWithWhereUniqueWithoutOrganizationInput = {
  data: TemplateUpdateWithoutOrganizationInput;
  where: TemplateWhereUniqueInput;
};

export type TemplateUpsertWithoutAutomatedCampaignInput = {
  create: TemplateCreateWithoutAutomatedCampaignInput;
  update: TemplateUpdateWithoutAutomatedCampaignInput;
};

export type TemplateUpsertWithoutStepActionInput = {
  create: TemplateCreateWithoutStepActionInput;
  update: TemplateUpdateWithoutStepActionInput;
};

export type TemplateUpsertWithoutTemplateVersionInput = {
  create: TemplateCreateWithoutTemplateVersionInput;
  update: TemplateUpdateWithoutTemplateVersionInput;
};

export type TemplateUpsertWithWhereUniqueWithoutLast_Modified_ByInput = {
  create: TemplateCreateWithoutLast_Modified_ByInput;
  update: TemplateUpdateWithoutLast_Modified_ByInput;
  where: TemplateWhereUniqueInput;
};

export type TemplateUpsertWithWhereUniqueWithoutOrganizationInput = {
  create: TemplateCreateWithoutOrganizationInput;
  update: TemplateUpdateWithoutOrganizationInput;
  where: TemplateWhereUniqueInput;
};

export type TemplateVersion = {
  __typename?: 'TemplateVersion';
  content: Scalars['String']['output'];
  created_at: Scalars['DateTime']['output'];
  created_by: User;
  EmailItem: Array<EmailItem>;
  id: Scalars['String']['output'];
  SMSItem: Array<SmsItem>;
  subject?: Maybe<Scalars['String']['output']>;
  template: Template;
  template_id: Scalars['String']['output'];
  updated_at: Scalars['DateTime']['output'];
  user_id: Scalars['String']['output'];
};


export type TemplateVersionEmailItemArgs = {
  after?: InputMaybe<EmailItemWhereUniqueInput>;
  before?: InputMaybe<EmailItemWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type TemplateVersionSmsItemArgs = {
  after?: InputMaybe<SmsItemWhereUniqueInput>;
  before?: InputMaybe<SmsItemWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type TemplateVersionCreateManyCreated_ByInput = {
  content: Scalars['String']['input'];
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  organization_id?: InputMaybe<Scalars['String']['input']>;
  subject?: InputMaybe<Scalars['String']['input']>;
  template_id: Scalars['String']['input'];
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type TemplateVersionCreateManyCreated_ByInputEnvelope = {
  data?: InputMaybe<Array<TemplateVersionCreateManyCreated_ByInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type TemplateVersionCreateManyOrganizationInput = {
  content: Scalars['String']['input'];
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  subject?: InputMaybe<Scalars['String']['input']>;
  template_id: Scalars['String']['input'];
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user_id: Scalars['String']['input'];
};

export type TemplateVersionCreateManyOrganizationInputEnvelope = {
  data?: InputMaybe<Array<TemplateVersionCreateManyOrganizationInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type TemplateVersionCreateManyTemplateInput = {
  content: Scalars['String']['input'];
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  organization_id?: InputMaybe<Scalars['String']['input']>;
  subject?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user_id: Scalars['String']['input'];
};

export type TemplateVersionCreateManyTemplateInputEnvelope = {
  data?: InputMaybe<Array<TemplateVersionCreateManyTemplateInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type TemplateVersionCreateNestedManyWithoutCreated_ByInput = {
  connect?: InputMaybe<Array<TemplateVersionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TemplateVersionCreateOrConnectWithoutCreated_ByInput>>;
  create?: InputMaybe<Array<TemplateVersionCreateWithoutCreated_ByInput>>;
  createMany?: InputMaybe<TemplateVersionCreateManyCreated_ByInputEnvelope>;
};

export type TemplateVersionCreateNestedManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<TemplateVersionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TemplateVersionCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<TemplateVersionCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<TemplateVersionCreateManyOrganizationInputEnvelope>;
};

export type TemplateVersionCreateNestedManyWithoutTemplateInput = {
  connect?: InputMaybe<Array<TemplateVersionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TemplateVersionCreateOrConnectWithoutTemplateInput>>;
  create?: InputMaybe<Array<TemplateVersionCreateWithoutTemplateInput>>;
  createMany?: InputMaybe<TemplateVersionCreateManyTemplateInputEnvelope>;
};

export type TemplateVersionCreateNestedOneWithoutEmailItemInput = {
  connect?: InputMaybe<TemplateVersionWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TemplateVersionCreateOrConnectWithoutEmailItemInput>;
  create?: InputMaybe<TemplateVersionCreateWithoutEmailItemInput>;
};

export type TemplateVersionCreateNestedOneWithoutSmsItemInput = {
  connect?: InputMaybe<TemplateVersionWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TemplateVersionCreateOrConnectWithoutSmsItemInput>;
  create?: InputMaybe<TemplateVersionCreateWithoutSmsItemInput>;
};

export type TemplateVersionCreateOrConnectWithoutCreated_ByInput = {
  create: TemplateVersionCreateWithoutCreated_ByInput;
  where: TemplateVersionWhereUniqueInput;
};

export type TemplateVersionCreateOrConnectWithoutEmailItemInput = {
  create: TemplateVersionCreateWithoutEmailItemInput;
  where: TemplateVersionWhereUniqueInput;
};

export type TemplateVersionCreateOrConnectWithoutOrganizationInput = {
  create: TemplateVersionCreateWithoutOrganizationInput;
  where: TemplateVersionWhereUniqueInput;
};

export type TemplateVersionCreateOrConnectWithoutSmsItemInput = {
  create: TemplateVersionCreateWithoutSmsItemInput;
  where: TemplateVersionWhereUniqueInput;
};

export type TemplateVersionCreateOrConnectWithoutTemplateInput = {
  create: TemplateVersionCreateWithoutTemplateInput;
  where: TemplateVersionWhereUniqueInput;
};

export type TemplateVersionCreateWithoutCreated_ByInput = {
  content: Scalars['String']['input'];
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  EmailItem?: InputMaybe<EmailItemCreateNestedManyWithoutTemplate_VersionInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  Organization?: InputMaybe<OrganizationCreateNestedOneWithoutTemplateVersionsInput>;
  SMSItem?: InputMaybe<SmsItemCreateNestedManyWithoutTemplate_VersionInput>;
  subject?: InputMaybe<Scalars['String']['input']>;
  template: TemplateCreateNestedOneWithoutTemplateVersionInput;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type TemplateVersionCreateWithoutEmailItemInput = {
  content: Scalars['String']['input'];
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  created_by: UserCreateNestedOneWithoutTemplateVersionInput;
  id?: InputMaybe<Scalars['String']['input']>;
  Organization?: InputMaybe<OrganizationCreateNestedOneWithoutTemplateVersionsInput>;
  SMSItem?: InputMaybe<SmsItemCreateNestedManyWithoutTemplate_VersionInput>;
  subject?: InputMaybe<Scalars['String']['input']>;
  template: TemplateCreateNestedOneWithoutTemplateVersionInput;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type TemplateVersionCreateWithoutOrganizationInput = {
  content: Scalars['String']['input'];
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  created_by: UserCreateNestedOneWithoutTemplateVersionInput;
  EmailItem?: InputMaybe<EmailItemCreateNestedManyWithoutTemplate_VersionInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  SMSItem?: InputMaybe<SmsItemCreateNestedManyWithoutTemplate_VersionInput>;
  subject?: InputMaybe<Scalars['String']['input']>;
  template: TemplateCreateNestedOneWithoutTemplateVersionInput;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type TemplateVersionCreateWithoutSmsItemInput = {
  content: Scalars['String']['input'];
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  created_by: UserCreateNestedOneWithoutTemplateVersionInput;
  EmailItem?: InputMaybe<EmailItemCreateNestedManyWithoutTemplate_VersionInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  Organization?: InputMaybe<OrganizationCreateNestedOneWithoutTemplateVersionsInput>;
  subject?: InputMaybe<Scalars['String']['input']>;
  template: TemplateCreateNestedOneWithoutTemplateVersionInput;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type TemplateVersionCreateWithoutTemplateInput = {
  content: Scalars['String']['input'];
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  created_by: UserCreateNestedOneWithoutTemplateVersionInput;
  EmailItem?: InputMaybe<EmailItemCreateNestedManyWithoutTemplate_VersionInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  Organization?: InputMaybe<OrganizationCreateNestedOneWithoutTemplateVersionsInput>;
  SMSItem?: InputMaybe<SmsItemCreateNestedManyWithoutTemplate_VersionInput>;
  subject?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type TemplateVersionListRelationFilter = {
  every?: InputMaybe<TemplateVersionWhereInput>;
  none?: InputMaybe<TemplateVersionWhereInput>;
  some?: InputMaybe<TemplateVersionWhereInput>;
};

export type TemplateVersionOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
  count?: InputMaybe<SortOrder>;
};

export type TemplateVersionOrderByWithRelationInput = {
  content?: InputMaybe<SortOrder>;
  created_at?: InputMaybe<SortOrder>;
  created_by?: InputMaybe<UserOrderByWithRelationInput>;
  EmailItem?: InputMaybe<EmailItemOrderByRelationAggregateInput>;
  id?: InputMaybe<SortOrder>;
  Organization?: InputMaybe<OrganizationOrderByWithRelationInput>;
  organization_id?: InputMaybe<SortOrder>;
  SMSItem?: InputMaybe<SmsItemOrderByRelationAggregateInput>;
  subject?: InputMaybe<SortOrder>;
  template?: InputMaybe<TemplateOrderByWithRelationInput>;
  template_id?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
  user_id?: InputMaybe<SortOrder>;
};

export type TemplateVersionScalarWhereInput = {
  AND?: InputMaybe<Array<TemplateVersionScalarWhereInput>>;
  content?: InputMaybe<StringFilter>;
  created_at?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  NOT?: InputMaybe<Array<TemplateVersionScalarWhereInput>>;
  OR?: InputMaybe<Array<TemplateVersionScalarWhereInput>>;
  organization_id?: InputMaybe<StringNullableFilter>;
  subject?: InputMaybe<StringNullableFilter>;
  template_id?: InputMaybe<StringFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
  user_id?: InputMaybe<StringFilter>;
};

export type TemplateVersionUpdateManyMutationInput = {
  content?: InputMaybe<StringFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  subject?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type TemplateVersionUpdateManyWithoutCreated_ByInput = {
  connect?: InputMaybe<Array<TemplateVersionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TemplateVersionCreateOrConnectWithoutCreated_ByInput>>;
  create?: InputMaybe<Array<TemplateVersionCreateWithoutCreated_ByInput>>;
  createMany?: InputMaybe<TemplateVersionCreateManyCreated_ByInputEnvelope>;
  delete?: InputMaybe<Array<TemplateVersionWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<TemplateVersionScalarWhereInput>>;
  disconnect?: InputMaybe<Array<TemplateVersionWhereUniqueInput>>;
  set?: InputMaybe<Array<TemplateVersionWhereUniqueInput>>;
  update?: InputMaybe<Array<TemplateVersionUpdateWithWhereUniqueWithoutCreated_ByInput>>;
  updateMany?: InputMaybe<Array<TemplateVersionUpdateManyWithWhereWithoutCreated_ByInput>>;
  upsert?: InputMaybe<Array<TemplateVersionUpsertWithWhereUniqueWithoutCreated_ByInput>>;
};

export type TemplateVersionUpdateManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<TemplateVersionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TemplateVersionCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<TemplateVersionCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<TemplateVersionCreateManyOrganizationInputEnvelope>;
  delete?: InputMaybe<Array<TemplateVersionWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<TemplateVersionScalarWhereInput>>;
  disconnect?: InputMaybe<Array<TemplateVersionWhereUniqueInput>>;
  set?: InputMaybe<Array<TemplateVersionWhereUniqueInput>>;
  update?: InputMaybe<Array<TemplateVersionUpdateWithWhereUniqueWithoutOrganizationInput>>;
  updateMany?: InputMaybe<Array<TemplateVersionUpdateManyWithWhereWithoutOrganizationInput>>;
  upsert?: InputMaybe<Array<TemplateVersionUpsertWithWhereUniqueWithoutOrganizationInput>>;
};

export type TemplateVersionUpdateManyWithoutTemplateInput = {
  connect?: InputMaybe<Array<TemplateVersionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TemplateVersionCreateOrConnectWithoutTemplateInput>>;
  create?: InputMaybe<Array<TemplateVersionCreateWithoutTemplateInput>>;
  createMany?: InputMaybe<TemplateVersionCreateManyTemplateInputEnvelope>;
  delete?: InputMaybe<Array<TemplateVersionWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<TemplateVersionScalarWhereInput>>;
  disconnect?: InputMaybe<Array<TemplateVersionWhereUniqueInput>>;
  set?: InputMaybe<Array<TemplateVersionWhereUniqueInput>>;
  update?: InputMaybe<Array<TemplateVersionUpdateWithWhereUniqueWithoutTemplateInput>>;
  updateMany?: InputMaybe<Array<TemplateVersionUpdateManyWithWhereWithoutTemplateInput>>;
  upsert?: InputMaybe<Array<TemplateVersionUpsertWithWhereUniqueWithoutTemplateInput>>;
};

export type TemplateVersionUpdateManyWithWhereWithoutCreated_ByInput = {
  data: TemplateVersionUpdateManyMutationInput;
  where: TemplateVersionScalarWhereInput;
};

export type TemplateVersionUpdateManyWithWhereWithoutOrganizationInput = {
  data: TemplateVersionUpdateManyMutationInput;
  where: TemplateVersionScalarWhereInput;
};

export type TemplateVersionUpdateManyWithWhereWithoutTemplateInput = {
  data: TemplateVersionUpdateManyMutationInput;
  where: TemplateVersionScalarWhereInput;
};

export type TemplateVersionUpdateOneWithoutEmailItemInput = {
  connect?: InputMaybe<TemplateVersionWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TemplateVersionCreateOrConnectWithoutEmailItemInput>;
  create?: InputMaybe<TemplateVersionCreateWithoutEmailItemInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<TemplateVersionUpdateWithoutEmailItemInput>;
  upsert?: InputMaybe<TemplateVersionUpsertWithoutEmailItemInput>;
};

export type TemplateVersionUpdateOneWithoutSmsItemInput = {
  connect?: InputMaybe<TemplateVersionWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TemplateVersionCreateOrConnectWithoutSmsItemInput>;
  create?: InputMaybe<TemplateVersionCreateWithoutSmsItemInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<TemplateVersionUpdateWithoutSmsItemInput>;
  upsert?: InputMaybe<TemplateVersionUpsertWithoutSmsItemInput>;
};

export type TemplateVersionUpdateWithoutCreated_ByInput = {
  content?: InputMaybe<StringFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  EmailItem?: InputMaybe<EmailItemUpdateManyWithoutTemplate_VersionInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  Organization?: InputMaybe<OrganizationUpdateOneWithoutTemplateVersionsInput>;
  SMSItem?: InputMaybe<SmsItemUpdateManyWithoutTemplate_VersionInput>;
  subject?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  template?: InputMaybe<TemplateUpdateOneRequiredWithoutTemplateVersionInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type TemplateVersionUpdateWithoutEmailItemInput = {
  content?: InputMaybe<StringFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  created_by?: InputMaybe<UserUpdateOneRequiredWithoutTemplateVersionInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  Organization?: InputMaybe<OrganizationUpdateOneWithoutTemplateVersionsInput>;
  SMSItem?: InputMaybe<SmsItemUpdateManyWithoutTemplate_VersionInput>;
  subject?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  template?: InputMaybe<TemplateUpdateOneRequiredWithoutTemplateVersionInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type TemplateVersionUpdateWithoutOrganizationInput = {
  content?: InputMaybe<StringFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  created_by?: InputMaybe<UserUpdateOneRequiredWithoutTemplateVersionInput>;
  EmailItem?: InputMaybe<EmailItemUpdateManyWithoutTemplate_VersionInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  SMSItem?: InputMaybe<SmsItemUpdateManyWithoutTemplate_VersionInput>;
  subject?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  template?: InputMaybe<TemplateUpdateOneRequiredWithoutTemplateVersionInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type TemplateVersionUpdateWithoutSmsItemInput = {
  content?: InputMaybe<StringFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  created_by?: InputMaybe<UserUpdateOneRequiredWithoutTemplateVersionInput>;
  EmailItem?: InputMaybe<EmailItemUpdateManyWithoutTemplate_VersionInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  Organization?: InputMaybe<OrganizationUpdateOneWithoutTemplateVersionsInput>;
  subject?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  template?: InputMaybe<TemplateUpdateOneRequiredWithoutTemplateVersionInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type TemplateVersionUpdateWithoutTemplateInput = {
  content?: InputMaybe<StringFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  created_by?: InputMaybe<UserUpdateOneRequiredWithoutTemplateVersionInput>;
  EmailItem?: InputMaybe<EmailItemUpdateManyWithoutTemplate_VersionInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  Organization?: InputMaybe<OrganizationUpdateOneWithoutTemplateVersionsInput>;
  SMSItem?: InputMaybe<SmsItemUpdateManyWithoutTemplate_VersionInput>;
  subject?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type TemplateVersionUpdateWithWhereUniqueWithoutCreated_ByInput = {
  data: TemplateVersionUpdateWithoutCreated_ByInput;
  where: TemplateVersionWhereUniqueInput;
};

export type TemplateVersionUpdateWithWhereUniqueWithoutOrganizationInput = {
  data: TemplateVersionUpdateWithoutOrganizationInput;
  where: TemplateVersionWhereUniqueInput;
};

export type TemplateVersionUpdateWithWhereUniqueWithoutTemplateInput = {
  data: TemplateVersionUpdateWithoutTemplateInput;
  where: TemplateVersionWhereUniqueInput;
};

export type TemplateVersionUpsertWithoutEmailItemInput = {
  create: TemplateVersionCreateWithoutEmailItemInput;
  update: TemplateVersionUpdateWithoutEmailItemInput;
};

export type TemplateVersionUpsertWithoutSmsItemInput = {
  create: TemplateVersionCreateWithoutSmsItemInput;
  update: TemplateVersionUpdateWithoutSmsItemInput;
};

export type TemplateVersionUpsertWithWhereUniqueWithoutCreated_ByInput = {
  create: TemplateVersionCreateWithoutCreated_ByInput;
  update: TemplateVersionUpdateWithoutCreated_ByInput;
  where: TemplateVersionWhereUniqueInput;
};

export type TemplateVersionUpsertWithWhereUniqueWithoutOrganizationInput = {
  create: TemplateVersionCreateWithoutOrganizationInput;
  update: TemplateVersionUpdateWithoutOrganizationInput;
  where: TemplateVersionWhereUniqueInput;
};

export type TemplateVersionUpsertWithWhereUniqueWithoutTemplateInput = {
  create: TemplateVersionCreateWithoutTemplateInput;
  update: TemplateVersionUpdateWithoutTemplateInput;
  where: TemplateVersionWhereUniqueInput;
};

export type TemplateVersionWhereInput = {
  AND?: InputMaybe<Array<TemplateVersionWhereInput>>;
  content?: InputMaybe<StringFilter>;
  created_at?: InputMaybe<DateTimeFilter>;
  created_by?: InputMaybe<UserWhereInput>;
  EmailItem?: InputMaybe<EmailItemListRelationFilter>;
  id?: InputMaybe<StringFilter>;
  NOT?: InputMaybe<Array<TemplateVersionWhereInput>>;
  OR?: InputMaybe<Array<TemplateVersionWhereInput>>;
  Organization?: InputMaybe<OrganizationWhereInput>;
  organization_id?: InputMaybe<StringNullableFilter>;
  SMSItem?: InputMaybe<SmsItemListRelationFilter>;
  subject?: InputMaybe<StringNullableFilter>;
  template?: InputMaybe<TemplateWhereInput>;
  template_id?: InputMaybe<StringFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
  user_id?: InputMaybe<StringFilter>;
};

export type TemplateVersionWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']['input']>;
};

export type TemplateWhereInput = {
  AND?: InputMaybe<Array<TemplateWhereInput>>;
  AutomatedCampaign?: InputMaybe<AutomatedCampaignListRelationFilter>;
  bounced_perc?: InputMaybe<FloatFilter>;
  clicked_perc?: InputMaybe<FloatFilter>;
  content?: InputMaybe<StringFilter>;
  created_at?: InputMaybe<DateTimeFilter>;
  deleted_at?: InputMaybe<DateTimeNullableFilter>;
  delivered_perc?: InputMaybe<FloatFilter>;
  id?: InputMaybe<StringFilter>;
  last_modified_by?: InputMaybe<UserWhereInput>;
  name?: InputMaybe<StringFilter>;
  NOT?: InputMaybe<Array<TemplateWhereInput>>;
  opened_perc?: InputMaybe<FloatFilter>;
  OR?: InputMaybe<Array<TemplateWhereInput>>;
  organization?: InputMaybe<OrganizationWhereInput>;
  organization_id?: InputMaybe<StringFilter>;
  replied_perc?: InputMaybe<FloatFilter>;
  StepAction?: InputMaybe<StepActionListRelationFilter>;
  subject?: InputMaybe<StringNullableFilter>;
  TemplateVersion?: InputMaybe<TemplateVersionListRelationFilter>;
  type?: InputMaybe<EnumTemplateTypeFilter>;
  unsubscribed_perc?: InputMaybe<FloatFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
  user_id?: InputMaybe<StringFilter>;
  visible_roles?: InputMaybe<EnumRoleNullableListFilter>;
  visible_teams?: InputMaybe<StringNullableListFilter>;
  visible_users?: InputMaybe<StringNullableListFilter>;
};

export type TemplateWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']['input']>;
};

export enum Timekittype {
  Google = 'Google',
  Local = 'Local',
  Microsoft = 'Microsoft'
}

export type TimeOffRequest = {
  __typename?: 'TimeOffRequest';
  created_at: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  requesting_user?: Maybe<User>;
  result?: Maybe<Timeoffrequestresult>;
  schedule_item?: Maybe<ScheduleItem>;
  schedule_item_id?: Maybe<Scalars['String']['output']>;
  updated_at: Scalars['DateTime']['output'];
};

export type TimeOffRequestCreateManyRequesting_UserInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  result?: InputMaybe<Timeoffrequestresult>;
  schedule_item_id?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type TimeOffRequestCreateManyRequesting_UserInputEnvelope = {
  data?: InputMaybe<Array<TimeOffRequestCreateManyRequesting_UserInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type TimeOffRequestCreateNestedManyWithoutRequesting_UserInput = {
  connect?: InputMaybe<Array<TimeOffRequestWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TimeOffRequestCreateOrConnectWithoutRequesting_UserInput>>;
  create?: InputMaybe<Array<TimeOffRequestCreateWithoutRequesting_UserInput>>;
  createMany?: InputMaybe<TimeOffRequestCreateManyRequesting_UserInputEnvelope>;
};

export type TimeOffRequestCreateNestedOneWithoutSchedule_ItemInput = {
  connect?: InputMaybe<TimeOffRequestWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TimeOffRequestCreateOrConnectWithoutSchedule_ItemInput>;
  create?: InputMaybe<TimeOffRequestCreateWithoutSchedule_ItemInput>;
};

export type TimeOffRequestCreateOrConnectWithoutRequesting_UserInput = {
  create: TimeOffRequestCreateWithoutRequesting_UserInput;
  where: TimeOffRequestWhereUniqueInput;
};

export type TimeOffRequestCreateOrConnectWithoutSchedule_ItemInput = {
  create: TimeOffRequestCreateWithoutSchedule_ItemInput;
  where: TimeOffRequestWhereUniqueInput;
};

export type TimeOffRequestCreateWithoutRequesting_UserInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  result?: InputMaybe<Timeoffrequestresult>;
  schedule_item?: InputMaybe<ScheduleItemCreateNestedOneWithoutTimeoff_RequestInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type TimeOffRequestCreateWithoutSchedule_ItemInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  requesting_user?: InputMaybe<UserCreateNestedOneWithoutTimeOffRequestInput>;
  result?: InputMaybe<Timeoffrequestresult>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type TimeOffRequestListRelationFilter = {
  every?: InputMaybe<TimeOffRequestWhereInput>;
  none?: InputMaybe<TimeOffRequestWhereInput>;
  some?: InputMaybe<TimeOffRequestWhereInput>;
};

export type TimeOffRequestOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
  count?: InputMaybe<SortOrder>;
};

export type TimeOffRequestOrderByWithRelationInput = {
  created_at?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  requesting_user?: InputMaybe<UserOrderByWithRelationInput>;
  result?: InputMaybe<SortOrder>;
  schedule_item?: InputMaybe<ScheduleItemOrderByWithRelationInput>;
  schedule_item_id?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
  user_id?: InputMaybe<SortOrder>;
};

export enum Timeoffrequestresult {
  Accepted = 'Accepted',
  Declined = 'Declined'
}

export type TimeOffRequestScalarWhereInput = {
  AND?: InputMaybe<Array<TimeOffRequestScalarWhereInput>>;
  created_at?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  NOT?: InputMaybe<Array<TimeOffRequestScalarWhereInput>>;
  OR?: InputMaybe<Array<TimeOffRequestScalarWhereInput>>;
  result?: InputMaybe<EnumTimeoffrequestresultNullableFilter>;
  schedule_item_id?: InputMaybe<StringNullableFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
  user_id?: InputMaybe<StringNullableFilter>;
};

export type TimeOffRequestUpdateManyMutationInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  result?: InputMaybe<NullableEnumTimeoffrequestresultFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type TimeOffRequestUpdateManyWithoutRequesting_UserInput = {
  connect?: InputMaybe<Array<TimeOffRequestWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TimeOffRequestCreateOrConnectWithoutRequesting_UserInput>>;
  create?: InputMaybe<Array<TimeOffRequestCreateWithoutRequesting_UserInput>>;
  createMany?: InputMaybe<TimeOffRequestCreateManyRequesting_UserInputEnvelope>;
  delete?: InputMaybe<Array<TimeOffRequestWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<TimeOffRequestScalarWhereInput>>;
  disconnect?: InputMaybe<Array<TimeOffRequestWhereUniqueInput>>;
  set?: InputMaybe<Array<TimeOffRequestWhereUniqueInput>>;
  update?: InputMaybe<Array<TimeOffRequestUpdateWithWhereUniqueWithoutRequesting_UserInput>>;
  updateMany?: InputMaybe<Array<TimeOffRequestUpdateManyWithWhereWithoutRequesting_UserInput>>;
  upsert?: InputMaybe<Array<TimeOffRequestUpsertWithWhereUniqueWithoutRequesting_UserInput>>;
};

export type TimeOffRequestUpdateManyWithWhereWithoutRequesting_UserInput = {
  data: TimeOffRequestUpdateManyMutationInput;
  where: TimeOffRequestScalarWhereInput;
};

export type TimeOffRequestUpdateOneWithoutSchedule_ItemInput = {
  connect?: InputMaybe<TimeOffRequestWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TimeOffRequestCreateOrConnectWithoutSchedule_ItemInput>;
  create?: InputMaybe<TimeOffRequestCreateWithoutSchedule_ItemInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<TimeOffRequestUpdateWithoutSchedule_ItemInput>;
  upsert?: InputMaybe<TimeOffRequestUpsertWithoutSchedule_ItemInput>;
};

export type TimeOffRequestUpdateWithoutRequesting_UserInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  result?: InputMaybe<NullableEnumTimeoffrequestresultFieldUpdateOperationsInput>;
  schedule_item?: InputMaybe<ScheduleItemUpdateOneWithoutTimeoff_RequestInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type TimeOffRequestUpdateWithoutSchedule_ItemInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  requesting_user?: InputMaybe<UserUpdateOneWithoutTimeOffRequestInput>;
  result?: InputMaybe<NullableEnumTimeoffrequestresultFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type TimeOffRequestUpdateWithWhereUniqueWithoutRequesting_UserInput = {
  data: TimeOffRequestUpdateWithoutRequesting_UserInput;
  where: TimeOffRequestWhereUniqueInput;
};

export type TimeOffRequestUpsertWithoutSchedule_ItemInput = {
  create: TimeOffRequestCreateWithoutSchedule_ItemInput;
  update: TimeOffRequestUpdateWithoutSchedule_ItemInput;
};

export type TimeOffRequestUpsertWithWhereUniqueWithoutRequesting_UserInput = {
  create: TimeOffRequestCreateWithoutRequesting_UserInput;
  update: TimeOffRequestUpdateWithoutRequesting_UserInput;
  where: TimeOffRequestWhereUniqueInput;
};

export type TimeOffRequestWhereInput = {
  AND?: InputMaybe<Array<TimeOffRequestWhereInput>>;
  created_at?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  NOT?: InputMaybe<Array<TimeOffRequestWhereInput>>;
  OR?: InputMaybe<Array<TimeOffRequestWhereInput>>;
  requesting_user?: InputMaybe<UserWhereInput>;
  result?: InputMaybe<EnumTimeoffrequestresultNullableFilter>;
  schedule_item?: InputMaybe<ScheduleItemWhereInput>;
  schedule_item_id?: InputMaybe<StringNullableFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
  user_id?: InputMaybe<StringNullableFilter>;
};

export type TimeOffRequestWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  schedule_item_id?: InputMaybe<Scalars['String']['input']>;
};

export enum TransferActionResult {
  Hold = 'Hold',
  Na = 'NA',
  Sale = 'Sale'
}

export type TransferAttempt = {
  __typename?: 'TransferAttempt';
  auto_transfer_selection?: Maybe<AutoTransferSelection>;
  callSid?: Maybe<Scalars['String']['output']>;
  conference: Conference;
  conference_id: Scalars['String']['output'];
  created_at: Scalars['DateTime']['output'];
  from_user?: Maybe<User>;
  from_user_id?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  status: TransferAttemptStatus;
  to_user?: Maybe<User>;
  to_user_id?: Maybe<Scalars['String']['output']>;
  transfer_result: TransferActionResult;
  updated_at: Scalars['DateTime']['output'];
};

export type TransferAttemptCreateManyConferenceInput = {
  callSid?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  end_time?: InputMaybe<Scalars['DateTime']['input']>;
  from_user_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  organization_id?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<TransferAttemptStatus>;
  to_user_id?: InputMaybe<Scalars['String']['input']>;
  transfer_result?: InputMaybe<TransferActionResult>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type TransferAttemptCreateManyConferenceInputEnvelope = {
  data?: InputMaybe<Array<TransferAttemptCreateManyConferenceInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type TransferAttemptCreateManyFrom_UserInput = {
  callSid?: InputMaybe<Scalars['String']['input']>;
  conference_id: Scalars['String']['input'];
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  end_time?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  organization_id?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<TransferAttemptStatus>;
  to_user_id?: InputMaybe<Scalars['String']['input']>;
  transfer_result?: InputMaybe<TransferActionResult>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type TransferAttemptCreateManyFrom_UserInputEnvelope = {
  data?: InputMaybe<Array<TransferAttemptCreateManyFrom_UserInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type TransferAttemptCreateManyOrganizationInput = {
  callSid?: InputMaybe<Scalars['String']['input']>;
  conference_id: Scalars['String']['input'];
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  end_time?: InputMaybe<Scalars['DateTime']['input']>;
  from_user_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<TransferAttemptStatus>;
  to_user_id?: InputMaybe<Scalars['String']['input']>;
  transfer_result?: InputMaybe<TransferActionResult>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type TransferAttemptCreateManyOrganizationInputEnvelope = {
  data?: InputMaybe<Array<TransferAttemptCreateManyOrganizationInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type TransferAttemptCreateManyTo_UserInput = {
  callSid?: InputMaybe<Scalars['String']['input']>;
  conference_id: Scalars['String']['input'];
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  end_time?: InputMaybe<Scalars['DateTime']['input']>;
  from_user_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  organization_id?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<TransferAttemptStatus>;
  transfer_result?: InputMaybe<TransferActionResult>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type TransferAttemptCreateManyTo_UserInputEnvelope = {
  data?: InputMaybe<Array<TransferAttemptCreateManyTo_UserInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type TransferAttemptCreateNestedManyWithoutConferenceInput = {
  connect?: InputMaybe<Array<TransferAttemptWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TransferAttemptCreateOrConnectWithoutConferenceInput>>;
  create?: InputMaybe<Array<TransferAttemptCreateWithoutConferenceInput>>;
  createMany?: InputMaybe<TransferAttemptCreateManyConferenceInputEnvelope>;
};

export type TransferAttemptCreateNestedManyWithoutFrom_UserInput = {
  connect?: InputMaybe<Array<TransferAttemptWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TransferAttemptCreateOrConnectWithoutFrom_UserInput>>;
  create?: InputMaybe<Array<TransferAttemptCreateWithoutFrom_UserInput>>;
  createMany?: InputMaybe<TransferAttemptCreateManyFrom_UserInputEnvelope>;
};

export type TransferAttemptCreateNestedManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<TransferAttemptWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TransferAttemptCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<TransferAttemptCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<TransferAttemptCreateManyOrganizationInputEnvelope>;
};

export type TransferAttemptCreateNestedManyWithoutTo_UserInput = {
  connect?: InputMaybe<Array<TransferAttemptWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TransferAttemptCreateOrConnectWithoutTo_UserInput>>;
  create?: InputMaybe<Array<TransferAttemptCreateWithoutTo_UserInput>>;
  createMany?: InputMaybe<TransferAttemptCreateManyTo_UserInputEnvelope>;
};

export type TransferAttemptCreateNestedOneWithoutAuto_Transfer_SelectionInput = {
  connect?: InputMaybe<TransferAttemptWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TransferAttemptCreateOrConnectWithoutAuto_Transfer_SelectionInput>;
  create?: InputMaybe<TransferAttemptCreateWithoutAuto_Transfer_SelectionInput>;
};

export type TransferAttemptCreateOrConnectWithoutAuto_Transfer_SelectionInput = {
  create: TransferAttemptCreateWithoutAuto_Transfer_SelectionInput;
  where: TransferAttemptWhereUniqueInput;
};

export type TransferAttemptCreateOrConnectWithoutConferenceInput = {
  create: TransferAttemptCreateWithoutConferenceInput;
  where: TransferAttemptWhereUniqueInput;
};

export type TransferAttemptCreateOrConnectWithoutFrom_UserInput = {
  create: TransferAttemptCreateWithoutFrom_UserInput;
  where: TransferAttemptWhereUniqueInput;
};

export type TransferAttemptCreateOrConnectWithoutOrganizationInput = {
  create: TransferAttemptCreateWithoutOrganizationInput;
  where: TransferAttemptWhereUniqueInput;
};

export type TransferAttemptCreateOrConnectWithoutTo_UserInput = {
  create: TransferAttemptCreateWithoutTo_UserInput;
  where: TransferAttemptWhereUniqueInput;
};

export type TransferAttemptCreateWithoutAuto_Transfer_SelectionInput = {
  callSid?: InputMaybe<Scalars['String']['input']>;
  conference: ConferenceCreateNestedOneWithoutTransfer_AttemptsInput;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  end_time?: InputMaybe<Scalars['DateTime']['input']>;
  from_user?: InputMaybe<UserCreateNestedOneWithoutSend_Transfer_AttemptsInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  Organization?: InputMaybe<OrganizationCreateNestedOneWithoutTransferAttemptsInput>;
  status?: InputMaybe<TransferAttemptStatus>;
  to_user?: InputMaybe<UserCreateNestedOneWithoutReceive_Transfer_AttemptsInput>;
  transfer_result?: InputMaybe<TransferActionResult>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type TransferAttemptCreateWithoutConferenceInput = {
  auto_transfer_selection?: InputMaybe<AutoTransferSelectionCreateNestedOneWithoutTransfer_AttemptInput>;
  callSid?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  end_time?: InputMaybe<Scalars['DateTime']['input']>;
  from_user?: InputMaybe<UserCreateNestedOneWithoutSend_Transfer_AttemptsInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  Organization?: InputMaybe<OrganizationCreateNestedOneWithoutTransferAttemptsInput>;
  status?: InputMaybe<TransferAttemptStatus>;
  to_user?: InputMaybe<UserCreateNestedOneWithoutReceive_Transfer_AttemptsInput>;
  transfer_result?: InputMaybe<TransferActionResult>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type TransferAttemptCreateWithoutFrom_UserInput = {
  auto_transfer_selection?: InputMaybe<AutoTransferSelectionCreateNestedOneWithoutTransfer_AttemptInput>;
  callSid?: InputMaybe<Scalars['String']['input']>;
  conference: ConferenceCreateNestedOneWithoutTransfer_AttemptsInput;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  end_time?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  Organization?: InputMaybe<OrganizationCreateNestedOneWithoutTransferAttemptsInput>;
  status?: InputMaybe<TransferAttemptStatus>;
  to_user?: InputMaybe<UserCreateNestedOneWithoutReceive_Transfer_AttemptsInput>;
  transfer_result?: InputMaybe<TransferActionResult>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type TransferAttemptCreateWithoutOrganizationInput = {
  auto_transfer_selection?: InputMaybe<AutoTransferSelectionCreateNestedOneWithoutTransfer_AttemptInput>;
  callSid?: InputMaybe<Scalars['String']['input']>;
  conference: ConferenceCreateNestedOneWithoutTransfer_AttemptsInput;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  end_time?: InputMaybe<Scalars['DateTime']['input']>;
  from_user?: InputMaybe<UserCreateNestedOneWithoutSend_Transfer_AttemptsInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<TransferAttemptStatus>;
  to_user?: InputMaybe<UserCreateNestedOneWithoutReceive_Transfer_AttemptsInput>;
  transfer_result?: InputMaybe<TransferActionResult>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type TransferAttemptCreateWithoutTo_UserInput = {
  auto_transfer_selection?: InputMaybe<AutoTransferSelectionCreateNestedOneWithoutTransfer_AttemptInput>;
  callSid?: InputMaybe<Scalars['String']['input']>;
  conference: ConferenceCreateNestedOneWithoutTransfer_AttemptsInput;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  end_time?: InputMaybe<Scalars['DateTime']['input']>;
  from_user?: InputMaybe<UserCreateNestedOneWithoutSend_Transfer_AttemptsInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  Organization?: InputMaybe<OrganizationCreateNestedOneWithoutTransferAttemptsInput>;
  status?: InputMaybe<TransferAttemptStatus>;
  transfer_result?: InputMaybe<TransferActionResult>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type TransferAttemptListRelationFilter = {
  every?: InputMaybe<TransferAttemptWhereInput>;
  none?: InputMaybe<TransferAttemptWhereInput>;
  some?: InputMaybe<TransferAttemptWhereInput>;
};

export type TransferAttemptOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
  count?: InputMaybe<SortOrder>;
};

export type TransferAttemptScalarWhereInput = {
  AND?: InputMaybe<Array<TransferAttemptScalarWhereInput>>;
  callSid?: InputMaybe<StringNullableFilter>;
  conference_id?: InputMaybe<StringFilter>;
  created_at?: InputMaybe<DateTimeFilter>;
  end_time?: InputMaybe<DateTimeNullableFilter>;
  from_user_id?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  NOT?: InputMaybe<Array<TransferAttemptScalarWhereInput>>;
  OR?: InputMaybe<Array<TransferAttemptScalarWhereInput>>;
  organization_id?: InputMaybe<StringNullableFilter>;
  status?: InputMaybe<EnumTransferAttemptStatusFilter>;
  to_user_id?: InputMaybe<StringNullableFilter>;
  transfer_result?: InputMaybe<EnumTransferActionResultFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
};

export enum TransferAttemptStatus {
  Cancelled = 'Cancelled',
  Fail = 'Fail',
  Idle = 'Idle',
  InProgress = 'InProgress',
  Rejected = 'Rejected',
  Success = 'Success'
}

export type TransferAttemptUpdateManyMutationInput = {
  callSid?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  end_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumTransferAttemptStatusFieldUpdateOperationsInput>;
  transfer_result?: InputMaybe<EnumTransferActionResultFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type TransferAttemptUpdateManyWithoutConferenceInput = {
  connect?: InputMaybe<Array<TransferAttemptWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TransferAttemptCreateOrConnectWithoutConferenceInput>>;
  create?: InputMaybe<Array<TransferAttemptCreateWithoutConferenceInput>>;
  createMany?: InputMaybe<TransferAttemptCreateManyConferenceInputEnvelope>;
  delete?: InputMaybe<Array<TransferAttemptWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<TransferAttemptScalarWhereInput>>;
  disconnect?: InputMaybe<Array<TransferAttemptWhereUniqueInput>>;
  set?: InputMaybe<Array<TransferAttemptWhereUniqueInput>>;
  update?: InputMaybe<Array<TransferAttemptUpdateWithWhereUniqueWithoutConferenceInput>>;
  updateMany?: InputMaybe<Array<TransferAttemptUpdateManyWithWhereWithoutConferenceInput>>;
  upsert?: InputMaybe<Array<TransferAttemptUpsertWithWhereUniqueWithoutConferenceInput>>;
};

export type TransferAttemptUpdateManyWithoutFrom_UserInput = {
  connect?: InputMaybe<Array<TransferAttemptWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TransferAttemptCreateOrConnectWithoutFrom_UserInput>>;
  create?: InputMaybe<Array<TransferAttemptCreateWithoutFrom_UserInput>>;
  createMany?: InputMaybe<TransferAttemptCreateManyFrom_UserInputEnvelope>;
  delete?: InputMaybe<Array<TransferAttemptWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<TransferAttemptScalarWhereInput>>;
  disconnect?: InputMaybe<Array<TransferAttemptWhereUniqueInput>>;
  set?: InputMaybe<Array<TransferAttemptWhereUniqueInput>>;
  update?: InputMaybe<Array<TransferAttemptUpdateWithWhereUniqueWithoutFrom_UserInput>>;
  updateMany?: InputMaybe<Array<TransferAttemptUpdateManyWithWhereWithoutFrom_UserInput>>;
  upsert?: InputMaybe<Array<TransferAttemptUpsertWithWhereUniqueWithoutFrom_UserInput>>;
};

export type TransferAttemptUpdateManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<TransferAttemptWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TransferAttemptCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<TransferAttemptCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<TransferAttemptCreateManyOrganizationInputEnvelope>;
  delete?: InputMaybe<Array<TransferAttemptWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<TransferAttemptScalarWhereInput>>;
  disconnect?: InputMaybe<Array<TransferAttemptWhereUniqueInput>>;
  set?: InputMaybe<Array<TransferAttemptWhereUniqueInput>>;
  update?: InputMaybe<Array<TransferAttemptUpdateWithWhereUniqueWithoutOrganizationInput>>;
  updateMany?: InputMaybe<Array<TransferAttemptUpdateManyWithWhereWithoutOrganizationInput>>;
  upsert?: InputMaybe<Array<TransferAttemptUpsertWithWhereUniqueWithoutOrganizationInput>>;
};

export type TransferAttemptUpdateManyWithoutTo_UserInput = {
  connect?: InputMaybe<Array<TransferAttemptWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TransferAttemptCreateOrConnectWithoutTo_UserInput>>;
  create?: InputMaybe<Array<TransferAttemptCreateWithoutTo_UserInput>>;
  createMany?: InputMaybe<TransferAttemptCreateManyTo_UserInputEnvelope>;
  delete?: InputMaybe<Array<TransferAttemptWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<TransferAttemptScalarWhereInput>>;
  disconnect?: InputMaybe<Array<TransferAttemptWhereUniqueInput>>;
  set?: InputMaybe<Array<TransferAttemptWhereUniqueInput>>;
  update?: InputMaybe<Array<TransferAttemptUpdateWithWhereUniqueWithoutTo_UserInput>>;
  updateMany?: InputMaybe<Array<TransferAttemptUpdateManyWithWhereWithoutTo_UserInput>>;
  upsert?: InputMaybe<Array<TransferAttemptUpsertWithWhereUniqueWithoutTo_UserInput>>;
};

export type TransferAttemptUpdateManyWithWhereWithoutConferenceInput = {
  data: TransferAttemptUpdateManyMutationInput;
  where: TransferAttemptScalarWhereInput;
};

export type TransferAttemptUpdateManyWithWhereWithoutFrom_UserInput = {
  data: TransferAttemptUpdateManyMutationInput;
  where: TransferAttemptScalarWhereInput;
};

export type TransferAttemptUpdateManyWithWhereWithoutOrganizationInput = {
  data: TransferAttemptUpdateManyMutationInput;
  where: TransferAttemptScalarWhereInput;
};

export type TransferAttemptUpdateManyWithWhereWithoutTo_UserInput = {
  data: TransferAttemptUpdateManyMutationInput;
  where: TransferAttemptScalarWhereInput;
};

export type TransferAttemptUpdateOneWithoutAuto_Transfer_SelectionInput = {
  connect?: InputMaybe<TransferAttemptWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TransferAttemptCreateOrConnectWithoutAuto_Transfer_SelectionInput>;
  create?: InputMaybe<TransferAttemptCreateWithoutAuto_Transfer_SelectionInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<TransferAttemptUpdateWithoutAuto_Transfer_SelectionInput>;
  upsert?: InputMaybe<TransferAttemptUpsertWithoutAuto_Transfer_SelectionInput>;
};

export type TransferAttemptUpdateWithoutAuto_Transfer_SelectionInput = {
  callSid?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  conference?: InputMaybe<ConferenceUpdateOneRequiredWithoutTransfer_AttemptsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  end_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  from_user?: InputMaybe<UserUpdateOneWithoutSend_Transfer_AttemptsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  Organization?: InputMaybe<OrganizationUpdateOneWithoutTransferAttemptsInput>;
  status?: InputMaybe<EnumTransferAttemptStatusFieldUpdateOperationsInput>;
  to_user?: InputMaybe<UserUpdateOneWithoutReceive_Transfer_AttemptsInput>;
  transfer_result?: InputMaybe<EnumTransferActionResultFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type TransferAttemptUpdateWithoutConferenceInput = {
  auto_transfer_selection?: InputMaybe<AutoTransferSelectionUpdateOneWithoutTransfer_AttemptInput>;
  callSid?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  end_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  from_user?: InputMaybe<UserUpdateOneWithoutSend_Transfer_AttemptsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  Organization?: InputMaybe<OrganizationUpdateOneWithoutTransferAttemptsInput>;
  status?: InputMaybe<EnumTransferAttemptStatusFieldUpdateOperationsInput>;
  to_user?: InputMaybe<UserUpdateOneWithoutReceive_Transfer_AttemptsInput>;
  transfer_result?: InputMaybe<EnumTransferActionResultFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type TransferAttemptUpdateWithoutFrom_UserInput = {
  auto_transfer_selection?: InputMaybe<AutoTransferSelectionUpdateOneWithoutTransfer_AttemptInput>;
  callSid?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  conference?: InputMaybe<ConferenceUpdateOneRequiredWithoutTransfer_AttemptsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  end_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  Organization?: InputMaybe<OrganizationUpdateOneWithoutTransferAttemptsInput>;
  status?: InputMaybe<EnumTransferAttemptStatusFieldUpdateOperationsInput>;
  to_user?: InputMaybe<UserUpdateOneWithoutReceive_Transfer_AttemptsInput>;
  transfer_result?: InputMaybe<EnumTransferActionResultFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type TransferAttemptUpdateWithoutOrganizationInput = {
  auto_transfer_selection?: InputMaybe<AutoTransferSelectionUpdateOneWithoutTransfer_AttemptInput>;
  callSid?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  conference?: InputMaybe<ConferenceUpdateOneRequiredWithoutTransfer_AttemptsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  end_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  from_user?: InputMaybe<UserUpdateOneWithoutSend_Transfer_AttemptsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumTransferAttemptStatusFieldUpdateOperationsInput>;
  to_user?: InputMaybe<UserUpdateOneWithoutReceive_Transfer_AttemptsInput>;
  transfer_result?: InputMaybe<EnumTransferActionResultFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type TransferAttemptUpdateWithoutTo_UserInput = {
  auto_transfer_selection?: InputMaybe<AutoTransferSelectionUpdateOneWithoutTransfer_AttemptInput>;
  callSid?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  conference?: InputMaybe<ConferenceUpdateOneRequiredWithoutTransfer_AttemptsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  end_time?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  from_user?: InputMaybe<UserUpdateOneWithoutSend_Transfer_AttemptsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  Organization?: InputMaybe<OrganizationUpdateOneWithoutTransferAttemptsInput>;
  status?: InputMaybe<EnumTransferAttemptStatusFieldUpdateOperationsInput>;
  transfer_result?: InputMaybe<EnumTransferActionResultFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type TransferAttemptUpdateWithWhereUniqueWithoutConferenceInput = {
  data: TransferAttemptUpdateWithoutConferenceInput;
  where: TransferAttemptWhereUniqueInput;
};

export type TransferAttemptUpdateWithWhereUniqueWithoutFrom_UserInput = {
  data: TransferAttemptUpdateWithoutFrom_UserInput;
  where: TransferAttemptWhereUniqueInput;
};

export type TransferAttemptUpdateWithWhereUniqueWithoutOrganizationInput = {
  data: TransferAttemptUpdateWithoutOrganizationInput;
  where: TransferAttemptWhereUniqueInput;
};

export type TransferAttemptUpdateWithWhereUniqueWithoutTo_UserInput = {
  data: TransferAttemptUpdateWithoutTo_UserInput;
  where: TransferAttemptWhereUniqueInput;
};

export type TransferAttemptUpsertWithoutAuto_Transfer_SelectionInput = {
  create: TransferAttemptCreateWithoutAuto_Transfer_SelectionInput;
  update: TransferAttemptUpdateWithoutAuto_Transfer_SelectionInput;
};

export type TransferAttemptUpsertWithWhereUniqueWithoutConferenceInput = {
  create: TransferAttemptCreateWithoutConferenceInput;
  update: TransferAttemptUpdateWithoutConferenceInput;
  where: TransferAttemptWhereUniqueInput;
};

export type TransferAttemptUpsertWithWhereUniqueWithoutFrom_UserInput = {
  create: TransferAttemptCreateWithoutFrom_UserInput;
  update: TransferAttemptUpdateWithoutFrom_UserInput;
  where: TransferAttemptWhereUniqueInput;
};

export type TransferAttemptUpsertWithWhereUniqueWithoutOrganizationInput = {
  create: TransferAttemptCreateWithoutOrganizationInput;
  update: TransferAttemptUpdateWithoutOrganizationInput;
  where: TransferAttemptWhereUniqueInput;
};

export type TransferAttemptUpsertWithWhereUniqueWithoutTo_UserInput = {
  create: TransferAttemptCreateWithoutTo_UserInput;
  update: TransferAttemptUpdateWithoutTo_UserInput;
  where: TransferAttemptWhereUniqueInput;
};

export type TransferAttemptWhereInput = {
  AND?: InputMaybe<Array<TransferAttemptWhereInput>>;
  auto_transfer_selection?: InputMaybe<AutoTransferSelectionWhereInput>;
  callSid?: InputMaybe<StringNullableFilter>;
  conference?: InputMaybe<ConferenceWhereInput>;
  conference_id?: InputMaybe<StringFilter>;
  created_at?: InputMaybe<DateTimeFilter>;
  end_time?: InputMaybe<DateTimeNullableFilter>;
  from_user?: InputMaybe<UserWhereInput>;
  from_user_id?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  NOT?: InputMaybe<Array<TransferAttemptWhereInput>>;
  OR?: InputMaybe<Array<TransferAttemptWhereInput>>;
  Organization?: InputMaybe<OrganizationWhereInput>;
  organization_id?: InputMaybe<StringNullableFilter>;
  status?: InputMaybe<EnumTransferAttemptStatusFilter>;
  to_user?: InputMaybe<UserWhereInput>;
  to_user_id?: InputMaybe<StringNullableFilter>;
  transfer_result?: InputMaybe<EnumTransferActionResultFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
};

export type TransferAttemptWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']['input']>;
};

/** TransferRuleInput for create / update */
export type TransferRuleInput = {
  conditions: Array<RoutingCondition>;
  date_range?: InputMaybe<DateOption>;
  distribution_method: Distribution_Method;
  entity_type: EntityType;
  metric?: InputMaybe<Metric>;
  name: Scalars['String']['input'];
  rank_sort_type?: InputMaybe<RankSortType>;
  rep_ids: Array<Scalars['String']['input']>;
  rule_type?: InputMaybe<RuleType>;
};

export enum TransferRuleType {
  FutureBooking = 'FutureBooking',
  LiveTransfer = 'LiveTransfer'
}

/** Transfer Sort Options */
export enum TransferSortOption {
  CloseRate = 'CloseRate',
  SiteName = 'SiteName'
}

export enum TransferType {
  Auto = 'Auto',
  Manual = 'Manual'
}

export type TwilioFeedback = {
  __typename?: 'TwilioFeedback';
  category_reason: Array<Scalars['String']['output']>;
  conference: Conference;
  conference_id: Scalars['String']['output'];
  created_at: Scalars['DateTime']['output'];
  feedback_text?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  quality: Scalars['Int']['output'];
  updated_at: Scalars['DateTime']['output'];
};

export type TwilioFeedbackCreatecategory_ReasonInput = {
  set?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type TwilioFeedbackCreateManycategory_ReasonInput = {
  set?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type TwilioFeedbackCreateManyOrganizationInput = {
  category_reason?: InputMaybe<TwilioFeedbackCreateManycategory_ReasonInput>;
  conference_id: Scalars['String']['input'];
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  feedback_text?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  quality: Scalars['Int']['input'];
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type TwilioFeedbackCreateManyOrganizationInputEnvelope = {
  data?: InputMaybe<Array<TwilioFeedbackCreateManyOrganizationInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type TwilioFeedbackCreateNestedManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<TwilioFeedbackWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TwilioFeedbackCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<TwilioFeedbackCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<TwilioFeedbackCreateManyOrganizationInputEnvelope>;
};

export type TwilioFeedbackCreateNestedOneWithoutConferenceInput = {
  connect?: InputMaybe<TwilioFeedbackWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TwilioFeedbackCreateOrConnectWithoutConferenceInput>;
  create?: InputMaybe<TwilioFeedbackCreateWithoutConferenceInput>;
};

export type TwilioFeedbackCreateOrConnectWithoutConferenceInput = {
  create: TwilioFeedbackCreateWithoutConferenceInput;
  where: TwilioFeedbackWhereUniqueInput;
};

export type TwilioFeedbackCreateOrConnectWithoutOrganizationInput = {
  create: TwilioFeedbackCreateWithoutOrganizationInput;
  where: TwilioFeedbackWhereUniqueInput;
};

export type TwilioFeedbackCreateWithoutConferenceInput = {
  category_reason?: InputMaybe<TwilioFeedbackCreatecategory_ReasonInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  feedback_text?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  Organization?: InputMaybe<OrganizationCreateNestedOneWithoutTwilioFeedbacksInput>;
  quality: Scalars['Int']['input'];
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type TwilioFeedbackCreateWithoutOrganizationInput = {
  category_reason?: InputMaybe<TwilioFeedbackCreatecategory_ReasonInput>;
  conference: ConferenceCreateNestedOneWithoutTwilioFeedbackInput;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  feedback_text?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  quality: Scalars['Int']['input'];
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type TwilioFeedbackListRelationFilter = {
  every?: InputMaybe<TwilioFeedbackWhereInput>;
  none?: InputMaybe<TwilioFeedbackWhereInput>;
  some?: InputMaybe<TwilioFeedbackWhereInput>;
};

export type TwilioFeedbackOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
  count?: InputMaybe<SortOrder>;
};

export type TwilioFeedbackOrderByWithRelationInput = {
  category_reason?: InputMaybe<SortOrder>;
  conference?: InputMaybe<ConferenceOrderByWithRelationInput>;
  conference_id?: InputMaybe<SortOrder>;
  created_at?: InputMaybe<SortOrder>;
  feedback_text?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  Organization?: InputMaybe<OrganizationOrderByWithRelationInput>;
  organization_id?: InputMaybe<SortOrder>;
  quality?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
};

export type TwilioFeedbackScalarWhereInput = {
  AND?: InputMaybe<Array<TwilioFeedbackScalarWhereInput>>;
  category_reason?: InputMaybe<StringNullableListFilter>;
  conference_id?: InputMaybe<StringFilter>;
  created_at?: InputMaybe<DateTimeFilter>;
  feedback_text?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  NOT?: InputMaybe<Array<TwilioFeedbackScalarWhereInput>>;
  OR?: InputMaybe<Array<TwilioFeedbackScalarWhereInput>>;
  organization_id?: InputMaybe<StringNullableFilter>;
  quality?: InputMaybe<IntFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
};

export type TwilioFeedbackUpdatecategory_ReasonInput = {
  push?: InputMaybe<Scalars['String']['input']>;
  set?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type TwilioFeedbackUpdateManyMutationInput = {
  category_reason?: InputMaybe<TwilioFeedbackUpdatecategory_ReasonInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  feedback_text?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  quality?: InputMaybe<IntFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type TwilioFeedbackUpdateManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<TwilioFeedbackWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TwilioFeedbackCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<TwilioFeedbackCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<TwilioFeedbackCreateManyOrganizationInputEnvelope>;
  delete?: InputMaybe<Array<TwilioFeedbackWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<TwilioFeedbackScalarWhereInput>>;
  disconnect?: InputMaybe<Array<TwilioFeedbackWhereUniqueInput>>;
  set?: InputMaybe<Array<TwilioFeedbackWhereUniqueInput>>;
  update?: InputMaybe<Array<TwilioFeedbackUpdateWithWhereUniqueWithoutOrganizationInput>>;
  updateMany?: InputMaybe<Array<TwilioFeedbackUpdateManyWithWhereWithoutOrganizationInput>>;
  upsert?: InputMaybe<Array<TwilioFeedbackUpsertWithWhereUniqueWithoutOrganizationInput>>;
};

export type TwilioFeedbackUpdateManyWithWhereWithoutOrganizationInput = {
  data: TwilioFeedbackUpdateManyMutationInput;
  where: TwilioFeedbackScalarWhereInput;
};

export type TwilioFeedbackUpdateOneWithoutConferenceInput = {
  connect?: InputMaybe<TwilioFeedbackWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TwilioFeedbackCreateOrConnectWithoutConferenceInput>;
  create?: InputMaybe<TwilioFeedbackCreateWithoutConferenceInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<TwilioFeedbackUpdateWithoutConferenceInput>;
  upsert?: InputMaybe<TwilioFeedbackUpsertWithoutConferenceInput>;
};

export type TwilioFeedbackUpdateWithoutConferenceInput = {
  category_reason?: InputMaybe<TwilioFeedbackUpdatecategory_ReasonInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  feedback_text?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  Organization?: InputMaybe<OrganizationUpdateOneWithoutTwilioFeedbacksInput>;
  quality?: InputMaybe<IntFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type TwilioFeedbackUpdateWithoutOrganizationInput = {
  category_reason?: InputMaybe<TwilioFeedbackUpdatecategory_ReasonInput>;
  conference?: InputMaybe<ConferenceUpdateOneRequiredWithoutTwilioFeedbackInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  feedback_text?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  quality?: InputMaybe<IntFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type TwilioFeedbackUpdateWithWhereUniqueWithoutOrganizationInput = {
  data: TwilioFeedbackUpdateWithoutOrganizationInput;
  where: TwilioFeedbackWhereUniqueInput;
};

export type TwilioFeedbackUpsertWithoutConferenceInput = {
  create: TwilioFeedbackCreateWithoutConferenceInput;
  update: TwilioFeedbackUpdateWithoutConferenceInput;
};

export type TwilioFeedbackUpsertWithWhereUniqueWithoutOrganizationInput = {
  create: TwilioFeedbackCreateWithoutOrganizationInput;
  update: TwilioFeedbackUpdateWithoutOrganizationInput;
  where: TwilioFeedbackWhereUniqueInput;
};

export type TwilioFeedbackWhereInput = {
  AND?: InputMaybe<Array<TwilioFeedbackWhereInput>>;
  category_reason?: InputMaybe<StringNullableListFilter>;
  conference?: InputMaybe<ConferenceWhereInput>;
  conference_id?: InputMaybe<StringFilter>;
  created_at?: InputMaybe<DateTimeFilter>;
  feedback_text?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  NOT?: InputMaybe<Array<TwilioFeedbackWhereInput>>;
  OR?: InputMaybe<Array<TwilioFeedbackWhereInput>>;
  Organization?: InputMaybe<OrganizationWhereInput>;
  organization_id?: InputMaybe<StringNullableFilter>;
  quality?: InputMaybe<IntFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
};

export type TwilioFeedbackWhereUniqueInput = {
  conference_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
};

export type TwilioInfo = {
  __typename?: 'TwilioInfo';
  accountSid: Scalars['String']['output'];
  authToken: Scalars['String']['output'];
  id: Scalars['String']['output'];
  organization_id?: Maybe<Scalars['String']['output']>;
  organzation?: Maybe<Organization>;
  outgoingApplicationSid: Scalars['String']['output'];
  twilioApiKey: Scalars['String']['output'];
  twilioApiSecret: Scalars['String']['output'];
};

export type TwilioInfoCreateInput = {
  accountSid: Scalars['String']['input'];
  addressSid?: InputMaybe<Scalars['String']['input']>;
  authToken: Scalars['String']['input'];
  bundleSid?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  organzation?: InputMaybe<OrganizationCreateNestedOneWithoutTwilioInfoInput>;
  outgoingApplicationSid: Scalars['String']['input'];
  twilioApiKey: Scalars['String']['input'];
  twilioApiSecret: Scalars['String']['input'];
};

export type TwilioInfoCreateNestedOneWithoutOrganzationInput = {
  connect?: InputMaybe<TwilioInfoWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TwilioInfoCreateOrConnectWithoutOrganzationInput>;
  create?: InputMaybe<TwilioInfoCreateWithoutOrganzationInput>;
};

export type TwilioInfoCreateOrConnectWithoutOrganzationInput = {
  create: TwilioInfoCreateWithoutOrganzationInput;
  where: TwilioInfoWhereUniqueInput;
};

export type TwilioInfoCreateWithoutOrganzationInput = {
  accountSid: Scalars['String']['input'];
  addressSid?: InputMaybe<Scalars['String']['input']>;
  authToken: Scalars['String']['input'];
  bundleSid?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  outgoingApplicationSid: Scalars['String']['input'];
  twilioApiKey: Scalars['String']['input'];
  twilioApiSecret: Scalars['String']['input'];
};

export type TwilioInfoOrderByWithRelationInput = {
  accountSid?: InputMaybe<SortOrder>;
  addressSid?: InputMaybe<SortOrder>;
  authToken?: InputMaybe<SortOrder>;
  bundleSid?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  organization_id?: InputMaybe<SortOrder>;
  organzation?: InputMaybe<OrganizationOrderByWithRelationInput>;
  outgoingApplicationSid?: InputMaybe<SortOrder>;
  twilioApiKey?: InputMaybe<SortOrder>;
  twilioApiSecret?: InputMaybe<SortOrder>;
};

export type TwilioInfoUpdateOneWithoutOrganzationInput = {
  connect?: InputMaybe<TwilioInfoWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TwilioInfoCreateOrConnectWithoutOrganzationInput>;
  create?: InputMaybe<TwilioInfoCreateWithoutOrganzationInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<TwilioInfoUpdateWithoutOrganzationInput>;
  upsert?: InputMaybe<TwilioInfoUpsertWithoutOrganzationInput>;
};

export type TwilioInfoUpdateWithoutOrganzationInput = {
  accountSid?: InputMaybe<StringFieldUpdateOperationsInput>;
  addressSid?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  authToken?: InputMaybe<StringFieldUpdateOperationsInput>;
  bundleSid?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  outgoingApplicationSid?: InputMaybe<StringFieldUpdateOperationsInput>;
  twilioApiKey?: InputMaybe<StringFieldUpdateOperationsInput>;
  twilioApiSecret?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type TwilioInfoUpsertWithoutOrganzationInput = {
  create: TwilioInfoCreateWithoutOrganzationInput;
  update: TwilioInfoUpdateWithoutOrganzationInput;
};

export type TwilioInfoWhereInput = {
  accountSid?: InputMaybe<StringFilter>;
  addressSid?: InputMaybe<StringNullableFilter>;
  AND?: InputMaybe<Array<TwilioInfoWhereInput>>;
  authToken?: InputMaybe<StringFilter>;
  bundleSid?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  NOT?: InputMaybe<Array<TwilioInfoWhereInput>>;
  OR?: InputMaybe<Array<TwilioInfoWhereInput>>;
  organization_id?: InputMaybe<StringNullableFilter>;
  organzation?: InputMaybe<OrganizationWhereInput>;
  outgoingApplicationSid?: InputMaybe<StringFilter>;
  twilioApiKey?: InputMaybe<StringFilter>;
  twilioApiSecret?: InputMaybe<StringFilter>;
};

export type TwilioInfoWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  organization_id?: InputMaybe<Scalars['String']['input']>;
};

export type TwilioNumber = {
  __typename?: 'TwilioNumber';
  area_code: Array<Scalars['String']['output']>;
  created_at: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  in_use: Scalars['Boolean']['output'];
  number: Scalars['String']['output'];
  organization_id?: Maybe<Scalars['String']['output']>;
  updated_at: Scalars['DateTime']['output'];
};

export type TwilioNumberCreatearea_CodeInput = {
  set?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type TwilioNumberCreateManyarea_CodeInput = {
  set?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type TwilioNumberCreateManyOrganizationInput = {
  area_code?: InputMaybe<TwilioNumberCreateManyarea_CodeInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  in_use?: InputMaybe<Scalars['Boolean']['input']>;
  local: Scalars['Boolean']['input'];
  number: Scalars['String']['input'];
  router_number?: InputMaybe<Scalars['Boolean']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
};

export type TwilioNumberCreateManyOrganizationInputEnvelope = {
  data?: InputMaybe<Array<TwilioNumberCreateManyOrganizationInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type TwilioNumberCreateNestedManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<TwilioNumberWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TwilioNumberCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<TwilioNumberCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<TwilioNumberCreateManyOrganizationInputEnvelope>;
};

export type TwilioNumberCreateNestedOneWithoutLead_ActivitiesInput = {
  connect?: InputMaybe<TwilioNumberWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TwilioNumberCreateOrConnectWithoutLead_ActivitiesInput>;
  create?: InputMaybe<TwilioNumberCreateWithoutLead_ActivitiesInput>;
};

export type TwilioNumberCreateOrConnectWithoutLead_ActivitiesInput = {
  create: TwilioNumberCreateWithoutLead_ActivitiesInput;
  where: TwilioNumberWhereUniqueInput;
};

export type TwilioNumberCreateOrConnectWithoutOrganizationInput = {
  create: TwilioNumberCreateWithoutOrganizationInput;
  where: TwilioNumberWhereUniqueInput;
};

export type TwilioNumberCreateWithoutLead_ActivitiesInput = {
  area_code?: InputMaybe<TwilioNumberCreatearea_CodeInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  in_use?: InputMaybe<Scalars['Boolean']['input']>;
  local: Scalars['Boolean']['input'];
  number: Scalars['String']['input'];
  organization?: InputMaybe<OrganizationCreateNestedOneWithoutTwilio_NumbersInput>;
  router_number?: InputMaybe<Scalars['Boolean']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
};

export type TwilioNumberCreateWithoutOrganizationInput = {
  area_code?: InputMaybe<TwilioNumberCreatearea_CodeInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  in_use?: InputMaybe<Scalars['Boolean']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutLocal_NumberInput>;
  local: Scalars['Boolean']['input'];
  number: Scalars['String']['input'];
  router_number?: InputMaybe<Scalars['Boolean']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
};

export type TwilioNumberListRelationFilter = {
  every?: InputMaybe<TwilioNumberWhereInput>;
  none?: InputMaybe<TwilioNumberWhereInput>;
  some?: InputMaybe<TwilioNumberWhereInput>;
};

export type TwilioNumberLog = {
  __typename?: 'TwilioNumberLog';
  dml_created_by?: Maybe<Scalars['String']['output']>;
  dml_timestamp: Scalars['DateTime']['output'];
  dml_type: Dml_Type;
  new_row_data?: Maybe<Scalars['Json']['output']>;
  old_row_data?: Maybe<Scalars['Json']['output']>;
  twilio_number_id: Scalars['String']['output'];
};

export type TwilioNumberOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
  count?: InputMaybe<SortOrder>;
};

export type TwilioNumberOrderByWithRelationInput = {
  area_code?: InputMaybe<SortOrder>;
  created_at?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  in_use?: InputMaybe<SortOrder>;
  lead_activities?: InputMaybe<LeadActivityOrderByRelationAggregateInput>;
  local?: InputMaybe<SortOrder>;
  number?: InputMaybe<SortOrder>;
  organization?: InputMaybe<OrganizationOrderByWithRelationInput>;
  organization_id?: InputMaybe<SortOrder>;
  router_number?: InputMaybe<SortOrder>;
  updated_at?: InputMaybe<SortOrder>;
  user_id?: InputMaybe<SortOrder>;
};

export type TwilioNumberScalarWhereInput = {
  AND?: InputMaybe<Array<TwilioNumberScalarWhereInput>>;
  area_code?: InputMaybe<StringNullableListFilter>;
  created_at?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  in_use?: InputMaybe<BoolFilter>;
  local?: InputMaybe<BoolFilter>;
  NOT?: InputMaybe<Array<TwilioNumberScalarWhereInput>>;
  number?: InputMaybe<StringFilter>;
  OR?: InputMaybe<Array<TwilioNumberScalarWhereInput>>;
  organization_id?: InputMaybe<StringNullableFilter>;
  router_number?: InputMaybe<BoolFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
  user_id?: InputMaybe<StringNullableFilter>;
};

export type TwilioNumberUpdatearea_CodeInput = {
  push?: InputMaybe<Scalars['String']['input']>;
  set?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type TwilioNumberUpdateManyMutationInput = {
  area_code?: InputMaybe<TwilioNumberUpdatearea_CodeInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  in_use?: InputMaybe<BoolFieldUpdateOperationsInput>;
  local?: InputMaybe<BoolFieldUpdateOperationsInput>;
  number?: InputMaybe<StringFieldUpdateOperationsInput>;
  router_number?: InputMaybe<BoolFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type TwilioNumberUpdateManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<TwilioNumberWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TwilioNumberCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<TwilioNumberCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<TwilioNumberCreateManyOrganizationInputEnvelope>;
  delete?: InputMaybe<Array<TwilioNumberWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<TwilioNumberScalarWhereInput>>;
  disconnect?: InputMaybe<Array<TwilioNumberWhereUniqueInput>>;
  set?: InputMaybe<Array<TwilioNumberWhereUniqueInput>>;
  update?: InputMaybe<Array<TwilioNumberUpdateWithWhereUniqueWithoutOrganizationInput>>;
  updateMany?: InputMaybe<Array<TwilioNumberUpdateManyWithWhereWithoutOrganizationInput>>;
  upsert?: InputMaybe<Array<TwilioNumberUpsertWithWhereUniqueWithoutOrganizationInput>>;
};

export type TwilioNumberUpdateManyWithWhereWithoutOrganizationInput = {
  data: TwilioNumberUpdateManyMutationInput;
  where: TwilioNumberScalarWhereInput;
};

export type TwilioNumberUpdateOneWithoutLead_ActivitiesInput = {
  connect?: InputMaybe<TwilioNumberWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TwilioNumberCreateOrConnectWithoutLead_ActivitiesInput>;
  create?: InputMaybe<TwilioNumberCreateWithoutLead_ActivitiesInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<TwilioNumberUpdateWithoutLead_ActivitiesInput>;
  upsert?: InputMaybe<TwilioNumberUpsertWithoutLead_ActivitiesInput>;
};

export type TwilioNumberUpdateWithoutLead_ActivitiesInput = {
  area_code?: InputMaybe<TwilioNumberUpdatearea_CodeInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  in_use?: InputMaybe<BoolFieldUpdateOperationsInput>;
  local?: InputMaybe<BoolFieldUpdateOperationsInput>;
  number?: InputMaybe<StringFieldUpdateOperationsInput>;
  organization?: InputMaybe<OrganizationUpdateOneWithoutTwilio_NumbersInput>;
  router_number?: InputMaybe<BoolFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type TwilioNumberUpdateWithoutOrganizationInput = {
  area_code?: InputMaybe<TwilioNumberUpdatearea_CodeInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  in_use?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutLocal_NumberInput>;
  local?: InputMaybe<BoolFieldUpdateOperationsInput>;
  number?: InputMaybe<StringFieldUpdateOperationsInput>;
  router_number?: InputMaybe<BoolFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type TwilioNumberUpdateWithWhereUniqueWithoutOrganizationInput = {
  data: TwilioNumberUpdateWithoutOrganizationInput;
  where: TwilioNumberWhereUniqueInput;
};

export type TwilioNumberUpsertWithoutLead_ActivitiesInput = {
  create: TwilioNumberCreateWithoutLead_ActivitiesInput;
  update: TwilioNumberUpdateWithoutLead_ActivitiesInput;
};

export type TwilioNumberUpsertWithWhereUniqueWithoutOrganizationInput = {
  create: TwilioNumberCreateWithoutOrganizationInput;
  update: TwilioNumberUpdateWithoutOrganizationInput;
  where: TwilioNumberWhereUniqueInput;
};

export type TwilioNumberWhereInput = {
  AND?: InputMaybe<Array<TwilioNumberWhereInput>>;
  area_code?: InputMaybe<StringNullableListFilter>;
  created_at?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  in_use?: InputMaybe<BoolFilter>;
  lead_activities?: InputMaybe<LeadActivityListRelationFilter>;
  local?: InputMaybe<BoolFilter>;
  NOT?: InputMaybe<Array<TwilioNumberWhereInput>>;
  number?: InputMaybe<StringFilter>;
  OR?: InputMaybe<Array<TwilioNumberWhereInput>>;
  organization?: InputMaybe<OrganizationWhereInput>;
  organization_id?: InputMaybe<StringNullableFilter>;
  router_number?: InputMaybe<BoolFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
  user_id?: InputMaybe<StringNullableFilter>;
};

export type TwilioNumberWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  number?: InputMaybe<Scalars['String']['input']>;
};

/** Action for an active twilio recording */
export enum TwilioRecordingAction {
  Pause = 'Pause',
  Resume = 'Resume',
  Start = 'Start'
}

export type UpdateSequencesPriorityInput = {
  sequences_id: Array<Scalars['String']['input']>;
};

export type User = {
  __typename?: 'User';
  alternate_email?: Maybe<Scalars['String']['output']>;
  always_show_calendar_options: Scalars['Boolean']['output'];
  auto_transfer_selection_receive_attempts: Array<AutoTransferSelection>;
  auto_transfer_selection_send_attempts: Array<AutoTransferSelection>;
  /** If default_availability is return, it returns the orgs availability. Otherwise, it returns the users customer availability. */
  availability?: Maybe<Array<Maybe<Availability>>>;
  channel?: Maybe<Channelfilter>;
  close_rate?: Maybe<Scalars['Float']['output']>;
  close_user_cycle: Array<SaleCycle>;
  close_user_stat: Array<StatLeadAggregation>;
  commissions: Array<Commission>;
  /** Processed industries */
  computed_industries?: Maybe<Scalars['Json']['output']>;
  created_at: Scalars['DateTime']['output'];
  current_team_start_date?: Maybe<Scalars['DateTime']['output']>;
  custom_field_white_list_array: Array<UserCustomFieldFilter>;
  custom_queue_items: Array<CustomQueueItem>;
  daily_lead_claim: Scalars['Int']['output'];
  dashboard_2_access: Scalars['Boolean']['output'];
  default_audio_input?: Maybe<Scalars['String']['output']>;
  default_audio_output?: Maybe<Scalars['String']['output']>;
  default_availability: Scalars['Boolean']['output'];
  deleted_at?: Maybe<Scalars['DateTime']['output']>;
  email: Scalars['String']['output'];
  favorite_leads: Array<Lead>;
  first_name?: Maybe<Scalars['String']['output']>;
  folders: Array<Folder>;
  from_assignment_history: Array<LeadAssignmentHistory>;
  full_name?: Maybe<Scalars['String']['output']>;
  GlobalRouting: Array<GlobalRepRouting>;
  google_refresh_token?: Maybe<Scalars['Boolean']['output']>;
  holds_per_prospect_vs_held_demo_per_dial?: Maybe<Scalars['Float']['output']>;
  id: Scalars['String']['output'];
  industry_filter: Scalars['Boolean']['output'];
  industry_white_list_array: Array<Scalars['String']['output']>;
  industry_white_list_assign_type: AssignType;
  joinMe_access_token?: Maybe<Scalars['String']['output']>;
  /** Indicate if user is connected with JoinMe */
  joinMe_connected?: Maybe<Scalars['Boolean']['output']>;
  joinMe_refresh_token?: Maybe<Scalars['String']['output']>;
  last_demo_transferred_at?: Maybe<Scalars['DateTime']['output']>;
  last_name?: Maybe<Scalars['String']['output']>;
  last_name_first_name?: Maybe<Scalars['String']['output']>;
  last_updated_hours?: Maybe<Scalars['Float']['output']>;
  lead_activities: Array<LeadActivity>;
  lead_import_history: Array<LeadImportHistory>;
  lead_intents: Array<LeadIntent>;
  lead_source_filter: Scalars['Boolean']['output'];
  lead_source_white_list_array: Array<Scalars['String']['output']>;
  lead_source_white_list_assign_type: AssignType;
  /** Returns the number of cold call intents in the users queue */
  leads_in_queue?: Maybe<Scalars['Int']['output']>;
  liveUserStatus: Array<LiveUserStatus>;
  /** Returns the users manager if applicable */
  manager?: Maybe<User>;
  /** The id of the manager if applicable */
  manager_id?: Maybe<Scalars['String']['output']>;
  metric?: Maybe<Scalars['Float']['output']>;
  note_item: Array<NoteItem>;
  notifications: Array<Notification>;
  /** Number of unread SMS messages */
  num_unread_sms_items?: Maybe<Scalars['Int']['output']>;
  organization: Organization;
  organization_id: Scalars['String']['output'];
  owned_leads: Array<Lead>;
  panda_doc_access: Scalars['Boolean']['output'];
  password?: Maybe<Scalars['String']['output']>;
  phone_number?: Maybe<Scalars['String']['output']>;
  post: Array<Post>;
  profile_image?: Maybe<Scalars['String']['output']>;
  receiver_assignment_history: Array<LeadAssignmentHistory>;
  regions_white_list_array: Array<Scalars['String']['output']>;
  regions_white_list_assign_type: AssignType;
  rep_scores?: Maybe<Scalars['Float']['output']>;
  /** Revenue Per Rep Per Day for the the last 90 days */
  revenue_per_day?: Maybe<Scalars['Float']['output']>;
  role: Role;
  /** Label of the role */
  rolelabel?: Maybe<Scalars['String']['output']>;
  sales_team_column_options: Array<Scalars['String']['output']>;
  sales_team_column_options_computed?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  sales_team_column_options_computed_labels?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  schedule_items: Array<ScheduleItem>;
  sender_assignment_history: Array<LeadAssignmentHistory>;
  session_token?: Maybe<Scalars['String']['output']>;
  set_user_cycle: Array<SaleCycle>;
  set_user_stat: Array<StatLeadAggregation>;
  /** Folders shared with the user */
  shared_folders?: Maybe<Array<Maybe<Folder>>>;
  SmsItems: Array<SmsItem>;
  status: Status;
  status_label?: Maybe<Scalars['String']['output']>;
  sub_industry_white_list_array: Array<Scalars['String']['output']>;
  sub_industry_white_list_assign_type: AssignType;
  support_rep: Scalars['Boolean']['output'];
  taking_demos: Scalars['Boolean']['output'];
  team?: Maybe<Team>;
  team_id?: Maybe<Scalars['String']['output']>;
  team_name?: Maybe<Scalars['String']['output']>;
  timekit_id?: Maybe<Scalars['String']['output']>;
  timekit_type: Timekittype;
  timeOffRequest: Array<TimeOffRequest>;
  /** Returns user's timezone if it exists. Otherwise, uses organization's timezone */
  timezone?: Maybe<Scalars['String']['output']>;
  timezone_raw?: Maybe<Scalars['String']['output']>;
  transfer_type: TransferType;
  updated_at: Scalars['DateTime']['output'];
  user_custom_availability: Array<Availability>;
  /** folders that are viewable by the user */
  viewable_folders?: Maybe<Array<Maybe<Folder>>>;
  visible_all_leads_sm: Scalars['Boolean']['output'];
  visible_call_report_columns: Array<Scalars['String']['output']>;
  visible_call_report_columns_computed?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  visible_call_report_columns_computed_labels?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  visible_call_report_event_columns: Array<Scalars['String']['output']>;
  visible_call_report_event_columns_computed?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  visible_call_report_event_columns_computed_labels?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  visible_call_report_pipeline_columns: Array<Scalars['String']['output']>;
  visible_call_report_pipeline_columns_computed?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  visible_call_report_pipeline_columns_computed_labels?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  visible_my_leads_columns: Array<Scalars['String']['output']>;
  visible_my_leads_columns_computed?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  visible_my_leads_columns_computed_labels?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  voicemail_url?: Maybe<Scalars['String']['output']>;
};


export type UserAuto_Transfer_Selection_Receive_AttemptsArgs = {
  after?: InputMaybe<AutoTransferSelectionWhereUniqueInput>;
  before?: InputMaybe<AutoTransferSelectionWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type UserAuto_Transfer_Selection_Send_AttemptsArgs = {
  after?: InputMaybe<AutoTransferSelectionWhereUniqueInput>;
  before?: InputMaybe<AutoTransferSelectionWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type UserClose_User_CycleArgs = {
  after?: InputMaybe<SaleCycleWhereUniqueInput>;
  before?: InputMaybe<SaleCycleWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type UserClose_User_StatArgs = {
  after?: InputMaybe<StatLeadAggregationWhereUniqueInput>;
  before?: InputMaybe<StatLeadAggregationWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type UserCommissionsArgs = {
  after?: InputMaybe<CommissionWhereUniqueInput>;
  before?: InputMaybe<CommissionWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type UserCustom_Field_White_List_ArrayArgs = {
  after?: InputMaybe<UserCustomFieldFilterWhereUniqueInput>;
  before?: InputMaybe<UserCustomFieldFilterWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type UserCustom_Queue_ItemsArgs = {
  after?: InputMaybe<CustomQueueItemWhereUniqueInput>;
  before?: InputMaybe<CustomQueueItemWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type UserFavorite_LeadsArgs = {
  after?: InputMaybe<LeadWhereUniqueInput>;
  before?: InputMaybe<LeadWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type UserFoldersArgs = {
  after?: InputMaybe<FolderWhereUniqueInput>;
  before?: InputMaybe<FolderWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type UserFrom_Assignment_HistoryArgs = {
  after?: InputMaybe<LeadAssignmentHistoryWhereUniqueInput>;
  before?: InputMaybe<LeadAssignmentHistoryWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type UserGlobalRoutingArgs = {
  after?: InputMaybe<GlobalRepRoutingWhereUniqueInput>;
  before?: InputMaybe<GlobalRepRoutingWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type UserLead_ActivitiesArgs = {
  after?: InputMaybe<LeadActivityWhereUniqueInput>;
  before?: InputMaybe<LeadActivityWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type UserLead_Import_HistoryArgs = {
  after?: InputMaybe<LeadImportHistoryWhereUniqueInput>;
  before?: InputMaybe<LeadImportHistoryWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type UserLead_IntentsArgs = {
  after?: InputMaybe<LeadIntentWhereUniqueInput>;
  before?: InputMaybe<LeadIntentWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type UserLiveUserStatusArgs = {
  after?: InputMaybe<LiveUserStatusWhereUniqueInput>;
  before?: InputMaybe<LiveUserStatusWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type UserNote_ItemArgs = {
  after?: InputMaybe<NoteItemWhereUniqueInput>;
  before?: InputMaybe<NoteItemWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type UserNotificationsArgs = {
  after?: InputMaybe<NotificationWhereUniqueInput>;
  before?: InputMaybe<NotificationWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type UserOwned_LeadsArgs = {
  after?: InputMaybe<LeadWhereUniqueInput>;
  before?: InputMaybe<LeadWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type UserPostArgs = {
  after?: InputMaybe<PostWhereUniqueInput>;
  before?: InputMaybe<PostWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type UserReceiver_Assignment_HistoryArgs = {
  after?: InputMaybe<LeadAssignmentHistoryWhereUniqueInput>;
  before?: InputMaybe<LeadAssignmentHistoryWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type UserSchedule_ItemsArgs = {
  after?: InputMaybe<ScheduleItemWhereUniqueInput>;
  before?: InputMaybe<ScheduleItemWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type UserSender_Assignment_HistoryArgs = {
  after?: InputMaybe<LeadAssignmentHistoryWhereUniqueInput>;
  before?: InputMaybe<LeadAssignmentHistoryWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type UserSet_User_CycleArgs = {
  after?: InputMaybe<SaleCycleWhereUniqueInput>;
  before?: InputMaybe<SaleCycleWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type UserSet_User_StatArgs = {
  after?: InputMaybe<StatLeadAggregationWhereUniqueInput>;
  before?: InputMaybe<StatLeadAggregationWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type UserSmsItemsArgs = {
  after?: InputMaybe<SmsItemWhereUniqueInput>;
  before?: InputMaybe<SmsItemWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type UserTimeOffRequestArgs = {
  after?: InputMaybe<TimeOffRequestWhereUniqueInput>;
  before?: InputMaybe<TimeOffRequestWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type UserUser_Custom_AvailabilityArgs = {
  after?: InputMaybe<AvailabilityWhereUniqueInput>;
  before?: InputMaybe<AvailabilityWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** User Batch Edit Input */
export type UserBatchEditInput = {
  channel?: InputMaybe<Channelfilter>;
  custom_field_white_list_array?: InputMaybe<Array<UserCustomFieldFilterInput>>;
  industry_white_list_array?: InputMaybe<Array<Scalars['String']['input']>>;
  industry_white_list_assign_type?: InputMaybe<AssignType>;
  lead_source_white_list_array?: InputMaybe<Array<Scalars['String']['input']>>;
  lead_source_white_list_assign_type?: InputMaybe<AssignType>;
  regions_white_list_array?: InputMaybe<Array<Scalars['String']['input']>>;
  regions_white_list_assign_type?: InputMaybe<AssignType>;
  role?: InputMaybe<Role>;
  sub_industry_white_list_array?: InputMaybe<Array<Scalars['String']['input']>>;
  sub_industry_white_list_assign_type?: InputMaybe<AssignType>;
  team_id?: InputMaybe<Scalars['String']['input']>;
  timezone_raw?: InputMaybe<Scalars['String']['input']>;
};

export type UserCreateindustry_White_List_ArrayInput = {
  set?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type UserCreatelead_Source_White_List_ArrayInput = {
  set?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type UserCreateManyCurrent_Sequence_ViewInput = {
  ai_user?: InputMaybe<Scalars['Boolean']['input']>;
  alternate_email?: InputMaybe<Scalars['String']['input']>;
  always_show_calendar_options?: InputMaybe<Scalars['Boolean']['input']>;
  channel?: InputMaybe<Channelfilter>;
  close_rate?: InputMaybe<Scalars['Float']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  current_team_start_date?: InputMaybe<Scalars['DateTime']['input']>;
  daily_lead_claim?: InputMaybe<Scalars['Int']['input']>;
  dashboard_2_access?: InputMaybe<Scalars['Boolean']['input']>;
  default_audio_input?: InputMaybe<Scalars['String']['input']>;
  default_audio_output?: InputMaybe<Scalars['String']['input']>;
  default_availability?: InputMaybe<Scalars['Boolean']['input']>;
  deleted_at?: InputMaybe<Scalars['DateTime']['input']>;
  deprecate_old_session?: InputMaybe<Scalars['Boolean']['input']>;
  email: Scalars['String']['input'];
  first_name?: InputMaybe<Scalars['String']['input']>;
  google_refresh_token?: InputMaybe<Scalars['String']['input']>;
  holds_per_prospect_vs_held_demo_per_dial?: InputMaybe<Scalars['Float']['input']>;
  hubspot_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  industry_filter?: InputMaybe<Scalars['Boolean']['input']>;
  industry_white_list_array?: InputMaybe<UserCreateManyindustry_White_List_ArrayInput>;
  industry_white_list_assign_type?: InputMaybe<AssignType>;
  joinMe_access_token?: InputMaybe<Scalars['String']['input']>;
  joinMe_refresh_token?: InputMaybe<Scalars['String']['input']>;
  last_demo_transferred_at?: InputMaybe<Scalars['DateTime']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  lead_source_filter?: InputMaybe<Scalars['Boolean']['input']>;
  lead_source_white_list_array?: InputMaybe<UserCreateManylead_Source_White_List_ArrayInput>;
  lead_source_white_list_assign_type?: InputMaybe<AssignType>;
  manager_id?: InputMaybe<Scalars['String']['input']>;
  organization_id: Scalars['String']['input'];
  panda_doc_access?: InputMaybe<Scalars['Boolean']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  phone_number?: InputMaybe<Scalars['String']['input']>;
  profile_image?: InputMaybe<Scalars['String']['input']>;
  regions_white_list_array?: InputMaybe<UserCreateManyregions_White_List_ArrayInput>;
  regions_white_list_assign_type?: InputMaybe<AssignType>;
  rep_scores?: InputMaybe<Scalars['Float']['input']>;
  role?: InputMaybe<Role>;
  sales_team_column_options?: InputMaybe<UserCreateManysales_Team_Column_OptionsInput>;
  sequence_entry_criteria_id?: InputMaybe<Scalars['String']['input']>;
  session_token?: InputMaybe<Scalars['String']['input']>;
  sf_user_id?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Status>;
  sub_industry_white_list_array?: InputMaybe<UserCreateManysub_Industry_White_List_ArrayInput>;
  sub_industry_white_list_assign_type?: InputMaybe<AssignType>;
  support_rep?: InputMaybe<Scalars['Boolean']['input']>;
  taking_demos?: InputMaybe<Scalars['Boolean']['input']>;
  team_id?: InputMaybe<Scalars['String']['input']>;
  timekit_id?: InputMaybe<Scalars['String']['input']>;
  timekit_type?: InputMaybe<Timekittype>;
  timezone_raw?: InputMaybe<Scalars['String']['input']>;
  transfer_type?: InputMaybe<TransferType>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  visible_all_leads_sm?: InputMaybe<Scalars['Boolean']['input']>;
  visible_call_report_columns?: InputMaybe<UserCreateManyvisible_Call_Report_ColumnsInput>;
  visible_call_report_event_columns?: InputMaybe<UserCreateManyvisible_Call_Report_Event_ColumnsInput>;
  visible_call_report_pipeline_columns?: InputMaybe<UserCreateManyvisible_Call_Report_Pipeline_ColumnsInput>;
  visible_my_leads_columns?: InputMaybe<UserCreateManyvisible_My_Leads_ColumnsInput>;
  voicemail_url?: InputMaybe<Scalars['String']['input']>;
};

export type UserCreateManyCurrent_Sequence_ViewInputEnvelope = {
  data?: InputMaybe<Array<UserCreateManyCurrent_Sequence_ViewInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UserCreateManyindustry_White_List_ArrayInput = {
  set?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type UserCreateManylead_Source_White_List_ArrayInput = {
  set?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type UserCreateManyOrganizationInput = {
  ai_user?: InputMaybe<Scalars['Boolean']['input']>;
  alternate_email?: InputMaybe<Scalars['String']['input']>;
  always_show_calendar_options?: InputMaybe<Scalars['Boolean']['input']>;
  channel?: InputMaybe<Channelfilter>;
  close_rate?: InputMaybe<Scalars['Float']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  current_sequence_view_id?: InputMaybe<Scalars['String']['input']>;
  current_team_start_date?: InputMaybe<Scalars['DateTime']['input']>;
  daily_lead_claim?: InputMaybe<Scalars['Int']['input']>;
  dashboard_2_access?: InputMaybe<Scalars['Boolean']['input']>;
  default_audio_input?: InputMaybe<Scalars['String']['input']>;
  default_audio_output?: InputMaybe<Scalars['String']['input']>;
  default_availability?: InputMaybe<Scalars['Boolean']['input']>;
  deleted_at?: InputMaybe<Scalars['DateTime']['input']>;
  deprecate_old_session?: InputMaybe<Scalars['Boolean']['input']>;
  email: Scalars['String']['input'];
  first_name?: InputMaybe<Scalars['String']['input']>;
  google_refresh_token?: InputMaybe<Scalars['String']['input']>;
  holds_per_prospect_vs_held_demo_per_dial?: InputMaybe<Scalars['Float']['input']>;
  hubspot_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  industry_filter?: InputMaybe<Scalars['Boolean']['input']>;
  industry_white_list_array?: InputMaybe<UserCreateManyindustry_White_List_ArrayInput>;
  industry_white_list_assign_type?: InputMaybe<AssignType>;
  joinMe_access_token?: InputMaybe<Scalars['String']['input']>;
  joinMe_refresh_token?: InputMaybe<Scalars['String']['input']>;
  last_demo_transferred_at?: InputMaybe<Scalars['DateTime']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  lead_source_filter?: InputMaybe<Scalars['Boolean']['input']>;
  lead_source_white_list_array?: InputMaybe<UserCreateManylead_Source_White_List_ArrayInput>;
  lead_source_white_list_assign_type?: InputMaybe<AssignType>;
  manager_id?: InputMaybe<Scalars['String']['input']>;
  panda_doc_access?: InputMaybe<Scalars['Boolean']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  phone_number?: InputMaybe<Scalars['String']['input']>;
  profile_image?: InputMaybe<Scalars['String']['input']>;
  regions_white_list_array?: InputMaybe<UserCreateManyregions_White_List_ArrayInput>;
  regions_white_list_assign_type?: InputMaybe<AssignType>;
  rep_scores?: InputMaybe<Scalars['Float']['input']>;
  role?: InputMaybe<Role>;
  sales_team_column_options?: InputMaybe<UserCreateManysales_Team_Column_OptionsInput>;
  sequence_entry_criteria_id?: InputMaybe<Scalars['String']['input']>;
  session_token?: InputMaybe<Scalars['String']['input']>;
  sf_user_id?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Status>;
  sub_industry_white_list_array?: InputMaybe<UserCreateManysub_Industry_White_List_ArrayInput>;
  sub_industry_white_list_assign_type?: InputMaybe<AssignType>;
  support_rep?: InputMaybe<Scalars['Boolean']['input']>;
  taking_demos?: InputMaybe<Scalars['Boolean']['input']>;
  team_id?: InputMaybe<Scalars['String']['input']>;
  timekit_id?: InputMaybe<Scalars['String']['input']>;
  timekit_type?: InputMaybe<Timekittype>;
  timezone_raw?: InputMaybe<Scalars['String']['input']>;
  transfer_type?: InputMaybe<TransferType>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  visible_all_leads_sm?: InputMaybe<Scalars['Boolean']['input']>;
  visible_call_report_columns?: InputMaybe<UserCreateManyvisible_Call_Report_ColumnsInput>;
  visible_call_report_event_columns?: InputMaybe<UserCreateManyvisible_Call_Report_Event_ColumnsInput>;
  visible_call_report_pipeline_columns?: InputMaybe<UserCreateManyvisible_Call_Report_Pipeline_ColumnsInput>;
  visible_my_leads_columns?: InputMaybe<UserCreateManyvisible_My_Leads_ColumnsInput>;
  voicemail_url?: InputMaybe<Scalars['String']['input']>;
};

export type UserCreateManyOrganizationInputEnvelope = {
  data?: InputMaybe<Array<UserCreateManyOrganizationInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UserCreateManyregions_White_List_ArrayInput = {
  set?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type UserCreateManysales_Team_Column_OptionsInput = {
  set?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type UserCreateManysub_Industry_White_List_ArrayInput = {
  set?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type UserCreateManyTeamInput = {
  ai_user?: InputMaybe<Scalars['Boolean']['input']>;
  alternate_email?: InputMaybe<Scalars['String']['input']>;
  always_show_calendar_options?: InputMaybe<Scalars['Boolean']['input']>;
  channel?: InputMaybe<Channelfilter>;
  close_rate?: InputMaybe<Scalars['Float']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  current_sequence_view_id?: InputMaybe<Scalars['String']['input']>;
  current_team_start_date?: InputMaybe<Scalars['DateTime']['input']>;
  daily_lead_claim?: InputMaybe<Scalars['Int']['input']>;
  dashboard_2_access?: InputMaybe<Scalars['Boolean']['input']>;
  default_audio_input?: InputMaybe<Scalars['String']['input']>;
  default_audio_output?: InputMaybe<Scalars['String']['input']>;
  default_availability?: InputMaybe<Scalars['Boolean']['input']>;
  deleted_at?: InputMaybe<Scalars['DateTime']['input']>;
  deprecate_old_session?: InputMaybe<Scalars['Boolean']['input']>;
  email: Scalars['String']['input'];
  first_name?: InputMaybe<Scalars['String']['input']>;
  google_refresh_token?: InputMaybe<Scalars['String']['input']>;
  holds_per_prospect_vs_held_demo_per_dial?: InputMaybe<Scalars['Float']['input']>;
  hubspot_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  industry_filter?: InputMaybe<Scalars['Boolean']['input']>;
  industry_white_list_array?: InputMaybe<UserCreateManyindustry_White_List_ArrayInput>;
  industry_white_list_assign_type?: InputMaybe<AssignType>;
  joinMe_access_token?: InputMaybe<Scalars['String']['input']>;
  joinMe_refresh_token?: InputMaybe<Scalars['String']['input']>;
  last_demo_transferred_at?: InputMaybe<Scalars['DateTime']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  lead_source_filter?: InputMaybe<Scalars['Boolean']['input']>;
  lead_source_white_list_array?: InputMaybe<UserCreateManylead_Source_White_List_ArrayInput>;
  lead_source_white_list_assign_type?: InputMaybe<AssignType>;
  manager_id?: InputMaybe<Scalars['String']['input']>;
  organization_id: Scalars['String']['input'];
  panda_doc_access?: InputMaybe<Scalars['Boolean']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  phone_number?: InputMaybe<Scalars['String']['input']>;
  profile_image?: InputMaybe<Scalars['String']['input']>;
  regions_white_list_array?: InputMaybe<UserCreateManyregions_White_List_ArrayInput>;
  regions_white_list_assign_type?: InputMaybe<AssignType>;
  rep_scores?: InputMaybe<Scalars['Float']['input']>;
  role?: InputMaybe<Role>;
  sales_team_column_options?: InputMaybe<UserCreateManysales_Team_Column_OptionsInput>;
  sequence_entry_criteria_id?: InputMaybe<Scalars['String']['input']>;
  session_token?: InputMaybe<Scalars['String']['input']>;
  sf_user_id?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Status>;
  sub_industry_white_list_array?: InputMaybe<UserCreateManysub_Industry_White_List_ArrayInput>;
  sub_industry_white_list_assign_type?: InputMaybe<AssignType>;
  support_rep?: InputMaybe<Scalars['Boolean']['input']>;
  taking_demos?: InputMaybe<Scalars['Boolean']['input']>;
  timekit_id?: InputMaybe<Scalars['String']['input']>;
  timekit_type?: InputMaybe<Timekittype>;
  timezone_raw?: InputMaybe<Scalars['String']['input']>;
  transfer_type?: InputMaybe<TransferType>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  visible_all_leads_sm?: InputMaybe<Scalars['Boolean']['input']>;
  visible_call_report_columns?: InputMaybe<UserCreateManyvisible_Call_Report_ColumnsInput>;
  visible_call_report_event_columns?: InputMaybe<UserCreateManyvisible_Call_Report_Event_ColumnsInput>;
  visible_call_report_pipeline_columns?: InputMaybe<UserCreateManyvisible_Call_Report_Pipeline_ColumnsInput>;
  visible_my_leads_columns?: InputMaybe<UserCreateManyvisible_My_Leads_ColumnsInput>;
  voicemail_url?: InputMaybe<Scalars['String']['input']>;
};

export type UserCreateManyTeamInputEnvelope = {
  data?: InputMaybe<Array<UserCreateManyTeamInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UserCreateManyvisible_Call_Report_ColumnsInput = {
  set?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type UserCreateManyvisible_Call_Report_Event_ColumnsInput = {
  set?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type UserCreateManyvisible_Call_Report_Pipeline_ColumnsInput = {
  set?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type UserCreateManyvisible_My_Leads_ColumnsInput = {
  set?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type UserCreateNestedManyWithoutCurrent_Sequence_ViewInput = {
  connect?: InputMaybe<Array<UserWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserCreateOrConnectWithoutCurrent_Sequence_ViewInput>>;
  create?: InputMaybe<Array<UserCreateWithoutCurrent_Sequence_ViewInput>>;
  createMany?: InputMaybe<UserCreateManyCurrent_Sequence_ViewInputEnvelope>;
};

export type UserCreateNestedManyWithoutFavorite_LeadsInput = {
  connect?: InputMaybe<Array<UserWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserCreateOrConnectWithoutFavorite_LeadsInput>>;
  create?: InputMaybe<Array<UserCreateWithoutFavorite_LeadsInput>>;
};

export type UserCreateNestedManyWithoutIdle_Owned_LeadsInput = {
  connect?: InputMaybe<Array<UserWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserCreateOrConnectWithoutIdle_Owned_LeadsInput>>;
  create?: InputMaybe<Array<UserCreateWithoutIdle_Owned_LeadsInput>>;
};

export type UserCreateNestedManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<UserWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<UserCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<UserCreateManyOrganizationInputEnvelope>;
};

export type UserCreateNestedManyWithoutTeamInput = {
  connect?: InputMaybe<Array<UserWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserCreateOrConnectWithoutTeamInput>>;
  create?: InputMaybe<Array<UserCreateWithoutTeamInput>>;
  createMany?: InputMaybe<UserCreateManyTeamInputEnvelope>;
};

export type UserCreateNestedOneWithoutAssociate_Owner_Rep_HistoryInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutAssociate_Owner_Rep_HistoryInput>;
  create?: InputMaybe<UserCreateWithoutAssociate_Owner_Rep_HistoryInput>;
};

export type UserCreateNestedOneWithoutAssociate_User_HistoryInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutAssociate_User_HistoryInput>;
  create?: InputMaybe<UserCreateWithoutAssociate_User_HistoryInput>;
};

export type UserCreateNestedOneWithoutAuto_Transfer_Selection_Receive_AttemptsInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutAuto_Transfer_Selection_Receive_AttemptsInput>;
  create?: InputMaybe<UserCreateWithoutAuto_Transfer_Selection_Receive_AttemptsInput>;
};

export type UserCreateNestedOneWithoutAuto_Transfer_Selection_Send_AttemptsInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutAuto_Transfer_Selection_Send_AttemptsInput>;
  create?: InputMaybe<UserCreateWithoutAuto_Transfer_Selection_Send_AttemptsInput>;
};

export type UserCreateNestedOneWithoutCallMeNowAttemptsInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutCallMeNowAttemptsInput>;
  create?: InputMaybe<UserCreateWithoutCallMeNowAttemptsInput>;
};

export type UserCreateNestedOneWithoutCallSharingAsCoachInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutCallSharingAsCoachInput>;
  create?: InputMaybe<UserCreateWithoutCallSharingAsCoachInput>;
};

export type UserCreateNestedOneWithoutCallSharingAsUserInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutCallSharingAsUserInput>;
  create?: InputMaybe<UserCreateWithoutCallSharingAsUserInput>;
};

export type UserCreateNestedOneWithoutClose_User_CycleInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutClose_User_CycleInput>;
  create?: InputMaybe<UserCreateWithoutClose_User_CycleInput>;
};

export type UserCreateNestedOneWithoutClose_User_StatInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutClose_User_StatInput>;
  create?: InputMaybe<UserCreateWithoutClose_User_StatInput>;
};

export type UserCreateNestedOneWithoutCommissionsInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutCommissionsInput>;
  create?: InputMaybe<UserCreateWithoutCommissionsInput>;
};

export type UserCreateNestedOneWithoutContact_RequestsInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutContact_RequestsInput>;
  create?: InputMaybe<UserCreateWithoutContact_RequestsInput>;
};

export type UserCreateNestedOneWithoutCustom_Field_White_List_ArrayInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutCustom_Field_White_List_ArrayInput>;
  create?: InputMaybe<UserCreateWithoutCustom_Field_White_List_ArrayInput>;
};

export type UserCreateNestedOneWithoutCustom_Queue_ItemsInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutCustom_Queue_ItemsInput>;
  create?: InputMaybe<UserCreateWithoutCustom_Queue_ItemsInput>;
};

export type UserCreateNestedOneWithoutDashboardSavedViewInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutDashboardSavedViewInput>;
  create?: InputMaybe<UserCreateWithoutDashboardSavedViewInput>;
};

export type UserCreateNestedOneWithoutEmailSyncRecordInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutEmailSyncRecordInput>;
  create?: InputMaybe<UserCreateWithoutEmailSyncRecordInput>;
};

export type UserCreateNestedOneWithoutFolder_PermissionsInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutFolder_PermissionsInput>;
  create?: InputMaybe<UserCreateWithoutFolder_PermissionsInput>;
};

export type UserCreateNestedOneWithoutFoldersInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutFoldersInput>;
  create?: InputMaybe<UserCreateWithoutFoldersInput>;
};

export type UserCreateNestedOneWithoutFrom_Assignment_HistoryInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutFrom_Assignment_HistoryInput>;
  create?: InputMaybe<UserCreateWithoutFrom_Assignment_HistoryInput>;
};

export type UserCreateNestedOneWithoutGlobalRoutingInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutGlobalRoutingInput>;
  create?: InputMaybe<UserCreateWithoutGlobalRoutingInput>;
};

export type UserCreateNestedOneWithoutLead_ActivitiesInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutLead_ActivitiesInput>;
  create?: InputMaybe<UserCreateWithoutLead_ActivitiesInput>;
};

export type UserCreateNestedOneWithoutLead_Import_HistoryInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutLead_Import_HistoryInput>;
  create?: InputMaybe<UserCreateWithoutLead_Import_HistoryInput>;
};

export type UserCreateNestedOneWithoutLead_IntentsInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutLead_IntentsInput>;
  create?: InputMaybe<UserCreateWithoutLead_IntentsInput>;
};

export type UserCreateNestedOneWithoutLeads_Last_CalledInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutLeads_Last_CalledInput>;
  create?: InputMaybe<UserCreateWithoutLeads_Last_CalledInput>;
};

export type UserCreateNestedOneWithoutLiveUserStatusInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutLiveUserStatusInput>;
  create?: InputMaybe<UserCreateWithoutLiveUserStatusInput>;
};

export type UserCreateNestedOneWithoutNote_ItemInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutNote_ItemInput>;
  create?: InputMaybe<UserCreateWithoutNote_ItemInput>;
};

export type UserCreateNestedOneWithoutNotificationsInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutNotificationsInput>;
  create?: InputMaybe<UserCreateWithoutNotificationsInput>;
};

export type UserCreateNestedOneWithoutOwned_LeadsInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutOwned_LeadsInput>;
  create?: InputMaybe<UserCreateWithoutOwned_LeadsInput>;
};

export type UserCreateNestedOneWithoutPayment_Settings_UpdatedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutPayment_Settings_UpdatedInput>;
  create?: InputMaybe<UserCreateWithoutPayment_Settings_UpdatedInput>;
};

export type UserCreateNestedOneWithoutPayment_SettingsInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutPayment_SettingsInput>;
  create?: InputMaybe<UserCreateWithoutPayment_SettingsInput>;
};

export type UserCreateNestedOneWithoutProductsCreatedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutProductsCreatedInput>;
  create?: InputMaybe<UserCreateWithoutProductsCreatedInput>;
};

export type UserCreateNestedOneWithoutReceive_Transfer_AttemptsInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutReceive_Transfer_AttemptsInput>;
  create?: InputMaybe<UserCreateWithoutReceive_Transfer_AttemptsInput>;
};

export type UserCreateNestedOneWithoutReceiver_Assignment_HistoryInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutReceiver_Assignment_HistoryInput>;
  create?: InputMaybe<UserCreateWithoutReceiver_Assignment_HistoryInput>;
};

export type UserCreateNestedOneWithoutRoutingsInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutRoutingsInput>;
  create?: InputMaybe<UserCreateWithoutRoutingsInput>;
};

export type UserCreateNestedOneWithoutSale_Config_UpdatedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutSale_Config_UpdatedInput>;
  create?: InputMaybe<UserCreateWithoutSale_Config_UpdatedInput>;
};

export type UserCreateNestedOneWithoutSale_ConfigInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutSale_ConfigInput>;
  create?: InputMaybe<UserCreateWithoutSale_ConfigInput>;
};

export type UserCreateNestedOneWithoutSchedule_ItemsInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutSchedule_ItemsInput>;
  create?: InputMaybe<UserCreateWithoutSchedule_ItemsInput>;
};

export type UserCreateNestedOneWithoutSend_Transfer_AttemptsInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutSend_Transfer_AttemptsInput>;
  create?: InputMaybe<UserCreateWithoutSend_Transfer_AttemptsInput>;
};

export type UserCreateNestedOneWithoutSender_Assignment_HistoryInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutSender_Assignment_HistoryInput>;
  create?: InputMaybe<UserCreateWithoutSender_Assignment_HistoryInput>;
};

export type UserCreateNestedOneWithoutSequenceActionLogInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutSequenceActionLogInput>;
  create?: InputMaybe<UserCreateWithoutSequenceActionLogInput>;
};

export type UserCreateNestedOneWithoutSequenceDashboardViewInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutSequenceDashboardViewInput>;
  create?: InputMaybe<UserCreateWithoutSequenceDashboardViewInput>;
};

export type UserCreateNestedOneWithoutSequenceEntryExitLeadInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutSequenceEntryExitLeadInput>;
  create?: InputMaybe<UserCreateWithoutSequenceEntryExitLeadInput>;
};

export type UserCreateNestedOneWithoutSequences_CreatedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutSequences_CreatedInput>;
  create?: InputMaybe<UserCreateWithoutSequences_CreatedInput>;
};

export type UserCreateNestedOneWithoutSequences_Modified_By_UserInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutSequences_Modified_By_UserInput>;
  create?: InputMaybe<UserCreateWithoutSequences_Modified_By_UserInput>;
};

export type UserCreateNestedOneWithoutSet_User_CycleInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutSet_User_CycleInput>;
  create?: InputMaybe<UserCreateWithoutSet_User_CycleInput>;
};

export type UserCreateNestedOneWithoutSet_User_StatInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutSet_User_StatInput>;
  create?: InputMaybe<UserCreateWithoutSet_User_StatInput>;
};

export type UserCreateNestedOneWithoutSmsItemsInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutSmsItemsInput>;
  create?: InputMaybe<UserCreateWithoutSmsItemsInput>;
};

export type UserCreateNestedOneWithoutSnoozeTrackingInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutSnoozeTrackingInput>;
  create?: InputMaybe<UserCreateWithoutSnoozeTrackingInput>;
};

export type UserCreateNestedOneWithoutTemplateInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutTemplateInput>;
  create?: InputMaybe<UserCreateWithoutTemplateInput>;
};

export type UserCreateNestedOneWithoutTemplateVersionInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutTemplateVersionInput>;
  create?: InputMaybe<UserCreateWithoutTemplateVersionInput>;
};

export type UserCreateNestedOneWithoutTimeOffRequestInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutTimeOffRequestInput>;
  create?: InputMaybe<UserCreateWithoutTimeOffRequestInput>;
};

export type UserCreateNestedOneWithoutTransfer_User_CycleInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutTransfer_User_CycleInput>;
  create?: InputMaybe<UserCreateWithoutTransfer_User_CycleInput>;
};

export type UserCreateNestedOneWithoutUser_Custom_AvailabilityInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutUser_Custom_AvailabilityInput>;
  create?: InputMaybe<UserCreateWithoutUser_Custom_AvailabilityInput>;
};

export type UserCreateOrConnectWithoutAssociate_Owner_Rep_HistoryInput = {
  create: UserCreateWithoutAssociate_Owner_Rep_HistoryInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutAssociate_User_HistoryInput = {
  create: UserCreateWithoutAssociate_User_HistoryInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutAuto_Transfer_Selection_Receive_AttemptsInput = {
  create: UserCreateWithoutAuto_Transfer_Selection_Receive_AttemptsInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutAuto_Transfer_Selection_Send_AttemptsInput = {
  create: UserCreateWithoutAuto_Transfer_Selection_Send_AttemptsInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutCallMeNowAttemptsInput = {
  create: UserCreateWithoutCallMeNowAttemptsInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutCallSharingAsCoachInput = {
  create: UserCreateWithoutCallSharingAsCoachInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutCallSharingAsUserInput = {
  create: UserCreateWithoutCallSharingAsUserInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutClose_User_CycleInput = {
  create: UserCreateWithoutClose_User_CycleInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutClose_User_StatInput = {
  create: UserCreateWithoutClose_User_StatInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutCommissionsInput = {
  create: UserCreateWithoutCommissionsInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutContact_RequestsInput = {
  create: UserCreateWithoutContact_RequestsInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutCurrent_Sequence_ViewInput = {
  create: UserCreateWithoutCurrent_Sequence_ViewInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutCustom_Field_White_List_ArrayInput = {
  create: UserCreateWithoutCustom_Field_White_List_ArrayInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutCustom_Queue_ItemsInput = {
  create: UserCreateWithoutCustom_Queue_ItemsInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutDashboardSavedViewInput = {
  create: UserCreateWithoutDashboardSavedViewInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutEmailSyncRecordInput = {
  create: UserCreateWithoutEmailSyncRecordInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutFavorite_LeadsInput = {
  create: UserCreateWithoutFavorite_LeadsInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutFolder_PermissionsInput = {
  create: UserCreateWithoutFolder_PermissionsInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutFoldersInput = {
  create: UserCreateWithoutFoldersInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutFrom_Assignment_HistoryInput = {
  create: UserCreateWithoutFrom_Assignment_HistoryInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutGlobalRoutingInput = {
  create: UserCreateWithoutGlobalRoutingInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutIdle_Owned_LeadsInput = {
  create: UserCreateWithoutIdle_Owned_LeadsInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutLead_ActivitiesInput = {
  create: UserCreateWithoutLead_ActivitiesInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutLead_Import_HistoryInput = {
  create: UserCreateWithoutLead_Import_HistoryInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutLead_IntentsInput = {
  create: UserCreateWithoutLead_IntentsInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutLeads_Last_CalledInput = {
  create: UserCreateWithoutLeads_Last_CalledInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutLiveUserStatusInput = {
  create: UserCreateWithoutLiveUserStatusInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutNote_ItemInput = {
  create: UserCreateWithoutNote_ItemInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutNotificationsInput = {
  create: UserCreateWithoutNotificationsInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutOrganizationInput = {
  create: UserCreateWithoutOrganizationInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutOwned_LeadsInput = {
  create: UserCreateWithoutOwned_LeadsInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutPayment_Settings_UpdatedInput = {
  create: UserCreateWithoutPayment_Settings_UpdatedInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutPayment_SettingsInput = {
  create: UserCreateWithoutPayment_SettingsInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutProductsCreatedInput = {
  create: UserCreateWithoutProductsCreatedInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutReceive_Transfer_AttemptsInput = {
  create: UserCreateWithoutReceive_Transfer_AttemptsInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutReceiver_Assignment_HistoryInput = {
  create: UserCreateWithoutReceiver_Assignment_HistoryInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutRoutingsInput = {
  create: UserCreateWithoutRoutingsInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutSale_Config_UpdatedInput = {
  create: UserCreateWithoutSale_Config_UpdatedInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutSale_ConfigInput = {
  create: UserCreateWithoutSale_ConfigInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutSchedule_ItemsInput = {
  create: UserCreateWithoutSchedule_ItemsInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutSend_Transfer_AttemptsInput = {
  create: UserCreateWithoutSend_Transfer_AttemptsInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutSender_Assignment_HistoryInput = {
  create: UserCreateWithoutSender_Assignment_HistoryInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutSequenceActionLogInput = {
  create: UserCreateWithoutSequenceActionLogInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutSequenceDashboardViewInput = {
  create: UserCreateWithoutSequenceDashboardViewInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutSequenceEntryExitLeadInput = {
  create: UserCreateWithoutSequenceEntryExitLeadInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutSequences_CreatedInput = {
  create: UserCreateWithoutSequences_CreatedInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutSequences_Modified_By_UserInput = {
  create: UserCreateWithoutSequences_Modified_By_UserInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutSet_User_CycleInput = {
  create: UserCreateWithoutSet_User_CycleInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutSet_User_StatInput = {
  create: UserCreateWithoutSet_User_StatInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutSmsItemsInput = {
  create: UserCreateWithoutSmsItemsInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutSnoozeTrackingInput = {
  create: UserCreateWithoutSnoozeTrackingInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutTeamInput = {
  create: UserCreateWithoutTeamInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutTemplateInput = {
  create: UserCreateWithoutTemplateInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutTemplateVersionInput = {
  create: UserCreateWithoutTemplateVersionInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutTimeOffRequestInput = {
  create: UserCreateWithoutTimeOffRequestInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutTransfer_User_CycleInput = {
  create: UserCreateWithoutTransfer_User_CycleInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutUser_Custom_AvailabilityInput = {
  create: UserCreateWithoutUser_Custom_AvailabilityInput;
  where: UserWhereUniqueInput;
};

export type UserCreateregions_White_List_ArrayInput = {
  set?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type UserCreatesales_Team_Column_OptionsInput = {
  set?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type UserCreatesub_Industry_White_List_ArrayInput = {
  set?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type UserCreatevisible_Call_Report_ColumnsInput = {
  set?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type UserCreatevisible_Call_Report_Event_ColumnsInput = {
  set?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type UserCreatevisible_Call_Report_Pipeline_ColumnsInput = {
  set?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type UserCreatevisible_My_Leads_ColumnsInput = {
  set?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type UserCreateWithoutAssociate_Owner_Rep_HistoryInput = {
  ai_user?: InputMaybe<Scalars['Boolean']['input']>;
  alternate_email?: InputMaybe<Scalars['String']['input']>;
  always_show_calendar_options?: InputMaybe<Scalars['Boolean']['input']>;
  associate_user_history?: InputMaybe<AssociationHistoryCreateNestedManyWithoutUserInput>;
  auto_transfer_selection_receive_attempts?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutFrom_UserInput>;
  auto_transfer_selection_send_attempts?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutTo_UserInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptCreateNestedManyWithoutUserInput>;
  callSharingAsCoach?: InputMaybe<CallSharingCreateNestedManyWithoutCoachInput>;
  callSharingAsUser?: InputMaybe<CallSharingCreateNestedManyWithoutReceiver_UserInput>;
  channel?: InputMaybe<Channelfilter>;
  close_rate?: InputMaybe<Scalars['Float']['input']>;
  close_user_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutClose_UserInput>;
  close_user_stat?: InputMaybe<StatLeadAggregationCreateNestedManyWithoutClose_UserInput>;
  commissions?: InputMaybe<CommissionCreateNestedManyWithoutUserInput>;
  contact_requests?: InputMaybe<ContactRequestCreateNestedManyWithoutRequesting_UserInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  current_sequence_view?: InputMaybe<SequenceDashboardViewCreateNestedOneWithoutUserInput>;
  current_team_start_date?: InputMaybe<Scalars['DateTime']['input']>;
  custom_field_white_list_array?: InputMaybe<UserCustomFieldFilterCreateNestedManyWithoutUserInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemCreateNestedManyWithoutUserInput>;
  daily_lead_claim?: InputMaybe<Scalars['Int']['input']>;
  dashboard_2_access?: InputMaybe<Scalars['Boolean']['input']>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewCreateNestedManyWithoutUserInput>;
  default_audio_input?: InputMaybe<Scalars['String']['input']>;
  default_audio_output?: InputMaybe<Scalars['String']['input']>;
  default_availability?: InputMaybe<Scalars['Boolean']['input']>;
  deleted_at?: InputMaybe<Scalars['DateTime']['input']>;
  deprecate_old_session?: InputMaybe<Scalars['Boolean']['input']>;
  email: Scalars['String']['input'];
  EmailSyncRecord?: InputMaybe<EmailSyncRecordCreateNestedManyWithoutUserInput>;
  favorite_leads?: InputMaybe<LeadCreateNestedManyWithoutFavorited_By_UsersInput>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  folder_permissions?: InputMaybe<FolderPermissionCreateNestedManyWithoutUserInput>;
  folders?: InputMaybe<FolderCreateNestedManyWithoutOwnerInput>;
  from_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutOld_UserInput>;
  GlobalRouting?: InputMaybe<GlobalRepRoutingCreateNestedManyWithoutRepInput>;
  google_refresh_token?: InputMaybe<Scalars['String']['input']>;
  holds_per_prospect_vs_held_demo_per_dial?: InputMaybe<Scalars['Float']['input']>;
  hook_subscriptions?: InputMaybe<WebHookSubscriptionCreateNestedManyWithoutUserInput>;
  hubspot_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  idle_owned_leads?: InputMaybe<LeadCreateNestedManyWithoutSecondary_RepsInput>;
  industry_filter?: InputMaybe<Scalars['Boolean']['input']>;
  industry_white_list_array?: InputMaybe<UserCreateindustry_White_List_ArrayInput>;
  industry_white_list_assign_type?: InputMaybe<AssignType>;
  joinMe_access_token?: InputMaybe<Scalars['String']['input']>;
  joinMe_refresh_token?: InputMaybe<Scalars['String']['input']>;
  last_demo_transferred_at?: InputMaybe<Scalars['DateTime']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutUserInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryCreateNestedManyWithoutUserInput>;
  lead_intents?: InputMaybe<LeadIntentCreateNestedManyWithoutUserInput>;
  lead_source_filter?: InputMaybe<Scalars['Boolean']['input']>;
  lead_source_white_list_array?: InputMaybe<UserCreatelead_Source_White_List_ArrayInput>;
  lead_source_white_list_assign_type?: InputMaybe<AssignType>;
  leads_last_called?: InputMaybe<LeadCreateNestedManyWithoutLast_Call_Result_RepInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusCreateNestedManyWithoutUserInput>;
  manager_id?: InputMaybe<Scalars['String']['input']>;
  note_item?: InputMaybe<NoteItemCreateNestedManyWithoutUserInput>;
  notifications?: InputMaybe<NotificationCreateNestedManyWithoutUserInput>;
  organization: OrganizationCreateNestedOneWithoutUsersInput;
  owned_leads?: InputMaybe<LeadCreateNestedManyWithoutRepInput>;
  panda_doc_access?: InputMaybe<Scalars['Boolean']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  payment_settings?: InputMaybe<OrganizationPaymentSettingsCreateNestedManyWithoutCreated_ByInput>;
  payment_settings_updated?: InputMaybe<OrganizationPaymentSettingsCreateNestedManyWithoutLast_Updated_ByInput>;
  phone_number?: InputMaybe<Scalars['String']['input']>;
  post?: InputMaybe<PostCreateNestedManyWithoutAuthorInput>;
  ProductsCreated?: InputMaybe<ProductChangeLogCreateNestedManyWithoutUpdated_ByInput>;
  profile_image?: InputMaybe<Scalars['String']['input']>;
  receive_transfer_attempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutTo_UserInput>;
  receiver_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutReceiver_UserInput>;
  regions_white_list_array?: InputMaybe<UserCreateregions_White_List_ArrayInput>;
  regions_white_list_assign_type?: InputMaybe<AssignType>;
  rep_scores?: InputMaybe<Scalars['Float']['input']>;
  role?: InputMaybe<Role>;
  routings?: InputMaybe<RoutingCreateNestedManyWithoutRepInput>;
  sale_config?: InputMaybe<SaleConfigCreateNestedManyWithoutCreated_ByInput>;
  sale_config_updated?: InputMaybe<SaleConfigCreateNestedManyWithoutLast_Updated_ByInput>;
  sales_team_column_options?: InputMaybe<UserCreatesales_Team_Column_OptionsInput>;
  schedule_items?: InputMaybe<ScheduleItemCreateNestedManyWithoutUserInput>;
  send_transfer_attempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutFrom_UserInput>;
  sender_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutSender_UserInput>;
  sequence_entry_criteria_id?: InputMaybe<Scalars['String']['input']>;
  SequenceActionLog?: InputMaybe<StepActionLogCreateNestedManyWithoutRepInput>;
  sequenceDashboardView?: InputMaybe<SequenceDashboardViewCreateNestedManyWithoutOwned_ByInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutUserInput>;
  Sequences_created?: InputMaybe<SequenceCreateNestedManyWithoutCreated_ByInput>;
  Sequences_modified_by_user?: InputMaybe<SequenceCreateNestedManyWithoutLast_Updated_ByInput>;
  session_token?: InputMaybe<Scalars['String']['input']>;
  set_user_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutSet_UserInput>;
  set_user_stat?: InputMaybe<StatLeadAggregationCreateNestedManyWithoutSet_UserInput>;
  sf_user_id?: InputMaybe<Scalars['String']['input']>;
  SmsItems?: InputMaybe<SmsItemCreateNestedManyWithoutRepInput>;
  SnoozeTracking?: InputMaybe<SnoozeTrackingCreateNestedManyWithoutRepInput>;
  status?: InputMaybe<Status>;
  statusHistory?: InputMaybe<UserStatusHistoryCreateNestedManyWithoutUserInput>;
  sub_industry_white_list_array?: InputMaybe<UserCreatesub_Industry_White_List_ArrayInput>;
  sub_industry_white_list_assign_type?: InputMaybe<AssignType>;
  support_rep?: InputMaybe<Scalars['Boolean']['input']>;
  taking_demos?: InputMaybe<Scalars['Boolean']['input']>;
  team?: InputMaybe<TeamCreateNestedOneWithoutMembersInput>;
  Template?: InputMaybe<TemplateCreateNestedManyWithoutLast_Modified_ByInput>;
  TemplateVersion?: InputMaybe<TemplateVersionCreateNestedManyWithoutCreated_ByInput>;
  timekit_id?: InputMaybe<Scalars['String']['input']>;
  timekit_type?: InputMaybe<Timekittype>;
  timeOffRequest?: InputMaybe<TimeOffRequestCreateNestedManyWithoutRequesting_UserInput>;
  timezone_raw?: InputMaybe<Scalars['String']['input']>;
  transfer_type?: InputMaybe<TransferType>;
  transfer_user_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutTransfer_UserInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user_custom_availability?: InputMaybe<AvailabilityCreateNestedManyWithoutUserInput>;
  user_past_tokens?: InputMaybe<UserPastTokensCreateNestedManyWithoutUserInput>;
  visible_all_leads_sm?: InputMaybe<Scalars['Boolean']['input']>;
  visible_call_report_columns?: InputMaybe<UserCreatevisible_Call_Report_ColumnsInput>;
  visible_call_report_event_columns?: InputMaybe<UserCreatevisible_Call_Report_Event_ColumnsInput>;
  visible_call_report_pipeline_columns?: InputMaybe<UserCreatevisible_Call_Report_Pipeline_ColumnsInput>;
  visible_my_leads_columns?: InputMaybe<UserCreatevisible_My_Leads_ColumnsInput>;
  voicemail_url?: InputMaybe<Scalars['String']['input']>;
};

export type UserCreateWithoutAssociate_User_HistoryInput = {
  ai_user?: InputMaybe<Scalars['Boolean']['input']>;
  alternate_email?: InputMaybe<Scalars['String']['input']>;
  always_show_calendar_options?: InputMaybe<Scalars['Boolean']['input']>;
  associate_owner_rep_history?: InputMaybe<AssociationHistoryCreateNestedManyWithoutRepInput>;
  auto_transfer_selection_receive_attempts?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutFrom_UserInput>;
  auto_transfer_selection_send_attempts?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutTo_UserInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptCreateNestedManyWithoutUserInput>;
  callSharingAsCoach?: InputMaybe<CallSharingCreateNestedManyWithoutCoachInput>;
  callSharingAsUser?: InputMaybe<CallSharingCreateNestedManyWithoutReceiver_UserInput>;
  channel?: InputMaybe<Channelfilter>;
  close_rate?: InputMaybe<Scalars['Float']['input']>;
  close_user_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutClose_UserInput>;
  close_user_stat?: InputMaybe<StatLeadAggregationCreateNestedManyWithoutClose_UserInput>;
  commissions?: InputMaybe<CommissionCreateNestedManyWithoutUserInput>;
  contact_requests?: InputMaybe<ContactRequestCreateNestedManyWithoutRequesting_UserInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  current_sequence_view?: InputMaybe<SequenceDashboardViewCreateNestedOneWithoutUserInput>;
  current_team_start_date?: InputMaybe<Scalars['DateTime']['input']>;
  custom_field_white_list_array?: InputMaybe<UserCustomFieldFilterCreateNestedManyWithoutUserInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemCreateNestedManyWithoutUserInput>;
  daily_lead_claim?: InputMaybe<Scalars['Int']['input']>;
  dashboard_2_access?: InputMaybe<Scalars['Boolean']['input']>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewCreateNestedManyWithoutUserInput>;
  default_audio_input?: InputMaybe<Scalars['String']['input']>;
  default_audio_output?: InputMaybe<Scalars['String']['input']>;
  default_availability?: InputMaybe<Scalars['Boolean']['input']>;
  deleted_at?: InputMaybe<Scalars['DateTime']['input']>;
  deprecate_old_session?: InputMaybe<Scalars['Boolean']['input']>;
  email: Scalars['String']['input'];
  EmailSyncRecord?: InputMaybe<EmailSyncRecordCreateNestedManyWithoutUserInput>;
  favorite_leads?: InputMaybe<LeadCreateNestedManyWithoutFavorited_By_UsersInput>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  folder_permissions?: InputMaybe<FolderPermissionCreateNestedManyWithoutUserInput>;
  folders?: InputMaybe<FolderCreateNestedManyWithoutOwnerInput>;
  from_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutOld_UserInput>;
  GlobalRouting?: InputMaybe<GlobalRepRoutingCreateNestedManyWithoutRepInput>;
  google_refresh_token?: InputMaybe<Scalars['String']['input']>;
  holds_per_prospect_vs_held_demo_per_dial?: InputMaybe<Scalars['Float']['input']>;
  hook_subscriptions?: InputMaybe<WebHookSubscriptionCreateNestedManyWithoutUserInput>;
  hubspot_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  idle_owned_leads?: InputMaybe<LeadCreateNestedManyWithoutSecondary_RepsInput>;
  industry_filter?: InputMaybe<Scalars['Boolean']['input']>;
  industry_white_list_array?: InputMaybe<UserCreateindustry_White_List_ArrayInput>;
  industry_white_list_assign_type?: InputMaybe<AssignType>;
  joinMe_access_token?: InputMaybe<Scalars['String']['input']>;
  joinMe_refresh_token?: InputMaybe<Scalars['String']['input']>;
  last_demo_transferred_at?: InputMaybe<Scalars['DateTime']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutUserInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryCreateNestedManyWithoutUserInput>;
  lead_intents?: InputMaybe<LeadIntentCreateNestedManyWithoutUserInput>;
  lead_source_filter?: InputMaybe<Scalars['Boolean']['input']>;
  lead_source_white_list_array?: InputMaybe<UserCreatelead_Source_White_List_ArrayInput>;
  lead_source_white_list_assign_type?: InputMaybe<AssignType>;
  leads_last_called?: InputMaybe<LeadCreateNestedManyWithoutLast_Call_Result_RepInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusCreateNestedManyWithoutUserInput>;
  manager_id?: InputMaybe<Scalars['String']['input']>;
  note_item?: InputMaybe<NoteItemCreateNestedManyWithoutUserInput>;
  notifications?: InputMaybe<NotificationCreateNestedManyWithoutUserInput>;
  organization: OrganizationCreateNestedOneWithoutUsersInput;
  owned_leads?: InputMaybe<LeadCreateNestedManyWithoutRepInput>;
  panda_doc_access?: InputMaybe<Scalars['Boolean']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  payment_settings?: InputMaybe<OrganizationPaymentSettingsCreateNestedManyWithoutCreated_ByInput>;
  payment_settings_updated?: InputMaybe<OrganizationPaymentSettingsCreateNestedManyWithoutLast_Updated_ByInput>;
  phone_number?: InputMaybe<Scalars['String']['input']>;
  post?: InputMaybe<PostCreateNestedManyWithoutAuthorInput>;
  ProductsCreated?: InputMaybe<ProductChangeLogCreateNestedManyWithoutUpdated_ByInput>;
  profile_image?: InputMaybe<Scalars['String']['input']>;
  receive_transfer_attempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutTo_UserInput>;
  receiver_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutReceiver_UserInput>;
  regions_white_list_array?: InputMaybe<UserCreateregions_White_List_ArrayInput>;
  regions_white_list_assign_type?: InputMaybe<AssignType>;
  rep_scores?: InputMaybe<Scalars['Float']['input']>;
  role?: InputMaybe<Role>;
  routings?: InputMaybe<RoutingCreateNestedManyWithoutRepInput>;
  sale_config?: InputMaybe<SaleConfigCreateNestedManyWithoutCreated_ByInput>;
  sale_config_updated?: InputMaybe<SaleConfigCreateNestedManyWithoutLast_Updated_ByInput>;
  sales_team_column_options?: InputMaybe<UserCreatesales_Team_Column_OptionsInput>;
  schedule_items?: InputMaybe<ScheduleItemCreateNestedManyWithoutUserInput>;
  send_transfer_attempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutFrom_UserInput>;
  sender_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutSender_UserInput>;
  sequence_entry_criteria_id?: InputMaybe<Scalars['String']['input']>;
  SequenceActionLog?: InputMaybe<StepActionLogCreateNestedManyWithoutRepInput>;
  sequenceDashboardView?: InputMaybe<SequenceDashboardViewCreateNestedManyWithoutOwned_ByInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutUserInput>;
  Sequences_created?: InputMaybe<SequenceCreateNestedManyWithoutCreated_ByInput>;
  Sequences_modified_by_user?: InputMaybe<SequenceCreateNestedManyWithoutLast_Updated_ByInput>;
  session_token?: InputMaybe<Scalars['String']['input']>;
  set_user_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutSet_UserInput>;
  set_user_stat?: InputMaybe<StatLeadAggregationCreateNestedManyWithoutSet_UserInput>;
  sf_user_id?: InputMaybe<Scalars['String']['input']>;
  SmsItems?: InputMaybe<SmsItemCreateNestedManyWithoutRepInput>;
  SnoozeTracking?: InputMaybe<SnoozeTrackingCreateNestedManyWithoutRepInput>;
  status?: InputMaybe<Status>;
  statusHistory?: InputMaybe<UserStatusHistoryCreateNestedManyWithoutUserInput>;
  sub_industry_white_list_array?: InputMaybe<UserCreatesub_Industry_White_List_ArrayInput>;
  sub_industry_white_list_assign_type?: InputMaybe<AssignType>;
  support_rep?: InputMaybe<Scalars['Boolean']['input']>;
  taking_demos?: InputMaybe<Scalars['Boolean']['input']>;
  team?: InputMaybe<TeamCreateNestedOneWithoutMembersInput>;
  Template?: InputMaybe<TemplateCreateNestedManyWithoutLast_Modified_ByInput>;
  TemplateVersion?: InputMaybe<TemplateVersionCreateNestedManyWithoutCreated_ByInput>;
  timekit_id?: InputMaybe<Scalars['String']['input']>;
  timekit_type?: InputMaybe<Timekittype>;
  timeOffRequest?: InputMaybe<TimeOffRequestCreateNestedManyWithoutRequesting_UserInput>;
  timezone_raw?: InputMaybe<Scalars['String']['input']>;
  transfer_type?: InputMaybe<TransferType>;
  transfer_user_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutTransfer_UserInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user_custom_availability?: InputMaybe<AvailabilityCreateNestedManyWithoutUserInput>;
  user_past_tokens?: InputMaybe<UserPastTokensCreateNestedManyWithoutUserInput>;
  visible_all_leads_sm?: InputMaybe<Scalars['Boolean']['input']>;
  visible_call_report_columns?: InputMaybe<UserCreatevisible_Call_Report_ColumnsInput>;
  visible_call_report_event_columns?: InputMaybe<UserCreatevisible_Call_Report_Event_ColumnsInput>;
  visible_call_report_pipeline_columns?: InputMaybe<UserCreatevisible_Call_Report_Pipeline_ColumnsInput>;
  visible_my_leads_columns?: InputMaybe<UserCreatevisible_My_Leads_ColumnsInput>;
  voicemail_url?: InputMaybe<Scalars['String']['input']>;
};

export type UserCreateWithoutAuto_Transfer_Selection_Receive_AttemptsInput = {
  ai_user?: InputMaybe<Scalars['Boolean']['input']>;
  alternate_email?: InputMaybe<Scalars['String']['input']>;
  always_show_calendar_options?: InputMaybe<Scalars['Boolean']['input']>;
  associate_owner_rep_history?: InputMaybe<AssociationHistoryCreateNestedManyWithoutRepInput>;
  associate_user_history?: InputMaybe<AssociationHistoryCreateNestedManyWithoutUserInput>;
  auto_transfer_selection_send_attempts?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutTo_UserInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptCreateNestedManyWithoutUserInput>;
  callSharingAsCoach?: InputMaybe<CallSharingCreateNestedManyWithoutCoachInput>;
  callSharingAsUser?: InputMaybe<CallSharingCreateNestedManyWithoutReceiver_UserInput>;
  channel?: InputMaybe<Channelfilter>;
  close_rate?: InputMaybe<Scalars['Float']['input']>;
  close_user_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutClose_UserInput>;
  close_user_stat?: InputMaybe<StatLeadAggregationCreateNestedManyWithoutClose_UserInput>;
  commissions?: InputMaybe<CommissionCreateNestedManyWithoutUserInput>;
  contact_requests?: InputMaybe<ContactRequestCreateNestedManyWithoutRequesting_UserInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  current_sequence_view?: InputMaybe<SequenceDashboardViewCreateNestedOneWithoutUserInput>;
  current_team_start_date?: InputMaybe<Scalars['DateTime']['input']>;
  custom_field_white_list_array?: InputMaybe<UserCustomFieldFilterCreateNestedManyWithoutUserInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemCreateNestedManyWithoutUserInput>;
  daily_lead_claim?: InputMaybe<Scalars['Int']['input']>;
  dashboard_2_access?: InputMaybe<Scalars['Boolean']['input']>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewCreateNestedManyWithoutUserInput>;
  default_audio_input?: InputMaybe<Scalars['String']['input']>;
  default_audio_output?: InputMaybe<Scalars['String']['input']>;
  default_availability?: InputMaybe<Scalars['Boolean']['input']>;
  deleted_at?: InputMaybe<Scalars['DateTime']['input']>;
  deprecate_old_session?: InputMaybe<Scalars['Boolean']['input']>;
  email: Scalars['String']['input'];
  EmailSyncRecord?: InputMaybe<EmailSyncRecordCreateNestedManyWithoutUserInput>;
  favorite_leads?: InputMaybe<LeadCreateNestedManyWithoutFavorited_By_UsersInput>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  folder_permissions?: InputMaybe<FolderPermissionCreateNestedManyWithoutUserInput>;
  folders?: InputMaybe<FolderCreateNestedManyWithoutOwnerInput>;
  from_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutOld_UserInput>;
  GlobalRouting?: InputMaybe<GlobalRepRoutingCreateNestedManyWithoutRepInput>;
  google_refresh_token?: InputMaybe<Scalars['String']['input']>;
  holds_per_prospect_vs_held_demo_per_dial?: InputMaybe<Scalars['Float']['input']>;
  hook_subscriptions?: InputMaybe<WebHookSubscriptionCreateNestedManyWithoutUserInput>;
  hubspot_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  idle_owned_leads?: InputMaybe<LeadCreateNestedManyWithoutSecondary_RepsInput>;
  industry_filter?: InputMaybe<Scalars['Boolean']['input']>;
  industry_white_list_array?: InputMaybe<UserCreateindustry_White_List_ArrayInput>;
  industry_white_list_assign_type?: InputMaybe<AssignType>;
  joinMe_access_token?: InputMaybe<Scalars['String']['input']>;
  joinMe_refresh_token?: InputMaybe<Scalars['String']['input']>;
  last_demo_transferred_at?: InputMaybe<Scalars['DateTime']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutUserInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryCreateNestedManyWithoutUserInput>;
  lead_intents?: InputMaybe<LeadIntentCreateNestedManyWithoutUserInput>;
  lead_source_filter?: InputMaybe<Scalars['Boolean']['input']>;
  lead_source_white_list_array?: InputMaybe<UserCreatelead_Source_White_List_ArrayInput>;
  lead_source_white_list_assign_type?: InputMaybe<AssignType>;
  leads_last_called?: InputMaybe<LeadCreateNestedManyWithoutLast_Call_Result_RepInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusCreateNestedManyWithoutUserInput>;
  manager_id?: InputMaybe<Scalars['String']['input']>;
  note_item?: InputMaybe<NoteItemCreateNestedManyWithoutUserInput>;
  notifications?: InputMaybe<NotificationCreateNestedManyWithoutUserInput>;
  organization: OrganizationCreateNestedOneWithoutUsersInput;
  owned_leads?: InputMaybe<LeadCreateNestedManyWithoutRepInput>;
  panda_doc_access?: InputMaybe<Scalars['Boolean']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  payment_settings?: InputMaybe<OrganizationPaymentSettingsCreateNestedManyWithoutCreated_ByInput>;
  payment_settings_updated?: InputMaybe<OrganizationPaymentSettingsCreateNestedManyWithoutLast_Updated_ByInput>;
  phone_number?: InputMaybe<Scalars['String']['input']>;
  post?: InputMaybe<PostCreateNestedManyWithoutAuthorInput>;
  ProductsCreated?: InputMaybe<ProductChangeLogCreateNestedManyWithoutUpdated_ByInput>;
  profile_image?: InputMaybe<Scalars['String']['input']>;
  receive_transfer_attempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutTo_UserInput>;
  receiver_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutReceiver_UserInput>;
  regions_white_list_array?: InputMaybe<UserCreateregions_White_List_ArrayInput>;
  regions_white_list_assign_type?: InputMaybe<AssignType>;
  rep_scores?: InputMaybe<Scalars['Float']['input']>;
  role?: InputMaybe<Role>;
  routings?: InputMaybe<RoutingCreateNestedManyWithoutRepInput>;
  sale_config?: InputMaybe<SaleConfigCreateNestedManyWithoutCreated_ByInput>;
  sale_config_updated?: InputMaybe<SaleConfigCreateNestedManyWithoutLast_Updated_ByInput>;
  sales_team_column_options?: InputMaybe<UserCreatesales_Team_Column_OptionsInput>;
  schedule_items?: InputMaybe<ScheduleItemCreateNestedManyWithoutUserInput>;
  send_transfer_attempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutFrom_UserInput>;
  sender_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutSender_UserInput>;
  sequence_entry_criteria_id?: InputMaybe<Scalars['String']['input']>;
  SequenceActionLog?: InputMaybe<StepActionLogCreateNestedManyWithoutRepInput>;
  sequenceDashboardView?: InputMaybe<SequenceDashboardViewCreateNestedManyWithoutOwned_ByInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutUserInput>;
  Sequences_created?: InputMaybe<SequenceCreateNestedManyWithoutCreated_ByInput>;
  Sequences_modified_by_user?: InputMaybe<SequenceCreateNestedManyWithoutLast_Updated_ByInput>;
  session_token?: InputMaybe<Scalars['String']['input']>;
  set_user_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutSet_UserInput>;
  set_user_stat?: InputMaybe<StatLeadAggregationCreateNestedManyWithoutSet_UserInput>;
  sf_user_id?: InputMaybe<Scalars['String']['input']>;
  SmsItems?: InputMaybe<SmsItemCreateNestedManyWithoutRepInput>;
  SnoozeTracking?: InputMaybe<SnoozeTrackingCreateNestedManyWithoutRepInput>;
  status?: InputMaybe<Status>;
  statusHistory?: InputMaybe<UserStatusHistoryCreateNestedManyWithoutUserInput>;
  sub_industry_white_list_array?: InputMaybe<UserCreatesub_Industry_White_List_ArrayInput>;
  sub_industry_white_list_assign_type?: InputMaybe<AssignType>;
  support_rep?: InputMaybe<Scalars['Boolean']['input']>;
  taking_demos?: InputMaybe<Scalars['Boolean']['input']>;
  team?: InputMaybe<TeamCreateNestedOneWithoutMembersInput>;
  Template?: InputMaybe<TemplateCreateNestedManyWithoutLast_Modified_ByInput>;
  TemplateVersion?: InputMaybe<TemplateVersionCreateNestedManyWithoutCreated_ByInput>;
  timekit_id?: InputMaybe<Scalars['String']['input']>;
  timekit_type?: InputMaybe<Timekittype>;
  timeOffRequest?: InputMaybe<TimeOffRequestCreateNestedManyWithoutRequesting_UserInput>;
  timezone_raw?: InputMaybe<Scalars['String']['input']>;
  transfer_type?: InputMaybe<TransferType>;
  transfer_user_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutTransfer_UserInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user_custom_availability?: InputMaybe<AvailabilityCreateNestedManyWithoutUserInput>;
  user_past_tokens?: InputMaybe<UserPastTokensCreateNestedManyWithoutUserInput>;
  visible_all_leads_sm?: InputMaybe<Scalars['Boolean']['input']>;
  visible_call_report_columns?: InputMaybe<UserCreatevisible_Call_Report_ColumnsInput>;
  visible_call_report_event_columns?: InputMaybe<UserCreatevisible_Call_Report_Event_ColumnsInput>;
  visible_call_report_pipeline_columns?: InputMaybe<UserCreatevisible_Call_Report_Pipeline_ColumnsInput>;
  visible_my_leads_columns?: InputMaybe<UserCreatevisible_My_Leads_ColumnsInput>;
  voicemail_url?: InputMaybe<Scalars['String']['input']>;
};

export type UserCreateWithoutAuto_Transfer_Selection_Send_AttemptsInput = {
  ai_user?: InputMaybe<Scalars['Boolean']['input']>;
  alternate_email?: InputMaybe<Scalars['String']['input']>;
  always_show_calendar_options?: InputMaybe<Scalars['Boolean']['input']>;
  associate_owner_rep_history?: InputMaybe<AssociationHistoryCreateNestedManyWithoutRepInput>;
  associate_user_history?: InputMaybe<AssociationHistoryCreateNestedManyWithoutUserInput>;
  auto_transfer_selection_receive_attempts?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutFrom_UserInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptCreateNestedManyWithoutUserInput>;
  callSharingAsCoach?: InputMaybe<CallSharingCreateNestedManyWithoutCoachInput>;
  callSharingAsUser?: InputMaybe<CallSharingCreateNestedManyWithoutReceiver_UserInput>;
  channel?: InputMaybe<Channelfilter>;
  close_rate?: InputMaybe<Scalars['Float']['input']>;
  close_user_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutClose_UserInput>;
  close_user_stat?: InputMaybe<StatLeadAggregationCreateNestedManyWithoutClose_UserInput>;
  commissions?: InputMaybe<CommissionCreateNestedManyWithoutUserInput>;
  contact_requests?: InputMaybe<ContactRequestCreateNestedManyWithoutRequesting_UserInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  current_sequence_view?: InputMaybe<SequenceDashboardViewCreateNestedOneWithoutUserInput>;
  current_team_start_date?: InputMaybe<Scalars['DateTime']['input']>;
  custom_field_white_list_array?: InputMaybe<UserCustomFieldFilterCreateNestedManyWithoutUserInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemCreateNestedManyWithoutUserInput>;
  daily_lead_claim?: InputMaybe<Scalars['Int']['input']>;
  dashboard_2_access?: InputMaybe<Scalars['Boolean']['input']>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewCreateNestedManyWithoutUserInput>;
  default_audio_input?: InputMaybe<Scalars['String']['input']>;
  default_audio_output?: InputMaybe<Scalars['String']['input']>;
  default_availability?: InputMaybe<Scalars['Boolean']['input']>;
  deleted_at?: InputMaybe<Scalars['DateTime']['input']>;
  deprecate_old_session?: InputMaybe<Scalars['Boolean']['input']>;
  email: Scalars['String']['input'];
  EmailSyncRecord?: InputMaybe<EmailSyncRecordCreateNestedManyWithoutUserInput>;
  favorite_leads?: InputMaybe<LeadCreateNestedManyWithoutFavorited_By_UsersInput>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  folder_permissions?: InputMaybe<FolderPermissionCreateNestedManyWithoutUserInput>;
  folders?: InputMaybe<FolderCreateNestedManyWithoutOwnerInput>;
  from_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutOld_UserInput>;
  GlobalRouting?: InputMaybe<GlobalRepRoutingCreateNestedManyWithoutRepInput>;
  google_refresh_token?: InputMaybe<Scalars['String']['input']>;
  holds_per_prospect_vs_held_demo_per_dial?: InputMaybe<Scalars['Float']['input']>;
  hook_subscriptions?: InputMaybe<WebHookSubscriptionCreateNestedManyWithoutUserInput>;
  hubspot_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  idle_owned_leads?: InputMaybe<LeadCreateNestedManyWithoutSecondary_RepsInput>;
  industry_filter?: InputMaybe<Scalars['Boolean']['input']>;
  industry_white_list_array?: InputMaybe<UserCreateindustry_White_List_ArrayInput>;
  industry_white_list_assign_type?: InputMaybe<AssignType>;
  joinMe_access_token?: InputMaybe<Scalars['String']['input']>;
  joinMe_refresh_token?: InputMaybe<Scalars['String']['input']>;
  last_demo_transferred_at?: InputMaybe<Scalars['DateTime']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutUserInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryCreateNestedManyWithoutUserInput>;
  lead_intents?: InputMaybe<LeadIntentCreateNestedManyWithoutUserInput>;
  lead_source_filter?: InputMaybe<Scalars['Boolean']['input']>;
  lead_source_white_list_array?: InputMaybe<UserCreatelead_Source_White_List_ArrayInput>;
  lead_source_white_list_assign_type?: InputMaybe<AssignType>;
  leads_last_called?: InputMaybe<LeadCreateNestedManyWithoutLast_Call_Result_RepInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusCreateNestedManyWithoutUserInput>;
  manager_id?: InputMaybe<Scalars['String']['input']>;
  note_item?: InputMaybe<NoteItemCreateNestedManyWithoutUserInput>;
  notifications?: InputMaybe<NotificationCreateNestedManyWithoutUserInput>;
  organization: OrganizationCreateNestedOneWithoutUsersInput;
  owned_leads?: InputMaybe<LeadCreateNestedManyWithoutRepInput>;
  panda_doc_access?: InputMaybe<Scalars['Boolean']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  payment_settings?: InputMaybe<OrganizationPaymentSettingsCreateNestedManyWithoutCreated_ByInput>;
  payment_settings_updated?: InputMaybe<OrganizationPaymentSettingsCreateNestedManyWithoutLast_Updated_ByInput>;
  phone_number?: InputMaybe<Scalars['String']['input']>;
  post?: InputMaybe<PostCreateNestedManyWithoutAuthorInput>;
  ProductsCreated?: InputMaybe<ProductChangeLogCreateNestedManyWithoutUpdated_ByInput>;
  profile_image?: InputMaybe<Scalars['String']['input']>;
  receive_transfer_attempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutTo_UserInput>;
  receiver_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutReceiver_UserInput>;
  regions_white_list_array?: InputMaybe<UserCreateregions_White_List_ArrayInput>;
  regions_white_list_assign_type?: InputMaybe<AssignType>;
  rep_scores?: InputMaybe<Scalars['Float']['input']>;
  role?: InputMaybe<Role>;
  routings?: InputMaybe<RoutingCreateNestedManyWithoutRepInput>;
  sale_config?: InputMaybe<SaleConfigCreateNestedManyWithoutCreated_ByInput>;
  sale_config_updated?: InputMaybe<SaleConfigCreateNestedManyWithoutLast_Updated_ByInput>;
  sales_team_column_options?: InputMaybe<UserCreatesales_Team_Column_OptionsInput>;
  schedule_items?: InputMaybe<ScheduleItemCreateNestedManyWithoutUserInput>;
  send_transfer_attempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutFrom_UserInput>;
  sender_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutSender_UserInput>;
  sequence_entry_criteria_id?: InputMaybe<Scalars['String']['input']>;
  SequenceActionLog?: InputMaybe<StepActionLogCreateNestedManyWithoutRepInput>;
  sequenceDashboardView?: InputMaybe<SequenceDashboardViewCreateNestedManyWithoutOwned_ByInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutUserInput>;
  Sequences_created?: InputMaybe<SequenceCreateNestedManyWithoutCreated_ByInput>;
  Sequences_modified_by_user?: InputMaybe<SequenceCreateNestedManyWithoutLast_Updated_ByInput>;
  session_token?: InputMaybe<Scalars['String']['input']>;
  set_user_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutSet_UserInput>;
  set_user_stat?: InputMaybe<StatLeadAggregationCreateNestedManyWithoutSet_UserInput>;
  sf_user_id?: InputMaybe<Scalars['String']['input']>;
  SmsItems?: InputMaybe<SmsItemCreateNestedManyWithoutRepInput>;
  SnoozeTracking?: InputMaybe<SnoozeTrackingCreateNestedManyWithoutRepInput>;
  status?: InputMaybe<Status>;
  statusHistory?: InputMaybe<UserStatusHistoryCreateNestedManyWithoutUserInput>;
  sub_industry_white_list_array?: InputMaybe<UserCreatesub_Industry_White_List_ArrayInput>;
  sub_industry_white_list_assign_type?: InputMaybe<AssignType>;
  support_rep?: InputMaybe<Scalars['Boolean']['input']>;
  taking_demos?: InputMaybe<Scalars['Boolean']['input']>;
  team?: InputMaybe<TeamCreateNestedOneWithoutMembersInput>;
  Template?: InputMaybe<TemplateCreateNestedManyWithoutLast_Modified_ByInput>;
  TemplateVersion?: InputMaybe<TemplateVersionCreateNestedManyWithoutCreated_ByInput>;
  timekit_id?: InputMaybe<Scalars['String']['input']>;
  timekit_type?: InputMaybe<Timekittype>;
  timeOffRequest?: InputMaybe<TimeOffRequestCreateNestedManyWithoutRequesting_UserInput>;
  timezone_raw?: InputMaybe<Scalars['String']['input']>;
  transfer_type?: InputMaybe<TransferType>;
  transfer_user_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutTransfer_UserInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user_custom_availability?: InputMaybe<AvailabilityCreateNestedManyWithoutUserInput>;
  user_past_tokens?: InputMaybe<UserPastTokensCreateNestedManyWithoutUserInput>;
  visible_all_leads_sm?: InputMaybe<Scalars['Boolean']['input']>;
  visible_call_report_columns?: InputMaybe<UserCreatevisible_Call_Report_ColumnsInput>;
  visible_call_report_event_columns?: InputMaybe<UserCreatevisible_Call_Report_Event_ColumnsInput>;
  visible_call_report_pipeline_columns?: InputMaybe<UserCreatevisible_Call_Report_Pipeline_ColumnsInput>;
  visible_my_leads_columns?: InputMaybe<UserCreatevisible_My_Leads_ColumnsInput>;
  voicemail_url?: InputMaybe<Scalars['String']['input']>;
};

export type UserCreateWithoutCallMeNowAttemptsInput = {
  ai_user?: InputMaybe<Scalars['Boolean']['input']>;
  alternate_email?: InputMaybe<Scalars['String']['input']>;
  always_show_calendar_options?: InputMaybe<Scalars['Boolean']['input']>;
  associate_owner_rep_history?: InputMaybe<AssociationHistoryCreateNestedManyWithoutRepInput>;
  associate_user_history?: InputMaybe<AssociationHistoryCreateNestedManyWithoutUserInput>;
  auto_transfer_selection_receive_attempts?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutFrom_UserInput>;
  auto_transfer_selection_send_attempts?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutTo_UserInput>;
  callSharingAsCoach?: InputMaybe<CallSharingCreateNestedManyWithoutCoachInput>;
  callSharingAsUser?: InputMaybe<CallSharingCreateNestedManyWithoutReceiver_UserInput>;
  channel?: InputMaybe<Channelfilter>;
  close_rate?: InputMaybe<Scalars['Float']['input']>;
  close_user_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutClose_UserInput>;
  close_user_stat?: InputMaybe<StatLeadAggregationCreateNestedManyWithoutClose_UserInput>;
  commissions?: InputMaybe<CommissionCreateNestedManyWithoutUserInput>;
  contact_requests?: InputMaybe<ContactRequestCreateNestedManyWithoutRequesting_UserInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  current_sequence_view?: InputMaybe<SequenceDashboardViewCreateNestedOneWithoutUserInput>;
  current_team_start_date?: InputMaybe<Scalars['DateTime']['input']>;
  custom_field_white_list_array?: InputMaybe<UserCustomFieldFilterCreateNestedManyWithoutUserInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemCreateNestedManyWithoutUserInput>;
  daily_lead_claim?: InputMaybe<Scalars['Int']['input']>;
  dashboard_2_access?: InputMaybe<Scalars['Boolean']['input']>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewCreateNestedManyWithoutUserInput>;
  default_audio_input?: InputMaybe<Scalars['String']['input']>;
  default_audio_output?: InputMaybe<Scalars['String']['input']>;
  default_availability?: InputMaybe<Scalars['Boolean']['input']>;
  deleted_at?: InputMaybe<Scalars['DateTime']['input']>;
  deprecate_old_session?: InputMaybe<Scalars['Boolean']['input']>;
  email: Scalars['String']['input'];
  EmailSyncRecord?: InputMaybe<EmailSyncRecordCreateNestedManyWithoutUserInput>;
  favorite_leads?: InputMaybe<LeadCreateNestedManyWithoutFavorited_By_UsersInput>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  folder_permissions?: InputMaybe<FolderPermissionCreateNestedManyWithoutUserInput>;
  folders?: InputMaybe<FolderCreateNestedManyWithoutOwnerInput>;
  from_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutOld_UserInput>;
  GlobalRouting?: InputMaybe<GlobalRepRoutingCreateNestedManyWithoutRepInput>;
  google_refresh_token?: InputMaybe<Scalars['String']['input']>;
  holds_per_prospect_vs_held_demo_per_dial?: InputMaybe<Scalars['Float']['input']>;
  hook_subscriptions?: InputMaybe<WebHookSubscriptionCreateNestedManyWithoutUserInput>;
  hubspot_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  idle_owned_leads?: InputMaybe<LeadCreateNestedManyWithoutSecondary_RepsInput>;
  industry_filter?: InputMaybe<Scalars['Boolean']['input']>;
  industry_white_list_array?: InputMaybe<UserCreateindustry_White_List_ArrayInput>;
  industry_white_list_assign_type?: InputMaybe<AssignType>;
  joinMe_access_token?: InputMaybe<Scalars['String']['input']>;
  joinMe_refresh_token?: InputMaybe<Scalars['String']['input']>;
  last_demo_transferred_at?: InputMaybe<Scalars['DateTime']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutUserInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryCreateNestedManyWithoutUserInput>;
  lead_intents?: InputMaybe<LeadIntentCreateNestedManyWithoutUserInput>;
  lead_source_filter?: InputMaybe<Scalars['Boolean']['input']>;
  lead_source_white_list_array?: InputMaybe<UserCreatelead_Source_White_List_ArrayInput>;
  lead_source_white_list_assign_type?: InputMaybe<AssignType>;
  leads_last_called?: InputMaybe<LeadCreateNestedManyWithoutLast_Call_Result_RepInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusCreateNestedManyWithoutUserInput>;
  manager_id?: InputMaybe<Scalars['String']['input']>;
  note_item?: InputMaybe<NoteItemCreateNestedManyWithoutUserInput>;
  notifications?: InputMaybe<NotificationCreateNestedManyWithoutUserInput>;
  organization: OrganizationCreateNestedOneWithoutUsersInput;
  owned_leads?: InputMaybe<LeadCreateNestedManyWithoutRepInput>;
  panda_doc_access?: InputMaybe<Scalars['Boolean']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  payment_settings?: InputMaybe<OrganizationPaymentSettingsCreateNestedManyWithoutCreated_ByInput>;
  payment_settings_updated?: InputMaybe<OrganizationPaymentSettingsCreateNestedManyWithoutLast_Updated_ByInput>;
  phone_number?: InputMaybe<Scalars['String']['input']>;
  post?: InputMaybe<PostCreateNestedManyWithoutAuthorInput>;
  ProductsCreated?: InputMaybe<ProductChangeLogCreateNestedManyWithoutUpdated_ByInput>;
  profile_image?: InputMaybe<Scalars['String']['input']>;
  receive_transfer_attempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutTo_UserInput>;
  receiver_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutReceiver_UserInput>;
  regions_white_list_array?: InputMaybe<UserCreateregions_White_List_ArrayInput>;
  regions_white_list_assign_type?: InputMaybe<AssignType>;
  rep_scores?: InputMaybe<Scalars['Float']['input']>;
  role?: InputMaybe<Role>;
  routings?: InputMaybe<RoutingCreateNestedManyWithoutRepInput>;
  sale_config?: InputMaybe<SaleConfigCreateNestedManyWithoutCreated_ByInput>;
  sale_config_updated?: InputMaybe<SaleConfigCreateNestedManyWithoutLast_Updated_ByInput>;
  sales_team_column_options?: InputMaybe<UserCreatesales_Team_Column_OptionsInput>;
  schedule_items?: InputMaybe<ScheduleItemCreateNestedManyWithoutUserInput>;
  send_transfer_attempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutFrom_UserInput>;
  sender_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutSender_UserInput>;
  sequence_entry_criteria_id?: InputMaybe<Scalars['String']['input']>;
  SequenceActionLog?: InputMaybe<StepActionLogCreateNestedManyWithoutRepInput>;
  sequenceDashboardView?: InputMaybe<SequenceDashboardViewCreateNestedManyWithoutOwned_ByInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutUserInput>;
  Sequences_created?: InputMaybe<SequenceCreateNestedManyWithoutCreated_ByInput>;
  Sequences_modified_by_user?: InputMaybe<SequenceCreateNestedManyWithoutLast_Updated_ByInput>;
  session_token?: InputMaybe<Scalars['String']['input']>;
  set_user_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutSet_UserInput>;
  set_user_stat?: InputMaybe<StatLeadAggregationCreateNestedManyWithoutSet_UserInput>;
  sf_user_id?: InputMaybe<Scalars['String']['input']>;
  SmsItems?: InputMaybe<SmsItemCreateNestedManyWithoutRepInput>;
  SnoozeTracking?: InputMaybe<SnoozeTrackingCreateNestedManyWithoutRepInput>;
  status?: InputMaybe<Status>;
  statusHistory?: InputMaybe<UserStatusHistoryCreateNestedManyWithoutUserInput>;
  sub_industry_white_list_array?: InputMaybe<UserCreatesub_Industry_White_List_ArrayInput>;
  sub_industry_white_list_assign_type?: InputMaybe<AssignType>;
  support_rep?: InputMaybe<Scalars['Boolean']['input']>;
  taking_demos?: InputMaybe<Scalars['Boolean']['input']>;
  team?: InputMaybe<TeamCreateNestedOneWithoutMembersInput>;
  Template?: InputMaybe<TemplateCreateNestedManyWithoutLast_Modified_ByInput>;
  TemplateVersion?: InputMaybe<TemplateVersionCreateNestedManyWithoutCreated_ByInput>;
  timekit_id?: InputMaybe<Scalars['String']['input']>;
  timekit_type?: InputMaybe<Timekittype>;
  timeOffRequest?: InputMaybe<TimeOffRequestCreateNestedManyWithoutRequesting_UserInput>;
  timezone_raw?: InputMaybe<Scalars['String']['input']>;
  transfer_type?: InputMaybe<TransferType>;
  transfer_user_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutTransfer_UserInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user_custom_availability?: InputMaybe<AvailabilityCreateNestedManyWithoutUserInput>;
  user_past_tokens?: InputMaybe<UserPastTokensCreateNestedManyWithoutUserInput>;
  visible_all_leads_sm?: InputMaybe<Scalars['Boolean']['input']>;
  visible_call_report_columns?: InputMaybe<UserCreatevisible_Call_Report_ColumnsInput>;
  visible_call_report_event_columns?: InputMaybe<UserCreatevisible_Call_Report_Event_ColumnsInput>;
  visible_call_report_pipeline_columns?: InputMaybe<UserCreatevisible_Call_Report_Pipeline_ColumnsInput>;
  visible_my_leads_columns?: InputMaybe<UserCreatevisible_My_Leads_ColumnsInput>;
  voicemail_url?: InputMaybe<Scalars['String']['input']>;
};

export type UserCreateWithoutCallSharingAsCoachInput = {
  ai_user?: InputMaybe<Scalars['Boolean']['input']>;
  alternate_email?: InputMaybe<Scalars['String']['input']>;
  always_show_calendar_options?: InputMaybe<Scalars['Boolean']['input']>;
  associate_owner_rep_history?: InputMaybe<AssociationHistoryCreateNestedManyWithoutRepInput>;
  associate_user_history?: InputMaybe<AssociationHistoryCreateNestedManyWithoutUserInput>;
  auto_transfer_selection_receive_attempts?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutFrom_UserInput>;
  auto_transfer_selection_send_attempts?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutTo_UserInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptCreateNestedManyWithoutUserInput>;
  callSharingAsUser?: InputMaybe<CallSharingCreateNestedManyWithoutReceiver_UserInput>;
  channel?: InputMaybe<Channelfilter>;
  close_rate?: InputMaybe<Scalars['Float']['input']>;
  close_user_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutClose_UserInput>;
  close_user_stat?: InputMaybe<StatLeadAggregationCreateNestedManyWithoutClose_UserInput>;
  commissions?: InputMaybe<CommissionCreateNestedManyWithoutUserInput>;
  contact_requests?: InputMaybe<ContactRequestCreateNestedManyWithoutRequesting_UserInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  current_sequence_view?: InputMaybe<SequenceDashboardViewCreateNestedOneWithoutUserInput>;
  current_team_start_date?: InputMaybe<Scalars['DateTime']['input']>;
  custom_field_white_list_array?: InputMaybe<UserCustomFieldFilterCreateNestedManyWithoutUserInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemCreateNestedManyWithoutUserInput>;
  daily_lead_claim?: InputMaybe<Scalars['Int']['input']>;
  dashboard_2_access?: InputMaybe<Scalars['Boolean']['input']>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewCreateNestedManyWithoutUserInput>;
  default_audio_input?: InputMaybe<Scalars['String']['input']>;
  default_audio_output?: InputMaybe<Scalars['String']['input']>;
  default_availability?: InputMaybe<Scalars['Boolean']['input']>;
  deleted_at?: InputMaybe<Scalars['DateTime']['input']>;
  deprecate_old_session?: InputMaybe<Scalars['Boolean']['input']>;
  email: Scalars['String']['input'];
  EmailSyncRecord?: InputMaybe<EmailSyncRecordCreateNestedManyWithoutUserInput>;
  favorite_leads?: InputMaybe<LeadCreateNestedManyWithoutFavorited_By_UsersInput>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  folder_permissions?: InputMaybe<FolderPermissionCreateNestedManyWithoutUserInput>;
  folders?: InputMaybe<FolderCreateNestedManyWithoutOwnerInput>;
  from_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutOld_UserInput>;
  GlobalRouting?: InputMaybe<GlobalRepRoutingCreateNestedManyWithoutRepInput>;
  google_refresh_token?: InputMaybe<Scalars['String']['input']>;
  holds_per_prospect_vs_held_demo_per_dial?: InputMaybe<Scalars['Float']['input']>;
  hook_subscriptions?: InputMaybe<WebHookSubscriptionCreateNestedManyWithoutUserInput>;
  hubspot_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  idle_owned_leads?: InputMaybe<LeadCreateNestedManyWithoutSecondary_RepsInput>;
  industry_filter?: InputMaybe<Scalars['Boolean']['input']>;
  industry_white_list_array?: InputMaybe<UserCreateindustry_White_List_ArrayInput>;
  industry_white_list_assign_type?: InputMaybe<AssignType>;
  joinMe_access_token?: InputMaybe<Scalars['String']['input']>;
  joinMe_refresh_token?: InputMaybe<Scalars['String']['input']>;
  last_demo_transferred_at?: InputMaybe<Scalars['DateTime']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutUserInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryCreateNestedManyWithoutUserInput>;
  lead_intents?: InputMaybe<LeadIntentCreateNestedManyWithoutUserInput>;
  lead_source_filter?: InputMaybe<Scalars['Boolean']['input']>;
  lead_source_white_list_array?: InputMaybe<UserCreatelead_Source_White_List_ArrayInput>;
  lead_source_white_list_assign_type?: InputMaybe<AssignType>;
  leads_last_called?: InputMaybe<LeadCreateNestedManyWithoutLast_Call_Result_RepInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusCreateNestedManyWithoutUserInput>;
  manager_id?: InputMaybe<Scalars['String']['input']>;
  note_item?: InputMaybe<NoteItemCreateNestedManyWithoutUserInput>;
  notifications?: InputMaybe<NotificationCreateNestedManyWithoutUserInput>;
  organization: OrganizationCreateNestedOneWithoutUsersInput;
  owned_leads?: InputMaybe<LeadCreateNestedManyWithoutRepInput>;
  panda_doc_access?: InputMaybe<Scalars['Boolean']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  payment_settings?: InputMaybe<OrganizationPaymentSettingsCreateNestedManyWithoutCreated_ByInput>;
  payment_settings_updated?: InputMaybe<OrganizationPaymentSettingsCreateNestedManyWithoutLast_Updated_ByInput>;
  phone_number?: InputMaybe<Scalars['String']['input']>;
  post?: InputMaybe<PostCreateNestedManyWithoutAuthorInput>;
  ProductsCreated?: InputMaybe<ProductChangeLogCreateNestedManyWithoutUpdated_ByInput>;
  profile_image?: InputMaybe<Scalars['String']['input']>;
  receive_transfer_attempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutTo_UserInput>;
  receiver_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutReceiver_UserInput>;
  regions_white_list_array?: InputMaybe<UserCreateregions_White_List_ArrayInput>;
  regions_white_list_assign_type?: InputMaybe<AssignType>;
  rep_scores?: InputMaybe<Scalars['Float']['input']>;
  role?: InputMaybe<Role>;
  routings?: InputMaybe<RoutingCreateNestedManyWithoutRepInput>;
  sale_config?: InputMaybe<SaleConfigCreateNestedManyWithoutCreated_ByInput>;
  sale_config_updated?: InputMaybe<SaleConfigCreateNestedManyWithoutLast_Updated_ByInput>;
  sales_team_column_options?: InputMaybe<UserCreatesales_Team_Column_OptionsInput>;
  schedule_items?: InputMaybe<ScheduleItemCreateNestedManyWithoutUserInput>;
  send_transfer_attempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutFrom_UserInput>;
  sender_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutSender_UserInput>;
  sequence_entry_criteria_id?: InputMaybe<Scalars['String']['input']>;
  SequenceActionLog?: InputMaybe<StepActionLogCreateNestedManyWithoutRepInput>;
  sequenceDashboardView?: InputMaybe<SequenceDashboardViewCreateNestedManyWithoutOwned_ByInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutUserInput>;
  Sequences_created?: InputMaybe<SequenceCreateNestedManyWithoutCreated_ByInput>;
  Sequences_modified_by_user?: InputMaybe<SequenceCreateNestedManyWithoutLast_Updated_ByInput>;
  session_token?: InputMaybe<Scalars['String']['input']>;
  set_user_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutSet_UserInput>;
  set_user_stat?: InputMaybe<StatLeadAggregationCreateNestedManyWithoutSet_UserInput>;
  sf_user_id?: InputMaybe<Scalars['String']['input']>;
  SmsItems?: InputMaybe<SmsItemCreateNestedManyWithoutRepInput>;
  SnoozeTracking?: InputMaybe<SnoozeTrackingCreateNestedManyWithoutRepInput>;
  status?: InputMaybe<Status>;
  statusHistory?: InputMaybe<UserStatusHistoryCreateNestedManyWithoutUserInput>;
  sub_industry_white_list_array?: InputMaybe<UserCreatesub_Industry_White_List_ArrayInput>;
  sub_industry_white_list_assign_type?: InputMaybe<AssignType>;
  support_rep?: InputMaybe<Scalars['Boolean']['input']>;
  taking_demos?: InputMaybe<Scalars['Boolean']['input']>;
  team?: InputMaybe<TeamCreateNestedOneWithoutMembersInput>;
  Template?: InputMaybe<TemplateCreateNestedManyWithoutLast_Modified_ByInput>;
  TemplateVersion?: InputMaybe<TemplateVersionCreateNestedManyWithoutCreated_ByInput>;
  timekit_id?: InputMaybe<Scalars['String']['input']>;
  timekit_type?: InputMaybe<Timekittype>;
  timeOffRequest?: InputMaybe<TimeOffRequestCreateNestedManyWithoutRequesting_UserInput>;
  timezone_raw?: InputMaybe<Scalars['String']['input']>;
  transfer_type?: InputMaybe<TransferType>;
  transfer_user_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutTransfer_UserInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user_custom_availability?: InputMaybe<AvailabilityCreateNestedManyWithoutUserInput>;
  user_past_tokens?: InputMaybe<UserPastTokensCreateNestedManyWithoutUserInput>;
  visible_all_leads_sm?: InputMaybe<Scalars['Boolean']['input']>;
  visible_call_report_columns?: InputMaybe<UserCreatevisible_Call_Report_ColumnsInput>;
  visible_call_report_event_columns?: InputMaybe<UserCreatevisible_Call_Report_Event_ColumnsInput>;
  visible_call_report_pipeline_columns?: InputMaybe<UserCreatevisible_Call_Report_Pipeline_ColumnsInput>;
  visible_my_leads_columns?: InputMaybe<UserCreatevisible_My_Leads_ColumnsInput>;
  voicemail_url?: InputMaybe<Scalars['String']['input']>;
};

export type UserCreateWithoutCallSharingAsUserInput = {
  ai_user?: InputMaybe<Scalars['Boolean']['input']>;
  alternate_email?: InputMaybe<Scalars['String']['input']>;
  always_show_calendar_options?: InputMaybe<Scalars['Boolean']['input']>;
  associate_owner_rep_history?: InputMaybe<AssociationHistoryCreateNestedManyWithoutRepInput>;
  associate_user_history?: InputMaybe<AssociationHistoryCreateNestedManyWithoutUserInput>;
  auto_transfer_selection_receive_attempts?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutFrom_UserInput>;
  auto_transfer_selection_send_attempts?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutTo_UserInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptCreateNestedManyWithoutUserInput>;
  callSharingAsCoach?: InputMaybe<CallSharingCreateNestedManyWithoutCoachInput>;
  channel?: InputMaybe<Channelfilter>;
  close_rate?: InputMaybe<Scalars['Float']['input']>;
  close_user_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutClose_UserInput>;
  close_user_stat?: InputMaybe<StatLeadAggregationCreateNestedManyWithoutClose_UserInput>;
  commissions?: InputMaybe<CommissionCreateNestedManyWithoutUserInput>;
  contact_requests?: InputMaybe<ContactRequestCreateNestedManyWithoutRequesting_UserInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  current_sequence_view?: InputMaybe<SequenceDashboardViewCreateNestedOneWithoutUserInput>;
  current_team_start_date?: InputMaybe<Scalars['DateTime']['input']>;
  custom_field_white_list_array?: InputMaybe<UserCustomFieldFilterCreateNestedManyWithoutUserInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemCreateNestedManyWithoutUserInput>;
  daily_lead_claim?: InputMaybe<Scalars['Int']['input']>;
  dashboard_2_access?: InputMaybe<Scalars['Boolean']['input']>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewCreateNestedManyWithoutUserInput>;
  default_audio_input?: InputMaybe<Scalars['String']['input']>;
  default_audio_output?: InputMaybe<Scalars['String']['input']>;
  default_availability?: InputMaybe<Scalars['Boolean']['input']>;
  deleted_at?: InputMaybe<Scalars['DateTime']['input']>;
  deprecate_old_session?: InputMaybe<Scalars['Boolean']['input']>;
  email: Scalars['String']['input'];
  EmailSyncRecord?: InputMaybe<EmailSyncRecordCreateNestedManyWithoutUserInput>;
  favorite_leads?: InputMaybe<LeadCreateNestedManyWithoutFavorited_By_UsersInput>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  folder_permissions?: InputMaybe<FolderPermissionCreateNestedManyWithoutUserInput>;
  folders?: InputMaybe<FolderCreateNestedManyWithoutOwnerInput>;
  from_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutOld_UserInput>;
  GlobalRouting?: InputMaybe<GlobalRepRoutingCreateNestedManyWithoutRepInput>;
  google_refresh_token?: InputMaybe<Scalars['String']['input']>;
  holds_per_prospect_vs_held_demo_per_dial?: InputMaybe<Scalars['Float']['input']>;
  hook_subscriptions?: InputMaybe<WebHookSubscriptionCreateNestedManyWithoutUserInput>;
  hubspot_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  idle_owned_leads?: InputMaybe<LeadCreateNestedManyWithoutSecondary_RepsInput>;
  industry_filter?: InputMaybe<Scalars['Boolean']['input']>;
  industry_white_list_array?: InputMaybe<UserCreateindustry_White_List_ArrayInput>;
  industry_white_list_assign_type?: InputMaybe<AssignType>;
  joinMe_access_token?: InputMaybe<Scalars['String']['input']>;
  joinMe_refresh_token?: InputMaybe<Scalars['String']['input']>;
  last_demo_transferred_at?: InputMaybe<Scalars['DateTime']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutUserInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryCreateNestedManyWithoutUserInput>;
  lead_intents?: InputMaybe<LeadIntentCreateNestedManyWithoutUserInput>;
  lead_source_filter?: InputMaybe<Scalars['Boolean']['input']>;
  lead_source_white_list_array?: InputMaybe<UserCreatelead_Source_White_List_ArrayInput>;
  lead_source_white_list_assign_type?: InputMaybe<AssignType>;
  leads_last_called?: InputMaybe<LeadCreateNestedManyWithoutLast_Call_Result_RepInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusCreateNestedManyWithoutUserInput>;
  manager_id?: InputMaybe<Scalars['String']['input']>;
  note_item?: InputMaybe<NoteItemCreateNestedManyWithoutUserInput>;
  notifications?: InputMaybe<NotificationCreateNestedManyWithoutUserInput>;
  organization: OrganizationCreateNestedOneWithoutUsersInput;
  owned_leads?: InputMaybe<LeadCreateNestedManyWithoutRepInput>;
  panda_doc_access?: InputMaybe<Scalars['Boolean']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  payment_settings?: InputMaybe<OrganizationPaymentSettingsCreateNestedManyWithoutCreated_ByInput>;
  payment_settings_updated?: InputMaybe<OrganizationPaymentSettingsCreateNestedManyWithoutLast_Updated_ByInput>;
  phone_number?: InputMaybe<Scalars['String']['input']>;
  post?: InputMaybe<PostCreateNestedManyWithoutAuthorInput>;
  ProductsCreated?: InputMaybe<ProductChangeLogCreateNestedManyWithoutUpdated_ByInput>;
  profile_image?: InputMaybe<Scalars['String']['input']>;
  receive_transfer_attempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutTo_UserInput>;
  receiver_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutReceiver_UserInput>;
  regions_white_list_array?: InputMaybe<UserCreateregions_White_List_ArrayInput>;
  regions_white_list_assign_type?: InputMaybe<AssignType>;
  rep_scores?: InputMaybe<Scalars['Float']['input']>;
  role?: InputMaybe<Role>;
  routings?: InputMaybe<RoutingCreateNestedManyWithoutRepInput>;
  sale_config?: InputMaybe<SaleConfigCreateNestedManyWithoutCreated_ByInput>;
  sale_config_updated?: InputMaybe<SaleConfigCreateNestedManyWithoutLast_Updated_ByInput>;
  sales_team_column_options?: InputMaybe<UserCreatesales_Team_Column_OptionsInput>;
  schedule_items?: InputMaybe<ScheduleItemCreateNestedManyWithoutUserInput>;
  send_transfer_attempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutFrom_UserInput>;
  sender_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutSender_UserInput>;
  sequence_entry_criteria_id?: InputMaybe<Scalars['String']['input']>;
  SequenceActionLog?: InputMaybe<StepActionLogCreateNestedManyWithoutRepInput>;
  sequenceDashboardView?: InputMaybe<SequenceDashboardViewCreateNestedManyWithoutOwned_ByInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutUserInput>;
  Sequences_created?: InputMaybe<SequenceCreateNestedManyWithoutCreated_ByInput>;
  Sequences_modified_by_user?: InputMaybe<SequenceCreateNestedManyWithoutLast_Updated_ByInput>;
  session_token?: InputMaybe<Scalars['String']['input']>;
  set_user_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutSet_UserInput>;
  set_user_stat?: InputMaybe<StatLeadAggregationCreateNestedManyWithoutSet_UserInput>;
  sf_user_id?: InputMaybe<Scalars['String']['input']>;
  SmsItems?: InputMaybe<SmsItemCreateNestedManyWithoutRepInput>;
  SnoozeTracking?: InputMaybe<SnoozeTrackingCreateNestedManyWithoutRepInput>;
  status?: InputMaybe<Status>;
  statusHistory?: InputMaybe<UserStatusHistoryCreateNestedManyWithoutUserInput>;
  sub_industry_white_list_array?: InputMaybe<UserCreatesub_Industry_White_List_ArrayInput>;
  sub_industry_white_list_assign_type?: InputMaybe<AssignType>;
  support_rep?: InputMaybe<Scalars['Boolean']['input']>;
  taking_demos?: InputMaybe<Scalars['Boolean']['input']>;
  team?: InputMaybe<TeamCreateNestedOneWithoutMembersInput>;
  Template?: InputMaybe<TemplateCreateNestedManyWithoutLast_Modified_ByInput>;
  TemplateVersion?: InputMaybe<TemplateVersionCreateNestedManyWithoutCreated_ByInput>;
  timekit_id?: InputMaybe<Scalars['String']['input']>;
  timekit_type?: InputMaybe<Timekittype>;
  timeOffRequest?: InputMaybe<TimeOffRequestCreateNestedManyWithoutRequesting_UserInput>;
  timezone_raw?: InputMaybe<Scalars['String']['input']>;
  transfer_type?: InputMaybe<TransferType>;
  transfer_user_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutTransfer_UserInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user_custom_availability?: InputMaybe<AvailabilityCreateNestedManyWithoutUserInput>;
  user_past_tokens?: InputMaybe<UserPastTokensCreateNestedManyWithoutUserInput>;
  visible_all_leads_sm?: InputMaybe<Scalars['Boolean']['input']>;
  visible_call_report_columns?: InputMaybe<UserCreatevisible_Call_Report_ColumnsInput>;
  visible_call_report_event_columns?: InputMaybe<UserCreatevisible_Call_Report_Event_ColumnsInput>;
  visible_call_report_pipeline_columns?: InputMaybe<UserCreatevisible_Call_Report_Pipeline_ColumnsInput>;
  visible_my_leads_columns?: InputMaybe<UserCreatevisible_My_Leads_ColumnsInput>;
  voicemail_url?: InputMaybe<Scalars['String']['input']>;
};

export type UserCreateWithoutClose_User_CycleInput = {
  ai_user?: InputMaybe<Scalars['Boolean']['input']>;
  alternate_email?: InputMaybe<Scalars['String']['input']>;
  always_show_calendar_options?: InputMaybe<Scalars['Boolean']['input']>;
  associate_owner_rep_history?: InputMaybe<AssociationHistoryCreateNestedManyWithoutRepInput>;
  associate_user_history?: InputMaybe<AssociationHistoryCreateNestedManyWithoutUserInput>;
  auto_transfer_selection_receive_attempts?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutFrom_UserInput>;
  auto_transfer_selection_send_attempts?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutTo_UserInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptCreateNestedManyWithoutUserInput>;
  callSharingAsCoach?: InputMaybe<CallSharingCreateNestedManyWithoutCoachInput>;
  callSharingAsUser?: InputMaybe<CallSharingCreateNestedManyWithoutReceiver_UserInput>;
  channel?: InputMaybe<Channelfilter>;
  close_rate?: InputMaybe<Scalars['Float']['input']>;
  close_user_stat?: InputMaybe<StatLeadAggregationCreateNestedManyWithoutClose_UserInput>;
  commissions?: InputMaybe<CommissionCreateNestedManyWithoutUserInput>;
  contact_requests?: InputMaybe<ContactRequestCreateNestedManyWithoutRequesting_UserInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  current_sequence_view?: InputMaybe<SequenceDashboardViewCreateNestedOneWithoutUserInput>;
  current_team_start_date?: InputMaybe<Scalars['DateTime']['input']>;
  custom_field_white_list_array?: InputMaybe<UserCustomFieldFilterCreateNestedManyWithoutUserInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemCreateNestedManyWithoutUserInput>;
  daily_lead_claim?: InputMaybe<Scalars['Int']['input']>;
  dashboard_2_access?: InputMaybe<Scalars['Boolean']['input']>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewCreateNestedManyWithoutUserInput>;
  default_audio_input?: InputMaybe<Scalars['String']['input']>;
  default_audio_output?: InputMaybe<Scalars['String']['input']>;
  default_availability?: InputMaybe<Scalars['Boolean']['input']>;
  deleted_at?: InputMaybe<Scalars['DateTime']['input']>;
  deprecate_old_session?: InputMaybe<Scalars['Boolean']['input']>;
  email: Scalars['String']['input'];
  EmailSyncRecord?: InputMaybe<EmailSyncRecordCreateNestedManyWithoutUserInput>;
  favorite_leads?: InputMaybe<LeadCreateNestedManyWithoutFavorited_By_UsersInput>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  folder_permissions?: InputMaybe<FolderPermissionCreateNestedManyWithoutUserInput>;
  folders?: InputMaybe<FolderCreateNestedManyWithoutOwnerInput>;
  from_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutOld_UserInput>;
  GlobalRouting?: InputMaybe<GlobalRepRoutingCreateNestedManyWithoutRepInput>;
  google_refresh_token?: InputMaybe<Scalars['String']['input']>;
  holds_per_prospect_vs_held_demo_per_dial?: InputMaybe<Scalars['Float']['input']>;
  hook_subscriptions?: InputMaybe<WebHookSubscriptionCreateNestedManyWithoutUserInput>;
  hubspot_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  idle_owned_leads?: InputMaybe<LeadCreateNestedManyWithoutSecondary_RepsInput>;
  industry_filter?: InputMaybe<Scalars['Boolean']['input']>;
  industry_white_list_array?: InputMaybe<UserCreateindustry_White_List_ArrayInput>;
  industry_white_list_assign_type?: InputMaybe<AssignType>;
  joinMe_access_token?: InputMaybe<Scalars['String']['input']>;
  joinMe_refresh_token?: InputMaybe<Scalars['String']['input']>;
  last_demo_transferred_at?: InputMaybe<Scalars['DateTime']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutUserInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryCreateNestedManyWithoutUserInput>;
  lead_intents?: InputMaybe<LeadIntentCreateNestedManyWithoutUserInput>;
  lead_source_filter?: InputMaybe<Scalars['Boolean']['input']>;
  lead_source_white_list_array?: InputMaybe<UserCreatelead_Source_White_List_ArrayInput>;
  lead_source_white_list_assign_type?: InputMaybe<AssignType>;
  leads_last_called?: InputMaybe<LeadCreateNestedManyWithoutLast_Call_Result_RepInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusCreateNestedManyWithoutUserInput>;
  manager_id?: InputMaybe<Scalars['String']['input']>;
  note_item?: InputMaybe<NoteItemCreateNestedManyWithoutUserInput>;
  notifications?: InputMaybe<NotificationCreateNestedManyWithoutUserInput>;
  organization: OrganizationCreateNestedOneWithoutUsersInput;
  owned_leads?: InputMaybe<LeadCreateNestedManyWithoutRepInput>;
  panda_doc_access?: InputMaybe<Scalars['Boolean']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  payment_settings?: InputMaybe<OrganizationPaymentSettingsCreateNestedManyWithoutCreated_ByInput>;
  payment_settings_updated?: InputMaybe<OrganizationPaymentSettingsCreateNestedManyWithoutLast_Updated_ByInput>;
  phone_number?: InputMaybe<Scalars['String']['input']>;
  post?: InputMaybe<PostCreateNestedManyWithoutAuthorInput>;
  ProductsCreated?: InputMaybe<ProductChangeLogCreateNestedManyWithoutUpdated_ByInput>;
  profile_image?: InputMaybe<Scalars['String']['input']>;
  receive_transfer_attempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutTo_UserInput>;
  receiver_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutReceiver_UserInput>;
  regions_white_list_array?: InputMaybe<UserCreateregions_White_List_ArrayInput>;
  regions_white_list_assign_type?: InputMaybe<AssignType>;
  rep_scores?: InputMaybe<Scalars['Float']['input']>;
  role?: InputMaybe<Role>;
  routings?: InputMaybe<RoutingCreateNestedManyWithoutRepInput>;
  sale_config?: InputMaybe<SaleConfigCreateNestedManyWithoutCreated_ByInput>;
  sale_config_updated?: InputMaybe<SaleConfigCreateNestedManyWithoutLast_Updated_ByInput>;
  sales_team_column_options?: InputMaybe<UserCreatesales_Team_Column_OptionsInput>;
  schedule_items?: InputMaybe<ScheduleItemCreateNestedManyWithoutUserInput>;
  send_transfer_attempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutFrom_UserInput>;
  sender_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutSender_UserInput>;
  sequence_entry_criteria_id?: InputMaybe<Scalars['String']['input']>;
  SequenceActionLog?: InputMaybe<StepActionLogCreateNestedManyWithoutRepInput>;
  sequenceDashboardView?: InputMaybe<SequenceDashboardViewCreateNestedManyWithoutOwned_ByInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutUserInput>;
  Sequences_created?: InputMaybe<SequenceCreateNestedManyWithoutCreated_ByInput>;
  Sequences_modified_by_user?: InputMaybe<SequenceCreateNestedManyWithoutLast_Updated_ByInput>;
  session_token?: InputMaybe<Scalars['String']['input']>;
  set_user_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutSet_UserInput>;
  set_user_stat?: InputMaybe<StatLeadAggregationCreateNestedManyWithoutSet_UserInput>;
  sf_user_id?: InputMaybe<Scalars['String']['input']>;
  SmsItems?: InputMaybe<SmsItemCreateNestedManyWithoutRepInput>;
  SnoozeTracking?: InputMaybe<SnoozeTrackingCreateNestedManyWithoutRepInput>;
  status?: InputMaybe<Status>;
  statusHistory?: InputMaybe<UserStatusHistoryCreateNestedManyWithoutUserInput>;
  sub_industry_white_list_array?: InputMaybe<UserCreatesub_Industry_White_List_ArrayInput>;
  sub_industry_white_list_assign_type?: InputMaybe<AssignType>;
  support_rep?: InputMaybe<Scalars['Boolean']['input']>;
  taking_demos?: InputMaybe<Scalars['Boolean']['input']>;
  team?: InputMaybe<TeamCreateNestedOneWithoutMembersInput>;
  Template?: InputMaybe<TemplateCreateNestedManyWithoutLast_Modified_ByInput>;
  TemplateVersion?: InputMaybe<TemplateVersionCreateNestedManyWithoutCreated_ByInput>;
  timekit_id?: InputMaybe<Scalars['String']['input']>;
  timekit_type?: InputMaybe<Timekittype>;
  timeOffRequest?: InputMaybe<TimeOffRequestCreateNestedManyWithoutRequesting_UserInput>;
  timezone_raw?: InputMaybe<Scalars['String']['input']>;
  transfer_type?: InputMaybe<TransferType>;
  transfer_user_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutTransfer_UserInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user_custom_availability?: InputMaybe<AvailabilityCreateNestedManyWithoutUserInput>;
  user_past_tokens?: InputMaybe<UserPastTokensCreateNestedManyWithoutUserInput>;
  visible_all_leads_sm?: InputMaybe<Scalars['Boolean']['input']>;
  visible_call_report_columns?: InputMaybe<UserCreatevisible_Call_Report_ColumnsInput>;
  visible_call_report_event_columns?: InputMaybe<UserCreatevisible_Call_Report_Event_ColumnsInput>;
  visible_call_report_pipeline_columns?: InputMaybe<UserCreatevisible_Call_Report_Pipeline_ColumnsInput>;
  visible_my_leads_columns?: InputMaybe<UserCreatevisible_My_Leads_ColumnsInput>;
  voicemail_url?: InputMaybe<Scalars['String']['input']>;
};

export type UserCreateWithoutClose_User_StatInput = {
  ai_user?: InputMaybe<Scalars['Boolean']['input']>;
  alternate_email?: InputMaybe<Scalars['String']['input']>;
  always_show_calendar_options?: InputMaybe<Scalars['Boolean']['input']>;
  associate_owner_rep_history?: InputMaybe<AssociationHistoryCreateNestedManyWithoutRepInput>;
  associate_user_history?: InputMaybe<AssociationHistoryCreateNestedManyWithoutUserInput>;
  auto_transfer_selection_receive_attempts?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutFrom_UserInput>;
  auto_transfer_selection_send_attempts?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutTo_UserInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptCreateNestedManyWithoutUserInput>;
  callSharingAsCoach?: InputMaybe<CallSharingCreateNestedManyWithoutCoachInput>;
  callSharingAsUser?: InputMaybe<CallSharingCreateNestedManyWithoutReceiver_UserInput>;
  channel?: InputMaybe<Channelfilter>;
  close_rate?: InputMaybe<Scalars['Float']['input']>;
  close_user_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutClose_UserInput>;
  commissions?: InputMaybe<CommissionCreateNestedManyWithoutUserInput>;
  contact_requests?: InputMaybe<ContactRequestCreateNestedManyWithoutRequesting_UserInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  current_sequence_view?: InputMaybe<SequenceDashboardViewCreateNestedOneWithoutUserInput>;
  current_team_start_date?: InputMaybe<Scalars['DateTime']['input']>;
  custom_field_white_list_array?: InputMaybe<UserCustomFieldFilterCreateNestedManyWithoutUserInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemCreateNestedManyWithoutUserInput>;
  daily_lead_claim?: InputMaybe<Scalars['Int']['input']>;
  dashboard_2_access?: InputMaybe<Scalars['Boolean']['input']>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewCreateNestedManyWithoutUserInput>;
  default_audio_input?: InputMaybe<Scalars['String']['input']>;
  default_audio_output?: InputMaybe<Scalars['String']['input']>;
  default_availability?: InputMaybe<Scalars['Boolean']['input']>;
  deleted_at?: InputMaybe<Scalars['DateTime']['input']>;
  deprecate_old_session?: InputMaybe<Scalars['Boolean']['input']>;
  email: Scalars['String']['input'];
  EmailSyncRecord?: InputMaybe<EmailSyncRecordCreateNestedManyWithoutUserInput>;
  favorite_leads?: InputMaybe<LeadCreateNestedManyWithoutFavorited_By_UsersInput>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  folder_permissions?: InputMaybe<FolderPermissionCreateNestedManyWithoutUserInput>;
  folders?: InputMaybe<FolderCreateNestedManyWithoutOwnerInput>;
  from_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutOld_UserInput>;
  GlobalRouting?: InputMaybe<GlobalRepRoutingCreateNestedManyWithoutRepInput>;
  google_refresh_token?: InputMaybe<Scalars['String']['input']>;
  holds_per_prospect_vs_held_demo_per_dial?: InputMaybe<Scalars['Float']['input']>;
  hook_subscriptions?: InputMaybe<WebHookSubscriptionCreateNestedManyWithoutUserInput>;
  hubspot_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  idle_owned_leads?: InputMaybe<LeadCreateNestedManyWithoutSecondary_RepsInput>;
  industry_filter?: InputMaybe<Scalars['Boolean']['input']>;
  industry_white_list_array?: InputMaybe<UserCreateindustry_White_List_ArrayInput>;
  industry_white_list_assign_type?: InputMaybe<AssignType>;
  joinMe_access_token?: InputMaybe<Scalars['String']['input']>;
  joinMe_refresh_token?: InputMaybe<Scalars['String']['input']>;
  last_demo_transferred_at?: InputMaybe<Scalars['DateTime']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutUserInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryCreateNestedManyWithoutUserInput>;
  lead_intents?: InputMaybe<LeadIntentCreateNestedManyWithoutUserInput>;
  lead_source_filter?: InputMaybe<Scalars['Boolean']['input']>;
  lead_source_white_list_array?: InputMaybe<UserCreatelead_Source_White_List_ArrayInput>;
  lead_source_white_list_assign_type?: InputMaybe<AssignType>;
  leads_last_called?: InputMaybe<LeadCreateNestedManyWithoutLast_Call_Result_RepInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusCreateNestedManyWithoutUserInput>;
  manager_id?: InputMaybe<Scalars['String']['input']>;
  note_item?: InputMaybe<NoteItemCreateNestedManyWithoutUserInput>;
  notifications?: InputMaybe<NotificationCreateNestedManyWithoutUserInput>;
  organization: OrganizationCreateNestedOneWithoutUsersInput;
  owned_leads?: InputMaybe<LeadCreateNestedManyWithoutRepInput>;
  panda_doc_access?: InputMaybe<Scalars['Boolean']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  payment_settings?: InputMaybe<OrganizationPaymentSettingsCreateNestedManyWithoutCreated_ByInput>;
  payment_settings_updated?: InputMaybe<OrganizationPaymentSettingsCreateNestedManyWithoutLast_Updated_ByInput>;
  phone_number?: InputMaybe<Scalars['String']['input']>;
  post?: InputMaybe<PostCreateNestedManyWithoutAuthorInput>;
  ProductsCreated?: InputMaybe<ProductChangeLogCreateNestedManyWithoutUpdated_ByInput>;
  profile_image?: InputMaybe<Scalars['String']['input']>;
  receive_transfer_attempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutTo_UserInput>;
  receiver_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutReceiver_UserInput>;
  regions_white_list_array?: InputMaybe<UserCreateregions_White_List_ArrayInput>;
  regions_white_list_assign_type?: InputMaybe<AssignType>;
  rep_scores?: InputMaybe<Scalars['Float']['input']>;
  role?: InputMaybe<Role>;
  routings?: InputMaybe<RoutingCreateNestedManyWithoutRepInput>;
  sale_config?: InputMaybe<SaleConfigCreateNestedManyWithoutCreated_ByInput>;
  sale_config_updated?: InputMaybe<SaleConfigCreateNestedManyWithoutLast_Updated_ByInput>;
  sales_team_column_options?: InputMaybe<UserCreatesales_Team_Column_OptionsInput>;
  schedule_items?: InputMaybe<ScheduleItemCreateNestedManyWithoutUserInput>;
  send_transfer_attempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutFrom_UserInput>;
  sender_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutSender_UserInput>;
  sequence_entry_criteria_id?: InputMaybe<Scalars['String']['input']>;
  SequenceActionLog?: InputMaybe<StepActionLogCreateNestedManyWithoutRepInput>;
  sequenceDashboardView?: InputMaybe<SequenceDashboardViewCreateNestedManyWithoutOwned_ByInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutUserInput>;
  Sequences_created?: InputMaybe<SequenceCreateNestedManyWithoutCreated_ByInput>;
  Sequences_modified_by_user?: InputMaybe<SequenceCreateNestedManyWithoutLast_Updated_ByInput>;
  session_token?: InputMaybe<Scalars['String']['input']>;
  set_user_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutSet_UserInput>;
  set_user_stat?: InputMaybe<StatLeadAggregationCreateNestedManyWithoutSet_UserInput>;
  sf_user_id?: InputMaybe<Scalars['String']['input']>;
  SmsItems?: InputMaybe<SmsItemCreateNestedManyWithoutRepInput>;
  SnoozeTracking?: InputMaybe<SnoozeTrackingCreateNestedManyWithoutRepInput>;
  status?: InputMaybe<Status>;
  statusHistory?: InputMaybe<UserStatusHistoryCreateNestedManyWithoutUserInput>;
  sub_industry_white_list_array?: InputMaybe<UserCreatesub_Industry_White_List_ArrayInput>;
  sub_industry_white_list_assign_type?: InputMaybe<AssignType>;
  support_rep?: InputMaybe<Scalars['Boolean']['input']>;
  taking_demos?: InputMaybe<Scalars['Boolean']['input']>;
  team?: InputMaybe<TeamCreateNestedOneWithoutMembersInput>;
  Template?: InputMaybe<TemplateCreateNestedManyWithoutLast_Modified_ByInput>;
  TemplateVersion?: InputMaybe<TemplateVersionCreateNestedManyWithoutCreated_ByInput>;
  timekit_id?: InputMaybe<Scalars['String']['input']>;
  timekit_type?: InputMaybe<Timekittype>;
  timeOffRequest?: InputMaybe<TimeOffRequestCreateNestedManyWithoutRequesting_UserInput>;
  timezone_raw?: InputMaybe<Scalars['String']['input']>;
  transfer_type?: InputMaybe<TransferType>;
  transfer_user_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutTransfer_UserInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user_custom_availability?: InputMaybe<AvailabilityCreateNestedManyWithoutUserInput>;
  user_past_tokens?: InputMaybe<UserPastTokensCreateNestedManyWithoutUserInput>;
  visible_all_leads_sm?: InputMaybe<Scalars['Boolean']['input']>;
  visible_call_report_columns?: InputMaybe<UserCreatevisible_Call_Report_ColumnsInput>;
  visible_call_report_event_columns?: InputMaybe<UserCreatevisible_Call_Report_Event_ColumnsInput>;
  visible_call_report_pipeline_columns?: InputMaybe<UserCreatevisible_Call_Report_Pipeline_ColumnsInput>;
  visible_my_leads_columns?: InputMaybe<UserCreatevisible_My_Leads_ColumnsInput>;
  voicemail_url?: InputMaybe<Scalars['String']['input']>;
};

export type UserCreateWithoutCommissionsInput = {
  ai_user?: InputMaybe<Scalars['Boolean']['input']>;
  alternate_email?: InputMaybe<Scalars['String']['input']>;
  always_show_calendar_options?: InputMaybe<Scalars['Boolean']['input']>;
  associate_owner_rep_history?: InputMaybe<AssociationHistoryCreateNestedManyWithoutRepInput>;
  associate_user_history?: InputMaybe<AssociationHistoryCreateNestedManyWithoutUserInput>;
  auto_transfer_selection_receive_attempts?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutFrom_UserInput>;
  auto_transfer_selection_send_attempts?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutTo_UserInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptCreateNestedManyWithoutUserInput>;
  callSharingAsCoach?: InputMaybe<CallSharingCreateNestedManyWithoutCoachInput>;
  callSharingAsUser?: InputMaybe<CallSharingCreateNestedManyWithoutReceiver_UserInput>;
  channel?: InputMaybe<Channelfilter>;
  close_rate?: InputMaybe<Scalars['Float']['input']>;
  close_user_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutClose_UserInput>;
  close_user_stat?: InputMaybe<StatLeadAggregationCreateNestedManyWithoutClose_UserInput>;
  contact_requests?: InputMaybe<ContactRequestCreateNestedManyWithoutRequesting_UserInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  current_sequence_view?: InputMaybe<SequenceDashboardViewCreateNestedOneWithoutUserInput>;
  current_team_start_date?: InputMaybe<Scalars['DateTime']['input']>;
  custom_field_white_list_array?: InputMaybe<UserCustomFieldFilterCreateNestedManyWithoutUserInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemCreateNestedManyWithoutUserInput>;
  daily_lead_claim?: InputMaybe<Scalars['Int']['input']>;
  dashboard_2_access?: InputMaybe<Scalars['Boolean']['input']>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewCreateNestedManyWithoutUserInput>;
  default_audio_input?: InputMaybe<Scalars['String']['input']>;
  default_audio_output?: InputMaybe<Scalars['String']['input']>;
  default_availability?: InputMaybe<Scalars['Boolean']['input']>;
  deleted_at?: InputMaybe<Scalars['DateTime']['input']>;
  deprecate_old_session?: InputMaybe<Scalars['Boolean']['input']>;
  email: Scalars['String']['input'];
  EmailSyncRecord?: InputMaybe<EmailSyncRecordCreateNestedManyWithoutUserInput>;
  favorite_leads?: InputMaybe<LeadCreateNestedManyWithoutFavorited_By_UsersInput>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  folder_permissions?: InputMaybe<FolderPermissionCreateNestedManyWithoutUserInput>;
  folders?: InputMaybe<FolderCreateNestedManyWithoutOwnerInput>;
  from_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutOld_UserInput>;
  GlobalRouting?: InputMaybe<GlobalRepRoutingCreateNestedManyWithoutRepInput>;
  google_refresh_token?: InputMaybe<Scalars['String']['input']>;
  holds_per_prospect_vs_held_demo_per_dial?: InputMaybe<Scalars['Float']['input']>;
  hook_subscriptions?: InputMaybe<WebHookSubscriptionCreateNestedManyWithoutUserInput>;
  hubspot_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  idle_owned_leads?: InputMaybe<LeadCreateNestedManyWithoutSecondary_RepsInput>;
  industry_filter?: InputMaybe<Scalars['Boolean']['input']>;
  industry_white_list_array?: InputMaybe<UserCreateindustry_White_List_ArrayInput>;
  industry_white_list_assign_type?: InputMaybe<AssignType>;
  joinMe_access_token?: InputMaybe<Scalars['String']['input']>;
  joinMe_refresh_token?: InputMaybe<Scalars['String']['input']>;
  last_demo_transferred_at?: InputMaybe<Scalars['DateTime']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutUserInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryCreateNestedManyWithoutUserInput>;
  lead_intents?: InputMaybe<LeadIntentCreateNestedManyWithoutUserInput>;
  lead_source_filter?: InputMaybe<Scalars['Boolean']['input']>;
  lead_source_white_list_array?: InputMaybe<UserCreatelead_Source_White_List_ArrayInput>;
  lead_source_white_list_assign_type?: InputMaybe<AssignType>;
  leads_last_called?: InputMaybe<LeadCreateNestedManyWithoutLast_Call_Result_RepInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusCreateNestedManyWithoutUserInput>;
  manager_id?: InputMaybe<Scalars['String']['input']>;
  note_item?: InputMaybe<NoteItemCreateNestedManyWithoutUserInput>;
  notifications?: InputMaybe<NotificationCreateNestedManyWithoutUserInput>;
  organization: OrganizationCreateNestedOneWithoutUsersInput;
  owned_leads?: InputMaybe<LeadCreateNestedManyWithoutRepInput>;
  panda_doc_access?: InputMaybe<Scalars['Boolean']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  payment_settings?: InputMaybe<OrganizationPaymentSettingsCreateNestedManyWithoutCreated_ByInput>;
  payment_settings_updated?: InputMaybe<OrganizationPaymentSettingsCreateNestedManyWithoutLast_Updated_ByInput>;
  phone_number?: InputMaybe<Scalars['String']['input']>;
  post?: InputMaybe<PostCreateNestedManyWithoutAuthorInput>;
  ProductsCreated?: InputMaybe<ProductChangeLogCreateNestedManyWithoutUpdated_ByInput>;
  profile_image?: InputMaybe<Scalars['String']['input']>;
  receive_transfer_attempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutTo_UserInput>;
  receiver_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutReceiver_UserInput>;
  regions_white_list_array?: InputMaybe<UserCreateregions_White_List_ArrayInput>;
  regions_white_list_assign_type?: InputMaybe<AssignType>;
  rep_scores?: InputMaybe<Scalars['Float']['input']>;
  role?: InputMaybe<Role>;
  routings?: InputMaybe<RoutingCreateNestedManyWithoutRepInput>;
  sale_config?: InputMaybe<SaleConfigCreateNestedManyWithoutCreated_ByInput>;
  sale_config_updated?: InputMaybe<SaleConfigCreateNestedManyWithoutLast_Updated_ByInput>;
  sales_team_column_options?: InputMaybe<UserCreatesales_Team_Column_OptionsInput>;
  schedule_items?: InputMaybe<ScheduleItemCreateNestedManyWithoutUserInput>;
  send_transfer_attempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutFrom_UserInput>;
  sender_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutSender_UserInput>;
  sequence_entry_criteria_id?: InputMaybe<Scalars['String']['input']>;
  SequenceActionLog?: InputMaybe<StepActionLogCreateNestedManyWithoutRepInput>;
  sequenceDashboardView?: InputMaybe<SequenceDashboardViewCreateNestedManyWithoutOwned_ByInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutUserInput>;
  Sequences_created?: InputMaybe<SequenceCreateNestedManyWithoutCreated_ByInput>;
  Sequences_modified_by_user?: InputMaybe<SequenceCreateNestedManyWithoutLast_Updated_ByInput>;
  session_token?: InputMaybe<Scalars['String']['input']>;
  set_user_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutSet_UserInput>;
  set_user_stat?: InputMaybe<StatLeadAggregationCreateNestedManyWithoutSet_UserInput>;
  sf_user_id?: InputMaybe<Scalars['String']['input']>;
  SmsItems?: InputMaybe<SmsItemCreateNestedManyWithoutRepInput>;
  SnoozeTracking?: InputMaybe<SnoozeTrackingCreateNestedManyWithoutRepInput>;
  status?: InputMaybe<Status>;
  statusHistory?: InputMaybe<UserStatusHistoryCreateNestedManyWithoutUserInput>;
  sub_industry_white_list_array?: InputMaybe<UserCreatesub_Industry_White_List_ArrayInput>;
  sub_industry_white_list_assign_type?: InputMaybe<AssignType>;
  support_rep?: InputMaybe<Scalars['Boolean']['input']>;
  taking_demos?: InputMaybe<Scalars['Boolean']['input']>;
  team?: InputMaybe<TeamCreateNestedOneWithoutMembersInput>;
  Template?: InputMaybe<TemplateCreateNestedManyWithoutLast_Modified_ByInput>;
  TemplateVersion?: InputMaybe<TemplateVersionCreateNestedManyWithoutCreated_ByInput>;
  timekit_id?: InputMaybe<Scalars['String']['input']>;
  timekit_type?: InputMaybe<Timekittype>;
  timeOffRequest?: InputMaybe<TimeOffRequestCreateNestedManyWithoutRequesting_UserInput>;
  timezone_raw?: InputMaybe<Scalars['String']['input']>;
  transfer_type?: InputMaybe<TransferType>;
  transfer_user_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutTransfer_UserInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user_custom_availability?: InputMaybe<AvailabilityCreateNestedManyWithoutUserInput>;
  user_past_tokens?: InputMaybe<UserPastTokensCreateNestedManyWithoutUserInput>;
  visible_all_leads_sm?: InputMaybe<Scalars['Boolean']['input']>;
  visible_call_report_columns?: InputMaybe<UserCreatevisible_Call_Report_ColumnsInput>;
  visible_call_report_event_columns?: InputMaybe<UserCreatevisible_Call_Report_Event_ColumnsInput>;
  visible_call_report_pipeline_columns?: InputMaybe<UserCreatevisible_Call_Report_Pipeline_ColumnsInput>;
  visible_my_leads_columns?: InputMaybe<UserCreatevisible_My_Leads_ColumnsInput>;
  voicemail_url?: InputMaybe<Scalars['String']['input']>;
};

export type UserCreateWithoutContact_RequestsInput = {
  ai_user?: InputMaybe<Scalars['Boolean']['input']>;
  alternate_email?: InputMaybe<Scalars['String']['input']>;
  always_show_calendar_options?: InputMaybe<Scalars['Boolean']['input']>;
  associate_owner_rep_history?: InputMaybe<AssociationHistoryCreateNestedManyWithoutRepInput>;
  associate_user_history?: InputMaybe<AssociationHistoryCreateNestedManyWithoutUserInput>;
  auto_transfer_selection_receive_attempts?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutFrom_UserInput>;
  auto_transfer_selection_send_attempts?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutTo_UserInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptCreateNestedManyWithoutUserInput>;
  callSharingAsCoach?: InputMaybe<CallSharingCreateNestedManyWithoutCoachInput>;
  callSharingAsUser?: InputMaybe<CallSharingCreateNestedManyWithoutReceiver_UserInput>;
  channel?: InputMaybe<Channelfilter>;
  close_rate?: InputMaybe<Scalars['Float']['input']>;
  close_user_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutClose_UserInput>;
  close_user_stat?: InputMaybe<StatLeadAggregationCreateNestedManyWithoutClose_UserInput>;
  commissions?: InputMaybe<CommissionCreateNestedManyWithoutUserInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  current_sequence_view?: InputMaybe<SequenceDashboardViewCreateNestedOneWithoutUserInput>;
  current_team_start_date?: InputMaybe<Scalars['DateTime']['input']>;
  custom_field_white_list_array?: InputMaybe<UserCustomFieldFilterCreateNestedManyWithoutUserInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemCreateNestedManyWithoutUserInput>;
  daily_lead_claim?: InputMaybe<Scalars['Int']['input']>;
  dashboard_2_access?: InputMaybe<Scalars['Boolean']['input']>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewCreateNestedManyWithoutUserInput>;
  default_audio_input?: InputMaybe<Scalars['String']['input']>;
  default_audio_output?: InputMaybe<Scalars['String']['input']>;
  default_availability?: InputMaybe<Scalars['Boolean']['input']>;
  deleted_at?: InputMaybe<Scalars['DateTime']['input']>;
  deprecate_old_session?: InputMaybe<Scalars['Boolean']['input']>;
  email: Scalars['String']['input'];
  EmailSyncRecord?: InputMaybe<EmailSyncRecordCreateNestedManyWithoutUserInput>;
  favorite_leads?: InputMaybe<LeadCreateNestedManyWithoutFavorited_By_UsersInput>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  folder_permissions?: InputMaybe<FolderPermissionCreateNestedManyWithoutUserInput>;
  folders?: InputMaybe<FolderCreateNestedManyWithoutOwnerInput>;
  from_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutOld_UserInput>;
  GlobalRouting?: InputMaybe<GlobalRepRoutingCreateNestedManyWithoutRepInput>;
  google_refresh_token?: InputMaybe<Scalars['String']['input']>;
  holds_per_prospect_vs_held_demo_per_dial?: InputMaybe<Scalars['Float']['input']>;
  hook_subscriptions?: InputMaybe<WebHookSubscriptionCreateNestedManyWithoutUserInput>;
  hubspot_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  idle_owned_leads?: InputMaybe<LeadCreateNestedManyWithoutSecondary_RepsInput>;
  industry_filter?: InputMaybe<Scalars['Boolean']['input']>;
  industry_white_list_array?: InputMaybe<UserCreateindustry_White_List_ArrayInput>;
  industry_white_list_assign_type?: InputMaybe<AssignType>;
  joinMe_access_token?: InputMaybe<Scalars['String']['input']>;
  joinMe_refresh_token?: InputMaybe<Scalars['String']['input']>;
  last_demo_transferred_at?: InputMaybe<Scalars['DateTime']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutUserInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryCreateNestedManyWithoutUserInput>;
  lead_intents?: InputMaybe<LeadIntentCreateNestedManyWithoutUserInput>;
  lead_source_filter?: InputMaybe<Scalars['Boolean']['input']>;
  lead_source_white_list_array?: InputMaybe<UserCreatelead_Source_White_List_ArrayInput>;
  lead_source_white_list_assign_type?: InputMaybe<AssignType>;
  leads_last_called?: InputMaybe<LeadCreateNestedManyWithoutLast_Call_Result_RepInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusCreateNestedManyWithoutUserInput>;
  manager_id?: InputMaybe<Scalars['String']['input']>;
  note_item?: InputMaybe<NoteItemCreateNestedManyWithoutUserInput>;
  notifications?: InputMaybe<NotificationCreateNestedManyWithoutUserInput>;
  organization: OrganizationCreateNestedOneWithoutUsersInput;
  owned_leads?: InputMaybe<LeadCreateNestedManyWithoutRepInput>;
  panda_doc_access?: InputMaybe<Scalars['Boolean']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  payment_settings?: InputMaybe<OrganizationPaymentSettingsCreateNestedManyWithoutCreated_ByInput>;
  payment_settings_updated?: InputMaybe<OrganizationPaymentSettingsCreateNestedManyWithoutLast_Updated_ByInput>;
  phone_number?: InputMaybe<Scalars['String']['input']>;
  post?: InputMaybe<PostCreateNestedManyWithoutAuthorInput>;
  ProductsCreated?: InputMaybe<ProductChangeLogCreateNestedManyWithoutUpdated_ByInput>;
  profile_image?: InputMaybe<Scalars['String']['input']>;
  receive_transfer_attempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutTo_UserInput>;
  receiver_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutReceiver_UserInput>;
  regions_white_list_array?: InputMaybe<UserCreateregions_White_List_ArrayInput>;
  regions_white_list_assign_type?: InputMaybe<AssignType>;
  rep_scores?: InputMaybe<Scalars['Float']['input']>;
  role?: InputMaybe<Role>;
  routings?: InputMaybe<RoutingCreateNestedManyWithoutRepInput>;
  sale_config?: InputMaybe<SaleConfigCreateNestedManyWithoutCreated_ByInput>;
  sale_config_updated?: InputMaybe<SaleConfigCreateNestedManyWithoutLast_Updated_ByInput>;
  sales_team_column_options?: InputMaybe<UserCreatesales_Team_Column_OptionsInput>;
  schedule_items?: InputMaybe<ScheduleItemCreateNestedManyWithoutUserInput>;
  send_transfer_attempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutFrom_UserInput>;
  sender_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutSender_UserInput>;
  sequence_entry_criteria_id?: InputMaybe<Scalars['String']['input']>;
  SequenceActionLog?: InputMaybe<StepActionLogCreateNestedManyWithoutRepInput>;
  sequenceDashboardView?: InputMaybe<SequenceDashboardViewCreateNestedManyWithoutOwned_ByInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutUserInput>;
  Sequences_created?: InputMaybe<SequenceCreateNestedManyWithoutCreated_ByInput>;
  Sequences_modified_by_user?: InputMaybe<SequenceCreateNestedManyWithoutLast_Updated_ByInput>;
  session_token?: InputMaybe<Scalars['String']['input']>;
  set_user_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutSet_UserInput>;
  set_user_stat?: InputMaybe<StatLeadAggregationCreateNestedManyWithoutSet_UserInput>;
  sf_user_id?: InputMaybe<Scalars['String']['input']>;
  SmsItems?: InputMaybe<SmsItemCreateNestedManyWithoutRepInput>;
  SnoozeTracking?: InputMaybe<SnoozeTrackingCreateNestedManyWithoutRepInput>;
  status?: InputMaybe<Status>;
  statusHistory?: InputMaybe<UserStatusHistoryCreateNestedManyWithoutUserInput>;
  sub_industry_white_list_array?: InputMaybe<UserCreatesub_Industry_White_List_ArrayInput>;
  sub_industry_white_list_assign_type?: InputMaybe<AssignType>;
  support_rep?: InputMaybe<Scalars['Boolean']['input']>;
  taking_demos?: InputMaybe<Scalars['Boolean']['input']>;
  team?: InputMaybe<TeamCreateNestedOneWithoutMembersInput>;
  Template?: InputMaybe<TemplateCreateNestedManyWithoutLast_Modified_ByInput>;
  TemplateVersion?: InputMaybe<TemplateVersionCreateNestedManyWithoutCreated_ByInput>;
  timekit_id?: InputMaybe<Scalars['String']['input']>;
  timekit_type?: InputMaybe<Timekittype>;
  timeOffRequest?: InputMaybe<TimeOffRequestCreateNestedManyWithoutRequesting_UserInput>;
  timezone_raw?: InputMaybe<Scalars['String']['input']>;
  transfer_type?: InputMaybe<TransferType>;
  transfer_user_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutTransfer_UserInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user_custom_availability?: InputMaybe<AvailabilityCreateNestedManyWithoutUserInput>;
  user_past_tokens?: InputMaybe<UserPastTokensCreateNestedManyWithoutUserInput>;
  visible_all_leads_sm?: InputMaybe<Scalars['Boolean']['input']>;
  visible_call_report_columns?: InputMaybe<UserCreatevisible_Call_Report_ColumnsInput>;
  visible_call_report_event_columns?: InputMaybe<UserCreatevisible_Call_Report_Event_ColumnsInput>;
  visible_call_report_pipeline_columns?: InputMaybe<UserCreatevisible_Call_Report_Pipeline_ColumnsInput>;
  visible_my_leads_columns?: InputMaybe<UserCreatevisible_My_Leads_ColumnsInput>;
  voicemail_url?: InputMaybe<Scalars['String']['input']>;
};

export type UserCreateWithoutCurrent_Sequence_ViewInput = {
  ai_user?: InputMaybe<Scalars['Boolean']['input']>;
  alternate_email?: InputMaybe<Scalars['String']['input']>;
  always_show_calendar_options?: InputMaybe<Scalars['Boolean']['input']>;
  associate_owner_rep_history?: InputMaybe<AssociationHistoryCreateNestedManyWithoutRepInput>;
  associate_user_history?: InputMaybe<AssociationHistoryCreateNestedManyWithoutUserInput>;
  auto_transfer_selection_receive_attempts?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutFrom_UserInput>;
  auto_transfer_selection_send_attempts?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutTo_UserInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptCreateNestedManyWithoutUserInput>;
  callSharingAsCoach?: InputMaybe<CallSharingCreateNestedManyWithoutCoachInput>;
  callSharingAsUser?: InputMaybe<CallSharingCreateNestedManyWithoutReceiver_UserInput>;
  channel?: InputMaybe<Channelfilter>;
  close_rate?: InputMaybe<Scalars['Float']['input']>;
  close_user_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutClose_UserInput>;
  close_user_stat?: InputMaybe<StatLeadAggregationCreateNestedManyWithoutClose_UserInput>;
  commissions?: InputMaybe<CommissionCreateNestedManyWithoutUserInput>;
  contact_requests?: InputMaybe<ContactRequestCreateNestedManyWithoutRequesting_UserInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  current_team_start_date?: InputMaybe<Scalars['DateTime']['input']>;
  custom_field_white_list_array?: InputMaybe<UserCustomFieldFilterCreateNestedManyWithoutUserInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemCreateNestedManyWithoutUserInput>;
  daily_lead_claim?: InputMaybe<Scalars['Int']['input']>;
  dashboard_2_access?: InputMaybe<Scalars['Boolean']['input']>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewCreateNestedManyWithoutUserInput>;
  default_audio_input?: InputMaybe<Scalars['String']['input']>;
  default_audio_output?: InputMaybe<Scalars['String']['input']>;
  default_availability?: InputMaybe<Scalars['Boolean']['input']>;
  deleted_at?: InputMaybe<Scalars['DateTime']['input']>;
  deprecate_old_session?: InputMaybe<Scalars['Boolean']['input']>;
  email: Scalars['String']['input'];
  EmailSyncRecord?: InputMaybe<EmailSyncRecordCreateNestedManyWithoutUserInput>;
  favorite_leads?: InputMaybe<LeadCreateNestedManyWithoutFavorited_By_UsersInput>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  folder_permissions?: InputMaybe<FolderPermissionCreateNestedManyWithoutUserInput>;
  folders?: InputMaybe<FolderCreateNestedManyWithoutOwnerInput>;
  from_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutOld_UserInput>;
  GlobalRouting?: InputMaybe<GlobalRepRoutingCreateNestedManyWithoutRepInput>;
  google_refresh_token?: InputMaybe<Scalars['String']['input']>;
  holds_per_prospect_vs_held_demo_per_dial?: InputMaybe<Scalars['Float']['input']>;
  hook_subscriptions?: InputMaybe<WebHookSubscriptionCreateNestedManyWithoutUserInput>;
  hubspot_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  idle_owned_leads?: InputMaybe<LeadCreateNestedManyWithoutSecondary_RepsInput>;
  industry_filter?: InputMaybe<Scalars['Boolean']['input']>;
  industry_white_list_array?: InputMaybe<UserCreateindustry_White_List_ArrayInput>;
  industry_white_list_assign_type?: InputMaybe<AssignType>;
  joinMe_access_token?: InputMaybe<Scalars['String']['input']>;
  joinMe_refresh_token?: InputMaybe<Scalars['String']['input']>;
  last_demo_transferred_at?: InputMaybe<Scalars['DateTime']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutUserInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryCreateNestedManyWithoutUserInput>;
  lead_intents?: InputMaybe<LeadIntentCreateNestedManyWithoutUserInput>;
  lead_source_filter?: InputMaybe<Scalars['Boolean']['input']>;
  lead_source_white_list_array?: InputMaybe<UserCreatelead_Source_White_List_ArrayInput>;
  lead_source_white_list_assign_type?: InputMaybe<AssignType>;
  leads_last_called?: InputMaybe<LeadCreateNestedManyWithoutLast_Call_Result_RepInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusCreateNestedManyWithoutUserInput>;
  manager_id?: InputMaybe<Scalars['String']['input']>;
  note_item?: InputMaybe<NoteItemCreateNestedManyWithoutUserInput>;
  notifications?: InputMaybe<NotificationCreateNestedManyWithoutUserInput>;
  organization: OrganizationCreateNestedOneWithoutUsersInput;
  owned_leads?: InputMaybe<LeadCreateNestedManyWithoutRepInput>;
  panda_doc_access?: InputMaybe<Scalars['Boolean']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  payment_settings?: InputMaybe<OrganizationPaymentSettingsCreateNestedManyWithoutCreated_ByInput>;
  payment_settings_updated?: InputMaybe<OrganizationPaymentSettingsCreateNestedManyWithoutLast_Updated_ByInput>;
  phone_number?: InputMaybe<Scalars['String']['input']>;
  post?: InputMaybe<PostCreateNestedManyWithoutAuthorInput>;
  ProductsCreated?: InputMaybe<ProductChangeLogCreateNestedManyWithoutUpdated_ByInput>;
  profile_image?: InputMaybe<Scalars['String']['input']>;
  receive_transfer_attempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutTo_UserInput>;
  receiver_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutReceiver_UserInput>;
  regions_white_list_array?: InputMaybe<UserCreateregions_White_List_ArrayInput>;
  regions_white_list_assign_type?: InputMaybe<AssignType>;
  rep_scores?: InputMaybe<Scalars['Float']['input']>;
  role?: InputMaybe<Role>;
  routings?: InputMaybe<RoutingCreateNestedManyWithoutRepInput>;
  sale_config?: InputMaybe<SaleConfigCreateNestedManyWithoutCreated_ByInput>;
  sale_config_updated?: InputMaybe<SaleConfigCreateNestedManyWithoutLast_Updated_ByInput>;
  sales_team_column_options?: InputMaybe<UserCreatesales_Team_Column_OptionsInput>;
  schedule_items?: InputMaybe<ScheduleItemCreateNestedManyWithoutUserInput>;
  send_transfer_attempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutFrom_UserInput>;
  sender_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutSender_UserInput>;
  sequence_entry_criteria_id?: InputMaybe<Scalars['String']['input']>;
  SequenceActionLog?: InputMaybe<StepActionLogCreateNestedManyWithoutRepInput>;
  sequenceDashboardView?: InputMaybe<SequenceDashboardViewCreateNestedManyWithoutOwned_ByInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutUserInput>;
  Sequences_created?: InputMaybe<SequenceCreateNestedManyWithoutCreated_ByInput>;
  Sequences_modified_by_user?: InputMaybe<SequenceCreateNestedManyWithoutLast_Updated_ByInput>;
  session_token?: InputMaybe<Scalars['String']['input']>;
  set_user_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutSet_UserInput>;
  set_user_stat?: InputMaybe<StatLeadAggregationCreateNestedManyWithoutSet_UserInput>;
  sf_user_id?: InputMaybe<Scalars['String']['input']>;
  SmsItems?: InputMaybe<SmsItemCreateNestedManyWithoutRepInput>;
  SnoozeTracking?: InputMaybe<SnoozeTrackingCreateNestedManyWithoutRepInput>;
  status?: InputMaybe<Status>;
  statusHistory?: InputMaybe<UserStatusHistoryCreateNestedManyWithoutUserInput>;
  sub_industry_white_list_array?: InputMaybe<UserCreatesub_Industry_White_List_ArrayInput>;
  sub_industry_white_list_assign_type?: InputMaybe<AssignType>;
  support_rep?: InputMaybe<Scalars['Boolean']['input']>;
  taking_demos?: InputMaybe<Scalars['Boolean']['input']>;
  team?: InputMaybe<TeamCreateNestedOneWithoutMembersInput>;
  Template?: InputMaybe<TemplateCreateNestedManyWithoutLast_Modified_ByInput>;
  TemplateVersion?: InputMaybe<TemplateVersionCreateNestedManyWithoutCreated_ByInput>;
  timekit_id?: InputMaybe<Scalars['String']['input']>;
  timekit_type?: InputMaybe<Timekittype>;
  timeOffRequest?: InputMaybe<TimeOffRequestCreateNestedManyWithoutRequesting_UserInput>;
  timezone_raw?: InputMaybe<Scalars['String']['input']>;
  transfer_type?: InputMaybe<TransferType>;
  transfer_user_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutTransfer_UserInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user_custom_availability?: InputMaybe<AvailabilityCreateNestedManyWithoutUserInput>;
  user_past_tokens?: InputMaybe<UserPastTokensCreateNestedManyWithoutUserInput>;
  visible_all_leads_sm?: InputMaybe<Scalars['Boolean']['input']>;
  visible_call_report_columns?: InputMaybe<UserCreatevisible_Call_Report_ColumnsInput>;
  visible_call_report_event_columns?: InputMaybe<UserCreatevisible_Call_Report_Event_ColumnsInput>;
  visible_call_report_pipeline_columns?: InputMaybe<UserCreatevisible_Call_Report_Pipeline_ColumnsInput>;
  visible_my_leads_columns?: InputMaybe<UserCreatevisible_My_Leads_ColumnsInput>;
  voicemail_url?: InputMaybe<Scalars['String']['input']>;
};

export type UserCreateWithoutCustom_Field_White_List_ArrayInput = {
  ai_user?: InputMaybe<Scalars['Boolean']['input']>;
  alternate_email?: InputMaybe<Scalars['String']['input']>;
  always_show_calendar_options?: InputMaybe<Scalars['Boolean']['input']>;
  associate_owner_rep_history?: InputMaybe<AssociationHistoryCreateNestedManyWithoutRepInput>;
  associate_user_history?: InputMaybe<AssociationHistoryCreateNestedManyWithoutUserInput>;
  auto_transfer_selection_receive_attempts?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutFrom_UserInput>;
  auto_transfer_selection_send_attempts?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutTo_UserInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptCreateNestedManyWithoutUserInput>;
  callSharingAsCoach?: InputMaybe<CallSharingCreateNestedManyWithoutCoachInput>;
  callSharingAsUser?: InputMaybe<CallSharingCreateNestedManyWithoutReceiver_UserInput>;
  channel?: InputMaybe<Channelfilter>;
  close_rate?: InputMaybe<Scalars['Float']['input']>;
  close_user_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutClose_UserInput>;
  close_user_stat?: InputMaybe<StatLeadAggregationCreateNestedManyWithoutClose_UserInput>;
  commissions?: InputMaybe<CommissionCreateNestedManyWithoutUserInput>;
  contact_requests?: InputMaybe<ContactRequestCreateNestedManyWithoutRequesting_UserInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  current_sequence_view?: InputMaybe<SequenceDashboardViewCreateNestedOneWithoutUserInput>;
  current_team_start_date?: InputMaybe<Scalars['DateTime']['input']>;
  custom_queue_items?: InputMaybe<CustomQueueItemCreateNestedManyWithoutUserInput>;
  daily_lead_claim?: InputMaybe<Scalars['Int']['input']>;
  dashboard_2_access?: InputMaybe<Scalars['Boolean']['input']>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewCreateNestedManyWithoutUserInput>;
  default_audio_input?: InputMaybe<Scalars['String']['input']>;
  default_audio_output?: InputMaybe<Scalars['String']['input']>;
  default_availability?: InputMaybe<Scalars['Boolean']['input']>;
  deleted_at?: InputMaybe<Scalars['DateTime']['input']>;
  deprecate_old_session?: InputMaybe<Scalars['Boolean']['input']>;
  email: Scalars['String']['input'];
  EmailSyncRecord?: InputMaybe<EmailSyncRecordCreateNestedManyWithoutUserInput>;
  favorite_leads?: InputMaybe<LeadCreateNestedManyWithoutFavorited_By_UsersInput>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  folder_permissions?: InputMaybe<FolderPermissionCreateNestedManyWithoutUserInput>;
  folders?: InputMaybe<FolderCreateNestedManyWithoutOwnerInput>;
  from_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutOld_UserInput>;
  GlobalRouting?: InputMaybe<GlobalRepRoutingCreateNestedManyWithoutRepInput>;
  google_refresh_token?: InputMaybe<Scalars['String']['input']>;
  holds_per_prospect_vs_held_demo_per_dial?: InputMaybe<Scalars['Float']['input']>;
  hook_subscriptions?: InputMaybe<WebHookSubscriptionCreateNestedManyWithoutUserInput>;
  hubspot_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  idle_owned_leads?: InputMaybe<LeadCreateNestedManyWithoutSecondary_RepsInput>;
  industry_filter?: InputMaybe<Scalars['Boolean']['input']>;
  industry_white_list_array?: InputMaybe<UserCreateindustry_White_List_ArrayInput>;
  industry_white_list_assign_type?: InputMaybe<AssignType>;
  joinMe_access_token?: InputMaybe<Scalars['String']['input']>;
  joinMe_refresh_token?: InputMaybe<Scalars['String']['input']>;
  last_demo_transferred_at?: InputMaybe<Scalars['DateTime']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutUserInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryCreateNestedManyWithoutUserInput>;
  lead_intents?: InputMaybe<LeadIntentCreateNestedManyWithoutUserInput>;
  lead_source_filter?: InputMaybe<Scalars['Boolean']['input']>;
  lead_source_white_list_array?: InputMaybe<UserCreatelead_Source_White_List_ArrayInput>;
  lead_source_white_list_assign_type?: InputMaybe<AssignType>;
  leads_last_called?: InputMaybe<LeadCreateNestedManyWithoutLast_Call_Result_RepInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusCreateNestedManyWithoutUserInput>;
  manager_id?: InputMaybe<Scalars['String']['input']>;
  note_item?: InputMaybe<NoteItemCreateNestedManyWithoutUserInput>;
  notifications?: InputMaybe<NotificationCreateNestedManyWithoutUserInput>;
  organization: OrganizationCreateNestedOneWithoutUsersInput;
  owned_leads?: InputMaybe<LeadCreateNestedManyWithoutRepInput>;
  panda_doc_access?: InputMaybe<Scalars['Boolean']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  payment_settings?: InputMaybe<OrganizationPaymentSettingsCreateNestedManyWithoutCreated_ByInput>;
  payment_settings_updated?: InputMaybe<OrganizationPaymentSettingsCreateNestedManyWithoutLast_Updated_ByInput>;
  phone_number?: InputMaybe<Scalars['String']['input']>;
  post?: InputMaybe<PostCreateNestedManyWithoutAuthorInput>;
  ProductsCreated?: InputMaybe<ProductChangeLogCreateNestedManyWithoutUpdated_ByInput>;
  profile_image?: InputMaybe<Scalars['String']['input']>;
  receive_transfer_attempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutTo_UserInput>;
  receiver_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutReceiver_UserInput>;
  regions_white_list_array?: InputMaybe<UserCreateregions_White_List_ArrayInput>;
  regions_white_list_assign_type?: InputMaybe<AssignType>;
  rep_scores?: InputMaybe<Scalars['Float']['input']>;
  role?: InputMaybe<Role>;
  routings?: InputMaybe<RoutingCreateNestedManyWithoutRepInput>;
  sale_config?: InputMaybe<SaleConfigCreateNestedManyWithoutCreated_ByInput>;
  sale_config_updated?: InputMaybe<SaleConfigCreateNestedManyWithoutLast_Updated_ByInput>;
  sales_team_column_options?: InputMaybe<UserCreatesales_Team_Column_OptionsInput>;
  schedule_items?: InputMaybe<ScheduleItemCreateNestedManyWithoutUserInput>;
  send_transfer_attempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutFrom_UserInput>;
  sender_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutSender_UserInput>;
  sequence_entry_criteria_id?: InputMaybe<Scalars['String']['input']>;
  SequenceActionLog?: InputMaybe<StepActionLogCreateNestedManyWithoutRepInput>;
  sequenceDashboardView?: InputMaybe<SequenceDashboardViewCreateNestedManyWithoutOwned_ByInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutUserInput>;
  Sequences_created?: InputMaybe<SequenceCreateNestedManyWithoutCreated_ByInput>;
  Sequences_modified_by_user?: InputMaybe<SequenceCreateNestedManyWithoutLast_Updated_ByInput>;
  session_token?: InputMaybe<Scalars['String']['input']>;
  set_user_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutSet_UserInput>;
  set_user_stat?: InputMaybe<StatLeadAggregationCreateNestedManyWithoutSet_UserInput>;
  sf_user_id?: InputMaybe<Scalars['String']['input']>;
  SmsItems?: InputMaybe<SmsItemCreateNestedManyWithoutRepInput>;
  SnoozeTracking?: InputMaybe<SnoozeTrackingCreateNestedManyWithoutRepInput>;
  status?: InputMaybe<Status>;
  statusHistory?: InputMaybe<UserStatusHistoryCreateNestedManyWithoutUserInput>;
  sub_industry_white_list_array?: InputMaybe<UserCreatesub_Industry_White_List_ArrayInput>;
  sub_industry_white_list_assign_type?: InputMaybe<AssignType>;
  support_rep?: InputMaybe<Scalars['Boolean']['input']>;
  taking_demos?: InputMaybe<Scalars['Boolean']['input']>;
  team?: InputMaybe<TeamCreateNestedOneWithoutMembersInput>;
  Template?: InputMaybe<TemplateCreateNestedManyWithoutLast_Modified_ByInput>;
  TemplateVersion?: InputMaybe<TemplateVersionCreateNestedManyWithoutCreated_ByInput>;
  timekit_id?: InputMaybe<Scalars['String']['input']>;
  timekit_type?: InputMaybe<Timekittype>;
  timeOffRequest?: InputMaybe<TimeOffRequestCreateNestedManyWithoutRequesting_UserInput>;
  timezone_raw?: InputMaybe<Scalars['String']['input']>;
  transfer_type?: InputMaybe<TransferType>;
  transfer_user_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutTransfer_UserInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user_custom_availability?: InputMaybe<AvailabilityCreateNestedManyWithoutUserInput>;
  user_past_tokens?: InputMaybe<UserPastTokensCreateNestedManyWithoutUserInput>;
  visible_all_leads_sm?: InputMaybe<Scalars['Boolean']['input']>;
  visible_call_report_columns?: InputMaybe<UserCreatevisible_Call_Report_ColumnsInput>;
  visible_call_report_event_columns?: InputMaybe<UserCreatevisible_Call_Report_Event_ColumnsInput>;
  visible_call_report_pipeline_columns?: InputMaybe<UserCreatevisible_Call_Report_Pipeline_ColumnsInput>;
  visible_my_leads_columns?: InputMaybe<UserCreatevisible_My_Leads_ColumnsInput>;
  voicemail_url?: InputMaybe<Scalars['String']['input']>;
};

export type UserCreateWithoutCustom_Queue_ItemsInput = {
  ai_user?: InputMaybe<Scalars['Boolean']['input']>;
  alternate_email?: InputMaybe<Scalars['String']['input']>;
  always_show_calendar_options?: InputMaybe<Scalars['Boolean']['input']>;
  associate_owner_rep_history?: InputMaybe<AssociationHistoryCreateNestedManyWithoutRepInput>;
  associate_user_history?: InputMaybe<AssociationHistoryCreateNestedManyWithoutUserInput>;
  auto_transfer_selection_receive_attempts?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutFrom_UserInput>;
  auto_transfer_selection_send_attempts?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutTo_UserInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptCreateNestedManyWithoutUserInput>;
  callSharingAsCoach?: InputMaybe<CallSharingCreateNestedManyWithoutCoachInput>;
  callSharingAsUser?: InputMaybe<CallSharingCreateNestedManyWithoutReceiver_UserInput>;
  channel?: InputMaybe<Channelfilter>;
  close_rate?: InputMaybe<Scalars['Float']['input']>;
  close_user_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutClose_UserInput>;
  close_user_stat?: InputMaybe<StatLeadAggregationCreateNestedManyWithoutClose_UserInput>;
  commissions?: InputMaybe<CommissionCreateNestedManyWithoutUserInput>;
  contact_requests?: InputMaybe<ContactRequestCreateNestedManyWithoutRequesting_UserInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  current_sequence_view?: InputMaybe<SequenceDashboardViewCreateNestedOneWithoutUserInput>;
  current_team_start_date?: InputMaybe<Scalars['DateTime']['input']>;
  custom_field_white_list_array?: InputMaybe<UserCustomFieldFilterCreateNestedManyWithoutUserInput>;
  daily_lead_claim?: InputMaybe<Scalars['Int']['input']>;
  dashboard_2_access?: InputMaybe<Scalars['Boolean']['input']>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewCreateNestedManyWithoutUserInput>;
  default_audio_input?: InputMaybe<Scalars['String']['input']>;
  default_audio_output?: InputMaybe<Scalars['String']['input']>;
  default_availability?: InputMaybe<Scalars['Boolean']['input']>;
  deleted_at?: InputMaybe<Scalars['DateTime']['input']>;
  deprecate_old_session?: InputMaybe<Scalars['Boolean']['input']>;
  email: Scalars['String']['input'];
  EmailSyncRecord?: InputMaybe<EmailSyncRecordCreateNestedManyWithoutUserInput>;
  favorite_leads?: InputMaybe<LeadCreateNestedManyWithoutFavorited_By_UsersInput>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  folder_permissions?: InputMaybe<FolderPermissionCreateNestedManyWithoutUserInput>;
  folders?: InputMaybe<FolderCreateNestedManyWithoutOwnerInput>;
  from_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutOld_UserInput>;
  GlobalRouting?: InputMaybe<GlobalRepRoutingCreateNestedManyWithoutRepInput>;
  google_refresh_token?: InputMaybe<Scalars['String']['input']>;
  holds_per_prospect_vs_held_demo_per_dial?: InputMaybe<Scalars['Float']['input']>;
  hook_subscriptions?: InputMaybe<WebHookSubscriptionCreateNestedManyWithoutUserInput>;
  hubspot_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  idle_owned_leads?: InputMaybe<LeadCreateNestedManyWithoutSecondary_RepsInput>;
  industry_filter?: InputMaybe<Scalars['Boolean']['input']>;
  industry_white_list_array?: InputMaybe<UserCreateindustry_White_List_ArrayInput>;
  industry_white_list_assign_type?: InputMaybe<AssignType>;
  joinMe_access_token?: InputMaybe<Scalars['String']['input']>;
  joinMe_refresh_token?: InputMaybe<Scalars['String']['input']>;
  last_demo_transferred_at?: InputMaybe<Scalars['DateTime']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutUserInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryCreateNestedManyWithoutUserInput>;
  lead_intents?: InputMaybe<LeadIntentCreateNestedManyWithoutUserInput>;
  lead_source_filter?: InputMaybe<Scalars['Boolean']['input']>;
  lead_source_white_list_array?: InputMaybe<UserCreatelead_Source_White_List_ArrayInput>;
  lead_source_white_list_assign_type?: InputMaybe<AssignType>;
  leads_last_called?: InputMaybe<LeadCreateNestedManyWithoutLast_Call_Result_RepInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusCreateNestedManyWithoutUserInput>;
  manager_id?: InputMaybe<Scalars['String']['input']>;
  note_item?: InputMaybe<NoteItemCreateNestedManyWithoutUserInput>;
  notifications?: InputMaybe<NotificationCreateNestedManyWithoutUserInput>;
  organization: OrganizationCreateNestedOneWithoutUsersInput;
  owned_leads?: InputMaybe<LeadCreateNestedManyWithoutRepInput>;
  panda_doc_access?: InputMaybe<Scalars['Boolean']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  payment_settings?: InputMaybe<OrganizationPaymentSettingsCreateNestedManyWithoutCreated_ByInput>;
  payment_settings_updated?: InputMaybe<OrganizationPaymentSettingsCreateNestedManyWithoutLast_Updated_ByInput>;
  phone_number?: InputMaybe<Scalars['String']['input']>;
  post?: InputMaybe<PostCreateNestedManyWithoutAuthorInput>;
  ProductsCreated?: InputMaybe<ProductChangeLogCreateNestedManyWithoutUpdated_ByInput>;
  profile_image?: InputMaybe<Scalars['String']['input']>;
  receive_transfer_attempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutTo_UserInput>;
  receiver_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutReceiver_UserInput>;
  regions_white_list_array?: InputMaybe<UserCreateregions_White_List_ArrayInput>;
  regions_white_list_assign_type?: InputMaybe<AssignType>;
  rep_scores?: InputMaybe<Scalars['Float']['input']>;
  role?: InputMaybe<Role>;
  routings?: InputMaybe<RoutingCreateNestedManyWithoutRepInput>;
  sale_config?: InputMaybe<SaleConfigCreateNestedManyWithoutCreated_ByInput>;
  sale_config_updated?: InputMaybe<SaleConfigCreateNestedManyWithoutLast_Updated_ByInput>;
  sales_team_column_options?: InputMaybe<UserCreatesales_Team_Column_OptionsInput>;
  schedule_items?: InputMaybe<ScheduleItemCreateNestedManyWithoutUserInput>;
  send_transfer_attempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutFrom_UserInput>;
  sender_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutSender_UserInput>;
  sequence_entry_criteria_id?: InputMaybe<Scalars['String']['input']>;
  SequenceActionLog?: InputMaybe<StepActionLogCreateNestedManyWithoutRepInput>;
  sequenceDashboardView?: InputMaybe<SequenceDashboardViewCreateNestedManyWithoutOwned_ByInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutUserInput>;
  Sequences_created?: InputMaybe<SequenceCreateNestedManyWithoutCreated_ByInput>;
  Sequences_modified_by_user?: InputMaybe<SequenceCreateNestedManyWithoutLast_Updated_ByInput>;
  session_token?: InputMaybe<Scalars['String']['input']>;
  set_user_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutSet_UserInput>;
  set_user_stat?: InputMaybe<StatLeadAggregationCreateNestedManyWithoutSet_UserInput>;
  sf_user_id?: InputMaybe<Scalars['String']['input']>;
  SmsItems?: InputMaybe<SmsItemCreateNestedManyWithoutRepInput>;
  SnoozeTracking?: InputMaybe<SnoozeTrackingCreateNestedManyWithoutRepInput>;
  status?: InputMaybe<Status>;
  statusHistory?: InputMaybe<UserStatusHistoryCreateNestedManyWithoutUserInput>;
  sub_industry_white_list_array?: InputMaybe<UserCreatesub_Industry_White_List_ArrayInput>;
  sub_industry_white_list_assign_type?: InputMaybe<AssignType>;
  support_rep?: InputMaybe<Scalars['Boolean']['input']>;
  taking_demos?: InputMaybe<Scalars['Boolean']['input']>;
  team?: InputMaybe<TeamCreateNestedOneWithoutMembersInput>;
  Template?: InputMaybe<TemplateCreateNestedManyWithoutLast_Modified_ByInput>;
  TemplateVersion?: InputMaybe<TemplateVersionCreateNestedManyWithoutCreated_ByInput>;
  timekit_id?: InputMaybe<Scalars['String']['input']>;
  timekit_type?: InputMaybe<Timekittype>;
  timeOffRequest?: InputMaybe<TimeOffRequestCreateNestedManyWithoutRequesting_UserInput>;
  timezone_raw?: InputMaybe<Scalars['String']['input']>;
  transfer_type?: InputMaybe<TransferType>;
  transfer_user_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutTransfer_UserInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user_custom_availability?: InputMaybe<AvailabilityCreateNestedManyWithoutUserInput>;
  user_past_tokens?: InputMaybe<UserPastTokensCreateNestedManyWithoutUserInput>;
  visible_all_leads_sm?: InputMaybe<Scalars['Boolean']['input']>;
  visible_call_report_columns?: InputMaybe<UserCreatevisible_Call_Report_ColumnsInput>;
  visible_call_report_event_columns?: InputMaybe<UserCreatevisible_Call_Report_Event_ColumnsInput>;
  visible_call_report_pipeline_columns?: InputMaybe<UserCreatevisible_Call_Report_Pipeline_ColumnsInput>;
  visible_my_leads_columns?: InputMaybe<UserCreatevisible_My_Leads_ColumnsInput>;
  voicemail_url?: InputMaybe<Scalars['String']['input']>;
};

export type UserCreateWithoutDashboardSavedViewInput = {
  ai_user?: InputMaybe<Scalars['Boolean']['input']>;
  alternate_email?: InputMaybe<Scalars['String']['input']>;
  always_show_calendar_options?: InputMaybe<Scalars['Boolean']['input']>;
  associate_owner_rep_history?: InputMaybe<AssociationHistoryCreateNestedManyWithoutRepInput>;
  associate_user_history?: InputMaybe<AssociationHistoryCreateNestedManyWithoutUserInput>;
  auto_transfer_selection_receive_attempts?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutFrom_UserInput>;
  auto_transfer_selection_send_attempts?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutTo_UserInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptCreateNestedManyWithoutUserInput>;
  callSharingAsCoach?: InputMaybe<CallSharingCreateNestedManyWithoutCoachInput>;
  callSharingAsUser?: InputMaybe<CallSharingCreateNestedManyWithoutReceiver_UserInput>;
  channel?: InputMaybe<Channelfilter>;
  close_rate?: InputMaybe<Scalars['Float']['input']>;
  close_user_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutClose_UserInput>;
  close_user_stat?: InputMaybe<StatLeadAggregationCreateNestedManyWithoutClose_UserInput>;
  commissions?: InputMaybe<CommissionCreateNestedManyWithoutUserInput>;
  contact_requests?: InputMaybe<ContactRequestCreateNestedManyWithoutRequesting_UserInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  current_sequence_view?: InputMaybe<SequenceDashboardViewCreateNestedOneWithoutUserInput>;
  current_team_start_date?: InputMaybe<Scalars['DateTime']['input']>;
  custom_field_white_list_array?: InputMaybe<UserCustomFieldFilterCreateNestedManyWithoutUserInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemCreateNestedManyWithoutUserInput>;
  daily_lead_claim?: InputMaybe<Scalars['Int']['input']>;
  dashboard_2_access?: InputMaybe<Scalars['Boolean']['input']>;
  default_audio_input?: InputMaybe<Scalars['String']['input']>;
  default_audio_output?: InputMaybe<Scalars['String']['input']>;
  default_availability?: InputMaybe<Scalars['Boolean']['input']>;
  deleted_at?: InputMaybe<Scalars['DateTime']['input']>;
  deprecate_old_session?: InputMaybe<Scalars['Boolean']['input']>;
  email: Scalars['String']['input'];
  EmailSyncRecord?: InputMaybe<EmailSyncRecordCreateNestedManyWithoutUserInput>;
  favorite_leads?: InputMaybe<LeadCreateNestedManyWithoutFavorited_By_UsersInput>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  folder_permissions?: InputMaybe<FolderPermissionCreateNestedManyWithoutUserInput>;
  folders?: InputMaybe<FolderCreateNestedManyWithoutOwnerInput>;
  from_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutOld_UserInput>;
  GlobalRouting?: InputMaybe<GlobalRepRoutingCreateNestedManyWithoutRepInput>;
  google_refresh_token?: InputMaybe<Scalars['String']['input']>;
  holds_per_prospect_vs_held_demo_per_dial?: InputMaybe<Scalars['Float']['input']>;
  hook_subscriptions?: InputMaybe<WebHookSubscriptionCreateNestedManyWithoutUserInput>;
  hubspot_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  idle_owned_leads?: InputMaybe<LeadCreateNestedManyWithoutSecondary_RepsInput>;
  industry_filter?: InputMaybe<Scalars['Boolean']['input']>;
  industry_white_list_array?: InputMaybe<UserCreateindustry_White_List_ArrayInput>;
  industry_white_list_assign_type?: InputMaybe<AssignType>;
  joinMe_access_token?: InputMaybe<Scalars['String']['input']>;
  joinMe_refresh_token?: InputMaybe<Scalars['String']['input']>;
  last_demo_transferred_at?: InputMaybe<Scalars['DateTime']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutUserInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryCreateNestedManyWithoutUserInput>;
  lead_intents?: InputMaybe<LeadIntentCreateNestedManyWithoutUserInput>;
  lead_source_filter?: InputMaybe<Scalars['Boolean']['input']>;
  lead_source_white_list_array?: InputMaybe<UserCreatelead_Source_White_List_ArrayInput>;
  lead_source_white_list_assign_type?: InputMaybe<AssignType>;
  leads_last_called?: InputMaybe<LeadCreateNestedManyWithoutLast_Call_Result_RepInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusCreateNestedManyWithoutUserInput>;
  manager_id?: InputMaybe<Scalars['String']['input']>;
  note_item?: InputMaybe<NoteItemCreateNestedManyWithoutUserInput>;
  notifications?: InputMaybe<NotificationCreateNestedManyWithoutUserInput>;
  organization: OrganizationCreateNestedOneWithoutUsersInput;
  owned_leads?: InputMaybe<LeadCreateNestedManyWithoutRepInput>;
  panda_doc_access?: InputMaybe<Scalars['Boolean']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  payment_settings?: InputMaybe<OrganizationPaymentSettingsCreateNestedManyWithoutCreated_ByInput>;
  payment_settings_updated?: InputMaybe<OrganizationPaymentSettingsCreateNestedManyWithoutLast_Updated_ByInput>;
  phone_number?: InputMaybe<Scalars['String']['input']>;
  post?: InputMaybe<PostCreateNestedManyWithoutAuthorInput>;
  ProductsCreated?: InputMaybe<ProductChangeLogCreateNestedManyWithoutUpdated_ByInput>;
  profile_image?: InputMaybe<Scalars['String']['input']>;
  receive_transfer_attempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutTo_UserInput>;
  receiver_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutReceiver_UserInput>;
  regions_white_list_array?: InputMaybe<UserCreateregions_White_List_ArrayInput>;
  regions_white_list_assign_type?: InputMaybe<AssignType>;
  rep_scores?: InputMaybe<Scalars['Float']['input']>;
  role?: InputMaybe<Role>;
  routings?: InputMaybe<RoutingCreateNestedManyWithoutRepInput>;
  sale_config?: InputMaybe<SaleConfigCreateNestedManyWithoutCreated_ByInput>;
  sale_config_updated?: InputMaybe<SaleConfigCreateNestedManyWithoutLast_Updated_ByInput>;
  sales_team_column_options?: InputMaybe<UserCreatesales_Team_Column_OptionsInput>;
  schedule_items?: InputMaybe<ScheduleItemCreateNestedManyWithoutUserInput>;
  send_transfer_attempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutFrom_UserInput>;
  sender_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutSender_UserInput>;
  sequence_entry_criteria_id?: InputMaybe<Scalars['String']['input']>;
  SequenceActionLog?: InputMaybe<StepActionLogCreateNestedManyWithoutRepInput>;
  sequenceDashboardView?: InputMaybe<SequenceDashboardViewCreateNestedManyWithoutOwned_ByInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutUserInput>;
  Sequences_created?: InputMaybe<SequenceCreateNestedManyWithoutCreated_ByInput>;
  Sequences_modified_by_user?: InputMaybe<SequenceCreateNestedManyWithoutLast_Updated_ByInput>;
  session_token?: InputMaybe<Scalars['String']['input']>;
  set_user_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutSet_UserInput>;
  set_user_stat?: InputMaybe<StatLeadAggregationCreateNestedManyWithoutSet_UserInput>;
  sf_user_id?: InputMaybe<Scalars['String']['input']>;
  SmsItems?: InputMaybe<SmsItemCreateNestedManyWithoutRepInput>;
  SnoozeTracking?: InputMaybe<SnoozeTrackingCreateNestedManyWithoutRepInput>;
  status?: InputMaybe<Status>;
  statusHistory?: InputMaybe<UserStatusHistoryCreateNestedManyWithoutUserInput>;
  sub_industry_white_list_array?: InputMaybe<UserCreatesub_Industry_White_List_ArrayInput>;
  sub_industry_white_list_assign_type?: InputMaybe<AssignType>;
  support_rep?: InputMaybe<Scalars['Boolean']['input']>;
  taking_demos?: InputMaybe<Scalars['Boolean']['input']>;
  team?: InputMaybe<TeamCreateNestedOneWithoutMembersInput>;
  Template?: InputMaybe<TemplateCreateNestedManyWithoutLast_Modified_ByInput>;
  TemplateVersion?: InputMaybe<TemplateVersionCreateNestedManyWithoutCreated_ByInput>;
  timekit_id?: InputMaybe<Scalars['String']['input']>;
  timekit_type?: InputMaybe<Timekittype>;
  timeOffRequest?: InputMaybe<TimeOffRequestCreateNestedManyWithoutRequesting_UserInput>;
  timezone_raw?: InputMaybe<Scalars['String']['input']>;
  transfer_type?: InputMaybe<TransferType>;
  transfer_user_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutTransfer_UserInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user_custom_availability?: InputMaybe<AvailabilityCreateNestedManyWithoutUserInput>;
  user_past_tokens?: InputMaybe<UserPastTokensCreateNestedManyWithoutUserInput>;
  visible_all_leads_sm?: InputMaybe<Scalars['Boolean']['input']>;
  visible_call_report_columns?: InputMaybe<UserCreatevisible_Call_Report_ColumnsInput>;
  visible_call_report_event_columns?: InputMaybe<UserCreatevisible_Call_Report_Event_ColumnsInput>;
  visible_call_report_pipeline_columns?: InputMaybe<UserCreatevisible_Call_Report_Pipeline_ColumnsInput>;
  visible_my_leads_columns?: InputMaybe<UserCreatevisible_My_Leads_ColumnsInput>;
  voicemail_url?: InputMaybe<Scalars['String']['input']>;
};

export type UserCreateWithoutEmailSyncRecordInput = {
  ai_user?: InputMaybe<Scalars['Boolean']['input']>;
  alternate_email?: InputMaybe<Scalars['String']['input']>;
  always_show_calendar_options?: InputMaybe<Scalars['Boolean']['input']>;
  associate_owner_rep_history?: InputMaybe<AssociationHistoryCreateNestedManyWithoutRepInput>;
  associate_user_history?: InputMaybe<AssociationHistoryCreateNestedManyWithoutUserInput>;
  auto_transfer_selection_receive_attempts?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutFrom_UserInput>;
  auto_transfer_selection_send_attempts?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutTo_UserInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptCreateNestedManyWithoutUserInput>;
  callSharingAsCoach?: InputMaybe<CallSharingCreateNestedManyWithoutCoachInput>;
  callSharingAsUser?: InputMaybe<CallSharingCreateNestedManyWithoutReceiver_UserInput>;
  channel?: InputMaybe<Channelfilter>;
  close_rate?: InputMaybe<Scalars['Float']['input']>;
  close_user_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutClose_UserInput>;
  close_user_stat?: InputMaybe<StatLeadAggregationCreateNestedManyWithoutClose_UserInput>;
  commissions?: InputMaybe<CommissionCreateNestedManyWithoutUserInput>;
  contact_requests?: InputMaybe<ContactRequestCreateNestedManyWithoutRequesting_UserInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  current_sequence_view?: InputMaybe<SequenceDashboardViewCreateNestedOneWithoutUserInput>;
  current_team_start_date?: InputMaybe<Scalars['DateTime']['input']>;
  custom_field_white_list_array?: InputMaybe<UserCustomFieldFilterCreateNestedManyWithoutUserInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemCreateNestedManyWithoutUserInput>;
  daily_lead_claim?: InputMaybe<Scalars['Int']['input']>;
  dashboard_2_access?: InputMaybe<Scalars['Boolean']['input']>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewCreateNestedManyWithoutUserInput>;
  default_audio_input?: InputMaybe<Scalars['String']['input']>;
  default_audio_output?: InputMaybe<Scalars['String']['input']>;
  default_availability?: InputMaybe<Scalars['Boolean']['input']>;
  deleted_at?: InputMaybe<Scalars['DateTime']['input']>;
  deprecate_old_session?: InputMaybe<Scalars['Boolean']['input']>;
  email: Scalars['String']['input'];
  favorite_leads?: InputMaybe<LeadCreateNestedManyWithoutFavorited_By_UsersInput>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  folder_permissions?: InputMaybe<FolderPermissionCreateNestedManyWithoutUserInput>;
  folders?: InputMaybe<FolderCreateNestedManyWithoutOwnerInput>;
  from_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutOld_UserInput>;
  GlobalRouting?: InputMaybe<GlobalRepRoutingCreateNestedManyWithoutRepInput>;
  google_refresh_token?: InputMaybe<Scalars['String']['input']>;
  holds_per_prospect_vs_held_demo_per_dial?: InputMaybe<Scalars['Float']['input']>;
  hook_subscriptions?: InputMaybe<WebHookSubscriptionCreateNestedManyWithoutUserInput>;
  hubspot_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  idle_owned_leads?: InputMaybe<LeadCreateNestedManyWithoutSecondary_RepsInput>;
  industry_filter?: InputMaybe<Scalars['Boolean']['input']>;
  industry_white_list_array?: InputMaybe<UserCreateindustry_White_List_ArrayInput>;
  industry_white_list_assign_type?: InputMaybe<AssignType>;
  joinMe_access_token?: InputMaybe<Scalars['String']['input']>;
  joinMe_refresh_token?: InputMaybe<Scalars['String']['input']>;
  last_demo_transferred_at?: InputMaybe<Scalars['DateTime']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutUserInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryCreateNestedManyWithoutUserInput>;
  lead_intents?: InputMaybe<LeadIntentCreateNestedManyWithoutUserInput>;
  lead_source_filter?: InputMaybe<Scalars['Boolean']['input']>;
  lead_source_white_list_array?: InputMaybe<UserCreatelead_Source_White_List_ArrayInput>;
  lead_source_white_list_assign_type?: InputMaybe<AssignType>;
  leads_last_called?: InputMaybe<LeadCreateNestedManyWithoutLast_Call_Result_RepInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusCreateNestedManyWithoutUserInput>;
  manager_id?: InputMaybe<Scalars['String']['input']>;
  note_item?: InputMaybe<NoteItemCreateNestedManyWithoutUserInput>;
  notifications?: InputMaybe<NotificationCreateNestedManyWithoutUserInput>;
  organization: OrganizationCreateNestedOneWithoutUsersInput;
  owned_leads?: InputMaybe<LeadCreateNestedManyWithoutRepInput>;
  panda_doc_access?: InputMaybe<Scalars['Boolean']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  payment_settings?: InputMaybe<OrganizationPaymentSettingsCreateNestedManyWithoutCreated_ByInput>;
  payment_settings_updated?: InputMaybe<OrganizationPaymentSettingsCreateNestedManyWithoutLast_Updated_ByInput>;
  phone_number?: InputMaybe<Scalars['String']['input']>;
  post?: InputMaybe<PostCreateNestedManyWithoutAuthorInput>;
  ProductsCreated?: InputMaybe<ProductChangeLogCreateNestedManyWithoutUpdated_ByInput>;
  profile_image?: InputMaybe<Scalars['String']['input']>;
  receive_transfer_attempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutTo_UserInput>;
  receiver_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutReceiver_UserInput>;
  regions_white_list_array?: InputMaybe<UserCreateregions_White_List_ArrayInput>;
  regions_white_list_assign_type?: InputMaybe<AssignType>;
  rep_scores?: InputMaybe<Scalars['Float']['input']>;
  role?: InputMaybe<Role>;
  routings?: InputMaybe<RoutingCreateNestedManyWithoutRepInput>;
  sale_config?: InputMaybe<SaleConfigCreateNestedManyWithoutCreated_ByInput>;
  sale_config_updated?: InputMaybe<SaleConfigCreateNestedManyWithoutLast_Updated_ByInput>;
  sales_team_column_options?: InputMaybe<UserCreatesales_Team_Column_OptionsInput>;
  schedule_items?: InputMaybe<ScheduleItemCreateNestedManyWithoutUserInput>;
  send_transfer_attempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutFrom_UserInput>;
  sender_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutSender_UserInput>;
  sequence_entry_criteria_id?: InputMaybe<Scalars['String']['input']>;
  SequenceActionLog?: InputMaybe<StepActionLogCreateNestedManyWithoutRepInput>;
  sequenceDashboardView?: InputMaybe<SequenceDashboardViewCreateNestedManyWithoutOwned_ByInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutUserInput>;
  Sequences_created?: InputMaybe<SequenceCreateNestedManyWithoutCreated_ByInput>;
  Sequences_modified_by_user?: InputMaybe<SequenceCreateNestedManyWithoutLast_Updated_ByInput>;
  session_token?: InputMaybe<Scalars['String']['input']>;
  set_user_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutSet_UserInput>;
  set_user_stat?: InputMaybe<StatLeadAggregationCreateNestedManyWithoutSet_UserInput>;
  sf_user_id?: InputMaybe<Scalars['String']['input']>;
  SmsItems?: InputMaybe<SmsItemCreateNestedManyWithoutRepInput>;
  SnoozeTracking?: InputMaybe<SnoozeTrackingCreateNestedManyWithoutRepInput>;
  status?: InputMaybe<Status>;
  statusHistory?: InputMaybe<UserStatusHistoryCreateNestedManyWithoutUserInput>;
  sub_industry_white_list_array?: InputMaybe<UserCreatesub_Industry_White_List_ArrayInput>;
  sub_industry_white_list_assign_type?: InputMaybe<AssignType>;
  support_rep?: InputMaybe<Scalars['Boolean']['input']>;
  taking_demos?: InputMaybe<Scalars['Boolean']['input']>;
  team?: InputMaybe<TeamCreateNestedOneWithoutMembersInput>;
  Template?: InputMaybe<TemplateCreateNestedManyWithoutLast_Modified_ByInput>;
  TemplateVersion?: InputMaybe<TemplateVersionCreateNestedManyWithoutCreated_ByInput>;
  timekit_id?: InputMaybe<Scalars['String']['input']>;
  timekit_type?: InputMaybe<Timekittype>;
  timeOffRequest?: InputMaybe<TimeOffRequestCreateNestedManyWithoutRequesting_UserInput>;
  timezone_raw?: InputMaybe<Scalars['String']['input']>;
  transfer_type?: InputMaybe<TransferType>;
  transfer_user_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutTransfer_UserInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user_custom_availability?: InputMaybe<AvailabilityCreateNestedManyWithoutUserInput>;
  user_past_tokens?: InputMaybe<UserPastTokensCreateNestedManyWithoutUserInput>;
  visible_all_leads_sm?: InputMaybe<Scalars['Boolean']['input']>;
  visible_call_report_columns?: InputMaybe<UserCreatevisible_Call_Report_ColumnsInput>;
  visible_call_report_event_columns?: InputMaybe<UserCreatevisible_Call_Report_Event_ColumnsInput>;
  visible_call_report_pipeline_columns?: InputMaybe<UserCreatevisible_Call_Report_Pipeline_ColumnsInput>;
  visible_my_leads_columns?: InputMaybe<UserCreatevisible_My_Leads_ColumnsInput>;
  voicemail_url?: InputMaybe<Scalars['String']['input']>;
};

export type UserCreateWithoutFavorite_LeadsInput = {
  ai_user?: InputMaybe<Scalars['Boolean']['input']>;
  alternate_email?: InputMaybe<Scalars['String']['input']>;
  always_show_calendar_options?: InputMaybe<Scalars['Boolean']['input']>;
  associate_owner_rep_history?: InputMaybe<AssociationHistoryCreateNestedManyWithoutRepInput>;
  associate_user_history?: InputMaybe<AssociationHistoryCreateNestedManyWithoutUserInput>;
  auto_transfer_selection_receive_attempts?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutFrom_UserInput>;
  auto_transfer_selection_send_attempts?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutTo_UserInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptCreateNestedManyWithoutUserInput>;
  callSharingAsCoach?: InputMaybe<CallSharingCreateNestedManyWithoutCoachInput>;
  callSharingAsUser?: InputMaybe<CallSharingCreateNestedManyWithoutReceiver_UserInput>;
  channel?: InputMaybe<Channelfilter>;
  close_rate?: InputMaybe<Scalars['Float']['input']>;
  close_user_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutClose_UserInput>;
  close_user_stat?: InputMaybe<StatLeadAggregationCreateNestedManyWithoutClose_UserInput>;
  commissions?: InputMaybe<CommissionCreateNestedManyWithoutUserInput>;
  contact_requests?: InputMaybe<ContactRequestCreateNestedManyWithoutRequesting_UserInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  current_sequence_view?: InputMaybe<SequenceDashboardViewCreateNestedOneWithoutUserInput>;
  current_team_start_date?: InputMaybe<Scalars['DateTime']['input']>;
  custom_field_white_list_array?: InputMaybe<UserCustomFieldFilterCreateNestedManyWithoutUserInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemCreateNestedManyWithoutUserInput>;
  daily_lead_claim?: InputMaybe<Scalars['Int']['input']>;
  dashboard_2_access?: InputMaybe<Scalars['Boolean']['input']>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewCreateNestedManyWithoutUserInput>;
  default_audio_input?: InputMaybe<Scalars['String']['input']>;
  default_audio_output?: InputMaybe<Scalars['String']['input']>;
  default_availability?: InputMaybe<Scalars['Boolean']['input']>;
  deleted_at?: InputMaybe<Scalars['DateTime']['input']>;
  deprecate_old_session?: InputMaybe<Scalars['Boolean']['input']>;
  email: Scalars['String']['input'];
  EmailSyncRecord?: InputMaybe<EmailSyncRecordCreateNestedManyWithoutUserInput>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  folder_permissions?: InputMaybe<FolderPermissionCreateNestedManyWithoutUserInput>;
  folders?: InputMaybe<FolderCreateNestedManyWithoutOwnerInput>;
  from_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutOld_UserInput>;
  GlobalRouting?: InputMaybe<GlobalRepRoutingCreateNestedManyWithoutRepInput>;
  google_refresh_token?: InputMaybe<Scalars['String']['input']>;
  holds_per_prospect_vs_held_demo_per_dial?: InputMaybe<Scalars['Float']['input']>;
  hook_subscriptions?: InputMaybe<WebHookSubscriptionCreateNestedManyWithoutUserInput>;
  hubspot_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  idle_owned_leads?: InputMaybe<LeadCreateNestedManyWithoutSecondary_RepsInput>;
  industry_filter?: InputMaybe<Scalars['Boolean']['input']>;
  industry_white_list_array?: InputMaybe<UserCreateindustry_White_List_ArrayInput>;
  industry_white_list_assign_type?: InputMaybe<AssignType>;
  joinMe_access_token?: InputMaybe<Scalars['String']['input']>;
  joinMe_refresh_token?: InputMaybe<Scalars['String']['input']>;
  last_demo_transferred_at?: InputMaybe<Scalars['DateTime']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutUserInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryCreateNestedManyWithoutUserInput>;
  lead_intents?: InputMaybe<LeadIntentCreateNestedManyWithoutUserInput>;
  lead_source_filter?: InputMaybe<Scalars['Boolean']['input']>;
  lead_source_white_list_array?: InputMaybe<UserCreatelead_Source_White_List_ArrayInput>;
  lead_source_white_list_assign_type?: InputMaybe<AssignType>;
  leads_last_called?: InputMaybe<LeadCreateNestedManyWithoutLast_Call_Result_RepInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusCreateNestedManyWithoutUserInput>;
  manager_id?: InputMaybe<Scalars['String']['input']>;
  note_item?: InputMaybe<NoteItemCreateNestedManyWithoutUserInput>;
  notifications?: InputMaybe<NotificationCreateNestedManyWithoutUserInput>;
  organization: OrganizationCreateNestedOneWithoutUsersInput;
  owned_leads?: InputMaybe<LeadCreateNestedManyWithoutRepInput>;
  panda_doc_access?: InputMaybe<Scalars['Boolean']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  payment_settings?: InputMaybe<OrganizationPaymentSettingsCreateNestedManyWithoutCreated_ByInput>;
  payment_settings_updated?: InputMaybe<OrganizationPaymentSettingsCreateNestedManyWithoutLast_Updated_ByInput>;
  phone_number?: InputMaybe<Scalars['String']['input']>;
  post?: InputMaybe<PostCreateNestedManyWithoutAuthorInput>;
  ProductsCreated?: InputMaybe<ProductChangeLogCreateNestedManyWithoutUpdated_ByInput>;
  profile_image?: InputMaybe<Scalars['String']['input']>;
  receive_transfer_attempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutTo_UserInput>;
  receiver_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutReceiver_UserInput>;
  regions_white_list_array?: InputMaybe<UserCreateregions_White_List_ArrayInput>;
  regions_white_list_assign_type?: InputMaybe<AssignType>;
  rep_scores?: InputMaybe<Scalars['Float']['input']>;
  role?: InputMaybe<Role>;
  routings?: InputMaybe<RoutingCreateNestedManyWithoutRepInput>;
  sale_config?: InputMaybe<SaleConfigCreateNestedManyWithoutCreated_ByInput>;
  sale_config_updated?: InputMaybe<SaleConfigCreateNestedManyWithoutLast_Updated_ByInput>;
  sales_team_column_options?: InputMaybe<UserCreatesales_Team_Column_OptionsInput>;
  schedule_items?: InputMaybe<ScheduleItemCreateNestedManyWithoutUserInput>;
  send_transfer_attempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutFrom_UserInput>;
  sender_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutSender_UserInput>;
  sequence_entry_criteria_id?: InputMaybe<Scalars['String']['input']>;
  SequenceActionLog?: InputMaybe<StepActionLogCreateNestedManyWithoutRepInput>;
  sequenceDashboardView?: InputMaybe<SequenceDashboardViewCreateNestedManyWithoutOwned_ByInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutUserInput>;
  Sequences_created?: InputMaybe<SequenceCreateNestedManyWithoutCreated_ByInput>;
  Sequences_modified_by_user?: InputMaybe<SequenceCreateNestedManyWithoutLast_Updated_ByInput>;
  session_token?: InputMaybe<Scalars['String']['input']>;
  set_user_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutSet_UserInput>;
  set_user_stat?: InputMaybe<StatLeadAggregationCreateNestedManyWithoutSet_UserInput>;
  sf_user_id?: InputMaybe<Scalars['String']['input']>;
  SmsItems?: InputMaybe<SmsItemCreateNestedManyWithoutRepInput>;
  SnoozeTracking?: InputMaybe<SnoozeTrackingCreateNestedManyWithoutRepInput>;
  status?: InputMaybe<Status>;
  statusHistory?: InputMaybe<UserStatusHistoryCreateNestedManyWithoutUserInput>;
  sub_industry_white_list_array?: InputMaybe<UserCreatesub_Industry_White_List_ArrayInput>;
  sub_industry_white_list_assign_type?: InputMaybe<AssignType>;
  support_rep?: InputMaybe<Scalars['Boolean']['input']>;
  taking_demos?: InputMaybe<Scalars['Boolean']['input']>;
  team?: InputMaybe<TeamCreateNestedOneWithoutMembersInput>;
  Template?: InputMaybe<TemplateCreateNestedManyWithoutLast_Modified_ByInput>;
  TemplateVersion?: InputMaybe<TemplateVersionCreateNestedManyWithoutCreated_ByInput>;
  timekit_id?: InputMaybe<Scalars['String']['input']>;
  timekit_type?: InputMaybe<Timekittype>;
  timeOffRequest?: InputMaybe<TimeOffRequestCreateNestedManyWithoutRequesting_UserInput>;
  timezone_raw?: InputMaybe<Scalars['String']['input']>;
  transfer_type?: InputMaybe<TransferType>;
  transfer_user_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutTransfer_UserInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user_custom_availability?: InputMaybe<AvailabilityCreateNestedManyWithoutUserInput>;
  user_past_tokens?: InputMaybe<UserPastTokensCreateNestedManyWithoutUserInput>;
  visible_all_leads_sm?: InputMaybe<Scalars['Boolean']['input']>;
  visible_call_report_columns?: InputMaybe<UserCreatevisible_Call_Report_ColumnsInput>;
  visible_call_report_event_columns?: InputMaybe<UserCreatevisible_Call_Report_Event_ColumnsInput>;
  visible_call_report_pipeline_columns?: InputMaybe<UserCreatevisible_Call_Report_Pipeline_ColumnsInput>;
  visible_my_leads_columns?: InputMaybe<UserCreatevisible_My_Leads_ColumnsInput>;
  voicemail_url?: InputMaybe<Scalars['String']['input']>;
};

export type UserCreateWithoutFolder_PermissionsInput = {
  ai_user?: InputMaybe<Scalars['Boolean']['input']>;
  alternate_email?: InputMaybe<Scalars['String']['input']>;
  always_show_calendar_options?: InputMaybe<Scalars['Boolean']['input']>;
  associate_owner_rep_history?: InputMaybe<AssociationHistoryCreateNestedManyWithoutRepInput>;
  associate_user_history?: InputMaybe<AssociationHistoryCreateNestedManyWithoutUserInput>;
  auto_transfer_selection_receive_attempts?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutFrom_UserInput>;
  auto_transfer_selection_send_attempts?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutTo_UserInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptCreateNestedManyWithoutUserInput>;
  callSharingAsCoach?: InputMaybe<CallSharingCreateNestedManyWithoutCoachInput>;
  callSharingAsUser?: InputMaybe<CallSharingCreateNestedManyWithoutReceiver_UserInput>;
  channel?: InputMaybe<Channelfilter>;
  close_rate?: InputMaybe<Scalars['Float']['input']>;
  close_user_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutClose_UserInput>;
  close_user_stat?: InputMaybe<StatLeadAggregationCreateNestedManyWithoutClose_UserInput>;
  commissions?: InputMaybe<CommissionCreateNestedManyWithoutUserInput>;
  contact_requests?: InputMaybe<ContactRequestCreateNestedManyWithoutRequesting_UserInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  current_sequence_view?: InputMaybe<SequenceDashboardViewCreateNestedOneWithoutUserInput>;
  current_team_start_date?: InputMaybe<Scalars['DateTime']['input']>;
  custom_field_white_list_array?: InputMaybe<UserCustomFieldFilterCreateNestedManyWithoutUserInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemCreateNestedManyWithoutUserInput>;
  daily_lead_claim?: InputMaybe<Scalars['Int']['input']>;
  dashboard_2_access?: InputMaybe<Scalars['Boolean']['input']>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewCreateNestedManyWithoutUserInput>;
  default_audio_input?: InputMaybe<Scalars['String']['input']>;
  default_audio_output?: InputMaybe<Scalars['String']['input']>;
  default_availability?: InputMaybe<Scalars['Boolean']['input']>;
  deleted_at?: InputMaybe<Scalars['DateTime']['input']>;
  deprecate_old_session?: InputMaybe<Scalars['Boolean']['input']>;
  email: Scalars['String']['input'];
  EmailSyncRecord?: InputMaybe<EmailSyncRecordCreateNestedManyWithoutUserInput>;
  favorite_leads?: InputMaybe<LeadCreateNestedManyWithoutFavorited_By_UsersInput>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  folders?: InputMaybe<FolderCreateNestedManyWithoutOwnerInput>;
  from_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutOld_UserInput>;
  GlobalRouting?: InputMaybe<GlobalRepRoutingCreateNestedManyWithoutRepInput>;
  google_refresh_token?: InputMaybe<Scalars['String']['input']>;
  holds_per_prospect_vs_held_demo_per_dial?: InputMaybe<Scalars['Float']['input']>;
  hook_subscriptions?: InputMaybe<WebHookSubscriptionCreateNestedManyWithoutUserInput>;
  hubspot_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  idle_owned_leads?: InputMaybe<LeadCreateNestedManyWithoutSecondary_RepsInput>;
  industry_filter?: InputMaybe<Scalars['Boolean']['input']>;
  industry_white_list_array?: InputMaybe<UserCreateindustry_White_List_ArrayInput>;
  industry_white_list_assign_type?: InputMaybe<AssignType>;
  joinMe_access_token?: InputMaybe<Scalars['String']['input']>;
  joinMe_refresh_token?: InputMaybe<Scalars['String']['input']>;
  last_demo_transferred_at?: InputMaybe<Scalars['DateTime']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutUserInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryCreateNestedManyWithoutUserInput>;
  lead_intents?: InputMaybe<LeadIntentCreateNestedManyWithoutUserInput>;
  lead_source_filter?: InputMaybe<Scalars['Boolean']['input']>;
  lead_source_white_list_array?: InputMaybe<UserCreatelead_Source_White_List_ArrayInput>;
  lead_source_white_list_assign_type?: InputMaybe<AssignType>;
  leads_last_called?: InputMaybe<LeadCreateNestedManyWithoutLast_Call_Result_RepInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusCreateNestedManyWithoutUserInput>;
  manager_id?: InputMaybe<Scalars['String']['input']>;
  note_item?: InputMaybe<NoteItemCreateNestedManyWithoutUserInput>;
  notifications?: InputMaybe<NotificationCreateNestedManyWithoutUserInput>;
  organization: OrganizationCreateNestedOneWithoutUsersInput;
  owned_leads?: InputMaybe<LeadCreateNestedManyWithoutRepInput>;
  panda_doc_access?: InputMaybe<Scalars['Boolean']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  payment_settings?: InputMaybe<OrganizationPaymentSettingsCreateNestedManyWithoutCreated_ByInput>;
  payment_settings_updated?: InputMaybe<OrganizationPaymentSettingsCreateNestedManyWithoutLast_Updated_ByInput>;
  phone_number?: InputMaybe<Scalars['String']['input']>;
  post?: InputMaybe<PostCreateNestedManyWithoutAuthorInput>;
  ProductsCreated?: InputMaybe<ProductChangeLogCreateNestedManyWithoutUpdated_ByInput>;
  profile_image?: InputMaybe<Scalars['String']['input']>;
  receive_transfer_attempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutTo_UserInput>;
  receiver_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutReceiver_UserInput>;
  regions_white_list_array?: InputMaybe<UserCreateregions_White_List_ArrayInput>;
  regions_white_list_assign_type?: InputMaybe<AssignType>;
  rep_scores?: InputMaybe<Scalars['Float']['input']>;
  role?: InputMaybe<Role>;
  routings?: InputMaybe<RoutingCreateNestedManyWithoutRepInput>;
  sale_config?: InputMaybe<SaleConfigCreateNestedManyWithoutCreated_ByInput>;
  sale_config_updated?: InputMaybe<SaleConfigCreateNestedManyWithoutLast_Updated_ByInput>;
  sales_team_column_options?: InputMaybe<UserCreatesales_Team_Column_OptionsInput>;
  schedule_items?: InputMaybe<ScheduleItemCreateNestedManyWithoutUserInput>;
  send_transfer_attempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutFrom_UserInput>;
  sender_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutSender_UserInput>;
  sequence_entry_criteria_id?: InputMaybe<Scalars['String']['input']>;
  SequenceActionLog?: InputMaybe<StepActionLogCreateNestedManyWithoutRepInput>;
  sequenceDashboardView?: InputMaybe<SequenceDashboardViewCreateNestedManyWithoutOwned_ByInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutUserInput>;
  Sequences_created?: InputMaybe<SequenceCreateNestedManyWithoutCreated_ByInput>;
  Sequences_modified_by_user?: InputMaybe<SequenceCreateNestedManyWithoutLast_Updated_ByInput>;
  session_token?: InputMaybe<Scalars['String']['input']>;
  set_user_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutSet_UserInput>;
  set_user_stat?: InputMaybe<StatLeadAggregationCreateNestedManyWithoutSet_UserInput>;
  sf_user_id?: InputMaybe<Scalars['String']['input']>;
  SmsItems?: InputMaybe<SmsItemCreateNestedManyWithoutRepInput>;
  SnoozeTracking?: InputMaybe<SnoozeTrackingCreateNestedManyWithoutRepInput>;
  status?: InputMaybe<Status>;
  statusHistory?: InputMaybe<UserStatusHistoryCreateNestedManyWithoutUserInput>;
  sub_industry_white_list_array?: InputMaybe<UserCreatesub_Industry_White_List_ArrayInput>;
  sub_industry_white_list_assign_type?: InputMaybe<AssignType>;
  support_rep?: InputMaybe<Scalars['Boolean']['input']>;
  taking_demos?: InputMaybe<Scalars['Boolean']['input']>;
  team?: InputMaybe<TeamCreateNestedOneWithoutMembersInput>;
  Template?: InputMaybe<TemplateCreateNestedManyWithoutLast_Modified_ByInput>;
  TemplateVersion?: InputMaybe<TemplateVersionCreateNestedManyWithoutCreated_ByInput>;
  timekit_id?: InputMaybe<Scalars['String']['input']>;
  timekit_type?: InputMaybe<Timekittype>;
  timeOffRequest?: InputMaybe<TimeOffRequestCreateNestedManyWithoutRequesting_UserInput>;
  timezone_raw?: InputMaybe<Scalars['String']['input']>;
  transfer_type?: InputMaybe<TransferType>;
  transfer_user_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutTransfer_UserInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user_custom_availability?: InputMaybe<AvailabilityCreateNestedManyWithoutUserInput>;
  user_past_tokens?: InputMaybe<UserPastTokensCreateNestedManyWithoutUserInput>;
  visible_all_leads_sm?: InputMaybe<Scalars['Boolean']['input']>;
  visible_call_report_columns?: InputMaybe<UserCreatevisible_Call_Report_ColumnsInput>;
  visible_call_report_event_columns?: InputMaybe<UserCreatevisible_Call_Report_Event_ColumnsInput>;
  visible_call_report_pipeline_columns?: InputMaybe<UserCreatevisible_Call_Report_Pipeline_ColumnsInput>;
  visible_my_leads_columns?: InputMaybe<UserCreatevisible_My_Leads_ColumnsInput>;
  voicemail_url?: InputMaybe<Scalars['String']['input']>;
};

export type UserCreateWithoutFoldersInput = {
  ai_user?: InputMaybe<Scalars['Boolean']['input']>;
  alternate_email?: InputMaybe<Scalars['String']['input']>;
  always_show_calendar_options?: InputMaybe<Scalars['Boolean']['input']>;
  associate_owner_rep_history?: InputMaybe<AssociationHistoryCreateNestedManyWithoutRepInput>;
  associate_user_history?: InputMaybe<AssociationHistoryCreateNestedManyWithoutUserInput>;
  auto_transfer_selection_receive_attempts?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutFrom_UserInput>;
  auto_transfer_selection_send_attempts?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutTo_UserInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptCreateNestedManyWithoutUserInput>;
  callSharingAsCoach?: InputMaybe<CallSharingCreateNestedManyWithoutCoachInput>;
  callSharingAsUser?: InputMaybe<CallSharingCreateNestedManyWithoutReceiver_UserInput>;
  channel?: InputMaybe<Channelfilter>;
  close_rate?: InputMaybe<Scalars['Float']['input']>;
  close_user_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutClose_UserInput>;
  close_user_stat?: InputMaybe<StatLeadAggregationCreateNestedManyWithoutClose_UserInput>;
  commissions?: InputMaybe<CommissionCreateNestedManyWithoutUserInput>;
  contact_requests?: InputMaybe<ContactRequestCreateNestedManyWithoutRequesting_UserInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  current_sequence_view?: InputMaybe<SequenceDashboardViewCreateNestedOneWithoutUserInput>;
  current_team_start_date?: InputMaybe<Scalars['DateTime']['input']>;
  custom_field_white_list_array?: InputMaybe<UserCustomFieldFilterCreateNestedManyWithoutUserInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemCreateNestedManyWithoutUserInput>;
  daily_lead_claim?: InputMaybe<Scalars['Int']['input']>;
  dashboard_2_access?: InputMaybe<Scalars['Boolean']['input']>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewCreateNestedManyWithoutUserInput>;
  default_audio_input?: InputMaybe<Scalars['String']['input']>;
  default_audio_output?: InputMaybe<Scalars['String']['input']>;
  default_availability?: InputMaybe<Scalars['Boolean']['input']>;
  deleted_at?: InputMaybe<Scalars['DateTime']['input']>;
  deprecate_old_session?: InputMaybe<Scalars['Boolean']['input']>;
  email: Scalars['String']['input'];
  EmailSyncRecord?: InputMaybe<EmailSyncRecordCreateNestedManyWithoutUserInput>;
  favorite_leads?: InputMaybe<LeadCreateNestedManyWithoutFavorited_By_UsersInput>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  folder_permissions?: InputMaybe<FolderPermissionCreateNestedManyWithoutUserInput>;
  from_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutOld_UserInput>;
  GlobalRouting?: InputMaybe<GlobalRepRoutingCreateNestedManyWithoutRepInput>;
  google_refresh_token?: InputMaybe<Scalars['String']['input']>;
  holds_per_prospect_vs_held_demo_per_dial?: InputMaybe<Scalars['Float']['input']>;
  hook_subscriptions?: InputMaybe<WebHookSubscriptionCreateNestedManyWithoutUserInput>;
  hubspot_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  idle_owned_leads?: InputMaybe<LeadCreateNestedManyWithoutSecondary_RepsInput>;
  industry_filter?: InputMaybe<Scalars['Boolean']['input']>;
  industry_white_list_array?: InputMaybe<UserCreateindustry_White_List_ArrayInput>;
  industry_white_list_assign_type?: InputMaybe<AssignType>;
  joinMe_access_token?: InputMaybe<Scalars['String']['input']>;
  joinMe_refresh_token?: InputMaybe<Scalars['String']['input']>;
  last_demo_transferred_at?: InputMaybe<Scalars['DateTime']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutUserInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryCreateNestedManyWithoutUserInput>;
  lead_intents?: InputMaybe<LeadIntentCreateNestedManyWithoutUserInput>;
  lead_source_filter?: InputMaybe<Scalars['Boolean']['input']>;
  lead_source_white_list_array?: InputMaybe<UserCreatelead_Source_White_List_ArrayInput>;
  lead_source_white_list_assign_type?: InputMaybe<AssignType>;
  leads_last_called?: InputMaybe<LeadCreateNestedManyWithoutLast_Call_Result_RepInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusCreateNestedManyWithoutUserInput>;
  manager_id?: InputMaybe<Scalars['String']['input']>;
  note_item?: InputMaybe<NoteItemCreateNestedManyWithoutUserInput>;
  notifications?: InputMaybe<NotificationCreateNestedManyWithoutUserInput>;
  organization: OrganizationCreateNestedOneWithoutUsersInput;
  owned_leads?: InputMaybe<LeadCreateNestedManyWithoutRepInput>;
  panda_doc_access?: InputMaybe<Scalars['Boolean']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  payment_settings?: InputMaybe<OrganizationPaymentSettingsCreateNestedManyWithoutCreated_ByInput>;
  payment_settings_updated?: InputMaybe<OrganizationPaymentSettingsCreateNestedManyWithoutLast_Updated_ByInput>;
  phone_number?: InputMaybe<Scalars['String']['input']>;
  post?: InputMaybe<PostCreateNestedManyWithoutAuthorInput>;
  ProductsCreated?: InputMaybe<ProductChangeLogCreateNestedManyWithoutUpdated_ByInput>;
  profile_image?: InputMaybe<Scalars['String']['input']>;
  receive_transfer_attempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutTo_UserInput>;
  receiver_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutReceiver_UserInput>;
  regions_white_list_array?: InputMaybe<UserCreateregions_White_List_ArrayInput>;
  regions_white_list_assign_type?: InputMaybe<AssignType>;
  rep_scores?: InputMaybe<Scalars['Float']['input']>;
  role?: InputMaybe<Role>;
  routings?: InputMaybe<RoutingCreateNestedManyWithoutRepInput>;
  sale_config?: InputMaybe<SaleConfigCreateNestedManyWithoutCreated_ByInput>;
  sale_config_updated?: InputMaybe<SaleConfigCreateNestedManyWithoutLast_Updated_ByInput>;
  sales_team_column_options?: InputMaybe<UserCreatesales_Team_Column_OptionsInput>;
  schedule_items?: InputMaybe<ScheduleItemCreateNestedManyWithoutUserInput>;
  send_transfer_attempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutFrom_UserInput>;
  sender_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutSender_UserInput>;
  sequence_entry_criteria_id?: InputMaybe<Scalars['String']['input']>;
  SequenceActionLog?: InputMaybe<StepActionLogCreateNestedManyWithoutRepInput>;
  sequenceDashboardView?: InputMaybe<SequenceDashboardViewCreateNestedManyWithoutOwned_ByInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutUserInput>;
  Sequences_created?: InputMaybe<SequenceCreateNestedManyWithoutCreated_ByInput>;
  Sequences_modified_by_user?: InputMaybe<SequenceCreateNestedManyWithoutLast_Updated_ByInput>;
  session_token?: InputMaybe<Scalars['String']['input']>;
  set_user_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutSet_UserInput>;
  set_user_stat?: InputMaybe<StatLeadAggregationCreateNestedManyWithoutSet_UserInput>;
  sf_user_id?: InputMaybe<Scalars['String']['input']>;
  SmsItems?: InputMaybe<SmsItemCreateNestedManyWithoutRepInput>;
  SnoozeTracking?: InputMaybe<SnoozeTrackingCreateNestedManyWithoutRepInput>;
  status?: InputMaybe<Status>;
  statusHistory?: InputMaybe<UserStatusHistoryCreateNestedManyWithoutUserInput>;
  sub_industry_white_list_array?: InputMaybe<UserCreatesub_Industry_White_List_ArrayInput>;
  sub_industry_white_list_assign_type?: InputMaybe<AssignType>;
  support_rep?: InputMaybe<Scalars['Boolean']['input']>;
  taking_demos?: InputMaybe<Scalars['Boolean']['input']>;
  team?: InputMaybe<TeamCreateNestedOneWithoutMembersInput>;
  Template?: InputMaybe<TemplateCreateNestedManyWithoutLast_Modified_ByInput>;
  TemplateVersion?: InputMaybe<TemplateVersionCreateNestedManyWithoutCreated_ByInput>;
  timekit_id?: InputMaybe<Scalars['String']['input']>;
  timekit_type?: InputMaybe<Timekittype>;
  timeOffRequest?: InputMaybe<TimeOffRequestCreateNestedManyWithoutRequesting_UserInput>;
  timezone_raw?: InputMaybe<Scalars['String']['input']>;
  transfer_type?: InputMaybe<TransferType>;
  transfer_user_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutTransfer_UserInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user_custom_availability?: InputMaybe<AvailabilityCreateNestedManyWithoutUserInput>;
  user_past_tokens?: InputMaybe<UserPastTokensCreateNestedManyWithoutUserInput>;
  visible_all_leads_sm?: InputMaybe<Scalars['Boolean']['input']>;
  visible_call_report_columns?: InputMaybe<UserCreatevisible_Call_Report_ColumnsInput>;
  visible_call_report_event_columns?: InputMaybe<UserCreatevisible_Call_Report_Event_ColumnsInput>;
  visible_call_report_pipeline_columns?: InputMaybe<UserCreatevisible_Call_Report_Pipeline_ColumnsInput>;
  visible_my_leads_columns?: InputMaybe<UserCreatevisible_My_Leads_ColumnsInput>;
  voicemail_url?: InputMaybe<Scalars['String']['input']>;
};

export type UserCreateWithoutFrom_Assignment_HistoryInput = {
  ai_user?: InputMaybe<Scalars['Boolean']['input']>;
  alternate_email?: InputMaybe<Scalars['String']['input']>;
  always_show_calendar_options?: InputMaybe<Scalars['Boolean']['input']>;
  associate_owner_rep_history?: InputMaybe<AssociationHistoryCreateNestedManyWithoutRepInput>;
  associate_user_history?: InputMaybe<AssociationHistoryCreateNestedManyWithoutUserInput>;
  auto_transfer_selection_receive_attempts?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutFrom_UserInput>;
  auto_transfer_selection_send_attempts?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutTo_UserInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptCreateNestedManyWithoutUserInput>;
  callSharingAsCoach?: InputMaybe<CallSharingCreateNestedManyWithoutCoachInput>;
  callSharingAsUser?: InputMaybe<CallSharingCreateNestedManyWithoutReceiver_UserInput>;
  channel?: InputMaybe<Channelfilter>;
  close_rate?: InputMaybe<Scalars['Float']['input']>;
  close_user_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutClose_UserInput>;
  close_user_stat?: InputMaybe<StatLeadAggregationCreateNestedManyWithoutClose_UserInput>;
  commissions?: InputMaybe<CommissionCreateNestedManyWithoutUserInput>;
  contact_requests?: InputMaybe<ContactRequestCreateNestedManyWithoutRequesting_UserInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  current_sequence_view?: InputMaybe<SequenceDashboardViewCreateNestedOneWithoutUserInput>;
  current_team_start_date?: InputMaybe<Scalars['DateTime']['input']>;
  custom_field_white_list_array?: InputMaybe<UserCustomFieldFilterCreateNestedManyWithoutUserInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemCreateNestedManyWithoutUserInput>;
  daily_lead_claim?: InputMaybe<Scalars['Int']['input']>;
  dashboard_2_access?: InputMaybe<Scalars['Boolean']['input']>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewCreateNestedManyWithoutUserInput>;
  default_audio_input?: InputMaybe<Scalars['String']['input']>;
  default_audio_output?: InputMaybe<Scalars['String']['input']>;
  default_availability?: InputMaybe<Scalars['Boolean']['input']>;
  deleted_at?: InputMaybe<Scalars['DateTime']['input']>;
  deprecate_old_session?: InputMaybe<Scalars['Boolean']['input']>;
  email: Scalars['String']['input'];
  EmailSyncRecord?: InputMaybe<EmailSyncRecordCreateNestedManyWithoutUserInput>;
  favorite_leads?: InputMaybe<LeadCreateNestedManyWithoutFavorited_By_UsersInput>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  folder_permissions?: InputMaybe<FolderPermissionCreateNestedManyWithoutUserInput>;
  folders?: InputMaybe<FolderCreateNestedManyWithoutOwnerInput>;
  GlobalRouting?: InputMaybe<GlobalRepRoutingCreateNestedManyWithoutRepInput>;
  google_refresh_token?: InputMaybe<Scalars['String']['input']>;
  holds_per_prospect_vs_held_demo_per_dial?: InputMaybe<Scalars['Float']['input']>;
  hook_subscriptions?: InputMaybe<WebHookSubscriptionCreateNestedManyWithoutUserInput>;
  hubspot_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  idle_owned_leads?: InputMaybe<LeadCreateNestedManyWithoutSecondary_RepsInput>;
  industry_filter?: InputMaybe<Scalars['Boolean']['input']>;
  industry_white_list_array?: InputMaybe<UserCreateindustry_White_List_ArrayInput>;
  industry_white_list_assign_type?: InputMaybe<AssignType>;
  joinMe_access_token?: InputMaybe<Scalars['String']['input']>;
  joinMe_refresh_token?: InputMaybe<Scalars['String']['input']>;
  last_demo_transferred_at?: InputMaybe<Scalars['DateTime']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutUserInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryCreateNestedManyWithoutUserInput>;
  lead_intents?: InputMaybe<LeadIntentCreateNestedManyWithoutUserInput>;
  lead_source_filter?: InputMaybe<Scalars['Boolean']['input']>;
  lead_source_white_list_array?: InputMaybe<UserCreatelead_Source_White_List_ArrayInput>;
  lead_source_white_list_assign_type?: InputMaybe<AssignType>;
  leads_last_called?: InputMaybe<LeadCreateNestedManyWithoutLast_Call_Result_RepInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusCreateNestedManyWithoutUserInput>;
  manager_id?: InputMaybe<Scalars['String']['input']>;
  note_item?: InputMaybe<NoteItemCreateNestedManyWithoutUserInput>;
  notifications?: InputMaybe<NotificationCreateNestedManyWithoutUserInput>;
  organization: OrganizationCreateNestedOneWithoutUsersInput;
  owned_leads?: InputMaybe<LeadCreateNestedManyWithoutRepInput>;
  panda_doc_access?: InputMaybe<Scalars['Boolean']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  payment_settings?: InputMaybe<OrganizationPaymentSettingsCreateNestedManyWithoutCreated_ByInput>;
  payment_settings_updated?: InputMaybe<OrganizationPaymentSettingsCreateNestedManyWithoutLast_Updated_ByInput>;
  phone_number?: InputMaybe<Scalars['String']['input']>;
  post?: InputMaybe<PostCreateNestedManyWithoutAuthorInput>;
  ProductsCreated?: InputMaybe<ProductChangeLogCreateNestedManyWithoutUpdated_ByInput>;
  profile_image?: InputMaybe<Scalars['String']['input']>;
  receive_transfer_attempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutTo_UserInput>;
  receiver_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutReceiver_UserInput>;
  regions_white_list_array?: InputMaybe<UserCreateregions_White_List_ArrayInput>;
  regions_white_list_assign_type?: InputMaybe<AssignType>;
  rep_scores?: InputMaybe<Scalars['Float']['input']>;
  role?: InputMaybe<Role>;
  routings?: InputMaybe<RoutingCreateNestedManyWithoutRepInput>;
  sale_config?: InputMaybe<SaleConfigCreateNestedManyWithoutCreated_ByInput>;
  sale_config_updated?: InputMaybe<SaleConfigCreateNestedManyWithoutLast_Updated_ByInput>;
  sales_team_column_options?: InputMaybe<UserCreatesales_Team_Column_OptionsInput>;
  schedule_items?: InputMaybe<ScheduleItemCreateNestedManyWithoutUserInput>;
  send_transfer_attempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutFrom_UserInput>;
  sender_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutSender_UserInput>;
  sequence_entry_criteria_id?: InputMaybe<Scalars['String']['input']>;
  SequenceActionLog?: InputMaybe<StepActionLogCreateNestedManyWithoutRepInput>;
  sequenceDashboardView?: InputMaybe<SequenceDashboardViewCreateNestedManyWithoutOwned_ByInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutUserInput>;
  Sequences_created?: InputMaybe<SequenceCreateNestedManyWithoutCreated_ByInput>;
  Sequences_modified_by_user?: InputMaybe<SequenceCreateNestedManyWithoutLast_Updated_ByInput>;
  session_token?: InputMaybe<Scalars['String']['input']>;
  set_user_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutSet_UserInput>;
  set_user_stat?: InputMaybe<StatLeadAggregationCreateNestedManyWithoutSet_UserInput>;
  sf_user_id?: InputMaybe<Scalars['String']['input']>;
  SmsItems?: InputMaybe<SmsItemCreateNestedManyWithoutRepInput>;
  SnoozeTracking?: InputMaybe<SnoozeTrackingCreateNestedManyWithoutRepInput>;
  status?: InputMaybe<Status>;
  statusHistory?: InputMaybe<UserStatusHistoryCreateNestedManyWithoutUserInput>;
  sub_industry_white_list_array?: InputMaybe<UserCreatesub_Industry_White_List_ArrayInput>;
  sub_industry_white_list_assign_type?: InputMaybe<AssignType>;
  support_rep?: InputMaybe<Scalars['Boolean']['input']>;
  taking_demos?: InputMaybe<Scalars['Boolean']['input']>;
  team?: InputMaybe<TeamCreateNestedOneWithoutMembersInput>;
  Template?: InputMaybe<TemplateCreateNestedManyWithoutLast_Modified_ByInput>;
  TemplateVersion?: InputMaybe<TemplateVersionCreateNestedManyWithoutCreated_ByInput>;
  timekit_id?: InputMaybe<Scalars['String']['input']>;
  timekit_type?: InputMaybe<Timekittype>;
  timeOffRequest?: InputMaybe<TimeOffRequestCreateNestedManyWithoutRequesting_UserInput>;
  timezone_raw?: InputMaybe<Scalars['String']['input']>;
  transfer_type?: InputMaybe<TransferType>;
  transfer_user_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutTransfer_UserInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user_custom_availability?: InputMaybe<AvailabilityCreateNestedManyWithoutUserInput>;
  user_past_tokens?: InputMaybe<UserPastTokensCreateNestedManyWithoutUserInput>;
  visible_all_leads_sm?: InputMaybe<Scalars['Boolean']['input']>;
  visible_call_report_columns?: InputMaybe<UserCreatevisible_Call_Report_ColumnsInput>;
  visible_call_report_event_columns?: InputMaybe<UserCreatevisible_Call_Report_Event_ColumnsInput>;
  visible_call_report_pipeline_columns?: InputMaybe<UserCreatevisible_Call_Report_Pipeline_ColumnsInput>;
  visible_my_leads_columns?: InputMaybe<UserCreatevisible_My_Leads_ColumnsInput>;
  voicemail_url?: InputMaybe<Scalars['String']['input']>;
};

export type UserCreateWithoutGlobalRoutingInput = {
  ai_user?: InputMaybe<Scalars['Boolean']['input']>;
  alternate_email?: InputMaybe<Scalars['String']['input']>;
  always_show_calendar_options?: InputMaybe<Scalars['Boolean']['input']>;
  associate_owner_rep_history?: InputMaybe<AssociationHistoryCreateNestedManyWithoutRepInput>;
  associate_user_history?: InputMaybe<AssociationHistoryCreateNestedManyWithoutUserInput>;
  auto_transfer_selection_receive_attempts?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutFrom_UserInput>;
  auto_transfer_selection_send_attempts?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutTo_UserInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptCreateNestedManyWithoutUserInput>;
  callSharingAsCoach?: InputMaybe<CallSharingCreateNestedManyWithoutCoachInput>;
  callSharingAsUser?: InputMaybe<CallSharingCreateNestedManyWithoutReceiver_UserInput>;
  channel?: InputMaybe<Channelfilter>;
  close_rate?: InputMaybe<Scalars['Float']['input']>;
  close_user_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutClose_UserInput>;
  close_user_stat?: InputMaybe<StatLeadAggregationCreateNestedManyWithoutClose_UserInput>;
  commissions?: InputMaybe<CommissionCreateNestedManyWithoutUserInput>;
  contact_requests?: InputMaybe<ContactRequestCreateNestedManyWithoutRequesting_UserInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  current_sequence_view?: InputMaybe<SequenceDashboardViewCreateNestedOneWithoutUserInput>;
  current_team_start_date?: InputMaybe<Scalars['DateTime']['input']>;
  custom_field_white_list_array?: InputMaybe<UserCustomFieldFilterCreateNestedManyWithoutUserInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemCreateNestedManyWithoutUserInput>;
  daily_lead_claim?: InputMaybe<Scalars['Int']['input']>;
  dashboard_2_access?: InputMaybe<Scalars['Boolean']['input']>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewCreateNestedManyWithoutUserInput>;
  default_audio_input?: InputMaybe<Scalars['String']['input']>;
  default_audio_output?: InputMaybe<Scalars['String']['input']>;
  default_availability?: InputMaybe<Scalars['Boolean']['input']>;
  deleted_at?: InputMaybe<Scalars['DateTime']['input']>;
  deprecate_old_session?: InputMaybe<Scalars['Boolean']['input']>;
  email: Scalars['String']['input'];
  EmailSyncRecord?: InputMaybe<EmailSyncRecordCreateNestedManyWithoutUserInput>;
  favorite_leads?: InputMaybe<LeadCreateNestedManyWithoutFavorited_By_UsersInput>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  folder_permissions?: InputMaybe<FolderPermissionCreateNestedManyWithoutUserInput>;
  folders?: InputMaybe<FolderCreateNestedManyWithoutOwnerInput>;
  from_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutOld_UserInput>;
  google_refresh_token?: InputMaybe<Scalars['String']['input']>;
  holds_per_prospect_vs_held_demo_per_dial?: InputMaybe<Scalars['Float']['input']>;
  hook_subscriptions?: InputMaybe<WebHookSubscriptionCreateNestedManyWithoutUserInput>;
  hubspot_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  idle_owned_leads?: InputMaybe<LeadCreateNestedManyWithoutSecondary_RepsInput>;
  industry_filter?: InputMaybe<Scalars['Boolean']['input']>;
  industry_white_list_array?: InputMaybe<UserCreateindustry_White_List_ArrayInput>;
  industry_white_list_assign_type?: InputMaybe<AssignType>;
  joinMe_access_token?: InputMaybe<Scalars['String']['input']>;
  joinMe_refresh_token?: InputMaybe<Scalars['String']['input']>;
  last_demo_transferred_at?: InputMaybe<Scalars['DateTime']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutUserInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryCreateNestedManyWithoutUserInput>;
  lead_intents?: InputMaybe<LeadIntentCreateNestedManyWithoutUserInput>;
  lead_source_filter?: InputMaybe<Scalars['Boolean']['input']>;
  lead_source_white_list_array?: InputMaybe<UserCreatelead_Source_White_List_ArrayInput>;
  lead_source_white_list_assign_type?: InputMaybe<AssignType>;
  leads_last_called?: InputMaybe<LeadCreateNestedManyWithoutLast_Call_Result_RepInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusCreateNestedManyWithoutUserInput>;
  manager_id?: InputMaybe<Scalars['String']['input']>;
  note_item?: InputMaybe<NoteItemCreateNestedManyWithoutUserInput>;
  notifications?: InputMaybe<NotificationCreateNestedManyWithoutUserInput>;
  organization: OrganizationCreateNestedOneWithoutUsersInput;
  owned_leads?: InputMaybe<LeadCreateNestedManyWithoutRepInput>;
  panda_doc_access?: InputMaybe<Scalars['Boolean']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  payment_settings?: InputMaybe<OrganizationPaymentSettingsCreateNestedManyWithoutCreated_ByInput>;
  payment_settings_updated?: InputMaybe<OrganizationPaymentSettingsCreateNestedManyWithoutLast_Updated_ByInput>;
  phone_number?: InputMaybe<Scalars['String']['input']>;
  post?: InputMaybe<PostCreateNestedManyWithoutAuthorInput>;
  ProductsCreated?: InputMaybe<ProductChangeLogCreateNestedManyWithoutUpdated_ByInput>;
  profile_image?: InputMaybe<Scalars['String']['input']>;
  receive_transfer_attempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutTo_UserInput>;
  receiver_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutReceiver_UserInput>;
  regions_white_list_array?: InputMaybe<UserCreateregions_White_List_ArrayInput>;
  regions_white_list_assign_type?: InputMaybe<AssignType>;
  rep_scores?: InputMaybe<Scalars['Float']['input']>;
  role?: InputMaybe<Role>;
  routings?: InputMaybe<RoutingCreateNestedManyWithoutRepInput>;
  sale_config?: InputMaybe<SaleConfigCreateNestedManyWithoutCreated_ByInput>;
  sale_config_updated?: InputMaybe<SaleConfigCreateNestedManyWithoutLast_Updated_ByInput>;
  sales_team_column_options?: InputMaybe<UserCreatesales_Team_Column_OptionsInput>;
  schedule_items?: InputMaybe<ScheduleItemCreateNestedManyWithoutUserInput>;
  send_transfer_attempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutFrom_UserInput>;
  sender_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutSender_UserInput>;
  sequence_entry_criteria_id?: InputMaybe<Scalars['String']['input']>;
  SequenceActionLog?: InputMaybe<StepActionLogCreateNestedManyWithoutRepInput>;
  sequenceDashboardView?: InputMaybe<SequenceDashboardViewCreateNestedManyWithoutOwned_ByInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutUserInput>;
  Sequences_created?: InputMaybe<SequenceCreateNestedManyWithoutCreated_ByInput>;
  Sequences_modified_by_user?: InputMaybe<SequenceCreateNestedManyWithoutLast_Updated_ByInput>;
  session_token?: InputMaybe<Scalars['String']['input']>;
  set_user_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutSet_UserInput>;
  set_user_stat?: InputMaybe<StatLeadAggregationCreateNestedManyWithoutSet_UserInput>;
  sf_user_id?: InputMaybe<Scalars['String']['input']>;
  SmsItems?: InputMaybe<SmsItemCreateNestedManyWithoutRepInput>;
  SnoozeTracking?: InputMaybe<SnoozeTrackingCreateNestedManyWithoutRepInput>;
  status?: InputMaybe<Status>;
  statusHistory?: InputMaybe<UserStatusHistoryCreateNestedManyWithoutUserInput>;
  sub_industry_white_list_array?: InputMaybe<UserCreatesub_Industry_White_List_ArrayInput>;
  sub_industry_white_list_assign_type?: InputMaybe<AssignType>;
  support_rep?: InputMaybe<Scalars['Boolean']['input']>;
  taking_demos?: InputMaybe<Scalars['Boolean']['input']>;
  team?: InputMaybe<TeamCreateNestedOneWithoutMembersInput>;
  Template?: InputMaybe<TemplateCreateNestedManyWithoutLast_Modified_ByInput>;
  TemplateVersion?: InputMaybe<TemplateVersionCreateNestedManyWithoutCreated_ByInput>;
  timekit_id?: InputMaybe<Scalars['String']['input']>;
  timekit_type?: InputMaybe<Timekittype>;
  timeOffRequest?: InputMaybe<TimeOffRequestCreateNestedManyWithoutRequesting_UserInput>;
  timezone_raw?: InputMaybe<Scalars['String']['input']>;
  transfer_type?: InputMaybe<TransferType>;
  transfer_user_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutTransfer_UserInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user_custom_availability?: InputMaybe<AvailabilityCreateNestedManyWithoutUserInput>;
  user_past_tokens?: InputMaybe<UserPastTokensCreateNestedManyWithoutUserInput>;
  visible_all_leads_sm?: InputMaybe<Scalars['Boolean']['input']>;
  visible_call_report_columns?: InputMaybe<UserCreatevisible_Call_Report_ColumnsInput>;
  visible_call_report_event_columns?: InputMaybe<UserCreatevisible_Call_Report_Event_ColumnsInput>;
  visible_call_report_pipeline_columns?: InputMaybe<UserCreatevisible_Call_Report_Pipeline_ColumnsInput>;
  visible_my_leads_columns?: InputMaybe<UserCreatevisible_My_Leads_ColumnsInput>;
  voicemail_url?: InputMaybe<Scalars['String']['input']>;
};

export type UserCreateWithoutIdle_Owned_LeadsInput = {
  ai_user?: InputMaybe<Scalars['Boolean']['input']>;
  alternate_email?: InputMaybe<Scalars['String']['input']>;
  always_show_calendar_options?: InputMaybe<Scalars['Boolean']['input']>;
  associate_owner_rep_history?: InputMaybe<AssociationHistoryCreateNestedManyWithoutRepInput>;
  associate_user_history?: InputMaybe<AssociationHistoryCreateNestedManyWithoutUserInput>;
  auto_transfer_selection_receive_attempts?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutFrom_UserInput>;
  auto_transfer_selection_send_attempts?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutTo_UserInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptCreateNestedManyWithoutUserInput>;
  callSharingAsCoach?: InputMaybe<CallSharingCreateNestedManyWithoutCoachInput>;
  callSharingAsUser?: InputMaybe<CallSharingCreateNestedManyWithoutReceiver_UserInput>;
  channel?: InputMaybe<Channelfilter>;
  close_rate?: InputMaybe<Scalars['Float']['input']>;
  close_user_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutClose_UserInput>;
  close_user_stat?: InputMaybe<StatLeadAggregationCreateNestedManyWithoutClose_UserInput>;
  commissions?: InputMaybe<CommissionCreateNestedManyWithoutUserInput>;
  contact_requests?: InputMaybe<ContactRequestCreateNestedManyWithoutRequesting_UserInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  current_sequence_view?: InputMaybe<SequenceDashboardViewCreateNestedOneWithoutUserInput>;
  current_team_start_date?: InputMaybe<Scalars['DateTime']['input']>;
  custom_field_white_list_array?: InputMaybe<UserCustomFieldFilterCreateNestedManyWithoutUserInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemCreateNestedManyWithoutUserInput>;
  daily_lead_claim?: InputMaybe<Scalars['Int']['input']>;
  dashboard_2_access?: InputMaybe<Scalars['Boolean']['input']>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewCreateNestedManyWithoutUserInput>;
  default_audio_input?: InputMaybe<Scalars['String']['input']>;
  default_audio_output?: InputMaybe<Scalars['String']['input']>;
  default_availability?: InputMaybe<Scalars['Boolean']['input']>;
  deleted_at?: InputMaybe<Scalars['DateTime']['input']>;
  deprecate_old_session?: InputMaybe<Scalars['Boolean']['input']>;
  email: Scalars['String']['input'];
  EmailSyncRecord?: InputMaybe<EmailSyncRecordCreateNestedManyWithoutUserInput>;
  favorite_leads?: InputMaybe<LeadCreateNestedManyWithoutFavorited_By_UsersInput>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  folder_permissions?: InputMaybe<FolderPermissionCreateNestedManyWithoutUserInput>;
  folders?: InputMaybe<FolderCreateNestedManyWithoutOwnerInput>;
  from_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutOld_UserInput>;
  GlobalRouting?: InputMaybe<GlobalRepRoutingCreateNestedManyWithoutRepInput>;
  google_refresh_token?: InputMaybe<Scalars['String']['input']>;
  holds_per_prospect_vs_held_demo_per_dial?: InputMaybe<Scalars['Float']['input']>;
  hook_subscriptions?: InputMaybe<WebHookSubscriptionCreateNestedManyWithoutUserInput>;
  hubspot_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  industry_filter?: InputMaybe<Scalars['Boolean']['input']>;
  industry_white_list_array?: InputMaybe<UserCreateindustry_White_List_ArrayInput>;
  industry_white_list_assign_type?: InputMaybe<AssignType>;
  joinMe_access_token?: InputMaybe<Scalars['String']['input']>;
  joinMe_refresh_token?: InputMaybe<Scalars['String']['input']>;
  last_demo_transferred_at?: InputMaybe<Scalars['DateTime']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutUserInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryCreateNestedManyWithoutUserInput>;
  lead_intents?: InputMaybe<LeadIntentCreateNestedManyWithoutUserInput>;
  lead_source_filter?: InputMaybe<Scalars['Boolean']['input']>;
  lead_source_white_list_array?: InputMaybe<UserCreatelead_Source_White_List_ArrayInput>;
  lead_source_white_list_assign_type?: InputMaybe<AssignType>;
  leads_last_called?: InputMaybe<LeadCreateNestedManyWithoutLast_Call_Result_RepInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusCreateNestedManyWithoutUserInput>;
  manager_id?: InputMaybe<Scalars['String']['input']>;
  note_item?: InputMaybe<NoteItemCreateNestedManyWithoutUserInput>;
  notifications?: InputMaybe<NotificationCreateNestedManyWithoutUserInput>;
  organization: OrganizationCreateNestedOneWithoutUsersInput;
  owned_leads?: InputMaybe<LeadCreateNestedManyWithoutRepInput>;
  panda_doc_access?: InputMaybe<Scalars['Boolean']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  payment_settings?: InputMaybe<OrganizationPaymentSettingsCreateNestedManyWithoutCreated_ByInput>;
  payment_settings_updated?: InputMaybe<OrganizationPaymentSettingsCreateNestedManyWithoutLast_Updated_ByInput>;
  phone_number?: InputMaybe<Scalars['String']['input']>;
  post?: InputMaybe<PostCreateNestedManyWithoutAuthorInput>;
  ProductsCreated?: InputMaybe<ProductChangeLogCreateNestedManyWithoutUpdated_ByInput>;
  profile_image?: InputMaybe<Scalars['String']['input']>;
  receive_transfer_attempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutTo_UserInput>;
  receiver_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutReceiver_UserInput>;
  regions_white_list_array?: InputMaybe<UserCreateregions_White_List_ArrayInput>;
  regions_white_list_assign_type?: InputMaybe<AssignType>;
  rep_scores?: InputMaybe<Scalars['Float']['input']>;
  role?: InputMaybe<Role>;
  routings?: InputMaybe<RoutingCreateNestedManyWithoutRepInput>;
  sale_config?: InputMaybe<SaleConfigCreateNestedManyWithoutCreated_ByInput>;
  sale_config_updated?: InputMaybe<SaleConfigCreateNestedManyWithoutLast_Updated_ByInput>;
  sales_team_column_options?: InputMaybe<UserCreatesales_Team_Column_OptionsInput>;
  schedule_items?: InputMaybe<ScheduleItemCreateNestedManyWithoutUserInput>;
  send_transfer_attempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutFrom_UserInput>;
  sender_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutSender_UserInput>;
  sequence_entry_criteria_id?: InputMaybe<Scalars['String']['input']>;
  SequenceActionLog?: InputMaybe<StepActionLogCreateNestedManyWithoutRepInput>;
  sequenceDashboardView?: InputMaybe<SequenceDashboardViewCreateNestedManyWithoutOwned_ByInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutUserInput>;
  Sequences_created?: InputMaybe<SequenceCreateNestedManyWithoutCreated_ByInput>;
  Sequences_modified_by_user?: InputMaybe<SequenceCreateNestedManyWithoutLast_Updated_ByInput>;
  session_token?: InputMaybe<Scalars['String']['input']>;
  set_user_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutSet_UserInput>;
  set_user_stat?: InputMaybe<StatLeadAggregationCreateNestedManyWithoutSet_UserInput>;
  sf_user_id?: InputMaybe<Scalars['String']['input']>;
  SmsItems?: InputMaybe<SmsItemCreateNestedManyWithoutRepInput>;
  SnoozeTracking?: InputMaybe<SnoozeTrackingCreateNestedManyWithoutRepInput>;
  status?: InputMaybe<Status>;
  statusHistory?: InputMaybe<UserStatusHistoryCreateNestedManyWithoutUserInput>;
  sub_industry_white_list_array?: InputMaybe<UserCreatesub_Industry_White_List_ArrayInput>;
  sub_industry_white_list_assign_type?: InputMaybe<AssignType>;
  support_rep?: InputMaybe<Scalars['Boolean']['input']>;
  taking_demos?: InputMaybe<Scalars['Boolean']['input']>;
  team?: InputMaybe<TeamCreateNestedOneWithoutMembersInput>;
  Template?: InputMaybe<TemplateCreateNestedManyWithoutLast_Modified_ByInput>;
  TemplateVersion?: InputMaybe<TemplateVersionCreateNestedManyWithoutCreated_ByInput>;
  timekit_id?: InputMaybe<Scalars['String']['input']>;
  timekit_type?: InputMaybe<Timekittype>;
  timeOffRequest?: InputMaybe<TimeOffRequestCreateNestedManyWithoutRequesting_UserInput>;
  timezone_raw?: InputMaybe<Scalars['String']['input']>;
  transfer_type?: InputMaybe<TransferType>;
  transfer_user_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutTransfer_UserInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user_custom_availability?: InputMaybe<AvailabilityCreateNestedManyWithoutUserInput>;
  user_past_tokens?: InputMaybe<UserPastTokensCreateNestedManyWithoutUserInput>;
  visible_all_leads_sm?: InputMaybe<Scalars['Boolean']['input']>;
  visible_call_report_columns?: InputMaybe<UserCreatevisible_Call_Report_ColumnsInput>;
  visible_call_report_event_columns?: InputMaybe<UserCreatevisible_Call_Report_Event_ColumnsInput>;
  visible_call_report_pipeline_columns?: InputMaybe<UserCreatevisible_Call_Report_Pipeline_ColumnsInput>;
  visible_my_leads_columns?: InputMaybe<UserCreatevisible_My_Leads_ColumnsInput>;
  voicemail_url?: InputMaybe<Scalars['String']['input']>;
};

export type UserCreateWithoutLead_ActivitiesInput = {
  ai_user?: InputMaybe<Scalars['Boolean']['input']>;
  alternate_email?: InputMaybe<Scalars['String']['input']>;
  always_show_calendar_options?: InputMaybe<Scalars['Boolean']['input']>;
  associate_owner_rep_history?: InputMaybe<AssociationHistoryCreateNestedManyWithoutRepInput>;
  associate_user_history?: InputMaybe<AssociationHistoryCreateNestedManyWithoutUserInput>;
  auto_transfer_selection_receive_attempts?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutFrom_UserInput>;
  auto_transfer_selection_send_attempts?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutTo_UserInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptCreateNestedManyWithoutUserInput>;
  callSharingAsCoach?: InputMaybe<CallSharingCreateNestedManyWithoutCoachInput>;
  callSharingAsUser?: InputMaybe<CallSharingCreateNestedManyWithoutReceiver_UserInput>;
  channel?: InputMaybe<Channelfilter>;
  close_rate?: InputMaybe<Scalars['Float']['input']>;
  close_user_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutClose_UserInput>;
  close_user_stat?: InputMaybe<StatLeadAggregationCreateNestedManyWithoutClose_UserInput>;
  commissions?: InputMaybe<CommissionCreateNestedManyWithoutUserInput>;
  contact_requests?: InputMaybe<ContactRequestCreateNestedManyWithoutRequesting_UserInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  current_sequence_view?: InputMaybe<SequenceDashboardViewCreateNestedOneWithoutUserInput>;
  current_team_start_date?: InputMaybe<Scalars['DateTime']['input']>;
  custom_field_white_list_array?: InputMaybe<UserCustomFieldFilterCreateNestedManyWithoutUserInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemCreateNestedManyWithoutUserInput>;
  daily_lead_claim?: InputMaybe<Scalars['Int']['input']>;
  dashboard_2_access?: InputMaybe<Scalars['Boolean']['input']>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewCreateNestedManyWithoutUserInput>;
  default_audio_input?: InputMaybe<Scalars['String']['input']>;
  default_audio_output?: InputMaybe<Scalars['String']['input']>;
  default_availability?: InputMaybe<Scalars['Boolean']['input']>;
  deleted_at?: InputMaybe<Scalars['DateTime']['input']>;
  deprecate_old_session?: InputMaybe<Scalars['Boolean']['input']>;
  email: Scalars['String']['input'];
  EmailSyncRecord?: InputMaybe<EmailSyncRecordCreateNestedManyWithoutUserInput>;
  favorite_leads?: InputMaybe<LeadCreateNestedManyWithoutFavorited_By_UsersInput>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  folder_permissions?: InputMaybe<FolderPermissionCreateNestedManyWithoutUserInput>;
  folders?: InputMaybe<FolderCreateNestedManyWithoutOwnerInput>;
  from_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutOld_UserInput>;
  GlobalRouting?: InputMaybe<GlobalRepRoutingCreateNestedManyWithoutRepInput>;
  google_refresh_token?: InputMaybe<Scalars['String']['input']>;
  holds_per_prospect_vs_held_demo_per_dial?: InputMaybe<Scalars['Float']['input']>;
  hook_subscriptions?: InputMaybe<WebHookSubscriptionCreateNestedManyWithoutUserInput>;
  hubspot_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  idle_owned_leads?: InputMaybe<LeadCreateNestedManyWithoutSecondary_RepsInput>;
  industry_filter?: InputMaybe<Scalars['Boolean']['input']>;
  industry_white_list_array?: InputMaybe<UserCreateindustry_White_List_ArrayInput>;
  industry_white_list_assign_type?: InputMaybe<AssignType>;
  joinMe_access_token?: InputMaybe<Scalars['String']['input']>;
  joinMe_refresh_token?: InputMaybe<Scalars['String']['input']>;
  last_demo_transferred_at?: InputMaybe<Scalars['DateTime']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  lead_import_history?: InputMaybe<LeadImportHistoryCreateNestedManyWithoutUserInput>;
  lead_intents?: InputMaybe<LeadIntentCreateNestedManyWithoutUserInput>;
  lead_source_filter?: InputMaybe<Scalars['Boolean']['input']>;
  lead_source_white_list_array?: InputMaybe<UserCreatelead_Source_White_List_ArrayInput>;
  lead_source_white_list_assign_type?: InputMaybe<AssignType>;
  leads_last_called?: InputMaybe<LeadCreateNestedManyWithoutLast_Call_Result_RepInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusCreateNestedManyWithoutUserInput>;
  manager_id?: InputMaybe<Scalars['String']['input']>;
  note_item?: InputMaybe<NoteItemCreateNestedManyWithoutUserInput>;
  notifications?: InputMaybe<NotificationCreateNestedManyWithoutUserInput>;
  organization: OrganizationCreateNestedOneWithoutUsersInput;
  owned_leads?: InputMaybe<LeadCreateNestedManyWithoutRepInput>;
  panda_doc_access?: InputMaybe<Scalars['Boolean']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  payment_settings?: InputMaybe<OrganizationPaymentSettingsCreateNestedManyWithoutCreated_ByInput>;
  payment_settings_updated?: InputMaybe<OrganizationPaymentSettingsCreateNestedManyWithoutLast_Updated_ByInput>;
  phone_number?: InputMaybe<Scalars['String']['input']>;
  post?: InputMaybe<PostCreateNestedManyWithoutAuthorInput>;
  ProductsCreated?: InputMaybe<ProductChangeLogCreateNestedManyWithoutUpdated_ByInput>;
  profile_image?: InputMaybe<Scalars['String']['input']>;
  receive_transfer_attempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutTo_UserInput>;
  receiver_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutReceiver_UserInput>;
  regions_white_list_array?: InputMaybe<UserCreateregions_White_List_ArrayInput>;
  regions_white_list_assign_type?: InputMaybe<AssignType>;
  rep_scores?: InputMaybe<Scalars['Float']['input']>;
  role?: InputMaybe<Role>;
  routings?: InputMaybe<RoutingCreateNestedManyWithoutRepInput>;
  sale_config?: InputMaybe<SaleConfigCreateNestedManyWithoutCreated_ByInput>;
  sale_config_updated?: InputMaybe<SaleConfigCreateNestedManyWithoutLast_Updated_ByInput>;
  sales_team_column_options?: InputMaybe<UserCreatesales_Team_Column_OptionsInput>;
  schedule_items?: InputMaybe<ScheduleItemCreateNestedManyWithoutUserInput>;
  send_transfer_attempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutFrom_UserInput>;
  sender_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutSender_UserInput>;
  sequence_entry_criteria_id?: InputMaybe<Scalars['String']['input']>;
  SequenceActionLog?: InputMaybe<StepActionLogCreateNestedManyWithoutRepInput>;
  sequenceDashboardView?: InputMaybe<SequenceDashboardViewCreateNestedManyWithoutOwned_ByInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutUserInput>;
  Sequences_created?: InputMaybe<SequenceCreateNestedManyWithoutCreated_ByInput>;
  Sequences_modified_by_user?: InputMaybe<SequenceCreateNestedManyWithoutLast_Updated_ByInput>;
  session_token?: InputMaybe<Scalars['String']['input']>;
  set_user_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutSet_UserInput>;
  set_user_stat?: InputMaybe<StatLeadAggregationCreateNestedManyWithoutSet_UserInput>;
  sf_user_id?: InputMaybe<Scalars['String']['input']>;
  SmsItems?: InputMaybe<SmsItemCreateNestedManyWithoutRepInput>;
  SnoozeTracking?: InputMaybe<SnoozeTrackingCreateNestedManyWithoutRepInput>;
  status?: InputMaybe<Status>;
  statusHistory?: InputMaybe<UserStatusHistoryCreateNestedManyWithoutUserInput>;
  sub_industry_white_list_array?: InputMaybe<UserCreatesub_Industry_White_List_ArrayInput>;
  sub_industry_white_list_assign_type?: InputMaybe<AssignType>;
  support_rep?: InputMaybe<Scalars['Boolean']['input']>;
  taking_demos?: InputMaybe<Scalars['Boolean']['input']>;
  team?: InputMaybe<TeamCreateNestedOneWithoutMembersInput>;
  Template?: InputMaybe<TemplateCreateNestedManyWithoutLast_Modified_ByInput>;
  TemplateVersion?: InputMaybe<TemplateVersionCreateNestedManyWithoutCreated_ByInput>;
  timekit_id?: InputMaybe<Scalars['String']['input']>;
  timekit_type?: InputMaybe<Timekittype>;
  timeOffRequest?: InputMaybe<TimeOffRequestCreateNestedManyWithoutRequesting_UserInput>;
  timezone_raw?: InputMaybe<Scalars['String']['input']>;
  transfer_type?: InputMaybe<TransferType>;
  transfer_user_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutTransfer_UserInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user_custom_availability?: InputMaybe<AvailabilityCreateNestedManyWithoutUserInput>;
  user_past_tokens?: InputMaybe<UserPastTokensCreateNestedManyWithoutUserInput>;
  visible_all_leads_sm?: InputMaybe<Scalars['Boolean']['input']>;
  visible_call_report_columns?: InputMaybe<UserCreatevisible_Call_Report_ColumnsInput>;
  visible_call_report_event_columns?: InputMaybe<UserCreatevisible_Call_Report_Event_ColumnsInput>;
  visible_call_report_pipeline_columns?: InputMaybe<UserCreatevisible_Call_Report_Pipeline_ColumnsInput>;
  visible_my_leads_columns?: InputMaybe<UserCreatevisible_My_Leads_ColumnsInput>;
  voicemail_url?: InputMaybe<Scalars['String']['input']>;
};

export type UserCreateWithoutLead_Import_HistoryInput = {
  ai_user?: InputMaybe<Scalars['Boolean']['input']>;
  alternate_email?: InputMaybe<Scalars['String']['input']>;
  always_show_calendar_options?: InputMaybe<Scalars['Boolean']['input']>;
  associate_owner_rep_history?: InputMaybe<AssociationHistoryCreateNestedManyWithoutRepInput>;
  associate_user_history?: InputMaybe<AssociationHistoryCreateNestedManyWithoutUserInput>;
  auto_transfer_selection_receive_attempts?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutFrom_UserInput>;
  auto_transfer_selection_send_attempts?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutTo_UserInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptCreateNestedManyWithoutUserInput>;
  callSharingAsCoach?: InputMaybe<CallSharingCreateNestedManyWithoutCoachInput>;
  callSharingAsUser?: InputMaybe<CallSharingCreateNestedManyWithoutReceiver_UserInput>;
  channel?: InputMaybe<Channelfilter>;
  close_rate?: InputMaybe<Scalars['Float']['input']>;
  close_user_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutClose_UserInput>;
  close_user_stat?: InputMaybe<StatLeadAggregationCreateNestedManyWithoutClose_UserInput>;
  commissions?: InputMaybe<CommissionCreateNestedManyWithoutUserInput>;
  contact_requests?: InputMaybe<ContactRequestCreateNestedManyWithoutRequesting_UserInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  current_sequence_view?: InputMaybe<SequenceDashboardViewCreateNestedOneWithoutUserInput>;
  current_team_start_date?: InputMaybe<Scalars['DateTime']['input']>;
  custom_field_white_list_array?: InputMaybe<UserCustomFieldFilterCreateNestedManyWithoutUserInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemCreateNestedManyWithoutUserInput>;
  daily_lead_claim?: InputMaybe<Scalars['Int']['input']>;
  dashboard_2_access?: InputMaybe<Scalars['Boolean']['input']>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewCreateNestedManyWithoutUserInput>;
  default_audio_input?: InputMaybe<Scalars['String']['input']>;
  default_audio_output?: InputMaybe<Scalars['String']['input']>;
  default_availability?: InputMaybe<Scalars['Boolean']['input']>;
  deleted_at?: InputMaybe<Scalars['DateTime']['input']>;
  deprecate_old_session?: InputMaybe<Scalars['Boolean']['input']>;
  email: Scalars['String']['input'];
  EmailSyncRecord?: InputMaybe<EmailSyncRecordCreateNestedManyWithoutUserInput>;
  favorite_leads?: InputMaybe<LeadCreateNestedManyWithoutFavorited_By_UsersInput>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  folder_permissions?: InputMaybe<FolderPermissionCreateNestedManyWithoutUserInput>;
  folders?: InputMaybe<FolderCreateNestedManyWithoutOwnerInput>;
  from_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutOld_UserInput>;
  GlobalRouting?: InputMaybe<GlobalRepRoutingCreateNestedManyWithoutRepInput>;
  google_refresh_token?: InputMaybe<Scalars['String']['input']>;
  holds_per_prospect_vs_held_demo_per_dial?: InputMaybe<Scalars['Float']['input']>;
  hook_subscriptions?: InputMaybe<WebHookSubscriptionCreateNestedManyWithoutUserInput>;
  hubspot_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  idle_owned_leads?: InputMaybe<LeadCreateNestedManyWithoutSecondary_RepsInput>;
  industry_filter?: InputMaybe<Scalars['Boolean']['input']>;
  industry_white_list_array?: InputMaybe<UserCreateindustry_White_List_ArrayInput>;
  industry_white_list_assign_type?: InputMaybe<AssignType>;
  joinMe_access_token?: InputMaybe<Scalars['String']['input']>;
  joinMe_refresh_token?: InputMaybe<Scalars['String']['input']>;
  last_demo_transferred_at?: InputMaybe<Scalars['DateTime']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutUserInput>;
  lead_intents?: InputMaybe<LeadIntentCreateNestedManyWithoutUserInput>;
  lead_source_filter?: InputMaybe<Scalars['Boolean']['input']>;
  lead_source_white_list_array?: InputMaybe<UserCreatelead_Source_White_List_ArrayInput>;
  lead_source_white_list_assign_type?: InputMaybe<AssignType>;
  leads_last_called?: InputMaybe<LeadCreateNestedManyWithoutLast_Call_Result_RepInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusCreateNestedManyWithoutUserInput>;
  manager_id?: InputMaybe<Scalars['String']['input']>;
  note_item?: InputMaybe<NoteItemCreateNestedManyWithoutUserInput>;
  notifications?: InputMaybe<NotificationCreateNestedManyWithoutUserInput>;
  organization: OrganizationCreateNestedOneWithoutUsersInput;
  owned_leads?: InputMaybe<LeadCreateNestedManyWithoutRepInput>;
  panda_doc_access?: InputMaybe<Scalars['Boolean']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  payment_settings?: InputMaybe<OrganizationPaymentSettingsCreateNestedManyWithoutCreated_ByInput>;
  payment_settings_updated?: InputMaybe<OrganizationPaymentSettingsCreateNestedManyWithoutLast_Updated_ByInput>;
  phone_number?: InputMaybe<Scalars['String']['input']>;
  post?: InputMaybe<PostCreateNestedManyWithoutAuthorInput>;
  ProductsCreated?: InputMaybe<ProductChangeLogCreateNestedManyWithoutUpdated_ByInput>;
  profile_image?: InputMaybe<Scalars['String']['input']>;
  receive_transfer_attempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutTo_UserInput>;
  receiver_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutReceiver_UserInput>;
  regions_white_list_array?: InputMaybe<UserCreateregions_White_List_ArrayInput>;
  regions_white_list_assign_type?: InputMaybe<AssignType>;
  rep_scores?: InputMaybe<Scalars['Float']['input']>;
  role?: InputMaybe<Role>;
  routings?: InputMaybe<RoutingCreateNestedManyWithoutRepInput>;
  sale_config?: InputMaybe<SaleConfigCreateNestedManyWithoutCreated_ByInput>;
  sale_config_updated?: InputMaybe<SaleConfigCreateNestedManyWithoutLast_Updated_ByInput>;
  sales_team_column_options?: InputMaybe<UserCreatesales_Team_Column_OptionsInput>;
  schedule_items?: InputMaybe<ScheduleItemCreateNestedManyWithoutUserInput>;
  send_transfer_attempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutFrom_UserInput>;
  sender_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutSender_UserInput>;
  sequence_entry_criteria_id?: InputMaybe<Scalars['String']['input']>;
  SequenceActionLog?: InputMaybe<StepActionLogCreateNestedManyWithoutRepInput>;
  sequenceDashboardView?: InputMaybe<SequenceDashboardViewCreateNestedManyWithoutOwned_ByInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutUserInput>;
  Sequences_created?: InputMaybe<SequenceCreateNestedManyWithoutCreated_ByInput>;
  Sequences_modified_by_user?: InputMaybe<SequenceCreateNestedManyWithoutLast_Updated_ByInput>;
  session_token?: InputMaybe<Scalars['String']['input']>;
  set_user_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutSet_UserInput>;
  set_user_stat?: InputMaybe<StatLeadAggregationCreateNestedManyWithoutSet_UserInput>;
  sf_user_id?: InputMaybe<Scalars['String']['input']>;
  SmsItems?: InputMaybe<SmsItemCreateNestedManyWithoutRepInput>;
  SnoozeTracking?: InputMaybe<SnoozeTrackingCreateNestedManyWithoutRepInput>;
  status?: InputMaybe<Status>;
  statusHistory?: InputMaybe<UserStatusHistoryCreateNestedManyWithoutUserInput>;
  sub_industry_white_list_array?: InputMaybe<UserCreatesub_Industry_White_List_ArrayInput>;
  sub_industry_white_list_assign_type?: InputMaybe<AssignType>;
  support_rep?: InputMaybe<Scalars['Boolean']['input']>;
  taking_demos?: InputMaybe<Scalars['Boolean']['input']>;
  team?: InputMaybe<TeamCreateNestedOneWithoutMembersInput>;
  Template?: InputMaybe<TemplateCreateNestedManyWithoutLast_Modified_ByInput>;
  TemplateVersion?: InputMaybe<TemplateVersionCreateNestedManyWithoutCreated_ByInput>;
  timekit_id?: InputMaybe<Scalars['String']['input']>;
  timekit_type?: InputMaybe<Timekittype>;
  timeOffRequest?: InputMaybe<TimeOffRequestCreateNestedManyWithoutRequesting_UserInput>;
  timezone_raw?: InputMaybe<Scalars['String']['input']>;
  transfer_type?: InputMaybe<TransferType>;
  transfer_user_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutTransfer_UserInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user_custom_availability?: InputMaybe<AvailabilityCreateNestedManyWithoutUserInput>;
  user_past_tokens?: InputMaybe<UserPastTokensCreateNestedManyWithoutUserInput>;
  visible_all_leads_sm?: InputMaybe<Scalars['Boolean']['input']>;
  visible_call_report_columns?: InputMaybe<UserCreatevisible_Call_Report_ColumnsInput>;
  visible_call_report_event_columns?: InputMaybe<UserCreatevisible_Call_Report_Event_ColumnsInput>;
  visible_call_report_pipeline_columns?: InputMaybe<UserCreatevisible_Call_Report_Pipeline_ColumnsInput>;
  visible_my_leads_columns?: InputMaybe<UserCreatevisible_My_Leads_ColumnsInput>;
  voicemail_url?: InputMaybe<Scalars['String']['input']>;
};

export type UserCreateWithoutLead_IntentsInput = {
  ai_user?: InputMaybe<Scalars['Boolean']['input']>;
  alternate_email?: InputMaybe<Scalars['String']['input']>;
  always_show_calendar_options?: InputMaybe<Scalars['Boolean']['input']>;
  associate_owner_rep_history?: InputMaybe<AssociationHistoryCreateNestedManyWithoutRepInput>;
  associate_user_history?: InputMaybe<AssociationHistoryCreateNestedManyWithoutUserInput>;
  auto_transfer_selection_receive_attempts?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutFrom_UserInput>;
  auto_transfer_selection_send_attempts?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutTo_UserInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptCreateNestedManyWithoutUserInput>;
  callSharingAsCoach?: InputMaybe<CallSharingCreateNestedManyWithoutCoachInput>;
  callSharingAsUser?: InputMaybe<CallSharingCreateNestedManyWithoutReceiver_UserInput>;
  channel?: InputMaybe<Channelfilter>;
  close_rate?: InputMaybe<Scalars['Float']['input']>;
  close_user_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutClose_UserInput>;
  close_user_stat?: InputMaybe<StatLeadAggregationCreateNestedManyWithoutClose_UserInput>;
  commissions?: InputMaybe<CommissionCreateNestedManyWithoutUserInput>;
  contact_requests?: InputMaybe<ContactRequestCreateNestedManyWithoutRequesting_UserInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  current_sequence_view?: InputMaybe<SequenceDashboardViewCreateNestedOneWithoutUserInput>;
  current_team_start_date?: InputMaybe<Scalars['DateTime']['input']>;
  custom_field_white_list_array?: InputMaybe<UserCustomFieldFilterCreateNestedManyWithoutUserInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemCreateNestedManyWithoutUserInput>;
  daily_lead_claim?: InputMaybe<Scalars['Int']['input']>;
  dashboard_2_access?: InputMaybe<Scalars['Boolean']['input']>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewCreateNestedManyWithoutUserInput>;
  default_audio_input?: InputMaybe<Scalars['String']['input']>;
  default_audio_output?: InputMaybe<Scalars['String']['input']>;
  default_availability?: InputMaybe<Scalars['Boolean']['input']>;
  deleted_at?: InputMaybe<Scalars['DateTime']['input']>;
  deprecate_old_session?: InputMaybe<Scalars['Boolean']['input']>;
  email: Scalars['String']['input'];
  EmailSyncRecord?: InputMaybe<EmailSyncRecordCreateNestedManyWithoutUserInput>;
  favorite_leads?: InputMaybe<LeadCreateNestedManyWithoutFavorited_By_UsersInput>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  folder_permissions?: InputMaybe<FolderPermissionCreateNestedManyWithoutUserInput>;
  folders?: InputMaybe<FolderCreateNestedManyWithoutOwnerInput>;
  from_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutOld_UserInput>;
  GlobalRouting?: InputMaybe<GlobalRepRoutingCreateNestedManyWithoutRepInput>;
  google_refresh_token?: InputMaybe<Scalars['String']['input']>;
  holds_per_prospect_vs_held_demo_per_dial?: InputMaybe<Scalars['Float']['input']>;
  hook_subscriptions?: InputMaybe<WebHookSubscriptionCreateNestedManyWithoutUserInput>;
  hubspot_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  idle_owned_leads?: InputMaybe<LeadCreateNestedManyWithoutSecondary_RepsInput>;
  industry_filter?: InputMaybe<Scalars['Boolean']['input']>;
  industry_white_list_array?: InputMaybe<UserCreateindustry_White_List_ArrayInput>;
  industry_white_list_assign_type?: InputMaybe<AssignType>;
  joinMe_access_token?: InputMaybe<Scalars['String']['input']>;
  joinMe_refresh_token?: InputMaybe<Scalars['String']['input']>;
  last_demo_transferred_at?: InputMaybe<Scalars['DateTime']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutUserInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryCreateNestedManyWithoutUserInput>;
  lead_source_filter?: InputMaybe<Scalars['Boolean']['input']>;
  lead_source_white_list_array?: InputMaybe<UserCreatelead_Source_White_List_ArrayInput>;
  lead_source_white_list_assign_type?: InputMaybe<AssignType>;
  leads_last_called?: InputMaybe<LeadCreateNestedManyWithoutLast_Call_Result_RepInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusCreateNestedManyWithoutUserInput>;
  manager_id?: InputMaybe<Scalars['String']['input']>;
  note_item?: InputMaybe<NoteItemCreateNestedManyWithoutUserInput>;
  notifications?: InputMaybe<NotificationCreateNestedManyWithoutUserInput>;
  organization: OrganizationCreateNestedOneWithoutUsersInput;
  owned_leads?: InputMaybe<LeadCreateNestedManyWithoutRepInput>;
  panda_doc_access?: InputMaybe<Scalars['Boolean']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  payment_settings?: InputMaybe<OrganizationPaymentSettingsCreateNestedManyWithoutCreated_ByInput>;
  payment_settings_updated?: InputMaybe<OrganizationPaymentSettingsCreateNestedManyWithoutLast_Updated_ByInput>;
  phone_number?: InputMaybe<Scalars['String']['input']>;
  post?: InputMaybe<PostCreateNestedManyWithoutAuthorInput>;
  ProductsCreated?: InputMaybe<ProductChangeLogCreateNestedManyWithoutUpdated_ByInput>;
  profile_image?: InputMaybe<Scalars['String']['input']>;
  receive_transfer_attempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutTo_UserInput>;
  receiver_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutReceiver_UserInput>;
  regions_white_list_array?: InputMaybe<UserCreateregions_White_List_ArrayInput>;
  regions_white_list_assign_type?: InputMaybe<AssignType>;
  rep_scores?: InputMaybe<Scalars['Float']['input']>;
  role?: InputMaybe<Role>;
  routings?: InputMaybe<RoutingCreateNestedManyWithoutRepInput>;
  sale_config?: InputMaybe<SaleConfigCreateNestedManyWithoutCreated_ByInput>;
  sale_config_updated?: InputMaybe<SaleConfigCreateNestedManyWithoutLast_Updated_ByInput>;
  sales_team_column_options?: InputMaybe<UserCreatesales_Team_Column_OptionsInput>;
  schedule_items?: InputMaybe<ScheduleItemCreateNestedManyWithoutUserInput>;
  send_transfer_attempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutFrom_UserInput>;
  sender_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutSender_UserInput>;
  sequence_entry_criteria_id?: InputMaybe<Scalars['String']['input']>;
  SequenceActionLog?: InputMaybe<StepActionLogCreateNestedManyWithoutRepInput>;
  sequenceDashboardView?: InputMaybe<SequenceDashboardViewCreateNestedManyWithoutOwned_ByInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutUserInput>;
  Sequences_created?: InputMaybe<SequenceCreateNestedManyWithoutCreated_ByInput>;
  Sequences_modified_by_user?: InputMaybe<SequenceCreateNestedManyWithoutLast_Updated_ByInput>;
  session_token?: InputMaybe<Scalars['String']['input']>;
  set_user_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutSet_UserInput>;
  set_user_stat?: InputMaybe<StatLeadAggregationCreateNestedManyWithoutSet_UserInput>;
  sf_user_id?: InputMaybe<Scalars['String']['input']>;
  SmsItems?: InputMaybe<SmsItemCreateNestedManyWithoutRepInput>;
  SnoozeTracking?: InputMaybe<SnoozeTrackingCreateNestedManyWithoutRepInput>;
  status?: InputMaybe<Status>;
  statusHistory?: InputMaybe<UserStatusHistoryCreateNestedManyWithoutUserInput>;
  sub_industry_white_list_array?: InputMaybe<UserCreatesub_Industry_White_List_ArrayInput>;
  sub_industry_white_list_assign_type?: InputMaybe<AssignType>;
  support_rep?: InputMaybe<Scalars['Boolean']['input']>;
  taking_demos?: InputMaybe<Scalars['Boolean']['input']>;
  team?: InputMaybe<TeamCreateNestedOneWithoutMembersInput>;
  Template?: InputMaybe<TemplateCreateNestedManyWithoutLast_Modified_ByInput>;
  TemplateVersion?: InputMaybe<TemplateVersionCreateNestedManyWithoutCreated_ByInput>;
  timekit_id?: InputMaybe<Scalars['String']['input']>;
  timekit_type?: InputMaybe<Timekittype>;
  timeOffRequest?: InputMaybe<TimeOffRequestCreateNestedManyWithoutRequesting_UserInput>;
  timezone_raw?: InputMaybe<Scalars['String']['input']>;
  transfer_type?: InputMaybe<TransferType>;
  transfer_user_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutTransfer_UserInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user_custom_availability?: InputMaybe<AvailabilityCreateNestedManyWithoutUserInput>;
  user_past_tokens?: InputMaybe<UserPastTokensCreateNestedManyWithoutUserInput>;
  visible_all_leads_sm?: InputMaybe<Scalars['Boolean']['input']>;
  visible_call_report_columns?: InputMaybe<UserCreatevisible_Call_Report_ColumnsInput>;
  visible_call_report_event_columns?: InputMaybe<UserCreatevisible_Call_Report_Event_ColumnsInput>;
  visible_call_report_pipeline_columns?: InputMaybe<UserCreatevisible_Call_Report_Pipeline_ColumnsInput>;
  visible_my_leads_columns?: InputMaybe<UserCreatevisible_My_Leads_ColumnsInput>;
  voicemail_url?: InputMaybe<Scalars['String']['input']>;
};

export type UserCreateWithoutLeads_Last_CalledInput = {
  ai_user?: InputMaybe<Scalars['Boolean']['input']>;
  alternate_email?: InputMaybe<Scalars['String']['input']>;
  always_show_calendar_options?: InputMaybe<Scalars['Boolean']['input']>;
  associate_owner_rep_history?: InputMaybe<AssociationHistoryCreateNestedManyWithoutRepInput>;
  associate_user_history?: InputMaybe<AssociationHistoryCreateNestedManyWithoutUserInput>;
  auto_transfer_selection_receive_attempts?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutFrom_UserInput>;
  auto_transfer_selection_send_attempts?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutTo_UserInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptCreateNestedManyWithoutUserInput>;
  callSharingAsCoach?: InputMaybe<CallSharingCreateNestedManyWithoutCoachInput>;
  callSharingAsUser?: InputMaybe<CallSharingCreateNestedManyWithoutReceiver_UserInput>;
  channel?: InputMaybe<Channelfilter>;
  close_rate?: InputMaybe<Scalars['Float']['input']>;
  close_user_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutClose_UserInput>;
  close_user_stat?: InputMaybe<StatLeadAggregationCreateNestedManyWithoutClose_UserInput>;
  commissions?: InputMaybe<CommissionCreateNestedManyWithoutUserInput>;
  contact_requests?: InputMaybe<ContactRequestCreateNestedManyWithoutRequesting_UserInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  current_sequence_view?: InputMaybe<SequenceDashboardViewCreateNestedOneWithoutUserInput>;
  current_team_start_date?: InputMaybe<Scalars['DateTime']['input']>;
  custom_field_white_list_array?: InputMaybe<UserCustomFieldFilterCreateNestedManyWithoutUserInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemCreateNestedManyWithoutUserInput>;
  daily_lead_claim?: InputMaybe<Scalars['Int']['input']>;
  dashboard_2_access?: InputMaybe<Scalars['Boolean']['input']>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewCreateNestedManyWithoutUserInput>;
  default_audio_input?: InputMaybe<Scalars['String']['input']>;
  default_audio_output?: InputMaybe<Scalars['String']['input']>;
  default_availability?: InputMaybe<Scalars['Boolean']['input']>;
  deleted_at?: InputMaybe<Scalars['DateTime']['input']>;
  deprecate_old_session?: InputMaybe<Scalars['Boolean']['input']>;
  email: Scalars['String']['input'];
  EmailSyncRecord?: InputMaybe<EmailSyncRecordCreateNestedManyWithoutUserInput>;
  favorite_leads?: InputMaybe<LeadCreateNestedManyWithoutFavorited_By_UsersInput>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  folder_permissions?: InputMaybe<FolderPermissionCreateNestedManyWithoutUserInput>;
  folders?: InputMaybe<FolderCreateNestedManyWithoutOwnerInput>;
  from_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutOld_UserInput>;
  GlobalRouting?: InputMaybe<GlobalRepRoutingCreateNestedManyWithoutRepInput>;
  google_refresh_token?: InputMaybe<Scalars['String']['input']>;
  holds_per_prospect_vs_held_demo_per_dial?: InputMaybe<Scalars['Float']['input']>;
  hook_subscriptions?: InputMaybe<WebHookSubscriptionCreateNestedManyWithoutUserInput>;
  hubspot_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  idle_owned_leads?: InputMaybe<LeadCreateNestedManyWithoutSecondary_RepsInput>;
  industry_filter?: InputMaybe<Scalars['Boolean']['input']>;
  industry_white_list_array?: InputMaybe<UserCreateindustry_White_List_ArrayInput>;
  industry_white_list_assign_type?: InputMaybe<AssignType>;
  joinMe_access_token?: InputMaybe<Scalars['String']['input']>;
  joinMe_refresh_token?: InputMaybe<Scalars['String']['input']>;
  last_demo_transferred_at?: InputMaybe<Scalars['DateTime']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutUserInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryCreateNestedManyWithoutUserInput>;
  lead_intents?: InputMaybe<LeadIntentCreateNestedManyWithoutUserInput>;
  lead_source_filter?: InputMaybe<Scalars['Boolean']['input']>;
  lead_source_white_list_array?: InputMaybe<UserCreatelead_Source_White_List_ArrayInput>;
  lead_source_white_list_assign_type?: InputMaybe<AssignType>;
  liveUserStatus?: InputMaybe<LiveUserStatusCreateNestedManyWithoutUserInput>;
  manager_id?: InputMaybe<Scalars['String']['input']>;
  note_item?: InputMaybe<NoteItemCreateNestedManyWithoutUserInput>;
  notifications?: InputMaybe<NotificationCreateNestedManyWithoutUserInput>;
  organization: OrganizationCreateNestedOneWithoutUsersInput;
  owned_leads?: InputMaybe<LeadCreateNestedManyWithoutRepInput>;
  panda_doc_access?: InputMaybe<Scalars['Boolean']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  payment_settings?: InputMaybe<OrganizationPaymentSettingsCreateNestedManyWithoutCreated_ByInput>;
  payment_settings_updated?: InputMaybe<OrganizationPaymentSettingsCreateNestedManyWithoutLast_Updated_ByInput>;
  phone_number?: InputMaybe<Scalars['String']['input']>;
  post?: InputMaybe<PostCreateNestedManyWithoutAuthorInput>;
  ProductsCreated?: InputMaybe<ProductChangeLogCreateNestedManyWithoutUpdated_ByInput>;
  profile_image?: InputMaybe<Scalars['String']['input']>;
  receive_transfer_attempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutTo_UserInput>;
  receiver_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutReceiver_UserInput>;
  regions_white_list_array?: InputMaybe<UserCreateregions_White_List_ArrayInput>;
  regions_white_list_assign_type?: InputMaybe<AssignType>;
  rep_scores?: InputMaybe<Scalars['Float']['input']>;
  role?: InputMaybe<Role>;
  routings?: InputMaybe<RoutingCreateNestedManyWithoutRepInput>;
  sale_config?: InputMaybe<SaleConfigCreateNestedManyWithoutCreated_ByInput>;
  sale_config_updated?: InputMaybe<SaleConfigCreateNestedManyWithoutLast_Updated_ByInput>;
  sales_team_column_options?: InputMaybe<UserCreatesales_Team_Column_OptionsInput>;
  schedule_items?: InputMaybe<ScheduleItemCreateNestedManyWithoutUserInput>;
  send_transfer_attempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutFrom_UserInput>;
  sender_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutSender_UserInput>;
  sequence_entry_criteria_id?: InputMaybe<Scalars['String']['input']>;
  SequenceActionLog?: InputMaybe<StepActionLogCreateNestedManyWithoutRepInput>;
  sequenceDashboardView?: InputMaybe<SequenceDashboardViewCreateNestedManyWithoutOwned_ByInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutUserInput>;
  Sequences_created?: InputMaybe<SequenceCreateNestedManyWithoutCreated_ByInput>;
  Sequences_modified_by_user?: InputMaybe<SequenceCreateNestedManyWithoutLast_Updated_ByInput>;
  session_token?: InputMaybe<Scalars['String']['input']>;
  set_user_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutSet_UserInput>;
  set_user_stat?: InputMaybe<StatLeadAggregationCreateNestedManyWithoutSet_UserInput>;
  sf_user_id?: InputMaybe<Scalars['String']['input']>;
  SmsItems?: InputMaybe<SmsItemCreateNestedManyWithoutRepInput>;
  SnoozeTracking?: InputMaybe<SnoozeTrackingCreateNestedManyWithoutRepInput>;
  status?: InputMaybe<Status>;
  statusHistory?: InputMaybe<UserStatusHistoryCreateNestedManyWithoutUserInput>;
  sub_industry_white_list_array?: InputMaybe<UserCreatesub_Industry_White_List_ArrayInput>;
  sub_industry_white_list_assign_type?: InputMaybe<AssignType>;
  support_rep?: InputMaybe<Scalars['Boolean']['input']>;
  taking_demos?: InputMaybe<Scalars['Boolean']['input']>;
  team?: InputMaybe<TeamCreateNestedOneWithoutMembersInput>;
  Template?: InputMaybe<TemplateCreateNestedManyWithoutLast_Modified_ByInput>;
  TemplateVersion?: InputMaybe<TemplateVersionCreateNestedManyWithoutCreated_ByInput>;
  timekit_id?: InputMaybe<Scalars['String']['input']>;
  timekit_type?: InputMaybe<Timekittype>;
  timeOffRequest?: InputMaybe<TimeOffRequestCreateNestedManyWithoutRequesting_UserInput>;
  timezone_raw?: InputMaybe<Scalars['String']['input']>;
  transfer_type?: InputMaybe<TransferType>;
  transfer_user_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutTransfer_UserInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user_custom_availability?: InputMaybe<AvailabilityCreateNestedManyWithoutUserInput>;
  user_past_tokens?: InputMaybe<UserPastTokensCreateNestedManyWithoutUserInput>;
  visible_all_leads_sm?: InputMaybe<Scalars['Boolean']['input']>;
  visible_call_report_columns?: InputMaybe<UserCreatevisible_Call_Report_ColumnsInput>;
  visible_call_report_event_columns?: InputMaybe<UserCreatevisible_Call_Report_Event_ColumnsInput>;
  visible_call_report_pipeline_columns?: InputMaybe<UserCreatevisible_Call_Report_Pipeline_ColumnsInput>;
  visible_my_leads_columns?: InputMaybe<UserCreatevisible_My_Leads_ColumnsInput>;
  voicemail_url?: InputMaybe<Scalars['String']['input']>;
};

export type UserCreateWithoutLiveUserStatusInput = {
  ai_user?: InputMaybe<Scalars['Boolean']['input']>;
  alternate_email?: InputMaybe<Scalars['String']['input']>;
  always_show_calendar_options?: InputMaybe<Scalars['Boolean']['input']>;
  associate_owner_rep_history?: InputMaybe<AssociationHistoryCreateNestedManyWithoutRepInput>;
  associate_user_history?: InputMaybe<AssociationHistoryCreateNestedManyWithoutUserInput>;
  auto_transfer_selection_receive_attempts?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutFrom_UserInput>;
  auto_transfer_selection_send_attempts?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutTo_UserInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptCreateNestedManyWithoutUserInput>;
  callSharingAsCoach?: InputMaybe<CallSharingCreateNestedManyWithoutCoachInput>;
  callSharingAsUser?: InputMaybe<CallSharingCreateNestedManyWithoutReceiver_UserInput>;
  channel?: InputMaybe<Channelfilter>;
  close_rate?: InputMaybe<Scalars['Float']['input']>;
  close_user_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutClose_UserInput>;
  close_user_stat?: InputMaybe<StatLeadAggregationCreateNestedManyWithoutClose_UserInput>;
  commissions?: InputMaybe<CommissionCreateNestedManyWithoutUserInput>;
  contact_requests?: InputMaybe<ContactRequestCreateNestedManyWithoutRequesting_UserInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  current_sequence_view?: InputMaybe<SequenceDashboardViewCreateNestedOneWithoutUserInput>;
  current_team_start_date?: InputMaybe<Scalars['DateTime']['input']>;
  custom_field_white_list_array?: InputMaybe<UserCustomFieldFilterCreateNestedManyWithoutUserInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemCreateNestedManyWithoutUserInput>;
  daily_lead_claim?: InputMaybe<Scalars['Int']['input']>;
  dashboard_2_access?: InputMaybe<Scalars['Boolean']['input']>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewCreateNestedManyWithoutUserInput>;
  default_audio_input?: InputMaybe<Scalars['String']['input']>;
  default_audio_output?: InputMaybe<Scalars['String']['input']>;
  default_availability?: InputMaybe<Scalars['Boolean']['input']>;
  deleted_at?: InputMaybe<Scalars['DateTime']['input']>;
  deprecate_old_session?: InputMaybe<Scalars['Boolean']['input']>;
  email: Scalars['String']['input'];
  EmailSyncRecord?: InputMaybe<EmailSyncRecordCreateNestedManyWithoutUserInput>;
  favorite_leads?: InputMaybe<LeadCreateNestedManyWithoutFavorited_By_UsersInput>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  folder_permissions?: InputMaybe<FolderPermissionCreateNestedManyWithoutUserInput>;
  folders?: InputMaybe<FolderCreateNestedManyWithoutOwnerInput>;
  from_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutOld_UserInput>;
  GlobalRouting?: InputMaybe<GlobalRepRoutingCreateNestedManyWithoutRepInput>;
  google_refresh_token?: InputMaybe<Scalars['String']['input']>;
  holds_per_prospect_vs_held_demo_per_dial?: InputMaybe<Scalars['Float']['input']>;
  hook_subscriptions?: InputMaybe<WebHookSubscriptionCreateNestedManyWithoutUserInput>;
  hubspot_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  idle_owned_leads?: InputMaybe<LeadCreateNestedManyWithoutSecondary_RepsInput>;
  industry_filter?: InputMaybe<Scalars['Boolean']['input']>;
  industry_white_list_array?: InputMaybe<UserCreateindustry_White_List_ArrayInput>;
  industry_white_list_assign_type?: InputMaybe<AssignType>;
  joinMe_access_token?: InputMaybe<Scalars['String']['input']>;
  joinMe_refresh_token?: InputMaybe<Scalars['String']['input']>;
  last_demo_transferred_at?: InputMaybe<Scalars['DateTime']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutUserInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryCreateNestedManyWithoutUserInput>;
  lead_intents?: InputMaybe<LeadIntentCreateNestedManyWithoutUserInput>;
  lead_source_filter?: InputMaybe<Scalars['Boolean']['input']>;
  lead_source_white_list_array?: InputMaybe<UserCreatelead_Source_White_List_ArrayInput>;
  lead_source_white_list_assign_type?: InputMaybe<AssignType>;
  leads_last_called?: InputMaybe<LeadCreateNestedManyWithoutLast_Call_Result_RepInput>;
  manager_id?: InputMaybe<Scalars['String']['input']>;
  note_item?: InputMaybe<NoteItemCreateNestedManyWithoutUserInput>;
  notifications?: InputMaybe<NotificationCreateNestedManyWithoutUserInput>;
  organization: OrganizationCreateNestedOneWithoutUsersInput;
  owned_leads?: InputMaybe<LeadCreateNestedManyWithoutRepInput>;
  panda_doc_access?: InputMaybe<Scalars['Boolean']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  payment_settings?: InputMaybe<OrganizationPaymentSettingsCreateNestedManyWithoutCreated_ByInput>;
  payment_settings_updated?: InputMaybe<OrganizationPaymentSettingsCreateNestedManyWithoutLast_Updated_ByInput>;
  phone_number?: InputMaybe<Scalars['String']['input']>;
  post?: InputMaybe<PostCreateNestedManyWithoutAuthorInput>;
  ProductsCreated?: InputMaybe<ProductChangeLogCreateNestedManyWithoutUpdated_ByInput>;
  profile_image?: InputMaybe<Scalars['String']['input']>;
  receive_transfer_attempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutTo_UserInput>;
  receiver_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutReceiver_UserInput>;
  regions_white_list_array?: InputMaybe<UserCreateregions_White_List_ArrayInput>;
  regions_white_list_assign_type?: InputMaybe<AssignType>;
  rep_scores?: InputMaybe<Scalars['Float']['input']>;
  role?: InputMaybe<Role>;
  routings?: InputMaybe<RoutingCreateNestedManyWithoutRepInput>;
  sale_config?: InputMaybe<SaleConfigCreateNestedManyWithoutCreated_ByInput>;
  sale_config_updated?: InputMaybe<SaleConfigCreateNestedManyWithoutLast_Updated_ByInput>;
  sales_team_column_options?: InputMaybe<UserCreatesales_Team_Column_OptionsInput>;
  schedule_items?: InputMaybe<ScheduleItemCreateNestedManyWithoutUserInput>;
  send_transfer_attempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutFrom_UserInput>;
  sender_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutSender_UserInput>;
  sequence_entry_criteria_id?: InputMaybe<Scalars['String']['input']>;
  SequenceActionLog?: InputMaybe<StepActionLogCreateNestedManyWithoutRepInput>;
  sequenceDashboardView?: InputMaybe<SequenceDashboardViewCreateNestedManyWithoutOwned_ByInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutUserInput>;
  Sequences_created?: InputMaybe<SequenceCreateNestedManyWithoutCreated_ByInput>;
  Sequences_modified_by_user?: InputMaybe<SequenceCreateNestedManyWithoutLast_Updated_ByInput>;
  session_token?: InputMaybe<Scalars['String']['input']>;
  set_user_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutSet_UserInput>;
  set_user_stat?: InputMaybe<StatLeadAggregationCreateNestedManyWithoutSet_UserInput>;
  sf_user_id?: InputMaybe<Scalars['String']['input']>;
  SmsItems?: InputMaybe<SmsItemCreateNestedManyWithoutRepInput>;
  SnoozeTracking?: InputMaybe<SnoozeTrackingCreateNestedManyWithoutRepInput>;
  status?: InputMaybe<Status>;
  statusHistory?: InputMaybe<UserStatusHistoryCreateNestedManyWithoutUserInput>;
  sub_industry_white_list_array?: InputMaybe<UserCreatesub_Industry_White_List_ArrayInput>;
  sub_industry_white_list_assign_type?: InputMaybe<AssignType>;
  support_rep?: InputMaybe<Scalars['Boolean']['input']>;
  taking_demos?: InputMaybe<Scalars['Boolean']['input']>;
  team?: InputMaybe<TeamCreateNestedOneWithoutMembersInput>;
  Template?: InputMaybe<TemplateCreateNestedManyWithoutLast_Modified_ByInput>;
  TemplateVersion?: InputMaybe<TemplateVersionCreateNestedManyWithoutCreated_ByInput>;
  timekit_id?: InputMaybe<Scalars['String']['input']>;
  timekit_type?: InputMaybe<Timekittype>;
  timeOffRequest?: InputMaybe<TimeOffRequestCreateNestedManyWithoutRequesting_UserInput>;
  timezone_raw?: InputMaybe<Scalars['String']['input']>;
  transfer_type?: InputMaybe<TransferType>;
  transfer_user_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutTransfer_UserInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user_custom_availability?: InputMaybe<AvailabilityCreateNestedManyWithoutUserInput>;
  user_past_tokens?: InputMaybe<UserPastTokensCreateNestedManyWithoutUserInput>;
  visible_all_leads_sm?: InputMaybe<Scalars['Boolean']['input']>;
  visible_call_report_columns?: InputMaybe<UserCreatevisible_Call_Report_ColumnsInput>;
  visible_call_report_event_columns?: InputMaybe<UserCreatevisible_Call_Report_Event_ColumnsInput>;
  visible_call_report_pipeline_columns?: InputMaybe<UserCreatevisible_Call_Report_Pipeline_ColumnsInput>;
  visible_my_leads_columns?: InputMaybe<UserCreatevisible_My_Leads_ColumnsInput>;
  voicemail_url?: InputMaybe<Scalars['String']['input']>;
};

export type UserCreateWithoutNote_ItemInput = {
  ai_user?: InputMaybe<Scalars['Boolean']['input']>;
  alternate_email?: InputMaybe<Scalars['String']['input']>;
  always_show_calendar_options?: InputMaybe<Scalars['Boolean']['input']>;
  associate_owner_rep_history?: InputMaybe<AssociationHistoryCreateNestedManyWithoutRepInput>;
  associate_user_history?: InputMaybe<AssociationHistoryCreateNestedManyWithoutUserInput>;
  auto_transfer_selection_receive_attempts?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutFrom_UserInput>;
  auto_transfer_selection_send_attempts?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutTo_UserInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptCreateNestedManyWithoutUserInput>;
  callSharingAsCoach?: InputMaybe<CallSharingCreateNestedManyWithoutCoachInput>;
  callSharingAsUser?: InputMaybe<CallSharingCreateNestedManyWithoutReceiver_UserInput>;
  channel?: InputMaybe<Channelfilter>;
  close_rate?: InputMaybe<Scalars['Float']['input']>;
  close_user_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutClose_UserInput>;
  close_user_stat?: InputMaybe<StatLeadAggregationCreateNestedManyWithoutClose_UserInput>;
  commissions?: InputMaybe<CommissionCreateNestedManyWithoutUserInput>;
  contact_requests?: InputMaybe<ContactRequestCreateNestedManyWithoutRequesting_UserInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  current_sequence_view?: InputMaybe<SequenceDashboardViewCreateNestedOneWithoutUserInput>;
  current_team_start_date?: InputMaybe<Scalars['DateTime']['input']>;
  custom_field_white_list_array?: InputMaybe<UserCustomFieldFilterCreateNestedManyWithoutUserInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemCreateNestedManyWithoutUserInput>;
  daily_lead_claim?: InputMaybe<Scalars['Int']['input']>;
  dashboard_2_access?: InputMaybe<Scalars['Boolean']['input']>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewCreateNestedManyWithoutUserInput>;
  default_audio_input?: InputMaybe<Scalars['String']['input']>;
  default_audio_output?: InputMaybe<Scalars['String']['input']>;
  default_availability?: InputMaybe<Scalars['Boolean']['input']>;
  deleted_at?: InputMaybe<Scalars['DateTime']['input']>;
  deprecate_old_session?: InputMaybe<Scalars['Boolean']['input']>;
  email: Scalars['String']['input'];
  EmailSyncRecord?: InputMaybe<EmailSyncRecordCreateNestedManyWithoutUserInput>;
  favorite_leads?: InputMaybe<LeadCreateNestedManyWithoutFavorited_By_UsersInput>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  folder_permissions?: InputMaybe<FolderPermissionCreateNestedManyWithoutUserInput>;
  folders?: InputMaybe<FolderCreateNestedManyWithoutOwnerInput>;
  from_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutOld_UserInput>;
  GlobalRouting?: InputMaybe<GlobalRepRoutingCreateNestedManyWithoutRepInput>;
  google_refresh_token?: InputMaybe<Scalars['String']['input']>;
  holds_per_prospect_vs_held_demo_per_dial?: InputMaybe<Scalars['Float']['input']>;
  hook_subscriptions?: InputMaybe<WebHookSubscriptionCreateNestedManyWithoutUserInput>;
  hubspot_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  idle_owned_leads?: InputMaybe<LeadCreateNestedManyWithoutSecondary_RepsInput>;
  industry_filter?: InputMaybe<Scalars['Boolean']['input']>;
  industry_white_list_array?: InputMaybe<UserCreateindustry_White_List_ArrayInput>;
  industry_white_list_assign_type?: InputMaybe<AssignType>;
  joinMe_access_token?: InputMaybe<Scalars['String']['input']>;
  joinMe_refresh_token?: InputMaybe<Scalars['String']['input']>;
  last_demo_transferred_at?: InputMaybe<Scalars['DateTime']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutUserInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryCreateNestedManyWithoutUserInput>;
  lead_intents?: InputMaybe<LeadIntentCreateNestedManyWithoutUserInput>;
  lead_source_filter?: InputMaybe<Scalars['Boolean']['input']>;
  lead_source_white_list_array?: InputMaybe<UserCreatelead_Source_White_List_ArrayInput>;
  lead_source_white_list_assign_type?: InputMaybe<AssignType>;
  leads_last_called?: InputMaybe<LeadCreateNestedManyWithoutLast_Call_Result_RepInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusCreateNestedManyWithoutUserInput>;
  manager_id?: InputMaybe<Scalars['String']['input']>;
  notifications?: InputMaybe<NotificationCreateNestedManyWithoutUserInput>;
  organization: OrganizationCreateNestedOneWithoutUsersInput;
  owned_leads?: InputMaybe<LeadCreateNestedManyWithoutRepInput>;
  panda_doc_access?: InputMaybe<Scalars['Boolean']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  payment_settings?: InputMaybe<OrganizationPaymentSettingsCreateNestedManyWithoutCreated_ByInput>;
  payment_settings_updated?: InputMaybe<OrganizationPaymentSettingsCreateNestedManyWithoutLast_Updated_ByInput>;
  phone_number?: InputMaybe<Scalars['String']['input']>;
  post?: InputMaybe<PostCreateNestedManyWithoutAuthorInput>;
  ProductsCreated?: InputMaybe<ProductChangeLogCreateNestedManyWithoutUpdated_ByInput>;
  profile_image?: InputMaybe<Scalars['String']['input']>;
  receive_transfer_attempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutTo_UserInput>;
  receiver_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutReceiver_UserInput>;
  regions_white_list_array?: InputMaybe<UserCreateregions_White_List_ArrayInput>;
  regions_white_list_assign_type?: InputMaybe<AssignType>;
  rep_scores?: InputMaybe<Scalars['Float']['input']>;
  role?: InputMaybe<Role>;
  routings?: InputMaybe<RoutingCreateNestedManyWithoutRepInput>;
  sale_config?: InputMaybe<SaleConfigCreateNestedManyWithoutCreated_ByInput>;
  sale_config_updated?: InputMaybe<SaleConfigCreateNestedManyWithoutLast_Updated_ByInput>;
  sales_team_column_options?: InputMaybe<UserCreatesales_Team_Column_OptionsInput>;
  schedule_items?: InputMaybe<ScheduleItemCreateNestedManyWithoutUserInput>;
  send_transfer_attempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutFrom_UserInput>;
  sender_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutSender_UserInput>;
  sequence_entry_criteria_id?: InputMaybe<Scalars['String']['input']>;
  SequenceActionLog?: InputMaybe<StepActionLogCreateNestedManyWithoutRepInput>;
  sequenceDashboardView?: InputMaybe<SequenceDashboardViewCreateNestedManyWithoutOwned_ByInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutUserInput>;
  Sequences_created?: InputMaybe<SequenceCreateNestedManyWithoutCreated_ByInput>;
  Sequences_modified_by_user?: InputMaybe<SequenceCreateNestedManyWithoutLast_Updated_ByInput>;
  session_token?: InputMaybe<Scalars['String']['input']>;
  set_user_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutSet_UserInput>;
  set_user_stat?: InputMaybe<StatLeadAggregationCreateNestedManyWithoutSet_UserInput>;
  sf_user_id?: InputMaybe<Scalars['String']['input']>;
  SmsItems?: InputMaybe<SmsItemCreateNestedManyWithoutRepInput>;
  SnoozeTracking?: InputMaybe<SnoozeTrackingCreateNestedManyWithoutRepInput>;
  status?: InputMaybe<Status>;
  statusHistory?: InputMaybe<UserStatusHistoryCreateNestedManyWithoutUserInput>;
  sub_industry_white_list_array?: InputMaybe<UserCreatesub_Industry_White_List_ArrayInput>;
  sub_industry_white_list_assign_type?: InputMaybe<AssignType>;
  support_rep?: InputMaybe<Scalars['Boolean']['input']>;
  taking_demos?: InputMaybe<Scalars['Boolean']['input']>;
  team?: InputMaybe<TeamCreateNestedOneWithoutMembersInput>;
  Template?: InputMaybe<TemplateCreateNestedManyWithoutLast_Modified_ByInput>;
  TemplateVersion?: InputMaybe<TemplateVersionCreateNestedManyWithoutCreated_ByInput>;
  timekit_id?: InputMaybe<Scalars['String']['input']>;
  timekit_type?: InputMaybe<Timekittype>;
  timeOffRequest?: InputMaybe<TimeOffRequestCreateNestedManyWithoutRequesting_UserInput>;
  timezone_raw?: InputMaybe<Scalars['String']['input']>;
  transfer_type?: InputMaybe<TransferType>;
  transfer_user_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutTransfer_UserInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user_custom_availability?: InputMaybe<AvailabilityCreateNestedManyWithoutUserInput>;
  user_past_tokens?: InputMaybe<UserPastTokensCreateNestedManyWithoutUserInput>;
  visible_all_leads_sm?: InputMaybe<Scalars['Boolean']['input']>;
  visible_call_report_columns?: InputMaybe<UserCreatevisible_Call_Report_ColumnsInput>;
  visible_call_report_event_columns?: InputMaybe<UserCreatevisible_Call_Report_Event_ColumnsInput>;
  visible_call_report_pipeline_columns?: InputMaybe<UserCreatevisible_Call_Report_Pipeline_ColumnsInput>;
  visible_my_leads_columns?: InputMaybe<UserCreatevisible_My_Leads_ColumnsInput>;
  voicemail_url?: InputMaybe<Scalars['String']['input']>;
};

export type UserCreateWithoutNotificationsInput = {
  ai_user?: InputMaybe<Scalars['Boolean']['input']>;
  alternate_email?: InputMaybe<Scalars['String']['input']>;
  always_show_calendar_options?: InputMaybe<Scalars['Boolean']['input']>;
  associate_owner_rep_history?: InputMaybe<AssociationHistoryCreateNestedManyWithoutRepInput>;
  associate_user_history?: InputMaybe<AssociationHistoryCreateNestedManyWithoutUserInput>;
  auto_transfer_selection_receive_attempts?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutFrom_UserInput>;
  auto_transfer_selection_send_attempts?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutTo_UserInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptCreateNestedManyWithoutUserInput>;
  callSharingAsCoach?: InputMaybe<CallSharingCreateNestedManyWithoutCoachInput>;
  callSharingAsUser?: InputMaybe<CallSharingCreateNestedManyWithoutReceiver_UserInput>;
  channel?: InputMaybe<Channelfilter>;
  close_rate?: InputMaybe<Scalars['Float']['input']>;
  close_user_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutClose_UserInput>;
  close_user_stat?: InputMaybe<StatLeadAggregationCreateNestedManyWithoutClose_UserInput>;
  commissions?: InputMaybe<CommissionCreateNestedManyWithoutUserInput>;
  contact_requests?: InputMaybe<ContactRequestCreateNestedManyWithoutRequesting_UserInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  current_sequence_view?: InputMaybe<SequenceDashboardViewCreateNestedOneWithoutUserInput>;
  current_team_start_date?: InputMaybe<Scalars['DateTime']['input']>;
  custom_field_white_list_array?: InputMaybe<UserCustomFieldFilterCreateNestedManyWithoutUserInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemCreateNestedManyWithoutUserInput>;
  daily_lead_claim?: InputMaybe<Scalars['Int']['input']>;
  dashboard_2_access?: InputMaybe<Scalars['Boolean']['input']>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewCreateNestedManyWithoutUserInput>;
  default_audio_input?: InputMaybe<Scalars['String']['input']>;
  default_audio_output?: InputMaybe<Scalars['String']['input']>;
  default_availability?: InputMaybe<Scalars['Boolean']['input']>;
  deleted_at?: InputMaybe<Scalars['DateTime']['input']>;
  deprecate_old_session?: InputMaybe<Scalars['Boolean']['input']>;
  email: Scalars['String']['input'];
  EmailSyncRecord?: InputMaybe<EmailSyncRecordCreateNestedManyWithoutUserInput>;
  favorite_leads?: InputMaybe<LeadCreateNestedManyWithoutFavorited_By_UsersInput>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  folder_permissions?: InputMaybe<FolderPermissionCreateNestedManyWithoutUserInput>;
  folders?: InputMaybe<FolderCreateNestedManyWithoutOwnerInput>;
  from_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutOld_UserInput>;
  GlobalRouting?: InputMaybe<GlobalRepRoutingCreateNestedManyWithoutRepInput>;
  google_refresh_token?: InputMaybe<Scalars['String']['input']>;
  holds_per_prospect_vs_held_demo_per_dial?: InputMaybe<Scalars['Float']['input']>;
  hook_subscriptions?: InputMaybe<WebHookSubscriptionCreateNestedManyWithoutUserInput>;
  hubspot_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  idle_owned_leads?: InputMaybe<LeadCreateNestedManyWithoutSecondary_RepsInput>;
  industry_filter?: InputMaybe<Scalars['Boolean']['input']>;
  industry_white_list_array?: InputMaybe<UserCreateindustry_White_List_ArrayInput>;
  industry_white_list_assign_type?: InputMaybe<AssignType>;
  joinMe_access_token?: InputMaybe<Scalars['String']['input']>;
  joinMe_refresh_token?: InputMaybe<Scalars['String']['input']>;
  last_demo_transferred_at?: InputMaybe<Scalars['DateTime']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutUserInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryCreateNestedManyWithoutUserInput>;
  lead_intents?: InputMaybe<LeadIntentCreateNestedManyWithoutUserInput>;
  lead_source_filter?: InputMaybe<Scalars['Boolean']['input']>;
  lead_source_white_list_array?: InputMaybe<UserCreatelead_Source_White_List_ArrayInput>;
  lead_source_white_list_assign_type?: InputMaybe<AssignType>;
  leads_last_called?: InputMaybe<LeadCreateNestedManyWithoutLast_Call_Result_RepInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusCreateNestedManyWithoutUserInput>;
  manager_id?: InputMaybe<Scalars['String']['input']>;
  note_item?: InputMaybe<NoteItemCreateNestedManyWithoutUserInput>;
  organization: OrganizationCreateNestedOneWithoutUsersInput;
  owned_leads?: InputMaybe<LeadCreateNestedManyWithoutRepInput>;
  panda_doc_access?: InputMaybe<Scalars['Boolean']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  payment_settings?: InputMaybe<OrganizationPaymentSettingsCreateNestedManyWithoutCreated_ByInput>;
  payment_settings_updated?: InputMaybe<OrganizationPaymentSettingsCreateNestedManyWithoutLast_Updated_ByInput>;
  phone_number?: InputMaybe<Scalars['String']['input']>;
  post?: InputMaybe<PostCreateNestedManyWithoutAuthorInput>;
  ProductsCreated?: InputMaybe<ProductChangeLogCreateNestedManyWithoutUpdated_ByInput>;
  profile_image?: InputMaybe<Scalars['String']['input']>;
  receive_transfer_attempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutTo_UserInput>;
  receiver_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutReceiver_UserInput>;
  regions_white_list_array?: InputMaybe<UserCreateregions_White_List_ArrayInput>;
  regions_white_list_assign_type?: InputMaybe<AssignType>;
  rep_scores?: InputMaybe<Scalars['Float']['input']>;
  role?: InputMaybe<Role>;
  routings?: InputMaybe<RoutingCreateNestedManyWithoutRepInput>;
  sale_config?: InputMaybe<SaleConfigCreateNestedManyWithoutCreated_ByInput>;
  sale_config_updated?: InputMaybe<SaleConfigCreateNestedManyWithoutLast_Updated_ByInput>;
  sales_team_column_options?: InputMaybe<UserCreatesales_Team_Column_OptionsInput>;
  schedule_items?: InputMaybe<ScheduleItemCreateNestedManyWithoutUserInput>;
  send_transfer_attempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutFrom_UserInput>;
  sender_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutSender_UserInput>;
  sequence_entry_criteria_id?: InputMaybe<Scalars['String']['input']>;
  SequenceActionLog?: InputMaybe<StepActionLogCreateNestedManyWithoutRepInput>;
  sequenceDashboardView?: InputMaybe<SequenceDashboardViewCreateNestedManyWithoutOwned_ByInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutUserInput>;
  Sequences_created?: InputMaybe<SequenceCreateNestedManyWithoutCreated_ByInput>;
  Sequences_modified_by_user?: InputMaybe<SequenceCreateNestedManyWithoutLast_Updated_ByInput>;
  session_token?: InputMaybe<Scalars['String']['input']>;
  set_user_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutSet_UserInput>;
  set_user_stat?: InputMaybe<StatLeadAggregationCreateNestedManyWithoutSet_UserInput>;
  sf_user_id?: InputMaybe<Scalars['String']['input']>;
  SmsItems?: InputMaybe<SmsItemCreateNestedManyWithoutRepInput>;
  SnoozeTracking?: InputMaybe<SnoozeTrackingCreateNestedManyWithoutRepInput>;
  status?: InputMaybe<Status>;
  statusHistory?: InputMaybe<UserStatusHistoryCreateNestedManyWithoutUserInput>;
  sub_industry_white_list_array?: InputMaybe<UserCreatesub_Industry_White_List_ArrayInput>;
  sub_industry_white_list_assign_type?: InputMaybe<AssignType>;
  support_rep?: InputMaybe<Scalars['Boolean']['input']>;
  taking_demos?: InputMaybe<Scalars['Boolean']['input']>;
  team?: InputMaybe<TeamCreateNestedOneWithoutMembersInput>;
  Template?: InputMaybe<TemplateCreateNestedManyWithoutLast_Modified_ByInput>;
  TemplateVersion?: InputMaybe<TemplateVersionCreateNestedManyWithoutCreated_ByInput>;
  timekit_id?: InputMaybe<Scalars['String']['input']>;
  timekit_type?: InputMaybe<Timekittype>;
  timeOffRequest?: InputMaybe<TimeOffRequestCreateNestedManyWithoutRequesting_UserInput>;
  timezone_raw?: InputMaybe<Scalars['String']['input']>;
  transfer_type?: InputMaybe<TransferType>;
  transfer_user_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutTransfer_UserInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user_custom_availability?: InputMaybe<AvailabilityCreateNestedManyWithoutUserInput>;
  user_past_tokens?: InputMaybe<UserPastTokensCreateNestedManyWithoutUserInput>;
  visible_all_leads_sm?: InputMaybe<Scalars['Boolean']['input']>;
  visible_call_report_columns?: InputMaybe<UserCreatevisible_Call_Report_ColumnsInput>;
  visible_call_report_event_columns?: InputMaybe<UserCreatevisible_Call_Report_Event_ColumnsInput>;
  visible_call_report_pipeline_columns?: InputMaybe<UserCreatevisible_Call_Report_Pipeline_ColumnsInput>;
  visible_my_leads_columns?: InputMaybe<UserCreatevisible_My_Leads_ColumnsInput>;
  voicemail_url?: InputMaybe<Scalars['String']['input']>;
};

export type UserCreateWithoutOrganizationInput = {
  ai_user?: InputMaybe<Scalars['Boolean']['input']>;
  alternate_email?: InputMaybe<Scalars['String']['input']>;
  always_show_calendar_options?: InputMaybe<Scalars['Boolean']['input']>;
  associate_owner_rep_history?: InputMaybe<AssociationHistoryCreateNestedManyWithoutRepInput>;
  associate_user_history?: InputMaybe<AssociationHistoryCreateNestedManyWithoutUserInput>;
  auto_transfer_selection_receive_attempts?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutFrom_UserInput>;
  auto_transfer_selection_send_attempts?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutTo_UserInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptCreateNestedManyWithoutUserInput>;
  callSharingAsCoach?: InputMaybe<CallSharingCreateNestedManyWithoutCoachInput>;
  callSharingAsUser?: InputMaybe<CallSharingCreateNestedManyWithoutReceiver_UserInput>;
  channel?: InputMaybe<Channelfilter>;
  close_rate?: InputMaybe<Scalars['Float']['input']>;
  close_user_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutClose_UserInput>;
  close_user_stat?: InputMaybe<StatLeadAggregationCreateNestedManyWithoutClose_UserInput>;
  commissions?: InputMaybe<CommissionCreateNestedManyWithoutUserInput>;
  contact_requests?: InputMaybe<ContactRequestCreateNestedManyWithoutRequesting_UserInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  current_sequence_view?: InputMaybe<SequenceDashboardViewCreateNestedOneWithoutUserInput>;
  current_team_start_date?: InputMaybe<Scalars['DateTime']['input']>;
  custom_field_white_list_array?: InputMaybe<UserCustomFieldFilterCreateNestedManyWithoutUserInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemCreateNestedManyWithoutUserInput>;
  daily_lead_claim?: InputMaybe<Scalars['Int']['input']>;
  dashboard_2_access?: InputMaybe<Scalars['Boolean']['input']>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewCreateNestedManyWithoutUserInput>;
  default_audio_input?: InputMaybe<Scalars['String']['input']>;
  default_audio_output?: InputMaybe<Scalars['String']['input']>;
  default_availability?: InputMaybe<Scalars['Boolean']['input']>;
  deleted_at?: InputMaybe<Scalars['DateTime']['input']>;
  deprecate_old_session?: InputMaybe<Scalars['Boolean']['input']>;
  email: Scalars['String']['input'];
  EmailSyncRecord?: InputMaybe<EmailSyncRecordCreateNestedManyWithoutUserInput>;
  favorite_leads?: InputMaybe<LeadCreateNestedManyWithoutFavorited_By_UsersInput>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  folder_permissions?: InputMaybe<FolderPermissionCreateNestedManyWithoutUserInput>;
  folders?: InputMaybe<FolderCreateNestedManyWithoutOwnerInput>;
  from_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutOld_UserInput>;
  GlobalRouting?: InputMaybe<GlobalRepRoutingCreateNestedManyWithoutRepInput>;
  google_refresh_token?: InputMaybe<Scalars['String']['input']>;
  holds_per_prospect_vs_held_demo_per_dial?: InputMaybe<Scalars['Float']['input']>;
  hook_subscriptions?: InputMaybe<WebHookSubscriptionCreateNestedManyWithoutUserInput>;
  hubspot_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  idle_owned_leads?: InputMaybe<LeadCreateNestedManyWithoutSecondary_RepsInput>;
  industry_filter?: InputMaybe<Scalars['Boolean']['input']>;
  industry_white_list_array?: InputMaybe<UserCreateindustry_White_List_ArrayInput>;
  industry_white_list_assign_type?: InputMaybe<AssignType>;
  joinMe_access_token?: InputMaybe<Scalars['String']['input']>;
  joinMe_refresh_token?: InputMaybe<Scalars['String']['input']>;
  last_demo_transferred_at?: InputMaybe<Scalars['DateTime']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutUserInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryCreateNestedManyWithoutUserInput>;
  lead_intents?: InputMaybe<LeadIntentCreateNestedManyWithoutUserInput>;
  lead_source_filter?: InputMaybe<Scalars['Boolean']['input']>;
  lead_source_white_list_array?: InputMaybe<UserCreatelead_Source_White_List_ArrayInput>;
  lead_source_white_list_assign_type?: InputMaybe<AssignType>;
  leads_last_called?: InputMaybe<LeadCreateNestedManyWithoutLast_Call_Result_RepInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusCreateNestedManyWithoutUserInput>;
  manager_id?: InputMaybe<Scalars['String']['input']>;
  note_item?: InputMaybe<NoteItemCreateNestedManyWithoutUserInput>;
  notifications?: InputMaybe<NotificationCreateNestedManyWithoutUserInput>;
  owned_leads?: InputMaybe<LeadCreateNestedManyWithoutRepInput>;
  panda_doc_access?: InputMaybe<Scalars['Boolean']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  payment_settings?: InputMaybe<OrganizationPaymentSettingsCreateNestedManyWithoutCreated_ByInput>;
  payment_settings_updated?: InputMaybe<OrganizationPaymentSettingsCreateNestedManyWithoutLast_Updated_ByInput>;
  phone_number?: InputMaybe<Scalars['String']['input']>;
  post?: InputMaybe<PostCreateNestedManyWithoutAuthorInput>;
  ProductsCreated?: InputMaybe<ProductChangeLogCreateNestedManyWithoutUpdated_ByInput>;
  profile_image?: InputMaybe<Scalars['String']['input']>;
  receive_transfer_attempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutTo_UserInput>;
  receiver_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutReceiver_UserInput>;
  regions_white_list_array?: InputMaybe<UserCreateregions_White_List_ArrayInput>;
  regions_white_list_assign_type?: InputMaybe<AssignType>;
  rep_scores?: InputMaybe<Scalars['Float']['input']>;
  role?: InputMaybe<Role>;
  routings?: InputMaybe<RoutingCreateNestedManyWithoutRepInput>;
  sale_config?: InputMaybe<SaleConfigCreateNestedManyWithoutCreated_ByInput>;
  sale_config_updated?: InputMaybe<SaleConfigCreateNestedManyWithoutLast_Updated_ByInput>;
  sales_team_column_options?: InputMaybe<UserCreatesales_Team_Column_OptionsInput>;
  schedule_items?: InputMaybe<ScheduleItemCreateNestedManyWithoutUserInput>;
  send_transfer_attempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutFrom_UserInput>;
  sender_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutSender_UserInput>;
  sequence_entry_criteria_id?: InputMaybe<Scalars['String']['input']>;
  SequenceActionLog?: InputMaybe<StepActionLogCreateNestedManyWithoutRepInput>;
  sequenceDashboardView?: InputMaybe<SequenceDashboardViewCreateNestedManyWithoutOwned_ByInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutUserInput>;
  Sequences_created?: InputMaybe<SequenceCreateNestedManyWithoutCreated_ByInput>;
  Sequences_modified_by_user?: InputMaybe<SequenceCreateNestedManyWithoutLast_Updated_ByInput>;
  session_token?: InputMaybe<Scalars['String']['input']>;
  set_user_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutSet_UserInput>;
  set_user_stat?: InputMaybe<StatLeadAggregationCreateNestedManyWithoutSet_UserInput>;
  sf_user_id?: InputMaybe<Scalars['String']['input']>;
  SmsItems?: InputMaybe<SmsItemCreateNestedManyWithoutRepInput>;
  SnoozeTracking?: InputMaybe<SnoozeTrackingCreateNestedManyWithoutRepInput>;
  status?: InputMaybe<Status>;
  statusHistory?: InputMaybe<UserStatusHistoryCreateNestedManyWithoutUserInput>;
  sub_industry_white_list_array?: InputMaybe<UserCreatesub_Industry_White_List_ArrayInput>;
  sub_industry_white_list_assign_type?: InputMaybe<AssignType>;
  support_rep?: InputMaybe<Scalars['Boolean']['input']>;
  taking_demos?: InputMaybe<Scalars['Boolean']['input']>;
  team?: InputMaybe<TeamCreateNestedOneWithoutMembersInput>;
  Template?: InputMaybe<TemplateCreateNestedManyWithoutLast_Modified_ByInput>;
  TemplateVersion?: InputMaybe<TemplateVersionCreateNestedManyWithoutCreated_ByInput>;
  timekit_id?: InputMaybe<Scalars['String']['input']>;
  timekit_type?: InputMaybe<Timekittype>;
  timeOffRequest?: InputMaybe<TimeOffRequestCreateNestedManyWithoutRequesting_UserInput>;
  timezone_raw?: InputMaybe<Scalars['String']['input']>;
  transfer_type?: InputMaybe<TransferType>;
  transfer_user_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutTransfer_UserInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user_custom_availability?: InputMaybe<AvailabilityCreateNestedManyWithoutUserInput>;
  user_past_tokens?: InputMaybe<UserPastTokensCreateNestedManyWithoutUserInput>;
  visible_all_leads_sm?: InputMaybe<Scalars['Boolean']['input']>;
  visible_call_report_columns?: InputMaybe<UserCreatevisible_Call_Report_ColumnsInput>;
  visible_call_report_event_columns?: InputMaybe<UserCreatevisible_Call_Report_Event_ColumnsInput>;
  visible_call_report_pipeline_columns?: InputMaybe<UserCreatevisible_Call_Report_Pipeline_ColumnsInput>;
  visible_my_leads_columns?: InputMaybe<UserCreatevisible_My_Leads_ColumnsInput>;
  voicemail_url?: InputMaybe<Scalars['String']['input']>;
};

export type UserCreateWithoutOwned_LeadsInput = {
  ai_user?: InputMaybe<Scalars['Boolean']['input']>;
  alternate_email?: InputMaybe<Scalars['String']['input']>;
  always_show_calendar_options?: InputMaybe<Scalars['Boolean']['input']>;
  associate_owner_rep_history?: InputMaybe<AssociationHistoryCreateNestedManyWithoutRepInput>;
  associate_user_history?: InputMaybe<AssociationHistoryCreateNestedManyWithoutUserInput>;
  auto_transfer_selection_receive_attempts?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutFrom_UserInput>;
  auto_transfer_selection_send_attempts?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutTo_UserInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptCreateNestedManyWithoutUserInput>;
  callSharingAsCoach?: InputMaybe<CallSharingCreateNestedManyWithoutCoachInput>;
  callSharingAsUser?: InputMaybe<CallSharingCreateNestedManyWithoutReceiver_UserInput>;
  channel?: InputMaybe<Channelfilter>;
  close_rate?: InputMaybe<Scalars['Float']['input']>;
  close_user_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutClose_UserInput>;
  close_user_stat?: InputMaybe<StatLeadAggregationCreateNestedManyWithoutClose_UserInput>;
  commissions?: InputMaybe<CommissionCreateNestedManyWithoutUserInput>;
  contact_requests?: InputMaybe<ContactRequestCreateNestedManyWithoutRequesting_UserInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  current_sequence_view?: InputMaybe<SequenceDashboardViewCreateNestedOneWithoutUserInput>;
  current_team_start_date?: InputMaybe<Scalars['DateTime']['input']>;
  custom_field_white_list_array?: InputMaybe<UserCustomFieldFilterCreateNestedManyWithoutUserInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemCreateNestedManyWithoutUserInput>;
  daily_lead_claim?: InputMaybe<Scalars['Int']['input']>;
  dashboard_2_access?: InputMaybe<Scalars['Boolean']['input']>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewCreateNestedManyWithoutUserInput>;
  default_audio_input?: InputMaybe<Scalars['String']['input']>;
  default_audio_output?: InputMaybe<Scalars['String']['input']>;
  default_availability?: InputMaybe<Scalars['Boolean']['input']>;
  deleted_at?: InputMaybe<Scalars['DateTime']['input']>;
  deprecate_old_session?: InputMaybe<Scalars['Boolean']['input']>;
  email: Scalars['String']['input'];
  EmailSyncRecord?: InputMaybe<EmailSyncRecordCreateNestedManyWithoutUserInput>;
  favorite_leads?: InputMaybe<LeadCreateNestedManyWithoutFavorited_By_UsersInput>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  folder_permissions?: InputMaybe<FolderPermissionCreateNestedManyWithoutUserInput>;
  folders?: InputMaybe<FolderCreateNestedManyWithoutOwnerInput>;
  from_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutOld_UserInput>;
  GlobalRouting?: InputMaybe<GlobalRepRoutingCreateNestedManyWithoutRepInput>;
  google_refresh_token?: InputMaybe<Scalars['String']['input']>;
  holds_per_prospect_vs_held_demo_per_dial?: InputMaybe<Scalars['Float']['input']>;
  hook_subscriptions?: InputMaybe<WebHookSubscriptionCreateNestedManyWithoutUserInput>;
  hubspot_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  idle_owned_leads?: InputMaybe<LeadCreateNestedManyWithoutSecondary_RepsInput>;
  industry_filter?: InputMaybe<Scalars['Boolean']['input']>;
  industry_white_list_array?: InputMaybe<UserCreateindustry_White_List_ArrayInput>;
  industry_white_list_assign_type?: InputMaybe<AssignType>;
  joinMe_access_token?: InputMaybe<Scalars['String']['input']>;
  joinMe_refresh_token?: InputMaybe<Scalars['String']['input']>;
  last_demo_transferred_at?: InputMaybe<Scalars['DateTime']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutUserInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryCreateNestedManyWithoutUserInput>;
  lead_intents?: InputMaybe<LeadIntentCreateNestedManyWithoutUserInput>;
  lead_source_filter?: InputMaybe<Scalars['Boolean']['input']>;
  lead_source_white_list_array?: InputMaybe<UserCreatelead_Source_White_List_ArrayInput>;
  lead_source_white_list_assign_type?: InputMaybe<AssignType>;
  leads_last_called?: InputMaybe<LeadCreateNestedManyWithoutLast_Call_Result_RepInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusCreateNestedManyWithoutUserInput>;
  manager_id?: InputMaybe<Scalars['String']['input']>;
  note_item?: InputMaybe<NoteItemCreateNestedManyWithoutUserInput>;
  notifications?: InputMaybe<NotificationCreateNestedManyWithoutUserInput>;
  organization: OrganizationCreateNestedOneWithoutUsersInput;
  panda_doc_access?: InputMaybe<Scalars['Boolean']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  payment_settings?: InputMaybe<OrganizationPaymentSettingsCreateNestedManyWithoutCreated_ByInput>;
  payment_settings_updated?: InputMaybe<OrganizationPaymentSettingsCreateNestedManyWithoutLast_Updated_ByInput>;
  phone_number?: InputMaybe<Scalars['String']['input']>;
  post?: InputMaybe<PostCreateNestedManyWithoutAuthorInput>;
  ProductsCreated?: InputMaybe<ProductChangeLogCreateNestedManyWithoutUpdated_ByInput>;
  profile_image?: InputMaybe<Scalars['String']['input']>;
  receive_transfer_attempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutTo_UserInput>;
  receiver_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutReceiver_UserInput>;
  regions_white_list_array?: InputMaybe<UserCreateregions_White_List_ArrayInput>;
  regions_white_list_assign_type?: InputMaybe<AssignType>;
  rep_scores?: InputMaybe<Scalars['Float']['input']>;
  role?: InputMaybe<Role>;
  routings?: InputMaybe<RoutingCreateNestedManyWithoutRepInput>;
  sale_config?: InputMaybe<SaleConfigCreateNestedManyWithoutCreated_ByInput>;
  sale_config_updated?: InputMaybe<SaleConfigCreateNestedManyWithoutLast_Updated_ByInput>;
  sales_team_column_options?: InputMaybe<UserCreatesales_Team_Column_OptionsInput>;
  schedule_items?: InputMaybe<ScheduleItemCreateNestedManyWithoutUserInput>;
  send_transfer_attempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutFrom_UserInput>;
  sender_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutSender_UserInput>;
  sequence_entry_criteria_id?: InputMaybe<Scalars['String']['input']>;
  SequenceActionLog?: InputMaybe<StepActionLogCreateNestedManyWithoutRepInput>;
  sequenceDashboardView?: InputMaybe<SequenceDashboardViewCreateNestedManyWithoutOwned_ByInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutUserInput>;
  Sequences_created?: InputMaybe<SequenceCreateNestedManyWithoutCreated_ByInput>;
  Sequences_modified_by_user?: InputMaybe<SequenceCreateNestedManyWithoutLast_Updated_ByInput>;
  session_token?: InputMaybe<Scalars['String']['input']>;
  set_user_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutSet_UserInput>;
  set_user_stat?: InputMaybe<StatLeadAggregationCreateNestedManyWithoutSet_UserInput>;
  sf_user_id?: InputMaybe<Scalars['String']['input']>;
  SmsItems?: InputMaybe<SmsItemCreateNestedManyWithoutRepInput>;
  SnoozeTracking?: InputMaybe<SnoozeTrackingCreateNestedManyWithoutRepInput>;
  status?: InputMaybe<Status>;
  statusHistory?: InputMaybe<UserStatusHistoryCreateNestedManyWithoutUserInput>;
  sub_industry_white_list_array?: InputMaybe<UserCreatesub_Industry_White_List_ArrayInput>;
  sub_industry_white_list_assign_type?: InputMaybe<AssignType>;
  support_rep?: InputMaybe<Scalars['Boolean']['input']>;
  taking_demos?: InputMaybe<Scalars['Boolean']['input']>;
  team?: InputMaybe<TeamCreateNestedOneWithoutMembersInput>;
  Template?: InputMaybe<TemplateCreateNestedManyWithoutLast_Modified_ByInput>;
  TemplateVersion?: InputMaybe<TemplateVersionCreateNestedManyWithoutCreated_ByInput>;
  timekit_id?: InputMaybe<Scalars['String']['input']>;
  timekit_type?: InputMaybe<Timekittype>;
  timeOffRequest?: InputMaybe<TimeOffRequestCreateNestedManyWithoutRequesting_UserInput>;
  timezone_raw?: InputMaybe<Scalars['String']['input']>;
  transfer_type?: InputMaybe<TransferType>;
  transfer_user_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutTransfer_UserInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user_custom_availability?: InputMaybe<AvailabilityCreateNestedManyWithoutUserInput>;
  user_past_tokens?: InputMaybe<UserPastTokensCreateNestedManyWithoutUserInput>;
  visible_all_leads_sm?: InputMaybe<Scalars['Boolean']['input']>;
  visible_call_report_columns?: InputMaybe<UserCreatevisible_Call_Report_ColumnsInput>;
  visible_call_report_event_columns?: InputMaybe<UserCreatevisible_Call_Report_Event_ColumnsInput>;
  visible_call_report_pipeline_columns?: InputMaybe<UserCreatevisible_Call_Report_Pipeline_ColumnsInput>;
  visible_my_leads_columns?: InputMaybe<UserCreatevisible_My_Leads_ColumnsInput>;
  voicemail_url?: InputMaybe<Scalars['String']['input']>;
};

export type UserCreateWithoutPayment_Settings_UpdatedInput = {
  ai_user?: InputMaybe<Scalars['Boolean']['input']>;
  alternate_email?: InputMaybe<Scalars['String']['input']>;
  always_show_calendar_options?: InputMaybe<Scalars['Boolean']['input']>;
  associate_owner_rep_history?: InputMaybe<AssociationHistoryCreateNestedManyWithoutRepInput>;
  associate_user_history?: InputMaybe<AssociationHistoryCreateNestedManyWithoutUserInput>;
  auto_transfer_selection_receive_attempts?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutFrom_UserInput>;
  auto_transfer_selection_send_attempts?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutTo_UserInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptCreateNestedManyWithoutUserInput>;
  callSharingAsCoach?: InputMaybe<CallSharingCreateNestedManyWithoutCoachInput>;
  callSharingAsUser?: InputMaybe<CallSharingCreateNestedManyWithoutReceiver_UserInput>;
  channel?: InputMaybe<Channelfilter>;
  close_rate?: InputMaybe<Scalars['Float']['input']>;
  close_user_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutClose_UserInput>;
  close_user_stat?: InputMaybe<StatLeadAggregationCreateNestedManyWithoutClose_UserInput>;
  commissions?: InputMaybe<CommissionCreateNestedManyWithoutUserInput>;
  contact_requests?: InputMaybe<ContactRequestCreateNestedManyWithoutRequesting_UserInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  current_sequence_view?: InputMaybe<SequenceDashboardViewCreateNestedOneWithoutUserInput>;
  current_team_start_date?: InputMaybe<Scalars['DateTime']['input']>;
  custom_field_white_list_array?: InputMaybe<UserCustomFieldFilterCreateNestedManyWithoutUserInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemCreateNestedManyWithoutUserInput>;
  daily_lead_claim?: InputMaybe<Scalars['Int']['input']>;
  dashboard_2_access?: InputMaybe<Scalars['Boolean']['input']>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewCreateNestedManyWithoutUserInput>;
  default_audio_input?: InputMaybe<Scalars['String']['input']>;
  default_audio_output?: InputMaybe<Scalars['String']['input']>;
  default_availability?: InputMaybe<Scalars['Boolean']['input']>;
  deleted_at?: InputMaybe<Scalars['DateTime']['input']>;
  deprecate_old_session?: InputMaybe<Scalars['Boolean']['input']>;
  email: Scalars['String']['input'];
  EmailSyncRecord?: InputMaybe<EmailSyncRecordCreateNestedManyWithoutUserInput>;
  favorite_leads?: InputMaybe<LeadCreateNestedManyWithoutFavorited_By_UsersInput>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  folder_permissions?: InputMaybe<FolderPermissionCreateNestedManyWithoutUserInput>;
  folders?: InputMaybe<FolderCreateNestedManyWithoutOwnerInput>;
  from_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutOld_UserInput>;
  GlobalRouting?: InputMaybe<GlobalRepRoutingCreateNestedManyWithoutRepInput>;
  google_refresh_token?: InputMaybe<Scalars['String']['input']>;
  holds_per_prospect_vs_held_demo_per_dial?: InputMaybe<Scalars['Float']['input']>;
  hook_subscriptions?: InputMaybe<WebHookSubscriptionCreateNestedManyWithoutUserInput>;
  hubspot_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  idle_owned_leads?: InputMaybe<LeadCreateNestedManyWithoutSecondary_RepsInput>;
  industry_filter?: InputMaybe<Scalars['Boolean']['input']>;
  industry_white_list_array?: InputMaybe<UserCreateindustry_White_List_ArrayInput>;
  industry_white_list_assign_type?: InputMaybe<AssignType>;
  joinMe_access_token?: InputMaybe<Scalars['String']['input']>;
  joinMe_refresh_token?: InputMaybe<Scalars['String']['input']>;
  last_demo_transferred_at?: InputMaybe<Scalars['DateTime']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutUserInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryCreateNestedManyWithoutUserInput>;
  lead_intents?: InputMaybe<LeadIntentCreateNestedManyWithoutUserInput>;
  lead_source_filter?: InputMaybe<Scalars['Boolean']['input']>;
  lead_source_white_list_array?: InputMaybe<UserCreatelead_Source_White_List_ArrayInput>;
  lead_source_white_list_assign_type?: InputMaybe<AssignType>;
  leads_last_called?: InputMaybe<LeadCreateNestedManyWithoutLast_Call_Result_RepInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusCreateNestedManyWithoutUserInput>;
  manager_id?: InputMaybe<Scalars['String']['input']>;
  note_item?: InputMaybe<NoteItemCreateNestedManyWithoutUserInput>;
  notifications?: InputMaybe<NotificationCreateNestedManyWithoutUserInput>;
  organization: OrganizationCreateNestedOneWithoutUsersInput;
  owned_leads?: InputMaybe<LeadCreateNestedManyWithoutRepInput>;
  panda_doc_access?: InputMaybe<Scalars['Boolean']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  payment_settings?: InputMaybe<OrganizationPaymentSettingsCreateNestedManyWithoutCreated_ByInput>;
  phone_number?: InputMaybe<Scalars['String']['input']>;
  post?: InputMaybe<PostCreateNestedManyWithoutAuthorInput>;
  ProductsCreated?: InputMaybe<ProductChangeLogCreateNestedManyWithoutUpdated_ByInput>;
  profile_image?: InputMaybe<Scalars['String']['input']>;
  receive_transfer_attempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutTo_UserInput>;
  receiver_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutReceiver_UserInput>;
  regions_white_list_array?: InputMaybe<UserCreateregions_White_List_ArrayInput>;
  regions_white_list_assign_type?: InputMaybe<AssignType>;
  rep_scores?: InputMaybe<Scalars['Float']['input']>;
  role?: InputMaybe<Role>;
  routings?: InputMaybe<RoutingCreateNestedManyWithoutRepInput>;
  sale_config?: InputMaybe<SaleConfigCreateNestedManyWithoutCreated_ByInput>;
  sale_config_updated?: InputMaybe<SaleConfigCreateNestedManyWithoutLast_Updated_ByInput>;
  sales_team_column_options?: InputMaybe<UserCreatesales_Team_Column_OptionsInput>;
  schedule_items?: InputMaybe<ScheduleItemCreateNestedManyWithoutUserInput>;
  send_transfer_attempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutFrom_UserInput>;
  sender_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutSender_UserInput>;
  sequence_entry_criteria_id?: InputMaybe<Scalars['String']['input']>;
  SequenceActionLog?: InputMaybe<StepActionLogCreateNestedManyWithoutRepInput>;
  sequenceDashboardView?: InputMaybe<SequenceDashboardViewCreateNestedManyWithoutOwned_ByInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutUserInput>;
  Sequences_created?: InputMaybe<SequenceCreateNestedManyWithoutCreated_ByInput>;
  Sequences_modified_by_user?: InputMaybe<SequenceCreateNestedManyWithoutLast_Updated_ByInput>;
  session_token?: InputMaybe<Scalars['String']['input']>;
  set_user_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutSet_UserInput>;
  set_user_stat?: InputMaybe<StatLeadAggregationCreateNestedManyWithoutSet_UserInput>;
  sf_user_id?: InputMaybe<Scalars['String']['input']>;
  SmsItems?: InputMaybe<SmsItemCreateNestedManyWithoutRepInput>;
  SnoozeTracking?: InputMaybe<SnoozeTrackingCreateNestedManyWithoutRepInput>;
  status?: InputMaybe<Status>;
  statusHistory?: InputMaybe<UserStatusHistoryCreateNestedManyWithoutUserInput>;
  sub_industry_white_list_array?: InputMaybe<UserCreatesub_Industry_White_List_ArrayInput>;
  sub_industry_white_list_assign_type?: InputMaybe<AssignType>;
  support_rep?: InputMaybe<Scalars['Boolean']['input']>;
  taking_demos?: InputMaybe<Scalars['Boolean']['input']>;
  team?: InputMaybe<TeamCreateNestedOneWithoutMembersInput>;
  Template?: InputMaybe<TemplateCreateNestedManyWithoutLast_Modified_ByInput>;
  TemplateVersion?: InputMaybe<TemplateVersionCreateNestedManyWithoutCreated_ByInput>;
  timekit_id?: InputMaybe<Scalars['String']['input']>;
  timekit_type?: InputMaybe<Timekittype>;
  timeOffRequest?: InputMaybe<TimeOffRequestCreateNestedManyWithoutRequesting_UserInput>;
  timezone_raw?: InputMaybe<Scalars['String']['input']>;
  transfer_type?: InputMaybe<TransferType>;
  transfer_user_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutTransfer_UserInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user_custom_availability?: InputMaybe<AvailabilityCreateNestedManyWithoutUserInput>;
  user_past_tokens?: InputMaybe<UserPastTokensCreateNestedManyWithoutUserInput>;
  visible_all_leads_sm?: InputMaybe<Scalars['Boolean']['input']>;
  visible_call_report_columns?: InputMaybe<UserCreatevisible_Call_Report_ColumnsInput>;
  visible_call_report_event_columns?: InputMaybe<UserCreatevisible_Call_Report_Event_ColumnsInput>;
  visible_call_report_pipeline_columns?: InputMaybe<UserCreatevisible_Call_Report_Pipeline_ColumnsInput>;
  visible_my_leads_columns?: InputMaybe<UserCreatevisible_My_Leads_ColumnsInput>;
  voicemail_url?: InputMaybe<Scalars['String']['input']>;
};

export type UserCreateWithoutPayment_SettingsInput = {
  ai_user?: InputMaybe<Scalars['Boolean']['input']>;
  alternate_email?: InputMaybe<Scalars['String']['input']>;
  always_show_calendar_options?: InputMaybe<Scalars['Boolean']['input']>;
  associate_owner_rep_history?: InputMaybe<AssociationHistoryCreateNestedManyWithoutRepInput>;
  associate_user_history?: InputMaybe<AssociationHistoryCreateNestedManyWithoutUserInput>;
  auto_transfer_selection_receive_attempts?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutFrom_UserInput>;
  auto_transfer_selection_send_attempts?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutTo_UserInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptCreateNestedManyWithoutUserInput>;
  callSharingAsCoach?: InputMaybe<CallSharingCreateNestedManyWithoutCoachInput>;
  callSharingAsUser?: InputMaybe<CallSharingCreateNestedManyWithoutReceiver_UserInput>;
  channel?: InputMaybe<Channelfilter>;
  close_rate?: InputMaybe<Scalars['Float']['input']>;
  close_user_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutClose_UserInput>;
  close_user_stat?: InputMaybe<StatLeadAggregationCreateNestedManyWithoutClose_UserInput>;
  commissions?: InputMaybe<CommissionCreateNestedManyWithoutUserInput>;
  contact_requests?: InputMaybe<ContactRequestCreateNestedManyWithoutRequesting_UserInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  current_sequence_view?: InputMaybe<SequenceDashboardViewCreateNestedOneWithoutUserInput>;
  current_team_start_date?: InputMaybe<Scalars['DateTime']['input']>;
  custom_field_white_list_array?: InputMaybe<UserCustomFieldFilterCreateNestedManyWithoutUserInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemCreateNestedManyWithoutUserInput>;
  daily_lead_claim?: InputMaybe<Scalars['Int']['input']>;
  dashboard_2_access?: InputMaybe<Scalars['Boolean']['input']>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewCreateNestedManyWithoutUserInput>;
  default_audio_input?: InputMaybe<Scalars['String']['input']>;
  default_audio_output?: InputMaybe<Scalars['String']['input']>;
  default_availability?: InputMaybe<Scalars['Boolean']['input']>;
  deleted_at?: InputMaybe<Scalars['DateTime']['input']>;
  deprecate_old_session?: InputMaybe<Scalars['Boolean']['input']>;
  email: Scalars['String']['input'];
  EmailSyncRecord?: InputMaybe<EmailSyncRecordCreateNestedManyWithoutUserInput>;
  favorite_leads?: InputMaybe<LeadCreateNestedManyWithoutFavorited_By_UsersInput>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  folder_permissions?: InputMaybe<FolderPermissionCreateNestedManyWithoutUserInput>;
  folders?: InputMaybe<FolderCreateNestedManyWithoutOwnerInput>;
  from_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutOld_UserInput>;
  GlobalRouting?: InputMaybe<GlobalRepRoutingCreateNestedManyWithoutRepInput>;
  google_refresh_token?: InputMaybe<Scalars['String']['input']>;
  holds_per_prospect_vs_held_demo_per_dial?: InputMaybe<Scalars['Float']['input']>;
  hook_subscriptions?: InputMaybe<WebHookSubscriptionCreateNestedManyWithoutUserInput>;
  hubspot_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  idle_owned_leads?: InputMaybe<LeadCreateNestedManyWithoutSecondary_RepsInput>;
  industry_filter?: InputMaybe<Scalars['Boolean']['input']>;
  industry_white_list_array?: InputMaybe<UserCreateindustry_White_List_ArrayInput>;
  industry_white_list_assign_type?: InputMaybe<AssignType>;
  joinMe_access_token?: InputMaybe<Scalars['String']['input']>;
  joinMe_refresh_token?: InputMaybe<Scalars['String']['input']>;
  last_demo_transferred_at?: InputMaybe<Scalars['DateTime']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutUserInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryCreateNestedManyWithoutUserInput>;
  lead_intents?: InputMaybe<LeadIntentCreateNestedManyWithoutUserInput>;
  lead_source_filter?: InputMaybe<Scalars['Boolean']['input']>;
  lead_source_white_list_array?: InputMaybe<UserCreatelead_Source_White_List_ArrayInput>;
  lead_source_white_list_assign_type?: InputMaybe<AssignType>;
  leads_last_called?: InputMaybe<LeadCreateNestedManyWithoutLast_Call_Result_RepInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusCreateNestedManyWithoutUserInput>;
  manager_id?: InputMaybe<Scalars['String']['input']>;
  note_item?: InputMaybe<NoteItemCreateNestedManyWithoutUserInput>;
  notifications?: InputMaybe<NotificationCreateNestedManyWithoutUserInput>;
  organization: OrganizationCreateNestedOneWithoutUsersInput;
  owned_leads?: InputMaybe<LeadCreateNestedManyWithoutRepInput>;
  panda_doc_access?: InputMaybe<Scalars['Boolean']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  payment_settings_updated?: InputMaybe<OrganizationPaymentSettingsCreateNestedManyWithoutLast_Updated_ByInput>;
  phone_number?: InputMaybe<Scalars['String']['input']>;
  post?: InputMaybe<PostCreateNestedManyWithoutAuthorInput>;
  ProductsCreated?: InputMaybe<ProductChangeLogCreateNestedManyWithoutUpdated_ByInput>;
  profile_image?: InputMaybe<Scalars['String']['input']>;
  receive_transfer_attempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutTo_UserInput>;
  receiver_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutReceiver_UserInput>;
  regions_white_list_array?: InputMaybe<UserCreateregions_White_List_ArrayInput>;
  regions_white_list_assign_type?: InputMaybe<AssignType>;
  rep_scores?: InputMaybe<Scalars['Float']['input']>;
  role?: InputMaybe<Role>;
  routings?: InputMaybe<RoutingCreateNestedManyWithoutRepInput>;
  sale_config?: InputMaybe<SaleConfigCreateNestedManyWithoutCreated_ByInput>;
  sale_config_updated?: InputMaybe<SaleConfigCreateNestedManyWithoutLast_Updated_ByInput>;
  sales_team_column_options?: InputMaybe<UserCreatesales_Team_Column_OptionsInput>;
  schedule_items?: InputMaybe<ScheduleItemCreateNestedManyWithoutUserInput>;
  send_transfer_attempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutFrom_UserInput>;
  sender_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutSender_UserInput>;
  sequence_entry_criteria_id?: InputMaybe<Scalars['String']['input']>;
  SequenceActionLog?: InputMaybe<StepActionLogCreateNestedManyWithoutRepInput>;
  sequenceDashboardView?: InputMaybe<SequenceDashboardViewCreateNestedManyWithoutOwned_ByInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutUserInput>;
  Sequences_created?: InputMaybe<SequenceCreateNestedManyWithoutCreated_ByInput>;
  Sequences_modified_by_user?: InputMaybe<SequenceCreateNestedManyWithoutLast_Updated_ByInput>;
  session_token?: InputMaybe<Scalars['String']['input']>;
  set_user_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutSet_UserInput>;
  set_user_stat?: InputMaybe<StatLeadAggregationCreateNestedManyWithoutSet_UserInput>;
  sf_user_id?: InputMaybe<Scalars['String']['input']>;
  SmsItems?: InputMaybe<SmsItemCreateNestedManyWithoutRepInput>;
  SnoozeTracking?: InputMaybe<SnoozeTrackingCreateNestedManyWithoutRepInput>;
  status?: InputMaybe<Status>;
  statusHistory?: InputMaybe<UserStatusHistoryCreateNestedManyWithoutUserInput>;
  sub_industry_white_list_array?: InputMaybe<UserCreatesub_Industry_White_List_ArrayInput>;
  sub_industry_white_list_assign_type?: InputMaybe<AssignType>;
  support_rep?: InputMaybe<Scalars['Boolean']['input']>;
  taking_demos?: InputMaybe<Scalars['Boolean']['input']>;
  team?: InputMaybe<TeamCreateNestedOneWithoutMembersInput>;
  Template?: InputMaybe<TemplateCreateNestedManyWithoutLast_Modified_ByInput>;
  TemplateVersion?: InputMaybe<TemplateVersionCreateNestedManyWithoutCreated_ByInput>;
  timekit_id?: InputMaybe<Scalars['String']['input']>;
  timekit_type?: InputMaybe<Timekittype>;
  timeOffRequest?: InputMaybe<TimeOffRequestCreateNestedManyWithoutRequesting_UserInput>;
  timezone_raw?: InputMaybe<Scalars['String']['input']>;
  transfer_type?: InputMaybe<TransferType>;
  transfer_user_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutTransfer_UserInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user_custom_availability?: InputMaybe<AvailabilityCreateNestedManyWithoutUserInput>;
  user_past_tokens?: InputMaybe<UserPastTokensCreateNestedManyWithoutUserInput>;
  visible_all_leads_sm?: InputMaybe<Scalars['Boolean']['input']>;
  visible_call_report_columns?: InputMaybe<UserCreatevisible_Call_Report_ColumnsInput>;
  visible_call_report_event_columns?: InputMaybe<UserCreatevisible_Call_Report_Event_ColumnsInput>;
  visible_call_report_pipeline_columns?: InputMaybe<UserCreatevisible_Call_Report_Pipeline_ColumnsInput>;
  visible_my_leads_columns?: InputMaybe<UserCreatevisible_My_Leads_ColumnsInput>;
  voicemail_url?: InputMaybe<Scalars['String']['input']>;
};

export type UserCreateWithoutProductsCreatedInput = {
  ai_user?: InputMaybe<Scalars['Boolean']['input']>;
  alternate_email?: InputMaybe<Scalars['String']['input']>;
  always_show_calendar_options?: InputMaybe<Scalars['Boolean']['input']>;
  associate_owner_rep_history?: InputMaybe<AssociationHistoryCreateNestedManyWithoutRepInput>;
  associate_user_history?: InputMaybe<AssociationHistoryCreateNestedManyWithoutUserInput>;
  auto_transfer_selection_receive_attempts?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutFrom_UserInput>;
  auto_transfer_selection_send_attempts?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutTo_UserInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptCreateNestedManyWithoutUserInput>;
  callSharingAsCoach?: InputMaybe<CallSharingCreateNestedManyWithoutCoachInput>;
  callSharingAsUser?: InputMaybe<CallSharingCreateNestedManyWithoutReceiver_UserInput>;
  channel?: InputMaybe<Channelfilter>;
  close_rate?: InputMaybe<Scalars['Float']['input']>;
  close_user_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutClose_UserInput>;
  close_user_stat?: InputMaybe<StatLeadAggregationCreateNestedManyWithoutClose_UserInput>;
  commissions?: InputMaybe<CommissionCreateNestedManyWithoutUserInput>;
  contact_requests?: InputMaybe<ContactRequestCreateNestedManyWithoutRequesting_UserInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  current_sequence_view?: InputMaybe<SequenceDashboardViewCreateNestedOneWithoutUserInput>;
  current_team_start_date?: InputMaybe<Scalars['DateTime']['input']>;
  custom_field_white_list_array?: InputMaybe<UserCustomFieldFilterCreateNestedManyWithoutUserInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemCreateNestedManyWithoutUserInput>;
  daily_lead_claim?: InputMaybe<Scalars['Int']['input']>;
  dashboard_2_access?: InputMaybe<Scalars['Boolean']['input']>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewCreateNestedManyWithoutUserInput>;
  default_audio_input?: InputMaybe<Scalars['String']['input']>;
  default_audio_output?: InputMaybe<Scalars['String']['input']>;
  default_availability?: InputMaybe<Scalars['Boolean']['input']>;
  deleted_at?: InputMaybe<Scalars['DateTime']['input']>;
  deprecate_old_session?: InputMaybe<Scalars['Boolean']['input']>;
  email: Scalars['String']['input'];
  EmailSyncRecord?: InputMaybe<EmailSyncRecordCreateNestedManyWithoutUserInput>;
  favorite_leads?: InputMaybe<LeadCreateNestedManyWithoutFavorited_By_UsersInput>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  folder_permissions?: InputMaybe<FolderPermissionCreateNestedManyWithoutUserInput>;
  folders?: InputMaybe<FolderCreateNestedManyWithoutOwnerInput>;
  from_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutOld_UserInput>;
  GlobalRouting?: InputMaybe<GlobalRepRoutingCreateNestedManyWithoutRepInput>;
  google_refresh_token?: InputMaybe<Scalars['String']['input']>;
  holds_per_prospect_vs_held_demo_per_dial?: InputMaybe<Scalars['Float']['input']>;
  hook_subscriptions?: InputMaybe<WebHookSubscriptionCreateNestedManyWithoutUserInput>;
  hubspot_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  idle_owned_leads?: InputMaybe<LeadCreateNestedManyWithoutSecondary_RepsInput>;
  industry_filter?: InputMaybe<Scalars['Boolean']['input']>;
  industry_white_list_array?: InputMaybe<UserCreateindustry_White_List_ArrayInput>;
  industry_white_list_assign_type?: InputMaybe<AssignType>;
  joinMe_access_token?: InputMaybe<Scalars['String']['input']>;
  joinMe_refresh_token?: InputMaybe<Scalars['String']['input']>;
  last_demo_transferred_at?: InputMaybe<Scalars['DateTime']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutUserInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryCreateNestedManyWithoutUserInput>;
  lead_intents?: InputMaybe<LeadIntentCreateNestedManyWithoutUserInput>;
  lead_source_filter?: InputMaybe<Scalars['Boolean']['input']>;
  lead_source_white_list_array?: InputMaybe<UserCreatelead_Source_White_List_ArrayInput>;
  lead_source_white_list_assign_type?: InputMaybe<AssignType>;
  leads_last_called?: InputMaybe<LeadCreateNestedManyWithoutLast_Call_Result_RepInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusCreateNestedManyWithoutUserInput>;
  manager_id?: InputMaybe<Scalars['String']['input']>;
  note_item?: InputMaybe<NoteItemCreateNestedManyWithoutUserInput>;
  notifications?: InputMaybe<NotificationCreateNestedManyWithoutUserInput>;
  organization: OrganizationCreateNestedOneWithoutUsersInput;
  owned_leads?: InputMaybe<LeadCreateNestedManyWithoutRepInput>;
  panda_doc_access?: InputMaybe<Scalars['Boolean']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  payment_settings?: InputMaybe<OrganizationPaymentSettingsCreateNestedManyWithoutCreated_ByInput>;
  payment_settings_updated?: InputMaybe<OrganizationPaymentSettingsCreateNestedManyWithoutLast_Updated_ByInput>;
  phone_number?: InputMaybe<Scalars['String']['input']>;
  post?: InputMaybe<PostCreateNestedManyWithoutAuthorInput>;
  profile_image?: InputMaybe<Scalars['String']['input']>;
  receive_transfer_attempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutTo_UserInput>;
  receiver_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutReceiver_UserInput>;
  regions_white_list_array?: InputMaybe<UserCreateregions_White_List_ArrayInput>;
  regions_white_list_assign_type?: InputMaybe<AssignType>;
  rep_scores?: InputMaybe<Scalars['Float']['input']>;
  role?: InputMaybe<Role>;
  routings?: InputMaybe<RoutingCreateNestedManyWithoutRepInput>;
  sale_config?: InputMaybe<SaleConfigCreateNestedManyWithoutCreated_ByInput>;
  sale_config_updated?: InputMaybe<SaleConfigCreateNestedManyWithoutLast_Updated_ByInput>;
  sales_team_column_options?: InputMaybe<UserCreatesales_Team_Column_OptionsInput>;
  schedule_items?: InputMaybe<ScheduleItemCreateNestedManyWithoutUserInput>;
  send_transfer_attempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutFrom_UserInput>;
  sender_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutSender_UserInput>;
  sequence_entry_criteria_id?: InputMaybe<Scalars['String']['input']>;
  SequenceActionLog?: InputMaybe<StepActionLogCreateNestedManyWithoutRepInput>;
  sequenceDashboardView?: InputMaybe<SequenceDashboardViewCreateNestedManyWithoutOwned_ByInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutUserInput>;
  Sequences_created?: InputMaybe<SequenceCreateNestedManyWithoutCreated_ByInput>;
  Sequences_modified_by_user?: InputMaybe<SequenceCreateNestedManyWithoutLast_Updated_ByInput>;
  session_token?: InputMaybe<Scalars['String']['input']>;
  set_user_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutSet_UserInput>;
  set_user_stat?: InputMaybe<StatLeadAggregationCreateNestedManyWithoutSet_UserInput>;
  sf_user_id?: InputMaybe<Scalars['String']['input']>;
  SmsItems?: InputMaybe<SmsItemCreateNestedManyWithoutRepInput>;
  SnoozeTracking?: InputMaybe<SnoozeTrackingCreateNestedManyWithoutRepInput>;
  status?: InputMaybe<Status>;
  statusHistory?: InputMaybe<UserStatusHistoryCreateNestedManyWithoutUserInput>;
  sub_industry_white_list_array?: InputMaybe<UserCreatesub_Industry_White_List_ArrayInput>;
  sub_industry_white_list_assign_type?: InputMaybe<AssignType>;
  support_rep?: InputMaybe<Scalars['Boolean']['input']>;
  taking_demos?: InputMaybe<Scalars['Boolean']['input']>;
  team?: InputMaybe<TeamCreateNestedOneWithoutMembersInput>;
  Template?: InputMaybe<TemplateCreateNestedManyWithoutLast_Modified_ByInput>;
  TemplateVersion?: InputMaybe<TemplateVersionCreateNestedManyWithoutCreated_ByInput>;
  timekit_id?: InputMaybe<Scalars['String']['input']>;
  timekit_type?: InputMaybe<Timekittype>;
  timeOffRequest?: InputMaybe<TimeOffRequestCreateNestedManyWithoutRequesting_UserInput>;
  timezone_raw?: InputMaybe<Scalars['String']['input']>;
  transfer_type?: InputMaybe<TransferType>;
  transfer_user_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutTransfer_UserInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user_custom_availability?: InputMaybe<AvailabilityCreateNestedManyWithoutUserInput>;
  user_past_tokens?: InputMaybe<UserPastTokensCreateNestedManyWithoutUserInput>;
  visible_all_leads_sm?: InputMaybe<Scalars['Boolean']['input']>;
  visible_call_report_columns?: InputMaybe<UserCreatevisible_Call_Report_ColumnsInput>;
  visible_call_report_event_columns?: InputMaybe<UserCreatevisible_Call_Report_Event_ColumnsInput>;
  visible_call_report_pipeline_columns?: InputMaybe<UserCreatevisible_Call_Report_Pipeline_ColumnsInput>;
  visible_my_leads_columns?: InputMaybe<UserCreatevisible_My_Leads_ColumnsInput>;
  voicemail_url?: InputMaybe<Scalars['String']['input']>;
};

export type UserCreateWithoutReceive_Transfer_AttemptsInput = {
  ai_user?: InputMaybe<Scalars['Boolean']['input']>;
  alternate_email?: InputMaybe<Scalars['String']['input']>;
  always_show_calendar_options?: InputMaybe<Scalars['Boolean']['input']>;
  associate_owner_rep_history?: InputMaybe<AssociationHistoryCreateNestedManyWithoutRepInput>;
  associate_user_history?: InputMaybe<AssociationHistoryCreateNestedManyWithoutUserInput>;
  auto_transfer_selection_receive_attempts?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutFrom_UserInput>;
  auto_transfer_selection_send_attempts?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutTo_UserInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptCreateNestedManyWithoutUserInput>;
  callSharingAsCoach?: InputMaybe<CallSharingCreateNestedManyWithoutCoachInput>;
  callSharingAsUser?: InputMaybe<CallSharingCreateNestedManyWithoutReceiver_UserInput>;
  channel?: InputMaybe<Channelfilter>;
  close_rate?: InputMaybe<Scalars['Float']['input']>;
  close_user_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutClose_UserInput>;
  close_user_stat?: InputMaybe<StatLeadAggregationCreateNestedManyWithoutClose_UserInput>;
  commissions?: InputMaybe<CommissionCreateNestedManyWithoutUserInput>;
  contact_requests?: InputMaybe<ContactRequestCreateNestedManyWithoutRequesting_UserInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  current_sequence_view?: InputMaybe<SequenceDashboardViewCreateNestedOneWithoutUserInput>;
  current_team_start_date?: InputMaybe<Scalars['DateTime']['input']>;
  custom_field_white_list_array?: InputMaybe<UserCustomFieldFilterCreateNestedManyWithoutUserInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemCreateNestedManyWithoutUserInput>;
  daily_lead_claim?: InputMaybe<Scalars['Int']['input']>;
  dashboard_2_access?: InputMaybe<Scalars['Boolean']['input']>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewCreateNestedManyWithoutUserInput>;
  default_audio_input?: InputMaybe<Scalars['String']['input']>;
  default_audio_output?: InputMaybe<Scalars['String']['input']>;
  default_availability?: InputMaybe<Scalars['Boolean']['input']>;
  deleted_at?: InputMaybe<Scalars['DateTime']['input']>;
  deprecate_old_session?: InputMaybe<Scalars['Boolean']['input']>;
  email: Scalars['String']['input'];
  EmailSyncRecord?: InputMaybe<EmailSyncRecordCreateNestedManyWithoutUserInput>;
  favorite_leads?: InputMaybe<LeadCreateNestedManyWithoutFavorited_By_UsersInput>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  folder_permissions?: InputMaybe<FolderPermissionCreateNestedManyWithoutUserInput>;
  folders?: InputMaybe<FolderCreateNestedManyWithoutOwnerInput>;
  from_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutOld_UserInput>;
  GlobalRouting?: InputMaybe<GlobalRepRoutingCreateNestedManyWithoutRepInput>;
  google_refresh_token?: InputMaybe<Scalars['String']['input']>;
  holds_per_prospect_vs_held_demo_per_dial?: InputMaybe<Scalars['Float']['input']>;
  hook_subscriptions?: InputMaybe<WebHookSubscriptionCreateNestedManyWithoutUserInput>;
  hubspot_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  idle_owned_leads?: InputMaybe<LeadCreateNestedManyWithoutSecondary_RepsInput>;
  industry_filter?: InputMaybe<Scalars['Boolean']['input']>;
  industry_white_list_array?: InputMaybe<UserCreateindustry_White_List_ArrayInput>;
  industry_white_list_assign_type?: InputMaybe<AssignType>;
  joinMe_access_token?: InputMaybe<Scalars['String']['input']>;
  joinMe_refresh_token?: InputMaybe<Scalars['String']['input']>;
  last_demo_transferred_at?: InputMaybe<Scalars['DateTime']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutUserInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryCreateNestedManyWithoutUserInput>;
  lead_intents?: InputMaybe<LeadIntentCreateNestedManyWithoutUserInput>;
  lead_source_filter?: InputMaybe<Scalars['Boolean']['input']>;
  lead_source_white_list_array?: InputMaybe<UserCreatelead_Source_White_List_ArrayInput>;
  lead_source_white_list_assign_type?: InputMaybe<AssignType>;
  leads_last_called?: InputMaybe<LeadCreateNestedManyWithoutLast_Call_Result_RepInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusCreateNestedManyWithoutUserInput>;
  manager_id?: InputMaybe<Scalars['String']['input']>;
  note_item?: InputMaybe<NoteItemCreateNestedManyWithoutUserInput>;
  notifications?: InputMaybe<NotificationCreateNestedManyWithoutUserInput>;
  organization: OrganizationCreateNestedOneWithoutUsersInput;
  owned_leads?: InputMaybe<LeadCreateNestedManyWithoutRepInput>;
  panda_doc_access?: InputMaybe<Scalars['Boolean']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  payment_settings?: InputMaybe<OrganizationPaymentSettingsCreateNestedManyWithoutCreated_ByInput>;
  payment_settings_updated?: InputMaybe<OrganizationPaymentSettingsCreateNestedManyWithoutLast_Updated_ByInput>;
  phone_number?: InputMaybe<Scalars['String']['input']>;
  post?: InputMaybe<PostCreateNestedManyWithoutAuthorInput>;
  ProductsCreated?: InputMaybe<ProductChangeLogCreateNestedManyWithoutUpdated_ByInput>;
  profile_image?: InputMaybe<Scalars['String']['input']>;
  receiver_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutReceiver_UserInput>;
  regions_white_list_array?: InputMaybe<UserCreateregions_White_List_ArrayInput>;
  regions_white_list_assign_type?: InputMaybe<AssignType>;
  rep_scores?: InputMaybe<Scalars['Float']['input']>;
  role?: InputMaybe<Role>;
  routings?: InputMaybe<RoutingCreateNestedManyWithoutRepInput>;
  sale_config?: InputMaybe<SaleConfigCreateNestedManyWithoutCreated_ByInput>;
  sale_config_updated?: InputMaybe<SaleConfigCreateNestedManyWithoutLast_Updated_ByInput>;
  sales_team_column_options?: InputMaybe<UserCreatesales_Team_Column_OptionsInput>;
  schedule_items?: InputMaybe<ScheduleItemCreateNestedManyWithoutUserInput>;
  send_transfer_attempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutFrom_UserInput>;
  sender_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutSender_UserInput>;
  sequence_entry_criteria_id?: InputMaybe<Scalars['String']['input']>;
  SequenceActionLog?: InputMaybe<StepActionLogCreateNestedManyWithoutRepInput>;
  sequenceDashboardView?: InputMaybe<SequenceDashboardViewCreateNestedManyWithoutOwned_ByInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutUserInput>;
  Sequences_created?: InputMaybe<SequenceCreateNestedManyWithoutCreated_ByInput>;
  Sequences_modified_by_user?: InputMaybe<SequenceCreateNestedManyWithoutLast_Updated_ByInput>;
  session_token?: InputMaybe<Scalars['String']['input']>;
  set_user_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutSet_UserInput>;
  set_user_stat?: InputMaybe<StatLeadAggregationCreateNestedManyWithoutSet_UserInput>;
  sf_user_id?: InputMaybe<Scalars['String']['input']>;
  SmsItems?: InputMaybe<SmsItemCreateNestedManyWithoutRepInput>;
  SnoozeTracking?: InputMaybe<SnoozeTrackingCreateNestedManyWithoutRepInput>;
  status?: InputMaybe<Status>;
  statusHistory?: InputMaybe<UserStatusHistoryCreateNestedManyWithoutUserInput>;
  sub_industry_white_list_array?: InputMaybe<UserCreatesub_Industry_White_List_ArrayInput>;
  sub_industry_white_list_assign_type?: InputMaybe<AssignType>;
  support_rep?: InputMaybe<Scalars['Boolean']['input']>;
  taking_demos?: InputMaybe<Scalars['Boolean']['input']>;
  team?: InputMaybe<TeamCreateNestedOneWithoutMembersInput>;
  Template?: InputMaybe<TemplateCreateNestedManyWithoutLast_Modified_ByInput>;
  TemplateVersion?: InputMaybe<TemplateVersionCreateNestedManyWithoutCreated_ByInput>;
  timekit_id?: InputMaybe<Scalars['String']['input']>;
  timekit_type?: InputMaybe<Timekittype>;
  timeOffRequest?: InputMaybe<TimeOffRequestCreateNestedManyWithoutRequesting_UserInput>;
  timezone_raw?: InputMaybe<Scalars['String']['input']>;
  transfer_type?: InputMaybe<TransferType>;
  transfer_user_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutTransfer_UserInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user_custom_availability?: InputMaybe<AvailabilityCreateNestedManyWithoutUserInput>;
  user_past_tokens?: InputMaybe<UserPastTokensCreateNestedManyWithoutUserInput>;
  visible_all_leads_sm?: InputMaybe<Scalars['Boolean']['input']>;
  visible_call_report_columns?: InputMaybe<UserCreatevisible_Call_Report_ColumnsInput>;
  visible_call_report_event_columns?: InputMaybe<UserCreatevisible_Call_Report_Event_ColumnsInput>;
  visible_call_report_pipeline_columns?: InputMaybe<UserCreatevisible_Call_Report_Pipeline_ColumnsInput>;
  visible_my_leads_columns?: InputMaybe<UserCreatevisible_My_Leads_ColumnsInput>;
  voicemail_url?: InputMaybe<Scalars['String']['input']>;
};

export type UserCreateWithoutReceiver_Assignment_HistoryInput = {
  ai_user?: InputMaybe<Scalars['Boolean']['input']>;
  alternate_email?: InputMaybe<Scalars['String']['input']>;
  always_show_calendar_options?: InputMaybe<Scalars['Boolean']['input']>;
  associate_owner_rep_history?: InputMaybe<AssociationHistoryCreateNestedManyWithoutRepInput>;
  associate_user_history?: InputMaybe<AssociationHistoryCreateNestedManyWithoutUserInput>;
  auto_transfer_selection_receive_attempts?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutFrom_UserInput>;
  auto_transfer_selection_send_attempts?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutTo_UserInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptCreateNestedManyWithoutUserInput>;
  callSharingAsCoach?: InputMaybe<CallSharingCreateNestedManyWithoutCoachInput>;
  callSharingAsUser?: InputMaybe<CallSharingCreateNestedManyWithoutReceiver_UserInput>;
  channel?: InputMaybe<Channelfilter>;
  close_rate?: InputMaybe<Scalars['Float']['input']>;
  close_user_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutClose_UserInput>;
  close_user_stat?: InputMaybe<StatLeadAggregationCreateNestedManyWithoutClose_UserInput>;
  commissions?: InputMaybe<CommissionCreateNestedManyWithoutUserInput>;
  contact_requests?: InputMaybe<ContactRequestCreateNestedManyWithoutRequesting_UserInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  current_sequence_view?: InputMaybe<SequenceDashboardViewCreateNestedOneWithoutUserInput>;
  current_team_start_date?: InputMaybe<Scalars['DateTime']['input']>;
  custom_field_white_list_array?: InputMaybe<UserCustomFieldFilterCreateNestedManyWithoutUserInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemCreateNestedManyWithoutUserInput>;
  daily_lead_claim?: InputMaybe<Scalars['Int']['input']>;
  dashboard_2_access?: InputMaybe<Scalars['Boolean']['input']>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewCreateNestedManyWithoutUserInput>;
  default_audio_input?: InputMaybe<Scalars['String']['input']>;
  default_audio_output?: InputMaybe<Scalars['String']['input']>;
  default_availability?: InputMaybe<Scalars['Boolean']['input']>;
  deleted_at?: InputMaybe<Scalars['DateTime']['input']>;
  deprecate_old_session?: InputMaybe<Scalars['Boolean']['input']>;
  email: Scalars['String']['input'];
  EmailSyncRecord?: InputMaybe<EmailSyncRecordCreateNestedManyWithoutUserInput>;
  favorite_leads?: InputMaybe<LeadCreateNestedManyWithoutFavorited_By_UsersInput>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  folder_permissions?: InputMaybe<FolderPermissionCreateNestedManyWithoutUserInput>;
  folders?: InputMaybe<FolderCreateNestedManyWithoutOwnerInput>;
  from_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutOld_UserInput>;
  GlobalRouting?: InputMaybe<GlobalRepRoutingCreateNestedManyWithoutRepInput>;
  google_refresh_token?: InputMaybe<Scalars['String']['input']>;
  holds_per_prospect_vs_held_demo_per_dial?: InputMaybe<Scalars['Float']['input']>;
  hook_subscriptions?: InputMaybe<WebHookSubscriptionCreateNestedManyWithoutUserInput>;
  hubspot_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  idle_owned_leads?: InputMaybe<LeadCreateNestedManyWithoutSecondary_RepsInput>;
  industry_filter?: InputMaybe<Scalars['Boolean']['input']>;
  industry_white_list_array?: InputMaybe<UserCreateindustry_White_List_ArrayInput>;
  industry_white_list_assign_type?: InputMaybe<AssignType>;
  joinMe_access_token?: InputMaybe<Scalars['String']['input']>;
  joinMe_refresh_token?: InputMaybe<Scalars['String']['input']>;
  last_demo_transferred_at?: InputMaybe<Scalars['DateTime']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutUserInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryCreateNestedManyWithoutUserInput>;
  lead_intents?: InputMaybe<LeadIntentCreateNestedManyWithoutUserInput>;
  lead_source_filter?: InputMaybe<Scalars['Boolean']['input']>;
  lead_source_white_list_array?: InputMaybe<UserCreatelead_Source_White_List_ArrayInput>;
  lead_source_white_list_assign_type?: InputMaybe<AssignType>;
  leads_last_called?: InputMaybe<LeadCreateNestedManyWithoutLast_Call_Result_RepInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusCreateNestedManyWithoutUserInput>;
  manager_id?: InputMaybe<Scalars['String']['input']>;
  note_item?: InputMaybe<NoteItemCreateNestedManyWithoutUserInput>;
  notifications?: InputMaybe<NotificationCreateNestedManyWithoutUserInput>;
  organization: OrganizationCreateNestedOneWithoutUsersInput;
  owned_leads?: InputMaybe<LeadCreateNestedManyWithoutRepInput>;
  panda_doc_access?: InputMaybe<Scalars['Boolean']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  payment_settings?: InputMaybe<OrganizationPaymentSettingsCreateNestedManyWithoutCreated_ByInput>;
  payment_settings_updated?: InputMaybe<OrganizationPaymentSettingsCreateNestedManyWithoutLast_Updated_ByInput>;
  phone_number?: InputMaybe<Scalars['String']['input']>;
  post?: InputMaybe<PostCreateNestedManyWithoutAuthorInput>;
  ProductsCreated?: InputMaybe<ProductChangeLogCreateNestedManyWithoutUpdated_ByInput>;
  profile_image?: InputMaybe<Scalars['String']['input']>;
  receive_transfer_attempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutTo_UserInput>;
  regions_white_list_array?: InputMaybe<UserCreateregions_White_List_ArrayInput>;
  regions_white_list_assign_type?: InputMaybe<AssignType>;
  rep_scores?: InputMaybe<Scalars['Float']['input']>;
  role?: InputMaybe<Role>;
  routings?: InputMaybe<RoutingCreateNestedManyWithoutRepInput>;
  sale_config?: InputMaybe<SaleConfigCreateNestedManyWithoutCreated_ByInput>;
  sale_config_updated?: InputMaybe<SaleConfigCreateNestedManyWithoutLast_Updated_ByInput>;
  sales_team_column_options?: InputMaybe<UserCreatesales_Team_Column_OptionsInput>;
  schedule_items?: InputMaybe<ScheduleItemCreateNestedManyWithoutUserInput>;
  send_transfer_attempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutFrom_UserInput>;
  sender_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutSender_UserInput>;
  sequence_entry_criteria_id?: InputMaybe<Scalars['String']['input']>;
  SequenceActionLog?: InputMaybe<StepActionLogCreateNestedManyWithoutRepInput>;
  sequenceDashboardView?: InputMaybe<SequenceDashboardViewCreateNestedManyWithoutOwned_ByInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutUserInput>;
  Sequences_created?: InputMaybe<SequenceCreateNestedManyWithoutCreated_ByInput>;
  Sequences_modified_by_user?: InputMaybe<SequenceCreateNestedManyWithoutLast_Updated_ByInput>;
  session_token?: InputMaybe<Scalars['String']['input']>;
  set_user_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutSet_UserInput>;
  set_user_stat?: InputMaybe<StatLeadAggregationCreateNestedManyWithoutSet_UserInput>;
  sf_user_id?: InputMaybe<Scalars['String']['input']>;
  SmsItems?: InputMaybe<SmsItemCreateNestedManyWithoutRepInput>;
  SnoozeTracking?: InputMaybe<SnoozeTrackingCreateNestedManyWithoutRepInput>;
  status?: InputMaybe<Status>;
  statusHistory?: InputMaybe<UserStatusHistoryCreateNestedManyWithoutUserInput>;
  sub_industry_white_list_array?: InputMaybe<UserCreatesub_Industry_White_List_ArrayInput>;
  sub_industry_white_list_assign_type?: InputMaybe<AssignType>;
  support_rep?: InputMaybe<Scalars['Boolean']['input']>;
  taking_demos?: InputMaybe<Scalars['Boolean']['input']>;
  team?: InputMaybe<TeamCreateNestedOneWithoutMembersInput>;
  Template?: InputMaybe<TemplateCreateNestedManyWithoutLast_Modified_ByInput>;
  TemplateVersion?: InputMaybe<TemplateVersionCreateNestedManyWithoutCreated_ByInput>;
  timekit_id?: InputMaybe<Scalars['String']['input']>;
  timekit_type?: InputMaybe<Timekittype>;
  timeOffRequest?: InputMaybe<TimeOffRequestCreateNestedManyWithoutRequesting_UserInput>;
  timezone_raw?: InputMaybe<Scalars['String']['input']>;
  transfer_type?: InputMaybe<TransferType>;
  transfer_user_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutTransfer_UserInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user_custom_availability?: InputMaybe<AvailabilityCreateNestedManyWithoutUserInput>;
  user_past_tokens?: InputMaybe<UserPastTokensCreateNestedManyWithoutUserInput>;
  visible_all_leads_sm?: InputMaybe<Scalars['Boolean']['input']>;
  visible_call_report_columns?: InputMaybe<UserCreatevisible_Call_Report_ColumnsInput>;
  visible_call_report_event_columns?: InputMaybe<UserCreatevisible_Call_Report_Event_ColumnsInput>;
  visible_call_report_pipeline_columns?: InputMaybe<UserCreatevisible_Call_Report_Pipeline_ColumnsInput>;
  visible_my_leads_columns?: InputMaybe<UserCreatevisible_My_Leads_ColumnsInput>;
  voicemail_url?: InputMaybe<Scalars['String']['input']>;
};

export type UserCreateWithoutRoutingsInput = {
  ai_user?: InputMaybe<Scalars['Boolean']['input']>;
  alternate_email?: InputMaybe<Scalars['String']['input']>;
  always_show_calendar_options?: InputMaybe<Scalars['Boolean']['input']>;
  associate_owner_rep_history?: InputMaybe<AssociationHistoryCreateNestedManyWithoutRepInput>;
  associate_user_history?: InputMaybe<AssociationHistoryCreateNestedManyWithoutUserInput>;
  auto_transfer_selection_receive_attempts?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutFrom_UserInput>;
  auto_transfer_selection_send_attempts?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutTo_UserInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptCreateNestedManyWithoutUserInput>;
  callSharingAsCoach?: InputMaybe<CallSharingCreateNestedManyWithoutCoachInput>;
  callSharingAsUser?: InputMaybe<CallSharingCreateNestedManyWithoutReceiver_UserInput>;
  channel?: InputMaybe<Channelfilter>;
  close_rate?: InputMaybe<Scalars['Float']['input']>;
  close_user_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutClose_UserInput>;
  close_user_stat?: InputMaybe<StatLeadAggregationCreateNestedManyWithoutClose_UserInput>;
  commissions?: InputMaybe<CommissionCreateNestedManyWithoutUserInput>;
  contact_requests?: InputMaybe<ContactRequestCreateNestedManyWithoutRequesting_UserInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  current_sequence_view?: InputMaybe<SequenceDashboardViewCreateNestedOneWithoutUserInput>;
  current_team_start_date?: InputMaybe<Scalars['DateTime']['input']>;
  custom_field_white_list_array?: InputMaybe<UserCustomFieldFilterCreateNestedManyWithoutUserInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemCreateNestedManyWithoutUserInput>;
  daily_lead_claim?: InputMaybe<Scalars['Int']['input']>;
  dashboard_2_access?: InputMaybe<Scalars['Boolean']['input']>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewCreateNestedManyWithoutUserInput>;
  default_audio_input?: InputMaybe<Scalars['String']['input']>;
  default_audio_output?: InputMaybe<Scalars['String']['input']>;
  default_availability?: InputMaybe<Scalars['Boolean']['input']>;
  deleted_at?: InputMaybe<Scalars['DateTime']['input']>;
  deprecate_old_session?: InputMaybe<Scalars['Boolean']['input']>;
  email: Scalars['String']['input'];
  EmailSyncRecord?: InputMaybe<EmailSyncRecordCreateNestedManyWithoutUserInput>;
  favorite_leads?: InputMaybe<LeadCreateNestedManyWithoutFavorited_By_UsersInput>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  folder_permissions?: InputMaybe<FolderPermissionCreateNestedManyWithoutUserInput>;
  folders?: InputMaybe<FolderCreateNestedManyWithoutOwnerInput>;
  from_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutOld_UserInput>;
  GlobalRouting?: InputMaybe<GlobalRepRoutingCreateNestedManyWithoutRepInput>;
  google_refresh_token?: InputMaybe<Scalars['String']['input']>;
  holds_per_prospect_vs_held_demo_per_dial?: InputMaybe<Scalars['Float']['input']>;
  hook_subscriptions?: InputMaybe<WebHookSubscriptionCreateNestedManyWithoutUserInput>;
  hubspot_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  idle_owned_leads?: InputMaybe<LeadCreateNestedManyWithoutSecondary_RepsInput>;
  industry_filter?: InputMaybe<Scalars['Boolean']['input']>;
  industry_white_list_array?: InputMaybe<UserCreateindustry_White_List_ArrayInput>;
  industry_white_list_assign_type?: InputMaybe<AssignType>;
  joinMe_access_token?: InputMaybe<Scalars['String']['input']>;
  joinMe_refresh_token?: InputMaybe<Scalars['String']['input']>;
  last_demo_transferred_at?: InputMaybe<Scalars['DateTime']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutUserInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryCreateNestedManyWithoutUserInput>;
  lead_intents?: InputMaybe<LeadIntentCreateNestedManyWithoutUserInput>;
  lead_source_filter?: InputMaybe<Scalars['Boolean']['input']>;
  lead_source_white_list_array?: InputMaybe<UserCreatelead_Source_White_List_ArrayInput>;
  lead_source_white_list_assign_type?: InputMaybe<AssignType>;
  leads_last_called?: InputMaybe<LeadCreateNestedManyWithoutLast_Call_Result_RepInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusCreateNestedManyWithoutUserInput>;
  manager_id?: InputMaybe<Scalars['String']['input']>;
  note_item?: InputMaybe<NoteItemCreateNestedManyWithoutUserInput>;
  notifications?: InputMaybe<NotificationCreateNestedManyWithoutUserInput>;
  organization: OrganizationCreateNestedOneWithoutUsersInput;
  owned_leads?: InputMaybe<LeadCreateNestedManyWithoutRepInput>;
  panda_doc_access?: InputMaybe<Scalars['Boolean']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  payment_settings?: InputMaybe<OrganizationPaymentSettingsCreateNestedManyWithoutCreated_ByInput>;
  payment_settings_updated?: InputMaybe<OrganizationPaymentSettingsCreateNestedManyWithoutLast_Updated_ByInput>;
  phone_number?: InputMaybe<Scalars['String']['input']>;
  post?: InputMaybe<PostCreateNestedManyWithoutAuthorInput>;
  ProductsCreated?: InputMaybe<ProductChangeLogCreateNestedManyWithoutUpdated_ByInput>;
  profile_image?: InputMaybe<Scalars['String']['input']>;
  receive_transfer_attempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutTo_UserInput>;
  receiver_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutReceiver_UserInput>;
  regions_white_list_array?: InputMaybe<UserCreateregions_White_List_ArrayInput>;
  regions_white_list_assign_type?: InputMaybe<AssignType>;
  rep_scores?: InputMaybe<Scalars['Float']['input']>;
  role?: InputMaybe<Role>;
  sale_config?: InputMaybe<SaleConfigCreateNestedManyWithoutCreated_ByInput>;
  sale_config_updated?: InputMaybe<SaleConfigCreateNestedManyWithoutLast_Updated_ByInput>;
  sales_team_column_options?: InputMaybe<UserCreatesales_Team_Column_OptionsInput>;
  schedule_items?: InputMaybe<ScheduleItemCreateNestedManyWithoutUserInput>;
  send_transfer_attempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutFrom_UserInput>;
  sender_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutSender_UserInput>;
  sequence_entry_criteria_id?: InputMaybe<Scalars['String']['input']>;
  SequenceActionLog?: InputMaybe<StepActionLogCreateNestedManyWithoutRepInput>;
  sequenceDashboardView?: InputMaybe<SequenceDashboardViewCreateNestedManyWithoutOwned_ByInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutUserInput>;
  Sequences_created?: InputMaybe<SequenceCreateNestedManyWithoutCreated_ByInput>;
  Sequences_modified_by_user?: InputMaybe<SequenceCreateNestedManyWithoutLast_Updated_ByInput>;
  session_token?: InputMaybe<Scalars['String']['input']>;
  set_user_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutSet_UserInput>;
  set_user_stat?: InputMaybe<StatLeadAggregationCreateNestedManyWithoutSet_UserInput>;
  sf_user_id?: InputMaybe<Scalars['String']['input']>;
  SmsItems?: InputMaybe<SmsItemCreateNestedManyWithoutRepInput>;
  SnoozeTracking?: InputMaybe<SnoozeTrackingCreateNestedManyWithoutRepInput>;
  status?: InputMaybe<Status>;
  statusHistory?: InputMaybe<UserStatusHistoryCreateNestedManyWithoutUserInput>;
  sub_industry_white_list_array?: InputMaybe<UserCreatesub_Industry_White_List_ArrayInput>;
  sub_industry_white_list_assign_type?: InputMaybe<AssignType>;
  support_rep?: InputMaybe<Scalars['Boolean']['input']>;
  taking_demos?: InputMaybe<Scalars['Boolean']['input']>;
  team?: InputMaybe<TeamCreateNestedOneWithoutMembersInput>;
  Template?: InputMaybe<TemplateCreateNestedManyWithoutLast_Modified_ByInput>;
  TemplateVersion?: InputMaybe<TemplateVersionCreateNestedManyWithoutCreated_ByInput>;
  timekit_id?: InputMaybe<Scalars['String']['input']>;
  timekit_type?: InputMaybe<Timekittype>;
  timeOffRequest?: InputMaybe<TimeOffRequestCreateNestedManyWithoutRequesting_UserInput>;
  timezone_raw?: InputMaybe<Scalars['String']['input']>;
  transfer_type?: InputMaybe<TransferType>;
  transfer_user_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutTransfer_UserInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user_custom_availability?: InputMaybe<AvailabilityCreateNestedManyWithoutUserInput>;
  user_past_tokens?: InputMaybe<UserPastTokensCreateNestedManyWithoutUserInput>;
  visible_all_leads_sm?: InputMaybe<Scalars['Boolean']['input']>;
  visible_call_report_columns?: InputMaybe<UserCreatevisible_Call_Report_ColumnsInput>;
  visible_call_report_event_columns?: InputMaybe<UserCreatevisible_Call_Report_Event_ColumnsInput>;
  visible_call_report_pipeline_columns?: InputMaybe<UserCreatevisible_Call_Report_Pipeline_ColumnsInput>;
  visible_my_leads_columns?: InputMaybe<UserCreatevisible_My_Leads_ColumnsInput>;
  voicemail_url?: InputMaybe<Scalars['String']['input']>;
};

export type UserCreateWithoutSale_Config_UpdatedInput = {
  ai_user?: InputMaybe<Scalars['Boolean']['input']>;
  alternate_email?: InputMaybe<Scalars['String']['input']>;
  always_show_calendar_options?: InputMaybe<Scalars['Boolean']['input']>;
  associate_owner_rep_history?: InputMaybe<AssociationHistoryCreateNestedManyWithoutRepInput>;
  associate_user_history?: InputMaybe<AssociationHistoryCreateNestedManyWithoutUserInput>;
  auto_transfer_selection_receive_attempts?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutFrom_UserInput>;
  auto_transfer_selection_send_attempts?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutTo_UserInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptCreateNestedManyWithoutUserInput>;
  callSharingAsCoach?: InputMaybe<CallSharingCreateNestedManyWithoutCoachInput>;
  callSharingAsUser?: InputMaybe<CallSharingCreateNestedManyWithoutReceiver_UserInput>;
  channel?: InputMaybe<Channelfilter>;
  close_rate?: InputMaybe<Scalars['Float']['input']>;
  close_user_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutClose_UserInput>;
  close_user_stat?: InputMaybe<StatLeadAggregationCreateNestedManyWithoutClose_UserInput>;
  commissions?: InputMaybe<CommissionCreateNestedManyWithoutUserInput>;
  contact_requests?: InputMaybe<ContactRequestCreateNestedManyWithoutRequesting_UserInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  current_sequence_view?: InputMaybe<SequenceDashboardViewCreateNestedOneWithoutUserInput>;
  current_team_start_date?: InputMaybe<Scalars['DateTime']['input']>;
  custom_field_white_list_array?: InputMaybe<UserCustomFieldFilterCreateNestedManyWithoutUserInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemCreateNestedManyWithoutUserInput>;
  daily_lead_claim?: InputMaybe<Scalars['Int']['input']>;
  dashboard_2_access?: InputMaybe<Scalars['Boolean']['input']>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewCreateNestedManyWithoutUserInput>;
  default_audio_input?: InputMaybe<Scalars['String']['input']>;
  default_audio_output?: InputMaybe<Scalars['String']['input']>;
  default_availability?: InputMaybe<Scalars['Boolean']['input']>;
  deleted_at?: InputMaybe<Scalars['DateTime']['input']>;
  deprecate_old_session?: InputMaybe<Scalars['Boolean']['input']>;
  email: Scalars['String']['input'];
  EmailSyncRecord?: InputMaybe<EmailSyncRecordCreateNestedManyWithoutUserInput>;
  favorite_leads?: InputMaybe<LeadCreateNestedManyWithoutFavorited_By_UsersInput>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  folder_permissions?: InputMaybe<FolderPermissionCreateNestedManyWithoutUserInput>;
  folders?: InputMaybe<FolderCreateNestedManyWithoutOwnerInput>;
  from_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutOld_UserInput>;
  GlobalRouting?: InputMaybe<GlobalRepRoutingCreateNestedManyWithoutRepInput>;
  google_refresh_token?: InputMaybe<Scalars['String']['input']>;
  holds_per_prospect_vs_held_demo_per_dial?: InputMaybe<Scalars['Float']['input']>;
  hook_subscriptions?: InputMaybe<WebHookSubscriptionCreateNestedManyWithoutUserInput>;
  hubspot_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  idle_owned_leads?: InputMaybe<LeadCreateNestedManyWithoutSecondary_RepsInput>;
  industry_filter?: InputMaybe<Scalars['Boolean']['input']>;
  industry_white_list_array?: InputMaybe<UserCreateindustry_White_List_ArrayInput>;
  industry_white_list_assign_type?: InputMaybe<AssignType>;
  joinMe_access_token?: InputMaybe<Scalars['String']['input']>;
  joinMe_refresh_token?: InputMaybe<Scalars['String']['input']>;
  last_demo_transferred_at?: InputMaybe<Scalars['DateTime']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutUserInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryCreateNestedManyWithoutUserInput>;
  lead_intents?: InputMaybe<LeadIntentCreateNestedManyWithoutUserInput>;
  lead_source_filter?: InputMaybe<Scalars['Boolean']['input']>;
  lead_source_white_list_array?: InputMaybe<UserCreatelead_Source_White_List_ArrayInput>;
  lead_source_white_list_assign_type?: InputMaybe<AssignType>;
  leads_last_called?: InputMaybe<LeadCreateNestedManyWithoutLast_Call_Result_RepInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusCreateNestedManyWithoutUserInput>;
  manager_id?: InputMaybe<Scalars['String']['input']>;
  note_item?: InputMaybe<NoteItemCreateNestedManyWithoutUserInput>;
  notifications?: InputMaybe<NotificationCreateNestedManyWithoutUserInput>;
  organization: OrganizationCreateNestedOneWithoutUsersInput;
  owned_leads?: InputMaybe<LeadCreateNestedManyWithoutRepInput>;
  panda_doc_access?: InputMaybe<Scalars['Boolean']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  payment_settings?: InputMaybe<OrganizationPaymentSettingsCreateNestedManyWithoutCreated_ByInput>;
  payment_settings_updated?: InputMaybe<OrganizationPaymentSettingsCreateNestedManyWithoutLast_Updated_ByInput>;
  phone_number?: InputMaybe<Scalars['String']['input']>;
  post?: InputMaybe<PostCreateNestedManyWithoutAuthorInput>;
  ProductsCreated?: InputMaybe<ProductChangeLogCreateNestedManyWithoutUpdated_ByInput>;
  profile_image?: InputMaybe<Scalars['String']['input']>;
  receive_transfer_attempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutTo_UserInput>;
  receiver_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutReceiver_UserInput>;
  regions_white_list_array?: InputMaybe<UserCreateregions_White_List_ArrayInput>;
  regions_white_list_assign_type?: InputMaybe<AssignType>;
  rep_scores?: InputMaybe<Scalars['Float']['input']>;
  role?: InputMaybe<Role>;
  routings?: InputMaybe<RoutingCreateNestedManyWithoutRepInput>;
  sale_config?: InputMaybe<SaleConfigCreateNestedManyWithoutCreated_ByInput>;
  sales_team_column_options?: InputMaybe<UserCreatesales_Team_Column_OptionsInput>;
  schedule_items?: InputMaybe<ScheduleItemCreateNestedManyWithoutUserInput>;
  send_transfer_attempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutFrom_UserInput>;
  sender_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutSender_UserInput>;
  sequence_entry_criteria_id?: InputMaybe<Scalars['String']['input']>;
  SequenceActionLog?: InputMaybe<StepActionLogCreateNestedManyWithoutRepInput>;
  sequenceDashboardView?: InputMaybe<SequenceDashboardViewCreateNestedManyWithoutOwned_ByInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutUserInput>;
  Sequences_created?: InputMaybe<SequenceCreateNestedManyWithoutCreated_ByInput>;
  Sequences_modified_by_user?: InputMaybe<SequenceCreateNestedManyWithoutLast_Updated_ByInput>;
  session_token?: InputMaybe<Scalars['String']['input']>;
  set_user_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutSet_UserInput>;
  set_user_stat?: InputMaybe<StatLeadAggregationCreateNestedManyWithoutSet_UserInput>;
  sf_user_id?: InputMaybe<Scalars['String']['input']>;
  SmsItems?: InputMaybe<SmsItemCreateNestedManyWithoutRepInput>;
  SnoozeTracking?: InputMaybe<SnoozeTrackingCreateNestedManyWithoutRepInput>;
  status?: InputMaybe<Status>;
  statusHistory?: InputMaybe<UserStatusHistoryCreateNestedManyWithoutUserInput>;
  sub_industry_white_list_array?: InputMaybe<UserCreatesub_Industry_White_List_ArrayInput>;
  sub_industry_white_list_assign_type?: InputMaybe<AssignType>;
  support_rep?: InputMaybe<Scalars['Boolean']['input']>;
  taking_demos?: InputMaybe<Scalars['Boolean']['input']>;
  team?: InputMaybe<TeamCreateNestedOneWithoutMembersInput>;
  Template?: InputMaybe<TemplateCreateNestedManyWithoutLast_Modified_ByInput>;
  TemplateVersion?: InputMaybe<TemplateVersionCreateNestedManyWithoutCreated_ByInput>;
  timekit_id?: InputMaybe<Scalars['String']['input']>;
  timekit_type?: InputMaybe<Timekittype>;
  timeOffRequest?: InputMaybe<TimeOffRequestCreateNestedManyWithoutRequesting_UserInput>;
  timezone_raw?: InputMaybe<Scalars['String']['input']>;
  transfer_type?: InputMaybe<TransferType>;
  transfer_user_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutTransfer_UserInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user_custom_availability?: InputMaybe<AvailabilityCreateNestedManyWithoutUserInput>;
  user_past_tokens?: InputMaybe<UserPastTokensCreateNestedManyWithoutUserInput>;
  visible_all_leads_sm?: InputMaybe<Scalars['Boolean']['input']>;
  visible_call_report_columns?: InputMaybe<UserCreatevisible_Call_Report_ColumnsInput>;
  visible_call_report_event_columns?: InputMaybe<UserCreatevisible_Call_Report_Event_ColumnsInput>;
  visible_call_report_pipeline_columns?: InputMaybe<UserCreatevisible_Call_Report_Pipeline_ColumnsInput>;
  visible_my_leads_columns?: InputMaybe<UserCreatevisible_My_Leads_ColumnsInput>;
  voicemail_url?: InputMaybe<Scalars['String']['input']>;
};

export type UserCreateWithoutSale_ConfigInput = {
  ai_user?: InputMaybe<Scalars['Boolean']['input']>;
  alternate_email?: InputMaybe<Scalars['String']['input']>;
  always_show_calendar_options?: InputMaybe<Scalars['Boolean']['input']>;
  associate_owner_rep_history?: InputMaybe<AssociationHistoryCreateNestedManyWithoutRepInput>;
  associate_user_history?: InputMaybe<AssociationHistoryCreateNestedManyWithoutUserInput>;
  auto_transfer_selection_receive_attempts?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutFrom_UserInput>;
  auto_transfer_selection_send_attempts?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutTo_UserInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptCreateNestedManyWithoutUserInput>;
  callSharingAsCoach?: InputMaybe<CallSharingCreateNestedManyWithoutCoachInput>;
  callSharingAsUser?: InputMaybe<CallSharingCreateNestedManyWithoutReceiver_UserInput>;
  channel?: InputMaybe<Channelfilter>;
  close_rate?: InputMaybe<Scalars['Float']['input']>;
  close_user_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutClose_UserInput>;
  close_user_stat?: InputMaybe<StatLeadAggregationCreateNestedManyWithoutClose_UserInput>;
  commissions?: InputMaybe<CommissionCreateNestedManyWithoutUserInput>;
  contact_requests?: InputMaybe<ContactRequestCreateNestedManyWithoutRequesting_UserInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  current_sequence_view?: InputMaybe<SequenceDashboardViewCreateNestedOneWithoutUserInput>;
  current_team_start_date?: InputMaybe<Scalars['DateTime']['input']>;
  custom_field_white_list_array?: InputMaybe<UserCustomFieldFilterCreateNestedManyWithoutUserInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemCreateNestedManyWithoutUserInput>;
  daily_lead_claim?: InputMaybe<Scalars['Int']['input']>;
  dashboard_2_access?: InputMaybe<Scalars['Boolean']['input']>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewCreateNestedManyWithoutUserInput>;
  default_audio_input?: InputMaybe<Scalars['String']['input']>;
  default_audio_output?: InputMaybe<Scalars['String']['input']>;
  default_availability?: InputMaybe<Scalars['Boolean']['input']>;
  deleted_at?: InputMaybe<Scalars['DateTime']['input']>;
  deprecate_old_session?: InputMaybe<Scalars['Boolean']['input']>;
  email: Scalars['String']['input'];
  EmailSyncRecord?: InputMaybe<EmailSyncRecordCreateNestedManyWithoutUserInput>;
  favorite_leads?: InputMaybe<LeadCreateNestedManyWithoutFavorited_By_UsersInput>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  folder_permissions?: InputMaybe<FolderPermissionCreateNestedManyWithoutUserInput>;
  folders?: InputMaybe<FolderCreateNestedManyWithoutOwnerInput>;
  from_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutOld_UserInput>;
  GlobalRouting?: InputMaybe<GlobalRepRoutingCreateNestedManyWithoutRepInput>;
  google_refresh_token?: InputMaybe<Scalars['String']['input']>;
  holds_per_prospect_vs_held_demo_per_dial?: InputMaybe<Scalars['Float']['input']>;
  hook_subscriptions?: InputMaybe<WebHookSubscriptionCreateNestedManyWithoutUserInput>;
  hubspot_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  idle_owned_leads?: InputMaybe<LeadCreateNestedManyWithoutSecondary_RepsInput>;
  industry_filter?: InputMaybe<Scalars['Boolean']['input']>;
  industry_white_list_array?: InputMaybe<UserCreateindustry_White_List_ArrayInput>;
  industry_white_list_assign_type?: InputMaybe<AssignType>;
  joinMe_access_token?: InputMaybe<Scalars['String']['input']>;
  joinMe_refresh_token?: InputMaybe<Scalars['String']['input']>;
  last_demo_transferred_at?: InputMaybe<Scalars['DateTime']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutUserInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryCreateNestedManyWithoutUserInput>;
  lead_intents?: InputMaybe<LeadIntentCreateNestedManyWithoutUserInput>;
  lead_source_filter?: InputMaybe<Scalars['Boolean']['input']>;
  lead_source_white_list_array?: InputMaybe<UserCreatelead_Source_White_List_ArrayInput>;
  lead_source_white_list_assign_type?: InputMaybe<AssignType>;
  leads_last_called?: InputMaybe<LeadCreateNestedManyWithoutLast_Call_Result_RepInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusCreateNestedManyWithoutUserInput>;
  manager_id?: InputMaybe<Scalars['String']['input']>;
  note_item?: InputMaybe<NoteItemCreateNestedManyWithoutUserInput>;
  notifications?: InputMaybe<NotificationCreateNestedManyWithoutUserInput>;
  organization: OrganizationCreateNestedOneWithoutUsersInput;
  owned_leads?: InputMaybe<LeadCreateNestedManyWithoutRepInput>;
  panda_doc_access?: InputMaybe<Scalars['Boolean']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  payment_settings?: InputMaybe<OrganizationPaymentSettingsCreateNestedManyWithoutCreated_ByInput>;
  payment_settings_updated?: InputMaybe<OrganizationPaymentSettingsCreateNestedManyWithoutLast_Updated_ByInput>;
  phone_number?: InputMaybe<Scalars['String']['input']>;
  post?: InputMaybe<PostCreateNestedManyWithoutAuthorInput>;
  ProductsCreated?: InputMaybe<ProductChangeLogCreateNestedManyWithoutUpdated_ByInput>;
  profile_image?: InputMaybe<Scalars['String']['input']>;
  receive_transfer_attempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutTo_UserInput>;
  receiver_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutReceiver_UserInput>;
  regions_white_list_array?: InputMaybe<UserCreateregions_White_List_ArrayInput>;
  regions_white_list_assign_type?: InputMaybe<AssignType>;
  rep_scores?: InputMaybe<Scalars['Float']['input']>;
  role?: InputMaybe<Role>;
  routings?: InputMaybe<RoutingCreateNestedManyWithoutRepInput>;
  sale_config_updated?: InputMaybe<SaleConfigCreateNestedManyWithoutLast_Updated_ByInput>;
  sales_team_column_options?: InputMaybe<UserCreatesales_Team_Column_OptionsInput>;
  schedule_items?: InputMaybe<ScheduleItemCreateNestedManyWithoutUserInput>;
  send_transfer_attempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutFrom_UserInput>;
  sender_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutSender_UserInput>;
  sequence_entry_criteria_id?: InputMaybe<Scalars['String']['input']>;
  SequenceActionLog?: InputMaybe<StepActionLogCreateNestedManyWithoutRepInput>;
  sequenceDashboardView?: InputMaybe<SequenceDashboardViewCreateNestedManyWithoutOwned_ByInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutUserInput>;
  Sequences_created?: InputMaybe<SequenceCreateNestedManyWithoutCreated_ByInput>;
  Sequences_modified_by_user?: InputMaybe<SequenceCreateNestedManyWithoutLast_Updated_ByInput>;
  session_token?: InputMaybe<Scalars['String']['input']>;
  set_user_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutSet_UserInput>;
  set_user_stat?: InputMaybe<StatLeadAggregationCreateNestedManyWithoutSet_UserInput>;
  sf_user_id?: InputMaybe<Scalars['String']['input']>;
  SmsItems?: InputMaybe<SmsItemCreateNestedManyWithoutRepInput>;
  SnoozeTracking?: InputMaybe<SnoozeTrackingCreateNestedManyWithoutRepInput>;
  status?: InputMaybe<Status>;
  statusHistory?: InputMaybe<UserStatusHistoryCreateNestedManyWithoutUserInput>;
  sub_industry_white_list_array?: InputMaybe<UserCreatesub_Industry_White_List_ArrayInput>;
  sub_industry_white_list_assign_type?: InputMaybe<AssignType>;
  support_rep?: InputMaybe<Scalars['Boolean']['input']>;
  taking_demos?: InputMaybe<Scalars['Boolean']['input']>;
  team?: InputMaybe<TeamCreateNestedOneWithoutMembersInput>;
  Template?: InputMaybe<TemplateCreateNestedManyWithoutLast_Modified_ByInput>;
  TemplateVersion?: InputMaybe<TemplateVersionCreateNestedManyWithoutCreated_ByInput>;
  timekit_id?: InputMaybe<Scalars['String']['input']>;
  timekit_type?: InputMaybe<Timekittype>;
  timeOffRequest?: InputMaybe<TimeOffRequestCreateNestedManyWithoutRequesting_UserInput>;
  timezone_raw?: InputMaybe<Scalars['String']['input']>;
  transfer_type?: InputMaybe<TransferType>;
  transfer_user_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutTransfer_UserInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user_custom_availability?: InputMaybe<AvailabilityCreateNestedManyWithoutUserInput>;
  user_past_tokens?: InputMaybe<UserPastTokensCreateNestedManyWithoutUserInput>;
  visible_all_leads_sm?: InputMaybe<Scalars['Boolean']['input']>;
  visible_call_report_columns?: InputMaybe<UserCreatevisible_Call_Report_ColumnsInput>;
  visible_call_report_event_columns?: InputMaybe<UserCreatevisible_Call_Report_Event_ColumnsInput>;
  visible_call_report_pipeline_columns?: InputMaybe<UserCreatevisible_Call_Report_Pipeline_ColumnsInput>;
  visible_my_leads_columns?: InputMaybe<UserCreatevisible_My_Leads_ColumnsInput>;
  voicemail_url?: InputMaybe<Scalars['String']['input']>;
};

export type UserCreateWithoutSchedule_ItemsInput = {
  ai_user?: InputMaybe<Scalars['Boolean']['input']>;
  alternate_email?: InputMaybe<Scalars['String']['input']>;
  always_show_calendar_options?: InputMaybe<Scalars['Boolean']['input']>;
  associate_owner_rep_history?: InputMaybe<AssociationHistoryCreateNestedManyWithoutRepInput>;
  associate_user_history?: InputMaybe<AssociationHistoryCreateNestedManyWithoutUserInput>;
  auto_transfer_selection_receive_attempts?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutFrom_UserInput>;
  auto_transfer_selection_send_attempts?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutTo_UserInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptCreateNestedManyWithoutUserInput>;
  callSharingAsCoach?: InputMaybe<CallSharingCreateNestedManyWithoutCoachInput>;
  callSharingAsUser?: InputMaybe<CallSharingCreateNestedManyWithoutReceiver_UserInput>;
  channel?: InputMaybe<Channelfilter>;
  close_rate?: InputMaybe<Scalars['Float']['input']>;
  close_user_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutClose_UserInput>;
  close_user_stat?: InputMaybe<StatLeadAggregationCreateNestedManyWithoutClose_UserInput>;
  commissions?: InputMaybe<CommissionCreateNestedManyWithoutUserInput>;
  contact_requests?: InputMaybe<ContactRequestCreateNestedManyWithoutRequesting_UserInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  current_sequence_view?: InputMaybe<SequenceDashboardViewCreateNestedOneWithoutUserInput>;
  current_team_start_date?: InputMaybe<Scalars['DateTime']['input']>;
  custom_field_white_list_array?: InputMaybe<UserCustomFieldFilterCreateNestedManyWithoutUserInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemCreateNestedManyWithoutUserInput>;
  daily_lead_claim?: InputMaybe<Scalars['Int']['input']>;
  dashboard_2_access?: InputMaybe<Scalars['Boolean']['input']>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewCreateNestedManyWithoutUserInput>;
  default_audio_input?: InputMaybe<Scalars['String']['input']>;
  default_audio_output?: InputMaybe<Scalars['String']['input']>;
  default_availability?: InputMaybe<Scalars['Boolean']['input']>;
  deleted_at?: InputMaybe<Scalars['DateTime']['input']>;
  deprecate_old_session?: InputMaybe<Scalars['Boolean']['input']>;
  email: Scalars['String']['input'];
  EmailSyncRecord?: InputMaybe<EmailSyncRecordCreateNestedManyWithoutUserInput>;
  favorite_leads?: InputMaybe<LeadCreateNestedManyWithoutFavorited_By_UsersInput>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  folder_permissions?: InputMaybe<FolderPermissionCreateNestedManyWithoutUserInput>;
  folders?: InputMaybe<FolderCreateNestedManyWithoutOwnerInput>;
  from_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutOld_UserInput>;
  GlobalRouting?: InputMaybe<GlobalRepRoutingCreateNestedManyWithoutRepInput>;
  google_refresh_token?: InputMaybe<Scalars['String']['input']>;
  holds_per_prospect_vs_held_demo_per_dial?: InputMaybe<Scalars['Float']['input']>;
  hook_subscriptions?: InputMaybe<WebHookSubscriptionCreateNestedManyWithoutUserInput>;
  hubspot_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  idle_owned_leads?: InputMaybe<LeadCreateNestedManyWithoutSecondary_RepsInput>;
  industry_filter?: InputMaybe<Scalars['Boolean']['input']>;
  industry_white_list_array?: InputMaybe<UserCreateindustry_White_List_ArrayInput>;
  industry_white_list_assign_type?: InputMaybe<AssignType>;
  joinMe_access_token?: InputMaybe<Scalars['String']['input']>;
  joinMe_refresh_token?: InputMaybe<Scalars['String']['input']>;
  last_demo_transferred_at?: InputMaybe<Scalars['DateTime']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutUserInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryCreateNestedManyWithoutUserInput>;
  lead_intents?: InputMaybe<LeadIntentCreateNestedManyWithoutUserInput>;
  lead_source_filter?: InputMaybe<Scalars['Boolean']['input']>;
  lead_source_white_list_array?: InputMaybe<UserCreatelead_Source_White_List_ArrayInput>;
  lead_source_white_list_assign_type?: InputMaybe<AssignType>;
  leads_last_called?: InputMaybe<LeadCreateNestedManyWithoutLast_Call_Result_RepInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusCreateNestedManyWithoutUserInput>;
  manager_id?: InputMaybe<Scalars['String']['input']>;
  note_item?: InputMaybe<NoteItemCreateNestedManyWithoutUserInput>;
  notifications?: InputMaybe<NotificationCreateNestedManyWithoutUserInput>;
  organization: OrganizationCreateNestedOneWithoutUsersInput;
  owned_leads?: InputMaybe<LeadCreateNestedManyWithoutRepInput>;
  panda_doc_access?: InputMaybe<Scalars['Boolean']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  payment_settings?: InputMaybe<OrganizationPaymentSettingsCreateNestedManyWithoutCreated_ByInput>;
  payment_settings_updated?: InputMaybe<OrganizationPaymentSettingsCreateNestedManyWithoutLast_Updated_ByInput>;
  phone_number?: InputMaybe<Scalars['String']['input']>;
  post?: InputMaybe<PostCreateNestedManyWithoutAuthorInput>;
  ProductsCreated?: InputMaybe<ProductChangeLogCreateNestedManyWithoutUpdated_ByInput>;
  profile_image?: InputMaybe<Scalars['String']['input']>;
  receive_transfer_attempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutTo_UserInput>;
  receiver_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutReceiver_UserInput>;
  regions_white_list_array?: InputMaybe<UserCreateregions_White_List_ArrayInput>;
  regions_white_list_assign_type?: InputMaybe<AssignType>;
  rep_scores?: InputMaybe<Scalars['Float']['input']>;
  role?: InputMaybe<Role>;
  routings?: InputMaybe<RoutingCreateNestedManyWithoutRepInput>;
  sale_config?: InputMaybe<SaleConfigCreateNestedManyWithoutCreated_ByInput>;
  sale_config_updated?: InputMaybe<SaleConfigCreateNestedManyWithoutLast_Updated_ByInput>;
  sales_team_column_options?: InputMaybe<UserCreatesales_Team_Column_OptionsInput>;
  send_transfer_attempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutFrom_UserInput>;
  sender_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutSender_UserInput>;
  sequence_entry_criteria_id?: InputMaybe<Scalars['String']['input']>;
  SequenceActionLog?: InputMaybe<StepActionLogCreateNestedManyWithoutRepInput>;
  sequenceDashboardView?: InputMaybe<SequenceDashboardViewCreateNestedManyWithoutOwned_ByInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutUserInput>;
  Sequences_created?: InputMaybe<SequenceCreateNestedManyWithoutCreated_ByInput>;
  Sequences_modified_by_user?: InputMaybe<SequenceCreateNestedManyWithoutLast_Updated_ByInput>;
  session_token?: InputMaybe<Scalars['String']['input']>;
  set_user_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutSet_UserInput>;
  set_user_stat?: InputMaybe<StatLeadAggregationCreateNestedManyWithoutSet_UserInput>;
  sf_user_id?: InputMaybe<Scalars['String']['input']>;
  SmsItems?: InputMaybe<SmsItemCreateNestedManyWithoutRepInput>;
  SnoozeTracking?: InputMaybe<SnoozeTrackingCreateNestedManyWithoutRepInput>;
  status?: InputMaybe<Status>;
  statusHistory?: InputMaybe<UserStatusHistoryCreateNestedManyWithoutUserInput>;
  sub_industry_white_list_array?: InputMaybe<UserCreatesub_Industry_White_List_ArrayInput>;
  sub_industry_white_list_assign_type?: InputMaybe<AssignType>;
  support_rep?: InputMaybe<Scalars['Boolean']['input']>;
  taking_demos?: InputMaybe<Scalars['Boolean']['input']>;
  team?: InputMaybe<TeamCreateNestedOneWithoutMembersInput>;
  Template?: InputMaybe<TemplateCreateNestedManyWithoutLast_Modified_ByInput>;
  TemplateVersion?: InputMaybe<TemplateVersionCreateNestedManyWithoutCreated_ByInput>;
  timekit_id?: InputMaybe<Scalars['String']['input']>;
  timekit_type?: InputMaybe<Timekittype>;
  timeOffRequest?: InputMaybe<TimeOffRequestCreateNestedManyWithoutRequesting_UserInput>;
  timezone_raw?: InputMaybe<Scalars['String']['input']>;
  transfer_type?: InputMaybe<TransferType>;
  transfer_user_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutTransfer_UserInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user_custom_availability?: InputMaybe<AvailabilityCreateNestedManyWithoutUserInput>;
  user_past_tokens?: InputMaybe<UserPastTokensCreateNestedManyWithoutUserInput>;
  visible_all_leads_sm?: InputMaybe<Scalars['Boolean']['input']>;
  visible_call_report_columns?: InputMaybe<UserCreatevisible_Call_Report_ColumnsInput>;
  visible_call_report_event_columns?: InputMaybe<UserCreatevisible_Call_Report_Event_ColumnsInput>;
  visible_call_report_pipeline_columns?: InputMaybe<UserCreatevisible_Call_Report_Pipeline_ColumnsInput>;
  visible_my_leads_columns?: InputMaybe<UserCreatevisible_My_Leads_ColumnsInput>;
  voicemail_url?: InputMaybe<Scalars['String']['input']>;
};

export type UserCreateWithoutSend_Transfer_AttemptsInput = {
  ai_user?: InputMaybe<Scalars['Boolean']['input']>;
  alternate_email?: InputMaybe<Scalars['String']['input']>;
  always_show_calendar_options?: InputMaybe<Scalars['Boolean']['input']>;
  associate_owner_rep_history?: InputMaybe<AssociationHistoryCreateNestedManyWithoutRepInput>;
  associate_user_history?: InputMaybe<AssociationHistoryCreateNestedManyWithoutUserInput>;
  auto_transfer_selection_receive_attempts?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutFrom_UserInput>;
  auto_transfer_selection_send_attempts?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutTo_UserInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptCreateNestedManyWithoutUserInput>;
  callSharingAsCoach?: InputMaybe<CallSharingCreateNestedManyWithoutCoachInput>;
  callSharingAsUser?: InputMaybe<CallSharingCreateNestedManyWithoutReceiver_UserInput>;
  channel?: InputMaybe<Channelfilter>;
  close_rate?: InputMaybe<Scalars['Float']['input']>;
  close_user_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutClose_UserInput>;
  close_user_stat?: InputMaybe<StatLeadAggregationCreateNestedManyWithoutClose_UserInput>;
  commissions?: InputMaybe<CommissionCreateNestedManyWithoutUserInput>;
  contact_requests?: InputMaybe<ContactRequestCreateNestedManyWithoutRequesting_UserInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  current_sequence_view?: InputMaybe<SequenceDashboardViewCreateNestedOneWithoutUserInput>;
  current_team_start_date?: InputMaybe<Scalars['DateTime']['input']>;
  custom_field_white_list_array?: InputMaybe<UserCustomFieldFilterCreateNestedManyWithoutUserInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemCreateNestedManyWithoutUserInput>;
  daily_lead_claim?: InputMaybe<Scalars['Int']['input']>;
  dashboard_2_access?: InputMaybe<Scalars['Boolean']['input']>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewCreateNestedManyWithoutUserInput>;
  default_audio_input?: InputMaybe<Scalars['String']['input']>;
  default_audio_output?: InputMaybe<Scalars['String']['input']>;
  default_availability?: InputMaybe<Scalars['Boolean']['input']>;
  deleted_at?: InputMaybe<Scalars['DateTime']['input']>;
  deprecate_old_session?: InputMaybe<Scalars['Boolean']['input']>;
  email: Scalars['String']['input'];
  EmailSyncRecord?: InputMaybe<EmailSyncRecordCreateNestedManyWithoutUserInput>;
  favorite_leads?: InputMaybe<LeadCreateNestedManyWithoutFavorited_By_UsersInput>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  folder_permissions?: InputMaybe<FolderPermissionCreateNestedManyWithoutUserInput>;
  folders?: InputMaybe<FolderCreateNestedManyWithoutOwnerInput>;
  from_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutOld_UserInput>;
  GlobalRouting?: InputMaybe<GlobalRepRoutingCreateNestedManyWithoutRepInput>;
  google_refresh_token?: InputMaybe<Scalars['String']['input']>;
  holds_per_prospect_vs_held_demo_per_dial?: InputMaybe<Scalars['Float']['input']>;
  hook_subscriptions?: InputMaybe<WebHookSubscriptionCreateNestedManyWithoutUserInput>;
  hubspot_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  idle_owned_leads?: InputMaybe<LeadCreateNestedManyWithoutSecondary_RepsInput>;
  industry_filter?: InputMaybe<Scalars['Boolean']['input']>;
  industry_white_list_array?: InputMaybe<UserCreateindustry_White_List_ArrayInput>;
  industry_white_list_assign_type?: InputMaybe<AssignType>;
  joinMe_access_token?: InputMaybe<Scalars['String']['input']>;
  joinMe_refresh_token?: InputMaybe<Scalars['String']['input']>;
  last_demo_transferred_at?: InputMaybe<Scalars['DateTime']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutUserInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryCreateNestedManyWithoutUserInput>;
  lead_intents?: InputMaybe<LeadIntentCreateNestedManyWithoutUserInput>;
  lead_source_filter?: InputMaybe<Scalars['Boolean']['input']>;
  lead_source_white_list_array?: InputMaybe<UserCreatelead_Source_White_List_ArrayInput>;
  lead_source_white_list_assign_type?: InputMaybe<AssignType>;
  leads_last_called?: InputMaybe<LeadCreateNestedManyWithoutLast_Call_Result_RepInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusCreateNestedManyWithoutUserInput>;
  manager_id?: InputMaybe<Scalars['String']['input']>;
  note_item?: InputMaybe<NoteItemCreateNestedManyWithoutUserInput>;
  notifications?: InputMaybe<NotificationCreateNestedManyWithoutUserInput>;
  organization: OrganizationCreateNestedOneWithoutUsersInput;
  owned_leads?: InputMaybe<LeadCreateNestedManyWithoutRepInput>;
  panda_doc_access?: InputMaybe<Scalars['Boolean']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  payment_settings?: InputMaybe<OrganizationPaymentSettingsCreateNestedManyWithoutCreated_ByInput>;
  payment_settings_updated?: InputMaybe<OrganizationPaymentSettingsCreateNestedManyWithoutLast_Updated_ByInput>;
  phone_number?: InputMaybe<Scalars['String']['input']>;
  post?: InputMaybe<PostCreateNestedManyWithoutAuthorInput>;
  ProductsCreated?: InputMaybe<ProductChangeLogCreateNestedManyWithoutUpdated_ByInput>;
  profile_image?: InputMaybe<Scalars['String']['input']>;
  receive_transfer_attempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutTo_UserInput>;
  receiver_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutReceiver_UserInput>;
  regions_white_list_array?: InputMaybe<UserCreateregions_White_List_ArrayInput>;
  regions_white_list_assign_type?: InputMaybe<AssignType>;
  rep_scores?: InputMaybe<Scalars['Float']['input']>;
  role?: InputMaybe<Role>;
  routings?: InputMaybe<RoutingCreateNestedManyWithoutRepInput>;
  sale_config?: InputMaybe<SaleConfigCreateNestedManyWithoutCreated_ByInput>;
  sale_config_updated?: InputMaybe<SaleConfigCreateNestedManyWithoutLast_Updated_ByInput>;
  sales_team_column_options?: InputMaybe<UserCreatesales_Team_Column_OptionsInput>;
  schedule_items?: InputMaybe<ScheduleItemCreateNestedManyWithoutUserInput>;
  sender_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutSender_UserInput>;
  sequence_entry_criteria_id?: InputMaybe<Scalars['String']['input']>;
  SequenceActionLog?: InputMaybe<StepActionLogCreateNestedManyWithoutRepInput>;
  sequenceDashboardView?: InputMaybe<SequenceDashboardViewCreateNestedManyWithoutOwned_ByInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutUserInput>;
  Sequences_created?: InputMaybe<SequenceCreateNestedManyWithoutCreated_ByInput>;
  Sequences_modified_by_user?: InputMaybe<SequenceCreateNestedManyWithoutLast_Updated_ByInput>;
  session_token?: InputMaybe<Scalars['String']['input']>;
  set_user_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutSet_UserInput>;
  set_user_stat?: InputMaybe<StatLeadAggregationCreateNestedManyWithoutSet_UserInput>;
  sf_user_id?: InputMaybe<Scalars['String']['input']>;
  SmsItems?: InputMaybe<SmsItemCreateNestedManyWithoutRepInput>;
  SnoozeTracking?: InputMaybe<SnoozeTrackingCreateNestedManyWithoutRepInput>;
  status?: InputMaybe<Status>;
  statusHistory?: InputMaybe<UserStatusHistoryCreateNestedManyWithoutUserInput>;
  sub_industry_white_list_array?: InputMaybe<UserCreatesub_Industry_White_List_ArrayInput>;
  sub_industry_white_list_assign_type?: InputMaybe<AssignType>;
  support_rep?: InputMaybe<Scalars['Boolean']['input']>;
  taking_demos?: InputMaybe<Scalars['Boolean']['input']>;
  team?: InputMaybe<TeamCreateNestedOneWithoutMembersInput>;
  Template?: InputMaybe<TemplateCreateNestedManyWithoutLast_Modified_ByInput>;
  TemplateVersion?: InputMaybe<TemplateVersionCreateNestedManyWithoutCreated_ByInput>;
  timekit_id?: InputMaybe<Scalars['String']['input']>;
  timekit_type?: InputMaybe<Timekittype>;
  timeOffRequest?: InputMaybe<TimeOffRequestCreateNestedManyWithoutRequesting_UserInput>;
  timezone_raw?: InputMaybe<Scalars['String']['input']>;
  transfer_type?: InputMaybe<TransferType>;
  transfer_user_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutTransfer_UserInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user_custom_availability?: InputMaybe<AvailabilityCreateNestedManyWithoutUserInput>;
  user_past_tokens?: InputMaybe<UserPastTokensCreateNestedManyWithoutUserInput>;
  visible_all_leads_sm?: InputMaybe<Scalars['Boolean']['input']>;
  visible_call_report_columns?: InputMaybe<UserCreatevisible_Call_Report_ColumnsInput>;
  visible_call_report_event_columns?: InputMaybe<UserCreatevisible_Call_Report_Event_ColumnsInput>;
  visible_call_report_pipeline_columns?: InputMaybe<UserCreatevisible_Call_Report_Pipeline_ColumnsInput>;
  visible_my_leads_columns?: InputMaybe<UserCreatevisible_My_Leads_ColumnsInput>;
  voicemail_url?: InputMaybe<Scalars['String']['input']>;
};

export type UserCreateWithoutSender_Assignment_HistoryInput = {
  ai_user?: InputMaybe<Scalars['Boolean']['input']>;
  alternate_email?: InputMaybe<Scalars['String']['input']>;
  always_show_calendar_options?: InputMaybe<Scalars['Boolean']['input']>;
  associate_owner_rep_history?: InputMaybe<AssociationHistoryCreateNestedManyWithoutRepInput>;
  associate_user_history?: InputMaybe<AssociationHistoryCreateNestedManyWithoutUserInput>;
  auto_transfer_selection_receive_attempts?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutFrom_UserInput>;
  auto_transfer_selection_send_attempts?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutTo_UserInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptCreateNestedManyWithoutUserInput>;
  callSharingAsCoach?: InputMaybe<CallSharingCreateNestedManyWithoutCoachInput>;
  callSharingAsUser?: InputMaybe<CallSharingCreateNestedManyWithoutReceiver_UserInput>;
  channel?: InputMaybe<Channelfilter>;
  close_rate?: InputMaybe<Scalars['Float']['input']>;
  close_user_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutClose_UserInput>;
  close_user_stat?: InputMaybe<StatLeadAggregationCreateNestedManyWithoutClose_UserInput>;
  commissions?: InputMaybe<CommissionCreateNestedManyWithoutUserInput>;
  contact_requests?: InputMaybe<ContactRequestCreateNestedManyWithoutRequesting_UserInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  current_sequence_view?: InputMaybe<SequenceDashboardViewCreateNestedOneWithoutUserInput>;
  current_team_start_date?: InputMaybe<Scalars['DateTime']['input']>;
  custom_field_white_list_array?: InputMaybe<UserCustomFieldFilterCreateNestedManyWithoutUserInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemCreateNestedManyWithoutUserInput>;
  daily_lead_claim?: InputMaybe<Scalars['Int']['input']>;
  dashboard_2_access?: InputMaybe<Scalars['Boolean']['input']>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewCreateNestedManyWithoutUserInput>;
  default_audio_input?: InputMaybe<Scalars['String']['input']>;
  default_audio_output?: InputMaybe<Scalars['String']['input']>;
  default_availability?: InputMaybe<Scalars['Boolean']['input']>;
  deleted_at?: InputMaybe<Scalars['DateTime']['input']>;
  deprecate_old_session?: InputMaybe<Scalars['Boolean']['input']>;
  email: Scalars['String']['input'];
  EmailSyncRecord?: InputMaybe<EmailSyncRecordCreateNestedManyWithoutUserInput>;
  favorite_leads?: InputMaybe<LeadCreateNestedManyWithoutFavorited_By_UsersInput>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  folder_permissions?: InputMaybe<FolderPermissionCreateNestedManyWithoutUserInput>;
  folders?: InputMaybe<FolderCreateNestedManyWithoutOwnerInput>;
  from_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutOld_UserInput>;
  GlobalRouting?: InputMaybe<GlobalRepRoutingCreateNestedManyWithoutRepInput>;
  google_refresh_token?: InputMaybe<Scalars['String']['input']>;
  holds_per_prospect_vs_held_demo_per_dial?: InputMaybe<Scalars['Float']['input']>;
  hook_subscriptions?: InputMaybe<WebHookSubscriptionCreateNestedManyWithoutUserInput>;
  hubspot_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  idle_owned_leads?: InputMaybe<LeadCreateNestedManyWithoutSecondary_RepsInput>;
  industry_filter?: InputMaybe<Scalars['Boolean']['input']>;
  industry_white_list_array?: InputMaybe<UserCreateindustry_White_List_ArrayInput>;
  industry_white_list_assign_type?: InputMaybe<AssignType>;
  joinMe_access_token?: InputMaybe<Scalars['String']['input']>;
  joinMe_refresh_token?: InputMaybe<Scalars['String']['input']>;
  last_demo_transferred_at?: InputMaybe<Scalars['DateTime']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutUserInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryCreateNestedManyWithoutUserInput>;
  lead_intents?: InputMaybe<LeadIntentCreateNestedManyWithoutUserInput>;
  lead_source_filter?: InputMaybe<Scalars['Boolean']['input']>;
  lead_source_white_list_array?: InputMaybe<UserCreatelead_Source_White_List_ArrayInput>;
  lead_source_white_list_assign_type?: InputMaybe<AssignType>;
  leads_last_called?: InputMaybe<LeadCreateNestedManyWithoutLast_Call_Result_RepInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusCreateNestedManyWithoutUserInput>;
  manager_id?: InputMaybe<Scalars['String']['input']>;
  note_item?: InputMaybe<NoteItemCreateNestedManyWithoutUserInput>;
  notifications?: InputMaybe<NotificationCreateNestedManyWithoutUserInput>;
  organization: OrganizationCreateNestedOneWithoutUsersInput;
  owned_leads?: InputMaybe<LeadCreateNestedManyWithoutRepInput>;
  panda_doc_access?: InputMaybe<Scalars['Boolean']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  payment_settings?: InputMaybe<OrganizationPaymentSettingsCreateNestedManyWithoutCreated_ByInput>;
  payment_settings_updated?: InputMaybe<OrganizationPaymentSettingsCreateNestedManyWithoutLast_Updated_ByInput>;
  phone_number?: InputMaybe<Scalars['String']['input']>;
  post?: InputMaybe<PostCreateNestedManyWithoutAuthorInput>;
  ProductsCreated?: InputMaybe<ProductChangeLogCreateNestedManyWithoutUpdated_ByInput>;
  profile_image?: InputMaybe<Scalars['String']['input']>;
  receive_transfer_attempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutTo_UserInput>;
  receiver_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutReceiver_UserInput>;
  regions_white_list_array?: InputMaybe<UserCreateregions_White_List_ArrayInput>;
  regions_white_list_assign_type?: InputMaybe<AssignType>;
  rep_scores?: InputMaybe<Scalars['Float']['input']>;
  role?: InputMaybe<Role>;
  routings?: InputMaybe<RoutingCreateNestedManyWithoutRepInput>;
  sale_config?: InputMaybe<SaleConfigCreateNestedManyWithoutCreated_ByInput>;
  sale_config_updated?: InputMaybe<SaleConfigCreateNestedManyWithoutLast_Updated_ByInput>;
  sales_team_column_options?: InputMaybe<UserCreatesales_Team_Column_OptionsInput>;
  schedule_items?: InputMaybe<ScheduleItemCreateNestedManyWithoutUserInput>;
  send_transfer_attempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutFrom_UserInput>;
  sequence_entry_criteria_id?: InputMaybe<Scalars['String']['input']>;
  SequenceActionLog?: InputMaybe<StepActionLogCreateNestedManyWithoutRepInput>;
  sequenceDashboardView?: InputMaybe<SequenceDashboardViewCreateNestedManyWithoutOwned_ByInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutUserInput>;
  Sequences_created?: InputMaybe<SequenceCreateNestedManyWithoutCreated_ByInput>;
  Sequences_modified_by_user?: InputMaybe<SequenceCreateNestedManyWithoutLast_Updated_ByInput>;
  session_token?: InputMaybe<Scalars['String']['input']>;
  set_user_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutSet_UserInput>;
  set_user_stat?: InputMaybe<StatLeadAggregationCreateNestedManyWithoutSet_UserInput>;
  sf_user_id?: InputMaybe<Scalars['String']['input']>;
  SmsItems?: InputMaybe<SmsItemCreateNestedManyWithoutRepInput>;
  SnoozeTracking?: InputMaybe<SnoozeTrackingCreateNestedManyWithoutRepInput>;
  status?: InputMaybe<Status>;
  statusHistory?: InputMaybe<UserStatusHistoryCreateNestedManyWithoutUserInput>;
  sub_industry_white_list_array?: InputMaybe<UserCreatesub_Industry_White_List_ArrayInput>;
  sub_industry_white_list_assign_type?: InputMaybe<AssignType>;
  support_rep?: InputMaybe<Scalars['Boolean']['input']>;
  taking_demos?: InputMaybe<Scalars['Boolean']['input']>;
  team?: InputMaybe<TeamCreateNestedOneWithoutMembersInput>;
  Template?: InputMaybe<TemplateCreateNestedManyWithoutLast_Modified_ByInput>;
  TemplateVersion?: InputMaybe<TemplateVersionCreateNestedManyWithoutCreated_ByInput>;
  timekit_id?: InputMaybe<Scalars['String']['input']>;
  timekit_type?: InputMaybe<Timekittype>;
  timeOffRequest?: InputMaybe<TimeOffRequestCreateNestedManyWithoutRequesting_UserInput>;
  timezone_raw?: InputMaybe<Scalars['String']['input']>;
  transfer_type?: InputMaybe<TransferType>;
  transfer_user_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutTransfer_UserInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user_custom_availability?: InputMaybe<AvailabilityCreateNestedManyWithoutUserInput>;
  user_past_tokens?: InputMaybe<UserPastTokensCreateNestedManyWithoutUserInput>;
  visible_all_leads_sm?: InputMaybe<Scalars['Boolean']['input']>;
  visible_call_report_columns?: InputMaybe<UserCreatevisible_Call_Report_ColumnsInput>;
  visible_call_report_event_columns?: InputMaybe<UserCreatevisible_Call_Report_Event_ColumnsInput>;
  visible_call_report_pipeline_columns?: InputMaybe<UserCreatevisible_Call_Report_Pipeline_ColumnsInput>;
  visible_my_leads_columns?: InputMaybe<UserCreatevisible_My_Leads_ColumnsInput>;
  voicemail_url?: InputMaybe<Scalars['String']['input']>;
};

export type UserCreateWithoutSequenceActionLogInput = {
  ai_user?: InputMaybe<Scalars['Boolean']['input']>;
  alternate_email?: InputMaybe<Scalars['String']['input']>;
  always_show_calendar_options?: InputMaybe<Scalars['Boolean']['input']>;
  associate_owner_rep_history?: InputMaybe<AssociationHistoryCreateNestedManyWithoutRepInput>;
  associate_user_history?: InputMaybe<AssociationHistoryCreateNestedManyWithoutUserInput>;
  auto_transfer_selection_receive_attempts?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutFrom_UserInput>;
  auto_transfer_selection_send_attempts?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutTo_UserInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptCreateNestedManyWithoutUserInput>;
  callSharingAsCoach?: InputMaybe<CallSharingCreateNestedManyWithoutCoachInput>;
  callSharingAsUser?: InputMaybe<CallSharingCreateNestedManyWithoutReceiver_UserInput>;
  channel?: InputMaybe<Channelfilter>;
  close_rate?: InputMaybe<Scalars['Float']['input']>;
  close_user_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutClose_UserInput>;
  close_user_stat?: InputMaybe<StatLeadAggregationCreateNestedManyWithoutClose_UserInput>;
  commissions?: InputMaybe<CommissionCreateNestedManyWithoutUserInput>;
  contact_requests?: InputMaybe<ContactRequestCreateNestedManyWithoutRequesting_UserInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  current_sequence_view?: InputMaybe<SequenceDashboardViewCreateNestedOneWithoutUserInput>;
  current_team_start_date?: InputMaybe<Scalars['DateTime']['input']>;
  custom_field_white_list_array?: InputMaybe<UserCustomFieldFilterCreateNestedManyWithoutUserInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemCreateNestedManyWithoutUserInput>;
  daily_lead_claim?: InputMaybe<Scalars['Int']['input']>;
  dashboard_2_access?: InputMaybe<Scalars['Boolean']['input']>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewCreateNestedManyWithoutUserInput>;
  default_audio_input?: InputMaybe<Scalars['String']['input']>;
  default_audio_output?: InputMaybe<Scalars['String']['input']>;
  default_availability?: InputMaybe<Scalars['Boolean']['input']>;
  deleted_at?: InputMaybe<Scalars['DateTime']['input']>;
  deprecate_old_session?: InputMaybe<Scalars['Boolean']['input']>;
  email: Scalars['String']['input'];
  EmailSyncRecord?: InputMaybe<EmailSyncRecordCreateNestedManyWithoutUserInput>;
  favorite_leads?: InputMaybe<LeadCreateNestedManyWithoutFavorited_By_UsersInput>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  folder_permissions?: InputMaybe<FolderPermissionCreateNestedManyWithoutUserInput>;
  folders?: InputMaybe<FolderCreateNestedManyWithoutOwnerInput>;
  from_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutOld_UserInput>;
  GlobalRouting?: InputMaybe<GlobalRepRoutingCreateNestedManyWithoutRepInput>;
  google_refresh_token?: InputMaybe<Scalars['String']['input']>;
  holds_per_prospect_vs_held_demo_per_dial?: InputMaybe<Scalars['Float']['input']>;
  hook_subscriptions?: InputMaybe<WebHookSubscriptionCreateNestedManyWithoutUserInput>;
  hubspot_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  idle_owned_leads?: InputMaybe<LeadCreateNestedManyWithoutSecondary_RepsInput>;
  industry_filter?: InputMaybe<Scalars['Boolean']['input']>;
  industry_white_list_array?: InputMaybe<UserCreateindustry_White_List_ArrayInput>;
  industry_white_list_assign_type?: InputMaybe<AssignType>;
  joinMe_access_token?: InputMaybe<Scalars['String']['input']>;
  joinMe_refresh_token?: InputMaybe<Scalars['String']['input']>;
  last_demo_transferred_at?: InputMaybe<Scalars['DateTime']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutUserInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryCreateNestedManyWithoutUserInput>;
  lead_intents?: InputMaybe<LeadIntentCreateNestedManyWithoutUserInput>;
  lead_source_filter?: InputMaybe<Scalars['Boolean']['input']>;
  lead_source_white_list_array?: InputMaybe<UserCreatelead_Source_White_List_ArrayInput>;
  lead_source_white_list_assign_type?: InputMaybe<AssignType>;
  leads_last_called?: InputMaybe<LeadCreateNestedManyWithoutLast_Call_Result_RepInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusCreateNestedManyWithoutUserInput>;
  manager_id?: InputMaybe<Scalars['String']['input']>;
  note_item?: InputMaybe<NoteItemCreateNestedManyWithoutUserInput>;
  notifications?: InputMaybe<NotificationCreateNestedManyWithoutUserInput>;
  organization: OrganizationCreateNestedOneWithoutUsersInput;
  owned_leads?: InputMaybe<LeadCreateNestedManyWithoutRepInput>;
  panda_doc_access?: InputMaybe<Scalars['Boolean']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  payment_settings?: InputMaybe<OrganizationPaymentSettingsCreateNestedManyWithoutCreated_ByInput>;
  payment_settings_updated?: InputMaybe<OrganizationPaymentSettingsCreateNestedManyWithoutLast_Updated_ByInput>;
  phone_number?: InputMaybe<Scalars['String']['input']>;
  post?: InputMaybe<PostCreateNestedManyWithoutAuthorInput>;
  ProductsCreated?: InputMaybe<ProductChangeLogCreateNestedManyWithoutUpdated_ByInput>;
  profile_image?: InputMaybe<Scalars['String']['input']>;
  receive_transfer_attempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutTo_UserInput>;
  receiver_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutReceiver_UserInput>;
  regions_white_list_array?: InputMaybe<UserCreateregions_White_List_ArrayInput>;
  regions_white_list_assign_type?: InputMaybe<AssignType>;
  rep_scores?: InputMaybe<Scalars['Float']['input']>;
  role?: InputMaybe<Role>;
  routings?: InputMaybe<RoutingCreateNestedManyWithoutRepInput>;
  sale_config?: InputMaybe<SaleConfigCreateNestedManyWithoutCreated_ByInput>;
  sale_config_updated?: InputMaybe<SaleConfigCreateNestedManyWithoutLast_Updated_ByInput>;
  sales_team_column_options?: InputMaybe<UserCreatesales_Team_Column_OptionsInput>;
  schedule_items?: InputMaybe<ScheduleItemCreateNestedManyWithoutUserInput>;
  send_transfer_attempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutFrom_UserInput>;
  sender_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutSender_UserInput>;
  sequence_entry_criteria_id?: InputMaybe<Scalars['String']['input']>;
  sequenceDashboardView?: InputMaybe<SequenceDashboardViewCreateNestedManyWithoutOwned_ByInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutUserInput>;
  Sequences_created?: InputMaybe<SequenceCreateNestedManyWithoutCreated_ByInput>;
  Sequences_modified_by_user?: InputMaybe<SequenceCreateNestedManyWithoutLast_Updated_ByInput>;
  session_token?: InputMaybe<Scalars['String']['input']>;
  set_user_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutSet_UserInput>;
  set_user_stat?: InputMaybe<StatLeadAggregationCreateNestedManyWithoutSet_UserInput>;
  sf_user_id?: InputMaybe<Scalars['String']['input']>;
  SmsItems?: InputMaybe<SmsItemCreateNestedManyWithoutRepInput>;
  SnoozeTracking?: InputMaybe<SnoozeTrackingCreateNestedManyWithoutRepInput>;
  status?: InputMaybe<Status>;
  statusHistory?: InputMaybe<UserStatusHistoryCreateNestedManyWithoutUserInput>;
  sub_industry_white_list_array?: InputMaybe<UserCreatesub_Industry_White_List_ArrayInput>;
  sub_industry_white_list_assign_type?: InputMaybe<AssignType>;
  support_rep?: InputMaybe<Scalars['Boolean']['input']>;
  taking_demos?: InputMaybe<Scalars['Boolean']['input']>;
  team?: InputMaybe<TeamCreateNestedOneWithoutMembersInput>;
  Template?: InputMaybe<TemplateCreateNestedManyWithoutLast_Modified_ByInput>;
  TemplateVersion?: InputMaybe<TemplateVersionCreateNestedManyWithoutCreated_ByInput>;
  timekit_id?: InputMaybe<Scalars['String']['input']>;
  timekit_type?: InputMaybe<Timekittype>;
  timeOffRequest?: InputMaybe<TimeOffRequestCreateNestedManyWithoutRequesting_UserInput>;
  timezone_raw?: InputMaybe<Scalars['String']['input']>;
  transfer_type?: InputMaybe<TransferType>;
  transfer_user_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutTransfer_UserInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user_custom_availability?: InputMaybe<AvailabilityCreateNestedManyWithoutUserInput>;
  user_past_tokens?: InputMaybe<UserPastTokensCreateNestedManyWithoutUserInput>;
  visible_all_leads_sm?: InputMaybe<Scalars['Boolean']['input']>;
  visible_call_report_columns?: InputMaybe<UserCreatevisible_Call_Report_ColumnsInput>;
  visible_call_report_event_columns?: InputMaybe<UserCreatevisible_Call_Report_Event_ColumnsInput>;
  visible_call_report_pipeline_columns?: InputMaybe<UserCreatevisible_Call_Report_Pipeline_ColumnsInput>;
  visible_my_leads_columns?: InputMaybe<UserCreatevisible_My_Leads_ColumnsInput>;
  voicemail_url?: InputMaybe<Scalars['String']['input']>;
};

export type UserCreateWithoutSequenceDashboardViewInput = {
  ai_user?: InputMaybe<Scalars['Boolean']['input']>;
  alternate_email?: InputMaybe<Scalars['String']['input']>;
  always_show_calendar_options?: InputMaybe<Scalars['Boolean']['input']>;
  associate_owner_rep_history?: InputMaybe<AssociationHistoryCreateNestedManyWithoutRepInput>;
  associate_user_history?: InputMaybe<AssociationHistoryCreateNestedManyWithoutUserInput>;
  auto_transfer_selection_receive_attempts?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutFrom_UserInput>;
  auto_transfer_selection_send_attempts?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutTo_UserInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptCreateNestedManyWithoutUserInput>;
  callSharingAsCoach?: InputMaybe<CallSharingCreateNestedManyWithoutCoachInput>;
  callSharingAsUser?: InputMaybe<CallSharingCreateNestedManyWithoutReceiver_UserInput>;
  channel?: InputMaybe<Channelfilter>;
  close_rate?: InputMaybe<Scalars['Float']['input']>;
  close_user_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutClose_UserInput>;
  close_user_stat?: InputMaybe<StatLeadAggregationCreateNestedManyWithoutClose_UserInput>;
  commissions?: InputMaybe<CommissionCreateNestedManyWithoutUserInput>;
  contact_requests?: InputMaybe<ContactRequestCreateNestedManyWithoutRequesting_UserInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  current_sequence_view?: InputMaybe<SequenceDashboardViewCreateNestedOneWithoutUserInput>;
  current_team_start_date?: InputMaybe<Scalars['DateTime']['input']>;
  custom_field_white_list_array?: InputMaybe<UserCustomFieldFilterCreateNestedManyWithoutUserInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemCreateNestedManyWithoutUserInput>;
  daily_lead_claim?: InputMaybe<Scalars['Int']['input']>;
  dashboard_2_access?: InputMaybe<Scalars['Boolean']['input']>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewCreateNestedManyWithoutUserInput>;
  default_audio_input?: InputMaybe<Scalars['String']['input']>;
  default_audio_output?: InputMaybe<Scalars['String']['input']>;
  default_availability?: InputMaybe<Scalars['Boolean']['input']>;
  deleted_at?: InputMaybe<Scalars['DateTime']['input']>;
  deprecate_old_session?: InputMaybe<Scalars['Boolean']['input']>;
  email: Scalars['String']['input'];
  EmailSyncRecord?: InputMaybe<EmailSyncRecordCreateNestedManyWithoutUserInput>;
  favorite_leads?: InputMaybe<LeadCreateNestedManyWithoutFavorited_By_UsersInput>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  folder_permissions?: InputMaybe<FolderPermissionCreateNestedManyWithoutUserInput>;
  folders?: InputMaybe<FolderCreateNestedManyWithoutOwnerInput>;
  from_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutOld_UserInput>;
  GlobalRouting?: InputMaybe<GlobalRepRoutingCreateNestedManyWithoutRepInput>;
  google_refresh_token?: InputMaybe<Scalars['String']['input']>;
  holds_per_prospect_vs_held_demo_per_dial?: InputMaybe<Scalars['Float']['input']>;
  hook_subscriptions?: InputMaybe<WebHookSubscriptionCreateNestedManyWithoutUserInput>;
  hubspot_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  idle_owned_leads?: InputMaybe<LeadCreateNestedManyWithoutSecondary_RepsInput>;
  industry_filter?: InputMaybe<Scalars['Boolean']['input']>;
  industry_white_list_array?: InputMaybe<UserCreateindustry_White_List_ArrayInput>;
  industry_white_list_assign_type?: InputMaybe<AssignType>;
  joinMe_access_token?: InputMaybe<Scalars['String']['input']>;
  joinMe_refresh_token?: InputMaybe<Scalars['String']['input']>;
  last_demo_transferred_at?: InputMaybe<Scalars['DateTime']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutUserInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryCreateNestedManyWithoutUserInput>;
  lead_intents?: InputMaybe<LeadIntentCreateNestedManyWithoutUserInput>;
  lead_source_filter?: InputMaybe<Scalars['Boolean']['input']>;
  lead_source_white_list_array?: InputMaybe<UserCreatelead_Source_White_List_ArrayInput>;
  lead_source_white_list_assign_type?: InputMaybe<AssignType>;
  leads_last_called?: InputMaybe<LeadCreateNestedManyWithoutLast_Call_Result_RepInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusCreateNestedManyWithoutUserInput>;
  manager_id?: InputMaybe<Scalars['String']['input']>;
  note_item?: InputMaybe<NoteItemCreateNestedManyWithoutUserInput>;
  notifications?: InputMaybe<NotificationCreateNestedManyWithoutUserInput>;
  organization: OrganizationCreateNestedOneWithoutUsersInput;
  owned_leads?: InputMaybe<LeadCreateNestedManyWithoutRepInput>;
  panda_doc_access?: InputMaybe<Scalars['Boolean']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  payment_settings?: InputMaybe<OrganizationPaymentSettingsCreateNestedManyWithoutCreated_ByInput>;
  payment_settings_updated?: InputMaybe<OrganizationPaymentSettingsCreateNestedManyWithoutLast_Updated_ByInput>;
  phone_number?: InputMaybe<Scalars['String']['input']>;
  post?: InputMaybe<PostCreateNestedManyWithoutAuthorInput>;
  ProductsCreated?: InputMaybe<ProductChangeLogCreateNestedManyWithoutUpdated_ByInput>;
  profile_image?: InputMaybe<Scalars['String']['input']>;
  receive_transfer_attempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutTo_UserInput>;
  receiver_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutReceiver_UserInput>;
  regions_white_list_array?: InputMaybe<UserCreateregions_White_List_ArrayInput>;
  regions_white_list_assign_type?: InputMaybe<AssignType>;
  rep_scores?: InputMaybe<Scalars['Float']['input']>;
  role?: InputMaybe<Role>;
  routings?: InputMaybe<RoutingCreateNestedManyWithoutRepInput>;
  sale_config?: InputMaybe<SaleConfigCreateNestedManyWithoutCreated_ByInput>;
  sale_config_updated?: InputMaybe<SaleConfigCreateNestedManyWithoutLast_Updated_ByInput>;
  sales_team_column_options?: InputMaybe<UserCreatesales_Team_Column_OptionsInput>;
  schedule_items?: InputMaybe<ScheduleItemCreateNestedManyWithoutUserInput>;
  send_transfer_attempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutFrom_UserInput>;
  sender_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutSender_UserInput>;
  sequence_entry_criteria_id?: InputMaybe<Scalars['String']['input']>;
  SequenceActionLog?: InputMaybe<StepActionLogCreateNestedManyWithoutRepInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutUserInput>;
  Sequences_created?: InputMaybe<SequenceCreateNestedManyWithoutCreated_ByInput>;
  Sequences_modified_by_user?: InputMaybe<SequenceCreateNestedManyWithoutLast_Updated_ByInput>;
  session_token?: InputMaybe<Scalars['String']['input']>;
  set_user_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutSet_UserInput>;
  set_user_stat?: InputMaybe<StatLeadAggregationCreateNestedManyWithoutSet_UserInput>;
  sf_user_id?: InputMaybe<Scalars['String']['input']>;
  SmsItems?: InputMaybe<SmsItemCreateNestedManyWithoutRepInput>;
  SnoozeTracking?: InputMaybe<SnoozeTrackingCreateNestedManyWithoutRepInput>;
  status?: InputMaybe<Status>;
  statusHistory?: InputMaybe<UserStatusHistoryCreateNestedManyWithoutUserInput>;
  sub_industry_white_list_array?: InputMaybe<UserCreatesub_Industry_White_List_ArrayInput>;
  sub_industry_white_list_assign_type?: InputMaybe<AssignType>;
  support_rep?: InputMaybe<Scalars['Boolean']['input']>;
  taking_demos?: InputMaybe<Scalars['Boolean']['input']>;
  team?: InputMaybe<TeamCreateNestedOneWithoutMembersInput>;
  Template?: InputMaybe<TemplateCreateNestedManyWithoutLast_Modified_ByInput>;
  TemplateVersion?: InputMaybe<TemplateVersionCreateNestedManyWithoutCreated_ByInput>;
  timekit_id?: InputMaybe<Scalars['String']['input']>;
  timekit_type?: InputMaybe<Timekittype>;
  timeOffRequest?: InputMaybe<TimeOffRequestCreateNestedManyWithoutRequesting_UserInput>;
  timezone_raw?: InputMaybe<Scalars['String']['input']>;
  transfer_type?: InputMaybe<TransferType>;
  transfer_user_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutTransfer_UserInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user_custom_availability?: InputMaybe<AvailabilityCreateNestedManyWithoutUserInput>;
  user_past_tokens?: InputMaybe<UserPastTokensCreateNestedManyWithoutUserInput>;
  visible_all_leads_sm?: InputMaybe<Scalars['Boolean']['input']>;
  visible_call_report_columns?: InputMaybe<UserCreatevisible_Call_Report_ColumnsInput>;
  visible_call_report_event_columns?: InputMaybe<UserCreatevisible_Call_Report_Event_ColumnsInput>;
  visible_call_report_pipeline_columns?: InputMaybe<UserCreatevisible_Call_Report_Pipeline_ColumnsInput>;
  visible_my_leads_columns?: InputMaybe<UserCreatevisible_My_Leads_ColumnsInput>;
  voicemail_url?: InputMaybe<Scalars['String']['input']>;
};

export type UserCreateWithoutSequenceEntryExitLeadInput = {
  ai_user?: InputMaybe<Scalars['Boolean']['input']>;
  alternate_email?: InputMaybe<Scalars['String']['input']>;
  always_show_calendar_options?: InputMaybe<Scalars['Boolean']['input']>;
  associate_owner_rep_history?: InputMaybe<AssociationHistoryCreateNestedManyWithoutRepInput>;
  associate_user_history?: InputMaybe<AssociationHistoryCreateNestedManyWithoutUserInput>;
  auto_transfer_selection_receive_attempts?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutFrom_UserInput>;
  auto_transfer_selection_send_attempts?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutTo_UserInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptCreateNestedManyWithoutUserInput>;
  callSharingAsCoach?: InputMaybe<CallSharingCreateNestedManyWithoutCoachInput>;
  callSharingAsUser?: InputMaybe<CallSharingCreateNestedManyWithoutReceiver_UserInput>;
  channel?: InputMaybe<Channelfilter>;
  close_rate?: InputMaybe<Scalars['Float']['input']>;
  close_user_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutClose_UserInput>;
  close_user_stat?: InputMaybe<StatLeadAggregationCreateNestedManyWithoutClose_UserInput>;
  commissions?: InputMaybe<CommissionCreateNestedManyWithoutUserInput>;
  contact_requests?: InputMaybe<ContactRequestCreateNestedManyWithoutRequesting_UserInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  current_sequence_view?: InputMaybe<SequenceDashboardViewCreateNestedOneWithoutUserInput>;
  current_team_start_date?: InputMaybe<Scalars['DateTime']['input']>;
  custom_field_white_list_array?: InputMaybe<UserCustomFieldFilterCreateNestedManyWithoutUserInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemCreateNestedManyWithoutUserInput>;
  daily_lead_claim?: InputMaybe<Scalars['Int']['input']>;
  dashboard_2_access?: InputMaybe<Scalars['Boolean']['input']>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewCreateNestedManyWithoutUserInput>;
  default_audio_input?: InputMaybe<Scalars['String']['input']>;
  default_audio_output?: InputMaybe<Scalars['String']['input']>;
  default_availability?: InputMaybe<Scalars['Boolean']['input']>;
  deleted_at?: InputMaybe<Scalars['DateTime']['input']>;
  deprecate_old_session?: InputMaybe<Scalars['Boolean']['input']>;
  email: Scalars['String']['input'];
  EmailSyncRecord?: InputMaybe<EmailSyncRecordCreateNestedManyWithoutUserInput>;
  favorite_leads?: InputMaybe<LeadCreateNestedManyWithoutFavorited_By_UsersInput>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  folder_permissions?: InputMaybe<FolderPermissionCreateNestedManyWithoutUserInput>;
  folders?: InputMaybe<FolderCreateNestedManyWithoutOwnerInput>;
  from_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutOld_UserInput>;
  GlobalRouting?: InputMaybe<GlobalRepRoutingCreateNestedManyWithoutRepInput>;
  google_refresh_token?: InputMaybe<Scalars['String']['input']>;
  holds_per_prospect_vs_held_demo_per_dial?: InputMaybe<Scalars['Float']['input']>;
  hook_subscriptions?: InputMaybe<WebHookSubscriptionCreateNestedManyWithoutUserInput>;
  hubspot_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  idle_owned_leads?: InputMaybe<LeadCreateNestedManyWithoutSecondary_RepsInput>;
  industry_filter?: InputMaybe<Scalars['Boolean']['input']>;
  industry_white_list_array?: InputMaybe<UserCreateindustry_White_List_ArrayInput>;
  industry_white_list_assign_type?: InputMaybe<AssignType>;
  joinMe_access_token?: InputMaybe<Scalars['String']['input']>;
  joinMe_refresh_token?: InputMaybe<Scalars['String']['input']>;
  last_demo_transferred_at?: InputMaybe<Scalars['DateTime']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutUserInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryCreateNestedManyWithoutUserInput>;
  lead_intents?: InputMaybe<LeadIntentCreateNestedManyWithoutUserInput>;
  lead_source_filter?: InputMaybe<Scalars['Boolean']['input']>;
  lead_source_white_list_array?: InputMaybe<UserCreatelead_Source_White_List_ArrayInput>;
  lead_source_white_list_assign_type?: InputMaybe<AssignType>;
  leads_last_called?: InputMaybe<LeadCreateNestedManyWithoutLast_Call_Result_RepInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusCreateNestedManyWithoutUserInput>;
  manager_id?: InputMaybe<Scalars['String']['input']>;
  note_item?: InputMaybe<NoteItemCreateNestedManyWithoutUserInput>;
  notifications?: InputMaybe<NotificationCreateNestedManyWithoutUserInput>;
  organization: OrganizationCreateNestedOneWithoutUsersInput;
  owned_leads?: InputMaybe<LeadCreateNestedManyWithoutRepInput>;
  panda_doc_access?: InputMaybe<Scalars['Boolean']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  payment_settings?: InputMaybe<OrganizationPaymentSettingsCreateNestedManyWithoutCreated_ByInput>;
  payment_settings_updated?: InputMaybe<OrganizationPaymentSettingsCreateNestedManyWithoutLast_Updated_ByInput>;
  phone_number?: InputMaybe<Scalars['String']['input']>;
  post?: InputMaybe<PostCreateNestedManyWithoutAuthorInput>;
  ProductsCreated?: InputMaybe<ProductChangeLogCreateNestedManyWithoutUpdated_ByInput>;
  profile_image?: InputMaybe<Scalars['String']['input']>;
  receive_transfer_attempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutTo_UserInput>;
  receiver_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutReceiver_UserInput>;
  regions_white_list_array?: InputMaybe<UserCreateregions_White_List_ArrayInput>;
  regions_white_list_assign_type?: InputMaybe<AssignType>;
  rep_scores?: InputMaybe<Scalars['Float']['input']>;
  role?: InputMaybe<Role>;
  routings?: InputMaybe<RoutingCreateNestedManyWithoutRepInput>;
  sale_config?: InputMaybe<SaleConfigCreateNestedManyWithoutCreated_ByInput>;
  sale_config_updated?: InputMaybe<SaleConfigCreateNestedManyWithoutLast_Updated_ByInput>;
  sales_team_column_options?: InputMaybe<UserCreatesales_Team_Column_OptionsInput>;
  schedule_items?: InputMaybe<ScheduleItemCreateNestedManyWithoutUserInput>;
  send_transfer_attempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutFrom_UserInput>;
  sender_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutSender_UserInput>;
  sequence_entry_criteria_id?: InputMaybe<Scalars['String']['input']>;
  SequenceActionLog?: InputMaybe<StepActionLogCreateNestedManyWithoutRepInput>;
  sequenceDashboardView?: InputMaybe<SequenceDashboardViewCreateNestedManyWithoutOwned_ByInput>;
  Sequences_created?: InputMaybe<SequenceCreateNestedManyWithoutCreated_ByInput>;
  Sequences_modified_by_user?: InputMaybe<SequenceCreateNestedManyWithoutLast_Updated_ByInput>;
  session_token?: InputMaybe<Scalars['String']['input']>;
  set_user_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutSet_UserInput>;
  set_user_stat?: InputMaybe<StatLeadAggregationCreateNestedManyWithoutSet_UserInput>;
  sf_user_id?: InputMaybe<Scalars['String']['input']>;
  SmsItems?: InputMaybe<SmsItemCreateNestedManyWithoutRepInput>;
  SnoozeTracking?: InputMaybe<SnoozeTrackingCreateNestedManyWithoutRepInput>;
  status?: InputMaybe<Status>;
  statusHistory?: InputMaybe<UserStatusHistoryCreateNestedManyWithoutUserInput>;
  sub_industry_white_list_array?: InputMaybe<UserCreatesub_Industry_White_List_ArrayInput>;
  sub_industry_white_list_assign_type?: InputMaybe<AssignType>;
  support_rep?: InputMaybe<Scalars['Boolean']['input']>;
  taking_demos?: InputMaybe<Scalars['Boolean']['input']>;
  team?: InputMaybe<TeamCreateNestedOneWithoutMembersInput>;
  Template?: InputMaybe<TemplateCreateNestedManyWithoutLast_Modified_ByInput>;
  TemplateVersion?: InputMaybe<TemplateVersionCreateNestedManyWithoutCreated_ByInput>;
  timekit_id?: InputMaybe<Scalars['String']['input']>;
  timekit_type?: InputMaybe<Timekittype>;
  timeOffRequest?: InputMaybe<TimeOffRequestCreateNestedManyWithoutRequesting_UserInput>;
  timezone_raw?: InputMaybe<Scalars['String']['input']>;
  transfer_type?: InputMaybe<TransferType>;
  transfer_user_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutTransfer_UserInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user_custom_availability?: InputMaybe<AvailabilityCreateNestedManyWithoutUserInput>;
  user_past_tokens?: InputMaybe<UserPastTokensCreateNestedManyWithoutUserInput>;
  visible_all_leads_sm?: InputMaybe<Scalars['Boolean']['input']>;
  visible_call_report_columns?: InputMaybe<UserCreatevisible_Call_Report_ColumnsInput>;
  visible_call_report_event_columns?: InputMaybe<UserCreatevisible_Call_Report_Event_ColumnsInput>;
  visible_call_report_pipeline_columns?: InputMaybe<UserCreatevisible_Call_Report_Pipeline_ColumnsInput>;
  visible_my_leads_columns?: InputMaybe<UserCreatevisible_My_Leads_ColumnsInput>;
  voicemail_url?: InputMaybe<Scalars['String']['input']>;
};

export type UserCreateWithoutSequences_CreatedInput = {
  ai_user?: InputMaybe<Scalars['Boolean']['input']>;
  alternate_email?: InputMaybe<Scalars['String']['input']>;
  always_show_calendar_options?: InputMaybe<Scalars['Boolean']['input']>;
  associate_owner_rep_history?: InputMaybe<AssociationHistoryCreateNestedManyWithoutRepInput>;
  associate_user_history?: InputMaybe<AssociationHistoryCreateNestedManyWithoutUserInput>;
  auto_transfer_selection_receive_attempts?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutFrom_UserInput>;
  auto_transfer_selection_send_attempts?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutTo_UserInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptCreateNestedManyWithoutUserInput>;
  callSharingAsCoach?: InputMaybe<CallSharingCreateNestedManyWithoutCoachInput>;
  callSharingAsUser?: InputMaybe<CallSharingCreateNestedManyWithoutReceiver_UserInput>;
  channel?: InputMaybe<Channelfilter>;
  close_rate?: InputMaybe<Scalars['Float']['input']>;
  close_user_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutClose_UserInput>;
  close_user_stat?: InputMaybe<StatLeadAggregationCreateNestedManyWithoutClose_UserInput>;
  commissions?: InputMaybe<CommissionCreateNestedManyWithoutUserInput>;
  contact_requests?: InputMaybe<ContactRequestCreateNestedManyWithoutRequesting_UserInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  current_sequence_view?: InputMaybe<SequenceDashboardViewCreateNestedOneWithoutUserInput>;
  current_team_start_date?: InputMaybe<Scalars['DateTime']['input']>;
  custom_field_white_list_array?: InputMaybe<UserCustomFieldFilterCreateNestedManyWithoutUserInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemCreateNestedManyWithoutUserInput>;
  daily_lead_claim?: InputMaybe<Scalars['Int']['input']>;
  dashboard_2_access?: InputMaybe<Scalars['Boolean']['input']>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewCreateNestedManyWithoutUserInput>;
  default_audio_input?: InputMaybe<Scalars['String']['input']>;
  default_audio_output?: InputMaybe<Scalars['String']['input']>;
  default_availability?: InputMaybe<Scalars['Boolean']['input']>;
  deleted_at?: InputMaybe<Scalars['DateTime']['input']>;
  deprecate_old_session?: InputMaybe<Scalars['Boolean']['input']>;
  email: Scalars['String']['input'];
  EmailSyncRecord?: InputMaybe<EmailSyncRecordCreateNestedManyWithoutUserInput>;
  favorite_leads?: InputMaybe<LeadCreateNestedManyWithoutFavorited_By_UsersInput>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  folder_permissions?: InputMaybe<FolderPermissionCreateNestedManyWithoutUserInput>;
  folders?: InputMaybe<FolderCreateNestedManyWithoutOwnerInput>;
  from_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutOld_UserInput>;
  GlobalRouting?: InputMaybe<GlobalRepRoutingCreateNestedManyWithoutRepInput>;
  google_refresh_token?: InputMaybe<Scalars['String']['input']>;
  holds_per_prospect_vs_held_demo_per_dial?: InputMaybe<Scalars['Float']['input']>;
  hook_subscriptions?: InputMaybe<WebHookSubscriptionCreateNestedManyWithoutUserInput>;
  hubspot_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  idle_owned_leads?: InputMaybe<LeadCreateNestedManyWithoutSecondary_RepsInput>;
  industry_filter?: InputMaybe<Scalars['Boolean']['input']>;
  industry_white_list_array?: InputMaybe<UserCreateindustry_White_List_ArrayInput>;
  industry_white_list_assign_type?: InputMaybe<AssignType>;
  joinMe_access_token?: InputMaybe<Scalars['String']['input']>;
  joinMe_refresh_token?: InputMaybe<Scalars['String']['input']>;
  last_demo_transferred_at?: InputMaybe<Scalars['DateTime']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutUserInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryCreateNestedManyWithoutUserInput>;
  lead_intents?: InputMaybe<LeadIntentCreateNestedManyWithoutUserInput>;
  lead_source_filter?: InputMaybe<Scalars['Boolean']['input']>;
  lead_source_white_list_array?: InputMaybe<UserCreatelead_Source_White_List_ArrayInput>;
  lead_source_white_list_assign_type?: InputMaybe<AssignType>;
  leads_last_called?: InputMaybe<LeadCreateNestedManyWithoutLast_Call_Result_RepInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusCreateNestedManyWithoutUserInput>;
  manager_id?: InputMaybe<Scalars['String']['input']>;
  note_item?: InputMaybe<NoteItemCreateNestedManyWithoutUserInput>;
  notifications?: InputMaybe<NotificationCreateNestedManyWithoutUserInput>;
  organization: OrganizationCreateNestedOneWithoutUsersInput;
  owned_leads?: InputMaybe<LeadCreateNestedManyWithoutRepInput>;
  panda_doc_access?: InputMaybe<Scalars['Boolean']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  payment_settings?: InputMaybe<OrganizationPaymentSettingsCreateNestedManyWithoutCreated_ByInput>;
  payment_settings_updated?: InputMaybe<OrganizationPaymentSettingsCreateNestedManyWithoutLast_Updated_ByInput>;
  phone_number?: InputMaybe<Scalars['String']['input']>;
  post?: InputMaybe<PostCreateNestedManyWithoutAuthorInput>;
  ProductsCreated?: InputMaybe<ProductChangeLogCreateNestedManyWithoutUpdated_ByInput>;
  profile_image?: InputMaybe<Scalars['String']['input']>;
  receive_transfer_attempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutTo_UserInput>;
  receiver_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutReceiver_UserInput>;
  regions_white_list_array?: InputMaybe<UserCreateregions_White_List_ArrayInput>;
  regions_white_list_assign_type?: InputMaybe<AssignType>;
  rep_scores?: InputMaybe<Scalars['Float']['input']>;
  role?: InputMaybe<Role>;
  routings?: InputMaybe<RoutingCreateNestedManyWithoutRepInput>;
  sale_config?: InputMaybe<SaleConfigCreateNestedManyWithoutCreated_ByInput>;
  sale_config_updated?: InputMaybe<SaleConfigCreateNestedManyWithoutLast_Updated_ByInput>;
  sales_team_column_options?: InputMaybe<UserCreatesales_Team_Column_OptionsInput>;
  schedule_items?: InputMaybe<ScheduleItemCreateNestedManyWithoutUserInput>;
  send_transfer_attempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutFrom_UserInput>;
  sender_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutSender_UserInput>;
  sequence_entry_criteria_id?: InputMaybe<Scalars['String']['input']>;
  SequenceActionLog?: InputMaybe<StepActionLogCreateNestedManyWithoutRepInput>;
  sequenceDashboardView?: InputMaybe<SequenceDashboardViewCreateNestedManyWithoutOwned_ByInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutUserInput>;
  Sequences_modified_by_user?: InputMaybe<SequenceCreateNestedManyWithoutLast_Updated_ByInput>;
  session_token?: InputMaybe<Scalars['String']['input']>;
  set_user_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutSet_UserInput>;
  set_user_stat?: InputMaybe<StatLeadAggregationCreateNestedManyWithoutSet_UserInput>;
  sf_user_id?: InputMaybe<Scalars['String']['input']>;
  SmsItems?: InputMaybe<SmsItemCreateNestedManyWithoutRepInput>;
  SnoozeTracking?: InputMaybe<SnoozeTrackingCreateNestedManyWithoutRepInput>;
  status?: InputMaybe<Status>;
  statusHistory?: InputMaybe<UserStatusHistoryCreateNestedManyWithoutUserInput>;
  sub_industry_white_list_array?: InputMaybe<UserCreatesub_Industry_White_List_ArrayInput>;
  sub_industry_white_list_assign_type?: InputMaybe<AssignType>;
  support_rep?: InputMaybe<Scalars['Boolean']['input']>;
  taking_demos?: InputMaybe<Scalars['Boolean']['input']>;
  team?: InputMaybe<TeamCreateNestedOneWithoutMembersInput>;
  Template?: InputMaybe<TemplateCreateNestedManyWithoutLast_Modified_ByInput>;
  TemplateVersion?: InputMaybe<TemplateVersionCreateNestedManyWithoutCreated_ByInput>;
  timekit_id?: InputMaybe<Scalars['String']['input']>;
  timekit_type?: InputMaybe<Timekittype>;
  timeOffRequest?: InputMaybe<TimeOffRequestCreateNestedManyWithoutRequesting_UserInput>;
  timezone_raw?: InputMaybe<Scalars['String']['input']>;
  transfer_type?: InputMaybe<TransferType>;
  transfer_user_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutTransfer_UserInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user_custom_availability?: InputMaybe<AvailabilityCreateNestedManyWithoutUserInput>;
  user_past_tokens?: InputMaybe<UserPastTokensCreateNestedManyWithoutUserInput>;
  visible_all_leads_sm?: InputMaybe<Scalars['Boolean']['input']>;
  visible_call_report_columns?: InputMaybe<UserCreatevisible_Call_Report_ColumnsInput>;
  visible_call_report_event_columns?: InputMaybe<UserCreatevisible_Call_Report_Event_ColumnsInput>;
  visible_call_report_pipeline_columns?: InputMaybe<UserCreatevisible_Call_Report_Pipeline_ColumnsInput>;
  visible_my_leads_columns?: InputMaybe<UserCreatevisible_My_Leads_ColumnsInput>;
  voicemail_url?: InputMaybe<Scalars['String']['input']>;
};

export type UserCreateWithoutSequences_Modified_By_UserInput = {
  ai_user?: InputMaybe<Scalars['Boolean']['input']>;
  alternate_email?: InputMaybe<Scalars['String']['input']>;
  always_show_calendar_options?: InputMaybe<Scalars['Boolean']['input']>;
  associate_owner_rep_history?: InputMaybe<AssociationHistoryCreateNestedManyWithoutRepInput>;
  associate_user_history?: InputMaybe<AssociationHistoryCreateNestedManyWithoutUserInput>;
  auto_transfer_selection_receive_attempts?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutFrom_UserInput>;
  auto_transfer_selection_send_attempts?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutTo_UserInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptCreateNestedManyWithoutUserInput>;
  callSharingAsCoach?: InputMaybe<CallSharingCreateNestedManyWithoutCoachInput>;
  callSharingAsUser?: InputMaybe<CallSharingCreateNestedManyWithoutReceiver_UserInput>;
  channel?: InputMaybe<Channelfilter>;
  close_rate?: InputMaybe<Scalars['Float']['input']>;
  close_user_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutClose_UserInput>;
  close_user_stat?: InputMaybe<StatLeadAggregationCreateNestedManyWithoutClose_UserInput>;
  commissions?: InputMaybe<CommissionCreateNestedManyWithoutUserInput>;
  contact_requests?: InputMaybe<ContactRequestCreateNestedManyWithoutRequesting_UserInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  current_sequence_view?: InputMaybe<SequenceDashboardViewCreateNestedOneWithoutUserInput>;
  current_team_start_date?: InputMaybe<Scalars['DateTime']['input']>;
  custom_field_white_list_array?: InputMaybe<UserCustomFieldFilterCreateNestedManyWithoutUserInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemCreateNestedManyWithoutUserInput>;
  daily_lead_claim?: InputMaybe<Scalars['Int']['input']>;
  dashboard_2_access?: InputMaybe<Scalars['Boolean']['input']>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewCreateNestedManyWithoutUserInput>;
  default_audio_input?: InputMaybe<Scalars['String']['input']>;
  default_audio_output?: InputMaybe<Scalars['String']['input']>;
  default_availability?: InputMaybe<Scalars['Boolean']['input']>;
  deleted_at?: InputMaybe<Scalars['DateTime']['input']>;
  deprecate_old_session?: InputMaybe<Scalars['Boolean']['input']>;
  email: Scalars['String']['input'];
  EmailSyncRecord?: InputMaybe<EmailSyncRecordCreateNestedManyWithoutUserInput>;
  favorite_leads?: InputMaybe<LeadCreateNestedManyWithoutFavorited_By_UsersInput>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  folder_permissions?: InputMaybe<FolderPermissionCreateNestedManyWithoutUserInput>;
  folders?: InputMaybe<FolderCreateNestedManyWithoutOwnerInput>;
  from_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutOld_UserInput>;
  GlobalRouting?: InputMaybe<GlobalRepRoutingCreateNestedManyWithoutRepInput>;
  google_refresh_token?: InputMaybe<Scalars['String']['input']>;
  holds_per_prospect_vs_held_demo_per_dial?: InputMaybe<Scalars['Float']['input']>;
  hook_subscriptions?: InputMaybe<WebHookSubscriptionCreateNestedManyWithoutUserInput>;
  hubspot_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  idle_owned_leads?: InputMaybe<LeadCreateNestedManyWithoutSecondary_RepsInput>;
  industry_filter?: InputMaybe<Scalars['Boolean']['input']>;
  industry_white_list_array?: InputMaybe<UserCreateindustry_White_List_ArrayInput>;
  industry_white_list_assign_type?: InputMaybe<AssignType>;
  joinMe_access_token?: InputMaybe<Scalars['String']['input']>;
  joinMe_refresh_token?: InputMaybe<Scalars['String']['input']>;
  last_demo_transferred_at?: InputMaybe<Scalars['DateTime']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutUserInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryCreateNestedManyWithoutUserInput>;
  lead_intents?: InputMaybe<LeadIntentCreateNestedManyWithoutUserInput>;
  lead_source_filter?: InputMaybe<Scalars['Boolean']['input']>;
  lead_source_white_list_array?: InputMaybe<UserCreatelead_Source_White_List_ArrayInput>;
  lead_source_white_list_assign_type?: InputMaybe<AssignType>;
  leads_last_called?: InputMaybe<LeadCreateNestedManyWithoutLast_Call_Result_RepInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusCreateNestedManyWithoutUserInput>;
  manager_id?: InputMaybe<Scalars['String']['input']>;
  note_item?: InputMaybe<NoteItemCreateNestedManyWithoutUserInput>;
  notifications?: InputMaybe<NotificationCreateNestedManyWithoutUserInput>;
  organization: OrganizationCreateNestedOneWithoutUsersInput;
  owned_leads?: InputMaybe<LeadCreateNestedManyWithoutRepInput>;
  panda_doc_access?: InputMaybe<Scalars['Boolean']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  payment_settings?: InputMaybe<OrganizationPaymentSettingsCreateNestedManyWithoutCreated_ByInput>;
  payment_settings_updated?: InputMaybe<OrganizationPaymentSettingsCreateNestedManyWithoutLast_Updated_ByInput>;
  phone_number?: InputMaybe<Scalars['String']['input']>;
  post?: InputMaybe<PostCreateNestedManyWithoutAuthorInput>;
  ProductsCreated?: InputMaybe<ProductChangeLogCreateNestedManyWithoutUpdated_ByInput>;
  profile_image?: InputMaybe<Scalars['String']['input']>;
  receive_transfer_attempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutTo_UserInput>;
  receiver_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutReceiver_UserInput>;
  regions_white_list_array?: InputMaybe<UserCreateregions_White_List_ArrayInput>;
  regions_white_list_assign_type?: InputMaybe<AssignType>;
  rep_scores?: InputMaybe<Scalars['Float']['input']>;
  role?: InputMaybe<Role>;
  routings?: InputMaybe<RoutingCreateNestedManyWithoutRepInput>;
  sale_config?: InputMaybe<SaleConfigCreateNestedManyWithoutCreated_ByInput>;
  sale_config_updated?: InputMaybe<SaleConfigCreateNestedManyWithoutLast_Updated_ByInput>;
  sales_team_column_options?: InputMaybe<UserCreatesales_Team_Column_OptionsInput>;
  schedule_items?: InputMaybe<ScheduleItemCreateNestedManyWithoutUserInput>;
  send_transfer_attempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutFrom_UserInput>;
  sender_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutSender_UserInput>;
  sequence_entry_criteria_id?: InputMaybe<Scalars['String']['input']>;
  SequenceActionLog?: InputMaybe<StepActionLogCreateNestedManyWithoutRepInput>;
  sequenceDashboardView?: InputMaybe<SequenceDashboardViewCreateNestedManyWithoutOwned_ByInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutUserInput>;
  Sequences_created?: InputMaybe<SequenceCreateNestedManyWithoutCreated_ByInput>;
  session_token?: InputMaybe<Scalars['String']['input']>;
  set_user_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutSet_UserInput>;
  set_user_stat?: InputMaybe<StatLeadAggregationCreateNestedManyWithoutSet_UserInput>;
  sf_user_id?: InputMaybe<Scalars['String']['input']>;
  SmsItems?: InputMaybe<SmsItemCreateNestedManyWithoutRepInput>;
  SnoozeTracking?: InputMaybe<SnoozeTrackingCreateNestedManyWithoutRepInput>;
  status?: InputMaybe<Status>;
  statusHistory?: InputMaybe<UserStatusHistoryCreateNestedManyWithoutUserInput>;
  sub_industry_white_list_array?: InputMaybe<UserCreatesub_Industry_White_List_ArrayInput>;
  sub_industry_white_list_assign_type?: InputMaybe<AssignType>;
  support_rep?: InputMaybe<Scalars['Boolean']['input']>;
  taking_demos?: InputMaybe<Scalars['Boolean']['input']>;
  team?: InputMaybe<TeamCreateNestedOneWithoutMembersInput>;
  Template?: InputMaybe<TemplateCreateNestedManyWithoutLast_Modified_ByInput>;
  TemplateVersion?: InputMaybe<TemplateVersionCreateNestedManyWithoutCreated_ByInput>;
  timekit_id?: InputMaybe<Scalars['String']['input']>;
  timekit_type?: InputMaybe<Timekittype>;
  timeOffRequest?: InputMaybe<TimeOffRequestCreateNestedManyWithoutRequesting_UserInput>;
  timezone_raw?: InputMaybe<Scalars['String']['input']>;
  transfer_type?: InputMaybe<TransferType>;
  transfer_user_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutTransfer_UserInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user_custom_availability?: InputMaybe<AvailabilityCreateNestedManyWithoutUserInput>;
  user_past_tokens?: InputMaybe<UserPastTokensCreateNestedManyWithoutUserInput>;
  visible_all_leads_sm?: InputMaybe<Scalars['Boolean']['input']>;
  visible_call_report_columns?: InputMaybe<UserCreatevisible_Call_Report_ColumnsInput>;
  visible_call_report_event_columns?: InputMaybe<UserCreatevisible_Call_Report_Event_ColumnsInput>;
  visible_call_report_pipeline_columns?: InputMaybe<UserCreatevisible_Call_Report_Pipeline_ColumnsInput>;
  visible_my_leads_columns?: InputMaybe<UserCreatevisible_My_Leads_ColumnsInput>;
  voicemail_url?: InputMaybe<Scalars['String']['input']>;
};

export type UserCreateWithoutSet_User_CycleInput = {
  ai_user?: InputMaybe<Scalars['Boolean']['input']>;
  alternate_email?: InputMaybe<Scalars['String']['input']>;
  always_show_calendar_options?: InputMaybe<Scalars['Boolean']['input']>;
  associate_owner_rep_history?: InputMaybe<AssociationHistoryCreateNestedManyWithoutRepInput>;
  associate_user_history?: InputMaybe<AssociationHistoryCreateNestedManyWithoutUserInput>;
  auto_transfer_selection_receive_attempts?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutFrom_UserInput>;
  auto_transfer_selection_send_attempts?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutTo_UserInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptCreateNestedManyWithoutUserInput>;
  callSharingAsCoach?: InputMaybe<CallSharingCreateNestedManyWithoutCoachInput>;
  callSharingAsUser?: InputMaybe<CallSharingCreateNestedManyWithoutReceiver_UserInput>;
  channel?: InputMaybe<Channelfilter>;
  close_rate?: InputMaybe<Scalars['Float']['input']>;
  close_user_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutClose_UserInput>;
  close_user_stat?: InputMaybe<StatLeadAggregationCreateNestedManyWithoutClose_UserInput>;
  commissions?: InputMaybe<CommissionCreateNestedManyWithoutUserInput>;
  contact_requests?: InputMaybe<ContactRequestCreateNestedManyWithoutRequesting_UserInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  current_sequence_view?: InputMaybe<SequenceDashboardViewCreateNestedOneWithoutUserInput>;
  current_team_start_date?: InputMaybe<Scalars['DateTime']['input']>;
  custom_field_white_list_array?: InputMaybe<UserCustomFieldFilterCreateNestedManyWithoutUserInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemCreateNestedManyWithoutUserInput>;
  daily_lead_claim?: InputMaybe<Scalars['Int']['input']>;
  dashboard_2_access?: InputMaybe<Scalars['Boolean']['input']>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewCreateNestedManyWithoutUserInput>;
  default_audio_input?: InputMaybe<Scalars['String']['input']>;
  default_audio_output?: InputMaybe<Scalars['String']['input']>;
  default_availability?: InputMaybe<Scalars['Boolean']['input']>;
  deleted_at?: InputMaybe<Scalars['DateTime']['input']>;
  deprecate_old_session?: InputMaybe<Scalars['Boolean']['input']>;
  email: Scalars['String']['input'];
  EmailSyncRecord?: InputMaybe<EmailSyncRecordCreateNestedManyWithoutUserInput>;
  favorite_leads?: InputMaybe<LeadCreateNestedManyWithoutFavorited_By_UsersInput>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  folder_permissions?: InputMaybe<FolderPermissionCreateNestedManyWithoutUserInput>;
  folders?: InputMaybe<FolderCreateNestedManyWithoutOwnerInput>;
  from_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutOld_UserInput>;
  GlobalRouting?: InputMaybe<GlobalRepRoutingCreateNestedManyWithoutRepInput>;
  google_refresh_token?: InputMaybe<Scalars['String']['input']>;
  holds_per_prospect_vs_held_demo_per_dial?: InputMaybe<Scalars['Float']['input']>;
  hook_subscriptions?: InputMaybe<WebHookSubscriptionCreateNestedManyWithoutUserInput>;
  hubspot_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  idle_owned_leads?: InputMaybe<LeadCreateNestedManyWithoutSecondary_RepsInput>;
  industry_filter?: InputMaybe<Scalars['Boolean']['input']>;
  industry_white_list_array?: InputMaybe<UserCreateindustry_White_List_ArrayInput>;
  industry_white_list_assign_type?: InputMaybe<AssignType>;
  joinMe_access_token?: InputMaybe<Scalars['String']['input']>;
  joinMe_refresh_token?: InputMaybe<Scalars['String']['input']>;
  last_demo_transferred_at?: InputMaybe<Scalars['DateTime']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutUserInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryCreateNestedManyWithoutUserInput>;
  lead_intents?: InputMaybe<LeadIntentCreateNestedManyWithoutUserInput>;
  lead_source_filter?: InputMaybe<Scalars['Boolean']['input']>;
  lead_source_white_list_array?: InputMaybe<UserCreatelead_Source_White_List_ArrayInput>;
  lead_source_white_list_assign_type?: InputMaybe<AssignType>;
  leads_last_called?: InputMaybe<LeadCreateNestedManyWithoutLast_Call_Result_RepInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusCreateNestedManyWithoutUserInput>;
  manager_id?: InputMaybe<Scalars['String']['input']>;
  note_item?: InputMaybe<NoteItemCreateNestedManyWithoutUserInput>;
  notifications?: InputMaybe<NotificationCreateNestedManyWithoutUserInput>;
  organization: OrganizationCreateNestedOneWithoutUsersInput;
  owned_leads?: InputMaybe<LeadCreateNestedManyWithoutRepInput>;
  panda_doc_access?: InputMaybe<Scalars['Boolean']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  payment_settings?: InputMaybe<OrganizationPaymentSettingsCreateNestedManyWithoutCreated_ByInput>;
  payment_settings_updated?: InputMaybe<OrganizationPaymentSettingsCreateNestedManyWithoutLast_Updated_ByInput>;
  phone_number?: InputMaybe<Scalars['String']['input']>;
  post?: InputMaybe<PostCreateNestedManyWithoutAuthorInput>;
  ProductsCreated?: InputMaybe<ProductChangeLogCreateNestedManyWithoutUpdated_ByInput>;
  profile_image?: InputMaybe<Scalars['String']['input']>;
  receive_transfer_attempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutTo_UserInput>;
  receiver_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutReceiver_UserInput>;
  regions_white_list_array?: InputMaybe<UserCreateregions_White_List_ArrayInput>;
  regions_white_list_assign_type?: InputMaybe<AssignType>;
  rep_scores?: InputMaybe<Scalars['Float']['input']>;
  role?: InputMaybe<Role>;
  routings?: InputMaybe<RoutingCreateNestedManyWithoutRepInput>;
  sale_config?: InputMaybe<SaleConfigCreateNestedManyWithoutCreated_ByInput>;
  sale_config_updated?: InputMaybe<SaleConfigCreateNestedManyWithoutLast_Updated_ByInput>;
  sales_team_column_options?: InputMaybe<UserCreatesales_Team_Column_OptionsInput>;
  schedule_items?: InputMaybe<ScheduleItemCreateNestedManyWithoutUserInput>;
  send_transfer_attempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutFrom_UserInput>;
  sender_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutSender_UserInput>;
  sequence_entry_criteria_id?: InputMaybe<Scalars['String']['input']>;
  SequenceActionLog?: InputMaybe<StepActionLogCreateNestedManyWithoutRepInput>;
  sequenceDashboardView?: InputMaybe<SequenceDashboardViewCreateNestedManyWithoutOwned_ByInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutUserInput>;
  Sequences_created?: InputMaybe<SequenceCreateNestedManyWithoutCreated_ByInput>;
  Sequences_modified_by_user?: InputMaybe<SequenceCreateNestedManyWithoutLast_Updated_ByInput>;
  session_token?: InputMaybe<Scalars['String']['input']>;
  set_user_stat?: InputMaybe<StatLeadAggregationCreateNestedManyWithoutSet_UserInput>;
  sf_user_id?: InputMaybe<Scalars['String']['input']>;
  SmsItems?: InputMaybe<SmsItemCreateNestedManyWithoutRepInput>;
  SnoozeTracking?: InputMaybe<SnoozeTrackingCreateNestedManyWithoutRepInput>;
  status?: InputMaybe<Status>;
  statusHistory?: InputMaybe<UserStatusHistoryCreateNestedManyWithoutUserInput>;
  sub_industry_white_list_array?: InputMaybe<UserCreatesub_Industry_White_List_ArrayInput>;
  sub_industry_white_list_assign_type?: InputMaybe<AssignType>;
  support_rep?: InputMaybe<Scalars['Boolean']['input']>;
  taking_demos?: InputMaybe<Scalars['Boolean']['input']>;
  team?: InputMaybe<TeamCreateNestedOneWithoutMembersInput>;
  Template?: InputMaybe<TemplateCreateNestedManyWithoutLast_Modified_ByInput>;
  TemplateVersion?: InputMaybe<TemplateVersionCreateNestedManyWithoutCreated_ByInput>;
  timekit_id?: InputMaybe<Scalars['String']['input']>;
  timekit_type?: InputMaybe<Timekittype>;
  timeOffRequest?: InputMaybe<TimeOffRequestCreateNestedManyWithoutRequesting_UserInput>;
  timezone_raw?: InputMaybe<Scalars['String']['input']>;
  transfer_type?: InputMaybe<TransferType>;
  transfer_user_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutTransfer_UserInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user_custom_availability?: InputMaybe<AvailabilityCreateNestedManyWithoutUserInput>;
  user_past_tokens?: InputMaybe<UserPastTokensCreateNestedManyWithoutUserInput>;
  visible_all_leads_sm?: InputMaybe<Scalars['Boolean']['input']>;
  visible_call_report_columns?: InputMaybe<UserCreatevisible_Call_Report_ColumnsInput>;
  visible_call_report_event_columns?: InputMaybe<UserCreatevisible_Call_Report_Event_ColumnsInput>;
  visible_call_report_pipeline_columns?: InputMaybe<UserCreatevisible_Call_Report_Pipeline_ColumnsInput>;
  visible_my_leads_columns?: InputMaybe<UserCreatevisible_My_Leads_ColumnsInput>;
  voicemail_url?: InputMaybe<Scalars['String']['input']>;
};

export type UserCreateWithoutSet_User_StatInput = {
  ai_user?: InputMaybe<Scalars['Boolean']['input']>;
  alternate_email?: InputMaybe<Scalars['String']['input']>;
  always_show_calendar_options?: InputMaybe<Scalars['Boolean']['input']>;
  associate_owner_rep_history?: InputMaybe<AssociationHistoryCreateNestedManyWithoutRepInput>;
  associate_user_history?: InputMaybe<AssociationHistoryCreateNestedManyWithoutUserInput>;
  auto_transfer_selection_receive_attempts?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutFrom_UserInput>;
  auto_transfer_selection_send_attempts?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutTo_UserInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptCreateNestedManyWithoutUserInput>;
  callSharingAsCoach?: InputMaybe<CallSharingCreateNestedManyWithoutCoachInput>;
  callSharingAsUser?: InputMaybe<CallSharingCreateNestedManyWithoutReceiver_UserInput>;
  channel?: InputMaybe<Channelfilter>;
  close_rate?: InputMaybe<Scalars['Float']['input']>;
  close_user_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutClose_UserInput>;
  close_user_stat?: InputMaybe<StatLeadAggregationCreateNestedManyWithoutClose_UserInput>;
  commissions?: InputMaybe<CommissionCreateNestedManyWithoutUserInput>;
  contact_requests?: InputMaybe<ContactRequestCreateNestedManyWithoutRequesting_UserInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  current_sequence_view?: InputMaybe<SequenceDashboardViewCreateNestedOneWithoutUserInput>;
  current_team_start_date?: InputMaybe<Scalars['DateTime']['input']>;
  custom_field_white_list_array?: InputMaybe<UserCustomFieldFilterCreateNestedManyWithoutUserInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemCreateNestedManyWithoutUserInput>;
  daily_lead_claim?: InputMaybe<Scalars['Int']['input']>;
  dashboard_2_access?: InputMaybe<Scalars['Boolean']['input']>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewCreateNestedManyWithoutUserInput>;
  default_audio_input?: InputMaybe<Scalars['String']['input']>;
  default_audio_output?: InputMaybe<Scalars['String']['input']>;
  default_availability?: InputMaybe<Scalars['Boolean']['input']>;
  deleted_at?: InputMaybe<Scalars['DateTime']['input']>;
  deprecate_old_session?: InputMaybe<Scalars['Boolean']['input']>;
  email: Scalars['String']['input'];
  EmailSyncRecord?: InputMaybe<EmailSyncRecordCreateNestedManyWithoutUserInput>;
  favorite_leads?: InputMaybe<LeadCreateNestedManyWithoutFavorited_By_UsersInput>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  folder_permissions?: InputMaybe<FolderPermissionCreateNestedManyWithoutUserInput>;
  folders?: InputMaybe<FolderCreateNestedManyWithoutOwnerInput>;
  from_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutOld_UserInput>;
  GlobalRouting?: InputMaybe<GlobalRepRoutingCreateNestedManyWithoutRepInput>;
  google_refresh_token?: InputMaybe<Scalars['String']['input']>;
  holds_per_prospect_vs_held_demo_per_dial?: InputMaybe<Scalars['Float']['input']>;
  hook_subscriptions?: InputMaybe<WebHookSubscriptionCreateNestedManyWithoutUserInput>;
  hubspot_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  idle_owned_leads?: InputMaybe<LeadCreateNestedManyWithoutSecondary_RepsInput>;
  industry_filter?: InputMaybe<Scalars['Boolean']['input']>;
  industry_white_list_array?: InputMaybe<UserCreateindustry_White_List_ArrayInput>;
  industry_white_list_assign_type?: InputMaybe<AssignType>;
  joinMe_access_token?: InputMaybe<Scalars['String']['input']>;
  joinMe_refresh_token?: InputMaybe<Scalars['String']['input']>;
  last_demo_transferred_at?: InputMaybe<Scalars['DateTime']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutUserInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryCreateNestedManyWithoutUserInput>;
  lead_intents?: InputMaybe<LeadIntentCreateNestedManyWithoutUserInput>;
  lead_source_filter?: InputMaybe<Scalars['Boolean']['input']>;
  lead_source_white_list_array?: InputMaybe<UserCreatelead_Source_White_List_ArrayInput>;
  lead_source_white_list_assign_type?: InputMaybe<AssignType>;
  leads_last_called?: InputMaybe<LeadCreateNestedManyWithoutLast_Call_Result_RepInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusCreateNestedManyWithoutUserInput>;
  manager_id?: InputMaybe<Scalars['String']['input']>;
  note_item?: InputMaybe<NoteItemCreateNestedManyWithoutUserInput>;
  notifications?: InputMaybe<NotificationCreateNestedManyWithoutUserInput>;
  organization: OrganizationCreateNestedOneWithoutUsersInput;
  owned_leads?: InputMaybe<LeadCreateNestedManyWithoutRepInput>;
  panda_doc_access?: InputMaybe<Scalars['Boolean']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  payment_settings?: InputMaybe<OrganizationPaymentSettingsCreateNestedManyWithoutCreated_ByInput>;
  payment_settings_updated?: InputMaybe<OrganizationPaymentSettingsCreateNestedManyWithoutLast_Updated_ByInput>;
  phone_number?: InputMaybe<Scalars['String']['input']>;
  post?: InputMaybe<PostCreateNestedManyWithoutAuthorInput>;
  ProductsCreated?: InputMaybe<ProductChangeLogCreateNestedManyWithoutUpdated_ByInput>;
  profile_image?: InputMaybe<Scalars['String']['input']>;
  receive_transfer_attempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutTo_UserInput>;
  receiver_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutReceiver_UserInput>;
  regions_white_list_array?: InputMaybe<UserCreateregions_White_List_ArrayInput>;
  regions_white_list_assign_type?: InputMaybe<AssignType>;
  rep_scores?: InputMaybe<Scalars['Float']['input']>;
  role?: InputMaybe<Role>;
  routings?: InputMaybe<RoutingCreateNestedManyWithoutRepInput>;
  sale_config?: InputMaybe<SaleConfigCreateNestedManyWithoutCreated_ByInput>;
  sale_config_updated?: InputMaybe<SaleConfigCreateNestedManyWithoutLast_Updated_ByInput>;
  sales_team_column_options?: InputMaybe<UserCreatesales_Team_Column_OptionsInput>;
  schedule_items?: InputMaybe<ScheduleItemCreateNestedManyWithoutUserInput>;
  send_transfer_attempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutFrom_UserInput>;
  sender_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutSender_UserInput>;
  sequence_entry_criteria_id?: InputMaybe<Scalars['String']['input']>;
  SequenceActionLog?: InputMaybe<StepActionLogCreateNestedManyWithoutRepInput>;
  sequenceDashboardView?: InputMaybe<SequenceDashboardViewCreateNestedManyWithoutOwned_ByInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutUserInput>;
  Sequences_created?: InputMaybe<SequenceCreateNestedManyWithoutCreated_ByInput>;
  Sequences_modified_by_user?: InputMaybe<SequenceCreateNestedManyWithoutLast_Updated_ByInput>;
  session_token?: InputMaybe<Scalars['String']['input']>;
  set_user_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutSet_UserInput>;
  sf_user_id?: InputMaybe<Scalars['String']['input']>;
  SmsItems?: InputMaybe<SmsItemCreateNestedManyWithoutRepInput>;
  SnoozeTracking?: InputMaybe<SnoozeTrackingCreateNestedManyWithoutRepInput>;
  status?: InputMaybe<Status>;
  statusHistory?: InputMaybe<UserStatusHistoryCreateNestedManyWithoutUserInput>;
  sub_industry_white_list_array?: InputMaybe<UserCreatesub_Industry_White_List_ArrayInput>;
  sub_industry_white_list_assign_type?: InputMaybe<AssignType>;
  support_rep?: InputMaybe<Scalars['Boolean']['input']>;
  taking_demos?: InputMaybe<Scalars['Boolean']['input']>;
  team?: InputMaybe<TeamCreateNestedOneWithoutMembersInput>;
  Template?: InputMaybe<TemplateCreateNestedManyWithoutLast_Modified_ByInput>;
  TemplateVersion?: InputMaybe<TemplateVersionCreateNestedManyWithoutCreated_ByInput>;
  timekit_id?: InputMaybe<Scalars['String']['input']>;
  timekit_type?: InputMaybe<Timekittype>;
  timeOffRequest?: InputMaybe<TimeOffRequestCreateNestedManyWithoutRequesting_UserInput>;
  timezone_raw?: InputMaybe<Scalars['String']['input']>;
  transfer_type?: InputMaybe<TransferType>;
  transfer_user_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutTransfer_UserInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user_custom_availability?: InputMaybe<AvailabilityCreateNestedManyWithoutUserInput>;
  user_past_tokens?: InputMaybe<UserPastTokensCreateNestedManyWithoutUserInput>;
  visible_all_leads_sm?: InputMaybe<Scalars['Boolean']['input']>;
  visible_call_report_columns?: InputMaybe<UserCreatevisible_Call_Report_ColumnsInput>;
  visible_call_report_event_columns?: InputMaybe<UserCreatevisible_Call_Report_Event_ColumnsInput>;
  visible_call_report_pipeline_columns?: InputMaybe<UserCreatevisible_Call_Report_Pipeline_ColumnsInput>;
  visible_my_leads_columns?: InputMaybe<UserCreatevisible_My_Leads_ColumnsInput>;
  voicemail_url?: InputMaybe<Scalars['String']['input']>;
};

export type UserCreateWithoutSmsItemsInput = {
  ai_user?: InputMaybe<Scalars['Boolean']['input']>;
  alternate_email?: InputMaybe<Scalars['String']['input']>;
  always_show_calendar_options?: InputMaybe<Scalars['Boolean']['input']>;
  associate_owner_rep_history?: InputMaybe<AssociationHistoryCreateNestedManyWithoutRepInput>;
  associate_user_history?: InputMaybe<AssociationHistoryCreateNestedManyWithoutUserInput>;
  auto_transfer_selection_receive_attempts?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutFrom_UserInput>;
  auto_transfer_selection_send_attempts?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutTo_UserInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptCreateNestedManyWithoutUserInput>;
  callSharingAsCoach?: InputMaybe<CallSharingCreateNestedManyWithoutCoachInput>;
  callSharingAsUser?: InputMaybe<CallSharingCreateNestedManyWithoutReceiver_UserInput>;
  channel?: InputMaybe<Channelfilter>;
  close_rate?: InputMaybe<Scalars['Float']['input']>;
  close_user_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutClose_UserInput>;
  close_user_stat?: InputMaybe<StatLeadAggregationCreateNestedManyWithoutClose_UserInput>;
  commissions?: InputMaybe<CommissionCreateNestedManyWithoutUserInput>;
  contact_requests?: InputMaybe<ContactRequestCreateNestedManyWithoutRequesting_UserInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  current_sequence_view?: InputMaybe<SequenceDashboardViewCreateNestedOneWithoutUserInput>;
  current_team_start_date?: InputMaybe<Scalars['DateTime']['input']>;
  custom_field_white_list_array?: InputMaybe<UserCustomFieldFilterCreateNestedManyWithoutUserInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemCreateNestedManyWithoutUserInput>;
  daily_lead_claim?: InputMaybe<Scalars['Int']['input']>;
  dashboard_2_access?: InputMaybe<Scalars['Boolean']['input']>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewCreateNestedManyWithoutUserInput>;
  default_audio_input?: InputMaybe<Scalars['String']['input']>;
  default_audio_output?: InputMaybe<Scalars['String']['input']>;
  default_availability?: InputMaybe<Scalars['Boolean']['input']>;
  deleted_at?: InputMaybe<Scalars['DateTime']['input']>;
  deprecate_old_session?: InputMaybe<Scalars['Boolean']['input']>;
  email: Scalars['String']['input'];
  EmailSyncRecord?: InputMaybe<EmailSyncRecordCreateNestedManyWithoutUserInput>;
  favorite_leads?: InputMaybe<LeadCreateNestedManyWithoutFavorited_By_UsersInput>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  folder_permissions?: InputMaybe<FolderPermissionCreateNestedManyWithoutUserInput>;
  folders?: InputMaybe<FolderCreateNestedManyWithoutOwnerInput>;
  from_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutOld_UserInput>;
  GlobalRouting?: InputMaybe<GlobalRepRoutingCreateNestedManyWithoutRepInput>;
  google_refresh_token?: InputMaybe<Scalars['String']['input']>;
  holds_per_prospect_vs_held_demo_per_dial?: InputMaybe<Scalars['Float']['input']>;
  hook_subscriptions?: InputMaybe<WebHookSubscriptionCreateNestedManyWithoutUserInput>;
  hubspot_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  idle_owned_leads?: InputMaybe<LeadCreateNestedManyWithoutSecondary_RepsInput>;
  industry_filter?: InputMaybe<Scalars['Boolean']['input']>;
  industry_white_list_array?: InputMaybe<UserCreateindustry_White_List_ArrayInput>;
  industry_white_list_assign_type?: InputMaybe<AssignType>;
  joinMe_access_token?: InputMaybe<Scalars['String']['input']>;
  joinMe_refresh_token?: InputMaybe<Scalars['String']['input']>;
  last_demo_transferred_at?: InputMaybe<Scalars['DateTime']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutUserInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryCreateNestedManyWithoutUserInput>;
  lead_intents?: InputMaybe<LeadIntentCreateNestedManyWithoutUserInput>;
  lead_source_filter?: InputMaybe<Scalars['Boolean']['input']>;
  lead_source_white_list_array?: InputMaybe<UserCreatelead_Source_White_List_ArrayInput>;
  lead_source_white_list_assign_type?: InputMaybe<AssignType>;
  leads_last_called?: InputMaybe<LeadCreateNestedManyWithoutLast_Call_Result_RepInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusCreateNestedManyWithoutUserInput>;
  manager_id?: InputMaybe<Scalars['String']['input']>;
  note_item?: InputMaybe<NoteItemCreateNestedManyWithoutUserInput>;
  notifications?: InputMaybe<NotificationCreateNestedManyWithoutUserInput>;
  organization: OrganizationCreateNestedOneWithoutUsersInput;
  owned_leads?: InputMaybe<LeadCreateNestedManyWithoutRepInput>;
  panda_doc_access?: InputMaybe<Scalars['Boolean']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  payment_settings?: InputMaybe<OrganizationPaymentSettingsCreateNestedManyWithoutCreated_ByInput>;
  payment_settings_updated?: InputMaybe<OrganizationPaymentSettingsCreateNestedManyWithoutLast_Updated_ByInput>;
  phone_number?: InputMaybe<Scalars['String']['input']>;
  post?: InputMaybe<PostCreateNestedManyWithoutAuthorInput>;
  ProductsCreated?: InputMaybe<ProductChangeLogCreateNestedManyWithoutUpdated_ByInput>;
  profile_image?: InputMaybe<Scalars['String']['input']>;
  receive_transfer_attempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutTo_UserInput>;
  receiver_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutReceiver_UserInput>;
  regions_white_list_array?: InputMaybe<UserCreateregions_White_List_ArrayInput>;
  regions_white_list_assign_type?: InputMaybe<AssignType>;
  rep_scores?: InputMaybe<Scalars['Float']['input']>;
  role?: InputMaybe<Role>;
  routings?: InputMaybe<RoutingCreateNestedManyWithoutRepInput>;
  sale_config?: InputMaybe<SaleConfigCreateNestedManyWithoutCreated_ByInput>;
  sale_config_updated?: InputMaybe<SaleConfigCreateNestedManyWithoutLast_Updated_ByInput>;
  sales_team_column_options?: InputMaybe<UserCreatesales_Team_Column_OptionsInput>;
  schedule_items?: InputMaybe<ScheduleItemCreateNestedManyWithoutUserInput>;
  send_transfer_attempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutFrom_UserInput>;
  sender_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutSender_UserInput>;
  sequence_entry_criteria_id?: InputMaybe<Scalars['String']['input']>;
  SequenceActionLog?: InputMaybe<StepActionLogCreateNestedManyWithoutRepInput>;
  sequenceDashboardView?: InputMaybe<SequenceDashboardViewCreateNestedManyWithoutOwned_ByInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutUserInput>;
  Sequences_created?: InputMaybe<SequenceCreateNestedManyWithoutCreated_ByInput>;
  Sequences_modified_by_user?: InputMaybe<SequenceCreateNestedManyWithoutLast_Updated_ByInput>;
  session_token?: InputMaybe<Scalars['String']['input']>;
  set_user_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutSet_UserInput>;
  set_user_stat?: InputMaybe<StatLeadAggregationCreateNestedManyWithoutSet_UserInput>;
  sf_user_id?: InputMaybe<Scalars['String']['input']>;
  SnoozeTracking?: InputMaybe<SnoozeTrackingCreateNestedManyWithoutRepInput>;
  status?: InputMaybe<Status>;
  statusHistory?: InputMaybe<UserStatusHistoryCreateNestedManyWithoutUserInput>;
  sub_industry_white_list_array?: InputMaybe<UserCreatesub_Industry_White_List_ArrayInput>;
  sub_industry_white_list_assign_type?: InputMaybe<AssignType>;
  support_rep?: InputMaybe<Scalars['Boolean']['input']>;
  taking_demos?: InputMaybe<Scalars['Boolean']['input']>;
  team?: InputMaybe<TeamCreateNestedOneWithoutMembersInput>;
  Template?: InputMaybe<TemplateCreateNestedManyWithoutLast_Modified_ByInput>;
  TemplateVersion?: InputMaybe<TemplateVersionCreateNestedManyWithoutCreated_ByInput>;
  timekit_id?: InputMaybe<Scalars['String']['input']>;
  timekit_type?: InputMaybe<Timekittype>;
  timeOffRequest?: InputMaybe<TimeOffRequestCreateNestedManyWithoutRequesting_UserInput>;
  timezone_raw?: InputMaybe<Scalars['String']['input']>;
  transfer_type?: InputMaybe<TransferType>;
  transfer_user_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutTransfer_UserInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user_custom_availability?: InputMaybe<AvailabilityCreateNestedManyWithoutUserInput>;
  user_past_tokens?: InputMaybe<UserPastTokensCreateNestedManyWithoutUserInput>;
  visible_all_leads_sm?: InputMaybe<Scalars['Boolean']['input']>;
  visible_call_report_columns?: InputMaybe<UserCreatevisible_Call_Report_ColumnsInput>;
  visible_call_report_event_columns?: InputMaybe<UserCreatevisible_Call_Report_Event_ColumnsInput>;
  visible_call_report_pipeline_columns?: InputMaybe<UserCreatevisible_Call_Report_Pipeline_ColumnsInput>;
  visible_my_leads_columns?: InputMaybe<UserCreatevisible_My_Leads_ColumnsInput>;
  voicemail_url?: InputMaybe<Scalars['String']['input']>;
};

export type UserCreateWithoutSnoozeTrackingInput = {
  ai_user?: InputMaybe<Scalars['Boolean']['input']>;
  alternate_email?: InputMaybe<Scalars['String']['input']>;
  always_show_calendar_options?: InputMaybe<Scalars['Boolean']['input']>;
  associate_owner_rep_history?: InputMaybe<AssociationHistoryCreateNestedManyWithoutRepInput>;
  associate_user_history?: InputMaybe<AssociationHistoryCreateNestedManyWithoutUserInput>;
  auto_transfer_selection_receive_attempts?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutFrom_UserInput>;
  auto_transfer_selection_send_attempts?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutTo_UserInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptCreateNestedManyWithoutUserInput>;
  callSharingAsCoach?: InputMaybe<CallSharingCreateNestedManyWithoutCoachInput>;
  callSharingAsUser?: InputMaybe<CallSharingCreateNestedManyWithoutReceiver_UserInput>;
  channel?: InputMaybe<Channelfilter>;
  close_rate?: InputMaybe<Scalars['Float']['input']>;
  close_user_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutClose_UserInput>;
  close_user_stat?: InputMaybe<StatLeadAggregationCreateNestedManyWithoutClose_UserInput>;
  commissions?: InputMaybe<CommissionCreateNestedManyWithoutUserInput>;
  contact_requests?: InputMaybe<ContactRequestCreateNestedManyWithoutRequesting_UserInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  current_sequence_view?: InputMaybe<SequenceDashboardViewCreateNestedOneWithoutUserInput>;
  current_team_start_date?: InputMaybe<Scalars['DateTime']['input']>;
  custom_field_white_list_array?: InputMaybe<UserCustomFieldFilterCreateNestedManyWithoutUserInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemCreateNestedManyWithoutUserInput>;
  daily_lead_claim?: InputMaybe<Scalars['Int']['input']>;
  dashboard_2_access?: InputMaybe<Scalars['Boolean']['input']>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewCreateNestedManyWithoutUserInput>;
  default_audio_input?: InputMaybe<Scalars['String']['input']>;
  default_audio_output?: InputMaybe<Scalars['String']['input']>;
  default_availability?: InputMaybe<Scalars['Boolean']['input']>;
  deleted_at?: InputMaybe<Scalars['DateTime']['input']>;
  deprecate_old_session?: InputMaybe<Scalars['Boolean']['input']>;
  email: Scalars['String']['input'];
  EmailSyncRecord?: InputMaybe<EmailSyncRecordCreateNestedManyWithoutUserInput>;
  favorite_leads?: InputMaybe<LeadCreateNestedManyWithoutFavorited_By_UsersInput>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  folder_permissions?: InputMaybe<FolderPermissionCreateNestedManyWithoutUserInput>;
  folders?: InputMaybe<FolderCreateNestedManyWithoutOwnerInput>;
  from_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutOld_UserInput>;
  GlobalRouting?: InputMaybe<GlobalRepRoutingCreateNestedManyWithoutRepInput>;
  google_refresh_token?: InputMaybe<Scalars['String']['input']>;
  holds_per_prospect_vs_held_demo_per_dial?: InputMaybe<Scalars['Float']['input']>;
  hook_subscriptions?: InputMaybe<WebHookSubscriptionCreateNestedManyWithoutUserInput>;
  hubspot_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  idle_owned_leads?: InputMaybe<LeadCreateNestedManyWithoutSecondary_RepsInput>;
  industry_filter?: InputMaybe<Scalars['Boolean']['input']>;
  industry_white_list_array?: InputMaybe<UserCreateindustry_White_List_ArrayInput>;
  industry_white_list_assign_type?: InputMaybe<AssignType>;
  joinMe_access_token?: InputMaybe<Scalars['String']['input']>;
  joinMe_refresh_token?: InputMaybe<Scalars['String']['input']>;
  last_demo_transferred_at?: InputMaybe<Scalars['DateTime']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutUserInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryCreateNestedManyWithoutUserInput>;
  lead_intents?: InputMaybe<LeadIntentCreateNestedManyWithoutUserInput>;
  lead_source_filter?: InputMaybe<Scalars['Boolean']['input']>;
  lead_source_white_list_array?: InputMaybe<UserCreatelead_Source_White_List_ArrayInput>;
  lead_source_white_list_assign_type?: InputMaybe<AssignType>;
  leads_last_called?: InputMaybe<LeadCreateNestedManyWithoutLast_Call_Result_RepInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusCreateNestedManyWithoutUserInput>;
  manager_id?: InputMaybe<Scalars['String']['input']>;
  note_item?: InputMaybe<NoteItemCreateNestedManyWithoutUserInput>;
  notifications?: InputMaybe<NotificationCreateNestedManyWithoutUserInput>;
  organization: OrganizationCreateNestedOneWithoutUsersInput;
  owned_leads?: InputMaybe<LeadCreateNestedManyWithoutRepInput>;
  panda_doc_access?: InputMaybe<Scalars['Boolean']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  payment_settings?: InputMaybe<OrganizationPaymentSettingsCreateNestedManyWithoutCreated_ByInput>;
  payment_settings_updated?: InputMaybe<OrganizationPaymentSettingsCreateNestedManyWithoutLast_Updated_ByInput>;
  phone_number?: InputMaybe<Scalars['String']['input']>;
  post?: InputMaybe<PostCreateNestedManyWithoutAuthorInput>;
  ProductsCreated?: InputMaybe<ProductChangeLogCreateNestedManyWithoutUpdated_ByInput>;
  profile_image?: InputMaybe<Scalars['String']['input']>;
  receive_transfer_attempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutTo_UserInput>;
  receiver_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutReceiver_UserInput>;
  regions_white_list_array?: InputMaybe<UserCreateregions_White_List_ArrayInput>;
  regions_white_list_assign_type?: InputMaybe<AssignType>;
  rep_scores?: InputMaybe<Scalars['Float']['input']>;
  role?: InputMaybe<Role>;
  routings?: InputMaybe<RoutingCreateNestedManyWithoutRepInput>;
  sale_config?: InputMaybe<SaleConfigCreateNestedManyWithoutCreated_ByInput>;
  sale_config_updated?: InputMaybe<SaleConfigCreateNestedManyWithoutLast_Updated_ByInput>;
  sales_team_column_options?: InputMaybe<UserCreatesales_Team_Column_OptionsInput>;
  schedule_items?: InputMaybe<ScheduleItemCreateNestedManyWithoutUserInput>;
  send_transfer_attempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutFrom_UserInput>;
  sender_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutSender_UserInput>;
  sequence_entry_criteria_id?: InputMaybe<Scalars['String']['input']>;
  SequenceActionLog?: InputMaybe<StepActionLogCreateNestedManyWithoutRepInput>;
  sequenceDashboardView?: InputMaybe<SequenceDashboardViewCreateNestedManyWithoutOwned_ByInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutUserInput>;
  Sequences_created?: InputMaybe<SequenceCreateNestedManyWithoutCreated_ByInput>;
  Sequences_modified_by_user?: InputMaybe<SequenceCreateNestedManyWithoutLast_Updated_ByInput>;
  session_token?: InputMaybe<Scalars['String']['input']>;
  set_user_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutSet_UserInput>;
  set_user_stat?: InputMaybe<StatLeadAggregationCreateNestedManyWithoutSet_UserInput>;
  sf_user_id?: InputMaybe<Scalars['String']['input']>;
  SmsItems?: InputMaybe<SmsItemCreateNestedManyWithoutRepInput>;
  status?: InputMaybe<Status>;
  statusHistory?: InputMaybe<UserStatusHistoryCreateNestedManyWithoutUserInput>;
  sub_industry_white_list_array?: InputMaybe<UserCreatesub_Industry_White_List_ArrayInput>;
  sub_industry_white_list_assign_type?: InputMaybe<AssignType>;
  support_rep?: InputMaybe<Scalars['Boolean']['input']>;
  taking_demos?: InputMaybe<Scalars['Boolean']['input']>;
  team?: InputMaybe<TeamCreateNestedOneWithoutMembersInput>;
  Template?: InputMaybe<TemplateCreateNestedManyWithoutLast_Modified_ByInput>;
  TemplateVersion?: InputMaybe<TemplateVersionCreateNestedManyWithoutCreated_ByInput>;
  timekit_id?: InputMaybe<Scalars['String']['input']>;
  timekit_type?: InputMaybe<Timekittype>;
  timeOffRequest?: InputMaybe<TimeOffRequestCreateNestedManyWithoutRequesting_UserInput>;
  timezone_raw?: InputMaybe<Scalars['String']['input']>;
  transfer_type?: InputMaybe<TransferType>;
  transfer_user_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutTransfer_UserInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user_custom_availability?: InputMaybe<AvailabilityCreateNestedManyWithoutUserInput>;
  user_past_tokens?: InputMaybe<UserPastTokensCreateNestedManyWithoutUserInput>;
  visible_all_leads_sm?: InputMaybe<Scalars['Boolean']['input']>;
  visible_call_report_columns?: InputMaybe<UserCreatevisible_Call_Report_ColumnsInput>;
  visible_call_report_event_columns?: InputMaybe<UserCreatevisible_Call_Report_Event_ColumnsInput>;
  visible_call_report_pipeline_columns?: InputMaybe<UserCreatevisible_Call_Report_Pipeline_ColumnsInput>;
  visible_my_leads_columns?: InputMaybe<UserCreatevisible_My_Leads_ColumnsInput>;
  voicemail_url?: InputMaybe<Scalars['String']['input']>;
};

export type UserCreateWithoutTeamInput = {
  ai_user?: InputMaybe<Scalars['Boolean']['input']>;
  alternate_email?: InputMaybe<Scalars['String']['input']>;
  always_show_calendar_options?: InputMaybe<Scalars['Boolean']['input']>;
  associate_owner_rep_history?: InputMaybe<AssociationHistoryCreateNestedManyWithoutRepInput>;
  associate_user_history?: InputMaybe<AssociationHistoryCreateNestedManyWithoutUserInput>;
  auto_transfer_selection_receive_attempts?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutFrom_UserInput>;
  auto_transfer_selection_send_attempts?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutTo_UserInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptCreateNestedManyWithoutUserInput>;
  callSharingAsCoach?: InputMaybe<CallSharingCreateNestedManyWithoutCoachInput>;
  callSharingAsUser?: InputMaybe<CallSharingCreateNestedManyWithoutReceiver_UserInput>;
  channel?: InputMaybe<Channelfilter>;
  close_rate?: InputMaybe<Scalars['Float']['input']>;
  close_user_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutClose_UserInput>;
  close_user_stat?: InputMaybe<StatLeadAggregationCreateNestedManyWithoutClose_UserInput>;
  commissions?: InputMaybe<CommissionCreateNestedManyWithoutUserInput>;
  contact_requests?: InputMaybe<ContactRequestCreateNestedManyWithoutRequesting_UserInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  current_sequence_view?: InputMaybe<SequenceDashboardViewCreateNestedOneWithoutUserInput>;
  current_team_start_date?: InputMaybe<Scalars['DateTime']['input']>;
  custom_field_white_list_array?: InputMaybe<UserCustomFieldFilterCreateNestedManyWithoutUserInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemCreateNestedManyWithoutUserInput>;
  daily_lead_claim?: InputMaybe<Scalars['Int']['input']>;
  dashboard_2_access?: InputMaybe<Scalars['Boolean']['input']>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewCreateNestedManyWithoutUserInput>;
  default_audio_input?: InputMaybe<Scalars['String']['input']>;
  default_audio_output?: InputMaybe<Scalars['String']['input']>;
  default_availability?: InputMaybe<Scalars['Boolean']['input']>;
  deleted_at?: InputMaybe<Scalars['DateTime']['input']>;
  deprecate_old_session?: InputMaybe<Scalars['Boolean']['input']>;
  email: Scalars['String']['input'];
  EmailSyncRecord?: InputMaybe<EmailSyncRecordCreateNestedManyWithoutUserInput>;
  favorite_leads?: InputMaybe<LeadCreateNestedManyWithoutFavorited_By_UsersInput>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  folder_permissions?: InputMaybe<FolderPermissionCreateNestedManyWithoutUserInput>;
  folders?: InputMaybe<FolderCreateNestedManyWithoutOwnerInput>;
  from_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutOld_UserInput>;
  GlobalRouting?: InputMaybe<GlobalRepRoutingCreateNestedManyWithoutRepInput>;
  google_refresh_token?: InputMaybe<Scalars['String']['input']>;
  holds_per_prospect_vs_held_demo_per_dial?: InputMaybe<Scalars['Float']['input']>;
  hook_subscriptions?: InputMaybe<WebHookSubscriptionCreateNestedManyWithoutUserInput>;
  hubspot_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  idle_owned_leads?: InputMaybe<LeadCreateNestedManyWithoutSecondary_RepsInput>;
  industry_filter?: InputMaybe<Scalars['Boolean']['input']>;
  industry_white_list_array?: InputMaybe<UserCreateindustry_White_List_ArrayInput>;
  industry_white_list_assign_type?: InputMaybe<AssignType>;
  joinMe_access_token?: InputMaybe<Scalars['String']['input']>;
  joinMe_refresh_token?: InputMaybe<Scalars['String']['input']>;
  last_demo_transferred_at?: InputMaybe<Scalars['DateTime']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutUserInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryCreateNestedManyWithoutUserInput>;
  lead_intents?: InputMaybe<LeadIntentCreateNestedManyWithoutUserInput>;
  lead_source_filter?: InputMaybe<Scalars['Boolean']['input']>;
  lead_source_white_list_array?: InputMaybe<UserCreatelead_Source_White_List_ArrayInput>;
  lead_source_white_list_assign_type?: InputMaybe<AssignType>;
  leads_last_called?: InputMaybe<LeadCreateNestedManyWithoutLast_Call_Result_RepInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusCreateNestedManyWithoutUserInput>;
  manager_id?: InputMaybe<Scalars['String']['input']>;
  note_item?: InputMaybe<NoteItemCreateNestedManyWithoutUserInput>;
  notifications?: InputMaybe<NotificationCreateNestedManyWithoutUserInput>;
  organization: OrganizationCreateNestedOneWithoutUsersInput;
  owned_leads?: InputMaybe<LeadCreateNestedManyWithoutRepInput>;
  panda_doc_access?: InputMaybe<Scalars['Boolean']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  payment_settings?: InputMaybe<OrganizationPaymentSettingsCreateNestedManyWithoutCreated_ByInput>;
  payment_settings_updated?: InputMaybe<OrganizationPaymentSettingsCreateNestedManyWithoutLast_Updated_ByInput>;
  phone_number?: InputMaybe<Scalars['String']['input']>;
  post?: InputMaybe<PostCreateNestedManyWithoutAuthorInput>;
  ProductsCreated?: InputMaybe<ProductChangeLogCreateNestedManyWithoutUpdated_ByInput>;
  profile_image?: InputMaybe<Scalars['String']['input']>;
  receive_transfer_attempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutTo_UserInput>;
  receiver_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutReceiver_UserInput>;
  regions_white_list_array?: InputMaybe<UserCreateregions_White_List_ArrayInput>;
  regions_white_list_assign_type?: InputMaybe<AssignType>;
  rep_scores?: InputMaybe<Scalars['Float']['input']>;
  role?: InputMaybe<Role>;
  routings?: InputMaybe<RoutingCreateNestedManyWithoutRepInput>;
  sale_config?: InputMaybe<SaleConfigCreateNestedManyWithoutCreated_ByInput>;
  sale_config_updated?: InputMaybe<SaleConfigCreateNestedManyWithoutLast_Updated_ByInput>;
  sales_team_column_options?: InputMaybe<UserCreatesales_Team_Column_OptionsInput>;
  schedule_items?: InputMaybe<ScheduleItemCreateNestedManyWithoutUserInput>;
  send_transfer_attempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutFrom_UserInput>;
  sender_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutSender_UserInput>;
  sequence_entry_criteria_id?: InputMaybe<Scalars['String']['input']>;
  SequenceActionLog?: InputMaybe<StepActionLogCreateNestedManyWithoutRepInput>;
  sequenceDashboardView?: InputMaybe<SequenceDashboardViewCreateNestedManyWithoutOwned_ByInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutUserInput>;
  Sequences_created?: InputMaybe<SequenceCreateNestedManyWithoutCreated_ByInput>;
  Sequences_modified_by_user?: InputMaybe<SequenceCreateNestedManyWithoutLast_Updated_ByInput>;
  session_token?: InputMaybe<Scalars['String']['input']>;
  set_user_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutSet_UserInput>;
  set_user_stat?: InputMaybe<StatLeadAggregationCreateNestedManyWithoutSet_UserInput>;
  sf_user_id?: InputMaybe<Scalars['String']['input']>;
  SmsItems?: InputMaybe<SmsItemCreateNestedManyWithoutRepInput>;
  SnoozeTracking?: InputMaybe<SnoozeTrackingCreateNestedManyWithoutRepInput>;
  status?: InputMaybe<Status>;
  statusHistory?: InputMaybe<UserStatusHistoryCreateNestedManyWithoutUserInput>;
  sub_industry_white_list_array?: InputMaybe<UserCreatesub_Industry_White_List_ArrayInput>;
  sub_industry_white_list_assign_type?: InputMaybe<AssignType>;
  support_rep?: InputMaybe<Scalars['Boolean']['input']>;
  taking_demos?: InputMaybe<Scalars['Boolean']['input']>;
  Template?: InputMaybe<TemplateCreateNestedManyWithoutLast_Modified_ByInput>;
  TemplateVersion?: InputMaybe<TemplateVersionCreateNestedManyWithoutCreated_ByInput>;
  timekit_id?: InputMaybe<Scalars['String']['input']>;
  timekit_type?: InputMaybe<Timekittype>;
  timeOffRequest?: InputMaybe<TimeOffRequestCreateNestedManyWithoutRequesting_UserInput>;
  timezone_raw?: InputMaybe<Scalars['String']['input']>;
  transfer_type?: InputMaybe<TransferType>;
  transfer_user_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutTransfer_UserInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user_custom_availability?: InputMaybe<AvailabilityCreateNestedManyWithoutUserInput>;
  user_past_tokens?: InputMaybe<UserPastTokensCreateNestedManyWithoutUserInput>;
  visible_all_leads_sm?: InputMaybe<Scalars['Boolean']['input']>;
  visible_call_report_columns?: InputMaybe<UserCreatevisible_Call_Report_ColumnsInput>;
  visible_call_report_event_columns?: InputMaybe<UserCreatevisible_Call_Report_Event_ColumnsInput>;
  visible_call_report_pipeline_columns?: InputMaybe<UserCreatevisible_Call_Report_Pipeline_ColumnsInput>;
  visible_my_leads_columns?: InputMaybe<UserCreatevisible_My_Leads_ColumnsInput>;
  voicemail_url?: InputMaybe<Scalars['String']['input']>;
};

export type UserCreateWithoutTemplateInput = {
  ai_user?: InputMaybe<Scalars['Boolean']['input']>;
  alternate_email?: InputMaybe<Scalars['String']['input']>;
  always_show_calendar_options?: InputMaybe<Scalars['Boolean']['input']>;
  associate_owner_rep_history?: InputMaybe<AssociationHistoryCreateNestedManyWithoutRepInput>;
  associate_user_history?: InputMaybe<AssociationHistoryCreateNestedManyWithoutUserInput>;
  auto_transfer_selection_receive_attempts?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutFrom_UserInput>;
  auto_transfer_selection_send_attempts?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutTo_UserInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptCreateNestedManyWithoutUserInput>;
  callSharingAsCoach?: InputMaybe<CallSharingCreateNestedManyWithoutCoachInput>;
  callSharingAsUser?: InputMaybe<CallSharingCreateNestedManyWithoutReceiver_UserInput>;
  channel?: InputMaybe<Channelfilter>;
  close_rate?: InputMaybe<Scalars['Float']['input']>;
  close_user_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutClose_UserInput>;
  close_user_stat?: InputMaybe<StatLeadAggregationCreateNestedManyWithoutClose_UserInput>;
  commissions?: InputMaybe<CommissionCreateNestedManyWithoutUserInput>;
  contact_requests?: InputMaybe<ContactRequestCreateNestedManyWithoutRequesting_UserInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  current_sequence_view?: InputMaybe<SequenceDashboardViewCreateNestedOneWithoutUserInput>;
  current_team_start_date?: InputMaybe<Scalars['DateTime']['input']>;
  custom_field_white_list_array?: InputMaybe<UserCustomFieldFilterCreateNestedManyWithoutUserInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemCreateNestedManyWithoutUserInput>;
  daily_lead_claim?: InputMaybe<Scalars['Int']['input']>;
  dashboard_2_access?: InputMaybe<Scalars['Boolean']['input']>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewCreateNestedManyWithoutUserInput>;
  default_audio_input?: InputMaybe<Scalars['String']['input']>;
  default_audio_output?: InputMaybe<Scalars['String']['input']>;
  default_availability?: InputMaybe<Scalars['Boolean']['input']>;
  deleted_at?: InputMaybe<Scalars['DateTime']['input']>;
  deprecate_old_session?: InputMaybe<Scalars['Boolean']['input']>;
  email: Scalars['String']['input'];
  EmailSyncRecord?: InputMaybe<EmailSyncRecordCreateNestedManyWithoutUserInput>;
  favorite_leads?: InputMaybe<LeadCreateNestedManyWithoutFavorited_By_UsersInput>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  folder_permissions?: InputMaybe<FolderPermissionCreateNestedManyWithoutUserInput>;
  folders?: InputMaybe<FolderCreateNestedManyWithoutOwnerInput>;
  from_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutOld_UserInput>;
  GlobalRouting?: InputMaybe<GlobalRepRoutingCreateNestedManyWithoutRepInput>;
  google_refresh_token?: InputMaybe<Scalars['String']['input']>;
  holds_per_prospect_vs_held_demo_per_dial?: InputMaybe<Scalars['Float']['input']>;
  hook_subscriptions?: InputMaybe<WebHookSubscriptionCreateNestedManyWithoutUserInput>;
  hubspot_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  idle_owned_leads?: InputMaybe<LeadCreateNestedManyWithoutSecondary_RepsInput>;
  industry_filter?: InputMaybe<Scalars['Boolean']['input']>;
  industry_white_list_array?: InputMaybe<UserCreateindustry_White_List_ArrayInput>;
  industry_white_list_assign_type?: InputMaybe<AssignType>;
  joinMe_access_token?: InputMaybe<Scalars['String']['input']>;
  joinMe_refresh_token?: InputMaybe<Scalars['String']['input']>;
  last_demo_transferred_at?: InputMaybe<Scalars['DateTime']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutUserInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryCreateNestedManyWithoutUserInput>;
  lead_intents?: InputMaybe<LeadIntentCreateNestedManyWithoutUserInput>;
  lead_source_filter?: InputMaybe<Scalars['Boolean']['input']>;
  lead_source_white_list_array?: InputMaybe<UserCreatelead_Source_White_List_ArrayInput>;
  lead_source_white_list_assign_type?: InputMaybe<AssignType>;
  leads_last_called?: InputMaybe<LeadCreateNestedManyWithoutLast_Call_Result_RepInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusCreateNestedManyWithoutUserInput>;
  manager_id?: InputMaybe<Scalars['String']['input']>;
  note_item?: InputMaybe<NoteItemCreateNestedManyWithoutUserInput>;
  notifications?: InputMaybe<NotificationCreateNestedManyWithoutUserInput>;
  organization: OrganizationCreateNestedOneWithoutUsersInput;
  owned_leads?: InputMaybe<LeadCreateNestedManyWithoutRepInput>;
  panda_doc_access?: InputMaybe<Scalars['Boolean']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  payment_settings?: InputMaybe<OrganizationPaymentSettingsCreateNestedManyWithoutCreated_ByInput>;
  payment_settings_updated?: InputMaybe<OrganizationPaymentSettingsCreateNestedManyWithoutLast_Updated_ByInput>;
  phone_number?: InputMaybe<Scalars['String']['input']>;
  post?: InputMaybe<PostCreateNestedManyWithoutAuthorInput>;
  ProductsCreated?: InputMaybe<ProductChangeLogCreateNestedManyWithoutUpdated_ByInput>;
  profile_image?: InputMaybe<Scalars['String']['input']>;
  receive_transfer_attempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutTo_UserInput>;
  receiver_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutReceiver_UserInput>;
  regions_white_list_array?: InputMaybe<UserCreateregions_White_List_ArrayInput>;
  regions_white_list_assign_type?: InputMaybe<AssignType>;
  rep_scores?: InputMaybe<Scalars['Float']['input']>;
  role?: InputMaybe<Role>;
  routings?: InputMaybe<RoutingCreateNestedManyWithoutRepInput>;
  sale_config?: InputMaybe<SaleConfigCreateNestedManyWithoutCreated_ByInput>;
  sale_config_updated?: InputMaybe<SaleConfigCreateNestedManyWithoutLast_Updated_ByInput>;
  sales_team_column_options?: InputMaybe<UserCreatesales_Team_Column_OptionsInput>;
  schedule_items?: InputMaybe<ScheduleItemCreateNestedManyWithoutUserInput>;
  send_transfer_attempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutFrom_UserInput>;
  sender_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutSender_UserInput>;
  sequence_entry_criteria_id?: InputMaybe<Scalars['String']['input']>;
  SequenceActionLog?: InputMaybe<StepActionLogCreateNestedManyWithoutRepInput>;
  sequenceDashboardView?: InputMaybe<SequenceDashboardViewCreateNestedManyWithoutOwned_ByInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutUserInput>;
  Sequences_created?: InputMaybe<SequenceCreateNestedManyWithoutCreated_ByInput>;
  Sequences_modified_by_user?: InputMaybe<SequenceCreateNestedManyWithoutLast_Updated_ByInput>;
  session_token?: InputMaybe<Scalars['String']['input']>;
  set_user_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutSet_UserInput>;
  set_user_stat?: InputMaybe<StatLeadAggregationCreateNestedManyWithoutSet_UserInput>;
  sf_user_id?: InputMaybe<Scalars['String']['input']>;
  SmsItems?: InputMaybe<SmsItemCreateNestedManyWithoutRepInput>;
  SnoozeTracking?: InputMaybe<SnoozeTrackingCreateNestedManyWithoutRepInput>;
  status?: InputMaybe<Status>;
  statusHistory?: InputMaybe<UserStatusHistoryCreateNestedManyWithoutUserInput>;
  sub_industry_white_list_array?: InputMaybe<UserCreatesub_Industry_White_List_ArrayInput>;
  sub_industry_white_list_assign_type?: InputMaybe<AssignType>;
  support_rep?: InputMaybe<Scalars['Boolean']['input']>;
  taking_demos?: InputMaybe<Scalars['Boolean']['input']>;
  team?: InputMaybe<TeamCreateNestedOneWithoutMembersInput>;
  TemplateVersion?: InputMaybe<TemplateVersionCreateNestedManyWithoutCreated_ByInput>;
  timekit_id?: InputMaybe<Scalars['String']['input']>;
  timekit_type?: InputMaybe<Timekittype>;
  timeOffRequest?: InputMaybe<TimeOffRequestCreateNestedManyWithoutRequesting_UserInput>;
  timezone_raw?: InputMaybe<Scalars['String']['input']>;
  transfer_type?: InputMaybe<TransferType>;
  transfer_user_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutTransfer_UserInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user_custom_availability?: InputMaybe<AvailabilityCreateNestedManyWithoutUserInput>;
  user_past_tokens?: InputMaybe<UserPastTokensCreateNestedManyWithoutUserInput>;
  visible_all_leads_sm?: InputMaybe<Scalars['Boolean']['input']>;
  visible_call_report_columns?: InputMaybe<UserCreatevisible_Call_Report_ColumnsInput>;
  visible_call_report_event_columns?: InputMaybe<UserCreatevisible_Call_Report_Event_ColumnsInput>;
  visible_call_report_pipeline_columns?: InputMaybe<UserCreatevisible_Call_Report_Pipeline_ColumnsInput>;
  visible_my_leads_columns?: InputMaybe<UserCreatevisible_My_Leads_ColumnsInput>;
  voicemail_url?: InputMaybe<Scalars['String']['input']>;
};

export type UserCreateWithoutTemplateVersionInput = {
  ai_user?: InputMaybe<Scalars['Boolean']['input']>;
  alternate_email?: InputMaybe<Scalars['String']['input']>;
  always_show_calendar_options?: InputMaybe<Scalars['Boolean']['input']>;
  associate_owner_rep_history?: InputMaybe<AssociationHistoryCreateNestedManyWithoutRepInput>;
  associate_user_history?: InputMaybe<AssociationHistoryCreateNestedManyWithoutUserInput>;
  auto_transfer_selection_receive_attempts?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutFrom_UserInput>;
  auto_transfer_selection_send_attempts?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutTo_UserInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptCreateNestedManyWithoutUserInput>;
  callSharingAsCoach?: InputMaybe<CallSharingCreateNestedManyWithoutCoachInput>;
  callSharingAsUser?: InputMaybe<CallSharingCreateNestedManyWithoutReceiver_UserInput>;
  channel?: InputMaybe<Channelfilter>;
  close_rate?: InputMaybe<Scalars['Float']['input']>;
  close_user_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutClose_UserInput>;
  close_user_stat?: InputMaybe<StatLeadAggregationCreateNestedManyWithoutClose_UserInput>;
  commissions?: InputMaybe<CommissionCreateNestedManyWithoutUserInput>;
  contact_requests?: InputMaybe<ContactRequestCreateNestedManyWithoutRequesting_UserInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  current_sequence_view?: InputMaybe<SequenceDashboardViewCreateNestedOneWithoutUserInput>;
  current_team_start_date?: InputMaybe<Scalars['DateTime']['input']>;
  custom_field_white_list_array?: InputMaybe<UserCustomFieldFilterCreateNestedManyWithoutUserInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemCreateNestedManyWithoutUserInput>;
  daily_lead_claim?: InputMaybe<Scalars['Int']['input']>;
  dashboard_2_access?: InputMaybe<Scalars['Boolean']['input']>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewCreateNestedManyWithoutUserInput>;
  default_audio_input?: InputMaybe<Scalars['String']['input']>;
  default_audio_output?: InputMaybe<Scalars['String']['input']>;
  default_availability?: InputMaybe<Scalars['Boolean']['input']>;
  deleted_at?: InputMaybe<Scalars['DateTime']['input']>;
  deprecate_old_session?: InputMaybe<Scalars['Boolean']['input']>;
  email: Scalars['String']['input'];
  EmailSyncRecord?: InputMaybe<EmailSyncRecordCreateNestedManyWithoutUserInput>;
  favorite_leads?: InputMaybe<LeadCreateNestedManyWithoutFavorited_By_UsersInput>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  folder_permissions?: InputMaybe<FolderPermissionCreateNestedManyWithoutUserInput>;
  folders?: InputMaybe<FolderCreateNestedManyWithoutOwnerInput>;
  from_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutOld_UserInput>;
  GlobalRouting?: InputMaybe<GlobalRepRoutingCreateNestedManyWithoutRepInput>;
  google_refresh_token?: InputMaybe<Scalars['String']['input']>;
  holds_per_prospect_vs_held_demo_per_dial?: InputMaybe<Scalars['Float']['input']>;
  hook_subscriptions?: InputMaybe<WebHookSubscriptionCreateNestedManyWithoutUserInput>;
  hubspot_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  idle_owned_leads?: InputMaybe<LeadCreateNestedManyWithoutSecondary_RepsInput>;
  industry_filter?: InputMaybe<Scalars['Boolean']['input']>;
  industry_white_list_array?: InputMaybe<UserCreateindustry_White_List_ArrayInput>;
  industry_white_list_assign_type?: InputMaybe<AssignType>;
  joinMe_access_token?: InputMaybe<Scalars['String']['input']>;
  joinMe_refresh_token?: InputMaybe<Scalars['String']['input']>;
  last_demo_transferred_at?: InputMaybe<Scalars['DateTime']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutUserInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryCreateNestedManyWithoutUserInput>;
  lead_intents?: InputMaybe<LeadIntentCreateNestedManyWithoutUserInput>;
  lead_source_filter?: InputMaybe<Scalars['Boolean']['input']>;
  lead_source_white_list_array?: InputMaybe<UserCreatelead_Source_White_List_ArrayInput>;
  lead_source_white_list_assign_type?: InputMaybe<AssignType>;
  leads_last_called?: InputMaybe<LeadCreateNestedManyWithoutLast_Call_Result_RepInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusCreateNestedManyWithoutUserInput>;
  manager_id?: InputMaybe<Scalars['String']['input']>;
  note_item?: InputMaybe<NoteItemCreateNestedManyWithoutUserInput>;
  notifications?: InputMaybe<NotificationCreateNestedManyWithoutUserInput>;
  organization: OrganizationCreateNestedOneWithoutUsersInput;
  owned_leads?: InputMaybe<LeadCreateNestedManyWithoutRepInput>;
  panda_doc_access?: InputMaybe<Scalars['Boolean']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  payment_settings?: InputMaybe<OrganizationPaymentSettingsCreateNestedManyWithoutCreated_ByInput>;
  payment_settings_updated?: InputMaybe<OrganizationPaymentSettingsCreateNestedManyWithoutLast_Updated_ByInput>;
  phone_number?: InputMaybe<Scalars['String']['input']>;
  post?: InputMaybe<PostCreateNestedManyWithoutAuthorInput>;
  ProductsCreated?: InputMaybe<ProductChangeLogCreateNestedManyWithoutUpdated_ByInput>;
  profile_image?: InputMaybe<Scalars['String']['input']>;
  receive_transfer_attempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutTo_UserInput>;
  receiver_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutReceiver_UserInput>;
  regions_white_list_array?: InputMaybe<UserCreateregions_White_List_ArrayInput>;
  regions_white_list_assign_type?: InputMaybe<AssignType>;
  rep_scores?: InputMaybe<Scalars['Float']['input']>;
  role?: InputMaybe<Role>;
  routings?: InputMaybe<RoutingCreateNestedManyWithoutRepInput>;
  sale_config?: InputMaybe<SaleConfigCreateNestedManyWithoutCreated_ByInput>;
  sale_config_updated?: InputMaybe<SaleConfigCreateNestedManyWithoutLast_Updated_ByInput>;
  sales_team_column_options?: InputMaybe<UserCreatesales_Team_Column_OptionsInput>;
  schedule_items?: InputMaybe<ScheduleItemCreateNestedManyWithoutUserInput>;
  send_transfer_attempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutFrom_UserInput>;
  sender_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutSender_UserInput>;
  sequence_entry_criteria_id?: InputMaybe<Scalars['String']['input']>;
  SequenceActionLog?: InputMaybe<StepActionLogCreateNestedManyWithoutRepInput>;
  sequenceDashboardView?: InputMaybe<SequenceDashboardViewCreateNestedManyWithoutOwned_ByInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutUserInput>;
  Sequences_created?: InputMaybe<SequenceCreateNestedManyWithoutCreated_ByInput>;
  Sequences_modified_by_user?: InputMaybe<SequenceCreateNestedManyWithoutLast_Updated_ByInput>;
  session_token?: InputMaybe<Scalars['String']['input']>;
  set_user_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutSet_UserInput>;
  set_user_stat?: InputMaybe<StatLeadAggregationCreateNestedManyWithoutSet_UserInput>;
  sf_user_id?: InputMaybe<Scalars['String']['input']>;
  SmsItems?: InputMaybe<SmsItemCreateNestedManyWithoutRepInput>;
  SnoozeTracking?: InputMaybe<SnoozeTrackingCreateNestedManyWithoutRepInput>;
  status?: InputMaybe<Status>;
  statusHistory?: InputMaybe<UserStatusHistoryCreateNestedManyWithoutUserInput>;
  sub_industry_white_list_array?: InputMaybe<UserCreatesub_Industry_White_List_ArrayInput>;
  sub_industry_white_list_assign_type?: InputMaybe<AssignType>;
  support_rep?: InputMaybe<Scalars['Boolean']['input']>;
  taking_demos?: InputMaybe<Scalars['Boolean']['input']>;
  team?: InputMaybe<TeamCreateNestedOneWithoutMembersInput>;
  Template?: InputMaybe<TemplateCreateNestedManyWithoutLast_Modified_ByInput>;
  timekit_id?: InputMaybe<Scalars['String']['input']>;
  timekit_type?: InputMaybe<Timekittype>;
  timeOffRequest?: InputMaybe<TimeOffRequestCreateNestedManyWithoutRequesting_UserInput>;
  timezone_raw?: InputMaybe<Scalars['String']['input']>;
  transfer_type?: InputMaybe<TransferType>;
  transfer_user_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutTransfer_UserInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user_custom_availability?: InputMaybe<AvailabilityCreateNestedManyWithoutUserInput>;
  user_past_tokens?: InputMaybe<UserPastTokensCreateNestedManyWithoutUserInput>;
  visible_all_leads_sm?: InputMaybe<Scalars['Boolean']['input']>;
  visible_call_report_columns?: InputMaybe<UserCreatevisible_Call_Report_ColumnsInput>;
  visible_call_report_event_columns?: InputMaybe<UserCreatevisible_Call_Report_Event_ColumnsInput>;
  visible_call_report_pipeline_columns?: InputMaybe<UserCreatevisible_Call_Report_Pipeline_ColumnsInput>;
  visible_my_leads_columns?: InputMaybe<UserCreatevisible_My_Leads_ColumnsInput>;
  voicemail_url?: InputMaybe<Scalars['String']['input']>;
};

export type UserCreateWithoutTimeOffRequestInput = {
  ai_user?: InputMaybe<Scalars['Boolean']['input']>;
  alternate_email?: InputMaybe<Scalars['String']['input']>;
  always_show_calendar_options?: InputMaybe<Scalars['Boolean']['input']>;
  associate_owner_rep_history?: InputMaybe<AssociationHistoryCreateNestedManyWithoutRepInput>;
  associate_user_history?: InputMaybe<AssociationHistoryCreateNestedManyWithoutUserInput>;
  auto_transfer_selection_receive_attempts?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutFrom_UserInput>;
  auto_transfer_selection_send_attempts?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutTo_UserInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptCreateNestedManyWithoutUserInput>;
  callSharingAsCoach?: InputMaybe<CallSharingCreateNestedManyWithoutCoachInput>;
  callSharingAsUser?: InputMaybe<CallSharingCreateNestedManyWithoutReceiver_UserInput>;
  channel?: InputMaybe<Channelfilter>;
  close_rate?: InputMaybe<Scalars['Float']['input']>;
  close_user_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutClose_UserInput>;
  close_user_stat?: InputMaybe<StatLeadAggregationCreateNestedManyWithoutClose_UserInput>;
  commissions?: InputMaybe<CommissionCreateNestedManyWithoutUserInput>;
  contact_requests?: InputMaybe<ContactRequestCreateNestedManyWithoutRequesting_UserInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  current_sequence_view?: InputMaybe<SequenceDashboardViewCreateNestedOneWithoutUserInput>;
  current_team_start_date?: InputMaybe<Scalars['DateTime']['input']>;
  custom_field_white_list_array?: InputMaybe<UserCustomFieldFilterCreateNestedManyWithoutUserInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemCreateNestedManyWithoutUserInput>;
  daily_lead_claim?: InputMaybe<Scalars['Int']['input']>;
  dashboard_2_access?: InputMaybe<Scalars['Boolean']['input']>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewCreateNestedManyWithoutUserInput>;
  default_audio_input?: InputMaybe<Scalars['String']['input']>;
  default_audio_output?: InputMaybe<Scalars['String']['input']>;
  default_availability?: InputMaybe<Scalars['Boolean']['input']>;
  deleted_at?: InputMaybe<Scalars['DateTime']['input']>;
  deprecate_old_session?: InputMaybe<Scalars['Boolean']['input']>;
  email: Scalars['String']['input'];
  EmailSyncRecord?: InputMaybe<EmailSyncRecordCreateNestedManyWithoutUserInput>;
  favorite_leads?: InputMaybe<LeadCreateNestedManyWithoutFavorited_By_UsersInput>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  folder_permissions?: InputMaybe<FolderPermissionCreateNestedManyWithoutUserInput>;
  folders?: InputMaybe<FolderCreateNestedManyWithoutOwnerInput>;
  from_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutOld_UserInput>;
  GlobalRouting?: InputMaybe<GlobalRepRoutingCreateNestedManyWithoutRepInput>;
  google_refresh_token?: InputMaybe<Scalars['String']['input']>;
  holds_per_prospect_vs_held_demo_per_dial?: InputMaybe<Scalars['Float']['input']>;
  hook_subscriptions?: InputMaybe<WebHookSubscriptionCreateNestedManyWithoutUserInput>;
  hubspot_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  idle_owned_leads?: InputMaybe<LeadCreateNestedManyWithoutSecondary_RepsInput>;
  industry_filter?: InputMaybe<Scalars['Boolean']['input']>;
  industry_white_list_array?: InputMaybe<UserCreateindustry_White_List_ArrayInput>;
  industry_white_list_assign_type?: InputMaybe<AssignType>;
  joinMe_access_token?: InputMaybe<Scalars['String']['input']>;
  joinMe_refresh_token?: InputMaybe<Scalars['String']['input']>;
  last_demo_transferred_at?: InputMaybe<Scalars['DateTime']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutUserInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryCreateNestedManyWithoutUserInput>;
  lead_intents?: InputMaybe<LeadIntentCreateNestedManyWithoutUserInput>;
  lead_source_filter?: InputMaybe<Scalars['Boolean']['input']>;
  lead_source_white_list_array?: InputMaybe<UserCreatelead_Source_White_List_ArrayInput>;
  lead_source_white_list_assign_type?: InputMaybe<AssignType>;
  leads_last_called?: InputMaybe<LeadCreateNestedManyWithoutLast_Call_Result_RepInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusCreateNestedManyWithoutUserInput>;
  manager_id?: InputMaybe<Scalars['String']['input']>;
  note_item?: InputMaybe<NoteItemCreateNestedManyWithoutUserInput>;
  notifications?: InputMaybe<NotificationCreateNestedManyWithoutUserInput>;
  organization: OrganizationCreateNestedOneWithoutUsersInput;
  owned_leads?: InputMaybe<LeadCreateNestedManyWithoutRepInput>;
  panda_doc_access?: InputMaybe<Scalars['Boolean']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  payment_settings?: InputMaybe<OrganizationPaymentSettingsCreateNestedManyWithoutCreated_ByInput>;
  payment_settings_updated?: InputMaybe<OrganizationPaymentSettingsCreateNestedManyWithoutLast_Updated_ByInput>;
  phone_number?: InputMaybe<Scalars['String']['input']>;
  post?: InputMaybe<PostCreateNestedManyWithoutAuthorInput>;
  ProductsCreated?: InputMaybe<ProductChangeLogCreateNestedManyWithoutUpdated_ByInput>;
  profile_image?: InputMaybe<Scalars['String']['input']>;
  receive_transfer_attempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutTo_UserInput>;
  receiver_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutReceiver_UserInput>;
  regions_white_list_array?: InputMaybe<UserCreateregions_White_List_ArrayInput>;
  regions_white_list_assign_type?: InputMaybe<AssignType>;
  rep_scores?: InputMaybe<Scalars['Float']['input']>;
  role?: InputMaybe<Role>;
  routings?: InputMaybe<RoutingCreateNestedManyWithoutRepInput>;
  sale_config?: InputMaybe<SaleConfigCreateNestedManyWithoutCreated_ByInput>;
  sale_config_updated?: InputMaybe<SaleConfigCreateNestedManyWithoutLast_Updated_ByInput>;
  sales_team_column_options?: InputMaybe<UserCreatesales_Team_Column_OptionsInput>;
  schedule_items?: InputMaybe<ScheduleItemCreateNestedManyWithoutUserInput>;
  send_transfer_attempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutFrom_UserInput>;
  sender_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutSender_UserInput>;
  sequence_entry_criteria_id?: InputMaybe<Scalars['String']['input']>;
  SequenceActionLog?: InputMaybe<StepActionLogCreateNestedManyWithoutRepInput>;
  sequenceDashboardView?: InputMaybe<SequenceDashboardViewCreateNestedManyWithoutOwned_ByInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutUserInput>;
  Sequences_created?: InputMaybe<SequenceCreateNestedManyWithoutCreated_ByInput>;
  Sequences_modified_by_user?: InputMaybe<SequenceCreateNestedManyWithoutLast_Updated_ByInput>;
  session_token?: InputMaybe<Scalars['String']['input']>;
  set_user_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutSet_UserInput>;
  set_user_stat?: InputMaybe<StatLeadAggregationCreateNestedManyWithoutSet_UserInput>;
  sf_user_id?: InputMaybe<Scalars['String']['input']>;
  SmsItems?: InputMaybe<SmsItemCreateNestedManyWithoutRepInput>;
  SnoozeTracking?: InputMaybe<SnoozeTrackingCreateNestedManyWithoutRepInput>;
  status?: InputMaybe<Status>;
  statusHistory?: InputMaybe<UserStatusHistoryCreateNestedManyWithoutUserInput>;
  sub_industry_white_list_array?: InputMaybe<UserCreatesub_Industry_White_List_ArrayInput>;
  sub_industry_white_list_assign_type?: InputMaybe<AssignType>;
  support_rep?: InputMaybe<Scalars['Boolean']['input']>;
  taking_demos?: InputMaybe<Scalars['Boolean']['input']>;
  team?: InputMaybe<TeamCreateNestedOneWithoutMembersInput>;
  Template?: InputMaybe<TemplateCreateNestedManyWithoutLast_Modified_ByInput>;
  TemplateVersion?: InputMaybe<TemplateVersionCreateNestedManyWithoutCreated_ByInput>;
  timekit_id?: InputMaybe<Scalars['String']['input']>;
  timekit_type?: InputMaybe<Timekittype>;
  timezone_raw?: InputMaybe<Scalars['String']['input']>;
  transfer_type?: InputMaybe<TransferType>;
  transfer_user_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutTransfer_UserInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user_custom_availability?: InputMaybe<AvailabilityCreateNestedManyWithoutUserInput>;
  user_past_tokens?: InputMaybe<UserPastTokensCreateNestedManyWithoutUserInput>;
  visible_all_leads_sm?: InputMaybe<Scalars['Boolean']['input']>;
  visible_call_report_columns?: InputMaybe<UserCreatevisible_Call_Report_ColumnsInput>;
  visible_call_report_event_columns?: InputMaybe<UserCreatevisible_Call_Report_Event_ColumnsInput>;
  visible_call_report_pipeline_columns?: InputMaybe<UserCreatevisible_Call_Report_Pipeline_ColumnsInput>;
  visible_my_leads_columns?: InputMaybe<UserCreatevisible_My_Leads_ColumnsInput>;
  voicemail_url?: InputMaybe<Scalars['String']['input']>;
};

export type UserCreateWithoutTransfer_User_CycleInput = {
  ai_user?: InputMaybe<Scalars['Boolean']['input']>;
  alternate_email?: InputMaybe<Scalars['String']['input']>;
  always_show_calendar_options?: InputMaybe<Scalars['Boolean']['input']>;
  associate_owner_rep_history?: InputMaybe<AssociationHistoryCreateNestedManyWithoutRepInput>;
  associate_user_history?: InputMaybe<AssociationHistoryCreateNestedManyWithoutUserInput>;
  auto_transfer_selection_receive_attempts?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutFrom_UserInput>;
  auto_transfer_selection_send_attempts?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutTo_UserInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptCreateNestedManyWithoutUserInput>;
  callSharingAsCoach?: InputMaybe<CallSharingCreateNestedManyWithoutCoachInput>;
  callSharingAsUser?: InputMaybe<CallSharingCreateNestedManyWithoutReceiver_UserInput>;
  channel?: InputMaybe<Channelfilter>;
  close_rate?: InputMaybe<Scalars['Float']['input']>;
  close_user_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutClose_UserInput>;
  close_user_stat?: InputMaybe<StatLeadAggregationCreateNestedManyWithoutClose_UserInput>;
  commissions?: InputMaybe<CommissionCreateNestedManyWithoutUserInput>;
  contact_requests?: InputMaybe<ContactRequestCreateNestedManyWithoutRequesting_UserInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  current_sequence_view?: InputMaybe<SequenceDashboardViewCreateNestedOneWithoutUserInput>;
  current_team_start_date?: InputMaybe<Scalars['DateTime']['input']>;
  custom_field_white_list_array?: InputMaybe<UserCustomFieldFilterCreateNestedManyWithoutUserInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemCreateNestedManyWithoutUserInput>;
  daily_lead_claim?: InputMaybe<Scalars['Int']['input']>;
  dashboard_2_access?: InputMaybe<Scalars['Boolean']['input']>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewCreateNestedManyWithoutUserInput>;
  default_audio_input?: InputMaybe<Scalars['String']['input']>;
  default_audio_output?: InputMaybe<Scalars['String']['input']>;
  default_availability?: InputMaybe<Scalars['Boolean']['input']>;
  deleted_at?: InputMaybe<Scalars['DateTime']['input']>;
  deprecate_old_session?: InputMaybe<Scalars['Boolean']['input']>;
  email: Scalars['String']['input'];
  EmailSyncRecord?: InputMaybe<EmailSyncRecordCreateNestedManyWithoutUserInput>;
  favorite_leads?: InputMaybe<LeadCreateNestedManyWithoutFavorited_By_UsersInput>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  folder_permissions?: InputMaybe<FolderPermissionCreateNestedManyWithoutUserInput>;
  folders?: InputMaybe<FolderCreateNestedManyWithoutOwnerInput>;
  from_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutOld_UserInput>;
  GlobalRouting?: InputMaybe<GlobalRepRoutingCreateNestedManyWithoutRepInput>;
  google_refresh_token?: InputMaybe<Scalars['String']['input']>;
  holds_per_prospect_vs_held_demo_per_dial?: InputMaybe<Scalars['Float']['input']>;
  hook_subscriptions?: InputMaybe<WebHookSubscriptionCreateNestedManyWithoutUserInput>;
  hubspot_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  idle_owned_leads?: InputMaybe<LeadCreateNestedManyWithoutSecondary_RepsInput>;
  industry_filter?: InputMaybe<Scalars['Boolean']['input']>;
  industry_white_list_array?: InputMaybe<UserCreateindustry_White_List_ArrayInput>;
  industry_white_list_assign_type?: InputMaybe<AssignType>;
  joinMe_access_token?: InputMaybe<Scalars['String']['input']>;
  joinMe_refresh_token?: InputMaybe<Scalars['String']['input']>;
  last_demo_transferred_at?: InputMaybe<Scalars['DateTime']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutUserInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryCreateNestedManyWithoutUserInput>;
  lead_intents?: InputMaybe<LeadIntentCreateNestedManyWithoutUserInput>;
  lead_source_filter?: InputMaybe<Scalars['Boolean']['input']>;
  lead_source_white_list_array?: InputMaybe<UserCreatelead_Source_White_List_ArrayInput>;
  lead_source_white_list_assign_type?: InputMaybe<AssignType>;
  leads_last_called?: InputMaybe<LeadCreateNestedManyWithoutLast_Call_Result_RepInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusCreateNestedManyWithoutUserInput>;
  manager_id?: InputMaybe<Scalars['String']['input']>;
  note_item?: InputMaybe<NoteItemCreateNestedManyWithoutUserInput>;
  notifications?: InputMaybe<NotificationCreateNestedManyWithoutUserInput>;
  organization: OrganizationCreateNestedOneWithoutUsersInput;
  owned_leads?: InputMaybe<LeadCreateNestedManyWithoutRepInput>;
  panda_doc_access?: InputMaybe<Scalars['Boolean']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  payment_settings?: InputMaybe<OrganizationPaymentSettingsCreateNestedManyWithoutCreated_ByInput>;
  payment_settings_updated?: InputMaybe<OrganizationPaymentSettingsCreateNestedManyWithoutLast_Updated_ByInput>;
  phone_number?: InputMaybe<Scalars['String']['input']>;
  post?: InputMaybe<PostCreateNestedManyWithoutAuthorInput>;
  ProductsCreated?: InputMaybe<ProductChangeLogCreateNestedManyWithoutUpdated_ByInput>;
  profile_image?: InputMaybe<Scalars['String']['input']>;
  receive_transfer_attempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutTo_UserInput>;
  receiver_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutReceiver_UserInput>;
  regions_white_list_array?: InputMaybe<UserCreateregions_White_List_ArrayInput>;
  regions_white_list_assign_type?: InputMaybe<AssignType>;
  rep_scores?: InputMaybe<Scalars['Float']['input']>;
  role?: InputMaybe<Role>;
  routings?: InputMaybe<RoutingCreateNestedManyWithoutRepInput>;
  sale_config?: InputMaybe<SaleConfigCreateNestedManyWithoutCreated_ByInput>;
  sale_config_updated?: InputMaybe<SaleConfigCreateNestedManyWithoutLast_Updated_ByInput>;
  sales_team_column_options?: InputMaybe<UserCreatesales_Team_Column_OptionsInput>;
  schedule_items?: InputMaybe<ScheduleItemCreateNestedManyWithoutUserInput>;
  send_transfer_attempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutFrom_UserInput>;
  sender_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutSender_UserInput>;
  sequence_entry_criteria_id?: InputMaybe<Scalars['String']['input']>;
  SequenceActionLog?: InputMaybe<StepActionLogCreateNestedManyWithoutRepInput>;
  sequenceDashboardView?: InputMaybe<SequenceDashboardViewCreateNestedManyWithoutOwned_ByInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutUserInput>;
  Sequences_created?: InputMaybe<SequenceCreateNestedManyWithoutCreated_ByInput>;
  Sequences_modified_by_user?: InputMaybe<SequenceCreateNestedManyWithoutLast_Updated_ByInput>;
  session_token?: InputMaybe<Scalars['String']['input']>;
  set_user_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutSet_UserInput>;
  set_user_stat?: InputMaybe<StatLeadAggregationCreateNestedManyWithoutSet_UserInput>;
  sf_user_id?: InputMaybe<Scalars['String']['input']>;
  SmsItems?: InputMaybe<SmsItemCreateNestedManyWithoutRepInput>;
  SnoozeTracking?: InputMaybe<SnoozeTrackingCreateNestedManyWithoutRepInput>;
  status?: InputMaybe<Status>;
  statusHistory?: InputMaybe<UserStatusHistoryCreateNestedManyWithoutUserInput>;
  sub_industry_white_list_array?: InputMaybe<UserCreatesub_Industry_White_List_ArrayInput>;
  sub_industry_white_list_assign_type?: InputMaybe<AssignType>;
  support_rep?: InputMaybe<Scalars['Boolean']['input']>;
  taking_demos?: InputMaybe<Scalars['Boolean']['input']>;
  team?: InputMaybe<TeamCreateNestedOneWithoutMembersInput>;
  Template?: InputMaybe<TemplateCreateNestedManyWithoutLast_Modified_ByInput>;
  TemplateVersion?: InputMaybe<TemplateVersionCreateNestedManyWithoutCreated_ByInput>;
  timekit_id?: InputMaybe<Scalars['String']['input']>;
  timekit_type?: InputMaybe<Timekittype>;
  timeOffRequest?: InputMaybe<TimeOffRequestCreateNestedManyWithoutRequesting_UserInput>;
  timezone_raw?: InputMaybe<Scalars['String']['input']>;
  transfer_type?: InputMaybe<TransferType>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user_custom_availability?: InputMaybe<AvailabilityCreateNestedManyWithoutUserInput>;
  user_past_tokens?: InputMaybe<UserPastTokensCreateNestedManyWithoutUserInput>;
  visible_all_leads_sm?: InputMaybe<Scalars['Boolean']['input']>;
  visible_call_report_columns?: InputMaybe<UserCreatevisible_Call_Report_ColumnsInput>;
  visible_call_report_event_columns?: InputMaybe<UserCreatevisible_Call_Report_Event_ColumnsInput>;
  visible_call_report_pipeline_columns?: InputMaybe<UserCreatevisible_Call_Report_Pipeline_ColumnsInput>;
  visible_my_leads_columns?: InputMaybe<UserCreatevisible_My_Leads_ColumnsInput>;
  voicemail_url?: InputMaybe<Scalars['String']['input']>;
};

export type UserCreateWithoutUser_Custom_AvailabilityInput = {
  ai_user?: InputMaybe<Scalars['Boolean']['input']>;
  alternate_email?: InputMaybe<Scalars['String']['input']>;
  always_show_calendar_options?: InputMaybe<Scalars['Boolean']['input']>;
  associate_owner_rep_history?: InputMaybe<AssociationHistoryCreateNestedManyWithoutRepInput>;
  associate_user_history?: InputMaybe<AssociationHistoryCreateNestedManyWithoutUserInput>;
  auto_transfer_selection_receive_attempts?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutFrom_UserInput>;
  auto_transfer_selection_send_attempts?: InputMaybe<AutoTransferSelectionCreateNestedManyWithoutTo_UserInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptCreateNestedManyWithoutUserInput>;
  callSharingAsCoach?: InputMaybe<CallSharingCreateNestedManyWithoutCoachInput>;
  callSharingAsUser?: InputMaybe<CallSharingCreateNestedManyWithoutReceiver_UserInput>;
  channel?: InputMaybe<Channelfilter>;
  close_rate?: InputMaybe<Scalars['Float']['input']>;
  close_user_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutClose_UserInput>;
  close_user_stat?: InputMaybe<StatLeadAggregationCreateNestedManyWithoutClose_UserInput>;
  commissions?: InputMaybe<CommissionCreateNestedManyWithoutUserInput>;
  contact_requests?: InputMaybe<ContactRequestCreateNestedManyWithoutRequesting_UserInput>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  current_sequence_view?: InputMaybe<SequenceDashboardViewCreateNestedOneWithoutUserInput>;
  current_team_start_date?: InputMaybe<Scalars['DateTime']['input']>;
  custom_field_white_list_array?: InputMaybe<UserCustomFieldFilterCreateNestedManyWithoutUserInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemCreateNestedManyWithoutUserInput>;
  daily_lead_claim?: InputMaybe<Scalars['Int']['input']>;
  dashboard_2_access?: InputMaybe<Scalars['Boolean']['input']>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewCreateNestedManyWithoutUserInput>;
  default_audio_input?: InputMaybe<Scalars['String']['input']>;
  default_audio_output?: InputMaybe<Scalars['String']['input']>;
  default_availability?: InputMaybe<Scalars['Boolean']['input']>;
  deleted_at?: InputMaybe<Scalars['DateTime']['input']>;
  deprecate_old_session?: InputMaybe<Scalars['Boolean']['input']>;
  email: Scalars['String']['input'];
  EmailSyncRecord?: InputMaybe<EmailSyncRecordCreateNestedManyWithoutUserInput>;
  favorite_leads?: InputMaybe<LeadCreateNestedManyWithoutFavorited_By_UsersInput>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  folder_permissions?: InputMaybe<FolderPermissionCreateNestedManyWithoutUserInput>;
  folders?: InputMaybe<FolderCreateNestedManyWithoutOwnerInput>;
  from_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutOld_UserInput>;
  GlobalRouting?: InputMaybe<GlobalRepRoutingCreateNestedManyWithoutRepInput>;
  google_refresh_token?: InputMaybe<Scalars['String']['input']>;
  holds_per_prospect_vs_held_demo_per_dial?: InputMaybe<Scalars['Float']['input']>;
  hook_subscriptions?: InputMaybe<WebHookSubscriptionCreateNestedManyWithoutUserInput>;
  hubspot_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  idle_owned_leads?: InputMaybe<LeadCreateNestedManyWithoutSecondary_RepsInput>;
  industry_filter?: InputMaybe<Scalars['Boolean']['input']>;
  industry_white_list_array?: InputMaybe<UserCreateindustry_White_List_ArrayInput>;
  industry_white_list_assign_type?: InputMaybe<AssignType>;
  joinMe_access_token?: InputMaybe<Scalars['String']['input']>;
  joinMe_refresh_token?: InputMaybe<Scalars['String']['input']>;
  last_demo_transferred_at?: InputMaybe<Scalars['DateTime']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  lead_activities?: InputMaybe<LeadActivityCreateNestedManyWithoutUserInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryCreateNestedManyWithoutUserInput>;
  lead_intents?: InputMaybe<LeadIntentCreateNestedManyWithoutUserInput>;
  lead_source_filter?: InputMaybe<Scalars['Boolean']['input']>;
  lead_source_white_list_array?: InputMaybe<UserCreatelead_Source_White_List_ArrayInput>;
  lead_source_white_list_assign_type?: InputMaybe<AssignType>;
  leads_last_called?: InputMaybe<LeadCreateNestedManyWithoutLast_Call_Result_RepInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusCreateNestedManyWithoutUserInput>;
  manager_id?: InputMaybe<Scalars['String']['input']>;
  note_item?: InputMaybe<NoteItemCreateNestedManyWithoutUserInput>;
  notifications?: InputMaybe<NotificationCreateNestedManyWithoutUserInput>;
  organization: OrganizationCreateNestedOneWithoutUsersInput;
  owned_leads?: InputMaybe<LeadCreateNestedManyWithoutRepInput>;
  panda_doc_access?: InputMaybe<Scalars['Boolean']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  payment_settings?: InputMaybe<OrganizationPaymentSettingsCreateNestedManyWithoutCreated_ByInput>;
  payment_settings_updated?: InputMaybe<OrganizationPaymentSettingsCreateNestedManyWithoutLast_Updated_ByInput>;
  phone_number?: InputMaybe<Scalars['String']['input']>;
  post?: InputMaybe<PostCreateNestedManyWithoutAuthorInput>;
  ProductsCreated?: InputMaybe<ProductChangeLogCreateNestedManyWithoutUpdated_ByInput>;
  profile_image?: InputMaybe<Scalars['String']['input']>;
  receive_transfer_attempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutTo_UserInput>;
  receiver_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutReceiver_UserInput>;
  regions_white_list_array?: InputMaybe<UserCreateregions_White_List_ArrayInput>;
  regions_white_list_assign_type?: InputMaybe<AssignType>;
  rep_scores?: InputMaybe<Scalars['Float']['input']>;
  role?: InputMaybe<Role>;
  routings?: InputMaybe<RoutingCreateNestedManyWithoutRepInput>;
  sale_config?: InputMaybe<SaleConfigCreateNestedManyWithoutCreated_ByInput>;
  sale_config_updated?: InputMaybe<SaleConfigCreateNestedManyWithoutLast_Updated_ByInput>;
  sales_team_column_options?: InputMaybe<UserCreatesales_Team_Column_OptionsInput>;
  schedule_items?: InputMaybe<ScheduleItemCreateNestedManyWithoutUserInput>;
  send_transfer_attempts?: InputMaybe<TransferAttemptCreateNestedManyWithoutFrom_UserInput>;
  sender_assignment_history?: InputMaybe<LeadAssignmentHistoryCreateNestedManyWithoutSender_UserInput>;
  sequence_entry_criteria_id?: InputMaybe<Scalars['String']['input']>;
  SequenceActionLog?: InputMaybe<StepActionLogCreateNestedManyWithoutRepInput>;
  sequenceDashboardView?: InputMaybe<SequenceDashboardViewCreateNestedManyWithoutOwned_ByInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadCreateNestedManyWithoutUserInput>;
  Sequences_created?: InputMaybe<SequenceCreateNestedManyWithoutCreated_ByInput>;
  Sequences_modified_by_user?: InputMaybe<SequenceCreateNestedManyWithoutLast_Updated_ByInput>;
  session_token?: InputMaybe<Scalars['String']['input']>;
  set_user_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutSet_UserInput>;
  set_user_stat?: InputMaybe<StatLeadAggregationCreateNestedManyWithoutSet_UserInput>;
  sf_user_id?: InputMaybe<Scalars['String']['input']>;
  SmsItems?: InputMaybe<SmsItemCreateNestedManyWithoutRepInput>;
  SnoozeTracking?: InputMaybe<SnoozeTrackingCreateNestedManyWithoutRepInput>;
  status?: InputMaybe<Status>;
  statusHistory?: InputMaybe<UserStatusHistoryCreateNestedManyWithoutUserInput>;
  sub_industry_white_list_array?: InputMaybe<UserCreatesub_Industry_White_List_ArrayInput>;
  sub_industry_white_list_assign_type?: InputMaybe<AssignType>;
  support_rep?: InputMaybe<Scalars['Boolean']['input']>;
  taking_demos?: InputMaybe<Scalars['Boolean']['input']>;
  team?: InputMaybe<TeamCreateNestedOneWithoutMembersInput>;
  Template?: InputMaybe<TemplateCreateNestedManyWithoutLast_Modified_ByInput>;
  TemplateVersion?: InputMaybe<TemplateVersionCreateNestedManyWithoutCreated_ByInput>;
  timekit_id?: InputMaybe<Scalars['String']['input']>;
  timekit_type?: InputMaybe<Timekittype>;
  timeOffRequest?: InputMaybe<TimeOffRequestCreateNestedManyWithoutRequesting_UserInput>;
  timezone_raw?: InputMaybe<Scalars['String']['input']>;
  transfer_type?: InputMaybe<TransferType>;
  transfer_user_cycle?: InputMaybe<SaleCycleCreateNestedManyWithoutTransfer_UserInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user_past_tokens?: InputMaybe<UserPastTokensCreateNestedManyWithoutUserInput>;
  visible_all_leads_sm?: InputMaybe<Scalars['Boolean']['input']>;
  visible_call_report_columns?: InputMaybe<UserCreatevisible_Call_Report_ColumnsInput>;
  visible_call_report_event_columns?: InputMaybe<UserCreatevisible_Call_Report_Event_ColumnsInput>;
  visible_call_report_pipeline_columns?: InputMaybe<UserCreatevisible_Call_Report_Pipeline_ColumnsInput>;
  visible_my_leads_columns?: InputMaybe<UserCreatevisible_My_Leads_ColumnsInput>;
  voicemail_url?: InputMaybe<Scalars['String']['input']>;
};

export type UserCustomFieldFilter = {
  __typename?: 'UserCustomFieldFilter';
  assign: AssignType;
  created_at: Scalars['DateTime']['output'];
  custom_field: CustomField;
  customFieldId: Scalars['String']['output'];
  selected: Array<Scalars['String']['output']>;
  updated_at: Scalars['DateTime']['output'];
  user: User;
  userId: Scalars['String']['output'];
};

export type UserCustomFieldFilterCreateManyCustom_FieldInput = {
  assign: AssignType;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  organization_id?: InputMaybe<Scalars['String']['input']>;
  selected?: InputMaybe<UserCustomFieldFilterCreateManyselectedInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  userId: Scalars['String']['input'];
};

export type UserCustomFieldFilterCreateManyCustom_FieldInputEnvelope = {
  data?: InputMaybe<Array<UserCustomFieldFilterCreateManyCustom_FieldInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UserCustomFieldFilterCreateManyOrganizationInput = {
  assign: AssignType;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  customFieldId: Scalars['String']['input'];
  selected?: InputMaybe<UserCustomFieldFilterCreateManyselectedInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  userId: Scalars['String']['input'];
};

export type UserCustomFieldFilterCreateManyOrganizationInputEnvelope = {
  data?: InputMaybe<Array<UserCustomFieldFilterCreateManyOrganizationInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UserCustomFieldFilterCreateManyselectedInput = {
  set?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type UserCustomFieldFilterCreateManyUserInput = {
  assign: AssignType;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  customFieldId: Scalars['String']['input'];
  organization_id?: InputMaybe<Scalars['String']['input']>;
  selected?: InputMaybe<UserCustomFieldFilterCreateManyselectedInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type UserCustomFieldFilterCreateManyUserInputEnvelope = {
  data?: InputMaybe<Array<UserCustomFieldFilterCreateManyUserInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UserCustomFieldFilterCreateNestedManyWithoutCustom_FieldInput = {
  connect?: InputMaybe<Array<UserCustomFieldFilterWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserCustomFieldFilterCreateOrConnectWithoutCustom_FieldInput>>;
  create?: InputMaybe<Array<UserCustomFieldFilterCreateWithoutCustom_FieldInput>>;
  createMany?: InputMaybe<UserCustomFieldFilterCreateManyCustom_FieldInputEnvelope>;
};

export type UserCustomFieldFilterCreateNestedManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<UserCustomFieldFilterWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserCustomFieldFilterCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<UserCustomFieldFilterCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<UserCustomFieldFilterCreateManyOrganizationInputEnvelope>;
};

export type UserCustomFieldFilterCreateNestedManyWithoutUserInput = {
  connect?: InputMaybe<Array<UserCustomFieldFilterWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserCustomFieldFilterCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<UserCustomFieldFilterCreateWithoutUserInput>>;
  createMany?: InputMaybe<UserCustomFieldFilterCreateManyUserInputEnvelope>;
};

export type UserCustomFieldFilterCreateOrConnectWithoutCustom_FieldInput = {
  create: UserCustomFieldFilterCreateWithoutCustom_FieldInput;
  where: UserCustomFieldFilterWhereUniqueInput;
};

export type UserCustomFieldFilterCreateOrConnectWithoutOrganizationInput = {
  create: UserCustomFieldFilterCreateWithoutOrganizationInput;
  where: UserCustomFieldFilterWhereUniqueInput;
};

export type UserCustomFieldFilterCreateOrConnectWithoutUserInput = {
  create: UserCustomFieldFilterCreateWithoutUserInput;
  where: UserCustomFieldFilterWhereUniqueInput;
};

export type UserCustomFieldFilterCreateselectedInput = {
  set?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type UserCustomFieldFilterCreateWithoutCustom_FieldInput = {
  assign: AssignType;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  Organization?: InputMaybe<OrganizationCreateNestedOneWithoutUserCustomFieldFiltersInput>;
  selected?: InputMaybe<UserCustomFieldFilterCreateselectedInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user: UserCreateNestedOneWithoutCustom_Field_White_List_ArrayInput;
};

export type UserCustomFieldFilterCreateWithoutOrganizationInput = {
  assign: AssignType;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  custom_field: CustomFieldCreateNestedOneWithoutUserCustomFieldFilterInput;
  selected?: InputMaybe<UserCustomFieldFilterCreateselectedInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  user: UserCreateNestedOneWithoutCustom_Field_White_List_ArrayInput;
};

export type UserCustomFieldFilterCreateWithoutUserInput = {
  assign: AssignType;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  custom_field: CustomFieldCreateNestedOneWithoutUserCustomFieldFilterInput;
  Organization?: InputMaybe<OrganizationCreateNestedOneWithoutUserCustomFieldFiltersInput>;
  selected?: InputMaybe<UserCustomFieldFilterCreateselectedInput>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type UserCustomFieldFilterInput = {
  assign: AssignType;
  /** customFieldId */
  customFieldId: Scalars['String']['input'];
  selected: Array<Scalars['String']['input']>;
  /** userId for whom this is effecting. if not provided will use context to try to parse or use user who submitted */
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type UserCustomFieldFilterListRelationFilter = {
  every?: InputMaybe<UserCustomFieldFilterWhereInput>;
  none?: InputMaybe<UserCustomFieldFilterWhereInput>;
  some?: InputMaybe<UserCustomFieldFilterWhereInput>;
};

export type UserCustomFieldFilterOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
  count?: InputMaybe<SortOrder>;
};

export type UserCustomFieldFilterScalarWhereInput = {
  AND?: InputMaybe<Array<UserCustomFieldFilterScalarWhereInput>>;
  assign?: InputMaybe<EnumAssignTypeFilter>;
  created_at?: InputMaybe<DateTimeFilter>;
  customFieldId?: InputMaybe<StringFilter>;
  NOT?: InputMaybe<Array<UserCustomFieldFilterScalarWhereInput>>;
  OR?: InputMaybe<Array<UserCustomFieldFilterScalarWhereInput>>;
  organization_id?: InputMaybe<StringNullableFilter>;
  selected?: InputMaybe<StringNullableListFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type UserCustomFieldFilterUpdateManyMutationInput = {
  assign?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  selected?: InputMaybe<UserCustomFieldFilterUpdateselectedInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type UserCustomFieldFilterUpdateManyWithoutCustom_FieldInput = {
  connect?: InputMaybe<Array<UserCustomFieldFilterWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserCustomFieldFilterCreateOrConnectWithoutCustom_FieldInput>>;
  create?: InputMaybe<Array<UserCustomFieldFilterCreateWithoutCustom_FieldInput>>;
  createMany?: InputMaybe<UserCustomFieldFilterCreateManyCustom_FieldInputEnvelope>;
  delete?: InputMaybe<Array<UserCustomFieldFilterWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<UserCustomFieldFilterScalarWhereInput>>;
  disconnect?: InputMaybe<Array<UserCustomFieldFilterWhereUniqueInput>>;
  set?: InputMaybe<Array<UserCustomFieldFilterWhereUniqueInput>>;
  update?: InputMaybe<Array<UserCustomFieldFilterUpdateWithWhereUniqueWithoutCustom_FieldInput>>;
  updateMany?: InputMaybe<Array<UserCustomFieldFilterUpdateManyWithWhereWithoutCustom_FieldInput>>;
  upsert?: InputMaybe<Array<UserCustomFieldFilterUpsertWithWhereUniqueWithoutCustom_FieldInput>>;
};

export type UserCustomFieldFilterUpdateManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<UserCustomFieldFilterWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserCustomFieldFilterCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<UserCustomFieldFilterCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<UserCustomFieldFilterCreateManyOrganizationInputEnvelope>;
  delete?: InputMaybe<Array<UserCustomFieldFilterWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<UserCustomFieldFilterScalarWhereInput>>;
  disconnect?: InputMaybe<Array<UserCustomFieldFilterWhereUniqueInput>>;
  set?: InputMaybe<Array<UserCustomFieldFilterWhereUniqueInput>>;
  update?: InputMaybe<Array<UserCustomFieldFilterUpdateWithWhereUniqueWithoutOrganizationInput>>;
  updateMany?: InputMaybe<Array<UserCustomFieldFilterUpdateManyWithWhereWithoutOrganizationInput>>;
  upsert?: InputMaybe<Array<UserCustomFieldFilterUpsertWithWhereUniqueWithoutOrganizationInput>>;
};

export type UserCustomFieldFilterUpdateManyWithoutUserInput = {
  connect?: InputMaybe<Array<UserCustomFieldFilterWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserCustomFieldFilterCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<UserCustomFieldFilterCreateWithoutUserInput>>;
  createMany?: InputMaybe<UserCustomFieldFilterCreateManyUserInputEnvelope>;
  delete?: InputMaybe<Array<UserCustomFieldFilterWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<UserCustomFieldFilterScalarWhereInput>>;
  disconnect?: InputMaybe<Array<UserCustomFieldFilterWhereUniqueInput>>;
  set?: InputMaybe<Array<UserCustomFieldFilterWhereUniqueInput>>;
  update?: InputMaybe<Array<UserCustomFieldFilterUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: InputMaybe<Array<UserCustomFieldFilterUpdateManyWithWhereWithoutUserInput>>;
  upsert?: InputMaybe<Array<UserCustomFieldFilterUpsertWithWhereUniqueWithoutUserInput>>;
};

export type UserCustomFieldFilterUpdateManyWithWhereWithoutCustom_FieldInput = {
  data: UserCustomFieldFilterUpdateManyMutationInput;
  where: UserCustomFieldFilterScalarWhereInput;
};

export type UserCustomFieldFilterUpdateManyWithWhereWithoutOrganizationInput = {
  data: UserCustomFieldFilterUpdateManyMutationInput;
  where: UserCustomFieldFilterScalarWhereInput;
};

export type UserCustomFieldFilterUpdateManyWithWhereWithoutUserInput = {
  data: UserCustomFieldFilterUpdateManyMutationInput;
  where: UserCustomFieldFilterScalarWhereInput;
};

export type UserCustomFieldFilterUpdateselectedInput = {
  push?: InputMaybe<Scalars['String']['input']>;
  set?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type UserCustomFieldFilterUpdateWithoutCustom_FieldInput = {
  assign?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  Organization?: InputMaybe<OrganizationUpdateOneWithoutUserCustomFieldFiltersInput>;
  selected?: InputMaybe<UserCustomFieldFilterUpdateselectedInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutCustom_Field_White_List_ArrayInput>;
};

export type UserCustomFieldFilterUpdateWithoutOrganizationInput = {
  assign?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  custom_field?: InputMaybe<CustomFieldUpdateOneRequiredWithoutUserCustomFieldFilterInput>;
  selected?: InputMaybe<UserCustomFieldFilterUpdateselectedInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutCustom_Field_White_List_ArrayInput>;
};

export type UserCustomFieldFilterUpdateWithoutUserInput = {
  assign?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  custom_field?: InputMaybe<CustomFieldUpdateOneRequiredWithoutUserCustomFieldFilterInput>;
  Organization?: InputMaybe<OrganizationUpdateOneWithoutUserCustomFieldFiltersInput>;
  selected?: InputMaybe<UserCustomFieldFilterUpdateselectedInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type UserCustomFieldFilterUpdateWithWhereUniqueWithoutCustom_FieldInput = {
  data: UserCustomFieldFilterUpdateWithoutCustom_FieldInput;
  where: UserCustomFieldFilterWhereUniqueInput;
};

export type UserCustomFieldFilterUpdateWithWhereUniqueWithoutOrganizationInput = {
  data: UserCustomFieldFilterUpdateWithoutOrganizationInput;
  where: UserCustomFieldFilterWhereUniqueInput;
};

export type UserCustomFieldFilterUpdateWithWhereUniqueWithoutUserInput = {
  data: UserCustomFieldFilterUpdateWithoutUserInput;
  where: UserCustomFieldFilterWhereUniqueInput;
};

export type UserCustomFieldFilterUpsertWithWhereUniqueWithoutCustom_FieldInput = {
  create: UserCustomFieldFilterCreateWithoutCustom_FieldInput;
  update: UserCustomFieldFilterUpdateWithoutCustom_FieldInput;
  where: UserCustomFieldFilterWhereUniqueInput;
};

export type UserCustomFieldFilterUpsertWithWhereUniqueWithoutOrganizationInput = {
  create: UserCustomFieldFilterCreateWithoutOrganizationInput;
  update: UserCustomFieldFilterUpdateWithoutOrganizationInput;
  where: UserCustomFieldFilterWhereUniqueInput;
};

export type UserCustomFieldFilterUpsertWithWhereUniqueWithoutUserInput = {
  create: UserCustomFieldFilterCreateWithoutUserInput;
  update: UserCustomFieldFilterUpdateWithoutUserInput;
  where: UserCustomFieldFilterWhereUniqueInput;
};

export type UserCustomFieldFilterUserIdCustomFieldIdCompoundUniqueInput = {
  customFieldId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};

export type UserCustomFieldFilterWhereInput = {
  AND?: InputMaybe<Array<UserCustomFieldFilterWhereInput>>;
  assign?: InputMaybe<EnumAssignTypeFilter>;
  created_at?: InputMaybe<DateTimeFilter>;
  custom_field?: InputMaybe<CustomFieldWhereInput>;
  customFieldId?: InputMaybe<StringFilter>;
  NOT?: InputMaybe<Array<UserCustomFieldFilterWhereInput>>;
  OR?: InputMaybe<Array<UserCustomFieldFilterWhereInput>>;
  Organization?: InputMaybe<OrganizationWhereInput>;
  organization_id?: InputMaybe<StringNullableFilter>;
  selected?: InputMaybe<StringNullableListFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserWhereInput>;
  userId?: InputMaybe<StringFilter>;
};

export type UserCustomFieldFilterWhereUniqueInput = {
  userId_customFieldId?: InputMaybe<UserCustomFieldFilterUserIdCustomFieldIdCompoundUniqueInput>;
};

export type UserListRelationFilter = {
  every?: InputMaybe<UserWhereInput>;
  none?: InputMaybe<UserWhereInput>;
  some?: InputMaybe<UserWhereInput>;
};

export type UserLog = {
  __typename?: 'UserLog';
  dml_created_by?: Maybe<Scalars['String']['output']>;
  dml_timestamp: Scalars['DateTime']['output'];
  dml_type: Dml_Type;
  new_row_data?: Maybe<Scalars['Json']['output']>;
  old_row_data?: Maybe<Scalars['Json']['output']>;
  user_id: Scalars['String']['output'];
};

export type UserOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
  count?: InputMaybe<SortOrder>;
};

export type UserOrderByWithRelationInput = {
  ai_user?: InputMaybe<SortOrder>;
  alternate_email?: InputMaybe<SortOrder>;
  always_show_calendar_options?: InputMaybe<SortOrder>;
  associate_owner_rep_history?: InputMaybe<AssociationHistoryOrderByRelationAggregateInput>;
  associate_user_history?: InputMaybe<AssociationHistoryOrderByRelationAggregateInput>;
  auto_transfer_selection_receive_attempts?: InputMaybe<AutoTransferSelectionOrderByRelationAggregateInput>;
  auto_transfer_selection_send_attempts?: InputMaybe<AutoTransferSelectionOrderByRelationAggregateInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptOrderByRelationAggregateInput>;
  callSharingAsCoach?: InputMaybe<CallSharingOrderByRelationAggregateInput>;
  callSharingAsUser?: InputMaybe<CallSharingOrderByRelationAggregateInput>;
  channel?: InputMaybe<SortOrder>;
  close_rate?: InputMaybe<SortOrder>;
  close_user_cycle?: InputMaybe<SaleCycleOrderByRelationAggregateInput>;
  close_user_stat?: InputMaybe<StatLeadAggregationOrderByRelationAggregateInput>;
  commissions?: InputMaybe<CommissionOrderByRelationAggregateInput>;
  contact_requests?: InputMaybe<ContactRequestOrderByRelationAggregateInput>;
  created_at?: InputMaybe<SortOrder>;
  current_sequence_view?: InputMaybe<SequenceDashboardViewOrderByWithRelationInput>;
  current_sequence_view_id?: InputMaybe<SortOrder>;
  current_team_start_date?: InputMaybe<SortOrder>;
  custom_field_white_list_array?: InputMaybe<UserCustomFieldFilterOrderByRelationAggregateInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemOrderByRelationAggregateInput>;
  daily_lead_claim?: InputMaybe<SortOrder>;
  dashboard_2_access?: InputMaybe<SortOrder>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewOrderByRelationAggregateInput>;
  default_audio_input?: InputMaybe<SortOrder>;
  default_audio_output?: InputMaybe<SortOrder>;
  default_availability?: InputMaybe<SortOrder>;
  deleted_at?: InputMaybe<SortOrder>;
  deprecate_old_session?: InputMaybe<SortOrder>;
  email?: InputMaybe<SortOrder>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordOrderByRelationAggregateInput>;
  favorite_leads?: InputMaybe<LeadOrderByRelationAggregateInput>;
  first_name?: InputMaybe<SortOrder>;
  folder_permissions?: InputMaybe<FolderPermissionOrderByRelationAggregateInput>;
  folders?: InputMaybe<FolderOrderByRelationAggregateInput>;
  from_assignment_history?: InputMaybe<LeadAssignmentHistoryOrderByRelationAggregateInput>;
  GlobalRouting?: InputMaybe<GlobalRepRoutingOrderByRelationAggregateInput>;
  google_refresh_token?: InputMaybe<SortOrder>;
  holds_per_prospect_vs_held_demo_per_dial?: InputMaybe<SortOrder>;
  hook_subscriptions?: InputMaybe<WebHookSubscriptionOrderByRelationAggregateInput>;
  hubspot_id?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  idle_owned_leads?: InputMaybe<LeadOrderByRelationAggregateInput>;
  industry_filter?: InputMaybe<SortOrder>;
  industry_white_list_array?: InputMaybe<SortOrder>;
  industry_white_list_assign_type?: InputMaybe<SortOrder>;
  joinMe_access_token?: InputMaybe<SortOrder>;
  joinMe_refresh_token?: InputMaybe<SortOrder>;
  last_demo_transferred_at?: InputMaybe<SortOrder>;
  last_name?: InputMaybe<SortOrder>;
  lead_activities?: InputMaybe<LeadActivityOrderByRelationAggregateInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryOrderByRelationAggregateInput>;
  lead_intents?: InputMaybe<LeadIntentOrderByRelationAggregateInput>;
  lead_source_filter?: InputMaybe<SortOrder>;
  lead_source_white_list_array?: InputMaybe<SortOrder>;
  lead_source_white_list_assign_type?: InputMaybe<SortOrder>;
  leads_last_called?: InputMaybe<LeadOrderByRelationAggregateInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusOrderByRelationAggregateInput>;
  manager_id?: InputMaybe<SortOrder>;
  note_item?: InputMaybe<NoteItemOrderByRelationAggregateInput>;
  notifications?: InputMaybe<NotificationOrderByRelationAggregateInput>;
  organization?: InputMaybe<OrganizationOrderByWithRelationInput>;
  organization_id?: InputMaybe<SortOrder>;
  owned_leads?: InputMaybe<LeadOrderByRelationAggregateInput>;
  panda_doc_access?: InputMaybe<SortOrder>;
  password?: InputMaybe<SortOrder>;
  payment_settings?: InputMaybe<OrganizationPaymentSettingsOrderByRelationAggregateInput>;
  payment_settings_updated?: InputMaybe<OrganizationPaymentSettingsOrderByRelationAggregateInput>;
  phone_number?: InputMaybe<SortOrder>;
  post?: InputMaybe<PostOrderByRelationAggregateInput>;
  ProductsCreated?: InputMaybe<ProductChangeLogOrderByRelationAggregateInput>;
  profile_image?: InputMaybe<SortOrder>;
  receive_transfer_attempts?: InputMaybe<TransferAttemptOrderByRelationAggregateInput>;
  receiver_assignment_history?: InputMaybe<LeadAssignmentHistoryOrderByRelationAggregateInput>;
  regions_white_list_array?: InputMaybe<SortOrder>;
  regions_white_list_assign_type?: InputMaybe<SortOrder>;
  rep_scores?: InputMaybe<SortOrder>;
  role?: InputMaybe<SortOrder>;
  routings?: InputMaybe<RoutingOrderByRelationAggregateInput>;
  sale_config?: InputMaybe<SaleConfigOrderByRelationAggregateInput>;
  sale_config_updated?: InputMaybe<SaleConfigOrderByRelationAggregateInput>;
  sales_team_column_options?: InputMaybe<SortOrder>;
  schedule_items?: InputMaybe<ScheduleItemOrderByRelationAggregateInput>;
  send_transfer_attempts?: InputMaybe<TransferAttemptOrderByRelationAggregateInput>;
  sender_assignment_history?: InputMaybe<LeadAssignmentHistoryOrderByRelationAggregateInput>;
  sequence_entry_criteria_id?: InputMaybe<SortOrder>;
  SequenceActionLog?: InputMaybe<StepActionLogOrderByRelationAggregateInput>;
  sequenceDashboardView?: InputMaybe<SequenceDashboardViewOrderByRelationAggregateInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadOrderByRelationAggregateInput>;
  Sequences_created?: InputMaybe<SequenceOrderByRelationAggregateInput>;
  Sequences_modified_by_user?: InputMaybe<SequenceOrderByRelationAggregateInput>;
  session_token?: InputMaybe<SortOrder>;
  set_user_cycle?: InputMaybe<SaleCycleOrderByRelationAggregateInput>;
  set_user_stat?: InputMaybe<StatLeadAggregationOrderByRelationAggregateInput>;
  sf_user_id?: InputMaybe<SortOrder>;
  SmsItems?: InputMaybe<SmsItemOrderByRelationAggregateInput>;
  SnoozeTracking?: InputMaybe<SnoozeTrackingOrderByRelationAggregateInput>;
  status?: InputMaybe<SortOrder>;
  statusHistory?: InputMaybe<UserStatusHistoryOrderByRelationAggregateInput>;
  sub_industry_white_list_array?: InputMaybe<SortOrder>;
  sub_industry_white_list_assign_type?: InputMaybe<SortOrder>;
  support_rep?: InputMaybe<SortOrder>;
  taking_demos?: InputMaybe<SortOrder>;
  team?: InputMaybe<TeamOrderByWithRelationInput>;
  team_id?: InputMaybe<SortOrder>;
  Template?: InputMaybe<TemplateOrderByRelationAggregateInput>;
  TemplateVersion?: InputMaybe<TemplateVersionOrderByRelationAggregateInput>;
  timekit_id?: InputMaybe<SortOrder>;
  timekit_type?: InputMaybe<SortOrder>;
  timeOffRequest?: InputMaybe<TimeOffRequestOrderByRelationAggregateInput>;
  timezone_raw?: InputMaybe<SortOrder>;
  transfer_type?: InputMaybe<SortOrder>;
  transfer_user_cycle?: InputMaybe<SaleCycleOrderByRelationAggregateInput>;
  updated_at?: InputMaybe<SortOrder>;
  user_custom_availability?: InputMaybe<AvailabilityOrderByRelationAggregateInput>;
  user_past_tokens?: InputMaybe<UserPastTokensOrderByRelationAggregateInput>;
  visible_all_leads_sm?: InputMaybe<SortOrder>;
  visible_call_report_columns?: InputMaybe<SortOrder>;
  visible_call_report_event_columns?: InputMaybe<SortOrder>;
  visible_call_report_pipeline_columns?: InputMaybe<SortOrder>;
  visible_my_leads_columns?: InputMaybe<SortOrder>;
  voicemail_url?: InputMaybe<SortOrder>;
};

export type UserPastTokensCreateManyUserInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  session_token: Scalars['String']['input'];
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type UserPastTokensCreateManyUserInputEnvelope = {
  data?: InputMaybe<Array<UserPastTokensCreateManyUserInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UserPastTokensCreateNestedManyWithoutUserInput = {
  connect?: InputMaybe<Array<UserPastTokensWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserPastTokensCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<UserPastTokensCreateWithoutUserInput>>;
  createMany?: InputMaybe<UserPastTokensCreateManyUserInputEnvelope>;
};

export type UserPastTokensCreateOrConnectWithoutUserInput = {
  create: UserPastTokensCreateWithoutUserInput;
  where: UserPastTokensWhereUniqueInput;
};

export type UserPastTokensCreateWithoutUserInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  session_token: Scalars['String']['input'];
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type UserPastTokensListRelationFilter = {
  every?: InputMaybe<UserPastTokensWhereInput>;
  none?: InputMaybe<UserPastTokensWhereInput>;
  some?: InputMaybe<UserPastTokensWhereInput>;
};

export type UserPastTokensOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
  count?: InputMaybe<SortOrder>;
};

export type UserPastTokensScalarWhereInput = {
  AND?: InputMaybe<Array<UserPastTokensScalarWhereInput>>;
  created_at?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  NOT?: InputMaybe<Array<UserPastTokensScalarWhereInput>>;
  OR?: InputMaybe<Array<UserPastTokensScalarWhereInput>>;
  session_token?: InputMaybe<StringFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
  user_id?: InputMaybe<StringFilter>;
};

export type UserPastTokensUpdateManyMutationInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  session_token?: InputMaybe<StringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type UserPastTokensUpdateManyWithoutUserInput = {
  connect?: InputMaybe<Array<UserPastTokensWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserPastTokensCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<UserPastTokensCreateWithoutUserInput>>;
  createMany?: InputMaybe<UserPastTokensCreateManyUserInputEnvelope>;
  delete?: InputMaybe<Array<UserPastTokensWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<UserPastTokensScalarWhereInput>>;
  disconnect?: InputMaybe<Array<UserPastTokensWhereUniqueInput>>;
  set?: InputMaybe<Array<UserPastTokensWhereUniqueInput>>;
  update?: InputMaybe<Array<UserPastTokensUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: InputMaybe<Array<UserPastTokensUpdateManyWithWhereWithoutUserInput>>;
  upsert?: InputMaybe<Array<UserPastTokensUpsertWithWhereUniqueWithoutUserInput>>;
};

export type UserPastTokensUpdateManyWithWhereWithoutUserInput = {
  data: UserPastTokensUpdateManyMutationInput;
  where: UserPastTokensScalarWhereInput;
};

export type UserPastTokensUpdateWithoutUserInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  session_token?: InputMaybe<StringFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type UserPastTokensUpdateWithWhereUniqueWithoutUserInput = {
  data: UserPastTokensUpdateWithoutUserInput;
  where: UserPastTokensWhereUniqueInput;
};

export type UserPastTokensUpsertWithWhereUniqueWithoutUserInput = {
  create: UserPastTokensCreateWithoutUserInput;
  update: UserPastTokensUpdateWithoutUserInput;
  where: UserPastTokensWhereUniqueInput;
};

export type UserPastTokensWhereInput = {
  AND?: InputMaybe<Array<UserPastTokensWhereInput>>;
  created_at?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  NOT?: InputMaybe<Array<UserPastTokensWhereInput>>;
  OR?: InputMaybe<Array<UserPastTokensWhereInput>>;
  session_token?: InputMaybe<StringFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserWhereInput>;
  user_id?: InputMaybe<StringFilter>;
};

export type UserPastTokensWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  session_token?: InputMaybe<Scalars['String']['input']>;
};

export type UserPreview = {
  __typename?: 'UserPreview';
  AllScheduledEvents?: Maybe<Scalars['Int']['output']>;
  AssignedLead?: Maybe<Scalars['Int']['output']>;
  CCDials?: Maybe<Scalars['Int']['output']>;
  DecisionCalls?: Maybe<Scalars['Int']['output']>;
  Demos?: Maybe<Scalars['Int']['output']>;
  FollowUpDials?: Maybe<Scalars['Int']['output']>;
  GeneralCallBackDials?: Maybe<Scalars['Int']['output']>;
  OwnedLead?: Maybe<Scalars['Int']['output']>;
  ScheduledCallBacks?: Maybe<Scalars['Int']['output']>;
  TotalLead?: Maybe<Scalars['Int']['output']>;
  UpcomingDials?: Maybe<Scalars['Int']['output']>;
};

export type UserScalarWhereInput = {
  ai_user?: InputMaybe<BoolFilter>;
  alternate_email?: InputMaybe<StringNullableFilter>;
  always_show_calendar_options?: InputMaybe<BoolFilter>;
  AND?: InputMaybe<Array<UserScalarWhereInput>>;
  channel?: InputMaybe<EnumChannelfilterNullableFilter>;
  close_rate?: InputMaybe<FloatNullableFilter>;
  created_at?: InputMaybe<DateTimeFilter>;
  current_sequence_view_id?: InputMaybe<StringNullableFilter>;
  current_team_start_date?: InputMaybe<DateTimeNullableFilter>;
  daily_lead_claim?: InputMaybe<IntFilter>;
  dashboard_2_access?: InputMaybe<BoolFilter>;
  default_audio_input?: InputMaybe<StringNullableFilter>;
  default_audio_output?: InputMaybe<StringNullableFilter>;
  default_availability?: InputMaybe<BoolFilter>;
  deleted_at?: InputMaybe<DateTimeNullableFilter>;
  deprecate_old_session?: InputMaybe<BoolFilter>;
  email?: InputMaybe<StringFilter>;
  first_name?: InputMaybe<StringNullableFilter>;
  google_refresh_token?: InputMaybe<StringNullableFilter>;
  holds_per_prospect_vs_held_demo_per_dial?: InputMaybe<FloatNullableFilter>;
  hubspot_id?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  industry_filter?: InputMaybe<BoolFilter>;
  industry_white_list_array?: InputMaybe<StringNullableListFilter>;
  industry_white_list_assign_type?: InputMaybe<EnumAssignTypeFilter>;
  joinMe_access_token?: InputMaybe<StringNullableFilter>;
  joinMe_refresh_token?: InputMaybe<StringNullableFilter>;
  last_demo_transferred_at?: InputMaybe<DateTimeNullableFilter>;
  last_name?: InputMaybe<StringNullableFilter>;
  lead_source_filter?: InputMaybe<BoolFilter>;
  lead_source_white_list_array?: InputMaybe<StringNullableListFilter>;
  lead_source_white_list_assign_type?: InputMaybe<EnumAssignTypeFilter>;
  manager_id?: InputMaybe<StringNullableFilter>;
  NOT?: InputMaybe<Array<UserScalarWhereInput>>;
  OR?: InputMaybe<Array<UserScalarWhereInput>>;
  organization_id?: InputMaybe<StringFilter>;
  panda_doc_access?: InputMaybe<BoolFilter>;
  password?: InputMaybe<StringNullableFilter>;
  phone_number?: InputMaybe<StringNullableFilter>;
  profile_image?: InputMaybe<StringNullableFilter>;
  regions_white_list_array?: InputMaybe<StringNullableListFilter>;
  regions_white_list_assign_type?: InputMaybe<EnumAssignTypeFilter>;
  rep_scores?: InputMaybe<FloatNullableFilter>;
  role?: InputMaybe<EnumRoleFilter>;
  sales_team_column_options?: InputMaybe<StringNullableListFilter>;
  sequence_entry_criteria_id?: InputMaybe<StringNullableFilter>;
  session_token?: InputMaybe<StringNullableFilter>;
  sf_user_id?: InputMaybe<StringNullableFilter>;
  status?: InputMaybe<EnumStatusFilter>;
  sub_industry_white_list_array?: InputMaybe<StringNullableListFilter>;
  sub_industry_white_list_assign_type?: InputMaybe<EnumAssignTypeFilter>;
  support_rep?: InputMaybe<BoolFilter>;
  taking_demos?: InputMaybe<BoolFilter>;
  team_id?: InputMaybe<StringNullableFilter>;
  timekit_id?: InputMaybe<StringNullableFilter>;
  timekit_type?: InputMaybe<EnumTimekittypeFilter>;
  timezone_raw?: InputMaybe<StringNullableFilter>;
  transfer_type?: InputMaybe<EnumTransferTypeFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
  visible_all_leads_sm?: InputMaybe<BoolFilter>;
  visible_call_report_columns?: InputMaybe<StringNullableListFilter>;
  visible_call_report_event_columns?: InputMaybe<StringNullableListFilter>;
  visible_call_report_pipeline_columns?: InputMaybe<StringNullableListFilter>;
  visible_my_leads_columns?: InputMaybe<StringNullableListFilter>;
  voicemail_url?: InputMaybe<StringNullableFilter>;
};

export type UserStatus = {
  __typename?: 'UserStatus';
  conference_id?: Maybe<Scalars['String']['output']>;
  organization_id?: Maybe<Scalars['ID']['output']>;
  status?: Maybe<Status>;
  updated_at?: Maybe<Scalars['String']['output']>;
  user_id?: Maybe<Scalars['ID']['output']>;
};

export type UserStatusHistory = {
  __typename?: 'UserStatusHistory';
  created_at: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  status: Status;
  updated_at: Scalars['DateTime']['output'];
  user?: Maybe<User>;
  user_id?: Maybe<Scalars['String']['output']>;
};

export type UserStatusHistoryCreateManyUserInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Status>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type UserStatusHistoryCreateManyUserInputEnvelope = {
  data?: InputMaybe<Array<UserStatusHistoryCreateManyUserInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UserStatusHistoryCreateNestedManyWithoutUserInput = {
  connect?: InputMaybe<Array<UserStatusHistoryWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserStatusHistoryCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<UserStatusHistoryCreateWithoutUserInput>>;
  createMany?: InputMaybe<UserStatusHistoryCreateManyUserInputEnvelope>;
};

export type UserStatusHistoryCreateOrConnectWithoutUserInput = {
  create: UserStatusHistoryCreateWithoutUserInput;
  where: UserStatusHistoryWhereUniqueInput;
};

export type UserStatusHistoryCreateWithoutUserInput = {
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Status>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
};

export type UserStatusHistoryListRelationFilter = {
  every?: InputMaybe<UserStatusHistoryWhereInput>;
  none?: InputMaybe<UserStatusHistoryWhereInput>;
  some?: InputMaybe<UserStatusHistoryWhereInput>;
};

export type UserStatusHistoryOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
  count?: InputMaybe<SortOrder>;
};

export type UserStatusHistoryScalarWhereInput = {
  AND?: InputMaybe<Array<UserStatusHistoryScalarWhereInput>>;
  created_at?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  NOT?: InputMaybe<Array<UserStatusHistoryScalarWhereInput>>;
  OR?: InputMaybe<Array<UserStatusHistoryScalarWhereInput>>;
  status?: InputMaybe<EnumStatusFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
  user_id?: InputMaybe<StringNullableFilter>;
};

export type UserStatusHistoryUpdateManyMutationInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type UserStatusHistoryUpdateManyWithoutUserInput = {
  connect?: InputMaybe<Array<UserStatusHistoryWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserStatusHistoryCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<UserStatusHistoryCreateWithoutUserInput>>;
  createMany?: InputMaybe<UserStatusHistoryCreateManyUserInputEnvelope>;
  delete?: InputMaybe<Array<UserStatusHistoryWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<UserStatusHistoryScalarWhereInput>>;
  disconnect?: InputMaybe<Array<UserStatusHistoryWhereUniqueInput>>;
  set?: InputMaybe<Array<UserStatusHistoryWhereUniqueInput>>;
  update?: InputMaybe<Array<UserStatusHistoryUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: InputMaybe<Array<UserStatusHistoryUpdateManyWithWhereWithoutUserInput>>;
  upsert?: InputMaybe<Array<UserStatusHistoryUpsertWithWhereUniqueWithoutUserInput>>;
};

export type UserStatusHistoryUpdateManyWithWhereWithoutUserInput = {
  data: UserStatusHistoryUpdateManyMutationInput;
  where: UserStatusHistoryScalarWhereInput;
};

export type UserStatusHistoryUpdateWithoutUserInput = {
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type UserStatusHistoryUpdateWithWhereUniqueWithoutUserInput = {
  data: UserStatusHistoryUpdateWithoutUserInput;
  where: UserStatusHistoryWhereUniqueInput;
};

export type UserStatusHistoryUpsertWithWhereUniqueWithoutUserInput = {
  create: UserStatusHistoryCreateWithoutUserInput;
  update: UserStatusHistoryUpdateWithoutUserInput;
  where: UserStatusHistoryWhereUniqueInput;
};

export type UserStatusHistoryWhereInput = {
  AND?: InputMaybe<Array<UserStatusHistoryWhereInput>>;
  created_at?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  NOT?: InputMaybe<Array<UserStatusHistoryWhereInput>>;
  OR?: InputMaybe<Array<UserStatusHistoryWhereInput>>;
  status?: InputMaybe<EnumStatusFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserWhereInput>;
  user_id?: InputMaybe<StringNullableFilter>;
};

export type UserStatusHistoryWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']['input']>;
};

export type UserUpdateindustry_White_List_ArrayInput = {
  push?: InputMaybe<Scalars['String']['input']>;
  set?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type UserUpdateInput = {
  ai_user?: InputMaybe<BoolFieldUpdateOperationsInput>;
  alternate_email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  always_show_calendar_options?: InputMaybe<BoolFieldUpdateOperationsInput>;
  associate_owner_rep_history?: InputMaybe<AssociationHistoryUpdateManyWithoutRepInput>;
  associate_user_history?: InputMaybe<AssociationHistoryUpdateManyWithoutUserInput>;
  auto_transfer_selection_receive_attempts?: InputMaybe<AutoTransferSelectionUpdateManyWithoutFrom_UserInput>;
  auto_transfer_selection_send_attempts?: InputMaybe<AutoTransferSelectionUpdateManyWithoutTo_UserInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptUpdateManyWithoutUserInput>;
  callSharingAsCoach?: InputMaybe<CallSharingUpdateManyWithoutCoachInput>;
  callSharingAsUser?: InputMaybe<CallSharingUpdateManyWithoutReceiver_UserInput>;
  channel?: InputMaybe<NullableEnumChannelfilterFieldUpdateOperationsInput>;
  close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  close_user_cycle?: InputMaybe<SaleCycleUpdateManyWithoutClose_UserInput>;
  close_user_stat?: InputMaybe<StatLeadAggregationUpdateManyWithoutClose_UserInput>;
  commissions?: InputMaybe<CommissionUpdateManyWithoutUserInput>;
  contact_requests?: InputMaybe<ContactRequestUpdateManyWithoutRequesting_UserInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  current_sequence_view?: InputMaybe<SequenceDashboardViewUpdateOneWithoutUserInput>;
  current_team_start_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  custom_field_white_list_array?: InputMaybe<UserCustomFieldFilterUpdateManyWithoutUserInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemUpdateManyWithoutUserInput>;
  daily_lead_claim?: InputMaybe<IntFieldUpdateOperationsInput>;
  dashboard_2_access?: InputMaybe<BoolFieldUpdateOperationsInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewUpdateManyWithoutUserInput>;
  default_audio_input?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  default_audio_output?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  default_availability?: InputMaybe<BoolFieldUpdateOperationsInput>;
  deleted_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  deprecate_old_session?: InputMaybe<BoolFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordUpdateManyWithoutUserInput>;
  favorite_leads?: InputMaybe<LeadUpdateManyWithoutFavorited_By_UsersInput>;
  first_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  folder_permissions?: InputMaybe<FolderPermissionUpdateManyWithoutUserInput>;
  folders?: InputMaybe<FolderUpdateManyWithoutOwnerInput>;
  from_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutOld_UserInput>;
  GlobalRouting?: InputMaybe<GlobalRepRoutingUpdateManyWithoutRepInput>;
  google_refresh_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  holds_per_prospect_vs_held_demo_per_dial?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  hook_subscriptions?: InputMaybe<WebHookSubscriptionUpdateManyWithoutUserInput>;
  hubspot_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  idle_owned_leads?: InputMaybe<LeadUpdateManyWithoutSecondary_RepsInput>;
  industry_filter?: InputMaybe<BoolFieldUpdateOperationsInput>;
  industry_white_list_array?: InputMaybe<UserUpdateindustry_White_List_ArrayInput>;
  industry_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  joinMe_access_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  joinMe_refresh_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  last_demo_transferred_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  last_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutUserInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryUpdateManyWithoutUserInput>;
  lead_intents?: InputMaybe<LeadIntentUpdateManyWithoutUserInput>;
  lead_source_filter?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lead_source_white_list_array?: InputMaybe<UserUpdatelead_Source_White_List_ArrayInput>;
  lead_source_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  leads_last_called?: InputMaybe<LeadUpdateManyWithoutLast_Call_Result_RepInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusUpdateManyWithoutUserInput>;
  manager_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  note_item?: InputMaybe<NoteItemUpdateManyWithoutUserInput>;
  notifications?: InputMaybe<NotificationUpdateManyWithoutUserInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutUsersInput>;
  owned_leads?: InputMaybe<LeadUpdateManyWithoutRepInput>;
  panda_doc_access?: InputMaybe<BoolFieldUpdateOperationsInput>;
  password?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  payment_settings?: InputMaybe<OrganizationPaymentSettingsUpdateManyWithoutCreated_ByInput>;
  payment_settings_updated?: InputMaybe<OrganizationPaymentSettingsUpdateManyWithoutLast_Updated_ByInput>;
  phone_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  post?: InputMaybe<PostUpdateManyWithoutAuthorInput>;
  ProductsCreated?: InputMaybe<ProductChangeLogUpdateManyWithoutUpdated_ByInput>;
  profile_image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  receive_transfer_attempts?: InputMaybe<TransferAttemptUpdateManyWithoutTo_UserInput>;
  receiver_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutReceiver_UserInput>;
  regions_white_list_array?: InputMaybe<UserUpdateregions_White_List_ArrayInput>;
  regions_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  rep_scores?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumRoleFieldUpdateOperationsInput>;
  routings?: InputMaybe<RoutingUpdateManyWithoutRepInput>;
  sale_config?: InputMaybe<SaleConfigUpdateManyWithoutCreated_ByInput>;
  sale_config_updated?: InputMaybe<SaleConfigUpdateManyWithoutLast_Updated_ByInput>;
  sales_team_column_options?: InputMaybe<UserUpdatesales_Team_Column_OptionsInput>;
  schedule_items?: InputMaybe<ScheduleItemUpdateManyWithoutUserInput>;
  send_transfer_attempts?: InputMaybe<TransferAttemptUpdateManyWithoutFrom_UserInput>;
  sender_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutSender_UserInput>;
  sequence_entry_criteria_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SequenceActionLog?: InputMaybe<StepActionLogUpdateManyWithoutRepInput>;
  sequenceDashboardView?: InputMaybe<SequenceDashboardViewUpdateManyWithoutOwned_ByInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutUserInput>;
  Sequences_created?: InputMaybe<SequenceUpdateManyWithoutCreated_ByInput>;
  Sequences_modified_by_user?: InputMaybe<SequenceUpdateManyWithoutLast_Updated_ByInput>;
  session_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  set_user_cycle?: InputMaybe<SaleCycleUpdateManyWithoutSet_UserInput>;
  set_user_stat?: InputMaybe<StatLeadAggregationUpdateManyWithoutSet_UserInput>;
  sf_user_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SmsItems?: InputMaybe<SmsItemUpdateManyWithoutRepInput>;
  SnoozeTracking?: InputMaybe<SnoozeTrackingUpdateManyWithoutRepInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  statusHistory?: InputMaybe<UserStatusHistoryUpdateManyWithoutUserInput>;
  sub_industry_white_list_array?: InputMaybe<UserUpdatesub_Industry_White_List_ArrayInput>;
  sub_industry_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  support_rep?: InputMaybe<BoolFieldUpdateOperationsInput>;
  taking_demos?: InputMaybe<BoolFieldUpdateOperationsInput>;
  team?: InputMaybe<TeamUpdateOneWithoutMembersInput>;
  Template?: InputMaybe<TemplateUpdateManyWithoutLast_Modified_ByInput>;
  TemplateVersion?: InputMaybe<TemplateVersionUpdateManyWithoutCreated_ByInput>;
  timekit_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timekit_type?: InputMaybe<EnumTimekittypeFieldUpdateOperationsInput>;
  timeOffRequest?: InputMaybe<TimeOffRequestUpdateManyWithoutRequesting_UserInput>;
  timezone_raw?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transfer_type?: InputMaybe<EnumTransferTypeFieldUpdateOperationsInput>;
  transfer_user_cycle?: InputMaybe<SaleCycleUpdateManyWithoutTransfer_UserInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user_custom_availability?: InputMaybe<AvailabilityUpdateManyWithoutUserInput>;
  user_past_tokens?: InputMaybe<UserPastTokensUpdateManyWithoutUserInput>;
  visible_all_leads_sm?: InputMaybe<BoolFieldUpdateOperationsInput>;
  visible_call_report_columns?: InputMaybe<UserUpdatevisible_Call_Report_ColumnsInput>;
  visible_call_report_event_columns?: InputMaybe<UserUpdatevisible_Call_Report_Event_ColumnsInput>;
  visible_call_report_pipeline_columns?: InputMaybe<UserUpdatevisible_Call_Report_Pipeline_ColumnsInput>;
  visible_my_leads_columns?: InputMaybe<UserUpdatevisible_My_Leads_ColumnsInput>;
  voicemail_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type UserUpdatelead_Source_White_List_ArrayInput = {
  push?: InputMaybe<Scalars['String']['input']>;
  set?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type UserUpdateManyMutationInput = {
  ai_user?: InputMaybe<BoolFieldUpdateOperationsInput>;
  alternate_email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  always_show_calendar_options?: InputMaybe<BoolFieldUpdateOperationsInput>;
  channel?: InputMaybe<NullableEnumChannelfilterFieldUpdateOperationsInput>;
  close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  current_team_start_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  daily_lead_claim?: InputMaybe<IntFieldUpdateOperationsInput>;
  dashboard_2_access?: InputMaybe<BoolFieldUpdateOperationsInput>;
  default_audio_input?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  default_audio_output?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  default_availability?: InputMaybe<BoolFieldUpdateOperationsInput>;
  deleted_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  deprecate_old_session?: InputMaybe<BoolFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  first_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  google_refresh_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  holds_per_prospect_vs_held_demo_per_dial?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  hubspot_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  industry_filter?: InputMaybe<BoolFieldUpdateOperationsInput>;
  industry_white_list_array?: InputMaybe<UserUpdateindustry_White_List_ArrayInput>;
  industry_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  joinMe_access_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  joinMe_refresh_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  last_demo_transferred_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  last_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  lead_source_filter?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lead_source_white_list_array?: InputMaybe<UserUpdatelead_Source_White_List_ArrayInput>;
  lead_source_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  manager_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  panda_doc_access?: InputMaybe<BoolFieldUpdateOperationsInput>;
  password?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phone_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  profile_image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  regions_white_list_array?: InputMaybe<UserUpdateregions_White_List_ArrayInput>;
  regions_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  rep_scores?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumRoleFieldUpdateOperationsInput>;
  sales_team_column_options?: InputMaybe<UserUpdatesales_Team_Column_OptionsInput>;
  sequence_entry_criteria_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  session_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sf_user_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  sub_industry_white_list_array?: InputMaybe<UserUpdatesub_Industry_White_List_ArrayInput>;
  sub_industry_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  support_rep?: InputMaybe<BoolFieldUpdateOperationsInput>;
  taking_demos?: InputMaybe<BoolFieldUpdateOperationsInput>;
  timekit_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timekit_type?: InputMaybe<EnumTimekittypeFieldUpdateOperationsInput>;
  timezone_raw?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transfer_type?: InputMaybe<EnumTransferTypeFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  visible_all_leads_sm?: InputMaybe<BoolFieldUpdateOperationsInput>;
  visible_call_report_columns?: InputMaybe<UserUpdatevisible_Call_Report_ColumnsInput>;
  visible_call_report_event_columns?: InputMaybe<UserUpdatevisible_Call_Report_Event_ColumnsInput>;
  visible_call_report_pipeline_columns?: InputMaybe<UserUpdatevisible_Call_Report_Pipeline_ColumnsInput>;
  visible_my_leads_columns?: InputMaybe<UserUpdatevisible_My_Leads_ColumnsInput>;
  voicemail_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type UserUpdateManyWithoutCurrent_Sequence_ViewInput = {
  connect?: InputMaybe<Array<UserWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserCreateOrConnectWithoutCurrent_Sequence_ViewInput>>;
  create?: InputMaybe<Array<UserCreateWithoutCurrent_Sequence_ViewInput>>;
  createMany?: InputMaybe<UserCreateManyCurrent_Sequence_ViewInputEnvelope>;
  delete?: InputMaybe<Array<UserWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<UserScalarWhereInput>>;
  disconnect?: InputMaybe<Array<UserWhereUniqueInput>>;
  set?: InputMaybe<Array<UserWhereUniqueInput>>;
  update?: InputMaybe<Array<UserUpdateWithWhereUniqueWithoutCurrent_Sequence_ViewInput>>;
  updateMany?: InputMaybe<Array<UserUpdateManyWithWhereWithoutCurrent_Sequence_ViewInput>>;
  upsert?: InputMaybe<Array<UserUpsertWithWhereUniqueWithoutCurrent_Sequence_ViewInput>>;
};

export type UserUpdateManyWithoutFavorite_LeadsInput = {
  connect?: InputMaybe<Array<UserWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserCreateOrConnectWithoutFavorite_LeadsInput>>;
  create?: InputMaybe<Array<UserCreateWithoutFavorite_LeadsInput>>;
  delete?: InputMaybe<Array<UserWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<UserScalarWhereInput>>;
  disconnect?: InputMaybe<Array<UserWhereUniqueInput>>;
  set?: InputMaybe<Array<UserWhereUniqueInput>>;
  update?: InputMaybe<Array<UserUpdateWithWhereUniqueWithoutFavorite_LeadsInput>>;
  updateMany?: InputMaybe<Array<UserUpdateManyWithWhereWithoutFavorite_LeadsInput>>;
  upsert?: InputMaybe<Array<UserUpsertWithWhereUniqueWithoutFavorite_LeadsInput>>;
};

export type UserUpdateManyWithoutIdle_Owned_LeadsInput = {
  connect?: InputMaybe<Array<UserWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserCreateOrConnectWithoutIdle_Owned_LeadsInput>>;
  create?: InputMaybe<Array<UserCreateWithoutIdle_Owned_LeadsInput>>;
  delete?: InputMaybe<Array<UserWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<UserScalarWhereInput>>;
  disconnect?: InputMaybe<Array<UserWhereUniqueInput>>;
  set?: InputMaybe<Array<UserWhereUniqueInput>>;
  update?: InputMaybe<Array<UserUpdateWithWhereUniqueWithoutIdle_Owned_LeadsInput>>;
  updateMany?: InputMaybe<Array<UserUpdateManyWithWhereWithoutIdle_Owned_LeadsInput>>;
  upsert?: InputMaybe<Array<UserUpsertWithWhereUniqueWithoutIdle_Owned_LeadsInput>>;
};

export type UserUpdateManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<UserWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<UserCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<UserCreateManyOrganizationInputEnvelope>;
  delete?: InputMaybe<Array<UserWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<UserScalarWhereInput>>;
  disconnect?: InputMaybe<Array<UserWhereUniqueInput>>;
  set?: InputMaybe<Array<UserWhereUniqueInput>>;
  update?: InputMaybe<Array<UserUpdateWithWhereUniqueWithoutOrganizationInput>>;
  updateMany?: InputMaybe<Array<UserUpdateManyWithWhereWithoutOrganizationInput>>;
  upsert?: InputMaybe<Array<UserUpsertWithWhereUniqueWithoutOrganizationInput>>;
};

export type UserUpdateManyWithoutTeamInput = {
  connect?: InputMaybe<Array<UserWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserCreateOrConnectWithoutTeamInput>>;
  create?: InputMaybe<Array<UserCreateWithoutTeamInput>>;
  createMany?: InputMaybe<UserCreateManyTeamInputEnvelope>;
  delete?: InputMaybe<Array<UserWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<UserScalarWhereInput>>;
  disconnect?: InputMaybe<Array<UserWhereUniqueInput>>;
  set?: InputMaybe<Array<UserWhereUniqueInput>>;
  update?: InputMaybe<Array<UserUpdateWithWhereUniqueWithoutTeamInput>>;
  updateMany?: InputMaybe<Array<UserUpdateManyWithWhereWithoutTeamInput>>;
  upsert?: InputMaybe<Array<UserUpsertWithWhereUniqueWithoutTeamInput>>;
};

export type UserUpdateManyWithWhereWithoutCurrent_Sequence_ViewInput = {
  data: UserUpdateManyMutationInput;
  where: UserScalarWhereInput;
};

export type UserUpdateManyWithWhereWithoutFavorite_LeadsInput = {
  data: UserUpdateManyMutationInput;
  where: UserScalarWhereInput;
};

export type UserUpdateManyWithWhereWithoutIdle_Owned_LeadsInput = {
  data: UserUpdateManyMutationInput;
  where: UserScalarWhereInput;
};

export type UserUpdateManyWithWhereWithoutOrganizationInput = {
  data: UserUpdateManyMutationInput;
  where: UserScalarWhereInput;
};

export type UserUpdateManyWithWhereWithoutTeamInput = {
  data: UserUpdateManyMutationInput;
  where: UserScalarWhereInput;
};

export type UserUpdateOneRequiredWithoutAssociate_User_HistoryInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutAssociate_User_HistoryInput>;
  create?: InputMaybe<UserCreateWithoutAssociate_User_HistoryInput>;
  update?: InputMaybe<UserUpdateWithoutAssociate_User_HistoryInput>;
  upsert?: InputMaybe<UserUpsertWithoutAssociate_User_HistoryInput>;
};

export type UserUpdateOneRequiredWithoutCustom_Field_White_List_ArrayInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutCustom_Field_White_List_ArrayInput>;
  create?: InputMaybe<UserCreateWithoutCustom_Field_White_List_ArrayInput>;
  update?: InputMaybe<UserUpdateWithoutCustom_Field_White_List_ArrayInput>;
  upsert?: InputMaybe<UserUpsertWithoutCustom_Field_White_List_ArrayInput>;
};

export type UserUpdateOneRequiredWithoutCustom_Queue_ItemsInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutCustom_Queue_ItemsInput>;
  create?: InputMaybe<UserCreateWithoutCustom_Queue_ItemsInput>;
  update?: InputMaybe<UserUpdateWithoutCustom_Queue_ItemsInput>;
  upsert?: InputMaybe<UserUpsertWithoutCustom_Queue_ItemsInput>;
};

export type UserUpdateOneRequiredWithoutFolder_PermissionsInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutFolder_PermissionsInput>;
  create?: InputMaybe<UserCreateWithoutFolder_PermissionsInput>;
  update?: InputMaybe<UserUpdateWithoutFolder_PermissionsInput>;
  upsert?: InputMaybe<UserUpsertWithoutFolder_PermissionsInput>;
};

export type UserUpdateOneRequiredWithoutGlobalRoutingInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutGlobalRoutingInput>;
  create?: InputMaybe<UserCreateWithoutGlobalRoutingInput>;
  update?: InputMaybe<UserUpdateWithoutGlobalRoutingInput>;
  upsert?: InputMaybe<UserUpsertWithoutGlobalRoutingInput>;
};

export type UserUpdateOneRequiredWithoutLiveUserStatusInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutLiveUserStatusInput>;
  create?: InputMaybe<UserCreateWithoutLiveUserStatusInput>;
  update?: InputMaybe<UserUpdateWithoutLiveUserStatusInput>;
  upsert?: InputMaybe<UserUpsertWithoutLiveUserStatusInput>;
};

export type UserUpdateOneRequiredWithoutProductsCreatedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutProductsCreatedInput>;
  create?: InputMaybe<UserCreateWithoutProductsCreatedInput>;
  update?: InputMaybe<UserUpdateWithoutProductsCreatedInput>;
  upsert?: InputMaybe<UserUpsertWithoutProductsCreatedInput>;
};

export type UserUpdateOneRequiredWithoutRoutingsInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutRoutingsInput>;
  create?: InputMaybe<UserCreateWithoutRoutingsInput>;
  update?: InputMaybe<UserUpdateWithoutRoutingsInput>;
  upsert?: InputMaybe<UserUpsertWithoutRoutingsInput>;
};

export type UserUpdateOneRequiredWithoutSequenceActionLogInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutSequenceActionLogInput>;
  create?: InputMaybe<UserCreateWithoutSequenceActionLogInput>;
  update?: InputMaybe<UserUpdateWithoutSequenceActionLogInput>;
  upsert?: InputMaybe<UserUpsertWithoutSequenceActionLogInput>;
};

export type UserUpdateOneRequiredWithoutSequenceDashboardViewInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutSequenceDashboardViewInput>;
  create?: InputMaybe<UserCreateWithoutSequenceDashboardViewInput>;
  update?: InputMaybe<UserUpdateWithoutSequenceDashboardViewInput>;
  upsert?: InputMaybe<UserUpsertWithoutSequenceDashboardViewInput>;
};

export type UserUpdateOneRequiredWithoutSequences_CreatedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutSequences_CreatedInput>;
  create?: InputMaybe<UserCreateWithoutSequences_CreatedInput>;
  update?: InputMaybe<UserUpdateWithoutSequences_CreatedInput>;
  upsert?: InputMaybe<UserUpsertWithoutSequences_CreatedInput>;
};

export type UserUpdateOneRequiredWithoutSequences_Modified_By_UserInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutSequences_Modified_By_UserInput>;
  create?: InputMaybe<UserCreateWithoutSequences_Modified_By_UserInput>;
  update?: InputMaybe<UserUpdateWithoutSequences_Modified_By_UserInput>;
  upsert?: InputMaybe<UserUpsertWithoutSequences_Modified_By_UserInput>;
};

export type UserUpdateOneRequiredWithoutSnoozeTrackingInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutSnoozeTrackingInput>;
  create?: InputMaybe<UserCreateWithoutSnoozeTrackingInput>;
  update?: InputMaybe<UserUpdateWithoutSnoozeTrackingInput>;
  upsert?: InputMaybe<UserUpsertWithoutSnoozeTrackingInput>;
};

export type UserUpdateOneRequiredWithoutTemplateInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutTemplateInput>;
  create?: InputMaybe<UserCreateWithoutTemplateInput>;
  update?: InputMaybe<UserUpdateWithoutTemplateInput>;
  upsert?: InputMaybe<UserUpsertWithoutTemplateInput>;
};

export type UserUpdateOneRequiredWithoutTemplateVersionInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutTemplateVersionInput>;
  create?: InputMaybe<UserCreateWithoutTemplateVersionInput>;
  update?: InputMaybe<UserUpdateWithoutTemplateVersionInput>;
  upsert?: InputMaybe<UserUpsertWithoutTemplateVersionInput>;
};

export type UserUpdateOneWithoutAssociate_Owner_Rep_HistoryInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutAssociate_Owner_Rep_HistoryInput>;
  create?: InputMaybe<UserCreateWithoutAssociate_Owner_Rep_HistoryInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<UserUpdateWithoutAssociate_Owner_Rep_HistoryInput>;
  upsert?: InputMaybe<UserUpsertWithoutAssociate_Owner_Rep_HistoryInput>;
};

export type UserUpdateOneWithoutAuto_Transfer_Selection_Receive_AttemptsInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutAuto_Transfer_Selection_Receive_AttemptsInput>;
  create?: InputMaybe<UserCreateWithoutAuto_Transfer_Selection_Receive_AttemptsInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<UserUpdateWithoutAuto_Transfer_Selection_Receive_AttemptsInput>;
  upsert?: InputMaybe<UserUpsertWithoutAuto_Transfer_Selection_Receive_AttemptsInput>;
};

export type UserUpdateOneWithoutAuto_Transfer_Selection_Send_AttemptsInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutAuto_Transfer_Selection_Send_AttemptsInput>;
  create?: InputMaybe<UserCreateWithoutAuto_Transfer_Selection_Send_AttemptsInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<UserUpdateWithoutAuto_Transfer_Selection_Send_AttemptsInput>;
  upsert?: InputMaybe<UserUpsertWithoutAuto_Transfer_Selection_Send_AttemptsInput>;
};

export type UserUpdateOneWithoutCallMeNowAttemptsInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutCallMeNowAttemptsInput>;
  create?: InputMaybe<UserCreateWithoutCallMeNowAttemptsInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<UserUpdateWithoutCallMeNowAttemptsInput>;
  upsert?: InputMaybe<UserUpsertWithoutCallMeNowAttemptsInput>;
};

export type UserUpdateOneWithoutCallSharingAsCoachInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutCallSharingAsCoachInput>;
  create?: InputMaybe<UserCreateWithoutCallSharingAsCoachInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<UserUpdateWithoutCallSharingAsCoachInput>;
  upsert?: InputMaybe<UserUpsertWithoutCallSharingAsCoachInput>;
};

export type UserUpdateOneWithoutCallSharingAsUserInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutCallSharingAsUserInput>;
  create?: InputMaybe<UserCreateWithoutCallSharingAsUserInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<UserUpdateWithoutCallSharingAsUserInput>;
  upsert?: InputMaybe<UserUpsertWithoutCallSharingAsUserInput>;
};

export type UserUpdateOneWithoutClose_User_CycleInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutClose_User_CycleInput>;
  create?: InputMaybe<UserCreateWithoutClose_User_CycleInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<UserUpdateWithoutClose_User_CycleInput>;
  upsert?: InputMaybe<UserUpsertWithoutClose_User_CycleInput>;
};

export type UserUpdateOneWithoutClose_User_StatInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutClose_User_StatInput>;
  create?: InputMaybe<UserCreateWithoutClose_User_StatInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<UserUpdateWithoutClose_User_StatInput>;
  upsert?: InputMaybe<UserUpsertWithoutClose_User_StatInput>;
};

export type UserUpdateOneWithoutCommissionsInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutCommissionsInput>;
  create?: InputMaybe<UserCreateWithoutCommissionsInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<UserUpdateWithoutCommissionsInput>;
  upsert?: InputMaybe<UserUpsertWithoutCommissionsInput>;
};

export type UserUpdateOneWithoutContact_RequestsInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutContact_RequestsInput>;
  create?: InputMaybe<UserCreateWithoutContact_RequestsInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<UserUpdateWithoutContact_RequestsInput>;
  upsert?: InputMaybe<UserUpsertWithoutContact_RequestsInput>;
};

export type UserUpdateOneWithoutDashboardSavedViewInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutDashboardSavedViewInput>;
  create?: InputMaybe<UserCreateWithoutDashboardSavedViewInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<UserUpdateWithoutDashboardSavedViewInput>;
  upsert?: InputMaybe<UserUpsertWithoutDashboardSavedViewInput>;
};

export type UserUpdateOneWithoutEmailSyncRecordInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutEmailSyncRecordInput>;
  create?: InputMaybe<UserCreateWithoutEmailSyncRecordInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<UserUpdateWithoutEmailSyncRecordInput>;
  upsert?: InputMaybe<UserUpsertWithoutEmailSyncRecordInput>;
};

export type UserUpdateOneWithoutFoldersInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutFoldersInput>;
  create?: InputMaybe<UserCreateWithoutFoldersInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<UserUpdateWithoutFoldersInput>;
  upsert?: InputMaybe<UserUpsertWithoutFoldersInput>;
};

export type UserUpdateOneWithoutFrom_Assignment_HistoryInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutFrom_Assignment_HistoryInput>;
  create?: InputMaybe<UserCreateWithoutFrom_Assignment_HistoryInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<UserUpdateWithoutFrom_Assignment_HistoryInput>;
  upsert?: InputMaybe<UserUpsertWithoutFrom_Assignment_HistoryInput>;
};

export type UserUpdateOneWithoutLead_ActivitiesInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutLead_ActivitiesInput>;
  create?: InputMaybe<UserCreateWithoutLead_ActivitiesInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<UserUpdateWithoutLead_ActivitiesInput>;
  upsert?: InputMaybe<UserUpsertWithoutLead_ActivitiesInput>;
};

export type UserUpdateOneWithoutLead_Import_HistoryInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutLead_Import_HistoryInput>;
  create?: InputMaybe<UserCreateWithoutLead_Import_HistoryInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<UserUpdateWithoutLead_Import_HistoryInput>;
  upsert?: InputMaybe<UserUpsertWithoutLead_Import_HistoryInput>;
};

export type UserUpdateOneWithoutLead_IntentsInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutLead_IntentsInput>;
  create?: InputMaybe<UserCreateWithoutLead_IntentsInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<UserUpdateWithoutLead_IntentsInput>;
  upsert?: InputMaybe<UserUpsertWithoutLead_IntentsInput>;
};

export type UserUpdateOneWithoutLeads_Last_CalledInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutLeads_Last_CalledInput>;
  create?: InputMaybe<UserCreateWithoutLeads_Last_CalledInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<UserUpdateWithoutLeads_Last_CalledInput>;
  upsert?: InputMaybe<UserUpsertWithoutLeads_Last_CalledInput>;
};

export type UserUpdateOneWithoutNote_ItemInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutNote_ItemInput>;
  create?: InputMaybe<UserCreateWithoutNote_ItemInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<UserUpdateWithoutNote_ItemInput>;
  upsert?: InputMaybe<UserUpsertWithoutNote_ItemInput>;
};

export type UserUpdateOneWithoutNotificationsInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutNotificationsInput>;
  create?: InputMaybe<UserCreateWithoutNotificationsInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<UserUpdateWithoutNotificationsInput>;
  upsert?: InputMaybe<UserUpsertWithoutNotificationsInput>;
};

export type UserUpdateOneWithoutOwned_LeadsInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutOwned_LeadsInput>;
  create?: InputMaybe<UserCreateWithoutOwned_LeadsInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<UserUpdateWithoutOwned_LeadsInput>;
  upsert?: InputMaybe<UserUpsertWithoutOwned_LeadsInput>;
};

export type UserUpdateOneWithoutPayment_Settings_UpdatedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutPayment_Settings_UpdatedInput>;
  create?: InputMaybe<UserCreateWithoutPayment_Settings_UpdatedInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<UserUpdateWithoutPayment_Settings_UpdatedInput>;
  upsert?: InputMaybe<UserUpsertWithoutPayment_Settings_UpdatedInput>;
};

export type UserUpdateOneWithoutPayment_SettingsInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutPayment_SettingsInput>;
  create?: InputMaybe<UserCreateWithoutPayment_SettingsInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<UserUpdateWithoutPayment_SettingsInput>;
  upsert?: InputMaybe<UserUpsertWithoutPayment_SettingsInput>;
};

export type UserUpdateOneWithoutReceive_Transfer_AttemptsInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutReceive_Transfer_AttemptsInput>;
  create?: InputMaybe<UserCreateWithoutReceive_Transfer_AttemptsInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<UserUpdateWithoutReceive_Transfer_AttemptsInput>;
  upsert?: InputMaybe<UserUpsertWithoutReceive_Transfer_AttemptsInput>;
};

export type UserUpdateOneWithoutReceiver_Assignment_HistoryInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutReceiver_Assignment_HistoryInput>;
  create?: InputMaybe<UserCreateWithoutReceiver_Assignment_HistoryInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<UserUpdateWithoutReceiver_Assignment_HistoryInput>;
  upsert?: InputMaybe<UserUpsertWithoutReceiver_Assignment_HistoryInput>;
};

export type UserUpdateOneWithoutSale_Config_UpdatedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutSale_Config_UpdatedInput>;
  create?: InputMaybe<UserCreateWithoutSale_Config_UpdatedInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<UserUpdateWithoutSale_Config_UpdatedInput>;
  upsert?: InputMaybe<UserUpsertWithoutSale_Config_UpdatedInput>;
};

export type UserUpdateOneWithoutSale_ConfigInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutSale_ConfigInput>;
  create?: InputMaybe<UserCreateWithoutSale_ConfigInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<UserUpdateWithoutSale_ConfigInput>;
  upsert?: InputMaybe<UserUpsertWithoutSale_ConfigInput>;
};

export type UserUpdateOneWithoutSchedule_ItemsInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutSchedule_ItemsInput>;
  create?: InputMaybe<UserCreateWithoutSchedule_ItemsInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<UserUpdateWithoutSchedule_ItemsInput>;
  upsert?: InputMaybe<UserUpsertWithoutSchedule_ItemsInput>;
};

export type UserUpdateOneWithoutSend_Transfer_AttemptsInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutSend_Transfer_AttemptsInput>;
  create?: InputMaybe<UserCreateWithoutSend_Transfer_AttemptsInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<UserUpdateWithoutSend_Transfer_AttemptsInput>;
  upsert?: InputMaybe<UserUpsertWithoutSend_Transfer_AttemptsInput>;
};

export type UserUpdateOneWithoutSender_Assignment_HistoryInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutSender_Assignment_HistoryInput>;
  create?: InputMaybe<UserCreateWithoutSender_Assignment_HistoryInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<UserUpdateWithoutSender_Assignment_HistoryInput>;
  upsert?: InputMaybe<UserUpsertWithoutSender_Assignment_HistoryInput>;
};

export type UserUpdateOneWithoutSequenceEntryExitLeadInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutSequenceEntryExitLeadInput>;
  create?: InputMaybe<UserCreateWithoutSequenceEntryExitLeadInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<UserUpdateWithoutSequenceEntryExitLeadInput>;
  upsert?: InputMaybe<UserUpsertWithoutSequenceEntryExitLeadInput>;
};

export type UserUpdateOneWithoutSet_User_CycleInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutSet_User_CycleInput>;
  create?: InputMaybe<UserCreateWithoutSet_User_CycleInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<UserUpdateWithoutSet_User_CycleInput>;
  upsert?: InputMaybe<UserUpsertWithoutSet_User_CycleInput>;
};

export type UserUpdateOneWithoutSet_User_StatInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutSet_User_StatInput>;
  create?: InputMaybe<UserCreateWithoutSet_User_StatInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<UserUpdateWithoutSet_User_StatInput>;
  upsert?: InputMaybe<UserUpsertWithoutSet_User_StatInput>;
};

export type UserUpdateOneWithoutSmsItemsInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutSmsItemsInput>;
  create?: InputMaybe<UserCreateWithoutSmsItemsInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<UserUpdateWithoutSmsItemsInput>;
  upsert?: InputMaybe<UserUpsertWithoutSmsItemsInput>;
};

export type UserUpdateOneWithoutTimeOffRequestInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutTimeOffRequestInput>;
  create?: InputMaybe<UserCreateWithoutTimeOffRequestInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<UserUpdateWithoutTimeOffRequestInput>;
  upsert?: InputMaybe<UserUpsertWithoutTimeOffRequestInput>;
};

export type UserUpdateOneWithoutTransfer_User_CycleInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutTransfer_User_CycleInput>;
  create?: InputMaybe<UserCreateWithoutTransfer_User_CycleInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<UserUpdateWithoutTransfer_User_CycleInput>;
  upsert?: InputMaybe<UserUpsertWithoutTransfer_User_CycleInput>;
};

export type UserUpdateOneWithoutUser_Custom_AvailabilityInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutUser_Custom_AvailabilityInput>;
  create?: InputMaybe<UserCreateWithoutUser_Custom_AvailabilityInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<UserUpdateWithoutUser_Custom_AvailabilityInput>;
  upsert?: InputMaybe<UserUpsertWithoutUser_Custom_AvailabilityInput>;
};

export type UserUpdateregions_White_List_ArrayInput = {
  push?: InputMaybe<Scalars['String']['input']>;
  set?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type UserUpdatesales_Team_Column_OptionsInput = {
  push?: InputMaybe<Scalars['String']['input']>;
  set?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type UserUpdatesub_Industry_White_List_ArrayInput = {
  push?: InputMaybe<Scalars['String']['input']>;
  set?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type UserUpdatevisible_Call_Report_ColumnsInput = {
  push?: InputMaybe<Scalars['String']['input']>;
  set?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type UserUpdatevisible_Call_Report_Event_ColumnsInput = {
  push?: InputMaybe<Scalars['String']['input']>;
  set?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type UserUpdatevisible_Call_Report_Pipeline_ColumnsInput = {
  push?: InputMaybe<Scalars['String']['input']>;
  set?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type UserUpdatevisible_My_Leads_ColumnsInput = {
  push?: InputMaybe<Scalars['String']['input']>;
  set?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type UserUpdateWithoutAssociate_Owner_Rep_HistoryInput = {
  ai_user?: InputMaybe<BoolFieldUpdateOperationsInput>;
  alternate_email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  always_show_calendar_options?: InputMaybe<BoolFieldUpdateOperationsInput>;
  associate_user_history?: InputMaybe<AssociationHistoryUpdateManyWithoutUserInput>;
  auto_transfer_selection_receive_attempts?: InputMaybe<AutoTransferSelectionUpdateManyWithoutFrom_UserInput>;
  auto_transfer_selection_send_attempts?: InputMaybe<AutoTransferSelectionUpdateManyWithoutTo_UserInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptUpdateManyWithoutUserInput>;
  callSharingAsCoach?: InputMaybe<CallSharingUpdateManyWithoutCoachInput>;
  callSharingAsUser?: InputMaybe<CallSharingUpdateManyWithoutReceiver_UserInput>;
  channel?: InputMaybe<NullableEnumChannelfilterFieldUpdateOperationsInput>;
  close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  close_user_cycle?: InputMaybe<SaleCycleUpdateManyWithoutClose_UserInput>;
  close_user_stat?: InputMaybe<StatLeadAggregationUpdateManyWithoutClose_UserInput>;
  commissions?: InputMaybe<CommissionUpdateManyWithoutUserInput>;
  contact_requests?: InputMaybe<ContactRequestUpdateManyWithoutRequesting_UserInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  current_sequence_view?: InputMaybe<SequenceDashboardViewUpdateOneWithoutUserInput>;
  current_team_start_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  custom_field_white_list_array?: InputMaybe<UserCustomFieldFilterUpdateManyWithoutUserInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemUpdateManyWithoutUserInput>;
  daily_lead_claim?: InputMaybe<IntFieldUpdateOperationsInput>;
  dashboard_2_access?: InputMaybe<BoolFieldUpdateOperationsInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewUpdateManyWithoutUserInput>;
  default_audio_input?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  default_audio_output?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  default_availability?: InputMaybe<BoolFieldUpdateOperationsInput>;
  deleted_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  deprecate_old_session?: InputMaybe<BoolFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordUpdateManyWithoutUserInput>;
  favorite_leads?: InputMaybe<LeadUpdateManyWithoutFavorited_By_UsersInput>;
  first_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  folder_permissions?: InputMaybe<FolderPermissionUpdateManyWithoutUserInput>;
  folders?: InputMaybe<FolderUpdateManyWithoutOwnerInput>;
  from_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutOld_UserInput>;
  GlobalRouting?: InputMaybe<GlobalRepRoutingUpdateManyWithoutRepInput>;
  google_refresh_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  holds_per_prospect_vs_held_demo_per_dial?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  hook_subscriptions?: InputMaybe<WebHookSubscriptionUpdateManyWithoutUserInput>;
  hubspot_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  idle_owned_leads?: InputMaybe<LeadUpdateManyWithoutSecondary_RepsInput>;
  industry_filter?: InputMaybe<BoolFieldUpdateOperationsInput>;
  industry_white_list_array?: InputMaybe<UserUpdateindustry_White_List_ArrayInput>;
  industry_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  joinMe_access_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  joinMe_refresh_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  last_demo_transferred_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  last_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutUserInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryUpdateManyWithoutUserInput>;
  lead_intents?: InputMaybe<LeadIntentUpdateManyWithoutUserInput>;
  lead_source_filter?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lead_source_white_list_array?: InputMaybe<UserUpdatelead_Source_White_List_ArrayInput>;
  lead_source_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  leads_last_called?: InputMaybe<LeadUpdateManyWithoutLast_Call_Result_RepInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusUpdateManyWithoutUserInput>;
  manager_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  note_item?: InputMaybe<NoteItemUpdateManyWithoutUserInput>;
  notifications?: InputMaybe<NotificationUpdateManyWithoutUserInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutUsersInput>;
  owned_leads?: InputMaybe<LeadUpdateManyWithoutRepInput>;
  panda_doc_access?: InputMaybe<BoolFieldUpdateOperationsInput>;
  password?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  payment_settings?: InputMaybe<OrganizationPaymentSettingsUpdateManyWithoutCreated_ByInput>;
  payment_settings_updated?: InputMaybe<OrganizationPaymentSettingsUpdateManyWithoutLast_Updated_ByInput>;
  phone_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  post?: InputMaybe<PostUpdateManyWithoutAuthorInput>;
  ProductsCreated?: InputMaybe<ProductChangeLogUpdateManyWithoutUpdated_ByInput>;
  profile_image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  receive_transfer_attempts?: InputMaybe<TransferAttemptUpdateManyWithoutTo_UserInput>;
  receiver_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutReceiver_UserInput>;
  regions_white_list_array?: InputMaybe<UserUpdateregions_White_List_ArrayInput>;
  regions_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  rep_scores?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumRoleFieldUpdateOperationsInput>;
  routings?: InputMaybe<RoutingUpdateManyWithoutRepInput>;
  sale_config?: InputMaybe<SaleConfigUpdateManyWithoutCreated_ByInput>;
  sale_config_updated?: InputMaybe<SaleConfigUpdateManyWithoutLast_Updated_ByInput>;
  sales_team_column_options?: InputMaybe<UserUpdatesales_Team_Column_OptionsInput>;
  schedule_items?: InputMaybe<ScheduleItemUpdateManyWithoutUserInput>;
  send_transfer_attempts?: InputMaybe<TransferAttemptUpdateManyWithoutFrom_UserInput>;
  sender_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutSender_UserInput>;
  sequence_entry_criteria_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SequenceActionLog?: InputMaybe<StepActionLogUpdateManyWithoutRepInput>;
  sequenceDashboardView?: InputMaybe<SequenceDashboardViewUpdateManyWithoutOwned_ByInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutUserInput>;
  Sequences_created?: InputMaybe<SequenceUpdateManyWithoutCreated_ByInput>;
  Sequences_modified_by_user?: InputMaybe<SequenceUpdateManyWithoutLast_Updated_ByInput>;
  session_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  set_user_cycle?: InputMaybe<SaleCycleUpdateManyWithoutSet_UserInput>;
  set_user_stat?: InputMaybe<StatLeadAggregationUpdateManyWithoutSet_UserInput>;
  sf_user_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SmsItems?: InputMaybe<SmsItemUpdateManyWithoutRepInput>;
  SnoozeTracking?: InputMaybe<SnoozeTrackingUpdateManyWithoutRepInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  statusHistory?: InputMaybe<UserStatusHistoryUpdateManyWithoutUserInput>;
  sub_industry_white_list_array?: InputMaybe<UserUpdatesub_Industry_White_List_ArrayInput>;
  sub_industry_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  support_rep?: InputMaybe<BoolFieldUpdateOperationsInput>;
  taking_demos?: InputMaybe<BoolFieldUpdateOperationsInput>;
  team?: InputMaybe<TeamUpdateOneWithoutMembersInput>;
  Template?: InputMaybe<TemplateUpdateManyWithoutLast_Modified_ByInput>;
  TemplateVersion?: InputMaybe<TemplateVersionUpdateManyWithoutCreated_ByInput>;
  timekit_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timekit_type?: InputMaybe<EnumTimekittypeFieldUpdateOperationsInput>;
  timeOffRequest?: InputMaybe<TimeOffRequestUpdateManyWithoutRequesting_UserInput>;
  timezone_raw?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transfer_type?: InputMaybe<EnumTransferTypeFieldUpdateOperationsInput>;
  transfer_user_cycle?: InputMaybe<SaleCycleUpdateManyWithoutTransfer_UserInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user_custom_availability?: InputMaybe<AvailabilityUpdateManyWithoutUserInput>;
  user_past_tokens?: InputMaybe<UserPastTokensUpdateManyWithoutUserInput>;
  visible_all_leads_sm?: InputMaybe<BoolFieldUpdateOperationsInput>;
  visible_call_report_columns?: InputMaybe<UserUpdatevisible_Call_Report_ColumnsInput>;
  visible_call_report_event_columns?: InputMaybe<UserUpdatevisible_Call_Report_Event_ColumnsInput>;
  visible_call_report_pipeline_columns?: InputMaybe<UserUpdatevisible_Call_Report_Pipeline_ColumnsInput>;
  visible_my_leads_columns?: InputMaybe<UserUpdatevisible_My_Leads_ColumnsInput>;
  voicemail_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutAssociate_User_HistoryInput = {
  ai_user?: InputMaybe<BoolFieldUpdateOperationsInput>;
  alternate_email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  always_show_calendar_options?: InputMaybe<BoolFieldUpdateOperationsInput>;
  associate_owner_rep_history?: InputMaybe<AssociationHistoryUpdateManyWithoutRepInput>;
  auto_transfer_selection_receive_attempts?: InputMaybe<AutoTransferSelectionUpdateManyWithoutFrom_UserInput>;
  auto_transfer_selection_send_attempts?: InputMaybe<AutoTransferSelectionUpdateManyWithoutTo_UserInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptUpdateManyWithoutUserInput>;
  callSharingAsCoach?: InputMaybe<CallSharingUpdateManyWithoutCoachInput>;
  callSharingAsUser?: InputMaybe<CallSharingUpdateManyWithoutReceiver_UserInput>;
  channel?: InputMaybe<NullableEnumChannelfilterFieldUpdateOperationsInput>;
  close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  close_user_cycle?: InputMaybe<SaleCycleUpdateManyWithoutClose_UserInput>;
  close_user_stat?: InputMaybe<StatLeadAggregationUpdateManyWithoutClose_UserInput>;
  commissions?: InputMaybe<CommissionUpdateManyWithoutUserInput>;
  contact_requests?: InputMaybe<ContactRequestUpdateManyWithoutRequesting_UserInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  current_sequence_view?: InputMaybe<SequenceDashboardViewUpdateOneWithoutUserInput>;
  current_team_start_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  custom_field_white_list_array?: InputMaybe<UserCustomFieldFilterUpdateManyWithoutUserInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemUpdateManyWithoutUserInput>;
  daily_lead_claim?: InputMaybe<IntFieldUpdateOperationsInput>;
  dashboard_2_access?: InputMaybe<BoolFieldUpdateOperationsInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewUpdateManyWithoutUserInput>;
  default_audio_input?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  default_audio_output?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  default_availability?: InputMaybe<BoolFieldUpdateOperationsInput>;
  deleted_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  deprecate_old_session?: InputMaybe<BoolFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordUpdateManyWithoutUserInput>;
  favorite_leads?: InputMaybe<LeadUpdateManyWithoutFavorited_By_UsersInput>;
  first_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  folder_permissions?: InputMaybe<FolderPermissionUpdateManyWithoutUserInput>;
  folders?: InputMaybe<FolderUpdateManyWithoutOwnerInput>;
  from_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutOld_UserInput>;
  GlobalRouting?: InputMaybe<GlobalRepRoutingUpdateManyWithoutRepInput>;
  google_refresh_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  holds_per_prospect_vs_held_demo_per_dial?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  hook_subscriptions?: InputMaybe<WebHookSubscriptionUpdateManyWithoutUserInput>;
  hubspot_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  idle_owned_leads?: InputMaybe<LeadUpdateManyWithoutSecondary_RepsInput>;
  industry_filter?: InputMaybe<BoolFieldUpdateOperationsInput>;
  industry_white_list_array?: InputMaybe<UserUpdateindustry_White_List_ArrayInput>;
  industry_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  joinMe_access_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  joinMe_refresh_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  last_demo_transferred_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  last_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutUserInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryUpdateManyWithoutUserInput>;
  lead_intents?: InputMaybe<LeadIntentUpdateManyWithoutUserInput>;
  lead_source_filter?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lead_source_white_list_array?: InputMaybe<UserUpdatelead_Source_White_List_ArrayInput>;
  lead_source_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  leads_last_called?: InputMaybe<LeadUpdateManyWithoutLast_Call_Result_RepInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusUpdateManyWithoutUserInput>;
  manager_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  note_item?: InputMaybe<NoteItemUpdateManyWithoutUserInput>;
  notifications?: InputMaybe<NotificationUpdateManyWithoutUserInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutUsersInput>;
  owned_leads?: InputMaybe<LeadUpdateManyWithoutRepInput>;
  panda_doc_access?: InputMaybe<BoolFieldUpdateOperationsInput>;
  password?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  payment_settings?: InputMaybe<OrganizationPaymentSettingsUpdateManyWithoutCreated_ByInput>;
  payment_settings_updated?: InputMaybe<OrganizationPaymentSettingsUpdateManyWithoutLast_Updated_ByInput>;
  phone_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  post?: InputMaybe<PostUpdateManyWithoutAuthorInput>;
  ProductsCreated?: InputMaybe<ProductChangeLogUpdateManyWithoutUpdated_ByInput>;
  profile_image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  receive_transfer_attempts?: InputMaybe<TransferAttemptUpdateManyWithoutTo_UserInput>;
  receiver_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutReceiver_UserInput>;
  regions_white_list_array?: InputMaybe<UserUpdateregions_White_List_ArrayInput>;
  regions_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  rep_scores?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumRoleFieldUpdateOperationsInput>;
  routings?: InputMaybe<RoutingUpdateManyWithoutRepInput>;
  sale_config?: InputMaybe<SaleConfigUpdateManyWithoutCreated_ByInput>;
  sale_config_updated?: InputMaybe<SaleConfigUpdateManyWithoutLast_Updated_ByInput>;
  sales_team_column_options?: InputMaybe<UserUpdatesales_Team_Column_OptionsInput>;
  schedule_items?: InputMaybe<ScheduleItemUpdateManyWithoutUserInput>;
  send_transfer_attempts?: InputMaybe<TransferAttemptUpdateManyWithoutFrom_UserInput>;
  sender_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutSender_UserInput>;
  sequence_entry_criteria_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SequenceActionLog?: InputMaybe<StepActionLogUpdateManyWithoutRepInput>;
  sequenceDashboardView?: InputMaybe<SequenceDashboardViewUpdateManyWithoutOwned_ByInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutUserInput>;
  Sequences_created?: InputMaybe<SequenceUpdateManyWithoutCreated_ByInput>;
  Sequences_modified_by_user?: InputMaybe<SequenceUpdateManyWithoutLast_Updated_ByInput>;
  session_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  set_user_cycle?: InputMaybe<SaleCycleUpdateManyWithoutSet_UserInput>;
  set_user_stat?: InputMaybe<StatLeadAggregationUpdateManyWithoutSet_UserInput>;
  sf_user_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SmsItems?: InputMaybe<SmsItemUpdateManyWithoutRepInput>;
  SnoozeTracking?: InputMaybe<SnoozeTrackingUpdateManyWithoutRepInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  statusHistory?: InputMaybe<UserStatusHistoryUpdateManyWithoutUserInput>;
  sub_industry_white_list_array?: InputMaybe<UserUpdatesub_Industry_White_List_ArrayInput>;
  sub_industry_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  support_rep?: InputMaybe<BoolFieldUpdateOperationsInput>;
  taking_demos?: InputMaybe<BoolFieldUpdateOperationsInput>;
  team?: InputMaybe<TeamUpdateOneWithoutMembersInput>;
  Template?: InputMaybe<TemplateUpdateManyWithoutLast_Modified_ByInput>;
  TemplateVersion?: InputMaybe<TemplateVersionUpdateManyWithoutCreated_ByInput>;
  timekit_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timekit_type?: InputMaybe<EnumTimekittypeFieldUpdateOperationsInput>;
  timeOffRequest?: InputMaybe<TimeOffRequestUpdateManyWithoutRequesting_UserInput>;
  timezone_raw?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transfer_type?: InputMaybe<EnumTransferTypeFieldUpdateOperationsInput>;
  transfer_user_cycle?: InputMaybe<SaleCycleUpdateManyWithoutTransfer_UserInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user_custom_availability?: InputMaybe<AvailabilityUpdateManyWithoutUserInput>;
  user_past_tokens?: InputMaybe<UserPastTokensUpdateManyWithoutUserInput>;
  visible_all_leads_sm?: InputMaybe<BoolFieldUpdateOperationsInput>;
  visible_call_report_columns?: InputMaybe<UserUpdatevisible_Call_Report_ColumnsInput>;
  visible_call_report_event_columns?: InputMaybe<UserUpdatevisible_Call_Report_Event_ColumnsInput>;
  visible_call_report_pipeline_columns?: InputMaybe<UserUpdatevisible_Call_Report_Pipeline_ColumnsInput>;
  visible_my_leads_columns?: InputMaybe<UserUpdatevisible_My_Leads_ColumnsInput>;
  voicemail_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutAuto_Transfer_Selection_Receive_AttemptsInput = {
  ai_user?: InputMaybe<BoolFieldUpdateOperationsInput>;
  alternate_email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  always_show_calendar_options?: InputMaybe<BoolFieldUpdateOperationsInput>;
  associate_owner_rep_history?: InputMaybe<AssociationHistoryUpdateManyWithoutRepInput>;
  associate_user_history?: InputMaybe<AssociationHistoryUpdateManyWithoutUserInput>;
  auto_transfer_selection_send_attempts?: InputMaybe<AutoTransferSelectionUpdateManyWithoutTo_UserInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptUpdateManyWithoutUserInput>;
  callSharingAsCoach?: InputMaybe<CallSharingUpdateManyWithoutCoachInput>;
  callSharingAsUser?: InputMaybe<CallSharingUpdateManyWithoutReceiver_UserInput>;
  channel?: InputMaybe<NullableEnumChannelfilterFieldUpdateOperationsInput>;
  close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  close_user_cycle?: InputMaybe<SaleCycleUpdateManyWithoutClose_UserInput>;
  close_user_stat?: InputMaybe<StatLeadAggregationUpdateManyWithoutClose_UserInput>;
  commissions?: InputMaybe<CommissionUpdateManyWithoutUserInput>;
  contact_requests?: InputMaybe<ContactRequestUpdateManyWithoutRequesting_UserInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  current_sequence_view?: InputMaybe<SequenceDashboardViewUpdateOneWithoutUserInput>;
  current_team_start_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  custom_field_white_list_array?: InputMaybe<UserCustomFieldFilterUpdateManyWithoutUserInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemUpdateManyWithoutUserInput>;
  daily_lead_claim?: InputMaybe<IntFieldUpdateOperationsInput>;
  dashboard_2_access?: InputMaybe<BoolFieldUpdateOperationsInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewUpdateManyWithoutUserInput>;
  default_audio_input?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  default_audio_output?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  default_availability?: InputMaybe<BoolFieldUpdateOperationsInput>;
  deleted_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  deprecate_old_session?: InputMaybe<BoolFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordUpdateManyWithoutUserInput>;
  favorite_leads?: InputMaybe<LeadUpdateManyWithoutFavorited_By_UsersInput>;
  first_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  folder_permissions?: InputMaybe<FolderPermissionUpdateManyWithoutUserInput>;
  folders?: InputMaybe<FolderUpdateManyWithoutOwnerInput>;
  from_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutOld_UserInput>;
  GlobalRouting?: InputMaybe<GlobalRepRoutingUpdateManyWithoutRepInput>;
  google_refresh_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  holds_per_prospect_vs_held_demo_per_dial?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  hook_subscriptions?: InputMaybe<WebHookSubscriptionUpdateManyWithoutUserInput>;
  hubspot_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  idle_owned_leads?: InputMaybe<LeadUpdateManyWithoutSecondary_RepsInput>;
  industry_filter?: InputMaybe<BoolFieldUpdateOperationsInput>;
  industry_white_list_array?: InputMaybe<UserUpdateindustry_White_List_ArrayInput>;
  industry_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  joinMe_access_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  joinMe_refresh_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  last_demo_transferred_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  last_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutUserInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryUpdateManyWithoutUserInput>;
  lead_intents?: InputMaybe<LeadIntentUpdateManyWithoutUserInput>;
  lead_source_filter?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lead_source_white_list_array?: InputMaybe<UserUpdatelead_Source_White_List_ArrayInput>;
  lead_source_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  leads_last_called?: InputMaybe<LeadUpdateManyWithoutLast_Call_Result_RepInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusUpdateManyWithoutUserInput>;
  manager_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  note_item?: InputMaybe<NoteItemUpdateManyWithoutUserInput>;
  notifications?: InputMaybe<NotificationUpdateManyWithoutUserInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutUsersInput>;
  owned_leads?: InputMaybe<LeadUpdateManyWithoutRepInput>;
  panda_doc_access?: InputMaybe<BoolFieldUpdateOperationsInput>;
  password?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  payment_settings?: InputMaybe<OrganizationPaymentSettingsUpdateManyWithoutCreated_ByInput>;
  payment_settings_updated?: InputMaybe<OrganizationPaymentSettingsUpdateManyWithoutLast_Updated_ByInput>;
  phone_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  post?: InputMaybe<PostUpdateManyWithoutAuthorInput>;
  ProductsCreated?: InputMaybe<ProductChangeLogUpdateManyWithoutUpdated_ByInput>;
  profile_image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  receive_transfer_attempts?: InputMaybe<TransferAttemptUpdateManyWithoutTo_UserInput>;
  receiver_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutReceiver_UserInput>;
  regions_white_list_array?: InputMaybe<UserUpdateregions_White_List_ArrayInput>;
  regions_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  rep_scores?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumRoleFieldUpdateOperationsInput>;
  routings?: InputMaybe<RoutingUpdateManyWithoutRepInput>;
  sale_config?: InputMaybe<SaleConfigUpdateManyWithoutCreated_ByInput>;
  sale_config_updated?: InputMaybe<SaleConfigUpdateManyWithoutLast_Updated_ByInput>;
  sales_team_column_options?: InputMaybe<UserUpdatesales_Team_Column_OptionsInput>;
  schedule_items?: InputMaybe<ScheduleItemUpdateManyWithoutUserInput>;
  send_transfer_attempts?: InputMaybe<TransferAttemptUpdateManyWithoutFrom_UserInput>;
  sender_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutSender_UserInput>;
  sequence_entry_criteria_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SequenceActionLog?: InputMaybe<StepActionLogUpdateManyWithoutRepInput>;
  sequenceDashboardView?: InputMaybe<SequenceDashboardViewUpdateManyWithoutOwned_ByInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutUserInput>;
  Sequences_created?: InputMaybe<SequenceUpdateManyWithoutCreated_ByInput>;
  Sequences_modified_by_user?: InputMaybe<SequenceUpdateManyWithoutLast_Updated_ByInput>;
  session_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  set_user_cycle?: InputMaybe<SaleCycleUpdateManyWithoutSet_UserInput>;
  set_user_stat?: InputMaybe<StatLeadAggregationUpdateManyWithoutSet_UserInput>;
  sf_user_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SmsItems?: InputMaybe<SmsItemUpdateManyWithoutRepInput>;
  SnoozeTracking?: InputMaybe<SnoozeTrackingUpdateManyWithoutRepInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  statusHistory?: InputMaybe<UserStatusHistoryUpdateManyWithoutUserInput>;
  sub_industry_white_list_array?: InputMaybe<UserUpdatesub_Industry_White_List_ArrayInput>;
  sub_industry_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  support_rep?: InputMaybe<BoolFieldUpdateOperationsInput>;
  taking_demos?: InputMaybe<BoolFieldUpdateOperationsInput>;
  team?: InputMaybe<TeamUpdateOneWithoutMembersInput>;
  Template?: InputMaybe<TemplateUpdateManyWithoutLast_Modified_ByInput>;
  TemplateVersion?: InputMaybe<TemplateVersionUpdateManyWithoutCreated_ByInput>;
  timekit_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timekit_type?: InputMaybe<EnumTimekittypeFieldUpdateOperationsInput>;
  timeOffRequest?: InputMaybe<TimeOffRequestUpdateManyWithoutRequesting_UserInput>;
  timezone_raw?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transfer_type?: InputMaybe<EnumTransferTypeFieldUpdateOperationsInput>;
  transfer_user_cycle?: InputMaybe<SaleCycleUpdateManyWithoutTransfer_UserInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user_custom_availability?: InputMaybe<AvailabilityUpdateManyWithoutUserInput>;
  user_past_tokens?: InputMaybe<UserPastTokensUpdateManyWithoutUserInput>;
  visible_all_leads_sm?: InputMaybe<BoolFieldUpdateOperationsInput>;
  visible_call_report_columns?: InputMaybe<UserUpdatevisible_Call_Report_ColumnsInput>;
  visible_call_report_event_columns?: InputMaybe<UserUpdatevisible_Call_Report_Event_ColumnsInput>;
  visible_call_report_pipeline_columns?: InputMaybe<UserUpdatevisible_Call_Report_Pipeline_ColumnsInput>;
  visible_my_leads_columns?: InputMaybe<UserUpdatevisible_My_Leads_ColumnsInput>;
  voicemail_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutAuto_Transfer_Selection_Send_AttemptsInput = {
  ai_user?: InputMaybe<BoolFieldUpdateOperationsInput>;
  alternate_email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  always_show_calendar_options?: InputMaybe<BoolFieldUpdateOperationsInput>;
  associate_owner_rep_history?: InputMaybe<AssociationHistoryUpdateManyWithoutRepInput>;
  associate_user_history?: InputMaybe<AssociationHistoryUpdateManyWithoutUserInput>;
  auto_transfer_selection_receive_attempts?: InputMaybe<AutoTransferSelectionUpdateManyWithoutFrom_UserInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptUpdateManyWithoutUserInput>;
  callSharingAsCoach?: InputMaybe<CallSharingUpdateManyWithoutCoachInput>;
  callSharingAsUser?: InputMaybe<CallSharingUpdateManyWithoutReceiver_UserInput>;
  channel?: InputMaybe<NullableEnumChannelfilterFieldUpdateOperationsInput>;
  close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  close_user_cycle?: InputMaybe<SaleCycleUpdateManyWithoutClose_UserInput>;
  close_user_stat?: InputMaybe<StatLeadAggregationUpdateManyWithoutClose_UserInput>;
  commissions?: InputMaybe<CommissionUpdateManyWithoutUserInput>;
  contact_requests?: InputMaybe<ContactRequestUpdateManyWithoutRequesting_UserInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  current_sequence_view?: InputMaybe<SequenceDashboardViewUpdateOneWithoutUserInput>;
  current_team_start_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  custom_field_white_list_array?: InputMaybe<UserCustomFieldFilterUpdateManyWithoutUserInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemUpdateManyWithoutUserInput>;
  daily_lead_claim?: InputMaybe<IntFieldUpdateOperationsInput>;
  dashboard_2_access?: InputMaybe<BoolFieldUpdateOperationsInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewUpdateManyWithoutUserInput>;
  default_audio_input?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  default_audio_output?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  default_availability?: InputMaybe<BoolFieldUpdateOperationsInput>;
  deleted_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  deprecate_old_session?: InputMaybe<BoolFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordUpdateManyWithoutUserInput>;
  favorite_leads?: InputMaybe<LeadUpdateManyWithoutFavorited_By_UsersInput>;
  first_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  folder_permissions?: InputMaybe<FolderPermissionUpdateManyWithoutUserInput>;
  folders?: InputMaybe<FolderUpdateManyWithoutOwnerInput>;
  from_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutOld_UserInput>;
  GlobalRouting?: InputMaybe<GlobalRepRoutingUpdateManyWithoutRepInput>;
  google_refresh_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  holds_per_prospect_vs_held_demo_per_dial?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  hook_subscriptions?: InputMaybe<WebHookSubscriptionUpdateManyWithoutUserInput>;
  hubspot_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  idle_owned_leads?: InputMaybe<LeadUpdateManyWithoutSecondary_RepsInput>;
  industry_filter?: InputMaybe<BoolFieldUpdateOperationsInput>;
  industry_white_list_array?: InputMaybe<UserUpdateindustry_White_List_ArrayInput>;
  industry_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  joinMe_access_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  joinMe_refresh_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  last_demo_transferred_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  last_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutUserInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryUpdateManyWithoutUserInput>;
  lead_intents?: InputMaybe<LeadIntentUpdateManyWithoutUserInput>;
  lead_source_filter?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lead_source_white_list_array?: InputMaybe<UserUpdatelead_Source_White_List_ArrayInput>;
  lead_source_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  leads_last_called?: InputMaybe<LeadUpdateManyWithoutLast_Call_Result_RepInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusUpdateManyWithoutUserInput>;
  manager_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  note_item?: InputMaybe<NoteItemUpdateManyWithoutUserInput>;
  notifications?: InputMaybe<NotificationUpdateManyWithoutUserInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutUsersInput>;
  owned_leads?: InputMaybe<LeadUpdateManyWithoutRepInput>;
  panda_doc_access?: InputMaybe<BoolFieldUpdateOperationsInput>;
  password?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  payment_settings?: InputMaybe<OrganizationPaymentSettingsUpdateManyWithoutCreated_ByInput>;
  payment_settings_updated?: InputMaybe<OrganizationPaymentSettingsUpdateManyWithoutLast_Updated_ByInput>;
  phone_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  post?: InputMaybe<PostUpdateManyWithoutAuthorInput>;
  ProductsCreated?: InputMaybe<ProductChangeLogUpdateManyWithoutUpdated_ByInput>;
  profile_image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  receive_transfer_attempts?: InputMaybe<TransferAttemptUpdateManyWithoutTo_UserInput>;
  receiver_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutReceiver_UserInput>;
  regions_white_list_array?: InputMaybe<UserUpdateregions_White_List_ArrayInput>;
  regions_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  rep_scores?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumRoleFieldUpdateOperationsInput>;
  routings?: InputMaybe<RoutingUpdateManyWithoutRepInput>;
  sale_config?: InputMaybe<SaleConfigUpdateManyWithoutCreated_ByInput>;
  sale_config_updated?: InputMaybe<SaleConfigUpdateManyWithoutLast_Updated_ByInput>;
  sales_team_column_options?: InputMaybe<UserUpdatesales_Team_Column_OptionsInput>;
  schedule_items?: InputMaybe<ScheduleItemUpdateManyWithoutUserInput>;
  send_transfer_attempts?: InputMaybe<TransferAttemptUpdateManyWithoutFrom_UserInput>;
  sender_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutSender_UserInput>;
  sequence_entry_criteria_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SequenceActionLog?: InputMaybe<StepActionLogUpdateManyWithoutRepInput>;
  sequenceDashboardView?: InputMaybe<SequenceDashboardViewUpdateManyWithoutOwned_ByInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutUserInput>;
  Sequences_created?: InputMaybe<SequenceUpdateManyWithoutCreated_ByInput>;
  Sequences_modified_by_user?: InputMaybe<SequenceUpdateManyWithoutLast_Updated_ByInput>;
  session_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  set_user_cycle?: InputMaybe<SaleCycleUpdateManyWithoutSet_UserInput>;
  set_user_stat?: InputMaybe<StatLeadAggregationUpdateManyWithoutSet_UserInput>;
  sf_user_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SmsItems?: InputMaybe<SmsItemUpdateManyWithoutRepInput>;
  SnoozeTracking?: InputMaybe<SnoozeTrackingUpdateManyWithoutRepInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  statusHistory?: InputMaybe<UserStatusHistoryUpdateManyWithoutUserInput>;
  sub_industry_white_list_array?: InputMaybe<UserUpdatesub_Industry_White_List_ArrayInput>;
  sub_industry_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  support_rep?: InputMaybe<BoolFieldUpdateOperationsInput>;
  taking_demos?: InputMaybe<BoolFieldUpdateOperationsInput>;
  team?: InputMaybe<TeamUpdateOneWithoutMembersInput>;
  Template?: InputMaybe<TemplateUpdateManyWithoutLast_Modified_ByInput>;
  TemplateVersion?: InputMaybe<TemplateVersionUpdateManyWithoutCreated_ByInput>;
  timekit_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timekit_type?: InputMaybe<EnumTimekittypeFieldUpdateOperationsInput>;
  timeOffRequest?: InputMaybe<TimeOffRequestUpdateManyWithoutRequesting_UserInput>;
  timezone_raw?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transfer_type?: InputMaybe<EnumTransferTypeFieldUpdateOperationsInput>;
  transfer_user_cycle?: InputMaybe<SaleCycleUpdateManyWithoutTransfer_UserInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user_custom_availability?: InputMaybe<AvailabilityUpdateManyWithoutUserInput>;
  user_past_tokens?: InputMaybe<UserPastTokensUpdateManyWithoutUserInput>;
  visible_all_leads_sm?: InputMaybe<BoolFieldUpdateOperationsInput>;
  visible_call_report_columns?: InputMaybe<UserUpdatevisible_Call_Report_ColumnsInput>;
  visible_call_report_event_columns?: InputMaybe<UserUpdatevisible_Call_Report_Event_ColumnsInput>;
  visible_call_report_pipeline_columns?: InputMaybe<UserUpdatevisible_Call_Report_Pipeline_ColumnsInput>;
  visible_my_leads_columns?: InputMaybe<UserUpdatevisible_My_Leads_ColumnsInput>;
  voicemail_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutCallMeNowAttemptsInput = {
  ai_user?: InputMaybe<BoolFieldUpdateOperationsInput>;
  alternate_email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  always_show_calendar_options?: InputMaybe<BoolFieldUpdateOperationsInput>;
  associate_owner_rep_history?: InputMaybe<AssociationHistoryUpdateManyWithoutRepInput>;
  associate_user_history?: InputMaybe<AssociationHistoryUpdateManyWithoutUserInput>;
  auto_transfer_selection_receive_attempts?: InputMaybe<AutoTransferSelectionUpdateManyWithoutFrom_UserInput>;
  auto_transfer_selection_send_attempts?: InputMaybe<AutoTransferSelectionUpdateManyWithoutTo_UserInput>;
  callSharingAsCoach?: InputMaybe<CallSharingUpdateManyWithoutCoachInput>;
  callSharingAsUser?: InputMaybe<CallSharingUpdateManyWithoutReceiver_UserInput>;
  channel?: InputMaybe<NullableEnumChannelfilterFieldUpdateOperationsInput>;
  close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  close_user_cycle?: InputMaybe<SaleCycleUpdateManyWithoutClose_UserInput>;
  close_user_stat?: InputMaybe<StatLeadAggregationUpdateManyWithoutClose_UserInput>;
  commissions?: InputMaybe<CommissionUpdateManyWithoutUserInput>;
  contact_requests?: InputMaybe<ContactRequestUpdateManyWithoutRequesting_UserInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  current_sequence_view?: InputMaybe<SequenceDashboardViewUpdateOneWithoutUserInput>;
  current_team_start_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  custom_field_white_list_array?: InputMaybe<UserCustomFieldFilterUpdateManyWithoutUserInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemUpdateManyWithoutUserInput>;
  daily_lead_claim?: InputMaybe<IntFieldUpdateOperationsInput>;
  dashboard_2_access?: InputMaybe<BoolFieldUpdateOperationsInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewUpdateManyWithoutUserInput>;
  default_audio_input?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  default_audio_output?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  default_availability?: InputMaybe<BoolFieldUpdateOperationsInput>;
  deleted_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  deprecate_old_session?: InputMaybe<BoolFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordUpdateManyWithoutUserInput>;
  favorite_leads?: InputMaybe<LeadUpdateManyWithoutFavorited_By_UsersInput>;
  first_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  folder_permissions?: InputMaybe<FolderPermissionUpdateManyWithoutUserInput>;
  folders?: InputMaybe<FolderUpdateManyWithoutOwnerInput>;
  from_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutOld_UserInput>;
  GlobalRouting?: InputMaybe<GlobalRepRoutingUpdateManyWithoutRepInput>;
  google_refresh_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  holds_per_prospect_vs_held_demo_per_dial?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  hook_subscriptions?: InputMaybe<WebHookSubscriptionUpdateManyWithoutUserInput>;
  hubspot_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  idle_owned_leads?: InputMaybe<LeadUpdateManyWithoutSecondary_RepsInput>;
  industry_filter?: InputMaybe<BoolFieldUpdateOperationsInput>;
  industry_white_list_array?: InputMaybe<UserUpdateindustry_White_List_ArrayInput>;
  industry_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  joinMe_access_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  joinMe_refresh_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  last_demo_transferred_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  last_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutUserInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryUpdateManyWithoutUserInput>;
  lead_intents?: InputMaybe<LeadIntentUpdateManyWithoutUserInput>;
  lead_source_filter?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lead_source_white_list_array?: InputMaybe<UserUpdatelead_Source_White_List_ArrayInput>;
  lead_source_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  leads_last_called?: InputMaybe<LeadUpdateManyWithoutLast_Call_Result_RepInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusUpdateManyWithoutUserInput>;
  manager_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  note_item?: InputMaybe<NoteItemUpdateManyWithoutUserInput>;
  notifications?: InputMaybe<NotificationUpdateManyWithoutUserInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutUsersInput>;
  owned_leads?: InputMaybe<LeadUpdateManyWithoutRepInput>;
  panda_doc_access?: InputMaybe<BoolFieldUpdateOperationsInput>;
  password?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  payment_settings?: InputMaybe<OrganizationPaymentSettingsUpdateManyWithoutCreated_ByInput>;
  payment_settings_updated?: InputMaybe<OrganizationPaymentSettingsUpdateManyWithoutLast_Updated_ByInput>;
  phone_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  post?: InputMaybe<PostUpdateManyWithoutAuthorInput>;
  ProductsCreated?: InputMaybe<ProductChangeLogUpdateManyWithoutUpdated_ByInput>;
  profile_image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  receive_transfer_attempts?: InputMaybe<TransferAttemptUpdateManyWithoutTo_UserInput>;
  receiver_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutReceiver_UserInput>;
  regions_white_list_array?: InputMaybe<UserUpdateregions_White_List_ArrayInput>;
  regions_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  rep_scores?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumRoleFieldUpdateOperationsInput>;
  routings?: InputMaybe<RoutingUpdateManyWithoutRepInput>;
  sale_config?: InputMaybe<SaleConfigUpdateManyWithoutCreated_ByInput>;
  sale_config_updated?: InputMaybe<SaleConfigUpdateManyWithoutLast_Updated_ByInput>;
  sales_team_column_options?: InputMaybe<UserUpdatesales_Team_Column_OptionsInput>;
  schedule_items?: InputMaybe<ScheduleItemUpdateManyWithoutUserInput>;
  send_transfer_attempts?: InputMaybe<TransferAttemptUpdateManyWithoutFrom_UserInput>;
  sender_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutSender_UserInput>;
  sequence_entry_criteria_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SequenceActionLog?: InputMaybe<StepActionLogUpdateManyWithoutRepInput>;
  sequenceDashboardView?: InputMaybe<SequenceDashboardViewUpdateManyWithoutOwned_ByInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutUserInput>;
  Sequences_created?: InputMaybe<SequenceUpdateManyWithoutCreated_ByInput>;
  Sequences_modified_by_user?: InputMaybe<SequenceUpdateManyWithoutLast_Updated_ByInput>;
  session_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  set_user_cycle?: InputMaybe<SaleCycleUpdateManyWithoutSet_UserInput>;
  set_user_stat?: InputMaybe<StatLeadAggregationUpdateManyWithoutSet_UserInput>;
  sf_user_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SmsItems?: InputMaybe<SmsItemUpdateManyWithoutRepInput>;
  SnoozeTracking?: InputMaybe<SnoozeTrackingUpdateManyWithoutRepInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  statusHistory?: InputMaybe<UserStatusHistoryUpdateManyWithoutUserInput>;
  sub_industry_white_list_array?: InputMaybe<UserUpdatesub_Industry_White_List_ArrayInput>;
  sub_industry_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  support_rep?: InputMaybe<BoolFieldUpdateOperationsInput>;
  taking_demos?: InputMaybe<BoolFieldUpdateOperationsInput>;
  team?: InputMaybe<TeamUpdateOneWithoutMembersInput>;
  Template?: InputMaybe<TemplateUpdateManyWithoutLast_Modified_ByInput>;
  TemplateVersion?: InputMaybe<TemplateVersionUpdateManyWithoutCreated_ByInput>;
  timekit_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timekit_type?: InputMaybe<EnumTimekittypeFieldUpdateOperationsInput>;
  timeOffRequest?: InputMaybe<TimeOffRequestUpdateManyWithoutRequesting_UserInput>;
  timezone_raw?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transfer_type?: InputMaybe<EnumTransferTypeFieldUpdateOperationsInput>;
  transfer_user_cycle?: InputMaybe<SaleCycleUpdateManyWithoutTransfer_UserInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user_custom_availability?: InputMaybe<AvailabilityUpdateManyWithoutUserInput>;
  user_past_tokens?: InputMaybe<UserPastTokensUpdateManyWithoutUserInput>;
  visible_all_leads_sm?: InputMaybe<BoolFieldUpdateOperationsInput>;
  visible_call_report_columns?: InputMaybe<UserUpdatevisible_Call_Report_ColumnsInput>;
  visible_call_report_event_columns?: InputMaybe<UserUpdatevisible_Call_Report_Event_ColumnsInput>;
  visible_call_report_pipeline_columns?: InputMaybe<UserUpdatevisible_Call_Report_Pipeline_ColumnsInput>;
  visible_my_leads_columns?: InputMaybe<UserUpdatevisible_My_Leads_ColumnsInput>;
  voicemail_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutCallSharingAsCoachInput = {
  ai_user?: InputMaybe<BoolFieldUpdateOperationsInput>;
  alternate_email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  always_show_calendar_options?: InputMaybe<BoolFieldUpdateOperationsInput>;
  associate_owner_rep_history?: InputMaybe<AssociationHistoryUpdateManyWithoutRepInput>;
  associate_user_history?: InputMaybe<AssociationHistoryUpdateManyWithoutUserInput>;
  auto_transfer_selection_receive_attempts?: InputMaybe<AutoTransferSelectionUpdateManyWithoutFrom_UserInput>;
  auto_transfer_selection_send_attempts?: InputMaybe<AutoTransferSelectionUpdateManyWithoutTo_UserInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptUpdateManyWithoutUserInput>;
  callSharingAsUser?: InputMaybe<CallSharingUpdateManyWithoutReceiver_UserInput>;
  channel?: InputMaybe<NullableEnumChannelfilterFieldUpdateOperationsInput>;
  close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  close_user_cycle?: InputMaybe<SaleCycleUpdateManyWithoutClose_UserInput>;
  close_user_stat?: InputMaybe<StatLeadAggregationUpdateManyWithoutClose_UserInput>;
  commissions?: InputMaybe<CommissionUpdateManyWithoutUserInput>;
  contact_requests?: InputMaybe<ContactRequestUpdateManyWithoutRequesting_UserInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  current_sequence_view?: InputMaybe<SequenceDashboardViewUpdateOneWithoutUserInput>;
  current_team_start_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  custom_field_white_list_array?: InputMaybe<UserCustomFieldFilterUpdateManyWithoutUserInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemUpdateManyWithoutUserInput>;
  daily_lead_claim?: InputMaybe<IntFieldUpdateOperationsInput>;
  dashboard_2_access?: InputMaybe<BoolFieldUpdateOperationsInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewUpdateManyWithoutUserInput>;
  default_audio_input?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  default_audio_output?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  default_availability?: InputMaybe<BoolFieldUpdateOperationsInput>;
  deleted_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  deprecate_old_session?: InputMaybe<BoolFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordUpdateManyWithoutUserInput>;
  favorite_leads?: InputMaybe<LeadUpdateManyWithoutFavorited_By_UsersInput>;
  first_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  folder_permissions?: InputMaybe<FolderPermissionUpdateManyWithoutUserInput>;
  folders?: InputMaybe<FolderUpdateManyWithoutOwnerInput>;
  from_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutOld_UserInput>;
  GlobalRouting?: InputMaybe<GlobalRepRoutingUpdateManyWithoutRepInput>;
  google_refresh_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  holds_per_prospect_vs_held_demo_per_dial?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  hook_subscriptions?: InputMaybe<WebHookSubscriptionUpdateManyWithoutUserInput>;
  hubspot_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  idle_owned_leads?: InputMaybe<LeadUpdateManyWithoutSecondary_RepsInput>;
  industry_filter?: InputMaybe<BoolFieldUpdateOperationsInput>;
  industry_white_list_array?: InputMaybe<UserUpdateindustry_White_List_ArrayInput>;
  industry_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  joinMe_access_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  joinMe_refresh_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  last_demo_transferred_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  last_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutUserInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryUpdateManyWithoutUserInput>;
  lead_intents?: InputMaybe<LeadIntentUpdateManyWithoutUserInput>;
  lead_source_filter?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lead_source_white_list_array?: InputMaybe<UserUpdatelead_Source_White_List_ArrayInput>;
  lead_source_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  leads_last_called?: InputMaybe<LeadUpdateManyWithoutLast_Call_Result_RepInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusUpdateManyWithoutUserInput>;
  manager_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  note_item?: InputMaybe<NoteItemUpdateManyWithoutUserInput>;
  notifications?: InputMaybe<NotificationUpdateManyWithoutUserInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutUsersInput>;
  owned_leads?: InputMaybe<LeadUpdateManyWithoutRepInput>;
  panda_doc_access?: InputMaybe<BoolFieldUpdateOperationsInput>;
  password?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  payment_settings?: InputMaybe<OrganizationPaymentSettingsUpdateManyWithoutCreated_ByInput>;
  payment_settings_updated?: InputMaybe<OrganizationPaymentSettingsUpdateManyWithoutLast_Updated_ByInput>;
  phone_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  post?: InputMaybe<PostUpdateManyWithoutAuthorInput>;
  ProductsCreated?: InputMaybe<ProductChangeLogUpdateManyWithoutUpdated_ByInput>;
  profile_image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  receive_transfer_attempts?: InputMaybe<TransferAttemptUpdateManyWithoutTo_UserInput>;
  receiver_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutReceiver_UserInput>;
  regions_white_list_array?: InputMaybe<UserUpdateregions_White_List_ArrayInput>;
  regions_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  rep_scores?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumRoleFieldUpdateOperationsInput>;
  routings?: InputMaybe<RoutingUpdateManyWithoutRepInput>;
  sale_config?: InputMaybe<SaleConfigUpdateManyWithoutCreated_ByInput>;
  sale_config_updated?: InputMaybe<SaleConfigUpdateManyWithoutLast_Updated_ByInput>;
  sales_team_column_options?: InputMaybe<UserUpdatesales_Team_Column_OptionsInput>;
  schedule_items?: InputMaybe<ScheduleItemUpdateManyWithoutUserInput>;
  send_transfer_attempts?: InputMaybe<TransferAttemptUpdateManyWithoutFrom_UserInput>;
  sender_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutSender_UserInput>;
  sequence_entry_criteria_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SequenceActionLog?: InputMaybe<StepActionLogUpdateManyWithoutRepInput>;
  sequenceDashboardView?: InputMaybe<SequenceDashboardViewUpdateManyWithoutOwned_ByInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutUserInput>;
  Sequences_created?: InputMaybe<SequenceUpdateManyWithoutCreated_ByInput>;
  Sequences_modified_by_user?: InputMaybe<SequenceUpdateManyWithoutLast_Updated_ByInput>;
  session_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  set_user_cycle?: InputMaybe<SaleCycleUpdateManyWithoutSet_UserInput>;
  set_user_stat?: InputMaybe<StatLeadAggregationUpdateManyWithoutSet_UserInput>;
  sf_user_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SmsItems?: InputMaybe<SmsItemUpdateManyWithoutRepInput>;
  SnoozeTracking?: InputMaybe<SnoozeTrackingUpdateManyWithoutRepInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  statusHistory?: InputMaybe<UserStatusHistoryUpdateManyWithoutUserInput>;
  sub_industry_white_list_array?: InputMaybe<UserUpdatesub_Industry_White_List_ArrayInput>;
  sub_industry_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  support_rep?: InputMaybe<BoolFieldUpdateOperationsInput>;
  taking_demos?: InputMaybe<BoolFieldUpdateOperationsInput>;
  team?: InputMaybe<TeamUpdateOneWithoutMembersInput>;
  Template?: InputMaybe<TemplateUpdateManyWithoutLast_Modified_ByInput>;
  TemplateVersion?: InputMaybe<TemplateVersionUpdateManyWithoutCreated_ByInput>;
  timekit_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timekit_type?: InputMaybe<EnumTimekittypeFieldUpdateOperationsInput>;
  timeOffRequest?: InputMaybe<TimeOffRequestUpdateManyWithoutRequesting_UserInput>;
  timezone_raw?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transfer_type?: InputMaybe<EnumTransferTypeFieldUpdateOperationsInput>;
  transfer_user_cycle?: InputMaybe<SaleCycleUpdateManyWithoutTransfer_UserInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user_custom_availability?: InputMaybe<AvailabilityUpdateManyWithoutUserInput>;
  user_past_tokens?: InputMaybe<UserPastTokensUpdateManyWithoutUserInput>;
  visible_all_leads_sm?: InputMaybe<BoolFieldUpdateOperationsInput>;
  visible_call_report_columns?: InputMaybe<UserUpdatevisible_Call_Report_ColumnsInput>;
  visible_call_report_event_columns?: InputMaybe<UserUpdatevisible_Call_Report_Event_ColumnsInput>;
  visible_call_report_pipeline_columns?: InputMaybe<UserUpdatevisible_Call_Report_Pipeline_ColumnsInput>;
  visible_my_leads_columns?: InputMaybe<UserUpdatevisible_My_Leads_ColumnsInput>;
  voicemail_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutCallSharingAsUserInput = {
  ai_user?: InputMaybe<BoolFieldUpdateOperationsInput>;
  alternate_email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  always_show_calendar_options?: InputMaybe<BoolFieldUpdateOperationsInput>;
  associate_owner_rep_history?: InputMaybe<AssociationHistoryUpdateManyWithoutRepInput>;
  associate_user_history?: InputMaybe<AssociationHistoryUpdateManyWithoutUserInput>;
  auto_transfer_selection_receive_attempts?: InputMaybe<AutoTransferSelectionUpdateManyWithoutFrom_UserInput>;
  auto_transfer_selection_send_attempts?: InputMaybe<AutoTransferSelectionUpdateManyWithoutTo_UserInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptUpdateManyWithoutUserInput>;
  callSharingAsCoach?: InputMaybe<CallSharingUpdateManyWithoutCoachInput>;
  channel?: InputMaybe<NullableEnumChannelfilterFieldUpdateOperationsInput>;
  close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  close_user_cycle?: InputMaybe<SaleCycleUpdateManyWithoutClose_UserInput>;
  close_user_stat?: InputMaybe<StatLeadAggregationUpdateManyWithoutClose_UserInput>;
  commissions?: InputMaybe<CommissionUpdateManyWithoutUserInput>;
  contact_requests?: InputMaybe<ContactRequestUpdateManyWithoutRequesting_UserInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  current_sequence_view?: InputMaybe<SequenceDashboardViewUpdateOneWithoutUserInput>;
  current_team_start_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  custom_field_white_list_array?: InputMaybe<UserCustomFieldFilterUpdateManyWithoutUserInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemUpdateManyWithoutUserInput>;
  daily_lead_claim?: InputMaybe<IntFieldUpdateOperationsInput>;
  dashboard_2_access?: InputMaybe<BoolFieldUpdateOperationsInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewUpdateManyWithoutUserInput>;
  default_audio_input?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  default_audio_output?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  default_availability?: InputMaybe<BoolFieldUpdateOperationsInput>;
  deleted_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  deprecate_old_session?: InputMaybe<BoolFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordUpdateManyWithoutUserInput>;
  favorite_leads?: InputMaybe<LeadUpdateManyWithoutFavorited_By_UsersInput>;
  first_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  folder_permissions?: InputMaybe<FolderPermissionUpdateManyWithoutUserInput>;
  folders?: InputMaybe<FolderUpdateManyWithoutOwnerInput>;
  from_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutOld_UserInput>;
  GlobalRouting?: InputMaybe<GlobalRepRoutingUpdateManyWithoutRepInput>;
  google_refresh_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  holds_per_prospect_vs_held_demo_per_dial?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  hook_subscriptions?: InputMaybe<WebHookSubscriptionUpdateManyWithoutUserInput>;
  hubspot_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  idle_owned_leads?: InputMaybe<LeadUpdateManyWithoutSecondary_RepsInput>;
  industry_filter?: InputMaybe<BoolFieldUpdateOperationsInput>;
  industry_white_list_array?: InputMaybe<UserUpdateindustry_White_List_ArrayInput>;
  industry_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  joinMe_access_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  joinMe_refresh_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  last_demo_transferred_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  last_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutUserInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryUpdateManyWithoutUserInput>;
  lead_intents?: InputMaybe<LeadIntentUpdateManyWithoutUserInput>;
  lead_source_filter?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lead_source_white_list_array?: InputMaybe<UserUpdatelead_Source_White_List_ArrayInput>;
  lead_source_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  leads_last_called?: InputMaybe<LeadUpdateManyWithoutLast_Call_Result_RepInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusUpdateManyWithoutUserInput>;
  manager_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  note_item?: InputMaybe<NoteItemUpdateManyWithoutUserInput>;
  notifications?: InputMaybe<NotificationUpdateManyWithoutUserInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutUsersInput>;
  owned_leads?: InputMaybe<LeadUpdateManyWithoutRepInput>;
  panda_doc_access?: InputMaybe<BoolFieldUpdateOperationsInput>;
  password?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  payment_settings?: InputMaybe<OrganizationPaymentSettingsUpdateManyWithoutCreated_ByInput>;
  payment_settings_updated?: InputMaybe<OrganizationPaymentSettingsUpdateManyWithoutLast_Updated_ByInput>;
  phone_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  post?: InputMaybe<PostUpdateManyWithoutAuthorInput>;
  ProductsCreated?: InputMaybe<ProductChangeLogUpdateManyWithoutUpdated_ByInput>;
  profile_image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  receive_transfer_attempts?: InputMaybe<TransferAttemptUpdateManyWithoutTo_UserInput>;
  receiver_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutReceiver_UserInput>;
  regions_white_list_array?: InputMaybe<UserUpdateregions_White_List_ArrayInput>;
  regions_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  rep_scores?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumRoleFieldUpdateOperationsInput>;
  routings?: InputMaybe<RoutingUpdateManyWithoutRepInput>;
  sale_config?: InputMaybe<SaleConfigUpdateManyWithoutCreated_ByInput>;
  sale_config_updated?: InputMaybe<SaleConfigUpdateManyWithoutLast_Updated_ByInput>;
  sales_team_column_options?: InputMaybe<UserUpdatesales_Team_Column_OptionsInput>;
  schedule_items?: InputMaybe<ScheduleItemUpdateManyWithoutUserInput>;
  send_transfer_attempts?: InputMaybe<TransferAttemptUpdateManyWithoutFrom_UserInput>;
  sender_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutSender_UserInput>;
  sequence_entry_criteria_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SequenceActionLog?: InputMaybe<StepActionLogUpdateManyWithoutRepInput>;
  sequenceDashboardView?: InputMaybe<SequenceDashboardViewUpdateManyWithoutOwned_ByInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutUserInput>;
  Sequences_created?: InputMaybe<SequenceUpdateManyWithoutCreated_ByInput>;
  Sequences_modified_by_user?: InputMaybe<SequenceUpdateManyWithoutLast_Updated_ByInput>;
  session_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  set_user_cycle?: InputMaybe<SaleCycleUpdateManyWithoutSet_UserInput>;
  set_user_stat?: InputMaybe<StatLeadAggregationUpdateManyWithoutSet_UserInput>;
  sf_user_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SmsItems?: InputMaybe<SmsItemUpdateManyWithoutRepInput>;
  SnoozeTracking?: InputMaybe<SnoozeTrackingUpdateManyWithoutRepInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  statusHistory?: InputMaybe<UserStatusHistoryUpdateManyWithoutUserInput>;
  sub_industry_white_list_array?: InputMaybe<UserUpdatesub_Industry_White_List_ArrayInput>;
  sub_industry_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  support_rep?: InputMaybe<BoolFieldUpdateOperationsInput>;
  taking_demos?: InputMaybe<BoolFieldUpdateOperationsInput>;
  team?: InputMaybe<TeamUpdateOneWithoutMembersInput>;
  Template?: InputMaybe<TemplateUpdateManyWithoutLast_Modified_ByInput>;
  TemplateVersion?: InputMaybe<TemplateVersionUpdateManyWithoutCreated_ByInput>;
  timekit_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timekit_type?: InputMaybe<EnumTimekittypeFieldUpdateOperationsInput>;
  timeOffRequest?: InputMaybe<TimeOffRequestUpdateManyWithoutRequesting_UserInput>;
  timezone_raw?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transfer_type?: InputMaybe<EnumTransferTypeFieldUpdateOperationsInput>;
  transfer_user_cycle?: InputMaybe<SaleCycleUpdateManyWithoutTransfer_UserInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user_custom_availability?: InputMaybe<AvailabilityUpdateManyWithoutUserInput>;
  user_past_tokens?: InputMaybe<UserPastTokensUpdateManyWithoutUserInput>;
  visible_all_leads_sm?: InputMaybe<BoolFieldUpdateOperationsInput>;
  visible_call_report_columns?: InputMaybe<UserUpdatevisible_Call_Report_ColumnsInput>;
  visible_call_report_event_columns?: InputMaybe<UserUpdatevisible_Call_Report_Event_ColumnsInput>;
  visible_call_report_pipeline_columns?: InputMaybe<UserUpdatevisible_Call_Report_Pipeline_ColumnsInput>;
  visible_my_leads_columns?: InputMaybe<UserUpdatevisible_My_Leads_ColumnsInput>;
  voicemail_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutClose_User_CycleInput = {
  ai_user?: InputMaybe<BoolFieldUpdateOperationsInput>;
  alternate_email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  always_show_calendar_options?: InputMaybe<BoolFieldUpdateOperationsInput>;
  associate_owner_rep_history?: InputMaybe<AssociationHistoryUpdateManyWithoutRepInput>;
  associate_user_history?: InputMaybe<AssociationHistoryUpdateManyWithoutUserInput>;
  auto_transfer_selection_receive_attempts?: InputMaybe<AutoTransferSelectionUpdateManyWithoutFrom_UserInput>;
  auto_transfer_selection_send_attempts?: InputMaybe<AutoTransferSelectionUpdateManyWithoutTo_UserInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptUpdateManyWithoutUserInput>;
  callSharingAsCoach?: InputMaybe<CallSharingUpdateManyWithoutCoachInput>;
  callSharingAsUser?: InputMaybe<CallSharingUpdateManyWithoutReceiver_UserInput>;
  channel?: InputMaybe<NullableEnumChannelfilterFieldUpdateOperationsInput>;
  close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  close_user_stat?: InputMaybe<StatLeadAggregationUpdateManyWithoutClose_UserInput>;
  commissions?: InputMaybe<CommissionUpdateManyWithoutUserInput>;
  contact_requests?: InputMaybe<ContactRequestUpdateManyWithoutRequesting_UserInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  current_sequence_view?: InputMaybe<SequenceDashboardViewUpdateOneWithoutUserInput>;
  current_team_start_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  custom_field_white_list_array?: InputMaybe<UserCustomFieldFilterUpdateManyWithoutUserInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemUpdateManyWithoutUserInput>;
  daily_lead_claim?: InputMaybe<IntFieldUpdateOperationsInput>;
  dashboard_2_access?: InputMaybe<BoolFieldUpdateOperationsInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewUpdateManyWithoutUserInput>;
  default_audio_input?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  default_audio_output?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  default_availability?: InputMaybe<BoolFieldUpdateOperationsInput>;
  deleted_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  deprecate_old_session?: InputMaybe<BoolFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordUpdateManyWithoutUserInput>;
  favorite_leads?: InputMaybe<LeadUpdateManyWithoutFavorited_By_UsersInput>;
  first_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  folder_permissions?: InputMaybe<FolderPermissionUpdateManyWithoutUserInput>;
  folders?: InputMaybe<FolderUpdateManyWithoutOwnerInput>;
  from_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutOld_UserInput>;
  GlobalRouting?: InputMaybe<GlobalRepRoutingUpdateManyWithoutRepInput>;
  google_refresh_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  holds_per_prospect_vs_held_demo_per_dial?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  hook_subscriptions?: InputMaybe<WebHookSubscriptionUpdateManyWithoutUserInput>;
  hubspot_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  idle_owned_leads?: InputMaybe<LeadUpdateManyWithoutSecondary_RepsInput>;
  industry_filter?: InputMaybe<BoolFieldUpdateOperationsInput>;
  industry_white_list_array?: InputMaybe<UserUpdateindustry_White_List_ArrayInput>;
  industry_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  joinMe_access_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  joinMe_refresh_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  last_demo_transferred_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  last_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutUserInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryUpdateManyWithoutUserInput>;
  lead_intents?: InputMaybe<LeadIntentUpdateManyWithoutUserInput>;
  lead_source_filter?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lead_source_white_list_array?: InputMaybe<UserUpdatelead_Source_White_List_ArrayInput>;
  lead_source_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  leads_last_called?: InputMaybe<LeadUpdateManyWithoutLast_Call_Result_RepInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusUpdateManyWithoutUserInput>;
  manager_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  note_item?: InputMaybe<NoteItemUpdateManyWithoutUserInput>;
  notifications?: InputMaybe<NotificationUpdateManyWithoutUserInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutUsersInput>;
  owned_leads?: InputMaybe<LeadUpdateManyWithoutRepInput>;
  panda_doc_access?: InputMaybe<BoolFieldUpdateOperationsInput>;
  password?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  payment_settings?: InputMaybe<OrganizationPaymentSettingsUpdateManyWithoutCreated_ByInput>;
  payment_settings_updated?: InputMaybe<OrganizationPaymentSettingsUpdateManyWithoutLast_Updated_ByInput>;
  phone_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  post?: InputMaybe<PostUpdateManyWithoutAuthorInput>;
  ProductsCreated?: InputMaybe<ProductChangeLogUpdateManyWithoutUpdated_ByInput>;
  profile_image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  receive_transfer_attempts?: InputMaybe<TransferAttemptUpdateManyWithoutTo_UserInput>;
  receiver_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutReceiver_UserInput>;
  regions_white_list_array?: InputMaybe<UserUpdateregions_White_List_ArrayInput>;
  regions_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  rep_scores?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumRoleFieldUpdateOperationsInput>;
  routings?: InputMaybe<RoutingUpdateManyWithoutRepInput>;
  sale_config?: InputMaybe<SaleConfigUpdateManyWithoutCreated_ByInput>;
  sale_config_updated?: InputMaybe<SaleConfigUpdateManyWithoutLast_Updated_ByInput>;
  sales_team_column_options?: InputMaybe<UserUpdatesales_Team_Column_OptionsInput>;
  schedule_items?: InputMaybe<ScheduleItemUpdateManyWithoutUserInput>;
  send_transfer_attempts?: InputMaybe<TransferAttemptUpdateManyWithoutFrom_UserInput>;
  sender_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutSender_UserInput>;
  sequence_entry_criteria_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SequenceActionLog?: InputMaybe<StepActionLogUpdateManyWithoutRepInput>;
  sequenceDashboardView?: InputMaybe<SequenceDashboardViewUpdateManyWithoutOwned_ByInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutUserInput>;
  Sequences_created?: InputMaybe<SequenceUpdateManyWithoutCreated_ByInput>;
  Sequences_modified_by_user?: InputMaybe<SequenceUpdateManyWithoutLast_Updated_ByInput>;
  session_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  set_user_cycle?: InputMaybe<SaleCycleUpdateManyWithoutSet_UserInput>;
  set_user_stat?: InputMaybe<StatLeadAggregationUpdateManyWithoutSet_UserInput>;
  sf_user_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SmsItems?: InputMaybe<SmsItemUpdateManyWithoutRepInput>;
  SnoozeTracking?: InputMaybe<SnoozeTrackingUpdateManyWithoutRepInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  statusHistory?: InputMaybe<UserStatusHistoryUpdateManyWithoutUserInput>;
  sub_industry_white_list_array?: InputMaybe<UserUpdatesub_Industry_White_List_ArrayInput>;
  sub_industry_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  support_rep?: InputMaybe<BoolFieldUpdateOperationsInput>;
  taking_demos?: InputMaybe<BoolFieldUpdateOperationsInput>;
  team?: InputMaybe<TeamUpdateOneWithoutMembersInput>;
  Template?: InputMaybe<TemplateUpdateManyWithoutLast_Modified_ByInput>;
  TemplateVersion?: InputMaybe<TemplateVersionUpdateManyWithoutCreated_ByInput>;
  timekit_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timekit_type?: InputMaybe<EnumTimekittypeFieldUpdateOperationsInput>;
  timeOffRequest?: InputMaybe<TimeOffRequestUpdateManyWithoutRequesting_UserInput>;
  timezone_raw?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transfer_type?: InputMaybe<EnumTransferTypeFieldUpdateOperationsInput>;
  transfer_user_cycle?: InputMaybe<SaleCycleUpdateManyWithoutTransfer_UserInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user_custom_availability?: InputMaybe<AvailabilityUpdateManyWithoutUserInput>;
  user_past_tokens?: InputMaybe<UserPastTokensUpdateManyWithoutUserInput>;
  visible_all_leads_sm?: InputMaybe<BoolFieldUpdateOperationsInput>;
  visible_call_report_columns?: InputMaybe<UserUpdatevisible_Call_Report_ColumnsInput>;
  visible_call_report_event_columns?: InputMaybe<UserUpdatevisible_Call_Report_Event_ColumnsInput>;
  visible_call_report_pipeline_columns?: InputMaybe<UserUpdatevisible_Call_Report_Pipeline_ColumnsInput>;
  visible_my_leads_columns?: InputMaybe<UserUpdatevisible_My_Leads_ColumnsInput>;
  voicemail_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutClose_User_StatInput = {
  ai_user?: InputMaybe<BoolFieldUpdateOperationsInput>;
  alternate_email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  always_show_calendar_options?: InputMaybe<BoolFieldUpdateOperationsInput>;
  associate_owner_rep_history?: InputMaybe<AssociationHistoryUpdateManyWithoutRepInput>;
  associate_user_history?: InputMaybe<AssociationHistoryUpdateManyWithoutUserInput>;
  auto_transfer_selection_receive_attempts?: InputMaybe<AutoTransferSelectionUpdateManyWithoutFrom_UserInput>;
  auto_transfer_selection_send_attempts?: InputMaybe<AutoTransferSelectionUpdateManyWithoutTo_UserInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptUpdateManyWithoutUserInput>;
  callSharingAsCoach?: InputMaybe<CallSharingUpdateManyWithoutCoachInput>;
  callSharingAsUser?: InputMaybe<CallSharingUpdateManyWithoutReceiver_UserInput>;
  channel?: InputMaybe<NullableEnumChannelfilterFieldUpdateOperationsInput>;
  close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  close_user_cycle?: InputMaybe<SaleCycleUpdateManyWithoutClose_UserInput>;
  commissions?: InputMaybe<CommissionUpdateManyWithoutUserInput>;
  contact_requests?: InputMaybe<ContactRequestUpdateManyWithoutRequesting_UserInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  current_sequence_view?: InputMaybe<SequenceDashboardViewUpdateOneWithoutUserInput>;
  current_team_start_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  custom_field_white_list_array?: InputMaybe<UserCustomFieldFilterUpdateManyWithoutUserInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemUpdateManyWithoutUserInput>;
  daily_lead_claim?: InputMaybe<IntFieldUpdateOperationsInput>;
  dashboard_2_access?: InputMaybe<BoolFieldUpdateOperationsInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewUpdateManyWithoutUserInput>;
  default_audio_input?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  default_audio_output?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  default_availability?: InputMaybe<BoolFieldUpdateOperationsInput>;
  deleted_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  deprecate_old_session?: InputMaybe<BoolFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordUpdateManyWithoutUserInput>;
  favorite_leads?: InputMaybe<LeadUpdateManyWithoutFavorited_By_UsersInput>;
  first_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  folder_permissions?: InputMaybe<FolderPermissionUpdateManyWithoutUserInput>;
  folders?: InputMaybe<FolderUpdateManyWithoutOwnerInput>;
  from_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutOld_UserInput>;
  GlobalRouting?: InputMaybe<GlobalRepRoutingUpdateManyWithoutRepInput>;
  google_refresh_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  holds_per_prospect_vs_held_demo_per_dial?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  hook_subscriptions?: InputMaybe<WebHookSubscriptionUpdateManyWithoutUserInput>;
  hubspot_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  idle_owned_leads?: InputMaybe<LeadUpdateManyWithoutSecondary_RepsInput>;
  industry_filter?: InputMaybe<BoolFieldUpdateOperationsInput>;
  industry_white_list_array?: InputMaybe<UserUpdateindustry_White_List_ArrayInput>;
  industry_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  joinMe_access_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  joinMe_refresh_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  last_demo_transferred_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  last_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutUserInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryUpdateManyWithoutUserInput>;
  lead_intents?: InputMaybe<LeadIntentUpdateManyWithoutUserInput>;
  lead_source_filter?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lead_source_white_list_array?: InputMaybe<UserUpdatelead_Source_White_List_ArrayInput>;
  lead_source_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  leads_last_called?: InputMaybe<LeadUpdateManyWithoutLast_Call_Result_RepInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusUpdateManyWithoutUserInput>;
  manager_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  note_item?: InputMaybe<NoteItemUpdateManyWithoutUserInput>;
  notifications?: InputMaybe<NotificationUpdateManyWithoutUserInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutUsersInput>;
  owned_leads?: InputMaybe<LeadUpdateManyWithoutRepInput>;
  panda_doc_access?: InputMaybe<BoolFieldUpdateOperationsInput>;
  password?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  payment_settings?: InputMaybe<OrganizationPaymentSettingsUpdateManyWithoutCreated_ByInput>;
  payment_settings_updated?: InputMaybe<OrganizationPaymentSettingsUpdateManyWithoutLast_Updated_ByInput>;
  phone_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  post?: InputMaybe<PostUpdateManyWithoutAuthorInput>;
  ProductsCreated?: InputMaybe<ProductChangeLogUpdateManyWithoutUpdated_ByInput>;
  profile_image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  receive_transfer_attempts?: InputMaybe<TransferAttemptUpdateManyWithoutTo_UserInput>;
  receiver_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutReceiver_UserInput>;
  regions_white_list_array?: InputMaybe<UserUpdateregions_White_List_ArrayInput>;
  regions_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  rep_scores?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumRoleFieldUpdateOperationsInput>;
  routings?: InputMaybe<RoutingUpdateManyWithoutRepInput>;
  sale_config?: InputMaybe<SaleConfigUpdateManyWithoutCreated_ByInput>;
  sale_config_updated?: InputMaybe<SaleConfigUpdateManyWithoutLast_Updated_ByInput>;
  sales_team_column_options?: InputMaybe<UserUpdatesales_Team_Column_OptionsInput>;
  schedule_items?: InputMaybe<ScheduleItemUpdateManyWithoutUserInput>;
  send_transfer_attempts?: InputMaybe<TransferAttemptUpdateManyWithoutFrom_UserInput>;
  sender_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutSender_UserInput>;
  sequence_entry_criteria_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SequenceActionLog?: InputMaybe<StepActionLogUpdateManyWithoutRepInput>;
  sequenceDashboardView?: InputMaybe<SequenceDashboardViewUpdateManyWithoutOwned_ByInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutUserInput>;
  Sequences_created?: InputMaybe<SequenceUpdateManyWithoutCreated_ByInput>;
  Sequences_modified_by_user?: InputMaybe<SequenceUpdateManyWithoutLast_Updated_ByInput>;
  session_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  set_user_cycle?: InputMaybe<SaleCycleUpdateManyWithoutSet_UserInput>;
  set_user_stat?: InputMaybe<StatLeadAggregationUpdateManyWithoutSet_UserInput>;
  sf_user_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SmsItems?: InputMaybe<SmsItemUpdateManyWithoutRepInput>;
  SnoozeTracking?: InputMaybe<SnoozeTrackingUpdateManyWithoutRepInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  statusHistory?: InputMaybe<UserStatusHistoryUpdateManyWithoutUserInput>;
  sub_industry_white_list_array?: InputMaybe<UserUpdatesub_Industry_White_List_ArrayInput>;
  sub_industry_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  support_rep?: InputMaybe<BoolFieldUpdateOperationsInput>;
  taking_demos?: InputMaybe<BoolFieldUpdateOperationsInput>;
  team?: InputMaybe<TeamUpdateOneWithoutMembersInput>;
  Template?: InputMaybe<TemplateUpdateManyWithoutLast_Modified_ByInput>;
  TemplateVersion?: InputMaybe<TemplateVersionUpdateManyWithoutCreated_ByInput>;
  timekit_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timekit_type?: InputMaybe<EnumTimekittypeFieldUpdateOperationsInput>;
  timeOffRequest?: InputMaybe<TimeOffRequestUpdateManyWithoutRequesting_UserInput>;
  timezone_raw?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transfer_type?: InputMaybe<EnumTransferTypeFieldUpdateOperationsInput>;
  transfer_user_cycle?: InputMaybe<SaleCycleUpdateManyWithoutTransfer_UserInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user_custom_availability?: InputMaybe<AvailabilityUpdateManyWithoutUserInput>;
  user_past_tokens?: InputMaybe<UserPastTokensUpdateManyWithoutUserInput>;
  visible_all_leads_sm?: InputMaybe<BoolFieldUpdateOperationsInput>;
  visible_call_report_columns?: InputMaybe<UserUpdatevisible_Call_Report_ColumnsInput>;
  visible_call_report_event_columns?: InputMaybe<UserUpdatevisible_Call_Report_Event_ColumnsInput>;
  visible_call_report_pipeline_columns?: InputMaybe<UserUpdatevisible_Call_Report_Pipeline_ColumnsInput>;
  visible_my_leads_columns?: InputMaybe<UserUpdatevisible_My_Leads_ColumnsInput>;
  voicemail_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutCommissionsInput = {
  ai_user?: InputMaybe<BoolFieldUpdateOperationsInput>;
  alternate_email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  always_show_calendar_options?: InputMaybe<BoolFieldUpdateOperationsInput>;
  associate_owner_rep_history?: InputMaybe<AssociationHistoryUpdateManyWithoutRepInput>;
  associate_user_history?: InputMaybe<AssociationHistoryUpdateManyWithoutUserInput>;
  auto_transfer_selection_receive_attempts?: InputMaybe<AutoTransferSelectionUpdateManyWithoutFrom_UserInput>;
  auto_transfer_selection_send_attempts?: InputMaybe<AutoTransferSelectionUpdateManyWithoutTo_UserInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptUpdateManyWithoutUserInput>;
  callSharingAsCoach?: InputMaybe<CallSharingUpdateManyWithoutCoachInput>;
  callSharingAsUser?: InputMaybe<CallSharingUpdateManyWithoutReceiver_UserInput>;
  channel?: InputMaybe<NullableEnumChannelfilterFieldUpdateOperationsInput>;
  close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  close_user_cycle?: InputMaybe<SaleCycleUpdateManyWithoutClose_UserInput>;
  close_user_stat?: InputMaybe<StatLeadAggregationUpdateManyWithoutClose_UserInput>;
  contact_requests?: InputMaybe<ContactRequestUpdateManyWithoutRequesting_UserInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  current_sequence_view?: InputMaybe<SequenceDashboardViewUpdateOneWithoutUserInput>;
  current_team_start_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  custom_field_white_list_array?: InputMaybe<UserCustomFieldFilterUpdateManyWithoutUserInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemUpdateManyWithoutUserInput>;
  daily_lead_claim?: InputMaybe<IntFieldUpdateOperationsInput>;
  dashboard_2_access?: InputMaybe<BoolFieldUpdateOperationsInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewUpdateManyWithoutUserInput>;
  default_audio_input?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  default_audio_output?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  default_availability?: InputMaybe<BoolFieldUpdateOperationsInput>;
  deleted_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  deprecate_old_session?: InputMaybe<BoolFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordUpdateManyWithoutUserInput>;
  favorite_leads?: InputMaybe<LeadUpdateManyWithoutFavorited_By_UsersInput>;
  first_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  folder_permissions?: InputMaybe<FolderPermissionUpdateManyWithoutUserInput>;
  folders?: InputMaybe<FolderUpdateManyWithoutOwnerInput>;
  from_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutOld_UserInput>;
  GlobalRouting?: InputMaybe<GlobalRepRoutingUpdateManyWithoutRepInput>;
  google_refresh_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  holds_per_prospect_vs_held_demo_per_dial?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  hook_subscriptions?: InputMaybe<WebHookSubscriptionUpdateManyWithoutUserInput>;
  hubspot_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  idle_owned_leads?: InputMaybe<LeadUpdateManyWithoutSecondary_RepsInput>;
  industry_filter?: InputMaybe<BoolFieldUpdateOperationsInput>;
  industry_white_list_array?: InputMaybe<UserUpdateindustry_White_List_ArrayInput>;
  industry_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  joinMe_access_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  joinMe_refresh_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  last_demo_transferred_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  last_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutUserInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryUpdateManyWithoutUserInput>;
  lead_intents?: InputMaybe<LeadIntentUpdateManyWithoutUserInput>;
  lead_source_filter?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lead_source_white_list_array?: InputMaybe<UserUpdatelead_Source_White_List_ArrayInput>;
  lead_source_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  leads_last_called?: InputMaybe<LeadUpdateManyWithoutLast_Call_Result_RepInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusUpdateManyWithoutUserInput>;
  manager_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  note_item?: InputMaybe<NoteItemUpdateManyWithoutUserInput>;
  notifications?: InputMaybe<NotificationUpdateManyWithoutUserInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutUsersInput>;
  owned_leads?: InputMaybe<LeadUpdateManyWithoutRepInput>;
  panda_doc_access?: InputMaybe<BoolFieldUpdateOperationsInput>;
  password?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  payment_settings?: InputMaybe<OrganizationPaymentSettingsUpdateManyWithoutCreated_ByInput>;
  payment_settings_updated?: InputMaybe<OrganizationPaymentSettingsUpdateManyWithoutLast_Updated_ByInput>;
  phone_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  post?: InputMaybe<PostUpdateManyWithoutAuthorInput>;
  ProductsCreated?: InputMaybe<ProductChangeLogUpdateManyWithoutUpdated_ByInput>;
  profile_image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  receive_transfer_attempts?: InputMaybe<TransferAttemptUpdateManyWithoutTo_UserInput>;
  receiver_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutReceiver_UserInput>;
  regions_white_list_array?: InputMaybe<UserUpdateregions_White_List_ArrayInput>;
  regions_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  rep_scores?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumRoleFieldUpdateOperationsInput>;
  routings?: InputMaybe<RoutingUpdateManyWithoutRepInput>;
  sale_config?: InputMaybe<SaleConfigUpdateManyWithoutCreated_ByInput>;
  sale_config_updated?: InputMaybe<SaleConfigUpdateManyWithoutLast_Updated_ByInput>;
  sales_team_column_options?: InputMaybe<UserUpdatesales_Team_Column_OptionsInput>;
  schedule_items?: InputMaybe<ScheduleItemUpdateManyWithoutUserInput>;
  send_transfer_attempts?: InputMaybe<TransferAttemptUpdateManyWithoutFrom_UserInput>;
  sender_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutSender_UserInput>;
  sequence_entry_criteria_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SequenceActionLog?: InputMaybe<StepActionLogUpdateManyWithoutRepInput>;
  sequenceDashboardView?: InputMaybe<SequenceDashboardViewUpdateManyWithoutOwned_ByInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutUserInput>;
  Sequences_created?: InputMaybe<SequenceUpdateManyWithoutCreated_ByInput>;
  Sequences_modified_by_user?: InputMaybe<SequenceUpdateManyWithoutLast_Updated_ByInput>;
  session_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  set_user_cycle?: InputMaybe<SaleCycleUpdateManyWithoutSet_UserInput>;
  set_user_stat?: InputMaybe<StatLeadAggregationUpdateManyWithoutSet_UserInput>;
  sf_user_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SmsItems?: InputMaybe<SmsItemUpdateManyWithoutRepInput>;
  SnoozeTracking?: InputMaybe<SnoozeTrackingUpdateManyWithoutRepInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  statusHistory?: InputMaybe<UserStatusHistoryUpdateManyWithoutUserInput>;
  sub_industry_white_list_array?: InputMaybe<UserUpdatesub_Industry_White_List_ArrayInput>;
  sub_industry_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  support_rep?: InputMaybe<BoolFieldUpdateOperationsInput>;
  taking_demos?: InputMaybe<BoolFieldUpdateOperationsInput>;
  team?: InputMaybe<TeamUpdateOneWithoutMembersInput>;
  Template?: InputMaybe<TemplateUpdateManyWithoutLast_Modified_ByInput>;
  TemplateVersion?: InputMaybe<TemplateVersionUpdateManyWithoutCreated_ByInput>;
  timekit_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timekit_type?: InputMaybe<EnumTimekittypeFieldUpdateOperationsInput>;
  timeOffRequest?: InputMaybe<TimeOffRequestUpdateManyWithoutRequesting_UserInput>;
  timezone_raw?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transfer_type?: InputMaybe<EnumTransferTypeFieldUpdateOperationsInput>;
  transfer_user_cycle?: InputMaybe<SaleCycleUpdateManyWithoutTransfer_UserInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user_custom_availability?: InputMaybe<AvailabilityUpdateManyWithoutUserInput>;
  user_past_tokens?: InputMaybe<UserPastTokensUpdateManyWithoutUserInput>;
  visible_all_leads_sm?: InputMaybe<BoolFieldUpdateOperationsInput>;
  visible_call_report_columns?: InputMaybe<UserUpdatevisible_Call_Report_ColumnsInput>;
  visible_call_report_event_columns?: InputMaybe<UserUpdatevisible_Call_Report_Event_ColumnsInput>;
  visible_call_report_pipeline_columns?: InputMaybe<UserUpdatevisible_Call_Report_Pipeline_ColumnsInput>;
  visible_my_leads_columns?: InputMaybe<UserUpdatevisible_My_Leads_ColumnsInput>;
  voicemail_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutContact_RequestsInput = {
  ai_user?: InputMaybe<BoolFieldUpdateOperationsInput>;
  alternate_email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  always_show_calendar_options?: InputMaybe<BoolFieldUpdateOperationsInput>;
  associate_owner_rep_history?: InputMaybe<AssociationHistoryUpdateManyWithoutRepInput>;
  associate_user_history?: InputMaybe<AssociationHistoryUpdateManyWithoutUserInput>;
  auto_transfer_selection_receive_attempts?: InputMaybe<AutoTransferSelectionUpdateManyWithoutFrom_UserInput>;
  auto_transfer_selection_send_attempts?: InputMaybe<AutoTransferSelectionUpdateManyWithoutTo_UserInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptUpdateManyWithoutUserInput>;
  callSharingAsCoach?: InputMaybe<CallSharingUpdateManyWithoutCoachInput>;
  callSharingAsUser?: InputMaybe<CallSharingUpdateManyWithoutReceiver_UserInput>;
  channel?: InputMaybe<NullableEnumChannelfilterFieldUpdateOperationsInput>;
  close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  close_user_cycle?: InputMaybe<SaleCycleUpdateManyWithoutClose_UserInput>;
  close_user_stat?: InputMaybe<StatLeadAggregationUpdateManyWithoutClose_UserInput>;
  commissions?: InputMaybe<CommissionUpdateManyWithoutUserInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  current_sequence_view?: InputMaybe<SequenceDashboardViewUpdateOneWithoutUserInput>;
  current_team_start_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  custom_field_white_list_array?: InputMaybe<UserCustomFieldFilterUpdateManyWithoutUserInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemUpdateManyWithoutUserInput>;
  daily_lead_claim?: InputMaybe<IntFieldUpdateOperationsInput>;
  dashboard_2_access?: InputMaybe<BoolFieldUpdateOperationsInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewUpdateManyWithoutUserInput>;
  default_audio_input?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  default_audio_output?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  default_availability?: InputMaybe<BoolFieldUpdateOperationsInput>;
  deleted_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  deprecate_old_session?: InputMaybe<BoolFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordUpdateManyWithoutUserInput>;
  favorite_leads?: InputMaybe<LeadUpdateManyWithoutFavorited_By_UsersInput>;
  first_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  folder_permissions?: InputMaybe<FolderPermissionUpdateManyWithoutUserInput>;
  folders?: InputMaybe<FolderUpdateManyWithoutOwnerInput>;
  from_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutOld_UserInput>;
  GlobalRouting?: InputMaybe<GlobalRepRoutingUpdateManyWithoutRepInput>;
  google_refresh_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  holds_per_prospect_vs_held_demo_per_dial?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  hook_subscriptions?: InputMaybe<WebHookSubscriptionUpdateManyWithoutUserInput>;
  hubspot_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  idle_owned_leads?: InputMaybe<LeadUpdateManyWithoutSecondary_RepsInput>;
  industry_filter?: InputMaybe<BoolFieldUpdateOperationsInput>;
  industry_white_list_array?: InputMaybe<UserUpdateindustry_White_List_ArrayInput>;
  industry_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  joinMe_access_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  joinMe_refresh_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  last_demo_transferred_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  last_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutUserInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryUpdateManyWithoutUserInput>;
  lead_intents?: InputMaybe<LeadIntentUpdateManyWithoutUserInput>;
  lead_source_filter?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lead_source_white_list_array?: InputMaybe<UserUpdatelead_Source_White_List_ArrayInput>;
  lead_source_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  leads_last_called?: InputMaybe<LeadUpdateManyWithoutLast_Call_Result_RepInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusUpdateManyWithoutUserInput>;
  manager_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  note_item?: InputMaybe<NoteItemUpdateManyWithoutUserInput>;
  notifications?: InputMaybe<NotificationUpdateManyWithoutUserInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutUsersInput>;
  owned_leads?: InputMaybe<LeadUpdateManyWithoutRepInput>;
  panda_doc_access?: InputMaybe<BoolFieldUpdateOperationsInput>;
  password?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  payment_settings?: InputMaybe<OrganizationPaymentSettingsUpdateManyWithoutCreated_ByInput>;
  payment_settings_updated?: InputMaybe<OrganizationPaymentSettingsUpdateManyWithoutLast_Updated_ByInput>;
  phone_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  post?: InputMaybe<PostUpdateManyWithoutAuthorInput>;
  ProductsCreated?: InputMaybe<ProductChangeLogUpdateManyWithoutUpdated_ByInput>;
  profile_image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  receive_transfer_attempts?: InputMaybe<TransferAttemptUpdateManyWithoutTo_UserInput>;
  receiver_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutReceiver_UserInput>;
  regions_white_list_array?: InputMaybe<UserUpdateregions_White_List_ArrayInput>;
  regions_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  rep_scores?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumRoleFieldUpdateOperationsInput>;
  routings?: InputMaybe<RoutingUpdateManyWithoutRepInput>;
  sale_config?: InputMaybe<SaleConfigUpdateManyWithoutCreated_ByInput>;
  sale_config_updated?: InputMaybe<SaleConfigUpdateManyWithoutLast_Updated_ByInput>;
  sales_team_column_options?: InputMaybe<UserUpdatesales_Team_Column_OptionsInput>;
  schedule_items?: InputMaybe<ScheduleItemUpdateManyWithoutUserInput>;
  send_transfer_attempts?: InputMaybe<TransferAttemptUpdateManyWithoutFrom_UserInput>;
  sender_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutSender_UserInput>;
  sequence_entry_criteria_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SequenceActionLog?: InputMaybe<StepActionLogUpdateManyWithoutRepInput>;
  sequenceDashboardView?: InputMaybe<SequenceDashboardViewUpdateManyWithoutOwned_ByInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutUserInput>;
  Sequences_created?: InputMaybe<SequenceUpdateManyWithoutCreated_ByInput>;
  Sequences_modified_by_user?: InputMaybe<SequenceUpdateManyWithoutLast_Updated_ByInput>;
  session_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  set_user_cycle?: InputMaybe<SaleCycleUpdateManyWithoutSet_UserInput>;
  set_user_stat?: InputMaybe<StatLeadAggregationUpdateManyWithoutSet_UserInput>;
  sf_user_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SmsItems?: InputMaybe<SmsItemUpdateManyWithoutRepInput>;
  SnoozeTracking?: InputMaybe<SnoozeTrackingUpdateManyWithoutRepInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  statusHistory?: InputMaybe<UserStatusHistoryUpdateManyWithoutUserInput>;
  sub_industry_white_list_array?: InputMaybe<UserUpdatesub_Industry_White_List_ArrayInput>;
  sub_industry_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  support_rep?: InputMaybe<BoolFieldUpdateOperationsInput>;
  taking_demos?: InputMaybe<BoolFieldUpdateOperationsInput>;
  team?: InputMaybe<TeamUpdateOneWithoutMembersInput>;
  Template?: InputMaybe<TemplateUpdateManyWithoutLast_Modified_ByInput>;
  TemplateVersion?: InputMaybe<TemplateVersionUpdateManyWithoutCreated_ByInput>;
  timekit_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timekit_type?: InputMaybe<EnumTimekittypeFieldUpdateOperationsInput>;
  timeOffRequest?: InputMaybe<TimeOffRequestUpdateManyWithoutRequesting_UserInput>;
  timezone_raw?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transfer_type?: InputMaybe<EnumTransferTypeFieldUpdateOperationsInput>;
  transfer_user_cycle?: InputMaybe<SaleCycleUpdateManyWithoutTransfer_UserInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user_custom_availability?: InputMaybe<AvailabilityUpdateManyWithoutUserInput>;
  user_past_tokens?: InputMaybe<UserPastTokensUpdateManyWithoutUserInput>;
  visible_all_leads_sm?: InputMaybe<BoolFieldUpdateOperationsInput>;
  visible_call_report_columns?: InputMaybe<UserUpdatevisible_Call_Report_ColumnsInput>;
  visible_call_report_event_columns?: InputMaybe<UserUpdatevisible_Call_Report_Event_ColumnsInput>;
  visible_call_report_pipeline_columns?: InputMaybe<UserUpdatevisible_Call_Report_Pipeline_ColumnsInput>;
  visible_my_leads_columns?: InputMaybe<UserUpdatevisible_My_Leads_ColumnsInput>;
  voicemail_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutCurrent_Sequence_ViewInput = {
  ai_user?: InputMaybe<BoolFieldUpdateOperationsInput>;
  alternate_email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  always_show_calendar_options?: InputMaybe<BoolFieldUpdateOperationsInput>;
  associate_owner_rep_history?: InputMaybe<AssociationHistoryUpdateManyWithoutRepInput>;
  associate_user_history?: InputMaybe<AssociationHistoryUpdateManyWithoutUserInput>;
  auto_transfer_selection_receive_attempts?: InputMaybe<AutoTransferSelectionUpdateManyWithoutFrom_UserInput>;
  auto_transfer_selection_send_attempts?: InputMaybe<AutoTransferSelectionUpdateManyWithoutTo_UserInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptUpdateManyWithoutUserInput>;
  callSharingAsCoach?: InputMaybe<CallSharingUpdateManyWithoutCoachInput>;
  callSharingAsUser?: InputMaybe<CallSharingUpdateManyWithoutReceiver_UserInput>;
  channel?: InputMaybe<NullableEnumChannelfilterFieldUpdateOperationsInput>;
  close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  close_user_cycle?: InputMaybe<SaleCycleUpdateManyWithoutClose_UserInput>;
  close_user_stat?: InputMaybe<StatLeadAggregationUpdateManyWithoutClose_UserInput>;
  commissions?: InputMaybe<CommissionUpdateManyWithoutUserInput>;
  contact_requests?: InputMaybe<ContactRequestUpdateManyWithoutRequesting_UserInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  current_team_start_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  custom_field_white_list_array?: InputMaybe<UserCustomFieldFilterUpdateManyWithoutUserInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemUpdateManyWithoutUserInput>;
  daily_lead_claim?: InputMaybe<IntFieldUpdateOperationsInput>;
  dashboard_2_access?: InputMaybe<BoolFieldUpdateOperationsInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewUpdateManyWithoutUserInput>;
  default_audio_input?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  default_audio_output?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  default_availability?: InputMaybe<BoolFieldUpdateOperationsInput>;
  deleted_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  deprecate_old_session?: InputMaybe<BoolFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordUpdateManyWithoutUserInput>;
  favorite_leads?: InputMaybe<LeadUpdateManyWithoutFavorited_By_UsersInput>;
  first_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  folder_permissions?: InputMaybe<FolderPermissionUpdateManyWithoutUserInput>;
  folders?: InputMaybe<FolderUpdateManyWithoutOwnerInput>;
  from_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutOld_UserInput>;
  GlobalRouting?: InputMaybe<GlobalRepRoutingUpdateManyWithoutRepInput>;
  google_refresh_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  holds_per_prospect_vs_held_demo_per_dial?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  hook_subscriptions?: InputMaybe<WebHookSubscriptionUpdateManyWithoutUserInput>;
  hubspot_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  idle_owned_leads?: InputMaybe<LeadUpdateManyWithoutSecondary_RepsInput>;
  industry_filter?: InputMaybe<BoolFieldUpdateOperationsInput>;
  industry_white_list_array?: InputMaybe<UserUpdateindustry_White_List_ArrayInput>;
  industry_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  joinMe_access_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  joinMe_refresh_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  last_demo_transferred_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  last_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutUserInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryUpdateManyWithoutUserInput>;
  lead_intents?: InputMaybe<LeadIntentUpdateManyWithoutUserInput>;
  lead_source_filter?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lead_source_white_list_array?: InputMaybe<UserUpdatelead_Source_White_List_ArrayInput>;
  lead_source_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  leads_last_called?: InputMaybe<LeadUpdateManyWithoutLast_Call_Result_RepInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusUpdateManyWithoutUserInput>;
  manager_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  note_item?: InputMaybe<NoteItemUpdateManyWithoutUserInput>;
  notifications?: InputMaybe<NotificationUpdateManyWithoutUserInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutUsersInput>;
  owned_leads?: InputMaybe<LeadUpdateManyWithoutRepInput>;
  panda_doc_access?: InputMaybe<BoolFieldUpdateOperationsInput>;
  password?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  payment_settings?: InputMaybe<OrganizationPaymentSettingsUpdateManyWithoutCreated_ByInput>;
  payment_settings_updated?: InputMaybe<OrganizationPaymentSettingsUpdateManyWithoutLast_Updated_ByInput>;
  phone_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  post?: InputMaybe<PostUpdateManyWithoutAuthorInput>;
  ProductsCreated?: InputMaybe<ProductChangeLogUpdateManyWithoutUpdated_ByInput>;
  profile_image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  receive_transfer_attempts?: InputMaybe<TransferAttemptUpdateManyWithoutTo_UserInput>;
  receiver_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutReceiver_UserInput>;
  regions_white_list_array?: InputMaybe<UserUpdateregions_White_List_ArrayInput>;
  regions_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  rep_scores?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumRoleFieldUpdateOperationsInput>;
  routings?: InputMaybe<RoutingUpdateManyWithoutRepInput>;
  sale_config?: InputMaybe<SaleConfigUpdateManyWithoutCreated_ByInput>;
  sale_config_updated?: InputMaybe<SaleConfigUpdateManyWithoutLast_Updated_ByInput>;
  sales_team_column_options?: InputMaybe<UserUpdatesales_Team_Column_OptionsInput>;
  schedule_items?: InputMaybe<ScheduleItemUpdateManyWithoutUserInput>;
  send_transfer_attempts?: InputMaybe<TransferAttemptUpdateManyWithoutFrom_UserInput>;
  sender_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutSender_UserInput>;
  sequence_entry_criteria_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SequenceActionLog?: InputMaybe<StepActionLogUpdateManyWithoutRepInput>;
  sequenceDashboardView?: InputMaybe<SequenceDashboardViewUpdateManyWithoutOwned_ByInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutUserInput>;
  Sequences_created?: InputMaybe<SequenceUpdateManyWithoutCreated_ByInput>;
  Sequences_modified_by_user?: InputMaybe<SequenceUpdateManyWithoutLast_Updated_ByInput>;
  session_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  set_user_cycle?: InputMaybe<SaleCycleUpdateManyWithoutSet_UserInput>;
  set_user_stat?: InputMaybe<StatLeadAggregationUpdateManyWithoutSet_UserInput>;
  sf_user_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SmsItems?: InputMaybe<SmsItemUpdateManyWithoutRepInput>;
  SnoozeTracking?: InputMaybe<SnoozeTrackingUpdateManyWithoutRepInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  statusHistory?: InputMaybe<UserStatusHistoryUpdateManyWithoutUserInput>;
  sub_industry_white_list_array?: InputMaybe<UserUpdatesub_Industry_White_List_ArrayInput>;
  sub_industry_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  support_rep?: InputMaybe<BoolFieldUpdateOperationsInput>;
  taking_demos?: InputMaybe<BoolFieldUpdateOperationsInput>;
  team?: InputMaybe<TeamUpdateOneWithoutMembersInput>;
  Template?: InputMaybe<TemplateUpdateManyWithoutLast_Modified_ByInput>;
  TemplateVersion?: InputMaybe<TemplateVersionUpdateManyWithoutCreated_ByInput>;
  timekit_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timekit_type?: InputMaybe<EnumTimekittypeFieldUpdateOperationsInput>;
  timeOffRequest?: InputMaybe<TimeOffRequestUpdateManyWithoutRequesting_UserInput>;
  timezone_raw?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transfer_type?: InputMaybe<EnumTransferTypeFieldUpdateOperationsInput>;
  transfer_user_cycle?: InputMaybe<SaleCycleUpdateManyWithoutTransfer_UserInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user_custom_availability?: InputMaybe<AvailabilityUpdateManyWithoutUserInput>;
  user_past_tokens?: InputMaybe<UserPastTokensUpdateManyWithoutUserInput>;
  visible_all_leads_sm?: InputMaybe<BoolFieldUpdateOperationsInput>;
  visible_call_report_columns?: InputMaybe<UserUpdatevisible_Call_Report_ColumnsInput>;
  visible_call_report_event_columns?: InputMaybe<UserUpdatevisible_Call_Report_Event_ColumnsInput>;
  visible_call_report_pipeline_columns?: InputMaybe<UserUpdatevisible_Call_Report_Pipeline_ColumnsInput>;
  visible_my_leads_columns?: InputMaybe<UserUpdatevisible_My_Leads_ColumnsInput>;
  voicemail_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutCustom_Field_White_List_ArrayInput = {
  ai_user?: InputMaybe<BoolFieldUpdateOperationsInput>;
  alternate_email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  always_show_calendar_options?: InputMaybe<BoolFieldUpdateOperationsInput>;
  associate_owner_rep_history?: InputMaybe<AssociationHistoryUpdateManyWithoutRepInput>;
  associate_user_history?: InputMaybe<AssociationHistoryUpdateManyWithoutUserInput>;
  auto_transfer_selection_receive_attempts?: InputMaybe<AutoTransferSelectionUpdateManyWithoutFrom_UserInput>;
  auto_transfer_selection_send_attempts?: InputMaybe<AutoTransferSelectionUpdateManyWithoutTo_UserInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptUpdateManyWithoutUserInput>;
  callSharingAsCoach?: InputMaybe<CallSharingUpdateManyWithoutCoachInput>;
  callSharingAsUser?: InputMaybe<CallSharingUpdateManyWithoutReceiver_UserInput>;
  channel?: InputMaybe<NullableEnumChannelfilterFieldUpdateOperationsInput>;
  close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  close_user_cycle?: InputMaybe<SaleCycleUpdateManyWithoutClose_UserInput>;
  close_user_stat?: InputMaybe<StatLeadAggregationUpdateManyWithoutClose_UserInput>;
  commissions?: InputMaybe<CommissionUpdateManyWithoutUserInput>;
  contact_requests?: InputMaybe<ContactRequestUpdateManyWithoutRequesting_UserInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  current_sequence_view?: InputMaybe<SequenceDashboardViewUpdateOneWithoutUserInput>;
  current_team_start_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemUpdateManyWithoutUserInput>;
  daily_lead_claim?: InputMaybe<IntFieldUpdateOperationsInput>;
  dashboard_2_access?: InputMaybe<BoolFieldUpdateOperationsInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewUpdateManyWithoutUserInput>;
  default_audio_input?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  default_audio_output?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  default_availability?: InputMaybe<BoolFieldUpdateOperationsInput>;
  deleted_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  deprecate_old_session?: InputMaybe<BoolFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordUpdateManyWithoutUserInput>;
  favorite_leads?: InputMaybe<LeadUpdateManyWithoutFavorited_By_UsersInput>;
  first_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  folder_permissions?: InputMaybe<FolderPermissionUpdateManyWithoutUserInput>;
  folders?: InputMaybe<FolderUpdateManyWithoutOwnerInput>;
  from_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutOld_UserInput>;
  GlobalRouting?: InputMaybe<GlobalRepRoutingUpdateManyWithoutRepInput>;
  google_refresh_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  holds_per_prospect_vs_held_demo_per_dial?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  hook_subscriptions?: InputMaybe<WebHookSubscriptionUpdateManyWithoutUserInput>;
  hubspot_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  idle_owned_leads?: InputMaybe<LeadUpdateManyWithoutSecondary_RepsInput>;
  industry_filter?: InputMaybe<BoolFieldUpdateOperationsInput>;
  industry_white_list_array?: InputMaybe<UserUpdateindustry_White_List_ArrayInput>;
  industry_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  joinMe_access_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  joinMe_refresh_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  last_demo_transferred_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  last_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutUserInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryUpdateManyWithoutUserInput>;
  lead_intents?: InputMaybe<LeadIntentUpdateManyWithoutUserInput>;
  lead_source_filter?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lead_source_white_list_array?: InputMaybe<UserUpdatelead_Source_White_List_ArrayInput>;
  lead_source_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  leads_last_called?: InputMaybe<LeadUpdateManyWithoutLast_Call_Result_RepInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusUpdateManyWithoutUserInput>;
  manager_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  note_item?: InputMaybe<NoteItemUpdateManyWithoutUserInput>;
  notifications?: InputMaybe<NotificationUpdateManyWithoutUserInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutUsersInput>;
  owned_leads?: InputMaybe<LeadUpdateManyWithoutRepInput>;
  panda_doc_access?: InputMaybe<BoolFieldUpdateOperationsInput>;
  password?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  payment_settings?: InputMaybe<OrganizationPaymentSettingsUpdateManyWithoutCreated_ByInput>;
  payment_settings_updated?: InputMaybe<OrganizationPaymentSettingsUpdateManyWithoutLast_Updated_ByInput>;
  phone_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  post?: InputMaybe<PostUpdateManyWithoutAuthorInput>;
  ProductsCreated?: InputMaybe<ProductChangeLogUpdateManyWithoutUpdated_ByInput>;
  profile_image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  receive_transfer_attempts?: InputMaybe<TransferAttemptUpdateManyWithoutTo_UserInput>;
  receiver_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutReceiver_UserInput>;
  regions_white_list_array?: InputMaybe<UserUpdateregions_White_List_ArrayInput>;
  regions_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  rep_scores?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumRoleFieldUpdateOperationsInput>;
  routings?: InputMaybe<RoutingUpdateManyWithoutRepInput>;
  sale_config?: InputMaybe<SaleConfigUpdateManyWithoutCreated_ByInput>;
  sale_config_updated?: InputMaybe<SaleConfigUpdateManyWithoutLast_Updated_ByInput>;
  sales_team_column_options?: InputMaybe<UserUpdatesales_Team_Column_OptionsInput>;
  schedule_items?: InputMaybe<ScheduleItemUpdateManyWithoutUserInput>;
  send_transfer_attempts?: InputMaybe<TransferAttemptUpdateManyWithoutFrom_UserInput>;
  sender_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutSender_UserInput>;
  sequence_entry_criteria_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SequenceActionLog?: InputMaybe<StepActionLogUpdateManyWithoutRepInput>;
  sequenceDashboardView?: InputMaybe<SequenceDashboardViewUpdateManyWithoutOwned_ByInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutUserInput>;
  Sequences_created?: InputMaybe<SequenceUpdateManyWithoutCreated_ByInput>;
  Sequences_modified_by_user?: InputMaybe<SequenceUpdateManyWithoutLast_Updated_ByInput>;
  session_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  set_user_cycle?: InputMaybe<SaleCycleUpdateManyWithoutSet_UserInput>;
  set_user_stat?: InputMaybe<StatLeadAggregationUpdateManyWithoutSet_UserInput>;
  sf_user_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SmsItems?: InputMaybe<SmsItemUpdateManyWithoutRepInput>;
  SnoozeTracking?: InputMaybe<SnoozeTrackingUpdateManyWithoutRepInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  statusHistory?: InputMaybe<UserStatusHistoryUpdateManyWithoutUserInput>;
  sub_industry_white_list_array?: InputMaybe<UserUpdatesub_Industry_White_List_ArrayInput>;
  sub_industry_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  support_rep?: InputMaybe<BoolFieldUpdateOperationsInput>;
  taking_demos?: InputMaybe<BoolFieldUpdateOperationsInput>;
  team?: InputMaybe<TeamUpdateOneWithoutMembersInput>;
  Template?: InputMaybe<TemplateUpdateManyWithoutLast_Modified_ByInput>;
  TemplateVersion?: InputMaybe<TemplateVersionUpdateManyWithoutCreated_ByInput>;
  timekit_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timekit_type?: InputMaybe<EnumTimekittypeFieldUpdateOperationsInput>;
  timeOffRequest?: InputMaybe<TimeOffRequestUpdateManyWithoutRequesting_UserInput>;
  timezone_raw?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transfer_type?: InputMaybe<EnumTransferTypeFieldUpdateOperationsInput>;
  transfer_user_cycle?: InputMaybe<SaleCycleUpdateManyWithoutTransfer_UserInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user_custom_availability?: InputMaybe<AvailabilityUpdateManyWithoutUserInput>;
  user_past_tokens?: InputMaybe<UserPastTokensUpdateManyWithoutUserInput>;
  visible_all_leads_sm?: InputMaybe<BoolFieldUpdateOperationsInput>;
  visible_call_report_columns?: InputMaybe<UserUpdatevisible_Call_Report_ColumnsInput>;
  visible_call_report_event_columns?: InputMaybe<UserUpdatevisible_Call_Report_Event_ColumnsInput>;
  visible_call_report_pipeline_columns?: InputMaybe<UserUpdatevisible_Call_Report_Pipeline_ColumnsInput>;
  visible_my_leads_columns?: InputMaybe<UserUpdatevisible_My_Leads_ColumnsInput>;
  voicemail_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutCustom_Queue_ItemsInput = {
  ai_user?: InputMaybe<BoolFieldUpdateOperationsInput>;
  alternate_email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  always_show_calendar_options?: InputMaybe<BoolFieldUpdateOperationsInput>;
  associate_owner_rep_history?: InputMaybe<AssociationHistoryUpdateManyWithoutRepInput>;
  associate_user_history?: InputMaybe<AssociationHistoryUpdateManyWithoutUserInput>;
  auto_transfer_selection_receive_attempts?: InputMaybe<AutoTransferSelectionUpdateManyWithoutFrom_UserInput>;
  auto_transfer_selection_send_attempts?: InputMaybe<AutoTransferSelectionUpdateManyWithoutTo_UserInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptUpdateManyWithoutUserInput>;
  callSharingAsCoach?: InputMaybe<CallSharingUpdateManyWithoutCoachInput>;
  callSharingAsUser?: InputMaybe<CallSharingUpdateManyWithoutReceiver_UserInput>;
  channel?: InputMaybe<NullableEnumChannelfilterFieldUpdateOperationsInput>;
  close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  close_user_cycle?: InputMaybe<SaleCycleUpdateManyWithoutClose_UserInput>;
  close_user_stat?: InputMaybe<StatLeadAggregationUpdateManyWithoutClose_UserInput>;
  commissions?: InputMaybe<CommissionUpdateManyWithoutUserInput>;
  contact_requests?: InputMaybe<ContactRequestUpdateManyWithoutRequesting_UserInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  current_sequence_view?: InputMaybe<SequenceDashboardViewUpdateOneWithoutUserInput>;
  current_team_start_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  custom_field_white_list_array?: InputMaybe<UserCustomFieldFilterUpdateManyWithoutUserInput>;
  daily_lead_claim?: InputMaybe<IntFieldUpdateOperationsInput>;
  dashboard_2_access?: InputMaybe<BoolFieldUpdateOperationsInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewUpdateManyWithoutUserInput>;
  default_audio_input?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  default_audio_output?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  default_availability?: InputMaybe<BoolFieldUpdateOperationsInput>;
  deleted_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  deprecate_old_session?: InputMaybe<BoolFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordUpdateManyWithoutUserInput>;
  favorite_leads?: InputMaybe<LeadUpdateManyWithoutFavorited_By_UsersInput>;
  first_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  folder_permissions?: InputMaybe<FolderPermissionUpdateManyWithoutUserInput>;
  folders?: InputMaybe<FolderUpdateManyWithoutOwnerInput>;
  from_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutOld_UserInput>;
  GlobalRouting?: InputMaybe<GlobalRepRoutingUpdateManyWithoutRepInput>;
  google_refresh_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  holds_per_prospect_vs_held_demo_per_dial?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  hook_subscriptions?: InputMaybe<WebHookSubscriptionUpdateManyWithoutUserInput>;
  hubspot_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  idle_owned_leads?: InputMaybe<LeadUpdateManyWithoutSecondary_RepsInput>;
  industry_filter?: InputMaybe<BoolFieldUpdateOperationsInput>;
  industry_white_list_array?: InputMaybe<UserUpdateindustry_White_List_ArrayInput>;
  industry_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  joinMe_access_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  joinMe_refresh_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  last_demo_transferred_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  last_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutUserInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryUpdateManyWithoutUserInput>;
  lead_intents?: InputMaybe<LeadIntentUpdateManyWithoutUserInput>;
  lead_source_filter?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lead_source_white_list_array?: InputMaybe<UserUpdatelead_Source_White_List_ArrayInput>;
  lead_source_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  leads_last_called?: InputMaybe<LeadUpdateManyWithoutLast_Call_Result_RepInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusUpdateManyWithoutUserInput>;
  manager_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  note_item?: InputMaybe<NoteItemUpdateManyWithoutUserInput>;
  notifications?: InputMaybe<NotificationUpdateManyWithoutUserInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutUsersInput>;
  owned_leads?: InputMaybe<LeadUpdateManyWithoutRepInput>;
  panda_doc_access?: InputMaybe<BoolFieldUpdateOperationsInput>;
  password?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  payment_settings?: InputMaybe<OrganizationPaymentSettingsUpdateManyWithoutCreated_ByInput>;
  payment_settings_updated?: InputMaybe<OrganizationPaymentSettingsUpdateManyWithoutLast_Updated_ByInput>;
  phone_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  post?: InputMaybe<PostUpdateManyWithoutAuthorInput>;
  ProductsCreated?: InputMaybe<ProductChangeLogUpdateManyWithoutUpdated_ByInput>;
  profile_image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  receive_transfer_attempts?: InputMaybe<TransferAttemptUpdateManyWithoutTo_UserInput>;
  receiver_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutReceiver_UserInput>;
  regions_white_list_array?: InputMaybe<UserUpdateregions_White_List_ArrayInput>;
  regions_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  rep_scores?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumRoleFieldUpdateOperationsInput>;
  routings?: InputMaybe<RoutingUpdateManyWithoutRepInput>;
  sale_config?: InputMaybe<SaleConfigUpdateManyWithoutCreated_ByInput>;
  sale_config_updated?: InputMaybe<SaleConfigUpdateManyWithoutLast_Updated_ByInput>;
  sales_team_column_options?: InputMaybe<UserUpdatesales_Team_Column_OptionsInput>;
  schedule_items?: InputMaybe<ScheduleItemUpdateManyWithoutUserInput>;
  send_transfer_attempts?: InputMaybe<TransferAttemptUpdateManyWithoutFrom_UserInput>;
  sender_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutSender_UserInput>;
  sequence_entry_criteria_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SequenceActionLog?: InputMaybe<StepActionLogUpdateManyWithoutRepInput>;
  sequenceDashboardView?: InputMaybe<SequenceDashboardViewUpdateManyWithoutOwned_ByInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutUserInput>;
  Sequences_created?: InputMaybe<SequenceUpdateManyWithoutCreated_ByInput>;
  Sequences_modified_by_user?: InputMaybe<SequenceUpdateManyWithoutLast_Updated_ByInput>;
  session_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  set_user_cycle?: InputMaybe<SaleCycleUpdateManyWithoutSet_UserInput>;
  set_user_stat?: InputMaybe<StatLeadAggregationUpdateManyWithoutSet_UserInput>;
  sf_user_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SmsItems?: InputMaybe<SmsItemUpdateManyWithoutRepInput>;
  SnoozeTracking?: InputMaybe<SnoozeTrackingUpdateManyWithoutRepInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  statusHistory?: InputMaybe<UserStatusHistoryUpdateManyWithoutUserInput>;
  sub_industry_white_list_array?: InputMaybe<UserUpdatesub_Industry_White_List_ArrayInput>;
  sub_industry_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  support_rep?: InputMaybe<BoolFieldUpdateOperationsInput>;
  taking_demos?: InputMaybe<BoolFieldUpdateOperationsInput>;
  team?: InputMaybe<TeamUpdateOneWithoutMembersInput>;
  Template?: InputMaybe<TemplateUpdateManyWithoutLast_Modified_ByInput>;
  TemplateVersion?: InputMaybe<TemplateVersionUpdateManyWithoutCreated_ByInput>;
  timekit_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timekit_type?: InputMaybe<EnumTimekittypeFieldUpdateOperationsInput>;
  timeOffRequest?: InputMaybe<TimeOffRequestUpdateManyWithoutRequesting_UserInput>;
  timezone_raw?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transfer_type?: InputMaybe<EnumTransferTypeFieldUpdateOperationsInput>;
  transfer_user_cycle?: InputMaybe<SaleCycleUpdateManyWithoutTransfer_UserInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user_custom_availability?: InputMaybe<AvailabilityUpdateManyWithoutUserInput>;
  user_past_tokens?: InputMaybe<UserPastTokensUpdateManyWithoutUserInput>;
  visible_all_leads_sm?: InputMaybe<BoolFieldUpdateOperationsInput>;
  visible_call_report_columns?: InputMaybe<UserUpdatevisible_Call_Report_ColumnsInput>;
  visible_call_report_event_columns?: InputMaybe<UserUpdatevisible_Call_Report_Event_ColumnsInput>;
  visible_call_report_pipeline_columns?: InputMaybe<UserUpdatevisible_Call_Report_Pipeline_ColumnsInput>;
  visible_my_leads_columns?: InputMaybe<UserUpdatevisible_My_Leads_ColumnsInput>;
  voicemail_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutDashboardSavedViewInput = {
  ai_user?: InputMaybe<BoolFieldUpdateOperationsInput>;
  alternate_email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  always_show_calendar_options?: InputMaybe<BoolFieldUpdateOperationsInput>;
  associate_owner_rep_history?: InputMaybe<AssociationHistoryUpdateManyWithoutRepInput>;
  associate_user_history?: InputMaybe<AssociationHistoryUpdateManyWithoutUserInput>;
  auto_transfer_selection_receive_attempts?: InputMaybe<AutoTransferSelectionUpdateManyWithoutFrom_UserInput>;
  auto_transfer_selection_send_attempts?: InputMaybe<AutoTransferSelectionUpdateManyWithoutTo_UserInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptUpdateManyWithoutUserInput>;
  callSharingAsCoach?: InputMaybe<CallSharingUpdateManyWithoutCoachInput>;
  callSharingAsUser?: InputMaybe<CallSharingUpdateManyWithoutReceiver_UserInput>;
  channel?: InputMaybe<NullableEnumChannelfilterFieldUpdateOperationsInput>;
  close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  close_user_cycle?: InputMaybe<SaleCycleUpdateManyWithoutClose_UserInput>;
  close_user_stat?: InputMaybe<StatLeadAggregationUpdateManyWithoutClose_UserInput>;
  commissions?: InputMaybe<CommissionUpdateManyWithoutUserInput>;
  contact_requests?: InputMaybe<ContactRequestUpdateManyWithoutRequesting_UserInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  current_sequence_view?: InputMaybe<SequenceDashboardViewUpdateOneWithoutUserInput>;
  current_team_start_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  custom_field_white_list_array?: InputMaybe<UserCustomFieldFilterUpdateManyWithoutUserInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemUpdateManyWithoutUserInput>;
  daily_lead_claim?: InputMaybe<IntFieldUpdateOperationsInput>;
  dashboard_2_access?: InputMaybe<BoolFieldUpdateOperationsInput>;
  default_audio_input?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  default_audio_output?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  default_availability?: InputMaybe<BoolFieldUpdateOperationsInput>;
  deleted_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  deprecate_old_session?: InputMaybe<BoolFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordUpdateManyWithoutUserInput>;
  favorite_leads?: InputMaybe<LeadUpdateManyWithoutFavorited_By_UsersInput>;
  first_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  folder_permissions?: InputMaybe<FolderPermissionUpdateManyWithoutUserInput>;
  folders?: InputMaybe<FolderUpdateManyWithoutOwnerInput>;
  from_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutOld_UserInput>;
  GlobalRouting?: InputMaybe<GlobalRepRoutingUpdateManyWithoutRepInput>;
  google_refresh_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  holds_per_prospect_vs_held_demo_per_dial?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  hook_subscriptions?: InputMaybe<WebHookSubscriptionUpdateManyWithoutUserInput>;
  hubspot_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  idle_owned_leads?: InputMaybe<LeadUpdateManyWithoutSecondary_RepsInput>;
  industry_filter?: InputMaybe<BoolFieldUpdateOperationsInput>;
  industry_white_list_array?: InputMaybe<UserUpdateindustry_White_List_ArrayInput>;
  industry_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  joinMe_access_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  joinMe_refresh_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  last_demo_transferred_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  last_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutUserInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryUpdateManyWithoutUserInput>;
  lead_intents?: InputMaybe<LeadIntentUpdateManyWithoutUserInput>;
  lead_source_filter?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lead_source_white_list_array?: InputMaybe<UserUpdatelead_Source_White_List_ArrayInput>;
  lead_source_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  leads_last_called?: InputMaybe<LeadUpdateManyWithoutLast_Call_Result_RepInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusUpdateManyWithoutUserInput>;
  manager_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  note_item?: InputMaybe<NoteItemUpdateManyWithoutUserInput>;
  notifications?: InputMaybe<NotificationUpdateManyWithoutUserInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutUsersInput>;
  owned_leads?: InputMaybe<LeadUpdateManyWithoutRepInput>;
  panda_doc_access?: InputMaybe<BoolFieldUpdateOperationsInput>;
  password?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  payment_settings?: InputMaybe<OrganizationPaymentSettingsUpdateManyWithoutCreated_ByInput>;
  payment_settings_updated?: InputMaybe<OrganizationPaymentSettingsUpdateManyWithoutLast_Updated_ByInput>;
  phone_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  post?: InputMaybe<PostUpdateManyWithoutAuthorInput>;
  ProductsCreated?: InputMaybe<ProductChangeLogUpdateManyWithoutUpdated_ByInput>;
  profile_image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  receive_transfer_attempts?: InputMaybe<TransferAttemptUpdateManyWithoutTo_UserInput>;
  receiver_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutReceiver_UserInput>;
  regions_white_list_array?: InputMaybe<UserUpdateregions_White_List_ArrayInput>;
  regions_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  rep_scores?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumRoleFieldUpdateOperationsInput>;
  routings?: InputMaybe<RoutingUpdateManyWithoutRepInput>;
  sale_config?: InputMaybe<SaleConfigUpdateManyWithoutCreated_ByInput>;
  sale_config_updated?: InputMaybe<SaleConfigUpdateManyWithoutLast_Updated_ByInput>;
  sales_team_column_options?: InputMaybe<UserUpdatesales_Team_Column_OptionsInput>;
  schedule_items?: InputMaybe<ScheduleItemUpdateManyWithoutUserInput>;
  send_transfer_attempts?: InputMaybe<TransferAttemptUpdateManyWithoutFrom_UserInput>;
  sender_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutSender_UserInput>;
  sequence_entry_criteria_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SequenceActionLog?: InputMaybe<StepActionLogUpdateManyWithoutRepInput>;
  sequenceDashboardView?: InputMaybe<SequenceDashboardViewUpdateManyWithoutOwned_ByInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutUserInput>;
  Sequences_created?: InputMaybe<SequenceUpdateManyWithoutCreated_ByInput>;
  Sequences_modified_by_user?: InputMaybe<SequenceUpdateManyWithoutLast_Updated_ByInput>;
  session_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  set_user_cycle?: InputMaybe<SaleCycleUpdateManyWithoutSet_UserInput>;
  set_user_stat?: InputMaybe<StatLeadAggregationUpdateManyWithoutSet_UserInput>;
  sf_user_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SmsItems?: InputMaybe<SmsItemUpdateManyWithoutRepInput>;
  SnoozeTracking?: InputMaybe<SnoozeTrackingUpdateManyWithoutRepInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  statusHistory?: InputMaybe<UserStatusHistoryUpdateManyWithoutUserInput>;
  sub_industry_white_list_array?: InputMaybe<UserUpdatesub_Industry_White_List_ArrayInput>;
  sub_industry_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  support_rep?: InputMaybe<BoolFieldUpdateOperationsInput>;
  taking_demos?: InputMaybe<BoolFieldUpdateOperationsInput>;
  team?: InputMaybe<TeamUpdateOneWithoutMembersInput>;
  Template?: InputMaybe<TemplateUpdateManyWithoutLast_Modified_ByInput>;
  TemplateVersion?: InputMaybe<TemplateVersionUpdateManyWithoutCreated_ByInput>;
  timekit_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timekit_type?: InputMaybe<EnumTimekittypeFieldUpdateOperationsInput>;
  timeOffRequest?: InputMaybe<TimeOffRequestUpdateManyWithoutRequesting_UserInput>;
  timezone_raw?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transfer_type?: InputMaybe<EnumTransferTypeFieldUpdateOperationsInput>;
  transfer_user_cycle?: InputMaybe<SaleCycleUpdateManyWithoutTransfer_UserInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user_custom_availability?: InputMaybe<AvailabilityUpdateManyWithoutUserInput>;
  user_past_tokens?: InputMaybe<UserPastTokensUpdateManyWithoutUserInput>;
  visible_all_leads_sm?: InputMaybe<BoolFieldUpdateOperationsInput>;
  visible_call_report_columns?: InputMaybe<UserUpdatevisible_Call_Report_ColumnsInput>;
  visible_call_report_event_columns?: InputMaybe<UserUpdatevisible_Call_Report_Event_ColumnsInput>;
  visible_call_report_pipeline_columns?: InputMaybe<UserUpdatevisible_Call_Report_Pipeline_ColumnsInput>;
  visible_my_leads_columns?: InputMaybe<UserUpdatevisible_My_Leads_ColumnsInput>;
  voicemail_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutEmailSyncRecordInput = {
  ai_user?: InputMaybe<BoolFieldUpdateOperationsInput>;
  alternate_email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  always_show_calendar_options?: InputMaybe<BoolFieldUpdateOperationsInput>;
  associate_owner_rep_history?: InputMaybe<AssociationHistoryUpdateManyWithoutRepInput>;
  associate_user_history?: InputMaybe<AssociationHistoryUpdateManyWithoutUserInput>;
  auto_transfer_selection_receive_attempts?: InputMaybe<AutoTransferSelectionUpdateManyWithoutFrom_UserInput>;
  auto_transfer_selection_send_attempts?: InputMaybe<AutoTransferSelectionUpdateManyWithoutTo_UserInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptUpdateManyWithoutUserInput>;
  callSharingAsCoach?: InputMaybe<CallSharingUpdateManyWithoutCoachInput>;
  callSharingAsUser?: InputMaybe<CallSharingUpdateManyWithoutReceiver_UserInput>;
  channel?: InputMaybe<NullableEnumChannelfilterFieldUpdateOperationsInput>;
  close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  close_user_cycle?: InputMaybe<SaleCycleUpdateManyWithoutClose_UserInput>;
  close_user_stat?: InputMaybe<StatLeadAggregationUpdateManyWithoutClose_UserInput>;
  commissions?: InputMaybe<CommissionUpdateManyWithoutUserInput>;
  contact_requests?: InputMaybe<ContactRequestUpdateManyWithoutRequesting_UserInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  current_sequence_view?: InputMaybe<SequenceDashboardViewUpdateOneWithoutUserInput>;
  current_team_start_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  custom_field_white_list_array?: InputMaybe<UserCustomFieldFilterUpdateManyWithoutUserInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemUpdateManyWithoutUserInput>;
  daily_lead_claim?: InputMaybe<IntFieldUpdateOperationsInput>;
  dashboard_2_access?: InputMaybe<BoolFieldUpdateOperationsInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewUpdateManyWithoutUserInput>;
  default_audio_input?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  default_audio_output?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  default_availability?: InputMaybe<BoolFieldUpdateOperationsInput>;
  deleted_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  deprecate_old_session?: InputMaybe<BoolFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  favorite_leads?: InputMaybe<LeadUpdateManyWithoutFavorited_By_UsersInput>;
  first_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  folder_permissions?: InputMaybe<FolderPermissionUpdateManyWithoutUserInput>;
  folders?: InputMaybe<FolderUpdateManyWithoutOwnerInput>;
  from_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutOld_UserInput>;
  GlobalRouting?: InputMaybe<GlobalRepRoutingUpdateManyWithoutRepInput>;
  google_refresh_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  holds_per_prospect_vs_held_demo_per_dial?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  hook_subscriptions?: InputMaybe<WebHookSubscriptionUpdateManyWithoutUserInput>;
  hubspot_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  idle_owned_leads?: InputMaybe<LeadUpdateManyWithoutSecondary_RepsInput>;
  industry_filter?: InputMaybe<BoolFieldUpdateOperationsInput>;
  industry_white_list_array?: InputMaybe<UserUpdateindustry_White_List_ArrayInput>;
  industry_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  joinMe_access_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  joinMe_refresh_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  last_demo_transferred_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  last_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutUserInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryUpdateManyWithoutUserInput>;
  lead_intents?: InputMaybe<LeadIntentUpdateManyWithoutUserInput>;
  lead_source_filter?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lead_source_white_list_array?: InputMaybe<UserUpdatelead_Source_White_List_ArrayInput>;
  lead_source_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  leads_last_called?: InputMaybe<LeadUpdateManyWithoutLast_Call_Result_RepInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusUpdateManyWithoutUserInput>;
  manager_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  note_item?: InputMaybe<NoteItemUpdateManyWithoutUserInput>;
  notifications?: InputMaybe<NotificationUpdateManyWithoutUserInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutUsersInput>;
  owned_leads?: InputMaybe<LeadUpdateManyWithoutRepInput>;
  panda_doc_access?: InputMaybe<BoolFieldUpdateOperationsInput>;
  password?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  payment_settings?: InputMaybe<OrganizationPaymentSettingsUpdateManyWithoutCreated_ByInput>;
  payment_settings_updated?: InputMaybe<OrganizationPaymentSettingsUpdateManyWithoutLast_Updated_ByInput>;
  phone_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  post?: InputMaybe<PostUpdateManyWithoutAuthorInput>;
  ProductsCreated?: InputMaybe<ProductChangeLogUpdateManyWithoutUpdated_ByInput>;
  profile_image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  receive_transfer_attempts?: InputMaybe<TransferAttemptUpdateManyWithoutTo_UserInput>;
  receiver_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutReceiver_UserInput>;
  regions_white_list_array?: InputMaybe<UserUpdateregions_White_List_ArrayInput>;
  regions_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  rep_scores?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumRoleFieldUpdateOperationsInput>;
  routings?: InputMaybe<RoutingUpdateManyWithoutRepInput>;
  sale_config?: InputMaybe<SaleConfigUpdateManyWithoutCreated_ByInput>;
  sale_config_updated?: InputMaybe<SaleConfigUpdateManyWithoutLast_Updated_ByInput>;
  sales_team_column_options?: InputMaybe<UserUpdatesales_Team_Column_OptionsInput>;
  schedule_items?: InputMaybe<ScheduleItemUpdateManyWithoutUserInput>;
  send_transfer_attempts?: InputMaybe<TransferAttemptUpdateManyWithoutFrom_UserInput>;
  sender_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutSender_UserInput>;
  sequence_entry_criteria_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SequenceActionLog?: InputMaybe<StepActionLogUpdateManyWithoutRepInput>;
  sequenceDashboardView?: InputMaybe<SequenceDashboardViewUpdateManyWithoutOwned_ByInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutUserInput>;
  Sequences_created?: InputMaybe<SequenceUpdateManyWithoutCreated_ByInput>;
  Sequences_modified_by_user?: InputMaybe<SequenceUpdateManyWithoutLast_Updated_ByInput>;
  session_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  set_user_cycle?: InputMaybe<SaleCycleUpdateManyWithoutSet_UserInput>;
  set_user_stat?: InputMaybe<StatLeadAggregationUpdateManyWithoutSet_UserInput>;
  sf_user_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SmsItems?: InputMaybe<SmsItemUpdateManyWithoutRepInput>;
  SnoozeTracking?: InputMaybe<SnoozeTrackingUpdateManyWithoutRepInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  statusHistory?: InputMaybe<UserStatusHistoryUpdateManyWithoutUserInput>;
  sub_industry_white_list_array?: InputMaybe<UserUpdatesub_Industry_White_List_ArrayInput>;
  sub_industry_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  support_rep?: InputMaybe<BoolFieldUpdateOperationsInput>;
  taking_demos?: InputMaybe<BoolFieldUpdateOperationsInput>;
  team?: InputMaybe<TeamUpdateOneWithoutMembersInput>;
  Template?: InputMaybe<TemplateUpdateManyWithoutLast_Modified_ByInput>;
  TemplateVersion?: InputMaybe<TemplateVersionUpdateManyWithoutCreated_ByInput>;
  timekit_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timekit_type?: InputMaybe<EnumTimekittypeFieldUpdateOperationsInput>;
  timeOffRequest?: InputMaybe<TimeOffRequestUpdateManyWithoutRequesting_UserInput>;
  timezone_raw?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transfer_type?: InputMaybe<EnumTransferTypeFieldUpdateOperationsInput>;
  transfer_user_cycle?: InputMaybe<SaleCycleUpdateManyWithoutTransfer_UserInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user_custom_availability?: InputMaybe<AvailabilityUpdateManyWithoutUserInput>;
  user_past_tokens?: InputMaybe<UserPastTokensUpdateManyWithoutUserInput>;
  visible_all_leads_sm?: InputMaybe<BoolFieldUpdateOperationsInput>;
  visible_call_report_columns?: InputMaybe<UserUpdatevisible_Call_Report_ColumnsInput>;
  visible_call_report_event_columns?: InputMaybe<UserUpdatevisible_Call_Report_Event_ColumnsInput>;
  visible_call_report_pipeline_columns?: InputMaybe<UserUpdatevisible_Call_Report_Pipeline_ColumnsInput>;
  visible_my_leads_columns?: InputMaybe<UserUpdatevisible_My_Leads_ColumnsInput>;
  voicemail_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutFavorite_LeadsInput = {
  ai_user?: InputMaybe<BoolFieldUpdateOperationsInput>;
  alternate_email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  always_show_calendar_options?: InputMaybe<BoolFieldUpdateOperationsInput>;
  associate_owner_rep_history?: InputMaybe<AssociationHistoryUpdateManyWithoutRepInput>;
  associate_user_history?: InputMaybe<AssociationHistoryUpdateManyWithoutUserInput>;
  auto_transfer_selection_receive_attempts?: InputMaybe<AutoTransferSelectionUpdateManyWithoutFrom_UserInput>;
  auto_transfer_selection_send_attempts?: InputMaybe<AutoTransferSelectionUpdateManyWithoutTo_UserInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptUpdateManyWithoutUserInput>;
  callSharingAsCoach?: InputMaybe<CallSharingUpdateManyWithoutCoachInput>;
  callSharingAsUser?: InputMaybe<CallSharingUpdateManyWithoutReceiver_UserInput>;
  channel?: InputMaybe<NullableEnumChannelfilterFieldUpdateOperationsInput>;
  close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  close_user_cycle?: InputMaybe<SaleCycleUpdateManyWithoutClose_UserInput>;
  close_user_stat?: InputMaybe<StatLeadAggregationUpdateManyWithoutClose_UserInput>;
  commissions?: InputMaybe<CommissionUpdateManyWithoutUserInput>;
  contact_requests?: InputMaybe<ContactRequestUpdateManyWithoutRequesting_UserInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  current_sequence_view?: InputMaybe<SequenceDashboardViewUpdateOneWithoutUserInput>;
  current_team_start_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  custom_field_white_list_array?: InputMaybe<UserCustomFieldFilterUpdateManyWithoutUserInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemUpdateManyWithoutUserInput>;
  daily_lead_claim?: InputMaybe<IntFieldUpdateOperationsInput>;
  dashboard_2_access?: InputMaybe<BoolFieldUpdateOperationsInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewUpdateManyWithoutUserInput>;
  default_audio_input?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  default_audio_output?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  default_availability?: InputMaybe<BoolFieldUpdateOperationsInput>;
  deleted_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  deprecate_old_session?: InputMaybe<BoolFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordUpdateManyWithoutUserInput>;
  first_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  folder_permissions?: InputMaybe<FolderPermissionUpdateManyWithoutUserInput>;
  folders?: InputMaybe<FolderUpdateManyWithoutOwnerInput>;
  from_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutOld_UserInput>;
  GlobalRouting?: InputMaybe<GlobalRepRoutingUpdateManyWithoutRepInput>;
  google_refresh_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  holds_per_prospect_vs_held_demo_per_dial?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  hook_subscriptions?: InputMaybe<WebHookSubscriptionUpdateManyWithoutUserInput>;
  hubspot_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  idle_owned_leads?: InputMaybe<LeadUpdateManyWithoutSecondary_RepsInput>;
  industry_filter?: InputMaybe<BoolFieldUpdateOperationsInput>;
  industry_white_list_array?: InputMaybe<UserUpdateindustry_White_List_ArrayInput>;
  industry_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  joinMe_access_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  joinMe_refresh_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  last_demo_transferred_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  last_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutUserInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryUpdateManyWithoutUserInput>;
  lead_intents?: InputMaybe<LeadIntentUpdateManyWithoutUserInput>;
  lead_source_filter?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lead_source_white_list_array?: InputMaybe<UserUpdatelead_Source_White_List_ArrayInput>;
  lead_source_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  leads_last_called?: InputMaybe<LeadUpdateManyWithoutLast_Call_Result_RepInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusUpdateManyWithoutUserInput>;
  manager_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  note_item?: InputMaybe<NoteItemUpdateManyWithoutUserInput>;
  notifications?: InputMaybe<NotificationUpdateManyWithoutUserInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutUsersInput>;
  owned_leads?: InputMaybe<LeadUpdateManyWithoutRepInput>;
  panda_doc_access?: InputMaybe<BoolFieldUpdateOperationsInput>;
  password?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  payment_settings?: InputMaybe<OrganizationPaymentSettingsUpdateManyWithoutCreated_ByInput>;
  payment_settings_updated?: InputMaybe<OrganizationPaymentSettingsUpdateManyWithoutLast_Updated_ByInput>;
  phone_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  post?: InputMaybe<PostUpdateManyWithoutAuthorInput>;
  ProductsCreated?: InputMaybe<ProductChangeLogUpdateManyWithoutUpdated_ByInput>;
  profile_image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  receive_transfer_attempts?: InputMaybe<TransferAttemptUpdateManyWithoutTo_UserInput>;
  receiver_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutReceiver_UserInput>;
  regions_white_list_array?: InputMaybe<UserUpdateregions_White_List_ArrayInput>;
  regions_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  rep_scores?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumRoleFieldUpdateOperationsInput>;
  routings?: InputMaybe<RoutingUpdateManyWithoutRepInput>;
  sale_config?: InputMaybe<SaleConfigUpdateManyWithoutCreated_ByInput>;
  sale_config_updated?: InputMaybe<SaleConfigUpdateManyWithoutLast_Updated_ByInput>;
  sales_team_column_options?: InputMaybe<UserUpdatesales_Team_Column_OptionsInput>;
  schedule_items?: InputMaybe<ScheduleItemUpdateManyWithoutUserInput>;
  send_transfer_attempts?: InputMaybe<TransferAttemptUpdateManyWithoutFrom_UserInput>;
  sender_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutSender_UserInput>;
  sequence_entry_criteria_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SequenceActionLog?: InputMaybe<StepActionLogUpdateManyWithoutRepInput>;
  sequenceDashboardView?: InputMaybe<SequenceDashboardViewUpdateManyWithoutOwned_ByInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutUserInput>;
  Sequences_created?: InputMaybe<SequenceUpdateManyWithoutCreated_ByInput>;
  Sequences_modified_by_user?: InputMaybe<SequenceUpdateManyWithoutLast_Updated_ByInput>;
  session_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  set_user_cycle?: InputMaybe<SaleCycleUpdateManyWithoutSet_UserInput>;
  set_user_stat?: InputMaybe<StatLeadAggregationUpdateManyWithoutSet_UserInput>;
  sf_user_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SmsItems?: InputMaybe<SmsItemUpdateManyWithoutRepInput>;
  SnoozeTracking?: InputMaybe<SnoozeTrackingUpdateManyWithoutRepInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  statusHistory?: InputMaybe<UserStatusHistoryUpdateManyWithoutUserInput>;
  sub_industry_white_list_array?: InputMaybe<UserUpdatesub_Industry_White_List_ArrayInput>;
  sub_industry_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  support_rep?: InputMaybe<BoolFieldUpdateOperationsInput>;
  taking_demos?: InputMaybe<BoolFieldUpdateOperationsInput>;
  team?: InputMaybe<TeamUpdateOneWithoutMembersInput>;
  Template?: InputMaybe<TemplateUpdateManyWithoutLast_Modified_ByInput>;
  TemplateVersion?: InputMaybe<TemplateVersionUpdateManyWithoutCreated_ByInput>;
  timekit_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timekit_type?: InputMaybe<EnumTimekittypeFieldUpdateOperationsInput>;
  timeOffRequest?: InputMaybe<TimeOffRequestUpdateManyWithoutRequesting_UserInput>;
  timezone_raw?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transfer_type?: InputMaybe<EnumTransferTypeFieldUpdateOperationsInput>;
  transfer_user_cycle?: InputMaybe<SaleCycleUpdateManyWithoutTransfer_UserInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user_custom_availability?: InputMaybe<AvailabilityUpdateManyWithoutUserInput>;
  user_past_tokens?: InputMaybe<UserPastTokensUpdateManyWithoutUserInput>;
  visible_all_leads_sm?: InputMaybe<BoolFieldUpdateOperationsInput>;
  visible_call_report_columns?: InputMaybe<UserUpdatevisible_Call_Report_ColumnsInput>;
  visible_call_report_event_columns?: InputMaybe<UserUpdatevisible_Call_Report_Event_ColumnsInput>;
  visible_call_report_pipeline_columns?: InputMaybe<UserUpdatevisible_Call_Report_Pipeline_ColumnsInput>;
  visible_my_leads_columns?: InputMaybe<UserUpdatevisible_My_Leads_ColumnsInput>;
  voicemail_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutFolder_PermissionsInput = {
  ai_user?: InputMaybe<BoolFieldUpdateOperationsInput>;
  alternate_email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  always_show_calendar_options?: InputMaybe<BoolFieldUpdateOperationsInput>;
  associate_owner_rep_history?: InputMaybe<AssociationHistoryUpdateManyWithoutRepInput>;
  associate_user_history?: InputMaybe<AssociationHistoryUpdateManyWithoutUserInput>;
  auto_transfer_selection_receive_attempts?: InputMaybe<AutoTransferSelectionUpdateManyWithoutFrom_UserInput>;
  auto_transfer_selection_send_attempts?: InputMaybe<AutoTransferSelectionUpdateManyWithoutTo_UserInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptUpdateManyWithoutUserInput>;
  callSharingAsCoach?: InputMaybe<CallSharingUpdateManyWithoutCoachInput>;
  callSharingAsUser?: InputMaybe<CallSharingUpdateManyWithoutReceiver_UserInput>;
  channel?: InputMaybe<NullableEnumChannelfilterFieldUpdateOperationsInput>;
  close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  close_user_cycle?: InputMaybe<SaleCycleUpdateManyWithoutClose_UserInput>;
  close_user_stat?: InputMaybe<StatLeadAggregationUpdateManyWithoutClose_UserInput>;
  commissions?: InputMaybe<CommissionUpdateManyWithoutUserInput>;
  contact_requests?: InputMaybe<ContactRequestUpdateManyWithoutRequesting_UserInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  current_sequence_view?: InputMaybe<SequenceDashboardViewUpdateOneWithoutUserInput>;
  current_team_start_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  custom_field_white_list_array?: InputMaybe<UserCustomFieldFilterUpdateManyWithoutUserInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemUpdateManyWithoutUserInput>;
  daily_lead_claim?: InputMaybe<IntFieldUpdateOperationsInput>;
  dashboard_2_access?: InputMaybe<BoolFieldUpdateOperationsInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewUpdateManyWithoutUserInput>;
  default_audio_input?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  default_audio_output?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  default_availability?: InputMaybe<BoolFieldUpdateOperationsInput>;
  deleted_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  deprecate_old_session?: InputMaybe<BoolFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordUpdateManyWithoutUserInput>;
  favorite_leads?: InputMaybe<LeadUpdateManyWithoutFavorited_By_UsersInput>;
  first_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  folders?: InputMaybe<FolderUpdateManyWithoutOwnerInput>;
  from_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutOld_UserInput>;
  GlobalRouting?: InputMaybe<GlobalRepRoutingUpdateManyWithoutRepInput>;
  google_refresh_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  holds_per_prospect_vs_held_demo_per_dial?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  hook_subscriptions?: InputMaybe<WebHookSubscriptionUpdateManyWithoutUserInput>;
  hubspot_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  idle_owned_leads?: InputMaybe<LeadUpdateManyWithoutSecondary_RepsInput>;
  industry_filter?: InputMaybe<BoolFieldUpdateOperationsInput>;
  industry_white_list_array?: InputMaybe<UserUpdateindustry_White_List_ArrayInput>;
  industry_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  joinMe_access_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  joinMe_refresh_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  last_demo_transferred_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  last_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutUserInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryUpdateManyWithoutUserInput>;
  lead_intents?: InputMaybe<LeadIntentUpdateManyWithoutUserInput>;
  lead_source_filter?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lead_source_white_list_array?: InputMaybe<UserUpdatelead_Source_White_List_ArrayInput>;
  lead_source_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  leads_last_called?: InputMaybe<LeadUpdateManyWithoutLast_Call_Result_RepInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusUpdateManyWithoutUserInput>;
  manager_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  note_item?: InputMaybe<NoteItemUpdateManyWithoutUserInput>;
  notifications?: InputMaybe<NotificationUpdateManyWithoutUserInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutUsersInput>;
  owned_leads?: InputMaybe<LeadUpdateManyWithoutRepInput>;
  panda_doc_access?: InputMaybe<BoolFieldUpdateOperationsInput>;
  password?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  payment_settings?: InputMaybe<OrganizationPaymentSettingsUpdateManyWithoutCreated_ByInput>;
  payment_settings_updated?: InputMaybe<OrganizationPaymentSettingsUpdateManyWithoutLast_Updated_ByInput>;
  phone_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  post?: InputMaybe<PostUpdateManyWithoutAuthorInput>;
  ProductsCreated?: InputMaybe<ProductChangeLogUpdateManyWithoutUpdated_ByInput>;
  profile_image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  receive_transfer_attempts?: InputMaybe<TransferAttemptUpdateManyWithoutTo_UserInput>;
  receiver_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutReceiver_UserInput>;
  regions_white_list_array?: InputMaybe<UserUpdateregions_White_List_ArrayInput>;
  regions_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  rep_scores?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumRoleFieldUpdateOperationsInput>;
  routings?: InputMaybe<RoutingUpdateManyWithoutRepInput>;
  sale_config?: InputMaybe<SaleConfigUpdateManyWithoutCreated_ByInput>;
  sale_config_updated?: InputMaybe<SaleConfigUpdateManyWithoutLast_Updated_ByInput>;
  sales_team_column_options?: InputMaybe<UserUpdatesales_Team_Column_OptionsInput>;
  schedule_items?: InputMaybe<ScheduleItemUpdateManyWithoutUserInput>;
  send_transfer_attempts?: InputMaybe<TransferAttemptUpdateManyWithoutFrom_UserInput>;
  sender_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutSender_UserInput>;
  sequence_entry_criteria_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SequenceActionLog?: InputMaybe<StepActionLogUpdateManyWithoutRepInput>;
  sequenceDashboardView?: InputMaybe<SequenceDashboardViewUpdateManyWithoutOwned_ByInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutUserInput>;
  Sequences_created?: InputMaybe<SequenceUpdateManyWithoutCreated_ByInput>;
  Sequences_modified_by_user?: InputMaybe<SequenceUpdateManyWithoutLast_Updated_ByInput>;
  session_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  set_user_cycle?: InputMaybe<SaleCycleUpdateManyWithoutSet_UserInput>;
  set_user_stat?: InputMaybe<StatLeadAggregationUpdateManyWithoutSet_UserInput>;
  sf_user_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SmsItems?: InputMaybe<SmsItemUpdateManyWithoutRepInput>;
  SnoozeTracking?: InputMaybe<SnoozeTrackingUpdateManyWithoutRepInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  statusHistory?: InputMaybe<UserStatusHistoryUpdateManyWithoutUserInput>;
  sub_industry_white_list_array?: InputMaybe<UserUpdatesub_Industry_White_List_ArrayInput>;
  sub_industry_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  support_rep?: InputMaybe<BoolFieldUpdateOperationsInput>;
  taking_demos?: InputMaybe<BoolFieldUpdateOperationsInput>;
  team?: InputMaybe<TeamUpdateOneWithoutMembersInput>;
  Template?: InputMaybe<TemplateUpdateManyWithoutLast_Modified_ByInput>;
  TemplateVersion?: InputMaybe<TemplateVersionUpdateManyWithoutCreated_ByInput>;
  timekit_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timekit_type?: InputMaybe<EnumTimekittypeFieldUpdateOperationsInput>;
  timeOffRequest?: InputMaybe<TimeOffRequestUpdateManyWithoutRequesting_UserInput>;
  timezone_raw?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transfer_type?: InputMaybe<EnumTransferTypeFieldUpdateOperationsInput>;
  transfer_user_cycle?: InputMaybe<SaleCycleUpdateManyWithoutTransfer_UserInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user_custom_availability?: InputMaybe<AvailabilityUpdateManyWithoutUserInput>;
  user_past_tokens?: InputMaybe<UserPastTokensUpdateManyWithoutUserInput>;
  visible_all_leads_sm?: InputMaybe<BoolFieldUpdateOperationsInput>;
  visible_call_report_columns?: InputMaybe<UserUpdatevisible_Call_Report_ColumnsInput>;
  visible_call_report_event_columns?: InputMaybe<UserUpdatevisible_Call_Report_Event_ColumnsInput>;
  visible_call_report_pipeline_columns?: InputMaybe<UserUpdatevisible_Call_Report_Pipeline_ColumnsInput>;
  visible_my_leads_columns?: InputMaybe<UserUpdatevisible_My_Leads_ColumnsInput>;
  voicemail_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutFoldersInput = {
  ai_user?: InputMaybe<BoolFieldUpdateOperationsInput>;
  alternate_email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  always_show_calendar_options?: InputMaybe<BoolFieldUpdateOperationsInput>;
  associate_owner_rep_history?: InputMaybe<AssociationHistoryUpdateManyWithoutRepInput>;
  associate_user_history?: InputMaybe<AssociationHistoryUpdateManyWithoutUserInput>;
  auto_transfer_selection_receive_attempts?: InputMaybe<AutoTransferSelectionUpdateManyWithoutFrom_UserInput>;
  auto_transfer_selection_send_attempts?: InputMaybe<AutoTransferSelectionUpdateManyWithoutTo_UserInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptUpdateManyWithoutUserInput>;
  callSharingAsCoach?: InputMaybe<CallSharingUpdateManyWithoutCoachInput>;
  callSharingAsUser?: InputMaybe<CallSharingUpdateManyWithoutReceiver_UserInput>;
  channel?: InputMaybe<NullableEnumChannelfilterFieldUpdateOperationsInput>;
  close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  close_user_cycle?: InputMaybe<SaleCycleUpdateManyWithoutClose_UserInput>;
  close_user_stat?: InputMaybe<StatLeadAggregationUpdateManyWithoutClose_UserInput>;
  commissions?: InputMaybe<CommissionUpdateManyWithoutUserInput>;
  contact_requests?: InputMaybe<ContactRequestUpdateManyWithoutRequesting_UserInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  current_sequence_view?: InputMaybe<SequenceDashboardViewUpdateOneWithoutUserInput>;
  current_team_start_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  custom_field_white_list_array?: InputMaybe<UserCustomFieldFilterUpdateManyWithoutUserInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemUpdateManyWithoutUserInput>;
  daily_lead_claim?: InputMaybe<IntFieldUpdateOperationsInput>;
  dashboard_2_access?: InputMaybe<BoolFieldUpdateOperationsInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewUpdateManyWithoutUserInput>;
  default_audio_input?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  default_audio_output?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  default_availability?: InputMaybe<BoolFieldUpdateOperationsInput>;
  deleted_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  deprecate_old_session?: InputMaybe<BoolFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordUpdateManyWithoutUserInput>;
  favorite_leads?: InputMaybe<LeadUpdateManyWithoutFavorited_By_UsersInput>;
  first_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  folder_permissions?: InputMaybe<FolderPermissionUpdateManyWithoutUserInput>;
  from_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutOld_UserInput>;
  GlobalRouting?: InputMaybe<GlobalRepRoutingUpdateManyWithoutRepInput>;
  google_refresh_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  holds_per_prospect_vs_held_demo_per_dial?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  hook_subscriptions?: InputMaybe<WebHookSubscriptionUpdateManyWithoutUserInput>;
  hubspot_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  idle_owned_leads?: InputMaybe<LeadUpdateManyWithoutSecondary_RepsInput>;
  industry_filter?: InputMaybe<BoolFieldUpdateOperationsInput>;
  industry_white_list_array?: InputMaybe<UserUpdateindustry_White_List_ArrayInput>;
  industry_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  joinMe_access_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  joinMe_refresh_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  last_demo_transferred_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  last_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutUserInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryUpdateManyWithoutUserInput>;
  lead_intents?: InputMaybe<LeadIntentUpdateManyWithoutUserInput>;
  lead_source_filter?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lead_source_white_list_array?: InputMaybe<UserUpdatelead_Source_White_List_ArrayInput>;
  lead_source_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  leads_last_called?: InputMaybe<LeadUpdateManyWithoutLast_Call_Result_RepInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusUpdateManyWithoutUserInput>;
  manager_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  note_item?: InputMaybe<NoteItemUpdateManyWithoutUserInput>;
  notifications?: InputMaybe<NotificationUpdateManyWithoutUserInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutUsersInput>;
  owned_leads?: InputMaybe<LeadUpdateManyWithoutRepInput>;
  panda_doc_access?: InputMaybe<BoolFieldUpdateOperationsInput>;
  password?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  payment_settings?: InputMaybe<OrganizationPaymentSettingsUpdateManyWithoutCreated_ByInput>;
  payment_settings_updated?: InputMaybe<OrganizationPaymentSettingsUpdateManyWithoutLast_Updated_ByInput>;
  phone_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  post?: InputMaybe<PostUpdateManyWithoutAuthorInput>;
  ProductsCreated?: InputMaybe<ProductChangeLogUpdateManyWithoutUpdated_ByInput>;
  profile_image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  receive_transfer_attempts?: InputMaybe<TransferAttemptUpdateManyWithoutTo_UserInput>;
  receiver_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutReceiver_UserInput>;
  regions_white_list_array?: InputMaybe<UserUpdateregions_White_List_ArrayInput>;
  regions_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  rep_scores?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumRoleFieldUpdateOperationsInput>;
  routings?: InputMaybe<RoutingUpdateManyWithoutRepInput>;
  sale_config?: InputMaybe<SaleConfigUpdateManyWithoutCreated_ByInput>;
  sale_config_updated?: InputMaybe<SaleConfigUpdateManyWithoutLast_Updated_ByInput>;
  sales_team_column_options?: InputMaybe<UserUpdatesales_Team_Column_OptionsInput>;
  schedule_items?: InputMaybe<ScheduleItemUpdateManyWithoutUserInput>;
  send_transfer_attempts?: InputMaybe<TransferAttemptUpdateManyWithoutFrom_UserInput>;
  sender_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutSender_UserInput>;
  sequence_entry_criteria_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SequenceActionLog?: InputMaybe<StepActionLogUpdateManyWithoutRepInput>;
  sequenceDashboardView?: InputMaybe<SequenceDashboardViewUpdateManyWithoutOwned_ByInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutUserInput>;
  Sequences_created?: InputMaybe<SequenceUpdateManyWithoutCreated_ByInput>;
  Sequences_modified_by_user?: InputMaybe<SequenceUpdateManyWithoutLast_Updated_ByInput>;
  session_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  set_user_cycle?: InputMaybe<SaleCycleUpdateManyWithoutSet_UserInput>;
  set_user_stat?: InputMaybe<StatLeadAggregationUpdateManyWithoutSet_UserInput>;
  sf_user_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SmsItems?: InputMaybe<SmsItemUpdateManyWithoutRepInput>;
  SnoozeTracking?: InputMaybe<SnoozeTrackingUpdateManyWithoutRepInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  statusHistory?: InputMaybe<UserStatusHistoryUpdateManyWithoutUserInput>;
  sub_industry_white_list_array?: InputMaybe<UserUpdatesub_Industry_White_List_ArrayInput>;
  sub_industry_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  support_rep?: InputMaybe<BoolFieldUpdateOperationsInput>;
  taking_demos?: InputMaybe<BoolFieldUpdateOperationsInput>;
  team?: InputMaybe<TeamUpdateOneWithoutMembersInput>;
  Template?: InputMaybe<TemplateUpdateManyWithoutLast_Modified_ByInput>;
  TemplateVersion?: InputMaybe<TemplateVersionUpdateManyWithoutCreated_ByInput>;
  timekit_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timekit_type?: InputMaybe<EnumTimekittypeFieldUpdateOperationsInput>;
  timeOffRequest?: InputMaybe<TimeOffRequestUpdateManyWithoutRequesting_UserInput>;
  timezone_raw?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transfer_type?: InputMaybe<EnumTransferTypeFieldUpdateOperationsInput>;
  transfer_user_cycle?: InputMaybe<SaleCycleUpdateManyWithoutTransfer_UserInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user_custom_availability?: InputMaybe<AvailabilityUpdateManyWithoutUserInput>;
  user_past_tokens?: InputMaybe<UserPastTokensUpdateManyWithoutUserInput>;
  visible_all_leads_sm?: InputMaybe<BoolFieldUpdateOperationsInput>;
  visible_call_report_columns?: InputMaybe<UserUpdatevisible_Call_Report_ColumnsInput>;
  visible_call_report_event_columns?: InputMaybe<UserUpdatevisible_Call_Report_Event_ColumnsInput>;
  visible_call_report_pipeline_columns?: InputMaybe<UserUpdatevisible_Call_Report_Pipeline_ColumnsInput>;
  visible_my_leads_columns?: InputMaybe<UserUpdatevisible_My_Leads_ColumnsInput>;
  voicemail_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutFrom_Assignment_HistoryInput = {
  ai_user?: InputMaybe<BoolFieldUpdateOperationsInput>;
  alternate_email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  always_show_calendar_options?: InputMaybe<BoolFieldUpdateOperationsInput>;
  associate_owner_rep_history?: InputMaybe<AssociationHistoryUpdateManyWithoutRepInput>;
  associate_user_history?: InputMaybe<AssociationHistoryUpdateManyWithoutUserInput>;
  auto_transfer_selection_receive_attempts?: InputMaybe<AutoTransferSelectionUpdateManyWithoutFrom_UserInput>;
  auto_transfer_selection_send_attempts?: InputMaybe<AutoTransferSelectionUpdateManyWithoutTo_UserInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptUpdateManyWithoutUserInput>;
  callSharingAsCoach?: InputMaybe<CallSharingUpdateManyWithoutCoachInput>;
  callSharingAsUser?: InputMaybe<CallSharingUpdateManyWithoutReceiver_UserInput>;
  channel?: InputMaybe<NullableEnumChannelfilterFieldUpdateOperationsInput>;
  close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  close_user_cycle?: InputMaybe<SaleCycleUpdateManyWithoutClose_UserInput>;
  close_user_stat?: InputMaybe<StatLeadAggregationUpdateManyWithoutClose_UserInput>;
  commissions?: InputMaybe<CommissionUpdateManyWithoutUserInput>;
  contact_requests?: InputMaybe<ContactRequestUpdateManyWithoutRequesting_UserInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  current_sequence_view?: InputMaybe<SequenceDashboardViewUpdateOneWithoutUserInput>;
  current_team_start_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  custom_field_white_list_array?: InputMaybe<UserCustomFieldFilterUpdateManyWithoutUserInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemUpdateManyWithoutUserInput>;
  daily_lead_claim?: InputMaybe<IntFieldUpdateOperationsInput>;
  dashboard_2_access?: InputMaybe<BoolFieldUpdateOperationsInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewUpdateManyWithoutUserInput>;
  default_audio_input?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  default_audio_output?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  default_availability?: InputMaybe<BoolFieldUpdateOperationsInput>;
  deleted_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  deprecate_old_session?: InputMaybe<BoolFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordUpdateManyWithoutUserInput>;
  favorite_leads?: InputMaybe<LeadUpdateManyWithoutFavorited_By_UsersInput>;
  first_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  folder_permissions?: InputMaybe<FolderPermissionUpdateManyWithoutUserInput>;
  folders?: InputMaybe<FolderUpdateManyWithoutOwnerInput>;
  GlobalRouting?: InputMaybe<GlobalRepRoutingUpdateManyWithoutRepInput>;
  google_refresh_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  holds_per_prospect_vs_held_demo_per_dial?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  hook_subscriptions?: InputMaybe<WebHookSubscriptionUpdateManyWithoutUserInput>;
  hubspot_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  idle_owned_leads?: InputMaybe<LeadUpdateManyWithoutSecondary_RepsInput>;
  industry_filter?: InputMaybe<BoolFieldUpdateOperationsInput>;
  industry_white_list_array?: InputMaybe<UserUpdateindustry_White_List_ArrayInput>;
  industry_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  joinMe_access_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  joinMe_refresh_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  last_demo_transferred_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  last_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutUserInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryUpdateManyWithoutUserInput>;
  lead_intents?: InputMaybe<LeadIntentUpdateManyWithoutUserInput>;
  lead_source_filter?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lead_source_white_list_array?: InputMaybe<UserUpdatelead_Source_White_List_ArrayInput>;
  lead_source_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  leads_last_called?: InputMaybe<LeadUpdateManyWithoutLast_Call_Result_RepInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusUpdateManyWithoutUserInput>;
  manager_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  note_item?: InputMaybe<NoteItemUpdateManyWithoutUserInput>;
  notifications?: InputMaybe<NotificationUpdateManyWithoutUserInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutUsersInput>;
  owned_leads?: InputMaybe<LeadUpdateManyWithoutRepInput>;
  panda_doc_access?: InputMaybe<BoolFieldUpdateOperationsInput>;
  password?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  payment_settings?: InputMaybe<OrganizationPaymentSettingsUpdateManyWithoutCreated_ByInput>;
  payment_settings_updated?: InputMaybe<OrganizationPaymentSettingsUpdateManyWithoutLast_Updated_ByInput>;
  phone_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  post?: InputMaybe<PostUpdateManyWithoutAuthorInput>;
  ProductsCreated?: InputMaybe<ProductChangeLogUpdateManyWithoutUpdated_ByInput>;
  profile_image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  receive_transfer_attempts?: InputMaybe<TransferAttemptUpdateManyWithoutTo_UserInput>;
  receiver_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutReceiver_UserInput>;
  regions_white_list_array?: InputMaybe<UserUpdateregions_White_List_ArrayInput>;
  regions_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  rep_scores?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumRoleFieldUpdateOperationsInput>;
  routings?: InputMaybe<RoutingUpdateManyWithoutRepInput>;
  sale_config?: InputMaybe<SaleConfigUpdateManyWithoutCreated_ByInput>;
  sale_config_updated?: InputMaybe<SaleConfigUpdateManyWithoutLast_Updated_ByInput>;
  sales_team_column_options?: InputMaybe<UserUpdatesales_Team_Column_OptionsInput>;
  schedule_items?: InputMaybe<ScheduleItemUpdateManyWithoutUserInput>;
  send_transfer_attempts?: InputMaybe<TransferAttemptUpdateManyWithoutFrom_UserInput>;
  sender_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutSender_UserInput>;
  sequence_entry_criteria_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SequenceActionLog?: InputMaybe<StepActionLogUpdateManyWithoutRepInput>;
  sequenceDashboardView?: InputMaybe<SequenceDashboardViewUpdateManyWithoutOwned_ByInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutUserInput>;
  Sequences_created?: InputMaybe<SequenceUpdateManyWithoutCreated_ByInput>;
  Sequences_modified_by_user?: InputMaybe<SequenceUpdateManyWithoutLast_Updated_ByInput>;
  session_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  set_user_cycle?: InputMaybe<SaleCycleUpdateManyWithoutSet_UserInput>;
  set_user_stat?: InputMaybe<StatLeadAggregationUpdateManyWithoutSet_UserInput>;
  sf_user_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SmsItems?: InputMaybe<SmsItemUpdateManyWithoutRepInput>;
  SnoozeTracking?: InputMaybe<SnoozeTrackingUpdateManyWithoutRepInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  statusHistory?: InputMaybe<UserStatusHistoryUpdateManyWithoutUserInput>;
  sub_industry_white_list_array?: InputMaybe<UserUpdatesub_Industry_White_List_ArrayInput>;
  sub_industry_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  support_rep?: InputMaybe<BoolFieldUpdateOperationsInput>;
  taking_demos?: InputMaybe<BoolFieldUpdateOperationsInput>;
  team?: InputMaybe<TeamUpdateOneWithoutMembersInput>;
  Template?: InputMaybe<TemplateUpdateManyWithoutLast_Modified_ByInput>;
  TemplateVersion?: InputMaybe<TemplateVersionUpdateManyWithoutCreated_ByInput>;
  timekit_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timekit_type?: InputMaybe<EnumTimekittypeFieldUpdateOperationsInput>;
  timeOffRequest?: InputMaybe<TimeOffRequestUpdateManyWithoutRequesting_UserInput>;
  timezone_raw?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transfer_type?: InputMaybe<EnumTransferTypeFieldUpdateOperationsInput>;
  transfer_user_cycle?: InputMaybe<SaleCycleUpdateManyWithoutTransfer_UserInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user_custom_availability?: InputMaybe<AvailabilityUpdateManyWithoutUserInput>;
  user_past_tokens?: InputMaybe<UserPastTokensUpdateManyWithoutUserInput>;
  visible_all_leads_sm?: InputMaybe<BoolFieldUpdateOperationsInput>;
  visible_call_report_columns?: InputMaybe<UserUpdatevisible_Call_Report_ColumnsInput>;
  visible_call_report_event_columns?: InputMaybe<UserUpdatevisible_Call_Report_Event_ColumnsInput>;
  visible_call_report_pipeline_columns?: InputMaybe<UserUpdatevisible_Call_Report_Pipeline_ColumnsInput>;
  visible_my_leads_columns?: InputMaybe<UserUpdatevisible_My_Leads_ColumnsInput>;
  voicemail_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutGlobalRoutingInput = {
  ai_user?: InputMaybe<BoolFieldUpdateOperationsInput>;
  alternate_email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  always_show_calendar_options?: InputMaybe<BoolFieldUpdateOperationsInput>;
  associate_owner_rep_history?: InputMaybe<AssociationHistoryUpdateManyWithoutRepInput>;
  associate_user_history?: InputMaybe<AssociationHistoryUpdateManyWithoutUserInput>;
  auto_transfer_selection_receive_attempts?: InputMaybe<AutoTransferSelectionUpdateManyWithoutFrom_UserInput>;
  auto_transfer_selection_send_attempts?: InputMaybe<AutoTransferSelectionUpdateManyWithoutTo_UserInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptUpdateManyWithoutUserInput>;
  callSharingAsCoach?: InputMaybe<CallSharingUpdateManyWithoutCoachInput>;
  callSharingAsUser?: InputMaybe<CallSharingUpdateManyWithoutReceiver_UserInput>;
  channel?: InputMaybe<NullableEnumChannelfilterFieldUpdateOperationsInput>;
  close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  close_user_cycle?: InputMaybe<SaleCycleUpdateManyWithoutClose_UserInput>;
  close_user_stat?: InputMaybe<StatLeadAggregationUpdateManyWithoutClose_UserInput>;
  commissions?: InputMaybe<CommissionUpdateManyWithoutUserInput>;
  contact_requests?: InputMaybe<ContactRequestUpdateManyWithoutRequesting_UserInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  current_sequence_view?: InputMaybe<SequenceDashboardViewUpdateOneWithoutUserInput>;
  current_team_start_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  custom_field_white_list_array?: InputMaybe<UserCustomFieldFilterUpdateManyWithoutUserInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemUpdateManyWithoutUserInput>;
  daily_lead_claim?: InputMaybe<IntFieldUpdateOperationsInput>;
  dashboard_2_access?: InputMaybe<BoolFieldUpdateOperationsInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewUpdateManyWithoutUserInput>;
  default_audio_input?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  default_audio_output?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  default_availability?: InputMaybe<BoolFieldUpdateOperationsInput>;
  deleted_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  deprecate_old_session?: InputMaybe<BoolFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordUpdateManyWithoutUserInput>;
  favorite_leads?: InputMaybe<LeadUpdateManyWithoutFavorited_By_UsersInput>;
  first_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  folder_permissions?: InputMaybe<FolderPermissionUpdateManyWithoutUserInput>;
  folders?: InputMaybe<FolderUpdateManyWithoutOwnerInput>;
  from_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutOld_UserInput>;
  google_refresh_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  holds_per_prospect_vs_held_demo_per_dial?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  hook_subscriptions?: InputMaybe<WebHookSubscriptionUpdateManyWithoutUserInput>;
  hubspot_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  idle_owned_leads?: InputMaybe<LeadUpdateManyWithoutSecondary_RepsInput>;
  industry_filter?: InputMaybe<BoolFieldUpdateOperationsInput>;
  industry_white_list_array?: InputMaybe<UserUpdateindustry_White_List_ArrayInput>;
  industry_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  joinMe_access_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  joinMe_refresh_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  last_demo_transferred_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  last_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutUserInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryUpdateManyWithoutUserInput>;
  lead_intents?: InputMaybe<LeadIntentUpdateManyWithoutUserInput>;
  lead_source_filter?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lead_source_white_list_array?: InputMaybe<UserUpdatelead_Source_White_List_ArrayInput>;
  lead_source_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  leads_last_called?: InputMaybe<LeadUpdateManyWithoutLast_Call_Result_RepInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusUpdateManyWithoutUserInput>;
  manager_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  note_item?: InputMaybe<NoteItemUpdateManyWithoutUserInput>;
  notifications?: InputMaybe<NotificationUpdateManyWithoutUserInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutUsersInput>;
  owned_leads?: InputMaybe<LeadUpdateManyWithoutRepInput>;
  panda_doc_access?: InputMaybe<BoolFieldUpdateOperationsInput>;
  password?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  payment_settings?: InputMaybe<OrganizationPaymentSettingsUpdateManyWithoutCreated_ByInput>;
  payment_settings_updated?: InputMaybe<OrganizationPaymentSettingsUpdateManyWithoutLast_Updated_ByInput>;
  phone_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  post?: InputMaybe<PostUpdateManyWithoutAuthorInput>;
  ProductsCreated?: InputMaybe<ProductChangeLogUpdateManyWithoutUpdated_ByInput>;
  profile_image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  receive_transfer_attempts?: InputMaybe<TransferAttemptUpdateManyWithoutTo_UserInput>;
  receiver_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutReceiver_UserInput>;
  regions_white_list_array?: InputMaybe<UserUpdateregions_White_List_ArrayInput>;
  regions_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  rep_scores?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumRoleFieldUpdateOperationsInput>;
  routings?: InputMaybe<RoutingUpdateManyWithoutRepInput>;
  sale_config?: InputMaybe<SaleConfigUpdateManyWithoutCreated_ByInput>;
  sale_config_updated?: InputMaybe<SaleConfigUpdateManyWithoutLast_Updated_ByInput>;
  sales_team_column_options?: InputMaybe<UserUpdatesales_Team_Column_OptionsInput>;
  schedule_items?: InputMaybe<ScheduleItemUpdateManyWithoutUserInput>;
  send_transfer_attempts?: InputMaybe<TransferAttemptUpdateManyWithoutFrom_UserInput>;
  sender_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutSender_UserInput>;
  sequence_entry_criteria_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SequenceActionLog?: InputMaybe<StepActionLogUpdateManyWithoutRepInput>;
  sequenceDashboardView?: InputMaybe<SequenceDashboardViewUpdateManyWithoutOwned_ByInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutUserInput>;
  Sequences_created?: InputMaybe<SequenceUpdateManyWithoutCreated_ByInput>;
  Sequences_modified_by_user?: InputMaybe<SequenceUpdateManyWithoutLast_Updated_ByInput>;
  session_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  set_user_cycle?: InputMaybe<SaleCycleUpdateManyWithoutSet_UserInput>;
  set_user_stat?: InputMaybe<StatLeadAggregationUpdateManyWithoutSet_UserInput>;
  sf_user_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SmsItems?: InputMaybe<SmsItemUpdateManyWithoutRepInput>;
  SnoozeTracking?: InputMaybe<SnoozeTrackingUpdateManyWithoutRepInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  statusHistory?: InputMaybe<UserStatusHistoryUpdateManyWithoutUserInput>;
  sub_industry_white_list_array?: InputMaybe<UserUpdatesub_Industry_White_List_ArrayInput>;
  sub_industry_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  support_rep?: InputMaybe<BoolFieldUpdateOperationsInput>;
  taking_demos?: InputMaybe<BoolFieldUpdateOperationsInput>;
  team?: InputMaybe<TeamUpdateOneWithoutMembersInput>;
  Template?: InputMaybe<TemplateUpdateManyWithoutLast_Modified_ByInput>;
  TemplateVersion?: InputMaybe<TemplateVersionUpdateManyWithoutCreated_ByInput>;
  timekit_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timekit_type?: InputMaybe<EnumTimekittypeFieldUpdateOperationsInput>;
  timeOffRequest?: InputMaybe<TimeOffRequestUpdateManyWithoutRequesting_UserInput>;
  timezone_raw?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transfer_type?: InputMaybe<EnumTransferTypeFieldUpdateOperationsInput>;
  transfer_user_cycle?: InputMaybe<SaleCycleUpdateManyWithoutTransfer_UserInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user_custom_availability?: InputMaybe<AvailabilityUpdateManyWithoutUserInput>;
  user_past_tokens?: InputMaybe<UserPastTokensUpdateManyWithoutUserInput>;
  visible_all_leads_sm?: InputMaybe<BoolFieldUpdateOperationsInput>;
  visible_call_report_columns?: InputMaybe<UserUpdatevisible_Call_Report_ColumnsInput>;
  visible_call_report_event_columns?: InputMaybe<UserUpdatevisible_Call_Report_Event_ColumnsInput>;
  visible_call_report_pipeline_columns?: InputMaybe<UserUpdatevisible_Call_Report_Pipeline_ColumnsInput>;
  visible_my_leads_columns?: InputMaybe<UserUpdatevisible_My_Leads_ColumnsInput>;
  voicemail_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutIdle_Owned_LeadsInput = {
  ai_user?: InputMaybe<BoolFieldUpdateOperationsInput>;
  alternate_email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  always_show_calendar_options?: InputMaybe<BoolFieldUpdateOperationsInput>;
  associate_owner_rep_history?: InputMaybe<AssociationHistoryUpdateManyWithoutRepInput>;
  associate_user_history?: InputMaybe<AssociationHistoryUpdateManyWithoutUserInput>;
  auto_transfer_selection_receive_attempts?: InputMaybe<AutoTransferSelectionUpdateManyWithoutFrom_UserInput>;
  auto_transfer_selection_send_attempts?: InputMaybe<AutoTransferSelectionUpdateManyWithoutTo_UserInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptUpdateManyWithoutUserInput>;
  callSharingAsCoach?: InputMaybe<CallSharingUpdateManyWithoutCoachInput>;
  callSharingAsUser?: InputMaybe<CallSharingUpdateManyWithoutReceiver_UserInput>;
  channel?: InputMaybe<NullableEnumChannelfilterFieldUpdateOperationsInput>;
  close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  close_user_cycle?: InputMaybe<SaleCycleUpdateManyWithoutClose_UserInput>;
  close_user_stat?: InputMaybe<StatLeadAggregationUpdateManyWithoutClose_UserInput>;
  commissions?: InputMaybe<CommissionUpdateManyWithoutUserInput>;
  contact_requests?: InputMaybe<ContactRequestUpdateManyWithoutRequesting_UserInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  current_sequence_view?: InputMaybe<SequenceDashboardViewUpdateOneWithoutUserInput>;
  current_team_start_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  custom_field_white_list_array?: InputMaybe<UserCustomFieldFilterUpdateManyWithoutUserInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemUpdateManyWithoutUserInput>;
  daily_lead_claim?: InputMaybe<IntFieldUpdateOperationsInput>;
  dashboard_2_access?: InputMaybe<BoolFieldUpdateOperationsInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewUpdateManyWithoutUserInput>;
  default_audio_input?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  default_audio_output?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  default_availability?: InputMaybe<BoolFieldUpdateOperationsInput>;
  deleted_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  deprecate_old_session?: InputMaybe<BoolFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordUpdateManyWithoutUserInput>;
  favorite_leads?: InputMaybe<LeadUpdateManyWithoutFavorited_By_UsersInput>;
  first_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  folder_permissions?: InputMaybe<FolderPermissionUpdateManyWithoutUserInput>;
  folders?: InputMaybe<FolderUpdateManyWithoutOwnerInput>;
  from_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutOld_UserInput>;
  GlobalRouting?: InputMaybe<GlobalRepRoutingUpdateManyWithoutRepInput>;
  google_refresh_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  holds_per_prospect_vs_held_demo_per_dial?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  hook_subscriptions?: InputMaybe<WebHookSubscriptionUpdateManyWithoutUserInput>;
  hubspot_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  industry_filter?: InputMaybe<BoolFieldUpdateOperationsInput>;
  industry_white_list_array?: InputMaybe<UserUpdateindustry_White_List_ArrayInput>;
  industry_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  joinMe_access_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  joinMe_refresh_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  last_demo_transferred_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  last_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutUserInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryUpdateManyWithoutUserInput>;
  lead_intents?: InputMaybe<LeadIntentUpdateManyWithoutUserInput>;
  lead_source_filter?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lead_source_white_list_array?: InputMaybe<UserUpdatelead_Source_White_List_ArrayInput>;
  lead_source_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  leads_last_called?: InputMaybe<LeadUpdateManyWithoutLast_Call_Result_RepInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusUpdateManyWithoutUserInput>;
  manager_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  note_item?: InputMaybe<NoteItemUpdateManyWithoutUserInput>;
  notifications?: InputMaybe<NotificationUpdateManyWithoutUserInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutUsersInput>;
  owned_leads?: InputMaybe<LeadUpdateManyWithoutRepInput>;
  panda_doc_access?: InputMaybe<BoolFieldUpdateOperationsInput>;
  password?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  payment_settings?: InputMaybe<OrganizationPaymentSettingsUpdateManyWithoutCreated_ByInput>;
  payment_settings_updated?: InputMaybe<OrganizationPaymentSettingsUpdateManyWithoutLast_Updated_ByInput>;
  phone_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  post?: InputMaybe<PostUpdateManyWithoutAuthorInput>;
  ProductsCreated?: InputMaybe<ProductChangeLogUpdateManyWithoutUpdated_ByInput>;
  profile_image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  receive_transfer_attempts?: InputMaybe<TransferAttemptUpdateManyWithoutTo_UserInput>;
  receiver_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutReceiver_UserInput>;
  regions_white_list_array?: InputMaybe<UserUpdateregions_White_List_ArrayInput>;
  regions_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  rep_scores?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumRoleFieldUpdateOperationsInput>;
  routings?: InputMaybe<RoutingUpdateManyWithoutRepInput>;
  sale_config?: InputMaybe<SaleConfigUpdateManyWithoutCreated_ByInput>;
  sale_config_updated?: InputMaybe<SaleConfigUpdateManyWithoutLast_Updated_ByInput>;
  sales_team_column_options?: InputMaybe<UserUpdatesales_Team_Column_OptionsInput>;
  schedule_items?: InputMaybe<ScheduleItemUpdateManyWithoutUserInput>;
  send_transfer_attempts?: InputMaybe<TransferAttemptUpdateManyWithoutFrom_UserInput>;
  sender_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutSender_UserInput>;
  sequence_entry_criteria_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SequenceActionLog?: InputMaybe<StepActionLogUpdateManyWithoutRepInput>;
  sequenceDashboardView?: InputMaybe<SequenceDashboardViewUpdateManyWithoutOwned_ByInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutUserInput>;
  Sequences_created?: InputMaybe<SequenceUpdateManyWithoutCreated_ByInput>;
  Sequences_modified_by_user?: InputMaybe<SequenceUpdateManyWithoutLast_Updated_ByInput>;
  session_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  set_user_cycle?: InputMaybe<SaleCycleUpdateManyWithoutSet_UserInput>;
  set_user_stat?: InputMaybe<StatLeadAggregationUpdateManyWithoutSet_UserInput>;
  sf_user_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SmsItems?: InputMaybe<SmsItemUpdateManyWithoutRepInput>;
  SnoozeTracking?: InputMaybe<SnoozeTrackingUpdateManyWithoutRepInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  statusHistory?: InputMaybe<UserStatusHistoryUpdateManyWithoutUserInput>;
  sub_industry_white_list_array?: InputMaybe<UserUpdatesub_Industry_White_List_ArrayInput>;
  sub_industry_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  support_rep?: InputMaybe<BoolFieldUpdateOperationsInput>;
  taking_demos?: InputMaybe<BoolFieldUpdateOperationsInput>;
  team?: InputMaybe<TeamUpdateOneWithoutMembersInput>;
  Template?: InputMaybe<TemplateUpdateManyWithoutLast_Modified_ByInput>;
  TemplateVersion?: InputMaybe<TemplateVersionUpdateManyWithoutCreated_ByInput>;
  timekit_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timekit_type?: InputMaybe<EnumTimekittypeFieldUpdateOperationsInput>;
  timeOffRequest?: InputMaybe<TimeOffRequestUpdateManyWithoutRequesting_UserInput>;
  timezone_raw?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transfer_type?: InputMaybe<EnumTransferTypeFieldUpdateOperationsInput>;
  transfer_user_cycle?: InputMaybe<SaleCycleUpdateManyWithoutTransfer_UserInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user_custom_availability?: InputMaybe<AvailabilityUpdateManyWithoutUserInput>;
  user_past_tokens?: InputMaybe<UserPastTokensUpdateManyWithoutUserInput>;
  visible_all_leads_sm?: InputMaybe<BoolFieldUpdateOperationsInput>;
  visible_call_report_columns?: InputMaybe<UserUpdatevisible_Call_Report_ColumnsInput>;
  visible_call_report_event_columns?: InputMaybe<UserUpdatevisible_Call_Report_Event_ColumnsInput>;
  visible_call_report_pipeline_columns?: InputMaybe<UserUpdatevisible_Call_Report_Pipeline_ColumnsInput>;
  visible_my_leads_columns?: InputMaybe<UserUpdatevisible_My_Leads_ColumnsInput>;
  voicemail_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutLead_ActivitiesInput = {
  ai_user?: InputMaybe<BoolFieldUpdateOperationsInput>;
  alternate_email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  always_show_calendar_options?: InputMaybe<BoolFieldUpdateOperationsInput>;
  associate_owner_rep_history?: InputMaybe<AssociationHistoryUpdateManyWithoutRepInput>;
  associate_user_history?: InputMaybe<AssociationHistoryUpdateManyWithoutUserInput>;
  auto_transfer_selection_receive_attempts?: InputMaybe<AutoTransferSelectionUpdateManyWithoutFrom_UserInput>;
  auto_transfer_selection_send_attempts?: InputMaybe<AutoTransferSelectionUpdateManyWithoutTo_UserInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptUpdateManyWithoutUserInput>;
  callSharingAsCoach?: InputMaybe<CallSharingUpdateManyWithoutCoachInput>;
  callSharingAsUser?: InputMaybe<CallSharingUpdateManyWithoutReceiver_UserInput>;
  channel?: InputMaybe<NullableEnumChannelfilterFieldUpdateOperationsInput>;
  close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  close_user_cycle?: InputMaybe<SaleCycleUpdateManyWithoutClose_UserInput>;
  close_user_stat?: InputMaybe<StatLeadAggregationUpdateManyWithoutClose_UserInput>;
  commissions?: InputMaybe<CommissionUpdateManyWithoutUserInput>;
  contact_requests?: InputMaybe<ContactRequestUpdateManyWithoutRequesting_UserInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  current_sequence_view?: InputMaybe<SequenceDashboardViewUpdateOneWithoutUserInput>;
  current_team_start_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  custom_field_white_list_array?: InputMaybe<UserCustomFieldFilterUpdateManyWithoutUserInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemUpdateManyWithoutUserInput>;
  daily_lead_claim?: InputMaybe<IntFieldUpdateOperationsInput>;
  dashboard_2_access?: InputMaybe<BoolFieldUpdateOperationsInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewUpdateManyWithoutUserInput>;
  default_audio_input?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  default_audio_output?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  default_availability?: InputMaybe<BoolFieldUpdateOperationsInput>;
  deleted_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  deprecate_old_session?: InputMaybe<BoolFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordUpdateManyWithoutUserInput>;
  favorite_leads?: InputMaybe<LeadUpdateManyWithoutFavorited_By_UsersInput>;
  first_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  folder_permissions?: InputMaybe<FolderPermissionUpdateManyWithoutUserInput>;
  folders?: InputMaybe<FolderUpdateManyWithoutOwnerInput>;
  from_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutOld_UserInput>;
  GlobalRouting?: InputMaybe<GlobalRepRoutingUpdateManyWithoutRepInput>;
  google_refresh_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  holds_per_prospect_vs_held_demo_per_dial?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  hook_subscriptions?: InputMaybe<WebHookSubscriptionUpdateManyWithoutUserInput>;
  hubspot_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  idle_owned_leads?: InputMaybe<LeadUpdateManyWithoutSecondary_RepsInput>;
  industry_filter?: InputMaybe<BoolFieldUpdateOperationsInput>;
  industry_white_list_array?: InputMaybe<UserUpdateindustry_White_List_ArrayInput>;
  industry_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  joinMe_access_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  joinMe_refresh_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  last_demo_transferred_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  last_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryUpdateManyWithoutUserInput>;
  lead_intents?: InputMaybe<LeadIntentUpdateManyWithoutUserInput>;
  lead_source_filter?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lead_source_white_list_array?: InputMaybe<UserUpdatelead_Source_White_List_ArrayInput>;
  lead_source_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  leads_last_called?: InputMaybe<LeadUpdateManyWithoutLast_Call_Result_RepInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusUpdateManyWithoutUserInput>;
  manager_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  note_item?: InputMaybe<NoteItemUpdateManyWithoutUserInput>;
  notifications?: InputMaybe<NotificationUpdateManyWithoutUserInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutUsersInput>;
  owned_leads?: InputMaybe<LeadUpdateManyWithoutRepInput>;
  panda_doc_access?: InputMaybe<BoolFieldUpdateOperationsInput>;
  password?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  payment_settings?: InputMaybe<OrganizationPaymentSettingsUpdateManyWithoutCreated_ByInput>;
  payment_settings_updated?: InputMaybe<OrganizationPaymentSettingsUpdateManyWithoutLast_Updated_ByInput>;
  phone_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  post?: InputMaybe<PostUpdateManyWithoutAuthorInput>;
  ProductsCreated?: InputMaybe<ProductChangeLogUpdateManyWithoutUpdated_ByInput>;
  profile_image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  receive_transfer_attempts?: InputMaybe<TransferAttemptUpdateManyWithoutTo_UserInput>;
  receiver_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutReceiver_UserInput>;
  regions_white_list_array?: InputMaybe<UserUpdateregions_White_List_ArrayInput>;
  regions_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  rep_scores?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumRoleFieldUpdateOperationsInput>;
  routings?: InputMaybe<RoutingUpdateManyWithoutRepInput>;
  sale_config?: InputMaybe<SaleConfigUpdateManyWithoutCreated_ByInput>;
  sale_config_updated?: InputMaybe<SaleConfigUpdateManyWithoutLast_Updated_ByInput>;
  sales_team_column_options?: InputMaybe<UserUpdatesales_Team_Column_OptionsInput>;
  schedule_items?: InputMaybe<ScheduleItemUpdateManyWithoutUserInput>;
  send_transfer_attempts?: InputMaybe<TransferAttemptUpdateManyWithoutFrom_UserInput>;
  sender_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutSender_UserInput>;
  sequence_entry_criteria_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SequenceActionLog?: InputMaybe<StepActionLogUpdateManyWithoutRepInput>;
  sequenceDashboardView?: InputMaybe<SequenceDashboardViewUpdateManyWithoutOwned_ByInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutUserInput>;
  Sequences_created?: InputMaybe<SequenceUpdateManyWithoutCreated_ByInput>;
  Sequences_modified_by_user?: InputMaybe<SequenceUpdateManyWithoutLast_Updated_ByInput>;
  session_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  set_user_cycle?: InputMaybe<SaleCycleUpdateManyWithoutSet_UserInput>;
  set_user_stat?: InputMaybe<StatLeadAggregationUpdateManyWithoutSet_UserInput>;
  sf_user_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SmsItems?: InputMaybe<SmsItemUpdateManyWithoutRepInput>;
  SnoozeTracking?: InputMaybe<SnoozeTrackingUpdateManyWithoutRepInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  statusHistory?: InputMaybe<UserStatusHistoryUpdateManyWithoutUserInput>;
  sub_industry_white_list_array?: InputMaybe<UserUpdatesub_Industry_White_List_ArrayInput>;
  sub_industry_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  support_rep?: InputMaybe<BoolFieldUpdateOperationsInput>;
  taking_demos?: InputMaybe<BoolFieldUpdateOperationsInput>;
  team?: InputMaybe<TeamUpdateOneWithoutMembersInput>;
  Template?: InputMaybe<TemplateUpdateManyWithoutLast_Modified_ByInput>;
  TemplateVersion?: InputMaybe<TemplateVersionUpdateManyWithoutCreated_ByInput>;
  timekit_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timekit_type?: InputMaybe<EnumTimekittypeFieldUpdateOperationsInput>;
  timeOffRequest?: InputMaybe<TimeOffRequestUpdateManyWithoutRequesting_UserInput>;
  timezone_raw?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transfer_type?: InputMaybe<EnumTransferTypeFieldUpdateOperationsInput>;
  transfer_user_cycle?: InputMaybe<SaleCycleUpdateManyWithoutTransfer_UserInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user_custom_availability?: InputMaybe<AvailabilityUpdateManyWithoutUserInput>;
  user_past_tokens?: InputMaybe<UserPastTokensUpdateManyWithoutUserInput>;
  visible_all_leads_sm?: InputMaybe<BoolFieldUpdateOperationsInput>;
  visible_call_report_columns?: InputMaybe<UserUpdatevisible_Call_Report_ColumnsInput>;
  visible_call_report_event_columns?: InputMaybe<UserUpdatevisible_Call_Report_Event_ColumnsInput>;
  visible_call_report_pipeline_columns?: InputMaybe<UserUpdatevisible_Call_Report_Pipeline_ColumnsInput>;
  visible_my_leads_columns?: InputMaybe<UserUpdatevisible_My_Leads_ColumnsInput>;
  voicemail_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutLead_Import_HistoryInput = {
  ai_user?: InputMaybe<BoolFieldUpdateOperationsInput>;
  alternate_email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  always_show_calendar_options?: InputMaybe<BoolFieldUpdateOperationsInput>;
  associate_owner_rep_history?: InputMaybe<AssociationHistoryUpdateManyWithoutRepInput>;
  associate_user_history?: InputMaybe<AssociationHistoryUpdateManyWithoutUserInput>;
  auto_transfer_selection_receive_attempts?: InputMaybe<AutoTransferSelectionUpdateManyWithoutFrom_UserInput>;
  auto_transfer_selection_send_attempts?: InputMaybe<AutoTransferSelectionUpdateManyWithoutTo_UserInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptUpdateManyWithoutUserInput>;
  callSharingAsCoach?: InputMaybe<CallSharingUpdateManyWithoutCoachInput>;
  callSharingAsUser?: InputMaybe<CallSharingUpdateManyWithoutReceiver_UserInput>;
  channel?: InputMaybe<NullableEnumChannelfilterFieldUpdateOperationsInput>;
  close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  close_user_cycle?: InputMaybe<SaleCycleUpdateManyWithoutClose_UserInput>;
  close_user_stat?: InputMaybe<StatLeadAggregationUpdateManyWithoutClose_UserInput>;
  commissions?: InputMaybe<CommissionUpdateManyWithoutUserInput>;
  contact_requests?: InputMaybe<ContactRequestUpdateManyWithoutRequesting_UserInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  current_sequence_view?: InputMaybe<SequenceDashboardViewUpdateOneWithoutUserInput>;
  current_team_start_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  custom_field_white_list_array?: InputMaybe<UserCustomFieldFilterUpdateManyWithoutUserInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemUpdateManyWithoutUserInput>;
  daily_lead_claim?: InputMaybe<IntFieldUpdateOperationsInput>;
  dashboard_2_access?: InputMaybe<BoolFieldUpdateOperationsInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewUpdateManyWithoutUserInput>;
  default_audio_input?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  default_audio_output?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  default_availability?: InputMaybe<BoolFieldUpdateOperationsInput>;
  deleted_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  deprecate_old_session?: InputMaybe<BoolFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordUpdateManyWithoutUserInput>;
  favorite_leads?: InputMaybe<LeadUpdateManyWithoutFavorited_By_UsersInput>;
  first_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  folder_permissions?: InputMaybe<FolderPermissionUpdateManyWithoutUserInput>;
  folders?: InputMaybe<FolderUpdateManyWithoutOwnerInput>;
  from_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutOld_UserInput>;
  GlobalRouting?: InputMaybe<GlobalRepRoutingUpdateManyWithoutRepInput>;
  google_refresh_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  holds_per_prospect_vs_held_demo_per_dial?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  hook_subscriptions?: InputMaybe<WebHookSubscriptionUpdateManyWithoutUserInput>;
  hubspot_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  idle_owned_leads?: InputMaybe<LeadUpdateManyWithoutSecondary_RepsInput>;
  industry_filter?: InputMaybe<BoolFieldUpdateOperationsInput>;
  industry_white_list_array?: InputMaybe<UserUpdateindustry_White_List_ArrayInput>;
  industry_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  joinMe_access_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  joinMe_refresh_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  last_demo_transferred_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  last_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutUserInput>;
  lead_intents?: InputMaybe<LeadIntentUpdateManyWithoutUserInput>;
  lead_source_filter?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lead_source_white_list_array?: InputMaybe<UserUpdatelead_Source_White_List_ArrayInput>;
  lead_source_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  leads_last_called?: InputMaybe<LeadUpdateManyWithoutLast_Call_Result_RepInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusUpdateManyWithoutUserInput>;
  manager_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  note_item?: InputMaybe<NoteItemUpdateManyWithoutUserInput>;
  notifications?: InputMaybe<NotificationUpdateManyWithoutUserInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutUsersInput>;
  owned_leads?: InputMaybe<LeadUpdateManyWithoutRepInput>;
  panda_doc_access?: InputMaybe<BoolFieldUpdateOperationsInput>;
  password?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  payment_settings?: InputMaybe<OrganizationPaymentSettingsUpdateManyWithoutCreated_ByInput>;
  payment_settings_updated?: InputMaybe<OrganizationPaymentSettingsUpdateManyWithoutLast_Updated_ByInput>;
  phone_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  post?: InputMaybe<PostUpdateManyWithoutAuthorInput>;
  ProductsCreated?: InputMaybe<ProductChangeLogUpdateManyWithoutUpdated_ByInput>;
  profile_image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  receive_transfer_attempts?: InputMaybe<TransferAttemptUpdateManyWithoutTo_UserInput>;
  receiver_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutReceiver_UserInput>;
  regions_white_list_array?: InputMaybe<UserUpdateregions_White_List_ArrayInput>;
  regions_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  rep_scores?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumRoleFieldUpdateOperationsInput>;
  routings?: InputMaybe<RoutingUpdateManyWithoutRepInput>;
  sale_config?: InputMaybe<SaleConfigUpdateManyWithoutCreated_ByInput>;
  sale_config_updated?: InputMaybe<SaleConfigUpdateManyWithoutLast_Updated_ByInput>;
  sales_team_column_options?: InputMaybe<UserUpdatesales_Team_Column_OptionsInput>;
  schedule_items?: InputMaybe<ScheduleItemUpdateManyWithoutUserInput>;
  send_transfer_attempts?: InputMaybe<TransferAttemptUpdateManyWithoutFrom_UserInput>;
  sender_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutSender_UserInput>;
  sequence_entry_criteria_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SequenceActionLog?: InputMaybe<StepActionLogUpdateManyWithoutRepInput>;
  sequenceDashboardView?: InputMaybe<SequenceDashboardViewUpdateManyWithoutOwned_ByInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutUserInput>;
  Sequences_created?: InputMaybe<SequenceUpdateManyWithoutCreated_ByInput>;
  Sequences_modified_by_user?: InputMaybe<SequenceUpdateManyWithoutLast_Updated_ByInput>;
  session_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  set_user_cycle?: InputMaybe<SaleCycleUpdateManyWithoutSet_UserInput>;
  set_user_stat?: InputMaybe<StatLeadAggregationUpdateManyWithoutSet_UserInput>;
  sf_user_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SmsItems?: InputMaybe<SmsItemUpdateManyWithoutRepInput>;
  SnoozeTracking?: InputMaybe<SnoozeTrackingUpdateManyWithoutRepInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  statusHistory?: InputMaybe<UserStatusHistoryUpdateManyWithoutUserInput>;
  sub_industry_white_list_array?: InputMaybe<UserUpdatesub_Industry_White_List_ArrayInput>;
  sub_industry_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  support_rep?: InputMaybe<BoolFieldUpdateOperationsInput>;
  taking_demos?: InputMaybe<BoolFieldUpdateOperationsInput>;
  team?: InputMaybe<TeamUpdateOneWithoutMembersInput>;
  Template?: InputMaybe<TemplateUpdateManyWithoutLast_Modified_ByInput>;
  TemplateVersion?: InputMaybe<TemplateVersionUpdateManyWithoutCreated_ByInput>;
  timekit_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timekit_type?: InputMaybe<EnumTimekittypeFieldUpdateOperationsInput>;
  timeOffRequest?: InputMaybe<TimeOffRequestUpdateManyWithoutRequesting_UserInput>;
  timezone_raw?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transfer_type?: InputMaybe<EnumTransferTypeFieldUpdateOperationsInput>;
  transfer_user_cycle?: InputMaybe<SaleCycleUpdateManyWithoutTransfer_UserInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user_custom_availability?: InputMaybe<AvailabilityUpdateManyWithoutUserInput>;
  user_past_tokens?: InputMaybe<UserPastTokensUpdateManyWithoutUserInput>;
  visible_all_leads_sm?: InputMaybe<BoolFieldUpdateOperationsInput>;
  visible_call_report_columns?: InputMaybe<UserUpdatevisible_Call_Report_ColumnsInput>;
  visible_call_report_event_columns?: InputMaybe<UserUpdatevisible_Call_Report_Event_ColumnsInput>;
  visible_call_report_pipeline_columns?: InputMaybe<UserUpdatevisible_Call_Report_Pipeline_ColumnsInput>;
  visible_my_leads_columns?: InputMaybe<UserUpdatevisible_My_Leads_ColumnsInput>;
  voicemail_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutLead_IntentsInput = {
  ai_user?: InputMaybe<BoolFieldUpdateOperationsInput>;
  alternate_email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  always_show_calendar_options?: InputMaybe<BoolFieldUpdateOperationsInput>;
  associate_owner_rep_history?: InputMaybe<AssociationHistoryUpdateManyWithoutRepInput>;
  associate_user_history?: InputMaybe<AssociationHistoryUpdateManyWithoutUserInput>;
  auto_transfer_selection_receive_attempts?: InputMaybe<AutoTransferSelectionUpdateManyWithoutFrom_UserInput>;
  auto_transfer_selection_send_attempts?: InputMaybe<AutoTransferSelectionUpdateManyWithoutTo_UserInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptUpdateManyWithoutUserInput>;
  callSharingAsCoach?: InputMaybe<CallSharingUpdateManyWithoutCoachInput>;
  callSharingAsUser?: InputMaybe<CallSharingUpdateManyWithoutReceiver_UserInput>;
  channel?: InputMaybe<NullableEnumChannelfilterFieldUpdateOperationsInput>;
  close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  close_user_cycle?: InputMaybe<SaleCycleUpdateManyWithoutClose_UserInput>;
  close_user_stat?: InputMaybe<StatLeadAggregationUpdateManyWithoutClose_UserInput>;
  commissions?: InputMaybe<CommissionUpdateManyWithoutUserInput>;
  contact_requests?: InputMaybe<ContactRequestUpdateManyWithoutRequesting_UserInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  current_sequence_view?: InputMaybe<SequenceDashboardViewUpdateOneWithoutUserInput>;
  current_team_start_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  custom_field_white_list_array?: InputMaybe<UserCustomFieldFilterUpdateManyWithoutUserInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemUpdateManyWithoutUserInput>;
  daily_lead_claim?: InputMaybe<IntFieldUpdateOperationsInput>;
  dashboard_2_access?: InputMaybe<BoolFieldUpdateOperationsInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewUpdateManyWithoutUserInput>;
  default_audio_input?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  default_audio_output?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  default_availability?: InputMaybe<BoolFieldUpdateOperationsInput>;
  deleted_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  deprecate_old_session?: InputMaybe<BoolFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordUpdateManyWithoutUserInput>;
  favorite_leads?: InputMaybe<LeadUpdateManyWithoutFavorited_By_UsersInput>;
  first_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  folder_permissions?: InputMaybe<FolderPermissionUpdateManyWithoutUserInput>;
  folders?: InputMaybe<FolderUpdateManyWithoutOwnerInput>;
  from_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutOld_UserInput>;
  GlobalRouting?: InputMaybe<GlobalRepRoutingUpdateManyWithoutRepInput>;
  google_refresh_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  holds_per_prospect_vs_held_demo_per_dial?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  hook_subscriptions?: InputMaybe<WebHookSubscriptionUpdateManyWithoutUserInput>;
  hubspot_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  idle_owned_leads?: InputMaybe<LeadUpdateManyWithoutSecondary_RepsInput>;
  industry_filter?: InputMaybe<BoolFieldUpdateOperationsInput>;
  industry_white_list_array?: InputMaybe<UserUpdateindustry_White_List_ArrayInput>;
  industry_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  joinMe_access_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  joinMe_refresh_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  last_demo_transferred_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  last_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutUserInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryUpdateManyWithoutUserInput>;
  lead_source_filter?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lead_source_white_list_array?: InputMaybe<UserUpdatelead_Source_White_List_ArrayInput>;
  lead_source_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  leads_last_called?: InputMaybe<LeadUpdateManyWithoutLast_Call_Result_RepInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusUpdateManyWithoutUserInput>;
  manager_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  note_item?: InputMaybe<NoteItemUpdateManyWithoutUserInput>;
  notifications?: InputMaybe<NotificationUpdateManyWithoutUserInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutUsersInput>;
  owned_leads?: InputMaybe<LeadUpdateManyWithoutRepInput>;
  panda_doc_access?: InputMaybe<BoolFieldUpdateOperationsInput>;
  password?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  payment_settings?: InputMaybe<OrganizationPaymentSettingsUpdateManyWithoutCreated_ByInput>;
  payment_settings_updated?: InputMaybe<OrganizationPaymentSettingsUpdateManyWithoutLast_Updated_ByInput>;
  phone_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  post?: InputMaybe<PostUpdateManyWithoutAuthorInput>;
  ProductsCreated?: InputMaybe<ProductChangeLogUpdateManyWithoutUpdated_ByInput>;
  profile_image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  receive_transfer_attempts?: InputMaybe<TransferAttemptUpdateManyWithoutTo_UserInput>;
  receiver_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutReceiver_UserInput>;
  regions_white_list_array?: InputMaybe<UserUpdateregions_White_List_ArrayInput>;
  regions_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  rep_scores?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumRoleFieldUpdateOperationsInput>;
  routings?: InputMaybe<RoutingUpdateManyWithoutRepInput>;
  sale_config?: InputMaybe<SaleConfigUpdateManyWithoutCreated_ByInput>;
  sale_config_updated?: InputMaybe<SaleConfigUpdateManyWithoutLast_Updated_ByInput>;
  sales_team_column_options?: InputMaybe<UserUpdatesales_Team_Column_OptionsInput>;
  schedule_items?: InputMaybe<ScheduleItemUpdateManyWithoutUserInput>;
  send_transfer_attempts?: InputMaybe<TransferAttemptUpdateManyWithoutFrom_UserInput>;
  sender_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutSender_UserInput>;
  sequence_entry_criteria_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SequenceActionLog?: InputMaybe<StepActionLogUpdateManyWithoutRepInput>;
  sequenceDashboardView?: InputMaybe<SequenceDashboardViewUpdateManyWithoutOwned_ByInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutUserInput>;
  Sequences_created?: InputMaybe<SequenceUpdateManyWithoutCreated_ByInput>;
  Sequences_modified_by_user?: InputMaybe<SequenceUpdateManyWithoutLast_Updated_ByInput>;
  session_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  set_user_cycle?: InputMaybe<SaleCycleUpdateManyWithoutSet_UserInput>;
  set_user_stat?: InputMaybe<StatLeadAggregationUpdateManyWithoutSet_UserInput>;
  sf_user_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SmsItems?: InputMaybe<SmsItemUpdateManyWithoutRepInput>;
  SnoozeTracking?: InputMaybe<SnoozeTrackingUpdateManyWithoutRepInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  statusHistory?: InputMaybe<UserStatusHistoryUpdateManyWithoutUserInput>;
  sub_industry_white_list_array?: InputMaybe<UserUpdatesub_Industry_White_List_ArrayInput>;
  sub_industry_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  support_rep?: InputMaybe<BoolFieldUpdateOperationsInput>;
  taking_demos?: InputMaybe<BoolFieldUpdateOperationsInput>;
  team?: InputMaybe<TeamUpdateOneWithoutMembersInput>;
  Template?: InputMaybe<TemplateUpdateManyWithoutLast_Modified_ByInput>;
  TemplateVersion?: InputMaybe<TemplateVersionUpdateManyWithoutCreated_ByInput>;
  timekit_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timekit_type?: InputMaybe<EnumTimekittypeFieldUpdateOperationsInput>;
  timeOffRequest?: InputMaybe<TimeOffRequestUpdateManyWithoutRequesting_UserInput>;
  timezone_raw?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transfer_type?: InputMaybe<EnumTransferTypeFieldUpdateOperationsInput>;
  transfer_user_cycle?: InputMaybe<SaleCycleUpdateManyWithoutTransfer_UserInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user_custom_availability?: InputMaybe<AvailabilityUpdateManyWithoutUserInput>;
  user_past_tokens?: InputMaybe<UserPastTokensUpdateManyWithoutUserInput>;
  visible_all_leads_sm?: InputMaybe<BoolFieldUpdateOperationsInput>;
  visible_call_report_columns?: InputMaybe<UserUpdatevisible_Call_Report_ColumnsInput>;
  visible_call_report_event_columns?: InputMaybe<UserUpdatevisible_Call_Report_Event_ColumnsInput>;
  visible_call_report_pipeline_columns?: InputMaybe<UserUpdatevisible_Call_Report_Pipeline_ColumnsInput>;
  visible_my_leads_columns?: InputMaybe<UserUpdatevisible_My_Leads_ColumnsInput>;
  voicemail_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutLeads_Last_CalledInput = {
  ai_user?: InputMaybe<BoolFieldUpdateOperationsInput>;
  alternate_email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  always_show_calendar_options?: InputMaybe<BoolFieldUpdateOperationsInput>;
  associate_owner_rep_history?: InputMaybe<AssociationHistoryUpdateManyWithoutRepInput>;
  associate_user_history?: InputMaybe<AssociationHistoryUpdateManyWithoutUserInput>;
  auto_transfer_selection_receive_attempts?: InputMaybe<AutoTransferSelectionUpdateManyWithoutFrom_UserInput>;
  auto_transfer_selection_send_attempts?: InputMaybe<AutoTransferSelectionUpdateManyWithoutTo_UserInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptUpdateManyWithoutUserInput>;
  callSharingAsCoach?: InputMaybe<CallSharingUpdateManyWithoutCoachInput>;
  callSharingAsUser?: InputMaybe<CallSharingUpdateManyWithoutReceiver_UserInput>;
  channel?: InputMaybe<NullableEnumChannelfilterFieldUpdateOperationsInput>;
  close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  close_user_cycle?: InputMaybe<SaleCycleUpdateManyWithoutClose_UserInput>;
  close_user_stat?: InputMaybe<StatLeadAggregationUpdateManyWithoutClose_UserInput>;
  commissions?: InputMaybe<CommissionUpdateManyWithoutUserInput>;
  contact_requests?: InputMaybe<ContactRequestUpdateManyWithoutRequesting_UserInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  current_sequence_view?: InputMaybe<SequenceDashboardViewUpdateOneWithoutUserInput>;
  current_team_start_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  custom_field_white_list_array?: InputMaybe<UserCustomFieldFilterUpdateManyWithoutUserInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemUpdateManyWithoutUserInput>;
  daily_lead_claim?: InputMaybe<IntFieldUpdateOperationsInput>;
  dashboard_2_access?: InputMaybe<BoolFieldUpdateOperationsInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewUpdateManyWithoutUserInput>;
  default_audio_input?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  default_audio_output?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  default_availability?: InputMaybe<BoolFieldUpdateOperationsInput>;
  deleted_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  deprecate_old_session?: InputMaybe<BoolFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordUpdateManyWithoutUserInput>;
  favorite_leads?: InputMaybe<LeadUpdateManyWithoutFavorited_By_UsersInput>;
  first_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  folder_permissions?: InputMaybe<FolderPermissionUpdateManyWithoutUserInput>;
  folders?: InputMaybe<FolderUpdateManyWithoutOwnerInput>;
  from_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutOld_UserInput>;
  GlobalRouting?: InputMaybe<GlobalRepRoutingUpdateManyWithoutRepInput>;
  google_refresh_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  holds_per_prospect_vs_held_demo_per_dial?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  hook_subscriptions?: InputMaybe<WebHookSubscriptionUpdateManyWithoutUserInput>;
  hubspot_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  idle_owned_leads?: InputMaybe<LeadUpdateManyWithoutSecondary_RepsInput>;
  industry_filter?: InputMaybe<BoolFieldUpdateOperationsInput>;
  industry_white_list_array?: InputMaybe<UserUpdateindustry_White_List_ArrayInput>;
  industry_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  joinMe_access_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  joinMe_refresh_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  last_demo_transferred_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  last_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutUserInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryUpdateManyWithoutUserInput>;
  lead_intents?: InputMaybe<LeadIntentUpdateManyWithoutUserInput>;
  lead_source_filter?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lead_source_white_list_array?: InputMaybe<UserUpdatelead_Source_White_List_ArrayInput>;
  lead_source_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusUpdateManyWithoutUserInput>;
  manager_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  note_item?: InputMaybe<NoteItemUpdateManyWithoutUserInput>;
  notifications?: InputMaybe<NotificationUpdateManyWithoutUserInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutUsersInput>;
  owned_leads?: InputMaybe<LeadUpdateManyWithoutRepInput>;
  panda_doc_access?: InputMaybe<BoolFieldUpdateOperationsInput>;
  password?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  payment_settings?: InputMaybe<OrganizationPaymentSettingsUpdateManyWithoutCreated_ByInput>;
  payment_settings_updated?: InputMaybe<OrganizationPaymentSettingsUpdateManyWithoutLast_Updated_ByInput>;
  phone_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  post?: InputMaybe<PostUpdateManyWithoutAuthorInput>;
  ProductsCreated?: InputMaybe<ProductChangeLogUpdateManyWithoutUpdated_ByInput>;
  profile_image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  receive_transfer_attempts?: InputMaybe<TransferAttemptUpdateManyWithoutTo_UserInput>;
  receiver_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutReceiver_UserInput>;
  regions_white_list_array?: InputMaybe<UserUpdateregions_White_List_ArrayInput>;
  regions_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  rep_scores?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumRoleFieldUpdateOperationsInput>;
  routings?: InputMaybe<RoutingUpdateManyWithoutRepInput>;
  sale_config?: InputMaybe<SaleConfigUpdateManyWithoutCreated_ByInput>;
  sale_config_updated?: InputMaybe<SaleConfigUpdateManyWithoutLast_Updated_ByInput>;
  sales_team_column_options?: InputMaybe<UserUpdatesales_Team_Column_OptionsInput>;
  schedule_items?: InputMaybe<ScheduleItemUpdateManyWithoutUserInput>;
  send_transfer_attempts?: InputMaybe<TransferAttemptUpdateManyWithoutFrom_UserInput>;
  sender_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutSender_UserInput>;
  sequence_entry_criteria_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SequenceActionLog?: InputMaybe<StepActionLogUpdateManyWithoutRepInput>;
  sequenceDashboardView?: InputMaybe<SequenceDashboardViewUpdateManyWithoutOwned_ByInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutUserInput>;
  Sequences_created?: InputMaybe<SequenceUpdateManyWithoutCreated_ByInput>;
  Sequences_modified_by_user?: InputMaybe<SequenceUpdateManyWithoutLast_Updated_ByInput>;
  session_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  set_user_cycle?: InputMaybe<SaleCycleUpdateManyWithoutSet_UserInput>;
  set_user_stat?: InputMaybe<StatLeadAggregationUpdateManyWithoutSet_UserInput>;
  sf_user_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SmsItems?: InputMaybe<SmsItemUpdateManyWithoutRepInput>;
  SnoozeTracking?: InputMaybe<SnoozeTrackingUpdateManyWithoutRepInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  statusHistory?: InputMaybe<UserStatusHistoryUpdateManyWithoutUserInput>;
  sub_industry_white_list_array?: InputMaybe<UserUpdatesub_Industry_White_List_ArrayInput>;
  sub_industry_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  support_rep?: InputMaybe<BoolFieldUpdateOperationsInput>;
  taking_demos?: InputMaybe<BoolFieldUpdateOperationsInput>;
  team?: InputMaybe<TeamUpdateOneWithoutMembersInput>;
  Template?: InputMaybe<TemplateUpdateManyWithoutLast_Modified_ByInput>;
  TemplateVersion?: InputMaybe<TemplateVersionUpdateManyWithoutCreated_ByInput>;
  timekit_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timekit_type?: InputMaybe<EnumTimekittypeFieldUpdateOperationsInput>;
  timeOffRequest?: InputMaybe<TimeOffRequestUpdateManyWithoutRequesting_UserInput>;
  timezone_raw?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transfer_type?: InputMaybe<EnumTransferTypeFieldUpdateOperationsInput>;
  transfer_user_cycle?: InputMaybe<SaleCycleUpdateManyWithoutTransfer_UserInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user_custom_availability?: InputMaybe<AvailabilityUpdateManyWithoutUserInput>;
  user_past_tokens?: InputMaybe<UserPastTokensUpdateManyWithoutUserInput>;
  visible_all_leads_sm?: InputMaybe<BoolFieldUpdateOperationsInput>;
  visible_call_report_columns?: InputMaybe<UserUpdatevisible_Call_Report_ColumnsInput>;
  visible_call_report_event_columns?: InputMaybe<UserUpdatevisible_Call_Report_Event_ColumnsInput>;
  visible_call_report_pipeline_columns?: InputMaybe<UserUpdatevisible_Call_Report_Pipeline_ColumnsInput>;
  visible_my_leads_columns?: InputMaybe<UserUpdatevisible_My_Leads_ColumnsInput>;
  voicemail_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutLiveUserStatusInput = {
  ai_user?: InputMaybe<BoolFieldUpdateOperationsInput>;
  alternate_email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  always_show_calendar_options?: InputMaybe<BoolFieldUpdateOperationsInput>;
  associate_owner_rep_history?: InputMaybe<AssociationHistoryUpdateManyWithoutRepInput>;
  associate_user_history?: InputMaybe<AssociationHistoryUpdateManyWithoutUserInput>;
  auto_transfer_selection_receive_attempts?: InputMaybe<AutoTransferSelectionUpdateManyWithoutFrom_UserInput>;
  auto_transfer_selection_send_attempts?: InputMaybe<AutoTransferSelectionUpdateManyWithoutTo_UserInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptUpdateManyWithoutUserInput>;
  callSharingAsCoach?: InputMaybe<CallSharingUpdateManyWithoutCoachInput>;
  callSharingAsUser?: InputMaybe<CallSharingUpdateManyWithoutReceiver_UserInput>;
  channel?: InputMaybe<NullableEnumChannelfilterFieldUpdateOperationsInput>;
  close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  close_user_cycle?: InputMaybe<SaleCycleUpdateManyWithoutClose_UserInput>;
  close_user_stat?: InputMaybe<StatLeadAggregationUpdateManyWithoutClose_UserInput>;
  commissions?: InputMaybe<CommissionUpdateManyWithoutUserInput>;
  contact_requests?: InputMaybe<ContactRequestUpdateManyWithoutRequesting_UserInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  current_sequence_view?: InputMaybe<SequenceDashboardViewUpdateOneWithoutUserInput>;
  current_team_start_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  custom_field_white_list_array?: InputMaybe<UserCustomFieldFilterUpdateManyWithoutUserInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemUpdateManyWithoutUserInput>;
  daily_lead_claim?: InputMaybe<IntFieldUpdateOperationsInput>;
  dashboard_2_access?: InputMaybe<BoolFieldUpdateOperationsInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewUpdateManyWithoutUserInput>;
  default_audio_input?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  default_audio_output?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  default_availability?: InputMaybe<BoolFieldUpdateOperationsInput>;
  deleted_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  deprecate_old_session?: InputMaybe<BoolFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordUpdateManyWithoutUserInput>;
  favorite_leads?: InputMaybe<LeadUpdateManyWithoutFavorited_By_UsersInput>;
  first_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  folder_permissions?: InputMaybe<FolderPermissionUpdateManyWithoutUserInput>;
  folders?: InputMaybe<FolderUpdateManyWithoutOwnerInput>;
  from_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutOld_UserInput>;
  GlobalRouting?: InputMaybe<GlobalRepRoutingUpdateManyWithoutRepInput>;
  google_refresh_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  holds_per_prospect_vs_held_demo_per_dial?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  hook_subscriptions?: InputMaybe<WebHookSubscriptionUpdateManyWithoutUserInput>;
  hubspot_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  idle_owned_leads?: InputMaybe<LeadUpdateManyWithoutSecondary_RepsInput>;
  industry_filter?: InputMaybe<BoolFieldUpdateOperationsInput>;
  industry_white_list_array?: InputMaybe<UserUpdateindustry_White_List_ArrayInput>;
  industry_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  joinMe_access_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  joinMe_refresh_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  last_demo_transferred_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  last_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutUserInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryUpdateManyWithoutUserInput>;
  lead_intents?: InputMaybe<LeadIntentUpdateManyWithoutUserInput>;
  lead_source_filter?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lead_source_white_list_array?: InputMaybe<UserUpdatelead_Source_White_List_ArrayInput>;
  lead_source_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  leads_last_called?: InputMaybe<LeadUpdateManyWithoutLast_Call_Result_RepInput>;
  manager_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  note_item?: InputMaybe<NoteItemUpdateManyWithoutUserInput>;
  notifications?: InputMaybe<NotificationUpdateManyWithoutUserInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutUsersInput>;
  owned_leads?: InputMaybe<LeadUpdateManyWithoutRepInput>;
  panda_doc_access?: InputMaybe<BoolFieldUpdateOperationsInput>;
  password?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  payment_settings?: InputMaybe<OrganizationPaymentSettingsUpdateManyWithoutCreated_ByInput>;
  payment_settings_updated?: InputMaybe<OrganizationPaymentSettingsUpdateManyWithoutLast_Updated_ByInput>;
  phone_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  post?: InputMaybe<PostUpdateManyWithoutAuthorInput>;
  ProductsCreated?: InputMaybe<ProductChangeLogUpdateManyWithoutUpdated_ByInput>;
  profile_image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  receive_transfer_attempts?: InputMaybe<TransferAttemptUpdateManyWithoutTo_UserInput>;
  receiver_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutReceiver_UserInput>;
  regions_white_list_array?: InputMaybe<UserUpdateregions_White_List_ArrayInput>;
  regions_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  rep_scores?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumRoleFieldUpdateOperationsInput>;
  routings?: InputMaybe<RoutingUpdateManyWithoutRepInput>;
  sale_config?: InputMaybe<SaleConfigUpdateManyWithoutCreated_ByInput>;
  sale_config_updated?: InputMaybe<SaleConfigUpdateManyWithoutLast_Updated_ByInput>;
  sales_team_column_options?: InputMaybe<UserUpdatesales_Team_Column_OptionsInput>;
  schedule_items?: InputMaybe<ScheduleItemUpdateManyWithoutUserInput>;
  send_transfer_attempts?: InputMaybe<TransferAttemptUpdateManyWithoutFrom_UserInput>;
  sender_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutSender_UserInput>;
  sequence_entry_criteria_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SequenceActionLog?: InputMaybe<StepActionLogUpdateManyWithoutRepInput>;
  sequenceDashboardView?: InputMaybe<SequenceDashboardViewUpdateManyWithoutOwned_ByInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutUserInput>;
  Sequences_created?: InputMaybe<SequenceUpdateManyWithoutCreated_ByInput>;
  Sequences_modified_by_user?: InputMaybe<SequenceUpdateManyWithoutLast_Updated_ByInput>;
  session_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  set_user_cycle?: InputMaybe<SaleCycleUpdateManyWithoutSet_UserInput>;
  set_user_stat?: InputMaybe<StatLeadAggregationUpdateManyWithoutSet_UserInput>;
  sf_user_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SmsItems?: InputMaybe<SmsItemUpdateManyWithoutRepInput>;
  SnoozeTracking?: InputMaybe<SnoozeTrackingUpdateManyWithoutRepInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  statusHistory?: InputMaybe<UserStatusHistoryUpdateManyWithoutUserInput>;
  sub_industry_white_list_array?: InputMaybe<UserUpdatesub_Industry_White_List_ArrayInput>;
  sub_industry_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  support_rep?: InputMaybe<BoolFieldUpdateOperationsInput>;
  taking_demos?: InputMaybe<BoolFieldUpdateOperationsInput>;
  team?: InputMaybe<TeamUpdateOneWithoutMembersInput>;
  Template?: InputMaybe<TemplateUpdateManyWithoutLast_Modified_ByInput>;
  TemplateVersion?: InputMaybe<TemplateVersionUpdateManyWithoutCreated_ByInput>;
  timekit_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timekit_type?: InputMaybe<EnumTimekittypeFieldUpdateOperationsInput>;
  timeOffRequest?: InputMaybe<TimeOffRequestUpdateManyWithoutRequesting_UserInput>;
  timezone_raw?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transfer_type?: InputMaybe<EnumTransferTypeFieldUpdateOperationsInput>;
  transfer_user_cycle?: InputMaybe<SaleCycleUpdateManyWithoutTransfer_UserInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user_custom_availability?: InputMaybe<AvailabilityUpdateManyWithoutUserInput>;
  user_past_tokens?: InputMaybe<UserPastTokensUpdateManyWithoutUserInput>;
  visible_all_leads_sm?: InputMaybe<BoolFieldUpdateOperationsInput>;
  visible_call_report_columns?: InputMaybe<UserUpdatevisible_Call_Report_ColumnsInput>;
  visible_call_report_event_columns?: InputMaybe<UserUpdatevisible_Call_Report_Event_ColumnsInput>;
  visible_call_report_pipeline_columns?: InputMaybe<UserUpdatevisible_Call_Report_Pipeline_ColumnsInput>;
  visible_my_leads_columns?: InputMaybe<UserUpdatevisible_My_Leads_ColumnsInput>;
  voicemail_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutNote_ItemInput = {
  ai_user?: InputMaybe<BoolFieldUpdateOperationsInput>;
  alternate_email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  always_show_calendar_options?: InputMaybe<BoolFieldUpdateOperationsInput>;
  associate_owner_rep_history?: InputMaybe<AssociationHistoryUpdateManyWithoutRepInput>;
  associate_user_history?: InputMaybe<AssociationHistoryUpdateManyWithoutUserInput>;
  auto_transfer_selection_receive_attempts?: InputMaybe<AutoTransferSelectionUpdateManyWithoutFrom_UserInput>;
  auto_transfer_selection_send_attempts?: InputMaybe<AutoTransferSelectionUpdateManyWithoutTo_UserInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptUpdateManyWithoutUserInput>;
  callSharingAsCoach?: InputMaybe<CallSharingUpdateManyWithoutCoachInput>;
  callSharingAsUser?: InputMaybe<CallSharingUpdateManyWithoutReceiver_UserInput>;
  channel?: InputMaybe<NullableEnumChannelfilterFieldUpdateOperationsInput>;
  close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  close_user_cycle?: InputMaybe<SaleCycleUpdateManyWithoutClose_UserInput>;
  close_user_stat?: InputMaybe<StatLeadAggregationUpdateManyWithoutClose_UserInput>;
  commissions?: InputMaybe<CommissionUpdateManyWithoutUserInput>;
  contact_requests?: InputMaybe<ContactRequestUpdateManyWithoutRequesting_UserInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  current_sequence_view?: InputMaybe<SequenceDashboardViewUpdateOneWithoutUserInput>;
  current_team_start_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  custom_field_white_list_array?: InputMaybe<UserCustomFieldFilterUpdateManyWithoutUserInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemUpdateManyWithoutUserInput>;
  daily_lead_claim?: InputMaybe<IntFieldUpdateOperationsInput>;
  dashboard_2_access?: InputMaybe<BoolFieldUpdateOperationsInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewUpdateManyWithoutUserInput>;
  default_audio_input?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  default_audio_output?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  default_availability?: InputMaybe<BoolFieldUpdateOperationsInput>;
  deleted_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  deprecate_old_session?: InputMaybe<BoolFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordUpdateManyWithoutUserInput>;
  favorite_leads?: InputMaybe<LeadUpdateManyWithoutFavorited_By_UsersInput>;
  first_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  folder_permissions?: InputMaybe<FolderPermissionUpdateManyWithoutUserInput>;
  folders?: InputMaybe<FolderUpdateManyWithoutOwnerInput>;
  from_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutOld_UserInput>;
  GlobalRouting?: InputMaybe<GlobalRepRoutingUpdateManyWithoutRepInput>;
  google_refresh_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  holds_per_prospect_vs_held_demo_per_dial?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  hook_subscriptions?: InputMaybe<WebHookSubscriptionUpdateManyWithoutUserInput>;
  hubspot_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  idle_owned_leads?: InputMaybe<LeadUpdateManyWithoutSecondary_RepsInput>;
  industry_filter?: InputMaybe<BoolFieldUpdateOperationsInput>;
  industry_white_list_array?: InputMaybe<UserUpdateindustry_White_List_ArrayInput>;
  industry_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  joinMe_access_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  joinMe_refresh_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  last_demo_transferred_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  last_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutUserInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryUpdateManyWithoutUserInput>;
  lead_intents?: InputMaybe<LeadIntentUpdateManyWithoutUserInput>;
  lead_source_filter?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lead_source_white_list_array?: InputMaybe<UserUpdatelead_Source_White_List_ArrayInput>;
  lead_source_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  leads_last_called?: InputMaybe<LeadUpdateManyWithoutLast_Call_Result_RepInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusUpdateManyWithoutUserInput>;
  manager_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  notifications?: InputMaybe<NotificationUpdateManyWithoutUserInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutUsersInput>;
  owned_leads?: InputMaybe<LeadUpdateManyWithoutRepInput>;
  panda_doc_access?: InputMaybe<BoolFieldUpdateOperationsInput>;
  password?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  payment_settings?: InputMaybe<OrganizationPaymentSettingsUpdateManyWithoutCreated_ByInput>;
  payment_settings_updated?: InputMaybe<OrganizationPaymentSettingsUpdateManyWithoutLast_Updated_ByInput>;
  phone_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  post?: InputMaybe<PostUpdateManyWithoutAuthorInput>;
  ProductsCreated?: InputMaybe<ProductChangeLogUpdateManyWithoutUpdated_ByInput>;
  profile_image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  receive_transfer_attempts?: InputMaybe<TransferAttemptUpdateManyWithoutTo_UserInput>;
  receiver_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutReceiver_UserInput>;
  regions_white_list_array?: InputMaybe<UserUpdateregions_White_List_ArrayInput>;
  regions_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  rep_scores?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumRoleFieldUpdateOperationsInput>;
  routings?: InputMaybe<RoutingUpdateManyWithoutRepInput>;
  sale_config?: InputMaybe<SaleConfigUpdateManyWithoutCreated_ByInput>;
  sale_config_updated?: InputMaybe<SaleConfigUpdateManyWithoutLast_Updated_ByInput>;
  sales_team_column_options?: InputMaybe<UserUpdatesales_Team_Column_OptionsInput>;
  schedule_items?: InputMaybe<ScheduleItemUpdateManyWithoutUserInput>;
  send_transfer_attempts?: InputMaybe<TransferAttemptUpdateManyWithoutFrom_UserInput>;
  sender_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutSender_UserInput>;
  sequence_entry_criteria_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SequenceActionLog?: InputMaybe<StepActionLogUpdateManyWithoutRepInput>;
  sequenceDashboardView?: InputMaybe<SequenceDashboardViewUpdateManyWithoutOwned_ByInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutUserInput>;
  Sequences_created?: InputMaybe<SequenceUpdateManyWithoutCreated_ByInput>;
  Sequences_modified_by_user?: InputMaybe<SequenceUpdateManyWithoutLast_Updated_ByInput>;
  session_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  set_user_cycle?: InputMaybe<SaleCycleUpdateManyWithoutSet_UserInput>;
  set_user_stat?: InputMaybe<StatLeadAggregationUpdateManyWithoutSet_UserInput>;
  sf_user_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SmsItems?: InputMaybe<SmsItemUpdateManyWithoutRepInput>;
  SnoozeTracking?: InputMaybe<SnoozeTrackingUpdateManyWithoutRepInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  statusHistory?: InputMaybe<UserStatusHistoryUpdateManyWithoutUserInput>;
  sub_industry_white_list_array?: InputMaybe<UserUpdatesub_Industry_White_List_ArrayInput>;
  sub_industry_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  support_rep?: InputMaybe<BoolFieldUpdateOperationsInput>;
  taking_demos?: InputMaybe<BoolFieldUpdateOperationsInput>;
  team?: InputMaybe<TeamUpdateOneWithoutMembersInput>;
  Template?: InputMaybe<TemplateUpdateManyWithoutLast_Modified_ByInput>;
  TemplateVersion?: InputMaybe<TemplateVersionUpdateManyWithoutCreated_ByInput>;
  timekit_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timekit_type?: InputMaybe<EnumTimekittypeFieldUpdateOperationsInput>;
  timeOffRequest?: InputMaybe<TimeOffRequestUpdateManyWithoutRequesting_UserInput>;
  timezone_raw?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transfer_type?: InputMaybe<EnumTransferTypeFieldUpdateOperationsInput>;
  transfer_user_cycle?: InputMaybe<SaleCycleUpdateManyWithoutTransfer_UserInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user_custom_availability?: InputMaybe<AvailabilityUpdateManyWithoutUserInput>;
  user_past_tokens?: InputMaybe<UserPastTokensUpdateManyWithoutUserInput>;
  visible_all_leads_sm?: InputMaybe<BoolFieldUpdateOperationsInput>;
  visible_call_report_columns?: InputMaybe<UserUpdatevisible_Call_Report_ColumnsInput>;
  visible_call_report_event_columns?: InputMaybe<UserUpdatevisible_Call_Report_Event_ColumnsInput>;
  visible_call_report_pipeline_columns?: InputMaybe<UserUpdatevisible_Call_Report_Pipeline_ColumnsInput>;
  visible_my_leads_columns?: InputMaybe<UserUpdatevisible_My_Leads_ColumnsInput>;
  voicemail_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutNotificationsInput = {
  ai_user?: InputMaybe<BoolFieldUpdateOperationsInput>;
  alternate_email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  always_show_calendar_options?: InputMaybe<BoolFieldUpdateOperationsInput>;
  associate_owner_rep_history?: InputMaybe<AssociationHistoryUpdateManyWithoutRepInput>;
  associate_user_history?: InputMaybe<AssociationHistoryUpdateManyWithoutUserInput>;
  auto_transfer_selection_receive_attempts?: InputMaybe<AutoTransferSelectionUpdateManyWithoutFrom_UserInput>;
  auto_transfer_selection_send_attempts?: InputMaybe<AutoTransferSelectionUpdateManyWithoutTo_UserInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptUpdateManyWithoutUserInput>;
  callSharingAsCoach?: InputMaybe<CallSharingUpdateManyWithoutCoachInput>;
  callSharingAsUser?: InputMaybe<CallSharingUpdateManyWithoutReceiver_UserInput>;
  channel?: InputMaybe<NullableEnumChannelfilterFieldUpdateOperationsInput>;
  close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  close_user_cycle?: InputMaybe<SaleCycleUpdateManyWithoutClose_UserInput>;
  close_user_stat?: InputMaybe<StatLeadAggregationUpdateManyWithoutClose_UserInput>;
  commissions?: InputMaybe<CommissionUpdateManyWithoutUserInput>;
  contact_requests?: InputMaybe<ContactRequestUpdateManyWithoutRequesting_UserInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  current_sequence_view?: InputMaybe<SequenceDashboardViewUpdateOneWithoutUserInput>;
  current_team_start_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  custom_field_white_list_array?: InputMaybe<UserCustomFieldFilterUpdateManyWithoutUserInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemUpdateManyWithoutUserInput>;
  daily_lead_claim?: InputMaybe<IntFieldUpdateOperationsInput>;
  dashboard_2_access?: InputMaybe<BoolFieldUpdateOperationsInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewUpdateManyWithoutUserInput>;
  default_audio_input?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  default_audio_output?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  default_availability?: InputMaybe<BoolFieldUpdateOperationsInput>;
  deleted_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  deprecate_old_session?: InputMaybe<BoolFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordUpdateManyWithoutUserInput>;
  favorite_leads?: InputMaybe<LeadUpdateManyWithoutFavorited_By_UsersInput>;
  first_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  folder_permissions?: InputMaybe<FolderPermissionUpdateManyWithoutUserInput>;
  folders?: InputMaybe<FolderUpdateManyWithoutOwnerInput>;
  from_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutOld_UserInput>;
  GlobalRouting?: InputMaybe<GlobalRepRoutingUpdateManyWithoutRepInput>;
  google_refresh_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  holds_per_prospect_vs_held_demo_per_dial?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  hook_subscriptions?: InputMaybe<WebHookSubscriptionUpdateManyWithoutUserInput>;
  hubspot_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  idle_owned_leads?: InputMaybe<LeadUpdateManyWithoutSecondary_RepsInput>;
  industry_filter?: InputMaybe<BoolFieldUpdateOperationsInput>;
  industry_white_list_array?: InputMaybe<UserUpdateindustry_White_List_ArrayInput>;
  industry_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  joinMe_access_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  joinMe_refresh_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  last_demo_transferred_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  last_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutUserInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryUpdateManyWithoutUserInput>;
  lead_intents?: InputMaybe<LeadIntentUpdateManyWithoutUserInput>;
  lead_source_filter?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lead_source_white_list_array?: InputMaybe<UserUpdatelead_Source_White_List_ArrayInput>;
  lead_source_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  leads_last_called?: InputMaybe<LeadUpdateManyWithoutLast_Call_Result_RepInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusUpdateManyWithoutUserInput>;
  manager_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  note_item?: InputMaybe<NoteItemUpdateManyWithoutUserInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutUsersInput>;
  owned_leads?: InputMaybe<LeadUpdateManyWithoutRepInput>;
  panda_doc_access?: InputMaybe<BoolFieldUpdateOperationsInput>;
  password?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  payment_settings?: InputMaybe<OrganizationPaymentSettingsUpdateManyWithoutCreated_ByInput>;
  payment_settings_updated?: InputMaybe<OrganizationPaymentSettingsUpdateManyWithoutLast_Updated_ByInput>;
  phone_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  post?: InputMaybe<PostUpdateManyWithoutAuthorInput>;
  ProductsCreated?: InputMaybe<ProductChangeLogUpdateManyWithoutUpdated_ByInput>;
  profile_image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  receive_transfer_attempts?: InputMaybe<TransferAttemptUpdateManyWithoutTo_UserInput>;
  receiver_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutReceiver_UserInput>;
  regions_white_list_array?: InputMaybe<UserUpdateregions_White_List_ArrayInput>;
  regions_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  rep_scores?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumRoleFieldUpdateOperationsInput>;
  routings?: InputMaybe<RoutingUpdateManyWithoutRepInput>;
  sale_config?: InputMaybe<SaleConfigUpdateManyWithoutCreated_ByInput>;
  sale_config_updated?: InputMaybe<SaleConfigUpdateManyWithoutLast_Updated_ByInput>;
  sales_team_column_options?: InputMaybe<UserUpdatesales_Team_Column_OptionsInput>;
  schedule_items?: InputMaybe<ScheduleItemUpdateManyWithoutUserInput>;
  send_transfer_attempts?: InputMaybe<TransferAttemptUpdateManyWithoutFrom_UserInput>;
  sender_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutSender_UserInput>;
  sequence_entry_criteria_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SequenceActionLog?: InputMaybe<StepActionLogUpdateManyWithoutRepInput>;
  sequenceDashboardView?: InputMaybe<SequenceDashboardViewUpdateManyWithoutOwned_ByInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutUserInput>;
  Sequences_created?: InputMaybe<SequenceUpdateManyWithoutCreated_ByInput>;
  Sequences_modified_by_user?: InputMaybe<SequenceUpdateManyWithoutLast_Updated_ByInput>;
  session_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  set_user_cycle?: InputMaybe<SaleCycleUpdateManyWithoutSet_UserInput>;
  set_user_stat?: InputMaybe<StatLeadAggregationUpdateManyWithoutSet_UserInput>;
  sf_user_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SmsItems?: InputMaybe<SmsItemUpdateManyWithoutRepInput>;
  SnoozeTracking?: InputMaybe<SnoozeTrackingUpdateManyWithoutRepInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  statusHistory?: InputMaybe<UserStatusHistoryUpdateManyWithoutUserInput>;
  sub_industry_white_list_array?: InputMaybe<UserUpdatesub_Industry_White_List_ArrayInput>;
  sub_industry_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  support_rep?: InputMaybe<BoolFieldUpdateOperationsInput>;
  taking_demos?: InputMaybe<BoolFieldUpdateOperationsInput>;
  team?: InputMaybe<TeamUpdateOneWithoutMembersInput>;
  Template?: InputMaybe<TemplateUpdateManyWithoutLast_Modified_ByInput>;
  TemplateVersion?: InputMaybe<TemplateVersionUpdateManyWithoutCreated_ByInput>;
  timekit_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timekit_type?: InputMaybe<EnumTimekittypeFieldUpdateOperationsInput>;
  timeOffRequest?: InputMaybe<TimeOffRequestUpdateManyWithoutRequesting_UserInput>;
  timezone_raw?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transfer_type?: InputMaybe<EnumTransferTypeFieldUpdateOperationsInput>;
  transfer_user_cycle?: InputMaybe<SaleCycleUpdateManyWithoutTransfer_UserInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user_custom_availability?: InputMaybe<AvailabilityUpdateManyWithoutUserInput>;
  user_past_tokens?: InputMaybe<UserPastTokensUpdateManyWithoutUserInput>;
  visible_all_leads_sm?: InputMaybe<BoolFieldUpdateOperationsInput>;
  visible_call_report_columns?: InputMaybe<UserUpdatevisible_Call_Report_ColumnsInput>;
  visible_call_report_event_columns?: InputMaybe<UserUpdatevisible_Call_Report_Event_ColumnsInput>;
  visible_call_report_pipeline_columns?: InputMaybe<UserUpdatevisible_Call_Report_Pipeline_ColumnsInput>;
  visible_my_leads_columns?: InputMaybe<UserUpdatevisible_My_Leads_ColumnsInput>;
  voicemail_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutOrganizationInput = {
  ai_user?: InputMaybe<BoolFieldUpdateOperationsInput>;
  alternate_email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  always_show_calendar_options?: InputMaybe<BoolFieldUpdateOperationsInput>;
  associate_owner_rep_history?: InputMaybe<AssociationHistoryUpdateManyWithoutRepInput>;
  associate_user_history?: InputMaybe<AssociationHistoryUpdateManyWithoutUserInput>;
  auto_transfer_selection_receive_attempts?: InputMaybe<AutoTransferSelectionUpdateManyWithoutFrom_UserInput>;
  auto_transfer_selection_send_attempts?: InputMaybe<AutoTransferSelectionUpdateManyWithoutTo_UserInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptUpdateManyWithoutUserInput>;
  callSharingAsCoach?: InputMaybe<CallSharingUpdateManyWithoutCoachInput>;
  callSharingAsUser?: InputMaybe<CallSharingUpdateManyWithoutReceiver_UserInput>;
  channel?: InputMaybe<NullableEnumChannelfilterFieldUpdateOperationsInput>;
  close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  close_user_cycle?: InputMaybe<SaleCycleUpdateManyWithoutClose_UserInput>;
  close_user_stat?: InputMaybe<StatLeadAggregationUpdateManyWithoutClose_UserInput>;
  commissions?: InputMaybe<CommissionUpdateManyWithoutUserInput>;
  contact_requests?: InputMaybe<ContactRequestUpdateManyWithoutRequesting_UserInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  current_sequence_view?: InputMaybe<SequenceDashboardViewUpdateOneWithoutUserInput>;
  current_team_start_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  custom_field_white_list_array?: InputMaybe<UserCustomFieldFilterUpdateManyWithoutUserInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemUpdateManyWithoutUserInput>;
  daily_lead_claim?: InputMaybe<IntFieldUpdateOperationsInput>;
  dashboard_2_access?: InputMaybe<BoolFieldUpdateOperationsInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewUpdateManyWithoutUserInput>;
  default_audio_input?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  default_audio_output?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  default_availability?: InputMaybe<BoolFieldUpdateOperationsInput>;
  deleted_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  deprecate_old_session?: InputMaybe<BoolFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordUpdateManyWithoutUserInput>;
  favorite_leads?: InputMaybe<LeadUpdateManyWithoutFavorited_By_UsersInput>;
  first_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  folder_permissions?: InputMaybe<FolderPermissionUpdateManyWithoutUserInput>;
  folders?: InputMaybe<FolderUpdateManyWithoutOwnerInput>;
  from_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutOld_UserInput>;
  GlobalRouting?: InputMaybe<GlobalRepRoutingUpdateManyWithoutRepInput>;
  google_refresh_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  holds_per_prospect_vs_held_demo_per_dial?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  hook_subscriptions?: InputMaybe<WebHookSubscriptionUpdateManyWithoutUserInput>;
  hubspot_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  idle_owned_leads?: InputMaybe<LeadUpdateManyWithoutSecondary_RepsInput>;
  industry_filter?: InputMaybe<BoolFieldUpdateOperationsInput>;
  industry_white_list_array?: InputMaybe<UserUpdateindustry_White_List_ArrayInput>;
  industry_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  joinMe_access_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  joinMe_refresh_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  last_demo_transferred_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  last_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutUserInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryUpdateManyWithoutUserInput>;
  lead_intents?: InputMaybe<LeadIntentUpdateManyWithoutUserInput>;
  lead_source_filter?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lead_source_white_list_array?: InputMaybe<UserUpdatelead_Source_White_List_ArrayInput>;
  lead_source_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  leads_last_called?: InputMaybe<LeadUpdateManyWithoutLast_Call_Result_RepInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusUpdateManyWithoutUserInput>;
  manager_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  note_item?: InputMaybe<NoteItemUpdateManyWithoutUserInput>;
  notifications?: InputMaybe<NotificationUpdateManyWithoutUserInput>;
  owned_leads?: InputMaybe<LeadUpdateManyWithoutRepInput>;
  panda_doc_access?: InputMaybe<BoolFieldUpdateOperationsInput>;
  password?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  payment_settings?: InputMaybe<OrganizationPaymentSettingsUpdateManyWithoutCreated_ByInput>;
  payment_settings_updated?: InputMaybe<OrganizationPaymentSettingsUpdateManyWithoutLast_Updated_ByInput>;
  phone_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  post?: InputMaybe<PostUpdateManyWithoutAuthorInput>;
  ProductsCreated?: InputMaybe<ProductChangeLogUpdateManyWithoutUpdated_ByInput>;
  profile_image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  receive_transfer_attempts?: InputMaybe<TransferAttemptUpdateManyWithoutTo_UserInput>;
  receiver_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutReceiver_UserInput>;
  regions_white_list_array?: InputMaybe<UserUpdateregions_White_List_ArrayInput>;
  regions_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  rep_scores?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumRoleFieldUpdateOperationsInput>;
  routings?: InputMaybe<RoutingUpdateManyWithoutRepInput>;
  sale_config?: InputMaybe<SaleConfigUpdateManyWithoutCreated_ByInput>;
  sale_config_updated?: InputMaybe<SaleConfigUpdateManyWithoutLast_Updated_ByInput>;
  sales_team_column_options?: InputMaybe<UserUpdatesales_Team_Column_OptionsInput>;
  schedule_items?: InputMaybe<ScheduleItemUpdateManyWithoutUserInput>;
  send_transfer_attempts?: InputMaybe<TransferAttemptUpdateManyWithoutFrom_UserInput>;
  sender_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutSender_UserInput>;
  sequence_entry_criteria_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SequenceActionLog?: InputMaybe<StepActionLogUpdateManyWithoutRepInput>;
  sequenceDashboardView?: InputMaybe<SequenceDashboardViewUpdateManyWithoutOwned_ByInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutUserInput>;
  Sequences_created?: InputMaybe<SequenceUpdateManyWithoutCreated_ByInput>;
  Sequences_modified_by_user?: InputMaybe<SequenceUpdateManyWithoutLast_Updated_ByInput>;
  session_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  set_user_cycle?: InputMaybe<SaleCycleUpdateManyWithoutSet_UserInput>;
  set_user_stat?: InputMaybe<StatLeadAggregationUpdateManyWithoutSet_UserInput>;
  sf_user_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SmsItems?: InputMaybe<SmsItemUpdateManyWithoutRepInput>;
  SnoozeTracking?: InputMaybe<SnoozeTrackingUpdateManyWithoutRepInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  statusHistory?: InputMaybe<UserStatusHistoryUpdateManyWithoutUserInput>;
  sub_industry_white_list_array?: InputMaybe<UserUpdatesub_Industry_White_List_ArrayInput>;
  sub_industry_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  support_rep?: InputMaybe<BoolFieldUpdateOperationsInput>;
  taking_demos?: InputMaybe<BoolFieldUpdateOperationsInput>;
  team?: InputMaybe<TeamUpdateOneWithoutMembersInput>;
  Template?: InputMaybe<TemplateUpdateManyWithoutLast_Modified_ByInput>;
  TemplateVersion?: InputMaybe<TemplateVersionUpdateManyWithoutCreated_ByInput>;
  timekit_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timekit_type?: InputMaybe<EnumTimekittypeFieldUpdateOperationsInput>;
  timeOffRequest?: InputMaybe<TimeOffRequestUpdateManyWithoutRequesting_UserInput>;
  timezone_raw?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transfer_type?: InputMaybe<EnumTransferTypeFieldUpdateOperationsInput>;
  transfer_user_cycle?: InputMaybe<SaleCycleUpdateManyWithoutTransfer_UserInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user_custom_availability?: InputMaybe<AvailabilityUpdateManyWithoutUserInput>;
  user_past_tokens?: InputMaybe<UserPastTokensUpdateManyWithoutUserInput>;
  visible_all_leads_sm?: InputMaybe<BoolFieldUpdateOperationsInput>;
  visible_call_report_columns?: InputMaybe<UserUpdatevisible_Call_Report_ColumnsInput>;
  visible_call_report_event_columns?: InputMaybe<UserUpdatevisible_Call_Report_Event_ColumnsInput>;
  visible_call_report_pipeline_columns?: InputMaybe<UserUpdatevisible_Call_Report_Pipeline_ColumnsInput>;
  visible_my_leads_columns?: InputMaybe<UserUpdatevisible_My_Leads_ColumnsInput>;
  voicemail_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutOwned_LeadsInput = {
  ai_user?: InputMaybe<BoolFieldUpdateOperationsInput>;
  alternate_email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  always_show_calendar_options?: InputMaybe<BoolFieldUpdateOperationsInput>;
  associate_owner_rep_history?: InputMaybe<AssociationHistoryUpdateManyWithoutRepInput>;
  associate_user_history?: InputMaybe<AssociationHistoryUpdateManyWithoutUserInput>;
  auto_transfer_selection_receive_attempts?: InputMaybe<AutoTransferSelectionUpdateManyWithoutFrom_UserInput>;
  auto_transfer_selection_send_attempts?: InputMaybe<AutoTransferSelectionUpdateManyWithoutTo_UserInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptUpdateManyWithoutUserInput>;
  callSharingAsCoach?: InputMaybe<CallSharingUpdateManyWithoutCoachInput>;
  callSharingAsUser?: InputMaybe<CallSharingUpdateManyWithoutReceiver_UserInput>;
  channel?: InputMaybe<NullableEnumChannelfilterFieldUpdateOperationsInput>;
  close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  close_user_cycle?: InputMaybe<SaleCycleUpdateManyWithoutClose_UserInput>;
  close_user_stat?: InputMaybe<StatLeadAggregationUpdateManyWithoutClose_UserInput>;
  commissions?: InputMaybe<CommissionUpdateManyWithoutUserInput>;
  contact_requests?: InputMaybe<ContactRequestUpdateManyWithoutRequesting_UserInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  current_sequence_view?: InputMaybe<SequenceDashboardViewUpdateOneWithoutUserInput>;
  current_team_start_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  custom_field_white_list_array?: InputMaybe<UserCustomFieldFilterUpdateManyWithoutUserInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemUpdateManyWithoutUserInput>;
  daily_lead_claim?: InputMaybe<IntFieldUpdateOperationsInput>;
  dashboard_2_access?: InputMaybe<BoolFieldUpdateOperationsInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewUpdateManyWithoutUserInput>;
  default_audio_input?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  default_audio_output?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  default_availability?: InputMaybe<BoolFieldUpdateOperationsInput>;
  deleted_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  deprecate_old_session?: InputMaybe<BoolFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordUpdateManyWithoutUserInput>;
  favorite_leads?: InputMaybe<LeadUpdateManyWithoutFavorited_By_UsersInput>;
  first_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  folder_permissions?: InputMaybe<FolderPermissionUpdateManyWithoutUserInput>;
  folders?: InputMaybe<FolderUpdateManyWithoutOwnerInput>;
  from_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutOld_UserInput>;
  GlobalRouting?: InputMaybe<GlobalRepRoutingUpdateManyWithoutRepInput>;
  google_refresh_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  holds_per_prospect_vs_held_demo_per_dial?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  hook_subscriptions?: InputMaybe<WebHookSubscriptionUpdateManyWithoutUserInput>;
  hubspot_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  idle_owned_leads?: InputMaybe<LeadUpdateManyWithoutSecondary_RepsInput>;
  industry_filter?: InputMaybe<BoolFieldUpdateOperationsInput>;
  industry_white_list_array?: InputMaybe<UserUpdateindustry_White_List_ArrayInput>;
  industry_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  joinMe_access_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  joinMe_refresh_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  last_demo_transferred_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  last_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutUserInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryUpdateManyWithoutUserInput>;
  lead_intents?: InputMaybe<LeadIntentUpdateManyWithoutUserInput>;
  lead_source_filter?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lead_source_white_list_array?: InputMaybe<UserUpdatelead_Source_White_List_ArrayInput>;
  lead_source_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  leads_last_called?: InputMaybe<LeadUpdateManyWithoutLast_Call_Result_RepInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusUpdateManyWithoutUserInput>;
  manager_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  note_item?: InputMaybe<NoteItemUpdateManyWithoutUserInput>;
  notifications?: InputMaybe<NotificationUpdateManyWithoutUserInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutUsersInput>;
  panda_doc_access?: InputMaybe<BoolFieldUpdateOperationsInput>;
  password?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  payment_settings?: InputMaybe<OrganizationPaymentSettingsUpdateManyWithoutCreated_ByInput>;
  payment_settings_updated?: InputMaybe<OrganizationPaymentSettingsUpdateManyWithoutLast_Updated_ByInput>;
  phone_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  post?: InputMaybe<PostUpdateManyWithoutAuthorInput>;
  ProductsCreated?: InputMaybe<ProductChangeLogUpdateManyWithoutUpdated_ByInput>;
  profile_image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  receive_transfer_attempts?: InputMaybe<TransferAttemptUpdateManyWithoutTo_UserInput>;
  receiver_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutReceiver_UserInput>;
  regions_white_list_array?: InputMaybe<UserUpdateregions_White_List_ArrayInput>;
  regions_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  rep_scores?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumRoleFieldUpdateOperationsInput>;
  routings?: InputMaybe<RoutingUpdateManyWithoutRepInput>;
  sale_config?: InputMaybe<SaleConfigUpdateManyWithoutCreated_ByInput>;
  sale_config_updated?: InputMaybe<SaleConfigUpdateManyWithoutLast_Updated_ByInput>;
  sales_team_column_options?: InputMaybe<UserUpdatesales_Team_Column_OptionsInput>;
  schedule_items?: InputMaybe<ScheduleItemUpdateManyWithoutUserInput>;
  send_transfer_attempts?: InputMaybe<TransferAttemptUpdateManyWithoutFrom_UserInput>;
  sender_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutSender_UserInput>;
  sequence_entry_criteria_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SequenceActionLog?: InputMaybe<StepActionLogUpdateManyWithoutRepInput>;
  sequenceDashboardView?: InputMaybe<SequenceDashboardViewUpdateManyWithoutOwned_ByInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutUserInput>;
  Sequences_created?: InputMaybe<SequenceUpdateManyWithoutCreated_ByInput>;
  Sequences_modified_by_user?: InputMaybe<SequenceUpdateManyWithoutLast_Updated_ByInput>;
  session_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  set_user_cycle?: InputMaybe<SaleCycleUpdateManyWithoutSet_UserInput>;
  set_user_stat?: InputMaybe<StatLeadAggregationUpdateManyWithoutSet_UserInput>;
  sf_user_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SmsItems?: InputMaybe<SmsItemUpdateManyWithoutRepInput>;
  SnoozeTracking?: InputMaybe<SnoozeTrackingUpdateManyWithoutRepInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  statusHistory?: InputMaybe<UserStatusHistoryUpdateManyWithoutUserInput>;
  sub_industry_white_list_array?: InputMaybe<UserUpdatesub_Industry_White_List_ArrayInput>;
  sub_industry_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  support_rep?: InputMaybe<BoolFieldUpdateOperationsInput>;
  taking_demos?: InputMaybe<BoolFieldUpdateOperationsInput>;
  team?: InputMaybe<TeamUpdateOneWithoutMembersInput>;
  Template?: InputMaybe<TemplateUpdateManyWithoutLast_Modified_ByInput>;
  TemplateVersion?: InputMaybe<TemplateVersionUpdateManyWithoutCreated_ByInput>;
  timekit_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timekit_type?: InputMaybe<EnumTimekittypeFieldUpdateOperationsInput>;
  timeOffRequest?: InputMaybe<TimeOffRequestUpdateManyWithoutRequesting_UserInput>;
  timezone_raw?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transfer_type?: InputMaybe<EnumTransferTypeFieldUpdateOperationsInput>;
  transfer_user_cycle?: InputMaybe<SaleCycleUpdateManyWithoutTransfer_UserInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user_custom_availability?: InputMaybe<AvailabilityUpdateManyWithoutUserInput>;
  user_past_tokens?: InputMaybe<UserPastTokensUpdateManyWithoutUserInput>;
  visible_all_leads_sm?: InputMaybe<BoolFieldUpdateOperationsInput>;
  visible_call_report_columns?: InputMaybe<UserUpdatevisible_Call_Report_ColumnsInput>;
  visible_call_report_event_columns?: InputMaybe<UserUpdatevisible_Call_Report_Event_ColumnsInput>;
  visible_call_report_pipeline_columns?: InputMaybe<UserUpdatevisible_Call_Report_Pipeline_ColumnsInput>;
  visible_my_leads_columns?: InputMaybe<UserUpdatevisible_My_Leads_ColumnsInput>;
  voicemail_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutPayment_Settings_UpdatedInput = {
  ai_user?: InputMaybe<BoolFieldUpdateOperationsInput>;
  alternate_email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  always_show_calendar_options?: InputMaybe<BoolFieldUpdateOperationsInput>;
  associate_owner_rep_history?: InputMaybe<AssociationHistoryUpdateManyWithoutRepInput>;
  associate_user_history?: InputMaybe<AssociationHistoryUpdateManyWithoutUserInput>;
  auto_transfer_selection_receive_attempts?: InputMaybe<AutoTransferSelectionUpdateManyWithoutFrom_UserInput>;
  auto_transfer_selection_send_attempts?: InputMaybe<AutoTransferSelectionUpdateManyWithoutTo_UserInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptUpdateManyWithoutUserInput>;
  callSharingAsCoach?: InputMaybe<CallSharingUpdateManyWithoutCoachInput>;
  callSharingAsUser?: InputMaybe<CallSharingUpdateManyWithoutReceiver_UserInput>;
  channel?: InputMaybe<NullableEnumChannelfilterFieldUpdateOperationsInput>;
  close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  close_user_cycle?: InputMaybe<SaleCycleUpdateManyWithoutClose_UserInput>;
  close_user_stat?: InputMaybe<StatLeadAggregationUpdateManyWithoutClose_UserInput>;
  commissions?: InputMaybe<CommissionUpdateManyWithoutUserInput>;
  contact_requests?: InputMaybe<ContactRequestUpdateManyWithoutRequesting_UserInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  current_sequence_view?: InputMaybe<SequenceDashboardViewUpdateOneWithoutUserInput>;
  current_team_start_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  custom_field_white_list_array?: InputMaybe<UserCustomFieldFilterUpdateManyWithoutUserInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemUpdateManyWithoutUserInput>;
  daily_lead_claim?: InputMaybe<IntFieldUpdateOperationsInput>;
  dashboard_2_access?: InputMaybe<BoolFieldUpdateOperationsInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewUpdateManyWithoutUserInput>;
  default_audio_input?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  default_audio_output?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  default_availability?: InputMaybe<BoolFieldUpdateOperationsInput>;
  deleted_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  deprecate_old_session?: InputMaybe<BoolFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordUpdateManyWithoutUserInput>;
  favorite_leads?: InputMaybe<LeadUpdateManyWithoutFavorited_By_UsersInput>;
  first_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  folder_permissions?: InputMaybe<FolderPermissionUpdateManyWithoutUserInput>;
  folders?: InputMaybe<FolderUpdateManyWithoutOwnerInput>;
  from_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutOld_UserInput>;
  GlobalRouting?: InputMaybe<GlobalRepRoutingUpdateManyWithoutRepInput>;
  google_refresh_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  holds_per_prospect_vs_held_demo_per_dial?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  hook_subscriptions?: InputMaybe<WebHookSubscriptionUpdateManyWithoutUserInput>;
  hubspot_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  idle_owned_leads?: InputMaybe<LeadUpdateManyWithoutSecondary_RepsInput>;
  industry_filter?: InputMaybe<BoolFieldUpdateOperationsInput>;
  industry_white_list_array?: InputMaybe<UserUpdateindustry_White_List_ArrayInput>;
  industry_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  joinMe_access_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  joinMe_refresh_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  last_demo_transferred_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  last_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutUserInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryUpdateManyWithoutUserInput>;
  lead_intents?: InputMaybe<LeadIntentUpdateManyWithoutUserInput>;
  lead_source_filter?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lead_source_white_list_array?: InputMaybe<UserUpdatelead_Source_White_List_ArrayInput>;
  lead_source_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  leads_last_called?: InputMaybe<LeadUpdateManyWithoutLast_Call_Result_RepInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusUpdateManyWithoutUserInput>;
  manager_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  note_item?: InputMaybe<NoteItemUpdateManyWithoutUserInput>;
  notifications?: InputMaybe<NotificationUpdateManyWithoutUserInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutUsersInput>;
  owned_leads?: InputMaybe<LeadUpdateManyWithoutRepInput>;
  panda_doc_access?: InputMaybe<BoolFieldUpdateOperationsInput>;
  password?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  payment_settings?: InputMaybe<OrganizationPaymentSettingsUpdateManyWithoutCreated_ByInput>;
  phone_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  post?: InputMaybe<PostUpdateManyWithoutAuthorInput>;
  ProductsCreated?: InputMaybe<ProductChangeLogUpdateManyWithoutUpdated_ByInput>;
  profile_image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  receive_transfer_attempts?: InputMaybe<TransferAttemptUpdateManyWithoutTo_UserInput>;
  receiver_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutReceiver_UserInput>;
  regions_white_list_array?: InputMaybe<UserUpdateregions_White_List_ArrayInput>;
  regions_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  rep_scores?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumRoleFieldUpdateOperationsInput>;
  routings?: InputMaybe<RoutingUpdateManyWithoutRepInput>;
  sale_config?: InputMaybe<SaleConfigUpdateManyWithoutCreated_ByInput>;
  sale_config_updated?: InputMaybe<SaleConfigUpdateManyWithoutLast_Updated_ByInput>;
  sales_team_column_options?: InputMaybe<UserUpdatesales_Team_Column_OptionsInput>;
  schedule_items?: InputMaybe<ScheduleItemUpdateManyWithoutUserInput>;
  send_transfer_attempts?: InputMaybe<TransferAttemptUpdateManyWithoutFrom_UserInput>;
  sender_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutSender_UserInput>;
  sequence_entry_criteria_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SequenceActionLog?: InputMaybe<StepActionLogUpdateManyWithoutRepInput>;
  sequenceDashboardView?: InputMaybe<SequenceDashboardViewUpdateManyWithoutOwned_ByInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutUserInput>;
  Sequences_created?: InputMaybe<SequenceUpdateManyWithoutCreated_ByInput>;
  Sequences_modified_by_user?: InputMaybe<SequenceUpdateManyWithoutLast_Updated_ByInput>;
  session_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  set_user_cycle?: InputMaybe<SaleCycleUpdateManyWithoutSet_UserInput>;
  set_user_stat?: InputMaybe<StatLeadAggregationUpdateManyWithoutSet_UserInput>;
  sf_user_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SmsItems?: InputMaybe<SmsItemUpdateManyWithoutRepInput>;
  SnoozeTracking?: InputMaybe<SnoozeTrackingUpdateManyWithoutRepInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  statusHistory?: InputMaybe<UserStatusHistoryUpdateManyWithoutUserInput>;
  sub_industry_white_list_array?: InputMaybe<UserUpdatesub_Industry_White_List_ArrayInput>;
  sub_industry_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  support_rep?: InputMaybe<BoolFieldUpdateOperationsInput>;
  taking_demos?: InputMaybe<BoolFieldUpdateOperationsInput>;
  team?: InputMaybe<TeamUpdateOneWithoutMembersInput>;
  Template?: InputMaybe<TemplateUpdateManyWithoutLast_Modified_ByInput>;
  TemplateVersion?: InputMaybe<TemplateVersionUpdateManyWithoutCreated_ByInput>;
  timekit_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timekit_type?: InputMaybe<EnumTimekittypeFieldUpdateOperationsInput>;
  timeOffRequest?: InputMaybe<TimeOffRequestUpdateManyWithoutRequesting_UserInput>;
  timezone_raw?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transfer_type?: InputMaybe<EnumTransferTypeFieldUpdateOperationsInput>;
  transfer_user_cycle?: InputMaybe<SaleCycleUpdateManyWithoutTransfer_UserInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user_custom_availability?: InputMaybe<AvailabilityUpdateManyWithoutUserInput>;
  user_past_tokens?: InputMaybe<UserPastTokensUpdateManyWithoutUserInput>;
  visible_all_leads_sm?: InputMaybe<BoolFieldUpdateOperationsInput>;
  visible_call_report_columns?: InputMaybe<UserUpdatevisible_Call_Report_ColumnsInput>;
  visible_call_report_event_columns?: InputMaybe<UserUpdatevisible_Call_Report_Event_ColumnsInput>;
  visible_call_report_pipeline_columns?: InputMaybe<UserUpdatevisible_Call_Report_Pipeline_ColumnsInput>;
  visible_my_leads_columns?: InputMaybe<UserUpdatevisible_My_Leads_ColumnsInput>;
  voicemail_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutPayment_SettingsInput = {
  ai_user?: InputMaybe<BoolFieldUpdateOperationsInput>;
  alternate_email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  always_show_calendar_options?: InputMaybe<BoolFieldUpdateOperationsInput>;
  associate_owner_rep_history?: InputMaybe<AssociationHistoryUpdateManyWithoutRepInput>;
  associate_user_history?: InputMaybe<AssociationHistoryUpdateManyWithoutUserInput>;
  auto_transfer_selection_receive_attempts?: InputMaybe<AutoTransferSelectionUpdateManyWithoutFrom_UserInput>;
  auto_transfer_selection_send_attempts?: InputMaybe<AutoTransferSelectionUpdateManyWithoutTo_UserInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptUpdateManyWithoutUserInput>;
  callSharingAsCoach?: InputMaybe<CallSharingUpdateManyWithoutCoachInput>;
  callSharingAsUser?: InputMaybe<CallSharingUpdateManyWithoutReceiver_UserInput>;
  channel?: InputMaybe<NullableEnumChannelfilterFieldUpdateOperationsInput>;
  close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  close_user_cycle?: InputMaybe<SaleCycleUpdateManyWithoutClose_UserInput>;
  close_user_stat?: InputMaybe<StatLeadAggregationUpdateManyWithoutClose_UserInput>;
  commissions?: InputMaybe<CommissionUpdateManyWithoutUserInput>;
  contact_requests?: InputMaybe<ContactRequestUpdateManyWithoutRequesting_UserInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  current_sequence_view?: InputMaybe<SequenceDashboardViewUpdateOneWithoutUserInput>;
  current_team_start_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  custom_field_white_list_array?: InputMaybe<UserCustomFieldFilterUpdateManyWithoutUserInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemUpdateManyWithoutUserInput>;
  daily_lead_claim?: InputMaybe<IntFieldUpdateOperationsInput>;
  dashboard_2_access?: InputMaybe<BoolFieldUpdateOperationsInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewUpdateManyWithoutUserInput>;
  default_audio_input?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  default_audio_output?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  default_availability?: InputMaybe<BoolFieldUpdateOperationsInput>;
  deleted_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  deprecate_old_session?: InputMaybe<BoolFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordUpdateManyWithoutUserInput>;
  favorite_leads?: InputMaybe<LeadUpdateManyWithoutFavorited_By_UsersInput>;
  first_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  folder_permissions?: InputMaybe<FolderPermissionUpdateManyWithoutUserInput>;
  folders?: InputMaybe<FolderUpdateManyWithoutOwnerInput>;
  from_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutOld_UserInput>;
  GlobalRouting?: InputMaybe<GlobalRepRoutingUpdateManyWithoutRepInput>;
  google_refresh_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  holds_per_prospect_vs_held_demo_per_dial?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  hook_subscriptions?: InputMaybe<WebHookSubscriptionUpdateManyWithoutUserInput>;
  hubspot_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  idle_owned_leads?: InputMaybe<LeadUpdateManyWithoutSecondary_RepsInput>;
  industry_filter?: InputMaybe<BoolFieldUpdateOperationsInput>;
  industry_white_list_array?: InputMaybe<UserUpdateindustry_White_List_ArrayInput>;
  industry_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  joinMe_access_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  joinMe_refresh_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  last_demo_transferred_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  last_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutUserInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryUpdateManyWithoutUserInput>;
  lead_intents?: InputMaybe<LeadIntentUpdateManyWithoutUserInput>;
  lead_source_filter?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lead_source_white_list_array?: InputMaybe<UserUpdatelead_Source_White_List_ArrayInput>;
  lead_source_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  leads_last_called?: InputMaybe<LeadUpdateManyWithoutLast_Call_Result_RepInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusUpdateManyWithoutUserInput>;
  manager_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  note_item?: InputMaybe<NoteItemUpdateManyWithoutUserInput>;
  notifications?: InputMaybe<NotificationUpdateManyWithoutUserInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutUsersInput>;
  owned_leads?: InputMaybe<LeadUpdateManyWithoutRepInput>;
  panda_doc_access?: InputMaybe<BoolFieldUpdateOperationsInput>;
  password?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  payment_settings_updated?: InputMaybe<OrganizationPaymentSettingsUpdateManyWithoutLast_Updated_ByInput>;
  phone_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  post?: InputMaybe<PostUpdateManyWithoutAuthorInput>;
  ProductsCreated?: InputMaybe<ProductChangeLogUpdateManyWithoutUpdated_ByInput>;
  profile_image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  receive_transfer_attempts?: InputMaybe<TransferAttemptUpdateManyWithoutTo_UserInput>;
  receiver_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutReceiver_UserInput>;
  regions_white_list_array?: InputMaybe<UserUpdateregions_White_List_ArrayInput>;
  regions_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  rep_scores?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumRoleFieldUpdateOperationsInput>;
  routings?: InputMaybe<RoutingUpdateManyWithoutRepInput>;
  sale_config?: InputMaybe<SaleConfigUpdateManyWithoutCreated_ByInput>;
  sale_config_updated?: InputMaybe<SaleConfigUpdateManyWithoutLast_Updated_ByInput>;
  sales_team_column_options?: InputMaybe<UserUpdatesales_Team_Column_OptionsInput>;
  schedule_items?: InputMaybe<ScheduleItemUpdateManyWithoutUserInput>;
  send_transfer_attempts?: InputMaybe<TransferAttemptUpdateManyWithoutFrom_UserInput>;
  sender_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutSender_UserInput>;
  sequence_entry_criteria_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SequenceActionLog?: InputMaybe<StepActionLogUpdateManyWithoutRepInput>;
  sequenceDashboardView?: InputMaybe<SequenceDashboardViewUpdateManyWithoutOwned_ByInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutUserInput>;
  Sequences_created?: InputMaybe<SequenceUpdateManyWithoutCreated_ByInput>;
  Sequences_modified_by_user?: InputMaybe<SequenceUpdateManyWithoutLast_Updated_ByInput>;
  session_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  set_user_cycle?: InputMaybe<SaleCycleUpdateManyWithoutSet_UserInput>;
  set_user_stat?: InputMaybe<StatLeadAggregationUpdateManyWithoutSet_UserInput>;
  sf_user_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SmsItems?: InputMaybe<SmsItemUpdateManyWithoutRepInput>;
  SnoozeTracking?: InputMaybe<SnoozeTrackingUpdateManyWithoutRepInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  statusHistory?: InputMaybe<UserStatusHistoryUpdateManyWithoutUserInput>;
  sub_industry_white_list_array?: InputMaybe<UserUpdatesub_Industry_White_List_ArrayInput>;
  sub_industry_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  support_rep?: InputMaybe<BoolFieldUpdateOperationsInput>;
  taking_demos?: InputMaybe<BoolFieldUpdateOperationsInput>;
  team?: InputMaybe<TeamUpdateOneWithoutMembersInput>;
  Template?: InputMaybe<TemplateUpdateManyWithoutLast_Modified_ByInput>;
  TemplateVersion?: InputMaybe<TemplateVersionUpdateManyWithoutCreated_ByInput>;
  timekit_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timekit_type?: InputMaybe<EnumTimekittypeFieldUpdateOperationsInput>;
  timeOffRequest?: InputMaybe<TimeOffRequestUpdateManyWithoutRequesting_UserInput>;
  timezone_raw?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transfer_type?: InputMaybe<EnumTransferTypeFieldUpdateOperationsInput>;
  transfer_user_cycle?: InputMaybe<SaleCycleUpdateManyWithoutTransfer_UserInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user_custom_availability?: InputMaybe<AvailabilityUpdateManyWithoutUserInput>;
  user_past_tokens?: InputMaybe<UserPastTokensUpdateManyWithoutUserInput>;
  visible_all_leads_sm?: InputMaybe<BoolFieldUpdateOperationsInput>;
  visible_call_report_columns?: InputMaybe<UserUpdatevisible_Call_Report_ColumnsInput>;
  visible_call_report_event_columns?: InputMaybe<UserUpdatevisible_Call_Report_Event_ColumnsInput>;
  visible_call_report_pipeline_columns?: InputMaybe<UserUpdatevisible_Call_Report_Pipeline_ColumnsInput>;
  visible_my_leads_columns?: InputMaybe<UserUpdatevisible_My_Leads_ColumnsInput>;
  voicemail_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutProductsCreatedInput = {
  ai_user?: InputMaybe<BoolFieldUpdateOperationsInput>;
  alternate_email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  always_show_calendar_options?: InputMaybe<BoolFieldUpdateOperationsInput>;
  associate_owner_rep_history?: InputMaybe<AssociationHistoryUpdateManyWithoutRepInput>;
  associate_user_history?: InputMaybe<AssociationHistoryUpdateManyWithoutUserInput>;
  auto_transfer_selection_receive_attempts?: InputMaybe<AutoTransferSelectionUpdateManyWithoutFrom_UserInput>;
  auto_transfer_selection_send_attempts?: InputMaybe<AutoTransferSelectionUpdateManyWithoutTo_UserInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptUpdateManyWithoutUserInput>;
  callSharingAsCoach?: InputMaybe<CallSharingUpdateManyWithoutCoachInput>;
  callSharingAsUser?: InputMaybe<CallSharingUpdateManyWithoutReceiver_UserInput>;
  channel?: InputMaybe<NullableEnumChannelfilterFieldUpdateOperationsInput>;
  close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  close_user_cycle?: InputMaybe<SaleCycleUpdateManyWithoutClose_UserInput>;
  close_user_stat?: InputMaybe<StatLeadAggregationUpdateManyWithoutClose_UserInput>;
  commissions?: InputMaybe<CommissionUpdateManyWithoutUserInput>;
  contact_requests?: InputMaybe<ContactRequestUpdateManyWithoutRequesting_UserInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  current_sequence_view?: InputMaybe<SequenceDashboardViewUpdateOneWithoutUserInput>;
  current_team_start_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  custom_field_white_list_array?: InputMaybe<UserCustomFieldFilterUpdateManyWithoutUserInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemUpdateManyWithoutUserInput>;
  daily_lead_claim?: InputMaybe<IntFieldUpdateOperationsInput>;
  dashboard_2_access?: InputMaybe<BoolFieldUpdateOperationsInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewUpdateManyWithoutUserInput>;
  default_audio_input?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  default_audio_output?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  default_availability?: InputMaybe<BoolFieldUpdateOperationsInput>;
  deleted_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  deprecate_old_session?: InputMaybe<BoolFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordUpdateManyWithoutUserInput>;
  favorite_leads?: InputMaybe<LeadUpdateManyWithoutFavorited_By_UsersInput>;
  first_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  folder_permissions?: InputMaybe<FolderPermissionUpdateManyWithoutUserInput>;
  folders?: InputMaybe<FolderUpdateManyWithoutOwnerInput>;
  from_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutOld_UserInput>;
  GlobalRouting?: InputMaybe<GlobalRepRoutingUpdateManyWithoutRepInput>;
  google_refresh_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  holds_per_prospect_vs_held_demo_per_dial?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  hook_subscriptions?: InputMaybe<WebHookSubscriptionUpdateManyWithoutUserInput>;
  hubspot_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  idle_owned_leads?: InputMaybe<LeadUpdateManyWithoutSecondary_RepsInput>;
  industry_filter?: InputMaybe<BoolFieldUpdateOperationsInput>;
  industry_white_list_array?: InputMaybe<UserUpdateindustry_White_List_ArrayInput>;
  industry_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  joinMe_access_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  joinMe_refresh_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  last_demo_transferred_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  last_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutUserInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryUpdateManyWithoutUserInput>;
  lead_intents?: InputMaybe<LeadIntentUpdateManyWithoutUserInput>;
  lead_source_filter?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lead_source_white_list_array?: InputMaybe<UserUpdatelead_Source_White_List_ArrayInput>;
  lead_source_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  leads_last_called?: InputMaybe<LeadUpdateManyWithoutLast_Call_Result_RepInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusUpdateManyWithoutUserInput>;
  manager_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  note_item?: InputMaybe<NoteItemUpdateManyWithoutUserInput>;
  notifications?: InputMaybe<NotificationUpdateManyWithoutUserInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutUsersInput>;
  owned_leads?: InputMaybe<LeadUpdateManyWithoutRepInput>;
  panda_doc_access?: InputMaybe<BoolFieldUpdateOperationsInput>;
  password?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  payment_settings?: InputMaybe<OrganizationPaymentSettingsUpdateManyWithoutCreated_ByInput>;
  payment_settings_updated?: InputMaybe<OrganizationPaymentSettingsUpdateManyWithoutLast_Updated_ByInput>;
  phone_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  post?: InputMaybe<PostUpdateManyWithoutAuthorInput>;
  profile_image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  receive_transfer_attempts?: InputMaybe<TransferAttemptUpdateManyWithoutTo_UserInput>;
  receiver_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutReceiver_UserInput>;
  regions_white_list_array?: InputMaybe<UserUpdateregions_White_List_ArrayInput>;
  regions_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  rep_scores?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumRoleFieldUpdateOperationsInput>;
  routings?: InputMaybe<RoutingUpdateManyWithoutRepInput>;
  sale_config?: InputMaybe<SaleConfigUpdateManyWithoutCreated_ByInput>;
  sale_config_updated?: InputMaybe<SaleConfigUpdateManyWithoutLast_Updated_ByInput>;
  sales_team_column_options?: InputMaybe<UserUpdatesales_Team_Column_OptionsInput>;
  schedule_items?: InputMaybe<ScheduleItemUpdateManyWithoutUserInput>;
  send_transfer_attempts?: InputMaybe<TransferAttemptUpdateManyWithoutFrom_UserInput>;
  sender_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutSender_UserInput>;
  sequence_entry_criteria_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SequenceActionLog?: InputMaybe<StepActionLogUpdateManyWithoutRepInput>;
  sequenceDashboardView?: InputMaybe<SequenceDashboardViewUpdateManyWithoutOwned_ByInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutUserInput>;
  Sequences_created?: InputMaybe<SequenceUpdateManyWithoutCreated_ByInput>;
  Sequences_modified_by_user?: InputMaybe<SequenceUpdateManyWithoutLast_Updated_ByInput>;
  session_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  set_user_cycle?: InputMaybe<SaleCycleUpdateManyWithoutSet_UserInput>;
  set_user_stat?: InputMaybe<StatLeadAggregationUpdateManyWithoutSet_UserInput>;
  sf_user_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SmsItems?: InputMaybe<SmsItemUpdateManyWithoutRepInput>;
  SnoozeTracking?: InputMaybe<SnoozeTrackingUpdateManyWithoutRepInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  statusHistory?: InputMaybe<UserStatusHistoryUpdateManyWithoutUserInput>;
  sub_industry_white_list_array?: InputMaybe<UserUpdatesub_Industry_White_List_ArrayInput>;
  sub_industry_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  support_rep?: InputMaybe<BoolFieldUpdateOperationsInput>;
  taking_demos?: InputMaybe<BoolFieldUpdateOperationsInput>;
  team?: InputMaybe<TeamUpdateOneWithoutMembersInput>;
  Template?: InputMaybe<TemplateUpdateManyWithoutLast_Modified_ByInput>;
  TemplateVersion?: InputMaybe<TemplateVersionUpdateManyWithoutCreated_ByInput>;
  timekit_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timekit_type?: InputMaybe<EnumTimekittypeFieldUpdateOperationsInput>;
  timeOffRequest?: InputMaybe<TimeOffRequestUpdateManyWithoutRequesting_UserInput>;
  timezone_raw?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transfer_type?: InputMaybe<EnumTransferTypeFieldUpdateOperationsInput>;
  transfer_user_cycle?: InputMaybe<SaleCycleUpdateManyWithoutTransfer_UserInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user_custom_availability?: InputMaybe<AvailabilityUpdateManyWithoutUserInput>;
  user_past_tokens?: InputMaybe<UserPastTokensUpdateManyWithoutUserInput>;
  visible_all_leads_sm?: InputMaybe<BoolFieldUpdateOperationsInput>;
  visible_call_report_columns?: InputMaybe<UserUpdatevisible_Call_Report_ColumnsInput>;
  visible_call_report_event_columns?: InputMaybe<UserUpdatevisible_Call_Report_Event_ColumnsInput>;
  visible_call_report_pipeline_columns?: InputMaybe<UserUpdatevisible_Call_Report_Pipeline_ColumnsInput>;
  visible_my_leads_columns?: InputMaybe<UserUpdatevisible_My_Leads_ColumnsInput>;
  voicemail_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutReceive_Transfer_AttemptsInput = {
  ai_user?: InputMaybe<BoolFieldUpdateOperationsInput>;
  alternate_email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  always_show_calendar_options?: InputMaybe<BoolFieldUpdateOperationsInput>;
  associate_owner_rep_history?: InputMaybe<AssociationHistoryUpdateManyWithoutRepInput>;
  associate_user_history?: InputMaybe<AssociationHistoryUpdateManyWithoutUserInput>;
  auto_transfer_selection_receive_attempts?: InputMaybe<AutoTransferSelectionUpdateManyWithoutFrom_UserInput>;
  auto_transfer_selection_send_attempts?: InputMaybe<AutoTransferSelectionUpdateManyWithoutTo_UserInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptUpdateManyWithoutUserInput>;
  callSharingAsCoach?: InputMaybe<CallSharingUpdateManyWithoutCoachInput>;
  callSharingAsUser?: InputMaybe<CallSharingUpdateManyWithoutReceiver_UserInput>;
  channel?: InputMaybe<NullableEnumChannelfilterFieldUpdateOperationsInput>;
  close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  close_user_cycle?: InputMaybe<SaleCycleUpdateManyWithoutClose_UserInput>;
  close_user_stat?: InputMaybe<StatLeadAggregationUpdateManyWithoutClose_UserInput>;
  commissions?: InputMaybe<CommissionUpdateManyWithoutUserInput>;
  contact_requests?: InputMaybe<ContactRequestUpdateManyWithoutRequesting_UserInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  current_sequence_view?: InputMaybe<SequenceDashboardViewUpdateOneWithoutUserInput>;
  current_team_start_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  custom_field_white_list_array?: InputMaybe<UserCustomFieldFilterUpdateManyWithoutUserInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemUpdateManyWithoutUserInput>;
  daily_lead_claim?: InputMaybe<IntFieldUpdateOperationsInput>;
  dashboard_2_access?: InputMaybe<BoolFieldUpdateOperationsInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewUpdateManyWithoutUserInput>;
  default_audio_input?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  default_audio_output?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  default_availability?: InputMaybe<BoolFieldUpdateOperationsInput>;
  deleted_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  deprecate_old_session?: InputMaybe<BoolFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordUpdateManyWithoutUserInput>;
  favorite_leads?: InputMaybe<LeadUpdateManyWithoutFavorited_By_UsersInput>;
  first_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  folder_permissions?: InputMaybe<FolderPermissionUpdateManyWithoutUserInput>;
  folders?: InputMaybe<FolderUpdateManyWithoutOwnerInput>;
  from_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutOld_UserInput>;
  GlobalRouting?: InputMaybe<GlobalRepRoutingUpdateManyWithoutRepInput>;
  google_refresh_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  holds_per_prospect_vs_held_demo_per_dial?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  hook_subscriptions?: InputMaybe<WebHookSubscriptionUpdateManyWithoutUserInput>;
  hubspot_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  idle_owned_leads?: InputMaybe<LeadUpdateManyWithoutSecondary_RepsInput>;
  industry_filter?: InputMaybe<BoolFieldUpdateOperationsInput>;
  industry_white_list_array?: InputMaybe<UserUpdateindustry_White_List_ArrayInput>;
  industry_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  joinMe_access_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  joinMe_refresh_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  last_demo_transferred_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  last_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutUserInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryUpdateManyWithoutUserInput>;
  lead_intents?: InputMaybe<LeadIntentUpdateManyWithoutUserInput>;
  lead_source_filter?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lead_source_white_list_array?: InputMaybe<UserUpdatelead_Source_White_List_ArrayInput>;
  lead_source_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  leads_last_called?: InputMaybe<LeadUpdateManyWithoutLast_Call_Result_RepInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusUpdateManyWithoutUserInput>;
  manager_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  note_item?: InputMaybe<NoteItemUpdateManyWithoutUserInput>;
  notifications?: InputMaybe<NotificationUpdateManyWithoutUserInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutUsersInput>;
  owned_leads?: InputMaybe<LeadUpdateManyWithoutRepInput>;
  panda_doc_access?: InputMaybe<BoolFieldUpdateOperationsInput>;
  password?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  payment_settings?: InputMaybe<OrganizationPaymentSettingsUpdateManyWithoutCreated_ByInput>;
  payment_settings_updated?: InputMaybe<OrganizationPaymentSettingsUpdateManyWithoutLast_Updated_ByInput>;
  phone_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  post?: InputMaybe<PostUpdateManyWithoutAuthorInput>;
  ProductsCreated?: InputMaybe<ProductChangeLogUpdateManyWithoutUpdated_ByInput>;
  profile_image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  receiver_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutReceiver_UserInput>;
  regions_white_list_array?: InputMaybe<UserUpdateregions_White_List_ArrayInput>;
  regions_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  rep_scores?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumRoleFieldUpdateOperationsInput>;
  routings?: InputMaybe<RoutingUpdateManyWithoutRepInput>;
  sale_config?: InputMaybe<SaleConfigUpdateManyWithoutCreated_ByInput>;
  sale_config_updated?: InputMaybe<SaleConfigUpdateManyWithoutLast_Updated_ByInput>;
  sales_team_column_options?: InputMaybe<UserUpdatesales_Team_Column_OptionsInput>;
  schedule_items?: InputMaybe<ScheduleItemUpdateManyWithoutUserInput>;
  send_transfer_attempts?: InputMaybe<TransferAttemptUpdateManyWithoutFrom_UserInput>;
  sender_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutSender_UserInput>;
  sequence_entry_criteria_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SequenceActionLog?: InputMaybe<StepActionLogUpdateManyWithoutRepInput>;
  sequenceDashboardView?: InputMaybe<SequenceDashboardViewUpdateManyWithoutOwned_ByInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutUserInput>;
  Sequences_created?: InputMaybe<SequenceUpdateManyWithoutCreated_ByInput>;
  Sequences_modified_by_user?: InputMaybe<SequenceUpdateManyWithoutLast_Updated_ByInput>;
  session_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  set_user_cycle?: InputMaybe<SaleCycleUpdateManyWithoutSet_UserInput>;
  set_user_stat?: InputMaybe<StatLeadAggregationUpdateManyWithoutSet_UserInput>;
  sf_user_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SmsItems?: InputMaybe<SmsItemUpdateManyWithoutRepInput>;
  SnoozeTracking?: InputMaybe<SnoozeTrackingUpdateManyWithoutRepInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  statusHistory?: InputMaybe<UserStatusHistoryUpdateManyWithoutUserInput>;
  sub_industry_white_list_array?: InputMaybe<UserUpdatesub_Industry_White_List_ArrayInput>;
  sub_industry_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  support_rep?: InputMaybe<BoolFieldUpdateOperationsInput>;
  taking_demos?: InputMaybe<BoolFieldUpdateOperationsInput>;
  team?: InputMaybe<TeamUpdateOneWithoutMembersInput>;
  Template?: InputMaybe<TemplateUpdateManyWithoutLast_Modified_ByInput>;
  TemplateVersion?: InputMaybe<TemplateVersionUpdateManyWithoutCreated_ByInput>;
  timekit_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timekit_type?: InputMaybe<EnumTimekittypeFieldUpdateOperationsInput>;
  timeOffRequest?: InputMaybe<TimeOffRequestUpdateManyWithoutRequesting_UserInput>;
  timezone_raw?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transfer_type?: InputMaybe<EnumTransferTypeFieldUpdateOperationsInput>;
  transfer_user_cycle?: InputMaybe<SaleCycleUpdateManyWithoutTransfer_UserInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user_custom_availability?: InputMaybe<AvailabilityUpdateManyWithoutUserInput>;
  user_past_tokens?: InputMaybe<UserPastTokensUpdateManyWithoutUserInput>;
  visible_all_leads_sm?: InputMaybe<BoolFieldUpdateOperationsInput>;
  visible_call_report_columns?: InputMaybe<UserUpdatevisible_Call_Report_ColumnsInput>;
  visible_call_report_event_columns?: InputMaybe<UserUpdatevisible_Call_Report_Event_ColumnsInput>;
  visible_call_report_pipeline_columns?: InputMaybe<UserUpdatevisible_Call_Report_Pipeline_ColumnsInput>;
  visible_my_leads_columns?: InputMaybe<UserUpdatevisible_My_Leads_ColumnsInput>;
  voicemail_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutReceiver_Assignment_HistoryInput = {
  ai_user?: InputMaybe<BoolFieldUpdateOperationsInput>;
  alternate_email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  always_show_calendar_options?: InputMaybe<BoolFieldUpdateOperationsInput>;
  associate_owner_rep_history?: InputMaybe<AssociationHistoryUpdateManyWithoutRepInput>;
  associate_user_history?: InputMaybe<AssociationHistoryUpdateManyWithoutUserInput>;
  auto_transfer_selection_receive_attempts?: InputMaybe<AutoTransferSelectionUpdateManyWithoutFrom_UserInput>;
  auto_transfer_selection_send_attempts?: InputMaybe<AutoTransferSelectionUpdateManyWithoutTo_UserInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptUpdateManyWithoutUserInput>;
  callSharingAsCoach?: InputMaybe<CallSharingUpdateManyWithoutCoachInput>;
  callSharingAsUser?: InputMaybe<CallSharingUpdateManyWithoutReceiver_UserInput>;
  channel?: InputMaybe<NullableEnumChannelfilterFieldUpdateOperationsInput>;
  close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  close_user_cycle?: InputMaybe<SaleCycleUpdateManyWithoutClose_UserInput>;
  close_user_stat?: InputMaybe<StatLeadAggregationUpdateManyWithoutClose_UserInput>;
  commissions?: InputMaybe<CommissionUpdateManyWithoutUserInput>;
  contact_requests?: InputMaybe<ContactRequestUpdateManyWithoutRequesting_UserInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  current_sequence_view?: InputMaybe<SequenceDashboardViewUpdateOneWithoutUserInput>;
  current_team_start_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  custom_field_white_list_array?: InputMaybe<UserCustomFieldFilterUpdateManyWithoutUserInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemUpdateManyWithoutUserInput>;
  daily_lead_claim?: InputMaybe<IntFieldUpdateOperationsInput>;
  dashboard_2_access?: InputMaybe<BoolFieldUpdateOperationsInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewUpdateManyWithoutUserInput>;
  default_audio_input?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  default_audio_output?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  default_availability?: InputMaybe<BoolFieldUpdateOperationsInput>;
  deleted_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  deprecate_old_session?: InputMaybe<BoolFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordUpdateManyWithoutUserInput>;
  favorite_leads?: InputMaybe<LeadUpdateManyWithoutFavorited_By_UsersInput>;
  first_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  folder_permissions?: InputMaybe<FolderPermissionUpdateManyWithoutUserInput>;
  folders?: InputMaybe<FolderUpdateManyWithoutOwnerInput>;
  from_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutOld_UserInput>;
  GlobalRouting?: InputMaybe<GlobalRepRoutingUpdateManyWithoutRepInput>;
  google_refresh_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  holds_per_prospect_vs_held_demo_per_dial?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  hook_subscriptions?: InputMaybe<WebHookSubscriptionUpdateManyWithoutUserInput>;
  hubspot_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  idle_owned_leads?: InputMaybe<LeadUpdateManyWithoutSecondary_RepsInput>;
  industry_filter?: InputMaybe<BoolFieldUpdateOperationsInput>;
  industry_white_list_array?: InputMaybe<UserUpdateindustry_White_List_ArrayInput>;
  industry_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  joinMe_access_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  joinMe_refresh_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  last_demo_transferred_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  last_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutUserInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryUpdateManyWithoutUserInput>;
  lead_intents?: InputMaybe<LeadIntentUpdateManyWithoutUserInput>;
  lead_source_filter?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lead_source_white_list_array?: InputMaybe<UserUpdatelead_Source_White_List_ArrayInput>;
  lead_source_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  leads_last_called?: InputMaybe<LeadUpdateManyWithoutLast_Call_Result_RepInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusUpdateManyWithoutUserInput>;
  manager_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  note_item?: InputMaybe<NoteItemUpdateManyWithoutUserInput>;
  notifications?: InputMaybe<NotificationUpdateManyWithoutUserInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutUsersInput>;
  owned_leads?: InputMaybe<LeadUpdateManyWithoutRepInput>;
  panda_doc_access?: InputMaybe<BoolFieldUpdateOperationsInput>;
  password?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  payment_settings?: InputMaybe<OrganizationPaymentSettingsUpdateManyWithoutCreated_ByInput>;
  payment_settings_updated?: InputMaybe<OrganizationPaymentSettingsUpdateManyWithoutLast_Updated_ByInput>;
  phone_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  post?: InputMaybe<PostUpdateManyWithoutAuthorInput>;
  ProductsCreated?: InputMaybe<ProductChangeLogUpdateManyWithoutUpdated_ByInput>;
  profile_image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  receive_transfer_attempts?: InputMaybe<TransferAttemptUpdateManyWithoutTo_UserInput>;
  regions_white_list_array?: InputMaybe<UserUpdateregions_White_List_ArrayInput>;
  regions_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  rep_scores?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumRoleFieldUpdateOperationsInput>;
  routings?: InputMaybe<RoutingUpdateManyWithoutRepInput>;
  sale_config?: InputMaybe<SaleConfigUpdateManyWithoutCreated_ByInput>;
  sale_config_updated?: InputMaybe<SaleConfigUpdateManyWithoutLast_Updated_ByInput>;
  sales_team_column_options?: InputMaybe<UserUpdatesales_Team_Column_OptionsInput>;
  schedule_items?: InputMaybe<ScheduleItemUpdateManyWithoutUserInput>;
  send_transfer_attempts?: InputMaybe<TransferAttemptUpdateManyWithoutFrom_UserInput>;
  sender_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutSender_UserInput>;
  sequence_entry_criteria_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SequenceActionLog?: InputMaybe<StepActionLogUpdateManyWithoutRepInput>;
  sequenceDashboardView?: InputMaybe<SequenceDashboardViewUpdateManyWithoutOwned_ByInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutUserInput>;
  Sequences_created?: InputMaybe<SequenceUpdateManyWithoutCreated_ByInput>;
  Sequences_modified_by_user?: InputMaybe<SequenceUpdateManyWithoutLast_Updated_ByInput>;
  session_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  set_user_cycle?: InputMaybe<SaleCycleUpdateManyWithoutSet_UserInput>;
  set_user_stat?: InputMaybe<StatLeadAggregationUpdateManyWithoutSet_UserInput>;
  sf_user_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SmsItems?: InputMaybe<SmsItemUpdateManyWithoutRepInput>;
  SnoozeTracking?: InputMaybe<SnoozeTrackingUpdateManyWithoutRepInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  statusHistory?: InputMaybe<UserStatusHistoryUpdateManyWithoutUserInput>;
  sub_industry_white_list_array?: InputMaybe<UserUpdatesub_Industry_White_List_ArrayInput>;
  sub_industry_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  support_rep?: InputMaybe<BoolFieldUpdateOperationsInput>;
  taking_demos?: InputMaybe<BoolFieldUpdateOperationsInput>;
  team?: InputMaybe<TeamUpdateOneWithoutMembersInput>;
  Template?: InputMaybe<TemplateUpdateManyWithoutLast_Modified_ByInput>;
  TemplateVersion?: InputMaybe<TemplateVersionUpdateManyWithoutCreated_ByInput>;
  timekit_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timekit_type?: InputMaybe<EnumTimekittypeFieldUpdateOperationsInput>;
  timeOffRequest?: InputMaybe<TimeOffRequestUpdateManyWithoutRequesting_UserInput>;
  timezone_raw?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transfer_type?: InputMaybe<EnumTransferTypeFieldUpdateOperationsInput>;
  transfer_user_cycle?: InputMaybe<SaleCycleUpdateManyWithoutTransfer_UserInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user_custom_availability?: InputMaybe<AvailabilityUpdateManyWithoutUserInput>;
  user_past_tokens?: InputMaybe<UserPastTokensUpdateManyWithoutUserInput>;
  visible_all_leads_sm?: InputMaybe<BoolFieldUpdateOperationsInput>;
  visible_call_report_columns?: InputMaybe<UserUpdatevisible_Call_Report_ColumnsInput>;
  visible_call_report_event_columns?: InputMaybe<UserUpdatevisible_Call_Report_Event_ColumnsInput>;
  visible_call_report_pipeline_columns?: InputMaybe<UserUpdatevisible_Call_Report_Pipeline_ColumnsInput>;
  visible_my_leads_columns?: InputMaybe<UserUpdatevisible_My_Leads_ColumnsInput>;
  voicemail_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutRoutingsInput = {
  ai_user?: InputMaybe<BoolFieldUpdateOperationsInput>;
  alternate_email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  always_show_calendar_options?: InputMaybe<BoolFieldUpdateOperationsInput>;
  associate_owner_rep_history?: InputMaybe<AssociationHistoryUpdateManyWithoutRepInput>;
  associate_user_history?: InputMaybe<AssociationHistoryUpdateManyWithoutUserInput>;
  auto_transfer_selection_receive_attempts?: InputMaybe<AutoTransferSelectionUpdateManyWithoutFrom_UserInput>;
  auto_transfer_selection_send_attempts?: InputMaybe<AutoTransferSelectionUpdateManyWithoutTo_UserInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptUpdateManyWithoutUserInput>;
  callSharingAsCoach?: InputMaybe<CallSharingUpdateManyWithoutCoachInput>;
  callSharingAsUser?: InputMaybe<CallSharingUpdateManyWithoutReceiver_UserInput>;
  channel?: InputMaybe<NullableEnumChannelfilterFieldUpdateOperationsInput>;
  close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  close_user_cycle?: InputMaybe<SaleCycleUpdateManyWithoutClose_UserInput>;
  close_user_stat?: InputMaybe<StatLeadAggregationUpdateManyWithoutClose_UserInput>;
  commissions?: InputMaybe<CommissionUpdateManyWithoutUserInput>;
  contact_requests?: InputMaybe<ContactRequestUpdateManyWithoutRequesting_UserInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  current_sequence_view?: InputMaybe<SequenceDashboardViewUpdateOneWithoutUserInput>;
  current_team_start_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  custom_field_white_list_array?: InputMaybe<UserCustomFieldFilterUpdateManyWithoutUserInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemUpdateManyWithoutUserInput>;
  daily_lead_claim?: InputMaybe<IntFieldUpdateOperationsInput>;
  dashboard_2_access?: InputMaybe<BoolFieldUpdateOperationsInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewUpdateManyWithoutUserInput>;
  default_audio_input?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  default_audio_output?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  default_availability?: InputMaybe<BoolFieldUpdateOperationsInput>;
  deleted_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  deprecate_old_session?: InputMaybe<BoolFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordUpdateManyWithoutUserInput>;
  favorite_leads?: InputMaybe<LeadUpdateManyWithoutFavorited_By_UsersInput>;
  first_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  folder_permissions?: InputMaybe<FolderPermissionUpdateManyWithoutUserInput>;
  folders?: InputMaybe<FolderUpdateManyWithoutOwnerInput>;
  from_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutOld_UserInput>;
  GlobalRouting?: InputMaybe<GlobalRepRoutingUpdateManyWithoutRepInput>;
  google_refresh_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  holds_per_prospect_vs_held_demo_per_dial?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  hook_subscriptions?: InputMaybe<WebHookSubscriptionUpdateManyWithoutUserInput>;
  hubspot_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  idle_owned_leads?: InputMaybe<LeadUpdateManyWithoutSecondary_RepsInput>;
  industry_filter?: InputMaybe<BoolFieldUpdateOperationsInput>;
  industry_white_list_array?: InputMaybe<UserUpdateindustry_White_List_ArrayInput>;
  industry_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  joinMe_access_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  joinMe_refresh_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  last_demo_transferred_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  last_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutUserInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryUpdateManyWithoutUserInput>;
  lead_intents?: InputMaybe<LeadIntentUpdateManyWithoutUserInput>;
  lead_source_filter?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lead_source_white_list_array?: InputMaybe<UserUpdatelead_Source_White_List_ArrayInput>;
  lead_source_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  leads_last_called?: InputMaybe<LeadUpdateManyWithoutLast_Call_Result_RepInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusUpdateManyWithoutUserInput>;
  manager_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  note_item?: InputMaybe<NoteItemUpdateManyWithoutUserInput>;
  notifications?: InputMaybe<NotificationUpdateManyWithoutUserInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutUsersInput>;
  owned_leads?: InputMaybe<LeadUpdateManyWithoutRepInput>;
  panda_doc_access?: InputMaybe<BoolFieldUpdateOperationsInput>;
  password?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  payment_settings?: InputMaybe<OrganizationPaymentSettingsUpdateManyWithoutCreated_ByInput>;
  payment_settings_updated?: InputMaybe<OrganizationPaymentSettingsUpdateManyWithoutLast_Updated_ByInput>;
  phone_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  post?: InputMaybe<PostUpdateManyWithoutAuthorInput>;
  ProductsCreated?: InputMaybe<ProductChangeLogUpdateManyWithoutUpdated_ByInput>;
  profile_image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  receive_transfer_attempts?: InputMaybe<TransferAttemptUpdateManyWithoutTo_UserInput>;
  receiver_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutReceiver_UserInput>;
  regions_white_list_array?: InputMaybe<UserUpdateregions_White_List_ArrayInput>;
  regions_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  rep_scores?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumRoleFieldUpdateOperationsInput>;
  sale_config?: InputMaybe<SaleConfigUpdateManyWithoutCreated_ByInput>;
  sale_config_updated?: InputMaybe<SaleConfigUpdateManyWithoutLast_Updated_ByInput>;
  sales_team_column_options?: InputMaybe<UserUpdatesales_Team_Column_OptionsInput>;
  schedule_items?: InputMaybe<ScheduleItemUpdateManyWithoutUserInput>;
  send_transfer_attempts?: InputMaybe<TransferAttemptUpdateManyWithoutFrom_UserInput>;
  sender_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutSender_UserInput>;
  sequence_entry_criteria_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SequenceActionLog?: InputMaybe<StepActionLogUpdateManyWithoutRepInput>;
  sequenceDashboardView?: InputMaybe<SequenceDashboardViewUpdateManyWithoutOwned_ByInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutUserInput>;
  Sequences_created?: InputMaybe<SequenceUpdateManyWithoutCreated_ByInput>;
  Sequences_modified_by_user?: InputMaybe<SequenceUpdateManyWithoutLast_Updated_ByInput>;
  session_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  set_user_cycle?: InputMaybe<SaleCycleUpdateManyWithoutSet_UserInput>;
  set_user_stat?: InputMaybe<StatLeadAggregationUpdateManyWithoutSet_UserInput>;
  sf_user_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SmsItems?: InputMaybe<SmsItemUpdateManyWithoutRepInput>;
  SnoozeTracking?: InputMaybe<SnoozeTrackingUpdateManyWithoutRepInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  statusHistory?: InputMaybe<UserStatusHistoryUpdateManyWithoutUserInput>;
  sub_industry_white_list_array?: InputMaybe<UserUpdatesub_Industry_White_List_ArrayInput>;
  sub_industry_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  support_rep?: InputMaybe<BoolFieldUpdateOperationsInput>;
  taking_demos?: InputMaybe<BoolFieldUpdateOperationsInput>;
  team?: InputMaybe<TeamUpdateOneWithoutMembersInput>;
  Template?: InputMaybe<TemplateUpdateManyWithoutLast_Modified_ByInput>;
  TemplateVersion?: InputMaybe<TemplateVersionUpdateManyWithoutCreated_ByInput>;
  timekit_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timekit_type?: InputMaybe<EnumTimekittypeFieldUpdateOperationsInput>;
  timeOffRequest?: InputMaybe<TimeOffRequestUpdateManyWithoutRequesting_UserInput>;
  timezone_raw?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transfer_type?: InputMaybe<EnumTransferTypeFieldUpdateOperationsInput>;
  transfer_user_cycle?: InputMaybe<SaleCycleUpdateManyWithoutTransfer_UserInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user_custom_availability?: InputMaybe<AvailabilityUpdateManyWithoutUserInput>;
  user_past_tokens?: InputMaybe<UserPastTokensUpdateManyWithoutUserInput>;
  visible_all_leads_sm?: InputMaybe<BoolFieldUpdateOperationsInput>;
  visible_call_report_columns?: InputMaybe<UserUpdatevisible_Call_Report_ColumnsInput>;
  visible_call_report_event_columns?: InputMaybe<UserUpdatevisible_Call_Report_Event_ColumnsInput>;
  visible_call_report_pipeline_columns?: InputMaybe<UserUpdatevisible_Call_Report_Pipeline_ColumnsInput>;
  visible_my_leads_columns?: InputMaybe<UserUpdatevisible_My_Leads_ColumnsInput>;
  voicemail_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutSale_Config_UpdatedInput = {
  ai_user?: InputMaybe<BoolFieldUpdateOperationsInput>;
  alternate_email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  always_show_calendar_options?: InputMaybe<BoolFieldUpdateOperationsInput>;
  associate_owner_rep_history?: InputMaybe<AssociationHistoryUpdateManyWithoutRepInput>;
  associate_user_history?: InputMaybe<AssociationHistoryUpdateManyWithoutUserInput>;
  auto_transfer_selection_receive_attempts?: InputMaybe<AutoTransferSelectionUpdateManyWithoutFrom_UserInput>;
  auto_transfer_selection_send_attempts?: InputMaybe<AutoTransferSelectionUpdateManyWithoutTo_UserInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptUpdateManyWithoutUserInput>;
  callSharingAsCoach?: InputMaybe<CallSharingUpdateManyWithoutCoachInput>;
  callSharingAsUser?: InputMaybe<CallSharingUpdateManyWithoutReceiver_UserInput>;
  channel?: InputMaybe<NullableEnumChannelfilterFieldUpdateOperationsInput>;
  close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  close_user_cycle?: InputMaybe<SaleCycleUpdateManyWithoutClose_UserInput>;
  close_user_stat?: InputMaybe<StatLeadAggregationUpdateManyWithoutClose_UserInput>;
  commissions?: InputMaybe<CommissionUpdateManyWithoutUserInput>;
  contact_requests?: InputMaybe<ContactRequestUpdateManyWithoutRequesting_UserInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  current_sequence_view?: InputMaybe<SequenceDashboardViewUpdateOneWithoutUserInput>;
  current_team_start_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  custom_field_white_list_array?: InputMaybe<UserCustomFieldFilterUpdateManyWithoutUserInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemUpdateManyWithoutUserInput>;
  daily_lead_claim?: InputMaybe<IntFieldUpdateOperationsInput>;
  dashboard_2_access?: InputMaybe<BoolFieldUpdateOperationsInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewUpdateManyWithoutUserInput>;
  default_audio_input?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  default_audio_output?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  default_availability?: InputMaybe<BoolFieldUpdateOperationsInput>;
  deleted_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  deprecate_old_session?: InputMaybe<BoolFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordUpdateManyWithoutUserInput>;
  favorite_leads?: InputMaybe<LeadUpdateManyWithoutFavorited_By_UsersInput>;
  first_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  folder_permissions?: InputMaybe<FolderPermissionUpdateManyWithoutUserInput>;
  folders?: InputMaybe<FolderUpdateManyWithoutOwnerInput>;
  from_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutOld_UserInput>;
  GlobalRouting?: InputMaybe<GlobalRepRoutingUpdateManyWithoutRepInput>;
  google_refresh_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  holds_per_prospect_vs_held_demo_per_dial?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  hook_subscriptions?: InputMaybe<WebHookSubscriptionUpdateManyWithoutUserInput>;
  hubspot_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  idle_owned_leads?: InputMaybe<LeadUpdateManyWithoutSecondary_RepsInput>;
  industry_filter?: InputMaybe<BoolFieldUpdateOperationsInput>;
  industry_white_list_array?: InputMaybe<UserUpdateindustry_White_List_ArrayInput>;
  industry_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  joinMe_access_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  joinMe_refresh_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  last_demo_transferred_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  last_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutUserInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryUpdateManyWithoutUserInput>;
  lead_intents?: InputMaybe<LeadIntentUpdateManyWithoutUserInput>;
  lead_source_filter?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lead_source_white_list_array?: InputMaybe<UserUpdatelead_Source_White_List_ArrayInput>;
  lead_source_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  leads_last_called?: InputMaybe<LeadUpdateManyWithoutLast_Call_Result_RepInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusUpdateManyWithoutUserInput>;
  manager_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  note_item?: InputMaybe<NoteItemUpdateManyWithoutUserInput>;
  notifications?: InputMaybe<NotificationUpdateManyWithoutUserInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutUsersInput>;
  owned_leads?: InputMaybe<LeadUpdateManyWithoutRepInput>;
  panda_doc_access?: InputMaybe<BoolFieldUpdateOperationsInput>;
  password?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  payment_settings?: InputMaybe<OrganizationPaymentSettingsUpdateManyWithoutCreated_ByInput>;
  payment_settings_updated?: InputMaybe<OrganizationPaymentSettingsUpdateManyWithoutLast_Updated_ByInput>;
  phone_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  post?: InputMaybe<PostUpdateManyWithoutAuthorInput>;
  ProductsCreated?: InputMaybe<ProductChangeLogUpdateManyWithoutUpdated_ByInput>;
  profile_image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  receive_transfer_attempts?: InputMaybe<TransferAttemptUpdateManyWithoutTo_UserInput>;
  receiver_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutReceiver_UserInput>;
  regions_white_list_array?: InputMaybe<UserUpdateregions_White_List_ArrayInput>;
  regions_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  rep_scores?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumRoleFieldUpdateOperationsInput>;
  routings?: InputMaybe<RoutingUpdateManyWithoutRepInput>;
  sale_config?: InputMaybe<SaleConfigUpdateManyWithoutCreated_ByInput>;
  sales_team_column_options?: InputMaybe<UserUpdatesales_Team_Column_OptionsInput>;
  schedule_items?: InputMaybe<ScheduleItemUpdateManyWithoutUserInput>;
  send_transfer_attempts?: InputMaybe<TransferAttemptUpdateManyWithoutFrom_UserInput>;
  sender_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutSender_UserInput>;
  sequence_entry_criteria_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SequenceActionLog?: InputMaybe<StepActionLogUpdateManyWithoutRepInput>;
  sequenceDashboardView?: InputMaybe<SequenceDashboardViewUpdateManyWithoutOwned_ByInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutUserInput>;
  Sequences_created?: InputMaybe<SequenceUpdateManyWithoutCreated_ByInput>;
  Sequences_modified_by_user?: InputMaybe<SequenceUpdateManyWithoutLast_Updated_ByInput>;
  session_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  set_user_cycle?: InputMaybe<SaleCycleUpdateManyWithoutSet_UserInput>;
  set_user_stat?: InputMaybe<StatLeadAggregationUpdateManyWithoutSet_UserInput>;
  sf_user_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SmsItems?: InputMaybe<SmsItemUpdateManyWithoutRepInput>;
  SnoozeTracking?: InputMaybe<SnoozeTrackingUpdateManyWithoutRepInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  statusHistory?: InputMaybe<UserStatusHistoryUpdateManyWithoutUserInput>;
  sub_industry_white_list_array?: InputMaybe<UserUpdatesub_Industry_White_List_ArrayInput>;
  sub_industry_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  support_rep?: InputMaybe<BoolFieldUpdateOperationsInput>;
  taking_demos?: InputMaybe<BoolFieldUpdateOperationsInput>;
  team?: InputMaybe<TeamUpdateOneWithoutMembersInput>;
  Template?: InputMaybe<TemplateUpdateManyWithoutLast_Modified_ByInput>;
  TemplateVersion?: InputMaybe<TemplateVersionUpdateManyWithoutCreated_ByInput>;
  timekit_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timekit_type?: InputMaybe<EnumTimekittypeFieldUpdateOperationsInput>;
  timeOffRequest?: InputMaybe<TimeOffRequestUpdateManyWithoutRequesting_UserInput>;
  timezone_raw?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transfer_type?: InputMaybe<EnumTransferTypeFieldUpdateOperationsInput>;
  transfer_user_cycle?: InputMaybe<SaleCycleUpdateManyWithoutTransfer_UserInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user_custom_availability?: InputMaybe<AvailabilityUpdateManyWithoutUserInput>;
  user_past_tokens?: InputMaybe<UserPastTokensUpdateManyWithoutUserInput>;
  visible_all_leads_sm?: InputMaybe<BoolFieldUpdateOperationsInput>;
  visible_call_report_columns?: InputMaybe<UserUpdatevisible_Call_Report_ColumnsInput>;
  visible_call_report_event_columns?: InputMaybe<UserUpdatevisible_Call_Report_Event_ColumnsInput>;
  visible_call_report_pipeline_columns?: InputMaybe<UserUpdatevisible_Call_Report_Pipeline_ColumnsInput>;
  visible_my_leads_columns?: InputMaybe<UserUpdatevisible_My_Leads_ColumnsInput>;
  voicemail_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutSale_ConfigInput = {
  ai_user?: InputMaybe<BoolFieldUpdateOperationsInput>;
  alternate_email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  always_show_calendar_options?: InputMaybe<BoolFieldUpdateOperationsInput>;
  associate_owner_rep_history?: InputMaybe<AssociationHistoryUpdateManyWithoutRepInput>;
  associate_user_history?: InputMaybe<AssociationHistoryUpdateManyWithoutUserInput>;
  auto_transfer_selection_receive_attempts?: InputMaybe<AutoTransferSelectionUpdateManyWithoutFrom_UserInput>;
  auto_transfer_selection_send_attempts?: InputMaybe<AutoTransferSelectionUpdateManyWithoutTo_UserInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptUpdateManyWithoutUserInput>;
  callSharingAsCoach?: InputMaybe<CallSharingUpdateManyWithoutCoachInput>;
  callSharingAsUser?: InputMaybe<CallSharingUpdateManyWithoutReceiver_UserInput>;
  channel?: InputMaybe<NullableEnumChannelfilterFieldUpdateOperationsInput>;
  close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  close_user_cycle?: InputMaybe<SaleCycleUpdateManyWithoutClose_UserInput>;
  close_user_stat?: InputMaybe<StatLeadAggregationUpdateManyWithoutClose_UserInput>;
  commissions?: InputMaybe<CommissionUpdateManyWithoutUserInput>;
  contact_requests?: InputMaybe<ContactRequestUpdateManyWithoutRequesting_UserInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  current_sequence_view?: InputMaybe<SequenceDashboardViewUpdateOneWithoutUserInput>;
  current_team_start_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  custom_field_white_list_array?: InputMaybe<UserCustomFieldFilterUpdateManyWithoutUserInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemUpdateManyWithoutUserInput>;
  daily_lead_claim?: InputMaybe<IntFieldUpdateOperationsInput>;
  dashboard_2_access?: InputMaybe<BoolFieldUpdateOperationsInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewUpdateManyWithoutUserInput>;
  default_audio_input?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  default_audio_output?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  default_availability?: InputMaybe<BoolFieldUpdateOperationsInput>;
  deleted_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  deprecate_old_session?: InputMaybe<BoolFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordUpdateManyWithoutUserInput>;
  favorite_leads?: InputMaybe<LeadUpdateManyWithoutFavorited_By_UsersInput>;
  first_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  folder_permissions?: InputMaybe<FolderPermissionUpdateManyWithoutUserInput>;
  folders?: InputMaybe<FolderUpdateManyWithoutOwnerInput>;
  from_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutOld_UserInput>;
  GlobalRouting?: InputMaybe<GlobalRepRoutingUpdateManyWithoutRepInput>;
  google_refresh_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  holds_per_prospect_vs_held_demo_per_dial?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  hook_subscriptions?: InputMaybe<WebHookSubscriptionUpdateManyWithoutUserInput>;
  hubspot_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  idle_owned_leads?: InputMaybe<LeadUpdateManyWithoutSecondary_RepsInput>;
  industry_filter?: InputMaybe<BoolFieldUpdateOperationsInput>;
  industry_white_list_array?: InputMaybe<UserUpdateindustry_White_List_ArrayInput>;
  industry_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  joinMe_access_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  joinMe_refresh_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  last_demo_transferred_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  last_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutUserInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryUpdateManyWithoutUserInput>;
  lead_intents?: InputMaybe<LeadIntentUpdateManyWithoutUserInput>;
  lead_source_filter?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lead_source_white_list_array?: InputMaybe<UserUpdatelead_Source_White_List_ArrayInput>;
  lead_source_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  leads_last_called?: InputMaybe<LeadUpdateManyWithoutLast_Call_Result_RepInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusUpdateManyWithoutUserInput>;
  manager_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  note_item?: InputMaybe<NoteItemUpdateManyWithoutUserInput>;
  notifications?: InputMaybe<NotificationUpdateManyWithoutUserInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutUsersInput>;
  owned_leads?: InputMaybe<LeadUpdateManyWithoutRepInput>;
  panda_doc_access?: InputMaybe<BoolFieldUpdateOperationsInput>;
  password?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  payment_settings?: InputMaybe<OrganizationPaymentSettingsUpdateManyWithoutCreated_ByInput>;
  payment_settings_updated?: InputMaybe<OrganizationPaymentSettingsUpdateManyWithoutLast_Updated_ByInput>;
  phone_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  post?: InputMaybe<PostUpdateManyWithoutAuthorInput>;
  ProductsCreated?: InputMaybe<ProductChangeLogUpdateManyWithoutUpdated_ByInput>;
  profile_image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  receive_transfer_attempts?: InputMaybe<TransferAttemptUpdateManyWithoutTo_UserInput>;
  receiver_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutReceiver_UserInput>;
  regions_white_list_array?: InputMaybe<UserUpdateregions_White_List_ArrayInput>;
  regions_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  rep_scores?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumRoleFieldUpdateOperationsInput>;
  routings?: InputMaybe<RoutingUpdateManyWithoutRepInput>;
  sale_config_updated?: InputMaybe<SaleConfigUpdateManyWithoutLast_Updated_ByInput>;
  sales_team_column_options?: InputMaybe<UserUpdatesales_Team_Column_OptionsInput>;
  schedule_items?: InputMaybe<ScheduleItemUpdateManyWithoutUserInput>;
  send_transfer_attempts?: InputMaybe<TransferAttemptUpdateManyWithoutFrom_UserInput>;
  sender_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutSender_UserInput>;
  sequence_entry_criteria_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SequenceActionLog?: InputMaybe<StepActionLogUpdateManyWithoutRepInput>;
  sequenceDashboardView?: InputMaybe<SequenceDashboardViewUpdateManyWithoutOwned_ByInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutUserInput>;
  Sequences_created?: InputMaybe<SequenceUpdateManyWithoutCreated_ByInput>;
  Sequences_modified_by_user?: InputMaybe<SequenceUpdateManyWithoutLast_Updated_ByInput>;
  session_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  set_user_cycle?: InputMaybe<SaleCycleUpdateManyWithoutSet_UserInput>;
  set_user_stat?: InputMaybe<StatLeadAggregationUpdateManyWithoutSet_UserInput>;
  sf_user_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SmsItems?: InputMaybe<SmsItemUpdateManyWithoutRepInput>;
  SnoozeTracking?: InputMaybe<SnoozeTrackingUpdateManyWithoutRepInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  statusHistory?: InputMaybe<UserStatusHistoryUpdateManyWithoutUserInput>;
  sub_industry_white_list_array?: InputMaybe<UserUpdatesub_Industry_White_List_ArrayInput>;
  sub_industry_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  support_rep?: InputMaybe<BoolFieldUpdateOperationsInput>;
  taking_demos?: InputMaybe<BoolFieldUpdateOperationsInput>;
  team?: InputMaybe<TeamUpdateOneWithoutMembersInput>;
  Template?: InputMaybe<TemplateUpdateManyWithoutLast_Modified_ByInput>;
  TemplateVersion?: InputMaybe<TemplateVersionUpdateManyWithoutCreated_ByInput>;
  timekit_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timekit_type?: InputMaybe<EnumTimekittypeFieldUpdateOperationsInput>;
  timeOffRequest?: InputMaybe<TimeOffRequestUpdateManyWithoutRequesting_UserInput>;
  timezone_raw?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transfer_type?: InputMaybe<EnumTransferTypeFieldUpdateOperationsInput>;
  transfer_user_cycle?: InputMaybe<SaleCycleUpdateManyWithoutTransfer_UserInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user_custom_availability?: InputMaybe<AvailabilityUpdateManyWithoutUserInput>;
  user_past_tokens?: InputMaybe<UserPastTokensUpdateManyWithoutUserInput>;
  visible_all_leads_sm?: InputMaybe<BoolFieldUpdateOperationsInput>;
  visible_call_report_columns?: InputMaybe<UserUpdatevisible_Call_Report_ColumnsInput>;
  visible_call_report_event_columns?: InputMaybe<UserUpdatevisible_Call_Report_Event_ColumnsInput>;
  visible_call_report_pipeline_columns?: InputMaybe<UserUpdatevisible_Call_Report_Pipeline_ColumnsInput>;
  visible_my_leads_columns?: InputMaybe<UserUpdatevisible_My_Leads_ColumnsInput>;
  voicemail_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutSchedule_ItemsInput = {
  ai_user?: InputMaybe<BoolFieldUpdateOperationsInput>;
  alternate_email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  always_show_calendar_options?: InputMaybe<BoolFieldUpdateOperationsInput>;
  associate_owner_rep_history?: InputMaybe<AssociationHistoryUpdateManyWithoutRepInput>;
  associate_user_history?: InputMaybe<AssociationHistoryUpdateManyWithoutUserInput>;
  auto_transfer_selection_receive_attempts?: InputMaybe<AutoTransferSelectionUpdateManyWithoutFrom_UserInput>;
  auto_transfer_selection_send_attempts?: InputMaybe<AutoTransferSelectionUpdateManyWithoutTo_UserInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptUpdateManyWithoutUserInput>;
  callSharingAsCoach?: InputMaybe<CallSharingUpdateManyWithoutCoachInput>;
  callSharingAsUser?: InputMaybe<CallSharingUpdateManyWithoutReceiver_UserInput>;
  channel?: InputMaybe<NullableEnumChannelfilterFieldUpdateOperationsInput>;
  close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  close_user_cycle?: InputMaybe<SaleCycleUpdateManyWithoutClose_UserInput>;
  close_user_stat?: InputMaybe<StatLeadAggregationUpdateManyWithoutClose_UserInput>;
  commissions?: InputMaybe<CommissionUpdateManyWithoutUserInput>;
  contact_requests?: InputMaybe<ContactRequestUpdateManyWithoutRequesting_UserInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  current_sequence_view?: InputMaybe<SequenceDashboardViewUpdateOneWithoutUserInput>;
  current_team_start_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  custom_field_white_list_array?: InputMaybe<UserCustomFieldFilterUpdateManyWithoutUserInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemUpdateManyWithoutUserInput>;
  daily_lead_claim?: InputMaybe<IntFieldUpdateOperationsInput>;
  dashboard_2_access?: InputMaybe<BoolFieldUpdateOperationsInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewUpdateManyWithoutUserInput>;
  default_audio_input?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  default_audio_output?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  default_availability?: InputMaybe<BoolFieldUpdateOperationsInput>;
  deleted_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  deprecate_old_session?: InputMaybe<BoolFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordUpdateManyWithoutUserInput>;
  favorite_leads?: InputMaybe<LeadUpdateManyWithoutFavorited_By_UsersInput>;
  first_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  folder_permissions?: InputMaybe<FolderPermissionUpdateManyWithoutUserInput>;
  folders?: InputMaybe<FolderUpdateManyWithoutOwnerInput>;
  from_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutOld_UserInput>;
  GlobalRouting?: InputMaybe<GlobalRepRoutingUpdateManyWithoutRepInput>;
  google_refresh_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  holds_per_prospect_vs_held_demo_per_dial?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  hook_subscriptions?: InputMaybe<WebHookSubscriptionUpdateManyWithoutUserInput>;
  hubspot_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  idle_owned_leads?: InputMaybe<LeadUpdateManyWithoutSecondary_RepsInput>;
  industry_filter?: InputMaybe<BoolFieldUpdateOperationsInput>;
  industry_white_list_array?: InputMaybe<UserUpdateindustry_White_List_ArrayInput>;
  industry_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  joinMe_access_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  joinMe_refresh_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  last_demo_transferred_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  last_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutUserInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryUpdateManyWithoutUserInput>;
  lead_intents?: InputMaybe<LeadIntentUpdateManyWithoutUserInput>;
  lead_source_filter?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lead_source_white_list_array?: InputMaybe<UserUpdatelead_Source_White_List_ArrayInput>;
  lead_source_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  leads_last_called?: InputMaybe<LeadUpdateManyWithoutLast_Call_Result_RepInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusUpdateManyWithoutUserInput>;
  manager_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  note_item?: InputMaybe<NoteItemUpdateManyWithoutUserInput>;
  notifications?: InputMaybe<NotificationUpdateManyWithoutUserInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutUsersInput>;
  owned_leads?: InputMaybe<LeadUpdateManyWithoutRepInput>;
  panda_doc_access?: InputMaybe<BoolFieldUpdateOperationsInput>;
  password?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  payment_settings?: InputMaybe<OrganizationPaymentSettingsUpdateManyWithoutCreated_ByInput>;
  payment_settings_updated?: InputMaybe<OrganizationPaymentSettingsUpdateManyWithoutLast_Updated_ByInput>;
  phone_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  post?: InputMaybe<PostUpdateManyWithoutAuthorInput>;
  ProductsCreated?: InputMaybe<ProductChangeLogUpdateManyWithoutUpdated_ByInput>;
  profile_image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  receive_transfer_attempts?: InputMaybe<TransferAttemptUpdateManyWithoutTo_UserInput>;
  receiver_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutReceiver_UserInput>;
  regions_white_list_array?: InputMaybe<UserUpdateregions_White_List_ArrayInput>;
  regions_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  rep_scores?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumRoleFieldUpdateOperationsInput>;
  routings?: InputMaybe<RoutingUpdateManyWithoutRepInput>;
  sale_config?: InputMaybe<SaleConfigUpdateManyWithoutCreated_ByInput>;
  sale_config_updated?: InputMaybe<SaleConfigUpdateManyWithoutLast_Updated_ByInput>;
  sales_team_column_options?: InputMaybe<UserUpdatesales_Team_Column_OptionsInput>;
  send_transfer_attempts?: InputMaybe<TransferAttemptUpdateManyWithoutFrom_UserInput>;
  sender_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutSender_UserInput>;
  sequence_entry_criteria_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SequenceActionLog?: InputMaybe<StepActionLogUpdateManyWithoutRepInput>;
  sequenceDashboardView?: InputMaybe<SequenceDashboardViewUpdateManyWithoutOwned_ByInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutUserInput>;
  Sequences_created?: InputMaybe<SequenceUpdateManyWithoutCreated_ByInput>;
  Sequences_modified_by_user?: InputMaybe<SequenceUpdateManyWithoutLast_Updated_ByInput>;
  session_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  set_user_cycle?: InputMaybe<SaleCycleUpdateManyWithoutSet_UserInput>;
  set_user_stat?: InputMaybe<StatLeadAggregationUpdateManyWithoutSet_UserInput>;
  sf_user_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SmsItems?: InputMaybe<SmsItemUpdateManyWithoutRepInput>;
  SnoozeTracking?: InputMaybe<SnoozeTrackingUpdateManyWithoutRepInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  statusHistory?: InputMaybe<UserStatusHistoryUpdateManyWithoutUserInput>;
  sub_industry_white_list_array?: InputMaybe<UserUpdatesub_Industry_White_List_ArrayInput>;
  sub_industry_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  support_rep?: InputMaybe<BoolFieldUpdateOperationsInput>;
  taking_demos?: InputMaybe<BoolFieldUpdateOperationsInput>;
  team?: InputMaybe<TeamUpdateOneWithoutMembersInput>;
  Template?: InputMaybe<TemplateUpdateManyWithoutLast_Modified_ByInput>;
  TemplateVersion?: InputMaybe<TemplateVersionUpdateManyWithoutCreated_ByInput>;
  timekit_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timekit_type?: InputMaybe<EnumTimekittypeFieldUpdateOperationsInput>;
  timeOffRequest?: InputMaybe<TimeOffRequestUpdateManyWithoutRequesting_UserInput>;
  timezone_raw?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transfer_type?: InputMaybe<EnumTransferTypeFieldUpdateOperationsInput>;
  transfer_user_cycle?: InputMaybe<SaleCycleUpdateManyWithoutTransfer_UserInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user_custom_availability?: InputMaybe<AvailabilityUpdateManyWithoutUserInput>;
  user_past_tokens?: InputMaybe<UserPastTokensUpdateManyWithoutUserInput>;
  visible_all_leads_sm?: InputMaybe<BoolFieldUpdateOperationsInput>;
  visible_call_report_columns?: InputMaybe<UserUpdatevisible_Call_Report_ColumnsInput>;
  visible_call_report_event_columns?: InputMaybe<UserUpdatevisible_Call_Report_Event_ColumnsInput>;
  visible_call_report_pipeline_columns?: InputMaybe<UserUpdatevisible_Call_Report_Pipeline_ColumnsInput>;
  visible_my_leads_columns?: InputMaybe<UserUpdatevisible_My_Leads_ColumnsInput>;
  voicemail_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutSend_Transfer_AttemptsInput = {
  ai_user?: InputMaybe<BoolFieldUpdateOperationsInput>;
  alternate_email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  always_show_calendar_options?: InputMaybe<BoolFieldUpdateOperationsInput>;
  associate_owner_rep_history?: InputMaybe<AssociationHistoryUpdateManyWithoutRepInput>;
  associate_user_history?: InputMaybe<AssociationHistoryUpdateManyWithoutUserInput>;
  auto_transfer_selection_receive_attempts?: InputMaybe<AutoTransferSelectionUpdateManyWithoutFrom_UserInput>;
  auto_transfer_selection_send_attempts?: InputMaybe<AutoTransferSelectionUpdateManyWithoutTo_UserInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptUpdateManyWithoutUserInput>;
  callSharingAsCoach?: InputMaybe<CallSharingUpdateManyWithoutCoachInput>;
  callSharingAsUser?: InputMaybe<CallSharingUpdateManyWithoutReceiver_UserInput>;
  channel?: InputMaybe<NullableEnumChannelfilterFieldUpdateOperationsInput>;
  close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  close_user_cycle?: InputMaybe<SaleCycleUpdateManyWithoutClose_UserInput>;
  close_user_stat?: InputMaybe<StatLeadAggregationUpdateManyWithoutClose_UserInput>;
  commissions?: InputMaybe<CommissionUpdateManyWithoutUserInput>;
  contact_requests?: InputMaybe<ContactRequestUpdateManyWithoutRequesting_UserInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  current_sequence_view?: InputMaybe<SequenceDashboardViewUpdateOneWithoutUserInput>;
  current_team_start_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  custom_field_white_list_array?: InputMaybe<UserCustomFieldFilterUpdateManyWithoutUserInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemUpdateManyWithoutUserInput>;
  daily_lead_claim?: InputMaybe<IntFieldUpdateOperationsInput>;
  dashboard_2_access?: InputMaybe<BoolFieldUpdateOperationsInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewUpdateManyWithoutUserInput>;
  default_audio_input?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  default_audio_output?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  default_availability?: InputMaybe<BoolFieldUpdateOperationsInput>;
  deleted_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  deprecate_old_session?: InputMaybe<BoolFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordUpdateManyWithoutUserInput>;
  favorite_leads?: InputMaybe<LeadUpdateManyWithoutFavorited_By_UsersInput>;
  first_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  folder_permissions?: InputMaybe<FolderPermissionUpdateManyWithoutUserInput>;
  folders?: InputMaybe<FolderUpdateManyWithoutOwnerInput>;
  from_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutOld_UserInput>;
  GlobalRouting?: InputMaybe<GlobalRepRoutingUpdateManyWithoutRepInput>;
  google_refresh_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  holds_per_prospect_vs_held_demo_per_dial?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  hook_subscriptions?: InputMaybe<WebHookSubscriptionUpdateManyWithoutUserInput>;
  hubspot_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  idle_owned_leads?: InputMaybe<LeadUpdateManyWithoutSecondary_RepsInput>;
  industry_filter?: InputMaybe<BoolFieldUpdateOperationsInput>;
  industry_white_list_array?: InputMaybe<UserUpdateindustry_White_List_ArrayInput>;
  industry_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  joinMe_access_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  joinMe_refresh_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  last_demo_transferred_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  last_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutUserInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryUpdateManyWithoutUserInput>;
  lead_intents?: InputMaybe<LeadIntentUpdateManyWithoutUserInput>;
  lead_source_filter?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lead_source_white_list_array?: InputMaybe<UserUpdatelead_Source_White_List_ArrayInput>;
  lead_source_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  leads_last_called?: InputMaybe<LeadUpdateManyWithoutLast_Call_Result_RepInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusUpdateManyWithoutUserInput>;
  manager_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  note_item?: InputMaybe<NoteItemUpdateManyWithoutUserInput>;
  notifications?: InputMaybe<NotificationUpdateManyWithoutUserInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutUsersInput>;
  owned_leads?: InputMaybe<LeadUpdateManyWithoutRepInput>;
  panda_doc_access?: InputMaybe<BoolFieldUpdateOperationsInput>;
  password?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  payment_settings?: InputMaybe<OrganizationPaymentSettingsUpdateManyWithoutCreated_ByInput>;
  payment_settings_updated?: InputMaybe<OrganizationPaymentSettingsUpdateManyWithoutLast_Updated_ByInput>;
  phone_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  post?: InputMaybe<PostUpdateManyWithoutAuthorInput>;
  ProductsCreated?: InputMaybe<ProductChangeLogUpdateManyWithoutUpdated_ByInput>;
  profile_image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  receive_transfer_attempts?: InputMaybe<TransferAttemptUpdateManyWithoutTo_UserInput>;
  receiver_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutReceiver_UserInput>;
  regions_white_list_array?: InputMaybe<UserUpdateregions_White_List_ArrayInput>;
  regions_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  rep_scores?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumRoleFieldUpdateOperationsInput>;
  routings?: InputMaybe<RoutingUpdateManyWithoutRepInput>;
  sale_config?: InputMaybe<SaleConfigUpdateManyWithoutCreated_ByInput>;
  sale_config_updated?: InputMaybe<SaleConfigUpdateManyWithoutLast_Updated_ByInput>;
  sales_team_column_options?: InputMaybe<UserUpdatesales_Team_Column_OptionsInput>;
  schedule_items?: InputMaybe<ScheduleItemUpdateManyWithoutUserInput>;
  sender_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutSender_UserInput>;
  sequence_entry_criteria_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SequenceActionLog?: InputMaybe<StepActionLogUpdateManyWithoutRepInput>;
  sequenceDashboardView?: InputMaybe<SequenceDashboardViewUpdateManyWithoutOwned_ByInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutUserInput>;
  Sequences_created?: InputMaybe<SequenceUpdateManyWithoutCreated_ByInput>;
  Sequences_modified_by_user?: InputMaybe<SequenceUpdateManyWithoutLast_Updated_ByInput>;
  session_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  set_user_cycle?: InputMaybe<SaleCycleUpdateManyWithoutSet_UserInput>;
  set_user_stat?: InputMaybe<StatLeadAggregationUpdateManyWithoutSet_UserInput>;
  sf_user_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SmsItems?: InputMaybe<SmsItemUpdateManyWithoutRepInput>;
  SnoozeTracking?: InputMaybe<SnoozeTrackingUpdateManyWithoutRepInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  statusHistory?: InputMaybe<UserStatusHistoryUpdateManyWithoutUserInput>;
  sub_industry_white_list_array?: InputMaybe<UserUpdatesub_Industry_White_List_ArrayInput>;
  sub_industry_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  support_rep?: InputMaybe<BoolFieldUpdateOperationsInput>;
  taking_demos?: InputMaybe<BoolFieldUpdateOperationsInput>;
  team?: InputMaybe<TeamUpdateOneWithoutMembersInput>;
  Template?: InputMaybe<TemplateUpdateManyWithoutLast_Modified_ByInput>;
  TemplateVersion?: InputMaybe<TemplateVersionUpdateManyWithoutCreated_ByInput>;
  timekit_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timekit_type?: InputMaybe<EnumTimekittypeFieldUpdateOperationsInput>;
  timeOffRequest?: InputMaybe<TimeOffRequestUpdateManyWithoutRequesting_UserInput>;
  timezone_raw?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transfer_type?: InputMaybe<EnumTransferTypeFieldUpdateOperationsInput>;
  transfer_user_cycle?: InputMaybe<SaleCycleUpdateManyWithoutTransfer_UserInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user_custom_availability?: InputMaybe<AvailabilityUpdateManyWithoutUserInput>;
  user_past_tokens?: InputMaybe<UserPastTokensUpdateManyWithoutUserInput>;
  visible_all_leads_sm?: InputMaybe<BoolFieldUpdateOperationsInput>;
  visible_call_report_columns?: InputMaybe<UserUpdatevisible_Call_Report_ColumnsInput>;
  visible_call_report_event_columns?: InputMaybe<UserUpdatevisible_Call_Report_Event_ColumnsInput>;
  visible_call_report_pipeline_columns?: InputMaybe<UserUpdatevisible_Call_Report_Pipeline_ColumnsInput>;
  visible_my_leads_columns?: InputMaybe<UserUpdatevisible_My_Leads_ColumnsInput>;
  voicemail_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutSender_Assignment_HistoryInput = {
  ai_user?: InputMaybe<BoolFieldUpdateOperationsInput>;
  alternate_email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  always_show_calendar_options?: InputMaybe<BoolFieldUpdateOperationsInput>;
  associate_owner_rep_history?: InputMaybe<AssociationHistoryUpdateManyWithoutRepInput>;
  associate_user_history?: InputMaybe<AssociationHistoryUpdateManyWithoutUserInput>;
  auto_transfer_selection_receive_attempts?: InputMaybe<AutoTransferSelectionUpdateManyWithoutFrom_UserInput>;
  auto_transfer_selection_send_attempts?: InputMaybe<AutoTransferSelectionUpdateManyWithoutTo_UserInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptUpdateManyWithoutUserInput>;
  callSharingAsCoach?: InputMaybe<CallSharingUpdateManyWithoutCoachInput>;
  callSharingAsUser?: InputMaybe<CallSharingUpdateManyWithoutReceiver_UserInput>;
  channel?: InputMaybe<NullableEnumChannelfilterFieldUpdateOperationsInput>;
  close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  close_user_cycle?: InputMaybe<SaleCycleUpdateManyWithoutClose_UserInput>;
  close_user_stat?: InputMaybe<StatLeadAggregationUpdateManyWithoutClose_UserInput>;
  commissions?: InputMaybe<CommissionUpdateManyWithoutUserInput>;
  contact_requests?: InputMaybe<ContactRequestUpdateManyWithoutRequesting_UserInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  current_sequence_view?: InputMaybe<SequenceDashboardViewUpdateOneWithoutUserInput>;
  current_team_start_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  custom_field_white_list_array?: InputMaybe<UserCustomFieldFilterUpdateManyWithoutUserInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemUpdateManyWithoutUserInput>;
  daily_lead_claim?: InputMaybe<IntFieldUpdateOperationsInput>;
  dashboard_2_access?: InputMaybe<BoolFieldUpdateOperationsInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewUpdateManyWithoutUserInput>;
  default_audio_input?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  default_audio_output?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  default_availability?: InputMaybe<BoolFieldUpdateOperationsInput>;
  deleted_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  deprecate_old_session?: InputMaybe<BoolFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordUpdateManyWithoutUserInput>;
  favorite_leads?: InputMaybe<LeadUpdateManyWithoutFavorited_By_UsersInput>;
  first_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  folder_permissions?: InputMaybe<FolderPermissionUpdateManyWithoutUserInput>;
  folders?: InputMaybe<FolderUpdateManyWithoutOwnerInput>;
  from_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutOld_UserInput>;
  GlobalRouting?: InputMaybe<GlobalRepRoutingUpdateManyWithoutRepInput>;
  google_refresh_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  holds_per_prospect_vs_held_demo_per_dial?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  hook_subscriptions?: InputMaybe<WebHookSubscriptionUpdateManyWithoutUserInput>;
  hubspot_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  idle_owned_leads?: InputMaybe<LeadUpdateManyWithoutSecondary_RepsInput>;
  industry_filter?: InputMaybe<BoolFieldUpdateOperationsInput>;
  industry_white_list_array?: InputMaybe<UserUpdateindustry_White_List_ArrayInput>;
  industry_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  joinMe_access_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  joinMe_refresh_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  last_demo_transferred_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  last_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutUserInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryUpdateManyWithoutUserInput>;
  lead_intents?: InputMaybe<LeadIntentUpdateManyWithoutUserInput>;
  lead_source_filter?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lead_source_white_list_array?: InputMaybe<UserUpdatelead_Source_White_List_ArrayInput>;
  lead_source_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  leads_last_called?: InputMaybe<LeadUpdateManyWithoutLast_Call_Result_RepInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusUpdateManyWithoutUserInput>;
  manager_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  note_item?: InputMaybe<NoteItemUpdateManyWithoutUserInput>;
  notifications?: InputMaybe<NotificationUpdateManyWithoutUserInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutUsersInput>;
  owned_leads?: InputMaybe<LeadUpdateManyWithoutRepInput>;
  panda_doc_access?: InputMaybe<BoolFieldUpdateOperationsInput>;
  password?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  payment_settings?: InputMaybe<OrganizationPaymentSettingsUpdateManyWithoutCreated_ByInput>;
  payment_settings_updated?: InputMaybe<OrganizationPaymentSettingsUpdateManyWithoutLast_Updated_ByInput>;
  phone_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  post?: InputMaybe<PostUpdateManyWithoutAuthorInput>;
  ProductsCreated?: InputMaybe<ProductChangeLogUpdateManyWithoutUpdated_ByInput>;
  profile_image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  receive_transfer_attempts?: InputMaybe<TransferAttemptUpdateManyWithoutTo_UserInput>;
  receiver_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutReceiver_UserInput>;
  regions_white_list_array?: InputMaybe<UserUpdateregions_White_List_ArrayInput>;
  regions_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  rep_scores?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumRoleFieldUpdateOperationsInput>;
  routings?: InputMaybe<RoutingUpdateManyWithoutRepInput>;
  sale_config?: InputMaybe<SaleConfigUpdateManyWithoutCreated_ByInput>;
  sale_config_updated?: InputMaybe<SaleConfigUpdateManyWithoutLast_Updated_ByInput>;
  sales_team_column_options?: InputMaybe<UserUpdatesales_Team_Column_OptionsInput>;
  schedule_items?: InputMaybe<ScheduleItemUpdateManyWithoutUserInput>;
  send_transfer_attempts?: InputMaybe<TransferAttemptUpdateManyWithoutFrom_UserInput>;
  sequence_entry_criteria_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SequenceActionLog?: InputMaybe<StepActionLogUpdateManyWithoutRepInput>;
  sequenceDashboardView?: InputMaybe<SequenceDashboardViewUpdateManyWithoutOwned_ByInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutUserInput>;
  Sequences_created?: InputMaybe<SequenceUpdateManyWithoutCreated_ByInput>;
  Sequences_modified_by_user?: InputMaybe<SequenceUpdateManyWithoutLast_Updated_ByInput>;
  session_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  set_user_cycle?: InputMaybe<SaleCycleUpdateManyWithoutSet_UserInput>;
  set_user_stat?: InputMaybe<StatLeadAggregationUpdateManyWithoutSet_UserInput>;
  sf_user_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SmsItems?: InputMaybe<SmsItemUpdateManyWithoutRepInput>;
  SnoozeTracking?: InputMaybe<SnoozeTrackingUpdateManyWithoutRepInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  statusHistory?: InputMaybe<UserStatusHistoryUpdateManyWithoutUserInput>;
  sub_industry_white_list_array?: InputMaybe<UserUpdatesub_Industry_White_List_ArrayInput>;
  sub_industry_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  support_rep?: InputMaybe<BoolFieldUpdateOperationsInput>;
  taking_demos?: InputMaybe<BoolFieldUpdateOperationsInput>;
  team?: InputMaybe<TeamUpdateOneWithoutMembersInput>;
  Template?: InputMaybe<TemplateUpdateManyWithoutLast_Modified_ByInput>;
  TemplateVersion?: InputMaybe<TemplateVersionUpdateManyWithoutCreated_ByInput>;
  timekit_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timekit_type?: InputMaybe<EnumTimekittypeFieldUpdateOperationsInput>;
  timeOffRequest?: InputMaybe<TimeOffRequestUpdateManyWithoutRequesting_UserInput>;
  timezone_raw?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transfer_type?: InputMaybe<EnumTransferTypeFieldUpdateOperationsInput>;
  transfer_user_cycle?: InputMaybe<SaleCycleUpdateManyWithoutTransfer_UserInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user_custom_availability?: InputMaybe<AvailabilityUpdateManyWithoutUserInput>;
  user_past_tokens?: InputMaybe<UserPastTokensUpdateManyWithoutUserInput>;
  visible_all_leads_sm?: InputMaybe<BoolFieldUpdateOperationsInput>;
  visible_call_report_columns?: InputMaybe<UserUpdatevisible_Call_Report_ColumnsInput>;
  visible_call_report_event_columns?: InputMaybe<UserUpdatevisible_Call_Report_Event_ColumnsInput>;
  visible_call_report_pipeline_columns?: InputMaybe<UserUpdatevisible_Call_Report_Pipeline_ColumnsInput>;
  visible_my_leads_columns?: InputMaybe<UserUpdatevisible_My_Leads_ColumnsInput>;
  voicemail_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutSequenceActionLogInput = {
  ai_user?: InputMaybe<BoolFieldUpdateOperationsInput>;
  alternate_email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  always_show_calendar_options?: InputMaybe<BoolFieldUpdateOperationsInput>;
  associate_owner_rep_history?: InputMaybe<AssociationHistoryUpdateManyWithoutRepInput>;
  associate_user_history?: InputMaybe<AssociationHistoryUpdateManyWithoutUserInput>;
  auto_transfer_selection_receive_attempts?: InputMaybe<AutoTransferSelectionUpdateManyWithoutFrom_UserInput>;
  auto_transfer_selection_send_attempts?: InputMaybe<AutoTransferSelectionUpdateManyWithoutTo_UserInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptUpdateManyWithoutUserInput>;
  callSharingAsCoach?: InputMaybe<CallSharingUpdateManyWithoutCoachInput>;
  callSharingAsUser?: InputMaybe<CallSharingUpdateManyWithoutReceiver_UserInput>;
  channel?: InputMaybe<NullableEnumChannelfilterFieldUpdateOperationsInput>;
  close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  close_user_cycle?: InputMaybe<SaleCycleUpdateManyWithoutClose_UserInput>;
  close_user_stat?: InputMaybe<StatLeadAggregationUpdateManyWithoutClose_UserInput>;
  commissions?: InputMaybe<CommissionUpdateManyWithoutUserInput>;
  contact_requests?: InputMaybe<ContactRequestUpdateManyWithoutRequesting_UserInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  current_sequence_view?: InputMaybe<SequenceDashboardViewUpdateOneWithoutUserInput>;
  current_team_start_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  custom_field_white_list_array?: InputMaybe<UserCustomFieldFilterUpdateManyWithoutUserInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemUpdateManyWithoutUserInput>;
  daily_lead_claim?: InputMaybe<IntFieldUpdateOperationsInput>;
  dashboard_2_access?: InputMaybe<BoolFieldUpdateOperationsInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewUpdateManyWithoutUserInput>;
  default_audio_input?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  default_audio_output?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  default_availability?: InputMaybe<BoolFieldUpdateOperationsInput>;
  deleted_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  deprecate_old_session?: InputMaybe<BoolFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordUpdateManyWithoutUserInput>;
  favorite_leads?: InputMaybe<LeadUpdateManyWithoutFavorited_By_UsersInput>;
  first_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  folder_permissions?: InputMaybe<FolderPermissionUpdateManyWithoutUserInput>;
  folders?: InputMaybe<FolderUpdateManyWithoutOwnerInput>;
  from_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutOld_UserInput>;
  GlobalRouting?: InputMaybe<GlobalRepRoutingUpdateManyWithoutRepInput>;
  google_refresh_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  holds_per_prospect_vs_held_demo_per_dial?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  hook_subscriptions?: InputMaybe<WebHookSubscriptionUpdateManyWithoutUserInput>;
  hubspot_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  idle_owned_leads?: InputMaybe<LeadUpdateManyWithoutSecondary_RepsInput>;
  industry_filter?: InputMaybe<BoolFieldUpdateOperationsInput>;
  industry_white_list_array?: InputMaybe<UserUpdateindustry_White_List_ArrayInput>;
  industry_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  joinMe_access_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  joinMe_refresh_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  last_demo_transferred_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  last_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutUserInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryUpdateManyWithoutUserInput>;
  lead_intents?: InputMaybe<LeadIntentUpdateManyWithoutUserInput>;
  lead_source_filter?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lead_source_white_list_array?: InputMaybe<UserUpdatelead_Source_White_List_ArrayInput>;
  lead_source_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  leads_last_called?: InputMaybe<LeadUpdateManyWithoutLast_Call_Result_RepInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusUpdateManyWithoutUserInput>;
  manager_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  note_item?: InputMaybe<NoteItemUpdateManyWithoutUserInput>;
  notifications?: InputMaybe<NotificationUpdateManyWithoutUserInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutUsersInput>;
  owned_leads?: InputMaybe<LeadUpdateManyWithoutRepInput>;
  panda_doc_access?: InputMaybe<BoolFieldUpdateOperationsInput>;
  password?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  payment_settings?: InputMaybe<OrganizationPaymentSettingsUpdateManyWithoutCreated_ByInput>;
  payment_settings_updated?: InputMaybe<OrganizationPaymentSettingsUpdateManyWithoutLast_Updated_ByInput>;
  phone_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  post?: InputMaybe<PostUpdateManyWithoutAuthorInput>;
  ProductsCreated?: InputMaybe<ProductChangeLogUpdateManyWithoutUpdated_ByInput>;
  profile_image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  receive_transfer_attempts?: InputMaybe<TransferAttemptUpdateManyWithoutTo_UserInput>;
  receiver_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutReceiver_UserInput>;
  regions_white_list_array?: InputMaybe<UserUpdateregions_White_List_ArrayInput>;
  regions_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  rep_scores?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumRoleFieldUpdateOperationsInput>;
  routings?: InputMaybe<RoutingUpdateManyWithoutRepInput>;
  sale_config?: InputMaybe<SaleConfigUpdateManyWithoutCreated_ByInput>;
  sale_config_updated?: InputMaybe<SaleConfigUpdateManyWithoutLast_Updated_ByInput>;
  sales_team_column_options?: InputMaybe<UserUpdatesales_Team_Column_OptionsInput>;
  schedule_items?: InputMaybe<ScheduleItemUpdateManyWithoutUserInput>;
  send_transfer_attempts?: InputMaybe<TransferAttemptUpdateManyWithoutFrom_UserInput>;
  sender_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutSender_UserInput>;
  sequence_entry_criteria_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sequenceDashboardView?: InputMaybe<SequenceDashboardViewUpdateManyWithoutOwned_ByInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutUserInput>;
  Sequences_created?: InputMaybe<SequenceUpdateManyWithoutCreated_ByInput>;
  Sequences_modified_by_user?: InputMaybe<SequenceUpdateManyWithoutLast_Updated_ByInput>;
  session_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  set_user_cycle?: InputMaybe<SaleCycleUpdateManyWithoutSet_UserInput>;
  set_user_stat?: InputMaybe<StatLeadAggregationUpdateManyWithoutSet_UserInput>;
  sf_user_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SmsItems?: InputMaybe<SmsItemUpdateManyWithoutRepInput>;
  SnoozeTracking?: InputMaybe<SnoozeTrackingUpdateManyWithoutRepInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  statusHistory?: InputMaybe<UserStatusHistoryUpdateManyWithoutUserInput>;
  sub_industry_white_list_array?: InputMaybe<UserUpdatesub_Industry_White_List_ArrayInput>;
  sub_industry_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  support_rep?: InputMaybe<BoolFieldUpdateOperationsInput>;
  taking_demos?: InputMaybe<BoolFieldUpdateOperationsInput>;
  team?: InputMaybe<TeamUpdateOneWithoutMembersInput>;
  Template?: InputMaybe<TemplateUpdateManyWithoutLast_Modified_ByInput>;
  TemplateVersion?: InputMaybe<TemplateVersionUpdateManyWithoutCreated_ByInput>;
  timekit_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timekit_type?: InputMaybe<EnumTimekittypeFieldUpdateOperationsInput>;
  timeOffRequest?: InputMaybe<TimeOffRequestUpdateManyWithoutRequesting_UserInput>;
  timezone_raw?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transfer_type?: InputMaybe<EnumTransferTypeFieldUpdateOperationsInput>;
  transfer_user_cycle?: InputMaybe<SaleCycleUpdateManyWithoutTransfer_UserInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user_custom_availability?: InputMaybe<AvailabilityUpdateManyWithoutUserInput>;
  user_past_tokens?: InputMaybe<UserPastTokensUpdateManyWithoutUserInput>;
  visible_all_leads_sm?: InputMaybe<BoolFieldUpdateOperationsInput>;
  visible_call_report_columns?: InputMaybe<UserUpdatevisible_Call_Report_ColumnsInput>;
  visible_call_report_event_columns?: InputMaybe<UserUpdatevisible_Call_Report_Event_ColumnsInput>;
  visible_call_report_pipeline_columns?: InputMaybe<UserUpdatevisible_Call_Report_Pipeline_ColumnsInput>;
  visible_my_leads_columns?: InputMaybe<UserUpdatevisible_My_Leads_ColumnsInput>;
  voicemail_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutSequenceDashboardViewInput = {
  ai_user?: InputMaybe<BoolFieldUpdateOperationsInput>;
  alternate_email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  always_show_calendar_options?: InputMaybe<BoolFieldUpdateOperationsInput>;
  associate_owner_rep_history?: InputMaybe<AssociationHistoryUpdateManyWithoutRepInput>;
  associate_user_history?: InputMaybe<AssociationHistoryUpdateManyWithoutUserInput>;
  auto_transfer_selection_receive_attempts?: InputMaybe<AutoTransferSelectionUpdateManyWithoutFrom_UserInput>;
  auto_transfer_selection_send_attempts?: InputMaybe<AutoTransferSelectionUpdateManyWithoutTo_UserInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptUpdateManyWithoutUserInput>;
  callSharingAsCoach?: InputMaybe<CallSharingUpdateManyWithoutCoachInput>;
  callSharingAsUser?: InputMaybe<CallSharingUpdateManyWithoutReceiver_UserInput>;
  channel?: InputMaybe<NullableEnumChannelfilterFieldUpdateOperationsInput>;
  close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  close_user_cycle?: InputMaybe<SaleCycleUpdateManyWithoutClose_UserInput>;
  close_user_stat?: InputMaybe<StatLeadAggregationUpdateManyWithoutClose_UserInput>;
  commissions?: InputMaybe<CommissionUpdateManyWithoutUserInput>;
  contact_requests?: InputMaybe<ContactRequestUpdateManyWithoutRequesting_UserInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  current_sequence_view?: InputMaybe<SequenceDashboardViewUpdateOneWithoutUserInput>;
  current_team_start_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  custom_field_white_list_array?: InputMaybe<UserCustomFieldFilterUpdateManyWithoutUserInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemUpdateManyWithoutUserInput>;
  daily_lead_claim?: InputMaybe<IntFieldUpdateOperationsInput>;
  dashboard_2_access?: InputMaybe<BoolFieldUpdateOperationsInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewUpdateManyWithoutUserInput>;
  default_audio_input?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  default_audio_output?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  default_availability?: InputMaybe<BoolFieldUpdateOperationsInput>;
  deleted_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  deprecate_old_session?: InputMaybe<BoolFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordUpdateManyWithoutUserInput>;
  favorite_leads?: InputMaybe<LeadUpdateManyWithoutFavorited_By_UsersInput>;
  first_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  folder_permissions?: InputMaybe<FolderPermissionUpdateManyWithoutUserInput>;
  folders?: InputMaybe<FolderUpdateManyWithoutOwnerInput>;
  from_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutOld_UserInput>;
  GlobalRouting?: InputMaybe<GlobalRepRoutingUpdateManyWithoutRepInput>;
  google_refresh_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  holds_per_prospect_vs_held_demo_per_dial?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  hook_subscriptions?: InputMaybe<WebHookSubscriptionUpdateManyWithoutUserInput>;
  hubspot_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  idle_owned_leads?: InputMaybe<LeadUpdateManyWithoutSecondary_RepsInput>;
  industry_filter?: InputMaybe<BoolFieldUpdateOperationsInput>;
  industry_white_list_array?: InputMaybe<UserUpdateindustry_White_List_ArrayInput>;
  industry_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  joinMe_access_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  joinMe_refresh_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  last_demo_transferred_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  last_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutUserInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryUpdateManyWithoutUserInput>;
  lead_intents?: InputMaybe<LeadIntentUpdateManyWithoutUserInput>;
  lead_source_filter?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lead_source_white_list_array?: InputMaybe<UserUpdatelead_Source_White_List_ArrayInput>;
  lead_source_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  leads_last_called?: InputMaybe<LeadUpdateManyWithoutLast_Call_Result_RepInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusUpdateManyWithoutUserInput>;
  manager_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  note_item?: InputMaybe<NoteItemUpdateManyWithoutUserInput>;
  notifications?: InputMaybe<NotificationUpdateManyWithoutUserInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutUsersInput>;
  owned_leads?: InputMaybe<LeadUpdateManyWithoutRepInput>;
  panda_doc_access?: InputMaybe<BoolFieldUpdateOperationsInput>;
  password?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  payment_settings?: InputMaybe<OrganizationPaymentSettingsUpdateManyWithoutCreated_ByInput>;
  payment_settings_updated?: InputMaybe<OrganizationPaymentSettingsUpdateManyWithoutLast_Updated_ByInput>;
  phone_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  post?: InputMaybe<PostUpdateManyWithoutAuthorInput>;
  ProductsCreated?: InputMaybe<ProductChangeLogUpdateManyWithoutUpdated_ByInput>;
  profile_image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  receive_transfer_attempts?: InputMaybe<TransferAttemptUpdateManyWithoutTo_UserInput>;
  receiver_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutReceiver_UserInput>;
  regions_white_list_array?: InputMaybe<UserUpdateregions_White_List_ArrayInput>;
  regions_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  rep_scores?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumRoleFieldUpdateOperationsInput>;
  routings?: InputMaybe<RoutingUpdateManyWithoutRepInput>;
  sale_config?: InputMaybe<SaleConfigUpdateManyWithoutCreated_ByInput>;
  sale_config_updated?: InputMaybe<SaleConfigUpdateManyWithoutLast_Updated_ByInput>;
  sales_team_column_options?: InputMaybe<UserUpdatesales_Team_Column_OptionsInput>;
  schedule_items?: InputMaybe<ScheduleItemUpdateManyWithoutUserInput>;
  send_transfer_attempts?: InputMaybe<TransferAttemptUpdateManyWithoutFrom_UserInput>;
  sender_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutSender_UserInput>;
  sequence_entry_criteria_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SequenceActionLog?: InputMaybe<StepActionLogUpdateManyWithoutRepInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutUserInput>;
  Sequences_created?: InputMaybe<SequenceUpdateManyWithoutCreated_ByInput>;
  Sequences_modified_by_user?: InputMaybe<SequenceUpdateManyWithoutLast_Updated_ByInput>;
  session_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  set_user_cycle?: InputMaybe<SaleCycleUpdateManyWithoutSet_UserInput>;
  set_user_stat?: InputMaybe<StatLeadAggregationUpdateManyWithoutSet_UserInput>;
  sf_user_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SmsItems?: InputMaybe<SmsItemUpdateManyWithoutRepInput>;
  SnoozeTracking?: InputMaybe<SnoozeTrackingUpdateManyWithoutRepInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  statusHistory?: InputMaybe<UserStatusHistoryUpdateManyWithoutUserInput>;
  sub_industry_white_list_array?: InputMaybe<UserUpdatesub_Industry_White_List_ArrayInput>;
  sub_industry_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  support_rep?: InputMaybe<BoolFieldUpdateOperationsInput>;
  taking_demos?: InputMaybe<BoolFieldUpdateOperationsInput>;
  team?: InputMaybe<TeamUpdateOneWithoutMembersInput>;
  Template?: InputMaybe<TemplateUpdateManyWithoutLast_Modified_ByInput>;
  TemplateVersion?: InputMaybe<TemplateVersionUpdateManyWithoutCreated_ByInput>;
  timekit_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timekit_type?: InputMaybe<EnumTimekittypeFieldUpdateOperationsInput>;
  timeOffRequest?: InputMaybe<TimeOffRequestUpdateManyWithoutRequesting_UserInput>;
  timezone_raw?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transfer_type?: InputMaybe<EnumTransferTypeFieldUpdateOperationsInput>;
  transfer_user_cycle?: InputMaybe<SaleCycleUpdateManyWithoutTransfer_UserInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user_custom_availability?: InputMaybe<AvailabilityUpdateManyWithoutUserInput>;
  user_past_tokens?: InputMaybe<UserPastTokensUpdateManyWithoutUserInput>;
  visible_all_leads_sm?: InputMaybe<BoolFieldUpdateOperationsInput>;
  visible_call_report_columns?: InputMaybe<UserUpdatevisible_Call_Report_ColumnsInput>;
  visible_call_report_event_columns?: InputMaybe<UserUpdatevisible_Call_Report_Event_ColumnsInput>;
  visible_call_report_pipeline_columns?: InputMaybe<UserUpdatevisible_Call_Report_Pipeline_ColumnsInput>;
  visible_my_leads_columns?: InputMaybe<UserUpdatevisible_My_Leads_ColumnsInput>;
  voicemail_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutSequenceEntryExitLeadInput = {
  ai_user?: InputMaybe<BoolFieldUpdateOperationsInput>;
  alternate_email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  always_show_calendar_options?: InputMaybe<BoolFieldUpdateOperationsInput>;
  associate_owner_rep_history?: InputMaybe<AssociationHistoryUpdateManyWithoutRepInput>;
  associate_user_history?: InputMaybe<AssociationHistoryUpdateManyWithoutUserInput>;
  auto_transfer_selection_receive_attempts?: InputMaybe<AutoTransferSelectionUpdateManyWithoutFrom_UserInput>;
  auto_transfer_selection_send_attempts?: InputMaybe<AutoTransferSelectionUpdateManyWithoutTo_UserInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptUpdateManyWithoutUserInput>;
  callSharingAsCoach?: InputMaybe<CallSharingUpdateManyWithoutCoachInput>;
  callSharingAsUser?: InputMaybe<CallSharingUpdateManyWithoutReceiver_UserInput>;
  channel?: InputMaybe<NullableEnumChannelfilterFieldUpdateOperationsInput>;
  close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  close_user_cycle?: InputMaybe<SaleCycleUpdateManyWithoutClose_UserInput>;
  close_user_stat?: InputMaybe<StatLeadAggregationUpdateManyWithoutClose_UserInput>;
  commissions?: InputMaybe<CommissionUpdateManyWithoutUserInput>;
  contact_requests?: InputMaybe<ContactRequestUpdateManyWithoutRequesting_UserInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  current_sequence_view?: InputMaybe<SequenceDashboardViewUpdateOneWithoutUserInput>;
  current_team_start_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  custom_field_white_list_array?: InputMaybe<UserCustomFieldFilterUpdateManyWithoutUserInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemUpdateManyWithoutUserInput>;
  daily_lead_claim?: InputMaybe<IntFieldUpdateOperationsInput>;
  dashboard_2_access?: InputMaybe<BoolFieldUpdateOperationsInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewUpdateManyWithoutUserInput>;
  default_audio_input?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  default_audio_output?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  default_availability?: InputMaybe<BoolFieldUpdateOperationsInput>;
  deleted_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  deprecate_old_session?: InputMaybe<BoolFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordUpdateManyWithoutUserInput>;
  favorite_leads?: InputMaybe<LeadUpdateManyWithoutFavorited_By_UsersInput>;
  first_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  folder_permissions?: InputMaybe<FolderPermissionUpdateManyWithoutUserInput>;
  folders?: InputMaybe<FolderUpdateManyWithoutOwnerInput>;
  from_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutOld_UserInput>;
  GlobalRouting?: InputMaybe<GlobalRepRoutingUpdateManyWithoutRepInput>;
  google_refresh_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  holds_per_prospect_vs_held_demo_per_dial?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  hook_subscriptions?: InputMaybe<WebHookSubscriptionUpdateManyWithoutUserInput>;
  hubspot_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  idle_owned_leads?: InputMaybe<LeadUpdateManyWithoutSecondary_RepsInput>;
  industry_filter?: InputMaybe<BoolFieldUpdateOperationsInput>;
  industry_white_list_array?: InputMaybe<UserUpdateindustry_White_List_ArrayInput>;
  industry_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  joinMe_access_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  joinMe_refresh_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  last_demo_transferred_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  last_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutUserInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryUpdateManyWithoutUserInput>;
  lead_intents?: InputMaybe<LeadIntentUpdateManyWithoutUserInput>;
  lead_source_filter?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lead_source_white_list_array?: InputMaybe<UserUpdatelead_Source_White_List_ArrayInput>;
  lead_source_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  leads_last_called?: InputMaybe<LeadUpdateManyWithoutLast_Call_Result_RepInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusUpdateManyWithoutUserInput>;
  manager_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  note_item?: InputMaybe<NoteItemUpdateManyWithoutUserInput>;
  notifications?: InputMaybe<NotificationUpdateManyWithoutUserInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutUsersInput>;
  owned_leads?: InputMaybe<LeadUpdateManyWithoutRepInput>;
  panda_doc_access?: InputMaybe<BoolFieldUpdateOperationsInput>;
  password?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  payment_settings?: InputMaybe<OrganizationPaymentSettingsUpdateManyWithoutCreated_ByInput>;
  payment_settings_updated?: InputMaybe<OrganizationPaymentSettingsUpdateManyWithoutLast_Updated_ByInput>;
  phone_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  post?: InputMaybe<PostUpdateManyWithoutAuthorInput>;
  ProductsCreated?: InputMaybe<ProductChangeLogUpdateManyWithoutUpdated_ByInput>;
  profile_image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  receive_transfer_attempts?: InputMaybe<TransferAttemptUpdateManyWithoutTo_UserInput>;
  receiver_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutReceiver_UserInput>;
  regions_white_list_array?: InputMaybe<UserUpdateregions_White_List_ArrayInput>;
  regions_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  rep_scores?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumRoleFieldUpdateOperationsInput>;
  routings?: InputMaybe<RoutingUpdateManyWithoutRepInput>;
  sale_config?: InputMaybe<SaleConfigUpdateManyWithoutCreated_ByInput>;
  sale_config_updated?: InputMaybe<SaleConfigUpdateManyWithoutLast_Updated_ByInput>;
  sales_team_column_options?: InputMaybe<UserUpdatesales_Team_Column_OptionsInput>;
  schedule_items?: InputMaybe<ScheduleItemUpdateManyWithoutUserInput>;
  send_transfer_attempts?: InputMaybe<TransferAttemptUpdateManyWithoutFrom_UserInput>;
  sender_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutSender_UserInput>;
  sequence_entry_criteria_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SequenceActionLog?: InputMaybe<StepActionLogUpdateManyWithoutRepInput>;
  sequenceDashboardView?: InputMaybe<SequenceDashboardViewUpdateManyWithoutOwned_ByInput>;
  Sequences_created?: InputMaybe<SequenceUpdateManyWithoutCreated_ByInput>;
  Sequences_modified_by_user?: InputMaybe<SequenceUpdateManyWithoutLast_Updated_ByInput>;
  session_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  set_user_cycle?: InputMaybe<SaleCycleUpdateManyWithoutSet_UserInput>;
  set_user_stat?: InputMaybe<StatLeadAggregationUpdateManyWithoutSet_UserInput>;
  sf_user_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SmsItems?: InputMaybe<SmsItemUpdateManyWithoutRepInput>;
  SnoozeTracking?: InputMaybe<SnoozeTrackingUpdateManyWithoutRepInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  statusHistory?: InputMaybe<UserStatusHistoryUpdateManyWithoutUserInput>;
  sub_industry_white_list_array?: InputMaybe<UserUpdatesub_Industry_White_List_ArrayInput>;
  sub_industry_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  support_rep?: InputMaybe<BoolFieldUpdateOperationsInput>;
  taking_demos?: InputMaybe<BoolFieldUpdateOperationsInput>;
  team?: InputMaybe<TeamUpdateOneWithoutMembersInput>;
  Template?: InputMaybe<TemplateUpdateManyWithoutLast_Modified_ByInput>;
  TemplateVersion?: InputMaybe<TemplateVersionUpdateManyWithoutCreated_ByInput>;
  timekit_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timekit_type?: InputMaybe<EnumTimekittypeFieldUpdateOperationsInput>;
  timeOffRequest?: InputMaybe<TimeOffRequestUpdateManyWithoutRequesting_UserInput>;
  timezone_raw?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transfer_type?: InputMaybe<EnumTransferTypeFieldUpdateOperationsInput>;
  transfer_user_cycle?: InputMaybe<SaleCycleUpdateManyWithoutTransfer_UserInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user_custom_availability?: InputMaybe<AvailabilityUpdateManyWithoutUserInput>;
  user_past_tokens?: InputMaybe<UserPastTokensUpdateManyWithoutUserInput>;
  visible_all_leads_sm?: InputMaybe<BoolFieldUpdateOperationsInput>;
  visible_call_report_columns?: InputMaybe<UserUpdatevisible_Call_Report_ColumnsInput>;
  visible_call_report_event_columns?: InputMaybe<UserUpdatevisible_Call_Report_Event_ColumnsInput>;
  visible_call_report_pipeline_columns?: InputMaybe<UserUpdatevisible_Call_Report_Pipeline_ColumnsInput>;
  visible_my_leads_columns?: InputMaybe<UserUpdatevisible_My_Leads_ColumnsInput>;
  voicemail_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutSequences_CreatedInput = {
  ai_user?: InputMaybe<BoolFieldUpdateOperationsInput>;
  alternate_email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  always_show_calendar_options?: InputMaybe<BoolFieldUpdateOperationsInput>;
  associate_owner_rep_history?: InputMaybe<AssociationHistoryUpdateManyWithoutRepInput>;
  associate_user_history?: InputMaybe<AssociationHistoryUpdateManyWithoutUserInput>;
  auto_transfer_selection_receive_attempts?: InputMaybe<AutoTransferSelectionUpdateManyWithoutFrom_UserInput>;
  auto_transfer_selection_send_attempts?: InputMaybe<AutoTransferSelectionUpdateManyWithoutTo_UserInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptUpdateManyWithoutUserInput>;
  callSharingAsCoach?: InputMaybe<CallSharingUpdateManyWithoutCoachInput>;
  callSharingAsUser?: InputMaybe<CallSharingUpdateManyWithoutReceiver_UserInput>;
  channel?: InputMaybe<NullableEnumChannelfilterFieldUpdateOperationsInput>;
  close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  close_user_cycle?: InputMaybe<SaleCycleUpdateManyWithoutClose_UserInput>;
  close_user_stat?: InputMaybe<StatLeadAggregationUpdateManyWithoutClose_UserInput>;
  commissions?: InputMaybe<CommissionUpdateManyWithoutUserInput>;
  contact_requests?: InputMaybe<ContactRequestUpdateManyWithoutRequesting_UserInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  current_sequence_view?: InputMaybe<SequenceDashboardViewUpdateOneWithoutUserInput>;
  current_team_start_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  custom_field_white_list_array?: InputMaybe<UserCustomFieldFilterUpdateManyWithoutUserInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemUpdateManyWithoutUserInput>;
  daily_lead_claim?: InputMaybe<IntFieldUpdateOperationsInput>;
  dashboard_2_access?: InputMaybe<BoolFieldUpdateOperationsInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewUpdateManyWithoutUserInput>;
  default_audio_input?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  default_audio_output?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  default_availability?: InputMaybe<BoolFieldUpdateOperationsInput>;
  deleted_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  deprecate_old_session?: InputMaybe<BoolFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordUpdateManyWithoutUserInput>;
  favorite_leads?: InputMaybe<LeadUpdateManyWithoutFavorited_By_UsersInput>;
  first_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  folder_permissions?: InputMaybe<FolderPermissionUpdateManyWithoutUserInput>;
  folders?: InputMaybe<FolderUpdateManyWithoutOwnerInput>;
  from_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutOld_UserInput>;
  GlobalRouting?: InputMaybe<GlobalRepRoutingUpdateManyWithoutRepInput>;
  google_refresh_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  holds_per_prospect_vs_held_demo_per_dial?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  hook_subscriptions?: InputMaybe<WebHookSubscriptionUpdateManyWithoutUserInput>;
  hubspot_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  idle_owned_leads?: InputMaybe<LeadUpdateManyWithoutSecondary_RepsInput>;
  industry_filter?: InputMaybe<BoolFieldUpdateOperationsInput>;
  industry_white_list_array?: InputMaybe<UserUpdateindustry_White_List_ArrayInput>;
  industry_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  joinMe_access_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  joinMe_refresh_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  last_demo_transferred_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  last_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutUserInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryUpdateManyWithoutUserInput>;
  lead_intents?: InputMaybe<LeadIntentUpdateManyWithoutUserInput>;
  lead_source_filter?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lead_source_white_list_array?: InputMaybe<UserUpdatelead_Source_White_List_ArrayInput>;
  lead_source_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  leads_last_called?: InputMaybe<LeadUpdateManyWithoutLast_Call_Result_RepInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusUpdateManyWithoutUserInput>;
  manager_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  note_item?: InputMaybe<NoteItemUpdateManyWithoutUserInput>;
  notifications?: InputMaybe<NotificationUpdateManyWithoutUserInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutUsersInput>;
  owned_leads?: InputMaybe<LeadUpdateManyWithoutRepInput>;
  panda_doc_access?: InputMaybe<BoolFieldUpdateOperationsInput>;
  password?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  payment_settings?: InputMaybe<OrganizationPaymentSettingsUpdateManyWithoutCreated_ByInput>;
  payment_settings_updated?: InputMaybe<OrganizationPaymentSettingsUpdateManyWithoutLast_Updated_ByInput>;
  phone_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  post?: InputMaybe<PostUpdateManyWithoutAuthorInput>;
  ProductsCreated?: InputMaybe<ProductChangeLogUpdateManyWithoutUpdated_ByInput>;
  profile_image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  receive_transfer_attempts?: InputMaybe<TransferAttemptUpdateManyWithoutTo_UserInput>;
  receiver_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutReceiver_UserInput>;
  regions_white_list_array?: InputMaybe<UserUpdateregions_White_List_ArrayInput>;
  regions_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  rep_scores?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumRoleFieldUpdateOperationsInput>;
  routings?: InputMaybe<RoutingUpdateManyWithoutRepInput>;
  sale_config?: InputMaybe<SaleConfigUpdateManyWithoutCreated_ByInput>;
  sale_config_updated?: InputMaybe<SaleConfigUpdateManyWithoutLast_Updated_ByInput>;
  sales_team_column_options?: InputMaybe<UserUpdatesales_Team_Column_OptionsInput>;
  schedule_items?: InputMaybe<ScheduleItemUpdateManyWithoutUserInput>;
  send_transfer_attempts?: InputMaybe<TransferAttemptUpdateManyWithoutFrom_UserInput>;
  sender_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutSender_UserInput>;
  sequence_entry_criteria_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SequenceActionLog?: InputMaybe<StepActionLogUpdateManyWithoutRepInput>;
  sequenceDashboardView?: InputMaybe<SequenceDashboardViewUpdateManyWithoutOwned_ByInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutUserInput>;
  Sequences_modified_by_user?: InputMaybe<SequenceUpdateManyWithoutLast_Updated_ByInput>;
  session_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  set_user_cycle?: InputMaybe<SaleCycleUpdateManyWithoutSet_UserInput>;
  set_user_stat?: InputMaybe<StatLeadAggregationUpdateManyWithoutSet_UserInput>;
  sf_user_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SmsItems?: InputMaybe<SmsItemUpdateManyWithoutRepInput>;
  SnoozeTracking?: InputMaybe<SnoozeTrackingUpdateManyWithoutRepInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  statusHistory?: InputMaybe<UserStatusHistoryUpdateManyWithoutUserInput>;
  sub_industry_white_list_array?: InputMaybe<UserUpdatesub_Industry_White_List_ArrayInput>;
  sub_industry_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  support_rep?: InputMaybe<BoolFieldUpdateOperationsInput>;
  taking_demos?: InputMaybe<BoolFieldUpdateOperationsInput>;
  team?: InputMaybe<TeamUpdateOneWithoutMembersInput>;
  Template?: InputMaybe<TemplateUpdateManyWithoutLast_Modified_ByInput>;
  TemplateVersion?: InputMaybe<TemplateVersionUpdateManyWithoutCreated_ByInput>;
  timekit_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timekit_type?: InputMaybe<EnumTimekittypeFieldUpdateOperationsInput>;
  timeOffRequest?: InputMaybe<TimeOffRequestUpdateManyWithoutRequesting_UserInput>;
  timezone_raw?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transfer_type?: InputMaybe<EnumTransferTypeFieldUpdateOperationsInput>;
  transfer_user_cycle?: InputMaybe<SaleCycleUpdateManyWithoutTransfer_UserInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user_custom_availability?: InputMaybe<AvailabilityUpdateManyWithoutUserInput>;
  user_past_tokens?: InputMaybe<UserPastTokensUpdateManyWithoutUserInput>;
  visible_all_leads_sm?: InputMaybe<BoolFieldUpdateOperationsInput>;
  visible_call_report_columns?: InputMaybe<UserUpdatevisible_Call_Report_ColumnsInput>;
  visible_call_report_event_columns?: InputMaybe<UserUpdatevisible_Call_Report_Event_ColumnsInput>;
  visible_call_report_pipeline_columns?: InputMaybe<UserUpdatevisible_Call_Report_Pipeline_ColumnsInput>;
  visible_my_leads_columns?: InputMaybe<UserUpdatevisible_My_Leads_ColumnsInput>;
  voicemail_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutSequences_Modified_By_UserInput = {
  ai_user?: InputMaybe<BoolFieldUpdateOperationsInput>;
  alternate_email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  always_show_calendar_options?: InputMaybe<BoolFieldUpdateOperationsInput>;
  associate_owner_rep_history?: InputMaybe<AssociationHistoryUpdateManyWithoutRepInput>;
  associate_user_history?: InputMaybe<AssociationHistoryUpdateManyWithoutUserInput>;
  auto_transfer_selection_receive_attempts?: InputMaybe<AutoTransferSelectionUpdateManyWithoutFrom_UserInput>;
  auto_transfer_selection_send_attempts?: InputMaybe<AutoTransferSelectionUpdateManyWithoutTo_UserInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptUpdateManyWithoutUserInput>;
  callSharingAsCoach?: InputMaybe<CallSharingUpdateManyWithoutCoachInput>;
  callSharingAsUser?: InputMaybe<CallSharingUpdateManyWithoutReceiver_UserInput>;
  channel?: InputMaybe<NullableEnumChannelfilterFieldUpdateOperationsInput>;
  close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  close_user_cycle?: InputMaybe<SaleCycleUpdateManyWithoutClose_UserInput>;
  close_user_stat?: InputMaybe<StatLeadAggregationUpdateManyWithoutClose_UserInput>;
  commissions?: InputMaybe<CommissionUpdateManyWithoutUserInput>;
  contact_requests?: InputMaybe<ContactRequestUpdateManyWithoutRequesting_UserInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  current_sequence_view?: InputMaybe<SequenceDashboardViewUpdateOneWithoutUserInput>;
  current_team_start_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  custom_field_white_list_array?: InputMaybe<UserCustomFieldFilterUpdateManyWithoutUserInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemUpdateManyWithoutUserInput>;
  daily_lead_claim?: InputMaybe<IntFieldUpdateOperationsInput>;
  dashboard_2_access?: InputMaybe<BoolFieldUpdateOperationsInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewUpdateManyWithoutUserInput>;
  default_audio_input?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  default_audio_output?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  default_availability?: InputMaybe<BoolFieldUpdateOperationsInput>;
  deleted_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  deprecate_old_session?: InputMaybe<BoolFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordUpdateManyWithoutUserInput>;
  favorite_leads?: InputMaybe<LeadUpdateManyWithoutFavorited_By_UsersInput>;
  first_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  folder_permissions?: InputMaybe<FolderPermissionUpdateManyWithoutUserInput>;
  folders?: InputMaybe<FolderUpdateManyWithoutOwnerInput>;
  from_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutOld_UserInput>;
  GlobalRouting?: InputMaybe<GlobalRepRoutingUpdateManyWithoutRepInput>;
  google_refresh_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  holds_per_prospect_vs_held_demo_per_dial?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  hook_subscriptions?: InputMaybe<WebHookSubscriptionUpdateManyWithoutUserInput>;
  hubspot_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  idle_owned_leads?: InputMaybe<LeadUpdateManyWithoutSecondary_RepsInput>;
  industry_filter?: InputMaybe<BoolFieldUpdateOperationsInput>;
  industry_white_list_array?: InputMaybe<UserUpdateindustry_White_List_ArrayInput>;
  industry_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  joinMe_access_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  joinMe_refresh_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  last_demo_transferred_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  last_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutUserInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryUpdateManyWithoutUserInput>;
  lead_intents?: InputMaybe<LeadIntentUpdateManyWithoutUserInput>;
  lead_source_filter?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lead_source_white_list_array?: InputMaybe<UserUpdatelead_Source_White_List_ArrayInput>;
  lead_source_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  leads_last_called?: InputMaybe<LeadUpdateManyWithoutLast_Call_Result_RepInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusUpdateManyWithoutUserInput>;
  manager_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  note_item?: InputMaybe<NoteItemUpdateManyWithoutUserInput>;
  notifications?: InputMaybe<NotificationUpdateManyWithoutUserInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutUsersInput>;
  owned_leads?: InputMaybe<LeadUpdateManyWithoutRepInput>;
  panda_doc_access?: InputMaybe<BoolFieldUpdateOperationsInput>;
  password?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  payment_settings?: InputMaybe<OrganizationPaymentSettingsUpdateManyWithoutCreated_ByInput>;
  payment_settings_updated?: InputMaybe<OrganizationPaymentSettingsUpdateManyWithoutLast_Updated_ByInput>;
  phone_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  post?: InputMaybe<PostUpdateManyWithoutAuthorInput>;
  ProductsCreated?: InputMaybe<ProductChangeLogUpdateManyWithoutUpdated_ByInput>;
  profile_image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  receive_transfer_attempts?: InputMaybe<TransferAttemptUpdateManyWithoutTo_UserInput>;
  receiver_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutReceiver_UserInput>;
  regions_white_list_array?: InputMaybe<UserUpdateregions_White_List_ArrayInput>;
  regions_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  rep_scores?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumRoleFieldUpdateOperationsInput>;
  routings?: InputMaybe<RoutingUpdateManyWithoutRepInput>;
  sale_config?: InputMaybe<SaleConfigUpdateManyWithoutCreated_ByInput>;
  sale_config_updated?: InputMaybe<SaleConfigUpdateManyWithoutLast_Updated_ByInput>;
  sales_team_column_options?: InputMaybe<UserUpdatesales_Team_Column_OptionsInput>;
  schedule_items?: InputMaybe<ScheduleItemUpdateManyWithoutUserInput>;
  send_transfer_attempts?: InputMaybe<TransferAttemptUpdateManyWithoutFrom_UserInput>;
  sender_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutSender_UserInput>;
  sequence_entry_criteria_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SequenceActionLog?: InputMaybe<StepActionLogUpdateManyWithoutRepInput>;
  sequenceDashboardView?: InputMaybe<SequenceDashboardViewUpdateManyWithoutOwned_ByInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutUserInput>;
  Sequences_created?: InputMaybe<SequenceUpdateManyWithoutCreated_ByInput>;
  session_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  set_user_cycle?: InputMaybe<SaleCycleUpdateManyWithoutSet_UserInput>;
  set_user_stat?: InputMaybe<StatLeadAggregationUpdateManyWithoutSet_UserInput>;
  sf_user_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SmsItems?: InputMaybe<SmsItemUpdateManyWithoutRepInput>;
  SnoozeTracking?: InputMaybe<SnoozeTrackingUpdateManyWithoutRepInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  statusHistory?: InputMaybe<UserStatusHistoryUpdateManyWithoutUserInput>;
  sub_industry_white_list_array?: InputMaybe<UserUpdatesub_Industry_White_List_ArrayInput>;
  sub_industry_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  support_rep?: InputMaybe<BoolFieldUpdateOperationsInput>;
  taking_demos?: InputMaybe<BoolFieldUpdateOperationsInput>;
  team?: InputMaybe<TeamUpdateOneWithoutMembersInput>;
  Template?: InputMaybe<TemplateUpdateManyWithoutLast_Modified_ByInput>;
  TemplateVersion?: InputMaybe<TemplateVersionUpdateManyWithoutCreated_ByInput>;
  timekit_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timekit_type?: InputMaybe<EnumTimekittypeFieldUpdateOperationsInput>;
  timeOffRequest?: InputMaybe<TimeOffRequestUpdateManyWithoutRequesting_UserInput>;
  timezone_raw?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transfer_type?: InputMaybe<EnumTransferTypeFieldUpdateOperationsInput>;
  transfer_user_cycle?: InputMaybe<SaleCycleUpdateManyWithoutTransfer_UserInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user_custom_availability?: InputMaybe<AvailabilityUpdateManyWithoutUserInput>;
  user_past_tokens?: InputMaybe<UserPastTokensUpdateManyWithoutUserInput>;
  visible_all_leads_sm?: InputMaybe<BoolFieldUpdateOperationsInput>;
  visible_call_report_columns?: InputMaybe<UserUpdatevisible_Call_Report_ColumnsInput>;
  visible_call_report_event_columns?: InputMaybe<UserUpdatevisible_Call_Report_Event_ColumnsInput>;
  visible_call_report_pipeline_columns?: InputMaybe<UserUpdatevisible_Call_Report_Pipeline_ColumnsInput>;
  visible_my_leads_columns?: InputMaybe<UserUpdatevisible_My_Leads_ColumnsInput>;
  voicemail_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutSet_User_CycleInput = {
  ai_user?: InputMaybe<BoolFieldUpdateOperationsInput>;
  alternate_email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  always_show_calendar_options?: InputMaybe<BoolFieldUpdateOperationsInput>;
  associate_owner_rep_history?: InputMaybe<AssociationHistoryUpdateManyWithoutRepInput>;
  associate_user_history?: InputMaybe<AssociationHistoryUpdateManyWithoutUserInput>;
  auto_transfer_selection_receive_attempts?: InputMaybe<AutoTransferSelectionUpdateManyWithoutFrom_UserInput>;
  auto_transfer_selection_send_attempts?: InputMaybe<AutoTransferSelectionUpdateManyWithoutTo_UserInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptUpdateManyWithoutUserInput>;
  callSharingAsCoach?: InputMaybe<CallSharingUpdateManyWithoutCoachInput>;
  callSharingAsUser?: InputMaybe<CallSharingUpdateManyWithoutReceiver_UserInput>;
  channel?: InputMaybe<NullableEnumChannelfilterFieldUpdateOperationsInput>;
  close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  close_user_cycle?: InputMaybe<SaleCycleUpdateManyWithoutClose_UserInput>;
  close_user_stat?: InputMaybe<StatLeadAggregationUpdateManyWithoutClose_UserInput>;
  commissions?: InputMaybe<CommissionUpdateManyWithoutUserInput>;
  contact_requests?: InputMaybe<ContactRequestUpdateManyWithoutRequesting_UserInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  current_sequence_view?: InputMaybe<SequenceDashboardViewUpdateOneWithoutUserInput>;
  current_team_start_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  custom_field_white_list_array?: InputMaybe<UserCustomFieldFilterUpdateManyWithoutUserInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemUpdateManyWithoutUserInput>;
  daily_lead_claim?: InputMaybe<IntFieldUpdateOperationsInput>;
  dashboard_2_access?: InputMaybe<BoolFieldUpdateOperationsInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewUpdateManyWithoutUserInput>;
  default_audio_input?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  default_audio_output?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  default_availability?: InputMaybe<BoolFieldUpdateOperationsInput>;
  deleted_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  deprecate_old_session?: InputMaybe<BoolFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordUpdateManyWithoutUserInput>;
  favorite_leads?: InputMaybe<LeadUpdateManyWithoutFavorited_By_UsersInput>;
  first_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  folder_permissions?: InputMaybe<FolderPermissionUpdateManyWithoutUserInput>;
  folders?: InputMaybe<FolderUpdateManyWithoutOwnerInput>;
  from_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutOld_UserInput>;
  GlobalRouting?: InputMaybe<GlobalRepRoutingUpdateManyWithoutRepInput>;
  google_refresh_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  holds_per_prospect_vs_held_demo_per_dial?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  hook_subscriptions?: InputMaybe<WebHookSubscriptionUpdateManyWithoutUserInput>;
  hubspot_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  idle_owned_leads?: InputMaybe<LeadUpdateManyWithoutSecondary_RepsInput>;
  industry_filter?: InputMaybe<BoolFieldUpdateOperationsInput>;
  industry_white_list_array?: InputMaybe<UserUpdateindustry_White_List_ArrayInput>;
  industry_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  joinMe_access_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  joinMe_refresh_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  last_demo_transferred_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  last_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutUserInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryUpdateManyWithoutUserInput>;
  lead_intents?: InputMaybe<LeadIntentUpdateManyWithoutUserInput>;
  lead_source_filter?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lead_source_white_list_array?: InputMaybe<UserUpdatelead_Source_White_List_ArrayInput>;
  lead_source_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  leads_last_called?: InputMaybe<LeadUpdateManyWithoutLast_Call_Result_RepInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusUpdateManyWithoutUserInput>;
  manager_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  note_item?: InputMaybe<NoteItemUpdateManyWithoutUserInput>;
  notifications?: InputMaybe<NotificationUpdateManyWithoutUserInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutUsersInput>;
  owned_leads?: InputMaybe<LeadUpdateManyWithoutRepInput>;
  panda_doc_access?: InputMaybe<BoolFieldUpdateOperationsInput>;
  password?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  payment_settings?: InputMaybe<OrganizationPaymentSettingsUpdateManyWithoutCreated_ByInput>;
  payment_settings_updated?: InputMaybe<OrganizationPaymentSettingsUpdateManyWithoutLast_Updated_ByInput>;
  phone_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  post?: InputMaybe<PostUpdateManyWithoutAuthorInput>;
  ProductsCreated?: InputMaybe<ProductChangeLogUpdateManyWithoutUpdated_ByInput>;
  profile_image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  receive_transfer_attempts?: InputMaybe<TransferAttemptUpdateManyWithoutTo_UserInput>;
  receiver_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutReceiver_UserInput>;
  regions_white_list_array?: InputMaybe<UserUpdateregions_White_List_ArrayInput>;
  regions_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  rep_scores?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumRoleFieldUpdateOperationsInput>;
  routings?: InputMaybe<RoutingUpdateManyWithoutRepInput>;
  sale_config?: InputMaybe<SaleConfigUpdateManyWithoutCreated_ByInput>;
  sale_config_updated?: InputMaybe<SaleConfigUpdateManyWithoutLast_Updated_ByInput>;
  sales_team_column_options?: InputMaybe<UserUpdatesales_Team_Column_OptionsInput>;
  schedule_items?: InputMaybe<ScheduleItemUpdateManyWithoutUserInput>;
  send_transfer_attempts?: InputMaybe<TransferAttemptUpdateManyWithoutFrom_UserInput>;
  sender_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutSender_UserInput>;
  sequence_entry_criteria_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SequenceActionLog?: InputMaybe<StepActionLogUpdateManyWithoutRepInput>;
  sequenceDashboardView?: InputMaybe<SequenceDashboardViewUpdateManyWithoutOwned_ByInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutUserInput>;
  Sequences_created?: InputMaybe<SequenceUpdateManyWithoutCreated_ByInput>;
  Sequences_modified_by_user?: InputMaybe<SequenceUpdateManyWithoutLast_Updated_ByInput>;
  session_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  set_user_stat?: InputMaybe<StatLeadAggregationUpdateManyWithoutSet_UserInput>;
  sf_user_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SmsItems?: InputMaybe<SmsItemUpdateManyWithoutRepInput>;
  SnoozeTracking?: InputMaybe<SnoozeTrackingUpdateManyWithoutRepInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  statusHistory?: InputMaybe<UserStatusHistoryUpdateManyWithoutUserInput>;
  sub_industry_white_list_array?: InputMaybe<UserUpdatesub_Industry_White_List_ArrayInput>;
  sub_industry_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  support_rep?: InputMaybe<BoolFieldUpdateOperationsInput>;
  taking_demos?: InputMaybe<BoolFieldUpdateOperationsInput>;
  team?: InputMaybe<TeamUpdateOneWithoutMembersInput>;
  Template?: InputMaybe<TemplateUpdateManyWithoutLast_Modified_ByInput>;
  TemplateVersion?: InputMaybe<TemplateVersionUpdateManyWithoutCreated_ByInput>;
  timekit_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timekit_type?: InputMaybe<EnumTimekittypeFieldUpdateOperationsInput>;
  timeOffRequest?: InputMaybe<TimeOffRequestUpdateManyWithoutRequesting_UserInput>;
  timezone_raw?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transfer_type?: InputMaybe<EnumTransferTypeFieldUpdateOperationsInput>;
  transfer_user_cycle?: InputMaybe<SaleCycleUpdateManyWithoutTransfer_UserInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user_custom_availability?: InputMaybe<AvailabilityUpdateManyWithoutUserInput>;
  user_past_tokens?: InputMaybe<UserPastTokensUpdateManyWithoutUserInput>;
  visible_all_leads_sm?: InputMaybe<BoolFieldUpdateOperationsInput>;
  visible_call_report_columns?: InputMaybe<UserUpdatevisible_Call_Report_ColumnsInput>;
  visible_call_report_event_columns?: InputMaybe<UserUpdatevisible_Call_Report_Event_ColumnsInput>;
  visible_call_report_pipeline_columns?: InputMaybe<UserUpdatevisible_Call_Report_Pipeline_ColumnsInput>;
  visible_my_leads_columns?: InputMaybe<UserUpdatevisible_My_Leads_ColumnsInput>;
  voicemail_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutSet_User_StatInput = {
  ai_user?: InputMaybe<BoolFieldUpdateOperationsInput>;
  alternate_email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  always_show_calendar_options?: InputMaybe<BoolFieldUpdateOperationsInput>;
  associate_owner_rep_history?: InputMaybe<AssociationHistoryUpdateManyWithoutRepInput>;
  associate_user_history?: InputMaybe<AssociationHistoryUpdateManyWithoutUserInput>;
  auto_transfer_selection_receive_attempts?: InputMaybe<AutoTransferSelectionUpdateManyWithoutFrom_UserInput>;
  auto_transfer_selection_send_attempts?: InputMaybe<AutoTransferSelectionUpdateManyWithoutTo_UserInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptUpdateManyWithoutUserInput>;
  callSharingAsCoach?: InputMaybe<CallSharingUpdateManyWithoutCoachInput>;
  callSharingAsUser?: InputMaybe<CallSharingUpdateManyWithoutReceiver_UserInput>;
  channel?: InputMaybe<NullableEnumChannelfilterFieldUpdateOperationsInput>;
  close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  close_user_cycle?: InputMaybe<SaleCycleUpdateManyWithoutClose_UserInput>;
  close_user_stat?: InputMaybe<StatLeadAggregationUpdateManyWithoutClose_UserInput>;
  commissions?: InputMaybe<CommissionUpdateManyWithoutUserInput>;
  contact_requests?: InputMaybe<ContactRequestUpdateManyWithoutRequesting_UserInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  current_sequence_view?: InputMaybe<SequenceDashboardViewUpdateOneWithoutUserInput>;
  current_team_start_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  custom_field_white_list_array?: InputMaybe<UserCustomFieldFilterUpdateManyWithoutUserInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemUpdateManyWithoutUserInput>;
  daily_lead_claim?: InputMaybe<IntFieldUpdateOperationsInput>;
  dashboard_2_access?: InputMaybe<BoolFieldUpdateOperationsInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewUpdateManyWithoutUserInput>;
  default_audio_input?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  default_audio_output?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  default_availability?: InputMaybe<BoolFieldUpdateOperationsInput>;
  deleted_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  deprecate_old_session?: InputMaybe<BoolFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordUpdateManyWithoutUserInput>;
  favorite_leads?: InputMaybe<LeadUpdateManyWithoutFavorited_By_UsersInput>;
  first_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  folder_permissions?: InputMaybe<FolderPermissionUpdateManyWithoutUserInput>;
  folders?: InputMaybe<FolderUpdateManyWithoutOwnerInput>;
  from_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutOld_UserInput>;
  GlobalRouting?: InputMaybe<GlobalRepRoutingUpdateManyWithoutRepInput>;
  google_refresh_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  holds_per_prospect_vs_held_demo_per_dial?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  hook_subscriptions?: InputMaybe<WebHookSubscriptionUpdateManyWithoutUserInput>;
  hubspot_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  idle_owned_leads?: InputMaybe<LeadUpdateManyWithoutSecondary_RepsInput>;
  industry_filter?: InputMaybe<BoolFieldUpdateOperationsInput>;
  industry_white_list_array?: InputMaybe<UserUpdateindustry_White_List_ArrayInput>;
  industry_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  joinMe_access_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  joinMe_refresh_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  last_demo_transferred_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  last_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutUserInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryUpdateManyWithoutUserInput>;
  lead_intents?: InputMaybe<LeadIntentUpdateManyWithoutUserInput>;
  lead_source_filter?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lead_source_white_list_array?: InputMaybe<UserUpdatelead_Source_White_List_ArrayInput>;
  lead_source_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  leads_last_called?: InputMaybe<LeadUpdateManyWithoutLast_Call_Result_RepInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusUpdateManyWithoutUserInput>;
  manager_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  note_item?: InputMaybe<NoteItemUpdateManyWithoutUserInput>;
  notifications?: InputMaybe<NotificationUpdateManyWithoutUserInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutUsersInput>;
  owned_leads?: InputMaybe<LeadUpdateManyWithoutRepInput>;
  panda_doc_access?: InputMaybe<BoolFieldUpdateOperationsInput>;
  password?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  payment_settings?: InputMaybe<OrganizationPaymentSettingsUpdateManyWithoutCreated_ByInput>;
  payment_settings_updated?: InputMaybe<OrganizationPaymentSettingsUpdateManyWithoutLast_Updated_ByInput>;
  phone_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  post?: InputMaybe<PostUpdateManyWithoutAuthorInput>;
  ProductsCreated?: InputMaybe<ProductChangeLogUpdateManyWithoutUpdated_ByInput>;
  profile_image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  receive_transfer_attempts?: InputMaybe<TransferAttemptUpdateManyWithoutTo_UserInput>;
  receiver_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutReceiver_UserInput>;
  regions_white_list_array?: InputMaybe<UserUpdateregions_White_List_ArrayInput>;
  regions_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  rep_scores?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumRoleFieldUpdateOperationsInput>;
  routings?: InputMaybe<RoutingUpdateManyWithoutRepInput>;
  sale_config?: InputMaybe<SaleConfigUpdateManyWithoutCreated_ByInput>;
  sale_config_updated?: InputMaybe<SaleConfigUpdateManyWithoutLast_Updated_ByInput>;
  sales_team_column_options?: InputMaybe<UserUpdatesales_Team_Column_OptionsInput>;
  schedule_items?: InputMaybe<ScheduleItemUpdateManyWithoutUserInput>;
  send_transfer_attempts?: InputMaybe<TransferAttemptUpdateManyWithoutFrom_UserInput>;
  sender_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutSender_UserInput>;
  sequence_entry_criteria_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SequenceActionLog?: InputMaybe<StepActionLogUpdateManyWithoutRepInput>;
  sequenceDashboardView?: InputMaybe<SequenceDashboardViewUpdateManyWithoutOwned_ByInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutUserInput>;
  Sequences_created?: InputMaybe<SequenceUpdateManyWithoutCreated_ByInput>;
  Sequences_modified_by_user?: InputMaybe<SequenceUpdateManyWithoutLast_Updated_ByInput>;
  session_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  set_user_cycle?: InputMaybe<SaleCycleUpdateManyWithoutSet_UserInput>;
  sf_user_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SmsItems?: InputMaybe<SmsItemUpdateManyWithoutRepInput>;
  SnoozeTracking?: InputMaybe<SnoozeTrackingUpdateManyWithoutRepInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  statusHistory?: InputMaybe<UserStatusHistoryUpdateManyWithoutUserInput>;
  sub_industry_white_list_array?: InputMaybe<UserUpdatesub_Industry_White_List_ArrayInput>;
  sub_industry_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  support_rep?: InputMaybe<BoolFieldUpdateOperationsInput>;
  taking_demos?: InputMaybe<BoolFieldUpdateOperationsInput>;
  team?: InputMaybe<TeamUpdateOneWithoutMembersInput>;
  Template?: InputMaybe<TemplateUpdateManyWithoutLast_Modified_ByInput>;
  TemplateVersion?: InputMaybe<TemplateVersionUpdateManyWithoutCreated_ByInput>;
  timekit_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timekit_type?: InputMaybe<EnumTimekittypeFieldUpdateOperationsInput>;
  timeOffRequest?: InputMaybe<TimeOffRequestUpdateManyWithoutRequesting_UserInput>;
  timezone_raw?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transfer_type?: InputMaybe<EnumTransferTypeFieldUpdateOperationsInput>;
  transfer_user_cycle?: InputMaybe<SaleCycleUpdateManyWithoutTransfer_UserInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user_custom_availability?: InputMaybe<AvailabilityUpdateManyWithoutUserInput>;
  user_past_tokens?: InputMaybe<UserPastTokensUpdateManyWithoutUserInput>;
  visible_all_leads_sm?: InputMaybe<BoolFieldUpdateOperationsInput>;
  visible_call_report_columns?: InputMaybe<UserUpdatevisible_Call_Report_ColumnsInput>;
  visible_call_report_event_columns?: InputMaybe<UserUpdatevisible_Call_Report_Event_ColumnsInput>;
  visible_call_report_pipeline_columns?: InputMaybe<UserUpdatevisible_Call_Report_Pipeline_ColumnsInput>;
  visible_my_leads_columns?: InputMaybe<UserUpdatevisible_My_Leads_ColumnsInput>;
  voicemail_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutSmsItemsInput = {
  ai_user?: InputMaybe<BoolFieldUpdateOperationsInput>;
  alternate_email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  always_show_calendar_options?: InputMaybe<BoolFieldUpdateOperationsInput>;
  associate_owner_rep_history?: InputMaybe<AssociationHistoryUpdateManyWithoutRepInput>;
  associate_user_history?: InputMaybe<AssociationHistoryUpdateManyWithoutUserInput>;
  auto_transfer_selection_receive_attempts?: InputMaybe<AutoTransferSelectionUpdateManyWithoutFrom_UserInput>;
  auto_transfer_selection_send_attempts?: InputMaybe<AutoTransferSelectionUpdateManyWithoutTo_UserInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptUpdateManyWithoutUserInput>;
  callSharingAsCoach?: InputMaybe<CallSharingUpdateManyWithoutCoachInput>;
  callSharingAsUser?: InputMaybe<CallSharingUpdateManyWithoutReceiver_UserInput>;
  channel?: InputMaybe<NullableEnumChannelfilterFieldUpdateOperationsInput>;
  close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  close_user_cycle?: InputMaybe<SaleCycleUpdateManyWithoutClose_UserInput>;
  close_user_stat?: InputMaybe<StatLeadAggregationUpdateManyWithoutClose_UserInput>;
  commissions?: InputMaybe<CommissionUpdateManyWithoutUserInput>;
  contact_requests?: InputMaybe<ContactRequestUpdateManyWithoutRequesting_UserInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  current_sequence_view?: InputMaybe<SequenceDashboardViewUpdateOneWithoutUserInput>;
  current_team_start_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  custom_field_white_list_array?: InputMaybe<UserCustomFieldFilterUpdateManyWithoutUserInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemUpdateManyWithoutUserInput>;
  daily_lead_claim?: InputMaybe<IntFieldUpdateOperationsInput>;
  dashboard_2_access?: InputMaybe<BoolFieldUpdateOperationsInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewUpdateManyWithoutUserInput>;
  default_audio_input?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  default_audio_output?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  default_availability?: InputMaybe<BoolFieldUpdateOperationsInput>;
  deleted_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  deprecate_old_session?: InputMaybe<BoolFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordUpdateManyWithoutUserInput>;
  favorite_leads?: InputMaybe<LeadUpdateManyWithoutFavorited_By_UsersInput>;
  first_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  folder_permissions?: InputMaybe<FolderPermissionUpdateManyWithoutUserInput>;
  folders?: InputMaybe<FolderUpdateManyWithoutOwnerInput>;
  from_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutOld_UserInput>;
  GlobalRouting?: InputMaybe<GlobalRepRoutingUpdateManyWithoutRepInput>;
  google_refresh_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  holds_per_prospect_vs_held_demo_per_dial?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  hook_subscriptions?: InputMaybe<WebHookSubscriptionUpdateManyWithoutUserInput>;
  hubspot_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  idle_owned_leads?: InputMaybe<LeadUpdateManyWithoutSecondary_RepsInput>;
  industry_filter?: InputMaybe<BoolFieldUpdateOperationsInput>;
  industry_white_list_array?: InputMaybe<UserUpdateindustry_White_List_ArrayInput>;
  industry_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  joinMe_access_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  joinMe_refresh_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  last_demo_transferred_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  last_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutUserInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryUpdateManyWithoutUserInput>;
  lead_intents?: InputMaybe<LeadIntentUpdateManyWithoutUserInput>;
  lead_source_filter?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lead_source_white_list_array?: InputMaybe<UserUpdatelead_Source_White_List_ArrayInput>;
  lead_source_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  leads_last_called?: InputMaybe<LeadUpdateManyWithoutLast_Call_Result_RepInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusUpdateManyWithoutUserInput>;
  manager_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  note_item?: InputMaybe<NoteItemUpdateManyWithoutUserInput>;
  notifications?: InputMaybe<NotificationUpdateManyWithoutUserInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutUsersInput>;
  owned_leads?: InputMaybe<LeadUpdateManyWithoutRepInput>;
  panda_doc_access?: InputMaybe<BoolFieldUpdateOperationsInput>;
  password?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  payment_settings?: InputMaybe<OrganizationPaymentSettingsUpdateManyWithoutCreated_ByInput>;
  payment_settings_updated?: InputMaybe<OrganizationPaymentSettingsUpdateManyWithoutLast_Updated_ByInput>;
  phone_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  post?: InputMaybe<PostUpdateManyWithoutAuthorInput>;
  ProductsCreated?: InputMaybe<ProductChangeLogUpdateManyWithoutUpdated_ByInput>;
  profile_image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  receive_transfer_attempts?: InputMaybe<TransferAttemptUpdateManyWithoutTo_UserInput>;
  receiver_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutReceiver_UserInput>;
  regions_white_list_array?: InputMaybe<UserUpdateregions_White_List_ArrayInput>;
  regions_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  rep_scores?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumRoleFieldUpdateOperationsInput>;
  routings?: InputMaybe<RoutingUpdateManyWithoutRepInput>;
  sale_config?: InputMaybe<SaleConfigUpdateManyWithoutCreated_ByInput>;
  sale_config_updated?: InputMaybe<SaleConfigUpdateManyWithoutLast_Updated_ByInput>;
  sales_team_column_options?: InputMaybe<UserUpdatesales_Team_Column_OptionsInput>;
  schedule_items?: InputMaybe<ScheduleItemUpdateManyWithoutUserInput>;
  send_transfer_attempts?: InputMaybe<TransferAttemptUpdateManyWithoutFrom_UserInput>;
  sender_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutSender_UserInput>;
  sequence_entry_criteria_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SequenceActionLog?: InputMaybe<StepActionLogUpdateManyWithoutRepInput>;
  sequenceDashboardView?: InputMaybe<SequenceDashboardViewUpdateManyWithoutOwned_ByInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutUserInput>;
  Sequences_created?: InputMaybe<SequenceUpdateManyWithoutCreated_ByInput>;
  Sequences_modified_by_user?: InputMaybe<SequenceUpdateManyWithoutLast_Updated_ByInput>;
  session_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  set_user_cycle?: InputMaybe<SaleCycleUpdateManyWithoutSet_UserInput>;
  set_user_stat?: InputMaybe<StatLeadAggregationUpdateManyWithoutSet_UserInput>;
  sf_user_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SnoozeTracking?: InputMaybe<SnoozeTrackingUpdateManyWithoutRepInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  statusHistory?: InputMaybe<UserStatusHistoryUpdateManyWithoutUserInput>;
  sub_industry_white_list_array?: InputMaybe<UserUpdatesub_Industry_White_List_ArrayInput>;
  sub_industry_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  support_rep?: InputMaybe<BoolFieldUpdateOperationsInput>;
  taking_demos?: InputMaybe<BoolFieldUpdateOperationsInput>;
  team?: InputMaybe<TeamUpdateOneWithoutMembersInput>;
  Template?: InputMaybe<TemplateUpdateManyWithoutLast_Modified_ByInput>;
  TemplateVersion?: InputMaybe<TemplateVersionUpdateManyWithoutCreated_ByInput>;
  timekit_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timekit_type?: InputMaybe<EnumTimekittypeFieldUpdateOperationsInput>;
  timeOffRequest?: InputMaybe<TimeOffRequestUpdateManyWithoutRequesting_UserInput>;
  timezone_raw?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transfer_type?: InputMaybe<EnumTransferTypeFieldUpdateOperationsInput>;
  transfer_user_cycle?: InputMaybe<SaleCycleUpdateManyWithoutTransfer_UserInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user_custom_availability?: InputMaybe<AvailabilityUpdateManyWithoutUserInput>;
  user_past_tokens?: InputMaybe<UserPastTokensUpdateManyWithoutUserInput>;
  visible_all_leads_sm?: InputMaybe<BoolFieldUpdateOperationsInput>;
  visible_call_report_columns?: InputMaybe<UserUpdatevisible_Call_Report_ColumnsInput>;
  visible_call_report_event_columns?: InputMaybe<UserUpdatevisible_Call_Report_Event_ColumnsInput>;
  visible_call_report_pipeline_columns?: InputMaybe<UserUpdatevisible_Call_Report_Pipeline_ColumnsInput>;
  visible_my_leads_columns?: InputMaybe<UserUpdatevisible_My_Leads_ColumnsInput>;
  voicemail_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutSnoozeTrackingInput = {
  ai_user?: InputMaybe<BoolFieldUpdateOperationsInput>;
  alternate_email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  always_show_calendar_options?: InputMaybe<BoolFieldUpdateOperationsInput>;
  associate_owner_rep_history?: InputMaybe<AssociationHistoryUpdateManyWithoutRepInput>;
  associate_user_history?: InputMaybe<AssociationHistoryUpdateManyWithoutUserInput>;
  auto_transfer_selection_receive_attempts?: InputMaybe<AutoTransferSelectionUpdateManyWithoutFrom_UserInput>;
  auto_transfer_selection_send_attempts?: InputMaybe<AutoTransferSelectionUpdateManyWithoutTo_UserInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptUpdateManyWithoutUserInput>;
  callSharingAsCoach?: InputMaybe<CallSharingUpdateManyWithoutCoachInput>;
  callSharingAsUser?: InputMaybe<CallSharingUpdateManyWithoutReceiver_UserInput>;
  channel?: InputMaybe<NullableEnumChannelfilterFieldUpdateOperationsInput>;
  close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  close_user_cycle?: InputMaybe<SaleCycleUpdateManyWithoutClose_UserInput>;
  close_user_stat?: InputMaybe<StatLeadAggregationUpdateManyWithoutClose_UserInput>;
  commissions?: InputMaybe<CommissionUpdateManyWithoutUserInput>;
  contact_requests?: InputMaybe<ContactRequestUpdateManyWithoutRequesting_UserInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  current_sequence_view?: InputMaybe<SequenceDashboardViewUpdateOneWithoutUserInput>;
  current_team_start_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  custom_field_white_list_array?: InputMaybe<UserCustomFieldFilterUpdateManyWithoutUserInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemUpdateManyWithoutUserInput>;
  daily_lead_claim?: InputMaybe<IntFieldUpdateOperationsInput>;
  dashboard_2_access?: InputMaybe<BoolFieldUpdateOperationsInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewUpdateManyWithoutUserInput>;
  default_audio_input?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  default_audio_output?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  default_availability?: InputMaybe<BoolFieldUpdateOperationsInput>;
  deleted_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  deprecate_old_session?: InputMaybe<BoolFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordUpdateManyWithoutUserInput>;
  favorite_leads?: InputMaybe<LeadUpdateManyWithoutFavorited_By_UsersInput>;
  first_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  folder_permissions?: InputMaybe<FolderPermissionUpdateManyWithoutUserInput>;
  folders?: InputMaybe<FolderUpdateManyWithoutOwnerInput>;
  from_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutOld_UserInput>;
  GlobalRouting?: InputMaybe<GlobalRepRoutingUpdateManyWithoutRepInput>;
  google_refresh_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  holds_per_prospect_vs_held_demo_per_dial?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  hook_subscriptions?: InputMaybe<WebHookSubscriptionUpdateManyWithoutUserInput>;
  hubspot_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  idle_owned_leads?: InputMaybe<LeadUpdateManyWithoutSecondary_RepsInput>;
  industry_filter?: InputMaybe<BoolFieldUpdateOperationsInput>;
  industry_white_list_array?: InputMaybe<UserUpdateindustry_White_List_ArrayInput>;
  industry_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  joinMe_access_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  joinMe_refresh_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  last_demo_transferred_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  last_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutUserInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryUpdateManyWithoutUserInput>;
  lead_intents?: InputMaybe<LeadIntentUpdateManyWithoutUserInput>;
  lead_source_filter?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lead_source_white_list_array?: InputMaybe<UserUpdatelead_Source_White_List_ArrayInput>;
  lead_source_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  leads_last_called?: InputMaybe<LeadUpdateManyWithoutLast_Call_Result_RepInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusUpdateManyWithoutUserInput>;
  manager_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  note_item?: InputMaybe<NoteItemUpdateManyWithoutUserInput>;
  notifications?: InputMaybe<NotificationUpdateManyWithoutUserInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutUsersInput>;
  owned_leads?: InputMaybe<LeadUpdateManyWithoutRepInput>;
  panda_doc_access?: InputMaybe<BoolFieldUpdateOperationsInput>;
  password?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  payment_settings?: InputMaybe<OrganizationPaymentSettingsUpdateManyWithoutCreated_ByInput>;
  payment_settings_updated?: InputMaybe<OrganizationPaymentSettingsUpdateManyWithoutLast_Updated_ByInput>;
  phone_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  post?: InputMaybe<PostUpdateManyWithoutAuthorInput>;
  ProductsCreated?: InputMaybe<ProductChangeLogUpdateManyWithoutUpdated_ByInput>;
  profile_image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  receive_transfer_attempts?: InputMaybe<TransferAttemptUpdateManyWithoutTo_UserInput>;
  receiver_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutReceiver_UserInput>;
  regions_white_list_array?: InputMaybe<UserUpdateregions_White_List_ArrayInput>;
  regions_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  rep_scores?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumRoleFieldUpdateOperationsInput>;
  routings?: InputMaybe<RoutingUpdateManyWithoutRepInput>;
  sale_config?: InputMaybe<SaleConfigUpdateManyWithoutCreated_ByInput>;
  sale_config_updated?: InputMaybe<SaleConfigUpdateManyWithoutLast_Updated_ByInput>;
  sales_team_column_options?: InputMaybe<UserUpdatesales_Team_Column_OptionsInput>;
  schedule_items?: InputMaybe<ScheduleItemUpdateManyWithoutUserInput>;
  send_transfer_attempts?: InputMaybe<TransferAttemptUpdateManyWithoutFrom_UserInput>;
  sender_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutSender_UserInput>;
  sequence_entry_criteria_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SequenceActionLog?: InputMaybe<StepActionLogUpdateManyWithoutRepInput>;
  sequenceDashboardView?: InputMaybe<SequenceDashboardViewUpdateManyWithoutOwned_ByInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutUserInput>;
  Sequences_created?: InputMaybe<SequenceUpdateManyWithoutCreated_ByInput>;
  Sequences_modified_by_user?: InputMaybe<SequenceUpdateManyWithoutLast_Updated_ByInput>;
  session_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  set_user_cycle?: InputMaybe<SaleCycleUpdateManyWithoutSet_UserInput>;
  set_user_stat?: InputMaybe<StatLeadAggregationUpdateManyWithoutSet_UserInput>;
  sf_user_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SmsItems?: InputMaybe<SmsItemUpdateManyWithoutRepInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  statusHistory?: InputMaybe<UserStatusHistoryUpdateManyWithoutUserInput>;
  sub_industry_white_list_array?: InputMaybe<UserUpdatesub_Industry_White_List_ArrayInput>;
  sub_industry_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  support_rep?: InputMaybe<BoolFieldUpdateOperationsInput>;
  taking_demos?: InputMaybe<BoolFieldUpdateOperationsInput>;
  team?: InputMaybe<TeamUpdateOneWithoutMembersInput>;
  Template?: InputMaybe<TemplateUpdateManyWithoutLast_Modified_ByInput>;
  TemplateVersion?: InputMaybe<TemplateVersionUpdateManyWithoutCreated_ByInput>;
  timekit_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timekit_type?: InputMaybe<EnumTimekittypeFieldUpdateOperationsInput>;
  timeOffRequest?: InputMaybe<TimeOffRequestUpdateManyWithoutRequesting_UserInput>;
  timezone_raw?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transfer_type?: InputMaybe<EnumTransferTypeFieldUpdateOperationsInput>;
  transfer_user_cycle?: InputMaybe<SaleCycleUpdateManyWithoutTransfer_UserInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user_custom_availability?: InputMaybe<AvailabilityUpdateManyWithoutUserInput>;
  user_past_tokens?: InputMaybe<UserPastTokensUpdateManyWithoutUserInput>;
  visible_all_leads_sm?: InputMaybe<BoolFieldUpdateOperationsInput>;
  visible_call_report_columns?: InputMaybe<UserUpdatevisible_Call_Report_ColumnsInput>;
  visible_call_report_event_columns?: InputMaybe<UserUpdatevisible_Call_Report_Event_ColumnsInput>;
  visible_call_report_pipeline_columns?: InputMaybe<UserUpdatevisible_Call_Report_Pipeline_ColumnsInput>;
  visible_my_leads_columns?: InputMaybe<UserUpdatevisible_My_Leads_ColumnsInput>;
  voicemail_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutTeamInput = {
  ai_user?: InputMaybe<BoolFieldUpdateOperationsInput>;
  alternate_email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  always_show_calendar_options?: InputMaybe<BoolFieldUpdateOperationsInput>;
  associate_owner_rep_history?: InputMaybe<AssociationHistoryUpdateManyWithoutRepInput>;
  associate_user_history?: InputMaybe<AssociationHistoryUpdateManyWithoutUserInput>;
  auto_transfer_selection_receive_attempts?: InputMaybe<AutoTransferSelectionUpdateManyWithoutFrom_UserInput>;
  auto_transfer_selection_send_attempts?: InputMaybe<AutoTransferSelectionUpdateManyWithoutTo_UserInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptUpdateManyWithoutUserInput>;
  callSharingAsCoach?: InputMaybe<CallSharingUpdateManyWithoutCoachInput>;
  callSharingAsUser?: InputMaybe<CallSharingUpdateManyWithoutReceiver_UserInput>;
  channel?: InputMaybe<NullableEnumChannelfilterFieldUpdateOperationsInput>;
  close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  close_user_cycle?: InputMaybe<SaleCycleUpdateManyWithoutClose_UserInput>;
  close_user_stat?: InputMaybe<StatLeadAggregationUpdateManyWithoutClose_UserInput>;
  commissions?: InputMaybe<CommissionUpdateManyWithoutUserInput>;
  contact_requests?: InputMaybe<ContactRequestUpdateManyWithoutRequesting_UserInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  current_sequence_view?: InputMaybe<SequenceDashboardViewUpdateOneWithoutUserInput>;
  current_team_start_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  custom_field_white_list_array?: InputMaybe<UserCustomFieldFilterUpdateManyWithoutUserInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemUpdateManyWithoutUserInput>;
  daily_lead_claim?: InputMaybe<IntFieldUpdateOperationsInput>;
  dashboard_2_access?: InputMaybe<BoolFieldUpdateOperationsInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewUpdateManyWithoutUserInput>;
  default_audio_input?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  default_audio_output?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  default_availability?: InputMaybe<BoolFieldUpdateOperationsInput>;
  deleted_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  deprecate_old_session?: InputMaybe<BoolFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordUpdateManyWithoutUserInput>;
  favorite_leads?: InputMaybe<LeadUpdateManyWithoutFavorited_By_UsersInput>;
  first_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  folder_permissions?: InputMaybe<FolderPermissionUpdateManyWithoutUserInput>;
  folders?: InputMaybe<FolderUpdateManyWithoutOwnerInput>;
  from_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutOld_UserInput>;
  GlobalRouting?: InputMaybe<GlobalRepRoutingUpdateManyWithoutRepInput>;
  google_refresh_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  holds_per_prospect_vs_held_demo_per_dial?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  hook_subscriptions?: InputMaybe<WebHookSubscriptionUpdateManyWithoutUserInput>;
  hubspot_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  idle_owned_leads?: InputMaybe<LeadUpdateManyWithoutSecondary_RepsInput>;
  industry_filter?: InputMaybe<BoolFieldUpdateOperationsInput>;
  industry_white_list_array?: InputMaybe<UserUpdateindustry_White_List_ArrayInput>;
  industry_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  joinMe_access_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  joinMe_refresh_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  last_demo_transferred_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  last_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutUserInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryUpdateManyWithoutUserInput>;
  lead_intents?: InputMaybe<LeadIntentUpdateManyWithoutUserInput>;
  lead_source_filter?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lead_source_white_list_array?: InputMaybe<UserUpdatelead_Source_White_List_ArrayInput>;
  lead_source_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  leads_last_called?: InputMaybe<LeadUpdateManyWithoutLast_Call_Result_RepInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusUpdateManyWithoutUserInput>;
  manager_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  note_item?: InputMaybe<NoteItemUpdateManyWithoutUserInput>;
  notifications?: InputMaybe<NotificationUpdateManyWithoutUserInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutUsersInput>;
  owned_leads?: InputMaybe<LeadUpdateManyWithoutRepInput>;
  panda_doc_access?: InputMaybe<BoolFieldUpdateOperationsInput>;
  password?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  payment_settings?: InputMaybe<OrganizationPaymentSettingsUpdateManyWithoutCreated_ByInput>;
  payment_settings_updated?: InputMaybe<OrganizationPaymentSettingsUpdateManyWithoutLast_Updated_ByInput>;
  phone_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  post?: InputMaybe<PostUpdateManyWithoutAuthorInput>;
  ProductsCreated?: InputMaybe<ProductChangeLogUpdateManyWithoutUpdated_ByInput>;
  profile_image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  receive_transfer_attempts?: InputMaybe<TransferAttemptUpdateManyWithoutTo_UserInput>;
  receiver_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutReceiver_UserInput>;
  regions_white_list_array?: InputMaybe<UserUpdateregions_White_List_ArrayInput>;
  regions_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  rep_scores?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumRoleFieldUpdateOperationsInput>;
  routings?: InputMaybe<RoutingUpdateManyWithoutRepInput>;
  sale_config?: InputMaybe<SaleConfigUpdateManyWithoutCreated_ByInput>;
  sale_config_updated?: InputMaybe<SaleConfigUpdateManyWithoutLast_Updated_ByInput>;
  sales_team_column_options?: InputMaybe<UserUpdatesales_Team_Column_OptionsInput>;
  schedule_items?: InputMaybe<ScheduleItemUpdateManyWithoutUserInput>;
  send_transfer_attempts?: InputMaybe<TransferAttemptUpdateManyWithoutFrom_UserInput>;
  sender_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutSender_UserInput>;
  sequence_entry_criteria_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SequenceActionLog?: InputMaybe<StepActionLogUpdateManyWithoutRepInput>;
  sequenceDashboardView?: InputMaybe<SequenceDashboardViewUpdateManyWithoutOwned_ByInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutUserInput>;
  Sequences_created?: InputMaybe<SequenceUpdateManyWithoutCreated_ByInput>;
  Sequences_modified_by_user?: InputMaybe<SequenceUpdateManyWithoutLast_Updated_ByInput>;
  session_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  set_user_cycle?: InputMaybe<SaleCycleUpdateManyWithoutSet_UserInput>;
  set_user_stat?: InputMaybe<StatLeadAggregationUpdateManyWithoutSet_UserInput>;
  sf_user_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SmsItems?: InputMaybe<SmsItemUpdateManyWithoutRepInput>;
  SnoozeTracking?: InputMaybe<SnoozeTrackingUpdateManyWithoutRepInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  statusHistory?: InputMaybe<UserStatusHistoryUpdateManyWithoutUserInput>;
  sub_industry_white_list_array?: InputMaybe<UserUpdatesub_Industry_White_List_ArrayInput>;
  sub_industry_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  support_rep?: InputMaybe<BoolFieldUpdateOperationsInput>;
  taking_demos?: InputMaybe<BoolFieldUpdateOperationsInput>;
  Template?: InputMaybe<TemplateUpdateManyWithoutLast_Modified_ByInput>;
  TemplateVersion?: InputMaybe<TemplateVersionUpdateManyWithoutCreated_ByInput>;
  timekit_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timekit_type?: InputMaybe<EnumTimekittypeFieldUpdateOperationsInput>;
  timeOffRequest?: InputMaybe<TimeOffRequestUpdateManyWithoutRequesting_UserInput>;
  timezone_raw?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transfer_type?: InputMaybe<EnumTransferTypeFieldUpdateOperationsInput>;
  transfer_user_cycle?: InputMaybe<SaleCycleUpdateManyWithoutTransfer_UserInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user_custom_availability?: InputMaybe<AvailabilityUpdateManyWithoutUserInput>;
  user_past_tokens?: InputMaybe<UserPastTokensUpdateManyWithoutUserInput>;
  visible_all_leads_sm?: InputMaybe<BoolFieldUpdateOperationsInput>;
  visible_call_report_columns?: InputMaybe<UserUpdatevisible_Call_Report_ColumnsInput>;
  visible_call_report_event_columns?: InputMaybe<UserUpdatevisible_Call_Report_Event_ColumnsInput>;
  visible_call_report_pipeline_columns?: InputMaybe<UserUpdatevisible_Call_Report_Pipeline_ColumnsInput>;
  visible_my_leads_columns?: InputMaybe<UserUpdatevisible_My_Leads_ColumnsInput>;
  voicemail_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutTemplateInput = {
  ai_user?: InputMaybe<BoolFieldUpdateOperationsInput>;
  alternate_email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  always_show_calendar_options?: InputMaybe<BoolFieldUpdateOperationsInput>;
  associate_owner_rep_history?: InputMaybe<AssociationHistoryUpdateManyWithoutRepInput>;
  associate_user_history?: InputMaybe<AssociationHistoryUpdateManyWithoutUserInput>;
  auto_transfer_selection_receive_attempts?: InputMaybe<AutoTransferSelectionUpdateManyWithoutFrom_UserInput>;
  auto_transfer_selection_send_attempts?: InputMaybe<AutoTransferSelectionUpdateManyWithoutTo_UserInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptUpdateManyWithoutUserInput>;
  callSharingAsCoach?: InputMaybe<CallSharingUpdateManyWithoutCoachInput>;
  callSharingAsUser?: InputMaybe<CallSharingUpdateManyWithoutReceiver_UserInput>;
  channel?: InputMaybe<NullableEnumChannelfilterFieldUpdateOperationsInput>;
  close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  close_user_cycle?: InputMaybe<SaleCycleUpdateManyWithoutClose_UserInput>;
  close_user_stat?: InputMaybe<StatLeadAggregationUpdateManyWithoutClose_UserInput>;
  commissions?: InputMaybe<CommissionUpdateManyWithoutUserInput>;
  contact_requests?: InputMaybe<ContactRequestUpdateManyWithoutRequesting_UserInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  current_sequence_view?: InputMaybe<SequenceDashboardViewUpdateOneWithoutUserInput>;
  current_team_start_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  custom_field_white_list_array?: InputMaybe<UserCustomFieldFilterUpdateManyWithoutUserInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemUpdateManyWithoutUserInput>;
  daily_lead_claim?: InputMaybe<IntFieldUpdateOperationsInput>;
  dashboard_2_access?: InputMaybe<BoolFieldUpdateOperationsInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewUpdateManyWithoutUserInput>;
  default_audio_input?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  default_audio_output?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  default_availability?: InputMaybe<BoolFieldUpdateOperationsInput>;
  deleted_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  deprecate_old_session?: InputMaybe<BoolFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordUpdateManyWithoutUserInput>;
  favorite_leads?: InputMaybe<LeadUpdateManyWithoutFavorited_By_UsersInput>;
  first_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  folder_permissions?: InputMaybe<FolderPermissionUpdateManyWithoutUserInput>;
  folders?: InputMaybe<FolderUpdateManyWithoutOwnerInput>;
  from_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutOld_UserInput>;
  GlobalRouting?: InputMaybe<GlobalRepRoutingUpdateManyWithoutRepInput>;
  google_refresh_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  holds_per_prospect_vs_held_demo_per_dial?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  hook_subscriptions?: InputMaybe<WebHookSubscriptionUpdateManyWithoutUserInput>;
  hubspot_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  idle_owned_leads?: InputMaybe<LeadUpdateManyWithoutSecondary_RepsInput>;
  industry_filter?: InputMaybe<BoolFieldUpdateOperationsInput>;
  industry_white_list_array?: InputMaybe<UserUpdateindustry_White_List_ArrayInput>;
  industry_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  joinMe_access_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  joinMe_refresh_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  last_demo_transferred_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  last_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutUserInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryUpdateManyWithoutUserInput>;
  lead_intents?: InputMaybe<LeadIntentUpdateManyWithoutUserInput>;
  lead_source_filter?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lead_source_white_list_array?: InputMaybe<UserUpdatelead_Source_White_List_ArrayInput>;
  lead_source_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  leads_last_called?: InputMaybe<LeadUpdateManyWithoutLast_Call_Result_RepInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusUpdateManyWithoutUserInput>;
  manager_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  note_item?: InputMaybe<NoteItemUpdateManyWithoutUserInput>;
  notifications?: InputMaybe<NotificationUpdateManyWithoutUserInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutUsersInput>;
  owned_leads?: InputMaybe<LeadUpdateManyWithoutRepInput>;
  panda_doc_access?: InputMaybe<BoolFieldUpdateOperationsInput>;
  password?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  payment_settings?: InputMaybe<OrganizationPaymentSettingsUpdateManyWithoutCreated_ByInput>;
  payment_settings_updated?: InputMaybe<OrganizationPaymentSettingsUpdateManyWithoutLast_Updated_ByInput>;
  phone_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  post?: InputMaybe<PostUpdateManyWithoutAuthorInput>;
  ProductsCreated?: InputMaybe<ProductChangeLogUpdateManyWithoutUpdated_ByInput>;
  profile_image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  receive_transfer_attempts?: InputMaybe<TransferAttemptUpdateManyWithoutTo_UserInput>;
  receiver_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutReceiver_UserInput>;
  regions_white_list_array?: InputMaybe<UserUpdateregions_White_List_ArrayInput>;
  regions_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  rep_scores?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumRoleFieldUpdateOperationsInput>;
  routings?: InputMaybe<RoutingUpdateManyWithoutRepInput>;
  sale_config?: InputMaybe<SaleConfigUpdateManyWithoutCreated_ByInput>;
  sale_config_updated?: InputMaybe<SaleConfigUpdateManyWithoutLast_Updated_ByInput>;
  sales_team_column_options?: InputMaybe<UserUpdatesales_Team_Column_OptionsInput>;
  schedule_items?: InputMaybe<ScheduleItemUpdateManyWithoutUserInput>;
  send_transfer_attempts?: InputMaybe<TransferAttemptUpdateManyWithoutFrom_UserInput>;
  sender_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutSender_UserInput>;
  sequence_entry_criteria_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SequenceActionLog?: InputMaybe<StepActionLogUpdateManyWithoutRepInput>;
  sequenceDashboardView?: InputMaybe<SequenceDashboardViewUpdateManyWithoutOwned_ByInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutUserInput>;
  Sequences_created?: InputMaybe<SequenceUpdateManyWithoutCreated_ByInput>;
  Sequences_modified_by_user?: InputMaybe<SequenceUpdateManyWithoutLast_Updated_ByInput>;
  session_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  set_user_cycle?: InputMaybe<SaleCycleUpdateManyWithoutSet_UserInput>;
  set_user_stat?: InputMaybe<StatLeadAggregationUpdateManyWithoutSet_UserInput>;
  sf_user_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SmsItems?: InputMaybe<SmsItemUpdateManyWithoutRepInput>;
  SnoozeTracking?: InputMaybe<SnoozeTrackingUpdateManyWithoutRepInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  statusHistory?: InputMaybe<UserStatusHistoryUpdateManyWithoutUserInput>;
  sub_industry_white_list_array?: InputMaybe<UserUpdatesub_Industry_White_List_ArrayInput>;
  sub_industry_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  support_rep?: InputMaybe<BoolFieldUpdateOperationsInput>;
  taking_demos?: InputMaybe<BoolFieldUpdateOperationsInput>;
  team?: InputMaybe<TeamUpdateOneWithoutMembersInput>;
  TemplateVersion?: InputMaybe<TemplateVersionUpdateManyWithoutCreated_ByInput>;
  timekit_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timekit_type?: InputMaybe<EnumTimekittypeFieldUpdateOperationsInput>;
  timeOffRequest?: InputMaybe<TimeOffRequestUpdateManyWithoutRequesting_UserInput>;
  timezone_raw?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transfer_type?: InputMaybe<EnumTransferTypeFieldUpdateOperationsInput>;
  transfer_user_cycle?: InputMaybe<SaleCycleUpdateManyWithoutTransfer_UserInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user_custom_availability?: InputMaybe<AvailabilityUpdateManyWithoutUserInput>;
  user_past_tokens?: InputMaybe<UserPastTokensUpdateManyWithoutUserInput>;
  visible_all_leads_sm?: InputMaybe<BoolFieldUpdateOperationsInput>;
  visible_call_report_columns?: InputMaybe<UserUpdatevisible_Call_Report_ColumnsInput>;
  visible_call_report_event_columns?: InputMaybe<UserUpdatevisible_Call_Report_Event_ColumnsInput>;
  visible_call_report_pipeline_columns?: InputMaybe<UserUpdatevisible_Call_Report_Pipeline_ColumnsInput>;
  visible_my_leads_columns?: InputMaybe<UserUpdatevisible_My_Leads_ColumnsInput>;
  voicemail_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutTemplateVersionInput = {
  ai_user?: InputMaybe<BoolFieldUpdateOperationsInput>;
  alternate_email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  always_show_calendar_options?: InputMaybe<BoolFieldUpdateOperationsInput>;
  associate_owner_rep_history?: InputMaybe<AssociationHistoryUpdateManyWithoutRepInput>;
  associate_user_history?: InputMaybe<AssociationHistoryUpdateManyWithoutUserInput>;
  auto_transfer_selection_receive_attempts?: InputMaybe<AutoTransferSelectionUpdateManyWithoutFrom_UserInput>;
  auto_transfer_selection_send_attempts?: InputMaybe<AutoTransferSelectionUpdateManyWithoutTo_UserInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptUpdateManyWithoutUserInput>;
  callSharingAsCoach?: InputMaybe<CallSharingUpdateManyWithoutCoachInput>;
  callSharingAsUser?: InputMaybe<CallSharingUpdateManyWithoutReceiver_UserInput>;
  channel?: InputMaybe<NullableEnumChannelfilterFieldUpdateOperationsInput>;
  close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  close_user_cycle?: InputMaybe<SaleCycleUpdateManyWithoutClose_UserInput>;
  close_user_stat?: InputMaybe<StatLeadAggregationUpdateManyWithoutClose_UserInput>;
  commissions?: InputMaybe<CommissionUpdateManyWithoutUserInput>;
  contact_requests?: InputMaybe<ContactRequestUpdateManyWithoutRequesting_UserInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  current_sequence_view?: InputMaybe<SequenceDashboardViewUpdateOneWithoutUserInput>;
  current_team_start_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  custom_field_white_list_array?: InputMaybe<UserCustomFieldFilterUpdateManyWithoutUserInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemUpdateManyWithoutUserInput>;
  daily_lead_claim?: InputMaybe<IntFieldUpdateOperationsInput>;
  dashboard_2_access?: InputMaybe<BoolFieldUpdateOperationsInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewUpdateManyWithoutUserInput>;
  default_audio_input?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  default_audio_output?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  default_availability?: InputMaybe<BoolFieldUpdateOperationsInput>;
  deleted_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  deprecate_old_session?: InputMaybe<BoolFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordUpdateManyWithoutUserInput>;
  favorite_leads?: InputMaybe<LeadUpdateManyWithoutFavorited_By_UsersInput>;
  first_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  folder_permissions?: InputMaybe<FolderPermissionUpdateManyWithoutUserInput>;
  folders?: InputMaybe<FolderUpdateManyWithoutOwnerInput>;
  from_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutOld_UserInput>;
  GlobalRouting?: InputMaybe<GlobalRepRoutingUpdateManyWithoutRepInput>;
  google_refresh_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  holds_per_prospect_vs_held_demo_per_dial?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  hook_subscriptions?: InputMaybe<WebHookSubscriptionUpdateManyWithoutUserInput>;
  hubspot_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  idle_owned_leads?: InputMaybe<LeadUpdateManyWithoutSecondary_RepsInput>;
  industry_filter?: InputMaybe<BoolFieldUpdateOperationsInput>;
  industry_white_list_array?: InputMaybe<UserUpdateindustry_White_List_ArrayInput>;
  industry_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  joinMe_access_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  joinMe_refresh_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  last_demo_transferred_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  last_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutUserInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryUpdateManyWithoutUserInput>;
  lead_intents?: InputMaybe<LeadIntentUpdateManyWithoutUserInput>;
  lead_source_filter?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lead_source_white_list_array?: InputMaybe<UserUpdatelead_Source_White_List_ArrayInput>;
  lead_source_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  leads_last_called?: InputMaybe<LeadUpdateManyWithoutLast_Call_Result_RepInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusUpdateManyWithoutUserInput>;
  manager_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  note_item?: InputMaybe<NoteItemUpdateManyWithoutUserInput>;
  notifications?: InputMaybe<NotificationUpdateManyWithoutUserInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutUsersInput>;
  owned_leads?: InputMaybe<LeadUpdateManyWithoutRepInput>;
  panda_doc_access?: InputMaybe<BoolFieldUpdateOperationsInput>;
  password?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  payment_settings?: InputMaybe<OrganizationPaymentSettingsUpdateManyWithoutCreated_ByInput>;
  payment_settings_updated?: InputMaybe<OrganizationPaymentSettingsUpdateManyWithoutLast_Updated_ByInput>;
  phone_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  post?: InputMaybe<PostUpdateManyWithoutAuthorInput>;
  ProductsCreated?: InputMaybe<ProductChangeLogUpdateManyWithoutUpdated_ByInput>;
  profile_image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  receive_transfer_attempts?: InputMaybe<TransferAttemptUpdateManyWithoutTo_UserInput>;
  receiver_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutReceiver_UserInput>;
  regions_white_list_array?: InputMaybe<UserUpdateregions_White_List_ArrayInput>;
  regions_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  rep_scores?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumRoleFieldUpdateOperationsInput>;
  routings?: InputMaybe<RoutingUpdateManyWithoutRepInput>;
  sale_config?: InputMaybe<SaleConfigUpdateManyWithoutCreated_ByInput>;
  sale_config_updated?: InputMaybe<SaleConfigUpdateManyWithoutLast_Updated_ByInput>;
  sales_team_column_options?: InputMaybe<UserUpdatesales_Team_Column_OptionsInput>;
  schedule_items?: InputMaybe<ScheduleItemUpdateManyWithoutUserInput>;
  send_transfer_attempts?: InputMaybe<TransferAttemptUpdateManyWithoutFrom_UserInput>;
  sender_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutSender_UserInput>;
  sequence_entry_criteria_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SequenceActionLog?: InputMaybe<StepActionLogUpdateManyWithoutRepInput>;
  sequenceDashboardView?: InputMaybe<SequenceDashboardViewUpdateManyWithoutOwned_ByInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutUserInput>;
  Sequences_created?: InputMaybe<SequenceUpdateManyWithoutCreated_ByInput>;
  Sequences_modified_by_user?: InputMaybe<SequenceUpdateManyWithoutLast_Updated_ByInput>;
  session_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  set_user_cycle?: InputMaybe<SaleCycleUpdateManyWithoutSet_UserInput>;
  set_user_stat?: InputMaybe<StatLeadAggregationUpdateManyWithoutSet_UserInput>;
  sf_user_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SmsItems?: InputMaybe<SmsItemUpdateManyWithoutRepInput>;
  SnoozeTracking?: InputMaybe<SnoozeTrackingUpdateManyWithoutRepInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  statusHistory?: InputMaybe<UserStatusHistoryUpdateManyWithoutUserInput>;
  sub_industry_white_list_array?: InputMaybe<UserUpdatesub_Industry_White_List_ArrayInput>;
  sub_industry_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  support_rep?: InputMaybe<BoolFieldUpdateOperationsInput>;
  taking_demos?: InputMaybe<BoolFieldUpdateOperationsInput>;
  team?: InputMaybe<TeamUpdateOneWithoutMembersInput>;
  Template?: InputMaybe<TemplateUpdateManyWithoutLast_Modified_ByInput>;
  timekit_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timekit_type?: InputMaybe<EnumTimekittypeFieldUpdateOperationsInput>;
  timeOffRequest?: InputMaybe<TimeOffRequestUpdateManyWithoutRequesting_UserInput>;
  timezone_raw?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transfer_type?: InputMaybe<EnumTransferTypeFieldUpdateOperationsInput>;
  transfer_user_cycle?: InputMaybe<SaleCycleUpdateManyWithoutTransfer_UserInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user_custom_availability?: InputMaybe<AvailabilityUpdateManyWithoutUserInput>;
  user_past_tokens?: InputMaybe<UserPastTokensUpdateManyWithoutUserInput>;
  visible_all_leads_sm?: InputMaybe<BoolFieldUpdateOperationsInput>;
  visible_call_report_columns?: InputMaybe<UserUpdatevisible_Call_Report_ColumnsInput>;
  visible_call_report_event_columns?: InputMaybe<UserUpdatevisible_Call_Report_Event_ColumnsInput>;
  visible_call_report_pipeline_columns?: InputMaybe<UserUpdatevisible_Call_Report_Pipeline_ColumnsInput>;
  visible_my_leads_columns?: InputMaybe<UserUpdatevisible_My_Leads_ColumnsInput>;
  voicemail_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutTimeOffRequestInput = {
  ai_user?: InputMaybe<BoolFieldUpdateOperationsInput>;
  alternate_email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  always_show_calendar_options?: InputMaybe<BoolFieldUpdateOperationsInput>;
  associate_owner_rep_history?: InputMaybe<AssociationHistoryUpdateManyWithoutRepInput>;
  associate_user_history?: InputMaybe<AssociationHistoryUpdateManyWithoutUserInput>;
  auto_transfer_selection_receive_attempts?: InputMaybe<AutoTransferSelectionUpdateManyWithoutFrom_UserInput>;
  auto_transfer_selection_send_attempts?: InputMaybe<AutoTransferSelectionUpdateManyWithoutTo_UserInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptUpdateManyWithoutUserInput>;
  callSharingAsCoach?: InputMaybe<CallSharingUpdateManyWithoutCoachInput>;
  callSharingAsUser?: InputMaybe<CallSharingUpdateManyWithoutReceiver_UserInput>;
  channel?: InputMaybe<NullableEnumChannelfilterFieldUpdateOperationsInput>;
  close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  close_user_cycle?: InputMaybe<SaleCycleUpdateManyWithoutClose_UserInput>;
  close_user_stat?: InputMaybe<StatLeadAggregationUpdateManyWithoutClose_UserInput>;
  commissions?: InputMaybe<CommissionUpdateManyWithoutUserInput>;
  contact_requests?: InputMaybe<ContactRequestUpdateManyWithoutRequesting_UserInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  current_sequence_view?: InputMaybe<SequenceDashboardViewUpdateOneWithoutUserInput>;
  current_team_start_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  custom_field_white_list_array?: InputMaybe<UserCustomFieldFilterUpdateManyWithoutUserInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemUpdateManyWithoutUserInput>;
  daily_lead_claim?: InputMaybe<IntFieldUpdateOperationsInput>;
  dashboard_2_access?: InputMaybe<BoolFieldUpdateOperationsInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewUpdateManyWithoutUserInput>;
  default_audio_input?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  default_audio_output?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  default_availability?: InputMaybe<BoolFieldUpdateOperationsInput>;
  deleted_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  deprecate_old_session?: InputMaybe<BoolFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordUpdateManyWithoutUserInput>;
  favorite_leads?: InputMaybe<LeadUpdateManyWithoutFavorited_By_UsersInput>;
  first_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  folder_permissions?: InputMaybe<FolderPermissionUpdateManyWithoutUserInput>;
  folders?: InputMaybe<FolderUpdateManyWithoutOwnerInput>;
  from_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutOld_UserInput>;
  GlobalRouting?: InputMaybe<GlobalRepRoutingUpdateManyWithoutRepInput>;
  google_refresh_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  holds_per_prospect_vs_held_demo_per_dial?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  hook_subscriptions?: InputMaybe<WebHookSubscriptionUpdateManyWithoutUserInput>;
  hubspot_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  idle_owned_leads?: InputMaybe<LeadUpdateManyWithoutSecondary_RepsInput>;
  industry_filter?: InputMaybe<BoolFieldUpdateOperationsInput>;
  industry_white_list_array?: InputMaybe<UserUpdateindustry_White_List_ArrayInput>;
  industry_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  joinMe_access_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  joinMe_refresh_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  last_demo_transferred_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  last_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutUserInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryUpdateManyWithoutUserInput>;
  lead_intents?: InputMaybe<LeadIntentUpdateManyWithoutUserInput>;
  lead_source_filter?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lead_source_white_list_array?: InputMaybe<UserUpdatelead_Source_White_List_ArrayInput>;
  lead_source_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  leads_last_called?: InputMaybe<LeadUpdateManyWithoutLast_Call_Result_RepInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusUpdateManyWithoutUserInput>;
  manager_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  note_item?: InputMaybe<NoteItemUpdateManyWithoutUserInput>;
  notifications?: InputMaybe<NotificationUpdateManyWithoutUserInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutUsersInput>;
  owned_leads?: InputMaybe<LeadUpdateManyWithoutRepInput>;
  panda_doc_access?: InputMaybe<BoolFieldUpdateOperationsInput>;
  password?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  payment_settings?: InputMaybe<OrganizationPaymentSettingsUpdateManyWithoutCreated_ByInput>;
  payment_settings_updated?: InputMaybe<OrganizationPaymentSettingsUpdateManyWithoutLast_Updated_ByInput>;
  phone_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  post?: InputMaybe<PostUpdateManyWithoutAuthorInput>;
  ProductsCreated?: InputMaybe<ProductChangeLogUpdateManyWithoutUpdated_ByInput>;
  profile_image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  receive_transfer_attempts?: InputMaybe<TransferAttemptUpdateManyWithoutTo_UserInput>;
  receiver_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutReceiver_UserInput>;
  regions_white_list_array?: InputMaybe<UserUpdateregions_White_List_ArrayInput>;
  regions_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  rep_scores?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumRoleFieldUpdateOperationsInput>;
  routings?: InputMaybe<RoutingUpdateManyWithoutRepInput>;
  sale_config?: InputMaybe<SaleConfigUpdateManyWithoutCreated_ByInput>;
  sale_config_updated?: InputMaybe<SaleConfigUpdateManyWithoutLast_Updated_ByInput>;
  sales_team_column_options?: InputMaybe<UserUpdatesales_Team_Column_OptionsInput>;
  schedule_items?: InputMaybe<ScheduleItemUpdateManyWithoutUserInput>;
  send_transfer_attempts?: InputMaybe<TransferAttemptUpdateManyWithoutFrom_UserInput>;
  sender_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutSender_UserInput>;
  sequence_entry_criteria_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SequenceActionLog?: InputMaybe<StepActionLogUpdateManyWithoutRepInput>;
  sequenceDashboardView?: InputMaybe<SequenceDashboardViewUpdateManyWithoutOwned_ByInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutUserInput>;
  Sequences_created?: InputMaybe<SequenceUpdateManyWithoutCreated_ByInput>;
  Sequences_modified_by_user?: InputMaybe<SequenceUpdateManyWithoutLast_Updated_ByInput>;
  session_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  set_user_cycle?: InputMaybe<SaleCycleUpdateManyWithoutSet_UserInput>;
  set_user_stat?: InputMaybe<StatLeadAggregationUpdateManyWithoutSet_UserInput>;
  sf_user_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SmsItems?: InputMaybe<SmsItemUpdateManyWithoutRepInput>;
  SnoozeTracking?: InputMaybe<SnoozeTrackingUpdateManyWithoutRepInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  statusHistory?: InputMaybe<UserStatusHistoryUpdateManyWithoutUserInput>;
  sub_industry_white_list_array?: InputMaybe<UserUpdatesub_Industry_White_List_ArrayInput>;
  sub_industry_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  support_rep?: InputMaybe<BoolFieldUpdateOperationsInput>;
  taking_demos?: InputMaybe<BoolFieldUpdateOperationsInput>;
  team?: InputMaybe<TeamUpdateOneWithoutMembersInput>;
  Template?: InputMaybe<TemplateUpdateManyWithoutLast_Modified_ByInput>;
  TemplateVersion?: InputMaybe<TemplateVersionUpdateManyWithoutCreated_ByInput>;
  timekit_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timekit_type?: InputMaybe<EnumTimekittypeFieldUpdateOperationsInput>;
  timezone_raw?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transfer_type?: InputMaybe<EnumTransferTypeFieldUpdateOperationsInput>;
  transfer_user_cycle?: InputMaybe<SaleCycleUpdateManyWithoutTransfer_UserInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user_custom_availability?: InputMaybe<AvailabilityUpdateManyWithoutUserInput>;
  user_past_tokens?: InputMaybe<UserPastTokensUpdateManyWithoutUserInput>;
  visible_all_leads_sm?: InputMaybe<BoolFieldUpdateOperationsInput>;
  visible_call_report_columns?: InputMaybe<UserUpdatevisible_Call_Report_ColumnsInput>;
  visible_call_report_event_columns?: InputMaybe<UserUpdatevisible_Call_Report_Event_ColumnsInput>;
  visible_call_report_pipeline_columns?: InputMaybe<UserUpdatevisible_Call_Report_Pipeline_ColumnsInput>;
  visible_my_leads_columns?: InputMaybe<UserUpdatevisible_My_Leads_ColumnsInput>;
  voicemail_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutTransfer_User_CycleInput = {
  ai_user?: InputMaybe<BoolFieldUpdateOperationsInput>;
  alternate_email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  always_show_calendar_options?: InputMaybe<BoolFieldUpdateOperationsInput>;
  associate_owner_rep_history?: InputMaybe<AssociationHistoryUpdateManyWithoutRepInput>;
  associate_user_history?: InputMaybe<AssociationHistoryUpdateManyWithoutUserInput>;
  auto_transfer_selection_receive_attempts?: InputMaybe<AutoTransferSelectionUpdateManyWithoutFrom_UserInput>;
  auto_transfer_selection_send_attempts?: InputMaybe<AutoTransferSelectionUpdateManyWithoutTo_UserInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptUpdateManyWithoutUserInput>;
  callSharingAsCoach?: InputMaybe<CallSharingUpdateManyWithoutCoachInput>;
  callSharingAsUser?: InputMaybe<CallSharingUpdateManyWithoutReceiver_UserInput>;
  channel?: InputMaybe<NullableEnumChannelfilterFieldUpdateOperationsInput>;
  close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  close_user_cycle?: InputMaybe<SaleCycleUpdateManyWithoutClose_UserInput>;
  close_user_stat?: InputMaybe<StatLeadAggregationUpdateManyWithoutClose_UserInput>;
  commissions?: InputMaybe<CommissionUpdateManyWithoutUserInput>;
  contact_requests?: InputMaybe<ContactRequestUpdateManyWithoutRequesting_UserInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  current_sequence_view?: InputMaybe<SequenceDashboardViewUpdateOneWithoutUserInput>;
  current_team_start_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  custom_field_white_list_array?: InputMaybe<UserCustomFieldFilterUpdateManyWithoutUserInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemUpdateManyWithoutUserInput>;
  daily_lead_claim?: InputMaybe<IntFieldUpdateOperationsInput>;
  dashboard_2_access?: InputMaybe<BoolFieldUpdateOperationsInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewUpdateManyWithoutUserInput>;
  default_audio_input?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  default_audio_output?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  default_availability?: InputMaybe<BoolFieldUpdateOperationsInput>;
  deleted_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  deprecate_old_session?: InputMaybe<BoolFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordUpdateManyWithoutUserInput>;
  favorite_leads?: InputMaybe<LeadUpdateManyWithoutFavorited_By_UsersInput>;
  first_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  folder_permissions?: InputMaybe<FolderPermissionUpdateManyWithoutUserInput>;
  folders?: InputMaybe<FolderUpdateManyWithoutOwnerInput>;
  from_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutOld_UserInput>;
  GlobalRouting?: InputMaybe<GlobalRepRoutingUpdateManyWithoutRepInput>;
  google_refresh_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  holds_per_prospect_vs_held_demo_per_dial?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  hook_subscriptions?: InputMaybe<WebHookSubscriptionUpdateManyWithoutUserInput>;
  hubspot_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  idle_owned_leads?: InputMaybe<LeadUpdateManyWithoutSecondary_RepsInput>;
  industry_filter?: InputMaybe<BoolFieldUpdateOperationsInput>;
  industry_white_list_array?: InputMaybe<UserUpdateindustry_White_List_ArrayInput>;
  industry_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  joinMe_access_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  joinMe_refresh_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  last_demo_transferred_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  last_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutUserInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryUpdateManyWithoutUserInput>;
  lead_intents?: InputMaybe<LeadIntentUpdateManyWithoutUserInput>;
  lead_source_filter?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lead_source_white_list_array?: InputMaybe<UserUpdatelead_Source_White_List_ArrayInput>;
  lead_source_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  leads_last_called?: InputMaybe<LeadUpdateManyWithoutLast_Call_Result_RepInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusUpdateManyWithoutUserInput>;
  manager_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  note_item?: InputMaybe<NoteItemUpdateManyWithoutUserInput>;
  notifications?: InputMaybe<NotificationUpdateManyWithoutUserInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutUsersInput>;
  owned_leads?: InputMaybe<LeadUpdateManyWithoutRepInput>;
  panda_doc_access?: InputMaybe<BoolFieldUpdateOperationsInput>;
  password?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  payment_settings?: InputMaybe<OrganizationPaymentSettingsUpdateManyWithoutCreated_ByInput>;
  payment_settings_updated?: InputMaybe<OrganizationPaymentSettingsUpdateManyWithoutLast_Updated_ByInput>;
  phone_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  post?: InputMaybe<PostUpdateManyWithoutAuthorInput>;
  ProductsCreated?: InputMaybe<ProductChangeLogUpdateManyWithoutUpdated_ByInput>;
  profile_image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  receive_transfer_attempts?: InputMaybe<TransferAttemptUpdateManyWithoutTo_UserInput>;
  receiver_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutReceiver_UserInput>;
  regions_white_list_array?: InputMaybe<UserUpdateregions_White_List_ArrayInput>;
  regions_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  rep_scores?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumRoleFieldUpdateOperationsInput>;
  routings?: InputMaybe<RoutingUpdateManyWithoutRepInput>;
  sale_config?: InputMaybe<SaleConfigUpdateManyWithoutCreated_ByInput>;
  sale_config_updated?: InputMaybe<SaleConfigUpdateManyWithoutLast_Updated_ByInput>;
  sales_team_column_options?: InputMaybe<UserUpdatesales_Team_Column_OptionsInput>;
  schedule_items?: InputMaybe<ScheduleItemUpdateManyWithoutUserInput>;
  send_transfer_attempts?: InputMaybe<TransferAttemptUpdateManyWithoutFrom_UserInput>;
  sender_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutSender_UserInput>;
  sequence_entry_criteria_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SequenceActionLog?: InputMaybe<StepActionLogUpdateManyWithoutRepInput>;
  sequenceDashboardView?: InputMaybe<SequenceDashboardViewUpdateManyWithoutOwned_ByInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutUserInput>;
  Sequences_created?: InputMaybe<SequenceUpdateManyWithoutCreated_ByInput>;
  Sequences_modified_by_user?: InputMaybe<SequenceUpdateManyWithoutLast_Updated_ByInput>;
  session_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  set_user_cycle?: InputMaybe<SaleCycleUpdateManyWithoutSet_UserInput>;
  set_user_stat?: InputMaybe<StatLeadAggregationUpdateManyWithoutSet_UserInput>;
  sf_user_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SmsItems?: InputMaybe<SmsItemUpdateManyWithoutRepInput>;
  SnoozeTracking?: InputMaybe<SnoozeTrackingUpdateManyWithoutRepInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  statusHistory?: InputMaybe<UserStatusHistoryUpdateManyWithoutUserInput>;
  sub_industry_white_list_array?: InputMaybe<UserUpdatesub_Industry_White_List_ArrayInput>;
  sub_industry_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  support_rep?: InputMaybe<BoolFieldUpdateOperationsInput>;
  taking_demos?: InputMaybe<BoolFieldUpdateOperationsInput>;
  team?: InputMaybe<TeamUpdateOneWithoutMembersInput>;
  Template?: InputMaybe<TemplateUpdateManyWithoutLast_Modified_ByInput>;
  TemplateVersion?: InputMaybe<TemplateVersionUpdateManyWithoutCreated_ByInput>;
  timekit_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timekit_type?: InputMaybe<EnumTimekittypeFieldUpdateOperationsInput>;
  timeOffRequest?: InputMaybe<TimeOffRequestUpdateManyWithoutRequesting_UserInput>;
  timezone_raw?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transfer_type?: InputMaybe<EnumTransferTypeFieldUpdateOperationsInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user_custom_availability?: InputMaybe<AvailabilityUpdateManyWithoutUserInput>;
  user_past_tokens?: InputMaybe<UserPastTokensUpdateManyWithoutUserInput>;
  visible_all_leads_sm?: InputMaybe<BoolFieldUpdateOperationsInput>;
  visible_call_report_columns?: InputMaybe<UserUpdatevisible_Call_Report_ColumnsInput>;
  visible_call_report_event_columns?: InputMaybe<UserUpdatevisible_Call_Report_Event_ColumnsInput>;
  visible_call_report_pipeline_columns?: InputMaybe<UserUpdatevisible_Call_Report_Pipeline_ColumnsInput>;
  visible_my_leads_columns?: InputMaybe<UserUpdatevisible_My_Leads_ColumnsInput>;
  voicemail_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutUser_Custom_AvailabilityInput = {
  ai_user?: InputMaybe<BoolFieldUpdateOperationsInput>;
  alternate_email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  always_show_calendar_options?: InputMaybe<BoolFieldUpdateOperationsInput>;
  associate_owner_rep_history?: InputMaybe<AssociationHistoryUpdateManyWithoutRepInput>;
  associate_user_history?: InputMaybe<AssociationHistoryUpdateManyWithoutUserInput>;
  auto_transfer_selection_receive_attempts?: InputMaybe<AutoTransferSelectionUpdateManyWithoutFrom_UserInput>;
  auto_transfer_selection_send_attempts?: InputMaybe<AutoTransferSelectionUpdateManyWithoutTo_UserInput>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptUpdateManyWithoutUserInput>;
  callSharingAsCoach?: InputMaybe<CallSharingUpdateManyWithoutCoachInput>;
  callSharingAsUser?: InputMaybe<CallSharingUpdateManyWithoutReceiver_UserInput>;
  channel?: InputMaybe<NullableEnumChannelfilterFieldUpdateOperationsInput>;
  close_rate?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  close_user_cycle?: InputMaybe<SaleCycleUpdateManyWithoutClose_UserInput>;
  close_user_stat?: InputMaybe<StatLeadAggregationUpdateManyWithoutClose_UserInput>;
  commissions?: InputMaybe<CommissionUpdateManyWithoutUserInput>;
  contact_requests?: InputMaybe<ContactRequestUpdateManyWithoutRequesting_UserInput>;
  created_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  current_sequence_view?: InputMaybe<SequenceDashboardViewUpdateOneWithoutUserInput>;
  current_team_start_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  custom_field_white_list_array?: InputMaybe<UserCustomFieldFilterUpdateManyWithoutUserInput>;
  custom_queue_items?: InputMaybe<CustomQueueItemUpdateManyWithoutUserInput>;
  daily_lead_claim?: InputMaybe<IntFieldUpdateOperationsInput>;
  dashboard_2_access?: InputMaybe<BoolFieldUpdateOperationsInput>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewUpdateManyWithoutUserInput>;
  default_audio_input?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  default_audio_output?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  default_availability?: InputMaybe<BoolFieldUpdateOperationsInput>;
  deleted_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  deprecate_old_session?: InputMaybe<BoolFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordUpdateManyWithoutUserInput>;
  favorite_leads?: InputMaybe<LeadUpdateManyWithoutFavorited_By_UsersInput>;
  first_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  folder_permissions?: InputMaybe<FolderPermissionUpdateManyWithoutUserInput>;
  folders?: InputMaybe<FolderUpdateManyWithoutOwnerInput>;
  from_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutOld_UserInput>;
  GlobalRouting?: InputMaybe<GlobalRepRoutingUpdateManyWithoutRepInput>;
  google_refresh_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  holds_per_prospect_vs_held_demo_per_dial?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  hook_subscriptions?: InputMaybe<WebHookSubscriptionUpdateManyWithoutUserInput>;
  hubspot_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  idle_owned_leads?: InputMaybe<LeadUpdateManyWithoutSecondary_RepsInput>;
  industry_filter?: InputMaybe<BoolFieldUpdateOperationsInput>;
  industry_white_list_array?: InputMaybe<UserUpdateindustry_White_List_ArrayInput>;
  industry_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  joinMe_access_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  joinMe_refresh_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  last_demo_transferred_at?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  last_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  lead_activities?: InputMaybe<LeadActivityUpdateManyWithoutUserInput>;
  lead_import_history?: InputMaybe<LeadImportHistoryUpdateManyWithoutUserInput>;
  lead_intents?: InputMaybe<LeadIntentUpdateManyWithoutUserInput>;
  lead_source_filter?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lead_source_white_list_array?: InputMaybe<UserUpdatelead_Source_White_List_ArrayInput>;
  lead_source_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  leads_last_called?: InputMaybe<LeadUpdateManyWithoutLast_Call_Result_RepInput>;
  liveUserStatus?: InputMaybe<LiveUserStatusUpdateManyWithoutUserInput>;
  manager_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  note_item?: InputMaybe<NoteItemUpdateManyWithoutUserInput>;
  notifications?: InputMaybe<NotificationUpdateManyWithoutUserInput>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutUsersInput>;
  owned_leads?: InputMaybe<LeadUpdateManyWithoutRepInput>;
  panda_doc_access?: InputMaybe<BoolFieldUpdateOperationsInput>;
  password?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  payment_settings?: InputMaybe<OrganizationPaymentSettingsUpdateManyWithoutCreated_ByInput>;
  payment_settings_updated?: InputMaybe<OrganizationPaymentSettingsUpdateManyWithoutLast_Updated_ByInput>;
  phone_number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  post?: InputMaybe<PostUpdateManyWithoutAuthorInput>;
  ProductsCreated?: InputMaybe<ProductChangeLogUpdateManyWithoutUpdated_ByInput>;
  profile_image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  receive_transfer_attempts?: InputMaybe<TransferAttemptUpdateManyWithoutTo_UserInput>;
  receiver_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutReceiver_UserInput>;
  regions_white_list_array?: InputMaybe<UserUpdateregions_White_List_ArrayInput>;
  regions_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  rep_scores?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumRoleFieldUpdateOperationsInput>;
  routings?: InputMaybe<RoutingUpdateManyWithoutRepInput>;
  sale_config?: InputMaybe<SaleConfigUpdateManyWithoutCreated_ByInput>;
  sale_config_updated?: InputMaybe<SaleConfigUpdateManyWithoutLast_Updated_ByInput>;
  sales_team_column_options?: InputMaybe<UserUpdatesales_Team_Column_OptionsInput>;
  schedule_items?: InputMaybe<ScheduleItemUpdateManyWithoutUserInput>;
  send_transfer_attempts?: InputMaybe<TransferAttemptUpdateManyWithoutFrom_UserInput>;
  sender_assignment_history?: InputMaybe<LeadAssignmentHistoryUpdateManyWithoutSender_UserInput>;
  sequence_entry_criteria_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SequenceActionLog?: InputMaybe<StepActionLogUpdateManyWithoutRepInput>;
  sequenceDashboardView?: InputMaybe<SequenceDashboardViewUpdateManyWithoutOwned_ByInput>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadUpdateManyWithoutUserInput>;
  Sequences_created?: InputMaybe<SequenceUpdateManyWithoutCreated_ByInput>;
  Sequences_modified_by_user?: InputMaybe<SequenceUpdateManyWithoutLast_Updated_ByInput>;
  session_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  set_user_cycle?: InputMaybe<SaleCycleUpdateManyWithoutSet_UserInput>;
  set_user_stat?: InputMaybe<StatLeadAggregationUpdateManyWithoutSet_UserInput>;
  sf_user_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SmsItems?: InputMaybe<SmsItemUpdateManyWithoutRepInput>;
  SnoozeTracking?: InputMaybe<SnoozeTrackingUpdateManyWithoutRepInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  statusHistory?: InputMaybe<UserStatusHistoryUpdateManyWithoutUserInput>;
  sub_industry_white_list_array?: InputMaybe<UserUpdatesub_Industry_White_List_ArrayInput>;
  sub_industry_white_list_assign_type?: InputMaybe<EnumAssignTypeFieldUpdateOperationsInput>;
  support_rep?: InputMaybe<BoolFieldUpdateOperationsInput>;
  taking_demos?: InputMaybe<BoolFieldUpdateOperationsInput>;
  team?: InputMaybe<TeamUpdateOneWithoutMembersInput>;
  Template?: InputMaybe<TemplateUpdateManyWithoutLast_Modified_ByInput>;
  TemplateVersion?: InputMaybe<TemplateVersionUpdateManyWithoutCreated_ByInput>;
  timekit_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timekit_type?: InputMaybe<EnumTimekittypeFieldUpdateOperationsInput>;
  timeOffRequest?: InputMaybe<TimeOffRequestUpdateManyWithoutRequesting_UserInput>;
  timezone_raw?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  transfer_type?: InputMaybe<EnumTransferTypeFieldUpdateOperationsInput>;
  transfer_user_cycle?: InputMaybe<SaleCycleUpdateManyWithoutTransfer_UserInput>;
  updated_at?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user_past_tokens?: InputMaybe<UserPastTokensUpdateManyWithoutUserInput>;
  visible_all_leads_sm?: InputMaybe<BoolFieldUpdateOperationsInput>;
  visible_call_report_columns?: InputMaybe<UserUpdatevisible_Call_Report_ColumnsInput>;
  visible_call_report_event_columns?: InputMaybe<UserUpdatevisible_Call_Report_Event_ColumnsInput>;
  visible_call_report_pipeline_columns?: InputMaybe<UserUpdatevisible_Call_Report_Pipeline_ColumnsInput>;
  visible_my_leads_columns?: InputMaybe<UserUpdatevisible_My_Leads_ColumnsInput>;
  voicemail_url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type UserUpdateWithWhereUniqueWithoutCurrent_Sequence_ViewInput = {
  data: UserUpdateWithoutCurrent_Sequence_ViewInput;
  where: UserWhereUniqueInput;
};

export type UserUpdateWithWhereUniqueWithoutFavorite_LeadsInput = {
  data: UserUpdateWithoutFavorite_LeadsInput;
  where: UserWhereUniqueInput;
};

export type UserUpdateWithWhereUniqueWithoutIdle_Owned_LeadsInput = {
  data: UserUpdateWithoutIdle_Owned_LeadsInput;
  where: UserWhereUniqueInput;
};

export type UserUpdateWithWhereUniqueWithoutOrganizationInput = {
  data: UserUpdateWithoutOrganizationInput;
  where: UserWhereUniqueInput;
};

export type UserUpdateWithWhereUniqueWithoutTeamInput = {
  data: UserUpdateWithoutTeamInput;
  where: UserWhereUniqueInput;
};

export type UserUpsertWithoutAssociate_Owner_Rep_HistoryInput = {
  create: UserCreateWithoutAssociate_Owner_Rep_HistoryInput;
  update: UserUpdateWithoutAssociate_Owner_Rep_HistoryInput;
};

export type UserUpsertWithoutAssociate_User_HistoryInput = {
  create: UserCreateWithoutAssociate_User_HistoryInput;
  update: UserUpdateWithoutAssociate_User_HistoryInput;
};

export type UserUpsertWithoutAuto_Transfer_Selection_Receive_AttemptsInput = {
  create: UserCreateWithoutAuto_Transfer_Selection_Receive_AttemptsInput;
  update: UserUpdateWithoutAuto_Transfer_Selection_Receive_AttemptsInput;
};

export type UserUpsertWithoutAuto_Transfer_Selection_Send_AttemptsInput = {
  create: UserCreateWithoutAuto_Transfer_Selection_Send_AttemptsInput;
  update: UserUpdateWithoutAuto_Transfer_Selection_Send_AttemptsInput;
};

export type UserUpsertWithoutCallMeNowAttemptsInput = {
  create: UserCreateWithoutCallMeNowAttemptsInput;
  update: UserUpdateWithoutCallMeNowAttemptsInput;
};

export type UserUpsertWithoutCallSharingAsCoachInput = {
  create: UserCreateWithoutCallSharingAsCoachInput;
  update: UserUpdateWithoutCallSharingAsCoachInput;
};

export type UserUpsertWithoutCallSharingAsUserInput = {
  create: UserCreateWithoutCallSharingAsUserInput;
  update: UserUpdateWithoutCallSharingAsUserInput;
};

export type UserUpsertWithoutClose_User_CycleInput = {
  create: UserCreateWithoutClose_User_CycleInput;
  update: UserUpdateWithoutClose_User_CycleInput;
};

export type UserUpsertWithoutClose_User_StatInput = {
  create: UserCreateWithoutClose_User_StatInput;
  update: UserUpdateWithoutClose_User_StatInput;
};

export type UserUpsertWithoutCommissionsInput = {
  create: UserCreateWithoutCommissionsInput;
  update: UserUpdateWithoutCommissionsInput;
};

export type UserUpsertWithoutContact_RequestsInput = {
  create: UserCreateWithoutContact_RequestsInput;
  update: UserUpdateWithoutContact_RequestsInput;
};

export type UserUpsertWithoutCustom_Field_White_List_ArrayInput = {
  create: UserCreateWithoutCustom_Field_White_List_ArrayInput;
  update: UserUpdateWithoutCustom_Field_White_List_ArrayInput;
};

export type UserUpsertWithoutCustom_Queue_ItemsInput = {
  create: UserCreateWithoutCustom_Queue_ItemsInput;
  update: UserUpdateWithoutCustom_Queue_ItemsInput;
};

export type UserUpsertWithoutDashboardSavedViewInput = {
  create: UserCreateWithoutDashboardSavedViewInput;
  update: UserUpdateWithoutDashboardSavedViewInput;
};

export type UserUpsertWithoutEmailSyncRecordInput = {
  create: UserCreateWithoutEmailSyncRecordInput;
  update: UserUpdateWithoutEmailSyncRecordInput;
};

export type UserUpsertWithoutFolder_PermissionsInput = {
  create: UserCreateWithoutFolder_PermissionsInput;
  update: UserUpdateWithoutFolder_PermissionsInput;
};

export type UserUpsertWithoutFoldersInput = {
  create: UserCreateWithoutFoldersInput;
  update: UserUpdateWithoutFoldersInput;
};

export type UserUpsertWithoutFrom_Assignment_HistoryInput = {
  create: UserCreateWithoutFrom_Assignment_HistoryInput;
  update: UserUpdateWithoutFrom_Assignment_HistoryInput;
};

export type UserUpsertWithoutGlobalRoutingInput = {
  create: UserCreateWithoutGlobalRoutingInput;
  update: UserUpdateWithoutGlobalRoutingInput;
};

export type UserUpsertWithoutLead_ActivitiesInput = {
  create: UserCreateWithoutLead_ActivitiesInput;
  update: UserUpdateWithoutLead_ActivitiesInput;
};

export type UserUpsertWithoutLead_Import_HistoryInput = {
  create: UserCreateWithoutLead_Import_HistoryInput;
  update: UserUpdateWithoutLead_Import_HistoryInput;
};

export type UserUpsertWithoutLead_IntentsInput = {
  create: UserCreateWithoutLead_IntentsInput;
  update: UserUpdateWithoutLead_IntentsInput;
};

export type UserUpsertWithoutLeads_Last_CalledInput = {
  create: UserCreateWithoutLeads_Last_CalledInput;
  update: UserUpdateWithoutLeads_Last_CalledInput;
};

export type UserUpsertWithoutLiveUserStatusInput = {
  create: UserCreateWithoutLiveUserStatusInput;
  update: UserUpdateWithoutLiveUserStatusInput;
};

export type UserUpsertWithoutNote_ItemInput = {
  create: UserCreateWithoutNote_ItemInput;
  update: UserUpdateWithoutNote_ItemInput;
};

export type UserUpsertWithoutNotificationsInput = {
  create: UserCreateWithoutNotificationsInput;
  update: UserUpdateWithoutNotificationsInput;
};

export type UserUpsertWithoutOwned_LeadsInput = {
  create: UserCreateWithoutOwned_LeadsInput;
  update: UserUpdateWithoutOwned_LeadsInput;
};

export type UserUpsertWithoutPayment_Settings_UpdatedInput = {
  create: UserCreateWithoutPayment_Settings_UpdatedInput;
  update: UserUpdateWithoutPayment_Settings_UpdatedInput;
};

export type UserUpsertWithoutPayment_SettingsInput = {
  create: UserCreateWithoutPayment_SettingsInput;
  update: UserUpdateWithoutPayment_SettingsInput;
};

export type UserUpsertWithoutProductsCreatedInput = {
  create: UserCreateWithoutProductsCreatedInput;
  update: UserUpdateWithoutProductsCreatedInput;
};

export type UserUpsertWithoutReceive_Transfer_AttemptsInput = {
  create: UserCreateWithoutReceive_Transfer_AttemptsInput;
  update: UserUpdateWithoutReceive_Transfer_AttemptsInput;
};

export type UserUpsertWithoutReceiver_Assignment_HistoryInput = {
  create: UserCreateWithoutReceiver_Assignment_HistoryInput;
  update: UserUpdateWithoutReceiver_Assignment_HistoryInput;
};

export type UserUpsertWithoutRoutingsInput = {
  create: UserCreateWithoutRoutingsInput;
  update: UserUpdateWithoutRoutingsInput;
};

export type UserUpsertWithoutSale_Config_UpdatedInput = {
  create: UserCreateWithoutSale_Config_UpdatedInput;
  update: UserUpdateWithoutSale_Config_UpdatedInput;
};

export type UserUpsertWithoutSale_ConfigInput = {
  create: UserCreateWithoutSale_ConfigInput;
  update: UserUpdateWithoutSale_ConfigInput;
};

export type UserUpsertWithoutSchedule_ItemsInput = {
  create: UserCreateWithoutSchedule_ItemsInput;
  update: UserUpdateWithoutSchedule_ItemsInput;
};

export type UserUpsertWithoutSend_Transfer_AttemptsInput = {
  create: UserCreateWithoutSend_Transfer_AttemptsInput;
  update: UserUpdateWithoutSend_Transfer_AttemptsInput;
};

export type UserUpsertWithoutSender_Assignment_HistoryInput = {
  create: UserCreateWithoutSender_Assignment_HistoryInput;
  update: UserUpdateWithoutSender_Assignment_HistoryInput;
};

export type UserUpsertWithoutSequenceActionLogInput = {
  create: UserCreateWithoutSequenceActionLogInput;
  update: UserUpdateWithoutSequenceActionLogInput;
};

export type UserUpsertWithoutSequenceDashboardViewInput = {
  create: UserCreateWithoutSequenceDashboardViewInput;
  update: UserUpdateWithoutSequenceDashboardViewInput;
};

export type UserUpsertWithoutSequenceEntryExitLeadInput = {
  create: UserCreateWithoutSequenceEntryExitLeadInput;
  update: UserUpdateWithoutSequenceEntryExitLeadInput;
};

export type UserUpsertWithoutSequences_CreatedInput = {
  create: UserCreateWithoutSequences_CreatedInput;
  update: UserUpdateWithoutSequences_CreatedInput;
};

export type UserUpsertWithoutSequences_Modified_By_UserInput = {
  create: UserCreateWithoutSequences_Modified_By_UserInput;
  update: UserUpdateWithoutSequences_Modified_By_UserInput;
};

export type UserUpsertWithoutSet_User_CycleInput = {
  create: UserCreateWithoutSet_User_CycleInput;
  update: UserUpdateWithoutSet_User_CycleInput;
};

export type UserUpsertWithoutSet_User_StatInput = {
  create: UserCreateWithoutSet_User_StatInput;
  update: UserUpdateWithoutSet_User_StatInput;
};

export type UserUpsertWithoutSmsItemsInput = {
  create: UserCreateWithoutSmsItemsInput;
  update: UserUpdateWithoutSmsItemsInput;
};

export type UserUpsertWithoutSnoozeTrackingInput = {
  create: UserCreateWithoutSnoozeTrackingInput;
  update: UserUpdateWithoutSnoozeTrackingInput;
};

export type UserUpsertWithoutTemplateInput = {
  create: UserCreateWithoutTemplateInput;
  update: UserUpdateWithoutTemplateInput;
};

export type UserUpsertWithoutTemplateVersionInput = {
  create: UserCreateWithoutTemplateVersionInput;
  update: UserUpdateWithoutTemplateVersionInput;
};

export type UserUpsertWithoutTimeOffRequestInput = {
  create: UserCreateWithoutTimeOffRequestInput;
  update: UserUpdateWithoutTimeOffRequestInput;
};

export type UserUpsertWithoutTransfer_User_CycleInput = {
  create: UserCreateWithoutTransfer_User_CycleInput;
  update: UserUpdateWithoutTransfer_User_CycleInput;
};

export type UserUpsertWithoutUser_Custom_AvailabilityInput = {
  create: UserCreateWithoutUser_Custom_AvailabilityInput;
  update: UserUpdateWithoutUser_Custom_AvailabilityInput;
};

export type UserUpsertWithWhereUniqueWithoutCurrent_Sequence_ViewInput = {
  create: UserCreateWithoutCurrent_Sequence_ViewInput;
  update: UserUpdateWithoutCurrent_Sequence_ViewInput;
  where: UserWhereUniqueInput;
};

export type UserUpsertWithWhereUniqueWithoutFavorite_LeadsInput = {
  create: UserCreateWithoutFavorite_LeadsInput;
  update: UserUpdateWithoutFavorite_LeadsInput;
  where: UserWhereUniqueInput;
};

export type UserUpsertWithWhereUniqueWithoutIdle_Owned_LeadsInput = {
  create: UserCreateWithoutIdle_Owned_LeadsInput;
  update: UserUpdateWithoutIdle_Owned_LeadsInput;
  where: UserWhereUniqueInput;
};

export type UserUpsertWithWhereUniqueWithoutOrganizationInput = {
  create: UserCreateWithoutOrganizationInput;
  update: UserUpdateWithoutOrganizationInput;
  where: UserWhereUniqueInput;
};

export type UserUpsertWithWhereUniqueWithoutTeamInput = {
  create: UserCreateWithoutTeamInput;
  update: UserUpdateWithoutTeamInput;
  where: UserWhereUniqueInput;
};

export type UserWhereInput = {
  ai_user?: InputMaybe<BoolFilter>;
  alternate_email?: InputMaybe<StringNullableFilter>;
  always_show_calendar_options?: InputMaybe<BoolFilter>;
  AND?: InputMaybe<Array<UserWhereInput>>;
  associate_owner_rep_history?: InputMaybe<AssociationHistoryListRelationFilter>;
  associate_user_history?: InputMaybe<AssociationHistoryListRelationFilter>;
  auto_transfer_selection_receive_attempts?: InputMaybe<AutoTransferSelectionListRelationFilter>;
  auto_transfer_selection_send_attempts?: InputMaybe<AutoTransferSelectionListRelationFilter>;
  callMeNowAttempts?: InputMaybe<CallMeNowAttemptListRelationFilter>;
  callSharingAsCoach?: InputMaybe<CallSharingListRelationFilter>;
  callSharingAsUser?: InputMaybe<CallSharingListRelationFilter>;
  channel?: InputMaybe<EnumChannelfilterNullableFilter>;
  close_rate?: InputMaybe<FloatNullableFilter>;
  close_user_cycle?: InputMaybe<SaleCycleListRelationFilter>;
  close_user_stat?: InputMaybe<StatLeadAggregationListRelationFilter>;
  commissions?: InputMaybe<CommissionListRelationFilter>;
  contact_requests?: InputMaybe<ContactRequestListRelationFilter>;
  created_at?: InputMaybe<DateTimeFilter>;
  current_sequence_view?: InputMaybe<SequenceDashboardViewWhereInput>;
  current_sequence_view_id?: InputMaybe<StringNullableFilter>;
  current_team_start_date?: InputMaybe<DateTimeNullableFilter>;
  custom_field_white_list_array?: InputMaybe<UserCustomFieldFilterListRelationFilter>;
  custom_queue_items?: InputMaybe<CustomQueueItemListRelationFilter>;
  daily_lead_claim?: InputMaybe<IntFilter>;
  dashboard_2_access?: InputMaybe<BoolFilter>;
  DashboardSavedView?: InputMaybe<DashboardSavedViewListRelationFilter>;
  default_audio_input?: InputMaybe<StringNullableFilter>;
  default_audio_output?: InputMaybe<StringNullableFilter>;
  default_availability?: InputMaybe<BoolFilter>;
  deleted_at?: InputMaybe<DateTimeNullableFilter>;
  deprecate_old_session?: InputMaybe<BoolFilter>;
  email?: InputMaybe<StringFilter>;
  EmailSyncRecord?: InputMaybe<EmailSyncRecordListRelationFilter>;
  favorite_leads?: InputMaybe<LeadListRelationFilter>;
  first_name?: InputMaybe<StringNullableFilter>;
  folder_permissions?: InputMaybe<FolderPermissionListRelationFilter>;
  folders?: InputMaybe<FolderListRelationFilter>;
  from_assignment_history?: InputMaybe<LeadAssignmentHistoryListRelationFilter>;
  GlobalRouting?: InputMaybe<GlobalRepRoutingListRelationFilter>;
  google_refresh_token?: InputMaybe<StringNullableFilter>;
  holds_per_prospect_vs_held_demo_per_dial?: InputMaybe<FloatNullableFilter>;
  hook_subscriptions?: InputMaybe<WebHookSubscriptionListRelationFilter>;
  hubspot_id?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  idle_owned_leads?: InputMaybe<LeadListRelationFilter>;
  industry_filter?: InputMaybe<BoolFilter>;
  industry_white_list_array?: InputMaybe<StringNullableListFilter>;
  industry_white_list_assign_type?: InputMaybe<EnumAssignTypeFilter>;
  joinMe_access_token?: InputMaybe<StringNullableFilter>;
  joinMe_refresh_token?: InputMaybe<StringNullableFilter>;
  last_demo_transferred_at?: InputMaybe<DateTimeNullableFilter>;
  last_name?: InputMaybe<StringNullableFilter>;
  lead_activities?: InputMaybe<LeadActivityListRelationFilter>;
  lead_import_history?: InputMaybe<LeadImportHistoryListRelationFilter>;
  lead_intents?: InputMaybe<LeadIntentListRelationFilter>;
  lead_source_filter?: InputMaybe<BoolFilter>;
  lead_source_white_list_array?: InputMaybe<StringNullableListFilter>;
  lead_source_white_list_assign_type?: InputMaybe<EnumAssignTypeFilter>;
  leads_last_called?: InputMaybe<LeadListRelationFilter>;
  liveUserStatus?: InputMaybe<LiveUserStatusListRelationFilter>;
  manager_id?: InputMaybe<StringNullableFilter>;
  NOT?: InputMaybe<Array<UserWhereInput>>;
  note_item?: InputMaybe<NoteItemListRelationFilter>;
  notifications?: InputMaybe<NotificationListRelationFilter>;
  OR?: InputMaybe<Array<UserWhereInput>>;
  organization?: InputMaybe<OrganizationWhereInput>;
  organization_id?: InputMaybe<StringFilter>;
  owned_leads?: InputMaybe<LeadListRelationFilter>;
  panda_doc_access?: InputMaybe<BoolFilter>;
  password?: InputMaybe<StringNullableFilter>;
  payment_settings?: InputMaybe<OrganizationPaymentSettingsListRelationFilter>;
  payment_settings_updated?: InputMaybe<OrganizationPaymentSettingsListRelationFilter>;
  phone_number?: InputMaybe<StringNullableFilter>;
  post?: InputMaybe<PostListRelationFilter>;
  ProductsCreated?: InputMaybe<ProductChangeLogListRelationFilter>;
  profile_image?: InputMaybe<StringNullableFilter>;
  receive_transfer_attempts?: InputMaybe<TransferAttemptListRelationFilter>;
  receiver_assignment_history?: InputMaybe<LeadAssignmentHistoryListRelationFilter>;
  regions_white_list_array?: InputMaybe<StringNullableListFilter>;
  regions_white_list_assign_type?: InputMaybe<EnumAssignTypeFilter>;
  rep_scores?: InputMaybe<FloatNullableFilter>;
  role?: InputMaybe<EnumRoleFilter>;
  routings?: InputMaybe<RoutingListRelationFilter>;
  sale_config?: InputMaybe<SaleConfigListRelationFilter>;
  sale_config_updated?: InputMaybe<SaleConfigListRelationFilter>;
  sales_team_column_options?: InputMaybe<StringNullableListFilter>;
  schedule_items?: InputMaybe<ScheduleItemListRelationFilter>;
  send_transfer_attempts?: InputMaybe<TransferAttemptListRelationFilter>;
  sender_assignment_history?: InputMaybe<LeadAssignmentHistoryListRelationFilter>;
  sequence_entry_criteria_id?: InputMaybe<StringNullableFilter>;
  SequenceActionLog?: InputMaybe<StepActionLogListRelationFilter>;
  sequenceDashboardView?: InputMaybe<SequenceDashboardViewListRelationFilter>;
  SequenceEntryExitLead?: InputMaybe<SequenceEntryExitLeadListRelationFilter>;
  Sequences_created?: InputMaybe<SequenceListRelationFilter>;
  Sequences_modified_by_user?: InputMaybe<SequenceListRelationFilter>;
  session_token?: InputMaybe<StringNullableFilter>;
  set_user_cycle?: InputMaybe<SaleCycleListRelationFilter>;
  set_user_stat?: InputMaybe<StatLeadAggregationListRelationFilter>;
  sf_user_id?: InputMaybe<StringNullableFilter>;
  SmsItems?: InputMaybe<SmsItemListRelationFilter>;
  SnoozeTracking?: InputMaybe<SnoozeTrackingListRelationFilter>;
  status?: InputMaybe<EnumStatusFilter>;
  statusHistory?: InputMaybe<UserStatusHistoryListRelationFilter>;
  sub_industry_white_list_array?: InputMaybe<StringNullableListFilter>;
  sub_industry_white_list_assign_type?: InputMaybe<EnumAssignTypeFilter>;
  support_rep?: InputMaybe<BoolFilter>;
  taking_demos?: InputMaybe<BoolFilter>;
  team?: InputMaybe<TeamWhereInput>;
  team_id?: InputMaybe<StringNullableFilter>;
  Template?: InputMaybe<TemplateListRelationFilter>;
  TemplateVersion?: InputMaybe<TemplateVersionListRelationFilter>;
  timekit_id?: InputMaybe<StringNullableFilter>;
  timekit_type?: InputMaybe<EnumTimekittypeFilter>;
  timeOffRequest?: InputMaybe<TimeOffRequestListRelationFilter>;
  timezone_raw?: InputMaybe<StringNullableFilter>;
  transfer_type?: InputMaybe<EnumTransferTypeFilter>;
  transfer_user_cycle?: InputMaybe<SaleCycleListRelationFilter>;
  updated_at?: InputMaybe<DateTimeFilter>;
  user_custom_availability?: InputMaybe<AvailabilityListRelationFilter>;
  user_past_tokens?: InputMaybe<UserPastTokensListRelationFilter>;
  visible_all_leads_sm?: InputMaybe<BoolFilter>;
  visible_call_report_columns?: InputMaybe<StringNullableListFilter>;
  visible_call_report_event_columns?: InputMaybe<StringNullableListFilter>;
  visible_call_report_pipeline_columns?: InputMaybe<StringNullableListFilter>;
  visible_my_leads_columns?: InputMaybe<StringNullableListFilter>;
  voicemail_url?: InputMaybe<StringNullableFilter>;
};

export type UserWhereUniqueInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  phone_number?: InputMaybe<Scalars['String']['input']>;
};

export type UserWithUserPreview = {
  __typename?: 'UserWithUserPreview';
  user?: Maybe<User>;
  user_id?: Maybe<Scalars['String']['output']>;
  user_preview?: Maybe<UserPreview>;
};

export type ValueAndLabelObject = {
  __typename?: 'ValueAndLabelObject';
  label: Scalars['String']['output'];
  value: Scalars['Int']['output'];
};

export type VideoUpload = {
  __typename?: 'VideoUpload';
  filename?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

/** Visibility Input for a template */
export type VisibilityInput = {
  roles?: InputMaybe<Array<Role>>;
  team_ids?: InputMaybe<Array<Scalars['String']['input']>>;
  user_ids?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type WebHookSubscriptionCreateManyUserInput = {
  event_type: Subscriptioneventtype;
  id?: InputMaybe<Scalars['String']['input']>;
  subscriber_identifier?: InputMaybe<Scalars['String']['input']>;
  url: Scalars['String']['input'];
};

export type WebHookSubscriptionCreateManyUserInputEnvelope = {
  data?: InputMaybe<Array<WebHookSubscriptionCreateManyUserInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type WebHookSubscriptionCreateNestedManyWithoutUserInput = {
  connect?: InputMaybe<Array<WebHookSubscriptionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<WebHookSubscriptionCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<WebHookSubscriptionCreateWithoutUserInput>>;
  createMany?: InputMaybe<WebHookSubscriptionCreateManyUserInputEnvelope>;
};

export type WebHookSubscriptionCreateOrConnectWithoutUserInput = {
  create: WebHookSubscriptionCreateWithoutUserInput;
  where: WebHookSubscriptionWhereUniqueInput;
};

export type WebHookSubscriptionCreateWithoutUserInput = {
  event_type: Subscriptioneventtype;
  id?: InputMaybe<Scalars['String']['input']>;
  subscriber_identifier?: InputMaybe<Scalars['String']['input']>;
  url: Scalars['String']['input'];
};

export type WebHookSubscriptionListRelationFilter = {
  every?: InputMaybe<WebHookSubscriptionWhereInput>;
  none?: InputMaybe<WebHookSubscriptionWhereInput>;
  some?: InputMaybe<WebHookSubscriptionWhereInput>;
};

export type WebHookSubscriptionOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
  count?: InputMaybe<SortOrder>;
};

export type WebHookSubscriptionScalarWhereInput = {
  AND?: InputMaybe<Array<WebHookSubscriptionScalarWhereInput>>;
  event_type?: InputMaybe<EnumSubscriptioneventtypeFilter>;
  id?: InputMaybe<StringFilter>;
  NOT?: InputMaybe<Array<WebHookSubscriptionScalarWhereInput>>;
  OR?: InputMaybe<Array<WebHookSubscriptionScalarWhereInput>>;
  subscriber_identifier?: InputMaybe<StringNullableFilter>;
  url?: InputMaybe<StringFilter>;
  user_id?: InputMaybe<StringFilter>;
};

export type WebHookSubscriptionUpdateManyMutationInput = {
  event_type?: InputMaybe<EnumSubscriptioneventtypeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  subscriber_identifier?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  url?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type WebHookSubscriptionUpdateManyWithoutUserInput = {
  connect?: InputMaybe<Array<WebHookSubscriptionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<WebHookSubscriptionCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<WebHookSubscriptionCreateWithoutUserInput>>;
  createMany?: InputMaybe<WebHookSubscriptionCreateManyUserInputEnvelope>;
  delete?: InputMaybe<Array<WebHookSubscriptionWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<WebHookSubscriptionScalarWhereInput>>;
  disconnect?: InputMaybe<Array<WebHookSubscriptionWhereUniqueInput>>;
  set?: InputMaybe<Array<WebHookSubscriptionWhereUniqueInput>>;
  update?: InputMaybe<Array<WebHookSubscriptionUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: InputMaybe<Array<WebHookSubscriptionUpdateManyWithWhereWithoutUserInput>>;
  upsert?: InputMaybe<Array<WebHookSubscriptionUpsertWithWhereUniqueWithoutUserInput>>;
};

export type WebHookSubscriptionUpdateManyWithWhereWithoutUserInput = {
  data: WebHookSubscriptionUpdateManyMutationInput;
  where: WebHookSubscriptionScalarWhereInput;
};

export type WebHookSubscriptionUpdateWithoutUserInput = {
  event_type?: InputMaybe<EnumSubscriptioneventtypeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  subscriber_identifier?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  url?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type WebHookSubscriptionUpdateWithWhereUniqueWithoutUserInput = {
  data: WebHookSubscriptionUpdateWithoutUserInput;
  where: WebHookSubscriptionWhereUniqueInput;
};

export type WebHookSubscriptionUpsertWithWhereUniqueWithoutUserInput = {
  create: WebHookSubscriptionCreateWithoutUserInput;
  update: WebHookSubscriptionUpdateWithoutUserInput;
  where: WebHookSubscriptionWhereUniqueInput;
};

export type WebHookSubscriptionWhereInput = {
  AND?: InputMaybe<Array<WebHookSubscriptionWhereInput>>;
  event_type?: InputMaybe<EnumSubscriptioneventtypeFilter>;
  id?: InputMaybe<StringFilter>;
  NOT?: InputMaybe<Array<WebHookSubscriptionWhereInput>>;
  OR?: InputMaybe<Array<WebHookSubscriptionWhereInput>>;
  subscriber_identifier?: InputMaybe<StringNullableFilter>;
  url?: InputMaybe<StringFilter>;
  user?: InputMaybe<UserWhereInput>;
  user_id?: InputMaybe<StringFilter>;
};

export type WebHookSubscriptionWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']['input']>;
};

export enum Weekday {
  Friday = 'Friday',
  Monday = 'Monday',
  Saturday = 'Saturday',
  Sunday = 'Sunday',
  Thursday = 'Thursday',
  Tuesday = 'Tuesday',
  Wednesday = 'Wednesday'
}

export enum When {
  After = 'After',
  Before = 'Before'
}

/** Used for select fields */
export type WidgetScheduleItem = {
  __typename?: 'WidgetScheduleItem';
  lead_email?: Maybe<Scalars['String']['output']>;
  rep_first_name?: Maybe<Scalars['String']['output']>;
  rep_image?: Maybe<Scalars['String']['output']>;
  rep_last_name?: Maybe<Scalars['String']['output']>;
  scheduleItem?: Maybe<ScheduleItem>;
};
