import moment from "moment";
import { v4 as uuidv4 } from "uuid";

type Tracker = {
  id: string;
  name: string;
  matches: {
    type: string;
    value: string;
    insightRefs: any[];
    messageRefs: {
      id: string;
      text: string;
      offset: number;
    }[];
  }[];
};

type CallScoreCriteria = {
  name: string;
  score: number;
  summary: string;
  feedback: {
    negative: {
      summary: string;
    };
    positive: {
      summary: string;
    };
  };
  criteriaId: string;
};

export const AIProvider = {
  SYMBOL: "symbol",
  ASSEMBLY: "assembly",
};

/** Generates the sentiment line chart data for the call report page. */
export const generateSymbolSentimentDataPoints = (data: any) => {
  const repName = data.fetchConference?.salesperson?.split(" ")?.[0];

  const transcriptMessages = data.fetchConference?.symbol_ai_data?.transcript?.messages || [];
  const repNameInTranscript = transcriptMessages.some((m: any) => m?.from?.name?.includes(repName));

  const repMessages = repNameInTranscript
    ? transcriptMessages.filter((m: any) => m?.from?.name?.includes(repName))
    : transcriptMessages.filter((m: any) => m?.from?.name?.includes("Speaker 1"));

  const leadMessages = repNameInTranscript
    ? transcriptMessages.filter((m: any) => !m?.from?.name?.includes(repName))
    : transcriptMessages.filter((m: any) => m?.from?.name?.includes("Speaker 2"));

  const repSentimentDataPoints = [{ score: 0, x: 0 }];
  repMessages?.forEach((m: any) => {
    repSentimentDataPoints.push({
      score: m?.sentiment?.polarity?.score,
      x: m?.timeOffset,
    });
  });
  repSentimentDataPoints.push({ score: 0, x: data.fetchConference?.duration });

  const leadSentimentDataPoints = [{ score: 0, x: 0 }];
  leadMessages?.forEach((m: any) => {
    leadSentimentDataPoints.push({
      score: m?.sentiment?.polarity?.score,
      x: m?.timeOffset,
    });
  });
  leadSentimentDataPoints.push({ score: 0, x: data.fetchConference?.duration });

  return [repSentimentDataPoints, leadSentimentDataPoints];
};

export const generateConferenceSentimentDataPoints = (data: any) => {
  const conference = data.fetchConference?.conference_ai_data;
  if (!conference?.transcript) return [[], []];

  // Get speaker mapping if it exists
  const speakerMapping = conference.analytics?.mapping?.reduce((acc: any, map: any) => {
    acc[map.speaker] = map;
    return acc;
  }, {});

  // Find rep and lead based on mapping or default to speaker numbers
  const repSpeaker = speakerMapping
    ? (Object.values(speakerMapping).find((map: any) => map.role === "rep") as any)?.name || "1"
    : "1";

  const leadSpeaker = speakerMapping
    ? (Object.values(speakerMapping).find((map: any) => map.role === "lead") as any)?.name || "2"
    : "2";

  const repMessages = conference.transcript.filter((m: any) => m.speaker === repSpeaker);
  const leadMessages = conference.transcript.filter((m: any) => m.speaker === leadSpeaker);

  const repSentimentDataPoints = [{ score: 0, x: 0 }];
  repMessages.forEach((m: any) => {
    repSentimentDataPoints.push({
      score: m.sentiment?.score || 0,
      x: m.start / 1000,
    });
  });
  repSentimentDataPoints.push({ score: 0, x: data.fetchConference?.duration });

  const leadSentimentDataPoints = [{ score: 0, x: 0 }];
  leadMessages.forEach((m: any) => {
    leadSentimentDataPoints.push({
      score: m.sentiment?.score || 0,
      x: m.start / 1000,
    });
  });
  leadSentimentDataPoints.push({ score: 0, x: data.fetchConference?.duration });

  return [repSentimentDataPoints, leadSentimentDataPoints];
};

export const calculateSymbolSpeakerTimeline = (
  messages: any[],
  totalDuration: number,
  speakerId: string,
): { width: number; talking: boolean }[] => {
  if (!messages) return [];

  // Initialize the timeline array
  const timeline: { width: number; talking: boolean }[] = [];

  // Filter messages by speaker ID
  const speakerMessages = messages.filter((message) => message.from.id === speakerId);

  // Sort messages by timeOffset
  // messages are given to us in order, this is just a safety measure
  speakerMessages.sort((a, b) => a.timeOffset - b.timeOffset);

  // Track the last end time to calculate silent periods
  let lastEndTime = 0;

  speakerMessages.forEach((message, index) => {
    const startTime = message.timeOffset;
    const endTime = message.timeOffset + message.duration;

    // Calculate silent period before this message if any
    if (startTime > lastEndTime) {
      const silentWidth = ((startTime - lastEndTime) / totalDuration) * 100;
      timeline.push({ width: silentWidth, talking: false });
    }

    // Calculate talking period
    const talkingWidth = (message.duration / totalDuration) * 100;
    timeline.push({ width: talkingWidth, talking: true });

    // Update last end time
    lastEndTime = endTime;

    // Check if there's a gap between this message and the next
    if (index === speakerMessages.length - 1 && lastEndTime < totalDuration) {
      const finalSilentWidth = ((totalDuration - lastEndTime) / totalDuration) * 100;
      timeline.push({ width: finalSilentWidth, talking: false });
    }
  });

  return timeline;
};

export const calculateConferenceSpeakerTimeline = (
  messages: any[],
  totalDuration: number,
  speakerId: string,
): { width: number; talking: boolean }[] => {
  if (!messages || !totalDuration) return [];

  const timeline: { width: number; talking: boolean }[] = [];

  // Filter messages by speaker ID for conference format
  const speakerMessages = messages.filter((message) => message.speaker === speakerId);

  if (!speakerMessages.length) return [];

  // Sort messages by start time
  speakerMessages.sort((a, b) => a.start - b.start);

  let lastEndTime = 0;

  speakerMessages.forEach((message, index) => {
    const startTime = message.start / 1000; // Convert ms to seconds
    const endTime = message.end / 1000;
    const duration = endTime - startTime;

    if (startTime > lastEndTime) {
      const silentWidth = ((startTime - lastEndTime) / totalDuration) * 100;
      if (silentWidth > 0) {
        timeline.push({ width: silentWidth, talking: false });
      }
    }

    const talkingWidth = (duration / totalDuration) * 100;
    if (talkingWidth > 0) {
      timeline.push({ width: talkingWidth, talking: true });
    }

    lastEndTime = endTime;

    if (index === speakerMessages.length - 1 && lastEndTime < totalDuration) {
      const finalSilentWidth = ((totalDuration - lastEndTime) / totalDuration) * 100;
      if (finalSilentWidth > 0) {
        timeline.push({ width: finalSilentWidth, talking: false });
      }
    }
  });

  return timeline;
};

/**
 * Formats SYMBOL AI and call data for UI display.
 */
export const formatSymbolAiData = (data: any) => {
  const symbol = data.fetchConference?.symbol_ai_data;
  const [repSentimentDataPoints, leadSentimentDataPoints] = generateSymbolSentimentDataPoints(data);

  const repName = data.fetchConference?.salesperson?.split(" ")?.[0];

  // repSpeakerId and leadSpeakerId are hardcoded to assume this is a 2-person call
  // TODO: update logic to consider more than 2 speakers
  const repSpeakerId = symbol?.transcript?.messages?.find((m: any) => m?.from?.name?.includes("Speaker 1"))?.from?.id;
  const leadSpeakerId = symbol?.transcript?.messages?.find((m: any) => !m?.from?.name?.includes("Speaker 1"))?.from?.id;

  // Calculate total talk time for each speaker
  const speakerTimes = symbol?.transcript?.messages.reduce((acc: any, message: any) => {
    const speakerName = message.from.name;
    const duration = message.duration;
    if (acc[speakerName]) {
      acc[speakerName] += duration;
    } else {
      acc[speakerName] = duration;
    }
    return acc;
  }, {});

  let totalDuration: any;
  let talkTime;
  if (!!speakerTimes) {
    // Calculate total duration of talk time
    totalDuration = Object.values(speakerTimes).reduce((acc: any, duration: any) => acc + duration, 0);
    // Calculate talk time percentage for each speaker
    talkTime = Object.entries(speakerTimes).reduce((acc: any, [speakerName, duration]: any[]) => {
      const percentage = (duration / totalDuration) * 100;
      acc[speakerName] = percentage < 1 ? Math.ceil(percentage) : Math.floor(percentage);
      return acc;
    }, {});
  }

  return {
    provider: AIProvider.SYMBOL,
    summary: symbol?.summary?.summary?.status === 500 ? [] : symbol?.summary?.summary ?? [],
    nextSteps: symbol?.action_items?.actionItems ?? [],
    formattedTranscript: symbol?.transcript?.messages?.map((m: any) => {
      return {
        id: m.id,
        text: m.text || "",
        speaker: m.from.name || "NA",
        timestamp: moment.utc(m.timeOffset * 1000).format("mm:ss"),
      };
    }),
    repSentimentDataPoints,
    leadSentimentDataPoints,
    avgRepSentiment:
      repSentimentDataPoints.reduce((acc, point) => acc + point.score, 0) / repSentimentDataPoints.length,
    avgLeadSentiment:
      leadSentimentDataPoints.reduce((acc, point) => acc + point.score, 0) / leadSentimentDataPoints.length,
    repSpeakingTimeline: calculateSymbolSpeakerTimeline(
      symbol?.transcript?.messages,
      data.fetchConference.duration,
      repSpeakerId,
    ),
    leadSpeakingTimeline: calculateSymbolSpeakerTimeline(
      symbol?.transcript?.messages,
      data.fetchConference.duration,
      leadSpeakerId,
    ),
    talkTime,
    trackers:
      symbol?.trackers?.map((t: Tracker) => ({
        name: t.name,
        messageRefs: t.matches?.flatMap((m) => m.messageRefs || []),
      })) ?? [],
    callScore: {
      overall: symbol?.call_score?.score,
      communicationAndEngagement: symbol?.call_score?.criteria?.find(
        (c: CallScoreCriteria) => c.name === "Communication And Engagement",
      ),
      forwardMotion: symbol?.call_score?.criteria?.find((c: CallScoreCriteria) => c.name === "Forward Motion"),
      questionHandling: symbol?.call_score?.criteria?.find((c: CallScoreCriteria) => c.name === "Question Handling"),
      salesProcess: symbol?.call_score?.criteria?.find((c: CallScoreCriteria) => c.name === "Sales Process"),
    },
    topics: symbol?.topics || [],
  };
};

/**
 * Formats CONFERENCE AI and call data for UI display.
 */
export const formatConferenceAiData = (data: any) => {
  const conference = data.fetchConference?.conference_ai_data;
  if (!conference) return null;

  const [repSentimentDataPoints, leadSentimentDataPoints] = generateConferenceSentimentDataPoints(data);

  // First determine speaker mapping
  let speakerMapping = new Map();
  let speakerTimes = {};

  if (conference.analytics) {
    if (typeof conference.analytics === "object") {
      if (conference.analytics.mapping) {
        // Use provided mapping
        conference.analytics.mapping.forEach((map: any) => {
          speakerMapping.set(map.name, map.name);
        });

        speakerTimes = conference.analytics.analytics.reduce((acc: any, analytic: any) => {
          const name = Object.keys(analytic)[0];
          const value = analytic[name];
          const speakerName = speakerMapping.get(name);

          if (speakerName && value) {
            acc[speakerName] = parseFloat(value);
          }
          return acc;
        }, {});
      } else if (conference.analytics.analytics) {
        // Create mapping from analytics
        conference.analytics.analytics.forEach((analytic: any) => {
          const key = Object.keys(analytic)[0];
          speakerMapping.set(key, `Speaker ${key}`);
        });

        speakerTimes = conference.analytics.analytics.reduce((acc: any, analytic: any) => {
          const key = Object.keys(analytic)[0];
          const value = analytic[key];

          if (value && !isNaN(parseFloat(value))) {
            acc[speakerMapping.get(key)] = parseFloat(value);
          }
          return acc;
        }, {});
      }
    } else if (Array.isArray(conference.analytics)) {
      // Create mapping from array format
      conference.analytics.forEach((analytic: any, index: number) => {
        if (analytic.name) {
          const speakerNum = index + 1;
          speakerMapping.set(speakerNum.toString(), `Speaker ${speakerNum}`);
        } else if (analytic.speaker_name) {
          speakerMapping.set(analytic.speaker_name, `Speaker ${analytic.speaker_name}`);
        } else {
          const key = Object.keys(analytic)[0];
          if (key) speakerMapping.set(key, `Speaker ${key}`);
        }
      });

      speakerTimes = conference.analytics.reduce((acc: any, analytic: any) => {
        if (analytic.name) {
          const speakerNum = Object.keys(acc).length + 1;
          acc[`Speaker ${speakerNum}`] = parseFloat(analytic.name);
        } else if (analytic.speaker_name) {
          acc[`Speaker ${analytic.speaker_name}`] = parseFloat(analytic.total_talk_time);
        } else {
          const key = Object.keys(analytic)[0];
          const value = analytic[key];
          if (value && !isNaN(parseFloat(value))) {
            acc[speakerMapping.get(key)] = parseFloat(value);
          }
        }
        return acc;
      }, {});
    }
  }

  // Use consistent speaker names in transcript
  const formattedTranscript =
    conference.transcript?.map((m: any) => ({
      id: uuidv4(),
      text: m.text || "",
      speaker: speakerMapping.get(m.speaker) || `Speaker ${m.speaker}`,
      timestamp: moment.utc(m.start).format("mm:ss"),
    })) || [];

  let talkTime = {};
  if (speakerTimes) {
    talkTime = Object.entries(speakerTimes).reduce((acc: any, [speakerName, duration]: any[]) => {
      acc[speakerName] = Math.round(duration);
      return acc;
    }, {});
  }

  return {
    provider: AIProvider.ASSEMBLY,
    summary: Array.isArray(conference.summary)
    ? (conference.summary || [])?.map((summary: any) => ({
        text: summary.summary,
        message: summary.text,
      }))
    : conference.summary
        ?.split("- ")
        ?.filter((s: string) => s.trim() !== "")
        ?.map((s: string) => ({ text: s })) || [],
    nextSteps: (conference.action_items || [])?.map((action: any) => ({
      text: action.action_item,
      message: action.text,
    })),
    formattedTranscript,
    repSentimentDataPoints,
    leadSentimentDataPoints,
    avgRepSentiment: repSentimentDataPoints.length
      ? repSentimentDataPoints.reduce((acc, point) => acc + (point.score || 0), 0) / repSentimentDataPoints.length
      : 0,
    avgLeadSentiment: leadSentimentDataPoints.length
      ? leadSentimentDataPoints.reduce((acc, point) => acc + (point.score || 0), 0) / leadSentimentDataPoints.length
      : 0,
    repSpeakingTimeline: calculateConferenceSpeakerTimeline(conference.transcript, data.fetchConference.duration, "1"),
    leadSpeakingTimeline: calculateConferenceSpeakerTimeline(conference.transcript, data.fetchConference.duration, "2"),
    talkTime,
    callScore: {
      overall: conference.call_score?.score || null,
      communicationAndEngagement: null,
      forwardMotion: null,
      questionHandling: null,
      salesProcess: null,
    },
    trackers:
      conference.trackers?.map((item: Record<string, any>) => {
        // Find all messages that contain any part of the tracker text
        const relatedMessages = formattedTranscript.filter((m: any) => {
          // If the message text is contained within the tracker text
          // OR if the tracker text is contained within the message text
          return item.text.includes(m.text) || m.text.includes(item.text);
        });

        return {
          id: uuidv4(),
          name: item.tracker,
          messageRefs: relatedMessages.map((m: any) => ({
            id: m.id,
            text: m.text,
          })),
        };
      }) || [],
    topics: (conference.topics || [])?.map((topic: any) => {
      // Similar bidirectional check for topics
      const relatedMessages = formattedTranscript.filter((m: any) => {
        // If the message text is contained within the topic text
        // OR if the topic text is contained within the message text
        return topic.text.includes(m.text) || m.text.includes(topic.text);
      });

      return {
        id: uuidv4(),
        text: topic.topic,
        messageIds: relatedMessages.map((m: any) => m.id),
      };
    }),
  };
};
