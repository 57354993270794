import * as Sentry from "@sentry/react";
import * as React from "react";
import { useMemo, useContext, useEffect, useState } from "react";
import styled from "styled-components";

import { theme } from "../../utils/theme";

import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { Formik, FormikProps } from "formik";
import * as Yup from "yup";

import { CallContext, ModalContext, LeadCardContext } from "../../context";

import { debounce, isEmpty, isUndefined } from "lodash";
import { info, user_white } from "../../images/NewDesign";
import { CommunicationStates } from "../../utils/format";
import { appToast } from "../../utils/toast";
import { PhoenixStyledTooltip } from "../Dumb/PhoenixStyledTooltip";
import { PhoenixInputField } from "../Field/Phoenix/PhoenixInputField";
import { AppErrorText, AppText, FlexDiv, Loading, NewAppSelect, StatCard, TemplateEditor } from "../UI";
import { PhoenixIcon, PhoenixMultiSelect, PhoenixRadio } from "../UI/Phoenix";
import { PhoenixAppButton } from "../UI/Phoenix/PhoenixAppButton";
import { Modal } from "./Modal";
import { MixpanelActions } from "src/services/mixpanel";

import Switch from "react-switch";
import { useFlags } from "launchdarkly-react-client-sdk";
import { OptionItem } from "src/types";

interface EditEmailModalV2Props {}

const editEmailSchema = Yup.object().shape({
  lead_id: Yup.string().required("Lead ID is required"),
  emails: Yup.array().required("Email is required").min(1, "Email is required"),
  subject: Yup.string()
    .required("Subject is required")
    .test("subject", "Subject cannot be empty", (value: any) => {
      return value?.length > 0;
    }),

  html: Yup.string()
    .required("Email content is required")
    .test("html", "Email content cannot be empty", (value: any) => {
      return value?.length > 0;
    }),
});

interface MyFormikProps {
  lead_id: string;
  subject: string;
  html: string;
  emails: string[];
  use_signature?: boolean;
}

const SEND_EMAIL_TO_LEAD = gql`
  mutation sendEmailToLead(
    $lead_id: String!
    $subject: String
    $html: String!
    $emails: [String]
    $template_id: String
    $email_thread_id: String
    $step_action_id: String
    $use_signature: Boolean!
  ) {
    sendEmailToLead(
      lead_id: $lead_id
      subject: $subject
      html: $html
      emails: $emails
      template_id: $template_id
      email_thread_id: $email_thread_id
      step_action_id: $step_action_id
      use_signature: $use_signature
    ) {
      id
      lead_activity {
        lead_id
      }
    }
  }
`;

const FETCH_TEMPLATES = gql`
  query fetchTemplates($TemplateFilter: TemplateFilter!) {
    fetchTemplates(TemplateFilter: $TemplateFilter) {
      id
      name
      subject
      content
      type
    }
  }
`;

const FETCH_LEAD = gql`
  query fetchLead($id: String!) {
    fetchLead(id: $id) {
      id
      full_name
      business_name
      local_primary_phone_number
      email_sub_status
      alternate_contacts {
        channel
        value
      }
      primary_email
      associate_parent_id
    }
  }
`;

const FETCH_ASSOCIATE_CONTACTS = gql`
  query FetchAssociateContact($primaryLeadId: String!) {
    fetchAssociateContact(primary_lead_id: $primaryLeadId) {
      id
      primary_email
      alternate_contacts {
        channel
        value
      }
    }
  }
`;

const FETCH_TEMPLATE_PREVIEW = gql`
  query FetchEmailTemplatePreview($leadId: String!, $templateId: String!) {
    fetchEmailTemplatePreview(lead_id: $leadId, template_id: $templateId) {
      body
      from
      subject
      to
      template_id
    }
  }
`;

const EditEmailModalV2: React.FC<EditEmailModalV2Props> = () => {
  const { showEmailEditorModal, closeEmailEditorModal, emailEditorLeadData, setComposeEmailData } = useContext(
    ModalContext,
  );
  const { suggestedActionData } = useContext(CallContext);
  const { resetRecentActivities } = useContext(LeadCardContext);

  const [selectedTemplateId, setSelectedTemplateId] = useState("");

  const [initialContent, setInitialContent] = useState("");

  const [userHasAlteredTemplate, setUserHasAlteredTemplate] = useState(false);
  const [primaryLeadId, setPrimaryLeadId] = useState(emailEditorLeadData.lead_id);

  const formikRef = React.useRef<FormikProps<MyFormikProps>>(null);

  const { emailSignature } = useFlags();

  const debouncedSetComposeEmailData = useMemo(
    () =>
      debounce((data: { lead_id: string; html: string }) => {
        setComposeEmailData(data);
      }, 1000),
    [setComposeEmailData],
  );

  useEffect(() => {
    console.log("emailEditorLeadData", emailEditorLeadData);
  }, [emailEditorLeadData]);

  useEffect(() => {
    console.log("emailEditorLeadData", emailEditorLeadData);
  }, [emailEditorLeadData]);

  const [sendEmailToLead, { loading, error }] = useMutation(SEND_EMAIL_TO_LEAD, {
    onCompleted({ sendEmailToLead }) {
      console.log("sendEmailToLead: ", sendEmailToLead);
      MixpanelActions.track("Email Sent", {
        contact_type: "lead",
        contact_id: emailEditorLeadData.lead_id,
        has_template: Boolean(selectedTemplateId),
        action_source: emailEditorLeadData?.action_source,
      });
      appToast("Email sent successfully");
      closeEmailEditorModal();
    },
    onError({ message }) {
      console.log(message);
      appToast(message);
      Sentry.captureEvent({
        message: `editEmailModal GraphQL Error: ${message}`,
      });
    },
  });

  interface TemplateFilter {
    template_id?: string;
    template_name?: string;
    team_ids?: string[];
    user_ids?: string[];
    roles?: string[];
    type?: "SMS" | "EMAIL";
  }

  const { data: templates, loading: templatesLoading, error: templatesError } = useQuery(FETCH_TEMPLATES, {
    fetchPolicy: "network-only",
    variables: {
      TemplateFilter: {
        type: "EMAIL",
      },
    } as TemplateFilter,
  });

  const { data: leadData, loading: leadDataLoading, error: leadDataError, refetch: refetchLead } = useQuery(
    FETCH_LEAD,
    {
      fetchPolicy: "network-only",
      variables: {
        id: emailEditorLeadData.lead_id,
      },
    },
  );

  useEffect(() => {
    if (leadData?.fetchLead?.associate_parent_id) {
      setPrimaryLeadId(leadData.fetchLead.associate_parent_id);
      refetchLead({ id: leadData.fetchLead.associate_parent_id });
    }
  }, [leadData]);

  const { data: associateContacts, loading: associateContactsLoading, error: associateContactsError } = useQuery(
    FETCH_ASSOCIATE_CONTACTS,
    {
      fetchPolicy: "network-only",
      variables: {
        primaryLeadId: primaryLeadId,
      },
    },
  );

  // lazy query
  const [fetchTemplatePreview, { data: templatePreview, loading: templatePreviewLoading }] = useLazyQuery(
    FETCH_TEMPLATE_PREVIEW,
    {
      variables: {
        leadId: emailEditorLeadData.lead_id,
        templateId: selectedTemplateId,
      },
      onCompleted({ fetchEmailTemplatePreview }) {
        console.log("fetchEmailTemplatePreview: ", fetchEmailTemplatePreview);

        // set the selected template id
        setSelectedTemplateId(fetchEmailTemplatePreview?.template_id);

        // set the subject
        if (emailEditorLeadData?.email_thread_id) {
          if (emailEditorLeadData?.subject?.trim().slice(0, 3).toLowerCase() === "re:") {
            formikRef.current?.setFieldValue("subject", emailEditorLeadData?.subject);
          } else {
            formikRef.current?.setFieldValue("subject", `Re: ${emailEditorLeadData?.subject}`);
          }
        } else {
          formikRef.current?.setFieldValue("subject", fetchEmailTemplatePreview?.subject);
        }
        // set the content
        const formattedInitialContent = handleEmptyToken(fetchEmailTemplatePreview?.body);
        setInitialContent(formattedInitialContent.innerHTML);
      },
      onError({ message }) {
        console.log(message);
        appToast(message);
      },
      fetchPolicy: "network-only",
    },
  );

  const templateOptions = useMemo(() => {
    return templates?.fetchTemplates
      ?.map((template: any) => ({
        value: template.id,
        label: template.name,
      }))
      ?.concat({
        value: "",
        label: "Select a template",
      });
  }, [templates]);

  const { lead_id, email, email_thread_id, subject, html } = emailEditorLeadData;
  useEffect(() => {
    setInitialContent(html ?? "");
  }, []);

  const leadUnsubscribedFromEmail =
    leadData?.fetchLead?.email_sub_status === CommunicationStates.OPSIQUnsubscribed ||
    leadData?.fetchLead?.email_sub_status === CommunicationStates.LeadUnsubscribed;

  const leadEmails: OptionItem[] = [
    // Primary email of the lead
    { label: leadData?.fetchLead?.primary_email, value: leadData?.fetchLead?.primary_email },
    // Alternate contacts of the lead
    ...(leadData?.fetchLead?.alternate_contacts || [])
      .filter((contact: any) => contact?.channel === "Email")
      .map((contact: any) => ({ label: contact.value, value: contact.value })),
    // Primary email of each associate contact
    ...(associateContacts?.fetchAssociateContact || []).map((contact: any) => ({
      label: contact.primary_email,
      value: contact.primary_email,
    })),
    // Alternate contacts of each associate contact
    ...(associateContacts?.fetchAssociateContact || []).flatMap((contact: any) =>
      contact.alternate_contacts
        .filter((altContact: any) => altContact?.channel === "Email")
        .map((altContact: any) => ({ label: altContact.value, value: altContact.value })),
    ),
  ].filter((email) => email.label && email.value);
  return (
    <Formik
      innerRef={formikRef}
      enableReinitialize={true}
      validateOnMount
      initialValues={{
        lead_id: lead_id,
        emails: leadEmails?.map((email: OptionItem) => email?.value) as string[],
        subject:
          isEmpty(subject) || isUndefined(subject)
            ? ""
            : subject?.trim().slice(0, 3).toLowerCase() === "re:"
            ? subject
            : `Re: ${subject}`,
        html: html ?? "",
        use_signature: true,
      }}
      validationSchema={editEmailSchema}
      onSubmit={async (values) => {
        console.log("3 submit fired", values);
        await sendEmailToLead({
          variables: {
            lead_id: values.lead_id,
            subject: values.subject,
            html: handleLineHeights(values.html).innerHTML,
            emails: values.emails,
            template_id: !!selectedTemplateId ? selectedTemplateId : undefined,
            email_thread_id: email_thread_id,
            step_action_id: suggestedActionData?.leadData?.sequence_step?.actions?.[0]?.id,
            use_signature: values.use_signature,
          },
        });
        resetRecentActivities();

        // reset state
        setComposeEmailData({ lead_id: "", html: "" });
      }}
    >
      {({ submitForm, isSubmitting, values, errors, setFieldValue, isValid }: FormikProps<MyFormikProps>) => {
        console.log("values", values);
        console.log("errors", errors);

        return (
          <Modal open={!!showEmailEditorModal} onClose={() => closeEmailEditorModal()}>
            <Main>
              <PhoenixStyledTooltip id="email-editor-tooltip" place="right" lineHeight={16} />
              <TitleDiv>
                <AppText fontSize={16} fontWeight={600}>
                  Compose Email
                </AppText>
                <FlexDiv align="center" gap={8}>
                  <StatCard variant="primary" icon={user_white} />
                  <AppText
                    color={theme.text.neutral.primary}
                    fontWeight={500}
                    fontSize={14}
                    style={{
                      cursor: "pointer",
                      borderBottom: "none",
                    }}
                    onClick={() => window.open(`/lead-detail/${leadData?.fetchLead?.id}`, "_blank", "noreferrer")}
                  >
                    {leadData?.fetchLead?.full_name ||
                      leadData?.fetchLead?.business_name ||
                      leadData?.fetchLead?.local_phone_number ||
                      "Your Lead"}{" "}
                  </AppText>
                </FlexDiv>
                {leadUnsubscribedFromEmail && (
                  <AppText fontSize={12} fontWeight={400} color={theme.DANGER500}>
                    (Unsubscribed from email communications)
                  </AppText>
                )}
              </TitleDiv>
              <BodyDiv>
                <Row>
                  <FlexDiv gap={8} align="center">
                    <PhoenixMultiSelect
                      name="template"
                      isMulti={false}
                      isClearable={false}
                      width={462}
                      titleText="Template"
                      titleTextSpacing={8}
                      style={{ marginBottom: "8px" }}
                      options={templateOptions}
                      onChange={(e: any) => {
                        const selectedTemplate = templates?.fetchTemplates.find(
                          (template: any) => template?.id === e?.value,
                        );

                        const userSelectedTheBlankOption = e?.value === "";

                        if (userSelectedTheBlankOption) {
                          // formik
                          setFieldValue("subject", "");
                          setFieldValue("html", "");

                          // editor
                          setInitialContent("");

                          // template id state to send to BE on submit
                          setSelectedTemplateId("");
                        } else if (selectedTemplate) {
                          // formik
                          setFieldValue("html", "");

                          // we get the template preview then set the initial content
                          fetchTemplatePreview({
                            variables: {
                              leadId: emailEditorLeadData.lead_id,
                              templateId: e?.value || "",
                            },
                          });
                        }
                      }}
                      value={{
                        label: templateOptions?.find((option: OptionItem) => option.value === selectedTemplateId)
                          ?.label,
                        value: selectedTemplateId,
                      }}
                      marginBottom={false}
                    />
                    <PhoenixIcon
                      svg={info}
                      data-tip="Email templates are created by admins. Templates will automatically fill in the subject and body of an email with the lead's information."
                      data-for="email-editor-tooltip"
                      variant="brand"
                      size={16}
                      style={{ paddingTop: "16px" }}
                    />
                  </FlexDiv>
                </Row>
                <Row>
                  <PhoenixInputField
                    id="emailSubjectFieldInput"
                    name="subject"
                    titleText="Email Subject"
                    requiredStar={!email_thread_id}
                    disabled={!!email_thread_id}
                    placeholder="Provide a subject line"
                    labelWeight={500}
                    variant={errors.subject ? "error" : "primary"}
                  />
                </Row>
                <Row>
                  <PhoenixMultiSelect
                    name="emails"
                    titleText="To"
                    isMulti={true}
                    required={!email_thread_id}
                    isDisabled={!!email_thread_id}
                    placeholder="Select recipients"
                    options={leadEmails}
                    // value={leadData?.fetchLead?.primary_email}
                    onChange={(e: OptionItem[]) => {
                      const selectedEmails = e.map((option) => option.value);
                      setFieldValue("emails", selectedEmails);
                    }}
                  />
                </Row>
                {templatePreviewLoading ? (
                  <Loading />
                ) : (
                  <TemplateEditor
                    onContentChange={(content) => {
                      setFieldValue("html", content);
                      // check the innerText of the content to see if it's empty
                      const contentIsEmpty = content.replace(/<[^>]*>?/gm, "").length === 0;

                      if (contentIsEmpty) {
                        setSelectedTemplateId("");
                        setFieldValue("subject", "");
                      }

                      debouncedSetComposeEmailData({ lead_id, html: content });
                    }}
                    initialContent={initialContent}
                    titleText="Message"
                    titleFontWeight={500}
                    requiredStar
                    noPaddingOnToolbar
                    deliver
                    emojiPickerTop={-392}
                    emojiPickerRight={24}
                    userHasAlteredTemplate={userHasAlteredTemplate}
                    setUserHasAlteredTemplate={setUserHasAlteredTemplate}
                    noMargin
                  />
                )}
                {selectedTemplateId && userHasAlteredTemplate && (
                  <FlexDiv>
                    <AppErrorText>The template you selected has been modified.</AppErrorText>
                  </FlexDiv>
                )}

                {emailSignature && (
                  <FlexDiv gap={8} align="center" style={{ paddingBottom: "16px" }}>
                    <Switch
                      onChange={(checked: boolean) => setFieldValue("use_signature", checked)}
                      checked={!!values.use_signature}
                      onColor={theme.PRIMARY500}
                      offColor={theme.NEUTRAL200}
                      height={16}
                      width={32}
                      handleDiameter={12}
                      checkedIcon={false}
                      uncheckedIcon={false}
                      activeBoxShadow="0 0 3px #000"
                    />
                    <AppText fontSize={12} fontWeight={500}>
                      Use email signature in this email
                    </AppText>
                  </FlexDiv>
                )}
              </BodyDiv>
              <ButtonContainer>
                <PhoenixAppButton
                  variant="danger-outline"
                  buttonType="secondary"
                  onClick={() => closeEmailEditorModal()}
                >
                  Cancel
                </PhoenixAppButton>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    position: "relative",
                  }}
                  data-for={!emailEditorLeadData?.email ? "email-editor-tooltip" : ""}
                  data-tip={`This lead does not have a primary email.`}
                >
                  <PhoenixAppButton
                    variant="brand"
                    buttonType="secondary"
                    onClick={submitForm}
                    disabled={!isValid || loading || isSubmitting || leadUnsubscribedFromEmail}
                  >
                    Send
                  </PhoenixAppButton>
                </div>
              </ButtonContainer>
            </Main>
          </Modal>
        );
      }}
    </Formik>
  );
};

/** This function handles cases where a space appears before an empty token.
 * For example:
 * "Hello [first_name]," - if [first_name] is blank the result would be "Hello ,"
 * The client wants the result to be "Hello," - without the extra space.
 *
 * This function parses through the dom elements seen in quills editor and handles these cases.
 * It should only run when loading a new template, not every render.
 *  */
export const handleEmptyToken = (initialContent: string) => {
  // create parentNode as the host for incoming HTML string
  const parentNode = document.createElement("div");
  parentNode.innerHTML = initialContent;

  /* Quill breaks its editor HTML content down into rows of <p> tags.
      First we loop over every <p> tag row */
  const elementList = [...parentNode.childNodes];
  for (let curRow of elementList) {
    const subList = curRow?.childNodes;

    /* Every <p> tag row consists on inner <span> tags.
        We need to loop over these span tags to modify the content and remove the spaces */
    for (let i = 0; i < subList.length; i++) {
      const curEle = subList[i];

      if (curEle instanceof HTMLSpanElement && i > 0) {
        const prevEle: any = subList[i - 1];

        // check for empty <span> tag and previous word ending with a space
        if (!curEle.innerText && prevEle?.innerText?.slice(-1) === " ") {
          prevEle.innerText = // set inner text to either an empty string or word with removed space
            prevEle?.innerText?.length === 1 ? "" : prevEle?.innerText?.slice(0, prevEle?.innerText?.length - 1);
        }
      }
    }
  }

  return parentNode;
};

/** This function handles heights of BR tags. By default, the P tags surrounding each line break will be much larger when sent via email.
 *
 * This results in emails that are much more spaced out than expected.
 *
 * This function parses through the dom elements seen in quills editor and handles these cases.
 * It should only run when submitting the 'sendEmail' form. Not every render.
 *  */
export const handleLineHeights = (initialContent: string) => {
  // create parentNode as the host for incoming HTML string
  const parentNode = document.createElement("div");
  parentNode.innerHTML = initialContent;

  /* Quill breaks its editor HTML content down into rows of <p> tags.
      First we loop over every <p> tag row */
  const elementList = [...parentNode.childNodes];
  for (let curRow of elementList) {
    const subList = curRow?.childNodes;

    /* Check if the row only has one child element. Set a default line-height value */
    if (subList.length === 1 && subList[0] instanceof HTMLBRElement === false) {
      const node = curRow as HTMLElement;
      node.style.lineHeight = "18px";
      node.style.margin = "0";
    }

    /* Check if the row only has one child element. Check that its a <br> tag. Set its line height to '0' */
    if (subList.length === 1 && subList[0] instanceof HTMLBRElement) {
      const node = curRow as HTMLElement;
      node.style.lineHeight = "0px";
    }
  }

  return parentNode;
};

export { EditEmailModalV2 };

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;

  padding: 16px 40px;

  border-top: 1px solid ${theme.border.neutral.secondary};
`;

const Row = styled.div`
  margin-bottom: 16px;
`;

const Main = styled.div`
  width: 520px;
  height: fit-content;
  min-height: 400px;
  max-height: 90vh;
  overflow-y: auto;
`;

const BodyDiv = styled.div`
  padding: 16px;
`;

const TitleDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;

  height: 114px;
  padding: 40px 0px 16px 0px;

  border-bottom: 1px solid ${theme.NEUTRAL200};
`;
