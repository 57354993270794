import React from "react";
import styled from "styled-components";
import { AppText, FlexDiv } from "../UI";
import { PhoenixAppButton } from "../UI/Phoenix";
import { theme } from "../../utils/theme";
import { Modal } from "./Modal";

interface UnassignUsersModalProps {
  closeModal?: () => void;
  ids: string[];
  isOpen?: boolean;
  onUnassign?: (ids: string[]) => void;
}

export const UnassignUsersModal: React.FC<UnassignUsersModalProps> = ({ closeModal, ids, isOpen, onUnassign }) => {
  const isMultiple = ids.length > 1;

  return (
    <Modal open={isOpen} onClose={closeModal} popupAnimation>
      <Main>
        <ModalBody>
          <AppText fontSize={16} fontWeight={600}>
            Unassign Rep{isMultiple ? "s" : ""}
          </AppText>

          <FlexDiv direction="column" align="center">
            <AppText fontSize={14} fontWeight={400}>
              Are you sure you want to unassign{isMultiple ? " these reps?" : " this rep?"}
            </AppText>
            <AppText fontSize={14} fontWeight={400}>
              {isMultiple ? "You can reassign them later." : "You can reassign this rep later."}
            </AppText>
          </FlexDiv>
        </ModalBody>

        <ModalButtonContainer>
          <PhoenixAppButton
            buttonType="secondary"
            variant="danger-outline"
            uppercase
            buttonTextFontSize={10}
            style={{ letterSpacing: "1px" }}
            onClick={closeModal}
          >
            No, Cancel
          </PhoenixAppButton>
          <PhoenixAppButton
            buttonType="secondary"
            uppercase
            buttonTextFontSize={10}
            style={{ letterSpacing: "1px" }}
            onClick={() => {
              onUnassign?.(ids);
              closeModal?.();
            }}
          >
            Yes, Unassign
          </PhoenixAppButton>
        </ModalButtonContainer>
      </Main>
    </Modal>
  );
};

const Main = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 476px;
`;

const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;

  padding: 40px 40px 98px 40px;

  text-align: center;
`;

const ModalButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  padding: 16px 40px;

  border-top: 1px solid ${theme.NEUTRAL200};
`;
