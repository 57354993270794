import gql from "graphql-tag";
import { useQuery } from "@apollo/client";

const FETCH_REPS = gql`
  query fetchReps {
    fetchReps {
      id
      full_name
    }
  }
`;

const useFetchReps = () => {
  const { data: repsData, loading, error } = useQuery<{
    fetchReps: { id: string; full_name: string }[];
  }>(FETCH_REPS, {
    fetchPolicy: "network-only",
    onError({ message, name }) {
      console.log(`Error in ${name}: `, message);
    },
  });

  return {
    data: repsData?.fetchReps ?? [],
    loading,
    error,
  };
};

export default useFetchReps;
