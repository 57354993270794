import { theme } from "src/utils/theme";

import { AppText } from "../UI";
import { Modal } from ".";
import { FlexDiv } from "../UI/FlexDiv";
import { PhoenixAppButton, PhoenixInput } from "../UI/Phoenix";
import { useModalContext } from "src/utils/hooks";
import React, { useState } from "react";
import styled from "styled-components";
const DeleteVoicemailDropModal = () => {
  const { showDeleteVoicemailDropModal, setShowDeleteVoicemailDropModal } = useModalContext();

  return (
    <Modal open={showDeleteVoicemailDropModal} onClose={() => setShowDeleteVoicemailDropModal(false)}>
      <ModalMain>
        <Top>
          <AppText fontSize={16} fontWeight={600} color={theme.BLACK_COLOR}>
            Delete Voicemail Recording
          </AppText>
          <AppText fontSize={14} fontWeight={400} color={theme.BLACK_COLOR} textAlign="center">
            Are you sure you want to delete this recording? You cannot undo this action.
          </AppText>
        </Top>

        <SubmitButtonDiv>
          <PhoenixAppButton
            variant="danger-outline"
            buttonType="secondary"
            onClick={() => setShowDeleteVoicemailDropModal(false)}
          >
            No, Cancel
          </PhoenixAppButton>
          <PhoenixAppButton
            variant="brand-outline"
            buttonType="secondary"
            onClick={() => {
              // delete voicemail drop
              console.log("delete voicemail drop");
              setShowDeleteVoicemailDropModal(false);
            }}
          >
            Yes, Continue
          </PhoenixAppButton>
        </SubmitButtonDiv>
      </ModalMain>
    </Modal>
  );
};

const Top = styled(FlexDiv)`
  align-items: center;
  padding: 40px;
  width: 100%;
  flex-direction: column;
  gap: 40px;
  height: 222px;
`;

const SubmitButtonDiv = styled(FlexDiv)`
  border-top: 1px solid ${theme.NEUTRAL200};
  padding: 16px 40px;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;

const ModalMain = styled(FlexDiv)`
  width: 476px;
  height: max-content;
  flex-direction: column;
  align-items: center;
`;

export default DeleteVoicemailDropModal;
