import styled, { keyframes } from "styled-components";
import * as React from "react";
import { useState, useEffect, useContext, useMemo } from "react";
import { AppInput, AppText, Loading, AppErrorText, NewAppSelect, DarkDiv } from "../../UI";
import { NewAppButton } from "../../UI/NewAppButton";
import { theme } from "../../../utils/theme";
import { GridFilterContext } from "../../../context/";
import { useQuery, gql } from "@apollo/client";
import { FETCH_PRODUCTS } from "../CallSegments/MakeSale";
import moment from "moment";
import { iconFilterClose } from "../../../images/";
import { TiArrowSortedUp } from "react-icons/ti";
import { loggedInUser } from "../../../apollo/cache";
import { AppCheckbox } from "../../UI/AppCheckbox";
import { MixpanelActions } from "../../../services/mixpanel";
import {
  PhoenixAppButton,
  PhoenixCheckbox,
  PhoenixIcon,
  PhoenixInput,
  PhoenixMultiSelect,
  PhoenixRadio,
} from "../../UI/Phoenix";
import {
  chevron_down,
  chevron_left,
  chevron_right,
  chevron_up,
  plus,
  refresh,
  trash,
  xIcon,
} from "../../../images/NewDesign";
import { FlexDiv } from "../../UI/FlexDiv";
import { cloneDeep, isEqual } from "lodash";
import { OptionItem } from "../../../types";
import { PHASES_OPTIONS, PIPELINE_OPTIONS } from "../LeadsFilterV2/shared";

const channelOptions = [
  { value: "Inbound", label: "Inbound Leads" },
  { value: "Outbound", label: "Outbound Leads" },
];

const GET_POSSIBLE_INDUSTRIES_DATA = gql`
  query fetchIndustryOptions {
    fetchIndustryOptions {
      id
      label
      sub_industries
    }
  }
`;

const GET_SEQUENCES = gql`
  query fetchSequences($sequencesFilter: SequencesFilter!) {
    fetchSequences(SequencesFilter: $sequencesFilter) {
      id
      name
    }
  }
`;

const GET_POSSIBLE_LEAD_SOURCES_DATA = gql`
  query fetchLeadSourceOptions {
    fetchLeadSourceOptions {
      id
      label
    }
  }
`;

const CHECK_INTEGRATION_STATUS = gql`
  query checkIntegrationStatus {
    checkIntegrationStatus
  }
`;

const FETCH_CUSTOM_FIELDS = gql`
  query fetchCustomFields {
    fetchCustomFields {
      id
      key
      type
      allow_reps_to_edit
      visible
      options
    }
  }
`;

const NEXT_SCHEDULED_EVENT_OPTIONS = [
  { label: "Decision Call", value: "DecisionCall" },
  { label: "Reschedule Demo", value: "RescheduleDemo" },
  { label: "Scheduled Event", value: "ScheduledEvent" },
  { label: "Scheduled Callback", value: "ScheduleCallback" },
  { label: "Demo", value: "Demo" },
  { label: "Follow-Up Demo", value: "FollowUpDemo" },
];
const GridFiltersComponent: React.FC = () => {
  const [expanded, setExpanded] = useState([] as string[]);

  const { data: dataProducts, loading: loadingProducts, error: errorProducts } = useQuery(FETCH_PRODUCTS, {
    onError({ message, name }) {
      // Sentry.captureEvent({
      //   message: `${name} GraphQL Error: ${message}`,
      // });
      console.log(`Error in ${name}: `, message);
    },
  });

  const { data: leadsourceData } = useQuery(GET_POSSIBLE_LEAD_SOURCES_DATA, {
    fetchPolicy: "network-only",
    onError({ message, name }) {
      console.log(`Error in ${name}: `, message);
    },
  });
  const { data: industriesData, loading: industriesLoading } = useQuery(GET_POSSIBLE_INDUSTRIES_DATA, {
    fetchPolicy: "network-only",
    onError({ message, name }) {
      console.log(`Error in ${name}: `, message);
    },
  });

  const { data: sequencesData } = useQuery(GET_SEQUENCES, {
    fetchPolicy: "network-only",
    skip: loggedInUser().role !== "ADMIN",
    variables: {
      sequencesFilter: {},
    },
    onError({ message, name }) {
      console.log(`Error in ${name}: `, message);
    },
  });

  const { data: dataStatus } = useQuery(CHECK_INTEGRATION_STATUS, {
    fetchPolicy: "network-only",
  });

  const { data: allCustomFieldOptions } = useQuery(FETCH_CUSTOM_FIELDS, {
    fetchPolicy: "cache-and-network",
  });

  const {
    dateStart,
    dateEnd,
    dateLabel,
    setDateStart,
    setDateEnd,
    gridFilter,
    setGridFilter,
    setFilterSidebarExpanded,
  } = useContext(GridFilterContext);

  const [selectedFilter, setSelectedFilter] = useState<null | string>(null);

  const [tempGridFilter, setTempGridFilter] = useState(
    JSON.parse(JSON.stringify(gridFilter)) || {
      channels: [],
      industries: [],
      sub_industries: [],
      lead_sources: [],
      lead_creation_sources: [],
      next_scheduled_event: {
        next_scheduled_event_days: undefined,
        next_scheduled_event_types: [],
      },
      sequences: [],
      custom_fields: [],
      lead_activity_channel: [],
    },
  );

  const [selectedCustomFields, setSelectedCustomFields] = useState<SelectedCustomFieldType[]>(
    gridFilter?.custom_fields?.length
      ? gridFilter?.custom_fields
      : [{ id: undefined, key: undefined, value: undefined, type: undefined }],
  );

  const customFieldOptions = useMemo(
    () =>
      allCustomFieldOptions?.fetchCustomFields?.length > 0
        ? allCustomFieldOptions?.fetchCustomFields
            // filter unsupported types
            .filter((item: any) => {
              return ["MultiDropdown", "Dropdown", "Boolean"].includes(item.type);
            })
            ?.map((item: any) => ({
              label: item.key,
              value: item.id,
              type: item.type,
            }))
            ?.filter((item: any) => !selectedCustomFields.some((selectedOption) => selectedOption.id === item.value))
        : [],
    [allCustomFieldOptions, selectedCustomFields],
  );

  // moved to usestate to handle edge case where user leaves tab open overnight (ops-4745)
  const [DATE_RANGE_OPTIONS] = useState([
    { start: moment().startOf("day").format(), end: moment().endOf("day").format(), label: "Today", value: "Today" },
    {
      start: moment().subtract(24, "hours").startOf("day").format(),
      end: moment().subtract(24, "hours").endOf("day").format(),
      label: "Yesterday",
      value: "Yesterday",
    },
    {
      start: moment().startOf("week").format(),
      end: moment().endOf("week").format(),
      label: "This Week",
      value: "ThisWeek",
    },
    {
      start: moment().subtract(1, "week").startOf("week").format(),
      end: moment().subtract(1, "week").endOf("week").format(),
      label: "Last Week",
      value: "LastWeek",
    },
    {
      start: moment().startOf("month").format(),
      end: moment().endOf("day").format(),
      label: "Month to Date",
      value: "MonthToDate",
    },
    {
      start: moment().subtract(1, "months").startOf("month").format(),
      end: moment().subtract(1, "months").endOf("month").format(),
      label: "Last Month",
      value: "LastMonth",
    },
    {
      start: moment().startOf("year").format(),
      end: moment().endOf("day").format(),
      label: "Year to Date",
      value: "YearToDate",
    },
    {
      start: moment().subtract(1, "years").startOf("year").format(),
      end: moment().subtract(1, "years").endOf("year").format(),
      label: "Last Year",
      value: "LastYear",
    },
    {
      start: moment().subtract(1, "months").startOf("day").format(),
      end: moment().endOf("day").format(),
      label: "Custom Range",
      value: "CustomRange",
    },
  ]);

  useEffect(() => {
    console.log("grid filter updating");
    setTempGridFilter(JSON.parse(JSON.stringify(gridFilter)));
  }, [gridFilter]);

  useEffect(() => {
    if (dateLabel !== "CustomRange") {
      setDateStart(DATE_RANGE_OPTIONS.find((item: any) => item.value === dateLabel)?.start || "");
      setDateEnd(DATE_RANGE_OPTIONS.find((item: any) => item.value === dateLabel)?.end || "");
    }
  }, [dateLabel]);

  const calculateIfSelected = (label: string) => {
    const selected = DATE_RANGE_OPTIONS.slice().filter(
      (item: any) =>
        moment(dateStart).format("YYY-MM-DD") === moment(item.start).format("YYY-MM-DD") &&
        moment(dateEnd).format("YYY-MM-DD") === moment(item.end).format("YYY-MM-DD"),
    )[0];

    if (!selected) {
      return label === "Custom Range";
    }

    return selected?.label === label;
  };

  const industryOptionsArray = industriesData?.fetchIndustryOptions?.map((item: any) => item.label);
  const leadSourceOptionsArray = leadsourceData?.fetchLeadSourceOptions?.map((item: any) => item.label);
  const sequenceOptionsArray = sequencesData?.fetchSequences?.map((item: any) => ({
    value: item.id,
    label: item.name,
  }));

  const mapOptionsToKey = (key: string) => {
    switch (key) {
      case "lead_sources":
        return leadSourceOptionsArray;
      case "channels":
      case "lead_activity_channel":
        return ["Inbound", "Outbound"];
      case "industries":
        return industryOptionsArray;
      case "sub_industries":
        return returnSubIndustryOptions(tempGridFilter.industries);
      case "lead_creation_sources":
        return !!dataStatus?.checkIntegrationStatus?.HubSpot
          ? ["self_sourced", "CSV", "as_admin", "hubspot", "salesforce"]
          : ["self_sourced", "CSV", "as_admin", "salesforce"];
      case "next_scheduled_event":
        return NEXT_SCHEDULED_EVENT_OPTIONS;
      case "sequences":
        return sequenceOptionsArray;
      case "phases":
        return PHASES_OPTIONS.map((v) => v.value);
      case "pipeline_stages":
        return PIPELINE_OPTIONS.map((v) => v.value);
      default:
        return [];
    }
  };

  const mapLabelToKey = (key: string) => {
    switch (key) {
      case "lead_sources":
        return "Lead Sources";
      case "channels":
        return "Channel (Source Type)";
      case "industries":
        return "Industry";
      case "sub_industries":
        return "Sub-Industry";
      case "lead_creation_sources":
        return "Lead Creation Source";
      case "next_scheduled_event":
        return "Next Scheduled Event";
      case "sequences":
        return "Sequences";
      case "custom_fields":
        return "Custom Fields";
      case "lead_activity_channel":
        return "Lead Activity Channel";
      case "phases":
        return "Phases";
      case "pipeline_stages":
        return "Pipeline Stages";
      default:
        return "";
    }
  };

  const handleMultiSelectChange = (key: string, items: string[]) => {
    if (key === "industries" && !!tempGridFilter.sub_industries.length) {
      const possibleSubIndustries = returnSubIndustryOptions(items);

      const filteredSubIndustries = tempGridFilter.sub_industries
        ?.map((item: any) => item)
        .filter((selected: string) => {
          return possibleSubIndustries.includes(selected);
        });

      const final = {
        ...tempGridFilter,
        industries: items,
        sub_industries: filteredSubIndustries,
      };

      console.log("possible:", possibleSubIndustries, "filtered: ", filteredSubIndustries, "final:", final);
      setTempGridFilter(final);
    } else {
      setTempGridFilter({
        ...tempGridFilter,
        [key]: items,
      });
    }
  };

  const returnSubIndustryOptions = (industry: string[]) => {
    if (industriesLoading) {
      return [
        {
          label: "Loading...",
          value: "",
        },
      ];
    }
    if (!!industry.length) {
      return industriesData.fetchIndustryOptions
        .slice()
        .filter((item: any) => industry.includes(item.label))
        ?.map((item: any) => item.sub_industries)
        ?.flat()
        ?.sort();
    } else {
      return [];
    }
  };

  const filterOptionsArray = Object.keys(tempGridFilter);

  const formatFilterName = (key: string, name: any) => {
    if (key === "lead_creation_sources") {
      switch (name) {
        case "self_sourced":
          return "Self Sourced";
        case "CSV":
          return "CSV Upload";
        case "as_admin":
          return "Admin Upload";
        case "hubspot":
          return "Hubspot";
        case "salesforce":
          return "Salesforce";
        default:
          return name;
      }
    }

    if (key === "sequences") {
      return name?.label;
    }

    if (key === "custom_fields") {
      return name?.key;
    }

    if (key !== "types") return name;

    const array = (name as string).match(/[A-Z][a-z]+/g);
    return array?.join(" ") ?? name;
  };

  const handleCheckboxClick = (key: string, item: string | number) => {
    if (tempGridFilter[key]?.includes(item)) {
      const filterKeyArray = tempGridFilter[key]?.filter((selected: string) => {
        return selected !== item;
      });
      setTempGridFilter({
        ...tempGridFilter,
        [key]: filterKeyArray,
      });
    } else {
      setTempGridFilter({
        ...tempGridFilter,
        [key]: [...(tempGridFilter[key] ?? []), item],
      });
    }
  };

  const resetIndustries = () => {
    setTempGridFilter({ ...tempGridFilter, industries: [], sub_industries: [] });
  };
  const resetSubindustries = () => {
    setTempGridFilter({ ...tempGridFilter, sub_industries: [] });
  };

  const isDisabled = useMemo(() => {
    const newGridFilter = cloneDeep(tempGridFilter);
    newGridFilter.custom_fields = newGridFilter.custom_fields.filter((ele: SelectedCustomFieldType) => !!ele?.id);
    return (
      JSON.stringify(newGridFilter) === JSON.stringify(gridFilter) ||
      newGridFilter.custom_fields.some((ele: SelectedCustomFieldType) => !ele?.value?.length)
    );
  }, [tempGridFilter]);

  return (
    <>
      <ModalContainer>
        <ModalContent>
          <HeaderDiv>
            <PhoenixIcon
              svg={xIcon}
              size={24}
              pointer
              style={{ position: "absolute", left: "8px", top: "8px" }}
              onClick={() => setFilterSidebarExpanded(false)}
            />

            <AppText fontSize={16} fontWeight={600} lineHeight={22}>
              Add Filters
            </AppText>
          </HeaderDiv>

          {!!selectedFilter && (
            <FlexDiv
              align="center"
              gap={4}
              style={{ padding: "24px 40px", cursor: "pointer", width: "fit-content" }}
              onClick={() => {
                setTempGridFilter({ ...tempGridFilter, [selectedFilter]: gridFilter[selectedFilter] });
                setSelectedFilter(null);
              }}
            >
              <PhoenixIcon
                svg={chevron_left}
                size={16}
                color={theme.PRIMARY500}
                hoverColor={theme.PRIMARY500}
                pointer
              />

              <AppText fontSize={10} fontWeight={600} letterSpacing={1} uppercase color={theme.PRIMARY500}>
                Back
              </AppText>
            </FlexDiv>
          )}

          {!selectedFilter && (
            <div style={{ padding: "0px 32px" }}>
              {filterOptionsArray
                ?.filter((key: string) => key !== "sub_industries")
                ?.filter((key: string) => !(loggedInUser().role !== "ADMIN" && key === "sequences"))
                ?.map((key: string) => {
                  const displayDot =
                    key === "next_scheduled_event"
                      ? !!gridFilter[key]?.next_scheduled_event_types?.length
                      : !!gridFilter[key]?.length;
                  return (
                    <SectionContainerDiv key={key} displayDot={displayDot}>
                      {displayDot && <Dot />}
                      <SectionRow onClick={() => setSelectedFilter(key)}>
                        <SectionTitle>
                          <AppText fontSize={12} fontWeight={500} lineHeight={20}>
                            {mapLabelToKey(key)} &nbsp;
                          </AppText>
                        </SectionTitle>
                        <PhoenixIcon
                          svg={chevron_right}
                          size={24}
                          variant="brand"
                          pointer
                          onClick={() =>
                            expanded?.includes(key)
                              ? setExpanded(expanded.filter((item) => item !== key))
                              : setExpanded([...expanded, key])
                          }
                        />
                      </SectionRow>
                    </SectionContainerDiv>
                  );
                })}
            </div>
          )}

          {selectedFilter === "channels" && (
            <PaddingAndScrollDiv>
              <FilterTitle style={{ marginBottom: "24px" }}>{mapLabelToKey(selectedFilter)}</FilterTitle>
              <FlexDiv gap={24} align="center">
                <FlexDiv align="center" gap={8}>
                  <PhoenixRadio
                    selected={isEqual(tempGridFilter[selectedFilter].sort(), ["Inbound", "Outbound"].sort())}
                    onClick={() => setTempGridFilter({ ...tempGridFilter, channels: ["Inbound", "Outbound"] })}
                  />
                  <AppText fontSize={12} fontWeight={500}>
                    Both
                  </AppText>
                </FlexDiv>
                <FlexDiv align="center" gap={8}>
                  <PhoenixRadio
                    selected={isEqual(tempGridFilter[selectedFilter], ["Inbound"])}
                    onClick={() => setTempGridFilter({ ...tempGridFilter, channels: ["Inbound"] })}
                  />
                  <AppText fontSize={12} fontWeight={500}>
                    Inbound
                  </AppText>
                </FlexDiv>
                <FlexDiv align="center" gap={8}>
                  <PhoenixRadio
                    selected={isEqual(tempGridFilter[selectedFilter], ["Outbound"])}
                    onClick={() => setTempGridFilter({ ...tempGridFilter, channels: ["Outbound"] })}
                  />
                  <AppText fontSize={12} fontWeight={500}>
                    Outbound
                  </AppText>
                </FlexDiv>
              </FlexDiv>
            </PaddingAndScrollDiv>
          )}

          {selectedFilter === "lead_sources" && (
            <PaddingAndScrollDiv>
              <FlexDiv justify="space-between" align="center" style={{ marginBottom: "24px" }}>
                <FilterTitle>{mapLabelToKey(selectedFilter)}</FilterTitle>
                <FlexDiv
                  gap={8}
                  align="center"
                  onClick={() => {
                    setTempGridFilter({ ...tempGridFilter, lead_sources: [] });
                  }}
                  style={{ cursor: "pointer", width: "fit-content" }}
                >
                  <PhoenixIcon svg={refresh} variant="brand" size={12} pointer />
                  <AppText
                    fontSize={10}
                    fontWeight={500}
                    color={theme.PRIMARY500}
                    style={{ lineHeight: "14px", userSelect: "none" }}
                  >
                    Reset Filters
                  </AppText>
                </FlexDiv>
              </FlexDiv>

              {mapOptionsToKey(selectedFilter)?.map((item: string, i: number) => {
                return (
                  <FlexDiv gap={8} align="center" style={{ paddingBottom: "16px" }} key={`lead_sources-${i}`}>
                    <PhoenixCheckbox
                      checked={tempGridFilter[selectedFilter]?.includes(item)}
                      onChange={() => {
                        handleCheckboxClick(selectedFilter, item);
                      }}
                    />
                    <AppText fontSize={12} fontWeight={500}>
                      {formatFilterName(selectedFilter, item)}
                    </AppText>
                  </FlexDiv>
                );
              })}
            </PaddingAndScrollDiv>
          )}

          {selectedFilter === "lead_creation_sources" && (
            <PaddingAndScrollDiv>
              <FlexDiv justify="space-between" align="center" style={{ marginBottom: "24px" }}>
                <FilterTitle>{mapLabelToKey(selectedFilter)}</FilterTitle>
                <FlexDiv
                  gap={8}
                  align="center"
                  onClick={() => {
                    setTempGridFilter({ ...tempGridFilter, lead_creation_sources: [] });
                  }}
                  style={{ cursor: "pointer", width: "fit-content" }}
                >
                  <PhoenixIcon svg={refresh} variant="brand" size={12} pointer />
                  <AppText
                    fontSize={10}
                    fontWeight={500}
                    color={theme.PRIMARY500}
                    style={{ lineHeight: "14px", userSelect: "none" }}
                  >
                    Reset Filters
                  </AppText>
                </FlexDiv>
              </FlexDiv>

              {mapOptionsToKey(selectedFilter)?.map((item: string, i: number) => {
                return (
                  <FlexDiv gap={8} align="center" style={{ paddingBottom: "16px" }} key={`lead_creation_sources-${i}`}>
                    <PhoenixCheckbox
                      checked={tempGridFilter[selectedFilter]?.includes(item)}
                      onChange={() => {
                        handleCheckboxClick(selectedFilter, item);
                      }}
                    />
                    <AppText fontSize={12} fontWeight={500}>
                      {formatFilterName(selectedFilter, item)}
                    </AppText>
                  </FlexDiv>
                );
              })}
            </PaddingAndScrollDiv>
          )}

          {selectedFilter === "lead_activity_channel" && (
            <PaddingAndScrollDiv>
              <FilterTitle style={{ marginBottom: "24px" }}>{mapLabelToKey(selectedFilter)}</FilterTitle>
              <FlexDiv gap={24} align="center">
                <FlexDiv align="center" gap={8}>
                  <PhoenixRadio
                    selected={isEqual(tempGridFilter[selectedFilter].sort(), ["Inbound", "Outbound"].sort())}
                    onClick={() =>
                      setTempGridFilter({ ...tempGridFilter, lead_activity_channel: ["Inbound", "Outbound"] })
                    }
                  />
                  <AppText fontSize={12} fontWeight={500}>
                    Both
                  </AppText>
                </FlexDiv>
                <FlexDiv align="center" gap={8}>
                  <PhoenixRadio
                    selected={isEqual(tempGridFilter[selectedFilter], ["Inbound"])}
                    onClick={() => setTempGridFilter({ ...tempGridFilter, lead_activity_channel: ["Inbound"] })}
                  />
                  <AppText fontSize={12} fontWeight={500}>
                    Inbound
                  </AppText>
                </FlexDiv>
                <FlexDiv align="center" gap={8}>
                  <PhoenixRadio
                    selected={isEqual(tempGridFilter[selectedFilter], ["Outbound"])}
                    onClick={() => setTempGridFilter({ ...tempGridFilter, lead_activity_channel: ["Outbound"] })}
                  />
                  <AppText fontSize={12} fontWeight={500}>
                    Outbound
                  </AppText>
                </FlexDiv>
              </FlexDiv>
            </PaddingAndScrollDiv>
          )}

          {selectedFilter === "industries" && (
            <PaddingAndScrollDiv>
              <FlexDiv justify="space-between" align="center" style={{ marginBottom: "24px" }}>
                <FilterTitle>{mapLabelToKey(selectedFilter)}</FilterTitle>
                <FlexDiv
                  gap={8}
                  align="center"
                  onClick={() => {
                    setTempGridFilter({ ...tempGridFilter, industries: [], sub_industries: [] });
                  }}
                  style={{ cursor: "pointer", width: "fit-content" }}
                >
                  <PhoenixIcon svg={refresh} variant="brand" size={12} pointer />
                  <AppText
                    fontSize={10}
                    fontWeight={500}
                    color={theme.PRIMARY500}
                    style={{ lineHeight: "14px", userSelect: "none" }}
                  >
                    Reset Filters
                  </AppText>
                </FlexDiv>
              </FlexDiv>

              <FlexDiv gap={8} direction="column" style={{ paddingBottom: "16px" }}>
                <AppText fontSize={12} fontWeight={500}>
                  Primary Industry
                </AppText>
                <PhoenixMultiSelect
                  maxHeight={200}
                  name={selectedFilter}
                  options={mapOptionsToKey(selectedFilter)?.map((item: any) => ({ label: item, value: item }))}
                  value={tempGridFilter.industries?.map((item: any) => ({ label: item, value: item }))}
                  onChange={(e: any) => {
                    !!e.length
                      ? handleMultiSelectChange(
                          "industries",
                          e?.map((item: any) => item.value),
                        )
                      : resetIndustries();
                  }}
                  isClearable={false}
                  showCheckbox
                  menuPortal
                  width={376}
                  closeMenuOnSelect={false}
                  hideSelectedOptions={false}
                  marginBottom={false}
                />
              </FlexDiv>
              <FlexDiv gap={8} direction="column" style={{ paddingBottom: "16px" }}>
                <AppText fontSize={12} fontWeight={500}>
                  Sub-Industry
                </AppText>
                <PhoenixMultiSelect
                  maxHeight={200}
                  name="sub_industries"
                  options={returnSubIndustryOptions(tempGridFilter.industries)?.map((item: any) => ({
                    label: item,
                    value: item,
                  }))}
                  value={tempGridFilter.sub_industries
                    ?.map((item: any) => ({ label: item, value: item }))
                    ?.sort((a: any, b: any) => (a?.label < b?.label ? -1 : 1))}
                  onChange={(e: any) => {
                    !!e.length
                      ? handleMultiSelectChange(
                          "sub_industries",
                          e?.map((item: any) => item.value),
                        )
                      : resetSubindustries();
                  }}
                  isClearable={false}
                  menuPortal
                  width={376}
                  showCheckbox
                  closeMenuOnSelect={false}
                  hideSelectedOptions={false}
                  marginBottom={false}
                />
              </FlexDiv>
            </PaddingAndScrollDiv>
          )}

          {selectedFilter === "next_scheduled_event" && (
            <PaddingAndScrollDiv>
              <FlexDiv justify="space-between" align="center" style={{ marginBottom: "24px" }}>
                <FilterTitle>{mapLabelToKey(selectedFilter)}</FilterTitle>
                <FlexDiv
                  gap={8}
                  align="center"
                  onClick={() => {
                    setTempGridFilter({
                      ...tempGridFilter,
                      next_scheduled_event: {
                        next_scheduled_event_types: [],
                        next_scheduled_event_days: undefined,
                      },
                    });
                  }}
                  style={{ cursor: "pointer", width: "fit-content" }}
                >
                  <PhoenixIcon svg={refresh} variant="brand" size={12} pointer />
                  <AppText
                    fontSize={10}
                    fontWeight={500}
                    color={theme.PRIMARY500}
                    style={{ lineHeight: "14px", userSelect: "none" }}
                  >
                    Reset Filters
                  </AppText>
                </FlexDiv>
              </FlexDiv>

              <PhoenixMultiSelect
                name="next_scheduled_event_types"
                placeholder={"Select Event Type"}
                options={mapOptionsToKey(selectedFilter)}
                value={tempGridFilter?.next_scheduled_event?.next_scheduled_event_types?.map((item: string) =>
                  NEXT_SCHEDULED_EVENT_OPTIONS?.find((option: OptionItem) => option.value === item),
                )}
                onChange={(e: any) => {
                  setTempGridFilter({
                    ...tempGridFilter,
                    next_scheduled_event: {
                      next_scheduled_event_types: !!e.length ? e?.map((item: any) => item.value) : [],
                      next_scheduled_event_days: tempGridFilter?.next_scheduled_event?.next_scheduled_event_days,
                    },
                  });
                }}
                marginBottom={false}
                menuPortal
                style={{ marginBottom: "16px" }}
              />

              <FlexDiv align="center" gap={8} style={{ paddingBottom: "2px" }}>
                <AppText fontSize={12} fontWeight={500}>
                  Occurs Within
                </AppText>
                <PhoenixInput
                  showNumberArrows
                  type="number"
                  inputValueType="number"
                  handleNumberArrowUpClick={() =>
                    setTempGridFilter({
                      ...tempGridFilter,
                      next_scheduled_event: {
                        ...tempGridFilter?.next_scheduled_event,
                        next_scheduled_event_days:
                          tempGridFilter?.next_scheduled_event?.next_scheduled_event_days + 1 || 0,
                      },
                    })
                  }
                  handleNumberArrowDownClick={() =>
                    setTempGridFilter({
                      ...tempGridFilter,
                      next_scheduled_event: {
                        ...tempGridFilter?.next_scheduled_event,
                        next_scheduled_event_days:
                          Math.max(tempGridFilter?.next_scheduled_event?.next_scheduled_event_days - 1, 0) || 0,
                      },
                    })
                  }
                  value={tempGridFilter?.next_scheduled_event?.next_scheduled_event_days}
                  onChange={(e: any) => {
                    setTempGridFilter({
                      ...tempGridFilter,
                      next_scheduled_event: {
                        ...tempGridFilter?.next_scheduled_event,
                        next_scheduled_event_days: !!e?.target?.value ? e.target.value : undefined,
                      },
                    });
                  }}
                  width={72}
                  displayNoContextText
                />
                <AppText fontSize={12} fontWeight={500} style={{ paddingLeft: "8px" }}>
                  Days
                </AppText>
              </FlexDiv>
            </PaddingAndScrollDiv>
          )}

          {selectedFilter === "sequences" && (
            <PaddingAndScrollDiv>
              <FlexDiv justify="space-between" align="center" style={{ marginBottom: "24px" }}>
                <FilterTitle>{mapLabelToKey(selectedFilter)}</FilterTitle>
                <FlexDiv
                  gap={8}
                  align="center"
                  onClick={() => {
                    setTempGridFilter({
                      ...tempGridFilter,
                      sequences: [],
                    });
                  }}
                  style={{ cursor: "pointer", width: "fit-content" }}
                >
                  <PhoenixIcon svg={refresh} variant="brand" size={12} pointer />
                  <AppText
                    fontSize={10}
                    fontWeight={500}
                    color={theme.PRIMARY500}
                    style={{ lineHeight: "14px", userSelect: "none" }}
                  >
                    Reset Filters
                  </AppText>
                </FlexDiv>
              </FlexDiv>

              <PhoenixMultiSelect
                maxHeight={200}
                name="sequences"
                options={mapOptionsToKey(selectedFilter)}
                value={tempGridFilter.sequences}
                onChange={(e: any) => handleMultiSelectChange("sequences", e)}
                isClearable={false}
                showCheckbox
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                menuPortal
              />
            </PaddingAndScrollDiv>
          )}

          {selectedFilter === "custom_fields" && (
            <PaddingAndScrollDiv>
              <FlexDiv justify="space-between" align="center" style={{ marginBottom: "24px" }}>
                <FilterTitle>{mapLabelToKey(selectedFilter)}</FilterTitle>
                <FlexDiv
                  gap={8}
                  align="center"
                  onClick={() => {
                    setTempGridFilter({
                      ...tempGridFilter,
                      custom_fields: [],
                    });
                    setSelectedCustomFields([{ id: undefined, key: undefined, value: [], type: undefined }]);
                  }}
                  style={{ cursor: "pointer", width: "fit-content" }}
                >
                  <PhoenixIcon svg={refresh} variant="brand" size={12} pointer />
                  <AppText
                    fontSize={10}
                    fontWeight={500}
                    color={theme.PRIMARY500}
                    style={{ lineHeight: "14px", userSelect: "none" }}
                  >
                    Reset Filters
                  </AppText>
                </FlexDiv>
              </FlexDiv>

              <FlexDiv gap={24} direction="column">
                {selectedCustomFields?.map((_: SelectedCustomFieldType, i: number) => (
                  <CustomFieldFilter
                    index={i}
                    allCustomFieldOptions={allCustomFieldOptions}
                    customFieldOptions={customFieldOptions}
                    selectedCustomFields={selectedCustomFields}
                    setSelectedCustomFields={setSelectedCustomFields}
                    tempGridFilter={tempGridFilter}
                    setTempGridFilter={setTempGridFilter}
                    key={`custom_field-${i}`}
                  />
                ))}

                <FlexDiv
                  gap={8}
                  style={{ cursor: "pointer", paddingBottom: "16px" }}
                  onClick={() =>
                    setSelectedCustomFields([
                      ...selectedCustomFields,
                      {
                        id: undefined,
                        key: undefined,
                        value: undefined,
                        type: undefined,
                      },
                    ])
                  }
                >
                  <PhoenixIcon svg={plus} size={16} variant="brand" hoverColor={theme.PRIMARY500} pointer />
                  <AppText fontSize={12} fontWeight={500} lineHeight={18} color={theme.PRIMARY500}>
                    Add Custom Field
                  </AppText>
                </FlexDiv>
              </FlexDiv>
            </PaddingAndScrollDiv>
          )}
        </ModalContent>

        <ModalBottom>
          <PhoenixAppButton
            buttonType="secondary"
            variant="danger-outline"
            width={95}
            onClick={() => setFilterSidebarExpanded(false)}
          >
            <AppText fontSize={10} fontWeight={600} lineHeight={16} letterSpacing={1} color={theme.DANGER600} uppercase>
              Cancel
            </AppText>
          </PhoenixAppButton>

          <PhoenixAppButton
            buttonType="secondary"
            variant="brand"
            width={138}
            onClick={() => {
              MixpanelActions.track("Filter Event", { Filters: tempGridFilter, Type: `Save` }); //Filters applied
              const newGridFilter = cloneDeep(tempGridFilter);
              newGridFilter.custom_fields = newGridFilter.custom_fields.filter(
                (ele: SelectedCustomFieldType) => !!ele?.id,
              );
              setGridFilter(newGridFilter);
            }}
            disabled={isDisabled}
          >
            <AppText
              fontSize={10}
              fontWeight={600}
              lineHeight={16}
              letterSpacing={1}
              color={theme.WHITE_COLOR}
              uppercase
            >
              Apply Filters
            </AppText>
          </PhoenixAppButton>
        </ModalBottom>
      </ModalContainer>

      <DarkDiv />
    </>
  );
};

type SelectedCustomFieldType = {
  id?: string;
  key?: string;
  value?: any[];
  type?: string;
};

interface CustomFieldFilterProps {
  index: number;
  allCustomFieldOptions: any;
  customFieldOptions: { label: string; value: any; type: string }[];
  selectedCustomFields: SelectedCustomFieldType[];
  setSelectedCustomFields: React.Dispatch<React.SetStateAction<SelectedCustomFieldType[]>>;
  tempGridFilter: any;
  setTempGridFilter: React.Dispatch<React.SetStateAction<any>>;
}

const CustomFieldFilter = ({
  index,
  allCustomFieldOptions,
  customFieldOptions,
  selectedCustomFields,
  setSelectedCustomFields,
  tempGridFilter,
  setTempGridFilter,
}: CustomFieldFilterProps) => {
  // const isMulti = useMemo(() => selectedCustomFields?.[index]?.type === "MultiDropdown", [selectedCustomFields, index]);

  return (
    <CustomFieldContainer gap={16} direction="column">
      <FlexDiv justify="space-between" align="center">
        <AppText fontSize={10} fontWeight={600} lineHeight={16} color={theme.NEUTRAL350} letterSpacing={1} uppercase>
          Custom Field {index + 1}
        </AppText>
        {index !== 0 && (
          <PhoenixIcon
            svg={trash}
            size={16}
            variant="danger"
            pointer
            onClick={() => {
              const arr = [...selectedCustomFields];
              arr.splice(index, 1);
              setSelectedCustomFields(arr);

              const newTempGridFilters = cloneDeep(tempGridFilter);
              newTempGridFilters.custom_fields = arr;
              setTempGridFilter(newTempGridFilters);
            }}
          />
        )}
      </FlexDiv>

      <FlexDiv gap={8} direction="column">
        <AppText fontSize={12} lineHeight={18}>
          Choose Custom Field <span style={{ color: theme.DANGER600 }}>*</span>
        </AppText>

        <PhoenixMultiSelect
          maxHeight={200}
          name={`custom_fields_option${index}`}
          options={customFieldOptions}
          value={
            selectedCustomFields?.[index]?.id
              ? {
                  label: selectedCustomFields?.[index]?.key,
                  value: selectedCustomFields?.[index]?.id,
                }
              : null
          }
          onChange={(e: { label: string; value: any; type: string }) => {
            const newVal = cloneDeep(selectedCustomFields);
            newVal[index] = { id: e.value, key: e.label, value: [], type: e.type };
            setSelectedCustomFields(newVal);
          }}
          isMulti={false}
          isClearable={false}
          hideSelectedOptions={false}
          marginBottom={false}
          maxMenuHeight={140}
        />
      </FlexDiv>

      <PhoenixMultiSelect
        maxHeight={200}
        name={`custom_fields_value${index}`}
        options={
          selectedCustomFields?.[index]
            ? selectedCustomFields?.[index]?.type === "Boolean"
              ? [
                  {
                    label: "true",
                    value: "true",
                  },
                  {
                    label: "false",
                    value: "false",
                  },
                ]
              : allCustomFieldOptions?.fetchCustomFields
                  ?.find((ele: any) => ele.id === selectedCustomFields?.[index]?.id)
                  ?.options?.map((value: string) => ({
                    label: value,
                    value: value,
                  }))
            : []
        }
        value={selectedCustomFields?.[index]?.value?.map((value: any) => ({ label: value, value: value }))}
        onChange={(e: any) => {
          const newVal = cloneDeep(selectedCustomFields);
          newVal[index].value = e?.map((ele: { label: string; value: any }) => ele?.value);
          setSelectedCustomFields(newVal);

          const newTempGridFilter = cloneDeep(tempGridFilter);
          newTempGridFilter.custom_fields = newVal;
          setTempGridFilter(newTempGridFilter);
        }}
        isMulti={true}
        showCheckbox={true}
        isClearable={false}
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        marginBottom={false}
      />
    </CustomFieldContainer>
  );
};

const slideInAnimation = keyframes`
  from {
    transform: translateX(100vw);
  }

  to {
    transform: translateX(calc(100vw - 528px));
  }
`;

const ModalContainer = styled.div`
  position: fixed;
  z-index: 899;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  width: 456px;
  height: 100vh;

  background-color: ${theme.WHITE_COLOR};

  animation: ${slideInAnimation} 0.4s ease forwards;
`;

const CustomFieldContainer = styled(FlexDiv)`
  border: 1px solid ${theme.NEUTRAL300};
  border-radius: 8px;
  padding: 16px;
`;

const HeaderDiv = styled.div`
  padding: 32px 32px 8px 40px;
  border-bottom: 1px solid ${theme.NEUTRAL200};
`;

const ModalContent = styled.div`
  position: relative;
  z-index: 20;

  max-height: 92vh;
  min-height: 92vh;

  padding-bottom: 100px;
`;

const ModalBottom = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 16px 40px;
`;

const SectionTitle = styled.div`
  display: flex;
  margin: 0px;
  height: 56px;
  align-items: center;
`;

interface SectionContainerDivProps {
  displayDot: boolean;
}

const SectionContainerDiv = styled.div<SectionContainerDivProps>`
  display: flex;
  align-items: center;
  padding-left: ${(props) => (props.displayDot ? "0px" : "16px")};
  padding-right: 24px;
  border-bottom: 1px solid ${theme.NEUTRAL200};
  cursor: pointer;
  /* max-height: 400px; */
`;

const Dot = styled.div`
  width: 8px;
  height: 8px;
  margin-right: 8px;
  border-radius: 50%;
  background-color: ${theme.DANGER600};
`;

const SectionRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const slideUpFadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(2px);
  }

  to {
    opacity: 1;
    transform: translateY(0px);
  }
`;

const PaddingAndScrollDiv = styled.div`
  overflow-x: hidden;
  overflow-y: auto;

  max-height: 78vh;
  min-height: 78vh;
  padding: 0px 40px;

  & > * {
    animation: ${slideUpFadeIn} 0.3s ease forwards;
  }
`;

const FilterTitle = styled(AppText)`
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
`;

interface SelectOptionProps {
  selected: boolean;
}

const OptionButton = styled.button<SelectOptionProps>`
  min-width: 78px;
  max-width: 78px;
  height: 24px;
  margin-right: 8px;
  margin-bottom: 11px;
  border-radius: 3px;
  font-size: 9px;
  font-weight: ${(props) => (props.selected ? 600 : 500)};
  border: solid 1px ${(props) => (props.selected ? theme.NEUTRAL100 : theme.NEUTRAL200)};
  background-color: ${(props) => (props.selected ? theme.PRIMARY500 : theme.WHITE_COLOR)};
  color: ${(props) => (props.selected ? theme.WHITE_COLOR : theme.BLACK_COLOR)};
  cursor: pointer;
  line-height: 1.1;
  :focus {
    outline: none;
  }
`;

export { GridFiltersComponent };
