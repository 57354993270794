import * as React from "react";
import styled from "styled-components";
import { AppText, SkeletonBlock } from "../../UI";
import { theme } from "../../../utils/theme";
import { PhoenixInput } from "../../UI/Phoenix";
import LeadRankingTable from "./lead-ranking/RankingTable";
import { gql, useQuery, useMutation } from "@apollo/client";
import { useState } from "react";
import { useDebounce, useFirstRender } from "../../../utils/hooks";
import { appToast, errorToast } from "../../../utils/toast";

interface StepLeadRankingProps {
  // not currently used but would be needed if we were to use the onboarding flow
  step: number;
  blinds: boolean;
  setBlinds: (blinds: boolean) => void;
  isOnboarding?: boolean;
}

const FETCH_EVENT_TIME = gql`
  query fetchOrganization {
    fetchOrganization {
      demo_prep_time_minutes
    }
  }
`;

const UPDATE_EVENT_TIME = gql`
  mutation UpdateOrgDemoPrepTime($demoPrepTimeMinutes: Int!) {
    updateOrgDemoPrepTime(demo_prep_time_minutes: $demoPrepTimeMinutes) {
      demo_prep_time_minutes
    }
  }
`;

const StepLeadRanking = ({ step, blinds, setBlinds, isOnboarding }: StepLeadRankingProps) => {
  const isFirstRender = useFirstRender();

  const [eventPrepTime, setEventPrepTime] = useState<number | null>(null);

  // debounce update looking at state value. Validations on debounce
  useDebounce(
    () => {
      if (isFirstRender) return;

      if (!eventPrepTime) return;

      if (eventPrepTime % 5 !== 0) {
        errorToast("Event alert time must be in increments of five.");
        return;
      }

      if (eventPrepTime < 5) {
        errorToast("Event alert time must be above five minutes.");
        return;
      }

      if (eventPrepTime === demoPrepTimeData?.fetchOrganization?.demo_prep_time_minutes) return;

      // update server
      updateOrgDemoPrepTime({ variables: { demoPrepTimeMinutes: eventPrepTime } });
    },
    [eventPrepTime],
    1000,
  );

  // mutations and fetches

  const { data: demoPrepTimeData, loading: demoPrepTimeLoading, error: demoPrepTimeError } = useQuery(
    FETCH_EVENT_TIME,
    {
      fetchPolicy: "cache-and-network",
      onCompleted: (data) => {
        setEventPrepTime(data?.fetchOrganization?.demo_prep_time_minutes);
      },
    },
  );

  const [updateOrgDemoPrepTime] = useMutation(UPDATE_EVENT_TIME, {
    onCompleted: (data) => {
      setEventPrepTime(data?.updateOrgDemoPrepTime?.demo_prep_time_minutes);
      appToast("Event alert time updated");
    },
    onError: (error) => {
      appToast("Error updating event alert time");
    },
  });

  return (
    <PageContainer>
      <Page>
        <Header>
          <StepLocationText>Lead Ranking</StepLocationText>
        </Header>
        <Main>
          <AppText fontSize={12} fontWeight={400}>
            Configure the order that lead tasks are presented to reps in their Dialer &gt; Suggested Action and Active
            Queue sections.
          </AppText>

          <LeadRankingTable queue="Active" />

          <EventAlertDiv>
            <AppText fontSize={10} fontWeight={600} color={theme.PRIMARY500} uppercase>
              Event Alert Timing
            </AppText>
            <AppText fontSize={12} fontWeight={400}>
              Specify how soon before an event starts that the next action will present the event.
            </AppText>
            <AppText fontSize={12} fontWeight={400} color={theme.NEUTRAL300}>
              Minimum time delay is 5 minutes and must be set in increments of 5 minutes.
            </AppText>
            {demoPrepTimeLoading ? (
              <SkeletonBlock width={100} height={40} borderRadius={6} />
            ) : (
              <EventInputTimeDiv>
                <PhoenixInput
                  name="eventAlertTiming"
                  type="number"
                  width={104}
                  handleNumberArrowDownClick={() => {
                    if (eventPrepTime && eventPrepTime >= 10) {
                      setEventPrepTime(eventPrepTime - 5);
                    }
                  }}
                  handleNumberArrowUpClick={() => {
                    if (eventPrepTime) {
                      setEventPrepTime(eventPrepTime + 5);
                    }
                  }}
                  showNumberArrows
                  placeholder="5"
                  value={eventPrepTime || 5}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    if (!e.target.value) return;
                    const value = parseInt(e.target.value);
                    setEventPrepTime(value);
                  }}
                  height={40}
                />

                <AppText fontSize={12} fontWeight={400}>
                  Minutes
                </AppText>
              </EventInputTimeDiv>
            )}
          </EventAlertDiv>
        </Main>
      </Page>
    </PageContainer>
  );
};

const EventInputTimeDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 16px;
`;

const EventAlertDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

// Refactored styled components
const PageContainer = styled.div`
  width: 100%;
  height: 100vh;
  padding: 24px;
`;

const Page = styled.div`
  min-height: inherit;
  width: 100%;
  min-width: 1200px;
  box-sizing: border-box;
  background-color: ${theme.fill.neutral.primary};
  border: 1px solid ${theme.NEUTRAL100};
  border-radius: 16px;
  overflow-y: auto;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 24px 40px;
  border-bottom: 1px solid ${theme.NEUTRAL200};
`;

const StepLocationText = styled(AppText)`
  color: ${theme.text.neutral.primary};
  font-size: 22px;
  font-weight: 500;
  line-height: 28px;
`;

const Main = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  width: 100%;
  padding: 40px;
`;

export { StepLeadRanking };
