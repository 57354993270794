export const countryCodesAll = [
  { iso2: "BD", iso3: "BGD", name: "Bangladesh", code: "880" },
  { iso2: "BE", iso3: "BEL", name: "Belgium", code: "32" },
  { iso2: "BF", iso3: "BFA", name: "Burkina Faso", code: "226" },
  { iso2: "BG", iso3: "BGR", name: "Bulgaria", code: "359" },
  { iso2: "BA", iso3: "BIH", name: "Bosnia and Herzegovina", code: "387" },
  { iso2: "BB", iso3: "BRB", name: "Barbados", code: "1246" },
  { iso2: "WF", iso3: "WLF", name: "Wallis and Futuna", code: "681" },
  { iso2: "BL", iso3: "BLM", name: "Saint Barthelemy", code: "590" },
  { iso2: "BM", iso3: "BMU", name: "Bermuda", code: "1441" },
  { iso2: "BN", iso3: "BRN", name: "Brunei", code: "673" },
  { iso2: "BO", iso3: "BOL", name: "Bolivia", code: "591" },
  { iso2: "BH", iso3: "BHR", name: "Bahrain", code: "973" },
  { iso2: "BI", iso3: "BDI", name: "Burundi", code: "257" },
  { iso2: "BJ", iso3: "BEN", name: "Benin", code: "229" },
  { iso2: "BT", iso3: "BTN", name: "Bhutan", code: "975" },
  { iso2: "JM", iso3: "JAM", name: "Jamaica", code: "1876" },
  { iso2: "BW", iso3: "BWA", name: "Botswana", code: "267" },
  { iso2: "WS", iso3: "WSM", name: "Samoa", code: "685" },
  { iso2: "BQ", iso3: "BES", name: "Bonaire, Saint Eustatius and Saba ", code: "599" },
  { iso2: "BR", iso3: "BRA", name: "Brazil", code: "55" },
  { iso2: "BS", iso3: "BHS", name: "Bahamas", code: "1242" },
  { iso2: "JE", iso3: "JEY", name: "Jersey", code: "441534" },
  { iso2: "BY", iso3: "BLR", name: "Belarus", code: "375" },
  { iso2: "BZ", iso3: "BLZ", name: "Belize", code: "501" },
  { iso2: "RU", iso3: "RUS", name: "Russia", code: "7" },
  { iso2: "RW", iso3: "RWA", name: "Rwanda", code: "250" },
  { iso2: "RS", iso3: "SRB", name: "Serbia", code: "381" },
  { iso2: "TL", iso3: "TLS", name: "East Timor", code: "670" },
  { iso2: "RE", iso3: "REU", name: "Reunion", code: "262" },
  { iso2: "TM", iso3: "TKM", name: "Turkmenistan", code: "993" },
  { iso2: "TJ", iso3: "TJK", name: "Tajikistan", code: "992" },
  { iso2: "RO", iso3: "ROU", name: "Romania", code: "40" },
  { iso2: "TK", iso3: "TKL", name: "Tokelau", code: "690" },
  { iso2: "GW", iso3: "GNB", name: "Guinea-Bissau", code: "245" },
  { iso2: "GU", iso3: "GUM", name: "Guam", code: "1671" },
  { iso2: "GT", iso3: "GTM", name: "Guatemala", code: "502" },
  { iso2: "GR", iso3: "GRC", name: "Greece", code: "30" },
  { iso2: "GQ", iso3: "GNQ", name: "Equatorial Guinea", code: "240" },
  { iso2: "GP", iso3: "GLP", name: "Guadeloupe", code: "590" },
  { iso2: "JP", iso3: "JPN", name: "Japan", code: "81" },
  { iso2: "GY", iso3: "GUY", name: "Guyana", code: "592" },
  { iso2: "GG", iso3: "GGY", name: "Guernsey", code: "441481" },
  { iso2: "GF", iso3: "GUF", name: "French Guiana", code: "594" },
  { iso2: "GE", iso3: "GEO", name: "Georgia", code: "995" },
  { iso2: "GD", iso3: "GRD", name: "Grenada", code: "1473" },
  { iso2: "GB", iso3: "GBR", name: "United Kingdom", code: "44" },
  { iso2: "GA", iso3: "GAB", name: "Gabon", code: "241" },
  { iso2: "SV", iso3: "SLV", name: "El Salvador", code: "503" },
  { iso2: "GN", iso3: "GIN", name: "Guinea", code: "224" },
  { iso2: "GM", iso3: "GMB", name: "Gambia", code: "220" },
  { iso2: "GL", iso3: "GRL", name: "Greenland", code: "299" },
  { iso2: "GI", iso3: "GIB", name: "Gibraltar", code: "350" },
  { iso2: "GH", iso3: "GHA", name: "Ghana", code: "233" },
  { iso2: "OM", iso3: "OMN", name: "Oman", code: "968" },
  { iso2: "TN", iso3: "TUN", name: "Tunisia", code: "216" },
  { iso2: "JO", iso3: "JOR", name: "Jordan", code: "962" },
  { iso2: "HR", iso3: "HRV", name: "Croatia", code: "385" },
  { iso2: "HT", iso3: "HTI", name: "Haiti", code: "509" },
  { iso2: "HU", iso3: "HUN", name: "Hungary", code: "36" },
  { iso2: "HK", iso3: "HKG", name: "Hong Kong", code: "852" },
  { iso2: "HN", iso3: "HND", name: "Honduras", code: "504" },
  { iso2: "VE", iso3: "VEN", name: "Venezuela", code: "58" },
  { iso2: "PR", iso3: "PRI", name: "Puerto Rico", code: "1939" },
  { iso2: "PR", iso3: "PRI", name: "Puerto Rico", code: "1787" },
  { iso2: "PS", iso3: "PSE", name: "Palestinian Territory", code: "970" },
  { iso2: "PW", iso3: "PLW", name: "Palau", code: "680" },
  { iso2: "PT", iso3: "PRT", name: "Portugal", code: "351" },
  { iso2: "SJ", iso3: "SJM", name: "Svalbard and Jan Mayen", code: "47" },
  { iso2: "PY", iso3: "PRY", name: "Paraguay", code: "595" },
  { iso2: "IQ", iso3: "IRQ", name: "Iraq", code: "964" },
  { iso2: "PA", iso3: "PAN", name: "Panama", code: "507" },
  { iso2: "PF", iso3: "PYF", name: "French Polynesia", code: "689" },
  { iso2: "PG", iso3: "PNG", name: "Papua New Guinea", code: "675" },
  { iso2: "PE", iso3: "PER", name: "Peru", code: "51" },
  { iso2: "PK", iso3: "PAK", name: "Pakistan", code: "92" },
  { iso2: "PH", iso3: "PHL", name: "Philippines", code: "63" },
  { iso2: "PN", iso3: "PCN", name: "Pitcairn", code: "870" },
  { iso2: "PL", iso3: "POL", name: "Poland", code: "48" },
  { iso2: "PM", iso3: "SPM", name: "Saint Pierre and Miquelon", code: "508" },
  { iso2: "ZM", iso3: "ZMB", name: "Zambia", code: "260" },
  { iso2: "EH", iso3: "ESH", name: "Western Sahara", code: "212" },
  { iso2: "EE", iso3: "EST", name: "Estonia", code: "372" },
  { iso2: "EG", iso3: "EGY", name: "Egypt", code: "20" },
  { iso2: "ZA", iso3: "ZAF", name: "South Africa", code: "27" },
  { iso2: "EC", iso3: "ECU", name: "Ecuador", code: "593" },
  { iso2: "IT", iso3: "ITA", name: "Italy", code: "39" },
  { iso2: "VN", iso3: "VNM", name: "Vietnam", code: "84" },
  { iso2: "SB", iso3: "SLB", name: "Solomon Islands", code: "677" },
  { iso2: "ET", iso3: "ETH", name: "Ethiopia", code: "251" },
  { iso2: "SO", iso3: "SOM", name: "Somalia", code: "252" },
  { iso2: "ZW", iso3: "ZWE", name: "Zimbabwe", code: "263" },
  { iso2: "SA", iso3: "SAU", name: "Saudi Arabia", code: "966" },
  { iso2: "ES", iso3: "ESP", name: "Spain", code: "34" },
  { iso2: "ER", iso3: "ERI", name: "Eritrea", code: "291" },
  { iso2: "ME", iso3: "MNE", name: "Montenegro", code: "382" },
  { iso2: "MD", iso3: "MDA", name: "Moldova", code: "373" },
  { iso2: "MG", iso3: "MDG", name: "Madagascar", code: "261" },
  { iso2: "MF", iso3: "MAF", name: "Saint Martin", code: "590" },
  { iso2: "MA", iso3: "MAR", name: "Morocco", code: "212" },
  { iso2: "MC", iso3: "MCO", name: "Monaco", code: "377" },
  { iso2: "UZ", iso3: "UZB", name: "Uzbekistan", code: "998" },
  { iso2: "MM", iso3: "MMR", name: "Myanmar", code: "95" },
  { iso2: "ML", iso3: "MLI", name: "Mali", code: "223" },
  { iso2: "MO", iso3: "MAC", name: "Macao", code: "853" },
  { iso2: "MN", iso3: "MNG", name: "Mongolia", code: "976" },
  { iso2: "MH", iso3: "MHL", name: "Marshall Islands", code: "692" },
  { iso2: "MK", iso3: "MKD", name: "Macedonia", code: "389" },
  { iso2: "MU", iso3: "MUS", name: "Mauritius", code: "230" },
  { iso2: "MT", iso3: "MLT", name: "Malta", code: "356" },
  { iso2: "MW", iso3: "MWI", name: "Malawi", code: "265" },
  { iso2: "MV", iso3: "MDV", name: "Maldives", code: "960" },
  { iso2: "MQ", iso3: "MTQ", name: "Martinique", code: "596" },
  { iso2: "MP", iso3: "MNP", name: "Northern Mariana Islands", code: "1670" },
  { iso2: "MS", iso3: "MSR", name: "Montserrat", code: "1664" },
  { iso2: "MR", iso3: "MRT", name: "Mauritania", code: "222" },
  { iso2: "IM", iso3: "IMN", name: "Isle of Man", code: "441624" },
  { iso2: "UG", iso3: "UGA", name: "Uganda", code: "256" },
  { iso2: "TZ", iso3: "TZA", name: "Tanzania", code: "255" },
  { iso2: "MY", iso3: "MYS", name: "Malaysia", code: "60" },
  { iso2: "MX", iso3: "MEX", name: "Mexico", code: "52" },
  { iso2: "IL", iso3: "ISR", name: "Israel", code: "972" },
  { iso2: "FR", iso3: "FRA", name: "France", code: "33" },
  { iso2: "IO", iso3: "IOT", name: "British Indian Ocean Territory", code: "246" },
  { iso2: "SH", iso3: "SHN", name: "Saint Helena", code: "290" },
  { iso2: "FI", iso3: "FIN", name: "Finland", code: "358" },
  { iso2: "FJ", iso3: "FJI", name: "Fiji", code: "679" },
  { iso2: "FK", iso3: "FLK", name: "Falkland Islands", code: "500" },
  { iso2: "FM", iso3: "FSM", name: "Micronesia", code: "691" },
  { iso2: "FO", iso3: "FRO", name: "Faroe Islands", code: "298" },
  { iso2: "NI", iso3: "NIC", name: "Nicaragua", code: "505" },
  { iso2: "NL", iso3: "NLD", name: "Netherlands", code: "31" },
  { iso2: "NO", iso3: "NOR", name: "Norway", code: "47" },
  { iso2: "NA", iso3: "NAM", name: "Namibia", code: "264" },
  { iso2: "VU", iso3: "VUT", name: "Vanuatu", code: "678" },
  { iso2: "NC", iso3: "NCL", name: "New Caledonia", code: "687" },
  { iso2: "NE", iso3: "NER", name: "Niger", code: "227" },
  { iso2: "NF", iso3: "NFK", name: "Norfolk Island", code: "672" },
  { iso2: "NG", iso3: "NGA", name: "Nigeria", code: "234" },
  { iso2: "NZ", iso3: "NZL", name: "New Zealand", code: "64" },
  { iso2: "NP", iso3: "NPL", name: "Nepal", code: "977" },
  { iso2: "NR", iso3: "NRU", name: "Nauru", code: "674" },
  { iso2: "NU", iso3: "NIU", name: "Niue", code: "683" },
  { iso2: "CK", iso3: "COK", name: "Cook Islands", code: "682" },
  { iso2: "CI", iso3: "CIV", name: "Ivory Coast", code: "225" },
  { iso2: "CH", iso3: "CHE", name: "Switzerland", code: "41" },
  { iso2: "CO", iso3: "COL", name: "Colombia", code: "57" },
  { iso2: "CN", iso3: "CHN", name: "China", code: "86" },
  { iso2: "CM", iso3: "CMR", name: "Cameroon", code: "237" },
  { iso2: "CL", iso3: "CHL", name: "Chile", code: "56" },
  { iso2: "CC", iso3: "CCK", name: "Cocos Islands", code: "61" },
  { iso2: "CA", iso3: "CAN", name: "Canada", code: "1" },
  { iso2: "CG", iso3: "COG", name: "Republic of the Congo", code: "242" },
  { iso2: "CF", iso3: "CAF", name: "Central African Republic", code: "236" },
  { iso2: "CD", iso3: "COD", name: "Democratic Republic of the Congo", code: "243" },
  { iso2: "CZ", iso3: "CZE", name: "Czech Republic", code: "420" },
  { iso2: "CY", iso3: "CYP", name: "Cyprus", code: "357" },
  { iso2: "CX", iso3: "CXR", name: "Christmas Island", code: "61" },
  { iso2: "CR", iso3: "CRI", name: "Costa Rica", code: "506" },
  { iso2: "CW", iso3: "CUW", name: "Curacao", code: "599" },
  { iso2: "CV", iso3: "CPV", name: "Cape Verde", code: "238" },
  { iso2: "CU", iso3: "CUB", name: "Cuba", code: "53" },
  { iso2: "SZ", iso3: "SWZ", name: "Swaziland", code: "268" },
  { iso2: "SY", iso3: "SYR", name: "Syria", code: "963" },
  { iso2: "SX", iso3: "SXM", name: "Sint Maarten", code: "599" },
  { iso2: "KG", iso3: "KGZ", name: "Kyrgyzstan", code: "996" },
  { iso2: "KE", iso3: "KEN", name: "Kenya", code: "254" },
  { iso2: "SS", iso3: "SSD", name: "South Sudan", code: "211" },
  { iso2: "SR", iso3: "SUR", name: "Suriname", code: "597" },
  { iso2: "KI", iso3: "KIR", name: "Kiribati", code: "686" },
  { iso2: "KH", iso3: "KHM", name: "Cambodia", code: "855" },
  { iso2: "KN", iso3: "KNA", name: "Saint Kitts and Nevis", code: "1869" },
  { iso2: "KM", iso3: "COM", name: "Comoros", code: "269" },
  { iso2: "ST", iso3: "STP", name: "Sao Tome and Principe", code: "239" },
  { iso2: "SK", iso3: "SVK", name: "Slovakia", code: "421" },
  { iso2: "KR", iso3: "KOR", name: "South Korea", code: "82" },
  { iso2: "SI", iso3: "SVN", name: "Slovenia", code: "386" },
  { iso2: "KP", iso3: "PRK", name: "North Korea", code: "850" },
  { iso2: "KW", iso3: "KWT", name: "Kuwait", code: "965" },
  { iso2: "SN", iso3: "SEN", name: "Senegal", code: "221" },
  { iso2: "SM", iso3: "SMR", name: "San Marino", code: "378" },
  { iso2: "SL", iso3: "SLE", name: "Sierra Leone", code: "232" },
  { iso2: "SC", iso3: "SYC", name: "Seychelles", code: "248" },
  { iso2: "KZ", iso3: "KAZ", name: "Kazakhstan", code: "7" },
  { iso2: "KY", iso3: "CYM", name: "Cayman Islands", code: "1345" },
  { iso2: "SG", iso3: "SGP", name: "Singapore", code: "65" },
  { iso2: "SE", iso3: "SWE", name: "Sweden", code: "46" },
  { iso2: "SD", iso3: "SDN", name: "Sudan", code: "249" },
  { iso2: "DO", iso3: "DOM", name: "Dominican Republic", code: "1809" },
  { iso2: "DO", iso3: "DOM", name: "Dominican Republic", code: "1829" },
  { iso2: "DO", iso3: "DOM", name: "Dominican Republic", code: "1849" },
  { iso2: "DM", iso3: "DMA", name: "Dominica", code: "1767" },
  { iso2: "DJ", iso3: "DJI", name: "Djibouti", code: "253" },
  { iso2: "DK", iso3: "DNK", name: "Denmark", code: "45" },
  { iso2: "VG", iso3: "VGB", name: "British Virgin Islands", code: "1284" },
  { iso2: "DE", iso3: "DEU", name: "Germany", code: "49" },
  { iso2: "YE", iso3: "YEM", name: "Yemen", code: "967" },
  { iso2: "DZ", iso3: "DZA", name: "Algeria", code: "213" },
  { iso2: "US", iso3: "USA", name: "United States", code: "1" },
  { iso2: "UY", iso3: "URY", name: "Uruguay", code: "598" },
  { iso2: "YT", iso3: "MYT", name: "Mayotte", code: "262" },
  { iso2: "UM", iso3: "UMI", name: "United States Minor Outlying Islands", code: "1" },
  { iso2: "LB", iso3: "LBN", name: "Lebanon", code: "961" },
  { iso2: "LC", iso3: "LCA", name: "Saint Lucia", code: "1758" },
  { iso2: "LA", iso3: "LAO", name: "Laos", code: "856" },
  { iso2: "TV", iso3: "TUV", name: "Tuvalu", code: "688" },
  { iso2: "TW", iso3: "TWN", name: "Taiwan", code: "886" },
  { iso2: "TT", iso3: "TTO", name: "Trinidad and Tobago", code: "1868" },
  { iso2: "TR", iso3: "TUR", name: "Turkey", code: "90" },
  { iso2: "LK", iso3: "LKA", name: "Sri Lanka", code: "94" },
  { iso2: "LI", iso3: "LIE", name: "Liechtenstein", code: "423" },
  { iso2: "LV", iso3: "LVA", name: "Latvia", code: "371" },
  { iso2: "TO", iso3: "TON", name: "Tonga", code: "676" },
  { iso2: "LT", iso3: "LTU", name: "Lithuania", code: "370" },
  { iso2: "LU", iso3: "LUX", name: "Luxembourg", code: "352" },
  { iso2: "LR", iso3: "LBR", name: "Liberia", code: "231" },
  { iso2: "LS", iso3: "LSO", name: "Lesotho", code: "266" },
  { iso2: "TH", iso3: "THA", name: "Thailand", code: "66" },
  { iso2: "TG", iso3: "TGO", name: "Togo", code: "228" },
  { iso2: "TD", iso3: "TCD", name: "Chad", code: "235" },
  { iso2: "TC", iso3: "TCA", name: "Turks and Caicos Islands", code: "1649" },
  { iso2: "LY", iso3: "LBY", name: "Libya", code: "218" },
  { iso2: "VA", iso3: "VAT", name: "Vatican", code: "379" },
  { iso2: "VC", iso3: "VCT", name: "Saint Vincent and the Grenadines", code: "1784" },
  { iso2: "AE", iso3: "ARE", name: "United Arab Emirates", code: "971" },
  { iso2: "AD", iso3: "AND", name: "Andorra", code: "376" },
  { iso2: "AG", iso3: "ATG", name: "Antigua and Barbuda", code: "1268" },
  { iso2: "AF", iso3: "AFG", name: "Afghanistan", code: "93" },
  { iso2: "AI", iso3: "AIA", name: "Anguilla", code: "1264" },
  { iso2: "VI", iso3: "VIR", name: "U.S. Virgin Islands", code: "1340" },
  { iso2: "IS", iso3: "ISL", name: "Iceland", code: "354" },
  { iso2: "IR", iso3: "IRN", name: "Iran", code: "98" },
  { iso2: "AM", iso3: "ARM", name: "Armenia", code: "374" },
  { iso2: "AL", iso3: "ALB", name: "Albania", code: "355" },
  { iso2: "AO", iso3: "AGO", name: "Angola", code: "244" },
  { iso2: "AS", iso3: "ASM", name: "American Samoa", code: "1684" },
  { iso2: "AR", iso3: "ARG", name: "Argentina", code: "54" },
  { iso2: "AU", iso3: "AUS", name: "Australia", code: "61" },
  { iso2: "AT", iso3: "AUT", name: "Austria", code: "43" },
  { iso2: "AW", iso3: "ABW", name: "Aruba", code: "297" },
  { iso2: "IN", iso3: "IND", name: "India", code: "91" },
  { iso2: "AX", iso3: "ALA", name: "Aland Islands", code: "35818" },
  { iso2: "AZ", iso3: "AZE", name: "Azerbaijan", code: "994" },
  { iso2: "IE", iso3: "IRL", name: "Ireland", code: "353" },
  { iso2: "ID", iso3: "IDN", name: "Indonesia", code: "62" },
  { iso2: "UA", iso3: "UKR", name: "Ukraine", code: "380" },
  { iso2: "QA", iso3: "QAT", name: "Qatar", code: "974" },
  { iso2: "MZ", iso3: "MOZ", name: "Mozambique", code: "258" },
  { iso2: "BY", iso3: "BLR", name: "Belarus", code: "375" },
];

export const isoCountries = [
  {
    name: "Afghanistan",
    "alpha-2": "AF",
    "alpha-3": "AFG",
    "country-code": "004",
    "iso_3166-2": "ISO 3166-2:AF",
    region: "Asia",
    "sub-region": "Southern Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "034",
    "intermediate-region-code": "",
  },
  {
    name: "Åland Islands",
    "alpha-2": "AX",
    "alpha-3": "ALA",
    "country-code": "248",
    "iso_3166-2": "ISO 3166-2:AX",
    region: "Europe",
    "sub-region": "Northern Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "154",
    "intermediate-region-code": "",
  },
  {
    name: "Albania",
    "alpha-2": "AL",
    "alpha-3": "ALB",
    "country-code": "008",
    "iso_3166-2": "ISO 3166-2:AL",
    region: "Europe",
    "sub-region": "Southern Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "039",
    "intermediate-region-code": "",
  },
  {
    name: "Algeria",
    "alpha-2": "DZ",
    "alpha-3": "DZA",
    "country-code": "012",
    "iso_3166-2": "ISO 3166-2:DZ",
    region: "Africa",
    "sub-region": "Northern Africa",
    "intermediate-region": "",
    "region-code": "002",
    "sub-region-code": "015",
    "intermediate-region-code": "",
  },
  {
    name: "American Samoa",
    "alpha-2": "AS",
    "alpha-3": "ASM",
    "country-code": "016",
    "iso_3166-2": "ISO 3166-2:AS",
    region: "Oceania",
    "sub-region": "Polynesia",
    "intermediate-region": "",
    "region-code": "009",
    "sub-region-code": "061",
    "intermediate-region-code": "",
  },
  {
    name: "Andorra",
    "alpha-2": "AD",
    "alpha-3": "AND",
    "country-code": "020",
    "iso_3166-2": "ISO 3166-2:AD",
    region: "Europe",
    "sub-region": "Southern Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "039",
    "intermediate-region-code": "",
  },
  {
    name: "Angola",
    "alpha-2": "AO",
    "alpha-3": "AGO",
    "country-code": "024",
    "iso_3166-2": "ISO 3166-2:AO",
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Middle Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "017",
  },
  {
    name: "Anguilla",
    "alpha-2": "AI",
    "alpha-3": "AIA",
    "country-code": "660",
    "iso_3166-2": "ISO 3166-2:AI",
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "Caribbean",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "029",
  },
  {
    name: "Antarctica",
    "alpha-2": "AQ",
    "alpha-3": "ATA",
    "country-code": "010",
    "iso_3166-2": "ISO 3166-2:AQ",
    region: "",
    "sub-region": "",
    "intermediate-region": "",
    "region-code": "",
    "sub-region-code": "",
    "intermediate-region-code": "",
  },
  {
    name: "Antigua and Barbuda",
    "alpha-2": "AG",
    "alpha-3": "ATG",
    "country-code": "028",
    "iso_3166-2": "ISO 3166-2:AG",
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "Caribbean",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "029",
  },
  {
    name: "Argentina",
    "alpha-2": "AR",
    "alpha-3": "ARG",
    "country-code": "032",
    "iso_3166-2": "ISO 3166-2:AR",
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "South America",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "005",
  },
  {
    name: "Armenia",
    "alpha-2": "AM",
    "alpha-3": "ARM",
    "country-code": "051",
    "iso_3166-2": "ISO 3166-2:AM",
    region: "Asia",
    "sub-region": "Western Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "145",
    "intermediate-region-code": "",
  },
  {
    name: "Aruba",
    "alpha-2": "AW",
    "alpha-3": "ABW",
    "country-code": "533",
    "iso_3166-2": "ISO 3166-2:AW",
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "Caribbean",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "029",
  },
  {
    name: "Australia",
    "alpha-2": "AU",
    "alpha-3": "AUS",
    "country-code": "036",
    "iso_3166-2": "ISO 3166-2:AU",
    region: "Oceania",
    "sub-region": "Australia and New Zealand",
    "intermediate-region": "",
    "region-code": "009",
    "sub-region-code": "053",
    "intermediate-region-code": "",
  },
  {
    name: "Austria",
    "alpha-2": "AT",
    "alpha-3": "AUT",
    "country-code": "040",
    "iso_3166-2": "ISO 3166-2:AT",
    region: "Europe",
    "sub-region": "Western Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "155",
    "intermediate-region-code": "",
  },
  {
    name: "Azerbaijan",
    "alpha-2": "AZ",
    "alpha-3": "AZE",
    "country-code": "031",
    "iso_3166-2": "ISO 3166-2:AZ",
    region: "Asia",
    "sub-region": "Western Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "145",
    "intermediate-region-code": "",
  },
  {
    name: "Bahamas",
    "alpha-2": "BS",
    "alpha-3": "BHS",
    "country-code": "044",
    "iso_3166-2": "ISO 3166-2:BS",
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "Caribbean",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "029",
  },
  {
    name: "Bahrain",
    "alpha-2": "BH",
    "alpha-3": "BHR",
    "country-code": "048",
    "iso_3166-2": "ISO 3166-2:BH",
    region: "Asia",
    "sub-region": "Western Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "145",
    "intermediate-region-code": "",
  },
  {
    name: "Bangladesh",
    "alpha-2": "BD",
    "alpha-3": "BGD",
    "country-code": "050",
    "iso_3166-2": "ISO 3166-2:BD",
    region: "Asia",
    "sub-region": "Southern Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "034",
    "intermediate-region-code": "",
  },
  {
    name: "Barbados",
    "alpha-2": "BB",
    "alpha-3": "BRB",
    "country-code": "052",
    "iso_3166-2": "ISO 3166-2:BB",
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "Caribbean",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "029",
  },
  {
    name: "Belarus",
    "alpha-2": "BY",
    "alpha-3": "BLR",
    "country-code": "112",
    "iso_3166-2": "ISO 3166-2:BY",
    region: "Europe",
    "sub-region": "Eastern Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "151",
    "intermediate-region-code": "",
  },
  {
    name: "Belgium",
    "alpha-2": "BE",
    "alpha-3": "BEL",
    "country-code": "056",
    "iso_3166-2": "ISO 3166-2:BE",
    region: "Europe",
    "sub-region": "Western Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "155",
    "intermediate-region-code": "",
  },
  {
    name: "Belize",
    "alpha-2": "BZ",
    "alpha-3": "BLZ",
    "country-code": "084",
    "iso_3166-2": "ISO 3166-2:BZ",
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "Central America",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "013",
  },
  {
    name: "Benin",
    "alpha-2": "BJ",
    "alpha-3": "BEN",
    "country-code": "204",
    "iso_3166-2": "ISO 3166-2:BJ",
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Western Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "011",
  },
  {
    name: "Bermuda",
    "alpha-2": "BM",
    "alpha-3": "BMU",
    "country-code": "060",
    "iso_3166-2": "ISO 3166-2:BM",
    region: "Americas",
    "sub-region": "Northern America",
    "intermediate-region": "",
    "region-code": "019",
    "sub-region-code": "021",
    "intermediate-region-code": "",
  },
  {
    name: "Bhutan",
    "alpha-2": "BT",
    "alpha-3": "BTN",
    "country-code": "064",
    "iso_3166-2": "ISO 3166-2:BT",
    region: "Asia",
    "sub-region": "Southern Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "034",
    "intermediate-region-code": "",
  },
  {
    name: "Bolivia, Plurinational State of",
    "alpha-2": "BO",
    "alpha-3": "BOL",
    "country-code": "068",
    "iso_3166-2": "ISO 3166-2:BO",
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "South America",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "005",
  },
  {
    name: "Bonaire, Sint Eustatius and Saba",
    "alpha-2": "BQ",
    "alpha-3": "BES",
    "country-code": "535",
    "iso_3166-2": "ISO 3166-2:BQ",
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "Caribbean",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "029",
  },
  {
    name: "Bosnia and Herzegovina",
    "alpha-2": "BA",
    "alpha-3": "BIH",
    "country-code": "070",
    "iso_3166-2": "ISO 3166-2:BA",
    region: "Europe",
    "sub-region": "Southern Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "039",
    "intermediate-region-code": "",
  },
  {
    name: "Botswana",
    "alpha-2": "BW",
    "alpha-3": "BWA",
    "country-code": "072",
    "iso_3166-2": "ISO 3166-2:BW",
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Southern Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "018",
  },
  {
    name: "Bouvet Island",
    "alpha-2": "BV",
    "alpha-3": "BVT",
    "country-code": "074",
    "iso_3166-2": "ISO 3166-2:BV",
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "South America",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "005",
  },
  {
    name: "Brazil",
    "alpha-2": "BR",
    "alpha-3": "BRA",
    "country-code": "076",
    "iso_3166-2": "ISO 3166-2:BR",
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "South America",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "005",
  },
  {
    name: "British Indian Ocean Territory",
    "alpha-2": "IO",
    "alpha-3": "IOT",
    "country-code": "086",
    "iso_3166-2": "ISO 3166-2:IO",
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Eastern Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "014",
  },
  {
    name: "Brunei Darussalam",
    "alpha-2": "BN",
    "alpha-3": "BRN",
    "country-code": "096",
    "iso_3166-2": "ISO 3166-2:BN",
    region: "Asia",
    "sub-region": "South-eastern Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "035",
    "intermediate-region-code": "",
  },
  {
    name: "Bulgaria",
    "alpha-2": "BG",
    "alpha-3": "BGR",
    "country-code": "100",
    "iso_3166-2": "ISO 3166-2:BG",
    region: "Europe",
    "sub-region": "Eastern Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "151",
    "intermediate-region-code": "",
  },
  {
    name: "Burkina Faso",
    "alpha-2": "BF",
    "alpha-3": "BFA",
    "country-code": "854",
    "iso_3166-2": "ISO 3166-2:BF",
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Western Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "011",
  },
  {
    name: "Burundi",
    "alpha-2": "BI",
    "alpha-3": "BDI",
    "country-code": "108",
    "iso_3166-2": "ISO 3166-2:BI",
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Eastern Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "014",
  },
  {
    name: "Cabo Verde",
    "alpha-2": "CV",
    "alpha-3": "CPV",
    "country-code": "132",
    "iso_3166-2": "ISO 3166-2:CV",
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Western Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "011",
  },
  {
    name: "Cambodia",
    "alpha-2": "KH",
    "alpha-3": "KHM",
    "country-code": "116",
    "iso_3166-2": "ISO 3166-2:KH",
    region: "Asia",
    "sub-region": "South-eastern Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "035",
    "intermediate-region-code": "",
  },
  {
    name: "Cameroon",
    "alpha-2": "CM",
    "alpha-3": "CMR",
    "country-code": "120",
    "iso_3166-2": "ISO 3166-2:CM",
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Middle Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "017",
  },
  {
    name: "Canada",
    "alpha-2": "CA",
    "alpha-3": "CAN",
    "country-code": "124",
    "iso_3166-2": "ISO 3166-2:CA",
    region: "Americas",
    "sub-region": "Northern America",
    "intermediate-region": "",
    "region-code": "019",
    "sub-region-code": "021",
    "intermediate-region-code": "",
  },
  {
    name: "Cayman Islands",
    "alpha-2": "KY",
    "alpha-3": "CYM",
    "country-code": "136",
    "iso_3166-2": "ISO 3166-2:KY",
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "Caribbean",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "029",
  },
  {
    name: "Central African Republic",
    "alpha-2": "CF",
    "alpha-3": "CAF",
    "country-code": "140",
    "iso_3166-2": "ISO 3166-2:CF",
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Middle Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "017",
  },
  {
    name: "Chad",
    "alpha-2": "TD",
    "alpha-3": "TCD",
    "country-code": "148",
    "iso_3166-2": "ISO 3166-2:TD",
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Middle Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "017",
  },
  {
    name: "Chile",
    "alpha-2": "CL",
    "alpha-3": "CHL",
    "country-code": "152",
    "iso_3166-2": "ISO 3166-2:CL",
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "South America",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "005",
  },
  {
    name: "China",
    "alpha-2": "CN",
    "alpha-3": "CHN",
    "country-code": "156",
    "iso_3166-2": "ISO 3166-2:CN",
    region: "Asia",
    "sub-region": "Eastern Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "030",
    "intermediate-region-code": "",
  },
  {
    name: "Christmas Island",
    "alpha-2": "CX",
    "alpha-3": "CXR",
    "country-code": "162",
    "iso_3166-2": "ISO 3166-2:CX",
    region: "Oceania",
    "sub-region": "Australia and New Zealand",
    "intermediate-region": "",
    "region-code": "009",
    "sub-region-code": "053",
    "intermediate-region-code": "",
  },
  {
    name: "Cocos (Keeling) Islands",
    "alpha-2": "CC",
    "alpha-3": "CCK",
    "country-code": "166",
    "iso_3166-2": "ISO 3166-2:CC",
    region: "Oceania",
    "sub-region": "Australia and New Zealand",
    "intermediate-region": "",
    "region-code": "009",
    "sub-region-code": "053",
    "intermediate-region-code": "",
  },
  {
    name: "Colombia",
    "alpha-2": "CO",
    "alpha-3": "COL",
    "country-code": "170",
    "iso_3166-2": "ISO 3166-2:CO",
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "South America",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "005",
  },
  {
    name: "Comoros",
    "alpha-2": "KM",
    "alpha-3": "COM",
    "country-code": "174",
    "iso_3166-2": "ISO 3166-2:KM",
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Eastern Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "014",
  },
  {
    name: "Congo",
    "alpha-2": "CG",
    "alpha-3": "COG",
    "country-code": "178",
    "iso_3166-2": "ISO 3166-2:CG",
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Middle Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "017",
  },
  {
    name: "Congo, Democratic Republic of the",
    "alpha-2": "CD",
    "alpha-3": "COD",
    "country-code": "180",
    "iso_3166-2": "ISO 3166-2:CD",
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Middle Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "017",
  },
  {
    name: "Cook Islands",
    "alpha-2": "CK",
    "alpha-3": "COK",
    "country-code": "184",
    "iso_3166-2": "ISO 3166-2:CK",
    region: "Oceania",
    "sub-region": "Polynesia",
    "intermediate-region": "",
    "region-code": "009",
    "sub-region-code": "061",
    "intermediate-region-code": "",
  },
  {
    name: "Costa Rica",
    "alpha-2": "CR",
    "alpha-3": "CRI",
    "country-code": "188",
    "iso_3166-2": "ISO 3166-2:CR",
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "Central America",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "013",
  },
  {
    name: "Côte d'Ivoire",
    "alpha-2": "CI",
    "alpha-3": "CIV",
    "country-code": "384",
    "iso_3166-2": "ISO 3166-2:CI",
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Western Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "011",
  },
  {
    name: "Croatia",
    "alpha-2": "HR",
    "alpha-3": "HRV",
    "country-code": "191",
    "iso_3166-2": "ISO 3166-2:HR",
    region: "Europe",
    "sub-region": "Southern Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "039",
    "intermediate-region-code": "",
  },
  {
    name: "Cuba",
    "alpha-2": "CU",
    "alpha-3": "CUB",
    "country-code": "192",
    "iso_3166-2": "ISO 3166-2:CU",
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "Caribbean",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "029",
  },
  {
    name: "Curaçao",
    "alpha-2": "CW",
    "alpha-3": "CUW",
    "country-code": "531",
    "iso_3166-2": "ISO 3166-2:CW",
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "Caribbean",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "029",
  },
  {
    name: "Cyprus",
    "alpha-2": "CY",
    "alpha-3": "CYP",
    "country-code": "196",
    "iso_3166-2": "ISO 3166-2:CY",
    region: "Asia",
    "sub-region": "Western Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "145",
    "intermediate-region-code": "",
  },
  {
    name: "Czechia",
    "alpha-2": "CZ",
    "alpha-3": "CZE",
    "country-code": "203",
    "iso_3166-2": "ISO 3166-2:CZ",
    region: "Europe",
    "sub-region": "Eastern Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "151",
    "intermediate-region-code": "",
  },
  {
    name: "Denmark",
    "alpha-2": "DK",
    "alpha-3": "DNK",
    "country-code": "208",
    "iso_3166-2": "ISO 3166-2:DK",
    region: "Europe",
    "sub-region": "Northern Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "154",
    "intermediate-region-code": "",
  },
  {
    name: "Djibouti",
    "alpha-2": "DJ",
    "alpha-3": "DJI",
    "country-code": "262",
    "iso_3166-2": "ISO 3166-2:DJ",
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Eastern Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "014",
  },
  {
    name: "Dominica",
    "alpha-2": "DM",
    "alpha-3": "DMA",
    "country-code": "212",
    "iso_3166-2": "ISO 3166-2:DM",
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "Caribbean",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "029",
  },
  {
    name: "Dominican Republic",
    "alpha-2": "DO",
    "alpha-3": "DOM",
    "country-code": "214",
    "iso_3166-2": "ISO 3166-2:DO",
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "Caribbean",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "029",
  },
  {
    name: "Ecuador",
    "alpha-2": "EC",
    "alpha-3": "ECU",
    "country-code": "218",
    "iso_3166-2": "ISO 3166-2:EC",
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "South America",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "005",
  },
  {
    name: "Egypt",
    "alpha-2": "EG",
    "alpha-3": "EGY",
    "country-code": "818",
    "iso_3166-2": "ISO 3166-2:EG",
    region: "Africa",
    "sub-region": "Northern Africa",
    "intermediate-region": "",
    "region-code": "002",
    "sub-region-code": "015",
    "intermediate-region-code": "",
  },
  {
    name: "El Salvador",
    "alpha-2": "SV",
    "alpha-3": "SLV",
    "country-code": "222",
    "iso_3166-2": "ISO 3166-2:SV",
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "Central America",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "013",
  },
  {
    name: "Equatorial Guinea",
    "alpha-2": "GQ",
    "alpha-3": "GNQ",
    "country-code": "226",
    "iso_3166-2": "ISO 3166-2:GQ",
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Middle Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "017",
  },
  {
    name: "Eritrea",
    "alpha-2": "ER",
    "alpha-3": "ERI",
    "country-code": "232",
    "iso_3166-2": "ISO 3166-2:ER",
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Eastern Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "014",
  },
  {
    name: "Estonia",
    "alpha-2": "EE",
    "alpha-3": "EST",
    "country-code": "233",
    "iso_3166-2": "ISO 3166-2:EE",
    region: "Europe",
    "sub-region": "Northern Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "154",
    "intermediate-region-code": "",
  },
  {
    name: "Eswatini",
    "alpha-2": "SZ",
    "alpha-3": "SWZ",
    "country-code": "748",
    "iso_3166-2": "ISO 3166-2:SZ",
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Southern Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "018",
  },
  {
    name: "Ethiopia",
    "alpha-2": "ET",
    "alpha-3": "ETH",
    "country-code": "231",
    "iso_3166-2": "ISO 3166-2:ET",
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Eastern Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "014",
  },
  {
    name: "Falkland Islands (Malvinas)",
    "alpha-2": "FK",
    "alpha-3": "FLK",
    "country-code": "238",
    "iso_3166-2": "ISO 3166-2:FK",
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "South America",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "005",
  },
  {
    name: "Faroe Islands",
    "alpha-2": "FO",
    "alpha-3": "FRO",
    "country-code": "234",
    "iso_3166-2": "ISO 3166-2:FO",
    region: "Europe",
    "sub-region": "Northern Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "154",
    "intermediate-region-code": "",
  },
  {
    name: "Fiji",
    "alpha-2": "FJ",
    "alpha-3": "FJI",
    "country-code": "242",
    "iso_3166-2": "ISO 3166-2:FJ",
    region: "Oceania",
    "sub-region": "Melanesia",
    "intermediate-region": "",
    "region-code": "009",
    "sub-region-code": "054",
    "intermediate-region-code": "",
  },
  {
    name: "Finland",
    "alpha-2": "FI",
    "alpha-3": "FIN",
    "country-code": "246",
    "iso_3166-2": "ISO 3166-2:FI",
    region: "Europe",
    "sub-region": "Northern Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "154",
    "intermediate-region-code": "",
  },
  {
    name: "France",
    "alpha-2": "FR",
    "alpha-3": "FRA",
    "country-code": "250",
    "iso_3166-2": "ISO 3166-2:FR",
    region: "Europe",
    "sub-region": "Western Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "155",
    "intermediate-region-code": "",
  },
  {
    name: "French Guiana",
    "alpha-2": "GF",
    "alpha-3": "GUF",
    "country-code": "254",
    "iso_3166-2": "ISO 3166-2:GF",
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "South America",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "005",
  },
  {
    name: "French Polynesia",
    "alpha-2": "PF",
    "alpha-3": "PYF",
    "country-code": "258",
    "iso_3166-2": "ISO 3166-2:PF",
    region: "Oceania",
    "sub-region": "Polynesia",
    "intermediate-region": "",
    "region-code": "009",
    "sub-region-code": "061",
    "intermediate-region-code": "",
  },
  {
    name: "French Southern Territories",
    "alpha-2": "TF",
    "alpha-3": "ATF",
    "country-code": "260",
    "iso_3166-2": "ISO 3166-2:TF",
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Eastern Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "014",
  },
  {
    name: "Gabon",
    "alpha-2": "GA",
    "alpha-3": "GAB",
    "country-code": "266",
    "iso_3166-2": "ISO 3166-2:GA",
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Middle Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "017",
  },
  {
    name: "Gambia",
    "alpha-2": "GM",
    "alpha-3": "GMB",
    "country-code": "270",
    "iso_3166-2": "ISO 3166-2:GM",
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Western Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "011",
  },
  {
    name: "Georgia",
    "alpha-2": "GE",
    "alpha-3": "GEO",
    "country-code": "268",
    "iso_3166-2": "ISO 3166-2:GE",
    region: "Asia",
    "sub-region": "Western Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "145",
    "intermediate-region-code": "",
  },
  {
    name: "Germany",
    "alpha-2": "DE",
    "alpha-3": "DEU",
    "country-code": "276",
    "iso_3166-2": "ISO 3166-2:DE",
    region: "Europe",
    "sub-region": "Western Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "155",
    "intermediate-region-code": "",
  },
  {
    name: "Ghana",
    "alpha-2": "GH",
    "alpha-3": "GHA",
    "country-code": "288",
    "iso_3166-2": "ISO 3166-2:GH",
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Western Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "011",
  },
  {
    name: "Gibraltar",
    "alpha-2": "GI",
    "alpha-3": "GIB",
    "country-code": "292",
    "iso_3166-2": "ISO 3166-2:GI",
    region: "Europe",
    "sub-region": "Southern Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "039",
    "intermediate-region-code": "",
  },
  {
    name: "Greece",
    "alpha-2": "GR",
    "alpha-3": "GRC",
    "country-code": "300",
    "iso_3166-2": "ISO 3166-2:GR",
    region: "Europe",
    "sub-region": "Southern Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "039",
    "intermediate-region-code": "",
  },
  {
    name: "Greenland",
    "alpha-2": "GL",
    "alpha-3": "GRL",
    "country-code": "304",
    "iso_3166-2": "ISO 3166-2:GL",
    region: "Americas",
    "sub-region": "Northern America",
    "intermediate-region": "",
    "region-code": "019",
    "sub-region-code": "021",
    "intermediate-region-code": "",
  },
  {
    name: "Grenada",
    "alpha-2": "GD",
    "alpha-3": "GRD",
    "country-code": "308",
    "iso_3166-2": "ISO 3166-2:GD",
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "Caribbean",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "029",
  },
  {
    name: "Guadeloupe",
    "alpha-2": "GP",
    "alpha-3": "GLP",
    "country-code": "312",
    "iso_3166-2": "ISO 3166-2:GP",
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "Caribbean",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "029",
  },
  {
    name: "Guam",
    "alpha-2": "GU",
    "alpha-3": "GUM",
    "country-code": "316",
    "iso_3166-2": "ISO 3166-2:GU",
    region: "Oceania",
    "sub-region": "Micronesia",
    "intermediate-region": "",
    "region-code": "009",
    "sub-region-code": "057",
    "intermediate-region-code": "",
  },
  {
    name: "Guatemala",
    "alpha-2": "GT",
    "alpha-3": "GTM",
    "country-code": "320",
    "iso_3166-2": "ISO 3166-2:GT",
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "Central America",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "013",
  },
  {
    name: "Guernsey",
    "alpha-2": "GG",
    "alpha-3": "GGY",
    "country-code": "831",
    "iso_3166-2": "ISO 3166-2:GG",
    region: "Europe",
    "sub-region": "Northern Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "154",
    "intermediate-region-code": "",
  },
  {
    name: "Guinea",
    "alpha-2": "GN",
    "alpha-3": "GIN",
    "country-code": "324",
    "iso_3166-2": "ISO 3166-2:GN",
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Western Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "011",
  },
  {
    name: "Guinea-Bissau",
    "alpha-2": "GW",
    "alpha-3": "GNB",
    "country-code": "624",
    "iso_3166-2": "ISO 3166-2:GW",
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Western Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "011",
  },
  {
    name: "Guyana",
    "alpha-2": "GY",
    "alpha-3": "GUY",
    "country-code": "328",
    "iso_3166-2": "ISO 3166-2:GY",
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "South America",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "005",
  },
  {
    name: "Haiti",
    "alpha-2": "HT",
    "alpha-3": "HTI",
    "country-code": "332",
    "iso_3166-2": "ISO 3166-2:HT",
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "Caribbean",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "029",
  },
  {
    name: "Heard Island and McDonald Islands",
    "alpha-2": "HM",
    "alpha-3": "HMD",
    "country-code": "334",
    "iso_3166-2": "ISO 3166-2:HM",
    region: "Oceania",
    "sub-region": "Australia and New Zealand",
    "intermediate-region": "",
    "region-code": "009",
    "sub-region-code": "053",
    "intermediate-region-code": "",
  },
  {
    name: "Holy See",
    "alpha-2": "VA",
    "alpha-3": "VAT",
    "country-code": "336",
    "iso_3166-2": "ISO 3166-2:VA",
    region: "Europe",
    "sub-region": "Southern Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "039",
    "intermediate-region-code": "",
  },
  {
    name: "Honduras",
    "alpha-2": "HN",
    "alpha-3": "HND",
    "country-code": "340",
    "iso_3166-2": "ISO 3166-2:HN",
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "Central America",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "013",
  },
  {
    name: "Hong Kong",
    "alpha-2": "HK",
    "alpha-3": "HKG",
    "country-code": "344",
    "iso_3166-2": "ISO 3166-2:HK",
    region: "Asia",
    "sub-region": "Eastern Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "030",
    "intermediate-region-code": "",
  },
  {
    name: "Hungary",
    "alpha-2": "HU",
    "alpha-3": "HUN",
    "country-code": "348",
    "iso_3166-2": "ISO 3166-2:HU",
    region: "Europe",
    "sub-region": "Eastern Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "151",
    "intermediate-region-code": "",
  },
  {
    name: "Iceland",
    "alpha-2": "IS",
    "alpha-3": "ISL",
    "country-code": "352",
    "iso_3166-2": "ISO 3166-2:IS",
    region: "Europe",
    "sub-region": "Northern Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "154",
    "intermediate-region-code": "",
  },
  {
    name: "India",
    "alpha-2": "IN",
    "alpha-3": "IND",
    "country-code": "356",
    "iso_3166-2": "ISO 3166-2:IN",
    region: "Asia",
    "sub-region": "Southern Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "034",
    "intermediate-region-code": "",
  },
  {
    name: "Indonesia",
    "alpha-2": "ID",
    "alpha-3": "IDN",
    "country-code": "360",
    "iso_3166-2": "ISO 3166-2:ID",
    region: "Asia",
    "sub-region": "South-eastern Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "035",
    "intermediate-region-code": "",
  },
  {
    name: "Iran, Islamic Republic of",
    "alpha-2": "IR",
    "alpha-3": "IRN",
    "country-code": "364",
    "iso_3166-2": "ISO 3166-2:IR",
    region: "Asia",
    "sub-region": "Southern Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "034",
    "intermediate-region-code": "",
  },
  {
    name: "Iraq",
    "alpha-2": "IQ",
    "alpha-3": "IRQ",
    "country-code": "368",
    "iso_3166-2": "ISO 3166-2:IQ",
    region: "Asia",
    "sub-region": "Western Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "145",
    "intermediate-region-code": "",
  },
  {
    name: "Ireland",
    "alpha-2": "IE",
    "alpha-3": "IRL",
    "country-code": "372",
    "iso_3166-2": "ISO 3166-2:IE",
    region: "Europe",
    "sub-region": "Northern Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "154",
    "intermediate-region-code": "",
  },
  {
    name: "Isle of Man",
    "alpha-2": "IM",
    "alpha-3": "IMN",
    "country-code": "833",
    "iso_3166-2": "ISO 3166-2:IM",
    region: "Europe",
    "sub-region": "Northern Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "154",
    "intermediate-region-code": "",
  },
  {
    name: "Israel",
    "alpha-2": "IL",
    "alpha-3": "ISR",
    "country-code": "376",
    "iso_3166-2": "ISO 3166-2:IL",
    region: "Asia",
    "sub-region": "Western Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "145",
    "intermediate-region-code": "",
  },
  {
    name: "Italy",
    "alpha-2": "IT",
    "alpha-3": "ITA",
    "country-code": "380",
    "iso_3166-2": "ISO 3166-2:IT",
    region: "Europe",
    "sub-region": "Southern Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "039",
    "intermediate-region-code": "",
  },
  {
    name: "Jamaica",
    "alpha-2": "JM",
    "alpha-3": "JAM",
    "country-code": "388",
    "iso_3166-2": "ISO 3166-2:JM",
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "Caribbean",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "029",
  },
  {
    name: "Japan",
    "alpha-2": "JP",
    "alpha-3": "JPN",
    "country-code": "392",
    "iso_3166-2": "ISO 3166-2:JP",
    region: "Asia",
    "sub-region": "Eastern Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "030",
    "intermediate-region-code": "",
  },
  {
    name: "Jersey",
    "alpha-2": "JE",
    "alpha-3": "JEY",
    "country-code": "832",
    "iso_3166-2": "ISO 3166-2:JE",
    region: "Europe",
    "sub-region": "Northern Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "154",
    "intermediate-region-code": "",
  },
  {
    name: "Jordan",
    "alpha-2": "JO",
    "alpha-3": "JOR",
    "country-code": "400",
    "iso_3166-2": "ISO 3166-2:JO",
    region: "Asia",
    "sub-region": "Western Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "145",
    "intermediate-region-code": "",
  },
  {
    name: "Kazakhstan",
    "alpha-2": "KZ",
    "alpha-3": "KAZ",
    "country-code": "398",
    "iso_3166-2": "ISO 3166-2:KZ",
    region: "Asia",
    "sub-region": "Central Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "143",
    "intermediate-region-code": "",
  },
  {
    name: "Kenya",
    "alpha-2": "KE",
    "alpha-3": "KEN",
    "country-code": "404",
    "iso_3166-2": "ISO 3166-2:KE",
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Eastern Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "014",
  },
  {
    name: "Kiribati",
    "alpha-2": "KI",
    "alpha-3": "KIR",
    "country-code": "296",
    "iso_3166-2": "ISO 3166-2:KI",
    region: "Oceania",
    "sub-region": "Micronesia",
    "intermediate-region": "",
    "region-code": "009",
    "sub-region-code": "057",
    "intermediate-region-code": "",
  },
  {
    name: "Korea, Democratic People's Republic of",
    "alpha-2": "KP",
    "alpha-3": "PRK",
    "country-code": "408",
    "iso_3166-2": "ISO 3166-2:KP",
    region: "Asia",
    "sub-region": "Eastern Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "030",
    "intermediate-region-code": "",
  },
  {
    name: "Korea, Republic of",
    "alpha-2": "KR",
    "alpha-3": "KOR",
    "country-code": "410",
    "iso_3166-2": "ISO 3166-2:KR",
    region: "Asia",
    "sub-region": "Eastern Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "030",
    "intermediate-region-code": "",
  },
  {
    name: "Kuwait",
    "alpha-2": "KW",
    "alpha-3": "KWT",
    "country-code": "414",
    "iso_3166-2": "ISO 3166-2:KW",
    region: "Asia",
    "sub-region": "Western Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "145",
    "intermediate-region-code": "",
  },
  {
    name: "Kyrgyzstan",
    "alpha-2": "KG",
    "alpha-3": "KGZ",
    "country-code": "417",
    "iso_3166-2": "ISO 3166-2:KG",
    region: "Asia",
    "sub-region": "Central Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "143",
    "intermediate-region-code": "",
  },
  {
    name: "Lao People's Democratic Republic",
    "alpha-2": "LA",
    "alpha-3": "LAO",
    "country-code": "418",
    "iso_3166-2": "ISO 3166-2:LA",
    region: "Asia",
    "sub-region": "South-eastern Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "035",
    "intermediate-region-code": "",
  },
  {
    name: "Latvia",
    "alpha-2": "LV",
    "alpha-3": "LVA",
    "country-code": "428",
    "iso_3166-2": "ISO 3166-2:LV",
    region: "Europe",
    "sub-region": "Northern Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "154",
    "intermediate-region-code": "",
  },
  {
    name: "Lebanon",
    "alpha-2": "LB",
    "alpha-3": "LBN",
    "country-code": "422",
    "iso_3166-2": "ISO 3166-2:LB",
    region: "Asia",
    "sub-region": "Western Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "145",
    "intermediate-region-code": "",
  },
  {
    name: "Lesotho",
    "alpha-2": "LS",
    "alpha-3": "LSO",
    "country-code": "426",
    "iso_3166-2": "ISO 3166-2:LS",
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Southern Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "018",
  },
  {
    name: "Liberia",
    "alpha-2": "LR",
    "alpha-3": "LBR",
    "country-code": "430",
    "iso_3166-2": "ISO 3166-2:LR",
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Western Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "011",
  },
  {
    name: "Libya",
    "alpha-2": "LY",
    "alpha-3": "LBY",
    "country-code": "434",
    "iso_3166-2": "ISO 3166-2:LY",
    region: "Africa",
    "sub-region": "Northern Africa",
    "intermediate-region": "",
    "region-code": "002",
    "sub-region-code": "015",
    "intermediate-region-code": "",
  },
  {
    name: "Liechtenstein",
    "alpha-2": "LI",
    "alpha-3": "LIE",
    "country-code": "438",
    "iso_3166-2": "ISO 3166-2:LI",
    region: "Europe",
    "sub-region": "Western Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "155",
    "intermediate-region-code": "",
  },
  {
    name: "Lithuania",
    "alpha-2": "LT",
    "alpha-3": "LTU",
    "country-code": "440",
    "iso_3166-2": "ISO 3166-2:LT",
    region: "Europe",
    "sub-region": "Northern Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "154",
    "intermediate-region-code": "",
  },
  {
    name: "Luxembourg",
    "alpha-2": "LU",
    "alpha-3": "LUX",
    "country-code": "442",
    "iso_3166-2": "ISO 3166-2:LU",
    region: "Europe",
    "sub-region": "Western Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "155",
    "intermediate-region-code": "",
  },
  {
    name: "Macao",
    "alpha-2": "MO",
    "alpha-3": "MAC",
    "country-code": "446",
    "iso_3166-2": "ISO 3166-2:MO",
    region: "Asia",
    "sub-region": "Eastern Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "030",
    "intermediate-region-code": "",
  },
  {
    name: "Madagascar",
    "alpha-2": "MG",
    "alpha-3": "MDG",
    "country-code": "450",
    "iso_3166-2": "ISO 3166-2:MG",
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Eastern Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "014",
  },
  {
    name: "Malawi",
    "alpha-2": "MW",
    "alpha-3": "MWI",
    "country-code": "454",
    "iso_3166-2": "ISO 3166-2:MW",
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Eastern Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "014",
  },
  {
    name: "Malaysia",
    "alpha-2": "MY",
    "alpha-3": "MYS",
    "country-code": "458",
    "iso_3166-2": "ISO 3166-2:MY",
    region: "Asia",
    "sub-region": "South-eastern Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "035",
    "intermediate-region-code": "",
  },
  {
    name: "Maldives",
    "alpha-2": "MV",
    "alpha-3": "MDV",
    "country-code": "462",
    "iso_3166-2": "ISO 3166-2:MV",
    region: "Asia",
    "sub-region": "Southern Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "034",
    "intermediate-region-code": "",
  },
  {
    name: "Mali",
    "alpha-2": "ML",
    "alpha-3": "MLI",
    "country-code": "466",
    "iso_3166-2": "ISO 3166-2:ML",
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Western Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "011",
  },
  {
    name: "Malta",
    "alpha-2": "MT",
    "alpha-3": "MLT",
    "country-code": "470",
    "iso_3166-2": "ISO 3166-2:MT",
    region: "Europe",
    "sub-region": "Southern Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "039",
    "intermediate-region-code": "",
  },
  {
    name: "Marshall Islands",
    "alpha-2": "MH",
    "alpha-3": "MHL",
    "country-code": "584",
    "iso_3166-2": "ISO 3166-2:MH",
    region: "Oceania",
    "sub-region": "Micronesia",
    "intermediate-region": "",
    "region-code": "009",
    "sub-region-code": "057",
    "intermediate-region-code": "",
  },
  {
    name: "Martinique",
    "alpha-2": "MQ",
    "alpha-3": "MTQ",
    "country-code": "474",
    "iso_3166-2": "ISO 3166-2:MQ",
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "Caribbean",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "029",
  },
  {
    name: "Mauritania",
    "alpha-2": "MR",
    "alpha-3": "MRT",
    "country-code": "478",
    "iso_3166-2": "ISO 3166-2:MR",
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Western Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "011",
  },
  {
    name: "Mauritius",
    "alpha-2": "MU",
    "alpha-3": "MUS",
    "country-code": "480",
    "iso_3166-2": "ISO 3166-2:MU",
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Eastern Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "014",
  },
  {
    name: "Mayotte",
    "alpha-2": "YT",
    "alpha-3": "MYT",
    "country-code": "175",
    "iso_3166-2": "ISO 3166-2:YT",
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Eastern Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "014",
  },
  {
    name: "Mexico",
    "alpha-2": "MX",
    "alpha-3": "MEX",
    "country-code": "484",
    "iso_3166-2": "ISO 3166-2:MX",
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "Central America",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "013",
  },
  {
    name: "Micronesia, Federated States of",
    "alpha-2": "FM",
    "alpha-3": "FSM",
    "country-code": "583",
    "iso_3166-2": "ISO 3166-2:FM",
    region: "Oceania",
    "sub-region": "Micronesia",
    "intermediate-region": "",
    "region-code": "009",
    "sub-region-code": "057",
    "intermediate-region-code": "",
  },
  {
    name: "Moldova, Republic of",
    "alpha-2": "MD",
    "alpha-3": "MDA",
    "country-code": "498",
    "iso_3166-2": "ISO 3166-2:MD",
    region: "Europe",
    "sub-region": "Eastern Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "151",
    "intermediate-region-code": "",
  },
  {
    name: "Monaco",
    "alpha-2": "MC",
    "alpha-3": "MCO",
    "country-code": "492",
    "iso_3166-2": "ISO 3166-2:MC",
    region: "Europe",
    "sub-region": "Western Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "155",
    "intermediate-region-code": "",
  },
  {
    name: "Mongolia",
    "alpha-2": "MN",
    "alpha-3": "MNG",
    "country-code": "496",
    "iso_3166-2": "ISO 3166-2:MN",
    region: "Asia",
    "sub-region": "Eastern Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "030",
    "intermediate-region-code": "",
  },
  {
    name: "Montenegro",
    "alpha-2": "ME",
    "alpha-3": "MNE",
    "country-code": "499",
    "iso_3166-2": "ISO 3166-2:ME",
    region: "Europe",
    "sub-region": "Southern Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "039",
    "intermediate-region-code": "",
  },
  {
    name: "Montserrat",
    "alpha-2": "MS",
    "alpha-3": "MSR",
    "country-code": "500",
    "iso_3166-2": "ISO 3166-2:MS",
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "Caribbean",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "029",
  },
  {
    name: "Morocco",
    "alpha-2": "MA",
    "alpha-3": "MAR",
    "country-code": "504",
    "iso_3166-2": "ISO 3166-2:MA",
    region: "Africa",
    "sub-region": "Northern Africa",
    "intermediate-region": "",
    "region-code": "002",
    "sub-region-code": "015",
    "intermediate-region-code": "",
  },
  {
    name: "Mozambique",
    "alpha-2": "MZ",
    "alpha-3": "MOZ",
    "country-code": "508",
    "iso_3166-2": "ISO 3166-2:MZ",
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Eastern Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "014",
  },
  {
    name: "Myanmar",
    "alpha-2": "MM",
    "alpha-3": "MMR",
    "country-code": "104",
    "iso_3166-2": "ISO 3166-2:MM",
    region: "Asia",
    "sub-region": "South-eastern Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "035",
    "intermediate-region-code": "",
  },
  {
    name: "Namibia",
    "alpha-2": "NA",
    "alpha-3": "NAM",
    "country-code": "516",
    "iso_3166-2": "ISO 3166-2:NA",
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Southern Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "018",
  },
  {
    name: "Nauru",
    "alpha-2": "NR",
    "alpha-3": "NRU",
    "country-code": "520",
    "iso_3166-2": "ISO 3166-2:NR",
    region: "Oceania",
    "sub-region": "Micronesia",
    "intermediate-region": "",
    "region-code": "009",
    "sub-region-code": "057",
    "intermediate-region-code": "",
  },
  {
    name: "Nepal",
    "alpha-2": "NP",
    "alpha-3": "NPL",
    "country-code": "524",
    "iso_3166-2": "ISO 3166-2:NP",
    region: "Asia",
    "sub-region": "Southern Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "034",
    "intermediate-region-code": "",
  },
  {
    name: "Netherlands, Kingdom of the",
    "alpha-2": "NL",
    "alpha-3": "NLD",
    "country-code": "528",
    "iso_3166-2": "ISO 3166-2:NL",
    region: "Europe",
    "sub-region": "Western Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "155",
    "intermediate-region-code": "",
  },
  {
    name: "New Caledonia",
    "alpha-2": "NC",
    "alpha-3": "NCL",
    "country-code": "540",
    "iso_3166-2": "ISO 3166-2:NC",
    region: "Oceania",
    "sub-region": "Melanesia",
    "intermediate-region": "",
    "region-code": "009",
    "sub-region-code": "054",
    "intermediate-region-code": "",
  },
  {
    name: "New Zealand",
    "alpha-2": "NZ",
    "alpha-3": "NZL",
    "country-code": "554",
    "iso_3166-2": "ISO 3166-2:NZ",
    region: "Oceania",
    "sub-region": "Australia and New Zealand",
    "intermediate-region": "",
    "region-code": "009",
    "sub-region-code": "053",
    "intermediate-region-code": "",
  },
  {
    name: "Nicaragua",
    "alpha-2": "NI",
    "alpha-3": "NIC",
    "country-code": "558",
    "iso_3166-2": "ISO 3166-2:NI",
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "Central America",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "013",
  },
  {
    name: "Niger",
    "alpha-2": "NE",
    "alpha-3": "NER",
    "country-code": "562",
    "iso_3166-2": "ISO 3166-2:NE",
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Western Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "011",
  },
  {
    name: "Nigeria",
    "alpha-2": "NG",
    "alpha-3": "NGA",
    "country-code": "566",
    "iso_3166-2": "ISO 3166-2:NG",
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Western Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "011",
  },
  {
    name: "Niue",
    "alpha-2": "NU",
    "alpha-3": "NIU",
    "country-code": "570",
    "iso_3166-2": "ISO 3166-2:NU",
    region: "Oceania",
    "sub-region": "Polynesia",
    "intermediate-region": "",
    "region-code": "009",
    "sub-region-code": "061",
    "intermediate-region-code": "",
  },
  {
    name: "Norfolk Island",
    "alpha-2": "NF",
    "alpha-3": "NFK",
    "country-code": "574",
    "iso_3166-2": "ISO 3166-2:NF",
    region: "Oceania",
    "sub-region": "Australia and New Zealand",
    "intermediate-region": "",
    "region-code": "009",
    "sub-region-code": "053",
    "intermediate-region-code": "",
  },
  {
    name: "North Macedonia",
    "alpha-2": "MK",
    "alpha-3": "MKD",
    "country-code": "807",
    "iso_3166-2": "ISO 3166-2:MK",
    region: "Europe",
    "sub-region": "Southern Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "039",
    "intermediate-region-code": "",
  },
  {
    name: "Northern Mariana Islands",
    "alpha-2": "MP",
    "alpha-3": "MNP",
    "country-code": "580",
    "iso_3166-2": "ISO 3166-2:MP",
    region: "Oceania",
    "sub-region": "Micronesia",
    "intermediate-region": "",
    "region-code": "009",
    "sub-region-code": "057",
    "intermediate-region-code": "",
  },
  {
    name: "Norway",
    "alpha-2": "NO",
    "alpha-3": "NOR",
    "country-code": "578",
    "iso_3166-2": "ISO 3166-2:NO",
    region: "Europe",
    "sub-region": "Northern Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "154",
    "intermediate-region-code": "",
  },
  {
    name: "Oman",
    "alpha-2": "OM",
    "alpha-3": "OMN",
    "country-code": "512",
    "iso_3166-2": "ISO 3166-2:OM",
    region: "Asia",
    "sub-region": "Western Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "145",
    "intermediate-region-code": "",
  },
  {
    name: "Pakistan",
    "alpha-2": "PK",
    "alpha-3": "PAK",
    "country-code": "586",
    "iso_3166-2": "ISO 3166-2:PK",
    region: "Asia",
    "sub-region": "Southern Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "034",
    "intermediate-region-code": "",
  },
  {
    name: "Palau",
    "alpha-2": "PW",
    "alpha-3": "PLW",
    "country-code": "585",
    "iso_3166-2": "ISO 3166-2:PW",
    region: "Oceania",
    "sub-region": "Micronesia",
    "intermediate-region": "",
    "region-code": "009",
    "sub-region-code": "057",
    "intermediate-region-code": "",
  },
  {
    name: "Palestine, State of",
    "alpha-2": "PS",
    "alpha-3": "PSE",
    "country-code": "275",
    "iso_3166-2": "ISO 3166-2:PS",
    region: "Asia",
    "sub-region": "Western Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "145",
    "intermediate-region-code": "",
  },
  {
    name: "Panama",
    "alpha-2": "PA",
    "alpha-3": "PAN",
    "country-code": "591",
    "iso_3166-2": "ISO 3166-2:PA",
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "Central America",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "013",
  },
  {
    name: "Papua New Guinea",
    "alpha-2": "PG",
    "alpha-3": "PNG",
    "country-code": "598",
    "iso_3166-2": "ISO 3166-2:PG",
    region: "Oceania",
    "sub-region": "Melanesia",
    "intermediate-region": "",
    "region-code": "009",
    "sub-region-code": "054",
    "intermediate-region-code": "",
  },
  {
    name: "Paraguay",
    "alpha-2": "PY",
    "alpha-3": "PRY",
    "country-code": "600",
    "iso_3166-2": "ISO 3166-2:PY",
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "South America",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "005",
  },
  {
    name: "Peru",
    "alpha-2": "PE",
    "alpha-3": "PER",
    "country-code": "604",
    "iso_3166-2": "ISO 3166-2:PE",
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "South America",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "005",
  },
  {
    name: "Philippines",
    "alpha-2": "PH",
    "alpha-3": "PHL",
    "country-code": "608",
    "iso_3166-2": "ISO 3166-2:PH",
    region: "Asia",
    "sub-region": "South-eastern Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "035",
    "intermediate-region-code": "",
  },
  {
    name: "Pitcairn",
    "alpha-2": "PN",
    "alpha-3": "PCN",
    "country-code": "612",
    "iso_3166-2": "ISO 3166-2:PN",
    region: "Oceania",
    "sub-region": "Polynesia",
    "intermediate-region": "",
    "region-code": "009",
    "sub-region-code": "061",
    "intermediate-region-code": "",
  },
  {
    name: "Poland",
    "alpha-2": "PL",
    "alpha-3": "POL",
    "country-code": "616",
    "iso_3166-2": "ISO 3166-2:PL",
    region: "Europe",
    "sub-region": "Eastern Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "151",
    "intermediate-region-code": "",
  },
  {
    name: "Portugal",
    "alpha-2": "PT",
    "alpha-3": "PRT",
    "country-code": "620",
    "iso_3166-2": "ISO 3166-2:PT",
    region: "Europe",
    "sub-region": "Southern Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "039",
    "intermediate-region-code": "",
  },
  {
    name: "Puerto Rico",
    "alpha-2": "PR",
    "alpha-3": "PRI",
    "country-code": "630",
    "iso_3166-2": "ISO 3166-2:PR",
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "Caribbean",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "029",
  },
  {
    name: "Qatar",
    "alpha-2": "QA",
    "alpha-3": "QAT",
    "country-code": "634",
    "iso_3166-2": "ISO 3166-2:QA",
    region: "Asia",
    "sub-region": "Western Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "145",
    "intermediate-region-code": "",
  },
  {
    name: "Réunion",
    "alpha-2": "RE",
    "alpha-3": "REU",
    "country-code": "638",
    "iso_3166-2": "ISO 3166-2:RE",
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Eastern Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "014",
  },
  {
    name: "Romania",
    "alpha-2": "RO",
    "alpha-3": "ROU",
    "country-code": "642",
    "iso_3166-2": "ISO 3166-2:RO",
    region: "Europe",
    "sub-region": "Eastern Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "151",
    "intermediate-region-code": "",
  },
  {
    name: "Russian Federation",
    "alpha-2": "RU",
    "alpha-3": "RUS",
    "country-code": "643",
    "iso_3166-2": "ISO 3166-2:RU",
    region: "Europe",
    "sub-region": "Eastern Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "151",
    "intermediate-region-code": "",
  },
  {
    name: "Rwanda",
    "alpha-2": "RW",
    "alpha-3": "RWA",
    "country-code": "646",
    "iso_3166-2": "ISO 3166-2:RW",
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Eastern Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "014",
  },
  {
    name: "Saint Barthélemy",
    "alpha-2": "BL",
    "alpha-3": "BLM",
    "country-code": "652",
    "iso_3166-2": "ISO 3166-2:BL",
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "Caribbean",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "029",
  },
  {
    name: "Saint Helena, Ascension and Tristan da Cunha",
    "alpha-2": "SH",
    "alpha-3": "SHN",
    "country-code": "654",
    "iso_3166-2": "ISO 3166-2:SH",
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Western Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "011",
  },
  {
    name: "Saint Kitts and Nevis",
    "alpha-2": "KN",
    "alpha-3": "KNA",
    "country-code": "659",
    "iso_3166-2": "ISO 3166-2:KN",
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "Caribbean",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "029",
  },
  {
    name: "Saint Lucia",
    "alpha-2": "LC",
    "alpha-3": "LCA",
    "country-code": "662",
    "iso_3166-2": "ISO 3166-2:LC",
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "Caribbean",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "029",
  },
  {
    name: "Saint Martin (French part)",
    "alpha-2": "MF",
    "alpha-3": "MAF",
    "country-code": "663",
    "iso_3166-2": "ISO 3166-2:MF",
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "Caribbean",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "029",
  },
  {
    name: "Saint Pierre and Miquelon",
    "alpha-2": "PM",
    "alpha-3": "SPM",
    "country-code": "666",
    "iso_3166-2": "ISO 3166-2:PM",
    region: "Americas",
    "sub-region": "Northern America",
    "intermediate-region": "",
    "region-code": "019",
    "sub-region-code": "021",
    "intermediate-region-code": "",
  },
  {
    name: "Saint Vincent and the Grenadines",
    "alpha-2": "VC",
    "alpha-3": "VCT",
    "country-code": "670",
    "iso_3166-2": "ISO 3166-2:VC",
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "Caribbean",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "029",
  },
  {
    name: "Samoa",
    "alpha-2": "WS",
    "alpha-3": "WSM",
    "country-code": "882",
    "iso_3166-2": "ISO 3166-2:WS",
    region: "Oceania",
    "sub-region": "Polynesia",
    "intermediate-region": "",
    "region-code": "009",
    "sub-region-code": "061",
    "intermediate-region-code": "",
  },
  {
    name: "San Marino",
    "alpha-2": "SM",
    "alpha-3": "SMR",
    "country-code": "674",
    "iso_3166-2": "ISO 3166-2:SM",
    region: "Europe",
    "sub-region": "Southern Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "039",
    "intermediate-region-code": "",
  },
  {
    name: "Sao Tome and Principe",
    "alpha-2": "ST",
    "alpha-3": "STP",
    "country-code": "678",
    "iso_3166-2": "ISO 3166-2:ST",
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Middle Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "017",
  },
  {
    name: "Saudi Arabia",
    "alpha-2": "SA",
    "alpha-3": "SAU",
    "country-code": "682",
    "iso_3166-2": "ISO 3166-2:SA",
    region: "Asia",
    "sub-region": "Western Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "145",
    "intermediate-region-code": "",
  },
  {
    name: "Senegal",
    "alpha-2": "SN",
    "alpha-3": "SEN",
    "country-code": "686",
    "iso_3166-2": "ISO 3166-2:SN",
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Western Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "011",
  },
  {
    name: "Serbia",
    "alpha-2": "RS",
    "alpha-3": "SRB",
    "country-code": "688",
    "iso_3166-2": "ISO 3166-2:RS",
    region: "Europe",
    "sub-region": "Southern Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "039",
    "intermediate-region-code": "",
  },
  {
    name: "Seychelles",
    "alpha-2": "SC",
    "alpha-3": "SYC",
    "country-code": "690",
    "iso_3166-2": "ISO 3166-2:SC",
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Eastern Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "014",
  },
  {
    name: "Sierra Leone",
    "alpha-2": "SL",
    "alpha-3": "SLE",
    "country-code": "694",
    "iso_3166-2": "ISO 3166-2:SL",
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Western Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "011",
  },
  {
    name: "Singapore",
    "alpha-2": "SG",
    "alpha-3": "SGP",
    "country-code": "702",
    "iso_3166-2": "ISO 3166-2:SG",
    region: "Asia",
    "sub-region": "South-eastern Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "035",
    "intermediate-region-code": "",
  },
  {
    name: "Sint Maarten (Dutch part)",
    "alpha-2": "SX",
    "alpha-3": "SXM",
    "country-code": "534",
    "iso_3166-2": "ISO 3166-2:SX",
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "Caribbean",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "029",
  },
  {
    name: "Slovakia",
    "alpha-2": "SK",
    "alpha-3": "SVK",
    "country-code": "703",
    "iso_3166-2": "ISO 3166-2:SK",
    region: "Europe",
    "sub-region": "Eastern Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "151",
    "intermediate-region-code": "",
  },
  {
    name: "Slovenia",
    "alpha-2": "SI",
    "alpha-3": "SVN",
    "country-code": "705",
    "iso_3166-2": "ISO 3166-2:SI",
    region: "Europe",
    "sub-region": "Southern Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "039",
    "intermediate-region-code": "",
  },
  {
    name: "Solomon Islands",
    "alpha-2": "SB",
    "alpha-3": "SLB",
    "country-code": "090",
    "iso_3166-2": "ISO 3166-2:SB",
    region: "Oceania",
    "sub-region": "Melanesia",
    "intermediate-region": "",
    "region-code": "009",
    "sub-region-code": "054",
    "intermediate-region-code": "",
  },
  {
    name: "Somalia",
    "alpha-2": "SO",
    "alpha-3": "SOM",
    "country-code": "706",
    "iso_3166-2": "ISO 3166-2:SO",
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Eastern Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "014",
  },
  {
    name: "South Africa",
    "alpha-2": "ZA",
    "alpha-3": "ZAF",
    "country-code": "710",
    "iso_3166-2": "ISO 3166-2:ZA",
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Southern Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "018",
  },
  {
    name: "South Georgia and the South Sandwich Islands",
    "alpha-2": "GS",
    "alpha-3": "SGS",
    "country-code": "239",
    "iso_3166-2": "ISO 3166-2:GS",
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "South America",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "005",
  },
  {
    name: "South Sudan",
    "alpha-2": "SS",
    "alpha-3": "SSD",
    "country-code": "728",
    "iso_3166-2": "ISO 3166-2:SS",
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Eastern Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "014",
  },
  {
    name: "Spain",
    "alpha-2": "ES",
    "alpha-3": "ESP",
    "country-code": "724",
    "iso_3166-2": "ISO 3166-2:ES",
    region: "Europe",
    "sub-region": "Southern Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "039",
    "intermediate-region-code": "",
  },
  {
    name: "Sri Lanka",
    "alpha-2": "LK",
    "alpha-3": "LKA",
    "country-code": "144",
    "iso_3166-2": "ISO 3166-2:LK",
    region: "Asia",
    "sub-region": "Southern Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "034",
    "intermediate-region-code": "",
  },
  {
    name: "Sudan",
    "alpha-2": "SD",
    "alpha-3": "SDN",
    "country-code": "729",
    "iso_3166-2": "ISO 3166-2:SD",
    region: "Africa",
    "sub-region": "Northern Africa",
    "intermediate-region": "",
    "region-code": "002",
    "sub-region-code": "015",
    "intermediate-region-code": "",
  },
  {
    name: "Suriname",
    "alpha-2": "SR",
    "alpha-3": "SUR",
    "country-code": "740",
    "iso_3166-2": "ISO 3166-2:SR",
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "South America",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "005",
  },
  {
    name: "Svalbard and Jan Mayen",
    "alpha-2": "SJ",
    "alpha-3": "SJM",
    "country-code": "744",
    "iso_3166-2": "ISO 3166-2:SJ",
    region: "Europe",
    "sub-region": "Northern Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "154",
    "intermediate-region-code": "",
  },
  {
    name: "Sweden",
    "alpha-2": "SE",
    "alpha-3": "SWE",
    "country-code": "752",
    "iso_3166-2": "ISO 3166-2:SE",
    region: "Europe",
    "sub-region": "Northern Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "154",
    "intermediate-region-code": "",
  },
  {
    name: "Switzerland",
    "alpha-2": "CH",
    "alpha-3": "CHE",
    "country-code": "756",
    "iso_3166-2": "ISO 3166-2:CH",
    region: "Europe",
    "sub-region": "Western Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "155",
    "intermediate-region-code": "",
  },
  {
    name: "Syrian Arab Republic",
    "alpha-2": "SY",
    "alpha-3": "SYR",
    "country-code": "760",
    "iso_3166-2": "ISO 3166-2:SY",
    region: "Asia",
    "sub-region": "Western Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "145",
    "intermediate-region-code": "",
  },
  {
    name: "Taiwan, Province of China",
    "alpha-2": "TW",
    "alpha-3": "TWN",
    "country-code": "158",
    "iso_3166-2": "ISO 3166-2:TW",
    region: null,
    "sub-region": null,
    "intermediate-region": null,
    "region-code": null,
    "sub-region-code": null,
    "intermediate-region-code": null,
  },
  {
    name: "Tajikistan",
    "alpha-2": "TJ",
    "alpha-3": "TJK",
    "country-code": "762",
    "iso_3166-2": "ISO 3166-2:TJ",
    region: "Asia",
    "sub-region": "Central Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "143",
    "intermediate-region-code": "",
  },
  {
    name: "Tanzania, United Republic of",
    "alpha-2": "TZ",
    "alpha-3": "TZA",
    "country-code": "834",
    "iso_3166-2": "ISO 3166-2:TZ",
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Eastern Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "014",
  },
  {
    name: "Thailand",
    "alpha-2": "TH",
    "alpha-3": "THA",
    "country-code": "764",
    "iso_3166-2": "ISO 3166-2:TH",
    region: "Asia",
    "sub-region": "South-eastern Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "035",
    "intermediate-region-code": "",
  },
  {
    name: "Timor-Leste",
    "alpha-2": "TL",
    "alpha-3": "TLS",
    "country-code": "626",
    "iso_3166-2": "ISO 3166-2:TL",
    region: "Asia",
    "sub-region": "South-eastern Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "035",
    "intermediate-region-code": "",
  },
  {
    name: "Togo",
    "alpha-2": "TG",
    "alpha-3": "TGO",
    "country-code": "768",
    "iso_3166-2": "ISO 3166-2:TG",
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Western Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "011",
  },
  {
    name: "Tokelau",
    "alpha-2": "TK",
    "alpha-3": "TKL",
    "country-code": "772",
    "iso_3166-2": "ISO 3166-2:TK",
    region: "Oceania",
    "sub-region": "Polynesia",
    "intermediate-region": "",
    "region-code": "009",
    "sub-region-code": "061",
    "intermediate-region-code": "",
  },
  {
    name: "Tonga",
    "alpha-2": "TO",
    "alpha-3": "TON",
    "country-code": "776",
    "iso_3166-2": "ISO 3166-2:TO",
    region: "Oceania",
    "sub-region": "Polynesia",
    "intermediate-region": "",
    "region-code": "009",
    "sub-region-code": "061",
    "intermediate-region-code": "",
  },
  {
    name: "Trinidad and Tobago",
    "alpha-2": "TT",
    "alpha-3": "TTO",
    "country-code": "780",
    "iso_3166-2": "ISO 3166-2:TT",
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "Caribbean",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "029",
  },
  {
    name: "Tunisia",
    "alpha-2": "TN",
    "alpha-3": "TUN",
    "country-code": "788",
    "iso_3166-2": "ISO 3166-2:TN",
    region: "Africa",
    "sub-region": "Northern Africa",
    "intermediate-region": "",
    "region-code": "002",
    "sub-region-code": "015",
    "intermediate-region-code": "",
  },
  {
    name: "Türkiye",
    "alpha-2": "TR",
    "alpha-3": "TUR",
    "country-code": "792",
    "iso_3166-2": "ISO 3166-2:TR",
    region: "Asia",
    "sub-region": "Western Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "145",
    "intermediate-region-code": "",
  },
  {
    name: "Turkmenistan",
    "alpha-2": "TM",
    "alpha-3": "TKM",
    "country-code": "795",
    "iso_3166-2": "ISO 3166-2:TM",
    region: "Asia",
    "sub-region": "Central Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "143",
    "intermediate-region-code": "",
  },
  {
    name: "Turks and Caicos Islands",
    "alpha-2": "TC",
    "alpha-3": "TCA",
    "country-code": "796",
    "iso_3166-2": "ISO 3166-2:TC",
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "Caribbean",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "029",
  },
  {
    name: "Tuvalu",
    "alpha-2": "TV",
    "alpha-3": "TUV",
    "country-code": "798",
    "iso_3166-2": "ISO 3166-2:TV",
    region: "Oceania",
    "sub-region": "Polynesia",
    "intermediate-region": "",
    "region-code": "009",
    "sub-region-code": "061",
    "intermediate-region-code": "",
  },
  {
    name: "Uganda",
    "alpha-2": "UG",
    "alpha-3": "UGA",
    "country-code": "800",
    "iso_3166-2": "ISO 3166-2:UG",
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Eastern Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "014",
  },
  {
    name: "Ukraine",
    "alpha-2": "UA",
    "alpha-3": "UKR",
    "country-code": "804",
    "iso_3166-2": "ISO 3166-2:UA",
    region: "Europe",
    "sub-region": "Eastern Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "151",
    "intermediate-region-code": "",
  },
  {
    name: "United Arab Emirates",
    "alpha-2": "AE",
    "alpha-3": "ARE",
    "country-code": "784",
    "iso_3166-2": "ISO 3166-2:AE",
    region: "Asia",
    "sub-region": "Western Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "145",
    "intermediate-region-code": "",
  },
  {
    name: "United Kingdom of Great Britain and Northern Ireland",
    "alpha-2": "GB",
    "alpha-3": "GBR",
    "country-code": "826",
    "iso_3166-2": "ISO 3166-2:GB",
    region: "Europe",
    "sub-region": "Northern Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "154",
    "intermediate-region-code": "",
  },
  {
    name: "United States of America",
    "alpha-2": "US",
    "alpha-3": "USA",
    "country-code": "840",
    "iso_3166-2": "ISO 3166-2:US",
    region: "Americas",
    "sub-region": "Northern America",
    "intermediate-region": "",
    "region-code": "019",
    "sub-region-code": "021",
    "intermediate-region-code": "",
  },
  {
    name: "United States Minor Outlying Islands",
    "alpha-2": "UM",
    "alpha-3": "UMI",
    "country-code": "581",
    "iso_3166-2": "ISO 3166-2:UM",
    region: "Oceania",
    "sub-region": "Micronesia",
    "intermediate-region": "",
    "region-code": "009",
    "sub-region-code": "057",
    "intermediate-region-code": "",
  },
  {
    name: "Uruguay",
    "alpha-2": "UY",
    "alpha-3": "URY",
    "country-code": "858",
    "iso_3166-2": "ISO 3166-2:UY",
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "South America",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "005",
  },
  {
    name: "Uzbekistan",
    "alpha-2": "UZ",
    "alpha-3": "UZB",
    "country-code": "860",
    "iso_3166-2": "ISO 3166-2:UZ",
    region: "Asia",
    "sub-region": "Central Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "143",
    "intermediate-region-code": "",
  },
  {
    name: "Vanuatu",
    "alpha-2": "VU",
    "alpha-3": "VUT",
    "country-code": "548",
    "iso_3166-2": "ISO 3166-2:VU",
    region: "Oceania",
    "sub-region": "Melanesia",
    "intermediate-region": "",
    "region-code": "009",
    "sub-region-code": "054",
    "intermediate-region-code": "",
  },
  {
    name: "Venezuela, Bolivarian Republic of",
    "alpha-2": "VE",
    "alpha-3": "VEN",
    "country-code": "862",
    "iso_3166-2": "ISO 3166-2:VE",
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "South America",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "005",
  },
  {
    name: "Viet Nam",
    "alpha-2": "VN",
    "alpha-3": "VNM",
    "country-code": "704",
    "iso_3166-2": "ISO 3166-2:VN",
    region: "Asia",
    "sub-region": "South-eastern Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "035",
    "intermediate-region-code": "",
  },
  {
    name: "Virgin Islands (British)",
    "alpha-2": "VG",
    "alpha-3": "VGB",
    "country-code": "092",
    "iso_3166-2": "ISO 3166-2:VG",
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "Caribbean",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "029",
  },
  {
    name: "Virgin Islands (U.S.)",
    "alpha-2": "VI",
    "alpha-3": "VIR",
    "country-code": "850",
    "iso_3166-2": "ISO 3166-2:VI",
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "Caribbean",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "029",
  },
  {
    name: "Wallis and Futuna",
    "alpha-2": "WF",
    "alpha-3": "WLF",
    "country-code": "876",
    "iso_3166-2": "ISO 3166-2:WF",
    region: "Oceania",
    "sub-region": "Polynesia",
    "intermediate-region": "",
    "region-code": "009",
    "sub-region-code": "061",
    "intermediate-region-code": "",
  },
  {
    name: "Western Sahara",
    "alpha-2": "EH",
    "alpha-3": "ESH",
    "country-code": "732",
    "iso_3166-2": "ISO 3166-2:EH",
    region: "Africa",
    "sub-region": "Northern Africa",
    "intermediate-region": "",
    "region-code": "002",
    "sub-region-code": "015",
    "intermediate-region-code": "",
  },
  {
    name: "Yemen",
    "alpha-2": "YE",
    "alpha-3": "YEM",
    "country-code": "887",
    "iso_3166-2": "ISO 3166-2:YE",
    region: "Asia",
    "sub-region": "Western Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "145",
    "intermediate-region-code": "",
  },
  {
    name: "Zambia",
    "alpha-2": "ZM",
    "alpha-3": "ZMB",
    "country-code": "894",
    "iso_3166-2": "ISO 3166-2:ZM",
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Eastern Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "014",
  },
  {
    name: "Zimbabwe",
    "alpha-2": "ZW",
    "alpha-3": "ZWE",
    "country-code": "716",
    "iso_3166-2": "ISO 3166-2:ZW",
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Eastern Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "014",
  },
];

export const countryCodes = [
  { iso2: "BO", iso3: "BOL", name: "Bolivia", code: "591" },
  { iso2: "BZ", iso3: "BLZ", name: "Belize", code: "501" },
  { iso2: "GT", iso3: "GTM", name: "Guatemala", code: "502" },
  { iso2: "SV", iso3: "SLV", name: "El Salvador", code: "503" },
  { iso2: "HN", iso3: "HND", name: "Honduras", code: "504" },
  { iso2: "VE", iso3: "VEN", name: "Venezuela", code: "58" },
  { iso2: "PY", iso3: "PRY", name: "Paraguay", code: "595" },
  { iso2: "PA", iso3: "PAN", name: "Panama", code: "507" },
  { iso2: "PE", iso3: "PER", name: "Peru", code: "51" },
  { iso2: "EC", iso3: "ECU", name: "Ecuador", code: "593" },
  { iso2: "MX", iso3: "MEX", name: "Mexico", code: "52" },
  { iso2: "NI", iso3: "NIC", name: "Nicaragua", code: "505" },
  { iso2: "CO", iso3: "COL", name: "Colombia", code: "57" },
  { iso2: "CL", iso3: "CHL", name: "Chile", code: "56" },
  { iso2: "CA", iso3: "CAN", name: "Canada", code: "1" },
  { iso2: "CR", iso3: "CRI", name: "Costa Rica", code: "506" },
  { iso2: "DO", iso3: "DOM", name: "Dominican Republic", code: "1809" },
  { iso2: "DO", iso3: "DOM", name: "Dominican Republic", code: "1829" },
  { iso2: "US", iso3: "USA", name: "United States", code: "1" },
  { iso2: "UY", iso3: "URY", name: "Uruguay", code: "598" },
  { iso2: "AR", iso3: "ARG", name: "Argentina", code: "54" },
  { iso2: "BY", iso3: "BLR", name: "Belarus", code: "375" },
];

export const sortedCountryCodesData = [
  { label: "United States (1)", value: { iso2: "US", code: "1" } },
  ...countryCodes
    .map((item) => ({ label: `${item.name} (${item.code})`, value: { iso2: item.iso2, code: item.code } }))
    .filter((item: any) => item.label !== "United States (1)")
    .sort((a: any, b: any) => {
      if (a.label > b.label) return 1;
      if (a.label < b.label) return -1;
      else return 0;
    }),
];

/**
 * Retrieves additional country codes based on a provided list of country ISO codes.
 *
 * This function filters the full list of country codes (countryCodesAll) to include only
 * those countries specified in the input array (allCountries). Input is primary_phone_number_country_code values.
 *  It excludes the United States and any countries already present in the main countryCodes array.
 * The resulting list is sorted alphabetically by country name.
 *
 * @param {string[]} allCountries - An array of country values. All valid ISO2 codes will be returned.
 * @returns {Array<{label: string, value: {iso2: string, code: string}}>} An array of objects
 *          containing the country name, dialing code, and ISO code for each additional country.
 */

export const getExtraCountryCodes = (allCountries: string[]) => {
  return (
    countryCodesAll
      .filter((item) => allCountries.includes(item?.code))
      .filter((item) => item.iso2 !== "US")
      .filter((item) => !countryCodes.some((country) => country.iso2 === item.iso2))
      .map((item) => ({ label: `${item.name} (${item.code})`, value: { iso2: item.iso2, code: item.code } }))
      .sort((a: any, b: any) => {
        if (a.label > b.label) return 1;
        if (a.label < b.label) return -1;
        else return 0;
      }) || []
  );
};

export const findCountryWithCode = (code: string) => {
  const country = countryCodesAll?.map((item) => item)?.filter((item: any) => item.iso2 === code);
  if (!country || !country.length) {
    return undefined;
  }
  return !!country[0].name ? country[0].name : undefined;
};

export const findStateCode = (name: string, country: string = "US") => {
  let data = statesUSA;
  if (country === "CA") {
    data = provincesCanada;
  }

  const state = data?.map((item) => item)?.filter((item: any) => item.state_code.toLowerCase() === name.toLowerCase());
  if (!state || !state.length) {
    return "";
  }
  return !!state[0].name ? state[0].name : "";
};

export const provincesMexico = [
  {
    id: 3456,
    name: "Aguascalientes",
    state_code: "AGU",
  },
  {
    id: 3457,
    name: "Baja California",
    state_code: "BCN",
  },
  {
    id: 3460,
    name: "Baja California Sur",
    state_code: "BCS",
  },
  {
    id: 3475,
    name: "Campeche",
    state_code: "CAM",
  },
  {
    id: 3451,
    name: "Chiapas",
    state_code: "CHP",
  },
  {
    id: 3447,
    name: "Chihuahua",
    state_code: "CHH",
  },
  {
    id: 3471,
    name: "Coahuila",
    state_code: "COA",
  },
  {
    id: 3472,
    name: "Colima",
    state_code: "COL",
  },
  {
    id: 3453,
    name: "Durango",
    state_code: "DUR",
  },
  {
    id: 3469,
    name: "Guanajuato",
    state_code: "GUA",
  },
  {
    id: 3459,
    name: "Guerrero",
    state_code: "GRO",
  },
  {
    id: 3470,
    name: "Hidalgo",
    state_code: "HID",
  },
  {
    id: 4857,
    name: "Jalisco",
    state_code: "JAL",
  },
  {
    id: 3450,
    name: "México",
    state_code: "MEX",
  },
  {
    id: 3473,
    name: "Mexico City",
    state_code: "CMX",
  },
  {
    id: 3474,
    name: "Michoacán",
    state_code: "MIC",
  },
  {
    id: 3465,
    name: "Morelos",
    state_code: "MOR",
  },
  {
    id: 3477,
    name: "Nayarit",
    state_code: "NAY",
  },
  {
    id: 3452,
    name: "Nuevo León",
    state_code: "NLE",
  },
  {
    id: 3448,
    name: "Oaxaca",
    state_code: "OAX",
  },
  {
    id: 3476,
    name: "Puebla",
    state_code: "PUE",
  },
  {
    id: 3455,
    name: "Querétaro",
    state_code: "QUE",
  },
  {
    id: 3467,
    name: "Quintana Roo",
    state_code: "ROO",
  },
  {
    id: 3461,
    name: "San Luis Potosí",
    state_code: "SLP",
  },
  {
    id: 3449,
    name: "Sinaloa",
    state_code: "SIN",
  },
  {
    id: 3468,
    name: "Sonora",
    state_code: "SON",
  },
  {
    id: 3454,
    name: "Tabasco",
    state_code: "TAB",
  },
  {
    id: 3463,
    name: "Tamaulipas",
    state_code: "TAM",
  },
  {
    id: 3458,
    name: "Tlaxcala",
    state_code: "TLA",
  },
  {
    id: 3464,
    name: "Veracruz",
    state_code: "VER",
  },
  {
    id: 3466,
    name: "Yucatán",
    state_code: "YUC",
  },
  {
    id: 3462,
    name: "Zacatecas",
    state_code: "ZAC",
  },
];

export const provincesBrazil = [
  {
    id: 2012,
    name: "Acre",
    state_code: "AC",
  },
  {
    id: 2007,
    name: "Alagoas",
    state_code: "AL",
  },
  {
    id: 1999,
    name: "Amapá",
    state_code: "AP",
  },
  {
    id: 2004,
    name: "Amazonas",
    state_code: "AM",
  },
  {
    id: 2002,
    name: "Bahia",
    state_code: "BA",
  },
  {
    id: 2016,
    name: "Ceará",
    state_code: "CE",
  },
  {
    id: 2018,
    name: "Espírito Santo",
    state_code: "ES",
  },
  {
    id: 2017,
    name: "Federal District",
    state_code: "DF",
  },
  {
    id: 2000,
    name: "Goiás",
    state_code: "GO",
  },
  {
    id: 2015,
    name: "Maranhão",
    state_code: "MA",
  },
  {
    id: 2011,
    name: "Mato Grosso",
    state_code: "MT",
  },
  {
    id: 2010,
    name: "Mato Grosso do Sul",
    state_code: "MS",
  },
  {
    id: 1998,
    name: "Minas Gerais",
    state_code: "MG",
  },
  {
    id: 2009,
    name: "Pará",
    state_code: "PA",
  },
  {
    id: 2005,
    name: "Paraíba",
    state_code: "PB",
  },
  {
    id: 2022,
    name: "Paraná",
    state_code: "PR",
  },
  {
    id: 2006,
    name: "Pernambuco",
    state_code: "PE",
  },
  {
    id: 2008,
    name: "Piauí",
    state_code: "PI",
  },
  {
    id: 1997,
    name: "Rio de Janeiro",
    state_code: "RJ",
  },
  {
    id: 2019,
    name: "Rio Grande do Norte",
    state_code: "RN",
  },
  {
    id: 2001,
    name: "Rio Grande do Sul",
    state_code: "RS",
  },
  {
    id: 2013,
    name: "Rondônia",
    state_code: "RO",
  },
  {
    id: 4858,
    name: "Roraima",
    state_code: "RR",
  },
  {
    id: 2014,
    name: "Santa Catarina",
    state_code: "SC",
  },
  {
    id: 2021,
    name: "São Paulo",
    state_code: "SP",
  },
  {
    id: 2003,
    name: "Sergipe",
    state_code: "SE",
  },
  {
    id: 2020,
    name: "Tocantins",
    state_code: "TO",
  },
];

export const provincesCanada = [
  { id: 231, name: "British Columbia", state_code: "BC" },
  { id: 232, name: "Alberta", state_code: "AB" },
  { id: 233, name: "Northwest Territories", state_code: "NT" },
  { id: 234, name: "Yukon", state_code: "YT" },
  { id: 235, name: "Manitoba", state_code: "MB" },
  { id: 236, name: "Saskatchewan", state_code: "SK" },
  { id: 237, name: "Ontario", state_code: "ON" },
  { id: 238, name: "Quebec", state_code: "QC" },
  { id: 239, name: "New Brunswick", state_code: "NB" },
  { id: 240, name: "Nova Scotia", state_code: "NS" },
  { id: 241, name: "Newfoundland", state_code: "NF" },
];

export const statesUSA = [
  {
    id: 1456,
    name: "Alabama",
    state_code: "AL",
  },
  {
    id: 1400,
    name: "Alaska",
    state_code: "AK",
  },
  {
    id: 1424,
    name: "American Samoa",
    state_code: "AS",
  },
  {
    id: 1434,
    name: "Arizona",
    state_code: "AZ",
  },
  {
    id: 1444,
    name: "Arkansas",
    state_code: "AR",
  },
  {
    id: 1402,
    name: "Baker Island",
    state_code: "UM-81",
  },
  {
    id: 1416,
    name: "California",
    state_code: "CA",
  },
  {
    id: 1450,
    name: "Colorado",
    state_code: "CO",
  },
  {
    id: 1435,
    name: "Connecticut",
    state_code: "CT",
  },
  {
    id: 1399,
    name: "Delaware",
    state_code: "DE",
  },
  {
    id: 1437,
    name: "District of Columbia",
    state_code: "DC",
  },
  {
    id: 1436,
    name: "Florida",
    state_code: "FL",
  },
  {
    id: 1455,
    name: "Georgia",
    state_code: "GA",
  },
  {
    id: 1412,
    name: "Guam",
    state_code: "GU",
  },
  {
    id: 1411,
    name: "Hawaii",
    state_code: "HI",
  },
  {
    id: 1398,
    name: "Howland Island",
    state_code: "UM-84",
  },
  {
    id: 1460,
    name: "Idaho",
    state_code: "ID",
  },
  {
    id: 1425,
    name: "Illinois",
    state_code: "IL",
  },
  {
    id: 1440,
    name: "Indiana",
    state_code: "IN",
  },
  {
    id: 1459,
    name: "Iowa",
    state_code: "IA",
  },
  {
    id: 1410,
    name: "Jarvis Island",
    state_code: "UM-86",
  },
  {
    id: 1428,
    name: "Johnston Atoll",
    state_code: "UM-67",
  },
  {
    id: 1406,
    name: "Kansas",
    state_code: "KS",
  },
  {
    id: 1419,
    name: "Kentucky",
    state_code: "KY",
  },
  {
    id: 1403,
    name: "Kingman Reef",
    state_code: "UM-89",
  },
  {
    id: 1457,
    name: "Louisiana",
    state_code: "LA",
  },
  {
    id: 1453,
    name: "Maine",
    state_code: "ME",
  },
  {
    id: 1401,
    name: "Maryland",
    state_code: "MD",
  },
  {
    id: 1433,
    name: "Massachusetts",
    state_code: "MA",
  },
  {
    id: 1426,
    name: "Michigan",
    state_code: "MI",
  },
  {
    id: 1438,
    name: "Midway Atoll",
    state_code: "UM-71",
  },
  {
    id: 1420,
    name: "Minnesota",
    state_code: "MN",
  },
  {
    id: 1430,
    name: "Mississippi",
    state_code: "MS",
  },
  {
    id: 1451,
    name: "Missouri",
    state_code: "MO",
  },
  {
    id: 1446,
    name: "Montana",
    state_code: "MT",
  },
  {
    id: 1439,
    name: "Navassa Island",
    state_code: "UM-76",
  },
  {
    id: 1408,
    name: "Nebraska",
    state_code: "NE",
  },
  {
    id: 1458,
    name: "Nevada",
    state_code: "NV",
  },
  {
    id: 1404,
    name: "New Hampshire",
    state_code: "NH",
  },
  {
    id: 1417,
    name: "New Jersey",
    state_code: "NJ",
  },
  {
    id: 1423,
    name: "New Mexico",
    state_code: "NM",
  },
  {
    id: 1452,
    name: "New York",
    state_code: "NY",
  },
  {
    id: 1447,
    name: "North Carolina",
    state_code: "NC",
  },
  {
    id: 1418,
    name: "North Dakota",
    state_code: "ND",
  },
  {
    id: 1431,
    name: "Northern Mariana Islands",
    state_code: "MP",
  },
  {
    id: 4851,
    name: "Ohio",
    state_code: "OH",
  },
  {
    id: 1421,
    name: "Oklahoma",
    state_code: "OK",
  },
  {
    id: 1415,
    name: "Oregon",
    state_code: "OR",
  },
  {
    id: 1448,
    name: "Palmyra Atoll",
    state_code: "UM-95",
  },
  {
    id: 1422,
    name: "Pennsylvania",
    state_code: "PA",
  },
  {
    id: 1449,
    name: "Puerto Rico",
    state_code: "PR",
  },
  {
    id: 1461,
    name: "Rhode Island",
    state_code: "RI",
  },
  {
    id: 1443,
    name: "South Carolina",
    state_code: "SC",
  },
  {
    id: 1445,
    name: "South Dakota",
    state_code: "SD",
  },
  {
    id: 1454,
    name: "Tennessee",
    state_code: "TN",
  },
  {
    id: 1407,
    name: "Texas",
    state_code: "TX",
  },
  {
    id: 1432,
    name: "United States Minor Outlying Islands",
    state_code: "UM",
  },
  {
    id: 1413,
    name: "United States Virgin Islands",
    state_code: "VI",
  },
  {
    id: 1414,
    name: "Utah",
    state_code: "UT",
  },
  {
    id: 1409,
    name: "Vermont",
    state_code: "VT",
  },
  {
    id: 1427,
    name: "Virginia",
    state_code: "VA",
  },
  {
    id: 1405,
    name: "Wake Island",
    state_code: "UM-79",
  },
  {
    id: 1462,
    name: "Washington",
    state_code: "WA",
  },
  {
    id: 1429,
    name: "West Virginia",
    state_code: "WV",
  },
  {
    id: 1441,
    name: "Wisconsin",
    state_code: "WI",
  },
  {
    id: 1442,
    name: "Wyoming",
    state_code: "WY",
  },
];

export const statesUSAWITHProvincesCanada = [
  {
    id: 1456,
    name: "Alabama",
    state_code: "AL",
  },
  {
    id: 1400,
    name: "Alaska",
    state_code: "AK",
  },
  {
    id: 232,
    name: "Alberta",
    state_code: "AB",
  },
  {
    id: 1424,
    name: "American Samoa",
    state_code: "AS",
  },
  {
    id: 1434,
    name: "Arizona",
    state_code: "AZ",
  },
  {
    id: 1444,
    name: "Arkansas",
    state_code: "AR",
  },
  {
    id: 1402,
    name: "Baker Island",
    state_code: "UM-81",
  },
  {
    id: 231,
    name: "British Columbia",
    state_code: "BC",
  },
  {
    id: 1416,
    name: "California",
    state_code: "CA",
  },
  {
    id: 1450,
    name: "Colorado",
    state_code: "CO",
  },
  {
    id: 1435,
    name: "Connecticut",
    state_code: "CT",
  },
  {
    id: 1399,
    name: "Delaware",
    state_code: "DE",
  },
  {
    id: 1437,
    name: "District of Columbia",
    state_code: "DC",
  },
  {
    id: 1436,
    name: "Florida",
    state_code: "FL",
  },
  {
    id: 1455,
    name: "Georgia",
    state_code: "GA",
  },
  {
    id: 1412,
    name: "Guam",
    state_code: "GU",
  },
  {
    id: 1411,
    name: "Hawaii",
    state_code: "HI",
  },
  {
    id: 1398,
    name: "Howland Island",
    state_code: "UM-84",
  },
  {
    id: 1460,
    name: "Idaho",
    state_code: "ID",
  },
  {
    id: 1425,
    name: "Illinois",
    state_code: "IL",
  },
  {
    id: 1440,
    name: "Indiana",
    state_code: "IN",
  },
  {
    id: 1459,
    name: "Iowa",
    state_code: "IA",
  },
  {
    id: 1410,
    name: "Jarvis Island",
    state_code: "UM-86",
  },
  {
    id: 1428,
    name: "Johnston Atoll",
    state_code: "UM-67",
  },
  {
    id: 1406,
    name: "Kansas",
    state_code: "KS",
  },
  {
    id: 1419,
    name: "Kentucky",
    state_code: "KY",
  },
  {
    id: 1403,
    name: "Kingman Reef",
    state_code: "UM-89",
  },
  {
    id: 1457,
    name: "Louisiana",
    state_code: "LA",
  },
  {
    id: 1453,
    name: "Maine",
    state_code: "ME",
  },
  {
    id: 235,
    name: "Manitoba",
    state_code: "MB",
  },
  {
    id: 1401,
    name: "Maryland",
    state_code: "MD",
  },
  {
    id: 1433,
    name: "Massachusetts",
    state_code: "MA",
  },
  {
    id: 1426,
    name: "Michigan",
    state_code: "MI",
  },
  {
    id: 1438,
    name: "Midway Atoll",
    state_code: "UM-71",
  },
  {
    id: 1420,
    name: "Minnesota",
    state_code: "MN",
  },
  {
    id: 1430,
    name: "Mississippi",
    state_code: "MS",
  },
  {
    id: 1451,
    name: "Missouri",
    state_code: "MO",
  },
  {
    id: 1446,
    name: "Montana",
    state_code: "MT",
  },
  {
    id: 1439,
    name: "Navassa Island",
    state_code: "UM-76",
  },
  {
    id: 1408,
    name: "Nebraska",
    state_code: "NE",
  },
  {
    id: 1458,
    name: "Nevada",
    state_code: "NV",
  },
  {
    id: 241,
    name: "Newfoundland",
    state_code: "NF",
  },
  {
    id: 239,
    name: "New Brunswick",
    state_code: "NB",
  },
  {
    id: 1404,
    name: "New Hampshire",
    state_code: "NH",
  },
  {
    id: 1417,
    name: "New Jersey",
    state_code: "NJ",
  },
  {
    id: 1423,
    name: "New Mexico",
    state_code: "NM",
  },
  {
    id: 1452,
    name: "New York",
    state_code: "NY",
  },
  {
    id: 1447,
    name: "North Carolina",
    state_code: "NC",
  },
  {
    id: 1418,
    name: "North Dakota",
    state_code: "ND",
  },
  {
    id: 1431,
    name: "Northern Mariana Islands",
    state_code: "MP",
  },
  {
    id: 233,
    name: "Northwest Territories",
    state_code: "NT",
  },
  {
    id: 240,
    name: "Nova Scotia",
    state_code: "NS",
  },
  {
    id: 4851,
    name: "Ohio",
    state_code: "OH",
  },
  {
    id: 1421,
    name: "Oklahoma",
    state_code: "OK",
  },
  {
    id: 237,
    name: "Ontario",
    state_code: "ON",
  },
  {
    id: 1415,
    name: "Oregon",
    state_code: "OR",
  },
  {
    id: 1448,
    name: "Palmyra Atoll",
    state_code: "UM-95",
  },
  {
    id: 1422,
    name: "Pennsylvania",
    state_code: "PA",
  },
  {
    id: 1449,
    name: "Puerto Rico",
    state_code: "PR",
  },
  {
    id: 238,
    name: "Quebec",
    state_code: "QC",
  },
  {
    id: 1461,
    name: "Rhode Island",
    state_code: "RI",
  },
  {
    id: 236,
    name: "Saskatchewan",
    state_code: "SK",
  },
  {
    id: 1443,
    name: "South Carolina",
    state_code: "SC",
  },
  {
    id: 1445,
    name: "South Dakota",
    state_code: "SD",
  },
  {
    id: 1454,
    name: "Tennessee",
    state_code: "TN",
  },
  {
    id: 1407,
    name: "Texas",
    state_code: "TX",
  },
  {
    id: 1432,
    name: "United States Minor Outlying Islands",
    state_code: "UM",
  },
  {
    id: 1413,
    name: "United States Virgin Islands",
    state_code: "VI",
  },
  {
    id: 1414,
    name: "Utah",
    state_code: "UT",
  },
  {
    id: 1409,
    name: "Vermont",
    state_code: "VT",
  },
  {
    id: 1427,
    name: "Virginia",
    state_code: "VA",
  },
  {
    id: 1405,
    name: "Wake Island",
    state_code: "UM-79",
  },
  {
    id: 1462,
    name: "Washington",
    state_code: "WA",
  },
  {
    id: 1429,
    name: "West Virginia",
    state_code: "WV",
  },
  {
    id: 1441,
    name: "Wisconsin",
    state_code: "WI",
  },
  {
    id: 1442,
    name: "Wyoming",
    state_code: "WY",
  },
  {
    id: 234,
    name: "Yukon",
    state_code: "YT",
  },
];

export const provincesBrazilWITHProvincesMexico = [
  {
    id: 2012,
    name: "Acre",
    state_code: "AC",
  },
  {
    id: 3456,
    name: "Aguascalientes",
    state_code: "AGU",
  },
  {
    id: 2007,
    name: "Alagoas",
    state_code: "AL",
  },
  {
    id: 1999,
    name: "Amapá",
    state_code: "AP",
  },
  {
    id: 2004,
    name: "Amazonas",
    state_code: "AM",
  },
  {
    id: 2002,
    name: "Bahia",
    state_code: "BA",
  },
  {
    id: 3457,
    name: "Baja California",
    state_code: "BCN",
  },
  {
    id: 3460,
    name: "Baja California Sur",
    state_code: "BCS",
  },
  {
    id: 3475,
    name: "Campeche",
    state_code: "CAM",
  },
  {
    id: 2016,
    name: "Ceará",
    state_code: "CE",
  },
  {
    id: 3451,
    name: "Chiapas",
    state_code: "CHP",
  },
  {
    id: 3447,
    name: "Chihuahua",
    state_code: "CHH",
  },
  {
    id: 3471,
    name: "Coahuila",
    state_code: "COA",
  },
  {
    id: 3472,
    name: "Colima",
    state_code: "COL",
  },
  {
    id: 3453,
    name: "Durango",
    state_code: "DUR",
  },
  {
    id: 2018,
    name: "Espírito Santo",
    state_code: "ES",
  },
  {
    id: 2017,
    name: "Federal District",
    state_code: "DF",
  },
  {
    id: 2000,
    name: "Goiás",
    state_code: "GO",
  },
  {
    id: 3469,
    name: "Guanajuato",
    state_code: "GUA",
  },
  {
    id: 3459,
    name: "Guerrero",
    state_code: "GRO",
  },
  {
    id: 3470,
    name: "Hidalgo",
    state_code: "HID",
  },
  {
    id: 4857,
    name: "Jalisco",
    state_code: "JAL",
  },
  {
    id: 2015,
    name: "Maranhão",
    state_code: "MA",
  },
  {
    id: 2011,
    name: "Mato Grosso",
    state_code: "MT",
  },
  {
    id: 2010,
    name: "Mato Grosso do Sul",
    state_code: "MS",
  },
  {
    id: 3473,
    name: "Mexico City",
    state_code: "CMX",
  },
  {
    id: 3474,
    name: "Michoacán",
    state_code: "MIC",
  },
  {
    id: 1998,
    name: "Minas Gerais",
    state_code: "MG",
  },
  {
    id: 3465,
    name: "Morelos",
    state_code: "MOR",
  },
  {
    id: 3450,
    name: "México",
    state_code: "MEX",
  },
  {
    id: 3477,
    name: "Nayarit",
    state_code: "NAY",
  },
  {
    id: 3452,
    name: "Nuevo León",
    state_code: "NLE",
  },
  {
    id: 3448,
    name: "Oaxaca",
    state_code: "OAX",
  },
  {
    id: 2022,
    name: "Paraná",
    state_code: "PR",
  },
  {
    id: 2005,
    name: "Paraíba",
    state_code: "PB",
  },
  {
    id: 2009,
    name: "Pará",
    state_code: "PA",
  },
  {
    id: 2006,
    name: "Pernambuco",
    state_code: "PE",
  },
  {
    id: 2008,
    name: "Piauí",
    state_code: "PI",
  },
  {
    id: 3476,
    name: "Puebla",
    state_code: "PUE",
  },
  {
    id: 3455,
    name: "Querétaro",
    state_code: "QUE",
  },
  {
    id: 3467,
    name: "Quintana Roo",
    state_code: "ROO",
  },
  {
    id: 1997,
    name: "Rio de Janeiro",
    state_code: "RJ",
  },
  {
    id: 2019,
    name: "Rio Grande do Norte",
    state_code: "RN",
  },
  {
    id: 2001,
    name: "Rio Grande do Sul",
    state_code: "RS",
  },
  {
    id: 2013,
    name: "Rondônia",
    state_code: "RO",
  },
  {
    id: 4858,
    name: "Roraima",
    state_code: "RR",
  },
  {
    id: 3461,
    name: "San Luis Potosí",
    state_code: "SLP",
  },
  {
    id: 2014,
    name: "Santa Catarina",
    state_code: "SC",
  },
  {
    id: 2003,
    name: "Sergipe",
    state_code: "SE",
  },
  {
    id: 3449,
    name: "Sinaloa",
    state_code: "SIN",
  },
  {
    id: 3468,
    name: "Sonora",
    state_code: "SON",
  },
  {
    id: 2021,
    name: "São Paulo",
    state_code: "SP",
  },
  {
    id: 3454,
    name: "Tabasco",
    state_code: "TAB",
  },
  {
    id: 3463,
    name: "Tamaulipas",
    state_code: "TAM",
  },
  {
    id: 3458,
    name: "Tlaxcala",
    state_code: "TLA",
  },
  {
    id: 2020,
    name: "Tocantins",
    state_code: "TO",
  },
  {
    id: 3464,
    name: "Veracruz",
    state_code: "VER",
  },
  {
    id: 3466,
    name: "Yucatán",
    state_code: "YUC",
  },
  {
    id: 3462,
    name: "Zacatecas",
    state_code: "ZAC",
  },
];

export const listUSA = [
  "Alabama",
  "Alaska",
  "American Samoa",
  "Arizona",
  "Arkansas",
  "Baker Island",
  "California",
  "Colorado",
  "Connecticut",
  "Delaware",
  "District of Columbia",
  "Florida",
  "Georgia",
  "Guam",
  "Hawaii",
  "Howland Island",
  "Idaho",
  "Illinois",
  "Indiana",
  "Iowa",
  "Jarvis Island",
  "Johnston Atoll",
  "Kansas",
  "Kentucky",
  "Kingman Reef",
  "Louisiana",
  "Maine",
  "Maryland",
  "Massachusetts",
  "Michigan",
  "Midway Atoll",
  "Minnesota",
  "Mississippi",
  "Missouri",
  "Montana",
  "Navassa Island",
  "Nebraska",
  "Nevada",
  "New Hampshire",
  "New Jersey",
  "New Mexico",
  "New York",
  "North Carolina",
  "North Dakota",
  "Northern Mariana Islands",
  "Ohio",
  "Oklahoma",
  "Oregon",
  "Palmyra Atoll",
  "Pennsylvania",
  "Puerto Rico",
  "Rhode Island",
  "South Carolina",
  "South Dakota",
  "Tennessee",
  "Texas",
  "United States Minor Outlying Islands",
  "United States Virgin Islands",
  "Utah",
  "Vermont",
  "Virginia",
  "Wake Island",
  "Washington",
  "West Virginia",
  "Wisconsin",
  "Wyoming",
];

export const listBrazil = [
  "Acre",
  "Alagoas",
  "Amapá",
  "Amazonas",
  "Bahia",
  "Ceará",
  "Espírito Santo",
  "Federal District",
  "Goiás",
  "Maranhão",
  "Mato Grosso",
  "Mato Grosso do Sul",
  "Minas Gerais",
  "Pará",
  "Paraíba",
  "Paraná",
  "Pernambuco",
  "Piauí",
  "Rio de Janeiro",
  "Rio Grande do Norte",
  "Rio Grande do Sul",
  "Rondônia",
  "Roraima",
  "Santa Catarina",
  "São Paulo",
  "Sergipe",
  "Tocantins",
];

export const listMexico = [
  "Aguascalientes",
  "Baja California",
  "Baja California Sur",
  "Campeche",
  "Chiapas",
  "Chihuahua",
  "Coahuila",
  "Colima",
  "Durango",
  "Guanajuato",
  "Guerrero",
  "Hidalgo",
  "Jalisco",
  "México",
  "Mexico City",
  "Michoacán",
  "Morelos",
  "Nayarit",
  "Nuevo León",
  "Oaxaca",
  "Puebla",
  "Querétaro",
  "Quintana Roo",
  "San Luis Potosí",
  "Sinaloa",
  "Sonora",
  "Tabasco",
  "Tamaulipas",
  "Tlaxcala",
  "Veracruz",
  "Yucatán",
  "Zacatecas",
];

export const allStatesAndProvinces = [...statesUSA, ...provincesCanada, ...provincesMexico, ...provincesBrazil];
export const allStatesAndProvincesSelectOptions = [
  ...statesUSAWITHProvincesCanada,
  ...provincesBrazilWITHProvincesMexico,
]?.map((state) => ({
  label: state.name,
  value: state.state_code,
}));
