import React, { useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { formatTime, calculateTimeDifference } from "../../../utils/format";
import { IoMdPause, IoMdPlay } from "react-icons/io";
import { theme } from "../../../utils/theme";
import { AppText } from "../../UI/AppText";
import { MyPriorCallNotes } from "../DashboardSideBarSegments";
import { CoachingNotes } from ".";
import { FetchResult, MutationFunctionOptions } from "@apollo/client";
import { restAPI } from "../../../apollo";
import { BACKEND_URL } from "../../../utils/variables";
import { AppButton, AppSelect } from "../../UI";
import { BsMic, BsVolumeDownFill } from "react-icons/bs";
import { inputDevices, outputDevices, twilioMuted, currentCallState, loggedInUser } from "../../../apollo/cache";
import { joinCall, leaveCall, whisperIcon } from "../../../images";
import { ConfirmJoinConferenceModal } from "../../modal";
import { CallContext, ModalContext } from "../../../context";
import moment from "moment";
import { toast } from "react-toastify";
import { appToast } from "../../../utils/toast";
import { MixpanelActions } from "../../../services/mixpanel";
import { axios } from "../../../services/rest";
import { CallReportListLead } from "../CallReportListLead";

interface Lead {
  id: string;
  business_name: string;
  channel: string;
  first_name: string;
  last_name: string;
  industry_label: string;
  lead_source_label: string;
  current_lead_type: string;
  call_notes: { created_at: string; notes: string; id: string }[];
}

export interface Region {
  id: string;
  start: number;
  end: number;
  data: {
    note: string;
  };
}

interface LiveCallSegmentProps {
  conference_id: string;
  regions: Region[];
  lead_data: Lead;
  salesperson: string;
  refetch_call_report: () => void;
  start_time: string;
  deleteCoachingNote: (
    options?: MutationFunctionOptions<any, Record<string, any>> | undefined,
  ) => Promise<FetchResult<any, Record<string, any>, Record<string, any>>>;
  addCoachingNote: (
    options?: MutationFunctionOptions<any, Record<string, any>> | undefined,
  ) => Promise<FetchResult<any, Record<string, any>, Record<string, any>>>;
  editCoachingNote: (
    options?: MutationFunctionOptions<any, Record<string, any>> | undefined,
  ) => Promise<FetchResult<any, Record<string, any>, Record<string, any>>>;
}

const LiveCallSegment: React.FC<LiveCallSegmentProps> = ({
  conference_id,
  regions,
  lead_data,
  refetch_call_report,
  deleteCoachingNote,
  editCoachingNote,
  addCoachingNote,
  salesperson,
  start_time,
}) => {
  const { showJoinConferenceModal, openJoinConferenceModal, closeJoinConferenceModal } = useContext(ModalContext);
  const {
    conferenceState,
    toggleMute,
    hangup,
    device,
    getOutputDevice,
    changeInputDevice,
    changeOutputDevice,
  } = useContext(CallContext);
  const [connecting, setConnecting] = useState(true);
  const [whisperMode, setWhisperMode] = useState(true);
  const [playerTime, setPlayerTime] = useState(calculateTimeDifference(moment(), moment(start_time)));

  const config = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  };

  const inviteCoach = async () => {
    console.log("invite coach");
    const params = new URLSearchParams();
    const user_id = localStorage.getItem("user_id") || "";
    params.append("user_id", user_id);
    params.append("conference_id", conference_id);

    try {
      const api_call = await restAPI.post(`${BACKEND_URL}/twilio/inviteCoach`, params, config);

      console.log("api_call", api_call);
      if (api_call?.status === 400) {
        appToast("Error inviting coach!");
        return;
      }
      setConnecting(false);
      MixpanelActions.track("Call Whispering", { type: "action", conference_id, user_id });

      return api_call;
    } catch (error: any) {
      console.log("error coach", error);
      console.error("error coach", error?.response?.data);
      appToast(`Error inviting coach: ${error?.response?.data}`);
    }
  };

  const removeCoaching = async () => {
    const params = new URLSearchParams();
    const user_id = localStorage.getItem("user_id") || "";
    params.append("user_id", user_id);
    params.append("conference_id", conference_id);

    try {
      const api_call = await restAPI.post(`${BACKEND_URL}/twilio/removeCoaching`, params, config);
      console.log("removeCoaching response: ", api_call);
      MixpanelActions.track("Call Whispering: Removing whisper", { type: "action", conference_id, user_id });
      setWhisperMode(false);
      return api_call;
    } catch (error: any) {
      console.log(`Error removing coaching: ${error}`);
    }
  };

  const enableCoaching = async () => {
    const params = new URLSearchParams();
    const user_id = localStorage.getItem("user_id") || "";
    params.append("user_id", user_id);
    params.append("conference_id", conference_id);

    try {
      const api_call = await restAPI.post(`${BACKEND_URL}/twilio/enableCoaching`, params, config);
      MixpanelActions.track("Call Whispering", { type: "action", conference_id, user_id });
      setWhisperMode(true);
      return api_call;
    } catch (error: any) {
      console.log(`Error enabling coaching: ${error}`);
    }
  };

  const managerMute = async () => {
    const params = new URLSearchParams();
    const user_id = localStorage.getItem("user_id") || "";
    params.append("user_id", user_id);
    params.append("conference_id", conference_id);
    params.append("muted", `${twilioMuted()}`);

    try {
      const api_call = await restAPI.post(`${BACKEND_URL}/twilio/muteOrUnmuteCoach`, params, config);
      return api_call;
    } catch (error: any) {
      console.log(`Error in managerMute: ${error}`);
    }
  };

  useEffect(() => {
    setConnecting(true);
    setTimeout(async () => {
      inviteCoach();
    }, 1000);
    const this_interval = setInterval(() => {
      setPlayerTime(calculateTimeDifference(moment(), moment(start_time)));
    }, 1000);
    return () => {
      clearInterval(this_interval);
      hangup();
    };
  }, []);

  console.log("mute status: ", twilioMuted());
  const getCurrentSeconds = () => moment().diff(moment(start_time), "seconds");

  const addRegion = async (text: string) => {
    await addCoachingNote({
      variables: {
        conference_id: conference_id,
        text: !!text ? text : "",
        start: getCurrentSeconds(),
        end: getCurrentSeconds() + 1,
      },
    });
    refetch_call_report();
    appToast("New note added!");
    const user_id = localStorage.getItem("user_id") || "";
    MixpanelActions.track("Call Whispering: Note Added", { type: "action", conference_id, user_id });
  };

  const updateNotes = async (noteId: string, note: string) => {
    await editCoachingNote({
      variables: {
        noteItemId: noteId,
        text: note,
      },
    });
    refetch_call_report();
    appToast("Note updated!");
    const user_id = localStorage.getItem("user_id") || "";
    MixpanelActions.track("Call Whispering: Note updated", { type: "action", conference_id, user_id });
  };

  const removeRegion = async (regionId: string) => {
    await deleteCoachingNote({
      variables: {
        noteItemId: regionId,
      },
    });

    refetch_call_report();
    appToast("Note deleted!");
    const user_id = localStorage.getItem("user_id") || "";
    MixpanelActions.track("Call Whispering: Note deleted", { type: "action", conference_id, user_id });
  };
  return (
    <LiveCallContainer>
      {showJoinConferenceModal && (
        <ConfirmJoinConferenceModal
          showConfirmJoinConference={showJoinConferenceModal}
          closeConfirmJoinConference={closeJoinConferenceModal}
          joinCall={removeCoaching}
          salesperson={salesperson}
          lead_data={lead_data}
        />
      )}
      <PlayerContainer>
        <TimeOnCallDiv>
          <TimeOnCallText>Time on Call</TimeOnCallText>
          <TimeOnCallNumberText>{playerTime}</TimeOnCallNumberText>
        </TimeOnCallDiv>
        <PlayerStatusControl>
          {!conferenceState ? (
            <ConnectingText>Connecting Now..</ConnectingText>
          ) : (
            <PlayerStatusContainer>
              <MicroPhoneButton
                onClick={async () => {
                  toggleMute();
                  const test = await managerMute();
                }}
                muted={twilioMuted()}
              >
                <BsMic size={20} color={twilioMuted() ? theme.WHITE_COLOR : theme.BLACK_COLOR} />
              </MicroPhoneButton>

              {whisperMode ? (
                <InfoContainer>
                  <img src={whisperIcon} alt="" height="36px" width="36px" />
                  <PlayerStatusText>You are in Whisper Mode</PlayerStatusText>
                </InfoContainer>
              ) : (
                <InfoContainer>
                  <PlayerStatusText>You have joined the Conference Call</PlayerStatusText>
                </InfoContainer>
              )}
              <div style={{ width: 200 }} />
              {whisperMode ? (
                <PlayerStatusButton onClick={openJoinConferenceModal}>
                  <img src={joinCall} alt="" height="36px" width="36px" />
                  <PlayerStatusText>Join Conference Call</PlayerStatusText>
                </PlayerStatusButton>
              ) : (
                <PlayerStatusButton onClick={enableCoaching}>
                  <img src={leaveCall} alt="" height="30px" />
                  <PlayerStatusText>Leave Conference Call</PlayerStatusText>
                </PlayerStatusButton>
              )}
            </PlayerStatusContainer>
          )}
        </PlayerStatusControl>
        <ControlCenter>
          <AppSelect
            name="inputSelect"
            options={inputDevices()}
            appSelectIcon={<BsMic size={15} color={theme.BLACK_COLOR} />}
            value={device?.audio?.inputDevice?.deviceId ? device?.audio?.inputDevice?.deviceId : ""}
            //@ts-ignore
            onChange={(e) => changeInputDevice(e.target.value)}
          />
          <AppSelect
            name="outputSelect"
            options={outputDevices()}
            value={getOutputDevice()}
            //@ts-ignore
            onChange={(e) => changeOutputDevice(e.target.value)}
            appSelectIcon={<BsVolumeDownFill size={20} color={theme.BLACK_COLOR} />}
          />
        </ControlCenter>
      </PlayerContainer>
      <NotesContainer>
        <MyPriorCallNotes isInCallReport callNotes={lead_data.call_notes} color={theme.NEUTRAL500} />
        <CoachingNotes
          coachingNotes={regions ? regions : []}
          addRegion={addRegion}
          updateNotes={updateNotes}
          removeRegion={removeRegion}
        />
      </NotesContainer>
      {(loggedInUser().role === "ADMIN" || loggedInUser().role === "SM") && (
        <CallReportListLead leadID={lead_data?.id} gridFilter={false} callReportPage />
      )}
    </LiveCallContainer>
  );
};

export { LiveCallSegment };

const LiveCallContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const NotesContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 40px;
`;

const PlayerContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 113px;
  /* width: 993px; */
  border-radius: 4px;
  background-color: ${theme.BLACK_COLOR};
  padding: 20px 20px 20px 40px;
`;

const TimeOnCallDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: fit-content;
  height: 100%;
  float: left;
`;

const TimeOnCallText = styled(AppText)`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: ${theme.WHITE_COLOR};
`;

const TimeOnCallNumberText = styled(AppText)`
  font-size: 37px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: ${theme.WHITE_COLOR};
`;

const PlayerStatusControl = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const ConnectingText = styled(AppText)`
  font-size: 15px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: ${theme.WHITE_COLOR};
`;

interface MicroPhoneButtonProps {
  muted: boolean;
}

const MicroPhoneButton = styled(AppButton)<MicroPhoneButtonProps>`
  width: 40px;
  height: 40px;
  margin: 0 10px;
  padding: 0 10px;
  border-radius: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => (props.muted ? theme.ATTENTION700 : "#ffffff")};
  :focus {
    outline: 0;
    opacity: 0.8;
  }
`;

const InfoContainer = styled.div`
  height: 100%;
  width: 200px;
  margin: 0 10px;
  padding: 10px 10px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
`;

const PlayerStatusButton = styled(AppButton)`
  height: 100%;
  width: 200px;
  margin: 0 10px;
  padding: 10px 10px;
  border-radius: 4px;
  background-color: ${theme.BLACK_COLOR};
  border: solid 1px #06d291;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
`;
const PlayerStatusContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const PlayerStatusText = styled(AppText)`
  font-size: 10px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: ${theme.WHITE_COLOR};
`;

const ControlCenter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 170px;
  height: 100%;
  padding-top: 20px;
`;
