import gql from "graphql-tag";
import { useQuery } from "@apollo/client";
import { appToast } from "src/utils/toast";
import { LabelT } from "src/types";

const FETCH_LABEL = gql`
  query fetchLabel($input: fetchLabelsInput!) {
    fetchLabels(input: $input) {
      id
      name
      description
      color
      created_at
      updated_at
      deleted_at
      organization_id
      created_by {
        id
        full_name
      }
      last_updated_by {
        id
        full_name
      }
      reps {
        user_id
        user {
          id
          full_name
        }
      }
      rules {
        rule_id
        rule {
          id
          name
        }
      }
    }
  }
`;

const useFetchLabel = (id: string) => {
  const { data: labelsData, loading, error } = useQuery<{ fetchLabels: LabelT[] }>(FETCH_LABEL, {
    fetchPolicy: "cache-and-network",
    variables: { input: { labels_id: [id] } },
    onCompleted: () => {},
    onError: () => {
      appToast("Something went wrong.");
    },
  });

  return {
    data: labelsData?.fetchLabels?.[0] ?? null,
    loading,
    error,
  };
};

export default useFetchLabel;
