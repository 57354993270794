import { gql, useQuery } from "@apollo/client";
import { isEqual } from "lodash";
import * as React from "react";
import { Dispatch, SetStateAction, useContext, useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import styled from "styled-components";
import { CORE_LEAD_CARD_DATA } from "../../../apollo/fragments";
import { FavoritedLeadFilterContext, LeadCardContext, ModalContext, ScheduleContext } from "../../../context";
import { filter } from "../../../images/NewDesign";
import { MixpanelActions } from "../../../services/mixpanel";
import { theme } from "../../../utils/theme";
import { LeadCardError } from "../../Smart";
import { AppSidebarCard, AppText, Loading } from "../../UI";
import { PhoenixIcon, PhoenixMultiSelect } from "../../UI/Phoenix";
import { LeadCardV2 } from "../DashboardSideBarSegments";

const FETCH_FAVORITED_LEADS = gql`
  ${CORE_LEAD_CARD_DATA}
  query fetchFavoritedLeads($lead_filter: LeadFilter!, $skip: Int!, $take: Int!, $sort_option: DialSortOption) {
    fetchFavoritedLeads(lead_filter: $lead_filter, skip: $skip, take: $take, sort_option: $sort_option) {
      id
      ...CoreLeadCardData
    }
  }
`;

interface CurrentLeadProps {
  closeOtherModals?: any;
}

const FavoriteDialsComponent: React.FC<CurrentLeadProps> = ({ closeOtherModals }): any => {
  const { favoriteDials, setFavoriteDials } = useContext(LeadCardContext);
  //Local State
  const [skip, setSkip] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [isClicked, setIsClicked] = useState(false);
  const [sortOption, setSortOption] = useState("HighestPDV");

  const { leadFilter, setLeadFilter, selected, setSelected, filterNum } = useContext(FavoritedLeadFilterContext);
  const { showFavoriteFiltersModal, setShowFavoriteFiltersModal } = useContext(ModalContext);
  const { expandSchedule } = useContext(ScheduleContext); // TODO: figure out how to use this to expand height when schedule is collapsed

  // Queries
  const {
    data: dataFavorites,
    loading: loadingFavorites,
    error: errorFavorites,
    fetchMore: fetchMoreFavorites,
    refetch: dataRefetch,
  } = useQuery(FETCH_FAVORITED_LEADS, {
    fetchPolicy: "network-only",
    variables: {
      skip: skip,
      // ! If you change the take value, you must change the length check below to match as well as setSkip in handleShowMore.
      take: 4,
      lead_filter: leadFilter,
      sort_option: sortOption,
    },
    onCompleted({ fetchFavoritedLeads }) {
      if (!fetchFavoritedLeads.length && favoriteDials.length === 0) {
        setFavoriteDials([]);
        setHasMore(false);
        return;
      }
      const newList = [...favoriteDials, ...fetchFavoritedLeads]
        .filter((lead) => lead.favorited)
        .reduce((acc, cv) => (acc?.map((a: any) => a.id)?.includes(cv.id) ? acc : [...acc, cv]), []);

      dataFavorites.fetchFavoritedLeads.length < 4 ? setHasMore(false) : setHasMore(true);
      setFavoriteDials(newList);
    },
    onError({ message, name }) {
      // Sentry.captureEvent({
      //   message: `${name} GraphQL Error: ${message}`,
      // });
      console.log(`Error in ${name}: `, message);
    },
  });

  const refreshFavoritedLeads = () => {
    setSkip(0);
    setFavoriteDials([]);
    dataRefetch({ lead_filter: leadFilter, sort_option: sortOption, skip: 0, take: 4 });
  };

  useEffect(() => {
    refreshFavoritedLeads();
  }, [leadFilter, sortOption]);

  //Custom Functions
  const handleShowMore = () => {
    setSkip(skip + 4);
    fetchMoreFavorites({
      variables: {
        skip: skip,
        take: 4,
        lead_filter: leadFilter,
        sort_option: sortOption,
      },
    });
  };

  if (errorFavorites) {
    return (
      <LeadCardError
        title="FavoriteLeads"
        error="Error loading favorite calls"
        refetch={() => {
          setSkip(0);
          setFavoriteDials([]);
          dataRefetch({ lead_filter: leadFilter, sort_option: sortOption, skip: 0, take: 4 });
        }}
      />
    );
  }

  return (
    <MemoizedContent
      isClicked={isClicked}
      setIsClicked={setIsClicked}
      filterNum={filterNum}
      showFavoriteFiltersModal={showFavoriteFiltersModal}
      setShowFavoriteFiltersModal={setShowFavoriteFiltersModal}
      sortOption={sortOption}
      setSortOption={setSortOption}
      favoriteDials={favoriteDials}
      handleShowMore={handleShowMore}
      hasMore={hasMore}
      closeOtherModals={closeOtherModals}
    />
  );
};

interface MemoizedContentProps {
  isClicked: boolean;
  setIsClicked: Dispatch<SetStateAction<boolean>>;
  filterNum: boolean;
  showFavoriteFiltersModal: boolean;
  setShowFavoriteFiltersModal: Dispatch<SetStateAction<boolean>>;
  sortOption: string;
  setSortOption: Dispatch<SetStateAction<string>>;
  favoriteDials: any[];
  handleShowMore: () => void;
  hasMore: boolean;
  closeOtherModals?: () => void;
}

const FAVORITE_QUEUE_SORT_OPTIONS = [
  { label: "Predicted Dial Value", value: "HighestPDV" },
  { label: "Most Recently Dialed", value: "MostRecentDial" },
  { label: "Longest Dial Since", value: "LastSinceDialed" },
];

const MemoizedContent: React.FC<MemoizedContentProps> = React.memo(
  ({
    isClicked,
    setIsClicked,
    filterNum,
    showFavoriteFiltersModal,
    setShowFavoriteFiltersModal,
    sortOption,
    setSortOption,
    favoriteDials,
    handleShowMore,
    hasMore,
    closeOtherModals,
  }) => (
    <AppSidebarCard
      width={432}
      title={"Favorite Leads"}
      showExpand
      trackMixpanel
      render={
        <Horizontal>
          <PhoenixMultiSelect
            isMulti={false}
            name="sort"
            options={FAVORITE_QUEUE_SORT_OPTIONS}
            placeholder="Sort"
            onChange={(e: any) => {
              MixpanelActions.track("Favorite Dials Event", { type: "sort", view: "Dashboard" });
              setSortOption(e.value);
            }}
            value={{
              label: "Sort",
              value: sortOption,
            }}
            isClearable={false}
            marginBottom={false}
            error={false}
            ghostSelect
            ghostSelectAppThemeOverride={"Dark"}
            width={50}
            optionsContainerWidth={186}
            style={{ overflow: "hidden" }}
            minHeight={22}
            lineHeight="10px"
            maxHeight={22}
            // menuPortal={true}
            pointer
            menuInset="20px 0px 0px -130px"
            menuBorder
            removeMenuPadding
          />
          <div style={{ position: "relative" }}>
            {!!filterNum && (
              <NotificationsNumber
                onClick={() => {
                  MixpanelActions.track("Favorite Dials Event", { type: "sort", view: "Dashboard" });
                  setShowFavoriteFiltersModal(!showFavoriteFiltersModal);
                }}
              />
            )}
            <PhoenixIcon
              svg={filter}
              color={theme.icon.brand.default}
              hoverColor={theme.icon.brand.default}
              size={16}
              onClick={() => {
                MixpanelActions.track("Favorite Dials Event", { type: "filter", view: "Dashboard" });
                setShowFavoriteFiltersModal(!showFavoriteFiltersModal);
              }}
              pointer
            />
            {isClicked && (
              <OptionsDiv>
                <Option
                  onClick={() => {
                    setSortOption("HighestPDV");
                    setIsClicked(false);
                  }}
                >
                  Predicted Dial Value
                </Option>
                <Option
                  onClick={() => {
                    setSortOption("MostRecentDial");
                    setIsClicked(false);
                  }}
                >
                  Most Recently Dialed
                </Option>
                <Option
                  onClick={() => {
                    setSortOption("LastSinceDialed");
                    setIsClicked(false);
                  }}
                >
                  Longest Dial Since
                </Option>
              </OptionsDiv>
            )}
          </div>
        </Horizontal>
      }
    >
      <ScrollDiv id="favorite-scroll-div">
        <InfiniteScroll
          dataLength={favoriteDials.length}
          next={handleShowMore}
          hasMore={hasMore}
          loader={<Loading />}
          scrollableTarget="favorite-scroll-div"
        >
          {favoriteDials.length ? (
            favoriteDials?.map((item: any, index: number) => (
              <BorderDiv borderTop={index > 0} key={`favorite-${index}-${item.id}`}>
                <LeadCardV2
                  parentType={"favoriteDials"}
                  leadData={item}
                  intent={item?.next_intent_scheduled_or_unscheduled}
                  closeOtherModals={closeOtherModals}
                />
              </BorderDiv>
            ))
          ) : (
            <EmptyState>
              <EmptyStateText>
                You do not have any favorited leads. Try favoriting leads by clicking on the star icon.
              </EmptyStateText>
            </EmptyState>
          )}
        </InfiniteScroll>
      </ScrollDiv>
    </AppSidebarCard>
  ),
  (prevProps, newProps) => {
    const arePropsEqual =
      isEqual(prevProps.favoriteDials, newProps.favoriteDials) &&
      isEqual(prevProps.filterNum, newProps.filterNum) &&
      isEqual(prevProps.showFavoriteFiltersModal, newProps.showFavoriteFiltersModal) &&
      isEqual(prevProps.sortOption, newProps.sortOption) &&
      isEqual(prevProps.hasMore, newProps.hasMore) &&
      isEqual(prevProps.isClicked, newProps.isClicked);
    return arePropsEqual;
  },
);

interface BorderProps {
  borderTop?: boolean;
}

const BorderDiv = styled.div<BorderProps>`
  border-top: 1px solid ${(props) => (props.borderTop ? theme.NEUTRAL200 : "transparent")};
  padding: 0px;
  margin: 0px;
`;

const ScrollDiv = styled.div`
  overflow: auto;
  height: 400px;
`;

const EmptyState = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;
  margin: 50px;
`;

const EmptyStateText = styled(AppText)`
  text-align: center;
`;

const OptionsDiv = styled.div`
  position: absolute;
  left: -60px;
  top: 30px;
  width: 171px;
  background-color: ${theme.WHITE_COLOR};
  /* box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.15); */
  border: 1px solid ${theme.NEUTRAL200};
  border-radius: 4px;
  z-index: 6;
`;

const Option = styled.div`
  display: flex;
  justify-content: canter;
  align-items: center;
  height: 39px;
  font-weight: normal;
  font-size: 10px;
  line-height: 15px;
  padding-left: 12px;
  background-color: ${theme.WHITE_COLOR};
  color: ${theme.BLACK_COLOR};
  text-transform: none;
  letter-spacing: 0.2px;
  cursor: pointer;
  :first-child {
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
  }
  :last-child {
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
  }
  :hover {
    background-color: ${theme.NEUTRAL200};
  }
`;

const Horizontal = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  position: relative;
  gap: 8px;
`;

const NotificationsNumber = styled.div`
  position: absolute;
  top: -5px;
  right: -2px;
  height: 10px;
  width: 10px;
  /* padding-top: 2px; */
  background: ${theme.ATTENTION700};
  border-radius: 50%;
  justify-items: center;
  /* border: 1px solid ${theme.BLACK_COLOR}; */
  z-index: 2;
  cursor: pointer;
`;

export { FavoriteDialsComponent };
