import React from "react";
import styled, { css, keyframes } from "styled-components";
import { theme } from "src/utils/theme";
import { AppText, FlexDiv } from "../UI";

type TableListProps<T> = {
  data: T[];
  headers: string[];
  renderItem?: (item: T) => React.ReactNode;
  onRowClick?: (item: T) => void;
};

export const TableList = <T extends Record<string, any>>({
  data,
  headers,
  renderItem,
  onRowClick,
}: TableListProps<T>) => {
  const columns = headers
    .map((header, index) => (index === headers.length - 1 && header === "" ? "100px" : "1fr"))
    .join(" ");

  return (
    <ListWrapper>
      <HeaderRow style={{ marginBottom: "8px" }} columns={columns}>
        {headers.map((i) => {
          if (!i) {
            return <FlexDiv />;
          }

          return (
            <AppText fontSize={12} fontWeight={600}>
              {i}
            </AppText>
          );
        })}
      </HeaderRow>

      {data?.map((item) => {
        return (
          <Row columns={columns} isMounted onClick={() => onRowClick?.(item)}>
            {renderItem?.(item)}
          </Row>
        );
      })}
    </ListWrapper>
  );
};

export default TableList;

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const ListWrapper = styled.div`
  height: 100%;
  max-height: 65vh;
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-width: 800px;
  width: 100%;
`;

export const HeaderRow = styled.div<{ columns: string }>`
  align-items: center;
  display: grid;
  grid-template-columns: ${({ columns }) => columns};
  height: 52px;
  padding: 16px;
  width: 100%;
`;

export const Row = styled.div<{ columns: string; isMounted?: boolean; onClick?: () => void }>`
  align-items: center;
  animation: ${(props) =>
    !props.isMounted
      ? css`
          ${fadeIn} 0.25s ease forwards
        `
      : "unset"};
  animation-fill-mode: initial;
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid ${theme.border.neutral.primary};
  cursor: ${({ onClick }) => (onClick ? "pointer" : "default")};
  display: grid;
  grid-template-columns: ${({ columns }) => columns};
  height: 52px;
  opacity: 1;
  padding: 16px;
  width: 100%;
`;
