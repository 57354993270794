import styled from "styled-components";
import * as React from "react";
import { AppButton, AppText, AppInput, Loading, NewAppButton } from "../../UI";
import { theme } from "../../../utils/theme";
import { Link, useHistory } from "react-router-dom";
import { CreateNewLead, LeadCSVUpload, ResolveDuplicateLead, CreateCsvLink } from "../../modal/";
import * as Sentry from "@sentry/react";
import { useState, useRef } from "react";
import { gql, useQuery, useMutation } from "@apollo/client";
import { formatImportDate } from "../../../utils/format";

import { restAPI } from "../../../apollo";
import { LEAD_CSV_EXAMPLE_URL, LEAD_IMPORT_HISTORY_POLL_INTERVAL } from "../../../utils/variables";
import { ModalContext } from "../../../context";
import { useContext } from "react";
import { appToast } from "../../../utils/toast";
import Lottie from "react-lottie";
import pending from "../../../images/lotties/pending.json";
import ReactTooltip from "react-tooltip";
import { AppTable, AppTableItems, TableRow, TableTD } from "../../UI/AppTable";
import { downloadResourceAWS, sanitizeURL } from "../../../utils/misc";
import { PhoenixAppButton } from "../../UI/Phoenix";
import { loggedInUser } from "src/apollo/cache";
interface DisappearingDivProps {
  step: number;
  blinds: boolean;
  setBlinds: (blinds: boolean) => void;
  isOnboarding?: boolean;
}
const FETCH_CSV_LINK_HISTORY = gql`
  query getCsvLinks {
    csvLinks {
      id
      active
      csv_link
      created_at
      updated_at
      user {
        id
      }
    }
  }
`;

const FETCH_LEAD_IMPORT_HISTORY = gql`
  query fetchLeadImportHistory($skip: Int, $take: Int) {
    fetchLeadImportHistory(skip: $skip, take: $take) {
      id
      name
      type
      created_at
      leads_added
      leads_updated
      leads_errored
      leads_queued
      leads_on_warning
      error_report_url
      duplicate_report_url
      is_resolved
      user {
        id
        first_name
        last_name
      }
    }
  }
`;

const DOWNLOAD_CSV_TEMPLATE = gql`
  mutation OrgTemplateCsv {
    OrgTemplateCsv {
      url
    }
  }
`;

const IGNORE_LEAD_UPLOAD_WARNING = gql`
  mutation IgnoreLeadUploadWarning($upload_id: String!, $force_upload: Boolean) {
    ignoreLeadUploadWarning(upload_id: $upload_id, force_upload: $force_upload)
  }
`;

const defaultCSVModal = {
  num_imported: undefined,
  num_updated: undefined,
  num_staged: undefined,
  upload_id: undefined,
  num_error: undefined,
  error_report_url: undefined,
  visible: false,
};

const IMPORT_COLUMN_HEADERS = [
  { label: "Upload Date" },
  { label: "Upload Type", style: { minWidth: 80 } },
  { label: "Uploader" },
  { label: "File Name / Lead Name" },
  { label: "Added" },
  { label: "Updated" },
  { label: "Queued" },
  { label: "Warnings" },
  { label: "Errored" },
  { label: "Error Report" },
  { label: "Duplicates" },
];

const CSV_LINK_HEADERS = [{ label: "Created Date" }, { label: "CSV Link" }, { label: "Status" }];

const StepLeadsV2: React.FC<DisappearingDivProps> = ({ step, isOnboarding }) => {
  const [addNewLeadModal, setAddNewLeadModal] = useState(false);
  const [csvLinkModal, setCsvLinkModal] = useState(false); // Add this line

  const [showResolveModal, setShowResolveModal] = useState(false);
  const [resolveModalLead, setResolveModalLead] = useState(undefined);

  const { csvModal, setCsvModal } = useContext(ModalContext);
  const [leadUploadLoading, setLeadUploadLoading] = useState(false);
  const hiddenFileInput = useRef<HTMLInputElement>(null);
  const lottieOptions = (loop: boolean, animationData: any) => {
    return {
      loop: loop,
      autoplay: true,
      animationData: animationData,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };
  };

  const { data, loading, error, refetch } = useQuery(FETCH_LEAD_IMPORT_HISTORY, {
    fetchPolicy: "network-only",
    variables: 20,
    pollInterval: LEAD_IMPORT_HISTORY_POLL_INTERVAL,
    onError({ message, name }) {
      // Sentry.captureEvent({
      //   message: `${name} GraphQL Error: ${message}`,
      // });
      console.log(`Error in ${name}: `, message);
    },
  });

  const { data: csvLinksData, loading: csvLinksLoading, refetch: refetchCSVLinkHistory } = useQuery(
    FETCH_CSV_LINK_HISTORY,
    {
      fetchPolicy: "network-only",
      onError({ message, name }) {
        console.log(`Error in ${name}: `, message);
      },
    },
  );

  const [OrgTemplateCsv, { data: orgTemplateCsvData, loading: loadingTemplate }] = useMutation(DOWNLOAD_CSV_TEMPLATE, {
    fetchPolicy: "no-cache",
    onCompleted({ OrgTemplateCsv }) {
      if (OrgTemplateCsv.url) {
        // window.open(OrgTemplateCsv.url);
        downloadResourceAWS(OrgTemplateCsv.url);
      }
    },
    onError({ message }) {
      appToast(`${message}`);
      Sentry.captureEvent({
        message: `updateDashboardSavedView GraphQL Error: ${message}`,
      });
    },
  });

  const onFileChange = async (event: any) => {
    // Create an object of formData
    const formData = new FormData();
    // Update the formData object
    formData.append("file", event.target.files[0]);
    //Request made to the backend api
    //Send formData object;
    try {
      setLeadUploadLoading(true);
      const response = await restAPI.post(`/upload/leadCSVtoRedis`, formData, {
        headers: {
          ...restAPI.defaults.headers,
          "content-type": "multipart/form-data",
        },
      });
      if (response.data.length === 0) {
        const response = await restAPI.post(`/upload/mapLeadCSV`, formData, {
          headers: {
            ...restAPI.defaults.headers,
            "content-type": "application/json",
          },
        });
        console.log("RES: ", JSON.stringify(response.data));
        const newState = {
          num_imported: response.data.num_imported,
          num_updated: response.data.num_updated,
          num_error: response.data.num_errors,
          num_queued: response.data.num_queued,
          error_report_url: response.data.error_report,
          upload_id: response.data.upload_id,
          num_staged: response.data.num_stagged,
          visible: true,
        };
        console.log("RES:", newState);
        setLeadUploadLoading(false);
        setCsvModal(newState);
        refetch();
        event.value = "";
        return null;
      }
      window.localStorage.setItem("customPhasesData", JSON.stringify(response));
      setLeadUploadLoading(false);
      history.push("/onboarding/map-sale-phases");
      event.value = "";
    } catch (e: any) {
      setLeadUploadLoading(false);
      console.error(e?.response?.data ?? "Error uploading CSV.");
      event.value = "";
      appToast(`${e?.response?.data.error}` ?? "Error uploading CSV.");
    } finally {
      setLeadUploadLoading(false);
    }
  };

  const history = useHistory();
  return (
    <Sentry.ErrorBoundary fallback={"An error has occured at the Leads step of Admin Onboarding"}>
      <DisappearingDiv>
        {addNewLeadModal && (
          <CreateNewLead refetch={refetch} visible={addNewLeadModal} close={() => setAddNewLeadModal(false)} />
        )}
        {showResolveModal && (
          <ResolveDuplicateLead
            refetch={refetch}
            visible={showResolveModal}
            close={() => {
              setResolveModalLead(undefined);
              setShowResolveModal(false);
            }}
            lead={resolveModalLead}
          />
        )}
        {csvModal.visible && <LeadCSVUpload close={() => setCsvModal(defaultCSVModal)} />}
        {csvLinkModal && (
          <CreateCsvLink visible={csvLinkModal} close={() => setCsvLinkModal(false)} refetch={refetchCSVLinkHistory} />
        )}
        <CenterDiv>
          <PhoenixAppButton
            height={48}
            width={188}
            variant="brand"
            buttonType="secondary"
            disabled={leadUploadLoading || loadingTemplate}
            onClick={() => OrgTemplateCsv()}
          >
            Download CSV Template
          </PhoenixAppButton>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-end",
              gap: 16,
              flexGrow: 2,
            }}
          >
            <PhoenixAppButton
              height={48}
              width={188}
              variant="brand"
              buttonType="primary"
              disabled={leadUploadLoading}
              onClick={() => setAddNewLeadModal(true)}
            >
              Add New Lead
            </PhoenixAppButton>
            <>
              <PhoenixAppButton
                height={48}
                width={188}
                buttonType={"primary"}
                variant="brand"
                disabled={leadUploadLoading}
                onClick={() => {
                  hiddenFileInput.current !== null && hiddenFileInput?.current.click();
                }}
              >
                Import CSV
              </PhoenixAppButton>
              <input
                style={{ display: "none" }}
                type="file"
                ref={hiddenFileInput}
                onChange={onFileChange}
                onClick={(event: any) => (event.target.value = null)}
              />
            </>
            {loggedInUser()?.organization?.enable_csv_link && (
              <PhoenixAppButton
                height={48}
                width={188}
                buttonType="primary"
                variant="brand"
                onClick={() => setCsvLinkModal(true)}
              >
                Add CSV Link
              </PhoenixAppButton>
            )}
          </div>
        </CenterDiv>
        <LocalWrapper>
          <GridDiv>
            <>
              <AppTable columns={IMPORT_COLUMN_HEADERS} noTooltip>
                {loading || leadUploadLoading ? (
                  <Loading />
                ) : (
                  !!data?.fetchLeadImportHistory.length &&
                  data?.fetchLeadImportHistory?.map((lead: any, index: number) => (
                    <TableRow key={lead.id}>
                      <TableTD>
                        <ListItemText style={{ minWidth: 80 }}>
                          {formatImportDate(lead.created_at || Date.now())}
                        </ListItemText>
                      </TableTD>

                      <TableTD>
                        <ListItemText style={{ width: 80 }}>{lead.type}</ListItemText>
                      </TableTD>
                      <TableTD>{`${lead.user?.first_name} ${lead.user?.last_name}`}</TableTD>
                      <TableTD>
                        <LeadDiv style={{ width: 150 }}>
                          <ReactTooltip
                            id={lead.id}
                            multiline
                            place="top"
                            effect="solid"
                            className="sib-tooltip"
                            css={{
                              maxWidth: 600,
                              lineHeight: 1.4,
                              textAlign: "center",
                              fontFamily: theme.PRIMARY_FONT,
                            }}
                            backgroundColor={theme.PRIMARY800}
                            getContent={(dataTip) => (
                              <span
                                style={{
                                  fontFamily: "Inter",
                                  fontStyle: "normal",
                                  fontWeight: 600,
                                  fontSize: "10px",
                                  lineHeight: "14px",
                                }}
                              >
                                {dataTip}
                              </span>
                            )}
                          />
                          <SpinDiv>
                            {!lead.is_resolved && (
                              <Lottie options={lottieOptions(true, pending)} height={11} width={11} />
                            )}
                          </SpinDiv>

                          <ListItemText data-tip={lead.name} data-for={lead.id}>
                            {lead.name}
                          </ListItemText>
                        </LeadDiv>
                      </TableTD>
                      <TableTD>
                        <ListItemText>{lead.leads_added}</ListItemText>
                      </TableTD>
                      <TableTD>
                        <ListItemText>{lead.leads_updated}</ListItemText>
                      </TableTD>
                      <TableTD>
                        <ListItemText>{lead.leads_queued}</ListItemText>
                      </TableTD>
                      <TableTD>
                        <ListItemText>{lead.leads_on_warning}</ListItemText>
                      </TableTD>
                      <TableTD>
                        <ListItemText>{lead.leads_errored}</ListItemText>
                      </TableTD>
                      <TableTD>
                        <NewAppButton
                          variant="attention"
                          style={{ border: "none", backgroundColor: "transparent", textAlign: "left", padding: 0 }}
                          onClick={() => {
                            const santizedURL = sanitizeURL(lead.error_report_url);

                            if (santizedURL) {
                              window.open(santizedURL, "_blank", "noopener,noreferrer");
                            }
                          }}
                          disabled={!lead.error_report_url}
                        >
                          Download
                        </NewAppButton>
                      </TableTD>
                      <TableTD>
                        <NewAppButton
                          variant="secondary"
                          style={{ border: "none", backgroundColor: "transparent", textAlign: "left", padding: 0 }}
                          disabled={!lead.duplicate_report_url || lead.is_resolved}
                          onClick={() => {
                            setResolveModalLead(lead);
                            setShowResolveModal(true);
                          }}
                        >
                          Resolve
                        </NewAppButton>
                      </TableTD>
                    </TableRow>
                  ))
                )}
              </AppTable>
            </>
            {loggedInUser()?.organization?.enable_csv_link && (
              <>
                <AppText style={{ margin: "32px 0 16px 0", fontWeight: "bold" }}>CSV Link History</AppText>
                <AppTable columns={CSV_LINK_HEADERS} noTooltip>
                  {csvLinksLoading ? (
                    <Loading />
                  ) : (
                    !!csvLinksData?.csvLinks?.length &&
                    csvLinksData?.csvLinks?.map((link: any) => (
                      <TableRow key={link.id}>
                        <TableTD>
                          <ListItemText>{formatImportDate(link.created_at || Date.now())}</ListItemText>
                        </TableTD>
                        <TableTD>
                          <ListItemText>{link.csv_link || "-"}</ListItemText>
                        </TableTD>
                        <TableTD>
                          <ListItemText>{link.active ? "Active" : "Inactive"}</ListItemText>
                        </TableTD>
                      </TableRow>
                    ))
                  )}
                </AppTable>
              </>
            )}
          </GridDiv>
          {!!isOnboarding && (
            <CenterDiv>
              <SaveAndContinueButton
                onClick={() => {
                  if (isOnboarding) {
                    history.push(`/onboarding?step=${step + 1}`);
                  } else {
                    appToast("Updated!");
                  }
                }}
              >
                {!!isOnboarding ? "Continue" : "Save Changes"}
              </SaveAndContinueButton>
            </CenterDiv>
          )}
        </LocalWrapper>
      </DisappearingDiv>
    </Sentry.ErrorBoundary>
  );
};

const LeadDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const SpinDiv = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  padding-right: 4px;
`;

const LocalWrapper = styled.div`
  margin-right: auto;
  margin-left: auto;
`;

const DisappearingDiv = styled.div`
  display: block;
  width: 100%;
  height: 100%;
  align-items: center;
`;

const GridDiv = styled.div`
  margin-bottom: 16px;
  min-height: 600px;
  max-height: 640px;
  overflow: auto;
`;

const CenterDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 50px;
`;

const SaveAndContinueButton = styled(AppButton)`
  margin: 20px auto;
  border-radius: 2.9px;
  font-size: 15px;
  font-weight: 500;
  width: 221px;
  height: 47px;
`;

const ListItemText = styled.div`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  word-break: break-all;
`;

export { StepLeadsV2 };
