import React from "react";
import styled from "styled-components";
import { components } from "react-select";
import useFetchLabels from "src/query-hooks/useFetchLabels";
import { LabelT } from "src/types";
import { DEFAULT_LABEL_COLORS, PhoenixMultiSelect } from "../UI/Phoenix";
import { FlexDiv } from "../UI";
import { gql, useMutation } from "@apollo/client";
import { errorToast } from "src/utils/toast";

const CREATE_OR_UPDATE_LABEL = gql`
  mutation CreateOrUpdateLabel($input: CreateOrUpdateLabelInput!) {
    createOrUpdateLabel(input: $input) {
      id
      name
      description
      color
      created_at
      updated_at
      deleted_at
      organization_id
      created_by {
        id
        full_name
      }
      last_updated_by {
        id
        full_name
      }
    }
  }
`;

type LabelSelectOption = { value: string; label: string; data: LabelT };

type LabelSelectProps = {
  includeReps?: boolean;
  onChange?: (value: LabelSelectOption[]) => void;
  titleText?: string;
  titleTextSpacing?: number;
  value?: string[];
  width?: number;
};

export const LabelSelect = ({ width, onChange, value, titleText, titleTextSpacing, includeReps }: LabelSelectProps) => {
  const { data } = useFetchLabels({ includeReps });

  const [createOrEditLabel, { loading: isCreatingLabel }] = useMutation(CREATE_OR_UPDATE_LABEL, {
    onCompleted({ createOrUpdateLabel }) {
      onChange?.([
        ...(value?.map((v) => options.find((o) => o.value === v)).filter(Boolean) as LabelSelectOption[]),
        {
          value: createOrUpdateLabel.id,
          label: createOrUpdateLabel.name,
          data: createOrUpdateLabel,
        },
      ]);
    },
    onError({ message }) {
      errorToast(message);
    },
    refetchQueries: ["fetchLabels"],
  });

  const options =
    data?.map((label) => ({
      value: label.id,
      label: label.name,
      data: label,
    })) ?? [];

  const { Option, MultiValueContainer } = components;

  const CustomOption = (props: any) => (
    <Option {...props}>
      <FlexDiv align="center" gap={8}>
        <LabelColor color={props?.data?.data?.color} />
        {props?.data?.label}
      </FlexDiv>
    </Option>
  );

  const CustomMultiValueContainer = (props: any) => (
    <MultiValueContainer {...props}>
      <FlexDiv align="center" style={{ backgroundColor: props?.data?.data?.color }}>
        {props.children}
      </FlexDiv>
    </MultiValueContainer>
  );

  const onCreateOption = (value: any) => {
    createOrEditLabel({
      variables: {
        input: {
          name: value,
          color: DEFAULT_LABEL_COLORS[Math.floor(Math.random() * DEFAULT_LABEL_COLORS.length)],
        },
      },
    });
  };

  return (
    <PhoenixMultiSelect
      components={{ Option: CustomOption, MultiValueContainer: CustomMultiValueContainer }}
      creatableOptions
      hideErrorMessage
      isClearable
      isLoading={isCreatingLabel}
      isMulti
      itemBackgroundColor="transparent"
      marginBottom={false}
      name="reps"
      onChange={(v: LabelSelectOption[]) => onChange?.(v)}
      onCreateOption={onCreateOption}
      options={options}
      titleText={titleText}
      titleTextSpacing={titleTextSpacing}
      value={value?.map((v) => options.find((o) => o.value === v))}
      width={width}
    />
  );
};

export default LabelSelect;

const LabelColor = styled.div<{ color: string }>`
  background-color: ${({ color }) => color};
  width: 10px;
  height: 10px;
  border-radius: 100%;
`;
