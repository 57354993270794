import styled from "styled-components";
import * as React from "react";

import { theme } from "../../utils/theme";
import { FaPlayCircle } from "react-icons/fa";
import { Link } from "react-router-dom";
import { formatImportDate, formatCallDuration } from "../../utils/format";
import { callReportSave } from "../../images/";
import { ModalContext } from "../../context";
import { useContext } from "react";

interface CallHistoryItemProps {
  /**
   * id of the conference object
   */
  id: string;
  time: string;
  salesperson: string;
  result: string;
  duration: number;
  event_start_time?: string;
}

const CallHistoryItem: React.FC<CallHistoryItemProps> = ({
  id,
  time,
  salesperson,
  result,
  duration,
  event_start_time,
}) => {
  const { saveCallToLibraryModal, setSaveCallToLibraryModal, currentConferenceID, setCurrentConferenceID } = useContext(
    ModalContext,
  );

  return (
    <ListItemDiv>
      <ListItemText>{formatImportDate(time)}</ListItemText>
      <ListItemText>{salesperson}</ListItemText>
      <ListItemText>{result}</ListItemText>
      <ListItemText>
        {event_start_time === "None" || !event_start_time ? "None" : formatImportDate(event_start_time)}
      </ListItemText>
      {duration > 0 ? (
        <Horizontal>
          <ListItemDuration>
            <StyledLink href={`/call-report/${id}/from_system_view=true`}>
              <div style={{ display: "flex", height: "100%" }}>
                <CenterDiv>
                  <FaPlayCircle size={10} color={theme.PRIMARY500} style={{ marginRight: "6px", marginLeft: "4px" }} />
                </CenterDiv>
                <CenterDiv>{formatCallDuration(duration)}</CenterDiv>
              </div>
            </StyledLink>
          </ListItemDuration>
          <IconDiv
            onClick={() => {
              setCurrentConferenceID(id);
              setSaveCallToLibraryModal(true);
            }}
          >
            <img src={callReportSave} alt="Save to Call Library" />
            SAVE
          </IconDiv>
        </Horizontal>
      ) : (
        <ListItemText></ListItemText>
      )}
    </ListItemDiv>
  );
};

const IconDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 8px;
  line-height: 13px;
  color: ${theme.TERTIARY500};
  margin-left: 20px;
  cursor: pointer;
`;

const Horizontal = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const CenterDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ListItemDiv = styled.div`
  display: grid;
  grid-template-columns: 200px 200px 250px 150px 1fr;
  align-items: center;
  justify-items: start;
  height: 40px;
  border-bottom: solid 1px ${theme.NEUTRAL100};
`;

interface ListItemDurationProps {
  display?: boolean;
}

const ListItemDuration = styled.div<ListItemDurationProps>`
  border: solid 0.7px ${theme.PRIMARY500};
  border-radius: 2.1px;
  width: 74px;
  height: 17px;
  font-size: 10px;
`;

const ListItemIcon = styled.div`
  place-self: center;
  font-size: 10px;
`;

const ListItemText = styled.div`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 10px;
  padding-right: 15px;
`;

const StyledLink = styled.a`
  text-decoration: none;

  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
    color: inherit;
  }
`;

export { CallHistoryItem };
