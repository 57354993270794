import * as Sentry from "@sentry/react";
import moment from "moment";
import * as React from "react";
import { useContext, useEffect } from "react";
import { AiFillCaretDown, AiFillCaretUp } from "react-icons/ai";
import { BsTrashFill } from "react-icons/bs";
import { Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import styled from "styled-components";
import { LeadCardContactButton } from "../../Components/Segments/DashboardSideBarSegments/LeadCardV2";
import { NotificationType } from "../../Components/Segments/Header/HeaderNotifications";
import { loggedInUser } from "../../apollo/cache";
import { CallContext } from "../../context/CallContext";
import { NotificationsContext } from "../../context/NotificationsContext";
import { info, trash } from "../../images/NewDesign";
import { theme } from "../../utils/theme";
import { appToast } from "../../utils/toast";
import { CreateActionButtons } from "../Segments/Header/";
import { AppErrorText, AppText, FlexDiv, Loading, Wrapper } from "../UI";
import { PhoenixIcon } from "../UI/Phoenix";
interface NotificationDetailsLinkProps {
  item: NotificationType;
}
export const NotificationDetailsLink = ({ item }: NotificationDetailsLinkProps) => {
  switch (item?.type) {
    case "MissedCall":
      return (
        <a target="_blank" href={item?.voicemail_url_computed}>
          <i>{!!item?.details && `${item?.details}`}</i>
        </a>
      );
    case "EmailRecieved":
      return (
        <Link
          to={{
            pathname: `/lead-detail/${item?.lead_id}`,
            search: `?tab=emails&${item?.relevant_id}`,
            state: { email_id: item?.relevant_id },
          }}
        >
          <i>{!!item?.details && `${item?.details}`}</i>
        </Link>
      );
    case "ReassginLead":
      return !!item?.relevant_id ? (
        <Link
          to={{
            pathname: `/lead-detail/${item?.relevant_id}`,
          }}
        >
          <i>{!!item?.details && `${item?.details}`}</i>
        </Link>
      ) : (
        <i>{!!item?.details && `${item?.details}`}</i>
      );
    case "CallReportReviewedFeedback":
      return (
        <Link
          to={{
            pathname: `/call-report/${item?.relevant_id}`,
          }}
        >
          <i>{!!item?.details && `${item?.details}`}</i>
        </Link>
      );
    case "ConciergeWidget":
      return !!item?.relevant_id ? (
        <Link
          to={{
            pathname: `/lead-detail/${item?.relevant_id}`,
          }}
        >
          <i>{!!item?.details && `${item?.details}`}</i>
        </Link>
      ) : (
        <i>{!!item?.details && `${item?.details}`}</i>
      );

    case "LeadOwnershipConstraint":
      return !!item?.relevant_id ? (
        <Link
          to={{
            pathname: `/lead-detail/${item?.relevant_id}`,
          }}
        >
          <i>{!!item?.details && `${item?.details}`}</i>
        </Link>
      ) : (
        <i>{!!item?.details && `${item?.details}`}</i>
      );
    case "HotLead":
      return !!item?.relevant_id ? (
        <Link
          to={{
            pathname: `/lead-detail/${item?.lead_id}`,
          }}
        >
          <i>{!!item?.details && `${item?.details}`}</i>
        </Link>
      ) : (
        <i>{!!item?.details && `${item?.details}`}</i>
      );
    case "LeadAlreadyOwned":
      return !!item?.relevant_id ? (
        <Link
          to={{
            pathname: `/lead-detail/${item?.relevant_id}`,
          }}
        >
          <i>{!!item?.details && `${item?.details}`}</i>
        </Link>
      ) : (
        <i>{!!item?.details && `${item?.details}`}</i>
      );
    case "LeadOwnershipConstraint":
      return !!item?.relevant_id ? (
        <Link
          to={{
            pathname: `/lead-detail/${item?.relevant_id}`,
          }}
        >
          <i>{!!item?.details && `${item?.details}`}</i>
        </Link>
      ) : (
        <i>{!!item?.details && `${item?.details}`}</i>
      );
    case "NewSMS":
      return !!item?.relevant_id ? (
        <Link
          to={{
            pathname: `/lead-detail/${item?.relevant_id}`,
          }}
        >
          <i>{`Business Name: ${item?.details ? item?.details : "N/A"}`}</i>
        </Link>
      ) : (
        <i>{`Business Name: ${item?.details ? item?.details : "N/A"}`}</i>
      );

    default:
      return <i>{!!item?.details && `${item?.details}`}</i>;
  }
};

const NotificationsPage: React.FC = () => {
  const { goToCall } = useContext(CallContext);

  const {
    notifications,
    sortOrder,
    setSortOrder,
    sortedNotifications,
    setNotifications,
    handleDeleteOneNotification,
    fetchNotifications,
    loading,
    error,
    markNotificationsAsRead,
  } = useContext(NotificationsContext);

  useEffect(() => {
    markNotificationsAsRead();
    fetchNotifications();
  }, []);

  if (loading) return <Loading />;

  const returnTooltipData = () => {
    if (loggedInUser().role === "ADMIN")
      return "As an administrator you can undo system actions performed by reps. Deleting a notification will remove it from your queue.";
    if (loggedInUser().role === "SM")
      return "As a manager you can approve or deny time-off requests from reps, review requested call reports and you will be notified of rep's missed events. Deleting a notification will remove it from your queue.";
    if (loggedInUser().role === "SDR" || loggedInUser().role === "AE")
      return "Here you will get notified of missed calls, missed events, and have the ability to listen to voicemails. You can also review your completed call reports, and check the status of your time-off requests and Lead updates. Deleting a notification will remove it from your queue.";
    return "No tooltip available";
  };

  return (
    <Sentry.ErrorBoundary fallback={"An error has occured at the Notifications page"}>
      <ReactTooltip
        multiline
        place="bottom"
        effect="solid"
        css={{
          maxWidth: 600,
          lineHeight: 1.4,
          textAlign: "center",
          fontFamily: theme.PRIMARY_FONT,
        }}
        backgroundColor={theme.PRIMARY800}
        getContent={(dataTip) => (
          <span
            style={{ fontFamily: "Inter", fontStyle: "normal", fontWeight: 600, fontSize: "10px", lineHeight: "14px" }}
          >
            {dataTip}
          </span>
        )}
      />
      <OnboardingColorWrapper>
        <Main>
          <OnboardingWrapper>
            <SimpleFlexDiv>
              <StepLocationText>Notifications</StepLocationText>
              <PhoenixIcon svg={info} size={14} variant="brand" data-tip={returnTooltipData()} />
            </SimpleFlexDiv>
            <NotificationsBox>
              <NotificationsTable>
                <NotificationsTR>
                  <NotificationsTH
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setSortOrder(sortOrder === "ascending" ? "descending" : "ascending");
                    }}
                  >
                    Time of Notification
                    {sortOrder === "descending" ? (
                      <AiFillCaretDown
                        style={{ marginLeft: "4px", verticalAlign: "bottom" }}
                        size={14}
                        color={theme.BLACK_COLOR}
                      />
                    ) : (
                      <AiFillCaretUp
                        style={{ marginLeft: "4px", verticalAlign: "bottom" }}
                        size={14}
                        color={theme.BLACK_COLOR}
                      />
                    )}
                  </NotificationsTH>
                  <NotificationsTH>Notification Type</NotificationsTH>
                  <NotificationsTH>Sales Rep</NotificationsTH>
                  <NotificationsTH>Details</NotificationsTH>
                  <NotificationsTH></NotificationsTH>
                </NotificationsTR>
                {loading ? (
                  <Loading />
                ) : error ? (
                  <AppErrorText>Error loading notifications!</AppErrorText>
                ) : (
                  sortedNotifications?.map((item: NotificationType) => (
                    <NotificationsTR key={item?.id}>
                      <NotificationsTD blueDot={!item?.call_to_action_read}>
                        {moment(item?.created_at).format("MM/DD/YYYY h:mm A")}
                      </NotificationsTD>
                      <NotificationsTD>{item?.type_label}</NotificationsTD>
                      <NotificationsTD>{item?.salesperson}</NotificationsTD>
                      <NotificationsTD>
                        <MainNotifcationText>
                          <TextRow>
                            {!!item?.text_computed && `${item?.text_computed}`}
                            {!!item?.can_dial && !!item?.phone_number && (
                              <LeadCardContactButton
                                size={10}
                                padding={4}
                                type={"phone"}
                                disabled={false}
                                onClick={() => {
                                  const numberUsedToCallRep = item?.phone_number;
                                  const leadId = item?.relevant_id ?? item?.lead_id;

                                  if (!leadId || !numberUsedToCallRep) {
                                    appToast("Something went wrong.");
                                    return;
                                  }

                                  // go to call with notification number
                                  goToCall({
                                    lead_id: leadId,
                                    phoneNumber: numberUsedToCallRep,
                                  });
                                }}
                              />
                            )}
                          </TextRow>
                          <NotificationDetailsLink item={item} />
                        </MainNotifcationText>
                      </NotificationsTD>
                      <NotificationsTD>
                        {!!item?.text_action ? (
                          <ItalicsTableText>{item?.text_action}</ItalicsTableText>
                        ) : (
                          <FlexDiv align="center" gap={16}>
                            <PhoenixIcon
                              svg={trash}
                              size={16}
                              variant="danger"
                              pointer
                              onClick={async () => {
                                await handleDeleteOneNotification(item);
                              }}
                            />
                            <CreateActionButtons
                              item={item}
                              refetch={() => {
                                // fetchNotifications();
                              }}
                            />
                          </FlexDiv>
                        )}
                      </NotificationsTD>
                    </NotificationsTR>
                  ))
                )}
              </NotificationsTable>
            </NotificationsBox>
          </OnboardingWrapper>
        </Main>
      </OnboardingColorWrapper>
    </Sentry.ErrorBoundary>
  );
};

interface NotificationUnreadProp {
  blueDot?: boolean;
}

const NotificationsTR = styled.tr<NotificationUnreadProp>`
  border: none;
  border-bottom: solid 1px ${theme.NEUTRAL100};
  td:nth-child(1),
  td:nth-child(2),
  td:nth-child(3) {
    width: 175px;
  }
  td:nth-child(4) {
    width: 400px;
    padding: 5px 0px;
  }
  td:last-child {
    text-align: right;
  }
  :first-child {
    border-bottom: none;
  }
  :last-child {
    border-bottom: none;
  }
`;

const NotificationsTD = styled.td<NotificationUnreadProp>`
  font-size: 12px;
  color: ${theme.BLACK_COLOR};
  border: none;
  height: 42px;
  text-align: left;
  ::before {
    content: "";
    position: absolute;
    left: 15px;
    margin-top: 6px;
    height: 5px;
    width: 5px;
    border-radius: 50%;
    background-color: ${(props) => (props.blueDot ? theme.PRIMARY500 : "transparent")};
  }
`;

const ItalicsTableText = styled(AppText)`
  font-size: 12px;
  color: ${theme.BLACK_COLOR};
  text-align: left;
  font-style: italic;
  opacity: 0.5;
`;

const NotificationsTH = styled.th`
  font-size: 13px;
  font-weight: 500;
  text-align: left;
  height: 42px;
  text-align: left;
`;

const NotificationsTable = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const NotificationsBox = styled.div`
  position: absolute;
  bottom: 0px;
  top: 153px;
  width: 1155px;
  height: auto;
  padding: 10px 30px 40px 30px;
  border-radius: 16px;
  border: solid 4px ${theme.NEUTRAL100};
  border-bottom: none;
  background-color: ${theme.WHITE_COLOR};
  overflow-y: scroll;
`;

const OnboardingColorWrapper = styled.div`
  background: ${theme.surface.neutral.tertiary};
  min-width: 1100px;
  position: relative;
`;

interface ModalOnProps {
  blinds?: boolean;
}

const MainNotifcationText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const TextRow = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const OnboardingWrapper = styled(Wrapper)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: inherit;
`;

const StepLocationText = styled(AppText)`
  font-size: 15px;
  font-weight: 500;
`;

const SimpleFlexDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const Main = styled.div<ModalOnProps>`
  position: ${(props) => (props.blinds ? "fixed" : "relative")};
  height: auto;
  min-width: 1250px;
  padding-top: 50px;
  padding-bottom: 50px;
  max-width: inherit;
  background: ${theme.surface.neutral.tertiary};
  width: 100%;
  min-height: calc(100vh);
`;

export { NotificationsPage };
