import * as React from "react";
import styled, { css } from "styled-components";
import { theme } from "../../utils/theme";
import { PhoenixIcon } from "./Phoenix";
import { SkeletonBlock } from "./SkeletonBlock";

interface StatCardProps {
  variant?: "primary" | "secondary" | "tertiary";
  icon: string;
  text?: string;

  // optional
  loading?: boolean;
  fadeIn?: boolean;

  // overrides
  backgroundColor?: string;
  textColor?: string;
  iconSize?: number;
  iconColor?: string;
  containerPadding?: string;
  iconHoverColor?: string;
}

const StatCard: React.FC<StatCardProps> = ({
  variant,
  icon,
  text,
  loading = false,
  fadeIn,
  backgroundColor,
  textColor = variant === "tertiary" ? theme.DANGER600 : theme.WHITE_COLOR,
  iconColor = variant === "tertiary" ? theme.DANGER600 : theme.WHITE_COLOR,
  iconSize,
  containerPadding,
  iconHoverColor,
}) => {
  if (loading) {
    return <SkeletonBlock width={20} height={20} borderRadius={4} />;
  }

  return (
    <StatCardContainer
      variant={variant}
      backgroundColor={backgroundColor}
      textColor={textColor}
      hasText={!!text}
      iconSize={iconSize}
      fadeIn={fadeIn}
      containerPadding={containerPadding}
    >
      <PhoenixIcon svg={icon} color={iconColor} size={iconSize} hoverColor={iconHoverColor ?? iconColor} />
      {text}
    </StatCardContainer>
  );
};

interface StatCardContainerProps {
  variant?: "primary" | "secondary" | "tertiary";
  backgroundColor?: string;
  textColor?: string;
  hasText: boolean;
  iconSize?: number;
  fadeIn?: boolean;
  containerPadding?: string;
}

const primaryBackgroundColor = theme.PRIMARY500;
const secondaryBackgroundColor = theme.PRIMARY900;
const tertiaryBackgroundColor = theme.DANGER200;

const StatCardContainer = styled.div<StatCardContainerProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${(props) => (props.containerPadding ? props.containerPadding : "4px 8px")};
  gap: 8px;
  font-size: 8px;
  font-weight: 600;

  color: ${(props) =>
    props.textColor ? props.textColor : props.variant === "tertiary" ? theme.DANGER600 : theme.WHITE_COLOR};
  background-color: ${(props) => {
    if (props.backgroundColor) {
      return props.backgroundColor;
    }
    switch (props.variant) {
      case "secondary":
        return secondaryBackgroundColor;
      case "tertiary":
        return tertiaryBackgroundColor;
      default:
        return primaryBackgroundColor;
    }
  }};
  border-radius: 4px;

  width: ${(props) => (props.hasText ? "fit-content" : props.iconSize ? props.iconSize + 2 + "px" : "20px")};
  height: ${(props) => (props.hasText ? "24px" : props.iconSize ? props.iconSize + 2 + "px" : "20px")};

  animation: ${(props) => (props.fadeIn ? theme.fadeIn : "none")} 0.25s ease-in-out forwards;
`;

export { StatCard };
