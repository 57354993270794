import React, { useMemo, useRef, useState } from "react";
import * as Sentry from "@sentry/react";
import { gql, useMutation } from "@apollo/client";
import styled from "styled-components";
import { chevron_down, flag, info } from "src/images/NewDesign";
import { theme } from "src/utils/theme";
import { appToast, errorToast } from "src/utils/toast";
import { useClickOutside } from "src/utils/hooks";
import { AppText } from "../UI";
import { PhoenixIcon } from "../UI/Phoenix";

const REMOVE_FLAG_FROM_LEAD_NUMBER = gql`
  mutation removeFlagFromLeadNumber($lead_flagged_number_id: String!) {
    removeFlagFromLeadNumber(lead_flagged_number_id: $lead_flagged_number_id)
  }
`;

export const BadNumberTag = ({
  canRemoveFlag,
  flaggedNumbers,
  phoneNumber,
}: {
  canRemoveFlag?: boolean;
  flaggedNumbers: any[];
  phoneNumber: string;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const closeDropdown = () => {
    setIsOpen(false);
  };

  useClickOutside(dropdownRef, closeDropdown);

  const lead_flagged_number_id = useMemo(() => {
    return flaggedNumbers?.find(
      (item: any) =>
        item?.phone_number === phoneNumber &&
        ["TrackingNumber", "WrongNum", "DeadLine"].includes(item?.lead_activity?.related_disposition?.type),
    )?.id;
  }, [phoneNumber, flaggedNumbers]);

  const [removeFlagFromLeadNumber, { loading }] = useMutation(REMOVE_FLAG_FROM_LEAD_NUMBER, {
    onCompleted: () => {
      appToast("Flag removed successfully");
      setIsOpen(false);
    },
    onError: ({ message }) => {
      Sentry.captureEvent({
        message: `updateActiveTwilioRecording graphql error: ${message}`,
      });
      errorToast("Failed to remove flag");
    },
    refetchQueries: ["fetchLead"],
  });

  const handleRemoveFlag = () => {
    removeFlagFromLeadNumber({ variables: { lead_flagged_number_id } });
    closeDropdown();
  };

  return (
    <NumberTagWrapper ref={dropdownRef}>
      <TextWrapper>
        <PhoenixIcon svg={info} variant="danger" size={12} />
        <BadNumberTagText>Bad Number</BadNumberTagText>
      </TextWrapper>

      {canRemoveFlag && (
        <DropdownTrigger loading={loading} onClick={() => setIsOpen(!isOpen)}>
          <PhoenixIcon svg={chevron_down} fillIcon variant="danger" hoverColor={theme.DANGER500} size={12} />
        </DropdownTrigger>
      )}

      {isOpen && (
        <DropdownMenu>
          <DropdownItem onClick={handleRemoveFlag}>
            <PhoenixIcon variant="danger" svg={flag} size={16} />
            Remove Flag
          </DropdownItem>
        </DropdownMenu>
      )}
    </NumberTagWrapper>
  );
};

const NumberTagWrapper = styled.div`
  background-color: ${theme.DANGER100};
  border-radius: 4px;
  display: flex;
  position: relative;
`;

const TextWrapper = styled.div`
  align-items: center;
  display: flex;
  gap: 8px;
  padding: 8px;
`;

const BadNumberTagText = styled(AppText)`
  color: ${theme.text.danger.primary};
  font-size: 8px;
  font-weight: 600;
  text-transform: uppercase;
  white-space: nowrap;
`;

const DropdownTrigger = styled.div<{ loading: boolean }>`
  align-items: center;
  align-self: stretch;
  border-left: 1px solid ${theme.text.neutral.inverse};
  cursor: ${(props) => (props.loading ? "not-allowed" : "pointer")};
  display: flex;
  justify-content: center;
  opacity: ${(props) => (props.loading ? 0.5 : 1)};
  padding: 4px;
`;

const DropdownMenu = styled.div`
  background: ${theme.WHITE_COLOR};
  border-radius: 4px;
  border: 1px solid ${theme.NEUTRAL200};
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  position: absolute;
  right: 0;
  top: calc(100% + 4px);
  z-index: 1;
`;

const DropdownItem = styled.div`
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  font-size: 12px;
  gap: 8px;
  padding: 8px;
  text-align: left;
  white-space: nowrap;
  width: 100%;

  &:hover {
    background: ${theme.NEUTRAL100};
  }
`;
