import React from "react";
import { theme } from "src/utils/theme";
import styled from "styled-components";

interface PhoenixColorPickerGridProps {
  color: string;
  colorList?: string[];
  columns?: number;
  setColor: (color: string) => void;
}

export const DEFAULT_LABEL_COLORS = [
  "#E8EAF6",
  "#1E88E5",
  "#1A237E",
  "#B0BEC5",
  "#212121",
  "#E0F2F1",
  "#43A047",
  "#1B5E20",
  "#FFECB3",
  "#FFA726",
  "#FB8C00",
  "#FFCDD2",
  "#F44336",
  "#C62828",
  "#D1C4E9",
  "#AB47BC",
  "#4A148C",
  "#B3E5FC",
  "#039BE5",
  "#00ACC1",
  "#B2EBF2",
  "#80DEEA",
  "#4DB6AC",
  "#FFE0B2",
  "#FFB74D",
];

export const PhoenixColorPickerGrid: React.FC<PhoenixColorPickerGridProps> = ({
  color,
  colorList = DEFAULT_LABEL_COLORS,
  columns = 6,
  setColor,
}) => {
  return (
    <Wrapper columns={columns}>
      {colorList?.map((c: string) => {
        const isSelected = color === c;

        return (
          <Palette
            key={c}
            color={c}
            isSelected={isSelected}
            onClick={() => {
              setColor(c);
            }}
          />
        );
      })}
    </Wrapper>
  );
};

const Wrapper = styled.div<{ columns?: number }>`
  display: grid;
  grid-template-columns: repeat(${(props) => props.columns}, 1fr);
  gap: 8px;
  position: relative;
`;

interface PaletteProps {
  color: string;
  isSelected: boolean;
  onClick: () => void;
}

const Palette = styled.div<PaletteProps>`
  aspect-ratio: 1/1;
  background-color: ${(props) => props.color};
  border-radius: 4px;
  border: 1px solid ${({ isSelected }) => (isSelected ? theme.NEUTRAL800 : theme.NEUTRAL200)};
  cursor: pointer;
`;
