import { theme } from "src/utils/theme";

import React, { useMemo, useState } from "react";
import { useModalContext } from "src/utils/hooks";
import styled from "styled-components";
import { Modal } from ".";
import { AppText } from "../UI";
import { FlexDiv } from "../UI/FlexDiv";
import { PhoenixAppButton, PhoenixInput } from "../UI/Phoenix";
import { gql, useMutation, useQuery } from "@apollo/client";
import { VoicemailDrop } from "src/types/VoicemailTypes";
import { loggedInUser } from "src/apollo/cache";
interface FetchVoicemailDropsExpectedResponse {
  fetchVoicemailDrops: VoicemailDrop[];
}

const FETCH_SAVED_VOICEMAIL_DROPS = gql`
  query FetchVoicemailDrops($userId: String) {
    fetchVoicemailDrops(user_id: $userId)
  }
`;

const UPDATE_VOICEMAIL_DROP = gql`
  mutation UpdateVoicemailDrop {
    updateVoicemailDrop(title: "New Voicemail Title", id: "12345") {
      id
      title
    }
  }
`;
const RenameVoicemailDropModal = () => {
  const { data, loading, error } = useQuery<FetchVoicemailDropsExpectedResponse>(FETCH_SAVED_VOICEMAIL_DROPS, {
    fetchPolicy: "cache-and-network",
    variables: {
      userId: loggedInUser().id,
    },
  });
  const [updateVoicemailDrop] = useMutation(UPDATE_VOICEMAIL_DROP, {
    onCompleted: () => {
      setShowRenameVoicemailDropModal(false);
    },
    refetchQueries: ["FetchVoicemailDrops"],
  });
  const {
    showRenameVoicemailDropModal,
    setShowRenameVoicemailDropModal,
    renameVoicemailDropModalData,
  } = useModalContext();

  const { id } = renameVoicemailDropModalData;

  const selectedVoicemailDrop = useMemo(() => {
    return data?.fetchVoicemailDrops.find((voicemailDrop: VoicemailDrop) => voicemailDrop.id === id);
  }, [data?.fetchVoicemailDrops, id]);

  const [newName, setNewName] = useState(selectedVoicemailDrop?.title);

  const handleRenameVoicemailDrop = () => {
    updateVoicemailDrop({
      variables: {
        id,
        title: newName,
      },
    });
  };

  return (
    <Modal open={showRenameVoicemailDropModal} onClose={() => setShowRenameVoicemailDropModal(false)}>
      <ModalMain>
        <Top>
          <AppText fontSize={16} fontWeight={600} color={theme.BLACK_COLOR}>
            Rename Voicemail Recording
          </AppText>
          <PhoenixInput
            titleTextSpacing={8}
            placeholder="Enter new name"
            value={newName}
            titleText="Name"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setNewName(e.target.value)}
          />
        </Top>

        <SubmitButtonDiv>
          <PhoenixAppButton
            variant="danger-outline"
            buttonType="secondary"
            onClick={() => setShowRenameVoicemailDropModal(false)}
          >
            Cancel
          </PhoenixAppButton>
          <PhoenixAppButton variant="brand" buttonType="secondary" onClick={handleRenameVoicemailDrop}>
            Continue
          </PhoenixAppButton>
        </SubmitButtonDiv>
      </ModalMain>
    </Modal>
  );
};

const Top = styled(FlexDiv)`
  align-items: center;
  padding: 40px;
  width: 100%;
  flex-direction: column;
  gap: 40px;
  height: 222px;
`;

const SubmitButtonDiv = styled(FlexDiv)`
  border-top: 1px solid ${theme.NEUTRAL200};
  padding: 16px 40px;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;

const ModalMain = styled(FlexDiv)`
  width: 476px;
  height: max-content;
  flex-direction: column;
  align-items: center;
`;

export default RenameVoicemailDropModal;
