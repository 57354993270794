import React, { useContext, useEffect, useRef } from "react";
import styled from "styled-components";
import { AppText, FlexDiv } from "../UI";
import { theme } from "../../utils/theme";
import { PhoenixIcon } from "../UI/Phoenix";
import { info, xIcon } from "src/images/NewDesign";
import { ModalContext, NotificationsContext } from "src/context";
import { MixpanelActions } from "src/services/mixpanel";
import { loggedInUser } from "src/apollo/cache";

const trackMixpanelEvent = (eventName: string) => {
  MixpanelActions.track(eventName, {
    user_id: loggedInUser().id,
    organization_id: loggedInUser().organization_id,
    date: new Date().toISOString(),
  });
};

const NewDeployVersionBanner = () => {
  const { showNewDeployVersionBanner, setShowNewDeployVersionBanner } = useContext(ModalContext);
  const { latestBreakingDeployTimestamp } = useContext(NotificationsContext);

  const handleRefreshPage = () => {
    trackMixpanelEvent("New Deploy Version Banner Clicked");

    // to let other tabs know to refresh if old
    if (latestBreakingDeployTimestamp) {
      localStorage.setItem("LatestBreakingDeploy", latestBreakingDeployTimestamp);
    }

    window.location.reload();
  };

  const handleCloseBanner = () => {
    trackMixpanelEvent("New Deploy Version Banner Closed Without Refresh");
    setShowNewDeployVersionBanner(false);
  };

  if (!showNewDeployVersionBanner) return null;

  return (
    <FlexDiv
      direction="column"
      padding="10px"
      width="400px"
      gap={4}
      style={{
        zIndex: 1000,
        position: "fixed",
        bottom: 0,
        right: 0,
        background: theme.fill.brand.secondary,
        borderRadius: "10px",
        margin: "10px",
      }}
    >
      <FlexDiv direction="row" gap={8} align="center">
        <PhoenixIcon svg={info} size={22} color={theme.text.brand.primary} />
        <AppText color={theme.text.brand.primary} fontSize={14} fontWeight={500}>
          There's a new version of Sellfire available! 🎉 Please{" "}
          <ClickableSpan onClick={handleRefreshPage}>click here</ClickableSpan> to update.
        </AppText>
      </FlexDiv>
      <StyledDivider />
      <FlexDiv direction="row" gap={8} align="center">
        <AppText color={theme.text.neutral.tertiary} fontSize={10} fontWeight={400} textAlign="center">
          This will refresh all tabs. Please save any unsaved changes.
        </AppText>
      </FlexDiv>
      <PhoenixIcon
        svg={xIcon}
        size={22}
        color={theme.text.brand.primary}
        onClick={handleCloseBanner}
        pointer
        style={{
          position: "absolute",
          right: 10,
          top: 10,
        }}
      />
    </FlexDiv>
  );
};

const StyledDivider = styled.hr`
  height: 1px;
  width: 100%;
  background: ${theme.fill.neutral.tertiary};
`;

const ClickableSpan = styled.span`
  cursor: pointer;
  font-weight: 600;
  font-size: 14px;
  color: ${theme.text.brand.primary};
  text-decoration: underline;
`;

export default NewDeployVersionBanner;
